import TimeUtils from '../time-utils.service';

function daysDiff(timeUtils) {
    return function (time) {
        return timeUtils.daysDiffString(time);
    };
}
angular.module('tonkean.app').filter('daysDiff', daysDiff);

function DaysDiffInSentence(timeUtils) {
    return function (time) {
        return timeUtils.daysDiffSentenceString(time);
    };
}
angular.module('tonkean.app').filter('daysDiffInSentence', DaysDiffInSentence);
const daysDiffInSentenceInstance = new DaysDiffInSentence(TimeUtils);
export { daysDiffInSentenceInstance as daysDiffInSentenceFilter };

function DaysDiffShort(timeUtils) {
    return function (time) {
        return timeUtils.daysDiffShortString(time);
    };
}
angular.module('tonkean.app').filter('daysDiffShort', DaysDiffShort);
const daysDiffShortInstance = new DaysDiffShort(TimeUtils);
export { daysDiffShortInstance as daysDiffShortFilter };

function DaysDiffShorter(timeUtils) {
    return function (time) {
        return timeUtils.daysDiffShorterString(time);
    };
}
angular.module('tonkean.app').filter('daysDiffShorter', DaysDiffShorter);
const daysDiffShorterFilterInstance = new DaysDiffShorter(TimeUtils);
export { daysDiffShorterFilterInstance as DaysDiffShorterFilter };

function daysDiffNumber(timeUtils) {
    return function (time) {
        return Math.abs(timeUtils.daysDiff(time));
    };
}
angular.module('tonkean.app').filter('daysDiffNumber', daysDiffNumber);
const daysDiffNumberInstance = new daysDiffNumber(TimeUtils);
export { daysDiffNumberInstance as DaysDiffNumberFilter };

function daysDiffCalendarSentence(timeUtils) {
    return function (time) {
        return timeUtils.daysDiffCalendarString(time);
    };
}
angular.module('tonkean.app').filter('daysDiffCalendarSentence', daysDiffCalendarSentence);

function DaysHoursDiff(timeUtils) {
    return function (time) {
        const diffDays = timeUtils.daysDiffString(time);

        const date = new Date(time);

        const min = date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;

        const result = `${diffDays}, at ${date.getHours()}:${min}`;
        return result;
    };
}
angular.module('tonkean.app').filter('daysHoursDiff', DaysHoursDiff);
const daysHoursDiffFilterInstance = new DaysHoursDiff(TimeUtils);
export { daysHoursDiffFilterInstance as DaysHoursDiffFilter };
