import botStatusExampleImage from '../../images/BOT_StatusExample.png';
import {
    addButtonBlockCreateAfterOption,
    addNlpBranchBlockCreateAfterOption,
    formAnsweredTriggerParamsEnricher,
    innerItemLogicBlockConfigs,
    logicBlockTypes,
    logicConfigModes,
} from '@tonkean/logic-block-configs';

function Consts(environment) {
    /** Free trial length **/
    this.trialDaysLength = 7;

    /**
     * The data types supported.
     * @type {*[]}
     */
    const dataTypeOptions = [
        {
            value: 0,
            label: 'Fixing bugs',
            peopleLabel: 'Bug Fixers',
            peopleTypes: ['BUGS_FIXER'],
        },
        {
            value: 1,
            label: 'Feature Development',
            peopleLabel: 'Feature Developers',
            peopleTypes: ['FEATURES_WRITER'],
        },
    ];

    // Template for new integration creation
    /* eslint-disable no-unused-vars */
    const integrationTemplate = {
        importSettings: [],
        importIndexOnly: true,
        allEntitiesSupportedIntegrations: false,
        integrationSupportsCustomOwnerFields: false,
        integrationSupportsChangingFieldType: false,
        syncSettings: {},
        fieldSettings: [],
        integrationCompilerType: 'Elastic',
        globalMetricSettings: [],
        externalStatusUpdateSettings: {},
        createDateSettings: '',
        fieldOptions: [],
        filterOptions: [],
        integrationRequireMap: '',
    };
    /* eslint-disable no-unused-vars */

    /**
     * How many items do we allow to sync into Tonkean.
     */
    const syncItemsLimitation = 2_000_000;

    const updateViewingGroupInterval = 5 * 60 * 1000; // 5 minutes.

    const reportTypesSettings = {
        List: 'LIST',
        Status: 'STATUS',
        Function: 'FUNCTION',
        Tag: 'TAG',
        'Due/ETA': 'DUE_OR_ETA',
        Custom: 'CUSTOM',
    };

    const daysOfWeekSettings = {
        Mon: 'MONDAY',
        Tue: 'TUESDAY',
        Wed: 'WEDNESDAY',
        Thu: 'THURSDAY',
        Fri: 'FRIDAY',
        Sat: 'SATURDAY',
        Sun: 'SUNDAY',
    };

    const monthsOfYearSettings = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
    };

    const initiativeRelatedHighlightTypes = ['WARN_CHANGE', 'DUE_DATE', 'ETA', 'RANGE_CHANGE', 'EXTERNAL_DATE'];

    const tips = {
        createFirst: {
            title: 'Staying up to date',
            text: 'Creating Tracks gives you *super powers*!\n\nEvery *Track* you create should represent an initiative, of any kind, that you want to get updates for.\n\nSimply write those down, set an owner, and Tonkean will reach out to them for you to make sure this view is always up to date!\n\nYou can also define _Inner Tracks_ to represent inner initiatives - as easy as a bullet list!',
        },
        functions: {
            title: 'Functions',
            text: 'Functions are the *teams/departments* taking part of the work you and your team are doing (ex: Marketing, Sales, Front-End team, etc..).\n\nEvery item you track can be associate with a function that will own it and update its status.',
        },
        newItem: {
            title: 'Owners and Due date',
            text: 'Awesome! we are getting closer! :)\n\nSetting an *owner/function* is critical to enable the “Smart tracking”.\n\nThis is what allows Tonkean to *know who to reach out to*, when it’s time, for the latest status.',
        },
        bot: {
            title: 'Add Tonkean to Slack',
            text: "Tonkean is a wonderful friend.\n\nIt makes your life so much easier! it will *reach out* to the tracks owners and gather the latest status of everything, by itself.\n\nSo you won't have to nag anymore! :)",
        },
        import: {
            title: 'Import Tracks',
            text: 'You can import high-level items from your external systems.\n\nFor example: importing your *customers* (from your CRM) or your product\'s *milestones / epics* (from your Task Management system) will become "Tracks" automatically and kept up to date!',
        },
        fields: {
            title: 'Data Columns',
            text: 'Add the columns for the metrics you care about, and connect them to your tools to enable *Live Data*.\n\nTonkean will keep it up-to-date, monitor it and alert the owner if the value dramatically change.\n\nFor example: Add a *Revenue* field and connect it to Salesforce (or other CRM) or add a *Score* field and connect to your own database!',
        },
        filterBar: {
            title: 'Filter',
            text: 'Click Filter to open the "filter bar". From the filter bar, you can easily filter *across lists* by status, functions and tags!',
        },
    };

    // For bots use
    const slackIntegrationScopes = 'chat:write:bot,bot,users:read,users:read.email,identify,commands';
    const slackIntegrationScopesV2 =
        'chat:write,chat:write.public,chat:write.customize,commands,groups:history,channels:history,channels:read,groups:read,im:history';
    const slackIntegrationUserScopes = 'chat:write,users:read,users:read.email';

    // For App use (..as data source)
    const slackAppIntegrationScopes =
        'chat:write:bot,bot,users:read,users:read.email,identify,commands,channels:write,channels:read,groups:write';
    const slackAppIntegrationScopesV2 =
        'chat:write,chat:write.public,chat:write.customize,commands,channels:read,groups:read,reactions:write,usergroups:read,usergroups:write,channels:history,groups:history,im:history';
    const slackAppIntegrationUserScopes =
        'chat:write,users:read,users:read.email,channels:write,channels:read,groups:read,reactions:write,usergroups:read,usergroups:write,groups:write';
    const slackAppIntegrationScopesV2New =
        'chat:write,chat:write.public,chat:write.customize,commands,channels:read,groups:read,reactions:write,usergroups:read,usergroups:write,channels:history,groups:history,im:history,reactions:read';
    const slackAppIntegrationUserScopesNew =
        'chat:write,users:read,users:read.email,channels:write,channels:read,groups:read,reactions:write,usergroups:read,usergroups:write,groups:write,bookmarks:write,bookmarks:read,pins:write,reactions:read';

    // For login use
    const slackLoginUserScopes = 'users:read';

    const googleAnalyticsLoginScope = 'https://www.googleapis.com/auth/analytics.readonly';

    const googleCalendarLoginScope = 'https://www.googleapis.com/auth/calendar';

    const gmailLoginScope = 'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/userinfo.email';

    let apiUrl;

    let appUrl;

    const usecases = {
        // The first array item is used when the user selected only one option. The second array item is used when combining options.
        titles: {
            CUSTOMER_SUCCESS: ['top customers or deals', 'top customers'],
            PRODUCT_ECOSYSTEM: ['upcoming product milestones or campaigns', 'upcoming product milestones'],
            OPERATIONS: ['administrative or recruiting initiatives', ' general initiatives'],
        },
    };

    const botStates = {
        LIVE: {
            id: 'LIVE',
            label: 'module live',
            css: 'mod-live',
            title: "👋\u00A0\u00A0 Hi, I'm the Tonkean Module and I'm live!\nI will ping Owners for you to get updates on next steps.",
            body: '',
            image: botStatusExampleImage,
        },
        ANALYZING: {
            id: 'ANALYZING',
            label: 'analyzing',
            css: 'mod-loading mod-analyzing',
            title: 'Analyzing your data',
            body: 'This will take few seconds, after which your Tonkean module will be activated.',
        },
        NO_OWNER: {
            id: 'NO_OWNER',
            label: 'no owner',
            css: 'mod-grey',
            title: "👋\u00A0\u00A0 Hi, I'm the Tonkean BOT!\nMy job is to get you updates from owners on the things you want to track. Assign owners to activate me!",
            body: '',
            image: botStatusExampleImage,
        },
        PAUSED: {
            id: 'PAUSED',
            label: 'module inactive',
            css: 'mod-grey',
            title: 'Tonkean module is paused',
            body: 'Your team members are not being asked for an update.',
        },
        LOADING: {
            id: 'LOADING',
            label: ' ',
            css: 'mod-loading',
        },
    };

    const planIntervals = {
        month: 'month',
        year: 'year',
    };

    const countries = {
        AF: 'Afghanistan',
        AX: 'Åland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua and Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia, Plurinational State of',
        BQ: 'Bonaire, Sint Eustatius and Saba',
        BA: 'Bosnia and Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, the Democratic Republic of the',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: "Côte d'Ivoire",
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Curaçao',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island and McDonald Islands',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran, Islamic Republic of',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KP: "Korea, Democratic People's Republic of",
        KR: 'Korea, Republic of',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: "Lao People's Democratic Republic",
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia, the former Yugoslav Republic of',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia, Federated States of',
        MD: 'Moldova, Republic of',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territory',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Réunion',
        RO: 'Romania',
        RU: 'Russian Federation',
        RW: 'Rwanda',
        BL: 'Saint Barthélemy',
        SH: 'Saint Helena, Ascension and Tristan da Cunha',
        KN: 'Saint Kitts and Nevis',
        LC: 'Saint Lucia',
        MF: 'Saint Martin (French part)',
        PM: 'Saint Pierre and Miquelon',
        VC: 'Saint Vincent and the Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome and Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten (Dutch part)',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia and the South Sandwich Islands',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard and Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan, Province of China',
        TJ: 'Tajikistan',
        TZ: 'Tanzania, United Republic of',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad and Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks and Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UM: 'United States Minor Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela, Bolivarian Republic of',
        VN: 'Viet Nam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.S.',
        WF: 'Wallis and Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe',
    };

    const usaStates = {
        AL: 'Alabama',
        AK: 'Alaska',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District Of Columbia',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
    };

    const conditionBlockTypes = {
        ALWAYS: {
            type: 'ALWAYS',
            title: 'Always',
            iconClass: 'mod-always',
        },
        FILTER: {
            type: 'FILTER',
            title: 'Add Condition',
            iconClass: 'mod-condition',
        },
    };

    const defaultLogicName = 'Action Block';

    const autoFillOwnerFields = {
        HUBSPOT: {
            Deal: 'Deal owner-Email',
            Company: 'hubspot_owner_id',
        },
    };

    /**
     * How many initiatives should we fetch at first, for components where scrolling to bottom of page is the trigger for loading more initiatives.
     */
    const scrollableInitiativesPageSize = 50;
    this.getScrollableInitiativesPageSize = function () {
        return scrollableInitiativesPageSize;
    };

    /**
     * How many initiatives should we fetch at a single page, when we're fetching all pages of initiatives.
     */
    const fetchAllInitiativesPageSize = 250;
    this.getFetchAllInitiativesPageSize = function () {
        return fetchAllInitiativesPageSize;
    };

    this.getLogicBlockTypes = function () {
        return logicBlockTypes;
    };

    this.getInnerItemLogicBlockTypes = function () {
        return innerItemLogicBlockConfigs;
    };

    this.logicTypeToConfigurationMap = undefined;
    /**
     * Creates a map between logic type configuration type to the configuration object.
     * Includes the secondary custom trigger type, if none exists the default would be in the 'UNKNOWN' key
     * logicTypeToConfigurationMap[type][secondaryType] or logicTypeToConfigurationMap[type]['UNKNOWN']
     */
    this.getLogicBlockTypesMap = function () {
        if (!this.logicTypeToConfigurationMap) {
            const logicTypeToConfigurationMap = {};
            // Gathering all logic type configurations together.
            const allLogicTypeConfigurations = [];
            const blockTypes = this.getLogicBlockTypes();
            for (const key in blockTypes) {
                if (blockTypes.hasOwnProperty(key)) {
                    allLogicTypeConfigurations.push(blockTypes[key]);
                }
            }

            // Creating a map of the logic type configurations array.
            for (const logicTypeConfiguration of allLogicTypeConfigurations) {
                if (!logicTypeToConfigurationMap[logicTypeConfiguration.type]) {
                    logicTypeToConfigurationMap[logicTypeConfiguration.type] = {};
                }
                logicTypeToConfigurationMap[logicTypeConfiguration.type][
                    logicTypeConfiguration.secondaryType || 'UNKNOWN'
                ] = logicTypeConfiguration;

                // Always have a config in the undefined position, preserving the logic with going over getLogicBlockTypes
                logicTypeToConfigurationMap[logicTypeConfiguration.type][undefined] = logicTypeConfiguration;
            }

            this.logicTypeToConfigurationMap = logicTypeToConfigurationMap;
        }

        return this.logicTypeToConfigurationMap;
    };

    this.getAddButtonBlockCreateAfterOption = function () {
        return addButtonBlockCreateAfterOption;
    };

    this.getAddNlpBranchBlockCreateAfterOption = function () {
        return addNlpBranchBlockCreateAfterOption;
    };

    this.getDefaultLogicName = function () {
        return defaultLogicName;
    };

    this.getLogicConfigModes = function () {
        return logicConfigModes;
    };

    this.getAutoFillOwnerFieldsSuggestions = function () {
        return autoFillOwnerFields;
    };

    this.getConditionBlockTypes = function () {
        return conditionBlockTypes;
    };

    this.getTips = function () {
        return tips;
    };

    this.getSyncItemsLimitation = function () {
        return syncItemsLimitation;
    };

    this.getReportTypesSettings = function () {
        return reportTypesSettings;
    };

    this.getDaysOfWeekSettings = function () {
        return daysOfWeekSettings;
    };

    this.getMonthsOfYearSettings = function () {
        return monthsOfYearSettings;
    };

    this.getHoursOfTheDay = () => {
        const hoursOfTheDay = [];

        for (let i = 0; i <= 11; i++) {
            hoursOfTheDay[i] = {
                label: `${i === 0 ? 12 : i} AM`,
                value: i,
            };
        }

        for (let i = 0; i <= 11; i++) {
            hoursOfTheDay[i + 12] = {
                label: `${i === 0 ? 12 : i} PM`,
                value: i + 12,
            };
        }

        return hoursOfTheDay;
    };

    this.getInitiativeRelatedHighlightTypes = function () {
        return initiativeRelatedHighlightTypes;
    };

    this.getUsecasesTitles = function () {
        return angular.copy(usecases.titles);
    };

    this.getBotStates = function () {
        return angular.copy(botStates);
    };

    this.getSlackIntegrationScopes = function () {
        return angular.copy(slackIntegrationScopes);
    };

    this.getSlackIntegrationScopesV2 = function () {
        return angular.copy(slackIntegrationScopesV2);
    };

    this.getSlackIntegrationUserScopes = function () {
        return angular.copy(slackIntegrationUserScopes);
    };

    this.getSlackAppIntegrationScopes = function () {
        return angular.copy(slackAppIntegrationScopes);
    };

    this.getSlackAppIntegrationScopesV2New = function () {
        return angular.copy(slackAppIntegrationScopesV2New);
    };

    this.slackAppIntegrationUserScopesNew = function () {
        return angular.copy(slackAppIntegrationUserScopesNew);
    };

    this.getSlackAppIntegrationScopesV2 = function () {
        return angular.copy(slackAppIntegrationScopesV2);
    };

    this.getSlackAppIntegrationUserScopes = function () {
        return angular.copy(slackAppIntegrationUserScopes);
    };

    this.getSlackLoginUserScopes = function () {
        return angular.copy(slackLoginUserScopes);
    };

    this.getGoogleAnalyticsScopes = function () {
        return angular.copy(googleAnalyticsLoginScope);
    };

    this.getGoogleCalendarScopes = function () {
        return angular.copy(googleCalendarLoginScope);
    };

    this.getGmailScopes = function () {
        return angular.copy(gmailLoginScope);
    };

    this.getPlanIntervals = function () {
        return angular.copy(planIntervals);
    };

    this.getCountries = function () {
        return angular.copy(countries);
    };

    this.getUsaStates = function () {
        return angular.copy(usaStates);
    };

    this.getUpdateViewingGroupInterval = function () {
        return updateViewingGroupInterval;
    };

    /**
     * Gets a new copy of the dataTypeOptions array.
     * @return {[]} An array with all the lenses.
     */
    this.getDataTypeOptions = function () {
        return angular.copy(dataTypeOptions);
    };

    /**
     * Gets the base url for the api
     * @return {string} The url with a / at the end.
     */
    this.getAppUrl = function () {
        return environment.appUrl;
    };

    /**
     * Gets the base url for the marketplace discovery page
     * @return {string} The url with a / at the end.
     */
    this.getMarketplaceUrl = function () {
        return environment.marketplaceUrl;
    };

    const TriggerActivityActionTypes = [
        {
            activityTypeDisplayName: 'Worker Turned On/Off',
            activityType: 'WORKER_ENABLED_STATE_CHANGED',
            activityTypeID: 43,
        },
        {
            activityTypeDisplayName: 'Logic/Trigger Block Turned On/Off',
            activityType: 'TRIGGER_ENABLED_STATE_CHANGED',
            activityTypeID: 44,
        },
        {
            activityTypeDisplayName: 'Logic/Trigger Configuration Change',
            activityType: 'CUSTOM_TRIGGER_ACTION_DEFINITION_CHANGED',
            activityTypeID: 45,
        },
        {
            activityTypeDisplayName: 'Display Name Change',
            activityType: 'CUSTOM_TRIGGER_DISPLAY_NAME_CHANGED',
            activityTypeID: 46,
        },
        {
            activityTypeDisplayName: 'Post-State Change',
            activityType: 'CUSTOM_TRIGGER_POST_STATE_CHANGED',
            activityTypeID: 47,
        },
        {
            activityTypeDisplayName: 'Logic/Trigger Creation',
            activityType: 'CUSTOM_TRIGGER_CREATED',
            activityTypeID: 48,
        },
        {
            activityTypeDisplayName: 'Logic/Trigger Deletion',
            activityType: 'CUSTOM_TRIGGER_DELETED',
            activityTypeID: 49,
        },
        {
            activityTypeDisplayName: 'Logic/Trigger Duplication',
            activityType: 'CUSTOM_TRIGGER_DUPLICATED',
            activityTypeID: 50,
        },
        {
            activityTypeDisplayName: 'Auto Check-Ins Turned On/Off',
            activityType: 'GROUP_SHOULD_GATHER_UPDATES',
            activityTypeID: 51,
        },
        {
            activityTypeDisplayName: 'Live Report Turned On/Off',
            activityType: 'GROUP_IS_DASHBOARD_HIDDEN_TOGGLED',
            activityTypeID: 52,
        },
        {
            activityTypeDisplayName: 'Logic/Trigger Hidden State Change',
            activityType: 'CUSTOM_TRIGGER_HIDDEN_STATE_CHANGE',
            activityTypeID: 53,
        },
        {
            activityTypeDisplayName: 'Data Source Changed To Manual',
            activityType: 'GROUP_DATA_SOURCE_CHANGED_TO_MANUAL',
            activityTypeID: 54,
        },
        {
            activityTypeDisplayName: 'Data Source Removed',
            activityType: 'GROUP_DATA_SOURCE_REMOVED',
            activityTypeID: 55,
        },
        {
            activityTypeDisplayName: 'Data Source Changed To Scheduled Worker',
            activityType: 'DATA_SOURCE_CHANGED_TO_SCHEDULED',
            activityTypeID: 56,
        },
        {
            activityTypeDisplayName: 'Data Source Changed To Integration',
            activityType: 'DATA_SOURCE_CHANGED_TO_INTEGRATION',
            activityTypeID: 57,
        },
        {
            activityTypeDisplayName: 'Data Source Integration Configuration Changed',
            activityType: 'INTEGRATION_DATA_SOURCE_CONFIGURATION_CHANGED',
            activityTypeID: 58,
        },

        {
            activityTypeDisplayName: 'Field Definition Created',
            activityType: 'FIELD_DEFINITION_CREATED',
            activityTypeID: 59,
        },
        {
            activityTypeDisplayName: 'Field Definition Updated',
            activityType: 'FIELD_DEFINITION_UPDATED',
            activityTypeID: 60,
        },
        {
            activityTypeDisplayName: 'Field Definition Deleted',
            activityType: 'FIELD_DEFINITION_DELETED',
            activityTypeID: 61,
        },
        {
            activityTypeDisplayName: 'Form Created',
            activityType: 'FORM_CREATED',
            activityTypeID: 62,
        },
        {
            activityTypeDisplayName: 'Form Updated',
            activityType: 'FORM_UPDATED',
            activityTypeID: 63,
        },
        {
            activityTypeDisplayName: 'Form Deleted',
            activityType: 'FORM_DELETED',
            activityTypeID: 64,
        },
        {
            activityTypeDisplayName: 'Module Statuses Changed',
            activityType: 'STATES_CHANGED',
            activityTypeID: 65,
        },
        {
            activityTypeDisplayName: 'Flow Structure Changed',
            activityType: 'WORKER_CUSTOM_TRIGGER_GRAPH_CHANGED',
            activityTypeID: 66,
        },
        {
            activityTypeDisplayName: 'Trained Keyword Added',
            activityType: 'TRAINED_KEYWORD_ADDED',
            activityTypeID: 67,
        },
        {
            activityTypeDisplayName: 'Trained Keyword Deleted',
            activityType: 'TRAINED_KEYWORD_DELETED',
            activityTypeID: 68,
        },
        {
            activityTypeDisplayName: 'Scheduled Worker Configuration Changed',
            activityType: 'SCHEDULED_WORKER_CONFIGURATION_CHANGED',
            activityTypeID: 69,
        },
        {
            activityTypeDisplayName: 'Field Configuration Change',
            activityType: 'FIELD_GRAPH_UPDATE',
            activityTypeID: 70,
        },
        {
            activityTypeDisplayName: 'Build Environment Turned On/Off',
            activityType: 'BUILD_ENVIRONMENT_ENABLED_STATE_CHANGED',
            activityTypeID: 71,
        },
        {
            activityTypeDisplayName: 'Created Module From Template',
            activityType: 'CREATED_BOT_FROM_TEMPLATE',
            activityTypeID: 72,
        },
        {
            activityTypeDisplayName: 'Field Shown/Hidden in Live Report',
            activityType: 'LIVE_REPORT_FIELD_IS_HIDDEN_UPDATED',
            activityTypeID: 73,
        },
        {
            activityTypeDisplayName: 'Reordered Field in Live Report',
            activityType: 'LIVE_REPORT_FIELD_REORDER',
            activityTypeID: 74,
        },
        {
            activityTypeDisplayName: 'Created Module From Library Template',
            activityType: 'MODULE_CREATED_FROM_MARKETPLACE',
            activityTypeID: 86,
        },
        {
            activityTypeDisplayName: 'Data Source Changed To Forms',
            activityType: 'GROUP_DATA_SOURCE_CHANGED_TO_FORMS',
            activityTypeID: 94,
        },
    ];

    this.getTriggerActivityActionTypes = function () {
        return TriggerActivityActionTypes;
    };

    this.getFormAnsweredTriggerParamsEnricher = formAnsweredTriggerParamsEnricher;
}

export default angular.module('tonkean.shared').service('consts', Consts);
export const consts = new Consts();
