import React, { useEffect, useState } from 'react';

import ImageWidgetDisplay from './ImageWidgetDisplay';
import StreamActionImageWidgetDisplay from './StreamActionImageWidgetDisplay';
import type { ItemWidgetProps } from '../../../WidgetModule';
import { ImageInputType } from '../../entities';
import type ImageWidgetConfiguration from '../ImageWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useInitiativeExpressionServerEvaluation, useLastDefinedValue } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { isUrlValid } from '@tonkean/utils';

const ImageWidget: React.FC<ItemWidgetProps<ImageWidgetConfiguration>> = ({
    widget,
    permission,
    workflowVersionId,
}) => {
    const { initiative } = useItemInterfaceContext();
    const [isUrlExpressionValid, setIsUrlExpressionValid] = useState<boolean>(false);
    const {
        values: [urlExpression],
        loading: loadingExpressions,
    } = useInitiativeExpressionServerEvaluation(
        widget?.configuration?.image?.url ? [widget?.configuration?.image?.url] : [],
        initiative,
    );

    const lastUrlExpression = useLastDefinedValue<string | undefined>(urlExpression);
    const enableStreamImageFeature = useFeatureFlag('tonkean_enable_stream_service_features');

    useEffect(() => {
        setIsUrlExpressionValid(
            (!!lastUrlExpression &&
                isUrlValid(lastUrlExpression) &&
                lastUrlExpression?.match(/\.(jpeg|jpg|png)$/) != null) ||
                widget?.configuration.imageInputType == ImageInputType.IMAGE_FROM_ACTION,
        );
    }, [lastUrlExpression, widget?.configuration.imageInputType]);

    return (
        <>
            {enableStreamImageFeature && widget?.configuration?.imageInputType === ImageInputType.IMAGE_FROM_ACTION ? (
                <StreamActionImageWidgetDisplay
                    permission={permission}
                    altText={widget?.configuration?.image?.altText}
                    url={lastUrlExpression}
                    isUrlExpressionValid={isUrlExpressionValid}
                    originalExpression={widget?.configuration?.image?.url?.originalExpression}
                    loadingExpressions={loadingExpressions}
                    imageStreamSelectedActionId={widget?.configuration?.imageStreamSelectedActionId}
                    imageStreamSelectedProjectIntegration={widget?.configuration?.imageStreamSelectedProjectIntegration}
                    imageExternalIdentifier={widget?.configuration?.imageExternalIdentifier}
                />
            ) : (
                <ImageWidgetDisplay
                    permission={permission}
                    altText={widget?.configuration?.image?.altText}
                    url={lastUrlExpression}
                    isUrlExpressionValid={isUrlExpressionValid}
                    originalExpression={widget?.configuration?.image?.url?.originalExpression}
                    loadingExpressions={loadingExpressions}
                />
            )}
        </>
    );
};

export default ImageWidget;
