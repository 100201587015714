import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationFrontDoorEntities from './ProjectIntegrationFrontDoorEntities';
import ProjectIntegrationFrontDoorEntitiesLoadingState from './ProjectIntegrationFrontDoorEntitiesLoadingState';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { SimpleErrorStateMessage } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const TableGrid = styled.div`
    display: grid;
    grid-column-gap: 30px;
    align-items: center;

    grid-template-columns: 220px minmax(70px, 140px) 28px 28px 50px;
`;

const TableHeader = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.XSMALL_10};
`;

const Divider = styled.hr`
    margin: 8px 0;
    grid-column: 1/-1;
`;

interface Props {
    projectIntegration: ProjectIntegration;
}

const ProjectIntegrationFrontDoorTable: React.FC<Props> = ({ projectIntegration }) => {
    const { data, loading, error } = useTonkeanService(
        'getProjectIntegrationSmartSearchAccessSettings',
        projectIntegration.id,
    );

    return (
        <TableGrid>
            <TableHeader>Entity Name</TableHeader>
            <TableHeader>Allow Access</TableHeader>
            <TableHeader>Filters</TableHeader>
            <TableHeader>Delete</TableHeader>
            <TableHeader />
            <Divider />
            {data && (
                <ProjectIntegrationFrontDoorEntities
                    initialEntities={data.entities}
                    projectIntegration={projectIntegration}
                />
            )}
            {loading && <ProjectIntegrationFrontDoorEntitiesLoadingState />}
            {error && <SimpleErrorStateMessage error={error} showSmallError />}
        </TableGrid>
    );
};

export default ProjectIntegrationFrontDoorTable;
