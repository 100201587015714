import type React from 'react';

import type SpecificEditorProps from './SpecificEditorProps';
import SpecificEditorFindWords from '../modules/SpecificEditorFindWordsModule/SpecificEditorFindWords';
import RegexViewer from '../modules/SpecificEditorRegexModule/RegexViewer';

import type { formulaOperatorsList } from '@tonkean/forumla-operators';
import { OperatorKey } from '@tonkean/tonkean-entities';

type OmitOperatorsWithoutSpecificEditor<T> = T extends { specificEditor: true } ? T : never;
type OperatorsWithSpecificEditor = OmitOperatorsWithoutSpecificEditor<(typeof formulaOperatorsList)[number]>['key'];

const operandKeyToSpecificEditor: Record<OperatorsWithSpecificEditor, React.FC<SpecificEditorProps>> = {
    [OperatorKey.REGEX_FIND]: RegexViewer,
    [OperatorKey.FIND_WORDS]: SpecificEditorFindWords,
};

export default operandKeyToSpecificEditor;
