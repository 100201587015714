import { useAngularService } from 'angulareact';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ProjectIntegrationCollectStatusCollectErrorOccurred from './ProjectIntegrationCollectStatusCollectErrorOccurred';
import ProjectIntegrationCollectStatusCollectInvalidConfiguration from './ProjectIntegrationCollectStatusCollectInvalidConfiguration';
import ProjectIntegrationCollectStatusCollectIsDisabled from './ProjectIntegrationCollectStatusCollectIsDisabled';
import ProjectIntegrationCollectStatusCollectNow from './ProjectIntegrationCollectStatusCollectNow';
import ProjectIntegrationCollectStatusCollectPending from './ProjectIntegrationCollectStatusCollectPending';
import ProjectIntegrationCollectStatusCollectRequestSent from './ProjectIntegrationCollectStatusCollectRequestSent';
import ProjectIntegrationCollectStatusCollectSuccessfullyExecuted from './ProjectIntegrationCollectStatusCollectSuccessfullyExecuted';
import ProjectIntegrationCollectStatusInProgress from './ProjectIntegrationCollectStatusInProgress';
import ProjectIntegrationCollectStatusLastCollectTime from './ProjectIntegrationCollectStatusLastCollectTime';
import ProjectIntegrationCollectStatusMaxPages from './ProjectIntegrationCollectStatusMaxPages';
import ProjectIntegrationCollectStatusNextCollectTime from './ProjectIntegrationCollectStatusNextCollectTime';
import ProjectIntegrationCollectStatusStopped from './ProjectIntegrationCollectStatusStopped';
import ProjectIntegrationSubEntityCollectNotConfigured from './ProjectIntegrationSubEntityCollectNotConfigured';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { IntegrationEntityType, ProjectIntegration } from '@tonkean/tonkean-entities';
import { CollectStatusType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const CollectStatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${FontSize.SMALL_12};
`;

const StyledProjectIntegrationCollectStatusLastCollectTime = styled(ProjectIntegrationCollectStatusLastCollectTime)`
    margin-top: 8px;
`;

const StyledCollectStatus = styled.div`
    display: flex;
    margin-bottom: 8px;
`;

const StyledProjectIntegrationCollectStatusCollectNow = styled.div`
    margin-left: 8px;
`;

const NoSelectedEntityPlaceholder = styled.div`
    display: flex;
    color: ${Theme.colors.gray_700};
`;

const LoadingCollectDataPlaceholder = styled.div`
    display: flex;
    color: ${Theme.colors.gray_700};
`;

interface Props {
    projectIntegration: ProjectIntegration;
    selectedEntity?: IntegrationEntityType;
    onCollectCompleted?: (projectIntegration: ProjectIntegration) => void;
}

const COLLECT_STATUS_POLLING_INTERVAL_MILLISECONDS = 5000;

const ProjectIntegrationCollectStatus: React.FC<Props> = ({
    projectIntegration,
    selectedEntity,
    onCollectCompleted = () => {},
}) => {
    const [pollingLastUpdatedTime, setPollingLastUpdatedTime] = useState(() => new Date());
    const tonkeanService = useAngularService('tonkeanService');
    const [collectStatus, setCollectStatus] = useState<CollectStatusType>();
    const [isCollectEnabledForCurrentEntity, setIsCollectEnabledForCurrentEntity] = useState<boolean>(true);
    const [currentProjectIntegration, setCurrentProjectIntegration] = useState<ProjectIntegration>(projectIntegration);

    const [{ data: collectStatusResponse }, getProjectIntegrationCollectStatus] = useLazyTonkeanService(
        'getProjectIntegrationCollectStatus',
    );

    const currentEntityCollectStatus = useMemo(() => {
        if (selectedEntity) {
            return collectStatusResponse?.entityIdToCollectStatusMap[selectedEntity.entity];
        }
    }, [selectedEntity, collectStatusResponse]);

    const disableManualUpdateButton = useMemo(() => {
        return (
            collectStatus === CollectStatusType.REQUEST_SENT ||
            !isCollectEnabledForCurrentEntity ||
            projectIntegration.disabled ||
            collectStatus === CollectStatusType.SUB_ENTITY_COLLECT_NOT_CONFIGURED
        );
    }, [collectStatus, isCollectEnabledForCurrentEntity, projectIntegration.disabled]);

    const onCollectNowRequestStarted = useCallback(() => {
        setCollectStatus(CollectStatusType.REQUEST_SENT);
    }, []);

    // The collect is queued if the last collect time passed and we're not collecting yet
    const isCollectPending = useCallback(
        (
            currentCollectStatus: CollectStatusType | undefined,
            collectedProjectIntegration: ProjectIntegration,
            isCollectEnabled?: boolean,
        ) =>
            currentCollectStatus &&
            [CollectStatusType.IDLE, CollectStatusType.PENDING].includes(currentCollectStatus) &&
            collectedProjectIntegration.nextCollect &&
            isCollectEnabled &&
            dayjs(collectedProjectIntegration.nextCollect).isBefore(dayjs()),
        [],
    );

    // If we passed the last collect time, show pending
    useEffect(() => {
        if (isCollectPending(collectStatus, currentProjectIntegration, currentEntityCollectStatus?.isCollectEnabled)) {
            setCollectStatus(CollectStatusType.PENDING);
        }
    }, [isCollectPending, collectStatus, setCollectStatus, currentProjectIntegration, currentEntityCollectStatus]);

    useEffect(() => {
        if (currentEntityCollectStatus) {
            const { isCollectEnabled } = currentEntityCollectStatus;
            setIsCollectEnabledForCurrentEntity(isCollectEnabled != undefined ? isCollectEnabled : true);
        }
    }, [setIsCollectEnabledForCurrentEntity, currentEntityCollectStatus]);

    // Map status type and the component which displays it
    const collectStatusToComponentMap = {
        [CollectStatusType.IDLE]: (
            <ProjectIntegrationCollectStatusNextCollectTime
                nextCollectTime={currentProjectIntegration.nextCollect}
                lastUpdatedTime={pollingLastUpdatedTime}
            />
        ),
        [CollectStatusType.PENDING]: <ProjectIntegrationCollectStatusCollectPending />,
        [CollectStatusType.SUB_ENTITY_COLLECT_NOT_CONFIGURED]: <ProjectIntegrationSubEntityCollectNotConfigured />,
        [CollectStatusType.IN_PROGRESS]: (
            <ProjectIntegrationCollectStatusInProgress lastUpdatedTime={pollingLastUpdatedTime} />
        ),
        [CollectStatusType.STOPPED]: (
            <ProjectIntegrationCollectStatusStopped
                projectIntegrationId={currentProjectIntegration.id}
                onCollectNowRequestStarted={onCollectNowRequestStarted}
            />
        ),
        [CollectStatusType.REQUEST_SENT]: <ProjectIntegrationCollectStatusCollectRequestSent />,
        [CollectStatusType.ERROR_OCCURRED]: <ProjectIntegrationCollectStatusCollectErrorOccurred />,
        [CollectStatusType.INVALID_CONFIGURATION]: <ProjectIntegrationCollectStatusCollectInvalidConfiguration />,
        [CollectStatusType.SUCCESSFULLY_EXECUTED]: <ProjectIntegrationCollectStatusCollectSuccessfullyExecuted />,
        [CollectStatusType.MAX_PAGES_REACHED]: <ProjectIntegrationCollectStatusMaxPages />,
    };

    // Handle collect status change from API
    useEffect(() => {
        if (!collectStatusResponse) {
            return;
        }

        // If we finished a collect, call the callback with the project integration
        if (
            collectStatus &&
            currentEntityCollectStatus?.collectStatus === CollectStatusType.IDLE &&
            [CollectStatusType.IN_PROGRESS, CollectStatusType.PENDING, CollectStatusType.REQUEST_SENT].includes(
                collectStatus,
            ) &&
            !isCollectPending(
                currentEntityCollectStatus?.collectStatus,
                currentProjectIntegration,
                currentEntityCollectStatus?.isCollectEnabled,
            )
        ) {
            onCollectCompleted(currentProjectIntegration);
        }

        // Update saved status
        if (
            isCollectPending(
                currentEntityCollectStatus?.collectStatus,
                currentProjectIntegration,
                currentEntityCollectStatus?.isCollectEnabled,
            )
        ) {
            setCollectStatus(CollectStatusType.PENDING);
        } else {
            setCollectStatus(currentEntityCollectStatus?.collectStatus);
        }

        // Update the project integration
        setCurrentProjectIntegration({
            ...currentProjectIntegration,
            lastCollect: currentEntityCollectStatus?.lastCollectTime || null,
            nextCollect: currentEntityCollectStatus?.nextCollectTime || null,
            lastCollectFriendlyErrorMessage: currentEntityCollectStatus?.lastCollectFriendlyErrorMessage,
        });
        // Silencing linter for not adding currentProjectIntegration ad dependenct cause it will cause an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentEntityCollectStatus?.collectStatus,
        isCollectPending,
        collectStatusResponse,
        currentProjectIntegration.nextCollect,
        onCollectCompleted,
        collectStatus,
        currentEntityCollectStatus?.isCollectEnabled,
        currentEntityCollectStatus?.lastCollectTime,
        currentEntityCollectStatus?.nextCollectTime,
        currentEntityCollectStatus?.lastCollectFriendlyErrorMessage,
    ]);

    // Poll the collect status
    useEffect(() => {
        const timeout = setTimeout(async () => {
            const pollingStartTime = new Date();

            await getProjectIntegrationCollectStatus(currentProjectIntegration.id);

            setPollingLastUpdatedTime(pollingStartTime);
        }, COLLECT_STATUS_POLLING_INTERVAL_MILLISECONDS);

        return () => clearTimeout(timeout);
    }, [
        tonkeanService,
        pollingLastUpdatedTime,
        setPollingLastUpdatedTime,
        currentProjectIntegration.id,
        getProjectIntegrationCollectStatus,
    ]);

    // Make the first collect status request (polling start after a delay of COLLECT_STATUS_POLLING_INTERVAL_MILLISECONDS)
    useEffect(() => {
        getProjectIntegrationCollectStatus(projectIntegration.id);
    }, [getProjectIntegrationCollectStatus, projectIntegration.id]);

    return (
        <CollectStatusWrapper>
            {collectStatus && (
                <>
                    <StyledCollectStatus>
                        {isCollectEnabledForCurrentEntity && collectStatusToComponentMap[collectStatus]}

                        {!isCollectEnabledForCurrentEntity && <ProjectIntegrationCollectStatusCollectIsDisabled />}

                        <StyledProjectIntegrationCollectStatusCollectNow>
                            <ProjectIntegrationCollectStatusCollectNow
                                projectIntegrationId={projectIntegration.id}
                                onCollectNowRequestStarted={onCollectNowRequestStarted}
                                disabled={disableManualUpdateButton}
                            />
                        </StyledProjectIntegrationCollectStatusCollectNow>
                    </StyledCollectStatus>

                    <StyledProjectIntegrationCollectStatusLastCollectTime
                        updated={currentProjectIntegration.lastCollect}
                        lastUpdatedTime={pollingLastUpdatedTime}
                        error={currentProjectIntegration.lastCollectFriendlyErrorMessage}
                    />
                </>
            )}
            {selectedEntity && !collectStatus && (
                <LoadingCollectDataPlaceholder>
                    Loading collect data <span className="loading margin-left-xs" />
                </LoadingCollectDataPlaceholder>
            )}
            {!selectedEntity && (
                <NoSelectedEntityPlaceholder>
                    Please select an entity to see its collect status.
                </NoSelectedEntityPlaceholder>
            )}
        </CollectStatusWrapper>
    );
};

export default ProjectIntegrationCollectStatus;
