<div class="delete-field-definition-modal">
    <!-- Explanation message -->
    <div class="common-size-s margin-bottom-lg">
        Are you sure you want to delete
        <strong>{{ data.fieldDefinitionName }}</strong>
        ?
    </div>

    <!-- Delete & Cancel buttons -->
    <div class="flex-vmiddle margin-bottom">
        <!-- Delete button -->
        <button
            type="button"
            class="btn btn-danger margin-right-xs"
            ng-click="deleteFieldDefinition()"
            ng-disabled="data.deletingFieldDefinition"
        >
            Delete
        </button>

        <!-- Cancel button -->
        <button type="button" class="btn btn-default" ng-click="cancel()">Cancel</button>
    </div>

    <!-- Loading -->
    <div class="flex-vmiddle" ng-if="data.deletingFieldDefinition">
        <i class="loading-small margin-right"></i>
        <span>Deleting field definition...</span>
    </div>

    <!-- Error -->
    <span class="common-color-danger" ng-if="!data.deletingFieldDefinition && data.errorDeletingFieldDefinition">
        {{ data.errorDeletingFieldDefinition }}
    </span>
</div>
