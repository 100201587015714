import type { IntrospectionQuery } from 'graphql';
import type { IntrospectionField, IntrospectionObjectType } from 'graphql/utilities/getIntrospectionQuery';

export const mockMutationField: IntrospectionField = {
    name: 'mockMutationForCacheUpdates',
    description: null,
    args: [],
    type: {
        kind: 'NON_NULL',
        ofType: {
            kind: 'OBJECT',
            name: 'MockMutationForCacheUpdatesResponse',
        },
    },
    isDeprecated: false,
    deprecationReason: null,
};

export const mockMutationResponse: IntrospectionObjectType = {
    kind: 'OBJECT',
    name: 'MockMutationForCacheUpdatesResponse',
    description: null,
    fields: [
        {
            name: 'id',
            description: null,
            args: [],
            type: {
                kind: 'NON_NULL',
                ofType: {
                    kind: 'SCALAR',
                    name: 'ID',
                },
            },
            isDeprecated: false,
            deprecationReason: null,
        },
    ],
    interfaces: [],
};

const mutationObject: IntrospectionObjectType = {
    kind: 'OBJECT',
    name: 'Mutation',
    description: null,
    fields: [mockMutationField],
    interfaces: [],
};

function addMockMutationForCacheUpdateToSchema(schema: IntrospectionQuery): IntrospectionQuery {
    const mutationType = schema.__schema.mutationType ?? { name: 'Mutation', kind: 'OBJECT' };

    const fullOriginalMutationType = schema.__schema.types.find(
        (type): type is IntrospectionObjectType => type.kind === 'OBJECT' && type.name === mutationType.name,
    );
    const typesWithoutMutation = schema.__schema.types.filter((type) => type !== fullOriginalMutationType);
    const fullNewMutationType = {
        ...mutationObject,
        ...(fullOriginalMutationType ?? []),
        fields: [...(fullOriginalMutationType?.fields ?? []), mockMutationField],
    };
    const fullNewTypes = [...typesWithoutMutation, fullNewMutationType, mockMutationResponse];

    return {
        ...schema,
        __schema: {
            ...schema.__schema,
            mutationType,
            types: fullNewTypes,
        },
    };
}

export default addMockMutationForCacheUpdateToSchema;
