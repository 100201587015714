import { useAngularService } from 'angulareact';
import React, { useState } from 'react';
import styled from 'styled-components';

import BrokenDatasourceIndication from './BrokenDatasourceIndication';
import ProjectIntegrationsViewType from '../../../../../ProjectIntegrationsViewModule/entities/ProjectIntegrationsViewType';
import ProjectIntegrationsView from '../../../../../ProjectIntegrationsViewModule/ProjectIntegrationsView';

import { Input } from '@tonkean/infrastructure';
import type { IntegrationType, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationSummary } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

const StyledInput = styled(Input)`
    margin-bottom: 12px;
`;

const StyledClickableLink = styled(ClickableLink)`
    margin-top: 8px;
`;

interface Props {
    workflowVersion: WorkflowVersion;
    filterByIntegrationType?: IntegrationType[];
}

const ConnectNewDatasourceInputSource: React.FC<Props> = ({ workflowVersion, filterByIntegrationType }) => {
    const $timeout = useAngularService('$timeout');
    const $state = useAngularService('$state');
    const [selectedProjectIntegrationSummary, setSelectedProjectIntegrationSummary] = useState<
        ProjectIntegrationSummary | undefined
    >();

    const [projectIntegrationsTextFilter, setProjectIntegrationsTextFilter] = useState('');

    const changeProjectIntegration = (projectIntegrationSummary?: ProjectIntegrationSummary) => {
        setSelectedProjectIntegrationSummary(projectIntegrationSummary);
        $timeout(() => $state.go('.', { syncProjectIntegrationId: projectIntegrationSummary?.id }, { notify: false }));
    };

    return (
        <>
            {(!selectedProjectIntegrationSummary || selectedProjectIntegrationSummary.valid) && (
                <>
                    <StyledInput
                        placeholder="Search for a data source"
                        value={projectIntegrationsTextFilter}
                        onChange={(event) => setProjectIntegrationsTextFilter(event.target.value)}
                    />

                    <ProjectIntegrationsView
                        selectedProjectIntegrationId={$state.params.projectIntegration}
                        viewType={ProjectIntegrationsViewType.LIST}
                        filterByText={projectIntegrationsTextFilter}
                        filterByIntegrationType={filterByIntegrationType}
                        onClickConnectedDataSource={changeProjectIntegration}
                        isListFilterBySolutionAccess
                    />
                </>
            )}

            {selectedProjectIntegrationSummary && !selectedProjectIntegrationSummary.valid && (
                <>
                    <BrokenDatasourceIndication
                        workflowVersionId={workflowVersion.id}
                        projectIntegrationSummary={selectedProjectIntegrationSummary}
                    />
                    <StyledClickableLink onClick={() => changeProjectIntegration(undefined)}>
                        Select different data source
                    </StyledClickableLink>
                </>
            )}
        </>
    );
};

export default ConnectNewDatasourceInputSource;
