import React from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import ItemInterfaceActionsWidgetActionSelector from '../../ActionsWidgetModule/components/ItemInterfaceActionsWidgetActionSelector';
import type RichTextWidgetConfiguration from '../RichTextWidgetConfiguration';

import { Toggle } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 20px;
`;
const StyledToggle = styled(Toggle)`
    display: flex;
    justify-content: space-between;
    gap: 4px;
    margin-bottom: 10px;
`;

const ItemInterfaceRichTextWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<RichTextWidgetConfiguration>> = ({
    workflowVersion,
}) => {
    return (
        <Wrapper>
            <ConfigurationSectionTitle>Actions</ConfigurationSectionTitle>
            <StyledToggle
                name="configuration.showActionsAsMenu"
                labelBefore="Show actions as a menu"
                size={InputSize.SMALL}
            />
            <StyledToggle
                name="configuration.useAlternativeColors"
                labelBefore="Use alternative colors for button"
                size={InputSize.SMALL}
            />
            <ItemInterfaceActionsWidgetActionSelector workflowVersion={workflowVersion} />
        </Wrapper>
    );
};

export default ItemInterfaceRichTextWidgetConfigurationEditor;
