import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MultiFieldQuickSelectionCtrl($scope, projectManager, workflowVersionManager, syncConfigCacheManager) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        includeGlobalFields: ctrl.includeGlobalFields,
        actions: ctrl.customActions,
        specialFieldsForFeatures: ctrl.specialFieldsForFeatures,
        fields: ctrl.existingFields ? ctrl.existingFields : [{}],
        doNotIncludeFieldIdsMap: {},
        menuOpenIndex: -1,
    };

    ctrl.$onInit = function () {
        recalculateDoNotIncludeFields();
    };

    /**
     * Occurs once you select a field.
     * @param selectedField
     * @param index
     */
    $scope.onFieldOptionSelected = function (selectedField, index) {
        $scope.data.fields[index] = selectedField;
        $scope.onFieldsChange();
    };

    /**
     * Occurs once you remove a field.
     */
    $scope.removeField = function (index) {
        $scope.data.fields.splice(index, 1);

        $scope.onFieldsChange();
    };

    /**
     * Occurs once you move a field up.
     */
    $scope.moveFieldUp = function (index) {
        if (index === 0) {
            return; // ignore - can't move up
        }
        $scope.data.fields = moveArrayItemToNewIndex($scope.data.fields, index, index - 1);

        $scope.onFieldsChange();
    };

    /**
     * Occurs once you move a field down.
     */
    $scope.moveFieldDown = function (index) {
        if (index === $scope.data.fields.length - 1) {
            return; // ignore - can't move down
        }
        $scope.data.fields = moveArrayItemToNewIndex($scope.data.fields, index, index + 1);

        $scope.onFieldsChange();
    };

    /**
     * Adds a new field.
     */
    $scope.addField = function () {
        $scope.data.fields.push({});
    };

    /**
     * Occurs on fields change.
     */
    $scope.onFieldsChange = function () {
        recalculateDoNotIncludeFields();
        if (ctrl.onFieldsChange) {
            ctrl.onFieldsChange({ fields: $scope.data.fields });
        }
    };

    $scope.toggleMenu = function ($index) {
        $scope.data.menuOpenIndex = $scope.data.menuOpenIndex === $index ? -1 : $index;
    };

    function recalculateDoNotIncludeFields() {
        // Construct a map of {field id: true} to prevent duplicates between selectors
        $scope.data.doNotIncludeFieldIdsSet = $scope.data.fields
            .filter((field) => !!field)
            .reduce((map, field) => {
                map[field.id] = true;
                return map;
            }, {});
    }

    function moveArrayItemToNewIndex(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let index = new_index - arr.length + 1;
            while (index--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }
}

export default angular
    .module('tonkean.app')
    .controller('multiFieldQuickSelectionCtrl', lateConstructController(MultiFieldQuickSelectionCtrl));
