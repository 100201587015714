import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { WidgetConfigurationFieldSelector } from '../../CommonWidgetConfiguration';
import type { InnerItemWidgetConfiguration } from '../../entities';
import type { LineItemWidgetAggregationType, LineItemAggregation } from '../../entities';
import type { FieldsWidgetMenuItem } from '../../FieldsWidgetModule';
import { useGetWorkflowVersionFieldsAndBasicFields } from '../../hooks';
import { useWidgetConfigurationDeletedFields } from '../../hooks';
import LineItemsAddAggregationModal from '../../LineItemsWidgetModule/components/LineItemsAddAggregationModal';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { LoadingCircle, SimpleErrorStateMessage, useFormikField } from '@tonkean/infrastructure';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import {
    type FieldDefinition,
    FieldType,
    type Initiative,
    type TonkeanId,
    type TonkeanType,
} from '@tonkean/tonkean-entities';
import { ItemInterfaceWidgetConfigurationDisplayMode } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    configuration: InnerItemWidgetConfiguration;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    initiative: Initiative | undefined;
    fieldsAdditionalOptions?: FieldsWidgetMenuItem[];
    fieldDefinitions: FieldDefinition[] | undefined;
    titleFieldUnlocked?: boolean;
    supportRequiredFields?: boolean;
    supportAggregations?: boolean;
    fixedOrderForSpecialFields: boolean;
    supportCardsViewFields?: boolean;
    supportFieldWidth?: boolean;
}

const CardFieldsConfigurationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding-top: 16px;
    border-top: 1px solid ${Theme.colors.gray_300};
`;

const ItemsWidgetConfigurationEditorFieldSelector: React.FC<Props> = ({
    configuration,
    workflowVersionId,
    initiative,
    fieldsAdditionalOptions,
    titleFieldUnlocked,
    supportRequiredFields = false,
    supportAggregations = false,
    fieldDefinitions,
    fixedOrderForSpecialFields,
    supportCardsViewFields = true,
    supportFieldWidth = false,
}) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const {
        data: workflowVersion,
        loading: loadingWorkflowVersion,
        error: workflowVersionError,
    } = useAsyncMethod(workflowVersionManager, 'getCachedWorkflowVersionOrGetFromServer', workflowVersionId);

    const { loading, allFieldDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(workflowVersionId, [
        'ITEM_INTERFACE_INNER_ITEMS_WIDGET',
    ]);

    const [editedAggregationKeyAndNameAndType, setEditedAggregationKeyAndNameAndType] = useState<
        [FieldDefinitionKey, string, FieldType] | false
    >(false);

    const { setValue: setAggregations, value: aggregations } = useFormikField<LineItemAggregation[] | undefined>(
        'configuration.aggregations',
    );

    const deletedFieldsIdsTableView = useWidgetConfigurationDeletedFields(
        allFieldDefinitions,
        Object.keys(configuration.fields || {}),
    );

    const deletedFieldsIdsCardsView = useWidgetConfigurationDeletedFields(
        allFieldDefinitions,
        Object.keys(configuration.cardFields || {}),
    );

    // When the modal is open this field will be populated with the field aggregation configuration.
    const editableAggregationField = useMemo(() => {
        return aggregations?.find((agg) => agg.fieldDefinitionKey === editedAggregationKeyAndNameAndType[0]);
    }, [aggregations, editedAggregationKeyAndNameAndType]);

    const onSubmitAggregationModal = useCallback(
        (
            aggregation: LineItemWidgetAggregationType,
            title: string,
            outputFieldDefinitionId: FieldDefinition['id'] | undefined,
            hide: boolean | undefined,
        ) => {
            if (!editedAggregationKeyAndNameAndType) {
                // Shouldn't happen because only when the modal is open we call this callback
                return;
            }

            setAggregations((prevAggregations) => {
                return [
                    // For now, we support only 1 aggregation per fields therefore we filter out the old aggregation (if exists)
                    ...(prevAggregations || []).filter(
                        (agg) => agg.fieldDefinitionKey !== editedAggregationKeyAndNameAndType[0],
                    ),
                    {
                        aggregation,
                        fieldDefinitionKey: editedAggregationKeyAndNameAndType[0],
                        outputFieldDefinitionId,
                        title,
                        hide,
                    },
                ];
            });

            setEditedAggregationKeyAndNameAndType(false);
        },
        [editedAggregationKeyAndNameAndType, setAggregations],
    );

    const finalFieldAdditionalOptions = supportAggregations
        ? [
              ...(fieldsAdditionalOptions || EMPTY_ARRAY),
              {
                  dataAutomation: 'fields-widget-config-field-row-aggregation',
                  key: 'fields-widget-config-field-row-aggregation',
                  title: 'Show aggregate',
                  isDisabled: (fieldType) => {
                      return (
                          fieldType !== FieldType.Number &&
                          fieldType !== FieldType.Date &&
                          fieldType !== FieldType.String &&
                          fieldType !== FieldType.LongString
                      );
                  },

                  onClick: (fieldKey, fieldName, fieldType) => {
                      setEditedAggregationKeyAndNameAndType([fieldKey, fieldName, fieldType]);
                  },
              },
          ]
        : fieldsAdditionalOptions;

    if (loading || loadingWorkflowVersion) {
        return <LoadingCircle large centered />;
    }

    if (workflowVersionError) {
        return <SimpleErrorStateMessage error={workflowVersionError} showSmallError />;
    }

    const shouldShowCardsViewFields =
        supportCardsViewFields &&
        (configuration.displayMode === ItemInterfaceWidgetConfigurationDisplayMode.AUTO ||
            configuration.displayMode === ItemInterfaceWidgetConfigurationDisplayMode.CARDS);

    const shouldShowTableViewFields =
        configuration.displayMode === ItemInterfaceWidgetConfigurationDisplayMode.AUTO ||
        configuration.displayMode === ItemInterfaceWidgetConfigurationDisplayMode.TABLE;

    return (
        <>
            {shouldShowTableViewFields && (
                <WidgetConfigurationFieldSelector
                    groups={undefined}
                    allFieldDefinitions={allFieldDefinitions}
                    deletedFieldsIds={deletedFieldsIdsTableView}
                    workflowVersion={workflowVersion}
                    exampleItem={initiative}
                    configurationTitle="Table View Fields"
                    disabledFields={!titleFieldUnlocked ? ['TNK_TITLE'] : undefined}
                    disableEditFields={!titleFieldUnlocked ? ['TNK_TITLE'] : undefined}
                    fieldsAdditionalOptions={finalFieldAdditionalOptions}
                    supportRequiredFields={supportRequiredFields}
                    fixedOrderForSpecialFields={fixedOrderForSpecialFields}
                    supportFieldWidth={supportFieldWidth}
                    shouldToggleFilters
                />
            )}
            {shouldShowCardsViewFields && (
                <CardFieldsConfigurationWrapper>
                    <WidgetConfigurationFieldSelector
                        groups={undefined}
                        allFieldDefinitions={allFieldDefinitions}
                        deletedFieldsIds={deletedFieldsIdsCardsView}
                        workflowVersion={workflowVersion}
                        exampleItem={initiative}
                        configurationTitle="Card View Fields"
                        fieldConfigurationPath="configuration.cardFields"
                        maxAmountOfFields={6}
                        enableSearch={false}
                        fieldsAdditionalOptions={finalFieldAdditionalOptions}
                        fixedOrderForSpecialFields={false}
                        supportRequiredFields={supportRequiredFields}
                        hideAllFieldsCheckbox
                        shouldToggleFilters
                    />
                </CardFieldsConfigurationWrapper>
            )}
            <LineItemsAddAggregationModal
                onSubmit={onSubmitAggregationModal}
                onClose={() => setEditedAggregationKeyAndNameAndType(false)}
                open={supportAggregations && !!editedAggregationKeyAndNameAndType}
                initialValues={editableAggregationField}
                fieldName={editedAggregationKeyAndNameAndType[1] || ''}
                fieldType={editedAggregationKeyAndNameAndType[2] || FieldType.String}
                fieldDefinitions={fieldDefinitions || EMPTY_ARRAY}
                loading={fieldDefinitions === undefined}
            />
        </>
    );
};

export default ItemsWidgetConfigurationEditorFieldSelector;
