function SyncConfigCacheManager($q, $rootScope, tonkeanService, workflowVersionManager) {
    const _this = this;

    /**
     * Map between a workflow version and its sync config.
     */
    _this.workflowVersionIdToSyncConfigMap = {};

    /**
     * Caches given sync config.
     */
    _this.cacheSyncConfig = function (syncConfig) {
        if (syncConfig && syncConfig.id && syncConfig.workflowVersion && syncConfig.workflowVersion.id) {
            _this.workflowVersionIdToSyncConfigMap[syncConfig.workflowVersion.id] = syncConfig;
        }
    };

    /**
     * Fetches sync config for a workflow version.
     */
    _this.getSyncConfig = function (workflowVersionId) {
        if (workflowVersionId && _this.workflowVersionIdToSyncConfigMap[workflowVersionId]) {
            return _this.workflowVersionIdToSyncConfigMap[workflowVersionId];
        } else {
            return null;
        }
    };

    /**
     * Sync the selected view to the given initiative.
     */
    _this.createInitiativeSyncConfig = function (
        initiativeId,
        projectIntegrationId,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType,
        deleteItemsSelectionType,
        syncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration,
    ) {
        return tonkeanService.createInitiativeSyncConfig(
            initiativeId,
            projectIntegrationId,
            viewData,
            viewCount,
            viewType,
            previewMode,
            uniqueIdentifierType,
            deleteItemsSelectionType,
            syncItemsCreatorSelectionType,
            syncItemsCreatorConfiguration,
        );
    };

    /**
     * Sync the selected view to the given group.
     */
    _this.createGroupSyncConfig = function (
        groupId,
        projectIntegrationId,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType,
        deleteItemsSelectionType,
        syncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration,
    ) {
        return tonkeanService
            .createGroupSyncConfig(
                groupId,
                projectIntegrationId,
                viewData,
                viewCount,
                viewType,
                previewMode,
                uniqueIdentifierType,
                deleteItemsSelectionType,
                syncItemsCreatorSelectionType,
                syncItemsCreatorConfiguration,
            )
            .then((data) => {
                workflowVersionManager.incrementDraftCounter(groupId);

                // We broadcast a change, so components relying on this information can take action.
                // Doing broadcast-watch is frowned upon, but the alternative is a very ugly spaghetti
                // code between components in order to have them updated. It's a compromise that makes
                // code more readable in this particular case.
                $rootScope.$broadcast('groupSyncConfigAdded', {
                    groupId,
                    workflowVersionId: workflowVersionManager.getDraftVersionFromCache(groupId).id,
                });

                return $q.resolve(data);
            });
    };

    /**
     * Updates the given sync config.
     */
    _this.updateSyncConfig = function (
        syncConfigId,
        projectIntegrationId,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType,
        deleteItemsSelectionType,
        syncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration,
    ) {
        return tonkeanService
            .updateSyncConfig(
                syncConfigId,
                projectIntegrationId,
                viewData,
                viewCount,
                viewType,
                previewMode,
                uniqueIdentifierType,
                deleteItemsSelectionType,
                syncItemsCreatorSelectionType,
                syncItemsCreatorConfiguration,
            )
            .then((data) => {
                // Incrementing the changes counter for the correct workflow version.
                Object.keys(_this.workflowVersionIdToSyncConfigMap).forEach((workflowVersionId) => {
                    const syncConfig = _this.workflowVersionIdToSyncConfigMap[workflowVersionId];
                    if (syncConfig && syncConfig.id === syncConfigId) {
                        workflowVersionManager.incrementWorkflowVersionCounter(workflowVersionId);
                    }
                });

                return $q.resolve(data);
            });
    };

    _this.workflowVersionHasDatasourceOfType = function (workflowVersionId, integrationType, entity) {
        const currentWorkflowVersion = workflowVersionManager.workflowVersionIdToWorkflowVersionMap[workflowVersionId];

        if (currentWorkflowVersion.dataSourceType !== 'INTEGRATION') {
            return false;
        }

        const viewData = currentWorkflowVersion.syncConfig?.viewData;
        const sameIntegrationType = viewData?.integrationType === integrationType;
        const sameEntityIfExists = !entity || viewData?.entity?.toLowerCase() === entity.toLowerCase();
        return sameIntegrationType && sameEntityIfExists;
    };
}

angular.module('tonkean.app').service('syncConfigCacheManager', SyncConfigCacheManager);
