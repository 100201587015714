import React from 'react';
import styled from 'styled-components';

import { InformationTooltip } from '@tonkean/infrastructure';
import { BrokenIcon } from '@tonkean/svg';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { colorSvg } from '@tonkean/utils';

const FieldErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${Theme.colors.warning};
`;

const BrokenIconWrapper = styled.span`
    line-height: 0;

    svg {
        height: 12px;
        ${colorSvg(Theme.colors.warning)}
    }
`;

const FieldErrorText = styled.i`
    margin: 0 5px;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.warning};
    font-style: italic;
`;

interface Props extends StyledComponentsSupportProps {
    fieldErrorText: string;
    tooltipText: string;
}

const FieldError: React.FC<Props> = ({ fieldErrorText, tooltipText }) => {
    return (
        <FieldErrorWrapper data-automation="single-field-missing-field-state">
            <BrokenIconWrapper>
                <BrokenIcon />
            </BrokenIconWrapper>
            <FieldErrorText>{fieldErrorText}</FieldErrorText>
            <InformationTooltip>{tooltipText}</InformationTooltip>
        </FieldErrorWrapper>
    );
};

export default FieldError;
