/**
 * Created by sagieliyahu on 07/10/15.
 */

function ManageNotificationsCtrl($scope, $stateParams, $q, authenticationService, projectManager, tonkeanService) {
    $scope.as = authenticationService;
    $scope.pm = projectManager;

    $scope.data = {
        messageId: $stateParams.message,
        savingMessagePreference: false,
        errorSavingMessagePreference: false,
    };

    /**
     * Occurs once selected preferences are changed.
     */
    $scope.onSelectedPreferencesChanged = function (selectedPreferences) {
        $scope.data.savingMessagePreference = true;
        $scope.data.errorSavingMessagePreference = false;

        let updatePromise = $q.resolve();
        if (authenticationService.currentUser) {
            updatePromise = tonkeanService.updateUserMessagePreferences(selectedPreferences, $scope.data.messageId);
        } else {
            updatePromise = tonkeanService.updateMessagePreferences(selectedPreferences, $scope.data.messageId);
        }

        return updatePromise
            .catch(() => {
                $scope.data.errorSavingMessagePreference = true;
            })
            .finally(() => {
                $scope.data.savingMessagePreference = false;
            });
    };
}

export default angular.module('tonkean.app').controller('ManageNotificationsCtrl', ManageNotificationsCtrl);
