import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import lateConstructController from '../../utils/lateConstructController';
import { analyticsWrapper } from '@tonkean/analytics';
import { getFormSecondaryColorOptions } from '@tonkean/constants';

/* @ngInject */
function FormPageCtrl($scope, $state, $timeout, $q, formManager, projectManager, groupInfoManager, tonkeanService) {
    const ctrl = this;

    $scope.fieldDefinitionId = ctrl.fieldDefinitionId;
    $scope.onlyInnerItems = ctrl.onlyInnerItems;
    $scope.projectId = ctrl.projectId;
    $scope.formId = ctrl.formId;
    $scope.hierarchy = ctrl.hierarchy;
    $scope.formVersionType = ctrl.formVersionType?.toLowerCase();
    $scope.viewMode = ctrl.viewMode;

    $scope.workerRunId = ctrl.workerRunId;
    $scope.workerRunLogicActionType = ctrl.workerRunLogicActionType;

    $scope.data = {
        workerRunId: ctrl.workerRunId,
        formId: ctrl.formId,
        sessionId: ctrl.sessionId,
        customTriggerId: ctrl.customTriggerId,
        initiativeId: ctrl.initiativeId,
        backgroundColor: null,
        expandHovered: false,
        historyLinksConfigs: {},
        hasHistoryLinks: false,
        initiative: undefined,
        expanded: false,
        form: ctrl.form,
        project: ctrl.project,
        loadForm: ctrl.loadForm,
        addUnderInitiative: ctrl.addUnderInitiative,
        collectionForm: ctrl.collectionForm,
        group: ctrl.group,
        isFormFetchedFromGraphql: !!ctrl.form,
        formVersionType: ctrl.formVersionType?.toLowerCase(),
        onFormClosed: ctrl.onFormClosed,
        onInitiativeCreated: ctrl.onInitiativeCreated,
        parentInitiativeId: ctrl.parentInitiativeId,
        isModal: ctrl.isModal,
        formModalRef: ctrl.formModalRef,
        onSubmitResolved: ctrl.onSubmitResolved,
        itemInterfaceId: ctrl.itemInterfaceId,
        workflowVersionId: ctrl.workflowVersionId,
        createUsingCustomTriggerId: ctrl.createUsingCustomTriggerId,
        projectUrlSlug: projectManager.project?.urlSlug,
        theme: {},
    };

    $scope.init = function () {
        loadIntakeData();
    };

    $scope.toggleExpand = function () {
        $scope.data.expanded = !$scope.data.expanded;
    };

    $scope.init();

    function loadIntakeData() {
        $scope.data.backgroundColor = projectManager?.project?.themeConfiguration?.primaryColor;
        if ($scope.data.form) {
            if (projectManager?.project?.forceThemeConfiguration) {
                $scope.data.form.definition = {
                    ...$scope.data.form.definition,
                    primaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                    buttonsColor: projectManager?.project?.themeConfiguration?.primaryColor,
                    secondaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                    logoUrl: $scope.data.project?.themeConfiguration?.logoUrl,
                };
                $scope.data.theme = {
                    primaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                    buttonsColor: projectManager?.project?.themeConfiguration?.primaryColor,
                    secondaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                    logoUrl: $scope.data.project?.themeConfiguration?.logoUrl,
                };
            }
            $scope.data.backgroundColor =
                $scope.data.form.definition.secondaryColor || getFormSecondaryColorOptions().default;
            $scope.data.expanded = $scope.data.form.definition.expandByDefault;
            $scope.data.workflowVersionId = $scope.data.form.workflowVersion.id;
            onFormLoaded();
        } else {
            $scope.data.loadingIntake = true;
            let intakePromise = $q.resolve();
            if (ctrl.formId) {
                intakePromise = formManager.getFormByWorkflowVersionType(
                    $scope.data.formId,
                    $scope.formVersionType === 'draft' ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
                    { referringInitiative: $scope.data.parentInitiativeId },
                );
            } else if (ctrl.itemInterfaceId) {
                intakePromise = tonkeanService.getItemInterfaceById(
                    $scope.data.itemInterfaceId,
                    $scope.data.workflowVersionId,
                    null,
                );
            }
            intakePromise
                .then((intake) => {
                    if (ctrl.formId) {
                        $scope.data.form = intake;
                        if (projectManager?.project?.forceThemeConfiguration) {
                            $scope.data.form.definition = {
                                ...$scope.data.form.definition,
                                primaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                                buttonsColor: projectManager?.project?.themeConfiguration?.primaryColor,
                                secondaryColor: projectManager?.project.themeConfiguration?.primaryColor,
                                logoUrl: projectManager?.project?.themeConfiguration?.logoUrl,
                            };
                            $scope.data.theme = {
                                primaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                                buttonsColor: projectManager?.project?.themeConfiguration?.primaryColor,
                                secondaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                                logoUrl: projectManager?.project?.themeConfiguration?.logoUrl,
                            };
                        } else {
                            $scope.data.backgroundColor =
                                $scope.data.form.definition.secondaryColor || getFormSecondaryColorOptions().default;
                        }
                    }
                    if (ctrl.itemInterfaceId) {
                        $scope.data.itemInterface = intake;
                        if (projectManager?.project?.forceThemeConfiguration) {
                            $scope.data.theme = {
                                primaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                                buttonsColor: projectManager?.project?.themeConfiguration?.primaryColor,
                                secondaryColor: projectManager?.project?.themeConfiguration?.primaryColor,
                                logoUrl: projectManager?.project?.themeConfiguration?.logoUrl,
                            };
                        } else {
                            $scope.data.backgroundColor =
                                $scope.data.itemInterface?.themeConfiguration?.headerBackgroundColor;
                        }
                    }
                    $scope.data.workflowVersionId = intake.workflowVersion.id;

                    $scope.data.expanded = ctrl.formId && $scope.data.form.definition.expandByDefault;

                    return groupInfoManager.getGroup(intake.group.id, undefined, false);
                })
                .catch(() => {
                    $timeout(() => {
                        $scope.data.form = null;
                    });
                })
                .finally(() => {
                    $timeout(() => {
                        $scope.data.loadingIntake = false;
                        onFormLoaded();
                    });
                });
        }
    }

    function onFormLoaded() {
        analyticsWrapper.timeEvent('forms_shown');
    }

    $scope.onExpandMouseEnter = () => {
        $scope.data.expandHovered = true;
    };

    $scope.onExpandMouseLeave = () => {
        $scope.data.expandHovered = false;
    };

    $scope.onInitiativeSelected = function (initiativeId) {
        $state.transitionTo('form', { initiativeId }, { notify: false, inherit: true });
        ctrl.initiativeId = initiativeId;
        $scope.data.initiativeId = initiativeId;
        $scope.data.loadIntakeData();
    };

    $scope.onFormCreatedInitiative = (initiative, workerRunId, formDisplayName) => {
        if (!$scope.data.historyLinksConfigs[initiative.id]) {
            // Add the initiative to the links map, and set the first worker run that is relevant for it
            // to be selected by the history
            $scope.data.historyLinksConfigs[initiative.id] = {
                workerRunId,
                initiative,
            };

            $scope.data.hasHistoryLinks = true;
        }

        $scope.data.onInitiativeCreated?.({ createdInitiative: initiative, workerRunId, formDisplayName });
    };

    $scope.onCustomTriggerIdChanged = (customTriggerId) => {
        $scope.data.customTriggerId = customTriggerId;
    };

    $scope.onFormClosedWrapper = () => {
        $scope.data.onFormClosed?.();
    };

    ctrl.$onChanges = function (changeObj) {
        if (changeObj.form) {
            $scope.data.form = ctrl.form;
        }
        if (changeObj.project) {
            $scope.data.project = ctrl.project;
        }
        if (changeObj.onInitiativeCreated) {
            $scope.data.onInitiativeCreated = ctrl.onInitiativeCreated;
        }
        if (changeObj.onFormClosed) {
            $scope.data.onFormClosed = ctrl.onFormClosed;
        }
        if (changeObj.parentInitiativeId) {
            $scope.data.parentInitiativeId = ctrl.parentInitiativeId;
        }
        if (changeObj.itemInterfaceId) {
            $scope.data.itemInterfaceId = ctrl.itemInterfaceId;
        }
        if (changeObj.createUsingCustomTriggerId) {
            $scope.data.createUsingCustomTriggerId = ctrl.createUsingCustomTriggerId;
        }
        loadIntakeData();
    };
}

angular.module('tonkean.app').controller('FormPageCtrl', lateConstructController(FormPageCtrl));
