import React, { useContext } from 'react';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const DEFAULT_CONTEXT = {
    navigateToNextInitiativeId: () => {},
    navigateToPreviousInitiativeId: () => {},
    currentPosition: -1,
    total: -1,
    items: [],
};

type InitiativeNavigationContextType = {
    navigateToNextInitiativeId: () => void;
    navigateToPreviousInitiativeId: () => void;
    currentPosition: number;
    total: number;
    items: { id: TonkeanId<TonkeanType.INITIATIVE>; title: string }[];
};
const InitiativeNavigationContext = React.createContext<InitiativeNavigationContextType>(DEFAULT_CONTEXT);

export function useInitiativeNavigationContext() {
    const context = useContext(InitiativeNavigationContext);
    return context || DEFAULT_CONTEXT;
}

export default InitiativeNavigationContext;
