import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { FieldChartIntervalOption } from './FieldChartIntervalOption';

import { Spacer, TnkSelect } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';

const IntervalSelectorContainer = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    float: right;
    margin-right: 10px;
    margin-bottom: 15px;
`;

const IntervalText = styled.div`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
`;

interface Props {
    selectedInterval: FieldChartIntervalOption;
    onIntervalChanged: (fieldChartIntervalOption: FieldChartIntervalOption) => void;
    intervalOptions: FieldChartIntervalOption[];
}
const rangeTo = new Date();

const IntervalSelector: React.FC<Props> = ({ selectedInterval, onIntervalChanged, intervalOptions }) => {
    const rangeFrom = useMemo(() => {
        const from = new Date(rangeTo);
        from.setDate(from.getDate() - selectedInterval.value);
        return from;
    }, [selectedInterval]);

    return (
        <IntervalSelectorContainer>
            <IntervalText>
                Last {selectedInterval.label}{' '}
                <i>
                    ({dayjs(rangeFrom).format('MM/DD')} - {dayjs(rangeTo).format('MM/DD')})
                </i>
            </IntervalText>
            <Spacer width={5} />
            <TnkSelect value={selectedInterval} options={intervalOptions} onChange={onIntervalChanged} thin />
        </IntervalSelectorContainer>
    );
};
export default IntervalSelector;
