import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class CreateListFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.CREATE_LIST;
    override readonly sign = 'CreateList';
    override readonly displayName = 'Create List';
    override readonly description = 'Creates a list out of given items.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Items',
            pluralName: 'Items',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => [
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: FieldType.String,
                    displayName: `Item ${repeatIndex}`,
                    explanation: 'The item to add to the list.',
                },
            ],
        },
    ];
}
