import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { Checkbox, Input, KebabMenuButton, Menu, MenuItem, TextEllipsis } from '@tonkean/infrastructure';
import { CheckIconSVG } from '@tonkean/svg';
import { XIcon as XIconSVG } from '@tonkean/svg';
import type { GroupEnvironmentFormSummary } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ListItem = styled.li`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    border-bottom: 1px solid ${Theme.colors.gray_300};
    height: 50px;
    line-height: 14px;
    display: flex;
    align-items: center;
    background: ${Theme.colors.gray_100};
`;

const ActionType = styled.span<{ widthPercentage: number }>`
    width: ${({ widthPercentage }) => widthPercentage}%;
    padding-right: 5px;
`;

const StyledCheckbox = styled(Checkbox)`
    padding-left: 20px;
`;

const EllipsisDiv = styled.div`
    flex-grow: 1;
    margin-left: 8px;
    margin-right: 20px;
    max-width: 60%;
`;

const EditLabelWrapper = styled.div`
    display: flex;
`;

const CheckIcon = styled(CheckIconSVG)`
    height: 12px !important;
    width: 12px !important;
    margin-right: 0 !important;
`;

const XIcon = styled(XIconSVG)`
    height: 12px !important;
    width: 12px !important;
    margin-right: 0 !important;
`;

export interface DropdownAction {
    key: string;
    label: string;
    onClick: (actionId: TonkeanId<TonkeanType.FORM> | TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => void;
}

interface CheckBoxProps {
    form: GroupEnvironmentFormSummary;
    actionTypeWidthPercentage: number;

    updateRecords(formId: string, isAvailable: boolean): void;

    enableEditLabel?: boolean;
    setLabel?: (id: string, label: string) => void;
    extraDropdownActions?: DropdownAction[];
}

const ActionsCheckboxRow: React.FC<CheckBoxProps> = React.memo(
    ({ form, updateRecords, actionTypeWidthPercentage, enableEditLabel, setLabel, extraDropdownActions }) => {
        const [menuOpen, setMenuOpen] = useState(false);
        const [isEditingLabel, setIsEditingLabel] = useState(false);
        const [statusLabel, setStatusLabel] = useState(form.overrideLabel || form.displayName);
        const kebabMenuRef = useRef<HTMLButtonElement>(null);
        const inputRef = useRef<HTMLInputElement>(null);

        const menuItems = useMemo(() => {
            const extraItems =
                extraDropdownActions?.map((action) => (
                    <MenuItem
                        data-automation="action-checkbox-row-set-disabled-state"
                        key={action.key}
                        onClick={() => action.onClick(form.id)}
                    >
                        <>{action.label}</>
                    </MenuItem>
                )) || [];

            return [
                <MenuItem
                    data-automation="action-checkbox-row-edit-action-label"
                    key="edit"
                    onClick={() => setIsEditingLabel(true)}
                >
                    Edit action label
                </MenuItem>,
                <MenuItem
                    data-automation="action-checkbox-row-restore-action-label"
                    key="restore"
                    onClick={() => {
                        setStatusLabel(form.displayName);
                        setLabel && setLabel(form.id, form.displayName);
                    }}
                >
                    Restore to default
                </MenuItem>,
                ...extraItems,
            ];
        }, [extraDropdownActions, form.displayName, form.id, setLabel]);

        return (
            <ListItem key={form.id}>
                <StyledCheckbox
                    checked={form.isAvailable}
                    name={form.id}
                    onChange={(event) => {
                        updateRecords(form.id, event.target.checked);
                    }}
                />
                <EllipsisDiv data-automation="action-checkbox-row-action-name">
                    {isEditingLabel ? (
                        <EditLabelWrapper>
                            <Input
                                data-automation="action-checkbox-row-action-name-input"
                                value={statusLabel}
                                onChange={(e) => setStatusLabel(e.target.value)}
                                // onKeyDown={handleLabelInputKeyPress}
                                ref={inputRef}
                            />
                            <Button
                                data-automation="action-checkbox-row-save-action-label"
                                size={ButtonSize.MEDIUM_SNUG}
                                shape={ButtonShape.RECTANGULAR}
                                onClick={() => {
                                    setLabel && setLabel(form.id, statusLabel);
                                    setIsEditingLabel(false);
                                }}
                                aria-label="save action label"
                                flex
                                highlighted
                            >
                                <CheckIcon />
                            </Button>
                            <Button
                                data-automation="action-checkbox-row-discard-custom-label"
                                size={ButtonSize.MEDIUM_SNUG}
                                shape={ButtonShape.RECTANGULAR}
                                onClick={() => setIsEditingLabel(false)}
                                aria-label="discard editing of action label"
                                flex
                                highlighted
                                outlined
                            >
                                <XIcon />
                            </Button>
                        </EditLabelWrapper>
                    ) : (
                        <TextEllipsis numberOfLines={1} tooltip>
                            {form.overrideLabel || form.displayName}
                        </TextEllipsis>
                    )}
                </EllipsisDiv>
                <ActionType widthPercentage={actionTypeWidthPercentage}>{form.additionalDataValue}</ActionType>

                <Menu
                    nodeRef={kebabMenuRef}
                    // Fixing an issue with input not focusing because of the focus trap
                    show={menuOpen}
                    onClose={() => setMenuOpen(false)}
                    menuItems={menuItems}
                    // nodeRef={kebabMenuRef}
                    // Fixing an issue with input not focusing because of the focus trap
                    // restoreFocusElementRef={isEditingLabel ? inputRef : kebabMenuRef}
                />

                {enableEditLabel && (
                    <KebabMenuButton
                        data-automation="action-checkbox-row-kebab-menu"
                        onClick={() => setMenuOpen(true)}
                        ref={kebabMenuRef}
                        flat
                    />
                )}
            </ListItem>
        );
    },
);

export default ActionsCheckboxRow;
