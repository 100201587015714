import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';

/**
 * A component used to redirect from the old form urls (`product.form`) to the new state `form`.
 */
const TonkeanFormRedirect: React.FC = () => {
    const $state = useAngularService('$state');
    const $stateParams = useAngularService('$stateParams');

    useEffect(() => {
        if ($state.current.name === 'product.form') {
            $state.go('form', $stateParams);
        }
    }, [$state, $stateParams]);

    return <></>;
};

export default TonkeanFormRedirect;
