import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function CommunicationProjectIntegrationSelectorCtrl($scope, $state, projectManager) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.data = {
        selectedCommunicationProjectIntegration: ctrl.selectedCommunicationProjectIntegration,
        communicationProjectIntegrationOptions: angular.copy($scope.pm.project.communicationIntegrations),
        forceEmail: ctrl.forceEmail,
        emailOption: ctrl.emailOption,
        manageIntegrationsLiteral: 'Manage Communication Sources',
        disallowManageCommunicationSources: ctrl.disallowManageCommunicationSources,
        automationIdentifier: ctrl.automationIdentifier,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        $scope.data.selectedCommunicationProjectIntegration = getSelectedCommunicationIntegrationOrProjectDefault();

        if ($scope.data.emailOption) {
            $scope.data.communicationProjectIntegrationOptions.unshift({ communicationIntegrationType: 'Email' });
        }

        if ($scope.data.forceEmail) {
            $scope.data.selectedCommunicationProjectIntegration = { communicationIntegrationType: 'Email' };
        }

        if ($scope.data.disallowManageCommunicationSources) {
            return;
        }

        $scope.data.communicationProjectIntegrationOptions.push({
            communicationIntegrationType: $scope.data.manageIntegrationsLiteral,
        });
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.automationIdentifier) {
            $scope.data.automationIdentifier = ctrl.automationIdentifier;
        }
    };

    function getSelectedCommunicationIntegrationOrProjectDefault() {
        if (
            $scope.data.selectedCommunicationProjectIntegration &&
            $scope.pm.getProjectIntegrationById($scope.data.selectedCommunicationProjectIntegration.id)
        ) {
            return $scope.pm.getProjectIntegrationById($scope.data.selectedCommunicationProjectIntegration.id);
        }
    }

    function openCommunicationIntegrationPageWithReturnTo() {
        $state.go('product.enterpriseComponents', {
            tab: 'communication-sources',
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
            fromName: $scope.pm.groupsMap[$state.params.g]?.name || 'Module Editor',
        });
    }

    $scope.onCommunicationIntegrationChanged = function (communicationProjectIntegration) {
        if (communicationProjectIntegration.communicationIntegrationType === $scope.data.manageIntegrationsLiteral) {
            openCommunicationIntegrationPageWithReturnTo();
            return;
        }

        $scope.data.selectedCommunicationProjectIntegration = communicationProjectIntegration;
        if (ctrl.onCommunicationProjectIntegrationChanged) {
            ctrl.onCommunicationProjectIntegrationChanged({
                selectedCommunicationProjectIntegration: $scope.data.selectedCommunicationProjectIntegration,
            });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'CommunicationProjectIntegrationSelectorCtrl',
        lateConstructController(CommunicationProjectIntegrationSelectorCtrl),
    );
