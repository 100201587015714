<div class="form-answered-configuration-logic">
    <tnk-post-message-action-configuration
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        configured-logic="data.configuredLogic"
        preview-evaluation-source="data.previewEvaluationSource"
        custom-trigger-action-type="{{ data.configuredLogic.node.customTriggerType }}"
        invalid-logics="data.invalidLogics"
        open-field-modal="data.openFieldModal"
        on-actions-changed="onActionsChanged(definition, shouldSaveLogic)"
    ></tnk-post-message-action-configuration>
</div>
