import { lighten } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

import SearchWidgetInnerStateContainer from './SearchWidgetInnerStateContainer';
import type SearchWidgetConfiguration from '../SearchWidgetConfiguration';

import { H3, Paragraph, Placeholder } from '@tonkean/infrastructure';
import { useInitiativeExpressionServerEvaluation } from '@tonkean/infrastructure';
import type { Initiative, ItemInterfaceHeaderThemeConfiguration } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import { classNames, colorSvg } from '@tonkean/utils';

const IconContainer = styled.div<{ $themeColor: Color }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 148px;
    height: 148px;
    border-radius: 50%;
    font-size: 58px;
    ${({ $themeColor }) => css`
        background-color: ${lighten(0.4, $themeColor)};
        color: ${$themeColor};
        ${colorSvg($themeColor)}
    `}

    margin-bottom: 8px;
`;

interface Props {
    initiative: Initiative | undefined;
    configuration: SearchWidgetConfiguration;
    themeConfiguration: ItemInterfaceHeaderThemeConfiguration;
}

const SearchWidgetNoSearchState: React.FC<Props> = ({ initiative, configuration, themeConfiguration }) => {
    const {
        values: [titleExpression],
        loading: loadingTitle,
    } = useInitiativeExpressionServerEvaluation(
        configuration.noSearchStateTitle ? [configuration.noSearchStateTitle] : [],
        initiative,
    );

    const {
        values: [descriptionExpression],
        loading: loadingDescription,
    } = useInitiativeExpressionServerEvaluation(
        configuration.noSearchStateDescription ? [configuration.noSearchStateDescription] : [],
        initiative,
    );

    const firstClassName = configuration.noSearchStateIcon?.iconClass
        ? `fa-${configuration.noSearchStateIcon.iconClass}`
        : 'fa-solid';

    return (
        <SearchWidgetInnerStateContainer>
            {configuration.noSearchStateIcon && (
                <IconContainer $themeColor={themeConfiguration.headerBackgroundColor}>
                    <div className={classNames(firstClassName, `fa-${configuration.noSearchStateIcon.name}`)} />
                </IconContainer>
            )}

            {configuration.noSearchStateTitle && (
                <>{loadingTitle ? <Placeholder $width="50%" $height="19px" /> : <H3>{titleExpression}</H3>}</>
            )}

            {configuration.noSearchStateDescription && (
                <>
                    {loadingDescription ? (
                        <Placeholder $width="35%" $height="14px" />
                    ) : (
                        <Paragraph $light>{descriptionExpression}</Paragraph>
                    )}
                </>
            )}
        </SearchWidgetInnerStateContainer>
    );
};

export default SearchWidgetNoSearchState;
