import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CustomiseIcon } from '../../../../images/icons/customise.svg';
import HistoryContext from '../entities/HistoryContext';

import {
    Chevron,
    ChevronDirection,
    EnvironmentHeader,
    Menu,
    MenuItem,
    TextEllipsis,
    Tooltip,
} from '@tonkean/infrastructure';
import StateLink from '@tonkean/tui-basic/StateLink';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const TitleMenu = styled.button`
    border: none;
    background: transparent;
    line-height: normal;
    display: flex;
    align-items: center;
`;

const GroupName = styled.div`
    margin-bottom: 3px;
`;

const GroupMenuChevron = styled(Chevron)`
    margin-left: 10px;
`;

const ModuleName = styled.div`
    margin-right: 32px;
`;

interface Props extends StyledComponentsSupportProps {
    isModal: boolean;
    group: any;
    groups?: any[];
    onCloseClick: (() => void) | undefined;
}

const HistoryHeader: React.FC<Props> = ({ className, isModal, groups = [], group, onCloseClick }) => {
    const { state, updateState, environmentIsActive } = useContext(HistoryContext);

    const [groupsMenuOpen, setGroupsMenuOpen] = useState(false);

    const groupId = group.id;
    const workerEditorStateParam = useMemo(
        () => ({
            g: groupId,
        }),
        [groupId],
    );

    return (
        <EnvironmentHeader
            onCloseClick={onCloseClick}
            closeButtonDataAutomation="history-header-back-button"
            className={className}
            environmentIsActive={environmentIsActive}
            currentEnvironment={state.environment}
            onEnvironmentChange={(environment) => updateState({ environment })}
        >
            {isModal ? (
                <TextEllipsis numberOfLines={1} tooltip>
                    <ModuleName>{group.name}</ModuleName>
                </TextEllipsis>
            ) : (
                <>
                    <Menu
                        menuItems={
                            <>
                                {groups.map((group) => (
                                    <MenuItem
                                        key={group.id}
                                        onClick={() => updateState({ groupId: group.id, workerRunInfo: undefined })}
                                        selected={group.id === groupId}
                                    >
                                        {group.name}
                                    </MenuItem>
                                ))}
                            </>
                        }
                        show={groupsMenuOpen}
                        onClose={() => setGroupsMenuOpen(false)}
                    >
                        <TitleMenu onClick={() => setGroupsMenuOpen(!groupsMenuOpen)}>
                            <GroupName>{group.name}</GroupName>
                            <GroupMenuChevron
                                direction={groupsMenuOpen ? ChevronDirection.UP : ChevronDirection.DOWN}
                            />
                        </TitleMenu>
                    </Menu>

                    <Tooltip content="Open Module Editor">
                        <StateLink
                            className="btn btn-no-border flex-vmiddle margin-left-lg"
                            state="product.workerEditor"
                            params={workerEditorStateParam}
                        >
                            <div className="svg-icon-md common-color-dark-grey">
                                <span className="tnk-icon">
                                    <CustomiseIcon />
                                </span>
                            </div>
                        </StateLink>
                    </Tooltip>
                </>
            )}
        </EnvironmentHeader>
    );
};

export default HistoryHeader;
