import { reactToAngular } from 'angulareact';

import PreviewNotificationPane from './PreviewNotificationPane';

export default angular
    .module('tonkean.app')
    .component(
        'tnkPreviewNotificationPane',
        reactToAngular(PreviewNotificationPane, [
            'messageContent',
            'workflowVersionId',
            'logicId',
            'previewEvaluationSource',
            'color',
            'addLinkText',
            'fields',
            'buttons',
            'sendOnBehalf',
        ]),
    );
