<!-- Fix message if the project integration is not valid -->
<div class="info-box margin-bottom" ng-if="projectIntegration && projectIntegration.valid === false">
    <div class="flex mod-align-center">
        <div class="common-size-xs common-color-black">
            <i class="fa fa-info-circle common-color-danger margin-right margin-top-xxs"></i>
            <strong>{{ projectIntegration.displayName }}</strong>
            data source is broken.
        </div>
        <button
            type="button"
            class="btn btn-warning"
            ng-click="goToSingleIntegrationPage(projectIntegration)"
            ng-if="projectIntegration.valid === false"
            uib-tooltip="Click to fix this data source in the data sources page"
            tooltip-append-to-body="true"
            analytics-on
            analytics-category="Sync"
            analytics-event="'Sync ' + projectIntegration.integration.integrationType"
        >
            Fix
        </button>
    </div>
</div>

<!-- Main content -->
<div class="flex sync-tracks" ng-if="projectIntegration && projectIntegration.valid">
    <div class="common-width-full">
        <div ng-if="!collapsed" class="" ng-hide="loadingExternal">
            <!-- Items settings -->
            <div
                class="common-bold pointer flex-vmiddle common-bg mod-grey5 common-border-bottom padding-normal-sm"
                ng-click="data.hideItemsSettings = !data.hideItemsSettings"
                ng-hide="hideSectionHeaders || (sectionVisibility && !sectionVisibility.items)"
            >
                Items
                <i
                    class="common-size-xxxs common-color-grey margin-left-lg fa"
                    ng-class="data.hideItemsSettings ? 'fa-chevron-right' : 'fa-chevron-down'"
                ></i>
            </div>
            <div
                ng-hide="data.hideItemsSettings || (sectionVisibility && !sectionVisibility.items)"
                class="padding-normal"
                ng-class="{ 'padding-none': hideSectionHeaders || (sectionVisibility && !sectionVisibility.items) }"
            >
                <div class="flex-vmiddle margin-bottom-lg">
                    <!-- View -->

                    <!-- Custom filter -->
                    <button
                        class="
                            text-left
                            btn btn-default
                            common-no-outline
                            margin-right-xs
                            padding-right-xs padding-left-xs
                        "
                        ng-click="toggleShowCustomFilters(true); control.hasFilter = true;"
                        ng-class="
                            control.customDefinedView && control.hasFilter ? 'selected-option' : 'not-selected-option'
                        "
                    >
                        Custom Filter
                    </button>

                    <!-- All -->
                    <button
                        class="
                            text-left
                            btn btn-default
                            common-no-outline
                            margin-right-xs
                            padding-right-xs padding-left-xs
                        "
                        ng-click="toggleShowCustomFilters(true); control.hasFilter = false;"
                        ng-class="
                            control.customDefinedView && !control.hasFilter ? 'selected-option' : 'not-selected-option'
                        "
                    >
                        All
                        {{
                            projectIntegration.displayName !== selectedEntityDisplayName
                                ? selectedEntityDisplayName
                                : 'items'
                        }}
                    </button>
                </div>

                <div ng-show="control.customDefinedView && control.hasFilter && !loadingExternal">
                    <p class="">
                        Only
                        {{
                            projectIntegration.displayName !== selectedEntityDisplayName
                                ? selectedEntityDisplayName
                                : projectIntegration.displayName + ' entities'
                        }}
                        that -
                    </p>
                    <tnk-custom-filters
                        control="control"
                        initialize-query-with-default-create-date-condition="!data.shouldRemoveTheDefaultSyncDateTimeFilter"
                        items-source="EXTERNAL"
                        existing-definition="viewType && viewType === viewTypes.custom ? syncData : null"
                        project-integration="projectIntegration"
                        selected-entity-type="selectedEntity"
                        from-sync="true"
                        small-mod="modSmall"
                        edit-mode="false"
                        hide-close-button="true"
                        hide-time-range-selection="hideTimeRangeSelection"
                        show-field-inspect-modal="true"
                        workflow-version-id="data.workflowVersionId"
                        not-allowed-conditions-set="data.notAllowedConditionsSet"
                    ></tnk-custom-filters>
                </div>

                <div
                    ng-if="control.customDefinedView && !control.hasFilter && !loadingExternal"
                    class="common-size-xxs"
                >
                    NOTE: This option syncs all
                    {{
                        projectIntegration.displayName !== selectedEntityDisplayName
                            ? selectedEntityDisplayName
                            : 'items'
                    }}
                    with Tonkean, but only
                    {{
                        projectIntegration.displayName !== selectedEntityDisplayName
                            ? selectedEntityDisplayName
                            : 'items'
                    }}
                    received after this sync are monitored (and included in business reports). For improved performance,
                    it is recommended to narrow down the
                    {{
                        projectIntegration.displayName !== selectedEntityDisplayName
                            ? selectedEntityDisplayName
                            : 'items'
                    }}
                    Tonkean monitors by selecting
                    <b>Custom Filter</b>
                    and providing filter parameters.
                </div>
            </div>

            <!-- Advanced selection configuration header -->
            <div
                class="
                    common-bold
                    pointer
                    flex-vmiddle
                    common-bg
                    mod-grey5
                    common-border-bottom
                    margin-top
                    padding-normal-sm
                "
                ng-click="data.hideTitleSelectionSettings = !data.hideTitleSelectionSettings"
                ng-if="!hideSectionHeaders && (!sectionVisibility || sectionVisibility.titleSelection)"
            >
                Advanced
                <i
                    class="common-size-xxxs common-color-grey margin-left-lg fa"
                    ng-class="data.hideTitleSelectionSettings ? 'fa-chevron-right' : 'fa-chevron-down'"
                ></i>
            </div>

            <div ng-if="sectionVisibility.uniqueIdentifierType">
                <div class="common-bold common-color-black common-size-14 margin-bottom-xs">Unique Identifier:</div>
                <div>
                    <label
                        for="unique-identifier-type-radio-id"
                        class="common-normal-weight pointer flex mod-align-baseline"
                    >
                        <input
                            type="radio"
                            id="unique-identifier-type-radio-id"
                            class="flex-no-shrink"
                            name="uniqueIdentifierTypeId"
                            ng-model="data.uniqueIdentifierType"
                            value="EXTERNAL_ENTITY_ID"
                        />
                        <div class="margin-left-xs">Based on Data Source Item’s identifier</div>
                    </label>
                </div>
                <div>
                    <label
                        for="unique-identifier-type-radio-title"
                        class="common-normal-weight pointer flex mod-align-baseline"
                    >
                        <input
                            type="radio"
                            id="unique-identifier-type-radio-title"
                            class="flex-no-shrink"
                            name="uniqueIdentifierTypeTitle"
                            ng-model="data.uniqueIdentifierType"
                            value="TITLE"
                        />
                        <div class="margin-left-xs">Based on Data Source Item’s title</div>
                    </label>
                </div>
            </div>

            <!-- Title selection configuration content -->
            <div
                ng-hide="data.hideTitleSelectionSettings && (!sectionVisibility || !sectionVisibility.titleSelection)"
                class="flex-col common-size-xxxs margin-top-md"
                ng-if="!sectionVisibility || sectionVisibility.titleSelection"
            >
                <!-- Small Inline Title -->
                <div class="common-bold common-color-black common-size-14 margin-bottom-xs" ng-if="hideSectionHeaders">
                    Custom Title:
                </div>

                <!-- Expression for title -->
                <div class="flex-vmiddle margin-bottom">
                    <!-- Choosing an expression for a title -->
                    <div ng-if="!hideSectionHeaders" class="flex-no-shrink margin-right common-normal-weight">
                        Title:
                    </div>

                    <!-- Expression input -->
                    <input
                        type="text"
                        class="form-control common-width-50 margin-right"
                        ng-model="data.titleExpression"
                    />

                    <!-- Selecting a field to append to the title expression -->
                    <ui-select
                        theme="bootstrap"
                        style="margin-top: -1px"
                        class="common-width-50"
                        ng-change="addFieldToTitleExpression(data.selectedAvailableField)"
                        ng-model="data.selectedAvailableField"
                    >
                        <ui-select-match placeholder="Insert field...">
                            {{ data.selectedAvailableField.label }}
                        </ui-select-match>
                        <ui-select-choices repeat="availableField in data.availableFields | filter:$select.search">
                            <div
                                class="select-option"
                                ng-bind-html="availableField.label | highlight: $select.search"
                            ></div>
                        </ui-select-choices>
                    </ui-select>
                </div>
            </div>

            <div ng-if="sectionVisibility.creator && data.showCreatorSelection">
                <hr />

                <div class="common-bold common-color-black common-size-14 margin-bottom-xs">
                    Who should be set as the creator of the synced items?
                </div>
                <div>
                    <label class="common-normal-weight pointer flex mod-align-baseline">
                        <input
                            type="radio"
                            class="flex-no-shrink sync-items-selection-radio"
                            name="itemInputSourceCreator"
                            ng-model="data.syncItemsCreatorSelectionType"
                            ng-change="syncItemsCreatorSelectionTypeChange()"
                            value="MODULE_DEFAULT_ACTOR"
                        />
                        The default actor of this module
                    </label>
                    <div>
                        <label class="common-normal-weight pointer flex mod-align-baseline">
                            <input
                                type="radio"
                                class="flex-no-shrink sync-items-selection-radio"
                                name="itemSpecificCreator"
                                ng-model="data.syncItemsCreatorSelectionType"
                                ng-change="syncItemsCreatorSelectionTypeChange()"
                                value="SPECIFIC"
                            />
                            Specific person
                        </label>
                        <div class="margin-bottom" ng-if="data.syncItemsCreatorSelectionType === 'SPECIFIC'">
                            <tnk-deprecated-people-selector
                                no-validation="false"
                                selected-people="data.specificCreator"
                                no-custom="true"
                                max-tags="1"
                                group="groupId"
                                on-tag-added="addSpecificPerson()"
                                on-tag-removed="removeSpecificPerson()"
                                placeholder="Search a user"
                            ></tnk-deprecated-people-selector>
                        </div>
                    </div>
                    <div>
                        <label class="common-normal-weight pointer flex mod-align-baseline">
                            <input
                                type="radio"
                                class="flex-no-shrink sync-items-selection-radio"
                                name="itemCustomCreator"
                                ng-model="data.syncItemsCreatorSelectionType"
                                ng-change="syncItemsCreatorSelectionTypeChange()"
                                value="CUSTOM"
                            />
                            Custom email
                        </label>

                        <!-- Expression for creator -->
                        <div class="flex-vmiddle margin-bottom" ng-if="data.syncItemsCreatorSelectionType === 'CUSTOM'">
                            <!-- Expression input -->
                            <input
                                type="text"
                                class="form-control common-width-50 margin-right"
                                ng-model="data.syncItemsCreatorConfiguration.expression.originalExpression"
                            />

                            <!-- Selecting a field to append to the creator expression -->
                            <ui-select
                                theme="bootstrap"
                                style="margin-top: -1px"
                                class="common-width-50"
                                ng-change="addFieldToCreatorCustomConfigurationExpression(data.selectedAvailableField)"
                                ng-model="data.selectedAvailableField"
                            >
                                <ui-select-match placeholder="Insert field...">
                                    {{ data.selectedAvailableField.label }}
                                </ui-select-match>
                                <ui-select-choices
                                    repeat="availableField in data.availableFields | filter:$select.search"
                                >
                                    <div
                                        class="select-option"
                                        ng-bind-html="availableField.label | highlight: $select.search"
                                    ></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ng-if="
                    sectionVisibility.deleteItemsSelectionType &&
                    data.isSupportedIntegrationType &&
                    data.isSupportedUsageMethodType
                "
            >
                <hr />

                <div class="common-bold common-color-black common-size-14 margin-bottom-xs">
                    When an item is deleted from the data source:
                </div>
                <label class="common-normal-weight pointer flex mod-align-baseline">
                    <input
                        class="flex-no-shrink"
                        name="itemDeletedDelete"
                        ng-model="data.deleteItemsSelectionType"
                        type="radio"
                        value="ITEM_DELETED_DELETE"
                    />
                    <div class="margin-left-xs">Delete the corresponding Tonkean item from the module.</div>
                </label>
                <div>
                    <label class="common-normal-weight pointer flex mod-align-baseline">
                        <input
                            class="flex-no-shrink"
                            name="itemDeletedKeep"
                            ng-model="data.deleteItemsSelectionType"
                            type="radio"
                            value="ITEM_DELETED_KEEP"
                        />
                        <div class="margin-left-xs">Keep the corresponding Tonkean item in the module.</div>
                    </label>
                </div>
            </div>

            <!-- Fields syncing - we do not support syncing fields to initiatives. -->
            <div
                class="padding-normal-sm margin-top"
                ng-if="
                    (control.selectedView || (!loadingExternal && viewType === viewTypes.custom)) &&
                    !disableFields &&
                    data.targetEntityType !== TONKEAN_ENTITY_TYPE.INITIATIVE &&
                    !syncData &&
                    (!sectionVisibility || sectionVisibility.fields)
                "
            >
                <strong>Add the following fields:</strong>
                <tags-input
                    class="margin-top tnk-selector common-no-outline"
                    ng-model="data.selectedFields"
                    display-property="label"
                    key-property="name"
                    text="data.latestFieldSearch"
                    placeholder="Add field to track.."
                    add-from-autocomplete-only="true"
                    maxTags="6"
                    max-tags="6"
                    ng-class="{ 'mod-max': data.selectedFields.length >= 6 }"
                    allow-leftover-text="false"
                >
                    <auto-complete
                        source="filterExternalFields($query)"
                        min-length="0"
                        load-on-focus="true"
                        load-on-empty="true"
                        max-results-to-show="250"
                    ></auto-complete>
                    <i class="loading mod-tags-input" ng-show="loadingTags"></i>
                </tags-input>
            </div>

            <div class="margin-top-lg" ng-if="loadingExternal">
                <i class="loading-small margin-right-xs"></i>
                Loading your {{ projectIntegration.displayName }}'s data. Will be ready in just a few seconds...
            </div>
        </div>
    </div>
</div>
