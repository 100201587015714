import { useAngularService, useAngularWatch } from 'angulareact';
import { useCallback, useMemo } from 'react';

import type {
    SingleFieldConfigurationWithDefinition,
    WidgetFieldPackage,
} from '../CommonWidgetConfiguration/FieldSelector';
import type { SingleFieldConfiguration } from '../CommonWidgetConfiguration/SingleFieldConfiguration';

import { getFieldInstance } from '@tonkean/fields';
import { FieldType } from '@tonkean/tonkean-entities';

function useFilterFieldsInWidget(
    showEmptyFieldWhenNoInitiative: boolean,
    fields: WidgetFieldPackage[],
    configurationFields: Record<string, SingleFieldConfiguration>,
): [
    string[],
    SingleFieldConfigurationWithDefinition[],
    (field: SingleFieldConfigurationWithDefinition, searchString: string) => boolean,
] {
    const projectManager = useAngularService('projectManager');

    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const fieldsSearchComparer = useCallback(
        (field: SingleFieldConfigurationWithDefinition, searchString: string) => {
            const fieldName = field.label || field.package?.fieldDefinition.name || '';
            let fieldValue =
                field.package &&
                getFieldInstance(
                    field.package.fieldDefinition,
                    field.package.initiative,
                    field.package.workflowVersion,
                    field.package.groupId,
                    showEmptyFieldWhenNoInitiative,
                    groupsMap,
                )?.value;

            fieldValue =
                fieldValue && field.package?.fieldDefinition?.fieldType === FieldType.Date
                    ? new Date(fieldValue).toLocaleDateString()
                    : fieldValue?.toString();

            const valueContainSearchString = fieldValue
                ? fieldValue.toLowerCase().includes(searchString.toLowerCase())
                : false;
            const labelContainsSearchString = fieldName.toLowerCase().includes(searchString.toLowerCase());

            return valueContainSearchString || labelContainsSearchString;
        },
        [groupsMap, showEmptyFieldWhenNoInitiative],
    );

    const shownFieldKeys = useMemo<string[]>(() => {
        const keys: string[] = [];
        if (configurationFields) {
            Object.keys(configurationFields).forEach((key) => {
                // allowing undefined for backward compatibility
                if (configurationFields[key]?.isShown === undefined || configurationFields[key]?.isShown) {
                    keys.push(key);
                }
            });
        }
        return keys;
    }, [configurationFields]);

    const filteredShownFields: SingleFieldConfigurationWithDefinition[] = useMemo(() => {
        return shownFieldKeys
            .filter((key) => configurationFields[key]?.isShown === undefined || configurationFields[key]?.isShown)
            .map((key) => ({
                ...configurationFields[key],
                package: fields.find((field) => field.fieldDefinition.id === key),
                key,
            }));
    }, [configurationFields, fields, shownFieldKeys]);

    return [shownFieldKeys, filteredShownFields, fieldsSearchComparer];
}

export default useFilterFieldsInWidget;
