import React from 'react';
import styled from 'styled-components';

import { Field, Input, Paragraph, Toggle } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const FieldWrapper = styled(Paragraph)`
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: center;
`;
const FieldRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 0;
`;

const StyledField = styled(Field)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;
`;

const StyledToggle = styled(Toggle)`
    margin-left: auto;
`;

export enum CustomFieldRowDataTypes {
    INPUT = 'input',
    TOGGLE = 'toggle',
}
export interface CustomFieldRowProps {
    fieldNameId: string;
    fieldLabel: string;
    subTitle?: string;
    dataAutomation: string;
    dataType: CustomFieldRowDataTypes;
    disabled?: boolean;
}

const CustomFieldRow: React.FC<CustomFieldRowProps> = ({
    fieldNameId,
    fieldLabel,
    subTitle,
    dataAutomation,
    dataType,
    disabled,
}) => {
    return (
        <FieldRow>
            <FieldWrapper>
                <Paragraph>{fieldLabel}</Paragraph>
                {subTitle && (
                    <Paragraph data-automation="custom-field-subtitle" $color={Theme.colors.gray_500}>
                        {subTitle}
                    </Paragraph>
                )}
            </FieldWrapper>

            <StyledField light>
                {dataType === CustomFieldRowDataTypes.INPUT && (
                    <Input type="text" name={fieldNameId} data-automation={dataAutomation} disabled={disabled} />
                )}
                {dataType === CustomFieldRowDataTypes.TOGGLE && (
                    <StyledToggle
                        name={fieldNameId}
                        size={InputSize.SMALL}
                        boldLabel={false}
                        data-automation={dataAutomation}
                    />
                )}
            </StyledField>
        </FieldRow>
    );
};

export default CustomFieldRow;
