import React from 'react';
import styled from 'styled-components';

import MarketplaceItemCreateModalSingleKeyMetric from './MarketplaceItemCreateModalSingleKeyMetric';

import { Field, Tooltip, useFormikArrayField, useSequentialIdentifier } from '@tonkean/infrastructure';
import { MarketPlaceItemKeyMetric, MarketplaceItemKeyMetricTrendType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';

const Title = styled.span`
    font-size: ${FontSize.SMALL_12};
    margin-top: 24px;
`;

const AddKeyMetric = styled(ClickableLink)`
    margin-top: 24px;
`;

interface Props {}

const MarketplaceItemCreateModalKeyMetrics: React.FC<Props> = ({}) => {
    const {
        value: keyMetrics,
        error,
        push,
        remove,
    } = useFormikArrayField<(MarketPlaceItemKeyMetric & { id: number })[]>('configuration.keyMetricsConfig.keyMetrics');

    const { incrementValue } = useSequentialIdentifier(() =>
        Math.max(...(keyMetrics?.map((metric) => metric.id) || [])),
    );

    const addKeyMetricDisabled = keyMetrics && keyMetrics.length >= 9;
    return (
        <>
            <Field
                label="Key Metrics"
                informationTooltip="Key matrics should describe the quantitative benefits received from implementing this blueprint."
            >
                {keyMetrics?.map((metric, index) => {
                    return (
                        <MarketplaceItemCreateModalSingleKeyMetric
                            key={metric.id}
                            onRemove={() => remove(index)}
                            index={index}
                        />
                    );
                })}
            </Field>

            <Tooltip content="Maximum of 9 key metrics is allowed" disabled={!addKeyMetricDisabled}>
                <AddKeyMetric
                    data-automation="marketplace-item-create-modal-key-metrics-add-key-metric"
                    onClick={() =>
                        push({
                            id: incrementValue(),
                            metric: '',
                            trendType: MarketplaceItemKeyMetricTrendType.GREEN_UP,
                            subTitle: '',
                        })
                    }
                    disabled={addKeyMetricDisabled}
                >
                    + Add Key Metric
                </AddKeyMetric>
            </Tooltip>
        </>
    );
};

export default MarketplaceItemCreateModalKeyMetrics;
