import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class GetSequenceLinkByTriggerNameFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.GET_SEQUENCE_LINK_BY_TRIGGER_NAME;
    override readonly sign = 'GetSequenceLinkByTriggerName';
    override readonly displayName = 'Get sequence link by trigger name';
    override readonly description = 'Will get link to the sequence by trigger name.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Trigger Name',
            dataType: [FieldType.String],
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
