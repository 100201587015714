export function TeamconnectModalCtrl(
    $scope,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.data = {
        subDomain: null,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.selected = state.selected || {};

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.connect = function () {
        if ($scope.data.loginModal.$valid) {
            const subdomain = $scope.data.subdomain;
            const username = $scope.data.username;
            const password = $scope.data.password;
            const version = $scope.data.version;

            $scope.error = null;
            $scope.loading = true;
            return createProjectApis
                .createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    {
                        subdomain,
                        username,
                        password,
                        version,
                    },
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'TEAMCONNECT',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                )
                .then(function (integObj) {
                    $scope.integration = integObj;
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.loading = false;
                    const s = {
                        selected: $scope.selected,
                        integration: $scope.integration,
                    };

                    s.integrations = [
                        {
                            integration: $scope.integration,
                            projectData: {},
                        },
                    ];

                    if ($scope.onIntegrationClosed) {
                        $scope.onIntegrationClosed(s);
                    }
                });
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.selected = {};
    };

    $scope.ok = function () {
        if (!$scope.data.loginModal.$valid) {
            return;
        }

        const s = {
            selected: $scope.selected,
            integration: $scope.integration,
        };

        s.integrations = [
            {
                integration: $scope.integration,
                projectData: {},
            },
        ];

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;
            }
        }
    }
}
export default angular.module('tonkean.shared').controller('TeamconnectModalCtrl', TeamconnectModalCtrl);
