import * as React from 'react';
import { useCallback, useMemo } from 'react';

import EnterpriseComponentVariableValueAssignmentResponseHandleResponseView from '../../../../../../../../EnterpriseComponentsModule/components/EnterpriseComponentAuthorizationPage/EnterpriseComponentVariableValueAssignmentResponseHandleResponseView';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import type {
    EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition,
    ProjectIntegration,
    ProjectIntegrationAction,
} from '@tonkean/tonkean-entities';

interface Props {
    projectIntegration: ProjectIntegration;
    projectIntegrationAction: ProjectIntegrationAction<EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition>;
    setProjectIntegrationAction: React.Dispatch<
        React.SetStateAction<
            ProjectIntegrationAction<EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition>
        >
    >;
}

const SuccessCriteriaValueAssignment: React.FC<Props> = ({
    projectIntegration,
    projectIntegrationAction,
    setProjectIntegrationAction,
}) => {
    const expressionProps: Partial<TonkeanExpressionProps> = useMemo(
        () => ({ globalExpressionOnly: true, doNotEvaluatePreview: true, hideEditorButton: true }),
        [],
    );

    // On Change handle response definition update the current project integration action with the updated handle response.
    const onChangeDefinition = useCallback(
        (definition: EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition) => {
            setProjectIntegrationAction((oldAction) => ({
                ...oldAction,
                responseHandlingDefinition: { ...definition },
            }));
        },
        [setProjectIntegrationAction],
    );

    return (
        <EnterpriseComponentVariableValueAssignmentResponseHandleResponseView
            projectIntegration={projectIntegration}
            onChangeHandlingDefinition={onChangeDefinition}
            variableValueAssignmentResponseHandlingDefinition={projectIntegrationAction.responseHandlingDefinition}
            expressionProps={expressionProps}
        />
    );
};

export default SuccessCriteriaValueAssignment;
