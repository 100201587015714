import type { WidgetInterface } from './WidgetInterface';

import type {
    WidgetBase,
    WidgetConfiguration,
    WidgetParentTypes,
    FieldDefinitionSummary,
} from '@tonkean/tonkean-entities';

export class BaseWidgetHandler implements WidgetInterface {
    getFieldsSummaryFromConfiguration(
        widget: WidgetBase<WidgetConfiguration, WidgetParentTypes>,
    ): FieldDefinitionSummary[] | undefined {
        throw new Error('Method not implemented.');
    }
    containsInnerItems = false;
}
