import { useAngularService } from 'angulareact';
import React, { useContext, useMemo } from 'react';

import type { ResponseOfIsValueValid } from '../../../components/common/EditableTitle/EditableText';
import EditableTitle from '../../../components/common/EditableTitle/EditableTitle';
import useEnvironmentFlags from '../../../infrastructure/hooks/useEnvironmentFlags';

import { FormBuilderContext } from '@tonkean/infrastructure';
import { SavingIndicator } from '@tonkean/infrastructure';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

interface Props {
    value: string;
    onChange: (value: string) => void;
    workflowVersionId: string;
    formId?: string;
}

const FormBuilderEditableTitle: React.FC<Props> = ({ value, onChange, workflowVersionId, formId }) => {
    const { isEditable, editedSections } = useContext(FormBuilderContext);
    const formManager = useAngularService('formManager');

    const formIdsToIgnore = useMemo(() => [formId], [formId]);
    const forms = formManager.workflowVersionIdToFormIdToFormMap[workflowVersionId];

    const isDisplayNameValid = useMemo(() => {
        const allFormsNames = forms
            ? Object.values(forms)
                  .filter((form) => !formIdsToIgnore.includes(form.id))
                  .map((form) => form.displayName)
            : [];

        return (displayName: string) => {
            return !allFormsNames.includes(displayName);
        };
    }, [formIdsToIgnore, forms]);

    const { isDraft } = useEnvironmentFlags();

    const isNewNameValid = (newTitle: string): ResponseOfIsValueValid => {
        if (utils.isNullOrEmpty(newTitle.trim())) {
            return {
                isValid: false,
                errorReason: "Form name can't be empty",
            };
        } else if (!isDisplayNameValid(newTitle)) {
            return {
                isValid: false,
                errorReason: 'Form name already exists',
            };
        } else {
            return {
                isValid: true,
            };
        }
    };

    return (
        <>
            <EditableTitle
                isEditable={isEditable && isDraft}
                onChange={onChange}
                value={value}
                isValueValid={isNewNameValid}
                notEditableTooltip="Form cannot be edited when creating"
                className="margin-right-xs"
            />
            <SavingIndicator state={editedSections[FormBuilderSections.DisplayName]} />
        </>
    );
};

export default FormBuilderEditableTitle;
