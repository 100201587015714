import type BaseNavigablePage from './BaseNavigablePage';
import type { NavigablePageType } from './BaseNavigablePage';

interface InternalNavigablePage extends BaseNavigablePage {
    type: NavigablePageType.INTERNAL;
    state: string;
    params?: Record<string, unknown>;
    innerPages?: InternalNavigablePage[];
}

export default InternalNavigablePage;

export function isInternalNavigablePage(page: BaseNavigablePage): page is InternalNavigablePage {
    return typeof page.state === 'string';
}
