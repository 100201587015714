// open the setup group in a modal
function SetupGroupModalNewCtrl(
    $scope,
    showGroupSettings,
    duplicateGroup,
    workersTemplateMode,
    isInline,
    emptyOnly,
    workflowFolderId,
    workflowFolderCategoryId,
    marketplaceItemSummary,
) {
    $scope.init = function () {
        $scope.showGroupSettings = showGroupSettings;
        $scope.workersTemplateMode = workersTemplateMode;
        $scope.isInline = isInline;
        $scope.isModal = true;
        $scope.emptyOnly = emptyOnly;
        $scope.workflowFolderId = workflowFolderId;
        $scope.workflowFolderCategoryId = workflowFolderCategoryId;
        $scope.marketplaceItemSummary = marketplaceItemSummary;

        if (duplicateGroup) {
            $scope.isDuplicatedList = true;
            prepareDuplicateGroup(angular.copy(duplicateGroup));
        }
    };

    /**
     * Prepares the needed group data for group duplication.
     * @param group - the group to parse into duplication data supplied to the setupGroupCtrl.
     */
    function prepareDuplicateGroup(group) {
        // The $scope.group reflects the desired groupSettings.
        $scope.group = {
            isDuplicatedList: true,
            sourceGroupId: group.id,
            shortTitle: `Duplicate list: ${group.name}`,
            category: null,
            name: `Copy of ${group.name}`,
            owners: group.owners || [],
        };
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('SetupGroupModalNewCtrl', SetupGroupModalNewCtrl);
