import { useCallback, useEffect, useMemo, useState } from 'react';

import useProjectPeople from './useProjectPeople';

import { LIMIT_PARAM, SKIP_PARAM } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import { SCIMRolesType, SCIMTonkeanRole } from '@tonkean/tonkean-entities';

function useProjectActiveProcessContributors() {
    const project = useProject();
    const {
        getProjectPeople,
        totalPeople,
        getBoardPeopleResponse: { peopleLoading },
    } = useProjectPeople();
    const [activeProcessContributors, setActiveProcessContributors] = useState<number>(0);

    const loadActiveProcessContributors = useCallback(() => {
        getProjectPeople(project.id, LIMIT_PARAM, SKIP_PARAM, {
            includeDisabled: false,
            includeActive: true,
            onlyUsers: false,
            disabledAsRole: SCIMTonkeanRole.PROCESS_CONTRIBUTOR,
            SCIMRolesType: SCIMRolesType.CALCULATED,
        });
    }, [getProjectPeople, project.id]);

    useEffect(() => {
        loadActiveProcessContributors();
    }, [loadActiveProcessContributors]);

    useEffect(() => {
        setActiveProcessContributors(totalPeople);
    }, [totalPeople]);

    const processContributorsCountExceedingQuota = useMemo<boolean>(() => {
        return !!(
            project?.projectBundleConfiguration &&
            activeProcessContributors > project?.projectBundleConfiguration.processContributorsQuota
        );
    }, [activeProcessContributors, project?.projectBundleConfiguration]);

    const processContributorsCountReachedQuota = useMemo<boolean>(() => {
        return !!(
            project?.projectBundleConfiguration &&
            activeProcessContributors >= project?.projectBundleConfiguration.processContributorsQuota
        );
    }, [activeProcessContributors, project?.projectBundleConfiguration]);

    return {
        activeProcessContributors,
        activeProcessContributorsLoading: peopleLoading.any,
        processContributorsCountReachedQuota,
        processContributorsCountExceedingQuota,
        loadActiveProcessContributors,
    };
}

export default useProjectActiveProcessContributors;
