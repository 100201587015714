import { RegexExpressionPart } from '../RegexExpressionPart';

import { Theme } from '@tonkean/tui-theme';

/**
 * A regex group
 */
export class RegexParser {
    public static parseRegex(regexExpression: string): RegexExpressionPart[] {
        // Reset part count
        this.partCounter = 0;
        // Parts from the regex separator
        const originalParts = this.generateRegexExpressionPartsFromExpression(regexExpression);
        // Parts with wrappers (full matching parts, etc)
        const parts: RegexExpressionPart[] = [...originalParts];
        // A stack of group openers, to match with a closing
        // bracket and create an inner group
        const groupStack: RegexExpressionPart[] = [];

        originalParts.forEach((part) => {
            // If a group opening
            if (part.value.startsWith('(')) {
                return this.parseGroupOpening(part, groupStack);
            }

            // If a negative char matching part
            if (part.value.startsWith('[') && part.value.endsWith(']')) {
                return this.parseCharGroup(part);
            }

            // Group closing
            if (part.value.startsWith(')')) {
                return this.parseGroupClosing(part, regexExpression, parts, groupStack);
            }

            // Backslash token
            if (part.value.startsWith('\\')) {
                return this.parseBackslash(part);
            }

            // Regex operator
            const regexOperator = this.regexOperators.find((regexOperator) => regexOperator.operator === part.value);
            if (regexOperator) {
                part.label = regexOperator.label;
                part.color = Theme.current.palette.regex.expression.regexOperator;
                return;
            }

            // Regex range
            const regexRange = part.value.match(this.charCountGroupRegex);
            if (regexRange) {
                if (regexRange[2] === undefined) {
                    part.label = `Match exactly ${regexRange[1]} times`;
                } else if (regexRange[3] === '?') {
                    part.label = `Matches between ${regexRange[1]} and ${
                        regexRange[2] || 'unlimited'
                    } times, as few times as possible`;
                } else {
                    part.label = `Matches between ${regexRange[1]} and ${
                        regexRange[2] || 'unlimited'
                    } times, as many times as possible`;
                }
                part.color = Theme.current.palette.regex.expression.regexOperator;
                return;
            }

            // Char
            part.color = Theme.current.palette.regex.expression.char;
            part.index = undefined;
        });

        return parts;
    }

    /**
     *  Generate list of RegexExpressionPart from a regex expression
     *
     *  @param regexExpression - the regex expression to split to parts
     *  @returns list of regex expression parts
     */
    private static generateRegexExpressionPartsFromExpression(regexExpression: string): RegexExpressionPart[] {
        const parts = [...regexExpression.matchAll(this.separatorRegex)];
        return parts.map((part) => new RegexExpressionPart(part[0], part.index, part.index + part[0].length));
    }

    /**
     * Parse regex expression part of group opening `(`.
     *
     * @param part - the regex part
     * @param groupStack - a list of regex parts of group openers
     */
    private static parseGroupOpening(part: RegexExpressionPart, groupStack: RegexExpressionPart[]) {
        const groupType = this.regexGroupTypes.find((regexToken) => part.value.startsWith(regexToken.startsWith));
        part.label = groupType?.label;

        // If it's a capturing group, count it's index
        if (groupType?.startsWith === '(') {
            this.partCounter += 1;
            part.groupIndex = this.partCounter;
        }

        // Make group unhoverable
        part.index = undefined;
        part.color = Theme.current.palette.regex.expression.group;

        groupStack.push(part);
    }

    /**
     * Parse regex expression part of group closing `)`.
     *
     * @param part - the regex part
     * @param regexExpression - the full regex expression
     * @param parts - a list of regex parts
     * @param groupStack - a list of regex parts of group openers
     */
    private static parseGroupClosing(
        part: RegexExpressionPart,
        regexExpression: string,
        parts: RegexExpressionPart[],
        groupStack: RegexExpressionPart[],
    ) {
        // Make part unhoverable
        part.index = undefined;
        part.color = Theme.current.palette.regex.expression.group;

        const opening = groupStack.pop();
        if (!opening) {
            // This should not happen!
            return;
        }

        // Generate RegexExpressionGroup to wrap the part
        const groupWrapper = new RegexExpressionPart(
            regexExpression.substring(opening.startIndex, part.endIndex),
            opening.startIndex,
            part.endIndex,
        );
        if (opening.groupIndex) {
            groupWrapper.label = `${opening.label} ${opening.groupIndex}`;
        } else {
            groupWrapper.label = opening.label;
        }

        // Set the part wrapper color to the same
        // as of a regular character, because it will
        // have color on it's brackets.
        groupWrapper.color = Theme.current.palette.regex.expression.char;
        groupWrapper.groupIndex = opening.groupIndex;

        // Add the wrapper to the list of parts
        parts.push(groupWrapper);

        // Clear the opening object, to prevet tooltip
        opening.label = undefined;
        opening.groupIndex = undefined;
    }

    /**
     * Parse regex expression part of group closing `[A-z]`.
     *
     * @param part - the regex part
     */
    private static parseCharGroup(part: RegexExpressionPart) {
        if (part.value.startsWith('[^')) {
            // Negative char matching part
            part.label = 'Match any character except the ones between the square brackets';
        } else {
            // Positive char matching part
            part.label = 'Match any character that between the square brackets';
        }

        part.color = Theme.current.palette.regex.expression.charsGroup;
    }

    /**
     * Parse regex expression part of backslash token (for example, `\n`)
     *
     * @param part - the regex part
     */
    private static parseBackslash(part: RegexExpressionPart) {
        part.label = this.regexBackslashTokens.find((backslashToken) =>
            typeof backslashToken.token === 'string'
                ? backslashToken.token === part.value
                : backslashToken.token.test(part.value),
        )?.label;
        part.color = Theme.current.palette.regex.expression.backslash;
    }

    /**
     * List of regex single char operators.
     */
    private static regexOperators = [
        { label: 'Match as few characters as possible', operator: '*?' },
        { label: 'Match zero or one, as few as possible', operator: '??' },
        { label: 'Match one or more, as few as possible', operator: '+?' },
        { label: 'End of string', operator: '$' },
        { label: 'Start of string', operator: '^' },
        { label: 'Match any character', operator: '.' },
        { label: 'Or', operator: '|' },
        { label: 'Match one or more', operator: '+' },
        { label: 'Match zero or more', operator: '*' },
        { label: 'Match zero or one', operator: '?' },
    ];

    /**
     * List of regex groups
     */
    private static regexGroupTypes = [
        { label: 'Positive lookbehind group', startsWith: '(?<=' },
        { label: 'Negative lookbehind group', startsWith: '(?<!' },
        { label: 'Non-capturing group', startsWith: '(?:' },
        { label: 'Positive lookahead group', startsWith: '(?=' },
        { label: 'Negative lookahead group', startsWith: '(?!' },
        { label: 'Atomic group', startsWith: '(?>' },
        { label: 'Capturing group', startsWith: '(' },
        // Catch all
        { label: 'Group', startsWith: '' },
    ];

    /**
     * List of regex tokens that starts with backslash
     */
    private static regexBackslashTokens = [
        { label: 'Newline', token: '\\n' },
        { label: 'Carriage return', token: '\\r' },
        { label: 'Tab', token: '\\t' },
        { label: 'Null character', token: '\\0' },
        { label: 'Any whitespace character', token: '\\s' },
        { label: 'Any non-whitespace character', token: '\\S' },
        { label: 'Any digit', token: '\\d' },
        { label: 'Any non-digit', token: '\\D' },
        { label: 'Any word character', token: '\\w' },
        { label: 'Any non-word character', token: '\\W' },
        { label: 'Vertical whitespace character', token: '\\v' },
        { label: 'Start of string', token: '\\A' },
        { label: 'End of string', token: '\\Z' },
        { label: 'A word boundary', token: '\\b' },
        { label: 'Non-word boundary', token: '\\B' },
        { label: 'Complete match contents', token: '\\0' },
        { label: 'Tab', token: '\\t' },
        { label: 'Carriage return', token: '\\r' },
        { label: 'Form-feed', token: '\\f' },
        { label: 'Uppercase Transformation', token: '\\U' },
        { label: 'Lowercase Transformation', token: '\\L' },
        { label: 'Terminate any Transformation', token: '\\E' },
        { label: 'Any whitespace character', token: '\\s' },
        { label: 'Any non-whitespace character', token: '\\S' },
        { label: 'Any digit', token: '\\d' },
        { label: 'Any non-digit', token: '\\D' },
        { label: 'Any word character', token: '\\w' },
        { label: 'Any non-word character', token: '\\W' },
        { label: 'A word boundary', token: '\\b' },
        { label: 'Non-word boundary', token: '\\B' },
        { label: 'Hex character', token: /^\\x[\dA-Fa-f]{2}$/g },
        { label: 'Unicode escape', token: /^\\u[\dA-Fa-f]{4}$/g },
        { label: 'Control character escape', token: /^\\c[A-Za-z]$/g },
        { label: 'Octal character', token: /^\\0(?:[0-3][0-7]{0,2}|[4-7][0-7]?)?$/g },
        { label: 'Match nth subpattern or contents in nth capture group', token: /^\\[1-9]\d*$/g },
        { label: 'Character literal', token: /^\\.$/g },
        { label: 'Character literal', token: '\\\\' },
    ];

    /**
     * A regex expression the groups regex expressions -
     * groups, char groups, backslash, etc.
     */
    private static separatorRegex =
        /\[\^?]?(?:[^\\\]]+|\\[\S\s]?)*]?|\\(?:0(?:[0-3][0-7]{0,2}|[4-7][0-7]?)?|[1-9]\d*|x[\dA-Fa-f]{2}|u[\dA-Fa-f]{4}|c[A-Za-z]|[\S\s]?)|\((?:\?(?:[:=!]|<[=!])?)?|(?:[?*+]|{\d+(?:,\d*)?})\??|[^.?*+^${[()|\\]+|./gm;

    /**
     * A regex expression the groups regex expressions -
     * groups, char groups, backslash, etc.
     */
    private static charCountGroupRegex = /{(\d+)(?:,(\d*))?}(\?)?/;

    /**
     * Counter to get the group id
     */
    private static partCounter = 0;
}
