import React from 'react';
import styled from 'styled-components';

import { IntakeProgressStatusCompletionStatus } from '../entities/IntakeProgressStatusInfo';
import { ReactComponent as StatusCompleteIcon } from '../images/status-tracker/intake-status-tracker-complete.svg';
import { ReactComponent as StatusCurrentIcon } from '../images/status-tracker/intake-status-tracker-current.svg';
import { ReactComponent as StatusFutureIcon } from '../images/status-tracker/intake-status-tracker-not-complete.svg';
import { ReactComponent as StatusSkippedIcon } from '../images/status-tracker/intake-status-tracker-skipped.svg';

import type { Color } from '@tonkean/tui-theme';

const completionStatusToRenderInfo = {
    [IntakeProgressStatusCompletionStatus.COMPLETE]: {
        Icon: StatusCompleteIcon,
        dataAutomation: 'intake-status-progress-single-status-icon-complete',
    },
    [IntakeProgressStatusCompletionStatus.CURRENT]: {
        Icon: StatusCurrentIcon,
        dataAutomation: 'intake-status-progress-single-status-icon-current',
    },
    [IntakeProgressStatusCompletionStatus.SKIPPED]: {
        Icon: StatusSkippedIcon,
        dataAutomation: 'intake-status-progress-single-status-icon-skipped',
    },
    [IntakeProgressStatusCompletionStatus.FUTURE]: {
        Icon: StatusFutureIcon,
        dataAutomation: 'intake-status-progress-single-status-icon-future',
    },
};

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
`;

interface Props {
    completionStatus: IntakeProgressStatusCompletionStatus;
    isLast: boolean;
    color: Color;
}

const IntakeStatusProgressBarCompletionStatusIcon: React.FC<Props> = ({ completionStatus, isLast, color }) => {
    const completionStatusForIcon =
        isLast && completionStatus === IntakeProgressStatusCompletionStatus.CURRENT
            ? IntakeProgressStatusCompletionStatus.COMPLETE
            : completionStatus;
    const Icon = completionStatusToRenderInfo[completionStatusForIcon].Icon;
    const dataAutomation = completionStatusToRenderInfo[completionStatusForIcon].dataAutomation;

    return (
        <IconContainer>
            <Icon color={color} style={{ flexShrink: 0 }} data-automation={dataAutomation} />
        </IconContainer>
    );
};

export default IntakeStatusProgressBarCompletionStatusIcon;
