import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class StringCharAtFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.STRING_CHAR_AT;
    override readonly sign = 'CharAt';
    override readonly displayName = 'Char At';
    override readonly description = 'Will return the char at the given index';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input String',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Character Index',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
