import React from 'react';
import styled from 'styled-components';

import { ErrorMessage, useJsonStringify } from '@tonkean/infrastructure';
import { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Textarea = styled.textarea`
    height: 100%;
    width: 100%;
    border: 1px solid ${Theme.current.palette.general.border};
    border-radius: 3px;
    font-weight: 300;
    font-size: ${FontSize.SMALL_12};
    line-height: 20px;
    padding: 10px 20px;
    resize: none;
    flex-grow: 1;
`;

const StyledClickableLink = styled(ClickableLink)`
    display: inline-block;
`;

const TruncatedDataNote = styled(ErrorMessage)`
    margin-bottom: 8px;
`;

interface Props {
    object: any;
    inspectTabType: WorkerRunLogicInspectTabType;
}

const tabTypeToFriendlyName = {
    [WorkerRunLogicInspectTabType.RESPONSE_JSON]: 'Response',
    [WorkerRunLogicInspectTabType.REQUEST_JSON]: 'Request',
};

const SingleWorkerRunActionInspectJson: React.FC<Props> = ({ object, inspectTabType }) => {
    const { json, isTruncated } = useJsonStringify(object, 2000, true);

    const downloadRequestFile = () => {
        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(object, undefined, 4)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `History Run ${tabTypeToFriendlyName[inspectTabType]}.txt`;
        document.body.append(element);
        element.click();
    };

    return (
        <>
            {isTruncated && (
                <TruncatedDataNote warn>
                    Partial {tabTypeToFriendlyName[inspectTabType].toLowerCase()} message is presented due to size
                    limitation. For more details please{' '}
                    <StyledClickableLink onClick={downloadRequestFile}>
                        download full {tabTypeToFriendlyName[inspectTabType].toLowerCase()}
                    </StyledClickableLink>
                    .
                </TruncatedDataNote>
            )}
            <Textarea value={json} readOnly />
        </>
    );
};

export default SingleWorkerRunActionInspectJson;
