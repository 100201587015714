import { getGroupsSectionsIds } from '@tonkean/constants';

function ManageGroupsModalCtrl($scope, $rootScope, modalUtils, groupManager, groupInfoManager, requestedTab) {
    $scope.pm = $rootScope.pm;
    $scope.as = $rootScope.as;

    $scope.isHidden = false;
    const sectionsIds = getGroupsSectionsIds();

    $scope.tabs = {};
    $scope.tabs[sectionsIds.all] = { name: 'All business reports', filter: { dashboardHidden: false } };
    $scope.tabs[sectionsIds.starred] = { name: 'Starred', filter: { isStarred: true, dashboardHidden: false } };
    $scope.tabs[sectionsIds.createdByMe] = {
        name: 'Created by me',
        filter: { creator: { id: $scope.as.currentUser.id }, dashboardHidden: false },
    };

    $scope.data = {
        selectedTab: requestedTab,
    };

    $scope.init = function () {
        $scope.isLoadingGroups = true;
        groupInfoManager.getGroups(true).finally(function () {
            $scope.isLoadingGroups = false;
        });
    };

    $scope.openNewListModal = function () {
        const modal = modalUtils.openSetupGroupModal(true, true, null, null, true);
        $scope.isHidden = true;
        modal.result.then(
            function () {
                $scope.$dismiss();
            },
            function () {
                $scope.isHidden = false;
            },
        );
    };

    $scope.isSearching = false;
    $scope.onSearch = function (text) {
        if (text !== '') {
            $scope.isSearching = true;
        } else {
            $scope.isSearching = false;
        }
    };

    $scope.toggleStar = function (group) {
        // change for ui
        group.isStarred = !group.isStarred;
        // update server
        groupManager.toggleStar(group.id, group.isStarred);
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('ManageGroupsModalCtrl', ManageGroupsModalCtrl);
