import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ProjectIntegrationEntityHandlePayloadResponseView from './ProjectIntegrationEntityHandlePayloadResponseView';
import ProjectIntegrationEntityLiveStreamWebhookPayloadSelector from './ProjectIntegrationEntityLiveStreamWebhookPayloadSelector';
import ProjectIntegrationEntityLiveStreamWebhookPayloadViewer from './ProjectIntegrationEntityLiveStreamWebhookPayloadViewer';
import { ReactComponent as SpaceShip } from '../../../../../images/icons/spaceship-large.svg';

import { AnimatedSwitch, AnimatedSwitchItem, H4, StateMessage, Tab, Tabs, Tooltip } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { WebhookPayloadHandlingType } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntityResponseHandlingDefinition, WebhookPayload } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledAnimatedSwitchItem = styled(AnimatedSwitchItem)`
    flex-grow: 1;
    overflow: hidden;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    background-color: ${Theme.colors.gray_100};
`;

const ContentWrapper = styled.div`
    padding: 6px 16px 16px 16px;
    white-space: pre;
    overflow-y: scroll;
    height: 100%;
`;

const TabsWrapper = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1;
`;

const StyledStateMessage = styled(StateMessage)`
    height: 75%;
`;

const TestPickerWrapper = styled.div`
    padding: 20px 16px 15px 16px;
`;

const HeaderText = styled(H4)`
    margin: 4px 0 8px 0;
`;

enum PreviewTabType {
    EXTRACTED_ENTITIES = 'EXTRACTED_ENTITIES',
    WEBHOOK_PAYLOAD = 'WEBHOOK_PAYLOAD',
}

interface Props {
    entity: ProjectIntegrationEntity;
    webhookPayloads: WebhookPayload[] | undefined;
    isLoadingWebhookPayloads: boolean;
    selectedWebhookPayload: WebhookPayload | undefined;
    setSelectedWebhookPayload: (webhookPayload: WebhookPayload | undefined) => void;
    handleResponseDefinition: ProjectIntegrationEntityResponseHandlingDefinition | undefined;
    webhookPayloadHandlingType: WebhookPayloadHandlingType | undefined;
}

const ProjectIntegrationEntityLiveStreamRightLayout: React.FC<Props> = ({
    entity,
    webhookPayloads,
    isLoadingWebhookPayloads,
    selectedWebhookPayload,
    setSelectedWebhookPayload,
    handleResponseDefinition,
    webhookPayloadHandlingType,
}) => {
    const onWebhookPayloadSelected = (webhookPayloadId: string) => {
        const webhookPayload = webhookPayloads?.find(
            (webhookPayloadTemp) => webhookPayloadTemp.id === webhookPayloadId,
        );
        setSelectedWebhookPayload(webhookPayload);
    };

    const [tab, setTab] = useState<PreviewTabType>(PreviewTabType.WEBHOOK_PAYLOAD);

    // Memo for enabling/disabling handle response tab.
    const isHandleResponseConfigured = useMemo(() => {
        return handleResponseDefinition?.validationStatus?.isValid;
    }, [handleResponseDefinition]);

    // Change selected tab if the current tab became disabled.
    useEffect(() => {
        if (!isHandleResponseConfigured && tab === PreviewTabType.EXTRACTED_ENTITIES) {
            setTab(PreviewTabType.WEBHOOK_PAYLOAD);
        }
    }, [tab, setTab, isHandleResponseConfigured]);

    return (
        <Wrapper>
            <TestPickerWrapper>
                <HeaderText $bold>Preview Webhook Payloads</HeaderText>
                <ProjectIntegrationEntityLiveStreamWebhookPayloadSelector
                    webhookPayloads={webhookPayloads}
                    isLoading={isLoadingWebhookPayloads}
                    selectedWebhookPayloadId={selectedWebhookPayload?.id}
                    onWebhookPayloadSelected={onWebhookPayloadSelected}
                />
            </TestPickerWrapper>

            {webhookPayloads?.length !== 0 && (
                <TabsWrapper>
                    <Tabs showBackground={false} value={tab} onChange={setTab}>
                        <Tab label={PreviewTabType.WEBHOOK_PAYLOAD}>Webhook Payload</Tab>
                        <Tooltip
                            disabled={isHandleResponseConfigured}
                            content="In order to see the extracted entities, response handling must be configured and saved"
                        >
                            <Tab label={PreviewTabType.EXTRACTED_ENTITIES} disabled={!isHandleResponseConfigured}>
                                Extracted Entities
                            </Tab>
                        </Tooltip>
                    </Tabs>

                    {selectedWebhookPayload && (
                        <AnimatedSwitch activeLabel={tab}>
                            <StyledAnimatedSwitchItem label={PreviewTabType.WEBHOOK_PAYLOAD}>
                                <ContentWrapper>
                                    <ProjectIntegrationEntityLiveStreamWebhookPayloadViewer
                                        jsonPayload={selectedWebhookPayload.webhookPayload.jsonPayload}
                                        isResponse
                                    />
                                </ContentWrapper>
                            </StyledAnimatedSwitchItem>

                            <StyledAnimatedSwitchItem label={PreviewTabType.EXTRACTED_ENTITIES}>
                                <ContentWrapper>
                                    <ProjectIntegrationEntityHandlePayloadResponseView
                                        entity={entity}
                                        handlingDefinition={handleResponseDefinition}
                                        webhookPayloadHandlingType={webhookPayloadHandlingType}
                                        payloadId={selectedWebhookPayload.id}
                                    />
                                </ContentWrapper>
                            </StyledAnimatedSwitchItem>
                        </AnimatedSwitch>
                    )}
                </TabsWrapper>
            )}

            {webhookPayloads?.length === 0 && (
                <StyledStateMessage
                    title="No webhook was received for this data source"
                    icon={<SpaceShip className="tnk-icon" />}
                />
            )}
        </Wrapper>
    );
};

export default ProjectIntegrationEntityLiveStreamRightLayout;
