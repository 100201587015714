import React, { useContext } from 'react';

import type {
    Group,
    MarketplaceItem,
    ModuleMarketplaceItem,
    SolutionMarketplaceItem,
    TonkeanId,
    TonkeanType,
    WorkflowFolderCategory,
    WorkflowFolderCategoryProcessParticipants,
} from '@tonkean/tonkean-entities';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';

export interface MarketplaceItemCreateModalContextDefinition<T extends MarketplaceItem> {
    workflowFolder?: WorkflowFolder;
    workflowFolderCategories?: WorkflowFolderCategory[];
    groups?: Group[];
    entityIdToCopy?: TonkeanId;
    existingMarketplaceItem?: T;
    projectIntegrationId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    workflowFolderCategoryProcessParticipants?: WorkflowFolderCategoryProcessParticipants[];
}

const MarketplaceItemCreateModal = React.createContext<MarketplaceItemCreateModalContextDefinition<MarketplaceItem>>(
    {},
);

export function useSolutionMarketplaceItemCreateModal() {
    return useContext(
        MarketplaceItemCreateModal,
    ) as MarketplaceItemCreateModalContextDefinition<SolutionMarketplaceItem>;
}

export function useModuleMarketplaceItemCreateModal() {
    return useContext(MarketplaceItemCreateModal) as MarketplaceItemCreateModalContextDefinition<ModuleMarketplaceItem>;
}

export default MarketplaceItemCreateModal;
