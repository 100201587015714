import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type {
    IntegrationSupportedEntity,
    ProjectIntegration,
    ProjectIntegrationAction,
} from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    border: 1px solid ${Theme.colors.gray_300};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: grid;
    grid-template-columns: 30px auto 35px;
    grid-template-rows: 3fr 2fr 2fr;
    min-height: 80px;

    grid-template-areas:
        'icon entity sticker'
        'name name name'
        'empty empty empty';
`;

const ProjectIntegrationIconWrapper = styled.div`
    grid-area: icon;
    padding-left: 8px;
    padding-top: 8px;
`;

const MyNewEntityHeader = styled.div`
    grid-area: entity;
    font-weight: 500;
    padding-top: 8px;
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
`;

const ProjectIntegrationNameAndEntityTypeWrapper = styled.div`
    grid-area: name;
    font-weight: 400;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    padding-left: 8px;
    padding-bottom: 4px;
`;

const NewStickerWrapper = styled.div`
    grid-area: sticker;
    background-color: ${Theme.colors.primaryHighlight};
    border-radius: 100px;
    font-weight: 900;
    height: 13px;
    font-size: ${FontSize.XXSMALL_8};
    color: ${Theme.current.palette.colorPicker.HEX_FFFFFF};
    width: 30px;
    text-align: center;
    line-height: 13px;
    margin-top: 5px;
    margin-right: 5px;
`;

const EmptySpace = styled.div`
    grid-area: empty;
    border-top: 1px solid ${Theme.colors.gray_300};
`;

interface Props {
    projectIntegration: ProjectIntegration;
    projectIntegrationAction: ProjectIntegrationAction;
    entity: IntegrationSupportedEntity | undefined;
}

const NewMatchedEntityPreviewCard: React.FC<Props> = ({ projectIntegration, entity }) => {
    const integrations = useAngularService('integrations');

    // Memo for integrationName form the const integrations config.
    const integrationName = useMemo(() => {
        return (
            integrations?.getIntegrationsConfig()[projectIntegration.integration.integrationType.toLowerCase()]
                ?.displayName || projectIntegration.integration.integrationType.toLowerCase()
        );
    }, [integrations, projectIntegration.integration.integrationType]);
    return (
        <Wrapper>
            <ProjectIntegrationIconWrapper>
                <ProjectIntegrationIcon
                    integrationType={projectIntegration.integration.integrationType}
                    width={15}
                    iconUrl={projectIntegration.iconUrl}
                />
            </ProjectIntegrationIconWrapper>
            <MyNewEntityHeader>My {entity?.pluralLabel}</MyNewEntityHeader>
            <ProjectIntegrationNameAndEntityTypeWrapper>
                {integrationName} / {entity?.pluralLabel}
            </ProjectIntegrationNameAndEntityTypeWrapper>
            <NewStickerWrapper>New</NewStickerWrapper>
            <EmptySpace />
        </Wrapper>
    );
};

export default NewMatchedEntityPreviewCard;
