/**
 * Created by sagieliyahu on 07/10/15.
 */
import { analyticsWrapper } from '@tonkean/analytics';

function UpgradeCtrl(
    $scope,
    $rootScope,
    $q,
    $state,
    $window,
    $timeout,
    consts,
    utils,
    tonkeanService,
    authenticationService,
    projectManager,
) {
    /**
     * This component used to inherit the scope of the settingsCtrl when rendered from the $state.
     * Now it is rendered with `ng-transclude`, so it doesn't inherit the scope, and we must manually add
     * the values from the parent scope (`$scope.$parent.$parent`) into the `$scope`.
     */
    Object.entries($scope.$parent?.$parent).forEach(([key, value]) => {
        if (!key.startsWith('$')) {
            $scope[key] = value;
        }
    });

    $scope.pm = projectManager;
    $scope.upgradeStepsMap = { select: 'SELECT', info: 'INFO', payment: 'PAYMENT', assignUsers: 'ASSIGN_USERS' };
    const upgradeSteps = [
        $scope.upgradeStepsMap.select,
        $scope.upgradeStepsMap.info,
        $scope.upgradeStepsMap.payment,
        $scope.upgradeStepsMap.assignUsers,
    ];

    $scope.Math = window.Math;

    $scope.data = {
        loadingStripe: false,
        countries: consts.getCountries(),
        usaStates: consts.getUsaStates(),
        currentStep: upgradeSteps[0],
        hideMenu: false,
        postPaymentDetails: {},
        totalFullUsers: 1,
        fullUsersToAdd: [],
        fullUsersToAddTemp: [],
        newLicense: null,
        upgradeSuggestion: 1,
        upgradeQuantity: 1,
    };

    // A sync object passed to the tnkCreditCard component. It overrides the submit function with its own submit logic.
    $scope.creditCardSyncObject = {
        submit() {
            console.log('tnkCreditCard component failed in initialize.');
        },
    };
    // The card extra data the form holds (zipCode and name). This data is also sent to stripe via the tnkCreditCard component.
    $scope.cardExtraData = {};

    $scope.licenseData = {
        country: 'US',
        state: 'CA',
    };

    $scope.forms = {};

    $scope.init = function () {
        $scope.posting = false;
        $scope.loadingPeople = false;

        initUpgradeSuggestion();
        // If the user is already licensed, we should send him to the main subscription page (he has already upgraded in the past).
        if ($scope.license) {
            $state.go('product.settings.license', { tab: 'overview' });
        } else {
            // Otherwise, initialize the page.
            $scope.data.loadingStripe = true;
            $scope.data.hideMenu = false;
            $scope.data.currentStep = upgradeSteps[0];

            // If in free plan, auto select a plan for the user (the plan that he will have selected is the free one - you can't upgrade to a free plan).
            if ($scope.pm.project.isLimitedLicense) {
                $scope.setPlan('YEAR');
            }
        }
    };

    $scope.$watch('loadingOrg', function (newValue, oldValue) {
        // If we come from modal - new value will be false after being true.
        // If we come from page  - new value will be false after being false (because we, as ui-view, are not loaded until loadingOrg is false).
        if (newValue === false && !utils.isNullOrUndefined(oldValue)) {
            $scope.init();
        }
    });

    $scope.loadingStripeComplete = function () {
        $scope.data.loadingStripe = false;
    };

    $scope.applyPromoCode = function () {
        if (!$scope.data.loadingPromoCode && $scope.data.promoCodeInput && $scope.data.promoCodeInput.length) {
            $scope.data.loadingPromoCode = true;
            $scope.data.promoCodeStatus = null;
            return tonkeanService
                .getPlans($scope.projectId, $scope.data.promoCodeInput)
                .then(function (data) {
                    if (data.isCouponValid) {
                        // Reset the all plans array before updating it (so we won't have any duplicates).
                        $scope.sharedData.allPlans = [];

                        for (let i = 0; i < data.plans.length; i++) {
                            const p = data.plans[i];
                            p.quantity = $scope.plan.quantity;
                            p.amountPerSeat = p.amountPerSeat / 100;
                            p.amountPerSeatPerMonth = p.amountPerSeatPerMonth / 100;
                            p.amountOff = p.amountOff ? p.amountOff / 100 : null;
                            $scope.sharedData.allPlans.push(p);
                        }
                        $scope.setCurrentTierPlans($scope.sharedData.selectedTier);

                        // Set the default plan.
                        $scope.plan = $scope.plans['YEAR'];
                        $scope.data.promoCodeStatus = 'success';
                    } else {
                        $scope.data.promoCodeStatus = 'error';
                    }
                })
                .catch(function () {
                    $scope.data.promoCodeStatus = 'error';
                })
                .finally(function () {
                    $scope.data.loadingPromoCode = false;
                });
        }
    };

    $scope.onCouponKeyDown = function ($event) {
        if (($event.code === 'Enter' || $event.keyCode === 13) && !$scope.data.loadingPromoCode) {
            $scope.applyPromoCode().finally(function () {
                $timeout(function () {
                    $event.target.focus();
                });
            });
        }
    };

    $scope.setPlan = function (id) {
        const currentQuantity = $scope.plan.quantity;
        $scope.plan = $scope.plans[id];
        $scope.plan.quantity = currentQuantity;
    };

    $scope.setTier = function (selectedTier) {
        // We need to find the key of the current plan in the plans object, to pass it to the setPlan function (will be either 'MONTH' or 'YEAR').
        let currentPlanKeyValuePair = null;
        if ($scope.plan) {
            currentPlanKeyValuePair = utils.findInObj($scope.plans, (plan) => plan.id === $scope.plan.id);
        }
        // Update the current selected tier.
        $scope.sharedData.selectedTier = selectedTier;
        // Update the current plans according to this tier.
        $scope.setCurrentTierPlans(selectedTier);
        // Update the selected plan to the matching plan in this tier.
        $scope.setPlan(currentPlanKeyValuePair ? currentPlanKeyValuePair.key : 'MONTH');
    };

    $scope.nextStep = function (formNameToValidate, initFunction) {
        let formValid = true;

        // If we got a form name that has to be valid before continuing, make sure it's valid.
        if (formNameToValidate) {
            formValid = $scope.forms[formNameToValidate].$valid;
        }

        // Continue if the form is valid.
        if (formValid) {
            // Get the current step index by finding it in the array.
            const currentStepIndex = upgradeSteps.indexOf($scope.data.currentStep);
            // Make sure we didn't pass the array's length.
            const nextStepIndex =
                currentStepIndex + 1 < upgradeSteps.length ? currentStepIndex + 1 : upgradeSteps.length - 1;
            // Set the next step.
            $scope.data.currentStep = upgradeSteps[nextStepIndex];
            // Call the initialization function if supplied.
            if (initFunction) {
                initFunction();
            }
        }
    };

    $scope.previousStep = function () {
        // Get the current step index by finding it in the array.
        const currentStepIndex = upgradeSteps.indexOf($scope.data.currentStep);
        // Make sure we are not in the first step (and that we found the current step in the array).
        if (currentStepIndex > 0) {
            // Go back one step.
            $scope.data.currentStep = upgradeSteps[currentStepIndex - 1];
        }
    };

    $scope.goToStep = function (stepId) {
        if (stepId) {
            // Find the index of the requested step in the array.
            const requestedStepIndex = upgradeSteps.indexOf(stepId);

            // Make sure we found it, and if so set the requested step.
            if (requestedStepIndex > -1 && requestedStepIndex < upgradeSteps.length) {
                $scope.data.currentStep = upgradeSteps[requestedStepIndex];
            }
        }
    };

    $scope.submit = function () {
        if ($scope.forms.frmPaymentInfo.$valid && !$scope.posting) {
            analyticsWrapper.track('Add License', { category: 'First purchase' });
            $scope.posting = true;
            $scope.creditCardSyncObject
                .submit()
                .then(function (token) {
                    $scope.plan.quantity = $scope.data.upgradeQuantity;
                    let seats = $scope.plan.quantity.toFixed(0);
                    if (seats < 1) {
                        seats = 1;
                    }

                    $scope.licenseData.token = token.id;
                    $scope.licenseData.plan = $scope.plan.id;
                    $scope.licenseData.seats = seats;

                    if ($scope.plan.coupon) {
                        $scope.licenseData.coupon = $scope.plan.coupon;
                    }

                    // Don't send the state if the country is not the US.
                    if ($scope.licenseData.country !== 'US') {
                        $scope.licenseData.state = null;
                    }

                    return tonkeanService.createOrUpdateLicense($scope.pm.project.id, $scope.licenseData);
                })
                .then(function (newLicense) {
                    // Take the data we need to present to the user from the new license.
                    $scope.data.postPaymentDetails.last4 = newLicense.license.customer.card.last4;
                    $scope.data.postPaymentDetails.periodStart =
                        newLicense.license.upcomingInvoice.lines[0].periodStart;
                    $scope.data.postPaymentDetails.periodEnd = newLicense.license.upcomingInvoice.lines[0].periodEnd;

                    // Also save the license so when the user finishes the purchase flow we can set it as license.
                    $scope.data.newLicense = newLicense;

                    let updateUserPromise = $q.resolve();

                    analyticsWrapper.track('Upgraded to paid', { category: 'License' });

                    // If we got here, createLicense above run successfully.
                    $scope.$emit('alert', {
                        type: 'success',
                        msg: `${$scope.pm.project.name} was upgraded successfully!`,
                    });

                    // update the project obj from the server
                    $scope.pm.getProjectData(true);
                    $scope.setLicenseObject($scope.data.newLicense);

                    // We update the user to be a licenced user.
                    // The projectContext might not exist because the user is not updated.
                    if (
                        authenticationService.currentUser.projectContexts &&
                        authenticationService.currentUser.projectContexts[$scope.pm.project.id]
                    ) {
                        // If the project context exists, set the user as licensed.
                        authenticationService.currentUser.projectContexts[$scope.pm.project.id].isLicensed = true;
                    } else {
                        // Get a fresh user and update the authentication service.
                        updateUserPromise = tonkeanService.getCurrentUser().then(function (freshUser) {
                            authenticationService.updateCurrentUser(freshUser);
                        });
                    }

                    // We call finally because we don't want to fail the flow if we got an error updating the user.
                    updateUserPromise.finally(function () {
                        $scope.data.hideMenu = true;
                        $scope.loadFullUsers();

                        if ($scope.plan.quantity <= 1) {
                            // If the user only bought one seat, no point in showing him the "add users" screen.
                            $scope.completeUpgrade();
                        } else {
                            // Always show the current user (the buyer) as one of the users that are assigned.
                            if (!$scope.data.fullUsersToAddTemp) {
                                $scope.data.fullUsersToAddTemp = [];
                            }
                            $scope.data.fullUsersToAddTemp.push(authenticationService.currentUser);

                            if ($rootScope.lps.isPricingVersion($rootScope.lps.pricingVersions.V2)) {
                                // If in V2 pricing, try to add the all the current users to the temp tag control.
                                $scope.loadValidUsersToTempListAfterSubmit();
                            }

                            // More than one seat: continue to the assign users step.
                            $scope.goToStep($scope.upgradeStepsMap.assignUsers);
                        }
                    });
                })
                .catch(function (error) {
                    $scope.$emit('alert', error);
                })
                .finally(function () {
                    $scope.posting = false;
                });
        }
    };

    $scope.completeUpgrade = function () {
        // We're done. Set the new license to the parent scope (the organizationCtrl).
        $scope.setLicenseObject($scope.data.newLicense);

        // Refresh the project object from the server and go to the license overview page.
        $scope.pm.getProjectData(true).then(function () {
            $scope.$emit('alert', {
                type: 'success',
                msg: 'Success!',
            });

            $scope.close();
        });
    };

    $scope.loadFullUsers = function () {
        if ($rootScope.lps.isPricingVersion($rootScope.lps.pricingVersions.V1)) {
            $scope.loadingFullUsers = true;
            tonkeanService
                .getTeamMembers($scope.projectId, { excludeMe: false, onlyLicensed: true })
                .then(function (data) {
                    $scope.data.totalFullUsers = data ? data.total : 1;
                })
                .catch(function (error) {
                    $scope.usersError = error;
                })
                .finally(function () {
                    $scope.loadingFullUsers = false;
                });
        }
    };

    $scope.loadValidUsersToTempListAfterSubmit = function () {
        $scope.loadingFullUsers = true;

        // Make sure the full users array is defined.
        if (!$scope.data.fullUsersToAddTemp) {
            $scope.data.fullUsersToAddTemp = [];
        }

        // Exclude the current user (the buyer). He is always added to this list separately (before this function).
        const options = {
            excludeMe: true,
            excludeBuyer: false,
        };
        return tonkeanService
            .getProjectPotentialSeats($scope.projectId, options)
            .then(function (data) {
                for (let i = 0; i < data.seats.length; i++) {
                    // Don't allow adding more people than the plan's quantity (minus one, because the buyer is also in that list).
                    if (i === $scope.plan.quantity - 1) {
                        break;
                    }

                    const p = data.seats[i];
                    $scope.data.fullUsersToAddTemp.push(p);
                }

                $scope.onFullUserTagsChanged();
            })
            .finally(() => {
                $scope.loadingFullUsers = false;
            });
    };

    $scope.addFullUsers = function () {
        // Don't allow adding full users if we're already at it.
        if ($scope.addingUsers) {
            return;
        }

        if ($scope.data.fullUsersToAdd && $scope.data.fullUsersToAdd.length) {
            $scope.addingUsers = true;

            const fullUsersToAdd = angular.copy($scope.data.fullUsersToAdd);

            // Check if there are not enough licenses.
            innerAddPeople(angular.copy(fullUsersToAdd), true)
                .then(function () {
                    $scope.data.fullUsersToAdd = [];
                    $scope.completeUpgrade();
                })
                .catch(function (error) {
                    $scope.addingUsers = false;
                    $scope.usersError = error;
                });
        } else {
            // The assign seats was clicked without any users selected (happens if the user has added himself to the users to add).
            // Just complete the upgrade.
            $scope.completeUpgrade();
        }
    };

    $scope.onFullUserTagsRemoved = function ($tag) {
        // Don't allow removing the current user or a licensed user.
        if ($tag.id === $scope.as.currentUser.id || $tag.projectContext.isLicensed) {
            return false;
        }

        $scope.onFullUserTagsChanged();
        return true;
    };

    $scope.onFullUserTagsChanged = function () {
        // Go over the fullUsersToAddTemp array, and add users who are not the current user to the fullUsersToAdd array.
        if ($scope.data.fullUsersToAddTemp && $scope.data.fullUsersToAddTemp.length) {
            const currentUser = authenticationService.currentUser;
            $scope.data.fullUsersToAdd = $scope.data.fullUsersToAddTemp.filter(
                (person) => (person.id && person.id !== currentUser.id) || person.email !== currentUser.email,
            );
        }
    };

    /**
     * When the user clicks the x button of the modal
     */
    $scope.close = function () {
        // if the user bought a license we must refresh the page because tnkRequire removed ng-click of some
        // elements and we must restore them
        if ($scope.data.newLicense) {
            // hard reload so all the modals would close + tnkRequire would refresh
            $window.location.reload();
        } else {
            if ($scope.$dismiss) {
                // if we are a modal, close it
                $scope.$dismiss();
            } else {
                // otherwise navigate to the overview page
                $state.go('product.settings.license', { tab: 'overview' }, { reload: true });
            }
        }
    };

    function innerAddPeople(fullUsersToAdd, addSeatIfNeeded) {
        analyticsWrapper.track('Added full users', { category: 'First purchase' });

        const addUserPromises = [];

        /* jshint loopfunc:true */
        for (const person of fullUsersToAdd) {
            addUserPromises.push(setPersonAsFullUser(person, addSeatIfNeeded));
        }

        return $q.all(addUserPromises);
    }

    function setPersonAsFullUser(person, addSeatIfNeeded) {
        if (!person.id) {
            // create the temp people first
            return tonkeanService
                .sendInvites($scope.pm.project.id, [
                    {
                        email: person.email,
                        name: person.email.split('@')[0],
                    },
                ])
                .then(function (data) {
                    person = data.invites[0].person;
                    return tonkeanService.updatePersonLicense($scope.pm.project.id, person.id, true, addSeatIfNeeded);
                });
        } else {
            return tonkeanService.updatePersonLicense($scope.pm.project.id, person.id, true, addSeatIfNeeded);
        }
    }

    function initUpgradeSuggestion() {
        if ($rootScope.lps.isPricingVersion($rootScope.lps.pricingVersions.V2)) {
            const options = {
                excludeMe: false,
                excludeBuyer: false,
            };
            tonkeanService.getProjectPotentialSeats($scope.projectId, options).then(function (data) {
                $scope.data.upgradeSuggestion = data.seats && data.seats.length ? data.seats.length : 1;
                $scope.data.upgradeQuantity = $scope.data.upgradeSuggestion;
                $scope.data.tempQuantity = $scope.data.upgradeSuggestion;
            });
        } else {
            $scope.data.upgradeSuggestion = $scope.plan.quantity;
            $scope.data.upgradeQuantity = $scope.data.upgradeSuggestion;
            $scope.data.tempQuantity = $scope.data.upgradeSuggestion;
        }
    }
}

export default angular.module('tonkean.app').controller('UpgradeCtrl', UpgradeCtrl);
