import React, { useCallback, useMemo, useState } from 'react';

import checkAllWidgetsAreValid from './checkAllWidgetsAreValid';
import updateTouchedWidget from '../SystemInterface/utils/updateTouchedWidget';

import {
    type TonkeanId,
    type TonkeanType,
    Validation,
    type ValidationResult,
    type ValidationState,
} from '@tonkean/tonkean-entities';

/**
 * Context for syncing the validation state between inner widgets and the container interface.
 */
export interface InterfaceSubmitValidationContextProps {
    validation: Validation;
    validationResult: Record<string, ValidationResult[]>;
    setValidationKey: (key: string, value: ValidationResult[]) => void;
    resetValidation: () => void;
    setWidgetsIdsToValidate: (keys: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[]) => void;
    forceShowValidation: boolean;
    setForceShowValidation: React.Dispatch<React.SetStateAction<boolean>>;
    onValidationChanged: (errors: string[], widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>) => void;
    setTouchedWidgetId: (value: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>) => void;
    touchedWidgetsIds: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[];
}

export const useBuildInterfaceSubmitValidationContext = (
    onValidationChangedCallback?: (validation: ValidationState) => void,
): InterfaceSubmitValidationContextProps => {
    const [touchedWidgetsIds, setTouchedWidgetsIds] = useState<TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[]>([]);
    const [validationResult, setValidationResult] = useState<Record<string, ValidationResult[]>>({});

    const setValidationKey = useCallback((key: string, value: ValidationResult[]) => {
        setValidationResult((previous) => ({ ...previous, [key]: value }));
    }, []);

    const [widgetsIdsToValidate, setWidgetsIdsToValidate] = useState<TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[]>(
        [],
    );

    const resetValidation = useCallback(() => {
        setValidationResult({});
    }, []);

    const trueValidation = useMemo(() => {
        return checkAllWidgetsAreValid(validationResult, widgetsIdsToValidate);
    }, [validationResult, widgetsIdsToValidate]);

    const visibleValidation = useMemo(() => {
        return checkAllWidgetsAreValid(validationResult, widgetsIdsToValidate, touchedWidgetsIds);
    }, [touchedWidgetsIds, validationResult, widgetsIdsToValidate]);

    const [forceShowValidation, setForceShowValidation] = useState(false);

    const [prevValidation, setPrevValidation] = useState<ValidationState>({
        visibleValidation: Validation.NOTHING_TO_VALIDATE,
        trueValidation: Validation.NOTHING_TO_VALIDATE,
        setForceShowValidation: () => {},
    });

    if (
        (prevValidation.trueValidation !== trueValidation || prevValidation.visibleValidation !== visibleValidation) &&
        !!onValidationChangedCallback
    ) {
        onValidationChangedCallback({ trueValidation, visibleValidation, setForceShowValidation });
        setPrevValidation({ trueValidation, visibleValidation, setForceShowValidation });
    }

    // on validation change update context for valid or invalid state - used for module sequence.
    const onValidationChanged = useCallback(
        (errors: string[], widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>) => {
            if (errors.length > 0) {
                const errorObjects = errors.map((error) => ({
                    validation: Validation.INVALID,
                    errorMessage: error,
                }));

                setValidationKey(widgetId, errorObjects);
            } else {
                setValidationKey(widgetId, [
                    {
                        validation: Validation.VALID,
                    },
                ]);
            }
        },
        [setValidationKey],
    );
    const updateTouched = useCallback(updateTouchedWidget, []);

    const setTouchedWidgetId = useCallback(
        (value: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>) => {
            setTouchedWidgetsIds(updateTouched(value));
        },
        [updateTouched],
    );

    return {
        validation: trueValidation,
        validationResult,
        resetValidation,
        setValidationKey,
        setWidgetsIdsToValidate,

        forceShowValidation,
        setForceShowValidation,

        onValidationChanged,

        touchedWidgetsIds,
        setTouchedWidgetId,
    };
};

const InterfaceSubmitValidationContext = React.createContext<InterfaceSubmitValidationContextProps>({
    setWidgetsIdsToValidate(keys: string[]): void {},
    forceShowValidation: false,
    validation: Validation.NOTHING_TO_VALIDATE,
    setValidationKey(key: string, validator: ValidationResult[]): void {},
    resetValidation(): void {},
    setForceShowValidation(value: ((prevState: boolean) => boolean) | boolean): void {},
    validationResult: {},
    onValidationChanged(errors: string[], widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>): void {},
    touchedWidgetsIds: [],
    setTouchedWidgetId(value: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>): void {},
});
export default InterfaceSubmitValidationContext;
