import React from 'react';

import SingleSnippetLineComponent from './SingleSnippetLineComponent';

import type { PreviewFindWordsSnippet } from '@tonkean/tonkean-entities';

interface Props {
    snippet: PreviewFindWordsSnippet;
}

const SingleSnippetComponent: React.FC<Props> = ({ snippet }) => {
    return (
        <>
            {snippet.lines.map((line) => (
                <div key={line.snippetLineIndex}>
                    <SingleSnippetLineComponent line={line} />
                </div>
            ))}
        </>
    );
};

export default SingleSnippetComponent;
