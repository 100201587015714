import { useMemo } from 'react';

import type { ActionWidgetOpenMatchedItemActionData } from './ActionWidgetActionData';
import useMatchedItems, { getGroupIdToMatchedItemFromMatchedData } from './useMatchedItems';

import { type Initiative, InterfaceCTAActionType, type TonkeanId, type TonkeanType } from '@tonkean/tonkean-entities';

const useMatchedItemsActionButtons = (
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
    initiative?: Pick<Initiative, 'fields'>,
) => {
    const matchedItemsData = useMatchedItems(workflowVersionId, initiative);

    const result = useMemo(() => {
        const groupIdToMatchedItemId = getGroupIdToMatchedItemFromMatchedData(matchedItemsData);
        const matchedItemsActionButtons = matchedItemsData?.map((data) => {
            const actionData: ActionWidgetOpenMatchedItemActionData = {
                id: data.fieldDefinition.id as TonkeanId<TonkeanType.FIELD_DEFINITION>,
                type: InterfaceCTAActionType.OPEN_MATCHED_ITEM,
                initiativeId: data.matchedInitiative?.id,
                customTriggerDisplayName: data.customTrigger?.displayName || data.fieldDefinition?.name || '',
                defaultLabel: data.customTrigger?.displayName || data.fieldDefinition?.name || '',
            };
            return actionData;
        });

        return { groupIdToMatchedItemId, matchedItemsActionButtons };
    }, [matchedItemsData]);

    return result;
};

export default useMatchedItemsActionButtons;
