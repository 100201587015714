import FunctionOperatorBase from './FunctionOperatorBase';
import { timeZoneField } from '../commonFields';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class DaysDiffFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.DAYS_DIFF;
    override readonly dataType = FieldType.Number;
    override readonly sign = 'DaysDiff';
    override readonly displayName = 'Days Differential';
    override readonly description = 'Will calculate the days difference between the two given fields.';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            // Can create my own display name
            displayName: 'Start date',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            // Can create my own display name
            displayName: 'End date',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            // Can create my own display name
            displayName: 'Exclude Weekends',
            dataType: FieldType.Boolean,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: false,
        },
        timeZoneField(),
    ];
}
