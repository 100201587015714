import { lateConstructController } from '@tonkean/angular-components';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';
import { analyticsWrapper } from '@tonkean/analytics';
import { VIEW_TYPES } from '@tonkean/constants';

/* @ngInject */
function SetupGroupNewCtrl(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $location,
    $q,
    $timeout,
    $sce,
    $localStorage,
    consts,
    integrations,
    modalUtils,
    modal,
    builtInListsConsts,
    projectManager,
    authenticationService,
    tonkeanService,
    utils,
    onBoardingManager,
    groupInfoManager,
    workflowFolderManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.tonkeanService = tonkeanService;
    $scope.modalUtils = modalUtils;
    $scope.as = $rootScope.as;
    $scope.consts = consts;
    $scope.integrations = integrations;
    $scope.viewTypes = VIEW_TYPES;
    $scope.utils = utils;
    $scope.builtInLists = {};
    $scope.categories = {};
    $scope.categoriesList = [];
    $scope.wfm = workflowFolderManager;
    $scope.utils = utils;

    $scope.forms = {}; // Contains our forms and enables checking its validity.
    $scope.error = {
        shortMessage: null,
        longMessage: null,
    }; // Initialize the error message to an empty state object.

    if (
        !$scope.isModal &&
        !$scope.group &&
        $stateParams.g &&
        projectManager.groupsMap &&
        projectManager.groupsMap[$stateParams.g]
    ) {
        $scope.group = projectManager.groupsMap[$stateParams.g];
    }

    $scope.mainType = 'bot';
    $scope.isInline = true;
    $scope.workersTemplateMode = true;

    $scope.data = {
        selectedTab: null,
        currentStepIndex: 0,
        // Whether or not to show the group settings screen.
        // If a group wasn't provided, we always show the group settings screen.
        showGroupSettings: $scope.group ? $scope.showGroupSettings : true,
        integrationState: {},
        groupSettings: $scope.group && !$scope.emptyOnly ? angular.copy($scope.group) : {},
        groupControlObject: {},
        sidebarColor: null,
        loadingSandboxIFrame: true,
        autoCreateList: $stateParams.autoCreateList,
        createExampleListButtonClicked: false,
        isUserAuthenticated: false,
        oldIntegrationIdToNewIntegrationIdMapping: {},
        isMakeBotFromJson: false,
        isPeopleMatchConfigurationCollapsed: true,
        oldPersonIdToNewPersonIdMapping: {},
        loadingGroupCreation: false,
        peopleMatchConfigurationError: null,
        lodaingCreationJsonDependencies: false,
        isAdvancedSettingsOpened: false,
        solutionNotChooseError: null,
        templateModalIsOpen: false,
        disableTabNavigation: ctrl.disableTabNavigation,
        tab: ctrl.tab,
        hideTitle: ctrl.hideTitle,
        workflowFolderId: $scope.workflowFolderId,
        workflowFolderCategoryId: $scope.workflowFolderCategoryId,
        marketplaceItemSummary: $scope.marketplaceItemSummary,
    };

    $scope.valueResults = {};
    $scope.integrationsResults = {};

    $scope.init = function () {
        $scope.data.loadingTemplates = true;
        $scope.data.errorLoadingTemplates = true;

        let getTemplatesPromise;
        if ($scope.isListGallery) {
            // Get anonymous templates
            getTemplatesPromise = tonkeanService.getPublishedTemplates();
        } else {
            // Get all published templates and all templates which the current user created (Only admin users with access to tonkean tools)
            getTemplatesPromise = tonkeanService.getAllTemplates(
                'PUBLISHED',
                null,
                null,
                $scope.as.currentUser.id,
                projectManager.project.id,
            );
        }

        getTemplatesPromise
            .then((data) => {
                const templates = data.templates;
                for (const template of templates) {
                    template.isExampleList = true;
                    if (template.additionalCategories.length) {
                        template.additionalCategoriesMap = {};
                        for (let j = 0; j < template.additionalCategories.length; j++) {
                            template.additionalCategoriesMap[template.additionalCategories[j].id] = true;
                        }
                    }
                }

                $scope.data.idToTemplateMap = utils.createMapFromArray(
                    templates.filter(
                        (singleTemplate) =>
                            !singleTemplate.projectId || singleTemplate.projectId === projectManager.project.id,
                    ),
                    'id',
                );
                $scope.categoryIdToTemplateMapping = {};
                $scope.categoryIdToTemplateMapping['SHARED_TEMPLATES'] = [];

                templates.forEach((singleTemplate) => {
                    if (
                        singleTemplate.category &&
                        (!singleTemplate.projectId || singleTemplate.projectId === projectManager.project.id)
                    ) {
                        if (
                            !(
                                $scope.categoryIdToTemplateMapping[singleTemplate.category.id] &&
                                $scope.categoryIdToTemplateMapping[singleTemplate.category.id].length
                            )
                        ) {
                            $scope.categoryIdToTemplateMapping[singleTemplate.category.id] = [];
                        }

                        $scope.categoryIdToTemplateMapping[singleTemplate.category.id].push(singleTemplate);
                    }

                    if (singleTemplate.projectId && projectManager.project.id === singleTemplate.projectId) {
                        $scope.categoryIdToTemplateMapping['SHARED_TEMPLATES'].push(singleTemplate);
                    }

                    if (
                        singleTemplate.additionalCategories &&
                        singleTemplate.additionalCategories.length &&
                        (!singleTemplate.projectId || singleTemplate.projectId === projectManager.project.id)
                    ) {
                        singleTemplate.additionalCategories.forEach((singleCategory) => {
                            // If the template does not have a main category we put the first additional category to the main one.
                            if (!singleTemplate.category) {
                                singleTemplate.category = singleCategory;
                            }

                            if (
                                !(
                                    $scope.categoryIdToTemplateMapping[singleCategory.id] &&
                                    $scope.categoryIdToTemplateMapping[singleCategory.id].length
                                )
                            ) {
                                $scope.categoryIdToTemplateMapping[singleCategory.id] = [];
                            }

                            $scope.categoryIdToTemplateMapping[singleCategory.id].push(singleTemplate);
                        });
                    }
                });

                // if has id in query string, move to it
                if (
                    $stateParams.listType &&
                    $stateParams.listType.length &&
                    $scope.data.idToTemplateMap &&
                    $scope.data.idToTemplateMap[$stateParams.listType]
                ) {
                    $scope.selectList($scope.data.idToTemplateMap[$stateParams.listType]);
                }

                return tonkeanService
                    .getAllTemplateCategories()
                    .then((data) => {
                        $scope.categories = data.categories;
                        $scope.categoriesList = getCategoriesList($scope.categories);
                    })
                    .finally(() => {
                        $scope.data.loadingTemplates = false;
                    });
            })
            .catch(() => {
                $scope.data.errorLoadingTemplates = true;
            });

        if ($stateParams.listType && $stateParams.listType.toLowerCase() === 'custom') {
            $scope.data.isMakeBotFromJson = true;
        }

        $scope.data.isUserAuthenticated = !!authenticationService.isUserAuthenticated();

        if (ctrl.tab) {
            $scope.data.selectedTab = { id: ctrl.tab };
        }
        // If there is a request for a list type.
        if ($scope.emptyOnly) {
            $scope.selectList(builtInListsConsts.getEmptyList(), true);
        } else if ($stateParams.listType && $stateParams.listType.length) {
            let requestedType = $stateParams.listType.replace(/-/gi, '_');
            requestedType = requestedType.toUpperCase();
            if (requestedType === 'CUSTOM') {
                $scope.data.enterCustomJson = true;
            }
        } else if ($scope.group) {
            // If we were supplied with a duplicate group data, set up what's needed.
            // Select the given duplicate list model as the selected list.
            // We have no steps, so the group settings step will be added and we'll jump right to it.
            $scope.group.steps = [];
            $scope.selectList($scope.group, false, 'DUPLICATE');

            // Initializing the sync validity object for the live sync control of the duplicate group case.
            $scope.valueResults['SYNC_VALIDITY'] = {};
        } else if ($scope.data.marketplaceItemSummary) {
            $scope.selectList({ ...$scope.data.marketplaceItemSummary, isExampleList: true });
        }

        if ($stateParams.autoCreateList && $stateParams.autoCreateList !== 'false') {
            $scope.submitList(true, true);
        }
    };

    $scope.onWorkflowFolderSelected = function (workflowFolder) {
        $scope.data.workflowFolderId = workflowFolder?.id;
    };

    $scope.onWorkflowFolderCategorySelected = function (workflowFolderCategoryId) {
        $scope.data.workflowFolderCategoryId = workflowFolderCategoryId;
    };

    function createMappingBetweenOldFieldsToNewFieldsName() {
        const externalFieldIdToFieldNameMapping = {};
        if ($scope.data.columns) {
            for (let i = 0; i < $scope.data.columns.length; i++) {
                if ($scope.data.columns[i].matchedEntity) {
                    externalFieldIdToFieldNameMapping[$scope.data.columns[i].id] =
                        $scope.data.columns[i].matchedEntity.name;
                }
            }
        }
        return externalFieldIdToFieldNameMapping;
    }

    $scope.toggleAdvancedSettings = function () {
        for (let i = 0; i < $scope.data.requiredIntegrationsStates.length; i++) {
            if ($scope.data.requiredIntegrationsStates[i].currentIntegration) {
                $scope.getAvailableExternalFields($scope.data.requiredIntegrationsStates[i]);
            } else {
                $scope.data.requiredIntegrationsStates[i].matchFieldsError =
                    'Please choose data integration in order to map fields';
            }
        }

        $scope.data.isAdvancedSettingsOpened = !$scope.data.isAdvancedSettingsOpened;
    };

    $scope.submitList = function () {
        if ($scope.emptyOnly) {
            $scope.data.loadingGroupCreation = true;
            // Create new Module
            return createGroup()
                .then(onSubmitSuccessful)
                .catch(handleError)
                .finally(() => {
                    $scope.data.loadingGroupCreation = false;
                });
        } else if ($scope.data.selectedList.isDuplicatedList) {
            $scope.data.loadingGroupCreation = true;
            // When duplicating a list
            return $scope.data.groupControlObject
                .submit($scope.data.selectedList.id !== 'EMPTY', $scope.data.selectedList.workerType)
                .then((newGroup) => {
                    $scope.data.newGroup = newGroup;
                    onSubmitSuccessful();
                })
                .catch(handleError);
        } else {
            let createGroupPromise;
            $scope.data.loadingGroupCreation = true;
            const externalFieldIdToFieldNameMapping = createMappingBetweenOldFieldsToNewFieldsName();
            if ($scope.data.isMakeBotFromJson) {
                createGroupPromise = tonkeanService
                    .createEntityFromCreationJson(projectManager.project.id, $scope.data.selectedList.creationJson, {
                        oldIntegrationIdToNewIntegrationIdMapping:
                            $scope.data.oldIntegrationIdToNewIntegrationIdMapping,
                        oldPersonIdToNewPersonIdMapping: $scope.data.oldPersonIdToNewPersonIdMapping,
                        externalFieldIdToFieldNameMapping,
                    })
                    .then((data) => {
                        const createdGroupId = data.createdEntitiesIds.find((id) => id.startsWith('GRUP'));
                        return createdGroupId;
                    });
            } else if ($scope.data.marketplaceItemSummary) {
                // Validating if there is a selected workflow folder id.
                if (!$scope.data.workflowFolderId) {
                    $scope.data.solutionNotChoseError = 'You have to choose solution before selecting a template';
                    $scope.data.createExampleListButtonClicked = false;
                    return;
                }

                createGroupPromise = tonkeanService
                    .createFromMarketplaceItem({
                        projectId: projectManager.project.id,
                        templateName: $scope.data.marketplaceItemSummary.templateName,
                        marketplaceItemType: MarketplaceItemType.MODULE,
                        workflowFolderId: $scope.data.workflowFolderId,
                        workflowFolderCategoryId: $scope.data.workflowFolderCategoryId,
                        creationJsonDependencies: {
                            oldIntegrationIdToNewIntegrationIdMapping:
                                $scope.data.oldIntegrationIdToNewIntegrationIdMapping,
                            oldPersonIdToNewPersonIdMapping: $scope.data.oldPersonIdToNewPersonIdMapping,
                            externalFieldIdToFieldNameMapping,
                        },
                    })
                    .then((data) => data.createdItemId);
            } else {
                // Validating if there is a selected workflow folder id.
                if (!$scope.data.workflowFolderId) {
                    $scope.data.solutionNotChoseError = 'You have to choose solution before selecting a template';
                    $scope.data.createExampleListButtonClicked = false;
                    return;
                }

                createGroupPromise = tonkeanService
                    .createBotByTemplateId(
                        projectManager.project.id,
                        $scope.data.selectedList.id,
                        $scope.data.oldIntegrationIdToNewIntegrationIdMapping,
                        $scope.data.oldPersonIdToNewPersonIdMapping,
                        externalFieldIdToFieldNameMapping,
                        $rootScope.features[$rootScope.pm.project.id],
                        $scope.data.workflowFolderId,
                        $scope.data.workflowFolderCategoryId,
                    )
                    .then((data) => {
                        data.createdGroupId;
                    });
            }

            return createGroupPromise
                .then((createdGroupId) => {
                    $state.go(
                        'product.workerEditor',
                        {
                            g: createdGroupId,
                            wr: null,
                            init: null,
                        },
                        { location: 'replace', reload: true },
                    );

                    // Force refresh, otherwise things don't really load up.
                    $timeout(function () {
                        window.location.reload();
                    }, 300);
                })
                .catch(handleError)
                .finally(() => {
                    $scope.data.loadingGroupCreation = false;
                });
        }
    };

    $scope.showPeopleMatchConfiguration = function showPeopleMatchConfiguration() {
        $scope.data.isPeopleMatchConfigurationCollapsed = !$scope.data.isPeopleMatchConfigurationCollapsed;
    };

    $scope.ownerSelected = function (person, paramsObject) {
        $scope.data.oldPersonIdToNewPersonIdMapping[paramsObject.id] = person.id;
    };

    $scope.ownerRemoved = function (paramsObject) {
        $scope.data.oldPersonIdToNewPersonIdMapping[paramsObject.id] = null;
    };

    $scope.selectList = function (list, dontNav, overrideListId) {
        $scope.data.selectedList = angular.copy(list);
        $scope.data.steps = angular.copy(list.steps);
        $scope.data.configurationColumns = angular.copy(list.columns);
        $scope.data.requiredIntegrationsStates = [];

        // Loading selected list (or creationJson) dependencies
        let dependenciesPromise = $q.resolve();
        if ($scope.data.selectedList?.id && $scope.data.selectedList?.id !== 'EMPTY' && !$scope.isListGallery) {
            $scope.data.lodaingCreationJsonDependencies = true;
            dependenciesPromise = tonkeanService.getTemplatesDependencies($scope.data.selectedList.id);
        } else if ($scope.data.isMakeBotFromJson && !$scope.isListGallery) {
            $scope.data.lodaingCreationJsonDependencies = true;
            dependenciesPromise = tonkeanService.getCreationJsonDependencies(list.creationJson);
        } else if ($scope.data.marketplaceItemSummary) {
            dependenciesPromise = tonkeanService.getMarketplaceItemDependencies(
                $scope.data.marketplaceItemSummary.templateName,
                MarketplaceItemType.MODULE,
            );
        }

        if (!$scope.isModal && !dontNav) {
            // If not modal, set the nav.
            // We don't want a refresh here, cause it will cause authentication to happen.
            $state.current.reloadOnSearch = false;
            $location.search('listType', list.id);
            $timeout(() => {
                $state.current.reloadOnSearch = true;
            });
        }

        dependenciesPromise
            .then((dependencies) => {
                if (dependencies) {
                    $scope.data.selectedList.requiredIntegrations = dependencies.requiredProjectIntegrations.map(
                        (requiredIntegration) => requiredIntegration.integrationType,
                    );
                    $scope.data.selectedList.requiredIntegrationsIds = dependencies.requiredProjectIntegrations.map(
                        (requiredIntegration) => requiredIntegration.id,
                    );
                    $scope.data.selectedList.pastIntegrationNames = dependencies.requiredProjectIntegrations.map(
                        (requiredIntegration) => requiredIntegration.displayName,
                    );
                    $scope.data.selectedList.requiredPeople = dependencies.requiredPeople;
                    $scope.data.columns = dependencies.requiredExternalFields;
                    if (overrideListId) {
                        $scope.data.selectedList.id = overrideListId;
                    }
                    $scope.initRequiredIntegrationStates();
                }

                settingFirstStep(list);

                // Initializing the tags of the selected list.
                if ($scope.data.selectedList.category) {
                    $scope.data.selectedList.tags = [];
                    $scope.data.selectedList.tags.push($scope.data.selectedList.category.title);

                    if ($scope.data.selectedList?.additionalCategories?.length) {
                        for (let i = 0; i < $scope.data.selectedList.additionalCategories.length; i++) {
                            $scope.data.selectedList.tags.push($scope.data.selectedList.additionalCategories[i].title);
                        }
                    }
                }
            })
            .finally(() => {
                $scope.data.lodaingCreationJsonDependencies = false;
            });
    };

    function settingFirstStep(list) {
        $scope.addSetupGroupStep();
        $scope.initRequiredIntegrationStates();

        $scope.nextStep(0);

        window.Intercom('trackEvent', `Chose Builtin List ${list.category}`);
    }

    /**
     * Add the setup group settings to the end if needed
     * **/
    $scope.addSetupGroupStep = function () {
        if ($scope.data.showGroupSettings) {
            // Make sure we have a steps array.
            if (!$scope.data.steps) {
                $scope.data.steps = [];
            }

            // Add the list settings to the end.
            $scope.data.steps.push({
                type: 'config',
                data: {
                    template: 'groupSettingsTemplate',
                    infoTemplate: 'groupSettingsInfoTemplate',
                },
            });
        }
    };

    /**
     * Going forward a step, or going to the given index.
     * Since this function can be given a specific index, it can be we either going backwards or forwards,
     * and that's why we need to have the goingToPreviousStep flag, which says which direction we're going.
     * We use that flag to either skip backwards or forwards the preview step if it isn't needed.
     * @param index If given, will move to that index.
     * @param goingToPreviousStep If true, means we're going backwards in steps. Otherwise, we're going forwards in steps.
     */
    $scope.nextStep = function (index, goingToPreviousStep) {
        $scope.scrollModalToTop();

        // If user gave us a step to jump to , we go to that step. Otherwise, we increment the current step by one.
        if (index >= 0) {
            $scope.data.currentStepIndex = index;
        } else {
            $scope.data.currentStepIndex = $scope.data.currentStepIndex + 1;
        }

        // If we should skip step, we go skip backwards or forwards according to given flag.
        if (
            $scope.data.steps[$scope.data.currentStepIndex].shouldSkipStep &&
            $scope.data.steps[$scope.data.currentStepIndex].shouldSkipStep(
                $scope.valueResults,
                $scope.integrationsResults,
            )
        ) {
            if (goingToPreviousStep) {
                $scope.data.currentStepIndex = $scope.data.currentStepIndex - 1;
                $scope.data.steps[$scope.data.currentStepIndex].actualIndex = $scope.data.currentStepIndex;
            } else {
                $scope.data.currentStepIndex = $scope.data.currentStepIndex + 1;
                $scope.data.steps[$scope.data.currentStepIndex].actualIndex = $scope.data.currentStepIndex - 1;
            }

            // if we are skipping this step, actually preform the next step method so next step logic (like skip step) will run
            // (if we need to skip multiple steps)
            $scope.nextStep($scope.data.currentStepIndex, goingToPreviousStep);
            return;
        }

        if (!$scope.isModal) {
            $location.hash($scope.data.currentStepIndex);
        }

        $scope.data.currentStep = $scope.data.steps[$scope.data.currentStepIndex];

        if ($scope.integrationsResults[$scope.data.currentStep.data.sourceType]) {
            $scope.integrationsResults[$scope.data.currentStep.data.sourceType] = null;
        }

        // clear new integration in progress
        $scope.data.currentIntegration = null;

        // reload step (to make sure our ng-inits will be restarted
        $scope.data.loadingStep = true;
        $timeout(function () {
            $scope.data.loadingStep = false;
        });
    };

    $scope.previousStep = function () {
        // if not in a sync list there would be no sync validity properties
        if ($scope.valueResults['SYNC_VALIDITY']) {
            $scope.valueResults['SYNC_VALIDITY'].isLoading = true;
        }

        if ($scope.data.currentStepIndex === 0) {
            $scope.$dismiss();
            $scope.modalUtils.openPrivateGroupModal(projectManager.groupsMap[$stateParams.g], null, true, true);
        } else {
            return $scope.nextStep($scope.data.currentStepIndex - 1, true);
        }
    };

    $scope.close = function () {
        $scope.$dismiss();
    };

    $scope.goToListSelection = function () {
        $scope.data.selectedList = null;

        $scope.clearCurrentSetupValues();

        // If not in modal, set the nav.
        if (!$scope.isModal && !$scope.data.disableTabNavigation) {
            $location.hash(null);

            if (!$scope.isListGallery) {
                // User is authenticated - go to list creation page.
                $location.path('/create/list/', false);
            } else {
                // User not authenticated - just clear the list type state param without refreshing.
                $state.current.reloadOnSearch = false;
                $location.search('listType', null);
                $timeout(() => {
                    $state.current.reloadOnSearch = true;
                });
            }
        }
    };

    $scope.clearCurrentSetupValues = function () {
        // clear new integration in progress
        $scope.data.currentIntegration = null;
        $scope.data.integrationState = {};
        $scope.valueResults = {};
        $scope.integrationsResults = {};
    };

    $scope.filterListBySearchTerm = function (list, search) {
        if (!search || !search.length) {
            return true;
        }
        search = search.toLowerCase();

        let isMatch = list.title.toLowerCase().includes(search);
        isMatch = isMatch || (list.description && list.description.toLowerCase().includes(search));
        isMatch = isMatch || (list.longDescription && list.longDescription.toLowerCase().includes(search));
        isMatch =
            isMatch ||
            (list.requiredIntegrations &&
                list.requiredIntegrations.length &&
                list.requiredIntegrations.join(' ').toLowerCase().includes(search));
        isMatch = isMatch || (list.category && list.category.title?.toLowerCase().indexOf(search) > -1);
        isMatch =
            isMatch ||
            (list?.additionalCategories?.length &&
                utils.existsInArray(
                    list.additionalCategories,
                    (category) => category.title?.toLowerCase().indexOf(search) > -1,
                ));
        isMatch =
            isMatch ||
            (list?.requiredIntegrations?.length &&
                utils.existsInArray(list.requiredIntegrations, (integration) =>
                    integration.toLowerCase().includes(search),
                ));

        return isMatch;
    };

    // Select category
    $scope.selectTab = function (cat) {
        $scope.data.selectedList = null;
        $scope.data.searchTerm = null;
        $scope.data.selectedTab = cat;

        $scope.goToListSelection();
    };

    /**
     * Used to make the sandbox url a trusted url.
     */
    $scope.trustSrc = function (src) {
        return $sce.trustAsResourceUrl(src);
    };

    $scope.loadFields = function (integrationState) {
        integrationState.isCollapsed = !integrationState.isCollapsed;
        if (!integrationState.isCollapsed) {
            $scope.getAvailableExternalFields(integrationState);
        }
    };

    $scope.getAvailableExternalFields = function (integrationState, forceServer) {
        integrationState.isLoadingExternalFields = true;
        // If we already filled the fields options, skip so we won't erase the changes of the user
        if (
            integrationState.availableExternalFields &&
            integrationState.availableExternalFields.length &&
            !forceServer
        ) {
            integrationState.isLoadingExternalFields = false;
            return $q.resolve();
        }

        const externalColumns = $scope.data.columns
            ? $scope.data.columns.filter(
                  (column) =>
                      column.fieldDefinitionType === 'EXTERNAL' &&
                      column.projectIntegration.id === integrationState.originalProjectIntegrationId,
              )
            : [];

        // Get the external types related to this project integration
        const externalTypes = [];
        if (externalColumns.length) {
            externalColumns.map((column) => {
                if (
                    column.definition &&
                    column.projectIntegration.id === integrationState.originalProjectIntegrationId
                ) {
                    externalTypes.push(column.definition.ExternalType);
                }
            });
        }

        return tonkeanService
            .getAvailableExternalFields(integrationState.currentIntegration.id, externalTypes)
            .then((data) => {
                integrationState.availableExternalFields = data.entitiesWithLabels;

                // Put the external entity on the column so we can retrieve it when sending to server
                externalColumns.forEach((column) => {
                    column.matchedEntity = utils.findFirst(integrationState.availableExternalFields, (entity) => {
                        return (
                            column.definition &&
                            entity.label === column.definition.FieldLabel &&
                            column.definition.ExternalType === entity.entity
                        );
                    });
                });
            })
            .finally(() => (integrationState.isLoadingExternalFields = false));
    };

    $scope.filterNonDefinitionFields = function (column) {
        return !!(column.definition && column.definition.FieldLabel);
    };

    function getPastIntegrationName(integrationName) {
        if (
            integrationName &&
            integrationName.includes('Google Drive') &&
            integrationName.includes('(') &&
            integrationName.includes(')')
        ) {
            // Fetch the string between the two parenthesis
            return integrationName.substring(integrationName.lastIndexOf('(') + 1, integrationName.lastIndexOf(')'));
        }
        return integrationName;
    }

    $scope.initRequiredIntegrationStates = function () {
        if (
            $scope.data.selectedList.requiredIntegrations &&
            $scope.data.selectedList.requiredIntegrations.length &&
            !$scope.isListGallery
        ) {
            $scope.data.requiredIntegrationsStates = $scope.data.selectedList.requiredIntegrations.map(
                (integrationType, index) => {
                    const state = {
                        type: integrationType,
                        state: {},
                        currentIntegration: null,
                        integrationChanged: null,
                        supportsMultipleIntegrationsPerUser: false,
                        existingProjectIntegrations: [],
                        pastIntegrationName: null,
                        originalProjectIntegrationId: null,
                        isCollapsed: true,
                        isLoadingExternalFields: false,
                    };
                    // match integration with their past name
                    if ($scope.data.selectedList.pastIntegrationNames) {
                        // match integration with their past name
                        state.pastIntegrationName = getPastIntegrationName(
                            $scope.data.selectedList.pastIntegrationNames[index],
                        );
                    }

                    if ($scope.data.selectedList.requiredIntegrationsIds) {
                        state.originalProjectIntegrationId = $scope.data.selectedList.requiredIntegrationsIds[index];
                    }

                    const filter = $scope.pm.project.integrations.filter(
                        (integ) => integ.integrationType === state.type,
                    );
                    state.currentIntegration = null;

                    if (filter && filter.length) {
                        state.supportsMultipleIntegrationsPerUser =
                            filter[0].integration.supportsMultipleIntegrationsPerUser;
                        state.currentIntegration = filter[0];
                        state.selectedIntegration = state.currentIntegration;

                        // Creating the default mapping between old and new project integrations
                        if ($scope.data.selectedList.requiredIntegrationsIds) {
                            $scope.data.oldIntegrationIdToNewIntegrationIdMapping[
                                $scope.data.selectedList.requiredIntegrationsIds[index]
                            ] = filter[0].id;
                        }

                        if (state.supportsMultipleIntegrationsPerUser) {
                            state.existingProjectIntegrations = filter;
                        }
                    }

                    state.integrationChanged = (changedProjectIntegration) => {
                        // If this is a newly connected integration the model wont be the same, so we correct this
                        if (changedProjectIntegration.projectIntegration) {
                            state.currentIntegration = changedProjectIntegration.projectIntegration;
                            state.currentIntegration.valid = changedProjectIntegration.valid;
                            state.currentIntegration.disabled = changedProjectIntegration.disabled;
                        } else {
                            state.currentIntegration = changedProjectIntegration;
                        }

                        state.selectedIntegration = state.currentIntegration;

                        $scope.getAvailableExternalFields(state, true);
                    };

                    return state;
                },
            );
        }
    };

    $scope.refreshExistingIntegrations = function (integrationState) {
        if ($scope.isListGallery) {
            return;
        }

        const filter = $scope.pm.project.integrations.filter(
            (integ) => integ.integrationType === integrationState.type,
        );
        integrationState.existingProjectIntegrations = filter;
    };

    $scope.onExistingIntegrationSelected = function (integrationState, selectedIntegration) {
        $scope.refreshExistingIntegrations(integrationState);
        $scope.data.oldIntegrationIdToNewIntegrationIdMapping[integrationState.originalProjectIntegrationId] =
            selectedIntegration.id;
        integrationState.currentIntegration = selectedIntegration;
        $scope.getAvailableExternalFields(integrationState, true);
    };

    /**
     * Creates example list.
     */
    $scope.createExampleList = function () {
        analyticsWrapper.track('Create example list', {
            category: 'Built-in List Modal',
            label: $scope.data.selectedList?.id || $scope.data.selectedList?.templateName,
        });

        if ($scope.isListGallery) {
            // If we're in the list gallery, we are in an iFrame and should let our parent know the user wants to create a list,
            // so he can open the authentication window, etc.
            // We also save an auto list creation id in the localStorage so when the user finishes the sign up + onboarding
            // his list is auto created by navigating to '/create/list/{listId}?autoCreateList=true'.
            $localStorage.autoCreateListId = $scope.data.selectedList.id;
            $scope.postMessageToContainer($scope.data.selectedList.id);
        } else {
            $scope.data.createExampleListButtonClicked = true;
            $scope.submitList(true, true);
        }
    };

    // Might not be essential
    /**
     * Occurs once the iframe is loaded.
     */
    $scope.onSandboxIFrameLoaded = function () {
        $scope.data.loadingSandboxIFrame = false;
    };

    /**
     * Creates the group of the built in list.
     */
    function createGroup() {
        // Save the built-in list id to the group's metadata, and send analytics.
        analyticsWrapper.track('Create group', { category: 'Built-in List Modal', label: $scope.data.selectedList.id });

        $scope.valueResults['TNK_GROUP_CREATOR'] = $scope.as.currentUser.id;

        // Create group from template
        if ($scope.data.selectedList.isExampleList) {
            // If it's an example list, we create the group directly using tonkean service.
            const groupName = $scope.data.selectedList.getListDefaultName
                ? $scope.data.selectedList.getListDefaultName()
                : $scope.data.selectedList.title;

            // Set the metadata for this example list if we want to set displayImportPromoBox on it.
            if (!$scope.data.groupSettings.metadata) {
                $scope.data.groupSettings.metadata = {};
            }
            $scope.data.groupSettings.metadata.displayClearExamples = true;
            $scope.data.groupSettings.metadata.displayImportPromoBox = $scope.data.selectedList.displayImportPromoBox;

            return tonkeanService
                .createGroup(
                    projectManager.project.id,
                    groupName,
                    'PUBLIC',
                    null,
                    'NONE',
                    null,
                    null,
                    $scope.data.groupSettings.metadata,
                    !$scope.data.selectedList.disableShouldSendGatherUpdates,
                    null,
                    false,
                    null,
                    null,
                    null,
                    $scope.data.selectedList.dashboardHidden,
                    false,
                    $scope.data.selectedList.workerType,
                    $scope.data.selectedList.recurrencePeriodType,
                    $scope.data.selectedList.recurrenceDaysInMonth,
                    $scope.data.selectedList.recurrenceDaysInWeek,
                    $scope.data.selectedList.recurrenceHour,
                    $scope.data.selectedList.recurrenceMinute,
                    $scope.data.selectedList.everyXMinutes,
                    $scope.data.selectedList.everyXHours,
                    $scope.data.selectedList.doNotRunOnWeekends,
                    null,
                    null,
                    undefined,
                    undefined,
                    undefined,
                    '',
                    undefined,
                    $rootScope.features[$rootScope.pm.project.id],
                )
                .then((updatedGroup) => {
                    // Update group in cache.
                    updateGroupInCaches(updatedGroup);
                });
            // Create new group or duplicate
        } else if ($scope.data.showGroupSettings) {
            // If we displayed the group settings, we should use the tnkEditGroup directive's submit logic.
            // if parameter is false - bot live is active
            return $scope.data.groupControlObject
                .submit($scope.data.selectedList.id !== 'EMPTY', $scope.data.selectedList.workerType)
                .then(function (updatedGroup) {
                    // The group can be null (if this is a new group). data.groupSettings can't.
                    const groupObject = $scope.group || $scope.data.groupSettings;
                    // Update the referenced group name or our new group settings.
                    groupObject.name = updatedGroup.name;

                    // Update group in cache.
                    updateGroupInCaches(updatedGroup);
                });
        }
        // Group settings wasn't displayed.
        // If a group was provided from the start just use it.
        if ($scope.group) {
            $scope.group.name = $scope.data.groupSettings.name;
            $scope.data.newGroup = $scope.group;

            // Save the group id to $scope.valueResults.
            $scope.valueResults['GROUP_ID'] = $scope.data.newGroup.id;

            return tonkeanService.updateGroupName($scope.group, $scope.group.name);
        }
    }

    /**
     * Updates the newly created group in relevant caches, and in the variables map.
     */
    function updateGroupInCaches(updatedGroup) {
        // Set the newGroup that is used in the next submit phases.
        $scope.data.newGroup = updatedGroup;
        // Save the group id to $scope.valueResults.
        $scope.valueResults['GROUP_ID'] = $scope.data.newGroup.id;
        // Add the new group to the pm. Otherwise the app won't work well.
        $scope.pm.addGroup(updatedGroup);
    }

    /**
     * Occurs after successfully submitting the new dashboard.
     */
    function onSubmitSuccessful() {
        // Emit a success message, only if this is not the first group,
        // because it pops over the already auto-popping education/kb popover.
        if ($scope.pm.groups && $scope.pm.groups.length > 1) {
            $scope.$emit('alert', {
                msg: `${$scope.workersTemplateMode ? 'Bot' : 'List'} created successfully!`,
                type: 'success',
            });
        }

        // Broadcast an update message to the system.
        $rootScope.$broadcast('newActivityUpdate');

        // Complete the firstListCreated on boarding step. If it was already completed this action will be ignored.
        onBoardingManager.completeStep('firstListCreated');

        let to;
        let params;
        let options;
        let requestPromise = $q.resolve();

        // Go to the new group's page.
        if ($scope.workersTemplateMode) {
            requestPromise = groupInfoManager.getGroup($scope.data.newGroup.id, true).finally(function () {
                to = 'product.workerEditor';
                params = {
                    g: $scope.data.newGroup.id,
                    wr: null,
                    init: null,
                };
                options = { location: 'replace', reload: true };
            });
        } else {
            if ($scope.data.selectedList && $scope.data.selectedList.isDuplicatedList) {
                to = 'product.board';
                params = {
                    filter: 'all',
                    st: null,
                    g: $scope.data.newGroup.id,
                    listFilter: null,
                    isGroupListFilter: null,
                };
                options = { location: 'replace', reload: true };
            } else {
                to = 'product.board';
                params = {
                    filter: 'all',
                    st: null,
                    g: $scope.data.newGroup.id,
                    listFilter: null,
                    isGroupListFilter: null,
                };
                options = { location: 'replace' };
            }
        }

        return requestPromise.then(() => {
            $state.go(to, params, options);

            // Force refresh, otherwise things don't really load up.
            $timeout(function () {
                window.location.reload();
                $scope.data.loadingGroupCreation = false;
            }, 300);

            // Close the modal as we are done.
            if ($scope.$close) {
                $scope.$close();
            }

            window.Intercom('trackEvent', 'Finished Builtin List Setup');
            window.Intercom('trackEvent', `Finished Builtin List Setup ${$scope.data.selectedList.category}`);
        });
    }

    function handleError(errorResponse) {
        $scope.data.loadingGroupCreation = false;

        if (errorResponse === 'dismissed') {
            return;
        }

        // Set a default error message.
        $scope.error.shortMessage = 'Unknown error. Please try again.';

        // Try to find the actual error message.
        if (errorResponse) {
            if (errorResponse?.data?.error?.message || errorResponse?.data?.data?.error?.message) {
                $scope.error.shortMessage =
                    errorResponse.data?.error?.message || errorResponse.data?.data?.error?.message;
                $scope.error.longMessage = errorResponse.data?.error?.stack || errorResponse.data?.data?.error?.stack;
            } else if (errorResponse.statusText) {
                $scope.shortMessage = errorResponse.statusText;
            }
        }

        $scope.data.createExampleListButtonClicked = false;
    }

    // This function accepts only creationJson from the new type
    $scope.loadCustomJson = function (creationJsonAsText) {
        const creationJson = JSON.parse(creationJsonAsText);

        if (creationJson) {
            const selectedList = {
                title: creationJson.group?.name,
                isExampleList: true,
                creationJson,
            };
            $scope.selectList(selectedList, true, null);
        }
    };

    /**
     * Return the categories ordered by keywords found in the title (if exists)
     */
    function getCategoriesList(categories) {
        const title = authenticationService.currentUser?.title?.toLowerCase();

        const categoriesList = [];
        // going over the categories, the one that has a match with the title
        // setting it to be first
        let foundMatch = false;
        for (const id in categories) {
            if (categories.hasOwnProperty(id)) {
                const cat = categories[id];
                let inserted = false;

                // only checking, if we didn't find match yet - this is in order to maintaining order of importance
                // ie. Sales is stronger than product (matching wise)
                if (!foundMatch && title && title.length && cat.titleKeywords && cat.titleKeywords.length) {
                    for (let i = 0; i < cat.titleKeywords.length; i++) {
                        const keyword = cat.titleKeywords[i].toLowerCase();
                        if (title.includes(keyword)) {
                            categoriesList.splice(0, 0, cat);
                            inserted = true;
                            foundMatch = true;
                            break;
                        }
                    }
                }

                if (!inserted) {
                    categoriesList.push(cat);
                }
            }
        }

        return categoriesList;
    }

    $scope.scrollModalToTop = function () {
        $timeout(function () {
            // scroll to top
            const el = document.querySelectorAll('.modal-content')[0];
            if (el) {
                el.scrollTop = 0;
            }
        });
    };

    /**
     * Copies the full error to clipboard because it's too long for displaying
     * @returns True if the copy was successful
     */
    $scope.copyErrorToClipboard = function () {
        const isCopiedSuccessfully = utils.copyToClipboardFromText($scope.error.longMessage);
        if (isCopiedSuccessfully) {
            $rootScope.$emit('alert', {
                msg: 'The error was copied successfully to clipboard',
                type: 'success',
            });
        } else {
            $rootScope.$emit('alert', {
                msg: 'Copying the error to clipboard was unsuccessful',
                type: 'danger',
            });
        }
        return isCopiedSuccessfully;
    };

    $scope.closeTemplateModal = () => {
        $timeout(() => {
            $scope.init();
            $scope.selectTab({ id: 'SHARED_TEMPLATES' });
            $scope.data.templateModalIsOpen = false;
        });
    };

    $scope.deleteCompanyTemplate = ($event, templateId) => {
        $event.stopPropagation();

        $scope.data.deleteTemplateButtonClicked = true;

        $scope.questionConfirmModalData = {
            title: 'Are you sure?',
            body: 'Are you sure you want to delete this template?',
            okLabel: 'Delete',
            cancelLabel: 'Cancel',
        };

        modal
            .openQuestionConfirmModal({
                scope: $scope,
                windowClass: 'mod-warning vertical-middle-modal',
            })
            .result.then(() => {
                $scope.data.deleteTemplateButtonClicked = false;
                tonkeanService.deleteCompanyTemplate(templateId).then(() => {
                    delete $scope.data.idToTemplateMap[templateId];
                });
            });
    };

    /**
     * Computes whether we should show the list of templates
     * @returns {boolean|*}
     */
    $scope.shouldShowList = function (list) {
        const isAllChosen = !$scope.data.selectedTab;

        const isTemplateInChosenCategory =
            (list.category && list.category?.id === $scope.data.selectedTab?.id) ||
            (list.additionalCategoriesMap && list.additionalCategoriesMap[$scope.data.selectedTab?.id]) ||
            ($scope.data.selectedTab?.id === 'SHARED_TEMPLATES' &&
                list.projectId &&
                $scope.pm.project?.id === list.projectId) ||
            isAllChosen;

        const noSearchTerm = !$scope.data.searchTerm || !$scope.data.searchTerm.length;

        return (
            (isTemplateInChosenCategory && noSearchTerm) ||
            (!noSearchTerm &&
                isTemplateInChosenCategory &&
                $scope.filterListBySearchTerm(list, $scope.data.searchTerm)) ||
            (!$scope.data.selectedTab && noSearchTerm && !$scope.data.loadingTemplates)
        );
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('SetupGroupNewCtrl', lateConstructController(SetupGroupNewCtrl));
