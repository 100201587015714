import { useMemo } from 'react';

import type { EmbedConfiguration } from './EmbedWidgetConfiguration';
import { EmbedWidgetType } from './EmbedWidgetConfiguration';
import EmbedUrlInputType from '../entities/EmbedUrlInputType';

export const useEmbedWidgetContentExpression = (embedConfiguration: EmbedConfiguration) => {
    const expressionToLoad = useMemo(() => {
        if (
            embedConfiguration.type === EmbedWidgetType.URL &&
            embedConfiguration.urlInputType !== EmbedUrlInputType.FROM_ACTION &&
            embedConfiguration.url
        ) {
            return [embedConfiguration.url];
        }
        if (embedConfiguration?.type === EmbedWidgetType.HTML && embedConfiguration.html) {
            return [embedConfiguration.html];
        }

        return [];
    }, [embedConfiguration]);

    return expressionToLoad;
};
