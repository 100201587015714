import type { AngularServices } from 'angulareact';
import type { DeferredPromise } from '@tonkean/utils';
import { getDeferredPromise } from '@tonkean/utils';
import type { JobStatusResponse } from '@tonkean/tonkean-entities';
import { JobStatus } from '@tonkean/tonkean-entities';
import { bindThis } from '@tonkean/utils';
import type { IPromise } from 'angular';

/**
 * A service that handles the api job data and statues.
 *
 * When even a new job is added we add it to the job configs and keep polling until
 * we get an updated status then we update the job promise.
 */
class ApiJobManager {
    public static $inject: (string & keyof AngularServices)[] = [];

    private currentlyExecutedRequestForLatestJobStatusesPromise: IPromise<void> | undefined;
    private jobConfigs: { jobId: string; deferredPromise: DeferredPromise<unknown> }[] = [];

    protected constructor() {}

    /**
     * This method adds a job to the job configs that we want to fetch theirs statuses
     * recursively that way when it is completed we can update the job promise.
     * @param jobId The job tonkean id.
     * @param getJobsStatusCallback A callback that gets the jobs latest statuses.
     * @returns the promise that will be resolved or rejected according to the job status response,
     * this will contain the main action result.
     */
    @bindThis
    public addJob<T>(
        jobId: string,
        getJobsStatusCallback: (jobIds: string[]) => IPromise<{ entities: JobStatusResponse<unknown>[] }>,
    ): Promise<T> {
        const existingJobConfig = this.jobConfigs.find((singleJobConfig) => singleJobConfig.jobId === jobId);
        if (existingJobConfig) {
            return existingJobConfig.deferredPromise.promise as Promise<T>;
        }

        const deferredPromise = getDeferredPromise<T>();
        this.jobConfigs = [...this.jobConfigs, { jobId, deferredPromise }];

        this.recursivelyGetJobStatuses(getJobsStatusCallback);

        return deferredPromise.promise;
    }

    /**
     * This method recursively get the job statuses when ever we got pending jobs.
     * This is triggered first when ever a job is firstly executed.
     *
     * @param getJobsStatusCallback A callback that gets the latest job statuses.
     */
    @bindThis
    private recursivelyGetJobStatuses(
        getJobsStatusCallback: (jobIds: string[]) => IPromise<{ entities: JobStatusResponse<unknown>[] }>,
    ): void {
        if (this.currentlyExecutedRequestForLatestJobStatusesPromise || !this.jobConfigs.length) {
            return;
        }

        const jobIds = this.jobConfigs.map((jobConfig) => jobConfig.jobId);
        this.currentlyExecutedRequestForLatestJobStatusesPromise = getJobsStatusCallback(jobIds)
            .then((jobStatusResponses) => jobStatusResponses.entities.forEach(this.handleJobResponse))
            .finally(() => {
                this.currentlyExecutedRequestForLatestJobStatusesPromise = undefined;
                setTimeout(() => {
                    this.recursivelyGetJobStatuses(getJobsStatusCallback);
                }, 1000);
            });
    }

    /**
     * This method handles the job response it got.
     * If the job response is completed or has error we resolve or reject the job promise.
     *
     * @param jobStatusResponse The desired {@link JobStatusResponse} to handle.
     * @private
     */
    @bindThis
    private handleJobResponse(jobStatusResponse: JobStatusResponse<unknown>): void {
        const jobConfig = this.jobConfigs.find((singleJobConfig) => singleJobConfig.jobId === jobStatusResponse.jobId);

        if (!jobConfig || jobStatusResponse.status === JobStatus.IN_PROGRESS) {
            return;
        }

        switch (jobStatusResponse.status) {
            case JobStatus.COMPLETED_SUCCESSFULLY:
                jobConfig.deferredPromise.resolve(jobStatusResponse.response);
                break;
            case JobStatus.HAS_ERROR:
                jobConfig.deferredPromise.reject(jobStatusResponse.response);
                break;
        }

        this.jobConfigs = this.jobConfigs.filter((singleJobConfig) => singleJobConfig !== jobConfig);
    }
}

angular.module('tonkean.app').service('apiJobManager', ApiJobManager);
export default ApiJobManager;
