<!-- Mobile side menu -->
<div ng-cloak ng-show="as.currentUser" class="main-nav-sidebar flex flex-col" ng-class="{ 'is-open': isMenuOpen }">
    <a class="main-nav-sidebar-logo common-height-auto" href="/">
        <span class="margin-left">{{ pm.project.name || 'Tonkean' }}</span>
    </a>
    <a
        class="main-nav-item mod-only-sidebar margin-top-lg"
        ng-click="openInvite()"
        ng-show="(pm.project || pm.projects.length) && pm.isUserLicensed"
        analytics-on
        analytics-category="Invite"
        analytics-event="Invite"
        analytics-label="MainNav"
    >
        Invite Teammates
    </a>
    <a
        class="main-nav-item mod-only-sidebar"
        ng-if="pm.project && !pm.viewOnlyMode"
        ui-sref="updateState({projectId:pm.project.id})"
        ui-sref-active="is-active"
    >
        My Items
    </a>
    <div class="main-nav-item mod-only-sidebar padding-none">
        <hr class="margin-none" />
    </div>
    <a
        class="main-nav-item mod-only-sidebar"
        ng-if="pm.project && pm.isUserLicensed"
        ui-sref="product.settings.board"
        ui-sref-active="is-active"
    >
        Board Settings
    </a>
    <a
        class="main-nav-item mod-only-sidebar"
        ng-if="pm.project && !pm.viewOnlyMode"
        ui-sref="product.integrations({projectId:pm.project.id})"
        ui-sref-active="is-active"
    >
        Data Sources
    </a>
    <a class="main-nav-item mod-only-sidebar" ng-click="goToProfilePage()">Edit Profile</a>
    <a
        class="main-nav-item mod-only-sidebar"
        ui-sref="product.settings.license({tab: 'overview'})"
        ng-if="!pm.isEnterprise && (!pm.isLicensed || (pm.isOwner && pm.isUserLicensed))"
    >
        Subscription & Billing
    </a>
    <div class="main-nav-item mod-only-sidebar padding-none" ng-if="isEnterpriseUser">
        <hr class="margin-none" />
    </div>
    <a
        class="main-nav-item mod-only-sidebar"
        ng-if="isEnterpriseUser"
        href="#"
        ui-sref="enterprise()"
        analytics-on
        analytics-category="Enterprise"
        analytics-event="Manage"
        analytics-label="MainNav"
    >
        Administration
    </a>
    <div
        class="main-nav-item mod-only-sidebar padding-left-lg margin-top-none"
        ng-if="isEnterpriseUser && !pm.isEnterprise"
    >
        <div class="flex-vmiddle">
            <button type="button" class="btn btn-primary" ng-click="addProjectToEnterprise($event)">
                Add Board to Account
            </button>
            <span class="margin-left-xs" ng-if="addingProjectToEnterprise">
                <i class="loading"></i>
            </span>
        </div>
    </div>
    <div class="main-nav-item mod-only-sidebar padding-none">
        <hr class="margin-none" />
    </div>
    <a
        class="main-nav-item mod-only-sidebar"
        ng-if="pm.projects && pm.projects.length > 1"
        ui-sref="products"
        ui-sref-active="is-active"
    >
        Change Board
    </a>
    <a class="main-nav-item mod-only-sidebar" href="#" ng-click="openReferralModal()">Referral</a>
    <a class="main-nav-item mod-only-sidebar" href="#" id="intercom-open-button">Help / Feedback</a>
    <a class="main-nav-item mod-only-sidebar" href="#" ui-sref="knowledgebase" ng-if="as.currentUser && pm.project">
        Knowledge Base
    </a>
    <a class="main-nav-item mod-only-sidebar" href="#" ng-click="as.logout()">Logout</a>
    <div class="flex-grow main-nav-item mod-only-sidebar"></div>
    <div class="main-nav-item mod-only-sidebar padding-normal">
        <div class="common-size-xxs">
            <a href="https://tonkean.com/terms/" target="_blank">
                Terms
                <span class="hidden-xs">&nbsp;Of Service</span>
            </a>
            <span class="visible-xs-inline">|</span>
            <a href="https://tonkean.com/privacy/" target="_blank">
                Privacy
                <span class="hidden-xs">&nbsp;Policy</span>
            </a>
            <span class="visible-xs-inline">|</span>
            <a
                analytics-on="click"
                analytics-category="AppVersion"
                analytics-event="Update Version Clicked"
                href="javascript:window.location.reload();"
            >
                Update
            </a>
            <br />
            © {{ currentYear }} Tonkean, Inc.
        </div>
    </div>
</div>

<!-- Bar that suggests to refresh the app to get the latest update -->
<div class="main-new-version" ng-if="$rootScope.hasNewVersion" ng-class="{ visible: $rootScope.hasNewVersion }">
    <i class="fa fa-asterisk margin-right"></i>
    <span>New version available!</span>
    <a
        class="margin-left"
        analytics-on="click"
        analytics-category="AppVersion"
        analytics-event="Update Version Clicked"
        href="javascript:window.location.reload();"
    >
        <strong>Update</strong>
    </a>
</div>

<!-- Mobile bottom tab navigation menu -->
<div
    class="mobile-tab-nav flex text-center"
    ng-if="as.currentUser && showHeader && pm.isUserLicensed && !$root.hideMobileBottomMenu"
>
    <a
        class="mobile-tab-button mod-icon"
        ui-sref-active="mod-selected"
        ui-sref="product.board({filter:'all', g:$storage.lastGroupId, st:null})"
    >
        <i class="tab-icon">
            <i class="fa fa-list-ul"></i>
        </i>
        <span class="tab-subtitle">Lists</span>
    </a>
    <a class="mobile-tab-button mod-icon" ui-sref-active="mod-selected" ui-sref="product.calendar">
        <i class="tab-icon">
            <tnk-icon src="../../../../apps/tracks/images/icons/calendar-nav.svg"></tnk-icon>
        </i>
        <span class="tab-subtitle">Calendar</span>
    </a>
    <a class="mobile-tab-button mod-icon" ng-if="as.currentUser && pm.project && pm.isUserLicensed">
        <tnk-activity-popup></tnk-activity-popup>
        <span class="tab-subtitle">Activity</span>
    </a>
    <a class="mobile-tab-button mod-icon" ng-click="toggleMenu()">
        <i class="tab-icon">
            <i class="fa fa-bars"></i>
        </i>
        <span class="tab-subtitle">More</span>
    </a>
</div>
