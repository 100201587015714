import React from 'react';
import type { ReactElement } from 'react';

import { ReactComponent as GChatIcon } from '../../../images/google-chat-icon.svg';
import { ReactComponent as TeamsIcon } from '../../../images/microsoft-teams-logo.svg';
import { ReactComponent as SlackIcon } from '../../../images/slack-icon.svg';

import type { IntegrationType } from '@tonkean/tonkean-entities';

export const integrationToIcon: Partial<Record<IntegrationType, ReactElement>> = {
    SLACK: <SlackIcon />,
    MICROSOFT_TEAMS: <TeamsIcon />,
    GOOGLECHAT: <GChatIcon />,
} as const;

interface Props {
    integrationType: keyof typeof integrationToIcon;
}

const CommunicationIntegrationIcon: React.FC<Props> = ({ integrationType }) => {
    return integrationToIcon[integrationType] ?? <></>;
};

export default CommunicationIntegrationIcon;
