import React from 'react';
import styled from 'styled-components';

import type { SmartSearchAssistantToolMessage } from '../hooks';

import { H3 } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';
import Utils from '@tonkean/utils';

const noOpacityAnimationColor = Utils.hexToRgbaWithOpacity(Theme.colors.light_purple, 0.4);
const fullOpacityAnimationColor = Utils.hexToRgbaWithOpacity(Theme.colors.light_purple, 1);
const MessageText = styled(H3)`
    @keyframes gradient-animation {
        0% {
            background-position: 200% 0;
        }
        100% {
            background-position: -200% 0;
        }
    }
    background: linear-gradient(
        90deg,
        ${noOpacityAnimationColor} 0%,
        ${fullOpacityAnimationColor} 50%,
        ${noOpacityAnimationColor} 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 100%;
    animation: gradient-animation 6s infinite linear;
`;

interface Props {
    assistantToolMessage: SmartSearchAssistantToolMessage;
}

const SmartSearchAssistantToolMessageView: React.FC<Props> = ({ assistantToolMessage }) => {
    return (
        <MessageText $color={Theme.colors.light_purple} $thin>
            {assistantToolMessage.message}
        </MessageText>
    );
};

export default SmartSearchAssistantToolMessageView;
