<div class="bot-tab-pane mod-history">
    <div class="bot-pane-header">
        <div class="common-title-xs">Follow-ups & Alerts</div>

        <div class="common-color-grey3 common-size-xxxs margin-bottom-lg margin-right-lg">
            View the Module messages that were sent in the past, and that are projected for the future.
        </div>

        <div class="flex-vmiddle">
            <div class="margin-right-lg flex">
                <button
                    class="btn btn-no-border gathers-feed-tab-btn"
                    ng-click="data.isShowingFuture = false"
                    ng-class="{ selected: !data.isShowingFuture }"
                >
                    <div class="gathers-feed-tab-btn-header">In the past</div>
                </button>
                <button
                    class="btn btn-no-border gathers-feed-tab-btn"
                    ng-click="data.isShowingFuture = true"
                    ng-class="{ selected: data.isShowingFuture }"
                >
                    <div class="gathers-feed-tab-btn-header">Projected</div>
                </button>
            </div>

            <div class="flex-grow"></div>

            <div class="dropdown margin-right-lg" uib-dropdown>
                <a class="flex-vmiddle common-link-no-style common-title-xxxs" uib-dropdown-toggle>
                    <span class="margin-right-xxs">
                        {{
                            data.isCurrentListSelected
                                ? 'Current module'
                                : data.filter.group
                                ? data.filter.group.name
                                : 'All modules'
                        }}
                    </span>
                    <i class="dropdown-chevron mod-static"></i>
                </a>

                <ul uib-dropdown-menu class="dropdown-menu-right gathers-feed-lists-dropdown">
                    <li ng-click="setGroupFilter(null)">
                        <a>All Modules</a>
                    </li>
                    <li ng-if="data.currentGroup" ng-click="setGroupFilter(data.currentGroup, true)">
                        <a>Current module</a>
                    </li>
                    <li class="divider"></li>
                    <li ng-repeat="group in pm.groups" ng-click="setGroupFilter(group)">
                        <a class="flex-vmiddle">
                            <i
                                class="fa margin-right-xs"
                                ng-class="group.visibilityType === 'PUBLIC' ? 'fa-globe' : 'fa-lock'"
                            ></i>
                            <span>{{ group.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="dropdown" uib-dropdown>
                <a
                    class="flex-vmiddle common-link-no-style common-title-xxxs"
                    uib-popover-template="'./filterByOwnerPopover.template.html'"
                    popover-placement="bottom-right"
                    popover-is-open="data.ownerPopoverIsOpen"
                >
                    <span class="margin-right-xxs">{{ data.filter.owner ? data.filter.owner.name : 'Everyone' }}</span>
                    <i class="dropdown-chevron mod-static"></i>
                </a>
            </div>
        </div>

        <hr class="margin-top-none" />
    </div>

    <tnk-bot-history
        ng-show="!data.isShowingFuture"
        filter="data.filter"
        is-visible="data.isVisible && !data.isShowingFuture"
    ></tnk-bot-history>

    <tnk-bot-future-gathers
        ng-show="data.isShowingFuture"
        filter="data.filter"
        is-visible="data.isVisible && data.isShowingFuture"
    ></tnk-bot-future-gathers>
</div>
