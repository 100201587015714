import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { StyledField, StyledTonkeanExpression } from './SuccessCriteriaSharedStyles';
import { ReactComponent as InfoIcon } from '../../../../../../../../../../images/icons/info10px.svg';
import NewMatchedEntityPreviewCard from '../../../NewMatchedEntityPreviewCard';

import { Field, Tooltip } from '@tonkean/infrastructure';
import { ActionParameterType, EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import type {
    IntegrationSupportedEntity,
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionModuleResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

export const NewMatchedEntityPreviewCardWrapper = styled.div`
    width: 245px;
    height: 80px;
`;

const StyledInfoIcon = styled(InfoIcon)`
    margin-left: 6px;
`;

interface Props {
    projectIntegrationAction: ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>;
    projectIntegration: ProjectIntegration;
    setProjectIntegrationAction: React.Dispatch<
        React.SetStateAction<ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>>
    >;
    actionsCustomFieldTab: TonkeanExpressionAdditionalTab[];
    evaluateExpressionToEvaluatedPreview?: Record<string, string>;
    groupId: string;
    hasExampleRequest: boolean;
    entity: IntegrationSupportedEntity | undefined;
}

const SuccessCriteriaCreate: React.FC<Props> = ({
    projectIntegrationAction,
    projectIntegration,
    setProjectIntegrationAction,
    actionsCustomFieldTab,
    evaluateExpressionToEvaluatedPreview,
    groupId,
    hasExampleRequest,
    entity,
}) => {
    // Memo for the result of the preview for the tonkean expression input.
    const previewOfCreateMatchedEntity: string = useMemo(() => {
        if (!hasExampleRequest) {
            return 'No Test Responses Found';
        }
        const idField = projectIntegrationAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields[0];
        if (idField && evaluateExpressionToEvaluatedPreview?.[idField.value.evaluatedExpression]) {
            return evaluateExpressionToEvaluatedPreview[idField.value.evaluatedExpression] || '';
        } else {
            return '';
        }
    }, [
        evaluateExpressionToEvaluatedPreview,
        hasExampleRequest,
        projectIntegrationAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields,
    ]);

    // On changing the value of the id field we updating the action.
    const onChangeIdField = useCallback(
        (originalExpression: string, evaluatedExpression: string) => {
            if (!entity) {
                return;
            }
            setProjectIntegrationAction((currentAction) => {
                // Combine the old responseHandlingDefinition ( to keep the on failure handle)
                return {
                    ...currentAction,
                    responseHandlingDefinition: {
                        responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                        ...currentAction.responseHandlingDefinition,
                        successDefinition: {
                            ...currentAction.responseHandlingDefinition?.successDefinition,
                            outputLinkedFields: [
                                {
                                    value: {
                                        originalExpression,
                                        evaluatedExpression,
                                        isStripHtmlDisabled: true,
                                        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
                                    },
                                    containsEntityId: true,
                                    name: `${entity?.pluralLabel} Id`,
                                },
                            ],
                        },
                    },
                };
            });
        },
        [entity, setProjectIntegrationAction],
    );

    return (
        <>
            <NewMatchedEntityPreviewCardWrapper>
                <NewMatchedEntityPreviewCard
                    projectIntegrationAction={projectIntegrationAction}
                    projectIntegration={projectIntegration}
                    entity={entity}
                />
            </NewMatchedEntityPreviewCardWrapper>

            <Field
                label={
                    <StyledField>
                        {`${entity?.pluralLabel} ID`}
                        <Tooltip content="You can use Json Path formula to indicate the location of the field value">
                            <StyledInfoIcon />
                        </Tooltip>
                    </StyledField>
                }
                error={
                    !projectIntegrationAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields?.[0]
                        ?.value.originalExpression
                        ? "Value can't be empty"
                        : undefined
                }
            >
                <StyledTonkeanExpression
                    groupId={groupId}
                    workflowVersionId=""
                    onTonkeanExpressionChanged={onChangeIdField}
                    placeholder="Extract value from the response body/headers"
                    additionalTabs={actionsCustomFieldTab}
                    savedOriginalExpression={
                        projectIntegrationAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields[0]
                            ?.value.originalExpression || ''
                    }
                    savedEvaluatedExpression={
                        projectIntegrationAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields[0]
                            ?.value.evaluatedExpression || ''
                    }
                    customPreview={previewOfCreateMatchedEntity}
                    globalExpressionOnly
                />
            </Field>
        </>
    );
};

export default SuccessCriteriaCreate;
