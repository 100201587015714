import AirtableAuthenticationComponent from './AirtableAuthenticationComponent';
import AirtableCustomizeSetupComponent from './AirtableCustomizeSetupComponent';
import airtableImage from '../../../../apps/tracks/images/airtable.svg';
import airtableCircleImage from '../../../../apps/tracks/images/integrations/airtable-circle.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

class AirtableIntegration extends IntegrationDefinitionBase {
    override name = 'airtable';
    override displayName = 'Airtable';
    override description = 'We will only collect rows items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override externalActivityCreatedDateFieldName = 'createdate';
    override entities = [];
    override hasDynamicEntities = true;
    override supportsMultipleIntegrationPerUser = true;

    override customizedAuthenticationComponent = AirtableAuthenticationComponent;
    override customizedSetupComponent = AirtableCustomizeSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(airtableCircleImage, '130px'),
        new IntegrationIcon(airtableImage, '130px'),
    );

    // We define data retention settings to allow the Airtable integration to support multiple dynamic entities.
    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: true,
        editableEntities: {},
        removableEntities: {},
        isOnlyDataRetention: {},
    };
}

export default AirtableIntegration;
