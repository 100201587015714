import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Input, LoadingCircle } from '@tonkean/infrastructure';
import type { ProjectIntegration, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ParameterName = styled.div`
    font-size: ${FontSize.MSMALL_13};
    font-weight: bold;
    margin-right: 9px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 0;
`;

const ParameterValue = styled.div``;

const ErrorText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.error};
    margin-top: 10px;
`;

interface Props {
    configurationProjectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    storageProjectIntegration: ProjectIntegration;
    onStorageIntegrationDynamicParametersChanged: (storageIntegration) => void;
}

const NoCodeProjectIntegrationUploadDynamic: React.FC<Props> = ({
    configurationProjectIntegrationId,
    storageProjectIntegration,
    onStorageIntegrationDynamicParametersChanged,
}) => {
    const {
        data: uploadCustomAction,
        loading: loadingUploadCustom,
        error: errorUploadCustom,
    } = useTonkeanService('getUploadCustomActionByProjectIntegrationId', storageProjectIntegration.id);

    const {
        data: storageIntegrationConfiguration,
        loading: loadingStorageIntegrationConfiguration,
        error: errorStorageIntegrationConfiguration,
    } = useTonkeanService('getProjectIntegrationStorageConfiguration', configurationProjectIntegrationId);

    const [{ error: updateStorageIntegrationError }, updateStorageIntegrationConfiguration] = useLazyTonkeanService(
        'updateProjectIntegrationStorageIntegrationConfiguration',
    );

    const [dynamicFieldsMap, setDynamicFieldsMap] = useState(
        storageProjectIntegration?.storageIntegrationConfiguration?.dynamicParameters || {},
    );

    useEffect(() => {
        if (!storageIntegrationConfiguration) {
            return;
        }

        setDynamicFieldsMap(storageIntegrationConfiguration.storageIntegrationConfiguration?.dynamicParameters || {});
    }, [storageIntegrationConfiguration]);

    useEffect(() => {
        if (configurationProjectIntegrationId) {
            const timeOutId = setTimeout(
                () =>
                    updateStorageIntegrationConfiguration(configurationProjectIntegrationId, {
                        dynamicParameters: dynamicFieldsMap,
                    }),
                300,
            );
            return () => clearTimeout(timeOutId);
        }
    }, [dynamicFieldsMap, configurationProjectIntegrationId, updateStorageIntegrationConfiguration]);

    return (
        <>
            {/* Handle all loading */}
            {(loadingUploadCustom || loadingStorageIntegrationConfiguration) && <LoadingCircle />}

            {/* Handle all errors */}
            {errorUploadCustom && <ErrorText>Error loading upload action parameters</ErrorText>}
            {updateStorageIntegrationError && (
                <ErrorText>Error updating project integration dynamic fields configuration</ErrorText>
            )}
            {configurationProjectIntegrationId !== undefined && errorStorageIntegrationConfiguration && (
                <ErrorText>Error loading the storage integration configuration from server</ErrorText>
            )}

            {uploadCustomAction?.uploadProjectIntegrationAction?.parametersDefinition?.parameters?.map((parameter) => (
                <div key={parameter.id}>
                    <div className="flex-vmiddle margin-top">
                        <ParameterName>{parameter.displayName}:</ParameterName>
                        <ParameterValue>
                            <Input
                                id={parameter.id}
                                type="text"
                                value={
                                    dynamicFieldsMap[parameter.id] === undefined ? '' : dynamicFieldsMap[parameter.id]
                                }
                                onChange={({ target: { value } }) => {
                                    setDynamicFieldsMap({
                                        ...dynamicFieldsMap,
                                        [parameter.id]: value,
                                    });
                                    onStorageIntegrationDynamicParametersChanged({
                                        ...dynamicFieldsMap,
                                        [parameter.id]: value,
                                    });
                                }}
                            />
                        </ParameterValue>
                    </div>
                </div>
            ))}
        </>
    );
};

export default NoCodeProjectIntegrationUploadDynamic;
