import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const InfoText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: #34393e;
    margin-top: 10px;
`;

const ErrorText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.error};
    margin-top: 10px;
`;

const ButtonWrapper = styled.div`
    margin-right: 6px;
`;

interface Props {
    integrationId: string;
    query: string | undefined;
    pageSize: number | undefined;
    onTestSuccessful: () => void;
    onTestFailed: () => void;
    projectIntegration: ProjectIntegration | undefined;
}

const SQLDatabaseTestQuery: React.FC<Props> = ({
    integrationId,
    query,
    pageSize,
    onTestSuccessful,
    onTestFailed,
    projectIntegration,
}) => {
    const projectManager = useAngularService('projectManager');
    const [{ loading }, callExecuteIntegrationCommand] = useLazyTonkeanService('executeIntegrationCommand');

    const [successfulData, setSuccessfulData] = useState<{ resultCount: string } | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    // If query is changed, we erase previous test data.
    useEffect(() => {
        setSuccessfulData(undefined);
        setErrorMessage(undefined);
    }, [query]);

    const testQuery = () => {
        // We only test if we have a query in hand.
        if (query) {
            // We reset the previous test results before executing another test.
            setSuccessfulData(undefined);
            setErrorMessage(undefined);

            callExecuteIntegrationCommand(
                projectManager.project.id,
                integrationId,
                'FETCH_QUERY',
                { query, pageSize },
                projectIntegration?.id,
            )
                .then((data) => {
                    if (data['errorOccurred']) {
                        setErrorMessage(data.errorMessage);
                        onTestFailed();
                    } else {
                        setSuccessfulData({ resultCount: data.resultCount });
                        onTestSuccessful();
                    }
                })
                .catch(() => {
                    setErrorMessage('Error fetching query.');
                    onTestFailed();
                });
        }
    };

    return (
        <>
            <ButtonWrapper>
                <Button
                    data-automation="sql-database-test-query-test-button"
                    size={ButtonSize.SMALL}
                    shape={ButtonShape.ROUND}
                    onClick={() => testQuery()}
                    outlined
                >
                    Test
                </Button>
            </ButtonWrapper>

            {loading && <InfoText data-automation="sql-database-test-query-loading-message">Testing query...</InfoText>}

            {errorMessage && (
                <ErrorText data-automation="sql-database-test-query-error-message">{errorMessage}</ErrorText>
            )}

            {successfulData && (
                <InfoText data-automation="sql-database-test-query-success-message">
                    {successfulData.resultCount} results returned
                </InfoText>
            )}
        </>
    );
};

export default SQLDatabaseTestQuery;
