import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function EnterpriseOverviewCtrl(
    $scope,
    $rootScope,
    $timeout,
    authenticationService,
    tonkeanService,
    utils,
) {
    $scope.data = {
        owners: angular.copy(authenticationService.currentUser.enterprise.owners),
        enterprise: authenticationService.currentUser.enterprise,
        enterpriseId: authenticationService.currentUser.enterprise.id,
        showEditOwners: false,
        savingOwners: false,
        toggles: {},
        loadingState: {},
        loginMethod: {
            isSet: !utils.isNullOrUndefined(authenticationService.currentUser.enterprise.preferredLoginType),
            supportedMethodsMap: {
                SLACK: {
                    label: 'Slack',
                    name: 'SLACK',
                },
                GOOGLE: {
                    label: 'Google',
                    name: 'GOOGLE',
                },
                MICROSOFT: {
                    label: 'Microsoft',
                    name: 'MICROSOFT',
                },
                OKTA: {
                    label: 'Okta',
                    name: 'OKTA',
                    urlValidation: getUrlValidationConfig('acme.okta.com'),
                    secrets: 'ClientSecret',
                },
                EMAIL: {
                    label: 'Email',
                    name: 'EMAIL',
                },
                PING_IDENTITY: {
                    label: 'Ping Identity',
                    name: 'PING_IDENTITY',
                    urlValidation: getUrlValidationConfig('https://auth.pingone.com/{env-id}'),
                    secrets: 'ClientSecret',
                    ssoUrlsEditable: true
                },
            },
            supportedMethods: [],
            selectedMethod: '',
            loginMethodUrl: authenticationService?.currentUser?.enterprise?.loginMethodUrl,
            loginMethodSecrets: {},
            savingPreferredLoginType: false,
            errorSavingPreferredLoginType: null,
        },
        enterpriseDomains: [],
        loginMethodFormPristine: true,
    };

    $scope.init = function () {
        $scope.data.loginMethod.defaultMethod = $scope.data.loginMethod.supportedMethodsMap.SLACK;
        $scope.data.loginMethod.supportedMethods = [
            $scope.data.loginMethod.supportedMethodsMap.SLACK,
            $scope.data.loginMethod.supportedMethodsMap.GOOGLE,
            $scope.data.loginMethod.supportedMethodsMap.OKTA,
            $scope.data.loginMethod.supportedMethodsMap.MICROSOFT,
            $scope.data.loginMethod.supportedMethodsMap.EMAIL,
            $scope.data.loginMethod.supportedMethodsMap.PING_IDENTITY,
        ];
        $scope.data.loginMethod.selectedMethod =
            $scope.data.loginMethod.supportedMethodsMap[
                authenticationService.currentUser.enterprise.preferredLoginType
            ];
        tonkeanService.getEnterpriseDomains($scope.data.enterpriseId).then(function (data) {
            $scope.data.enterpriseDomains = data.enterpriseDomains;
        });
    };

    $scope.editOwners = function () {
        $scope.data.owners = angular.copy(authenticationService.currentUser.enterprise.owners);
        $scope.data.showEditOwners = true;
    };

    $scope.addOwner = function (person) {
        $scope.data.owners = angular.copy(authenticationService.currentUser.enterprise.owners);
        $scope.data.owners.push(person);
        $scope.saveOwners();
    };

    $scope.saveOwners = function (force) {
        if ($scope.ownersForm.$valid && !$scope.data.savingOwners) {
            const saveAnyway = force || utils.findFirstById($scope.data.owners, authenticationService.currentUser.id);
            if (saveAnyway) {
                $scope.data.savingOwners = true;
                tonkeanService
                    .saveEnterpriseOwners($scope.data.enterpriseId, $scope.data.owners)
                    .then(function () {
                        $scope.data.enterprise.owners = angular.copy($scope.data.owners);
                        $scope.data.showEditOwners = false;
                        $scope.$emit('alert', {
                            msg: 'Saved!',
                            type: 'success',
                        });
                    })
                    .catch(function (error) {
                        $scope.$emit('alert', error);
                    })
                    .finally(function () {
                        $scope.data.savingOwners = false;
                    });
            } else {
                $scope.mboxData = {
                    title: 'Removing Yourself',
                    body: "You can't remove yourself as an owner of this enterprise.",
                    cancelLabel: 'Take me back',
                };

                $rootScope.modal
                    .openMessageBox({
                        scope: $scope,
                        size: 'sm',
                        windowClass: 'mod-danger',
                    })
                    .result.then(function () {
                        $scope.saveOwners(true);
                    });
            }
        }
    };

    $scope.onEnterpriseNameClick = function () {
        $scope.data.toggles.editEnterpriseName = true;
        $scope.data.tempEnterpriseName = $scope.data.enterprise.name;
        $timeout(function () {
            document.querySelector('#input-edit-enterprise-name').focus();
        });
    };

    $scope.onEnterpriseNameKeyDown = function ($event) {
        if ($scope.disableEscapeEvent($event)) {
            $scope.data.toggles.editEnterpriseName = false;
        }
    };

    $scope.disableEscapeEvent = function ($event) {
        if (
            ($event.code === 'Escape' || $event.keyCode === 27) && // keycode 27 is escape
            $event &&
            $event.target
        ) {
            // call both to make sure it disables propagation
            $event.preventDefault();
            $event.stopPropagation();
            return true;
        }

        return false;
    };

    $scope.updateEnterpriseName = function (name) {
        $scope.data.loadingState.updateEnterpriseName = true;
        analyticsWrapper.track('Update Name', { category: 'Enterprise overview' });
        tonkeanService.updateEnterpriseName($scope.data.enterpriseId, name).then(function (data) {
            $scope.data.toggles.editEnterpriseName = false;
            $scope.data.loadingState.updateEnterpriseName = false;
            $scope.data.enterprise.name = data.name;
        });
    };

    $scope.setLoginMethodFormDirty = function () {
        if (
            authenticationService?.currentUser?.enterprise?.loginMethodUrl === $scope.data.loginMethod.loginMethodUrl &&
            !$scope.data.loginMethod.loginMethodSecrets.clientSecret &&
            !$scope.data.loginMethod.loginMethodSecrets.clientId
        ) {
            $scope.data.loginMethodFormPristine = true;
        } else {
            $scope.data.loginMethodFormPristine = false;
        }
    };

    /**
     * Occurs once the user checks or unchecks the checkbox of preferred login type.
     * The radio button requires to update the model in the on click. the value of the radio should be string.
     */
    $scope.toggleIsLoginMethodSet = function (isSet) {
        $scope.data.loginMethod.isSet = isSet;
        $scope.data.loginMethodFormPristine = false;
        if (isSet) {
            $scope.setLoginMethod($scope.data.loginMethod.defaultMethod);
        } else {
            $scope.setLoginMethod(null);
        }
    };

    /**
     * Set enterprise login method - slack/google.
     */
    $scope.setLoginMethod = function (loginMethod) {
        $scope.data.loginMethod.selectedMethod = loginMethod;
        $scope.data.loginMethodFormPristine = false;
        if (loginMethod.urlValidation) {
            $timeout(function () {
                document.querySelector('#login-method-url').focus();
            });
        }
    };

    /**
     * Cancels the edit mode and reverts to the previous settings
     */
    $scope.cancelEditLoginMethod = function () {
        $scope.data.toggles.editLoginMethod = false;
        $scope.data.loginMethod.selectedMethod = $scope.data.tempLoginMethod;
        $scope.data.loginMethod.isSet = $scope.data.tempIsSetLoginMethod;
    };

    /**
     * When starting edit for the login method restrictions
     */
    $scope.onStartEditLoginMethodMode = function () {
        $scope.data.toggles.editLoginMethod = true;
        $scope.data.tempLoginMethod = $scope.data.loginMethod.selectedMethod;
        $scope.data.tempIsSetLoginMethod = $scope.data.loginMethod.isSet;
    };

    /**
     * Toggle "Advanced Settings" in the preferred authentication screen
     */
    $scope.toggleAdvancedSettings = function () {
        $scope.data.advancedSettingsOpen = !$scope.data.advancedSettingsOpen
    };

    /**
     * Used to save the login method
     * @returns {*}
     */
    $scope.saveLoginMethod = function () {
        $scope.data.loginMethod.savingPreferredLoginType = true;
        $scope.data.loginMethod.errorSavingPreferredLoginType = null;

        if ($scope.data.loginMethod.isSet === 'true') {
            if (
                $scope.data.loginMethod.selectedMethod.urlValidation &&
                utils.isNullOrEmpty($scope.data.loginMethod.loginMethodUrl)
            ) {
                $scope.data.loginMethod.errorSavingPreferredLoginType = 'Must enter a valid identity provider url.';
                $scope.data.loginMethod.savingPreferredLoginType = false;
                return;
            }

            if (
                $scope.data.loginMethod.selectedMethod.secrets &&
                $scope.data.loginMethod.selectedMethod.secrets === 'ClientSecret' &&
                (utils.isNullOrEmpty($scope.data.loginMethod.loginMethodSecrets.clientId) ||
                    utils.isNullOrEmpty($scope.data.loginMethod.loginMethodSecrets.clientSecret))
            ) {
                $scope.data.loginMethod.errorSavingPreferredLoginType = 'Must enter a valid clientId and clientSecret.';
                $scope.data.loginMethod.savingPreferredLoginType = false;
                return;
            }
        }

        return tonkeanService
            .updateEnterprisePreferredLoginType(
                $scope.data.enterpriseId,
                $scope.data.loginMethod?.selectedMethod?.name,
                $scope.data.loginMethod.loginMethodUrl,
                $scope.data.loginMethod.loginMethodSecrets,
            )
            .then((data) => {
                // Also update the user, so the changes are up to date locally as well.
                authenticationService.currentUser.enterprise.preferredLoginType = data.preferredLoginType;
                authenticationService.updateCurrentUser(authenticationService.currentUser);
                $scope.data.toggles.editLoginMethod = false;
                $scope.data.loginMethodFormPristine = true;
            })
            .catch(() => {
                $scope.data.loginMethod.errorSavingPreferredLoginType = "Couldn't set authentication method.";
            })
            .finally(() => {
                $scope.data.loginMethod.savingPreferredLoginType = false;
            });
    };

    $scope.updateEnterpriseDomains = function (domains) {
        $scope.data.loadingState.updateEnterpriseDomains = true;
        tonkeanService.updateEnterpriseDomains($scope.data.enterpriseId, domains).then(function (data) {
            $scope.data.enterpriseDomains = data.enterpriseDomains;
            $scope.data.loadingState.updateEnterpriseDomains = false;
        });
    };

    /**
     * gets an object representing the url validation settings
     * @param urlExample the url to be presented in the ui as an example (for example: acme.okta.com)
     * @returns {{urlExample: *}}
     */
    function getUrlValidationConfig(urlExample) {
        return {
            //
            urlExample,
        };
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('EnterpriseOverviewCtrl', lateConstructController(EnterpriseOverviewCtrl));
