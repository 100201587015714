import template from './tnkWorkflowMatchByField.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkflowMatchByField', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        projectIntegration: '<',
        viewOnly: '<',
        entityMetadata: '<',
        entityMatchField: '<',
        workerItemMatchFieldDefinition: '<',
        workerItemMatchSpecialField: '<',

        onWorkflowMatchByFieldChanged: '&',
    },
    template,
    controller: 'WorkflowMatchByFieldCtrl',
});
