<div class="tnk-form-field-select-actions-menu">
    <div uib-dropdown>
        <!-- Three dots -->
        <div
            class="svg-icon-smd common-color-grey svg-icon-hover-primary pointer"
            data-automation="form-field-select-action-settings-button"
            uib-dropdown-toggle=""
        >
            <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
        </div>

        <ul class="dropdown-menu-right" uib-dropdown-menu>
            <!-- Make required -->
            <li
                ng-if="data.formQuestionType !== 'COLLECT_INNER_ITEMS'"
                ng-class="{ disabled: !data.field.fieldDefinition }"
            >
                <a
                    ng-click="data.field.fieldDefinition && data.toggleIsRequired(data.field)"
                    class="padding-left-none"
                    data-automation="form-field-select-actions-menu-is-required-checkbox"
                >
                    <div class="flex-vmiddle padding-left">
                        <span
                            class="common-color-grey margin-right-xs"
                            ng-class="data.field.isRequired ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                        ></span>
                        <div class="flex-grow">Is required</div>
                    </div>
                </a>
            </li>

            <!-- Make inline -->
            <li ng-if="data.enableInline" ng-class="{ disabled: !data.field.fieldDefinition }">
                <a
                    ng-click="data.field.fieldDefinition && data.toggleFieldInline({ field: data.field })"
                    class="padding-left-none"
                >
                    <div class="flex-vmiddle padding-left">
                        <span
                            class="common-color-grey margin-right-xs"
                            ng-class="data.field.isInline ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                            data-automation="form-field-select-action-menu-inline-question-checkbox"
                        ></span>
                        <div class="flex-grow">Inline question</div>
                    </div>
                </a>
            </li>

            <!-- Show title -->
            <li
                ng-if="data.formQuestionType !== 'COLLECT_INNER_ITEMS'"
                ng-class="{ disabled: !data.field.fieldDefinition }"
            >
                <a ng-click="data.field.fieldDefinition && toggleShowTitle(data.field)" class="padding-left-none">
                    <div class="flex-vmiddle padding-left">
                        <span
                            class="common-color-grey margin-right-xs"
                            ng-class="data.field.showTitle ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                            data-automation="form-field-select-actions-menu-click-on-show-button"
                        ></span>
                        <div class="flex-grow">Show question title</div>
                    </div>
                </a>
            </li>

            <!-- Edit mode -->
            <li
                ng-if="data.formQuestionType !== 'COLLECT_INNER_ITEMS' && data.formType === 'UPDATE'"
                ng-class="{
                    disabled:
                        !data.field.fieldDefinition.updateable ||
                        !data.field.fieldDefinition.canUpdateFromUI ||
                        data.field.fieldDefinition.idRelationField
                }"
            >
                <a ng-click="data.field.fieldDefinition && toggleViewOnly(data.field)" class="padding-left-none">
                    <div class="flex-vmiddle padding-left">
                        <span
                            class="common-color-grey margin-right-xs"
                            ng-class="data.field.viewOnly ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                            data-automation="form-field-select-actions-menu-show-values-view-only"
                        ></span>
                        <div class="flex-grow">Show value as 'view only'</div>
                    </div>
                </a>
            </li>

            <!-- Field validation -->
            <li ng-class="{ disabled: !data.field.fieldDefinition }">
                <a
                    ng-click="data.field.fieldDefinition && (data.showValidationConfigurationModal = true)"
                    class="padding-left-none"
                >
                    <span class="flex-vmiddle padding-left">
                        <span
                            class="common-color-grey margin-right-xs"
                            ng-class="data.field.validation.length ? 'fa fa-check-square-o' : 'fa fa-square-o'"
                        ></span>
                        <span class="flex-grow">Field validation</span>
                        <tnk-field-validation-configuration-modal
                            workflow-version-id="data.workflowVersionId"
                            group-id="data.groupId"
                            display-name="data.field.displayName || data.field.fieldDefinition.name"
                            field-validation="data.field.validation"
                            open="data.showValidationConfigurationModal"
                            on-close="(closeValidationConfigurationModal)"
                            on-change="(onValidationChanged)"
                        ></tnk-field-validation-configuration-modal>
                    </span>
                </a>
            </li>

            <!-- Collapse description -->
            <li
                ng-if="data.formQuestionType !== 'COLLECT_INNER_ITEMS'"
                ng-class="{ disabled: !data.field.fieldDefinition }"
            >
                <a
                    ng-click="data.field.fieldDefinition && toggleCollapseDescriptionByDefault(data.field)"
                    class="padding-left-none"
                >
                    <div class="flex-vmiddle padding-left">
                        <span
                            class="common-color-grey margin-right-xs"
                            ng-class="
                                data.field.collapseDescriptionByDefault ? 'fa fa-check-square-o' : 'fa fa-square-o'
                            "
                        ></span>
                        <div class="flex-grow">Collapse description by default</div>
                    </div>
                </a>
            </li>

            <!-- Remove -->
            <li ng-if="data.enableDelete">
                <a
                    ng-click="data.removeFormField({ index: $index })"
                    class="padding-left-none"
                    id="delete-question-edit-field"
                    data-automation="form-field-select-actions-menu-delete-button"
                >
                    <div class="flex-vmiddle padding-left">
                        <div>
                            <span class="svg-icon-xs common-color-grey margin-right-xs">
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </span>
                        </div>
                        <div class="flex-grow margin-top-xxxs">Delete question</div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</div>
