<tnk-deprecated-people-selector
    class="flex-grow margin-none block"
    selected-people="data.selectedPeopleArray"
    no-custom="false"
    max-tags="{{data.maxTags}}"
    on-tag-added="onUpdate()"
    on-tag-removed="onUpdate()"
    no-validation="false"
    max-tag-chars="22"
    placeholder="{{::data.placeholder || 'Search for a person'}}"
    disabled="data.disabled"
    hide-placeholder-when-disabled="data.hidePlaceholderWhenDisabled"
    hide-tags-background-when-disabled="data.hideTagsBackgroundWhenDisabled"
    include-external="data.includeExternal"
 />
