import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css, type CSSObject } from 'styled-components';

import FieldError from './FieldError';
import NextGatherUpdateSelector from './NextGatherUpdateSelector';
import UpdateFieldAsyncSearchDropdownSelectorWrapper from './UpdateFieldAsyncSearchDropdownSelectorWrapper';
import UpdateFieldLinkText from './UpdateFieldLinkText';
import UpdateFieldTextOrURL from './UpdateFieldTextOrURL';
import UpdateFieldWrapper from './UpdateFieldWrapper';
import type UpdateListFieldProps from './UpdateListFieldProps';
import useGetFieldInstance, { getFieldInstance } from './useGetFieldInstance';

import { TnkNotificationSettings } from '@tonkean/angular-components';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { SPECIAL_FIELD_KEYS } from '@tonkean/constants';
import {
    buildTnkSelectOption,
    Checkbox,
    DatepickerInput,
    Input,
    InputReveal,
    InputRevealContainer,
    PeopleSelectorByPersonIdentifier,
    SimpleSelect,
    StaticStatusSelectPresentationMode,
    StatusSelect,
    Textarea,
    TonkeanItemTagsSelector,
} from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { FieldDefinition, Initiative, TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import {
    FieldDefinitionDateFormatToDisplayMapper,
    FieldDefinitionDisplayFormat,
    FieldType,
    ItemInterfaceScrollContext,
} from '@tonkean/tonkean-entities';
import { getFieldRangeAppliedStyle } from '@tonkean/tonkean-utils';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { debouncer, isUrlValid } from '@tonkean/utils';

const TnkNotificationSettingsInputRevealWrapper = styled(InputRevealContainer)`
    margin-top: -10px;
`;

// wrapper to fix dropdown being hidden partially (z-index does not work with scroll)
// on scroll event, hide dropdown
const SelectorWrapper = styled(Clickable)<{
    $fieldLocation: DOMRect | undefined;
    $dropdownVisible: boolean;
    $isInPopover: boolean;
}>`
    width: 100%;
    ${({ $fieldLocation }) =>
        $fieldLocation &&
        css`
            tags-input .autocomplete {
                position: fixed;
            }

            tnk-channel-selector ul.dropdown-menu {
                position: fixed;
                top: ${$fieldLocation.top + $fieldLocation.height}px;
                left: ${$fieldLocation.left}px;
                max-width: 180px;
            }
        `}
    ${({ $fieldLocation, $isInPopover }) =>
        !$isInPopover &&
        $fieldLocation &&
        css`
            tags-input .autocomplete {
                top: ${$fieldLocation.top + $fieldLocation.height}px;
                left: ${$fieldLocation.left}px;
            }
        `}
  ${({ $dropdownVisible }) =>
        !$dropdownVisible &&
        css`
            tags-input .autocomplete {
                display: none;
            }

            tnk-channel-selector ul.dropdown-menu {
                display: none;
            }
        `}
`;

const EmphasizedTextArea = styled(Textarea)<{ $bold: boolean }>`
    ${({ $bold }) =>
        $bold &&
        css`
            font-size: ${FontSize.XXXXLARGE_24};
            font-weight: 500;
        `}
`;

const SEPERATOR = '[TNK_SPECIAL_CHAR]';

interface Props<T = unknown> {
    fieldDefinition: FieldDefinition<T>;
    onValueSave: (newValue: string) => void;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    groupId: TonkeanId<TonkeanType.GROUP>;
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    viewOnly?: boolean;
    hideColorsAndTrends?: boolean;
    revealByDefault: boolean;
    fieldsAreBeingEdited: boolean;
    showEmptyFieldWhenNoInitiative: boolean;
    isSubmittingForm: boolean;
    overrideValue?: any;
    shouldOverrideValue?: boolean;
    isInPopover?: boolean;
    isFilterInput?: boolean;
    onEnterDown?: (event: React.KeyboardEvent) => void;
    placeholderText?: string;
    onValueSet?: (value: any) => void;
    hasError?: boolean;
    isInBuilder?: boolean;
    updateListFieldProps?: UpdateListFieldProps;
    isEmphasizeText?: boolean;
    isInGalleryView?: boolean;
    isInTableView?: boolean;
    editingEnabledInWidget?: boolean;
    saveWhileTyping?: boolean;
    getLoadedFieldDefinitionById: (
        fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>,
    ) => FieldDefinition | undefined;
    submitAsForm?: boolean;
}

enum SpecialFieldsType {
    PERSON_SELECTOR = 'PersonSelector',
    MULTI_PERSON_SELECTOR = 'MultiPersonSelector',
    STATUS_PICKER = 'StatusPicker',
    TAGS_PICKER = 'TagsPicker',
    NEXT_GATHER_UPDATE_SELECTOR = 'NextGatherUpdateSelector',
    CHANNEL_SELECTOR = 'ChannelSelector',
}

const UpdateField: React.FC<Props> = ({
    showEmptyFieldWhenNoInitiative,
    fieldDefinition,
    onValueSave,
    onValueSet,
    projectId,
    groupId,
    initiative,
    workflowVersion,
    viewOnly,
    hideColorsAndTrends,
    revealByDefault,
    fieldsAreBeingEdited,
    isSubmittingForm,
    overrideValue,
    shouldOverrideValue,
    onEnterDown,
    placeholderText,
    isInPopover = false,
    isFilterInput = false,
    hasError,
    isInBuilder,
    updateListFieldProps,
    isEmphasizeText = false,
    isInGalleryView = false,
    isInTableView = false,
    editingEnabledInWidget = false,
    saveWhileTyping,
    getLoadedFieldDefinitionById,
    submitAsForm,
    ...props
}) => {
    const { scrollingEventObject } = useContext(ItemInterfaceScrollContext);
    const [value, setValue] = useState<unknown>(overrideValue);
    const [formattedValue, setFormattedValue] = useState<string | undefined>();
    const [lastSavedValue, setLastSavedValue] = useState<unknown>();
    const [multiValueList, setMultiValueList] = useState<string[]>([]);
    const [dropdownFieldLocation, setDropdownFieldLocation] = useState<DOMRect | undefined>();
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(true);
    const showPreviewBuilderLikePreview = useFeatureFlag('tonkean_feature_preview_builder_view_like_preview');

    const dateFieldDisplayFormatDetails =
        FieldDefinitionDateFormatToDisplayMapper[fieldDefinition.displayFormat || 'DEFAULT_DATE'] ||
        FieldDefinitionDateFormatToDisplayMapper['DEFAULT_DATE'];

    const selectorRef = useRef<HTMLDivElement>(null);

    const updateDropdownFieldLocation = useCallback(() => {
        setDropdownVisible(true);
        setDropdownFieldLocation(selectorRef.current?.getBoundingClientRect());
    }, [selectorRef]);

    // On scroll event, dropdown need be hidden (because it is in fixed position)
    useEffect(() => {
        setDropdownVisible(false);
    }, [scrollingEventObject]);

    const fieldInstance = useGetFieldInstance(
        groupId,
        fieldDefinition,
        initiative,
        workflowVersion,
        showEmptyFieldWhenNoInitiative,
    );

    const projectManager = useAngularService('projectManager');
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const dropdownFieldOptions = useMemo(() => {
        const possibleValuesSet = new Set<string>();
        if (
            fieldDefinition.dropdownOptionsFromFieldDefinitionId &&
            fieldDefinition.dropdownSource === 'FROM_FIELD' &&
            workflowVersion
        ) {
            const dropdownOptionsFromFieldDefinition = getLoadedFieldDefinitionById(
                fieldDefinition.dropdownOptionsFromFieldDefinitionId,
            );
            if (dropdownOptionsFromFieldDefinition) {
                const optionsField = getFieldInstance(
                    dropdownOptionsFromFieldDefinition,
                    initiative,
                    workflowVersion,
                    groupId,
                    !initiative,
                    groupsMap,
                );

                const optionsFieldArray = optionsField?.value
                    ? optionsField.value.toString().split(fieldDefinition.inputMultiValueSeparator || SEPERATOR)
                    : [];
                optionsFieldArray.forEach((item) => possibleValuesSet.add(item));
            }
        }

        fieldDefinition.possibleValues?.forEach((item) => possibleValuesSet.add(item));
        const possibleValues = [...possibleValuesSet];
        return possibleValues.map((item) => buildTnkSelectOption(item));
    }, [
        fieldDefinition.dropdownOptionsFromFieldDefinitionId,
        fieldDefinition.dropdownSource,
        fieldDefinition.inputMultiValueSeparator,
        fieldDefinition.possibleValues,
        groupId,
        groupsMap,
        initiative,
        workflowVersion,
        getLoadedFieldDefinitionById,
    ]);

    let rangeStyle: CSSObject | undefined = undefined;

    if (
        !fieldDefinition.isSpecialField &&
        fieldInstance?.appliedRangeNumber !== undefined &&
        fieldDefinition.ranges[fieldInstance?.appliedRangeNumber] &&
        !hideColorsAndTrends
    ) {
        rangeStyle = getFieldRangeAppliedStyle(
            fieldDefinition.isImportant,
            fieldDefinition.ranges[fieldInstance?.appliedRangeNumber]?.color!,
        )?.reactStyle;
    }

    const [editing, setEditing] = useState(false);

    useEffect(() => {
        if (
            editing || // if we are currently in edit on a field
            isSubmittingForm || // or the form is submitting to the server
            (submitAsForm && fieldsAreBeingEdited) // or we are in submitAsForm mode and some fields are dirty
        ) {
            return;
        }
        const foundValue = fieldInstance?.value as unknown;
        const foundFormattedValue = fieldInstance?.formattedValue;

        if (foundValue || overrideValue === undefined) {
            setValue(foundValue);
        } else if (!foundValue && shouldOverrideValue) {
            setValue(overrideValue);
        }
        onValueSet?.(foundValue);
        setLastSavedValue(foundValue);
        setFormattedValue(foundFormattedValue);
    }, [
        editing,
        fieldInstance?.formattedValue,
        fieldInstance?.value,
        overrideValue,
        shouldOverrideValue,
        onValueSet,
        isSubmittingForm,
        fieldsAreBeingEdited,
        submitAsForm,
    ]);

    const definitionType = useMemo<SpecialFieldsType | FieldType>(() => {
        switch (fieldDefinition?.id) {
            case SPECIAL_FIELD_KEYS.ownerId:
                return SpecialFieldsType.PERSON_SELECTOR;
            case SPECIAL_FIELD_KEYS.status:
            case SPECIAL_FIELD_KEYS.statusText:
                return SpecialFieldsType.STATUS_PICKER;
            case SPECIAL_FIELD_KEYS.tags:
                return SpecialFieldsType.TAGS_PICKER;
            case SPECIAL_FIELD_KEYS.nextGatherUpdate:
                return SpecialFieldsType.NEXT_GATHER_UPDATE_SELECTOR;
            case SPECIAL_FIELD_KEYS.itemNotificationChannel:
                return SpecialFieldsType.CHANNEL_SELECTOR;
        }

        if (
            fieldDefinition?.fieldType === FieldType.String &&
            fieldDefinition?.displayFormat === FieldDefinitionDisplayFormat.USER
        ) {
            if (fieldDefinition.isMultiValueField) {
                return SpecialFieldsType.MULTI_PERSON_SELECTOR;
            }

            return SpecialFieldsType.PERSON_SELECTOR;
        }

        if (
            fieldDefinition?.fieldType === FieldType.String &&
            fieldDefinition?.displayFormat === FieldDefinitionDisplayFormat.LONG_TEXT
        ) {
            return FieldType.LongString;
        }

        if (fieldDefinition.fieldType === FieldType.List) {
            return fieldDefinition?.fieldType;
        }

        return fieldDefinition?.displayAs || fieldDefinition?.fieldType;
    }, [
        fieldDefinition?.id,
        fieldDefinition?.fieldType,
        fieldDefinition?.displayAs,
        fieldDefinition?.displayFormat,
        fieldDefinition.isMultiValueField,
    ]);

    const isMultiValueList = definitionType === FieldType.List && fieldDefinition?.isMultiValueField;

    const debounce = useMemo(() => {
        return debouncer(300);
    }, []);
    const onChange = useCallback(
        (newValue, dontSave = false) => {
            setValue(newValue);
            setFormattedValue(newValue);
            const saveValue = () => {
                if (newValue !== lastSavedValue) {
                    onValueSave(isMultiValueList ? newValue.join(fieldDefinition.inputMultiValueSeparator) : newValue);
                    setLastSavedValue(newValue);
                }
            };

            if (saveWhileTyping) {
                debounce(saveValue, !dontSave);
            } else if (!dontSave) {
                saveValue();
            }
        },
        [
            debounce,
            fieldDefinition.inputMultiValueSeparator,
            isMultiValueList,
            lastSavedValue,
            onValueSave,
            saveWhileTyping,
        ],
    );

    useEffect(() => {
        if (isMultiValueList) {
            let newMultiValue: string[] = [];
            if (value) {
                if (Array.isArray(value)) {
                    newMultiValue = value;
                } else {
                    newMultiValue = (value as string).split(fieldDefinition.inputMultiValueSeparator) as string[];
                }
            }
            setMultiValueList(newMultiValue);
        }
    }, [value, setMultiValueList, isMultiValueList, fieldDefinition.inputMultiValueSeparator]);

    let valueComponent: JSX.Element;
    const finalReadOnly =
        !isFilterInput &&
        (!isInBuilder || showPreviewBuilderLikePreview) &&
        (!fieldDefinition.updateable || viewOnly || fieldDefinition.systemUtilized);

    const [isNumberInputFocused, setIsNumberInputFocused] = useState(false);
    const [isNumberInputHovered, setIsNumberInputHovered] = useState(false);
    const showFormattedValue = !(isNumberInputFocused || isNumberInputHovered);

    // Tooltip should be displayed only for types 'string, long string and number' and only in read only state
    const tooltipEnabled =
        value &&
        (definitionType === FieldType.String ||
            definitionType === FieldType.LongString ||
            definitionType === FieldType.Number) &&
        finalReadOnly;

    const dropDownStyling = useMemo(() => {
        return !editingEnabledInWidget && (isInTableView || isInGalleryView)
            ? {
                  valueContainer: () => ({ padding: '0px', overflow: 'visible' }),
                  multiValue: () => ({ margin: '0px' }),
              }
            : undefined;
    }, [editingEnabledInWidget, isInTableView, isInGalleryView]);

    // when the value is empty and the field is read only, override the input with textual input with value "-"
    const valueIsEmpty = value === undefined || value === null || (value as string).length === 0;
    const shouldPresentEmptyValueWithDash =
        valueIsEmpty &&
        (finalReadOnly ||
            (!initiative &&
                (definitionType === SpecialFieldsType.NEXT_GATHER_UPDATE_SELECTOR ||
                    definitionType === SpecialFieldsType.CHANNEL_SELECTOR)));
    if (shouldPresentEmptyValueWithDash) {
        valueComponent = (
            <InputReveal
                data-automation="update-field-string"
                as={Input}
                value="-"
                type="text"
                $revealByDefault={false}
                size={InputSize.LARGE}
                readOnly
                {...props}
            />
        );
    } else {
        switch (definitionType) {
            case SpecialFieldsType.PERSON_SELECTOR: {
                valueComponent = (
                    <InputRevealContainer
                        borderedSelector=".tags"
                        selectorsToHide={['.remove-person-link']}
                        readOnly={finalReadOnly}
                        $revealByDefault={revealByDefault}
                        data-automation="update-field-person"
                    >
                        <SelectorWrapper
                            $fieldLocation={dropdownFieldLocation}
                            $isInPopover={isInPopover}
                            $dropdownVisible={dropdownVisible}
                            onClick={updateDropdownFieldLocation}
                            ref={selectorRef}
                        >
                            <PeopleSelectorByPersonIdentifier
                                selectedEmailsOrIds={value as string | undefined}
                                onChange={onChange}
                                isDisabled={finalReadOnly}
                                isMulti={false}
                                styles={dropDownStyling}
                                bold={isEmphasizeText}
                                isInPopover={isInPopover}
                                includeExternal
                            />
                        </SelectorWrapper>
                    </InputRevealContainer>
                );
                break;
            }
            case SpecialFieldsType.MULTI_PERSON_SELECTOR: {
                valueComponent = (
                    <InputRevealContainer
                        borderedSelector=".tags"
                        selectorsToHide={['.remove-person-link']}
                        readOnly={finalReadOnly}
                        $revealByDefault={revealByDefault}
                        data-automation="update-field-multi-person"
                    >
                        <SelectorWrapper
                            $fieldLocation={dropdownFieldLocation}
                            $dropdownVisible={dropdownVisible}
                            $isInPopover={isInPopover}
                            onClick={updateDropdownFieldLocation}
                            ref={selectorRef}
                        >
                            <PeopleSelectorByPersonIdentifier
                                selectedEmailsOrIds={value as string | undefined}
                                onChange={onChange}
                                isDisabled={finalReadOnly}
                                bold={isEmphasizeText}
                                isInPopover={isInPopover}
                                isMulti
                                includeExternal
                            />
                        </SelectorWrapper>
                    </InputRevealContainer>
                );
                break;
            }
            case SpecialFieldsType.STATUS_PICKER: {
                valueComponent = (
                    <InputReveal
                        as={StatusSelect}
                        borderedSelector="[class$='-control']"
                        selectorsToHide={['[class$="-indicatorContainer"]']}
                        value={value as string}
                        onChange={(newValue) => onChange(newValue)}
                        workflowVersionId={fieldDefinition.workflowVersionId ?? workflowVersion?.id}
                        placeholder={finalReadOnly ? 'None selected' : placeholderText || undefined}
                        readOnly={finalReadOnly}
                        isDisabled={finalReadOnly}
                        $revealByDefault={revealByDefault}
                        bold={isEmphasizeText}
                        presentationMode={
                            !editingEnabledInWidget ? StaticStatusSelectPresentationMode.COLORED_TEXT : undefined
                        }
                        styles={dropDownStyling}
                    />
                );
                break;
            }
            case SpecialFieldsType.TAGS_PICKER: {
                valueComponent = (
                    <InputReveal
                        as={TonkeanItemTagsSelector}
                        borderedSelector="[class$=-control]"
                        $borderedElementCssOverride={{ backgroundColor: 'transparent' }}
                        selectorsToHide={[
                            '[class$=-indicatorContainer]',
                            '.tonkean-tags-selector__multi-value__remove',
                        ]}
                        projectId={projectId ?? initiative?.project?.id}
                        value={value as string}
                        isDisabled={finalReadOnly}
                        onChange={(newValue) => {
                            const stringedTags = newValue.map((tag) => tag.name).join(',');
                            onChange(stringedTags);
                        }}
                        bold={isEmphasizeText}
                        styles={dropDownStyling}
                        $revealByDefault={revealByDefault}
                    />
                );
                break;
            }
            case SpecialFieldsType.NEXT_GATHER_UPDATE_SELECTOR: {
                if (!initiative) {
                    throw new Error(
                        'You muse pass an initiative to UpdateField when the field definition is of type NEXT_GATHER_UPDATE_SELECTOR',
                    );
                }

                valueComponent = (
                    <NextGatherUpdateSelector
                        initiative={initiative}
                        onSetReminder={onChange}
                        value={Number(value)}
                        viewOnly={Boolean(finalReadOnly)}
                        forceCloseDropdown={!dropdownVisible}
                        bold={isEmphasizeText}
                    />
                );
                break;
            }
            case SpecialFieldsType.CHANNEL_SELECTOR: {
                if (!initiative) {
                    throw new Error(
                        'You muse pass an initiative to UpdateField when the field definition is of type CHANNEL_SELECTOR',
                    );
                }

                valueComponent = (
                    <TnkNotificationSettingsInputRevealWrapper
                        borderedSelector=".ui-select-toggle"
                        selectorsToHide={['.channel-selector-refresh-button', '.caret']}
                        $revealByDefault={revealByDefault}
                    >
                        <SelectorWrapper
                            $fieldLocation={dropdownFieldLocation}
                            $dropdownVisible={dropdownVisible}
                            $isInPopover={isInPopover}
                            onClick={updateDropdownFieldLocation}
                            ref={selectorRef}
                        >
                            <TnkNotificationSettings
                                onChannelChanged={(newValue) => onChange(newValue.channelId)}
                                overwriteId={initiative.id}
                                overwriteSettings={value}
                                viewOnly={Boolean(finalReadOnly)}
                                controlledSave
                                explanationInTooltip
                                hideTagsBackgroundWhenDisabled
                            />
                        </SelectorWrapper>
                    </TnkNotificationSettingsInputRevealWrapper>
                );

                break;
            }
            case FieldType.String: {
                valueComponent = (
                    <UpdateFieldTextOrURL
                        value={value?.toString()}
                        isInTableView={isInTableView}
                        dataAutomation="update-field-string"
                        readOnly={finalReadOnly && isUrlValid(value?.toString())}
                        onChange={onChange}
                        saveWhileTyping={saveWhileTyping}
                        setEditing={setEditing}
                        finalReadOnly={finalReadOnly}
                        revealByDefault={revealByDefault}
                        placeholderText={placeholderText}
                        onEnterDown={onEnterDown}
                        {...props}
                    />
                );
                break;
            }
            case FieldType.URL: {
                valueComponent = (
                    <UpdateFieldTextOrURL
                        urlLabel={fieldDefinition.urlLabel}
                        value={value?.toString()}
                        isInTableView={isInTableView}
                        dataAutomation="update-field-url"
                        readOnly={finalReadOnly}
                        onChange={onChange}
                        saveWhileTyping={saveWhileTyping}
                        setEditing={setEditing}
                        finalReadOnly={finalReadOnly}
                        revealByDefault={revealByDefault}
                        placeholderText={placeholderText}
                        onEnterDown={onEnterDown}
                        {...props}
                    />
                );
                break;
            }
            case FieldType.Number: {
                valueComponent = (
                    <InputReveal
                        data-automation="update-field-number"
                        as={Input}
                        value={showFormattedValue ? formattedValue : value?.toString()}
                        onChange={(e) => {
                            const newValue: string = e.target.value.toString();
                            // We parseFloat because the event value is a string, but the saved value is a number.
                            // So they would not be the same when comparing for "if we should save" check
                            const floatNumber = Number.parseFloat(e.target.value);

                            // If the user want to type "4.01" when he reaches to "4.0" (last number is 0) if we parse to float "4.0" -> 4, and he can never type "4.01" because parse remove redundant 0.
                            const isLastNumberIsZero: RegExp = new RegExp(/^-?\d*\.\d*0$/);

                            const savedValue = isLastNumberIsZero.test(newValue) ? newValue : floatNumber;
                            onChange(Number.isNaN(savedValue) ? null : savedValue, true);
                        }}
                        onBlur={(e) => {
                            if (saveWhileTyping) {
                                setEditing(false);
                            }
                            const newValue = Number.parseFloat(e.target.value);
                            onChange(Number.isNaN(newValue) ? null : newValue);
                            setIsNumberInputFocused(false);
                        }}
                        onFocus={() => {
                            if (saveWhileTyping) {
                                setEditing(true);
                            }
                            setIsNumberInputFocused(true);
                        }}
                        onMouseEnter={() => setIsNumberInputHovered(true)}
                        onMouseLeave={() => setIsNumberInputHovered(false)}
                        readOnly={finalReadOnly}
                        disabled={finalReadOnly}
                        placeholder={placeholderText || 'Enter a number'}
                        $revealByDefault={revealByDefault}
                        size={InputSize.LARGE}
                        {...(showFormattedValue
                            ? {}
                            : {
                                  type: 'number',
                                  inputMode: 'decimal',
                              })}
                        onKeyDown={(e) => {
                            if (onEnterDown && e.key === 'Enter') {
                                onChange(e.currentTarget.value);
                                onEnterDown(e);
                            }
                        }}
                        step="any"
                        {...props}
                    />
                );
                break;
            }
            case FieldType.Boolean: {
                valueComponent = (
                    <InputReveal
                        as={Checkbox}
                        $borderedElementCssOverride={{ marginLeft: 0 }}
                        checked={Boolean(value)}
                        onChange={(e) => onChange(e.target.value)}
                        disabled={finalReadOnly}
                        $revealByDefault={revealByDefault}
                        {...props}
                    />
                );
                break;
            }
            case FieldType.Date: {
                /**
                 * The value can have a few forms:
                 * 1. A string representing epoch time ("1232345345")
                 * 2. A pretty string of the date ("Sat Jul 09 2022 03:00:00 GMT+0300 (Israel Daylight Time)")
                 * 3. A number representing epoch time ("12345678")
                 *
                 * For the first and second cases, we cant the string to a number. If we get NaN we use the string as-is.
                 * If we get a real number we use the number value to create the Date object.
                 * If the value is a number we use it.
                 */
                let dateValue: Date | undefined = value as any;
                if (typeof value === 'string') {
                    try {
                        const numberValue = Number(value);
                        if (Number.isNaN(numberValue)) {
                            throw new TypeError('value cannot be parsed as number');
                        }
                        dateValue = new Date(numberValue);
                    } catch {
                        dateValue = new Date(value);
                    }
                } else if (typeof value === 'number') {
                    dateValue = new Date(value);
                }

                if (dateValue && dateValue.toString() === 'Invalid Date') {
                    valueComponent = (
                        <FieldError
                            fieldErrorText={`Unsupported date format - ${value?.toString()}`}
                            tooltipText="Date can not be displayed in a date picker since the format is unsupported."
                        />
                    );
                } else {
                    valueComponent = (
                        <InputReveal
                            dataAutomation="update-field-date"
                            as={DatepickerInput}
                            borderedSelector={['.react-datepicker__input-container>div', 'input']}
                            outlinedSelector={['input', '.react-datepicker__input-container>div']}
                            boxShadowSelector=".react-datepicker__input-container>div"
                            selectorsToHide={['button']}
                            selected={dateValue}
                            onChange={(newValue) => onChange(newValue)}
                            dateFormat={dateFieldDisplayFormatDetails?.format}
                            showTimeSelect={dateFieldDisplayFormatDetails?.includeTime}
                            showTimeSelectOnly={!dateFieldDisplayFormatDetails?.includeDate}
                            readOnly={finalReadOnly}
                            disabled={finalReadOnly}
                            $revealByDefault={revealByDefault}
                            placeholderText={placeholderText || 'Select a date'}
                            size={InputSize.LARGE}
                            {...props}
                        />
                    );
                }
                break;
            }
            case FieldType.List: {
                valueComponent =
                    fieldDefinition.dropdownSource === 'SEARCH' && initiative ? (
                        <UpdateFieldAsyncSearchDropdownSelectorWrapper
                            fieldDefinitionId={fieldDefinition.id}
                            initiativeId={initiative.id}
                            onChange={onChange}
                            value={!Array.isArray(value) ? (value as string) : undefined}
                            isMultiValueList={isMultiValueList}
                            multiValueList={isMultiValueList ? multiValueList : undefined}
                            readOnly={finalReadOnly}
                            isInBuilder={isInBuilder}
                            placeholderText={placeholderText}
                            isCreatable={fieldDefinition.allowAddDropdownOptions}
                            workflowVersionType={workflowVersion?.workflowVersionType}
                            styles={dropDownStyling}
                            {...props}
                        />
                    ) : (
                        <InputReveal
                            as={SimpleSelect}
                            borderedSelector="[class$=-control]"
                            selectorsToHide={['[class$=-indicatorContainer]']}
                            value={isMultiValueList ? multiValueList : value}
                            options={dropdownFieldOptions}
                            onChange={(newValue) => onChange(newValue)}
                            readOnly={finalReadOnly}
                            isDisabled={false}
                            displayAsList={updateListFieldProps?.displayAsList}
                            isMulti={fieldDefinition?.isMultiValueField}
                            multiMaximumToSelect={updateListFieldProps?.maximumToSelect}
                            $revealByDefault={revealByDefault}
                            placeholder={placeholderText || 'Select an option'}
                            isCreatable={fieldDefinition?.allowAddDropdownOptions}
                            listSelectionColor={projectManager?.project?.themeConfiguration?.primaryColor}
                            bold={isEmphasizeText}
                            styles={dropDownStyling}
                            isClearable
                            isSearchable
                            showValueWhenNotInOptions
                            {...props}
                        />
                    );
                break;
            }
            case FieldType.LongString: {
                valueComponent =
                    finalReadOnly && isUrlValid(value?.toString()) ? (
                        <UpdateFieldLinkText value={value?.toString()} isInTableView={isInTableView} />
                    ) : (
                        <InputReveal
                            data-automation="update-field-long-string"
                            as={EmphasizedTextArea}
                            minRows={viewOnly ? 1 : 3}
                            maxRows={isInTableView ? undefined : 6}
                            value={value?.toString()}
                            onChange={(e) => onChange(e.target.value, true)}
                            onFocus={() => saveWhileTyping && setEditing(true)}
                            onBlur={(e) => {
                                if (saveWhileTyping) {
                                    setEditing(false);
                                }
                                return onChange(e.target.value);
                            }}
                            readOnly={finalReadOnly}
                            disabled={finalReadOnly}
                            $revealByDefault={revealByDefault}
                            placeholder={
                                placeholderText ||
                                (fieldDefinition.id === 'TNK_UPDATE_TEXT' ? 'Provide update text' : 'Enter text')
                            }
                            size={InputSize.LARGE}
                            $bold={isEmphasizeText}
                            {...props}
                        />
                    );
                break;
            }

            default:
                throw new Error(`No component found for type: ${definitionType as any}`);
        }
    }

    return (
        <Tooltip disabled={!tooltipEnabled} content={value?.toString()}>
            <UpdateFieldWrapper
                rangeStyle={rangeStyle}
                hasError={hasError}
                editingEnabledInWidget={editingEnabledInWidget}
                isInGalleryView={isInGalleryView}
                isInTableView={isInTableView}
            >
                {valueComponent}
            </UpdateFieldWrapper>
        </Tooltip>
    );
};

export default UpdateField;
