import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useEffect } from 'react';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { PercentageFilter } from '@tonkean/shared-services';
import { NumberExFilter } from '@tonkean/shared-services';

interface Props {
    realTrack: any;
    expandTrackToggle(): void;
    goToTrack(): void;
    collectItemsMode?: boolean;
}

const TrackStatus: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
    { realTrack, expandTrackToggle, goToTrack, collectItemsMode },
    ref,
) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const [{ data: draftVersion }, getDraftVersion] = useLazyAsyncMethod(workflowVersionManager, 'getDraftVersion');
    const [{ data: publishedVersion }, getPublishedVersion] = useLazyAsyncMethod(
        workflowVersionManager,
        'getPublishedVersion',
    );

    useEffect(() => {
        if (realTrack.isDraftInitiative) {
            getDraftVersion(realTrack.group.id);
        } else {
            getPublishedVersion(realTrack.group.id);
        }
    }, [getDraftVersion, getPublishedVersion, realTrack.group.id, realTrack.isDraftInitiative]);

    const [status, trackColor, innerItemWarn, progress, stateText] = useAngularWatch(
        () => realTrack.status,
        () => realTrack.stateColor,
        () => realTrack.innerItemWarn,
        () => realTrack.metadata.progress,
        () => realTrack.stateText,
    );

    const trackVersion = realTrack.isDraftInitiative ? draftVersion : publishedVersion;
    const color = trackVersion?.['stateLabelToStateMap']?.[realTrack.stateText]?.color ?? trackColor;

    const percentage = PercentageFilter(NumberExFilter())(progress, 0);
    const hasProgress = progress > 0;
    const statusIsFuture = status === 'FUTURE';
    const visibleOnHover = statusIsFuture && !innerItemWarn;

    return (
        <div
            className={`flex-vmiddle dropdown-toggle tracks-col-status-text track-item-func track-padding-right-no-xs common-size-xxxxs text-left ${
                visibleOnHover && !hasProgress ? 'visible-on-hover' : ''
            }`}
            data-automation="track-status-option-status-list"
            style={!statusIsFuture && color ? { color } : {}}
        >
            <span
                className="flex-vmiddle flex-grow relative"
                data-automation="track-status-inner-item-status"
                ref={ref}
            >
                {/* Status text */}
                <span
                    style={{ opacity: statusIsFuture ? '0.3' : '1' }}
                    className={`${visibleOnHover ? 'visible-on-hover' : ''} 
                    ${collectItemsMode ? 'mod-force-visible' : ''} flex-grow common-ellipsis`}
                >
                    {!statusIsFuture ? stateText : 'Add status'}
                    <span className="dropdown-chevron mod-small mod-static visible-on-hover" />
                </span>

                {/* Progress text */}
                {hasProgress && (
                    <span
                        className="common-color-light-grey2 common-size-xxxxs flex-no-shrink hidden-xs hidden-sm margin-left-xs"
                        onClick={expandTrackToggle}
                        onDoubleClick={goToTrack}
                    >
                        {percentage}
                    </span>
                )}

                {/* Status line and progress */}
                {!statusIsFuture && (
                    <>
                        <span
                            className={hasProgress ? 'tracks-col-status-line mod-percent' : 'tracks-col-status-line'}
                            style={{
                                backgroundColor: color,
                                width: hasProgress ? `${progress * 100}%` : '100%',
                            }}
                        />
                        {hasProgress && (
                            <span
                                className="tracks-col-status-line-percent"
                                style={{
                                    backgroundColor: color,
                                    width: hasProgress ? `${(1 - progress) * 100}%` : '0',
                                }}
                            />
                        )}
                    </>
                )}
            </span>
        </div>
    );
};

export default React.forwardRef(TrackStatus);
