import React from 'react';
import styled from 'styled-components';

import { ReactComponent as EmptyFieldsIcon } from '../../../../../../images/icons/history/empty-fields.svg';
import { ReactComponent as OffIconSvg } from '../../../../../../images/icons/history/off.svg';

import { EmptyStateFilters, StateMessage } from '@tonkean/infrastructure';

const OffIconContainer = styled.div`
    display: inline-flex;
    align-items: center;
    margin-left: 14px;

    .tnk-icon {
        display: inline-flex;

        svg {
            width: 30px;
            height: 16px;
        }
    }
`;

interface Props {
    isActive: boolean;
    isFiltering: boolean;
    clearFilters(): void;
}

const WorkerRunsEmptySwitch: React.FC<Props> = ({ isFiltering, isActive, clearFilters }) => {
    if (isFiltering) {
        return (
            <EmptyStateFilters
                icon={
                    <span className="tnk-icon">
                        <EmptyFieldsIcon />
                    </span>
                }
                title="No events to show"
                clearFilters={clearFilters}
                messageDataAutomation="worker-runs-empty-switch-changing-filters"
                clearFiltersDataAutomation="worker-runs-empty-switch-clear-filters-button"
            />
        );
    }

    if (isActive) {
        return (
            <StateMessage
                icon={
                    <span className="tnk-icon">
                        <EmptyFieldsIcon />
                    </span>
                }
            >
                No Events To Show
            </StateMessage>
        );
    }

    return (
        <StateMessage
            icon={
                <span className="tnk-icon">
                    <EmptyFieldsIcon />
                </span>
            }
            title={
                <>
                    <div className="flex mod-center">
                        <span>Environment is turned OFF</span>
                        <OffIconContainer>
                            <span className="tnk-icon">
                                <OffIconSvg />
                            </span>
                        </OffIconContainer>
                    </div>
                </>
            }
        >
            <div>This environment might not display events since it’s turned off!</div>
            <div>Go to the Module Editor and turn on the module.</div>
        </StateMessage>
    );
};

export default WorkerRunsEmptySwitch;
