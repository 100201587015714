import React from 'react';
import styled from 'styled-components';

import { HTMLEditor } from '@tonkean/editor';
import { H3, Paragraph } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const EmptyState = styled.div`
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    position: absolute;
    z-index: 1;
    background-color: transparent;
    border: none;

    ${H3} {
        color: ${Theme.colors.gray_700};
    }

    ${Paragraph} {
        color: ${Theme.colors.gray_500};
    }
`;

const DisabledHTMLEditor = styled(HTMLEditor)`
    opacity: 0.5;
    background-color: ${Theme.colors.gray_200};
    height: 130px;
`;

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 4px;
    justify-content: start;

    padding: 12px 16px;
    background: ${Theme.colors.gray_200};
    border-top: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
`;

const EditorWrapper = styled.div`
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
`;

interface EmptyStateText {
    title: string;
    subText: string;
}
export const noItemsText: EmptyStateText = {
    title: 'No items in module',
    subText: 'To be able to store the html content create at least one item',
};
export const noFieldSelectedText: EmptyStateText = {
    title: 'No field defined for storage',
    subText: 'Select the field to store on the configuration panel',
};

const ErrorText = ({ emptyStateText }) => (
    <>
        <H3 $bold>{emptyStateText.title}</H3>
        <Paragraph>{emptyStateText.subText}</Paragraph>
    </>
);

interface Props {
    isFieldSelected: boolean;
}
const RichTextEditorInputEmptyState: React.FC<Props> = ({ isFieldSelected }) => {
    return (
        <>
            <EditorWrapper>
                <DisabledHTMLEditor dataAutomation="html-editor-empty-state" isHideToolbar={false} isReadOnly />
                <ButtonsWrapper>
                    <Button size={ButtonSize.SMALL} disabled>
                        Dismiss
                    </Button>
                    <Button size={ButtonSize.SMALL} disabled>
                        Save
                    </Button>
                </ButtonsWrapper>
            </EditorWrapper>
            <EmptyState data-automation="no-item-empty-state">
                {isFieldSelected ? (
                    <ErrorText emptyStateText={noItemsText} />
                ) : (
                    <ErrorText emptyStateText={noFieldSelectedText} />
                )}
            </EmptyState>
        </>
    );
};

export default RichTextEditorInputEmptyState;
