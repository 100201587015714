import React, { useCallback } from 'react';
import styled from 'styled-components';

import DatePickerCustomButton from './DatePickerCustomButton';

import { DatepickerInput } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FieldDefinitionDateFormatToDisplayMapper } from '@tonkean/tonkean-entities';

const DEFAULT_DISPLAY_FORMAT = 'DEFAULT_DATE';
interface Props {
    className?: string;
    displayFormat?: string;
    onChange(
        fieldDefinition: TonkeanId<TonkeanType.FIELD_DEFINITION>,
        date: Date | [Date | null, Date | null] | /* for selectsRange */ null,
        isDate?: boolean,
        event?: React.SyntheticEvent<any> | undefined,
    ): void;
    selected?: Date | null | undefined;
    isLoading: boolean;
    disabled?: boolean;
    fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>;
    minDate?: Date | null | undefined;
}

const Wrapper = styled.div`
    .date-picker-button {
        width: 35px;
    }
`;

const TnkFormDatePickerInput: React.FC<Props> = ({
    className,
    displayFormat,
    onChange,
    selected,
    isLoading,
    disabled,
    fieldDefinitionId,
    minDate,
}) => {
    const fieldDisplayDetails =
        FieldDefinitionDateFormatToDisplayMapper[displayFormat || DEFAULT_DISPLAY_FORMAT] ||
        FieldDefinitionDateFormatToDisplayMapper[DEFAULT_DISPLAY_FORMAT];

    const OnDateChange = useCallback(
        (value) => {
            onChange(fieldDefinitionId, value, true);
        },
        [fieldDefinitionId, onChange],
    );

    return (
        <Wrapper>
            <DatepickerInput
                className={className}
                selected={selected}
                onChange={(value) => OnDateChange(value)}
                startOpen={false}
                dateFormat={fieldDisplayDetails?.format}
                timeFormat={fieldDisplayDetails?.timeFormat}
                showTimeSelect={fieldDisplayDetails?.includeTime}
                showTimeSelectOnly={!fieldDisplayDetails?.includeDate}
                customButton={<DatePickerCustomButton isLoading={isLoading} />}
                disabled={disabled}
                minDate={minDate}
                todayButton={fieldDisplayDetails?.includeTime ? '' : 'today'}
                usePortal={false}
            />
        </Wrapper>
    );
};

export default TnkFormDatePickerInput;
