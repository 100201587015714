import type { FormikProps } from 'formik/dist/types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ReactComponent as ErrorIcon } from '../../../../../../images/icons/error-or-fail.svg';

import { Field, Input, Radio, RadioGroup } from '@tonkean/infrastructure';
import type { IpRangeValue, WebhookAccessControlDefinition } from '@tonkean/tonkean-entities';
import { IpRestrictionType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const InputsWrapper = styled.div`
    width: 100%;
    display: inline-flex;
`;

const ToIpText = styled.div`
    color: ${Theme.colors.gray_500};
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    margin: auto 8px;
`;

const Divider = styled.hr`
    width: 100%;
    margin: 6px 0 0 0;
`;

const RadioGroupWrapper = styled.div`
    margin-bottom: 10px;
    display: inline-flex;
    width: 100%;
`;

const StyledRemoveIpRangeIconButton = styled(IconButton)`
    transform: translate(5px, 0px);
    height: 18px;
    width: 18px;
    margin-left: auto;
`;

const IpRestrictionSingleRule = styled.div`
    padding: 16px;
`;

const AddIpRestrictionButton = styled(ClickableLink)`
    margin-top: 20px;
`;

interface Props {
    ipRestrictionsIndex: number;
    form: FormikProps<any>;
    removeIpGroup: (index: number) => void;
    pushNewIp: (obj: IpRangeValue) => void;
}

const IpAddressRestrictionRangeValueView: React.FC<Props> = ({
    ipRestrictionsIndex,
    removeIpGroup,
    form,
    pushNewIp,
}) => {
    const { accessControl } = form.values;

    const isAddIpRangeDisabled = useCallback(
        (webhookAccessControlDefinition: WebhookAccessControlDefinition, ipRestrictionsIndex: number) => {
            const currentIpRangeValues =
                webhookAccessControlDefinition.ipRestrictionGroups.ipRestrictions[ipRestrictionsIndex]?.ipRangeValues;
            const lasIpRangeValue = currentIpRangeValues?.[currentIpRangeValues.length - 1];
            return lasIpRangeValue && lasIpRangeValue.fromIp === '' && lasIpRangeValue.toIp === '';
        },
        [],
    );

    return (
        <>
            {accessControl.ipRestrictionGroups.ipRestrictions[ipRestrictionsIndex].ipRangeValues.map(
                (ipRangeValue, ipRangeIndex) => (
                    <React.Fragment key={`ip ${ipRangeIndex}`}>
                        {ipRangeIndex !== 0 && <Divider />}

                        <IpRestrictionSingleRule>
                            <RadioGroupWrapper>
                                <Field>
                                    <RadioGroup
                                        size={InputSize.MEDIUM}
                                        direction="row"
                                        name={`accessControl.ipRestrictionGroups.ipRestrictions.${ipRestrictionsIndex}.ipRangeValues.${ipRangeIndex}.ipRestrictionType`}
                                    >
                                        <Radio value={IpRestrictionType.SINGLE_IP}>Specific IP</Radio>
                                        <Radio value={IpRestrictionType.IP_RANGE}>IP Range</Radio>
                                    </RadioGroup>
                                </Field>

                                {ipRangeIndex !== 0 && (
                                    <StyledRemoveIpRangeIconButton onClick={() => removeIpGroup(ipRangeIndex)} flat>
                                        <ErrorIcon />
                                    </StyledRemoveIpRangeIconButton>
                                )}
                            </RadioGroupWrapper>

                            <InputsWrapper>
                                <Field>
                                    <Input
                                        placeholder="Insert IP Address"
                                        name={`accessControl.ipRestrictionGroups.ipRestrictions.${ipRestrictionsIndex}.ipRangeValues.${ipRangeIndex}.fromIp`}
                                    />
                                </Field>

                                {accessControl.ipRestrictionGroups.ipRestrictions[ipRestrictionsIndex].ipRangeValues[
                                    ipRangeIndex
                                ].ipRestrictionType === IpRestrictionType.IP_RANGE && (
                                    <>
                                        <ToIpText>To</ToIpText>

                                        <Field>
                                            <Input
                                                placeholder="Insert IP Address"
                                                name={`accessControl.ipRestrictionGroups.ipRestrictions.${ipRestrictionsIndex}.ipRangeValues.${ipRangeIndex}.toIp`}
                                            />
                                        </Field>
                                    </>
                                )}
                            </InputsWrapper>

                            {accessControl.ipRestrictionGroups.ipRestrictions[ipRestrictionsIndex].ipRangeValues
                                .length -
                                1 ===
                                ipRangeIndex && (
                                <AddIpRestrictionButton
                                    disabled={isAddIpRangeDisabled(accessControl, ipRestrictionsIndex)}
                                    onClick={() =>
                                        pushNewIp({
                                            ipRestrictionType: IpRestrictionType.SINGLE_IP,
                                            fromIp: '',
                                            toIp: '',
                                        })
                                    }
                                >
                                    + Add IP/IP Range
                                </AddIpRestrictionButton>
                            )}
                        </IpRestrictionSingleRule>
                    </React.Fragment>
                ),
            )}
        </>
    );
};

export default IpAddressRestrictionRangeValueView;
