<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>

<form novalidate>
    <div ng-form="data.integrationForm">
        <div class="modal-body">
            <!-- Saving to... -->
            <div class="text-center" ng-if="data.saving">
                <h4>
                    <span class="loading"></span>
                    Saving...
                </h4>
            </div>

            <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
            <div ng-hide="connecting || integration || data.saving" class="text-center">
                <button
                    type="button"
                    class="btn btn-secondary btn-lg"
                    analytics-on
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Authenticate"
                    analytics-label="{{ currentIntegration.name }}"
                    ng-click="authorize()"
                    ng-if="!data.isUsingGCPServiceAccount"
                >
                    Authenticate with {{ currentIntegration.displayName }}
                </button>

                <div ng-if="data.isUsingGCPServiceAccount">
                    <tnk-google-internal-gcp-service-account
                        authenticate="authorize"
                    ></tnk-google-internal-gcp-service-account>
                </div>

                <div class="margin-normal-sm-v common-subtitle-inner"></div>
            </div>
            <div class="text-center" ng-show="connecting">
                <h4>
                    <span class="loading"></span>
                    Connecting to {{ currentIntegration.displayName }}
                </h4>
            </div>
            <div ng-show="integration && !connecting && !data.saving">
                <div class="alert alert-info">
                    <a
                        class="pull-right"
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Change User
                    </a>

                    <div class="integration-group-header-connected" ng-if="integration">
                        Authenticated
                        <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                            as
                            <span class="integration-group-header-connected-username">
                                {{ integration.integrationUserDisplayName || integration.integrationUser }}
                            </span>
                        </span>
                    </div>
                </div>
                <hr />
                <div ng-if="calendars && calendars.length">
                    <div class="common-bold">Select the Google Calendar:</div>
                    <div class="flex-vmiddle margin-normal-sm-v">
                        <div class="flex-grow margin-right">
                            <ui-select
                                theme="bootstrap"
                                ng-model="temp.calendar"
                                ng-disabled="validatingRepo || !calendars"
                            >
                                <ui-select-match placeholder="Select calendar">
                                    {{ selectedCalendar.displayName }}
                                </ui-select-match>
                                <ui-select-choices repeat="calendar in calendars | filter:$select.search">
                                    <div
                                        ng-bind-html="calendar.displayName | escapeHtml | highlight: $select.search"
                                    ></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div
                class="common-color-danger pull-left"
                ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid"
            >
                You should select a Google calendar
            </div>
            <button
                class="btn btn-default"
                ng-disabled="data.saving"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ selectedCalendar.displayName }}"
                type="button"
                ng-click="onCancel()"
            >
                Cancel
            </button>
            <button
                class="btn btn-primary"
                ng-disabled="!data.integrationForm.$valid || !selectedCalendar.displayName || data.saving"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ selectedCalendar.displayName }}"
                type="button"
                ng-click="ok()"
            >
                OK
            </button>
        </div>
    </div>
</form>
