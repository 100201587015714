import EmbedWidget from './components/EmbedWidget';
import EmbedWidgetEditor from './components/EmbedWidgetEditor';
import type EmbedWidgetConfiguration from './EmbedWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const EmbedWidgetPackage: ItemInterfaceWidgetPackage<EmbedWidgetConfiguration> = {
    displayComponent: EmbedWidget,
    configurationComponent: EmbedWidgetEditor,
};

export default EmbedWidgetPackage;
