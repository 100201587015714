import { useAngularService } from 'angulareact';
import { useState } from 'react';

import { useFetchManager } from '@tonkean/infrastructure';
import { useModifiableList } from '@tonkean/infrastructure';
import type { PeopleInProject } from '@tonkean/tonkean-entities';

function useProjectPeople() {
    const tonkeanService = useAngularService('tonkeanService');
    const [totalPeople, setTotalPeople] = useState(0);

    const [[getProjectPeople], { data: projectPeopleResponse, loadNextPage, hasMorePages, loading, manuallyReload }] =
        useFetchManager(tonkeanService, 'getBoardPeople', {
            limit: 20,
            checkHasMore: (response: PeopleInProject) => response.total > response.offset + response.length,
            isSingle: false,
            getItems: (response: PeopleInProject) => response.people,
            onLoaded: (response) => setTotalPeople(response.total),
        });
    const [projectPeople, { onCreate, onDelete, onUpdate }] = useModifiableList(projectPeopleResponse);

    return {
        getProjectPeople,
        projectPeople,
        totalPeople,
        personListActions: {
            onPersonCreate: onCreate,
            onPersonDelete: onDelete,
            onPersonUpdate: onUpdate,
        },
        getBoardPeopleResponse: {
            hasMorePeople: hasMorePages,
            peopleLoading: loading,
            reloadProjectPeople: manuallyReload,
            loadMorePeople: loadNextPage,
        },
    };
}

export default useProjectPeople;
