import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import type { FieldsSelectorByWorkflowVersionIdGroupSummary } from './FieldsSelectorByWorkflowVersionIdGroupSummary';
import type { SingleFieldConfiguration } from './SingleFieldConfiguration';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import FieldsWidgetAddField from '../FieldsWidgetModule/components/FieldsWidgetAddField';
import FieldsWidgetConfigFieldRow from '../FieldsWidgetModule/components/FieldsWidgetConfigFieldRow';

import { Message, MessageType, SearchBox, useFormikField, useSearch } from '@tonkean/infrastructure';
import type { FieldDefinition, Initiative, WorkflowVersion, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_OBJECT } from '@tonkean/utils';

const DeletedFieldsMessage = styled(Message)`
    margin-bottom: 16px;
`;

const DeletedFieldsMessageText = styled.div`
    margin-bottom: 8px;
`;

const StyledSearch = styled(SearchBox)`
    margin-bottom: 25px;
`;

const Wrapper = styled.div`
    padding: 0 20px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const FieldsSectionTitle = styled(ConfigurationSectionTitle)`
    margin-bottom: 17px;
`;

const comparer = (field: FieldDefinition, search: string) =>
    field.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());

interface Props {
    allFieldDefinitions: FieldDefinition[];
    deletedFieldsIds: string[];
    workflowVersion: WorkflowVersion | undefined;
    workflowVersionType?: WorkflowVersionType;
    groups: FieldsSelectorByWorkflowVersionIdGroupSummary[] | undefined;
    exampleItem?: Initiative | undefined;
    enableSearch?: boolean;
    fieldConfigurationPath?: string;
    onFieldChanged?: (value: Record<FieldDefinitionKey, SingleFieldConfiguration>) => void;
    configurationTitle?: string;
    maxAmountOfFields?: number;
}

const WidgetConfigurationFirstFieldSelector: React.FC<Props> = ({
    allFieldDefinitions,
    deletedFieldsIds,
    workflowVersion,
    workflowVersionType,
    groups,
    exampleItem,
    configurationTitle,
    enableSearch = true,
    fieldConfigurationPath = 'configuration.fields',
    onFieldChanged,
}) => {
    const { value: fields, setValue: setConfigValue } =
        useFormikField<Record<FieldDefinitionKey, SingleFieldConfiguration>>(fieldConfigurationPath);

    const updateConfigValue = useCallback(
        (configValue: Record<FieldDefinitionKey, SingleFieldConfiguration>) => {
            setConfigValue(configValue);
            if (onFieldChanged) {
                onFieldChanged(configValue);
            }
        },
        [onFieldChanged, setConfigValue],
    );

    const onRemoveDeletedFields = useCallback(() => {
        updateConfigValue({});
    }, [updateConfigValue]);

    const configuredFields: FieldDefinition[] = useMemo(() => {
        return allFieldDefinitions?.filter((fieldDefinition) => fields?.[fieldDefinition?.id]);
    }, [allFieldDefinitions, fields]);

    const {
        searchTerm,
        setSearchTerm,
        debouncedFilteredItems: fieldsToShow,
    } = useSearch<FieldDefinition>(configuredFields, comparer);

    const addFieldToConfiguration = useCallback(
        (field: FieldDefinition) => {
            if (field?.id && fields) {
                const configValue: Record<FieldDefinitionKey, SingleFieldConfiguration> = { ...fields };
                configValue[field?.id] = { isShown: true, fullWidth: false, index: '0', hideColorsAndTrends: true };
                updateConfigValue(configValue);
            }
        },
        [fields, updateConfigValue],
    );

    const isFieldConfiguredForWidget = useMemo(() => {
        return !!(fields && Object.keys(fields).length > 0);
    }, [fields]);

    return (
        <Wrapper>
            <TitleWrapper>
                <FieldsSectionTitle>{configurationTitle || 'Field'}</FieldsSectionTitle>
            </TitleWrapper>
            {enableSearch && (
                <StyledSearch
                    data-automaton="widget-configuration-field-selector-search-input"
                    value={searchTerm}
                    onChange={({ target: { value } }) => setSearchTerm(value)}
                />
            )}
            {!!deletedFieldsIds?.length && (
                <DeletedFieldsMessage type={MessageType.WARNING}>
                    <DeletedFieldsMessageText>
                        The field is no longer available in the associated module.
                    </DeletedFieldsMessageText>
                    <Button
                        data-automation="widget-configuration-field-selector-remove-all-fields"
                        onClick={() => onRemoveDeletedFields()}
                        size={ButtonSize.MEDIUM}
                        warningOutlined
                    >
                        Remove Deleted Field
                    </Button>
                </DeletedFieldsMessage>
            )}
            {fieldsToShow.map((fieldDefinition) => {
                return (
                    <FieldsWidgetConfigFieldRow
                        key={fieldDefinition.id}
                        formikName={`${fieldConfigurationPath}.[${fieldDefinition.id}]`}
                        fieldDefinition={fieldDefinition}
                        exampleItem={exampleItem}
                        workflowVersion={workflowVersion}
                        workflowVersionType={workflowVersionType}
                        setIsFieldShown={() => {}}
                        onRemoveDeletedFields={onRemoveDeletedFields}
                        updateFieldIndex={() => {}}
                        fieldIndex={0}
                        fieldWidthConfig={EMPTY_OBJECT}
                        isShown
                        disabledCheckbox
                        disableChangeOrder
                    />
                );
            })}
            {!isFieldConfiguredForWidget && (
                <FieldsWidgetAddField
                    addFieldToConfiguration={addFieldToConfiguration}
                    groups={groups}
                    fieldDefinitions={allFieldDefinitions}
                    isChecked
                />
            )}
        </Wrapper>
    );
};
export default React.memo(WidgetConfigurationFirstFieldSelector);
