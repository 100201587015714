import { ReactComponent as EndSequence } from '../../../../../images/icons/end-sequence.svg';
import { ReactComponent as GoToSequence } from '../../../../../images/icons/go-to-sequence.svg';
import { ReactComponent as Form } from '../../../../../images/icons/show-form.svg';
import { ReactComponent as Interface } from '../../../../../images/icons/show-interface.svg';

import { ItemDetailsIconType } from '@tonkean/tonkean-entities';

const ItemDetailsIconTypeMapping: Record<
    ItemDetailsIconType,
    React.FunctionComponent<React.SVGAttributes<SVGElement>>
> = {
    [ItemDetailsIconType.INTERFACE]: Interface,
    [ItemDetailsIconType.GO_TO_SEQUENCE]: GoToSequence,
    [ItemDetailsIconType.END_SEQUENCE]: EndSequence,
    [ItemDetailsIconType.FORM]: Form,
};

export default ItemDetailsIconTypeMapping;
