import type { WidgetBase } from './ItemInterfaceWidget';

export const WIDGET_OLD_MAX_DISPLAY_COLUMNS = 2;
export const WIDGET_MAX_DISPLAY_COLUMNS = 12;

export function getWidgetDisplayColumns(widget?: WidgetBase): number {
    if (!widget?.displayColumns) {
        return WIDGET_MAX_DISPLAY_COLUMNS;
    }

    // backwards compatibility - adapt old display columns to new display columns
    if (widget.displayColumns <= WIDGET_OLD_MAX_DISPLAY_COLUMNS) {
        return widget.displayColumns * 6;
    }

    return widget.displayColumns;
}
