<div>
    <!-- Closed Query Summary -->
    <div
        class="close-group query-summary-row summary-row pointer"
        uib-tooltip="{{ data.query.name || 'New Condition Group' }}"
        tooltip-placement="top auto"
        ng-click="setQueryUiOpenState(data.query, true)"
        ng-if="!data.query.uiOpen && !data.forceExpand"
        ng-class="{ 'mod-error': data.errorObject.filters.length }"
    >
        <div class="description">
            <span class="group-icon flex" ng-include="'images/icons/conditions-group.svg'"></span>

            <span class="summary-text common-ellipsis margin-left-6">
                {{ data.query.name || 'New Condition Group' }}
            </span>
        </div>

        <div class="open-indicator">
            <i class="fa fa-chevron-down common-size-xxxxxs margin-left-6"></i>
        </div>
    </div>

    <div class="open-group" ng-if="data.query.uiOpen || data.forceExpand">
        <!-- Group Name -->
        <div class="flex-grow margin-right group-name">
            <div class="overflow-hidden flex flex-grow">
                <!-- Display name non-edit-mode -->
                <div
                    class="pointer display-name-non-edit flex-grow"
                    ng-if="data.groupNameBeingEditId !== data.query.uiId"
                    ng-click="data.onGroupNameEdit(data.query.uiId)"
                    ng-disabled="data.groupNameBeingEditId === null"
                >
                    <span class="group-icon flex" ng-include="'images/icons/conditions-group.svg'"></span>

                    <span class="common-ellipsis margin-left-6">{{ data.query.name || 'New Conditions Group' }}</span>

                    <span class="edit-group-name-icon margin-left-6" ng-include="'images/icons/pencil-new.svg'"></span>
                </div>

                <!-- Display name edit-mode -->
                <div
                    ng-if="data.groupNameBeingEditId === data.query.uiId"
                    class="flex-vmiddle flex-grow display-name-edit"
                >
                    <span class="group-icon flex" ng-include="'images/icons/conditions-group.svg'"></span>

                    <!-- Text input -->
                    <input
                        type="text"
                        class="form-control common-input-only-line margin-left-6"
                        ng-blur="data.onGroupNameEdit(null)"
                        ng-model="data.query.name"
                        ng-change="data.invokeOnFilterChange()"
                    />
                </div>
            </div>

            <div class="pointer" ng-click="setQueryUiOpenState(data.query, false)">
                <i class="fa fa-chevron-up common-size-xxxxxs margin-left-md"></i>
            </div>
        </div>

        <!-- Query type -->
        <div
            class="margin-bottom-lg common-size-xxs flex-vmiddle"
            ng-if="!(data.hideQueryOnSingleCondition && data.query.filters.length === 1)"
        >
            <!-- All/Any -->
            <span
                class="dropbox margin-right-xs flex-no-shrink"
                data-automation="custom-filter-all-or-one-open-button"
                uib-dropdown
            >
                <div
                    class="pointer common-bold dropdown-toggle flex-vmiddle"
                    uib-dropdown-toggle
                    ng-disabled="data.viewOnly"
                >
                    <span>{{ data.query.selectedQueryCondition }}</span>
                    <i class="fa fa-chevron-down common-size-xxxxxs margin-left-6"></i>
                    <i class="fa fa-chevron-up common-size-xxxxxs margin-left-6"></i>
                </div>

                <ul uib-dropdown-menu class="dropdown-menu" role="menu">
                    <li ng-repeat="opt in data.queryTypes" ng-if="opt !== data.query.selectedQueryCondition">
                        <a
                            data-automation="custom-filter-choose-option-condition-one-or-all"
                            ng-click="data.query.selectedQueryCondition = opt; data.invokeOnFilterChange();"
                        >
                            {{ opt }}
                        </a>
                    </li>
                </ul>
            </span>

            <span class="group-condition-text">of these conditions are met:</span>
        </div>

        <!-- Existing filters and adding filters -->
        <div class="query-and-filter">
            <div class="loading-small absolute margin-top" ng-show="data.loadingFields"></div>

            <!-- Inner filters -->
            <div
                class="inner-filter"
                data-automation="custom-filters-inner-query-template-filter-close"
                ng-repeat="filter in data.query.filters"
                ng-if="!filter.queryAllFields"
                ng-class="{ 'in-focus': data.filterInFocusId === filter.uiId }"
            >
                <div
                    class="filter-summary-row summary-row pointer"
                    data-automation="custom-filter-set-new-condition"
                    uib-tooltip="{{ filter.uiDescription }}"
                    tooltip-placement="top auto"
                    ng-click="data.onFilterFocus(filter)"
                    ng-class="{
                        'in-focus': data.filterInFocusId === filter.uiId,
                        'mod-error': data.filtersErrors[$index].length
                    }"
                >
                    <div class="description">
                        <div
                            class="filter-summary-row-indicator"
                            ng-class="{
                        'mod-pass':data.filtersResultMap[filter.id] === true,
                        'mod-fail':data.filtersResultMap[filter.id] === false,
                    }"
                        ></div>

                        <div
                            class="initiative-integration-icon mod-width-14 mod-sm mod-circle mod-{{
                                data.fieldDefinitionMap[
                                    filter.field.name
                                ].projectIntegration.integrationType.toLowerCase()
                            }}"
                            ng-if="data.fieldDefinitionMap[filter.field.name].projectIntegration"
                        ></div>

                        <div
                            class="initiative-integration-icon mod-width-14 mod-sm mod-circle mod-{{
                                data.projectIntegrationDescriptionClass
                            }}"
                            ng-if="data.itemsSource === data.itemsSourceTypes.external"
                        ></div>

                        <div ng-if="data.filtersResultMap[filter.id] !== undefined" class="margin-right-xs">
                            <span ng-if="data.filtersResultMap[filter.id]" class="svg-icon-12">
                                <tnk-icon src="../../../../../../apps/tracks/images/icons/filter-pass.svg"></tnk-icon>
                            </span>
                            <span ng-if="data.filtersResultMap[filter.id] === false" class="svg-icon-12">
                                <tnk-icon src="../../../../../../apps/tracks/images/icons/filter-fail.svg"></tnk-icon>
                            </span>
                        </div>

                        <span class="summary-text common-ellipsis">
                            {{ filter.uiDescription }}
                        </span>
                    </div>

                    <div class="open-indicator">
                        <i
                            class="fa fa-chevron-down common-size-xxxxxs margin-left-6"
                            ng-if="data.filterInFocusId !== filter.uiId"
                        ></i>
                        <i
                            class="fa fa-chevron-up common-size-xxxxxs margin-left-6"
                            ng-if="data.filterInFocusId === filter.uiId"
                        ></i>
                    </div>
                </div>

                <div class="custom-filters-row-container">
                    <div
                        class="flex-vmiddle custom-filters-row"
                        ng-class="{
                            'custom-filter-block': data.smallMod,
                            'in-focus': data.filterInFocusId === filter.uiId || data.forceExpand,
                            'mod-error': data.filtersErrors[$index].length
                        }"
                    >
                        <div class="custom-filters-row-fields-column">
                            <div class="flex flex-xs-wrap flex-wrap filter-selectors">
                                <!-- Field to filter on -->
                                <tnk-field-selector
                                    ng-class="{ 'margin-right-xs': !data.verticalMod }"
                                    class="margin-bottom-xs filter-min-width margin-bottom-xs flex-shrink flex-grow"
                                    data-automation="custom-filter-open-field-drop-down"
                                    selected-field="filter.field"
                                    selected-field-identifier="filter"
                                    items-source="{{ data.itemsSource }}"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.targetWorkflowVersionId || data.workflowVersionId }}"
                                    consider-all-groups="{{ data.considerAllGroups }}"
                                    project-integration="data.projectIntegration"
                                    display-integration-indication="data.displayIntegrationIndication"
                                    external-type="data.selectedEntityType"
                                    inspect-entity-type="data.inspectEntityType"
                                    on-field-selected="
                                        data.onFieldOptionSelected(selectedField, selectedFieldIdentifier)
                                    "
                                    add-custom-fields="data.addCustomFields(fieldOptions, data.projectIntegration, externalType)"
                                    reload-fields="data.reloadFields"
                                    group-by="groupBy"
                                    place-holder="Search data field"
                                    field-inspect-popover="data.showFieldInspect"
                                    field-inspect-modal="data.showFieldInspectModal"
                                    view-only="data.viewOnly"
                                    append-to-body="data.appendToBody"
                                    special-fields-for-features="data.specialFieldsForFeatures"
                                    reload-fields-on-new-field-definition-creation="data.reloadFieldsOnNewFieldDefinitionCreation"
                                    auto-create-column-fields="true"
                                    add-fields-quick-create-options="true"
                                    include-viewer-fields="data.includeViewerFields"
                                ></tnk-field-selector>

                                <!-- Condition -->
                                <tnk-condition-selector
                                    class="flex-grow margin-bottom-xs"
                                    project-integration="data.projectIntegration"
                                    field-type="filter.field.type"
                                    disable-selections="!filter.field || viewOnly"
                                    value="filter.value"
                                    second-value="filter.secondValue"
                                    expression="filter.expression"
                                    group-id="{{ data.groupId }}"
                                    consider-all-groups="{{ data.considerAllGroups }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    items-source="{{ data.itemsSource }}"
                                    field="filter.field"
                                    field-name="filter.field.name"
                                    possible-values="data.fieldDefinitionMap[filter.field.id].possibleValues || filter.field.values"
                                    possible-values-map="filter.field.possibleValuesMap"
                                    condition-api-name="filter.condition"
                                    on-condition-selected="data.onConditionSelection(selectedCondition, param, isInit)"
                                    on-condition-selected-param="(filter)"
                                    on-value-changed="data.onValueChanged(valueObject, param, isInit, doNotSaveChanges)"
                                    on-value-changed-param="(filter)"
                                    on-second-value-changed="
                                        data.onSecondValueChanged(valueObject, param, doNotSaveChanges)
                                    "
                                    on-expression-changed="data.onExpressionChanged(expression, param, shouldSaveLogic)"
                                    reset-condition="filter.resetConditionFlag"
                                    use-expressions-for-value="data.useExpressionsForValue"
                                    tabs-field-selector-mod-narrow="data.tabsFieldSelectorModNarrow"
                                    tabs-field-selector-mod-fixed-width="data.tabsFieldSelectorModFixedWidth"
                                    mod-fixed-width="data.modFixedWidth"
                                    vertical-mod="data.verticalMod"
                                    do-not-evaluate-preview="!data.evaluatePreview"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    allowed-conditions-set="data.allowedConditionsSet"
                                    not-allowed-conditions-set="data.notAllowedConditionsSet"
                                    excluded-tab-selector-special-fields="data.excludedTabSelectorSpecialFields"
                                    include-tab-selector-special-fields-for-features="data.includeTabSelectorSpecialFieldsForFeatures"
                                    allow-contains="data.allowContains"
                                    show-people-directories="data.showPeopleDirectories"
                                ></tnk-condition-selector>
                            </div>

                            <div ng-if="data.filtersErrors[$index].length" class="common-error margin-left-xs">
                                <!-- Because we access the previous ngRepeat (the filters ones) index with $parent -->
                                <div ng-repeat="error in data.filtersErrors[$parent.$index]">{{ error }}</div>
                            </div>

                            <div class="flex-grow"></div>
                            <div class="filter-preview-and-action">
                                <div class="flex padding-right mod-align-center">
                                <span
                                        class="
                                        svg-icon-sm svg-icon-align-text-top
                                        margin-right
                                        common-color-grey
                                        mod-line-height-12
                                    "
                                        uib-popover-html="'<div><a href=\'https://docs.tonkean.com/en/reference/formulas.html#logical-conditions-5274\' target=\'_blank\'>Learn more</a> about the differences between comparison operators.<div>'"
                                        popover-placement="top"
                                        popover-append-to-body="true"
                                        popover-trigger="mouseenter"
                                        popover-popup-close-delay="3000">
                                    <tnk-icon src="../../../../../../apps/tracks/images/icons/info20px.svg"></tnk-icon>
                                </span>
                                </div>
                                <div>
                                    <tnk-example-item-evaluated-expression
                                        class="flex-grow"
                                        ng-if="data.logicId && data.evaluatePreview"
                                        field-id-to-evaluate="filter.field.id || filter.field.name"
                                        group-id="{{ data.groupId }}"
                                        workflow-version-id="{{ data.workflowVersionId }}"
                                        logic-id="{{ data.logicId }}"
                                        expression-unique-identifier="{{ data.uniqueIdentifier }}customFilters{{
                                            $index
                                        }}"
                                        do-not-evaluate-preview="!data.evaluatePreview"
                                        preview-evaluation-source="data.previewEvaluationSource"
                                        hide-preview="!data.evaluatePreview"
                                    ></tnk-example-item-evaluated-expression>
                                </div>

                                <!-- Logic options -->
                                <div class="relative" uib-dropdown>
                                    <!-- Logic options button -->
                                    <div
                                        class="svg-icon-mdl svg-icon-hover-primary pointer"
                                        ng-disabled="viewOnly"
                                        uib-dropdown-toggle
                                    >
                                        <span class="flex-vmiddle">
                                            <span
                                                class="svg-icon-mdl"
                                                data-automation="custom-filter-query-settings-button"
                                            >
                                                <tnk-icon src="../../../../../../apps/tracks/images/icons/ellipsis-h.svg"></tnk-icon>
                                            </span>
                                        </span>
                                    </div>

                                    <!-- Logic options menu -->
                                    <ul
                                        uib-dropdown-menu
                                        class="dropdown-menu dropdown-menu-right logic-menu padding-1"
                                        ng-class="{ 'mod-wide': !data.selectedLogicTypeConfiguration.cantChangeType() }"
                                        role="menu"
                                    >
                                        <!-- Delete -->
                                        <li
                                            class="flex padding-right mod-align-center"
                                            data-automation="custom-filter-delete-conditions"
                                            ng-click="data.removeFieldFilter(data.query.filters, $index)"
                                        >
                                            <span
                                                class="
                                                    svg-icon-sm svg-icon-align-text-top
                                                    margin-right
                                                    common-color-grey
                                                    mod-line-height-12
                                                "
                                            >
                                                <tnk-icon src="../../../../../../apps/tracks/images/icons/delete.svg"></tnk-icon>
                                            </span>
                                            <span>Delete Condition</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="in-focus-stub"></div>

                <div
                    class="connecting-condition-phrase"
                    ng-show="
                        $index !== data.query.filters.length - 1 ||
                        ($index === data.query.filters.length - 1 && data.query.queries && data.query.queries.length)
                    "
                >
                    <span ng-if="data.query.selectedQueryCondition === 'All'">AND</span>
                    <span ng-if="data.query.selectedQueryCondition === 'At least one'">OR</span>
                </div>
            </div>

            <!-- Inner queries -->
            <div
                class="custom-filters-sub-queries"
                ng-if="data.query.queries && data.query.queries.length"
                ng-init="outquery = data.query"
            >
                <div ng-repeat="innerQuery in innerQueries = data.query.queries">
                    <div class="custom-filters-sub-query">
                        <tnk-custom-filters-inner-query
                            query="innerQuery"
                            error-object="data.errorObject.queries[$index]"
                            force-expand="data.forceExpand"
                            group-name-being-edit-id="data.groupNameBeingEditId"
                            on-group-name-edit="(data.onGroupNameEdit)"
                            invoke-on-filter-change="data.invokeOnFilterChange"
                            hide-query-on-single-condition="data.hideQueryOnSingleCondition"
                            query-types="data.queryTypes"
                            loading-fields="data.loadingFields"
                            filter-in-focus-id="data.filterInFocusId"
                            on-filter-focus="(data.onFilterFocus)"
                            filters-result-map="data.filtersResultMap"
                            field-definition-map="data.fieldDefinitionMap"
                            project-integration-description-class="data.projectIntegrationDescriptionClass"
                            items-source="data.itemsSource"
                            small-mod="data.smallMod"
                            group-id="data.groupId"
                            target-workflow-version-id="data.targetWorkflowVersionId"
                            workflow-version-id="data.workflowVersionId"
                            consider-all-groups="data.considerAllGroups"
                            project-integration="data.projectIntegration"
                            display-integration-indication="data.displayIntegrationIndication"
                            selected-entity-type="data.selectedEntityType"
                            inspect-entity-type="data.inspectEntityType"
                            on-field-option-selected="(data.onFieldOptionSelected)"
                            add-custom-fields="data.addCustomFields"
                            reload-fields="data.reloadFields"
                            show-field-inspect="data.showFieldInspect"
                            show-field-inspect-modal="data.showFieldInspectModal"
                            view-only="data.viewOnly"
                            append-to-body="data.appendToBody"
                            special-fields-for-features="data.specialFieldsForFeatures"
                            reload-fields-on-new-field-definition-creation="data.reloadFieldsOnNewFieldDefinitionCreation"
                            on-condition-selection="(data.onConditionSelection)"
                            on-value-changed="(data.onValueChanged)"
                            on-second-value-changed="(data.onSecondValueChanged)"
                            on-expression-changed="(data.onExpressionChanged)"
                            use-expressions-for-value="data.useExpressionsForValue"
                            tabs-field-selector-mod-narrow="data.tabsFieldSelectorModNarrow"
                            tabs-field-selector-mod-fixed-width="data.tabsFieldSelectorModFixedWidth"
                            mod-fixed-width="data.modFixedWidth"
                            vertical-mod="data.verticalMod"
                            preview-evaluation-source="data.previewEvaluationSource"
                            allowed-conditions-set="data.allowedConditionsSet"
                            not-allowed-conditions-set="data.notAllowedConditionsSet"
                            excluded-tab-selector-special-fields="data.excludedTabSelectorSpecialFields"
                            include-tab-selector-special-fields-for-features="data.includeTabSelectorSpecialFieldsForFeatures"
                            unique-identifier="data.uniqueIdentifier"
                            evaluate-preview="data.evaluatePreview"
                            selected-logic-type-configuration="data.selectedLogicTypeConfiguration"
                            remove-field-filter="data.removeFieldFilter"
                            add-new-aggregate-condition="data.addNewAggregateCondition"
                            add-new-aggregate-query="data.addNewAggregateQuery"
                            logic-id="data.logicId"
                            query-index="$index"
                            on-delete-inner-query="(deleteInnerQuery)"
                            show-people-directories="true"
                            include-viewer-fields="data.includeViewerFields"
                        ></tnk-custom-filters-inner-query>
                    </div>

                    <div class="connecting-condition-phrase" ng-if="innerQueries && $index !== innerQueries.length - 1">
                        <span ng-if="outquery.selectedQueryCondition === 'All'">AND</span>
                        <span ng-if="outquery.selectedQueryCondition === 'At least one'">OR</span>
                    </div>
                </div>
            </div>

            <div class="action-buttons">
                <!-- Add button  -->
                <div
                    class="custom-filters-btn"
                    uib-tooltip="Add condition"
                    tooltip-placement="top auto"
                    tooltip-append-to-body="true"
                    uib-dropdown
                >
                    <div
                        class="dropdown-toggle btn btn-no-border"
                        data-automation="custom-filter-and-button-condition"
                        uib-dropdown-toggle
                        ng-disabled="data.viewOnly"
                        ng-class="{ pointer: !data.viewOnly }"
                    >
                        <span ng-if="data.query.selectedQueryCondition === 'All'">+ AND</span>
                        <span ng-if="data.query.selectedQueryCondition === 'At least one'">+ OR</span>
                    </div>

                    <ul class="dropdown-menu-left" uib-dropdown-menu role="menu" aria-labelledby="split-button">
                        <li>
                            <a
                                ng-click="data.addNewAggregateCondition(data.query.filters)"
                                data-automation="custom-filter-add-new-condition"
                            >
                                New Condition
                            </a>
                        </li>

                        <li class="divider"></li>

                        <li>
                            <a ng-click="data.addNewAggregateQuery(data.query)">New Condition Group</a>
                        </li>
                    </ul>
                </div>

                <!-- Logic options -->
                <div ng-if="data.onDeleteInnerQuery" class="relative logic-options" uib-dropdown>
                    <!-- Logic options button -->
                    <div
                        class="svg-icon-mdl svg-icon-hover-primary pointer"
                        ng-disabled="data.viewOnly"
                        uib-dropdown-toggle
                    >
                        <span class="flex-vmiddle">
                            <span class="svg-icon-mdl">
                                <tnk-icon src="../../../../../../apps/tracks/images/icons/ellipsis-h.svg"></tnk-icon>
                            </span>
                        </span>
                    </div>

                    <!-- Logic options menu -->
                    <ul
                        uib-dropdown-menu
                        class="dropdown-menu-right logic-menu padding-1"
                        ng-class="{ 'mod-wide': !data.selectedLogicTypeConfiguration.cantChangeType() }"
                        role="menu"
                    >
                        <!-- Delete -->
                        <li
                            class="flex padding-right mod-align-center"
                            ng-click="data.onDeleteInnerQuery(data.queryIndex)"
                        >
                            <span
                                class="
                                    svg-icon-sm svg-icon-align-text-top
                                    margin-right
                                    common-color-grey
                                    mod-line-height-12
                                "
                            >
                                <tnk-icon src="../../../../../../apps/tracks/images/icons/delete.svg"></tnk-icon>
                            </span>
                            <span>Delete Group</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
