import { useField } from 'formik';
import groupBy from 'lodash.groupby';
import React, { useMemo } from 'react';
import type { GroupBase } from 'react-select';
import styled, { css } from 'styled-components';

import MultiEntitySelectorItemBox from './components/MultiEntitySelectorItemBox';
import MultiEntitySelectorItemRow from './components/MultiEntitySelectorItemRow';
import type { MultiEntitySelectorGetCustomMenuItems } from './MultiEntitySelectorItem';
import MultiEntitySelectorItem from './MultiEntitySelectorItem';
import MultiEntitySelectorLoading from './MultiEntitySelectorLoading';
import type MultiEntitySelectorCustomComponents from './types/MultiEntitySelectorCustomComponents';

import { Checkbox, SimpleErrorStateMessage, SimpleSelect } from '@tonkean/infrastructure';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import type { MultiEntitySelectorEntityOption, MultiEntitySelectorSavedEntity } from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;
`;

const StyledMultiEntitySelectorItemBox = styled(MultiEntitySelectorItemBox)<{ $singleItemMode: boolean }>`
    gap: 5px;
    ${({ $singleItemMode }) =>
        $singleItemMode &&
        css`
            border: none;
        `}
`;

const StyledSimpleSelect = styled(SimpleSelect)<{ $singleItemMode: boolean }>`
    flex-grow: 1;
    margin: 10px 0;
    ${({ $singleItemMode }) =>
        $singleItemMode &&
        css`
            margin: 0;
        `}
`;

interface Props<T extends MultiEntitySelectorSavedEntity> {
    name: string;
    loading: boolean;
    error: ErrorResponseType | undefined;
    /**
     * What is this type of entity called
     */
    entityTypeLabel: string;
    entitiesOptions: MultiEntitySelectorEntityOption<T>[];
    customComponents: MultiEntitySelectorCustomComponents<T>;
    /**
     * Hide any built in actions for editing the label
     */
    hideEditLabel?: boolean;
    /**
     * Hide any built in actions for editing the order of the selected items
     */
    hideEditIndex?: boolean;
    /**
     * Hide any built in actions for editing if the selected options are shown
     */
    hideEditShown?: boolean;
    getCustomMenuItems?: MultiEntitySelectorGetCustomMenuItems<T>;
    /**
     * Turn this selector to a component that can only select a single entity
     */
    singleItemMode?: boolean;
}

const MultiEntitySelector = <T extends MultiEntitySelectorSavedEntity = MultiEntitySelectorSavedEntity>({
    name,
    loading,
    error,
    entityTypeLabel,
    entitiesOptions,
    customComponents,
    hideEditLabel = false,
    hideEditIndex = false,
    hideEditShown = false,
    singleItemMode = false,
    getCustomMenuItems,
}: Props<T>) => {
    const [{ value: savedEntities }, , { setValue: setEntities }] = useField<T[] | undefined>(name);

    const idToEntityOption = useMemo(() => {
        return Object.fromEntries(entitiesOptions.map((option) => [option.savedEntityTemplate.id, option]));
    }, [entitiesOptions]);

    const filteredEntitiesOptions: SimpleSelectSingleOption<MultiEntitySelectorEntityOption<T>>[] = useMemo(() => {
        return entitiesOptions
            .filter(
                (entityOption) =>
                    !savedEntities?.some((savedEntity) => savedEntity.id === entityOption.savedEntityTemplate.id),
            )
            .map((entityOption) => ({
                label: entityOption.savedEntityTemplate.defaultLabel,
                icon: entityOption.icon,
                value: entityOption,
                groupTitle: entityOption.groupTitle,
            }));
    }, [savedEntities, entitiesOptions]);

    const groupedEntitiesOptions = groupBy(filteredEntitiesOptions, 'groupTitle');

    const groupedOptions: GroupBase<SimpleSelectSingleOption<MultiEntitySelectorEntityOption<T>>>[] = Object.keys(
        groupedEntitiesOptions,
    ).map((entityKey): GroupBase<SimpleSelectSingleOption<MultiEntitySelectorEntityOption<T>>> => {
        return {
            label: entityKey,
            options: groupedEntitiesOptions[entityKey] || [],
        };
    });

    const swapIndices = (firstIndex: number, secondIndex: number) => {
        const firstEntity = savedEntities?.[firstIndex];
        const secondEntity = savedEntities?.[secondIndex];
        if (!firstEntity || !secondEntity) {
            console.debug(
                `You are trying to swap indices [${firstIndex}] and [${secondIndex}] but one of them doesnt exist in the entities array`,
            );
            return;
        }

        const swappedArray = savedEntities.map((entity) => {
            switch (entity.id) {
                case firstEntity.id:
                    return secondEntity;
                case secondEntity.id:
                    return firstEntity;
                default:
                    return entity;
            }
        });

        setEntities(swappedArray);
    };

    const removeEntity = (entityIdToRemove: string) => {
        setEntities(savedEntities?.filter((entity) => entity.id !== entityIdToRemove));
    };

    const maxIndex = savedEntities?.length || 0;

    return (
        <Wrapper>
            {loading ? (
                <MultiEntitySelectorLoading entitiesAmount={savedEntities?.length} />
            ) : (
                <>
                    {savedEntities?.map((savedEntity, index) => {
                        const key = savedEntity.id.toString();

                        const entityOption = idToEntityOption[savedEntity.id];
                        if (!entityOption) {
                            // shouldnt happen

                            return undefined;
                        }

                        return (
                            <MultiEntitySelectorItem<T>
                                key={key}
                                name={`${name}.[${index}]`}
                                savedEntity={savedEntity}
                                entityOption={entityOption}
                                entityTypeLabel={entityTypeLabel}
                                customComponents={customComponents}
                                moveUp={() => swapIndices(index, index - 1)}
                                moveUpDisabled={index === 0}
                                moveDown={() => swapIndices(index, index + 1)}
                                moveDownDisabled={index + 1 === maxIndex}
                                removeEntity={removeEntity}
                                hideEditLabel={hideEditLabel}
                                hideEditIndex={hideEditIndex || singleItemMode}
                                hideEditShown={hideEditShown || singleItemMode}
                                getCustomMenuItems={getCustomMenuItems}
                            />
                        );
                    })}
                    {/* Dont show the entity selector if we are in single item mode and we have an entity selected */}
                    {(!singleItemMode || !savedEntities?.length) && (
                        <MultiEntitySelectorItemRow>
                            {!hideEditShown && !singleItemMode && (
                                <Checkbox boldLabel={false} checked highlighted disabled labelFillSpace />
                            )}
                            <StyledMultiEntitySelectorItemBox $singleItemMode={singleItemMode}>
                                <StyledSimpleSelect
                                    options={groupedOptions}
                                    onChange={(selectedValue) => {
                                        if (selectedValue) {
                                            setEntities([
                                                ...(savedEntities || []),
                                                { ...selectedValue.savedEntityTemplate, shown: true },
                                            ]);
                                        }
                                    }}
                                    $singleItemMode={singleItemMode}
                                />
                            </StyledMultiEntitySelectorItemBox>
                        </MultiEntitySelectorItemRow>
                    )}
                </>
            )}

            {error && <SimpleErrorStateMessage error={error} showSmallError />}
        </Wrapper>
    );
};

export default MultiEntitySelector;
