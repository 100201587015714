import React, { useState } from 'react';
import styled from 'styled-components';

import SFTPAuthenticationType from './SFTPAuthenticationType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const CenteredLinkButton = styled.div`
    text-align: center;
`;

const SftpComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    integrationConfiguration,
}) => {
    const [authenticationType, setAuthenticationType] = useState<SFTPAuthenticationType>(
        SFTPAuthenticationType.PASSWORD,
    );
    const [hostUrl, setHostUrl] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [hostPort, setHostPort] = useState<string>('');
    const [privateKey, setPrivateKey] = useState<string>('');
    const [publicKey, setPublicKey] = useState<string>('');
    const [passphrase, setPassphrase] = useState<string>('');

    const authenticateSFTP = (formEvent) => {
        formEvent.preventDefault();
        const config = {
            hostUrl,
            username,
            password,
            hostPort,
            privateKey,
            publicKey,
            passphrase,
            authenticationType,
        };

        authenticate(config, {}, `${integrationConfiguration.displayName} (${hostUrl})`);
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateSFTP}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="host-url" className="col-sm-3 control-label">
                            Host URL
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={hostUrl}
                                className="form-control"
                                id="host-url"
                                onChange={({ target }) => setHostUrl(target.value)}
                                autoComplete="off"
                                placeholder="Host URL"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="username" className="col-sm-3 control-label">
                            Username
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={username}
                                className="form-control"
                                id="username"
                                onChange={({ target }) => setUsername(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="host-port" className="col-sm-3 control-label">
                            Host Port (Optional)
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="number"
                                value={hostPort}
                                className="form-control"
                                id="host-port"
                                onChange={({ target }) => setHostPort(target.value)}
                                autoComplete="off"
                                placeholder="Host Port (Optional)"
                            />
                        </div>
                    </div>

                    {authenticationType === SFTPAuthenticationType.PASSWORD && (
                        <div>
                            <div className="form-group">
                                <label htmlFor="password" className="col-sm-3 control-label">
                                    Password
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        type="password"
                                        value={password}
                                        className="form-control"
                                        onChange={({ target }) => setPassword(target.value)}
                                        id="password"
                                        autoComplete="off"
                                        placeholder="Password"
                                        required
                                    />
                                </div>
                            </div>

                            <CenteredLinkButton className="form-group">
                                <a onClick={() => setAuthenticationType(SFTPAuthenticationType.KEY)}>
                                    Use Key Authentication
                                </a>
                            </CenteredLinkButton>
                        </div>
                    )}

                    {authenticationType === SFTPAuthenticationType.KEY && (
                        <div>
                            <div className="form-group">
                                <label htmlFor="private-key" className="col-sm-3 control-label">
                                    Private Key
                                </label>
                                <div className="col-sm-8">
                                    <textarea
                                        value={privateKey}
                                        className="form-control"
                                        onChange={({ target }) => setPrivateKey(target.value)}
                                        id="private-key"
                                        autoComplete="off"
                                        placeholder="Private Key"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="public-key" className="col-sm-3 control-label">
                                    Public Key (Optional)
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        value={publicKey}
                                        className="form-control"
                                        onChange={({ target }) => setPublicKey(target.value)}
                                        id="public-key"
                                        autoComplete="off"
                                        placeholder="Public Key (Optional)"
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="passphrase" className="col-sm-3 control-label">
                                    Passphrase
                                </label>
                                <div className="col-sm-8">
                                    <input
                                        type="password"
                                        value={passphrase}
                                        className="form-control"
                                        onChange={({ target }) => setPassphrase(target.value)}
                                        id="passphrase"
                                        autoComplete="off"
                                        placeholder="Passphrase"
                                        required
                                    />
                                </div>
                            </div>
                            <CenteredLinkButton className="form-group">
                                <a onClick={() => setAuthenticationType(SFTPAuthenticationType.PASSWORD)}>
                                    Use Password Authentication
                                </a>
                            </CenteredLinkButton>
                        </div>
                    )}

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button type="submit" className="btn btn-primary">
                            Connect
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SftpComponent;
