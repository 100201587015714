import { useAngularService } from 'angulareact';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { EnterpriseComponentAuthorizationType, OAuth2GrantType } from '@tonkean/tonkean-entities';

const useAuthentication: () => (projectIntegration: ProjectIntegration) => Promise<any> = () => {
    const [, exchangeCodeFromToken] = useLazyTonkeanService('exchangeCodeFromToken');
    const [, evaluateCodeRequest] = useLazyTonkeanService('evaluateCodeRequest');
    const [, oauthInitRequest] = useLazyTonkeanService('oauthInitRequest');
    const oauthHandler = useAngularService('oauthHandler');

    // Handle all authentication required.
    return async (projectIntegration: ProjectIntegration) => {
        switch (projectIntegration.integration?.authorizationDetails?.type) {
            case EnterpriseComponentAuthorizationType.OAUTH2: {
                switch (projectIntegration.integration.authorizationDetails.grantType) {
                    case OAuth2GrantType.AUTHORIZATION_CODE: {
                        // get security state
                        const state = oauthHandler.publicGetState();

                        const { evaluatedCodeRequestUrl } = await evaluateCodeRequest(
                            projectIntegration.id,
                            projectIntegration.integration.authorizationDetails,
                            state,
                        );

                        // Open Oauth Modal and wait for response.
                        const code: any = await oauthHandler.startOAuth(evaluatedCodeRequestUrl, state);

                        // grant token or any other data to allow refresh the token.
                        return exchangeCodeFromToken(
                            projectIntegration.id,
                            projectIntegration.integration.authorizationDetails,
                            code,
                        );
                    }

                    case OAuth2GrantType.ACCOUNT_CREDENTIALS:
                    case OAuth2GrantType.CLIENT_CREDENTIALS:
                    case OAuth2GrantType.CUSTOM:
                        return oauthInitRequest(
                            projectIntegration.id,
                            projectIntegration.integration.authorizationDetails,
                        );
                }
            }
            default:
                return Promise.resolve();
        }
    };
};

export default useAuthentication;
