import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { EntityToSolutionMapping } from '../../../../../hooks/useProjectIntegrationActionToSolutionAndGroupMapping';

import { useProject } from '@tonkean/infrastructure';
import {
    ErrorMessage,
    ModalBody,
    ModalFooterActions,
    ModalHeader,
    ModalSize,
    TextEllipsis,
    withModal,
} from '@tonkean/infrastructure';
import { WarningTriangleIcon } from '@tonkean/svg';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Theme } from '@tonkean/tui-theme';
import type { ErrorResponseType } from '@tonkean/utils';
import { getStateError } from '@tonkean/utils';

const SolutionSection = styled.div`
    font-weight: bold;
    margin-top: 16px;
`;

const ModuleName = styled.div`
    font-weight: normal;
    margin-left: 16px;
`;

const DependenciesList = styled.div`
    margin-top: 16px;
`;

const WarningIcon = styled(WarningTriangleIcon)`
    margin-right: 8px;
`;

const SolutionName = styled.div`
    margin-bottom: 4px;
`;

const Notice = styled.div`
    color: ${Theme.colors.gray_500};
    margin-top: 16px;
`;

const NoAccess = styled.span`
    margin-left: 4px;
`;

const DataSourceNameHeader = styled.div`
    display: inline-block;
    margin-left: 4px;
`;

const BlackLinkState = styled(StateLink)`
    color: black;

    &:hover {
        color: black;
    }
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin-top: 8px;
`;

interface Props {
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    entityId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>;
    entityDisplayName: string;
    typeDisplayName: string;
    title?: string;
    onDelete: () => void;
    loading: boolean;
    error?: ErrorResponseType;
    entityToSolutionAndGroupMappingProvider: (
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        entityId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>,
    ) => EntityToSolutionMapping;
}

const ProjectIntegrationActionRemoveModal: React.FC<Props> = ({
    projectIntegrationId,
    entityId,
    entityDisplayName,
    typeDisplayName,
    onDelete,
    title = `${entityDisplayName} ${typeDisplayName}`,
    loading,
    error,
    entityToSolutionAndGroupMappingProvider,
}) => {
    const project = useProject();
    const $state = useAngularService('$state');
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const { mapping, workflowFolders, groups } = entityToSolutionAndGroupMappingProvider(
        projectIntegrationId,
        entityId,
    );

    const isSaveDisabled = useMemo(() => Object.keys(mapping || []).length > 0, [mapping]);

    return (
        <>
            <ModalHeader $flex>
                <WarningIcon />
                Delete
                <DataSourceNameHeader>
                    <TextEllipsis numberOfLines={1} tooltip>
                        {title}
                    </TextEllipsis>
                </DataSourceNameHeader>
            </ModalHeader>

            <ModalBody>
                <>
                    <Notice>
                        {Object.keys(mapping || []).length > 0
                            ? `Solutions that are using ${title}:`
                            : `There are no solutions which use ${title}.`}
                    </Notice>

                    <DependenciesList>
                        {Object.keys(mapping || [])?.map((workflowFolderId) => {
                            const isUserAllowed =
                                workflowFolderManager.projectIdToFolderIdToFolderMap[project.id]?.[workflowFolderId]
                                    ?.isUserOwner;

                            return (
                                <SolutionSection key={workflowFolderId}>
                                    <SolutionName>
                                        <BlackLinkState
                                            state="product.workers"
                                            params={{
                                                folderId: workflowFolderId,
                                                fromState: $state.current.name,
                                                fromStateParams: $state.params,
                                                fromName: title,
                                            }}
                                        >
                                            {workflowFolders[workflowFolderId]?.displayName}
                                        </BlackLinkState>

                                        {!isUserAllowed && <NoAccess>(No Access)</NoAccess>}
                                    </SolutionName>

                                    {mapping[workflowFolderId]?.map((groupId) => {
                                        return (
                                            <ModuleName key={groupId}>
                                                {isUserAllowed ? (
                                                    <BlackLinkState
                                                        state="product.workerEditor"
                                                        params={{
                                                            g: groupId,
                                                            fromState: $state.current.name,
                                                            fromStateParams: $state.params,
                                                            fromName: title,
                                                        }}
                                                    >
                                                        {groups[groupId]?.name}
                                                    </BlackLinkState>
                                                ) : (
                                                    <div>{groups[groupId]?.name}</div>
                                                )}
                                            </ModuleName>
                                        );
                                    })}
                                </SolutionSection>
                            );
                        })}
                    </DependenciesList>
                </>

                {error && <StyledErrorMessage>{getStateError(error)}</StyledErrorMessage>}
            </ModalBody>

            <ModalFooterActions
                saveLabel="Delete"
                loading={loading}
                saveDisabled={isSaveDisabled}
                onSave={onDelete}
                isWarning
            />
        </>
    );
};

export default withModal(ProjectIntegrationActionRemoveModal, { size: ModalSize.SMEDIUM, fixedWidth: true });
