import type { OldIntegrationConfiguration } from '@tonkean/integrations';
import { integrationsService } from '@tonkean/integrations';
import type { IntegrationType } from '@tonkean/tonkean-entities';
import { memoize } from '@tonkean/utils';

/**
 * Returns the integration object by a given type, e.g. "ZENDESK"
 *
 * @param integrationType - The type of the integration
 * @returns The integration definition
 */
function getIntegrationByType(integrationType: IntegrationType): OldIntegrationConfiguration | undefined {
    return integrationsService.getIntegrationsConfigList().find((integration) => integration.name === integrationType);
}

export default memoize(getIntegrationByType);
