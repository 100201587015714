// this is because jshint doesnt know the keyword const (when in dist host)

function Capitalize() {
    return function (text, onlyFirstChar) {
        if (text && text.length) {
            let capitalizedText = text.charAt(0).toUpperCase();

            if (text.length > 1) {
                if (onlyFirstChar === true) {
                    capitalizedText += text.slice(1);
                } else {
                    capitalizedText += text.slice(1).toLowerCase();
                }
            }

            return capitalizedText;
        }
    };
}
export default angular.module('tonkean.app').filter('capitalize', Capitalize);
const capitalizeFilterInstance = new Capitalize();
export { capitalizeFilterInstance as capitalizeFilter };
