import template from './tnkBotToggle.template.html?angularjs';
import { lateConstructController } from '@tonkean/angular-components';

export const tnkBotToggleComponent = angular.module('tonkean.app').component('tnkBotToggle', {
    bindings: {
        groupId: '<', // Required if no overrideToggleMethod provided
        workflowVersionId: '@', // Required if no overrideToggleMethod provided
        isBotActive: '<', // Initial state (required if no overrideToggleMethod provided)
        noValidation: '<', // Set true if no validation modal is necessary (optional)
        overrideToggleMethod: '&', // Set if custom toggle logic is needed (optional)
        size: '@', // Sets the side of the toggle (can be one of these values: sm, lg). If not given, will be default size.,
        botName: '@', // Sets the name of the bot, default is "BOT"
        toggleDisabled: '<', // If true user will not be able to toggle
    },
    template,
    controller: 'BotToggleCtrl',
});

/* @ngInject */
function BotToggleCtrl($scope, $rootScope, $attrs, modal, workflowVersionManager) {
    const ctrl = this;

    $scope.pm = $rootScope.pm;
    $scope.data = {
        isBotActive: ctrl.isBotActive,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        noValidation: ctrl.noValidation,
        size: ctrl.size,
        botName: ctrl.botName,
        overrideToggleMethod: $attrs.overrideToggleMethod ? ctrl.overrideToggleMethod : null,
        toggleDisabled: ctrl.toggleDisabled,
    };

    ctrl.$onInit = function () {
        // If isBotActive was not supplied to the component, take if from the group.
        if (!$attrs.isBotActive) {
            $scope.data.isBotActive = workflowVersionManager.getCachedWorkflowVersion(
                $scope.data.workflowVersionId,
            ).shouldSendGatherUpdates;
        }
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.isBotActive) {
            $scope.data.isBotActive = ctrl.isBotActive;
        }
    };

    $scope.openBotToggleValidationModal = function () {
        return modal.openBotToggleValidationModal($scope.data.isBotActive).result;
    };

    $scope.toggleIsBotActive = function (isActive) {
        $scope.data.isBotActive = isActive;
        // use override logic if exists
        if ($scope.data.overrideToggleMethod) {
            $scope.data.overrideToggleMethod();
            return;
        }

        workflowVersionManager.toggleShouldSendGatherUpdates($scope.data.groupId, $scope.data.isBotActive);
    };
}

export const BotToggleCtrlController = angular
    .module('tonkean.app')
    .controller('BotToggleCtrl', lateConstructController(BotToggleCtrl));
