import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function LeverCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        definition: ctrl.definition,
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'UPDATE_FEEDBACK':
                if (!$scope.data.definition.fields) {
                    $scope.data.definition.fields = [];
                }
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.addField = function () {
        $scope.data.definition.fields.push({});
    };

    $scope.removeField = function (index) {
        $scope.data.definition.fields.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.onOpportunityIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.opportunityIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFeedbackIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.feedbackIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPerformAsTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.performAsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onCompletedAtTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.completedAtExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFieldIdTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.fields[index].fieldIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFieldValueTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.fields[index].fieldValueExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('LeverCustomActionsDefinitionCtrl', lateConstructController(LeverCustomActionsDefinitionCtrl));
