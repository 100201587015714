import { useAngularService } from 'angulareact';
import React, { useState } from 'react';
import type { FormEvent } from 'react';
import styled from 'styled-components';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { RadioGroup } from '@tonkean/infrastructure';
import { Radio } from '@tonkean/infrastructure';
import { Checkbox } from '@tonkean/infrastructure';
import { useToggle } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { TooltipIcon } from '@tonkean/svg';
import { InputSize } from '@tonkean/tui-theme/sizes';

export interface ZendeskAuthenticationProps extends CustomizedAuthenticationComponentProps {}

enum AuthenticationType {
    oauth = 'oauth',
    apiToken = 'apiToken',
}

const ToolTipIconWrapper = styled(TooltipIcon)`
    margin-left: 5px;
`;

const ZendeskAuthenticationComponent: React.FC<ZendeskAuthenticationProps> = ({ authenticate, reportError }) => {
    const environment = useAngularService('environment');
    const oauthHandler = useAngularService('oauthHandler');
    const [authType, setAuthType] = useState<AuthenticationType>(AuthenticationType.oauth);

    const [subDomain, setSubDomain] = useState<string>();
    const [apiToken, setApiToken] = useState<string>();
    const [userEmail, setUserEmail] = useState<string>();

    const [hasAdminRights, toggleHasAdminRights] = useToggle(false);

    const startAuthentication = async (formEvent: FormEvent) => {
        formEvent.preventDefault();
        formEvent.stopPropagation();

        try {
            switch (authType) {
                case AuthenticationType.oauth: {
                    const oauthAuthenticationConfig = await getOauthAuthenticationConfig();
                    authenticate(oauthAuthenticationConfig);
                    break;
                }
                case AuthenticationType.apiToken: {
                    authenticate(getApiTokenAuthenticationConfig());
                    break;
                }
            }
        } catch {
            // We are using this component from angularjs context, thus we can't be sure that reportError is present.
            if (reportError) {
                reportError('Error occurred while authenticating.');
            }
        }
    };

    const getOauthAuthenticationConfig = async () => {
        const zendeskScope = hasAdminRights ? 'impersonate read write' : 'read write';

        const code = await oauthHandler.zendesk(
            subDomain,
            environment.integrationKeysMap.zendesk,
            zendeskScope,
            environment.integrationKeysMap.zendeskUri,
        );

        const config = {
            accountName: subDomain,
            code,
            redirectUri: environment.integrationKeysMap.zendeskUri,
            isAdmin: hasAdminRights,
        };
        return config;
    };

    const getApiTokenAuthenticationConfig = () => {
        const config = {
            accountName: subDomain,
            apiToken,
            zendeskUserEmail: userEmail,
            redirectUri: environment.integrationKeysMap.zendeskUri,
            isAdmin: hasAdminRights,
        };

        return config;
    };

    return (
        <form className="flex flex-col" onSubmit={startAuthentication}>
            <div className="flex flex-col">
                {/* Authentication Type*/}
                <div className="form-group flex-inline">
                    <label className="control-label col-md-4">Authentication Type:</label>

                    <div className="col-sm-8">
                        <RadioGroup value={authType} direction="row" onChange={setAuthType} size={InputSize.MEDIUM}>
                            <Radio value={AuthenticationType.oauth}>OAuth</Radio>
                            <Radio value={AuthenticationType.apiToken}>API Token</Radio>
                        </RadioGroup>
                    </div>
                </div>

                {/* URL*/}
                <div className="form-group">
                    <label className="col-sm-3 control-label">URL</label>
                    <div className="col-sm-8">
                        <div className="flex-vmiddle">
                            <div>https://</div>
                            <div className="flex-grow">
                                <input
                                    type="text"
                                    value={subDomain}
                                    className="form-control"
                                    onChange={({ target }) => setSubDomain(target.value)}
                                    autoComplete="off"
                                    placeholder="Your sub-domain"
                                    required
                                />
                            </div>
                            <div>.zendesk.com</div>
                        </div>
                    </div>
                </div>

                {/* API Token*/}
                {authType === AuthenticationType.apiToken && (
                    <div className="flex flex-col">
                        <div className="form-group">
                            <label className="col-sm-3 control-label">API Token</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    value={apiToken}
                                    className="form-control"
                                    onChange={({ target }) => setApiToken(target.value)}
                                    autoComplete="off"
                                    placeholder="API Token"
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-3 control-label">User Email</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    value={userEmail}
                                    className="form-control"
                                    onChange={({ target }) => setUserEmail(target.value)}
                                    autoComplete="off"
                                    placeholder="User email"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                )}

                {authType === AuthenticationType.oauth && (
                    <div className="form-group margin-auto">
                        <Checkbox checked={hasAdminRights} name="adminRights" onChange={() => toggleHasAdminRights()}>
                            I have admin rights
                        </Checkbox>
                        <Tooltip content="Zendesk Support admins can perform actions on behalf of other support admins (i.e. adding and removing tags, updating tickets).">
                            <ToolTipIconWrapper />
                        </Tooltip>
                    </div>
                )}

                <div className="flex mod-center margin-top-20">
                    <button className="btn btn-secondary btn-lg">Authenticate with Zendesk</button>
                </div>
            </div>
        </form>
    );
};

export default ZendeskAuthenticationComponent;
