import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Input, Placeholder } from '@tonkean/infrastructure';
import type { ProjectIntegrationParameterMarketplaceSummaryWithIsEncrypted } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    align-items: center;
`;

const EmptyStateMessage = styled.div``;

const RedSpan = styled.span`
    color: ${Theme.colors.error};
    padding-left: 3px;
`;

const VariableLabel = styled.label`
    color: ${Theme.colors.gray_800};
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    margin: 0;
`;

interface Props {
    variables?: ProjectIntegrationParameterMarketplaceSummaryWithIsEncrypted[];
    updatedValuesMap: Record<string, string>;
    onChange: (params: Record<string, string>) => void;
    loading?: boolean;
}

const EnterpriseComponentVariableValuesFromMarketplaceItem: React.FC<Props> = ({
    variables,
    loading,
    updatedValuesMap,
    onChange,
}) => {
    const sortedVariables = useMemo(() => {
        return variables?.sort((variableA, variableB) => ((variableA?.index ?? 0) > (variableB?.index ?? 0) ? 1 : -1));
    }, [variables]);

    return (
        <Wrapper>
            {/* Loading State */}
            {loading && range(6).map((index) => <Placeholder key={index} $height="26px" $width="auto" $circle />)}

            {/* Table columns */}
            {!loading &&
                sortedVariables?.map((variable) => (
                    <React.Fragment key={variable.displayName}>
                        <VariableLabel htmlFor={variable.displayName}>
                            {variable.displayName}

                            {variable.isRequired && <RedSpan>*</RedSpan>}
                        </VariableLabel>

                        <Input
                            id={variable.displayName}
                            type={variable.isEncrypted ? 'password' : 'text'}
                            value={
                                updatedValuesMap[variable.displayName] === undefined
                                    ? ''
                                    : updatedValuesMap[variable.displayName]
                            }
                            onChange={({ target: { value } }) => {
                                onChange({
                                    ...updatedValuesMap,
                                    [variable.displayName]: value,
                                });
                            }}
                            placeholder="Insert Value"
                        />
                    </React.Fragment>
                ))}
        </Wrapper>
    );
};

export default EnterpriseComponentVariableValuesFromMarketplaceItem;
