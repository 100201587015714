import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import {
    getIntakeSequenceActionOption,
    getOpenFormActionOptions,
} from '../../../components/InterfaceCTAActionsSelector/hooks/useMatchedItemOptions';

import { useAsyncMethod, useTonkeanService } from '@tonkean/angular-hooks';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';
import {
    type Form,
    FormType,
    type InterfaceCTAActionOption,
    type TonkeanId,
    type TonkeanType,
} from '@tonkean/tonkean-entities';

export const useSolutionSiteActionOptions = (
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    workflowVersionType: WorkflowVersionType,
) => {
    const formManager = useAngularService('formManager');
    const {
        data: forms,
        loading: loadingForms,
        error: errorForms,
    } = useAsyncMethod(formManager, 'getAllWorkflowFolderForms', workflowFolderId, workflowVersionType);

    const {
        data: intakeSequencesResponse,
        loading: loadingIntakeSequences,
        error: errorIntakeSequences,
    } = useTonkeanService('getIntakeSequenceCustomTriggersForWorkflowFolder', workflowFolderId, workflowVersionType);

    const groupInfoManager = useAngularService('groupInfoManager');

    const actionOptions: InterfaceCTAActionOption[] = useMemo(() => {
        const filteredForms: Form[] = forms?.filter((form) => form.formType === FormType.CREATE) || [];
        const openFormActions = getOpenFormActionOptions(filteredForms).map((option) => {
            const form = filteredForms.find((item) => item.id === option.savedEntityTemplate.id);
            const group = groupInfoManager.getGroupByIdFromCache(form?.group?.id as TonkeanId<TonkeanType.GROUP>);
            return {
                ...option,
                groupTitle: `${option.groupTitle} - ${group?.name}`,
            };
        });

        const intakeSequencesActions = intakeSequencesResponse?.entities?.map((intakeSequence) => {
            const option = getIntakeSequenceActionOption(intakeSequence);
            const group = groupInfoManager.getGroupByIdFromCache(intakeSequence.groupId);
            const optionWithGroupName: InterfaceCTAActionOption = {
                ...option,
                groupTitle: `${option.groupTitle} - ${group?.name}`,
            };
            return optionWithGroupName;
        });

        return [...openFormActions, ...(intakeSequencesActions || [])];
    }, [forms, groupInfoManager, intakeSequencesResponse?.entities]);
    const loading = loadingForms || loadingIntakeSequences;
    const error = errorForms || errorIntakeSequences;
    return { loading, error, actionOptions };
};
