import widgetDataChangeImage from '../../../../images/highlightsExamples/widget_DataChange.png';
import mobileWidgetDataIncreaseImage from '../../../../images/highlightsExamples/Mobile_WidgetDataIncrease.png';
import widgetMultiDataChangesImage from '../../../../images/highlightsExamples/widget_MultiDataChanges.png';
import mobileMultiDataChangeImage from '../../../../images/highlightsExamples/Mobile_MultiDataChange.png';
import widgetLateImage from '../../../../images/highlightsExamples/widget_Late.png';
import mobileWidgetLateImage from '../../../../images/highlightsExamples/Mobile_WidgetLate.png';

function ExampleHighlightsCtrl($scope) {
    $scope.images = [
        {
            title: 'Major increase in support tickets',
            img: widgetDataChangeImage,
            mobileImage: mobileWidgetDataIncreaseImage,
        },
        {
            title: 'Multiple data change',
            img: widgetMultiDataChangesImage,
            mobileImage: mobileMultiDataChangeImage,
        },
        {
            title: 'P0 issue late',
            img: widgetLateImage,
            mobileImage: mobileWidgetLateImage,
        },
    ];
}

export default angular.module('tonkean.app').controller('ExampleHighlightsCtrl', ExampleHighlightsCtrl);
