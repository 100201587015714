<div class="preview-bot-bubble-container">
    <div
        class="preview-bot-message common-color-dark-grey common-size-xxxxs common-bold hidden-xs"
        ng-if="!data.noTriangle"
    >
        Preview Message
    </div>
    <div
        class="margin-bottom-lg preview-bot-bubble"
        ng-class="{ 'mod-no-triangle': data.noTriangle, 'mod-width-auto': data.autoWidth }"
    >
        <div class="flex padding-normal-md">
            <!-- Tonkean icon -->
            <div
                ng-if="!data.isEmail"
                class="margin-right-xs svg-icon svg-icon-lg mod-static relative"
                ng-class="{ 'tonkean-icon': !data.sendOnBehalf }"
            >
                <!-- tonkean -->
                <div ng-if="!data.sendOnBehalf">
                    <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                </div>

                <!-- avatar -->
                <img
                    ng-if="data.sendOnBehalf"
                    class="avatar avatar-md margin-right-xs"
                    src="/images/avatars/avatar-1.png"
                />
            </div>

            <div>
                <!-- Tonkean Module title -->
                <div class="margin-bottom flex-vmiddle">
                    <!-- Tonkean logo, for when it's an email -->
                    <div ng-if="data.isEmail" class="margin-right-xs svg-icon-md mod-static relative">
                        <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                    </div>

                    <!-- Title -->
                    <span class="common-bold margin-right">
                        {{ data.sendOnBehalf ? 'Person Name' : 'Tonkean BOT' }}
                    </span>

                    <!-- Slack mimic for when it's not email -->
                    <span ng-if="!data.isEmail" class="common-size-xxxxxs margin-right preview-tag-box">APP</span>
                    <span ng-if="!data.isEmail" class="common-color-grey margin-right common-size-xxxxxs">2:31 PM</span>

                    <!-- Toggle Example Item -->
                    <a
                        ng-if="data.exampleInitiativeId"
                        data-ng-click="toggleShowExampleItem()"
                        class="common-color-grey common-size-xxxs"
                        tooltip-placement="bottom"
                        uib-tooltip="Show how this message will look with an example item selected in the left workflow outline. {{
                            data.exampleInitiativeTitle
                        }}"
                    >
                        <i class="fa fa-flask"></i>
                    </a>
                </div>

                <div class="relative" ng-class="{ 'padding-left-md': !data.isEmail && data.color }">
                    <!-- Left border-->
                    <div
                        ng-if="
                            !data.isEmail &&
                            data.color &&
                            ((data.botButtons && data.botButtons.length) || (data.botFields && data.botFields.length))
                        "
                        class="preview-bot-message-attachment-line"
                        ng-style="{ 'background-color': data.color }"
                    ></div>

                    <!-- Preview content -->
                    <div ng-switch="data.previewType">
                        <!-- Text preview -->
                        <div ng-switch-when="text" class="common-break-long">
                            <div ng-class="{ 'common-color-grey': !data.text }">
                                <div>
                                    <!-- Original Text -->
                                    <span
                                        ng-if="
                                            !data.showExampleItem ||
                                            !data.exampleInitiativeId ||
                                            !data.textEvaluatedExpression
                                        "
                                    >
                                        <span
                                            ng-if="data.htmlPreview"
                                            ng-bind-html="(data.text ? data.text : (data.placeHolder || '{Your text will appear here}'))"
                                        ></span>
                                        <span
                                            ng-if="!data.htmlPreview"
                                            ng-bind="(data.text ? data.text : (data.placeHolder || '{Your text will appear here}'))"
                                        ></span>
                                    </span>
                                    <tnk-example-item-evaluated-expression
                                        ng-if="data.showExampleItem && data.exampleInitiativeId"
                                        expression-to-evaluate="data.textEvaluatedExpression"
                                        group-id="{{ data.groupId }}"
                                        workflow-version-id="{{ data.workflowVersionId }}"
                                        logic-id="{{ data.logicId }}"
                                        expression-unique-identifier="{{ data.uniqueIdentifier }}text"
                                        hide-preview="!data.showExampleItem"
                                        override-example-initiative-id="data.exampleInitiativeId"
                                        hide-icon="true"
                                        color="black"
                                        html-preview="data.htmlPreview"
                                        is-inline="true"
                                        preview-evaluation-source="data.previewEvaluationSource"
                                    ></tnk-example-item-evaluated-expression>

                                    <!-- Post Text -->
                                    <span ng-if="data.postText && data.postText.length">
                                        |
                                        <span
                                            ng-if="!data.showExampleItem || !data.exampleInitiativeId"
                                            class="common-color-link-blue"
                                        >
                                            {{ data.postText }}
                                        </span>
                                        <tnk-example-item-evaluated-expression
                                            ng-if="data.showExampleItem && data.exampleInitiativeId"
                                            expression-to-evaluate="data.postTextEvaluatedExpression"
                                            group-id="{{ data.groupId }}"
                                            workflow-version-id="{{ data.workflowVersionId }}"
                                            logic-id="{{ data.logicId }}"
                                            expression-unique-identifier="{{ data.uniqueIdentifier }}postText"
                                            hide-preview="!data.showExampleItem"
                                            preview-evaluation-source="data.previewEvaluationSource"
                                            override-example-initiative-id="data.exampleInitiativeId"
                                            hide-icon="true"
                                            color="blue"
                                            is-inline="true"
                                        ></tnk-example-item-evaluated-expression>
                                    </span>
                                </div>

                                <!-- New Line -->
                                <div
                                    ng-if="
                                        data.newLineText &&
                                        data.newLineText.length &&
                                        (!data.showExampleItem || !data.exampleInitiativeId)
                                    "
                                >
                                    {{ data.newLineText }}
                                </div>

                                <tnk-example-item-evaluated-expression
                                    ng-if="data.showExampleItem && data.exampleInitiativeId"
                                    expression-to-evaluate="data.newLineEvaluatedExpression"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.logicId }}"
                                    expression-unique-identifier="{{ data.uniqueIdentifier }}newLine"
                                    hide-preview="!data.showExampleItem"
                                    override-example-initiative-id="data.exampleInitiativeId"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    hide-icon="true"
                                    is-inline="true"
                                ></tnk-example-item-evaluated-expression>
                            </div>
                        </div>

                        <!-- Question preview -->
                        <div ng-switch-when="question">
                            <div>
                                <span ng-class="{ 'common-color-grey': !data.text }">
                                    {{ data.text ? data.text : '{Item name}' }}
                                </span>
                                <span>is marked as</span>
                                <span class="common-bold">At risk</span>
                                .
                            </div>
                            <div>Anything new worth sharing?</div>
                        </div>

                        <!-- Trigger preview -->
                        <div ng-switch-when="trigger" class="common-break-long">
                            <span>
                                <strong>{{ data.entityName || 'ACME' }}</strong>
                                was triggered for:&nbsp;
                            </span>
                            <strong ng-class="{ 'common-color-grey': !data.text }">
                                {{ data.text ? data.text : data.placeHolder || '{Your text will appear here}' }}
                            </strong>
                        </div>
                    </div>

                    <!-- Fields -->
                    <div ng-if="data.botFields && data.botFields.length" class="flex flex-wrap">
                        <div
                            ng-repeat="field in data.botFields"
                            class="margin-right-lg margin-top"
                            ng-if="(field.label && field.label.length) || (field.name && field.name.length)"
                            ng-class="{ 'common-width-full': !field.isShort }"
                        >
                            <strong>{{ field.fieldLabel || field.label || field.name }}</strong>
                            <br />
                            <span ng-if="!data.showExampleItem || !data.exampleInitiativeId">...</span>
                            <tnk-example-item-evaluated-expression
                                ng-if="data.showExampleItem && data.exampleInitiativeId"
                                field-id-to-evaluate="field.id"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.logicId }}"
                                expression-unique-identifier="{{ data.uniqueIdentifier }}field{{ field.id }}"
                                hide-preview="!data.showExampleItem"
                                override-example-initiative-id="data.exampleInitiativeId"
                                preview-evaluation-source="data.previewEvaluationSource"
                                hide-icon="true"
                                is-inline="true"
                            ></tnk-example-item-evaluated-expression>
                        </div>
                    </div>

                    <!-- Buttons -->
                    <div ng-if="!data.isEmail && !data.noButtons">
                        <!-- Custom Module buttons -->
                        <div
                            ng-if="data.botButtons && data.botButtons.length"
                            class="flex-vmiddle flex-wrap margin-top-md"
                        >
                            <div
                                ng-if="botButton.text"
                                ng-repeat="botButton in data.botButtons"
                                class="
                                    common-size-xxxxs common-bold
                                    preview-write-update
                                    margin-right-xs margin-top-xs
                                    flex-no-shrink
                                "
                            >
                                {{ botButton.text }}
                            </div>
                        </div>

                        <!-- If no custom button, defaults to showing write an update button -->
                        <div
                            ng-if="!data.botButtons"
                            ng-class="
                                data.previewType === data.previewTypesEnum.question ? 'margin-top' : 'margin-top-lg'
                            "
                            class="flex-vmiddle"
                        >
                            <div class="common-size-xxxxs common-bold preview-write-update margin-right-xs">
                                Write an update
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Post Attachment text -->
                <div
                    ng-if="!data.isEmail && data.postAttachementText && data.postAttachementText.length"
                    class="padding-left-md relative margin-top"
                >
                    <!-- Left border-->
                    <div class="preview-bot-message-attachment-line common-bg mod-secondary"></div>

                    <!-- content -->
                    <tnk-example-item-evaluated-expression
                        expression-to-evaluate="data.postAttachementText"
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.logicId }}"
                        expression-unique-identifier="{{ data.uniqueIdentifier }}PostAttachement"
                        hide-preview="!data.showExampleItem"
                        override-example-initiative-id="data.exampleInitiativeId"
                        preview-evaluation-source="data.previewEvaluationSource"
                        hide-icon="true"
                        color="black"
                        html-preview="data.htmlPreview"
                        is-inline="true"
                    ></tnk-example-item-evaluated-expression>
                </div>
            </div>
        </div>

        <!-- Reply via email -->
        <div ng-if="data.isEmail" class="flex-vmiddle padding-normal-sm common-border-top">
            <!-- user avatar -->
            <tnk-avatar user="data.user" class="avatar-sm margin-right" noclass="true"></tnk-avatar>

            <!-- Text -->
            <div class="common-bold common-size-xxxxxs">Sent via email</div>

            <!-- Growth placeholder -->
            <div class="flex-grow"></div>

            <!-- Reply icon-->
            <i class="fa fa-reply common-color-light-grey"></i>
        </div>

        <!-- Error state -->
        <span class="common-color-danger common-size-xxxs margin-left" ng-if="data.error">{{ data.error }}</span>
    </div>
</div>
