import template from './tnkWorkerTargetStorageSelection.template.html?angularjs';

/* Worker Target Storage Selection
 * Used in integration's action that downloads files and uploading the file to the selected storage provider.
 * The saved uploaded file ID is of the storage provider
 */
export default angular.module('tonkean.app').component('tnkWorkerTargetStorageSelection', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        customTriggerId: '@',
        storageIntegrationId: '@',
        newDocumentName: '<',
        shouldDisplayDeleteSign: '<',
        previewEvaluationSource: '<',
        onDeleteSignClicked: '&',
        onWorkerTargetStorageSelectionChanged: '&',
        infoMessage: '<',
    },
    template,
    controller: 'WorkerTargetStorageSelectionCtrl',
});
