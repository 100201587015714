function CustomerFiltersInnerQuery($scope) {
    const ctrl = this;
    $scope.data = {
        query: ctrl.query,
        errorObject: ctrl.errorObject,

        forceExpand: ctrl.forceExpand,
        groupNameBeingEditId: ctrl.groupNameBeingEditId,
        onGroupNameEdit: ctrl.onGroupNameEdit,
        invokeOnFilterChange: ctrl.invokeOnFilterChange,
        hideQueryOnSingleCondition: ctrl.hideQueryOnSingleCondition,
        queryTypes: ctrl.queryTypes,
        loadingFields: ctrl.loadingFields,
        filterInFocusId: ctrl.filterInFocusId,
        onFilterFocus: ctrl.onFilterFocus,
        filtersResultMap: ctrl.filtersResultMap,
        fieldDefinitionMap: ctrl.fieldDefinitionMap,
        projectIntegrationDescriptionClass: ctrl.projectIntegrationDescriptionClass,
        itemsSource: ctrl.itemsSource,
        smallMod: ctrl.smallMod,
        groupId: ctrl.groupId,
        targetWorkflowVersionId: ctrl.targetWorkflowVersionId,
        workflowVersionId: ctrl.workflowVersionId,
        considerAllGroups: ctrl.considerAllGroups,
        projectIntegration: ctrl.projectIntegration,
        displayIntegrationIndication: ctrl.displayIntegrationIndication,
        selectedEntityType: ctrl.selectedEntityType,
        inspectEntityType: ctrl.inspectEntityType,
        onFieldOptionSelected: ctrl.onFieldOptionSelected,
        addCustomFields: ctrl.addCustomFields,
        reloadFields: ctrl.reloadFields,
        showFieldInspect: ctrl.showFieldInspect,
        showFieldInspectModal: ctrl.showFieldInspectModal,
        viewOnly: ctrl.viewOnly,
        appendToBody: ctrl.appendToBody,
        specialFieldsForFeatures: ctrl.specialFieldsForFeatures,
        reloadFieldsOnNewFieldDefinitionCreation: ctrl.reloadFieldsOnNewFieldDefinitionCreation,
        onConditionSelection: ctrl.onConditionSelection,
        onValueChanged: ctrl.onValueChanged,
        onSecondValueChanged: ctrl.onSecondValueChanged,
        onExpressionChanged: ctrl.onExpressionChanged,
        useExpressionsForValue: ctrl.useExpressionsForValue,
        tabsFieldSelectorModNarrow: ctrl.tabsFieldSelectorModNarrow,
        tabsFieldSelectorModFixedWidth: ctrl.tabsFieldSelectorModFixedWidth,
        modFixedWidth: ctrl.modFixedWidth,
        verticalMod: ctrl.verticalMod,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        allowedConditionsSet: ctrl.allowedConditionsSet,
        notAllowedConditionsSet: ctrl.notAllowedConditionsSet,
        excludedTabSelectorSpecialFields: ctrl.excludedTabSelectorSpecialFields,
        includeTabSelectorSpecialFieldsForFeatures: ctrl.includeTabSelectorSpecialFieldsForFeatures,
        uniqueIdentifier: ctrl.uniqueIdentifier,
        evaluatePreview: ctrl.evaluatePreview,
        selectedLogicTypeConfiguration: ctrl.selectedLogicTypeConfiguration,
        removeFieldFilter: ctrl.removeFieldFilter,
        addNewAggregateCondition: ctrl.addNewAggregateCondition,
        addNewAggregateQuery: ctrl.addNewAggregateQuery,
        logicId: ctrl.logicId,
        queryIndex: ctrl.queryIndex,
        onDeleteInnerQuery: ctrl.onDeleteInnerQuery,
        allowContains: ctrl.allowContains,
        showPeopleDirectories: ctrl.showPeopleDirectories,
        includeViewerFields: ctrl.includeViewerFields,

        filtersErrors: [],
    };

    ctrl.$onChanges = function (changesObj) {
        Object.keys($scope.data).forEach((variable) => {
            if (changesObj[variable]) {
                $scope.data[variable] = changesObj[variable].currentValue;
            }
        });

        if (changesObj.errorObject) {
            // Mapping the filters errors to only the strings with no keys, will just display them in order
            $scope.data.filtersErrors = ctrl.errorObject?.filters?.map((err) => Object.values(err)) || [];
        }
    };

    $scope.deleteInnerQuery = (index) => {
        if (!$scope.data.viewOnly) {
            $scope.data.query.queries.splice(index, 1);
            $scope.data.invokeOnFilterChange();
        }
    };

    $scope.setQueryUiOpenState = function (query, isOpen) {
        query.uiOpen = isOpen;
    };
}

angular.module('tonkean.app').controller('CustomerFiltersInnerQuery', CustomerFiltersInnerQuery);
