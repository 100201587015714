<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body slack-app-modal">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>

    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUser }} ({{ integration.integrationUserDisplayName }})
                    </span>
                </span>
            </div>
        </div>
        <form
            class="margin-normal-v"
            novalidate
        >
            <div ng-form="data.integrationForm">
                <div class="common-title-xs">Choose Channels to monitor</div>
                <div class="common-subtitle-inner">We will look at each message in these channels</div>

                <div class="monitored-channels-container overflow-auto">
                    <div
                        ng-repeat="channelToCollect in selected.channelsToCollect"
                        class="
                            monitored-channel
                            relative
                            flex-vmiddle
                            common-border
                            padding-right-xlg padding-left-lg padding-top padding-bottom
                        "
                        ng-class="{ 'common-no-bottom-border': !$last }"
                    >
                        <div class="flex-grow">
                            <div class="flex-vmiddle">
                                <!-- Public channel option -->
                                <tnk-radio-button
                                    radio-id="public-channel-{{ $index }}"
                                    radio-name="channel-visibility-type-{{ $index }}"
                                    radio-value="public"
                                    model="channelToCollect.selectionType"
                                    on-click="selectChannelVisibilityType(channelToCollect, onClickParam)"
                                    on-click-param="('public')"
                                    is-required="true"
                                >
                                    Public
                                </tnk-radio-button>

                                <!-- Private channel option -->
                                <tnk-radio-button
                                    radio-id="private-channel-{{ $index }}"
                                    radio-name="channel-visibility-type-{{ $index }}"
                                    radio-value="private"
                                    model="channelToCollect.selectionType"
                                    class="margin-left-xs"
                                    on-click="selectChannelVisibilityType(channelToCollect, onClickParam)"
                                    on-click-param="('private')"
                                    is-required="true"
                                >
                                    Private
                                </tnk-radio-button>

                                <tnk-radio-button
                                        class="margin-left-6"
                                        radio-id="public-by-name-{{ $index }}"
                                        radio-name="channel-visibility-type-{{ $index }}"
                                        radio-value="public-by-name"
                                        model="channelToCollect.selectionType"
                                        on-click="selectChannelVisibilityType(channelToCollect, onClickParam)"
                                        on-click-param="('public-by-name')"
                                        is-required="true"
                                >
                                    Search public channel
                                </tnk-radio-button>
                            </div>
                            <div>
                                <!-- Public channel selector -->
                                <tnk-channel-selector
                                    ng-if="channelToCollect.selectionType === 'public'"
                                    class="block"
                                    selected-channel="channelToCollect"
                                    on-channel-selected="onPublicChannelSelected(channel, $index)"
                                    selected-integration="integration"
                                    append-to-body="true"
                                ></tnk-channel-selector>

                                <!-- Private channel selector -->
                                <tnk-channel-checker-selector
                                    ng-if="channelToCollect.selectionType === 'private'"
                                    selected-channel="channelToCollect.displayName"
                                    integration="integration"
                                    on-private-channel-selected="
                                        onPrivateChannelSelected(channelSelectionObject, $index)
                                    "
                                    channel-type="'PRIVATE_CHANNEL'"
                                ></tnk-channel-checker-selector>
                                <!-- Public channel by name selector -->
                                <tnk-channel-checker-selector
                                        ng-if="channelToCollect.selectionType === 'public-by-name'"
                                        selected-channel="channelToCollect.displayName"
                                        integration="integration"
                                        on-private-channel-selected="
                                        onPublicChannelByNameSelected(channelSelectionObject, $index)
                                    "
                                        channel-type="'PUBLIC_CHANNEL'"
                                ></tnk-channel-checker-selector>
                            </div>
                        </div>
                        <button
                            class="
                                btn
                                svg-icon-sm
                                absolute-top-right
                                margin-right-xs margin-top-xs
                                common-color-grey
                                padding-none
                            "
                            ng-click="removeChannelToCollect($index)"
                        >
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </button>
                    </div>
                </div>

                <div class="margin-top-xs">
                    <a ng-click="addChannelToCollect()">+ Add Channel</a>
                </div>
            </div>

            <div class="alert alert-grey margin-top-lg">
                <div>Note: In order to monitor a channel's messages you must</div>
                <ol>
                    <li>Have Tonkean connected as a Communication Source</li>
                    <li>
                        Invite the Tonkean app to the channel you want monitored through the channel settings. Go to
                        Channel Settings, click on Add Apps and look for your App.
                    </li>
                </ol>

                <div ng-if="data.isSlackCommunicationConnected">*Slack is connected as a communication source</div>
                <div ng-if="!data.isSlackCommunicationConnected">
                    <span class="common-color-danger">*Please connect Slack as a communication source,</span>
                    <a ui-sref="product.enterpriseComponents({tab: 'communication-sources'})" ng-click="$dismiss()">
                        connect here
                    </a>
                </div>
            </div>
        </form>

        <div ng-if="data.hasInvalidChannels" class="common-color-danger margin-top-xs">
            One or more channel configurations is invalid.
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        data-automation="modal-template-ok-button"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        ng-if="!data.isCreate"
        type="button"
        ng-click="ok()"
        ng-disabled="isChannelsValid()"
    >
        OK
    </button>
</div>
