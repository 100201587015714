import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import SimpleSelect from '../../../../../../../packages/infrastructure/src/components/TUI/Select/SimpleSelect';
import projectIntegrationEntityCollectPageInputState from '../../states/projectIntegrationEntityCollectPageInputState';
import ProjectIntegrationEntityHandleResponseView from '../common/ProjectIntegrationEntityHandleResponseView';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';

const StyledEntityPickerText = styled.div`
    margin-top: 10px;
    font-weight: bold;
`;

const StyledSimpleSelect = styled(SimpleSelect)`
    margin-top: 6px;
    margin-bottom: 6px;
`;

interface Props {
    entity: ProjectIntegrationEntity;
}

const ProjectIntegrationEntityHandleTestRunResponseView: React.FC<Props> = ({ entity }) => {
    const [projectIntegrationEntity, setProjectIntegrationEntity] = useState(entity);

    // State for the selected test run Ids.
    const [{ selectedTestRunIds }] = useRecoilState(projectIntegrationEntityCollectPageInputState);

    // handle response preview lazy call.
    const [{ error: errorSummaries, loading: loadingSummaries, data: summaries }, getExternalActivities] =
        useLazyTonkeanService('getProjectIntegrationActionExternalActivitiesHandleResponseByTestRun');

    const {
        data: entities,
        loading,
        error,
    } = useTonkeanService('getProjectIntegrationEntityDependencies', entity.projectIntegrationId, entity.id);

    const projectIntegrationEntities = useMemo(() => {
        return [...(entities?.entities || []), entity];
    }, [entities, entity]);

    const options = useMemo(() => {
        if (!entities) {
            return [];
        }

        return projectIntegrationEntities.map((currentEntity) => ({
            value: currentEntity.id,
            label: currentEntity.displayName,
        }));
    }, [entities, projectIntegrationEntities]);

    useEffect(() => {
        getExternalActivities(
            projectIntegrationEntity.projectIntegrationId,
            projectIntegrationEntity.id,
            selectedTestRunIds,
        );
    }, [getExternalActivities, projectIntegrationEntity, selectedTestRunIds]);

    // Debounce callback on search external activity.
    const getExternalActivitiesAutoComplete = useCallback(
        (debounced: string) => {
            return getExternalActivities(
                entity.projectIntegrationId,
                projectIntegrationEntity.id,
                selectedTestRunIds,
                debounced,
            ).then((response) => response.entities);
        },
        [entity.projectIntegrationId, getExternalActivities, projectIntegrationEntity.id, selectedTestRunIds],
    );

    const responseKey = selectedTestRunIds.join(',');

    return (
        <>
            {entities && (
                <>
                    <StyledEntityPickerText>Select Entity to show external activities for:</StyledEntityPickerText>
                    <StyledSimpleSelect
                        value={projectIntegrationEntity.id}
                        options={options}
                        onChange={(value) => {
                            setProjectIntegrationEntity(
                                entities.entities?.find((currEntity) => currEntity.id === value) || entity,
                            );
                        }}
                        thin
                    />
                </>
            )}

            <ProjectIntegrationEntityHandleResponseView
                key={responseKey}
                entity={projectIntegrationEntity}
                totalEntities={summaries?.totalEntities}
                numOfPages={summaries?.numOfPages}
                error={errorSummaries}
                entitiesPerPage={summaries?.entitiesPerPage}
                isLoadingSummaries={loadingSummaries}
                getExternalActivitiesAutoComplete={getExternalActivitiesAutoComplete}
            />
        </>
    );
};
export default ProjectIntegrationEntityHandleTestRunResponseView;
