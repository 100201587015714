import ApprovalCycleLogicBlockConfig from './configs/ApprovalCycleLogicBlockConfig';
import AskFieldUpdateAnsweredLogicBlockConfig from './configs/AskFieldUpdateAnsweredLogicBlockConfig';
import AskFieldUpdateLogicBlockConfig from './configs/AskFieldUpdateLogicBlockConfig';
import AutoCloseLogicBlockConfig from './configs/AutoCloseLogicBlockConfig';
import AutonomousCreatedFromFormLogicBlockConfig from './configs/AutonomousCreatedFromFormLogicBlockConfig';
import AutonomousFieldChangedLogicBlockConfig from './configs/AutonomousFieldChangedLogicBlockConfig';
import AutonomousInterfaceSubmittedLogicBlockConfig from './configs/AutonomousInterfaceSubmittedLogicBlockConfig';
import AutonomousItemCreatedLogicBlockConfig from './configs/AutonomousItemCreatedLogicBlockConfig';
import AutonomousLogicBlockConfig from './configs/AutonomousLogicBlockConfig';
import AutonomousScheduleLogicBlockConfig from './configs/AutonomousScheduleLogicBlockConfig';
import BotButtonPressedLogicBlockConfig from './configs/BotButtonPressedLogicBlockConfig';
import BotThreadReplyLogicBlockConfig from './configs/BotThreadReplyLogicBlockConfig';
import DelayLogicBlockConfig from './configs/DelayLogicBlockConfig';
import FrontDoorActionLogicBlockConfig from './configs/FrontDoorActionLogicBlockConfig';
import GatherUpdateLogicBlockConfig from './configs/GatherUpdateLogicBlockConfig';
import HttpUploadLogicBlockConfig from './configs/HttpUploadLogicBlockConfig';
import InnerAutonomousCreatedFromFormLogicBlockConfig from './configs/InnerAutonomousCreatedFromFormLogicBlockConfig';
import InnerAutonomousFieldChangedLogicBlockConfig from './configs/InnerAutonomousFieldChangedLogicBlockConfig';
import InnerAutonomousItemCreatedLogicBlockConfig from './configs/InnerAutonomousItemCreatedLogicBlockConfig';
import InnerAutonomousLogicBlockConfig from './configs/InnerAutonomousLogicBlockConfig';
import InnerAutonomousScheduleLogicBlockConfig from './configs/InnerAutonomousScheduleLogicBlockConfig';
import ManualFieldUpdateLogicBlockConfig from './configs/ManualFieldUpdateLogicBlockConfig';
import ManualNextStepsLogicBlockConfig from './configs/ManualNextStepsLogicBlockConfig';
import ManualUpdateOwnerLogicBlockConfig from './configs/ManualUpdateOwnerLogicBlockConfig';
import MonitorTracksAutonomousItemCreatedLogicBlockConfig from './configs/MonitorTracksAutonomousItemCreatedLogicBlockConfig';
import MonitorTracksItemCreatedLogicBlockConfig from './configs/MonitorTracksItemCreatedLogicBlockConfig';
import MonitorTracksLogicBlockConfig from './configs/MonitorTracksLogicBlockConfig';
import NextStepsLogicBlockConfig from './configs/NextStepsLogicBlockConfig';
import NlpBranchAnyOfTheRestLogicBlockConfig from './configs/NlpBranchAnyOfTheRestLogicBlockConfig';
import NlpBranchDefaultLogicBlockConfig from './configs/NlpBranchDefaultLogicBlockConfig';
import NlpBranchLogicBlockConfig from './configs/NlpBranchLogicBlockConfig';
import NlpProcessorLogicBlockConfig from './configs/NlpProcessorLogicBlockConfig';
import OcrConversionLogicBlockConfig from './configs/OcrConversionLogicBlockConfig';
import OutgoingWebhookLogicBlockConfig from './configs/OutgoingWebhookLogicBlockConfig';
import PerformIntegrationActionLogicBlockConfig from './configs/PerformIntegratiobActionLogicBlockConfig';
import PersonInquiryLogicBlockConfig from './configs/PersonInquiryLogicBlockConfig';
import SendEmailLogicBlockConfig from './configs/SendEmailLogicBlockConfig';
import SendFormAnsweredLogicBlockConfig from './configs/SendFormAnsweredLogicBlockConfig';
import SendFormLogicBlockConfig from './configs/SendFormLogicBlockConfig';
import SendItemInterfaceLogicBlockConfig from './configs/SendItemInterfaceLogicBlockConfig';
import SendNotificationLogicBlockConfig from './configs/SendNotificationLogicBlockConfig';
import StorageUploadLogicBlockConfig from './configs/StorageUploadLogicBlockConfig';
import SyncInnerMatchedEntityItemsLogicBlockConfig from './configs/SyncInnerMatchedEntityItemsLogicBlockConfig';
import TextExtractorLogicBlockConfig from './configs/TextExtractorLogicBlockConfig';
import TonkeanActionLogicBlockConfig from './configs/TonkeanActionLogicBlockConfig';
import TrainTriggerLogicBlockConfig from './configs/TrainTriggerLogicBlockConfig';
import UnknownLogicBlockConfig from './configs/UnknownLogicBlockConfig';
import UserInterfaceButtonClickedLogicBlockConfig from './configs/UserInterfaceButtonClickedLogicBlockConfig';
import type LogicBlockConfigDefinition from './LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

// Make sure the key matches the type property of the value
const logicBlockConfigs: Record<LogicBlockConfigType, LogicBlockConfigDefinition> = {
    [LogicBlockConfigType.UNKNOWN]: UnknownLogicBlockConfig,
    [LogicBlockConfigType.PERSON_INQUIRY]: PersonInquiryLogicBlockConfig,
    [LogicBlockConfigType.SEND_NOTIFICATION]: SendNotificationLogicBlockConfig,
    [LogicBlockConfigType.APPROVAL_CYCLE]: ApprovalCycleLogicBlockConfig,
    [LogicBlockConfigType.NEXT_STEPS]: NextStepsLogicBlockConfig,
    [LogicBlockConfigType.MANUAL_NEXT_STEPS]: ManualNextStepsLogicBlockConfig,
    [LogicBlockConfigType.SYNC_INNER_MATCHED_ENTITY]: SyncInnerMatchedEntityItemsLogicBlockConfig,
    [LogicBlockConfigType.MANUAL_FIELD_UPDATE]: ManualFieldUpdateLogicBlockConfig,
    [LogicBlockConfigType.TRAIN_TRIGGER]: TrainTriggerLogicBlockConfig,
    [LogicBlockConfigType.OCR_CONVERSION]: OcrConversionLogicBlockConfig,
    [LogicBlockConfigType.TEXT_EXTRACTOR]: TextExtractorLogicBlockConfig,
    [LogicBlockConfigType.MANUAL_OWNER_UPDATE]: ManualUpdateOwnerLogicBlockConfig,
    [LogicBlockConfigType.PERFORM_INTEGRATION_ACTION]: PerformIntegrationActionLogicBlockConfig,
    [LogicBlockConfigType.SEND_EMAIL]: SendEmailLogicBlockConfig,
    [LogicBlockConfigType.DELAY]: DelayLogicBlockConfig,
    [LogicBlockConfigType.OUTGOING_WEBHOOK]: OutgoingWebhookLogicBlockConfig,
    [LogicBlockConfigType.HTTP_UPLOAD]: HttpUploadLogicBlockConfig,
    [LogicBlockConfigType.STORAGE_UPLOAD]: StorageUploadLogicBlockConfig,
    [LogicBlockConfigType.ASK_FIELD_UPDATE]: AskFieldUpdateLogicBlockConfig,
    [LogicBlockConfigType.ASK_FIELD_UPDATE_ANSWERED]: AskFieldUpdateAnsweredLogicBlockConfig,
    [LogicBlockConfigType.SEND_FORM]: SendFormLogicBlockConfig,
    [LogicBlockConfigType.SEND_FORM_ANSWERED]: SendFormAnsweredLogicBlockConfig,
    [LogicBlockConfigType.SEND_ITEM_INTERFACE]: SendItemInterfaceLogicBlockConfig,
    [LogicBlockConfigType.AUTONOMOUS]: AutonomousLogicBlockConfig,
    [LogicBlockConfigType.AUTONOMOUS_ITEM_CREATED]: AutonomousItemCreatedLogicBlockConfig,
    [LogicBlockConfigType.AUTONOMOUS_CREATED_FROM_FORM]: AutonomousCreatedFromFormLogicBlockConfig,
    [LogicBlockConfigType.AUTONOMOUS_INTERFACE_SUBMITTED]: AutonomousInterfaceSubmittedLogicBlockConfig,
    [LogicBlockConfigType.AUTONOMOUS_MATCH_CONDITIONS]: AutonomousLogicBlockConfig,
    [LogicBlockConfigType.AUTONOMOUS_SCHEDULE]: AutonomousScheduleLogicBlockConfig,
    [LogicBlockConfigType.AUTONOMOUS_FIELD_CHANGED]: AutonomousFieldChangedLogicBlockConfig,
    [LogicBlockConfigType.INNER_AUTONOMOUS]: InnerAutonomousLogicBlockConfig,
    [LogicBlockConfigType.INNER_AUTONOMOUS_ITEM_CREATED]: InnerAutonomousItemCreatedLogicBlockConfig,
    [LogicBlockConfigType.INNER_AUTONOMOUS_CREATED_FROM_FORM]: InnerAutonomousCreatedFromFormLogicBlockConfig,
    [LogicBlockConfigType.INNER_AUTONOMOUS_SCHEDULE]: InnerAutonomousScheduleLogicBlockConfig,
    [LogicBlockConfigType.INNER_AUTONOMOUS_FIELD_CHANGED]: InnerAutonomousFieldChangedLogicBlockConfig,
    [LogicBlockConfigType.AUTO_CLOSE]: AutoCloseLogicBlockConfig,
    [LogicBlockConfigType.USER_INTERFACE_BUTTON_CLICK]: UserInterfaceButtonClickedLogicBlockConfig,
    [LogicBlockConfigType.BOT_BUTTON_PRESSED]: BotButtonPressedLogicBlockConfig,
    [LogicBlockConfigType.BOT_THREAD_REPLY]: BotThreadReplyLogicBlockConfig,
    [LogicBlockConfigType.GATHER_UPDATE]: GatherUpdateLogicBlockConfig,
    [LogicBlockConfigType.MONITOR_TRACKS]: MonitorTracksLogicBlockConfig,
    [LogicBlockConfigType.MONITOR_TRACKS_ITEM_CREATED]: MonitorTracksItemCreatedLogicBlockConfig,
    [LogicBlockConfigType.MONITOR_TRACKS_AUTONOMOUS_ITEM_CREATED]: MonitorTracksAutonomousItemCreatedLogicBlockConfig,
    [LogicBlockConfigType.TONKEAN_ACTION]: TonkeanActionLogicBlockConfig,
    [LogicBlockConfigType.NLP_PROCESSOR]: NlpProcessorLogicBlockConfig,
    [LogicBlockConfigType.NLP_BRANCH]: NlpBranchLogicBlockConfig,
    [LogicBlockConfigType.NLP_BRANCH_DEFAULT]: NlpBranchDefaultLogicBlockConfig,
    [LogicBlockConfigType.NLP_BRANCH_ANY_OF_THE_REST]: NlpBranchAnyOfTheRestLogicBlockConfig,
    [LogicBlockConfigType.FRONT_DOOR_ACTION]: FrontDoorActionLogicBlockConfig,
};

export default logicBlockConfigs;
