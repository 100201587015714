<div class="activity-digest-email-settings-modal common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn svg-icon-lg" ng-click="cancel()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold margin-bottom-md margin-top-lg flex-vmiddle">
        <span class="">Activity Email Notifications</span>
        <span
            class="common-size-xxs common-color-success save-alert margin-left-xlg-no-xs"
            ng-class="{ 'mod-visible': data.saved }"
        >
            Saved!
        </span>
    </h1>

    <!-- Activity explanation -->
    <div class="common-color-grey common-size-xxs margin-bottom-md">
        Subscribe to an email with a roll-up of activities such as status and data changes.
    </div>

    <!-- Recurrence options -->
    <div class="margin-bottom-xlg">
        <tnk-radio-button
            radio-id="recurrence-type-none"
            radio-name="recurrence-type"
            radio-label="Don't send email notifications"
            radio-value="None"
            model="data.recurrenceType"
            is-required="true"
            on-click="onRecurrenceTypeSelected(onClickParam)"
            on-click-param="('None')"
            classes="margin-top"
        ></tnk-radio-button>
        <tnk-radio-button
            radio-id="recurrence-type-hourly"
            radio-name="recurrence-type"
            radio-label="Send once every hour (if there’s new activity)"
            radio-value="Hourly"
            model="data.recurrenceType"
            is-required="true"
            on-click="onRecurrenceTypeSelected(onClickParam)"
            on-click-param="('Hourly')"
            classes="margin-top"
        ></tnk-radio-button>
        <tnk-radio-button
            radio-id="recurrence-type-every3Hours"
            radio-name="recurrence-type"
            radio-label="Send once every 3 hours (if there’s new activity)"
            radio-value="Every3Hours"
            model="data.recurrenceType"
            is-required="true"
            on-click="onRecurrenceTypeSelected(onClickParam)"
            on-click-param="('Every3Hours')"
            classes="margin-top"
        ></tnk-radio-button>
        <tnk-radio-button
            radio-id="recurrence-type-daily"
            radio-name="recurrence-type"
            radio-label="Send once a day (if there’s new activity)"
            radio-value="Daily"
            model="data.recurrenceType"
            is-required="true"
            on-click="onRecurrenceTypeSelected(onClickParam)"
            on-click-param="('Daily')"
            classes="margin-top"
        ></tnk-radio-button>

        <!-- Error -->
        <div class="common-color-danger margin-top-lg" ng-if="data.error">{{ data.error }}</div>
    </div>

    <!-- Lists subscriptions part -->
    <div class="margin-bottom-xlg list-subscribe-box" ng-if="data.recurrenceType !== 'None'">
        <!-- Title for list part -->
        <div class="common-bold margin-top-xs margin-left flex-vmiddle">
            <!-- Title -->
            <span class="flex-grow">Follow activities for these lists</span>

            <!-- Search icon -->
            <span class="svg-icon-sm flex-no-shrink">
                <tnk-icon src="../../../../apps/tracks/images/icons/search.svg"></tnk-icon>
            </span>

            <!-- Search textbox -->
            <input
                type="text"
                class="form-control common-width-20 common-no-border common-no-outline flex-no-shrink"
                placeholder="Search..."
                ng-model="data.groupsSearchText"
            />
        </div>
        <hr class="margin-top-xs margin-bottom common-separator mod-darker" />

        <div class="list-subscribe-box-content">
            <!-- Possibility to subscribe for each list -->
            <div
                ng-repeat="group in pm.groups | filter:{name: data.groupsSearchText} "
                ng-if="!data.loadingSubscribedGroups && !data.errorLoadingSubscribedGroups"
                class="margin-bottom"
            >
                <div class="flex-vmiddle">
                    <!-- List name -->
                    <div class="flex-grow margin-left flex-vmiddle">
                        <!-- List actual name -->
                        <span class="margin-right-xs common-ellipsis group-name">{{ group.name }}</span>
                        <!-- Private group lock indication -->
                        <i
                            class="svg-icon-xs svg-icon-align-text-base common-single-lineheight"
                            ng-if="group.visibilityType && group.visibilityType === 'PRIVATE'"
                        >
                            <tnk-icon src="../../../../apps/tracks/images/icons/lock.svg"></tnk-icon>
                        </i>
                    </div>

                    <!-- Error Indication -->
                    <div
                        class="flex-no-shrink common-color-danger margin-right-xs"
                        ng-if="data.errorGroupSubscriptionMap[group.id]"
                    >
                        {{ data.errorGroupSubscriptionMap[group.id] }}
                    </div>
                    <!-- Toggle subscribe to group on & off -->
                    <tnk-toggle
                        data="group"
                        size="sm-long"
                        class="margin-right"
                        on-label="('ON')"
                        off-label="OFF"
                        is-active="data.subscribedGroupsSet[group.id]"
                        on-toggle-click="toggleSubscribeToGroup(data)"
                    ></tnk-toggle>
                </div>

                <hr class="common-separator margin-top margin-bottom-none" ng-hide="$last" />
            </div>
        </div>

        <!-- Loading for groups -->
        <div ng-if="data.loadingSubscribedGroups && !data.errorLoadingSubscribedGroups">
            <i class="loading-small margin-right-xs"></i>
            <span>Loading lists...</span>
        </div>

        <!-- Error for loading groups -->
        <div class="common-color-danger" ng-if="data.errorLoadingSubscribedGroups && !data.loadingSubscribedGroups">
            <span class="margin-right-xs">{{ data.errorLoadingSubscribedGroups }}</span>
            <a ng-click="reloadSubscribedGroups()">Reload</a>
        </div>
    </div>
</div>
