<div class="search-triggers-popover">
    <tnk-search-triggers
        group-id="data.filter.group.id"
        can-clear="true"
        on-trigger-selected="onFilterByTrigger(selectedSimplifiedTrigger, isInit)"
        should-set-default-trigger="false"
        default-triggers-amount="20"
        selected-trigger-id="data.filter.simplifiedTrigger.id"
        only-roots="true"
    ></tnk-search-triggers>
</div>
