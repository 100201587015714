import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useMemo } from 'react';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { classNames } from '@tonkean/utils';

interface Props {
    realTrack: any;
    expandTrackToggle(): void;
    showDone: boolean | undefined;
    enableInnerItemsToggle?: boolean;
}

const TrackBullet: React.FC<Props> = ({
    realTrack,
    expandTrackToggle,
    showDone: showDoneProp,
    enableInnerItemsToggle = true,
}) => {
    const [
        doneInnerItemsCount,
        innerItemsCount,
        showCompletedInnerItems,
        hasRelatedInitiatives,
        expended,
        status,
        stateColor,
        isDraftInitiative,
        group,
        stateText,
    ] = useAngularWatch(
        () => realTrack.doneInnerItemsCount,
        () => realTrack.innerItemsCount,
        () => realTrack.showCompletedInnerItems,
        () => realTrack.hasRelatedInitiatives,
        () => realTrack.expended,
        () => realTrack.status,
        () => realTrack.stateColor,
        () => realTrack.isDraftInitiative,
        () => realTrack.group,
        () => realTrack.stateText,
    );

    const workflowVersionManager = useAngularService('workflowVersionManager');
    const { data: draftVersion } = useAsyncMethod(workflowVersionManager, 'getDraftVersion', group.id);
    const { data: publishedVersion } = useAsyncMethod(workflowVersionManager, 'getPublishedVersion', group.id);

    const color = useMemo(() => {
        // Need to know the workflow version, undefined doesn't mean draft.
        if (isDraftInitiative === undefined) {
            return;
        }
        const trackVersion = isDraftInitiative ? draftVersion : publishedVersion;

        if (!trackVersion) {
            return;
        }

        if (trackVersion?.['stateLabelToStateMap']) {
            return trackVersion['stateLabelToStateMap'][stateText]?.color ?? stateColor;
        } else {
            return stateColor;
        }
    }, [isDraftInitiative, draftVersion, publishedVersion, stateText, stateColor]);

    const showDone = showCompletedInnerItems || showDoneProp;

    const disableToggle = useMemo(
        () => hasRelatedInitiatives && doneInnerItemsCount === innerItemsCount && !showDone && !expended,
        [hasRelatedInitiatives, doneInnerItemsCount, innerItemsCount, showDone, expended],
    );

    const isFutureStatus = useMemo(() => status !== 'FUTURE', [status]);

    return (
        <Tooltip content="All items are completed, remove filter to toggle" disabled={!disableToggle}>
            <i
                className={classNames(
                    'flex-no-shrink common-color-white track-item-bullet',
                    enableInnerItemsToggle && {
                        'chevron mod-full chevron-bottom': hasRelatedInitiatives && expended,
                        'chevron mod-full chevron-right': hasRelatedInitiatives && !expended,
                        'mod-disabled': hasRelatedInitiatives && disableToggle,
                        pointer: hasRelatedInitiatives && !disableToggle,
                    },
                )}
                data-automation={classNames('track-bullet', {
                    ' expended-inner-items': hasRelatedInitiatives && expended,
                    ' collapsed-inner-items': hasRelatedInitiatives && !expended,
                })}
                style={isFutureStatus ? { backgroundColor: color } : {}}
                onClick={expandTrackToggle}
            />
        </Tooltip>
    );
};

export default TrackBullet;
