import type { FormikProps } from 'formik/dist/types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import WorkerRunsFilterDateRange from './WorkerRunsFilterDateRange';
import WorkerRunsFilterStages from './WorkerRunsFilterStages';
import WorkerRunsFiltersTrigger from './WorkerRunsFiltersTrigger';
import WorkerRunsSearchBox from './WorkerRunsSearchBox';
import { ReactComponent as FilterIcon } from '../../../../../../images/icons/history/filter.svg';
import { ReactComponent as RefreshIcon } from '../../../../../../images/icons/history/refresh.svg';
import { ReactComponent as XIcon } from '../../../../../../images/icons/x.svg';
import type WorkerRunDateRangeFilter from '../../../entities/WorkerRunDateRangeFilter';
import type WorkerRunsFilter from '../../../entities/WorkerRunsFilter';
import type WorkerRunStagesFilter from '../../../entities/WorkerRunStagesFilter';
import originatedWorkerRunState from '../../../states/originatedWorkerRunState';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { WorkerRunStage } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { classNames, colorSvg } from '@tonkean/utils';

const Form = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    padding: 20px 20px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const Title = styled.h3`
    margin: 0;
    font-weight: normal;
    font-size: ${FontSize.LARGE_16};
    line-height: 19px;
    color: ${Theme.colors.gray_800};
`;

const LoadingCircle = styled.div.attrs(({ className }) => ({ className: classNames(className, 'loading') }))`
    margin-left: 5px;
`;

const SearchBoxContainer = styled.div`
    flex-grow: 1;
    margin-right: 10px;
`;

const SearchFilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RefreshButton = styled(Button)`
    margin-left: 20px;
`;

const FilteredByInnerFlowRunsMessage = styled.div`
    font-size: ${FontSize.XSMALL_10};
    display: inline-flex;
    margin-top: 15px;
    align-items: center;
    color: ${Theme.colors.primary};
    border-radius: 100px;
    padding: 2px;
    border: 1px solid ${Theme.colors.primary};
    background: rgba(63, 167, 184, 0.07);
`;

const FilterIconWrapper = styled(FilterIcon)`
    display: inline-flex;
    margin-left: 8px;
    margin-right: 6px;
    width: 10px;
    height: 10px;
    ${colorSvg(Theme.colors.primary)};
`;

const H4 = styled.h4`
    margin: 30px 0 5px;
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    line-height: 10px;
    color: ${Theme.current.palette.history.workerRunsList.tableHeaderColor};
`;

const XCloseIcon = styled(XIcon)`
    display: inline-flex;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 6px;
    width: 8px;
    height: 8px;
    ${colorSvg(Theme.colors.primary)}
`;

interface Props {
    stats: Record<WorkerRunStage, number>;
    filters: WorkerRunsFilter;
    workerRunStagesFilter: WorkerRunStagesFilter;
    workerRunDateRangeFilter: WorkerRunDateRangeFilter;
    searchTerm: string;
    onFiltersChange(filters: WorkerRunsFilter): void;
    onWorkerRunStagesFilterChange(workerRunStagesFilter: WorkerRunStagesFilter): void;
    onWorkerRunDateRangeFilterChange(workerRunDateRangeFilter: WorkerRunDateRangeFilter): void;
    onSearchTermChange(searchTerm: string): void;
    reloading: boolean;
    reload(autoReloading?: boolean): void;
    filtersChangeCount: number;
}

const WorkerRunsForm: React.FC<Props> = ({
    filters,
    searchTerm,
    onFiltersChange,
    onSearchTermChange,
    reload,
    reloading,
    filtersChangeCount,
    stats,
    workerRunDateRangeFilter,
    onWorkerRunDateRangeFilterChange,
    workerRunStagesFilter,
    onWorkerRunStagesFilterChange,
}) => {
    const [originatedWorkerRun, setOriginatedWorkerRun] = useRecoilState(originatedWorkerRunState);
    const [{ data: customTrigger }, getCustomTrigger] = useLazyTonkeanService('getCustomTrigger');
    const rangeFormikRef = useRef<FormikProps<any>>(null);

    useEffect(() => {
        rangeFormikRef.current?.resetForm({ values: { ...workerRunDateRangeFilter } });
    }, [workerRunDateRangeFilter]);

    useEffect(() => {
        if (originatedWorkerRun?.customTriggerId && originatedWorkerRun.workerRun?.workflowVersionId) {
            getCustomTrigger(originatedWorkerRun.workerRun.workflowVersionId, originatedWorkerRun.customTriggerId);
        }
    }, [getCustomTrigger, originatedWorkerRun?.customTriggerId, originatedWorkerRun?.workerRun.workflowVersionId]);

    const onWorkerRunStagesFilterChanged = useCallback(
        (newFilters: WorkerRunStagesFilter) => {
            onWorkerRunDateRangeFilterChange({
                dateFrom: workerRunDateRangeFilter.dateFrom,
                dateTo: workerRunDateRangeFilter.dateTo,
            });
            onWorkerRunStagesFilterChange(newFilters);
        },
        [
            onWorkerRunDateRangeFilterChange,
            onWorkerRunStagesFilterChange,
            workerRunDateRangeFilter.dateFrom,
            workerRunDateRangeFilter.dateTo,
        ],
    );

    return (
        <Form>
            <Header>
                <Title>Module Event History</Title>
                <RefreshButton
                    data-automation="worker-runs-form-refresh-button"
                    onClick={() => reload(false)}
                    size={ButtonSize.SMALL}
                    disabled={reloading}
                    outlined
                >
                    <RefreshIcon />
                    Refresh
                </RefreshButton>
                {reloading && <LoadingCircle />}
            </Header>
            <SearchFilterWrapper>
                <SearchBoxContainer>
                    <WorkerRunsSearchBox searchTerm={searchTerm} onChange={onSearchTermChange} />
                </SearchBoxContainer>

                <WorkerRunsFiltersTrigger
                    filters={filters}
                    filtersChangeCount={filtersChangeCount}
                    onChange={(newFilters) => {
                        onWorkerRunDateRangeFilterChange({ ...workerRunDateRangeFilter });
                        onFiltersChange(newFilters);
                    }}
                />
            </SearchFilterWrapper>

            {originatedWorkerRun && customTrigger && (
                <FilteredByInnerFlowRunsMessage>
                    <FilterIconWrapper>
                        <FilterIcon />
                    </FilterIconWrapper>
                    Filtered only inner items of {customTrigger.displayName} by Flow Run
                    {(originatedWorkerRun.workerRun.customTriggerNames?.length || 0) > 1 ? 's - ' : ' - '}
                    {originatedWorkerRun.workerRun.customTriggerNames?.join(', ')}
                    <XCloseIcon
                        onClick={() => {
                            setOriginatedWorkerRun(undefined);
                        }}
                    />
                </FilteredByInnerFlowRunsMessage>
            )}

            <H4>Date:</H4>
            <WorkerRunsFilterDateRange
                onChange={onWorkerRunDateRangeFilterChange}
                initialRange={workerRunDateRangeFilter}
                innerRef={rangeFormikRef}
                alignLeft
            />

            <H4>View Event Types:</H4>
            <WorkerRunsFilterStages
                stats={stats}
                filters={workerRunStagesFilter}
                onChange={onWorkerRunStagesFilterChanged}
            />
        </Form>
    );
};

export default React.memo(WorkerRunsForm);
