import { ValidationType } from '@tonkean/items-grid';
import type { InitiativeRowData } from '@tonkean/items-grid';
import type { FieldValidation } from '@tonkean/items-grid';
import type { FieldValidationParams } from '@tonkean/items-grid';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { SpecialFieldsKey } from '@tonkean/tonkean-entities';

export interface FieldDateRangeValidationParams extends FieldValidationParams {
    min: Date | undefined;
    max: Date | undefined;
}

class FieldDateRangeValidation implements FieldValidation<FieldDateRangeValidationParams> {
    fieldDefinitionKey: FieldDefinitionKey;
    type: ValidationType;
    params: FieldDateRangeValidationParams;

    isValid(data: InitiativeRowData | undefined): boolean {
        const fieldDefinitionKey =
            this.fieldDefinitionKey === SpecialFieldsKey.DUE_DATE ? 'dueDate' : this.fieldDefinitionKey;

        if (!data) {
            return false;
        }

        const columnValue = data[fieldDefinitionKey];

        // If no value present, we consider it valid
        if (!columnValue) {
            return true;
        }

        const dateObject = new Date(columnValue as string);

        if (!Number.isNaN(dateObject.getTime())) {
            const date: Date = dateObject;

            // date is between min and max, but only if they are configured
            return (!this.params.max || date <= this.params.max) && (!this.params.min || date >= this.params.min);
        }
        return false;
    }

    constructor(fieldDefinitionKey: FieldDefinitionKey, params: FieldDateRangeValidationParams) {
        this.params = {
            min: params.min ? new Date(params.min) : undefined,
            max: params.max ? new Date(params.max) : undefined,
        };
        this.fieldDefinitionKey = fieldDefinitionKey;
        this.type = ValidationType.DATE_RANGE;
    }
}

export default FieldDateRangeValidation;
