import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function GoogleDriveCustomActionsDefinitionCtrl(
    $scope,
    projectManager,
    integrationsConsts,
    utils,
) {
    const ctrl = this;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,

        filterByIntegrations: ['GOOGLECLOUD'],

        selectedRole: null,
        roleOptions: [
            {
                // Keep as first, because this is default.
                key: 'reader',
                label: 'Reader',
            },
            {
                key: 'writer',
                label: 'Writer',
            },
        ],

        loading: false,

        columnsObjects: [],
        alphabet: [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ],

        docReplacementActions: [],
        fileEntityMetadata: integrationsConsts.getEntitiesMetadata()['GOOGLEDRIVE']['FILE'],
        moveFromAllParentFolders: false,

        possibleMimeTypes: ['text/plain', 'text/html'],
    };

    ctrl.$onInit = function () {
        if ($scope.data.configuredLogic && $scope.data.definition) {
            initializeEditMode();
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onContentTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.content = originalExpression;
        $scope.data.definition.evaluatedContent = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onEmailTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.email = originalExpression;
        $scope.data.definition.evaluatedEmail = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRoleSelected = function (role) {
        $scope.data.definition.role = role.key;
        $scope.data.selectedRole = role;
        $scope.onDefinitionChanged(true);
    };

    $scope.onDestinationFolderIdTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.destinationFolderId = originalExpression;
        $scope.data.definition.evaluatedDestinationFolderId = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNewFileNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.newFileName = originalExpression;
        $scope.data.definition.evaluatedNewFileName = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPdfFileNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.pdfFileName = originalExpression;
        $scope.data.definition.evaluatedPdfFileName = evaluatedExpression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSourceFolderIdTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.sourceFolderId = originalExpression;
        $scope.data.definition.evaluatedSourceFolderId = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSheetNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.sheetName = originalExpression;
        $scope.data.definition.evaluatedSheetName = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSpreadsheetNameTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.spreadsheetName = originalExpression;
        $scope.data.definition.evaluatedSpreadsheetName = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPastedFileNameTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.pastedFileName = originalExpression;
        $scope.data.definition.evaluatedPastedFileName = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onParentFolderIdTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.parentFolderId = originalExpression;
        $scope.data.definition.evaluatedParentFolderId = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTemplateIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.templateIdExpression = expression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTemplateReplaceTextTonkeanExpressionChanged = function (expression, shouldSaveLogic, replacementAction) {
        replacementAction.replaceTextExpression = expression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAttachedEmailMessageExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.definition.attachedEmailMessage = originalExpression;
        $scope.data.definition.evaluatedAttachedEmailMessage = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onMimeTypeChanged = function () {
        $scope.onDefinitionChanged(true);
    };

    $scope.onProjectIntegrationSelected = function (selectedProjectIntegration) {
        if (selectedProjectIntegration) {
            $scope.data.definition.googleCloudProjectIntegrationId = selectedProjectIntegration.id;
        } else {
            $scope.data.definition.googleCloudProjectIntegrationId = null;
        }

        $scope.onDefinitionChanged(true);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        if ($scope.data.docReplacementActions) {
            $scope.data.definition.replacementActions = $scope.data.docReplacementActions.filter(
                (action) =>
                    action.findText ||
                    (action.replaceTextExpression && action.replaceTextExpression.evaluatedExpression) ||
                    action.isMatchCase,
            );
        }

        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.addColumnObject = function () {
        $scope.data.columnsObjects.push({
            nameText: '',
            evaluatedNameText: '',
            valueText: '',
            evaluatedValueText: '',
        });
    };

    $scope.removeColumnObject = function (index) {
        $scope.data.columnsObjects.splice(index, 1);

        $scope.onActionsChanged(true);
    };

    /**
     * Occurs on change of the expression.
     */
    $scope.onNameTonkeanExpressionChangedForColumns = function (
        originalExpression,
        evaluatedExpression,
        index,
        shouldSaveLogic,
    ) {
        $scope.data.columnsObjects[index].nameText = originalExpression;
        $scope.data.columnsObjects[index].evaluatedNameText = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs on change of the expression.
     */
    $scope.onValueTonkeanExpressionChangedForColumns = function (
        originalExpression,
        evaluatedExpression,
        index,
        shouldSaveLogic,
    ) {
        $scope.data.columnsObjects[index].valueText = originalExpression;
        $scope.data.columnsObjects[index].evaluatedValueText = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.addReplacementAction = function () {
        $scope.data.docReplacementActions.push({
            findText: '',
            replaceTextExpression: {},
            isMatchCase: false,
        });
    };

    $scope.onMatchCaseToggled = function (action, value) {
        action.isMatchCase = value;
        $scope.onDefinitionChanged(true);
    };

    $scope.onDestinationFolderExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationFolderIdExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFileNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fileNameExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Convert File Type
     */
    $scope.onConvertDestinationIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.convertDestinationIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onConvertNewFileNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.convertNewFileNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onConvertMimetypeExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.convertMimetypeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onActionsChanged = function (shouldSave) {
        if (ctrl.onDefinitionChanged) {
            switch ($scope.data.definition.customActionKey) {
                case 'CREATE_SHEET':
                    if (!$scope.data.columnsObjects) {
                        $scope.data.columnsObjects = [];
                    }

                    $scope.data.definition.columns = $scope.data.columnsObjects.map((columnToAdd) => {
                        return {
                            nameText: columnToAdd.nameText,
                            evaluatedNameText: columnToAdd.evaluatedNameText,
                            valueText: columnToAdd.valueText,
                            evaluatedValueText: columnToAdd.evaluatedValueText,
                        };
                    });
                    break;

                case 'MOVE_FILE':
                    $scope.data.definition.moveFromAllParentFolders = $scope.data.moveFromAllParentFolders;
                    break;
            }

            $scope.onDefinitionChanged(shouldSave);
        }
    };

    /**
     * Occurs when the extended match configuration is changed.
     */
    $scope.onExtendedMatchConfigurationChanged = function (extendedMatchConfiguration, shouldSaveLogic) {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs when remove all parents and move checkbox checked\unchecked.
     */
    $scope.onIfMoveFromAllParentFoldersChanged = function () {
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs when ever the worker file selection component's properties changed.
     */
    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        const attachment = {};
        attachment.fileSourceType = fileSourceType;
        attachment.storageIntegrationId = storageIntegrationId;
        attachment.storageIntegrationActionId = storageIntegrationActionId;
        attachment.extendedMatchConfiguration = extendedMatchConfiguration;
        attachment.url = urlExpression;
        attachment.workerStaticAssetId = workerStaticAssetId;
        attachment.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        attachment.parametersValues = parametersValues;

        $scope.data.definition.attachment = attachment;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Copy Rows action
     */

    $scope.onSourceSpreadsheetIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.sourceSpreadsheetIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSourceSheetNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.sourceSheetNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDestinationSpreadsheetIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationSpreadsheetIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDestinationSheetNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationSheetNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSourceStartRowNumberExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.sourceStartRowNumberExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (
            customTriggerAction &&
            customTriggerAction.customTriggerActionDefinition &&
            customTriggerAction.customTriggerActionDefinition.performedActionDefinition
        ) {
            const definition = customTriggerAction.customTriggerActionDefinition.performedActionDefinition;
            if (definition.role) {
                $scope.data.selectedRole = utils.findFirst(
                    $scope.data.roleOptions,
                    (role) => role.key === definition.role,
                );
            }
        }

        switch ($scope.data.definition.customActionKey) {
            case 'CREATE_SHEET':
                if (!$scope.data.definition.columns) {
                    $scope.data.definition.columns = [];
                }

                $scope.data.columnsObjects = $scope.data.definition.columns.map((column) => {
                    return {
                        nameText: column.nameText,
                        evaluatedNameText: column.evaluatedNameText,
                        valueText: column.valueText,
                        evaluatedValueText: column.evaluatedValueText,
                    };
                });

                break;

            case 'CREATE_DOC_FROM_TEMPLATE':
                if (!$scope.data.definition.replacementActions) {
                    $scope.data.definition.replacementActions = [];
                }

                $scope.data.docReplacementActions = $scope.data.definition.replacementActions;

                if (!$scope.data.docReplacementActions.length) {
                    $scope.addReplacementAction();
                }

                break;
            case 'MOVE_FILE':
                $scope.data.moveFromAllParentFolders = $scope.data.definition.moveFromAllParentFolders;
                break;
            case 'UPLOAD_FILE_FROM_URL':
                // Backwards compatibility Code. Before the change, the user could only upload files from url.
                // After the change, using worker-file-selection component the user can also use storage provider.
                // Thus we need to copy the old URL expression into our new worker-file-selection definition.
                if (!$scope.data.definition.attachment) {
                    $scope.data.definition.attachment = {
                        url: $scope.data.definition.urlExpression,
                        fileSourceType: 'URL',
                    };
                }
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller(
        'GoogleDriveCustomActionsDefinitionCtrl',
        lateConstructController(GoogleDriveCustomActionsDefinitionCtrl),
    );
