import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import ImageWidgetUploader from './ImageWidgetUploader';
import { useSolutionSiteContext } from '../../../hooks';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import { SolutionSitePageWidgetUrlExpression } from '../../CommonWidgetComponents';
import type ImageWidgetConfiguration from '../ImageWidgetConfiguration';

import { useGetStateParams } from '@tonkean/angular-hooks';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    padding: 20px;
    padding-top: 0px;
`;

const SolutionSitePageImageWidgetEditor: React.FC<ItemWidgetEditorProps<ImageWidgetConfiguration>> = ({
    configuration,
    selectedWidget,
    onSave,
}) => {
    const { solutionSite } = useSolutionSiteContext();
    const [entityVersionType] = useGetStateParams<[WorkflowVersionType]>('env');
    const customFieldsManager = useAngularService('customFieldsManager');

    useEffect(() => {
        customFieldsManager.getGlobalFieldDefinitionsByWorkflowFolderId(
            solutionSite.workflowFolderId,
            entityVersionType,
        );
    }, [customFieldsManager, entityVersionType, solutionSite.workflowFolderId]);

    return (
        <Wrapper>
            <ImageWidgetUploader configuration={configuration} onSave={onSave} selectedWidget={selectedWidget} />
            <SolutionSitePageWidgetUrlExpression
                label="Image Url"
                name="configuration.image.url"
                placeholder="Insert image url"
                dataAutomation="solution-site-page-image-editor-image-url"
            />
        </Wrapper>
    );
};

export default SolutionSitePageImageWidgetEditor;
