import { atom } from 'recoil';

import type { WorkerRun } from '@tonkean/tonkean-entities';

const currentWorkerRunState = atom<WorkerRun | undefined>({
    key: 'currentWorkerRunState',
    default: undefined,
});

export default currentWorkerRunState;
