import {
    type FieldDefinition,
    type FieldDefinitionTargetType,
    FormDefinitionType,
    type TonkeanId,
    type TonkeanType,
    type WorkflowVersion,
    type WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP, getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';
import type TonkeanService from './tonkean-service.service';

/**
 * Manages the custom fields added by the user and their highlighting rules.
 */
class CustomFieldsManager {
    public specialFieldsMap: any;
    public selectedFieldsMap = {};
    public selectedColumnFieldsMap = {};
    public selectedGlobalFieldsMap: Record<TonkeanId<TonkeanType.WORKFLOW_VERSION>, FieldDefinition[]> = {};
    public selectedGlobalFieldsMapByWorkflowFolder = {};
    public selectedTargetFieldsMap = {};
    public selectedVisibleColumnFieldsMap = {};
    public selectedVisibleGlobalFieldsMap = {};
    public selectedVisibleTargetFieldsMap = {};
    public fieldsMap = {};
    /**
     * Used to avoid multiple parallel calls to the server to bring field definitions by the same workflowVersionId
     */
    private workflowVersionIdToFieldDefinitionsPromises: Record<
        TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        ReturnType<TonkeanService['getFieldDefinitions']>
    > = {};

    private workflowVersionIdToSpecificFieldDefinitionPromises: Record<
        TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        Record<TonkeanId<TonkeanType.FIELD_DEFINITION>, ReturnType<TonkeanService['getFieldDefinitionById']>>
    > = {};

    constructor(
        private $rootScope,
        private $q,
        private projectManager,
        private tonkeanService: TonkeanService,
        private utils,
        private workflowVersionManager,
        private liveReportHelper,
    ) {
        this.specialFieldsMap = this.utils.createMapFromArray(getSpecialFieldsForFeatures(true), 'id');
    }

    /**
     * Retrieves the field definitions for given workflow version.
     *
     * @param workflowVersionId - The workflow version id.
     * @param forceServer {boolean=} - if true, it will fetch from server even if the field definitions already cached.
     * @returns {Promise<[]>}
     */
    async getFieldDefinitions(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        forceServer: boolean = true,
    ): Promise<FieldDefinition[]> {
        if (!forceServer) {
            const fieldsInCache = this.selectedFieldsMap[workflowVersionId];
            if (fieldsInCache) {
                return fieldsInCache;
            }
        }

        if (!this.workflowVersionIdToFieldDefinitionsPromises[workflowVersionId]) {
            this.workflowVersionIdToFieldDefinitionsPromises[workflowVersionId] =
                this.tonkeanService.getFieldDefinitions(workflowVersionId);
        }

        return this.workflowVersionIdToFieldDefinitionsPromises[workflowVersionId]!.then((data) => {
            this.cacheFieldDefinitionsForWorkflowVersion(workflowVersionId, data.entities);
            return this.$q.resolve(data.entities);
        }).finally(() => delete this.workflowVersionIdToFieldDefinitionsPromises[workflowVersionId]);
    }

    async getFieldDefinitionById(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>,
        forceServer?: boolean,
    ): Promise<FieldDefinition> {
        if (!forceServer) {
            this.initializeCaches(workflowVersionId);
            const cachedResult = this.getFieldDefinitionFromCachesById(workflowVersionId, fieldDefinitionId);
            if (cachedResult) {
                return cachedResult;
            }

            // There is a chance theres an active fetch for the entire version field definitions, so we check if that returns our result first
            const workflowVersionIdToFieldDefinitionsPromise =
                this.workflowVersionIdToFieldDefinitionsPromises[workflowVersionId];
            if (workflowVersionIdToFieldDefinitionsPromise) {
                const result = await workflowVersionIdToFieldDefinitionsPromise;
                const foundFieldDefinition = result.entities.find((entity) => entity.id === fieldDefinitionId);
                if (foundFieldDefinition) {
                    return foundFieldDefinition;
                }
            }
        }

        if (!this.workflowVersionIdToSpecificFieldDefinitionPromises[workflowVersionId]) {
            this.workflowVersionIdToSpecificFieldDefinitionPromises[workflowVersionId] = {};
        }

        if (
            forceServer ||
            !this.workflowVersionIdToSpecificFieldDefinitionPromises[workflowVersionId]![fieldDefinitionId]
        ) {
            this.workflowVersionIdToSpecificFieldDefinitionPromises[workflowVersionId]![fieldDefinitionId] =
                this.tonkeanService.getFieldDefinitionById(workflowVersionId, fieldDefinitionId);
        }

        return this.workflowVersionIdToSpecificFieldDefinitionPromises[workflowVersionId]![fieldDefinitionId]!;
    }

    async getFieldDefinitionsByIds(
        workflowVersionId: WorkflowVersion['id'],
        workflowVersionType: WorkflowVersionType,
        fieldDefinitionIds: FieldDefinition['id'][],
        projectId: TonkeanId<TonkeanType.PROJECT>,
    ): Promise<FieldDefinition[]> {
        const cachedFieldDefinitions: FieldDefinition[] = [];
        const fieldDefinitionIdsToFetch: FieldDefinition['id'][] = [];

        this.initializeCaches(workflowVersionId);
        fieldDefinitionIds.forEach((fieldDefinitionId) => {
            const fieldDefinition = this.getFieldDefinitionFromCachesById(workflowVersionId, fieldDefinitionId);

            if (fieldDefinition) {
                cachedFieldDefinitions.push(fieldDefinition);
            } else {
                fieldDefinitionIdsToFetch.push(fieldDefinitionId);
            }
        });

        if (fieldDefinitionIdsToFetch.length) {
            return this.getFieldDefinitionsByIdsAndByWorkflowVersionType(
                fieldDefinitionIds,
                projectId,
                workflowVersionType,
            );
        }

        return Promise.resolve(cachedFieldDefinitions);
    }

    getFieldDefinitionsFromCache(workflowVersionId: string): FieldDefinition[] {
        return this.selectedFieldsMap[workflowVersionId];
    }

    /**
     * Get field definitions by workflow version.
     * Note: only load workflow versions from the same type (draft\published) otherwise entity helper might corrupt the data
     */
    getFieldDefinitionsByWorkflowVersions(projectId, workflowVersionIds) {
        return this.paginateFieldDefinitionsByWorkflowVersion(projectId, workflowVersionIds, 1000).then(
            (fieldDefinitions) => {
                const fieldDefinitionsByWorkflowVersion = this.utils.groupBy(
                    fieldDefinitions,
                    (def) => def.workflowVersionId,
                );
                return fieldDefinitionsByWorkflowVersion;
            },
        );
    }

    getFieldDefinitionByIdAndWorkflowVersionType(fieldDefinitionId, workflowVersionType) {
        return this.tonkeanService
            .getFieldDefinitionByIdAndWorkflowVersionType(fieldDefinitionId, workflowVersionType)
            .then((fieldDefinition) => {
                this.addFieldDefinitionToAllCaches(fieldDefinition.workflowVersionId, fieldDefinition);
                return fieldDefinition;
            });
    }

    getFieldDefinitionsByIdsAndByWorkflowVersionType(
        fieldDefinitionIds: FieldDefinition['id'][],
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowVersionType: WorkflowVersionType,
    ): Promise<FieldDefinition[]> {
        return this.tonkeanService
            .getFieldDefinitionsByIdsAndByWorkflowVersionType(fieldDefinitionIds, projectId, workflowVersionType)
            .then((response) => {
                response.fieldDefinitions.forEach((fieldDefinition) => {
                    this.initializeCaches(fieldDefinition.workflowVersionId);
                    this.addFieldDefinitionToAllCaches(fieldDefinition.workflowVersionId, fieldDefinition);
                });

                return response.fieldDefinitions;
            });
    }

    paginateFieldDefinitionsByWorkflowVersion(projectId, workflowVersionsIds, limit, skip = 0, entities: any[] = []) {
        return this.tonkeanService
            .getFieldDefinitionsByWorkflowVersions(projectId, workflowVersionsIds, skip, limit)
            .then((data) => {
                entities.push(...data.entities);
                if (data.entities.length === limit) {
                    return this.paginateFieldDefinitionsByWorkflowVersion(
                        projectId,
                        workflowVersionsIds,
                        limit,
                        skip + limit,
                        entities,
                    );
                } else {
                    return this.$q.resolve(entities);
                }
            });
    }

    /**
     * This function gets all field group names exists by workflowVerison id. Used for autocomplete input.
     */
    async getFieldGroupNames(workflowVersionId) {
        const fieldDefinitionsMap = this.selectedFieldsMap[workflowVersionId];
        const fieldGroups = this.utils
            .objToArray(fieldDefinitionsMap)
            .map((kvp) => kvp.value?.fieldGroupName)
            .filter((fieldGroupName) => !this.utils.isNullOrEmpty(fieldGroupName));

        // We use new Set to get rid of duplicated values
        return [...new Set(fieldGroups)];
    }

    /**
     * This function fetches the linked fields definition according to the given custom triggers array.
     */
    async getCustomTriggersLinkedFieldDefinitions(workflowVersionId, customTriggersIds) {
        const { entities } = await this.tonkeanService.getFieldDefinitions(workflowVersionId, customTriggersIds);

        // Adding the fields definitions to the cache.
        this.addToCache(workflowVersionId, entities);

        return entities;
    }

    /**
     * Resetting and then caching the given field definitions for the given workflow version.
     */
    cacheFieldDefinitionsForWorkflowVersion(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        fieldDefinitions: FieldDefinition[],
        solutionBusinessReportId?: TonkeanId<TonkeanType.SOLUTION_BUSINESS_REPORT>,
        groupId?: TonkeanId<TonkeanType.GROUP>,
    ) {
        if (!workflowVersionId) {
            return;
        }

        // Initialize all caches.
        this.initializeCaches(workflowVersionId);

        // Filling the caches with field definitions.
        for (const fieldDefinition of fieldDefinitions) {
            this.addFieldDefinitionToAllCaches(workflowVersionId, fieldDefinition, solutionBusinessReportId);
        }

        if (groupId) {
            // Sort all cached arrays.
            this.sortInternalCachedArrays(workflowVersionId, groupId, undefined, solutionBusinessReportId);
        }
    }

    rearrangeLiveReportFields(
        groupId,
        orderedFieldDefinitions: FieldDefinition[],
        fieldDefinitionToIsHiddenMap: Record<TonkeanId<TonkeanType.FIELD_DEFINITION>, Record<string, unknown>>,
        targetType,
        workflowVersionType,
        workflowVersionId,
        solutionBusinessReportId,
    ) {
        if (!groupId || !fieldDefinitionToIsHiddenMap || !targetType) {
            return this.$q.resolve();
        }

        if (!orderedFieldDefinitions || !orderedFieldDefinitions.length) {
            orderedFieldDefinitions = [];
        }

        const fieldDefinitionSettings: Record<string, unknown>[] = [];
        for (const fieldDefinition of orderedFieldDefinitions) {
            fieldDefinitionSettings.push({
                fieldDefinitionId: fieldDefinition.id,
                isHidden: fieldDefinitionToIsHiddenMap[fieldDefinition.id] || false,
                index: fieldDefinitionToIsHiddenMap[fieldDefinition.id]?.index,
            });
        }

        return this.tonkeanService
            .overrideLiveReportFieldDefinitions(
                groupId,
                fieldDefinitionSettings,
                targetType,
                workflowVersionType,
                solutionBusinessReportId,
            )
            .then((liveReportFieldDefinitionEntities) =>
                this.handleGroupUpdateAfterReorder(
                    groupId,
                    workflowVersionType,
                    workflowVersionId,
                    targetType,
                    liveReportFieldDefinitionEntities,
                    solutionBusinessReportId,
                ),
            );
    }

    /**
     * Updates the isHidden property of a field definition.
     */
    updateFieldIsHidden(
        fieldDefinitionId,
        isHidden,
        workflowVersionType,
        groupId,
        workflowVersionId,
        targetType,
        solutionBusinessReportId,
    ) {
        return this.liveReportHelper
            .setFieldIsHidden(
                fieldDefinitionId,
                isHidden,
                workflowVersionType,
                groupId,
                targetType,
                solutionBusinessReportId,
            )
            .then((ignored) => {
                // Keeping the visible metrics caches up to date with new information.
                if (workflowVersionId && targetType === 'GLOBAL') {
                    this.updateVisibleFieldsCache(
                        workflowVersionId,
                        this.selectedVisibleTargetFieldsMap[workflowVersionId]['GLOBAL'] || [],
                        targetType,
                        workflowVersionType,
                        groupId,
                    );

                    this.sortFieldDefinitionsArray(
                        groupId,
                        workflowVersionType,
                        solutionBusinessReportId,
                        this.selectedVisibleTargetFieldsMap[workflowVersionId]['GLOBAL'],
                    );

                    this.updateVisibleFieldsCache(
                        workflowVersionId,
                        this.selectedVisibleGlobalFieldsMap[workflowVersionId] || [],
                        targetType,
                        workflowVersionType,
                        groupId,
                    );

                    this.sortFieldDefinitionsArray(
                        groupId,
                        workflowVersionType,
                        solutionBusinessReportId,
                        this.selectedVisibleGlobalFieldsMap[workflowVersionId],
                    );
                } else {
                    const newVisibleField = this.selectedFieldsMap[workflowVersionId].find(
                        (field) => field.id === fieldDefinitionId,
                    );
                    if (!newVisibleField) {
                        return;
                    }
                    this.addFieldDefinitionToVisibleCaches(
                        workflowVersionId,
                        newVisibleField,
                        solutionBusinessReportId,
                        !isHidden,
                    );
                }
            });
    }

    /**
     * Updates the cache of global fields with the new field definition.
     */
    updateFieldDefinitionInCaches(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        fieldDefinition: FieldDefinition,
    ): any {
        if (!workflowVersionId || !fieldDefinition) {
            return;
        }

        const workflowVersion = this.workflowVersionManager.getCachedWorkflowVersion(workflowVersionId);

        this.fieldsMap[workflowVersionId] = fieldDefinition;
        const targetType = fieldDefinition.targetType;
        if (targetType === 'COLUMN') {
            this.replaceFieldDefinitionInArray(this.selectedColumnFieldsMap[workflowVersion.id], fieldDefinition);
            this.replaceFieldDefinitionInArray(
                this.selectedVisibleColumnFieldsMap[workflowVersion.id],
                fieldDefinition,
            );
            this.replaceFieldDefinitionInArray(
                this.selectedVisibleTargetFieldsMap[workflowVersion.id]['COLUMN'],
                fieldDefinition,
            );
        } else {
            this.replaceFieldDefinitionInArray(this.selectedGlobalFieldsMap[workflowVersion.id], fieldDefinition);
            this.replaceFieldDefinitionInArray(
                this.selectedVisibleGlobalFieldsMap[workflowVersion.id],
                fieldDefinition,
            );
            this.replaceFieldDefinitionInArray(
                this.selectedVisibleTargetFieldsMap[workflowVersion.id]['GLOBAL'],
                fieldDefinition,
            );
        }

        this.replaceFieldDefinitionInArray(this.selectedFieldsMap[workflowVersion.id], fieldDefinition);
        this.replaceFieldDefinitionInArray(
            this.selectedTargetFieldsMap[workflowVersion.id][targetType],
            fieldDefinition,
        );

        this.sortInternalCachedArrays(workflowVersionId);
    }

    /**
     * Goes over all the field definitions of a given workflow version and return true if at least one of them is not manual (is external).
     * @param workflowVersionId - the workflow version id to check its fields for external.
     * @returns {boolean} - true if at least one field is external (not manual). False otherwise.
     */
    hasExternalFields(workflowVersionId) {
        if (workflowVersionId && this.selectedFieldsMap && this.selectedFieldsMap[workflowVersionId]) {
            for (let i = 0; i < this.selectedFieldsMap[workflowVersionId].length; i++) {
                const fieldDef = this.selectedFieldsMap[workflowVersionId][i];
                if (fieldDef.type && fieldDef.type.toLowerCase() !== 'manual') {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Change the order of a global or column field.
     * @param {string} direction - left\right the direction this field is being moved
     * @param {number} shiftsAmount - how many spots to move this field? defaults to 1
     */
    moveField(
        groupId,
        fieldDefinitionId,
        targetType,
        direction,
        environment,
        shiftsAmount = 1,
        solutionBusinessReportId,
    ) {
        const workflowVersionId =
            environment === 'DRAFT'
                ? this.workflowVersionManager.getDraftVersionFromCache(groupId).id
                : this.workflowVersionManager.getPublishedVersionFromCache(groupId).id;

        const relevantFieldDefinitions =
            targetType === 'COLUMN'
                ? this.selectedColumnFieldsMap[workflowVersionId]
                : this.selectedGlobalFieldsMap[workflowVersionId];

        const onlyDisplayableFields = relevantFieldDefinitions.filter((field) => !field.systemUtilized);

        const fieldDefinitionIndex = this.utils.indexOf(
            onlyDisplayableFields,
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        if (fieldDefinitionIndex === -1) {
            return this.$q.resolve();
        }

        let rightToFieldDefinition: FieldDefinition | null = null;
        const fieldDefinitionIdToNewIndexMap = {};

        switch (direction) {
            case 'right':
                // If the field to move is the utmost right, no need to do anything
                if (fieldDefinitionIndex === onlyDisplayableFields.length - 1) {
                    return this.$q.resolve();
                }

                rightToFieldDefinition = onlyDisplayableFields[fieldDefinitionIndex + shiftsAmount];
                Object.assign(
                    fieldDefinitionIdToNewIndexMap,
                    this.getUpdatedFieldIdToIndexMap(onlyDisplayableFields, rightToFieldDefinition, fieldDefinitionId),
                );

                break;

            case 'left':
                // If the field to move is the utmost left, no need to do anything
                if (fieldDefinitionIndex === 0) {
                    return this.$q.resolve();
                }
                // If the target index is 0 the 'rightToFieldDefinition' variable should be null
                else if (fieldDefinitionIndex - shiftsAmount !== 0) {
                    rightToFieldDefinition = onlyDisplayableFields[fieldDefinitionIndex - shiftsAmount - 1];
                    Object.assign(
                        fieldDefinitionIdToNewIndexMap,
                        this.getUpdatedFieldIdToIndexMap(
                            onlyDisplayableFields,
                            rightToFieldDefinition,
                            fieldDefinitionId,
                        ),
                    );
                } else {
                    let index = 1;
                    for (const fieldDefinition of onlyDisplayableFields) {
                        if (fieldDefinitionId === fieldDefinition.id) {
                            fieldDefinitionIdToNewIndexMap[fieldDefinition.id] = 0;
                        } else {
                            fieldDefinitionIdToNewIndexMap[fieldDefinition.id] = index;
                            index += 1;
                        }
                    }
                }
                break;
        }

        return this.tonkeanService
            .reorderGroupLiveReportFields(
                groupId,
                fieldDefinitionIdToNewIndexMap,
                environment,
                solutionBusinessReportId,
            )
            .then((liveReportFieldDefinitionEntities) =>
                this.handleGroupUpdateAfterReorder(
                    groupId,
                    environment,
                    workflowVersionId,
                    targetType,
                    liveReportFieldDefinitionEntities,
                    solutionBusinessReportId,
                ),
            );
    }

    private getUpdatedFieldIdToIndexMap(fieldDefinitions, rightToFieldDefinition, fieldDefinitionId) {
        const fieldDefinitionIdToNewIndexMap = {};
        let index = 0;

        const relevantFieldDefinitions = fieldDefinitions.filter((field) => field.id !== fieldDefinitionId);
        for (const fieldDefinition of relevantFieldDefinitions) {
            if (fieldDefinition.id !== rightToFieldDefinition?.id) {
                // Update all fields to sequential indexes from 0
                fieldDefinitionIdToNewIndexMap[fieldDefinition.id] = index;
                index += 1;
            } else {
                fieldDefinitionIdToNewIndexMap[fieldDefinition.id] = index;
                index += 1;
                fieldDefinitionIdToNewIndexMap[fieldDefinitionId] = index;
                index += 1;
            }
        }

        return fieldDefinitionIdToNewIndexMap;
    }

    /**
     * Moves the field left, ignoring the hidden fields.
     */
    moveFieldLeft(groupId, fieldDefinitionId, targetType, environment, amount, solutionBusinessReportId) {
        return this.moveField(
            groupId,
            fieldDefinitionId,
            targetType,
            'left',
            environment,
            amount,
            solutionBusinessReportId,
        );
    }

    /**
     * Moves the field right, ignoring the hidden fields.
     */
    moveFieldRight(groupId, fieldDefinitionId, targetType, environment, amount, solutionBusinessReportId) {
        return this.moveField(
            groupId,
            fieldDefinitionId,
            targetType,
            'right',
            environment,
            amount,
            solutionBusinessReportId,
        );
    }

    /**
     * Removes both the global field and global field definition.
     */
    deleteField(workflowVersionId, groupId, fieldDefinitionId, globalFieldId, shouldDeleteFieldFromServer = true) {
        const group = this.projectManager.groupsMap[groupId];
        const workflowVersion = this.workflowVersionManager.getCachedWorkflowVersion(workflowVersionId);

        this.utils.removeFirst(group.globalFields, (field) => field.id === globalFieldId);

        delete this.fieldsMap[workflowVersionId][fieldDefinitionId];
        this.utils.removeFirst(
            this.selectedFieldsMap[workflowVersion.id],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        this.utils.removeFirst(
            this.selectedColumnFieldsMap[workflowVersion.id],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        this.utils.removeFirst(
            this.selectedGlobalFieldsMap[workflowVersion.id],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        this.utils.removeFirst(
            this.selectedTargetFieldsMap[workflowVersion.id]['COLUMN'],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        this.utils.removeFirst(
            this.selectedTargetFieldsMap[workflowVersion.id]['GLOBAL'],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        this.utils.removeFirst(
            this.selectedVisibleColumnFieldsMap[workflowVersion.id],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        this.utils.removeFirst(
            this.selectedVisibleGlobalFieldsMap[workflowVersion.id],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        this.utils.removeFirst(
            this.selectedVisibleTargetFieldsMap[workflowVersion.id]['COLUMN'],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );
        this.utils.removeFirst(
            this.selectedVisibleTargetFieldsMap[workflowVersion.id]['GLOBAL'],
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
        );

        this.sortInternalCachedArrays(workflowVersionId);

        return shouldDeleteFieldFromServer
            ? this.tonkeanService.deleteFieldDefinition(fieldDefinitionId)
            : this.$q.resolve();
    }

    /**
     * Adds a field definition to the cache under workflow version.
     * @param workflowVersionId Workflow version ID.
     * @param fieldsDefinitionsList Array of fields definitions.
     */
    addToCache(workflowVersionId, fieldsDefinitionsList) {
        /**
         * Initializes the caches.
         */
        this.initializeCaches(workflowVersionId);

        /**
         * Iterate over all of the fields definitions and adds each one to all caches.
         */
        fieldsDefinitionsList.forEach((fieldDefinition) =>
            this.addFieldDefinitionToAllCaches(workflowVersionId, fieldDefinition),
        );

        /**
         * Sorts the cached arrays.
         */
        this.sortInternalCachedArrays(workflowVersionId);
    }

    /**
     * Deletes a field definition.
     */
    deleteFieldDefinition(groupId, fieldDefinitionId) {
        const draftWorkflowVersionId = this.workflowVersionManager.getDraftVersionFromCache(groupId).id;

        return this.tonkeanService.deleteFieldDefinition(fieldDefinitionId).then(() => {
            this.workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
            return this.getFieldDefinitions(draftWorkflowVersionId);
        });
    }

    createFieldDefinition(
        targetType,
        name,
        description,
        type,
        ranges,
        definition,
        projectIntegrationId,
        groupId,
        fieldType,
        values,
        updateable,
        isImportant,
        displayConfiguration,
        manualValue,
        notConfigured,
        populationDisabled,
        isHidden,
        systemUtilized,
        forceUseDefinitionMetadata,
        updateFieldPermissions,
        idRelationField,
        isMultiValueField,
        inputMultiValueSeparator,
        outputMultiValueSeparator,
        dropdownSource,
        allowAddDropdownOptions,
        dropdownOptionsFromFieldDefinitionId,
        dropdownOptionsFromFieldMetadataSyncFieldDefinition,
        formulaData,
        fieldGroupName,
        linkedCustomTriggerId,
        secondaryId,
        suggestedValue,
        showManualOptionsNoResults,
        defaultValueFieldDefinitionId,
        urlLabel,
    ): Promise<FieldDefinition<unknown>> {
        const draftWorkflowVersionId = this.workflowVersionManager.getDraftVersionFromCache(groupId).id;

        return this.validateFieldDefinitionName(draftWorkflowVersionId, name, targetType, type)
            .then((validatedName) => {
                return this.tonkeanService.createFieldDefinition(
                    targetType,
                    validatedName,
                    description,
                    type,
                    ranges,
                    definition,
                    projectIntegrationId,
                    groupId,
                    fieldType,
                    values,
                    updateable,
                    isImportant,
                    displayConfiguration,
                    manualValue,
                    notConfigured,
                    populationDisabled,
                    isHidden,
                    systemUtilized,
                    forceUseDefinitionMetadata,
                    updateFieldPermissions,
                    idRelationField,
                    isMultiValueField,
                    inputMultiValueSeparator,
                    outputMultiValueSeparator,
                    dropdownSource,
                    allowAddDropdownOptions,
                    dropdownOptionsFromFieldDefinitionId,
                    dropdownOptionsFromFieldMetadataSyncFieldDefinition,
                    formulaData,
                    fieldGroupName,
                    linkedCustomTriggerId,
                    secondaryId,
                    suggestedValue,
                    showManualOptionsNoResults,
                    defaultValueFieldDefinitionId,
                    urlLabel,
                );
            })
            .then((createdFieldDefinition) => {
                this.workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
                this.liveReportHelper.cacheFieldIsHidden('DRAFT', createdFieldDefinition.id, isHidden, groupId);
                this.updateFieldDefinitionInCaches(draftWorkflowVersionId, createdFieldDefinition);
                return createdFieldDefinition;
            });
    }

    updateFieldDefinition(
        fieldDefinitionId,
        targetType,
        name,
        description,
        type,
        ranges,
        definition,
        projectIntegrationId,
        groupId,
        fieldType,
        values,
        updateable,
        isImportant,
        displayConfiguration,
        updateFieldPermissions,
        isMultiValueField,
        inputMultiValueSeparator,
        outputMultiValueSeparator,
        dropdownSource,
        allowAddDropdownOptions,
        dropdownOptionsFromFieldDefinitionId,
        dropdownOptionsFromFieldMetadataSyncFieldDefinition,
        formulaData,
        fieldGroupName,
        suggestedValue,
        showManualOptionsNoResults,
        secondaryId,
        defaultValueFieldDefinitionId,
        urlLabel,
    ) {
        const draftWorkflowVersionId = this.workflowVersionManager.getDraftVersionFromCache(groupId).id;

        return this.validateFieldDefinitionName(draftWorkflowVersionId, name, targetType, type, fieldDefinitionId).then(
            (validatedName) =>
                this.tonkeanService
                    .updateFieldDefinition(
                        fieldDefinitionId,
                        targetType,
                        validatedName,
                        description,
                        type,
                        ranges,
                        definition,
                        projectIntegrationId,
                        groupId,
                        fieldType,
                        values,
                        updateable,
                        isImportant,
                        displayConfiguration,
                        updateFieldPermissions,
                        isMultiValueField,
                        inputMultiValueSeparator,
                        outputMultiValueSeparator,
                        dropdownSource,
                        allowAddDropdownOptions,
                        dropdownOptionsFromFieldDefinitionId,
                        dropdownOptionsFromFieldMetadataSyncFieldDefinition,
                        formulaData,
                        fieldGroupName,
                        suggestedValue,
                        showManualOptionsNoResults,
                        secondaryId,
                        defaultValueFieldDefinitionId,
                        urlLabel,
                    )
                    .then((updatedField) => {
                        this.workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
                        this.updateFieldDefinitionInCaches(draftWorkflowVersionId, updatedField);
                        return updatedField;
                    })
                    .catch((error) => {
                        return this.$q.reject(error?.data?.data?.error?.message);
                    }),
        );
    }

    createMultipleFieldDefinitions(groupId, definitions, dontOverrideIndex) {
        const draftWorkflowVersionId = this.workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const definitionsValidations = definitions.map((def) =>
            this.validateFieldDefinitionName(
                draftWorkflowVersionId,
                def.name,
                def.targetType,
                def.fieldDefinitionType,
                null,
            ).then((validName) => (def.name = validName)),
        );

        return this.$q.all(definitionsValidations).then(() => {
            return this.tonkeanService
                .createMultipleFieldDefinitions(groupId, definitions, dontOverrideIndex)
                .then((data) => {
                    for (let i = 0; i < data.creationResults.length; i++) {
                        const creationResult = data.creationResults[i];
                        this.workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
                        this.addToCache(draftWorkflowVersionId, [creationResult.definition]);
                    }
                    return this.$q.resolve(data);
                });
        });
    }

    getNonInitiativeRelatedSpecialFieldDefinition(identifier) {
        return Object.fromEntries(
            Object.entries(this.specialFieldsMap).filter(([, value]: [any, any]) => value.notInitiativeRelated),
        )[identifier];
    }

    /**
     * Tries to get the matching field definition for the given identifier, either in:
     * 1. The customFieldsManager's selectedFieldsMap.
     * 2. The specialFieldsMap retrieved from this.tonkeanUtils' getSpecialFieldsForFeatures function.
     * 3. The globalFieldDefinitions array for the given workflowVersionId.
     * @param identifier The id of a field definition, or special field identifier.
     * @param workflowVersionId The workflow version to search for fields in.
     */
    getFieldDefinitionFromCachesById(workflowVersionId: string, identifier: string): null | FieldDefinition {
        if (!this.selectedFieldsMap[workflowVersionId]) {
            return null;
        }

        const fieldDefinitionsMap = this.utils.createMapFromArray(this.selectedFieldsMap[workflowVersionId], 'id');

        if (fieldDefinitionsMap[identifier]) {
            // Field definition?
            return fieldDefinitionsMap[identifier];
        } else if (this.specialFieldsMap[identifier]) {
            // Special field?
            return this.specialFieldsMap[identifier];
        }
        // Otherwise, we can't find it.
        return null;
    }

    /**
     * Tries to get field by workflowFolderId and entityVersionType
     * @param workflowFolderId The id of a workflow folder.
     * @param entityVersionType The version type.
     * @param fieldId the field id to filter by.

     */
    getGlobalFieldDefinitionByWorkflowFolderFromCachesById(
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        entityVersionType: WorkflowVersionType,
        fieldId: TonkeanId<TonkeanType.FIELD_DEFINITION>,
    ) {
        if (
            this.selectedGlobalFieldsMapByWorkflowFolder?.[workflowFolderId] &&
            this.selectedGlobalFieldsMapByWorkflowFolder?.[workflowFolderId][entityVersionType]
        ) {
            return this.selectedGlobalFieldsMapByWorkflowFolder?.[workflowFolderId][entityVersionType].find(
                (field) => field.id === fieldId,
            );
        }
    }

    /**
     * Tries to get global fields by workflow folder and version type
     * @param workflowFolderId The id of a workflow folder.
     * @param entityVersionType The version type.
     */
    getGlobalFieldDefinitionsByWorkflowFolderId(
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        entityVersionType: WorkflowVersionType,
    ): Promise<any> {
        if (workflowFolderId && entityVersionType) {
            return this.tonkeanService
                .getWorkflowFolderGlobalFieldDefinitions(workflowFolderId, entityVersionType)
                .then((data) => {
                    this.selectedGlobalFieldsMapByWorkflowFolder[workflowFolderId] = {};
                    this.selectedGlobalFieldsMapByWorkflowFolder[workflowFolderId][entityVersionType] = data.entities;
                    return this.selectedGlobalFieldsMapByWorkflowFolder[workflowFolderId][entityVersionType];
                });
        } else {
            return this.$q.resolve({});
        }
    }

    /**
     * Adds a field definition to a workflow version.
     */
    addFieldDefinitionToWorkflowVersion(addToWorkflowVersionId, originalWorkflowVersionId, fieldDefinitionId) {
        return this.tonkeanService
            .addFieldDefinitionToWorkflowVersion(addToWorkflowVersionId, originalWorkflowVersionId, fieldDefinitionId)
            .then(() => this.getFieldDefinitions(addToWorkflowVersionId)); // Calling custom fields manager to update the cache.
    }

    /**
     * Removes a field definition from a workflow version.
     */
    removeFieldDefinitionToWorkflowVersion(workflowVersionId, fieldDefinitionId) {
        return this.tonkeanService
            .removeFieldDefinitionFromWorkflowVersion(workflowVersionId, fieldDefinitionId)
            .then(() => this.getFieldDefinitions(workflowVersionId)); // Calling custom fields manager to update the cache.
    }

    /**
     * Validates the requested name for a field definition.
     * If the name already exists in this workflow version in the same type (global vs column) then it is considered invalid
     * and will return the next valid name.
     * Next valid name should be "FieldName (X)" where X is the lowest available number.
     * @param requestedName
     * @param workflowVersionId
     * @param targetType - COLUMN or GLOBAL
     * @param existingFieldDefinitionId - if this is an update, only run this check if the name has been changed.
     * @returns {*}
     */
    async validateFieldDefinitionName(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        requestedName: any,
        targetType: FieldDefinitionTargetType,
        fieldDefinitionType: any,
        existingFieldDefinitionId?: TonkeanId<TonkeanType.FIELD_DEFINITION> | null,
    ) {
        // If this is an update, only run this check if the name has been changed.
        if (existingFieldDefinitionId) {
            const fieldDefinition = this.getFieldDefinitionFromCachesById(workflowVersionId, existingFieldDefinitionId);

            // If name hasnt changed, no need for validation.
            if (fieldDefinition && fieldDefinition.name === requestedName) {
                return requestedName;
            }
        }

        const fieldDefinitionsMap = this.selectedFieldsMap[workflowVersionId];
        // In case we are creating workflow version from templates.
        if (!fieldDefinitionsMap) {
            return requestedName;
        }

        let fieldDefinitions = this.utils.objToArray(fieldDefinitionsMap).map((kvp) => kvp.value);
        const specialFieldsForFeatures = getSpecialFieldsForFeatures(true);
        fieldDefinitions = fieldDefinitions.concat(specialFieldsForFeatures);
        const validatedName = this.findSmallestAvailableName(requestedName, fieldDefinitions);
        const shouldntAlert = fieldDefinitionType === FormDefinitionType.MATCH_TONKEAN_ITEM;
        if (requestedName !== validatedName && !shouldntAlert) {
            this.$rootScope.$emit('alert', {
                msg: `Found field with name '${requestedName}', created field name was changed to ${validatedName}`,
                type: 'warning',
            });
        }
        return validatedName;
    }

    /**
     * Gets the field value (if exists) on the initiative
     * Can be either a special field or a custom field
     */
    getFieldValue(initiative, fieldKey, workflowVersion) {
        const specialFieldDefinition = FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[fieldKey];
        if (specialFieldDefinition?.extractValueFromInitiative) {
            const existingSpecialFieldValue = specialFieldDefinition.extractValueFromInitiative(
                initiative,
                workflowVersion,
            );
            return existingSpecialFieldValue;
        } else {
            // Otherwise, it's a field definition.
            if (initiative.fields && initiative.fields.length) {
                const existingField = this.utils.findFirst(
                    initiative.fields,
                    (field) => field?.fieldDefinition?.id === fieldKey,
                );
                return existingField?.value;
            }
        }
    }

    /**
     * Sorts field definition array by index.
     */
    sortFieldDefinitionsArray(groupId, environment, solutionBusinessReportId, fieldDefinitions) {
        if (!fieldDefinitions) {
            return;
        }

        const fieldsMap =
            this.liveReportHelper.solutionReportsFieldsIndexMap?.[solutionBusinessReportId] ||
            this.liveReportHelper.fieldsIndexMap?.[groupId]?.[environment];

        if (fieldsMap) {
            this.sortLiveReportFieldDefinitionsArray(fieldsMap, fieldDefinitions);
        }
    }

    /**
     * Sorts live report or solution report field definitions array by index.
     */
    sortLiveReportFieldDefinitionsArray(fieldIndexMap, array) {
        array.sort((fieldDefinitionA, fieldDefinitionB) => {
            if (fieldIndexMap[fieldDefinitionA.id] < fieldIndexMap[fieldDefinitionB.id]) {
                return -1;
            } else if (fieldIndexMap[fieldDefinitionA.id] > fieldIndexMap[fieldDefinitionB.id]) {
                return 1;
            }
            return 0;
        });
    }

    /**
     * Initializes the internal caches used by the custom fields manager.
     */
    initializeCaches(workflowVersionId) {
        if (!this.fieldsMap) {
            this.fieldsMap = {};
        }
        if (!this.fieldsMap[workflowVersionId]) {
            this.fieldsMap[workflowVersionId] = {};
        }
        if (!this.selectedFieldsMap) {
            this.selectedFieldsMap = {};
        }
        if (!this.selectedFieldsMap[workflowVersionId]) {
            this.selectedFieldsMap[workflowVersionId] = [];
        }
        if (!this.selectedColumnFieldsMap) {
            this.selectedColumnFieldsMap = {};
        }
        if (!this.selectedColumnFieldsMap[workflowVersionId]) {
            this.selectedColumnFieldsMap[workflowVersionId] = [];
        }
        if (!this.selectedGlobalFieldsMap) {
            this.selectedGlobalFieldsMap = {};
        }
        if (!this.selectedGlobalFieldsMap[workflowVersionId]) {
            this.selectedGlobalFieldsMap[workflowVersionId] = [];
        }
        if (!this.selectedTargetFieldsMap) {
            this.selectedTargetFieldsMap = {};
        }
        if (!this.selectedTargetFieldsMap[workflowVersionId]) {
            this.selectedTargetFieldsMap[workflowVersionId] = {
                COLUMN: [],
                GLOBAL: [],
            };
        }
        if (!this.selectedVisibleColumnFieldsMap) {
            this.selectedVisibleColumnFieldsMap = {};
        }
        if (!this.selectedVisibleColumnFieldsMap[workflowVersionId]) {
            this.selectedVisibleColumnFieldsMap[workflowVersionId] = [];
        }
        if (!this.selectedVisibleGlobalFieldsMap) {
            this.selectedVisibleGlobalFieldsMap = {};
        }
        if (!this.selectedVisibleGlobalFieldsMap[workflowVersionId]) {
            this.selectedVisibleGlobalFieldsMap[workflowVersionId] = [];
        }
        if (!this.selectedVisibleTargetFieldsMap) {
            this.selectedVisibleTargetFieldsMap = {};
        }
        if (!this.selectedVisibleTargetFieldsMap[workflowVersionId]) {
            this.selectedVisibleTargetFieldsMap[workflowVersionId] = {
                COLUMN: [],
                GLOBAL: [],
            };
        }
    }

    /**
     * Resets all the internal caches arrays for the given workflow version id, without changing the reference of the array.
     */
    resetInternalCachedArrays(workflowVersionId) {
        this.selectedFieldsMap[workflowVersionId].splice(0, this.selectedFieldsMap[workflowVersionId].length);
        this.selectedColumnFieldsMap[workflowVersionId].splice(
            0,
            this.selectedColumnFieldsMap[workflowVersionId].length,
        );
        this.selectedGlobalFieldsMap[workflowVersionId]?.splice(
            0,
            this.selectedGlobalFieldsMap[workflowVersionId]?.length,
        );
        this.selectedTargetFieldsMap[workflowVersionId]['COLUMN'].splice(
            0,
            this.selectedTargetFieldsMap[workflowVersionId]['COLUMN'].length,
        );
        this.selectedTargetFieldsMap[workflowVersionId]['GLOBAL'].splice(
            0,
            this.selectedTargetFieldsMap[workflowVersionId]['GLOBAL'].length,
        );
        this.selectedVisibleColumnFieldsMap[workflowVersionId].splice(
            0,
            this.selectedVisibleColumnFieldsMap[workflowVersionId].length,
        );
        this.selectedVisibleGlobalFieldsMap[workflowVersionId].splice(
            0,
            this.selectedVisibleGlobalFieldsMap[workflowVersionId].length,
        );
        this.selectedVisibleTargetFieldsMap[workflowVersionId]['COLUMN'].splice(
            0,
            this.selectedVisibleTargetFieldsMap[workflowVersionId]['COLUMN'].length,
        );
        this.selectedVisibleTargetFieldsMap[workflowVersionId]['GLOBAL'].splice(
            0,
            this.selectedVisibleTargetFieldsMap[workflowVersionId]['GLOBAL'].length,
        );
    }

    /**
     * Checks if field visible by the relevant caches (live report/solution business report)
     */
    isFieldVisible(workflowVersionId, fieldDefinition, solutionBusinessReportId) {
        if (solutionBusinessReportId) {
            return (
                this.liveReportHelper.solutionReportsFieldsIsHiddenMap[solutionBusinessReportId][fieldDefinition.id] ===
                false
            );
        }

        const cachedWorkflowVersion = this.workflowVersionManager.getCachedWorkflowVersion(workflowVersionId);

        return (
            cachedWorkflowVersion &&
            this.liveReportHelper.fieldsIsHiddenMap[cachedWorkflowVersion.groupId] &&
            this.liveReportHelper.fieldsIsHiddenMap[cachedWorkflowVersion.groupId][
                cachedWorkflowVersion.workflowVersionType
            ] &&
            this.liveReportHelper.fieldsIsHiddenMap[cachedWorkflowVersion.groupId][
                cachedWorkflowVersion.workflowVersionType
            ][fieldDefinition.id] === false
        );
    }

    /**
     * Adds the given field definition to the different visible caches.
     */
    addFieldDefinitionToVisibleCaches(
        workflowVersionId,
        fieldDefinition,
        solutionBusinessReportId,
        isVisibleField = false,
    ) {
        if (this.isFieldVisible(workflowVersionId, fieldDefinition, solutionBusinessReportId) || isVisibleField) {
            if (fieldDefinition.targetType === 'COLUMN') {
                this.utils.addOrReplaceById(this.selectedVisibleColumnFieldsMap[workflowVersionId], fieldDefinition);
                this.utils.addOrReplaceById(
                    this.selectedVisibleTargetFieldsMap[workflowVersionId]['COLUMN'],
                    fieldDefinition,
                );
            }

            if (fieldDefinition.targetType === 'GLOBAL') {
                this.utils.addOrReplaceById(this.selectedVisibleGlobalFieldsMap[workflowVersionId], fieldDefinition);
                this.utils.addOrReplaceById(
                    this.selectedVisibleTargetFieldsMap[workflowVersionId]['GLOBAL'],
                    fieldDefinition,
                );
            }
        }
    }

    /**
     * Adds given field definition to all caches under given workflow version id.
     */
    addFieldDefinitionToAllCaches(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        fieldDefinition: FieldDefinition,
        solutionBusinessReportId?: TonkeanId<TonkeanType.SOLUTION_BUSINESS_REPORT>,
    ) {
        this.fieldsMap[workflowVersionId][fieldDefinition.id] = fieldDefinition;

        this.utils.addOrReplaceById(this.selectedFieldsMap[workflowVersionId], fieldDefinition);

        if (fieldDefinition.targetType === 'COLUMN') {
            this.utils.addOrReplaceById(this.selectedColumnFieldsMap[workflowVersionId], fieldDefinition);
            this.utils.addOrReplaceById(this.selectedTargetFieldsMap[workflowVersionId]['COLUMN'], fieldDefinition);
        }

        if (fieldDefinition.targetType === 'GLOBAL') {
            this.utils.addOrReplaceById(this.selectedGlobalFieldsMap[workflowVersionId], fieldDefinition);
            this.utils.addOrReplaceById(this.selectedTargetFieldsMap[workflowVersionId]['GLOBAL'], fieldDefinition);
        }

        this.addFieldDefinitionToVisibleCaches(workflowVersionId, fieldDefinition, solutionBusinessReportId);
    }

    /**
     * Sorts all the internal caches arrays of given workflow version id (sorting is done based on the index).
     */

    sortInternalCachedArrays(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        groupIdParam?: TonkeanId<TonkeanType.GROUP>,
        environmentParam?: any,
        solutionBusinessReportId?: TonkeanId<TonkeanType.SOLUTION_BUSINESS_REPORT>,
    ) {
        const cachedWorkflowVersion = this.workflowVersionManager.getCachedWorkflowVersion(workflowVersionId);
        const groupId = groupIdParam || cachedWorkflowVersion.groupId;
        const environment = environmentParam || cachedWorkflowVersion.workflowVersionType;

        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedFieldsMap[workflowVersionId],
        );
        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedColumnFieldsMap[workflowVersionId],
        );
        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedGlobalFieldsMap[workflowVersionId],
        );
        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedTargetFieldsMap[workflowVersionId]['COLUMN'],
        );
        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedTargetFieldsMap[workflowVersionId]['GLOBAL'],
        );
        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedVisibleColumnFieldsMap[workflowVersionId],
        );
        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedVisibleGlobalFieldsMap[workflowVersionId],
        );
        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedVisibleTargetFieldsMap[workflowVersionId]['COLUMN'],
        );
        this.sortFieldDefinitionsArray(
            groupId,
            environment,
            solutionBusinessReportId,
            this.selectedVisibleTargetFieldsMap[workflowVersionId]['GLOBAL'],
        );
    }

    findSmallestAvailableName(requestedName, fieldDefinitions) {
        const namesToDefinition = this.utils.createMapFromArray(fieldDefinitions, 'name');
        let count = 0;
        let availableName = null;
        do {
            let possibleName = requestedName;
            if (count > 0) {
                possibleName = `${possibleName} (${count})`;
            }

            availableName = namesToDefinition[possibleName] ? null : possibleName;
            count += 1;
        } while (!availableName);

        return availableName;
    }

    /**
     * Replaces given field definition in the array of field definitions.
     */
    replaceFieldDefinitionInArray(array, fieldDefinitionToReplace) {
        const existingFieldDefinitionIndex = this.utils.indexOf(
            array,
            (fieldDefinition) => fieldDefinition.id === fieldDefinitionToReplace.id,
        );
        if (existingFieldDefinitionIndex !== -1) {
            array.splice(existingFieldDefinitionIndex, 1, fieldDefinitionToReplace);
        } else {
            array.push(fieldDefinitionToReplace);
        }
    }

    /**
     * Update a visible fields cache based on current cached information on hidden fields.
     */
    updateVisibleFieldsCache(workflowVersionId, visibleFieldDefinitionsArray, targetType, environment, groupId) {
        // Saving fields is hidden map to a property.
        const fieldsIsHiddenMap = this.liveReportHelper.fieldsIsHiddenMap[groupId][environment] || {};

        // Creating a set of currently visible field definition ids.
        const existingVisibleFieldDefinitionIdsSet = this.utils.arrayToSet(
            visibleFieldDefinitionsArray.map((currentlyVisibleFieldDefinition) => currentlyVisibleFieldDefinition.id),
        );

        // Getting all the field definition indices that we need to splice - field definitions that were
        // visible but now are hidden.
        const indicesToSplice = visibleFieldDefinitionsArray
            .map((currentlyVisibleFieldDefinition, index) => {
                if (fieldsIsHiddenMap[currentlyVisibleFieldDefinition.id]) {
                    return index;
                }

                return;
            })
            .filter((val) => val !== undefined); // to keep 0 index value

        // Removing the now hidden fields.
        indicesToSplice.forEach((indexToSplice) => visibleFieldDefinitionsArray.splice(indexToSplice, 1));

        // Adding any fields that are now visible but do not yet exist in the collection.
        (this.selectedFieldsMap[workflowVersionId] || [])
            .filter(
                (selectedFieldDefinition) =>
                    !existingVisibleFieldDefinitionIdsSet[selectedFieldDefinition.id] &&
                    !fieldsIsHiddenMap[selectedFieldDefinition.id] &&
                    (!targetType || selectedFieldDefinition.targetType === targetType),
            )
            .forEach((selectedFieldDefinition) => visibleFieldDefinitionsArray.push(selectedFieldDefinition));
    }

    /**
     * Handles the update of a group after rearrangement of live report field definitions.
     */
    handleGroupUpdateAfterReorder(
        groupId,
        environment,
        workflowVersionId,
        targetType,
        liveReportFieldDefinitions,
        solutionBusinessReportId,
    ) {
        // Update cached on new live report
        this.liveReportHelper.cacheLiveReport(
            environment,
            liveReportFieldDefinitions,
            groupId,
            solutionBusinessReportId,
        );

        // Sorting all relevant arrays of fields.
        this.sortInternalCachedArrays(workflowVersionId, groupId, environment, solutionBusinessReportId);
        return this.$q.resolve();
    }
}

angular.module('tonkean.app').service('customFieldsManager', CustomFieldsManager);

export default CustomFieldsManager;
