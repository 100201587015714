import { useAngularService } from 'angulareact';
import type { FormEvent } from 'react';
import React, { useContext, useState } from 'react';

import ServicenowAuthType, { authTypeToDisplayName } from './ServicenowAuthType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { Radio, RadioGroup } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const ServicenowComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [authType, setAuthType] = useState<ServicenowAuthType>(ServicenowAuthType.BASIC);
    const [subdomain, setSubdomain] = useState<string>('');
    const [username, setUsername] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();
    const [client, setClient] = useState<string>('');
    const [secret, setSecret] = useState<string | undefined>();

    const redirectUrl = environment.integrationKeysMap.servicenowUri || '';

    const startAuthentication = async (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const projectIntegrationData = {
                authType,
                subdomain,
            };

            let credentials;

            if (authType === ServicenowAuthType.OAUTH) {
                const code = await oauthHandler.servicenow(client, redirectUrl, subdomain);
                credentials = {
                    client,
                    secret,
                    code,
                    redirect_uri: redirectUrl,
                };
            } else {
                credentials = {
                    username,
                    password,
                };
            }

            const config = {
                ...projectIntegrationData,
                ...credentials,
            };
            authenticate(config, projectIntegrationData, integrationConfiguration.displayName);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col form-horizontal">
            <div className="flex flex-col api-token-authentication">
                <div className="form-group">
                    <label className="col-sm-4 control-label">Authentication Type</label>
                    <div className="col-sm-8 padding-top-xs">
                        <RadioGroup value={authType} direction="row" onChange={setAuthType} size={InputSize.MEDIUM}>
                            <Radio value={ServicenowAuthType.BASIC}>
                                {authTypeToDisplayName[ServicenowAuthType.BASIC]}
                            </Radio>
                            <Radio value={ServicenowAuthType.OAUTH}>
                                {authTypeToDisplayName[ServicenowAuthType.OAUTH]}
                            </Radio>
                        </RadioGroup>
                    </div>
                </div>

                <div className="form-group">
                    <label className="col-sm-3 control-label">Subdomain</label>
                    <div className="col-sm-8">
                        <div className="flex-vmiddle">
                            <div>https://</div>
                            <div className="flex-grow">
                                <input
                                    type="text"
                                    value={subdomain}
                                    className="form-control"
                                    onChange={({ target }) => setSubdomain(target.value)}
                                    autoComplete="off"
                                    placeholder="Subdomain"
                                    data-automation="servicenow-authentication-component-subdomain"
                                    required
                                />
                            </div>
                            <div>.service-now.com/</div>
                        </div>
                    </div>
                </div>

                {authType === ServicenowAuthType.BASIC && (
                    <div className="flex flex-col">
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Username</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    value={username}
                                    className="form-control"
                                    onChange={({ target }) => setUsername(target.value)}
                                    autoComplete="off"
                                    placeholder="Username"
                                    data-automation="servicenow-authentication-component-username"
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-3 control-label">Password</label>
                            <div className="col-sm-8">
                                <input
                                    type="password"
                                    value={password}
                                    className="form-control"
                                    onChange={({ target }) => setPassword(target.value)}
                                    autoComplete="off"
                                    placeholder="Password"
                                    data-automation="servicenow-authentication-component-password"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                )}

                {authType === ServicenowAuthType.OAUTH && (
                    <div className="flex flex-col">
                        <div className="form-group">
                            <label className="col-sm-3 control-label">Client ID</label>
                            <div className="col-sm-8">
                                <input
                                    value={client}
                                    className="form-control"
                                    onChange={({ target }) => setClient(target.value)}
                                    autoComplete="off"
                                    placeholder="Client ID"
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-sm-3 control-label">Client Secret</label>
                            <div className="col-sm-8">
                                <input
                                    type="password"
                                    value={secret}
                                    className="form-control"
                                    onChange={({ target }) => setSecret(target.value)}
                                    autoComplete="off"
                                    placeholder="Client Secret"
                                    required
                                />
                            </div>
                        </div>

                        <div className="margin-bottom-lg info-box common-size-xxs">
                            <p className="common-color-dark-grey">OAuth Setup Information:</p>
                            <div className="common-color-dark-grey margin-top-lg">
                                <ul className="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                                    <li className="margin-bottom-xs margin-top-xs">
                                        Navigate to System OAuth {'>'} Application Registry and click on "New".
                                    </li>
                                    <li className="margin-bottom-xs">
                                        Select "Create an OAuth API endpoint for external clients"
                                    </li>
                                    <li className="margin-bottom-xs">
                                        Enter a name and the next redirect URL:
                                        <br />
                                        {redirectUrl}
                                    </li>
                                    <li className="margin-bottom-xs">
                                        Submit the form and click on the newly created app.
                                    </li>
                                    <li className="margin-bottom-xs">
                                        Grab the Client ID & Client Secret form the details page.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}

                {/* Connect button*/}
                <div className="flex mod-center margin-top-20">
                    <button
                        className="btn btn-secondary btn-lg"
                        onClick={startAuthentication}
                        disabled={!subdomain || subdomain === ''}
                        data-automation="servicenow-authentication-component-authenticate"
                    >
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ServicenowComponent;
