import React from 'react';
import styled from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const CommonPlaceHolderContainer = styled.div`
    border-bottom: 1px solid ${Theme.current.palette.mainColors.gray_300};
    height: 50px;
    line-height: 50px;
    vertical-align: center;
`;

const PlaceholderColumnContainer = styled(CommonPlaceHolderContainer)`
    display: table-cell;
    padding-top: 17px;
`;

const PlaceholderColumnCirclesContainer = styled(CommonPlaceHolderContainer)`
    padding-top: 13px;
    display: flex !important;
`;

const PlaceholderColumnToggleContainer = styled(CommonPlaceHolderContainer)`
    padding-top: 15px;
    display: table-cell;
`;

const AvatarPlaceholder = styled(Placeholder)`
    margin-left: 3px;
`;

interface Props {}

const TableLoaderView: React.FC<Props> = () => {
    return (
        <>
            {range(5).map((i) => (
                <React.Fragment key={i}>
                    <PlaceholderColumnContainer>
                        <Placeholder $height="15px" $width="calc(100% - 25px)" />
                    </PlaceholderColumnContainer>
                    <PlaceholderColumnCirclesContainer>
                        {range(5).map((jIndex) => (
                            <Placeholder key={jIndex} $height="25px" $width="25px" $circle />
                        ))}
                    </PlaceholderColumnCirclesContainer>
                    <PlaceholderColumnToggleContainer>
                        <AvatarPlaceholder $height="15px" $width="25px" />
                    </PlaceholderColumnToggleContainer>
                </React.Fragment>
            ))}
        </>
    );
};

export default TableLoaderView;
