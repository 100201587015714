import React, { useMemo } from 'react';

import RichTextEditorInBuilderMode from './RichTextEditorInBuilderMode';
import RichTextEditorInputEmptyState from './RichTextEditorInputEmptyState';
import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';
import RichTextEditorViewItemExists from './RichTextEditorViewItemExists';
import type { SingleFieldChanged, WidgetFieldPackage } from '../CommonWidgetConfiguration';

import { filterToCoreEditorPlugins, type HTMLEditorPlugin } from '@tonkean/editor';
import type { Initiative, WidgetBase, WorkflowVersion } from '@tonkean/tonkean-entities';

interface Props {
    isBuilderMode: boolean | undefined;
    widget: WidgetBase<RichTextEditorInputWidgetConfiguration>;
    editorPlugins: HTMLEditorPlugin[];
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    configuredFields: WidgetFieldPackage[];
    isEditMode: boolean;
    setIsEditMode: (isShowEdit: boolean) => void;
    onChange: (changes: SingleFieldChanged[]) => Promise<void>;
}
const RichTextEditorInputWidgetViewBody: React.FC<Props> = ({
    isBuilderMode,
    widget,
    editorPlugins,
    initiative,
    workflowVersion,
    configuredFields,
    isEditMode,
    setIsEditMode,
    onChange,
}) => {
    const coreEditorPlugins = useMemo(() => filterToCoreEditorPlugins(editorPlugins), [editorPlugins]);
    const isItemExists = !!initiative && !!workflowVersion && !!configuredFields?.[0];

    if (isBuilderMode) {
        return (
            <RichTextEditorInBuilderMode
                data-automation="rich-text-editor-in-builder-mode"
                widget={widget}
                editorPlugins={editorPlugins}
            />
        );
    } else if (isItemExists && widget.configuration.selectedField) {
        return (
            <RichTextEditorViewItemExists
                data-automation="rich-text-editor-view-item-exists"
                widget={widget}
                initiative={initiative}
                workflowVersion={workflowVersion}
                coreEditorPlugins={coreEditorPlugins}
                currentFieldDefinition={configuredFields[0]!.fieldDefinition}
                onSave={(singleField) => onChange([singleField])}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
            />
        );
    } else {
        return (
            <RichTextEditorInputEmptyState
                data-automation="rich-text-editor-input-empty-state"
                isFieldSelected={!!widget.configuration.selectedField}
            />
        );
    }
};

export default RichTextEditorInputWidgetViewBody;
