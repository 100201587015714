import { useCallback, useReducer } from 'react';

import type { AddFieldsReducerAction, FieldOption } from '../entities';

export const useAddFieldsModalManager = <T extends string>(initialFieldsState: FieldOption<T>[]) => {
    const getSubmitResult = useCallback(
        (updatedState: FieldOption<T>[]) => {
            const originalSelectedFields = new Set(
                initialFieldsState.filter((field) => field.isSelected).map((field) => field.id),
            );
            const newSelectedFields = updatedState.filter((field) => field.isSelected);
            const newChecked = newSelectedFields
                .filter((field) => !originalSelectedFields.has(field.id))
                .map((field) => field.id);

            return {
                newState: updatedState,
                newChecked,
            };
        },
        [initialFieldsState],
    );
    const fieldsReducesAction = (state: FieldOption<T>[], action: AddFieldsReducerAction<T>) => {
        switch (action.action) {
            case 'checked': {
                return state.map((field) => {
                    if (field.id === action.id) {
                        return { ...field, isSelected: true };
                    }
                    return field;
                });
            }
            case 'unchecked': {
                return state.map((field) => {
                    if (field.id === action.id) {
                        return { ...field, isSelected: false };
                    }
                    return field;
                });
            }
            case 'check-all': {
                return state.map((field) => {
                    if (!field.disabled && !field.isSelected) {
                        return { ...field, isSelected: true };
                    }
                    return field;
                });
            }
            case 'uncheck-all': {
                return state.map((field) => {
                    if (!field.disabled && field.isSelected) {
                        return { ...field, isSelected: false };
                    }
                    return field;
                });
            }
            default: {
                console.error("Wrong dispatcher's action");

                return state;
            }
        }
    };

    const [state, dispatcher] = useReducer(fieldsReducesAction, initialFieldsState);

    return {
        fieldsState: state,
        dispatcher,
        getSubmitResult,
    };
};
