import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const UserInterfaceButtonClickedLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.AUTONOMOUS,
    secondaryType: LogicBlockConfigType.USER_INTERFACE_BUTTON_CLICK,
    iconClass: 'mod-trigger',
    secondaryIconClass: 'icon-button-click',
    title: 'Button Clicked',
    description: 'On user clicking a button',
    longDescription:
        'This flow will run when an action button that references this trigger is clicked in an app or interface',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    doNotDisplayStatusEdit: false,
    doNotDisplayNotificationsEdit: true,
    doesntHaveDefinition: true,
    cantDrag: true,
    definition: {},
};

export default UserInterfaceButtonClickedLogicBlockConfig;
