import { useMemo } from 'react';

import useLazyTonkeanService from './useLazyTonkeanService';

import {
    type ActionParameterExpression,
    ActionParameterType,
    type BaseActionParameter,
    type ProjectIntegrationAction,
} from '@tonkean/tonkean-entities';

const useFetchProjectIntegrationActionParameters = (
    projectIntegrationAction: ProjectIntegrationAction,
    projectIntegrationActionLoading: boolean,
) => {
    const [
        { data: parameters, loading: parametersLoading, error: parameterError },
        getProjectIntegrationActionParameters,
    ] = useLazyTonkeanService('getProjectIntegrationActionParameters');

    useMemo(() => {
        const hasAnyEntitiesToRunOn = projectIntegrationAction?.parametersDefinition?.entitiesToRunOn.length === 1;
        const enableCustomFields = projectIntegrationAction?.parametersDefinition?.isCustomFieldsEnabled;

        if (hasAnyEntitiesToRunOn && enableCustomFields) {
            getProjectIntegrationActionParameters(
                projectIntegrationAction.projectIntegrationId,
                projectIntegrationAction.id,
            );
        }
    }, [getProjectIntegrationActionParameters, projectIntegrationAction]);

    return useMemo(() => {
        const hasAnyEntitiesToRunOn = projectIntegrationAction?.parametersDefinition?.entitiesToRunOn.length === 1;
        const enableCustomFields = projectIntegrationAction?.parametersDefinition?.isCustomFieldsEnabled;

        if (hasAnyEntitiesToRunOn && enableCustomFields) {
            return {
                parameters: (parameters || []) as (BaseActionParameter & ActionParameterExpression)[],
                parametersLoading,
                parameterError,
            };
        } else {
            return {
                parameters: (projectIntegrationAction?.parametersDefinition?.parameters.filter(
                    (parameter) => parameter.parameterType === ActionParameterType.TONKEAN_EXPRESSION,
                ) || []) as (BaseActionParameter & ActionParameterExpression)[],
                parametersLoading: projectIntegrationActionLoading,
                parameterError: null,
            };
        }
    }, [
        parameterError,
        parameters,
        parametersLoading,
        projectIntegrationActionLoading,
        projectIntegrationAction?.parametersDefinition?.entitiesToRunOn.length,
        projectIntegrationAction?.parametersDefinition?.parameters,
        projectIntegrationAction?.parametersDefinition?.isCustomFieldsEnabled,
    ]);
};

export default useFetchProjectIntegrationActionParameters;
