import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';
import styled from 'styled-components';

import type { NavigationSelectionGroupType, NavigationSelectionSingleItemType } from './NavigationSelectionTypes';

import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { TextEllipsis } from '@tonkean/infrastructure';
import { logicBlockTypes } from '@tonkean/logic-block-configs';
import { SMART_SEARCH_ID } from '@tonkean/smart-search';
import { SolutionBusinessReportIcon } from '@tonkean/svg';
import { LightningIcon } from '@tonkean/svg';
import { PageIcon } from '@tonkean/svg';
import { SmartSearchIcon } from '@tonkean/svg';
import { FormIcon } from '@tonkean/svg';
import { ShowInterfaceIcon as InterfaceIcon } from '@tonkean/svg';
import { TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ProjectIntegrationIconWrapper = styled.div`
    margin-right: 10px;
`;

const CustomTriggerIcon = styled.span`
    margin-right: 10px;
`;

const FormIconWrapper = styled.span`
    svg {
        width: 16px;
    }
`;

const PageIconWrapper = styled.span`
    svg {
        width: 16px;
    }
`;

const StyledInterfaceIcon = styled(InterfaceIcon)`
    margin-right: 10px;
    margin-top: 4px;
    height: auto;
    path {
        fill: ${Theme.colors.promotion};
    }
`;

const NavigationSearchItemOption: React.FC<
    OptionProps<NavigationSelectionSingleItemType, false, NavigationSelectionGroupType>
> = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            {props.data.tonkeanType === TonkeanType.PROJECT_INTEGRATION && (
                <ProjectIntegrationIconWrapper>
                    <ProjectIntegrationIcon
                        width={15}
                        integrationType={props.data['integrationType']}
                        iconUrl={props.data['iconUrl']}
                    />
                </ProjectIntegrationIconWrapper>
            )}

            {props.data.tonkeanType === TonkeanType.GROUP && (
                <span className="tnk-icon module-studio-navigation-icon">
                    <LightningIcon />
                </span>
            )}

            {props.data.tonkeanType === TonkeanType.WORKFLOW_FOLDER && (
                <span className="tnk-icon module-studio-navigation-icon">
                    <LightningIcon />
                </span>
            )}

            {props.data.tonkeanType === TonkeanType.SOLUTION_BUSINESS_REPORT && (
                <span className="tnk-icon module-studio-navigation-icon">
                    <SolutionBusinessReportIcon />
                </span>
            )}

            {props.data.tonkeanType === TonkeanType.CUSTOM_TRIGGER && (
                <CustomTriggerIcon
                    className={`logic-template-icon mod-xxs ${
                        logicBlockTypes[props.data['customTriggerType']]?.iconClass
                    }`}
                />
            )}

            {props.data.tonkeanType === TonkeanType.INITIATIVE && (
                <CustomTriggerIcon className="logic-template-icon mod-xxs mod-tonkean" />
            )}

            {props.data.tonkeanType === TonkeanType.FORM && (
                <FormIconWrapper className="tnk-icon module-studio-navigation-icon">
                    <FormIcon />
                </FormIconWrapper>
            )}

            {props.data.tonkeanType === TonkeanType.ITEM_INTERFACE && (
                <FormIconWrapper className="tnk-icon">
                    <StyledInterfaceIcon />
                </FormIconWrapper>
            )}

            {props.data.tonkeanType === TonkeanType.SOLUTION_SITE_PAGE && (
                <PageIconWrapper className="tnk-icon module-studio-navigation-icon">
                    <PageIcon />
                </PageIconWrapper>
            )}

            {props.data.id === SMART_SEARCH_ID && (
                <PageIconWrapper className="tnk-icon module-studio-navigation-icon">
                    <SmartSearchIcon />
                </PageIconWrapper>
            )}

            <TextEllipsis numberOfLines={1} tooltip>
                {children}
            </TextEllipsis>
        </components.Option>
    );
};

export default NavigationSearchItemOption;
