import type LineItemAggregation from './LineItemAggregation';
import type MinMaxRequirementConfiguration from './MinMaxRequirementConfiguration';
import type SortByFieldConfiguration from './SortByFieldConfiguration';
import type { SingleFieldConfiguration } from '../CommonWidgetConfiguration';
import type { RowHeight } from '../InnerItemsWidgetModule/components/configurationEditorSettingsComponents/RowHeightSelector';
import type InitialFieldDefinitionValueExpression from '../LineItemsWidgetModule/entities/InitialFieldDefinitionValueExpression';

import type {
    BasicQueryDefinition,
    ItemInterfaceWidgetConfiguration,
    ItemInterfaceWidgetConfigurationDisplayMode,
    ItemInterfaceWidgetConfigurationUserAccessLevel,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

export enum InnerItemWidgetConfigurationItemCreationType {
    MODAL = 'modal',
    IMMEDIATE = 'immediate',
}

export const DEFAULT_ITEM_CREATION_TYPE = InnerItemWidgetConfigurationItemCreationType.MODAL;
export default interface InnerItemWidgetConfiguration extends ItemInterfaceWidgetConfiguration {
    /**
     * The fields to display as columns in the widget
     */
    fields: Record<FieldDefinitionKey, SingleFieldConfiguration>;

    /**
     * The card fields to display as columns in the widget
     */
    cardFields: Record<FieldDefinitionKey, SingleFieldConfiguration>;
    /**
     * Can the user edit inner items
     */
    editingEnabled?: boolean;

    /**
     * Item Interface to present the inner items in
     */
    drillDownInterface: TonkeanId<TonkeanType.ITEM_INTERFACE> | undefined;

    /**
     * Item create form to add an item in cards view
     */
    createForm: TonkeanId<TonkeanType.FORM> | undefined;

    /**
     * Item creation button label
     */
    itemCreationButtonLabel: string | undefined;

    /**
     * Item creation button label
     */
    itemCreationType: InnerItemWidgetConfigurationItemCreationType | undefined;

    /**
     * Configuration to define minimum items and maximum items required in table
     */
    minMaxRequirementConfiguration?: MinMaxRequirementConfiguration;

    /**
     * Item Interface to present the inner items in
     */
    displayMode: ItemInterfaceWidgetConfigurationDisplayMode;

    /**
     * Enable process contributor to switch between display modes for his view
     */

    enableDisplayModeToggle: boolean | undefined;

    filters: BasicQueryDefinition | undefined;

    /**
     * Should display advanced filters on the items widget
     */
    displayAdvancedFilters?: boolean;

    /**
     * Indicates whether to include inner items from all hierarchies when querying.
     */
    includeInnerItemsFromAllHierarchies: boolean | undefined;

    /**
     * Enable inner items toggle.
     */
    enableInnerItemsToggle?: boolean;

    /**
     * Indicates the user access level to the items.
     */
    userAccessLevel?: ItemInterfaceWidgetConfigurationUserAccessLevel;

    /**
     * Determines the default sorting of the items
     */
    sortByField?: SortByFieldConfiguration;

    /**
     * Displays line items in bold
     */
    emphasizeText?: boolean;

    /**
     * Aggregations on the inner items
     */
    aggregations?: LineItemAggregation[];

    /**
     * Initial values for fields in the initiative created
     */
    initialExpressionValues?: InitialFieldDefinitionValueExpression[];

    /**
     * A fixed height for the rows in the table
     */
    rowHeight?: RowHeight;

    /**
     * Should we allow a drilldown to an item interface
     */
    allowDrillDown?: boolean;
}
