import typeformCircleImage from '../../../../apps/tracks/images/integrations/typeform-circle.png';
import typeformImage from '../../../../apps/tracks/images/typeform.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class TypeformIntegration extends IntegrationDefinitionBase {
    override name = 'typeform';
    override displayName = 'Typeform';
    override description = '';
    override authenticationType = AuthenticationMethod.API_TOKEN;
    override entities = [
        new IntegrationEntity('Form', 'Forms'),
        new IntegrationEntity('Response', 'Responses'),
        new IntegrationEntity('Question', 'Questions'),
        new IntegrationEntity('Answer', 'Answers'),
    ];

    override supportsMultipleIntegrationPerUser = true;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(typeformCircleImage, '130px'),
        new IntegrationIcon(typeformImage, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Form: true, Response: false },
        isOnlyDataRetention: {},
    };

    // I would like to use OAuth but I'm having issues with the redirect url we're providing (with the state param)

    //     override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
    //         //const scope = 'accounts:read forms:read images:read images:write themes:write themes:read';
    //         const scope = 'accounts:read';
    //         const clientId = environment.integrationKeysMap.typeform;
    //         const uri = environment.integrationKeysMap.typeformUri;
    //         const redirectUriState = oAuthHandler.publicGetState();
    //         const redirectUri = `${uri}?state=${redirectUriState}`;
    //         const url = `https://api.typeform.com/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${encodeURIComponent(
    //             'https://tracks.tonkean.com/oauth.html',
    //         )}`;
    //
    //         const code = await oAuthHandler.startOAuth(url, redirectUriState);
    //         return { code, redirectUri };
    //     }
}

export default TypeformIntegration;
