<!-- Displayed when field is associated - showing what it is associated with -->
<div ng-if="data.field.isAssociated && data.integration">
    <div class="flex">
        <i class="fa fa-check common-color-primary margin-right-xs margin-top-xxs"></i>
        <div>
            <div>
                Item title
                <span class="common-bold common-color-light-grey">{{ data.field.externalId }}</span>
                is matched to field
                <span class="common-bold common-color-light-grey">
                    {{ data.field.fieldDefinition.definition.matchTrackField.label }}
                </span>
                of
                <span class="common-bold common-color-light-grey">
                    {{ data.field.fieldDefinition.definition.entity }}
                </span>
            </div>
        </div>
    </div>
</div>

<!-- Displayed when field is not associated - showing a message indicating we couldn't match the field -->
<div ng-if="!data.field.isAssociated" class="flex info-box">
    <i class="fa fa-info-circle common-color-danger margin-right margin-top-xxs"></i>
    <div class="common-size-xs common-color-dark-grey">
        Couldn't find a match for "{{ data.initiative.title }}" using the field
        <span class="common-bold common-color-light-grey">
            {{ data.field.fieldDefinition.definition.matchTrackField.label }}
        </span>
        of
        <span class="common-bold common-color-light-grey">{{ data.field.fieldDefinition.definition.entity }}</span>
        . Manually match it by changing the value below:
    </div>
</div>

<!-- Choosing a different title to match with -->
<div class="flex-vmiddle common-size-sm margin-top-lg">
    <div ng-if="data.field.isAssociated && data.integration">
        <button type="button" class="btn btn-secondary" ng-click="enterEditMode()" ng-show="!editing">
            Edit matched title
        </button>
    </div>

    <span
        ng-show="editing || !data.field.isAssociated"
        class="flex-no-shrink create-field-definition-modal-label margin-bottom-xs margin-right"
    >
        Title to match with:
    </span>

    <input
        type="text"
        id="fieldExternalIdInput"
        name="fieldExternalIdInput"
        ng-show="editing || !data.field.isAssociated"
        class="form-control"
        ng-model="data.item.id"
        autocomplete="off"
        placeholder="Enter a custom value for a title"
        required
    />
</div>
<hr />
