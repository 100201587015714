<span ng-switch="displayMode">
    <!-- When single Button mode-->
    <span ng-switch-when="button" ng-init="data.inlineMode = true;">
        <span ng-include="'./integrationGroupSingleButtonModeTemplate.template.html'"></span>
    </span>

    <!-- When single row mode-->
    <span ng-switch-when="simple-row" ng-init="data.inlineMode = true;">
        <span ng-include="'./integrationGroupSimpleRowModeTemplate.template.html'"></span>
    </span>

    <!-- When Inline mode-->
    <span ng-switch-when="row" ng-init="data.inlineMode = true;">
        <span ng-include="'./integrationGroupRowModeTemplate.template.html'"></span>
    </span>

    <!-- When row 2-->
    <span ng-switch-when="row2" ng-init="data.inlineMode = true;">
        <span ng-include="'./integrationGroupRow2ModeTemplate.template.html'"></span>
    </span>

    <!-- When box mode-->
    <span ng-switch-when="box" ng-init="data.inlineMode = true;">
        <span ng-include="'./integrationGroupBoxModeTemplate.template.html'"></span>
    </span>

    <!-- When edit-button only mode-->
    <span ng-switch-when="edit-button" ng-init="data.inlineMode = true; setIntegrations()">
        <span ng-include="'./integrationGroupButtonTemplate.template.html'"></span>
    </span>

    <!-- Default-->
    <span ng-switch-default>
        <span ng-include="'./integrationGroupFullModeTemplate.template.html'"></span>
    </span>
</span>

<div
    ng-if="inlineSetupScreen && inlineSetupScreen.controllerClass"
    ng-hide="saving"
    ng-include="'./tnkIntegrationGroupSetupModal.template.html'"
    ng-controller="inlineSetupScreen.controllerClass"
></div>
<div ng-if="inlineSetupScreen && saving" class="text-center">
    <div class="loading-large"></div>
</div>
