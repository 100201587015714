import React from 'react';

import EnterpriseComponentsExplorerListView from './EnterpriseComponentsExplorerListView';
import type { EnterpriseComponentExplorerListSectionConfiguration } from '../../../entities/EnterpriseComponentExplorerListConfiguration';

import type { EnterpriseComponentsPersonPermissionRole } from '@tonkean/tonkean-entities';
import type { TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    enterpriseComponentType: TonkeanType;
    enterpriseComponentSections: EnterpriseComponentExplorerListSectionConfiguration[];
    filterByText?: string;
    allAccessData: EnterpriseComponentsPersonPermissionRole;
}

const EnterpriseComponentExplorerList: React.FC<Props> = ({
    enterpriseComponentType,
    enterpriseComponentSections,
    filterByText,
    allAccessData,
}) => {
    return (
        <EnterpriseComponentsExplorerListView
            enterpriseComponentType={enterpriseComponentType}
            searchText={filterByText}
            enterpriseComponentSections={enterpriseComponentSections}
            enterpriseComponentsCurrentPersonPermissionRole={allAccessData}
        />
    );
};

export default EnterpriseComponentExplorerList;
