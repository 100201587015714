import React from 'react';
import styled from 'styled-components';

import DetailedItemsWidgetDrillDownHeader from './DetailedItemsWidgetDrillDownHeader';
import CustomInterfaceView from '../../../components/CustomInterfaceView';
import ItemInterfacePermission from '../../../entities/ItemInterfacePermission';

import type { ItemInterfaceSection } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType, ValidationState } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0 32px;
    background: ${Theme.colors.gray_300};
`;

const InterfaceViewLoading = styled.div`
    width: 100%;
    height: 100%;
`;

export interface DetailedItemsWidgetDrillDownProps {
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
    drillDownInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    selectedItemId: TonkeanId<TonkeanType.INITIATIVE>;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    viewOnly: boolean;
    fastReloadRequired?: () => void;
    section: ItemInterfaceSection;
    onValidationChangedCallback?: (
        validation: ValidationState,
        setForceShownValidation: (value: boolean) => void,
    ) => void;
}

const DetailedItemsWidgetDrillDown: React.FC<DetailedItemsWidgetDrillDownProps> = ({
    drillDownInterfaceId,
    selectedItemId,
    widgetId,
    projectId,
    viewOnly,
    fastReloadRequired,
    onValidationChangedCallback,
    section,
}) => {
    const drillDownInterfacePermission = viewOnly
        ? ItemInterfacePermission.USER_CANT_EDIT_ITEM
        : ItemInterfacePermission.USER_CAN_EDIT_ITEM;

    return (
        <Wrapper>
            <CustomInterfaceView
                itemInterfaceId={drillDownInterfaceId}
                initiativeId={selectedItemId}
                originWidget={widgetId}
                projectId={projectId}
                forcedPermission={drillDownInterfacePermission}
                showPoweredByTonkean={false}
                CustomHeaderComponent={DetailedItemsWidgetDrillDownHeader}
                CustomLoadingComponent={InterfaceViewLoading}
                initiativeReloadStarted={fastReloadRequired}
                showTitle={false}
                onValidationChangedCallback={onValidationChangedCallback}
                section={section}
                isNested
                cleanMode
            />
        </Wrapper>
    );
};

export default DetailedItemsWidgetDrillDown;
