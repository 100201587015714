import AdobeSignIntegration from './adobesign/AdobeSignIntegration';
import AirtableIntegration from './airtable/AirtableIntegration';
import AllocadiaIntegration from './allocadia/AllocadiaIntegration';
import AmazonKinesisIntegration from './amazonKinesis/AmazonKinesisIntegration';
import AmazonS3Integration from './amazonS3/AmazonS3Integration';
import AmazonTextractIntegration from './amazonTextract/AmazonTextractIntegration';
import AsanaIntegration from './asana/AsanaIntegration';
import AzureBlobStorageIntegration from './azureBlobStorage/AzureBlobStorageIntegration';
import type IntegrationDefinitionBase from './base/IntegrationDefinitionBase';
import OldIntegrationConfiguration from './base/OldIntegrationConfiguration';
import BitbucketIntegration from './bitbucket/BitbucketIntegration';
import BoxIntegration from './box/BoxIntegration';
import BrazeIntegration from './braze/BrazeIntegration';
import CloudConvertIntegration from './cloudConvert/CloudConvertIntegration';
import ConcurIntegration from './concur/ConcurIntegration';
import CoupaIntegration from './coupa/CoupaIntegration';
import DatadogIntegration from './datadog/DatadogIntegration';
import DocuSignIntegration from './docuSign/DocuSignIntegration';
import Dynamics365FOIntegration from './dynamics365fo/Dynamics365FOIntegration';
import EvisortIntegration from './evisort/EvisortIntegration';
import GongIntegration from './gong/GongIntegration';
import GoodTimeIntegration from './goodTime/GoodTimeIntegration';
import GoogleCloudStorageIntegration from './googleCloudStorage/GoogleCloudStorageIntegration';
import GoogleDriveIntegration from './googleDrive/GoogleDriveIntegration';
import GoogleGroupsIntegration from './googleGroups/GoogleGroupsIntegration';
import GoogleSheetsIntegration from './googleSheets/GoogleSheetsIntegration';
import GoogleSlidesIntegration from './googleSlides/GoogleSlidesIntegration';
import HubspotIntegration from './hubspot/HubspotIntegration';
import IroncladIntegration from './ironclad/IroncladIntegration';
import KustomerIntegration from './kustomer/KustomerIntegration';
import LatticeIntegration from './lattice/LatticeIntegration';
import LeverIntegration from './lever/LeverIntegration';
import MailchimpMarketingIntegration from './mailchimpMarketing/MailchimpMarketingIntegration';
import MailchimpTransactionalIntegration from './mailchimptransactional/MailchimpTransactionalIntegration';
import MarketoIntegration from './marketo/MarketoIntegration';
import MicrosoftAdminIntegration from './microsoftAdmin/MicrosoftAdminIntegration';
import MicrosoftTeamsAppIntegration from './microsoftTeamsApp/MicrosoftTeamsAppIntegration';
import MiurosIntegration from './miuros/MiurosIntegration';
import NetDocumentsIntegration from './netDocuments/NetDocumentsIntegration';
import NetsuiteIntegration from './netsuite/NetsuiteIntegration';
import NextCloudIntegration from './nextcloud/NextCloudIntegration';
import NoCodeDataSourceIntegration from './noCodeDataSource/NoCodeDataSourceIntegration';
import NotionIntegration from './notion/NotionIntegration';
import OneDriveIntegration from './oneDrive/OneDriveIntegration';
import Onedrive365Integration from './onedrive365/Onedrive365Integration';
import OrigamiRiskIntegration from './origamiRisk/OrigamiRiskIntegration';
import OutlookIntegration from './outlook/OutlookIntegration';
import OutreachIntegration from './outreach/OutreachIntegration';
import PagerDutyIntegration from './pagerDuty/PagerDutyIntegration';
import PardotIntegration from './pardot/PardotIntegration';
import SAPOpenConnectorIntegration from './sap/SAPOpenConnectorIntegration';
import ServicenowIntegration from './servicenow/ServicenowIntegration';
import SftpIntegration from './sftp/SftpIntegration';
import SharePointIntegration from './sharePoint/sharepointIntegration';
import ShopifyIntegration from './shopify/ShopifyIntegration';
import SimpleLegalIntegration from './simpleLegal/SimpleLegalIntegration';
import SmartsheetWorkspaceIntegration from './smartsheetWorkspace/SmartsheetWorkspaceIntegration';
import SpringCMIntegration from './springCm/SpringCMIntegration';
import SQLDatabaseIntegration from './sqldatabase/SQLDatabaseIntegration';
import TwilioSMSIntegration from './twilio/twiliosms/TwilioSMSIntegration';
import TypeformIntegration from './typeform/TypeformIntegration';
import UiPathIntegration from './uiPath/UiPathIntegration';
import UkgIntegration from './ukg/UkgIntegration';
import WorkdayIntegration from './workday/WorkdayIntegration';
import WorkdayReportsIntegration from './workday/WorkdayReportsIntegration';
import WorkfrontIntegration from './workfront/WorkfrontIntegration';
import ZendeskIntegration from './zendesk/ZendeskIntegration';

import { environment } from '@tonkean/shared-services';
import type { MarketplaceItemSummary } from '@tonkean/tonkean-entities';
// Add New Integration Import Here - DO NOT DELETE OR MODIFY THIS LINE

/**
 * Handles all the new integrations for us.
 */
export default class IntegrationManager {
    /**
     * Holds all the integrations of the new infrastructure.
     */
    private static integrations: IntegrationDefinitionBase[] = [
        new HubspotIntegration(),
        new SmartsheetWorkspaceIntegration(),
        new AmazonS3Integration(),
        new SQLDatabaseIntegration(),
        new SAPOpenConnectorIntegration(),
        new NetsuiteIntegration(),
        new AmazonKinesisIntegration(),
        new MailchimpTransactionalIntegration(),
        new MailchimpMarketingIntegration(),
        new MarketoIntegration(),
        new WorkdayReportsIntegration(),
        new TypeformIntegration(),
        new GongIntegration(),
        new SharePointIntegration(),
        new BitbucketIntegration(),
        new AirtableIntegration(),
        new TwilioSMSIntegration(),
        new SpringCMIntegration(),
        new DatadogIntegration(),
        new GoogleGroupsIntegration(),
        new LatticeIntegration(),
        new ConcurIntegration(),
        new OrigamiRiskIntegration(),
        new GoodTimeIntegration(),
        new WorkdayIntegration(),
        new SftpIntegration(),
        new BoxIntegration(),
        new ShopifyIntegration(),
        new MicrosoftAdminIntegration(),
        new KustomerIntegration(),
        new AsanaIntegration(),
        new GoogleSheetsIntegration(),
        new ZendeskIntegration(),
        new LeverIntegration(),
        new GoogleDriveIntegration(),
        new DocuSignIntegration(),
        new GoogleCloudStorageIntegration(),
        new Onedrive365Integration(),
        new OneDriveIntegration(),
        new UiPathIntegration(),
        new OutreachIntegration(),
        new NetDocumentsIntegration(),
        new AzureBlobStorageIntegration(),
        new EvisortIntegration(),
        new SimpleLegalIntegration(),
        new NotionIntegration(),
        new GoogleSlidesIntegration(),
        new WorkfrontIntegration(),
        new Dynamics365FOIntegration(),
        new PardotIntegration(),
        new PagerDutyIntegration(),
        new CloudConvertIntegration(),
        new BrazeIntegration(),
        new NoCodeDataSourceIntegration(),
        new AdobeSignIntegration(),
        new MicrosoftTeamsAppIntegration(),
        new MiurosIntegration(),
        new CoupaIntegration(),
        new UkgIntegration(),
        new ServicenowIntegration(),
        new AllocadiaIntegration(),
        new OutlookIntegration(),
        new NextCloudIntegration(),
        new AmazonTextractIntegration(),
        new IroncladIntegration(),
        // Add New Integration Here - DO NOT DELETE OR MODIFY THIS LINE
    ];

    /**
     * Generates the old configuration out of the new configuration for all the new integrations.
     */
    public static getOldIntegrationsConfiguration($rootScope): Record<string, OldIntegrationConfiguration> {
        return Object.fromEntries(
            this.integrations.map((integration) => {
                return [integration.name, new OldIntegrationConfiguration(integration, $rootScope)];
            }),
        );
    }

    /**
     * Returns array of the requested configuration.
     * For example: [{integrationName: hubspot, displayName: 'Hubspot' }]
     * @param propertyName The requested configuration field name.
     */
    public static getConfigurationByPropertyName(
        propertyName: string,
    ): ({ integrationName: string; integrationDisplayName: string } & Record<string, any>)[] {
        return this.integrations
            .filter((integration) => integration[propertyName] !== undefined)
            .map((integration) => ({
                integrationName: integration.name.toUpperCase(),
                integrationDisplayName: integration.displayName,
                [propertyName]: integration[propertyName],
            }));
    }

    /**
     * Gets a specific integration by its name.
     */
    public static getIntegrationByName(integrationName: string): IntegrationDefinitionBase | undefined {
        return this.integrations.find(
            (integration) => integration.name.toLowerCase() === integrationName.toLowerCase(),
        );
    }

    /**
     * Gets all fast integrations.
     */
    public static getAllFastIntegrations(): IntegrationDefinitionBase[] {
        return this.integrations;
    }

    private static getBaseMarketplaceUrl() {
        if (environment.isLocal) {
            return environment.config.environments.test?.marketplaceUrl;
        }
        if (environment.marketplaceUrl) {
            return environment.marketplaceUrl;
        }
        return environment.config.environments.prod?.marketplaceUrl;
    }

    public static async loadMarketplaceDataSources(): Promise<MarketplaceItemSummary[]> {
        try {
            const url = `${this.getBaseMarketplaceUrl()}integrations.json`;
            const integrations = await fetch(url).then((res) => res.json());

            return integrations.map((item) => ({
                ...item,
                name: item.templateName, // required for search capability
                imgUrl: item.iconUrl,
            }));
        } catch (error) {
            console.log(error);
            return [];
        }
    }
}
