<div class="dropdown-search">
    <div uib-dropdown>
        <!-- Dropdown toggle -->
        <div uib-dropdown-toggle class="pointer flex-vmiddle" ng-disabled="data.viewOnly">
            <div
                ng-bind="data.currentlySelectedItem ? data.selectedLabel : data.closedPlaceholder"
                class="{{ data.labelClasses }}"
                ng-class="{ 'common-color-link-blue': data.currentlySelectedItem }"
            ></div>
            <span class="margin-left-xxs" ng-hide="data.hideDropdownChevron">
                <span class="dropdown-chevron mod-static"></span>
            </span>
        </div>

        <!-- Dropdown menu -->
        <ul uib-dropdown-menu class="padding-top-none padding-bottom-none dropdown-menu">
            <!-- Search box menu item -->
            <li class="relative">
                <input
                    type="text"
                    class="form-control common-input-only-line padding-left dropdown-search-input"
                    ng-class="{ 'padding-right-lg': data.enableClear && data.currentlySelectedItem }"
                    placeholder="{{ data.placeholder }}"
                    ng-change="searchChanged()"
                    ng-model="data.searchText"
                    ng-click="searchBoxClicked($event)"
                />
                <span
                    ng-if="data.enableClear && data.currentlySelectedItem"
                    ng-click="onItemSelected(null)"
                    class="
                        svg-icon-vmiddle svg-icon-hover-dark-grey
                        absolute-center-v absolute-right
                        padding-right-xs
                        margin-top-xs-minus
                        common-color-grey
                    "
                >
                    <tnk-icon src="../../../../svg/src/icons/x.svg"></tnk-icon>
                </span>
            </li>
            <!-- Dropdown menu item -->
            <div class="dropdown-menu-item-container">
                <!-- Items state -->
                <li
                    ng-if="(data.items | filter: data.searchText).length > 0"
                    ng-repeat="opt in data.items | filter:data.searchText"
                    ng-click="onItemSelected(opt)"
                    class="padding-normal-sm common-size-xxs dropdown-item pointer"
                >
                    {{ data.itemsDisplayNameProperty ? opt[data.itemsDisplayNameProperty] : opt.displayName || opt }}
                </li>

                <!-- Empty state -->
                <li
                    ng-if="(data.items | filter: data.searchText).length === 0"
                    class="padding-normal-sm common-size-xxs common-color-grey"
                >
                    No results found...
                </li>
            </div>
        </ul>
    </div>
</div>
