import { Validation, type ValidationResult, type TonkeanId, type TonkeanType } from '@tonkean/tonkean-entities';

const checkAllWidgetsAreValid = (
    widgetsValidation: Record<string, ValidationResult[]>,
    widgetsIdsToValidate: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[],
    touchedWidgetsIds?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[],
) => {
    if (widgetsIdsToValidate.length === 0 || Object.keys(widgetsValidation).length === 0) {
        return Validation.NOTHING_TO_VALIDATE;
    }

    const widgetsToValidateMap = Object.fromEntries(
        widgetsIdsToValidate
            .filter((widgetIdToValidate) => !touchedWidgetsIds || touchedWidgetsIds.includes(widgetIdToValidate))
            .map((key) => [key, true]),
    );
    const validValidationResults = Object.entries(widgetsValidation)
        .filter(([key, value]) => widgetsToValidateMap[key])
        .map(([key, value]) => value);
    const hasAnyInvalidResults = validValidationResults.some((value) =>
        value.some((currValidationResult) => currValidationResult.validation === Validation.INVALID),
    );

    return hasAnyInvalidResults ? Validation.INVALID : Validation.VALID;
};

export default checkAllWidgetsAreValid;
