import type { LogicImpact } from '@tonkean/tonkean-entities';
import { CustomTriggerType } from '@tonkean/tonkean-entities';

const formAnsweredCanCreate = (childImpacts: LogicImpact[]) => {
    // If there are no child triggers with this type, it can be created
    return (
        !childImpacts?.length ||
        childImpacts.filter((trigger) => trigger.node.customTriggerType === CustomTriggerType.SEND_FORM_ANSWERED)
            .length === 0
    );
};

export default formAnsweredCanCreate;
