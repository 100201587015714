import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import ResponseViewTabType from './ResponseViewTabType';
import { ProjectIntegrationActionManagerTabKey } from '../../../../../../../components/state.product.projectIntegrationActionManager/ProjectIntegrationActionManagerTabKey';
import ProjectIntegrationActionTestResponseStatusCode from '../ProjectIntegrationActionTestResponseStatusCode';

import { useToastMessage } from '@tonkean/angular-hooks';
import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    BasicKeyValueTable,
    InputWithButton,
    JsonPickerBlock,
    Tab,
    Tabs,
    TabsBorderType,
} from '@tonkean/infrastructure';
import { ProjectIntegrationActionDefinitionType } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationActionTestRun } from '@tonkean/tonkean-entities';
import { Link } from '@tonkean/tui-buttons/Link';
import { FontSize, Theme } from '@tonkean/tui-theme';

const JsonPickerEmptyState = styled.div`
    height: 300px;
    background: ${Theme.colors.gray_100};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${Theme.colors.gray_300};
`;

const AnimatedSwitchItemWrapper = styled(AnimatedSwitchItem)`
    height: 350px;
    overflow: auto;
    margin-top: 26px;
`;

const JsonPickerEmptyStateTitle = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
    margin-bottom: 15px;
`;

const JsonPickerEmptyStateContent = styled.div`
    text-align: center;
    padding: 0 40px;
`;

const InputWithButtonWrapper = styled.div`
    margin-top: 16px;
    width: 100%;
`;

const ProjectIntegrationActionTestResponseStatusCodeWrapper = styled.div`
    margin: auto 0 auto auto;
`;

interface Props {
    projectIntegrationActionTestRun?: ProjectIntegrationActionTestRun;
    switchToRequestTab: (tabKey: string) => void;
}

const ResponseDetailsView: React.FC<Props> = ({ projectIntegrationActionTestRun, switchToRequestTab }) => {
    // State for the viewing Tab
    const [selectedTab, setSelectedTab] = useState(ResponseViewTabType.RESPONSE_BODY);
    const [jsonPath, setJsonPath] = useState<string>();
    const emitToastMessage = useToastMessage();
    const utils = useAngularService('utils');

    const latestRequestExecutionResult = useMemo(() => {
        if (projectIntegrationActionTestRun) {
            return projectIntegrationActionTestRun.executionResultList[
                projectIntegrationActionTestRun.executionResultList.length - 1
            ];
        }
        return undefined;
    }, [projectIntegrationActionTestRun]);

    const copyJsonPathToClipboard = () => {
        if (jsonPath) {
            const isCopied = utils.copyToClipboardFromText(jsonPath);

            if (isCopied) {
                emitToastMessage('Copied successfully', 'success');
            } else {
                emitToastMessage("Couldn't copy, Try again", 'warning');
            }
        }
    };

    const responseHeaders = useMemo(() => {
        if (latestRequestExecutionResult?.type === ProjectIntegrationActionDefinitionType.HTTP) {
            try {
                return JSON.parse(latestRequestExecutionResult?.response?.responseHeadersString || '{}');
            } catch {
                return {};
            }
        }

        return {};
    }, [latestRequestExecutionResult]);

    const responseBody: Record<any, any> | string | undefined = useMemo(() => {
        try {
            return JSON.parse(latestRequestExecutionResult?.response?.body || '');
        } catch {
            return latestRequestExecutionResult?.response?.body;
        }
    }, [latestRequestExecutionResult?.response?.body]);

    return (
        <>
            <Tabs
                showBackground={false}
                value={selectedTab}
                onChange={setSelectedTab}
                borderType={TabsBorderType.RAILWAY}
                height="46px"
            >
                <Tab disabled={!projectIntegrationActionTestRun} label={ResponseViewTabType.RESPONSE_BODY}>
                    {ResponseViewTabType.RESPONSE_BODY}
                </Tab>

                {latestRequestExecutionResult?.type === ProjectIntegrationActionDefinitionType.HTTP && (
                    <Tab disabled={!projectIntegrationActionTestRun} label={ResponseViewTabType.HEADERS}>
                        {ResponseViewTabType.HEADERS}
                    </Tab>
                )}

                {latestRequestExecutionResult?.type === ProjectIntegrationActionDefinitionType.HTTP &&
                    latestRequestExecutionResult?.response?.responseCode && (
                        <ProjectIntegrationActionTestResponseStatusCodeWrapper>
                            <ProjectIntegrationActionTestResponseStatusCode
                                statusCode={latestRequestExecutionResult?.response?.responseCode}
                            />
                        </ProjectIntegrationActionTestResponseStatusCodeWrapper>
                    )}
            </Tabs>

            <AnimatedSwitch activeLabel={selectedTab}>
                <AnimatedSwitchItemWrapper label={ResponseViewTabType.RESPONSE_BODY}>
                    {projectIntegrationActionTestRun && (
                        <JsonPickerBlock
                            json={responseBody || ''}
                            onClick={(selectedJsonPath) =>
                                setJsonPath(`<# JsonPath({{body}}, '${selectedJsonPath}', false) #>`)
                            }
                            height="300px"
                        />
                    )}

                    {!projectIntegrationActionTestRun && (
                        <JsonPickerEmptyState>
                            <JsonPickerEmptyStateTitle>No test runs were created yet</JsonPickerEmptyStateTitle>
                            <JsonPickerEmptyStateContent>
                                In order to use simple field extraction from request json - navigate to the{' '}
                                <Link onClick={() => switchToRequestTab(ProjectIntegrationActionManagerTabKey.REQUEST)}>
                                    Request
                                </Link>{' '}
                                category and run the first test on this action
                            </JsonPickerEmptyStateContent>
                        </JsonPickerEmptyState>
                    )}

                    <InputWithButtonWrapper>
                        <InputWithButton
                            buttonClickableProps={{
                                onClick: copyJsonPathToClipboard,
                                disabled: !projectIntegrationActionTestRun,
                            }}
                            buttonText="Copy"
                            onInputChange={({ target }) => setJsonPath(target.value)}
                            inputValue={jsonPath || ''}
                            placeholder="JsonPath($.id',false)"
                        />
                    </InputWithButtonWrapper>
                </AnimatedSwitchItemWrapper>

                <AnimatedSwitchItemWrapper label={ResponseViewTabType.HEADERS}>
                    {latestRequestExecutionResult?.type === ProjectIntegrationActionDefinitionType.HTTP &&
                        latestRequestExecutionResult?.response?.responseHeadersString && (
                            <BasicKeyValueTable
                                keyValueRecord={responseHeaders}
                                keyColumnWidth={200}
                                loadingPlaceholderCount={4}
                            />
                        )}
                </AnimatedSwitchItemWrapper>
            </AnimatedSwitch>
        </>
    );
};

export default ResponseDetailsView;
