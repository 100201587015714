import useQueryClientAdapter from '../utils/reactQuery/useQueryClientAdapter';
import useTonkeanQuery from '../utils/reactQuery/useTonkeanQuery';

import { pollInitiative } from '@tonkean/network-service';
import type { Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface UseTDLPollInitiativeProps {
    projectId: TonkeanId<TonkeanType.PROJECT> | undefined;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    lastPolledInitiativeUpdateTime: number | undefined;
    pollingInterval: number;
    pollingEnabled: boolean;
}

const useTDLPollInitiative = ({
    projectId,
    initiativeId,
    lastPolledInitiativeUpdateTime,
    pollingInterval,
    pollingEnabled = true,
}: UseTDLPollInitiativeProps) => {
    const queryClientAdapter = useQueryClientAdapter();

    return useTonkeanQuery<Initiative | undefined>({
        queryUniqueKey: 'pollInitiative',
        propsUniqueKeysArray: [projectId, initiativeId],
        queryFn: async (networkService) => {
            const cachedPolledInitiative = queryClientAdapter.getCachedData<Initiative>({
                queryUniqueKey: 'pollInitiative',
                propsUniqueKeysArray: [projectId, initiativeId],
            });
            return pollInitiative(
                networkService,
                projectId!,
                initiativeId!,
                lastPolledInitiativeUpdateTime ?? 0,
                cachedPolledInitiative,
            );
        },
        enabledFn: () => !!projectId && !!initiativeId && pollingEnabled,
        useDeprecatedRelatedEntitiesEnrichment: true,
        polling: {
            enabled: pollingEnabled,
            interval: pollingInterval,
        },
    });
};

export default useTDLPollInitiative;
export type { UseTDLPollInitiativeProps };
