import React from 'react';
import styled from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import { randomNumber, range } from '@tonkean/utils';

const TextPlaceHolder = styled(Placeholder)`
    margin-top: 2px;
`;

interface Props {}

const ProjectIntegrationLoadingBoxesView: React.FC<Props> = () => {
    return (
        <>
            {range(randomNumber(8, 20)).map((i) => (
                <div key={i}>
                    <Placeholder $height="60px" $width="60px" />
                    <TextPlaceHolder $height="14px" $width="60" />
                </div>
            ))}
        </>
    );
};

export default ProjectIntegrationLoadingBoxesView;
