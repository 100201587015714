import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function CoupaCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        definition: ctrl.definition,
        possiblePurchaseOrderLineTypes: ['', 'OrderQuantityLine', 'OrderAmountLine'],
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'ADD_PURCHASE_ORDER_LINE':
                if (!$scope.data.definition.purchaseOrderLines) {
                    $scope.data.definition.purchaseOrderLines = [];
                    $scope.addPurchaseOrderLine();
                }
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    /**
     * Purchase Order Lines
     */
    $scope.addPurchaseOrderLine = function () {
        $scope.data.definition.purchaseOrderLines.push({
            lineType: $scope.data.possiblePurchaseOrderLineTypes[0],
        });
    };

    $scope.removePurchaseOrderLine = function (index) {
        $scope.data.definition.purchaseOrderLines.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.onLineLineIdTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.purchaseOrderLines[index].lineIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLineDescriptionTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.purchaseOrderLines[index].descriptionExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLineQuantityTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.purchaseOrderLines[index].quantityExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLinePriceTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.purchaseOrderLines[index].priceExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLineCurrencyTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.purchaseOrderLines[index].currencyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('CoupaCustomActionsDefinitionCtrl', lateConstructController(CoupaCustomActionsDefinitionCtrl));
