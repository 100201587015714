import { analyticsWrapper } from '@tonkean/analytics';
import { getSubscriptionTiers } from '@tonkean/constants';

function SettingsCtrl(
    $scope,
    $log,
    $state,
    $stateParams,
    $q,
    tonkeanService,
    utils,
    timeUtils,
    consts,
    projectManager,
    modal,
) {
    const tiersMap = getSubscriptionTiers();
    const intervalsMap = consts.getPlanIntervals();
    $scope.projectId = $stateParams.projectId;
    $scope.pm = projectManager;
    $scope.isLicensePage = false;
    $scope.$state = $state;
    $scope.currentState = $state.current.name;

    $scope.shouldShowEnterpriseComponentTab = true;

    // Listen to state changes.
    $scope.$on('$stateChangeSuccess', function () {
        updateIsLicensePage();
    });

    // plans holds the yearly and monthly plans for the current selected tier.
    $scope.plans = {};
    $scope.plan = null;

    // Data that is shared with inner ui-view controllers (such as orgOverviewCtrl, orMembersCtrl and orgInvoicesCtrl).
    $scope.sharedData = {
        trialDaysLeft: consts.trialDaysLength,
        intervals: intervalsMap,
        intervalsArray: Object.keys(intervalsMap),
        tiers: utils.objValues(tiersMap),
        tiersMap,
        selectedTier: tiersMap['STANDARD'],
        selectedInterval: intervalsMap.year,
        tiersToPlans: {},
        allPlans: [], // allPlans will hold plans from all tiers in a flattened array as returned from the server.
    };

    $scope.init = function () {
        updateIsLicensePage();

        // If we're not licensed, we have to initialize some basic info about a basic plan until we get the plans from the server.
        if (!$scope.pm.isLicensed) {
            $scope.plan = {
                quantity: 1,
            };
        }

        $scope.getLicenseError = false;

        // If the settings-upgrade page is opened as a modal, we can't take the projectId from the $stateParams. We take if from the pm.
        if (!$scope.projectId) {
            $scope.projectId = $scope.pm.project ? $scope.pm.project.id : null;
        }

        if ($scope.pm.project.expirationDate && $scope.pm.project.isInTrial) {
            $scope.sharedData.trialDaysLeft = timeUtils.daysDiff($scope.pm.project.expirationDate);
        }

        $scope.loadingOrg = true;
        $scope
            .populatePlans()
            .then(function () {
                // Set the default plan.
                $scope.plan = $scope.plans['YEAR'];

                $scope.updateOrganization();
            })
            .catch(function () {
                $scope.getLicenseError = true;
                $scope.loadingOrg = false;
            })
            .finally(() => {
                $scope.orgLoaded = true;
            });

        // Load Stripe if it wasn't already loaded, since the user might need it if he wants to pay
        // in the inner upgrade page (and we don't want to keep him waiting for it to load).
        if (typeof Stripe === 'undefined' || Stripe === undefined || Stripe === null) {
            utils.loadScript('https://js.stripe.com/v3/', function () {
                $log.info('Stripe loaded');
            });
        }
    };

    $scope.populatePlans = function (couponCode) {
        return tonkeanService
            .getPlans($scope.projectId, couponCode)
            .then(function (data) {
                // Reset the all plans array before updating it (so we won't have any duplicates).
                $scope.sharedData.allPlans = [];

                for (let i = 0; i < data.plans.length; i++) {
                    const p = data.plans[i];
                    p.quantity = 1;
                    p.amountPerSeat = p.amountPerSeat / 100;
                    p.amountPerSeatPerMonth = p.amountPerSeatPerMonth / 100;

                    $scope.sharedData.allPlans.push(p);

                    if (!$scope.sharedData.tiersToPlans[p.tier]) {
                        $scope.sharedData.tiersToPlans[p.tier] = {};
                    }
                    $scope.sharedData.tiersToPlans[p.tier][p.interval.toUpperCase()] = p;
                }

                $scope.setCurrentTierPlans($scope.sharedData.selectedTier);
            })
            .catch(function (error) {
                $log.error(error);
                return $q.reject(error);
            })
            .finally(() => {
                $scope.orgLoaded = true;
            });
    };

    $scope.setCurrentTierPlans = function (tier) {
        $scope.plans['MONTH'] = utils.findFirst(
            $scope.sharedData.allPlans,
            (plan) => plan.tier === tier.apiName && plan.interval === $scope.sharedData.intervals.month,
        );
        $scope.plans['YEAR'] = utils.findFirst(
            $scope.sharedData.allPlans,
            (plan) => plan.tier === tier.apiName && plan.interval === $scope.sharedData.intervals.year,
        );
    };

    $scope.updateOrganization = function (softLoad) {
        if ($scope.pm.project.licensed) {
            $scope.loadingOrg = !softLoad;
            $scope.getLicenseError = false;
            tonkeanService
                .getProjectLicense($scope.projectId)
                .then(function (data) {
                    $scope.license = data.license;
                    $scope.buyer = $scope.pm.project.license
                        ? $scope.pm.project.license.buyer
                        : $scope.pm.project.creator;
                    if ($scope.license && $scope.license.customer && $scope.license.customer.plan) {
                        $scope.plan = $scope.license.customer.plan;
                        $scope.plan.amountPerSeat = $scope.plan.amountPerSeat / 100;
                        $scope.plan.amountPerSeatPerMonth = $scope.plan.amountPerSeatPerMonth / 100;
                        $scope.plan.quantity = $scope.license.customer.subscription.seats;

                        $scope.sharedData.selectedTier = tiersMap[$scope.plan.tier];
                        $scope.sharedData.selectedInterval = intervalsMap[$scope.plan.interval];
                    }
                    if ($scope.license && $scope.license.upcomingInvoice) {
                        $scope.firstSubscriptionLine =
                            utils.findFirst($scope.license.upcomingInvoice.lines, function (l) {
                                return l.isSubscriptionLine;
                            }) || $scope.license.upcomingInvoice.lines[0];

                        if ($scope.license.upcomingInvoice.discount) {
                            $scope.plan.amountOff = $scope.license.upcomingInvoice.discount.amountOff
                                ? $scope.license.upcomingInvoice.discount.amountOff / 100
                                : null;
                            $scope.plan.percentOff = $scope.license.upcomingInvoice.discount.percentOff
                                ? $scope.license.upcomingInvoice.discount.percentOff / 100
                                : null;
                        }
                    }

                    // if we do have license, we don't want to allow the upgrade page at all
                    if ($state.current.name === 'product.settings.license' && $state.params.tab === 'upgrade') {
                        $state.go('product.settings.license', { tab: 'overview' }, { location: 'replace' });
                    }
                })
                .catch(function () {
                    $scope.getLicenseError = true;
                })
                .finally(function () {
                    $scope.loadingOrg = false;
                    $scope.orgLoaded = true;
                });
        } else {
            if ($scope.pm.project.isLimitedLicense) {
                // if free use the free plan from the project entity
                $scope.plan = $scope.pm.project.license;
                $scope.plan.amountPerSeat = 0;
                $scope.plan.amountPerSeatPerMonth = 0;
                $scope.plan.quantity = $scope.pm.project.license.seats;
                $scope.plan.maximumQuantity = $scope.pm.project.license.seats;
                $scope.plan.interval = $scope.sharedData.intervals.year;

                $scope.loadingOrg = false;
                $scope.orgLoaded = true;
            } else {
                $scope.loadingTeamMembers = true;
                tonkeanService
                    .getTeamMembers($scope.projectId, { onlyUsers: true })
                    .then(function (data) {
                        $scope.plan.quantity = data.total || 1;
                    })
                    .finally(function () {
                        $scope.loadingTeamMembers = false;
                        $scope.loadingOrg = false;
                        $scope.orgLoaded = true;
                    });
            }
        }
    };

    $scope.setPlan = function (id) {
        const currentQuantity = $scope.plan.quantity;
        $scope.plan = $scope.plans[id] || $scope.plans['MONTH'];
        $scope.plan.quantity = currentQuantity;
    };

    $scope.changeSeats = function (seats) {
        seats = seats.toFixed(0);
        if (seats < 1) {
            seats = 1;
        }

        analyticsWrapper.track('Change number of seats', { category: 'Subscription' });
        if (seats < $scope.plan.quantity) {
            $scope.data.changeSeatsLoading = true;
            // check if they need to remove full users first
            tonkeanService
                .getTeamMembers($scope.projectId, { onlyLicensed: true })
                .then(function (data) {
                    if (data && data.total > seats) {
                        // can't update
                        $scope.questionConfirmModalData = {
                            title: 'Plan seats change',
                            body: `You can't change your seats number to ${seats} because you currently have <b>${data.total} full users</b>.<br/> Remove the users you don't want first and then try again.`,
                            okLabel: 'Manage Full Users',
                            cancelLabel: 'Cancel',
                        };

                        modal
                            .openQuestionConfirmModal({
                                scope: $scope,
                                windowClass: 'mod-warning',
                            })
                            .result.then(function () {
                                // okLabel
                                $state.go('product.settings.license', { tab: 'members' });
                            });
                    } else {
                        $scope.updateSeats(seats);
                    }

                    $scope.data.showChangeSeats = false;
                })
                .finally(function () {
                    $scope.data.changeSeatsLoading = false;
                    $scope.orgLoaded = true;
                });
        } else {
            $scope.updateSeats(seats);
            $scope.data.showChangeSeats = false;
        }
    };

    /**
     * Update the project's seats.
     * @param seats - the number of seats to set on the project.
     * @param shouldBuyMoreSeats - whether the user doesn't have enough seats and should buy more.
     * @param softLoad - then true, the main "loadingOrg" flag won't be turned to true. This can be used to avoid from re-loading the inner ui-view.
     * @param callback - the callback to call after updating the server (in case the user approves).
     */
    $scope.updateSeats = function (seats, shouldBuyMoreSeats, softLoad, callback) {
        const amountOff = $scope.plan.amountOff;
        let currentCost = ($scope.plan.amountPerSeatPerMonth * $scope.plan.quantity).toFixed(2);
        let finalCost = ($scope.plan.amountPerSeatPerMonth * seats).toFixed(2);
        if (amountOff) {
            currentCost -= amountOff;
            finalCost -= amountOff;
            if (currentCost < 0) {
                currentCost = 0;
            }
            if (finalCost < 0) {
                finalCost = 0;
            }
        }

        const currency = '$'; // We currently only support charging dollars.

        const pretext =
            seats > $scope.plan.quantity
                ? `Adding ${seats - $scope.plan.quantity}`
                : `Removing ${$scope.plan.quantity - seats}`;
        const seatPostfix = Math.abs(seats - $scope.plan.quantity) > 1 ? ' seats' : ' seat';

        let bodyText = `${
            pretext + seatPostfix
        } to your plan will change your total monthly cost to ${currency}${finalCost} (was ${currency}${currentCost}).`;
        if (shouldBuyMoreSeats) {
            bodyText = `You don't have enough available seats.<br/>Click submit if you want to add additional seats in order to license those users.<br/><br/>${bodyText}`;
        }

        $scope.questionConfirmModalData = {
            title: 'Plan seats change',
            body: bodyText,
            okLabel: 'Submit',
            cancelLabel: 'Cancel',
        };

        modal
            .open({
                scope: $scope,
                windowClass: 'mod-primary',
            })
            .result.then(function () {
                // okLabel
                const licenseData = {
                    seats,
                    plan: $scope.plan.id,
                };
                $scope.loadingOrg = !softLoad;
                tonkeanService
                    .createOrUpdateLicense($scope.pm.project.id, licenseData)
                    .then(function () {
                        $scope.$emit('alert', {
                            type: 'success',
                            msg: 'Plan seats were changed successfully!',
                        });
                        $scope.updateOrganization(softLoad);

                        if (callback) {
                            callback(true);
                        }
                    })
                    .catch(function (error) {
                        $scope.$emit('alert', error);
                        $scope.loadingOrg = false;
                        $scope.orgLoaded = true;
                        if (callback) {
                            callback(false);
                        }
                    })
                    .finally(() => {
                        $scope.orgLoaded = true;
                    });
            })
            .catch(function () {
                // cancelLabel
                if (callback) {
                    callback(false);
                }
            })
            .finally(() => {
                $scope.orgLoaded = true;
            });
    };

    /**
     * Parses the given new plan quantity (number of users) for validity and return a valid number of seats.
     * @param newQuantity - the new quantity to validate and save.
     * @returns a valid quantity to save into the real quantity model.
     */
    $scope.parseNewPlanQuantity = function (newQuantity) {
        // Cast the new quantity to string so isNan works as expected across browsers (including safari that thinks @ is a number).
        const stringQuantity = String(newQuantity);

        // Check if the new quantity is valid: is a number and not smaller than 1.
        if (Number.isNaN(stringQuantity) || newQuantity < 1) {
            // The new quantity was not valid - set 1 as a default value.
            return 1;
        } else {
            // The new quantity is valid. Use it.
            return newQuantity;
        }
    };

    /**
     * Enables child ui-views to update the license object.
     * @param newLicense - the new license.
     */
    $scope.setLicenseObject = function (newLicense) {
        $scope.license = newLicense;
    };

    /**
     * Enables child ui-views to update the loadingOrg param if needed.
     * @param isLoading - a boolean value set to loadingOrg.
     */
    $scope.setLoadingOrg = function (isLoading) {
        $scope.loadingOrg = isLoading;
    };

    /**
     * Sets the isLicensePage flag according to the current state.
     */
    function updateIsLicensePage() {
        // If this is a page that has something to do with the license, we mark it as such.
        // This is needed to handle errors in loading the license via the updateOrganization function.
        // If it fails it marks a flag. This info is critical in license pages.
        // In these cases this flag will be take into consideration.
        $scope.isLicensePage = $state.current.name.includes('product.settings.license');
    }

    $scope.$on('$stateChangeSuccess', function (e, toState) {
        $scope.currentState = toState.name;
    });

    /**
     * A hack to make sure the BoardSettings react component can render the proper sections only when the data is fully loaded
     */
    $scope.$watchGroup(
        [
            "!!(currentState !== 'product.settings.license' || $state.params.tab !== 'upgrade')",
            '!!(!pm.isExpired && pm.isOwner)',
            '!!(!pm.isExpired && pm.isOwner && shouldShowEnterpriseComponentTab)',
            '!!(!loadingOrg && !pm.isEnterprise && (!pm.isLicensed || pm.isOwner))',
            '!!((!loadingOrg && !pm.isEnterprise && (!pm.isLicensed || pm.isOwner)) &&  ((pm.isBuyer || pm.isOwner) && license))',
            'orgLoaded',
        ],
        (expressions) => {
            $scope?.setVisiblePages({
                all: expressions[0],
                generalAndPermissions: expressions[1],
                enterpriseComponents: expressions[2],
                subscription: expressions[3],
                invoices: expressions[4],
                orgLoaded: expressions[5],
            });
        },
    );

    $scope.init();
}

export default angular.module('tonkean.app').controller('SettingsCtrl', SettingsCtrl);
