import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import SolutionReportIcon from './SolutionReportIcon';
import SolutionReportListItemKebabMenu from './SolutionReportListItemKebabMenu';
import SolutionReportListItemMetric from './SolutionReportListItemMetric';
import SolutionReportListItemMetricEnvironment from './SolutionReportListItemMetricEnvironment';
import SolutionReportListItemMetricModal from './SolutionReportListItemMetricModal';
import type { SolutionReport } from '../entities/SolutionReportType';
import { SolutionReportTypes } from '../entities/SolutionReportType';

import { Modal, ModalSize, Placeholder, TextEllipsis } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Row = styled(Clickable)`
    display: grid;
    width: 100%;
    height: 58px;
    border: 1px solid ${Theme.colors.gray_200};
    border-radius: 3px;
    gap: 8px;
    background-color: #ffffff;
    grid-template-columns: 1fr 550px;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_800};
`;

const StyledIcon = styled.div`
    margin: 0 17px;

    svg {
        height: 16px;
        width: 16px;
    }
`;

const MetricsAndMenu = styled.div`
    display: flex;
    align-items: center;
    flex: 0 1 616px;
    justify-content: space-between;
`;

const Metrics = styled.div`
    flex: 0;
    display: grid;
    gap: 98px;
    grid-template-columns: repeat(3, 1fr);
`;

const EnvironmentsList = styled.div`
    display: flex;
    gap: 10px;
`;

interface Props {
    solutionReportId: string | undefined;
    solutionReport: SolutionReport | undefined;
    loading: boolean;
}

const SolutionReportsListItem: React.FC<Props> = ({ solutionReportId, solutionReport, loading }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalHeader, setModalHeader] = useState<string>('');
    const [modalData, setModalData] = useState<string[]>([]);

    const onMetricClick = useCallback((header: string, data: string[]) => {
        setModalHeader(header);
        setModalData(data);
        setIsOpen(true);
    }, []);

    return (
        <>
            <Row buttonAsDiv>
                <Title>
                    <StyledIcon>
                        {loading ? (
                            <Placeholder $width="16px" $circle />
                        ) : (
                            solutionReport && <SolutionReportIcon type={solutionReport?.type} />
                        )}
                    </StyledIcon>
                    {loading ? (
                        <Placeholder $width="200px" />
                    ) : (
                        <TextEllipsis numberOfLines={1} tooltip>
                            {solutionReport?.displayName}
                        </TextEllipsis>
                    )}
                </Title>
                <MetricsAndMenu>
                    <Metrics>
                        <SolutionReportListItemMetric
                            header="Business Actions"
                            value={
                                solutionReport?.type === SolutionReportTypes.SOLUTION
                                    ? solutionReport?.actions.length
                                    : '-'
                            }
                            onClick={
                                loading
                                    ? undefined
                                    : () => onMetricClick('All business actions', solutionReport?.actions ?? [])
                            }
                            disabled={solutionReport?.actions.length === 0}
                            underline={solutionReport?.actions.length !== 0}
                            loading={loading}
                        />
                        <SolutionReportListItemMetric
                            header="Modules"
                            value={solutionReport?.groupNames.length}
                            onClick={
                                loading
                                    ? undefined
                                    : () => onMetricClick('All modules', solutionReport?.groupNames ?? [])
                            }
                            disabled={solutionReport?.groupNames.length === 0}
                            underline={solutionReport?.groupNames.length !== 0}
                            loading={loading}
                        />
                        <SolutionReportListItemMetric
                            header="Environment"
                            value={
                                <EnvironmentsList>
                                    {solutionReport?.environments.map((environment) => (
                                        <SolutionReportListItemMetricEnvironment
                                            key={environment}
                                            environment={environment}
                                        />
                                    ))}
                                </EnvironmentsList>
                            }
                            loading={loading}
                            disabled
                        />
                    </Metrics>
                    <SolutionReportListItemKebabMenu solutionReport={solutionReport} />
                </MetricsAndMenu>
            </Row>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                size={ModalSize.MEDIUM}
                backdropCloses={false}
                fixedWidth
            >
                <SolutionReportListItemMetricModal
                    header={modalHeader}
                    data={modalData}
                    onClose={() => setIsOpen(false)}
                />
            </Modal>
        </>
    );
};

export default SolutionReportsListItem;
