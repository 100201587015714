import { useAngularService } from 'angulareact';
import React, { useId, useMemo } from 'react';
import styled from 'styled-components';

import TimelineDateGroup from './TimelineDateGroup';
import TimelineEventsLoadingState from './TimelineEventsLoadingState';
import { ItemInterfacePermission } from '../../../../entities';
import type { ItemWidgetProps } from '../../../../WidgetModule';
import { ItemWidget } from '../../../../WidgetModule';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import {
    Breakpoint,
    NoItemEmptyState,
    StateMessage,
    useInitiativeActivityFetchManager,
    useItemInterfaceContext,
    useModifiableComments,
} from '@tonkean/infrastructure';
import { TimelineWidgetIcon } from '@tonkean/svg';
import type { ItemInterfaceWidgetConfiguration, Activity } from '@tonkean/tonkean-entities';
import {
    ActivityType,
    TonkeanType,
    WorkflowVersionType,
    tonkeanTypeToIdentifyingPrefixMap,
} from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import utils, { colorSvg } from '@tonkean/utils';

const ListWrapper = styled.div`
    padding: 14px 24px 24px;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: 0 15px;
    }
`;

const EmptyTimelineIcon = styled(TimelineWidgetIcon)`
    width: 81px;
    height: 81px;
    ${colorSvg(Theme.colors.gray_500)};
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const LoadMoreButton = styled(Button)`
    margin-top: 12px;
`;

const TIMELINE_ACTIVITY_TYPES: ActivityType[] = [
    ActivityType.INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED,
    ActivityType.INITIATIVE_STATUS_CHANGED,
    ActivityType.INITIATIVE_MANUAL_FIELD_VALUE_CHANGED,
    ActivityType.INITIATIVE_DUE_DATE_CHANGED,
    ActivityType.INITIATIVE_LEADER_CHANGED,
    ActivityType.INITIATIVE_DESCRIPTION_CHANGED,
    ActivityType.BOT_GATHER,
    ActivityType.NEW_COMMENT,
    ActivityType.FORM_SUBMITTED,
    ActivityType.ITEM_INTERFACE_SUBMITTED,
    ActivityType.ITEM_INTERFACE_BUTTON_CLICKED,
    ActivityType.INNER_INITIATIVE_CREATED,
    ActivityType.INITIATIVE_FILE_UPLOADED,
];

interface EventsGroup {
    date: string;
    events: Activity[];
}

const groupEvents = (events: Activity[]): EventsGroup[] => {
    const eventDates = utils.groupBy(events, (event) => utils.formatDate(event.created, true, true, true, true));

    return Object.entries(eventDates)
        .map(([date, _events]) => {
            return {
                date,
                events: _events!.sort((first, second) => second.created - first.created),
            };
        })
        .sort((first, second) => second.events[0]!.created - first.events[0]!.created);
};

const TimelineWidget: React.FC<ItemWidgetProps<ItemInterfaceWidgetConfiguration>> = ({
    initiative,
    widget,
    permission,
}) => {
    const enableInterfaceWidgetNoItemEmptyState = useFeatureFlag(
        'tonkean_enable_interface_widgets_no_item_empty_state',
    );

    const { initiativeInitialLoading } = useItemInterfaceContext();
    const authenticationService = useAngularService('authenticationService');
    const defaultEventId = useId();

    const { activities, loading, hasMorePages, loadNextPage } = useInitiativeActivityFetchManager(
        initiative?.id,
        TIMELINE_ACTIVITY_TYPES,
        false,
    );

    const [modifiableComments, { onCommentAdded, onCommentDelete }] = useModifiableComments(
        activities,
        initiative?.group,
        initiative?.project,
    );

    const eventGroups = useMemo(() => {
        if (!initiative) {
            return [];
        }

        if (!hasMorePages) {
            // we want to show the "item created" event as the first event in the timeline
            const activitiesWithDefault = [
                ...modifiableComments,
                {
                    id: `${tonkeanTypeToIdentifyingPrefixMap[TonkeanType.ACTIVITY]}${defaultEventId}INITIATIVE_CREATED`,
                    actor: initiative.creator,
                    created: initiative.created,
                    type: 'INITIATIVE_CREATED',
                    target: initiative,
                    project: initiative.project,
                    group: initiative.group,
                } as Activity,
            ];
            return groupEvents(activitiesWithDefault);
        }

        return groupEvents(modifiableComments);
    }, [defaultEventId, hasMorePages, initiative, modifiableComments]);

    const canUserEditItem = [
        ItemInterfacePermission.USER_CAN_EDIT_ITEM,
        ItemInterfacePermission.USER_CAN_EDIT_EXISTING_ITEM,
    ].includes(permission);

    const showNewEmptyState = useMemo(() => {
        return !initiative && !initiativeInitialLoading && enableInterfaceWidgetNoItemEmptyState;
    }, [initiativeInitialLoading, initiative, enableInterfaceWidgetNoItemEmptyState]);

    if (!initiative) {
        return (
            <ItemWidget permission={permission}>
                {initiativeInitialLoading ? (
                    <TimelineEventsLoadingState />
                ) : showNewEmptyState ? (
                    <NoItemEmptyState />
                ) : (
                    <StateMessage icon={<EmptyTimelineIcon />} title="No events yet" />
                )}
            </ItemWidget>
        );
    }

    return (
        <ItemWidget permission={permission}>
            <>
                {eventGroups.length > 0 ? (
                    <ListWrapper>
                        {eventGroups.map((group, index) => (
                            <TimelineDateGroup
                                key={group.date}
                                date={group.date}
                                events={group.events}
                                currentUser={authenticationService.getCurrentUser()}
                                defaultShowAddCommentForm={false}
                                onCommentAdded={onCommentAdded}
                                onCommentDelete={onCommentDelete}
                                isLastGroup={index === eventGroups.length - 1 && !loading.nextPageLoading}
                                canUserEditItem={canUserEditItem}
                                initiativeId={initiative.id}
                                workflowVersionType={
                                    initiative.isDraftInitiative
                                        ? WorkflowVersionType.DRAFT
                                        : WorkflowVersionType.PUBLISHED
                                }
                            />
                        ))}
                    </ListWrapper>
                ) : (
                    <StateMessage icon={<EmptyTimelineIcon />} title="No events yet" />
                )}
                {eventGroups?.length === 0 && loading.any && <TimelineEventsLoadingState />}
                {hasMorePages && !loading.any && (
                    <ButtonWrapper>
                        <LoadMoreButton size={ButtonSize.SMALL} onClick={loadNextPage} outlined>
                            Load More
                        </LoadMoreButton>
                    </ButtonWrapper>
                )}
            </>
        </ItemWidget>
    );
};

export default TimelineWidget;
