<div class="margin-top tnk-create-track-action-definition">
    <!-- Target -->
    <div class="margin-left margin-top">

        <div class="margin-bottom-lg" ng-if="data.isModuleContractsFeatureFlagOn">
            <div class="common-bold common-size-s margin-bottom-xxs">Select a contract</div>
            <div class="common-color-grey common-size-xxxs margin-bottom-16">
                A new item will be created in the selected module where it can be processed further
            </div>

            <div class="flex-vmiddle">
                <tnk-contract-selector
                        class="margin-bottom-xs flex-grow"
                        contracts="data.contractSummaries"
                        on-change="(onContractSelectedChange)"
                        loading="data.loadingContractSummaries"
                        selected-contract="data.definition.contractId"
                        disabled="!data.enableContractOptionsChange"
                ></tnk-contract-selector>

                <i
                        ng-show="!data.enableContractOptionsChange"
                        class="fa fa-info-circle padding-left-xs padding-right-xs"
                        uib-tooltip="Contract can't be replaced due to dependencies associated with the selected contract"
                        tooltip-placement="top auto"
                ></i>
            </div>
        </div>

        <div class="common-size-s margin-bottom">
            <div class="common-bold common-size-s margin-bottom-xxs"> Select a module</div>
            <div class="common-color-grey common-size-xxxs margin-bottom-16">
                {{data.isModuleContractsFeatureFlagOn ? "You can only select modules that implement your selected contract" : "A new item will be created in the selected module where it can be processed further"}}
            </div>
        </div>

        <!-- Target Group -->
        <div class="flex-vmiddle margin-bottom-xs">

            <!-- Edit Target Group -->
            <ui-select
                    class="flex-grow padding-right-xs"
                    theme="bootstrap"
                    ng-model="data.selectedGroup"
                    ng-disabled="!data.enableGroupOptionsChange"
                    ng-change="onGroupSelected()"
            >
                <ui-select-match
                        placeholder="{{ data.groupOptions.length == 0 ? 'No modules' : 'Choose a module from this solution' }}">
                    <span class="module-actions-icon">{{ $select.selected.name }}</span>
                </ui-select-match>
                <ui-select-choices
                        class="overflow-x-auto"
                        repeat="group in (data.groupOptions | filter: {name: $select.search})"
                >
                    <div
                            ng-bind-html="(group.id !== data.groupId ? group.name : 'This List') | highlight: $select.search"
                    ></div>
                    <div
                            class="module-off-option"
                            ng-if="
                            data.showModuleOffIndication && group.id !== data.groupId &&
                            (data.workflowVersionType === 'DRAFT'
                                ? !group.buildEnvironmentEnabled
                                : !group.workerEnabled)
                        "
                    >
                        Module is OFF
                    </div>
                </ui-select-choices>
            </ui-select>

            <i
                    ng-show="!data.enableGroupOptionsChange"
                    class="fa fa-info-circle padding-right-xs"
                    uib-tooltip="There are dependencies associated with this module. To change the module you should delete the fields in the matched item created by this action"
                    tooltip-placement="top auto"
            ></i>
            <span class="loading-small" ng-show="data.editGroupLoading"></span>
        </div>
        <!-- Create new module -->
        <div ng-if="data.canUserCreateModuleInSolution" class="common-size-xs margin-top-6"><a
                ng-click="createNewModuleLink()"> Create new module</a></div>
        <div ng-if="data.validationObject.noGroup" class="common-color-danger">{{ data.validationObject.noGroup }}</div>
    </div>

    <!-- Creator -->
    <div class="margin-top">
        <div class="common-bold common-size-xs margin-left margin-top-lg">Creator</div>

        <div class="margin-left">
            <div class="common-size-xxs common-color-grey3 margin-bottom">
                Choose who will be the creator of the Item.
            </div>

            <tnk-person-selection-configuration
                    class="block"
                    support-quick-create="true"
                    radio-id-prefix="'create-track-creator-' + data.workflowVersionId"
                    allow-none="true"
                    none-option-label="'Default actor (' + pm.groupsMap[data.groupId].defaultActor.name + ')'"
                    max-people="1"
                    hide-fail-if-no-value="true"
                    configured-logic="data.configuredLogic"
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-person-selection-configuration="data.existingCreatorPersonSelectionConfiguration"
                    on-person-selection-configuration-changed="
                    onCreatorPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
                "
                    unique-identifier="creator"
            ></tnk-person-selection-configuration>
        </div>
    </div>

    <div class="common-size-s margin-bottom margin-top-24 margin-left"
         ng-if="data.isModuleContractsFeatureFlagOn && data.definition.contractId">
        <tnk-create-initiative-by-contract-action-definition
                contract-id="data.definition.contractId"
                workflow-version-id="data.workflowVersionId"
                project-id="pm.project.id"
                on-change="(onContractFieldToExpressionChange)"
                contract-field-to-expression="data.definition.contractFieldToExpression"
        ></tnk-create-initiative-by-contract-action-definition>
    </div>


    <div class="common-bold common-size-xs margin-left margin-top-lg margin-bottom">Basic Fields</div>

    <!-- Initiative Fields -->
    <table class="table table-striped margin-bottom-none">
        <tr ng-repeat="field in data.fields">
            <td class="padding-normal-xs col-sm-10"
                ng-if="!data.definition.contractId || !data.isModuleContractsFeatureFlagOn || field.key === 'title'">
                <div class="padding-normal-xs">
                    <span ng-if="field.isRequired" class="common-color-danger">*</span>
                    {{ field.label }}:
                    <span ng-if="field.key === 'title' && data.validationObject.noTitle"
                          class="common-color-danger">
                        {{ data.validationObject.noTitle }}
                    </span>
                </div>

                <!-- Input selection -->
                <div class="padding-left-xs margin-bottom-xs">
                    <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            saved-original-expression="data.definition[field.key]"
                            saved-evaluated-expression="data.definition['evaluated' + (field.key | capitalize:true)]"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Enter {{ field.label }}..."
                            on-tonkean-expression-changed="
                            onFieldExpressionChanged(
                                originalExpression,
                                evaluatedExpression,
                                shouldSaveLogic,
                                field.key
                            )
                        "
                    ></tnk-tonkean-expression>
                </div>
            </td>
        </tr>
    </table>

    <div ng-if="!data.definition.contractId || !data.isModuleContractsFeatureFlagOn">
        <div ng-if="data.selectedGroup">
            <div class="common-bold common-size-xs margin-left margin-top-lg">Custom Fields</div>

            <!-- Fields to update selection -->
            <div class="margin-normal-sm">
                <tnk-multiple-field-to-update-editors
                        field-selector-group-id="{{ data.selectedGroup.id }}"
                        field-selector-workflow-version-id="{{ data.selectedGroup.draftWorkflowVersionId }}"
                        expression-group-id="{{ data.groupId }}"
                        expression-workflow-version-id="{{ data.workflowVersionId }}"
                        configured-fields="data.fieldsToUpdate"
                        preview-evaluation-source="data.previewEvaluationSource"
                        special-fields-for-features="['CREATE_TRACK_UPDATE_FIELDS']"
                        on-configured-fields-changed="onConfiguredFieldsChanged(configuredFields, shouldSaveLogic)"
                        logic-id="{{ data.configuredLogic.node.id }}"
                ></tnk-multiple-field-to-update-editors>
            </div>
        </div>

        <div class="common-bold common-size-xs margin-left margin-top-lg margin-bottom">Tags</div>

        <!-- Tags -->
        <table class="table table-striped margin-bottom">
            <tr ng-repeat="tag in data.tags">
                <td>
                    <div class="padding-left-xs margin-bottom-xs">
                        <div class="margin-bottom-xs">Tag {{ $index + 1 }}:</div>

                        <tnk-tonkean-expression
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                saved-original-expression="tag.value"
                                saved-evaluated-expression="tag.evaluatedValue"
                                preview-evaluation-source="data.previewEvaluationSource"
                                placeholder="Enter Tag..."
                                on-tonkean-expression-changed="
                            onTagExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic, tag)
                        "
                        ></tnk-tonkean-expression>
                    </div>
                </td>
            </tr>
        </table>

        <!-- Add tag link -->
        <a ng-click="addEmptyTag()" class="padding-left-xs">+ Add Tag</a>
    </div>
</div>
