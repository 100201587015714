import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

import type { WorkflowFolder, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useFetchWorkflowFolderManager = () => {
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const getWorkflowFolder = useCallback(
        (
            projectId: TonkeanId<TonkeanType.PROJECT>,
            workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        ): WorkflowFolder | undefined => {
            const workflowFolder = workflowFolderManager.projectIdToFolderIdToFolderMap[projectId]?.[workflowFolderId];

            if (!workflowFolder) {
                console.error(`Unable to find workflow folder [${workflowFolderId}] in cache`);
                return undefined;
            }

            return workflowFolder;
        },
        [workflowFolderManager.projectIdToFolderIdToFolderMap],
    );

    return { getWorkflowFolder };
};

export default useFetchWorkflowFolderManager;
