import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { TnkSelect } from '@tonkean/infrastructure';

const AzureBlobStorageSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    createProjectApis,
    onChangeOrInitIntegration,
    projectIntegration,
}) => {
    const projectManager = useAngularService('projectManager');
    const [containersList, setContainersList] = useState([]);
    const [error, setError] = useState<string | undefined>();
    const [selectedContainer, setSelectedContainer] = useState<{ value: string; label: string }>();
    const [isLoadingContainers, setIsLoadingContainers] = useState(false);

    // On every change of the project integration, we initialize current container from project data.
    useEffect(() => {
        const projectData = projectIntegration?.projectData?.projectDatas[0];
        if (projectData) {
            setSelectedContainer(projectData.container);
        }
    }, [projectIntegration]);

    // Reset the containers list
    useEffect(() => {
        // In case the user re-authenticated
        if (integration?.id !== projectIntegration?.integration?.id) {
            setSelectedContainer(undefined);
        }
    }, [integration, projectIntegration]);

    // Get Containers list.
    useEffect(() => {
        const getContainers = async () => {
            setIsLoadingContainers(true);
            try {
                const containers = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'containers',
                );
                const autoCompleteOptions = constructAutoCompleteOptions(containers.options);
                setContainersList(autoCompleteOptions);
            } catch {
                setError('Error occurred while getting the Containers list.');
            } finally {
                setIsLoadingContainers(false);
            }
        };
        getContainers();
    }, [createProjectApis, integration, projectManager]);

    const onListSelected = useCallback((container) => {
        setSelectedContainer(container);
    }, []);

    useEffect(() => {
        const projectIntegrationData = {
            container: selectedContainer,
        };
        const projectIntegrationDisplayName = `Azure Blob Storage (${selectedContainer?.label})`;

        onChangeOrInitIntegration(
            {
                projectIntegrationData,
                projectIntegrationDisplayName,
            },
            !selectedContainer,
        );
    }, [onChangeOrInitIntegration, selectedContainer]);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: option.displayName,
        }));
    };

    return (
        <div className="flex flex-col">
            {error && <div className="alert alert-danger margin-bottom">{error}</div>}

            <div className="flex flex-col">
                <div className="flex-vmiddle margin-bottom">
                    <b>Select Container:</b>
                    {isLoadingContainers && <i className="loading-small margin-left-md" />}
                </div>

                <TnkSelect
                    placeholder="Search Containers..."
                    isMulti={false}
                    isCreatable={false}
                    isClearable={false}
                    options={containersList}
                    onChange={onListSelected}
                    value={selectedContainer}
                    isSearchable
                    closeMenuOnSelect
                />
            </div>
        </div>
    );
};

export default AzureBlobStorageSetupComponent;
