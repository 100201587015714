import React from 'react';
import type { RenderElementProps } from 'slate-react/dist/components/editable';
import styled from 'styled-components';

const ElementWrapper = styled.span`
    display: inline;
    vertical-align: baseline;
`;

interface Props {
    editorChildren: React.ReactNode;
    attributes: RenderElementProps['attributes'];
}

const ExpressionEditorElementWrapper: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    attributes,
    editorChildren,
}) => {
    return (
        <ElementWrapper {...attributes}>
            {children}
            {editorChildren}
        </ElementWrapper>
    );
};

export default ExpressionEditorElementWrapper;
