<div class="common-close-btn-container">
    <div class="common-close-btn" ng-click="dismiss()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </div>
    <div class="field-view-modal padding-normal">
        <div class="common-title-inner mod-no-top">{{ field.fieldDefinition.name }}</div>
        <div class="common-subtitle-inner" ng-if="data.isInitiative">
            <a ng-click="openTarget()">{{ target.title || target.name }}</a>
        </div>

        <div ng-switch="viewMode">
            <!-- Displaying full field value -->
            <div ng-switch-when="SHOW_FULL_VIEW" class="common-text-wrap field-view-text-box">
                <hr />
                <div class="common-break-long" ng-if="field && field.value" light-markdown="field.value"></div>
            </div>

            <div ng-switch-when="PIE_CHART">
                <tnk-categorical-field-instance-chart field-instance="field" chart-display-type="categoricalChartDisplayType"></tnk-categorical-field-instance-chart>
            </div>

            <!-- Displaying the graph for the field -->
            <div ng-switch-default>
                <div class="flex flex-vmiddle common-size-xxxxs margin-top margin-right common-color-grey">
                    <!-- Disabling this for now, since the new API doesn't support intervals -->
                    <select
                        class="form-control margin-left-auto field-view-interval-select"
                        ng-model="data.interval"
                        ng-change="onIntervalSelect()"
                        ng-options="key for (key, value) in data.intervalOptions"
                    ></select>
                    <div class="margin-left">
                        Last {{ data.interval.range }} days
                        <i>({{ data.rangeFrom | date: 'MM/dd' }} - {{ data.rangeTo | date: 'MM/dd' }})</i>
                    </div>
                </div>
                <div class="field-view-graph-box">
                    <div
                        ng-if="loading"
                        class="flex-vmiddle flex-justify-space-around absolute-fill-view text-center padding-bottom-lg"
                    >
                        <i class="loading-medium"></i>
                    </div>
                    <div ng-if="!loading">
                        <div
                            class="absolute-fill-view common-color-grey flex-vmiddle"
                            ng-if="!data.results || !data.results.length"
                        >
                            <div class="text-center flex-grow">Not enough data..</div>
                        </div>

                        <canvas id="fieldViewChart"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
