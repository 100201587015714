/**
 * Returns the display object for the given fieldDefinition and possibly, the groupName.
 */
export function getDisplayObjectForFieldDefinition(fieldDefinition, groupName, isGlobalFieldDefinition) {
    const displayObject: Record<string, any> = {
        id: fieldDefinition.id,
        name: fieldDefinition.id,
        label: fieldDefinition.name,
        fieldLabel: fieldDefinition.fieldLabel,
        type: fieldDefinition.fieldType,
        updateable: fieldDefinition.updateable,
        compareTimeframe: fieldDefinition.compareTimeframe,
        values: fieldDefinition.possibleValues,
        displayFormat: fieldDefinition.displayFormat,
        globalFieldDefinition: isGlobalFieldDefinition,
        isMultiValueField: fieldDefinition.isMultiValueField,
        // Used for ui-select pretty display
        groupBy: 'FIELDS',
    };

    // Add source to display icon of the integration in the field selector
    if (fieldDefinition.projectIntegration?.integrationType) {
        displayObject.source = fieldDefinition.projectIntegration.integrationType.toLowerCase();
    }

    if (groupName) {
        displayObject.groupName = groupName;
    }

    return displayObject;
}
