import { CustomTriggerType, FormType } from '@tonkean/tonkean-entities';

const validateFormFromDifferentModule = (
    validationObject,
    form,
    definition,
    workflowVersionId,
    children,
    featureMap,
) => {
    if (featureMap.tonkean_feature_form_match_entity) {
        if (
            form &&
            form.formType !== FormType.CREATE &&
            form.workflowVersion.id !== workflowVersionId &&
            !definition.formMatchedEntityFieldId
        ) {
            validationObject.formNoMatchedEntity = 'Must define a Matched Entity for forms from different modules';
        }

        if (
            definition.formMatchedEntityFieldId &&
            children.some(
                (child) =>
                    child.node?.customTriggerType === CustomTriggerType.SEND_FORM_ANSWERED && !child.node.disabled,
            )
        ) {
            validationObject.formMatchedEntityHasWhenAnswered =
                'A When Answered Block is not allowed on a Send Form block thats sends to a different module';
        }
    }
};

export default validateFormFromDifferentModule;
