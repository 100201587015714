import { consts } from '../../../../../../../shared/services/consts.service';

import { timezonesPreset } from '@tonkean/forumla-operators';
import { DayOfWeek, RecurrencePeriodType } from '@tonkean/tonkean-entities';
import { range, toCamelCase } from '@tonkean/utils';

export const recurrencePeriodTypeToLabel: Record<RecurrencePeriodType, string> = {
    [RecurrencePeriodType.EveryXMinutes]: 'Every X Minutes',
    [RecurrencePeriodType.EveryXHours]: 'Every X Hours',
    [RecurrencePeriodType.Daily]: 'Daily',
    [RecurrencePeriodType.Weekly]: 'Weekly',
    [RecurrencePeriodType.Monthly]: 'Monthly',
    [RecurrencePeriodType.Quarterly]: 'Quarterly',

    // Deprecated options
    [RecurrencePeriodType.Every3Hours]: '',
    [RecurrencePeriodType.Hourly]: '',
};

export const frequencyOptions = Object.keys(RecurrencePeriodType)
    .map((value) => ({
        value,
        label: recurrencePeriodTypeToLabel[value],
    }))
    // Filter out options with no label (deprecated)
    .filter((entry) => entry.label);

export const dayInWeekOptions = Object.keys(DayOfWeek).map((value) => ({
    value,
    label: toCamelCase(value).slice(0, 3),
}));

export const monthOptions = Object.entries(consts.getMonthsOfYearSettings()).map(([label, value]) => ({
    label,
    value,
}));

export const dayInMonthOptions = range(1, 32).map((value) => ({
    value,
    label: value,
}));

export const timezoneOptions = timezonesPreset.map((value) => ({ value, label: value }));

export const hoursOfDayOptions: any[] = consts.getHoursOfTheDay();

export const minuteOptions = range(0, 60).map((value) => ({
    value,
    label: value,
}));
