<div class="margin-top-lg padding-top tnk-gallery common-height-full">
    <div class="common-size-xxs flex-vmiddle common-color-grey">
        <div class="arrow-placeholder visible-xs">
            <i
                ng-show="data.arrowVisibility.previous"
                ng-click="previousImage()"
                class="pointer fa fa-2x fa-chevron-left"
            ></i>
        </div>
        <div class="flex-grow">{{ images[data.imgIndex].title }}</div>
        <div class="arrow-placeholder visible-xs">
            <i ng-show="data.arrowVisibility.next" ng-click="nextImage()" class="pointer fa fa-2x fa-chevron-right"></i>
        </div>
    </div>
    <div class="flex-vmiddle margin-top common-color-grey">
        <div class="arrow-placeholder hidden-xs">
            <i
                ng-show="data.arrowVisibility.previous"
                ng-click="previousImage()"
                class="hidden-xs pointer fa fa-2x fa-chevron-left"
            ></i>
        </div>
        <div class="flex-grow">
            <div class="tnk-gallery-images">
                <img
                    ng-src="{{ images[data.imgIndex].img }}"
                    alt="{{ images[data.imgIndex].title }}"
                    class="hidden-xs"
                />
                <img
                    ng-src="{{ images[data.imgIndex].mobileImage }}"
                    alt="{{ images[data.imgIndex].title }}"
                    class="visible-xs-inline-block"
                />
            </div>
            <div class="margin-top">
                <i
                    class="margin-right-xs pointer fa"
                    ng-click="moveToIndex($index)"
                    ng-class="data.imgIndex === $index ? 'fa-circle common-color-primary' : 'fa-circle-o'"
                    ng-repeat="i in images"
                ></i>
            </div>
        </div>
        <div class="arrow-placeholder hidden-xs">
            <i
                ng-show="data.arrowVisibility.next"
                ng-click="nextImage()"
                class="hidden-xs pointer fa fa-2x fa-chevron-right"
            ></i>
        </div>
    </div>
</div>
