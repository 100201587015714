import React, { useMemo } from 'react';
import styled from 'styled-components';

import SQLDatabaseIntegrationConfigurationContext from './components/SQLDatabaseIntegrationConfigurationContext';
import SQLDatabaseQueryConfigurationForm from './components/SQLDatabaseQueryConfigurationForm';
import useEditSQLDatabaseQueryConfigurations from './hooks/useEditSQLDatabaseQueryConfigurations';
import getSQLDatabaseQueryConfigurationsInvalidMessage from './utils/getSQLDatabaseQueryConfigurationsInvalidMessage';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { KnowledgeBase } from '@tonkean/constants';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const FooterButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const QueryEntitiesTitle = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: bold;
    margin-bottom: 10px;
`;

const Explanation = styled.p`
    font-size: 12px;
    margin-bottom: 20px;
`;

const SQLDatabaseConfigurationComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    projectIntegration,
    onChangeOrInitIntegration,
}) => {
    const {
        queryConfigurations,
        isValid,
        toggleEditMode,
        changeQueryName,
        changeExpanded,
        changeQuery,
        changeEntityIdType,
        changeIdentifierColumn,
        changePaginationEnabled,
        changePagesAmount,
        changePageSize,
        changeCustomTimestampsEnabled,
        changeCreatedColumn,
        changeUpdatedColumn,
        changeQueryTested,
        removeQuery,
        addQuery,
    } = useEditSQLDatabaseQueryConfigurations(
        projectIntegration,
        (projectIntegrationData, projectIntegrationDisplayName) =>
            onChangeOrInitIntegration(
                { projectIntegrationData, projectIntegrationDisplayName },
                !isValid,
                getSQLDatabaseQueryConfigurationsInvalidMessage(queryConfigurations),
            ),
        <>Notice: deleting the query entity will not delete already received data source items.</>,
    );

    const contextValue = useMemo(() => {
        return {
            queryConfigurations,
            toggleEditMode,
            changeQueryName,
            changeExpanded,
            changeQuery,
            changeEntityIdType,
            changeIdentifierColumn,
            changePaginationEnabled,
            changePagesAmount,
            changePageSize,
            changeCustomTimestampsEnabled,
            changeCreatedColumn,
            changeUpdatedColumn,
            changeQueryTested,
            removeQuery,
        };
    }, [
        queryConfigurations,
        toggleEditMode,
        changeQueryName,
        changeExpanded,
        changeQuery,
        changeEntityIdType,
        changeIdentifierColumn,
        changePaginationEnabled,
        changePagesAmount,
        changePageSize,
        changeCustomTimestampsEnabled,
        changeCreatedColumn,
        changeUpdatedColumn,
        changeQueryTested,
        removeQuery,
    ]);

    return (
        <SQLDatabaseIntegrationConfigurationContext.Provider value={contextValue}>
            <QueryEntitiesTitle>Query Entities</QueryEntitiesTitle>

            <Explanation>
                Each query entity will create an entity definition in the data source. We will fetch the configured SQL
                query periodically from the connected database, and the rows returned as the query response will be the
                entities created under the query entity definition. The fields of the created entities will be the
                columns of the returned response.{' '}
                <a href={KnowledgeBase.baseUrl} target="_blank">
                    See examples
                </a>
            </Explanation>

            {queryConfigurations.map((queryConfiguration, index) => {
                return (
                    <SQLDatabaseQueryConfigurationForm
                        key={queryConfiguration.queryId}
                        integrationId={integration.id}
                        queryConfiguration={queryConfiguration}
                        index={index}
                        projectIntegration={projectIntegration}
                    />
                );
            })}

            <FooterButtonsContainer>
                <Button size={ButtonSize.MEDIUM} shape={ButtonShape.ROUND} onClick={() => addQuery()} outlined>
                    + Add Query Entity
                </Button>
            </FooterButtonsContainer>
        </SQLDatabaseIntegrationConfigurationContext.Provider>
    );
};

export default SQLDatabaseConfigurationComponent;
