import TrainingSetNlpModelDetails from './TrainingSetNlpModelDetails';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkTrainingSetNlpModelDetails',
        reactToAngular(TrainingSetNlpModelDetails, ['selectedTrainingSetId', 'selectedTrainingSetModelId', 'groupId']),
    );
