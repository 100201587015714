import type { Element as SlateElement, Node } from 'slate';
import { Editor, Text } from 'slate';

import type FormulaElement from '../entities/FormulaElement';
import type VariableElement from '../entities/VariableElement';

function isVariableElement(element: SlateElement): element is VariableElement {
    return element['type'] === 'variable';
}

function isFormulaElement(element: SlateElement): element is FormulaElement {
    return element['type'] === 'formula';
}

function convertNodeToString(node: Node) {
    if (Text.isText(node)) {
        return node.text;
    } else if (!Editor.isEditor(node)) {
        if (isVariableElement(node)) {
            return `{{${node.variableContent}}}`;
        }
        if (isFormulaElement(node)) {
            return `<#${node.formulaContent}#>`;
        }
    }
    return node.children.map(convertNodeToString).join('');
}

function convertNodesToString(nodes: SlateElement[]) {
    return nodes.map(convertNodeToString).join('\n');
}

export default convertNodesToString;
