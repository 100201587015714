import {
    type CustomPageConfiguration,
    InterfaceQuickNavigationMode,
    type ItemInterface,
} from '@tonkean/tonkean-entities';

export const useIsTabsViewItemInterface = (itemInterface: ItemInterface | undefined) => {
    return useIsTabsView(itemInterface?.configuration?.quickNavigationMode);
};

export const useIsTabsViewWorkspaceApp = (configuration: CustomPageConfiguration | undefined) => {
    return useIsTabsView(configuration?.quickNavigationMode);
};

function useIsTabsView(quickNavigationMode: InterfaceQuickNavigationMode | undefined) {
    return Boolean(quickNavigationMode === InterfaceQuickNavigationMode.TABS);
}
