/**
 * Gets the first action of given type in action array.
 */
export function getFirstCustomTriggerAction(customTriggerActions, customTriggerActionType) {
    if (customTriggerActionType && customTriggerActions?.length) {
        for (const action of customTriggerActions) {
            if (action.customTriggerActionType === customTriggerActionType || action.type === customTriggerActionType) {
                return action;
            }
        }
    }

    return null;
}
