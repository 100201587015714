import template from './tnkOutlineFilter.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkOutlineFilter', {
    bindings: {
        groupId: '<',
        applyFilters: '&',
        fieldDefinitionForDependenciesOptions: '<',
        selectedField: '<',
        selectedFieldDefinitionForDependencies: '<',
        entityName: '@',
    },
    template,
    controller: 'OutlineFilterCtrl',
});
