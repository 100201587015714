export enum AmazonS3PermissionType {
    GLOBAL = 'global',
    RESTRICTED = 'restricted',
}

export const permissionTypeToDisplayName: Record<AmazonS3PermissionType, string> = {
    [AmazonS3PermissionType.GLOBAL]: 'Global - List Buckets',
    [AmazonS3PermissionType.RESTRICTED]: 'Restricted - Specific Bucket',
};

export const permissionInfoTypeToDisplayName: Record<AmazonS3PermissionType, string> = {
    [AmazonS3PermissionType.GLOBAL]:
        'Credentials have S3Actions.ListBucket permissions, show a list of buckets and let me choose the one i need',
    [AmazonS3PermissionType.RESTRICTED]:
        'Credentials are restricted to a specific bucket. Let me write the bucket name',
};

export default AmazonS3PermissionType;
