import React from 'react';
import styled from 'styled-components';

import LoginErrorMessage from './LoginErrorMessage';
import { type Auth, AuthTypes } from './LoginPageTypes';

import { Clickable } from '@tonkean/tui-buttons/Clickable';

const LoginWithButton = styled(Clickable)`
    margin-bottom: 20px;
    padding-block: 15px;
    border: 1px solid #d9d9d9;
    background: white;
    font-weight: 400;
    font-size: 16px;
    color: #34393e;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: end;
    width: 100%;
    justify-content: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }
`;

const ButtonIcon = styled.svg`
    width: 24px;
    height: 24px;
    margin-right: 8px;
`;

interface Props {
    onClick: (authType: AuthTypes) => void;
    authTypes: Auth[];
    error?: {
        type: AuthTypes;
        message: string;
    };
}

const OtherLoginButtons: React.FC<Props> = ({ authTypes, onClick, error }) => {
    return (
        <>
            {authTypes.map((authType) => (
                <React.Fragment key={`${authType.name}-${authType.type}`}>
                    <LoginWithButton data-automation="3rd-party-login-buttons" onClick={() => onClick(authType.type)}>
                        <ButtonIcon as={authType.icon} />
                        Continue with {authType.name}
                    </LoginWithButton>
                    {error?.type === authType.type && error.message && (
                        <LoginErrorMessage data-automation="3rd-party-login-buttons-error">
                            {error.message}
                        </LoginErrorMessage>
                    )}
                </React.Fragment>
            ))}
        </>
    );
};

export default OtherLoginButtons;
