import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { IGraphViewProps } from 'react-digraph';
import styled, { css } from 'styled-components';

import ProcessMapperGraph from './ProcessMapperGraph';

import type {
    Initiative,
    ProcessMapper,
    ProcessMapperEdge,
    ProcessMapperNode,
    ProcessMapperNodeStage,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div<{ noOverlay: boolean }>`
    height: 100%;
    width: 100%;
    overflow: hidden;
    ${({ noOverlay }) =>
        noOverlay &&
        css`
            outline: 1px solid ${Theme.colors.primaryHighlight};
        `}
    border-radius: 6px;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2; /* Put the overlay on top of the content */
`;

interface Props {
    processMapper: ProcessMapper;
    initiative: Initiative | undefined;
    graphRef: React.RefObject<React.Component<IGraphViewProps, any, any>>;
    processMapperNodeToStage: Record<`PMNO${string}`, ProcessMapperNodeStage> | undefined;
    processMapperNodes: ProcessMapperNode[];
    processMapperEdges: ProcessMapperEdge[];
}

const ProcessMapperDisplayableGraph: React.FC<Props> = ({
    processMapper,
    initiative,
    graphRef,
    processMapperNodeToStage,
    processMapperNodes,
    processMapperEdges,
}) => {
    const [blockInteractiveGraph, setBlockInteractiveGraph] = useState<boolean>(true);

    const renderBackground = useCallback(() => {
        return <rect className="background" x={0} y={0} width="100%" height="100%" fill="#f9f9f7" />;
    }, []);

    const wrapperRef = useRef<HTMLDivElement>(null);
    /**
     * Add an overlay to prevent graph interaction during scrolling
     * The overlay is a transparent div that blocks the graph from being interacted with while scrolling.
     * This ensures smooth scrolling in the interface without interfering with the graph's behavior.
     */
    const overlayRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            // When Overlay is clicked, hide the overlay to enable graph movement and zoom interactions
            const isOverlayClicked = overlayRef?.current?.contains(event.target as Node);
            if (isOverlayClicked) {
                setBlockInteractiveGraph(false);
            } else if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target as Node) &&
                !blockInteractiveGraph
            ) {
                // When a different div which is not the overlay or the wrapper clicked
                // Show the overlay to prevent graph interactions while scrolling
                // Also, add an outline to indicate that the graph is interactive
                setBlockInteractiveGraph(true);
            }
        },
        [blockInteractiveGraph],
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    // The reason for changing the type of the nodes is because react-digraph requires a 'shape' representation
    // before runtime to render the shapes according to their types specified in ProcessMapperGraphConfig.ts.
    // Therefore, all nodes of type 'JOINT' are changed to 'DISPLAYABLE_JOINT' to make them disappear in the displayable version of the graph.
    const evaluatedNodes = useMemo(() => {
        return processMapperNodes.map((node) => {
            const isJointNode = node.type === 'JOINT';
            return {
                ...node,
                type: isJointNode ? 'DISPLAYABLE_JOINT' : node.type,
            } as ProcessMapperNode;
        });
    }, [processMapperNodes]);

    return (
        <Wrapper ref={wrapperRef} noOverlay={!blockInteractiveGraph}>
            {blockInteractiveGraph && <Overlay ref={overlayRef} />}
            <ProcessMapperGraph
                processMapper={processMapper}
                initiative={initiative}
                processMapperNodes={evaluatedNodes}
                processMapperEdges={processMapperEdges}
                createProcessMapperEdge={() => {}}
                graphRef={graphRef}
                moveNode={() => {}}
                processMapperNodeToStage={processMapperNodeToStage}
                selectedNode={undefined}
                setSelectedNodeOrEdge={() => {}}
                background={renderBackground}
                readOnly
            />
        </Wrapper>
    );
};

export default ProcessMapperDisplayableGraph;
