import React from 'react';

import RichTextWidgetView from './RichTextWidgetView';
import type { SolutionSitePageWidgetProps } from '../../../WidgetModule';
import type RichTextWidgetConfiguration from '../RichTextWidgetConfiguration';

import { HTMLEditorFullPluginsList } from '@tonkean/editor';

const SolutionSitePageRichTextWidget: React.FC<SolutionSitePageWidgetProps<RichTextWidgetConfiguration>> = ({
    widget,
    permission,
}) => {
    return <RichTextWidgetView widget={widget} editorPlugins={HTMLEditorFullPluginsList} permission={permission} />;
};

export default SolutionSitePageRichTextWidget;
