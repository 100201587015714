import NumericBinaryOperatorBase from './NumbericBinaryOperatorBase';

import { OperatorKey } from '@tonkean/tonkean-entities';

export default class MultiplyOperator extends NumericBinaryOperatorBase {
    override readonly key = OperatorKey.MULTIPLY;
    override readonly sign = '*';
    override readonly displayName = 'Multiply';
    override readonly description = 'Will multiply the left hand field by the right hand field.';
}
