import { useMemo } from 'react';

import useCurrentlyAuthenticatedUser from './useCurrentlyAuthenticatedUser';
import useGroupGlobalFields from './useGroupGlobalFields';

import { WorkflowVersionType, type Initiative, type Person } from '@tonkean/tonkean-entities';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';

function useAllInitiativeFieldsValues(initiative: Initiative | undefined): Record<string, string> {
    const currentUser = useCurrentlyAuthenticatedUser();

    const globalFields = useGroupGlobalFields({
        groupId: initiative?.group.id,
        workflowVersionType: initiative?.isDraftInitiative ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
    });

    const globalFieldsKeyValue = useMemo(() => {
        const fieldsKeyValue = {};

        Object.keys(globalFields).forEach((fieldId) => {
            fieldsKeyValue[fieldId] = globalFields[fieldId]?.value || '';
        });

        return fieldsKeyValue;
    }, [globalFields]);

    const specialFields = useMemo(() => {
        if (!initiative) {
            return {};
        }

        const specialFieldIdToValueMap = getSpecialFieldsForFeatures(false, ['MATCH_CONDITIONAL_WIDGETS']);
        return specialFieldIdToValueMap.reduce(
            (
                acc: { [x: string]: any },
                specialFieldInfo: {
                    id: string;
                    getValueForExpressionEvaluation: (initiative: Initiative, viewer: Person) => any;
                },
            ) => {
                acc[specialFieldInfo.id] = specialFieldInfo.getValueForExpressionEvaluation(initiative, currentUser);
                return acc;
            },
            {},
        );
    }, [initiative, currentUser]);

    const customFields = useMemo(() => {
        const fieldsKeyValue = {};

        initiative?.fields?.forEach((field) => {
            if (field.fieldDefinition !== undefined) {
                fieldsKeyValue[field.fieldDefinition.id] = field.value || '';
            }
        });

        return fieldsKeyValue;
    }, [initiative]);

    return useMemo(() => {
        return {
            ...specialFields,
            ...customFields,
            ...globalFieldsKeyValue,
        };
    }, [specialFields, customFields, globalFieldsKeyValue]);
}

export default useAllInitiativeFieldsValues;
