import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ModuleIntegrationsPreviewCtrl($scope, workflowVersionManager) {
    const ctrl = this;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        worker: ctrl.worker,
        size: ctrl.size || 'md',
        showCircle: !ctrl.hideCircle,
    };

    $scope.avatarsLimit = function (worker) {
        const integrationsLimit = 3;

        // Auto check-ins will appear instead of an integration
        const cachedPublishedVersion = workflowVersionManager.getPublishedVersionFromCache(worker.id);
        let limit =
            cachedPublishedVersion && cachedPublishedVersion.shouldSendGatherUpdates
                ? integrationsLimit - 1
                : integrationsLimit;

        // If it's only a single integration more, just show it (instead of the +1 icon)
        limit = worker.originalGroupProjectIntegrations.length === limit + 1 ? limit + 1 : limit;

        return limit;
    };
}

export default angular
    .module('tonkean.app')
    .controller('ModuleIntegrationsPreviewCtrl', lateConstructController(ModuleIntegrationsPreviewCtrl));
