import React from 'react';
import styled from 'styled-components';

import { Checkbox, Field, useFormikField } from '@tonkean/infrastructure';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

const MarketplaceItemCreateModalDataSourceInstructionsSection: React.FC = () => {
    const { value: shouldSubscribeOnInstall, setValue: setShouldSubscribeOnInstall } =
        useFormikField<boolean>('shouldSubscribeOnInstall');

    return (
        <StyledField
            label="Subscriptions to marketplace updates"
            informationTooltip={
                <>
                    Data sources subscribed to marketplace updates can not be altered or changed and will get
                    automatically updated when new capabilities are published to the marketplace.
                </>
            }
        >
            <Checkbox name="shouldSubscribeOnInstall">Enable Subscription</Checkbox>
        </StyledField>
    );
};

export default MarketplaceItemCreateModalDataSourceInstructionsSection;
