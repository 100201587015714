import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ColumnSumFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.COLUMN_SUM;
    override readonly sign = 'ColumnSum';
    override readonly displayName = 'Column Sum';
    override readonly description = 'Aggregates a column and calculates its sum.';
    override readonly dataType = FieldType.Number;

    override readonly aggregationOnly = true;

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input Column',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
