import React, { useMemo } from 'react';

import FieldSelectorValueTypeIcons from './FieldSelectorValueTypeIcons';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import { InputSize } from '@tonkean/tui-theme/sizes';

interface Props {
    fieldDefinitions: FieldDefinition[];
    selectedField: TonkeanId<TonkeanType.FIELD_DEFINITION> | undefined;
    onChange: (fieldId: TonkeanId<TonkeanType.FIELD_DEFINITION> | undefined) => void;
    loading: boolean;
    size?: InputComponentSizes;
}

const ManualFieldSelector: React.FC<Props> = ({
    fieldDefinitions,
    loading,
    onChange,
    selectedField,
    size = InputSize.XMEDIUM_LARGE,
}) => {
    const options = useMemo(() => {
        return fieldDefinitions.map((field) => {
            return {
                label: field.name,
                value: field.id,
                icon: <FieldSelectorValueTypeIcons type={field.fieldType} />,
            };
        });
    }, [fieldDefinitions]);

    return (
        <SimpleSelect
            options={options}
            value={selectedField}
            onChange={onChange}
            isLoading={loading}
            size={size}
            isClearable
        />
    );
};

export default ManualFieldSelector;
