import { reactToAngular } from 'angulareact';

import ProcessMappersWorkerOutline from './ProcessMappersWorkerOutline';

export default angular
    .module('tonkean.app')
    .component(
        'tnkProcessMappersWorkerOutline',
        reactToAngular(ProcessMappersWorkerOutline, ['workflowVersionId', 'workflowVersionType']),
    );
