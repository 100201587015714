const tryPrettifyJsonText = (jsonText: string) => {
    try {
        return JSON.stringify(JSON.parse(jsonText), undefined, 4);
    } catch {
        return jsonText;
    }
};

export const prettifyJson = (json: Record<any, any>) => {
    return JSON.stringify(json, undefined, 4);
};

export default tryPrettifyJsonText;
