import Dynamics365FOAuthenticationComponent from './Dynamics365FOAuthenticationComponent';
import Dynamics365FOIcon from '../../../../apps/tracks/images/dynamics365fo.png';
import Dynamics365FOCircleIcon from '../../../../apps/tracks/images/integrations/dynamics365-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class Dynamics365FOIntegration extends IntegrationDefinitionBase {
    override name = 'dynamics365fo';
    override displayName = 'Dynamics 365 Finance & Operations';
    override description = 'We will only collect projects & users related data.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override customizedAuthenticationComponent = Dynamics365FOAuthenticationComponent;

    override entities = [
        new IntegrationEntity('Company', 'Companies'),
        new IntegrationEntity('Contract', 'Contracts'),
        new IntegrationEntity('Customer', 'Customers'),
        new IntegrationEntity('Group', 'Groups'),
        new IntegrationEntity('Project', 'Projects'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Worker', 'Workers'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(Dynamics365FOCircleIcon, '130px'),
        new IntegrationIcon(Dynamics365FOIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {
            Company: true,
            Contract: true,
            Customer: true,
            Group: true,
            Project: true,
            User: true,
            Worker: true,
        },
        isOnlyDataRetention: {},
    };
}

export default Dynamics365FOIntegration;
