import { FieldArray, useField } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

import SingleQueryParamComponent from './SingleQueryParamComponent';

import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { LinkLikeButton, Placeholder } from '@tonkean/infrastructure';
import type { HttpRequestQueryParam } from '@tonkean/tonkean-entities';
import { HttpQueryParamConditionType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import utils, { range } from '@tonkean/utils';

const AddQueryParamButton = styled(LinkLikeButton)`
    color: ${Theme.colors.primaryHighlight};
`;

const QueryParamsWrapper = styled.div<{ isTypeExists: boolean }>`
    display: grid;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 16px;

    ${({ isTypeExists }) => {
        return isTypeExists
            ? css`
                  grid-template-columns: 3fr 5fr 3fr 30px;
              `
            : css`
                  grid-template-columns: 2fr 3fr 30px;
              `;
    }}
`;

const QueryParamHeader = styled.div`
    color: ${Theme.colors.gray_500};
`;

interface Props {
    name: string;
    expressionProps?: FormikExpressionProps;
    showQueryParamType: boolean;
    isLoading: boolean;
}

const QueryParamsComponent: React.FC<Props> = ({ name, showQueryParamType, expressionProps, isLoading }) => {
    const [queryParamsList, , queryParamsHelper] = useField<HttpRequestQueryParam[]>(name);
    const shouldShowHeaders = queryParamsList?.value?.length > 0 || isLoading;

    return (
        <>
            <QueryParamsWrapper isTypeExists={showQueryParamType}>
                {shouldShowHeaders && (
                    <>
                        <QueryParamHeader>Param</QueryParamHeader>
                        <QueryParamHeader>Value</QueryParamHeader>
                        {showQueryParamType && <QueryParamHeader>On which request?</QueryParamHeader>}
                        <QueryParamHeader />
                    </>
                )}

                {!isLoading && (
                    <FieldArray name={name}>
                        {({ remove }) => {
                            return queryParamsList.value.map((queryParam, index) => (
                                <SingleQueryParamComponent
                                    expressionProps={expressionProps}
                                    showQueryParamType={showQueryParamType}
                                    key={queryParam.id}
                                    remove={() => remove(index)}
                                    name={`${name}.${index}`}
                                />
                            ));
                        }}
                    </FieldArray>
                )}

                {isLoading && range(8).map((index) => <Placeholder key={index} $height="36px" $width="auto" />)}
            </QueryParamsWrapper>

            <AddQueryParamButton
                disabled={isLoading}
                onClick={() =>
                    queryParamsHelper.setValue([
                        ...queryParamsList.value,
                        {
                            name: {
                                originalExpression: '',
                                evaluatedExpression: '',
                                isStripHtmlDisabled: true,
                            },
                            value: {
                                originalExpression: '',
                                evaluatedExpression: '',
                                isStripHtmlDisabled: true,
                            },
                            conditionType: HttpQueryParamConditionType.ALWAYS,
                            id: utils.guid(),
                        },
                    ])
                }
            >
                + Add Query Param
            </AddQueryParamButton>
        </>
    );
};

export default QueryParamsComponent;
