<div>
    <div class="flex mod-align-center">
        <!-- Field type selection title -->
        <div class="margin-right-xs margin-top">
            {{ data.targetType === 'COLUMN' ? 'Field' : 'Global Field' }} type

        </div>

        <!-- Field type selection drop down -->
        <div class="field-definition-modal-dropbox margin-top-xs" uib-dropdown>
            <!-- Selected type display -->
            <button
                data-automation="manual-field-open-field-type-drop-down"
                type="button"
                class="btn btn-default common-no-outline dropdown-toggle"
                uib-dropdown-toggle
            >
                <span class="flex-vmiddle">
                    <span class="flex-grow text-left common-ellipsis margin-right-xs">
                        {{ data.selectedType }}
                    </span>
                    <span class="caret flex-no-shrink"></span>
                </span>
            </button>

            <!-- Picklist menu -->
            <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="split-button">
                <!-- Text -->
                <li>
                    <a data-automation="manual-filed-field-type-text-option" ng-click="selectType('Text')">
                        <i class="svg-icon-sm margin-right-xs">
                            <tnk-icon src="/images/icons/text.svg"></tnk-icon>
                        </i>
                        <span class="common-size-xxxxs">Text</span>
                    </a>
                </li>

                <!-- Long Text -->
                <li ng-if="data.targetType !== 'GLOBAL' || data.selectedType === 'Long Text'">
                    <a data-automation="manual-filed-field-type-long-text-option" ng-click="selectType('Long Text')">
                        <i class="svg-icon-sm margin-right-xs">
                            <tnk-icon src="/images/icons/text.svg"></tnk-icon>
                        </i>
                        <span class="common-size-xxxxs">Long Text</span>
                    </a>
                </li>

                <!-- Number -->
                <li>
                    <a data-automation="manual-filed-field-type-number-option" ng-click="selectType('Number')">
                        <i class="svg-icon-sm margin-right-xs">
                            <tnk-icon src="/images/icons/hashtag.svg"></tnk-icon>
                        </i>
                        <span class="common-size-xxxxs">Number</span>
                    </a>
                </li>

                <!-- Date -->
                <li>
                    <a data-automation="manual-filed-field-type-date-option" ng-click="selectType('Date')">
                        <i class="svg-icon-sm margin-right-xs">
                            <tnk-icon src="/images/icons/calendar-new.svg"></tnk-icon>
                        </i>
                        <span class="common-size-xxxxs">Date</span>
                    </a>
                </li>

                <!-- Picklist -->
                <li ng-if="data.targetType !== 'GLOBAL'">
                    <a data-automation="manual-filed-field-type-dropdown-option" ng-click="selectType('Dropdown')">
                        <i class="svg-icon-sm margin-right-xs">
                            <tnk-icon src="/images/icons/chevron-circle.svg"></tnk-icon>
                        </i>
                        <span class="common-size-xxxxs">Dropdown</span>
                    </a>
                </li>

                <!-- URL -->
                <li ng-if="data.targetType !== 'GLOBAL' && data.featureUrlFieldEnabled">
                    <a data-automation="manual-filed-field-type-url-option" ng-click="selectType('URL')">
                        <i class="svg-icon-sm margin-right-xs">
                            <tnk-icon src="/images/icons/text.svg"></tnk-icon>
                        </i>
                        <span class="common-size-xxxxs">URL</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="margin-left margin-top-xs" ng-if="data.selectedType === 'URL'">
            <!-- Title -->
            <span class="margin-right-xs">URL label:

            <!-- Value input -->
            <input
              data-automation="manual-field-url-label-input"
              type="text"
              class="mod-width-100"
              ng-model="data.urlLabel"
              ng-change="urlLabelChange()"
              autocomplete="off"
            />
            </span>
        </div>
    </div>

    <hr />

    <div ng-if="['Text', 'Long Text', 'Dropdown', 'Number', 'Date', 'URL'].includes(data.selectedType)">
        <!-- Configuration for Text/Long Text -->
        <div ng-if="['Text', 'Long Text'].includes(data.selectedType)" class="flex-vmiddle">
            <tnk-toggle-buttons-group
                automation-identifier="manual-field-value-option"
                title-label="'Values:'"
                buttons="data.fieldValueTypeButtons"
                selected-button="getButtonByValue(data.fieldValueTypeButtons, data.isMultiValueField)"
                on-button-selected="selectFieldValueType(selectedButton.label)"
            ></tnk-toggle-buttons-group>

            <!-- Input separator selection -->
            <div
                class="flex-vmiddle margin-left margin-right-xs"
                ng-style="{ visibility: data.isMultiValueField ? 'visible' : 'hidden' }"
            >
                <!-- Title -->
                <div class="margin-right-xs">Separator:</div>

                <!-- Value input -->
                <input
                    data-automation="manual-field-Separator-input"
                    type="text"
                    class="form-control mod-width-100 common-center-input"
                    ng-model="data.inputMultiValueSeparator"
                    ng-change="separatorChange()"
                    placeholder="Separate input values by..."
                    autocomplete="off"
                />
            </div>
        </div>

        <!-- Configuration for Dropdown -->
        <div ng-if="data.selectedType === 'Dropdown'">
            <tnk-toggle-buttons-group
                automation-identifier="manual-field-drop-down-number-of-choices"
                title-label="'Number of Choices'"
                buttons="data.fieldValueTypeButtons"
                selected-button="getButtonByValue(data.fieldValueTypeButtons, data.isMultiValueField)"
                on-button-selected="selectFieldValueType(selectedButton.label)"
            ></tnk-toggle-buttons-group>

            <hr />

            <!-- Select an Options Source Category -->
            <tnk-toggle-buttons-group
                automation-identifier="manual-field-drop-down-source-of-options"
                title-label="'Source of Options'"
                buttons="data.dropdownSourceCategoryButtons"
                selected-button="getButtonByValue(data.dropdownSourceCategoryButtons, data.dropdownSourceCategory)"
                on-button-selected="selectFieldDropdownSourceCategory(selectedButton.value)"
            ></tnk-toggle-buttons-group>

            <!-- Select an Options Source From a Field (Values/Options) -->
            <div ng-if="data.dropdownSourceCategory === 'FIELD'">
                <hr />

                <tnk-toggle-buttons-group
                    automation-identifier="manual-field-drop-down-continuous-import-from"
                    ng-if="data.dropdownSourceCategory === 'FIELD'"
                    title-label="'Continuous Import From'"
                    buttons="data.fromFieldMetadataSyncDropdownSourceButtons"
                    selected-button="getButtonByValue(data.fromFieldMetadataSyncDropdownSourceButtons, data.dropdownSource)"
                    on-button-selected="selectFieldDropdownSource(selectedButton.value)"
                ></tnk-toggle-buttons-group>
            </div>

            <!-- Can User Add His Own Options? -->
            <div ng-if="data.dropdownSource !== 'SYNC_FIELD' && data.dropdownSource !== 'SEARCH'">
                <hr />
                
                <tnk-toggle-buttons-group
                    automation-identifier="manual-field-drop-down-users-can-add-options"
                    ng-if="data.dropdownSource !== 'SYNC_FIELD'"
                    title-label="'Users Can Add Options?'"
                    buttons="data.allowAddingOptionsButtons"
                    selected-button="getButtonByValue(data.allowAddingOptionsButtons, data.allowAddDropdownOptions)"
                    on-button-selected="selectFieldAllowAddingOptions(selectedButton.value)"
                ></tnk-toggle-buttons-group>
            </div>

            <div ng-if="data.dropdownSourceCategory === 'FIELD'">
                <hr />
                <div class="margin-right-xs flex-inline">
                    <div class="margin-right-xs margin-top">Display Separator:</div>
                    <input
                        data-automation="display-configuration-display-separator"
                        type="text"
                        class="margin-top-xs form-control mod-width-100 common-center-input"
                        ng-model="data.inputMultiValueSeparator"
                        ng-change="separatorChange()"
                        placeholder="Display values separated by..."
                        autocomplete="off"
                    />
                </div>
            </div>

            <hr />
            <!--Suggested Value -->
            <div class="flex-space-between">
                <div class="margin-right-xs flex-inline">
                    <div class="margin-right-xs margin-top">Suggested Value:</div>
                    <input
                      data-automation="dropdown-field-definition-suggested-value"
                      type="text"
                      class="margin-top-xs form-control mod-width-250"
                      ng-model="data.suggestedValue"
                      ng-change="suggestedValueChanged()"
                      autocomplete="off"
                    />
                </div>
                <i
                  class="fa fa-info-circle common-color-grey margin-left-xs"
                  uib-tooltip="Suggested values are  preselected when used in forms (only if a matching option exists in the dropdown and the item value has not been set)."
                ></i>
            </div>

            <hr />

            <!-- Select a Field (When "Field Values" Chosen) --->
            <div class="margin-top-lg" ng-if="data.dropdownSource === 'FROM_FIELD'">
                <div class="flex mod-align-center">
                    <span class="inline-block margin-right-xs">Field:</span>

                    <!-- Choose a Field -->
                    <tnk-field-selector
                        class="inline-block mod-width-250"
                        ng-class="{ 'selector-value-selected': data.fieldToMatch }"
                        items-source="COLUMN"
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        special-fields-for-features="['MATCH_TRACK_FIELD']"
                        selected-field="data.selectedDropdownOptionsFromFieldDefinitionId"
                        display-integration-indication="true"
                        fields-filter="filterCurrentFields"
                        on-field-selected="selectDropdownFieldSource(selectedField.id, dontSaveChanges)"
                        include-global-fields="true"
                    ></tnk-field-selector>
                </div>
            </div>

            <!-- Select a Field --->
            <div class="margin-top-lg" ng-if="data.dropdownSource === 'SEARCH'">
                <div class="flex mod-align-center">
                    <span class="inline-block margin-right-xs">Field:</span>

                    <!-- Choose a Field -->
                    <tnk-field-selector
                            class="inline-block mod-width-250"
                            ng-class="{ 'selector-value-selected': data.fieldToMatch }"
                            items-source="COLUMN"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            selected-field="data.selectedDropdownOptionsFromFieldDefinitionId"
                            display-integration-indication="true"
                            fields-filter="filterMatchedEntitiesRelatedFields"
                            on-field-selected="selectDropdownFieldSource(selectedField.id, dontSaveChanges)"
                    ></tnk-field-selector>
                </div>
            </div>

            <!-- Select a Field (When "Field Options" is Chosen) --->
            <div class="margin-top-lg" ng-if="data.dropdownSource === 'SYNC_FIELD'">
                <div class="flex mod-align-center">
                    <span class="inline-block margin-right-xs">Field:</span>

                    <!-- Choose a Field -->
                    <tnk-field-selector
                        class="inline-block mod-width-250"
                        ng-class="{ 'selector-value-selected': data.fieldToMatch }"
                        items-source="COLUMN"
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        selected-field="data.dropdownOptionsFromFieldMetadataSyncFieldDefinition"
                        display-integration-indication="true"
                        do-not-display-special-fields="true"
                        do-not-display-existing-fields="true"
                        add-fields-quick-create-options="true"
                        on-field-selected="selectDropdownFieldSyncSource(selectedField, dontSaveChanges)"
                    ></tnk-field-selector>
                </div>

                <!-- Preview of Choices for Sync -->
                <div>
                    <div
                        class="margin-top-md"
                        ng-if="
                            data.dropdownOptionsFromFieldMetadataSyncFieldDefinition.definition.fieldMetadata.values
                                .length
                        "
                    >
                        <tnk-picklist-value-editor
                            disable-edit="true"
                            existing-values="data.dropdownOptionsFromFieldMetadataSyncFieldDefinition.definition.fieldMetadata.values"
                        ></tnk-picklist-value-editor>
                    </div>

                    <!-- No Preview Available Message -->
                    <div
                        class="margin-top-md"
                        ng-if=" 
                            data.dropdownOptionsFromFieldMetadataSyncFieldDefinition &&
                            !data.dropdownOptionsFromFieldMetadataSyncFieldDefinition.definition.fieldMetadata.values
                                .length
                        "
                    >
                        This field does not have possible options.
                    </div>
                </div>
            </div>
            <hr/>
            <tnk-toggle-buttons-group
                ng-if="data.dropdownSource === 'SEARCH'"
                automation-identifier="show-manual-options-of-no-results -found"
                title-label="'Show manual options if no results found'"
                buttons="data.showManualOptionsNoResultsButtons"
                selected-button="getButtonByValue(data.showManualOptionsNoResultsButtons, data.showManualOptionsNoResults)"
                on-button-selected="showManualOptionsNoResultsChanged(selectedButton.value)"
            ></tnk-toggle-buttons-group>

                    <!-- Picklist Manual Options (When field of type dropdown) -->
            <tnk-picklist-value-editor
                class="margin-top-lg"
                create-mode="data.createMode"
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                duplicate-mode="data.duplicateMode"
                existing-values="data.existingFieldDefinition.possibleValues"
                on-picklist-changed="onPicklistValuesChanged(values)"
                enable-import="true"
                source-options="data.dropdownSource"
                on-change="onChange()"
                required="data.dropdownSource === 'MANUAL'"
            ></tnk-picklist-value-editor>
        </div>

        <div ng-if="data.featureDefaultFieldSource" class="flex flex-vmiddle margin-top">
            <span class="inline-block margin-right-xs">Default value field source:</span>
            <tnk-field-selector
                    class="inline-block mod-width-250"
                    ng-class="{ 'selector-value-selected': data.fieldToMatch }"
                    items-source="COLUMN"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    special-fields-for-features="['MATCH_TRACK_FIELD']"
                    selected-field="data.defaultValueFieldDefinition"
                    display-integration-indication="true"
                    fields-filter="filterCurrentFields"
                    on-field-selected="selectDefaultValueFieldDefinitionId(selectedField.id, dontSaveChanges)"
            ></tnk-field-selector>
        </div>


        <!-- Value entering for key metric manual field -->
        <div class="flex flex-vmiddle margin-top" ng-if="data.targetType === 'GLOBAL' && data.duplicateOrCreateMode">
            <!-- Title -->
            <div class="margin-right-xs">Value:</div>

            <!-- Value input -->
            <input
                type="{{ data.selectedType === 'Number' || data.selectedType === 'Decimal' ? 'number' : 'text' }}"
                ng-if="data.selectedType !== 'Date'"
                step="any"
                class="form-control common-width-50"
                data-automation="manual-field-global-field-input"
                ng-model="data.manualValue"
                ng-change="onManualValueChanged()"
                ng-keydown="onValueInputKeyDown($event)"
                placeholder="Enter value"
                required
            />

            <div class="input-group btn-group flex-vmiddle common-width-50" ng-if="data.selectedType === 'Date'">
                <input
                    type="text"
                    class="form-control"
                    uib-datepicker-popup="MMM dd, yyyy"
                    id="update-input-date"
                    datepicker-options="{showWeeks: false}"
                    ng-model="data.manualValue"
                    ng-change="data.manualDatePickerIsOpen = false; onManualValueChanged();"
                    ng-keydown="onValueInputKeyDown($event)"
                    is-open="data.manualDatePickerIsOpen"
                    close-text="Close"
                    required
                />
                <button
                    type="button"
                    class="btn btn-default"
                    ng-click="data.manualDatePickerIsOpen = !data.manualDatePickerIsOpen"
                >
                    <i class="svg-icon-track svg-icon-hover-black common-color-light-grey svg-icon-align-text-top">
                        <tnk-icon src="/images/icons/calendar.svg"></tnk-icon>
                    </i>
                </button>
            </div>
        </div>
    </div>
</div>
