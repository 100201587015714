function tnkIcon() {
    return {
        restrict: 'E',
        replace: 'true',
        scope: {},
        template(element, attrs) {
            return `<span class="tnk-icon" ng-include="'${attrs.src}'"></span>`;
        },
    };
}
angular.module('tonkean.shared').directive('tnkIcon', tnkIcon);
