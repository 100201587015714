import styled from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';

const SingleWorkerRunFlowRunTableRowGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(1px, 6fr) minmax(1px, 2fr) minmax(1px, 2fr);
    grid-gap: 20px;
    min-height: 40px;
    padding: 10px 0;
    font-size: ${FontSize.SMALL_12};

    > &:first-child {
        padding-left: 20px;
    }
`;

export default SingleWorkerRunFlowRunTableRowGrid;
