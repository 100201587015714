import React from 'react';

import UploadWidget from './UploadWidget';
import type { ItemWidgetProps } from '../../WidgetModule';
import type { UploadWidgetConfiguration } from '../entities';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useItemInterfaceHeaderThemeConfiguration } from '@tonkean/tonkean-entities';

const ItemInterfaceUploadWidget: React.FC<ItemWidgetProps<UploadWidgetConfiguration>> = ({
    initiative: parentInitiative,
    widget,
    permission,
}) => {
    const { project, itemInterface } = useItemInterfaceContext();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);

    return (
        <UploadWidget
            groupId={parentInitiative?.group.id}
            permission={permission}
            theme={themeConfiguration}
            projectId={project.id}
            initiative={parentInitiative}
            widget={widget}
        />
    );
};

export default ItemInterfaceUploadWidget;
