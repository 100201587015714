import template from './tnkHttpRequestConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkHttpRequestConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        isIntegrationGenericAction: '<',
        // if true tonkean expression will be effected will show only additional tabs
        // when isIntegrationGenericActionModal true isIntegrationGenericAction should be true too
        isIntegrationGenericActionModal: '<',
        apiBaseUrl: '@',
        existingDefinition: '<',
        onDefinitionChanged: '&',
        importActionConfig: '@',
        isHttpUploadAction: '<',
        isPreviewHidden: '<',
        isBaseUrlHidden: '<',
        additionalTabsForTonkeanExpression: '<',
        previewEvaluationSource: '<',
        shouldDeleteVariable: '<',
    },
    template,
    controller: 'HttpRequestConfigurationCtrl',
});
