import { reactToAngular } from 'angulareact';

import PeopleDirectoryPicker from './PeopleDirectoryPicker';

export default angular
    .module('tonkean.app')
    .component(
        'tnkPeopleDirectoryPicker',
        reactToAngular(PeopleDirectoryPicker, [
            'peopleDirectoriesNameExpression',
            'updatePeopleDirectoriesNameExpression',
            'sendToGroupOfPeopleAs',
            'previewEvaluationSource',
            'updateSendToGroupOfPeopleAs',
            'validationObject',
            'customTrigger',
        ]),
    );
