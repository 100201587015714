import { reactToAngular } from 'angulareact';

import AddContractFieldsModal from './AddContractFieldsModal';

export default angular
    .module('tonkean.app')
    .component(
        'tnkAddContractFieldsModal',
        reactToAngular(AddContractFieldsModal, ['initialFieldsState', 'onSubmit', 'onClose', 'open']),
    );
