import { OperatorFamily } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FormulaOperatorDefinitionBase } from '@tonkean/tonkean-entities';

abstract class LogicalOperatorBase extends FormulaOperatorDefinitionBase {
    public readonly family = OperatorFamily.LOGICAL;
    public readonly dataType = FieldType.Boolean;
}

export default LogicalOperatorBase;
