import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function EnterpriseManageMembersCtrl($scope, tonkeanService, modal, authenticationService) {
    $scope.data = {
        sortField: 'NAME',
        sortDesc: false,
        currentPage: 1,
        pageSize: 7,
        enterprise: authenticationService.currentUser.enterprise,
        enterpriseId: authenticationService.currentUser.enterprise.id,
        currentUserId: authenticationService.currentUser.id,
        peopleSearch: '',
    };

    $scope.init = function () {
        $scope.getEnterpriseMembers($scope.data.enterpriseId);
    };

    $scope.$watch('data.peopleSearch', function () {
        $scope.data.currentPage = 1;
        $scope.data.searchTotal = 0;
        $scope.data.people = [];
        $scope.getEnterpriseMembers($scope.data.enterpriseId);
    });

    $scope.$watchGroup(['data.currentPage', 'data.sortField', 'data.sortDesc'], function () {
        $scope.getEnterpriseMembers($scope.data.enterpriseId);
    });

    $scope.sort = function (sortBy) {
        if ($scope.data.sortField === sortBy) {
            $scope.data.sortDesc = !$scope.data.sortDesc;
        } else {
            $scope.data.sortField = sortBy;
        }
    };

    $scope.removeSeat = function (person, projects) {
        const projectsNames = projects ? $scope.getProjectsNames(projects) : '';
        const text1 = `<span class="common-color-black common-size-xxs">${person.name} will be removed as a member<br>from: ${projectsNames}.</span>`;
        const text2 = `<span class="common-color-light-grey common-size-xxs">They can still be re-added as a member by any<br> user from ${$scope.data.enterprise.name}.</span>`;
        $scope.questionConfirmModalData = {
            title: `Remove ${person.name} as a member?`,
            body: `<div>${text1}<br>${text2}</div>`,
            okLabel: 'Yes',
            cancelLabel: 'No',
        };

        modal
            .openQuestionConfirmModal({
                scope: $scope,
                windowClass: 'mod-warning',
            })
            .result.then(function () {
                // okLabel clicked.
                tonkeanService
                    .deleteEnterpriseSeat($scope.data.enterpriseId, person.id)
                    .then(function () {
                        $scope.getEnterpriseMembers($scope.data.enterpriseId);
                    })
                    .catch(function (error) {
                        $scope.error = error;
                    })
                    .finally(function () {});
            });
    };

    /**
     * Get members of an enterprise
     * Page size is to limit amount of items per page
     * Page index is for pagination, the user can ask for next page
     * @param enterpriseId
     */
    $scope.getEnterpriseMembers = function () {
        const pageIndex = $scope.data.currentPage - 1;
        const skip = $scope.data.pageSize + (pageIndex - 1) * $scope.data.pageSize;
        if (!$scope.data.loadingPeople && pageIndex !== -1 && (skip === 0 || skip < $scope.data.totalPeople)) {
            $scope.data.loadingPeople = true;
            const q = $scope.data.peopleSearch;
            const options = {
                includeDisabled: true,
                sortField: $scope.data.sortField,
                sortDesc: $scope.data.sortDesc,
            };
            tonkeanService
                .getEnterpriseMembers($scope.data.enterpriseId, options, q, $scope.data.pageSize, skip)
                .then(function (data) {
                    if (q === $scope.data.peopleSearch) {
                        $scope.data.people = data.people;
                    }
                    if (!q) {
                        $scope.data.totalPeople = data.total;
                    }
                    $scope.data.searchTotal = data.total;
                })
                .catch(function (error) {
                    $scope.data.error = error;
                })
                .finally(function () {
                    $scope.data.loadingPeople = false;
                    if (q !== $scope.data.peopleSearch) {
                        $scope.getEnterpriseMembers();
                    }
                });
        }
    };

    $scope.getProjectsNames = function (projects) {
        return projects.map((project) => project.name).join(', ');
    };

    $scope.init();
}

export default angular
    .module('tonkean.app')
    .controller('EnterpriseManageMembersCtrl', lateConstructController(EnterpriseManageMembersCtrl));
