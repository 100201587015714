<div>
    <div
        ng-if="!displayInPlace"
        class="common-height-full"
        uib-popover-template="'./addFunctionPopoverTemplate.template.html'"
        popover-placement="{{::placement || 'auto left'}}"
        popover-is-open="popover.isOpen"
        popover-trigger="none"
    >
        <ng-transclude></ng-transclude>
    </div>
    <div ng-if="displayInPlace">
        <div ng-include="'./addFunctionPopoverTemplate.template.html'"></div>
    </div>
</div>
