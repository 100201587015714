function BotGatherExampleCtrl(
    $scope,
    $location,
    $localStorage,
    modalUtils,
    projectManager,
    hideManageTriggersModal,
    communicationIntegrationsService,
) {
    $scope.modalUtils = modalUtils;
    $scope.groupId = $location.search().g || $localStorage.lastGroupId || projectManager.groupDefaultId;

    $scope.images = {
        bot: [
            {
                title: "When it's time for an update",
                img: '/images/onBoarding/bot-gather-example-1.png',
                mobileImage: '/images/onBoarding/bot-gather-example-1.png',
            },
            {
                title: 'When important data changes',
                img: '/images/onBoarding/bot-gather-example-2.png',
                mobileImage: '/images/onBoarding/bot-gather-example-2.png',
            },
            {
                title: 'When you need an immediate answer',
                img: '/images/onBoarding/bot-gather-example-3.png',
                mobileImage: '/images/onBoarding/bot-gather-example-3.png',
            },
        ],
        mail: [
            {
                title: "When it's time for an update",
                img: '/images/onBoarding/bot-gather-example-email-1.png',
                mobileImage: '/images/onBoarding/bot-gather-example-email-1.png',
            },
            {
                title: 'When important data changes',
                img: '/images/onBoarding/bot-gather-example-email-2.png',
                mobileImage: '/images/onBoarding/bot-gather-example-email-2.png',
            },
            {
                title: 'When you need an immediate answer',
                img: '/images/onBoarding/bot-gather-example-email-3.png',
                mobileImage: '/images/onBoarding/bot-gather-example-email-3.png',
            },
        ],
    };

    $scope.data = {
        mode: 'bot',
        hideManageTriggersModal,
    };

    $scope.init = function () {
        // If no communication integration connected, change to mail mode.
        $scope.data.mode = communicationIntegrationsService.anyIntegrationsConnected() ? 'bot' : 'mail';
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('BotGatherExampleCtrl', BotGatherExampleCtrl);
