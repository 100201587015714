import CloudConvertAuthenticationComponent from './CloudConvertAuthenticationComponent';
import cloudConvertIcon from '../../../../apps/tracks/images/cloudconvert.png';
import cloudConvertCircleIcon from '../../../../apps/tracks/images/integrations/cloudconvert-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class CloudConvertIntegration extends IntegrationDefinitionBase {
    override name = 'cloudconvert';
    override displayName = 'CloudConvert';
    override description = 'We will collect Jobs and Tasks data.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [new IntegrationEntity('Job', 'Jobs'), new IntegrationEntity('Task', 'Tasks')];
    override customizedAuthenticationComponent = CloudConvertAuthenticationComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(cloudConvertCircleIcon, '130px'),
        new IntegrationIcon(cloudConvertIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Job: true, Task: true },
        isOnlyDataRetention: {},
    };
}

export default CloudConvertIntegration;
