<section ng-if="!data.isLoading" class="login-page flex-vmiddle mod-no-bg">
    <div class="flex-vmiddle-xs-block flex-grow common-height-full">
        <div class="login-page-content mod-create-form" data-automation="login-create-template-login-page">
            <h1 class="login-page-title">Welcome to Tonkean!</h1>
            <div class="common-size-xs margin-top common-title-font">Tell us a bit about yourself:</div>

            <div ng-show="!error">
                <form
                    name="createTeamForm"
                    class="padding-bottom"
                    ng-submit="createTeamForm.$valid && create()"
                    novalidate
                >
                    <div class="margin-normal-sm-v hidden-xs">
                        <!--Your basic info:-->
                        &nbsp;
                    </div>
                    <div class="form-group">
                        <div class="common-title-xxs margin-bottom-xxs">Your name</div>
                        <input
                            type="text"
                            ng-model="data.userName"
                            class="form-control margin-bottom-lg sign-up-input"
                            placeholder="Name"
                            autofocus
                            ng-disabled="creating"
                            required
                        />

                        <div class="common-title-xxs margin-bottom-xxs">Your title</div>
                        <input
                            type="text"
                            ng-model="data.title"
                            class="form-control margin-bottom-lg sign-up-input"
                            data-automation="login-create-enter-title"
                            placeholder="Your job title"
                            ng-disabled="creating"
                            required
                        />

                        <div class="common-title-xxs margin-bottom-xxs">Your phone number</div>
                        <input
                            type="text"
                            ng-model="data.phone"
                            class="form-control margin-bottom-lg sign-up-input"
                            placeholder="Your phone number (optional)"
                            ng-disabled="creating"
                            tnk-phone-input="true"
                        />

                        <div class="common-title-xxs margin-bottom-xxs">Account name</div>
                        <div class="common-size-xxs margin-bottom-xxs common-color-grey3">
                            Usually your team or company name. You can always change this later.
                        </div>
                        <input
                            type="text"
                            ng-model="data.companyName"
                            class="form-control margin-bottom-xs sign-up-input"
                            data-automation="login-create-input-account-name"
                            placeholder="ACME Inc."
                            ng-disabled="creating"
                            required
                        />
                        <div class="flex" ng-if="data.companyDomain">
                            <div class="margin-right">
                                <input type="checkbox" ng-model="data.useCreatorDomain" />
                            </div>
                            <div class="flex-grow">
                                Let other people access this account when signing up a verified
                                <span class="common-bold">@{{ data.companyDomain }}</span>
                                email address
                            </div>
                        </div>
                    </div>

                    <!--<div class="margin-normal-sm-v login-label">-->
                    <!--What areas of your business do you want to track?-->
                    <!--<br/>-->
                    <!--(select all that apply)-->
                    <!--</div>-->
                    <!--<div class="form-group padding-bottom">-->
                    <!--<div class="checkbox margin-normal-sm-v">-->
                    <!--<label><input id="chkCustomerSuccess" type="checkbox" ng-model="data.usecaseMap.CUSTOMER_SUCCESS">Customer success</label>-->
                    <!--</div>-->
                    <!--<div class="checkbox margin-normal-sm-v">-->
                    <!--<label><input id="chkProduct" type="checkbox" ng-model="data.usecaseMap.PRODUCT_ECOSYSTEM">Product ecosystem</label>-->
                    <!--</div>-->
                    <!--<div class="checkbox margin-normal-sm-v">-->
                    <!--<label><input id="chkOperations" type="checkbox" ng-model="data.usecaseMap.OPERATIONS">Company operations</label>-->
                    <!--</div>-->
                    <!--</div>-->
                    <div class="inline-block margin-top-xs">
                        <button
                            type="submit"
                            class="btn btn-lg login-btn mod-primary mod-icon-on-right common-bold margin-bottom-xs"
                            data-automation="login-create-submit-you-account"
                            ng-disabled="creating"
                        >
                            <i ng-show="creating" class="loading mod-white"></i>
                            Create your account
                            <i class="fa fa-long-arrow-right margin-left"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div ng-show="error" class="login-token-error-box">
            <div class="alert alert-danger text-center" role="alert">{{ error }}</div>
        </div>
    </div>
    <div class="login-page-image-box common-bg mod-gradient-blue mod-rpa hidden-sm hidden-xs">
        <div class="login-page-image-inner-box mod-left">
            <div class="login-page-image mod-rpa"></div>
        </div>
    </div>

    <!-- Placeholder hidden div for pre loading login images-->
    <div id="login-images" class="hidden"></div>

    <a
        ng-click="logout()"
        class="absolute-bottom-left common-color-light-grey common-size-xxxxs margin-left margin-bottom"
    >
        Sign out
    </a>
</section>

<section ng-if="data.isLoading" class="login-page mod-no-bg">
    <div class="flex-vmiddle-xs-block flex-grow common-height-full padding-top-xxlg">
        <div class="flex mod-justify-center common-width-full">
            <div>
                <div class="common-size-xl login-page-title">Signing in...</div>
                <i class="loading-large" data-automation="loading-circle"></i>
            </div>
        </div>
    </div>
    <div class="login-page-image-box common-bg mod-gradient-blue hidden-sm hidden-xs">
        <div class="login-page-image-inner-box mod-left">
            <div class="login-page-image mod-rpa"></div>
        </div>
    </div>
</section>