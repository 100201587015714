import type { Person } from '@tonkean/tonkean-entities';

const getAdminsAsTextObject = (admins: Person[]) => {
    const adminsLength = admins.length;
    return admins.map((admin, index) => {
        const isLastAdmin = index === adminsLength - 1;
        const isFirstAdmin = index === 0;
        const getPrefix = () => {
            if (isFirstAdmin) {
                return ' ';
            } else if (isLastAdmin) {
                return ' or ';
            } else {
                return ', ';
            }
        };
        return {
            name: admin.name,
            email: admin.email,
            prefix: getPrefix(),
            id: admin.id,
        };
    });
};

export default getAdminsAsTextObject;
