import ProcessMapperWidget from './ProcessMapperWidget';
import type ProcessMapperWidgetConfiguration from './ProcessMapperWidgetConfiguration';
import ProcessMapperWidgetEditor from './ProcessMapperWidgetEditor';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const ProcessMapperWidgetPackage: ItemInterfaceWidgetPackage<ProcessMapperWidgetConfiguration> = {
    displayComponent: ProcessMapperWidget,
    configurationComponent: ProcessMapperWidgetEditor,
};

export default ProcessMapperWidgetPackage;
