import React from 'react';

import { ReactComponent as MailIcon } from '../../../../../../images/integrations/mail.svg';

import { CommunicationIntegrationIcon } from '@tonkean/infrastructure';
import { IntegrationType, MessageSendBy } from '@tonkean/tonkean-entities';

interface Props {
    messageSendBy: MessageSendBy;
    overrideText?: string;
}

const messageSendByToCommunicationIntegrationType: Partial<
    Record<MessageSendBy, { type: IntegrationType; displayName: string }>
> = {
    SLACK: { type: IntegrationType.SLACK, displayName: 'Slack' },
    GOOGLE_CHAT: { type: IntegrationType.GOOGLECHAT, displayName: 'Google Chat' },
    MS_TEAMS: { type: IntegrationType.MICROSOFT_TEAMS, displayName: 'Microsoft Teams' },
};

const CommunicationIntegrationByMessageSendBy: React.FC<Props> = ({ messageSendBy, overrideText }) => {
    if (messageSendBy === MessageSendBy.EMAIL) {
        return (
            <>
                <MailIcon />
                &nbsp;
                {overrideText ?? 'Email'}
            </>
        );
    }

    const communicationIntegrationType = messageSendByToCommunicationIntegrationType[messageSendBy];

    if (!communicationIntegrationType) {
        return <></>;
    }

    return (
        <>
            <CommunicationIntegrationIcon integrationType={communicationIntegrationType.type} />
            &nbsp;
            {overrideText ?? communicationIntegrationType.displayName}
        </>
    );
};

export default CommunicationIntegrationByMessageSendBy;
