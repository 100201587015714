import type { TElement } from '@udecode/plate';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import TemplateCategoriesSelector from '../../../../../modals/TemplateConfigurationModal/TemplateCategoriesSelector';

import { HTMLEditor } from '@tonkean/editor';
import { Field, Input, useFormikField } from '@tonkean/infrastructure';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

interface Props {
    entityName: string;
}

const MarketplaceItemCreateModalOverviewSection: React.FC<Props> = ({ entityName }) => {
    const { value: subtitle, setValue: setSubtitle } = useFormikField<TElement[]>('subtitle');
    const { value: categories, setValue: setCategories } = useFormikField<string[]>('categories');

    const selectedCategoriesOptions = useMemo(() => {
        return categories.map((categoryName) => ({ id: categoryName, title: categoryName }));
    }, [categories]);

    const onSelectedCategoriesChange = (values) => {
        const newCategories = values.map((value) => value.title);
        setCategories(newCategories);
    };

    return (
        <>
            <StyledField
                label="Template Name (Slug URL)"
                informationTooltip={
                    <>
                        The {entityName} name will be used as a unique identifier of the {entityName}. It will also be
                        used as the last part of the page URL (Slug URL). Please use lowercase letters and hyphens as
                        the word separator. Example : "legal-intake-triage-coordination"
                    </>
                }
            >
                <Input data-automation="marketplace-item-create-modal-template-name" name="templateName" />
            </StyledField>
            <StyledField
                label="Title"
                informationTooltip={<>Title will be used as the {entityName} header in the public library.</>}
            >
                <Input data-automation="marketplace-item-create-modal-title" name="title" />
            </StyledField>
            <StyledField
                label="Summary text on library card"
                informationTooltip={
                    <>The text will be presented on the {entityName} summary card in the public library.</>
                }
            >
                <Input data-automation="marketplace-item-create-modal-summary-preview" name="previewSummary" />
            </StyledField>
            <StyledField
                label="Subtitle"
                informationTooltip={<>The text that will be presented below the {entityName} main header.</>}
            >
                <HTMLEditor
                    dataAutomation="marketplace-item-create-modal-subtitle"
                    initialValue={subtitle}
                    onChange={(value) => setSubtitle(value)}
                />
            </StyledField>
            <StyledField
                label="Related Categories"
                informationTooltip={<>Select the relevant business categories this {entityName} apply for.</>}
            >
                <TemplateCategoriesSelector
                    categories={selectedCategoriesOptions}
                    onChange={onSelectedCategoriesChange}
                />
            </StyledField>
        </>
    );
};

export default MarketplaceItemCreateModalOverviewSection;
