/**
 * Represents the different types of authentication methods we support for integrations.
 */
enum AuthenticationMethod {
    /**
     * API token authentication.
     */
    API_TOKEN = 'API_TOKEN',

    /**
     * OAuth authentication.
     */
    OAUTH = 'OAUTH',

    /**
     * Custom implementation for authentication method.
     */
    CUSTOM = 'CUSTOM',

    /**
     * None implementation for authentication method.
     */
    NONE = 'NONE',
}

export default AuthenticationMethod;
