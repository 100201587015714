import React, { useContext, useMemo } from 'react';

import type { TaggableEntityItem } from '../../taggableEntities/entities';
import useGetTaggableEntities from '../../taggableEntities/hooks/useGetTaggableEntities';

import type { FieldDefinitionSummary, TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

/**
 * Context for comments and taggable entities in comments
 */
export interface CommentsContext {
    workflowVersionType?: WorkflowVersionType;
    fields?: FieldDefinitionSummary[];
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    projectId?: TonkeanId<TonkeanType.PROJECT>;
    taggableCustomTriggerInterfaces?: TaggableEntityItem[];
}

export const CommentsContext = React.createContext<CommentsContext>({});

export default function useCommentContext() {
    const { projectId, fields, initiativeId, workflowVersionType, taggableCustomTriggerInterfaces } =
        useContext(CommentsContext);

    const fieldsEntities: TaggableEntityItem[] = useMemo(() => {
        return (
            fields?.map((field) => ({
                id: field.fieldDefinitionId,
                display: field.label ?? field.name,
                previewValue: field.value,
            })) || []
        );
    }, [fields]);

    const { fetchEntities, getTaggabaleEntityById } = useGetTaggableEntities(
        fieldsEntities,
        projectId,
        taggableCustomTriggerInterfaces,
    );

    return {
        fetchEntities,
        getTaggabaleEntityById,
        projectId,
        fields: fieldsEntities,
        initiativeId,
        workflowVersionType,
        taggableCustomTriggerInterfaces,
    } as const;
}
