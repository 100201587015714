import React, { useState } from 'react';
import styled from 'styled-components';

import type CustomizedAuthenticationComponentProps from '../../../base/CustomizedAuthenticationComponentProps';
import IntegrationManager from '../../../IntegrationManager';
import GoogleInternalGCPServiceAccount from '../googleInternalGCPServiceAccount/GoogleInternalGCPServiceAccount';
import GoogleOAuthAuthentication from '../googleOAuthAuthentication/GoogleOAuthAuthentication';
import type OAuthPermissionType from '../googleOAuthAuthentication/OAuthPermissionType';
import GoogleServiceAccount from '../googleServiceAccount/GoogleServiceAccount';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Checkbox, Radio, RadioGroup } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { GoogleAuthenticationType } from '@tonkean/tonkean-entities';
import { IntegrationType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

export interface GoogleAuthenticationProps extends CustomizedAuthenticationComponentProps {
    googleIntegrationType?: IntegrationType;
    initialOauthPermissionType?: OAuthPermissionType;
    // If initialGoogleAuthenticationType is present, we will allow only this option.
    initialGoogleAuthenticationType?: GoogleAuthenticationType;
    disableOtherGoogleAuthenticationTypes?: boolean;
}

const AuthenticationTypeWrapper = styled.div`
    margin-bottom: 8px;
`;

const ContentWrapper = styled.div`
    text-align: center;
`;

const DisclaimerText = styled.div`
    font-size: 13px;
    color: ${Theme.colors.gray_500};
    margin-top: 5px;
`;

const IndexContentSection = styled.div`
    text-align: left;
    margin: 12px 0 0 3px;
`;

const CheckboxLabel = styled.div`
    padding-left: 8px;
`;

const GoogleAuthenticationComponent: React.FC<GoogleAuthenticationProps> = ({
    authenticate,
    googleIntegrationType,
    initialGoogleAuthenticationType,
    initialOauthPermissionType,
    disableOtherGoogleAuthenticationTypes,
    integrationConfiguration,
}) => {
    const isGcpEnvironmentSupported = useFeatureFlag('tonkean_feature_gcp_service_account_enabled') as boolean;

    const [authenticationType, setAuthenticationType] = useState<GoogleAuthenticationType>(
        initialGoogleAuthenticationType || GoogleAuthenticationType.PUBLIC,
    );

    const [shouldIndexContent, setShouldIndexContent] = useState<boolean>(false);

    const innerGoogleIntegrationType =
        googleIntegrationType || (integrationConfiguration.name.toUpperCase() as IntegrationType);

    const smartSearchFlagEnabled = useFeatureFlag('tonkean_feature_smartsearch');

    const shouldDisableAuthenticationType = (authenticationType) => {
        return (
            disableOtherGoogleAuthenticationTypes &&
            initialGoogleAuthenticationType &&
            initialGoogleAuthenticationType !== authenticationType
        );
    };

    const shouldDisplayDisclaimerText = () => {
        return innerGoogleIntegrationType === IntegrationType.GOOGLESHEETS;
    };

    const getAuthenticationComponent = (authenticationType: GoogleAuthenticationType) => {
        const integrationConfiguration = IntegrationManager.getIntegrationByName(innerGoogleIntegrationType);
        if (integrationConfiguration) {
            switch (authenticationType) {
                case GoogleAuthenticationType.SERVICE_ACCOUNT:
                    return <GoogleServiceAccount authenticate={authenticate} shouldIndexContent={shouldIndexContent} />;
                case GoogleAuthenticationType.INTERNAL_GCP:
                    return (
                        <GoogleInternalGCPServiceAccount
                            authenticate={authenticate}
                            shouldIndexContent={shouldIndexContent}
                        />
                    );
                case GoogleAuthenticationType.PUBLIC:
                    return (
                        <GoogleOAuthAuthentication
                            integrationDisplayName={integrationConfiguration.displayName}
                            authenticate={authenticate}
                            shouldIndexContent={shouldIndexContent}
                            googleIntegrationType={innerGoogleIntegrationType}
                            initialOAuthPermissionType={initialOauthPermissionType}
                        />
                    );
            }
        }
    };

    return (
        <ContentWrapper>
            <AuthenticationTypeWrapper>
                <RadioGroup value={authenticationType} onChange={setAuthenticationType} direction="row">
                    <Radio
                        disabled={shouldDisableAuthenticationType(GoogleAuthenticationType.PUBLIC)}
                        value={GoogleAuthenticationType.PUBLIC}
                    >
                        OAuth
                    </Radio>
                    <Radio
                        disabled={shouldDisableAuthenticationType(GoogleAuthenticationType.SERVICE_ACCOUNT)}
                        value={GoogleAuthenticationType.SERVICE_ACCOUNT}
                    >
                        Service Account
                    </Radio>
                    {isGcpEnvironmentSupported && (
                        <Radio
                            disabled={shouldDisableAuthenticationType(GoogleAuthenticationType.INTERNAL_GCP)}
                            value={GoogleAuthenticationType.INTERNAL_GCP}
                        >
                            GCP
                        </Radio>
                    )}
                </RadioGroup>
            </AuthenticationTypeWrapper>

            {getAuthenticationComponent(authenticationType)}

            {IntegrationType.GOOGLEDRIVE && smartSearchFlagEnabled && (
                <IndexContentSection>
                    <Checkbox checked={shouldIndexContent} onChange={() => setShouldIndexContent((value) => !value)}>
                        <Tooltip
                            limitWidth={50}
                            content="Enabling this option allows AI integrations to access the contents of the connected
                            folders and retrieve requested data. Enable this option to allow the AI Front Door to access
                            contents of the connected folder"
                        >
                            <CheckboxLabel>Include file content</CheckboxLabel>
                        </Tooltip>
                    </Checkbox>
                </IndexContentSection>
            )}

            {shouldDisplayDisclaimerText() && (
                <DisclaimerText>
                    Once the data source is connected, you won't be able to change the authentication type.
                </DisclaimerText>
            )}
        </ContentWrapper>
    );
};

export default GoogleAuthenticationComponent;
