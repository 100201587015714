import type PersonProjectContext from './PersonProjectContext';
import type SCIMTonkeanRole from './SCIMTonkeanRole';

export function isUserInRole(personProjectContext: PersonProjectContext | undefined, role: SCIMTonkeanRole) {
    return personProjectContext?.calculatedTonkeanRoles.includes(role);
}

export function isUserOnlyInRole(personProjectContext: PersonProjectContext | undefined, role: SCIMTonkeanRole) {
    return (
        personProjectContext?.calculatedTonkeanRoles.length === 1 &&
        personProjectContext?.calculatedTonkeanRoles[0] === role
    );
}

export function noRolesUser(personProjectContext: PersonProjectContext | undefined) {
    return personProjectContext?.calculatedTonkeanRoles.length === 0;
}
