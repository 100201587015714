import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as SearchTooltipIcon } from '../../../../../../images/icons/history/search-tooltip.svg';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { SearchBox, Tooltip, useDebouncer } from '@tonkean/infrastructure';

const Wrapper = styled.div`
    position: relative;
`;

const TooltipTrigger = styled.div`
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -6px;

    span {
        display: block;
        line-height: 0;
    }
`;

const TooltipContent = styled.div`
    text-align: left;
`;

interface Props {
    searchTerm: string;
    /** A callback function to emit the new value after debounce. **Must be memoized** */
    onChange(term: string): void;
}

const WorkerRunsSearchBox: React.FC<Props> = ({ searchTerm, onChange }) => {
    const [value, setValue] = useState(searchTerm);
    const $state = useAngularService('$state');
    const [q] = useGetStateParams('q');

    useEffect(() => {
        setValue(searchTerm);
    }, [searchTerm]);

    // Init search term from url query param
    useEffect(() => {
        setValue(q);
    }, [q]);

    const emitValue = useCallback(() => {
        if (searchTerm === value) {
            return;
        }

        $state.go('.', { ...$state.params, q: value }, { reload: false, notify: false });

        onChange(value);
    }, [value, searchTerm, onChange, $state]);

    useDebouncer(emitValue, 200);

    return (
        <Wrapper>
            <SearchBox
                value={value}
                onChange={(event) => setValue(event.target.value)}
                data-automation="search-box-module-events-history"
                autoFocus
            />

            <Tooltip
                placement="right"
                content={
                    // TODO: bring back in V2
                    // <TooltipContent>
                    //     Search by the following:
                    //     <br />
                    //     - Data source item Name/ID
                    //     <br />
                    //     - Module item Name/ID
                    //     <br />
                    //     - Free text to be found in one of the items fields
                    // </TooltipContent>
                    <TooltipContent data-automation="worker-runs-search-box-tooltip-popup">
                        Search by the module item name and/or ID
                    </TooltipContent>
                }
            >
                <TooltipTrigger data-automation="worker-runs-search-box-tooltip-icon">
                    <span className="tnk-icon">
                        <SearchTooltipIcon />
                    </span>
                </TooltipTrigger>
            </Tooltip>
        </Wrapper>
    );
};

export default WorkerRunsSearchBox;
