<div
    ng-switch="fieldDefinition.fieldType"
    class="relative common-width-full update-fields-value"
    ng-class="{ invalid: data.invalid }"
    uib-tooltip="Only item creator can update this field"
    tooltip-enable="!data.isCreatorOnlyValid && isDisabled"
>
    <!--  List input type -->
    <div ng-switch-when="List" class="flex-vmiddle list-input-type">
        <!-- External with list of options -->
        <tnk-select
            ng-if="fieldDefinition.dropdownSource !== 'SEARCH'"
            class="inline-block common-width-100"
            data-automation="update-field-value-drop-down-field-container"
            id="update-input-list"
            is-disabled="isDisabled || data.posting"
            value="data.dropdownNewValues"
            is-multi="fieldDefinition.isMultiValueField"
            is-creatable="fieldDefinition.allowAddDropdownOptions"
            close-menu-on-select="!fieldDefinition.isMultiValueField"
            on-change="(dropdownUpdateOrCreateField)"
            is-searchable="true"
            is-clearable="true"
            styles="data.selectStyles"
            options="data.dropdownPossibleValues"
            forceFindPortalContainer="true"
        ></tnk-select>

        <!-- External with async list of options -->
        <tnk-update-field-async-search-dropdown-selector
                ng-if="fieldDefinition.dropdownSource === 'SEARCH' && !!initiative"
                class="inline-block common-width-100"
                data-automation="update-field-search-value-drop-down-field-container"
                field-definition-id="fieldDefinition.id"
                initiative-id="initiative.id"
                on-change="(dropdownUpdateOrCreateField)"
                value="!data.isMulti ? data.dropdownNewValues : undefined"
                is-multi-value-list="fieldDefinition.isMultiValueField"
                multi-value-list="data.isMulti ? data.dropdownNewValues : undefined"
                read-only="isDisabled || data.posting"
                is-creatable="data.isCreatable"
                close-menu-on-select="!fieldDefinition.isMultiValueField"
                workflow-version-type="data.workflowVersionType"
        ></tnk-update-field-async-search-dropdown-selector>

        <span class="input-group-btn" ng-if="data.isEditing && !data.hideSaveButton && data.inlineUpdate">
            <button
                type="submit"
                class="btn btn-primary"
                ng-disabled="isDisabled || data.posting"
                ng-click="updateOrCreateFieldSave()"
            >
                <i ng-class="data.posting ? 'loading-small mod-white' : 'fa fa-arrow-right'"></i>
            </button>
        </span>

        <span class="margin-left margin-top-xs" ng-if="data.posting">
            <i class="loading-small" style="z-index: 10"></i>
        </span>
    </div>

    <!-- Date input type  -->
    <div ng-switch-when="Date">
        <!-- Date -->
        <tnk-form-date-picker-input
                on-change="updateOrCreateField"
                field-definition-id="fieldDefinition.id"
                selected="data.newValueDate"
                disabled="isDisabled || data.posting"
                display-format="data.displayFormat"
            />
    </div>

    <!-- String/Number input type -->
    <div
        ng-switch-default
        class="other-user-input"
        ng-class="{ 'input-users': fieldDefinition.displayFormat === 'USER' }"
    >
        <form>
            <div ng-class="{ 'input-group': data.isEditing && !data.hideSaveButton }">
                <!-- Users input type -->
                <tnk-people-selector-by-person-identifier
                  ng-if="fieldDefinition.displayFormat === 'USER'"
                  is-disabled="isDisabled || data.posting"
                  include-external="true"
                  is-multi="fieldDefinition.isMultiValueField"
                  selected-emails-or-ids="data.newValue"
                  on-change="(onValueChanged)"
                  ></tnk-people-selector-by-person-identifier>

                <!-- HTML input type -->
                <div
                    ng-if="fieldDefinition.displayFormat === fieldDisplayFormats.html.apiName"
                    text-angular
                    ng-model="data.newValue"
                    ng-change="onValueChanged(data.newValue)"
                    ta-disabled="isDisabled || data.posting"
                ></div>

                <!-- Regular input field -->
                <input
                    class="form-control text-input"
                    data-automation="update-input-manual"
                    id="update-input-manual-{{ fieldDefinition.id }}"
                    ng-change="onValueChanged(data.newValue)"
                    ng-disabled="isDisabled || data.posting"
                    ng-if="
                        fieldDefinition.displayFormat !== 'USER' &&
                        fieldDefinition.displayFormat !== fieldDisplayFormats.html.apiName &&
                        !fieldDefinition.isMultiValueField &&
                        fieldDefinition.fieldType !== 'LongString' &&
                        fieldDefinition.fieldType !== 'Number'
                    "
                    ng-model="data.newValue"
                />

                <!-- Number input field -->
                <input
                    class="form-control text-input"
                    data-automation="update-input-manual"
                    id="update-input-manual-{{ fieldDefinition.id }}"
                    ng-change="onValueChanged(data.newValueNumber)"
                    ng-disabled="isDisabled || data.posting"
                    ng-if="fieldDefinition.fieldType === 'Number'"
                    ng-model="data.newValueNumber"
                    type="number"
                />

                <textarea
                    class="form-control text-input"
                    ng-if="
                        fieldDefinition.displayFormat !== 'USER' &&
                        fieldDefinition.displayFormat !== fieldDisplayFormats.html.apiName &&
                        !fieldDefinition.isMultiValueField &&
                        fieldDefinition.fieldType === 'LongString'
                    "
                    ng-model="data.newValue"
                    ng-disabled="isDisabled || data.posting"
                    data-automation="update-input-manual"
                    id="update-input-manual-{{ fieldDefinition.id }}"
                    ng-change="onValueChanged(data.newValue)"
                ></textarea>

                <tnk-multiple-input
                    ng-if="
                        fieldDefinition.displayFormat !== 'USER' &&
                        fieldDefinition.displayFormat !== fieldDisplayFormats.html.apiName &&
                        fieldDefinition.isMultiValueField
                    "
                    tags="data.newValueTags"
                    on-tags-changed="(onTagsChanged)"
                    disabled="isDisabled || data.posting"
                ></tnk-multiple-input>

                <span class="input-group-btn" ng-if="data.isEditing && !data.hideSaveButton && data.inlineUpdate">
                    <button
                        data-automation="update-field-value-submit-button"
                        type="submit"
                        class="btn btn-primary"
                        ng-disabled="isDisabled || data.posting"
                        ng-click="updateOrCreateFieldSave()"
                    >
                        <i ng-class="data.posting ? 'loading-small mod-white' : 'fa fa-arrow-right'"></i>
                    </button>
                </span>
            </div>
        </form>
    </div>
</div>

<div class="margin-top text-right margin-left-auto" ng-if="data.isOnlyField && !data.inlineUpdate">
    <button class="btn btn-lg btn-primary" ng-click="updateOrCreateFieldSave()">Submit</button>
</div>
