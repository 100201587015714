import WorkerHistoryItem from './WorkerHistoryItem';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkWorkerHistoryItem',
        reactToAngular(
            WorkerHistoryItem,
            ['id', 'uiActions', 'groupId', 'closeSidePane'],
            [
                '$state',
                'workerHistoryItemStore',
                'customTriggerManager',
                'workflowVersionManager',
                '$rootScope',
                'consts',
            ],
        ),
    );
