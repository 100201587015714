<div class="change-groups-workflow-folder">
    <div class="padding-25">
        <div class="move-module-title margin-bottom-md">Move Module</div>

        <div class="margin-bottom-xlg">You are about to move the following Module to a different Solution Group</div>

        <div class="margin-bottom-md current-solution margin-bottom-30">
            <span class="common-color-grey margin-right">Solution:</span>
            <span>
                {{ wfm.projectIdToFolderIdToFolderMap[data.projectId][data.originWorkflowFolderId].displayName }}
            </span>
        </div>

        <div class="tile-width">
            <tnk-worker-tile class="tile-width" worker="data.group" view-only="true"></tnk-worker-tile>
        </div>

        <div class="margin-top-xlg">
            <div class="margin-top margin-bottom-md move-to-title flex-vmiddle">
                <span class="svg-icon-smd">
                    <tnk-icon src="/images/icons/folder.svg"></tnk-icon>
                </span>

                <div class="margin-left margin-top-xxxs">Move to</div>
            </div>
            <div>
                <tnk-workflow-folder-selector
                    is-required="true"
                    hide-options-ids="data.folderIdsToHide"
                    on-folder-selected="onFolderSelected(folder)"
                ></tnk-workflow-folder-selector>
            </div>
        </div>

        <div class="margin-top-xlg" ng-if="data.selectedFolder">
            <div class="margin-top margin-bottom-md move-to-title flex-vmiddle">
                <div class="margin-left margin-top-xxxs">Solution Outline</div>
            </div>
            <div>
                <tnk-workflow-folder-category-selector
                    data-automation="change-groups-workflow-folder-modal-solution-outline-selector"
                    workflow-folder-category-id="data.workflowFolderCategoryId"
                    workflow-folder-id="data.selectedFolder.id"
                    on-category-selected="(onWorkflowFolderCategorySelected)"
                    auto-select-first="true"
                ></tnk-workflow-folder-category-selector>
            </div>
        </div>
    </div>

    <hr class="margin-none" />

    <div
        ng-if="data.selectedFolder && !data.selectedFolder.isUserOwner"
        class="alert alert-warning margin-normal margin-bottom-none"
    >
        You have selected a Solution in which you are not a Maker. This mean you will no longer be able to edit it! Only
        the Makers of
        <strong>{{ data.selectedFolder.displayName }}</strong>
        will be able to edit this Module.
    </div>

    <!-- Save button and error -->
    <div class="flex mod-justify-end padding-25">
        <div ng-if="data.error" class="common-color-danger">{{ data.error | error }}</div>
        <div class="flex mod-justify-end">
            <button ng-click="cancel()" class="btn round-button btn-round-white margin-right">Cancel</button>
            <button
                class="btn round-button btn-blue"
                data-automation="change-group-workflow-folder-modal-save-button"
                ng-click="move()"
                ng-disabled="!data.selectedFolder || !data.workflowFolderCategoryId"
            >
                Move
                <i ng-if="data.loading" class="loading-small"></i>
            </button>
        </div>
    </div>
</div>
