import type CreateAfterOption from '../../../entities/CreateAfterOption';
import logicConfigModes from '../../../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../../../entities/TriggerParamsEnricher';
import addButtonBlockCreateAfterOptionCanCreate from '../canCreate/addButtonBlockCreateAfterOptionCanCreate';

import { CustomTriggerType } from '@tonkean/tonkean-entities';

const botButtonTriggerParamsEnricher: TriggerParamsEnricher = (params) => {
    params.customTriggerType = CustomTriggerType.BOT_BUTTON_PRESSED;
    params.displayName = addButtonBlockCreateAfterOption.blockDisplayName;
    params.selectWithConfigMode = logicConfigModes.configuration;
    params.customTriggerActions = [
        {
            type: CustomTriggerType.BOT_BUTTON_PRESSED,
            customTriggerActionDefinition: {
                replyOnOriginalMessage: true,
            },
        },
    ];
};

const addButtonBlockCreateAfterOption: CreateAfterOption = {
    label: 'Add Button',
    blockDisplayName: 'Module Button',
    triggerParamsEnricher: botButtonTriggerParamsEnricher,
    canCreate: addButtonBlockCreateAfterOptionCanCreate,
};

export default addButtonBlockCreateAfterOption;
