import { analyticsWrapper } from '@tonkean/analytics';

function DeleteGroupModalCtrl(
    $scope,
    $state,
    tonkeanService,
    projectManager,
    groupInfoManager,
    workflowFolderManager,
    group,
) {
    $scope.deleteTypes = {
        archive: 'archive',
        move: 'move',
    };

    $scope.data = {
        group,
        type: $scope.deleteTypes.archive,
    };

    $scope.init = function () {
        $scope.groups = [];
        for (let i = 0; i < projectManager.groups.length; i++) {
            if (projectManager.groups[i].id !== group.id) {
                $scope.groups.push(projectManager.groups[i]);
            }
        }
    };

    $scope.removeList = function () {
        $scope.error = null;
        $scope.loading = true;
        const params = {};

        if ($scope.data.type === $scope.deleteTypes.move) {
            if ($scope.data.selectedGroup) {
                params.moveToGroupId = $scope.data.selectedGroup.id;
            } else {
                $scope.error = 'You must select a list to move the items to.';
                $scope.loading = false;
                return;
            }
        } else {
            params.archiveAll = true;
        }

        analyticsWrapper.track('Delete list', { category: 'Delete List Modal' });

        tonkeanService
            .deleteEntity(group.id, params)
            .then(() => {
                // Remove the folder relation from the cache
                const workflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                    projectManager.project.id,
                    group.id,
                );
                if (workflowFolder) {
                    workflowFolderManager.removeGroupFromWorkflowFolderCache(
                        projectManager.project.id,
                        workflowFolder.id,
                        group.id,
                    );
                }

                // Get the groups again, so we are fresh.
                groupInfoManager.getGroups(true).then(function () {
                    $scope.$emit('alert', {
                        msg: 'Deleted successfully.',
                        type: 'success',
                    });

                    if ($state.current.name.indexOf('product.worker') === 0) {
                        $state.go('product.solution', { solutionId: workflowFolder.id });
                    } else {
                        // If not, go back to board (lists).
                        $state.go(
                            'product.board',
                            {
                                filter: 'all',
                                g: projectManager.groupDefaultId,
                                listFilter: null,
                                isGroupListFilter: null,
                            },
                            { location: 'replace' },
                        );
                    }

                    $scope.$close();
                });
            })
            .catch((error) => {
                $scope.error = error?.data || error;
                $scope.loading = false;
            });
    };

    $scope.init();
}
export default angular.module('tonkean.app').controller('DeleteGroupModalCtrl', DeleteGroupModalCtrl);
