import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import projectIntegrationPaginatedActionConstantParamIdToConfig from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationPaginatedActionConstantParamIdToConfig';

import { H4, Tooltip } from '@tonkean/infrastructure';
import type { CollectParameter } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const CollectParametersHeader = styled(H4)`
    margin-bottom: 12px;
    margin-top: 11px;
`;

const PageParametersHeader = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: ${Theme.colors.gray_500};
    margin-bottom: 7px;
`;

const ParametersBlocksWrapper = styled.div`
    display: flex;
    gap: 10px;
    flex-flow: wrap;

    margin-bottom: 12px;
`;

const ParameterName = styled.div`
    background: ${Theme.colors.gray_300};
    border-radius: 3px;
    padding: 3px 6px;

    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_700};
`;

interface Props {
    name: string;
}

const CollectParametersView: React.FC<Props> = ({ name }) => {
    const [{ value: nextPageParameters }] = useField<CollectParameter[]>(name);

    // All the constant parameters names include the next page params.
    const nextPageParamNames = (nextPageParameters || [])
        .map((singleParam) => singleParam?.parameterName)
        .filter((singleParamName) => singleParamName !== '');
    return (
        <>
            <CollectParametersHeader $bold>Collect Params</CollectParametersHeader>
            <PageParametersHeader>Params With Values In Every Request</PageParametersHeader>
            <ParametersBlocksWrapper>
                {Object.values(projectIntegrationPaginatedActionConstantParamIdToConfig).map((constantParameter) => (
                    <Tooltip content={constantParameter.description} key={constantParameter.id}>
                        <ParameterName>{constantParameter.displayName}</ParameterName>
                    </Tooltip>
                ))}
            </ParametersBlocksWrapper>

            {nextPageParamNames.length !== 0 && (
                <>
                    <PageParametersHeader>Params With Value Only After First Request</PageParametersHeader>

                    <ParametersBlocksWrapper>
                        {nextPageParamNames.map((constantParameterName) => (
                            <ParameterName key={constantParameterName}>{constantParameterName}</ParameterName>
                        ))}
                    </ParametersBlocksWrapper>
                </>
            )}
        </>
    );
};

export default CollectParametersView;
