import React from 'react';
import styled from 'styled-components';

import ItemInterfacePermission from '../../../entities/ItemInterfacePermission';
import { ReactComponent as EmptyIcon } from '../../../icons/interface-embed-missing-configuration.svg';
import ItemInterfaceView from '../../../SystemInterface/components/ItemInterface/ItemInterfaceView';
import { FieldsWidgetDisplayType, FieldsWidgetLoadingSkeleton } from '../../FieldsWidgetModule';

import { NotFoundPage } from '@tonkean/infrastructure';
import { ItemInterfaceLoadingState, type ItemInterfaceWidget, type WorkflowVersion } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    margin: 20px 0 20px 0;
`;

const Title = styled.div`
    font-size: ${FontSize.LARGE_16};
    height: 19px;
    font-weight: 500;
    padding-bottom: 8px;
    color: #5b636c;
    margin-top: 16px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0 8px 0;
    color: ${Theme.colors.gray_500};
`;

interface SolutionSitePageIncludeInterfaceWidgetDisplayProps {
    embedInterfaceWidgets: ItemInterfaceWidget[] | undefined;
    workflowVersion: WorkflowVersion | undefined;
    embedInterfaceIsLoading: boolean;
    embedInterfaceIsError: boolean;
    configurationSet: boolean;
    emptyTitle: string | undefined;
    emptyMessage: string | undefined;
}

const SolutionSitePageIncludeInterfaceWidgetDisplay: React.FC<SolutionSitePageIncludeInterfaceWidgetDisplayProps> = ({
    embedInterfaceWidgets,
    workflowVersion,
    embedInterfaceIsLoading,
    embedInterfaceIsError,
    configurationSet,
    emptyTitle,
    emptyMessage,
}) => {
    if (embedInterfaceIsLoading) {
        return (
            <FieldsWidgetLoadingSkeleton
                fieldDisplay={FieldsWidgetDisplayType.LIST}
                data-automation="solution-site-page-include-interface-widget-display-loading"
                singleField
            />
        );
    }

    if (embedInterfaceIsError) {
        return (
            <React.Suspense>
                <NotFoundPage text="Oops, we can't find that page" dataAutomation="interface-not-found" />
            </React.Suspense>
        );
    }

    if (!configurationSet || !embedInterfaceWidgets || embedInterfaceWidgets.length === 0) {
        return (
            <Wrapper>
                <EmptyIcon />
                <Title data-automation="solution-site-page-include-interface-widget-display-empty-title">
                    {emptyTitle}
                </Title>
                <Content>{emptyMessage}</Content>
            </Wrapper>
        );
    }

    return (
        <ItemInterfaceView
            itemInterfaceWidgetsState={
                embedInterfaceIsLoading ? ItemInterfaceLoadingState.LOADING : ItemInterfaceLoadingState.IDLE
            }
            widgets={embedInterfaceWidgets ?? []}
            permission={ItemInterfacePermission.USER_CANT_EDIT_ITEM}
            showPoweredByTonkean={false}
            initiative={undefined}
            showReturnToHomepageButton={false}
            workflowVersion={workflowVersion}
            showTitle={false}
            cleanMode
            hideLogoInHeader
        />
    );
};

export default SolutionSitePageIncludeInterfaceWidgetDisplay;
