import FunctionOperatorBase from './FunctionOperatorBase';
import { timeZoneField } from '../commonFields';
import { weekdaysPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class NextDayOfWeekFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.NEXT_DAY_OF_WEEK;
    override readonly sign = 'NextDayOfWeek';
    override readonly displayName = 'Next Day of Week';
    override readonly description =
        'Will calculate the next date of requested week day according to given reference date.';
    override readonly dataType = FieldType.Date;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Reference date',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Week day',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: weekdaysPreset,
        },
        {
            displayName: 'Is today count',
            dataType: FieldType.Boolean,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
        },
        timeZoneField(),
    ];
}
