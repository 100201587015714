<div class="tnk-docusign-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            info-message="'Maximum document size is 5MB'"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                New File Name (with extension):
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="File Name"
                saved-expression="data.definition.newNameExpression"
                on-tonkean-expression-changed="onNewNameTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div
        ng-if="
            data.definition.customActionKey === 'MOVE_FOLDER' ||
            data.definition.customActionKey === 'MOVE_FILE' ||
            data.definition.customActionKey === 'COPY_FOLDER' ||
            data.definition.customActionKey === 'COPY_FILE'
        "
        class="margin-bottom-xlg"
    >
        <div class="margin-top">
            <div class="margin-bottom-xs">
                New {{ data.definition.customActionKey.contains('FOLDER') ? 'Folder' : 'File' }} Name
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Name"
                saved-expression="data.definition.newNameExpression"
                on-tonkean-expression-changed="onNewNameTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div
        ng-if="
            data.definition.customActionKey === 'UPLOAD_FILE' ||
            data.definition.customActionKey === 'MOVE_FOLDER' ||
            data.definition.customActionKey === 'MOVE_FILE' ||
            data.definition.customActionKey === 'COPY_FOLDER' ||
            data.definition.customActionKey === 'COPY_FILE'
        "
        class="margin-bottom-xlg"
    >
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Destination Path (Optional):
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Relative to the selected folder in the integration's setup."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Destination Path"
                saved-expression="data.definition.destinationPathExpression"
                on-tonkean-expression-changed="onDestinationPathTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'SHARE_FILE'" class="margin-bottom-xlg">
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Share Type ID (Optional):
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Options: 0 = user; 1 = group; 3 (default) = public; 4 = email"
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Share Type"
                    saved-expression="data.definition.shareTypeExpression"
                    on-tonkean-expression-changed="onShareTypeTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                Permissions ID (Optional):
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Options: 1 = read (default); 2 = update; 4 = create; 8 = delete; 16 = share; 31 = all"
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Permissions"
                    saved-expression="data.definition.sharePermissionsExpression"
                    on-tonkean-expression-changed="onSharePermissionsTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                Expiration Date (Optional):
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Format: yyyy-MM-dd"
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Expiration Date"
                    saved-expression="data.definition.shareExpirationExpression"
                    on-tonkean-expression-changed="onShareExpirationTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
