import template from './tnkTonkeanActionLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkTonkeanActionLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        workflowVersionType: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        previewEvaluationSource: '<',
        onUpdateLogicType: '&',
        onActionsChanged: '&',
        onSelectedLogicTypeConfigurationChanged: '&',
    },
    template,
    controller: 'TonkeanActionLogicConfigurationCtrl',
});
