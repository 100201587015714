import React, { useContext, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { workerRunsFiltersHelper } from './WorkerRunsForm/WorkerRunsFiltersPopover/WorkerRunsFiltersPopover';
import WorkerRunsForm from './WorkerRunsForm/WorkerRunsForm';
import WorkerRunsTable from './WorkerRunsTable/WorkerRunsTable';
import WorkerRunsTableHeader from './WorkerRunsTable/WorkerRunsTableHeader';
import HistoryContext from '../../entities/HistoryContext';
import useWorkerRunsFetchManager from '../../hooks/useWorkerRunsFetchManager';
import useWorkerRunsFilters from '../../hooks/useWorkerRunsFilters';

import { ErrorMessage } from '@tonkean/infrastructure';
import { createWorkerRunInfo } from '@tonkean/infrastructure';
import { WorkerRunStage } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

// TODO remove stats mock and use real information
const statsMock: Record<WorkerRunStage, number> = {
    [WorkerRunStage.DATA_SOURCE]: 51,
    [WorkerRunStage.FLOW_RUN]: 23,
    [WorkerRunStage.MODULE_ITEM]: 51,
};

const Wrapper = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: ${Theme.colors.basicBackground};
    border-right: 1px solid ${Theme.colors.HEX_CACACA};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Sticky = styled.div`
    background: ${Theme.colors.basicBackground};
    position: sticky;
    top: 0;
    // When hovering or focusing, the table item will have a z-index of 1 to show it's focus ring, so to prevent
    // it from being shown over the sticky element, we use z-index of 2.
    z-index: 2;
`;

const Error = styled(ErrorMessage)`
    margin: 0 20px 20px 20px;
`;

interface Props extends StyledComponentsSupportProps {
    groupId: string;

    onLoading(isLoading: boolean): void;
}

const WorkerRuns: React.FC<Props> = ({ className, groupId, onLoading }) => {
    const { state, updateState } = useContext(HistoryContext);
    const { environment, workerRunInfo: selectedWorkerRunInfo } = state;

    const workerRunsWrapperRef = useRef<HTMLDivElement>(null);

    const {
        filters,
        setFilters,
        workerRunDateRangeFilter,
        setWorkerRunDateRangeFilter,
        workerRunStagesFilter,
        setWorkerRunStagesFilter,
        searchTerm,
        setSearchTerm,
        clearFilters,
    } = useWorkerRunsFilters(state, updateState);

    const { workerRuns, showLoadingPlaceholders, manuallyReloading, hasMorePages, error, reload, loadNextPage } =
        useWorkerRunsFetchManager(
            groupId,
            searchTerm,
            filters,
            workerRunStagesFilter,
            workerRunDateRangeFilter,
            environment,
            onLoading,
            state.inspect,
            workerRunsWrapperRef,
        );

    /**
     * There should always be a selected worker run in the right side, so if nothing is selected, it should auto-select
     * the first item in the workerRuns list. It should not be changed based on the filtering. If the first worker run
     * is not in the current group, it means that the data is outdated and should be ignored..
     */
    useEffect(() => {
        const workerRun = workerRuns[0];
        if (!selectedWorkerRunInfo && workerRun?.groupId === groupId) {
            updateState(
                {
                    workerRunInfo: createWorkerRunInfo(workerRun.id, workerRun.startTime),
                    workerRunStage: workerRun.workerRunStage,
                },
                false,
            );
        }
    }, [selectedWorkerRunInfo, updateState, workerRuns, groupId]);

    const filtersChangeCount = useMemo(() => {
        return workerRunsFiltersHelper.changesCount(filters);
    }, [filters]);

    const isInError = !!error;
    const isSmallError = !!workerRuns?.length && isInError;
    const isNetworkError = error?.status === -1;

    return (
        <Wrapper className={className} ref={workerRunsWrapperRef}>
            <Sticky>
                <WorkerRunsForm
                    stats={statsMock}
                    filters={filters}
                    workerRunStagesFilter={workerRunStagesFilter}
                    workerRunDateRangeFilter={workerRunDateRangeFilter}
                    searchTerm={searchTerm}
                    onFiltersChange={setFilters}
                    onWorkerRunStagesFilterChange={setWorkerRunStagesFilter}
                    onWorkerRunDateRangeFilterChange={setWorkerRunDateRangeFilter}
                    onSearchTermChange={setSearchTerm}
                    reload={reload}
                    reloading={manuallyReloading}
                    filtersChangeCount={filtersChangeCount}
                />
                {isSmallError && (
                    <Error>Error updating history{isNetworkError && ', please check network connection'}</Error>
                )}
                <WorkerRunsTableHeader />
            </Sticky>

            <WorkerRunsTable
                workerRuns={workerRuns}
                loading={showLoadingPlaceholders}
                hasMore={hasMorePages}
                loadMore={loadNextPage}
                reload={reload}
                error={error}
                isFiltering={!!filtersChangeCount || !!searchTerm}
                clearFilters={clearFilters}
            />
        </Wrapper>
    );
};

export default WorkerRuns;
