export enum CloudConvertAccountType {
    SANDBOX = 'api.sandbox',
    PRODUCTION = 'api',
}

export const accountTypeToDisplayName: Record<CloudConvertAccountType, string> = {
    [CloudConvertAccountType.SANDBOX]: 'Sandbox',
    [CloudConvertAccountType.PRODUCTION]: 'Production',
};
export default CloudConvertAccountType;
