import useTonkeanQuery from '../utils/reactQuery/useTonkeanQuery';

import { matchConditions } from '@tonkean/network-service';
import type {
    MatchConditionExpression,
    MatchConditionResult,
    TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

interface UseTDLMatchConditionsProps {
    fieldValues: Record<TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}`, string>;
    matchInput: MatchConditionExpression[];
    workflowVersionType: WorkflowVersionType;
}

const useTDLMatchConditions = ({ fieldValues, matchInput, workflowVersionType }: UseTDLMatchConditionsProps) => {
    return useTonkeanQuery<Record<string, MatchConditionResult>>({
        queryUniqueKey: 'matchConditions',
        propsUniqueKeysArray: [fieldValues, matchInput, workflowVersionType],
        queryFn: async (networkService) => {
            return matchConditions(networkService, fieldValues, matchInput, workflowVersionType);
        },
        enabledFn: () => matchInput.length > 0,
        resetDataWhenKeyChanges: false,
    });
};

export default useTDLMatchConditions;
export type { UseTDLMatchConditionsProps };
