import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

/**
 * RegexFindAll is deprecated in favor of RegexFind. Use RegexFind with the fourth operand as 0.
 * @deprecated
 */
export default class RegexFindAllFunction extends FunctionOperatorBase {
    override readonly deprecated = true;
    override readonly key = OperatorKey.REGEX_FIND_ALL;
    override readonly sign = 'RegexFindAll';
    override readonly displayName = 'Regex Find All';
    override readonly description =
        'RegexFindAll is deprecated in favor of RegexFind. Use RegexFind with the fourth operand as 0.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input String',
            explanation: 'The string against which to match the regular expression',
            dataType: undefined,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Regular expression',
            explanation: 'Pattern to match by',
            dataType: undefined,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Requested Groups',
            explanation: 'For multiple groups, separate by commas. 0 means full match',
            dataType: undefined,
            defaultValue: 0,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
