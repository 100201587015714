import WorkerGallerySolutionOptions from './WorkerGallerySolutionOptions';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkWorkerGallerySolutionOptions',
        reactToAngular(WorkerGallerySolutionOptions, [
            'workflowFolder',
            'canPublishSolution',
            'onSolutionPublished',
            'deleteWorkflowFolder',
            'openWorkflowFolderVersionsSidePane',
        ]),
    );
