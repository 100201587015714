import { lateConstructController } from '@tonkean/angular-components';
import { RunOnTypes } from '@tonkean/logic-block-configs';
import { GetItemDetailsForItemDetailsModuleEditorByTargetIdDocument } from '../../../modules/ItemDetailsModule/components/ItemDetailsModuleEditor/getItemDetailsForItemDetailsModuleEditor.graphql';

/* @ngInject */
function WorkerLogicCtrl(
    $scope,
    $timeout,
    $localStorage,
    $rootScope,
    $state,
    $window,
    consts,
    modalUtils,
    projectManager,
    customTriggerManager,
    utils,
    workflowVersionManager,
    formManager,
    projectIntegrationCache,
) {
    const ctrl = this;
    const logicConfigModes = consts.getLogicConfigModes();
    const innerItemLogicBlockConfigs = consts.getInnerItemLogicBlockTypes();

    /** @type {import("@urql/core").Client} */
    const urql = $rootScope['urql'];

    $scope.logicBlockTypes = consts.getLogicBlockTypes();
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    // Autonomous triggers have the same type 'AUTONOMOUS', thus we want to map them by their secondary type.
    // Mapping between the autonomousSecondaryType key to the autonomous definition.
    $scope.autonomousSecondaryTypeToDefinitionMap = utils.createMapFromArray(
        utils.findAllInObj($scope.logicBlockTypes, (blockType) => blockType.type === 'AUTONOMOUS'),
        'secondaryType',
    );

    $scope.data = {
        // Component properties.
        syncObject: ctrl.syncObject,
        configuredLogic: null, // The configuredLogic is loaded with a $timeout delay. See $onInit.
        invalidLogics: ctrl.invalidLogics,
        groupId: ctrl.groupId,
        workflowVersion: null,
        workflowVersionId: ctrl.workflowVersionId,
        isParentDisabled: ctrl.isParentDisabled,
        canModifyBot: ctrl.canModifyBot,

        // Inner properties.
        displayEditLogicName: false,
        editedLogicName: null,
        isCollapsed: false,
        collapsedChildrenHaveError: false,
        createOptionsMenuIsOpen: false,
        addModuleLinkButton: false,
        showWorkerLogicItemDetails: false,
        features: projectManager.project.features,
        directParent: null,
        showSequenceMenu: false,
        createOptionsMenuItems: [],
        createAfterOptionsTitle: '',
        uniqueIconsForWhenFormAnswered: '',
        isContinueSequenceTrigger: false,
    };

    ctrl.$onInit = function () {
        // We only load the configuredLogic in a $timeout and inside the init, so he will postpone loading its recursive
        // impacts to the next digest loop. This way we do not overload the digest loop with to many iteration
        // (until it ultimately gives up after 10 loops).
        $timeout(() => {
            $scope.data.configuredLogic = ctrl.configuredLogic;
            setLogicProjectIntegration();
            calculateCollapsedChildren();
            calcUniqueIconsForWhenFormAnswered();
        });

        if ($localStorage.collapsedLogics && $localStorage.collapsedLogics[ctrl.configuredLogic.node.id]) {
            $scope.data.isCollapsed = true;
        }

        if (
            $scope.data.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.actionDefinition?.groupId
        ) {
            $scope.data.addModuleLinkButton = true;
        }

        updateWorkflowVersion();
        $scope.data.directParent = customTriggerManager.getDirectParentInWorkflowVersion(
            $scope.data.workflowVersionId,
            $scope.data.configuredLogic?.node?.id,
        );

        const autonomousTriggerMonitoringFormOrInterface = $scope.data.configuredLogic?.node?.customTriggerType === 'AUTONOMOUS' && 
        ($scope.data.configuredLogic?.node?.customTriggerSecondaryType === 'AUTONOMOUS_CREATED_FROM_FORM'|| $scope.data.configuredLogic?.node?.customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED')

        const triggerInTheMiddleOfSequence =  $scope.data.configuredLogic?.node?.customTriggerType === 'SEND_FORM_ANSWERED'

        if(autonomousTriggerMonitoringFormOrInterface || triggerInTheMiddleOfSequence){
            $scope.data.showSequenceMenu = true;
        }
    };

    $scope.getWorkingOnContext = function () {
        return (
            customTriggerManager.getWorkflowVersionFirstMonitorParentData(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node,
            )?.monitorActionDefinition?.workerItemContextType ??
            $scope.data.configuredLogic.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.workerItemContextType
        );
    };

    function createIntakeLink(customTrigger){
        const itemInterfaceId = customTrigger?.customTriggerActions?.[0]?.customTriggerActionDefinition?.itemInterfaceId
        if(itemInterfaceId){
            return new URL(
                $state.href('product.intakeInterface', {
                    projectId: projectManager.project.id,
                    customTriggerId: customTrigger?.id,
                    workflowVersionType: $scope.wvm.isDraftVersion($scope.data.workflowVersionId) ? 'DRAFT' : 'PUBLISHED',
                }),
                window.location.origin,
            ).href
        }
    }

    $scope.goToIntakePage = function (customTrigger) {
        var url = createIntakeLink(customTrigger);

        if(url){
            $window.open(url, '_blank');
        }
    }

    $scope.calculateLogicCantAddChildren = function () {
        const customTriggerType = $scope.data.configuredLogic?.node?.customTriggerType;

        // If this logic has create after specific options we want to open a dropdown on plus button click
        if (customTriggerType) {
            const cantAddChildren = $scope.logicBlockTypes?.[customTriggerType]?.cantAddChildren;
            if (cantAddChildren) {
                const workingOn = $scope.getWorkingOnContext();
                return cantAddChildren($scope.data.configuredLogic.node, projectManager.project.features, workingOn);
            }
        }
        return false;
    };

    $scope.calculateCantAddChildren = function () {
        return (
            $scope.data.isDragging ||
            $scope.data.isCollapsed ||
            $scope.calculateLogicCantAddChildren() ||
            ($scope.data.configuredLogic?.node?.cantAddChildren && $scope.data.configuredLogic?.impacts?.length == 0)
        );
    };

    function getItemDetailsByTargetId(createAfterOption) {
        const queryVariablesInnerItem = {
            targetId:
                createAfterOption.runOnType === RunOnTypes.NEW_REQUEST
                    ? $scope.data.groupId
                    : $scope.data.configuredLogic?.node?.id,
            workflowVersionId: $scope.data.workflowVersionId,
        };

        return urql
            .query(GetItemDetailsForItemDetailsModuleEditorByTargetIdDocument, queryVariablesInnerItem)
            .toPromise();
    }

    function getItemDetailsByTargetId(createAfterOption) {
        const queryVariablesInnerItem = {
            targetId:
                createAfterOption.runOnType === RunOnTypes.NEW_REQUEST
                    ? $scope.data.groupId
                    : $scope.data.configuredLogic?.node?.id,
            workflowVersionId: $scope.data.workflowVersionId,
        };

        return urql
            .query(GetItemDetailsForItemDetailsModuleEditorByTargetIdDocument, queryVariablesInnerItem)
            .toPromise();
    }

    function calcUniqueIconsForWhenFormAnswered() {
        if($scope.data.configuredLogic.node.customTriggerType === 'SEND_FORM_ANSWERED'){
                const buttonType = $scope.data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.selectedButtonType 
            || $scope.data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.selectedActionType
            || 'continue-flow';
            $scope.data.uniqueIconsForWhenFormAnswered = 'mod-send-form-answered-' + buttonType;
            $scope.data.isContinueSequenceTrigger = ['open-custom-interface-in-sequence', 'open-form'].includes(buttonType);
        }
    }

    async function enrichPlusButtonOptionLabel(option) {
        return getItemDetailsByTargetId(option).then((data) => {
            const fallbackLabel =
                option.runOnType === RunOnTypes.NEW_REQUEST
                    ? 'Request'
                    : $scope.logicBlockTypes[$scope.data.configuredLogic?.node?.customTriggerType]
                          ?.innerItemDetailsTitlePlaceholder ?? 'Inner Item';

            const itemDetailsName = data?.data?.itemDetailsByTargetIdAndWorkflowVersionId?.name;
            const hasName = !utils.isNullOrEmpty(itemDetailsName);

            const enrichedOption = {
                ...option,
                label: hasName ? itemDetailsName : fallbackLabel,
            };
            $scope.data.calculatedPlusButtonDropdownOptions.push(enrichedOption);
            return enrichedOption;
        });
    }

    async function calculatePlusButtonDropdownOptions(createAfterOptions) {
        // If this logic has create after specific options we want to open a dropdown on plus button click
        if (createAfterOptions.length > 0) {
            const filteredOptions = createAfterOptions.filter((option) => $scope.createAfterOptionFilter(option));

            if (
                projectManager.project.features.tonkean_show_inner_item_idd &&
                innerItemLogicBlockConfigs.includes($scope.data.configuredLogic.node.customTriggerType)
            ) {
                // if this custom trigger type included in the innerItemLogicBlockConfigs -> enrich labels for options
                $scope.data.calculatedPlusButtonDropdownOptions = [];
                return filteredOptions?.map((option) => {
                    if (!!option.runOnType) {
                        return enrichPlusButtonOptionLabel(option);
                    } else {
                        $scope.data.calculatedPlusButtonDropdownOptions.push(option);
                        return option;
                    }
                });
            } else {
                $scope.data.calculatedPlusButtonDropdownOptions = filteredOptions;
                return filteredOptions;
            }
        }
        $scope.data.calculatedPlusButtonDropdownOptions = createAfterOptions;
        return createAfterOptions;
    }

    ctrl.$onChanges = function (changes) {
        if (changes.invalidLogics && changes.invalidLogics.currentValue !== $scope.data.invalidLogics) {
            // This fixed the digest loop from exploding because more than 10 $onChanges are fired.
            $timeout(() => {
                $scope.data.invalidLogics = changes.invalidLogics.currentValue;
                $scope.checkIfCollapsedChildrenHaveError();
            });
        }

        if (changes.configuredLogic) {
            $scope.data.configuredLogic = changes.configuredLogic.currentValue;
        }

        if (changes.isParentDisabled) {
            $scope.data.isParentDisabled = ctrl.isParentDisabled;
        }

        if (changes.canModifyBot) {
            $scope.data.canModifyBot = ctrl.canModifyBot;
        }

        if (changes.workflowVersionId) {
            $scope.data.workflowVersionId = changes.workflowVersionId.currentValue;
            updateWorkflowVersion();
        }
    };

    function updateAddModuleLinkButton() {
        if (
            $scope.data.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.actionDefinition?.groupId
        ) {
            $scope.data.addModuleLinkButton = true;
        } else {
            $scope.data.addModuleLinkButton = false;
        }
    }

    $scope.$watch(
        'data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.actionDefinition.groupId',
        updateAddModuleLinkButton,
    );

    // Watching this value so we can change the icon when the type of logic changes
    $scope.$watch(
        'data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.projectIntegrationId',
        setLogicProjectIntegration,
    );

    $scope.$watch(
        'data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.selectedButtonType',
        calcUniqueIconsForWhenFormAnswered,
    );
    $scope.$watch(
        'data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.selectedActionType',
        calcUniqueIconsForWhenFormAnswered,
    );

    function setLogicProjectIntegration() {
        if (!$scope.data.configuredLogic) {
            return;
        }
        const projectIntegrationId =
            $scope.data.configuredLogic.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.projectIntegrationId;

        if (projectIntegrationId) {
            $scope.data.logicProjectIntegration = projectIntegrationCache.getFromCache(projectIntegrationId);
        }
    }

    $scope.$watch('data.configuredLogic.node.customTriggerType', setWorkerLogicItemDetails);

    function setWorkerLogicItemDetails() {
        if (
            projectManager.project.features.tonkean_show_inner_item_idd &&
            innerItemLogicBlockConfigs.includes($scope.data.configuredLogic?.node?.customTriggerType)
        ) {
            $scope.data.showWorkerLogicItemDetails = true;
        } else {
            $scope.data.showWorkerLogicItemDetails = false;
        }
    }

    $scope.createAfterOptionFilter = function (option) {
        return (
            !option.canCreate ||
            option.canCreate(
                $scope.data.configuredLogic.impacts,
                $scope.data.configuredLogic,
                formManager,
                customTriggerManager,
                projectManager.project.features,
            )
        );
    };

    $scope.onCreateNewLogic = function (params) {
        if (ctrl.createNewLogicCallback) {
            return ctrl.createNewLogicCallback({
                params,
            });
        }
    };

    $scope.createNewLogic = function (node, enricher) {
        $scope.data.createOptionsMenuIsOpen = false;

        const params = {
            parentNode: node,
            enricher,
        };

        $scope.data.loadingCreateNewLogic = true;
        return $scope.onCreateNewLogic(params).finally(() => ($scope.data.loadingCreateNewLogic = false));
    };

    $scope.getAfterOptionTargetId = function (createAfterOption) {
        if (createAfterOption.runOnType === RunOnTypes.NEW_REQUEST) {
            return $scope.data.groupId;
        } else {
            return $scope.data.configuredLogic.node.id;
        }
    };

    $scope.getIconType = function (option) {
        return option.iconType;
    };

    $scope.selectCustomTriggerTarget = function (innerLogicNode) {
        const innerLogicType = innerLogicNode.customTriggerType;
        if (innerLogicType === 'INNER_AUTONOMOUS') {
            return innerLogicNode.id;
        } else {
            return $scope.data.configuredLogic.node.id;
        }
    };

    $scope.clickOnPlusButton = function () {
        if (!$scope.data.canModifyBot()) {
            return;
        }
        const workingOn = $scope.getWorkingOnContext();
        // if there are more than 1 child creation options that can create -> open dropdown
        let triggerType = $scope.data.configuredLogic.node.customTriggerType;
        if(triggerType === 'AUTONOMOUS' && $scope.data.configuredLogic.node.customTriggerSecondaryType){
            triggerType = $scope.data.configuredLogic.node.customTriggerSecondaryType;
        }

        const createAfterOptions =
            $scope.logicBlockTypes[triggerType].createAfterOptions?.(
                projectManager.project.features,
                workingOn,
                $scope.data.directParent?.node?.customTriggerType,
            ) || [];

        calculatePlusButtonDropdownOptions(createAfterOptions).then((options) => {
            const filteredCreateAfterOptions = options;
            if (filteredCreateAfterOptions && filteredCreateAfterOptions.length > 1) {
                $scope.data.createOptionsMenuItems = filteredCreateAfterOptions;
                $scope.data.createAfterOptionsTitle = $scope.logicBlockTypes[triggerType].createAfterOptionsTitle
                return;
            }

            const enricher =
                filteredCreateAfterOptions && filteredCreateAfterOptions.length === 1
                    ? filteredCreateAfterOptions[0].triggerParamsEnricher
                    : null;

            if (
                createAfterOptions &&
                createAfterOptions.length &&
                (!filteredCreateAfterOptions || !filteredCreateAfterOptions.length)
            ) {
                $rootScope.$emit('alert', {
                    msg: 'No new logics can be created after this logic.',
                    type: 'warning',
                });
            } else {
                $scope.createNewLogic($scope.data.configuredLogic, enricher);
            }
        });
    };

    $scope.openModuleLink = function () {
        const groupId =
            $scope.data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.actionDefinition
                .groupId;

        const url = $state.href('product.workerEditor', { g: groupId });
        $window.open(url, '_blank');
    };

    $scope.selectLogic = function (logic, logicConfigMode, event) {
        if (event) {
            event.stopPropagation();
        }
        if (logic && logic.node && logic.node.id) {
            $scope.pm.currentlyViewedSimplifiedCustomTrigger = { id: logic.node.id, title: logic.node.displayName };
        }

        if (ctrl.selectLogicCallback) {
            ctrl.selectLogicCallback({
                logic,
                logicConfigMode,
            });
        }
    };

    $scope.selectOutlineTab = function (tabKey, additionalParams) {
        if (ctrl.selectOutlineTabCallback) {
            ctrl.selectOutlineTabCallback({ tabKey, additionalParams });
        }
    };

    $scope.selectLogicBox = function () {
        let type = logicConfigModes.selectType;
        if ($scope.data.configuredLogic.node.customTriggerType !== 'UNKNOWN') {
            type = logicConfigModes.configuration;
        }
        $scope.selectLogic($scope.data.configuredLogic, type);
    };

    $scope.closeSidePane = function () {
        if (ctrl.closeSidePaneCallback) {
            ctrl.closeSidePaneCallback();
        }
    };

    /**
     * Toggles the edit flag of the display name of the custom trigger.
     */
    $scope.toggleEditLogicDisplayName = function (previousName) {
        if ($scope.data.canModifyBot()) {
            $scope.data.displayEditLogicName = true;
            $scope.data.editedLogicName = previousName;

            $timeout(() => {
                utils.focus('worker-editor-logic-name-input');
            });
        }
    };

    /**
     * Handles key down of the logic display name input.
     */
    $scope.onLogicNameKeyDown = function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            // Handle enter.
            e.preventDefault();
            $scope.updateLogicDisplayName($scope.data.editedLogicName);
        }
    };

    /**
     * Updates the display name of the custom trigger.
     */
    $scope.updateLogicDisplayName = function (newDisplayName) {
        $scope.data.displayEditLogicName = false;

        // If the name didn't change, we don't call the server with an update.
        if ($scope.data.configuredLogic.node.displayName === newDisplayName) {
            return;
        }

        $scope.data.loadingSavingLogicDisplayName = true;
        $scope.data.configuredLogic.node.manuallyChangedName = true;

        return customTriggerManager
            .updateCustomTriggerDisplayName($scope.data.groupId, $scope.data.configuredLogic.node.id, newDisplayName)
            .catch(() => {
                $scope.$emit('alert', 'There was an error trying to save the display name.');
            })
            .finally(() => {
                $scope.data.loadingSavingLogicDisplayName = false;
            });
    };

    // region DnD Functions **************************************

    $scope.onDrop = function (logicId, index, targetLogicId) {
        // Make sure we have a logic id to begin with, and that we're not dropping it into itself by accident (can happen).
        if (!logicId || logicId === targetLogicId) {
            return;
        }

        // Make sure we have a valid index.
        index = index || 0;

        // Try to find the dropped logic and the target logic. Continue if we do.
        const droppedLogicData = customTriggerManager.findLogicDataInGraphById($scope.data.workflowVersionId, logicId);
        const targetLogicData = customTriggerManager.findLogicDataInGraphById(
            $scope.data.workflowVersionId,
            targetLogicId,
        );

        // Making sure the logic is dropped under a valid parent
        if (!validateParentsAndChildrenConstraints(droppedLogicData, targetLogicData)) {
            return;
        }

        if (droppedLogicData && targetLogicData) {
            // Make sure the drop target has an impacts array.
            if (!targetLogicData.logic.impacts) {
                targetLogicData.logic.impacts = [];
                // No impacts array - the index must be 0.
                index = 0;
            }

            // sort the new parent's impacts array, to make sure it's in the same order the user sees on screen.
            // If we don't do that, we will splice in a wrong place.
            targetLogicData.logic.impacts.sort((a, b) => a - b);

            // If this is a reorder (moving to the current parent), the dnd infra will also count the moved item for the
            // given index. So we should check if the moved item is before the given index, and if so - decrease by 1.
            if (droppedLogicData.parent.node.id === targetLogicData.logic.node.id) {
                for (let i = 0; i < targetLogicData.logic.impacts.length; i++) {
                    if (i >= index) {
                        break;
                    }
                    if (targetLogicData.logic.impacts[i].node.id === logicId) {
                        // We found the moved logic is before the desired index.
                        index -= 1;
                        break;
                    }
                }
            }

            // Remove from old parent.
            droppedLogicData.parent.impacts.splice(droppedLogicData.indexInParent, 1);

            // Add to the new parent. Do the move.
            targetLogicData.logic.impacts.splice(index, 0, droppedLogicData.logic);

            // Reindex all the children in the new parent, now that we have a new item (we order in the view by the index property).
            for (let i = 0; i < targetLogicData.logic.impacts.length; i++) {
                targetLogicData.logic.impacts[i].node.index = i;
            }

            const belowCustomTrigger = targetLogicData.logic.impacts.find((logic) => logic.node.index === index - 1);

            // Update the server.
            customTriggerManager
                .moveCustomTrigger($scope.data.groupId, targetLogicId, logicId, belowCustomTrigger?.node?.id)
                .catch((error) => {
                    if (error.status === 409) {
                        // On conflict
                        modalUtils.openRefreshModuleModal();
                    }
                });
        }
    };

    function updateWorkflowVersion() {
        $scope.data.workflowVersion = workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId);
    }

    /**
     * Checking if the dropped logic is dropped under an allowed parent logic type and vice versa (if the parent logic type allow's the dropped logic type).
     */
    function validateParentsAndChildrenConstraints(droppedLogicData, targetLogicData) {
        const droppedTriggerTypeDefinition = $scope.logicBlockTypes[droppedLogicData.logic.node.customTriggerType];
        let targetTriggerTypeDefinition = $scope.logicBlockTypes[targetLogicData.logic.node.customTriggerType];

        // Checking whether the target trigger type is 'AUTONOMOUS' type and it has customTriggerSecondaryType
        if (
            targetTriggerTypeDefinition.type === 'AUTONOMOUS' &&
            targetLogicData.logic.node.customTriggerSecondaryType
        ) {
            targetTriggerTypeDefinition =
                $scope.autonomousSecondaryTypeToDefinitionMap[targetLogicData.logic.node.customTriggerSecondaryType];
        }

        const droppedTriggerName = droppedTriggerTypeDefinition.title;

        const targetTriggerName = targetTriggerTypeDefinition.title
            ? targetTriggerTypeDefinition.title
            : 'empty action block';

        const targetAllowedChildrenTypes = targetTriggerTypeDefinition.allowedChildrenTypes?.(
            targetLogicData.logic.node,
        );
        const childAllowedParentTypes = droppedTriggerTypeDefinition.allowedParentTypes;

        // Checking if the dropped logic is dropped under an allowed parent logic typ
        if (childAllowedParentTypes) {
            const isParentAllowed = utils.anyMatch(
                childAllowedParentTypes,
                (type) => type === targetTriggerTypeDefinition.type,
            );

            if (!isParentAllowed) {
                $rootScope.$emit('alert', {
                    msg: `${droppedTriggerName} is not allowed to drop under ${targetTriggerName}`,
                    type: 'error',
                });
                return false;
            }
        }

        // Checking if the dropped logic parent type allows the dropped logic type.
        if (targetAllowedChildrenTypes) {
            const isChildAllowed = utils.anyMatch(
                targetAllowedChildrenTypes,
                (type) => type === droppedTriggerTypeDefinition.type,
            );

            if (!isChildAllowed) {
                $rootScope.$emit('alert', {
                    msg: `${droppedTriggerName} is not allowed to drop under ${targetTriggerName}`,
                    type: 'error',
                });
                return false;
            }
        }

        // Checking if the dropped logic has a parent (Not just the direct parent) which not satisfy the predicate (If exist)
        if (droppedTriggerTypeDefinition.parentValidationOnCreation) {
            let directParentInWorkflowVersion = targetLogicData.logic;
            while (directParentInWorkflowVersion) {
                if (
                    !droppedTriggerTypeDefinition.parentValidationOnCreation(
                        directParentInWorkflowVersion.node,
                        $rootScope.$emit,
                    )
                ) {
                    return false;
                }
                directParentInWorkflowVersion = customTriggerManager.getDirectParentInWorkflowVersion(
                    $scope.data.workflowVersionId,
                    directParentInWorkflowVersion.node.id,
                );
            }
        }

        // Checking if the direct parent can have the dropped trigger as a child
        if (
            droppedTriggerTypeDefinition.allowedDirectParentPredicate
        ) {
            const alertObject = droppedTriggerTypeDefinition.allowedDirectParentPredicate(targetLogicData.logic.node, customTriggerManager, droppedLogicData.logic.node)
            if(!!alertObject){
                $rootScope.$emit(alertObject.type,
                {
                    msg: alertObject.message,
                    type: alertObject.messageType
                });
                return false;
            }
        }

        return true;
    }

    $scope.getDndMarker = function () {
        return (
            `${
                '<div class="tnkDndMarker flex relative worker-editor-logic-box">' +
                '<div class="worker-editor-inner-hline mod-arrow"></div>' +
                '<div>' +
                '<span class="worker-editor-logic-name">'
            }${$scope.data.configuredLogic.node.displayName}</span>` +
            `<div class="worker-editor-logic-container mod-drag-marker flex-vmiddle mod-justify-center">` +
            `</div>` +
            `</div>` +
            `<div class="worker-editor-inner-hline mod-static"></div>` +
            `<span class="worker-editor-add-logic-container">` +
            `<span class="worker-editor-add-logic mod-color">` +
            `<span>+</span>` +
            `</span>` +
            `</span>` +
            `</div>`
        );
    };

    $scope.getDndPlaceholder = function () {
        return '<div class="worker-editor-logic-container mod-drag-placeholder"></div>';
    };

    // endregion

    $scope.toggleLogicCollapsed = function () {
        $scope.data.isCollapsed = !$scope.data.isCollapsed;

        // If collapsing
        if ($scope.data.isCollapsed) {
            if (!$localStorage.collapsedLogics) {
                $localStorage.collapsedLogics = {};
            }

            $localStorage.collapsedLogics[$scope.data.configuredLogic.node.id] = true;
            calculateCollapsedChildren();
        } else if ($localStorage.collapsedLogics) {
            delete $localStorage.collapsedLogics[$scope.data.configuredLogic.node.id];
        }

        $scope.checkIfCollapsedChildrenHaveError();
    };

    $scope.checkIfCollapsedChildrenHaveError = function () {
        if ($scope.data.isCollapsed) {
            $scope.data.collapsedChildrenHaveError = customTriggerManager.anyLogicChildren(
                $scope.data.configuredLogic,
                (logic) => $scope.data.invalidLogics[logic.node.id],
            );
        }
    };

    function calculateCollapsedChildren() {
        let count = 0;
        customTriggerManager.anyLogicChildren($scope.data.configuredLogic, (logic) => {
            if (!logic?.node?.isHidden) {
                count += 1;
            }

            return false;
        });

        $scope.data.collapsedChildrenCount = count;
    }
}

export default angular.module('tonkean.app').controller('WorkerLogicCtrl', lateConstructController(WorkerLogicCtrl));
