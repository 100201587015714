import template from './tnkAvatar.template.html?angularjs';
/**
 * Created by ShaLi on 5/13/15.
 */

function tnkAvatar() {
    return {
        restrict: 'E',
        scope: {
            user: '=?',
            square: '=',
            noclass: '=',
            modSecondary: '=',
            mod: '=',
            defaultOnEmpty: '=',
            defaultUserExtraClass: '@',
            size: '<',
            hideDefaultAvatarPhoto: '<',
            opacity: '<',
        },
        replace: true,
        template,
        controller: 'AvatarCtrl',
        link(scope, element) {
            element.addClass(scope.square ? 'img-rounded' : 'img-circle');
        },
    };
}
angular.module('tonkean.app').directive('tnkAvatar', tnkAvatar);
