import lateConstructController from '../../utils/lateConstructController';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';

/* @ngInject */
function FieldInspectCtrl(
    $scope,
    $q,
    $timeout,
    tonkeanService,
    projectManager,
    customTriggerManager,
    utils,
    apiConsts,
    requestThrottler,
    trackHelper,
    workflowVersionManager,
    groupInfoManager,
) {
    const ctrl = this;

    $scope.data = {
        // Component Bindings.
        projectIntegration: ctrl.projectIntegration,
        externalType: ctrl.externalType,
        onFieldSelect: ctrl.onFieldSelect,
        staticData: ctrl.staticData,
        exampleEntity: ctrl.exampleEntity,
        viewOnly: ctrl.viewOnly,
        useTracksOfGroupId: ctrl.useTracksOfGroupId,
        workflowVersionId: ctrl.workflowVersionId,
        onlyItemsFromSelectedEnvironment: ctrl.onlyItemsFromSelectedEnvironment,
        shouldHaveEntityPrefix: ctrl.shouldHaveEntityPrefix || false,
        creatingCustomTriggerId: ctrl.creatingCustomTriggerId,
        isForMatchedItem: ctrl.isForMatchedItem,
        externalTypeDisplayName: ctrl.externalTypeDisplayName,

        multiSelect: ctrl.multiSelect,
        multiSelectMap: {},

        // Inner attributes.
        fieldNameToDisplayNameMap: {},
        matchedEntitiesFields: [],
        globalFields: [],
        aggregationFields: [],
        entityQuery: null,
        fieldQuery: angular.copy(ctrl.fieldQuery),
        exampleEntities: [],
        selectedExampleEntity: null,
        entityFields: [],
        enrichedOriginalEntity: {},
        targetGroup: null, // the target group for matched item fields

        // Loading state.
        loadingInitialData: false,
        loadingGroup: false,
        loadingExampleEntities: false,

        // Toggles.
        changeEntityToggle: false,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        // If we have static data, we don't want to enter loading mode at all.
        $scope.data.loadingInitialData = !ctrl.staticData;
        // $onChanges is in charge for marking this flag as false (when not in staticData mode) since he will eventually do the needed API call.
        // This was done because $onChanges pops for the very first initialization of "externalType".

        $scope.data.specialFields = getSpecialFieldsForFeatures(false, ['MONITOR_DESCRIPTION']);

        if ($scope.data.isForMatchedItem && $scope.data.creatingCustomTriggerId) {
            $scope.data.loadingGroup = true;

            // the field is a matched item that was created by a custom trigger
            const creatingCustomTrigger = customTriggerManager.getCachedCustomTrigger(
                $scope.data.workflowVersionId,
                $scope.data.creatingCustomTriggerId,
            );

            if (creatingCustomTrigger.customTriggerType === 'TONKEAN_ACTION') {
                const targetGroupId =
                    creatingCustomTrigger.customTriggerActions[0].customTriggerActionDefinition.actionDefinition
                        .groupId;
                groupInfoManager
                    .getGroupById(targetGroupId)
                    .then((group) => {
                        $scope.data.targetGroup = group;
                    })
                    .finally(() => {
                        $scope.data.loadingGroup = false;
                    });
            }
        }
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object of the form.
     */
    ctrl.$onChanges = function (changes) {
        if (!ctrl.staticData && changes.externalType && changes.externalType.currentValue) {
            // Update the requested external type.
            $scope.data.externalType = ctrl.externalType;

            if ($scope.data.projectIntegration) {
                // Setting external type to its underlying name if it's a special field.
                const specialExternalType = apiConsts.getConfigurationByIntegrationAndEntity(
                    $scope.data.projectIntegration.integration.integrationType,
                    $scope.data.externalType,
                );
                if (specialExternalType && specialExternalType.isSpecialField) {
                    $scope.data.externalType = specialExternalType.underlyingPluralName;
                }
            }

            // Reload the example entities (now that the external type has changed).
            // If we are initializing (which means running this call) we also set the $scope.data.loadingInitialData flag to false when we're done.
            loadExampleEntities($scope.data.entityQuery)
                .then(async (exampleEntities) => {
                    // Save the example entities to the scope.
                    $scope.data.exampleEntities = exampleEntities;

                    await setExampleEntityIfInsideWorkflowFromCache();

                    if (!$scope.data.exampleEntity) {
                        if (exampleEntities && exampleEntities.length) {
                            $scope.selectExampleEntity(exampleEntities[0]);
                        } else {
                            $scope.selectExampleEntity(null);
                        }
                    } else {
                        if (changes.exampleEntity && changes.exampleEntity.currentValue) {
                            $scope.data.exampleEntity = changes.exampleEntity.currentValue;
                        }
                        // load the requested example entity
                        $scope.selectExampleEntity($scope.data.exampleEntity);
                    }
                })
                .finally(() => {
                    if ($scope.data.loadingInitialData) {
                        // Auto focus the field search input.
                        $timeout(() => {
                            const inspectInputElement = document.querySelector('#field-inspect-field-search-input');
                            if (inspectInputElement) {
                                inspectInputElement.focus();
                            }
                        });

                        $scope.data.loadingInitialData = false;
                    }
                });
        } else if (!ctrl.staticData && changes.exampleEntity) {
            $scope.data.exampleEntity = changes.exampleEntity.currentValue;
            $scope.selectExampleEntity($scope.data.exampleEntity);
        }

        // If we have static data, we want to load it and populate the entity fields.
        if (ctrl.staticData) {
            $scope.data.enrichedOriginalEntity = $scope.data.staticData.data;
            $scope.data.fieldNameToFieldTypeMap = $scope.data.staticData.fieldNameToFieldTypeMap || {};
            $scope.data.fieldNameToPossibleValuesMap = $scope.data.staticData.fieldNameToPossibleValuesMap || {};
            $scope.data.fieldNameToDisplayNameMap = $scope.data.staticData.displayNameMap;
            $scope.data.matchedEntitiesFields = $scope.data.staticData.matchedEntitiesFields;
            $scope.data.globalFields = $scope.data.staticData.globalFields;
            $scope.data.aggregationFields = $scope.data.staticData.aggregationFields;
            $scope.populateEntityFields($scope.data.enrichedOriginalEntity);
        }
    };

    /**
     * Toggles the entity search according to the given toggleOn param.
     */
    $scope.toggleEntitySearch = function (toggleOn) {
        $scope.data.changeEntityToggle = toggleOn;

        if ($scope.data.changeEntityToggle) {
            // If we are toggling on, try to auto open the ui-select.
            $timeout(() => {
                const entitySelectorToggle = document
                    .querySelector('#field-inspect-entity-selector')
                    .querySelectorAll('.ui-select-toggle');
                if (entitySelectorToggle && entitySelectorToggle[0]) {
                    entitySelectorToggle[0].click();
                }
            });
        }
    };

    /**
     * Parses the current $scope.data.selectedExampleEntity's fields and populates them into $scope.data.entityFields,
     * highlighting the current query text in the name and/or value of the field.
     */
    $scope.populateEntityFields = function (originalEntity) {
        // Get the populated entity fields. The function returns a promise.
        getPopulatedEntityFields(
            originalEntity,
            $scope.data.fieldQuery,
            $scope.data.fieldNameToDisplayNameMap,
            $scope.data.fieldNameToFieldTypeMap,
            $scope.data.fieldNameToPossibleValuesMap,
            $scope.data.matchedEntitiesFields,
            $scope.data.globalFields,
            $scope.data.aggregationFields,
        ).then((fields) => ($scope.data.entityFields = fields));
    };

    /**
     * Encapsulates getPopulatedEntityFields and uses the requestThrottler to throttle the logic,
     * so it doesn't slow down the user while he's typing.
     */
    $scope.populateEntityFieldsWhileTyping = function () {
        // The $scope.data.enrichedOriginalEntity is initialized on load with all the fields from the entity and $scope.data.fieldNameToDisplayNameMap.
        requestThrottler.do(
            'populateEntityFields',
            200,
            () =>
                getPopulatedEntityFields(
                    $scope.data.enrichedOriginalEntity,
                    $scope.data.fieldQuery,
                    $scope.data.fieldNameToDisplayNameMap,
                    $scope.data.fieldNameToFieldTypeMap,
                    $scope.data.fieldNameToPossibleValuesMap,
                    $scope.data.matchedEntitiesFields,
                    $scope.data.globalFields,
                    $scope.data.aggregationFields,
                ),
            (fields) => ($scope.data.entityFields = fields),
            null,
            () => {
                // Scroll the table back to top for the user.
                document.querySelector('#field-inspect-table').scrollTop = 0;
            },
        );
    };

    /**
     * Fired whenever the user presses a key in the search field input.
     */
    $scope.onSearchFieldKeyDown = function (event) {
        // We stop propagation of the event, since clicking enter while we're in popover might cause the containing modal to submit.
        if (event && (event.code === 'Enter' || event.keyCode === 13)) {
            event.stopPropagation();
        }
    };

    /**
     * Clears the current query.
     */
    $scope.clearQuery = function () {
        // Clear the query.
        $scope.data.fieldQuery = '';
        // Re-focus the search input.
        $timeout(() => document.querySelector('#field-inspect-field-search-input').focus());
        // Re-populate the entity fields (since the query has been cleared).
        $scope.populateEntityFields($scope.data.enrichedOriginalEntity);
    };

    /**
     * Loads the example entities from the server in a throttled way, meaning multiple requests are throttled according to a set timeout.
     * This is done so we not spam the server with requests while the user is typing.
     */
    $scope.loadExampleEntitiesThrottled = function (entityQuery) {
        requestThrottler.do(
            'loadExampleEntities',
            350,
            () => loadExampleEntities(entityQuery),
            (exampleEntities) => ($scope.data.exampleEntities = exampleEntities),
        );
    };

    /**
     * Marks the given entity as the selected example entity and uses its fields to populates the data.entityFields array.
     */
    $scope.selectExampleEntity = function (entity, event) {
        // The function can be called from different places and not always have an event.
        if (event) {
            // We stop propagation since it can lead to a "click outside" behaviour.
            event.stopPropagation();
        }

        // if it's initiative, we need to make sure it's example initiative, otherwise, we convert it first
        let convertResults;
        if ($scope.data.useTracksOfGroupId && entity && !entity.exampleFormat) {
            convertResults = convertInitiativesToExampleEntities([entity]);
            entity = convertResults.exampleEntities[0];
        }

        $scope.data.selectedExampleEntity = entity;

        // Enrich the selected example entity with all possible fields. It comes with only the fields it has value for.
        $scope.data.enrichedOriginalEntity = enrichExampleEntityWithFields(entity ? entity.originalEntity : {});
        // If we have an entity, we should parse and populate the $scope.data.entityFields array.
        $scope.populateEntityFields($scope.data.enrichedOriginalEntity);
        // Toggle off the change entity toggle (in case it was on).
        $scope.data.changeEntityToggle = false;
    };

    /**
     * Fired whenever the user selects a field from the inspect table.
     */
    $scope.onFieldSelected = function (field) {
        $timeout(() => {
            if (!$scope.data.multiSelect) {
                // Notify a field was selected.
                $scope.resolveSelection(field);
            } else {
                // We reassign the value to the map so that react will re-render in the FieldInspectList
                $scope.data.multiSelectMap = {
                    ...$scope.data.multiSelectMap,
                    [field.name]: !$scope.data.multiSelectMap[field.name] ? field : null,
                };
            }
        });
    };

    $scope.resolveMultiSelection = function () {
        const selectedFields = [];
        Object.entries($scope.data.multiSelectMap).forEach((pair) => selectedFields.push(pair[1]));
        $scope.resolveSelection(selectedFields);
    };

    /**
     * Fired whenever the user selects a field from the inspect table.
     */
    $scope.resolveSelection = function (field) {
        if (!$scope.data.viewOnly) {
            // Notify a field was selected.
            $scope.data.onFieldSelect({ field });
        }
    };

    /**
     * Fired whenever the user selects an entity from the entity selector.
     */
    $scope.onEntitySelected = function (event) {
        // Try to stop the event propagation, since the click might close us if we're in a popover mode.
        if (event) {
            event.stopPropagation();
        }
    };

    /**
     * Trim search term and trigger a new search
     *
     * @param event {MouseEvent} - the click event
     */
    $scope.trimSpaces = (event) => {
        event.preventDefault();
        event.stopPropagation();

        $scope.data.fieldQuery = $scope.data.fieldQuery.trim();
        $scope.populateEntityFieldsWhileTyping();
    };

    /**
     * Loads example entities for the current project integration, external type and query.
     * @returns {Promise<any>}
     */
    function loadExampleEntities(entityQuery) {
        const projectIntegration = $scope.data.projectIntegration;

        const query = utils.isNullOrEmpty(entityQuery) ? null : entityQuery;
        $scope.data.loadingExampleEntities = true;

        const promises = [];

        if ($scope.data.useTracksOfGroupId) {
            // get tracks as examples
            promises.push(getInitiativesExampleEntities($scope.data.useTracksOfGroupId, query, 10, ctrl.onlyUpdatable));
        } else if (projectIntegration && projectIntegration.integration) {
            // Only do something if we have a project integration to get field options from.
            promises.push(
                tonkeanService.getIntegrationExampleEntities(
                    projectIntegration.id,
                    $scope.data.externalType,
                    query,
                    10,
                    ctrl.onlyUpdatable,
                ),
            );
        } else {
            promises.push($q.resolve());
        }

        if (projectIntegration) {
            promises.push(tonkeanService.getAvailableExternalFields(projectIntegration.id, [$scope.data.externalType]));
        }

        return Promise.all(promises)
            .then((results) => {
                const exampleEntitiesResult = results[0];
                const availableExternalFields = results[1];

                if (availableExternalFields && availableExternalFields.entitiesWithLabels) {
                    enrichExampleEntitiesWithOtherFields(
                        availableExternalFields.entitiesWithLabels,
                        exampleEntitiesResult,
                    );
                }

                let exampleEntities = [];

                if (exampleEntitiesResult && exampleEntitiesResult.exampleEntities) {
                    exampleEntities = exampleEntitiesResult.exampleEntities;
                    // Save the mapping between field names to field display names, while making sure it's never null.
                    $scope.data.fieldNameToFieldTypeMap = exampleEntitiesResult.fieldNameToFieldTypeMap || {};
                    $scope.data.fieldNameToPossibleValuesMap = exampleEntitiesResult.fieldNameToPossibleValuesMap || {};
                    $scope.data.fieldNameToDisplayNameMap = exampleEntitiesResult.fieldNameToDisplayNameMap || {};
                    $scope.data.matchedEntitiesFields = exampleEntitiesResult.matchedEntityFields;
                    $scope.data.globalFields = exampleEntitiesResult.globalFields;
                    $scope.data.aggregationFields = exampleEntitiesResult.aggregationFields;
                }

                return $q.resolve(exampleEntities);
            })
            .finally(function () {
                $scope.data.loadingExampleEntities = false;
            });
    }

    /**
     * The function enriches the given example entities with the whole entity fields metadata.
     * The fields number is changing between entities. Some may have 10 and other 15.
     * This function will add the missing fields to the partial entity in order to fill it.
     */
    function enrichExampleEntitiesWithOtherFields(availableExternalFields, exampleEntities) {
        for (const field of availableExternalFields) {
            // fieldNameToFieldTypeMap
            if (!exampleEntities.fieldNameToFieldTypeMap) {
                exampleEntities.fieldNameToFieldTypeMap = {};
            }
            if (!exampleEntities.fieldNameToFieldTypeMap[field.name]) {
                exampleEntities.fieldNameToFieldTypeMap[field.name] = field.type;
            }

            // fieldNameToPossibleValuesMap
            if (!exampleEntities.fieldNameToPossibleValuesMap) {
                exampleEntities.fieldNameToPossibleValuesMap = {};
            }
            if (!exampleEntities.fieldNameToPossibleValuesMap[field.name]) {
                exampleEntities.fieldNameToPossibleValuesMap[field.name] = field.values;
            }

            // fieldNameToDisplayNameMap
            if (!exampleEntities.fieldNameToDisplayNameMap) {
                exampleEntities.fieldNameToDisplayNameMap = {};
            }
            if (!exampleEntities.fieldNameToDisplayNameMap[field.name]) {
                exampleEntities.fieldNameToDisplayNameMap[field.name] = field.label;
            }
        }
    }

    function getDisplayFieldName(fieldDisplayName) {
        return `${$scope.data.externalTypeDisplayName || $scope.data.externalType}-${fieldDisplayName}`;
    }

    /**
     * Builds a fields array according to the given original entity and returns a promise with that data.
     * @param originalEntity - the original entity as extracted from the entity returned from the server.
     * @param query - the current field search query.
     * @param displayNameMap - a map from field name to display name as returned from the server.
     * @param fieldTypeMap - a map from field name to type as returned from the server.
     * @param possibleValuesMap - a map from field name to possible values as returned from the server.
     * @param matchedEntityFieldsArray - set of the fields from matched entity
     * @returns {promise}
     */
    function getPopulatedEntityFields(
        originalEntity,
        query,
        displayNameMap,
        fieldTypeMap,
        possibleValuesMap,
        matchedEntityFieldsArray,
        globalFields,
        aggregationFields,
    ) {
        const fields = [];

        if (originalEntity) {
            if (query) {
                const lowerFieldQuery = query.toLowerCase();

                // Go over the properties in originalEntity and parse them into html that surround the current query
                // with a span with the field-inspect-table-highlight class (if the query is matched for each field).
                let matchesCount = 0;
                for (const fieldName in originalEntity) {
                    if (originalEntity.hasOwnProperty(fieldName)) {
                        // Check if the display name and/or value match the query.
                        const fieldType = fieldTypeMap[fieldName];
                        const possibleValues = possibleValuesMap[fieldName];
                        const fieldDisplayName = displayNameMap[fieldName] || fieldName;
                        const displayNameMatchIndex = fieldDisplayName.toLowerCase().indexOf(lowerFieldQuery);

                        // We make sure the value is not null or undefined before running toString and toLowerCase on it.
                        // We call toString so even if the value is an ARRAY of values, we can search and highlight it.
                        const fieldValue = originalEntity[fieldName]?.toString?.();
                        const valueMatchIndex = fieldValue?.toLowerCase().indexOf(lowerFieldQuery);

                        // If either the name or value matched, add the field to the BEGINNING of the array and create html with highlighted span for each.
                        // We show all values always, but put the highlighted matches in the start.
                        if (displayNameMatchIndex > -1 || valueMatchIndex > -1) {
                            matchesCount += 1;
                            fields.unshift({
                                id: !$scope.data.shouldHaveEntityPrefix
                                    ? fieldName
                                    : `${$scope.data.externalType}-${fieldName}`,
                                name: !$scope.data.shouldHaveEntityPrefix
                                    ? fieldName
                                    : `${$scope.data.externalType}-${fieldName}`,
                                type: fieldType,
                                displayName: !$scope.data.shouldHaveEntityPrefix
                                    ? fieldDisplayName
                                    : getDisplayFieldName(fieldDisplayName),
                                possibleValues,
                                value: fieldValue,
                                entity: $scope.data.externalType,
                                isMatchedEntityField: matchedEntityFieldsArray?.includes(fieldName) || false,
                                isGlobalField: globalFields?.includes(fieldName) || false,
                                isAggregationField: aggregationFields?.includes(fieldName) || false,
                            });
                        } else {
                            // No match, add the item to the end of the array.
                            fields.push({
                                id: !$scope.data.shouldHaveEntityPrefix
                                    ? fieldName
                                    : `${$scope.data.externalType}-${fieldName}`,
                                name:
                                    $scope.data.workflowVersionId?.length > 0
                                        ? fieldName
                                        : `${$scope.data.externalType}-${fieldName}`,
                                type: fieldType,
                                displayName:
                                    $scope.data.workflowVersionId?.length > 0
                                        ? fieldDisplayName
                                        : getDisplayFieldName(fieldDisplayName),
                                possibleValues,
                                value: fieldValue,
                                entity: $scope.data.externalType,
                                isMatchedEntityField: matchedEntityFieldsArray?.includes(fieldName) || false,
                                isGlobalField: globalFields?.includes(fieldName) || false,
                                isAggregationField: aggregationFields?.includes(fieldName) || false,
                            });
                        }
                    }
                }

                // Check if we have found any match. If there is no single match, we empty the array so we'll reach the empty state.
                // We don't expect this to happen when the query is of a field name (that the user's selected in the field selector,
                // because we show all possible fields - even empty ones).
                if (matchesCount === 0) {
                    fields.splice(0, fields.length);
                }
            } else {
                // No query, we just create basic html for name and value.
                for (const fieldName in originalEntity) {
                    if (originalEntity.hasOwnProperty(fieldName)) {
                        const fieldDisplayName = displayNameMap[fieldName] || fieldName;

                        fields.push({
                            id: !$scope.data.shouldHaveEntityPrefix
                                ? fieldName
                                : `${$scope.data.externalType}-${fieldName}`,
                            name: !$scope.data.shouldHaveEntityPrefix
                                ? fieldName
                                : `${$scope.data.externalType}-${fieldName}`,
                            type: $scope.data.fieldNameToFieldTypeMap[fieldName],
                            possibleValues: $scope.data.fieldNameToPossibleValuesMap[fieldName],
                            displayName: !$scope.data.shouldHaveEntityPrefix
                                ? fieldDisplayName
                                : getDisplayFieldName(fieldDisplayName),
                            value: originalEntity[fieldName]?.toString?.() || 'empty',
                            entity: $scope.data.externalType,
                            isMatchedEntityField: matchedEntityFieldsArray?.includes(fieldName) || false,
                            isGlobalField: globalFields?.includes(fieldName) || false,
                            isAggregationField: aggregationFields?.includes(fieldName) || false,
                        });
                    }
                }
            }
        }

        return $q.resolve(fields).then((fields) => (ctrl.fieldFilter ? fields.filter(ctrl.fieldFilter) : fields));
    }

    /**
     * Enriches the given original entity with the fields from the data.fieldNameToDisplayNameMap.
     * This allows us to also show 'empty' fields.
     */
    function enrichExampleEntityWithFields(originalEntity) {
        // Make sure we don't have null as an original entity.
        if (!originalEntity) {
            originalEntity = {};
        }

        if ($scope.data.fieldNameToDisplayNameMap) {
            const fieldsMap = $scope.data.fieldNameToDisplayNameMap;

            // Go over the fields in the data.fieldNameToDisplayNameMap, and add the ones that are aren't in the example entity's fields map.
            for (const fieldName in fieldsMap) {
                // If it's a custom property of the fieldsMap and it doesn't exist in the entity, add it.
                if (fieldsMap.hasOwnProperty(fieldName) && !originalEntity[fieldName]) {
                    originalEntity[fieldName] = null;
                }
            }
        }

        return originalEntity;
    }

    function getInitiativesExampleEntities(groupId, searchText, limit, onlyUpdatable) {
        // If no environment provided will default to prod
        const isDraftInitiatives =
            $scope.data.workflowVersionId && $scope.data.onlyItemsFromSelectedEnvironment
                ? workflowVersionManager.isDraftVersion($scope.data.workflowVersionId)
                : false;

        return tonkeanService
            .searchInitiatives(projectManager.project.id, {
                isArchived: false,
                groupId,
                searchString: searchText,
                limit,
                isDraftInitiatives,
            })
            .then((data) => {
                const response = convertInitiativesToExampleEntities(data.entities, onlyUpdatable);
                return $q.resolve(response);
            });
    }

    function convertInitiativesToExampleEntities(initiatives, onlyUpdatable) {
        const response = {
            exampleEntities: [],
            fieldNameToDisplayNameMap: {},
            fieldNameToFieldTypeMap: {},
        };

        for (const item of initiatives) {
            const originalEntity = {};

            // load the special fields first
            for (let j = 0; j < $scope.data.specialFields.length; j++) {
                const specialField = $scope.data.specialFields[j];
                if (specialField.entityFieldName && (!onlyUpdatable || specialField.updateable)) {
                    const fieldName = specialField.entityFieldName;
                    response.fieldNameToDisplayNameMap[fieldName] = specialField.label;
                    response.fieldNameToFieldTypeMap[fieldName] = specialField.fieldType;

                    let value = item[fieldName];
                    if (value) {
                        // if obj, take the name
                        if (value.id) {
                            value = value.name;
                        } else if (Array.isArray(value)) {
                            value = value.join(', ');
                        }

                        originalEntity[fieldName] = value;
                    }
                }
            }

            if (item.fields) {
                // add the fields

                for (let k = 0; k < item.fields.length; k++) {
                    const field = item.fields[k];
                    if (!field.fieldDefinition.systemUtilized && (!onlyUpdatable || field.fieldDefinition.updateable)) {
                        const fieldDefId = field.fieldDefinition.id;
                        response.fieldNameToDisplayNameMap[fieldDefId] = field.fieldDefinition.name;
                        response.fieldNameToFieldTypeMap[fieldDefId] = field.fieldDefinition.fieldType;
                        $scope.data.fieldNameToDisplayNameMap[fieldDefId] = field.fieldDefinition.name;

                        originalEntity[fieldDefId] = field.value;
                    }
                }
            }

            response.exampleEntities.push({
                id: item.id,
                title: item.title,
                exampleFormat: true,
                externalTypePluralName: 'Items',
                originalEntity,
            });
        }

        return response;
    }

    async function setExampleEntityIfInsideWorkflowFromCache() {
        if (!$scope.data.workflowVersionId) {
            return;
        }

        const exampleEntityId = customTriggerManager.getWorkflowExampleItemIdFromCache($scope.data.workflowVersionId);
        if (!exampleEntityId) {
            return;
        }

        const entity = await trackHelper.getInitiativeById(exampleEntityId);
        if (!entity) {
            return;
        }

        if ($scope.data.useTracksOfGroupId) {
            $scope.data.exampleEntity = entity;
        } else if (
            entity.externalId &&
            !$scope.data.useTracksOfGroupId &&
            entity.externalType === $scope.data.exampleEntities[0]?.externalType
        ) {
            try {
                $scope.data.exampleEntity = await tonkeanService.getExternalActivityById(
                    entity.project.id,
                    entity.linkedProjectIntegrationId.id,
                    entity.externalId,
                    false,
                );
            } catch {
                $scope.data.exampleEntity = null;
            }
        }
    }
}

angular.module('tonkean.app').controller('FieldInspectCtrl', lateConstructController(FieldInspectCtrl));
