import latticeCircleIcon from '../../../../apps/tracks/images/integrations/lattice-circle.png';
import latticeIcon from '../../../../apps/tracks/images/lattice.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class LatticeIntegration extends IntegrationDefinitionBase {
    override name = 'lattice';
    override displayName = 'Lattice';
    override description = 'We will only collect Feedback and Reviews';
    override authenticationType = AuthenticationMethod.API_TOKEN;
    override externalActivityCreatedDateFieldName = 'createdate';
    override entities = [new IntegrationEntity('Feedback', 'Feedbacks'), new IntegrationEntity('Review', 'Reviews')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(latticeCircleIcon, '130px'),
        new IntegrationIcon(latticeIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Feedback: true, Review: true },
        isOnlyDataRetention: {},
    };
}

export default LatticeIntegration;
