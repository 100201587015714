import React from 'react';
import styled from 'styled-components';

import ColumnId from '../../configs/config/columnId';
import type ItemsGridCellRendererParams from '../../entities/ItemsGridCellRendererParams';
import isEditableColumn from '../../utils/getIsEditableColumn';

import { FileIcon, H4 } from '@tonkean/infrastructure';

const IconWrapper = styled.div`
    margin-top: 15px;
    padding-right: 12px;
`;

const ClickableWrapper = styled(H4)<{ $clickable: boolean }>`
    text-decoration: ${({ $clickable }) => ($clickable ? 'underline' : 'none')};
`;

const TitleCellRenderer: React.FC<ItemsGridCellRendererParams & { isDrilldownEnabled: boolean }> = ({
    data,
    value,
    context: { backgroundColor, drilldownColumnId },
}) => {
    // Regardless of if the column is editable, we want to check if the column can be clicked for drilldown
    const isDrilldownEnabled = !!drilldownColumnId && !isEditableColumn(value, ColumnId.Title, true, drilldownColumnId);

    return (
        <>
            {data?.titleIcon && (
                <IconWrapper>
                    {data?.titleIcon.icon || <FileIcon name={data.titleIcon.name} size={25} key={data.tableRowId} />}
                </IconWrapper>
            )}

            <ClickableWrapper $clickable={isDrilldownEnabled}>{value}</ClickableWrapper>
        </>
    );
};

export default TitleCellRenderer;
