import template from './tnkIntervalPicker.template.html?angularjs';

function tnkIntervalPicker() {
    return {
        restrict: 'E',
        scope: {
            interval: '=',
            options: '=?',
        },
        template,
        controller: 'IntervalPickerCtrl',
    };
}

export default angular.module('tonkean.app').directive('tnkIntervalPicker', tnkIntervalPicker);
