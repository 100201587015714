import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { Placeholder } from '@tonkean/infrastructure';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledTonkeanExpression = styled(TonkeanExpression)`
    .tnk-tags-expression {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-color: ${Theme.colors.gray_300};
    }
`;

interface Props {
    name: string;
    expressionProps?: FormikExpressionProps;
    isLoading: boolean;
    hideEnvUrlPrefix: boolean;
}

const UrlComponent: React.FC<Props> = ({ name, expressionProps, isLoading, hideEnvUrlPrefix }) => {
    const [url, , urlHelpers] = useField<TonkeanExpressionDefinition>(name);

    return (
        <>
            {isLoading && <Placeholder $height="36px" $width="auto" />}

            {!isLoading && (
                <StyledTonkeanExpression
                    {...expressionProps}
                    placeholder="Request URL"
                    savedOriginalExpression={url.value.originalExpression}
                    savedEvaluatedExpression={url.value.evaluatedExpression}
                    onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                        urlHelpers.setValue({
                            ...url.value,
                            originalExpression,
                            evaluatedExpression,
                        });
                    }}
                    prefix={hideEnvUrlPrefix ? '' : '{{env_url}}/'}
                />
            )}
        </>
    );
};

export default UrlComponent;
