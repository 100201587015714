import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as MoreActionIcon } from '../../../../images/icons/more-actions.svg';

import { useToastMessage } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Menu, MenuItem, useCreateDialog, useToggle } from '@tonkean/infrastructure';
import type { ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const StyledIconButton = styled(IconButton)`
    margin-left: 5px;
`;

interface Props {
    fetcherAction: ProjectIntegrationAction;
    entity: ProjectIntegrationEntity;
    setEntity: React.Dispatch<React.SetStateAction<ProjectIntegrationEntity>>;
    onDelete: (itemId: string) => void;
}

const ProjectIntegrationActionFetcherMenu: React.FC<Props> = ({ fetcherAction, entity, setEntity, onDelete }) => {
    const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
    const { confirm } = useCreateDialog();
    const $rootScope = useAngularService('$rootScope');
    const emitToast = useToastMessage();

    const [{ loading: loadingDeleteAction }, deleteProjectIntegrationAction] = useLazyTonkeanService(
        'deleteProjectIntegrationAction',
    );

    const [
        { loading: isUpdateWebhookLoading, error: updateWebhookError },
        updateProjectIntegrationEntityFetcherDefinition,
    ] = useLazyTonkeanService('updateProjectIntegrationEntityFetcherDefinition');

    const [, getProjectIntegrationFetcherDependencies] = useLazyTonkeanService(
        'getProjectIntegrationFetcherDependencies',
    );

    const onClickDeleteEntity = async () => {
        try {
            const dependentEntities = await getProjectIntegrationFetcherDependencies(
                fetcherAction.projectIntegrationId,
                fetcherAction.id,
            );

            // If we have dependent entities we not allow deleting
            if (dependentEntities.entitiesDependentDisplayNames.length) {
                emitToast(
                    `Could not delete entity fetcher. This entity fetcher depends on those entities : ${dependentEntities.entitiesDependentDisplayNames.join(
                        ', ',
                    )}. Remove the dependencies before deleting`,
                    'danger',
                );
            } else {
                const confirmed = await confirm(
                    'Are you sure?',
                    `You are about to delete entity fetcher "${fetcherAction.displayName}" This action cannot be undone`,
                    {
                        okLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        warning: true,
                    },
                );

                if (!confirmed) {
                    return;
                }

                onDelete(fetcherAction.id);
                await deleteProjectIntegrationAction(fetcherAction.id);
            }
        } catch {
            emitToast('Failed to delete entity fetcher. Please try again.', 'danger');
        }
    };

    const onClickUseForSingleCollect = async () => {
        const confirmed = await confirm(
            'Are you sure?',
            `You are about to change the default entity fetcher "${fetcherAction.displayName}"`,
            {
                okLabel: 'Set as Default',
                cancelLabel: 'Cancel',
                warning: true,
            },
        );

        if (!confirmed) {
            return;
        }

        updateProjectIntegrationEntityFetcherDefinition(entity.projectIntegrationId, entity.id, fetcherAction.id)
            .then((res) => {
                setEntity({ ...entity, entityFetcherDefinition: res.entityFetcherDefinition });
            })
            .catch(() => {
                emitToast('Failed to update default entity fetcher. Please try again.', 'danger');
            });
    };

    return (
        <Menu
            show={isMenuOpen}
            onClose={toggleIsMenuOpen}
            placement="right-start"
            menuItems={
                <>
                    <MenuItem onClick={onClickDeleteEntity}>Delete Fetcher</MenuItem>
                    <MenuItem onClick={onClickUseForSingleCollect}>Use for single collect</MenuItem>
                </>
            }
            thin
        >
            <StyledIconButton onClick={() => toggleIsMenuOpen()} flat>
                <MoreActionIcon />
            </StyledIconButton>
        </Menu>
    );
};

export default React.memo(ProjectIntegrationActionFetcherMenu);
