<div class="margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                custom-trigger-id="{{ data.configuredLogic.node.id }}"
                file-source-type="{{ data.definition.fileSourceType }}"
                preview-evaluation-source="data.previewEvaluationSource"
                storage-integration-id="{{ data.definition.storageIntegrationId }}"
                storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
                should-display-delete-sign="false"
                url-expression="data.definition.url"
                extended-match-configuration="data.definition.extendedMatchConfiguration"
                worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
                worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
                parameters-values="data.definition.parametersValues"
                on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- Path + File name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                File Path &amp; Name:
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Document name"
                    saved-original-expression="data.definition.filePathAndNameExpression.originalExpression"
                    saved-evaluated-expression="data.definition.filePathAndNameExpression.evaluatedExpression"
                    on-tonkean-expression-changed="
                    onFilePathAndNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'MOVE_FILE'" class="margin-bottom-xlg">
        <!-- New File Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                New File Name:
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="New File Name"
                    saved-original-expression="data.definition.newFileNameExpression.originalExpression"
                    saved-evaluated-expression="data.definition.newFileNameExpression.evaluatedExpression"
                    on-tonkean-expression-changed="
                    onNewFileNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Full path -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Target Path:
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="If empty, the original path will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Target Path"
                    saved-original-expression="data.definition.targetPathExpression.originalExpression"
                    saved-evaluated-expression="data.definition.targetPathExpression.evaluatedExpression"
                    on-tonkean-expression-changed="
                    onTargetPathTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
