import { useMemo } from 'react';

import type { DetailedItemsWidgetItemInfo } from '../entities/DetailedItemsWidgetItemInfo';

import { Validation, type ValidationState } from '@tonkean/tonkean-entities';

const useItemsVisibleValidMap = (
    itemsInfo: DetailedItemsWidgetItemInfo[],
    visitedItemInterfacesValidation?: Record<string, ValidationState>,
    forceShowValidation?: boolean,
) => {
    return useMemo(() => {
        return itemsInfo.reduce((acc, itemInfo) => {
            const itemIsVisiblyInvalid =
                visitedItemInterfacesValidation?.[itemInfo.id]?.visibleValidation === Validation.INVALID;
            const itemIsForcedShownInvalid =
                forceShowValidation &&
                visitedItemInterfacesValidation?.[itemInfo.id]?.trueValidation === Validation.INVALID;
            const itemNotVisited =
                !visitedItemInterfacesValidation ||
                !(itemInfo.id in visitedItemInterfacesValidation) ||
                visitedItemInterfacesValidation?.[itemInfo.id]?.trueValidation === Validation.NOTHING_TO_VALIDATE;

            acc[itemInfo.id] = itemNotVisited || (!itemIsVisiblyInvalid && !itemIsForcedShownInvalid);

            return acc;
        }, {});
    }, [forceShowValidation, itemsInfo, visitedItemInterfacesValidation]);
};

export default useItemsVisibleValidMap;
