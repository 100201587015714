<div class="connect-field-manually-modal relative">
    <div class="flex-vmiddle">
        <div class="common-title flex-grow">
            <span>
                Connect field:
                <em class="common-italic">{{ fieldNameTitle }}</em>
            </span>
        </div>
        <!-- Integration logo -->
        <div class="flex-no-shrink">
            <!-- Icon when there's NO icon url. -->
            <i
                ng-if="!data.field.fieldDefinition.projectIntegration.iconUrl"
                class="integration-group-header-connected-logo margin-none mod-{{
                    data.field.fieldDefinition.projectIntegration.integration.integrationType | lowercase
                }}"
            ></i>
            <!-- Icon when there's an iconUrl -->
            <div
                ng-if="data.field.fieldDefinition.projectIntegration.iconUrl"
                class="integration-group-header-connected-logo mod-manual-icon"
            >
                <i
                    class="initiative-integration-icon mod-normal margin-right-xs"
                    ng-style="{
                        background:
                            'url(' + data.field.fieldDefinition.projectIntegration.iconUrl + ') no-repeat center'
                    }"
                ></i>
                <span>{{ data.field.fieldDefinition.projectIntegration.displayName }}</span>
            </div>
        </div>
    </div>

    <hr />

    <div class="text-center" ng-show="loading">
        <i class="loading-large"></i>
    </div>

    <div ng-hide="loading">
        <form name="forms.editFieldForm" ng-submit="submit()" novalidate>
            <div ng-switch="data.currentType">
                <div ng-switch-when="SQL">
                    <div ng-include="'./integrations/mysql.template.html'"></div>
                </div>
                <div ng-switch-when="EXTERNAL">
                    <div ng-include="'./integrations/external.template.html'"></div>
                </div>
                <div ng-switch-when="GOOGLE">
                    <div ng-include="'./integrations/google.template.html'"></div>
                </div>
                <div ng-switch-when="JIRA_COUNT_FILTER">
                    <div ng-include="'./integrations/jira_count_filter.template.html'"></div>
                </div>
                <div ng-switch-when="AGGREGATE_QUERY">
                    <div ng-include="'./integrations/count_field.template.html'"></div>
                </div>
                <div ng-switch-when="SMARTSHEET">
                    <div ng-include="'./integrations/smartsheet.template.html'"></div>
                </div>
            </div>

            <div class="clearfix padding-top margin-top-lg">
                <button
                    type="submit"
                    class="btn btn-primary mark-modal-btn flex-vmiddle"
                    ng-disabled="!forms.editFieldForm.$valid || posting || !data.item || !data.item.id"
                >
                    <i class="loading-small margin-right-xs common-color-white" ng-show="posting"></i>
                    Save
                </button>
                <button
                    type="button"
                    class="btn btn-default mark-modal-btn"
                    ng-click="$dismiss()"
                    ng-disabled="posting"
                >
                    Cancel
                </button>
            </div>
            <div class="margin-top">
                <span class="text-danger" ng-if="error">{{ error | error }}</span>
            </div>
        </form>
    </div>
</div>
