/* jshint ignore:start */

import React from 'react';
import DefaultHistoryReason from './DefaultHistoryReason';

export default class FieldRangeChangedHistoryReason extends DefaultHistoryReason {
    constructor(props) {
        super(props);
    }

    getTitle() {
        if (
            this.props.item.reasonMetadata &&
            this.props.item.reasonMetadata.fieldRangesChanges &&
            this.props.item.reasonMetadata.fieldRangesChanges.length
        ) {
            const changes = [];
            for (let i = 0; i < this.props.item.reasonMetadata.fieldRangesChanges.length; i++) {
                const fieldRangeChange = this.props.item.reasonMetadata.fieldRangesChanges[i];
                changes.push(
                    <div key={i}>
                        value of column "{fieldRangeChange.fieldDefName}" was changed
                        {fieldRangeChange.oldValue ? ` from ${fieldRangeChange.oldValue}` : null} to{' '}
                        {fieldRangeChange.newValue}
                    </div>,
                );
            }

            return (
                <React.Fragment>
                    <div>
                        {this.props.person.name} was notified that for {this.renderTitleLink()} the{' '}
                    </div>
                    {changes}
                </React.Fragment>
            );
        } else {
            return super.getTitle();
        }
    }
}
/* jshint ignore:end */
