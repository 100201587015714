import React, { useState } from 'react';
import styled from 'styled-components';

import TriggeredModulesEnvironmentPicker from './TriggeredModulesEnvironmentPicker';
import TriggeredSolutionsModulesTable from './TriggeredSolutionsModulesTable';
import useGetWorkerRunsItemFormattedDate from '../../../../../../HistoryModule/hooks/useGetWorkerRunsItemFormattedDate';

import { useTonkeanService } from '@tonkean/angular-hooks';
import {
    Breadcrumbs,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalHeaderActionButtons,
    ModalSize,
    XCloseButton,
} from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { Environment, ProjectIntegration } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const CollectedItemTitle = styled.div`
    font-size: ${FontSize.LARGE_16};
    margin-top: 16px;
`;

const CollectedItemLabel = styled.span`
    color: ${Theme.colors.gray_500};
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const ProjectIntegrationIconWrapper = styled.div`
    height: 22px;
    display: flex;
    align-items: center;
    margin-right: 4px;
    margin-top: 4px;
`;

const StyledModalBody = styled(ModalBody)`
    padding: 0;
`;

const ModalCloseButton = styled(XCloseButton)`
    position: absolute;
    right: 12px;
    top: 12px;
`;

interface Props {
    open: boolean;
    onClose: () => void;
    externalActivity: { id: string; title: string };
    projectIntegration: ProjectIntegration;
    activityLogType: string;
    externalType: string;
    collectLogTime: number;
}

const TriggeredModulesModal: React.FC<Props> = ({
    open,
    onClose,
    externalActivity,
    projectIntegration,
    activityLogType,
    externalType,
    collectLogTime,
}) => {
    const [environment, setEnvironment] = useState<Environment>('production' as Environment);

    const project = useProject();

    const {
        data: groups,
        error: groupsError,
        loading: groupsLoading,
    } = useTonkeanService(
        'getSyncedGroupsByProjectIntegrationExternalType',
        project.id,
        environment === 'build' ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
        projectIntegration.id,
        externalType,
        0,
        100,
    );

    const { formattedDate, formattedTime } = useGetWorkerRunsItemFormattedDate(collectLogTime);

    const breadcrumbsConfig = [
        {
            id: '1',
            displayName: projectIntegration.displayNameFull,
            prefixIcon: (
                <ProjectIntegrationIcon
                    projectIntegrationId={projectIntegration.id}
                    integrationType={projectIntegration.integrationType}
                    width={14}
                    height={14}
                    placeholderWidth={14}
                />
            ),
        },
        {
            id: '2',
            displayName: 'Collection Logs',
        },
        {
            id: '3',
            displayName: `${activityLogType} (${formattedDate ? formattedDate : ''}${
                formattedDate ? ', ' : ''
            }${formattedTime})`,
        },
    ];

    return (
        <Modal onClose={onClose} size={ModalSize.LARGE} height="580px" open fixedWidth>
            <ModalHeader>
                <Flex>
                    <ProjectIntegrationIconWrapper />
                    <Breadcrumbs settings={breadcrumbsConfig} />
                </Flex>

                <CollectedItemTitle>
                    <CollectedItemLabel>Collected Item:</CollectedItemLabel> {externalActivity.title}
                </CollectedItemTitle>

                <ModalHeaderActionButtons />
            </ModalHeader>

            <StyledModalBody>
                <>
                    <TriggeredModulesEnvironmentPicker
                        groupsCount={groups?.groups?.length || 0}
                        loading={groupsLoading}
                        error={groupsError?.data?.error?.message}
                        onChange={(newEnvironment) => setEnvironment(newEnvironment)}
                    />

                    <TriggeredSolutionsModulesTable
                        externalActivity={externalActivity}
                        projectIntegration={projectIntegration}
                        collectLogTime={collectLogTime}
                        groups={groups?.groups || []}
                        error={groupsError?.data?.error?.message || ''}
                        loading={groupsLoading || false}
                        environment={environment}
                    />
                </>
            </StyledModalBody>
            <ModalFooter border />
        </Modal>
    );
};

export default TriggeredModulesModal;
