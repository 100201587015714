<div
    ng-if="!displayInPlace"
    class="track-item-func-height"
    uib-popover-template="'./noGatherUpdatePopoverTemplate.template.html'"
    popover-is-open="isOpen"
    popover-placement="{{ placement || 'bottom-right' }}"
    popover-trigger="none"
    popover-class="no-gather-update-popover-template"
></div>
<div ng-if="displayInPlace">
    <div ng-include="'./noGatherUpdatePopoverTemplate.template.html'"></div>
</div>
