import { useAngularService } from 'angulareact';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import type { StateParams } from './useHistoryStateManager';
import HistoryContext from '../entities/HistoryContext';
import initialFilters from '../entities/initialFilters';
import type WorkerRunStagesFilter from '../entities/WorkerRunStagesFilter';
import originatedWorkerRunState from '../states/originatedWorkerRunState';
import workerRunsFilterState from '../states/workerRunsFilterState';

import { useGetStateParams } from '@tonkean/angular-hooks';

/**
 * React hook that manages the filters of the worker runs list by:
 * - Creates their states,
 * - Clears the filters when changing group,
 * - Clears version filter when changing environment,
 * - And updates search term when defaultSearchTerm from the URL state changes.
 *
 * @param state - the current URL state.
 */
function useWorkerRunsFilters(
    state: StateParams,
    updateState: (newState: Partial<StateParams>, showInBrowserHistory?: boolean) => void,
) {
    const { groupId, defaultFilterSearchTerm, environment } = state;

    const [filters, setFilters] = useRecoilState(workerRunsFilterState);
    const [workerRunStagesFilter, setWorkerRunStagesFilter] = useState<WorkerRunStagesFilter>(initialFilters.stages);
    const { setRangeFilter: setWorkerRunDateRangeFilter, rangeFilter: workerRunDateRangeFilter } =
        useContext(HistoryContext);
    const resetOriginatedWorker = useResetRecoilState(originatedWorkerRunState);
    const $state = useAngularService('$state');

    const [searchTerm, setSearchTerm] = useState(defaultFilterSearchTerm || '');

    const clearFilters = useCallback(() => {
        setFilters(initialFilters.filters);
        setSearchTerm('');
        resetOriginatedWorker();
        setWorkerRunStagesFilter(initialFilters.stages);
    }, [resetOriginatedWorker, setFilters]);

    /**
     * Clear filters when switching modules
     */
    useEffect(() => {
        clearFilters();
    }, [groupId, clearFilters]);

    /**
     * Clear filters when switching modules
     */
    useEffect(() => {
        if (environment === 'build') {
            setFilters((filters) => ({
                ...filters,
                version: undefined,
            }));
        }
    }, [environment, setFilters]);

    /**
     * Update initial search term
     */
    useEffect(() => {
        if (defaultFilterSearchTerm) {
            setSearchTerm(defaultFilterSearchTerm);
            updateState({ ...state, defaultFilterSearchTerm: undefined }, false);
        }
    }, [defaultFilterSearchTerm, state, updateState]);

    const [defaultFilterBackgroundProcessId] = useGetStateParams('defaultFilterBackgroundProcessId');
    const [defaultFilterStartTime] = useGetStateParams('defaultFilterStartTime');

    useEffect(() => {
        if (defaultFilterBackgroundProcessId || defaultFilterStartTime) {
            if (defaultFilterBackgroundProcessId) {
                setFilters((currentFilters) => ({
                    ...currentFilters,
                    backgroundProcess: defaultFilterBackgroundProcessId,
                }));
            }

            if (defaultFilterStartTime) {
                setWorkerRunDateRangeFilter((currentRange) => ({
                    ...currentRange,
                    dateFrom: new Date(defaultFilterStartTime),
                }));
            }

            $state.go('.', { defaultFilterBackgroundProcessId: undefined }, { location: 'replace', notify: false });
        }
    }, [defaultFilterBackgroundProcessId, $state, setFilters, defaultFilterStartTime, setWorkerRunDateRangeFilter]);

    return {
        filters,
        setFilters,
        workerRunDateRangeFilter,
        setWorkerRunDateRangeFilter,
        workerRunStagesFilter,
        setWorkerRunStagesFilter,
        searchTerm,
        setSearchTerm,
        clearFilters,
    };
}

export default useWorkerRunsFilters;
