import React from 'react';

import ModuleItemsWidgetAgGridConfigurationEditor from './ModuleItemsWidgetAgGridConfigurationEditor';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import InnerItemsItemWidgetConfigurationEditorDisplay from '../../InnerItemsWidgetModule/components/configurationEditorDisplays/InnerItemsItemWidgetConfigurationEditorDisplay';
import type ModuleItemsWidgetConfiguration from '../entities/ModuleItemsWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useItemInterfaceContext } from '@tonkean/infrastructure';

const ModuleItemsItemWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<ModuleItemsWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const { itemInterfaceFieldDefinitions, loadingItemInterfaceFieldDefinitions } = useItemInterfaceContext();
    const showItemsTableAgGrid = !!useFeatureFlag('tonkean_feature_workspace_app_inner_items_ag_grid');

    return showItemsTableAgGrid ? (
        <ModuleItemsWidgetAgGridConfigurationEditor
            configuration={configuration}
            workflowVersionId={workflowVersion.id}
            groupId={workflowVersion.groupId}
            fieldDefinitions={itemInterfaceFieldDefinitions}
            isLoadingFieldDefinitions={loadingItemInterfaceFieldDefinitions}
        />
    ) : (
        <InnerItemsItemWidgetConfigurationEditorDisplay
            initiative={undefined}
            configuration={configuration}
            fieldDefinitions={itemInterfaceFieldDefinitions}
            workflowVersionId={workflowVersion.id}
            groupId={workflowVersion.groupId}
        />
    );
};

export default ModuleItemsItemWidgetConfigurationEditor;
