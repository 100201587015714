import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo } from 'react';

import getTrainingSetNlpActionBranchAsRow from './getTrainingSetNlpActionBranchAsRow';
import HistoryNlpInspectTabEmptyState from './HistoryNlpInspectTabEmptyState';
import TableChevronCell from './TableChevronCell';
import type { TabComponentProps } from '../../../entities/WorkerRunLogicInspectTabData';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Placeholder, SimpleErrorStateMessage, Table } from '@tonkean/infrastructure';
import type { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';
import { TableSize } from '@tonkean/tui-theme/sizes';
import utils, { range } from '@tonkean/utils';

const SingleWorkerRunActionInspectTrainingSetNlpAction: React.FC<
    TabComponentProps<WorkerRunLogicInspectTabType.TRAINING_SET_NLP>
> = ({ logic, branchResults, workerRun }) => {
    const consts = useAngularService('consts');
    const logicBlockTypes = consts.getLogicBlockTypes();
    const projectManager = useAngularService('projectManager');

    const [{ data, error, loading: modelSummariesLoading }, getTrainingSetModelSummariesByIds] = useLazyTonkeanService(
        'getTrainingSetModelSummariesByIds',
    );

    const modelIds = useMemo(() => {
        return branchResults.flatMap((branchResult) =>
            branchResult.modelResults.map((modelResult) => modelResult.trainingSetModelId),
        );
    }, [branchResults]);

    const availableChildBranches = useMemo(() => {
        return logic.impacts.filter((impact) => !impact.node.isHidden && !impact.node.disabled);
    }, [logic.impacts]);

    useEffect(() => {
        if (modelIds.length) {
            getTrainingSetModelSummariesByIds(projectManager.project.id, modelIds);
        }
    }, [getTrainingSetModelSummariesByIds, modelIds, projectManager.project.id]);

    const activatedBranchWorkerRunLogic = useMemo(() => {
        return workerRun.workerRunLogics?.find((entity) =>
            availableChildBranches.find((branch) => branch.node.id === entity.customTriggerId),
        );
    }, [availableChildBranches, workerRun.workerRunLogics]);

    const rows = useMemo(() => {
        if (modelSummariesLoading) {
            return range(3).map(() => {
                return {
                    chevron: <Placeholder $width="50%" $circle />,
                    branch: <Placeholder $width="100%" />,
                    status: <Placeholder $width="100%" />,
                    model: <Placeholder $width="100%" />,
                };
            });
        }

        return availableChildBranches
            .map((branchImpact) => {
                const branchResult = branchResults.find(
                    (branchResult) => branchResult.branchId === branchImpact.node.id,
                );

                const modelResultsOfBranchByModelId = branchResult
                    ? utils.createMapFromArray(branchResult.modelResults, 'trainingSetModelId')
                    : {};

                const trainingSetModelSummariesOfBranch =
                    data?.modelSummaries.filter((modelSummary) => modelResultsOfBranchByModelId[modelSummary.id]) || [];

                return getTrainingSetNlpActionBranchAsRow(
                    branchImpact,
                    logicBlockTypes,
                    trainingSetModelSummariesOfBranch,
                    branchResult,
                    activatedBranchWorkerRunLogic,
                );
            })
            .filter((row) => row);
    }, [
        activatedBranchWorkerRunLogic,
        availableChildBranches,
        branchResults,
        data?.modelSummaries,
        logicBlockTypes,
        modelSummariesLoading,
    ]);

    const columns = useMemo(
        () => [
            {
                accessor: 'chevron' as const,
                Cell: TableChevronCell,
            },
            {
                Header: 'Branch',
                accessor: 'branch' as const,
                width: '30%',
            },
            {
                Header: 'Status',
                accessor: 'status' as const,
                width: '40%',
            },
            {
                Header: 'Matched model',
                accessor: 'model' as const,
                width: '30%',
            },
        ],
        [],
    );

    const shouldShowEmptyState = availableChildBranches.length === 0;

    return (
        <>
            {error && <SimpleErrorStateMessage error="Error loading NLP action table" />}
            {shouldShowEmptyState && <HistoryNlpInspectTabEmptyState logic={logic} />}
            {!error && !shouldShowEmptyState && (
                <Table columns={columns} data={rows} size={TableSize.SMALL} expendableRows border />
            )}
        </>
    );
};

export default SingleWorkerRunActionInspectTrainingSetNlpAction;
