import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function BotSidePaneCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        isOpen: false,
        selectedTab: null,

        firstTimeLoadedTabMap: {}, // Tab name to did load for first time, used to load the tab data only when clicked, and only once.

        newActivitiesCount: 0,
        newInboxItemsCount: 0,
    };

    $scope.onTabHeaderClick = function (name) {
        $scope.data.firstTimeLoadedTabMap[name] = true;

        if ($scope.data.isOpen) {
            if (name === $scope.data.selectedTab) {
                $scope.data.selectedTab = null;
                $scope.setIsOpen(false);
            }
        } else {
            $scope.setIsOpen(true);
        }

        $scope.data.selectedTab = name;
    };

    $scope.onActivityCountUpdated = function (count) {
        $scope.data.newActivitiesCount = count;
    };

    $scope.onInboxNewItems = function (count) {
        $scope.data.newInboxItemsCount = count;
    };

    $scope.setIsOpen = function (value) {
        $scope.data.isOpen = value;
        ctrl.onSidepaneOpenChanged({ isOpen: $scope.data.isOpen });
    };
}

export default angular.module('tonkean.app').controller('BotSidePaneCtrl', lateConstructController(BotSidePaneCtrl));
