import { useAngularService } from 'angulareact';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';

const useLazyGetEntitiesFromPersonCache = () => {
    const personCache = useAngularService('personCache');
    const [{ data: people }, getEntitiesByEmailOrIds] = useLazyAsyncMethod(personCache, 'getEntitiesByEmailOrIds');
    return [people, getEntitiesByEmailOrIds];
};

export default useLazyGetEntitiesFromPersonCache;
