import type ImageInputType from '../entities/ImageInputType';

import type {
    Icon,
    TonkeanExpressionDefinition,
    TonkeanId,
    TonkeanType,
    WidgetConfiguration,
} from '@tonkean/tonkean-entities';

export enum SearchWidgetCardsDensity {
    SPACED = 'SPACED',
    COMPACT = 'COMPACT',
}

export const SEARCH_WIDGET_DEFAULT_CARDS_DENSITY = SearchWidgetCardsDensity.SPACED;

export enum SearchWidgetImagePlaceholderMode {
    NONE = 'NONE',
    INITIALS = 'INITIALS',
    IMAGE = 'IMAGE',
}
export const SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE = SearchWidgetImagePlaceholderMode.IMAGE;

export enum SearchWidgetSelectedResultMode {
    LIST_FIELD = 'LIST_FIELD',
    INNER_ITEMS = 'INNER_ITEMS',
}
export const SEARCH_WIDGET_DEFAULT_SELECTED_RESULT_MODE = SearchWidgetSelectedResultMode.LIST_FIELD;

export type SearchWidgetEditableResultFieldConfiguration = {
    fieldDefinitionId?: TonkeanId<TonkeanType.FIELD_DEFINITION>;
    label?: string;
    defaultValue?: string;
};

interface SearchWidgetConfiguration extends WidgetConfiguration {
    searchEntityProjectIntegrationId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    searchEntityApiName?: string;
    title?: TonkeanExpressionDefinition;
    description?: TonkeanExpressionDefinition;
    entityImageUrl?: TonkeanExpressionDefinition;
    tagText?: TonkeanExpressionDefinition;
    secondaryProperty?: TonkeanExpressionDefinition;

    searchInputPlaceholder?: TonkeanExpressionDefinition;
    noSearchStateIcon?: Icon;
    noSearchStateTitle?: TonkeanExpressionDefinition;
    noSearchStateDescription?: TonkeanExpressionDefinition;

    resultMode?: SearchWidgetSelectedResultMode;
    resultFieldDefinitionId?: TonkeanId<TonkeanType.FIELD_DEFINITION>;

    editableResultField?: SearchWidgetEditableResultFieldConfiguration;

    cardsDensity?: SearchWidgetCardsDensity;
    imagePlaceholderMode?: SearchWidgetImagePlaceholderMode;
    imageInputType?: ImageInputType;
    imageStreamSelectedActionId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>;
}

export default SearchWidgetConfiguration;
