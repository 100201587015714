import SmartsheetWorkspaceComponent from './SmartsheetWorkspaceComponent';
import smartsheetCircleImage from '../../../../apps/tracks/images/integrations/smartsheet-circle.svg';
import smartsheetImage from '../../../../apps/tracks/images/smartsheet.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

export default class SmartsheetWorkspaceIntegration extends IntegrationDefinitionBase {
    override name = 'smartsheetworkspace';
    override displayName = 'Smartsheet Workspace';
    override description = 'We will only collect folders and sheets.';
    override authenticationType = AuthenticationMethod.OAUTH;
    override supportsMultipleIntegrationPerUser = true;
    override customizedSetupComponent = SmartsheetWorkspaceComponent;
    override storageProviderSettings = new StorageProviderSettings(false, true);
    override showEntitiesInboundConfiguration = false;

    override entities = [new IntegrationEntity('Folder', 'Folders'), new IntegrationEntity('Sheet', 'Sheets')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(smartsheetCircleImage, '130px'),
        new IntegrationIcon(smartsheetImage, '130px'),
    );

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const redirectUri = environment.integrationKeysMap.smartsheetUri;
        const clientId = environment.integrationKeysMap.smartsheet;
        const permissionsScope = 'READ_SHEETS WRITE_SHEETS ADMIN_WEBHOOKS CREATE_SHEETS';
        const state = oAuthHandler.publicGetState();
        const url = `https://app.smartsheet.com/b/authorize?response_type=code&client_id=${clientId}&scope=${permissionsScope}&redirect_uri=${redirectUri}&state=${state}`;
        const code = await oAuthHandler.startOAuth(url, state);
        return { code, redirectUri };
    }
}
