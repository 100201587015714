import template from './emailConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkEmailConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',

        // Data
        savedOriginalSubjectExpression: '<',
        savedEvaluatedSubjectExpression: '<',

        savedOriginalBodyExpression: '<',
        savedEvaluatedBodyExpression: '<',

        sendAsPlainText: '<',

        // Callbacks
        onEmailSubjectTonkeanExpressionChanged: '&',
        onEmailBodyTonkeanExpressionChanged: '&',
        onSendAsPlainTextChanged: '&',
    },
    template,
    controller: 'EmailConfigurationCtrl',
});
