import httpRequestPreviewUtils from './httpRequestPreviewModalUtils';

function HttpRequestPreviewModalCtrl(
    $scope,
    requestThrottler,
    tonkeanService,
    utils,
    $uibModalInstance,
    groupId,
    options,
    exampleInitiative,
    projectManager,
) {
    $scope.data = {
        options,
        groupId,
        evaluatedExpressionLoading: false,
        exampleInitiative,
    };

    $scope.init = function () {
        const headers = angular.copy($scope.data.options.headers);
        // content type arrives in a separate member and should consider as header
        if ($scope.data.options.contentType) {
            headers.unshift({
                name: { originalExpression: 'content-type', evaluatedExpression: 'content-type' },
                value: {
                    originalExpression: $scope.data.options.contentType,
                    evaluatedExpression: $scope.data.options.contentType,
                },
            });
        }
        $scope.data.options.headers = headers;
        evaluateOptions();
        $scope.curl = $scope.data.options;
    };

    async function evaluateOptions() {
        const requestsToSevrerMap = {};

        // Url
        requestsToSevrerMap['url'] = options.url;
        // Body
        requestsToSevrerMap['body'] = options.body;
        // Headers
        for (let i = 0; i < options.headers.length; i++) {
            const header = options.headers[i];
            requestsToSevrerMap[header.name.evaluatedExpression] = header.name.evaluatedExpression;
            requestsToSevrerMap[header.value.evaluatedExpression] = header.value.evaluatedExpression;
        }

        const requestsToServer = [];
        for (const key in requestsToSevrerMap) {
            if (requestsToSevrerMap.hasOwnProperty(key)) {
                const value = requestsToSevrerMap[key];
                requestsToServer.push({ key, expression: value });
            }
        }

        if (requestsToServer && requestsToServer.length) {
            // Evaluating all expressions in server.
            const evaluatedInitiativeExpressionResult = await evaluateInitiativeExpressions(
                requestsToServer,
                $scope.data.exampleInitiative,
            );

            if (evaluatedInitiativeExpressionResult && evaluatedInitiativeExpressionResult.evaluatedExpressions) {
                const evaluatedExpressions = evaluatedInitiativeExpressionResult.evaluatedExpressions;
                // Url
                options.url = evaluatedExpressions['url'];
                // Body
                options.body = evaluatedExpressions['body'];
                // Headers
                for (let i = 0; i < options.headers.length; i++) {
                    const header = options.headers[i];
                    header.name.evaluatedExpression = evaluatedExpressions[header.name.evaluatedExpression];
                    header.value.evaluatedExpression = evaluatedExpressions[header.value.evaluatedExpression];
                }
            }
        }
    }

    /**
     * Evaluate Initiative expressions.
     */
    async function evaluateInitiativeExpressions(expressionsToEvaluate, initiative) {
        $scope.data.evaluatedExpressionLoading = true;
        $scope.data.errorEvaluatedExpression = false;

        try {
            return await tonkeanService.getEvaluatedExpressionsForInitiative(
                projectManager.project.id,
                initiative,
                expressionsToEvaluate,
                true,
            );
        } catch {
            $scope.data.errorEvaluatedExpression = true;
        } finally {
            $scope.data.evaluatedExpressionLoading = false;
        }
    }

    $scope.copyToClipboard = () => {
        const generatedCurl = httpRequestPreviewUtils.curlGenerator($scope.data.options);
        const copyResult = utils.copyToClipboardFromText(generatedCurl);
        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'CURL command copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = () => {
        $uibModalInstance.close();
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('HttpRequestPreviewModalCtrl', HttpRequestPreviewModalCtrl);
