import React from 'react';

import { StatusRow } from './DraggableStateRow';

import { Placeholder } from '@tonkean/infrastructure';

const StateLoadingRow: React.FC = () => {
    return (
        <StatusRow>
            <Placeholder $height="20px" $width="5%" />
            <Placeholder $height="20px" $width="15%" />
            <Placeholder $height="20px" $width="50%" />
            <Placeholder $height="20px" $width="25%" />
            <Placeholder $height="20px" $width="5%" />
        </StatusRow>
    );
};

export default StateLoadingRow;
