function IntervalPickerCtrl($scope) {
    if (!$scope.options) {
        $scope.options = {
            '1d': 'Daily',
            '1w': 'Weekly',
            '1M': 'Monthly',
        };
    }
    if (!$scope.interval) {
        $scope.interval = '1M';
    }

    $scope.isOpen = false;

    $scope.selectInterval = function (interval) {
        $scope.interval = interval;
        $scope.isOpen = false;
    };
}

export default angular.module('tonkean.app').controller('IntervalPickerCtrl', IntervalPickerCtrl);
