import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import type BaseProjectIntegrationEntityProps from './BaseProjectIntegrationEntityProps';
import { ProjectIntegrationEntityTabKey } from './ProjectIntegrationEntityTabKey';
import { ReactComponent as BackIcon } from '../../../images/icons/back.svg';
import type { AdditionalSidePaneConfiguration } from '../../modules/GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ProjectIntegrationEntityGenericSplitPageWithRequest from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntityGenericSplitPageWithRequest';
import HandleResponseActionType from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntitySchema/HandleResponseActionType';
import { ProjectIntegrationPaginatedActionConstantParams } from '../../modules/ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationPaginatedActionConstantParams';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { ActionParameterType } from '@tonkean/tonkean-entities';
import type { BaseActionParameter } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { IconButton } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div<{ marginRight: number; marginBottom: number }>`
    display: flex;

    height: 100%;
    width: 100%;
    margin-right: ${({ marginRight }) => `${marginRight}px`};
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};

    ${childrenStyledFocus};
`;

const SidePaneWrapper = styled.div<{ $color?: Color; showSeparator: boolean }>`
    padding: 24px 14px;
    width: 140px;
    flex-grow: 0;
    flex-shrink: 0;

    background-color: ${({ $color }) => $color ?? 'unset'};
    border-right: ${({ showSeparator }) => (showSeparator ? `1px solid ${Theme.colors.gray_300}` : 'none')};
`;

const BackIconButton = styled(IconButton)`
    margin-bottom: 28px;
    width: 75px;
`;

const ProjectIntegrationEntityEntityFetcherAction: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>
> = () => {
    const $state = useAngularService('$state');

    const [fetcherActionId, props, enterpriseComponentId, entityId] = useGetStateParams<
        [string, AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>, string, string]
    >('fetcherActionId', 'props', 'enterpriseComponentId', 'entityId');

    const OnPageFailedLoadingParams = useCallback(() => {
        $state.go('product.projectIntegrationPageEntity', {
            entityId,
            enterpriseComponentId,
            tab: ProjectIntegrationEntityTabKey.ENTITY_FETCHER,
        });
        return <></>;
    }, [$state, enterpriseComponentId, entityId]);

    const isFailedLoadingProps = useMemo(() => {
        return !props.projectIntegration || !props.entity;
    }, [props.entity, props.projectIntegration]);

    const actionParameters: BaseActionParameter[] = [
        {
            id: 'external_activity_id',
            displayName: 'Id',
            parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        },
        {
            id: ProjectIntegrationPaginatedActionConstantParams.LAST_COLLECT,
            displayName: 'Last Collect',
            parameterType: ActionParameterType.DATE,
        },
    ];

    const onClickBackButton = useCallback(() => {
        $state.go('product.projectIntegrationPageEntity', {
            entityId: props.entity.id,
            tab: ProjectIntegrationEntityTabKey.ENTITY_FETCHER,
            enterpriseComponentId: props.projectIntegration.id,
        });
    }, [$state, props.entity?.id, props.projectIntegration?.id]);

    return (
        <Wrapper marginRight={0} marginBottom={0}>
            {isFailedLoadingProps ? (
                <OnPageFailedLoadingParams />
            ) : (
                <>
                    <SidePaneWrapper showSeparator={false}>
                        <BackIconButton
                            leftIcon={<BackIcon />}
                            onClick={onClickBackButton} // Overrides clickableBack.onClick
                            data-automation="project-integration-entity-fetchers-back-button"
                        >
                            Back
                        </BackIconButton>
                    </SidePaneWrapper>
                    <ProjectIntegrationEntityGenericSplitPageWithRequest
                        projectIntegrationActionId={fetcherActionId}
                        httpRequestConfigurationHeaderText="Entity Fetcher"
                        additionalActionParameters={actionParameters}
                        requestUrlHeader="Entity Fetcher URL"
                        description={
                            <>
                                <div>
                                    Entity fetching is the mechanism by which Tonkean sends an API request to an
                                    application to get or update a single data source record for an entity,
                                </div>
                                <div>ensuring the data remains synced with the application.</div>
                            </>
                        }
                        isModuleParams={false}
                        handleResponseActionType={HandleResponseActionType.ENTITY_FETCHER}
                        shouldShowPredefinedParameters
                        isPaginatedRequest
                        useEntityFetcherBreadcrumbs
                        {...props}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default ProjectIntegrationEntityEntityFetcherAction;
