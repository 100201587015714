import React, { useCallback } from 'react';
import type { ChangeEventHandler } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import styled from 'styled-components';

import TaggableEntitiesItems from './TaggableEntitiesItems';
import taggableEntitiesStyle from './taggableEntitiesStyle';
import { useCommentContext } from '../../../comments/context';
import type { TaggableFieldDefinition } from '../../entities';

import { useInnerField, useResponsiveInputSize } from '@tonkean/infrastructure';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { Person, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const StyledMentionsInput = styled(MentionsInput)`
    width: 100%;
    background: white;
`;

const isEntityPerson = (entityMarkup: string): boolean => {
    const entityIdFirstIndex = entityMarkup.indexOf('(');
    const entityIdLastIndex = entityMarkup.indexOf(')');

    const entityId = entityMarkup.slice(entityIdFirstIndex + 1, entityIdLastIndex);
    return entityId?.startsWith('PRSN');
};

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>, DataAutomationSupportProps {
    size?: InputComponentSizes;
    mobileSize?: InputComponentSizes;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onMentionsChange?: (mentions: Person[]) => void;
    fields: TaggableFieldDefinition[];
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>;
    workflowVersionType: WorkflowVersionType;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    onCommentChange?: (comment: string) => void;
}

const TaggableEntitiesInput: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {
        type = 'text',
        size = InputSize.MEDIUM as InputComponentSizes,
        mobileSize = InputSize.XLARGE as InputComponentSizes,
        onChange,
        onMentionsChange,
        dataAutomation,
        fields,
        value,
        initiativeId,
        workflowVersionType,
        projectId,
        onCommentChange,
        ...props
    },
    ref,
) => {
    const [fieldProps] = useInnerField({ type, multiple: false, value, ...props });
    const inputSize = useResponsiveInputSize(size, mobileSize) as InputComponentSizes;

    const { fetchEntities, getTaggabaleEntityById } = useCommentContext();

    const isNotTaggableEntity = useCallback(
        (entityMarkup: string): boolean => {
            const entityIdFirstIndex = entityMarkup.indexOf('(');
            const entityIdLastIndex = entityMarkup.indexOf(')');

            const entityId = entityMarkup.slice(entityIdFirstIndex + 1, entityIdLastIndex);
            const entity = getTaggabaleEntityById(entityId);
            return entity ? !!entity?.isEmptyState || !!entity?.isTitle : false;
        },
        [getTaggabaleEntityById],
    );

    const onMentionsChangeHandler = useCallback(
        (event, newValue, newPlainTextValue, mentions) => {
            if (isNotTaggableEntity(newValue)) {
                return;
            }

            onCommentChange?.(newValue);
            if (isEntityPerson(newValue)) {
                onMentionsChange?.(mentions);
            }
        },
        [onMentionsChange, isNotTaggableEntity, onCommentChange],
    );

    return (
        <StyledMentionsInput
            type={type}
            $size={inputSize}
            {...props}
            {...fieldProps}
            inputRef={ref}
            style={taggableEntitiesStyle(false, false, InputSize.MEDIUM_LARGE)}
            onChange={onMentionsChangeHandler}
            data-automation={dataAutomation}
            singleLine
            allowSuggestionsAboveCursor
        >
            <Mention
                trigger="@"
                data={fetchEntities}
                markup="*@__display__*[](__id__)"
                displayTransform={(id, display) => `@${display}`}
                renderSuggestion={(entry, search, highlightedDisplay, index, focused) => {
                    return (
                        <TaggableEntitiesItems
                            entry={entry}
                            search={search}
                            highlightedDisplay={highlightedDisplay}
                            index={index}
                            focused={focused}
                            key={entry.id}
                        />
                    );
                }}
            />
        </StyledMentionsInput>
    );
};

export default React.forwardRef(TaggableEntitiesInput);
