import type { Field } from '../../../../infrastructure/components/FieldMapperTable/FieldMapperFieldRow';
import type { Section } from '../../../../infrastructure/components/FieldMapperTable/FieldMapperTable';

import type { ProjectIntegrationEntityFields } from '@tonkean/tonkean-entities';

const convertedDataForFieldMapperTable = (
    entitiesMetadata: Record<string, ProjectIntegrationEntityFields> | undefined,
) => {
    if (entitiesMetadata != undefined) {
        const convertedSections: Section[] = [];
        Object.keys(entitiesMetadata).forEach((entityId) => {
            const sectionFields: Field[] = [];
            const entityFields = entitiesMetadata[entityId]?.fields;
            if (entityFields) {
                Object.keys(entityFields).forEach((fieldName) => {
                    const field = {
                        name: fieldName,
                        isEditable: true,
                        highlightMark: entityFields[fieldName]?.isCustomField && 'Custom Field',
                    };
                    sectionFields.push(field);
                });
                convertedSections.push({
                    id: entityId,
                    name: entitiesMetadata[entityId]?.displayName || '',
                    fields: sectionFields,
                });
            }
        });

        return convertedSections;
    }
};

export default convertedDataForFieldMapperTable;
