import React, { useMemo } from 'react';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components';

import IconsCategorySection from './IconsCategorySection';

import { H3 } from '@tonkean/infrastructure';
import { NoSearchResultsIcon } from '@tonkean/svg';
import type { Icon, IconsByCategory } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const ROW_HEIGHT = 50;
const ICONS_IN_ROW = 7;
const TITLE_SPACE_SECTION_SIZE = 30;

const ListWrapper = styled.div<{ height: number }>`
    height: ${({ height }) => height}px;
    overflow-x: hidden !important;
    div {
        overflow-y: initial !important;
    }
    display: flex;
    flex-grow: 1;
`;

const NoSearchResults = styled(H3)`
    color: ${Theme.colors.gray_700};
`;

const SearchAgain = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-height: 18px;
    color: ${Theme.colors.gray_700};
`;

const EmptyState = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 150px;
    padding: 24px;
`;

interface Props {
    iconsByCategories: IconsByCategory[];
    showCategories?: boolean;
    onChangeIcon: (icon: Icon) => void;
    width: number;
    height: number;
    selectedIcon: Icon | undefined;
    searchTerm?: string;
    color?: string;
}

const IconsPickerContent: React.FC<Props> = ({
    iconsByCategories,
    showCategories = true,
    onChangeIcon,
    width,
    height,
    selectedIcon,
    searchTerm,
    color,
}) => {
    const sectionData = useMemo(
        () => ({
            icons: iconsByCategories,
            showCategories,
            onChangeIcon,
            width,
            height,
            selectedIcon,
            color,
        }),
        [iconsByCategories, showCategories, onChangeIcon, width, height, selectedIcon, color],
    );

    const getSectionSize = (index) => {
        const numOfIconsInSection = sectionData?.icons?.[index]?.icons?.length;
        const numOfRows = numOfIconsInSection && Math.ceil(numOfIconsInSection / ICONS_IN_ROW);
        //  calculate every section size by number of rows
        const categorySize = numOfIconsInSection && numOfRows ? numOfRows * ROW_HEIGHT : 0;
        return categorySize + TITLE_SPACE_SECTION_SIZE;
    };

    const showEmptyState = useMemo(() => {
        return iconsByCategories?.every((category) => {
            if (category?.icons?.length > 0) {
                return false;
            }
            return true;
        });
    }, [iconsByCategories]);

    return (
        <>
            {showEmptyState ? (
                <EmptyState>
                    <NoSearchResultsIcon />
                    <NoSearchResults $bold>No Results for ‘{searchTerm}’</NoSearchResults>
                    <SearchAgain>Try to refine your search</SearchAgain>
                </EmptyState>
            ) : (
                <ListWrapper height={height}>
                    {iconsByCategories?.length > 1 ? (
                        <VariableSizeList
                            height={height}
                            width="100%"
                            itemCount={iconsByCategories.length}
                            estimatedItemSize={200}
                            itemSize={getSectionSize}
                            itemData={sectionData}
                            itemKey={(index, data) => data.icons[index]?.label || 1}
                        >
                            {IconsCategorySection}
                        </VariableSizeList>
                    ) : (
                        iconsByCategories.map((icon, index) => (
                            <IconsCategorySection data={sectionData} index={index} key={icon.label} style={{}} />
                        ))
                    )}
                </ListWrapper>
            )}
        </>
    );
};

export default IconsPickerContent;
