import type {
    IDeferred,
    IIntervalService,
    ILocationService,
    ILogService,
    IPromise,
    IQService,
    IRootScopeService,
    ITimeoutService,
    IWindowService,
} from 'angular';
import type { UtilsService } from '@tonkean/shared-services';
import type { Environment } from '@tonkean/shared-services';
import type { AngularServices } from 'angulareact';
import { bindThis } from '@tonkean/utils';
import { POPULATED_FAILED } from '@tonkean/constants';
import getIntegrationKeyMapValue from './utils/getIntegrationKeyMapValue';

class OAuthHandler {
    public static $inject: (string & keyof AngularServices)[] = [
        '$window',
        '$rootScope',
        '$location',
        '$timeout',
        '$interval',
        '$q',
        'utils',
        '$log',
        'environment',
    ];

    private instances: Record<
        string,
        { popup?: Window | null; timer?: Promise<any> | IPromise<any>; deferred: IDeferred<unknown> }
    > = {};

    protected constructor(
        private $window: IWindowService,
        private $rootScope: Record<string, any> & IRootScopeService,
        private $location: ILocationService,
        private $timeout: ITimeoutService,
        private $interval: IIntervalService,
        private $q: IQService,
        private utils: UtilsService,
        private $log: ILogService,
        private environment: Environment,
    ) {
        this.watchForHashChangesInUrl();

        $window.oauthCallback = this.oauthCallback;
        $window.clearPopup = this.clearPopup;
    }

    @bindThis
    public github(clientId, scope) {
        const state = this.getState();
        const url = `https://github.com/login/oauth/authorize?client_id=${clientId}&scope=${scope}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public bitBucket(clientId) {
        const state = this.getState();
        const url = `https://bitbucket.org/site/oauth2/authorize?client_id=${clientId}&response_type=code&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public zohoCRM(clientId, scope, redirectUri) {
        const state = this.getState();
        const url =
            `https://accounts.zoho.com/oauth/v2/auth?scope=${scope}&client_id=${clientId}&response_type=code` +
            `&access_type=offline&redirect_uri=${redirectUri}&state=${state}&prompt=consent`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public zendesk(subdomain, clientId, scope, redirectUri) {
        const state = this.getState();
        const url = `https://${subdomain}.zendesk.com/oauth/authorizations/new?response_type=code&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public hubspot(clientId, scope, redirectUri, state) {
        const encodedRedirectUri = encodeURIComponent(redirectUri);
        const url = `https://app.hubspot.com/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${encodedRedirectUri}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public wrike(clientId, redirectUri, state) {
        const url = `https://www.wrike.com/oauth2/authorize?client_id=${clientId}&response_type=code&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public trello(clientId, redirectUri) {
        const state = this.getState();
        const url =
            `https://trello.com/1/authorize?expiration=never&name=Tonkean&callback_method=fragment&response_type=token&key=${clientId}&scope=read,write` +
            `&redirect_uri=${redirectUri}?state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public asana(clientId, redirectUri) {
        const state = this.getState();
        const url = `https://app.asana.com/-/oauth_authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${state}`;
        return this.startOAuth(url, state);
    }
    @bindThis
    public namely(clientId, redirectUri) {
        const state = this.getState();
        const url = `https://acme-sandbox.namely.com/api/v1/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public wunderlist(clientId, redirectUri) {
        const state = this.getState();
        const url = `https://www.wunderlist.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public salesforce(clientId, redirectUri, authSubdomain) {
        const state = this.getState();
        const url = `https://${authSubdomain}.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public harvest(clientId, redirectUri) {
        const state = this.getState();
        const url = `https://api.harvestapp.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public stripeapp(clientId) {
        const state = this.getState();
        const url = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_only&client_id=${clientId}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public slack(clientId, scope, userScope, v2) {
        const state = this.getState();
        const redirectUri = this.environment.defaultRedirectUri;
        const url = v2
            ? `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scope}&user_scope=${userScope}&redirect_uri=${redirectUri}&state=${state}`
            : `https://slack.com/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public microsoftTeams() {
        // We don't really do oAuth with microsoft teams.
        // The user goes to the market place and connects Tonkean, then gets back to us and tells us he did it.
        const url = 'https://appsource.microsoft.com/en-us/product/office/WA104381749?tab=Overview';
        this.$window.open(url, '_blank');
        return this.$q.resolve();
    }

    @bindThis
    public google(clientId, scope, redirectUri, offline) {
        const state = this.getState();
        let url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&prompt=consent&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;

        if (offline) {
            url = `${url}&access_type=offline`;
        }
        // scope = 'profile%20email'
        return this.startOAuth(url, state);
    }

    @bindThis
    public pingIdentity(authUrl, authSuffix, clientId, redirectUri, redirectAfterAuth, shouldRedirectToNewPage) {
        let state = `${this.getState()}_ping_${authUrl}`;
        if (redirectAfterAuth) {
            state = `${state}_redirectAfterAuth_${redirectAfterAuth}`;
        }
        let url = `${authUrl}/${authSuffix}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;

        return this.startOAuth(url, state, shouldRedirectToNewPage);
    }

    @bindThis
    public dropbox(clientId, redirectUri) {
        const state = this.getState();
        const url = `https://www.dropbox.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;

        return this.startOAuth(url, state);
    }

    @bindThis
    public microsoft(scope, redirectUri, offline) {
        let state = 'com_msft'; // getState()

        if (offline) {
            state = this.getState();
        }
        let clientId = getIntegrationKeyMapValue('microsoft', this.$rootScope);
        let tenant = getIntegrationKeyMapValue('microsoftTenant', this.$rootScope);
        let authUrl = getIntegrationKeyMapValue('microsoftAuthUri', this.$rootScope);

        const url = `${authUrl}/${tenant}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&state=${state}&scope=${scope}`;

        return this.startOAuth(url, state);
    }

    @bindThis
    public dynamics(clientId, resource, redirectUri) {
        const state = this.getState();

        const url = `https://login.windows.net/common/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&state=${state}&resource=${resource}`;

        return this.startOAuth(url, state);
    }

    @bindThis
    public basecamp(clientId, redirectUri) {
        const state = this.getState();
        const url = `https://launchpad.37signals.com/authorization/new?type=web_server&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public smartsheet(clientId, scope, redirectUri) {
        const state = this.getState();
        const url = `https://app.smartsheet.com/b/authorize?response_type=code&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public intercom(clientId, redirectUri) {
        const state = this.getState();
        const url =
            `https://app.intercom.io/oauth?client_id=${clientId}&scope=read` +
            `&redirect_uri=${redirectUri}&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public facebookads(clientId, redirectUri) {
        const state = this.getState();
        const url =
            `https://www.facebook.com/v13.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=ads_management` +
            `&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public mavenlink(clientId: string, redirectUri: string) {
        const state = this.getState();
        const url = `https://app.mavenlink.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public okta(
        clientId: string,
        redirectUri: string,
        oktaUrl: string,
        scope: string,
        shouldRedirectToNewPage,
        redirectAfterAuth,
    ) {
        let state = `${this.getState()}_okta_${oktaUrl}`;
        if (redirectAfterAuth) {
            state = `${state}_redirectAfterAuth_${redirectAfterAuth}`;
        }
        const url = `https://${oktaUrl}/oauth2/v1/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}&response_type=code`;
        return this.startOAuth(url, state, shouldRedirectToNewPage);
    }

    @bindThis
    public servicenow(clientId: string, redirectUri: string, subdomain: string) {
        const state = this.getState();
        const url = `https://${subdomain}.service-now.com/oauth_auth.do?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${state}`;
        return this.startOAuth(url, state);
    }

    @bindThis
    public publicGetState() {
        return this.getState();
    }

    private getState() {
        let state = this.utils.guid();

        if (!this.environment.isProd) {
            state += 'testing';
        }

        if (this.$rootScope.health && this.$rootScope.health.env === 'local') {
            state += 'devCollector';
        }

        return state;
    }

    private getPopupName() {
        const name = 'Log in for Tonkean';

        if (this.environment.isProd) {
            return name;
        }

        // We are using the default environment and not the current one because it's used for notifying the app from
        // oauth.html, so it needs to know to redirect to the real url.
        const environmentName = this.utils.capitalize(this.environment.config.defaultEnvironment);
        return `${name} - ${environmentName}`;
    }

    @bindThis
    public startOAuth(url: string, id: string, shouldRedirectToNewPage?: boolean) {
        const deferred = this.$q.defer();

        if (!!shouldRedirectToNewPage) {
            location.assign(url);
            return deferred.promise;
        } else if (this.$rootScope.mobileApp) {
            this.instances[id] = {
                deferred,
            };

            // ask it to handle the popup
            if (window['tnkAndroid'] && window['tnkAndroid'].postMessage) {
                // means it's android
                window['tnkAndroid'].postMessage(url);
                /* jshint ignore:line */
            } else {
                // means it's ios
                window['webkit'].messageHandlers.callbackHandler.postMessage(url);
                /* jshint ignore:line */
            }

            return deferred.promise;
        } else {
            const popupName = this.getPopupName();
            const popup = this.utils.popupWindow(url, popupName, 1000, 630);
            if (!popup) {
                throw POPULATED_FAILED;
            }
            const timer = this.$interval(() => this.checkPopupExist(id), 1000, 0, true);
            this.instances[id] = {
                popup,
                timer,
                deferred,
            };

            return deferred.promise;
        }
    }

    private checkPopupExist(id: string) {
        const popup = this.instances[id]?.popup;

        if (!popup || popup.closed) {
            // Some integrations don't have opener so we check if our oauth.html code set an the href recieved from the integration.
            if (popup && popup['authLocationString']) {
                // Run this code not in a digest loop (third param false). Because it uses $apply.
                this.$timeout(() => this.$window.oauthCallback(popup['authLocationString']), 0, false);
                return;
            }

            this.onPopupClosed(id);
        }
    }

    private onPopupClosed(id: string) {
        this.$log.warn('oAuth popup does not exist anymore...');
        this.instances[id]?.deferred.reject('Authentication window closed');
        this.clearPopup(id);
    }

    @bindThis
    public oauthCallback(url: string) {
        this.$rootScope.$apply(() => {
            const params = this.utils.parseParams(url);
            const id = params.state?.toString();

            const instance = id && this.instances[id];
            if (id && instance) {
                const refreshTokenField = 'refresh_token';

                if (params.code) {
                    instance.deferred.resolve(params.code);
                } else if (params.token) {
                    instance.deferred.resolve(params.token);
                } else if (params[refreshTokenField]) {
                    instance.deferred.resolve(params[refreshTokenField]);
                } else if (params.error) {
                    instance.deferred.reject(params.error);
                } else {
                    instance.deferred.reject('No code provided');
                }
                this.clearPopup(id);
            } else {
                this.$log.warn('oauthCallback was called but not expected', url);
            }
        });
    }

    @bindThis
    public clearPopup(id: string) {
        const timer = this.instances[id]?.timer;
        if (timer) {
            this.$interval.cancel(timer);
        }

        this.instances[id]?.deferred.reject('oAuth Failed');
        delete this.instances[id];
    }

    private watchForHashChangesInUrl() {
        this.$rootScope.$watchCollection(
            () => {
                return this.$location.hash();
            },
            () => {
                // using the hash to pass back the code from the mobile app
                const oauthKey = 'oauthOk:';
                const closedKey = 'oauthClose:';

                if (this.$location.hash()) {
                    const hash = this.$location.hash();
                    if (hash.startsWith(oauthKey)) {
                        this.$timeout(() => {
                            this.$window.location.hash = '';
                            this.$window.location.assign(
                                this.$window.location.href.slice(Math.max(0, this.$window.location.href.length - 1)),
                            );
                            this.$window.oauthCallback(hash.slice(oauthKey.length));
                        });
                    } else if (hash.startsWith(closedKey)) {
                        const splits = hash.split('state=');
                        const code = splits[splits.length - 1];
                        this.$timeout(() => {
                            this.$window.location.hash = '';
                            this.$window.location.assign(
                                this.$window.location.href.slice(Math.max(0, this.$window.location.href.length - 1)),
                            );
                            this.$window.clearPopup(code);
                        });
                    }
                }
            },
        );
    }

    @bindThis
    public async generatePKCEPair() {
        const verifierLength = 64;
        const verifier = await this.generateVerifier(verifierLength);
        const challenge = await this.generateChallenge(verifier);
        return { verifier, challenge };
    }

    private encodeAndCleanPKCE(buffer: Uint8Array): string {
        return btoa(String.fromCharCode.apply(null, buffer)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

    private async generateVerifier(length: number): Promise<string> {
        const randomBytes = new Uint8Array(length);
        window.crypto.getRandomValues(randomBytes);
        return this.encodeAndCleanPKCE(randomBytes);
    }

    private async generateChallenge(verifier: string): Promise<string> {
        const verifierBuffer = new TextEncoder().encode(verifier);
        const hashedVerifier = await window.crypto.subtle.digest('SHA-256', verifierBuffer);
        return this.encodeAndCleanPKCE(new Uint8Array(hashedVerifier));
    }
}

export default OAuthHandler;

angular.module('tonkean.shared').service('oauthHandler', OAuthHandler);
