import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import FormulaInnerRoundedBox from './FormulaInnerRoundedBox';
import FormulaContext from '../../entities/FormulaContext';
import HighlightedNodesSource from '../../entities/HighlightedNodesSource';

import type { FormulaFieldsMetadata } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';

export const Comma = styled.div.attrs({ children: ',' })<{ faded: boolean }>`
    padding: 0 4px;
    color: #4a1b79;
    font-weight: bold;
    line-height: 28px;
    width: 11px;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 9999999;

    ${({ faded }) =>
        faded &&
        css`
            opacity: 0.5;
        `}
`;

export const Plus = styled.button.attrs({ type: 'button' })<{ onlyOnHover?: boolean }>`
    width: 0;
    height: 28px;
    position: relative;
    border: 0;
    padding: 0;
    margin: 0;
    z-index: 9999999;
    outline: none;

    ::after {
        content: '+';
        position: absolute;
        top: 0;
        left: -14px;
        width: 28px;
        height: 28px;
        color: #fff;
        background: #3fa7b8;
        border-radius: 100%;
        border: 4px solid white;
        opacity: ${({ onlyOnHover = false }) => (onlyOnHover ? 0 : 0.7)};
        line-height: 20px;
        text-align: center;
        transition: 0.1s opacity;
    }

    &:hover::after,
    &:focus::after {
        opacity: 1;
    }
`;

interface Props {
    /** The operator in the formula */
    operand: formulaTreeNode;
    depth: number;
    fieldsMetadata: FormulaFieldsMetadata;
    disabled: boolean;
    onAdd: (index: number) => void;
}

const FormulaOperatorWrapper: React.FC<React.PropsWithChildren<Props>> = ({
    operand,
    depth,
    fieldsMetadata,
    disabled,
    children,
    onAdd,
}) => {
    const { highlightedNodes, setHighlightedNodes } = useContext(FormulaContext);

    const markAsActive = () => {
        if (disabled) {
            return;
        }

        setHighlightedNodes({ source: HighlightedNodesSource.HOVER, arrayField: fieldsMetadata.arrayField });
    };

    const unmarkAsActive = () => {
        if (
            highlightedNodes?.source === HighlightedNodesSource.HOVER &&
            highlightedNodes.arrayField === fieldsMetadata.arrayField
        ) {
            setHighlightedNodes(undefined);
        }
    };

    const metadata = operand.field.metadata;

    return (
        <>
            {metadata.fieldDefinitionType === FieldDefinitionType.ARRAY ? (
                <>
                    {metadata.firstInTuple && !disabled && (
                        <Plus type="button" onClick={() => onAdd(operand.field.metadata.inOperandIndex)} onlyOnHover />
                    )}

                    {children}

                    {(metadata.lastInArray || metadata.lastInTuple) && !disabled && (
                        <Plus
                            type="button"
                            onClick={() => onAdd(operand.field.metadata.inOperandIndex + 1)}
                            onlyOnHover
                        />
                    )}
                    {metadata.lastInTuple && !metadata.lastInArray && (
                        <FormulaInnerRoundedBox
                            depth={depth}
                            onMouseEnter={markAsActive}
                            onMouseLeave={unmarkAsActive}
                            faded={
                                disabled ||
                                (highlightedNodes && highlightedNodes.arrayField !== fieldsMetadata.arrayField)
                            }
                            nodeType={FormulaTreeNodeType.ARRAY}
                            compact
                            right
                            left
                        >
                            ,
                        </FormulaInnerRoundedBox>
                    )}
                    {!metadata.lastInOperand && !metadata.lastInTuple && (
                        <Comma faded={disabled || !!highlightedNodes} />
                    )}
                </>
            ) : (
                <>
                    {children}

                    {!metadata.lastInOperand && <Comma faded={disabled || !!highlightedNodes} />}
                </>
            )}
        </>
    );
};

export default FormulaOperatorWrapper;
