function ChangeGroupsWorkflowFolderCtrl(
    $timeout,
    $scope,
    projectManager,
    groupId,
    workflowFolderManager,
    originWorkflowFolderId,
) {
    const ctrl = this;
    $scope.wfm = workflowFolderManager;
    $scope.data = {
        workflowFolderName: '',
        groupId,
        group: projectManager.groupsMap[groupId],
        selectedFolder: null,
        loading: false,
        error: null,
        originWorkflowFolderId,
        workflowFolderCategoryId: undefined,
        projectId: projectManager.project.id,
        // list of all folders (solutions) that we dont allow to move to them.
        folderIdsToHide: [],
    };

    ctrl.$onInit = function () {
        $scope.data.folderIdsToHide = [];
    };

    $scope.cancel = function () {
        $scope.$dismiss();
    };

    $scope.move = function () {
        $scope.data.loadingState = true;
        workflowFolderManager
            .moveGroupToWorkflowFolder(
                projectManager.project.id,
                groupId,
                originWorkflowFolderId,
                $scope.data.selectedFolder.id,
                $scope.data.workflowFolderCategoryId,
            )
            .then(() => {
                $scope.$emit('alert', {
                    type: 'success',
                    msg: `Module "${$scope.data.group.name}" has successfully moved to Solution "${$scope.data.selectedFolder.displayName}".`,
                });
                $scope.$close(true);
            })
            .catch((error) => {
                $scope.data.error = error?.data || error;
            })
            .finally(() => {
                $scope.data.loading = false;
            });
    };

    $scope.onFolderSelected = function (folder) {
        // Using $timeout to avoid digest cycle error instead of $scope.$apply
        // to avoid running digest cycle twice, which results in an error.
        $timeout(() => {
            $scope.data.selectedFolder = folder;
            $scope.data.workflowFolderCategoryId = undefined;
        });
    };

    $scope.onWorkflowFolderCategorySelected = function (categoryId) {
        // Using $timeout to avoid digest cycle error instead of $scope.$apply
        // to avoid running digest cycle twice, which results in an error.
        $timeout(() => {
            $scope.data.workflowFolderCategoryId = categoryId
        })
    };
}

export default angular.module('tonkean.app').controller('ChangeGroupsWorkflowFolderCtrl', ChangeGroupsWorkflowFolderCtrl);
