import AutonomousFieldChangedLogicBlockConfig from './AutonomousFieldChangedLogicBlockConfig';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const InnerAutonomousFieldChangedLogicBlockConfig: LogicBlockConfigDefinition = {
    ...AutonomousFieldChangedLogicBlockConfig,
    type: LogicBlockConfigType.INNER_AUTONOMOUS,
};

export default InnerAutonomousFieldChangedLogicBlockConfig;
