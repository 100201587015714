import constructHistoryTableGroup from './constructHistoryTableGroup';
import getHistoryMatchedEntityFieldName from './getHistoryMatchedEntityFieldName';
import type HistoryTableGroup from './HistoryTableGroup';
import type HistoryField from '../entities/HistoryField';

/**
 * Create {@link HistoryTableGroup} for each matched entity and return a map with the relation field id as key
 * and the group as the value.
 *
 * @param fields - list of history fields.
 * @param workflowVersionId - the workflow version id.
 * @param customTriggerManager - angular's custom trigger manager.
 * @returns object with the relation field id as key and it's history table group as value.
 */
function getMatchedEntityToFieldsGroupMap(
    fields: HistoryField[],
    workflowVersionId: string | undefined,
    customTriggerManager,
): Record<string, HistoryTableGroup> {
    const relationFieldsIdAndFieldGroupPairs = fields
        .filter((tableField) => tableField.isMatchedEntity)
        .map((tableField) => {
            const matchedEntityFieldsGroup = constructHistoryTableGroup(
                tableField.id,
                getHistoryMatchedEntityFieldName(tableField, workflowVersionId, customTriggerManager),
                tableField.type,
                tableField.projectIntegrationId,
                tableField.integrationType,
                tableField.fieldDefinition?.isForMatchedItem === true ? 'mod-match' : undefined,
            );

            return [tableField.relationFieldId || tableField.id, matchedEntityFieldsGroup];
        });

    return Object.fromEntries(relationFieldsIdAndFieldGroupPairs);
}

export default getMatchedEntityToFieldsGroupMap;
