<!-- Cover with absolute because not directly in modal and can be in it's own page-->
<div class="setup-group-new" ng-class="{ 'absolute-fill-view': !data.disableTabNavigation }">
    <div class="common-close-btn-container common-height-full clearfix">
        <!-- Logo to go back to dashboard if not a modal -->
        <div
            class="hidden-xs main-logo-sm pointer fixed-top-left"
            ng-if="::(!isModal && !isInline && !isListGallery)"
            ng-hide="data.selectedList"
            ui-sref="product"
        >
            <tnk-icon src="/images/icons/tonkean.svg"></tnk-icon>
        </div>

        <!-- Close button -->
        <div
            class="common-close-btn svg-icon-lg margin-normal-sm"
            ng-if="isModal"
            ng-click="$dismiss()"
            ng-hide="data.selectedList"
        >
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </div>

        <!-- Close button that sends the user back to live reports when not in modal but in inline mod (selecting bot) -->
        <div
            class="common-close-btn svg-icon-lg margin-normal-sm"
            ng-if="!isModal && !data.disableTabNavigation"
            ui-sref="product.board({filter: 'all'})"
            ng-hide="data.selectedList"
        >
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </div>

        <!-- Back -->
        <div
            class="absolute-left pointer padding-normal setup-group-modal-back-button"
            ng-if="data.selectedList && !emptyOnly"
            ng-hide="
                (data.selectedList.id === 'DUPLICATE' || data.selectedList.isExampleList) && data.currentStepIndex === 0
            "
            ng-click="previousStep()"
        >
            <i class="fa fa-chevron-left margin-right"></i>
            <span>Back</span>
        </div>

        <div
            class="setup-group-built-in-left-bar flex-no-shrink"
            ng-if="
                !data.disableTabNavigation &&
                (!data.selectedList || data.selectedList.isExampleList) &&
                !data.autoCreateList &&
                !data.loadingTemplates
            "
            ng-class="{ 'hidden-xs': isInline }"
        >
            <!--tabs-->
            <div class="setup-group-built-in-tabs-bar flex common-color-dark-grey mod-inline flex-xs-wrap">
                <a
                    class="setup-group-tab-btn text-left btn btn-default margin-right margin-bottom common-bold"
                    ng-class="{ 'groups-selected-option': !data.selectedTab }"
                    ng-click="selectTab(null)"
                >
                    All
                </a>
                <a
                    ng-if="isInline && !isListGallery"
                    class="setup-group-tab-btn text-left btn btn-default margin-right margin-bottom common-bold"
                    ng-class="{
                        'groups-selected-option': data.selectedTab && data.selectedTab.id === 'SHARED_TEMPLATES'
                    }"
                    ng-click="selectTab({id:'SHARED_TEMPLATES'})"
                >
                    Reusable Components
                </a>
                <a
                    ng-repeat="cat in categoriesList"
                    class="setup-group-tab-btn text-left btn btn-default margin-right margin-bottom common-bold"
                    ng-class="{ 'groups-selected-option': data.selectedTab && data.selectedTab.id === cat.id }"
                    ng-click="selectTab(cat)"
                    ng-if="categoryIdToTemplateMapping[cat.id].length"
                >
                    <div>{{ cat.title }}</div>
                </a>
            </div>
        </div>

        <div class="setup-group-built-in-left-bar flex-no-shrink" ng-if="data.loadingTemplates">
            <div class="setup-group-built-in-tabs-bar flex common-color-dark-grey mod-inline flex-xs-wrap">
                <span class="loading-medium"></span>
            </div>
        </div>

        <!-- Select list -->
        <div
            class="setup-group-modal-select-list text-left"
            ng-class="{ 'align-left': data.disableTabNavigation }"
            ng-if="!data.selectedList || data.selectedList.isExampleList"
        >
            <div
                ng-hide="data.selectedList"
                class="padding-bottom-lg flex-vmiddle flex-wrap-no-xs flex-xs-col margin-top-lg margin-bottom-lg"
            >
                <div ng-if="!data.hideTitle" class="flex-grow margin-right-lg">
                    <h1 class="mod-no-top">Module Templates</h1>
                    <p>
                        Add a ready to go Module from our built-in templates. Just connect the relevant data sources and
                        turn it on, or customize it to your needs.
                    </p>
                </div>

                <div class="flex common-width-full">
                    <div class="rounded-box flex-vmiddle setup-group-built-in-search flex-grow">
                        <span class="svg-icon-sm margin-left">
                            <tnk-icon src="/images/icons/search.svg"></tnk-icon>
                        </span>
                        <input
                            data-automation="set-up-group-new-search-input"
                            class="form-control common-width-full common-no-border common-no-outline"
                            placeholder="Search..."
                            ng-model="data.searchTerm"
                            ng-change="data.selectedList = null"
                        />
                    </div>
                    <tnk-template-configuration-modal-trigger
                        ng-if="
                            data.isUserAuthenticated && data.selectedTab && data.selectedTab.id === 'SHARED_TEMPLATES'
                        "
                        on-close="(closeTemplateModal)"
                    ></tnk-template-configuration-modal-trigger>
                </div>
            </div>

            <div
                ng-if="data.searchTerm && data.searchTerm.length"
                class="
                    alert alert-info
                    common-size-xxxxs common-width-50
                    padding-normal-sm
                    margin-bottom-lg
                    flex-vmiddle
                "
            >
                <span class="flex-grow">Showing lists related to "{{ data.searchTerm }}".</span>
                <a ng-click="data.searchTerm = null">
                    <i class="fa fa-times margin-right-xs inline-block"></i>
                    Clear search
                </a>
            </div>

            <!-- List gallery -->
            <div ng-if="!data.selectedList && !data.enterCustomJson">
                <div class="flex-grow setup-group-built-in-gallery-container text-left margin-top-lg">
                    <p class="margin-left" ng-hide="data.searchTerm && data.searchTerm.length">
                        <strong>{{ data.selectedTab.title }}</strong>
                    </p>
                    <!--options-->
                    <div class="setup-group-built-in-gallery flex flex-wrap">
                        <div ng-if="data.loadingTemplates" class="flex flex-col">
                            <span class="loading-large"></span>
                        </div>
                        <div
                            class="setup-group-built-in-item flex flex-col"
                            ng-repeat="list in data.idToTemplateMap"
                            ng-mouseenter="list.hover = true"
                            ng-mouseleave="list.hover = false"
                            ng-class="{ 'border-color-purple': pm.project.id === list.projectId }"
                            ng-if="shouldShowList(list)"
                            ng-click="selectList(list)"
                        >
                            <div
                                class="relative setup-group-built-in-header common-bg flex-vmiddle {{ list.headerCss }}"
                            >
                                <div
                                    ng-if="list.category"
                                    class="common-circle mod-full common-color-white opacity-90"
                                    ng-style="{ background: list.mainColor }"
                                >
                                    <i class="fa {{ list.category.symbol }}"></i>
                                </div>

                                <!-- Delete Template -->
                                <div
                                    class="delete-template-icon"
                                    ng-if="as.currentUser.id === list.creator.id && list.project"
                                    ng-disabled="data.deleteTemplateButtonClicked"
                                    uib-tooltip="Delete Template"
                                    tooltip-append-to-body="true"
                                    apped
                                    ng-click="deleteCompanyTemplate($event, list.id)"
                                >
                                    <span class="svg-icon-smd">
                                        <tnk-icon src="/images/icons/delete.svg"></tnk-icon>
                                    </span>
                                </div>
                            </div>
                            <div class="padding-normal">
                                <div class="flex mod-justify-center">
                                    <tnk-icon
                                        class="svg-icon-18 margin-right-xxs"
                                        ng-if="pm.project.id && pm.project.id === list.projectId"
                                        src="/images/icons/template/shared-template.svg"
                                    ></tnk-icon>
                                    <div class="common-size-xs flex-grow">
                                        <strong
                                            ng-class="{
                                                'common-color-purple': pm.project.id && pm.project.id === list.projectId
                                            }"
                                        >
                                            {{ list.title }}
                                        </strong>
                                    </div>
                                    <div
                                        ng-if="list.versionType === 'DRAFT'"
                                        class="draft-template-title common-size-l"
                                    >
                                        Draft
                                    </div>
                                </div>

                                <p class="common-size-xxxxs common-ellipsis">
                                    {{ list.longDescription || list.description }}
                                </p>

                                <div ng-if="workersTemplateMode">
                                    <!-- Integrations Icons and arrow -->
                                    <div class="flex-vmiddle flex-xs-wrap">
                                        <!-- Tonkean logo (when there are no integrations) -->
                                        <span class="workers-gallery-icon-container flex-vmiddle mod-justify-center">
                                            <span class="svg-icon svg-icon-lg mod-static padding-left-xxs">
                                                <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                                            </span>
                                        </span>

                                        <!-- Arrow -->
                                        <span class="svg-icon-xxxlg">
                                            <tnk-icon src="/images/icons/arrow-right-lg.svg"></tnk-icon>
                                        </span>

                                        <!-- Integrations icons -->
                                        <span
                                            ng-repeat="source in list.requiredIntegrations | limitTo:3 track by $index"
                                            class="workers-gallery-icon-container mod-big-icon mod-margin-right-minus"
                                            uib-tooltip="{{ source.toLowerCase() }}"
                                            tooltip-placement="bottom"
                                            ng-style="{ zIndex: 100 - $index }"
                                        >
                                            <span
                                                class="inline-block initiative-integration-icon common-radius-50 mod-{{
                                                    source.toLowerCase()
                                                }}"
                                            ></span>
                                        </span>

                                        <!-- Bullet List -->
                                        <span
                                            class="workers-gallery-icon-container flex-vmiddle mod-justify-center"
                                            ng-if="!list.requiredIntegrations || list.requiredIntegrations.length === 0"
                                            ng-style="{ zIndex: 2 }"
                                        >
                                            <span
                                                class="
                                                    svg-icon-nav-sm svg-icon-hover-black
                                                    pointer
                                                    inline-block
                                                    common-color-grey3
                                                "
                                            >
                                                <tnk-icon src="/images/icons/bullet-list.svg"></tnk-icon>
                                            </span>
                                        </span>

                                        <!-- Avatars -->
                                        <span
                                            class="workers-gallery-icon-container mod-big-icon mod-margin-left-minus"
                                            uib-tooltip="Auto Check-ins"
                                            tooltip-placement="bottom"
                                            ng-style="{ zIndex: 1 }"
                                        >
                                            <img
                                                class="inline-block avatar {{ source.iconClass }}"
                                                ng-src="/images/avatars/avatar-{{ ($index % 7) + 1 }}.png"
                                            />
                                        </span>

                                        <!-- "More" label (when there are integrations) -->
                                        <span
                                            class="
                                                workers-gallery-icon-container
                                                mod-wide
                                                common-color-grey3
                                                flex-vmiddle
                                            "
                                            ng-if="list.requiredIntegrations.length > 3"
                                            ng-style="{ zIndex: 2 }"
                                        >
                                            <span class="text-center padding-left">
                                                + {{ list.requiredIntegrations.length - 3 }}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- NEW built in lists - with example data -->
            <div ng-if="data.selectedList.isExampleList" class="example-list-container">
                <!-- Back sign -->
                <div class="flex-vmiddle margin-bottom back-button pointer" ng-click="previousStep()">
                    <i class="fa fa-chevron-left mod-static margin-right-xs"></i>
                    <div>Back</div>
                </div>

                <!-- Top Bar - Title & Create List button -->
                <div class="flex-vmiddle margin-bottom common-title-sm margin-xs-right-lg template-title">
                    <!-- Title -->
                    <h3 class="margin-none">
                        <span class="margin-right">
                            {{ data.selectedList.title }}
                            <span
                                ng-if="pm.project.id === data.selectedList.projectId"
                                class="common-color-light-grey common-size-xxxs"
                            >
                                (Created by {{ data.selectedList.creator.name }})
                            </span>
                        </span>

                        <!-- Tags -->
                        <span
                            ng-repeat="tag in data.selectedList.tags track by $index"
                            class="common-pill inline-block mod-thin margin-right-xs common-size-xxxxxs"
                        >
                            {{ tag }}
                        </span>
                    </h3>
                </div>

                <!-- Description -->
                <div
                    class="description-container template-description"
                    ng-bind-html="data.selectedList.longDescription"
                ></div>

                <div
                    class="margin-top margin-bottom flex-vmiddle-no-xs"
                    ng-if="data.selectedList.popularIntegrations && data.selectedList.popularIntegrations.length"
                >
                    <div class="common-color-grey common-size-xxxxs margin-right margin-xs-bottom-xs">
                        Popular with:
                    </div>
                    <div class="flex-vmiddle flex-xs-wrap">
                        <span
                            ng-repeat="source in data.selectedList.popularIntegrations"
                            uib-tooltip="{{ source.toLowerCase() }}"
                            class="
                                initiative-integration-icon
                                margin-bottom-xs margin-right-xs
                                mod-normal mod-circle
                                flex-no-shrink
                            "
                            ng-class="'mod-' + source.toLowerCase()"
                        ></span>
                        <i
                            class="margin-left margin-bottom-xs common-color-grey fa fa-ellipsis-h"
                            uib-tooltip="And many others..."
                        ></i>
                    </div>
                </div>

                <!--Triggers examples-->
                <div
                    class="margin-top margin-bottom flex-vmiddle-no-xs"
                    ng-if="data.selectedList.popularIntegrations && data.selectedList.popularIntegrations.length"
                >
                    <div class="common-color-grey common-size-xxxxs margin-right margin-xs-bottom-xs">
                        Example Bot Trigger:
                    </div>
                    <div class="flex-vmiddle flex-xs-wrap">
                        <span
                            ng-repeat="trigger in data.selectedList.triggersExample"
                            class="margin-right-xs flex-no-shrink"
                        >
                            <span class="trigger-example-tag">{{ trigger }}</span>
                            {{ !$last ? ' + ' : '' }}
                        </span>
                    </div>
                </div>

                <div class="template-border padding-md flex flex-col">
                    <!-- Screenshots previewImageUrl -->
                    <div
                        class="screenshot-container"
                        ng-if="data.selectedList.previewImageUrl && data.selectedList.previewImageUrl.length"
                    >
                        <img class="screenshot-img" ng-src="{{ data.selectedList.previewImageUrl }}" />
                    </div>

                    <!-- Solution -->
                    <div
                        ng-if="data.selectedList.isExampleList && !data.isMakeBotFromJson && !isListGallery"
                        class="margin-left"
                    >
                        <div class="margin-top-xs">
                            <!-- Header -->
                            <div class="common-size common-bold-title">Solution</div>

                            <!-- Solutions Dropdown -->
                            <div class="flex flex-col margin-bottom-xs">
                                <!-- Dropdown Label -->
                                <div class="margin-right-xs">Place the template under a solution:</div>

                                <!-- Drodpdown Menu -->
                                <div class="mod-width-250">
                                    <tnk-workflow-folder-selector
                                        is-required="true"
                                        on-folder-selected="onWorkflowFolderSelected(folder)"
                                        selected-workflow-folder="wfm.projectIdToFolderIdToFolderMap[pm.project.id][data.workflowFolderId]"
                                    ></tnk-workflow-folder-selector>
                                </div>
                            </div>
                            <div ng-if="data.solutionNotChoseError" class="common-error margin-bottom-xs">
                                {{ data.solutionNotChoseError }}
                            </div>
                        </div>

                        <div class="margin-top-xs">
                            <!-- Header -->
                            <div class="common-size common-bold-title">Outline</div>

                            <!-- Solutions Outline Dropdown -->
                            <div class="flex flex-col margin-bottom-xs">
                                <!-- Dropdown Label -->
                                <div class="margin-right-xs">Place the template under an outline section:</div>

                                <!-- Drodpdown Menu -->
                                <div class="mod-width-250">
                                    <tnk-workflow-folder-category-selector
                                        workflow-folder-category-id="data.workflowFolderCategoryId"
                                        workflow-folder-id="data.workflowFolderId"
                                        on-category-selected="(onWorkflowFolderCategorySelected)"
                                        auto-select-first="true"
                                    ></tnk-workflow-folder-category-selector>
                                </div>
                            </div>
                        </div>

                        <!-- Loading dependencies indication -->
                        <i ng-if="data.lodaingCreationJsonDependencies" class="loading-large"></i>
                    </div>

                    <!-- Required people -->
                    <div
                        class="margin-left margin-right margin-top"
                        ng-if="
                            data.selectedList.requiredPeople &&
                            (data.selectedList.requirePersonMapping || data.isMakeBotFromJson) &&
                            !isListGallery
                        "
                    >
                        <!-- Title -->
                        <div class="common-size common-bold-title">Required People</div>

                        <!-- People match selector -->
                        <div class="margin-bottom-md common-border mod-radius field-matcher-container">
                            <button
                                class="
                                    pointer
                                    btn btn-default
                                    button-background-grey
                                    common-width-100
                                    flex-vmiddle
                                    mod-justify-space
                                "
                                type="button"
                                ng-click="showPeopleMatchConfiguration()"
                            >
                                <span class="common-size-15 button-text-font">People Match Configuration</span>
                                <span
                                    class="fa"
                                    ng-class="{
                                        'fa-chevron-up': !data.isPeopleMatchConfigurationCollapsed,
                                        'fa-chevron-down': data.isPeopleMatchConfigurationCollapsed
                                    }"
                                ></span>
                            </button>

                            <div
                                uib-collapse="data.isPeopleMatchConfigurationCollapsed"
                                class="margin-top-md padding-normal-h"
                            >
                                <div ng-repeat="person in data.selectedList.requiredPeople">
                                    <div class="flex-vmiddle margin-bottom-md">
                                        <div class="common-width-33">
                                            {{ person.name }}
                                            <span ng-if="person.title">({{ person.title }})</span>
                                            :
                                        </div>
                                        <div class="common-width-20">
                                            <tnk-single-person-selector
                                                params-object-for-callbacks="person"
                                                on-person-selected="ownerSelected(person, paramsObject)"
                                                on-person-removed="ownerRemoved(paramsObject)"
                                            ></tnk-single-person-selector>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="margin-top-md common-error" ng-if="data.peopleMatchConfigurationError">
                            {{ data.peopleMatchConfigurationError }}
                        </div>
                    </div>

                    <!-- Required Data Sources -->
                    <div
                        class="margin-left margin-right margin-top margin-bottom-lg"
                        ng-if="data.requiredIntegrationsStates && !isListGallery"
                    >
                        <!-- Title -->
                        <div class="common-size common-bold-title" ng-if="data.requiredIntegrationsStates.length">
                            Required Data Sources
                        </div>

                        <!-- Required integration selector -->
                        <div
                            ng-repeat="integrationState in data.requiredIntegrationsStates"
                            class="integration-group-exists"
                        >
                            <!-- Success indicator -->
                            <div
                                ng-if="
                                    integrationState.currentIntegration.valid &&
                                    !integrationState.currentIntegration.disabled
                                "
                                uib-tooltip="The data source is connected"
                                tooltip-append-to-body="true"
                                class="integration-group-exists-indicator common-color-success"
                            >
                                <i class="fa fa-check-circle-o"></i>
                            </div>

                            <!-- Fix indicator -->
                            <div
                                ng-if="
                                    integrationState.currentIntegration &&
                                    (!integrationState.currentIntegration.valid ||
                                        integrationState.currentIntegration.disabled)
                                "
                                uib-tooltip="Click on the data source in order to fix it"
                                tooltip-append-to-body="true"
                                class="integration-group-exists-indicator common-color-warning"
                            >
                                <i class="fa fa-times-circle-o"></i>
                            </div>

                            <!-- Integration connection -->
                            <div>
                                <!-- Integration with no multiple instances-->
                                <div
                                    class="single-occurrence-integration"
                                    ng-if="!integrationState.supportsMultipleIntegrationsPerUser"
                                    ng-class="{
                                        'common-disabled':
                                            integrationState.currentIntegration.valid &&
                                            !integrationState.currentIntegration.disabled
                                    }"
                                    uib-tooltip="This type of data source can only be connected once"
                                    tooltip-append-to-body="true"
                                    tooltip-enable="{{
                                        integrationState.currentIntegration.valid &&
                                            !integrationState.currentIntegration.disabled
                                    }}"
                                >
                                    <!-- Not allowing clicking on connected valid integrations, since clicking caused the integrationState to be cleared and thus marked as not connected -->
                                    <tnk-integration-group
                                        ng-class="{
                                            'pointer-events-none':
                                                integrationState.currentIntegration.valid &&
                                                !integrationState.currentIntegration.disabled
                                        }"
                                        sources="[integrationState.type.toLowerCase()]"
                                        ng-model="ignored"
                                        display-mode="button"
                                        current-integration="integrationState.currentIntegration"
                                        state="integrationState.state"
                                        integration-changed="integrationState.integrationChanged(changedProjectIntegration)"
                                        open-modal-for-configuration="true"
                                        display-new-prefix="false"
                                        wide-mode="false"
                                    ></tnk-integration-group>
                                </div>

                                <!-- Possible multiple instances of integration -->
                                <div ng-if="integrationState.supportsMultipleIntegrationsPerUser">
                                    <tnk-select-integration-instance
                                        integration-type="integrationState.type"
                                        project-integration-options="integrationState.existingProjectIntegrations"
                                        on-integration-selected="
                                            onExistingIntegrationSelected(integrationState, selectedIntegration)
                                        "
                                        selected-project-integration="integrationState.currentIntegration"
                                    >
                                        <!-- Show the selected integration as a trigger to open the popover -->
                                        <div class="pointer">
                                            <tnk-integration-group
                                                class="pointer-events-none"
                                                sources="[integrationState.type.toLowerCase()]"
                                                ng-model="ignored"
                                                display-mode="button"
                                                button-postfix="{{ ' (' + integrationState.pastIntegrationName + ')' }}"
                                                current-integration="integrationState.currentIntegration"
                                                state="integrationState.state"
                                                integration-changed="integrationState.integrationChanged(changedProjectIntegration)"
                                                open-modal-for-configuration="true"
                                                display-new-prefix="false"
                                                wide-mode="false"
                                            ></tnk-integration-group>
                                        </div>
                                    </tnk-select-integration-instance>
                                </div>
                            </div>
                        </div>

                        <!-- Field Match Selector -->
                        <div
                            class="margin-top-lg margin-bottom-lg"
                            ng-if="
                                data.requiredIntegrationsStates.length &&
                                (data.selectedList.requireFieldMapping || data.isMakeBotFromJson) &&
                                data.columns.length
                            "
                        >
                            <div class="common-size common-bold-title">Required Fields</div>

                            <!-- Dropdown placeholder -->
                            <button
                                class="
                                    pointer
                                    btn btn-default
                                    button-background-grey
                                    common-width-100
                                    flex-vmiddle
                                    mod-justify-space
                                "
                                type="button"
                                ng-click="toggleAdvancedSettings()"
                            >
                                <span class="common-size-15 button-text-font">Fields Mapping</span>
                                <span
                                    class="fa"
                                    ng-class="{
                                        'fa-chevron-up': data.isAdvancedSettingsOpened,
                                        'fa-chevron-down': !data.isAdvancedSettingsOpened
                                    }"
                                ></span>
                            </button>

                            <!-- Dropdown menu -->
                            <div uib-collapse="!data.isAdvancedSettingsOpened" class="margin-top-md padding-normal-h">
                                <div ng-repeat="integrationState in data.requiredIntegrationsStates">
                                    <div class="flex-vmiddle margin-right-xlg">
                                        <!-- Integration icon -->
                                        <div
                                            class="initiative-integration-icon common-radius-50 mod-normal margin-right-md margin-top margin-bottom mod-{{
                                                integrationState.type.toLowerCase()
                                            }}"
                                        ></div>

                                        <!-- Past Integration name -->
                                        <div class="original-integration-box common-width-40">
                                            {{ integrationState.pastIntegrationName }}
                                        </div>

                                        <!-- Current integration name -->
                                        <div
                                            class="
                                                original-integration-box
                                                margin-left-md
                                                common-width-40
                                                original-integration-color
                                            "
                                            ng-if="integrationState.currentIntegration"
                                        >
                                            {{ integrationState.currentIntegration.displayName }}
                                        </div>

                                        <!-- Choose integration error -->
                                        <div
                                            class="original-integration-box margin-left-md common-width-40 common-error"
                                            ng-if="
                                                !integrationState.currentIntegration &&
                                                integrationState.matchFieldsError
                                            "
                                        >
                                            {{ integrationState.matchFieldsError }}
                                        </div>
                                    </div>

                                    <!-- Map fields title -->
                                    <div
                                        ng-if="integrationState.currentIntegration"
                                        class="margin-left-xlg margin-top-md match-fields-title"
                                    >
                                        Map Fields
                                    </div>

                                    <div
                                        ng-if="integrationState.currentIntegration"
                                        class="margin-bottom-md field-matcher-container margin-top-minus-xxs"
                                    >
                                        <div class="margin-top-md">
                                            <div
                                                ng-if="!integrationState.isLoadingExternalFields"
                                                class="margin-bottom-xlg"
                                            >
                                                <div
                                                    ng-repeat="column in data.columns | filter:filterNonDefinitionFields(integrationState) | filter: { projectIntegration: { id: integrationState.originalProjectIntegrationId }}"
                                                >
                                                    <!-- Original field -->
                                                    <div class="flex-vmiddle margin-bottom-md margin-right-xlg">
                                                        <div class="original-field-box common-width-40">
                                                            {{ column.definition.FieldLabel }}
                                                            ({{ column.definition.ExternalType }}):
                                                        </div>

                                                        <!-- New integration fields selector -->
                                                        <ui-select
                                                            class="margin-left-md common-width-40"
                                                            ng-change="fieldSelected(column.matchedEntity, column)"
                                                            theme="bootstrap"
                                                            ng-model="column.matchedEntity"
                                                        >
                                                            <ui-select-match placeholder="">
                                                                {{ column.matchedEntity.label }}
                                                                ({{ column.matchedEntity.entityDisplayName }})
                                                            </ui-select-match>
                                                            <ui-select-choices
                                                                repeat="field in integrationState.availableExternalFields | filter:{label:$select.search}"
                                                            >
                                                                <div>
                                                                    {{ field.label }} ({{ field.entityDisplayName }})
                                                                </div>
                                                            </ui-select-choices>
                                                        </ui-select>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Loading External Fields -->
                                            <i
                                                ng-if="integrationState.isLoadingExternalFields"
                                                class="loading-medium"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Create Module Button -->
                    <div
                        ng-if="data.selectedList.isExampleList || !data.isMakeBotFromJson"
                        class="hidden-xs margin-right-lg margin-top-md margin-bottom-lg flex-self-end"
                        ng-include="'setup-group-create-button'"
                    ></div>

                    <!-- Error message -->
                    <div
                        class="flex mod-justify-end margin-top common-size-xxs text-danger common-text-right-no-xs"
                        ng-if="error.shortMessage"
                    >
                        <!-- Error -->
                        <div>
                            <!-- Short error -->
                            <div>
                                <strong>An error has occurred:</strong>
                                {{ error.shortMessage | error }}
                            </div>

                            <!-- Full Stack Error -->
                            <div class="inline-block" ng-if="error.longMessage">
                                <!-- Copy to clipboard -->
                                <a>
                                    <span class="fa fa-copy"></span>
                                    <span ng-click="copyErrorToClipboard()">
                                        Copy full error stacktrace to clipboard
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Create List button (Mobile) -->
                <div class="visible-xs margin-top-lg" ng-include="'setup-group-create-button'"></div>

                <span
                    class="flex margin-bottom-xs margin-xs-top margin-xs-bottom-xlg mod-justify-end-no-xs"
                    ng-if="data.selectedList.sandboxUrl"
                >
                    <span class="common-color-grey visible-xs-inline margin-right">Or</span>

                    <!-- Explore list link -->
                    <a class="common-bold flex-vmiddle" href="{{ data.selectedList.sandboxUrl }}" target="_blank">
                        <!-- Explore list link text -->
                        <span class="margin-right-xs common-color-black">Explore Live List</span>

                        <!-- Open in new tab icon -->
                        <span class="svg-icon-sm common-color-grey">
                            <tnk-icon src="/images/icons/open-in-new-tab.svg"></tnk-icon>
                        </span>
                    </a>
                </span>

                <!-- Sandbox -->
                <div class="sandbox-iframe-container" ng-if="data.selectedList.sandboxUrl">
                    <iframe
                        width="100%"
                        height="100%"
                        class="sandbox-iframe"
                        ng-src="{{ trustSrc(data.selectedList.sandboxUrl + '/embed') }}"
                        frameborder="0"
                        iframe-onload="onSandboxIFrameLoaded()"
                    ></iframe>
                </div>
            </div>

            <!-- Custom Json-->
            <div ng-if="!data.selectedList && data.enterCustomJson">
                <p>Enter custom template (json):</p>

                <div>
                    <textarea ng-model="data.customJson" class="form-control" rows="10"></textarea>
                </div>

                <button
                    class="btn btn-primary margin-top-xs"
                    ng-disabled="!data.customJson.length"
                    ng-click="loadCustomJson(data.customJson)"
                >
                    Load
                </button>
            </div>
        </div>

        <!-- Modal content - setup steps (list selected) -->
        <div class="setup-group-modal-list-setup flex" ng-if="data.selectedList && !data.selectedList.isExampleList">
            <!-- Old built in lists - not with example data -->
            <div class="flex flex-grow text-center">
                <!-- Top line -->
                <div
                    class="setup-group-step-top-line"
                    ng-if="data.selectedList.id !== 'EMPTY'"
                    ng-style="{ 'background-color': data.selectedList.mainColor }"
                ></div>

                <!-- Step content -->
                <div class="flex-grow padding-normal common-close-btn-container">
                    <!-- Close button when in this mode -->
                    <div class="common-close-btn svg-icon-lg margin-normal-sm" ng-if="isModal" ng-click="close()">
                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                    </div>

                    <div class="setup-group-step-container text-left">
                        <div class="flex margin-top-xlg">
                            <div class="flex-no-shrink margin-right">
                                <div class="common-circle mod-primary mod-bold">
                                    {{
                                        data.steps[data.currentStepIndex].actualIndex
                                            ? data.steps[data.currentStepIndex].actualIndex + 1
                                            : data.currentStepIndex + 1
                                    }}
                                </div>
                            </div>

                            <!-- Step content -->
                            <div
                                class="flex-grow padding-normal-sm-h"
                                ng-if="!data.loadingStep"
                                ng-switch="data.currentStep.type"
                            >
                                <!-- Config -->
                                <div ng-switch-when="config">
                                    <form
                                        name="forms.setupList"
                                        ng-submit="submitList(data.currentStepIndex >= (data.steps.length - 1))"
                                    >
                                        <div
                                            ng-if="data.currentStep.data.controller"
                                            ng-include="'../setupGroupModal/setupGroupTemplateSwitch.template.html'"
                                            ng-controller="data.currentStep.data.controller"
                                        ></div>
                                        <!-- Need to do this twice, cause ng-controller don't know how to handle null -->
                                        <div
                                            ng-if="!data.currentStep.data.controller"
                                            ng-include="'../setupGroupModal/setupGroupTemplateSwitch.template.html'"
                                        ></div>

                                        <div
                                            ng-if="!valueResults['SYNC_VALIDITY'].hasError"
                                            class="
                                                text-right
                                                margin-top-lg
                                                padding-top-lg
                                                flex-vmiddle flex-justify-end-no-xs
                                            "
                                        >
                                            <button
                                                class="btn btn-primary"
                                                ng-if="data.steps.length - 1 > data.currentStepIndex"
                                                type="submit"
                                            >
                                                Continue
                                            </button>
                                            <button
                                                class="btn btn-primary"
                                                ng-if="data.steps.length - 1 === data.currentStepIndex"
                                                type="submit"
                                                ng-disabled="data.loadingGroupCreation"
                                            >
                                                Complete
                                            </button>
                                            <!-- Loading-->
                                            <div class="margin-left-md" ng-if="data.loadingGroupCreation">
                                                <i class="loading-medium"></i>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Error message -->
                        <div
                            class="flex mod-justify-end margin-top common-size-xxs text-danger common-text-right-no-xs"
                            ng-if="error.shortMessage"
                        >
                            <!-- Error -->
                            <div>
                                <!-- Short error -->
                                <div>
                                    <strong>An error has occurred:</strong>
                                    {{ error.shortMessage | error }}
                                </div>

                                <!-- Full Stack Error -->
                                <div class="inline-block" ng-if="error.longMessage">
                                    <!-- Copy to clipboard -->
                                    <a>
                                        <span class="fa fa-copy"></span>
                                        <span ng-click="copyErrorToClipboard()">
                                            Copy full error stacktrace to clipboard
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Side bar-->
                <div
                    class="setup-group-step-sidebar"
                    ng-if="
                        data.selectedList.id !== 'EMPTY' &&
                        data.selectedList.id !== 'DUPLICATE' &&
                        !data.selectedList.isExampleList
                    "
                    ng-init="data.sidebarColor = utils.hexToRgbaWithOpacity(data.selectedList.mainColor, 0.1)"
                    ng-class="{ 'mod-shrinked': data.currentStepIndex === data.steps.length - 1 }"
                    ng-style="{ 'background-color': data.sidebarColor }"
                >
                    <div class="side-bar-content" id="setup-group-side-bar">
                        <!-- List overview -->
                        <div ng-if="data.currentStepIndex === 0">
                            <!--list details-->
                            <div class="box-with-shadow padding-normal text-left">
                                <div class="common-size common-bold-title margin-top-none margin-bottom header-font">
                                    {{ data.selectedList.title }}
                                </div>
                                <div class="common-size-xxs common-color-grey hidden-xs">
                                    {{ data.selectedList.subtitle }}
                                </div>
                                <div class="margin-top-lg margin-bottom">{{ data.selectedList.description }}</div>
                            </div>
                        </div>

                        <!-- Specific step info -->
                        <div ng-if="data.currentStepIndex > 0">
                            <div ng-include="'../setupGroupModal/setupGroupInfoTemplateSwitch.template.html'"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<script type="text/ng-template" id="setup-group-create-button">
    <!-- Create List button (Authenticated) -->
    <!-- Authenticated users will see a button with tnk-require on it. -->
    <div class="flex-vmiddle">

        <button type="button"
                class="btn btn-primary btn-lg btn-lg flex mod-align-center mod-width-160 text-center"
                ng-disabled="data.createExampleListButtonClicked"
                ng-if="data.isUserAuthenticated"
                ng-click="createExampleList()"
        >

            <!-- Loading-->
            <span ng-if="data.loadingGroupCreation">
                <i class="inline-block loading-small margin-right-sm"></i>
                <span class="margin-left-sm">
                    Creating...
                </span>
            </span>

            <!-- Button Text -->
            <span ng-if="!data.loadingGroupCreation">
                Create {{workersTemplateMode ? 'Module' : 'List'}}
            </span>
        </button>

        <!-- Create List button (Anonymous) -->
        <button type="button"
                class="btn btn-primary btn-lg flex mod-align-center mod-width-160 text-center"
                ng-if="!data.isUserAuthenticated"
                ng-click="createExampleList()">

            <!-- Loading-->
            <span ng-if="data.loadingGroupCreation">
                <i class="loading-small"></i>
                <span>
                    Creating...
                </span>
            </span>

            <!-- Button Text -->
            <span ng-if="!data.loadingGroupCreation">
                Create {{workersTemplateMode ? 'Module' : 'List'}}
            </span>
        </button>
    </div>
</script>
