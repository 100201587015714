import { STATUSES } from '@tonkean/constants';
import { DeprecatedDate } from '@tonkean/utils';
import template from './tnkStatusPopover.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';

/**
 * A wrapping directive for statusPopoverTemplate.
 */
function tnkStatusPopover() {
    return {
        restrict: 'E',
        scope: {
            initiativeId: '=',
            workflowVersionId: '=',
            isViewOnly: '=',
            isCommentInEdit: '=',
            onClose: '&',
        },
        template,
        controller: StatusPopoverCtrl,
    };
}

angular.module('tonkean.app').directive('tnkStatusPopover', tnkStatusPopover);

function StatusPopoverCtrl(
    $scope,
    $rootScope,
    $q,
    modal,
    trackHelper,
    initiativeCache,
    tonkeanService,
    workflowVersionManager,
    licensePermissionsService,
    authenticationService,
) {
    const updateTextLimit = 160;
    const updateTextShowMoreLimit = 220;

    $scope.statuses = STATUSES;
    $scope.pm = $rootScope.pm;
    $scope.as = authenticationService;
    $scope.lps = licensePermissionsService;
    $scope.trackHelper = trackHelper;
    $scope.itemMap = initiativeCache.getInitiativesCache();
    $scope.todayTime = DeprecatedDate.nowAsDate();
    $scope.initialStatusCommentText = null;

    $scope.data = {
        tempDueDate: null,
        dueDatePopoverOpen: false,
        isCommentsBarOpen: false,
        loadingComments: false,
        statusActivityId: null,
        comments: null,
        latestComment: null,
        updateTextLengthLimit: updateTextLimit,
        showingMore: false,

        loadingInitiativeFromServer: false,
    };

    $scope.gatherReasons = {
        DUE_DATE: 'due date',
        ETA: 'eta',
    };

    $scope.$watch('initiativeId', function () {
        if ($scope.initiativeId) {
            let getInitiativePromise;
            if ($scope.itemMap[$scope.initiativeId]) {
                // The initiative is in the cache, no need to request it from server.
                getInitiativePromise = $q.resolve();
            } else {
                // The initiative is not in the cache, we should get it from server.
                $scope.data.loadingInitiativeFromServer = true;
                getInitiativePromise = trackHelper.getInitiativeById($scope.initiativeId).finally(() => {
                    $scope.data.loadingInitiativeFromServer = false;
                });
            }

            getInitiativePromise.then(() => {
                // Now we surely have the initiative (whether if it was in the cache or not.
                $scope.item = $scope.itemMap[$scope.initiativeId];

                // The relevant person is usually the owner.
                $scope.itemPerson = $scope.item.owner;
                if ($scope.item.status && $scope.item.status !== 'FUTURE') {
                    // If there's a state text, the relevant person is the one who gave the update.
                    $scope.itemPerson = $scope.item.updater;
                }

                $scope.isUpdaterNotOwner = isUpdaterNotOwner($scope.item);
                if ($scope.itemMap[$scope.item.trackId].group) {
                    const workflowVersion = $scope.workflowVersionId
                        ? workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId)
                        : workflowVersionManager.getPublishedVersionFromCache($scope.item.group.id);
                    $scope.gatherUpdatesActive = workflowVersion.shouldSendGatherUpdates;
                    $scope.gatherUpdateReason = getGatherReason($scope.item);
                } else {
                    $scope.gatherUpdatesActive = true;
                }
                init();
            });
        }
    });

    /**
     * Fired when the popover template is clicked.
     */
    $scope.onStatusPopoverClick = function (event) {
        // Stop propagation, so the status we are contained in is not clicked.
        event.stopPropagation();
    };

    /**
     * Opens the "ping now" modal.
     */
    $scope.pingNow = function () {
        analyticsWrapper.track('Ping now clicked', { category: 'Initiative status popover' });
        modal.openAskForUpdateModal($scope.item, $scope.item.owner.name);
    };

    /**
     * Opens the initiative's gather update settings.
     */
    $scope.openGatherSettings = function () {
        analyticsWrapper.track('Gather settings clicked', { category: 'Initiative status popover' });
        modal.openInitiativeSettingsModal($scope.item, true);
    };

    /**
     * Toggles the dueDatePopoverOpen boolean.
     */
    $scope.toggleDueDatePopover = function () {
        $scope.data.dueDatePopoverOpen = !$scope.data.dueDatePopoverOpen;
    };

    /**
     * Fired when the due date is changed via the popover.
     * @param initiative - the initiative to update.
     * @param date - the new date.
     */
    $scope.onDueDateChange = function (initiative, date) {
        analyticsWrapper.track('Changed due date', { category: 'Initiative status popover' });
        // Update the server.
        trackHelper.updateDueDate(initiative.id, date, true);
        // Close the popover once the due date set is clicked.
        $scope.close();
    };

    $scope.onSeeMoreUpdateTextClicked = function () {
        if ($scope.itemMap[$scope.item.trackId].updateText.length > updateTextShowMoreLimit) {
            if ($scope.as.currentUser && $scope.as.currentUser.isUser) {
                // If the update text is very long, send the user to the track view.
                modal.openViewInitiative($scope.item.trackId);
            }
        } else {
            // If the update text is no very long, just show it to the user.
            $scope.data.updateTextLengthLimit = updateTextShowMoreLimit;
            $scope.data.showingMore = true;
        }
    };

    $scope.toggleCommentsBar = function () {
        if (!$scope.as.currentUser || !$scope.as.currentUser.isUser || $scope.isViewOnly) {
            return;
        }

        // Since $scope.data.isCommentsBarOpen is sometimes toggles immediatly and sometimes async, this flag tells us what our intention will be: on or off.
        const isCommentsBarTogglingOn = !$scope.data.isCommentsBarOpen;

        // If we are toggling off or there will be no comments to load (just the new comment input), toggle immediately.
        if ($scope.data.isCommentsBarOpen || !$scope.item.lastStatusComment) {
            $scope.data.isCommentsBarOpen = !$scope.data.isCommentsBarOpen;
        }

        // If the user opened the comments bar, and there are comments to fetch (which we didn't fetch) - get them (and mark loading comments).
        if (isCommentsBarTogglingOn && !$scope.data.statusActivityId && !$scope.data.loadingComments) {
            $scope.data.loadingComments = true;
            tonkeanService
                .getInitiativeActivity($scope.item.id, 1, null, ['INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED'], false)
                .then(function (data) {
                    if (data && data.activity && data.activity.length) {
                        $scope.data.statusActivityId = data.activity[0].id;
                        $scope.data.comments = data.activity[0].comments;
                        if ($scope.data.comments && $scope.data.comments.length) {
                            $scope.data.latestComment = $scope.data.comments[0];
                        }
                    }
                })
                .finally(function () {
                    $scope.data.loadingComments = false;
                    // Anyway, at the end the scope state should be the same as our inner toggle state.
                    $scope.data.isCommentsBarOpen = isCommentsBarTogglingOn;
                });
        } else {
            // Update the scope state if we didn't send a request.
            $scope.data.isCommentsBarOpen = isCommentsBarTogglingOn;
        }

        // If we are opening the comments bar, load the ongoing status comment text (if exists).
        if (isCommentsBarTogglingOn && $scope.itemMap[$scope.item.trackId].ongoingStatusCommentText) {
            $scope.initialStatusCommentText = $scope.itemMap[$scope.item.trackId].ongoingStatusCommentText;
        }

        analyticsWrapper.track('Comments bar toggled', {
            category: 'Initiative status popover',
            label: isCommentsBarTogglingOn,
        });
    };

    /**
     * Fired when a new comment is added by the user.
     * @param newComment - the new comment the user has just added.
     */
    $scope.onNewCommentPosted = function (newComment) {
        $scope.isCommentInEdit = false;

        // Add the new comment to the comments array and replace the latest comment with it.
        if (!$scope.data.comments) {
            $scope.data.comments = [];
        }

        $scope.data.comments.unshift(newComment);
        $scope.data.latestComment = newComment;

        // Update the initiative with the lastStatusComment, since that's what the user just do.
        $scope.itemMap[$scope.item.id].lastStatusComment = DeprecatedDate.nowAsDate();

        // Clear the ongoing status comment text.
        $scope.itemMap[$scope.item.trackId].ongoingStatusCommentText = null;
    };

    $scope.onStatusCommentTextChanged = function (text) {
        if (text && text.length) {
            // If there's a status comment text.
            $scope.itemMap[$scope.item.trackId].ongoingStatusCommentText = text;

            $scope.isCommentInEdit = true;
        } else {
            // If the text was deleted, clear the status comment saved on the initiative.
            $scope.itemMap[$scope.item.trackId].ongoingStatusCommentText = null;

            $scope.isCommentInEdit = false;
        }
    };

    /**
     * Initialize the popover's state data.
     */
    function init() {
        $scope.data.tempDueDate = null;
        $scope.data.dueDatePopoverOpen = false;
        $scope.data.isCommentsBarOpen = false;
        $scope.data.loadingComments = false;
        $scope.data.statusActivityId = null;
        $scope.data.comments = null;
        $scope.data.latestComment = null;
        $scope.data.updateTextLengthLimit = updateTextLimit;
        $scope.data.showingMore = false;
    }

    /**
     * Checks whether the initiative's updater and owner are different, safely.
     * @param currentItem - the current initiative item.
     * @returns {boolean}
     */
    function isUpdaterNotOwner(currentItem) {
        if (currentItem.owner && currentItem.updater) {
            return currentItem.owner.id !== currentItem.updater.id;
        }

        return false;
    }

    /**
     * Returns the gather reason: gatherReasons.DUE_DATE or gatherReasons.ETA.
     * @param currentItem - the current initiative item.
     */
    function getGatherReason(currentItem) {
        const dueDateGatherReasons = {
            DUE_DATE: true,
            PARENT_DUE_DATE: true,
            DUE_DATE_EXACT: true,
            PARENT_DUE_DATE_EXACT: true,
            DUE_DATE_PAST: true,
            PARENT_DUE_DATE_PAST: true,
            DUE_DATE_FUTURE: true,
            PARENT_DUE_DATE_FUTURE: true,
        };

        const etaGatherReasons = {
            ETA: true,
            PARENT_ETA: true,
            ETA_EXACT: true,
            PARENT_ETA_EXACT: true,
            ETA_PAST: true,
            PARENT_ETA_PAST: true,
            ETA_FUTURE: true,
            PARENT_ETA_FUTURE: true,
        };

        if (currentItem.nextGatherUpdateReason) {
            if (dueDateGatherReasons[currentItem.nextGatherUpdateReason]) {
                return $scope.gatherReasons.DUE_DATE;
            } else if (etaGatherReasons[currentItem.nextGatherUpdateReason]) {
                return $scope.gatherReasons.ETA;
            }
        }

        return null;
    }

    $scope.close = function () {
        $scope.isCommentInEdit = false;
        $scope.onClose();
    };
}

angular.module('tonkean.app').controller('StatusPopoverCtrl', StatusPopoverCtrl);
