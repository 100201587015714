import React from 'react';
import styled from 'styled-components';

import { ReactComponent as DoneIcon } from '../../../../../../images/icons/history/done.svg';

import { LoadingDots } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const theme = Theme.current.palette.history.workerRunsList;

const StatusItem = styled.div`
    display: flex;
    color: ${theme.textStatusRunning};
    font-weight: 500;
`;

const InvalidStatusItem = styled(StatusItem)`
    color: ${theme.textStatusError};
`;

const WarningStatusItem = styled(StatusItem)`
    color: ${Theme.colors.warning};
`;

interface Props {
    hasErrors: boolean;
    hasWarning: boolean;
    completed: boolean | undefined;
}

const WorkerRunsTableItemStatus: React.FC<Props> = ({ hasErrors, hasWarning, completed = true }) => {
    if (hasErrors) {
        return <InvalidStatusItem>Error</InvalidStatusItem>;
    }

    if (hasWarning) {
        return <WarningStatusItem>Partial Success</WarningStatusItem>;
    }

    if (completed) {
        return (
            <span className="tnk-icon">
                <DoneIcon />
            </span>
        );
    }

    return (
        <StatusItem>
            Running
            <LoadingDots color="black" />
        </StatusItem>
    );
};

export default WorkerRunsTableItemStatus;
