<div class="margin-top-xxs">
    <strong>{{ item.actor.id === as.currentUser.id ? 'You' : item.actor.name }}</strong>
    changed the value of
    <strong>
        {{ cfm.fieldsMap[item.reference2.fieldDefinition.workflowVersionId][item.reference2.fieldDefinition.id].name }}
    </strong>
    <span ng-if="item.reference1.id !== item.target.id || targetId !== item.target.id">
        on
        <a ng-click="modal.openViewInitiative(item.reference1.id)" class="common-color-dark-grey common-bold">
            {{ item.reference1.title }}
        </a>
    </span>
</div>
