import React from 'react';
import styled from 'styled-components';

import { ReactComponent as AtomIcon } from '../../../../images/icons/atom.svg';

import { AITypewriter } from '@tonkean/ai-builder';
import { FontSize } from '@tonkean/tui-theme';

const IconWrapper = styled.span`
    margin-right: 8px;
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const QuestionMessage = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    margin-top: 8px;
    font-size: 18px;
    font-weight: 300;
`;

const FirstMessageIntroText = styled.div`
    display: flex;
`;

const MessageText = styled.div`
    display: flex;
    flex-direction: column;
    color: #24064b;
    font-size: ${FontSize.XLARGE_18};
`;

interface Props {
    text: string;
    isFirstMessage?: boolean;
}

const ProcessBuilderQuestionMessage: React.FC<Props> = ({ text, isFirstMessage = false }) => {
    return (
        <QuestionMessage>
            <IconWrapper>
                <AtomIcon />
            </IconWrapper>

            <MessageText>
                {isFirstMessage && (
                    <FirstMessageIntroText>Awesome. A bit more context would be helpful:</FirstMessageIntroText>
                )}
                <AITypewriter text={text} />
            </MessageText>
        </QuestionMessage>
    );
};

export default ProcessBuilderQuestionMessage;
