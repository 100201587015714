import { TrackActions } from '@tonkean/flux';

/**
 * Helper for actions on the as.currentUser
 */
function CurrentUserHelper($q, authenticationService, tonkeanService, entityHelper, trackHelper) {
    const _this = this;

    /**
     * Updates the cached preferences for user with given preferences.
     */
    _this.updateCurrentUserMessagePreferences = function (selectedPreferences) {
        // Updating the cached user.
        authenticationService.currentUser.messagePreferences = selectedPreferences;

        if (selectedPreferences.includes('NO_GATHER_UPDATE')) {
            trackHelper.updateAllTracksWithOwner(
                authenticationService.currentUser.id,
                (initiative) =>
                    (initiative.owner.isGatherUpdateDisabled =
                        authenticationService.currentUser.isGatherUpdateDisabled),
            );
            TrackActions.newFullDataUpdate();
        }

        // Enriching person again for properties calculated based on preferences.
        authenticationService.currentUser = entityHelper.enrichPerson(authenticationService.currentUser);
    };

    /**
     * Removes the NO_GATHER_UPDATE from the preferences of current user and updates server.
     */
    _this.removeNoGatherUpdateOptionFromCurrentUser = function () {
        return _this.removeMessagePreferenceOptionFromCurrentUser('NO_GATHER_UPDATE');
    };

    /**
     * Removes the NO_SCHEDULED_REPORT from the preferences of current user and updates server.
     */
    _this.removeNoScheduledReportOptionFromCurrentUser = function () {
        return _this.removeMessagePreferenceOptionFromCurrentUser('NO_SCHEDULED_REPORT');
    };

    /**
     * Removes a message preference option from current user and updates server.
     */
    _this.removeMessagePreferenceOptionFromCurrentUser = function (preferenceOptionKey) {
        // Saving the previously defined preferences to roll back if needed.
        const previouslyDefinedPreferences = angular.copy(authenticationService.currentUser.messagePreferences);

        // Creating the newly selected preferences.
        const clonedPreferences = angular.copy(authenticationService.currentUser.messagePreferences);
        const noGatherUpdateIndex = clonedPreferences.indexOf(preferenceOptionKey);
        if (noGatherUpdateIndex !== -1) {
            clonedPreferences.splice(noGatherUpdateIndex, 1);
        }

        // Updates current user with new preferences.
        _this.updateCurrentUserMessagePreferences(clonedPreferences);

        // Update server.
        return tonkeanService.updateUserMessagePreferences(clonedPreferences).catch((error) => {
            // Roll back preferences on failure.
            _this.updateCurrentUserMessagePreferences(previouslyDefinedPreferences);

            return $q.reject(error);
        });
    };

    /**
     * Returns whether given preferenceOptionKey exists in current user preferences.
     */
    _this.checkUserMessagePreferenceExists = function (preferenceOptionKey) {
        if (authenticationService.currentUser && authenticationService.currentUser.messagePreferences) {
            return authenticationService.currentUser.messagePreferences.includes(preferenceOptionKey);
        } else {
            return false;
        }
    };
}

angular.module('tonkean.app').service('currentUserHelper', CurrentUserHelper);
