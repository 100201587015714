import React, { useState } from 'react';
import type { FormEvent } from 'react';

import CloudConvertAccountType, { accountTypeToDisplayName } from './CloudConvertAccountType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { Radio, RadioGroup } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const CloudConvertAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [accountType, setAccountType] = useState<CloudConvertAccountType>(CloudConvertAccountType.PRODUCTION);
    const [apiKey, setApiKey] = useState<string>('');

    const authenticateWithApiKey = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            authenticate(
                { accountType, apiKey },
                {},
                `${integrationConfiguration.displayName} (${accountTypeToDisplayName[accountType]})`,
            );
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <div className="flex api-token-authentication">
                <div className="form-group">
                    <label className="col-sm-4 control-label text-right">Account Type</label>
                    <div className="col-sm-7">
                        <RadioGroup
                            value={accountType}
                            direction="row"
                            onChange={setAccountType}
                            size={InputSize.MEDIUM}
                        >
                            <Radio value={CloudConvertAccountType.SANDBOX}>
                                {accountTypeToDisplayName[CloudConvertAccountType.SANDBOX]}
                            </Radio>
                            <Radio value={CloudConvertAccountType.PRODUCTION}>
                                {accountTypeToDisplayName[CloudConvertAccountType.PRODUCTION]}
                            </Radio>
                        </RadioGroup>
                    </div>
                </div>

                <div className="form-group">
                    <label className="col-sm-4 control-label text-right">API Key</label>
                    <div className="col-sm-7">
                        <input
                            value={apiKey}
                            className="form-control"
                            onChange={({ target }) => setApiKey(target.value)}
                            id="api_key"
                            autoComplete="off"
                            placeholder="API Key"
                            required
                        />
                        <span className="common-size-xxxs common-color-grey">
                            Get your API key{' '}
                            <a href="https://cloudconvert.com/dashboard/api/v2/keys" target="_blank">
                                here
                            </a>
                            .
                        </span>
                    </div>
                </div>

                <div className="flex mod-center margin-top-20">
                    <button className=" btn btn-secondary btn-lg" onClick={authenticateWithApiKey}>
                        Connect to {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CloudConvertAuthenticationComponent;
