import React, { useCallback } from 'react';
import styled from 'styled-components';

import type { ContractFieldWithIsSelected } from './ContractFieldWithIsSelected';

import {
    FieldsImporter,
    ModalFooterActions,
    ModalHeader,
    ModalSize,
    useAddFieldsModalManager,
    withModal,
} from '@tonkean/infrastructure';
import type { TonkeanType } from '@tonkean/tonkean-entities';
import type { TonkeanId } from '@tonkean/tonkean-entities';
import { removeFalsy } from '@tonkean/utils';

const StyledModalBody = styled.div`
    padding: 20px;
    display: grid;
    grid-template-rows: 1fr 10fr;
    gap: 20px;
    overflow: hidden;
`;

interface Props {
    initialFieldsState: ContractFieldWithIsSelected[];
    onSubmit: (contractFieldsToAdd: Omit<ContractFieldWithIsSelected, 'isSelected'>[]) => Promise<unknown> | void;
    onClose: () => void;
}

const AddContractFieldsModal: React.FC<Props> = ({ initialFieldsState, onClose, onSubmit: onSubmitProp }) => {
    const { fieldsState, dispatcher, getSubmitResult } =
        useAddFieldsModalManager<TonkeanId<TonkeanType.CONTRACT_FIELD>>(initialFieldsState);

    const onSubmit = useCallback(async () => {
        const changes = getSubmitResult(fieldsState);
        const newChecked = removeFalsy(
            changes.newChecked.map((field) => initialFieldsState.find((initialField) => initialField.id === field)),
        );

        await onSubmitProp(newChecked);
        onClose();
    }, [fieldsState, getSubmitResult, initialFieldsState, onClose, onSubmitProp]);

    return (
        <>
            <ModalHeader>Select Contract Fields</ModalHeader>

            <StyledModalBody>
                <FieldsImporter<TonkeanId<TonkeanType.CONTRACT_FIELD>>
                    fieldsState={fieldsState}
                    dispatcher={dispatcher}
                />
            </StyledModalBody>

            <ModalFooterActions saveLabel="Add Selected" onSave={onSubmit} onCancel={onClose} />
        </>
    );
};

export default withModal(AddContractFieldsModal, { size: ModalSize.MEDIUM, fixedWidth: true, height: '50%' });
