import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import type { DropdownAction } from './ActionCheckboxRow';
import ActionsCheckboxRow from './ActionCheckboxRow';

import { SearchNewIcon as SearchIcon } from '@tonkean/svg';
import type { GroupEnvironmentFormSummary } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const StyledSearchIcon = styled(SearchIcon)`
    width: 100px;
    height: 100px;

    path {
        fill: ${Theme.colors.gray_500};
    }
`;

const StyledNoAction = styled.div`
    height: 100%;
    background: ${Theme.colors.gray_100};
    font-size: ${FontSize.SMALL_12};
    line-height: 18px;
    text-align: center;
    display: grid;
    place-items: center;
`;
const StyledSearchIconContainer = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;
const NoResultsTitle = styled.div`
    font-size: ${FontSize.LARGE_16};
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
    color: ${Theme.colors.gray_700};
`;

const ActionsList = styled.ul`
    padding: 0px;
`;

interface Props {
    records: GroupEnvironmentFormSummary[];
    searchTerm: string;

    updateRecords(formId: string, isAvailable: boolean): void;

    actionTypeWidthPercentage: number;
    enableEditLabel?: boolean;
    setLabel?: (id: string, label: string) => void;
    actionEntityIdToDropDownExtraAction?: Record<string, DropdownAction[]>;
}

const SolutionsBusinessReportActionsManagementActionsList: React.FC<Props> = ({
    records,
    updateRecords,
    searchTerm,
    actionTypeWidthPercentage,
    enableEditLabel,
    setLabel,
    actionEntityIdToDropDownExtraAction,
}) => {
    const actionsList = useMemo(() => {
        return records
            .filter((form) => (form.overrideLabel || form.displayName).toLowerCase().includes(searchTerm.toLowerCase()))
            .sort((first, second) => first.displayName?.localeCompare(second.displayName));
    }, [searchTerm, records]);

    const [selectedActions, setSelectedActions] = useState([...actionsList]);

    const onChangeSelectedActions = (actionId: string, isSelected: boolean) => {
        setSelectedActions(
            selectedActions.map((action) =>
                action.id === actionId
                    ? ({ ...action, isAvailable: isSelected } as GroupEnvironmentFormSummary)
                    : action,
            ),
        );
        updateRecords(actionId, isSelected);
    };

    const onChangeLabel = (actionId: string, label: string) => {
        const newActionStatuses = selectedActions.map((action) => {
            return action.id === actionId
                ? ({
                      ...action,
                      overrideLabel: label,
                  } as GroupEnvironmentFormSummary)
                : action;
        });

        setSelectedActions(newActionStatuses);
        setLabel && setLabel(actionId, label);
    };

    return actionsList.length > 0 ? (
        <ActionsList>
            {actionsList.map((form) => (
                <ActionsCheckboxRow
                    key={form.id}
                    form={form}
                    updateRecords={onChangeSelectedActions}
                    actionTypeWidthPercentage={actionTypeWidthPercentage}
                    enableEditLabel={enableEditLabel}
                    setLabel={onChangeLabel}
                    extraDropdownActions={
                        actionEntityIdToDropDownExtraAction ? actionEntityIdToDropDownExtraAction[form.id] : []
                    }
                />
            ))}
        </ActionsList>
    ) : (
        <StyledNoAction>
            <div>
                <StyledSearchIconContainer>
                    <StyledSearchIcon />
                </StyledSearchIconContainer>
                <NoResultsTitle>No results for "{searchTerm}"</NoResultsTitle>
                <div>Try refining your search</div>
            </div>
        </StyledNoAction>
    );
};

export default SolutionsBusinessReportActionsManagementActionsList;
