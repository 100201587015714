<div class="test-workflow-modal relative">
    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold mod-no-top">Test {{ pm.groupsMap[data.groupId].name }}</h1>

    <!-- Test workflow component -->
    <tnk-test-workflow group-id="{{ data.groupId }}" on-test-item-finished="onTestItemFinished()"></tnk-test-workflow>
</div>
