<div class="inline-block">
    <!-- Owner has access -->
    <div
        ng-if="lps.canPersonUpdate(item.owner)"
        class="pointer dropbox inline-block relative"
        uib-dropdown
        on-toggle="toggled(data.dropdownOpen)"
        is-open="data.dropdownOpen"
        uib-tooltip="{{
            manualGatherUpdate && manualGatherUpdate.date
                ? 'Next reminder: ' + (manualGatherUpdate.date | daysHoursDiff)
                : item.nextGatherUpdate
                ? 'Next reminder: ' + (item.nextGatherUpdate | daysHoursDiff)
                : ''
        }}"
        tooltip-placement="auto top"
        tooltip-enable="!noTooltip"
    >
        <div class="dropdown-toggle" uib-dropdown-toggle ng-disabled="viewOnly">
            <ng-transclude ng-if="!defaultDropdownToggleLabel"></ng-transclude>
            <div ng-if="defaultDropdownToggleLabel" class="common-color-light-grey2">
                {{ ((manualGatherUpdate ? manualGatherUpdate.date : item.nextGatherUpdate) | daysDiff) || 'None' }}
                <i class="fa fa-caret-down margin-left-xs"></i>
            </div>
        </div>
        <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="simple-dropdown-{{ item.id }}">
            <li class="dropdown-header">Set next follow-up to:</li>

            <li ng-if="item.owner.id !== as.currentUser.id" ng-click="askForUpdateNow(item)">
                <a>Now</a>
            </li>

            <li ng-repeat="reminder in reminders">
                <a ng-click="setReminder(item, reminder)">{{ reminder.label }}</a>
            </li>

            <li class="divider" ng-if="!hideCustom"></li>

            <li ng-if="!hideCustom">
                <a ng-click="onCustomClicked({item: item})">{{ customLabel || 'Custom' }}...</a>
            </li>
        </ul>
    </div>
    <!-- No access for owner -->
    <div ng-if="!lps.canPersonUpdate(item.owner)" class="common-color-danger">Not a Member</div>
</div>
