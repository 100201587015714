<div class="relative field-selector-container">
    <ui-select
        class="relative field-selector-ui-select"
        theme="bootstrap"
        ng-model="data.selectedWorkflowFolder"
        ng-disabled="!data.workflowFolderOptions.length"
        on-select="folderSelectionChange()"
    >
        <ui-select-match data-automation="workflow-folder-selector-select-solution-button" placeholder="Select Solution...">
            {{ data.selectedWorkflowFolder.displayName }}
        </ui-select-match>

        <ui-select-choices repeat="folder in data.workflowFolderOptions | filter:$select.search">
            {{ folder.displayName }}
        </ui-select-choices>
    </ui-select>

    <!-- Error message for when you have no solutions. -->
    <div ng-if="!data.workflowFolderOptions.length" class="common-color-danger common-size-xxxs margin-top">
        * You are not a maker of any existing solution.
    </div>
</div>
