import template from './fieldInspect.template.html?angularjs';

/**
 * Component to inspect fields of integrations, find matching fields and select them.
 */
angular.module('tonkean.app').component('tnkFieldInspect', {
    bindings: {
        projectIntegration: '<', // The project integration to load external fields from.
        externalType: '<', // The entity we're fetching fields for.
        externalTypeDisplayName: '<',
        fieldQuery: '<', // The initial search query for a field (for either its name or value) (if any).
        onFieldSelect: '&', // A mandatory callback that's fired when the user selects a field in the inspect table. Sends back the selected field name as fieldName.
        staticData: '<', // A static data provided to the inspector. Providing this replaces the need for projectIntegration and externalType.
        onlyUpdatable: '<', // If true, only updateable fields will be returned for the field inspector.
        viewOnly: '<', // If true, only updateable fields will be returned for the field inspector.
        useTracksOfGroupId: '<', // expected: GroupId or null. If exists, will search and show tracks and not the external items
        exampleEntity: '<', // If true, only updateable fields will be returned for the field inspector.
        fieldFilter: '<', // If exists, has to be a filter method to filter out fields from being displayed
        multiSelect: '<', // If true, will allow multi select
        workflowVersionId: '<', // If exist, the modal in opened inside a module context
        onlyItemsFromSelectedEnvironment: '<', // if set will user will only be able to see items from provided workflowVersionId
        shouldHaveEntityPrefix: '<', // If true the entity field will have the entity prefix.
        creatingCustomTriggerId: '<', // If exists, holds the id of the custom trigger that created the field
        isForMatchedItem: '<', // Whether the field is a matched item field
    },
    template,
    controller: 'FieldInspectCtrl',
});
