import type { TElement } from '@udecode/plate';
import React, { useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';
import type { SingleFieldChanged } from '../CommonWidgetConfiguration';

import { type CoreEditorRef, type HTMLEditorPlugin, HTMLEditor } from '@tonkean/editor';
import type { FieldDefinition, Initiative, WidgetBase, WorkflowVersion } from '@tonkean/tonkean-entities';
import { RouterContext } from '@tonkean/tui-basic/RouterLink';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 4px;
    justify-content: start;

    padding: 12px 16px;
    background: ${Theme.colors.disabled};
    border-top: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
`;

const StyledEditor = styled(HTMLEditor)`
    flex-grow: 1;
    border: none;
    border-radius: 4px;
`;

interface Props {
    widget: WidgetBase<RichTextEditorInputWidgetConfiguration>;
    plugins: HTMLEditorPlugin[];
    initialValueContent?: TElement[];
    initiative: Initiative;
    currentFieldDefinition: FieldDefinition;
    workflowVersion: WorkflowVersion;
    onSave: (changes: SingleFieldChanged) => Promise<void>;
    isReadOnly: boolean;
    isHideToolbar: boolean;
    setIsEditMode: (isEditMode: boolean) => void;
}

const RichTextEditor: React.FC<Props> = ({
    widget,
    plugins,
    initialValueContent,
    initiative,
    isReadOnly,
    currentFieldDefinition,
    onSave,
    isHideToolbar,
    setIsEditMode,
    workflowVersion,
}) => {
    const routerContextValue = useContext(RouterContext);

    if (!routerContextValue) {
        throw new Error(
            'Attempted to use RouterLink (or Clickable with state/params) without passing TonkeanRouter to ReactRoot',
        );
    }

    const editorRef = useRef<CoreEditorRef>(null);
    const [htmlValue, setHtmlValue] = useState(initialValueContent);
    const hasChanges = useMemo(
        () => JSON.stringify(initialValueContent) !== JSON.stringify(htmlValue),
        [htmlValue, initialValueContent],
    );

    const onChange = (value: TElement[]) => {
        setHtmlValue(value);
    };

    const onSaveClick = () => {
        try {
            // Important so 'null' will be saved in DB as field's empty value but the empty value in this component is [].
            const newValue = htmlValue && htmlValue.length > 0 ? JSON.stringify(htmlValue) : null;
            if (initiative && widget.configuration.selectedField?.key && currentFieldDefinition) {
                onSave({
                    package: {
                        fieldDefinition: currentFieldDefinition,
                        initiative,
                        groupId: initiative.group.id,
                        workflowVersion,
                    },
                    newValue,
                });
                setIsEditMode(false);
            }
        } catch (error: any) {
            console.error(`Failed to convert new rich text editor value from Json to String: ${error}`);
        }
    };

    // There are fields that return list with one empty object or null. This line ensures that the empty state is [].
    const initialValue =
        initialValueContent?.[0] && Object.keys(initialValueContent?.[0]).length === 0 ? [] : initialValueContent;

    return (
        <>
            <StyledEditor
                dataAutomation="rich-text-editor-input-widget"
                initialValue={initialValue}
                onChange={onChange}
                plugins={plugins}
                ref={editorRef}
                placeholder={widget.configuration.placeholderText}
                isReadOnly={isReadOnly}
                isHideToolbar={isHideToolbar}
            />
            {!isHideToolbar && (
                <ButtonsWrapper>
                    <Button
                        data-automation="rich-text-editor-input-widget-dismiss-button"
                        size={ButtonSize.SMALL}
                        onClick={() => {
                            editorRef.current?.setValue(initialValue || []);
                            setIsEditMode(false);
                        }}
                        outlined
                    >
                        Dismiss
                    </Button>
                    <Button
                        data-automation="rich-text-editor-input-widget-save-button"
                        size={ButtonSize.SMALL}
                        disabled={!hasChanges}
                        onClick={onSaveClick}
                    >
                        Save
                    </Button>
                </ButtonsWrapper>
            )}
        </>
    );
};

export default RichTextEditor;
