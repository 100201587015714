export function GitLoginCtrl($scope, createProjectApis, utils, $rootScope, projectManager) {
    const userInUrlRegex = /https?:\/\/([^:]+)(:(.+))?@/;

    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        state = state || {};
        $scope.instances = angular.copy(state.instances) || [{}];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;

        tryLoadFromEdit();
    };

    $scope.addAnother = function () {
        $scope.instances.push({});
    };

    $scope.removeIntegrationInstance = function ($index) {
        $scope.instances.splice($index, 1);
    };

    $scope.signIn = function (integration, isValid) {
        if (isValid) {
            integration.error = null;
            integration.connecting = true;
            createProjectApis
                .createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    integration.auth,
                    undefined,
                    undefined,
                )
                .then(function (integObj) {
                    integration.Obj = integObj;
                    return createProjectApis.getAutoCompleteOptions(
                        projectManager.project.id,
                        integration.Obj.id,
                        'branches',
                    );
                })
                .then(function (data) {
                    const branches = (integration.branches = data.options);
                    if (branches && branches.length > 0) {
                        // Select the master branch by default.
                        integration.branch =
                            branches.find(function (b) {
                                return b.displayName === 'master';
                            }) || branches[0];
                    }
                })
                .catch(function (error) {
                    integration.error = error;
                    if (error.status === 403) {
                        const isBasic = integration.auth.uri.indexOf('http') === 0;
                        integration.authType = isBasic ? 'basic' : 'ssh';
                        if (isBasic && !integration.auth.user) {
                            let m;
                            if ((m = userInUrlRegex.exec(integration.auth.uri)) !== null && m[1]) {
                                integration.auth.user = m[1];
                            }
                        }
                    }
                })
                .finally(function () {
                    integration.connecting = false;
                });
        }
    };

    $scope.ok = function () {
        const inValid = $scope.instances.some(function (integration) {
            return !integration.branch;
        });
        if (inValid) {
            return;
        }

        const s = {
            instances: $scope.instances,
        };

        s.integrations = $scope.instances.map(function (integration) {
            return {
                integration: integration.Obj,
                projectData: {
                    branch: integration.branch.value,
                },
            };
        });

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    function tryLoadFromEdit() {
        if (state.integrations && state.integrations.length > 0 && !state.instances) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            state.integrations.forEach(function (originalIntegration, index) {
                const integration = ($scope.instances[index] = {});
                integration.Obj = originalIntegration.integration;
                integration.auth = { uri: integration.Obj.integrationUri };
                createProjectApis
                    .getAutoCompleteOptions(projectManager.project.id, integration.Obj.id, 'branches')
                    .then(function (data) {
                        const branches = (integration.branches = data.options);
                        if (branches && branches.length > 0) {
                            integration.branch =
                                branches.find(function (b) {
                                    return b.value === originalIntegration.projectData.branch;
                                }) || branches[0];
                        }
                    })
                    .catch(function (error) {
                        integration.error = error;
                        if (error.status === 403) {
                            const isBasic = integration.auth.uri.indexOf('http') === 0;
                            integration.authType = isBasic ? 'basic' : 'ssh';
                            if (isBasic && !integration.auth.user) {
                                let m;
                                if ((m = userInUrlRegex.exec(integration.auth.uri)) !== null && m[1]) {
                                    integration.auth.user = m[1];
                                }
                            }
                        }
                    })
                    .finally(function () {
                        integration.connecting = false;
                    });
            });
        }
    }
}
export default angular.module('tonkean.shared').controller('GitLoginCtrl', GitLoginCtrl);
