import React, { useCallback } from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import { WidgetConfigurationToggle } from '../../CommonWidgetConfiguration';
import type SeparatorWidgetConfiguration from '../SeparatorWidgetConfiguration';

import {
    type CustomColor,
    PopoverHexColorPickerWithList,
    useCustomColorListFromProjectThemConfiguration,
    useFormikField,
} from '@tonkean/infrastructure';
import { useFilterOutHiddenColors } from '@tonkean/infrastructure';
import { useActualColorToDisplay } from '@tonkean/infrastructure';
import { Theme, FontSize, type Color } from '@tonkean/tui-theme';

const EditorWrapper = styled.div`
    padding: 16px;
    padding-top: 0;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
`;

const Row = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-hight: 10px;
    color: ${Theme.colors.gray_700};
    min-width: 60px;
    display: flex;
    justify-content: space-between;
`;

const SectionTitle = styled(ConfigurationSectionTitle)`
    margin-top: 24px;
    margin-bottom: 32px;
`;

const SeparatorWidgetEditor: React.FC<ItemWidgetEditorProps<SeparatorWidgetConfiguration>> = () => {
    const { value: separatorColor, setValue: setSeparatorColor } = useFormikField<Color | undefined>(
        'configuration.separatorColor',
    );
    const { value: separatorColorId, setValue: setSeparatorColorId } = useFormikField<string | undefined>(
        'configuration.separatorColorId',
    );

    const projectThemeConfigurationCustomColorList: CustomColor[] = useCustomColorListFromProjectThemConfiguration();
    const filteredHiddenColors = useFilterOutHiddenColors(projectThemeConfigurationCustomColorList);

    const actualSeparatorColor = useActualColorToDisplay(separatorColor, separatorColorId, Theme.colors.gray_400);

    const onChangeColor = useCallback(
        (color: string, customColorId?: string) => {
            setSeparatorColor(color);
            setSeparatorColorId(customColorId);
        },
        [setSeparatorColor, setSeparatorColorId],
    );

    return (
        <>
            <EditorWrapper>
                <SectionTitle>Separator Line</SectionTitle>
                <Row>
                    <Label>Separator Line Color</Label>
                    <PopoverHexColorPickerWithList
                        dataAutomation="separator-color-picker"
                        onChange={onChangeColor}
                        selectedColor={actualSeparatorColor}
                        customColors={filteredHiddenColors}
                        selectedCustomColorId={separatorColorId}
                        noBackgroundColor
                        showBorder
                    />
                </Row>
                <WidgetConfigurationToggle
                    dataAutomation="item-builder-widget-configuration-separator-dashed-line"
                    title="Dashed Line"
                    toolTip="When set to true, the separator line will be dashed"
                    name="configuration.isDashed"
                />
            </EditorWrapper>
        </>
    );
};

export default SeparatorWidgetEditor;
