<div
    ng-init="initiativesLable = (data.selectedList.entitiesName || 'initiatives'); initiativeLable = (data.selectedList.entityName || 'initiative'); integrationKey = (data.currentStep.data.integrationType || 'PRODUCT')"
></div>

<!--if choosen an accounts integration-->
<div
    ng-if="integrationsResults[integrationKey]"
    ng-controller="AdvancedSyncCtrl"
    ng-init="initializeSyncEntity(integrationKey, 0)"
>
    <div class="common-size-xs padding-top-xs">
        <strong>What {{ initiativesLable }} should we track?</strong>
        <span class="common-size-xxxxs">&nbsp;(in {{ integrationsResults[integrationKey].displayName }})</span>
        <div class="common-color-dark-grey common-size-xxs">
            Ex: filter by project, topic, or any other relevant condition
        </div>
    </div>
    <hr class="margin-top" />
    <div ng-init="initAdvancedSync()">
        <div class="margin-normal-v">
            <strong class="margin-right-xs">Use {{ integrationsResults[integrationKey].displayName }}'s:</strong>
            <tnk-entity-selector
                project-integration="integrationsResults[integrationKey]"
                selected-entity="{{ valueResults['SYNC_ENTITY'] }}"
                mode="sync"
                on-entity-selected="selectEntityOption(selectedEntity)"
            ></tnk-entity-selector>
        </div>
        <tnk-custom-filters
            control="valueResults['SYNC_CONTROL']"
            items-source="EXTERNAL"
            existing-definition="valueResults['SYNC_DEFINITION']"
            project-integration="integrationsResults[integrationKey]"
            from-sync="true"
            edit-mode="false"
            selected-entity-type="valueResults['SYNC_ENTITY']"
            hide-time-range-selection="true"
            show-field-inspect="true"
        ></tnk-custom-filters>
    </div>
</div>

<!--if didn't choose an accounts integration-->
<div ng-if="!integrationsResults[integrationKey]" class="padding-top-xs">
    <div class="common-size-xs margin-bottom" ng-init="addDefaultsToInitiativesResults()">
        <strong>{{ initiativesLable }} to track:</strong>
    </div>
    <div class="common-color-dark-grey common-size-xxs margin-bottom">
        Enter your top {{ initiativesLable }} names, due date and primary owner (team/function lead)
    </div>
    <hr />
    <div ng-include="'../directives/manualAddTracksTemplate.template.html'"></div>
</div>
