import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import InputSourceSelectionContentConfiguration from '../../../../infrastructure/components/Contracts/InputSourceSelectionContentConfiguration';
import DisableOnProduction from '../../../../infrastructure/components/DisableOnProduction';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { SimpleErrorStateMessage } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const Wrapper = styled.div`
    padding: 16px;
`;

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    workflowVersionType: WorkflowVersionType | undefined;
}

const ContractSidePaneTab: React.FC<Props> = ({ groupId, workflowVersionId, workflowVersionType }) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const {
        data: contractsResponse,
        loading: loadingContracts,
        error: errorLoadingContracts,
        rerun: rerunLoadContracts,
    } = useTonkeanService('getContractSummaries', groupId);

    if (errorLoadingContracts) {
        return <SimpleErrorStateMessage error={errorLoadingContracts} reload={rerunLoadContracts} />;
    }

    return (
        <DisableOnProduction>
            <Wrapper>
                <InputSourceSelectionContentConfiguration
                    contracts={contractsResponse?.entities || EMPTY_ARRAY}
                    loading={loadingContracts}
                    groupId={groupId}
                    workflowVersionType={workflowVersionType}
                    workflowVersionId={workflowVersionId}
                    onConfigurationChanged={() => {
                        workflowVersionManager.incrementDraftCounter(groupId);
                    }}
                />
            </Wrapper>
        </DisableOnProduction>
    );
};

export default ContractSidePaneTab;
