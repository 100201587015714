import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RowHeight } from './configurationEditorSettingsComponents/RowHeightSelector';
import InnerItemsItemWidgetHeaderActions from './InnerItemsItemWidgetHeaderActions';
import ItemInterfaceModal from '../../../components/ItemInterfaceModal';
import type { ItemInterfacePermission } from '../../../entities';
import SystemInterfaceModal from '../../../SystemInterface/components/SystemInterface/SystemInterfaceModal';
import useInitiativeNavigation from '../../../SystemInterface/hooks/useInitiativeNavigation';
import InitiativeNavigationContext from '../../../SystemInterface/hooks/useIntitiativeNavigationContext';
import { ItemWidget, ItemWidgetHeaderTitle } from '../../../WidgetModule';
import { ItemsGridWithValidation } from '../../CommonWidgetComponents';
import type { InnerItemWidgetConfiguration } from '../../entities';
import { useWidgetConfigurationFieldsSoftMigration } from '../../hooks';
import useItemsGridInlineMenuActions from '../../hooks/useItemsGridInlineMenuActions';
import useFieldDefinitionKeyToSettings from '../../LineItemsWidgetModule/hooks/useFieldDefinitionKeyToSettings';
import useGridDataIsLoading from '../../LineItemsWidgetModule/hooks/useGridDataIsLoading';
import useHandleCreateWrapper from '../../LineItemsWidgetModule/hooks/useHandleCreateWrapper';
import useHandleUpdateWrapper from '../../LineItemsWidgetModule/hooks/useHandleUpdateWrapper';
import useItemsGridCrudHandlers from '../../LineItemsWidgetModule/hooks/useItemsGridCrudHandlers';
import useLineItemPermission from '../../LineItemsWidgetModule/hooks/useLineItemPermission';
import { useFieldsToDisplay, useGetFieldDefinitions } from '../hooks';
import useDrilldownInterface from '../hooks/useDrilldownInterface';
import useReloadInnerItemsInitiatives from '../hooks/useReloadInitiatives';
import useSearchInitiativesQuery from '../hooks/useSearchInitiativesQuery';
import itemsTableGridStyles from '../styles/itemsTableGridStyles';
import EMPTY_CONDITIONS_DEFINITION from '../utils/emptyConditionsDefinition';
import ROW_HEIGHTS from '../utils/rowHeight';

import { useFeatureFlag, useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Breakpoint,
    DataNotUpToDateIndicator,
    Spacer,
    useBreakpoint,
    useDebouncedState,
} from '@tonkean/infrastructure';
import { ColumnId, useItemsGridRowsManager } from '@tonkean/items-grid';
import {
    type FieldDefinition,
    type Initiative,
    type TonkeanId,
    type TonkeanType,
    type WidgetBase,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { EMPTY_ARRAY, typedObjectKeys } from '@tonkean/utils';

const TitleContainer = styled.div`
    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: flex;
        flex-direction: row;
    }
`;

const HeaderWrapper = styled.div`
    align-items: center;
    justify-content: center;
`;

const DRILLDOWN_COLUMN_ID = ColumnId.Title;

interface Props {
    widget: WidgetBase<InnerItemWidgetConfiguration>;
    permission: ItemInterfacePermission;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    workflowVersionType: WorkflowVersionType | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
    environmentIsActive?: boolean;
    showModuleOffIndication?: boolean;
    headerBackgroundColor?: string;
    reloadInitiativeFromServer?: () => void;
    footer?: React.FC<{
        parentInitiativeId: Initiative['id'] | undefined;
        initiatives: Initiative[];
        widget: WidgetBase<InnerItemWidgetConfiguration>;
        workflowVersionType: WorkflowVersionType | undefined;
        hasMoreInitiatives: boolean;
        totalInitiatives: number | undefined;
    }>;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const InnerItemsAgGridWidgetDisplay: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    widget,
    permission,
    workflowVersionType,
    environmentIsActive,
    showModuleOffIndication,
    headerBackgroundColor = Theme.colors.primary,
    reloadInitiativeFromServer,
    footer: Footer,
    originatedCustomTriggerId,
}) => {
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useDebouncedState(debouncedSearchTerm, setDebouncedSearchTerm);

    const projectManager = useAngularService('projectManager');
    const [fieldDefinitions, setFieldDefinitions] = useState<FieldDefinition[]>([]);

    const [{ loading: workflowVersionLoading, data: workflowVersion }, getWorkflowVersionById] =
        useLazyTonkeanService('getWorkflowVersionById');

    useEffect(() => {
        if (workflowVersionId) {
            getWorkflowVersionById(workflowVersionId);
        }
    }, [getWorkflowVersionById, workflowVersionId]);

    useGetFieldDefinitions(workflowVersionId, workflowVersionType, typedObjectKeys, widget, setFieldDefinitions);

    const effectiveBreakpoint = useBreakpoint();
    const isMobile = Number(effectiveBreakpoint) <= Breakpoint.MID_XSMALL_768;

    const configurationFields = useWidgetConfigurationFieldsSoftMigration(widget.configuration.fields);

    const fieldsToDisplay = useFieldsToDisplay(configurationFields);

    const { fieldDefinitionKeyToLabel } = useFieldDefinitionKeyToSettings(widget);

    const itemsLimit = useFeatureFlag<number>('tonkean_feature_items_table_limit') || 50;
    const { reloadInitiatives, initiatives, loading, isFetched, executionDate, fastInterval } =
        useReloadInnerItemsInitiatives(
            widget,
            groupId,
            workflowVersionType,
            itemsLimit,
            '',
            widget.configuration.filters,
        );

    useEffect(() => {
        reloadInitiatives();
    }, [reloadInitiatives]);

    const { handleUpdate, handleCreate, handleDelete } = useItemsGridCrudHandlers(
        widget.projectId,
        groupId,
        undefined,
        workflowVersion,
        originatedCustomTriggerId,
        undefined,
    );

    const handleCreateWrapper = useHandleCreateWrapper(handleCreate, fastInterval);
    const handleUpdateWrapper = useHandleUpdateWrapper(handleUpdate, fastInterval);

    const { userCanAddLineItems, userCanEditLineItems } = useLineItemPermission(widget, groupId, workflowVersionType);

    const { items, onUpdate, onDelete, isInitialized } = useItemsGridRowsManager(
        initiatives,
        fieldDefinitions,
        isFetched && !loading,
        executionDate ?? Date.now(),
        userCanAddLineItems,
        0,
        itemsLimit,
        EMPTY_ARRAY,
        undefined,
        handleUpdateWrapper,
        handleCreateWrapper,
        handleDelete,
    );

    const gridDataIsLoading = useGridDataIsLoading(loading, isFetched, !fieldDefinitions, isInitialized);

    const inlineActions = useItemsGridInlineMenuActions(
        widget.configuration?.actions || EMPTY_ARRAY,
        workflowVersion?.workflowVersionType || WorkflowVersionType.PUBLISHED,
        groupId,
    );

    const rowHeight = ROW_HEIGHTS[widget.configuration?.rowHeight || RowHeight.SMALL];

    const searchInitiativesQuery = useSearchInitiativesQuery(
        widget,
        EMPTY_ARRAY,
        EMPTY_CONDITIONS_DEFINITION,
        groupId,
        debouncedSearchTerm,
        undefined,
        workflowVersionType,
    );

    const { modalIsOpen, modalInitiativeId, onClose, onRowClicked, setModalInitiativeId } = useDrilldownInterface(
        searchInitiativesQuery,
        DRILLDOWN_COLUMN_ID,
    );

    const itemInterfaceNavigation = useInitiativeNavigation(modalInitiativeId, setModalInitiativeId);

    return (
        <ItemWidget
            headerTitle={
                <TitleContainer>
                    <ItemWidgetHeaderTitle>
                        <HeaderWrapper>
                            {widget?.displayName ?? 'Inner Items '} <Spacer width={8} />
                            <DataNotUpToDateIndicator show={!environmentIsActive && showModuleOffIndication} />
                        </HeaderWrapper>
                    </ItemWidgetHeaderTitle>
                </TitleContainer>
            }
            className="innerItemWidget"
            permission={permission}
            headerActions={
                <>
                    {!isMobile && (
                        <InnerItemsItemWidgetHeaderActions
                            searchText={searchTerm}
                            setSearchText={setSearchTerm}
                            widget={widget}
                            workflowVersionId={workflowVersionId}
                            headerBackgroundColor={headerBackgroundColor}
                            onInitiativeCreated={reloadInitiatives}
                            workflowVersionType={workflowVersionType}
                            reloadInitiativeFromServer={reloadInitiativeFromServer}
                            originatedCustomTriggerId={originatedCustomTriggerId}
                        />
                    )}
                </>
            }
            subHeader={
                isMobile ? (
                    <InnerItemsItemWidgetHeaderActions
                        searchText={searchTerm}
                        setSearchText={setSearchTerm}
                        widget={widget}
                        workflowVersionId={workflowVersionId}
                        headerBackgroundColor={headerBackgroundColor}
                        onInitiativeCreated={reloadInitiatives}
                        workflowVersionType={workflowVersionType}
                        reloadInitiativeFromServer={reloadInitiativeFromServer}
                        originatedCustomTriggerId={originatedCustomTriggerId}
                    />
                ) : undefined
            }
            enableHorizontalScrolling
            noPaddingBody
        >
            <ItemsGridWithValidation
                widgetId={widget.id}
                rowData={items}
                searchTerm={debouncedSearchTerm}
                groupId={groupId}
                workflowVersion={workflowVersion}
                onChange={onUpdate}
                projectId={widget.projectId}
                loading={gridDataIsLoading || workflowVersionLoading}
                userCanEdit={userCanEditLineItems}
                fieldDefinitionsToShow={fieldsToDisplay}
                fieldDefinitionKeyToName={fieldDefinitionKeyToLabel}
                onDelete={onDelete}
                fieldDefinitions={fieldDefinitions}
                inlineMenuActions={inlineActions}
                isEmphasizeText={!!widget.configuration.emphasizeText}
                backgroundColor={projectManager.project.themeConfiguration.primaryColor}
                fixedRowHeight={rowHeight}
                hasHorizontalBorder={false}
                allowFilter={widget.configuration.displayAdvancedFilters}
                onRowClicked={onRowClicked}
                drilldownColumnId={DRILLDOWN_COLUMN_ID}
                gridStyles={itemsTableGridStyles}
                suppressMenuHide={false}
                hasRowMarker={false}
                hasVerticalBorder
                forceGridHeight
                showOptionsMenu
            />

            {widget.configuration.drillDownInterface && modalInitiativeId && (
                <InitiativeNavigationContext.Provider value={itemInterfaceNavigation}>
                    <ItemInterfaceModal
                        itemInterfaceId={widget.configuration.drillDownInterface}
                        initiativeId={modalInitiativeId}
                        projectId={widget.projectId}
                        onClose={onClose}
                        open={modalIsOpen}
                        showNavigation
                    />
                </InitiativeNavigationContext.Provider>
            )}
            {!widget.configuration.drillDownInterface && (
                <InitiativeNavigationContext.Provider value={itemInterfaceNavigation}>
                    <SystemInterfaceModal open={modalIsOpen} initiativeId={modalInitiativeId} onClose={onClose} />
                </InitiativeNavigationContext.Provider>
            )}
        </ItemWidget>
    );
};

export default InnerItemsAgGridWidgetDisplay;
