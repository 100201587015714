<div class="change-plan-modal">
    <!-- Close button -->
    <div class="common-close-btn mod-big mod-with-text padding-normal-sm" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <div class="change-plan-modal-content">
        <!-- Title -->
        <div class="common-title text-center margin-top-lg">
            <span>Change your plan</span>
        </div>
        <!-- Body -->
        <div class="margin-top-xlg">
            <div class="flex-vmiddle mod-justify-space flex-xs-wrap">
                <span class="common-title-xs mod-light flex-no-shrink">Want to make a switch?</span>
                <a href="https://tonkean.com/pricing/" target="_blank" class="common-size-xxs">Pricing plans</a>
            </div>
            <hr class="common-separator mod-darker margin-top margin-bottom-lg" />

            <!-- Plan selection -->
            <div class="flex-vmiddle mod-justify-space padding-top padding-bottom">
                <div class="margin-left-xs">
                    <div class="dropbox flex-no-shrink" uib-dropdown on-toggle="toggled(open)">
                        <div class="common-bold pointer" uib-dropdown-toggle>
                            <span>{{ sharedData.selectedTier.displayName }}</span>
                            <i class="dropdown-chevron mod-static visible-on-hover margin-left-xxs"></i>
                        </div>
                        <ul uib-dropdown-menu class="dropdown-menu" role="menu">
                            <li ng-repeat="tier in sharedData.tiers">
                                <a ng-click="sharedData.selectedTier = tier; refreshNewPlan()">
                                    <div class="flex mod-justify-space">
                                        <div class="flex-grow">
                                            <div>{{ tier.displayName }}</div>
                                        </div>
                                        <span
                                            class="
                                                svg-icon-xxs
                                                margin-left
                                                common-color-primary
                                                flex-no-shrink
                                                svg-icon-align-text-base
                                            "
                                            ng-if="sharedData.selectedTier.apiName === tier.apiName"
                                        >
                                            <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr class="common-separator mod-darker margin-top-lg margin-bottom-lg" />

            <!-- Seats -->
            <div class="flex-vmiddle mod-justify-space common-bold">
                <span class="margin-left-xs flex-no-shrink margin-right-xs">Number of seats</span>
                <div class="popover-primary-bg-container mod-left common-size-xxl margin-right-md">
                    <!-- Seats input -->
                    <div
                        uib-popover-template="'change-plan-not-enough-seats-template'"
                        popover-is-open="data.newQuantity < data.numberOfFullUsers"
                        popover-placement="left"
                        popover-trigger="none"
                    >
                        <input
                            type="number"
                            class="form-control org-seats-box mod-input block change-plan-seats-input"
                            ng-init="data.newQuantity = plan.quantity; tempQuantity = data.newQuantity;"
                            ng-model="tempQuantity"
                            ng-change="data.newQuantity = parseNewPlanQuantity(tempQuantity)"
                            ng-blur="tempQuantity = data.newQuantity"
                            min="1"
                        />
                    </div>
                </div>
            </div>
            <hr class="common-separator mod-darker margin-top-lg margin-bottom-lg" />

            <!-- Interval and monthly price -->
            <div class="flex-vmiddle mod-justify-space">
                <div class="margin-left-xs">
                    <div class="dropbox flex-no-shrink" uib-dropdown on-toggle="toggled(open)">
                        <div class="common-bold pointer" uib-dropdown-toggle>
                            <span>{{ sharedData.selectedInterval + 'ly' | capitalize }}</span>
                            <i class="dropdown-chevron mod-static visible-on-hover margin-left-xxs"></i>
                        </div>
                        <ul uib-dropdown-menu class="dropdown-menu" role="menu">
                            <li ng-repeat="interval in sharedData.intervalsArray">
                                <a ng-click="sharedData.selectedInterval = interval; refreshNewPlan()">
                                    <div class="flex mod-justify-space">
                                        <div class="flex-grow">
                                            <div>{{ interval + 'ly billing' | capitalize }}</div>
                                            <div
                                                ng-if="interval === sharedData.intervals.year"
                                                class="common-color-dark-grey"
                                            >
                                                Save over 15%
                                            </div>
                                        </div>
                                        <span
                                            class="
                                                svg-icon-xxs
                                                margin-left
                                                common-color-primary
                                                flex-no-shrink
                                                svg-icon-align-text-base
                                            "
                                            ng-if="interval === sharedData.selectedInterval"
                                        >
                                            <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                        </span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="common-color-link-blue common-size-xxs"
                        ng-if="sharedData.selectedInterval === sharedData.intervals.year"
                    >
                        You're saving 15%!
                    </div>
                </div>
                <div class="margin-right-md">
                    <div class="common-size-xxl common-bold line-height-normal">
                        ${{ changePlanData.newPlan.amountPerSeatPerMonth | number: 2 }}
                    </div>
                    <div class="common-size-xxxxs text-right">Per seat/month</div>
                </div>
            </div>
            <hr class="common-separator mod-darker margin-top-lg margin-bottom-lg" />

            <!-- Total price -->
            <div class="flex-vmiddle mod-justify-space">
                <span class="margin-left-xs common-bold">Total payment</span>
                <div class="margin-right-md">
                    <div class="common-size-xxl common-bold line-height-normal">
                        ${{
                            changePlanData.newPlan.amountPerSeatPerMonth *
                                changePlanData.newPlanTotalPriceFactor *
                                tempQuantity | number: 2
                        }}
                    </div>
                    <div class="common-size-xxxxs text-right">{{ changePlanData.newPlan.interval }}ly</div>
                </div>
            </div>
            <hr class="common-separator mod-darker margin-top-lg margin-bottom-lg" />
        </div>
        <!-- Buttons -->
        <div class="text-center margin-top-xlg">
            <button
                class="btn btn-lg mod-wide btn-primary"
                ng-click="$close()"
                ng-disabled="data.newQuantity < data.numberOfFullUsers"
            >
                Change plan
            </button>
            <div
                ng-if="changePlanData.newPlan.interval === 'year'"
                class="common-color-dark-grey common-size-xxs margin-top"
            >
                You'll be charged immediately for the yearly cost
            </div>
        </div>
        <!-- Powered by stripe -->
        <div class="margin-top-xlg text-center">
            <a href="https://stripe.com" target="_blank" class="powered-by-stripe block margin-top padding-top-xs">
                <img src="/images/powered-by-stripe.png" alt="powered by Stripe" />
            </a>
        </div>
    </div>
</div>

<!-- Not enough seats for current full users popover template -->
<script type="text/ng-template" id="change-plan-not-enough-seats-template">
    <div class="common-bold">
        You have to <a href="" ui-sref="product.settings.license({tab: 'members'})" class="common-link-no-color common-underline">remove</a> {{data.numberOfFullUsers - data.newQuantity > 1 ? (data.numberOfFullUsers - data.newQuantity) + ' full users' : 'a full user'}}
    </div>
</script>
