import { angularToReact } from '@tonkean/angular-components';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

export type ProjectIntegrationSelectorProps = {
    selectedProjectIntegrationId: string | undefined;
    groupId?: string;
    onProjectIntegrationSelected: (projectIntegration: ProjectIntegration) => void;
    filterOnIntegrationType?: string[];
    disabled?: boolean;
    formDefaultSelectionDataStorageId?: string;
    selectorLabelPlaceholder?: string;
    isFormDefaultSelected?: boolean;
    displayFormDefault?: boolean;
    allowDefaultOption?: boolean;
    includeAllNoCodeDatasources?: boolean;
    isForUpload?: boolean;
    isForDownload?: boolean;
};
const TnkProjectIntegrationSelector = angularToReact<ProjectIntegrationSelectorProps>(
    'tnk-project-integration-selector',
    {
        onProjectIntegrationSelected: ['selectedProjectIntegration'],
    },
);

export default TnkProjectIntegrationSelector;
