import React from 'react';
import styled from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    height: 60px;
`;

const Content = styled.div`
    display: flex;
    padding: 13px 10px 13px 19px;
    flex-grow: 1;
`;

const ContentPlaceholder = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
`;

const SubtitlePlaceholder = styled(Placeholder)`
    margin-top: 8px;
`;

const UserActionItemPlaceholder: React.FC = () => {
    return (
        <Item>
            <Content>
                <Placeholder $width="20px" $height="20px" $circle />
                <ContentPlaceholder>
                    <Placeholder $width="400px" $height="14px" />
                    <SubtitlePlaceholder $width="200px" $height="12px" />
                </ContentPlaceholder>
            </Content>
        </Item>
    );
};

export default UserActionItemPlaceholder;
