<span>
    {{
        item.reference1.peopleCount === 1
            ? 'You have recently indicated that:'
            : item.reference1.peopleCount === 2
            ? 'You
    and someone else have recently indicated that:'
            : 'You and ' + item.reference1.peopleCount + ' others have indicated
    that:'
    }}
</span>
