import React from 'react';
import styled from 'styled-components';

import { ReactComponent as XButton } from '../../../../../images/icons/x.svg';

import { ErrorMessage, Input, Paragraph } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntityFieldMetadataSubField } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const AddFieldButton = styled.a`
    color: ${Theme.current.palette.colorPicker.HEX_2F84DC};
    font-size: ${FontSize.SMALL_12};
`;

const Container = styled.div`
    width: 50%;
`;

const InputContainer = styled.div`
    margin-bottom: 8px;
    display: flex;
`;

const FieldWrapper = styled(Paragraph)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const FieldRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 0;
`;

interface CustomFieldRowProps {
    initialSubFields: ProjectIntegrationEntityFieldMetadataSubField[];
    dataAutomation: string;
    fieldLabel: string;
    subTitle?: string;
    disabled?: boolean;
    subFieldsValidationError: string | undefined;
    addSubField: () => void;
    removeSubField: (index: number) => void;
    editSubField: (index: number, id: string, newName: string) => void;
}

const CustomFieldRowMultiInput: React.FC<CustomFieldRowProps> = ({
    initialSubFields,
    dataAutomation,
    fieldLabel,
    subTitle,
    disabled,
    subFieldsValidationError,
    addSubField,
    removeSubField,
    editSubField,
}) => {
    return (
        <FieldRow data-automation={dataAutomation}>
            <FieldWrapper>
                <Paragraph>{fieldLabel}</Paragraph>
                {subTitle && <Paragraph $color={Theme.colors.gray_500}>{subTitle}</Paragraph>}
            </FieldWrapper>
            <Container>
                {initialSubFields.map((field, index) => (
                    <InputContainer key={`input_container_${index}`}>
                        <Input
                            key={`${field.id}-${index}`}
                            data-automation={`sub-field-input-${index}`}
                            type="text"
                            value={field.name}
                            disabled={disabled}
                            onChange={(e) => editSubField(index, field.id, e.target.value)}
                        />
                        <IconButton
                            key={`icon-button-sub-field-x-button-${index}`}
                            data-automation={`sub-field-x-button-${index}`}
                            onClick={() => removeSubField(index)}
                            flat
                        >
                            <XButton />
                        </IconButton>
                    </InputContainer>
                ))}
                {subFieldsValidationError && (
                    <ErrorMessage dataAutomation="sub-fields-validation-error">{subFieldsValidationError}</ErrorMessage>
                )}
                <AddFieldButton data-automation="add-sub-field-button" onClick={addSubField}>
                    Add a sub field
                </AddFieldButton>
            </Container>
        </FieldRow>
    );
};

export default CustomFieldRowMultiInput;
