function RevertDraftVersionCtrl(
    $scope,
    $q,
    $timeout,
    $uibModalInstance,
    $rootScope,
    tonkeanService,
    groupManager,
    workflowVersionManager,
    groupId,
    solutionSitePageId,
    subSequentialIdentifier,
    entityVersionId,
) {
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        groupId,
        solutionSitePageId,
        subSequentialIdentifier,
        entityVersionId,
        revertingDraftWorkflowVersion: false,
        errorRevertingDraftWorkflowVersion: false,

        activityActorSelectionPopoverOpen: false,
        selectedActorPeopleToFilterOn: [],
        workerAuditLogsControlObject: {},

        changesFromDate: null,
        onlyActivityTypes: workflowVersionManager.getWorkflowRelevantActivityTypes(),
    };

    /**
     * Initialization function for the modal.
     */
    $scope.init = function () {
        $scope.data.changesFromDate = workflowVersionManager.getChangesFromDate($scope.data.groupId);
    };

    $scope.init();

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    /**
     * Occurs once the person added.
     */
    $scope.onActorPersonAdded = function () {
        $scope.data.workerAuditLogsControlObject.onActorPeopleSelected($scope.data.selectedActorPeopleToFilterOn);
    };

    /**
     * Opens the activity actor person selection popover.
     */
    $scope.openActivityActorPersonSelection = function () {
        $scope.data.activityActorSelectionPopoverOpen = true;

        const ngPopoverElement = angular.element(document.querySelector('#activity-actor-selection-popover'));
        const ngContainer = angular.element(document.querySelector('#activity-actor-selection-popover-container'));

        ngContainer.append(ngPopoverElement);

        // This is important to make the popover show right after the first click, and not have to double click the link for it to show.
        // Our theory here is that appending the element of the popover requires a digest loop to be rendered, and by calling timeout
        // we trigger such digest loop and the popover gets displayed.
        $timeout(() => {});
    };

    /**
     * Closes the popover for the actor selection.
     */
    $scope.resetActivityActorSelectionPopover = function () {
        $scope.data.activityActorSelectionPopoverOpen = false;

        const ngPopoverElement = angular.element(document.querySelector('#activity-actor-selection-popover'));
        const ngContainer = angular.element(document.querySelector('#activity-actor-selection-popover-parent'));

        ngContainer.append(ngPopoverElement);
    };

    /**
     * Occurs once the person removed.
     */
    $scope.onActorPersonRemoved = function () {
        $scope.data.workerAuditLogsControlObject.onActorPeopleSelected($scope.data.selectedActorPeopleToFilterOn);
    };

    /**
     * Reverts the draft workflow version of the bot.
     */
    $scope.revertDraftWorkflowVersion = function () {
        $scope.data.revertingDraftWorkflowVersion = true;
        $scope.data.errorRevertingDraftWorkflowVersion = false;

        promptConfirm().then(
            () => {
                if ($scope.data.groupId) {
                    // User clicked OK
                    groupManager
                        .revertDraftWorkflowVersion($scope.data.groupId)
                        .then(() => {
                            // Closing modal on success and get groups that is finished.
                            $uibModalInstance.close(true);
                            return $q.resolve();
                        })
                        .catch(() => {
                            $scope.data.errorRevertingDraftWorkflowVersion = true;
                        })
                        .finally(() => {
                            $scope.data.revertingDraftWorkflowVersion = false;
                        });
                }

                if ($scope.data.solutionSitePageId) {
                    tonkeanService
                        .revertSolutionSitePage($scope.data.solutionSitePageId)
                        .then(() => {
                            $uibModalInstance.close(true);
                            return $q.resolve();
                        })
                        .catch(() => {
                            $scope.data.errorRevertingDraftWorkflowVersion = true;
                        })
                        .finally(() => {
                            $scope.data.revertingDraftWorkflowVersion = false;
                        });
                }
            },
            () => {
                // User clicked Cancel
                $scope.data.revertingDraftWorkflowVersion = false;
            },
        );
    };

    function promptConfirm() {
        $scope.mboxData = {
            title: 'Discard Changes',
            body: 'Are you sure you want to discard your changes?',
            isWarn: true,
            okLabel: 'Yes',
            cancelLabel: 'Cancel',
        };

        return $rootScope.modal.openMessageBox({
            scope: $scope,
            size: 'md',
            windowClass: 'mod-danger',
        }).result;
    }
}

export default angular.module('tonkean.app').controller('RevertDraftVersionCtrl', RevertDraftVersionCtrl);
