import React from 'react';

import type { CustomSelectorProps } from './ContractFieldsMapping';

import { FieldSelector } from '@tonkean/fields';
import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

type Props = CustomSelectorProps<FieldDefinition> & {
    includeSpecialFields?: boolean;
};

const ContractFieldsSelector: React.FC<Props> = ({
    onChange,
    selectedField,
    loading,
    options,
    includeSpecialFields,
}) => {
    return (
        <FieldSelector
            fieldsDefinitions={options}
            onChange={onChange}
            loading={loading}
            placeholder="Select..."
            fieldDefinitionId={selectedField}
            size={InputSize.XMEDIUM_LARGE}
            includeSpecialFields={includeSpecialFields}
            thin
            isClearable
        />
    );
};

export default ContractFieldsSelector;
