import React from 'react';

import InnerItemsItemWidgetConfigurationEditorDisplay from './configurationEditorDisplays/InnerItemsItemWidgetConfigurationEditorDisplay';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import type { InnerItemWidgetConfiguration } from '../../entities';

import { useItemInterfaceContext } from '@tonkean/infrastructure';

const InnerItemsItemWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<InnerItemWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const { initiative, itemInterfaceFieldDefinitions } = useItemInterfaceContext();

    return (
        <InnerItemsItemWidgetConfigurationEditorDisplay
            initiative={initiative}
            configuration={configuration}
            fieldDefinitions={itemInterfaceFieldDefinitions}
            workflowVersionId={workflowVersion.id}
            groupId={workflowVersion.groupId}
        />
    );
};

export default InnerItemsItemWidgetConfigurationEditor;
