import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function PersonSelectionConfigurationCtrl(
    $scope,
    $q,
    $state,
    projectManager,
    utils,
    consts,
    integrations,
    tonkeanUtils,
    customTriggerManager,
    communicationIntegrationsService,
) {
    const ctrl = this;
    const autoFillOwnerFieldsSuggestions = consts.getAutoFillOwnerFieldsSuggestions();

    $scope.tonkeanUtils = tonkeanUtils;
    $scope.pm = projectManager;

    $scope.personContainerOptions = {
        tonkean: 'tonkean',
        person: 'person',
        serviceAccount: 'serviceAccount',
    };

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        radioIdPrefix: ctrl.radioIdPrefix || '',
        savedPersonSelectionConfiguration: ctrl.savedPersonSelectionConfiguration,
        savedServiceAccountSelectionConfiguration: ctrl.savedServiceAccountSelectionConfiguration,
        validationObject: ctrl.validationObject,
        allowCustom: ctrl.allowCustom ? ctrl.allowCustom : false,
        allowNone: ctrl.allowNone ? ctrl.allowNone : false,
        noTracksOwnerOption: ctrl.noTracksOwnerOption,
        showTonkeanOption: ctrl.showTonkeanOption,
        showServiceAccountOption: ctrl.showServiceAccountOption,
        specificPeopleCustomOptions: ctrl.specificPeopleCustomOptions,
        specificPeopleCustomOptionsRefresh: ctrl.specificPeopleCustomOptionsRefresh,
        supportQuickCreate: ctrl.supportQuickCreate,
        maxPeople: ctrl.maxPeople,
        noneOptionLabel: ctrl.noneOptionLabel,
        hideFailIfNoValue: ctrl.hideFailIfNoValue,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        showSendBy: ctrl.showSendBy,
        showSendTo: ctrl.showSendTo,
        showFromFieldTooltip: utils.isNullOrUndefined(ctrl.showFromFieldTooltip)
            ? ctrl.showSendBy
            : ctrl.showFromFieldTooltip,
        showSendAsGroupMessage: ctrl.showSendAsGroupMessage,
        enableClearSelection: ctrl.enableClearSelection,
        fromFieldTooltip: ctrl.fromFieldTooltip || 'Will accept multiple values separated by a comma.',

        selectedSendTo: null,
        selectedEmailProviderProjectIntegrationId: null,

        personSelectionConfigurationOptions: {
            initiativeOwner: 'initiativeOwner',
            previousActor: 'previousActor',
            specificPeople: 'specificPeople',
            fromField: 'fromField',
            custom: 'custom',
            useNone: 'none',
            isTonkeanRootUser: 'isTonkeanRootUser',
        },

        failIfNoValue: true,

        selectedCommunicationMode: null,

        noPreviousActorOption: false,

        // Selected option
        personSelectionConfigurationOption: ctrl.defaultSelectedOption || null,
        // Selected sender (Tonkean or Person).
        personSenderConfigurationOption: $scope.personContainerOptions.tonkean,

        // Multi people selection
        selectedPeopleIds: [],

        // Single custom specific person selected (when ctrl.specificPeopleCustomOptions is supplied).
        selectedPerson: null,

        // From integration field - create mode - project integration
        projectDataImportIntegrations: {},
        currentIntegration: null,
        integrationState: {},
        integrationsSettings: integrations.getFieldSettings(),
        nonExistingIntegrationSources: [],
        selectedProjectIntegration: null,
        // From integration field - entity + field
        selectedIntegrationEntity: null,

        fromFieldExpressionDefinition: null,
        onBehalfOfExpressionDefinition: null,
        fromServiceAccountExpressionDefinition: null,

        autoFillFieldName: '',

        specialFieldsForFeatures: ['OTHER_ONLY'],

        // Evaluated example
        evaluatedExpressionValue: null,
        evaluatedExpressionLoading: false,
        evaluationError: '',
        hidePreview: ctrl.hidePreview,

        doNotEvaluatePreview: ctrl.doNotEvaluatePreview,
        exampleInitiative: null,

        uniqueIdentifier: ctrl.uniqueIdentifier,
        communicationProjectIntegrationSelector: ctrl.communicationProjectIntegrationSelector,

        onlyTonkeanOption: ctrl.onlyTonkeanOption,

        automationIdentifier: ctrl.automationIdentifier,
        enableChoosingTonkean: ctrl.enableChoosingTonkean,
        onBehalfOfCustomPerson: ctrl.onBehalfOfCustomPerson,
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        initializeIntegrationSources();

        initializeCommunicationIntegrationsVariables();

        // If communication integration is set, set it as the default
        if (!$scope.data.selectedCommunicationMode) {
            $scope.data.selectedCommunicationMode =
                $scope.data.projectHasCommunicationIntegration &&
                $scope.data.possibleCommunicationIntegrationsNamesString
                    ? 'integration'
                    : 'email';

            // If we defaulted to email we want to update the parent that a change has been made (UI only)
            if ($scope.data.selectedCommunicationMode === 'email') {
                $scope.onPersonSelectionConfigurationChanged(false);
            }
        }

        if (!$scope.data.selectedSendTo) {
            $scope.data.selectedSendTo = 'INTERNAL';
        }

        if (ctrl.overrideExampleInitiative) {
            $scope.data.exampleInitiative = ctrl.overrideExampleInitiative;
        }
    };

    /**
     * Occurs on changes to component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.validationObject) {
            $scope.data.validationObject = changesObj.validationObject.currentValue;
        }

        let shouldInitializeEditMode = false;

        // Either a change to savedPersonSelectionConfiguration or specificPeopleCustomOptions should trigger an initialization of edit mode,
        // since edit mode initialization depends on both of these properties.
        // We use the flag to not call the initialization function twice.
        if (changesObj.savedPersonSelectionConfiguration) {
            $scope.data.savedPersonSelectionConfiguration = changesObj.savedPersonSelectionConfiguration.currentValue;
            shouldInitializeEditMode = true;
        }

        if (changesObj.specificPeopleCustomOptions) {
            $scope.data.specificPeopleCustomOptions = changesObj.specificPeopleCustomOptions.currentValue;
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = changesObj.previewEvaluationSource.currentValue;
        }

        if (changesObj.onlyTonkeanOption) {
            $scope.data.onlyTonkeanOption = changesObj.onlyTonkeanOption.currentValue;

            if (changesObj.onlyTonkeanOption.currentValue === true) {
                $scope.onPersonSenderConfigurationOptionSelected($scope.personContainerOptions.tonkean, false);
            }
        }

        if (shouldInitializeEditMode) {
            initializeCommunicationIntegrationsVariables();
            initializeEditMode();
        }

        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            $scope.data.noPreviousActorOption = true;
            if (
                $scope.data.configuredLogic &&
                $scope.data.configuredLogic.node &&
                $scope.data.configuredLogic.node.id
            ) {
                const logicBlockTypes = consts.getLogicBlockTypes();

                const previousActorLogicParents = [
                    logicBlockTypes.BOT_BUTTON_PRESSED.type,
                    logicBlockTypes.BOT_THREAD_REPLY.type,
                ];

                $scope.data.noPreviousActorOption = !customTriggerManager.anyParentOfTypesWorkflowVersion(
                    $scope.data.workflowVersionId,
                    $scope.data.configuredLogic.node.id,
                    previousActorLogicParents,
                );
            }
        }

        if (
            changesObj.overrideExampleInitiative &&
            !utils.isNullOrUndefined(changesObj.overrideExampleInitiative.currentValue)
        ) {
            $scope.data.exampleInitiative = changesObj.overrideExampleInitiative.currentValue;
        }

        if (changesObj.automationIdentifier) {
            $scope.data.automationIdentifier = changesObj.automationIdentifier.currentValue;
        }
    };

    // region: Project Integration Selection

    /**
     * Selects a project integration for import/sync.
     */
    $scope.selectProjectIntegration = function (projectIntegration) {
        $scope.data.selectedProjectIntegration = projectIntegration;
    };

    /**
     * Occurs once a new non-existing integration was added.
     */
    $scope.onIntegrationSaved = function (overrideState) {
        let projectIntegration;
        if (overrideState) {
            const stateValue = utils.objToArray(overrideState)[0].value;
            projectIntegration = stateValue.projectIntegrations
                ? stateValue.projectIntegrations[0]
                : stateValue.integrations[0];
        } else {
            projectIntegration = utils.objToArray($scope.data.integrationState)[0].value.projectIntegrations[0];
        }

        $scope.selectProjectIntegration(projectIntegration);
        initializeIntegrationSources();
    };

    $scope.onCommunicationIntegrationChanged = function (selectedCommunicationProjectIntegration) {
        $scope.selectedCommunicationProjectIntegration = selectedCommunicationProjectIntegration;
        if (selectedCommunicationProjectIntegration.communicationIntegrationType === 'Email') {
            $scope.selectCommunicationMode('email');
            return;
        }
        $scope.selectCommunicationMode('integration');

        if (ctrl.onCommunicationProjectIntegrationChanged) {
            ctrl.onCommunicationProjectIntegrationChanged({
                selectedCommunicationProjectIntegration,
            });
        }
    };

    /**
     * Used for integration configuration using integration selection component.
     */
    $scope.onCurrentIntegrationChanged = function (currentIntegration) {
        $scope.data.currentIntegration = currentIntegration;
    };

    /**
     * Initializes $scope.data.nonExistingIntegrationSources with all the non-connected sources.
     */
    function initializeIntegrationSources() {
        $scope.data.projectDataImportIntegrations = projectManager.project.integrations.filter(
            (projectIntegration) =>
                !!integrations.getImportSettingsByIntegration(projectIntegration.integration.integrationType),
        );

        // Creating a set of existing integration types
        const existingIntegrations = {};

        for (let i = 0; i < $scope.data.projectDataImportIntegrations.length; i++) {
            const type =
                $scope.data.projectDataImportIntegrations[i].integration.isNativeIntegration &&
                !$scope.data.projectDataImportIntegrations[i].integration.supportsMultipleIntegrationsPerUser
                    ? $scope.data.projectDataImportIntegrations[i].integration.integrationType
                    : $scope.data.projectDataImportIntegrations[i].integration.integrationUniqueType;
            existingIntegrations[type.toLowerCase()] = true;
        }

        // Taking only the sources that do not already exist
        $scope.data.nonExistingIntegrationSources = utils
            .objKeys(integrations.getSyncSettings())
            .filter((source) => !existingIntegrations[source.toLowerCase()]);
    }

    // endregion

    /**
     * Occurs once person selection configuration option selection.
     */
    $scope.onPersonSelectionConfigurationOptionSelected = function (onClickParam) {
        $scope.data.personSelectionConfigurationOption = onClickParam;

        $scope.onPersonSelectionConfigurationChanged(true);
    };

    $scope.onPersonSelected = function (people) {
        $scope.data.selectedPeopleIds = people.map((person) => person.id);
        $scope.onPersonSelectionConfigurationChanged(true);
    };

    $scope.onPersonRemoved = function (people) {
        $scope.data.selectedPeopleIds = people.map((person) => person.id);
        $scope.onPersonSelectionConfigurationChanged(true);
    };

    /**
     * Occurs when a person sender is selected (either Tonkean or Person).
     * @param selectedSender - either 'tonkean' or 'person' when one of those options are selected.
     * @param shouldSaveLogic - should save login.
     */
    $scope.onPersonSenderConfigurationOptionSelected = function (selectedSender, shouldSaveLogic = true) {
        // Save regardless of selectedSender, so the the change will apply if the person is already selected (if specific people is already full, need to save)
        $scope.data.personSenderConfigurationOption = selectedSender;
        $scope.onPersonSelectionConfigurationChanged(shouldSaveLogic);
    };

    /**
     * The callback function when selecting a custom person.
     * Selecting custom people is enabled when ctrl.specificPeopleCustomOptions is supplied.
     * @param person - the person to select.
     */
    $scope.onCustomPersonSelected = function (person) {
        $scope.data.selectedPerson = person;
        $scope.onPersonSelectionConfigurationChanged(true);
    };

    /**
     * When the custom input has been changd
     */
    $scope.onCustomInputChanged = function () {
        $scope.onPersonSelectionConfigurationChanged(true);
    };

    /**
     * Selects an entity integration to select field from.
     */
    $scope.onEntitySelected = function (selectedEntity) {
        $scope.data.selectedIntegrationEntity = selectedEntity;

        if ($scope.data.selectedIntegrationEntity) {
            const integrationSuggestions =
                autoFillOwnerFieldsSuggestions[$scope.data.selectedProjectIntegration.integrationType];
            if (integrationSuggestions) {
                $scope.data.autoFillFieldName =
                    integrationSuggestions[$scope.data.selectedIntegrationEntity.pluralLabel];
            }
        }
    };

    /**
     * Occurs once an expression field is updated.
     */
    $scope.onFieldOptionSelected = function (
        originalExpression,
        evaluatedExpression,
        expressionIdentifier,
        shouldSaveLogic,
    ) {
        if (!$scope.data.fromFieldExpressionDefinition) {
            $scope.data.fromFieldExpressionDefinition = {};
        }

        $scope.data.fromFieldExpressionDefinition.originalExpression = originalExpression;
        $scope.data.fromFieldExpressionDefinition.evaluatedExpression = evaluatedExpression;

        $scope.onPersonSelectionConfigurationChanged(shouldSaveLogic);
    };



    /**
     * Occurs once the on behalf of expression field is updated.
     */
    $scope.onOnBehalfOfExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        expressionIdentifier,
        shouldSaveLogic,
    ) {
        if (!$scope.data.onBehalfOfExpressionDefinition) {
            $scope.data.onBehalfOfExpressionDefinition = {};
        }

        $scope.data.onBehalfOfExpressionDefinition.originalExpression = originalExpression;
        $scope.data.onBehalfOfExpressionDefinition.evaluatedExpression = evaluatedExpression;
        console.log($scope.data.onBehalfOfExpressionDefinition)
        $scope.onPersonSelectionConfigurationChanged(shouldSaveLogic);
    };



    /**
     * Occurs once an expression field is updated.
     */
    $scope.onServiceAccountSelected = function (
        originalExpression,
        evaluatedExpression,
        expressionIdentifier,
        shouldSaveLogic,
    ) {
        if (!$scope.data.fromServiceAccountExpressionDefinition) {
            $scope.data.fromServiceAccountExpressionDefinition = {};
        }

        $scope.data.fromServiceAccountExpressionDefinition.originalExpression = originalExpression;
        $scope.data.fromServiceAccountExpressionDefinition.evaluatedExpression = evaluatedExpression;

        $scope.onPersonSelectionConfigurationChanged(shouldSaveLogic);
    };

    /**
     * Occurs once the selection configuration is changed.
     * Using this function will alert all others for the change in configuration.
     */
    $scope.onPersonSelectionConfigurationChanged = function (shouldSaveLogic) {
        const selectedPeopleIds = ctrl.specificPeopleCustomOptions
            ? $scope.data.selectedPerson
                ? [$scope.data.selectedPerson.id]
                : null
            : $scope.data.selectedPeopleIds;

        const personSelectionConfiguration = {
            personSelectionType: $scope.data.personSelectionConfigurationOption,
            selectedPeopleIds,
            fromFieldExpressionDefinition: $scope.data.fromFieldExpressionDefinition,
            onBehalfOfExpressionDefinition: $scope.data.onBehalfOfExpressionDefinition,
            fromServiceAccountExpressionDefinition: $scope.data.fromServiceAccountExpressionDefinition,
            customInput: $scope.data.customInput,
            tonkeanSelected: $scope.data.personSenderConfigurationOption === $scope.personContainerOptions.tonkean,
            serviceAccountSelected: $scope.data.personSenderConfigurationOption === $scope.personContainerOptions.serviceAccount,
            failIfNoValue: $scope.data.failIfNoValue,
            forceEmail: $scope.data.selectedCommunicationMode === 'email',
            sendAsGroupMessage: $scope.data.sendAsGroupMessage,
            sendTo: $scope.data.selectedSendTo,
            selectedEmailProviderProjectIntegrationId: $scope.data.selectedEmailProviderProjectIntegrationId,
            selectedPersonIdToNameMap: createPersonIdToNameMap(selectedPeopleIds),
        };

        if (ctrl.onPersonSelectionConfigurationChanged) {
            ctrl.onPersonSelectionConfigurationChanged({
                personSelectionConfiguration,
                shouldSaveLogic,
            });
        }
    };

    $scope.onFailIfNoValueChanged = function (value) {
        $scope.data.failIfNoValue = value;
        $scope.onPersonSelectionConfigurationChanged(true);
        return $q.resolve();
    };

    $scope.onSendAsGroupMessageChanged = function (value) {
        $scope.data.sendAsGroupMessage = value;
        $scope.onPersonSelectionConfigurationChanged(true);
    };

    /**
     * Occurs once a force email mode is selected.
     */
    $scope.selectCommunicationMode = function (selectedCommunicationMode) {
        $scope.data.selectedCommunicationMode = selectedCommunicationMode;
        $scope.onPersonSelectionConfigurationChanged(true);
    };

    $scope.refreshSpecificPeopleCustomOptions = function (searchText) {
        if ($scope.data.specificPeopleCustomOptionsRefresh) {
            $scope.data.specificPeopleCustomOptionsRefresh({ q: searchText });
        }
    };

    $scope.selectSendTo = function (selectedSendTo) {
        if (selectedSendTo === 'EXTERNAL') {
            const anyButtonOnThreadReplyMonitorChildren = customTriggerManager.anyLogicChildren(
                $scope.data.configuredLogic,
                (logic) =>
                    logic &&
                    logic.node &&
                    logic.node.customTriggerType &&
                    logic.node.customTriggerType === consts.getLogicBlockTypes()['BOT_BUTTON_PRESSED'].type,
            );

            if (anyButtonOnThreadReplyMonitorChildren) {
                $scope.$emit('alert', {
                    msg: 'Cannot change to External if you have buttons.',
                    type: 'warn',
                });
                $scope.data.selectedSendTo = 'INTERNAL';
                return;
            }
        }

        $scope.data.selectedSendTo = selectedSendTo;
        $scope.onPersonSelectionConfigurationChanged(true);
    };

    /**
     * Occurs once a project integration is selected for an email provider.
     */
    $scope.onEmailProviderProjectIntegrationSelected = function (selectedProjectIntegration) {
        if (selectedProjectIntegration && !selectedProjectIntegration.isDefaultOption) {
            $scope.data.selectedEmailProviderProjectIntegrationId = selectedProjectIntegration.id;
        } else {
            $scope.data.selectedEmailProviderProjectIntegrationId = null;
        }

        $scope.onPersonSelectionConfigurationChanged(true);
    };

    /**
     * Reset component selection.
     */
    $scope.clearSelection = function () {
        $scope.data.personSelectionConfigurationOption = {
            personSelectionType: null,
            selectedPeopleIds: null,
            fromFieldExpressionDefinition: null,
            onBehalfOfExpressionDefinition: null,
            customInput: null,
            tonkeanSelected: null,
            failIfNoValue: null,
            forceEmail: null,
            sendAsGroupMessage: null,
        };

        if (ctrl.onPersonSelectionConfigurationChanged) {
            ctrl.onPersonSelectionConfigurationChanged({
                personSelectionConfiguration: $scope.data.personSelectionConfigurationOption,
                shouldSaveLogic: true,
            });
        }
    };

    $scope.openCommunicationIntegrationPage = function () {
        $state.go('product.enterpriseComponents', { tab: 'communication-sources' });
    };

    /**
     * Initializes all the variables relevant to communication integrations.
     */
    function initializeCommunicationIntegrationsVariables() {
        $scope.data.projectHasCommunicationIntegration = communicationIntegrationsService.anyIntegrationsConnected();

        const projectIntegration = communicationIntegrationsService.getFirstIntegration();
        if (projectIntegration) {
            $scope.data.possibleCommunicationIntegrationsNamesString = projectIntegration.displayName;
        }
    }

    function createPersonIdToNameMap(peopleIds) {
        if (peopleIds && peopleIds.length > 0) {
            return $scope.data.specificPeopleCustomOptions
                ?.filter((specificPersonOption) => peopleIds.includes(specificPersonOption.id))
                .reduce((map, singleOption) => {
                    map[singleOption.id] = singleOption.title;
                    return map;
                }, {});
        }

        return {};
    }

    /**
     * Initialize the edit mode of the component.
     */
    function initializeEditMode() {
        // This check is for a custom notification on an action.
        // Should happen first because the next check will override it if its a people's action
        $scope.data.selectedCommunicationProjectIntegration = projectManager.getProjectIntegrationById(
            $scope.data.savedPersonSelectionConfiguration?.communicationProjectIntegrationId,
        );

        if (
            !$scope.data.selectedCommunicationProjectIntegration &&
            $scope.data.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.communicationProjectIntegrationId
        ) {
            $scope.data.selectedCommunicationProjectIntegration = $scope.pm.getProjectIntegrationById(
                $scope.data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                    .communicationProjectIntegrationId,
            );
        }

        if ($scope.data.savedPersonSelectionConfiguration) {
            let personEmailMigrated = false;

            if ($scope.data.showTonkeanOption || $scope.data.showServiceAccountOption) {
                // If we show "Tonkean" and "Person" as container options, auto select Person (unless we discover it should be tonkean later).
                $scope.data.personSenderConfigurationOption = $scope.personContainerOptions.person;
            }

            let option = null;
            if (
                $scope.data.savedPersonSelectionConfiguration.specificPeopleIds &&
                $scope.data.savedPersonSelectionConfiguration.specificPeopleIds.length
            ) {
                // Specific people
                option = $scope.data.personSelectionConfigurationOptions.specificPeople;
                $scope.data.selectedPeopleIds = $scope.data.savedPersonSelectionConfiguration.specificPeopleIds;

                if (
                    ctrl.specificPeopleCustomOptions &&
                    $scope.data.savedPersonSelectionConfiguration.specificPeopleIds &&
                    $scope.data.savedPersonSelectionConfiguration.specificPeopleIds.length
                ) {
                    $scope.data.selectedPerson = utils.findFirstById(
                        $scope.data.specificPeopleCustomOptions,
                        $scope.data.savedPersonSelectionConfiguration.specificPeopleIds[0],
                    );
                }
            } else if (
                $scope.data.savedPersonSelectionConfiguration.personEmailFieldDefinitionId &&
                !$scope.data.savedPersonSelectionConfiguration.personEmailExpressionDefinition
            ) {
                // From integration field
                option = $scope.data.personSelectionConfigurationOptions.fromField;
                // Old configurations with fieldDefinitionId instead of an expression are migrated to the new form.
                personEmailMigrated = customTriggerManager.migratePersonEmailFieldDefinitionIdToExpressionIfNeeded(
                    $scope.data.groupId,
                    $scope.data.savedPersonSelectionConfiguration,
                );
                // Now we are sure the savedPersonSelectionConfiguration
                $scope.data.fromFieldExpressionDefinition =
                    $scope.data.savedPersonSelectionConfiguration.personEmailExpressionDefinition;
                $scope.data.selectedField = $scope.data.fromFieldExpressionDefinition;
            } else if ($scope.data.savedPersonSelectionConfiguration.personEmailExpressionDefinition) {
                // From expression (which contains fields).
                option = $scope.data.personSelectionConfigurationOptions.fromField;
                $scope.data.fromFieldExpressionDefinition =
                    $scope.data.savedPersonSelectionConfiguration.personEmailExpressionDefinition;
                $scope.data.selectedField = $scope.data.fromFieldExpressionDefinition;
            } else if (
                $scope.data.savedPersonSelectionConfiguration.customInput &&
                $scope.data.savedPersonSelectionConfiguration.customInput.length
            ) {
                // From custom input
                option = $scope.data.personSelectionConfigurationOptions.custom;
                $scope.data.customInput = $scope.data.savedPersonSelectionConfiguration.customInput;
            } else if ($scope.data.savedPersonSelectionConfiguration.initiativeOwner) {
                // Initiative owner
                option = $scope.data.personSelectionConfigurationOptions.initiativeOwner;
            } else if ($scope.data.savedPersonSelectionConfiguration.previousActor) {
                // Previous actor
                option = $scope.data.personSelectionConfigurationOptions.previousActor;
            } else if ($scope.data.savedPersonSelectionConfiguration.isTonkeanRootUser) {
                // Use tonkean
                option = $scope.data.personSelectionConfigurationOptions.isTonkeanRootUser;
                $scope.data.personSenderConfigurationOption = $scope.personContainerOptions.isTonkeanRootUser;
            } else if ($scope.data.savedPersonSelectionConfiguration.useNone) {
                // Use none
                option = $scope.data.personSelectionConfigurationOptions.useNone;
                $scope.data.personSenderConfigurationOption = $scope.personContainerOptions.tonkean;
            } else {
                $scope.data.personSenderConfigurationOption = $scope.personContainerOptions.tonkean;
            }


            $scope.data.onBehalfOfExpressionDefinition =
                $scope.data.savedPersonSelectionConfiguration.onBehalfOfExpressionDefinition;

            // Set true by default is not set yet
            $scope.data.failIfNoValue = utils.isNullOrUndefined(
                $scope.data.savedPersonSelectionConfiguration.failIfNoValue,
            )
                ? true
                : $scope.data.savedPersonSelectionConfiguration.failIfNoValue;

            if (!utils.isNullOrUndefined($scope.data.savedPersonSelectionConfiguration.forceEmail)) {
                $scope.data.selectedCommunicationMode =
                    $scope.data.savedPersonSelectionConfiguration.forceEmail ||
                    !$scope.data.possibleCommunicationIntegrationsNamesString
                        ? 'email'
                        : 'integration';
            }

            $scope.data.selectedSendTo = $scope.data.savedPersonSelectionConfiguration.sendTo;
            $scope.data.selectedEmailProviderProjectIntegrationId =
                $scope.data.savedPersonSelectionConfiguration.selectedEmailProviderProjectIntegrationId;

            $scope.data.sendAsGroupMessage = $scope.data.savedPersonSelectionConfiguration.sendAsGroupMessage;

            $scope.data.personSelectionConfigurationOption =
                option ||
                $scope.data.savedPersonSelectionConfiguration?.channelOrPersonSelectionConfiguration
                    ?.personSelectionType;
            if (
                personEmailMigrated &&
                (!projectManager.groupsMap[$scope.data.groupId].workerEnabled ||
                    (projectManager.groupsMap[$scope.data.groupId].workerEnabled &&
                        customTriggerManager.getCachedCustomTrigger(
                            $scope.data.workflowVersionId,
                            $scope.data.configuredLogic.node.id,
                        ).disabled))
            ) {
                // If a migration has happened, and the worker is not enabled or it is enabled but the logic is off, trigger an auto save.
                $scope.onPersonSelectionConfigurationChanged(true);
            } else {
                $scope.onPersonSelectionConfigurationChanged(false);
            }
        } else if ($scope.data.showServiceAccountOption && $scope.data.savedServiceAccountSelectionConfiguration) {
            $scope.data.personSenderConfigurationOption = $scope.personContainerOptions.serviceAccount;
            $scope.data.fromServiceAccountExpressionDefinition = $scope.data.savedServiceAccountSelectionConfiguration;
            $scope.data.serviceAccountSelectedField = $scope.data.fromServiceAccountExpressionDefinition;
        } else if ($scope.data.showTonkeanOption) {
            // No saved selection and we show tonkean as an option - we should select it.
            $scope.data.personSenderConfigurationOption = $scope.personContainerOptions.tonkean;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('PersonSelectionConfigurationCtrl', lateConstructController(PersonSelectionConfigurationCtrl));
