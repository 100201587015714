import React from 'react';
import styled from 'styled-components';

import FieldMapperEmptyState from './FieldMapperEmptyState';
import FieldMapperTable, {
    type Section,
} from '../../../../infrastructure/components/FieldMapperTable/FieldMapperTable';
import EnterpriseComponentIcon from '../../../EnterpriseComponentsModule/components/EnterpriseComponentIcon';

import { LoadingCircle } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

const TableWrapper = styled.div`
    width: 50%;
`;

const CircleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

interface Props {
    currentProjectIntegration: ProjectIntegration | undefined;
    filteredBySearchQuery: Section[] | undefined;
    onClickEditField: (name: string, entityId: string) => void;
    isInitialLoading: boolean;
}
const TableMetadataBody: React.FC<Props> = ({
    currentProjectIntegration,
    filteredBySearchQuery,
    onClickEditField,
    isInitialLoading,
}) => {
    if (filteredBySearchQuery && currentProjectIntegration) {
        return (
            <TableWrapper>
                <FieldMapperTable
                    titleDisplayName={currentProjectIntegration.displayName}
                    sections={filteredBySearchQuery}
                    onClickEditField={onClickEditField}
                    icon={
                        <EnterpriseComponentIcon
                            iconUrl={currentProjectIntegration.iconUrl}
                            integrationType={currentProjectIntegration.integration.integrationType}
                            dataAutomation={`enterprise-component-icon-${currentProjectIntegration.integration.integrationType}`}
                            small
                        />
                    }
                />
            </TableWrapper>
        );
    } else if (isInitialLoading) {
        return (
            <CircleWrapper>
                <LoadingCircle large />
            </CircleWrapper>
        );
    } else {
        return <FieldMapperEmptyState />;
    }
};

export default TableMetadataBody;
