<div class="margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'CREATE_EXPORT_JOB'" class="margin-bottom">
        <!-- Source Object Name -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Source Object Name:</div>

            <ui-select
                theme="bootstrap"
                ng-model="data.definition.sourceObjectName"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Select option">
                    {{ data.definition.sourceObjectName }}
                </ui-select-match>

                <ui-select-choices repeat="sourceObjectName in data.possibleObjectSource | filter:$select.search">
                    <div ng-bind-html="sourceObjectName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Source Object ID -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Source Object ID:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Source Object ID"
                saved-expression="data.definition.sourceObjectIdExpression"
                on-tonkean-expression-changed="onSourceObjectIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Format -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">File Format:</div>

            <ui-select
                theme="bootstrap"
                ng-model="data.definition.exportFileFormat"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Select option">
                    {{ data.definition.exportFileFormat }}
                </ui-select-match>

                <ui-select-choices repeat="exportFileFormat in data.possibleFileFormats | filter:$select.search">
                    <div ng-bind-html="exportFileFormat | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Created At -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Created At:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Created At"
                saved-expression="data.definition.createdAtExpression"
                on-tonkean-expression-changed="onCreatedAtTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Updated At -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Updated At:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Updated At"
                saved-expression="data.definition.updatedAtExpression"
                on-tonkean-expression-changed="onUpdatedAtTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Fields Array -->
        <div class="margin-bottom margin-top-lg">
            <div ng-if="data.definition.fields.length > 0" class="margin-right margin-top-xs margin-bottom">
                Fields ({{ data.definition.fields.length }}):
            </div>

            <div ng-repeat="(fieldIndex, field) in data.definition.fields" class="flex">
                <div class="margin-left-xs margin-right-xs margin-top">{{ fieldIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Field Name -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">Field Name:</div>

                            <div class="flex-grow"></div>

                            <button
                                class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                ng-click="removeField(fieldIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <tnk-dropdown-search
                            ng-class="{
                                'pointer-events-none': data.loadingFieldsError,
                                'common-disabled': data.loadingFieldsError
                            }"
                            items="data.possibleFields"
                            selected-item="data.definition.fields[fieldIndex].fieldObject"
                            on-item-selected="onFieldNameChanged(fieldIndex, selectedItem, true)"
                            selected-label="{{ data.definition.fields[fieldIndex].fieldObject.displayName }}"
                            closed-placeholder="Search Field"
                            label-classes="common-bold pointer form-control flex-grow"
                            hide-dropdown-chevron="false"
                            items-display-name-property="displayName"
                            placeholder="Search Field..."
                        ></tnk-dropdown-search>
                    </div>

                    <!-- New Column Header -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">New Column Header:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.fields[fieldIndex].newColumnName"
                            on-tonkean-expression-changed="
                                onNewColumnNameTonkeanExpressionChanged(expression, fieldIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>
                </div>
            </div>

            <a ng-click="addField()">+ Add Field</a>
        </div>
        <div class="margin-top-6 common-error" ng-if="data.loadingFieldsError">{{ data.loadingFieldsError }}</div>
    </div>
</div>
