import type { IRootScopeService } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import SharePointAuthenticationType, {
    sharePointAuthenticationTypeToDisplayName,
} from './SharePointAuthenticationType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

const CenteredAuthTypeTitle = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const CenteredLinkButton = styled.div`
    text-align: center;
`;

const SharePointComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [authType, setAuthenticationType] = useState<SharePointAuthenticationType>(
        SharePointAuthenticationType.AUTHORIZATION_CODE,
    );

    // $rootScope to get the feature flags
    const $rootScope = useAngularService('$rootScope') as IRootScopeService & Record<any, any>;

    const projectManager = useAngularService('projectManager');
    const predefinedTenantId = projectManager.projectData.sharepointPredefinedTenantId;

    const [tenantId, setTenantId] = useState<string | undefined>(predefinedTenantId);

    const executeAuthentication = async () => {
        try {
            switch (authType) {
                case SharePointAuthenticationType.CLIENT_CREDENTIALS:
                    authenticate(
                        { tenantId, authType },
                        {},
                        `${integrationConfiguration.displayName} (${sharePointAuthenticationTypeToDisplayName[authType]})`,
                    );
                    break;
                default:
                    const scopePermissions = `https://graph.microsoft.com/Sites.ReadWrite.All https://graph.microsoft.com/Files.ReadWrite.All offline_access`;
                    const redirectUri = environment.integrationKeysMap.microsoftUri;
                    const code = await oauthHandler.microsoft(scopePermissions, redirectUri, true);
                    authenticate(
                        { code, redirectUri },
                        {},
                        `${integrationConfiguration.displayName} (${sharePointAuthenticationTypeToDisplayName[authType]})`,
                    );
            }
        } catch {
            reportError(
                `Error occurred while authenticating using ${sharePointAuthenticationTypeToDisplayName[authType]}.`,
            );
        }
    };

    return (
        <div className="flex flex-col">
            <div className="api-token-authentication">
                <CenteredAuthTypeTitle>
                    <h5>{sharePointAuthenticationTypeToDisplayName[authType]} Authentication</h5>
                </CenteredAuthTypeTitle>

                {$rootScope.features.currentProject.tonkean_feature_sharepoint_show_app_authentication && (
                    <div className="flex mod-center common-subtitle-inner common-size-xxs margin-bottom-md">
                        <i className="fa fa-info-circle margin-right-xs margin-top-xxxs" />
                        <span>
                            For Pre-Configuration documentation{' '}
                            <a
                                href="https://docs.tonkean.com/en/data-sources/connect-native-data-sources/common-data-sources/sharepoint.html"
                                target="_blank"
                            >
                                click here
                            </a>
                        </span>
                    </div>
                )}

                {authType === SharePointAuthenticationType.CLIENT_CREDENTIALS && (
                    <div className="flex flex-col">
                        <div className="form-group flex-inline">
                            <label htmlFor="tenant_id" className="control-label col-md-3 text-right margin-top-xs">
                                Tenant ID
                            </label>

                            <div className="col-sm-8">
                                <input
                                    value={tenantId}
                                    className="form-control"
                                    onChange={({ target }) => setTenantId(target.value)}
                                    id="tenant_id"
                                    autoComplete="off"
                                    placeholder="Tenant ID"
                                    required
                                />
                                <span className="common-size-xxxs common-color-grey">
                                    Get your Tenant ID{' '}
                                    <a
                                        href="https://aad.portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/Overview"
                                        target="_blank"
                                    >
                                        here
                                    </a>
                                    .
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex flex-col">
                    <div className="flex mod-center margin-top-20">
                        <button
                            className="btn btn-secondary btn-lg"
                            disabled={
                                authType === SharePointAuthenticationType.CLIENT_CREDENTIALS &&
                                (!tenantId || tenantId === '')
                            }
                            onClick={executeAuthentication}
                        >
                            Authenticate with {integrationConfiguration.displayName}
                        </button>
                    </div>
                </div>

                {$rootScope.features.currentProject.tonkean_feature_sharepoint_show_app_authentication && (
                    <div>
                        <CenteredLinkButton className="form-group margin-top-20">
                            <a
                                onClick={() =>
                                    setAuthenticationType(
                                        authType === SharePointAuthenticationType.AUTHORIZATION_CODE
                                            ? SharePointAuthenticationType.CLIENT_CREDENTIALS
                                            : SharePointAuthenticationType.AUTHORIZATION_CODE,
                                    )
                                }
                            >
                                Use&nbsp;
                                {authType === SharePointAuthenticationType.AUTHORIZATION_CODE
                                    ? sharePointAuthenticationTypeToDisplayName[
                                          SharePointAuthenticationType.CLIENT_CREDENTIALS
                                      ]
                                    : sharePointAuthenticationTypeToDisplayName[
                                          SharePointAuthenticationType.AUTHORIZATION_CODE
                                      ]}
                                &nbsp;Authentication
                            </a>
                        </CenteredLinkButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SharePointComponent;
