<div class="tonkean-expression {{ $ctrl.className }}" ng-if="data.isInitiated">
    <div blocked-element="{{ data.disabled }}" blocked-reason="{{ data.disabledReason }}">
        <!-- Variable selector -->
        <div class="flex mod-stretch relative">
            <!-- Expression input (plain text) -->
            <input
                type="text"
                ng-model="data.expression"
                ng-change="onExpressionChanged(data.expression, null)"
                class="form-control flex-grow expression-input"
                placeholder="{{ data.placeholder }}"
                ng-keyup="data.saveOnKeyUp && onExpressionChanged(data.expression, null)"
                ng-if="data.modNormalInput"
                data-automation="{{ data.automationIdentifierExpressionValue }}"
            />

            <!-- Expression textarea (multiline plain text) -->
            <textarea
                id="{{ data.elementId }}"
                class="form-control flex-grow expression-input common-no-outline common-resize-y-only"
                ng-if="data.modTextArea"
                rows="{{ data.textAreaNumberOfRows }}"
                ng-model="data.expression"
                ng-keyup="data.saveOnKeyUp && onExpressionChanged(data.expression, null)"
                ng-change="onExpressionChanged(data.expression, null)"
                placeholder="{{ data.placeholder }}"
                data-automation="{{ data.automationIdentifierExpressionValue }}"
            ></textarea>

            <!-- Expression input (tags expression input) -->
            <tnk-tags-expression
                ng-class="{ 'fixed-width': data.modFixedWidth }"
                class="flex-grow expression-input"
                class-name="'tnk-tags-expression'"
                ng-if="!data.modNormalInput && !data.modTextArea"
                workflow-version-id="data.workflowVersionId"
                group-id="data.groupId"
                custom-trigger-id="data.logicId"
                translate-variable-label="getFieldLabel"
                get-variable-icon-class-name="getFieldIconClassName"
                get-project-integration-type-and-id="getProjectIntegrationTypeAndId"
                expression="data.savedEvaluatedExpression"
                placeholder="data.placeholder"
                on-expression-updated="(onExpressionChanged)"
                debounce-on-change="!data.saveOnKeyUp"
                dont-strip-html="data.savedIsStripHtmlDisabled"
                prefix="data.prefix"
                set-control-object="setExpressionControlObject"
                automation-identifier-expression-value="data.automationIdentifierExpressionValue"
                additional-tabs="data.additionalTabs"
            ></tnk-tags-expression>

            <div class="relative" ng-if="!data.hideEditorButton && (!data.disabled || data.editorType)">
                <div
                    class="
                        tonkean-expression-expand
                        common-border
                        flex-vmiddle
                        mod-justify-center
                        pointer
                        common-color-grey
                    "
                    uib-tooltip="Open an editor for rich text formatting"
                    tooltip-append-to-body="true"
                    data-automation="tonkean-expression-editor-button"
                    ng-click="expandEditorView()"
                >
                    <i class="fa fa-expand margin-right-xs"></i>
                    Editor
                </div>
            </div>

            <!-- Variable addition to text -->

            <span class="flex-no-shrink variable-selector">
                <tnk-tabs-field-selector
                    class="tonkean-expression-fields-btn"
                    data-automation="tnk-tonkean-expression-template-click-expression-fields"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    workflow-folder-id="{{ data.workflowFolderId }}"
                    entity-version-type="{{ data.entityVersionType }}"
                    logic-id="{{ data.logicId }}"
                    tooltip="Insert field"
                    additional-tabs="data.additionalTabs"
                    param-for-tabs="data.paramForTabs"
                    global-tabs-only="data.globalExpressionOnly"
                    on-field-selected="onVariableSelected(selectedField)"
                    tabs-field-selector-mod-narrow="data.tabsFieldSelectorModNarrow"
                    tabs-field-selector-mod-fixed-width="data.tabsFieldSelectorModFixedWidth"
                    show-formulas-tab="data.showFormulasTab"
                    on-popover-open="onFieldPopoverOpen()"
                    automation-identifier-expression-add-field="{{ data.automationIdentifierExpressionAddField }}"
                    show-people-directories="data.showPeopleDirectories"
                    people-directories="data.showPeopleDirectories ? data.peopleDirectories : undefined"
                    default-tab-id="data.defaultTabId"
                    excluded-tab-selector-special-fields="data.excludedTabSelectorSpecialFields"
                    include-tab-selector-special-fields-for-features="data.includeTabSelectorSpecialFieldsForFeatures"
                    fields-to-filter="data.fieldsToFilter"
                ></tnk-tabs-field-selector>
            </span>
        </div>
    </div>
    <div class="flex-vmiddle">
        <tnk-example-item-evaluated-expression
            class="flex-grow margin-right-xs"
            expression-to-evaluate="data.evaluatedExpression"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.logicId }}"
            expression-unique-identifier="{{ data.expressionUniqueIdentifier }}"
            do-not-evaluate-preview="data.doNotEvaluatePreview"
            override-example-initiative-id="data.exampleInitiative"
            custom-preview="data.customPreview"
            hide-preview="data.hidePreview"
            show-people-directories="data.showPeopleDirectories"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-example-item-evaluated-expression>
        <span class="flex-no-shrink" ng-if="data.showToggleStripHtml" uib-dropdown>
            <!-- Dropdown toggle -->
            <div
                class="svg-icon-smd common-color-grey svg-icon-hover-primary pointer margin-top-xxs"
                uib-dropdown-toggle
            >
                <i class="svg-icon-track flex-vmiddle svg-icon-vmiddle">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/ellipsis-v.svg"></tnk-icon>
                </i>
            </div>
            <!-- Dropdown menu -->
            <ul uib-dropdown-menu class="dropdown-menu-right margin-right-xxs" role="menu">
                <!-- Strip HTML -->
                <li class="flex-vmiddle padding-right-xs padding-left-xs pointer" ng-click="toggleStripHtml()">
                    <span class="flex-no-shrink margin-right svg-icon-sm common-color-grey">
                        <tnk-icon src="../../../../../apps/tracks/images/icons/report.svg"></tnk-icon>
                    </span>
                    <span class="flex-grow">Strip HTML</span>
                    <!--<div class="flex-grow flex mod-align-start">-->
                    <!--</div>-->
                    <span class="flex-no-shrink">
                        <tnk-toggle
                            size="xxs"
                            is-active="!data.savedIsStripHtmlDisabled"
                            uib-tooltip="{{ data.savedIsStripHtmlDisabled ? 'OFF' : 'ON' }}"
                            on-toggle-click="toggleStripHtml()"
                        ></tnk-toggle>
                    </span>
                </li>
            </ul>
        </span>
    </div>
</div>
<div ng-if="!data.isInitiated" class="loading"></div>
