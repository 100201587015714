import { FieldArray, useField } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import ContentTypeHeader from './ContentTypeHeader';
import SingleHeaderRow from './SingleHeaderRow';
import useHeadersExpressionAdditionalTab from '../utils/useHeadersExpressionAdditionalTab';

import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { LinkLikeButton, Placeholder } from '@tonkean/infrastructure';
import type { ActionType, HttpRequestHeader } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import utils, { range } from '@tonkean/utils';

const AddHeaderButton = styled(LinkLikeButton)`
    color: ${Theme.colors.primaryHighlight};
`;

const TableHeader = styled.div`
    color: ${Theme.colors.gray_500};
    margin-bottom: 8px;
`;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 50px;
`;

interface Props {
    name: string;
    shouldShowContentType: boolean;
    expressionProps?: FormikExpressionProps;
    isLoading: boolean;
    actionType: ActionType | undefined;
    shouldIncludeFileDownloading: boolean;
}

const HeaderComponent: React.FC<Props> = ({
    name,
    shouldShowContentType,
    expressionProps,
    isLoading,
    actionType,
    shouldIncludeFileDownloading,
}) => {
    const [headersList, , helpers] = useField<HttpRequestHeader[]>(`${name}.headers`);

    const headersAdditionsTab = useHeadersExpressionAdditionalTab();

    const updatedRequestExpressionProps = useMemo(() => {
        return {
            ...expressionProps,
            additionalTabs: expressionProps?.additionalTabs
                ? [headersAdditionsTab, ...expressionProps.additionalTabs]
                : [headersAdditionsTab],
        };
    }, [headersAdditionsTab, expressionProps]);

    return (
        <>
            <Wrapper>
                {(shouldShowContentType || headersList.value.length > 0 || isLoading) && (
                    <>
                        <TableHeader>Header Name</TableHeader>
                        <TableHeader>Header Value</TableHeader>
                        <TableHeader />
                    </>
                )}

                {shouldShowContentType && (
                    <ContentTypeHeader
                        shouldIncludeFileDownloading={shouldIncludeFileDownloading}
                        actionType={actionType}
                        name={`${name}.contentType`}
                    />
                )}

                {isLoading && (
                    <>
                        {range(6).map((i) => (
                            <Placeholder
                                style={{ marginBottom: '15px', marginRight: '5px' }}
                                key={i}
                                $height="36px"
                                $width="auto"
                            />
                        ))}
                    </>
                )}

                {!isLoading && (
                    <FieldArray name={`${name}.headers`}>
                        {({ remove: removeRow }) => {
                            return headersList.value.map((header, index) => (
                                <SingleHeaderRow
                                    expressionProps={updatedRequestExpressionProps}
                                    removeRow={() => {
                                        removeRow(index);
                                    }}
                                    key={header.id}
                                    name={`${name}.headers.${index}`}
                                />
                            ));
                        }}
                    </FieldArray>
                )}
            </Wrapper>

            <AddHeaderButton
                disabled={isLoading}
                onClick={() => {
                    helpers.setValue([
                        ...headersList.value,
                        {
                            name: {
                                originalExpression: '',
                                evaluatedExpression: '',
                                isStripHtmlDisabled: true,
                            },
                            value: {
                                originalExpression: '',
                                evaluatedExpression: '',
                                isStripHtmlDisabled: true,
                            },
                            id: utils.guid(),
                        },
                    ]);
                }}
            >
                + Add Header
            </AddHeaderButton>
        </>
    );
};

export default HeaderComponent;
