export enum IroncladAuthType {
    API_KEY = 'API_KEY',
    OAUTH = 'OAUTH',
}

export const authTypeToDisplayName: Record<IroncladAuthType, string> = {
    [IroncladAuthType.API_KEY]: 'API Key',
    [IroncladAuthType.OAUTH]: 'User Delegated OAuth',
};
export default IroncladAuthType;
