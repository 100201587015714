import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SlackIcon } from '../../../../../../images/slack-icon.svg';

import { Spacer, Toggle } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Primary = styled.div`
    color: ${Theme.colors.gray_800};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.MEDIUM_14};

    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Secondary = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.MEDIUM_14};

    display: flex;
    align-items: center;
    gap: 4px;

    svg {
        height: 14px;
        width: 14px;
    }
`;

interface Props {
    setFormType(newFormType: 'WEBFORM' | 'DIALOG'): void;
    currentFormType: 'WEBFORM' | 'DIALOG';
}

const NativeFormCapabilities: React.FC<Props> = ({ setFormType, currentFormType }) => {
    return (
        <>
            <Spacer height={10} />
            <Secondary>By default, update forms are sent as web forms</Secondary>
            <Spacer height={18} />
            <Primary>
                Use communication source native forms capabilities
                <Toggle
                    checked={currentFormType === 'DIALOG'}
                    onChange={(e) => setFormType(e.target.checked ? 'DIALOG' : 'WEBFORM')}
                    size={InputSize.MEDIUM}
                />
            </Primary>
            <Spacer height={5} />
            <Secondary>
                <span>Supported on:</span>
                <SlackIcon />
            </Secondary>
        </>
    );
};

export default NativeFormCapabilities;
