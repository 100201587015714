import React from 'react';
import styled from 'styled-components';

import { ModalHeaderActionButtons } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SolutionName = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_600};
    margin-bottom: 10px;
`;
const TitleLine = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;
const Title = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    margin-left: 14px;
`;

interface Props {
    workflowFolder: WorkflowFolder;
    icon: React.ReactNode;
    useDefaultIconColor?: boolean;
}

const SolutionManagingModalHeader: React.FC<React.PropsWithChildren<Props>> = ({
    workflowFolder,
    icon,
    useDefaultIconColor,
    children,
}) => {
    return (
        <>
            <SolutionName>{workflowFolder.displayName}</SolutionName>
            <TitleLine>
                <i className={`svg-icon svg-icon-lg${useDefaultIconColor ? '' : 'common-color-primary'}`}>
                    <span className="tnk-icon">{icon}</span>
                </i>
                <Title>{children}</Title>
            </TitleLine>

            <ModalHeaderActionButtons />
        </>
    );
};
export default SolutionManagingModalHeader;
