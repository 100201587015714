import * as React from 'react';
import { useMemo } from 'react';

import type { OcrParagraph } from '@tonkean/tonkean-entities';
import { getIndexedCollection } from '@tonkean/utils';

interface Props {
    ocrParagraph: OcrParagraph;
}

const DocumentPreviewParagraph: React.FC<Props> = ({ ocrParagraph }) => {
    const indexedParagraphLines = useMemo(() => {
        return getIndexedCollection(ocrParagraph.paragraphLines || []);
    }, [ocrParagraph]);

    return (
        <>
            {indexedParagraphLines.map((line) => (
                <React.Fragment key={line.index}>
                    {line.original} <br />
                </React.Fragment>
            ))}
        </>
    );
};

export default DocumentPreviewParagraph;
