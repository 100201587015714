import React from 'react';
import styled from 'styled-components';

import type CommentsWidgetConfiguration from './CommentsWidgetConfiguration';
import type { ItemWidgetEditorProps } from '../../../../WidgetModule';
import { WidgetConfigurationToggle } from '../../../CommonWidgetConfiguration';

const Wrapper = styled.div`
    padding: 0 16px;
`;

const CommentsWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<CommentsWidgetConfiguration>> = () => {
    return (
        <Wrapper>
            <WidgetConfigurationToggle
                title="Include comments from all matched items"
                name="configuration.includeIntakeActivities"
            />
            <WidgetConfigurationToggle
                title="Comments will be public to all intake modules"
                name="configuration.saveActivitesAsPublic"
            />
            <WidgetConfigurationToggle
                title="Disable the option to reply to a comment"
                name="configuration.disableReplyToComment"
            />
            <WidgetConfigurationToggle title="Sort comments by newest last" name="configuration.orderByAscending" />
        </Wrapper>
    );
};

export default CommentsWidgetConfigurationEditor;
