import { useField } from 'formik';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-form.svg';

import { Field, Input, SimpleSelect } from '@tonkean/infrastructure';
import { ActionType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledIconButton = styled(IconButton)`
    margin: 3px auto auto auto;
`;

const InputWrapper = styled(Field)`
    margin-right: 12px;
    margin-bottom: 15px;
`;

interface Props {
    name: string;
    actionType: ActionType | undefined;
    shouldIncludeFileDownloading: boolean;
}

const ContentTypeHeader: React.FC<Props> = ({ name, actionType, shouldIncludeFileDownloading }) => {
    const contentTypeOptions = useMemo(() => {
        return [
            'application/EDI-X12',
            'application/EDIFACT',
            'application/octet-stream',
            'application/ogg',
            'application/pdf  ',
            'application/xhtml+xml',
            'application/x-shockwave-flash',
            'application/json',
            'application/ld+json',
            'application/vnd.api+json',
            'application/xml',
            'application/zip',
            'application/x-www-form-urlencoded',
            'text/csv',
            'text/html',
            'text/plain',
            'text/xml',
            'multipart/form-data',
            (actionType && actionType === ActionType.UPLOAD) || shouldIncludeFileDownloading
                ? 'from mime type'
                : undefined,
        ]
            .filter((type) => type !== undefined)
            .map((type) => ({ label: type, value: type }));
    }, [actionType, shouldIncludeFileDownloading]);

    const [contentType, , contentTypeHelper] = useField<string>(name);

    const defaultValue = 'application/json';

    // on render ContentTypeHeader if the existing value is empty - set default one
    useEffect(() => {
        if (contentType.value === '') {
            contentTypeHelper.setValue(defaultValue);
        }
    }, [contentType.value, contentTypeHelper]);

    return (
        <>
            <InputWrapper>
                <Input size={InputSize.LARGE} value="Content-Type" disabled />
            </InputWrapper>

            <SimpleSelect options={contentTypeOptions} name={name} isCreatable />

            <StyledIconButton disabled flat>
                <TrashIcon />
            </StyledIconButton>
        </>
    );
};

export default ContentTypeHeader;
