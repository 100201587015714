import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import type { SingleFieldConfigurationWithDefinition } from './FieldSelector';
import FieldSelector from './FieldSelector';
import type { FieldsSelectorGroupSummaryBase } from './FieldsSelectorGroupSummaryBase';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';

import { getFieldInstance } from '@tonkean/fields';
import { TextEllipsis, useFormikField } from '@tonkean/infrastructure';
import { FieldType } from '@tonkean/tonkean-entities';
import type { FieldDefinition, Initiative, WorkflowVersion, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { getInitiativeFieldValue } from '@tonkean/tonkean-utils';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const SubTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: ${Theme.colors.gray_700};
    margin-bottom: 8px;
`;

const FieldsSectionTitle = styled(ConfigurationSectionTitle)`
    margin-bottom: 17px;
`;

const ExampleValueRow = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    min-height: 12px;
    left: 0;
    top: calc(50% - 10px / 2);
    order: 0;
    flex-grow: 0;
    margin: 8px 2px 0 2px;
`;

interface Props {
    allFieldDefinitions: FieldDefinition[];
    workflowVersion: WorkflowVersion | undefined;
    workflowVersionType?: WorkflowVersionType;
    groups: FieldsSelectorGroupSummaryBase[] | undefined;
    exampleItem?: Initiative | undefined;
    fieldConfigurationPath?: string;
    onFieldChanged?: (value: SingleFieldConfigurationWithDefinition) => void;
    configurationTitle?: string;
    configurationSubTitle?: string;
    maxAmountOfFields?: number;
}

const WidgetConfigurationSingleFieldSelector: React.FC<Props> = ({
    allFieldDefinitions,
    workflowVersion,
    workflowVersionType,
    groups,
    exampleItem,
    configurationTitle,
    configurationSubTitle,
    fieldConfigurationPath = 'configuration.selectedField',
    onFieldChanged,
}) => {
    const { value: selectedField, setValue: setSelectedField } =
        useFormikField<SingleFieldConfigurationWithDefinition>(fieldConfigurationPath);

    const projectManager = useAngularService('projectManager');
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const updateConfigValue = useCallback(
        (selectedField: SingleFieldConfigurationWithDefinition) => {
            setSelectedField(selectedField);
            if (onFieldChanged) {
                onFieldChanged(selectedField);
            }
        },
        [onFieldChanged, setSelectedField],
    );

    const configuredField = useMemo(() => {
        return allFieldDefinitions?.find((fieldDefinition) => selectedField?.key === fieldDefinition?.id);
    }, [allFieldDefinitions, selectedField?.key]);

    const addFieldToConfiguration = useCallback(
        (field: FieldDefinition) => {
            if (field?.id) {
                const configValue: SingleFieldConfigurationWithDefinition = {
                    package: undefined,
                    key: field?.id,
                    isShown: true,
                    fullWidth: false,
                    hideColorsAndTrends: true,
                    index: '0',
                };
                updateConfigValue(configValue);
            }
        },
        [updateConfigValue],
    );

    const exampleValue = useMemo(() => {
        if (!configuredField) {
            return undefined;
        }

        if (!exampleItem || !workflowVersion) {
            return getFieldInstance(
                configuredField,
                exampleItem,
                workflowVersion,
                workflowVersion?.groupId,
                true,
                groupsMap,
                workflowVersionType,
            )?.formattedValue;
        }
        const initiativeValue = getInitiativeFieldValue(exampleItem, configuredField, workflowVersion, true)?.value;

        if (configuredField && configuredField.fieldType === FieldType.Date) {
            return initiativeValue && new Date(initiativeValue).toLocaleDateString();
        }

        return initiativeValue;
    }, [exampleItem, configuredField, groupsMap, workflowVersion, workflowVersionType]);

    return (
        <>
            {configurationTitle && (
                <TitleWrapper>
                    <FieldsSectionTitle>{configurationTitle}</FieldsSectionTitle>
                </TitleWrapper>
            )}
            {configurationSubTitle && <SubTitleWrapper>{configurationSubTitle}</SubTitleWrapper>}
            <FieldSelector
                addFieldToConfiguration={addFieldToConfiguration}
                groups={groups}
                groupByPredicate={(value) => value.fieldDefinition.fieldType}
                fieldDefinitions={allFieldDefinitions}
                selectedField={selectedField}
                size={InputSize.MEDIUM}
            />
            {exampleValue && (
                <ExampleValueRow>
                    <TextEllipsis numberOfLines={1} tooltip>
                        {exampleValue}
                    </TextEllipsis>
                </ExampleValueRow>
            )}
        </>
    );
};
export default React.memo(WidgetConfigurationSingleFieldSelector);
