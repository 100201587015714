import React from 'react';
import styled, { css } from 'styled-components';

import type { PeopleDirectoryRecipientsPreviewProps } from './PeopleDirectoryRecipientsPreviewProps';
import PeopleDirectoryRecipientsPreviewTable from './PeopleDirectoryRecipientsPreviewTable';
import PeopleDirectoryRecipientsSummaryMessage from './PeopleDirectoryRecipientsSummaryMessage';
import SinglePeopleDirectorySummary from './SinglePeopleDirectorySummary';
import PeopleDirectoryViewMode from '../../../entities/PeopleDirectoryViewMode';
import { usePeopleDirectoriesView } from '../../../utils/PeopleDirectoryViewContext';

import { Checkbox, SearchBox, Spacer } from '@tonkean/infrastructure';
import { PeopleDirectorySendStatusLabel, SendToGroupOfPeopleAs } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const FiltersBar = styled.div<{ mode: PeopleDirectoryViewMode }>`
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
    display: grid;
    align-items: center;
    row-gap: 20px;
    column-gap: 16px;

    ${({ mode }) => {
        switch (mode) {
            case PeopleDirectoryViewMode.PREVIEW: {
                return css`
                    grid-template-columns: 1fr auto;
                    grid-template-areas: 'summaries search';
                `;
            }
            case PeopleDirectoryViewMode.HISTORY: {
                return css`
                    grid-template-columns: 1fr auto;
                    grid-template-rows: auto auto;
                    grid-template-areas:
                        'summaries summaries'
                        'failed search';
                `;
            }
            default: {
                return ``;
            }
        }
    }}
`;
const Filters = styled.span`
    display: flex;
    align-items: center;
    gap: 12px;
`;
const PeopleDirectorySummaries = styled.span`
    grid-area: summaries;
    display: inline-flex;
    gap: 16px;
`;

const PeopleDirectoryRecipientsPreview: React.FC<PeopleDirectoryRecipientsPreviewProps> = (props) => {
    const {
        peopleDirectories,
        mode,
        sendToGroupOfPeopleAs,
        showSendViaPreference,
        toggleShowSendViaPreference,
        showSendViewEmailFallback,
        toggleShowSendViewEmailFallback,
        showFailed,
        toggleShowFailed,
    } = usePeopleDirectoriesView();

    return (
        <>
            <PeopleDirectoryRecipientsSummaryMessage {...props} />
            <Spacer height={26} />
            <FiltersBar mode={mode}>
                <PeopleDirectorySummaries>
                    {Object.keys(peopleDirectories).map((peopleDirectoryId) => (
                        <SinglePeopleDirectorySummary
                            key={peopleDirectoryId}
                            peopleDirectoryId={peopleDirectoryId}
                            showFailed={mode === PeopleDirectoryViewMode.HISTORY}
                        />
                    ))}
                    {props.loading && (
                        <>
                            <SinglePeopleDirectorySummary loading />
                            <SinglePeopleDirectorySummary loading />
                            <SinglePeopleDirectorySummary loading />
                            <SinglePeopleDirectorySummary loading />
                        </>
                    )}
                </PeopleDirectorySummaries>
                {mode === PeopleDirectoryViewMode.HISTORY && (
                    <Filters>
                        {sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL
                            ? 'Show recipients with status:'
                            : 'Show business groups where:'}
                        <Checkbox checked={showSendViaPreference} onChange={toggleShowSendViaPreference}>
                            {PeopleDirectorySendStatusLabel.SUCCESS}
                        </Checkbox>
                        {sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL && (
                            <Checkbox checked={showSendViewEmailFallback} onChange={toggleShowSendViewEmailFallback}>
                                {PeopleDirectorySendStatusLabel.PARTIAL}
                            </Checkbox>
                        )}
                        <Checkbox checked={showFailed} onChange={toggleShowFailed}>
                            {PeopleDirectorySendStatusLabel.FAIL}
                        </Checkbox>
                    </Filters>
                )}
                <SearchBox
                    value={props.searchTerm}
                    onChange={(e) => props.setSearchTerm(e.target.value)}
                    $width="250px"
                />
            </FiltersBar>
            <Spacer height={14} />
            <PeopleDirectoryRecipientsPreviewTable loading={props.loading} />
        </>
    );
};

export default PeopleDirectoryRecipientsPreview;
