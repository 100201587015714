import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import IntegrationSetupFileTreePicker from './IntegrationSetupFileTreePicker';
import type OneDriveProjectDatas from './OneDrive365ProjectDatas';
import { OneDriveDriveType } from './OneDrive365ProjectDatas';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { Field } from '@tonkean/infrastructure';
import { Input } from '@tonkean/infrastructure';

const StyledField = styled(Field)`
    margin: 10px 0 20px 0;
`;

const OneDrive365SetupComponent: React.FC<CustomizedSetupComponentProps<OneDriveProjectDatas>> = ({
    integration,
    projectIntegrationData,
    onChangeOrInitIntegration,
    createProjectApis,
}) => {
    const projectManager = useAngularService('projectManager');
    // Path to the selected folder.
    const [pathToFolder, setPathToFolder] = useState<{ id: string; name: string }[]>(
        projectIntegrationData?.pathToFolder || [],
    );

    // When choose to collect from shared drive we use this drive id.
    const [externalDriveId, setExternalDriveId] = useState<string | undefined>(projectIntegrationData?.externalDriveId);

    // The selected driver type Shared or My Drive.
    const [driveType, setDriveType] = useState<OneDriveDriveType | undefined>(projectIntegrationData?.driveType);

    const [url, setUrl] = useState<string>(projectIntegrationData?.url || '');

    const isUrlValid = url !== '' && url.startsWith('https://') && url.endsWith('.com');

    // Gets the current folder name.
    const parentFolderLastName = useMemo(() => {
        if (pathToFolder[pathToFolder.length - 1]?.name) {
            return pathToFolder[pathToFolder.length - 1]?.name as string;
        }

        return driveType === OneDriveDriveType.SHARED ? 'Shared With Me' : 'My Drive';
    }, [driveType, pathToFolder]);

    // The auto complete request.
    const [{ data: autoCompleteOptions, loading, error }, getAutoCompleteOptions] = useLazyAsyncMethod(
        createProjectApis,

        'getAutoCompleteOptions',
    );

    // Save the project integration.
    const onSaveProjectIntegration = () => {
        const projectIntegrationData = {
            url,
            pathToFolder,
            externalDriveId,
            driveType,
        };

        const disabled = !canChooseFolder || error;

        onChangeOrInitIntegration(
            { projectIntegrationData, projectIntegrationDisplayName: `One Drive ${parentFolderLastName}` },
            disabled,
        );
    };

    // Updates the view for the current parent folder.
    useEffect(() => {
        const folderId = pathToFolder[pathToFolder.length - 1]?.id;

        getAutoCompleteOptions(projectManager.project.id, integration.id, 'onedrive', {
            folderId,
            driveType,
            externalDriveId,
        });
    }, [
        externalDriveId,
        driveType,
        getAutoCompleteOptions,
        integration,
        integration.id,
        pathToFolder,
        projectManager.project.id,
    ]);

    // Enter The selected Folder.
    const onClickFolder = (folderId: string, name: string) => {
        if (driveType === OneDriveDriveType.SHARED) {
            setExternalDriveId(folderId.split('!')?.[0]);
        }

        setPathToFolder((prev) => [...prev, { id: folderId, name }]);
    };

    // On Click THe Back Button
    const onClickBack = () => {
        setPathToFolder((prev) => {
            return prev.slice(0, -1);
        });
    };

    // On Click Reset Button
    const onClickReset = () => {
        setPathToFolder([]);
        setDriveType(undefined);
        setExternalDriveId(undefined);
    };

    // Indicates whether the user can save the project integration or not
    const canChooseFolder =
        (driveType === OneDriveDriveType.ROOT || (driveType === OneDriveDriveType.SHARED && pathToFolder.length > 0)) &&
        !error &&
        isUrlValid;

    return (
        <>
            <StyledField
                error={!isUrlValid ? 'Url must start with "https://" and ends with ".com"' : undefined}
                label="The Url of the company OneDrive 365"
            >
                <Input
                    placeholder="https://{companyName}.sharepoint.com"
                    value={url}
                    onChange={({ target: { value } }) => {
                        setUrl(value);
                    }}
                />
            </StyledField>

            {driveType === undefined && (
                <>
                    <button
                        type="button"
                        className="btn btn-default btn-lg block common-width-100 margin-bottom"
                        onClick={() => {
                            setDriveType(OneDriveDriveType.ROOT);
                            getAutoCompleteOptions(projectManager.project.id, integration.id, 'onedrive', {
                                driveType: OneDriveDriveType.ROOT,
                            });
                        }}
                    >
                        My Drive
                    </button>

                    <button
                        type="button"
                        className="btn btn-default btn-lg block common-width-100 margin-bottom"
                        onClick={() => {
                            setDriveType(OneDriveDriveType.SHARED);
                            getAutoCompleteOptions(projectManager.project.id, integration.id, 'onedrive', {
                                driveType: OneDriveDriveType.SHARED,
                            });
                        }}
                    >
                        Shared With Me
                    </button>
                </>
            )}

            {driveType && (
                <>
                    <IntegrationSetupFileTreePicker
                        canChooseFolder={canChooseFolder}
                        hasError={error}
                        isLoading={loading}
                        pathToFolder={pathToFolder}
                        options={autoCompleteOptions?.options || []}
                        onClickBack={onClickBack}
                        onClickFolder={onClickFolder}
                        onSaveProjectIntegration={onSaveProjectIntegration}
                        onClickReset={onClickReset}
                        parentFolderLastName={parentFolderLastName}
                        defaultErrorMessage="Error While getting the folder"
                    />
                </>
            )}
        </>
    );
};

export default OneDrive365SetupComponent;
