import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function AddCommentActionDefinitionCtrl($scope, tonkeanUtils, $rootScope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        // "from" configuration.
        fromPersonSelectionConfiguration: {},
        fromExistingPersonSelectionConfiguration: null,
    };

    ctrl.$onInit = function () {
        initializeEditMode();
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.comment = originalExpression;
        $scope.data.definition.evaluatedCommentExpression = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    /**
     * Occurs once a "from" person selection is changed.
     */
    $scope.onFromPersonSelectionChanged = function (fromPersonSelectionConfiguration, shouldSaveLogic) {
        $scope.data.fromPersonSelectionConfiguration = fromPersonSelectionConfiguration;
        $scope.data.definition.fromPersonSelectionConfiguration = tonkeanUtils.buildPersonSelectionConfiguration(
            $scope.data.fromPersonSelectionConfiguration,
        );

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    function initializeEditMode() {
        if ($scope.data.definition) {
            // "From" person configuration.
            $scope.data.fromExistingPersonSelectionConfiguration =
                $scope.data.definition.fromPersonSelectionConfiguration;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('AddCommentActionDefinitionCtrl', lateConstructController(AddCommentActionDefinitionCtrl));
