import template from './tnkFillForm.template.html?angularjs';

/**
 * A component that allows you to fill defined form.
 */
angular.module('tonkean.app').component('tnkFillForm', {
    bindings: {
        formId: '@', // The form id that will be displayed.
        workflowVersionId: '@',
        formVersionType: '<',
        groupId: '@',
        workerRunId: '@',
        customTriggerId: '@',
        onCustomTriggerIdChanged: '&',
        initiativeId: '@',
        parentInitiativeId: '<',
        sessionId: '@',
        workerRunLogicId: '@',
        displayCloseButtonWhenFormAnswered: '<',
        viewMode: '<',
        onFormClosed: '&',
        onInitiativeCreated: '&',
        showBorder: '<',
        expandHovered: '<',
        solutionBusinessReportId: '<',
        form: '<',
        addUnderInitiative: '<',
        collectionForm: '<',
        group: '<',
        onSubmitResolved: '&',
        itemInterfaceId: '<',
        itemInterface: '<',
        backgroundColor: '<',
        formModalRef: '<',
        createUsingCustomTriggerId: '<',
    },
    template,
    controller: 'FillFormCtrl',
});
