import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addEndSequenceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addEndSequenceBlockCreateAfterOption';
import addNewLogicOptionNotAfterOpenForm from '../sharedConfigComponents/form/createAfterOptions/addNewLogicOptionNotAfterOpenForm';
import addShowFormBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addShowFormBlockCreateAfterOption';
import addShowInterfaceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addShowInterfaceBlockCreateAfterOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const AskFieldUpdateAnsweredLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.ASK_FIELD_UPDATE_ANSWERED,
    title: 'When the Ask for Information was answered by the recipient.',
    iconClass: 'mod-ask-information-answered',
    description: 'This block will trigger when the recipient has provided the requested information.',
    cantChangeType: () => true,
    doNotDisplaySettingsEdit: true,
    doesntHaveDefinition: true,
    cantMove: false,
    cantDrag: false,
    specialChild: true,
    allowedParentTypes: [LogicBlockConfigType.ASK_FIELD_UPDATE],
    createAfterOptions: () => [
        addShowFormBlockCreateAfterOption,
        addShowInterfaceBlockCreateAfterOption,
        addEndSequenceBlockCreateAfterOption,
        addNewLogicOptionNotAfterOpenForm,
    ],
};

export default AskFieldUpdateAnsweredLogicBlockConfig;
