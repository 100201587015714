import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import FieldDefinitionMatchItemSettingsModalInner from './FieldDefinitionMatchItemSettingsModalInner';

import { useAsyncMethod, useLazyTonkeanService, useToastMessage, useTonkeanService } from '@tonkean/angular-hooks';
import { FormikHelpers } from '@tonkean/infrastructure';
import type {
    FieldDefinition,
    Group,
    MatchItemFieldDefinition,
    PreviewFieldDefinition,
    ProjectIntegration,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import { ContractFieldType, FieldDefinitionTargetType, FieldQueryType, FieldType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const updateFieldPermissions = ['LIST_OWNER', 'COLLABORATORS', 'TRACK_OWNERS', 'TRACK_CREATOR'];

interface Props {
    fieldDefinition: FieldDefinition<MatchItemFieldDefinition> | undefined;
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersion: WorkflowVersion;
    reloadFields: (fieldDefinition: FieldDefinition | undefined, forceReload: boolean) => void;
    onModalEscClose: (force: boolean) => void;
    onCancel: () => void;
    onDelete: () => void;
    onDuplicate: () => void;
}

const FieldDefinitionMatchItemSettingsModal: React.FC<Props> = ({
    fieldDefinition,
    groupId,
    workflowVersion,
    reloadFields,
    onModalEscClose,
    onCancel,
    onDelete,
    onDuplicate,
}) => {
    const projectManager = useAngularService('projectManager');
    const groupInfoManager = useAngularService('groupInfoManager');
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const customFieldsManager = useAngularService('customFieldsManager');
    const emitToastMessage = useToastMessage();

    const { data: moduleData } = useAsyncMethod(groupInfoManager, 'getGroups', false);

    const workflowFolder = useMemo(() => {
        return workflowFolderManager.getContainingWorkflowFolder(projectManager.project.id, groupId);
    }, [groupId, projectManager.project.id, workflowFolderManager]);

    const fieldDefinitionToEdit = useMemo<FieldDefinition<MatchItemFieldDefinition>>(() => {
        const fieldDefinitionPreview: PreviewFieldDefinition = {
            definitionId: 'previewedFieldDefinition',
            name: 'Match Item',
            fieldDefinitionType: 'EXTERNAL',
            projectIntegrationId: undefined,
            fieldType: FieldType.LongString,
            updateable: false,
            definition: {
                FieldName: 'TNK_REAL_ENTITY_ID',
                fieldDefinitionTargetType: FieldDefinitionTargetType.COLUMN,
                FieldLabel: 'External Entity Id',
                ExternalType: undefined,
                matchConfiguration: {
                    matchOption: 'MATCH_FIELD_CUSTOM_QUERY',
                    customQuery: {
                        query: {
                            type: FieldQueryType.And,
                            filters: [],
                            queries: [],
                        },
                    },
                    contractId: undefined,
                    orderByField: undefined,
                    orderByOrder: 'ASC',
                    orderByFieldType: 'String',
                    isForMatchingItem: true,
                },
            },
            ranges: [],
            isImportant: false,
            displayAs: FieldType.String,
            compareTimeframe: 1,
            isIncrementNegative: false,
            decimalPlaces: 3,
            includeTrailingZeros: true,
            validDefinition: true,
            inputMultiValueSeparator: ',',
            outputMultiValueSeparator: ',',
            evaluatedDisplayType: FieldType.String,
            allowAddDropdownOptions: false,
            suggestedValue: '',
            previewDefinitionType: 'NEW_DEFINITION',
            doNotCalculatePreviewForDefinition: false,
            numberFieldDecimalPlaces: undefined,
        };
        return (fieldDefinition || fieldDefinitionPreview) as FieldDefinition<MatchItemFieldDefinition>;
    }, [fieldDefinition]);

    const [selectedModule, setSelectedModule] = useState<Group | undefined>(undefined);

    const [{ data: workflowFolderCategories }, getWorkflowFolderCategories] =
        useLazyTonkeanService('getWorkflowFolderCategories');

    useEffect(() => {
        if (!workflowFolderCategories && workflowFolder) {
            getWorkflowFolderCategories(projectManager.project.id, workflowFolder.id);
        }
    }, [getWorkflowFolderCategories, projectManager.project.id, workflowFolder, workflowFolderCategories]);

    const { loading: isLoadingContracts, data: contractsSummary } = useTonkeanService('getContractSummaries', groupId);

    const [, getContractFields] = useLazyTonkeanService('getContractFields');

    const solutionGroups = useMemo(() => {
        if (workflowFolderCategories && moduleData) {
            const workflowFolderCategoriesIds = new Set(
                workflowFolderCategories.workflowFolderCategories.map((category) => category.id),
            );
            return moduleData.filter(
                (group) =>
                    group.workflowFolderCategoryId === undefined ||
                    workflowFolderCategoriesIds.has(group.workflowFolderCategoryId),
            );
        }
    }, [moduleData, workflowFolderCategories]);

    useEffect(() => {
        if (moduleData && fieldDefinition?.projectIntegration?.representativeGroupId) {
            const existingFieldDefinitionSelectedModule = moduleData?.find(
                (module: Group) => module.id === fieldDefinition.projectIntegration.representativeGroupId,
            );

            if (existingFieldDefinitionSelectedModule) {
                setSelectedModule(existingFieldDefinitionSelectedModule);
            }
        } else {
            setSelectedModule(undefined);
        }
    }, [fieldDefinition?.projectIntegration?.representativeGroupId, moduleData]);

    const [, getGroupProjectIntegrationForMatchedItems] = useLazyTonkeanService(
        'getGroupProjectIntegrationForMatchedItems',
    );

    const [projectIntegration, setProjectIntegration] = useState<ProjectIntegration | undefined>(undefined);

    useEffect(() => {
        if (selectedModule) {
            const cachedProjectIntegration = selectedModule.allProjectIntegrations?.find(
                (projectIntegration) => projectIntegration.id === selectedModule.projectIntegrationIdForGroupItems,
            );
            if (cachedProjectIntegration) {
                setProjectIntegration(cachedProjectIntegration);
            } else {
                getGroupProjectIntegrationForMatchedItems(selectedModule.id).then((projectIntegration) =>
                    setProjectIntegration(projectIntegration),
                );
            }
        }
    }, [getGroupProjectIntegrationForMatchedItems, selectedModule]);

    const onModalEscCloseCallback = useCallback(() => {
        onModalEscClose(true);
    }, [onModalEscClose]);

    const updateFieldDefinitionCallback = useCallback(
        async (values) => {
            // we are extracting the configuration of the group project integration.
            // there is no expiration for this entity so we just take the first one.
            const externalType = Object.keys(
                projectIntegration?.entitiesExpirationConfiguration?.entitiesConfiguration,
            )[0];

            if (!externalType) {
                console.error("Cannot update new match item field definition. 'externalType' is undefined");

                emitToastMessage('Unable to create contract fields', 'danger');
                return;
            }

            const definition = {
                ...fieldDefinitionToEdit.definition,
                ...values.definition,
                ExternalType: externalType,
                matchConfiguration: {
                    ...values.definition.matchConfiguration,
                    matchedItemSourceGroupId: selectedModule?.id,
                    matchedItemSourceWorkflowVersionId: selectedModule?.draftWorkflowVersionId,
                },
            };

            const updated: FieldDefinition = await customFieldsManager.updateFieldDefinition(
                fieldDefinitionToEdit.id,
                fieldDefinitionToEdit.targetType,
                values.name,
                '',
                fieldDefinitionToEdit.type,
                fieldDefinitionToEdit.ranges,
                definition,
                projectIntegration?.id,
                groupId,
                fieldDefinitionToEdit.fieldType,
                [],
                fieldDefinitionToEdit.updateable,
                fieldDefinitionToEdit.isImportant,
                FieldType.String,
                fieldDefinitionToEdit.updateFieldPermissions,
                fieldDefinitionToEdit.isMultiValueField,
                fieldDefinitionToEdit.inputMultiValueSeparator,
                fieldDefinitionToEdit.outputMultiValueSeparator,
                fieldDefinitionToEdit.dropdownSource,
                fieldDefinitionToEdit.allowAddDropdownOptions,
                fieldDefinitionToEdit.dropdownOptionsFromFieldDefinitionId,
                fieldDefinitionToEdit.dropdownOptionsFromFieldDefinitionId,
                undefined,
                selectedModule?.name,
                fieldDefinitionToEdit.suggestedValue,
                fieldDefinitionToEdit.showManualOptionsNoResults,
                selectedModule?.id,
                fieldDefinitionToEdit.defaultValueFieldDefinitionId,
                fieldDefinitionToEdit.urlLabel,
            );
            reloadFields(updated, true);

            const contractId = definition?.matchConfiguration?.contractId;
            if (contractId) {
                const fields = await getContractFields(projectManager.project.id, contractId, ContractFieldType.OUTPUT);
                const allNestedFields: FieldDefinition<MatchItemFieldDefinition>[] =
                    customFieldsManager.selectedColumnFieldsMap[workflowVersion.id].filter(
                        (field: FieldDefinition<MatchItemFieldDefinition>) =>
                            field.definition?.matchConfiguration?.idRelationFieldDefinitionId === updated.id,
                    );

                allNestedFields.map(async (field) => {
                    const contractFieldName = fields.entities.find(
                        (contractField) => contractField.contractFieldId === field.definition?.FieldName,
                    )?.displayName;

                    const updatedNested: FieldDefinition = await customFieldsManager.updateFieldDefinition(
                        field.id,
                        field.targetType,
                        `${values.name} - ${contractFieldName}`,
                        '',
                        field.type,
                        field.ranges,
                        {
                            ExternalType: externalType,
                            FieldLabel: field.name,
                            FieldName: field.definition?.FieldName,
                            matchConfiguration: {
                                matchOption: 'SPECIFIC_ITEM',
                                performOnWorkerItem: false,
                                idRelationFieldDefinitionId: updated.id,
                                isForMatchingItem: true,
                                matchedItemSourceGroupId: selectedModule?.id,
                                matchedItemSourceWorkflowVersionId: selectedModule?.draftWorkflowVersionId,
                                contractId,
                            },
                        },
                        selectedModule?.projectIntegrationIdForGroupItems,
                        groupId,
                        field.fieldType,
                        [],
                        field.updateable,
                        field.isImportant,
                        FieldType.String,
                        field.updateFieldPermissions,
                        field.isMultiValueField,
                        field.inputMultiValueSeparator,
                        field.outputMultiValueSeparator,
                        field.dropdownSource,
                        field.allowAddDropdownOptions,
                        field.dropdownOptionsFromFieldDefinitionId,
                        field.dropdownOptionsFromFieldDefinitionId,
                        undefined,
                        field?.name,
                        field.suggestedValue,
                        field.showManualOptionsNoResults,
                        field.definition?.FieldName,
                        field.defaultValueFieldDefinitionId,
                        field.urlLabel,
                    );
                    reloadFields(updatedNested, true);
                });
            }

            onModalEscClose(true);
        },
        [
            fieldDefinitionToEdit.outputMultiValueSeparator,
            customFieldsManager,
            emitToastMessage,
            fieldDefinitionToEdit.allowAddDropdownOptions,
            fieldDefinitionToEdit.definition,
            fieldDefinitionToEdit.dropdownOptionsFromFieldDefinitionId,
            fieldDefinitionToEdit.dropdownSource,
            fieldDefinitionToEdit.defaultValueFieldDefinitionId,
            fieldDefinitionToEdit.fieldType,
            fieldDefinitionToEdit.id,
            fieldDefinitionToEdit.inputMultiValueSeparator,
            fieldDefinitionToEdit.isImportant,
            fieldDefinitionToEdit.isMultiValueField,
            fieldDefinitionToEdit.ranges,
            fieldDefinitionToEdit.showManualOptionsNoResults,
            fieldDefinitionToEdit.suggestedValue,
            fieldDefinitionToEdit.targetType,
            fieldDefinitionToEdit.type,
            fieldDefinitionToEdit.updateFieldPermissions,
            fieldDefinitionToEdit.updateable,
            fieldDefinitionToEdit.urlLabel,
            getContractFields,
            groupId,
            onModalEscClose,
            projectIntegration?.entitiesExpirationConfiguration?.entitiesConfiguration,
            projectIntegration?.id,
            projectManager.project.id,
            reloadFields,
            selectedModule?.draftWorkflowVersionId,
            selectedModule?.id,
            selectedModule?.name,
            selectedModule?.projectIntegrationIdForGroupItems,
            workflowVersion.id,
        ],
    );

    const createNewMatchItemFieldsAccordingToContract = useCallback(
        async (
            contractId: TonkeanId<TonkeanType.CONTRACT>,
            externalType: string,
            idRelationFieldDefinitionId: string,
            customTriggerName: string,
        ) => {
            if (!selectedModule?.id) {
                console.error(
                    "Cannot create new match item fields according to contract. 'selectedModule' is undefined",
                );
                emitToastMessage('Unable to create contract fields', 'danger');

                return;
            }
            const fields = await getContractFields(projectManager.project.id, contractId, ContractFieldType.OUTPUT);

            const contractFieldsPromises = fields.entities.map(async (field) => {
                const updated = await customFieldsManager.createFieldDefinition(
                    'COLUMN',
                    `${customTriggerName} - ${field.displayName}`,
                    undefined,
                    'EXTERNAL',
                    null,
                    {
                        ExternalType: externalType,
                        FieldLabel: field.displayName,
                        FieldName: field.contractFieldId,
                        matchConfiguration: {
                            matchOption: 'SPECIFIC_ITEM',
                            performOnWorkerItem: false,
                            idRelationFieldDefinitionId,
                            isForMatchingItem: true,
                            matchedItemSourceGroupId: selectedModule.id,
                            matchedItemSourceWorkflowVersionId: selectedModule.draftWorkflowVersionId,
                            contractId,
                        },
                    },
                    selectedModule.projectIntegrationIdForGroupItems,
                    groupId,
                    'String',
                    null,
                    undefined,
                    null,
                    null,
                    null,
                    false,
                    false,
                    true,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    null,
                    field.contractFieldId,
                    undefined,
                    undefined,
                    null,
                    undefined,
                );
                reloadFields(updated, true);
            });

            try {
                await Promise.all(contractFieldsPromises);
            } catch (error) {
                console.error('Error creating contract fields', error);

                emitToastMessage('Unable to create contract fields', 'danger');
            }
        },
        [
            customFieldsManager,
            emitToastMessage,
            getContractFields,
            groupId,
            projectManager.project.id,
            reloadFields,
            selectedModule?.draftWorkflowVersionId,
            selectedModule?.id,
            selectedModule?.projectIntegrationIdForGroupItems,
        ],
    );

    const createNewMatchItemFieldDefinition = useCallback(
        (values) => {
            if (selectedModule && projectIntegration) {
                // we are extracting the configuration of the group project integration.
                // there is no expiration for this entity so we just take the first one.
                const externalType = Object.keys(
                    projectIntegration?.entitiesExpirationConfiguration?.entitiesConfiguration,
                )[0];

                if (!externalType) {
                    console.error("Cannot create new match item field definition. 'externalType' is undefined");

                    emitToastMessage('Unable to create contract fields', 'danger');
                    return;
                }

                const definition = {
                    ...values.definition,
                    ExternalType: externalType,
                    matchConfiguration: {
                        ...values.definition.matchConfiguration,
                        matchedItemSourceGroupId: selectedModule.id,
                        matchedItemSourceWorkflowVersionId: selectedModule.draftWorkflowVersionId,
                    },
                };

                customFieldsManager
                    .createFieldDefinition(
                        FieldDefinitionTargetType.COLUMN,
                        values.name,
                        '',
                        'EXTERNAL',
                        null,
                        definition,
                        projectIntegration?.id,
                        groupId,
                        FieldType.String,
                        null,
                        true,
                        false,
                        null,
                        '',
                        false,
                        false,
                        false,
                        false,
                        true,
                        updateFieldPermissions,
                        true,
                        false,
                        ',',
                        undefined,
                        'MANUAL',
                        false,
                        undefined,
                        undefined,
                        undefined,
                        selectedModule.id,
                        undefined,
                        selectedModule.id,
                        '',
                        false,
                        null,
                        undefined,
                    )
                    .then(async (created) => {
                        if (!!definition.matchConfiguration.contractId) {
                            await createNewMatchItemFieldsAccordingToContract(
                                definition.matchConfiguration.contractId,
                                externalType,
                                created.id,
                                values.name,
                            );
                        }

                        reloadFields(created, true);
                        onModalEscClose(true);
                    });
            }
        },
        [
            createNewMatchItemFieldsAccordingToContract,
            customFieldsManager,
            emitToastMessage,
            groupId,
            onModalEscClose,
            projectIntegration,
            reloadFields,
            selectedModule,
        ],
    );

    return (
        <>
            {solutionGroups && (
                <Formik
                    key={`field_definition_${fieldDefinitionToEdit?.id}`}
                    initialValues={fieldDefinitionToEdit}
                    onSubmit={async (values) => {
                        if (!!fieldDefinition) {
                            await updateFieldDefinitionCallback(values);
                        } else {
                            createNewMatchItemFieldDefinition(values);
                        }
                    }}
                    enableReinitialize
                >
                    {(formik) => (
                        <FormikHelpers>
                            <FieldDefinitionMatchItemSettingsModalInner
                                groupId={groupId}
                                workflowVersion={workflowVersion}
                                onCancel={onCancel}
                                onDelete={onDelete}
                                onDuplicate={onDuplicate}
                                fieldDefinitionToEdit={fieldDefinitionToEdit}
                                selectedModule={selectedModule}
                                setSelectedModule={setSelectedModule}
                                modules={solutionGroups}
                                projectIntegration={projectIntegration}
                                onModalEscCloseCallback={onModalEscCloseCallback}
                                editFieldDefinition={!!fieldDefinition}
                                formik={formik}
                                contracts={contractsSummary?.entities || EMPTY_ARRAY}
                                isLoadingContracts={isLoadingContracts}
                            />
                        </FormikHelpers>
                    )}
                </Formik>
            )}
        </>
    );
};
export default FieldDefinitionMatchItemSettingsModal;
