import type { StateData } from '../entities/StateSectionsFormikValues';

import { InitiativeStatus } from '@tonkean/tonkean-entities';
import { DeprecatedDate } from '@tonkean/utils';
import { generateHEX } from '@tonkean/utils';

const typeToLabel = {
    [InitiativeStatus.ACTIVE]: 'New Status',
    [InitiativeStatus.INTAKE]: 'Intake Status',
};

const getNewStatus = (position: number, type: InitiativeStatus): StateData => {
    return {
        state: {
            id: 'NEW',
            label: `${typeToLabel[type]} (${position})`,
            color: generateHEX(),
            type,
        },
        formikId: `TEMP_ID${Number(DeprecatedDate.nowAsDate())}`,
    };
};

export default getNewStatus;
