<div class="update-field-logic" data-automation="update-fields-logic-configuration">
    <!-- We do loading on all the logic content because we want them to init after the fields for the relevant group have been loaded -->
    <div ng-if="data.initiativeToUseLoading" class="loading"></div>

    <div ng-if="!data.initiativeToUseLoading && !data.reloadFields">
        <!-- Fields to update selection -->
        <tnk-multiple-field-to-update-editors
            logic-id="{{ data.configuredLogic.node.id }}"
            field-selector-group-id="{{ data.groupId }}"
            field-selector-workflow-version-id="{{ data.workflowVersionIdForFieldSelector }}"
            expression-group-id="{{ data.groupId }}"
            expression-workflow-version-id="{{ data.workflowVersionId }}"
            is-preselected-field="{{ data.singleFieldSelector }}"
            preselected-field-label="{{ data.singleFieldSelectorLabel }}"
            preselected-field-id="{{ data.singleFieldSelectorFieldId }}"
            configured-fields="data.fieldsToUpdate"
            special-fields-for-features="['WORKER_UPDATE_FIELD']"
            preview-evaluation-source="data.previewEvaluationSource"
            reload-fields="data.reloadFields"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
            on-configured-fields-changed="onConfiguredFieldsChanged(configuredFields, shouldSaveLogic)"
        ></tnk-multiple-field-to-update-editors>

        <!-- Separator -->
        <hr />

        <!-- Fields selection title -->
        <div class="common-title-xxs">Updater</div>
        <span class="common-size-xxs common-color-grey3 margin-bottom">
            Choose who would be the updater of the field
        </span>

        <!-- Selecting notification method -->
        <tnk-person-selection-configuration
            class="block"
            support-quick-create="true"
            radio-id-prefix="'field-updater-' + data.workflowVersionId"
            hide-fail-if-no-value="true"
            allow-none="true"
            none-option-label="'Default actor (' + pm.groupsMap[data.groupId].defaultActor.name + ')'"
            max-people="1"
            configured-logic="data.configuredLogic"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
            saved-person-selection-configuration="data.existingFieldUpdaterPersonSelectionConfiguration"
            on-person-selection-configuration-changed="
                onFieldUpdaterPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
            "
            preview-evaluation-source="data.previewEvaluationSource"
            enable-choosing-tonkean="true"
        ></tnk-person-selection-configuration>

        <!-- Separator -->
        <hr />

        <!-- Track selection title -->
        <div class="common-title-xxs">What item to update</div>
        <span class="common-size-xxs common-color-grey3 margin-bottom">Choose on which item to run the updates</span>

        <!-- Track in flow option -->
        <tnk-radio-button
            radio-id="initiative-to-use-flow-{{ data.workflowVersionId }}"
            radio-name="initiative-to-use-option-{{ data.workflowVersionId }}"
            radio-label="Item in Flow"
            radio-value="flow-track"
            model="data.initiativeToUseOption"
            is-required="true"
            on-click="selectFlowTrackOption()"
            classes="margin-top"
        ></tnk-radio-button>

        <!-- Custom track option -->
        <tnk-radio-button
            radio-id="initiative-to-use-flow-{{ data.workflowVersionId }}"
            radio-name="initiative-to-use-option-{{ data.workflowVersionId }}"
            radio-label="Custom Item"
            radio-value="custom-track"
            model="data.initiativeToUseOption"
            is-required="true"
            on-click="selectCustomTrackOption()"
            classes="margin-top"
        ></tnk-radio-button>

        <div ng-if="data.initiativeToUseOption === 'custom-track'">
            <!-- Currently selected track -->
            <div ng-if="data.initiativeToUse" class="margin-bottom">
                Currently selected
                <i class="common-bold">{{ data.initiativeToUse.title }}</i>
                ({{ data.initiativeToUse.group.name }})
                <a ng-click="data.initiativeToUseEditMode = !data.initiativeToUseEditMode">Edit</a>
            </div>

            <div
                ng-if="!data.initiativeToUse || data.initiativeToUseEditMode"
                class="search-autocomplete-hide-link-tags"
            >
                <div>
                    <!-- Choose group (optional) -->
                    <ui-select
                        theme="bootstrap"
                        ng-model="data.initiativeGroupToUse"
                        ng-change="updateWorkflowVersionIdForFieldSelector()"
                    >
                        <ui-select-match placeholder="Choose Module">
                            {{ data.initiativeGroupToUse.name }}
                        </ui-select-match>
                        <ui-select-choices repeat="p in data.groupsOptions | filter:$select.search">
                            <div ng-bind-html="p.name | escapeHtml | highlight: $select.search"></div>
                        </ui-select-choices>
                    </ui-select>

                    <!-- Choose track -->
                    <tnk-search-auto-complete
                        class="flex-grow margin-top"
                        search-text="data.initiativeSearch.searchText"
                        placeholder="Search Item"
                        only-group-id="data.initiativeGroupToUse.id"
                        is-open="data.initiativeSearch.isOpen"
                        is-focused="data.initiativeSearch.isFocused"
                        results="data.initiativeSearch.results"
                        on-select="onInitiativeToUseSelect($item)"
                    ></tnk-search-auto-complete>
                </div>
            </div>
        </div>

        <!-- Dynamic track option -->
        <tnk-radio-button
            radio-id="initiative-to-use-flow-{{ data.workflowVersionId }}"
            radio-name="initiative-to-use-option-{{ data.workflowVersionId }}"
            radio-label="Item by Title"
            radio-value="by-title-track"
            model="data.initiativeToUseOption"
            is-required="true"
            on-click="selectByTitleTrackOption()"
            classes="margin-top"
        ></tnk-radio-button>

        <div ng-if="data.initiativeToUseOption === 'by-title-track'">
            <!-- Choose group (optional) -->
            <div class="margin-bottom-xs">
                <ui-select
                    theme="bootstrap"
                    ng-model="data.groupToUseWithTrackTitle"
                    ng-change="onGroupToUseWithTrackTitleChanged()"
                >
                    <ui-select-match placeholder="Choose Module">
                        {{ data.groupToUseWithTrackTitle.name }}
                    </ui-select-match>
                    <ui-select-choices repeat="p in data.groupsOptions | filter:$select.search">
                        <div ng-bind-html="p.name | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <span ng-if="data.groupToUseWithTrackTitleLoading" class="loading-small"></span>

            <!-- Track by title -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                placeholder="Item Title"
                saved-expression="data.initiativeTitleToUse"
                on-tonkean-expression-changed="onInitiativeTitleToUseExpressionChanged(expression, shouldSaveLogic)"
                preview-evaluation-source="data.previewEvaluationSource"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].trackToUpdate"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].trackToUpdate }}
        </div>

        <!-- Separator -->
        <hr />

        <!-- Should we send notification for the field update -->
        <div ng-if="!data.cantControlNotification">
            <!-- Notification Settings -->
            <div class="common-title-xxs">Notification Settings</div>
            <span class="common-size-xxs common-color-grey3 margin-bottom">
                Define whether item update notification should be sent (only in production environment)
            </span>

            <!-- Checkbox input -->
            <label>
                <input type="checkbox" ng-model="data.shouldSendNotification" ng-change="onActionsChanged(true)" />
                <span class="common-normal-weight">Send notifications</span>
            </label>
        </div>
    </div>
</div>
