import React, { useMemo } from 'react';
import type { GroupBase } from 'react-select';

import type { SimpleSelectProps, SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { SimpleSelect } from '@tonkean/infrastructure';
import { states } from '@tonkean/tonkean-entities';
import type { InitiativeStatus } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { DataAutomationSupportProps } from '@tonkean/utils';

type StateTypeOption = SimpleSelectSingleOption<keyof typeof states>;

interface StateTypeSelectProps
    extends DataAutomationSupportProps,
        Omit<SimpleSelectProps<StateTypeOption, false, false, GroupBase<StateTypeOption>>, 'options'> {
    typesToInclude?: Set<InitiativeStatus>;
}

const AllTypes: Set<InitiativeStatus> = new Set(Object.entries(states).map(([, { type }]) => type));

const StateTypeSelect: React.ForwardRefRenderFunction<HTMLDivElement, StateTypeSelectProps> = (
    { typesToInclude = AllTypes, ...props },
    ref,
) => {
    const options: StateTypeOption[] = useMemo(() => {
        const types = states;

        const stateTypesList = Object.values(types) as { type: keyof typeof states; label: string }[];

        return stateTypesList
            .filter((stateOption) => typesToInclude?.has(stateOption.type))
            .map((stateOption) => ({
                value: stateOption.type,
                label: stateOption.label,
            }));
    }, [typesToInclude]);

    return (
        // ref is needed for the tooltip
        <div ref={ref}>
            <SimpleSelect
                size={InputSize.MEDIUM}
                options={options}
                {...props}
                isDisabled={props.isDisabled || options.length <= 1}
            />
        </div>
    );
};

export default React.forwardRef(StateTypeSelect);
