import { useMemo } from 'react';

import FieldDateRangeValidation from '../entities/FieldDateRangeValidation';
import FieldRequiredValidation from '../entities/FieldRequiredValidation';
import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';

import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import type { ItemInterfaceWidget } from '@tonkean/tonkean-entities';

const useValidations = (
    widget: ItemInterfaceWidget<LineItemsWidgetConfiguration>,
    userCanEditLineItems: boolean,
    requiredFields: FieldDefinitionKey[],
) => {
    return useMemo(() => {
        // Create objects from widget configuration
        const dateRangeValidations = (widget.configuration?.dateRangeValidations || [])?.map((validation) => {
            return new FieldDateRangeValidation(validation.fieldDefinitionKey, validation.params);
        });

        const requiredValidations = requiredFields.map((requiredField) => {
            return new FieldRequiredValidation(requiredField, { isEditable: userCanEditLineItems });
        });

        return [...dateRangeValidations, ...requiredValidations];
    }, [requiredFields, widget.configuration?.dateRangeValidations, userCanEditLineItems]);
};

export default useValidations;
