<section class="common-page-full-height flex flex-col">
    <div class="common-page-load" ng-show="loadingOrg">
        <i class="loading-full-circle"></i>
    </div>
    <div
        class="org-page-container flex-grow"
        ng-hide="loadingOrg"
        ng-class="
            currentState === 'product.settings.license' && $state.params.tab === 'upgrade' ? 'flex' : 'flex-no-xs'
        "
    >
        <div
            class="org-menu"
            ng-if="!hideSideBar && (currentState !== 'product.settings.license' || $state.params.tab !== 'upgrade')"
            ng-class="{ 'mod-visible': data.showOrgMenu }"
            ng-click="data.showOrgMenu = false"
        >
            <span class="visible-xs common-close-btn margin-normal-sm">
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </span>

            <div
                class="flex-vmiddle margin-bottom-lg margin-left btn btn-no-border"
                data-automation="settings-return-to-board"
                ui-sref="product.board({filter: 'all'})"
            >
                <i class="svg-icon-sm margin-right common-color-grey">
                    <tnk-icon src="/images/icons/enter.svg"></tnk-icon>
                </i>
                Back
            </div>
            <div class="padding-bottom-lg">
                <a class="org-menu-item" ui-sref="product.settings.profile" ui-sref-active="mod-selected">
                    Profile & Notifications
                </a>

                <span ng-if="!pm.isExpired && pm.isOwner">
                    <h4 class="org-menu-title">Board Settings</h4>

                    <div>
                        <a class="org-menu-item" ui-sref="product.settings.board" ui-sref-active="mod-selected">
                            General
                        </a>
                        <a class="org-menu-item" ui-sref="product.settings.people" ui-sref-active="mod-selected">
                            Permissions
                        </a>
                    </div>
                </span>

                <span ng-if="!pm.isExpired && pm.isOwner && shouldShowEnterpriseComponentTab">
                    <h4 class="org-menu-title">Enterprise Components</h4>

                    <div>
                        <a class="org-menu-item" ui-sref="product.settings.dataSources" ui-sref-active="mod-selected">
                            Data Sources
                        </a>
                    </div>
                </span>
            </div>

            <div class="margin-top-lg" ng-if="!loadingOrg && !pm.isEnterprise && (!pm.isLicensed || pm.isOwner)">
                <h4 class="org-menu-title">
                    Subscription
                    <span class="hidden-xs">&nbsp;& Billing</span>
                </h4>
                <a
                    class="org-menu-item"
                    ui-sref="product.settings.license({tab:'overview'})"
                    ui-sref-active="mod-selected"
                >
                    Overview
                </a>
                <a
                    class="org-menu-item"
                    ui-sref="product.settings.license({tab: 'members'})"
                    ui-sref-active="mod-selected"
                >
                    Manage Seats
                </a>
                <a
                    class="org-menu-item"
                    ui-sref="product.settings.license({tab: 'invoices'})"
                    ui-sref-active="mod-selected"
                    ng-if="(pm.isBuyer || pm.isOwner) && license"
                >
                    Invoices
                </a>
            </div>
        </div>
        <div
            class="org-menu-mobile-btn visible-xs pull-right margin-normal"
            ng-if="
                (!pm.project || !pm.isExpired) &&
                (currentState !== 'product.settings.license' || $state.params.tab !== 'upgrade')
            "
        >
            <button class="btn btn-primary" ng-click="data.showOrgMenu = true"><i class="fa fa-bars"></i></button>
        </div>

        <div
            class="org-content"
            ng-if="!loadingOrg"
            ng-hide="getLicenseError && isLicensePage"
            ng-class="{
                'mod-center':
                    currentState !== 'product.settings.license' ||
                    ($state.params.tab !== 'upgrade' && $state.params.tab !== 'dataSources')
            }"
        >
            <!-- ng-class="{ 'mod-center': !['product.settings.license.upgrade', 'product.settings.dataSources'].includes(currentState) }" -->
            <!-- <ui-view></ui-view> -->
            <ng-transclude></ng-transclude>
        </div>

        <!-- Error loading license -->
        <div
            ng-if="getLicenseError && isLicensePage"
            class="margin-auto-right-left margin-top-xxlg padding-right padding-left"
        >
            <uib-alert type="danger" class="common-size-xxs margin-bottom">
                <i class="fa fa-exclamation-triangle margin-right-xs"></i>
                Failed loading subscription & billing data
            </uib-alert>
            <div class="text-center common-width-full">
                <a href="" ng-click="updateOrganization()">Retry</a>
            </div>
        </div>
    </div>
</section>
