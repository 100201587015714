import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const ItemInterfaceSelectorContainer = styled.div`
    padding: 0 16px 16px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    margin-bottom: 16px;
`;

export default ItemInterfaceSelectorContainer;
