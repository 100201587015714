function DeprecatedCopyFromJsonPageNoteCtrl($scope, $localStorage, $location) {
    $scope.init = function () {
        $scope.projectId = $localStorage.lastProjectId;
        $scope.urlPrefix = `${$location.protocol()}://${$location.host()}:${$location.port()}`;
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('DeprecatedCopyFromJsonPageNoteCtrl', DeprecatedCopyFromJsonPageNoteCtrl);
