import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { InnerItemWidgetConfiguration } from '../../entities';
import { LineItemWidgetAggregationType } from '../../entities';
import useAutomaticUpdateOfManualFields from '../hooks/useAutomaticUpdateOfManualFields';
import type { AggregationThatRequireCalculation } from '../hooks/useCalculateAggregations';
import useCalculateAggregations from '../hooks/useCalculateAggregations';
import alphabeticallySort from '../utils/alphabeticallySort';

import { Breakpoint, H4, Paragraph } from '@tonkean/infrastructure';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { type FieldDefinition, FieldType, type Initiative, type WidgetBase } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { EMPTY_ARRAY } from '@tonkean/utils';

const Disclaimer = styled(Paragraph)`
    color: ${Theme.colors.gray_500};
`;

const Aggregation = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    direction: ltr;
    align-items: center;

    @media screen and (max-width: ${Breakpoint.SMALL_1366}px) {
        justify-content: space-between;
        width: 100%;
`;

const AggregationsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    direction: rtl;
    gap: 16px;
`;

const Wrapper = styled.div`
    min-height: 40px;
    max-height: 200px;
    overflow: auto;
    display: flex;
    gap: 4px;
    flex-direction: column;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 0 0 4px 4px;
    border-top: none;
    padding: 16px;
`;

interface Props {
    parentInitiativeId: Initiative['id'] | undefined;
    itemsValues: Record<FieldDefinitionKey, string | number | undefined | Date>[];
    widget: WidgetBase<InnerItemWidgetConfiguration>;
    hasMoreInitiatives: boolean;
    totalInitiatives: number | undefined;
    isLoaded: boolean;
    onUpdateParentInitiative?: (initiative: Initiative) => void;
    fieldDefinitions: FieldDefinition[];
}

const LineItemSummaryFooter: React.FC<Props> = ({
    widget,
    itemsValues,
    hasMoreInitiatives,
    totalInitiatives,
    parentInitiativeId,
    isLoaded,
    onUpdateParentInitiative,
    fieldDefinitions,
}): JSX.Element => {
    const fieldDisplay = useAngularService('fieldDisplay');

    const validAggregations = useMemo(() => {
        return (
            widget.configuration.aggregations
                ?.filter(
                    (agg): agg is AggregationThatRequireCalculation =>
                        agg.aggregation !== LineItemWidgetAggregationType.NONE,
                )
                // Filter out aggregations that don't have a field definition key.
                // keep fields that are not marked as is shown since we still want to aggregate them encase they need to be saved.
                .filter((agg) => widget.configuration.fields[agg.fieldDefinitionKey]) || EMPTY_ARRAY
        );
    }, [widget.configuration.aggregations, widget.configuration.fields]);

    const fieldDefinitionKeyToPrefixAndPostFix: Record<string, readonly [string, string]> = useMemo(() => {
        const keyToPrefixWithPostfix = fieldDefinitions?.map(
            (fieldDefinition) =>
                [
                    fieldDefinition.id,
                    [fieldDefinition.displayFormatPrefix || '', fieldDefinition.displayFormatPostfix || ''] as const,
                ] as const,
        );

        return Object.fromEntries(keyToPrefixWithPostfix || []);
    }, [fieldDefinitions]);

    const fieldDefinitionsMap = useMemo(() => {
        return Object.fromEntries(fieldDefinitions.map((fide) => [fide.id, fide]));
    }, [fieldDefinitions]);

    const calculationResults = useCalculateAggregations(itemsValues, validAggregations);

    const sortedCalculationResults = calculationResults.sort(([aggA], [aggB]) =>
        alphabeticallySort(aggA.title, aggB.title),
    );

    useAutomaticUpdateOfManualFields(
        parentInitiativeId,
        calculationResults,
        isLoaded,
        widget.workflowVersionId,
        onUpdateParentInitiative,
    );

    const titleWithPrettyValue: (readonly [FieldDefinitionKey, string, number | string | Date, string])[] =
        useMemo(() => {
            return sortedCalculationResults
                .filter(([aggregation]) => !(aggregation.hide && aggregation.outputFieldDefinitionId))
                .map(([aggregation, value]) => {
                    const fieldDefinition = fieldDefinitionsMap[aggregation.fieldDefinitionKey];

                    if (
                        value === null ||
                        value === undefined ||
                        value?.toString()?.trim() === '' ||
                        (fieldDefinition?.fieldType === FieldType.Number && !Number.isFinite(Number(value)))
                    ) {
                        return [aggregation.fieldDefinitionKey, `${aggregation.title}:`, value, 'No Value'] as const;
                    }
                    const evaluatedValue = fieldDisplay
                        .evaluateFieldDisplayValue(
                            fieldDefinition?.fieldType,
                            fieldDefinition?.displayFormat,
                            value,
                            value,
                            value,
                        )
                        ?.toString();

                    if (!evaluatedValue || evaluatedValue === 'Invalid Date') {
                        return [aggregation.fieldDefinitionKey, `${aggregation.title}:`, value, 'No Value'] as const;
                    } else {
                        const [prefix, suffix] = fieldDefinitionKeyToPrefixAndPostFix?.[
                            aggregation.fieldDefinitionKey
                        ] || ['', ''];

                        const valueToView: string = prefix + evaluatedValue + suffix;

                        return [aggregation.fieldDefinitionKey, `${aggregation.title}:`, value, valueToView] as const;
                    }
                });
        }, [fieldDefinitionKeyToPrefixAndPostFix, fieldDefinitionsMap, fieldDisplay, sortedCalculationResults]);

    return (
        <>
            {titleWithPrettyValue.length > 0 && (
                <Wrapper>
                    <AggregationsWrapper>
                        {titleWithPrettyValue.map(([key, title, value, valueToView]) => (
                            <Aggregation key={key} data-automation="line-item-summary-footer">
                                <H4 $light>{title}</H4>
                                <H4
                                    title={value.toString()}
                                    data-automation={`line-item-summary-footer-${title}`}
                                    $bold
                                >
                                    {valueToView}
                                </H4>
                            </Aggregation>
                        ))}
                    </AggregationsWrapper>

                    {hasMoreInitiatives && (
                        <Disclaimer>
                            Aggregated {itemsValues.length} of {totalInitiatives}
                        </Disclaimer>
                    )}
                </Wrapper>
            )}
        </>
    );
};
export default LineItemSummaryFooter;
