import { useAngularService } from 'angulareact';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFetchManager } from '@tonkean/infrastructure';
import type { Group, TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { debouncer } from '@tonkean/utils';

const usePollGroupsByIds = (entityVersionType: WorkflowVersionType) => {
    const [groupIdsToPoll, setGroupIdsToPoll] = useState<Set<TonkeanId<TonkeanType.GROUP>>>(new Set());

    const groupInfoManager = useAngularService('groupInfoManager');
    const [[getGroups, cancelGroupFetcher]] = useFetchManager(groupInfoManager, 'getGroupsByIds', {
        autoReloadInterval: 10_000,
        isSingle: false,
        getItems: (items) => items,
        compareItems: (first: Group, second: Group) => first.id !== second.id,
    });

    const debounce = useMemo(() => {
        return debouncer();
    }, []);

    useEffect(() => {
        if (groupIdsToPoll.size > 0) {
            debounce(() => {
                getGroups([...groupIdsToPoll], true, true, entityVersionType);
            });
        }

        return cancelGroupFetcher;
    }, [cancelGroupFetcher, getGroups, groupIdsToPoll, entityVersionType, debounce]);

    const addGroupIdsToPoll = useCallback(
        (groupIds: TonkeanId<TonkeanType.GROUP>[]) => {
            setGroupIdsToPoll((prevGroupIds) => new Set([...prevGroupIds, ...groupIds]));
        },
        [setGroupIdsToPoll],
    );

    return { addGroupIdsToPoll };
};

export default usePollGroupsByIds;
