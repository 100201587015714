import template from './tnkSecondaryTypeSidePane.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSecondaryTypeSidePane', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        customTrigger: '<',
        onTypeChanged: '&',
        isMonitorTrack: '<',
        isInnerAutonomous: '<',
    },
    template,
    controller: 'SecondaryTypeSidePaneCtrl',
});
