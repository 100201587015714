import { lateConstructController } from '@tonkean/angular-components';
import { getFormulaSpecialFieldIdToDefinitionMap } from '@tonkean/tonkean-utils';

/* @ngInject */
function InitiativeSpecialFieldSelector(
    $scope,
    $rootScope,
    $q,
    $location,
    tonkeanService,
    projectManager,
    trackHelper,
    workflowVersionManager,
) {
    const ctrl = this;
    const specialFieldMap = getFormulaSpecialFieldIdToDefinitionMap();

    $scope.pm = projectManager;

    $scope.data = {
        // Ctrl properties.
        initiativeId: ctrl.initiativeId,
        fieldId: ctrl.fieldId ? ctrl.fieldId.toUpperCase() : null,
        workflowVersionId: ctrl.workflowVersionId,

        // Inner properties.
        initiative: null,
        currentFieldValue: { value: null },
        specialFieldDefinition: null, // The config for the current special field from tonkeanUtils.
        previousFieldValue: null,

        // States.
        saving: false,
    };

    /**
     * Initialization function.
     */
    ctrl.$onInit = function () {
        if (ctrl.delegateSave && angular.isObject(ctrl.delegateSave)) {
            // execpeting an object
            ctrl.delegateSave.savePromise = innerSave;
            $scope.data.hideSaveButton = true;
        }
    };

    /**
     * Changes function.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.initiativeId) {
            $scope.data.initiativeId = changesObj.initiativeId.currentValue;

            // Get the initiative from the server if it has changed and is not null.
            if (
                changesObj.initiativeId.currentValue &&
                changesObj.initiativeId.currentValue !== changesObj.initiativeId.previousValue
            ) {
                getInitiative();
            }
        }
        if (changesObj.fieldId) {
            $scope.data.fieldId = changesObj.fieldId.currentValue
                ? changesObj.fieldId.currentValue.toUpperCase()
                : null;

            // Save the special field definition config.
            $scope.data.specialFieldDefinition = $scope.data.fieldId ? specialFieldMap[$scope.data.fieldId] : null;
        }
    };

    $scope.onFieldValueChanged = function (selectedValueToUpdate) {
        // Make sure the currentFieldValue is updated (the reference might have changed).
        if (selectedValueToUpdate) {
            $scope.data.currentFieldValue = selectedValueToUpdate;
        } else {
            // selectedValueToUpdate is null. We only want to set the value as null and not the whole object.
            $scope.data.currentFieldValue = { value: null };
        }
    };

    /**
     * Saves the current selection to the server.
     */
    $scope.save = function () {
        if (!$scope.data.hideSaveButton) {
            // if allowing save - perform real save
            innerSave();
        }
    };

    function innerSave() {
        // Do not allow to save same value multiple times. (unless it's an array, like in TNK_TAGS)
        if (
            $scope.data.currentFieldValue &&
            !angular.isArray($scope.data.previousFieldValue) &&
            $scope.data.previousFieldValue === $scope.data.currentFieldValue.value
        ) {
            return $q.resolve();
        }

        if ($scope.data.currentFieldValue && $scope.data.initiative && $scope.data.specialFieldDefinition) {
            $scope.data.saving = true;

            // Update the previous value.
            $scope.data.previousFieldValue = $scope.data.currentFieldValue.value;

            const workerRunId = $location.search().workerRunId;
            const customTriggerId = $location.search().customTriggerId;
            const workflowVersionId = $location.search().workflowVersionId;

            return $scope.data.specialFieldDefinition
                .updateValueOnInitiative(
                    trackHelper,
                    $scope.data.initiative,
                    $scope.data.currentFieldValue.value,
                    workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId),
                )
                .then(() => {
                    ctrl.onSaved && ctrl.onSaved();

                    return $q.resolve();
                })
                .then(() => {
                    return tonkeanService.resumeModuleFlowAfterTrigger(
                        $scope.data.initiative.id,
                        workerRunId,
                        customTriggerId,
                        workflowVersionId,
                    );
                })
                .then(() => {
                    if (!$scope.data.hideSaveButton) {
                        $rootScope.$emit('alert', { msg: 'Saved!', type: 'success' });
                    }
                })
                .catch(() => {
                    $rootScope.$emit('alert', { msg: 'Error saving value.' });
                })
                .finally(() => {
                    $scope.data.saving = false;
                });
        }

        return $q.resolve();
    }

    /**
     * Gets the initiative according to the current initiativeId.
     * Also sets the currentFieldValue once the initiative is achieved.
     */
    function getInitiative() {
        if ($scope.data.initiativeId) {
            trackHelper.getInitiativeById($scope.data.initiativeId).then((initiative) => {
                // Save the initiative so we can get data from it later.
                $scope.data.initiative = initiative;
                // Extract the field value from the initiative.
                $scope.data.currentFieldValue = {
                    value: $scope.data.specialFieldDefinition.extractValueFromInitiative(
                        initiative,
                        workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId),
                    ),
                };
                // Save the previous value as well (so we do not allow to save when not needed).
                $scope.data.previousFieldValue = $scope.data.currentFieldValue.value;
            });
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('InitiativeSpecialFieldSelector', lateConstructController(InitiativeSpecialFieldSelector));
