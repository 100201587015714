import { Formik } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ModalForm } from '@tonkean/infrastructure';
import { useCloseCallback } from '@tonkean/infrastructure';
import { ModalBody, ModalHeader } from '@tonkean/infrastructure';
import { ModalFooterActions } from '@tonkean/infrastructure';
import { H4 } from '@tonkean/infrastructure';
import { withModal } from '@tonkean/infrastructure';
import type { SolutionBusinessReport } from '@tonkean/tonkean-entities';

const StyledModalBody = styled(ModalBody)`
    width: 400px;
    padding: 16px;
`;

interface Props {
    solutionBusinessReport: SolutionBusinessReport;
    onDelete(): void;
}

const SolutionReportDeleteModal: React.FC<Props> = ({ solutionBusinessReport, onDelete }) => {
    const closeModal = useCloseCallback();

    const [{ error }, deleteSolutionBusinessReport] = useLazyTonkeanService('deleteSolutionBusinessReport');

    const onSubmit = useCallback(
        (values) => {
            return deleteSolutionBusinessReport(solutionBusinessReport.id).then(() => {
                onDelete();
                closeModal();
            });
        },
        [closeModal, deleteSolutionBusinessReport, onDelete, solutionBusinessReport.id],
    );

    return (
        <Formik initialValues={{}} onSubmit={onSubmit}>
            <ModalForm>
                <ModalHeader $padding={16}>
                    <H4 $bold>Delete Report</H4>
                </ModalHeader>
                <StyledModalBody>
                    <H4 $bold>Are you sure you want to delete the report?</H4>
                </StyledModalBody>
                <ModalFooterActions saveLabel="Delete" error={error} isDelete />
            </ModalForm>
        </Formik>
    );
};

export default withModal(SolutionReportDeleteModal);
