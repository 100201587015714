import { lateConstructController } from '@tonkean/angular-components';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';

/* @ngInject */
function IntegrationGoogleFieldCtrl(
    $scope,
    createProjectApis,
    projectManager,
    integrations,
    utils,
    customFieldsManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        // Component bindings
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        targetType: ctrl.targetType,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
        existingFieldDefinition: ctrl.existingFieldDefinition,

        fieldDefinitionName: ctrl.fieldDefinitionName,
        fieldDefinitionNameEdited: ctrl.fieldDefinitionNameEdited,

        view: null,
        metric: null,
        timespan: '7',
        dimension: null,
        segment: null,
        metricIsDimension: null,
        showGoogleFieldSetup: true,

        loadingExternal: false,
        availableViews: [],
        availableFields: [],
        availableSegments: [],

        selectedField: null,
        specialFieldMap: utils.createMapFromArray(getSpecialFieldsForFeatures(true), 'id'),
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        $scope.data.selectedField = $scope.data.specialFieldMap['TNK_TITLE'];

        $scope.loadExternalViews(true);
        $scope.data.googleStep = 'selectView';

        if (!$scope.data.createMode && $scope.data.existingFieldDefinition.definition) {
            initializeEditMode();
        }

        // It is important to fire the event to get the containing controller to have the updated data.
        definitionChanged();
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function () {};

    /**
     * Loads google analytics external views.
     */
    $scope.loadExternalViews = function (shouldReset) {
        // we don't want to delete the users value when he is editing an already existing external field
        if (shouldReset) {
            $scope.data.view = null;
        }

        $scope.data.availableViews = null;
        $scope.data.loadingExternal = true;

        const integrationId = $scope.data.projectIntegration.integration.id;

        if (!integrationId) {
            $scope.data.loadingExternal = false;
            return;
        }

        const projectId = projectManager.project.id;

        createProjectApis.getAutoCompleteOptions(projectId, integrationId, 'views').then((result) => {
            $scope.data.availableViews = result.options;
            $scope.data.loadingExternal = false;
        });
    };

    /**
     * Loads google analytics external segments.
     */
    $scope.loadExternalSegments = function (shouldReset) {
        // we don't want to delete the users value when he is editing an already existing external field
        if (shouldReset) {
            $scope.data.segment = null;
        }

        $scope.data.availableSegments = null;
        $scope.data.loadingExternal = true;

        const integrationId = $scope.data.projectIntegration.integration.id;

        if (!integrationId) {
            $scope.data.loadingExternal = false;
            return;
        }
        const projectId = projectManager.project.id;

        createProjectApis.getAutoCompleteOptions(projectId, integrationId, 'segments').then((result) => {
            $scope.data.availableSegments = result.options;
            $scope.data.loadingExternal = false;
        });
    };

    /**
     * Loads google analytics external options.
     */
    $scope.loadExternalOptions = function () {
        $scope.data.availableFields = null;
        $scope.data.loadingExternal = true;

        const integrationId = $scope.data.projectIntegration.integration.id;

        if (!integrationId) {
            $scope.data.loadingExternal = false;
            return;
        }

        if ($scope.data.view) {
            const accountId = $scope.data.view.accountId;
            const propertyId = $scope.data.view.webPropertyId;
            const projectId = projectManager.project.id;

            createProjectApis
                .getAutoCompleteOptions(projectId, integrationId, 'metricsAndDimensions', {
                    accountId,
                    webPropertyId: propertyId,
                    viewId: $scope.data.view.value,
                })
                .then((result) => {
                    $scope.data.availableFields = result.options;
                    $scope.data.loadingExternal = false;
                });
        }
    };

    /**
     * Occurs once a view is selected.
     */
    $scope.onViewSelected = function (view) {
        $scope.data.view = view;
        $scope.loadExternalOptions();
        $scope.loadExternalSegments(false);
        $scope.data.googleStep = 'selectFields';

        definitionChanged();
    };

    /**
     * Occurs once a time frame is selected.
     */
    $scope.onTimeFrameSelected = function () {
        definitionChanged();
    };

    /**
     * Occurs once an external field is selected.
     */
    $scope.externalFieldSelected = function () {
        definitionChanged();
    };

    /**
     * Occurs once a dimension is selected.
     */
    $scope.onDimensionSelected = function () {
        definitionChanged();
    };

    /**
     * Toggles showing the google field step.
     */
    $scope.toggleShowGoogleFieldStep = function () {
        $scope.data.showGoogleFieldSetup = !$scope.data.showGoogleFieldSetup;
    };

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged() {
        const validDefinition = !!(
            $scope.data.view &&
            $scope.data.metric &&
            $scope.data.timespan &&
            ($scope.data.targetType === 'GLOBAL' || $scope.data.dimension)
        );

        const definitionObject = {
            fieldConfigurationSummaryTitle: 'Google analytics',
            fieldConfigurationSummarySubTitle: null,
            validDefinition,
            definition: {
                viewId: $scope.data.view ? $scope.data.view.value : null,
                view: $scope.data.view,
                metric: $scope.data.metric ? $scope.data.metric.value : null,
                metricName: $scope.data.metric ? $scope.data.metric.displayName : null,
                timespan: $scope.data.timespan,
                dimension: $scope.data.dimension ? $scope.data.dimension.value : null,
                dimensionName: $scope.data.dimension ? $scope.data.dimension.displayName : null,
                segment: $scope.data.segment ? $scope.data.segment.value : null,
                segmentName: $scope.data.segment ? $scope.data.segment.displayName : null,
                metricIsDimension: !!$scope.data.metricIsDimension,
            },
        };

        if ($scope.data.selectedField) {
            definitionObject.definition.matchFieldDefinitionId = $scope.data.selectedField.isSpecialField
                ? null
                : $scope.data.selectedField.id;
            definitionObject.definition.matchSpecialFieldId = $scope.data.selectedField.isSpecialField
                ? $scope.data.selectedField.id
                : null;

            if ($scope.data.selectedField.id !== 'TNK_TITLE') {
                definitionObject.additionalDefinitionsToPreview = [
                    {
                        name: $scope.data.selectedField.label || $scope.data.selectedField.name,
                        definitionId: $scope.data.selectedField.id,
                        fieldDefinitionTargetType: $scope.data.targetType,
                        validDefinition: true,
                        previewDefinitionType: $scope.data.selectedField.isSpecialField
                            ? 'INITIATIVE_FIELD'
                            : 'EXISTING_FIELD_DEFINITION',
                    },
                ];
            }
        }

        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({
                newDefinition: definitionObject,
                doNotReloadPreview: !validDefinition,
            });
        }
    }

    /**
     * Initializes elastic edit mode.
     */
    function initializeEditMode() {
        if ($scope.data.existingFieldDefinition.definition.viewId) {
            $scope.data.view = {};
            $scope.data.view = $scope.data.existingFieldDefinition.definition.view;

            $scope.loadExternalSegments(false);
            $scope.loadExternalOptions();
            $scope.data.googleStep = 'selectFields';
        } else {
            $scope.data.googleStep = 'selectView';
        }

        if ($scope.data.existingFieldDefinition.definition.metric) {
            $scope.data.metric = {
                value: $scope.data.existingFieldDefinition.definition.metric,
                displayName: $scope.data.existingFieldDefinition.definition.metricName,
            };
            if ($scope.data.existingFieldDefinition.definition.metricIsDimension) {
                $scope.data.metricIsDimension = $scope.data.existingFieldDefinition.definition.metricIsDimension;
                $scope.data.metric.type = 'dimension';
            } else {
                $scope.data.metricIsDimension = false;
                $scope.data.metric.type = 'metric';
            }
        }

        if ($scope.data.existingFieldDefinition.definition.dimension) {
            $scope.data.dimension = {
                value: $scope.data.existingFieldDefinition.definition.dimension,
                displayName: $scope.data.existingFieldDefinition.definition.dimensionName,
            };
        }

        if ($scope.data.existingFieldDefinition.definition.segment) {
            $scope.data.segment = {
                value: $scope.data.existingFieldDefinition.definition.segment,
                displayName: $scope.data.existingFieldDefinition.definition.segmentName,
            };
            $scope.data.advancedOptions = true;
        }

        if ($scope.data.existingFieldDefinition.definition.timespan) {
            $scope.data.timespan = $scope.data.existingFieldDefinition.definition.timespan.toString();
        }

        const fieldDefinitionMap = utils.createMapFromArray(
            customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
            'id',
        );

        if ($scope.data.existingFieldDefinition.definition.matchSpecialFieldId) {
            $scope.data.selectedField =
                $scope.data.specialFieldMap[$scope.data.existingFieldDefinition.definition.matchSpecialFieldId];
        } else if ($scope.data.existingFieldDefinition.definition.matchFieldDefinitionId) {
            $scope.data.selectedField =
                fieldDefinitionMap[$scope.data.existingFieldDefinition.definition.matchFieldDefinitionId];
        }

        $scope.data.showGoogleFieldSetup = !$scope.data.existingFieldDefinition.definition.view;
    }

    /**
     * Adds custom fields to integration's available fields.
     */
    $scope.addCustomFields = function (fieldOptions, projectIntegration, externalType) {
        let fieldsToAdd = [];

        if (
            projectIntegration &&
            projectIntegration.integration &&
            projectIntegration.integration.integrationUniqueType.toLowerCase() === 'salesforce' &&
            externalType.toLowerCase() === 'task'
        ) {
            fieldsToAdd.push(
                {
                    name: 'What-Name',
                    label: 'Account Name',
                    type: 'string',
                    source: 'pencil',
                },
                {
                    name: 'What-Name',
                    label: 'Opportunity Name',
                    type: 'string',
                },
                {
                    name: 'What-Name',
                    label: 'Case Name',
                    type: 'string',
                },
            );
        }

        fieldsToAdd = fieldsToAdd.concat(
            integrations.generateIntegrationCustomFields(projectIntegration, $scope.pm.project.integrations),
        );

        return fieldsToAdd;
    };

    /**
     * Occurs once a field has been selected in a filter.
     * @param selectedField The selected field.
     */
    $scope.onFieldOptionSelected = function (selectedField) {
        $scope.data.selectedField = selectedField;
        definitionChanged();
    };
}
export default angular
    .module('tonkean.app')
    .controller('IntegrationGoogleFieldCtrl', lateConstructController(IntegrationGoogleFieldCtrl));
