import utils from '@tonkean/utils';

/**
 * This validates the send item interfaced logic has selected an item interface and adds an appropriate message.
 */
const itemInterfaceMustBeSelectedValidator = (validationObject, definition) => {
    if (
        utils.isNullOrUndefined(definition.itemInterfaceId) &&
        (utils.isNullOrUndefined(definition.contractId) ||
            utils.isNullOrUndefined(definition.contractFieldId) ||
            utils.isNullOrUndefined(definition.matchedInterfaceGroupId))
    ) {
        validationObject.noItemInterfaceSelected = 'You must select an item interface';
    }
};

export default itemInterfaceMustBeSelectedValidator;
