import React from 'react';
import styled from 'styled-components';

import { MenuItem, TextEllipsis } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SectionTitle = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    padding: 4px 10px 4px 6px;
    cursor: default;

    &:not(:first-child) {
        padding-top: 14px;
    }
    &:hover {
        color: ${Theme.colors.gray_700};
    }
`;

export interface ModuleOption {
    label: string;
    value: string;
}

interface Props {
    sectionName: string;
    menuOptions: ModuleOption[];
    selectedGroupId: TonkeanId<TonkeanType.GROUP>;
    onMenuItemClicked: (item: ModuleOption) => void;
}

const ModulePickerMenuSection: React.FC<Props> = ({ sectionName, menuOptions, selectedGroupId, onMenuItemClicked }) => {
    if (!menuOptions.length) {
        return null;
    }

    return (
        <>
            <SectionTitle>
                <TextEllipsis numberOfLines={1} tooltip>
                    {sectionName}
                </TextEllipsis>
            </SectionTitle>
            {menuOptions.map((groupOption) => (
                <MenuItem
                    key={groupOption.value}
                    selected={groupOption.value === selectedGroupId}
                    onClick={() => onMenuItemClicked(groupOption)}
                    data-automation="module-picker"
                    data-automation-group-label={groupOption.label}
                >
                    {groupOption.label}
                </MenuItem>
            ))}
        </>
    );
};

export default ModulePickerMenuSection;
