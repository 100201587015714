import type { IHttpResponse } from 'angular';
import { useCallback } from 'react';

import type { FormElements } from './EditCustomFieldModal';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';

interface Response {
    onSubmit: (values: FormElements) => void;
    createAndUpdateLoading: boolean;
}
const useCreateOrUpdateProjectIntegrationEntityFieldMetadata = (
    updateTableData,
    onModalClose,
    currentProjectIntegration,
    editedEntity,
    fieldToEdit,
    setError,
): Response => {
    const [{ loading: createLoading, error: createError }, createProjectIntegrationEntityFieldMetadata] =
        useLazyTonkeanService('createProjectIntegrationEntityFieldMetadata');
    const [{ loading: updateLoading, error: updateError }, updateProjectIntegrationEntityFieldMetadata] =
        useLazyTonkeanService('updateProjectIntegrationEntityFieldMetadata');

    const createAndUpdateLoading: boolean = createLoading || updateLoading;

    const isIHttpResponse = useCallback((error: any): error is IHttpResponse<any> => {
        return error && typeof error === 'object' && 'status' in error;
    }, []);

    const onSubmit = useCallback(
        async (values: FormElements) => {
            const newUpdatedFields = {
                displayName: values.displayName,
                generatedJsonFieldName: values.fieldName,
                isCustomField: values.isCustomFieldToggle,
                fieldType: values.fieldType,
                shouldIncludeInCreateAction: values.shouldIncludeInCreateActionToggle,
                isRequiredInCreateAction: values.isRequiredInCreateActionToggle,
                shouldIncludeInUpdateAndCustomAction: values.shouldIncludeInUpdateAndCustomActionToggle,
            };

            try {
                if (fieldToEdit === null) {
                    const data = await createProjectIntegrationEntityFieldMetadata({
                        projectIntegrationId: currentProjectIntegration!.id,
                        projectIntegrationEntityFieldMetadataParams: {
                            ...newUpdatedFields,
                            subFields: values.subFields || [],
                            projectIntegrationEntityId: editedEntity!.entityId,
                            collectedFieldName: editedEntity!.fieldName,
                            isMultiValues: false,
                        },
                    });
                    updateTableData(data.projectIntegrationEntityFieldMetadataObject);
                    onModalClose();
                } else {
                    const data = await updateProjectIntegrationEntityFieldMetadata({
                        projectIntegrationId: currentProjectIntegration!.id,
                        projectIntegrationEntityFieldMetadataId: fieldToEdit.id,
                        projectIntegrationEntityFieldMetadataParams: {
                            ...newUpdatedFields,
                            subFields: values.subFields || [],
                            isMultiValues: fieldToEdit.isMultiValues,
                            parentId: fieldToEdit.parentId,
                        },
                    });
                    updateTableData(data.projectIntegrationEntityFieldMetadataObject);
                    onModalClose();
                }
            } catch (error) {
                if (isIHttpResponse(error)) {
                    setError(error);
                }
            }
        },
        [
            createProjectIntegrationEntityFieldMetadata,
            currentProjectIntegration,
            editedEntity,
            fieldToEdit,
            isIHttpResponse,
            onModalClose,
            setError,
            updateProjectIntegrationEntityFieldMetadata,
            updateTableData,
        ],
    );

    return { onSubmit, createAndUpdateLoading };
};

export default useCreateOrUpdateProjectIntegrationEntityFieldMetadata;
