import React from 'react';
import styled from 'styled-components';

import { Checkbox, SearchBox } from '@tonkean/infrastructure';
import type { PersonPermissionRoleType } from '@tonkean/tonkean-entities';
import { personPermissionRoleToActionName } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SearchWrapper = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    flex-grow: 1;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const ShowAuthorizationTypesWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_800};
`;

const CheckboxWrapper = styled.span`
    margin-left: 12px;
`;

interface Props {
    onSearchTermChange: (value: string) => void;
    searchTerm: string;
    showAuthorizationTypes?: Record<PersonPermissionRoleType, boolean>;
    onShowAuthorizationTypesChange?: (authorizationTypes: Record<PersonPermissionRoleType, boolean>) => void;
    authorizationFiltersPrefix?: string;
    disabled?: boolean;
    placeholder?: string;
}

const EnterpriseComponentSearch: React.FC<Props> = ({
    onSearchTermChange,
    searchTerm,
    showAuthorizationTypes,
    onShowAuthorizationTypesChange,
    authorizationFiltersPrefix,
    disabled = false,
    placeholder,
}) => {
    const updateShowAuthorizationTypes = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!showAuthorizationTypes || !onShowAuthorizationTypesChange) {
            return;
        }

        onShowAuthorizationTypesChange({
            ...showAuthorizationTypes,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <SearchWrapper disabled={disabled}>
            <SearchBox
                value={searchTerm}
                onChange={({ target: { value } }) => onSearchTermChange(value)}
                $width="60%"
                disabled={disabled}
                placeholder={placeholder}
            />
            {showAuthorizationTypes && (
                <ShowAuthorizationTypesWrapper>
                    {authorizationFiltersPrefix}
                    {Object.entries(showAuthorizationTypes).map(([accessType, isShown]) => {
                        return (
                            <CheckboxWrapper key={accessType}>
                                <Checkbox
                                    checked={isShown}
                                    onChange={updateShowAuthorizationTypes}
                                    name={accessType}
                                    disabled={disabled}
                                >
                                    {personPermissionRoleToActionName[accessType]}
                                </Checkbox>
                            </CheckboxWrapper>
                        );
                    })}
                </ShowAuthorizationTypesWrapper>
            )}
        </SearchWrapper>
    );
};

export default EnterpriseComponentSearch;
