import React from 'react';

import AutonomousTypesDropdown from './AutonomousTypesDropdown';

import type { LogicBlockConfigDefinition } from '@tonkean/logic-block-configs';
import type { DataAutomationSupportProps } from '@tonkean/utils';

interface Props extends DataAutomationSupportProps {
    onAutonomousTypeSelected: (typeConfig: LogicBlockConfigDefinition) => void;
    isMonitorTrack?: boolean;
    isInnerAutonomous?: boolean;
}
const PencilButtonAutonomousTypesDropdown: React.FC<Props> = ({
    onAutonomousTypeSelected,
    isMonitorTrack,
    isInnerAutonomous,
}) => {
    return (
        <AutonomousTypesDropdown
            dropdownTitle="Change Type To:"
            onAutonomousTypeSelected={onAutonomousTypeSelected}
            isMonitorTrack={isMonitorTrack}
            isInnerAutonomous={isInnerAutonomous}
            placement="bottom-start"
        >
            <i
                className="fa fa-pencil common-color-link-blue common-size-xxxs edit-button"
                data-automation="secondary-type-side-pane-change-trigger-type"
            />
        </AutonomousTypesDropdown>
    );
};

export default PencilButtonAutonomousTypesDropdown;
