import { analyticsWrapper } from '@tonkean/analytics';
import { VIEW_TYPES } from '@tonkean/constants';

function ImportTracksCtrl(
    $scope,
    $rootScope,
    $timeout,
    integrations,
    tonkeanService,
    utils,
    modalUtils,
    csvHelper,
    workflowVersionManager,
    syncConfigManager,
    syncConfigCacheManager,
) {
    $scope.pm = $rootScope.pm;
    $scope.integrations = integrations;
    $scope.modalUtils = modalUtils;
    $scope.trackHelper = $rootScope.trackHelper;
    $scope.parentName = $scope.parentInitiative ? $scope.parentInitiative.title : $scope.func ? $scope.func.name : null;

    $scope.data = {
        selectedNewIntegration: {},
        includeIds: {},
        includeIdCount: 0,
        filterByText: '',
        mode: 'import',
        syncControl: {},
        selectedProjectIntegration: null,
        integrationsSettings: {},
        isBotActiveObject: null,
    };

    /**
     * Initialization function of the controller.
     */
    $scope.init = function () {
        $scope.data.integrationsSettings = $scope.onlySync ? integrations.getSyncSettings() : integrations.getImportSettings();

        $scope.data.isBotActiveObject = {
            getInitialValue: () =>
                workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId).shouldSendGatherUpdates,
            shouldOverride: false,
            overrideValue: null,
        };
        const group = $scope.pm.groupsMap[$scope.groupId]
            ? $scope.pm.groupsMap[$scope.groupId]
            : $scope.parentInitiative.group;

        // Merge the project integrations array to be able to access all the relevant ones.
        // Both the integrations that were added to the group from other people and the ones availabe in the project
        const availableProjectIntegrations = [...group.groupProjectIntegrations, ...$scope.pm.project.integrations];
        $scope.projectDataImportIntegrations = utils
            .distinctArrayById(availableProjectIntegrations)
            .filter(function (projectIntegration) {
                return !!integrations.getImportSettingsByIntegration(projectIntegration.integration.integrationType);
            });

        initializeNonExistingSources();

        $scope.data.currentStep = 'selectIntegration';
        $scope.data.previousStep = 'selectIntegration';

        // If we're already connected to an integration, we do the selection process for the user.
        if ($scope.connectedIntegrationUniqueType) {
            const selectedProjectIntegration = utils.findFirst(
                $scope.projectDataImportIntegrations,
                function (projectIntegration) {
                    return (
                        projectIntegration.integration.integrationUniqueType === $scope.connectedIntegrationUniqueType
                    );
                },
            );

            // Integration is either broken or deleted(less likely)
            if (!selectedProjectIntegration) {
                return;
            }

            // There's only one integration of each integration type.
            $scope.selectProjectIntegration(selectedProjectIntegration);
        }

        // If we don't have any data import project integrations connected, we should show right away the
        // connect new integrations part.
        if (!$scope.projectDataImportIntegrations || !$scope.projectDataImportIntegrations.length) {
            $scope.data.showNewIntegrations = true;
        }

        // Initializing the selected entity from definition, if such exists
        if ($scope.syncData) {
            const selectedFromDefinition = $scope.syncData['Entity'] || $scope.syncData['entity'];

            // If integration supports all entities, we don't go through consts
            if (
                integrations.integrationSupportsAllEntities(
                    $scope.data.selectedProjectIntegration.integration.integrationType,
                )
            ) {
                $scope.data.selectedEntity = selectedFromDefinition;
                if ($scope.syncData.entityMetadata) {
                    $scope.data.selectedEntityDisplayName = $scope.syncData.entityMetadata.label;
                } else {
                    $scope.data.selectedEntityDisplayName = $scope.data.selectedEntity;
                }
            } else {
                for (const key in $scope.data.selectedSyncSettings) {
                    if (
                        $scope.data.selectedSyncSettings.hasOwnProperty(key) &&
                        $scope.data.selectedSyncSettings[key].entityType &&
                        $scope.data.selectedSyncSettings[key].entityType === selectedFromDefinition
                    ) {
                        $scope.data.selectedEntity = key;
                        $scope.data.selectedEntityDisplayName = getDisplayNameFromEntity($scope.data.selectedEntity);
                        break;
                    }
                }
            }
        }
    };

    /**
     * Opens the sync preview modal.
     */
    $scope.openSyncPreviewModal = function () {
        const viewTypes = VIEW_TYPES;

        // Preview only applies to sync mode, therefore, if we're not in sync mode, we just execute the import.
        if ($scope.data.mode === 'sync') {
            $scope.data.previewSyncControl = {
                selectedView: $scope.data.syncControl.selectedView,
                createViewDataForIntegrationView: $scope.data.syncControl.createViewDataForIntegrationView,
            };

            let syncDefinition;

            if (!$scope.data.syncControl.customDefinedView) {
                // using a view
                syncDefinition = $scope.data.syncControl.createViewDataForIntegrationView(
                    $scope.data.syncControl.selectedView,
                );
            } else {
                // using a custom filter
                syncDefinition = $scope.data.syncControl.createDefinitionFromCustomFilters();

                if (!$scope.data.syncControl.hasFilter) {
                    // if want "all" then clean filters
                    syncDefinition.query = {
                        filters: [],
                        type: 'And',
                    };
                }
            }

            $scope.modalUtils.openSyncPreviewModal(
                $scope.data.previewSyncControl,
                $scope.data.syncControl.customDefinedView,
                $scope.data.selectedEntityMetadata?.pluralLabel || $scope.data.selectedEntity,
                $scope.data.selectedEntity,
                $scope.data.selectedProjectIntegration,
                syncDefinition,
                $scope.data.syncControl.customDefinedView ? viewTypes.custom : viewTypes.integration,
                $scope.data.syncControl.getAllIntegrationViews,
                $scope.data.isBotActiveObject,
                $scope.hideBotInPreview,
                true,
                $scope.execute,
                $scope.onModalClose,
                $scope.data.syncControl.getTitleExpression(),
                $scope.data.syncControl.getDefaultTitleIfExpressionEmpty(),
            );
        } else {
            $scope.execute();
        }
    };

    /**
     * Removes the sync from the given group or initiative.
     * @param projectId - the current project id.
     * @param groupId - The group id to sync (if syncing a group).
     * @param initiative - The initiative to sync (if syncing an initiative).
     */
    $scope.removeSync = function (projectId, groupId, initiative) {
        $scope.removingSync = true;

        if (initiative) {
            const syncConfigId = initiative.syncConfig ? initiative.syncConfig.id : null;

            tonkeanService
                .deleteSyncConfig(syncConfigId)
                .then(function () {
                    if ($scope.onDeleteCallback) {
                        $scope.onDeleteCallback();
                    }
                    $scope.removingSync = false;
                    $scope.trackHelper.getInitiativeFromCache(initiative.id).syncConfig = null;

                    $timeout(() => $scope.init());
                })
                .catch(function (error) {
                    $scope.removingSync = false;
                    $scope.data.removeSyncError = error;
                });
        } else {
            const syncConfigId = syncConfigCacheManager.getSyncConfig($scope.workflowVersionId).id;

            syncConfigManager
                .removeGroupSyncConfig(groupId, syncConfigId)
                .then(function () {
                    $scope.removingSync = false;

                    if ($scope.onDeleteCallback) {
                        $scope.onDeleteCallback();
                    }

                    $timeout(() => $scope.init());
                })
                .catch(function (error) {
                    $scope.removingSync = false;
                    $scope.data.removeSyncError = error;
                });
        }
    };

    $rootScope.$on('integrationsFinishedFirstCollect', function () {
        $timeout(function () {
            if ($scope.data.selectedProjectIntegration && $scope.data.selectedEntity) {
                // Reloading the existing integrations of the project
                initializeNonExistingSources();

                // Setting the project integration object to be the newly fetched one,
                // according to the selected project integration id
                for (let i = 0; i < $scope.pm.project.integrations.length; i++) {
                    const projectIntegration = $scope.pm.project.integrations[i];

                    if (projectIntegration.id === $scope.data.selectedProjectIntegration.id) {
                        $scope.data.selectedProjectIntegration = projectIntegration;
                        break;
                    }
                }

                $scope.loadData();
            }
        });
    });

    /**
     * Selects a project integration for import/sync.
     */
    $scope.selectProjectIntegration = function (projectIntegration) {
        analyticsWrapper.track(
            `Select integration ${projectIntegration ? projectIntegration.integration.integrationType : ''}`,
            { category: 'Import' },
        );

        $scope.data.selectedProjectIntegration = projectIntegration;
        $scope.data.selectedImportSettings = integrations.getImportSettingsByIntegration(
            $scope.data.selectedProjectIntegration.integration.integrationType,
        );
        $scope.data.selectedSyncSettings = integrations.getSyncSettingsByIntegration(
            $scope.data.selectedProjectIntegration.integration.integrationType,
        );

        if (
            ($scope.data.selectedImportSettings && $scope.data.selectedImportSettings.length) ||
            ($scope.data.selectedSyncSettings && $scope.data.selectedSyncSettings.length)
        ) {
            $scope.data.selectedEntity =
                $scope.onlySync === true
                    ? $scope.data.selectedSyncSettings.supportedEntities[0]
                    : $scope.data.selectedImportSettings[0];
            $scope.onEntityTypeChange($scope.data.selectedEntity);
        } else {
            $scope.data.selectedEntity = null;
            $scope.data.selectedEntityDisplayName = '';
        }

        if ($scope.data.selectedEntity) {
            $scope.data.selectedEntityDisplayName = getDisplayNameFromEntity($scope.data.selectedEntity);
        }

        initImportMode();

        $scope.data.previousStep = $scope.data.currentStep;
        $scope.data.currentStep = 'selectEntity';

        if ($scope.data.selectedEntity) {
            $scope.loadData();
        }

        if ($scope.onIntegrationSelectedCallback) {
            $scope.onIntegrationSelectedCallback({ projectIntegration });
        }
    };

    /**
     * Occurs once the selection of an entity is changed.
     */
    $scope.onEntityTypeChange = function (selectedEntity) {
        $scope.data.selectedEntityMetadata = selectedEntity;

        if (selectedEntity.entity) {
            $scope.data.selectedEntity = selectedEntity.entity;
            $scope.data.selectedEntityDisplayName = selectedEntity.label;
        } else if (selectedEntity.pluralLabel) {
            $scope.data.selectedEntity = selectedEntity.pluralLabel;
            $scope.data.selectedEntityDisplayName = selectedEntity.pluralLabel;
        } else {
            $scope.data.selectedEntity = selectedEntity;
            $scope.data.selectedEntityDisplayName = selectedEntity;
        }

        $scope.data.selectedView = null;

        initImportMode();

        $scope.loadData();
    };

    /**
     * Loads the data needed for the controller.
     */
    $scope.loadData = function () {
        if (!$scope.onlySync) {
            $scope.loadEntities();
        }
    };

    $scope.onModalClose = function () {};

    /**
     * Executes the import/sync.
     */
    $scope.execute = function () {
        $scope.posting = true;
        $scope.onSavedCallback();
        $scope.data.error = null;

        if ($scope.data.mode === 'sync' && $scope.data.previewSyncControl) {
            // reload only exists on custom filters syncs
            if ($scope.data.syncControl.reloadFromExistingDefinition) {
                const definitionToCreate = $scope.data.previewSyncControl.createDefinitionFromCustomFilters();
                $scope.data.syncControl.reloadFromExistingDefinition(definitionToCreate);
                $scope.reloadFields();
            } else {
                $scope.data.syncControl.selectedView = $scope.data.previewSyncControl.selectedView;
            }

            if ($scope.data.isBotActiveObject?.shouldOverride) {
                workflowVersionManager.toggleShouldSendGatherUpdates(
                    $scope.groupId,
                    $scope.data.isBotActiveObject.overrideValue,
                );
            }

            $scope.data.syncControl.sync();
        }

        if ($scope.data.mode === 'import') {
            $scope.import();
        }
    };

    /**
     * Imports items.
     */
    $scope.import = function () {
        $scope.posting = true;
        $scope.onSavedCallback();
        $scope.data.error = null;

        const parentId = $scope.parentInitiative ? $scope.parentInitiative.id : null;

        const importIds = [];
        if ($scope.data.includeIdCount) {
            for (const id in $scope.data.includeIds) {
                if ($scope.data.includeIds.hasOwnProperty(id) && $scope.data.includeIds[id]) {
                    importIds.push(id);
                }
                if (importIds.length === 100) {
                    break;
                }
            }
        }

        analyticsWrapper.track(
            `Import ${$scope.data.selectedProjectIntegration ? $scope.data.selectedProjectIntegration.name : ''}`,
            { category: 'Import' },
        );

        tonkeanService
            .importInitiatives(
                $scope.pm.project.id,
                $scope.data.selectedProjectIntegration.id,
                $scope.data.selectedEntity,
                parentId,
                $scope.workflowVersionId,
                false,
                importIds,
            )
            .then(function () {
                // Mark the importOrSync step in the on boarding as completed.
                $rootScope.onBoardingManager.completeStep('importOrSync');

                // Firing groupListUpdated so the list is updated.
                $rootScope.$broadcast('groupListUpdated', { groupIds: [$scope.groupId], shouldReloadLiveReport: true });

                $scope.onClose(true);
            })
            .catch(function (error) {
                $scope.data.error = error;
                $scope.posting = false;
            });
    };

    /**
     * On closing the import tracks.
     */
    $scope.onClose = function (noFailedTracks = true) {
        $scope.posting = false;

        if ($scope.onCompleteCallback) {
            $scope.onCompleteCallback();
        }

        $timeout(function () {
            const message =
                ($scope.data.mode === 'sync' ? 'Sync' : 'Import') + (noFailedTracks ? ' was successful!' : ' failed');

            $scope.$emit('alert', {
                msg: message,
                type: noFailedTracks ? 'success' : 'error',
            });

            // If import was used for syncing.
            if ($scope.onlySync) {
                if (!$scope.parentInitiative) {
                    // If we were syncing a group.
                    $rootScope.$broadcast('newActivityUpdate');
                }
            } else {
                // If import was used for import...
                $rootScope.$broadcast('newActivityUpdate');
            }
        });
    };

    $scope.onError = function (error) {
        $scope.data.error = error;
        $scope.loading = false;
        $scope.posting = false;
    };

    $scope.loadEntities = function () {
        $scope.loading = true;
        $scope.data.error = null;
        $scope.data.includeIdCount = 0;
        $scope.data.includeIds = {};
        $scope.data.filterByText = '';

        const parentId = $scope.parentInitiative ? $scope.parentInitiative.id : null;
        const requireIndex =
            integrations.getImportIndexOnlySettings()[
                $scope.data.selectedProjectIntegration.integration.integrationType.toLowerCase()
            ];

        if (requireIndex && !$scope.data.selectedProjectIntegration.lastCollect) {
            // require index to complete but it hasn't
            $scope.data.waitingForIndex = true;
            $scope.pm.areIntegrationsCollecting().then(function (result) {
                if (!result && $scope.data.selectedProjectIntegration.lastCollect) {
                    // if finished
                    $scope.loadData();
                }
            });
        } else {
            $scope.data.waitingForIndex = false;
            return tonkeanService
                .importInitiatives(
                    $scope.pm.project.id,
                    $scope.data.selectedProjectIntegration.id,
                    $scope.data.selectedEntity,
                    parentId,
                    $scope.workflowVersionId,
                    true,
                )
                .then(function (data) {
                    $scope.data.allEntities = data.entities;
                    $scope.data.entities = data.entities;
                    if ($scope.data.entities.length < 20) {
                        $scope.bulkSelect(true);
                    }

                    if ($scope.data.entities && $scope.data.entities.length > 14) {
                        $scope.data.reviewImport = true;
                    }
                    $scope.loading = false;
                })
                .catch(function (error) {
                    $scope.data.error = error;
                    $scope.loading = false;
                });
        }
    };

    $scope.filterFromServer = function () {
        if ($scope.data.filterByText && $scope.data.filterByText.length > 1) {
            const parentId = $scope.parentInitiative ? $scope.parentInitiative.id : null;

            $scope.data.filteringFromServer = true;
            tonkeanService
                .importInitiatives(
                    $scope.pm.project.id,
                    $scope.data.selectedProjectIntegration.id,
                    $scope.data.selectedEntity,
                    parentId,
                    $scope.workflowVersionId,
                    true,
                    null,
                    $scope.data.filterByText,
                )
                .then(function (data) {
                    if (data.entities && data.entities.length) {
                        $scope.data.entities = data.entities;
                    }
                })
                .finally(function () {
                    $scope.data.filteringFromServer = false;
                });
            //     }
            // },300);
        } else if (!$scope.data.filterByText.length) {
            // reload the full list
            $scope.data.entities = $scope.data.allEntities;
        }
    };

    $scope.includeItem = function (item) {
        if (!$scope.data.includeIds[item.id]) {
            $scope.data.includeIds[item.id] = item;
            $scope.data.includeIdCount++;
        } else {
            delete $scope.data.includeIds[item.id];
            $scope.data.includeIdCount--;
        }
    };

    $scope.bulkSelect = function (all, filteredEntities) {
        if (all) {
            const entities = filteredEntities || $scope.data.entities;
            let currentCount = $scope.data.includeIdCount || 0;
            angular.forEach(entities, function (item) {
                if (!$scope.data.includeIds[item.id]) {
                    currentCount += 1;
                }
                $scope.data.includeIds[item.id] = item;
            });
            $scope.data.includeIdCount = currentCount;
        } else {
            $scope.data.includeIds = {};
            $scope.data.includeIdCount = 0;
        }
    };

    /**
     * Checks if any of the given integrations are available for syncing group with.
     * @param projectIntegrations Integrations to check with.
     */
    $scope.anyIntegrationsForSync = function (projectIntegrations) {
        if (!projectIntegrations || !projectIntegrations.length) {
            return false;
        }

        for (const projectIntegration of projectIntegrations) {
            if (integrations.getSyncSettingsByIntegration(projectIntegration.integration.integrationType)) {
                return true;
            }
        }

        return false;
    };

    /**
     * Occurs once a new non-existing integration was added.
     */
    $scope.onIntegrationSaved = function (overrideState) {
        let projectIntegration;
        if (overrideState) {
            const stateValue = utils.objToArray(overrideState)[0].value;
            projectIntegration = stateValue.projectIntegrations
                ? stateValue.projectIntegrations[0]
                : stateValue.integrations[0];
        } else {
            projectIntegration = utils.objToArray($scope.data.selectedNewIntegration)[0].value.projectIntegrations[0];
        }

        $scope.selectProjectIntegration(projectIntegration);
        initializeNonExistingSources();
    };

    /**
     * Initializes the import mode of the directive.
     */
    function initImportMode() {
        if ($scope.onlyImport) {
            $scope.data.allowSync = false;
            $scope.data.mode = 'import';
        } else {
            $scope.data.allowSync = true;
            $scope.data.mode = 'sync';
        }
    }

    /**
     * Initializes $scope.data.nonExistingIntegrationSources with all the non-connected sources.
     */
    function initializeNonExistingSources() {
        // Creating a set of existing integration types
        const existingIntegrations = {};

        for (let i = 0; i < $scope.projectDataImportIntegrations.length; i++) {
            const type =
                $scope.projectDataImportIntegrations[i].integration.isNativeIntegration &&
                !$scope.projectDataImportIntegrations[i].integration.supportsMultipleIntegrationsPerUser
                    ? $scope.projectDataImportIntegrations[i].integration.integrationType
                    : $scope.projectDataImportIntegrations[i].integration.integrationUniqueType;
            existingIntegrations[type.toLowerCase()] = true;
        }

        // Taking only the sources that do not already exist
        $scope.data.nonExistingIntegrationSources = $scope.onlySync
            ? integrations.getSyncSettings()
            : integrations.getImportSettings();
        $scope.data.nonExistingIntegrationSources = utils
            .objKeys($scope.data.nonExistingIntegrationSources)
            .filter(function (source) {
                return !existingIntegrations[source.toLowerCase()];
            });
    }

    /** ****** region: Import CSV ********/

    $scope.importFromCSVClicked = function () {
        analyticsWrapper.track('CSV Option Clicked', { category: 'Import' });

        $scope.data.previousStep = 'selectIntegration';
        $scope.data.currentStep = 'importCsv';
    };

    $scope.handleFileSelect = function (el) {
        $scope.error = null;

        const file = el.files[0];
        $scope.data.fileName = file.name;
        $scope.loadingFile = true;

        csvHelper.parseFile(
            file,
            (table, csvFile) => {
                $scope.$apply(function ($scope) {
                    analyticsWrapper.track('CSV file uploaded', { category: 'Import' });
                    $scope.data.file = csvFile;
                    $scope.data.table = table;
                    $scope.loadingFile = false;
                });
            },
            (error) => {
                $scope.$apply(function ($scope) {
                    $scope.loadingFile = false;
                    $scope.error = error;
                });
            },
        );
    };

    $scope.onFileChange = function (e) {
        $scope.handleFileSelect(e.target);
    };

    $scope.clearFile = function () {
        $scope.data.file = null;
        $scope.data.table = null;
    };

    $scope.back = function () {
        $scope.init();
        $scope.dismiss();
    };

    /** endregion */

    $scope.init();

    function getDisplayNameFromEntity(entity) {
        return entity?.label || entity?.pluralLabel || entity || '';
    }
}

export default angular.module('tonkean.app').controller('ImportTracksCtrl', ImportTracksCtrl);
