<div class="padding-normal">
    <div class="modal-header">
        <div class="flex-vmiddle">
            <div class="integration-icon mod-googlesheets"></div>
            <h4 class="flex-grow">Set Up Google Spreadsheet</h4>
        </div>
    </div>
    <div class="margin-top">
        <tnk-google-sheet-selector
            on-project-integration-created="onGoogleSheetSelected(projectIntegration, fieldDefinitionsToCreate)"
            display-column-selection="true"
            on-cancel="cancel()"
        ></tnk-google-sheet-selector>
    </div>
</div>
