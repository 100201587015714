import GoogleGroupSetupComponent from './GoogleGroupSetupComponent';
import googleGroupCircle from '../../../../apps/tracks/images/google-groups-circle.svg';
import googleGroupLogo from '../../../../apps/tracks/images/integrations/google-groups-logo.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import { GoogleAuthenticationComponent } from '../components';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

export default class GoogleGroupsIntegration extends IntegrationDefinitionBase {
    override name = 'googlegroups';
    override displayName = 'Google Groups';
    override description = '';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override customizedAuthenticationComponent = GoogleAuthenticationComponent;
    override customizedSetupComponent = GoogleGroupSetupComponent;

    override entities = [
        new IntegrationEntity('Group', 'Groups'),
        new IntegrationEntity('GroupMember', 'Group Members'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('OrganizationUnit', 'Organization Units'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(googleGroupCircle, '130px'),
        new IntegrationIcon(googleGroupLogo, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
    };
}
