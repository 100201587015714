import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function UpdateOwnerLogicConfigurationCtrl($scope, projectManager) {
    const ctrl = this;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        defaultSpecialFieldId: 'TNK_OWNER_ID',
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
        }
    };

    $scope.onActionsChanged = function (definition, shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            ctrl.onActionsChanged({ definition, shouldSaveLogic });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('updateOwnerLogicConfigurationCtrl', lateConstructController(UpdateOwnerLogicConfigurationCtrl));
