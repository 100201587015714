import React from 'react';
import styled from 'styled-components';

import WorkerRunsTableItemGrid from './WorkerRunsTableItemGrid';

import { FontSize, Theme } from '@tonkean/tui-theme';

const theme = Theme.current.palette.history.workerRunsList;

const HeaderRow = styled(WorkerRunsTableItemGrid)`
    flex-grow: 0;
    flex-shrink: 0;
`;

const HeaderItem = styled.div`
    padding: 10px 0;
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    line-height: 10px;
    color: ${theme.tableHeaderColor};

    &:first-child {
        padding-left: 20px;
    }
`;

const WorkerRunsTableHeader: React.FC = () => {
    return (
        <>
            <HeaderRow>
                <HeaderItem>Time</HeaderItem>
                <HeaderItem>Event</HeaderItem>
                <HeaderItem>Involved Item</HeaderItem>
                <HeaderItem>Status</HeaderItem>
            </HeaderRow>
        </>
    );
};

export default WorkerRunsTableHeader;
