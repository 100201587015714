import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage, TextEllipsis } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import { MessageSeverity } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const StatusWrapper = styled(Clickable)<{ severity: MessageSeverity }>`
    margin-right: 20px;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    display: flex;
    min-width: 150px;
    align-items: center;
    border-radius: 44px;
    padding: 0 10px;

    ${({ severity }) => {
        switch (severity) {
            case MessageSeverity.WARNING:
                return css`
                    background-color: rgba(240, 119, 30, 0.1);
                    color: ${Theme.colors.warning};
                    border: 1px solid ${Theme.colors.warning};

                    &:focus,
                    &:hover {
                        color: ${Theme.colors.warning};
                    }
                `;
            case MessageSeverity.INFO:
                return css`
                    background-color: ${Theme.colors.gray_300};
                    color: ${Theme.current.palette.colorPicker.HEX_605CE4};

                    &:focus,
                    &:hover {
                        color: ${Theme.current.palette.colorPicker.HEX_605CE4};
                    }
                `;
        }
    }}
`;

const TrialStatus: React.FC = () => {
    const project = useProject();
    const authenticationService = useAngularService('authenticationService');
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const { data, error } = useTonkeanService('getTrialStatus', project.id);

    const currentUserId = authenticationService.getCurrentUser().id;
    const isAdmin = useMemo(() => {
        const userId = currentUserId;
        return project.owners.find((owner) => owner.id === userId);
    }, [currentUserId, project.owners]);

    if (error) {
        return <ErrorMessage>Error Loading Trial Status</ErrorMessage>;
    }

    if (!data?.isTrialMode) {
        return <></>;
    }

    const solutionsMap = workflowFolderManager.projectIdToFolderIdToFolderMap[project.id];
    const hasSolution = solutionsMap && Object.keys(solutionsMap).length > 0;
    const stateProp = isAdmin && hasSolution ? { state: 'product.settings.board' } : {};
    const trialMessage = data.messageContent ? ` - ${data.messageContent}` : '';
    return (
        <StatusWrapper {...stateProp} severity={data.messageSeverity}>
            <TextEllipsis numberOfLines={1} tooltip>
                Trial Account {trialMessage}
            </TextEllipsis>
        </StatusWrapper>
    );
};

export default TrialStatus;
