import { PencilOIcon } from '@tonkean/svg';
import React from 'react';
import { Swipeable } from 'react-swipeable';
import { TrackActions } from '@tonkean/flux';
import Utils from '@tonkean/utils';
import { TextEllipsis } from '@tonkean/infrastructure';
import TrackInnerItemsCounts from './TrackInnerItemsCounts';
import { DUMMY_TRACK_TITLE } from '@tonkean/constants';

/**
 * A components that renders the track list item field.
 */
export default class TrackTitleContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        // The title container depends on several properties. Any change in them should cause a re-render. Any other change can be ignored.
        // Below are all the things that are in this components interest.
        if (
            !Utils.shallowEqual(this.props.track, nextProps.track) ||
            !Utils.shallowEqual(this.props.realTrack, nextProps.realTrack)
        ) {
            return true;
        }
        if (this.props.track.title !== nextProps.track.title || this.props.title !== nextProps.title) {
            return true;
        }
        if (
            (this.props.track.status !== nextProps.track.status || this.props.status !== nextProps.status) &&
            nextProps.status === 'DONE'
        ) {
            return true;
        }
        if (
            this.props.track.description !== nextProps.track.description ||
            this.props.description !== nextProps.description
        ) {
            return true;
        }
        if (this.props.realTrack.parentInitiatives !== nextProps.realTrack.parentInitiatives) {
            return true;
        }
        if (this.props.doneTracksProgress !== nextProps.doneTracksProgress) {
            // We check if the progress has changed so we can update the done count.
            return true;
        }
        if (this.props.realTrack.description !== nextProps.realTrack.description) {
            return true;
        }
        if (this.props.realTrack.externalId !== nextProps.realTrack.externalId) {
            return true;
        }
        if (this.props.realTrack.loadingRelated !== nextProps.realTrack.loadingRelated) {
            return true;
        }
        if (this.props.realTrack.isArchived !== nextProps.realTrack.isArchived) {
            return true;
        }
        if (this.props.editTitleMode !== nextProps.editTitleMode) {
            return true;
        }
        if (this.props.trackSwipeShownInMobile !== nextProps.trackSwipeShownInMobile) {
            return true;
        }
        if (this.props.disableGoToTrack !== nextProps.disableGoToTrack) {
            return true;
        }
        if (this.props.enableInnerItemsToggle !== nextProps.enableInnerItemsToggle) {
            return true;
        }
        return false;
    }

    // region: Actions

    startTitleEditMode() {
        TrackActions.toggleTrackTitleEdit(this.props.track.id, this.props.data.editorId, true);
    }

    setSelected() {
        this.props.uiActions.setSelected(this.props.getIndex(), this.props.realTrack, true);
    }

    onTitleChange(newTitle) {
        /**
         * When editing the dummy item and clicking outside, the newTitle is '',
         * but we don't want to update the dummy item's title, because it won't be dummy anymore.
         *
         * @see: {@link https://tonkean.atlassian.net/browse/TNKN-6813}
         */
        if (newTitle === '' && this.props.realTrack.title === DUMMY_TRACK_TITLE) {
            return;
        }
        this.props.uiActions.onTitleChange(this.props.realTrack, newTitle);
    }

    // endregion: Actions

    // region: UI Actions

    updateTitle(e, keepInEditMode) {
        // NOTE: this assumes that the track editor make sure only a real change to the title will be posted to the server
        this.onTitleChange(e.target.value);

        if (!keepInEditMode) {
            TrackActions.toggleTrackTitleEdit(this.props.track.id, this.props.data.editorId, false);
        }
    }

    onTitleKeyDown(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            // Handle enter.
            e.preventDefault();
            this.setSelected();
            this.updateTitle(e);
        } else if (e.key === 'Escape' || e.keyCode === 27) {
            // Handle escape.
            e.preventDefault();
            TrackActions.toggleTrackTitleEdit(this.props.track.id, this.props.data.editorId, false);
        } else if ((e.key === 'Tab' || e.keyCode === 9) && !e.shiftKey) {
            // Handle tab (without shift).
            // Keeping the edited value of the title updated in the store.
            TrackActions.trackTitleEditValueUpdated(this.props.track.id, this.props.data.editorId, e.target.value);

            //  e.preventDefault();
            this.props.uiActions.tabInTrack();
        } else if ((e.key === 'Tab' || e.keyCode === 9) && e.shiftKey) {
            // Handle tab (with shift).
            // e.preventDefault();

            // Only do shift-tab if the track has a parent.
            if (this.props.realTrack.parent) {
                // Keeping the edited value of the title updated in the store.
                TrackActions.trackTitleEditValueUpdated(this.props.track.id, this.props.data.editorId, e.target.value);

                this.props.uiActions.tabOutTrack();
            }
        } else if (e.key === 'ArrowUp' || e.keyCode === 38) {
            // Handle arrow up.
            e.preventDefault();
            this.updateTitle(e, true);
            this.props.uiActions.moveOnItemsIndex(-1);
        } else if (e.code === 'ArrowDown' || e.keyCode === 40) {
            // Handle arrow down.
            e.preventDefault();
            this.updateTitle(e, true);
            this.props.uiActions.moveOnItemsIndex(+1);
        }
    }

    onTitleLinkClick(track, e) {
        const openInNewTab = e.metaKey || e.ctrlKey;
        // if left click
        if (e.button === 0 && !this.props.disableGoToTrack) {
            this.props.goToTrack(track, e, openInNewTab);
        }
    }

    // Because chrome doesnt consider a click on mouse wheel a click (????) we have to make a special method for on mouse up.
    onTitleLinkMouseUp(track, e) {
        // if this is middle mouse button click
        if (e.button === 1 && !this.props.disableGoToTrack) {
            this.props.goToTrack(track, e, true);
        }
    }

    onTitleHover(event) {
        // in edit, no need for tooltip
        if (this.props.editTitleMode) {
            return;
        }

        // Update the currently viewed initiative when hovering track title.
        this.props.uiActions.setSelectedInitiativeOnHover(this.props.track);

        const titleElement = event.target;
        const tooltipElement = titleElement.nextSibling;

        // if element has ellipsis (...)
        if (titleElement.offsetWidth < titleElement.scrollWidth) {
            // show tooltip
            tooltipElement.classList.remove('hidden');
        } else {
            // if title is not too long, make sure the tooltip is hidden
            if (!tooltipElement?.classList?.contains('hidden')) {
                tooltipElement?.classList?.add('hidden');
            }
        }
    }

    moveCaretToEnd(e) {
        Utils.moveCaretToEnd(e);
        this.props.uiActions.onTextFocus(this.props.track, this.props.getIndex());
    }

    /**
     * Toggles the track summary popover.
     * @param containerId - the id of the container in which we want to plant the popover's input.
     * @param isOpen - whether the popover should be open or closed.
     * @param e - the click event.
     */
    toggleTrackSummaryPopover(containerId, isOpen) {
        this.props.uiActions.toggleTrackSummaryPopover(containerId, this.props.realTrack, isOpen);
    }

    /**
     * Toggles the done Items and opens the .
     */
    toggleDoneItems(realTrack) {
        this.props.uiActions.toggleDoneItems(realTrack.id);

        // Also show the related items if they are not shown.
        this.props.uiActions.showRelated(realTrack, false, true);
    }

    // endregion: UI Actions

    // region: Render sub-functions

    /**
     * Renders the parents over a linked item or when filtering (global or on list) and the child is in the filter but the parent is not.
     * Format: ListName > ...(if there is more than 1 parent) > directParent
     */
    renderTitleParents(track, realTrack) {
        if (!this.props.data.parentItem && realTrack.parentInitiatives && realTrack.parentInitiatives.length) {
            // Classes.
            let parentsContainerClassName = 'common-size-xxxxs common-color-grey text-left track-item-text-parents';
            parentsContainerClassName += ' padding-left-xs hidden-sub';

            // Element.
            let directParent;
            let directParentTitle;
            if (realTrack.parentInitiatives.length) {
                directParent = realTrack.parentInitiatives[realTrack.parentInitiatives.length - 1];
                // The parent might not be in the cache yet. If not, use the title from the given parent object.
                directParentTitle = this.props.trackHelper.getInitiativeFromCache(directParent.id)
                    ? this.props.trackHelper.getInitiativeFromCache(directParent.id).title
                    : directParent.title;
            }

            return (
                <div className={parentsContainerClassName}>
                    <span className="flex-vmiddle">
                        <a
                            className="common-color-grey margin-right-xs inline-block track-title-parent common-ellipsis"
                            onClick={this.props.uiActions.goToGroup.bind(this, realTrack.group.id)}
                        >
                            {realTrack.group.name}
                        </a>
                        <i className="fa fa-angle-right margin-right-xs common-color-light-grey" />
                        {realTrack.parentInitiatives.length > 1 && (
                            <span className="inline-block">
                                <span className="common-color-grey margin-right-xs cursor-default">...</span>
                                <i className="fa fa-angle-right margin-right-xs common-color-light-grey" />
                            </span>
                        )}
                        {directParent && (
                            <span className="flex-vmiddle">
                                <a
                                    className="common-color-grey margin-right-xs inline-block track-title-parent"
                                    onClick={
                                        this.props.disableGoToTrack
                                            ? () => {}
                                            : this.props.goToTrack.bind(this, directParent)
                                    }
                                >
                                    <TextEllipsis numberOfLines={1} tooltip>
                                        {directParentTitle}
                                    </TextEllipsis>
                                </a>
                                <i className="fa fa-angle-right margin-right-xs common-color-light-grey" />
                            </span>
                        )}
                    </span>
                </div>
            );
        } else {
            return null;
        }
    }

    renderTitle(track, realTrack) {
        // The title itself. TODO: handle the hideAddNewForce mode of the tracksEditorCtrl and add an uneditable title.
        // We render 2 titles - one for desktop (editable) and one for mobile and linked items (not editable).
        let titleEditable = null;
        let titleEditableInnerClassName = 'padding-left-xs block track-item-text track-title-link';
        titleEditableInnerClassName += this.props.disableGoToTrack ? ' cursor-default' : '';
        titleEditableInnerClassName += this.props.editTitleMode
            ? ' visibility-hidden track-item-text-input-hidden-back-text'
            : '';

        if (track.status === 'DONE') {
            titleEditableInnerClassName += ' mod-done';
        }

        /**
         * In order to *not* display the dummy item title, we want to show an empty string.
         *
         * @see: {@link https://tonkean.atlassian.net/browse/TNKN-6813}
         */
        const title = realTrack.title === DUMMY_TRACK_TITLE ? '' : realTrack.title;
        // If we're in edit mode and we have an edited value, we should display this value.
        // Maybe the user tabbed in or out during updated - we don't want to override what he's done
        // if he did that.
        const trackCurrentTitle =
            this.props.editTitleMode && this.props.titleEditedValue ? this.props.titleEditedValue : title;

        // We add the trackCurrentTitle existence boolean to the key of the input element below to make sure
        // different input elements are created for when we have a title and we don't have a title.
        // Otherwise, react doesn't re render the value of the input field.
        titleEditable = (
            <span
                className="flex-vmiddle common-hover-visible-container"
                key={`item-text-${track.id}${this.props.data.editorId}`}
                onKeyPress={(e) => {
                    if (this.props.collectItemsMode && this.props.editTitleMode) {
                        this.startTitleEditMode();
                    }
                }}
            >
                {!this.props.editTitleMode && (!this.props.collectItemsMode || this.props.data.viewOnlyMode) && (
                    <div
                        className="tnk-tooltip mod-top margin-right-xs hidden-sm"
                        data-automation="track-title-container-item"
                        onMouseEnter={this.onTitleHover.bind(this)}
                    >
                        <a
                            id={`item-text-${track.id}${this.props.data.editorId}`}
                            name={`item-text-${track.id}${this.props.data.editorId}`}
                            onClick={this.onTitleLinkClick.bind(this, track)}
                            onMouseUp={this.onTitleLinkMouseUp.bind(this, track)}
                            className={titleEditableInnerClassName}
                            data-automation={`track-title-container-title-name-${title}`}
                        >
                            {title}
                        </a>
                        {/* always hides the tooltip, and on hover decides if to remove the 'hidden' class or not
                            MUST BE NEXT SIBILING OF TITLE because the onMouseEnter event activates on the <a> for some reason */}
                        {!this.props.editTitleMode && (
                            <span className="hidden tnk-tooltip-text mod-auto-width">{title}</span>
                        )}
                    </div>
                )}
                {(this.props.editTitleMode || this.props.collectItemsMode) && !this.props.data.viewOnlyMode && (
                    <input
                        data-automation="track-title-container-title-input"
                        type="text"
                        defaultValue={trackCurrentTitle}
                        className={`${
                            this.props.collectItemsMode ? 'track-item-text-input-new-forms' : ''
                        } form-control track-item-text-input-new common-width-full common-no-outline`}
                        key={`item-text-${track.id}${this.props.data.editorId}${!!trackCurrentTitle}`}
                        autoFocus={!this.props.collectItemsMode}
                        onFocus={this.moveCaretToEnd.bind(this)}
                        onBlur={this.updateTitle.bind(this)}
                        onKeyDown={this.onTitleKeyDown.bind(this)}
                        placeholder={`${this.props.titleFormLabel}...`}
                    />
                )}
                {!this.props.editTitleMode && !this.props.data.viewOnlyMode && !this.props.collectItemsMode && (
                    <i
                        data-automation="track-title-container-edit-title"
                        className={`${
                            this.props.collectItemsMode ? '' : 'flex-grow '
                        }svg-icon-xs svg-icon-align-text-base title-edit-pencil padding-right-xs pointer common-hover-visible hidden-xs`}
                        onClick={this.startTitleEditMode.bind(this)}
                    >
                        <span className="tnk-icon">
                            <PencilOIcon />
                        </span>
                    </i>
                )}
            </span>
        );

        // Classes.
        let titleNotEditableClassName = `${
            this.props.collectItemsMode ? '' : 'flex-grow '
        }flex-no-shrink track-item-text mod-no-edit track-title-link`;
        titleNotEditableClassName += this.props.disableGoToTrack ? ' common-disabled' : ' pointer';
        titleNotEditableClassName += 'visible-xs visible-sm';
        if (realTrack.status === 'DONE') {
            titleNotEditableClassName += ' mod-done';
        }

        const titleNotEditable = (
            <a
                className={titleNotEditableClassName}
                key={`item-static-text-${track.id}`}
                onClick={this.onTitleLinkClick.bind(this, track)}
                onMouseUp={this.onTitleLinkMouseUp.bind(this, track)}
            >
                {realTrack.title === DUMMY_TRACK_TITLE ? '' : realTrack.title || track.title}
            </a>
        );

        return [titleEditable, titleNotEditable];
    }

    shouldRenderDescription(realTrack) {
        return (
            !this.props.editTitleMode &&
            ((realTrack.description && realTrack.description.length) || realTrack.externalId)
        );
    }

    // endregion: Render sub-functions

    render() {
        const track = this.props.track;
        const realTrack = this.props.realTrack;
        const editorId = this.props.data.editorId;

        const parents = this.renderTitleParents(track, realTrack);
        const title = this.renderTitle(track, realTrack);

        let summaryIcon = null;

        if (this.shouldRenderDescription(realTrack)) {
            const summaryContainerId = Utils.getReactAngularContainerId(
                'track-summary',
                track.id,
                this.props.data.editorId,
            );
            summaryIcon = (
                <div
                    id={summaryContainerId}
                    style={{ marginLeft: '5px' }}
                    className="flex-vmiddle relative common-height-full padding-right-lg fix-popover-position"
                    onMouseEnter={this.toggleTrackSummaryPopover.bind(this, summaryContainerId, true)}
                    onMouseLeave={this.toggleTrackSummaryPopover.bind(this, summaryContainerId, false)}
                >
                    <i className="common-color-grey common-size-xxxxs margin-xs-left-xs pointer fa fa-align-left" />
                </div>
            );
        }

        const clickZoneFiller = (
            <div
                className={`${
                    this.props.collectItemsMode ? '' : 'flex-grow '
                }track-item-func pointer common-width-auto item-text-filler track-item-allow-context`}
                onClick={this.props.expandOrGoToTrack.bind(this, track)}
            >
                &nbsp;
            </div>
        );

        let textColClassName = `${
            this.props.collectItemsMode ? 'limit-col-text-width margin-right-2' : 'flex-grow'
        } tracks-col-text flex-vmiddle common-height-full track-item-allow-context`;
        if (!realTrack.isArchived) {
            textColClassName += ' track-item-tabbable';
        }

        const statusTextHint = (
            <span style={{ display: realTrack.status === 'FUTURE' ? 'none' : 'block', color: realTrack.stateColor }}>
                ● {realTrack.stateText}
            </span>
        );

        const titleContainerClassName = 'relative min-width-50';
        // saving the itemId in the data, so the tabbeble up and down can use it
        return (
            <Swipeable
                className={textColClassName}
                data-item-id={track.id}
                id={`tracks-col-item-text-${track.id}${editorId}`}
                onSwipedRight={() => this.props.toggleTrackSwipeInMobile(true)}
                onSwipedLeft={() => this.props.toggleTrackSwipeInMobile(false)}
            >
                <div className={titleContainerClassName}>
                    {parents}
                    {title}
                </div>
                {this.props.enableInnerItemsToggle && (
                    <TrackInnerItemsCounts
                        realTrack={realTrack}
                        hideCount={this.props.editTitleMode}
                        onClick={this.props.expandTrackToggle.bind(this)}
                        loadingRelatedInitiatives={this.props.loadingRelatedInitiatives}
                    />
                )}
                {summaryIcon}
                {clickZoneFiller}
                <div
                    className="tracks-title-box visible-on-hover"
                    onClick={this.props.disableGoToTrack ? null : this.props.goToTrack.bind(this, track)}
                >
                    {realTrack.title === DUMMY_TRACK_TITLE ? '' : realTrack.title || track.title}
                    {statusTextHint}
                </div>
            </Swipeable>
        );
    }
}
