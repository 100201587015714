import { useAngularService } from 'angulareact';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import getErrorMessagesFromWorkerRunLogic from '../../../entities/getErrorMessagesFromWorkerRunLogic';
import HistoryContext from '../../../entities/HistoryContext';
import type { TabComponentProps } from '../../../entities/WorkerRunLogicInspectTabData';
import workerRunLogicInspectTabToComponent from '../../../entities/WorkerRunLogicInspectTabToComponent';
import ActionInspectSidePane from '../../ActionInspectSidepan/ActionInspectSidePane';
import HistoryFieldsTableLoader from '../../HistoryFieldsTable/HistoryFieldsTableLoader';
import type LogicType from '../LogicType';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    Chevron,
    ChevronDirection,
    ErrorMessage,
    ModalBody,
    ModalHeader,
    PersistentSidePane,
    Tab,
    Tabs,
    Tooltip,
    XCloseButton,
} from '@tonkean/infrastructure';
import { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { colorSvg } from '@tonkean/utils';

const SIDEPANE_WIDTH = 368;
const StyledModalHeader = styled(ModalHeader)`
    display: flex;
    justify-content: space-between;
    padding: 14px 10px 14px 20px;
    height: 70px;
    flex-shrink: 0;
`;

const ModalCloseButton = styled(XCloseButton)`
    position: absolute;
    right: 6px;
    top: 6px;
`;

const ActionInspectSidePaneStyled = styled(ActionInspectSidePane)`
    z-index: 2;
`;
const DataInspect = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding: 14px 20px 20px;
`;

const StyledModalBody = styled(ModalBody)`
    height: 100%;
    display: flex;
    padding: 0;
`;

const ModalSubHeader = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.current.palette.modal.secondaryText};
    line-height: normal;
    margin-bottom: 6px;
`;

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 34px;
`;

const ModalMainHeader = styled.div`
    font-size: ${FontSize.LARGE_16};
    line-height: normal;
`;

const ModalMainHeaderType = styled.span`
    color: ${Theme.current.palette.modal.secondaryText};
`;

const InitiativeIntegrationIcon = styled.div`
    width: 20px;
    height: 20px;
    background-size: 20px !important;
`;

const CustomHeaderIcon = styled.div`
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    background-size: unset;
    flex-shrink: 0;
    margin-right: 8px;
    color: ${Theme.colors.gray_500};

    svg {
        width: 20px;
        height: 20px;
        ${colorSvg(Theme.colors.gray_500)}
    }
`;

const TabsWithMargin = styled(Tabs)`
    margin-bottom: 20px;
`;

const UppercasedTab = styled(Tab)`
    text-transform: capitalize;
`;

const SidePaneToggle = styled(Clickable)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 18px;
    right: 4px;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 50%;
    background: ${Theme.colors.basicBackground};
`;

interface Props {
    logic: any;
    logicInfo: any;
    workerRun: WorkerRun;
    logicType: LogicType;
    displayName: string;
    icon?: React.ReactNode;
}

const SingleWorkerRunActionInspect: React.FC<Props> = ({
    logic,
    logicInfo,
    workerRun,
    logicType,
    displayName,
    icon,
}) => {
    const { state, updateState } = useContext(HistoryContext);

    const consts = useAngularService('consts');
    const projectManager = useAngularService('projectManager');

    const logicBlockType = logic.node.customTriggerType && consts.getLogicBlockTypes()[logic.node.customTriggerType];

    const { data, loading } = useTonkeanService(
        'getWorkerRunLogicInspectTabs',
        projectManager.project.id,
        workerRun.id,
        logicInfo.id,
        workerRun.startTime,
    );

    const setSelectedTab = (tab: WorkerRunLogicInspectTabType) => {
        updateState({ tab });
    };

    useEffect(() => {
        if (data?.inspectTabs && !state.tab) {
            updateState({ tab: data.inspectTabs[0]?.type || WorkerRunLogicInspectTabType.FIELDS });
        }
    }, [data?.inspectTabs, state.tab, updateState]);

    const errors = useMemo(() => {
        return getErrorMessagesFromWorkerRunLogic(logicInfo).sort();
    }, [logicInfo]);

    const showActionInspectSidePaneFeatureFlag = useFeatureFlag(
        'tonkean_feature_history_worker_run_action_side_pane',
    ) as boolean;
    const showSidePane: boolean = showActionInspectSidePaneFeatureFlag && !!workerRun.workflowVersionId;
    const isDraft = logic?.node?.isDraft;
    const [isSidePaneOpen, setIsSidePaneOpen] = useState(!isDraft);

    return (
        <>
            <StyledModalHeader $border={false} $shadow>
                <HeaderContent>
                    <ModalSubHeader data-automation="single-worker-run-action-inspect-item-name">
                        {workerRun?.initiative?.title}
                    </ModalSubHeader>

                    <div className="flex mod-align-center">
                        {icon ? (
                            <CustomHeaderIcon>{icon}</CustomHeaderIcon>
                        ) : (
                            <div className="margin-right">
                                {logic.node.integrationType ? (
                                    <InitiativeIntegrationIcon
                                        className={`initiative-integration-icon margin mod-circle mod-${logic.node.integrationType.toLowerCase()}`}
                                    />
                                ) : (
                                    <div className={`logic-template-icon mod-xs ${logicBlockType?.iconClass}`} />
                                )}
                            </div>
                        )}

                        <ModalMainHeader>
                            <ModalMainHeaderType>{`${logicBlockType?.title} ${logicType}: `}</ModalMainHeaderType>
                            <span data-automation={`single-worker-run-action-inspect-${logicType.toLowerCase()}-name`}>
                                {displayName}
                            </span>
                        </ModalMainHeader>
                    </div>
                </HeaderContent>
                <ModalCloseButton size={ButtonSize.BIG} />
            </StyledModalHeader>

            <StyledModalBody>
                <PersistentSidePane
                    open={showSidePane && isSidePaneOpen}
                    onClose={() => setIsSidePaneOpen(false)}
                    width={SIDEPANE_WIDTH}
                    clickOutsideCloses={false}
                    escapeCloses={false}
                    peek={showSidePane ? 10 : undefined}
                    focusTrap={false}
                    sidePaneContent={
                        <ActionInspectSidePaneStyled
                            workflowVersionId={workerRun.workflowVersionId || ''}
                            configuredLogic={logic}
                            workerRun={workerRun}
                            logicType={logicType}
                            width={SIDEPANE_WIDTH}
                        />
                    }
                    skipInitialAnimation
                >
                    <DataInspect>
                        {data && state.tab && !loading && (
                            <TabsWithMargin value={state.tab} onChange={(index) => setSelectedTab(index)}>
                                {data.inspectTabs.map((tab) => (
                                    <UppercasedTab
                                        dataAutomation="single-worker-run-action-inspect-tab"
                                        key={tab.type}
                                        label={tab.type}
                                    >
                                        {workerRunLogicInspectTabToComponent[tab.type].tabName}
                                    </UppercasedTab>
                                ))}
                            </TabsWithMargin>
                        )}

                        {errors.map((error) => (
                            <ErrorMessage key={error} className="margin-bottom-20 flex-no-shrink">
                                {error}
                            </ErrorMessage>
                        ))}

                        <div className="overflow-hidden flex-grow">
                            {loading && <HistoryFieldsTableLoader />}
                            <AnimatedSwitch activeLabel={state.tab}>
                                {data?.inspectTabs.map((inspectTab) => {
                                    const Component = workerRunLogicInspectTabToComponent[inspectTab.type]
                                        .component as React.ComponentType<TabComponentProps<any>>;

                                    return (
                                        <AnimatedSwitchItem
                                            label={inspectTab.type}
                                            className="common-height-full"
                                            key={inspectTab.type}
                                        >
                                            <Component
                                                logic={logic}
                                                workerRun={workerRun}
                                                workerRunLogic={logicInfo}
                                                logicType={logicType}
                                                {...inspectTab}
                                            />
                                        </AnimatedSwitchItem>
                                    );
                                })}
                            </AnimatedSwitch>
                        </div>

                        {showSidePane && (
                            <Tooltip
                                content="Action inspect currently not available for Runs done in Build mode"
                                placement="left"
                                disabled={!isDraft}
                            >
                                <SidePaneToggle onClick={() => setIsSidePaneOpen(!isSidePaneOpen)} disabled={isDraft}>
                                    <Chevron
                                        direction={isSidePaneOpen ? ChevronDirection.RIGHT : ChevronDirection.LEFT}
                                    />
                                </SidePaneToggle>
                            </Tooltip>
                        )}
                    </DataInspect>
                </PersistentSidePane>
            </StyledModalBody>
        </>
    );
};

export default SingleWorkerRunActionInspect;
