import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class FormatFullNameFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.FORMAT_FULL_NAME;
    override readonly sign = 'FormatFullName';
    override readonly displayName = 'Format Full Name';
    override readonly description = 'Will format given string to a format of a full name.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Input',
            dataType: FieldType.String,
        },
    ];
}
