import React, { useMemo } from 'react';
import styled from 'styled-components';

import DataSourceConnectionListItem from './DataSourceConnectionListItem';
import { ReactComponent as ActionsEmptyStateIcon } from '../../../../../images/icons/actions-empty-state.svg';
import getIntegrationByType from '../../../../infrastructure/utils/getIntegrationByType';

import { Placeholder, Separator, StateMessage } from '@tonkean/infrastructure';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const DataSourceConnectionsListWrapper = styled.div<{ fullHeight: boolean }>`
    display: flex;
    flex-direction: column;
    width: 832px;
    max-height: ${(props) => (props.fullHeight ? 'none' : '300px')};
    overflow: auto;
    overflow-x: hidden;
`;

const DataSourceConnectionListHeader = styled.div`
    display: grid;
    grid-template-columns: 280px auto 139px;
    align-items: center;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 10px;
`;

const StyledListItemPlaceholder = styled.div`
    margin-top: 10px;
`;

const EmptyStateIcon = styled(ActionsEmptyStateIcon)`
    width: 100px;
    height: 100px;
`;

const StyledNoResults = styled.div`
    margin-top: 10px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
`;

const StyledListItemColumnPlaceholder = styled(Placeholder)`
    flex-direction: row;
    display: inline-flex;
    margin-right: 20px;
`;

interface Props {
    dataSourceConnections: DataSourceConnection[];
    searchValue?: string;
    loading?: boolean;
    fullHeight?: boolean;
}

const DataSourceConnectionsList: React.FC<Props> = ({
    dataSourceConnections,
    searchValue = '',
    loading = false,
    fullHeight = false,
}) => {
    const filteredSearchDataSourceConnections = useMemo(() => {
        if (searchValue) {
            return dataSourceConnections
                .filter((connection) => {
                    return !!getIntegrationByType(connection.dataSourceType)
                        ?.displayName.toLowerCase()
                        .includes(searchValue?.toLowerCase());
                })
                .sort((integrationA, integrationB) => integrationA.created - integrationB.created);
        } else {
            return dataSourceConnections;
        }
    }, [dataSourceConnections, searchValue]);

    return (
        <DataSourceConnectionsListWrapper fullHeight={fullHeight}>
            {/* Headers */}
            {!loading && filteredSearchDataSourceConnections.length > 0 && (
                <>
                    <DataSourceConnectionListHeader>
                        <div>Data Source Type</div>
                        <div>Who Can Create Connection</div>
                        <div>Rule Activation</div>
                    </DataSourceConnectionListHeader>
                    <Separator color={Theme.colors.gray_400} />
                </>
            )}

            {/* Loading and List */}
            {loading
                ? [...Array.from({ length: 3 }).keys()].map((_, index) => (
                      <StyledListItemPlaceholder key={index}>
                          <StyledListItemColumnPlaceholder $height="30px" $width="260px" />
                          <StyledListItemColumnPlaceholder $height="30px" $width="260px" />
                      </StyledListItemPlaceholder>
                  ))
                : filteredSearchDataSourceConnections.map((dataSourceConnection) => (
                      <DataSourceConnectionListItem
                          key={dataSourceConnection.id}
                          dataSourceConnection={dataSourceConnection}
                      />
                  ))}

            {/* No Results */}
            {!loading && filteredSearchDataSourceConnections.length === 0 && (
                <StyledNoResults>
                    {dataSourceConnections.length > 0 && searchValue ? (
                        'No data source rules to show. Consider changing the search filter.'
                    ) : (
                        <StateMessage
                            icon={
                                <span className="tnk-icon">
                                    <EmptyStateIcon />
                                </span>
                            }
                            title="No data source rules were created yet"
                        >
                            Click on the ‘Add Rule’ to create a new data source rule.
                        </StateMessage>
                    )}
                </StyledNoResults>
            )}
        </DataSourceConnectionsListWrapper>
    );
};

export default DataSourceConnectionsList;
