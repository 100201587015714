import gongImage from '../../../../apps/tracks/images/gong.png';
import gongCircleImage from '../../../../apps/tracks/images/integrations/gong-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class GongIntegration extends IntegrationDefinitionBase {
    override name = 'gong';
    override displayName = 'Gong';
    override description = 'We will only collect Calls and Users.';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.OAUTH;

    override entities = [
        new IntegrationEntity('Call', 'Calls'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Participant', 'Participants'),
        new IntegrationEntity('Comment', 'Comments'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(gongCircleImage, '130px'),
        new IntegrationIcon(gongImage, '130px'),
    );

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const redirectUri = environment.integrationKeysMap.gongUri;
        const clientId = environment.integrationKeysMap.gong;
        const permissionsScope =
            'api:calls:read:basic api:calls:read:extensive api:calls:read:transcript api:users:read';
        const state = oAuthHandler.publicGetState();
        const url = `https://app.gong.io/oauth2/authorize?response_type=code&client_id=${clientId}&scope=${encodeURIComponent(
            permissionsScope,
        )}&redirect_uri=${redirectUri}&state=${state}`;
        const code = await oAuthHandler.startOAuth(url, state);
        return { code, redirectUri };
    }
}

export default GongIntegration;
