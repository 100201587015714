import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { TnkSelect } from '@tonkean/infrastructure';

const NetDocumentsSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    createProjectApis,
    onChangeOrInitIntegration,
    projectIntegration,
}) => {
    const projectManager = useAngularService('projectManager');
    const [cabinetsList, setCabinetsList] = useState([]);
    const [error, setError] = useState<string | undefined>();
    const [query, setQuery] = useState<string>('');
    const [selectedCabinet, setSelectedCabinet] = useState<{ value: string; label: string }>();
    const [isLoadingCabinets, setIsLoadingCabinets] = useState(false);

    // On every change of the project integration, we initialize current cabinet from project data.
    useEffect(() => {
        const projectData = projectIntegration?.projectData?.projectDatas[0];
        if (projectData) {
            setSelectedCabinet(projectData.cabinet);
        }
    }, [projectIntegration]);

    // Reset the cabinets list
    useEffect(() => {
        // In case the user re-authenticated
        if (integration?.id !== projectIntegration?.integration?.id) {
            setSelectedCabinet(undefined);
        }
    }, [integration, projectIntegration]);

    // Get Cabinets list.
    useEffect(() => {
        const getCabinets = async () => {
            setIsLoadingCabinets(true);
            try {
                const cabinets = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'cabinets',
                    { query },
                );
                const autoCompleteOptions = constructAutoCompleteOptions(cabinets.options);
                setCabinetsList(autoCompleteOptions);
            } catch {
                setError('Error occurred while getting cabinets list.');
            } finally {
                setIsLoadingCabinets(false);
            }
        };
        getCabinets();
    }, [createProjectApis, integration, projectManager.project.id, query]);

    const onListSelected = useCallback((cabinet) => {
        setSelectedCabinet(cabinet);
    }, []);

    useEffect(() => {
        const projectIntegrationDisplayName = `NetDocuments (${selectedCabinet?.label})`;
        const projectData = {
            cabinet: selectedCabinet,
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData: projectData,
                projectIntegrationDisplayName,
            },
            !selectedCabinet,
        );
    }, [onChangeOrInitIntegration, selectedCabinet]);

    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: option.displayName,
            repositoryId: option.repositoryId,
        }));
    };

    return (
        <div className="flex flex-col">
            {error && <div className="alert alert-danger margin-bottom">{error}</div>}

            <div className="flex flex-col">
                <div className="flex-vmiddle margin-bottom">
                    <b>Select Cabinet:</b>
                    {isLoadingCabinets && <i className="loading-small margin-left-md" />}
                </div>

                <TnkSelect
                    placeholder="Search Cabinets..."
                    isMulti={false}
                    isClearable={false}
                    options={cabinetsList}
                    onChange={onListSelected}
                    value={selectedCabinet}
                    isCreatable={false}
                    closeMenuOnSelect
                    isSearchable
                />
            </div>
        </div>
    );
};

export default NetDocumentsSetupComponent;
