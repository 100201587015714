import AzureBlobStorageAuthenticationComponent from './AzureBlobStorageAuthenticationComponent';
import AzureBlobStorageSetupComponent from './AzureBlobStorageSetupComponent';
import azureBlobStorageIcon from '../../../../apps/tracks/images/azure_blob_storage.svg';
import azureBlobStorageCircleIcon from '../../../../apps/tracks/images/integrations/azure_blob_storage-circle.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class AzureBlobStorageIntegration extends IntegrationDefinitionBase {
    override name = 'azureblobstorage';
    override displayName = 'Azure Blob Storage';
    override description = 'We will only collect files and folders and containers items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override storageProviderSettings = new StorageProviderSettings(true, true);
    // override externalActivityCreatedDateFieldName = 'createdate';
    override entities = [
        new IntegrationEntity('File', 'Files'),
        new IntegrationEntity('Folder', 'Folders'),
        new IntegrationEntity('Container', 'Containers'),
    ];
    override customizedAuthenticationComponent = AzureBlobStorageAuthenticationComponent;
    override customizedSetupComponent = AzureBlobStorageSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(azureBlobStorageCircleIcon, '130px'),
        new IntegrationIcon(azureBlobStorageIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { File: true, Folder: true, Container: true },
        isOnlyDataRetention: {},
    };
}

export default AzureBlobStorageIntegration;
