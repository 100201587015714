import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import useProject from './useProject';

function useGetUpgradeLink() {
    const environmentService = useAngularService('environment');
    const project = useProject();
    const authenticationService = useAngularService('authenticationService');
    const currentUser = authenticationService.currentUser;

    const url = useMemo<string | undefined>(() => {
        if (currentUser && project && environmentService.stripePurchaseUrl) {
            const original = environmentService.stripePurchaseUrl;
            const newUrl = original.replace('{1}', currentUser.email).replace('{2}', project.id);
            return newUrl;
        }
    }, [currentUser, environmentService.stripePurchaseUrl, project]);

    return url;
}

export default useGetUpgradeLink;
