import { analyticsWrapper } from '@tonkean/analytics';
import { REGULAR_INITIATIVES_PAGE_SIZE, FIELD_CREATE_TYPES, getFieldDefinitionConfigurationModalSteps } from '@tonkean/constants';
import { anyNonEmptyConditionsInQuery } from '@tonkean/tonkean-utils';

function InitiativesCtrl(
    $rootScope,
    $scope,
    $state,
    $anchorScroll,
    $stateParams,
    $location,
    $localStorage,
    $timeout,
    $q,
    $log,
    utils,
    safeDomEvents,
    trackHelper,
    initiativeCache,
    initiativeManager,
    tonkeanService,
    consts,
    builtInListsConsts,
    customFieldsManager,
    modalUtils,
    modal,
    groupManager,
    groupInfoManager,
    groupPermissions,
    projectManager,
    viewingGroupUpdateService,
    authenticationService,
    communicationIntegrationsService,
    communicationIntegrationsConnectService,
    personCache,
    timeUtils,
    licenseManager,
    workflowVersionManager,
    syncConfigCacheManager,
    formManager,
    liveReportHelper,
    workflowFolderManager,
) {
    // let workerTypes = consts.getWorkerTypes();

    $scope.$stateParams = $stateParams;
    $scope.filter = $stateParams.filter;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    // If state params group is given, we take it. Otherwise, if local storage's last group exists in project's groups, we take it.
    // Otherwise, we take the default group from pm.defaultGroupId.
    $scope.groupId = $stateParams.g || evaluateSelectedGroupId($localStorage.lastGroupId);
    $scope.ownerId = $stateParams.o;
    $scope.pm = $rootScope.pm;
    $scope.as = $rootScope.as;
    $scope.gm = groupInfoManager;
    $scope.gp = groupPermissions;
    $scope.cis = communicationIntegrationsService;
    $scope.cisc = communicationIntegrationsConnectService;
    $scope.wvm = workflowVersionManager;
    $scope.anyNonEmptyConditionsInQuery = anyNonEmptyConditionsInQuery;
    $scope.timeUtils = timeUtils;
    $scope.licenseManager = licenseManager;

    $scope.filterByGroup = $scope.filter === 'all';
    $scope.pageKey = $scope.filter + ($scope.filterByGroup ? $scope.groupId : '');
    $scope.itemMap = initiativeCache.getInitiativesCache();
    $scope.cfm = customFieldsManager;
    $scope.modalUtils = modalUtils;
    $scope.modal = modal;
    $scope.utils = utils;
    $scope.usecasesTitles = consts.getUsecasesTitles();
    $scope.botStates = consts.getBotStates();
    $scope.builtInLists = builtInListsConsts.builtInLists;

    $scope.initiativesFilterControl = {};
    $scope.initiativesFilterPopoverState = {
        isOpen: false,
        isBootstrap: false,
    };

    // Save the query object sent to get the initiatives from the business report
    $scope.searchInitiativesQueryObject = {};

    $localStorage.lastGroupId = $scope.groupId;

    // Save the track id to open (if exists) BEFORE we change the search param to the group.
    $scope.initialTid = $location.search().tid;

    if (!$stateParams.g && !$stateParams.st && $scope.filter === 'all') {
        $location.search('g', $scope.groupId);
    }

    $scope.dueFilterOptions = [
        { label: 'Today', days: 1 },
        { label: 'Next 7 days', days: 7 },
        {
            label: 'Next 30 days',
            days: 30,
        },
    ];

    if (!$localStorage.view) {
        $localStorage.view = {};
    }
    if (!$localStorage.showWeekViewMap) {
        $localStorage.showWeekViewMap = {};
    }

    $scope.data = {
        allInitiatives: [],
        initiatives: [],
        initiativesByGroup: {},
        invisibleGroupIdToInitiativesMap: {},
        invisibleGroupIdToInitiativesMapIsEmpty: true,
        invisibleGroupIdToFieldDefinitionsMap: {},

        pageNumber: 0,
        highestPage: 0,
        scrollablePageSize: consts.getScrollableInitiativesPageSize(),
        regularPageSize: REGULAR_INITIATIVES_PAGE_SIZE,
        fetchAllInitiativesPageSize: consts.getFetchAllInitiativesPageSize(),
        pageSize: null,
        hasMoreInitiatives: true,
        loadingInitiativesPage: false,
        initiativesPagesPromiseCache: {},
        amountOfPagesToPreCache: -1,

        loading: true,
        showList: false,
        isFieldsStillLoadingFromCache: true,
        selectedPeople: [],
        view: $localStorage.view,
        onlyMine: $scope.filter === 'my',
        showWeekView: $localStorage.showWeekViewMap[$scope.groupId] || $localStorage.showWeekView,
        hideKeyMetrics: true,
        initiativesWithDueDateCount: 0,
        stateFilter: $stateParams.st,
        attachData: { results: [] },
        settingsTooltipIsOpen: false,
        createTypes: FIELD_CREATE_TYPES,
        isManageTriggersOpen: false,
        isNavToggleTooltipOpen: false, // Because the nav moves on open\close, sometimes the mouseleave event won't fire and the tooltip will stay open, use this to force close.
        isCurrentUserGroupOwner: false,
        loadingForms: false,
        errorLoadingForms: false,
        groupCreateForms: null,
        isDraft: false,
        loadingRelatedInitiatives: false,

        // Inner list filter
        displayFieldsOverride: null,
        structuredFilterOn: false,
        initiativesFilterOn: false,
        queryingServer: false,
        initiativesFilterDefinitionBarOpened: false,
        initiativesFilterDefinitionBarDropdownOptionState: {},
        initiativesFilterData: {
            filteredOwners: [],
            filteredCreators: [],

            selectedItemsMaps: {
                TAG: {},
                STATUS: {},
                FUNCTION: {},
                OWNER: {},
                CREATOR: {},
            },
            selectedItemsCountMap: {
                TAG: 0,
                STATUS: 0,
                FUNCTION: 0,
                OWNER: 0,
                CREATOR: 0,
            },
        },
        isBotActive: null,
        groupStatesLabelsSet: [],
        commonFiltersMapping: {
            statuses: {
                id: 'statuses',
                fieldName: 'TNK_STAGE',
                fieldLabel: 'Status',
                condition: 'Equals',
                type: 'List',
            },
            owners: {
                id: 'owners',
                fieldName: 'TNK_OWNER_NAME',
                fieldLabel: 'Owner Name',
                condition: 'Equals',
                type: 'String',
                fromOriginalEntity: true,
            },
            creators: {
                id: 'creators',
                fieldName: 'TNK_CREATOR_NAME',
                fieldLabel: 'Creator Name',
                condition: 'Equals',
                type: 'String',
                fromOriginalEntity: true,
            },
            functions: {
                id: 'function',
                fieldName: 'function',
                fieldLabel: 'Function',
                condition: 'Equals',
                type: 'String',
                fromOriginalEntity: false,
            },
            tags: {
                id: 'tag',
                fieldName: 'tags',
                fieldLabel: 'Tags',
                condition: 'Equals',
                type: 'String',
                fromOriginalEntity: false,
            },
        },

        orderBy: { field: '', orderType: '', fieldType: '' },
        loadingOrderBy: false,

        removingGroupSavedFilter: false,
        errorRemovingGroupSavedFilter: false,

        showModuleStudioButton: true,
        environment: $stateParams.env || 'PUBLISHED',

        defaultItemInterfaceId: undefined,
        defaultInterfaceByWorkflowVersion: {},
    };

    /**
     * Initialization function for the controller.
     */
    $scope.init = async function () {
        $anchorScroll();

        // First, we clear the track helper cache from any items it might have.
        trackHelper.clearCaches();

        if (!$scope.groupId || !$scope.filterByGroup || $scope.data.stateFilter) {
            $scope.data.pageSize = REGULAR_INITIATIVES_PAGE_SIZE;
        } else {
            $scope.data.pageSize = consts.getScrollableInitiativesPageSize();
        }

        // Validate user permissions
        if (isUserNotAllowedToViewGroup()) {
            $localStorage.lastGroupId = null;
            $scope.initialTid = null;

            // If the user has no permissions to the group and we show the custom interface,
            // we redirect them to the no access page.
            return $state.go('noaccesspage');
        }

        // Setting the activity manager to only cache items from current group.
        projectManager.currentlyViewedGroupId = $scope.groupId;

        let dayIndex;
        if (
            $scope.as &&
            $scope.as.currentUser &&
            $scope.as.currentUser.metadata &&
            $scope.as.currentUser.metadata.weekStartDay
        ) {
            dayIndex = $scope.as.currentUser.metadata.weekStartDay.index;
        }

        $scope.data.startDate = utils.getLatestWeekDay(null, dayIndex);
        $scope.data.lastMonthDayLoaded = new Date($scope.data.startDate);

        if ($scope.data.stateFilter) {
            $scope.filterVisiblity.showInnerBar = $rootScope.isMobile ? 'status' : null;
        }

        $rootScope.pageTitle = $scope.pm.groupsMap[$scope.groupId]?.name
            ? `${$scope.pm.groupsMap[$scope.groupId].name} (${$scope.filter})`
            : $scope.filter;

        if (
            $scope.pm.groupsMap[$scope.groupId] &&
            $scope.pm.groupsMap[$scope.groupId].owners &&
            $scope.pm.groupsMap[$scope.groupId].owners.length &&
            authenticationService.currentUser
        ) {
            $scope.data.isCurrentUserGroupOwner = utils.anyMatch(
                $scope.pm.groupsMap[$scope.groupId].owners,
                (owner) => owner.id === authenticationService.currentUser.id,
            );
        }

        $scope.data.showModuleStudioButton = workflowFolderManager.getContainingWorkflowFolder(
            $scope.pm.project.id,
            $scope.groupId,
        )?.isVisible;

        $scope.data.isDraft = $stateParams.env?.toUpperCase() === 'DRAFT';

        const getInitiativesPromise = $scope.pm.getFunctions().finally(function () {
            $scope.data.showloading = true;
            const orderBy = $localStorage.orderBy && $localStorage.orderBy[$scope.groupId];
            if (orderBy) {
                $scope.setOrderBy(orderBy.field, orderBy.fieldType, orderBy.orderType, false, $scope.groupId);
            }

            if ($scope.ownerId) {
                personCache.getEntityById($scope.ownerId).then((owner) => {
                    $scope.data.ownerName = owner.name;
                });
            }

            return (
                groupInfoManager
                    // we force update in order to initialize the caches when we move between solution business reports and live reports
                    .getGroup($scope.groupId, true)
                    .then(async () => {
                        $scope.workflowVersionId = getWorkflowVersionIdFromUrl();
                        let loadInitiativePromise = $q.resolve();
                        // Load initiatives, and then pre cache pages.
                        // This must happen after the workflowVersionId has been set as its dependant on it
                        const listFilter =
                            $stateParams.listFilter ||
                            ($scope.pm.groupsMap[$scope.groupId] &&
                                $scope.pm.groupsMap[$scope.groupId].metadata &&
                                $scope.pm.groupsMap[$scope.groupId].metadata.defaultSavedFilterUrl);
                        if (listFilter) {
                            $scope.data.listFilterUrlParam = listFilter;
                            initializeListFiltersFromUrl(listFilter);
                            loadSavedFilter($scope.data.listFilterUrlParam);
                        } else {
                            loadInitiativePromise = $scope
                                .loadInitiatives()
                                .then((initiativesData) =>
                                    preCachePages(initiativesData, 1, $scope.data.amountOfPagesToPreCache),
                                );
                        }

                        // Init states.
                        initializeGroupStatesLabelsSet();

                        if ($scope.workflowVersionId) {
                            // Load fields
                            const hasKeyMetrics =
                                $scope.workflowVersionId &&
                                customFieldsManager.selectedGlobalFieldsMap[$scope.workflowVersionId] &&
                                customFieldsManager.selectedGlobalFieldsMap[$scope.workflowVersionId].length > 0;
                            $scope.data.hideKeyMetrics =
                                $localStorage.hideKeyMetrics &&
                                typeof $localStorage.hideKeyMetrics[$scope.groupId] !== 'undefined'
                                    ? $localStorage.hideKeyMetrics[$scope.groupId]
                                    : !hasKeyMetrics;

                            $scope.data.isFieldsStillLoadingFromCache = false;

                            // Init all forms in order to fill the dropdown list
                            const loadFormsPromise = loadForms();

                            return Promise.all([loadInitiativePromise, loadFormsPromise]);
                        }
                    })
                    .then(() => $scope.loadThisWeek())
            );
        });

        $scope.pm.getActivityDigestSubscribedGroups();

        // If filter is my tracks, we'd like to listen over owner set events,
        // so that we can update the initiatives being displayed, knowing that 'my' filter
        // is based on the owner field.
        if ($scope.filter === 'my') {
            $scope.$on('ownerSet', function () {
                $scope.loadInitiatives(false, true);
            });
        }

        $scope.openBotOffWhenOwnersPopover = false;

        if ($scope.groupId) {
            // Starting interval actions for updating user viewing group.
            viewingGroupUpdateService.registerIntervalViewingGroupUpdate(
                $scope.groupId,
                false,
                null,
                consts.getUpdateViewingGroupInterval(),
                () => {
                    $scope.viewingUsers = $scope.prepareAvatars();
                },
            );
        }

        // init common filters mapping
        $scope.data.commonFiltersMapping.owners.values = $scope.data.initiativesFilterData.filteredOwners;
        $scope.data.commonFiltersMapping.creators.values = $scope.data.initiativesFilterData.filteredCreators;
        $scope.data.commonFiltersMapping.functions.values =
            $scope.data.initiativesFilterData.selectedItemsMaps['FUNCTION'];
        $scope.data.commonFiltersMapping.tags.values = $scope.data.initiativesFilterData.selectedItemsMaps['TAG'];
        $scope.data.commonFiltersMapping.statuses.values =
            $scope.data.initiativesFilterData.selectedItemsMaps['STATUS'];

        if ($scope.filter === 'my') {
            await getInitiativesPromise;
            const groups = $scope.data.initiatives.map((initiative) => initiative.group);
            $scope.data.defaultInterfaceByWorkflowVersion = Object.fromEntries(
                await Promise.all(
                    groups.map(async (group) => {
                        const workflowVersionId = getGroupWorkflowVersionByEnv(group);

                        return tonkeanService
                            .getDefaultItemInterfaceId(workflowVersionId)
                            .then(({ defaultItemInterfaceId }) => [workflowVersionId, defaultItemInterfaceId])
                            .catch(() => [workflowVersionId, undefined]);
                    }),
                ),
            );

            // We should show some item
            if ($scope.initialTid) {
                const group = $scope.data.initiatives.filter(
                    (initiative) => initiative.id === $scope.initialTid,
                )?.group;

                if (group) {
                    const workflowVersionId = getGroupWorkflowVersionByEnv(group);

                    if (!$scope.data.defaultInterfaceByWorkflowVersion[workflowVersionId]) {
                        $scope.modalUtils.openViewInitiative($scope.initialTid);
                    }
                }
            }
        } else {
            try {
                $scope.workflowVersionId = getWorkflowVersionIdFromUrl();
                const { defaultItemInterfaceId } = await tonkeanService.getDefaultItemInterfaceId(
                    $scope.workflowVersionId,
                );
                $scope.data.defaultItemInterfaceId = defaultItemInterfaceId;
            } catch {
                $scope.data.defaultItemInterfaceId = undefined;
            }

            if (!$scope.data.defaultItemInterfaceId && $scope.initialTid) {
                $scope.modalUtils.openViewInitiative($scope.initialTid);
            }
        }
    };

    function getGroupWorkflowVersionByEnv(group) {
        return $stateParams.env === 'DRAFT' ? group.draftWorkflowVersionId : group.publishedWorkflowVersionId;
    }

    function isUserNotAllowedToViewGroup() {
        return (
            $scope.groupId &&
            (!groupExistsInProjectGroups($scope.groupId) ||
                projectManager.groupsMap[$scope.groupId].dashboardHidden ||
                !projectManager.allowedBusinessReportIdsSet[$scope.groupId])
        );
    }

    function loadForms() {
        $scope.data.loadingForms = true;
        $scope.data.errorLoadingForms = false;

        return formManager
            .getAllWorkerForm($scope.workflowVersionId)
            .then((results) => {
                $scope.data.groupCreateForms = results.filter((form) => form.formType !== 'UPDATE');
            })
            .catch(() => {
                $scope.data.errorLoadingForms = true;
            })
            .finally(() => {
                $scope.data.loadingForms = false;
            });
    }

    $scope.openModalForm = function (formId) {
        modal.openFillFormModal(
            $scope.groupId,
            formId,
            $scope.workflowVersionId,
            (createdInitiative) => {
                // If the created track is an inner track (Configured to be added under other initiative)
                if (createdInitiative?.parent && createdInitiative?.parent.id) {
                    // Find the root initiative to add the inner item to
                    const rootInitiative = utils.findFirstById($scope.data.initiatives, createdInitiative.parent.id);
                    if (rootInitiative) {
                        rootInitiative.relatedInitiatives.push(createdInitiative);
                    }
                } else {
                    // Otherwise, just add the initiative to the list.
                    $scope.data.initiatives.push(createdInitiative);
                }
            },
            undefined,
            undefined,
            $scope.data.environment,
        );
    };

    /**
     * Initializes the list filters from listFilter url query parameter.
     */
    function initializeListFiltersFromUrl(listFilter) {
        // Parsing the common filters.
        const urlFiltersJsonObject = JSON.parse(decodeURIComponent(atob(decodeURI(listFilter))));

        // Map between list filter url name and filter name in the UI.
        const urlFiltersConversionMapping = {
            statuses: 'STATUS',
            functions: 'FUNCTION',
            tags: 'TAG',
        };

        // Converting the url filters into the actual ui filters.
        for (const urlFilterName in urlFiltersConversionMapping) {
            if (urlFiltersConversionMapping.hasOwnProperty(urlFilterName)) {
                const uiFilterName = urlFiltersConversionMapping[urlFilterName];

                if (urlFiltersJsonObject[urlFilterName] && urlFiltersJsonObject[urlFilterName].length) {
                    // Setting items count
                    $scope.data.initiativesFilterData.selectedItemsCountMap[uiFilterName] =
                        urlFiltersJsonObject[urlFilterName].length;

                    // Setting filtered values
                    for (let i = 0; i < urlFiltersJsonObject[urlFilterName].length; i++) {
                        const filteredItem = urlFiltersJsonObject[urlFilterName][i];
                        $scope.data.initiativesFilterData.selectedItemsMaps[uiFilterName][filteredItem] = true;
                    }
                }
            }
        }

        // Owner initialization
        if (urlFiltersJsonObject.owners && urlFiltersJsonObject.owners.length) {
            // Initializing the filtered owners, if not already set.
            if (!$scope.data.initiativesFilterData.filteredOwners) {
                $scope.data.initiativesFilterData.filteredOwners = [];
            }

            // Setting items count
            $scope.data.initiativesFilterData.selectedItemsCountMap['OWNER'] = urlFiltersJsonObject.owners.length;

            // Setting filtered values
            for (let i = 0; i < urlFiltersJsonObject.owners.length; i++) {
                const filteredOwner = urlFiltersJsonObject.owners[i];

                $scope.data.initiativesFilterData.filteredOwners.push(filteredOwner);
                $scope.data.initiativesFilterData.selectedItemsMaps['OWNER'][filteredOwner.id] = filteredOwner;
            }
        }

        // Creator initialization
        if (urlFiltersJsonObject.creators && urlFiltersJsonObject.creators.length) {
            // Initializing the filtered creators, if not already set.
            if (!$scope.data.initiativesFilterData.filteredCreators) {
                $scope.data.initiativesFilterData.filteredCreators = [];
            }

            // Setting items count
            $scope.data.initiativesFilterData.selectedItemsCountMap['CREATOR'] = urlFiltersJsonObject.creators.length;

            // Setting filtered values
            for (let i = 0; i < urlFiltersJsonObject.creators.length; i++) {
                const filteredCreator = urlFiltersJsonObject.creators[i];

                $scope.data.initiativesFilterData.filteredCreators.push(filteredCreator);
                $scope.data.initiativesFilterData.selectedItemsMaps['CREATOR'][filteredCreator.id] = filteredCreator;
            }
        }

        // Advanced query definition initialization
        if (urlFiltersJsonObject.definition) {
            $scope.data.existingDefinition = urlFiltersJsonObject.definition;

            if ($scope.initiativesFilterControl && $scope.initiativesFilterControl.createDefinitionFromCustomFilters) {
                $scope.initiativesFilterControl.reloadFromExistingDefinition();
            }
        }

        // Changing from active/all to a specific statuses should clear the only active.
        if (
            $scope.data.initiativesFilterData &&
            $scope.data.initiativesFilterData.selectedItemsCountMap &&
            $scope.data.initiativesFilterData.selectedItemsCountMap.STATUS > 0
        ) {
            $scope.data.toggleDone = true;
        }

        $scope.toggleInitiativesFilterDefinitionBarOpened();
        $scope.applyFilter();
    }

    /**
     * Pre caches pages.
     * @param initiativesData The result from the track helper get initiatives function.
     * @param startingPageNumber In which page number to start the pre caching from.
     * @param amountOfPages How many pages do we want to pre cache.
     */
    function preCachePages(initiativesData, startingPageNumber, amountOfPages) {
        if (
            initiativesData &&
            initiativesData.data &&
            initiativesData.data.hasMoreEntities &&
            $scope.data.highestPage <= startingPageNumber + 3
        ) {
            return cachePages(startingPageNumber, $scope.data.pageSize, 0, amountOfPages);
        } else {
            return $q.resolve();
        }
    }

    /**
     * Recursively caches the requested amount of initiatives pages.
     * @param currentPageNumber The current page number we're fetching.
     * @param pageSize The page size.
     * @param fetchedPages How many pages have we fetched so far (used to stop the recursion).
     * @param numberOfPages How many pages would you like to fetch.
     */
    function cachePages(currentPageNumber, pageSize, fetchedPages, numberOfPages) {
        // If we've already fetched the requested number of pages, we return.
        if (fetchedPages > numberOfPages) {
            if (currentPageNumber > $scope.data.highestPage) {
                $scope.data.highestPage = currentPageNumber;
                // console.log('highest ' + $scope.data.highestPage);
            }
            return $q.resolve();
        }

        return cacheInitiativesPage(currentPageNumber, pageSize).then((initiativesData) => {
            if (initiativesData && initiativesData.data && initiativesData.data.hasMoreEntities) {
                return cachePages(currentPageNumber + 1, pageSize, fetchedPages + 1, numberOfPages);
            } else {
                return $q.resolve();
            }
        });
    }

    /**
     * Toggles the toggle done of the list.
     */
    $scope.toggleToggleDone = function (event, value) {
        $scope.data.toggleDone = value;

        // Reloading initiatives as toggling done affects the filters of the initiatives.
        $scope.loadInitiatives(false, true);
    };

    $scope.toggleStar = function () {
        // change for ui
        $scope.pm.groupsMap[$scope.groupId].isStarred = !$scope.pm.groupsMap[$scope.groupId].isStarred;
        // update server
        groupManager.toggleStar($scope.groupId, $scope.pm.groupsMap[$scope.groupId].isStarred);
    };

    /**
     * Generic function for selecting the "All" option of a filtered selection.
     */
    $scope.toggleAllItemsFilter = function (event, filterItemName) {
        event.stopPropagation();
        if (!$scope.data.initiativesFilterData.selectedItemsCountMap[filterItemName]) {
            return;
        }

        $scope.data.initiativesFilterData.selectedItemsMaps[filterItemName] = {};
        $scope.data.initiativesFilterData.selectedItemsCountMap[filterItemName] = 0;

        $scope.applyFilter();
    };

    /**
     * Generic function for toggling the selected filtered item.
     */
    $scope.toggleSelectedFilteredItem = function (event, filterItemName, item) {
        event.stopPropagation();

        // Changing from active/all to a specific statuses should clear the only active.
        $scope.data.toggleDone = true;

        $scope.data.initiativesFilterData.selectedItemsMaps[filterItemName][item] =
            !$scope.data.initiativesFilterData.selectedItemsMaps[filterItemName][item];

        if ($scope.data.initiativesFilterData.selectedItemsMaps[filterItemName][item]) {
            $scope.data.initiativesFilterData.selectedItemsCountMap[filterItemName]++;
        } else {
            $scope.data.initiativesFilterData.selectedItemsCountMap[filterItemName]--;
        }

        $scope.applyFilter();
    };

    /**
     * Toggles the popover of initiative filter.
     * if toggleToOn is given, will just set the popover to be opened.
     * @param toggleToOn
     */
    $scope.toggleInitiativesFilterPopoverOpenState = function (toggleToOn) {
        if (toggleToOn) {
            $scope.initiativesFilterPopoverState.isOpen = true;
            return;
        }

        $scope.toggleInitiativesFilterPopover();
    };

    /**
     * Toggles the initiatives filter popover open state.
     */
    $scope.toggleInitiativesFilterPopover = function () {
        $scope.initiativesFilterPopoverState.isOpen = !$scope.initiativesFilterPopoverState.isOpen;
    };

    /**
     * Closes the initiatives filter popover.
     */
    $scope.closeInitiativesFilterPopover = function () {
        $scope.initiativesFilterPopoverState.isOpen = false;
    };

    /**
     * Toggles the filter bar in the list view.
     */
    $scope.toggleInitiativesFilterDefinitionBarOpened = function () {
        $scope.data.initiativesFilterDefinitionBarOpened = !$scope.data.initiativesFilterDefinitionBarOpened;
    };

    $scope.toggleFilterBarDropdownOption = function (option, visible) {
        if (visible) {
            $scope.data.initiativesFilterDefinitionBarOpened = true;
        }

        $timeout(() => ($scope.data.initiativesFilterDefinitionBarDropdownOptionState[option] = visible));
    };

    $scope.saveFilterToMyFilters = function () {
        if (!$scope.data.saveNewFilterName || !$scope.data.saveNewFilterName.length) {
            return;
        }

        $scope.data.showSaveFilterName = false;
        $timeout(function () {
            if ($scope.data.listFilterUrlParam && authenticationService.currentUser) {
                if (!authenticationService.currentUser.metadata) {
                    authenticationService.currentUser.metadata = {};
                }
                if (!authenticationService.currentUser.metadata.savedFilters) {
                    authenticationService.currentUser.metadata.savedFilters = {};
                }
                if (!authenticationService.currentUser.metadata.savedFilters[$scope.groupId]) {
                    authenticationService.currentUser.metadata.savedFilters[$scope.groupId] = [];
                }

                const savedFilter = {
                    name: $scope.data.saveNewFilterName,
                    url: $scope.data.listFilterUrlParam,
                };

                authenticationService.currentUser.metadata.savedFilters[$scope.groupId].push(savedFilter);

                tonkeanService.updateProfileMetadata(authenticationService.currentUser.metadata);

                $scope.data.currentSavedFilter = savedFilter;
                $scope.data.isGroupListFilter = false;
            }
        });
    };

    $scope.saveFilterToGroupFilters = function () {
        if (!$scope.data.saveNewFilterName || !$scope.data.saveNewFilterName.length) {
            return;
        }

        $scope.data.showSaveFilterName = false;
        $timeout(function () {
            if ($scope.data.listFilterUrlParam && $scope.pm.groupsMap[$scope.groupId]) {
                if (!$scope.pm.groupsMap[$scope.groupId].metadata) {
                    $scope.pm.groupsMap[$scope.groupId].metadata = {};
                }
                if (!$scope.pm.groupsMap[$scope.groupId].metadata.savedFilters) {
                    $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters = [];
                }

                const savedFilter = {
                    name: $scope.data.saveNewFilterName,
                    url: $scope.data.listFilterUrlParam,
                };

                $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters.push(savedFilter);

                $scope.pm.updateGroupMetadata($scope.groupId, $scope.pm.groupsMap[$scope.groupId].metadata);

                $scope.data.currentSavedFilter = savedFilter;
                $scope.data.isGroupListFilter = true;
            }
        });
    };

    $scope.toggleFilterIsDefault = function (filter) {
        if (!$scope.data.isCurrentUserGroupOwner) {
            $scope.$emit('alert', {
                msg: 'Only the list owner can change the default filter setting!',
                type: 'warning',
            });
            return;
        }

        if (!$scope.pm.groupsMap[$scope.groupId].metadata.defaultSavedFilterUrl) {
            $scope.pm.groupsMap[$scope.groupId].metadata.defaultSavedFilterUrl = filter.url;
            $scope.pm.updateGroupMetadata($scope.groupId, $scope.pm.groupsMap[$scope.groupId].metadata);
        } else {
            if ($scope.pm.groupsMap[$scope.groupId].metadata.defaultSavedFilterUrl !== filter.url) {
                $scope.pm.groupsMap[$scope.groupId].metadata.defaultSavedFilterUrl = filter.url;
                $scope.pm.updateGroupMetadata($scope.groupId, $scope.pm.groupsMap[$scope.groupId].metadata);
            } else {
                delete $scope.pm.groupsMap[$scope.groupId].metadata.defaultSavedFilterUrl;
                $scope.pm.updateGroupMetadata($scope.groupId, $scope.pm.groupsMap[$scope.groupId].metadata);
            }
        }
    };

    $scope.removeSavedFilter = function (filter) {
        if (filter) {
            let index;
            if (
                authenticationService.currentUser.metadata.savedFilters &&
                authenticationService.currentUser.metadata.savedFilters[$scope.groupId]
            ) {
                for (
                    let i = 0;
                    i < authenticationService.currentUser.metadata.savedFilters[$scope.groupId].length;
                    i += 1
                ) {
                    const item = authenticationService.currentUser.metadata.savedFilters[$scope.groupId][i];
                    if (item.url === filter.url && item.name === filter.name) {
                        index = i;
                        break;
                    }
                }

                if (index > -1) {
                    authenticationService.currentUser.metadata.savedFilters[$scope.groupId].splice(index, 1);
                    tonkeanService.updateProfileMetadata(authenticationService.currentUser.metadata);
                    return;
                }
            }

            if ($scope.pm.groupsMap[$scope.groupId].metadata.savedFilters) {
                if (!$scope.data.isCurrentUserGroupOwner) {
                    $scope.$emit('alert', { msg: 'Only list owners can delete list filters.', type: 'warning' });
                    return;
                }

                for (let i = 0; i < $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters.length; i++) {
                    const item = $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters[i];
                    if (item.url === filter.url && item.name === filter.name) {
                        index = i;
                        break;
                    }
                }

                if (index > -1) {
                    $scope.data.removingGroupSavedFilter = true;
                    $scope.data.errorRemovingGroupSavedFilter = false;
                    const deletedFilterUrl = $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters[index].url;
                    $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters.splice(index, 1);
                    $scope.pm
                        .updateGroupMetadata($scope.groupId, $scope.pm.groupsMap[$scope.groupId].metadata)
                        .then(() => {
                            if (
                                $scope.pm.groupsMap[$scope.groupId].metadata.defaultSavedFilterUrl === deletedFilterUrl
                            ) {
                                delete $scope.pm.groupsMap[$scope.groupId].metadata.defaultSavedFilterUrl;
                            }
                            $scope.data.currentSavedFilter = null;
                            $scope.data.isGroupListFilter = false;
                        })
                        .catch(() => {
                            $scope.$emit('alert', {
                                msg: 'There was an error trying to delete saved filter. Please make sure you have the sufficient permissions.',
                                type: 'error',
                            });
                            $scope.data.errorRemovingGroupSavedFilter = true;
                        })
                        .finally(() => {
                            $scope.data.removingGroupSavedFilter = false;
                        });
                    return;
                }
            }
        }
    };

    /**
     * Open custom trigger modal with the existing filters to create trigger
     */
    $scope.openTriggerDefinitionModalFromFilters = function () {};

    /**
     * Open create definition modal with the existing filters to create new key metric
     */
    $scope.openKeyMetricDefinitionModalFromFilters = function () {
        const fieldDefinition = {
            type: 'TNK_COLUMN_AGGREGATE',
            targetType: 'GLOBAL',
            definition: {
                aggregationType: 'Count',
                groupId: $scope.groupId,
                aggregateQueryDefinition: {
                    query: generateQuery(),
                },
            },
        };

        // Open create definition modal with query generated from the existing filters
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            'GLOBAL',
            'TNK_COLUMN_AGGREGATE',
            null,
            false,
            true,
            fieldDefinition,
            null,
            null,
            getFieldDefinitionConfigurationModalSteps().fieldDefinitionConfiguration.id,
            false,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.workflowVersionId,
        ]);
    };

    function generateQuery() {
        let generatedQuery = {
            type: 'And',
            filters: [],
        };

        let query;

        // Generate filters from custom filters.
        if ($scope.initiativesFilterControl.customFiltersDefinition) {
            query = $scope.initiativesFilterControl.createDefinitionFromCustomFilters().query;
        }

        // Check whether common filters exists.
        const commonFilters = $scope.data.initiativesFilterCommonFilters;
        let commonFiltersExists = false;
        for (let i = 0; i < utils.objKeys(commonFilters).length; i++) {
            const filter = commonFilters[utils.objKeys(commonFilters)[i]];
            if (filter.length > 0) {
                commonFiltersExists = true;
                break;
            }
        }

        // Generate filters from common filters.
        if (commonFiltersExists) {
            const filters = createFiltersFromCommonFilters(commonFilters);
            generatedQuery.filters = filters;
            if (query) {
                generatedQuery.queries = [query];
            }
        } else {
            if (query) {
                generatedQuery = query;
            }
        }

        return generatedQuery;
    }

    /**
     * From the given selected items map, returns all the selected items in it.
     * @param selectedItemsMap
     * @returns {Array}
     */
    function getSelectedItemsFromMap(selectedItemsMap) {
        if (!selectedItemsMap) {
            return [];
        }

        const selectedItems = [];
        for (const key in selectedItemsMap) {
            if (selectedItemsMap.hasOwnProperty(key) && selectedItemsMap[key]) {
                selectedItems.push(key);
            }
        }

        return selectedItems;
    }

    $scope.initiativesFilterFieldSelection = function (fieldDefinition) {
        $scope.data.initiativesFilterDefinitionBarOpened = true;

        if (fieldDefinition) {
            if (fieldDefinition.id) {
                // Advanced query definition initialization
                $scope.toggleInitiativesFilterPopoverOpenState(true);
                $timeout(function () {
                    if (!$scope.data.existingDefinition) {
                        $scope.data.existingDefinition = {
                            query: {
                                filters: [],
                                type: 'And',
                            },
                        };
                    } else {
                        // get up to date definition with values
                        $scope.data.existingDefinition =
                            $scope.initiativesFilterControl.createDefinitionFromCustomFilters();
                    }

                    $scope.data.existingDefinition.query.filters.push({
                        condition: fieldDefinition.fieldType === 'Date' ? 'Next' : 'Equals',
                        compareTimeframe: 1,
                        fieldLabel: fieldDefinition.name,
                        fieldName: fieldDefinition.id,
                        type: fieldDefinition.fieldType,
                    });

                    $scope.data.structuredFilterOn = true;

                    if (
                        $scope.initiativesFilterControl &&
                        $scope.initiativesFilterControl.createDefinitionFromCustomFilters
                    ) {
                        $scope.initiativesFilterControl.reloadFromExistingDefinition($scope.data.existingDefinition);
                    }

                    $scope.applyFilter();
                });
            } else {
                // special field
                $scope.toggleFilterBarDropdownOption(fieldDefinition, true);
            }
        }
    };

    /**
     * In order to stop a click in the people selector from closing the dropdown, we stop the propagation.
     */
    $scope.initiativesFilterOwnerSelection = function (event) {
        event.stopPropagation();
    };

    /**
     * In order to stop a click in the people selector from closing the dropdown, we stop the propagation.
     */
    $scope.initiativesFilterCreatorSelection = function (event) {
        event.stopPropagation();
    };

    /**
     * Once an owner is selected or unselected for filter, we update the filtered items.
     */
    $scope.onInitiativesFilterOwnerAddedOrRemoved = function () {
        $scope.data.initiativesFilterData.selectedItemsMaps['OWNER'] = {};
        $scope.data.initiativesFilterData.selectedItemsCountMap['OWNER'] = 0;

        if (
            $scope.data.initiativesFilterData.filteredOwners &&
            $scope.data.initiativesFilterData.filteredOwners.length
        ) {
            for (let i = 0; i < $scope.data.initiativesFilterData.filteredOwners.length; i++) {
                const filteredOwner = $scope.data.initiativesFilterData.filteredOwners[i];

                $scope.data.initiativesFilterData.selectedItemsMaps['OWNER'][filteredOwner.id] = filteredOwner;
                $scope.data.initiativesFilterData.selectedItemsCountMap['OWNER']++;
            }
        }

        $scope.applyFilter();
    };

    /**
     * Once an owner is selected or unselected for filter, we update the filtered items.
     */
    $scope.onInitiativesFilterCreatorAddedOrRemoved = function () {
        $scope.data.initiativesFilterData.selectedItemsMaps['CREATOR'] = {};
        $scope.data.initiativesFilterData.selectedItemsCountMap['CREATOR'] = 0;

        if (
            $scope.data.initiativesFilterData.filteredCreators &&
            $scope.data.initiativesFilterData.filteredCreators.length
        ) {
            for (let i = 0; i < $scope.data.initiativesFilterData.filteredCreators.length; i++) {
                const filteredCreator = $scope.data.initiativesFilterData.filteredCreators[i];

                $scope.data.initiativesFilterData.selectedItemsMaps['CREATOR'][filteredCreator.id] = filteredCreator;
                $scope.data.initiativesFilterData.selectedItemsCountMap['CREATOR']++;
            }
        }

        $scope.applyFilter();
    };

    /**
     * Gets the filter on-off flags.
     */
    function getFilterFlags(filterOnTags, filterOnStatuses, filterOnFunctions, filterOnOwners, filterOnCreators) {
        const filterOnTagsFinal = filterOnTags
            ? filterOnTags
            : getSelectedItemsFromMap($scope.data.initiativesFilterData.selectedItemsMaps['TAG']);
        const filterOnStatusesFinal = filterOnStatuses
            ? filterOnStatuses
            : getSelectedItemsFromMap($scope.data.initiativesFilterData.selectedItemsMaps['STATUS']);
        const filterOnFunctionsFinal = filterOnFunctions
            ? filterOnFunctions
            : getSelectedItemsFromMap($scope.data.initiativesFilterData.selectedItemsMaps['FUNCTION']);
        const filterOnOwnersFinal = filterOnOwners
            ? filterOnOwners
            : getSelectedItemsFromMap($scope.data.initiativesFilterData.selectedItemsMaps['OWNER']);
        const filterOnCreatorsFinal = filterOnCreators
            ? filterOnCreators
            : getSelectedItemsFromMap($scope.data.initiativesFilterData.selectedItemsMaps['CREATOR']);

        const emptySearchString = !$scope.data.innerListSearchText || !$scope.data.innerListSearchText.length;
        const structuredFilterEmpty =
            (!$scope.initiativesFilterControl ||
                !$scope.initiativesFilterControl.anyNonEmptyConditions ||
                !$scope.initiativesFilterControl.anyNonEmptyConditions()) &&
            (!filterOnTagsFinal || !filterOnTagsFinal.length) &&
            (!filterOnStatusesFinal || !filterOnStatusesFinal.length) &&
            (!filterOnFunctionsFinal || !filterOnFunctionsFinal.length) &&
            (!filterOnOwnersFinal || !filterOnOwnersFinal.length) &&
            (!filterOnCreatorsFinal || !filterOnCreatorsFinal.length) &&
            !$scope.data.existingDefinition;

        return {
            emptySearchString,
            structuredFilterEmpty,
        };
    }

    /**
     * Applies the filter over the list.
     */
    $scope.applyFilter = function (definition) {
        // Prevent the initiatives from refresh when filters popup bootstrap
        if ($scope.initiativesFilterPopoverState.isBootstrap) {
            // Apply filter is being called by each one of the tnk-custom-filters on init, $timeout will prevent isbootstrap to be set as false by the first filter.
            $timeout(() => {
                $scope.initiativesFilterPopoverState.isBootstrap = false;
            });

            // In case filter exists on popup bootstrap prevent refresh.
            if ($scope.data.initiativesFilterDefinition || $scope.data.initiativesFilterCommonFilters) {
                return;
            }
        }

        $scope.data.initiativesFilterDefinition = null;
        $scope.data.initiativesFilterCommonFilters = null;

        const filterFlags = getFilterFlags();

        // We'd like the user to see the loading and the filter toggle change before the actual server call starts.
        $scope.data.loading = true;
        $scope.data.structuredFilterOn = !filterFlags.structuredFilterEmpty || !!definition;
        $scope.data.initiativesSearchOn = !filterFlags.emptySearchString;
        $scope.data.initiativesFilterOn = $scope.data.structuredFilterOn || $scope.data.initiativesSearchOn;

        // If we're already querying the server, we do not want to fire another search.
        if (!$scope.data.queryingServer) {
            $scope.data.queryingServer = true;

            // Firing timeout
            $timeout(function () {
                const filterOnTags = getSelectedItemsFromMap(
                    $scope.data.initiativesFilterData.selectedItemsMaps['TAG'],
                );
                const filterOnStatuses = getSelectedItemsFromMap(
                    $scope.data.initiativesFilterData.selectedItemsMaps['STATUS'],
                );
                const filterOnFunctions = getSelectedItemsFromMap(
                    $scope.data.initiativesFilterData.selectedItemsMaps['FUNCTION'],
                );
                const filterOnOwners = getSelectedItemsFromMap(
                    $scope.data.initiativesFilterData.selectedItemsMaps['OWNER'],
                );
                const filterOnCreators = getSelectedItemsFromMap(
                    $scope.data.initiativesFilterData.selectedItemsMaps['CREATOR'],
                );

                const filterFlags = getFilterFlags(
                    filterOnTags,
                    filterOnStatuses,
                    filterOnFunctions,
                    filterOnOwners,
                    filterOnCreators,
                );
                const shouldClearFilter =
                    filterFlags.emptySearchString && filterFlags.structuredFilterEmpty && !definition;

                if (shouldClearFilter) {
                    $scope.loadInitiatives(false, true);
                } else {
                    if (
                        $scope.initiativesFilterControl &&
                        $scope.initiativesFilterControl.createDefinitionFromCustomFilters &&
                        !definition
                    ) {
                        definition = $scope.initiativesFilterControl.createDefinitionFromCustomFilters();
                    } else if ($scope.data.existingDefinition) {
                        definition = $scope.data.existingDefinition;
                    }

                    if (
                        definition ||
                        $scope.data.innerListSearchText ||
                        filterOnTags.length ||
                        filterOnStatuses.length ||
                        filterOnFunctions.length ||
                        filterOnOwners.length ||
                        filterOnCreators.length
                    ) {
                        const commonFilters = {
                            tags: filterOnTags,
                            statuses: filterOnStatuses,
                            functions: filterOnFunctions,
                            owners: filterOnOwners,
                            creators: filterOnCreators,
                        };

                        // Saving the definition and common filters to data, for the next page request.
                        $scope.data.initiativesFilterDefinition = definition;
                        $scope.data.initiativesFilterCommonFilters = commonFilters;

                        setListFilterToUrl(
                            commonFilters.tags,
                            commonFilters.statuses,
                            commonFilters.functions,
                            definition,
                        );
                        $scope.loadInitiatives(false, true);
                    } else {
                        $scope.loadInitiatives(false, true);
                    }
                }

                $scope.data.queryingServer = false;
            }, 500);
        }
    };

    /**
     * Sets the current filter to url.
     */
    function setListFilterToUrl(tags, statuses, functions, definition) {
        const urlFiltersObject = {
            tags,
            statuses,
            functions,
            owners: [],
            creators: [],
            definition,
        };

        // Setting the owners to the list filter.
        for (const ownerPersonId in $scope.data.initiativesFilterData.selectedItemsMaps['OWNER']) {
            if ($scope.data.initiativesFilterData.selectedItemsMaps['OWNER'].hasOwnProperty(ownerPersonId)) {
                const ownerPerson = $scope.data.initiativesFilterData.selectedItemsMaps['OWNER'][ownerPersonId];

                // In order for the people selector to display the owner, we have to save the name and avatarUri into the object aswell.
                urlFiltersObject.owners.push({
                    id: ownerPersonId,
                    name: ownerPerson.name,
                    email: ownerPerson.email,
                    avatarUri: ownerPerson.avatarUri,
                });
            }
        }

        // Setting the creators to the list filter.
        for (const creatorPersonId in $scope.data.initiativesFilterData.selectedItemsMaps['CREATOR']) {
            if ($scope.data.initiativesFilterData.selectedItemsMaps['CREATOR'].hasOwnProperty(creatorPersonId)) {
                const creatorPerson = $scope.data.initiativesFilterData.selectedItemsMaps['CREATOR'][creatorPersonId];

                // In order for the people selector to display the creator, we have to save the name and avatarUri into the object aswell.
                urlFiltersObject.creators.push({
                    id: creatorPersonId,
                    name: creatorPerson.name,
                    email: creatorPerson.email,
                    avatarUri: creatorPerson.avatarUri,
                });
            }
        }

        $state.current.reloadOnSearch = false;

        $scope.data.listFilterUrlParam = encodeURI(btoa(encodeURIComponent(JSON.stringify(urlFiltersObject))));
        loadSavedFilter($scope.data.listFilterUrlParam);

        $location.search('listFilter', $scope.data.listFilterUrlParam);
        $timeout(() => {
            $state.current.reloadOnSearch = true;
        });
    }

    /**
     * Clears the listFilter parameter in the url.
     */
    $scope.clearListFilterUrl = function () {
        $state.current.reloadOnSearch = false;
        $location.search('listFilter', null);
        $location.search('isGroupListFilter', null);
        $timeout(() => {
            $state.current.reloadOnSearch = true;
        });
    };

    $scope.applyFilterFromDefinition = function (queryDefinition) {
        $scope.clearStructuredFilter(true);
        $scope.data.existingDefinition = queryDefinition;

        // After defining a new existing definition, we need to reload the filters control from existing definition.
        if (
            $scope.initiativesFilterControl &&
            $scope.initiativesFilterControl.reloadFromExistingDefinition &&
            $scope.data.existingDefinition
        ) {
            $scope.initiativesFilterControl.reloadFromExistingDefinition($scope.data.existingDefinition);
        }

        $scope.data.toggleDone = true;
        $scope.data.initiativesFilterDefinitionBarOpened = true;
        $scope.applyFilter(queryDefinition);
    };

    /**
     * Occurs once an event of a whole list update was fired.
     */
    $rootScope.$on('groupListUpdated', (event, payload) => {
        // If we're not in a regular list view (for example, in filtered groups view),
        // we should reload regardless of if our group id exists or not.
        if (!$scope.groupId || !$scope.filterByGroup || $scope.data.stateFilter) {
            $scope.applyFilter();
        }

        // Reload live report items
        if (payload.shouldReloadLiveReport) {
            trackHelper.clearCaches();
            $scope.loadInitiatives(true, true, false, null);
        }
    });

    /**
     * Occurs once there was a request for a full reload of a full list(group) reload.
     */
    $rootScope.$on('fullListReload', (e, params) => {
        const groupIds = params ? params.groupIds : null;

        // If we're not in a regular list view (for example, in filtered groups view),
        // we should reload regardless of if our group id exists or not.
        if (!$scope.groupId || !$scope.filterByGroup || $scope.data.stateFilter) {
            $scope.applyFilter();
        } else {
            // Otherwise, that means we're in regular list view - we're filtered over a single group,
            // and we should only reload if that group id ($scope.groupId) is included
            // in the groups ids of the event.
            if (groupIds && groupIds.length) {
                for (const groupId of groupIds) {
                    if ($scope.groupId === groupId) {
                        $scope.applyFilter();
                        return;
                    }
                }
            }
        }
    });

    /**
     * Initializes the invisible group id to field definitions map.
     */
    function initializeInvisibleGroupIdToFieldDefinitionsMap() {
        $scope.data.invisibleGroupIdToFieldDefinitionsMap = {};

        for (const groupId in $scope.data.invisibleGroupIdToInitiativesMap) {
            if ($scope.data.invisibleGroupIdToInitiativesMap.hasOwnProperty(groupId)) {
                $scope.data.invisibleGroupIdToFieldDefinitionsMap[groupId] = customFieldsManager.selectedFieldsMap[
                    $scope.workflowVersionId
                ].filter(
                    (fieldDefinition) =>
                        !liveReportHelper.fieldsIsHiddenMap[groupId][$scope.data.environment][fieldDefinition.id],
                );
            }
        }
    }

    $scope.$on('initiativeMovedFromRoot', (e, params) => {
        if (params && params.initiativeId) {
            const movedInitiativeId = params.initiativeId;

            // Removing from $scope.data.allInitiatives
            const allInitiativesIndex = utils.indexOf(
                $scope.data.allInitiatives,
                (initiative) => initiative.id === movedInitiativeId,
            );
            if (allInitiativesIndex > -1) {
                $scope.data.allInitiatives.splice(allInitiativesIndex, 1);
            }

            // Removing from $scope.data.initiatives
            const initiativesIndex = utils.indexOf(
                $scope.data.initiatives,
                (initiative) => initiative.id === movedInitiativeId,
            );
            if (initiativesIndex > -1) {
                $scope.data.initiatives.splice(initiativesIndex, 1);
            }

            // Removing from $scope.data.invisibleGroupIdToInitiativesMap
            for (const key in $scope.data.invisibleGroupIdToInitiativesMap) {
                if ($scope.data.invisibleGroupIdToInitiativesMap.hasOwnProperty(key)) {
                    const invisibleInitiatives = $scope.data.invisibleGroupIdToInitiativesMap[key];

                    const index = utils.indexOf(
                        invisibleInitiatives,
                        (initiative) => initiative.id === movedInitiativeId,
                    );
                    if (index > -1) {
                        invisibleInitiatives.splice(index, 1);
                        break;
                    }
                }
            }
            $scope.data.invisibleGroupIdToInitiativesMapIsEmpty = utils.isMapEmpty(
                $scope.data.invisibleGroupIdToInitiativesMap,
            );
            initializeInvisibleGroupIdToFieldDefinitionsMap();

            // Removing from $scope.data.initiativesByGroup
            /* jshint loopfunc:true */
            for (const key in $scope.data.initiativesByGroup) {
                if ($scope.data.initiativesByGroup.hasOwnProperty(key)) {
                    const initiativesArray = $scope.data.initiativesByGroup[key];

                    const initiativesArrayIndex = utils.indexOf(
                        initiativesArray,
                        (initiative) => initiative.id === movedInitiativeId,
                    );
                    if (initiativesArrayIndex > -1) {
                        initiativesArray.splice(initiativesArrayIndex, 1);
                    }
                }
            }
        }
    });

    const usecasesTitles = consts.getUsecasesTitles();

    /**
     * Loads the initiatives needed for this week display.
     */
    $scope.loadThisWeek = function () {
        const monday = $scope.data.startDate;

        const from = new Date(monday);
        from.setDate(from.getDate() - 10);

        const to = new Date(monday);
        to.setDate(to.getDate() + 10);

        const thisWeekConditions = {
            from: from.getTime(),
            to: to.getTime(),
        };

        trackHelper
            .getWeekViewInitiatives(
                $scope.pm.project.id,
                thisWeekConditions,
                $scope.groupId,
                true,
                0,
                REGULAR_INITIATIVES_PAGE_SIZE,
                $scope.data.isDraft,
                false,
            )
            .then((data) => {
                if (data && data.data) {
                    $scope.data.initiativesWithDueDateCount += data.data.entities.length;
                    trackHelper.getRelatedInitiativesCount(
                        $scope.pm.project.id,
                        data.data.entities.map(({ id }) => id),
                    );
                }
            });
    };

    $scope.loadOtherWeek = function (diff) {
        const date = new Date($scope.data.startDate);
        date.setDate(date.getDate() + diff);
        $scope.data.startDate = new Date(date);
        $scope.data.loadingWeek = true;
        $scope.loadThisWeek();
        $timeout(function () {
            $scope.data.loadingWeek = false;
        });
    };

    /**
     * Clears the initiative search box.
     */
    $scope.clearInitiativesSearch = function () {
        $scope.data.innerListSearchText = null;
        $scope.data.initiativesSearchOn = false;

        $scope.data.initiativesFilterOn = $scope.data.structuredFilterOn && $scope.data.initiativesSearchOn;
        $scope.applyFilter();
    };

    /**
     * Clears the structured filter search.
     */
    $scope.clearStructuredFilter = function (dontApply) {
        if (!$scope.data.structuredFilterOn && !$scope.data.toggleDone) {
            return;
        }

        $scope.clearListFilterUrl();

        $scope.existingAdvanceFilterDefinition = null;
        $scope.data.existingDefinition = null;

        $scope.data.structuredFilterOn = false;

        if ($scope.initiativesFilterControl && $scope.initiativesFilterControl.clearFiltersAndQueries) {
            $scope.initiativesFilterControl.clearFiltersAndQueries();
        }

        $scope.initiativesFilterPopoverState.isOpen = false;

        $scope.data.initiativesFilterData.filteredOwners.splice(
            0,
            $scope.data.initiativesFilterData.filteredOwners.length,
        );
        $scope.data.initiativesFilterData.filteredCreators.splice(
            0,
            $scope.data.initiativesFilterData.filteredCreators.length,
        );
        $scope.data.initiativesFilterData.selectedItemsMaps = {
            TAG: {},
            STATUS: {},
            FUNCTION: {},
            OWNER: {},
            CREATOR: {},
        };
        $scope.data.initiativesFilterData.selectedItemsCountMap = {
            TAG: 0,
            STATUS: 0,
            FUNCTION: 0,
            OWNER: 0,
            CREATOR: 0,
        };

        $scope.data.initiativesFilterOn = $scope.data.structuredFilterOn && $scope.data.initiativesSearchOn;
        $scope.data.toggleDone = false;
        if (!dontApply) {
            $scope.applyFilter();
        }
    };

    /**
     * Fetches a page of initiatives and caches the response.
     */
    function cacheInitiativesPage(pageNumber, pageSize) {
        const cacheKey = getInitiativesPagesPromiseCacheKey(pageNumber, pageSize);

        // Only firing an API call if it's not already cached.
        if (!$scope.data.initiativesPagesPromiseCache[cacheKey]) {
            $log.debug(`Caching page [${pageNumber}] of size [${pageSize}].`);
            // Fetching the initiatives for the page and caching the promise.
            $scope.data.initiativesPagesPromiseCache[cacheKey] = getTrackHelperGetInitiativesPromise(
                getExtractedInitiativesFiltersObject(),
                false,
                pageNumber,
                pageSize,
            );
        }

        return $scope.data.initiativesPagesPromiseCache[cacheKey];
    }

    /**
     * Returns the key of a page in the page promise cache.
     */
    function getInitiativesPagesPromiseCacheKey(pageNumber, pageSize) {
        return `${pageNumber},${pageSize}${$scope.data.isDraft ? '-draft' : ''}`;
    }

    /**
     * Loads the next page of tracks.
     */
    $scope.loadNextTracksPage = function () {
        if ($scope.data.loadingInitiativesPage) {
            // console.log('load next.. (blocked) ' + $scope.data.pageNumber);
            $scope.data.loadingInitiativesPageQueue = true;
            return $q.resolve();
        }

        // console.log('load next.. ' + $scope.data.pageNumber);

        // By incrementing the page number, we make sure load initiatives will take the next page, as it relies on $scope.data.pageNumber.
        $scope.data.pageNumber++;
        // Setting loading to true.
        $scope.data.loadingInitiativesPage = true;

        return $scope
            .loadInitiatives(true)
            .then((initiativesData) => {
                if (initiativesData && initiativesData.data && initiativesData.data.hasMoreEntities) {
                    return preCachePages(
                        initiativesData,
                        $scope.data.pageNumber + 1,
                        $scope.data.amountOfPagesToPreCache,
                    );
                } else {
                    return $q.resolve();
                }
            })
            .finally(() => {
                $scope.data.loadingInitiativesPage = false;
                if ($scope.data.loadingInitiativesPageQueue) {
                    // if there was a request that got blocked, run again so the list will actually get updated
                    $scope.data.loadingInitiativesPageQueue = false;
                    // console.log('run again!');
                    $timeout($scope.loadNextTracksPage);
                }
            });
    };

    /**
     * Reloads ALL initiatives pages, and returns only after all pages have been retrieved.
     */
    $scope.loadAllInitiativesPages = function (softUpdate) {
        return $scope.loadInitiatives(softUpdate, true, true, $scope.data.fetchAllInitiativesPageSize);
    };

    /**
     * Loads initiatives to display.
     */
    $scope.loadInitiatives = function (softUpdate, resetInitiatives, fetchAllInitiatives, overridePageSize) {
        // If reset initiatives is requested, we reset all relevant arrays of initiatives and the page number to zero.
        if (resetInitiatives) {
            resetAllInitiativesInCtrl();
        }

        // Figuring out the page size.
        const pageSize = overridePageSize ? overridePageSize : $scope.data.pageSize;

        // Don't show 'loading' if it's a soft update. We use !! to cast to a boolean.
        $scope.data.loading = !softUpdate;

        // Initialize filter visibility for the bar on top of the initiatives ctrl.
        $scope.filterVisiblity.baseFilter = $stateParams.filter;
        $scope.filterVisiblity.stateFilter = $stateParams.st;
        $scope.filterVisiblity.groupId = $scope.groupId;
        $scope.filterVisiblity.showFunctionsBar = false;

        // Counting how many load initiatives were called.
        $rootScope.loadInitiativeCount = $rootScope.loadInitiativeCount ? $rootScope.loadInitiativeCount + 1 : 1;

        // Initializing filter views like function and tag and owner.
        initiativeFilterViews();

        // Figuring out the different filters.
        const initiativesFilterObject = getExtractedInitiativesFiltersObject();

        let getInitiativesPromise;
        if (
            !fetchAllInitiatives &&
            $scope.data.initiativesPagesPromiseCache[
                getInitiativesPagesPromiseCacheKey($scope.data.pageNumber, pageSize)
            ]
        ) {
            $log.debug(`Taking page [${$scope.data.pageNumber}] of size [${pageSize}] from cache.`);
            getInitiativesPromise =
                $scope.data.initiativesPagesPromiseCache[
                    getInitiativesPagesPromiseCacheKey($scope.data.pageNumber, pageSize)
                ];
        } else {
            $log.debug(`Page [${$scope.data.pageNumber}] of size [${pageSize}] was not in cache. Fetching it.`);
            getInitiativesPromise = getTrackHelperGetInitiativesPromise(
                initiativesFilterObject,
                fetchAllInitiatives,
                $scope.data.pageNumber,
                pageSize,
            );
        }

        return getInitiativesPromise.then((initiativesData) => {
            $scope.data.hasMoreInitiatives = initiativesData.data.hasMoreEntities;

            // If we weren't requested to fetch all initiatives, we pre cache the next pages.
            if (!fetchAllInitiatives) {
                preCachePages(initiativesData, $scope.data.pageNumber + 1, $scope.data.amountOfPagesToPreCache);
            }

            // $scope.data.allInitiatives Contains all initiatives (whether the user is allowed to see their group or not).
            for (let i = 0; i < initiativesData.data.entities.length; i++) {
                $scope.data.allInitiatives.push(initiativesData.data.entities[i]);
            }

            for (let i = 0; i < initiativesData.initiatives.length; i++) {
                const initiative = initiativesData.initiatives[i];
                const group = initiative.group;

                // Only add items visible to the user to the data.initiatives array and data.initiativesByGroup dict.
                if (group.isVisibleToUser) {
                    // The user has full access to this initiative and its group. Add it to the initiatives array.
                    $scope.data.initiatives.push(initiative);

                    if (!$scope.data.initiativesByGroup[group.id]) {
                        $scope.data.initiativesByGroup[group.id] = [];
                    }
                    $scope.data.initiativesByGroup[group.id].push(initiative.id);
                } else {
                    // If this item's group is not visible to the user, we should add it to the "invisibleGroupIdToInitiativesMap" bucket.
                    if (!$scope.data.invisibleGroupIdToInitiativesMap[initiative.group.id]) {
                        $scope.data.invisibleGroupIdToInitiativesMap[initiative.group.id] = [];
                    }
                    $scope.data.invisibleGroupIdToInitiativesMap[initiative.group.id].push(initiative);
                    $scope.data.invisibleGroupIdToInitiativesMapIsEmpty = utils.isMapEmpty(
                        $scope.data.invisibleGroupIdToInitiativesMap,
                    );
                    initializeInvisibleGroupIdToFieldDefinitionsMap();
                }

                // If any initiatives have returned, and at least one has an owner set, mark the on boarding "assign owner" step as done.
                // If the step was previously completed, this will do nothing.
                /* jshint loopfunc:true */
                if (initiative.owner && initiative.owner.id) {
                    // Use timeout so that tracksEditorControl will get the notification because it is not yet initialized.
                    $timeout(
                        () => {
                            $rootScope.onBoardingManager.completeStep('assignOwner');
                        },
                        0,
                        false,
                    );

                    checkShouldShowBotOffWhenOwnersPopover();
                }
            }

            if (!$scope.showInitialQuestion && (!$scope.data.initiatives || !$scope.data.initiatives.length)) {
                $scope.showInitialQuestion = true;
            }

            $scope.data.loading = false;
            // Off order by loading cause we might got here from sort.
            $scope.data.loadingOrderBy = false;

            $scope.data.showList =
                ($scope.data.initiatives && $scope.data.initiatives.length) ||
                !utils.isMapEmpty($scope.data.invisibleGroupIdToInitiativesMap) ||
                (initiativesFilterObject.onlyGroupId &&
                    $scope.pm.groupsMap[$scope.groupId] &&
                    ((syncConfigCacheManager.getSyncConfig($scope.workflowVersionId) &&
                        syncConfigCacheManager.getSyncConfig($scope.workflowVersionId).viewData) ||
                        ($scope.pm.groupsMap[$scope.groupId].metadata &&
                            $scope.pm.groupsMap[$scope.groupId].metadata.builtInListId))) ||
                $scope.data.initiativesFilterOn;

            $timeout(
                function () {
                    // in timeout, cause it will allow it to create the DOM and only then change this one
                    // on first load even adding few ms so all the templates will be loaded
                    if ($rootScope.currentRouteStateName === 'product.board') {
                        $scope.data.showloading = false;
                        $rootScope.showBotSidePane = true;
                    }
                },
                $rootScope.loadInitiativeCount === 1 ? 200 : 0,
            );

            // If any initiatives have returned, we must have created/synced/imported one. Thus, mark the on boarding "create tracks" step as done.
            // If the step was previously completed, this will do nothing.
            if ($scope.data.initiatives && $scope.data.initiatives.length) {
                // use timeout so that tracksEditorControl will get the notification because it is not yet initialized
                $timeout(
                    function () {
                        $rootScope.onBoardingManager.completeStep('createTrack');
                    },
                    0,
                    false,
                );
            }

            return $q.resolve(initiativesData);
        });
    };

    /**
     * Returns an object containing all needed filters for get initiatives from track helper.
     */
    function getExtractedInitiativesFiltersObject() {
        const onlyMine = $scope.filter === 'my';
        const onlyLate = $scope.filter === 'Late';
        const onlyGroupId =
            !onlyLate &&
            !onlyMine &&
            !$scope.data.stateFilter &&
            (!$scope.filter || $scope.filter.toLowerCase() === 'all')
                ? $scope.groupId
                : null;
        const excludeStatuses = !$scope.data.toggleDone ? ['DONE'] : [];
        const query = $scope.data.initiativesFilterOn ? $scope.data.initiativesFilterDefinition : null;
        const searchString = $scope.data.initiativesFilterOn ? $scope.data.innerListSearchText : null;
        const commonFilters = $scope.data.initiativesFilterOn ? $scope.data.initiativesFilterCommonFilters : null;

        return {
            onlyMine,
            onlyLate,
            onlyGroupId,
            excludeStatuses,
            query,
            searchString,
            commonFilters,
        };
    }

    /**
     * Returns the promise to fetch initiatives from track helper.
     */
    function getTrackHelperGetInitiativesPromise(filterObject, fetchAllInitiatives, pageNumber, pageSize) {
        const filterOnlyRootItems = !$scope.data.structuredFilterOn && !filterObject.onlyMine;

        if (!filterObject.commonFilters) {
            filterObject.commonFilters = {};
        }

        if ($scope.data.stateFilter) {
            filterObject.commonFilters.statuses = [$scope.data.stateFilter];
        }

        if ($scope.data.tag) {
            filterObject.tags = [$scope.data.tag];
        }

        if ($scope.data.func) {
            filterObject.commonFilters.functions = [$scope.data.func.name];
        }

        if ($scope.ownerId) {
            filterObject.commonFilters.ownerId = [$scope.ownerId];
        }

        if ($scope.filter === 'my') {
            filterObject.commonFilters.owners = [$scope.as.currentUser.id];
        }

        const createGetInitiativePromise = (pageNumber, pageSize) => {
            return initiativeManager
                .searchInitiatives(
                    $scope.pm.project.id,
                    filterObject.excludeStatuses,
                    $scope.data.dateRange,
                    null,
                    filterObject.onlyGroupId,
                    filterObject.query,
                    filterObject.searchString,
                    filterObject.commonFilters,
                    pageNumber * pageSize,
                    pageSize,
                    $scope.data.orderBy.field,
                    $scope.data.orderBy.fieldType,
                    $scope.data.orderBy.orderType,
                    filterOnlyRootItems ? true : undefined,
                    $scope.data.isDraft,
                )
                .then((data) => {
                    $scope.searchInitiativesQueryObject = {
                        isArchived: false,
                        groupId: filterObject.onlyGroupId,
                        conditionsQuery: filterObject.query,
                        searchString: filterObject.searchString,
                        filterByTags: filterObject.commonFilters?.tags,
                        filterByStateTexts: filterObject.commonFilters?.statuses,
                        filterByFunctions: filterObject.commonFilters?.functions,
                        filterByOwnerIds: filterObject.commonFilters?.owners,
                        filterByCreatorIds: filterObject.commonFilters?.creators,
                        excludeByInitiativeStatuses: filterObject.excludeStatuses,
                        skip: pageNumber * pageSize,
                        limit: pageSize,
                        orderByFieldId: $scope.data.orderBy.field,
                        orderByFieldType: $scope.data.orderBy.fieldType,
                        orderByType: $scope.data.orderBy.orderType,
                        filterOnlyRootItems,
                        isDraftInitiatives: $scope.data.isDraft,
                        from: $scope.data.dateRange?.from,
                        to: $scope.data.dateRange?.to,
                    };

                    if (filterOnlyRootItems) {
                        $scope.searchInitiativesQueryObject.isRootInitiative = true;
                    }

                    let liveReportFieldDefinitionsPromise = $q.resolve({});
                    let relatedInitiativesCountPromise = $q.resolve({});

                    if (filterObject.onlyMine) {
                        const groupIds = Object.values(data.data.relatedEntities)
                            .filter((entity) => entity.id.includes('GRUP'))
                            .map((group) => group.id);

                        // we get the relevant groups with shouldGetRelatedInfo in order to load the live report fields state of each group
                        liveReportFieldDefinitionsPromise = groupInfoManager.getGroupsByIds(groupIds, false, true);
                    }

                    // Add innerItems count to cached initiatives.
                    if (data && data.data) {
                        $scope.data.initiativesWithDueDateCount += data.data.entities.length;
                        $scope.data.loadingRelatedInitiatives = true;
                        relatedInitiativesCountPromise = trackHelper.getRelatedInitiativesCount(
                            $scope.pm.project.id,
                            data.data.entities.map(({ id }) => id),
                        );
                    }

                    return $q
                        .all([liveReportFieldDefinitionsPromise, relatedInitiativesCountPromise])
                        .then(() => {
                            // Returning the original getInitiative server response, so
                            // chaining to the promise will still work
                            return data;
                        })
                        .finally(() => {
                            $scope.data.loadingRelatedInitiatives = false;
                        });
                });
        };

        if (fetchAllInitiatives) {
            return fetchAllInitiativesPages(createGetInitiativePromise, 0, pageSize);
        } else {
            return createGetInitiativePromise(pageNumber, pageSize);
        }
    }

    /**
     * Initializing whether it's a function or a tag filter view.
     */
    function initiativeFilterViews() {
        if ($scope.filter === 'Archive' || $scope.filter === 'Late') {
            return;
        }

        if ($scope.filter === 'owner') {
            $scope.filterVisiblity.showFunctionsBar = 'owner';
            return;
        }

        // If we are showing all items in a group, and a group is defined.
        if ($scope.filter === 'all' && $scope.groupId) {
            $scope.data.startManually = true;
        }

        // Different parameters initialization.
        if ($scope.filter && $scope.filter !== 'all' && $scope.filter !== 'my') {
            // Trying to find if it's a function filter, and if so, initializing the function and default owner.
            const existingFunction = utils.findFirst($scope.pm.functions, (func) => func.name === $scope.filter);
            if (existingFunction) {
                $scope.data.func = existingFunction;

                if (existingFunction.defaultOwner) {
                    $scope.data.selectedPeople = [existingFunction.defaultOwner];
                }
            }

            // Initializing showFunctionsBar.
            if ($scope.filterVisiblity) {
                $scope.filterVisiblity.showFunctionsBar = !$scope.data.func ? 'tags' : 'func';
            }

            // If we didn't initialize a func, that must mean we're in a tag filter.
            if (!$scope.data.func) {
                $scope.data.tag = $scope.filter;
            }

            // If we have a func defined, we define the editOwner if needed.
            if ($scope.data.func) {
                $scope.data.editOwner = !$scope.data.func.defaultOwner;
            }
        }
    }

    /**
     * Resets all the initiatives saved in the control.
     */
    function resetAllInitiativesInCtrl() {
        $scope.data.pageNumber = 0;
        $scope.data.highestPage = 0;
        $scope.data.allInitiatives = [];
        $scope.data.initiatives = [];
        $scope.data.initiativesByGroup = {};
        $scope.data.invisibleGroupIdToInitiativesMap = {};
        $scope.data.invisibleGroupIdToInitiativesMapIsEmpty = true;
        $scope.data.initiativesPagesPromiseCache = {};
    }

    /**
     * Fetches all initiative pages.
     * @param fetchInitiativesCallback Callback to fetch initiatives single page. It's a callback that receives two arguments - the page number and the page size.
     * @param pageNumber The current page number we're fetching.
     * @param pageSize The page size for fetching.
     */
    function fetchAllInitiativesPages(fetchInitiativesCallback, pageNumber, pageSize) {
        // All parameters are a must.
        if (
            utils.isNullOrUndefined(fetchInitiativesCallback) ||
            utils.isNullOrUndefined(pageNumber) ||
            utils.isNullOrUndefined(pageSize)
        ) {
            return $q.resolve();
        }

        return fetchInitiativesCallback(pageNumber, pageSize).then((currentInitiativesData) => {
            // If we didn't receive any data back, or we don't have any more entities to fetch.
            if (
                !currentInitiativesData ||
                !currentInitiativesData.data ||
                !currentInitiativesData.data.hasMoreEntities
            ) {
                return $q.resolve(currentInitiativesData);
            }

            // Otherwise, we recursively fetch another page of initiatives.
            return fetchAllInitiativesPages(fetchInitiativesCallback, pageNumber + 1, pageSize).then(
                (newInitiativesData) => {
                    // Now, we merge between currentInitiativesData and newInitiativesData.
                    // If no new data, we just return the currentInitiativesData.
                    if (!newInitiativesData) {
                        return $q.resolve(currentInitiativesData);
                    }

                    // Starting from currentInitiativesData.
                    const mergedInitiativesData = currentInitiativesData;

                    // Merging initiatives.
                    if (newInitiativesData.initiatives && newInitiativesData.initiatives.length) {
                        mergedInitiativesData.initiatives = mergedInitiativesData.initiatives.concat(
                            newInitiativesData.initiatives,
                        );
                    }

                    // If there is no data property to the newInitiativesData, we finished our merge job.
                    if (!newInitiativesData.data) {
                        return $q.resolve(mergedInitiativesData);
                    }

                    // Merging hasMoreEntities.
                    mergedInitiativesData.data.hasMoreEntities = newInitiativesData.data.hasMoreEntities;

                    // Merging related entities.
                    if (newInitiativesData.data.relatedEntities) {
                        for (const key in newInitiativesData.data.relatedEntities) {
                            if (newInitiativesData.data.relatedEntities.hasOwnProperty(key)) {
                                mergedInitiativesData.data.relatedEntities[key] =
                                    newInitiativesData.data.relatedEntities[key];
                            }
                        }
                    }

                    // Merging entities.
                    if (newInitiativesData.data.entities && newInitiativesData.data.entities.length) {
                        /* jshint loopfunc:true */
                        for (let i = 0; i < newInitiativesData.data.entities.length; i++) {
                            const newEntity = newInitiativesData.data.entities[i];

                            // Checking if the new entity already exists in the merged data.
                            const existingEntity = utils.findFirst(
                                mergedInitiativesData.data.entities,
                                (entity) => entity.id === newEntity.id,
                            );

                            // If it does not already exist, we add it.
                            if (!existingEntity) {
                                mergedInitiativesData.data.entities.push(newEntity);
                            }
                        }
                    }

                    return $q.resolve(mergedInitiativesData);
                },
            );
        });
    }

    $scope.toggleOnlyMine = function () {
        $state.go('product.board', { filter: !$scope.data.onlyMine ? 'my' : 'all' });
    };

    /**
     * Navigates to a different list.
     */
    $scope.navigateToList = function (groupId) {
        $state.go('product.board', { filter: 'all', listFilter: null, isGroupListFilter: null, g: groupId, st: null });
    };

    $scope.updateFunctionName = function (name) {
        if ($scope.data.func) {
            $scope.data.loadingUpdateTitle = true;
            analyticsWrapper.track('Update Function name', { category: 'Initiatives' });
            tonkeanService.updateFunctionName($scope.data.func.id, name).then(function (data) {
                $scope.data.func.name = data.name;
                $scope.data.editTitle = false;
                $scope.data.loadingUpdateTitle = false;
                $state.go('product.board', { filter: data.name });
            });
        }
    };

    $scope.addPeopleToFunction = function (func) {
        const funcId = func.id;
        const selectedPeople = $scope.data.selectedPeople;

        let updatePromise = null;

        if (funcId && selectedPeople && selectedPeople.length) {
            analyticsWrapper.track('Set Owner', { category: 'Function Default' });

            const peopleIds = [];

            for (const person of selectedPeople) {
                if (person.id) {
                    peopleIds.push(person.id);
                }
            }

            updatePromise = tonkeanService.updateFunctionDefaultOwner(funcId, peopleIds[0]).then((data) => {
                $scope.data.func.defaultOwner = data.defaultOwner;
                $scope.data.editOwner = false;
            });
        } else {
            updatePromise = tonkeanService.updateFunctionDefaultOwner(funcId, null).then((data) => {
                $scope.data.func.defaultOwner = data.defaultOwner;
                $scope.data.editOwner = false;
            });
        }

        if (updatePromise) {
            updatePromise.then(() => $scope.loadInitiatives(false, true));
        }
    };

    $scope.deleteFunction = function (func) {
        $scope.questionConfirmModalData = {
            title: 'Delete function',
            body: `Are you sure you want to delete "${func.name}"?`,
            okLabel: 'Delete',
            cancelLabel: 'Cancel',
        };

        modal
            .openQuestionConfirmModal({
                scope: $scope,
                windowClass: 'mod-warning',
            })
            .result.then(function () {
                // okLabel

                analyticsWrapper.track('Delete Function', { category: 'Function Settings' });
                tonkeanService.deleteEntity(func.id).then(function () {
                    $scope.pm.getFunctions(true);
                    $scope.$emit('alert', { msg: 'Done!', type: 'success' });
                    $state.go('product');
                });
            })
            .catch(function () {
                // cancelLabel
                $scope.deleting = false;
            });
    };

    $scope.$watch('data.initiativesWithDueDateCount', function () {
        if ($localStorage.showWeekViewMap[$scope.groupId] === undefined && $localStorage.showWeekView === undefined) {
            $scope.data.showWeekView = $scope.data.initiativesWithDueDateCount > 0;
        }
    });

    $scope.toggleWeekView = function () {
        $scope.data.showWeekView = !$scope.data.showWeekView;
        // $localStorage.showWeekView = $scope.data.showWeekView;
        $localStorage.showWeekViewMap[$scope.groupId] = $scope.data.showWeekView;
    };

    $scope.toggleKeyMetrics = function (event) {
        // We don't want to close the dropdown, so we cancel click bubbling.
        if (event) {
            event.stopPropagation();
        }

        $scope.data.hideKeyMetrics = !$scope.data.hideKeyMetrics;
        if (!$localStorage.hideKeyMetrics) {
            $localStorage.hideKeyMetrics = {};
        }
        $localStorage.hideKeyMetrics[$scope.groupId] = $scope.data.hideKeyMetrics;
    };

    $scope.addItemToFilter = function (item) {
        if (!$scope.filter || !item || !item.id) {
            return;
        }

        let updatePromise = null;

        if ($scope.data.func) {
            // If user is in a function filter
            updatePromise = trackHelper.setFunction(item, $scope.data.func);
        } else if ($scope.data.tag) {
            // If user is in a tag filter
            if (!item.tags) {
                item.tags = [];
            }
            item.tags.push($scope.data.tag);

            updatePromise = trackHelper.updateTags(item.id, item.tags);
        }

        // Since we are in a filter mode, and paging is not a consideration, we can just call the load initiatives here.
        if (updatePromise) {
            updatePromise.then(() => $scope.loadInitiatives(false, true));
        }
    };

    $scope.getUsecaseTitle = function () {
        return utils.getUsecasesTitles(usecasesTitles, $scope.pm.project.usecases);
    };

    $scope.filterGroup = function (group) {
        return (
            !$scope.filterByGroup ||
            group.id === $scope.groupId ||
            ($scope.data.stateFilter &&
                $scope.data.initiativesByGroup[group.id] &&
                $scope.data.initiativesByGroup[group.id].length)
        );
    };

    $scope.setFreeMembershipGroup = function () {
        analyticsWrapper.track('Change free membership group', { category: 'Initiatives', label: $scope.groupId });
        $scope.pm.setFreeMembershipGroup($scope.groupId);
    };

    $scope.openManageFields = function (groupId, workflowVersionId, $event) {
        if ($scope.data.isFieldsStillLoadingFromCache) {
            $event.stopPropagation();
            return;
        }

        modal.openManageFieldsModal(groupId, workflowVersionId, false, undefined, $scope.data.environment);
    };

    /**
     * Checks if the current group is connected to a communication channel / direct messages.
     * Note: THIS FUNCTION IS CALLED FROM THE HTML AND RUNS IN EVERY DIGEST - so keep it light and fast!
     * @returns {boolean} - true if the current group is connected to a communication integration, false otherwise.
     */
    $scope.isCommunicationChannelConnected = function () {
        if ($scope.cis.anyIntegrationSupports($scope.cis.features.notifications)) {
            const group = $scope.pm.groupsMap[$scope.groupId];

            if (group && group.notificationSettings && group.notificationSettings.channelName) {
                // If the group has a channel name, it's connected.
                return true;
            } else if (group && group.visibilityType === 'PRIVATE' && group.notificationSettings) {
                // If this is a private group, if it has notificationSettings it either has a channel or direct messages configured.
                return true;
            } else if ($scope.pm.project.notificationSettings) {
                // If no group channel, if there's a default channel for the project - it's also connected to that channel.
                return true;
            }
        }

        return false;
    };

    /**
     * This code runs on every digest, keep it simple!
     * @returns {string} - communication integration menu item tooltip label.
     */
    $scope.getMenuCommunicationIntegrationTooltipLabel = function (communicationIntegration) {
        // Check if we're connected to a communication integration that supports notifications.
        if (communicationIntegration) {
            const group = $scope.pm.groupsMap[$scope.groupId];

            // Try to take the group's channel name.
            if (group && group.notificationSettings && group.notificationSettings.channelName) {
                return `Connected to ${group.notificationSettings.channelName}`;
            } else if (group && group.visibilityType === 'PRIVATE') {
                // If this is a private group, it might be set to direct messages (and not have a channel).
                return group.notificationSettings ? 'Direct messages' : 'Not set';
            } else if ($scope.pm.project.notificationSettings) {
                // If no group channel, try to take the default channel.
                return `Connected to ${$scope.pm.project.notificationSettings.channelName}`;
            } else {
                // Otherwise, tell the user he should define a channel.
                return `${communicationIntegration.displayName} ${
                    $scope.cis.integrations[communicationIntegration.integrationType].channelLabel
                } not selected`;
            }
        } else {
            // We're not connected to slack.
            return 'Not connected to Slack';
        }
    };

    /**
     * This code runs on every digest, keep it simple!
     * @returns {string} - communication integration menu item label.
     */
    $scope.getMenuCommunicationIntegrationLabel = function (communicationIntegration) {
        // Check if we're connected to a communication integration that supports notifications.
        if (communicationIntegration) {
            const group = $scope.pm.groupsMap[$scope.groupId];

            // Try to take the group's channel name.
            if (group && group.notificationSettings && group.notificationSettings.channelName) {
                const channelLabel = $scope.cis.integrations[communicationIntegration.integrationType].channelLabel;
                return `${communicationIntegration.displayName} ${channelLabel} notifications`;
            } else {
                // If the group has no channel connected, it's direct messages.
                return `${communicationIntegration.displayName} direct notifications`;
            }
        } else {
            // If there is no communication integration connected, we default to offer Slack.
            return 'Slack channel notifications';
        }
    };

    $scope.setForceHideBotOffWhenOwnersPopover = function (value) {
        $scope.as.currentUser.metadata.forceHideBotOffWhenOwnersPopover = value;
        tonkeanService.updateProfileMetadata($scope.as.currentUser.metadata);
    };

    $scope.startWithEmptyClicked = function () {
        let startOnBoarding = true;

        // If there are other groups created already, the on boarding should never start.
        // This can happen if the main group is left empty for some reason.
        if ($scope.pm.groups && $scope.pm.groups.length > 1) {
            startOnBoarding = false;
        }

        // refreshing bot state
        // loadBotState();

        // If we are in an active group, just mark the start manually flag as true.
        // (There might be a situation where there are no groups, so we won't have a group id).
        if ($scope.groupId) {
            $scope.data.startManually = true;

            // If this is the main default list, we should enable the continuous on-boarding.
            if ($scope.groupId === $scope.pm.groupDefaultId && startOnBoarding) {
                setShowOnBoardingTrue();
            }

            // Complete the firstListCreated on boarding step. If it was already completed this action won't do anything.
            $rootScope.onBoardingManager.completeStep('firstListCreated');
        } else {
            // No group id was provided, create a new empty group.
            $scope.creatingEmptyGroup = true;
            analyticsWrapper.track('Open new worker modal', { category: 'Workers browser' });
            modalUtils
                .openChooseWorkerTypeModal()
                .then((group) => {
                    $state.go('product.workerEditor', { g: group.id });
                })
                .finally(function () {
                    $scope.creatingEmptyGroup = false;
                });
        }
    };

    $scope.openCreateNewFieldModal = function () {
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            'GLOBAL',
            null,
            null,
            true,
            false,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            $scope.workflowVersionId,
        ]);
    };

    $scope.openEditFieldModal = function (fieldDefinition) {
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            fieldDefinition.targetType,
            fieldDefinition.type,
            fieldDefinition.projectIntegration,
            false,
            false,
            fieldDefinition,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.workflowVersionId,
        ]);
    };

    $scope.openFixIntegrationModal = function (fieldDefinition) {
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            fieldDefinition.targetType,
            fieldDefinition.type,
            fieldDefinition.projectIntegration,
            false,
            false,
            fieldDefinition,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.workflowVersionId,
        ]);
    };

    /**
     * Opens the invite modal with, possibly, a populated private group to invite to.
     */
    $scope.openInviteModal = function () {
        let initialGroupId = null;
        const group = projectManager.groupsMap[$scope.groupId];

        // Only if private list the user is a project owner, or the creator of the list, we populate the invite modal with the list the user opened the invite from.
        if (
            group.visibilityType === 'PRIVATE' &&
            (projectManager.isOwner || groupPermissions.currentUserIsOwnerMap[group.id])
        ) {
            initialGroupId = $scope.groupId;
        }

        modal.openInvite(null, null, null, initialGroupId ? [initialGroupId] : null, () =>
            viewingGroupUpdateService.updateUserViewingGroupApiCall($scope.groupId),
        );
    };

    $scope.openBotActiveValidationModal = function () {
        let body;
        body = workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId).shouldSendGatherUpdates
            ? 'Turning off the Module means Tonkean will not follow up automatically on your tracks'
            : 'Turning on the Module means Tonkean will occasionally reach out to the owners on your tracks';

        modal
            .openQuestionConfirmModal({
                windowClass: 'mod-primary',
                resolve: {
                    questionConfirmModalData() {
                        return {
                            title: 'Are you sure?',
                            body,
                            okLabel: 'Confirm',
                            cancelLabel: 'Cancel',
                        };
                    },
                },
            })
            .result.then(function () {
                // okLabel clicked.
                toggleIsBotActive();
            });
    };

    /**
     * Set order by, if its on loading we are not calling load intiative cause it will be called later.
     */
    $scope.setOrderBy = function (field, fieldType, orderType, forceLoad, groupId) {
        $scope.data.loadingOrderBy = true;
        $scope.data.orderBy.field = field;
        $scope.data.orderBy.fieldType = fieldType;
        $scope.data.orderBy.orderType = orderType;

        let orderByPreferences = $localStorage.orderBy;
        if (!orderByPreferences) {
            orderByPreferences = {};
        }
        orderByPreferences[groupId] = {
            field: $scope.data.orderBy.field,
            fieldType: $scope.data.orderBy.fieldType,
            orderType: $scope.data.orderBy.orderType,
        };

        if (orderByPreferences[groupId].field === 'index') {
            delete orderByPreferences[groupId];
        }

        $localStorage.orderBy = orderByPreferences;

        $scope.searchInitiativesQueryObject.orderByFieldId = $scope.data.orderBy.field;
        $scope.searchInitiativesQueryObject.orderByFieldType = $scope.data.orderBy.fieldType;
        $scope.searchInitiativesQueryObject.orderByType = $scope.data.orderBy.orderType;

        if (forceLoad) {
            $scope.loadInitiatives(false, true, null, null, true);
        }
    };

    /**
     * Checks whether the viewing users bar should be displayed on page
     */
    $scope.shouldDisplayViewingUsersBar = function () {
        return (
            !!$scope.viewingUsers &&
            (($scope.pm.isInTrial && !$scope.pm.isFree && !$scope.pm.isLicensed) ||
                ($scope.pm.project.license && $scope.pm.project.license.tier === 'PREMIUM') ||
                $scope.pm.isEnterprise) &&
            $scope.groupId &&
            $scope.filterByGroup &&
            !$scope.data.stateFilter &&
            ($scope.pm.groupsMap[$scope.groupId].visibilityType === 'PUBLIC' ||
                $scope.pm.groupsMap[$scope.groupId].members.length > 1)
        );
    };

    /**
     * Returns a canonized list of the users, containing all the params needed to display the tnk-avatars-bar component
     */
    $scope.prepareAvatars = function () {
        const viewingMapping = $scope.pm.groupIdToViewingUsersMap[$scope.groupId];
        if (viewingMapping) {
            const users = $scope.pm.groupIdToViewingUsersMap[$scope.groupId].users;
            const relevantUsers = users.map(function (user) {
                const currentUser = { ...$scope.pm.groupIdToViewingUsersMap[$scope.groupId].personMap[user.id] };
                currentUser.opacity = !user.lastViewTime || user.lastViewTime < utils.now() - 1 * 60 * 60 * 1000;
                currentUser.isLink = !!user.lastViewTime;
                currentUser.lastViewTime = user.lastViewTime;
                return currentUser;
            });
            return relevantUsers;
        }
        return [];
    };

    $scope.onEnvironmentToggleClick = function (environment) {
        const isProduction = environment === 'production';
        return $state.go('product.board', { env: isProduction ? 'PUBLISHED' : 'DRAFT' });
    };

    $scope.openEmailNotificationsSettings = function ($event) {
        if ($scope.data.isDraft) {
            $event.stopPropagation();
            return;
        }

        modal.openActivityDigestEmailSettingsModal();
    };

    $scope.openEmailEndpointModal = function ($event) {
        if ($scope.data.isDraft) {
            $event.stopPropagation();
            return;
        }

        modalUtils.openEmailEndpointModal($scope.groupId);
    };

    $scope.openReportScheduling = function ($event) {
        if ($scope.data.isDraft) {
            $event.stopPropagation();
            return;
        }

        modalUtils.openManageScheduledReportsModal($scope.groupId);
    };

    /**
     * Initializes the data.groupStatesLabelsSet with the available states.
     */
    function initializeGroupStatesLabelsSet() {
        if (
            $scope.groupId &&
            projectManager.groupsMap[$scope.groupId] &&
            $scope.workflowVersionId &&
            workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId).states &&
            workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId).states.length
        ) {
            $scope.data.groupStatesLabelsSet = utils.arrayToSet(
                workflowVersionManager
                    .getCachedWorkflowVersion($scope.workflowVersionId)
                    .states.map((state) => state.label),
            );
        } else {
            $scope.data.groupStatesLabelsSet = $scope.pm.project.states.map((state) => state.label);
        }
    }

    function toggleIsBotActive() {
        workflowVersionManager.toggleShouldSendGatherUpdates(
            $scope.groupId,
            !workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId).shouldSendGatherUpdates,
        );
    }

    function setShowOnBoardingTrue() {
        // Only show the on boarding if the user has the continuousOnBoarding object (which he gets in the sign up on boarding flow).
        // We don't show the on boarding to invited users - this shouldn't happen anyway, since a list will probably already have been created by the inviting admin.
        // This situation can happen when an invited user creates a board - again, not likely.
        if ($rootScope.as.currentUser.metadata && $rootScope.as.currentUser.metadata.continuousOnBoarding) {
            // showOnBoarding2 replaced showOnBoarding since we decided to only show the continuous onboarding to users who decided to create an empty list.
            // Numbering our properties replaces the need to run a migration (since showOnBoarding was set to true for each new user).
            $rootScope.as.currentUser.metadata.showOnBoarding2 = true;

            // Update the server.
            tonkeanService.updateProfileMetadata($rootScope.as.currentUser.metadata).then(function () {
                // Tell the authentication service the user has been updated so he will broadcast an event that will update the onBoardingManager.
                $rootScope.as.updateCurrentUser($rootScope.as.currentUser);
            });

            // Auto pop the continuous on boarding modal. Supply it with a dismiss function.
            $rootScope.$broadcast('toggleBotPopover', true, true);

            // $scope.modalUtils.openContinuousOnBoardingModal(function () {
            //     $rootScope.onBoardingManager.finishOnBoarding();
            // });
        }
    }

    /**
     * Returns true if the given group id exists in the project's groups.
     */
    function groupExistsInProjectGroups(groupId) {
        const existingGroup = $scope.pm.groups.filter((group) => group.id === groupId);
        return existingGroup && existingGroup.length === 1;
    }

    /**
     * If local storage's last group id exists in project groups, it will be selected.
     * Otherwise, the default group id will be selected.
     */
    function evaluateSelectedGroupId(lastGroupId) {
        if (groupExistsInProjectGroups(lastGroupId) && !$scope.pm.groupsMap[lastGroupId].dashboardHidden) {
            return lastGroupId;
        } else {
            return $scope.pm.groupDefaultId;
        }
    }

    /**
     * try to load a savedFilter if matched.
     */
    function loadSavedFilter(listFilterUrlParam) {
        let found = false;
        if (
            authenticationService.currentUser.metadata &&
            authenticationService.currentUser.metadata.savedFilters &&
            authenticationService.currentUser.metadata.savedFilters[$scope.groupId]
        ) {
            let currentSavedFilter = null;
            for (let i = 0; i < authenticationService.currentUser.metadata.savedFilters[$scope.groupId].length; i++) {
                const item = authenticationService.currentUser.metadata.savedFilters[$scope.groupId][i];
                if (item.url === listFilterUrlParam) {
                    currentSavedFilter = item;
                    found = true;
                    break;
                }
            }
            $scope.data.currentSavedFilter = currentSavedFilter;
            $scope.data.isGroupListFilter = false;
        }

        if (
            !found &&
            $scope.pm.groupsMap[$scope.groupId].metadata &&
            $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters
        ) {
            let currentSavedFilter = null;
            for (let i = 0; i < $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters.length; i++) {
                const item = $scope.pm.groupsMap[$scope.groupId].metadata.savedFilters[i];
                if (item.url === listFilterUrlParam) {
                    currentSavedFilter = item;
                    break;
                }
            }
            $scope.data.currentSavedFilter = currentSavedFilter;
            $scope.data.isGroupListFilter = true;
        }
    }

    /**
     * Checks if we should show the bot off when owners popover.
     */
    function checkShouldShowBotOffWhenOwnersPopover() {
        if (
            workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId) &&
            !workflowVersionManager.getCachedWorkflowVersion($scope.workflowVersionId).shouldSendGatherUpdates
        ) {
            $scope.openBotOffWhenOwnersPopover = true;
        }
    }

    /**
     * Return converted filters generated from common filters
     */
    function createFiltersFromCommonFilters(commonFilters) {
        const filters = [];

        const commonFiltersTypes = utils.objKeys(commonFilters);
        for (const commonFilterType of commonFiltersTypes) {
            for (let valueIndex = 0; valueIndex < commonFilters[commonFilterType].length; valueIndex++) {
                const filterValue = commonFilters[commonFilterType][valueIndex];

                const convertedFilter = angular.copy($scope.data.commonFiltersMapping[commonFilterType]);

                // in case values are objects compare ids and take name as value
                if (convertedFilter.values) {
                    if (Array.isArray(convertedFilter.values)) {
                        for (let k = 0; k < convertedFilter.values.length; k++) {
                            const convertedFilterValue = convertedFilter.values[k];
                            if (convertedFilterValue.id === filterValue) {
                                convertedFilter.value = convertedFilterValue.name;
                                break;
                            }
                        }
                    } else {
                        // in case the values are the keys

                        const convertedFilterValues = utils.objKeys(convertedFilter.values);
                        for (const convertedFilterValue of convertedFilterValues) {
                            if (convertedFilterValue === filterValue) {
                                convertedFilter.value = convertedFilterValue;
                                break;
                            }
                        }
                    }
                }

                delete convertedFilter.values;
                filters.push(convertedFilter);
            }
        }
        return filters;
    }

    /** Scroll handling **/
    let scrolled = 0;
    let onscrollAction = null;
    let onscrollHandlerId = null;
    const projectBoardElement = document.querySelector('#project-board');

    if (!$rootScope.isMobile) {
        onscrollAction = function () {
            // current scroll size
            scrolled = projectBoardElement.scrollTop;

            // handle metrics top position
            const metricsBarElement = document.querySelector('#initiatives-list-metrics');
            if (metricsBarElement) {
                metricsBarElement.style.top = `${-1 * scrolled}px`;
            }
        };

        onscrollHandlerId = safeDomEvents.registerEvent('project-board', 'onscroll', onscrollAction);
    }

    function getWorkflowVersionIdFromUrl() {
        if (!projectManager.groupsMap[$scope.groupId]) {
            return null;
        }

        if ($scope.data.isDraft) {
            return $scope.pm.groupsMap[$scope.groupId].draftWorkflowVersionId;
        } else {
            return $scope.pm.groupsMap[$scope.groupId].publishedWorkflowVersionId;
        }
    }

    $scope.getWorkflowVersionIdByGroupId = function (groupId) {
        return $scope.data.isDraft
            ? $scope.wvm.groupIdToDraftWorkflowVersionIdMap[groupId]
            : $scope.wvm.groupIdToPublishedWorkflowVersionIdMap[groupId];
    };

    $scope.$watch('$location.search().tid', (value) => {
        $scope.initialTid = value;
    });

    $scope.$on('$destroy', function () {
        // console.log('destory initiativesPage');
        // cleaning the onscroll by setting the old ballback (which if null, means we are the root and can get rid of it)
        safeDomEvents.unregisterEvent('project-board', 'onscroll', onscrollHandlerId);

        // Canceling the update user viewing group interval.
        viewingGroupUpdateService.cancelInterval($scope.groupId);

        // Resetting back the currentlyViewedGroupId to null, so it will be back to normal caching.
        projectManager.currentlyViewedGroupId = null;
    });

    $scope.init();
}

export default angular.module('tonkean.app').controller('InitiativesCtrl', InitiativesCtrl);
