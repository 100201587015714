/**
 * Created by sagieliyahu on 24/01/2018.
 */

export const tnkPhoneInput = angular.module('tonkean.app').directive('tnkPhoneInput', function ($filter, $browser) {
    return {
        require: 'ngModel',
        link($scope, $element, $attrs, ngModelCtrl) {
            if (!$attrs.tnkPhoneInput || $attrs.tnkPhoneInput === 'false') {
                // if it's false, we don't want to do anything
                return;
            }

            const listener = function () {
                const value = $element.val().replace(/\D/g, '');
                $element.val($filter('tel')(value, false));
            };

            // This runs when we update the text field
            ngModelCtrl.$parsers.push(function (viewValue) {
                return viewValue.replace(/\D/g, '').slice(0, 10);
            });

            // This runs when the model gets updated on the scope directly and keeps our view in sync
            ngModelCtrl.$render = function () {
                $element.val($filter('tel')(ngModelCtrl.$viewValue, false));
            };

            $element.bind('change', listener);
            $element.bind('keydown', function (event) {
                const key = event.keyCode;
                // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
                // This lets us support copy and paste too
                if (key === 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
                    return;
                }
                $browser.defer(listener); // Have to do this or changes don't get picked up properly
            });

            $element.bind('paste cut', function () {
                $browser.defer(listener);
            });
        },
    };
});
export const tel = angular.module('tonkean.app').filter('tel', function () {
    return function (tel) {
        if (!tel) {
            return '';
        }

        const value = tel.toString().trim().replace(/^\+/, '');

        if (/\D/.test(value)) {
            return tel;
        }

        let city;
        let number;

        switch (value.length) {
            case 1:
            case 2:
            case 3:
                city = value;
                break;

            default:
                city = value.slice(0, 3);
                number = value.slice(3);
        }

        if (number) {
            if (number.length > 3) {
                number = `${number.slice(0, 3)}-${number.slice(3, 7)}`;
            }

            return `(${city}) ${number}`.trim();
        } else {
            return `(${city}`;
        }
    };
});
