import { Formik } from 'formik';
import type { FormikValues } from 'formik';
import type { FormikProps } from 'formik/dist/types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import ProjectIntegrationEntityLiveStreamFilterPayloadRadio from './ProjectIntegrationEntityLiveStreamFilterPayloadRadio';
import ProjectIntegrationEntityLiveStreamRightLayout from './ProjectIntegrationEntityLiveStreamRightLayout';
import ProjectIntegrationEntityLiveStreamWebhookFilter from './ProjectIntegrationEntityLiveStreamWebhookFilter';
import ProjectIntegrationEntityLiveStreamWebhookUrl from './ProjectIntegrationEntityLiveStreamWebhookUrl';
import type BaseProjectIntegrationEntityProps from '../../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import SplitPageTemplate from '../../../../infrastructure/pageTemplates/SplitPageTemplate';
import useEnterpriseComponentVariablesExpressionTab from '../../../EnterpriseComponentsModule/hooks/useEnterpriseComponentVariablesExpressionTab';
import defaultTonkeanExpression from '../../../FormBuilder/entities/DefaultTonkeanExpression';
import type { AdditionalSidePaneConfiguration } from '../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import useWebhookPayloadAdditionalTab from '../../../HttpRequestConfiguration/utils/useWebhookPayloadAdditionalTab';
import projectIntegrationActionConstantParamsIdToConfig, {
    ProjectIntegrationActionConstantParams,
} from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationActionConstantParamIdToConfig';
import { ProjectIntegrationPaginatedActionConstantParams } from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationPaginatedActionConstantParams';
import ProjectIntegrationEntityLeftPageGenericHeader from '../common/ProjectIntegrationEntityLeftPageGenericHeader';
import ValidateActionHandleType from '../common/ValidateActionHandleType';
import ProjectIntegrationEntityHeaderComponent from '../ProjectIntegrationEntityHeaderComponent';
import HandleResponseActionType from '../ProjectIntegrationEntitySchema/HandleResponseActionType';
import singleHandleResponseSchema from '../projectIntegrationEntitySplitPageFormikSchema';
import ProjectIntegrationHandleResponse from '../ProjectIntegrationHandleResponse';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage, Field, H4, useCreateDialog } from '@tonkean/infrastructure';
import { WebhookPayloadHandlingType } from '@tonkean/tonkean-entities';
import { EntityResponseHandlingDefinitionType, FieldQueryType } from '@tonkean/tonkean-entities';
import type { WebhookPayload } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { yupEnum } from '@tonkean/utils';

const LeftPageWrapper = styled.div`
    margin-right: 33px;
`;

const WebhookPageField = styled(Field)`
    margin-bottom: 40px;
`;

const TabWrapper = styled.div`
    margin-top: 20px;
`;

const validationSchema = singleHandleResponseSchema.concat(
    Yup.object({}).shape({
        webhookFilter: Yup.object({
            shouldAcceptAll: Yup.boolean().required(),
            queryDefinition: Yup.object().nullable(),
        }),
        fetchEntity: yupEnum(WebhookPayloadHandlingType).required(),
    }),
);

const webhookConstantParameters: Set<
    ProjectIntegrationActionConstantParams | ProjectIntegrationPaginatedActionConstantParams
> = new Set([ProjectIntegrationActionConstantParams.TODAY]);

const ProjectIntegrationEntityLiveStreamPage: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>
> = ({ entity, projectIntegration, entitiesSummary, setEntity, setOnExitTab, tabKeyToOnExit, ...props }) => {
    const [selectedWebhookPayload, setSelectedWebhookPayload] = useState<WebhookPayload | undefined>(undefined);

    const formRef = useRef<FormikProps<any>>(null);

    const [
        { loading: isUpdateWebhookLoading, error: updateWebhookError },
        updateProjectIntegrationEntityWebhookDefinition,
    ] = useLazyTonkeanService('updateProjectIntegrationEntityWebhookDefinition');

    const {
        data: webhookPayloads,
        error: webhookPayloadsError,
        loading: webhookPayloadsLoading,
    } = useTonkeanService('getProjectIntegrationWebhookPayloads', projectIntegration.id);

    const webhookTab = useWebhookPayloadAdditionalTab();

    const enterpriseComponentVariablesTab = useEnterpriseComponentVariablesExpressionTab(
        entity.projectId,
        projectIntegration.id,
        projectIntegration.id,
    );

    const customActionParamsTab = useMemo<TonkeanExpressionAdditionalTab>(
        () => ({
            id: 'CONSTANT_PARAMS',
            label: 'Constant Params',
            iconClass: 'mod-columns',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: () => {
                const constantActionConfigs = Object.values(projectIntegrationActionConstantParamsIdToConfig).filter(
                    (param) => webhookConstantParameters.has(param.id),
                );
                return Promise.resolve(
                    constantActionConfigs.map((param) => ({
                        name: param.id,
                        value: param.id,
                        label: param.displayName,
                        id: param.id,
                    })),
                );
            },
        }),
        [],
    );

    const { confirm } = useCreateDialog();

    const additionalTabs = useMemo(
        () => [webhookTab, enterpriseComponentVariablesTab, customActionParamsTab],
        [enterpriseComponentVariablesTab, webhookTab, customActionParamsTab],
    );

    // Choose first one in default when we receive webhook payloads result
    useEffect(() => {
        if (!webhookPayloads?.payloads) {
            return;
        }

        setSelectedWebhookPayload(webhookPayloads.payloads[0]);
    }, [webhookPayloads?.payloads]);

    const onSubmit = async (values: FormikValues) => {
        setEntity(
            await updateProjectIntegrationEntityWebhookDefinition(
                entity.projectIntegrationId,
                entity.id,
                values.webhookFilter.queryDefinition,
                values.handleResponse,
                values.webhookFilter.shouldAcceptAll,
            ),
        );
        formRef?.current?.resetForm(values);
    };

    const formikInitialValues = {
        webhookFilter: {
            shouldAcceptAll: entity.entityWebhookDefinition?.shouldAcceptAll ?? true,
            queryDefinition: entity.entityWebhookDefinition?.queryDefinition || {
                query: {
                    type: FieldQueryType.And,
                    filters: [],
                    queries: [],
                },
            },
        },
        fetchEntity: entity.entityWebhookDefinition?.fetchingMethod || WebhookPayloadHandlingType.ID,
        handleResponse: entity.entityWebhookDefinition?.externalActivityResponseHandlingDefinition || {
            entity: { ...defaultTonkeanExpression },
            id: { ...defaultTonkeanExpression },
            displayName: { ...defaultTonkeanExpression },
            updatedDate: { ...defaultTonkeanExpression },
            createdDate: { ...defaultTonkeanExpression },
            entityUrl: { ...defaultTonkeanExpression },
            responseHandlingType:
                entity.entityWebhookDefinition?.externalActivityResponseHandlingDefinition?.responseHandlingType ||
                EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES,
            validationStatus: {
                outputFields: [],
                isValid: false,
            },
        },
    };

    // Set the onExitTab callback for a tab based on its dirty state.
    useEffect(() => {
        if (setOnExitTab) {
            setOnExitTab(async () => {
                return formRef?.current?.dirty
                    ? await confirm('You have unsaved changes', 'Are you sure you want to discard those changes?', {
                          warning: true,
                          okLabel: 'Yes',
                      })
                    : true;
            });
        }
    }, [formRef?.current?.dirty, confirm, setOnExitTab]);

    return (
        <SplitPageTemplate
            breadcrumbs={
                <ProjectIntegrationEntityHeaderComponent
                    entity={entity}
                    setEntity={setEntity}
                    projectIntegration={projectIntegration}
                    entitiesSummary={entitiesSummary}
                    defaultMargin={false}
                    tabKeyToOnExit={tabKeyToOnExit}
                    {...props}
                />
            }
            actions={
                <Button
                    disabled={isUpdateWebhookLoading}
                    size={ButtonSize.MEDIUM}
                    onClick={() => {
                        if (formRef.current) {
                            formRef.current?.submitForm();
                        }
                    }}
                >
                    Save
                </Button>
            }
            rightBody={
                webhookPayloadsError ? (
                    <ErrorMessage>Can't get webhook payloads from server.</ErrorMessage>
                ) : (
                    <ProjectIntegrationEntityLiveStreamRightLayout
                        entity={entity}
                        webhookPayloads={webhookPayloads?.payloads}
                        isLoadingWebhookPayloads={webhookPayloadsLoading}
                        selectedWebhookPayload={selectedWebhookPayload}
                        setSelectedWebhookPayload={setSelectedWebhookPayload}
                        webhookPayloadHandlingType={formRef?.current?.values?.handleResponse?.responseHandlingType}
                        handleResponseDefinition={formRef?.current?.values?.handleResponse}
                    />
                )
            }
            name="Webhook"
            title={
                <ProjectIntegrationEntityLeftPageGenericHeader
                    title="Webhook"
                    description="Configure a webhook to allow a third-party application to send events to Tonkean, providing real-time data."
                    error={updateWebhookError}
                    isLoading={isUpdateWebhookLoading}
                />
            }
        >
            <LeftPageWrapper>
                <Formik
                    innerRef={formRef}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    initialValues={formikInitialValues}
                    enableReinitialize
                >
                    {({ values, setFieldValue }) => {
                        return (
                            <>
                                <ProjectIntegrationEntityLiveStreamWebhookUrl
                                    projectIntegration={projectIntegration}
                                    entity={entity}
                                />

                                <WebhookPageField label={<H4 $bold>Which webhook should be processed</H4>}>
                                    <ProjectIntegrationEntityLiveStreamFilterPayloadRadio
                                        name="webhookFilter"
                                        projectIntegrationId={projectIntegration.id}
                                    />
                                </WebhookPageField>

                                <WebhookPageField label={<H4 $bold>Fetch entity</H4>}>
                                    <ProjectIntegrationEntityLiveStreamWebhookFilter namePrefix="handleResponse" />
                                </WebhookPageField>

                                <TabWrapper>
                                    <ProjectIntegrationHandleResponse
                                        projectIntegrationEntity={entity}
                                        expressionProps={{
                                            globalExpressionOnly: true,
                                            doNotEvaluatePreview: true,
                                            additionalTabs,
                                            hideEditorButton: true,
                                        }}
                                        labelPrefix="Entity"
                                        namePrefix="handleResponse"
                                        validateActionHandleType={ValidateActionHandleType.WEBHOOK_PAYLOAD_ID}
                                        validationId={selectedWebhookPayload?.id}
                                        projectIntegration={projectIntegration}
                                        showFetchEntity={false}
                                        entitiesSummary={entitiesSummary}
                                        handleResponseActionType={HandleResponseActionType.WEBHOOK}
                                    />
                                </TabWrapper>
                            </>
                        );
                    }}
                </Formik>
            </LeftPageWrapper>
        </SplitPageTemplate>
    );
};

export default ProjectIntegrationEntityLiveStreamPage;
