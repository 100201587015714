import { lateConstructController } from '@tonkean/angular-components';

/**
 * This component represents email configuration for Email Configuration option,
 * In this component there are 2 main inputs: Subject (Expression) & Body (Expression / HTML editor).
 */
/* @ngInject */
function EmailConfigurationCtrl($scope, customTriggerManager, modal) {
    const ctrl = this;
    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        // If the subject is empty, we use the body as subject by default.
        savedOriginalSubjectExpression: ctrl.savedOriginalSubjectExpression || ctrl.savedOriginalBodyExpression,
        savedEvaluatedSubjectExpression: ctrl.savedEvaluatedSubjectExpression || ctrl.savedEvaluatedBodyExpression,

        savedOriginalBodyExpression: ctrl.savedOriginalBodyExpression,
        savedEvaluatedBodyExpression: ctrl.savedEvaluatedBodyExpression,

        sendAsPlainText: ctrl.sendAsPlainText,
    };

    $scope.onEmailSubjectTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        if (ctrl.onEmailSubjectTonkeanExpressionChanged) {
            ctrl.onEmailSubjectTonkeanExpressionChanged({ originalExpression, evaluatedExpression, shouldSaveLogic });
        }
    };

    $scope.onEmailBodyTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        if (ctrl.onEmailBodyTonkeanExpressionChanged) {
            ctrl.onEmailBodyTonkeanExpressionChanged({ originalExpression, evaluatedExpression, shouldSaveLogic });
        }
    };

    $scope.onSendAsPlainTextChanged = function (shouldSendAsPlainText) {
        $scope.data.sendAsPlainText = shouldSendAsPlainText;
        ctrl.onSendAsPlainTextChanged({ shouldSendAsPlainText });
    };

    $scope.openHtmlEditor = function () {
        let exampleInitiative = null;
        if (customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId]) {
            exampleInitiative =
                customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][
                    $scope.data.configuredLogic.node.id
                ];
        }

        modal
            .openHtmlEditor(
                $scope.data.groupId,
                $scope.data.savedOriginalBodyExpression,
                exampleInitiative,
                false,
                $scope.data.configuredLogic.node.id,
                $scope.data.workflowVersionId,
            )
            .then((result) => onHtmlEditorContentTonkeanExpressionChanged(result.html, result.evaluatedHtml, true));
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    function onHtmlEditorContentTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.savedOriginalBodyExpression = originalExpression;
        $scope.data.savedEvaluatedBodyExpression = evaluatedExpression;
        $scope.onEmailBodyTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic);
    }
}

export default angular.module('tonkean.app').controller('EmailConfigurationCtrl', lateConstructController(EmailConfigurationCtrl));
