/**
 * Created by ShaLi on 9/24/15.
 */

function QuestionActivityController($scope, $state) {
    $scope.inquiry = $scope.item.reference1;

    if (!$scope.inquiry.questions[0].answer) {
        $scope.title = 'You have a pending question!';
    } else if (!$scope.inquiry.insight) {
        $scope.title = 'You have a stopped in the middle!';
    }

    $scope.onAnswerClicked = function (statement) {
        if (!$scope.postingAnswer) {
            $scope.postingAnswerId = statement.id;

            $scope.pm
                .answerQuestion($scope.openQuestion.id, statement.id)
                .then(function (data) {
                    $state.go('question', {
                        projectId: $scope.pm.project.id,
                        inquiry: $scope.openQuestion.inquiry.id,
                        inquiryData: data,
                    });
                })
                .catch(function () {
                    $scope.$emit('alert', 'Failed to post answer.');
                })
                .finally(function () {
                    $scope.postingAnswer = false;
                });
        }
    };

    function setQuestionData() {
        $scope.firstQuestionHeader = $scope.inquiry.firstQuestion;
        if ($scope.inquiry.questions && $scope.inquiry.questions.length > 0) {
            $scope.openQuestion = $scope.inquiry.questions[0];
            if ($scope.openQuestion.answer && !$scope.insight) {
                $scope.questionIncomplete = true;
            }
        }
    }

    setQuestionData();
}
angular.module('tonkean.app').controller('QuestionActivityController', QuestionActivityController);
