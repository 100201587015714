<div class="trigger-gallery-page container mod-main">
    <div class="trigger-gallery-content">
        <!-- Gallery -->
        <div ng-show="data.currentStep === steps.gallery">
            <!-- Title -->
            <div class="common-title mod-light">{{ data.title || 'Automate your work' }}</div>
            <div class="common-title-xxs mod-light mod-grey3">
                With Automation Triggers, you can train the Tonkean A.I. Module to proactively and smartly monitor your
                data and alert the team to take action.
                <br />
                Here are few pre-built examples to start from:
            </div>

            <!-- Search bar -->
            <div class="margin-top-lg">
                <input
                    type="text"
                    placeholder="Search triggers"
                    class="form-control trigger-gallery-search"
                    ng-model="data.searchTriggersNameText"
                />
            </div>
            <div class="flex-vmiddle flex-wrap margin-top common-color-grey">
                <span class="margin-right">Filter by:</span>
                <span class="trigger-gallery-tag pointer" ng-click="data.filterCategory = null">
                    <span ng-class="{ 'common-tag-full': !data.filterCategory }">All</span>
                </span>
                <span
                    ng-repeat="(category,x) in data.allCategories"
                    class="trigger-gallery-tag pointer"
                    ng-click="data.filterCategory = category"
                >
                    <span ng-class="{ 'common-tag-full': data.filterCategory === category }">{{ category }}</span>
                </span>
            </div>

            <div
                ng-if="data.searchTriggersNameText && data.searchTriggersNameText.length"
                class="
                    alert alert-info
                    common-size-xxxxs common-width-50
                    padding-normal-sm
                    margin-bottom-lg margin-top-xs
                    flex-vmiddle
                "
            >
                <span class="flex-grow">Showing triggers related to "{{ data.searchTriggersNameText }}".</span>
            </div>

            <!-- Trigger boxes -->
            <div class="trigger-gallery-box-container flex mod-align-start flex-wrap">
                <div
                    ng-repeat="triggerInfo in data.triggersInfos | filter:data.searchTriggersNameText"
                    ng-click="goToTriggerStep(triggerInfo.id)"
                    ng-if="
                        !data.filterCategory ||
                        (triggerInfo.categories && triggerInfo.categories.indexOf(data.filterCategory) > -1)
                    "
                    class="trigger-gallery-box"
                >
                    <div class="common-title-xs">{{ triggerInfo.title }}</div>
                    <div class="common-title-xs mod-light" style="min-height: 90px">
                        {{ triggerInfo.shortDescription }}
                    </div>
                    <div class="margin-top margin-bottom flex-vmiddle-no-xs" ng-if="triggerInfo.dataSources">
                        <div class="flex-vmiddle flex-xs-wrap">
                            <!-- Integrations icons -->
                            <span
                                ng-repeat="(key, source) in triggerInfo.dataSources"
                                class="trigger-gallery-icon-container mod-margin-left-minus"
                                ng-style="{ zIndex: 100 - $index }"
                            >
                                <span
                                    ng-if="!source.iconUrl"
                                    class="inline-block big-icon {{ source.iconClass }}"
                                ></span>
                                <img
                                    ng-if="source.iconUrl"
                                    ng-src="{{ source.iconUrl }}"
                                    alt="{{ source.name }}"
                                    class="inline-block big-icon {{ source.iconClass }}"
                                />
                            </span>
                            <!-- "More" label (when there are integrations) -->
                            <span
                                class="trigger-gallery-icon-container mod-wide mod-margin-left-minus"
                                ng-if="triggerInfo.integrationTypes.length"
                                ng-style="{ zIndex: 2 }"
                            >
                                <span
                                    ng-if="!source.iconUrl"
                                    class="inline-block margin-right-xs margin-left padding-top common-color-grey3 {{
                                        source.iconClass
                                    }}"
                                >
                                    + more
                                </span>
                            </span>
                            <!-- Tonkean logo (when there are no integrations) -->
                            <span
                                class="
                                    trigger-gallery-icon-container
                                    mod-margin-left-minus
                                    flex-vmiddle
                                    mod-justify-center
                                "
                                ng-if="!triggerInfo.integrationTypes.length"
                            >
                                <span class="svg-icon svg-icon-xlg mod-static margin-left-xs margin-bottom-xs">
                                    <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                                </span>
                            </span>
                            <!-- Arrow -->
                            <span class="svg-icon padding-top-xs">
                                <tnk-icon src="/images/icons/arrow-right-lg.svg"></tnk-icon>
                            </span>
                            <!-- Avatars -->
                            <span class="trigger-gallery-icon-container" ng-style="{ zIndex: 1 }">
                                <img
                                    class="inline-block avatar big-icon {{ source.iconClass }}"
                                    ng-src="/images/avatars/avatar-{{ ($index % 7) + 1 }}.png"
                                />
                            </span>
                            <!-- Pencil -->
                            <span
                                class="
                                    trigger-gallery-icon-container
                                    mod-margin-left-minus
                                    flex-vmiddle
                                    mod-justify-center
                                "
                            >
                                <span
                                    class="svg-icon-nav-sm svg-icon-hover-black pointer inline-block common-color-grey3"
                                >
                                    <tnk-icon src="/images/icons/pencil-o.svg"></tnk-icon>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Trigger configuration -->
        <div ng-if="data.currentStep === steps.triggerConfig">
            <!-- Back -->
            <div class="margin-bottom-lg common-color-grey pointer" ng-click="goToGalleryStep()">
                <i class="fa fa-chevron-left margin-right"></i>
                <span>Back</span>
            </div>
            <div class="flex mod-align-start">
                <!-- Left part -->
                <div class="flex-grow selected-trigger-left-pane">
                    <!-- Title -->
                    <div class="common-title-md" id="trigger-title">{{ data.currentTrigger.title }}</div>
                    <div class="common-title-xs mod-light">{{ data.currentTrigger.shortDescription }}</div>
                    <!-- Categories -->
                    <div class="flex-vmiddle margin-top-xs">
                        <span ng-repeat="category in data.currentTrigger.categories" class="trigger-gallery-tag">
                            <span>{{ category }}</span>
                        </span>
                    </div>

                    <!-- Used data sources -->
                    <div
                        ng-if="
                            data.currentTriggerInfo.integrationTypes && data.currentTriggerInfo.integrationTypes.length
                        "
                        class="margin-top-xlg"
                    >
                        <div class="common-title-xxs">Data sources used in this Pre-Built Trigger:</div>
                        <div class="flex-vmiddle flex-xs-wrap margin-top">
                            <div
                                ng-repeat="(key, source) in data.currentTriggerInfo.dataSources"
                                uib-dropdown
                                on-toggle="toggled(open)"
                                uib-dropdown-toggle
                                class="dropdown trigger-gallery-data-source flex-vmiddle"
                            >
                                <div class="dropdown-toggle trigger-gallery-data-source-toggle flex-vmiddle">
                                    <span class="margin-right-xs capital-first-letter">{{ source.name }}</span>
                                    <span class="trigger-gallery-data-source-separator"></span>
                                    <span
                                        ng-if="!source.iconUrl"
                                        class="inline-block margin-right-xs mod-normal {{ source.iconClass }}"
                                    ></span>
                                    <img
                                        ng-if="source.iconUrl"
                                        ng-src="{{ source.iconUrl }}"
                                        alt="{{ source.name }}"
                                        class="inline-block margin-right-xs mod-normal {{ source.iconClass }}"
                                    />
                                </div>

                                <!-- Optional integrations dropdown -->
                                <ul uib-dropdown-menu class="dropdown-menu" role="menu">
                                    <!-- Search Box -->
                                    <div class="padding-left-lg margin-left-xs padding-bottom-xs flex-vmiddle">
                                        <span class="svg-icon-sm">
                                            <tnk-icon src="/images/icons/search.svg"></tnk-icon>
                                        </span>
                                        <input
                                            ng-click="utils.eventStopPropagation($event)"
                                            type="text"
                                            class="
                                                form-control
                                                inline-block
                                                common-input-only-line
                                                margin-left-xs margin-right
                                            "
                                            placeholder="Search an integration"
                                            ng-model="data.searchIntegrationNameText"
                                        />
                                    </div>
                                    <!-- Existing Integrations-->
                                    <li
                                        class="common-size-xxs"
                                        ng-repeat="projectIntegration in pm.project.integrations | filter:isNotCommunicationIntegration | filter:data.searchIntegrationNameText"
                                    >
                                        <a
                                            ng-click="selectIntegration(projectIntegration, source)"
                                            class="common-width-full"
                                        >
                                            <span class="flex-vmiddle">
                                                <span
                                                    class="inline-block margin-right-xs mod-normal initiative-integration-icon mod-{{
                                                        projectIntegration.integration.integrationType.toLowerCase()
                                                    }}"
                                                ></span>
                                                <span class="trigger-gallery-data-source-separator"></span>
                                                <span class="margin-right-xs">
                                                    {{ projectIntegration.name }} (configured)
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                    <li
                                        ng-if="
                                            data.searchIntegrationNameText &&
                                            data.searchIntegrationNameText.length &&
                                            !filteredSources.length &&
                                            !filteredWebhookSources.length
                                        "
                                        class="
                                            flex-vmiddle
                                            padding-right padding-left
                                            common-color-grey common-size-xxs
                                        "
                                    >
                                        <p>
                                            No native integrations found for that search, but you can always use a
                                            Custom Data Source!
                                            <br />
                                        </p>
                                    </li>
                                    <!-- Source Integrations-->
                                    <li
                                        class="flex-vmiddle common-size-xxs"
                                        ng-repeat="integrationSource in filteredSources = (data.sources | filter:data.searchIntegrationNameText)"
                                    >
                                        <a
                                            ng-click="selectIntegrationByName(integrationSource, source)"
                                            class="common-width-full"
                                        >
                                            <span class="flex-vmiddle">
                                                <span
                                                    class="inline-block margin-right-xs mod-normal initiative-integration-icon mod-{{
                                                        integrationSource.toLowerCase()
                                                    }}"
                                                ></span>
                                                <span class="trigger-gallery-data-source-separator"></span>
                                                <span class="margin-right-xs capital-first-letter">
                                                    {{ integrationSource }}
                                                </span>
                                            </span>
                                        </a>
                                    </li>

                                    <!-- Webhook Integrations-->
                                    <li
                                        class="flex-vmiddle common-size-xxs"
                                        ng-repeat="webhookSource in filteredWebhookSources = (data.webhooksSources | filter:data.searchIntegrationNameText | limitTo:data.webhookSourcesLimit)"
                                    >
                                        <a
                                            ng-click="selectWebhookIntegration(webhookSource, source)"
                                            class="common-width-full"
                                        >
                                            <span class="flex-vmiddle">
                                                <img
                                                    ng-if="webhookSource.imgUrl"
                                                    ng-src="{{ webhookSource.imgUrl }}"
                                                    alt="{{ webhookSource.name }}"
                                                    class="
                                                        inline-block
                                                        margin-right-xs
                                                        mod-normal
                                                        initiative-integration-icon
                                                    "
                                                />
                                                <span class="trigger-gallery-data-source-separator"></span>
                                                <span class="margin-right-xs capital-first-letter">
                                                    {{ webhookSource.name }}
                                                </span>
                                            </span>
                                        </a>
                                    </li>

                                    <!-- Load More Integrations-->
                                    <li
                                        ng-if="
                                            data.webhookSourcesLimit < data.webhooksSources.length &&
                                            (!data.searchIntegrationNameText || !data.searchIntegrationNameText.length)
                                        "
                                        class="margin-top margin-bottom common-size-xxs"
                                    >
                                        <a ng-click="loadMoreIntegrations($event)">Load more...</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="margin-top common-color-grey3">Can work with any other data source.</div>
                    </div>

                    <!-- Separator -->
                    <hr class="common-separator mod-darker margin-top-xlg margin-bottom-lg" />

                    <!-- Trigger query and actions -->
                    <div>
                        <!-- Trigger query -->
                        <div class="flex-no-xs mod-align-baseline">
                            <div class="common-title-xxs margin-right-xlg">Criteria</div>
                            <div class="margin-xs-top-xs">
                                <div
                                    ng-include="'trigger-gallery-filter-template'"
                                    ng-init="query = data.currentTrigger.query"
                                ></div>
                            </div>
                        </div>

                        <!-- Trigger actions -->
                        <div class="flex-no-xs mod-align-start margin-top-xlg" ng-if="data.currentTrigger.actions">
                            <div class="common-title-xxs margin-right-xlg">Action</div>
                            <div class="margin-xs-top-xs">
                                <!-- Ask / Notify owner -->
                                <div ng-if="data.currentTrigger.actions['ASK']" class="flex-vmiddle">
                                    <span
                                        class="
                                            svg-icon-xxs svg-icon-vmiddle
                                            flex-no-shrink
                                            common-color-link-blue
                                            margin-right-md
                                        "
                                    >
                                        <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                    </span>
                                    <span>Ask Owner for an update</span>
                                </div>
                                <!-- Post / Notify channel -->
                                <div
                                    ng-if="data.currentTrigger.actions['NOTIFY_CHANNEL']"
                                    class="flex-vmiddle margin-top"
                                >
                                    <span
                                        class="
                                            svg-icon-xxs svg-icon-vmiddle
                                            flex-no-shrink
                                            common-color-link-blue
                                            margin-right-md
                                        "
                                    >
                                        <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                    </span>
                                    <span>Post to channel</span>
                                </div>
                                <!-- Post / Notify channel -->
                                <div ng-if="data.currentTrigger.actions['UPDATE']" class="flex margin-top">
                                    <span
                                        class="
                                            svg-icon-xxs svg-icon-vmiddle
                                            flex-no-shrink
                                            common-color-link-blue
                                            margin-right-md margin-top-xs
                                        "
                                    >
                                        <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                                    </span>
                                    <div>
                                        <p>Update Fields:</p>
                                        <div
                                            ng-repeat="updateField in data.currentTrigger.actions['UPDATE']"
                                            class="flex-vmiddle margin-top-xs"
                                        >
                                            <!-- Field -->
                                            <div
                                                class="trigger-gallery-grey-box"
                                                ng-init="source = updateField.integrationType ? data.currentTriggerInfo.dataSources[updateField.integrationType]: null"
                                            >
                                                <span
                                                    ng-if="!source.iconUrl"
                                                    class="inline-block margin-right-xs mod-normal {{
                                                        source.iconClass
                                                    }}"
                                                ></span>
                                                <img
                                                    ng-if="source.iconUrl"
                                                    ng-src="{{ source.iconUrl }}"
                                                    alt="{{ source.name }}"
                                                    class="inline-block margin-right-xs common-circle mod-normal {{
                                                        source.iconClass
                                                    }}"
                                                />

                                                {{ updateField.fieldLabel || updateField.fieldName }}
                                            </div>
                                            <i
                                                class="
                                                    fa fa-arrow-right
                                                    common-color-grey common-size-xxxs
                                                    margin-right-xs
                                                "
                                            ></i>
                                            <!-- Value -->
                                            <div class="trigger-gallery-grey-box">{{ updateField.value }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Use trigger button -->
                        <div class="margin-top-xlg padding-top-lg padding-bottom-lg">
                            <button type="button" class="btn btn-lg btn-primary" ng-click="useTrigger()">
                                {{ data.btnLabel }}
                            </button>
                        </div>

                        <!-- Separator -->
                        <hr class="common-separator mod-darker margin-top-xlg margin-bottom-lg" />

                        <!-- Footer -->
                        <div class="flex-no-xs mod-align-start">
                            <!-- Works well with... -->
                            <div class="flex-grow">
                                <div
                                    ng-if="data.currentTrigger.relatedLists && data.currentTrigger.relatedLists.length"
                                >
                                    <div class="common-bold">Works well with the Lists:</div>
                                    <div
                                        ng-repeat="relatedListId in data.currentTrigger.relatedLists"
                                        class="margin-top"
                                    >
                                        <a ng-click="goToBuiltInList(relatedListId)" class="common-link-blue">
                                            {{ data.builtInLists[relatedListId].title }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- Created by -->
                            <div
                                class="flex-no-shrink flex mod-align-start margin-xs-top-lg"
                                ng-if="data.currentTrigger.creator"
                            >
                                <div class="avatar avatar-main flex-no-shrink margin-right">
                                    <img
                                        ng-src="{{ data.currentTrigger.creator.avatarUri }}"
                                        class="avatar-img img-circle"
                                    />
                                </div>
                                <div>
                                    <div>Made by {{ data.currentTrigger.creator.name }}</div>
                                    <div class="common-color-grey">{{ data.currentTrigger.creator.company }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Right pane -->
                <div class="flex-no-shrink selected-trigger-right-pane hidden-xs">
                    <div class="selected-trigger-right-pane-box">
                        <!-- Title and long description -->
                        <div class="common-bold">Benefits:</div>
                        <div class="margin-top-xs" ng-bind-html="data.currentTrigger.longDescription"></div>
                        <!-- Works well with lists... -->
                        <div class="margin-top-lg">
                            <div class="common-bold">Works well with the Lists:</div>
                            <div class="margin-top-xs flex-vmiddle flex-wrap">
                                <span
                                    ng-repeat="relatedListId in data.currentTrigger.relatedLists"
                                    class="trigger-gallery-tag margin-bottom-xs"
                                >
                                    <a ng-click="goToBuiltInList(relatedListId)" class="common-link-blue">
                                        {{ data.builtInLists[relatedListId].title }}
                                    </a>
                                </span>
                            </div>
                        </div>
                        <button type="button" class="btn btn-lg btn-primary margin-top" ng-click="useTrigger()">
                            {{ data.btnLabel }}
                        </button>
                    </div>
                    <div class="selected-trigger-right-pane-box margin-top-md">
                        <!-- Title -->
                        <div class="common-bold">A.I. Module Message Preview</div>
                        <div>This is how the message sent to the owner will look like</div>
                        <!-- Preview box -->
                        <div class="margin-top">
                            <tnk-preview-bot-bubble-ctrl
                                class="hidden-xs"
                                text="data.currentTrigger.botPreview.message"
                                is-email="!data.projectHasCommunication"
                                user="as.currentUser"
                                preview-type="trigger"
                                entity-name="data.currentTrigger.botPreview.entity"
                                no-triangle="true"
                            ></tnk-preview-bot-bubble-ctrl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Trigger gallery filter template -->
<script type="text/ng-template" id="trigger-gallery-filter-template">
    <!-- Query type -->
    <div class="margin-bottom-xs common-size-xxs flex-vmiddle">

        <!-- IF -->
        <span class="margin-right-xs">If</span>

        <!-- All/Any -->
        <span class="flex-no-shrink margin-right-xs flex-vmiddle common-color-link-blue">
            {{(query.type === 'And' ? 'all' : 'at lease one' ) | lowercase}}
        </span>

        <!-- Of these... -->
        <span>of these conditions are met:</span>
    </div>

    <!-- Existing filters and adding filters -->
    <div class="relative">
        <div class="flex-vmiddle trigger-gallery-filter-row flex-xs-wrap" ng-repeat="filter in query.filters">
            <!-- Field -->
            <div class="trigger-gallery-grey-box mod-min-width" ng-init="source = filter.integrationType ? data.currentTriggerInfo.dataSources[filter.integrationType]: null">
                <span ng-if="!source.iconUrl" class="inline-block margin-right-xs mod-normal {{source.iconClass}}"></span>
                <img ng-if="source.iconUrl" ng-src="{{source.iconUrl}}" alt="{{source.name}}"
                     class="inline-block margin-right-xs common-circle mod-normal {{source.iconClass}}"/>

                {{filter.fieldLabel}}
            </div>

            <!-- Condition -->
            <div class="trigger-gallery-grey-box">
                {{data.fieldConditionToApiDefinitionMap[filter.condition].displayName}}
            </div>

            <!-- Value -->
            <div class="trigger-gallery-grey-box">
                {{filter.value}}
            </div>

            <!-- Condition Suffix -->
            <div class="trigger-gallery-grey-box"
                 ng-if="data.fieldConditionToApiDefinitionMap[filter.condition].inputDescriptionString">
                {{data.fieldConditionToApiDefinitionMap[filter.condition].inputDescriptionString}}
            </div>
        </div>
    </div>

    <!-- Inner queries -->
    <div ng-if="query.queries && query.queries.length" class="trigger-gallery-filter-inner-query">
        <div ng-repeat="query in innerQueries = query.queries" ng-include="'trigger-gallery-filter-template'">
        </div>
    </div>
</script>
