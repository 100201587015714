import React from 'react';
import styled from 'styled-components';

import type { ProjectIntegrationEntityForView } from './ProjectIntegrationEntityForView';
import { ReactComponent as SingleEntityIcon } from '../../../../../images/icons/settings-gray-14.svg';
import { ProjectIntegrationEntityTabKey } from '../../../../components/state.product.projectIntegrationPageEntity/ProjectIntegrationEntityTabKey';

import { TextEllipsis } from '@tonkean/infrastructure';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const EntitiesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 750px;
    overflow: auto;
    max-height: 50vh;

    a:first-child {
        border-top: 1px solid ${Theme.colors.gray_300};
    }

    ${childrenStyledFocus}
`;

const SingleEntityRow = styled(Clickable)`
    background-color: transparent;
    line-height: 43px;
    height: 43px;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
    border-bottom: 1px solid ${Theme.colors.gray_300};

    display: flex;
    align-items: center;

    &:hover {
        background-color: #f9f9f7;
    }
`;

const EditChevron = styled.i`
    margin-left: auto;
    margin-right: 10px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
`;

const StyledSingleEntityIcon = styled(SingleEntityIcon)`
    margin: 0 10px;
`;

const EntityNameLabel = styled(TextEllipsis)`
    font-style: normal;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    flex-grow: 1;
`;

const EntitiesListHeader = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: ${FontSize.XSMALL_10};
    font-weight: 500;
    color: ${Theme.current.palette.mainColors.gray_600};
`;

interface Props {
    entities: ProjectIntegrationEntityForView[] | undefined;
    projectIntegrationId: string;
    header: string;
}

const ProjectIntegrationEntitiesList: React.FC<Props> = ({ entities, projectIntegrationId, header }) => {
    return (
        <>
            {entities && entities.length > 0 && <EntitiesListHeader>{header}</EntitiesListHeader>}

            <EntitiesWrapper>
                {entities?.map((entity) => (
                    <SingleEntityRow
                        key={entity.id}
                        state="product.projectIntegrationPageEntity"
                        params={{
                            enterpriseComponentId: projectIntegrationId,
                            entityId: entity.id,
                            tab: ProjectIntegrationEntityTabKey.GENERAL,
                        }}
                        disabled={!entity.isClickable}
                    >
                        <StyledSingleEntityIcon />

                        <EntityNameLabel>{entity.displayName}</EntityNameLabel>
                        {entity.isClickable && <EditChevron className="fa fa-chevron-right" />}
                    </SingleEntityRow>
                ))}
            </EntitiesWrapper>
        </>
    );
};

export default ProjectIntegrationEntitiesList;
