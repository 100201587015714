/**
 * A directive that enables adding a title to a typeahead popup.
 * Note: this directive has no scope, so it can work with other directive that have a scope (such as mentio, etc...).
 * (angularjs doesn't allow multiple isolated scopes on the same element).
 * This directive also does not set a template, it simply adds a typeaheadPopupTitle property to your scope, which is then looked by
 * the typeaheadPopupTitle.html template. Use this template by also adding to your element the following attribute:
 * typeahead-popup-template-url="app/components/common/typeaheadPopupTitle/typeaheadPopupTitle.template.html"
 *
 * This was done to keep this template lightweight while still making it relatively easy to add a title to the typeahead popup.
 */
function typeaheadPopupTitle() {
    return {
        restrict: 'A',
        link(scope, element, attrs) {
            // Get the title the user has set.
            scope.typeaheadPopupTitle = attrs.typeaheadPopupTitle;
        },
    };
}
angular.module('tonkean.app').directive('typeaheadPopupTitle', typeaheadPopupTitle);
