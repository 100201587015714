import SearchWidget from './components/SearchWidget';
import SearchWidgetConfigurationEditor from './components/SearchWidgetConfigurationEditor';
import type SearchWidgetConfiguration from './SearchWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const SearchWidgetPackage: ItemInterfaceWidgetPackage<SearchWidgetConfiguration> = {
    displayComponent: SearchWidget,
    configurationComponent: SearchWidgetConfigurationEditor,
};

export default SearchWidgetPackage;
