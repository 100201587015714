<div class="invite-modal common-close-btn-container">
    <!-- Close button -->
    <div class="common-close-btn" ng-hide="inviting" ng-click="cancel()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </div>

    <!-- Sending loading circle -->
    <div class="invite-inviting text-center" ng-show="inviting">
        <i class="loading-large"></i>

        <h3>Sending...</h3>
    </div>

    <!-- Content -->
    <form name="inviteForm" novalidate ng-submit="inviteForm.$valid && submit()" ng-hide="inviting" ng-disabled="data.submitDisabled">
        <!-- Invite mode -->
        <div ng-if="!isIntro">
            <!-- Title -->
            <div class="common-title-lg text-center margin-top-lg">
                <div class="svg-icon tonkean-inline-logo mod-static relative common-color-black">
                    <tnk-icon src="../../../../apps/tracks/images/icons/tonkean-logo.svg"></tnk-icon>
                </div>
                Invite to {{ pm.project.name || 'Tonkean' }}
            </div>

            <div class="margin-top-xlg">
                <!-- People selector (when in invite mode) -->
                <div>
                    <div class="common-size-xxs common-bold margin-bottom-xs">Invite people</div>
                    <tnk-deprecated-people-selector
                        class="common-width-full margin-top-xlg"
                        no-validation="false"
                        selected-people="invitees"
                        no-custom="false"
                    ></tnk-deprecated-people-selector>
                </div>

                <!-- Private groups selector -->
                <div class="margin-top-lg group-selector padding-top common-size-xxs">
                    <div class="common-bold margin-bottom-xs">Grant them access to the following modules:</div>
                    <tags-input
                        class="tnk-selector common-no-outline tnk-selector"
                        template="invite-group-selected-template"
                        template-scope="this"
                        ng-model="data.selectedPrivateGroups"
                        on-tag-adding="isGroupAllowed($tag)"
                        on-tag-removing="isNotAllPublicListsPlaceholder($tag)"
                        display-property="name"
                        key-property="id"
                        text="data.latestGroupSearch"
                        placeholder="Select private modules..."
                        add-from-autocomplete-only="true"
                        allow-leftover-text="false"
                        replace-spaces-with-dashes="false"
                    >
                        <auto-complete
                            source="filterPrivateGroups($query)"
                            template="invite-group-autocomplete-template"
                            min-length="0"
                            max-results-to-show="250"
                            highlight-matched-text="false"
                            load-on-focus="true"
                            load-on-empty="true"
                            match-class="{'invite-groups-dummy-placeholder': $match.isDummyPlaceholder, 'invite-groups-not-allowed': $match.isNotAllowedToInvite}"
                        ></auto-complete>
                        <i class="loading mod-tags-input" ng-show="loadingTags"></i>
                    </tags-input>
                </div>

                <!-- Invite message -->
                <div class="invite-add-message-container margin-top-lg padding-top">
                    <!-- Add personalized message button -->
                    <div class="text-left padding-top-xs" ng-hide="showCustomInviteMessage">
                        <a
                            href=""
                            ng-click="showCustomInviteMessage = true;"
                            class="common-size-xxxxs common-color-grey common-underline"
                        >
                            Add a personalized message
                        </a>
                    </div>

                    <!-- Message text -->
                    <div class="margin-top" ng-if="showCustomInviteMessage">
                        <div class="common-size-xxs common-bold margin-bottom-xs">Message</div>
                        <textarea
                            id="customInviteMessage"
                            class="custom-invite-textarea form-control margin-top-xs common-resize-y-only"
                            ng-model="data.customMessageText"
                            placeholder="Type a personal message for new invitees"
                            ng-change="onCustomMessageChange()"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>

        <!-- Intro mode -->
        <div ng-if="isIntro">
            <!-- Avatars -->
            <div class="flex-vmiddle mod-center invite-intro-avatar-container margin-top-lg">
                <span
                    ng-repeat="invitee in inviteesList = (invitees | limitTo: 5)"
                    class="invite-intro-avatar"
                    ng-style="{ 'z-index': 6 - $index }"
                >
                    <tnk-avatar
                        user="invitee"
                        uib-tooltip="{{ invitee.name || invitee.email }}"
                        tooltip-placement="auto top"
                    ></tnk-avatar>
                </span>
                <span
                    ng-if="invitees.length > 5"
                    class="margin-left-xs"
                    uib-tooltip="{{ utils.joinObjArray(invitees.slice(5), 'name', ', ') }}"
                    tooltip-placement="auto top"
                >
                    + {{ invitees.length - 5 }} more
                </span>
            </div>
            <!-- Title -->
            <div class="common-title-lg text-center margin-top-xs">
                <ng-pluralize
                    count="invitees.length"
                    when="{'1': 'Intro {{ invitees[0].firstName }} to Tonkean', 'other': 'Send an intro to Tonkean'}"
                ></ng-pluralize>
            </div>
            <!-- Custom message -->
            <div class="margin-top-lg padding-top">
                <div class="common-bold common-size-xxs text-left">We recommend personalizing your intro message:</div>
                <textarea
                    id="customIntroMessage"
                    class="form-control margin-top-xs"
                    rows="4"
                    ng-model="data.messageText"
                    placeholder="Type a personalized intro message"
                ></textarea>
            </div>
        </div>

        <!-- Error message -->
        <uib-alert ng-show="inviteError" type="danger" class="margin-top common-size-xxs">{{ inviteError }}</uib-alert>

        <!-- Buttons - cancel and send -->
        <div class="flex-vmiddle mod-justify-end margin-top-lg">
            <button 
                type="submit" 
                ng-disabled="data.submitDisabled"
                class="btn btn-lg mod-wide btn-primary" 
                data-automation="invite-template-button-cancel-and-send"
                >
                {{
                    isIntro
                        ? 'Send intro'
                        : invitees.length
                        ? 'Invite ' + invitees.length + (invitees.length > 1 ? '
                people' : ' person')
                        : 'Close'
                }}
            </button>
        </div>
    </form>
</div>

<!-- Add invitee to private group template -->
<script type="text/ng-template" id="invite-group-autocomplete-template">
    <div class="flex-vmiddle common-size-xxs">
        <span class="flex-no-shrink" ng-class="data.isNotAllowedToInvite ? 'common-color-grey' : 'common-color-black'">{{data.name}}</span>
        <span ng-if="data.isNotAllowedToInvite" class="svg-icon-xs margin-left-xs svg-icon-vmiddle">
            <tnk-icon src="../../../../apps/tracks/images/icons/no-access.svg"></tnk-icon>
        </span>
    </div>
    <div class="common-color-grey common-size-xxxxs margin-top-xs" ng-if="!data.isDummyPlaceholder">
        <span ng-if="!data.isNotAllowedToInvite">
            {{ data.creator.id === $scope.currentUserId ? 'Created by you' : 'Created by ' + data.creator.name }}
        </span>
        <span ng-if="data.isNotAllowedToInvite">Only {{data.creator.name}} (and other owners) can add <span class="hidden-xs">someone</span> to this list</span>
    </div>
</script>
<!-- Invite group selected template -->
<script type="text/ng-template" id="invite-group-selected-template">
    <div class="flex-vmiddle tag-content" ng-class="{'mod-grey': data.publicPlaceholder}" uib-tooltip="{{data.tooltip}}"
         tooltip-placement="top-left">
        <span class="common-color-white">{{$getDisplayText()}}</span>
        <i ng-if="data.publicPlaceholder" class="fa fa-info-circle common-color-white margin-left-xs"></i>
        <span class="svg-icon-xxs svg-icon-align-text-base common-color-white pointer margin-left-xs"
              ng-if="!data.publicPlaceholder" ng-click="$removeTag()">
            <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
        </span>
    </div>
</script>
