import PerformedActionType from './PerformedActionType';
import MatchedEntityDefinitionSourcePrefix from './MatchedEntityDefinitionSourcePrefix';
import {lateConstructController} from '@tonkean/angular-components';
import {getSpecialFieldsForFeatures, convertMatchSelectionToServerObject} from '@tonkean/tonkean-utils';
import { ContractFieldType } from "@tonkean/tonkean-entities";

/* @ngInject */
function GroupFieldsMetadataTableCtrl(
    $scope,
    $rootScope,
    $q,
    $localStorage,
    projectManager,
    customTriggerManager,
    groupInfoManager,
    modalUtils,
    modal,
    utils,
    customFieldsManager,
    integrationsConsts,
    workflowVersionManager,
    syncConfigCacheManager,
    tonkeanService,
    formManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.ctm = customTriggerManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;
    $scope.utils = utils;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersion: null,
        workflowVersionId: ctrl.workflowVersionId,
        showExampleItemSelector: ctrl.showExampleItemSelector,
        canModifyBot: ctrl.canModifyBot || (() => true),
        miniMode: ctrl.miniMode,
        hideMatchedEntities: false,
        hideMatchedItems: false,
        updateContractFieldsModalState: null,

        contractIdToContractFields: {},
        actionsByIntegrationsMap: integrationsConsts.getActionsByIntegrationsMap(),
        fieldFilterByName: '',
        columns: {
            ctrlObject: {},
        },
        keyMetrics: {
            ctrlObject: {},
        },
        filteredKeyMetrices: [],
        filteredSpecialFields: [],
        filteredCustomFields: [],
        filteredAggregateFields: [],
        filterMenuOpened: false,
        fieldDefinitionForDependenciesOptions: [],
        selectedFieldDefinitionForDependencies: null,
        isOpen: true,
        showDependenciesDropdown: false,
        fieldDefinitionDependentIds: [],
        loadDependenciesDropdownList: false,
        fieldDefinitionDependenciesCounter: null,
        loadingDependenciesFilterBar: false,
        triggerDependenciesCounter: null,

        expandFieldGroup: null,
        isDraft: null,
        cantEditInProductionEnvironmentMessage: 'Editing in a published version is disabled',
        projectIntegrations: {},
        customTriggerToIcon: {},

        isMatchedEntityMutableMap: {}, // Maps between a matched entity definition source and if it's possible to add fields to it or edit it

        initiated: false,
    };

    // Handling a new field definition that is created.
    $rootScope.$on('newFieldDefinitionCreated', (event, {field, shouldReload = false}) =>
        $scope.reloadFields(field, shouldReload),
    );

    // Handling a new field definition that is deleted.
    $rootScope.$on('fieldDefinitionDeleted', (event, {field, shouldDeleteFieldFromServer = true}) =>
        customFieldsManager
            .deleteField(
                $scope.data.workflowVersionId,
                $scope.data.groupId,
                field.id,
                field.targetType === 'GLOBAL' ? field.id : null,
                shouldDeleteFieldFromServer,
            )
            .then(() => $scope.reloadFields(field, true)),
    );

    if (!$scope.data.miniMode) {
        // request to open a field group from the outside
        $rootScope.$on('expandFieldGroup', function (event, key) {
            $scope.data[key] = true;
            $scope.data.expandFieldGroup = key;
        });
    }

    if ($localStorage.hideMatchedEntities) {
        $scope.data.hideMatchedEntities = $localStorage.hideMatchedEntities[$scope.data.groupId];
    } else {
        $localStorage.hideMatchedEntities = {};
    }

    if ($localStorage.hideMatchedItems) {
        $scope.data.hideMatchedItems = $localStorage.hideMatchedItems[$scope.data.groupId];
    } else {
        $localStorage.hideMatchedItems = {};
    }

    /**
     * Initialization of the controller.
     */
    ctrl.$onInit = function () {
        updateWorkflowVersion();
        $scope.reloadFields();

        $scope.data.initiated = true;
    };

    $scope.getMatchedItemGroupByRelatedCustomTrigger = function (customTriggerId) {
        const groupId = $scope.ctm.getCachedCustomTrigger($scope.data.workflowVersionId, customTriggerId)
            .customTriggerActions[0].customTriggerActionDefinition.actionDefinition.groupId;
        return groupInfoManager.getGroupByIdFromCache(groupId);
    };

    /**
     * Load project integrations and custom trigger map from the definition sources of the matched entities
     */
    $scope.loadDefinitionsSources = function (defSource) {
        if ($scope.isMatchedEntityFromCustomTrigger(defSource)) {
            if ($scope.data.projectIntegrations[defSource]) {
                return;
            }

            const customTriggerPromise = $scope.ctm.getCustomTriggerFromCacheOrFallbackServer(
              $scope.data.workflowVersionId,
              defSource,
            )
            const projectIntegrationPromise = getProjectIntegrationFromTrigger(defSource)

            Promise.allSettled([customTriggerPromise, projectIntegrationPromise])
              .then(([customTriggerSettledResult,projectIntegrationSettledResult]) =>{
                  if(customTriggerSettledResult.status === "fulfilled"){
                      const contractId = $scope.getContractOfCustomTrigger(customTriggerSettledResult.value?.id)

                      if (contractId) {
                          tonkeanService.getContractFields(projectManager.project.id, contractId, ContractFieldType.OUTPUT)
                            .then((contractFields) => {
                                $scope.data.contractIdToContractFields[contractId] = contractFields.entities
                            })
                      }
                  }

                  if(projectIntegrationSettledResult.status === "fulfilled"){
                      $scope.data.projectIntegrations[defSource] = projectIntegrationSettledResult.value;

                      // If project integration wasn't found we try to get custom trigger icon
                      if (!projectIntegrationSettledResult.value) {
                          // in this case defSource is the customTriggerId so we send this twice
                          loadCustomTriggerIcon(defSource, defSource);
                      }
                  }
            });
        }

        if (defSource.startsWith('FIDE')) {
            $scope.data.projectIntegrations[defSource] =
                $scope.data.columns.fieldDefinitionsMap[defSource].projectIntegration;

            // If the field was created from a custom trigger like OCR, Create entity etc..
            const linkedCustomTrigger = $scope.data.columns.fieldDefinitionsMap[defSource]?.linkedCustomTrigger;
            if (linkedCustomTrigger) {
                getProjectIntegrationFromTrigger(linkedCustomTrigger.id).then((projectIntegration) => {
                    $scope.data.projectIntegrations[defSource] = projectIntegration;

                    // If project integration wasn't found we try to get custom trigger icon
                    if (!projectIntegration) {
                        loadCustomTriggerIcon(linkedCustomTrigger.id, defSource);
                    }
                });
            }
        }

        if (defSource === 'ROOT') {
            $scope.data.projectIntegrations[defSource] = $scope.scm.getSyncConfig(
                $scope.data.workflowVersionId,
            ).projectIntegration;
        }
    };

    /**
     * Load custom trigger icon from custom trigger id
     */
    function loadCustomTriggerIcon(linkedCustomTriggerId, defSource) {
        getCustomTriggerIconFromTrigger(linkedCustomTriggerId).then((customTriggerIcon) => {
            $scope.data.customTriggerToIcon[defSource] = customTriggerIcon;
        });
    }

    /**
     * Occurs when component bindings are changed.
     */
    ctrl.$onChanges = function (changesObj) {
        let reloadFields = false;
        let shouldForceReload = false;
        if (changesObj.workflowVersionId && changesObj.workflowVersionId.currentValue?.length > 0) {
            $scope.data.workflowVersionId = changesObj.workflowVersionId.currentValue;
            updateWorkflowVersion();
            reloadFields = true;
        }

        if (changesObj.groupId) {
            $scope.data.groupId = changesObj.groupId.currentValue;
            reloadFields = true;
        }

        if (changesObj.reloadConfiguration && changesObj.reloadConfiguration.currentValue) {
            shouldForceReload = true;
        }

        if ($scope.data.initiated && (reloadFields || shouldForceReload)) {
            $scope.reloadFields(false, shouldForceReload);
        }
    };

    // We reload configuration once the sync config has changed, as root entity fields rely on it.
    $scope.$on('groupSyncConfigAdded', (event, params) => {
        if (params.workflowVersionId === $scope.data.workflowVersionId) {
            $scope.reloadFields();
        }
    });

    // We reload configuration once the sync config has changed, as root entity fields rely on it.
    $scope.$on('groupSyncConfigRemoved', (event, params) => {
        if (params.workflowVersionId === $scope.data.workflowVersionId) {
            $scope.reloadFields();
        }
    });

    $scope.$on('linkedFieldDefinitionsChanged', () => {
        $scope.reloadFields(true, true);
    });

    /**
     * Opens the copy to another list modal.
     */
    $scope.openDuplicateFieldModal = function (fieldDefinition) {
        modal.openDuplicateFieldDefinitionModal(fieldDefinition, $scope.pm.groupsMap[$scope.data.groupId], true);
    };

    $scope.applyFieldDependenciesFilter = function (fieldDefinition, customTrigger) {
        // If the function receive customTrigger, we will fetch the custom triggers linked field definition
        if (customTrigger) {
            fieldDefinition = $scope.data.columns.fieldDefinitions.find(
                (field) => field.linkedCustomTrigger && field.linkedCustomTrigger.id === customTrigger.id,
            );
        }
        $scope.data.selectedFieldDefinitionForDependencies = fieldDefinition;
        $scope.data.filterMenuOpened = true;
        $scope.applyFilters($scope.data.fieldFilterByName, $scope.data.selectedFieldDefinitionForDependencies);
    };

    function initializeFilteredFieldDefinitionLists() {
        $scope.data.filteredCustomFields = [...$scope.data.columns.fieldDefinitions].filter(
            (field) => field.type !== 'AGGREGATE_QUERY',
        );
        $scope.data.filteredCustomFields.forEach((filteredCustomField) => {
            filteredCustomField.htmlName = filteredCustomField.name;
        });

        $scope.data.filteredAggregateFields = [...$scope.data.columns.fieldDefinitions].filter(
            (field) => field.type === 'AGGREGATE_QUERY',
        );
        $scope.data.filteredAggregateFields.forEach((field) => (field.htmlName = field.name));

        $scope.data.filteredSpecialFields = [...$scope.data.specialFields];
        $scope.data.filteredSpecialFields.forEach((filteredSpecialField) => {
            filteredSpecialField.htmlName = filteredSpecialField.name;
        });

        $scope.data.filteredKeyMetrices = [...$scope.data.keyMetrics.allFieldDefinitions];
        $scope.data.filteredKeyMetrices.forEach((filteredKeyMetric) => {
            filteredKeyMetric.htmlName = filteredKeyMetric.name;
        });
    }

    $scope.applyTriggerDependenciesFilter = function (fieldDefinition, customTrigger) {
        // If the function receive customTrigger, we will fetch the custom triggers linked field definition
        if (customTrigger) {
            fieldDefinition = $scope.data.columns.fieldDefinitions.find(
                (field) => field.linkedCustomTrigger && field.linkedCustomTrigger.id === customTrigger.id,
            );
        }
        ctrl.openTriggersOutline({fieldDefinitionForDependenciesFilterOptional: fieldDefinition});
    };

    function openAllCollapsedFieldDefinitionLists() {
        $scope.data.showYourFields = true;
        $scope.data.showAggregationFields = true;
        $scope.data.showBasicFields = true;
        $scope.data.showFormulaFields = true;
        for (let i = 0; i < $scope.data.columns.fieldDefinitionsRelatedMapping.length; i++) {
            $scope.data[`show${$scope.data.columns.fieldDefinitionsRelatedMapping[i]}`] = true;
        }
    }

    $scope.onFieldDefinitionForDependenciesSelected = function () {
        // If no field for dependencies was selected, return an empty array.
        if (!$scope.data.selectedFieldDefinitionForDependencies) {
            $scope.data.fieldDefinitionDependentIds = [];
            return $q.resolve();
        }

        $scope.data.showDependenciesDropdown = false;
        $scope.data.loadingDependenciesFilterBar = true;
        return tonkeanService
            .getFieldDefinitionDependencies(
                $scope.data.workflowVersionId,
                $scope.data.selectedFieldDefinitionForDependencies.id,
            )
            .then((dependencies) => {
                $scope.data.loadingDependenciesFilterBar = false;
                $scope.data.fieldDefinitionDependentIds = dependencies.dependentFieldDefinitions.map(
                    (field) => field.id,
                );
            });
    };

    function filterDependencies(field) {
        if ($scope.data.selectedFieldDefinitionForDependencies) {
            return $scope.data.fieldDefinitionDependentIds.includes(field.id);
        } else {
            return true;
        }
    }

    function loadFieldDependencies(field) {
        tonkeanService.getFieldDefinitionDependencies($scope.data.workflowVersionId, field.id).then((dependencies) => {
            $scope.data.loadDependenciesDropdownList = false;
            $scope.data.fieldDefinitionDependenciesCounter = dependencies.dependentFieldDefinitions.length;
            $scope.data.triggerDependenciesCounter = dependencies.dependentCustomTriggers.length;
        });
    }

    function toLowerCaseAndMatchWithSearchString(field) {
        return field.name.toLowerCase().includes($scope.data.fieldFilterByName.toLocaleLowerCase());
    }

    $scope.dropdownFieldOpened = function (field, customTrigger) {
        // If the function receive customTrigger, we will fetch the custom triggers linked field definition
        if (customTrigger) {
            field = $scope.data.columns.fieldDefinitions.find(
                (field) => field.linkedCustomTrigger && field.linkedCustomTrigger.id === customTrigger.id,
            );
        }
        $scope.data.loadDependenciesDropdownList = true;
        loadFieldDependencies(field);
    };

    function filterFieldLists() {
        $scope.data.filteredCustomFields = $scope.data.filteredCustomFields
            .filter(toLowerCaseAndMatchWithSearchString)
            .filter(filterDependencies);

        $scope.data.filteredAggregateFields = $scope.data.filteredAggregateFields
            .filter(toLowerCaseAndMatchWithSearchString)
            .filter(filterDependencies);

        $scope.data.filteredSpecialFields = $scope.data.filteredSpecialFields
            .filter(toLowerCaseAndMatchWithSearchString)
            .filter(filterDependencies);

        $scope.data.filteredKeyMetrices = $scope.data.filteredKeyMetrices
            .filter(toLowerCaseAndMatchWithSearchString)
            .filter(filterDependencies);
    }

    $scope.applyFilters = function (fieldTextFilter, fieldForDependencies) {
        $scope.data.fieldFilterByName = fieldTextFilter || '';
        $scope.data.selectedFieldDefinitionForDependencies = fieldForDependencies;

        // If there field for dependencies was selected, load his dependencies.
        const fieldDependenciesPromise = fieldForDependencies
            ? $scope.onFieldDefinitionForDependenciesSelected()
            : $q.resolve();

        fieldDependenciesPromise.then(() => {
            // Initialize all filter arrays (Doing array copy by slice because we dont want to filter out from the original lists)
            initializeFilteredFieldDefinitionLists();

            // Open all collapsed hidden field lists
            openAllCollapsedFieldDefinitionLists();

            // Filtering by the search text
            filterFieldLists();
        });
    };

    $scope.openUpdateContractFieldsModal = function (customTriggerId) {
        $scope.ctm.getCustomTriggerFromCacheOrFallbackServer(
          $scope.data.workflowVersionId,
          customTriggerId,
        ).then((customTrigger) => {
            const contractId = $scope.getContractOfCustomTrigger(customTriggerId)

            const existingFields = $scope.data.filteredCustomFields.filter((customField)=> customField.definition?.matchConfiguration?.creatingCustomTriggerId === customTriggerId)

            const allContractFields = $scope.data.contractIdToContractFields[contractId] || []

            const fieldsState = allContractFields.map((contractField) => ({
                isSelected: existingFields.some(existingField => existingField.secondaryId === contractField.contractFieldId),
                disabled: existingFields.some(existingField => existingField.secondaryId === contractField.contractFieldId),
                displayName: contractField.displayName,
                id: contractField.contractFieldId
            }))

            $scope.data.updateContractFieldsModalState = {
                contractId: contractId,
                customTrigger: customTrigger,
                fieldsState,
            };
        })
    }

    $scope.closeUpdateContractFieldsModal = function () {
        $scope.data.updateContractFieldsModalState = null;
        $rootScope.$applyAsync()
    }

    $scope.submitUpdateContractFields = async function(fieldsToCreate) {
        const existingFields = $scope.data.filteredCustomFields.filter((customField)=> customField.definition?.matchConfiguration?.creatingCustomTriggerId === $scope.data.updateContractFieldsModalState.customTrigger.id)
        const fieldDefinition = existingFields.find((_fieldDefinition) => _fieldDefinition.type === 'MANUAL')

        const promises = fieldsToCreate.map((fieldToCreate) => {
           return customFieldsManager.createFieldDefinition(
              'COLUMN',
              `${$scope.data.updateContractFieldsModalState.customTrigger.displayName} - ${fieldToCreate.displayName}`,
              undefined,
              'EXTERNAL',
              null,
              {
                  ExternalType: fieldDefinition.definition.ExternalType,
                  FieldLabel: fieldToCreate.displayName,
                  FieldName: fieldToCreate.id,
                  matchConfiguration: {
                      matchOption: 'SPECIFIC_ITEM',
                      performOnWorkerItem: false,
                      creatingCustomTriggerId: $scope.data.updateContractFieldsModalState.customTrigger.id,
                      isForMatchingItem: true,
                      matchedItemSourceGroupId: fieldDefinition.definition.matchConfiguration.matchedItemSourceGroupId,
                      matchedItemSourceWorkflowVersionId: fieldDefinition.definition.matchConfiguration.matchedItemSourceWorkflowVersionId,
                      contractId: fieldDefinition.definition.matchConfiguration.contractId,
                  },
              },
              fieldDefinition.projectIntegration.id,
              $scope.data.groupId,
              'String',
              null,
              undefined,
              null,
              null,
              null,
              false,
              false,
              true,
              false,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              $scope.data.updateContractFieldsModalState.customTrigger.id,
              fieldToCreate.id,
              undefined,
              undefined,
            );

        });
        await Promise.all(promises);

        $scope.reloadFields(promises);
    }

    $scope.showHideFields = function (key) {
        if ($scope.data.miniMode) {
            if (ctrl.onFieldsGroupExpanded) {
                ctrl.onFieldsGroupExpanded({key});
            }
        } else {
            $scope.data[key] = !$scope.data[key];
        }
    };

    $scope.showHideMatchedEntities = function () {
        $scope.data.hideMatchedEntities = !$scope.data.hideMatchedEntities;
        $localStorage.hideMatchedEntities[$scope.data.groupId] = $scope.data.hideMatchedEntities;
    };

    $scope.showHideMatchedItems = function () {
        $scope.data.hideMatchedItems = !$scope.data.hideMatchedItems;
        $localStorage.hideMatchedItems[$scope.data.groupId] = $scope.data.hideMatchedItems;
    };

    /**
     * Deletes field definition.
     */
    $scope.deleteFieldDefinition = function (fieldDefinition) {
        modalUtils
            .openDeleteFieldDefinitionModal(
                fieldDefinition.id,
                fieldDefinition.name,
                false,
                null,
                $scope.data.groupId,
                $scope.data.workflowVersionId,
                groupInfoManager,
            )
            .then((deletedSuccessfully) => {
                if (deletedSuccessfully) {
                    // Removing the field definition from the UI.
                    let indexToDelete = -1;
                    for (let i = 0; i < $scope.data.columns.fieldDefinitionsRelatedMapping.length; i++) {
                        if ($scope.data.columns.fieldDefinitionsRelatedMapping[i] === fieldDefinition.id) {
                            indexToDelete = i;
                        }
                    }
                    if (indexToDelete > -1) {
                        $scope.data.columns.fieldDefinitionsRelatedMapping.splice(indexToDelete, 1);
                    }
                    $rootScope.$broadcast('fieldDefinitionDeleted', {
                        field: fieldDefinition,
                        shouldDeleteFieldFromServer: false,
                    });
                }
            });
    };

    /**
     * Opens the quick create modal for the integration and entity.
     */
    $scope.addFieldByRelatedEntity = function (projectIntegration, relationEntityId, isMatchedItem) {
        let entityPromise = $q.resolve();
        let prefixPromise = $q.resolve();
        let customTriggerIdPromise = $q.resolve();
        let idRelationFieldDefinitionIdPromise = $q.resolve();
        let isForMatchedItemsPromise = $q.resolve(false);
        let matchedItemSourceGroupIdPromise = $q.resolve();
        let matchedItemSourceWorkflowVersionPromise = $q.resolve();

        if (isMatchedItem) {
            entityPromise = projectIntegration.name;
            prefixPromise = projectIntegration.name;
            idRelationFieldDefinitionIdPromise = relationEntityId;
            isForMatchedItemsPromise = $q.resolve(true);
            matchedItemSourceGroupIdPromise = $q.resolve(projectIntegration.representativeGroupId);
            matchedItemSourceWorkflowVersionPromise = workflowVersionManager.getGroupWorkflowVersion(
                projectIntegration.representativeGroupId,
                $scope.data.workflowVersion.workflowVersionType,
            );
        } else if ($scope.isMatchedEntityFromCustomTrigger(relationEntityId)) {
            // We are working with a custom trigger relation field.
            const customTrigger = customTriggerManager.getCachedCustomTrigger(
                $scope.data.workflowVersionId,
                relationEntityId,
            );
            customTriggerIdPromise = $q.resolve(relationEntityId);
            prefixPromise = getRelevantPrefixByCustomTrigger(relationEntityId);
            entityPromise = getCustomTriggerMatchedEntityIntegrationEntity(relationEntityId, projectIntegration);

            const matchedItems = customTrigger.customTriggerType === 'TONKEAN_ACTION';

            isForMatchedItemsPromise = $q.resolve(matchedItems);

            if (matchedItems) {
                const sourceGroupId =
                    customTrigger.customTriggerActions[0].customTriggerActionDefinition.actionDefinition.groupId;

                matchedItemSourceGroupIdPromise = $q.resolve(sourceGroupId);

                matchedItemSourceWorkflowVersionPromise = workflowVersionManager.getGroupWorkflowVersion(
                    sourceGroupId,
                    $scope.data.workflowVersion.workflowVersionType,
                );
            }
        } else if ($scope.isManualMatchedEntity(relationEntityId)) {
            // We are working with an id field definition relation field.
            idRelationFieldDefinitionIdPromise = $q.resolve(relationEntityId);
            entityPromise = $q.resolve(
                $scope.data.columns.fieldDefinitionsMap[relationEntityId].definition.ExternalType,
            );
            prefixPromise = $q.resolve($scope.data.columns.fieldDefinitionsMap[relationEntityId].name);
        } else {
            // We are using the root item.
            const syncConfig = syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId);
            if (syncConfig.viewData?.integrationType === 'WEBHOOK') {
                entityPromise = $q.resolve(
                    syncConfig?.viewData?.entityMetadata?.label ||
                    syncConfig?.viewData?.entityMetadata?.entity ||
                    syncConfig?.viewData?.entity,
                );
            } else {
                entityPromise = $q.resolve(
                    syncConfig.viewData?.entityMetadata?.entity ||
                    syncConfig.viewData['entity'] ||
                    syncConfig.viewData['Entity'],
                );
            }
            prefixPromise = $q.resolve(entityPromise);
        }

        return Promise.all([
            entityPromise,
            prefixPromise,
            customTriggerIdPromise,
            idRelationFieldDefinitionIdPromise,
            isForMatchedItemsPromise,
            matchedItemSourceGroupIdPromise,
            matchedItemSourceWorkflowVersionPromise,
        ]).then(
            ([
                 actualEntity,
                 actualPrefix,
                 actualCustomTriggerId,
                 actualIdRelationFieldDefinitionId,
                 isForMatchedItem,
                 matchedItemSourceGroupId,
                 matchedItemSourceWorkflowVersion,
             ]) => {
                // Opening the field inspect modal.
                modal.openFieldInspectModal(
                    projectIntegration,
                    actualEntity,
                    null,
                    (fields, dontNotifyChange) => {
                        for (const field of fields) {
                            onFieldSelectedInInspect(
                                field,
                                dontNotifyChange,
                                projectIntegration,
                                actualEntity,
                                actualCustomTriggerId,
                                actualIdRelationFieldDefinitionId,
                                actualPrefix,
                                isForMatchedItem,
                                matchedItemSourceGroupId,
                                matchedItemSourceWorkflowVersion?.id,
                            );
                        }
                    },
                    null,
                    null,
                    null,
                    null,
                    (field) =>
                        field.isMatchedEntityField === false &&
                        field.isGlobalField === false &&
                        field.isAggregationField === false, // We want to filter out these types of fields from the inspect modal
                    $scope.data.workflowVersionId,
                    true,
                    null,
                    null,
                    null,
                    null,
                    actualCustomTriggerId,
                    null,
                    isForMatchedItem,
                );
            },
        );
    };

    function updateWorkflowVersion() {
        $scope.data.workflowVersion = workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId);
        $scope.data.isDraft = $scope.data.workflowVersion?.workflowVersionType === 'DRAFT';
    }

    /**
     * Occurs once a field is selected in the quick create modal.
     * We create it with the correct matching configurations.
     */
    function onFieldSelectedInInspect(
        field,
        dontNotifyChange,
        projectIntegration,
        entity,
        customTriggerId,
        idRelationFieldDefinitionId,
        prefix,
        isForMatchingItem,
        matchedItemSourceGroupId,
        matchedItemSourceWorkflowVersionId,
    ) {
        if (!utils.isNullOrEmpty(field) && !utils.isNullOrEmpty(field.name)) {
            const matchConfiguration = {
                matchOptionApiName: 'SPECIFIC_ITEM',
                performOnWorkerItem: !customTriggerId && !idRelationFieldDefinitionId,
                creatingCustomTriggerId: customTriggerId,
                idRelationFieldDefinitionId,
                validMatchSelection: true,
                isForMatchingItem,
                matchedItemSourceGroupId,
                matchedItemSourceWorkflowVersionId,
            };

            const externalFieldDefinition = {
                ExternalType: entity,
                FieldName: field.name,
                FieldLabel: field.displayName,
                matchConfiguration: convertMatchSelectionToServerObject(matchConfiguration),
            };

            let fieldName = field.displayName;
            if (prefix && !fieldName.toLowerCase().includes(prefix.toLowerCase())) {
                fieldName = `${prefix} - ${field.displayName}`;
            }

            customFieldsManager
                .createFieldDefinition(
                    'COLUMN',
                    fieldName,
                    field.description,
                    'EXTERNAL',
                    null,
                    externalFieldDefinition,
                    projectIntegration.id,
                    $scope.data.groupId,
                    field.type,
                    null,
                    field.updateable,
                    null,
                    null,
                    null,
                    false,
                    false,
                    true,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    null,
                    isForMatchingItem,
                )
                .then((createdFieldDefinition) => {
                    // Cache.
                    customFieldsManager.addToCache($scope.data.workflowVersionId, [createdFieldDefinition]);
                    // Let everyone know we created a new field definition.
                    $rootScope.$broadcast('newFieldDefinitionCreated', field);
                });
        }
    }

    $scope.$watch(
        () => customFieldsManager.selectedColumnFieldsMap[$scope.data.workflowVersionId],
        () => {
            if ($scope.data.initiated) {
                $scope.reloadFields(false, false);
            }
        },
    );

    /**
     * Update reload flag so tnk-columns will reload his feilds.
     */
    $scope.reloadFields = function (createdOrUpdatedField, forceReload) {
        $scope.data.shouldReloadFields = utils.isNullOrEmpty(createdOrUpdatedField);

        let loadGroupPromise = $q.resolve();
        if (forceReload) {
            loadGroupPromise = groupInfoManager.getGroup($scope.data.groupId, true);
        }

        loadGroupPromise.then(function () {
            $scope.data.group = projectManager.groupsMap[$scope.data.groupId];
            $scope.data.workflowVersion = workflowVersionManager.getCachedWorkflowVersion(
                $scope.data.workflowVersionId,
            );

            $scope.data.columns.fieldDefinitions = angular.copy(
                (customFieldsManager.selectedColumnFieldsMap[$scope.data.workflowVersionId] || []).filter(
                    (fieldDefinition) => !fieldDefinition.systemUtilized,
                ),
            );

            const fieldDefinitionsSources = $scope.data.columns.fieldDefinitions.map((fd) => fd.definitionSource);
            $scope.data.columns.fieldDefinitionsSources = fieldDefinitionsSources.filter(
                (v, i) => fieldDefinitionsSources.indexOf(v) === i,
            ); // remove dups

            $scope.data.columns.fieldDefinitionsMap = {};
            $scope.data.columns.matchedItemsFields = [];
            $scope.data.columns.customMatchedItemsFieldDefinitions = [];

            $scope.data.columns.fieldDefinitions.forEach((fieldDefinition) => {
                const configuredSyncConfig = syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId);
                const configuredSyncEntity =
                    configuredSyncConfig &&
                    configuredSyncConfig.viewData &&
                    (configuredSyncConfig.viewData.entity ||
                        (configuredSyncConfig.viewData.entityMetadata &&
                            configuredSyncConfig.viewData.entityMetadata.entity));
                const configuredFieldDefinitionEntity =
                    fieldDefinition.definition &&
                    (fieldDefinition.definition.entity ||
                        (fieldDefinition.definition.entityMetadata &&
                            fieldDefinition.definition.entityMetadata.entity));

                // set default
                fieldDefinition.entityMappingKey = 'CUSTOM';

                // calculate entity grouping logic
                if (fieldDefinition.linkedCustomTrigger) {
                    fieldDefinition.entityMappingKey = fieldDefinition.linkedCustomTrigger.id;
                    if (fieldDefinition.definition?.matchConfiguration?.isForMatchingItem) {
                        $scope.data.columns.matchedItemsFields = [
                            ...$scope.data.columns.matchedItemsFields,
                            fieldDefinition,
                        ];
                    }
                } else if (
                    fieldDefinition.definition?.matchConfiguration?.isForMatchingItem &&
                    fieldDefinition.idRelationField
                ) {
                    fieldDefinition.entityMappingKey = 'MATCHED_ITEM';
                    $scope.data.columns.customMatchedItemsFieldDefinitions = [
                        ...$scope.data.columns.customMatchedItemsFieldDefinitions,
                        fieldDefinition,
                    ];
                } else if (
                    !fieldDefinition.definition ||
                    fieldDefinition.definitionSource === 'MANUAL' ||
                    fieldDefinition.definition.compiledQuery ||
                    fieldDefinition.type === 'AGGREGATE_QUERY'
                ) {
                    // default to custom
                    fieldDefinition.entityMappingKey = 'CUSTOM';
                } else {
                    if (fieldDefinition.definition.matchConfiguration) {
                        if (fieldDefinition.definition.matchConfiguration.isForMatchingItem) {
                            // if it's match item field definition it's either from a module action OR from the new matched item field
                            fieldDefinition.entityMappingKey =
                                fieldDefinition.definition.matchConfiguration.creatingCustomTriggerId ||
                                fieldDefinition.definition.matchConfiguration.idRelationFieldDefinitionId;
                        } else if (fieldDefinition.definition.matchConfiguration.performOnWorkerItem) {
                            if (
                                configuredSyncConfig &&
                                configuredSyncEntity &&
                                configuredFieldDefinitionEntity &&
                                configuredSyncConfig.projectIntegration.id === fieldDefinition.projectIntegration.id &&
                                configuredSyncEntity.toLowerCase() === configuredFieldDefinitionEntity.toLowerCase()
                            ) {
                                // if matched to root item
                                fieldDefinition.entityMappingKey = 'ROOT';
                            }
                        } else {
                            // if has match configuration, find the right id, if they are null fallback to custom
                            fieldDefinition.entityMappingKey =
                                fieldDefinition.definition.matchConfiguration.idRelationFieldDefinitionId ||
                                fieldDefinition.definition.matchConfiguration.creatingCustomTriggerId ||
                                'CUSTOM';
                        }
                    } else if (
                        fieldDefinition.projectIntegration &&
                        configuredSyncConfig &&
                        configuredSyncConfig.projectIntegration &&
                        fieldDefinition.projectIntegration.id === configuredSyncConfig.projectIntegration.id
                    ) {
                        // if no matching, but the same as root item, show as root (cause that what smart matching will do)
                        fieldDefinition.entityMappingKey = 'ROOT';
                    }
                }

                // add to map
                $scope.data.columns.fieldDefinitionsMap[fieldDefinition.id] = fieldDefinition;
            });

            const matchedItemsFieldDefinitionsRelatedMapping = $scope.data.columns.matchedItemsFields.map(
                (fd) => fd.entityMappingKey,
            );

            $scope.data.columns.matchedItemsFieldDefinitionsRelatedMapping =
                matchedItemsFieldDefinitionsRelatedMapping.filter(
                    // remove dups
                    (v, i) => matchedItemsFieldDefinitionsRelatedMapping.indexOf(v) === i,
                );

            const customMatchedItemsFieldDefinitionsRelatedMapping =
                $scope.data.columns.customMatchedItemsFieldDefinitions.map((fd) => fd.id);

            $scope.data.columns.customMatchedItemsFieldDefinitionsRelatedMapping =
                customMatchedItemsFieldDefinitionsRelatedMapping.filter(
                    // remove dups
                    (v, i) => customMatchedItemsFieldDefinitionsRelatedMapping.indexOf(v) === i,
                );

            // add existing fields dependencies
            let fieldDefinitionsWithEntityMapping = $scope.data.columns.fieldDefinitions.map(
                (fd) => fd.entityMappingKey,
            );

            // add root if group have sync
            if (
                syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId) &&
                syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId).viewData
            ) {
                fieldDefinitionsWithEntityMapping.push('ROOT');
            }

            const fieldsWithRelatedMappings = $scope.data.columns.fieldDefinitions.filter(
                (fd) => fd.idRelationField || fd.linkedCustomTrigger,
            );

            // add entities and root (in case no field exists yet)
            fieldDefinitionsWithEntityMapping = fieldDefinitionsWithEntityMapping.concat(
                fieldsWithRelatedMappings.map((fd) => (fd.linkedCustomTrigger ? fd.linkedCustomTrigger.id : fd.id)),
            );

            // remove dups
            $scope.data.columns.fieldDefinitionsRelatedMapping = fieldDefinitionsWithEntityMapping.filter(
                (v, i) => fieldDefinitionsWithEntityMapping.indexOf(v) === i,
            );

            // finale map for matched entities, we filter out the matched item field definitions
            $scope.data.columns.matchedEntitiesFieldDefinitionsRelatedMapping =
                $scope.data.columns.fieldDefinitionsRelatedMapping.filter(
                    (entity) =>
                        entity !== 'CUSTOM' &&
                        entity !== 'MATCHED_ITEM' &&
                        !$scope.data.columns.matchedItemsFieldDefinitionsRelatedMapping.includes(entity) &&
                        !$scope.data.columns.customMatchedItemsFieldDefinitionsRelatedMapping.includes(entity) &&
                        (Object.keys($scope.data.columns.fieldDefinitionsMap)?.includes(entity) ||
                            entity === 'ROOT' ||
                            entity.slice(0, 4) === 'CUTR'),
                );

            $scope.data.specialFields = getSpecialFieldsForFeatures(false, ['BASIC_FIELDS']);

            $scope.data.keyMetrics.allFieldDefinitions = angular.copy(
                (customFieldsManager.selectedGlobalFieldsMap[$scope.data.workflowVersionId] || []).filter(
                    (fieldDefinition) => !fieldDefinition.systemUtilized,
                ),
            );

            $scope.data.shouldReloadFields = false;

            $scope.data.fieldDefinitionForDependenciesOptions = $scope.data.columns.fieldDefinitions;

            initializeFilteredFieldDefinitionLists();
            initializeIsMatchedEntityMutableMap();
            filterFieldLists();
        });
    };

    /**
     * Filter the relevant fields according to the given defSource (CUTR/FIDE/ROOT)
     * @param matchedEntitySourceDefinitionId - An id of the relevant definition which created the fields
     * @returns {function(*): boolean}
     */
    $scope.filterRelatedFieldsByMatchedEntity = function (matchedEntitySourceDefinitionId) {
        // We have to return a function to the ng-repeat filter
        return (field) => {
            // If it's a linked field definition from a custom trigger
            if (field.entityMappingKey === 'CUSTOM' && field.linkedCustomTrigger?.id) {
                return field.linkedCustomTrigger.id === matchedEntitySourceDefinitionId;
            }

            // If it's from a regular matched entity
            return field.entityMappingKey === matchedEntitySourceDefinitionId;
        };
    };

    /**
     * Maps between a matched entity definition source and whether we allowed to add a field to the matched entity.
     * This is possible only for triggers that creates entities such as create, update and create or update entity.
     */
    function initializeIsMatchedEntityMutableMap() {
        $scope.data.columns.fieldDefinitionsRelatedMapping.forEach((matchedEntitySourceDefinitionId) => {
            if ($scope.isMatchedEntityFromCustomTrigger(matchedEntitySourceDefinitionId)) {
                // If it's a custom trigger, allow to add a field only if the entity created from a create or update trigger action
                $scope.ctm
                    .getCustomTriggerFromCacheOrFallbackServer(
                        $scope.data.workflowVersionId,
                        matchedEntitySourceDefinitionId,
                    )
                    .then((customTrigger) => {
                        matchedEntityOfCreatedEntity(customTrigger).then((matchedEntity) => {
                            $scope.data.isMatchedEntityMutableMap[matchedEntitySourceDefinitionId] = matchedEntity;
                        });
                    });
            } else {
                // Allow to add field to any matched entity that is not custom trigger
                $scope.data.isMatchedEntityMutableMap[matchedEntitySourceDefinitionId] = true;
            }
        });
    }

    /** @type {Record<string, MatchedEntityDefinitionSourcePrefix>} */
    const idToTypeCache = {};

    /**
     * @param id {string}
     * @returns {MatchedEntityDefinitionSourcePrefix}
     */
    const getIdTypeAndCache = (id) => {
        if (!idToTypeCache[id]) {
            switch (id.slice(0, 4)) {
                case MatchedEntityDefinitionSourcePrefix.CUSTOM_TRIGGER:
                    idToTypeCache[id] = MatchedEntityDefinitionSourcePrefix.CUSTOM_TRIGGER;
                    break;
                case MatchedEntityDefinitionSourcePrefix.FIELD_DEFINITION:
                    idToTypeCache[id] = MatchedEntityDefinitionSourcePrefix.FIELD_DEFINITION;
                    break;
                case MatchedEntityDefinitionSourcePrefix.ROOT:
                    idToTypeCache[id] = MatchedEntityDefinitionSourcePrefix.ROOT;
                    break;
            }
        }

        return idToTypeCache[id];
    };

    /**
     * Whether the given field definition from a matched entity can be edited
     * @param matchedEntityId - The matched entity id the field is part of
     * @param fieldDefinition - The field definition
     * @returns {*|boolean}
     */
    $scope.isFieldEditable = function (matchedEntityId, fieldDefinition) {
        return $scope.data.isMatchedEntityMutableMap[matchedEntityId] && fieldDefinition.type !== 'MANUAL' && !fieldDefinition.definition?.matchConfiguration?.contractId;
    };

    $scope.getContractOfCustomTrigger = function(customTriggerId){
        return $scope.ctm.getCachedCustomTrigger($scope.data.workflowVersionId, customTriggerId)?.customTriggerActions?.[0]?.customTriggerActionDefinition?.actionDefinition?.contractId
    }

    /**
     * Whether the given matched entity is generated from a custom trigger
     * @param matchedEntityId - the matched entity id
     * @returns {*}
     */
    $scope.isMatchedEntityFromCustomTrigger = function (matchedEntityId) {
        return getIdTypeAndCache(matchedEntityId) === MatchedEntityDefinitionSourcePrefix.CUSTOM_TRIGGER;
    };

    /**
     * Whether the given matched entity was configured manually
     * @param matchedEntityId - the matched entity id
     * @returns {*}
     */
    $scope.isManualMatchedEntity = function (matchedEntityId) {
        return getIdTypeAndCache(matchedEntityId) === MatchedEntityDefinitionSourcePrefix.FIELD_DEFINITION;
    };

    $scope.getManualMatchedEntityName = function (data, defSource) {
        return (
            data.columns.fieldDefinitionsMap[defSource].definition?.entityMetadata?.label ||
            data.columns.fieldDefinitionsMap[defSource].definition?.ExternalType[0]?.toUpperCase() +
            data.columns.fieldDefinitionsMap[defSource].definition?.ExternalType?.slice(1)?.toLowerCase()
        );
    };

    /**
     * Whether the given matched entity is a root matched entity
     * @param matchedEntityId - the matched entity id
     * @returns {*}
     */
    $scope.isRootMatchedEntity = function (matchedEntityId) {
        return getIdTypeAndCache(matchedEntityId) === MatchedEntityDefinitionSourcePrefix.ROOT;
    };

    /**
     * Extracts the integration behind a given custom trigger
     * @param customTriggerId - A given custom trigger id
     * @returns A promise that resolves the project integration
     */
    function getProjectIntegrationFromTrigger(customTriggerId) {
        return $scope.ctm
            .getCustomTriggerFromCacheOrFallbackServer($scope.data.workflowVersionId, customTriggerId)
            .then((customTrigger) => {
                return getRelevantProjectIntegrationFromCustomTrigger(customTrigger).then((projectIntegrationId) => {
                    // Get integration from project or from group
                    if (projectIntegrationId) {
                        const projectIntegrationToReturn =
                            $scope.pm.project.projectIntegrationIdDict[projectIntegrationId] ||
                            $scope.pm.groupsMap[$scope.data.groupId].projectIntegrationIdDict[projectIntegrationId];

                        // If we have in hand return else get from server
                        return projectIntegrationToReturn
                            ? projectIntegrationToReturn
                            : tonkeanService
                                .getProjectIntegrationById(projectIntegrationId)
                                .then((projectIntegrationResult) => {
                                    return projectIntegrationResult;
                                });
                    }
                });
            });
    }

    function getCustomTriggerIconFromTrigger(customTriggerId) {
        return $scope.ctm
            .getCustomTriggerFromCacheOrFallbackServer($scope.data.workflowVersionId, customTriggerId)
            .then((customTrigger) => {
                return $scope.ctm.getCustomTriggerIconClassByType(customTrigger.customTriggerType);
            });
    }

    $scope.getMatchedItemsFieldTitle = function (defSource) {
        if ($scope.isMatchedEntityFromCustomTrigger(defSource)) {
            return $scope.ctm.getCachedCustomTrigger($scope.data.workflowVersionId, defSource)?.displayName
        }
    };

    $scope.getMatchedEntityFieldTitle = function (defSource) {
        if($scope.isRootMatchedEntity(defSource)){
            return ($scope.scm.getSyncConfig($scope.data.workflowVersionId).viewData.entityMetadata
                .pluralLabel ||
            $scope.scm.getSyncConfig($scope.data.workflowVersionId).viewData['Entity'] ||
            $scope.scm.getSyncConfig($scope.data.workflowVersionId).viewData['entity']) + ' (Root)'
        }
        if($scope.isMatchedEntityFromCustomTrigger(defSource)){
            return $scope.ctm.getCachedCustomTrigger($scope.data.workflowVersionId, defSource)?.displayName
        }
        if($scope.isManualMatchedEntity(defSource)){
            return $scope.data.columns.fieldDefinitionsMap[defSource].name
        }

        return undefined;
    };

    $scope.onFieldSelected = function (
        groupId,
        targetType,
        fieldDefinitionType,
        projectIntegration,
        createMode,
        duplicateMode,
        existingFieldDefinition,
        afterCreateOrUpdateCallback,
        deleteCallback,
        openInStep,
        quickCreateForExternal,
        startWithDataSource,
        selectedEntity,
        isWorkerMode,
        overrideFormulaOperator,
        overrideFieldIsHidden,
        manualValue,
        idOnlyMode,
        workflowVersionId,
        allowOnlyFieldDefinitionDataTypes,
        isForMatchedItem,
    ) {
        let confirmationPromise = $q.resolve();
        if (ctrl.fieldEditorHasPendingChanges) {
            $scope.mboxData = {
                title: 'You have unsaved changes in your Field',
                body: 'Are you sure you want to discard those changes?',
                isWarn: true,
                okLabel: 'Discard',
                cancelLabel: 'Cancel',
            };

            confirmationPromise = modal.openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            }).result;
        }

        return confirmationPromise
            .then(() => {
                /**
                 * When adding a matched entity from the worker editor, we must make sure the 'fields' tab is selected.
                 * Otherwise, the field editor is not shown.
                 * */
                return ctrl.onTabSelected?.({key: 'fields'}) || $q.resolve();
            })
            .then(() => {
                ctrl.onFieldSelected(
                    groupId,
                    targetType,
                    fieldDefinitionType,
                    projectIntegration,
                    createMode,
                    duplicateMode,
                    existingFieldDefinition,
                    afterCreateOrUpdateCallback,
                    deleteCallback,
                    openInStep,
                    quickCreateForExternal,
                    startWithDataSource,
                    selectedEntity,
                    isWorkerMode,
                    overrideFormulaOperator,
                    overrideFieldIsHidden,
                    manualValue,
                    idOnlyMode,
                    workflowVersionId,
                    allowOnlyFieldDefinitionDataTypes,
                    undefined,
                    isForMatchedItem,
                );
            });
    };

    function getMatchedEntityByActionWithForm(customTrigger) {
        const definition = customTrigger.customTriggerActions?.[0].customTriggerActionDefinition;

        if (definition.formId) {
            return formManager.getWorkerForm($scope.data.workflowVersionId, definition.formId).then((form) => {
                return $q.resolve(form?.formQuestionType === 'UPLOAD_FILES');
            });
        } else {
            return $q.resolve(false);
        }
    }

    function matchedEntityOfCreatedEntity(customTrigger) {
        switch (customTrigger.customTriggerType) {
            case 'PERFORM_INTEGRATION_ACTION': {
                const performedActionType =
                    customTrigger.customTriggerActions?.[0].customTriggerActionDefinition?.performedActionType;

                switch (performedActionType) {
                    case 'CUSTOM_ACTIONS': {
                        // If the action is custom action and creates entities we can add fields to the matched entity
                        const createsEntity =
                            $scope.data.actionsByIntegrationsMap[customTrigger.integrationType].customActions[
                                customTrigger.customTriggerActions?.[0].customTriggerActionDefinition
                                    ?.performedActionDefinition?.customActionKey
                                ]?.createsEntity;

                        return $q.resolve(createsEntity);
                    }

                    case 'PROJECT_INTEGRATION_ACTION': {
                        const customTriggerActionDefinition =
                            customTrigger.customTriggerActions?.[0].customTriggerActionDefinition;

                        return tonkeanService
                            .getProjectIntegrationActionById(
                                customTriggerActionDefinition.projectIntegrationId,
                                customTriggerActionDefinition.performedActionDefinition.projectIntegrationActionId,
                            )
                            .then((projectIntegrationAction) => {
                                if (
                                    projectIntegrationAction?.responseHandlingDefinition?.successDefinition
                                        .outputLinkedFields.length
                                ) {
                                    return projectIntegrationAction.responseHandlingDefinition.successDefinition.outputLinkedFields.some(
                                        (singleOutputField) => singleOutputField.containsEntityId,
                                    );
                                }

                                return false;
                            });
                    }

                    default: {
                        const createdEntity = Object.values(PerformedActionType).includes(performedActionType);
                        return $q.resolve(createdEntity);
                    }
                }
            }

            case 'TONKEAN_ACTION': {
                const isContractExist = !!$scope.getContractOfCustomTrigger(customTrigger.id)

                return $q.resolve(!isContractExist || isContractExist);
            }

            case 'SEND_FORM':
            case 'SEND_FORM_ANSWERED':
            case 'BOT_BUTTON_PRESSED': {
                return getMatchedEntityByActionWithForm(customTrigger);
            }

            default:
                return $q.resolve(false);
        }
    }

    function getCustomTriggerMatchedEntityIntegrationEntity(relationEntityId, projectIntegration) {
        const customTrigger = customTriggerManager.getCachedCustomTrigger(
            $scope.data.workflowVersionId,
            relationEntityId,
        );

        switch (customTrigger.customTriggerType) {
            case 'PERFORM_INTEGRATION_ACTION':
                if (
                    customTrigger.customTriggerActions[0].customTriggerActionDefinition.performedActionType ===
                    'PROJECT_INTEGRATION_ACTION'
                ) {
                    return tonkeanService
                        .getProjectIntegrationActionById(
                            projectIntegration.id,
                            customTrigger.customTriggerActions[0].customTriggerActionDefinition
                                .performedActionDefinition.projectIntegrationActionId,
                        )
                        .then(
                            (projectIntegrationAction) =>
                                projectIntegrationAction?.parametersDefinition?.entitiesToRunOn?.[0],
                        );
                } else {
                    return $q.resolve(
                        customTrigger.customTriggerActions[0].customTriggerActionDefinition.performOnEntity.entity,
                    );
                }

            case 'SEND_FORM':
            case 'SEND_FORM_ANSWERED':
            case 'BOT_BUTTON_PRESSED':
                return $q.resolve('File');

            case 'TONKEAN_ACTION': {
                return tonkeanService
                    .getProjectIntegrationEntitySummaries(projectIntegration.id)
                    .then((entitiesResponse) => {
                        // There is only one project integration entity for the group project integration - the default one
                        return entitiesResponse.entities[0].displayName;
                    });
            }

            default:
                return $q.resolve();
        }
    }

    function getRelevantPrefixByCustomTrigger(customTriggerId) {
        const customTrigger = customTriggerManager.getCachedCustomTrigger(
            $scope.data.workflowVersionId,
            customTriggerId,
        );

        if (customTrigger.customTriggerType === 'TONKEAN_ACTION') {
            return groupInfoManager.getGroupName(
                customTrigger.customTriggerActions[0].customTriggerActionDefinition.actionDefinition.groupId,
                false,
            );
        }

        return $q.resolve(customTrigger.displayName);
    }

    function getRelevantProjectIntegrationFromCustomTrigger(customTrigger) {
        const definition = customTrigger.customTriggerActions?.[0]?.customTriggerActionDefinition;

        switch (customTrigger.customTriggerType) {
            case 'PERFORM_INTEGRATION_ACTION':
                return $q.resolve(definition?.projectIntegrationId);

            case 'SEND_FORM':
            case 'SEND_FORM_ANSWERED':
            case 'BOT_BUTTON_PRESSED':
                return $q.resolve(definition?.dataStorageId);
            case 'TONKEAN_ACTION':
                return groupInfoManager
                    .getGroup(
                        customTrigger.customTriggerActions[0].customTriggerActionDefinition.actionDefinition.groupId,
                        true,
                    )
                    .then((result) => {
                        return result.projectIntegrationIdForGroupItems;
                    });

            default:
                return $q.resolve();
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('GroupFieldsMetadataTableCtrl', lateConstructController(GroupFieldsMetadataTableCtrl));
