import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function LiveSyncCtrl($scope) {
    const ctrl = this;
    $scope.data = {
        // Ctrl
        customDefinedView: ctrl.customDefinedView,
        existingDefinition: ctrl.existingDefinition,
        projectIntegration: ctrl.projectIntegration,
        selectedEntityType: ctrl.selectedEntityType,
        copyPropertiesFromDefinition: ctrl.copyPropertiesFromDefinition,
        hideFilters: ctrl.hideFilters,
        syncValidityState: ctrl.syncValidityState,
        selectedEntityPluralName: ctrl.selectedEntityPluralName,
        selectedEntity: ctrl.selectedEntity,
        isSpecialEntity: ctrl.isSpecialEntity,
        fallbackEntityType: ctrl.fallbackEntityType,
        integrationType: ctrl.integrationType,
        viewType: ctrl.viewType,
        titleExpression: ctrl.titleExpression,
        defaultTitleIfExpressionEmpty: ctrl.defaultTitleIfExpressionEmpty,
        integrationViews: ctrl.integrationViews,
        hideManageTriggersModal: ctrl.hideManageTriggersModal,
        hideInformation: ctrl.hideInformation,
        overrideActivateBotObject: ctrl.overrideActivateBotObject,
        hideBotInPreview: ctrl.hideBotInPreview,
        fullScreen: ctrl.fullScreen,

        // Other
        viewData: null,
        control: null,
    };

    $scope.init = function () {
        // use given control or create a new one
        $scope.data.control = ctrl.control || {};
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.hideFilters) {
            $scope.data.hideFilters = ctrl.hideFilters;
        }

        if (changesObj.selectedEntityType) {
            $scope.data.selectedEntityType = ctrl.selectedEntityType;
        }
        if (changesObj.existingDefinition) {
            $scope.data.existingDefinition = ctrl.existingDefinition;
        }

        if (changesObj.selectedEntityPluralName) {
            $scope.data.selectedEntityPluralName = ctrl.selectedEntityPluralName;
        }

        if (changesObj.selectedEntity) {
            $scope.data.selectedEntity = ctrl.selectedEntity;
        }

        if (changesObj.projectIntegration) {
            $scope.data.projectIntegration = ctrl.projectIntegration;
        }

        if (changesObj.existingDefinition || (changesObj.loadPreviewFlag && changesObj.loadPreviewFlag.currentValue)) {
            $scope.loadPreview();
        }

        if (changesObj.fullScreen) {
            $scope.data.fullScreen = ctrl.fullScreen;
        }
    };

    $scope.onDataChanged = function () {
        ctrl.onDataChanged();
    };

    /**
     * By setting data.viewData to the created definition out of custom filters from sync control, we instantiate a reload.
     */
    $scope.loadPreview = function () {
        if ($scope.data.customDefinedView) {
            if ($scope.data.control.createDefinitionFromCustomFilters) {
                $scope.data.viewData = $scope.data.control.createDefinitionFromCustomFilters();
            }
        } else {
            $scope.data.viewData = $scope.data.existingDefinition;
        }

        if (ctrl.calculatePluralEntityName && $scope.data.viewData) {
            $scope.data.selectedEntityPluralName = ctrl.calculatePluralEntityName($scope.data.viewData);
        }
    };

    /**
     * reload when the user selects another view
     */
    $scope.onIntegrationViewSelected = function () {
        $scope.data.existingDefinition = $scope.data.control.createViewDataForIntegrationView(
            $scope.data.control.selectedView,
        );
        $scope.loadPreview();
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('LiveSyncCtrl', lateConstructController(LiveSyncCtrl));
