import { lateConstructController } from '@tonkean/angular-components';
import { convertMatchSelectionToServerObject } from '@tonkean/tonkean-utils';

/* @ngInject */
function MatchTonkeanItemFieldCtrl($scope, tonkeanUtils) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        targetWorkflowVersionId: ctrl.targetWorkflowVersionId,
        existingFieldDefinition: ctrl.existingFieldDefinition,
        openedFromCustomTriggerId: ctrl.openedFromCustomTriggerId,

        matchConfigurationSelectionObject: null,
    };

    ctrl.$onInit = function () {
        initializeEditMode();
    };

    ctrl.$onChanges = function (changesObj) {};

    /**
     * Occurs once the match option selection object is changed.
     */
    $scope.onMatchOptionSelectionChanged = function (selectionObject, shouldSave) {
        if (selectionObject && selectionObject.validMatchSelection) {
            $scope.data.matchConfigurationSelectionObject = selectionObject;
            definitionChanged(shouldSave);
        }
    };

    function definitionChanged(hadChanges) {
        const validDefinition = true;
        const definitionObject = {
            validDefinition,
            definition: {
                matchConfiguration: $scope.data.matchConfigurationSelectionObject
                    ? convertMatchSelectionToServerObject($scope.data.matchConfigurationSelectionObject)
                    : null,
            },
        };

        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({
                newDefinition: definitionObject,
                doNotReloadPreview: !validDefinition,
            });
        }

        if (hadChanges) {
            ctrl.onChange();
        }
    }

    function initializeEditMode() {
        if ($scope.data.existingFieldDefinition) {
            // Match option
            $scope.data.matchConfigurationSelectionObject = $scope.data.existingFieldDefinition.definition
                .matchConfiguration
                ? tonkeanUtils.convertMatchSelectionToClientObject(
                      $scope.data.existingFieldDefinition.definition.matchConfiguration,
                  )
                : null;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('MatchTonkeanItemFieldCtrl', lateConstructController(MatchTonkeanItemFieldCtrl));
