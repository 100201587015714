const FieldDefinitionDateFormatToDisplayMapper = {
    // Date formats
    DEFAULT_DATE: {
        format: 'MMM d, yyyy',
        includeDate: true,
        includeTime: false,
        timeFormat: '',
    },
    MEDIUM_DATE_TIME: {
        format: 'MMM d, yyyy hh:mm:ss a',
        includeDate: true,
        includeTime: true,
        timeFormat: 'hh:mm:ss a',
    },
    SHORT_DATE_TIME: {
        format: 'MM/dd/yy hh:mm a',
        includeDate: true,
        includeTime: true,
        timeFormat: 'hh:mm a',
    },
    FULL_DATE: {
        format: 'EEEE, MMMM d, yyyy',
        includeDate: true,
        includeTime: false,
        timeFormat: '',
    },
    LONG_DATE: {
        format: 'MMMM d, yyyy',
        includeDate: true,
        includeTime: false,
        timeFormat: '',
    },
    SHORT_DATE: {
        format: 'MM/dd/yy',
        includeDate: true,
        includeTime: false,
        timeFormat: '',
    },
    MEDIUM_TIME: {
        format: 'hh:mm:ss a',
        includeDate: false,
        includeTime: true,
        timeFormat: 'hh:mm:ss a',
    },
    SHORT_TIME: {
        format: 'hh:mm a',
        includeDate: false,
        includeTime: true,
        timeFormat: 'hh:mm a',
    },
    SHORT_DATE_TIME_EU: {
        format: 'dd/MM/yy hh:mm a',
        includeDate: true,
        includeTime: true,
        timeFormat: 'hh:mm a',
    },
    ISO_DATE_TIME: {
        format: "yyyy-MM-dd'T'HH:mm:ss",
        includeDate: true,
        includeTime: true,
        timeFormat: 'HH:mm:ss',
    },
    MEDIUM_TIME_24H: {
        format: 'HH:mm:ss',
        includeDate: false,
        includeTime: true,
        timeFormat: 'HH:mm:ss',
    },
    SHORT_TIME_24H: {
        format: 'HH:mm',
        includeDate: false,
        includeTime: true,
        timeFormat: 'HH:mm',
    },
};

export default FieldDefinitionDateFormatToDisplayMapper;
