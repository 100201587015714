import styled, { css } from 'styled-components';

import { FieldsWidgetDisplayType } from '../FieldsItemWidgetConfiguration';

import { FontSize, Theme } from '@tonkean/tui-theme';

const FieldsContainer = styled.div<{ fieldDisplay: FieldsWidgetDisplayType }>`
    display: grid;

    /**
* This piece of code is designed to make sure the fields widget takes as many columns as required
* while not exceeding 3 columns. When using the Table layout, we want to have only one column.
* 
* @link https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
*/
    --grid-layout-gap: ${({ fieldDisplay }) => (fieldDisplay === FieldsWidgetDisplayType.TABLE ? 0 : 40)}px;
    --grid-column-count: ${({ fieldDisplay }) => (fieldDisplay === FieldsWidgetDisplayType.TABLE ? 1 : 3)};
    --grid-item-min-width: 250px;

    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
    --grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

    grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));

    ${({ fieldDisplay }) => {
        return css`
            grid-gap: ${fieldDisplay === FieldsWidgetDisplayType.GALLERY ? '1px' : 'var(--grid-layout-gap)'};

            ${fieldDisplay === FieldsWidgetDisplayType.TABLE &&
            `
                > *:not(:last-child) {
                    border-bottom: 1px solid ${Theme.colors.gray_300};
                }
            `}
        `;
    }}

    // style override for the people selector, to make it more inline with the rest of the inputs
  .item-interface-style-override.people-selector {
        input {
            height: 28px;
            font-family: 'Roboto', sans-serif;

            ::placeholder {
                color: ${Theme.colors.gray_500};
            }
        }

        .tags {
            padding: 0;
            border-color: ${Theme.colors.gray_400};

            &:focus,
            &:focus-within {
                border-color: ${Theme.colors.focus};
            }

            .tag-item {
                background-color: ${Theme.colors.gray_300} !important;
                border: none !important;
                line-height: 1;
                height: auto;

                .name {
                    font-family: 'Roboto', sans-serif;
                    color: ${Theme.colors.gray_800};
                    font-size: ${FontSize.SMALL_12};
                    line-height: 20px;
                }

                tnk-user {
                    .avatar-main.avatar {
                        height: 16px;
                        width: 16px;
                    }

                    & + a {
                        display: grid;
                        place-items: center;
                        color: ${Theme.colors.gray_800};
                        border-radius: 100px;
                        padding: 3px 4px;

                        &:hover,
                        &:focus {
                            background-color: #ffbdad;

                            svg path {
                                fill: #de350b;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default FieldsContainer;
