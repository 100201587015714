import { useAngularService } from 'angulareact';
import type { PropsWithChildren } from 'react';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { FormPageModal } from '@tonkean/angular-to-react-components';
import { LoadingCircle, SimpleErrorStateMessage, TextEllipsis } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { ButtonShape, UserThemedClickableButton, type ButtonProps } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const StyledButton = styled(UserThemedClickableButton)<{ $viewOnly?: boolean }>`
    line-height: normal;
    height: auto;
    padding: 12px 21px;
    border-radius: 8px;
    border: none;

    ${({ $viewOnly, background }) =>
        $viewOnly &&
        css`
            &:hover {
                background: ${background};
            }
        `}
`;

type InnerComponentOverridableProps = PropsWithChildren<
    ButtonProps & StyledComponentsSupportProps & { disabled?: boolean }
>;

interface Props extends Omit<InnerComponentOverridableProps, 'shape' | 'children' | 'onClick'> {
    mainActionFormId: TonkeanId<TonkeanType.FORM>;
    mainActionLabel: string | undefined;
    workflowVersionType: WorkflowVersionType;
    color: Color;
    viewOnly?: boolean;
}

const MainCallToActionButton: React.FC<Props> = ({
    mainActionFormId,
    mainActionLabel,
    workflowVersionType,
    color,
    viewOnly,
    ...props
}) => {
    const formManager = useAngularService('formManager');
    const {
        data: form,
        loading,
        error,
    } = useAsyncMethod(formManager, 'getFormByWorkflowVersionType', mainActionFormId, workflowVersionType);

    const mainActionDisplayName = useMemo(() => {
        return mainActionLabel || form?.displayName;
    }, [form?.displayName, mainActionLabel]);

    const [openFormModal, setOpenFormModal] = useState(false);

    if (error) {
        return <SimpleErrorStateMessage error={error} showSmallError />;
    }

    return (
        <>
            <StyledButton
                shape={ButtonShape.RECTANGULAR}
                onClick={
                    !viewOnly
                        ? () => {
                              setOpenFormModal(true);
                          }
                        : undefined
                }
                background={color}
                $viewOnly={viewOnly}
                {...props}
            >
                {!mainActionDisplayName && loading ? (
                    <LoadingCircle white />
                ) : (
                    <TextEllipsis numberOfLines={1} tooltip>
                        {mainActionDisplayName}
                    </TextEllipsis>
                )}
            </StyledButton>
            {form && (
                <FormPageModal
                    open={openFormModal}
                    onClose={() => setOpenFormModal(false)}
                    formId={form.id}
                    workflowVersionId={form.workflowVersion?.id}
                    formVersionType={workflowVersionType}
                    initiativeId={undefined}
                    onInitiativeCreated={() => {}}
                    parentInitiativeId={undefined}
                />
            )}
        </>
    );
};

export default MainCallToActionButton;
