import lateConstructController from '../../utils/lateConstructController';

import type { FieldDefinitionConfigurationModalParams } from '@tonkean/tonkean-entities';
import { FormDefinitionType, FormType } from '@tonkean/tonkean-entities';

/* @ngInject */
function FormSelectorCtrl(
    $scope,
    $q,
    $state,
    $location,
    $rootScope,
    $timeout,
    modal,
    utils,
    formManager,
    customFieldsManager,
    workflowFolderManager,
    workflowVersionManager,
    projectManager,
    groupInfoManager,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        selectedFormId: ctrl.selectedFormId,
        openedFromCustomTriggerId: ctrl.openedFromCustomTriggerId,
        hideCreateForms: ctrl.hideCreateForms,
        hideUpdateForms: ctrl.hideUpdateForms,
        onFormSelectedParameter: ctrl.onFormSelectedParameter,
        doNotIncludeFormIdsSet: ctrl.doNotIncludeFormIdsSet,
        formQuestionTypes: ctrl.formQuestionTypes,
        openFieldModal: ctrl.openFieldModal,
        clearable: !!ctrl.clearable,
        existingFormMatchedEntityFieldId: ctrl.existingFormMatchedEntityFieldId,
        validationObject: ctrl.validationObject,

        selectedForm: null,
        forms: [],
        filteredForms: [],

        loading: false,
        errorLoading: null,
        showWorkflowFolderForms: ctrl.showWorkflowFolderForms,
        dropdownFieldIsTooLongForDialogType: false,
        workflowFolder: null,
        previousForm: null,

        automationIdentifier: ctrl.automationIdentifier,
        hideFileUploadForms: ctrl.hideFileUploadForms,
    };

    let unregisterFromOnFormCreated = () => {};

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        $scope.data.workflowFolder = workflowFolderManager.getContainingWorkflowFolder(
            projectManager.project.id,
            $scope.data.groupId,
        );

        loadFormBySelectedFormId();

        $rootScope.$on('formUpdate', (event, form) => {
            loadFormBySelectedFormId();
        });
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.doNotIncludeFormIdsSet) {
            $scope.data.doNotIncludeFormIdsSet = ctrl.doNotIncludeFormIdsSet;
            initializeFilteredForms();
        }
        if (changesObj.formType) {
            validateForm();
        }

        if (changesObj.automationIdentifier) {
            $scope.data.automationIdentifier = ctrl.automationIdentifier;
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
        let shouldCallOnInIt = false;
        if (ctrl.groupId !== $scope.data.groupId) {
            $scope.data.groupId = ctrl.groupId;
            shouldCallOnInIt = true;
        }

        if (ctrl.workflowVersionId !== $scope.data.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            shouldCallOnInIt = true;
        }

        if (shouldCallOnInIt) {
            ctrl.$onInit();
        }
    };

    function onFormCreated({ form }) {
        // Load new forms from cache/server
        $scope.data.loading = true;
        $scope.loadForms().then(() => {
            $scope.onFormSelected(form);
            $scope.data.loading = false;
        });
    }

    $scope.openEditWorkerFormModal = function (type) {
        if (!$location.$$url?.includes('module')) {
            $state.go('product.workerEditor', { g: $scope.data.groupId });
            $timeout(() => {
                $rootScope.$broadcast('editForm', { formId: null, formType: type });
            }, 100);
        } else {
            $rootScope.$broadcast('editForm', { formId: null, formType: type });
        }

        unregisterFromOnFormCreated = $rootScope.$on('formCreated', (event, form) => {
            if (unregisterFromOnFormCreated) {
                unregisterFromOnFormCreated();
            }
            onFormCreated({ form });
        });
    };

    $scope.groupByGroupName = function (form) {
        if (form.group) {
            const groupByIdFromCache = groupInfoManager.getGroupByIdFromCache(form.group.id);
            if (groupByIdFromCache) {
                return groupByIdFromCache.name;
            }
        } else {
            return 'Create';
        }
    };
    $scope.openMatchedFieldModal = () => {
        const params: FieldDefinitionConfigurationModalParams = {
            groupId: $scope.data.groupId,
            targetType: 'COLUMN',
            existingFieldDefinition: $scope.data.existingFormMatchedEntityField,
            createMode: !$scope.data.existingFormMatchedEntityField,
            fieldDefinitionType: FormDefinitionType.MATCH_TONKEAN_ITEM,
            openInStep: 'fieldDefinitionConfiguration',
            startWithDataSource: 'matchTonkeanItem',
            isWorkerMode: true,
            workflowVersionId: $scope.data.workflowVersionId,
            matchedEntityFromWorkflowVersionId: $scope.data.selectedForm?.workflowVersion?.id,
            afterCreateOrUpdateCallback: $scope.onMatchedEntityCreatedOrUpdated,
            isSystemUtilized: true,
            openedFromCustomTriggerId: $scope.data.openedFromCustomTriggerId,
            secondaryId: 'FORM_MATCHED_ENTITY',
        };
        $scope.data.openFieldModal(params);
    };

    $scope.onMatchedEntityCreatedOrUpdated = (field) => {
        $scope.data.existingFormMatchedEntityField = field;
        ctrl.onMatchedEntityCreatedOrUpdated({ field });
    };

    $scope.onFormSelected = function (form) {
        if (!form) {
            // this is clear selection
            ctrl.onFormSelected({});
            return;
        }

        // If its a regular form object or a create new one
        if (!form.isCreateNew) {
            let confirmationPromise = $q.resolve();

            // Check if parent component allow to select the form
            let parentCallback = $scope.data.showWorkflowFolderForms
                ? ctrl.onFormSelectedOpenConfirmation({ form })
                : Promise.resolve(true);
            const matchedEntityModuleChanged =
                $scope.data.existingFormMatchedEntityField &&
                $scope.data.previousForm.workflowVersion.id !== form.workflowVersion.id;

            const hasMatchEntityButIsCreateForm =
                $scope.data.existingFormMatchedEntityField && form.formType === FormType.CREATE;

            if (matchedEntityModuleChanged || hasMatchEntityButIsCreateForm) {
                $scope.mboxData = {
                    title: 'Matched Entity will be lost',
                    body: `Your existing matched entity is for another Module, changing to form "${form.displayName}" will delete the Matched Entity conditions`,
                    isWarn: true,
                    okLabel: 'Continue',
                    cancelLabel: 'Cancel',
                };
                parentCallback = parentCallback.then(() => {
                    return modal.openMessageBox({
                        scope: $scope,
                        size: 'md',
                        windowClass: 'mod-danger',
                    }).result;
                });
            }

            if (parentCallback) {
                confirmationPromise = parentCallback;
                confirmationPromise
                    .then(() => {
                        $scope.data.selectedForm = form;
                        $scope.data.selectedFormId = form.id;

                        if (matchedEntityModuleChanged) {
                            ctrl.onMatchedEntityCreatedOrUpdated({ field: null, shouldSaveLogic: false });
                        }
                        const onFormSelected = Promise.resolve(
                            ctrl.onFormSelected({
                                form,
                                onFormSelectedParameter: $scope.data.onFormSelectedParameter,
                            }),
                        );
                        onFormSelected.then(() => {
                            if (matchedEntityModuleChanged) {
                                const matchedFieldId = $scope.data.existingFormMatchedEntityField.id;
                                $scope.data.existingFormMatchedEntityFieldId = null;
                                $scope.data.existingFormMatchedEntityField = null;
                                return customFieldsManager.deleteFieldDefinition($scope.data.groupId, matchedFieldId);
                            }
                        });
                        validateForm();
                        $scope.data.previousForm = $scope.data.selectedForm;
                    })
                    .catch(loadFormBySelectedFormId);
            } else {
                $scope.data.selectedForm = $scope.data.previousForm;
                return;
            }
        }
    };

    /**
     * Loads the forms for the group.
     */
    $scope.loadForms = function () {
        $scope.data.errorLoading = false;
        $scope.data.forms = [];
        const workflowVersion = workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId);
        const formsPromise = $scope.data.showWorkflowFolderForms
            ? formManager.getAllWorkflowFolderForms(
                  $scope.data.workflowFolder.id,
                  workflowVersion.workflowVersionType,
                  true,
              )
            : formManager.getAllWorkerForm($scope.data.workflowVersionId);

        return formsPromise
            .then((forms) => {
                const filteredForms = forms.filter((form) => filterForm(form));
                const workerForms = filteredForms.filter(
                    (form) => form.workflowVersion.id === $scope.data.workflowVersionId,
                );
                $scope.data.forms = [...workerForms, ...filteredForms];
                $scope.data.forms = utils.distinctArrayById($scope.data.forms);

                if (!$scope.data.hideCreateForms) {
                    $scope.data.forms.push({
                        isCreateNew: true,
                        type: 'CREATE',
                        displayName: '+ Add Create Form',
                    });
                }

                if (!$scope.data.hideUpdateForms) {
                    $scope.data.forms.push({
                        isCreateNew: true,
                        type: 'UPDATE',
                        displayName: '+ Add Update Form',
                    });
                }

                initializeFilteredForms();
            })
            .catch(() => {
                $scope.data.errorLoading = true;
            });
    };

    $scope.clearSelection = ($select, event) => {
        $select.clear(event);
        $scope.data.selectedFormId = undefined;
        $scope.onFormSelected(null);
    };

    function filterForm(form) {
        const isSameModule = form.workflowVersion.id === $scope.data.workflowVersionId;
        if ($scope.data.hideCreateForms && form.formType === 'CREATE') {
            return false;
        }
        if ($scope.data.hideUpdateForms && form.formType === 'UPDATE') {
            return false;
        }
        if (
            form.formType === 'UPDATE' &&
            !isSameModule &&
            !$rootScope.features.currentProject.tonkean_feature_form_match_entity
        ) {
            return false;
        }
        if ($scope.data.hideFileUploadForms && form.formQuestionType === 'UPLOAD_FILES') {
            return false;
        }
        if ($scope.data.formQuestionTypes?.length && !$scope.data.formQuestionTypes.includes(form.formQuestionType)) {
            return false;
        }

        return true;
    }

    function loadFormBySelectedFormId() {
        $scope.data.loading = true;
        return $scope
            .loadForms()
            .then(() => {
                if ($scope.data.existingFormMatchedEntityFieldId) {
                    const workflowVersion = workflowVersionManager.getCachedWorkflowVersion(
                        $scope.data.workflowVersionId,
                    );
                    return customFieldsManager
                        .getFieldDefinitionByIdAndWorkflowVersionType(
                            $scope.data.existingFormMatchedEntityFieldId,
                            workflowVersion.workflowVersionType,
                        )
                        .then((fieldDefinition) => {
                            $scope.data.existingFormMatchedEntityField = fieldDefinition;
                        })
                        .catch(() => {
                            $scope.data.existingFormMatchedEntityField = null;
                        });
                }
            })
            .then(() => {
                if ($scope.data.selectedFormId && $scope.data.forms?.length) {
                    $scope.data.selectedForm = utils.findFirstById($scope.data.forms, $scope.data.selectedFormId);
                    $scope.data.previousForm = $scope.data.selectedForm;
                    validateForm();
                }
                $scope.data.loading = false;
            });
    }

    function initializeFilteredForms() {
        $scope.data.filteredForms = $scope.data.forms.filter(
            (form) => form.isCreateNew || !$scope.data.doNotIncludeFormIdsSet?.[form.id],
        );
    }

    function validateForm() {
        $scope.data.dropdownFieldIsTooLongForDialogType = false;

        $scope.data.selectedForm?.definition?.fields?.forEach((field) => {
            const fieldFromCache =
                customFieldsManager.fieldsMap[$scope.data.workflowVersionId][field.fieldDefinitionIdentifier];

            if (
                ctrl.formType === 'DIALOG' &&
                fieldFromCache?.evaluatedDisplayType === 'List' &&
                fieldFromCache?.possibleValues.some((value) => value.length > 75)
            ) {
                $scope.data.dropdownFieldIsTooLongForDialogType = true;
            }
        });
    }

    ctrl.$onDestroy = function () {
        if (unregisterFromOnFormCreated) {
            unregisterFromOnFormCreated();
        }
    };
}

angular.module('tonkean.app').controller('FormSelectorCtrl', lateConstructController(FormSelectorCtrl));
