import { useMemo } from 'react';

import type { SingleFieldConfiguration } from '../../CommonWidgetConfiguration';

import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

function useFieldsToDisplay(
    configurationFields: Record<FieldDefinitionKey, SingleFieldConfiguration>,
): FieldDefinitionKey[] {
    return useMemo(() => {
        return Object.entries(configurationFields)
            .filter(([, field]) => field.isShown)
            .sort(([, first], [, second]) => Number(first.index) - Number(second.index))
            .map(([id]) => id as FieldDefinitionKey);
    }, [configurationFields]);
}

export default useFieldsToDisplay;
