import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { Spacer } from '@tonkean/infrastructure';
import { ModalBody, ModalHeader } from '@tonkean/infrastructure';
import { H4, Paragraph } from '@tonkean/infrastructure';
import { withModal } from '@tonkean/infrastructure';
import type { SolutionBusinessReport } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const InformationRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16px;
`;

const StyledModalBody = styled(ModalBody)`
    width: 400px;
`;

interface Props {
    solutionBusinessReport: SolutionBusinessReport;
}

const SolutionReportInfoModal: React.FC<Props> = ({ solutionBusinessReport }) => {
    const personCache = useAngularService('personCache');
    const { data: creator } = useAsyncMethod(personCache, 'getEntityById', solutionBusinessReport.creatorId);

    const lastUpdated = useMemo(
        () => utils.formatDate(solutionBusinessReport.updated, true, true),
        [solutionBusinessReport.updated],
    );

    const environment =
        solutionBusinessReport.workflowVersionType === WorkflowVersionType.DRAFT ? 'Build' : 'Production';

    return (
        <>
            <ModalHeader $padding={16}>
                <H4 $light $bold>
                    Report Information
                </H4>
            </ModalHeader>
            <StyledModalBody removePadding>
                <Spacer height={16} />

                <InformationRow>
                    <Paragraph>Created By</Paragraph>
                    <Paragraph $light>{creator?.name}</Paragraph>
                </InformationRow>

                <Spacer height={8} />

                <InformationRow>
                    <Paragraph>Environment</Paragraph>
                    <Paragraph $light>{environment}</Paragraph>
                </InformationRow>

                <Spacer height={8} />

                <InformationRow>
                    <Paragraph>Last Update</Paragraph>
                    <Paragraph $light>{lastUpdated}</Paragraph>
                </InformationRow>

                <Spacer height={16} />
            </StyledModalBody>
        </>
    );
};

export default withModal(SolutionReportInfoModal);
