import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function DropboxCustomActionsDefinitionCtrl($scope, $q, projectManager) {
    const ctrl = this;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,
        filterByIntegrations: ['GOOGLECLOUD'],

        loading: false,
        isWorkingUnderRootFolder: true,
        isSourceFolderPathToCopyUnderRootFolder: true,
        members: [],
    };

    ctrl.$onInit = function () {
        if ($scope.data.configuredLogic && $scope.data.definition) {
            initializeEditMode();
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onFolderNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.folderNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPathExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.pathExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
        $scope.data.isWorkingUnderRootFolder = checkIfUnderRootFolder(
            $scope.data.definition.pathExpression.evaluatedExpression,
        );
    };

    $scope.onURLExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.urlExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.nameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onProjectIntegrationSelected = function (selectedProjectIntegration) {
        if (selectedProjectIntegration) {
            $scope.data.definition.googleCloudProjectIntegrationId = selectedProjectIntegration.id;
        } else {
            $scope.data.definition.googleCloudProjectIntegrationId = null;
        }

        $scope.onDefinitionChanged(true);
    };

    $scope.onPathFromExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.pathFromExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
        $scope.data.isSourceFolderPathToCopyUnderRootFolder = checkIfUnderRootFolder(
            $scope.data.definition.pathFromExpression.evaluatedExpression,
        );
    };

    $scope.onAutoRenameToggled = function (value) {
        $scope.data.definition.isAutoRename = value;
        $scope.onDefinitionChanged(true);
        return $q.resolve();
    };

    $scope.onIsAllowSharedFolderToggled = function (value) {
        $scope.data.definition.isAllowSharedFolder = value;
        $scope.onDefinitionChanged(true);
        return $q.resolve();
    };

    $scope.onIsAllowOwnershipTransferToggled = function (value) {
        $scope.data.definition.isAllowOwnershipTransfer = value;
        $scope.onDefinitionChanged(true);
        return $q.resolve();
    };

    $scope.onMemberEmailExpressionChanged = function (expression, shouldSaveLogic, member) {
        member.emailExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        $scope.data.definition.members = $scope.data.members.filter(
            (member) => member.emailExpression && member.emailExpression.evaluatedExpression,
        );

        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.addEmptyMember = function () {
        $scope.data.members.push({
            emailExpression: {},
        });
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        if ($scope.data.definition) {
            switch ($scope.data.definition.customActionKey) {
                case 'CREATE_SHARED_FOLDER':
                    if (!$scope.data.definition.members) {
                        $scope.data.definition.members = [];
                    }

                    $scope.data.members = $scope.data.definition.members;

                    if (!$scope.data.members.length) {
                        $scope.addEmptyMember();
                    }

                    break;
            }
        }
    }

    function checkIfUnderRootFolder(evaluatePathExpression) {
        // The root folder as configured in data sources.
        const rootFolder = `${$scope.data.projectIntegration.projectData.projectDatas[0].folder.displayName}/`;
        // Start from position 1 and not 0 because the '/' prefix of the regular path syntax.
        return evaluatePathExpression
            ? evaluatePathExpression.toLowerCase().startsWith(rootFolder.toLowerCase(), 1)
            : false;
    }
}

export default angular
    .module('tonkean.app')
    .controller('DropboxCustomActionsDefinitionCtrl', lateConstructController(DropboxCustomActionsDefinitionCtrl));
