<button
    class="tnk-tooltip mod-{{ data.tooltipDirection }} pointer inline-button padding-left-none filter-icon"
    uib-popover
    uib-popover-template="'workflow-versions-lists-filter-popover-template'"
    popover-is-open="data.filterPopoverOpen"
    popover-class="popover-no-max-width"
    popover-placement="bottom-left"
    popover-append-to-body="{{ data.appendFilterDialogToBody }}"
    type="button"
>
    <span class="tnk-tooltip-text">Filter</span>
    <span class="edit-button filter-trigger-icon common-size-xxxxxs padding-left-none">
        <tnk-icon src="/images/icons/version-control-filter.svg"></tnk-icon>
    </span>
</button>

<script type="text/ng-template" id="workflow-versions-lists-filter-popover-template">
    <form ng-submit="$event.preventDefault(); save();">
        <div class="workflow-versions-list-filter" ng-if="data.filterType === 'versions'">
            <div class="flex-no-shrink margin-right common-width-30">
                Version ID:
            </div>
            <input class="form-control" type="number" min="1" ng-model="data.sequentialIdentifier" />

            <div class="flex-no-shrink margin-right common-width-30">
                Maker:
            </div>
            <tnk-deprecated-people-selector
                class="flex-grow margin-none"
                no-validation="false"
                selected-people="data.publisher"
                no-custom="true"
                max-tags="1"
            ></tnk-deprecated-people-selector>

            <div class="margin-right common-width-30">
                From Date:
            </div>
            <div class="input-group flex-grow">
                <input class="form-control" uib-datepicker-popup="fullDate"
                       datepicker-options="{showWeeks: false}"
                       ng-model="data.from"
                       is-open="data.popovers.startedFromIsOpen"
                       close-text="Close"/>

                <span class="input-group-btn">
                        <button type="button" class="btn btn-default common-width-full"
                                ng-click="data.popovers.startedFromIsOpen = !data.popovers.startedFromIsOpen">
                            <i class="svg-icon-track svg-icon-hover-black common-color-light-grey">
                                <tnk-icon src="/images/icons/calendar.svg"></tnk-icon>
                            </i>
                        </button>
                    </span>
            </div>

            <div class="margin-right common-width-30">
                To Date:
            </div>
            <div class="input-group flex-grow">
                <input class="form-control" uib-datepicker-popup="fullDate"
                       datepicker-options="{showWeeks: false}"
                       ng-model="data.to"
                       is-open="data.popovers.startedToIsOpen"
                       close-text="Close"/>

                <span class="input-group-btn">
                        <button type="button" class="btn btn-default common-width-full"
                                ng-click="data.popovers.startedToIsOpen = !data.popovers.startedToIsOpen">
                            <i class="svg-icon-track svg-icon-hover-black common-color-light-grey">
                                <tnk-icon src="/images/icons/calendar.svg"></tnk-icon>
                            </i>
                        </button>
                    </span>
            </div>
        </div>
        <div class="workflow-versions-list-filter" ng-if="data.filterType === 'history'">
            <div class="flex-no-shrink margin-right common-width-30">
                Activity Type
            </div>
            <ui-select ng-model="data.activityType" theme="bootstrap">
                <ui-select-match>
                    {{data.activityType.activityTypeDisplayName}}
                </ui-select-match>
                <ui-select-choices repeat="activityType in data.activityTypes | filter:$select.search ">
                    <div ng-bind-html="activityType.activityTypeDisplayName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>

            <div class="flex-no-shrink margin-right common-width-30">
                Maker:
            </div>
            <tnk-deprecated-people-selector
                class="flex-grow margin-none"
                no-validation="false"
                selected-people="data.actor"
                no-custom="true"
                max-tags="1"
            ></tnk-deprecated-people-selector>
            <div ng-if="data.versions.length > 0" class="flex-no-shrink margin-right common-width-30">
                Version:
            </div>
            <ui-select ng-if="data.versions.length > 0" ng-model="data.subSequentialIdentifier" theme="bootstrap">
                <ui-select-match>
                    {{data.subSequentialIdentifier.label}}
                </ui-select-match>
                <ui-select-choices repeat="version in data.versions | filter:$select.search ">
                    <div ng-bind-html="version.label | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
            <div class="margin-right common-width-30">
                From Date:
            </div>
            <div class="input-group flex-grow">
                <input class="form-control" uib-datepicker-popup="fullDate"
                       datepicker-options="{showWeeks: false}"
                       ng-model="data.fromDate"
                       is-open="data.popovers.startedFromIsOpen"
                       close-text="Close"/>

                <span class="input-group-btn">
                        <button type="button" class="btn btn-default common-width-full"
                                ng-click="data.popovers.startedFromIsOpen = !data.popovers.startedFromIsOpen">
                            <i class="svg-icon-track svg-icon-hover-black common-color-light-grey">
                                <tnk-icon src="/images/icons/calendar.svg"></tnk-icon>
                            </i>
                        </button>
                    </span>
            </div>

            <div class="margin-right common-width-30">
                To Date:
            </div>
            <div class="input-group flex-grow">
                <input class="form-control" uib-datepicker-popup="fullDate"
                       datepicker-options="{showWeeks: false}"
                       ng-model="data.toDate"
                       is-open="data.popovers.startedToIsOpen"
                       close-text="Close"/>

                <span class="input-group-btn">
                        <button type="button" class="btn btn-default common-width-full"
                                ng-click="data.popovers.startedToIsOpen = !data.popovers.startedToIsOpen">
                            <i class="svg-icon-track svg-icon-hover-black common-color-light-grey">
                                <tnk-icon src="/images/icons/calendar.svg"></tnk-icon>
                            </i>
                        </button>
                    </span>
            </div>
        </div>

        <div class="flex flex-justify-end-no-xs margin-top">
            <button class="btn btn-secondary margin-right-xs" type="reset" ng-click="clear()">Clear</button>
            <button class="btn btn-primary" type="submit">Apply</button>
        </div>
    </form>
</script>
