<div class="margin-bottom margin-top-xs margin-left-xxxs">
    <a ng-click="data.matchingConfiguration = 'SIMPLE'">Simple</a>
    /
    <a ng-click="data.matchingConfiguration = 'ADVANCED'">Advanced</a>
</div>
<!-- Advanced matching -->
<div ng-if="data.matchingConfiguration === 'ADVANCED'">
    <tnk-custom-filters
        control="data.customQueryControl"
        items-source="EXTERNAL"
        project-integration="data.projectIntegration"
        selected-entity-type="data.entityMetadata && data.entityMetadata.entity ? data.entityMetadata.entity : data.entityMetadata"
        existing-definition="data.matchConfiguration.customQuery"
        group-id="data.groupId"
        workflow-version-id="data.workflowVersionId"
        reload-fields-on-new-field-definition-creation="'true'"
        filters-change-only-after-init="true"
        display-integration-indication="true"
        hide-time-range-selection="true"
        use-expressions-for-value="true"
        hide-close-button="true"
        bold-title="true"
        small-mod="true"
        preview-evaluation-source="data.previewEvaluationSource"
        on-filters-change="onAdvancedMatchingChanged(shouldNotSaveLogic)"
        show-field-inspect-modal="data.projectIntegration"
        inspect-entity-type="data.entityMetadata && data.entityMetadata.entity ? data.entityMetadata.entity : data.entityMetadata"
        mod-fixed-width="data.modFixedWidth"
        tabs-field-selector-mod-narrow="data.tabsFieldSelectorModNarrow"
        vertical-mod="true"
    ></tnk-custom-filters>
</div>

<!-- Simple matching -->
<div ng-if="data.matchingConfiguration === 'SIMPLE'">
    <tnk-workflow-match-by-field
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        project-integration="data.projectIntegration"
        entity-metadata="data.entityMetadata"
        entity-match-field="data.entityMatchField"
        worker-item-match-field-definition="data.workerItemMatchFieldDefinition"
        worker-item-match-special-field="data.workerItemMatchSpecialField"
        on-workflow-match-by-field-changed="
            onWorkflowMatchByFieldChanged(
                entityMatchField,
                workerItemMatchFieldDefinition,
                workerItemMatchSpecialField,
                shouldSaveLogic
            )
        "
    ></tnk-workflow-match-by-field>
</div>
