import type { PostSortRowsParams } from '@ag-grid-community/core';

import { isTempRowForAddition } from '../entities';
import type InitiativeRowData from '../entities/InitiativeRowData';

const onSortRowsCallback = (params: PostSortRowsParams<InitiativeRowData>) => {
    const sortIsActive = params?.api.getColumnState().some((columnState) => columnState.sort);

    if (sortIsActive) {
        const rowNodes = params.nodes;
        for (let i = 0; i < rowNodes.length; i++) {
            const insertPosition = rowNodes.length - 1;
            const currentNode = rowNodes[i];
            if (currentNode && isTempRowForAddition(currentNode.data!) && rowNodes) {
                const dummy = rowNodes.splice(i, 1)[0];
                if (dummy) {
                    rowNodes.splice(insertPosition, 0, dummy);
                }
            }
        }
    }
};
export default onSortRowsCallback;
