export const shortDateFormats = {
    sameDay: '[Today,] hh:mm A',
    nextDay: '[Tomorrow,] hh:mm A',
    nextWeek: 'dddd',
    lastDay: '[Yesterday,] hh:mm A',
    lastWeek: '[Last] dddd, hh:mm A',
    sameElse: 'MM/DD[,] hh:mm A',
};

export const fullDateFormat = 'MM/DD/YYYY hh:mm:ss A';
