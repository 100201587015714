<div class="flex-grow">
    <div class="common-title mod-light">Overview</div>
    <div class="enterprise-overview-section">
        <div class="common-size-xxs common-bold padding-top-xlg">Your plan</div>
        <hr class="common-separator mod-darker margin-top-xs margin-bottom-md" />
        <div>
            <span class="common-title mod-light">Enterprise</span>
            <span class="pull-right">
                <span class="common-size-xs common-bold">Seats</span>
                <span class="common-title mod-light">{{ data.enterprise.seats }}</span>
            </span>
        </div>
    </div>

    <div class="enterprise-overview-section">
        <div class="common-size-xxs common-bold padding-top-xlg">Name</div>
        <hr class="common-separator mod-darker margin-top-xs margin-bottom-md" />

        <div class="enterprise-name">
            <span
                class="common-title mod-light"
                ng-click="onEnterpriseNameClick()"
                ng-hide="data.toggles.editEnterpriseName"
            >
                <span>{{ data.enterprise.name }}</span>
                <span
                    ng-hide="data.toggles.editEnterpriseName"
                    class="enterprise-name-edit svg-icon-sm common-valign-text-bottom margin-right-xs"
                    ng-click="onEnterpriseNameClick()"
                >
                    <tnk-icon src="/images/icons/pencil.svg"></tnk-icon>
                </span>
            </span>

            <div class="enterprise-name-textbox mod-title" ng-show="data.toggles.editEnterpriseName">
                <form
                    ng-submit="updateEnterpriseName(data.tempEnterpriseName)"
                    name="enterprise-update-name-form"
                    id="enterprise-update-name-form"
                >
                    <input
                        type="text"
                        class="form-control common-title mod-light"
                        ng-model="data.tempEnterpriseName"
                        ng-keydown="onEnterpriseNameKeyDown($event)"
                        id="input-edit-enterprise-name"
                    />

                    <div class="text-right margin-top">
                        <button
                            type="button"
                            class="btn btn-default btn-sm"
                            ng-click="data.toggles.editEnterpriseName = false"
                        >
                            Cancel
                        </button>
                        <button
                            class="btn btn-primary btn-sm"
                            type="submit"
                            ng-disabled="data.loadingState.updateEnterpriseName"
                        >
                            Save
                            <i
                                class="loading-small mod-align-text-top margin-left-xxs"
                                ng-show="data.loadingState.updateEnterpriseName"
                            ></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <!-- Choosing the login type (with slack or google). -->
        <div class="margin-top">
            <!-- Title -->
            <div class="common-size-xs common-bold">Preferred authentication method</div>
            <p class="common-color-grey common-size-xxs">
                People with access to the account's boards will only be able to use the selected authentication method.
            </p>

            <div class="pointer margin-top-xs">
                <div class="flex-vmiddle margin-top-md">
                    <span
                        class="login-method"
                        ng-click="onStartEditLoginMethodMode()"
                        ng-hide="data.toggles.editLoginMethod"
                    >
                        <span ng-hide="!data.loginMethod.isSet">
                            Authenticate with
                            <span class="common-bold">{{ data.loginMethod.selectedMethod.label }}</span>
                        </span>
                        <span ng-hide="data.loginMethod.isSet">No restrictions</span>
                        <button
                            ng-hide="data.toggles.editLoginMethod"
                            class="
                                btn btn-no-style
                                login-method-edit
                                svg-icon-sm
                                common-valign-text-bottom
                                margin-right-xs margin-left-xxs
                            "
                            ng-click="onStartEditLoginMethodMode()"
                        >
                            <tnk-icon src="/images/icons/pencil.svg"></tnk-icon>
                        </button>
                    </span>
                </div>
                <div ng-hide="!data.toggles.editLoginMethod">
                    <div class="flex-vmiddle login-settings">
                        <!-- isSet Checkbox -->
                        <tnk-radio-button
                            radio-id="login-method-radio-no-restrictions"
                            radio-name="login-method-radio"
                            radio-label="No restrictions"
                            radio-value="false"
                            model="data.loginMethod.isSet.toString()"
                            is-required="true"
                            on-click="toggleIsLoginMethodSet(onClickParam)"
                            on-click-param="(false)"
                            classes="margin-top margin-right-md common-normal-weight"
                        ></tnk-radio-button>

                        <tnk-radio-button
                            radio-id="login-method-radio-authenticate"
                            radio-name="login-method-radio"
                            radio-label="Authenticate with"
                            radio-value="true"
                            model="data.loginMethod.isSet.toString()"
                            is-required="true"
                            on-click="toggleIsLoginMethodSet(onClickParam)"
                            on-click-param="(true)"
                            classes="margin-top common-normal-weight"
                        ></tnk-radio-button>

                        <!-- three dots when no restriction selected -->
                        <span class="margin-top-xs" ng-if="data.loginMethod.isSet">...</span>

                        <!-- login methods selection -->
                        <ui-select
                            class="ui-select-min-width margin-left-xs"
                            theme="bootstrap"
                            ng-model="data.loginMethod.selectedMethod"
                            ng-change="setLoginMethod(data.loginMethod.selectedMethod)"
                            ng-if="data.loginMethod.isSet"
                        >
                            <ui-select-match placeholder="Select">
                                {{ data.loginMethod.selectedMethod.label }}
                            </ui-select-match>
                            <ui-select-choices repeat="p in data.loginMethod.supportedMethods | filter:$select.search">
                                <div ng-bind-html="p.label | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>

                        <!-- Loading state -->
                        <i
                            class="loading-small margin-right-xxs margin-left"
                            ng-if="data.loginMethod.savingPreferredLoginType"
                        ></i>
                        <div class="text-right margin-left">
                            <button type="button" class="btn btn-default btn-sm" ng-click="cancelEditLoginMethod()">
                                Cancel
                            </button>
                            <button
                                class="btn btn-primary btn-sm"
                                type="button"
                                ng-click="saveLoginMethod()"
                                ng-disabled="data.loadingState.updateLoginMethod || data.loginMethodFormPristine"
                            >
                                Save
                                <i
                                    class="loading-small mod-align-text-top margin-left-xxs"
                                    ng-show="data.loadingState.updateLoginMethod"
                                ></i>
                            </button>
                        </div>
                    </div>
                    <!-- Error state -->
                    <span
                        class="common-color-danger margin-left"
                        ng-if="data.loginMethod.errorSavingPreferredLoginType"
                    >
                        {{ data.loginMethod.errorSavingPreferredLoginType }}
                    </span>
                    <form class="margin-top-xs" ng-if="data.loginMethod.selectedMethod.urlValidation">
                        <p class="common-color-grey common-size-xxs">
                            What is your {{ data.loginMethod.selectedMethod.label }} Url (i.e.
                            {{ data.loginMethod.selectedMethod.urlValidation.urlExample }}).
                        </p>
                        <input
                            name="loginUrl"
                            type="text"
                            class="form-control"
                            ng-model="data.loginMethod.loginMethodUrl"
                            placeholder="{{ data.loginMethod.selectedMethod.urlValidation.urlExample }}"
                            id="login-method-url"
                            ng-change="setLoginMethodFormDirty()"
                            required
                        />

                        <!-- Open advanced settings button -->
                        <div class="flex mod-justify-end" ng-if="data.loginMethod.selectedMethod.ssoUrlsEditable">
                            <button
                                class="btn btn-link flex-vmiddle"
                                data-automation="enterprise-overview-auth-open-advanced-settings"
                                ng-click="toggleAdvancedSettings()"
                                type="button"
                            >
                                <span class="margin-left-xs">Advanced Settings</span>
                                <span class="dropdown-chevron mod-static"></span>
                            </button>
                        </div>

                        <!-- Advanced settings -->
                        <div ng-if="data.advancedSettingsOpen && data.loginMethod.selectedMethod.ssoUrlsEditable">
                            <div class="margin-top-xs">
                                <p class="common-color-grey common-size-xxs">Authorization Endpoint Suffix: (change only if different from default e.g. PingFederated)</p>
                                <input
                                        type="text"
                                        class="form-control"
                                        ng-model="data.loginMethod.loginMethodSecrets.ssoUrls.authorizationEndpointSuffix"
                                        placeholder="as/authorize"
                                        id="sso-urls-authorization-endpoint-suffix"
                                        ng-change="setLoginMethodFormDirty()"
                                        required
                                />
                            </div>
                            <div class="margin-top-xs">
                                <p class="common-color-grey common-size-xxs">Token Endpoint Suffix: (change only if different from default e.g. PingFederated)</p>
                                <input
                                        type="text"
                                        class="form-control"
                                        ng-model="data.loginMethod.loginMethodSecrets.ssoUrls.tokenEndpointSuffix"
                                        placeholder="as/token"
                                        id="sso-urls-token-endpoint-suffix"
                                        ng-change="setLoginMethodFormDirty()"
                                        required
                                />
                            </div>
                            <div class="margin-top-xs">
                                <p class="common-color-grey common-size-xxs">UserInfo Endpoint Suffix: (change only if different from default e.g. PingFederated)</p>
                                <input
                                        type="text"
                                        class="form-control"
                                        ng-model="data.loginMethod.loginMethodSecrets.ssoUrls.userInfoEndpointSuffix"
                                        placeholder="as/userinfo"
                                        id="sso-urls-userinfo-endpoint-suffix"
                                        ng-change="setLoginMethodFormDirty()"
                                        required
                                />
                            </div>
                        </div>

                        <div
                            class="flex-vmiddle margin-top"
                            ng-hide="
                                !data.loginMethod.selectedMethod.secrets ||
                                !data.loginMethod.selectedMethod.secrets === 'ClientSecret'
                            "
                        >
                            <div class="flex-grow">
                                <p class="common-color-grey common-size-xs">Client ID</p>
                                <input
                                    name="clientId"
                                    type="text"
                                    class="form-control"
                                    ng-model="data.loginMethod.loginMethodSecrets.clientId"
                                    placeholder="ClientId"
                                    ng-change="setLoginMethodFormDirty()"
                                    required
                                />
                            </div>
                            <div class="flex-grow margin-left">
                                <p class="common-color-grey common-size-xs">Client Secret</p>
                                <input
                                    name="clientSecret"
                                    type="password"
                                    class="form-control"
                                    ng-model="data.loginMethod.loginMethodSecrets.clientSecret"
                                    placeholder="Client Secret"
                                    ng-change="setLoginMethodFormDirty()"
                                    required
                                />
                            </div>
                        </div>
                        <div class="flex-grow margin-top-lg">
                            <div class="secured-domain-title-wrapper">
                                <p class="common-color-grey common-size-xs common-normal-weight margin-right-md">
                                    Secured Domains
                                </p>
                                <div ng-show="data.loadingState.updateEnterpriseDomains" class="loading-small"></div>
                            </div>
                            <p class="common-size-xxs">
                                Emails containing the below domains will be directly routed into the selected
                                authentication url.
                            </p>
                            <tnk-domains-input
                                domains="data.enterpriseDomains"
                                update-domains="updateEnterpriseDomains"
                                class="inline-block margin-top domains-input"
                                placeholder="Enter secured domains"
                            ></tnk-domains-input>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="margin-top-lg">
            <div class="common-size-xs common-bold">Enterprise Admins</div>
            <p class="common-color-grey common-size-xxs">
                Can access this page, manage members, and every board they will create will automatically be added to
                this enterprise license.
            </p>
        </div>
    </div>

    <div>
        <div class="flex-vmiddle flex-wrap margin-top-lg" ng-show="!data.showEditOwners">
            <div
                class="common-tag-full mod-padding-sm margin-right-xs margin-bottom-xs"
                ng-repeat="owner in data.enterprise.owners"
            >
                <tnk-user user="owner" class="mod-one-line margin-right"></tnk-user>
            </div>
            <button
                class="btn btn-default settings-edit-owners margin-bottom-xs"
                ng-show="!data.showEditOwners"
                ng-click="editOwners()"
            >
                <i class="fa fa-pencil margin-right-xs"></i>
                Edit
            </button>
        </div>

        <form novalidate name="ownersForm" ng-show="data.showEditOwners" class="" ng-submit="saveOwners()">
            <tnk-deprecated-people-selector
                class="flex-grow common-width-75 margin-right"
                required
                ng-disabled="data.savingOwners"
                selected-people="data.owners"
                no-custom="false"
                no-validation="true"
                enterprise-id="data.enterprise.id"
            ></tnk-deprecated-people-selector>
            <div class="margin-top">
                <button
                    type="button"
                    class="btn btn-default margin-right-xs"
                    ng-click="data.showEditOwners=false"
                    ng-disabled="data.savingOwners"
                >
                    Cancel
                </button>
                <button type="submit" class="btn btn-primary" ng-disabled="data.savingOwners">Save</button>
            </div>
        </form>
    </div>
</div>
