import React, { useMemo } from 'react';
import styled from 'styled-components';

import SolutionVersionsSingleGroup from './SolutionVersionsSingleGroup';
import SolutionVersionsSingleGroupRow from './SolutionVersionsSingleGroupRow';
import SolutionVersionsVersionContainer from './SolutionVersionsVersionContainer';
import VersionComment from '../../../infrastructure/components/VersionComment';

import { HighlightableText, Tooltip } from '@tonkean/infrastructure';
import type { WorkflowFolderVersionSummary } from '@tonkean/tonkean-entities';
import type { WorkflowFolderVersionGroupSummary } from '@tonkean/tonkean-entities';
import { TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const SubHeading = styled.div`
    margin: 0 15px;
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    line-height: 10px;
    color: ${Theme.colors.gray_500};
`;

const ChangedGroups = styled.div`
    margin: 4px 15px 6px;
`;

const NotChangedModules = styled.div`
    text-decoration: underline;
`;

const NotChangedCount = styled.strong`
    font-weight: bold;
`;

const Comment = styled(VersionComment)`
    margin: 14px 15px 20px;
`;

interface Props {
    workflowFolderVersionSummary: WorkflowFolderVersionSummary;
    searchTerm: string;
}

const SolutionVersionsSingleVersion: React.FC<Props> = ({ workflowFolderVersionSummary, searchTerm }) => {
    const { workflowFolderVersion, workflowFolderVersionGroupSummaries } = workflowFolderVersionSummary;

    const { changedGroups, notChangedCount, notChangedNames, notChangedType } = useMemo(() => {
        const notChangedGroups: WorkflowFolderVersionGroupSummary[] = workflowFolderVersionGroupSummaries.filter(
            (summary) => !summary.hadChanges,
        );
        return {
            changedGroups: workflowFolderVersionGroupSummaries.filter((summary) => summary.hadChanges),
            notChangedNames: notChangedGroups
                .map((summary) => summary?.entityName ?? `Deleted module (${summary.workflowVersionId})`)
                .join(', '),
            notChangedCount: notChangedGroups.length,
            notChangedType: notChangedGroups.length === 1 ? notChangedGroups[0]?.entityType : null,
        };
    }, [workflowFolderVersionGroupSummaries]);

    const getNotChangedText = () => (notChangedType === TonkeanType.GROUP ? 'module was' : 'page was');

    return (
        <SolutionVersionsVersionContainer
            workflowFolderVersionSummary={workflowFolderVersionSummary}
            isSearching={!!searchTerm}
        >
            <SubHeading>Changes</SubHeading>
            <Comment user={workflowFolderVersion.creator}>
                <HighlightableText text={workflowFolderVersion.comment} highlightText={searchTerm} inline />
            </Comment>

            <SubHeading>Changed Modules and Pages</SubHeading>
            <ChangedGroups>
                {changedGroups.map((workflowFolderVersionGroupSummary) => (
                    <SolutionVersionsSingleGroup
                        key={workflowFolderVersionGroupSummary.workflowVersionId}
                        summary={workflowFolderVersionGroupSummary}
                    />
                ))}

                {!!notChangedCount && (
                    <SolutionVersionsSingleGroupRow hadChanged={false}>
                        <Tooltip content={notChangedNames} limitWidth={40}>
                            <NotChangedModules>
                                <NotChangedCount>{notChangedCount}</NotChangedCount> more{' '}
                                {notChangedCount === 1 ? getNotChangedText() : 'modules and pages were'} published with
                                no change
                            </NotChangedModules>
                        </Tooltip>
                    </SolutionVersionsSingleGroupRow>
                )}
            </ChangedGroups>
        </SolutionVersionsVersionContainer>
    );
};

export default SolutionVersionsSingleVersion;
