import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function HtmlEditorEditLinkCtrl($scope, modal) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        logicId: ctrl.logicId,
    };

    $scope.submit = function (url, label) {
        ctrl.onSubmit({
            url,
            label,
        });
    };

    $scope.openEditLinkModal = function () {
        modal
            .openEditHtmlEditLinkModal($scope.data.groupId, $scope.data.workflowVersionId, $scope.data.logicId)
            .then((result) => {
                $scope.submit(result.url, result.label);
            });
    };
}

export default angular.module('tonkean.app').controller('HtmlEditorEditLinkCtrl', lateConstructController(HtmlEditorEditLinkCtrl));
