import React from 'react';
import styled from 'styled-components';

import { SelectorTitle } from './SelectorTitle';
import InnerItemsItemWidgetDefaultOrderConfiguration from '../InnerItemsItemWidgetDefaultOrderConfiguration';

import { FormikTnkCustomFilters } from '@tonkean/angular-to-react-components';
import { Spacer } from '@tonkean/infrastructure';
import type { TonkeanType, TonkeanId } from '@tonkean/tonkean-entities';

const InnerItemsItemWidgetDefaultOrderConfigurationContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 20px;
`;

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
}
const FilterAndSortItems: React.FC<Props> = ({ groupId, workflowVersionId }) => {
    return (
        <>
            <SelectorTitle>Filter and sort items</SelectorTitle>
            <FormikTnkCustomFilters
                name="configuration.filters"
                itemsSource="COLUMN"
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                specialFieldsForFeatures={['ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER']}
                includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']}
                notAllowedConditionsSet={{ IsMemberOf: true }}
                hideTimeRangeSelection
                hideCloseButton
                editMode
                useExpressionsForValue
                verticalMod
                smallMod
            />

            <Spacer height={16} />
            <InnerItemsItemWidgetDefaultOrderConfiguration workflowVersionId={workflowVersionId} />
            <Spacer height={20} />
        </>
    );
};

export default FilterAndSortItems;
