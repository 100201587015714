import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function EvisortCustomActionsDefinitionCtrl($scope, integrations, integrationsConsts) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        definition: ctrl.definition, // The action definition.
        previewEvaluationSource: ctrl.previewEvaluationSource,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        fileEntityMetadata: integrationsConsts.getEntitiesMetadata()['EVISORT']['DOCUMENT'],
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'UPLOAD_DOCUMENT':
                if (!$scope.data.definition.fields) {
                    $scope.data.definition.fields = [];
                }
                break;
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onDestinationFolderExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationFolderExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDocumentIdExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.documentIdExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDocumentPathExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.documentPathExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (storageIntegrationId, newDocumentName, shouldSaveLogic) {
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.newDocumentName = newDocumentName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTargetPathTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.targetPathExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onContentTypeTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.contentTypeExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.addField = function () {
        $scope.data.definition.fields.push({
            fieldValueObjects: [],
        });
    };

    $scope.addFieldValueObject = function (parentIndex) {
        $scope.data.definition.fields[parentIndex].fieldValueObjects.push({});
    };

    $scope.removeField = function (index) {
        $scope.data.definition.fields.splice(index, 1);

        $scope.onDefinitionChanged(true);
    };

    $scope.removeFieldValueObject = function (parentIndex, index) {
        $scope.data.definition.fields[parentIndex].fieldValueObjects.splice(index, 1);

        $scope.onDefinitionChanged(true);
    };

    $scope.onFieldNameTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.fields[index].fieldNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFieldValueTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.fields[parentIndex].fieldValueObjects[index].valueExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('EvisortCustomActionsDefinitionCtrl', lateConstructController(EvisortCustomActionsDefinitionCtrl));
