<div class="person-inquiry-logic-configuration">
    <!-- Person selection title -->
    <div class="common-title-xxs margin-bottom">Who should Tonkean module ask?</div>

    <tnk-people-inquiry-button
        send-to="data.sendToType"
        on-send-to-change="(onSendToChange)"
    ></tnk-people-inquiry-button>

    <div
        class="channel-person-selector-spacer"
        ng-if="data.sendToType === 'CUSTOM'"
    ></div>

    <!-- Selecting message method -->
    <tnk-channel-or-person-selector
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        logic-id="data.configuredLogic.node.id"
        saved-channel-or-person-selection="data.existingChannelOrPersonSelectionConfiguration"
        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        on-channel-or-person-selection-changed="
            onChannelOrPersonSelectionChanged(channelOrPersonSelectionConfiguration, shouldSaveLogic)
        "
        configured-logic="data.configuredLogic"
        preview-evaluation-source="data.previewEvaluationSource"
        hide-fail-if-no-value="true"
        show-send-to="data.configuredLogic.node.customTriggerType === 'PERSON_INQUIRY' || data.configuredLogic.node.customTriggerType === 'SEND_NOTIFICATION'"
        show-reply-in-flow-item-thread="data.hasSlackMessagesAsDatasource || data.hasTeamsMessagesAsDataSource || data.hasEmailMessagesAsDatasource"
        show-reply-in-resume-thread="data.hasParentMonitoringThread"
        unique-identifier="whoToAsk"
        on-communication-project-integration-changed="
            onCommunicationIntegrationChanged(selectedCommunicationProjectIntegration)
        "
        communication-project-integration-selector="true"
        ng-if="data.sendToType === 'CUSTOM'"
    ></tnk-channel-or-person-selector>

    <tnk-people-directory-picker
        ng-if="data.sendToType === 'PEOPLE_DIRECTORY'"
        people-directories-name-expression="data.peopleDirectoriesNameExpression"
        update-people-directories-name-expression="updatePeopleDirectoriesNameExpression"
        send-to-group-of-people-as="data.sendToGroupOfPeopleAs"
        preview-evaluation-source="data.previewEvaluationSource"
        update-send-to-group-of-people-as="updateSendToGroupOfPeopleAs"
        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        custom-trigger="data.configuredLogic.node"
    ></tnk-people-directory-picker>

    <tnk-preview-notification-pane
        message-content="data.evaluatedBotMessageText"
        workflow-version-id="data.workflowVersionId"
        logic-id="data.configuredLogic.node.id"
        color="data.messageColor"
        add-link-text="data.addInitiativePageLink && tonkeanUtils.getItemDisplayNameForBotPreview(pm, data.groupId, wvm, scm) ? data.evaluatedPostText : undefined"
        fields="data.nonEditableExistingFields"
        preview-evaluation-source="data.previewEvaluationSource"
        buttons="data.buttons"
        send-on-behalf="data.fromPersonSelectionConfiguration.personSelectionType && !data.fromPersonSelectionConfiguration.tonkeanSelected"
        ng-if="
            data.sendToType === 'PEOPLE_DIRECTORY' &&
            !data.state.current.name.includes('history')
        "
    ></tnk-preview-notification-pane>

    <!-- separator -->
    <hr />

    <!-- Message content - only for people directories -->

    <div ng-if="data.sendToType === 'PEOPLE_DIRECTORY'">
        <!-- Subject header -->
        <div class="margin-bottom-xs common-title-xxs">Subject</div>

        <!-- Subject input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            saved-original-expression="data.emailSubject"
            saved-evaluated-expression="data.evaluatedEmailSubject"
            on-tonkean-expression-changed="
                onEmailSubjectTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            expression-unique-identifier="subject"
            automation-identifier-expression-value="people-directory-action-subject-expression-value"
            automation-identifier-expression-add-field="people-directory-action-subject-expression-value-add-field"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
        <div class="common-size-xxxs common-color-grey3 margin-bottom-20 margin-top">
            Will be used in communication sources that support subject
        </div>

        <div class="margin-bottom-xs common-title-xxs">
            Message
            <a
                class="inline-block common-italic margin-left"
                uib-tooltip="You can use markdown format to add styling to the message"
                target="_blank"
                href="https://learn.tonkean.com/formulas-conditions-30/markdown-format-56"
            >
                <i class="fa fa-info-circle"></i>
            </a>
        </div>
    </div>

    <!-- Message content -  only for email option-->
    <div
        ng-if="
            (data.channelOrPersonSelectionConfiguration.forceEmail ||
                data.channelOrPersonSelectionConfiguration.sendTo === 'EXTERNAL') &&
            data.sendToType !== 'PEOPLE_DIRECTORY'
        "
    >
        <!-- Email Subject -->
        <div class="margin-bottom-xs common-title-xxs">Email Subject</div>

        <!-- Subject Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            saved-original-expression="data.emailSubject"
            saved-evaluated-expression="data.evaluatedEmailSubject"
            on-tonkean-expression-changed="
                onEmailSubjectTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            expression-unique-identifier="subject"
            automation-identifier-expression-value="send-update-form-email-email-subject-expression-value"
            automation-identifier-expression-add-field="send-update-form-email-email-subject-expression-add-field"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>

        <!-- Email subject error -->
        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].emailSubject"
            class="margin-top common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].emailSubject }}
        </div>

        <!-- separator -->
        <hr />

        <!-- Title -->
        <div class="margin-bottom-xs common-title-xxs">
            Email Body (Html):
            <a
                class="inline-block common-italic margin-left"
                uib-tooltip="You can use markdown format to add styling to the message"
                target="_blank"
                ng-href="{{::$root.knowledgeBase.pages.formulasMarkdown}}"
            >
                <i class="fa fa-info-circle"></i>
            </a>
        </div>

        <!-- Plain Text / HTML option -->
        <div class="btn-group margin-bottom">
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': !data.sendAsPlainText }"
                uib-btn-radio="false"
                ng-model="data.sendAsPlainText"
                ng-click="onActionsChanged(true)"
            >
                HTML
            </label>
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': data.sendAsPlainText }"
                uib-btn-radio="true"
                ng-click="onActionsChanged(true)"
                ng-model="data.sendAsPlainText"
            >
                Plain-Text
            </label>
        </div>

        <!-- Title -->
        <div class="margin-bottom">Content of the email:</div>

        <!-- HTML Editor-->
        <div ng-if="!data.sendAsPlainText">
            <button class="btn btn-primary btn-slim" ng-click="openHtmlEditor()">Open Editor</button>
        </div>
    </div>

    <!-- Communication Integration Message Title -->
    <div
        ng-if="
            !data.channelOrPersonSelectionConfiguration.forceEmail &&
            data.channelOrPersonSelectionConfiguration.sendTo !== 'EXTERNAL' &&
            data.sendToType !== 'PEOPLE_DIRECTORY'
        "
        class="margin-bottom-xs common-title-xxs"
    >
        Message text:
        <a
            class="inline-block common-italic margin-left"
            uib-tooltip="You can use markdown format to add styling to the message"
            target="_blank"
            ng-href="{{::$root.knowledgeBase.pages.formulasMarkdown}}"
        >
            <i class="fa fa-info-circle"></i>
        </a>
    </div>

    <!-- Message content -  Plain Text Expression -->
    <div
        ng-if="
            (!data.channelOrPersonSelectionConfiguration.forceEmail &&
                data.channelOrPersonSelectionConfiguration.sendTo !== 'EXTERNAL') ||
            data.sendAsPlainText
        "
    >
        <!-- Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            saved-original-expression="data.botMessageText"
            saved-evaluated-expression="data.evaluatedBotMessageText"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            expression-unique-identifier="input"
            automation-identifier-expression-value="send-update-form-email-content-text-expression-value"
            automation-identifier-expression-add-field="send-update-form-email-content-text-expression-add-field"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>

    <div ng-if="data.invalidLogics[data.configuredLogic.node.id].text" class="margin-top common-color-danger">
        {{ data.invalidLogics[data.configuredLogic.node.id].text }}
    </div>

    <div ng-if="data.includeFormAttachment">
        <!-- Separator -->
        <hr />

        <!-- Choosing form title -->
        <div class="common-title-xxs margin-bottom-xs">Choose update form</div>

        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noFormSelected"
            class="margin-top common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].noFormSelected }}
        </div>

        <!-- Form Selector-->
        <tnk-form-selector
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            selected-form-id="data.formId"
            existing-form-matched-entity-field-id="data.formMatchedEntityFieldId"
            hide-create-forms="true"
            on-matched-entity-created-or-updated="onFormMatchedEntityCreatedOrUpdated(field)"
            on-form-selected-open-confirmation="onFormSelectedOpenConfirmation(form)"
            on-form-selected="onFormSelected(form)"
            open-field-modal="data.openFieldModal"
            opened-from-custom-trigger-id="data.configuredLogic.node.id"
            form-type="data.formType"
            show-workflow-folder-forms="$root.features.currentProject.tonkean_feature_form_match_entity"
            automation-identifier="send-update-form-open-form-drop-down"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        ></tnk-form-selector>

        <div ng-if="data.sendToType === 'PEOPLE_DIRECTORY'">
            <tnk-native-form-capabilities
                set-form-type="selectFormType"
                current-form-type="data.formType"
            ></tnk-native-form-capabilities>
        </div>

        <!-- Form Type selection -->
        <div
            class="flex flex-vmiddle common-normal-weight margin-top-xs"
            ng-if="data.sendToType !== 'PEOPLE_DIRECTORY'"
        >
            <span class="flex-no-shrink margin-right-xxs">Form Type</span>

            <!-- Web Form-->
            <div class="margin-top-xs margin-left-xs">
                <tnk-radio-button
                    radio-id="form-type-selection-webform-{{ data.workflowVersionId }}"
                    radio-name="formType-selection-{{ data.workflowVersionId }}"
                    uib-tooltip="The form will open in a Tonkean web form."
                    radio-value="WEBFORM"
                    model="data.formType"
                    radio-label="Web Form"
                    on-click="selectFormType(onClickParam)"
                    on-click-param="('WEBFORM')"
                    is-required="true"
                    automation-identifier="send-update-form-form-type-web"
                ></tnk-radio-button>
            </div>

            <!-- Dialog -->
            <div class="margin-top-xs margin-left-xs flex-no-shrink">
                <tnk-radio-button
                    radio-id="form-type-selection-dialog-{{ data.workflowVersionId }}"
                    radio-name="formType-selection-{{ data.workflowVersionId }}"
                    uib-tooltip="{{
                        'The form will open a dialog in Slack.' +
                            (!data.slackSyncAndReplyOnRootMonitorItem &&
                            (data.channelOrPersonSelectionConfiguration.forceEmail ||
                                data.selectedCommunicationProjectIntegration.integrationType !== 'SLACK')
                                ? 'This option is not available when Microsoft Teams/Email is selected as a communication source.'
                                : '')
                    }}"
                    radio-value="DIALOG"
                    model="data.formType"
                    radio-label="Slack Dialog"
                    on-click="selectFormType(onClickParam)"
                    on-click-param="('DIALOG')"
                    is-required="false"
                    view-only="data.form.formQuestionType === 'UPLOAD_FILES' || (!data.slackSyncAndReplyOnRootMonitorItem && (data.channelOrPersonSelectionConfiguration.forceEmail || data.selectedCommunicationProjectIntegration.integrationType !=='SLACK'))"
                    automation-identifier="send-update-form-slack-form-type-slack"
                ></tnk-radio-button>
            </div>
        </div>

        <!-- Form type error -->
        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].formTypeError"
            class="margin-top common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].formTypeError }}

            <a ng-click="editForm(data.formId, data.form.formType)">Edit</a>
        </div>

        <!-- Upload files data storage selection -->
        <div ng-if="data.form.formQuestionType === 'UPLOAD_FILES'">
            <tnk-upload-files-data-storage-selection
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                on-data-storage-changed="(onDataStorageChanged)"
                current-selected-data-storage-id="data.dataStorageId || data.form.definition.dataStorageId"
                current-folder-expression="data.folderIdExpression || data.form.definition.destinationFolderId"
                preview-evaluation-source="data.previewEvaluationSource"
                validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                form="data.form"
                environment="data.environment"
                disabled="data.disableStorageSelection"
            ></tnk-upload-files-data-storage-selection>
        </div>

        <!-- Fill Form Custom Label -->
        <div ng-if="data.formId">
            <hr />
            <div class="common-title-xxs margin-bottom-xs">Custom Label for Fill Form Button</div>
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="'Fill Form'"
                saved-original-expression="data.fillFormButtonLabel"
                saved-evaluated-expression="data.evaluatedFillFormButtonLabel"
                on-tonkean-expression-changed="
                    updateFormFillButtonName(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
                preview-evaluation-source="data.previewEvaluationSource"
            ></tnk-tonkean-expression>
        </div>

        <!--Display Form preview (including title)-->
        <span ng-if="data.formId">
            <hr />
            <div class="margin-top-lg">
                <!-- Title -->
                <div class="flex-vmiddle">
                    <div class="flex-grow margin-bottom common-bold margin-right-xs">Form Preview:</div>
                    <div class="margin-bottom">
                        <button
                            class="btn btn-link"
                            data-automation="send-update-form-form-preview-edit-button"
                            ng-class="{ disabled: data.form.group.id !== data.groupId }"
                            ng-click="data.form.group.id === data.groupId && editForm(data.formId, data.form.formType)"
                            tooltip-placement="left"
                            uib-tooltip="{{
                                data.form.group.id !== data.groupId ? 'To edit the form move to it\'s Module' : ''
                            }}"
                        >
                            Edit
                        </button>
                    </div>
                </div>

                <!--Form preview-->
                <tnk-preview-form-ctrl
                    form-id="data.formId"
                    form="data.form"
                    form-type="data.formType"
                    send-to-type="data.sendToType"
                ></tnk-preview-form-ctrl>
            </div>
        </span>
    </div>

    <div ng-if="data.includeItemInterfaceAttachment">
        <!-- Separator -->
        <hr />

        <!-- Choosing form title -->
        <div class="common-title-xxs margin-bottom-xs">Interface</div>

        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noItemInterfaceSelected"
            class="margin-top common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].noItemInterfaceSelected }}
        </div>

        <!-- Item Interface Selector-->
        <tnk-item-interface-selector
            group-id="data.groupId"
            workflow-version-id="data.workflowVersionId"
            selected-item-interface-id="data.itemInterfaceId"
            on-item-interface-selected="(onItemInterfaceSelected)"
            environment="data.environment"
            data-automation="item-interface-selector"
        ></tnk-item-interface-selector>
    </div>

    <div ng-if="data.includeFieldAttachment">
        <!-- Separator -->
        <hr />

        <div class="margin-bottom-xs common-title-xxs">Select a field to ask about:</div>

        <!-- Field selection -->
        <tnk-field-selector
            items-source="COLUMN"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            selected-field="data.selectedField"
            group-by="groupBy"
            display-integration-indication="true"
            on-field-selected="onFieldOptionSelected(selectedField, selectedFieldIdentifier)"
            project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
            inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
            only-updatable-field-definitions="true"
            auto-create-column-fields="true"
            add-fields-quick-create-options="true"
            special-fields-for-features="data.specialFieldsForFeatures"
        ></tnk-field-selector>

        <!-- Form type error -->
        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noFormSelected"
            class="margin-top common-color-danger"
        >
            Please select a field.
        </div>

        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noFieldChosen"
            class="margin-top common-color-danger"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].noFieldChosen }}
        </div>

        <hr />
    </div>

    <!-- Separator -->
    <hr ng-if="data.enableBotButtons && data.channelOrPersonSelectionConfiguration.sendTo !== 'EXTERNAL'" />

    <!-- Module buttons -->
    <div ng-if="data.enableBotButtons && data.channelOrPersonSelectionConfiguration.sendTo !== 'EXTERNAL'">
        <!-- Buttons selection title -->
        <div>
            <div class="common-title-xxs">Add action buttons to the message</div>

            <span class="common-size-xxs common-color-grey3">Each of the buttons will trigger another action</span>
        </div>

        <!-- Buttons-->
        <div ng-if="!data.buttonsLoading">
            <div class="buttons-draggable-list flex flex-col common-draggable-list">
                <div
                    ng-repeat="button in data.buttons"
                    class="common-draggable-list-item flex-vmiddle show-edit-when-hover-visibility"
                >
                    <div class="svg-icon svg-icon-align-text-base common-color-border-light drag margin-right">
                        <tnk-icon src="/images/icons/3lines-h.svg"></tnk-icon>
                    </div>

                    <!-- New button-->
                    <input
                        ng-if="!button.customTriggerId"
                        class="form-control"
                        ng-model="button.text"
                        ng-blur="onActionsChanged(false)"
                    />

                    <!-- Existing button -->
                    <div ng-if="button.customTriggerId">{{ button.text }}</div>
                </div>
            </div>
            <!-- Add button -->
            <div class="padding-normal-xs">
                <a
                    ng-click="addButton()"
                    data-automation="tnk-person-inquiry-logic-configuration-template-add-action-button"
                >
                    + Add action button
                </a>
            </div>
        </div>

        <i ng-if="data.buttonsLoading" class="loading"></i>

        <hr />
    </div>

    <tnk-thread-reply
        on-toggled="onActionsChanged(shouldSaveLogic, null, childrenCustomTriggersToCreate)"
        workflow-version-id="{{ data.workflowVersionId }}"
        configured-logic="data.configuredLogic"
        definition="data.threadReplyDefinition"
        monitor-replies-on-original-message="data.monitorRepliesOnOriginalMessage"
        group-id="data.groupId"
    ></tnk-thread-reply>

    <div ng-if="!data.includeItemInterfaceAttachment">
        <!-- Separator -->
        <hr ng-if="data.channelOrPersonSelectionConfiguration.sendTo !== 'EXTERNAL'" />
        <!-- Fields selection title -->
        <div ng-if="data.channelOrPersonSelectionConfiguration.sendTo !== 'EXTERNAL'">
            <div class="common-title-xxs">Add data of fields to the message</div>
            <span class="common-size-xxs common-color-grey3">Enrich your message with context</span>
        </div>

        <!-- Fields -->
        <tnk-multi-field-quick-selection
            ng-if="data.channelOrPersonSelectionConfiguration.sendTo !== 'EXTERNAL'"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            include-global-fields="true"
            custom-actions="data.customActionsOnFields"
            on-fields-change="onFieldsChange(fields)"
            existing-fields="data.nonEditableExistingFields"
            special-fields-for-features="['ADDED_FIELDS_TO_BOT_MESSAGE']"
        ></tnk-multi-field-quick-selection>
    </div>

    <!-- From (sender) configuration -->
    <!-- Doesnt work if the target is person and we are sending through communication integration -->
    <div
        ng-if="
            data.channelOrPersonSelectionConfiguration.targetType !== 'person' ||
            data.channelOrPersonSelectionConfiguration.forceEmail ||
            data.channelOrPersonSelectionConfiguration.sendTo === 'EXTERNAL'
        "
    >
        <!-- Separator -->
        <hr />
        <!-- Send message by title -->
        <div class="common-title-xxs">Message sender:</div>
        <div ng-if="data.channelOrPersonSelectionConfiguration.sendTo === 'EXTERNAL' && data.messageSenderDisclaimer">
        <p class="common-size-xxs common-color-grey3">
            {{ data.messageSenderDisclaimer }}
        </p>
        </div>
        <!-- From person selection options -->
        <!-- only-tonkean-option: you must check first that selectedCommunicationProjectIntegration, sendToType and channelOrPersonSelectionConfiguration are not empty and only then check the values-->
        <tnk-person-selection-configuration
            class="block margin-top-xs"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            support-quick-create="true"
            saved-person-selection-configuration="data.fromExistingPersonSelectionConfiguration"
            saved-service-account-selection-configuration="data.fromExistingServiceAccountConfiguration"
            on-person-selection-configuration-changed="
                onFromPersonSelectionChanged(personSelectionConfiguration, shouldSaveLogic)
            "
            configured-logic="data.configuredLogic"
            max-people="1"
            radio-id-prefix="'from-' + data.workflowVersionId"
            preview-evaluation-source="data.previewEvaluationSource"
            hide-fail-if-no-value="true"
            show-tonkean-option="true"
            show-service-account-option="data.channelOrPersonSelectionConfiguration.sendTo === 'EXTERNAL' && pm.project.features.tonkean_feature_allow_from_service_account"
            unique-identifier="messageBy"
            only-tonkean-option="
                !!data.selectedCommunicationProjectIntegration && data.selectedCommunicationProjectIntegration.integrationType !=='SLACK' &&
                !!data.sendToType && data.sendToType !== 'PEOPLE_DIRECTORY' &&
                !!data.channelOrPersonSelectionConfiguration && !data.channelOrPersonSelectionConfiguration.forceEmail"
        ></tnk-person-selection-configuration>
    </div>

    <!-- Attachments -->
    <div
        ng-if="
            $root.features[pm.project.id].tonkean_feature_allow_ask_a_question_external_emails &&
            data.channelOrPersonSelectionConfiguration.forceEmail
        "
    >
        <hr />

        <tnk-send-email-attachments
            group-id="data.groupId"
            workflow-version-id="data.workflowVersionId"
            configured-logic-id="data.configuredLogic.node.id"
            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
            existing-attachments-configuration="data.attachments"
            on-attachments-changed="onAttachmentsChanged(attachments, shouldSaveLogic)"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-send-email-attachments>
    </div>

    <!-- Display settings -->
    <div
        ng-if="data.channelOrPersonSelectionConfiguration.sendTo !== 'EXTERNAL' && !data.includeItemInterfaceAttachment"
    >
        <!-- Separator -->
        <hr />

        <!-- Title -->
        <div class="common-title-xxs margin-bottom">Display settings</div>

        <!-- Hide initiative page link-->
        <div class="flex-vmiddle">
            <span class="margin-right">Add a link to the item in the Business Report</span>
            <tnk-toggle
                is-active="data.addInitiativePageLink"
                size="xs"
                on-toggle-click="onToggleAddInitiativePageLink()"
            ></tnk-toggle>
        </div>

        <div class="flex-vmiddle margin-top">
            <div class="margin-right">Color:</div>

            <div class="rounded-box mod-no-border padding-left-lg" ng-style="{ 'background-color': data.messageColor }">
                <tnk-color-options-picker
                    selected-color="data.messageColor"
                    color-options="data.availableColors"
                    on-color-selected="onMessageColorSelected(color)"
                ></tnk-color-options-picker>
            </div>
        </div>

        <div class="common-color-light-grey common-size-xxxxs margin-top-xs">
            * Color will appear only if there are fields{{ data.enableBotButtons ? ' or buttons' : '' }}.
        </div>

        <hr ng-if="data.sendToType === 'CUSTOM'" />

        <!-- Form Invitation Preview -->
        <div class="margin-top-lg" ng-if="data.sendToType === 'CUSTOM'">
            <!-- Title -->
            <div class="margin-bottom common-bold">
                {{
                    data.channelOrPersonSelectionConfiguration.forceEmail ||
                    data.channelOrPersonSelectionConfiguration.sendTo === 'EXTERNAL'
                        ? 'Email Preview:'
                        : 'Message Preview:'
                }}
            </div>

            <!--Form Invitation Preview -->
            <tnk-preview-bot-bubble-ctrl
                text="data.botMessageText"
                post-text="data.addInitiativePageLink && tonkeanUtils.getItemDisplayNameForBotPreview(pm, data.groupId, wvm, scm)"
                post-text-evaluated-expression="data.evaluatedPostText"
                preview-type="text"
                placeholder="Your {{ data.enableBotButtons ? 'question' : 'message' }} will appear here"
                is-email="data.channelOrPersonSelectionConfiguration.forceEmail || data.channelOrPersonSelectionConfiguration.sendTo === 'EXTERNAL'"
                user="as.currentUser"
                no-triangle="true"
                color="data.messageColor"
                send-on-behalf="data.fromPersonSelectionConfiguration.personSelectionType && !data.fromPersonSelectionConfiguration.tonkeanSelected"
                bot-fields="data.nonEditableExistingFields"
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                bot-buttons="data.buttons"
                logic-id="data.configuredLogic.node.id"
                preview-evaluation-source="data.previewEvaluationSource"
                text-evaluated-expression="data.evaluatedBotMessageText"
                html-preview="!data.sendAsPlainText && (data.channelOrPersonSelectionConfiguration.forceEmail || data.channelOrPersonSelectionConfiguration.sendTo === 'EXTERNAL')"
            ></tnk-preview-bot-bubble-ctrl>
        </div>
    </div>
</div>
