import type { TElement } from '@udecode/plate';
import { useMemo } from 'react';

import createMarketplaceItemTemplateNameFromDisplayName from '../../getTemplateNameFromModuleName';
import type { MarketplaceItemCreateSection } from '../components/MarketplaceItemCreateModalSection';

import type { MarketplaceItem, TonkeanId, TonkeanType, WorkflowFolder } from '@tonkean/tonkean-entities';
import { BlueprintSolutionType, MarketplaceItemType, type TonkeanUploadedFile } from '@tonkean/tonkean-entities';

const useMarketplaceItemInitialValues = (
    projectId: TonkeanId<TonkeanType.PROJECT>,
    sections: MarketplaceItemCreateSection[],
    marketplaceItemType: MarketplaceItemType,
    existingMarketplaceItem?: MarketplaceItem,
    workflowFolder?: WorkflowFolder,
    entityNameToCopy?: string,
    entityIdToCopy?: TonkeanId,
    marketplaceItemImages?: TonkeanUploadedFile[],
    blueprintSolutionType?: BlueprintSolutionType,
) => {
    return useMemo(() => {
        const configurationInitialValues: Record<string, any> =
            existingMarketplaceItem?.configuration ??
            Object.fromEntries(
                sections
                    .map((section) => section.configurationInitialValues)
                    .filter(Boolean)
                    .flatMap((entry) => Object.entries(entry)),
            );

        const solutionTitle = workflowFolder?.displayName ?? '';
        const title = marketplaceItemType == MarketplaceItemType.SOLUTION ? solutionTitle : entityNameToCopy ?? '';
        const templateName = createMarketplaceItemTemplateNameFromDisplayName(title);
        const subTitle: TElement[] | undefined = [
            {
                type: 'p',
                children: [{ text: title ?? '' }],
            },
        ];
        const shouldSubscribeOnInstall = existingMarketplaceItem
            ? existingMarketplaceItem.shouldSubscribeOnInstall
            : marketplaceItemType === MarketplaceItemType.DATA_SOURCE;

        if (marketplaceItemType === MarketplaceItemType.SOLUTION && !existingMarketplaceItem) {
            configurationInitialValues.shouldBePublished =
                blueprintSolutionType === BlueprintSolutionType.SOLUTION_TEMPLATE;
            configurationInitialValues.blueprintSolutionType =
                blueprintSolutionType ?? BlueprintSolutionType.MARKETPLACE_BLUEPRINT;
        }

        return {
            projectId,
            entityId: entityIdToCopy,
            type: marketplaceItemType,
            templateName: existingMarketplaceItem?.templateName || templateName,
            title: existingMarketplaceItem?.title || title,
            previewSummary: existingMarketplaceItem?.previewSummary || '',
            subtitle: existingMarketplaceItem?.subtitle || subTitle,
            iconUrl: existingMarketplaceItem?.iconUrl || '',
            configuration: configurationInitialValues,
            version: existingMarketplaceItem?.version || '1',
            categories: existingMarketplaceItem?.categories || [],
            images: marketplaceItemImages || undefined,
            shouldSubscribeOnInstall,
            isViewOnly: existingMarketplaceItem?.isViewOnly ?? false,
            isHidden: existingMarketplaceItem?.isHidden ?? false,
        };
    }, [
        existingMarketplaceItem,
        marketplaceItemType,
        workflowFolder,
        entityNameToCopy,
        projectId,
        entityIdToCopy,
        marketplaceItemImages,
        sections,
        blueprintSolutionType,
    ]);
};

export default useMarketplaceItemInitialValues;
