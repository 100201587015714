import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const MonitorTracksAutonomousItemCreatedLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.MONITOR_TRACKS,
    title: 'Item Is Added',
    secondaryType: 'AUTONOMOUS_ITEM_CREATED',
    iconClass: 'mod-monitor-tracks',
    secondaryIconClass: 'icon-created',
    description: 'Created items will be monitored and acted upon by configuration.',
    longDescription: 'This trigger will run when a new item enters the flow, either synced or manually created.',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    cantMove: true,
    cantDrag: true,
};

export default MonitorTracksAutonomousItemCreatedLogicBlockConfig;
