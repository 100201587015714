import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import SolutionManagingModalHeader from './SolutionManagingModalHeader';
import { ReactComponent as AvatarIcon } from '../../../../images/icons/avatar.svg';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { DeprecatedPeopleSelector } from '@tonkean/angular-to-react-components';
import {
    CloseButton,
    ErrorMessage,
    LoadingCircle,
    ModalBody,
    ModalFooter,
    useCloseCallback,
} from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

const SaveButton = styled(Button)`
    margin-left: 8px;
`;

const PublishersModalBody = styled(ModalBody)`
    overflow: visible;
    z-index: 2;
`;

const PublishersErrorBox = styled(ErrorMessage)`
    margin-top: 10px;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    margin-right: 5px;
`;

const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ErrorText = styled.div`
    color: ${Theme.colors.error};
`;

interface Props {
    workflowFolder: WorkflowFolder;
    initiallyFocusedRef: React.Ref<HTMLButtonElement>;
}

const ManagePublishersModalContent: React.FC<Props> = ({ workflowFolder, initiallyFocusedRef }) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const {
        data: publishersResponse,
        error: errorLoadingPublishers,
        loading: loadingPublishers,
    } = useTonkeanService('getWorkflowFolderPublishers', workflowFolder.id);

    const publishersCopy = useMemo(() => {
        return publishersResponse?.entities?.length
            ? publishersResponse?.entities.filter((person) => !person.systemUtilized)
            : [];
    }, [publishersResponse]);

    const onClose = useCloseCallback();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();

    const onSave = useCallback(() => {
        setLoading(true);
        if (publishersCopy.length === 0) {
            setError('At least one solution publisher is required.');
            setLoading(false);
        } else {
            const supportUser = workflowFolder.owners.filter((person) => person.systemUtilized);
            workflowFolderManager
                .setWorkflowFolderPublishers(
                    workflowFolder.project.id,
                    workflowFolder.id,
                    publishersCopy.concat(supportUser),
                )
                .then(() => onClose())
                .catch((error) => {
                    setError(error.data.error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [
        onClose,
        publishersCopy,
        workflowFolder.id,
        workflowFolder.owners,
        workflowFolder.project.id,
        workflowFolderManager,
    ]);

    return (
        <>
            <PublishersModalBody>
                <SolutionManagingModalHeader workflowFolder={workflowFolder} icon={<AvatarIcon />}>
                    Manage Solution Publishers
                </SolutionManagingModalHeader>

                {!loadingPublishers && !errorLoadingPublishers && (
                    <DeprecatedPeopleSelector
                        selectedPeople={publishersCopy}
                        disabled={false}
                        onTagAdded={($tag) => console.log(`tag added ${$tag}`)}
                        onTagRemoved={($tag) => console.log(`tag removed ${$tag}`)}
                        noCustom
                        doNotSearchOnlyOwnersInGroup
                    />
                )}
                {loadingPublishers && (
                    <LoadingContainer>
                        <StyledLoadingCircle />
                        <span>Loading solution publishers...</span>
                    </LoadingContainer>
                )}
                {errorLoadingPublishers && <ErrorText>Error loading solution publishers...</ErrorText>}
                {error && <PublishersErrorBox>{error}</PublishersErrorBox>}
            </PublishersModalBody>
            <ModalFooter align="right">
                {loading && <LoadingCircle className="margin-right-xs" />}
                <CloseButton disabled={loading} onClick={() => onClose()} ref={initiallyFocusedRef} outlined>
                    Cancel
                </CloseButton>

                <SaveButton onClick={onSave} disabled={loading}>
                    Save
                </SaveButton>
            </ModalFooter>
        </>
    );
};
export default ManagePublishersModalContent;
