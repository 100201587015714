import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { HighlightableText, useResizeObserver } from '@tonkean/infrastructure';
import { CheckIconSVG as CheckIcon } from '@tonkean/svg';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

interface Props {
    data: {
        fields: any[];
        viewOnly: boolean;
        fieldQuery: string;
        onFieldSelected: ((field: any) => void) | undefined;
        multiSelectMap: Record<string, any>;
        setItemSize: (index: number, height: number | undefined) => void;
        defaultRowHeight: number;
    };
    index;
    style;
}

const FieldInspectRow: React.FC<Props> = ({
    data: { fields, viewOnly, fieldQuery, onFieldSelected, multiSelectMap, setItemSize },
    index,
    style,
}) => {
    const field = fields[index];

    let classNames = 'field-inspect-table-line mod-row';
    if (!viewOnly) {
        classNames += ' mod-row-hover';
    }

    const fieldValueText = useMemo(() => {
        return field?.type === 'date' || field?.type === 'datetime'
            ? dayjs(field?.value)?.format('MMM DD YYYY HH:mm:ss Z')
            : field?.value;
    }, [field?.type, field?.value]);

    let checkIconClasses = 'svg-icon-xxs field-inspect-table-line-icon icon-square-background';
    if (!!multiSelectMap[field?.name]) {
        checkIconClasses += ' mod-selected';
    }

    const { setNode } = useResizeObserver((entry) => {
        setItemSize(index, entry.borderBoxSize[0]?.blockSize);
    });

    return (
        <Clickable
            buttonAsDiv={!onFieldSelected}
            data-automation="field-inspect-fields-row"
            onClick={onFieldSelected && (() => onFieldSelected(field))}
            style={style}
        >
            <div ref={setNode} className={classNames}>
                <div>
                    <HighlightableText
                        highlightText={fieldQuery}
                        text={field.displayName}
                        data-automation={`field-inspect-field-name-${field.displayName}`}
                    />
                </div>

                <div className="fs-censored">
                    <HighlightableText
                        highlightText={fieldQuery}
                        text={fieldValueText}
                        data-automation={`field-inspect-field-value-${field.displayName}`}
                    />
                </div>

                {!viewOnly && (
                    <span className={checkIconClasses}>
                        <CheckIcon />
                    </span>
                )}
            </div>
        </Clickable>
    );
};

export default FieldInspectRow;
