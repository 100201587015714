import { insertNodes, usePlateEditorState } from '@udecode/plate';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { ELEMENT_TNK_VARIABLE } from './coreEditorConsts';
import type { CoreEditorVariableElement } from './coreEditorVariableElement';

import { TnkTabsFieldSelector } from '@tonkean/angular-to-react-components';
import { LoadingCircle } from '@tonkean/infrastructure';
import { TonkeanExpressionEditorContext } from '@tonkean/infrastructure';
import type { ServerFormulaExpressionNode } from '@tonkean/tonkean-entities';
import { FormulaTreeVariableNode } from '@tonkean/tonkean-entities';
import { rootFormulaField } from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    .tabs-field-selector-btn-override {
        border: none;

        svg {
            width: 13px;
        }
    }
`;

const CoreEditorTonkeanExpressionToolbarButton: React.FC = () => {
    const { workflowVersion } = useContext(TonkeanExpressionEditorContext);

    const editor = usePlateEditorState();
    const insertVariable = (editor, expression: ServerFormulaExpressionNode) => {
        return insertNodes<CoreEditorVariableElement>(editor, {
            type: ELEMENT_TNK_VARIABLE,
            expression,
            children: [{ text: '' }],
        });
    };

    if (!workflowVersion) {
        return <LoadingCircle />;
    }

    return (
        <Wrapper>
            <TnkTabsFieldSelector
                automationIdentifierExpressionAddField="core-editor-tonkean-expression-tool-bar-button-add-expressions"
                groupId={workflowVersion.groupId}
                workflowVersionId={workflowVersion.id}
                onFieldSelectedReact={(field, expressionNode) => {
                    const finalExpressionNode =
                        expressionNode ||
                        new FormulaTreeVariableNode(field.id, field.name, field.fieldType, rootFormulaField, 0);

                    insertVariable(editor, finalExpressionNode.getServerFormulaNode());
                }}
                popoverPlacement="bottom-left"
                buttonClassOverride="tabs-field-selector-btn-override"
                tooltip="Add Expression"
            />
        </Wrapper>
    );
};

export default CoreEditorTonkeanExpressionToolbarButton;
