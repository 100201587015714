import FormulaTreeNodeBase from './FormulaTreeNodeBase';
import { FieldType } from '../../FieldDefinition';
import type FormulaField from '../FormulaField';
import { FormulaTreeNodeType } from '../FormulaTreeNodeType';
import type { ServerFormulaExpressionNode, ServerFormulaExpressionVariableNode } from '../ServerFormulaExpressionNode';

export class FormulaTreeVariableNode extends FormulaTreeNodeBase {
    public declare type: FormulaTreeNodeType.VARIABLE;

    constructor(
        public variableId: string,
        public name: string | undefined,
        public override dataType: FieldType,
        public override field: FormulaField,
        id?: number,
    ) {
        super(FormulaTreeNodeType.VARIABLE, field, id);
        this.classifyType();
    }

    public override toString(evaluated: boolean = false) {
        if (evaluated) {
            return `{${this.variableId}}`;
        }
        return `{${this.name}}`;
    }

    public override getServerFormulaNode(): ServerFormulaExpressionNode {
        return {
            '@type': 'variable',
            name: this.variableId,
        } as ServerFormulaExpressionVariableNode;
    }

    protected override getType() {
        return this.dataType;
    }

    public override clone(field: FormulaField) {
        return new FormulaTreeVariableNode(this.variableId, this.name, this.dataType, field, this.id);
    }

    protected override validate(): boolean {
        if (this.name === undefined || this.name === null) {
            this.setError(`There is no field definition with the ID of '${this.variableId}'. Try to replace it.`);
        } else if (!this.dataType) {
            // If the field is not found, it will not have a type but it should not display an error about it.
            this.setError(`Unable to evaluate the type of '${this.name}'. Try to replace it.`);
        }

        return super.validate();
    }

    /**
     * Classifying the type of the field base on its value.
     * If its a list or a longString, it will mark it as a String.
     */
    private classifyType(): void {
        switch (this.dataType) {
            case FieldType.List:
            case FieldType.LongString:
                this.dataType = FieldType.String;
                break;
        }
    }
}
