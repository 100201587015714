import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import ActionTestPageRequestViewTabType from './ActionTestPageRequestViewTabType';
import extractQueryParams from '../../../utils/extractQueryParams';
import flatHeaderValuesInHeadersRecord from '../../../utils/flatHeaderValuesInHeadersRecord';
import tryPrettifyJsonText from '../../../utils/tryPrettifyJsonText';

import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    BasicKeyValueTable,
    DisabledTextareaWithExtendModal,
    Tab,
    Tabs,
    TabsBorderType,
} from '@tonkean/infrastructure';
import { randomNumber } from '@tonkean/utils';

const StyledAnimatedSwitchItem = styled(AnimatedSwitchItem)`
    margin-top: 25px;
`;

interface Props {
    evaluatedUrl?: string;
    evaluatedHeaders?: Record<string, string[]>;
    evaluatedBody?: string;
    shouldHideBodyTab?: boolean;
    isLoading: boolean;
}

const ActionTestPageRequestView: React.FC<Props> = ({
    evaluatedUrl,
    evaluatedBody,
    evaluatedHeaders,
    shouldHideBodyTab,
    isLoading,
}) => {
    // State for the viewing Tab
    const [selectedTab, setSelectedTab] = useState(ActionTestPageRequestViewTabType.QUERY_PARAMS);

    // Memo for evaluated query parameters record
    const evaluatedQueryParamToEvaluatedValueRecord = useMemo(() => {
        if (!evaluatedUrl) {
            return {};
        }
        return extractQueryParams(evaluatedUrl);
    }, [evaluatedUrl]);

    // Memo for headers.
    const evaluatedRequestHeaderToEvaluatedValueRecord: Record<string, string> = useMemo(() => {
        return flatHeaderValuesInHeadersRecord(evaluatedHeaders || {});
    }, [evaluatedHeaders]);

    // Memo for Prettify JSON view, if the body not a valid json return evaluatedBody
    const prettyJsonView = useMemo(() => {
        return tryPrettifyJsonText(evaluatedBody || '');
    }, [evaluatedBody]);

    const queryParamCount = useMemo(
        () => Object.keys(evaluatedQueryParamToEvaluatedValueRecord).length,
        [evaluatedQueryParamToEvaluatedValueRecord],
    );

    const headersCount = useMemo(
        () => Object.keys(evaluatedRequestHeaderToEvaluatedValueRecord).length,
        [evaluatedRequestHeaderToEvaluatedValueRecord],
    );

    return (
        <>
            <Tabs
                showBackground={false}
                value={selectedTab}
                onChange={setSelectedTab}
                borderType={TabsBorderType.RAILWAY}
                height="46px"
            >
                <Tab label={ActionTestPageRequestViewTabType.QUERY_PARAMS}>
                    {ActionTestPageRequestViewTabType.QUERY_PARAMS} ({queryParamCount})
                </Tab>

                <Tab label={ActionTestPageRequestViewTabType.HEADERS}>
                    {ActionTestPageRequestViewTabType.HEADERS} ({headersCount})
                </Tab>

                {!shouldHideBodyTab && (
                    <Tab label={ActionTestPageRequestViewTabType.REQUEST_BODY}>
                        {ActionTestPageRequestViewTabType.REQUEST_BODY}
                    </Tab>
                )}
            </Tabs>

            <AnimatedSwitch activeLabel={selectedTab}>
                <StyledAnimatedSwitchItem label={ActionTestPageRequestViewTabType.QUERY_PARAMS}>
                    <BasicKeyValueTable
                        keyValueRecord={evaluatedQueryParamToEvaluatedValueRecord}
                        keyColumnWidth={200}
                        isLoading={isLoading}
                        loadingPlaceholderCount={randomNumber(3, 5)}
                    />
                </StyledAnimatedSwitchItem>

                <StyledAnimatedSwitchItem label={ActionTestPageRequestViewTabType.HEADERS}>
                    <BasicKeyValueTable
                        keyValueRecord={evaluatedRequestHeaderToEvaluatedValueRecord}
                        keyColumnWidth={200}
                        isLoading={isLoading}
                        loadingPlaceholderCount={randomNumber(3, 5)}
                    />
                </StyledAnimatedSwitchItem>

                {!shouldHideBodyTab && (
                    <StyledAnimatedSwitchItem label={ActionTestPageRequestViewTabType.REQUEST_BODY}>
                        <DisabledTextareaWithExtendModal text={prettyJsonView} width="560px" height="160px" />
                    </StyledAnimatedSwitchItem>
                )}
            </AnimatedSwitch>
        </>
    );
};

export default ActionTestPageRequestView;
