import type CreateAfterOption from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import addFormBlockCreateOptionDefaults from '../addFormBlockCreateOptionDefaults';
import formAnsweredCanCreateInSameModule from '../canCreate/formAnsweredCanCreateInSameModule';
import formAnsweredTriggerParamsEnricher from '../enrichers/formAnsweredTriggerParamsEnricher';

const formAnsweredSendUpdateFormCanCreate: CreateAfterOptionCanCreate = (
    childImpacts,
    configuredLogic,
    logicBlockConfigsFormInfoRetriever,
) => {
    const isSameFormModuleConfigured = formAnsweredCanCreateInSameModule(
        configuredLogic,
        logicBlockConfigsFormInfoRetriever,
    );
    return isSameFormModuleConfigured;
};

const addFormAnsweredBlockCreateAfterOption: CreateAfterOption = {
    label: addFormBlockCreateOptionDefaults.label,
    blockDisplayName: addFormBlockCreateOptionDefaults.blockDisplayName,
    triggerParamsEnricher: formAnsweredTriggerParamsEnricher,
    canCreate: formAnsweredSendUpdateFormCanCreate,
};
export default addFormAnsweredBlockCreateAfterOption;
