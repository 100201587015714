<section class="login-page mod-reverse">
    <!-- desktop view -->
    <div class="login-page-desktop-container">
        <div class="login-page-content">
            <div class="flex flex-col login-page-content-box">
                <h1 class="login-page-title">
                    <div>End-to-End Workflow Automation</div>
                </h1>
                <div class="common-size common-thin">
                    <p>
                        With our powerful Workflow Builder, you can easily train your Modules to coordinate or execute
                        any business workflow end-to-end. Including data manipulation and people coordination.
                    </p>
                </div>

                <div class="flex-vmiddle flex-wrap margin-top-lg">
                    <div class="flex-vmiddle margin-bottom margin-right-lg">
                        <i class="fa fa-check-circle common-color-secondary common-size-l margin-right"></i>
                        <span>Risk Management Workflows</span>
                    </div>
                    <div class="flex-vmiddle margin-bottom margin-right-lg">
                        <i class="fa fa-check-circle common-color-secondary common-size-l margin-right"></i>
                        <span>Approval Processes</span>
                    </div>
                    <div class="flex-vmiddle margin-bottom margin-right-lg">
                        <i class="fa fa-check-circle common-color-secondary common-size-l margin-right"></i>
                        <span>Work Delegation</span>
                    </div>
                    <div class="flex-vmiddle margin-bottom">
                        <i class="fa fa-check-circle common-color-secondary common-size-l margin-right"></i>
                        <span>Status Reporting</span>
                    </div>
                </div>

                <div class="margin-top-lg margin-bottom flex-vmiddle mod-inline">
                    <button
                        class="btn login-btn mod-primary btn-lg common-bold"
                        data-automation="login-triggers-move-next"
                        ng-click="goToNextStep()"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
        <div class="login-page-image-box">
            <div class="login-page-image-inner-box">
                <div class="login-page-image mod-triggers"></div>
            </div>
        </div>
    </div>

    <!-- mobile view -->
    <div class="login-page-mobile-container">
        <div class="login-page-content">
            <div class="login-page-title">End-to-End Workflow Automation</div>
            <div class="common-size-xs">
                <div>
                    With our powerful Workflow Builder, you can easily train your Modules to coordinate or execute any
                    business workflow end-to-end. Including data manipulation and people coordination.
                </div>
            </div>
        </div>

        <div class="login-page-image-inner-box">
            <div class="login-page-image mod-triggers"></div>
        </div>

        <div class="flex mod-justify-center margin-top">
            <button ng-click="goToNextStep()" class="next-btn">Next</button>
        </div>
    </div>
</section>
