<form class="flex-vmiddle" ng-submit="save()">
    <!-- Input -->
    <tnk-special-field-manual-input
        class="flex-grow margin-right-xs"
        field-id="data.fieldId"
        group-id="data.initiative ? data.initiative.group.id : ''"
        workflow-version-id="{{ data.workflowVersionId }}"
        selected-value-to-update="data.currentFieldValue"
        on-manual-special-field-changed="onFieldValueChanged(selectedValueToUpdate)"
    ></tnk-special-field-manual-input>
    <!-- Save button -->
    <button type="submit" class="btn btn-primary margin-right-xs" data-automation="tnk-initiative-special-field-selector-template-submit-button" ng-if="!data.hideSaveButton">Submit</button>
    <!-- Loading circle -->
    <i class="loading-small" ng-show="data.saving" ng-if="!data.hideSaveButton"></i>
</form>
