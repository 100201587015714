import { atom } from 'recoil';

import JsonPickerOutputType from '../entities/JsonPickerOutputType';

// Initial values project integration entity collect page
const projectIntegrationEntityCollectPageInputState = atom<{
    jsonPickerOutputType: JsonPickerOutputType;
    selectedTestRunIds: string[];
    isJsonPickerDisabled: boolean;
}>({
    key: 'projectIntegrationEntityCollectPageInputState',
    default: {
        jsonPickerOutputType: JsonPickerOutputType.JSON_PATH,
        selectedTestRunIds: [],
        isJsonPickerDisabled: false,
    },
});

export default projectIntegrationEntityCollectPageInputState;
