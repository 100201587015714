import { useAngularService } from 'angulareact';
import React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import PagesList from './PagesList';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { TonkeanIconDisplay } from '@tonkean/icon-picker';
import { ModuleBrowserSolutionBoxTabsState } from '@tonkean/infrastructure';
import { H1, H4, IconSvg, SIDE_PANE_ANIMATION_DURATION_SECONDS } from '@tonkean/infrastructure';
import { useSolutionSiteContext } from '@tonkean/interface-module';
import { ChevronDoubleIcon, Edit01Icon } from '@tonkean/svg';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

const FloatingToggle = styled(Clickable)<{ $flip: boolean }>`
    z-index: 2;
    position: absolute;
    top: 50px;
    right: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;

    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 50%;
    background: ${Theme.colors.basicBackground};
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

    transition: transform 100ms ease-in-out;
    transition-delay: ${SIDE_PANE_ANIMATION_DURATION_SECONDS}s;
    ${({ $flip }) =>
        $flip &&
        css`
            transform: rotateZ(-180deg);
        `};
`;

const ContentContainer = styled.div<{ $showOverlay: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 16px 0;
    height: 100%;
    position: relative;

    // This element will display a white overlay when the sidepane is closed, so that the peeker would not show the sidepane content
    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: ${Theme.colors.basicBackground};
        z-index: 1;
        pointer-events: none;

        transition: opacity ${SIDE_PANE_ANIMATION_DURATION_SECONDS}s;
        opacity: ${({ $showOverlay }) => ($showOverlay ? 1 : 0)};
    }
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: baseline;
    gap: 12px;
    padding: 8px 16px;
`;

const SolutionSiteTitle = styled(H1)`
    font-family: 'Roboto', sans-serif;
`;

const StyledPagesList = styled(PagesList)`
    overflow: auto;
    gap: 10px;
    flex-grow: 1;
    padding: 8px;
`;

const OpenStudioButton = styled(IconButton)`
    margin: 0 8px;
    height: auto;
    padding: 10px 12px;
`;

const StyledIconSvg = styled(IconSvg)`
    display: flex; // IconButton shifts the height of the icon and it doesnt look aligned, this is a quick fix
`;

interface Props {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    isMobile: boolean;
}

const SolutionSiteSidePaneContent: React.FC<Props> = ({ open, setOpen, isMobile }) => {
    const { solutionSite } = useSolutionSiteContext();
    const projectManager = useAngularService('projectManager');

    const workflowFolderManager = useAngularService('workflowFolderManager');
    const { data: isMakerOfFolder } = useAsyncMethod(
        workflowFolderManager,
        'getIsMakerOfFolder',
        projectManager.project.id,
        solutionSite.workflowFolderId,
    );

    return (
        <ContentContainer $showOverlay={!open}>
            {!isMobile && (
                <FloatingToggle onClick={() => setOpen((prevState) => !prevState)} $flip={open}>
                    <ChevronDoubleIcon />
                </FloatingToggle>
            )}
            <TitleContainer>
                <TonkeanIconDisplay icon={solutionSite.themeConfiguration.icon} size={24} dontShowEmptyIcon />
                <SolutionSiteTitle data-automation="solution-site-side-pane-solution-site-display-name">
                    {solutionSite.displayName}
                </SolutionSiteTitle>
            </TitleContainer>

            <StyledPagesList
                onPageClick={() => {
                    if (isMobile) {
                        setOpen(false);
                    }
                }}
            />
            {isMakerOfFolder && (
                <OpenStudioButton
                    state="product.solution"
                    params={{
                        projectId: solutionSite.projectId,
                        solutionId: solutionSite.workflowFolderId,
                        tab: ModuleBrowserSolutionBoxTabsState.MODULES,
                    }}
                    horizontalAlignment="flex-start"
                    leftIcon={<StyledIconSvg as={Edit01Icon} size={20} />}
                    iconColor={Theme.colors.gray_800}
                    iconSize={20}
                    iconMargin={12}
                    flat
                >
                    <H4 $bold>Open Studio</H4>
                </OpenStudioButton>
            )}
        </ContentContainer>
    );
};

export default SolutionSiteSidePaneContent;
