import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import PeopleDirectoryRecipientsPreview from '../../../../PeopleDirectoryModule/components/PeopleDirectoryModuleEditor/PeopleDirectoryRecipientsPreview/PeopleDirectoryRecipientsPreview';
import PeopleDirectoryViewMode from '../../../../PeopleDirectoryModule/entities/PeopleDirectoryViewMode';
import type { PeopleDirectoryViewContextData } from '../../../../PeopleDirectoryModule/utils/PeopleDirectoryViewContext';
import PeopleDirectoryViewContext from '../../../../PeopleDirectoryModule/utils/PeopleDirectoryViewContext';
import type { TabComponentProps } from '../../../entities/WorkerRunLogicInspectTabData';

import { useToggle } from '@tonkean/infrastructure';
import { PeopleDirectorySendStatus, SendToGroupOfPeopleAs } from '@tonkean/tonkean-entities';
import type {
    PeopleDirectorySendResults,
    PeopleDirectorySendToIndividualResult,
    WorkerRunLogicInspectTabType,
} from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SingleWorkerRunActionInspectPeopleAction: React.FC<
    TabComponentProps<WorkerRunLogicInspectTabType.PEOPLE_DIRECTORY>
> = ({ sendResults }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showFailed, toggleShowFailed] = useToggle(true);
    const [showSendViaPreference, toggleShowSendViaPreference] = useToggle(true);
    const [showSendViewEmailFallback, toggleShowSendViewEmailFallback] = useToggle(true);

    const sendToGroupOfPeopleAs = sendResults[0]?.sendToGroupOfPeopleAs ?? SendToGroupOfPeopleAs.EACH_INDIVIDUAL;

    const getPeopleDirectoriesThatMatchFilters = useCallback(
        (peopleDirectories: PeopleDirectorySendResults[]) => {
            return peopleDirectories.filter((result) => {
                if (sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL) {
                    return true;
                }

                // In order to filter group messages we must filter the people directories
                if (
                    !result.peopleDirectory.displayName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) &&
                    !result.groupMessage?.communicationMethod
                        .toLocaleLowerCase()
                        .includes(searchTerm.toLocaleLowerCase())
                ) {
                    return false;
                }

                if (showFailed && result.groupMessage?.status === PeopleDirectorySendStatus.FAIL) {
                    return true;
                }

                if (showSendViaPreference && result.groupMessage?.status === PeopleDirectorySendStatus.SUCCESS) {
                    return true;
                }

                return false;
            });
        },
        [searchTerm, sendToGroupOfPeopleAs, showFailed, showSendViaPreference],
    );

    const peopleDirectories = useMemo(() => {
        return Object.fromEntries(
            getPeopleDirectoriesThatMatchFilters(sendResults).map((result) => [
                result.peopleDirectory.id,
                result.peopleDirectory,
            ]),
        );
    }, [getPeopleDirectoriesThatMatchFilters, sendResults]);

    const peopleDirectoryInstances = useMemo(() => {
        return Object.fromEntries(
            sendResults.map((result) => [result.peopleDirectory.id, result.peopleDirectoryInstance]),
        );
    }, [sendResults]);

    const groupMessagesResults = useMemo(() => {
        return Object.fromEntries(sendResults.map((result) => [result.peopleDirectory.id, result.groupMessage]));
    }, [sendResults]);

    const getIndividualsThatMatchFilters = useCallback(
        (individuals: PeopleDirectorySendToIndividualResult[]) => {
            return individuals.filter((person) => {
                const personNameMatchesSearch = person.person.name
                    .toLocaleLowerCase()
                    .includes(searchTerm.toLocaleLowerCase());

                if (!personNameMatchesSearch) {
                    return false;
                }

                if (showFailed && person.status === PeopleDirectorySendStatus.FAIL) {
                    return true;
                }

                if (showSendViaPreference && person.status === PeopleDirectorySendStatus.SUCCESS) {
                    return true;
                }

                if (showSendViewEmailFallback && person.status === PeopleDirectorySendStatus.PARTIAL) {
                    return true;
                }

                return false;
            });
        },
        [searchTerm, showFailed, showSendViaPreference, showSendViewEmailFallback],
    );

    const individualsSendResults = useMemo(() => {
        return Object.fromEntries(
            sendResults.map((result) => [
                result.peopleDirectory.id,
                getIndividualsThatMatchFilters(result.individuals),
            ]),
        );
    }, [getIndividualsThatMatchFilters, sendResults]);

    const totalIndividuals = useMemo(() => {
        return new Set(
            Object.values(individualsSendResults)
                .flat()
                .map((person) => person.person.id),
        ).size;
    }, [individualsSendResults]);

    const contextValue = useMemo<PeopleDirectoryViewContextData>(() => {
        return {
            peopleDirectories,
            peopleDirectoryInstances,
            missingInstancesPeopleDirectoryIds: [],
            groupMessages: {},
            groupMessagesResults,
            individuals: {},
            individualsSendResults,
            totalIndividuals,
            sendToGroupOfPeopleAs,
            mode: PeopleDirectoryViewMode.HISTORY,
            showFailed,
            peopleDirectorySendStatuses: Object.fromEntries(
                sendResults.map((result) => [result.peopleDirectory.id, result.status]),
            ),
            peopleDirectoryFriendlyErrors: Object.fromEntries(
                sendResults.map((result) => [result.peopleDirectory.id, result.friendlyErrorMessage]),
            ),
            toggleShowFailed: () => toggleShowFailed(),
            showSendViaPreference,
            toggleShowSendViaPreference: () => toggleShowSendViaPreference(),
            showSendViewEmailFallback,
            toggleShowSendViewEmailFallback: () => toggleShowSendViewEmailFallback(),
        };
    }, [
        peopleDirectories,
        peopleDirectoryInstances,
        groupMessagesResults,
        individualsSendResults,
        totalIndividuals,
        sendToGroupOfPeopleAs,
        showFailed,
        sendResults,
        showSendViaPreference,
        showSendViewEmailFallback,
        toggleShowFailed,
        toggleShowSendViaPreference,
        toggleShowSendViewEmailFallback,
    ]);

    return (
        <PeopleDirectoryViewContext.Provider value={contextValue}>
            <Wrapper>
                <PeopleDirectoryRecipientsPreview
                    loading={false}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                />
            </Wrapper>
        </PeopleDirectoryViewContext.Provider>
    );
};

export default SingleWorkerRunActionInspectPeopleAction;
