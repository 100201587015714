import React from 'react';

import { lazyReactToAngular } from '@tonkean/angular-components';
import { SolutionSiteLoadingSkeleton } from '@tonkean/solution-site';

export default angular.module('tonkean.app').component(
    'tnkSolutionSiteInterface',
    lazyReactToAngular(
        () => import('@tonkean/solution-site/components/SolutionSiteInterface'),
        undefined,
        <SolutionSiteLoadingSkeleton />,
    ),
);
