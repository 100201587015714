import type { FieldsSelectorGroupSummaryBase } from './FieldsSelectorGroupSummaryBase';

import type { FieldType } from '@tonkean/tonkean-entities';

export class FieldsSelectorByFieldTypeGroupSummary implements FieldsSelectorGroupSummaryBase {
    fieldType: FieldType;
    name: string;
    orderNumber: number;

    getGroupKey(): string {
        return this.fieldType.toString();
    }
}
