import AutonomousLogicBlockConfig from './AutonomousLogicBlockConfig';
import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const InnerAutonomousLogicBlockConfig: LogicBlockConfigDefinition = {
    ...AutonomousLogicBlockConfig,
    type: LogicBlockConfigType.INNER_AUTONOMOUS,
    title: 'Wait Trigger',
    longDescription:
        'This trigger will run on scheduled times as configured below, as long as it matches the condition below. Note: this will run on existing items.',
    cantDrag: false,

    validator: ({ customTrigger }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (customTrigger.isScheduled && !customTrigger.recurrencePeriodType) {
            validationObject.recurrencePeriodType = 'Must select a frequency.';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    dataAutomation: 'wait-trigger',
};

export default InnerAutonomousLogicBlockConfig;
