import React from 'react';

import type { ItemInterfaceSection } from '@tonkean/infrastructure';
import type { ItemInterfaceWidget } from '@tonkean/tonkean-entities';
import type { ClickableProps } from '@tonkean/tui-buttons/Clickable';

const ItemWidgetContext = React.createContext<
    | {
          widget: ItemInterfaceWidget;
          isEditable: boolean;
          sidepaneClickableProps: ClickableProps;
          section: ItemInterfaceSection;
      }
    | undefined
>(undefined);

export default ItemWidgetContext;
