<div class="next-steps-logic-configuration">
    <!-- Person selection title -->
    <div class="common-title-xxs margin-bottom">Who should Tonkean module Ask?</div>
    <tnk-person-selection-configuration
        class="block margin-bottom-lg"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        support-quick-create="true"
        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        configured-logic="data.configuredLogic"
        saved-person-selection-configuration="data.customTriggerAction.customTriggerActionDefinition"
        on-person-selection-configuration-changed="
            onPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
        "
        hide-fail-if-no-value="true"
        show-send-by="true"
        show-send-as-group-message="true"
        unique-identifier="nextStepsWhoToAsk"
        preview-evaluation-source="data.previewEvaluationSource"
    ></tnk-person-selection-configuration>

    <!-- separator -->
    <hr />

    <tnk-email-configuration
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        configured-logic="data.configuredLogic"
        saved-original-subject-expression="data.emailSubject"
        saved-evaluated-subject-expression="data.evaluatedEmailSubject"
        on-email-subject-tonkean-expression-changed="
            onTonkeanEmailSubjectExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
        "
        saved-original-body-expression="data.customText"
        saved-evaluated-body-expression="data.evaluatedCustomText"
        on-email-body-tonkean-expression-changed="
            onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
        "
        send-as-plain-text="data.sendAsPlainText"
        on-send-as-plain-text-changed="onSendAsPlainTextChanged(shouldSendAsPlainText)"
        ng-if="data.personSelectionConfiguration.forceEmail"
        preview-evaluation-source="data.previewEvaluationSource"
    ></tnk-email-configuration>

    <div ng-if="!data.personSelectionConfiguration.forceEmail">
        <div class="margin-bottom-xs common-title-xxs">
            Message text:
            <a
                class="inline-block common-italic margin-left"
                uib-tooltip="You can use markdown format to add styling to the message"
                target="_blank"
                ng-href="{{::$root.knowledgeBase.pages.formulasMarkdown}}"
            >
                <i class="fa fa-info-circle"></i>
            </a>
        </div>

        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            placeholder="Enter custom message"
            saved-original-expression="data.customText"
            saved-evaluated-expression="data.evaluatedCustomText"
            expression-unique-identifier="nextStepsMessage"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>

    <!-- Separator -->
    <hr />

    <!-- Preview -->
    <div class="margin-top-lg">
        <!-- Title Message Preview-->
        <div
            ng-if="!data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.forceEmail"
            class="margin-bottom common-bold"
        >
            Message Preview:
        </div>

        <!-- Title Email Preview -->
        <div
            ng-if="data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.forceEmail"
            class="margin-bottom common-bold"
        >
            Email Preview:
        </div>

        <!-- Preview -->
        <tnk-preview-bot-bubble-ctrl
            text="data.customText"
            text-evaluated-expression="data.evaluatedCustomText"
            post-text="tonkeanUtils.getItemDisplayNameForBotPreview(pm, data.groupId, wvm, scm)"
            post-text-evaluated-expression="data.evaluatedPostText"
            preview-type="text"
            placeholder="What are the next steps for"
            is-email="data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition.forceEmail"
            user="as.currentUser"
            no-buttons="true"
            no-triangle="true"
            logic-id="data.configuredLogic.node.id"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            auto-width="true"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-preview-bot-bubble-ctrl>
    </div>
</div>
