import template from './tnkPeopleSelector.template.html?angularjs';
import { TonkeanType } from '@tonkean/tonkean-entities';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';

function tnkDeprecatedPeopleSelector() {
    return {
        restrict: 'E',
        scope: {
            controlObject: '=',
            selectedPeople: '=',
            noCustom: '=',
            onlyCustom: '=', // When true, the directive will not show auto complete options and will allow custom emails.
            maxTags: '@',
            minTags: '@',
            placeholder: '@',
            noValidation: '=',
            options: '=',
            excludeMe: '=',
            onlyLicensed: '=',
            disabled: '=',
            onTagAdded: '&',
            onTagRemoved: '&',
            onTagRemoving: '&',
            onPersonInvite: '&',
            group: '=',
            doNotSearchOnlyOwnersInGroup: '<', // Allow to search after users which are not owners of this group.
            onlyCreatorsInGroup: '<',
            showIntro: '<', // Whether or not to show the little "User is new to Tonkean" message that allows to send the user an intro.
            introText: '@', // If no intro text is provided, a default text is used.
            introButtonText: '@', // The text for the button that opens the send intro (invite) modal. A default text is used if not provided.
            maxTagChars: '<', // If given, when a tag name has more chars than specified - it will be trimmed. Note that maxTagChars MUST be bigger than 3.
            customOptions: '<', // Array of objects. If given, will add these custom options (elements of the array) to the drop down of owners. You can customise the objects as you wish to be able to later identify them as custom options.
            enterpriseId: '<', // When not empty, an API for getting people in an enterprise project will be used.
            includeGroups: '<', // When true enables selecting user groups.
            onlyGroups: '<', // When true enables selecting only user groups.
            onlyProcessContributors: '<',
            hidePlaceholderWhenDisabled: '<',
            hideTagsBackgroundWhenDisabled: '<',
            includeExternal: '<',
        },
        template,
        controller: [
            '$scope',
            '$q',
            'tonkeanService',
            'authenticationService',
            'utils',
            'currentUserService',
            'modal',
            function ($scope, $q, tonkeanService, authenticationService, utils, currentUserService, modal) {
                $scope.utils = utils;
                $scope.text = '';
                $scope.domain = utils.getCompanyEmailDomain(authenticationService.currentUser.email);
                updateUninvitedPeople();

                // Source: http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
                const emailRegex =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;

                // Go over the given selected people and reformat when needed.
                $scope.$watch('selectedPeople', (peopleAndGroups) => {
                    if (peopleAndGroups !== undefined) {
                        for (const peopleAndGroup of peopleAndGroups) {
                            trimTagIfNeeded(peopleAndGroup);
                            // Adding email to groups
                            if (getTonkeanEntityType(peopleAndGroup.id) === TonkeanType.USER_GROUP) {
                                enrichGroup(peopleAndGroup);
                            }
                        }
                    }
                });

                $scope.searchPeople = function (query) {
                    if (
                        !utils.isNullOrEmpty($scope.maxTags) &&
                        $scope.maxTags >= 0 &&
                        $scope.selectedPeople.length >= $scope.maxTags
                    ) {
                        return [];
                    }
                    $scope.loadingPeople = true;
                    const limit = $scope.selectedPeople.length + 8;

                    const options = $scope.options || {};

                    options.excludeMe = $scope.excludeMe;
                    options.includeTemporary = $scope.$root.pm.allowTempUsers;
                    options.group = $scope.group;
                    options.onlyLicensed = $scope.onlyLicensed;
                    options.doNotSearchOnlyOwnersInGroup = $scope.doNotSearchOnlyOwnersInGroup;
                    if ((($scope.doNotSearchOnlyOwnersInGroup && !query) || query === 0) && $scope.onlyOwnersInGroup) {
                        options.onlyOwnersInGroupId = $scope.onlyOwnersInGroup.id;
                    }

                    if (($scope.onlyCreatorsInGroup && !query) || query === 0) {
                        options.onlyCreatorsInGroupId = $scope.onlyCreatorsInGroup.id;
                    }
                    if ($scope.onlyProcessContributors) {
                        options.onlyProcessContributors = $scope.onlyProcessContributors;
                    }
                    if ($scope.includeExternal) {
                        options.includeExternal = $scope.includeExternal;
                    }

                    let getTeamMembersAndGroupsPromises;
                    if (!$scope.onlyGroups) {
                        if ($scope.onlyCustom) {
                            // Only custom was requested. Make sure we allow custom and set the promise to not return anything.
                            $scope.noCustom = false;
                            getTeamMembersAndGroupsPromises = [$q.resolve({ people: [] })];
                        } else if (utils.isNullOrEmpty($scope.enterpriseId)) {
                            getTeamMembersAndGroupsPromises = [
                                tonkeanService.getTeamMembers($scope.$root.pm.project.id, options, query, limit),
                            ];
                        } else {
                            getTeamMembersAndGroupsPromises = [
                                tonkeanService
                                    .getEnterpriseMembers($scope.enterpriseId, options, query, limit)
                                    .then((data) => {
                                        // People return in a container object (containing a list of projects as well - so we extract them.
                                        const parsedPeople = data.people.map(
                                            (personContainer) => personContainer.person,
                                        );
                                        data.people = parsedPeople;

                                        return data;
                                    }),
                            ];
                        }
                        if ($scope.includeGroups) {
                            getTeamMembersAndGroupsPromises.push(
                                tonkeanService.getProjectGroups($scope.$root.pm.project.id, limit, 0, query),
                            );
                        }
                    } else {
                        getTeamMembersAndGroupsPromises = [
                            tonkeanService.getProjectGroups($scope.$root.pm.project.id, limit, 0, query),
                        ];
                    }

                    return Promise.all(getTeamMembersAndGroupsPromises)
                        .then((resultArray) => {
                            const people = $scope.onlyGroups ? [] : resultArray[0]?.people || [];
                            const groups = resultArray[$scope.onlyGroups ? 0 : 1]?.groups || [];
                            // People selector manages items through mails.
                            // so we generate mails for groups to fit people selector
                            groups.forEach(enrichGroup);
                            return people.concat(groups);
                        })
                        .then(function (peopleAndGroups) {
                            let people = peopleAndGroups;
                            if (query.length) {
                                const email = query.includes('@')
                                    ? query
                                    : `${query}@${$scope.domain || 'example.com'}`;

                                let alreadyExist = $scope.selectedPeople.some(function (p) {
                                    return $scope.noValidation
                                        ? p.name === query || p.displayName === query
                                        : p.email === email || generateEmailForGroup(p) === email;
                                });
                                if (!alreadyExist && people) {
                                    alreadyExist = people.some(function (p) {
                                        return $scope.noValidation
                                            ? p.name === query || p.displayName === query
                                            : p.email === email || generateEmailForGroup(p) === email;
                                    });
                                }

                                if (!alreadyExist && !$scope.noCustom) {
                                    people = people.slice(0, 7);
                                    people.push({
                                        customEmail: true,
                                        email,
                                        isValid: $scope.noValidation || emailRegex.test(email),
                                    });
                                }
                            }

                            // Inserting the custom options, if given, to the start of the options array.
                            if ($scope.customOptions && $scope.customOptions.length) {
                                for (let i = 0; i < $scope.customOptions.length; i++) {
                                    const customOption = $scope.customOptions[i];

                                    // We only allow objects in the custom options array, because we need to inject
                                    // a property indicating it is an option into the objects.
                                    if (customOption && customOption instanceof Object) {
                                        // This flag will be later use to style the auto complete option differently for the custom option.
                                        customOption.isCustomOption = true;
                                        people.unshift(customOption);
                                    }
                                }
                            }

                            return people;
                        })
                        .catch(function (error) {
                            return [{ error }];
                        })
                        .finally(function () {
                            $scope.loadingPeople = false;
                        });
                };

                $scope.openInviteModal = function () {
                    // Only do something if people have been selected.
                    if ($scope.uninvitedPeople && $scope.uninvitedPeople.length) {
                        // Open invite modal with the currently selected people in "send intro" mode.
                        modal
                            .openInvite($scope.uninvitedPeople, null, true)
                            .then(function () {
                                // Invites sent.
                                // Go over the selected people, and replace the uninvited ones with the fresh users updated by the modal.
                                for (let i = 0; i < $scope.selectedPeople.length; i++) {
                                    // Only replace this person if he is in the uninvited group.
                                    if (currentUserService.isPersonUninvited($scope.selectedPeople[i])) {
                                        // Find the updated person by email.
                                        const updatedPerson = utils.findFirstCompareProperties(
                                            $scope.uninvitedPeople,
                                            $scope.selectedPeople[i],
                                            'email',
                                        );
                                        // If found, update the person.
                                        if (updatedPerson) {
                                            $scope.selectedPeople[i] = updatedPerson;
                                        }
                                    }
                                }

                                // Update the uninvited people list.
                                updateUninvitedPeople();

                                $scope.onPersonInvite();
                            })
                            .catch(function () {
                                // Modal dismissed.
                            });
                    }
                };

                /**
                 * Fired when a tag is added to the tags input.
                 * Parses the data and forwards the call to the supplied onTagAdded function.
                 */
                $scope.onTagAddedInner = function ($tag) {
                    // If we were asked to trim the tag display name, do it.
                    trimTagIfNeeded($tag);

                    if ($scope.showIntro) {
                        // Update the uninvited people.
                        updateUninvitedPeople();
                    }

                    $scope.text = '';

                    // Pass the event.
                    return $scope.onTagAdded?.({ $tag });
                };

                /**
                 * Fired when a tag is removed from the tags input.
                 * Parses the data and forwards the call to the supplied onTagRemoved function.
                 */
                $scope.onTagRemovedInner = function ($tag) {
                    if ($scope.showIntro) {
                        // Update the uninvited people.
                        updateUninvitedPeople();
                    }
                    // Pass the event.
                    return $scope.onTagRemoved?.({ $tag });
                };
                /**
                 * Fired when a tag is removed from the tags input.
                 * Parses the data and forwards the call to the supplied onTagRemovingInner function.
                 */
                $scope.onTagRemovingInner = function ($tag) {
                    // Pass the event.
                    if ($scope.onTagRemoving) {
                        return $scope.onTagRemoving({ $tag });
                    } else {
                        return true;
                    }
                };

                if ($scope.controlObject) {
                    $scope.controlObject.updateUninvitedPeople = updateUninvitedPeople;
                }

                /**
                 * Updates the list of uninvited (to Tonkean) people ($scope.uninvitedPeople) from the given people list.
                 * @param peopleList - the original people list to extract uninvited people from.
                 */
                function updateUninvitedPeople() {
                    if ($scope.selectedPeople) {
                        $scope.uninvitedPeople = $scope.selectedPeople.filter(function (person) {
                            return currentUserService.isPersonUninvited(person);
                        });

                        if (!$scope.noCustom && $scope.uninvitedPeople) {
                            // Some people might only be mail addresses if custom is allowed.
                            // Set their first name as the first part of the email.
                            for (let i = 0; i < $scope.uninvitedPeople.length; i++) {
                                const uninvitee = $scope.uninvitedPeople[i];
                                // Only do this if the person doesn't already have a first name set.
                                if (!uninvitee.firstName) {
                                    uninvitee.firstName =
                                        $scope.uninvitedPeople[i].email &&
                                        $scope.uninvitedPeople[i].email.split('@')[0];
                                }
                            }
                        }
                    }
                }

                /**
                 * Trims the given tag accordingly if requested by the maxTagChars param.
                 * A tag is trimmed by adding a display name param to the object, which the template later prefers over the email or name.
                 * @param tag - the tag to trim.
                 */
                function trimTagIfNeeded(tag) {
                    // Only trim the tag if we were asked to.
                    if (tag && $scope.maxTagChars) {
                        // Try to get the text that is used as display name.
                        const displayNameRaw = tag.name || tag.email || '';
                        if (displayNameRaw.length > $scope.maxTagChars) {
                            tag.displayName = `${displayNameRaw.slice(0, Math.max(0, $scope.maxTagChars - 3))}...`;
                        }
                    }
                }

                function enrichGroup(group) {
                    group.email = generateEmailForGroup(group);
                    if ($scope.onlyGroups) {
                        /* when we are in only groups context
                         need to give the group identifying property for special styled elements*/
                        group.styledGroupTags = true;
                    }
                }

                function generateEmailForGroup(group) {
                    return `${group.id}@${$scope.domain || 'example.com'}`;
                }
            },
        ],
    };
}

angular.module('tonkean.app').directive('tnkDeprecatedPeopleSelector', tnkDeprecatedPeopleSelector);
