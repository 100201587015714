import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function TrelloCustomActionsDefinitionCtrl($scope, $q, createProjectApis, projectManager) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,

        availableLabelColors: {
            null: '#b3bec4', // (empty)
            yellow: '#f2d600',
            purple: '#c377e0',
            blue: '#0079bf',
            red: '#eb5a46',
            green: '#61bd4f',
            orange: '#ff9f1a',
            black: '#355263',
            sky: '#00c2e0',
            pink: '#ff78cb',
            lime: '#51e898',
        },

        trelloLists: [],
        trelloListsLoading: false,

        loading: false,
    };

    ctrl.$onInit = function () {
        if ($scope.data.configuredLogic && $scope.data.definition) {
            initializeEditMode();

            if ($scope.data.definition.customActionKey === 'MOVE_CARD') {
                $scope.initializeBoardLists();
            }
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onEmailTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.email = originalExpression;
        $scope.data.definition.evaluatedEmail = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onCommentTextTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.commentText = originalExpression;
        $scope.data.definition.evaluatedCommentText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.name = originalExpression;
        $scope.data.definition.evaluatedName = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onMessageColorSelected = function (colorName, color) {
        $scope.data.definition.colorName = colorName;
        $scope.data.definition.colorValue = color;
        $scope.onDefinitionChanged(true);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.onTrelloListSelected = function (list) {
        $scope.data.definition.listId = list.value;
        $scope.data.selectedList = list;
        $scope.onDefinitionChanged(true);
    };

    $scope.initializeBoardLists = function () {
        const getAutoCompletesPromises = [];
        for (let i = 0; i < $scope.data.projectIntegration.projectData.projectDatas.length; i++) {
            const projectData = $scope.data.projectIntegration.projectData.projectDatas[i];
            const promise = createProjectApis.getAutoCompleteOptions(
                projectManager.project.id,
                $scope.data.projectIntegration.integration.id,
                'lists',
                {
                    orgId: projectData.organization,
                },
            );
            getAutoCompletesPromises.push(promise);
        }

        $scope.data.trelloListsLoading = true;
        $q.all(getAutoCompletesPromises).then((results) => {
            $scope.data.trelloLists = [];
            for (const result of results) {
                $scope.data.trelloLists = $scope.data.trelloLists.concat(result.options);
            }

            if ($scope.data.definition.listId) {
                for (let i = 0; i < $scope.data.trelloLists.length; i++) {
                    const list = $scope.data.trelloLists[i];
                    if (list.value === $scope.data.definition.listId) {
                        $scope.data.selectedList = list;
                        break;
                    }
                }
            }

            if (!$scope.data.selectedList) {
                $scope.data.selectedList = $scope.data.trelloLists[0];
            }

            $scope.data.trelloListsLoading = false;
        });
    };

    function initializeEditMode() {
        $scope.data.definition.colorValue = $scope.data.definition.colorValue || '#b3bec4'; // null color
    }
}

export default angular
    .module('tonkean.app')
    .controller('TrelloCustomActionsDefinitionCtrl', lateConstructController(TrelloCustomActionsDefinitionCtrl));
