import template from './entitySelector.template.html?angularjs';

/**
 * Component to select entities of integrations.
 */
angular.module('tonkean.app').component('tnkEntitySelector', {
    bindings: {
        projectIntegration: '<', // The project integration we're working with.
        selectedEntity: '@', // If there is a selected entity already.
        forceEntitySelectionDisabled: '<', // Is the entity selection should be disabled
        selectedEntityDisplayName: '@', // If there is a selected entity already, this will be its display name.
        mode: '@', // Are we in 'sync' mode or 'import mode?
        isRequired: '<', // If true, will make the entity selector a requirement in the form it's in.
        setDefaultOnLoad: '@', // Should we set the default entity on load or keep empty
        appendToBody: '<', // Should the ui select be appended to body
        forceFetchSupportedEntitiesFromServer: '<', // If true, will fetch the supported entities from server.
        onEntitySelected: '&', // A function to be called when the entity is selected.
        name: '<', // Give the input the name for formik
        showAsError: '<', // Have a red border around the input (works only when useReactSelect is turned on
        /**
         * A function to be called when the dropdown choices are rendered.
         * The function gets an option object (dropdown item) and returns true or false whether it's disabled.
         */
        shouldDisableChoice: '&',
        shouldNotChooseDefaultEntity: '<', // Whether the component will choose a default entity when the amount of option is 1.
        /**
         * Array of entities which we don't want to display in the dropdown.
         * Provide the entities names both in singular and plural forms.
         * For example ["File", "Files", "Company", "Companies"]
         */
        ignoreEntities: '<',
        /**
         * Whether to use the react-select component instead of the ui-select
         */
        useReactSelect: '<',
        /**
         * Whether we can clear the currently selected value, relevant only when useReactSelect is true
         */
        allowClear: '<',
        /**
         * Whether the dropdown is disabled, relevant only when useReactSelect is true
         */
        disabled: '<',
    },
    template,
    controller: 'EntitySelectorCtrl',
});
