<div class="flex-grow enterprise-billing margin-bottom-xlg padding-bottom-xlg">
    <div class="common-title mod-light">Billing</div>

    <hr class="margin-bottom-lg" />

    <!-- Loading -->
    <div class="common-page-load" ng-show="loading">
        <i class="loading-full-circle"></i>
    </div>

    <div ng-if="!loading && !data.showAch">
        <!-- Title -->
        <h4>Payment method:</h4>
        <div ng-if="!license || !license.customer || !license.customer.bankAccount">
            <p class="padding-normal-sm-v">
                {{
                    !license.customer.bankAccount && license.customer.billingInformation.sourcesCount > 0
                        ? 'Credit Card'
                        : 'Manual wire transfer'
                }}
            </p>
            <button class="btn btn-primary" ng-click="data.showAch = true">
                Setup ACH
                <i class="fa fa-arrow-right margin-left"></i>
            </button>
        </div>

        <div ng-if="license && license.customer && license.customer.bankAccount">
            <div class="rounded-box common-border common-bg mod-grey5 padding-normal">
                <p class="common-bold margin-bottom-lg">Automatic ACH deposits</p>
                <p class="flex-vmiddle">
                    <span class="common-color-grey margin-right-lg text-right" style="width: 80px">Account:</span>
                    <span>{{ license.customer.bankAccount.name }} at {{ license.customer.bankAccount.brand }}</span>
                </p>
                <div class="flex-vmiddle">
                    <span class="common-color-grey margin-right-lg text-right" style="width: 80px">Status:</span>
                    <span ng-if="license.customer.bankAccount.status === 'verified'" class="common-color-success">
                        ACTIVE
                    </span>
                    <span ng-if="license.customer.bankAccount.status !== 'verified'" class="common-color-warning">
                        Setup complete, Pending Verification
                    </span>
                </div>
                <div ng-if="license.customer.bankAccount.status !== 'verified'" class="margin-top">
                    <hr />
                    <span class="common-tag-full mod-level3 mod-thin common-size-xxxxs">ACTION REQUIRED</span>
                    <p class="common-bold margin-top">How to verify your bank account:</p>
                    <p>
                        Two small deposits have been made to your account (cents). The transfers can take 1-2 business
                        days to appear. Once they’ve been received, you will need to provide us with the amounts for
                        each deposit, to verify that you have access to your account statement.
                    </p>
                    <div>Please email us the amounts for the two deposits to finish this step and enable ACH.</div>
                </div>
            </div>
            <p class="common-color-grey margin-top-lg">
                To revoke or change your payment information please contact us anytime at
                <a href="mailto:support@tonkean.com">support@tonkean.com</a>
                .
            </p>
        </div>
    </div>

    <!-- Setup ACH-->
    <div ng-if="!loading && data.showAch">
        <h4 class="margin-bottom-lg">Setup ACH payment:</h4>

        <form name="forms.setupBillingAch" class="form-inline" ng-submit="setupAch()">
            <div class="org-form-group">
                <label for="frmAccountHolder" class="control-label org-form-label">Name on account</label>
                <input id="frmAccountHolder" class="form-control" required ng-model="data.accountHolder" />
            </div>
            <div class="org-form-group">
                <label for="frmAccountHolderType" class="control-label org-form-label">Account holder type</label>
                <select id="frmAccountHolderType" class="form-control" required ng-model="data.accountHolderType">
                    <option selected value="company">Company</option>
                    <option value="individual">Individual</option>
                </select>
            </div>
            <div class="org-form-group">
                <label for="frmRouting" class="control-label org-form-label">
                    <div class="flex-vmiddle">
                        <span class="flex-grow">Routing Number</span>
                        <span
                            uib-popover-html="'<img src=\'./images/info-routing-number.png\' height=\'226\'/>'"
                            popover-is-open="triggers.infoRouting"
                            popover-placement="right auto"
                            ng-mouseenter="triggers.infoRouting = true"
                            ng-mouseleave="triggers.infoRouting = false"
                        >
                            <i class="fa fa-info-circle common-color-primary"></i>
                        </span>
                    </div>
                </label>
                <input
                    id="frmRouting"
                    placeholder="Routing Number"
                    class="form-control"
                    required
                    ng-model="data.routingNumber"
                />
            </div>
            <div class="org-form-group">
                <label for="frmAccountNum" class="control-label org-form-label">
                    <div class="flex-vmiddle">
                        <span class="flex-grow">Account Number</span>
                        <span
                            uib-popover-html="'<img src=\'./images/info-account-number.png\' height=\'232\'/>'"
                            popover-is-open="triggers.infoAccount"
                            popover-placement="right auto"
                            ng-mouseenter="triggers.infoAccount = true"
                            ng-mouseleave="triggers.infoAccount = false"
                        >
                            <i class="fa fa-info-circle common-color-primary"></i>
                        </span>
                    </div>
                </label>
                <input
                    id="frmAccountNum"
                    placeholder="Account Number"
                    class="form-control"
                    required
                    type="password"
                    ng-model="data.accountNumber"
                />
            </div>
            <div class="org-form-group">
                <div class="flex org-form-group-content">
                    <input
                        id="frmAccept"
                        class="form-control margin-none"
                        required
                        type="checkbox"
                        style="width: auto"
                        ng-model="data.acceptTerms"
                    />
                    <div class="margin-left">
                        I authorize Tonkean to electronically and periodically debit my account as per the terms of my
                        subscription and, if necessary, electronically credit my account to correct erroneous debits.
                    </div>
                </div>
            </div>
            <div class="org-form-group">
                <div class="org-form-label"></div>
                <div class="org-form-group-content text-right">
                    <span class="margin-top-xs margin-right-xs">
                        <i class="loading" ng-show="savingBillingInfo"></i>
                    </span>
                    <button
                        type="button"
                        class="btn btn-default flex-no-shrink margin-right-xs"
                        ng-disabled="savingBillingInfo"
                        ng-click="closeSetupAch()"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary flex-no-shrink"
                        ng-disabled="savingBillingInfo || !loadingStripeComplete || !data.acceptTerms"
                    >
                        Submit
                    </button>
                </div>
            </div>
            <div class="common-color-danger" ng-if="data.billingFormError">{{ data.billingFormError }}</div>
        </form>
    </div>

    <!-- Invoices-->
    <div ng-if="!loading && !data.showAch">
        <hr class="margin-top-xlg" />

        <div ng-if="data.stats">
            <!-- Title -->
            <h4 class="margin-bottom-lg">
                This period usage (
                <span uib-tooltip="{{ data.stats.periodStart | date: 'dd/MM/yyyy H:mm:ss' }}">
                    {{ data.stats.periodStart | date: 'dd/MM/yyyy' }}
                </span>
                -
                <span uib-tooltip="{{ data.stats.periodEnd | date: 'dd/MM/yyyy H:mm:ss' }}">
                    {{ data.stats.periodEnd | date: 'dd/MM/yyyy' }}
                </span>
                ):
            </h4>

            <div class="flex-vmiddle">
                <!-- Monitored Items -->
                <div class="padding-normal">
                    <div class="common-color-dark-grey">Monitored Items</div>
                    <div class="">
                        <span ng-if="loadingStats">...</span>
                        <span
                            ng-hide="loadingStats"
                            uib-tooltip="{{
                                data.stats.MONITORED_ITEMS > 999 ? (data.stats.MONITORED_ITEMS | number) : null
                            }}"
                        >
                            {{ data.stats.MONITORED_ITEMS | bigNumber }}&nbsp;
                        </span>
                    </div>
                </div>

                <!-- Data actions -->
                <div class="padding-normal">
                    <div class="common-color-dark-grey">Data Actions</div>
                    <div class="">
                        <span ng-if="loadingStats">...</span>
                        <span
                            ng-hide="loadingStats"
                            uib-tooltip="{{
                                data.stats.DATA_ACTIONS > 999 ? (data.stats.DATA_ACTIONS | number) : null
                            }}"
                        >
                            {{ data.stats.DATA_ACTIONS | bigNumber }}&nbsp;
                        </span>
                    </div>
                </div>

                <!-- People actions -->
                <div class="padding-normal">
                    <div class="common-color-dark-grey">People Coordination</div>
                    <div class="">
                        <span ng-if="loadingStats">...</span>
                        <span
                            ng-hide="loadingStats"
                            uib-tooltip="{{
                                data.stats.PEOPLE_ACTIONS > 999 ? (data.stats.PEOPLE_ACTIONS | number) : null
                            }}"
                        >
                            {{ data.stats.PEOPLE_ACTIONS | bigNumber }}&nbsp;
                        </span>
                    </div>
                </div>
            </div>

            <hr />
        </div>

        <!-- Title -->
        <h4 class="margin-bottom-lg">Invoices:</h4>

        <!-- Invoices table -->
        <table class="table" ng-show="!loading">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Time Period</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th class="hidden-xs">Description</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr ng-repeat="item in invoices">
                    <td class="common-ellipsis" style="max-width: 70px">{{ item.id }}</td>
                    <td>
                        <div class="flex-vmiddle flex-wrap" ng-if="!item.upcoming">
                            <span class="flex-no-shrink">{{ item.lines[0].periodStart | date: 'mediumDate' }}</span>
                            &nbsp;-&nbsp;
                            <span class="flex-no-shrink">{{ item.lines[0].periodEnd | date: 'mediumDate' }}</span>
                        </div>
                        <div ng-if="item.upcoming">Upcoming</div>
                    </td>
                    <td>
                        <div
                            uib-tooltip="This estimate is based on your current usage, excluding tax."
                            tooltip-enable="item.id === data.upcomingInvoiceId"
                            tooltip-placement="auto bottom"
                        >
                            {{ item.total / 100 | number }} {{ item.lines[0].currency.toUpperCase()
                            }}{{ item.id === data.upcomingInvoiceId ? ' *' : '' }}
                        </div>
                    </td>
                    <td>
                        <span ng-if="item.chargeId && item.paid" class="invoice-status mod-paid">PAID</span>
                        <span ng-if="!item.chargeId && !item.paid" class="invoice-status mod-open">OPEN</span>
                    </td>
                    <td class="common-break-long hidden-xs" ng-if="!item.upcoming">
                        <ul>
                            <li ng-repeat="line in item.lines | limitTo:(item.expand ? null : 2)">
                                {{ line.description }}
                            </li>
                        </ul>
                        <div class="margin-top-sm common-size-xxxxs">
                            <a ng-click="item.expand = !item.expand">{{ item.expand ? 'Less' : 'More' }}..</a>
                        </div>
                    </td>
                    <td class="common-break-long hidden-xs" ng-if="item.upcoming">
                        <div ng-repeat="period in item.periods" class="margin-bottom">
                            <div>
                                <strong>
                                    <span class="flex-no-shrink">
                                        {{ period.periodStart || period.lines[0].periodStart | date: 'mediumDate' }}
                                    </span>
                                    &nbsp;-&nbsp;
                                    <span class="flex-no-shrink">
                                        {{ period.periodEnd || period.lines[0].periodEnd | date: 'mediumDate' }}
                                    </span>
                                    :
                                </strong>
                            </div>
                            <ul>
                                <li ng-repeat="line in period.lines">
                                    {{ line.description }} =
                                    <strong>${{ line.amount / 100 | number }}:</strong>
                                </li>
                            </ul>
                        </div>
                    </td>
                    <td>
                        <a ng-if="item.invoiceHtml" href="{{ item.invoiceHtml }}" target="_blank">View</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
