<div class="common-bg mod-white common-height-full">
    <!-- Tab selection -->
    <div class="product-triggers-tab-selection">
        <div class="flex-vmiddle margin-auto padding-normal" style="max-width: 1200px">
            <div class="flex-vmiddle">
                <div
                    class="common-title-xs mod-light margin-right-lg common-clickable-bottom-border letter-spacing-sm"
                    ui-sref="product.triggers({tab: 'my'})"
                    ui-sref-active="mod-active"
                >
                    Your Triggers
                </div>
                <div
                    class="common-title-xs mod-light common-clickable-bottom-border letter-spacing-sm margin-left-xs"
                    ui-sref="product.triggers({tab: 'gallery'})"
                    ui-sref-active="mod-active"
                    analytics-on
                    analytics-category="Triggers page"
                    analytics-event="Open triggers gallery"
                >
                    Explore Gallery
                </div>
            </div>
        </div>
    </div>

    <div class="margin-auto" style="max-width: 1200px">
        <!-- My Triggers -->
        <div ng-if="tab === 'my'" class="padding-normal">
            <tnk-manage-triggers
                group-id="groupId"
                hide-bot-toggle="::true"
                mod-slim="::false"
                hide-close-btn="true"
            ></tnk-manage-triggers>
        </div>

        <!-- Gallery -->
        <div ng-if="tab === 'gallery'">
            <tnk-triggers-gallery is-iframe="false" title="Explore our Pre-Built Triggers"></tnk-triggers-gallery>
        </div>
    </div>
</div>
