import React from 'react';
import { Dropdown } from '@tonkean/infrastructure';

export default class BotHistoryItemDropdown extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps) {
        // The dropdown should only render itself if its isOpen status has changed (from true to false or vice versa).
        if (this.props.isOpen !== nextProps.isOpen) {
            return true;
        }

        return false;
    }

    render() {
        let dropdown = null;

        if (this.props.isOpen) {
            // Render the dropdown itself.
            dropdown = (
                <Dropdown classes="dropdown-menu-right" onClickOutside={this.props.toggle.bind(this)}>
                    <li>
                        <a onClick={this.props.goToTrack.bind(this, this.props.initiativeId)}>View Track</a>
                    </li>
                </Dropdown>
            );
        }

        return dropdown;
    }
}
