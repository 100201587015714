import type { TElement } from '@udecode/plate';

import type { Person } from '../Person';
import type TonkeanId from '../TonkeanId';
import type TonkeanType from '../TonkeanType';

export class WorkflowFolder {
    public id: TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
    public displayName: string;
    public groupIds: TonkeanId<TonkeanType.GROUP>[];
    public project: {
        id: TonkeanId<TonkeanType.PROJECT>;
    };
    public creator: Person;
    public owners: Person[];
    public publishers: Person[];
    public processOwners: Person[];
    public isUserOwner: boolean;
    public isUserPublisher: boolean;
    public isSandbox: boolean;
    public isHiddenFromNonSolutionCollaborator: boolean;
    public isVisible: boolean;

    /** @deprecated use htmlDescription. This is not maintained or updated if feature flag `FEATURE_SOLUTION_PAGE_USES_HTML_EDITOR` is on. */
    public description?: string;
    public htmlDescription: TElement[];
    /** @deprecated use htmlBusinessOutcome. This is not maintained or updated if feature flag `FEATURE_SOLUTION_PAGE_USES_HTML_EDITOR` is on. */
    public businessOutcome?: string;
    public htmlBusinessOutcome: TElement[];

    constructor(
        id: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        displayName: string,
        groupIds: TonkeanId<TonkeanType.GROUP>[] = [],
        projectId: TonkeanId<TonkeanType.PROJECT>,
        creator: any,
        owners: Person[],
        publishers: Person[],
        processOwners: Person[],
        isUserOwner: boolean,
        isUserPublisher: boolean,
        isSandbox: boolean,
        isHiddenFromNonSolutionCollaborator: boolean,
        description: string | undefined,
        htmlDescription: TElement[],
        businessOutcome: string | undefined,
        htmlBusinessOutcome: TElement[],
    ) {
        this.id = id;
        this.displayName = displayName;
        this.groupIds = groupIds;
        this.project = { id: projectId };
        this.creator = creator;
        this.owners = owners;
        this.publishers = publishers;
        this.processOwners = processOwners;
        this.isUserOwner = isUserOwner;
        this.isUserPublisher = isUserPublisher;
        this.isSandbox = isSandbox;
        this.isHiddenFromNonSolutionCollaborator = isHiddenFromNonSolutionCollaborator;
        this.businessOutcome = businessOutcome;
        this.htmlDescription = htmlDescription;
        this.description = description;
        this.htmlBusinessOutcome = htmlBusinessOutcome;

        this.isVisible = this.isHiddenFromNonSolutionCollaborator ? this.isUserOwner || this.isUserPublisher : true;
    }
}
