import ShopifyAuthenticationComponent from './ShopifyAuthenticationComponent';
import shopifyCircleIcon from '../../../../apps/tracks/images/integrations/shopify-circle.png';
import shopifyIcon from '../../../../apps/tracks/images/shopify.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class ShopifyIntegration extends IntegrationDefinitionBase {
    override name = 'shopify';
    override displayName = 'Shopify';
    override description = 'We will collect Products, Orders ans Customers.';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [
        new IntegrationEntity('Product', 'Products'),
        new IntegrationEntity('ProductVariant', 'Products Variants'),
        new IntegrationEntity('Order', 'Orders'),
        new IntegrationEntity('Customer', 'Customers'),
    ];
    override customizedAuthenticationComponent = ShopifyAuthenticationComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(shopifyCircleIcon, '130px'),
        new IntegrationIcon(shopifyIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: { Product: true, ProductVariant: true, Order: true, Customer: true },
        isOnlyDataRetention: {},
    };
}

export default ShopifyIntegration;
