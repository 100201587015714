import type { AngularServices } from 'angulareact';
import { getEntitiesFromRelatedEntitiesMap } from '@tonkean/tonkean-utils';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

class InitiativeManager {
    public static $inject: (string & keyof AngularServices)[] = [
        '$q', 'initiativeCache', 'entityInitiativeHelper', 'tonkeanService'
    ] as string[];

    constructor(
        private $q: AngularServices['$q'],
        private initiativeCache: AngularServices['initiativeCache'],
        private entityInitiativeHelper: AngularServices['entityInitiativeHelper'],
        private tonkeanService: AngularServices['tonkeanService'],
    ) {}

    /**
     * Fetches initiatives from the server.
     */
    searchInitiatives = (
        projectId: string,
        excludeStatuses: any[] | undefined,
        dateRange: {from: number, to: number},
        returnFlat: unknown,
        onlyGroupId: string,
        query: unknown,
        searchString: string | undefined,
        commonFilters: {
            tags?: any[];
            statuses?: any[];
            functions?: any[];
            owners?: any[];
            creators?: any[];
        },
        skip: number,
        limit: number,
        orderByField: string | undefined,
        orderByFieldType: string | undefined,
        orderByType: string | undefined,
        filterOnlyRootItems: boolean | undefined,
        onlyDraftInitiatives: boolean,
        updatedAfter: string,
        parentId?: TonkeanId<TonkeanType.INITIATIVE> | undefined,
        anyParentId?: TonkeanId<TonkeanType.INITIATIVE> | undefined,
        shouldFilterByRequesterId?: boolean | undefined,
    ): Promise<{ data: { entities: { id: string; [key: string]: any }[] } }> => {
        return this.tonkeanService
            .searchInitiatives(projectId, {
                isArchived: false,
                groupId: onlyGroupId,
                conditionsQuery: query,
                searchString,
                filterByTags: commonFilters?.tags,
                filterByStateTexts: commonFilters?.statuses,
                filterByFunctions: commonFilters?.functions,
                filterByOwnerIds: commonFilters?.owners,
                filterByCreatorIds: commonFilters?.creators,
                excludeByInitiativeStatuses: excludeStatuses,
                skip,
                limit,
                orderByFieldId: orderByField,
                orderByFieldType,
                orderByType,
                isRootInitiative: filterOnlyRootItems,
                isDraftInitiatives: onlyDraftInitiatives,
                from: dateRange?.from,
                to: dateRange?.to,
                updatedAfter,
                parentId,
                anyParentId,
                shouldFilterByRequesterId,
            })
            .then((data) => this.getInitiativesInner(data, returnFlat));
    };

    /**
     * Inner logic of the get initiatives function.
     */
    getInitiativesInner = (data, returnFlat) => {
        // Cache the returned results.
        data.entities = this.initiativeCache.cacheItems(data.entities);
        // Mark entities as fully loaded.
        for (let i = 0; i < data.entities.length; i++) {
            data.entities[i].isFullyLoaded = true;
        }

        let result = data.entities;

        if (data.relatedEntities) {
            this.initiativeCache.cacheItems(getEntitiesFromRelatedEntitiesMap(data.relatedEntities));
        }

        // If we don't want a flat tree, i.e we want a tree representation returned, we don't return children of parents who are also returned.
        // But, if we do want a flat tree, we just return all the items that we got.
        if (!returnFlat) {
            // Handle duplicates
            const children = {};

            // Get all children
            this.fillChildrenMapRecursively(data.entities, children);

            // If an item appears in the relatedEntities it means it's also a sub item in this list, therefor we hide it from the top list
            result = data.entities.filter((item) => !children[item.id]);
        }

        for (let i = 0; i < data.entities.length; i++) {
            const item = data.entities[i];

            if (item.hasRelatedInitiatives) {
                item.relatedInitiatives = this.initiativeCache.cacheItems(
                    item.relatedInitiatives,
                    false,
                    true,
                    true,
                    true,
                    true,
                );
            }

            if (item.parentInitiatives && item.parentInitiatives.length) {
                item.parentInitiatives = this.initiativeCache.cacheItems(item.parentInitiatives);
            }
            this.entityInitiativeHelper.enrichEntity(item);
        }

        return this.$q.resolve({ initiatives: result, data });
    }

    /**
     * Fill the childrenMap object with id to true for all the children of the given initiatives,
     * goes recursively so all the children childrens will also be mapped
     * @param initiatives
     * @param childrenMap
     */
    fillChildrenMapRecursively(initiatives, childrenMap) {
        for (const item of initiatives) {
            if (item.hasRelatedInitiatives) {
                for (let j = 0; j < item.relatedInitiatives.length; j++) {
                    childrenMap[item.relatedInitiatives[j].id] = true;
                    this.fillChildrenMapRecursively(item.relatedInitiatives, childrenMap);
                }
            }
        }
    }
}

angular.module('tonkean.shared').service('initiativeManager', InitiativeManager);
