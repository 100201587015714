import React from 'react';
import styled from 'styled-components';

import { MinusIcon, PlusIcon } from '@tonkean/svg';
import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const StyledWidthWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledWidthIconsWrapper = styled.span`
    display: flex;
    gap: 5px;
`;

interface Props {
    fieldDefinition: FieldDefinition;
    increaseFieldWidth?: (fieldDefinitionId: FieldDefinition['id']) => void;
    decreaseFieldWidth?: (fieldDefinitionId: FieldDefinition['id']) => void;
    disableEditingField?: boolean;
}

const FieldsWidgetConfigFieldWidth: React.FC<Props> = ({
    fieldDefinition,
    increaseFieldWidth,
    decreaseFieldWidth,
    disableEditingField,
}) => {
    if (!increaseFieldWidth || !decreaseFieldWidth) {
        return null;
    }

    return (
        <StyledWidthWrapper>
            Column width
            <StyledWidthIconsWrapper>
                <IconButton
                    key="increase"
                    disabled={disableEditingField}
                    onClick={() => increaseFieldWidth(fieldDefinition.id)}
                    data-automation="plus-icon"
                    buttonAsDiv
                >
                    <PlusIcon />
                </IconButton>
                <IconButton
                    key="decrease"
                    disabled={disableEditingField}
                    onClick={() => decreaseFieldWidth(fieldDefinition.id)}
                    data-automation="minus-icon"
                    buttonAsDiv
                >
                    <MinusIcon />
                </IconButton>
            </StyledWidthIconsWrapper>
        </StyledWidthWrapper>
    );
};

export default FieldsWidgetConfigFieldWidth;
