import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MultipleFieldToUpdateEditorsCtrl($scope, groupInfoManager) {
    const ctrl = this;

    $scope.data = {
        fieldSelectorGroupId: ctrl.fieldSelectorGroupId,
        fieldSelectorWorkflowVersionId: undefined,
        expressionGroupId: ctrl.expressionGroupId,
        expressionWorkflowVersionId: ctrl.expressionWorkflowVersionId,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        isPreselectedField: ctrl.isPreselectedField,
        preselectedFieldLabel: ctrl.preselectedFieldLabel,
        preselectedFieldId: ctrl.preselectedFieldId,
        configuredFields: ctrl.configuredFields || [{}],
        doNotIncludeFieldIdsSet: getSelectedFieldsId(ctrl.configuredFields),
        reloadFields: ctrl.reloadFields,
        specialFieldsForFeatures: ctrl.specialFieldsForFeatures,
        logicId: ctrl.logicId,
        validationObject: ctrl.validationObject,
        validationKeyPrefix: ctrl.validationKeyPrefix || '',
        extraButtonData: {
            trigger: ctrl.extraButtonTrigger,
            text: ctrl.extraButtonText,
            isRed: ctrl.isExtraButtonRed,
            visibility: ctrl.isExtraButtonVisible,
        },
        globalExpressionOnly: ctrl.globalExpressionOnly,
        allowDeleteOfLastField: ctrl.allowDeleteOfLastField
    };

    /**
     * Occurs on changes of the component.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredFields) {
            $scope.data.configuredFields = ctrl.configuredFields;
            $scope.data.doNotIncludeFieldIdsSet = getSelectedFieldsId(ctrl.configuredFields);
        }

        if (changesObj.fieldSelectorGroupId) {
            $scope.data.fieldSelectorGroupId = ctrl.fieldSelectorGroupId;
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = ctrl.previewEvaluationSource;
        }

        if (changesObj.fieldSelectorWorkflowVersionId) {
            if (ctrl.fieldSelectorWorkflowVersionId) {
                // If the group is another group that has not been loaded yet we will not be able to load fields
                groupInfoManager
                    .getGroup($scope.data.fieldSelectorGroupId)
                    .then(
                        (_ignore) => ($scope.data.fieldSelectorWorkflowVersionId = ctrl.fieldSelectorWorkflowVersionId),
                    );
            } else {
                $scope.data.fieldSelectorWorkflowVersionId = undefined;
            }
        }

        if (changesObj.expressionGroupId) {
            $scope.data.expressionGroupId = ctrl.expressionGroupId;
        }
    };

    /**
     * Occurs once a configured field is changed.
     */
    $scope.onConfiguredFieldChanged = function (configuredField, shouldSaveLogic, index) {
        $scope.data.configuredFields[index] = configuredField;
        $scope.onConfiguredFieldsChanged(shouldSaveLogic);
    };

    /**
     * Occurs on removal of a field.
     */
    $scope.onFieldRemoved = function (configuredField, index) {
        $scope.data.configuredFields.splice(index, 1);

        if (!$scope.data.configuredFields.length || $scope.data.configuredFields.length === 0) {
            $scope.data.configuredFields = $scope.data.allowDeleteOfLastField ? null : [{}];
        }
        $scope.onConfiguredFieldsChanged(true);
    };

    /**
     * Add empty field to update.
     */
    $scope.addFieldToUpdate = function () {
        if (!$scope.data.configuredFields) {
            $scope.data.configuredFields = [];
        }
        $scope.data.configuredFields.push({});
    };

    /**
     * Notifies users on change in fields.
     */
    $scope.onConfiguredFieldsChanged = function (shouldSaveLogic) {
        $scope.data.doNotIncludeFieldIdsSet = getSelectedFieldsId($scope.data.configuredFields);

        if (ctrl.onConfiguredFieldsChanged) {
            ctrl.onConfiguredFieldsChanged({
                configuredFields: $scope.data.configuredFields,
                shouldSaveLogic,
            });
        }
    };

    /**
     * returns the selected field ids.
     */
    function getSelectedFieldsId(configuredFields) {
        const selectedFieldsIds = {};

        if (configuredFields) {
            configuredFields.forEach((field) => {
                if (field?.fieldId) {
                    selectedFieldsIds[field.fieldId] = true;
                }
            });
        }

        return selectedFieldsIds;
    }
}

export default angular
    .module('tonkean.app')
    .controller('MultipleFieldToUpdateEditorsCtrl', lateConstructController(MultipleFieldToUpdateEditorsCtrl));
