export function GoogleCalendarModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    oauthHandler,
    consts,
    utils,
    projectManager,
    environment,
) {
    $scope.temp = {};
    $scope.data = {
        isUsingGCPServiceAccount: projectManager.project.features.tonkean_feature_gcp_service_account_enabled,
        saving: false,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.calendars = state.calendars;
        $scope.selectedCalendar = state.selectedCalendar || {};

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.authorize = async function (config) {
        $scope.connecting = true;
        $scope.error = null;

        try {
            if ($scope.data.isUsingGCPServiceAccount) {
                $scope.integration = await createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    config,
                    undefined,
                    undefined,
                );
            } else {
                $scope.integration = await createIntegrationUsingOAuth();
            }

            const calendars = await createProjectApis.getAutoCompleteOptions(
                projectManager.project.id,
                $scope.integration.id,
                'calendars',
            );
            $scope.calendars = calendars.options;
        } catch (error) {
            $scope.error = error;
        } finally {
            $scope.connecting = false;

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.calendars = null;
        $scope.authorize();
    };

    $scope.$watch('temp.calendar', function () {
        $scope.onCalendarSelected($scope.temp.calendar);
    });

    $scope.onCalendarSelected = function (calendar) {
        if (calendar && calendar.displayName) {
            $scope.selectedCalendar = calendar;
            $scope.temp.calendar = {};
        }
    };

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        $scope.data.saving = true;

        $scope.integration.projectDatas = [
            {
                calendar: $scope.selectedCalendar.value,
                calendarName: $scope.selectedCalendar.displayName,
            },
        ];

        let projectIntegrationPromise;
        const oldProject = projectManager.project;
        if ($scope.projectIntegration) {
            projectIntegrationPromise = createProjectApis.editProjectIntegration(
                projectManager.project.id,
                $scope.projectIntegration.id,
                $scope.integration,
            );
        } else {
            projectIntegrationPromise = createProjectApis.createProjectIntegration(
                projectManager.project,
                $scope.integration,
            );
        }

        projectIntegrationPromise.then((updatedProject) => {
            $scope.projectIntegration = utils.findFirst(
                updatedProject.integrations,
                (prin) =>
                    prin.id === $scope?.projectIntegration?.id ||
                    !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
            );

            projectManager.getProjectData(true).then(() => {
                $scope.data.saving = false;
                if ($scope.onIntegrationClosed) {
                    $scope.onIntegrationClosed({
                        createdProjectIntegration: $scope.projectIntegration,
                        integration: $scope.projectIntegration,
                        integrations: [{ integration: $scope.projectIntegration }],
                    });
                }
            });
        });
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (
            state &&
            state.integrations &&
            !$scope.integration &&
            state.integrations[0] &&
            state.integrations[0].integration
        ) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.projectIntegration = state.integrations[0];
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'calendars')
                .then(function (data) {
                    $scope.calendars = data.options;
                    const originalIntegration = state.integrations[0];
                    const projectData = originalIntegration.projectData.projectDatas[0];
                    if (
                        originalIntegration.projectData &&
                        originalIntegration.projectData.projectDatas &&
                        originalIntegration.projectData.projectDatas.length > 0
                    ) {
                        $scope.temp.calendar = $scope.selectedCalendar = data.options.find(function (calendar) {
                            // There's only one project integration for each integration type, so state.integrations[0] should exist.
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                if (projectData.calendar === calendar.value) {
                                    return calendar;
                                }
                            }
                        });
                    }
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.connecting = false;
                });
        }
    }

    async function createIntegrationUsingOAuth() {
        const redirectUri = environment.integrationKeysMap.googleUri;
        const code = await oauthHandler.google(
            environment.integrationKeysMap.google,
            consts.getGoogleCalendarScopes(),
            redirectUri,
            true,
        );
        const config = { code, redirectUri, environment: 'PUBLIC' };
        return createProjectApis.createIntegration(
            projectManager.project.id,
            $scope.currentIntegration.name,
            config,
            undefined,
            undefined,
        );
    }
}
export default angular.module('tonkean.shared').controller('GoogleCalendarModalCtrl', GoogleCalendarModalCtrl);
