<div class="margin-left margin-right">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Audience ID -->
        <div class="margin-bottom">
            <div class="margin-right">Audience ID</div>

            <input type="text" class="form-control" ng-model="data.audienceId" ng-blur="onDefinitionChanged(true)" />
        </div>

        <!-- User Email -->
        <div class="margin-bottom">
            <div>User Email</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="User email address"
                saved-original-expression="data.email"
                saved-evaluated-expression="data.evaluatedEmail"
                expression-unique-identifier="FAUSEREMAIL"
                on-tonkean-expression-changed="
                    onEmailTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- User First Name -->
        <div class="margin-bottom">
            <div>User First Name</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="User First Name"
                saved-original-expression="data.firstName"
                saved-evaluated-expression="data.evaluatedFirstName"
                expression-unique-identifier="FAUSERFIRSTNAME"
                on-tonkean-expression-changed="
                    onFirstNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- User Last Name -->
        <div class="margin-bottom">
            <div>User Last Name</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="User Last Name"
                saved-original-expression="data.lastName"
                saved-evaluated-expression="data.evaluatedLastName"
                expression-unique-identifier="FAUSERLASTNAME"
                on-tonkean-expression-changed="
                    onLastNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Action type -->
        <label>
            <input type="checkbox" ng-model="data.isRemove" ng-change="onDefinitionChanged(true)" />

            <span
                class="margin-left-xs"
                uib-tooltip="If this is a remove user action, if not this is an add user action"
                tooltip-append-to-body="true"
            >
                Is Remove?
            </span>
        </label>
    </div>
</div>
