import { useEffect, useMemo } from 'react';

import useIntakeCustomTriggersSequence from './useIntakeCustomTriggersSequence';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { FormType, type TonkeanId, type TonkeanType } from '@tonkean/tonkean-entities';

export interface useIntakeProgressTrackerConfigurationProps {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    currentCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    currentFormId?: TonkeanId<TonkeanType.FORM>;
    hasParentinitiative?: boolean;
}

export interface useIntakeProgressTrackerConfigurationResult {
    intakeProgressTrackerEnabled: boolean;
    intakeProgressTrackerTitle?: string;
    showVerticalIntakeProgressTracker?: boolean;
}

const useIntakeProgressTrackerConfiguration = ({
    workflowVersionId,
    currentCustomTriggerId,
    currentFormId,
    hasParentinitiative,
}: useIntakeProgressTrackerConfigurationProps): useIntakeProgressTrackerConfigurationResult => {
    const customTriggersSequence = useIntakeCustomTriggersSequence({
        workflowVersionId,
        customTriggerIdInSequence: currentCustomTriggerId,
        currentFormId,
    });

    const [{ data: initialTrigger }, getCustomTrigger] = useLazyTonkeanService('getCustomTrigger');

    useEffect(() => {
        if (customTriggersSequence[0] && customTriggersSequence[0]?.id !== initialTrigger?.id) {
            getCustomTrigger(workflowVersionId, customTriggersSequence[0]?.id);
        }
    }, [customTriggersSequence, workflowVersionId, getCustomTrigger, initialTrigger?.id]);

    return useMemo(() => {
        if (!initialTrigger) {
            return {
                intakeProgressTrackerEnabled: false,
                intakeProgressTrackerTitle: undefined,
                showVerticalIntakeProgressTracker: undefined,
            };
        }

        const initialTriggerMonitorForm = initialTrigger.monitorForms?.find((form) => form.formId === currentFormId);
        const isUpdateForm = initialTriggerMonitorForm?.formType === FormType.UPDATE;
        const isInnerItem = hasParentinitiative && !isUpdateForm;

        const customTriggerActionDefinition = initialTrigger.customTriggerActions?.[0]?.customTriggerActionDefinition;

        return {
            intakeProgressTrackerEnabled: !!(
                customTriggerActionDefinition?.intakeProgressTrackerEnabled && !isInnerItem
            ),
            intakeProgressTrackerTitle: customTriggerActionDefinition?.intakeProgressTrackerTitle,
            showVerticalIntakeProgressTracker: customTriggerActionDefinition?.showVerticalIntakeProgressTracker,
        };
    }, [initialTrigger, currentFormId, hasParentinitiative]);
};

export default useIntakeProgressTrackerConfiguration;
