/* eslint-disable no-useless-escape */

function BuiltInListsConsts(builtInListsTemplates) {
    const _this = this;

    _this.categories = {
        CSM: {
            id: 'CSM',
            title: 'Customer Success',
            symbol: 'fa-thumbs-o-up',
            titleKeywords: ['customer', 'account'],
        },
        FINANCE: {
            id: 'FINANCE',
            title: 'Finance',
            symbol: 'fa-usd',
            titleKeywords: ['finance', 'audit'],
        },
        HR: {
            id: 'HR',
            title: 'HR',
            symbol: 'fa-book',
            titleKeywords: ['hr', 'human'],
        },
        IT: {
            id: 'IT',
            title: 'IT',
            symbol: 'fa-asterisk',
            titleKeywords: ['it', 'ops', 'tech', 'operation', 'development', 'engineer'],
        },
        LEGAL: {
            id: 'LEGAL',
            title: 'Legal',
            symbol: 'fa-balance-scale',
            titleKeywords: ['legal'],
        },
        LIFEHACKS: {
            id: 'LIFEHACKS',
            title: 'Lifehacks',
            symbol: 'fa-calendar',
        },
        MARKETING: {
            id: 'MARKETING',
            title: 'Marketing',
            symbol: 'fa-filter',
            titleKeywords: ['marketing'],
        },
        PRODUCT: {
            id: 'PRODUCT',
            title: 'Product Management',
            symbol: 'fa-rocket',
            titleKeywords: ['product', 'project', 'pm'],
        },
        SALES: {
            id: 'SALES',
            title: 'Sales',
            symbol: 'fa-star',
            titleKeywords: ['sales'],
        },

        EMPTY: { id: 'EMPTY', title: 'Other', symbol: 'fa-asterisk' },
    };

    /**
     * Holds all lists available for creation in Tonkean.
     *
     * isExampleList - When true, it means the list is an example list, and we create example entities, without going through any steps.
     */
    _this.builtInLists = builtInListsTemplates.templates;

    /**
     * Returns a dictionary of category id to category definition object, as it is defined in the config.
     * @param onlyExampleLists - when true, only categories who have example lists in them will return.
     * * @param workerTemplates - should we return list template (default) or worker templates.
     */
    _this.getCategories = function (onlyExampleLists, workerTemplates) {
        workerTemplates = !!workerTemplates;

        // First, going over the lists and gathering all categories who have at least one example/not disabled list.
        const includedCategoriesSet = {};
        for (const listId in _this.builtInLists) {
            if (
                _this.builtInLists.hasOwnProperty(listId) &&
                (!onlyExampleLists || _this.builtInLists[listId].isExampleList) &&
                !_this.builtInLists[listId].disabled &&
                workerTemplates === !!_this.builtInLists[listId].isWorkerTemplate
            ) {
                includedCategoriesSet[_this.builtInLists[listId].category] = true;

                // adding the additional ones
                if (_this.builtInLists[listId].additionalCategoriesSet) {
                    const additionalCategoriesSet = _this.builtInLists[listId].additionalCategoriesSet;
                    for (const additionalCat in additionalCategoriesSet) {
                        if (
                            additionalCategoriesSet.hasOwnProperty(additionalCat) &&
                            additionalCategoriesSet[additionalCat]
                        ) {
                            includedCategoriesSet[additionalCat] = true;
                        }
                    }
                }
            }
        }

        // Next, we filter existing categories to only those who exist in the includedCategoriesSet.
        const includedCategoriesMap = {};
        for (const categoryId in _this.categories) {
            if (_this.categories.hasOwnProperty(categoryId) && includedCategoriesSet[categoryId]) {
                includedCategoriesMap[categoryId] = _this.categories[categoryId];
            }
        }

        return includedCategoriesMap;
    };
    /**
     * Returns an dictionary of listId to list property.
     * @param onlyExampleLists - when true, only lists marked as example lists are returned.
     * @param workerTemplates - should we return list template (default) or worker templates.
     * @returns {*}
     */
    _this.getBuiltInLists = function (onlyExampleLists, workerTemplates) {
        const lists = {};

        workerTemplates = !!workerTemplates;

        // Go over the built in lists and only take the ones that are marked as example lists.
        for (const listId in _this.builtInLists) {
            if (
                _this.builtInLists.hasOwnProperty(listId) &&
                (!onlyExampleLists || _this.builtInLists[listId].isExampleList) &&
                !_this.builtInLists[listId].disabled &&
                workerTemplates === !!_this.builtInLists[listId].isWorkerTemplate
            ) {
                lists[listId] = _this.builtInLists[listId];
            }
        }

        return lists;
    };

    _this.getEmptyList = function () {
        return _this.builtInLists['EMPTY'];
    };
}

export default angular.module('tonkean.shared').service('builtInListsConsts', BuiltInListsConsts);
