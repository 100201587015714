import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function OutgoingWebhookLogicConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        onActionsChanged: ctrl.onActionsChanged,
        importActionConfig: ctrl.importActionConfig,
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.importActionConfig) {
            $scope.data.importActionConfig = changesObj.importActionConfig.currentValue;
        }
    };

    $scope.onDefinitionChanged = function (definition, shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const actionDefinition = {
                actions: [
                    {
                        type: 'OUTGOING_WEBHOOK',
                        definition,
                    },
                ],
            };

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'OutgoingWebhookLogicConfigurationCtrl',
        lateConstructController(OutgoingWebhookLogicConfigurationCtrl),
    );
