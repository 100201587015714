import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import getManualNlpActionBranchAsRow from './getManualNlpActionBranchAsRow';
import HistoryNlpInspectTabEmptyState from './HistoryNlpInspectTabEmptyState';
import TableChevronCell from './TableChevronCell';
import type { TabComponentProps } from '../../../entities/WorkerRunLogicInspectTabData';

import { Table } from '@tonkean/infrastructure';
import type { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';
import { TableSize } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const SingleWorkerRunActionInspectManualNlpAction: React.FC<
    TabComponentProps<WorkerRunLogicInspectTabType.MANUAL_NLP>
> = ({ logic, branchResults, workerRun }) => {
    const consts = useAngularService('consts');
    const logicBlockTypes = consts.getLogicBlockTypes();

    const activeChildBranches = useMemo(() => {
        return logic.impacts.filter((impact) => !impact.node.isHidden && !impact.node.disabled);
    }, [logic.impacts]);

    const activeBranchesWorkerRunLogics = useMemo(() => {
        const logicImpactsByBranchId = utils.createMapFromArray(activeChildBranches, (impact) => impact.node.id);

        return workerRun.workerRunLogics?.filter((entity) => logicImpactsByBranchId[entity.customTriggerId]);
    }, [activeChildBranches, workerRun.workerRunLogics]);

    const resultsByBranchId = useMemo(() => {
        return utils.createMapFromArray(branchResults, 'branchId');
    }, [branchResults]);

    const rows = useMemo(() => {
        return activeChildBranches
            .map((branchImpact) => {
                const branchResult = resultsByBranchId[branchImpact.node.id];

                return getManualNlpActionBranchAsRow(
                    branchImpact,
                    logicBlockTypes,
                    branchResult,
                    activeBranchesWorkerRunLogics,
                );
            })
            .filter((row) => row);
    }, [activeBranchesWorkerRunLogics, activeChildBranches, logicBlockTypes, resultsByBranchId]);

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'chevron' as const,
                Cell: TableChevronCell,
            },
            {
                Header: 'Branch',
                accessor: 'branch' as const,
                width: '60%',
            },
            {
                Header: 'Status',
                accessor: 'status' as const,
                width: '40%',
            },
        ],
        [],
    );

    return activeChildBranches.length ? (
        <Table columns={columns} data={rows} size={TableSize.SMALL} expendableRows border />
    ) : (
        <HistoryNlpInspectTabEmptyState logic={logic} />
    );
};

export default SingleWorkerRunActionInspectManualNlpAction;
