import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const TextExtractorLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.TEXT_EXTRACTOR,
    title: 'Text Extractor',
    iconClass: 'mod-text-extractor-trigger',
    description: 'Extract data fields from text sources (Files, OCR, Email) to use across your module',

    validator({ definition }) {
        const validationObject: LogicBlockValidationObject = {};

        if (!definition?.inputText?.evaluatedExpression) {
            validationObject.noInputText = 'Must have input text';
        }
        if (!definition?.trainingSetId) {
            validationObject.noTextExtractor = 'Must have a Text Extractor';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default TextExtractorLogicBlockConfig;
