import type { TElement } from '@udecode/plate';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import MarketplaceItemCreateModalKeyMetrics from './MarketplaceItemCreateModalKeyMetrics';
import MarketplaceItemCreateModalContext from '../../../MarketplaceItemCreateModalContext';
import MarketPlaceItemCreateModalUploadImagesSection from '../MarketPlaceItemCreateModalUploadImagesSection';

import { HTMLEditor } from '@tonkean/editor';
import { Field, useFormikField } from '@tonkean/infrastructure';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

const MarketplaceItemCreateModalSolutionContentSection: React.FC = () => {
    const { workflowFolder } = useContext(MarketplaceItemCreateModalContext);
    const { value: description, setValue: setDescription } = useFormikField<TElement[]>('configuration.description');
    const { value: businessOutcome, setValue: setBusinessOutcome } = useFormikField<TElement[]>(
        'configuration.businessOutcome',
    );

    const initialDescription = workflowFolder?.htmlDescription;
    const initialBusinessOutcome = workflowFolder?.htmlBusinessOutcome;

    // Set initial values for formik
    useEffect(() => {
        if (initialDescription) {
            setDescription(initialDescription);
        }
        if (initialBusinessOutcome) {
            setBusinessOutcome(initialBusinessOutcome);
        }
    }, [initialBusinessOutcome, initialDescription, setBusinessOutcome, setDescription]);

    return (
        <>
            <StyledField label="Description" informationTooltip="Please enter the blueprint description.">
                <HTMLEditor
                    dataAutomation="marketplace-item-create-modal-summary"
                    initialValue={initialDescription || description}
                    onChange={(value) => setDescription(value)}
                />
            </StyledField>

            <StyledField label="Upload Hero Image">
                <MarketPlaceItemCreateModalUploadImagesSection maximumFiles={1} dataAutomation="upload-hero-image" />
            </StyledField>

            <StyledField
                label="Business Outcome"
                informationTooltip="Please enter the blueprint's primary business outcomes. "
            >
                <HTMLEditor
                    dataAutomation="marketplace-item-create-modal-business-outcome"
                    initialValue={initialBusinessOutcome || businessOutcome}
                    onChange={(value) => setBusinessOutcome(value)}
                />
            </StyledField>
            <MarketplaceItemCreateModalKeyMetrics />
        </>
    );
};

export default MarketplaceItemCreateModalSolutionContentSection;
