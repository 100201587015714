import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ColumnsIcon } from '../../../../../images/icons/columns.svg';
import { ReactComponent as EditIcon } from '../../../../../images/icons/edit.svg';
import { ReactComponent as LightningsIcon } from '../../../../../images/icons/lightning.svg';
import { ReactComponent as TrashIcon } from '../../../../../images/icons/trash.svg';

import { KebabMenuButton, Menu, MenuItem } from '@tonkean/infrastructure';
import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';

const StyledTrashIcon = styled(TrashIcon)`
    width: 15px;
`;

const SingleMenuItem = styled.div`
    font-size: ${FontSize.XSMALL_10};
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface Props {
    menuIsOpen: boolean;
    setMenuIsOpen: (boolean) => void;
    fieldDefinition: FieldDefinition<unknown>;
    applyFieldDependenciesFilter: (fieldDefinition: FieldDefinition<unknown>) => void;
    applyTriggerDependenciesFilter: (fieldDefinition: FieldDefinition<unknown>) => void;
    onFieldSelectedCallback?: (fieldDefinition) => void;
    deleteFieldDefinition?: (fieldDefinition: FieldDefinition<unknown>) => void;
}
const CustomItemMatchFieldsMetadataTableMenuList: React.FC<Props> = ({
    menuIsOpen,
    setMenuIsOpen,
    fieldDefinition,
    applyFieldDependenciesFilter,
    applyTriggerDependenciesFilter,
    onFieldSelectedCallback,
    deleteFieldDefinition,
}) => {
    return (
        <Menu
            key={`${fieldDefinition.id}_menu_kebab`}
            menuItems={
                <>
                    {onFieldSelectedCallback && (
                        <MenuItem onClick={() => onFieldSelectedCallback(fieldDefinition)}>
                            <SingleMenuItem>
                                <IconWrapper>
                                    <EditIcon />
                                </IconWrapper>
                                Edit matched by field
                            </SingleMenuItem>
                        </MenuItem>
                    )}
                    {deleteFieldDefinition && (
                        <MenuItem onClick={() => deleteFieldDefinition(fieldDefinition)}>
                            <SingleMenuItem>
                                <IconWrapper>
                                    <StyledTrashIcon />
                                </IconWrapper>
                                Delete
                            </SingleMenuItem>
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            applyFieldDependenciesFilter(fieldDefinition);
                        }}
                    >
                        <SingleMenuItem>
                            <IconWrapper>
                                <ColumnsIcon />
                            </IconWrapper>
                            Filter Dependent Fields
                        </SingleMenuItem>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            applyTriggerDependenciesFilter(fieldDefinition);
                        }}
                    >
                        <SingleMenuItem>
                            <IconWrapper>
                                <LightningsIcon />
                            </IconWrapper>
                            Filter Dependent Triggers
                        </SingleMenuItem>
                    </MenuItem>
                </>
            }
            show={menuIsOpen}
            onClose={() => setMenuIsOpen(false)}
        >
            <KebabMenuButton
                aria-label="open menu"
                onClick={(e) => {
                    setMenuIsOpen(true);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                iconSize={12}
                flat
            />
        </Menu>
    );
};
export default CustomItemMatchFieldsMetadataTableMenuList;
