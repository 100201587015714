import { useAngularService } from 'angulareact';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import type * as Yup from 'yup';

import ProjectIntegrationPageEmailIntakeAccessControlPage from './ProjectIntegrationAccessControlMail/ProjectIntegrationPageEmailIntakeAccessControlPage';
import ProjectIntegrationPageWebhookAccessControlPage from './ProjectIntegrationAccessControlWebhook/ProjectIntegrationPageWebhookAccessControlPage';
import EnterpriseComponentPageTemplate from '../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import ValidateControlAccess from '../../entities/ValidateControllAccess';
import getDefaultAccessControlDefinition from '../../utils/getDefaultAccessControlDefinition';
import ProjectIntegrationPageContext from '../ProjectIntegrationPageContext';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { H1, SavingIndicator } from '@tonkean/infrastructure';
import type { AccessControlDefinition } from '@tonkean/tonkean-entities';
import { AccessControlType, IntegrationType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { getStateError } from '@tonkean/utils';

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SaveButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Error = styled.span`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.error};
    margin-right: 6px;
    display: inline-flex;
    margin-bottom: 2px;
`;

const SubmitButton = styled(Button)`
    margin-left: 4px;
`;

type ControlAccessFormik = Yup.InferType<typeof ValidateControlAccess>;

const ProjectIntegrationPageAccessControlPage: React.FC = () => {
    const { currentProjectIntegration, setCurrentProjectIntegration } = useContext(ProjectIntegrationPageContext);
    const projectManager = useAngularService('projectManager');

    const [{ loading: updateLoading, error: updateError }, updateProjectIntegrationAccessControl] =
        useLazyTonkeanService('updateProjectIntegrationAccessControl');

    const [{ data: webhook }, getIncomingWebhookByProjectIntegrationId] = useLazyTonkeanService(
        'getIncomingWebhookByProjectIntegrationId',
    );

    const [{ loading: createKeyLoading, error: createKeyError }, setIntegrationEncryptionKey] =
        useLazyTonkeanService('setIntegrationEncryptionKey');

    const error = updateError || createKeyError;
    const loading = updateLoading || createKeyLoading;

    // Native integrations have no incoming webhook.
    useEffect(() => {
        if (
            currentProjectIntegration?.integration.integrationType === IntegrationType.WEBHOOK ||
            currentProjectIntegration?.integration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE
        ) {
            getIncomingWebhookByProjectIntegrationId(
                projectManager.project.id,
                currentProjectIntegration?.id || '',
                null,
                'TONKEAN',
            );
        }
    }, [
        currentProjectIntegration?.id,
        currentProjectIntegration?.integration,
        getIncomingWebhookByProjectIntegrationId,
        projectManager.project.id,
    ]);

    // Update the cached project integration access Control definition
    const updateProjectIntegrationDefinition = (definition: AccessControlDefinition) => {
        if (currentProjectIntegration) {
            setCurrentProjectIntegration({
                ...currentProjectIntegration,
                accessControlDefinition: definition,
            });
        }
    };

    // OnSave the access control we get the formik values and create Access Definition and encryption key.
    const extractFormValues = (
        controlAccessValue: ControlAccessFormik,
    ): { accessControl?: AccessControlDefinition; encryptionKey?: string } | undefined => {
        if (!currentProjectIntegration) {
            return;
        }

        const { accessControl, encryptionKey } = controlAccessValue;

        switch (accessControl?.accessControlType) {
            case AccessControlType.EMAIL_INBOX:
                return {
                    accessControl: {
                        ...accessControl,
                        fromEmailDomains: {
                            ...accessControl.fromEmailDomains,
                            emailDomains: accessControl.fromEmailDomains.emailDomains.map((domain) => ({
                                value: domain || '',
                            })),
                        },
                        fromEmailAddresses: {
                            ...accessControl.fromEmailAddresses,
                            emailAddresses: accessControl.fromEmailAddresses.emailAddresses.map((address) => ({
                                value: address || '',
                            })),
                        },
                    },
                    encryptionKey: undefined,
                };

            case AccessControlType.WEBHOOK:
                return {
                    accessControl: {
                        ...accessControl,
                        ipRestrictionGroups: {
                            ...accessControl.ipRestrictionGroups,
                            ipRestrictions: accessControl.ipRestrictionGroups.ipRestrictions.map((ipRestriction) => ({
                                displayName: ipRestriction.displayName || '',
                                ipRangeValues: ipRestriction.ipRangeValues.map((ipRangeValue) => ({
                                    ...ipRangeValue,
                                    fromIp: ipRangeValue.fromIp || '',
                                    toIp: ipRangeValue.toIp || '',
                                })),
                            })),
                        },
                        tokenRestriction: {
                            ...accessControl.tokenRestriction,
                            token: accessControl.tokenRestriction.token || '',
                            headerName: accessControl.tokenRestriction.headerName || '',
                        },
                    },
                    encryptionKey,
                };

            default:
                return {
                    encryptionKey,
                };
        }
    };

    // On submitting the form
    const submitProjectIntegrationAccessControl = (value) => {
        const { accessControl, encryptionKey } = extractFormValues(value) || {};

        if (
            accessControl?.accessControlType &&
            accessControl?.accessControlType !== AccessControlType.NATIVE_INTEGRATION &&
            currentProjectIntegration
        ) {
            updateProjectIntegrationDefinition(accessControl);
            updateProjectIntegrationAccessControl(currentProjectIntegration.id, accessControl);
        }

        if (
            currentProjectIntegration &&
            encryptionKey &&
            encryptionKey !== currentProjectIntegration?.integration.encryptionKeyDummy
        ) {
            setIntegrationEncryptionKey(
                currentProjectIntegration.projectId,
                currentProjectIntegration.id,
                encryptionKey,
            ).then(() => {
                currentProjectIntegration.integration.encryptionKeyDummy = encryptionKey;
            });
        }
    };

    // Initialize formik values.
    const formikInitialValue = useMemo(() => {
        const defaultDefinition = getDefaultAccessControlDefinition(
            currentProjectIntegration?.integration.integrationType,
        );

        const accessControl = currentProjectIntegration?.accessControlDefinition || defaultDefinition;

        const encryptionKey = currentProjectIntegration?.integration.encryptionKeyDummy || '';

        switch (accessControl.accessControlType) {
            case AccessControlType.EMAIL_INBOX:
                return {
                    accessControl: {
                        ...accessControl,
                        fromEmailDomains: {
                            ...accessControl.fromEmailDomains,
                            emailDomains: accessControl.fromEmailDomains.emailDomains.map((domain) => domain.value),
                        },
                        fromEmailAddresses: {
                            ...accessControl.fromEmailAddresses,
                            emailAddresses: accessControl.fromEmailAddresses.emailAddresses.map(
                                (address) => address.value,
                            ),
                        },
                    },
                    encryptionKey,
                };

            case AccessControlType.WEBHOOK:
                return {
                    accessControl: {
                        ...accessControl,
                        tokenRestriction: {
                            ...accessControl.tokenRestriction,
                            token: webhook?.incomingWebhook.accessControlToken,
                        },
                    },
                    encryptionKey,
                };

            case AccessControlType.NATIVE_INTEGRATION:
                return {
                    accessControl,
                    encryptionKey,
                };
        }
    }, [
        currentProjectIntegration?.accessControlDefinition,
        currentProjectIntegration?.integration.integrationType,
        currentProjectIntegration?.integration.encryptionKeyDummy,
        webhook?.incomingWebhook.accessControlToken,
    ]);

    // Check if we have the data we need to load the form
    const isRequiredDataLoaded = useMemo(() => {
        if (currentProjectIntegration?.accessControlDefinition?.accessControlType === AccessControlType.WEBHOOK) {
            return !!webhook;
        }
        return true;
    }, [currentProjectIntegration?.accessControlDefinition, webhook]);

    return (
        <Formik
            validationSchema={ValidateControlAccess}
            initialValues={formikInitialValue}
            onSubmit={(values) => submitProjectIntegrationAccessControl(values)}
            enableReinitialize
        >
            {({ values }) => (
                <Form>
                    <EnterpriseComponentPageTemplate
                        name="Access Control"
                        title={
                            <HeaderWrapper>
                                <H1>Access Control</H1>

                                <SaveButtonWrapper>
                                    <SavingIndicator loading={loading} error={error} />

                                    {error && <Error>{getStateError(error)}</Error>}
                                    <SubmitButton type="submit">Save Changes</SubmitButton>
                                </SaveButtonWrapper>
                            </HeaderWrapper>
                        }
                        description="Restrict access to the data source using one or more authorization methods."
                    >
                        {isRequiredDataLoaded && formikInitialValue && currentProjectIntegration && (
                            <>
                                {values.accessControl.accessControlType === AccessControlType.EMAIL_INBOX && (
                                    <ProjectIntegrationPageEmailIntakeAccessControlPage />
                                )}

                                {values.accessControl.accessControlType !== AccessControlType.EMAIL_INBOX && (
                                    <ProjectIntegrationPageWebhookAccessControlPage />
                                )}
                            </>
                        )}
                    </EnterpriseComponentPageTemplate>
                </Form>
            )}
        </Formik>
    );
};

export default ProjectIntegrationPageAccessControlPage;
