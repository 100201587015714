import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function PicklistValueEditorCtrl($scope, $timeout, utils, syncConfigCacheManager, customFieldsManager, modal) {
    const ctrl = this;

    $scope.scm = syncConfigCacheManager;
    $scope.cfm = customFieldsManager;

    $scope.data = {
        // Component bindings
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
        existingValues: ctrl.existingValues,
        sourceOptions: ctrl.sourceOptions,
        disableEdit: ctrl.disableEdit,
        enableImport: ctrl.enableImport,

        picklistValues: [],
        picklistValuesChangeMap: {},
        anyExistingValuesChanged: false,
        existingValuesChanged: null,
        selectedImportField: null,
        isOpen: false,
        required: ctrl.required,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        if (!$scope.data.createMode) {
            initializeEditMode();
        } else {
            $scope.data.picklistValues = [getEmptyPickListValue(), getEmptyPickListValue(), getEmptyPickListValue()];
        }

        picklistChanged();
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.existingValues) {
            $scope.data.existingValues = changesObj.existingValues.currentValue;
            $scope.data.picklistValues = [];
        }
        if(changesObj.required){
            $scope.data.required = changesObj.required.currentValue;
        }

        initializeEditMode();
    };

    /**
     * Fired when an item is moved from its old position within a drag-and-drop event.
     * @param oldIndex - the old original index of the item (before moving it).
     * @param newIndex - the new index of the item (to where should we move it).
     */
    $scope.onOptionMoved = function (oldIndex, newIndex) {
        // Remove the old item and save it so we can add it to its new position.
        const movedItem = $scope.data.picklistValues.splice(oldIndex, 1)[0];

        // If the old index is before the new one, removing the item will cause a shift in 1 position.
        if (oldIndex < newIndex) {
            newIndex = newIndex - 1;
        }

        // Place the item in its new position.
        $scope.data.picklistValues.splice(newIndex, 0, movedItem);

        onChange();
        picklistChanged();
    };

    /**
     * @param $event {ClipboardEvent}
     * @param index {number}
     */
    $scope.onPicklistValuePaste = ($event, index) => {
        const paste = ($event.clipboardData || window.clipboardData).getData('text');
        const pastedArray = paste
            .split(',')
            .map((option) => option.trim())
            .map(getPickListValue);

        if (pastedArray.length === 1) {
            return;
        }

        /** @type {HTMLInputElement}  */
        const target = $event.target;
        if (target.selectionStart === undefined) {
            // if the browser don't support selection
            return;
        }

        $event.preventDefault();

        modal
            .alert('Paste Options From Clipboard', {
                body: 'Do you want to create multiple options? (split by comma)',
                isWarn: false,
                okLabel: 'Split',
                backdrop: 'static',
                cancelLabel: 'Keep',
            })
            .then(() => {
                const shouldOverrideSelf = !$scope.data.picklistValues[index].value?.trim().length;

                $scope.data.picklistValues.splice(
                    shouldOverrideSelf ? index : index + 1,
                    shouldOverrideSelf ? 1 : 0,
                    ...pastedArray,
                );
            })
            .catch(() => {
                $scope.data.picklistValues[index].value = utils.replaceStringAt(
                    $scope.data.picklistValues[index].value || '',
                    paste,
                    target.selectionStart,
                    target.selectionEnd,
                );
            })
            .then(() => {
                onChange();
                picklistChanged();
            });
    };

    /**
     * Once you press an key during values definition.
     */
    $scope.onPicklistValueKeyDown = function ($event, index) {
        if ($event.code === 'Enter' || $event.keyCode === 13) {
            // Prevent submit logic
            $event.preventDefault();

            onChange();

            // Add a new empty option if needed
            const nextValue = $scope.data.picklistValues[index + 1];
            if (!nextValue) {
                $scope.addPicklistValue();
            }

            // Wait for option to be rendered and then focus it
            $timeout(() => {
                utils.focus(`picklist-value-${index + 1}`);
            });
        }
    };

    $scope.escape = (value) => {
        return value.replaceAll("'", "\\'");
    };

    /**
     * Occurs once a change to the picklist possible values is applied.
     */
    $scope.changeToPicklistValue = function (newValue, originalValue, index) {
        picklistChanged();

        // If we're in create mode or it's a value created in this edit session, we don't care for the data change warning.
        if ($scope.data.duplicateOrCreateMode || $scope.data.picklistValues[index].newValue) {
            return;
        }

        const existingPicklistValue = $scope.data.picklistValues[index];

        // If we do not have the picklist value in the array, we initialize it with its original value, so we can know when to revert the change warning message.
        if (!$scope.data.picklistValuesChangeMap[existingPicklistValue.id]) {
            $scope.data.picklistValuesChangeMap[existingPicklistValue.id] = {
                originalValue,
            };
        }

        // Marking the change, unless it is a revert to the old state, and then we mark it as not changed.
        $scope.data.picklistValuesChangeMap[existingPicklistValue.id].changed =
            newValue !== $scope.data.picklistValuesChangeMap[existingPicklistValue.id].originalValue;

        // Evaluate parameters.
        evaluatePicklistValuesChangeParameters();
        onChange();
    };

    /**
     * Removes a picklist possible value from the picklist values list of possible values.
     */
    $scope.removePicklistValue = function (currentValue, index) {
        // If it's a new value created during this edit session, we don't care for marking it as changed, as it isn't a change to an existing value.
        if (!$scope.data.picklistValues[index].newValue) {
            const existingValue = $scope.data.picklistValues[index];

            $scope.data.picklistValuesChangeMap[existingValue.id] = {
                originalValue: currentValue,
                changed: true,
            };
        }

        // Evaluate parameters.
        evaluatePicklistValuesChangeParameters();

        if ($scope.data.picklistValues.length === 1) {
            $scope.data.picklistValues[0].value = '';
        } else {
            $scope.data.picklistValues.splice(index, 1);
        }

        onChange();

        // Call the parent callback with the new picklist values
        picklistChanged();
    };

    /**
     * Adds another possible value to the picklist list of possible values.
     */
    $scope.addPicklistValue = function () {
        onChange();
        $scope.data.picklistValues.push(getEmptyPickListValue());
    };

    /**
     * Function which handles the import fields selection. It extracts the possible values from the chosen field.
     * @param field - the selected field to extract possible values from.
     */
    $scope.onFieldOptionSelected = function (field) {
        if (field.id) {
            $scope.cfm.getFieldDefinitions($scope.data.workflowVersionId).then((fieldDefinitions) => {
                $scope.data.selectedImportField = fieldDefinitions.find((f) => f.id === field.id);
            });
        } else if (field.values) {
            // If it's an external field
            $scope.data.selectedImportField = {
                ...field,
                possibleValues: field.values,
            };
        }
        onChange();
    };

    /**
     * Handles the closing of the import popover
     * @param $event
     */
    $scope.onImportPopoverClose = function ($event) {
        // If we're not stopping the event, the modal of field definition will close
        $event.preventDefault();
        $event.stopPropagation();

        $scope.data.isOpen = false;

        // Empty the possible values
        $scope.data.selectedImportField = null;
    };

    $scope.onImportSubmit = function ($event) {
        // If we're not stopping the event, the modal of field definition will close
        $event.preventDefault();
        $event.stopPropagation();

        $scope.data.isOpen = false;

        // Add the chosen import fields to the picklist values array
        $scope.data.picklistValues.push(
            ...$scope.data.selectedImportField.possibleValues.map((option) => ({
                id: utils.guid(),
                value: option,
                newValue: option,
            })),
        );

        // Remove empty values
        $scope.data.picklistValues = $scope.data.picklistValues.filter((option) => option.value);

        // Update relevant maps and callbacks with the change
        evaluatePicklistValuesChangeParameters();
        picklistChanged();

        // Empty the possible values
        $scope.data.selectedImportField = null;
    };

    /**
     * Evaluates the variables used to display change warning message.
     */
    function evaluatePicklistValuesChangeParameters() {
        const mapKeys = utils.objKeys($scope.data.picklistValuesChangeMap);

        // Aggregating a boolean indicating if there was any change.
        $scope.data.anyExistingValuesChanged = mapKeys.some((key) => $scope.data.picklistValuesChangeMap[key].changed);

        // Aggregating all changed values to one string.
        $scope.data.existingValuesChanged = mapKeys
            .filter((key) => $scope.data.picklistValuesChangeMap[key].changed)
            .map((key) => $scope.data.picklistValuesChangeMap[key].originalValue)
            .filter((value) => value)
            .join(', ');
    }

    /**
     * Occurs once the definition changes.
     */
    function picklistChanged() {
        const possibleValues = $scope.data.picklistValues
            ? $scope.data.picklistValues.map((valueObj) => valueObj.value).filter((val) => val)
            : null;

        if (ctrl.onPicklistChanged) {
            ctrl.onPicklistChanged({ values: possibleValues });
        }
    }

    /**
     * Initializes elastic edit mode.
     */
    function initializeEditMode() {
        if ($scope.data.existingValues && $scope.data.existingValues.length) {
            for (let i = 0; i < $scope.data.existingValues.length; i++) {
                $scope.data.picklistValues[i] = {
                    id: utils.guid(),
                    value: $scope.data.existingValues[i],
                };
            }
        }
    }

    /**
     * Get an empty pick list value.
     */
    function getEmptyPickListValue() {
        return getPickListValue('');
    }

    $scope.clearAllPicklistValues = function () {
        $scope.data.picklistValues = [getEmptyPickListValue()];
        onChange();
        picklistChanged();
    };

    /**
     * Get pick list value.
     */
    function getPickListValue(value) {
        return {
            id: utils.guid(),
            value,
            newValue: true,
        };
    }

    function onChange() {
        ctrl.onChange();
    }
}

export default angular.module('tonkean.app').controller('PicklistValueEditorCtrl', lateConstructController(PicklistValueEditorCtrl));
