import React from 'react';
import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const SubHeader = styled.p`
    margin: 14px 0 24px 0;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    max-width: 50%;
`;

const LearnMoreLink = styled.a`
    white-space: nowrap;
`;
interface Props {
    headerText: string;
    subHeaderText: string;
    knowledgeBaseUrl: string;
}

const EnterpriseComponentTabHeader: React.FC<Props> = ({ headerText, subHeaderText, knowledgeBaseUrl }) => {
    return (
        <>
            <h1 data-automation={`enterprise-component-${headerText}`}>{headerText}</h1>
            <SubHeader>
                {subHeaderText}{' '}
                <LearnMoreLink href={knowledgeBaseUrl} target="_blank">
                    Learn more
                </LearnMoreLink>
            </SubHeader>
        </>
    );
};

export default EnterpriseComponentTabHeader;
