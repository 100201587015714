import React from 'react';

import ItemWidgetsInlineActionMenuItem from './ItemWidgetsInlineActionMenuItem';

import type {
    CustomTrigger,
    FieldDefinitionInterfaceId,
    Form,
    Initiative,
    InterfaceCTAActionType,
    ItemInterface,
    TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

export type ItemGridMenuActionIds =
    | CustomTrigger['id']
    | ItemInterface['id']
    | Form['id']
    | TonkeanId<TonkeanType.FIELD_DEFINITION>
    | TonkeanId<TonkeanType.CONTRACT_FIELD>
    | 'DELETE_ITEM'
    | 'CLONE_ITEM'
    | FieldDefinitionInterfaceId;

interface Props {
    initiativeId: Initiative['id'] | undefined;
    displayName: string;
    actionId: ItemGridMenuActionIds;
    actionType: InterfaceCTAActionType;
    projectId: string | undefined;
    workflowVersionType: WorkflowVersionType;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    deleteCallback: any;
}

const ItemsGridInlineMenuItem: React.FC<Props> = ({ initiativeId, ...props }) => {
    return <ItemWidgetsInlineActionMenuItem initiativeId={initiativeId} {...props} />;
};

export default ItemsGridInlineMenuItem;
