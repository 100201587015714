const alphabeticallySort = (wordA: string, wordB: string) => {
    const loweredWordA = wordA.toLowerCase();
    const loweredWordB = wordB.toLowerCase();

    if (loweredWordA < loweredWordB) {
        return -1;
    }
    if (loweredWordA > loweredWordB) {
        return 1;
    }
    return 0;
};

export default alphabeticallySort;
