<div class="google-drive-selector">
    <!-- Saving to... -->
    <div class="text-center padding-top-xlg padding-bottom-xlg" ng-if="data.saving">
        <h4>
            <span class="loading"></span>
            Saving...
        </h4>
    </div>

    <form name="data.integrationForm" ng-hide="data.saving" novalidate ng-submit="onFolderSelected(data.currentFolder)">
        <div class="modal-body">
            <!-- Error -->
            <div class="alert alert-danger" ng-show="data.error">{{ data.error | error }}</div>

            <!-- Authenticate -->
            <div ng-if="(!data.integration && !data.connecting) || data.isEditingPermissions || data.isMock">
                <tnk-google-authentication
                    google-integration-type="data.integrationType"
                    authenticate="authorize"
                    initial-oauth-permission-type="data.integrationPermissions"
                    initial-google-authentication-type="data.authenticationType"
                ></tnk-google-authentication>

                <div ng-if="data.isEditingPermissions">
                    <div class="common-color-light-grey">
                        To change the permissions of this integration, first select the desired option and then
                        Authenticate with Google Drive again
                    </div>
                    <div class="flex-vmiddle mod-justify-end">
                        <a ng-click="setEditingPermissions(false)">&lt; Back</a>
                    </div>
                </div>
            </div>

            <!-- Connecting to... -->
            <h4 class="flex-vmiddle mod-justify-center" ng-if="data.connecting">
                <span class="loading"></span>
                <span class="margin-left-xs">Connecting to Google Drive</span>
            </h4>

            <div ng-if="data.integration && !data.connecting && !data.isEditingPermissions && !data.isMock">
                <div class="alert alert-info flex-vmiddle" ng-if="!data.isSharedCredential">
                    <a
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Switch account
                    </a>
                </div>

                <div ng-if="data.configStep === 'selectDomain'">
                    <button
                        class="btn btn-default btn-lg block common-width-100 margin-bottom"
                        ng-click="moveToSelectFolder()"
                        data-automation="google-drive-selector-template-my-drive-button"
                    >
                        My Drive
                    </button>

                    <button class="btn btn-default btn-lg block common-width-100" ng-click="moveToSelectFolder(true)" data-automation="google-drive-selector-template-my-share-with-me">
                        Shared with me
                    </button>
                </div>

                <div ng-if="data.configStep === 'selectFolder'">
                    <!-- Change Folder -->
                    <div ng-if="data.selectedFolder">
                        <b>Currently selected:</b>
                        <a
                            ng-href="{{ 'https://drive.google.com/open?id=' + data.selectedFolder.value }}"
                            target="_blank"
                        >
                            {{ data.selectedFolder ? data.selectedFolder.displayName : 'My Drive' }}
                        </a>
                    </div>

                    <!-- Change permissions-->
                    <div ng-if="data.authenticationType === 'PUBLIC'">
                        <span>Current Permissions:</span>
                        <span class="common-bold">
                            {{ data.integrationPermissions === 'readonly' ? 'Read only' : 'Read & Write' }}
                        </span>
                        <a ng-if="!data.isSharedCredential" ng-click="setEditingPermissions(true)">
                            Change Permissions
                        </a>
                    </div>

                    <div class="flex-vmiddle margin-bottom">
                        <b>Select a Folder:</b>
                        <a ng-click="goToPreviousFolder()" ng-if="data.currentFolder" class="margin-left-xs">Back</a>

                        <div class="flex-grow"></div>

                        <div class="flex-no-shrink">
                            <label class="sr-only" for="folderSearch">Search</label>
                            <input
                                type="search"
                                class="form-control"
                                data-automation="google-drive-selector-template-search"
                                id="folderSearch"
                                placeholder="Search"
                                ng-change="onSearchChanged(data.folderSearch)"
                                ng-model="data.folderSearch"
                            />
                        </div>
                    </div>

                    <!-- Header that shows current folder name -->
                    <div class="integration-instance-selection-box-header common-bold">
                        {{ data.currentFolder ? data.currentFolder.displayName : 'My Drive' }}
                    </div>

                    <!-- Folders selection -->
                    <div class="integration-instance-selection-box">
                        <!-- Loading -->
                        <div ng-if="data.foldersLoading" class="text-center margin-top-lg">
                            <i class="loading-medium"></i>
                        </div>

                        <!-- Folders -->
                        <div ng-if="!data.foldersLoading" class="common-height-full">
                            <div ng-if="data.folders && data.folders.length">
                                <div
                                    ng-click="onOpenClicked(selection)"
                                    ng-repeat="selection in data.folders"
                                    class="list-item btn-default pointer common-size-xxs flex-vmiddle"
                                    data-automation="google-drive-selector-template-folder"
                                >
                                    <div class="flex-grow">
                                        <strong>{{ selection.displayName }}</strong>
                                        <div class="common-size-xxxxs common-color-grey">
                                            <span>{{ selection.description }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- No inner folders -->
                            <div
                                class="flex-vmiddle mod-justify-center common-height-full"
                                ng-if="!data.folders || !data.folders.length"
                            >
                                <div class="text-center">
                                    <!-- Message -->
                                    <div class="common-color-grey common-size-xxxs">
                                        This folder has no inner folders
                                    </div>
                                    <!-- Use this folder -->
                                    <button
                                        class="btn btn-sm btn-primary margin-top-xs"
                                        ng-if="data.currentFolder.value"
                                        analytics-on
                                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                                        analytics-event="Save Integration"
                                        analytics-label="{{ currentIntegration.name }}"
                                        analytics-value="{{
                                            (data.selections | filter: { selected: true }:true).length
                                        }}"
                                        type="submit"
                                    >
                                        Use this Folder
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-vmiddle">
            <!-- Error -->
            <div class="common-color-danger" ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid">
                You must choose a title and unique identifier
            </div>

            <!-- Placeholder -->
            <div class="flex-grow"></div>

            <!-- Cancel button -->
            <button
                class="btn btn-default margin-right-xs flex-no-shrink"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (data.selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="cancel()"
            >
                Cancel
            </button>

            <!-- OK button -->
            <button
                class="btn btn-primary flex-no-shrink data-source-use-folder-button common-ellipsis"
                data-automation="google-drive-selector-template-submit-folder"
                ng-if="data.integration && data.configStep === 'selectFolder' && data.currentFolder.value"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (data.selections | filter: { selected: true }:true).length }}"
                uib-tooltip="{{ data.currentFolder ? data.currentFolder.displayName : 'My Drive' }}"
                tooltip-enable="utils.hasEllipsis('.google-drive-selector .data-source-use-folder-button')"
                type="submit"
            >
                Use this Folder ({{ data.currentFolder ? data.currentFolder.displayName : 'My Drive' }})
            </button>
        </div>
    </form>
</div>
