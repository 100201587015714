import React, { useMemo } from 'react';
import * as Yup from 'yup';

import type { MarketplaceItemCreateSection } from '../components/MarketplaceItemCreateModalSection';
import MarketplaceItemCreateModalOverviewSection from '../components/sections/MarketplaceItemCreateModalOverviewSection';
import MarketplaceItemCreateModalVersionSection from '../components/sections/MarketplaceItemCreateModalVersionSection';
import MarketplaceItemUsage from '../components/sections/MarketplaceItemUsage';
import SolutionPublisherToggle from '../components/sections/solution/SolutionPublisherToggle/SolutionPublisherToggle';

import { MarketplaceItemType } from '@tonkean/tonkean-entities';

const MarketplaceItemCreateSectionOverviewSchema = {
    templateName: Yup.string()
        .required('Must fill a template name')
        .max(150, 'Template name must be at most 150 characters'),
    title: Yup.string().required('Must fill a title').max(250, 'Title must be at most 250 characters'),
    previewSummary: Yup.string().max(300, 'Summary text must be at most 250 characters'),
};

const MarketplaceItemCreateSectionVersionSchema = {
    version: Yup.string()
        .required('Must fill a version number')
        .max(150, 'Version number must be at most 150 characters'),
};

const MarketplaceItemUsageSchema = {
    isViewOnly: Yup.boolean().default(false),
    isHidden: Yup.boolean().default(false),
};

const useGetMarketplaceItemFinalSections = (
    isMarketplaceBlueprint: boolean,
    marketplaceItemType: MarketplaceItemType,
    additionalSections?: MarketplaceItemCreateSection[],
) => {
    const finalSections: MarketplaceItemCreateSection[] = useMemo(() => {
        const generalSections: MarketplaceItemCreateSection[] = [
            {
                title: 'Overview',
                contentComponent: (
                    <MarketplaceItemCreateModalOverviewSection
                        entityName={isMarketplaceBlueprint ? 'blueprint' : 'template'}
                    />
                ),
                validationSchema: MarketplaceItemCreateSectionOverviewSchema,
            },
            {
                title: 'Version',
                contentComponent: (
                    <MarketplaceItemCreateModalVersionSection
                        entityName={isMarketplaceBlueprint ? 'blueprint' : 'template'}
                    />
                ),
                validationSchema: MarketplaceItemCreateSectionVersionSchema,
            },
        ];

        if (marketplaceItemType === MarketplaceItemType.SOLUTION) {
            return [
                ...generalSections,
                ...(additionalSections || []),
                {
                    title: 'Installation configuration',
                    contentComponent: <SolutionPublisherToggle />,
                    validationSchema: {
                        shouldBePublished: Yup.boolean(),
                        includeInitiatives: Yup.boolean(),
                        includeExternalActivities: Yup.boolean(),
                    },
                },
            ];
        }

        const marketplaceItemUsageSection = {
            title: 'Template Usage',
            contentComponent: <MarketplaceItemUsage />,
            validationSchema: MarketplaceItemUsageSchema,
        };

        return [...generalSections, marketplaceItemUsageSection, ...(additionalSections || [])];
    }, [additionalSections, isMarketplaceBlueprint, marketplaceItemType]);

    return finalSections;
};

export default useGetMarketplaceItemFinalSections;
