<input
    type="text"
    id="search-auto-complete-input"
    ng-model="searchTerm"
    placeholder="Type to search {{ externalTypeDisplayName || externalType }}..."
    uib-typeahead="item as getDisplayText(item) for item in search($viewValue)"
    ng-blur="validateSelection()"
    typeahead-loading="searching"
    typeahead-no-results="noResults"
    typeahead-on-select="onSelect($item, $model, $label, $event)"
    class="form-control"
    autocomplete="off"
/>
