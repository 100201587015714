import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { SimpleSelect } from '@tonkean/infrastructure';

interface Props {
    groupId: string;
    environment: string;
    sequentialIdentifier?: string;
    value?: string;
    onChange(value?: string): void;
}

const TriggersSelector: React.FC<Props> = ({ groupId, environment, sequentialIdentifier, value, onChange }) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const draft = workflowVersionManager.getDraftVersionFromCache(groupId);
    const published = workflowVersionManager.getPublishedVersionFromCache(groupId);
    const defaultWorkflowVersionId = environment === 'build' ? draft?.id : published?.id;
    const workflowVersionIds = sequentialIdentifier ? undefined : defaultWorkflowVersionId;

    const { loading, data } = useTonkeanService(
        'getCustomTriggerSummaryByWorkflowVersion',
        groupId,
        workflowVersionIds,
        sequentialIdentifier,
    );

    const options = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.map((summary) => ({
            value: summary.id,
            label: summary.displayName,
        }));
    }, [data]);

    return (
        <SimpleSelect
            isCreatable={false}
            options={options}
            isLoading={loading}
            getOptionValue={(option) => option.value}
            value={value}
            onChange={onChange}
            isClearable
            thin
        />
    );
};

export default TriggersSelector;
