import { useAngularService } from 'angulareact';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';

import FormBuilderTabLabel from '../entities/FormBuilderTabLabel';
import getInitialForm from '../utils/getInitialForm';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { defaultFormColors } from '@tonkean/infrastructure';
import type { Form, FormType } from '@tonkean/tonkean-entities';

interface Params {
    formId?: string;
    workflowVersionId: string;
    formType: FormType;
    setForm: Dispatch<SetStateAction<Form | undefined>>;
    setSelectedTab: Dispatch<SetStateAction<FormBuilderTabLabel | undefined>>;
    angularEditorFetchFormTrigger?: () => void;
}

function useGetWorkerForm({
    formId,
    workflowVersionId,
    setForm,
    formType,
    angularEditorFetchFormTrigger,
    setSelectedTab,
}: Params) {
    const formManager = useAngularService('formManager');
    const projectManager = useAngularService('projectManager');

    const [{ data, error, loading }, getWorkerForm] = useLazyAsyncMethod(formManager, 'getWorkerForm');

    /* Get form on initial render */
    useEffect(() => {
        if (formId && workflowVersionId) {
            getWorkerForm(workflowVersionId, formId);
        } else {
            setForm(
                getInitialForm(
                    formId,
                    formType,
                    formManager,
                    workflowVersionId,
                    projectManager.project.themeConfiguration,
                ),
            );
        }
    }, [
        formId,
        formManager,
        formType,
        getWorkerForm,
        projectManager.project.themeConfiguration,
        setForm,
        workflowVersionId,
    ]);

    /* Store form from getWorkerForm in state */
    useEffect(() => {
        if (data) {
            // Some forms are missing color values
            data.definition.primaryColor = data.definition.primaryColor || defaultFormColors.primaryColor;
            data.definition.secondaryColor = data.definition.secondaryColor || defaultFormColors.secondaryColor;

            setForm(data);
            if (data.formQuestionType === 'UPLOAD_FILES') {
                setSelectedTab(FormBuilderTabLabel.SETTINGS);
            }

            angularEditorFetchFormTrigger?.();
        }
    }, [angularEditorFetchFormTrigger, data, setForm, setSelectedTab]);

    return {
        getWorkerForm,
        getWorkerFormLoading: loading,
        getWorkerFormError: error,
    };
}

export default useGetWorkerForm;
