<div ng-switch="currentModal.settings.integrationType" ng-init="init(currentModal.settings)">
    <div ng-switch-when="github">
        <div ng-include="'./gitService/modal.template.html'"></div>
    </div>
    <div ng-switch-when="git">
        <div ng-include="'./gitLocal/modal.template.html'"></div>
    </div>
    <div ng-switch-when="jira">
        <div ng-include="'./jira/modal.template.html'"></div>
    </div>
    <div ng-switch-when="sql">
        <div ng-include="'./mysql/modal.template.html'"></div>
    </div>
    <div ng-switch-when="trello">
        <div ng-include="'./trello/modal.template.html'"></div>
    </div>
    <div ng-switch-when="asana">
        <div ng-include="'./asana/modal.template.html'"></div>
    </div>
    <div ng-switch-when="wunderlist">
        <div ng-include="'./wunderlist/modal.template.html'"></div>
    </div>
    <div ng-switch-when="salesforce">
        <div ng-include="'./salesforce/modal.template.html'"></div>
    </div>
    <div ng-switch-when="zendesk">
        <div ng-include="'./zendesk/modal.template.html'"></div>
    </div>
    <div ng-switch-when="smartsheet">
        <div ng-include="'./smartsheet/modal.template.html'"></div>
    </div>
    <div ng-switch-when="nutshell">
        <div ng-include="'./nutshell/modal.template.html'"></div>
    </div>
    <div ng-switch-when="pipedrive">
        <div ng-include="'./pipedrive/modal.template.html'"></div>
    </div>
    <div ng-switch-when="monday">
        <div ng-include="'./monday/modal.template.html'"></div>
    </div>
    <div ng-switch-when="twiliochat">
        <div ng-include="'./twiliochat/modal.template.html'"></div>
    </div>
    <div ng-switch-when="agilecrm">
        <div ng-include="'./agileCrm/modal.template.html'"></div>
    </div>
    <div ng-switch-when="facebookads">
        <div ng-include="'./facebookads/modal.template.html'"></div>
    </div>
    <div ng-switch-when="salesforceiq">
        <div ng-include="'./salesforceiq/modal.template.html'"></div>
    </div>
    <div ng-switch-when="googlesheets">
        <div ng-include="'./googlesheets/modal.template.html'"></div>
    </div>
    <div ng-switch-when="googledrive">
        <div ng-include="'./googledrive/modal.template.html'"></div>
    </div>
    <div ng-switch-when="dropbox">
        <div ng-include="'./dropbox/modal.template.html'"></div>
    </div>
    <div ng-switch-when="tableau">
        <div ng-include="'./tableau/modal.template.html'"></div>
    </div>
    <div ng-switch-when="teamwork">
        <div ng-include="'./teamwork/modal.template.html'"></div>
    </div>
    <div ng-switch-when="prosperworks">
        <div ng-include="'./prosperworks/modal.template.html'"></div>
    </div>
    <div ng-switch-when="google_calendar">
        <div ng-include="'./googlecalendar/modal.template.html'"></div>
    </div>
    <div ng-switch-when="gmail">
        <div ng-include="'./gmail/modal.template.html'"></div>
    </div>
    <div ng-switch-when="gitlab">
        <div ng-include="'./gitlab/modal.template.html'"></div>
    </div>
    <div ng-switch-when="excel365">
        <div ng-include="'./excel365/modal.template.html'"></div>
    </div>
    <div ng-switch-when="dynamics365">
        <div ng-include="'./dynamics365/modal.template.html'"></div>
    </div>
    <div ng-switch-when="basecamp">
        <div ng-include="'./basecamp/modal.template.html'"></div>
    </div>
    <div ng-switch-when="kanbanize">
        <div ng-include="'./kanbanize/modal.template.html'"></div>
    </div>
    <div ng-switch-when="rss">
        <div ng-include="'./rss/modal.template.html'"></div>
    </div>
    <div ng-switch-when="sendgrid">
        <div ng-include="'./sendgrid/modal.template.html'"></div>
    </div>
    <div ng-switch-when="freshdesk">
        <div ng-include="'./freshdesk/modal.template.html'"></div>
    </div>
    <div ng-switch-when="slack_app">
        <div ng-include="'./slackApp/modal.template.html'"></div>
    </div>
    <div ng-switch-when="zuora">
        <div ng-include="'./zuora/modal.template.html'"></div>
    </div>
    <div ng-switch-when="googlecloud">
        <div ng-include="'./googleCloud/modal.template.html'"></div>
    </div>
    <div ng-switch-when="greenhouse">
        <div ng-include="'./greenhouse/modal.template.html'"></div>
    </div>
    <div ng-switch-when="namely">
        <div ng-include="'./namely/modal.template.html'"></div>
    </div>
    <div ng-switch-when="okta">
        <div ng-include="'./okta/modal.template.html'"></div>
    </div>
    <div ng-switch-when="frontapp">
        <div ng-include="'./frontapp/modal.template.html'"></div>
    </div>
    <div ng-switch-when="emailwebhook">
        <div ng-include="'./emailwebhook/modal.template.html'"></div>
    </div>
    <div ng-switch-when="webhook">
        <div ng-include="'./webhook/modal.template.html'"></div>
    </div>
    <div ng-switch-when="microsoft_teams">
        <div ng-include="'./microsoftTeams/modal.template.html'"></div>
    </div>
    <div ng-switch-when="googlechat">
        <div ng-include="'./googlechat/modal.template.html'"></div>
    </div>
    <div ng-switch-when="mavenlink">
        <div ng-include="'./mavenlink/modal.template.html'"></div>
    </div>
    <div ng-switch-when="teamconnect">
        <div ng-include="'./teamconnect/modal.template.html'"></div>
    </div>
    <div ng-switch-default>
        <div ng-include="'./genericIntegration/genericIntegrationModal.template.html'"></div>
    </div>
</div>
