import { useMemo } from 'react';

import type ThankYouPageData from '../../../../entities/ThankYouPageData';

import { useEnvironmentAppUrl } from '@tonkean/shared-services';
import { getFullLinkUrl } from '@tonkean/utils';

const useSetThankYouPageData = (
    firstActionDefinition,
    itemInterfaceSubmissionResponse,
    initiativeTitle,
): ThankYouPageData => {
    const appUrl = useEnvironmentAppUrl();

    return useMemo(() => {
        const hasItemInterfaceLink =
            firstActionDefinition?.itemInterfaceId &&
            (firstActionDefinition?.selectedActionType === 'open-custom-interface' ||
                firstActionDefinition?.selectedActionType === 'continue-flow');

        return {
            evaluatedCustomInterfaceLabel: hasItemInterfaceLink
                ? itemInterfaceSubmissionResponse?.evaluatedCustomInterfaceLabel || initiativeTitle
                : itemInterfaceSubmissionResponse?.evaluatedCustomInterfaceLabel ||
                  itemInterfaceSubmissionResponse?.evaluatedUrlLink,
            evaluatedCustomResponseMessage: itemInterfaceSubmissionResponse?.evaluatedCustomResponseMessage,
            evaluatedWebformResponseMessage: itemInterfaceSubmissionResponse?.evaluatedWebformResponseMessage,
            evaluatedWebformResponseMessageSubtitle:
                itemInterfaceSubmissionResponse?.evaluatedWebformResponseMessageSubtitle,
            redirectToUrl:
                firstActionDefinition?.selectedActionType === 'open-link' ||
                firstActionDefinition?.selectedActionType === 'open-custom-interface',
            evaluatedUrlLink: getFullLinkUrl(itemInterfaceSubmissionResponse?.evaluatedUrlLink, appUrl),
            redirectDelay: firstActionDefinition?.redirectDelay,
            redirectToNewTab:
                firstActionDefinition?.redirectToNewTab || firstActionDefinition?.redirectToNewTab === undefined,
            evaluatedUrlLinkText: itemInterfaceSubmissionResponse?.evaluatedCustomInterfaceLabel,
            isCustomInterfaceLink: !!hasItemInterfaceLink,
        };
    }, [
        firstActionDefinition?.itemInterfaceId,
        firstActionDefinition?.selectedActionType,
        firstActionDefinition?.redirectDelay,
        firstActionDefinition?.redirectToNewTab,
        itemInterfaceSubmissionResponse?.evaluatedCustomInterfaceLabel,
        itemInterfaceSubmissionResponse?.evaluatedUrlLink,
        itemInterfaceSubmissionResponse?.evaluatedCustomResponseMessage,
        itemInterfaceSubmissionResponse?.evaluatedWebformResponseMessage,
        itemInterfaceSubmissionResponse?.evaluatedWebformResponseMessageSubtitle,
        initiativeTitle,
        appUrl,
    ]);
};

export default useSetThankYouPageData;
