import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import { SimpleSelect } from '@tonkean/infrastructure';
import { range } from '@tonkean/utils';

interface Props {
    groupId: string;
    value?: string;
    onChange(value?: string): void;
}

const WorkflowVersionSelector: React.FC<Props> = ({ groupId, value, onChange }) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const workflowVersionOptions = useMemo(() => {
        const published = workflowVersionManager.getPublishedVersionFromCache(groupId);
        if (!published) {
            throw new Error(`No published workflow version in cache for group id ${groupId}`);
        }

        // Range max is exclusive so it won't include the sequential identifier of the published version
        const otherSequentialIdentifiers = range(1, published.sequentialIdentifier || 1)
            .map((sequentialIdentifier) => ({
                label: `v${sequentialIdentifier}`,
                value: sequentialIdentifier.toString(),
            }))
            .reverse();

        const publishedVersionOption = published.sequentialIdentifier > 0 && {
            label: `v${published.sequentialIdentifier} (Production)`,
            value: published.sequentialIdentifier.toString(),
        };
        const publishedVersionOptionArray = publishedVersionOption ? [publishedVersionOption] : [];

        return [...publishedVersionOptionArray, ...otherSequentialIdentifiers];
    }, [groupId, workflowVersionManager]);

    return <SimpleSelect value={value} onChange={onChange} options={workflowVersionOptions} isClearable thin />;
};

export default WorkflowVersionSelector;
