<div class="tnk-avatars-bar flex">
    <div
        ng-repeat="avatar in data.avatars | limitTo:data.avatarsDesktopLimit track by $index"
        class="avatar-popover-container mod-bottom mod-align-center popover-black-bg-container"
        ng-class="{ 'popover-link-bg-container': avatar.isLink }"
    >
        <!-- Person avatar -->
        <div
            class="avatar-tooltip-wrapper"
            uib-popover-template="'./templates/summaryHoverTemplate.template.html'"
            popover-placement="bottom-center"
            popover-trigger="mouseenter"
            popover-is-open="popover.isOpen[$index]"
            ng-class="{
                'margin-left-xs-minus': !data.separatedAvatars,
                'margin-left-xxs': data.separatedAvatars
            }"
        >
            <tnk-avatar
                user="avatar"
                default-on-empty="true"
                opacity="avatar.opacity"
                class="image-avatar common-size-xxxs cursor-default common-z-index-1"
                mod="generateRandomPaletteColor($index)"
                size="data.size"
                hide-default-avatar-photo="true"
                ng-class="{
                    '{{data.size}}': true
                }"
            ></tnk-avatar>
        </div>
    </div>

    <!-- +x more -->
    <div
        class="avatar-more-users-container common-z-index-2"
        ng-mouseleave="popover.isOpen['more'] = false"
        ng-mouseenter="popover.isOpen['more'] = true"
    >
        <div
            class="image-avatar mod-more-circle common-size-xxxxs margin-left-xs-minus cursor-default"
            ng-class="{
                'margin-left-xs-minus': !data.separatedAvatars,
                'margin-left-xxs': data.separatedAvatars,
                '{{data.size}}': true
            }"
            ng-if="data.avatars.length - data.avatarsDesktopLimit > 0"
            uib-popover-template="'./templates/moreAvatarsSummaryTemplate.template.html'"
            popover-trigger="none"
            popover-is-open="popover.isOpen['more']"
            popover-append-to-body="true"
            popover-placement="bottom-center"
            popover-class="avatar-more-users-popover"
        >
            +{{ data.avatars.length - data.avatarsDesktopLimit }}
        </div>
    </div>
</div>
