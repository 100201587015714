import type CreateAfterOptionCanCreate from './CreateAfterOptionCanCreate';
import type TriggerParamsEnricher from './TriggerParamsEnricher';

import type { ItemDetailsIconType } from '@tonkean/tonkean-entities';

export enum RunOnTypes {
    INNER_ITEM = 'INNER_ITEM',
    NEW_REQUEST = 'NEW_REQUEST',
}

export default interface CreateAfterOption {
    label: string;
    runOnType?: RunOnTypes;
    blockDisplayName?: string;
    triggerParamsEnricher?: TriggerParamsEnricher;
    canCreate?: CreateAfterOptionCanCreate;
    iconType?: ItemDetailsIconType;
}
