<div class="secondary-type-side-pane">
    <div ng-if="data.customTrigger.secondaryIconClass">
        <!-- Trigger type title -->
        <div class="autonomous-type-box flex flex-vmiddle">
            <!-- badge icon -->
            <div
                ng-switch="data.customTrigger.customTriggerSecondaryType"
                class="left-badge common-color-white"
                ng-class="data.customTrigger.secondaryIconClass"
            >
                <div ng-switch-when="AUTONOMOUS_ITEM_CREATED" class="fa fa-flag fa-lg"></div>
                <div ng-switch-when="MONITOR_TRACKS_ITEM_CREATED" class="fa fa-flag fa-lg"></div>
                <div ng-switch-when="AUTONOMOUS_SCHEDULE" class="fa fa-clock-o fa-lg"></div>
                <div ng-switch-when="AUTONOMOUS_FIELD_CHANGED" class="fa fa-eye fa-lg"></div>
                <div ng-switch-when="AUTONOMOUS_CREATED_FROM_FORM" class="icon-form-svg"></div>
                <div ng-switch-when="AUTONOMOUS_INTERFACE_SUBMITTED" class="icon-interface-submitted-svg"></div>
                <div ng-switch-when="AUTONOMOUS_MATCH_CONDITIONS" class="icon-match-condition-svg"></div>
                <div ng-switch-when="MONITOR_TRACKS_MATCH_CONDITIONS" class="icon-match-condition-svg"></div>
                <div ng-switch-when="USER_INTERFACE_BUTTON_CLICK" class="icon-button-click-svg"></div>
                <div ng-switch-when="AUTO_CLOSE" class="icon-auto-close-svg"></div>
            </div>
            <div class="common-color-grey3 margin-left-lg">
                {{ data.secondaryTypeToLogicConfigurationMapping[data.customTrigger.customTriggerSecondaryType].title }}
            </div>
            <div class="margin-right-lg margin-top-xs-minus float-right">
                <!-- Autonomous types dropdown -->
                <div
                    ng-if="data.customTrigger.customTriggerSecondaryType !== 'AUTO_CLOSE'"
                >
                    <tnk-pencil-button-autonomous-types-dropdown
                            on-autonomous-type-selected="(changeAutonomousType)"
                            is-monitor-track="data.isMonitorTrack"
                            is-inner-autonomous="data.isInnerAutonomous"
                    />
                </div>
            </div>
        </div>

        <!-- trigger type description -->
        <div class="margin-top flex">
            <i class="fa fa-info common-color-grey margin-left-xs margin-right-xs margin-top-xxs"></i>
            <div
                ng-if="
                    data.secondaryTypeToLogicConfigurationMapping[data.customTrigger.customTriggerSecondaryType]
                        .longDescription
                "
                class="common-color-grey margin-left common-size-xs"
            >
                {{
                    data.secondaryTypeToLogicConfigurationMapping[data.customTrigger.customTriggerSecondaryType]
                        .longDescription
                }}
            </div>
            <tnk-long-description-selector
                ng-if="
                    data.secondaryTypeToLogicConfigurationMapping[data.customTrigger.customTriggerSecondaryType]
                        .longDescriptionComponent
                "
                custom-trigger-type="data.customTrigger.customTriggerType"
                custom-trigger-secondary-type="data.customTrigger.customTriggerSecondaryType"
            ></tnk-long-description-selector>
        </div>
    </div>

    <i ng-if="!data.customTrigger.secondaryIconClass" class="loading-medium"></i>
</div>
