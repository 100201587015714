import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Table } from '@tonkean/infrastructure';
import type { ProjectIntegrationActionTestRun } from '@tonkean/tonkean-entities';
import { ProjectIntegrationActionDefinitionType } from '@tonkean/tonkean-entities';

const TableWrapper = styled.div`
    padding: 0 0 16px 0;
    overflow: auto;
    flex-grow: 1;
    height: 100%;
`;

const TableRow = styled.tr`
    word-break: break-all;
`;

interface Props {
    testRun: ProjectIntegrationActionTestRun;
    isResponse: boolean;
}

const ProjectIntegrationActionHeadersLayout: React.FC<Props> = ({ testRun, isResponse }) => {
    // header list for table component.
    const headers: { key: string; value: any }[] = useMemo(() => {
        const latestRequestExecutionResult = testRun.executionResultList.slice(-1)[0];

        if (
            !latestRequestExecutionResult ||
            latestRequestExecutionResult.type === ProjectIntegrationActionDefinitionType.SQL
        ) {
            // We don't support sql action type.
            return [];
        } else {
            return Object.entries(
                isResponse
                    ? JSON.parse(latestRequestExecutionResult?.response?.responseHeadersString || '{}')
                    : latestRequestExecutionResult.request.headers || {},
            ).map(([key, value]) => ({
                key,
                value,
            }));
        }
    }, [isResponse, testRun.executionResultList]);

    // Columns header configuration.
    const columns = useMemo(
        () => [
            {
                Header: 'Key',
                accessor: 'key' as const,
                width: '50%',
            },
            {
                Header: 'Value',
                accessor: 'value' as const,
                width: '50%',
            },
        ],
        [],
    );

    return (
        <TableWrapper>
            <Table tableRowComponent={TableRow} columns={columns} data={headers} border />
        </TableWrapper>
    );
};

export default ProjectIntegrationActionHeadersLayout;
