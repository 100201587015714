import GroupSupportUserPermissions from './GroupSupportUserPermissions';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkGroupSupportUserPermissions',
        reactToAngular(GroupSupportUserPermissions, ['groupType', 'onToggle']),
    );
