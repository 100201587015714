import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as WarningIconSvg } from '../../../../../../images/icons/history/warning.svg';
import type HistoryField from '../../../entities/HistoryField';
import type { TabComponentProps } from '../../../entities/WorkerRunLogicInspectTabData';
import getHistoryInitiativeFields from '../../../utils/getHistoryInitiativeFields';
import getInitiativeTableFieldsWithChangeValues from '../../../utils/getInitiativeTableFieldsWithChangeValues';
import HistoryFieldsTable from '../../HistoryFieldsTable/HistoryFieldsTable';
import HistoryFieldsTableFilters, {
    HistoryFieldsTableFiltersCheckbox,
    HistoryFieldsTableFiltersHeader,
    HistoryFieldsTableFiltersSearchBox,
} from '../../HistoryFieldsTable/HistoryFieldsTableFilters';
import LogicType from '../LogicType';

import { useTonkeanService, useAsyncMethod } from '@tonkean/angular-hooks';
import type { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';

const Table = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FromActionFilter = styled.div`
    display: flex;
    align-items: center;
`;

const WarningIcon = styled.div`
    display: inline-flex;
    margin-left: 4px;

    .tnk-icon {
        display: inline-flex;
    }
`;

const SingleWorkerRunActionInspectFields: React.FC<TabComponentProps<WorkerRunLogicInspectTabType.FIELDS>> = ({
    workerRunLogic,
    logic,
    logicType,
    initialInitiativeFieldsState,
    finalInitiativeFieldsState,
}) => {
    const tonkeanUtils = useAngularService('tonkeanUtils');
    const customFieldsManager = useAngularService('customFieldsManager');
    const customTriggerManager = useAngularService('customTriggerManager');

    const workflowVersionId = logic.node.workflowVersion.id;
    const logicId = logic.node.id;

    const {
        loading: relatedFieldDefinitionsIdsLoading,
        error: relatedFieldDefinitionsIdsError,
        data: fieldDefinitionsIds,
    } = useTonkeanService('getRelatedFieldDefinitionIds', workflowVersionId, logicId);

    const {
        data: allFieldsDefinitions,
        loading: loadingFieldDefinitions,
        error: errorFieldDefinitions,
    } = useAsyncMethod(customFieldsManager, 'getFieldDefinitions', workflowVersionId, false);

    const [freeTextFilter, setFreeTextFilter] = useState('');
    const [filterOnlyValueChanged, setFilterOnlyValueChanged] = useState(false);
    const [filterOnlyEmptyValue, setFilterOnlyEmptyValue] = useState(false);
    const [filterInvolvedInAction, setFilterInvolvedInAction] = useState(false);

    // TODO: Remove filter for v2
    const relatedFieldsIds = useMemo(() => {
        if (!allFieldsDefinitions) {
            return;
        }

        return fieldDefinitionsIds?.fieldDefinitionIds?.filter(
            (id) => allFieldsDefinitions?.find((field) => field.id === id)?.fieldDefinitionTargetType !== 'GLOBAL',
        );
    }, [allFieldsDefinitions, fieldDefinitionsIds]);

    const tableLoading = filterInvolvedInAction && loadingFieldDefinitions && relatedFieldDefinitionsIdsLoading;
    const tableInError = filterInvolvedInAction && errorFieldDefinitions && relatedFieldDefinitionsIdsError;
    const isTrigger = logicType === LogicType.TRIGGER;

    const syncConfig = logic.node.workflowVersion.syncConfig;
    const tableFields: HistoryField[] = useMemo(() => {
        if (!workerRunLogic.initiativeId || !allFieldsDefinitions) {
            return [];
        }

        const fields = getHistoryInitiativeFields(allFieldsDefinitions || [], syncConfig, tonkeanUtils);
        return getInitiativeTableFieldsWithChangeValues(
            fields,
            initialInitiativeFieldsState,
            finalInitiativeFieldsState,
            !isTrigger,
            workflowVersionId,
            customTriggerManager,
            logicType,
        ).filter((field) => !field.isGlobalField);
    }, [
        allFieldsDefinitions,
        customTriggerManager,
        finalInitiativeFieldsState,
        initialInitiativeFieldsState,
        isTrigger,
        logicType,
        syncConfig,
        tonkeanUtils,
        workerRunLogic.initiativeId,
        workflowVersionId,
    ]);

    const { fieldsCount, emptyFieldsCount, changedFieldsCount } = useMemo(() => {
        return {
            fieldsCount: tableFields.length,
            emptyFieldsCount: tableFields.filter((field) => field.value.isEmpty).length,
            changedFieldsCount: tableFields.filter((field) => field.value.hasChanged).length,
        };
    }, [tableFields]);

    return (
        <>
            <Table>
                <HistoryFieldsTableFilters>
                    <HistoryFieldsTableFiltersHeader>
                        Filter Module Fields ({fieldsCount}):
                    </HistoryFieldsTableFiltersHeader>

                    <HistoryFieldsTableFiltersCheckbox
                        checked={filterInvolvedInAction}
                        onChange={(e) => setFilterInvolvedInAction(e.target.checked)}
                        dataAutomation="single-worker-run-action-inspect-fields-involved-in-action-checkbox"
                    >
                        <FromActionFilter>
                            Involved In
                            {isTrigger ? ' Trigger' : ' Action'}
                            {relatedFieldsIds && ` (${relatedFieldsIds?.length})`}
                            {relatedFieldDefinitionsIdsError && (
                                <WarningIcon>
                                    <span className="tnk-icon">
                                        <WarningIconSvg />
                                    </span>
                                </WarningIcon>
                            )}
                        </FromActionFilter>
                    </HistoryFieldsTableFiltersCheckbox>

                    <HistoryFieldsTableFiltersCheckbox
                        checked={filterOnlyEmptyValue}
                        onChange={(e) => setFilterOnlyEmptyValue(e.target.checked)}
                        dataAutomation="single-worker-run-action-inspect-fields-empty-value-checkbox"
                    >
                        Empty Values ({emptyFieldsCount})
                    </HistoryFieldsTableFiltersCheckbox>

                    {!isTrigger && (
                        <HistoryFieldsTableFiltersCheckbox
                            checked={filterOnlyValueChanged}
                            onChange={(e) => setFilterOnlyValueChanged(e.target.checked)}
                            dataAutomation="single-worker-run-action-inspect-fields-value-changed-checkbox"
                        >
                            Value Changed ({changedFieldsCount})
                        </HistoryFieldsTableFiltersCheckbox>
                    )}

                    <HistoryFieldsTableFiltersSearchBox
                        data-automation="single-worker-run-action-inspect-fields-search-box"
                        value={freeTextFilter}
                        onChange={(e) => setFreeTextFilter(e.target.value)}
                    />
                </HistoryFieldsTableFilters>

                <HistoryFieldsTable
                    couldHaveChanged={!isTrigger}
                    tableLoading={tableLoading}
                    tableInError={!!tableInError}
                    fields={tableFields}
                    relatedFieldsIds={relatedFieldsIds}
                    workflowVersionId={workflowVersionId}
                    syncConfig={syncConfig}
                    freeTextFilter={freeTextFilter}
                    filterOnlyEmptyValue={filterOnlyEmptyValue}
                    filterOnlyValueChanged={filterOnlyValueChanged}
                    filterInvolvedInAction={filterInvolvedInAction}
                />
            </Table>
        </>
    );
};

export default SingleWorkerRunActionInspectFields;
