import template from './formulaExpressionEditor.template.html?angularjs';

angular.module('tonkean.app').component('tnkFormulaExpressionEditor', {
    bindings: {
        targetType: '@', // Target type of defined formula.
        fieldDefinitionType: '@', // What type of field definition the formula is defined for.

        variableEntityName: '@', // What entity each of the variable is.
        groupId: '@', // The group we're defining the expression in.
        workflowVersionId: '@', // The workflow version we're defining the expression in.
        formulaExpression: '<', // The current formula expression being configured.
        evaluatedFormulaExpression: '<', // The current formula expression being configured.
        formulaExpressionTree: '<',
        exampleInitiative: '<',
        treeViewMod: '<',
        additionalTabs: '<',
        customTrigger: '<',
        projectIntegration: '<',

        aggregationOnColumnFeatureName: '@',
        columnFormulaFeatureName: '@',
        onUseBasic: '<',
        onFormulaExpressionChanged: '&', // Callback to be called once the formula expression is changed.
        onFormulaDataChange: '<', // Callback to be called once the formula data is changed.
        onInnerTrackAggregationSelected: '<',

        fieldDefinition: '<', // The field definition for which the formula is configured
    },
    template,
    controller: 'FormulaExpressionEditorCtrl',
});
