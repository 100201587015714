import template from './tnkPeopleAtMention.template.html?angularjs';

function tnkPeopleAtMention() {
    return {
        restrict: 'E',
        scope: {
            textId: '@',
            resultList: '=',
            noCustom: '=',
            maxTags: '@',
            noValidation: '=',
            excludeMe: '=',
            includeFunctions: '=',
            allowedPeople: '<', // An array with people who can be at mentioned (not allowed people will have a different ui). If null allow everyone.
            opened: '&',
            onTagAdded: '&',
        },
        template,
        controller: [
            '$scope',
            '$q',
            'tonkeanService',
            'utils',
            function ($scope, $q, tonkeanService, utils) {
                // Source: http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
                const emailRegex =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;

                $scope.selectedPeople = [];
                $scope.data = { visible: false };

                $scope.$watch('data.visible', function () {
                    const isOpen = $scope.data.visible;
                    if ($scope.opened) {
                        $scope.opened({ open: isOpen });
                    }
                });

                $scope.$root.pm.getFunctions();

                $scope.searchPeople = function (query) {
                    $scope.loadingPeople = true;
                    const limit = 3;
                    return tonkeanService
                        .getTeamMembers(
                            $scope.$root.pm.project.id,
                            { excludeMe: $scope.excludeMe, includeTemporary: $scope.$root.pm.allowTempUsers },
                            query,
                            limit,
                        )
                        .then(function (data) {
                            const people = data.people;
                            if ($scope.includeFunctions) {
                                const functions = $scope.$root.pm.functions.filter((f) =>
                                    f.name.toLowerCase().includes(query.toLowerCase()),
                                );
                                return handleResults(query, people, functions);
                            } else {
                                return handleResults(query, people);
                            }
                        })
                        .catch(function (error) {
                            return [{ error }];
                        })
                        .finally(function () {
                            $scope.loadingPeople = false;
                        });
                };

                function handleResults(query, people, functions) {
                    if (query.length) {
                        let alreadyExist = $scope.selectedPeople.some(function (p) {
                            return $scope.noValidation ? p.name === query : p.email === query;
                        });
                        if (!alreadyExist && people) {
                            alreadyExist = people.some(function (p) {
                                return $scope.noValidation ? p.name === query : p.email === query;
                            });
                            if (functions) {
                                alreadyExist |= functions.some(function (p) {
                                    return p.name === query;
                                });
                            }
                        }

                        if (!alreadyExist && !$scope.noCustom && $scope.$root.pm.allowTempUsers) {
                            people = people.slice(0, 2);
                            people.push({
                                customEmail: true,
                                name: query,
                                email: query,
                                title: '(new user)',
                                isValid: $scope.noValidation || emailRegex.test(query),
                            });
                        }
                    }
                    $scope.selectedPeople = functions ? functions.concat(people) : people;

                    // Mark all the people who are not in the allowedPeople array as notAllowed so the user cant select them
                    if ($scope.allowedPeople) {
                        $scope.selectedPeople = $scope.selectedPeople.map((selectedPerson) => {
                            const isAllowed = utils.findFirst(
                                $scope.allowedPeople,
                                (allowedPerson) => allowedPerson.id === selectedPerson.id,
                            );
                            if (!isAllowed) {
                                selectedPerson.isNotAllowed = true;
                            }
                            return selectedPerson;
                        });
                    }

                    return $scope.selectedPeople;
                }

                $scope.getPeopleTextRaw = function (item) {
                    // If the person is not allow, tell mentio to not select it
                    if (item.isNotAllowed) {
                        return $q.reject();
                    }

                    // slackUsername refers to a user name from any communication integration.
                    const displayName =
                        item.projectContext && item.projectContext.slackUsername
                            ? item.projectContext.slackUsername
                            : item.name;
                    item.atMentionName = utils.createAtMentionString(displayName);

                    if ($scope.resultList) {
                        $scope.resultList.push(item);
                    }

                    if ($scope.onTagAdded) {
                        $scope.onTagAdded({ item });
                    }

                    if ($scope.opened) {
                        $scope.opened({ open: false });
                    }

                    return $q.resolve(item.atMentionName);
                };
            },
        ],
    };
}
angular.module('tonkean.app').directive('tnkPeopleAtMention', tnkPeopleAtMention);
