import type { ICellEditorReactComp } from '@ag-grid-community/react';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

import type ItemsGridCellEditorParams from '../../entities/ItemsGridCellEditorParams';

import { PeopleAutocompleteSelect } from '@tonkean/infrastructure';
import type { Person } from '@tonkean/tonkean-entities';

const StyledPersonSelector = styled(PeopleAutocompleteSelect)<{ $width: number; $height: number }>`
    width: ${({ $width }) => `${$width}px`};
    // We have to calculate the padding because the component is a popup which is not affected by the cell's dimensions
    padding: ${({ $height }) => `${($height - 40) / 2}px`};
`;

interface Props extends ItemsGridCellEditorParams<Person> {
    fixedRowHeight: number;
}

const PeopleSelectorCellEditor: React.ForwardRefRenderFunction<ICellEditorReactComp, Props> = (
    { value, stopEditing, column, fixedRowHeight },
    ref,
) => {
    const [updatedValue, setUpdatedValue] = useState<Person | null | undefined>(value);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return updatedValue;
            },
        };
    });

    return (
        <StyledPersonSelector
            value={updatedValue}
            onChange={(person: Person | null) => {
                setUpdatedValue(person);
                setTimeout(() => {
                    stopEditing(false);
                }, 0);
            }}
            $width={column.getActualWidth()}
            $height={fixedRowHeight}
            isClearable
            showAvatar
            defaultMenuIsOpen
            includeExternal
        />
    );
};

export default forwardRef(PeopleSelectorCellEditor);
