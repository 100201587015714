import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import type { SmartSearchBaseProps } from './SmartSearch';
import SmartSearch from './SmartSearch';
import { SMART_SEARCH_MOBILE_BREAKPOINT } from './SmartSearchConsts';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import {
    Breakpoint,
    Modal,
    ModalAnimationType,
    ModalBackdrop,
    ModalBody,
    type ModalProps,
    useBreakpoint,
    usePreventConcurrentManualReloadFetchManager,
    XCloseButton,
    useLazyEventStreamAsyncMethod,
} from '@tonkean/infrastructure';
import { type SmartSearchAssistantToolMessage, useSmartConversation } from '@tonkean/smart-conversation';
import {
    HttpMethodType,
    type SmartConversationMessage,
    type SmartConversationReplyMethodParams,
    type SmartSearchAssistantCategoryResponse,
    type SmartSearchResponse,
} from '@tonkean/tonkean-entities';

// Todo replace any with FlattenSimpleInterpolation from styled component (type is missing)
export const SmartSearchDynamicWidth = (paddingModifier: number = 0): any => css`
    width: calc(40% + (${paddingModifier}px * 2));
    @media screen and (min-width: ${SMART_SEARCH_MOBILE_BREAKPOINT}px) and (max-width: ${Breakpoint.SMALL_1366}px) {
        width: 550px;
    }
`;

const StyledModal = styled(Modal)`
    @media screen and (min-width: ${SMART_SEARCH_MOBILE_BREAKPOINT}px) {
        border-radius: 30px;
        position: fixed;

        // we want the modal to grow until this size
        max-height: calc(100vh - 140px);
    }

    @media screen and (max-width: ${SMART_SEARCH_MOBILE_BREAKPOINT}px) {
        // Note: Having this control the width not be in the SmartSearchDynamicWidth would break the effect of having the
        // input in the modal be the same size, but thats ok, its too small for that effect.
        width: 100%;
        top: 50px;
    }
`;

// The animation for this modal is very unique so we have to overwrite the default modal animations.
// It should animate the backdrop with opacity (like the default) and animate the position + width of the modal content
const SmartSearchModalAnimation = css`
    &.modal-animation-enter {
        ${ModalBackdrop} {
            opacity: 0;
        }
        ${StyledModal} {
            // This is based on what the homepage is like
            width: 600px;
            // 22vh - based on homepage
            // 20px - modal contain padding (to get the inputs aligned)
            // 35px - modal body padding
            top: calc(22vh + 20px + 35px);
        }
    }
    &.modal-animation-enter-active,
    &.modal-animation-enter-done {
        ${ModalBackdrop} {
            transition: opacity;
            transition-duration: 300ms;
            transition-timing-function: ease-in;
            opacity: 1;
        }
        ${StyledModal} {
            transition: top, width;
            transition-duration: 1s;
            transition-timing-function: ease;
            top: 72px;
            ${SmartSearchDynamicWidth(21)}
        }
    }

    &.modal-animation-exit {
        ${ModalBackdrop} {
            opacity: 1;
        }
        ${StyledModal} {
            top: 72px;
            ${SmartSearchDynamicWidth(21)}
            opacity: 1;
        }
    }
    &.modal-animation-exit-active,
    &.modal-animation-exit-done {
        ${StyledModal}, ${ModalBackdrop} {
            transition: opacity;
            transition-duration: 300ms;
            transition-timing-function: ease-in;
            opacity: 0;
        }
    }
`;

const StyledXCloseButton = styled(XCloseButton)`
    width: 100%;
    height: 59px;
    border-top: 1px solid #e9ecf1;
    box-shadow: 0 3px 20px 4px rgba(0, 0, 0, 0.07);
`;

const getItems = (data: SmartConversationMessage<SmartSearchResponse>) => data;

interface SmartSearchEventStreamResult {
    threadId: string;
    answerText: string;
}

interface SmartSearchEventStreamParams {
    replyText: string;
    conversationId: string | undefined;
}

const SmartSearchModal: React.FC<ModalProps & SmartSearchBaseProps> = ({ open, onClose, ...props }) => {
    const breakpoint = useBreakpoint();
    const mobileView = breakpoint <= SMART_SEARCH_MOBILE_BREAKPOINT;

    const tonkeanService = useAngularService('tonkeanService');
    const callSmartSearchWithAssistant = useFeatureFlag('tonkean_feature_smartsearch_with_assistant');
    const enableFrontDoorEventStream = useFeatureFlag('tonkean_feature_front_door_assistant_event_stream');
    const shouldUseEventStream = callSmartSearchWithAssistant && enableFrontDoorEventStream;

    useEffect(() => {
        if (enableFrontDoorEventStream && !callSmartSearchWithAssistant) {
            console.warn(
                'tonkean_feature_front_door_assistant_event_stream relies on tonkean_feature_smartsearch_with_assistant and will not work without it, falling back to using no assistant',
            );
        }
    }, [callSmartSearchWithAssistant, enableFrontDoorEventStream]);

    const [[smartSearchReply], { loading: loadingSmartSearchReply, error: errorSmartSearchReply }] =
        usePreventConcurrentManualReloadFetchManager(tonkeanService, 'smartSearchReply', {
            isSingle: true,
            getItems,
        });

    const replyMethod = useCallback(
        ({ projectId, userReply, conversationId }: SmartConversationReplyMethodParams) => {
            return smartSearchReply(projectId, userReply, callSmartSearchWithAssistant, conversationId);
        },
        [callSmartSearchWithAssistant, smartSearchReply],
    );

    const [assistantToolMessages, setAssistantToolMessages] = useState<SmartSearchAssistantToolMessage[]>([]);
    const listeners = useMemo(
        () => ({
            'tool.message': (event) => {
                setAssistantToolMessages((state) => [...state, JSON.parse(event.data)]);
            },
        }),
        [],
    );
    const [{ loading: loadingEventStream, error: errorEventStream }, callEventStream] = useLazyEventStreamAsyncMethod<
        SmartSearchEventStreamParams,
        SmartSearchEventStreamResult
    >({ listeners, httpMethod: HttpMethodType.POST });

    const replyMethodEventStream = useCallback(
        async ({
            projectId,
            userReply,
            conversationId,
        }: SmartConversationReplyMethodParams): Promise<
            SmartConversationMessage<SmartSearchAssistantCategoryResponse>
        > => {
            setAssistantToolMessages([]);
            const result = await callEventStream(`${projectId}/smartSearchAssistantStream`, {
                replyText: userReply,
                conversationId,
            });
            return {
                // Message ID is auto generated in useSmartConversation
                messageId: '',
                conversationId: result.threadId,
                response: {
                    skill: 'ASSISTANT',
                    text: result.answerText,
                    answerText: result.answerText,
                },
            };
        },
        [callEventStream],
    );

    const smartConversationManager = useSmartConversation<SmartSearchResponse>(
        props.projectId,
        shouldUseEventStream ? replyMethodEventStream : replyMethod,
        shouldUseEventStream ? loadingEventStream : loadingSmartSearchReply.any,
        shouldUseEventStream ? errorEventStream : errorSmartSearchReply,
        assistantToolMessages,
    );

    const onEnteredCallback = useCallback(() => {
        if (props.initialSearchString) {
            smartConversationManager.callReplyMethod(props.initialSearchString, '', true, true);
        }
    }, [props.initialSearchString, smartConversationManager]);

    const onCloseCallback = useCallback(() => {
        smartConversationManager.messagesHelpers.reset();
        onClose();
    }, [onClose, smartConversationManager]);

    return (
        <StyledModal
            open={open}
            onEntered={onEnteredCallback}
            onClose={onCloseCallback}
            hasBackdrop={!mobileView}
            backdropBackground="rgba(0,0,0,0.5)"
            animationDuration={mobileView ? 0 : 500}
            modalAnimationType={
                mobileView ? ModalAnimationType.ANIMATE_OPACITY_ONLY : ModalAnimationType.ANIMATE_CUSTOM
            }
            fullScreen={mobileView}
            customAnimation={SmartSearchModalAnimation}
            fixedWidth
        >
            <ModalBody>
                <SmartSearch {...props} smartConversationManager={smartConversationManager} mobileView={mobileView} />
            </ModalBody>
            {mobileView && <StyledXCloseButton />}
        </StyledModal>
    );
};

export default SmartSearchModal;
