/**
 * Created by ShaLi on 5/13/15.
 */

function barHeight() {
    return function (item, allItems, fieldName) {
        let max = allItems[0][fieldName];
        angular.forEach(allItems, function (item) {
            max = Math.max(item[fieldName], max);
        });
        return { height: `${(item[fieldName] / max) * 100}%` };
    };
}
export default angular.module('tonkean.app').filter('barHeight', barHeight);
