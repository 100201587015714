import DivideOperator from './arithmetic/DivideOperator';
import MultiplyOperator from './arithmetic/MultiplyOperator';
import PowOperator from './arithmetic/PowOperator';
import SubtractionOperator from './arithmetic/SubtractionOperator';
import SumOperator from './arithmetic/SumOperator';
import AddTimeFunction from './functions/AddTimeFunction';
import ArrayCommonFunction from './functions/ArrayCommonFunction';
import ArrayDiffFunction from './functions/ArrayDiffFunction';
import ArrayDistinctFunction from './functions/ArrayDistinctFunction';
import ArrayIndexOfFunction from './functions/ArrayIndexOfFunction';
import ArrayLengthFunction from './functions/ArrayLengthFunction';
import ArraySumFunction from './functions/ArraySumFunction';
import AtMentionFunction from './functions/AtMentionFunction';
import AvgFunction from './functions/AvgFunction';
import ColumnAvgFunction from './functions/ColumnAvgFunction';
import ColumnMaxFunction from './functions/ColumnMaxFunction';
import ColumnMinFunction from './functions/ColumnMinFunction';
import ColumnSumFunction from './functions/ColumnSumFunction';
import CommentTranscriptFunction from './functions/CommentTranscriptFunction';
import ConcatFunction from './functions/ConcatFunction';
import ConcatWithLineBreaksFunction from './functions/ConcatWithLineBreaksFunction';
import ConditionalValuesFunction from './functions/ConditionalValuesFunction';
import ConvertInnerItemsToArray from './functions/ConvertInnerItemsToArray';
import ConvertObjectToKeyedArrayFunction from './functions/ConvertObjectToKeyedArrayFunction';
import ConvertToArrayFunction from './functions/ConvertToArrayFunction';
import ConvertToBooleanFunction from './functions/ConvertToBooleanFunction';
import ConvertToDateFunction from './functions/ConvertToDateFunction';
import ConvertToNumberFunction from './functions/ConvertToNumberFunction';
import ConvertToStringFunction from './functions/ConvertToStringFunction';
import CreateListFunction from './functions/CreateListFunction';
import CsvToJsonFunction from './functions/CsvToJsonFunction';
import DateDiffFunction from './functions/DateDiffFunction';
import DateFormatFunction from './functions/DateFormatFunction';
import DayOfWeekFunction from './functions/DayOfWeekFunction';
import DaysDiffFunction from './functions/DaysDiffFunction';
import DecimalFormatFunction from './functions/DecimalFormatFunction';
import DecodeBase64Function from './functions/DecodeBase64Function';
import DecodeHtmlFunction from './functions/DecodeHtmlFunction';
import DecodeUrlFunction from './functions/DecodeUrlFunction';
import DecryptFunction from './functions/DecryptFunction';
import EncodeBase64Function from './functions/EncodeBase64Function';
import EncodeMd5Function from './functions/EncodeMd5Function';
import EncodeUrlFunction from './functions/EncodeUrlFunction';
import EscapeHtmlFunction from './functions/EscapeHtmlFunction';
import EscapeJsonFunction from './functions/EscapeJsonFunction';
import EvaluateTonkeanExpressionFunction from './functions/EvaluateTonkeanExpressionFunction';
import FallbackFunction from './functions/FallbackFunction';
import FileToBase64Function from './functions/FileToBase64Function';
import FilterArrayOfObjectsWithEmptyField from './functions/FilterArrayOfObjectsWithEmptyField';
import FindWordsFunction from './functions/FindWordsFunction';
import FormatFullNameFunction from './functions/FormatFullNameFunction';
import GenerateSequenceIdFunction from './functions/GenerateSequenceIdFunction';
import GetInterfaceLinkByNameFunction from './functions/GetInterfaceLinkByNameFunction';
import GetSequenceLinkByTriggerNameFunction from './functions/GetSequenceLinkByTriggerNameFunction';
import GreedyHtmlPathFunction from './functions/GreedyHtmlPathFunction';
import HMAC_SHA256EncryptionFunction from './functions/HMAC_SHA256EncryptionFunction';
import HourFunction from './functions/HourFunction';
import HtmlPathFunction from './functions/HtmlPathFunction';
import InnerAggregationFunction from './functions/InnerAggregationFunction';
import InnerItemsCountFunction from './functions/InnerItemsCountFunction';
import IsBooleanFunction from './functions/IsBooleanFunction';
import IsDateFunction from './functions/IsDateFunction';
import IsEmptyFunction from './functions/IsEmptyFunction';
import IsInListFunction from './functions/IsInListFunction';
import IsNumberFunction from './functions/IsNumberFunction';
import JSLTFunction from './functions/JSLTFunction';
import JsonPathFunction from './functions/JsonPathFunction';
import JsonTraverseFunction from './functions/JsonTraverseFunction';
import LastIndexOfFunction from './functions/LastIndexOfFunction';
import MapFunction from './functions/MapFunction';
import MaxFunction from './functions/MaxFunction';
import MinFunction from './functions/MinFunction';
import MinuteFunction from './functions/MinuteFunction';
import ModFunction from './functions/ModFunction';
import MonthFunction from './functions/MonthFunction';
import NextDayOfWeekFunction from './functions/NextDayOfWeekFunction';
import NotFunction from './functions/NotFunction';
import OcrOutputContainsWordsFunction from './functions/OcrOutputContainsWordsFunction';
import PairsFunction from './functions/PairsFunction';
import PreviousDayOfWeekFunction from './functions/PreviousDayOfWeekFunction';
import PreviousValueFunction from './functions/PreviousValueFunction';
import RandomFunction from './functions/RandomFunction';
import RegexFindAllFunction from './functions/RegexFindAllFunction';
import RegexFindFunction from './functions/RegexFindFunction';
import RegexReplaceFunction from './functions/RegexReplaceFunction';
import RoundFunction from './functions/RoundFunction';
import SplitAndTakeIndexFunction from './functions/SplitAndTakeIndexFunction';
import SplitToPartsFunction from './functions/SplitToPartsFunction';
import SqrtFunction from './functions/SqrtFunction';
import StringCharAtFunction from './functions/StringCharAtFunction';
import StringConvertArrayFunction from './functions/StringConvertArrayFunction';
import StringIndexOfFunction from './functions/StringIndexOfFunction';
import StringJoinFunction from './functions/StringJoinFunction';
import StringLengthFunction from './functions/StringLengthFunction';
import StringLookUpTableFunction from './functions/StringLookUpTableFunction';
import StringReplaceFunction from './functions/StringReplaceFunction';
import StringStartsWithFunction from './functions/StringStartsWithFunction';
import StringToLowerCaseFunction from './functions/StringToLowerCaseFunction';
import StringToUpperCaseFunction from './functions/StringToUpperCaseFunction';
import StringTrimFunction from './functions/StringTrimFunction';
import StripHtmlFunction from './functions/StripHtmlFunction';
import SubstringFunction from './functions/SubstringFunction';
import FieldGroupTakeNotEmptyFunction from './functions/TakeNotEmptyFieldByFieldGroupFunction';
import TakeNotEmptyFunction from './functions/TakeNotEmptyFunction';
import TextAnalysisFunction from './functions/TextAnalysisFunction';
import ToDateFunction from './functions/ToDateFunction';
import ToNumberFunction from './functions/ToNumberFunction';
import ToPascalCaseFunction from './functions/ToPascalCaseFunction';
import ToStringFunction from './functions/ToStringFunction';
import ToTimeFunction from './functions/ToTimeFunction';
import ToTimestampFunction from './functions/ToTimestampFunction';
import TruncFunction from './functions/TruncFunction';
import UnescapeJsonFunction from './functions/UnescapeJsonFunction';
import UniqueIdFunction from './functions/UniqueIdFunction';
import WeekNumFunction from './functions/WeekNumFunction';
import WrapHtmlFunction from './functions/WrapHtmlFunction';
import XmlPathFunction from './functions/XmlPathFunction';
import XmlToJsonFunction from './functions/XmlToJsonFunction';
import EqualsOperator from './logical/EqualsOperator';
import GreaterThanOperator from './logical/GreaterThanOperator';
import GreaterThanOrEqualsOperator from './logical/GreaterThanOrEqualsOperator';
import LessThanOperator from './logical/LessThanOperator';
import LessThanOrEqualsOperator from './logical/LessThanOrEqualsOperator';
import LogicalAndOperator from './logical/LogicalAndOperator';
import LogicalOrOperator from './logical/LogicalOrOperator';
import NotEqualsOperator from './logical/NotEqualsOperator';
import EmailDomainFunction from './mocks/EmailDomainFunction';
import FirstNameFunction from './mocks/FirstNameFunction';

import type { FormulaOperatorDefinitionBase } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';

/**
 * Map with operator key as key and matching operator definition as value.
 */
export const operatorKeyToOperatorMap = operatorKeyToOperatorMapTypeEnforcer({
    [OperatorKey.DIVIDE]: new DivideOperator(),
    [OperatorKey.MULTIPLY]: new MultiplyOperator(),
    [OperatorKey.POW]: new PowOperator(),
    [OperatorKey.SUBTRACTION]: new SubtractionOperator(),
    [OperatorKey.SUM]: new SumOperator(),
    [OperatorKey.AVG]: new AvgFunction(),
    [OperatorKey.MAX]: new MaxFunction(),
    [OperatorKey.SQRT]: new SqrtFunction(),
    [OperatorKey.STRING_CONCAT]: new ConcatFunction(),
    [OperatorKey.CONDITIONAL_VALUES]: new ConditionalValuesFunction(),
    [OperatorKey.EQUALS]: new EqualsOperator(),
    [OperatorKey.GREATER_THAN]: new GreaterThanOperator(),
    [OperatorKey.GREATER_THAN_OR_EQUALS]: new GreaterThanOrEqualsOperator(),
    [OperatorKey.LESS_THAN]: new LessThanOperator(),
    [OperatorKey.LESS_THAN_OR_EQUALS]: new LessThanOrEqualsOperator(),
    [OperatorKey.LOGICAL_AND]: new LogicalAndOperator(),
    [OperatorKey.LOGICAL_OR]: new LogicalOrOperator(),
    [OperatorKey.NOT_EQUALS]: new NotEqualsOperator(),
    [OperatorKey.DAYS_DIFF]: new DaysDiffFunction(),
    [OperatorKey.STRING_LOOK_UP_TABLE]: new StringLookUpTableFunction(),
    [OperatorKey.MIN]: new MinFunction(),
    [OperatorKey.STRING_JOIN]: new StringJoinFunction(),
    [OperatorKey.FALLBACK]: new FallbackFunction(),
    [OperatorKey.IS_EMPTY]: new IsEmptyFunction(),
    [OperatorKey.IS_BOOLEAN]: new IsBooleanFunction(),
    [OperatorKey.IS_NUMBER]: new IsNumberFunction(),
    [OperatorKey.IS_DATE]: new IsDateFunction(),
    [OperatorKey.PREVIOUS_VALUE]: new PreviousValueFunction(),
    [OperatorKey.STRING_INDEX_OF]: new StringIndexOfFunction(),
    [OperatorKey.SUB_STRING]: new SubstringFunction(),
    [OperatorKey.NOT]: new NotFunction(),
    [OperatorKey.STRING_CHAR_AT]: new StringCharAtFunction(),
    [OperatorKey.STRING_REPLACE]: new StringReplaceFunction(),
    [OperatorKey.JSON_PATH]: new JsonPathFunction(),
    [OperatorKey.JSLT]: new JSLTFunction(),
    [OperatorKey.JSON_TRAVERSE]: new JsonTraverseFunction(),
    [OperatorKey.SPLIT_AND_TAKE]: new SplitAndTakeIndexFunction(),
    [OperatorKey.ARRAY_LENGTH]: new ArrayLengthFunction(),
    [OperatorKey.ARRAY_SUM]: new ArraySumFunction(),
    [OperatorKey.ARRAY_DIFF]: new ArrayDiffFunction(),
    [OperatorKey.ARRAY_DISTINCT]: new ArrayDistinctFunction(),
    [OperatorKey.STRING_TRIM]: new StringTrimFunction(),
    [OperatorKey.STRING_STARTS_WITH]: new StringStartsWithFunction(),
    [OperatorKey.STRING_UPPER_CASE]: new StringToUpperCaseFunction(),
    [OperatorKey.STRING_TO_LOWER_CASE]: new StringToLowerCaseFunction(),
    [OperatorKey.STRING_LAST_INDEX_OF]: new LastIndexOfFunction(),
    [OperatorKey.TO_DATE]: new ToDateFunction(),
    [OperatorKey.STRING_LENGTH]: new StringLengthFunction(),
    [OperatorKey.STRIP_HTML]: new StripHtmlFunction(),
    [OperatorKey.ENCODE_URL]: new EncodeUrlFunction(),
    [OperatorKey.DECODE_URL]: new DecodeUrlFunction(),
    [OperatorKey.ESCAPE_JSON]: new EscapeJsonFunction(),
    [OperatorKey.DECODE_HTML_URL]: new DecodeHtmlFunction(),
    [OperatorKey.TO_STRING]: new ToStringFunction(),
    [OperatorKey.TO_NUMBER]: new ToNumberFunction(),
    [OperatorKey.COLUMN_AVG]: new ColumnAvgFunction(),
    [OperatorKey.COLUMN_MAX]: new ColumnMaxFunction(),
    [OperatorKey.COLUMN_MIN]: new ColumnMinFunction(),
    [OperatorKey.COLUMN_SUM]: new ColumnSumFunction(),
    [OperatorKey.AT_MENTION]: new AtMentionFunction(),
    [OperatorKey.GENERATE_SEQUENCE_ID]: new GenerateSequenceIdFunction(),
    [OperatorKey.HOUR]: new HourFunction(),
    [OperatorKey.MINUTE]: new MinuteFunction(),
    [OperatorKey.TO_TIME]: new ToTimeFunction(),
    [OperatorKey.DAY_OF_WEEK]: new DayOfWeekFunction(),
    [OperatorKey.DECIMAL_FORMAT]: new DecimalFormatFunction(),
    [OperatorKey.ADD_TIME]: new AddTimeFunction(),
    [OperatorKey.MONTH]: new MonthFunction(),
    [OperatorKey.REGEX_FIND]: new RegexFindFunction(),
    [OperatorKey.TEXT_ANALYSIS]: new TextAnalysisFunction(),
    [OperatorKey.HTML_PATH]: new HtmlPathFunction(),
    [OperatorKey.TO_PASCAL_CASE]: new ToPascalCaseFunction(),
    [OperatorKey.GREEDY_HTML_PATH]: new GreedyHtmlPathFunction(),
    [OperatorKey.PREVIOUS_DAY_OF_WEEK]: new PreviousDayOfWeekFunction(),
    [OperatorKey.NEXT_DAY_OF_WEEK]: new NextDayOfWeekFunction(),
    [OperatorKey.OCR_OUTPUT_CONTAINS_WORDS]: new OcrOutputContainsWordsFunction(),
    [OperatorKey.FIND_WORDS]: new FindWordsFunction(),
    [OperatorKey.REGEX_FIND_ALL]: new RegexFindAllFunction(),
    [OperatorKey.COMMENT_TRANSCRIPT]: new CommentTranscriptFunction(),
    [OperatorKey.INNER_ITEMS_COUNT]: new InnerItemsCountFunction(),
    [OperatorKey.TRUNC]: new TruncFunction(),
    [OperatorKey.MOD]: new ModFunction(),
    [OperatorKey.ROUND]: new RoundFunction(),
    [OperatorKey.UNIQUE_IDENTIFIER]: new UniqueIdFunction(),
    [OperatorKey.RANDOM]: new RandomFunction(),
    [OperatorKey.XML_PATH]: new XmlPathFunction(),
    [OperatorKey.CONVERT_TO_BOOLEAN]: new ConvertToBooleanFunction(),
    [OperatorKey.CONVERT_TO_DATE]: new ConvertToDateFunction(),
    [OperatorKey.INNER_TRACK_AGGREGATION]: new InnerAggregationFunction(),
    [OperatorKey.CONVERT_TO_NUMBER]: new ConvertToNumberFunction(),
    [OperatorKey.CONVERT_TO_STRING]: new ConvertToStringFunction(),
    [OperatorKey.DATE_FORMAT]: new DateFormatFunction(),
    [OperatorKey.TO_TIMESTAMP]: new ToTimestampFunction(),
    [OperatorKey.DECRYPT]: new DecryptFunction(),
    [OperatorKey.CONVERT_TO_ARRAY]: new ConvertToArrayFunction(),
    [OperatorKey.TAKE_NOT_EMPTY]: new TakeNotEmptyFunction(),
    [OperatorKey.FIELD_GROUP_TAKE_NOT_EMPTY]: new FieldGroupTakeNotEmptyFunction(),
    [OperatorKey.PAIRS]: new PairsFunction(),
    [OperatorKey.CREATE_LIST]: new CreateListFunction(),
    [OperatorKey.FORMAT_FULL_NAME]: new FormatFullNameFunction(),
    [OperatorKey.FILE_TO_BASE64]: new FileToBase64Function(),
    [OperatorKey.REGEX_REPLACE]: new RegexReplaceFunction(),
    [OperatorKey.MAP]: new MapFunction(),
    [OperatorKey.EVALUATE_TONKEAN_EXPRESSION]: new EvaluateTonkeanExpressionFunction(),
    [OperatorKey.ENCODE_BASE64]: new EncodeBase64Function(),
    [OperatorKey.DECODE_BASE64]: new DecodeBase64Function(),
    [OperatorKey.ARRAY_INDEX_OF]: new ArrayIndexOfFunction(),
    [OperatorKey.DATE_DIFF]: new DateDiffFunction(),
    [OperatorKey.WEEK_NUM]: new WeekNumFunction(),
    [OperatorKey.HMAC_SHA256_ENCRYPTION]: new HMAC_SHA256EncryptionFunction(),
    [OperatorKey.ARRAY_COMMON]: new ArrayCommonFunction(),
    [OperatorKey.SPLIT_TO_PARTS]: new SplitToPartsFunction(),
    [OperatorKey.CONVERT_OBJECT_TO_KEYED_ARRAY]: new ConvertObjectToKeyedArrayFunction(),
    [OperatorKey.CONVERT_INNER_ITEMS_TO_ARRAY]: new ConvertInnerItemsToArray(),
    [OperatorKey.FILTER_ARRAY_OF_OBJECTS_WITH_EMPTY_FIELD]: new FilterArrayOfObjectsWithEmptyField(),
    [OperatorKey.STRING_CONCAT_WITH_LINE_BREAKS]: new ConcatWithLineBreaksFunction(),
    [OperatorKey.WRAP_HTML]: new WrapHtmlFunction(),
    [OperatorKey.STRING_CONVERT_ARRAY]: new StringConvertArrayFunction(),
    [OperatorKey.XML_TO_JSON]: new XmlToJsonFunction(),
    [OperatorKey.ESCAPE_HTML]: new EscapeHtmlFunction(),
    [OperatorKey.ENCODE_MD5]: new EncodeMd5Function(),
    [OperatorKey.GET_SEQUENCE_LINK_BY_TRIGGER_NAME]: new GetSequenceLinkByTriggerNameFunction(),
    [OperatorKey.GET_INTERFACE_LINK_BY_NAME]: new GetInterfaceLinkByNameFunction(),
    [OperatorKey.CSV_TO_JSON]: new CsvToJsonFunction(),
    [OperatorKey.UNESCAPE_JSON]: new UnescapeJsonFunction(),
    [OperatorKey.IS_IN_LIST]: new IsInListFunction(),
});

const mockFunctions = [new EmailDomainFunction(), new FirstNameFunction()];

/**
 * List of available function operator definitions.
 */
const formulaOperatorsList = [...Object.values(operatorKeyToOperatorMap), ...mockFunctions];
export default formulaOperatorsList;

/**
 * Type to enforce OperatorKey as key, and a FormulaOperatorDefinitionBase with matching OperatorKey in key as value.
 */
type OperatorKeyToOperatorMap = {
    [K in OperatorKey]: FormulaOperatorDefinitionBase & { key: K };
};

/**
 * This is a hack that allows to enforce the type, while keeping the inferred type of the class and not the interface.
 */
function operatorKeyToOperatorMapTypeEnforcer<T extends OperatorKeyToOperatorMap>(operatorKeyToOperatorMap: T): T {
    return operatorKeyToOperatorMap;
}
