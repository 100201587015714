import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function OrigamiRiskCustomActionsDefinitionCtrl($scope, integrations) {
    const ctrl = this;

    $scope.data = {
        previewEvaluationSource: ctrl.previewEvaluationSource,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
        fileEntityMetadata: {
            displayName: 'File',
            entity: 'File',
            pluralLabel: 'Files',
        },
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.onDomainTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.domainExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRecordIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.recordIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFolderIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.folderIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDescriptionTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.descriptionExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'OrigamiRiskCustomActionsDefinitionCtrl',
        lateConstructController(OrigamiRiskCustomActionsDefinitionCtrl),
    );
