import { TONKEAN_ENTITY_TYPE } from '@tonkean/constants';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';
import { analyticsWrapper } from '@tonkean/analytics';
import { FIELD_COMPARE_TIMEFRAME_LABELS_MAP, FIELD_CREATE_TYPES, getFieldDefinitionConfigurationModalSteps } from '@tonkean/constants';

function MetricsBarCtrl(
    $scope,
    $rootScope,
    $timeout,
    $q,
    modal,
    modalUtils,
    authenticationService,
    customFieldsManager,
    consts,
    projectManager,
    workflowVersionManager,
    tonkeanService,
    pollingManager,
    entityHelper,
    utils,
) {
    $scope.wvm = workflowVersionManager;
    $scope.cfm = customFieldsManager;
    $scope.pm = $rootScope.pm;
    $scope.modal = modal;
    $scope.TONKEAN_ENTITY_TYPE = TONKEAN_ENTITY_TYPE;
    $scope.consts = consts;
    $scope.as = authenticationService;
    $scope.fieldCompareTimeframeLabelsMap = FIELD_COMPARE_TIMEFRAME_LABELS_MAP;
    $scope.fieldCreateTypes = FIELD_CREATE_TYPES;
    $scope.reloadDefinition = {};
    $scope.loadingDefinition = {};
    $scope.editingField = {};
    $scope.refreshingFields = {};

    $scope.data = {
        fieldChartModalOpen: false,
        currentFieldInstance: undefined,
        target: undefined,
        targetType: null,
        workflowVersionId: $scope.workflowVersionId,
        currentMetricTooltipIsOpen: undefined,
        currentMetricTooltipLock: undefined,
        sampleMetrics: {
            CUSTOMER_SUCCESS: [
                { fieldName: 'New sign ups /w', value: '15', change: 16, trend: 'up', positive: true },
                { fieldName: 'Active pilots', value: '32', change: 5.9, trend: 'down', negative: true },
                { fieldName: 'Open customer bugs', value: '8', change: 33.3, trend: 'up', negative: true },
            ],
            PRODUCT_ECOSYSTEM: [
                { fieldName: 'Currently in progress', value: '15', change: 16, trend: 'up' },
                { fieldName: 'Days to launch', value: '32' },
                { fieldName: 'P0 bugs', value: '5', change: 3.9, trend: 'down', positive: true },
            ],
            OTHER: [
                { fieldName: 'New sign ups /w', value: '15', change: 16, trend: 'up', positive: true },
                { fieldName: 'Active pilots', value: '32', change: 5.9, trend: 'down', negative: true },
                { fieldName: 'Days to launch', value: '32' },
                { fieldName: 'P0 bugs', value: '5', change: 3.9, trend: 'down', positive: true },
            ],
        },
    };

    $scope.data.groupMetrics = [
        { fieldName: 'New sign ups /w', value: '15', change: 16, trend: 'up', positive: true },
        { fieldName: 'Active pilots', value: '32', change: 5.9, trend: 'down', positive: false },
        { fieldName: 'WAU', value: '145', change: 3.9, trend: 'down', positive: false },
        { fieldName: 'Open customer bugs', value: '8', change: 33.3, trend: 'up', positive: false },
        // {fieldName: 'Goal', value: '$60,000'}
    ];

    $scope.init = function () {
        if (!$scope.listMode) {
            $scope.collapseMetrics();
        } else {
            $scope.expandMetrics();

            if (
                !$scope.cfm.selectedVisibleTargetFieldsMap[$scope.data.workflowVersionId] ||
                !$scope.cfm.selectedVisibleTargetFieldsMap[$scope.data.workflowVersionId][
                    $scope.fieldDefinitionTargetType
                ] ||
                !$scope.cfm.selectedVisibleTargetFieldsMap[$scope.data.workflowVersionId][
                    $scope.fieldDefinitionTargetType
                ].length
            ) {
                $scope.data.loadAllFields = true;
            }
        }
        $scope.data.targetType = $scope.target
            ? getTonkeanEntityType($scope.target.id)
            : TONKEAN_ENTITY_TYPE.GROUP;
    };

    $scope.updateMetricValueSaved = function (updatedField) {
        $scope.editingField[updatedField.fieldDefinition.id] = null;
        analyticsWrapper.track('Update manual key metric', { category: 'Global metrics bar', label: $scope.label });
    };

    $scope.setMetricTooltipIsOpen = function (fieldId, isOpen, isLocking) {
        if (isLocking) {
            if ($scope.data.currentMetricTooltipLock) {
                $scope.data.currentMetricTooltipLock = null;
                isOpen = false;
            } else {
                $scope.data.currentMetricTooltipLock = fieldId;
            }
        } else if ($scope.data.currentMetricTooltipLock) {
            return;
        }

        $scope.data.currentMetricTooltipIsOpen = isOpen ? fieldId : null;
    };

    $scope.getFieldByDefinition = function (fieldDefintionId, isDraft) {
        const fieldsMap = isDraft ? $scope.fieldsMapDraft : $scope.fieldsMap;

        return fieldsMap[fieldDefintionId] && fieldsMap[fieldDefintionId][0]
            ? fieldsMap[fieldDefintionId][0]
            : fieldsMap[fieldDefintionId]
            ? fieldsMap[fieldDefintionId]
            : null;
    };

    $scope.refreshField = function (metric, fieldDefinitionId) {
        $scope.refreshingFields[fieldDefinitionId] = true;
        tonkeanService
            .repopulateFieldDefinition($scope.workflowVersionId, fieldDefinitionId)
            .then(() => {
                let numberOfPollingIntervals = 0;
                let valueChangedDueToPolling = false;
                pollingManager.registerPollingPromise(
                    () => {
                        numberOfPollingIntervals = numberOfPollingIntervals + 1;
                        return tonkeanService.getFieldInstanceById(metric.id).then((polledField) => {
                            const enrichedPolledField = entityHelper.enrichField(polledField);
                            valueChangedDueToPolling = enrichedPolledField.value !== metric.value;
                            utils.flatCopyObjectProperties(enrichedPolledField, metric);
                            return $q.resolve();
                        });
                    },
                    () => numberOfPollingIntervals > 20 || valueChangedDueToPolling,
                    () => {
                        $scope.refreshingFields[fieldDefinitionId] = false;
                        if (valueChangedDueToPolling) {
                            $scope.$emit('alert', {
                                msg: 'Key Metric value successfully updated!',
                                type: 'success',
                            });
                        }
                    },
                    3000,
                );
            })
            .catch(() => $scope.$emit('alert', { msg: 'Error occurred while refreshing Key Metric', type: 'warning' }));
    };

    $scope.openFixIntegrationModal = function (fieldDefinition) {
        $scope.onUserFixIntegration({ fieldDefinition });
    };

    $scope.createFieldInstance = function (fieldDefinition) {
        $scope.loadingDefinition[fieldDefinition.id] = true;
        $scope.onUserAddFieldInstance({ fieldDefinition }).then(function () {
            $scope.loadingDefinition[fieldDefinition.id] = false;

            $scope.reloadDefinition[fieldDefinition.id] = true;
            $timeout(function () {
                $scope.editingField[fieldDefinition.id] = true;
                $scope.reloadDefinition[fieldDefinition.id] = false;
            });
        });
    };

    $scope.openMatchFieldModal = function (field) {
        modalUtils.openConnectFieldManuallyModal(field, $scope.groupId, $scope.target);
    };

    $scope.expandMetrics = function () {
        $scope.collapsed = false;
    };

    $scope.collapseMetrics = function () {
        $scope.collapsed = true;
    };

    $scope.moveMetricLeft = function (fieldDefinitionId) {
        return customFieldsManager.moveFieldLeft(
            $scope.groupId,
            fieldDefinitionId,
            $scope.fieldDefinitionTargetType,
            $scope.environment,
        );
    };

    $scope.moveMetricRight = function (fieldDefinitionId) {
        return customFieldsManager.moveFieldRight(
            $scope.groupId,
            fieldDefinitionId,
            $scope.fieldDefinitionTargetType,
            $scope.environment,
        );
    };

    $scope.duplicateMetricInSameGroup = function (fieldDefinition, metric) {
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            fieldDefinition.targetType,
            fieldDefinition.type,
            fieldDefinition.projectIntegration,
            false,
            true,
            fieldDefinition,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            metric ? metric.value : '',
            null,
            $scope.data.workflowVersionId,
        ]);
    };

    $scope.openDuplicateMetricModal = function (fieldDefinition, metric) {
        modal.openDuplicateFieldDefinitionModal(
            fieldDefinition,
            projectManager.groupsMap[$scope.groupId],
            null,
            metric ? metric.value : '',
        );
    };

    $scope.openFieldChartModal = function (field, target) {
        if (!field) {
            return;
        }
        $scope.data.currentFieldInstance = field;
        $scope.data.target = target;
        $scope.data.fieldChartModalOpen = true;
    };

    $scope.closeFieldChartModal = function () {
        $scope.data.fieldChartModalOpen = false;
    };

    $scope.openFieldViewModal = function (metric, target, viewNode) {
        if (!metric) {
            return;
        }

        if (
            metric?.fieldDefinition?.definition?.aggregationType === 'Terms' &&
            metric?.fieldDefinition?.definition?.formulaType === 'STRUCTURED'
        ) {
            modal.openFieldViewModal(metric, target, 'PIE_CHART');
        }

        modal.openFieldViewModal(metric, target, viewNode);
    };

    $scope.hideMetric = function (definitionId) {
        customFieldsManager.updateFieldIsHidden(
            definitionId,
            true,
            $scope.environment,
            $scope.groupId,
            $scope.workflowVersionId,
            $scope.fieldDefinitionTargetType,
        );
    };

    $scope.onMetricEditKeyDown = function ($event, metric) {
        if ($event.code === 'Escape' || $event.keyCode === 27) {
            $scope.editingField[metric.fieldDefinition.id] = false;
            $event.stopPropagation();
        }
    };

    $scope.openConfigureManualMetricModal = function (fieldDefinition) {
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            'GLOBAL',
            fieldDefinition.type,
            null,
            false,
            false,
            fieldDefinition,
            null,
            null,
            getFieldDefinitionConfigurationModalSteps().dataTypeConfiguration.id,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.data.workflowVersionId,
        ]);
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('MetricsBarCtrl', MetricsBarCtrl);
