import React from 'react';
import WorkerAuditLogItem from './components/WorkerAuditLogItem';
import WorkflowVersionsPaginatedList from '../WorkflowVersionsHistoryTab/WorkflowVersionsPaginatedList';
import WorkflowVersionLoadingHistoryLog from '../WorkflowVersionsHistoryTab/WorkflowVersionLoadingHistoryLog';
import WorkerAuditLogGroupItem from './components/WorkerAuditLogGroupItem';

export default class WorkerAuditLogs extends React.Component {
    /**
     * Constructor.
     */
    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.pageSize = this.props.pageSize || 10;

        let onlyActivityTypesSet = null;
        if (this.props.onlyActivityTypes) {
            onlyActivityTypesSet = {};
            this.props.onlyActivityTypes.forEach((onlyActivityType) => {
                onlyActivityTypesSet[onlyActivityType] = true;
            });
        }

        // Holds the internal state for this component.
        this.state = {
            loadingInitialActivities: true,
            errorLoadingInitialActivities: false,

            loadingMoreActivities: false,
            errorLoadingMoreActivities: false,

            activities: null,
            groupedActivities: null,

            activitiesLoadedCounter: 0,
            groupedActivitiesLoadedCounter: 0,
            totalActivities: 0,
            totalGroups: 0,

            filters: {
                activityType: this.props.consts
                    .getTriggerActivityActionTypes()
                    .map((type) => type.activityType)
                    .filter((type) => !onlyActivityTypesSet || onlyActivityTypesSet[type]),
                actor: undefined,
                fromDate: undefined,
                toDate: undefined,
                subSequentialIdentifier: this.props.subSequentialIdentifier,
            },

            shouldDisplayLoadMore: false,
            useTotalCommitted: this.props.useTotalCommitted,
            isGrouped: this.props.targetTypeId != null ? false : this.props.groupByToggleDefault,
            emptyListMessage: 'No changes were found...',
            skip: 0,
        };
    }

    /**
     * Occurs once the component is finished initializing itself into the UI.
     */
    componentDidMount() {
        if (!this._Mounted) {
            this.loadActivities();
            this._Mounted = true;
        }
    }

    /**
     * Occurs once a filter has been changed for the activity log.
     */
    onFiltersChanged = (filters) => {
        this.setState(
            {
                activities: [],
                groupedActivities: [],
                filters,
            },
            () => this.loadActivities(),
        );
    };

    /**
     * Loads activities to be displayed in the UI.
     */
    loadActivities() {
        this.setState(
            {
                loadingInitialActivities: true,
                errorLoadingInitialActivities: false,
                activitiesLoadedCounter: 0,
                groupedActivitiesLoadedCounter: 0,
            },
            async () => {
                try {
                    const activitiesResponse = await this.loadActivitiesFromServer(
                        true,
                        this.props.shouldLoadCommitActivities,
                    );
                    let groupedActivitiesResponse;
                    if (!this.props.hideGroupByToggle) {
                        groupedActivitiesResponse = await this.loadGroupedActivitiesFromServer(
                            true,
                            this.props.shouldLoadCommitActivities,
                        );
                    }

                    this.setState({
                        loadingInitialActivities: false,
                        totalActivities: this.props.shouldLoadCommitActivities
                            ? activitiesResponse.commitActivityTotal
                            : activitiesResponse.total,
                        activities: activitiesResponse.activity,
                        activitiesLoadedCounter: activitiesResponse.activity.length,
                        shouldDisplayLoadMore: this.state.useTotalCommitted
                            ? activitiesResponse.commitActivityTotal > activitiesResponse.activity.length
                            : activitiesResponse.total > activitiesResponse.activity.length,
                        groupedActivities: groupedActivitiesResponse?.groups,
                        groupedActivitiesLoadedCounter: groupedActivitiesResponse?.groups.length,
                        totalGroups: groupedActivitiesResponse?.total,
                    });
                } catch {
                    this.setState({
                        loadingInitialActivities: false,
                        errorLoadingInitialActivities: true,
                        emptyListMessage: "couldn't load changes...",
                    });
                }
            },
        );
    }

    /**
     * Loads another page of activities.
     */
    async loadMoreActivities() {
        try {
            this.setState((prevState) => ({
                loadingMoreActivities: true,
                errorLoadingMoreActivities: false,
                skip: prevState.skip + 5,
            }));

            const activitiesResponse = await this.loadActivitiesFromServer(false);

            this.setState({
                loadingMoreActivities: false,
                activities: [...this.state.activities, ...(activitiesResponse.activity || [])],
                activitiesLoadedCounter: this.state.activitiesLoadedCounter + activitiesResponse.activity.length,
                shouldDisplayLoadMore:
                    this.state.totalActivities >
                    this.state.activitiesLoadedCounter + activitiesResponse.activity.length,
            });
        } catch {
            this.setState({
                loadingMoreActivities: false,
                errorLoadingMoreActivities: true,
                emptyListMessage: "couldn't load changes...",
            });
        }
    }

    /**
     * Loads another page of grouped activities.
     */
    async loadMoreGroupedActivities() {
        try {
            this.setState({
                loadingMoreActivities: true,
                errorLoadingMoreActivities: false,
            });

            const groupedActivitiesResponse = await this.loadGroupedActivitiesFromServer(
                false,
                this.props.shouldLoadCommitActivities,
            );

            this.setState({
                loadingMoreActivities: false,
                groupedActivities: [...this.state.groupedActivities, ...(groupedActivitiesResponse.groups || [])],
                groupedActivitiesLoadedCounter:
                    this.state.groupedActivitiesLoadedCounter + groupedActivitiesResponse.groups.length,
            });
        } catch {
            this.setState({
                loadingMoreActivities: false,
                errorLoadingMoreActivities: true,
                emptyListMessage: "couldn't load changes...",
            });
        }
    }

    /**
     * Loads activities from server.
     */
    async loadActivitiesFromServer(loadTotal, shouldLoadCommitActivities) {
        if (this.props.groupId) {
            return this.props.tonkeanService.getGroupActivity(
                this.props.groupId,
                this.pageSize,
                this.state.filters.activityType?.activityType
                    ? [this.state.filters.activityType?.activityType]
                    : undefined,
                null,
                this.state.activitiesLoadedCounter,
                shouldLoadCommitActivities,
                this.state.filters.actor ? [this.state.filters.actor] : undefined,
                this.state.filters.fromDate?.getTime(),
                this.state.filters.toDate?.getTime(),
                loadTotal,
                this.props.workflowVersionId,
                null,
                this.state.filters.subSequentialIdentifier,
            );
        } else {
            return this.props.tonkeanService.getProjectActivity(
                this.props.projectManager.project.id,
                this.pageSize,
                this.state.filters.activityType?.activityType
                    ? [this.state.filters.activityType?.activityType]
                    : undefined,
                null,
                null,
                null,
                this.props.targetTypeId,
                loadTotal,
                this.state.activitiesLoadedCounter,
                this.props.entityVersionId,
                shouldLoadCommitActivities,
                this.state.filters.subSequentialIdentifier,
            );
        }
    }

    /**
     * Loads groups of activities from server.
     */
    async loadGroupedActivitiesFromServer(loadTotal, shouldLoadCommitActivities) {
        return this.props.tonkeanService.getActivitiesGroupsByEntityId(
            this.props.groupId,
            this.pageSize,
            this.state.filters.activityType?.activityType ? [this.state.filters.activityType?.activityType] : undefined,
            this.state.groupedActivitiesLoadedCounter,
            this.state.filters.actor ? [this.state.filters.actor] : undefined,
            this.state.filters.fromDate?.getTime(),
            this.state.filters.toDate?.getTime(),
            loadTotal,
            this.props.workflowVersionId,
            this.props.projectManager.project.id,
            shouldLoadCommitActivities,
            this.state.filters.subSequentialIdentifier,
        );
    }

    handleGroupToggle = () => {
        this.setState((state) => {
            return {
                isGrouped: !state.isGrouped,
            };
        });
    };

    /**
     * The main render function
     */
    render() {
        return (
            <>
                {this.state.isGrouped ? (
                    <WorkflowVersionsPaginatedList
                        title={`Changes (${this.state.totalActivities})`}
                        hasLoadMore={this.state.totalGroups > this.state.groupedActivitiesLoadedCounter}
                        loading={this.state.loadingInitialActivities || this.state.loadingMoreActivities}
                        loadingIndicator={[...Array.from({ length: Number.parseInt(this.pageSize) }).keys()].map(
                            (index) => (
                                <WorkflowVersionLoadingHistoryLog key={index} />
                            ),
                        )}
                        isEmpty={!this.state.groupedActivities?.length}
                        filters={this.state.filters}
                        filterType="history"
                        loadMoreButtonSecondary={this.props.loadMoreButtonSecondary}
                        onFiltersChange={this.onFiltersChanged}
                        onLoadMoreClick={() => this.loadMoreGroupedActivities()}
                        appendFilterDialogToBody={this.props.appendFilterDialogToBody ?? false}
                        tooltipDirection={this.props.tooltipDirection ?? 'left'}
                        onGroupToggleClick={this.handleGroupToggle}
                        isToggleActive={this.state.isGrouped}
                        emptyListMessage={this.state.emptyListMessage}
                        shouldLoadCommitActivities={this.props.shouldLoadCommitActivities}
                        workflowVersionId={this.props.workflowVersionId}
                    >
                        {this.state.groupedActivities?.map((activitiesGroup) => (
                            <WorkerAuditLogGroupItem
                                key={
                                    !!activitiesGroup.activityTypesForGroupEntity
                                        ? activitiesGroup.entityId.concat(activitiesGroup.activityTypesForGroupEntity)
                                        : activitiesGroup.entityId
                                }
                                activitiesGroup={activitiesGroup}
                                user={this.props.authenticationService.currentUser}
                                entityId={activitiesGroup.entityId}
                                groupId={this.props.groupId}
                                pageSize={this.pageSize}
                                filters={this.state.filters}
                                workflowVersionId={this.props.workflowVersionId}
                                tonkeanUtils={this.props.tonkeanUtils}
                                modalUtils={this.props.modalUtils}
                                tonkeanService={this.props.tonkeanService}
                                onCloseModal={this.props.onCloseModal}
                                loadingIndicator={[
                                    ...Array.from({ length: Math.min(activitiesGroup.count, this.pageSize) }).keys(),
                                ].map((index) => (
                                    <WorkflowVersionLoadingHistoryLog key={index} />
                                ))}
                                showBackToMarkForPublishButton={this.props.showBackToMarkForPublishButton}
                            />
                        ))}
                    </WorkflowVersionsPaginatedList>
                ) : (
                    <WorkflowVersionsPaginatedList
                        title={`Changes (${this.state.totalActivities})`}
                        hasLoadMore={this.state.shouldDisplayLoadMore}
                        loading={this.state.loadingInitialActivities || this.state.loadingMoreActivities}
                        loadingIndicator={[...Array.from({ length: Number.parseInt(this.pageSize) }).keys()].map(
                            (index) => (
                                <WorkflowVersionLoadingHistoryLog key={index} />
                            ),
                        )}
                        isEmpty={!this.state.activities?.length}
                        filters={this.state.filters}
                        filterType="history"
                        loadMoreButtonSecondary={this.props.loadMoreButtonSecondary}
                        onFiltersChange={this.onFiltersChanged}
                        onLoadMoreClick={() => this.loadMoreActivities()}
                        appendFilterDialogToBody={this.props.appendFilterDialogToBody ?? false}
                        tooltipDirection={this.props.tooltipDirection ?? 'left'}
                        onGroupToggleClick={!this.props.hideGroupByToggle && this.handleGroupToggle}
                        isToggleActive={this.state.isGrouped}
                        emptyListMessage={this.state.emptyListMessage}
                        hideGroupByToggle={this.state.hideGroupByToggle}
                    >
                        {this.state.activities?.map((activity) => (
                            <WorkerAuditLogItem
                                key={activity.id}
                                activity={activity}
                                oneLineMessage={this.props.oneLineMessage}
                                currentUser={this.props.authenticationService.currentUser}
                                tonkeanUtils={this.props.tonkeanUtils}
                                modalUtils={this.props.modalUtils}
                                consts={this.props.consts}
                                isNotInner
                            />
                        ))}
                    </WorkflowVersionsPaginatedList>
                )}
            </>
        );
    }
}
