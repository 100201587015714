import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import InitiativeViewFormMenu from './InitiativeViewFormMenu';
import { ReactComponent as EmptyFormsIcon } from '../../../../images/icons/empty-forms.svg';
import { ReactComponent as EmptyItemsIcon } from '../../../../images/icons/empty-items.svg';
import DisableContent from '../../../infrastructure/components/DisableContent';
import FormDescription from '../../WorkerOutlineModule/components/FormsWorkerOutline/FormDescription';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { TnkTracksEditor } from '@tonkean/angular-to-react-components';
import { H3, Spacer, StateMessage } from '@tonkean/infrastructure';
import type { Form, FormSubmission } from '@tonkean/tonkean-entities';
import { FormQuestionType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    forms: Form[];
    formSubmissions: FormSubmission[];
    initiative: any;
    viewOnlyMode?: boolean;
}

const Layout = styled.div`
    display: flex;
    height: 100%;
`;

const FormsSidebar = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    border-right: 1px solid ${Theme.colors.gray_400};
`;

const FormDescriptionRow = styled(Clickable)<{ selected: boolean }>`
    display: flex;
    height: 50px;
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${Theme.colors.gray_300};

    ${({ selected }) =>
        selected &&
        css`
            background-color: ${Theme.colors.gray_300};
        `};
`;

const TrackEditorContent = styled.div`
    display: flex;
    flex-grow: 7;
    flex-basis: 0;
    overflow-x: scroll;
    flex-direction: column;
    padding: 0 10px;

    tnk-tracks-editor {
        width: 100% !important;
        overflow-x: scroll;
        height: 100%;
    }
`;

const DisableContentStyled = styled(DisableContent)`
    flex-grow: 1;

    &::before {
        opacity: 0.3;
    }
`;

const InitiativeViewFormTabContent: React.FC<Props> = ({
    initiative,
    forms = [],
    formSubmissions = [],
    viewOnlyMode = false,
}) => {
    const [selectedForm, setSelectedForm] = useState<Form>();

    const trackHelper = useAngularService('trackHelper');
    const [{ called, loading: loadingInner, data, error, args }, getDuplicatedFormInitiatives] = useLazyAsyncMethod(
        trackHelper,
        'getDuplicatedFormInitiatives',
    );

    const formSubmissionsMap = useMemo(() => {
        const map: Record<string, FormSubmission> = {};

        formSubmissions.forEach((submission) => {
            map[submission.formId] = submission;
        });

        return map;
    }, [formSubmissions]);

    const orderedForms = useMemo(
        () =>
            forms.sort(
                (formA, formB) =>
                    (formSubmissionsMap[formB.id]?.submitted || 0) - (formSubmissionsMap[formA.id]?.submitted || 0),
            ),
        [formSubmissionsMap, forms],
    );

    useEffect(() => {
        if (orderedForms.length) {
            setSelectedForm(orderedForms[0]);
        }
    }, [orderedForms]);

    const formsToFieldsIdsMap = useMemo(() => {
        const map: Record<string, string[]> = {};

        forms.forEach((form) => {
            map[form.id] = form.definition?.fields?.map((item) => {
                // The description field has no dedicated column, it is coupled to the title column, so when there is the description field, show the title field instead (Which includes the description as well).
                if (item.fieldDefinitionIdentifier === 'TNK_DESCRIPTION') {
                    return 'TNK_TITLE';
                }
                return item.fieldDefinitionIdentifier;
            });

            if (form.formQuestionType === FormQuestionType.UPLOAD_FILES) {
                map[form.id] = ['TNK_TITLE'];
            }
        });

        return map;
    }, [forms]);

    useEffect(() => {
        if (
            initiative &&
            selectedForm?.workflowVersion &&
            selectedForm.formQuestionType !== FormQuestionType.UPDATE_FIELDS
        ) {
            getDuplicatedFormInitiatives(
                selectedForm.workflowVersion.id,
                selectedForm.id,
                initiative.id,
                false,
                false,
                false,
                true,
            );
        }
    }, [getDuplicatedFormInitiatives, initiative, selectedForm]);

    const formInitiatives = useMemo(() => {
        if (!selectedForm || !initiative) {
            return [];
        }

        if (selectedForm.formQuestionType === FormQuestionType.UPDATE_FIELDS) {
            return [initiative];
        } else {
            return loadingInner || !data?.initiatives ? [] : [...data.initiatives];
        }
    }, [data, initiative, loadingInner, selectedForm]);

    const noForms = useMemo(() => {
        return forms.length === 0;
    }, [forms.length]);

    const emptyTrackItems = useMemo(() => {
        return (
            selectedForm &&
            formInitiatives.length === 0 &&
            selectedForm.formQuestionType !== FormQuestionType.UPDATE_FIELDS &&
            !loadingInner
        );
    }, [formInitiatives.length, loadingInner, selectedForm]);

    const noFieldsForm = useMemo(() => {
        return selectedForm && formsToFieldsIdsMap[selectedForm.id]?.length === 0;
    }, [formsToFieldsIdsMap, selectedForm]);

    const isLoading = useMemo(() => {
        return selectedForm && selectedForm.formQuestionType !== FormQuestionType.UPDATE_FIELDS && loadingInner;
    }, [loadingInner, selectedForm]);

    if (noForms) {
        return <StateMessage icon={<EmptyFormsIcon />} title="No forms to show" />;
    }

    let trackEditorContent = <></>;
    if (selectedForm && initiative && !isLoading) {
        if (noFieldsForm) {
            trackEditorContent = <StateMessage icon={<EmptyFormsIcon />} title="Form definition is without fields" />;
        } else if (emptyTrackItems) {
            trackEditorContent = <StateMessage icon={<EmptyItemsIcon />} title="No Inner items to show" />;
        } else {
            trackEditorContent = (
                <>
                    <Spacer height={10} />
                    {selectedForm.formQuestionType === FormQuestionType.COLLECT_INNER_ITEMS &&
                        selectedForm.definition.formQuestion && (
                            <>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: selectedForm.definition.formQuestion,
                                    }}
                                />
                                <Spacer height={10} />
                            </>
                        )}

                    {selectedForm.formQuestionType === FormQuestionType.UPLOAD_FILES && (
                        <>
                            <H3>Upload Files Form - Uploaded files</H3>
                            <Spacer height={10} />
                        </>
                    )}
                    <TnkTracksEditor
                        key={selectedForm.id}
                        tracks={formInitiatives}
                        editorId={`initiative-view-formId-${selectedForm.id}`}
                        groupId={(selectedForm.group as any)?.id}
                        onlyGroup={(selectedForm.group as any)?.id}
                        workflowVersionId={selectedForm.workflowVersion?.id}
                        displayFieldsList={formsToFieldsIdsMap[selectedForm.id]}
                        createdInForm={selectedForm}
                        createdInFormId={selectedForm.id}
                        customFields={null}
                        hideTitle={formsToFieldsIdsMap[selectedForm.id]?.indexOf('TNK_TITLE') === -1}
                        parentItem={initiative}
                        viewOnlyMode={viewOnlyMode}
                        ignoreColumnVisibility
                        hideColumnQuickCreateForce
                        showHeader
                        hideBulkSelection
                        formViewInInitiative
                        disableNewItemTypeahead
                        noSubitems
                        disableGoToTrack
                        disableOwnerInvite
                        hideContextMenu
                        stickyHeader
                        hideHistory
                        hideAddNewForce
                    />
                </>
            );
        }
    }

    return forms.length ? (
        <Layout>
            <FormsSidebar>
                {orderedForms.map((form) => (
                    <FormDescriptionRow
                        key={form.id}
                        selected={selectedForm?.id === form.id}
                        onClick={() => setSelectedForm(form)}
                    >
                        <FormDescription
                            form={form}
                            formSubmission={formSubmissionsMap[form.id]?.submitted}
                            showEmptyTimeAgoWhenNoSubmission
                        />
                        {!viewOnlyMode && <InitiativeViewFormMenu form={form} />}
                    </FormDescriptionRow>
                ))}
            </FormsSidebar>

            <TrackEditorContent>
                {selectedForm && initiative && !isLoading && <>{trackEditorContent}</>}
            </TrackEditorContent>
        </Layout>
    ) : (
        <></>
    );
};

export default InitiativeViewFormTabContent;
