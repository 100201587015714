import template from './aggregateOnColumnField.template.html?angularjs';

/**
 * Definition of an aggregate on column field.
 */
angular.module('tonkean.app').component('tnkAggregateOnColumnField', {
    bindings: {
        createMode: '<',
        duplicateMode: '<',
        projectIntegration: '<',
        groupId: '<',
        workflowVersionId: '@',
        targetType: '<',
        noAdvance: '<',

        existingFieldDefinition: '<',

        fieldDefinitionName: '<',
        fieldDefinitionNameEdited: '<',

        aggregationOnColumnFeatureName: '@',
        columnFormulaFeatureName: '@',
        shouldShowCheckboxForInnerItemsHirerachy: '<',

        onDefinitionChange: '&',
        onFieldDefinitionNameChange: '&',

        onFormulaSelected: '<',
        onChange: '<',
    },
    template,
    controller: 'AggregateOnColumnFieldCtrl',
});
