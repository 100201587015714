import { InitiativeStatus } from '@tonkean/tonkean-entities';
import {
    getFieldConditionToApiDefinitionMap,
    MATCH_CONDITION_TAGS_SEPARATOR,
    TONKEAN_ENTITY_TYPE,
} from '@tonkean/constants';
import {
    getColumnsAndStatesOptionsForDefinitions,
    getNumberOfValuesAcceptableByCondition,
    getTonkeanEntityType,
} from '@tonkean/tonkean-utils';
import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function ConditionSelectorCtrl($scope, $rootScope, utils, customFieldsManager, projectManager, workflowVersionManager) {
    const ctrl = this;
    $scope.utils = utils;
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        // Component Bindings
        fieldType: ctrl.fieldType,
        value: ctrl.value,
        secondValue: ctrl.secondValue,
        expression: ctrl.expression,
        conditionApiName: ctrl.conditionApiName,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        considerAllGroups: ctrl.considerAllGroups,
        itemsSource: ctrl.itemsSource,
        field: ctrl.field,
        fieldName: ctrl.fieldName,
        possibleValues: ctrl.possibleValues,
        possibleValuesMap: ctrl.possibleValuesMap,
        disableSelections: ctrl.disableSelections,
        doNotEvaluatePreview: ctrl.doNotEvaluatePreview,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        appendToBody: ctrl.appendToBody,
        projectIntegration: ctrl.projectIntegration,
        useExpressionsForValue: ctrl.useExpressionsForValue,
        tabsFieldSelectorModNarrow: ctrl.tabsFieldSelectorModNarrow,
        tabsFieldSelectorModFixedWidth: ctrl.tabsFieldSelectorModFixedWidth,
        verticalMod: ctrl.verticalMod,
        allowedConditionsSet: ctrl.allowedConditionsSet,
        notAllowedConditionsSet: ctrl.notAllowedConditionsSet,
        showPeopleDirectories: ctrl.showPeopleDirectories,

        expressionSupportedConditions: [
            'Equals',
            'BasicEquals',
            'NotEquals',
            'BasicNotEquals',
            'LessThan',
            'GreaterThan',
            'Match',
            'NotMatch',
            'IsMemberOf',
        ],
        matchConditionExpressions: ['Match', 'NotMatch'],

        possibleValuesArrayRef: [], // A constant array reference for ui-select.
        selectedStatusValue: null,
        valueTags: [],
        matchConditionTagsSeparator: MATCH_CONDITION_TAGS_SEPARATOR,

        // Functions params
        onConditionSelectedParam: ctrl.onConditionSelectedParam,
        onValueChangedParam: ctrl.onValueChangedParam,

        // Consts
        conditions: null,
        typeToDefaultConditionApiName: {
            date: 'InThis',
        },
        defaultFieldType: 'String',
        datePeriodOptions: ['Week', 'Month', 'Quarter', 'Day'],

        // Data
        selectedCondition: null,
        valueSelectionType: null,
        relevantConditions: null,
        groupConditionsBy: 'typeFamily',
        modFixedWidth: ctrl.modFixedWidth,
        stateLabelToStateMap: null,
        // Date
        dateRangeValueOptions: [],

        changeConditionRangeOperators: {},
        changeConditionRangeOperatorsDisplayNames: [],
        changeConditionRangeOperatorsApiToDisplayMap: {},
        changeConditionRangeOperatorsDisplayToApiMap: {},

        matchBreadthLevelsApiNames: ['LOOSE', 'STRICT', 'EXACT'],
        matchBreadthLevelsApiNameToConfiguration: {
            LOOSE: {
                displayName: 'Loose',
                explanation: 'Will do a strict matching, only ignoring casing and separators',
            },
            STRICT: {
                displayName: 'Strict',
                explanation: 'Will try to match the entire phrase',
            },
            EXACT: {
                displayName: 'Exact',
                explanation: 'Will try to match exactly a word in the phrase',
            },
            AT_LEAST_ONE: {
                displayName: 'At least one',
                explanation: 'Will try to match the entire phrase',
            },
        },
        excludedTabSelectorSpecialFields: ctrl.excludedTabSelectorSpecialFields,
        includeTabSelectorSpecialFieldsForFeatures: ctrl.includeTabSelectorSpecialFieldsForFeatures,
        allowContains: ctrl.allowContains,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        // Contains is not allowed for conditions that their left hand side is an expression.
        // We use a feature flag to allow contains in the conditions that support expressions,
        // but that shouldn't be really used - as trace back population isn't supported for contains.
        if (
            $rootScope.features[projectManager.project.id].tonkean_feature_allow_contains_in_matched_entities_conditions
        ) {
            $scope.data.expressionSupportedConditions.push('Contains');
        }
        if (!$scope.data.useExpressionsForValue) {
            if (!$scope.data.notAllowedConditionsSet) {
                $scope.data.notAllowedConditionsSet = {};
            }
            $scope.data.notAllowedConditionsSet = { ...$scope.data.notAllowedConditionsSet, IsMemberOf: true };
        }

        initializesConsts();

        // Special handling for fields that have a dynamic list of options, and we need to reload it here, even if it has saved options, because
        // those might not include all of the options.
        if ($scope.data.fieldName === 'TNK_STAGE' && $scope.data.itemsSource === 'COLUMN') {
            const columnsAndStatesOptionsForDefinitions = getColumnsAndStatesOptionsForDefinitions(
                $scope.data.groupId,
                $scope.data.considerAllGroups,
                customFieldsManager.selectedColumnFieldsMap,
                customFieldsManager.selectedGlobalFieldsMap,
                projectManager.groupsMap,
                false,
                $scope.data.workflowVersionId,
                workflowVersionManager.workflowVersionIdToWorkflowVersionMap,
                workflowVersionManager,
            );
            $scope.data.possibleValues = columnsAndStatesOptionsForDefinitions.states;
        }
        if ($scope.data.fieldName === 'groupId' && $scope.data.itemsSource === 'COLUMN') {
            $scope.data.possibleValues = projectManager.groups.map((group) => group.id);
            $scope.data.possibleValuesMap = utils.createMapFromArray(projectManager.groups, 'id', null, 'name');
        }

        // Initialize current condition from given condition api name, if such was given.
        if ($scope.data.fieldType && ctrl.conditionApiName && $scope.data.conditionMap[ctrl.conditionApiName]) {
            $scope.data.selectedCondition = $scope.data.conditionMap[ctrl.conditionApiName];
        } else {
            if ($scope.data.fieldType) {
                const selectedCondition = utils.findFirst($scope.data.conditions, function (condition) {
                    return condition.allowedTypesSet[$scope.data.fieldType.toLowerCase()];
                });

                if (selectedCondition) {
                    $scope.data.selectedCondition = selectedCondition;
                }
            }

            if (!$scope.data.selectedCondition) {
                $scope.data.selectedCondition = $scope.data.conditionMap['BasicEquals'];
            }
        }

        $scope.data.dateRangeValueOptions = ['Days', 'Hours', 'Minutes'];
        $scope.data.stateLabelToStateMap = getCurrentStateLabelToStateMap();

        // Default condition selection
        $scope.onConditionSelection($scope.data.selectedCondition, false, true, true, true);

        // Initialize the value selection type.
        initValueSelectionType(true);

        // Calculates what are the relevant conditions
        reevaluateRelevantConditions();

        // Setting a flag that we've finished $onInit.
        ctrl.finishedOnInit = true;

        if ($scope.data.matchConditionExpressions.includes(ctrl.conditionApiName)) {
            $scope.data.valueTags = $scope.data.value.split($scope.data.matchConditionTagsSeparator).map((tagValue) => {
                return {
                    text: tagValue,
                };
            });
        }

        if (
            $scope.data.useExpressionsForValue &&
            $scope.data.expressionSupportedConditions.includes(ctrl.conditionApiName) &&
            $scope.data.value &&
            !$scope.data.expression
        ) {
            $scope.data.expression = {
                evaluatedExpression: $scope.data.value,
                originalExpression: $scope.data.value,
            };
        }
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object of the form.
     */
    ctrl.$onChanges = function (changes) {
        // We should only take $onChanges into consideration if we're after th $onInit.
        if (ctrl.finishedOnInit) {
            // Reset condition
            if (changes.resetCondition && changes.resetCondition.currentValue) {
                // Setting the condition to the default condition according to the fieldType
                if (
                    $scope.data.fieldType &&
                    $scope.data.typeToDefaultConditionApiName[$scope.data.fieldType.toLowerCase()]
                ) {
                    $scope.onConditionSelection(
                        $scope.data.conditionMap[
                            $scope.data.typeToDefaultConditionApiName[$scope.data.fieldType.toLowerCase()]
                        ],
                        true,
                        true,
                    );
                } else {
                    $scope.onConditionSelection($scope.data.conditionMap['Equals'], true, true);
                }
            }

            // Possible values
            if (changes.possibleValues) {
                $scope.data.possibleValues = ctrl.possibleValues;
            }

            // Possible values map
            if (changes.possibleValuesMap) {
                $scope.data.possibleValuesMap = ctrl.possibleValuesMap;
            }

            // Field
            if (changes.field) {
                $scope.data.field = ctrl.field;
            }

            // Field name
            if (changes.fieldName) {
                $scope.data.fieldName = ctrl.fieldName;
                reevaluateRelevantConditions();
            }

            // Field type
            if (changes.fieldType) {
                $scope.data.fieldType = ctrl.fieldType;

                // Default field type is String
                if (!$scope.data.fieldType) {
                    $scope.data.fieldType = $scope.data.defaultFieldType;
                }

                // Reevaluate what conditions are relevant for the field type.
                reevaluateRelevantConditions();

                // If current select condition is not in the relevant conditions, we select the first condition we have in the relevant conditions as the selected condition.
                const relevantConditionsSet = utils.arrayToSet(
                    $scope.data.relevantConditions.map((condition) => condition.apiName),
                    'apiName',
                );
                if (!relevantConditionsSet[$scope.data.selectedCondition.apiName]) {
                    $scope.data.selectedCondition = $scope.data.relevantConditions[0];
                    $scope.onConditionSelection($scope.data.selectedCondition, false, true);
                }

                // Since value selection type is based on field type, we should update it.
                initValueSelectionType();
            }

            // Value
            if (changes.value) {
                $scope.data.value = ctrl.value;
            }

            // Expression
            if (changes.expression) {
                $scope.data.expression = ctrl.expression;
            }

            // Second value
            if (changes.secondValue) {
                $scope.data.secondValue = ctrl.secondValue;

                if ($scope.data.selectedCondition.changeCondition) {
                    $scope.data.secondValue =
                        $scope.data.changeConditionRangeOperatorsApiToDisplayMap[$scope.data.secondValue];
                }
            }

            // Disable selections
            if (changes.disableSelections) {
                $scope.data.disableSelections = ctrl.disableSelections;
            }

            // Condition API name
            if (changes.conditionApiName) {
                $scope.data.conditionApiName = ctrl.conditionApiName;
                $scope.onConditionSelection($scope.data.conditionMap[ctrl.conditionApiName], true, true);
            }

            if (changes.workflowVersionId) {
                $scope.data.workflowVersionId = ctrl.workflowVersionId;
                $scope.data.stateLabelToStateMap = getCurrentStateLabelToStateMap();
            }
        }
    };

    $scope.onFieldExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.expression = expression;

        if (ctrl.onExpressionChanged) {
            ctrl.onExpressionChanged({
                expression,
                param: $scope.data.onValueChangedParam,
                shouldSaveLogic,
            });
        }
    };

    /**
     * Occurs once the condition is selected.
     */
    $scope.onConditionSelection = function (
        selectedCondition,
        resetValue,
        fireOnConditionSelected,
        doNotSaveChanges,
        isInit,
    ) {
        $scope.data.selectedCondition = selectedCondition;

        const previousTypeFamily = $scope.data.conditionMap?.[$scope.data.conditionApiName]?.typeFamily;
        const currentTypeFamily = selectedCondition.typeFamily;
        const isConditionsFromDifferentFamilyType = previousTypeFamily !== currentTypeFamily;

        // Reset the values if the conditions are from different category types and the flag is on
        if (isConditionsFromDifferentFamilyType) {
            $scope.data.value = null;
            $scope.data.secondValue = null;
            $scope.data.expression = null;
        }

        // If we need to reset and our conditions are from the same family, we want to check
        // The number of values that each condition take, if the previous condition accepted more values
        // We omit them
        if (!isConditionsFromDifferentFamilyType) {
            const previousConditionNumberOfValues = getNumberOfValuesAcceptableByCondition(
                $scope.data.conditionMap?.[$scope.data.conditionApiName],
            );
            const currentlyConditionNumberOfValues = getNumberOfValuesAcceptableByCondition(selectedCondition);

            const isPreviousUsedExpression = $scope.data.expressionSupportedConditions.includes(
                $scope.data.conditionApiName,
            );

            const currentlyUsedExpression = $scope.data.expressionSupportedConditions.includes(
                selectedCondition.apiName,
            );

            // If we should use expression for value we need to calculate when to reset the expression to null
            if ($scope.data.useExpressionsForValue) {
                if (currentlyUsedExpression === false && isPreviousUsedExpression === true) {
                    $scope.data.expression = null;
                } else if (currentlyUsedExpression === true && isPreviousUsedExpression === false) {
                    $scope.data.value = null;
                }
            }

            // Handle and calculate which values to reset
            if (previousConditionNumberOfValues === 2 && currentlyConditionNumberOfValues === 1) {
                $scope.data.secondValue = null;
            } else if (previousConditionNumberOfValues === 1 && currentlyConditionNumberOfValues === 0) {
                $scope.data.value = null;
            } else if (previousConditionNumberOfValues === 2 && currentlyConditionNumberOfValues === 0) {
                $scope.data.value = null;
                $scope.data.secondValue = null;
            }
        }

        if (!$scope.data.expressionSupportedConditions.includes(selectedCondition.apiName)) {
            $scope.onFieldExpressionChanged(null, !doNotSaveChanges);
        }

        if (selectedCondition.changeCondition) {
            $scope.data.secondValue = $scope.data.changeConditionRangeOperators.exactly.displayName;
            $scope.onSecondValueChanged($scope.data.secondValue, doNotSaveChanges);
        }

        if (fireOnConditionSelected && ctrl.onConditionSelected) {
            // Fire the onConditionSelected user callback
            ctrl.onConditionSelected({
                selectedCondition,
                isInit,
                param: $scope.data.onConditionSelectedParam,
            });
        }

        // Update the value selection type
        initValueSelectionType(doNotSaveChanges);

        // If the condition initialized a default value, we should fire the on value changed event.
        if (!utils.isNullOrUndefined($scope.data.value) || $scope.data.selectedCondition.noValueRequired) {
            $scope.onValueChanged($scope.data.value, doNotSaveChanges);
        }
        if (!utils.isNullOrUndefined($scope.data.secondValue) || $scope.data.selectedCondition.noValueRequired) {
            $scope.onSecondValueChanged($scope.data.secondValue, doNotSaveChanges);
        }

        if (!utils.isNullOrUndefined($scope.data.expression)) {
            $scope.onFieldExpressionChanged($scope.data.expression, !doNotSaveChanges);
        }

        // This is a migration code for new condition BasicEqual to work with the boolean drop down,
        // The values true/false changed to lower case so we need to support backward values on the existing condition Equals that had True/False
        if (
            (selectedCondition?.apiName === 'NotEquals' || selectedCondition?.apiName === 'Equals') &&
            $scope.data.valueSelectionType === 'Boolean' &&
            ($scope.data.value === 'True' || $scope.data.value === 'False')
        ) {
            $scope.data.value = $scope.data.value.toLowerCase();
        }
    };

    /**
     * Occurs once value tags are changed.
     */
    $scope.onValueTagsChanged = function () {
        const value = $scope.data.valueTags?.map((tag) => tag.text).join($scope.data.matchConditionTagsSeparator);
        $scope.onValueChanged(value);
    };

    /**
     * Occurs when value changes.
     */
    $scope.onValueChanged = function (value, doNotSaveChanges) {
        $scope.data.value = value;
        initValueSelectionType(doNotSaveChanges);
        if (ctrl.onValueChanged) {
            const valueObject = {
                value: $scope.data.value,
            };

            ctrl.onValueChanged({
                valueObject,
                param: $scope.data.onValueChangedParam,
                isInit: !ctrl.finishedOnInit,
                doNotSaveChanges,
            });
        }
    };

    /**
     * Occurs when second value changes.
     */
    $scope.onSecondValueChanged = function (value, doNotSaveChanges) {
        if (ctrl.onSecondValueChanged) {
            let dispatchedValue = value;
            if ($scope.data.selectedCondition.changeCondition) {
                dispatchedValue = $scope.data.changeConditionRangeOperatorsDisplayToApiMap[value];
            }

            const valueObject = {
                value: dispatchedValue,
            };

            ctrl.onSecondValueChanged({
                valueObject,
                param: $scope.data.onValueChangedParam,
                doNotSaveChanges,
            });
        }
    };

    /**
     * Returns true if given value is a number.
     */
    $scope.isNumber = function (value) {
        return !isNaN(value);
    };

    $scope.getPossibleValuesOptions = function (searchQuery) {
        // To avoid infinite $digest loops, we always work with the same reference.
        const fields = $scope.data.possibleValuesArrayRef;
        utils.resetArrayValues(fields, $scope.data.possibleValues);

        if (searchQuery) {
            // Filter the fields.
            const searchQueryLower = searchQuery.toLowerCase();
            const searchMatches = fields.filter(function (field) {
                return field.toLowerCase() === searchQueryLower;
            });

            // If the search query is not found in the given entities, add the search query as an option and return it.
            if (!searchMatches || !searchMatches.length) {
                fields.unshift(searchQuery);
            }
        }

        // The search query is contained in our field options or the custom input feature is not enabled. Just return the field options.
        return fields;
    };

    /**
     * Evaluates what conditions are relevant for display.
     */
    function reevaluateRelevantConditions() {
        if ($scope.data.fieldType) {
            switch ($scope.data.fieldType.toLowerCase()) {
                case 'datetime':
                case 'date':
                case 'number':
                case 'boolean':
                    $scope.data.relevantConditions = $scope.data.conditions.filter(function (condition) {
                        return condition.allowedTypesSet[$scope.data.fieldType.toLowerCase()];
                    });
                    $scope.data.groupConditionsBy = '';
                    break;

                default:
                    $scope.data.relevantConditions = $scope.data.conditions;
                    $scope.data.groupConditionsBy = 'typeFamily';
                    break;
            }
        }

        // Excluding conditions by their function.
        if ($scope.data.relevantConditions && $scope.data.relevantConditions.length) {
            $scope.data.relevantConditions = $scope.data.relevantConditions.filter(function (condition) {
                if (condition.apiName === 'Contains' || condition.apiName === 'NotContains') {
                    return !!$scope.data.allowContains;
                }
                if (
                    condition.excludeConditionInOptions &&
                    condition.excludeConditionInOptions(
                        $scope.data.fieldType,
                        $scope.data.valueSelectionType,
                        $scope.data.possibleValues,
                    )
                ) {
                    return false;
                }

                return true;
            });
        }

        const fieldNameIsAFieldDefinition =
            getTonkeanEntityType($scope.data.fieldName) === TONKEAN_ENTITY_TYPE.FIELD_DEFINITION;

        // Filtering by items sources and supportsOnlyFieldDefinitions filters.
        $scope.data.relevantConditions = $scope.data.relevantConditions.filter((condition) => {
            return (
                (!condition.supportedItemsSourcesSet || condition.supportedItemsSourcesSet[$scope.data.itemsSource]) &&
                (!condition.supportsOnlyFieldDefinitions || fieldNameIsAFieldDefinition)
            );
        });
    }

    /**
     * Initializes the filter's selection type for conditions choosing.
     */
    function initValueSelectionType(doNotSaveChanges) {
        $scope.data.valueSelectionType = 'String';

        // To be on the safe side, we don't do the switch if we don't have the field type.
        if (!$scope.data.fieldType) {
            return;
        }

        // To be on the safe side, we don't do the switch if we don't have the selected condition.
        if (!$scope.data.selectedCondition) {
            return;
        }

        switch ($scope.data.field?.fieldType?.toLowerCase() || $scope.data?.fieldType.toLowerCase()) {
            case 'status':
            case 'tickettype':
            case 'priority':
            case 'enumeration':
            case 'picklist':
            case 'list':
                // Even if it's a list, only if have possible values to show, we will display it as a list.
                if ($scope.data.possibleValues && $scope.data.possibleValues.length > 0) {
                    $scope.data.valueSelectionType = 'List';
                }
                break;

            case 'number':
                $scope.data.valueSelectionType = 'Number';
                break;

            case 'boolean':
                $scope.data.valueSelectionType = 'Boolean';
                break;

            case 'datetime':
            case 'date':
                $scope.data.valueSelectionType = 'DatePeriod';
                break;
        }

        switch ($scope.data.selectedCondition.apiName) {
            case 'IncreasedBy':
            case 'DecreasedBy':
                $scope.data.valueSelectionType = 'ChangeOperator';

                // Convert $scope.data.value to a decimal.
                $scope.data.value = Number.parseFloat($scope.data.value);

                break;

            case 'Past':
            case 'Next':
            case 'OlderThan':
            case 'FurtherThan':
                $scope.data.valueSelectionType = 'DateRange';

                // Convert $scope.data.value to an decimal
                $scope.data.value = Number.parseFloat($scope.data.value);

                if (!$scope.data.secondValue) {
                    $scope.data.secondValue = 'Days';
                    $scope.onSecondValueChanged($scope.data.secondValue, doNotSaveChanges);
                }

                break;
            case 'InThis':
                $scope.data.valueSelectionType = 'DatePeriod';
                $scope.data.value = $scope.data.datePeriodOptions.includes($scope.data.value)
                    ? $scope.data.value
                    : undefined;
                break;
            case 'Before':
            case 'After':
                $scope.data.valueSelectionType = 'DateExact';

                // If we already have a date in our hand keep it as date
                if (angular.isDate($scope.data.value)) {
                    return;
                }

                // Convert $scope.data.value from epoch time to Date
                if (!utils.isNullOrUndefined($scope.data.value) && $scope.isNumber($scope.data.value)) {
                    $scope.data.value = new Date(Number.parseInt($scope.data.value));
                } else {
                    $scope.data.value = new Date();
                }

                break;
        }

        $scope.data.useExpressionsForConditionValue = false;

        if ($scope.data.expressionSupportedConditions.includes($scope.data.selectedCondition.apiName)) {
            $scope.data.useExpressionsForConditionValue = $scope.data.useExpressionsForValue;
        }
    }

    /**
     * Initializes the possible conditions.
     */
    function initializesConsts() {
        $scope.data.conditionMap = getFieldConditionToApiDefinitionMap();
        $scope.data.conditions = utils
            .objValues($scope.data.conditionMap)
            .filter(
                (condition) =>
                    (!$scope.data.allowedConditionsSet || $scope.data.allowedConditionsSet[condition.apiName]) &&
                    (!$scope.data.notAllowedConditionsSet || !$scope.data.notAllowedConditionsSet[condition.apiName]),
            );
        $scope.data.relevantConditions = $scope.data.conditions;

        $scope.data.changeConditionRangeOperators = {
            exactly: {
                apiName: 'EXACTLY',
                displayName: 'Exactly',
            },
            lessThan: {
                apiName: 'LESS_THAN',
                displayName: 'Less than',
            },
            greaterThan: {
                apiName: 'GREATER_THAN',
                displayName: 'More than',
            },
        };

        $scope.data.changeConditionRangeOperatorsDisplayNames = utils
            .objValues($scope.data.changeConditionRangeOperators)
            .map((operator) => operator.displayName);
        $scope.data.changeConditionRangeOperatorsApiToDisplayMap = {};
        $scope.data.changeConditionRangeOperatorsDisplayToApiMap = {};

        for (const key in $scope.data.changeConditionRangeOperators) {
            if ($scope.data.changeConditionRangeOperators.hasOwnProperty(key)) {
                const changeConditionOperatorObject = $scope.data.changeConditionRangeOperators[key];
                $scope.data.changeConditionRangeOperatorsApiToDisplayMap[changeConditionOperatorObject.apiName] =
                    changeConditionOperatorObject.displayName;
                $scope.data.changeConditionRangeOperatorsDisplayToApiMap[changeConditionOperatorObject.displayName] =
                    changeConditionOperatorObject.apiName;
            }
        }
    }

    $scope.groupByStatusType = function (label) {
        return $scope.data.stateLabelToStateMap[label]?.type === InitiativeStatus.INTAKE
            ? 'Intake'
            : 'Triage And Coordination';
    };

    function getCurrentStateLabelToStateMap() {
        if ($scope.data.workflowVersionId) {
            const version = $scope.wvm.getCachedWorkflowVersion($scope.data.workflowVersionId);
            if (version) {
                return version.stateLabelToStateMap;
            }
        }
    }
}

angular.module('tonkean.app').controller('ConditionSelectorCtrl', lateConstructController(ConditionSelectorCtrl));
