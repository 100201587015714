import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import RequiredFieldsConfiguration from './RequiredFieldsConfiguration';
import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import Section from '../../components/Section';
import {
    type FieldsSelectorByFieldTypeGroupSummary,
    WidgetConfigurationSingleFieldSelector,
} from '../CommonWidgetConfiguration';
import { useGetWorkflowVersionFieldsAndBasicFields } from '../hooks';
import { heightConfig, HeightOptions } from '../ImageWidgetModule/components/HeightOptionsUtils';
import widgetGroupedFieldsUtils from '../QuestionWidget/WidgetGroupedFieldsUtils';

import {
    Field,
    Input,
    LoadingCircle,
    SimpleSelect,
    Toggle,
    useFormikField,
    useItemInterfaceContext,
} from '@tonkean/infrastructure';
import type { WorkflowVersion } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

interface Props {
    configuration: RichTextEditorInputWidgetConfiguration;
    workflowVersion: WorkflowVersion;
}

const StyledField = styled(Field)`
    justify-content: space-between;
`;

const StyledInput = styled(Input)`
    margin-top: 10px;
`;

const heightOptionsMap = Object.keys(heightConfig).map((key) => ({ label: heightConfig[key].label, value: key }));

const RichTextEditorInputWidgetSelector: React.FC<Props> = ({ configuration, workflowVersion }) => {
    const { initiative } = useItemInterfaceContext();
    const [heightInput, setHeightInput] = useState<string>();
    const { setValue } = useFormikField('configuration.height');

    const groups = useMemo<FieldsSelectorByFieldTypeGroupSummary[]>(() => {
        return widgetGroupedFieldsUtils(configuration);
    }, [configuration]);

    const { loading, allFieldDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(
        workflowVersion.id,
        ['TONKEAN_FORMS'],
        undefined,
        FieldType.LongString,
        true,
        false,
    );

    const filteredAllFieldDefinitions = useMemo(() => {
        return allFieldDefinitions.filter(
            (fieldDefinition) =>
                fieldDefinition.updateable && fieldDefinition.canUpdateFromUI && !fieldDefinition.idRelationField,
        );
    }, [allFieldDefinitions]);

    if (loading || !allFieldDefinitions) {
        return <LoadingCircle large centered />;
    }

    return (
        <Section>
            <ConfigurationSectionTitle>Input</ConfigurationSectionTitle>

            <Field label="Select field to store" dataAutomation="rich-text-editor-store-field" light>
                <WidgetConfigurationSingleFieldSelector
                    allFieldDefinitions={filteredAllFieldDefinitions}
                    groups={groups}
                    workflowVersion={workflowVersion}
                    workflowVersionType={workflowVersion.workflowVersionType}
                    exampleItem={initiative}
                />
            </Field>

            <Field label="Placeholder label" light inlineError>
                <Input
                    title="altText"
                    name="configuration.placeholderText"
                    autoComplete="off"
                    data-automation="widget-configuration-placeholder-text"
                />
            </Field>

            <StyledField label="Show as disabled" light showLabelInline inlineError>
                <Toggle
                    size={InputSize.SMALL}
                    name="configuration.isDisabled"
                    dataAutomation="rich-text-editor-toggle-is-disabled"
                />
            </StyledField>

            {!configuration.isDisabled && (
                <>
                    <StyledField label="Is required?" light showLabelInline inlineError>
                        <Toggle size={InputSize.SMALL} name="configuration.required" />
                    </StyledField>

                    {configuration.required && <RequiredFieldsConfiguration />}
                </>
            )}

            <Field label="Height" dataAutomation="rich-text-editor-height-select" light inlineError>
                <SimpleSelect
                    name="configuration.heightOptionName"
                    placeholder="Height"
                    options={heightOptionsMap}
                    onChange={(value, event) => {
                        if (value) {
                            setValue(heightConfig[value].value);
                            if (value !== HeightOptions.CUSTOM) setHeightInput('0');
                        } else {
                            setValue(undefined);
                        }
                    }}
                    thin
                />
                {configuration.heightOptionName === HeightOptions.CUSTOM && (
                    <StyledInput
                        placeholder="Height (px)"
                        type="number"
                        name="configuration.height"
                        autoComplete="off"
                        data-automation="rich-text-editor-height-input"
                        value={heightInput}
                        onChange={(event) => {
                            setHeightInput(event.target.value);
                        }}
                    />
                )}
            </Field>
        </Section>
    );
};
export default RichTextEditorInputWidgetSelector;
