import { useAngularService } from 'angulareact';
import React from 'react';

import ItemInterfaceActionsWidgetMatchedItemWithInterfaceActionSelector from './ItemInterfaceActionsWidgetMatchedItemWithInterfaceActionSelector';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import type { WorkflowVersion } from '@tonkean/tonkean-entities';
import { CustomTriggerType } from '@tonkean/tonkean-entities';

interface Props {
    workflowVersion: WorkflowVersion;
}

const ItemInterfaceActionsWidgetActionSelector: React.FC<Props> = ({ workflowVersion }) => {
    const formManager = useAngularService('formManager');
    const {
        data: forms,
        loading: loadingForms,
        error: errorForms,
    } = useAsyncMethod(formManager, 'getAllWorkerForm', workflowVersion.id);

    const customTriggerManager = useAngularService('customTriggerManager');
    const {
        data: customTriggers,
        loading: loadingCustomTriggers,
        error: errorCustomTriggers,
    } = useAsyncMethod(
        customTriggerManager,
        'getCustomTriggersOfTypeInWorkflowVersion',
        workflowVersion.id,
        CustomTriggerType.AUTONOMOUS,
    );

    return (
        <>
            <ItemInterfaceActionsWidgetMatchedItemWithInterfaceActionSelector
                name="configuration.actions"
                forms={forms}
                errorForms={errorForms}
                customTriggers={customTriggers}
                loadingForms={loadingForms}
                loadingCustomTriggers={loadingCustomTriggers}
                errorCustomTriggers={errorCustomTriggers}
                groupId={workflowVersion.groupId}
                projectId={workflowVersion.project.id}
            />
        </>
    );
};
export default ItemInterfaceActionsWidgetActionSelector;
