<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Communication Tool</h4>
    </div>
</div>
<div class="modal-body">
    <form novalidate>
        <div ng-form="data.integrationForm">
            <div>
                <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
                <div ng-hide="connecting || integration || data.createMicrosoftTeamUserIntegration" class="text-center">
                    <button
                        type="button"
                        class="btn btn-secondary btn-lg"
                        data-automation="modal-template-team-authenticate"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="authorize()"
                    >
                        Authenticate with {{ currentIntegration.displayName }}
                    </button>
                </div>
                <div ng-if="data.createMicrosoftTeamUserIntegration" class="flex mod-align-center mod-justify-center">
                    <div>Creating Teams User Integration</div>
                    <i class="loading-medium margin-left"></i>
                </div>
                <div class="text-center" ng-show="connecting">
                    <h4>
                        <span class="loading"></span>
                        Connecting to {{ currentIntegration.displayName }}
                    </h4>
                </div>
            </div>
        </div>
    </form>

    <!--  Show here all the teams the user has -->
    <div
        ng-show="
            integration && !loading && !data.checkIfBotIsConnectedToTeamLoading && !data.selectedTeam && !data.isEdit
        "
    >
        <!--        Title + refresh button-->
        <div class="flex margin-bottom-xs">
            <div class="common-title-sm">Please select the team you wish to connect:</div>

            <div class="flex-vmiddle pointer common-color-primary margin-left margin-top-xxs" ng-click="loadAllTeams()">
                <span
                    ng-show="!data.loading"
                    class="svg-icon-md pointer pull-right"
                    uib-tooltip="Refresh"
                    tooltip-placement="bottom-right"
                >
                    <tnk-icon src="/images/icons/refresh.svg"></tnk-icon>
                </span>

                <div class="margin-left-xxs margin-top-xxxs">Refresh</div>
            </div>
        </div>

        <!-- Step 1 Team selection -->
        <div class="integration-instance-selection-box">
            <!-- Loading -->
            <div ng-if="data.teamsOptionsLoading" class="text-center margin-top-lg">
                <i class="loading-medium"></i>
            </div>

            <!-- Teams -->
            <div ng-if="!data.teamsOptionsLoading" class="common-height-full">
                <div ng-if="data.teamsOptions && data.teamsOptions.length">
                    <div
                        ng-click="onTeamClicked(selection)"
                        ng-repeat="selection in data.teamsOptions"
                        class="list-item btn-default pointer common-size-xxs flex-vmiddle"
                    >
                        <div class="flex-grow" data-automation="modal-template-team-channel">
                            <strong>{{ selection.displayName }}</strong>
                            <div class="common-size-xxxxs common-color-grey">
                                <span>{{ selection.description }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Bot Authorization   Step 2-->
    <form novalidate>
        <div ng-if="data.selectedTeam">
            <!-- Setup instructions -->
            <div class="margin-bottom-md common-size-xxxs common-color-dark-grey margin-left-xlg margin-top">
                <div>Please do the following steps to add tonkean to your team:</div>
                <div>
                    <ol class="padding-left-md">
                        <li class="margin-top-xs">Clicking on the button will open the Microsoft gallery</li>
                        <li class="margin-top-xs">Click on the "Get it now" button</li>
                        <li class="margin-top-xs">The tab will be redirected to the Teams app</li>
                        <li class="margin-top-xs">
                            Click on the chevron right to the add button and click on "add to a team"
                        </li>
                        <li class="margin-top-xs">
                            Select
                            <span class="common-bold">"{{ data.selectedTeam.displayName }}"</span>
                            and set up a bot
                        </li>
                    </ol>
                </div>
            </div>

            <!-- Connect button / connecting state -->
            <div class="margin-top-lg margin-bottom-lg">
                <!-- Connect button -->
                <div class="flex mod-align-center mod-justify-center">
                    <button
                        type="button"
                        ng-disabled="data.checkIfBotIsConnectedToTeamLoading"
                        ng-if="!connectionTabOpened"
                        class="btn btn-primary btn-lg"
                        ng-click="connect()"
                    >
                        Add Tonkean To
                        <span class="common-bold">"{{ data.selectedTeam.displayName }}"</span>
                    </button>
                    <!-- I did it button -->
                    <div class="flex-vmiddle">
                        <button
                            type="button"
                            class="btn btn-primary btn-lg microsoft-teams-i-did-it-btn margin-right-xs"
                            ng-if="connectionTabOpened"
                            ng-click="checkIfBotConnectedToSelectedTeam()"
                        >
                            I did it!
                        </button>
                    </div>
                </div>

                <div class="flex mod-align-center mod-justify-center">
                    <!-- Error message for when the user says "I did it" but we can't find the integration -->
                    <div
                        ng-if="data.connectBotToTeamAndTryAgain && data.selectedTeam"
                        class="common-color-danger common-size-xxxs padding-bottom-lg margin-top"
                    >
                        It seems like team
                        <span class="common-bold">"{{ data.selectedTeam.displayName }}"</span>
                        is not connected to Tonkean.
                        <br />
                        Please follow the steps above and try again.
                    </div>
                </div>
                <div class="flex mod-align-center mod-justify-center">
                    <div ng-if="data.checkIfBotIsConnectedToTeamLoading" class="flex-vmiddle">
                        <div class="margin-right">
                            Checking if the Tonkean bot is connected to team
                            <span class="common-bold">"{{ data.selectedTeam.displayName }}"</span>
                        </div>
                        <div class="loading-medium"></div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading && data.isEdit">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as-
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
<footer class="modal-footer">
    <div class="flex-vmiddle mod-justify-space">
        <div class="flex-vmiddle">
            <button
                class="inline-button teams-back-button"
                ng-click="changeTeam()"
                ng-if="data.selectedTeam"
                type="button"
            >
                <i class="fa fa-caret-left"></i>
                Back
            </button>
        </div>

        <button
            class="btn btn-default"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Cancel Integration"
            analytics-label="{{ currentIntegration.name }}"
            analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
            type="button"
            ng-click="onCancel()"
        >
            Cancel
        </button>
    </div>
</footer>
