import { initiativeTitle } from '@tonkean/utils';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { getStateError } from '@tonkean/utils';
import { lateConstructController } from '@tonkean/angular-components';
import { removeFalsy } from '@tonkean/utils';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function WorkerOutlineCtrl(
    $rootScope,
    $scope,
    $timeout,
    $state,
    $q,
    authenticationService,
    projectManager,
    utils,
    consts,
    customTriggerManager,
    customFieldsManager,
    tonkeanService,
    modalUtils,
    modal,
    groupManager,
    groupPermissions,
    workflowVersionManager,
    syncConfigCacheManager,
    formManager,
    integrationHelper,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.authenticationService = authenticationService;
    $scope.ctm = customTriggerManager;
    $scope.utils = utils;
    $scope.groupManager = groupManager;
    $scope.formManager = formManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;
    $scope.initiativeTitle = initiativeTitle;
    $scope.indicationState = {};
    $scope.$state = $state;
    let selectedNode = null;
    let selectedNodeParent = null;
    let selectedNodeGrandParent = null;
    const logicBlockTypesMap = consts.getLogicBlockTypesMap();

    $scope.data = {
        nodeTree: ctrl.nodeTree,
        syncObject: ctrl.syncObject,
        groupId: ctrl.groupId,
        isLoading: ctrl.isLoading,

        workflowVersion: null,
        workflowVersionId: ctrl.workflowVersionId,

        canModifyBot: ctrl.canModifyBot,

        titleLabel: ctrl.titleLabel,
        selectDataSourceNode: ctrl.selectDataSourceNode, // A callback function.
        closePanel: ctrl.closePanel, // A callback function.
        showExampleItemSelector: ctrl.showExampleItemSelector,
        workflowFolderProjectIntegrationsAccess: ctrl.workflowFolderProjectIntegrationsAccess,
        requestLogicsValidation: ctrl.requestLogicsValidation,
        invalidLogics: ctrl.invalidLogics,
        logicBlockTypesMap,

        reloadFieldsConfiguration: false,
        reloadStatesConfiguration: false,

        actionItemsLogics: [],

        // Internal data.
        newNodeDisplayName: '',
        createOptionsMenuIsOpen: false,

        // Loading states.
        savingNewNode: false,
        runningCollect: {},

        // Tabs
        isOutlineSidePaneOpen: ctrl.isOutlineOpen,
        selectedTab: null,
        selectedTabKey: ctrl.selectedTabKey,
        selectedFieldDefinitionForDependencies: null,
        tabs: removeFalsy([
            {
                key: 'item-details',
                name: 'Item Details',
                iconClass: 'worker-outline-tab-icon-item-details',
            },
            {
                key: 'items',
                name: 'Items',
                iconClass: 'worker-outline-tab-icon-items',
            },
            {
                key: 'fields',
                name: 'Fields',
                iconClass: 'worker-outline-tab-icon-fields',
            },
            {
                key: 'triggers',
                name: 'Triggers',
                iconClass: 'worker-outline-tab-icon-triggers',
            },
            {
                key: 'statuses',
                name: 'Statuses',
                iconClass: 'worker-outline-tab-icon-statuses',
            },
            {
                key: 'forms',
                name: 'Forms',
                iconClass: 'worker-outline-tab-icon-forms',
            },
            {
                key: 'interfaces',
                name: 'Interfaces',
                iconClass: 'worker-outline-tab-icon-interfaces',
            },
            {
                key: 'process-mappers',
                name: 'Process Maps',
                iconClass: 'worker-outline-tab-icon-process-mappers',
            },
            $rootScope.features.currentProject.tonkean_feature_enable_module_contract && {
                key: 'contract',
                name: 'Contract',
                iconClass: 'worker-outline-tab-icon-contracts',
            },
            {
                isSpacer: true,
            },
            {
                key: 'connectors',
                name: 'Data Source Connectors',
                iconClass: 'worker-outline-tab-icon-data-sources',
                indicationClass: 'tnk-side-pane-tabs-indicator-warning',
                indicationState: $scope.indicationState,
                indicationStateKey: 'hasBrokenIntegration',
            },
            {
                key: 'versions',
                name: 'Versions',
                iconClass: 'worker-outline-tab-icon-versions',
                indicationClass: 'tnk-side-pane-tabs-indicator-warning',
                indicationState: $scope.indicationState,
                indicationStateKey: 'hasWorkflowVersionChanges',
                showIndication: showWorkflowVersionChangesIndication,
            },
            {
                key: 'auto_checkins',
                name: 'Auto Check-ins',
                iconClass: 'worker-outline-tab-icon-auto-checkins',
            },
        ]),

        editFormId: ctrl.editFormId,
        formType: ctrl.formType,

        onOutlineFieldSelected: ctrl.onOutlineFieldSelected,
        fieldEditorHasPendingChanges: ctrl.fieldEditorHasPendingChanges,
        targetTypeId: ctrl.targetTypeId,
        groupByToggleDefault: ctrl.groupByToggleDefault,
        features: projectManager.project.features,

        projectIntegrationSettingsPageName: ProjectIntegrationPageMenuItemType.VIEW_DATA,
    };

    $scope.init = function () {
        updateWorkflowVersion();
        reloadActionItems();
        initializeStateObjectForAllProjectIntegrations();
        loadForms();

        $scope.data.hasPermissionsToEditWorker = groupPermissions.hasPermissionsToEditWorker(
            projectManager.groupsMap[$scope.data.groupId],
        );

        // checking if any of the group projectIntegrations are broken
        for (let i = 0; i < $scope.pm.groupsMap[$scope.data.groupId].groupProjectIntegrations.length; i++) {
            const projectIntegration = $scope.pm.groupsMap[$scope.data.groupId].groupProjectIntegrations[i];
            if (projectIntegration.valid === false || projectIntegration.disabled) {
                $scope.indicationState.hasBrokenIntegration = true;
                break;
            }
        }

        $scope.data.calculatedPlusButtonDropdownOptions = getCalculatedPlusButtonDropdownOptions();
    };

    $scope.$watch('$scope.data.createOptionsMenuIsOpen', function () {
        $scope.data.calculatedPlusButtonDropdownOptions = getCalculatedPlusButtonDropdownOptions();
    });
    $scope.$watch('$scope.data.selectedLogic.node.customTriggerType', function () {
        $scope.data.calculatedPlusButtonDropdownOptions = getCalculatedPlusButtonDropdownOptions();
    });
    $scope.$watch('$scope.data.selectedLogic.node.customTriggerSecondaryType', function () {
        $scope.data.calculatedPlusButtonDropdownOptions = getCalculatedPlusButtonDropdownOptions();
    });

    function getCalculatedPlusButtonDropdownOptions() {
        const customTriggerType = $scope.data.selectedLogic?.node?.customTriggerType;
        const customTriggerSecondaryType = $scope.data.selectedLogic?.node?.customTriggerSecondaryType;
        // If this logic has create after specific options we want to open a dropdown on plus button click
        if (customTriggerType && customTriggerSecondaryType) {
            const customTriggerTypeLogic =
                $scope.data.logicBlockTypesMap[customTriggerType]?.[customTriggerSecondaryType];

            if (customTriggerTypeLogic && customTriggerTypeLogic.createAfterOptions) {
                return customTriggerTypeLogic
                    .createAfterOptions($scope.data.features)
                    .filter((option) => $scope.createAfterOptionFilter(option));
            }
        }
        return undefined;
    }

    function showWorkflowVersionChangesIndication() {
        if (!$scope.data.workflowVersion) {
            updateWorkflowVersion();
        }

        return (
            $scope.data.workflowVersion &&
            $scope.data.workflowVersion.workflowVersionType === 'DRAFT' &&
            $scope.wvm.workflowVersionIdToChangesCounterMap[$scope.data.workflowVersionId] > 0
        );
    }

    $scope.filterGroupRepresentativeProjectIntegration = function (projectIntegration) {
        return projectIntegration.representativeGroupId === undefined;
    };

    $scope.filterCustomTriggers = function (fieldTextFilter, fieldForDependencies) {
        if (fieldTextFilter !== null) {
            $scope.data.filterText = fieldTextFilter;
            $scope.data.counterMap = {};
            if ($scope.data.nodeTree && $scope.data.nodeTree.impacts) {
                for (let i = 0; i < $scope.data.nodeTree.impacts.length; i++) {
                    $scope.data.nodeTree.impacts[i].node.displayNameHtml =
                        $scope.data.nodeTree.impacts[i].node.displayName;
                    $scope.data.nodeTree.impacts[i].isDependent = false;
                    $scope.data.counterMap[$scope.data.nodeTree.impacts[i].node.id] = {
                        filtered: $scope.ctm.howManyInnerLogicMatchCondition(
                            $scope.data.nodeTree.impacts[i],
                            filterByTextPredicate,
                        ),
                        total: $scope.ctm.howManyInnerLogicMatchCondition($scope.data.nodeTree.impacts[i], () => true),
                    };
                }
            }
        }

        loadFieldDependencies(fieldForDependencies);
    };

    function loadFieldDependencies(fieldDefinition) {
        if (fieldDefinition) {
            tonkeanService
                .getFieldDefinitionDependencies($scope.data.workflowVersionId, fieldDefinition.id)
                .then((dependencies) => {
                    $scope.data.dependentCustomTriggersIds = dependencies.dependentCustomTriggers.map(
                        (field) => field.id,
                    );
                    for (let i = 0; i < $scope.data.nodeTree.impacts.length; i++) {
                        if ($scope.data.dependentCustomTriggersIds.includes($scope.data.nodeTree.impacts[i].node.id)) {
                            $scope.data.nodeTree.impacts[i].isDependent = true;
                        }
                    }
                });
        } else {
            $scope.data.dependentCustomTriggersIds = [];
        }
    }

    function filterByTextPredicate(node) {
        if (!(node.node && node.node.displayName)) {
            return false;
        }
        return node.node.displayName.toLowerCase().includes($scope.data.filterText.toLowerCase());
    }

    $scope.isIntegrationConnectedByCurrentUser = function (projectIntegration) {
        return projectIntegration.creator.id === authenticationService.currentUser.id;
    };

    $scope.isIntegrationConnectedByOtherUser = function (projectIntegration) {
        return projectIntegration.creator.id !== authenticationService.currentUser.id;
    };

    function initializeStateObjectForAllProjectIntegrations() {
        $scope.projectIntegrationToStateMap = {};

        for (let i = 0; i < $scope.pm.project.integrations.length; i++) {
            const projectIntegration = $scope.pm.project.integrations[i];

            if (!$scope.projectIntegrationToStateMap[projectIntegration.id]) {
                $scope.projectIntegrationToStateMap[projectIntegration.id] = {
                    state: {},
                };

                $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                    projectIntegration.integration.integrationType.toLowerCase()
                ] = {
                    integrations: [projectIntegration],
                };
            } else {
                $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                    projectIntegration.integration.integrationType.toLowerCase()
                ].integrations[0].displayName = projectIntegration.displayName;
                $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                    projectIntegration.integration.integrationType.toLowerCase()
                ].integrations[0].disabled = projectIntegration.disabled;
            }
        }
    }

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.workflowVersionId && $scope.data.workflowVersionId !== ctrl.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            $scope.init();
        }

        if (changesObj.reloadConfiguration?.currentValue) {
            $scope.init();

            $scope.data.reloadFieldsConfiguration = true;
            $timeout(() => {
                $scope.data.reloadFieldsConfiguration = false;
            });

            $scope.data.reloadStatesConfiguration = true;
            $timeout(() => {
                $scope.data.reloadStatesConfiguration = false;
            });
        }

        if (changesObj.nodeTree) {
            $scope.data.nodeTree = changesObj.nodeTree.currentValue;

            reloadActionItems();
        }

        if (changesObj.groupId) {
            $scope.data.groupId = changesObj.groupId.currentValue;
        }

        if (changesObj.titleLabel) {
            $scope.data.titleLabel = changesObj.titleLabel.currentValue;
        }

        if (changesObj.selectedTabKey) {
            const tab = changesObj.selectedTabKey
                ? utils.findFirst($scope.data.tabs, (tab) => tab.key === changesObj.selectedTabKey.currentValue)
                : $scope.data.selectedTab;

            $scope.selectTab(tab, false);
        }

        if (changesObj.isOutlineOpen) {
            $scope.setOutlineIsOpen(changesObj.isOutlineOpen.currentValue, false);
        }

        if (changesObj.editFormId) {
            $scope.data.editFormId = changesObj.editFormId.currentValue;
        }
        if (changesObj.isLoading) {
            $scope.data.isLoading = changesObj.isLoading.currentValue;
        }
        if (changesObj.formType) {
            $scope.data.formType = changesObj.formType.currentValue;
        }
        if (changesObj.fieldEditorHasPendingChanges) {
            $scope.data.fieldEditorHasPendingChanges = changesObj.fieldEditorHasPendingChanges.currentValue;
        }
        if (changesObj.workflowFolderProjectIntegrationsAccess) {
            $scope.data.workflowFolderProjectIntegrationsAccess = ctrl.workflowFolderProjectIntegrationsAccess;
        }
        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = ctrl.invalidLogics;
        }
        if (changesObj.requestLogicsValidation) {
            $scope.data.requestLogicsValidation = ctrl.requestLogicsValidation;
        }
    };

    /**
     * Occurs once an event of customTriggerTypeUpdated is fired.
     */
    $rootScope.$on('customTriggerUpdated', (e, customTrigger) => {
        if (
            !customTrigger ||
            !customTrigger.customTriggerType ||
            !logicBlockTypesMap[customTrigger.customTriggerType][customTrigger.customTriggerSecondaryType]
                ?.creatingActionItems
        ) {
            // ignore
            return;
        }

        reloadActionItems();
    });

    $scope.openTriggerOutline = function (fieldDefinitionForDependenciesFilterOptional) {
        const triggersTab = $scope.data.tabs.find((tab) => tab.key === 'triggers');
        if (!triggersTab) {
            console.error('No triggers tab found, cant open triggers outline.');
            return;
        }

        // Select the triggers tab
        $scope.data.selectedTab = triggersTab;

        // Load dependent triggers
        loadFieldDependencies(fieldDefinitionForDependenciesFilterOptional);

        // Load field for the filter dropdown list
        $scope.data.fieldDefinitions = angular.copy(
            customFieldsManager.selectedColumnFieldsMap[$scope.data.workflowVersionId].filter(
                (fieldDefinition) => !fieldDefinition.systemUtilized,
            ),
        );
        if (fieldDefinitionForDependenciesFilterOptional) {
            $scope.data.selectedFieldDefinitionForDependencies = fieldDefinitionForDependenciesFilterOptional;
            $scope.filterCustomTriggers('', fieldDefinitionForDependenciesFilterOptional);
        }
    };

    /**
     * Occurs once an event of customTriggerTypeUpdated is fired.
     */
    $rootScope.$on('deletedCustomTrigger', (e, customTriggerId) => {
        if (customTriggerId) {
            reloadActionItems();
        }
    });

    $scope.onIntegrationSaved = function () {
        initializeStateObjectForAllProjectIntegrations();
    };

    $scope.openRightSidepane = function (key) {
        $rootScope.$broadcast('openEditorRightPane', key);
    };

    $scope.selectTab = function (tab, triggerCallbackParam = true) {
        return openDiscardFieldChangesModal().then(() => {
            $scope.data.selectedTab = tab;

            if (ctrl.onTabSelected && triggerCallbackParam) {
                ctrl.onTabSelected({ tab });
            }

            if (tab && tab.key === 'triggers') {
                $scope.data.fieldDefinitions = angular.copy(
                    customFieldsManager.selectedColumnFieldsMap[$scope.data.workflowVersionId].filter(
                        (fieldDefinition) => !fieldDefinition.systemUtilized,
                    ),
                );
            } else {
                clearTriggerFilters();
            }

            if (tab && tab.key !== 'forms') {
                ctrl.onOutlineFormSelected(null, null);
            }
        });
    };

    function openDiscardFieldChangesModal() {
        if (!ctrl.fieldEditorHasPendingChanges) {
            return $q.resolve();
        }

        $scope.mboxData = {
            title: 'You have unsaved changes in your Field',
            body: 'Are you sure you want to discard those changes?',
            isWarn: true,
            okLabel: 'Discard',
            cancelLabel: 'Cancel',
        };

        return modal.openMessageBox({
            scope: $scope,
            size: 'md',
            windowClass: 'mod-danger',
        }).result;
    }

    $scope.setOutlineIsOpen = function (isOpen, triggerCallbackParam = true) {
        $scope.data.isOutlineSidePaneOpen = isOpen;

        // Clear filter setting before closing.
        if (!isOpen && $scope.data.selectedTab && $scope.data.selectedTab.key === 'triggers') {
            clearTriggerFilters();
        }

        if (triggerCallbackParam) {
            ctrl.onIsOpenChanged({ isOpen });
        }
    };

    function clearTriggerFilters() {
        $scope.data.selectedFieldDefinitionForDependencies = null;
        $scope.data.dependentCustomTriggersIds = [];
        if ($scope.data.nodeTree && $scope.data.nodeTree.impacts && $scope.data.nodeTree.impacts.length) {
            for (let i = 0; i < $scope.data.nodeTree.impacts.length; i++) {
                $scope.data.nodeTree.impacts[i].isDependent = false;
            }
        }
    }

    /**
     * Making sure the option can be created
     */
    $scope.createAfterOptionFilter = function (option) {
        return (
            !option.canCreate ||
            option.canCreate(
                selectedNode.impacts,
                selectedNode,
                formManager,
                customTriggerManager,
                projectManager.project.features,
            )
        );
    };

    /**
     * Opens the modal for editing an id relation field definition.
     */
    $scope.editIdRelationField = function (fieldDefinition) {
        $rootScope.$broadcast('createNewField', [
            $scope.data.groupId,
            'COLUMN',
            fieldDefinition.type,
            fieldDefinition.projectIntegration,
            false,
            false,
            fieldDefinition,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.data.workflowVersionId,
        ]);
    };

    /**
     * A callback passed to the workerOutlineItem so it can call ctrl.selectNode passed from workerEditor.
     */
    $scope.selectNode = function (node) {
        ctrl.selectNode({ node });
    };

    $scope.selectWorkflowItem = function (customTrigger) {
        $scope.data.selectedWorkflowItem = customTrigger;
        if (customTrigger) {
            findNode($scope.data.nodeTree, customTrigger.id, function (node) {
                $scope.data.selectedWorkflowItemNode = node;
                $scope.selectNode(node);
            });
        } else {
            $scope.data.selectedWorkflowItemNode = null;
        }
    };

    /**
     * A callback passed to the workerOutlineItem so it can call ctrl.createNewNode passed from workerEditor.
     */
    $scope.createNewNode = function (parentNode, displayName, analyticsLabel) {
        analyticsWrapper.track('Create new logic', { category: 'Worker workflow outline', label: analyticsLabel });

        let enricher = null;

        if (
            parentNode &&
            logicBlockTypesMap[parentNode.node.customTriggerType]?.[
                parentNode.node.customTriggerSecondaryType
            ]?.createAfterOptions?.(projectManager.project.features)
        ) {
            const createAfterOptions = logicBlockTypesMap[parentNode.node.customTriggerType][
                parentNode.node.customTriggerSecondaryType
            ]
                .createAfterOptions?.(projectManager.project.features)
                .filter((option) => $scope.createAfterOptionFilter(option));

            if (createAfterOptions.length > 1) {
                $scope.data.createOptionsMenuIsOpen = true;
                return;
            }

            enricher = createAfterOptions.length === 1 ? createAfterOptions[0].triggerParamsEnricher : null;
        }

        return ctrl.createNewNode({ parentNode, displayName, enricher });
    };

    /**
     * This creates a new node according to the template it gets
     */
    $scope.createNewNodeAccordingToTemplate = function (template) {
        return ctrl.createNewNode({
            parentNode: selectedNode || $scope.data.nodeTree,
            displayName: null,
            enricher: template.triggerParamsEnricher,
        });
    };

    /**
     * This function either uses the given nodeBlock and nodeParent, or the currently selected node and node parent,
     * to tab IN a logic node (if possible).
     */
    $scope.tabNodeIn = function (nodeBlock, nodeParent) {
        analyticsWrapper.track('Tab logic in', { category: 'Worker workflow outline' });

        if (nodeBlock) {
            // If we've got a node block, use it.
            customTriggerManager.tabInComponentsGraphLogicInWorkflowVersion($scope.data.groupId, nodeBlock, nodeParent);
        } else {
            // If we haven't, use what we know about the current node and parent.
            customTriggerManager.tabInComponentsGraphLogicInWorkflowVersion(
                $scope.data.groupId,
                selectedNode,
                selectedNodeParent,
            );
        }
    };

    /**
     * This function either uses the given nodeBlock and nodeParent, or the currently selected node and node parent,
     * to tab OUT a logic node (if possible).
     */
    $scope.tabNodeOut = function (nodeBlock, nodeParent, nodeGrandParent) {
        analyticsWrapper.track('Tab logic out', { category: 'Worker workflow outline' });

        if (nodeBlock) {
            // If we've got a node block, use it.
            customTriggerManager.tabOutComponentsGraphLogicInWorkflowVersion(
                $scope.data.groupId,
                nodeBlock,
                nodeParent,
                nodeGrandParent,
            );
        } else {
            // If we haven't, use what we know about the current node and parent.
            customTriggerManager.tabOutComponentsGraphLogicInWorkflowVersion(
                $scope.data.groupId,
                selectedNode,
                selectedNodeParent,
                selectedNodeGrandParent,
            );
        }
    };

    /**
     * Key down event for the new node input (empty state input).
     */
    $scope.onNewNodeInputKeyDown = function (event) {
        // If enter is pressed and we have a name for the new logic, create it.
        if (
            event &&
            (event.code === 'Enter' || event.keyCode === 13) &&
            !utils.isNullOrEmpty($scope.data.newNodeDisplayName)
        ) {
            // If it's an enter key press.
            event.preventDefault();
            // Create a new logic.
            $scope.data.savingNewNode = true;
            $scope
                .createNewNode($scope.data.nodeTree, $scope.data.newNodeDisplayName, 'From enter press')
                .finally(() => {
                    $scope.data.savingNewNode = false;
                });
        }
    };

    /**
     * Blur event for the new node input (empty state input).
     */
    $scope.onNewNodeBlur = function () {
        // Create the new logic on blur if it has a name.
        if (!utils.isNullOrEmpty($scope.data.newNodeDisplayName)) {
            $scope.data.savingNewNode = true;
            $scope.createNewNode($scope.data.nodeTree, $scope.data.newNodeDisplayName, 'From blur').finally(() => {
                $scope.data.savingNewNode = false;
            });
        }
    };

    /**
     * Updates the current node data for the control panel.
     * This function is passed to the workerOutlineItems recursively.
     * This way when one realizes it's selected, it updates us so we can keep track on the relevant data
     * for the control panel actions.
     */
    $scope.updateCurrentNodeData = function (currentNode, nodeParent, nodeGrandParent) {
        selectedNode = currentNode;
        $scope.data.selectedLogic = selectedNode;
        selectedNodeParent = nodeParent;
        selectedNodeGrandParent = nodeGrandParent;
    };

    $scope.controlPanelAddNode = function () {
        if (selectedNode) {
            $scope.data.selectedLogic = selectedNode;
            $scope.createNewNode(selectedNode, null, 'From plus click');
        } else {
            $scope.data.selectedLogic = $scope.data.nodeTree;
            $scope.createNewNode($scope.data.nodeTree, null, 'From plus click');
        }
    };

    $scope.controlPanelDeleteNode = function () {
        const selectedLogic = $scope.data.selectedLogic;
        if (
            selectedLogic &&
            $scope.data.logicBlockTypesMap[selectedLogic.node.customTriggerType][
                selectedLogic.node.customTriggerSecondaryType
            ].cantDelete
        ) {
            return;
        }

        if (selectedNode) {
            analyticsWrapper.track('Delete logic', { category: 'Worker workflow outline' });

            // Before we delete, let's decide which node to move to after deletion is done.
            // Try to get the next one.
            let nodeToSelect = getNextOrPreviousInTree(false, selectedNode.node.id);
            if (!nodeToSelect) {
                // If we didn't find a next node, try moving up and taking the previous one.
                nodeToSelect = getNextOrPreviousInTree(true, selectedNode.node.id);
            }

            // Try do delete with the user's permission.
            customTriggerManager
                .deleteCustomTriggerInWorkflowVersion($scope.data.groupId, $scope, selectedNode)
                .then((wasLogicDeleted) => {
                    // If the logic was deleted and we found a node to go to, select it!
                    if (wasLogicDeleted && nodeToSelect) {
                        $scope.selectNode(nodeToSelect);
                    }
                })
                .catch((error) => {
                    const errorMessage = getStateError(error, {
                        fallbackErrorMessage: `There was an error trying to delete the logic ${$scope.data.configuredLogic.node.displayName}. please try again.`,
                    });

                    $rootScope.modal
                        .alert('Whoops!', { body: errorMessage, isWarn: true, okLabel: 'Refresh Module' })
                        .finally(() => {
                            $rootScope.$broadcast('reloadModuleEditorRequest');
                        });
                });
        }
    };

    $scope.controlPanelMoveDown = function () {
        analyticsWrapper.track('Move down in tree', { category: 'Worker workflow outline' });
        moveUpDownInTree(false);
    };

    $scope.controlPanelMoveUp = function () {
        analyticsWrapper.track('Move up in tree', { category: 'Worker workflow outline' });
        moveUpDownInTree(true);
    };

    $scope.controlPanelTabIn = function () {
        if (selectedNode && selectedNode.node && selectedNode.node.customTriggerType !== 'AUTONOMOUS') {
            $scope.tabNodeIn();
        }
    };

    $scope.controlPanelTabOut = function () {
        if (selectedNode) {
            $scope.tabNodeOut();
        }
    };

    $scope.loadFieldDefinitions = function () {
        $scope.data.loadingFields = true;
        customFieldsManager.getFieldDefinitions($scope.data.workflowVersionId).finally(function () {
            $scope.data.loadingFields = false;
        });
    };

    $scope.moveInTree = function (isUp, currentNodeId) {
        let nodeToSelect = null;

        if (isUp) {
            // Move up (to the previous node).
            nodeToSelect = getNextOrPreviousInTree(true, currentNodeId);
        } else {
            // Move down (to the next node).
            nodeToSelect = getNextOrPreviousInTree(false, currentNodeId);
        }

        // If we found a node to go to, select it!
        if (nodeToSelect) {
            $scope.selectNode(nodeToSelect);
        }
    };

    function loadForms() {
        if ($scope.data.workflowVersionId) {
            $scope.data.loadingForms = true;
            // Load forms to cache
            formManager
                .getAllWorkerForm($scope.data.workflowVersionId)
                .finally(() => ($scope.data.loadingForms = false));
        }
    }

    $scope.editForm = function (form) {
        $timeout(() => ctrl.onOutlineFormSelected(form.id, form.formType));
    };

    $scope.createNewForm = function (formType) {
        $timeout(() => ctrl.onOutlineFormSelected(null, formType));
    };

    /**
     * On Example initiative selected evaluate fields values.
     */
    $scope.onInitiativeSelected = function (selectedSimplifiedInitiative) {
        if (selectedSimplifiedInitiative.id) {
            $scope.data.selectedExampleInitiative = selectedSimplifiedInitiative;
            customTriggerManager.buildWorkflowVersionExampleItems(
                $scope.data.workflowVersionId,
                selectedSimplifiedInitiative.id,
            );
        }
    };

    /**
     * Runs a scheduled trigger now.
     */
    $scope.runScheduledAutonomousCustomTriggerNow = function (customTriggerId) {
        $scope.mboxData = {
            title: 'Are you sure?',
            body: 'All items that meet the conditions will run through the scheduled trigger now.',
            isWarn: true,
            okLabel: 'Yes',
            cancelLabel: 'Cancel',
        };

        return modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(() => {
                $scope.data.loadingRunScheduledTriggerNow = true;
                $scope.data.errorLoadingRunScheduledTriggerNow = false;

                return tonkeanService
                    .runScheduledAutonomousCustomTriggerNow($scope.data.workflowVersionId, customTriggerId)
                    .catch(() => {
                        $scope.data.errorLoadingRunScheduledTriggerNow = true;
                    })
                    .finally(() => {
                        $scope.data.loadingRunScheduledTriggerNow = false;
                    });
            });
    };

    $scope.openViewFormConfiguration = function (form) {
        modalUtils.openEditWorkerFormModal(
            $scope.data.groupId,
            form.id,
            form.formType,
            null,
            $scope.data.workflowVersionId,
            'preview',
        );
    };

    /**
     * Executes collect for the project integration.
     */
    $scope.runCollect = function (projectIntegration) {
        if ($scope.data.runningCollect[projectIntegration.id]) {
            return;
        }

        $scope.data.runningCollect[projectIntegration.id] = true;

        integrationHelper.runCollectOnProjectIntegration(projectIntegration).then(() => {
            delete $scope.data.runningCollect[projectIntegration.id];
        });
    };

    /**
     * Navigate to a specific project integration using the return to state button
     * @param projectIntegration
     */
    $scope.goToProjectIntegrationWithReturnTo = function (projectIntegration) {
        if (projectIntegration) {
            $state.go('product.projectIntegrationPage', {
                enterpriseComponentId: projectIntegration.id,
                fromName: projectManager.groupsMap[$scope.data.groupId].name,
                fromState: 'product.workerEditor',
                fromStateParams: $state.params,
                page: ProjectIntegrationPageMenuItemType.VIEW_DATA,
            });
        }
    };

    /**
     * Navigate to the project integrations gallery using the return to state button
     * @param projectIntegration
     */
    $scope.goToProjectIntegrationsGalleryWithReturnTo = function () {
        $state.go('product.enterpriseComponents', {
            tab: 'data-sources',
            fromName: projectManager.groupsMap[$scope.data.groupId].name,
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
        });
    };

    $scope.onTestWorkflowClicked = function (invalidLogics, validationState) {
        ctrl.onTestWorkflowClicked(invalidLogics, validationState);
    };

    $scope.onRevertWorkflowVersionClicked = function () {
        ctrl.onRevertWorkflowVersionClicked();
    };

    function getNextOrPreviousInTree(isUp, currentNodeId) {
        // We go over the tree in weird DFS, i.e we always add the children but we add them in reverse order.
        // This way, when ever we get to somewhere in the stack - the next in line is the one we should jump to.
        // If we are moving up in the tree, we simply go to the previous node we visited.

        // Create a stack.
        const nodeStack = [];
        // Push the starting nodes to the stack in reverse
        for (let i = $scope.data.nodeTree.impacts.length - 1; i >= 0; i--) {
            nodeStack.push($scope.data.nodeTree.impacts[i]);
        }

        let nodeFound = false; // Only used for moving down.
        let previousNode = null; // Only used for moving up.

        while (nodeStack.length) {
            // Go to the next one in the stack, but keep a hold over the previous one without mixing references.
            const currentNode = nodeStack.pop();

            // If the node was found in the last iteration already, we are moving down
            // and should return the current node (which is the next one in line).
            if (nodeFound) {
                // Here, nodeBlock is the previous one because we didn't pop from the stack yet.
                return currentNode;
            }

            // If we found the node.
            if (currentNode.node.id === currentNodeId) {
                if (isUp) {
                    // If we are moving up - we can return the previous block immediately.
                    return previousNode;
                }
                // If we are moving down - we wait until we are done pushing the next ones before returning.
                nodeFound = true;
            } else {
                // Didn't find out node yet - just keep track of the previous one.
                previousNode = currentNode;
            }

            // Push it's children in the stack (if he has any) IN REVERSE ORDER.
            // This way, the next one to pop out of the stack is the next child.
            if (currentNode.impacts && currentNode.impacts.length) {
                for (let i = currentNode.impacts.length - 1; i >= 0; i--) {
                    nodeStack.push(currentNode.impacts[i]);
                }
            }
        }

        // We didn't have anyone to move next to.
        return null;
    }

    function moveUpDownInTree(isUp) {
        if (selectedNode && selectedNodeParent && selectedNodeParent.impacts) {
            // Get the index of the currently selected node in his parent impacts array.
            const nodeIndex = utils.indexOf(
                selectedNodeParent.impacts,
                (nodeBlock) => selectedNode.node.id === nodeBlock.node.id,
            );

            // Calculate the required validation (according to up or down movement).
            const validation = isUp ? nodeIndex > 0 : nodeIndex < selectedNodeParent.impacts.length - 1;

            if (validation) {
                // Get the desired index according to up or down movement.
                const desiredIndex = isUp ? nodeIndex - 1 : nodeIndex + 1;

                // Get the server index of the node we are switching places with.
                const movedOverNodeBlock = selectedNodeParent.impacts[desiredIndex];
                const movedOverNodeServerIndex = movedOverNodeBlock.node.index;

                // Take the node out.
                const movedNodeBlock = selectedNodeParent.impacts.splice(nodeIndex, 1)[0];
                const movedNodeServerIndex = movedNodeBlock.node.index;

                // Update the indexes accordingly.
                movedNodeBlock.node.index = movedOverNodeServerIndex;
                movedOverNodeBlock.node.index = movedNodeServerIndex;

                // Put it back in.
                selectedNodeParent.impacts.splice(desiredIndex, 0, movedNodeBlock);

                const belowCustomTriggerId = selectedNodeParent.impacts[desiredIndex - 1]?.node?.id;

                // Update the server.
                customTriggerManager
                    .moveCustomTrigger(
                        $scope.data.groupId,
                        selectedNodeParent.node?.id || selectedNodeParent.node,
                        selectedNode.node.id,
                        belowCustomTriggerId,
                    )
                    .catch((error) => {
                        if (error.status === 409) {
                            modalUtils.openRefreshModuleModal();
                        }
                    });
            }
        }
    }

    function updateWorkflowVersion() {
        $scope.data.workflowVersion = workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId);
    }

    function reloadActionItems() {
        $scope.data.loadingActionItems = true;
        $scope.data.actionItemsLogics.length = 0;
        fillLogicOfActionsItems($scope.data.nodeTree, $scope.data.actionItemsLogics);
        $timeout(() => ($scope.data.loadingActionItems = false));
    }

    function fillLogicOfActionsItems(node, resultArray, level) {
        if (!level) {
            level = 0;
        }

        if (node) {
            if (
                node?.node?.customTriggerType &&
                logicBlockTypesMap[node.node.customTriggerType][node.node.customTriggerSecondaryType]
                    ?.creatingActionItems
            ) {
                level += 1;
                resultArray.push({
                    level,
                    node: node.node,
                });
            }

            if (node.impacts && node.impacts.length) {
                for (let i = 0; i < node.impacts.length; i++) {
                    fillLogicOfActionsItems(node.impacts[i], resultArray, level);
                }
            }
        }
    }

    function findNode(node, customTriggerId, callback) {
        if (node && customTriggerId && callback) {
            if (node.node && node.node.id && node.node.id === customTriggerId) {
                callback(node);
            } else {
                if (node.impacts && node.impacts.length) {
                    for (let i = 0; i < node.impacts.length; i++) {
                        findNode(node.impacts[i], customTriggerId, callback);
                    }
                }
            }
        }
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('WorkerOutlineCtrl', lateConstructController(WorkerOutlineCtrl));
