import React, { useEffect, useState } from 'react';

import IroncladAuthType, { authTypeToDisplayName } from './IroncladAuthType';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

interface ProjectData {
    authType: string | undefined;
    envSubdomain: string | undefined;
}

const IroncladSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    onChangeOrInitIntegration,
    projectIntegration,
    projectIntegrationData,
}) => {
    const [authType, setAuthType] = useState<string>(IroncladAuthType.API_KEY);
    const [envSubdomain, setEnvSubdomain] = useState<string | undefined>();

    useEffect(() => {
        const init = () => {
            if (!projectIntegrationData && !projectIntegration) return;

            const setupProjectData = projectIntegrationData as ProjectData;
            const existingProjectData = projectIntegration?.projectData?.projectDatas?.[0] || {};

            setAuthType(setupProjectData?.authType ?? existingProjectData.authType);
            setEnvSubdomain(setupProjectData?.envSubdomain ?? existingProjectData.envSubdomain);
        };
        init();
    }, [authType, projectIntegration, projectIntegrationData]);

    useEffect(() => {
        onChangeOrInitIntegration(
            {
                projectIntegrationData: {
                    authType,
                    envSubdomain,
                },
            },
            false,
        );
    }, [authType, envSubdomain, onChangeOrInitIntegration]);

    return (
        <div className="flex flex-col api-token-authentication">
            <div className="form-group">
                <label className="col-sm-4 control-label margin-top-xs">Authentication Type</label>
                <div className="col-sm-7">
                    <input value={authTypeToDisplayName[authType]} className="form-control" readOnly />
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-4 control-label margin-top-xs">Environment Subdomain</label>
                <div className="col-sm-7">
                    <input value={envSubdomain} className="form-control" readOnly />
                </div>
            </div>
        </div>
    );
};

export default IroncladSetupComponent;
