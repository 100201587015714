import { useField } from 'formik';
import React from 'react';

import TonkeanIconDisplay from './TonkeanIconDisplay';
import type { TonkeanIconPickerProps } from './TonkeanIconPicker';
import TonkeanIconPicker from './TonkeanIconPicker';

interface Props extends Omit<TonkeanIconPickerProps, 'icon' | 'onChangeIcon' | 'SelectedIconComponent'> {
    name: string;
}

const FormikTonkeanIconPicker: React.FC<Props> = ({ name, ...props }) => {
    const [{ value }, , { setValue }] = useField(name);
    return (
        <TonkeanIconPicker
            icon={value}
            onChangeIcon={(icon) => setValue(icon)}
            SelectedIconComponent={<TonkeanIconDisplay icon={value} />}
            {...props}
        />
    );
};

export default FormikTonkeanIconPicker;
