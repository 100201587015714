import type { ITooltipParams } from '@ag-grid-community/core';
import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const TooltipBox = styled.div`
    padding: 8px;
    color: white;
    background-color: ${Theme.colors.gray_800};
    border-radius: 4px;
`;

const TableTooltip: React.FC<ITooltipParams> = ({ value }) => {
    return <TooltipBox className="fs-censored">{value}</TooltipBox>;
};

export default TableTooltip;
