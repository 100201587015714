<div
    class="condition-selector"
    ng-class="{
        'flex reduce-margins': !data.verticalMod,
        'flex-wrap':
            (!data.verticalMod && data.selectedCondition.displayValueOnSeparateLine) ||
            (!data.selectedCondition.noValueRequired &&
                !data.useExpressionsForConditionValue &&
                data.valueSelectionType === 'List')
    }"
>
    <!-- Condition selection -->
    <ui-select
        ng-class="{ 'margin-right-xs margin-top-xs': !data.verticalMod }"
        class="condition-selector-filter-min-width margin-xs-bottom-xs flex-no-shrink flex-grow"
        data-automation="condition-selector-open-conditions-button"
        theme="bootstrap"
        ng-model="data.selectedCondition"
        ng-change="onConditionSelection(data.selectedCondition, true, true)"
        ng-disabled="data.disableSelections"
    >
        <ui-select-match placeholder="Condition">{{ data.selectedCondition.displayName }}</ui-select-match>
        <ui-select-choices
            group-by="data.groupConditionsBy"
            group-filter="utils.sortSelectGroups"
            repeat="p in data.relevantConditions | filter:$select.search"
        >
            <div
                data-automation="condition-selection-condition-option-name-{{ p.displayName }}"
                ng-bind-html="p.displayName | highlight: $select.search"
            ></div>
        </ui-select-choices>
    </ui-select>

    <!-- Match breadth level -->
    <ui-select
        ng-if="data.matchConditionExpressions.includes(data.selectedCondition.apiName)"
        class="margin-right-xs margin-top-xs condition-selector-filter-min-width margin-xs-bottom-xs flex-no-shrink"
        theme="bootstrap"
        ng-model="data.secondValue"
        ng-change="onSecondValueChanged(data.secondValue)"
        ng-disabled="data.disableSelections"
    >
        <ui-select-match placeholder="Level">
            {{ data.matchBreadthLevelsApiNameToConfiguration[data.secondValue].displayName }}
        </ui-select-match>
        <ui-select-choices repeat="matchBreadthLevel in data.matchBreadthLevelsApiNames | filter:$select.search">
            <div class="flex-vmiddle">
                <div
                    ng-bind-html="data.matchBreadthLevelsApiNameToConfiguration[matchBreadthLevel].displayName | highlight: $select.search"
                ></div>
            </div>
        </ui-select-choices>
    </ui-select>

    <!-- Value selection -->
    <div
        ng-switch="data.valueSelectionType"
        ng-if="!data.selectedCondition.noValueRequired && !data.useExpressionsForConditionValue"
        ng-class="{ 'margin-right-xs margin-top-xs': !data.verticalMod }"
        class="flex-grow condition-selector-value-min-width margin-xs-bottom-xs"
    >
        <!-- List. Will show the possible values only -->
        <div ng-switch-when="List" class="condition-selector-value-min-width mod-value-list">
            <!-- Special handling for a status field (showing a colored drop down list) -->
            <div ng-if="data.field.name === 'TNK_STAGE' || data.field.id === 'TNK_STAGE'">
                <ui-select
                    ng-init="!data.value && (value = data.possibleValues[0])"
                    data-automation="condition-selector-status-dropdown"
                    ng-model="data.value"
                    ng-disabled="data.disableSelections"
                    theme="bootstrap"
                    ng-change="onValueChanged(data.value)"
                    tagging="true"
                    tagging-label="false"
                >
                    <ui-select-match placeholder="Enter value...">
                        <div class="flex-vmiddle">
                            <i
                                ng-if="data.stateLabelToStateMap[data.value]"
                                class="status-color-box margin-right-xs"
                                ng-style="{ 'background-color': data.stateLabelToStateMap[data.value].color }"
                            ></i>
                            <div>{{ data.stateLabelToStateMap[data.value].label || data.value }}</div>
                        </div>
                    </ui-select-match>
                    <ui-select-choices repeat="p in data.possibleValues | filter:$select.search" group-by="groupByStatusType">
                        <div class="flex-vmiddle">
                            <i
                                class="status-color-box margin-right-xs"
                                ng-style="{ 'background-color': data.stateLabelToStateMap[p].color }"
                            ></i>
                            <div class="common-break-long" data-automation="condition-selector-template-selected-status">
                                <tnk-text-ellipsis
                                    number-of-lines="1"
                                    tooltip="true"
                                    children="data.stateLabelToStateMap[p].label"
                                ></tnk-text-ellipsis>
                            </div>
                        </div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <!-- Handling any other non-status field -->
            <div ng-if="data.field.name !== 'TNK_STAGE' && data.field.id !== 'TNK_STAGE'">
                <ui-select
                    ng-init="!data.value && (value = data.possibleValues[0])"
                    ng-model="data.value"
                    ng-disabled="data.disableSelections"
                    theme="bootstrap"
                    ng-change="onValueChanged(data.value)"
                >
                    <ui-select-match placeholder="Enter value...">
                        {{ data.possibleValuesMap[data.value] || data.value }}
                    </ui-select-match>
                    <ui-select-choices repeat="p in getPossibleValuesOptions($select.search) | filter:$select.search">
                        <div
                            ng-bind-html="(p.displayName || data.possibleValuesMap[p] || p) | highlight: $select.search"
                        ></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <!-- Boolean. Will show the possible true or false only -->
        <div ng-switch-when="Boolean">
            <select
                ng-model="data.value"
                ng-disabled="data.disableSelections"
                class="form-control condition-selector-value-min-width"
                placeholder="Enter value..."
                ng-change="onValueChanged(data.value)"
                ng-options="opt for opt in ['true', 'false']"
            ></select>
        </div>

        <!-- DateExact. Displays a date picker -->
        <div ng-switch-when="DateExact" class="relative text-center condition-selector-value-min-width">
            <button type="button" class="btn btn-default common-width-full" ng-click="datePickerOpen = !datePickerOpen">
                <span ng-if="data.value">{{ data.value | date }}</span>
                <span ng-if="!data.value">Pick a date</span>
                <i class="svg-icon-track svg-icon-hover-black common-color-light-grey">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/calendar.svg"></tnk-icon>
                </i>
            </button>
            <input
                class="condition-selector-date-hidden-input"
                uib-datepicker-popup="fullDate"
                datepicker-options="{showWeeks: false}"
                ng-model="data.value"
                ng-min="data.minDate"
                min-date="data.minDate"
                ng-change="onValueChanged(data.value.setHours(0, 0, 0))"
                is-open="datePickerOpen"
                close-text="Close"
            />
        </div>

        <!-- Change operator -->
        <div ng-switch-when="ChangeOperator" class="flex flex-vmiddle text-center condition-selector-value-min-width">
            <select
                ng-model="data.secondValue"
                ng-change="onSecondValueChanged(data.secondValue)"
                class="form-control common-width-auto margin-right-xs flex-grow"
                ng-class="{ 'margin-right-xs': data.selectedCondition.requiresSecondValue }"
                ng-options="opt for opt in data.changeConditionRangeOperatorsDisplayNames"
            ></select>

            <input
                type="number"
                min="0"
                step="1"
                ng-model="data.value"
                ng-disabled="data.disableSelections"
                ng-change="onValueChanged(data.value)"
                class="form-control margin-right-xs flex-grow"
            />

            <span
                ng-if="data.selectedCondition.inputDescriptionString"
                class="condition-label flex-grow"
                tooltip-append-to-body="true"
                tooltip-enable="{{ data.selectedCondition.changeCondition }}"
                uib-tooltip="The comparison is with the value the field had {{
                    data.field.compareTimeframe === 1
                        ? 'yesterday'
                        : data.field.compareTimeframe === 7
                        ? 'last week'
                        : 'last month'
                }}, which is taken from the field definition."
            >
                {{ data.selectedCondition.inputDescriptionString }}
            </span>
        </div>

        <!-- DateRange. Displays an input box -->
        <div ng-switch-when="DateRange" class="flex flex-vmiddle text-center condition-selector-value-min-width">
            <input
                type="number"
                min="0"
                step="1"
                ng-model="data.value"
                ng-disabled="data.disableSelections"
                ng-change="onValueChanged(data.value)"
                class="form-control margin-right-xs"
            />

            <!-- For selecting what the number represent days/hours/min -->
            <select
                type="text"
                ng-model="data.secondValue"
                ng-change="onSecondValueChanged(data.secondValue)"
                ng-disabled="data.disableSelections"
                class="form-control"
                ng-options="opt for opt in data.dateRangeValueOptions"
                placeholder="{{
                    data.selectedCondition.secondValuePlaceholder
                        ? data.selectedCondition.secondValuePlaceholder
                        : 'Enter value...'
                }}"
            ></select>

            <span
                ng-if="data.selectedCondition.inputDescriptionString"
                class="condition-label"
                tooltip-append-to-body="true"
                tooltip-enable="{{ data.selectedCondition.changeCondition }}"
                uib-tooltip="The comparison is with the value the field had {{
                    data.field.compareTimeframe === 1
                        ? 'yesterday'
                        : data.field.compareTimeframe === 7
                        ? 'last week'
                        : 'last month'
                }}, which is taken from the field definition."
            >
                {{ data.selectedCondition.inputDescriptionString }}
            </span>
        </div>

        <!-- DatePeriod. Displays the time period options -->
        <div ng-switch-when="DatePeriod" class="flex flex-vmiddle text-center">
            <select
                ng-model="data.value"
                ng-disabled="data.disableSelections"
                ng-change="onValueChanged(data.value)"
                class="form-control condition-selector-value-min-width"
                ng-options="opt for opt in data.datePeriodOptions"
            ></select>
        </div>

        <!-- Value to filter on -->
        <div ng-switch-default class="flex-vmiddle condition-selector-value-min-width">
            <tags-input
                ng-if="data.matchConditionExpressions.includes(data.selectedCondition.apiName)"
                ng-model="data.valueTags"
                class="flex-grow"
                add-from-autocomplete-only="false"
                add-on-enter="true"
                add-on-blur="true"
                placeholder="Add Phrases..."
                replace-spaces-with-dashes="false"
                on-tag-added="onValueTagsChanged()"
                on-tag-removed="onValueTagsChanged()"
            ></tags-input>

            <input
                type="text"
                ng-model="data.value"
                ng-if="!data.matchConditionExpressions.includes(data.selectedCondition.apiName)"
                ng-disabled="data.disableSelections"
                ng-change="onValueChanged(data.value)"
                ng-trim="false"
                class="form-control"
                data-automation="conditions-selector-add-value"
                ng-class="{ 'margin-right-xs': data.selectedCondition.requiresSecondValue }"
                placeholder="{{
                    data.selectedCondition.firstValuePlaceholder
                        ? data.selectedCondition.firstValuePlaceholder
                        : 'Enter value...'
                }}"
            />

            <input
                ng-if="data.selectedCondition.requiresSecondValue"
                type="text"
                ng-model="data.secondValue"
                ng-trim="false"
                ng-change="onSecondValueChanged(data.secondValue)"
                ng-disabled="data.disableSelections"
                class="form-control"
                placeholder="{{
                    data.selectedCondition.secondValuePlaceholder
                        ? data.selectedCondition.secondValuePlaceholder
                        : 'Enter value...'
                }}"
            />
        </div>
    </div>

    <!-- Expression value -->
    <div
        ng-if="data.useExpressionsForConditionValue"
        class="tonkean-expression-wrapper filter-min-width"
        ng-class="{
            'margin-top-xs': data.verticalMod,
            'margin-right-xs margin-top-xs': !data.verticalMod
        }"
    >
        <tnk-tonkean-expression
            class="condition-selector-filter-min-width flex-grow"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            saved-expression="data.expression"
            placeholder="Enter value..."
            on-tonkean-expression-changed="onFieldExpressionChanged(expression, shouldSaveLogic)"
            tabs-field-selector-mod-narrow="data.tabsFieldSelectorModNarrow"
            mod-fixed-width="data.modFixedWidth"
            tabs-field-selector-mod-fixed-width="data.tabsFieldSelectorModFixedWidth"
            preview-evaluation-source="data.previewEvaluationSource"
            excluded-tab-selector-special-fields="data.excludedTabSelectorSpecialFields"
            include-tab-selector-special-fields-for-features="data.includeTabSelectorSpecialFieldsForFeatures"
            do-not-evaluate-preview="data.doNotEvaluatePreview"
            show-people-directories="data.showPeopleDirectories"
        ></tnk-tonkean-expression>
    </div>
</div>
