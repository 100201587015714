import React, { useContext } from 'react';
import styled from 'styled-components';

import { ItemWidgetContext } from '../../../entities';

import { Placeholder } from '@tonkean/infrastructure';
import { getWidgetDisplayColumns } from '@tonkean/tonkean-entities';

const ImageWidgetPlaceholder = styled(Placeholder)<{ displayColumns: number }>`
    z-index: 0;
    overflow: hidden;
    object-fit: contain;
    grid-column: ${({ displayColumns }) => `span ${displayColumns}`};
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    height: 100%;
`;

const ImageWidgetLoadingSkeleton: React.FC = () => {
    const widgetContext = useContext(ItemWidgetContext);
    return (
        <>
            <ImageWidgetPlaceholder $width="100%" displayColumns={getWidgetDisplayColumns(widgetContext?.widget)} />
        </>
    );
};

export default ImageWidgetLoadingSkeleton;
