import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function ParentNavigationCtrl($scope) {
    const ctrl = this;
    $scope.data = {
        godFather: ctrl.godFather,
        disableGodFather: ctrl.disableGodFather,
        parentsArray: ctrl.parentsArray,
        provideParent: ctrl.provideParent,
        navigate: ctrl.navigate,
        immediateParentLabel: ctrl.immediateParentLabel,
        immediateParent: null,
        immediateParentBold: ctrl.immediateParentBold,
        parentsSeparator: ctrl.parentsSeparator,
    };

    ctrl.$onInit = function () {
        $scope.calculateCurrentImmediateParent();
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.parentsArray) {
            $scope.data.parentsArray = ctrl.parentsArray;
            $scope.calculateCurrentImmediateParent();
        }

        if (changesObj.godFather) {
            $scope.data.godFather = ctrl.godFather;
        }
    };

    $scope.calculateCurrentImmediateParent = function () {
        if ($scope.data.parentsArray && $scope.data.parentsArray.length > 0) {
            $scope.data.immediateParent = $scope.data.parentsArray[$scope.data.parentsArray.length - 1];
        }
    };
}
angular.module('tonkean.app').controller('ParentNavigationCtrl', lateConstructController(ParentNavigationCtrl));
