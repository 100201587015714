<div class="flex flex-grow flex-col flex-space-between">
    <div>
        <div class="flex-vmiddle flex-grow" data-automation="integration-group-box-mode-template-data-source-name">
            <!-- Integration logo when icon url is NOT available -->
            <div>
                <!-- Integration logo (inline) -->
                <div
                    class="initiative-integration-icon mod-17"
                    ng-class="'mod-' + integration.name.toLowerCase()"
                ></div>
            </div>

            <!-- Integration name (inline) -->
            <span
                uib-tooltip="{{ state[integration.name].integrations[0].displayName || integration.displayName }}"
                class="margin-left margin-right-xs common-ellipsis"
            >
                {{ state[integration.name].integrations[0].displayName || integration.displayName }}
            </span>

            <!-- Creator Name-->
            <span
                uib-tooltip="{{
                    projectIntegration.integration.integrationUserDisplayName || projectIntegration.displayNameFull
                }}"
                class="common-ellipsis"
                ng-class="{ 'common-color-grey': viewOnlyMode }"
            >
                ({{ projectIntegration.integration.integrationUserDisplayName || projectIntegration.displayNameFull }})
            </span>
        </div>

        <div
            class="default-communication-integration-desc common-size-xxxs margin-top-6 inline-block"
            ng-if="pm.project.defaultCommunicationProjectIntegration.id === projectIntegration.id"
            uib-tooltip="In case no communication source instance set, the default instance will be used by the flow"
        >
            Default Instance
        </div>
    </div>
    <div ng-if="!viewOnlyMode" class="flex flex-grow flex-col flex-space-between flex-justify-end-no-xs">
        <div class="flex flex-grow flex-col">
            <div class="common-size-xxxxs common-color-grey-soft"></div>
        </div>
        <!-- Loading icon -->
        <i class="loading-medium margin-right-xs" ng-show="updatingIntegrations[integration.name]"></i>
        <div>
            <div class="common-size-xxxxs common-color-grey-soft margin-bottom">
                Connected on {{ projectIntegration.integration.added | date: 'MM/dd/yyyy' }} (by
                {{ projectIntegration.creator.firstName }})
            </div>
            <div class="flex">
                <div
                    class="margin-left-auto"
                    ng-include="'./communicationIntegrationGroupButtonTemplate.template.html'"
                ></div>
            </div>
        </div>
    </div>
</div>
