import React from 'react';
import styled, { css } from 'styled-components';

import FormSettingsInput from './FormSettings/FormSettingsInput';
import FormSettingsLabel from './FormSettings/FormSettingsLabel';

import { useUUID } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Input = styled(FormSettingsInput)`
    flex-grow: 1;
    border-radius: 0;
    padding-left: 3px;
    text-overflow: ellipsis;
    border: none;
`;

const InputWrapper = styled.div<{ valid: boolean }>`
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    transition: border-color 0.2s ease-in-out;

    ${({ valid }) =>
        valid
            ? css`
                  border-color: ${Theme.colors.gray_400};
              `
            : css`
                  border-color: ${Theme.colors.error};
              `}
`;

const InputPrefix = styled(FormSettingsLabel)`
    height: 26px;
    display: grid;
    place-items: center;
    text-align: center;
    padding-left: 5px;
    margin: 0;
`;

const Error = styled.div`
    height: 26px;
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.error};
    white-space: pre;
    display: flex;
    align-items: center;
    padding: 0 5px;
`;

interface Props {
    valid: boolean;
    value?: string;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormBuilderSlackCommandInput: React.FC<Props> = ({ valid, value = '', disabled = false, onChange }) => {
    const id = useUUID();

    return (
        <InputWrapper valid={valid}>
            <InputPrefix htmlFor={id} disabled={disabled}>
                /tonk
            </InputPrefix>
            <Input
                placeholder="{Name}"
                value={value}
                onChange={onChange}
                withMarginTop={false}
                id={id}
                disabled={disabled}
            />
            {!valid && <Error>Command already exists</Error>}
        </InputWrapper>
    );
};

export default FormBuilderSlackCommandInput;
