import { useAngularService } from 'angulareact';
import React, { useContext } from 'react';

import { GenericIntegrationContext } from '@tonkean/integrations';
import type { CustomizedAuthenticationComponentProps } from '@tonkean/integrations';

const OAuthAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    integrationConfiguration,
    reportError,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);

    const oAuthAuthorization = async () => {
        try {
            const config = await integrationConfiguration.authenticate(oauthHandler, environment);
            authenticate(config);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="text-center">
            <button type="button" className=" btn btn-secondary btn-lg" onClick={oAuthAuthorization}>
                Authenticate with {integrationConfiguration.displayName}
            </button>
            {integrationConfiguration.description && (
                <div className="margin-normal-sm-v common-subtitle-inner">{integrationConfiguration.description}</div>
            )}
        </div>
    );
};

export default OAuthAuthenticationComponent;
