import LogicalOperatorBase from './LogicalOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class LogicalOrOperator extends LogicalOperatorBase {
    override readonly key = OperatorKey.LOGICAL_OR;
    override readonly sign = 'OR';
    override readonly displayName = 'Logical OR';
    override readonly description = 'Will return true if the left hand OR the right hand column are true.';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field A',
            dataType: FieldType.Boolean,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Field B',
            dataType: FieldType.Boolean,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
