import { useAngularService, useAngularWatch } from 'angulareact';
import { useCallback } from 'react';

import ProcessMapperLoadingState from '../entities/ProcessMapperLoadingState';

import type { WorkflowVersionManager } from '@tonkean/shared-services';
import type { ProcessMapper, TonkeanId } from '@tonkean/tonkean-entities';
import type { TonkeanType } from '@tonkean/tonkean-entities';

/**
 * The ProcessMapperManager is a slim cache for the process mappers in the module editor.
 * It supports caching mappers of 1 module at a time.
 * To make sure no stale data is returned, we use the WorkflowVersionId.
 *
 * This service should not be consumed directly, only via the hook below.
 */
class ProcessMapperCacheImpl {
    processMappers: Record<
        TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        Record<TonkeanId<TonkeanType.PROCESS_MAPPER>, ProcessMapper>
    > = {};
    loading: Record<TonkeanId<TonkeanType.WORKFLOW_VERSION>, ProcessMapperLoadingState> = {};

    public static $inject = ['workflowVersionManager'];

    constructor(private workflowVersionManager: WorkflowVersionManager) {}

    setProcessMappers(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>, processMappers: ProcessMapper[]) {
        const processMappersMap: Record<TonkeanId<TonkeanType.PROCESS_MAPPER>, ProcessMapper> = Object.fromEntries(
            processMappers.map((processMapper) => [processMapper.id, processMapper]),
        );

        this.processMappers = { ...this.processMappers, [workflowVersionId]: processMappersMap };
    }

    getProcessMappers(workflowVersionIdToGet: TonkeanId<TonkeanType.WORKFLOW_VERSION>): ProcessMapper[] {
        return Object.values(this.processMappers[workflowVersionIdToGet] ?? {});
    }

    getProcessMapperById(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
    ): ProcessMapper | undefined {
        return this.processMappers[workflowVersionId]?.[processMapperId];
    }

    onProcessMapperCreated(newProcessMapper: ProcessMapper | undefined) {
        if (newProcessMapper) {
            const workflowVersionId = newProcessMapper.workflowVersionId;
            const newProcessMappersMap = {
                ...this.processMappers[workflowVersionId],
                [newProcessMapper.id]: newProcessMapper,
            };
            this.setProcessMappers(workflowVersionId, Object.values(newProcessMappersMap));
            this.workflowVersionManager.incrementWorkflowVersionCounter(newProcessMapper.workflowVersionId);
        }
    }

    onProcessMapperDeleted(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
    ) {
        const processMapper = this.processMappers[workflowVersionId]?.[processMapperId];

        if (!processMapper) {
            return;
        }
        const processMappers = Object.values(this.processMappers[workflowVersionId] ?? []).filter(
            (_processMapper) => _processMapper.id !== processMapperId,
        );

        this.setProcessMappers(workflowVersionId, processMappers);
        this.workflowVersionManager.incrementWorkflowVersionCounter(processMapper.workflowVersionId);
    }

    onProcessMapperUpdated(newProcessMapper: ProcessMapper | undefined) {
        if (!newProcessMapper) {
            return;
        }

        const processMappers = Object.values(this.processMappers[newProcessMapper.workflowVersionId] ?? []).map(
            (_processMapper) => (_processMapper.id === newProcessMapper.id ? newProcessMapper : _processMapper),
        );

        this.setProcessMappers(newProcessMapper.workflowVersionId, processMappers);
        this.workflowVersionManager.incrementWorkflowVersionCounter(newProcessMapper.workflowVersionId);
    }

    setLoading(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>, loadingState: ProcessMapperLoadingState) {
        const loading = { ...this.loading };
        loading[workflowVersionId] = loadingState;

        this.loading = loading;
    }

    getLoading(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>) {
        // We default to ProcessMappersLoadingState.LOADING to avoid a flash of empty state.
        return this.loading[workflowVersionId] ?? ProcessMapperLoadingState.LOADING;
    }
}

export type ProcessMapperCache = typeof ProcessMapperCacheImpl;

/**
 * A thin wrapper around ProcessMappersManagerImpl that exposes a react-y API
 */
export function useProcessMapperCache() {
    const processMapperCache: ProcessMapperCacheImpl = useAngularService('processMappersManager');
    const $timeout = useAngularService('$timeout');

    // Using useAngularWatch to make sure React re-renders our components when we edit the process mappers
    // inside the processMappersManager
    const [rawProcessMappers, loading] = useAngularWatch(
        () => processMapperCache.processMappers,
        () => processMapperCache.loading,
    );

    const setProcessMappers = useCallback<ProcessMapperCacheImpl['setProcessMappers']>(
        (...args) => {
            $timeout(() => processMapperCache.setProcessMappers(...args));
        },
        [$timeout, processMapperCache],
    );

    const getProcessMappers = useCallback<ProcessMapperCacheImpl['getProcessMappers']>(
        (...args) => {
            if (rawProcessMappers) {
                return processMapperCache.getProcessMappers(...args);
            }
            return [];
        },
        [processMapperCache, rawProcessMappers],
    );

    const getProcessMapperById = useCallback<ProcessMapperCacheImpl['getProcessMapperById']>(
        (...args) => {
            if (rawProcessMappers) {
                return processMapperCache.getProcessMapperById(...args);
            }
        },
        [processMapperCache, rawProcessMappers],
    );

    const onProcessMapperCreated = useCallback<ProcessMapperCacheImpl['onProcessMapperCreated']>(
        (...args) => {
            $timeout(() => processMapperCache.onProcessMapperCreated(...args));
        },
        [$timeout, processMapperCache],
    );

    const onProcessMapperDeleted = useCallback<ProcessMapperCacheImpl['onProcessMapperDeleted']>(
        (...args) => {
            $timeout(() => processMapperCache.onProcessMapperDeleted(...args));
        },
        [$timeout, processMapperCache],
    );

    const onProcessMapperUpdated = useCallback<ProcessMapperCacheImpl['onProcessMapperUpdated']>(
        (...args) => {
            $timeout(() => processMapperCache.onProcessMapperUpdated(...args));
        },
        [$timeout, processMapperCache],
    );

    const setLoading = useCallback<ProcessMapperCacheImpl['setLoading']>(
        (...args) => {
            processMapperCache.setLoading(...args);
        },
        [processMapperCache],
    );

    const getLoading = useCallback<ProcessMapperCacheImpl['getLoading']>(
        (...args) => {
            return processMapperCache.getLoading(...args);
        },
        [processMapperCache],
    );

    return {
        getProcessMappers,
        setProcessMappers,
        getProcessMapperById,
        onProcessMapperCreated,
        onProcessMapperDeleted,
        onProcessMapperUpdated,
        setLoading,
        getLoading,
    };
}

export default angular.module('tonkean.app').service('processMappersManager', ProcessMapperCacheImpl);
