import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import type { FormPageProps } from './FormPage';
import FormPage from './FormPage';

import { useToastMessage } from '@tonkean/angular-hooks';
import { getFormSecondaryColorOptions } from '@tonkean/constants';
import type { ModalProps } from '@tonkean/infrastructure';
import { Breakpoint, Modal, useBreakpoint, XCloseButton } from '@tonkean/infrastructure';
import type { Form, Initiative } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledModal = styled(Modal)<{ backgroundColor?: string }>`
    position: relative;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'auto')};
`;

const StyledXCloseButton = styled(XCloseButton)`
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 150;

    z-index: 150;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        top: 10px;
        right: 10px;
    }

    &:active,
    &:hover {
        background-color: ${Theme.colors.gray_200};
    }
`;
interface Props extends Omit<FormPageProps, 'onFormClosed' | 'project'>, ModalProps {
    onSubmitResolved?: () => void | undefined;
}

const FormPageModal: React.FC<Props> = ({ onClose, onInitiativeCreated, onSubmitResolved, ...props }) => {
    const breakpoint = useBreakpoint();
    const mobileView = breakpoint <= Breakpoint.XSMALL_640;
    const projectManager = useAngularService('projectManager');
    const project = projectManager.project;
    const formManager = useAngularService('formManager');
    const [backgroundColor, setBackgroundColor] = useState<string | undefined>();
    const formModalRef = useRef<HTMLDivElement>(null);

    const [createdInitiativeParams, setCreatedInitiativeParams] = useState<
        { createdInitiative: Initiative; formDisplayName: string } | undefined
    >();

    const emit = useToastMessage();
    const onFormClosed = useCallback(() => {
        if (createdInitiativeParams) {
            emit(
                `Item ${createdInitiativeParams.createdInitiative.title} created by form ${createdInitiativeParams.formDisplayName}`,
                'success',
            );
        }
        onClose();
    }, [createdInitiativeParams, emit, onClose]);

    useEffect(() => {
        const getThemeColors = async () => {
            if (props.formId) {
                let form: Form | undefined = undefined;
                if (props.workflowVersionId) {
                    form = await formManager.getWorkerForm(
                        props.workflowVersionId,
                        props.formId,
                        props.parentInitiativeId,
                    );
                } else if (props.formVersionType) {
                    form = await formManager.getFormByWorkflowVersionType(props.formId, props.formVersionType);
                }
                if (form) {
                    setBackgroundColor(
                        project.forceThemeConfiguration
                            ? project.themeConfiguration.primaryColor
                            : form.definition.secondaryColor || getFormSecondaryColorOptions().default,
                    );
                }
            }
        };
        getThemeColors();
    }, [
        formManager,
        project.forceThemeConfiguration,
        project.themeConfiguration.logoUrl,
        project.themeConfiguration.primaryColor,
        props.formId,
        props.formVersionType,
        props.parentInitiativeId,
        props.workflowVersionId,
    ]);

    return (
        <StyledModal
            ref={formModalRef}
            open={props.open}
            onClose={onClose}
            windowedFullScreen={!mobileView}
            fullScreen={mobileView}
            backgroundColor={backgroundColor}
            backdropCloses={false}
            dataAutomation="form-page-modal"
        >
            <StyledXCloseButton fillColor="black" />
            <FormPage
                {...props}
                project={project}
                onFormClosed={onFormClosed}
                onInitiativeCreated={(result) => {
                    setCreatedInitiativeParams(result);
                    onInitiativeCreated(result);
                }}
                formModalRef={formModalRef?.current}
                onSubmitResolved={onSubmitResolved}
                isModal
            />
        </StyledModal>
    );
};

export default FormPageModal;
