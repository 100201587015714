import type HistoryTableGroup from './HistoryTableGroup';

/**
 * Construct a new history table group with no fields.
 *
 * @param id - Table's id. Used in the isGroupOpenMap.
 * @param displayName - The group name.
 * @param type - Field type (Number, Date, Manual, etc. Additionally, Root if it's the intake source).
 * @param projectIntegrationId - if it's a group of a matched entity, project integration id.
 * @param integrationType - if it's a group of a matched entity, the data source type (googleSheet, intercom, etc).
 * @param iconUrl - if it's a group of a matched entity, the data source icon url.
 * @returns a new empty group.
 */
function constructHistoryTableGroup(
    id: string,
    displayName: string,
    type: string,
    projectIntegrationId?: string,
    integrationType?: string,
    iconUrl?: string,
): HistoryTableGroup {
    return {
        id,
        displayName,
        type,
        projectIntegrationId,
        integrationType,
        iconUrl,
        fields: [],
    };
}

export default constructHistoryTableGroup;
