<div ng-switch="data.fieldId" class="special-field-manual-input" ng-class="{ invalid: data.invalid }">
    <!-- Owner selection -->
    <div ng-switch-when="TNK_OWNER_ID" class="manual-action-owner-selection">
        <tnk-people-selector-by-person-identifier
          data-automation="Form-Field-Value-{{ data.fieldId }}"
          is-disabled="isDisabled || data.posting"
          include-external="true"
          is-multi="false"
          selected-emails-or-ids="data.selectedValueToUpdate.value"
          on-change="(ownerSelected)"
        ></tnk-people-selector-by-person-identifier>
    </div>

    <!-- Status selection (HTML SAME AS NEXT ONE) -->
    <div ng-switch-when="TNK_STAGE" data-automation="Form-Field-STAGE-{{ data.fieldId }}">
        <ui-select theme="bootstrap" ng-model="data.selectedValueToUpdate.value" ng-change="onStateChanged()">
            <ui-select-match placeholder="Search...">
                <div class="flex mod-align-baseline">
                    <i
                        class="fa fa-square margin-right-xs"
                        ng-style="{
                            color: wvm.getCachedWorkflowVersion(data.workflowVersionId).stateLabelToStateMap[
                                data.selectedValueToUpdate.value
                            ].color
                        }"
                    ></i>
                    <span>
                        {{
                            wvm.getCachedWorkflowVersion(data.workflowVersionId).stateLabelToStateMap[
                                data.selectedValueToUpdate.value
                            ].label
                        }}
                    </span>
                </div>
            </ui-select-match>
            <ui-select-choices
                repeat="p in wvm.getCachedWorkflowVersion(data.workflowVersionId).states | filter:$select.search"
            >
                <div class="flex-vmiddle" data-automation="Form-Field-STAGE-{{ data.fieldId }}-status">
                    <i class="fa fa-square margin-right" ng-style="{ color: p.color }"></i>
                    <div ng-bind-html="p.label | escapeHtml | highlight: $select.search"></div>
                </div>
            </ui-select-choices>
        </ui-select>
    </div>

    <!-- Status selection (Same, but for TNK_STATUS_TEXT) -->
    <div ng-switch-when="TNK_STATUS_TEXT">
        <ui-select theme="bootstrap" ng-model="data.selectedValueToUpdate.value" ng-change="onStateChanged()">
            <ui-select-match placeholder="Search...">
                <div class="flex mod-align-baseline">
                    <i
                        class="fa fa-square margin-right-xs"
                        ng-style="{
                            color: wvm.getCachedWorkflowVersion(data.workflowVersionId).stateLabelToStateMap[
                                data.selectedValueToUpdate.value
                            ].color
                        }"
                    ></i>
                    <span>
                        {{
                            wvm.getCachedWorkflowVersion(data.workflowVersionId).stateLabelToStateMap[
                                data.selectedValueToUpdate.value
                            ].label
                        }}
                    </span>
                </div>
            </ui-select-match>
            <ui-select-choices
                repeat="p in wvm.getCachedWorkflowVersion(data.workflowVersionId).states | filter:$select.search"
            >
                <div class="flex-vmiddle">
                    <i class="fa fa-square margin-right" ng-style="{ color: p.color }"></i>
                    <div ng-bind-html="p.label | escapeHtml | highlight: $select.search"></div>
                </div>
            </ui-select-choices>
        </ui-select>
    </div>

    <!-- Tag selection  -->
    <div ng-switch-when="TNK_TAGS" class="manual-action-owner-selection">
        <tnk-tags-input
            class="flex-grow"
            data-automation="Form-Field-Value-{{ data.fieldId }}"
            tags="data.selectedValueToUpdate.value"
            latest-tag-search="latestTagSeach"
            selected-item-template="initiative-tag-selected-item"
            auto-complete-template="initiative-tag-autocomplete-item"
            load-on-empty="true"
            on-tag-added="onManualSpecialFieldChanged()"
            on-tag-removed="onManualSpecialFieldChanged()"
            placeholder="Add relevant topics..."
        ></tnk-tags-input>
    </div>

    <!-- Default -->
    <div ng-switch-default>
        <div ng-switch="data.specialField.fieldType">
            <!-- List -->
            <div ng-switch-when="List">
                <ui-select
                    theme="bootstrap"
                    ng-model="data.selectedValueToUpdate.value"
                    ng-change="onManualSpecialFieldChanged()"
                >
                    <ui-select-match placeholder="Search...">{{ data.selectedValueToUpdate.value }}</ui-select-match>
                    <ui-select-choices repeat="p in data.specialField.possibleValues | filter:$select.search">
                        <div ng-bind-html="p | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <!-- Date -->
            <div ng-switch-when="Date">
                <!-- Relative date picker -->
                <div ng-if="data.relativeMode">
                    <input
                        class="form-control actions-popover-date-type-input"
                        type="number"
                        ng-model="data.selectedValueToUpdate.value"
                        ng-change="onManualSpecialFieldChanged()"
                    />
                    <div class="common-size-xxxs margin-left-xs flex-no-shrink">Days from Trigger match</div>
                </div>
                <!-- Absolute date picker -->
                    <tnk-form-date-picker-input
                        data-automation="Form-Field-Value-TNK_DUE_DATE"
                        on-change="onManualSpecialFieldChanged"
                        selected="data.selectedValueToUpdate.value"
                        min-date="data.minDate"
                        placeholder-text="Pick a date"
                        min-date="data.minDate"
                        field-definition-id="data.fieldId"
                    />
            </div>

            <!-- LongString -->
            <div ng-switch-when="LongString" class="flex-vmiddle">
                <textarea
                    class="form-control common-resize-y-only"
                    ng-model="data.selectedValueToUpdate.value"
                    ng-change="onManualSpecialFieldChanged()"
                    data-automation="Form-Field-LongString-{{ data.fieldId }}"
                ></textarea>
            </div>

            <!-- Default (Number/String) -->
            <div ng-switch-default>
                <input
                    class="form-control field-text-input"
                    ng-model="data.selectedValueToUpdate.value"
                    data-automation="Form-Field-Number-String-{{ data.fieldId }}"
                    ng-change="onManualSpecialFieldChanged()"
                />
            </div>
        </div>
    </div>
</div>
