import UkgAuthenticationComponent from './UkgAuthenticationComponent';
import ukgCircleIcon from '../../../../apps/tracks/images/integrations/ukg-circle.png';
import ukgIcon from '../../../../apps/tracks/images/ukg.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class UkgIntegration extends IntegrationDefinitionBase {
    override name = 'ukg';
    override displayName = 'ukg';
    override description = 'We will only collect employees and jobs items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override entities = [new IntegrationEntity('Employee', 'Employees'), new IntegrationEntity('Job', 'Jobs')];
    override customizedAuthenticationComponent = UkgAuthenticationComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(ukgCircleIcon, '130px'),
        new IntegrationIcon(ukgIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: { Employee: true, Job: true },
        isOnlyDataRetention: {},
    };
}

export default UkgIntegration;
