import React, { useContext } from 'react';
import styled from 'styled-components';

import { Checkbox, Field, SavingIndicator } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import { FormBuilderSections } from '@tonkean/tonkean-entities';

const Hr = styled.hr`
    width: 100%;
`;

interface Props {
    canSkipUpload: boolean;
    onFormDefinitionChanged: (canSkipUpload: boolean) => void;
}

const FormSettingsUploadFilesCanSkip: React.FC<Props> = ({ canSkipUpload, onFormDefinitionChanged }) => {
    const { editedSections } = useContext(FormBuilderContext);
    return (
        <>
            <Hr />
            <Field className="flex-vmiddle">
                <Checkbox checked={canSkipUpload} onChange={(event) => onFormDefinitionChanged(event.target.checked)}>
                    Can Skip Upload?
                </Checkbox>
                <SavingIndicator state={editedSections[FormBuilderSections.CanSkipUpload]} />
            </Field>
        </>
    );
};

export default FormSettingsUploadFilesCanSkip;
