/**
 * Represents an icon to be used by an integration.
 */

type StaticImageData = { src: string; height: number; width: number; blurDataURL?: string };
class IntegrationIcon {
    constructor(
        /**
         * The path to the icon, relative to where we are at the app now.
         */
        public iconPath: string | StaticImageData,

        /**
         * The size of the icon.
         */
        public iconSize: string,
    ) {}
}

export default IntegrationIcon;
