import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function AmazonS3CustomActionsDefinitionCtrl(
    $scope,
    projectManager,
    integrationsConsts,
    utils,
    integrations,
) {
    const ctrl = this;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        fileEntityMetadata: integrationsConsts.getEntitiesMetadata()['AMAZONS3']['FILE'],
        loading: false,
    };

    ctrl.$onInit = function () {
        if ($scope.data.configuredLogic && $scope.data.definition) {
            initializeEditMode();
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.onUrlExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.urlExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDestinationFolderExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationFolderIdExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFileNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fileNameExpresssion = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onNewFileNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.newFileNameExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onBucketNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.bucketNameExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onExpirationDurationExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.expirationDurationExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFileKeyExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fileKeyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (
            customTriggerAction &&
            customTriggerAction.customTriggerActionDefinition &&
            customTriggerAction.customTriggerActionDefinition.performedActionDefinition
        ) {
            const definition = customTriggerAction.customTriggerActionDefinition.performedActionDefinition;
            if (definition.role) {
                $scope.data.selectedRole = utils.findFirst(
                    $scope.data.roleOptions,
                    (role) => role.key === definition.role,
                );
            }
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('AmazonS3CustomActionsDefinitionCtrl', lateConstructController(AmazonS3CustomActionsDefinitionCtrl));
