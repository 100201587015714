import template from './tnkWorkerFormEditor.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerFormEditor', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        formId: '<',
        onFormChanged: '&',
        onFormCreated: '&',
        formTypeToCreate: '<',
        advancedSettingsOpen: '<',
        isInFormBuilder: '<',
        setFormFetchTrigger: '<',
        onFieldsChange: '<',
        onFormQuestionChange: '<',
        form: '<',
    },
    template,
    controller: 'WorkerFormEditorCtrl',
});
