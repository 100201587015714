import React from 'react';

import workerRunReasonConfig from '../../../entities/workerRunReasonConfig';
import { SyncStatus } from '../../../entities/WorkerRunReasonConfigItem';
import SingleWorkerRunInformationItem from '../SingleWorkerRunInformationItem';
import SingleWorkerRunInformationRow from '../SingleWorkerRunInformationRow';
import SingleWorkerRunWorkflowVersionInformationItem from '../SingleWorkerRunWorkflowVersionInformationItem';

import { TextEllipsis } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';

const SyncStatusToDisplayText: Record<SyncStatus, string> = {
    [SyncStatus.MET]: 'Met',
    [SyncStatus.NOT_MET]: 'Not met',
    [SyncStatus.DISABLED]: 'Data source disabled',
};

interface Props {
    workerRun?: WorkerRun;
}

const SingleWorkerRunDataSourceDetails: React.FC<Props> = ({ workerRun }) => {
    const syncStatus = workerRun?.workerRunReason && workerRunReasonConfig[workerRun.workerRunReason].syncStatus;

    return (
        <SingleWorkerRunInformationRow>
            <SingleWorkerRunInformationItem
                title="Involved Item"
                value={
                    workerRun && (
                        <TextEllipsis numberOfLines={2} tooltip>
                            {workerRun.externalActivity?.title || 'Deleted Item'}
                        </TextEllipsis>
                    )
                }
                placeholderCount={2}
            />

            <SingleWorkerRunInformationItem
                title="Sync Status"
                value={syncStatus !== undefined && SyncStatusToDisplayText[syncStatus]}
            />

            <SingleWorkerRunWorkflowVersionInformationItem workerRun={workerRun} />
        </SingleWorkerRunInformationRow>
    );
};

export default SingleWorkerRunDataSourceDetails;
