import React, { useCallback, useMemo, useRef } from 'react';
import { VariableSizeList } from 'react-window';

import FieldInspectRow from './FieldInspectRow';

const ITEM_HEIGHT = 49;
const OVERSCAN_ITEMS_COUNT = 10;

interface Props {
    entityFields: any[];
    viewOnly: boolean;
    fieldQuery: string;
    onFieldSelected: ((field: any) => void) | undefined;
    multiSelectMap: Record<string, any>;
}

const FieldInspectList: React.FC<Props> = ({ entityFields, viewOnly, onFieldSelected, fieldQuery, multiSelectMap }) => {
    const sizeMapRef = useRef<Record<string, number | undefined>>({});
    const listRef = useRef<VariableSizeList | null>(null);
    const setItemSize = useCallback((index: number, size: number) => {
        sizeMapRef.current = { ...sizeMapRef.current, [index]: size };

        // This tells the list to re-render the items, so that the view updates
        listRef.current?.resetAfterIndex(index);
    }, []);
    const getItemSize = useCallback((index) => {
        const itemSize = sizeMapRef.current[index];
        return itemSize && itemSize >= ITEM_HEIGHT ? itemSize : ITEM_HEIGHT;
    }, []);

    const itemData = useMemo(
        () => ({
            fields: entityFields,
            viewOnly,
            fieldQuery,
            onFieldSelected,
            multiSelectMap,
            setItemSize,
            defaultRowHeight: ITEM_HEIGHT,
        }),
        [entityFields, fieldQuery, multiSelectMap, onFieldSelected, setItemSize, viewOnly],
    );

    return (
        <VariableSizeList
            height={300}
            width="100%"
            itemCount={entityFields.length}
            itemSize={getItemSize}
            overscanCount={OVERSCAN_ITEMS_COUNT}
            itemData={itemData}
            ref={listRef}
        >
            {FieldInspectRow}
        </VariableSizeList>
    );
};

export default FieldInspectList;
