import { useAngularService } from 'angulareact';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import UploadWidgetHeaderActions from './UploadWidgetHeaderActions';
import ItemInterfaceModal from '../../components/ItemInterfaceModal';
import WidgetRequiredIndication from '../../components/WidgetRequiredIndication';
import type { ItemInterfacePermission } from '../../entities';
import SystemInterfaceModal from '../../SystemInterface/components/SystemInterface/SystemInterfaceModal';
import InterfaceSubmitValidationContext from '../../utils/InterfaceSubmitValidationContext';
import { ItemWidget, ItemWidgetHeaderTitle } from '../../WidgetModule';
import { ItemsGridWithValidation } from '../CommonWidgetComponents';
import type { UploadWidgetConfiguration } from '../entities';
import useItemsGridInlineMenuActions from '../hooks/useItemsGridInlineMenuActions';
import useDrilldownInterface from '../InnerItemsWidgetModule/hooks/useDrilldownInterface';
import useFieldsToShow from '../LineItemsWidgetModule/hooks/useFieldsToShow';
import useItemsGridCrudHandlers from '../LineItemsWidgetModule/hooks/useItemsGridCrudHandlers';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { FieldError, H3, TextEllipsis, useDebouncedState, useIsMobile, useInitiatives } from '@tonkean/infrastructure';
import { useItemsGridRowsManager } from '@tonkean/items-grid';
import { EmptyUploadWidgetIcon } from '@tonkean/svg';
import {
    type Initiative,
    type ItemInterfaceHeaderThemeConfiguration,
    type ItemInterfaceWidget,
    type TonkeanId,
    TonkeanType,
    tonkeanTypeToIdentifyingPrefixMap,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { EMPTY_ARRAY } from '@tonkean/utils';

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const EmptyState = styled.div`
    height: 255px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
`;

const VerticalCenter = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    permission: ItemInterfacePermission;
    initiative: Initiative | undefined;
    widget: ItemInterfaceWidget<UploadWidgetConfiguration>;
    theme: ItemInterfaceHeaderThemeConfiguration;
    groupId?: TonkeanId<TonkeanType.GROUP>;
}

const UploadWidget: React.FC<Props> = ({ projectId, permission, initiative, widget, theme, groupId }) => {
    const authenticationService = useAngularService('authenticationService');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useDebouncedState(debouncedSearchTerm, setDebouncedSearchTerm);
    const {
        workflowVersion,
        itemInterface,
        itemInterfaceFieldDefinitions: allFieldDefinitions,
        loadingItemInterfaceFieldDefinitions: fieldDefinitionsLoading,
    } = useItemInterfaceContext();
    const isMobile = useIsMobile();

    const { forceShowValidation, onValidationChanged } = useContext(InterfaceSubmitValidationContext);

    const [searchInitiatives, { loading, initiatives, executionDate, isFetched }] = useInitiatives(
        50,
        30_000,
        widget.projectId,
        { key: 'created', desc: true },
    );

    const currentUserId = authenticationService.getCurrentUser().id;
    const reloadInitiatives = useCallback(() => {
        if (initiative?.id && groupId) {
            searchInitiatives(widget.projectId, {
                conditionsQuery: widget.configuration.filters,
                filterByCreatorIds: widget.configuration.showUsersFilesOnly ? [currentUserId] : [],
                isArchived: false,
                groupId,
                isDraftInitiatives: initiative?.isDraftInitiative,
                isEditModeInitiatives: false,
                parentId: initiative?.id,
                // TODO we should replace the hard coded skip & limit to - SKIP_PARAM, LIMIT_PARAM.
                //      For now its not supported because the skip & limit is inside object and the fetch manager (that used in useInitiative) is not supported skip/limit that inside object.
                //      its not urgent because the line items support max of 50 items but for future usage of this component need to fix that.
                skip: 0,
                limit: 50,
                orderByFieldId: 'TNK_CREATED_DATE',
                orderByFieldType: 'Date',
                orderByType: 'DESC',
            });
        }
    }, [
        initiative?.id,
        initiative?.isDraftInitiative,
        groupId,
        searchInitiatives,
        widget.projectId,
        widget.configuration.filters,
        widget.configuration.showUsersFilesOnly,
        currentUserId,
    ]);

    useLayoutEffect(() => {
        reloadInitiatives();
    }, [widget.configuration.filters, groupId, initiative?.id, searchInitiatives, widget.projectId, reloadInitiatives]);

    const fieldsToShow = useFieldsToShow(widget);

    const fieldDefinitionKeyToLabel: Record<FieldDefinitionKey, string | undefined> = useMemo(() => {
        return Object.fromEntries(
            Object.entries(widget.configuration.fields).map(
                ([key, configuration]) => [key, configuration.label] as const,
            ),
        );
    }, [widget.configuration.fields]);

    const { modalIsOpen, modalInitiativeId, onClose, onRowClicked } = useDrilldownInterface();

    const minimumItemsRequired = widget.configuration.minMaxRequirementConfiguration?.minimum ?? 0;
    const maximumItemsRequired = widget.configuration.minMaxRequirementConfiguration?.maximum ?? 50;

    const inlineActions = useItemsGridInlineMenuActions(
        widget.configuration?.actions || EMPTY_ARRAY,
        workflowVersion?.workflowVersionType || WorkflowVersionType.PUBLISHED,
        itemInterface.group.id,
    );

    const fieldDefinitions = useMemo(() => {
        return allFieldDefinitions.filter(
            (field) =>
                fieldsToShow.includes(field.id) &&
                field.id.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.FIELD_DEFINITION]),
        );
    }, [allFieldDefinitions, fieldsToShow]);

    const onValidationResultChanged = useCallback(
        (errors: string[]) => {
            onValidationChanged(errors, widget.id);
        },
        [onValidationChanged, widget.id],
    );

    const { handleDelete } = useItemsGridCrudHandlers(
        projectId,
        groupId,
        initiative?.id,
        workflowVersion,
        undefined,
        undefined,
    );
    const { items, appendServerRow, onDelete } = useItemsGridRowsManager(
        initiatives,
        fieldDefinitions,
        isFetched && !loading.initial,
        executionDate,
        false,
        minimumItemsRequired,
        maximumItemsRequired,
        undefined,
        onValidationResultChanged,
        undefined,
        undefined,
        handleDelete,
        true,
    );

    const onFilesAdded = useCallback(
        (newInitiatives: Initiative[]) => {
            newInitiatives.forEach(appendServerRow);

            return Promise.resolve();
        },
        [appendServerRow],
    );

    return (
        <ItemWidget
            permission={permission}
            headerTitle={
                <TitleContainer>
                    <ItemWidgetHeaderTitle>
                        {widget.configuration?.wrapDisplayName ? (
                            widget.displayName
                        ) : (
                            <TextEllipsis numberOfLines={1}>{widget.displayName}</TextEllipsis>
                        )}
                    </ItemWidgetHeaderTitle>
                    {minimumItemsRequired > 0 && (
                        <WidgetRequiredIndication type={widget.configuration.requiredIndicatorType} />
                    )}
                </TitleContainer>
            }
            headerActions={
                <>
                    {!isMobile && (
                        <UploadWidgetHeaderActions
                            initiative={initiative}
                            searchText={searchTerm}
                            setSearchText={setSearchTerm}
                            widget={widget}
                            workflowVersionType={workflowVersion?.workflowVersionType}
                            headerBackgroundColor={theme.headerBackgroundColor}
                            groupId={groupId}
                            onFilesAdded={onFilesAdded}
                        />
                    )}
                </>
            }
            subHeader={
                <>
                    {isMobile && (
                        <UploadWidgetHeaderActions
                            initiative={initiative}
                            searchText={searchTerm}
                            setSearchText={setSearchTerm}
                            widget={widget}
                            workflowVersionType={workflowVersion?.workflowVersionType}
                            headerBackgroundColor={theme.headerBackgroundColor}
                            groupId={groupId}
                            onFilesAdded={onFilesAdded}
                        />
                    )}
                </>
            }
            showIcon={widget.configuration.icon !== undefined}
            itemWidgetBodyMinHeight={100}
            disableMinHeight
            noPaddingBody
        >
            {items?.length === 0 && !loading.initial && searchTerm === '' ? (
                <VerticalCenter>
                    <EmptyState data-automation="upload-widget-no-files">
                        <EmptyUploadWidgetIcon />
                        <H3 $color={Theme.colors.gray_700} $bold>
                            {widget.configuration.emptyStateTitle || 'No files to show'}
                        </H3>
                        {widget.configuration.emptyStateSubTitle ||
                            'There are no files in this folder. Upload your files to see them here.'}
                    </EmptyState>
                    {minimumItemsRequired > 0 && forceShowValidation && (
                        <FieldError
                            $inlineError={false}
                            $textAlign="right"
                            data-automation="empty-upload-widget-error-message"
                        >
                            <div>You have not reached the required minimum of {minimumItemsRequired} items</div>
                        </FieldError>
                    )}
                </VerticalCenter>
            ) : (
                <ItemsGridWithValidation
                    rowData={items}
                    parentItemId={initiative?.id}
                    searchTerm={debouncedSearchTerm}
                    groupId={initiative?.group.id}
                    workflowVersion={workflowVersion}
                    projectId={widget.projectId}
                    userCanEdit={false}
                    onDelete={onDelete}
                    fieldDefinitions={fieldDefinitions}
                    fieldDefinitionsToShow={fieldsToShow}
                    fieldDefinitionKeyToName={fieldDefinitionKeyToLabel}
                    onRowClicked={widget.configuration.allowDrillDown ? onRowClicked : undefined}
                    widgetId={widget.id}
                    backgroundColor={theme.headerBackgroundColor}
                    inlineMenuActions={inlineActions}
                    loading={loading.initial || !isFetched || fieldDefinitionsLoading}
                    forceGridHeight
                    showOptionsMenu
                />
            )}
            {widget.configuration.drillDownInterface && modalInitiativeId && initiative && (
                <ItemInterfaceModal
                    itemInterfaceId={widget.configuration.drillDownInterface}
                    initiativeId={modalInitiativeId}
                    projectId={initiative.project.id}
                    onClose={onClose}
                    open={modalIsOpen}
                />
            )}
            {!widget.configuration.drillDownInterface && (
                <SystemInterfaceModal open={modalIsOpen} initiativeId={modalInitiativeId} onClose={onClose} />
            )}
        </ItemWidget>
    );
};

export default UploadWidget;
