import type { TonkeanType } from '@tonkean/tonkean-entities';

function reverseIdentifyingStringMap(map: Partial<Record<TonkeanType, string>>): Record<string, TonkeanType> {
    const reversedMap: Record<string, TonkeanType> = {};
    Object.entries(map).forEach(([key, value]) => {
        if (value) {
            reversedMap[value] = key as TonkeanType;
        }
    });
    return reversedMap;
}

export default reverseIdentifyingStringMap;
