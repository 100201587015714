import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class StringJoinFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.STRING_JOIN;
    override readonly sign = 'StringJoin';
    override readonly displayName = 'String Join';
    override readonly description = 'Will concatenate given values with a comma separating between values';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Value',
            pluralName: 'Values',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 3,
            generator: (repeatIndex) => ({
                fieldDefinitionType: FieldDefinitionType.SINGLE,
                dataType: FieldType.String,
                displayName: `Value ${repeatIndex}`,
                // The second value should be a space as default
                defaultValue: repeatIndex === 2 ? ' ' : undefined,
            }),
        },
    ];
}
