<tnk-form-page-modal
    form-id="data.formId"
    workflow-version-id="data.workflowVersionId"
    form-version-type="data.workflowVersionType"
    group-id="data.groupId"
    initiative-id="data.initiativeId"
    on-close="(close)"
    on-initiative-created="(onInitiativeCreatedByForm)"
    parent-initiative-id="data.parentInitiativeId"
    project="data.project"
    open="data.isOpen"
></tnk-form-page-modal>
