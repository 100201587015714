import template from './externalEntitySelector.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkExternalEntitySelector', {
    bindings: {
        projectIntegrationId: '<',
        externalType: '<',
        onlyUpdateable: '<',
        externalTypePluralName: '<',
        selectedEntity: '<',
        onEntitySelected: '<',
    },
    template,
    controller: 'ExternalEntitySelectorCtrl',
    controllerAs: '$ctrl',
});
