import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ColorOptionsPicker($scope) {
    const ctrl = this;

    $scope.data = {
        selectedColor: ctrl.selectedColor,
        isDisabled: ctrl.isDisabled,
        colorOptions: ctrl.colorOptions,
        forceClose: ctrl.forceClose,

        isOpen: false,
    };

    ctrl.$onChanges = function (changesObj) {
        // Close only if force close changed to true
        if (changesObj.forceClose && changesObj.forceClose.currentValue) {
            $scope.data.isOpen = false;
        }
    };

    $scope.onColorSelected = function (colorName, color) {
        $scope.data.selectedColor = color;
        $scope.data.isOpen = false;

        if (ctrl.onColorSelected) {
            ctrl.onColorSelected({ color, colorName });
        }
    };
}

export default angular.module('tonkean.app').controller('ColorOptionsPicker', lateConstructController(ColorOptionsPicker));
