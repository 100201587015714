<div class="org-content-page org-content-padded" style="padding: 50px 30px 0 30px">
    <p class="common-title-inner mod-no-top">Administrators</p>

    <p class="common-subtitle-inner">Board admins can edit permissions, change group memberships, and control data sources permissions and settings.</p>

    <div class="margin-normal-v">
        <div class="clearfix" ng-hide="showEditOwners">
            <div
                class="common-tag-full mod-padding-sm pull-left margin-right"
                ng-repeat="owner in pm.project.owners | filter:{systemUtilized: false}"
            >
                <tnk-user user="owner" class="mod-one-line margin-right"></tnk-user>
            </div>
            <button class="btn btn-default pull-left settings-edit-owners" ng-show="pm.isOwner" ng-click="editOwners()">
                <i class="fa fa-pencil"></i>
                Edit Admins
            </button>
        </div>
        <form novalidate name="ownersForm" class="" ng-show="showEditOwners" ng-submit="saveOwners()">
            <tnk-deprecated-people-selector
                class="flex-grow margin-right"
                required
                ng-disabled="savingOwners"
                selected-people="owners"
                no-custom="true"
                no-validation="true"
                show-intro="true"
            ></tnk-deprecated-people-selector>
            <div class="margin-top">
                <button
                    type="button"
                    class="btn btn-default margin-right-xs"
                    ng-click="showEditOwners=false"
                    ng-disabled="savingOwners"
                >
                    Cancel
                </button>
                <button type="submit" class="btn btn-primary" ng-disabled="savingOwners">Save</button>
            </div>
        </form>
    </div>

    <!-- Separator -->
    <hr />

    <!-- Default actor selection -->
    <div class="flex-vmiddle mod-align-start">
        <!-- Title -->
        <div class="common-size-xs margin-right">Default Actor:</div>

        <!-- Single people selector section -->
        <div>
            <!-- Single people selector -->
            <div class="flex-vmiddle">
                <tnk-single-person-selector
                    on-person-selected="onDefaultActorSelected(person)"
                    on-person-removed="onDefaultActorSelected(null)"
                    selected-person-id="defaultActor.id"
                ></tnk-single-person-selector>

                <span ng-if="loadingSavingDefaultActor" class="margin-left loading-small"></span>
            </div>

            <!-- Default actor selection explanation -->
            <div class="common-size-xxxxs common-color-light-grey margin-top">
                Selected person will be set the default actor for Tonkean actions.
            </div>

            <!-- Error message -->
            <div
                class="common-color-danger common-size-xxxxs common-color-light-grey margin-top-xs"
                ng-if="errorLoadingSavingDefaultActor"
            >
                <span>There was an error trying to update default actor.</span>
                <a ng-click="onDefaultActorSelected(defaultActor)">Retry</a>
            </div>

            <!-- Null message -->
            <div
                class="common-color-danger common-size-xxxxs common-color-light-grey margin-top-xs"
                ng-if="!defaultActor"
            >
                You must select a default actor.
            </div>
        </div>
    </div>

    <!-- Separator -->
    <hr />

    <div>
        <tnk-board-support-user-access></tnk-board-support-user-access>
    </div>
    <!-- Separator -->
    <hr />

    <div class="margin-bottom-xlg">
        <p class="common-title-inner mod-no-top">Solution Publish Settings</p>

        <div class="margin-bottom common-bold common-color-black">
            When this settings is off, the makers of the solution will be allowed to publish.
        </div>

        <!-- Allow publishers management in solutions -->
        <label class="common-thin margin-top-xs flex mod-align-center">
            <input
                type="checkbox"
                ng-model="data.allowPublishersManagement"
                ng-change="updateAllowPublishersManagement()"
            />
            <span class="margin-left-xs margin-top-xs">Allow publishers management</span>
            <i
                class="fa fa-info-circle common-color-primary margin-left-xs"
                uib-tooltip="Enabling this option will allow managing permissions for publishing a solution."
            ></i>
        </label>


        <label class="common-thin margin-top-xs flex mod-align-center">

            <input
              type="checkbox"
              ng-model="data.isOnlyAdminAllowToCreateSolution"
              ng-change="updateAllowBoardAdminsToCreateSolutions()"
            />

                <span class="margin-left-xs margin-top-xs">Restrict solution creation only to board admins</span>
            <i
              class="fa fa-info-circle common-color-primary margin-left-xs"
              uib-tooltip="Enabling this option will allow only admins to create solutions."
            ></i>
        </label>
    </div>

    <!-- Invite settings -->
    <div>
        <p class="common-title-inner mod-no-top">Invite settings</p>

        <p>
            <!-- Allow setting owners that are not collaborators -->
            <label class="common-thin margin-top-xs">
                <input type="checkbox" ng-model="data.allowOwnersNotInTeam" ng-change="updateAllowOwnersNotInTeam()" />
                <span class="margin-left-xs">
                    Allow users to set owners and add collaborators outside of your account
                </span>
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Enabling this option will allow users to set owners and collaborators who are not in this board. By doing that, they will invite the outside users and grant them access to your public modules."
                ></i>
            </label>
        </p>
    </div>
</div>
