import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class InnerItemsCountFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.INNER_ITEMS_COUNT;
    override readonly sign = 'InnerItemsCount';
    override readonly displayName = 'Inner Items Count';
    override readonly description = 'Will return the number of inner items.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Ignored',
            dataType: FieldType.String,
            defaultValue: '',
            defaultTab: FormulaPopoverTab.CONST,
        },
    ];
}
