import initiatives_0000_01DesktopImage from '../../../images/builtInLists/previews/desktop/product/Initiatives_0000_01.jpg';
import initiatives_0000_01MobileImage from '../../../images/builtInLists/previews/mobile/product/Initiatives_0000_01.png';
import initiatives_0001_02DesktopImage from '../../../images/builtInLists/previews/desktop/product/Initiatives_0001_02.jpg';
import initiatives_0001_02MobileImage from '../../../images/builtInLists/previews/mobile/product/Initiatives_0001_02.png';
import initiatives_0002_03DesktopImage from '../../../images/builtInLists/previews/desktop/product/Initiatives_0002_03.jpg';
import initiatives_0002_03MobileImage from '../../../images/builtInLists/previews/mobile/product/Initiatives_0002_03.png';
import initiatives_0003_04DesktopImage from '../../../images/builtInLists/previews/desktop/product/Initiatives_0003_04.jpg';
import initiatives_0003_04MobileImage from '../../../images/builtInLists/previews/mobile/product/Initiatives_0003_04.png';
import initiatives_0004_05DesktopImage from '../../../images/builtInLists/previews/desktop/product/Initiatives_0004_05.jpg';
import initiatives_0004_05MobileImage from '../../../images/builtInLists/previews/mobile/product/Initiatives_0004_05.png';
import milestones_0000_01DesktopImage from '../../../images/builtInLists/previews/desktop/product/Milestones_0000_01.jpg';
import milestones_0000_01MobileImage from '../../../images/builtInLists/previews/mobile/product/Milestones_0000_01.png';
import milestones_0001_02DesktopImage from '../../../images/builtInLists/previews/desktop/product/Milestones_0001_02.jpg';
import milestones_0001_02MobileImage from '../../../images/builtInLists/previews/mobile/product/Milestones_0001_02.png';
import milestones_0002_03DesktopImage from '../../../images/builtInLists/previews/desktop/product/Milestones_0002_03.jpg';
import milestones_0002_03MobileImage from '../../../images/builtInLists/previews/mobile/product/Milestones_0002_03.png';
import milestones_0003_04DesktopImage from '../../../images/builtInLists/previews/desktop/product/Milestones_0003_04.jpg';
import milestones_0003_04MobileImage from '../../../images/builtInLists/previews/mobile/product/Milestones_0003_04.png';
import milestones_0004_05DesktopImage from '../../../images/builtInLists/previews/desktop/product/Milestones_0004_05.jpg';
import milestones_0004_05MobileImage from '../../../images/builtInLists/previews/mobile/product/Milestones_0004_05.png';
import customerSuccess_0000_01DesktopImage from '../../../images/builtInLists/previews/desktop/csm/CustomerSuccess_0000_01.jpg';
import customerSuccess_0000_01MobileImage from '../../../images/builtInLists/previews/mobile/csm/CustomerSuccess_0000_01.png';
import customerSuccess_0001_02DesktopImage from '../../../images/builtInLists/previews/desktop/csm/CustomerSuccess_0001_02.jpg';
import customerSuccess_0001_02MobileImage from '../../../images/builtInLists/previews/mobile/csm/CustomerSuccess_0001_02.png';
import customerSuccess_0002_03DesktopImage from '../../../images/builtInLists/previews/desktop/csm/CustomerSuccess_0002_03.jpg';
import customerSuccess_0002_03MobileImage from '../../../images/builtInLists/previews/mobile/csm/CustomerSuccess_0002_03.png';
import customerSuccess_0003_04DesktopImage from '../../../images/builtInLists/previews/desktop/csm/CustomerSuccess_0003_04.jpg';
import customerSuccess_0003_04MobileImage from '../../../images/builtInLists/previews/mobile/csm/CustomerSuccess_0003_04.png';
import customerSuccess_0004_05DesktopImage from '../../../images/builtInLists/previews/desktop/csm/CustomerSuccess_0004_05.jpg';
import customerSuccess_0004_05MobileImage from '../../../images/builtInLists/previews/mobile/csm/CustomerSuccess_0004_05.png';
import salesForecast_0000_01DesktopImage from '../../../images/builtInLists/previews/desktop/sales/Sales_Forecast_0000_01.jpg';
import salesForecast_0000_01MobileImage from '../../../images/builtInLists/previews/mobile/sales/SalesForecast_0000_01.png';
import salesForecast_0001_02DesktopImage from '../../../images/builtInLists/previews/desktop/sales/Sales_Forecast_0001_02.jpg';
import salesForecast_0001_02MobileImage from '../../../images/builtInLists/previews/mobile/sales/SalesForecast_0001_02.png';
import salesForecast_0002_03DesktopImage from '../../../images/builtInLists/previews/desktop/sales/Sales_Forecast_0002_03.jpg';
import salesForecast_0002_03MobileImage from '../../../images/builtInLists/previews/mobile/sales/SalesForecast_0002_03.png';
import salesForecast_0003_04DesktopImage from '../../../images/builtInLists/previews/desktop/sales/Sales_Forecast_0003_04.jpg';
import salesForecast_0003_04MobileImage from '../../../images/builtInLists/previews/mobile/sales/SalesForecast_0003_04.png';
import salesForecast_0004_05DesktopImage from '../../../images/builtInLists/previews/desktop/sales/Sales_Forecast_0004_05.jpg';
import salesForecast_0004_05MobileImage from '../../../images/builtInLists/previews/mobile/sales/SalesForecast_0004_05.png';
import { integrationsService } from '@tonkean/integrations';
import { WORKER_TYPES } from '@tonkean/constants';
import Utils from '@tonkean/utils';

function BuiltInListsUtils() {
    const _this = this;
    const allNativeIntegrations = integrationsService.getAllIntegrationTypes({ communication: true });

    _this.variable = function (key, required) {
        return { key, fromValues: true, required };
    };

    _this.integrationGroups = integrationsService.getIntegrationGroups();
    _this.defaultFrames = {
        INITIATIVES: [
            {
                title: 'Your list of initiatives is created',
                image: initiatives_0000_01DesktopImage,
                mobileImage: initiatives_0000_01MobileImage,
                thumbnail: initiatives_0000_01DesktopImage,
            },
            {
                title: 'Our Module pings the owner for an update',
                image: initiatives_0001_02DesktopImage,
                mobileImage: initiatives_0001_02MobileImage,
                thumbnail: initiatives_0001_02DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'Item owner writes an update',
                image: initiatives_0002_03DesktopImage,
                mobileImage: initiatives_0002_03MobileImage,
                thumbnail: initiatives_0002_03DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'Track status updates instantly',
                image: initiatives_0003_04DesktopImage,
                mobileImage: initiatives_0003_04MobileImage,
                thumbnail: initiatives_0003_04DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'All items are updated',
                image: initiatives_0004_05DesktopImage,
                mobileImage: initiatives_0004_05MobileImage,
                thumbnail: initiatives_0004_05DesktopImage,
            },
        ],
        MILESTONES: [
            {
                title: 'Your list of milestones is created',
                image: milestones_0000_01DesktopImage,
                mobileImage: milestones_0000_01MobileImage,
                thumbnail: milestones_0000_01DesktopImage,
            },
            {
                title: 'Our Module pings the owner for an update',
                image: milestones_0001_02DesktopImage,
                mobileImage: milestones_0001_02MobileImage,
                thumbnail: milestones_0001_02DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'Track owner writes an update',
                image: milestones_0002_03DesktopImage,
                mobileImage: milestones_0002_03MobileImage,
                thumbnail: milestones_0002_03DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'Track status updates instantly',
                image: milestones_0003_04DesktopImage,
                mobileImage: milestones_0003_04MobileImage,
                thumbnail: milestones_0003_04DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'All items are updated',
                image: milestones_0004_05DesktopImage,
                mobileImage: milestones_0004_05MobileImage,
                thumbnail: milestones_0004_05DesktopImage,
            },
        ],
        ACCOUNTS: [
            {
                title: 'Your list of top customers is created',
                image: customerSuccess_0000_01DesktopImage,
                mobileImage: customerSuccess_0000_01MobileImage,
                thumbnail: customerSuccess_0000_01DesktopImage,
            },
            {
                title: 'Our Module pings the owner for an update',
                image: customerSuccess_0001_02DesktopImage,
                mobileImage: customerSuccess_0001_02MobileImage,
                thumbnail: customerSuccess_0001_02DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'Item owner writes an update',
                image: customerSuccess_0002_03DesktopImage,
                mobileImage: customerSuccess_0002_03MobileImage,
                thumbnail: customerSuccess_0002_03DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'Track status updates instantly',
                image: customerSuccess_0003_04DesktopImage,
                mobileImage: customerSuccess_0003_04MobileImage,
                thumbnail: customerSuccess_0003_04DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'All items are updated',
                image: customerSuccess_0004_05DesktopImage,
                mobileImage: customerSuccess_0004_05MobileImage,
                thumbnail: customerSuccess_0004_05DesktopImage,
            },
        ],
        SALES: [
            {
                title: 'Your list of opportunities is created',
                image: salesForecast_0000_01DesktopImage,
                mobileImage: salesForecast_0000_01MobileImage,
                thumbnail: salesForecast_0000_01DesktopImage,
            },
            {
                title: 'Our Module pings the owner for an update',
                image: salesForecast_0001_02DesktopImage,
                mobileImage: salesForecast_0001_02MobileImage,
                thumbnail: salesForecast_0001_02DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'Item owner writes an update',
                image: salesForecast_0002_03DesktopImage,
                mobileImage: salesForecast_0002_03MobileImage,
                thumbnail: salesForecast_0002_03DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'Track status updates instantly',
                image: salesForecast_0003_04DesktopImage,
                mobileImage: salesForecast_0003_04MobileImage,
                thumbnail: salesForecast_0003_04DesktopImage,
                hideTitleOnMobile: true,
            },
            {
                title: 'All items are updated',
                image: salesForecast_0004_05DesktopImage,
                mobileImage: salesForecast_0004_05MobileImage,
                thumbnail: salesForecast_0004_05DesktopImage,
            },
        ],
    };
    _this.builtInIntegrationsSteps = {
        SELECT_SOURCE_INITIATIVES: {
            type: 'plainConfig',
            data: {
                defaultStepsId: 'MANUAL_INITIATIVES',
                template: 'integrationTypeTemplate',
            },
        },
        SELECT_SOURCE_ACCOUNTS: {
            type: 'plainConfig',
            data: {
                defaultStepsId: 'MANUAL_ACCOUNTS',
                template: 'integrationTypeTemplate',
            },
        },
        CRM: {
            type: 'integration',
            data: {
                sourceType: 'CRM',
                title: 'CRM',
                subtitle: 'System of records that hold your pipeline of opportunities/deals',
                integrations: _this.integrationGroups.sales.integrations.concat(
                    _this.integrationGroups.webhooks.integrations,
                ),
            },
        },
        ANALYTICS: {
            type: 'integration',
            optional: true,
            data: {
                sourceType: 'ANALYTICS',
                title: 'Analytics system',
                subtitle: 'System of records that hold analytics of your users activity',
                integrations: ['google'],
            },
        },
        SUPPORT: {
            type: 'integration',
            optional: true,
            data: {
                sourceType: 'SUPPORT',
                title: 'Support ticketing system',
                subtitle:
                    'System of records that hold your customer support tickets/issues and customer communications',
                integrations: _this.integrationGroups.support.integrations
                    .concat(_this.integrationGroups.sales.integrations)
                    .concat(_this.integrationGroups.webhooks.integrations),
            },
        },
    };
    _this.workerTypes = WORKER_TYPES;
    _this.defaultSync = {
        INITIATIVES: {
            editableSyncOnlyOnClick: false,
            integrationType: 'PRODUCT',
            viewData: {
                // this is the sync view data, will be sent to server as is (after compile of values)
                entity: _this.variable('SYNC_ENTITY_TYPE'),
                query: _this.variable('SYNC_QUERY'),
            },
        },
        ACCOUNTS: {
            editableSyncOnlyOnClick: false,
            integrationType: 'ACCOUNTS',
            viewData: {
                // this is the sync view data, will be sent to server as is (after compile of values)
                entity: _this.variable('SYNC_ENTITY_TYPE'),
                query: _this.variable('SYNC_QUERY'),
            },
        },
        SALES: {
            editableSyncOnlyOnClick: true,
            integrationType: 'CRM',
            viewData: {
                // this is the sync view data, will be sent to server as is (after compile of values)
                entity: _this.variable('SYNC_ENTITY_TYPE'),
                query: {
                    type: 'And',
                    filters: [
                        { fieldName: 'TNK_CLOSE_DATE', condition: 'InThis', value: _this.variable('DATE_RANGE') },
                        { fieldName: 'TNK_IS_CLOSED', condition: 'Equals', value: 'False' },
                    ],
                },
            },
        },
    };
    _this.builtInColumns = {
        OPP_AMOUNT: {
            integrationType: 'CRM',
            fieldDefinition: {
                groupId: _this.variable('GROUP_ID', true),
                name: 'Amount',
                type: 'EXTERNAL',
                fieldType: 'Number',
                displayAs: 'Number',
                displayFormat: _this.variable('DISPLAY_FORMAT_API_NAME'),
                displayFormatPrefix: _this.variable('DISPLAY_FORMAT_PREFIX'),
                displayFormatPostfix: _this.variable('DISPLAY_FORMAT_POSTFIX'),
                definition: {
                    ExternalType: 'TNK_OPP',
                    FieldName: 'TNK_AMOUNT',
                },
                ranges: [],
            },
        },
        OPP_CLOSE_DATE: {
            integrationType: 'CRM',
            fieldDefinition: {
                groupId: _this.variable('GROUP_ID', true),
                name: 'Close Date',
                type: 'EXTERNAL',
                definition: {
                    ExternalType: 'TNK_OPP',
                    FieldName: 'TNK_CLOSE_DATE',
                },
                ranges: [],
            },
        },
        OPP_PROBABILITY: {
            integrationType: 'CRM',
            fieldDefinition: {
                groupId: _this.variable('GROUP_ID', true),
                name: 'Probability',
                type: 'EXTERNAL',
                fieldType: 'Number',
                displayAs: 'Number',
                displayFormat: 'PERCENTAGE',
                displayFormatPrefix: '',
                displayFormatPostfix: '%',
                definition: {
                    ExternalType: 'TNK_OPP',
                    FieldName: 'TNK_PROBABILITY',
                },
                ranges: [
                    { condition: 'LessThan', value: '40', color: '#D10027', isWarn: false },
                    { condition: 'Between', value: '40', secondValue: '60', color: '#FF611C', isWarn: false },
                    { condition: 'Between', value: '60', secondValue: '80', color: '#396394', isWarn: false },
                    { condition: 'GreaterThan', value: '80', color: '#23930D', isWarn: false },
                ],
            },
        },
        OPP_STAGE: {
            integrationType: 'CRM',
            fieldDefinition: {
                groupId: _this.variable('GROUP_ID', true),
                name: 'Stage',
                type: 'EXTERNAL',
                definition: {
                    ExternalType: 'TNK_OPP',
                    FieldName: 'TNK_STAGE',
                },
                isImportant: true,
                ranges: [
                    { condition: 'Equals', value: 'Closed Lost', color: '#D10027', isWarn: false },
                    { condition: 'Equals', value: 'Closed Won', color: '#23930D', isWarn: false },
                ],
            },
        },
    };
    _this.defaultGlobalFields = {
        SALES: [
            {
                integrationType: 'MANUAL',
                fieldDefinition: {
                    groupId: _this.variable('GROUP_ID', true),
                    name: 'Quota',
                    type: 'MANUAL',
                    manualValue: _this.variable('QUOTA'),
                    fieldType: 'Number',
                    displayAs: 'Number',
                    displayFormat: _this.variable('DISPLAY_FORMAT_API_NAME'),
                    displayFormatPrefix: _this.variable('DISPLAY_FORMAT_PREFIX'),
                    displayFormatPostfix: _this.variable('DISPLAY_FORMAT_POSTFIX'),
                    updateable: true,
                    ranges: [],
                },
            },
            {
                integrationType: 'CRM',
                fieldDefinition: {
                    groupId: _this.variable('GROUP_ID', true),
                    name: 'Closed',
                    type: 'AGGREGATE_QUERY',
                    fieldType: 'Number',
                    displayAs: 'Number',
                    displayFormat: _this.variable('DISPLAY_FORMAT_API_NAME'),
                    displayFormatPrefix: _this.variable('DISPLAY_FORMAT_PREFIX'),
                    displayFormatPostfix: _this.variable('DISPLAY_FORMAT_POSTFIX'),
                    definition: {
                        entity: 'TNK_OPP',
                        aggregationType: 'Sum',
                        aggregatedField: {
                            name: 'TNK_AMOUNT',
                        },
                        query: {
                            type: 'And',
                            filters: [
                                {
                                    fieldName: 'TNK_CLOSE_DATE',
                                    condition: 'InThis',
                                    value: _this.variable('DATE_RANGE'),
                                },
                                { fieldName: 'TNK_IS_CLOSED', condition: 'Equals', value: 'True' },
                            ],
                        },
                    },
                    ranges: [],
                },
            },
            {
                integrationType: 'CRM',
                fieldDefinition: {
                    groupId: _this.variable('GROUP_ID', true),
                    name: 'Open (>70%)',
                    type: 'AGGREGATE_QUERY',
                    fieldType: 'Number',
                    displayAs: 'Number',
                    displayFormat: _this.variable('DISPLAY_FORMAT_API_NAME'),
                    displayFormatPrefix: _this.variable('DISPLAY_FORMAT_PREFIX'),
                    displayFormatPostfix: _this.variable('DISPLAY_FORMAT_POSTFIX'),
                    definition: {
                        entity: 'TNK_OPP',
                        aggregationType: 'Sum',
                        aggregatedField: {
                            name: 'TNK_AMOUNT',
                        },
                        query: {
                            type: 'And',
                            filters: [
                                {
                                    fieldName: 'TNK_CLOSE_DATE',
                                    condition: 'InThis',
                                    value: _this.variable('DATE_RANGE'),
                                },
                                { fieldName: 'TNK_IS_CLOSED', condition: 'Equals', value: 'False' },
                                { fieldName: 'TNK_PROBABILITY', condition: 'GreaterThan', value: 70 },
                            ],
                        },
                    },
                    ranges: [],
                },
            },
            {
                integrationType: 'CRM',
                fieldDefinition: {
                    groupId: _this.variable('GROUP_ID', true),
                    name: 'Upside',
                    type: 'AGGREGATE_QUERY',
                    fieldType: 'Number',
                    displayAs: 'Number',
                    displayFormat: _this.variable('DISPLAY_FORMAT_API_NAME'),
                    displayFormatPrefix: _this.variable('DISPLAY_FORMAT_PREFIX'),
                    displayFormatPostfix: _this.variable('DISPLAY_FORMAT_POSTFIX'),
                    definition: {
                        entity: 'TNK_OPP',
                        aggregationType: 'Sum',
                        aggregatedField: {
                            name: 'TNK_AMOUNT',
                        },
                        query: {
                            type: 'And',
                            filters: [
                                {
                                    fieldName: 'TNK_CLOSE_DATE',
                                    condition: 'InThis',
                                    value: _this.variable('DATE_RANGE'),
                                },
                            ],
                            queries: [
                                {
                                    type: 'Or',
                                    filters: [
                                        { fieldName: 'TNK_IS_CLOSED', condition: 'Equals', value: 'True' },
                                        { fieldName: 'TNK_PROBABILITY', condition: 'GreaterThan', value: 70 },
                                    ],
                                },
                            ],
                        },
                    },
                    ranges: [
                        { condition: 'LessThan', value: _this.variable('QUOTA'), color: '#D10027' },
                        { condition: 'GreaterThan', value: _this.variable('QUOTA'), color: '#23930D' },
                    ],
                },
            },
        ],
        ACCOUNTS: [
            {
                integrationType: 'ACCOUNTS',
                requiresIntegration: false,
                fieldDefinition: {
                    groupId: _this.variable('GROUP_ID', true),
                    name: 'Total Amount',
                    type: 'TNK_COLUMN_AGGREGATE',
                    fieldType: 'Number',
                    displayAs: 'Number',
                    displayFormat: _this.variable('DISPLAY_FORMAT_API_NAME'),
                    displayFormatPrefix: _this.variable('DISPLAY_FORMAT_PREFIX'),
                    displayFormatPostfix: _this.variable('DISPLAY_FORMAT_POSTFIX'),
                    definition: {
                        aggregationType: 'Sum',
                        aggregatedDefinitionId: _this.variable('FIELD_KEY_AMOUNT', true),
                        aggregatedDefinitionName: 'Amount',
                        groupId: _this.variable('GROUP_ID'),
                    },
                    ranges: [],
                },
            },
        ],
    };
    _this.defaultColumns = {
        ACCOUNTS: [
            {
                integrationType: 'ACCOUNTS',
                fieldDefinition: {
                    groupId: _this.variable('GROUP_ID', true),
                    key: 'FIELD_KEY_AMOUNT',
                    name: 'Amount',
                    type: 'EXTERNAL',
                    fieldType: 'Number',
                    displayAs: 'Number',
                    displayFormat: _this.variable('DISPLAY_FORMAT_API_NAME'),
                    displayFormatPrefix: _this.variable('DISPLAY_FORMAT_PREFIX'),
                    displayFormatPostfix: _this.variable('DISPLAY_FORMAT_POSTFIX'),
                    definition: {
                        ExternalType: 'TNK_ACCOUNT',
                        FieldName: 'TNK_AMOUNT',
                    },
                    ranges: [],
                    fallBackToManual: true,
                },
            },
        ],
    };
    _this.defaultSteps = {
        INITIATIVES: [
            _this.builtInIntegrationsSteps['SELECT_SOURCE_INITIATIVES'],
            {
                type: 'integration',
                optional: true,
                data: {
                    sourceType: 'PRODUCT',
                    title: 'current tools to import your top initiatives',
                    subtitle:
                        'Which system holds your accounts information? (skip if you prefer to enter manually later)',
                    integrations: ['jira', 'trello', 'asana', 'teamwork', 'gitlab'].concat(
                        _this.integrationGroups.webhooks.integrations,
                    ),
                },
            },
            {
                type: 'config',
                data: {
                    template: 'setupTopInitiativesTrackingConfigTemplate',
                },
                prepareData(values) {
                    if (values['SYNC_CONTROL']) {
                        const definitionFromCustomFilters = values['SYNC_CONTROL'].createDefinitionFromCustomFilters();
                        values['SYNC_QUERY'] = definitionFromCustomFilters.query;
                    }
                },
                shouldActivateBot(integrations) {
                    // if no integrations then activate bot
                    return !integrations['PRODUCT'];
                },
            },
            {
                type: 'preview',
                shouldSkipStep(values, integrationResults) {
                    return !!values['USE_MANUALLY'] || !integrationResults['PRODUCT'];
                },
                data: {},
            },
        ],
        ACCOUNTS: [
            _this.builtInIntegrationsSteps['SELECT_SOURCE_ACCOUNTS'],
            {
                type: 'integration',
                optional: true,
                data: {
                    sourceType: 'ACCOUNTS',
                    title: 'current tools to import your accounts',
                    subtitle:
                        'Which system holds your accounts information? (skip if you prefer to enter manually later)',
                    integrations: Utils.objKeys(integrationsService.getSyncSettings()),
                },
            },
            {
                type: 'config',
                data: {
                    template: 'setupAccountsTrackingConfigTemplate',
                },
                prepareData(values) {
                    if (values['SYNC_CONTROL']) {
                        const definitionFromCustomFilters = values['SYNC_CONTROL'].createDefinitionFromCustomFilters();
                        values['SYNC_QUERY'] = definitionFromCustomFilters.query;
                    }
                },
                shouldActivateBot(integrations) {
                    return !integrations['ACCOUNTS'];
                },
            },
            {
                type: 'preview',
                shouldSkipStep(values, integrationResults) {
                    return !integrationResults['ACCOUNTS'];
                },
                data: {},
            },
        ],
        SALES: [
            _this.builtInIntegrationsSteps['SELECT_SOURCE_ACCOUNTS'],
            _this.builtInIntegrationsSteps['CRM'],
            {
                type: 'config',
                data: {
                    template: 'setupForecastConfigTemplate',
                },
                shouldActivateBot(integrations) {
                    // if no integrations then activate bot
                    return !integrations['CRM'];
                },
            },
            {
                type: 'preview',
                data: {},
            },
        ],
        MEETING_ITEMS: [
            _this.builtInIntegrationsSteps['SELECT_SOURCE_INITIATIVES'],
            {
                type: 'integration',
                optional: true,
                data: {
                    sourceType: 'PRODUCT',
                    title: 'current tools to import your top initiatives',
                    subtitle:
                        'Which system holds your accounts information? (skip if you prefer to enter manually later)',
                    integrations: allNativeIntegrations,
                },
            },
            {
                type: 'config',
                data: {
                    template: 'setupTopInitiativesTrackingConfigTemplate',
                },
                prepareData(values) {
                    if (values['SYNC_CONTROL']) {
                        const definitionFromCustomFilters = values['SYNC_CONTROL'].createDefinitionFromCustomFilters();
                        values['SYNC_QUERY'] = definitionFromCustomFilters.query;
                    }
                },
                shouldActivateBot(integrations) {
                    // if no integrations then activate bot
                    return !integrations['PRODUCT'];
                },
            },
            {
                type: 'preview',
                shouldSkipStep(values, integrationResults) {
                    return !!values['USE_MANUALLY'] || !integrationResults['PRODUCT'];
                },
                data: {},
            },
        ],
        MANUAL_INITIATIVES: [
            {
                type: 'config',
                data: {
                    template: 'setupTopInitiativesTrackingConfigTemplate',
                },
                shouldActivateBot() {
                    return true;
                },
            },
        ],
        MANUAL_ACCOUNTS: [
            {
                type: 'config',
                data: {
                    template: 'setupAccountsTrackingConfigTemplate',
                },
                shouldActivateBot() {
                    return true;
                },
            },
        ],
        SPREADSHEET: [
            {
                type: 'plainConfig',
                data: {
                    sourceType: 'SPREADSHEET',
                    template: 'spreadsheetImportConfigTemplate',
                },
            },
            {
                type: 'config',
                data: {
                    integrationType: 'SPREADSHEET',
                    template: 'setupTopInitiativesTrackingConfigTemplate',
                },
                prepareData(values) {
                    if (values['SYNC_CONTROL']) {
                        const definitionFromCustomFilters = values['SYNC_CONTROL'].createDefinitionFromCustomFilters();
                        values['SYNC_QUERY'] = definitionFromCustomFilters.query;
                    }
                },
                shouldActivateBot(integrations) {
                    // if no integrations then activate bot
                    return !integrations['SPREADSHEET'];
                },
            },
            // preview for spreadsheets integration
            {
                type: 'preview',
                shouldSkipStep(values, integrationResults) {
                    return !integrationResults['SPREADSHEET'];
                },
                data: {},
            },
            // preview for csv
            {
                type: 'previewManual',
                shouldSkipStep(values, integrationResults) {
                    return integrationResults['SPREADSHEET'];
                },
                data: {},
            },
        ],
    };
    _this.noIntegrationSupportFallback = {
        noInitiativeCreation: 'NO_INITIATIVE_CREATION',
        noSync: 'NO_SYNC',
    };

    /**
     * Defaults states of a project in Tonkean.
     */
    _this.defaultStatuses = {
        notStarted: {
            stateText: 'Not Started',
            stateColor: '#6A6C6D',
            status: 'ON_HOLD',
        },
        inProgress: {
            stateText: 'In Progress',
            stateColor: '#2F84DC',
            status: 'ACTIVE',
        },
        asPlanned: {
            stateText: 'As Planned',
            stateColor: '#23930D',
            status: 'ACTIVE',
        },
        atRisk: {
            stateText: 'At Risk',
            stateColor: '#FF611C',
            status: 'WARN',
        },
        blocked: {
            stateText: 'Blocked',
            stateColor: '#D10027',
            status: 'WARN',
        },
        done: {
            stateText: 'Done',
            stateColor: '#396394',
            status: 'DONE',
        },
    };

    /**
     * Returns current date with daysFromNow addition to its days part.
     */
    _this.getDaysFromNow = function (daysFromNow) {
        const now = new Date();
        now.addDays(daysFromNow);
        return now.getTime();
    };

    _this.getRangeColor = function (name) {
        switch (name) {
            case 'green':
                return '#23930D';
            case 'orange':
                return '#FF611C';
            case 'red':
                return '#D10027';
            case 'blue':
                return '#396394';
            case 'purple':
                return '#6C58A7';
            case 'grey':
                return '#6A6C6D';
            default:
                return '';
        }
    };
}

const builtInListsUtils = new BuiltInListsUtils();
export default builtInListsUtils;
