import React from 'react';
import DefaultProjectedReason from './DefaultProjectedReason';

const dateStringOptions = { weekday: 'long', month: 'long', day: 'numeric' };

export default class DateProjectedReason extends DefaultProjectedReason {
    constructor(props) {
        super(props);
    }

    renderBody() {
        // It's really hard to get custom date formats...
        const dateString = new Date(this.props.fieldValue).toLocaleDateString('en-US', dateStringOptions);

        return (
            <div>
                {this.renderTitleLink()}
                <span>
                    {this.props.label}
                    {dateString}
                </span>
            </div>
        );
    }
}
