function TimeUtils() {
    this.daysDiff = function (time, fromTime) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return timeDiff(time, fromTime, oneDay);
    };

    this.hoursDiff = function (time, fromTime) {
        const oneHour = 60 * 60 * 1000;
        return timeDiff(time, fromTime, oneHour);
    };

    this.minutesDiff = function (time, fromTime) {
        const oneMinute = 60 * 1000;
        return timeDiff(time, fromTime, oneMinute);
    };

    /**
     * The days diff between now and given time int
     * @param time {Date|Number} The date to compare to
     * @param fromTime {Date|Number} The date to compare from, defaults to now
     * @param timeUnit {Number} In what time unit to return the diff (Day\Hour\Minute...)
     */
    function timeDiff(time, fromTime, timeUnit) {
        // if no time (null or undefined), calculations can have unexpected results
        if (!time) {
            // returning null leads to unexpected results
            return undefined;
        }

        if (!timeUnit) {
            // Can't divide by nothing so set it as 1, which is basically 1 milli
            timeUnit = 1;
        }

        const firstDate = fromTime ? new Date(fromTime) : new Date(); // now
        const secondDate = new Date(time);

        const diff = Math.round((firstDate.getTime() - secondDate.getTime()) / timeUnit) * -1;
        return diff;
    }

    this.daysDiffString = function (time) {
        const diffDays = this.daysDiff(time);

        if (diffDays < 0) {
            return `${Math.abs(diffDays)} Days ago`;
        } else if (diffDays < 1) {
            return 'Today';
        } else if (diffDays >= 1 && diffDays < 2) {
            return 'Tomorrow';
        } else if (diffDays >= 2) {
            return `${diffDays} Days`;
        }
    };

    // How many calendaric day will pass between time and now
    this.daysDiffCalendarString = function (time) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const oneHour = 60 * 60 * 1000; // minutes*seconds*milliseconds
        const firstDate = new Date(); // now
        const secondDate = new Date(time);

        const diffDays = (secondDate.getTime() - firstDate.getTime()) / oneDay;
        const diffDaysCeil = Math.ceil(Math.abs(secondDate.getTime() - firstDate.getTime()) / oneDay);
        const diffHoursFloor = Math.floor(Math.abs(secondDate.getTime() - firstDate.getTime()) / oneHour);

        if (diffDays <= -1) {
            return `${diffDaysCeil} Days ago`;
        } else if (diffDays < 0 && diffDays > -1) {
            return `${diffHoursFloor} Hours ago`;
        } else if (0 <= diffDays && diffDays <= 2) {
            return `${diffHoursFloor} Hours`;
        } else if (diffDays > 2) {
            return `${diffDaysCeil} Days`;
        }
    };

    this.daysDiffSentenceString = function (time) {
        const diffDays = this.daysDiff(time);

        if (diffDays < 0) {
            return `${Math.abs(diffDays)} Days ago`;
        } else if (diffDays < 1) {
            return 'Today';
        } else if (diffDays >= 1 && diffDays < 2) {
            return 'Tomorrow';
        } else if (diffDays >= 2) {
            return `in ${diffDays} Days`;
        }
    };

    this.daysDiffShortString = function (time) {
        const diffDays = this.daysDiff(time);

        if (diffDays < -14) {
            const date = new Date(time);
            return `${date.getMonthName().slice(0, 3)} ${date.getDate()}`;
        } else if (diffDays < -1) {
            return `${Math.abs(diffDays)}d ago`;
        } else if (diffDays === -1) {
            return 'Yesterday';
        } else if (diffDays < 1) {
            return 'Today';
        } else if (diffDays >= 1 && diffDays < 2) {
            return 'Tomorrow';
        } else if (diffDays >= 2) {
            return `in ${diffDays}d`;
        }
    };

    this.daysDiffShorterString = function (time) {
        const diffDays = this.daysDiff(time);

        if (diffDays < 0) {
            return `${Math.abs(diffDays)}d`;
        } else if (diffDays < 1) {
            return 'Today';
        } else if (diffDays >= 1 && diffDays < 2) {
            return 'Tomorrow';
        } else if (diffDays >= 2) {
            return `${Math.abs(diffDays)}d`;
        }
    };

    this.getDateToken = function (date) {
        return new Date(date).toDateString();
    };

    this.isSameDay = function sameDay(t1, t2) {
        if (!t1 || !t2) {
            return false;
        }

        const d1 = new Date(t1);
        const d2 = new Date(t2);

        return (
            d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
        );
    };

    this.isToday = function (time) {
        const date = new Date(time);
        const today = new Date();
        return this.isSameDay(date, today);
    };

    this.isTomorrow = function (time) {
        const date = new Date(time);
        const tomorrow = new Date().addDays(1);
        return this.isSameDay(date, tomorrow);
    };

    this.isYesterday = function (time) {
        const date = new Date(time);
        const yesterday = new Date().addDays(-1);
        return this.isSameDay(date, yesterday);
    };

    this.isInNextSevenDays = function (time) {
        const daysDiff = this.daysDiff(time);
        return 0 <= daysDiff && daysDiff <= 7;
    };

    this.isInPastSevenDays = function (time) {
        const daysDiff = this.daysDiff(time);
        return -7 <= daysDiff && daysDiff <= 0;
    };
}

angular.module('tonkean.app').service('timeUtils', TimeUtils);
export default new TimeUtils();
