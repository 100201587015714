import { useAngularService } from 'angulareact';
import { useEffect, useMemo } from 'react';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { useCreateDialog } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';

/**
 * A custom react hook for lazily deleting forms.
 *
 * @param form The form to delete
 * @param groupId Form groupId
 * @param onFormDeleted function to call when form is deleted
 *
 * @returns {Function} - a memoized callback to trigger form deletion.
 */
function useDeleteWorkerFormCallback(form: Form | undefined, groupId: string, onFormDeleted: () => void) {
    const $rootScope = useAngularService('$rootScope');
    const formManager = useAngularService('formManager');
    const [{ called, error, loading }, deleteWorkerForm] = useLazyAsyncMethod(formManager, 'deleteWorkerForm');
    const { confirm, alert } = useCreateDialog();
    const formName = useMemo(() => form?.displayName ?? 'New form', [form]);

    useEffect(() => {
        if (called && !loading && !error) {
            onFormDeleted();
            $rootScope.$emit('alert', { msg: 'Form was deleted', type: 'success' });
        }

        if (error) {
            const errorMessage =
                error.status === 409
                    ? error.data.data.error.message
                    : 'An error occurred while trying to delete the form';

            alert(errorMessage);
        }
    }, [called, loading, error, onFormDeleted, alert, $rootScope]);

    const onDeleteForm = () => {
        if (!form) {
            return;
        }

        return confirm('Delete Form', `Are you sure you want to delete the form "${formName}"?`, {
            okLabel: 'Delete',
            cancelLabel: 'Cancel',
        }).then((deleteClicked) => {
            if (deleteClicked) {
                deleteWorkerForm(groupId, form.id);
            }
        });
    };

    return onDeleteForm;
}

export default useDeleteWorkerFormCallback;
