import React from 'react';
import styled from 'styled-components';

import { InputWrapper } from './configurationEditorSettingsComponents/InputWrapper';
import { Label } from './configurationEditorSettingsComponents/Label';

import { Input, useFormikField } from '@tonkean/infrastructure';

const MinMaxWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;

const StyledInput = styled(Input)`
    margin-top: 8px;
    width: 100%;
`;

interface Props {
    minMaxRequirementsEntityName?: string;
}

const ItemsWidgetMinMaxConfiguration: React.FC<Props> = ({ minMaxRequirementsEntityName }) => {
    const { value: minimum, setValue: setMinimum } = useFormikField<number | undefined>(
        'configuration.minMaxRequirementConfiguration.minimum',
    );
    return (
        <MinMaxWrapper>
            <InputWrapper>
                <Label>{`Minimum ${minMaxRequirementsEntityName}`}</Label>
                <StyledInput
                    value={minimum || 0}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMinimum(event.target.valueAsNumber > 50 ? 50 : event.target.valueAsNumber);
                    }}
                    autoComplete="off"
                    type="number"
                    min={0}
                    max={50}
                    placeholder="Set min"
                />
            </InputWrapper>
            <InputWrapper>
                <Label>{`Maximum ${minMaxRequirementsEntityName}`}</Label>
                <StyledInput
                    name="configuration.minMaxRequirementConfiguration.maximum"
                    autoComplete="off"
                    type="number"
                    min={1}
                    max={50}
                    value={50}
                    disabled
                />
            </InputWrapper>
        </MinMaxWrapper>
    );
};

export default ItemsWidgetMinMaxConfiguration;
