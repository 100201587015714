import React from 'react';

import ToggleIcon from './ToggleIcon';
import { useInnerField } from '../Field';
import { IconInputWithLabel } from '../IconInputWithLabel';

import { InputSize } from '@tonkean/tui-theme/sizes';
import type { ToggleComponentSizes } from '@tonkean/tui-theme/sizes';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import type { DataAutomationSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    /** Does the checkbox disabled? */
    disabled?: boolean;

    /** Should the checkbox label changes color to lighter gray when it's not checked? */
    changeLabelColor?: boolean;

    /** The checkbox size */
    size?: ToggleComponentSizes;

    /** Label to show before the toggle */
    labelBefore?: React.ReactNode;

    /** Label to show after the toggle */
    labelAfter?: React.ReactNode;

    /** Name to pass to the input */
    name?: string;

    /** If the label text should be inside the toggle icon */
    showInnerLabel?: boolean;

    /** The label inside the toggle icon for on state */
    innerLabelOn?: string;

    /** The label inside the toggle icon for off state */
    innerLabelOff?: string;

    /** Does the checkbox checked? */
    checked?: boolean;

    /** Will be triggered when the checkbox state changes. */
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;

    onClick?(event: React.MouseEvent<HTMLInputElement>): void;

    boldLabel?: boolean;
}

const Toggle: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {
        disabled = false,
        className,
        changeLabelColor = true,
        size = InputSize.LARGE,
        showInnerLabel = false,
        innerLabelOn = showInnerLabel ? 'ON' : undefined,
        innerLabelOff = showInnerLabel ? 'OFF' : undefined,
        name,
        checked,
        labelBefore,
        labelAfter,
        onChange,
        onClick,
        dataAutomation,
        boldLabel,
    },
    ref,
) => {
    const [props, hasError] = useInnerField({
        name,
        type: 'checkbox',
        multiple: false,
        checked,
        onChange,
    });

    // If only one label supplied, it should be checked if the toggle is checked. If both labels supplied,
    // the label before should be checked if the toggle is not checked, because it's the 'false' side, and
    // the label after checked if the toggle is checked, because it's the true side.
    const labelBeforeChecked = labelAfter ? !checked : checked;
    const labelAfterChecked = checked;

    return (
        <IconInputWithLabel
            type="checkbox"
            className={className}
            disabled={disabled}
            ref={ref}
            changeLabelColor={changeLabelColor}
            size={size}
            icon={
                <ToggleIcon
                    disabled={disabled}
                    checked={props.checked}
                    size={size}
                    label={props.checked ? innerLabelOn : innerLabelOff}
                />
            }
            labelBefore={labelBefore}
            labelBeforeChecked={labelBeforeChecked}
            labelAfter={labelAfter}
            labelAfterChecked={labelAfterChecked}
            dataAutomation={dataAutomation}
            onClick={onClick}
            boldLabel={boldLabel}
            {...props}
        />
    );
};

export default React.forwardRef(Toggle);
