import TemplateConfigurationModal from './TemplateConfigurationModal';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkTemplateConfigurationModal',
        reactToAngular(TemplateConfigurationModal, ['isOpen', 'defaultFromModuleId', 'onClose', 'onError']),
    );
