import React, { useState } from 'react';
import styled from 'styled-components';

import CustomItemMatchFieldsMetadataTableMenuList from './CustomItemMatchFieldsMetadataTableMenuList';

import { Separator } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

const NestedField = styled.div<{ whiteBackground: boolean }>`
    display: grid;
    grid-template-columns: 1fr 18fr 1fr;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background: ${({ whiteBackground }) =>
        whiteBackground
            ? Theme.colors.white
            : '#f9f9f9'}; // this color is not in the palette and needs to be consistent with the rest of the links in the module editor
`;

const EditNestedFieldSection = styled.div`
    display: flex;
    align-content: end;
    align-items: center;
    color: #3fa7b8; // this color is not in the palette and needs to be consistent with the rest of the links in the module editor
    &:hover {
        text-decoration: underline;
    }
`;

interface Props {
    fieldDefinition: FieldDefinition<unknown>;
    index: number;
    onFieldSelected: (definition: FieldDefinition) => void;
    applyFieldDependenciesFilter: (fieldDefinition: FieldDefinition<unknown>) => void;
    applyTriggerDependenciesFilter: (fieldDefinition: FieldDefinition<unknown>) => void;
    enableEditFields: boolean;
}
/** Nested Field list is the list of fields used from the matched entity **/
const NestedFieldListItem: React.FC<Props> = ({
    fieldDefinition,
    index,
    onFieldSelected,
    applyFieldDependenciesFilter,
    applyTriggerDependenciesFilter,
    enableEditFields,
}) => {
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    return (
        <>
            <Separator />
            <NestedField whiteBackground={index % 2 !== 0} key={fieldDefinition.id}>
                <ProjectIntegrationIcon
                    integrationType={fieldDefinition.projectIntegration.integration.integrationType}
                    width={16}
                    iconUrl={fieldDefinition.projectIntegration.iconUrl}
                />
                <span data-automation="group-fields-metadata-table-matched-item-names">{fieldDefinition.name}</span>
                <EditNestedFieldSection>
                    {enableEditFields && (
                        <Clickable
                            data-automation="group-fields-metadata-table-matched-item-edit"
                            onClick={() => onFieldSelected(fieldDefinition)}
                        >
                            Edit
                        </Clickable>
                    )}
                    <CustomItemMatchFieldsMetadataTableMenuList
                        menuIsOpen={menuIsOpen}
                        setMenuIsOpen={setMenuIsOpen}
                        fieldDefinition={fieldDefinition}
                        applyFieldDependenciesFilter={applyFieldDependenciesFilter}
                        applyTriggerDependenciesFilter={applyTriggerDependenciesFilter}
                    />
                </EditNestedFieldSection>
            </NestedField>
        </>
    );
};
export default NestedFieldListItem;
