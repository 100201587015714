<div
    class="tnk-side-pane bot-side-pane"
    ng-class="{
        'mod-shown': data.isOpen,
        'mod-alert-indicator': data.newActivitiesCount,
        'mod-collapsed': $root.showBotSidePaneCollapsed
    }"
    click-outside="setIsOpen(false)"
    outside-if-not="bot-side-pane-block-click-outside bot-side-pane-tab-header bot-pane-tabset manage-scheduled-reports-modal activity-digest-email-settings-modal suggestion-item tag-item"
>
    <div uib-tabset vertical="true" type="pills" class="bot-pane-tabset">
        <!-- Tonkean Modules -->
        <div
            uib-tab
            class="bot-tab"
            uib-tooltip="Modules"
            tooltip-class="tnk-side-pane-tab-tooltip"
            tooltip-placement="left"
        >
            <div
                uib-tab-heading
                ng-click="onTabHeaderClick('tonkean')"
                class="bot-side-pane-tab-header"
                analytics-on="click"
                analytics-category="BotSidePane"
                analytics-event="OpenTab"
                analytics-label="Modules"
            >
                <div class="svg-icon-xlg padding-left-xs mod-static relative common-color-black">
                    <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
                </div>
            </div>
            <div class="bot-tab-pane mod-history" ng-if="data.firstTimeLoadedTabMap['tonkean']">
                <div class="common-close-btn padding-normal" ng-click="setIsOpen(false)">
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </div>

                <tnk-bot-worker-feed
                    is-visible="data.selectedTab === 'tonkean' && data.isOpen"
                    close-side-pane="setIsOpen(false)"
                ></tnk-bot-worker-feed>
            </div>
        </div>

        <!-- Notifications -->
        <div
            uib-tab
            class="selectable"
            uib-tooltip="Notifications"
            tooltip-class="tnk-side-pane-tab-tooltip"
            tooltip-placement="left"
        >
            <div
                uib-tab-heading
                ng-click="onTabHeaderClick('notifications')"
                class="bot-side-pane-tab-header"
                analytics-on="click"
                analytics-category="BotSidePane"
                analytics-event="OpenTab"
                analytics-label="Notifications"
            >
                <div
                    class="svg-icon-md relative"
                    ng-class="{ 'bot-pane-new-activity-indicator': data.newActivitiesCount }"
                >
                    <tnk-icon src="/images/icons/notifications.svg"></tnk-icon>
                </div>
            </div>
            <div class="bot-tab-pane padding-none">
                <div class="common-close-btn padding-normal" ng-click="setIsOpen(false)">
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </div>

                <tnk-activity-popup
                    inline="true"
                    borderless="true"
                    custom-class="bot-pane-activity-tab"
                    on-item-count-updated="onActivityCountUpdated(count)"
                    is-visible="data.selectedTab === 'notifications' && data.isOpen"
                ></tnk-activity-popup>
            </div>
        </div>

        <!-- Inbox -->
        <div
            uib-tab
            class="selectable"
            uib-tooltip="Inbox"
            tooltip-class="tnk-side-pane-tab-tooltip"
            tooltip-placement="left"
        >
            <div
                uib-tab-heading
                ng-click="onTabHeaderClick('inbox')"
                class="bot-side-pane-tab-header"
                analytics-on="click"
                analytics-category="BotSidePane"
                analytics-event="OpenTab"
                analytics-label="Inbox"
            >
                <div
                    class="svg-icon-md relative"
                    ng-class="{ 'bot-pane-new-activity-indicator': data.newInboxItemsCount }"
                >
                    <tnk-icon src="/images/icons/inbox.svg"></tnk-icon>
                </div>
            </div>
            <div class="bot-tab-pane">
                <div class="common-close-btn padding-normal" ng-click="setIsOpen(false)">
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </div>

                <tnk-inbox
                    is-open="data.selectedTab === 'inbox' && data.isOpen"
                    on-new-items-count-updated="onInboxNewItems(newItemCount)"
                ></tnk-inbox>
            </div>
        </div>

        <!-- Past\Future gathers history -->
        <div
            uib-tab
            class="selectable"
            uib-tooltip="Team's Follow-ups"
            tooltip-placement="left"
            tooltip-class="bot-tab-follow-ups-tooltip"
        >
            <div
                uib-tab-heading
                ng-click="onTabHeaderClick('gathers-feed')"
                class="bot-side-pane-tab-header"
                analytics-on="click"
                analytics-category="BotSidePane"
                analytics-event="OpenTab"
                analytics-label="GathersFeed"
            >
                <div class="svg-icon-md">
                    <tnk-icon src="/images/icons/follow-ups.svg"></tnk-icon>
                </div>
            </div>

            <div class="bot-tab-pane mod-history" ng-if="data.firstTimeLoadedTabMap['gathers-feed']">
                <div class="common-close-btn padding-normal" ng-click="setIsOpen(false)">
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </div>

                <tnk-bot-gathers-feed
                    is-visible="data.selectedTab === 'gathers-feed' && data.isOpen"
                ></tnk-bot-gathers-feed>
            </div>
        </div>
    </div>
</div>
