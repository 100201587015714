import React, { useContext, useRef } from 'react';
import styled from 'styled-components';

import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';

import { type CoreEditorRef, HTMLEditor, type HTMLEditorPlugin } from '@tonkean/editor';
import type { WidgetBase } from '@tonkean/tonkean-entities';
import { RouterContext } from '@tonkean/tui-basic/RouterLink';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 4px;
    justify-content: start;

    padding: 12px 16px;
    background: ${Theme.colors.disabled};
    border-top: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
`;

const StyledEditor = styled(HTMLEditor)`
    flex-grow: 1;
    border: none;
    border-radius: 0;
    cursor: pointer;
`;
interface Props {
    widget: WidgetBase<RichTextEditorInputWidgetConfiguration>;
    editorPlugins: HTMLEditorPlugin[];
}
const RichTextEditorInBuilderMode: React.FC<Props> = ({ widget, editorPlugins }) => {
    const routerContextValue = useContext(RouterContext);
    if (!routerContextValue) {
        throw new Error(
            'Attempted to use RouterLink (or Clickable with state/params) without passing TonkeanRouter to ReactRoot',
        );
    }
    const { go } = routerContextValue;
    const editorRef = useRef<CoreEditorRef>(null);

    const setEditorConfiguration = () => {
        const query: Record<string, any> | undefined = {
            widgetId: widget.id,
            currentTab: undefined,
        };

        go('.', query, true, { location: 'replace' });
    };

    return (
        <>
            <StyledEditor
                dataAutomation="rich-text-widget-editor"
                plugins={editorPlugins}
                placeholder={widget.configuration.placeholderText}
                ref={editorRef}
                onClicked={setEditorConfiguration}
                isToolbarDisabled
                isReadOnly
            />
            <ButtonsWrapper>
                <Button
                    data-automation="rich-text-widget-editor-dismiss-button"
                    size={ButtonSize.SMALL}
                    outlined
                    disabled
                >
                    Dismiss
                </Button>
                <Button data-automation="rich-text-widget-editor-save-button" size={ButtonSize.SMALL} disabled>
                    Save
                </Button>
            </ButtonsWrapper>
        </>
    );
};

export default RichTextEditorInBuilderMode;
