import type { AngularServices } from 'angulareact';
import type {
    DataSourceType,
    TonkeanId,
    TonkeanType,
    UpdateWorkflowVersionRecurrenceTimeParams,
} from '@tonkean/tonkean-entities';
import { GetItemDetailsForItemDetailsModuleEditorDocument } from '../modules/ItemDetailsModule/components/ItemDetailsModuleEditor/getItemDetailsForItemDetailsModuleEditor.graphql';

/**
 * A service to help with groups operations
 */
export class WorkflowVersionDataSourceManager {
    /* @ngInject */

    protected constructor(
        private $rootScope: AngularServices['$rootScope'],
        private $q: AngularServices['$q'],
        private tonkeanService: AngularServices['tonkeanService'],
        private workflowVersionManager: AngularServices['workflowVersionManager'],
    ) {}

    /**
     * Updates the workflow version data source type both in cache and in the server.
     */
    public updateWorkflowVersionDataSourceType(
        groupId: string,
        dataSourceType: DataSourceType,
        doNotUpdateServer: boolean,
    ): Promise<unknown> {
        const cachedWorkflowVersion = this.workflowVersionManager.getDraftVersionFromCache(groupId);
        if (cachedWorkflowVersion) {
            cachedWorkflowVersion.dataSourceType = dataSourceType;
            this.workflowVersionManager.incrementWorkflowVersionCounter(cachedWorkflowVersion.id);
        }

        if (!doNotUpdateServer) {
            return this.tonkeanService.updateWorkflowVersionDataTypeSource(groupId, dataSourceType).then(() => {
                if (cachedWorkflowVersion) {
                    return this.refetchWorkflowVersionItemDetails(cachedWorkflowVersion.id);
                }
            });
        } else {
            return this.$q.resolve();
        }
    }

    public updateWorkflowVersionRecurringTime(params: UpdateWorkflowVersionRecurrenceTimeParams): Promise<unknown> {
        return this.tonkeanService.updateWorkflowVersionRecurringTime(params).then((updatedWorkflowVersion) => {
            this.workflowVersionManager.incrementDraftCounter(params.groupId);
            this.workflowVersionManager.updateWorkflowVersion(updatedWorkflowVersion);
            return this.refetchWorkflowVersionItemDetails(updatedWorkflowVersion.id);
        });
    }

    public updateWorkflowVersionIsScheduled(
        groupId: TonkeanId<TonkeanType.GROUP>,
        isScheduled: boolean,
    ): Promise<unknown> {
        return this.tonkeanService
            .updateWorkflowVersionIsScheduled(groupId, isScheduled)
            .then((updatedWorkflowVersion) => {
                 this.workflowVersionManager.incrementDraftCounter(groupId);

                this.workflowVersionManager.updateWorkflowVersion(updatedWorkflowVersion);
                return this.refetchWorkflowVersionItemDetails(updatedWorkflowVersion.id);
            });
    }

    public updateWorkflowVersionIsFromAnotherModule(
        groupId: TonkeanId<TonkeanType.GROUP>,
        isFromAnotherModule: boolean,
    ): Promise<void> {
        return this.tonkeanService
            .updateWorkflowVersionIsFromAnotherModule(groupId, isFromAnotherModule)
            .then((updatedWorkflowVersionResponse) => {
                this.workflowVersionManager.updateWorkflowVersion(updatedWorkflowVersionResponse.updatedWorkflowVersion);
                this.workflowVersionManager.incrementDraftCounter(groupId);

                return this.refetchWorkflowVersionItemDetails(updatedWorkflowVersionResponse.updatedWorkflowVersion.id);
            });
    }

    public refetchWorkflowVersionItemDetails(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    ): Promise<void> {
        return this.$rootScope['urql']
            ?.query(
                GetItemDetailsForItemDetailsModuleEditorDocument,
                { workflowVersionId },
                {
                    requestPolicy: 'network-only',
                },
            )
            ?.toPromise();
    }
}

export default angular.module('tonkean.app').service('workflowVersionDataSourceManager', WorkflowVersionDataSourceManager);