import { reactToAngular } from '@tonkean/angular-components';
import { InterfaceModuleHeader } from '@tonkean/interface-module';

angular
    .module('tonkean.app')
    .component(
        'tnkInterfaceModuleHeader',
        reactToAngular(InterfaceModuleHeader, [
            'logoUrl',
            'versionType',
            'formType',
            'workflowVersionId',
            'currentCustomTriggerId',
            'parentInitiativeId',
            'currentFormId',
            'themeColor',
            'projectUrlSlug',
        ]),
    );
