import { useAngularService } from 'angulareact';
import { useCallback, useEffect, useState } from 'react';

import workerRunLogicInspectTabToComponent from '../entities/WorkerRunLogicInspectTabToComponent';

import type { Environment, WorkerRunLogicInspectTabType, WorkerRunStage } from '@tonkean/tonkean-entities';
import { workerRunStageToPathParam } from '@tonkean/tonkean-entities';
import { RuntimePageTab } from '@tonkean/tonkean-entities';

export enum RootState {
    MODAL = 'product.workerEditor.history',
    TAB = 'product.workers',
    RUNTIME = 'product.runtime',
}

export interface StateParams {
    groupId: string;
    environment?: Environment;
    workerRunInfo?: string;
    workerRunStage?: WorkerRunStage;
    inspect: boolean;
    workerRunLogicId?: string;
    tab?: WorkerRunLogicInspectTabType | RuntimePageTab;

    /** Non url params */
    defaultFilterSearchTerm?: string;
}

const stateNameToUrlStateNameMap: Record<RootState, Partial<Record<keyof StateParams, string>>> = {
    [RootState.MODAL]: {
        groupId: 'g',
        environment: 'environment',
        workerRunInfo: 'wr',
        workerRunStage: 'workerRunStage',
        inspect: 'inspect',
        workerRunLogicId: 'workerRunLogicId',
        tab: 'tab',
        defaultFilterSearchTerm: 'defaultFilterSearchTerm',
    },
    [RootState.TAB]: {
        groupId: 'g',
        workerRunInfo: 'wr',
        environment: 'environment',
    },
    [RootState.RUNTIME]: {
        groupId: 'g',
        environment: 'environment',
        defaultFilterSearchTerm: 'defaultFilterSearchTerm',
        workerRunInfo: 'wr',
        tab: 'tab',
        inspect: 'inspect',
    },
};

/**
 * Convert a react state value to angular's state param value.
 *
 * @param key - the param key.
 * @param value - the param value.
 * @returns the value to pass to angular's state.
 */
function toAngularStateValue(key: keyof StateParams, value: string, rootState?: RootState) {
    switch (key) {
        case 'workerRunStage':
            return workerRunStageToPathParam[value as WorkerRunStage];
        case 'tab':
            // if the value is suitable for workerRunLogicInspectTabToComponent - use it, otherwise, if the rootState is runtime- use history tab.
            return (
                workerRunLogicInspectTabToComponent[value]?.tabKey ||
                (rootState === RootState.RUNTIME ? RuntimePageTab.HISTORY : undefined)
            );
        default:
            return value;
    }
}

/**
 * Convert react state params to angular's state (=url) params.
 *
 * @param rootState - the current root state.
 * @param params - object with react state params.
 * @returns object with angular state params.
 */
function toAngularStateParams(rootState: RootState, params: Partial<StateParams>) {
    return Object.fromEntries(
        Object.entries(params)
            .map(([key, value]: [keyof StateParams, any]) => [
                stateNameToUrlStateNameMap[rootState][key],
                toAngularStateValue(key, value, rootState),
            ])
            .filter(([key]) => !!key),
    );
}

/**
 * A function to get the "highest" state possible based on the given state params.
 *
 * @param rootState - the current root state.
 * @param stateParams - the state params to base the state name on.
 * @returns a state name.
 */
function getModalStateName(rootState: RootState, stateParams: StateParams): string {
    switch (rootState) {
        case RootState.MODAL:
            if (!stateParams.workerRunInfo) {
                return RootState.MODAL;
            }
            if (!stateParams.workerRunStage) {
                return RootState.MODAL;
            }
            if (!stateParams.inspect) {
                return `${RootState.MODAL}.workerRunStage`;
            }
            if (!stateParams.workerRunLogicId) {
                return `${RootState.MODAL}.workerRunStage.inspect`;
            }
            if (!stateParams.tab) {
                return `${RootState.MODAL}.workerRunStage.inspect.workerRunLogic`;
            }
            return `${RootState.MODAL}.workerRunStage.inspect.workerRunLogic.tab`;

        case RootState.TAB:
            return RootState.TAB;
        case RootState.RUNTIME:
            return RootState.RUNTIME;
    }
}

/**
 * A react hook to manage angular state (url) for the history modal.
 *
 * @param rootState - the current root state.
 * @param angularState - the current url state params.
 * @returns the current state, a function to update the state and a function to get state name and params based
 * on partial params.
 */
function useHistoryStateManager(rootState: RootState, angularState: StateParams) {
    const $state = useAngularService('$state');
    const [previousAngularState, setPreviousAngularState] = useState<StateParams>(angularState);
    const [state, setState] = useState<StateParams>(angularState);

    /**
     * Update react state when the url changes
     */
    useEffect(() => {
        const newStateEntries = Object.entries(angularState).filter(
            ([key, value]) => previousAngularState[key] !== value,
        );
        if (!newStateEntries.length) {
            return;
        }

        const newState = Object.fromEntries(newStateEntries);
        setState((currentState) => ({
            ...currentState,
            ...newState,
        }));
        setPreviousAngularState(angularState);
    }, [angularState, previousAngularState]);

    /**
     * Function to get the state name and params, useful for generating props to pass to StateLink.
     *
     * @param newState - the new state params. Can pass partial state, it will be concatenated with the current state.
     * @returns object with the state name and params.
     */
    const getState = useCallback(
        (newState: Partial<StateParams>, overrideRootState?: RootState) => {
            const updatedState = { ...state, ...newState };

            const name = getModalStateName(overrideRootState ?? rootState, updatedState);
            const params = {
                ...$state.params,
                ...toAngularStateParams(overrideRootState ?? rootState, updatedState),
            };

            return { name, params };
        },
        [state, rootState, $state.params],
    );

    /**
     * Updates the internal react state and redirects the angular state.
     *
     * @param newState - the new state params. Can pass partial state, it will be concatenated with the current state.
     * @param showInBrowserHistory - should the state change be shown in history?
     */
    const updateState = useCallback(
        (newState: Partial<StateParams>, showInBrowserHistory: boolean = true) => {
            const updatedState = { ...state, ...newState };
            setState(updatedState);

            // Url state manager shouldn't update the url if it's not in the history url.
            const currentStateName: string = $state.current.name;
            const possibleRootStates = [RootState.TAB, RootState.MODAL, RootState.RUNTIME];
            if (possibleRootStates.every((possibleRootState) => !currentStateName.startsWith(possibleRootState))) {
                return;
            }

            const { name, params } = getState(
                newState,
                currentStateName === RootState.RUNTIME ? RootState.RUNTIME : undefined,
            );
            const redirectParam = {
                ...(showInBrowserHistory ? {} : { location: 'replace' }),
                ...(rootState === RootState.TAB ? { notify: false } : {}),
                inherit: false,
            };

            $state.go(name, params, redirectParam);
        },
        [state, getState, $state, rootState],
    );

    return { state, updateState, getState };
}

export default useHistoryStateManager;
