class ManualFieldValidatorUtils {
    /**
     * In any case there are restrictions regarding any field update (like in the case of append usage method in googlesheets)
     * we would like to have a validator in order to make sure we get a proper error in case there is a violation.
     */
    public static integrationFieldUpdateValidator(
        definition,
        performedActionDefinition,
        validationObject: any,
        projectManager,
    ) {
        const actionProjectIntegration = projectManager.getProjectIntegrationById(definition.projectIntegrationId);

        switch (definition.integrationType) {
            case 'GOOGLESHEETS':
                const googleSheetUsageMethod =
                    actionProjectIntegration.projectData.projectDatas[0].config.spreadsheetUsageMethodType;

                if (googleSheetUsageMethod == 'APPEND' && !performedActionDefinition.fieldDefinitionIdToValueMap) {
                    validationObject.cantUpdateAppend = "Can't update or delete in append.";
                }
        }
    }

    public static integrationFieldCreateValidator(definition, validationObject: any) {
        const performedActionDefinition = definition.performedActionDefinition;

        if (performedActionDefinition.entityMetadata?.requiredFields) {
            const keys = Object.keys(performedActionDefinition.entityMetadata.requiredFields);
            for (const key of keys) {
                if (
                    !performedActionDefinition.fieldsForCreation?.[key] ||
                    (performedActionDefinition.fieldsForCreation[key].valueInputConfiguration &&
                        performedActionDefinition.fieldsForCreation[key].valueInputConfiguration
                            .valueInputOptionType === 'EXPRESSION' &&
                        !performedActionDefinition.fieldsForCreation[key].valueInputConfiguration.evaluatedExpression)
                ) {
                    validationObject.requiredFields = 'Must fill all required fields.';
                    break;
                }
            }
        }
    }
}

export default ManualFieldValidatorUtils;
