import { useAngularService } from 'angulareact';
import React, { useState } from 'react';
import styled from 'styled-components';

import SolutionBusinessReportCustomFilters from './SolutionBusinessReportCustomFilters';
import SolutionBusinessReportSavedFilters from './SolutionBusinessReportSavedFilters';

import type { ModifiableListReturnType } from '@tonkean/infrastructure';
import type { PeopleAutocompleteSelectComponentType } from '@tonkean/infrastructure';
import { CheckboxIcon, Chevron, ChevronDirection, Menu, MenuItem, Popover, Separator } from '@tonkean/infrastructure';
import { PeopleAutocompleteSelect } from '@tonkean/infrastructure';
import type {
    SolutionBusinessReportGroupFilters,
    SolutionBusinessReportSavedFilter,
    State,
} from '@tonkean/tonkean-entities';
import { ButtonShape } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Filters = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 5px 25px 10px;

    border-bottom: 1px solid ${Theme.colors.gray_300};
`;

const Filter = styled(Clickable)`
    display: flex;
    align-items: center;
    margin-right: 48px;
    size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
`;

const FilterChevron = styled(Chevron)`
    margin-left: 5px;
`;

const StyledPersonSelector = styled(PeopleAutocompleteSelect)`
    width: 300px;
` as PeopleAutocompleteSelectComponentType;

const StatusFilterMenuContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
`;

const StatusFilterMenuItem = styled(MenuItem)`
    min-width: 160px;
    max-width: 300px;
`;

interface Props {
    groupId?: string;
    workflowVersionId?: string;
    solutionBusinessReportId?: string;
    filters: SolutionBusinessReportGroupFilters;
    savedFiltersModifiableList: ModifiableListReturnType<SolutionBusinessReportSavedFilter>;
    onFiltersChange(filters: SolutionBusinessReportGroupFilters): void;
}

const SolutionBusinessReportFilters: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    solutionBusinessReportId,
    filters,
    savedFiltersModifiableList,
    onFiltersChange,
}) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const projectManager = useAngularService('projectManager');

    const [statusFilterMenuOpen, setStatusFilterMenuOpen] = useState(false);
    const [ownerFilterMenuOpen, setOwnerFilterMenuOpen] = useState(false);
    const [filterMenuOpen, setFilterMenuOpen] = useState(false);

    const states: State[] | undefined = workflowVersionId
        ? workflowVersionManager.getCachedWorkflowVersion(workflowVersionId)?.states ||
          projectManager.projectData.stats.map((state) => ({
              id: state.stateText,
              label: state.stateText,
              color: state.stateColor,
              type: state.status,
          }))
        : undefined;

    const onStatusesFilterChanged = (event: React.SyntheticEvent, statuses: string[], hideDone: boolean) => {
        onFiltersChange({
            ...filters,
            statuses,
            hideDone,
        });
        event.preventDefault();
    };

    const loading = !groupId || !workflowVersionId;

    return (
        <Filters>
            <Menu
                show={statusFilterMenuOpen}
                onClose={() => setStatusFilterMenuOpen(false)}
                menuItems={
                    states ? (
                        <>
                            <MenuItem onClick={(event) => onStatusesFilterChanged(event, [], true)}>
                                <StatusFilterMenuContentWrapper>
                                    Active statuses
                                    <CheckboxIcon
                                        size={InputSize.SMALL}
                                        shape={ButtonShape.RECTANGULAR}
                                        checked={filters.statuses.length === 0 && filters.hideDone}
                                    />
                                </StatusFilterMenuContentWrapper>
                            </MenuItem>
                            <MenuItem onClick={(event) => onStatusesFilterChanged(event, [], false)}>
                                <StatusFilterMenuContentWrapper>
                                    All statuses
                                    <CheckboxIcon
                                        size={InputSize.SMALL}
                                        shape={ButtonShape.RECTANGULAR}
                                        checked={filters.statuses.length === 0 && !filters.hideDone}
                                    />
                                </StatusFilterMenuContentWrapper>
                            </MenuItem>

                            <Separator />

                            {states.map((state) => (
                                <StatusFilterMenuItem
                                    key={state.id}
                                    onClick={(event) => {
                                        const shouldRemoveStatus = filters.statuses.includes(state.label);
                                        const newStatuses = shouldRemoveStatus
                                            ? filters.statuses.filter((existingLabel) => existingLabel !== state.label)
                                            : [...filters.statuses, state.label];

                                        onStatusesFilterChanged(event, newStatuses, true);
                                    }}
                                    icon={<i className="fa fa-circle margin-right-xs" style={{ color: state.color }} />}
                                >
                                    <StatusFilterMenuContentWrapper>
                                        {state.label}
                                        <CheckboxIcon
                                            size={InputSize.SMALL}
                                            shape={ButtonShape.RECTANGULAR}
                                            checked={filters.statuses.includes(state.label)}
                                        />
                                    </StatusFilterMenuContentWrapper>
                                </StatusFilterMenuItem>
                            ))}
                        </>
                    ) : null
                }
            >
                <Filter onClick={() => setStatusFilterMenuOpen(true)} disabled={loading}>
                    Status
                    <FilterChevron direction={statusFilterMenuOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
                </Filter>
            </Menu>
            <Popover
                show={ownerFilterMenuOpen}
                onClose={() => setOwnerFilterMenuOpen(false)}
                content={
                    <StyledPersonSelector
                        value={filters.owners}
                        onChange={(owners) => onFiltersChange({ ...filters, owners })}
                        onlyOwnersInGroupId={groupId}
                        isMulti
                    />
                }
            >
                <Filter onClick={() => setOwnerFilterMenuOpen(true)} disabled={loading}>
                    Owner
                    <FilterChevron direction={ownerFilterMenuOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
                </Filter>
            </Popover>
            <Popover
                show={filterMenuOpen}
                onClose={() => setFilterMenuOpen(false)}
                content={
                    workflowVersionId && groupId ? (
                        <SolutionBusinessReportCustomFilters
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                            filters={filters.definition}
                            onFiltersChange={(definition) => {
                                onFiltersChange({ ...filters, definition });
                                setFilterMenuOpen(false);
                            }}
                            onCancel={() => setFilterMenuOpen(false)}
                        />
                    ) : null
                }
            >
                <Filter onClick={() => setFilterMenuOpen(true)} disabled={loading}>
                    Filter
                    <FilterChevron direction={filterMenuOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
                </Filter>
            </Popover>

            {solutionBusinessReportId && groupId && (
                <SolutionBusinessReportSavedFilters
                    solutionBusinessReportId={solutionBusinessReportId}
                    groupId={groupId}
                    definition={filters}
                    savedFiltersModifiableList={savedFiltersModifiableList}
                    onDefinitionChange={onFiltersChange}
                />
            )}
        </Filters>
    );
};

export default SolutionBusinessReportFilters;
