import type { Group, Initiative } from '@tonkean/tonkean-entities';

/**
 * Checks if the initiative is synced with an external source, and if the project integration it was synced from is still available!
 */
export function initiativeSyncedWithExternalSource(initiative: Initiative) {
    if (!initiative) {
        return false;
    }
    if (!initiative.externalSource || !initiative.externalType) {
        return false;
    }
    if (!(initiative.group as Group).groupUniqueTypeToProjectIntegrationMap![initiative.externalSource]) {
        return false;
    }

    return true;
}
