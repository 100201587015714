import { getStateError } from '@tonkean/utils';

function CreateWorkflowFolderCtrl($scope, projectId, workflowFolderManager, projectManager, $rootScope) {
    $scope.data = {
        workflowFolderName: '',
    };

    // adding this flag to hide the option of sandbox solution
    // Note if you want to delete this code you have to change the button "Create Solution" validation
    // Because now user able to add only non sandbox workflow folders.
    $scope.shouldAllowUserToAddSandBox = false;

    $scope.isSandbox = false;

    $scope.pm = projectManager;

    $scope.cancel = function () {
        $scope.$dismiss();
    };

    // return true if the sandbox option should be disabled
    $scope.isSandboxDisabled = function () {
        // disable when the user reach the limit of normal workflow folders
        if ($scope.countOfNormalWorkflowFolders() >= $scope.pm.projectData.maxAllowedWorkflowFolders) {
            return true;
        }
        // disable when the user reach the limit of sandbox workflow folders
        if ($scope.countOfSandboxWorkflowFolders() >= $scope.pm.projectData.maxAllowedSandboxWorkflowFolders) {
            return true;
        }

        return false;
    };

    // Count the amount of SandboxWorkflowFolders
    $scope.countOfSandboxWorkflowFolders = function () {
        return (
            (workflowFolderManager.projectIdToFolderIdToFolderMap[$scope.pm.project.id] &&
                Object.values(workflowFolderManager.projectIdToFolderIdToFolderMap[$scope.pm.project.id]).filter(
                    (workflowFolder) => workflowFolder.isSandbox,
                ).length) ||
            0
        );
    };

    // Count the amount of NormalWorkflowFolders
    $scope.countOfNormalWorkflowFolders = function () {
        return (
            (workflowFolderManager.projectIdToFolderIdToFolderMap[$scope.pm.project.id] &&
                Object.values(workflowFolderManager.projectIdToFolderIdToFolderMap[$scope.pm.project.id]).filter(
                    (workflowFolder) => !workflowFolder.isSandbox,
                ).length) ||
            0
        );
    };

    $scope.onIsSandboxChanged = (event) => {
        $scope.isSandbox = event.target.checked;
        $rootScope.$applyAsync();
    };

    $scope.init = () => {
        // on init the modal we give isSandbox the correct value,
        // if the sandbox disable we set to true if the user reached the maxAllowedWorkflowFolders
        $scope.isSandbox =
            $scope.isSandboxDisabled() &&
            $scope.countOfNormalWorkflowFolders() >= $scope.pm.projectData.maxAllowedWorkflowFolders;
    };

    $scope.save = function () {
        $scope.data.loading = true;
        $scope.data.error = null;
        return workflowFolderManager
            .createWorkflowFolder($scope.data.workflowFolderName, $scope.isSandbox, projectId)
            .then((workflowFolder) => {
                $scope.$close(workflowFolder);
            })
            .catch((error) => {
                $scope.data.error = getStateError(error, {
                    fallbackErrorMessage: 'There was an error creating a solution.',
                });
            });
    };
}

export default angular.module('tonkean.app').controller('CreateWorkflowFolderCtrl', CreateWorkflowFolderCtrl);
