<div class="manage-groups-modal common-close-btn-container clearfix" ng-hide="isHidden">
    <!-- Close button -->
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <!-- Content-->
    <div class="flex flex-col manage-groups-modal-content">
        <!-- Title-->
        <div class="flex-vmiddle">
            <span class="flex-grow common-title">All business reports</span>
            <!--<button class="flex-no-shrink btn btn-primary" ng-click="openNewListModal()">-->
            <!--New list-->
            <!--</button>-->
        </div>

        <!-- Loading-->
        <div class="common-page-load" ng-if="isLoadingGroups">
            <i class="loading-full-circle"></i>
        </div>

        <!-- Groups-->
        <div class="flex-grow flex flex-col" ng-if="!isLoadingGroups">
            <!-- Search-->
            <div class="flex padding-top-xs margin-bottom">
                <input
                    class="flex-grow form-control"
                    placeholder="Search..."
                    ng-model="filterText"
                    ng-change="onSearch(filterText)"
                />
            </div>

            <!-- Tabs -->
            <div
                class="flex-vmiddle common-color-dark-grey mod-inline flex-xs-wrap margin-top tabs"
                ng-if="!isSearching"
            >
                <div
                    ng-repeat="(id, tab) in tabs"
                    class="
                        btn btn-default
                        manage-groups-tab
                        common-title-font common-color-light-grey2
                        tabs-font-size
                        margin-right margin-bottom
                    "
                    ng-class="{ 'selected-option': data.selectedTab === id }"
                    ng-click="data.selectedTab = id"
                >
                    {{ tab.name }}
                </div>
            </div>

            <!-- Showen Lists -->
            <div ng-show="filtered.length > 0" class="groups-box">
                <div
                    ng-repeat="group in pm.groups | filter: (isSearching ? { name: filterText }: tabs[data.selectedTab].filter) as filtered"
                    class="group-item flex-vmiddle show-edit-when-hover-visibility"
                >
                    <div
                        class="flex-grow"
                        ui-sref="product.board({filter:'all', g:group.id, st:null, listFilter: null, isGroupListFilter: null})"
                        ng-click="$dismiss()"
                    >
                        <div class="flex-vmiddle">
                            <div class="common-size-xxs">{{ group.name }}</div>
                            <i
                                class="svg-icon common-color-light-grey margin-left-xxs margin-bottom-xxs"
                                ng-if="group.visibilityType === 'PRIVATE'"
                            >
                                <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                            </i>
                        </div>
                        <div class="common-color-light-grey2 common-size-xxxxs">
                            Created by
                            <span class="common-color-black">{{ group.creator.name }}</span>
                            <span>on {{::group.created | date : 'MMMM dd, yyyy'}}</span>
                            <span ng-if="group.visibilityType === 'PRIVATE'">, {{ group.members.length }} members</span>
                        </div>
                    </div>
                    <div ng-click="toggleStar(group)" class="star-icon">
                        <i class="svg-icon-md common-color-yellow" ng-if="group.isStarred">
                            <tnk-icon src="/images/icons/star.svg"></tnk-icon>
                        </i>
                        <i class="svg-icon-md star-icon-empty" ng-if="!group.isStarred">
                            <tnk-icon src="/images/icons/star-not-selected.svg"></tnk-icon>
                        </i>
                    </div>
                </div>
            </div>

            <!-- No Matches-->
            <div
                ng-if="filtered.length === 0"
                class="margin-top-md margin-bottom-lg flex-vmiddle"
                style="
                     {
                        border: 1px solid #ddd;
                    }
                "
            >
                <i class="fa fa-search fa-2x common-color-primary margin-right"></i>
                <div>
                    <div class="common-section-title-sm">No matches found for what you searched</div>
                    <div class="common-subtitle-inner">Try searching again. Sorry!</div>
                </div>
            </div>
        </div>
    </div>
</div>
