import { angularToReact } from 'angulareact';

const ExampleItemEvaluatedExpression = angularToReact<{
    groupId: string;
    workflowVersionId: string;
    logicId: string;
    expressionUniqueIdentifier?: string;
    doNotEvaluatePreview?: boolean;
    overrideExampleInitiativeId?: string;
    hidePreview?: boolean;
    htmlPreview?: boolean;
    previewEvaluationSource?: any;
    expressionToEvaluate?: string;
    fieldIdToEvaluate?: string;
    hideIcon?: boolean;
    isInline?: boolean;
    color?: string;
    showPeopleDirectories?: boolean;
}>('tnk-example-item-evaluated-expression', angular.module('tonkean.app'));

export default ExampleItemEvaluatedExpression;
