import React from 'react';
import styled from 'styled-components';

import MarketplaceGalleryItem from './MarketplaceGalleryItem';
import type { MarketplaceItemCreateSection } from '../../../components/common/tnkWorkersBrowser/marketplace/components/MarketplaceItemCreateModalSection';

import { H3, H4 } from '@tonkean/infrastructure';
import {
    BlueprintSolutionType,
    MarketplaceItemType,
    type MarketplaceItem,
    type SolutionMarketplaceItem,
    type TonkeanId,
    type TonkeanType,
} from '@tonkean/tonkean-entities';

const GallerySection = styled.div`
    margin-bottom: 10px;
`;

const GallerySectionTitle = styled(H3)`
    margin-top: 20px;
    margin-bottom: 10px;
`;

const GalleryItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 30%));
    grid-gap: 20px;
`;

const marketplaceItemTypeToEntityPluralName: Record<MarketplaceItemType, string> = {
    [MarketplaceItemType.SOLUTION]: 'solution components',
    [MarketplaceItemType.MODULE]: 'module templates',
    [MarketplaceItemType.DATA_SOURCE]: 'data sources',
    [MarketplaceItemType.PAGE]: 'pages',
    [MarketplaceItemType.CONTRACT]: 'contracts',
};

const marketplaceItemTypeToEntityTitle: Record<MarketplaceItemType | BlueprintSolutionType, string> = {
    [MarketplaceItemType.SOLUTION]: 'Solutions',
    [MarketplaceItemType.MODULE]: 'Modules',
    [MarketplaceItemType.DATA_SOURCE]: 'Data Sources',
    [MarketplaceItemType.PAGE]: 'Pages',
    [MarketplaceItemType.CONTRACT]: 'Contracts',
    [BlueprintSolutionType.SOLUTION_TEMPLATE]: 'Internal Solution Templates',
    [BlueprintSolutionType.MARKETPLACE_BLUEPRINT]: 'Marketplace Blueprints',
};

interface Props {
    type: MarketplaceItemType | BlueprintSolutionType;
    marketplaceItems: MarketplaceItem[];
    marketplacitemToRootEntityNameMap: Record<string, string> | undefined;
    dataAutomation: string;
    onItemSubmitted: (marketplaceItem: MarketplaceItem) => void;
    onItemDeleted: (marketplaceItemId: TonkeanId<TonkeanType.MARKETPLACE_ITEM>) => void;
    additionalSections?: MarketplaceItemCreateSection[];
    validate?: (item: SolutionMarketplaceItem) => boolean;
    getItemsToDownload?: (item: SolutionMarketplaceItem) => MarketplaceItem[];
}

const MarketplaceGallerySectionByType: React.FC<Props> = ({
    type,
    marketplaceItems,
    marketplacitemToRootEntityNameMap,
    dataAutomation,
    onItemSubmitted,
    onItemDeleted,
    additionalSections,
    validate,
    getItemsToDownload,
}) => {
    return (
        <>
            <GallerySectionTitle>{marketplaceItemTypeToEntityTitle[type]}</GallerySectionTitle>
            {!!marketplaceItems.length && (
                <GallerySection>
                    <GalleryItemsContainer>
                        {marketplaceItems.map((item) => (
                            <MarketplaceGalleryItem
                                dataAutomation={`${dataAutomation}-${item.title}`}
                                key={item.id}
                                marketplaceItem={item}
                                rootEntityName={marketplacitemToRootEntityNameMap?.[item.rootEntityId]}
                                onItemSubmitted={onItemSubmitted}
                                onItemDeleted={onItemDeleted}
                                additionalSections={additionalSections}
                                validate={() => (validate ? validate(item as SolutionMarketplaceItem) : true)}
                                getItemsToDownload={() =>
                                    getItemsToDownload ? getItemsToDownload(item as SolutionMarketplaceItem) : [item]
                                }
                            />
                        ))}
                    </GalleryItemsContainer>
                </GallerySection>
            )}
            {!marketplaceItems.length && <H4 $light>No {marketplaceItemTypeToEntityPluralName[type]} found</H4>}
        </>
    );
};

export default MarketplaceGallerySectionByType;
