import { useAngularService } from 'angulareact';
import React, { type Dispatch, type SetStateAction, useMemo } from 'react';
import styled from 'styled-components';

import SolutionSiteBuilderHeader from './SolutionSiteBuilderHeader';
import SolutionSiteInterfaceEmptyView from './SolutionSiteInterfaceEmptyView';
import SolutionSiteInterfaceHeaderColorBanner from './SolutionSiteInterfaceHeaderColorBanner';
import SolutionSiteInterfacePageNotFound from './SolutionSiteInterfacePageNotFound';
import useSolutionSitePagesContext from '../hooks/useSolutionSitePagesContext';

import { Breakpoint, ItemInterfaceSection, LoadingCircle, Spacer } from '@tonkean/infrastructure';
import {
    GeneralInterfaceWrapper,
    ItemInterfaceBuilderEmptySpot,
    ItemInterfacePermission,
    SolutionSitePageWidgetsPanel,
    useInterfaceTabs,
    useSolutionSiteContext,
} from '@tonkean/interface-module';
import { ItemInterfaceLoadingState, useSolutionSiteThemeConfiguration } from '@tonkean/tonkean-entities';
import type {
    IncludedWidgetsSummaryByInterface,
    SolutionSitePageWidget,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

export const SolutionSitePageGrid = styled.div`
    display: grid;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    grid-template-areas:
        'solution-site-banner'
        'solution-site-header'
        'solution-site-body';
    grid-template-columns: 100%;
`;

export const SolutionSitePageCanvasContent = styled.div`
    grid-area: solution-site-body;
    overflow: auto;
    background-color: ${Theme.colors.gray};
    display: flex;
    gap: 32px;
    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        margin-top: 0;
        gap: 8px;
    }
`;

export const SolutionSitePageWidgetsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 26px 58px;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: 0 8px;
        margin-top: 24px;
    }
`;

interface Props {
    widgets: SolutionSitePageWidget[];
    includedWidgetsSummaryByInterfaceId: IncludedWidgetsSummaryByInterface | undefined;
    permission: ItemInterfacePermission;
    itemInterfaceWidgetsState: ItemInterfaceLoadingState;
    workflowVersionType: WorkflowVersionType;
    shouldShowColorBanner?: boolean;
    setSidepaneOpen?: Dispatch<SetStateAction<boolean>>;
}

const SolutionSitePageInterfaceView: React.FC<Props> = ({
    widgets,
    includedWidgetsSummaryByInterfaceId,
    permission,
    itemInterfaceWidgetsState,
    workflowVersionType,
    shouldShowColorBanner,
    setSidepaneOpen,
}) => {
    const widgetProps = useMemo(
        () => ({
            workflowVersionType,
            permission,
        }),
        [permission, workflowVersionType],
    );

    const { solutionSite } = useSolutionSiteContext();
    const { selectedPage } = useSolutionSitePagesContext();
    const projectManager = useAngularService('projectManager');
    const themeConfiguration = useSolutionSiteThemeConfiguration(solutionSite, projectManager.project);

    const tabs = useInterfaceTabs(widgets, includedWidgetsSummaryByInterfaceId);

    return (
        <GeneralInterfaceWrapper quickNavigationMode={selectedPage?.configuration?.quickNavigationMode} tabs={tabs}>
            <SolutionSitePageGrid>
                {shouldShowColorBanner && (
                    <SolutionSiteInterfaceHeaderColorBanner
                        data-automation="solution-site-builder-header"
                        $color={themeConfiguration.headerBackgroundColor}
                    />
                )}
                <SolutionSiteBuilderHeader
                    solutionSite={solutionSite}
                    selectedPage={selectedPage}
                    projectUrlSlug={projectManager.project.urlSlug}
                    widgets={widgets}
                    workflowVersionType={workflowVersionType}
                    permission={permission}
                    themeConfiguration={themeConfiguration}
                    setSidepaneOpen={setSidepaneOpen}
                />
                <SolutionSitePageCanvasContent>
                    <SolutionSitePageWidgetsWrapper>
                        <React.Suspense
                            fallback={
                                <>
                                    <SolutionSitePageWidgetsPanel loading />
                                    <Spacer height={32} />
                                </>
                            }
                        >
                            {!!widgets.length && (
                                <>
                                    <SolutionSitePageWidgetsPanel
                                        itemInterfaceWidgetsState={itemInterfaceWidgetsState}
                                        itemInterfaceWidgets={widgets}
                                        permission={permission}
                                        widgetProps={widgetProps}
                                        section={ItemInterfaceSection.MAIN}
                                    />
                                    <Spacer height={32} width={1} />
                                </>
                            )}
                            {itemInterfaceWidgetsState === ItemInterfaceLoadingState.LOADING && (
                                <LoadingCircle large centered />
                            )}

                            {itemInterfaceWidgetsState === ItemInterfaceLoadingState.NOT_FOUND && (
                                <SolutionSiteInterfacePageNotFound />
                            )}

                            {permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE && (
                                <ItemInterfaceBuilderEmptySpot />
                            )}

                            {itemInterfaceWidgetsState === ItemInterfaceLoadingState.IDLE &&
                                permission !== ItemInterfacePermission.INTERFACE_IS_EDITABLE &&
                                !widgets.length && <SolutionSiteInterfaceEmptyView />}
                        </React.Suspense>
                    </SolutionSitePageWidgetsWrapper>
                </SolutionSitePageCanvasContent>
            </SolutionSitePageGrid>
        </GeneralInterfaceWrapper>
    );
};

export default SolutionSitePageInterfaceView;
