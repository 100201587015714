/**
 * Helps with logic revolving caching with live report
 * Mainly used to separate logic to avoid using circular dependency between customFieldsManager and workflowVersionManager
 */
function LiveReportHelper(tonkeanService) {
    const _this = this;

    /**
     * A map between groupId to environment to a map of field definition id to its isHidden state.
     */
    _this.fieldsIsHiddenMap = {};

    /**
     * A map between solution business report id to a map of field definition id to its isHidden state.
     */
    _this.solutionReportsFieldsIsHiddenMap = {};

    /**
     * A map between groupId to environment to a map of field definition id to its index.
     */
    _this.fieldsIndexMap = {};

    /**
     * A map between solution business report id to a map of field definition id to its index.
     */
    _this.solutionReportsFieldsIndexMap = {};

    _this.cacheLiveReport = function (environment, liveReportFieldDefinitions, groupId, solutionBusinessReportId) {
        const fieldDefinitionIdToIsHiddenMap = {};
        const fieldDefinitionIdToIndexMap = {};
        liveReportFieldDefinitions.forEach((liveReportFieldDefinition) => {
            fieldDefinitionIdToIsHiddenMap[liveReportFieldDefinition.fieldDefinition.id] =
                liveReportFieldDefinition.isHidden;

            fieldDefinitionIdToIndexMap[liveReportFieldDefinition.fieldDefinition.id] = liveReportFieldDefinition.index;
        });
        // Caching the is hidden map of the group.
        _this.cacheFieldsHiddenMap(environment, fieldDefinitionIdToIsHiddenMap, groupId, solutionBusinessReportId);
        // Caching the index map of the group.
        _this.cacheFieldDefinitionsIndices(environment, fieldDefinitionIdToIndexMap, groupId, solutionBusinessReportId);
    };

    _this.setFieldIsHidden = function (
        fieldDefinitionId,
        isHidden,
        environment,
        groupId,
        targetType,
        solutionBusinessReportId,
    ) {
        return tonkeanService
            .setFieldIsHidden(fieldDefinitionId, isHidden, environment, groupId, targetType, solutionBusinessReportId)
            .then((ignored) => {
                _this.cacheFieldIsHidden(environment, fieldDefinitionId, isHidden, groupId, solutionBusinessReportId);
            });
    };

    _this.cacheFieldIsHidden = function (environment, fieldDefinitionId, isHidden, groupId, solutionBusinessReportId) {
        _this.initializeCache(environment, groupId, solutionBusinessReportId);
        if (solutionBusinessReportId) {
            _this.solutionReportsFieldsIsHiddenMap[solutionBusinessReportId][fieldDefinitionId] = isHidden;
        } else {
            _this.fieldsIsHiddenMap[groupId][environment][fieldDefinitionId] = isHidden;
        }
    };

    _this.cacheFieldsHiddenMap = function (environment, fieldsIsHiddenMap, groupId, solutionBusinessReportId) {
        if (!fieldsIsHiddenMap) {
            return;
        }

        if (solutionBusinessReportId) {
            _this.solutionReportsFieldsIsHiddenMap[solutionBusinessReportId] = fieldsIsHiddenMap;
        } else {
            if (!_this.fieldsIsHiddenMap[groupId]) {
                _this.fieldsIsHiddenMap[groupId] = {};
            }

            _this.fieldsIsHiddenMap[groupId][environment] = fieldsIsHiddenMap;
        }
    };

    _this.cacheFieldDefinitionsIndices = function (environment, fieldsIndexMap, groupId, solutionBusinessReportId) {
        if (!fieldsIndexMap) {
            return;
        }

        if (solutionBusinessReportId) {
            _this.solutionReportsFieldsIndexMap[solutionBusinessReportId] = fieldsIndexMap;
        } else {
            if (!_this.fieldsIndexMap[groupId]) {
                _this.fieldsIndexMap[groupId] = {};
            }

            _this.fieldsIndexMap[groupId][environment] = fieldsIndexMap;
        }
    };

    _this.getLiveReportFieldDefinitions = function (environment, groupId, solutionBusinessReportId) {
        let getFieldDefinitionsPromise;

        if (solutionBusinessReportId) {
            getFieldDefinitionsPromise =
                tonkeanService.getSolutionBusinessReportFieldDefinitions(solutionBusinessReportId);
        } else {
            getFieldDefinitionsPromise = tonkeanService.getLiveReportFieldDefinitions(environment, groupId);
        }

        return getFieldDefinitionsPromise.then((liveReportFieldDefinitions) =>
            this.cacheLiveReport(environment, liveReportFieldDefinitions, groupId, solutionBusinessReportId),
        );
    };

    _this.loadLiveReportFieldDefinitionsOfGroups = function (projectId, groupIds) {
        return tonkeanService.getLiveReportFieldDefinitionsOfGroups(projectId, groupIds).then((response) =>
            response.groupIdToEnvironmentIdsToLiveReportFields.forEach((singleGroupIdToEnvironmentToLiveReportFields) =>
                singleGroupIdToEnvironmentToLiveReportFields.environmentIdToLiveReportFields.forEach((entry) => {
                    this.cacheLiveReport(
                        entry.environmentId,
                        entry.liveReportFieldDefinitions,
                        singleGroupIdToEnvironmentToLiveReportFields.groupId,
                        undefined,
                    );
                }),
            ),
        );
    };

    _this.initializeCache = function (environment, groupId, solutionBusinessReportId) {
        if (environment && groupId) {
            _this.initializeFieldsCaches(environment, groupId);
        }

        if (solutionBusinessReportId) {
            _this.initializeSolutionBusinessReportRelatedCaches(solutionBusinessReportId);
        }
    };

    _this.initializeFieldsCaches = function (environment, groupId) {
        if (!_this.fieldsIndexMap) {
            _this.fieldsIndexMap = {};
        }
        if (!_this.fieldsIndexMap[groupId]) {
            _this.fieldsIndexMap[groupId] = {};
        }
        if (!_this.fieldsIndexMap[groupId][environment]) {
            _this.fieldsIndexMap[groupId][environment] = {};
        }

        if (!_this.fieldsIsHiddenMap) {
            _this.fieldsIsHiddenMap = {};
        }
        if (!_this.fieldsIsHiddenMap[groupId]) {
            _this.fieldsIsHiddenMap[groupId] = {};
        }
        if (!_this.fieldsIsHiddenMap[groupId][environment]) {
            _this.fieldsIsHiddenMap[groupId][environment] = {};
        }
    };

    _this.initializeSolutionBusinessReportRelatedCaches = function (solutionBusinessReportId) {
        if (!_this.solutionReportsFieldsIsHiddenMap) {
            _this.solutionReportsFieldsIsHiddenMap = {};
        }
        if (!_this.solutionReportsFieldsIsHiddenMap[solutionBusinessReportId]) {
            _this.solutionReportsFieldsIsHiddenMap[solutionBusinessReportId] = {};
        }

        if (!_this.solutionReportsFieldsIndexMap) {
            _this.solutionReportsFieldsIndexMap = {};
        }
        if (!_this.solutionReportsFieldsIndexMap[solutionBusinessReportId]) {
            _this.solutionReportsFieldsIndexMap[solutionBusinessReportId] = {};
        }
    };
}

angular.module('tonkean.app').service('liveReportHelper', LiveReportHelper);
