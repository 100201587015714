import { workerRunStageToPathParam } from '@tonkean/tonkean-entities';
import { createWorkerRunInfo } from '@tonkean/infrastructure';
import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function TestWorkflowCtrl(
    $scope,
    $state,
    $window,
    syncConfigCacheManager,
    customTriggerManager,
    modalUtils,
    tonkeanService,
    workflowVersionManager,
    formManager,
    projectManager,
) {
    const ctrl = this;

    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        shouldRunOnCustomTrigger: 'no',
        groupId: ctrl.groupId,
        testButtonSecondary: ctrl.testButtonSecondary,
        workflowVersionId: workflowVersionManager.getDraftVersionFromCache(ctrl.groupId).id,
        projectIntegrationId: undefined,
        externalType: undefined,
        externalItemToTestWith: undefined,
        availableCreateForms: [],
        testWorkflowMethods: {
            ITEM: 'ITEM',
            FORM: 'FORM',
        },
        selectedTestMethod: undefined,
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        // Object initialization used by the track search popover.
        $scope.popover = {
            search: {
                isOpen: false,
                isFocused: false,
                searchText: '',
                results: [],
            },
        };

        // Item label
        if (
            syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId) &&
            syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId).viewData &&
            syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId).viewData.entityMetadata
        ) {
            $scope.data.itemLabel = syncConfigCacheManager.getSyncConfig(
                $scope.data.workflowVersionId,
            ).viewData.entityMetadata.label;
        } else {
            $scope.data.itemLabel = 'item';
        }

        // Load external item options if has sync.
        const sync = syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId);
        const externalType = sync?.viewData?.entity || sync?.viewData?.entityMetadata?.entity;
        if (externalType) {
            $scope.data.projectIntegrationId = sync?.projectIntegration.id;
            $scope.data.externalType = externalType;
        }

        $scope.data.autonomousCustomTriggers = customTriggerManager.getCustomTriggersOfTypeInWorkflowVersionFromCache(
            $scope.data.workflowVersionId,
            'AUTONOMOUS',
        );

        $scope.data.selectedTestMethod = $scope.data.testWorkflowMethods.ITEM;
        formManager.getAllWorkerForm($scope.data.workflowVersionId).then((forms) => {
            const createForms = forms.filter((form) => form.formType === 'CREATE');
            $scope.data.availableForms = createForms;

            if (createForms.length === 1) {
                $scope.data.selectedForm = createForms[0];
            }
        });
    };

    $scope.onExternalItemToTestWithChanged = (externalItemToTestWith) => {
        $scope.data.externalItemToTestWith = externalItemToTestWith;
    };

    /**
     * Opens the initiative view in modal.
     */
    $scope.openViewInitiative = function () {
        modalUtils.openViewInitiative($scope.data.testedInitiative.id);
    };

    /**
     * Sending a test to the worker with selected initiative.
     */
    $scope.testWorker = function () {
        if ($scope.data.selectedTestMethod === $scope.data.testWorkflowMethods.ITEM) {
            testWorkerWithItem();
        } else if ($scope.data.selectedTestMethod === $scope.data.testWorkflowMethods.FORM) {
            const formUrl = $state.href('form', {
                formId: $scope.data.selectedForm.id,
                formVersionType: 'draft',
                projectId: projectManager.project.id,
                viewMode: 'real',
            });
            $window.open(formUrl, '_blank');
        }
    };

    function testWorkerWithItem() {
        $scope.data.sendingTestWorker = true;
        $scope.data.errorSendingTestWorker = null;
        const externalItemId = $scope.data.externalItemToTestWith ? $scope.data.externalItemToTestWith.id : null;

        let runOnCustomTriggerIds = null;
        if ($scope.data.runOnCustomTrigger) {
            runOnCustomTriggerIds = [$scope.data.runOnCustomTrigger.id];
        }
        let testWorkerPromise;
        if (workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).isScheduled) {
            testWorkerPromise = tonkeanService.runScheduledWorkerNow($scope.data.workflowVersionId);
        } else {
            testWorkerPromise = tonkeanService.testWorker(
                $scope.data.groupId,
                syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId) ? 'EXTERNAL_ITEM' : 'MANUAL_ITEM',
                null,
                externalItemId,
                $scope.data.manualItemToTestWith,
                runOnCustomTriggerIds,
            );
        }
        testWorkerPromise
            .then((data) => {
                getWorkerRunIdRecursively(data.initiative.id)
                    .then(({ workerRunId, workerRunStage }) => {
                        const trimmedInitiativeId = data.initiative.id.slice(4);
                        const defaultFilterSearchTerm = data.initiative.title ?? trimmedInitiativeId;

                        $state.go('product.workerEditor.history.workerRunStage', {
                            environment: 'build',
                            wr: workerRunId,
                            workerRunStage: workerRunStageToPathParam(workerRunStage),
                            defaultFilterSearchTerm,
                        });
                    })
                    .catch(() => {
                        // Got no worker run id, so will open the history without a specific worker run.
                        $state.go('product.workerEditor.history', {
                            environment: 'build',
                        });
                    })
                    .finally(() => {
                        ctrl.onTestItemFinished?.();
                    });
            })
            .catch(() => {
                $scope.data.errorSendingTestWorker = 'There was an error testing the workflow.';
                $scope.data.sendingTestWorker = false;
            });
    }

    /**
     * Recursive function to pool the new worker run id.
     *
     * @param initiativeId - the initiative id to look for it's worker run id.
     * @param maxAttempts - the amount of attempts to do before failing.
     * @returns {Promise<{workerRunId: string, workerRunStage: string}>} - a promise that resolves with the new worker run id.
     */
    function getWorkerRunIdRecursively(initiativeId, maxAttempts = 3) {
        return new Promise((resolve, reject) => {
            if (maxAttempts <= 0) {
                reject();
                return;
            }

            setTimeout(() => {
                tonkeanService
                    .getInitiativeAnyWorkerRun(initiativeId)
                    .then((data) => {
                        if (data.hasWorkerRuns) {
                            resolve({
                                workerRunInfo: createWorkerRunInfo(data.workerRunId, data.startTime),
                                workerRunStage: data.workerRunStage,
                            });
                        } else {
                            getWorkerRunIdRecursively(initiativeId, maxAttempts - 1)
                                .then(resolve)
                                .catch(reject);
                        }
                    })
                    .catch(reject);
            }, 1000);
        });
    }
}

export default angular.module('tonkean.app').controller('TestWorkflowCtrl', lateConstructController(TestWorkflowCtrl));
