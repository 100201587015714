import SolutionSitePageImageWidget from './components/SolutionSitePageImageWidget';
import SolutionSitePageImageWidgetEditor from './components/SolutionSitePageImageWidgetEditor';
import type ImageWidgetConfiguration from './ImageWidgetConfiguration';
import type { SolutionSitePageWidgetPackage } from '../../WidgetModule';

const SolutionSitePageImageWidgetPackage: SolutionSitePageWidgetPackage<ImageWidgetConfiguration> = {
    displayComponent: SolutionSitePageImageWidget,
    configurationComponent: SolutionSitePageImageWidgetEditor,
};

export default SolutionSitePageImageWidgetPackage;
