/**
 * Get integration type (external entity type) of the field.
 *
 * @param field - the field definition.
 * @returns the integration type.
 */
import type { AngularServices } from 'angulareact';

import type { FieldDefinition } from '@tonkean/tonkean-entities';

function getHistoryFieldIntegrationType(
    field: FieldDefinition<any>,
    workflowVersionId: string | undefined,
    customTriggerManager: AngularServices['customTriggerManager'],
): string | undefined {
    const isDataSourceMatchedEntity = field.type === 'ROOT';
    const isExternalEntity = field.type === 'EXTERNAL';
    if (field.definition && (isDataSourceMatchedEntity || isExternalEntity)) {
        return field.definition.integrationType;
    }
    // If it's a related entity field, it will be manual, but we should show the integration type of the
    // matched external entity.
    if (field.linkedCustomTrigger) {
        const customTrigger = customTriggerManager.getCachedCustomTrigger(
            workflowVersionId,
            field.linkedCustomTrigger.id,
        );
        return field.linkedCustomTrigger?.integrationType ?? customTrigger?.integrationType;
    }

    return field.projectIntegration?.integrationType;
}

export default getHistoryFieldIntegrationType;
