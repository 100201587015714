import { useCallback } from 'react';

import type { InitiativeRowData } from '@tonkean/items-grid';
import type { Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useHandleUpdateWrapper = (
    handleUpdate: (
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        updatedRow: InitiativeRowData,
        updatedCell: string,
        updatedValue: unknown,
    ) => Promise<{ id: `INIT${string}`; initiative: Initiative | undefined }>,
    fastInterval?: () => void,
) => {
    return useCallback(
        async (
            initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
            updatedRow: InitiativeRowData,
            updatedCell: string,
            updatedValue: unknown,
        ) => {
            const updateResponse = await handleUpdate(initiativeId, updatedRow, updatedCell, updatedValue);
            fastInterval?.();

            return updateResponse;
        },
        [fastInterval, handleUpdate],
    );
};

export default useHandleUpdateWrapper;
