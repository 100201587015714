import { useCallback } from 'react';

import type { InnerItemWidgetConfiguration } from '../../entities';
import EMPTY_CONDITIONS_DEFINITION from '../utils/emptyConditionsDefinition';

import { useFastReload, useInitiatives } from '@tonkean/infrastructure';
import {
    type BasicQueryDefinition,
    type TonkeanType,
    type WidgetBase,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { Initiative, TonkeanId } from '@tonkean/tonkean-entities';

interface ReloadInitiativeProps {
    reloadInitiatives: () => void;
    initiatives: Initiative[];
    loading: boolean;
    hasMorePages: boolean;
    isFetched: boolean;
    executionDate: number;
    totalInitiatives: number | undefined;
    fastInterval: () => void;
}
const useReloadInitiatives = (
    widget: WidgetBase<InnerItemWidgetConfiguration>,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    workflowVersionType: WorkflowVersionType | undefined,
    limit: number,
    debouncedSearchTerm?: string,
    calculatedFiltersQueryDefinition: BasicQueryDefinition = EMPTY_CONDITIONS_DEFINITION,
): ReloadInitiativeProps => {
    const [interval, fastInterval] = useFastReload(10_000, 1000);

    const [searchInitiatives, { initiatives, loading, hasMorePages, isFetched, executionDate, totalInitiatives }] =
        useInitiatives(limit, interval, widget.projectId);

    const reloadInitiatives = useCallback(() => {
        searchInitiatives(widget.projectId, {
            conditionsQuery: calculatedFiltersQueryDefinition,
            isArchived: false,
            groupId,
            isDraftInitiatives: workflowVersionType === WorkflowVersionType.DRAFT,
            isEditModeInitiatives: true,
            skip: 0,
            isRootInitiative: !debouncedSearchTerm && !widget.configuration.includeInnerItemsFromAllHierarchies,
            limit: 500,
            orderByFieldId: widget.configuration.sortByField?.fieldDefinitionId,
            orderByFieldType: widget.configuration.sortByField?.orderByFieldType,
            orderByType: widget.configuration.sortByField?.orderType,
        });
    }, [
        searchInitiatives,
        widget.projectId,
        widget.configuration.includeInnerItemsFromAllHierarchies,
        widget.configuration.sortByField?.fieldDefinitionId,
        widget.configuration.sortByField?.orderByFieldType,
        widget.configuration.sortByField?.orderType,
        calculatedFiltersQueryDefinition,
        groupId,
        workflowVersionType,
        debouncedSearchTerm,
    ]);

    return {
        reloadInitiatives,
        initiatives,
        loading: loading?.initial,
        hasMorePages,
        isFetched,
        executionDate,
        totalInitiatives,
        fastInterval,
    };
};

export default useReloadInitiatives;
