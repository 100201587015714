import { useAngularService } from 'angulareact';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import ScheduledInputSourceRecurrenceSelection from './ScheduledInputSourceRecurrenceSelection';
import WorkflowVersionInputSourceType from '../../../../entities/WorkflowVersionInputSourceType';
import InvalidLogicsValidationContext from '../../../ItemDetailsModuleEditorSidePane/InvalidLogicsValidationContext';
import type { InputSourceContentComponentProps } from '../../InputSourceContentComponentProps';
import { SelectInputSourceTitleContainer } from '../sharedComponents';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import {
    Field,
    FormikAutosave,
    FormikHelpers,
    H4,
    LoadingCircle,
    Paragraph,
    SimpleErrorStateMessage,
} from '@tonkean/infrastructure';
import { DayOfWeek } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ScheduledItemNameSubtitle = styled(Paragraph)`
    margin-top: 3px;
`;

const ScheduledInputSourceSelectionContent: React.FC<InputSourceContentComponentProps> = ({
    workflowVersion,
    setSelectedInputSourceType,
}) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const workflowVersionDataSourceManager = useAngularService('workflowVersionDataSourceManager');
    const [
        { error: updateRecurrenceTimeError, loading: updateRecurrenceTimeLoading },
        updateWorkflowVersionRecurringTime,
    ] = useLazyAsyncMethod(workflowVersionDataSourceManager, 'updateWorkflowVersionRecurringTime');

    const [
        { error: updateScheduledDefinitionError, loading: updateScheduledDefinitionLoading },
        updateWorkflowVersionScheduledWorkerDefinition,
    ] = useLazyAsyncMethod(workflowVersionManager, 'updateWorkflowVersionScheduledWorkerDefinition');

    const { requestLogicsValidation } = useContext(InvalidLogicsValidationContext);

    // Validation schema is built dynamically based on selected recurrence type
    const [validationSchema, setValidationSchema] = useState(undefined);

    return (
        <>
            <SelectInputSourceTitleContainer>
                <H4 $bold>Scheduled</H4>
                <ClickableLink onClick={() => setSelectedInputSourceType(WorkflowVersionInputSourceType.MANUAL)}>
                    Change Intake Method
                </ClickableLink>
            </SelectInputSourceTitleContainer>
            <Container>
                <Formik
                    initialValues={{
                        recurrencePeriodType: workflowVersion.recurrencePeriodType,
                        timezone: workflowVersion.timezone || dayjs.tz.guess(),
                        doNotRunOnWeekends: workflowVersion.doNotRunOnWeekends,
                        recurrenceMonthsInYear: workflowVersion.recurrenceMonthsInYear || [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
                        ],
                        recurrenceDaysInMonth: workflowVersion.recurrenceDaysInMonth?.[0] || 1,
                        recurrenceDaysInWeek: workflowVersion.recurrenceDaysInWeek || [DayOfWeek.MONDAY],
                        recurrenceHour: workflowVersion.recurrenceHour || 11,
                        recurrenceMinute: workflowVersion.recurrenceMinute || 0,
                        everyXHours: workflowVersion.everyXHours || 1,
                        everyXMinutes: workflowVersion.everyXMinutes || 30,
                    }}
                    onSubmit={(values) => {
                        // Need type validation so we dont send empty period type and ts doesnt understand unless we extract var
                        const recurrencePeriodType = values.recurrencePeriodType;
                        if (!recurrencePeriodType) {
                            return Promise.resolve();
                        }

                        const recurrenceDaysInMonth = [values.recurrenceDaysInMonth];
                        return updateWorkflowVersionRecurringTime({
                            ...values,
                            recurrencePeriodType,
                            recurrenceDaysInMonth,
                            groupId: workflowVersion.groupId,
                        }).then(() => requestLogicsValidation());
                    }}
                    validationSchema={validationSchema}
                >
                    <FormikHelpers>
                        <FormikAutosave />
                        <ScheduledInputSourceRecurrenceSelection setValidationSchema={setValidationSchema} />

                        {updateRecurrenceTimeLoading && <LoadingCircle />}
                        {updateRecurrenceTimeError && (
                            <SimpleErrorStateMessage error={updateRecurrenceTimeError} showSmallError />
                        )}
                    </FormikHelpers>
                </Formik>

                <Formik
                    initialValues={{
                        scheduledWorkerDefinition: workflowVersion.scheduledWorkerDefinition
                            ? // This prop is old and does not hold namimg coventions, so we need to map the existing values to standard tonkeanexpression
                              {
                                  originalExpression:
                                      workflowVersion.scheduledWorkerDefinition.scheduledTrackTitleExpression,
                                  evaluatedExpression:
                                      workflowVersion.scheduledWorkerDefinition.evaluatedScheduledTrackTitleExpression,
                              }
                            : {},
                    }}
                    onSubmit={(values) => {
                        return updateWorkflowVersionScheduledWorkerDefinition(
                            workflowVersion.groupId,
                            values.scheduledWorkerDefinition?.originalExpression,
                            values.scheduledWorkerDefinition?.evaluatedExpression,
                        );
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <FormikHelpers>
                            <FormikAutosave />
                            <Field
                                label={
                                    <>
                                        <H4>Scheduled Item Name</H4>
                                        <ScheduledItemNameSubtitle $light>
                                            When the scheduled time arrive a new item will be created with the following
                                            title.
                                        </ScheduledItemNameSubtitle>
                                    </>
                                }
                            >
                                <FormikTonkeanExpression
                                    groupId={workflowVersion.groupId}
                                    workflowVersionId={workflowVersion.id}
                                    logicId={undefined} // needed because this somehow sets correct available tabs
                                    placeholder="Type item name"
                                    name="scheduledWorkerDefinition"
                                    previewEvaluationSource={null}
                                    globalExpressionOnly
                                />
                            </Field>

                            {updateScheduledDefinitionLoading && <LoadingCircle />}
                            {updateScheduledDefinitionError && (
                                <SimpleErrorStateMessage error={updateScheduledDefinitionError} showSmallError />
                            )}
                        </FormikHelpers>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default ScheduledInputSourceSelectionContent;
