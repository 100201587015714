import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';

import { SavingIndicator, Toggle } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { CreateForm } from '@tonkean/tonkean-entities';
import { SavingState } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const TitleWrapper = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;

    display: flex;
    align-items: flex-end;
`;

interface Props {
    form: CreateForm;
    onFormChanged: Dispatch<SetStateAction<CreateForm>>;
}

const FormSettingsHomepageAccessEnabled: React.FC<Props> = ({ form, onFormChanged }) => {
    const { editedSections, setSectionLoading } = useContext(FormBuilderContext);

    const onChange = useCallback(
        ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
            if (form) {
                onFormChanged((prevForm) => ({
                    ...prevForm,
                    homepageEnabled: checked,
                }));
                setSectionLoading(FormBuilderSections.HomepageAccess);
            }
        },
        [form, onFormChanged, setSectionLoading],
    );

    return (
        <>
            <TitleWrapper>
                <FormSettingsSectionHeaderTitle>Home Page</FormSettingsSectionHeaderTitle>{' '}
                <SavingIndicator state={editedSections[FormBuilderSections.HomepageAccess]} />
            </TitleWrapper>
            <Toggle
                checked={form.homepageEnabled}
                onChange={onChange}
                size={InputSize.SMALL}
                disabled={editedSections[FormBuilderSections.HomepageAccess] === SavingState.LOADING}
                labelBefore="Form will be visible in Homepage search"
            />
        </>
    );
};

export default FormSettingsHomepageAccessEnabled;
