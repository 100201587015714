import { useAngularService } from 'angulareact';

import { useAsyncMethod } from './asyncMethod';

import type { AutonomousSecondaryType, CustomTriggerType } from '@tonkean/tonkean-entities';
import { type TonkeanId, type TonkeanType } from '@tonkean/tonkean-entities';

const useCustomTriggersOfTypeInWorkflowVersion = (
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    customTriggerType: CustomTriggerType,
    autonomousSecondaryType: AutonomousSecondaryType | undefined,
) => {
    const customTriggerManager = useAngularService('customTriggerManager');

    return useAsyncMethod(
        customTriggerManager,
        'getCustomTriggersOfTypeInWorkflowVersion',
        workflowVersionId,
        customTriggerType,
        autonomousSecondaryType,
    );
};

export default useCustomTriggersOfTypeInWorkflowVersion;
