import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function tnkIntercomIdTypePickerCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        onIdTypeSelected: ctrl.onIdTypeSelected,
        selectedIdType: ctrl.selectedIdType,
        idTypeOptions: ['INTERCOM_USER_ID', 'EXTERNAL_USER_ID', 'EMAIL'],
        idTypeDisplayNames: {
            INTERCOM_USER_ID: 'Intercom User ID',
            EXTERNAL_USER_ID: 'Custom User ID',
            EMAIL: 'Email',
        },
    };

    $scope.onUserIdTypeSelected = function () {
        $scope.data.onIdTypeSelected({ idTypeSelected: $scope.data.selectedIdType });
    };
}

export default angular
    .module('tonkean.app')
    .controller('tnkIntercomIdTypePickerCtrl', lateConstructController(tnkIntercomIdTypePickerCtrl));
