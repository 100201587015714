import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import WidgetItemSingleFilterPopover from './WidgetItemSingleFilterPopover';
import type { SingleFieldConfiguration } from '../widgets/CommonWidgetConfiguration/SingleFieldConfiguration';
import useGetWorkflowVersionFieldsAndBasicFields from '../widgets/hooks/useGetWorkflowVersionFieldsAndBasicFields';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { FilterIcon } from '@tonkean/svg';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import type { BasicQueryDefinition, FieldDefinition, FieldFilter, WorkflowVersion } from '@tonkean/tonkean-entities';
import { FieldQueryType, TonkeanFieldCondition } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const ClearFiltersButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ClearFiltersButton = styled(Button)`
    height: 30px;
    border: none;
    color: ${Theme.colors.gray_600};
`;

const FiltersContainer = styled.div`
    display: flex;
`;

const FilterByContainer = styled.div`
    color: ${Theme.colors.gray_700};
    display: flex;
    align-items: center;
    min-width: fit-content;
`;

const FilterByTitle = styled.span`
    margin-left: 4px;
`;

interface Props {
    endUserFilters: FieldFilter[];
    onEndUserFiltersChanged: (filters) => void;
    workflowVersionId: WorkflowVersion['id'];
    fields: Record<FieldDefinitionKey, SingleFieldConfiguration>;
    advancedFilter: BasicQueryDefinition;
    onChangeAdvancedFilter: (query: BasicQueryDefinition) => void;
    displayAdvancedFilters?: boolean;
}

const WidgetItemsFilters: React.FC<Props> = ({
    endUserFilters,
    onEndUserFiltersChanged,
    workflowVersionId,
    fields,
    advancedFilter,
    onChangeAdvancedFilter,
    displayAdvancedFilters = false,
}) => {
    const projectManager = useAngularService('projectManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const { data: workflowVersion, loading: loadingWorkflowVersion } = useAsyncMethod(
        workflowVersionManager,
        'getCachedWorkflowVersionOrGetFromServer',
        workflowVersionId,
    );

    const [fieldFiltersToFormattedLabels, setFieldFiltersToFormattedLabels] = useState<
        Record<FieldDefinitionKey, string | undefined>
    >({});

    const onChangeFormattedLabel = useCallback(
        (fieldFilterKey: FieldDefinitionKey, newFormattedLabel: string | undefined) => {
            const copyFieldFiltersToFormattedLabels = { ...fieldFiltersToFormattedLabels };
            copyFieldFiltersToFormattedLabels[fieldFilterKey] = newFormattedLabel;
            setFieldFiltersToFormattedLabels(copyFieldFiltersToFormattedLabels);
        },
        [fieldFiltersToFormattedLabels],
    );

    const { loading, allFieldDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(
        workflowVersionId,
        ['ITEM_INTERFACE_INNER_ITEMS_WIDGET'],
        ['TNK_TITLE'],
    );

    const possibleFilterFieldsToFieldDefinition = useMemo<Record<FieldDefinitionKey, FieldDefinition>>(() => {
        const configValue: Record<FieldDefinitionKey, FieldDefinition> = {};
        if (allFieldDefinitions) {
            allFieldDefinitions.forEach((fieldDefinition) => {
                const fieldDefinitionKey = fieldDefinition.id;
                const foundField: SingleFieldConfiguration | undefined = fields[fieldDefinitionKey];
                if (foundField?.isFilterColumn && foundField?.isShown) {
                    configValue[fieldDefinitionKey] = { ...fieldDefinition };
                }
            });
        }
        return configValue;
    }, [fields, allFieldDefinitions]);

    const sortedFieldsForFilters = useMemo<string[]>(() => {
        return Object.keys(fields).sort(
            (fieldA, fieldB) => Number(fields[fieldA]?.index) - Number(fields[fieldB]?.index),
        );
    }, [fields]);

    const fieldDefinitionIdsToFieldFilters = useMemo<Record<FieldDefinitionKey, FieldFilter | undefined>>(() => {
        const currentExistingFieldsMap = {};
        endUserFilters.forEach((filter) => {
            currentExistingFieldsMap[filter.fieldName] = { ...filter };
        });
        const existingFiltersKeys = Object.keys(currentExistingFieldsMap);
        sortedFieldsForFilters.forEach((possibleFilterFieldsKey) => {
            if (!existingFiltersKeys.includes(possibleFilterFieldsKey)) {
                currentExistingFieldsMap[possibleFilterFieldsKey] = undefined;
            }
        });
        return currentExistingFieldsMap;
    }, [endUserFilters, sortedFieldsForFilters]);

    const onFilterChanged = useCallback(
        (fieldId, value) => {
            const newEndUserFilters = [...endUserFilters];
            const fieldDefinition: FieldDefinition | undefined = possibleFilterFieldsToFieldDefinition[fieldId];
            if (fieldDefinition) {
                let filterNotExists = true;
                newEndUserFilters.forEach((filter) => {
                    if (filter.fieldName === fieldId) {
                        filter.value = value;
                        filterNotExists = false;
                    }
                });
                if (filterNotExists) {
                    newEndUserFilters.push({
                        fromOriginalEntity: fieldId !== 'TNK_OWNER_ID',
                        isSpecialField: fieldDefinition.isSpecialField,
                        possibleValuesMap: undefined,
                        values: [],
                        compareTimeframe: 1,
                        condition: TonkeanFieldCondition.Equals,
                        fieldLabel: fieldDefinition.name,
                        fieldName: fieldId,
                        id: utils.guid(),
                        type: fieldDefinition.fieldType,
                        value,
                    });
                }
            }
            onEndUserFiltersChanged(newEndUserFilters);
        },
        [possibleFilterFieldsToFieldDefinition, endUserFilters, onEndUserFiltersChanged],
    );

    const onFilterRemoved = useCallback(
        (fieldId?: FieldDefinition['id']) => {
            let newEndUserFilters: FieldFilter[] = [];
            if (fieldId) {
                newEndUserFilters = endUserFilters.filter((fieldFilter) => fieldFilter.fieldName !== fieldId);
            }
            onEndUserFiltersChanged(newEndUserFilters);
            setFieldFiltersToFormattedLabels({});
        },
        [endUserFilters, onEndUserFiltersChanged],
    );

    const clearAllFilters = useCallback(() => {
        onFilterRemoved();
        onChangeAdvancedFilter({
            query: {
                type: FieldQueryType.And,
                filters: [],
                queries: [],
            },
        });
    }, [onChangeAdvancedFilter, onFilterRemoved]);

    const hasAnyFilters = endUserFilters.length > 0 || advancedFilter.query.filters.length > 0;

    return (
        <>
            <FiltersContainer>
                <FilterByContainer>
                    <FilterIcon /> <FilterByTitle>Filter by</FilterByTitle>
                </FilterByContainer>
                {workflowVersion &&
                    fieldDefinitionIdsToFieldFilters &&
                    sortedFieldsForFilters.map((key) => {
                        const fieldDefinition = possibleFilterFieldsToFieldDefinition[key];
                        if (fieldDefinition) {
                            return (
                                <div key={`single-filter-container-${key}`}>
                                    <WidgetItemSingleFilterPopover
                                        fieldFilter={fieldDefinitionIdsToFieldFilters[key]}
                                        projectId={projectManager.project.id}
                                        workflowVersion={workflowVersion}
                                        groupId={workflowVersion.groupId}
                                        key={key}
                                        label={fields[key]?.label || fieldDefinition.name}
                                        fieldDefinition={fieldDefinition}
                                        onFilterChanged={onFilterChanged}
                                        onFilterRemoved={onFilterRemoved}
                                        formattedFilterLabel={fieldFiltersToFormattedLabels[key]}
                                        onChangeFormattedLabel={onChangeFormattedLabel}
                                    />
                                </div>
                            );
                        }
                    })}
                {displayAdvancedFilters && workflowVersion && (
                    <div key="single-filter-container-advanced-filters">
                        <WidgetItemSingleFilterPopover
                            fieldFilter={undefined}
                            fieldDefinition={undefined}
                            projectId={projectManager.project.id}
                            workflowVersion={workflowVersion}
                            groupId={workflowVersion.groupId}
                            key="advanced_field_filter"
                            label="Advanced"
                            advancedFilter={advancedFilter}
                            onChangeAdvancedFilter={onChangeAdvancedFilter}
                        />
                    </div>
                )}
                {hasAnyFilters && (
                    <ClearFiltersButtonContainer>
                        <ClearFiltersButton
                            onClick={() => clearAllFilters()}
                            data-automation="widget-items-filters-clear-filter"
                            size={ButtonSize.SMALL}
                            outlined
                        >
                            clear filters
                        </ClearFiltersButton>
                    </ClearFiltersButtonContainer>
                )}
            </FiltersContainer>
        </>
    );
};

export default WidgetItemsFilters;
