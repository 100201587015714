import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import type WsdlWithOperations from './WsdlTypes';

import type { PickerListItem, PickerListItems } from '@tonkean/infrastructure';
import { Checkbox, PickerList, StateMessage } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div`
    overflow: auto;
    flex-grow: 1;
    flex-basis: 0;
`;

const PickerListEmptyIcon = styled(Checkbox)`
    opacity: 0.3;
`;

interface Props {
    operationsList?: string[];
    setWsdlWithOperations: React.Dispatch<React.SetStateAction<WsdlWithOperations>>;
    isLoading: boolean;
}

const WsdlImportedOperationsView: React.FC<Props> = ({ operationsList, setWsdlWithOperations, isLoading }) => {
    // State for all viewed items.
    const [pickerListItems, setPickerListItems] = useState<PickerListItems<string>>({});

    // Getting a default list item by operation name.
    const getDefaultListItemByOperationName: (name: string, isChecked?: boolean) => PickerListItem<string> =
        useCallback(
            (operationName) => ({
                item: operationName,
                id: operationName,
                label: operationName,
            }),
            [],
        );

    // When the user fetch operations, we map the list of operation names to pickerListItems.
    useEffect(() => {
        if (operationsList) {
            setPickerListItems(() => {
                const pickerListItemsEntries = operationsList.map((operationName: string) => {
                    return [operationName, getDefaultListItemByOperationName(operationName)] as const;
                });

                return Object.fromEntries(pickerListItemsEntries);
            });
        } else {
            setPickerListItems({});
        }
    }, [getDefaultListItemByOperationName, operationsList]);

    // When a user checked/unchecked a list item we update parent state.
    const onCheckedListChanged: (updated: PickerListItems<string>) => void = useCallback(
        (updated: PickerListItems<string>) => {
            setPickerListItems((prevState) => ({ ...prevState, ...updated }));

            // When list item changed (checked/unchecked) we iterate every item and update parent selected operations accordingly.
            setWsdlWithOperations((prevWsdlWithOperations) => {
                const updatedSelectedOperations = Object.entries(updated).reduce((acc, [, listItem]) => {
                    if (listItem.checked && !acc.includes(listItem.item)) {
                        return [...acc, listItem.item];
                    } else if (!listItem.checked) {
                        return acc.filter((it) => it !== listItem.item);
                    }
                    return acc;
                }, prevWsdlWithOperations?.operations || []);
                return { ...prevWsdlWithOperations, operations: updatedSelectedOperations };
            });
        },
        [setWsdlWithOperations],
    );

    return (
        <Wrapper>
            <PickerList
                customEmptyMessage={
                    <StateMessage
                        icon={
                            <PickerListEmptyIcon size={InputSize.XLARGE} onChange={() => {}} checked disabled>
                                Fetch/Upload WSDL file to import actions
                            </PickerListEmptyIcon>
                        }
                    />
                }
                title="Actions"
                loading={isLoading}
                itemsById={pickerListItems}
                onCheckChanged={onCheckedListChanged}
                customPadding={28}
                titleShadow
            />
        </Wrapper>
    );
};

export default WsdlImportedOperationsView;
