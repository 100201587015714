/**
 * Get the scrollbar width.
 *
 * Using the answer from here https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
 */
function getScrollbarWidth(): number {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style['msOverflowStyle'] = 'scrollbar';
    document.body.append(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.append(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
}

export default getScrollbarWidth;
