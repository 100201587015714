import ModuleNavigation from './ModuleNavigation';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkModuleNavigation',
        reactToAngular(ModuleNavigation, [
            'currentEnvironment',
            'onEnvironmentChange',
            'environmentIsActive',
            'workflowVersionId',
            'onLiveReportClick',
            'isLiveReportOn',
            'group',
            'onTestWorkflowClicked',
            'successfulValidation',
            'showButtonBackToMarkForPublish',
            'onSuccessfulPublish',
            'onRevertWorkflowVersionClicked',
            'onReadyToPublishWorkflowClicked',
            'onDiscardPendingChangesModalOpened',
        ]),
    );
