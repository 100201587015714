import ItemDetailsModuleEditorSidePane from './ItemDetailsModuleEditorSidePane';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkItemDetailsModuleEditorSidePane',
        reactToAngular(ItemDetailsModuleEditorSidePane, [
            'workflowVersion',
            'workflowFolderProjectIntegrationsAccess',
            'requestLogicsValidation',
            'invalidLogics',
        ]),
    );
