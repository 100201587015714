import ItemDetailsIcon from './ItemDetailsIcon';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkItemDetailsIcon',
        reactToAngular(ItemDetailsIcon, ['workflowVersionId', 'targetId', 'sizeModifier', 'iconType']),
    );
