import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function OktaCustomActionsDefinitionCtrl(
    $scope,
    integrationsConsts,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,

        actionsByIntegrationsMap: integrationsConsts.getActionsByIntegrationsMap(),
    };

    ctrl.$onInit = function () {
        initializeEditMode();
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onGroupIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.groupIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        if (ctrl.onDefinitionChanged) {
            ctrl.onDefinitionChanged({ shouldSaveLogic });
        }
    };

    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (customTriggerAction) {
            // Placeholder for edit mode.
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('OktaCustomActionsDefinitionCtrl', lateConstructController(OktaCustomActionsDefinitionCtrl));
