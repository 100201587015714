import * as React from 'react';
import styled from 'styled-components';

import DocumentPreviewBlock from './DocumentPreviewBlock';
import DocumentPreviewParagraph from './DocumentPreviewParagraph';
import getPageHeight from '../utils/getPageHeight';
import getPageWidth from '../utils/getPageWidth';

import { OcrPositionedElementType } from '@tonkean/tonkean-entities';
import type { OcrPositionedElement } from '@tonkean/tonkean-entities';
import type { OcrPage } from '@tonkean/tonkean-entities';
import type { OcrBlock } from '@tonkean/tonkean-entities';
import type { OcrParagraph } from '@tonkean/tonkean-entities';
import type { OcrWord } from '@tonkean/tonkean-entities';
import type { NormalizedVertex } from '@tonkean/tonkean-entities';

const ElementWrapper = styled.div`
    position: absolute;
    border-radius: 4px;

    &:hover {
        background-color: rgba(57, 179, 214, 0.3);
    }
`;

interface Props {
    page: OcrPage;
    displayedElement: OcrPositionedElement;
}

const DocumentPreviewPositionedElement: React.FC<Props> = ({ page, displayedElement }) => {
    const topLeftCorner: NormalizedVertex = displayedElement.boundingBox.normalizedVertices[0];
    const topRightCorner: NormalizedVertex = displayedElement.boundingBox.normalizedVertices[1];
    const bottomLeftCorner: NormalizedVertex = displayedElement.boundingBox.normalizedVertices[3];

    return (
        <ElementWrapper
            style={{
                top: topLeftCorner.y * getPageHeight(page),
                left: topLeftCorner.x * getPageWidth(page),
                width: topRightCorner.x * getPageWidth(page) - topLeftCorner.x * getPageWidth(page),
                height: bottomLeftCorner.y * getPageHeight(page) - topLeftCorner.y * getPageHeight(page),
            }}
        >
            {displayedElement.ocrPositionedElementType === OcrPositionedElementType.BLOCK && (
                <DocumentPreviewBlock ocrBlock={displayedElement as OcrBlock} />
            )}

            {displayedElement.ocrPositionedElementType === OcrPositionedElementType.PARAGRAPH && (
                <DocumentPreviewParagraph ocrParagraph={displayedElement as OcrParagraph} />
            )}

            {displayedElement.ocrPositionedElementType === OcrPositionedElementType.WORD &&
                (displayedElement as OcrWord).word}
        </ElementWrapper>
    );
};

export default DocumentPreviewPositionedElement;
