import React from 'react';
import styled from 'styled-components';

import { useSolutionSiteContext } from '../../../hooks';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import type { SolutionSitePageIncludeInterfaceWidgetConfiguration } from '../SolutionSitePageIncludeInterfaceWidgetConfiguration';

import {
    ContractGroupSelector,
    ContractSelector,
    useGetContractGroupSelector,
    useGetContractFieldsSelector,
    ContractFieldSelector,
} from '@tonkean/contracts';
import { Field, useFormikField } from '@tonkean/infrastructure';
import { useFetchProjectManager, useFetchWorkflowFolderManager } from '@tonkean/shared-services';
import { useTDLContractsByProjectId } from '@tonkean/tonkean-data-layer';
import { ContractFieldType, type TonkeanId, type TonkeanType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 20px;
`;

const SolutionSitePageIncludeInterfaceWidgetEditor: React.FC<
    ItemWidgetEditorProps<SolutionSitePageIncludeInterfaceWidgetConfiguration>
> = () => {
    const { value: contractId } = useFormikField<TonkeanId<TonkeanType.CONTRACT> | undefined>(
        'configuration.matchingContractId',
    );

    const { solutionSite, workflowVersionType } = useSolutionSiteContext();

    const { isLoading: isLoadingContracts, data: contractsSummary } = useTDLContractsByProjectId(
        solutionSite.projectId,
    );

    const { getWorkflowFolder } = useFetchWorkflowFolderManager();
    const { getGroupsMap } = useFetchProjectManager();

    const groupIds = getWorkflowFolder(solutionSite.projectId, solutionSite.workflowFolderId)?.groupIds || EMPTY_ARRAY;

    const groupsSummary = useGetContractGroupSelector(getGroupsMap(groupIds), contractId, workflowVersionType);

    const { data: interfaceContractFields, loading: interfaceContractFieldsLoading } = useGetContractFieldsSelector(
        solutionSite.projectId,
        contractId,
        ContractFieldType.ITEM_INTERFACE,
    );

    return (
        <Wrapper>
            <Field label="Select a Contract" light>
                <ContractSelector
                    name="configuration.matchingContractId"
                    loading={isLoadingContracts}
                    contracts={contractsSummary?.entities || EMPTY_ARRAY}
                />
            </Field>
            <Field label="Select a Group" light>
                <ContractGroupSelector
                    name="configuration.matchingGroupId"
                    loading={false}
                    groups={groupsSummary}
                    disabled={!contractId}
                />
            </Field>
            <Field label="Select an Interface" light>
                <ContractFieldSelector
                    name="configuration.matchingContractFieldId"
                    options={interfaceContractFields}
                    loading={interfaceContractFieldsLoading}
                    contractFieldType={ContractFieldType.ITEM_INTERFACE}
                    disabled={!contractId}
                    isClearable
                />
            </Field>
        </Wrapper>
    );
};

export default SolutionSitePageIncludeInterfaceWidgetEditor;
