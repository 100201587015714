import template from './tnkCheckbox.template.html?angularjs';

angular.module('tonkean.app').directive('tnkCheckbox', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            value: '=',
            label: '<',
            isReversed: '<', // Reverse the ui so the label is on the left of the checkbox
            onToggleAsync: '&', // When called the directive will NOT wait for this, just notifies the parent component.
            onToggle: '&', // When called the directive will wait for this method to return
        },
        template,
        controller: 'CheckboxCtrl',
    };
});

function CheckboxCtrl($scope, $attrs) {
    $scope.data = {
        isSaving: false,
    };

    $scope.toggle = function () {
        $scope.value = !$scope.value;

        if ($attrs.onToggleAsync) {
            $scope.onToggleAsync({ value: $scope.value });
        }

        if ($attrs.onToggle) {
            $scope.data.isSaving = true;
            $scope
                .onToggle({ value: $scope.value })
                .catch(() => {
                    // Revert to the previous state
                    $scope.value = !$scope.value;
                })
                .finally(() => {
                    $scope.data.isSaving = false;
                });
        }
    };
}

angular.module('tonkean.app').controller('CheckboxCtrl', CheckboxCtrl);
