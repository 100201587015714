import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import DocumentPreviewOutputTypeSelector from './DocumentPreviewOutputTypeSelector';
import DocumentPreviewStructured from './DocumentPreviewStructured';
import SnippetComponent from '../SnippetModule/SnippetComponent';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { OcrOutputType } from '@tonkean/tonkean-entities';
import type { TextExtractionFieldInformation } from '@tonkean/tonkean-entities';

const SnippetWrapper = styled.div`
    background: #f7f7f9;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    height: 90%;
    overflow: auto;
    padding: 5px;
`;

interface Props {
    initiativeId?: string;
    fieldDefinitionId?: string;

    textExtractionFieldInformation: TextExtractionFieldInformation;
    afterAnchorExpression?: string;
    expectedValue?: string;
}

const DocumentPreviewModalContent: React.FC<Props> = ({
    initiativeId,
    fieldDefinitionId,
    textExtractionFieldInformation,
    expectedValue,
}) => {
    const [ocrOutputType, setOcrOutputType] = useState<OcrOutputType>(OcrOutputType.TRANSCRIPT);

    const [{ data, error, loading }, getOcrOutput] = useLazyTonkeanService('getOcrOutput');

    useEffect(() => {
        if (initiativeId && fieldDefinitionId) {
            getOcrOutput(initiativeId, fieldDefinitionId, ocrOutputType, textExtractionFieldInformation, expectedValue);
        }
    }, [getOcrOutput, ocrOutputType, initiativeId, fieldDefinitionId, textExtractionFieldInformation, expectedValue]);

    return (
        <>
            <DocumentPreviewOutputTypeSelector
                ocrOutputType={ocrOutputType}
                onOcrOutputTypeSelected={(newOutputType) => setOcrOutputType(newOutputType)}
            />

            {loading && <div>Loading preview...</div>}

            {error && <div>Error trying to calculate preview...</div>}

            {!loading && !error && data && (
                <>
                    {ocrOutputType !== OcrOutputType.TRANSCRIPT && (
                        <DocumentPreviewStructured ocrOutput={data.ocrOutput} ocrOutputType={ocrOutputType} />
                    )}

                    {ocrOutputType === OcrOutputType.TRANSCRIPT && (
                        <SnippetWrapper>
                            <SnippetComponent snippets={data.snippets || []} />
                        </SnippetWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default DocumentPreviewModalContent;
