import React, { useContext } from 'react';

import PeopleDirectoryViewMode from '../entities/PeopleDirectoryViewMode';

import type {
    PeopleDirectory,
    PeopleDirectoryInstance,
    PeopleDirectoryInstanceGroupMessage,
    PeopleDirectoryPersonSummary,
    PeopleDirectorySendGroupMessageResult,
    PeopleDirectorySendStatus,
    PeopleDirectorySendToIndividualResult,
} from '@tonkean/tonkean-entities';
import { SendToGroupOfPeopleAs } from '@tonkean/tonkean-entities';

export interface PeopleDirectoryViewContextData {
    peopleDirectories: { [peopleDirectoryId: string]: PeopleDirectory };
    peopleDirectoryInstances: { [peopleDirectoryId: string]: PeopleDirectoryInstance };
    missingInstancesPeopleDirectoryIds: string[];
    groupMessages: { [peopleDirectoryInstanceId: string]: PeopleDirectoryInstanceGroupMessage };
    groupMessagesResults: { [peopleDirectoryId: string]: PeopleDirectorySendGroupMessageResult | undefined };
    individuals: { [peopleDirectoryId: string]: PeopleDirectoryPersonSummary[] };
    individualsSendResults: { [peopleDirectoryId: string]: PeopleDirectorySendToIndividualResult[] };
    totalIndividuals: number;
    sendToGroupOfPeopleAs: SendToGroupOfPeopleAs;
    peopleDirectorySendStatuses?: { [peopleDirectoryId: string]: PeopleDirectorySendStatus };
    peopleDirectoryFriendlyErrors?: { [peopleDirectoryId: string]: string | undefined };
    mode: PeopleDirectoryViewMode;
    showFailed?: boolean;

    toggleShowFailed?(): void;

    showSendViaPreference?: boolean;

    toggleShowSendViaPreference?(): void;

    showSendViewEmailFallback?: boolean;

    toggleShowSendViewEmailFallback?(): void;
}

const PeopleDirectoryViewContext = React.createContext<PeopleDirectoryViewContextData>({
    peopleDirectories: {},
    peopleDirectoryInstances: {},
    missingInstancesPeopleDirectoryIds: [],
    groupMessages: {},
    groupMessagesResults: {},
    individuals: {},
    individualsSendResults: {},
    totalIndividuals: 0,
    sendToGroupOfPeopleAs: SendToGroupOfPeopleAs.EACH_INDIVIDUAL,
    mode: PeopleDirectoryViewMode.PREVIEW,
    showFailed: false,
    toggleShowFailed: () => {
        throw new Error('setViewFailed function not provided');
    },
    showSendViaPreference: false,
    toggleShowSendViaPreference: () => {
        throw new Error('toggleShowSendViaPreference function not provided');
    },
    showSendViewEmailFallback: false,
    toggleShowSendViewEmailFallback: () => {
        throw new Error('setShowSendViewEmailFallback function not provided');
    },
});

export function usePeopleDirectoryView(peopleDirectoryId: string) {
    const { sendToGroupOfPeopleAs, mode, peopleDirectoryFriendlyErrors, peopleDirectorySendStatuses, ...data } =
        useContext(PeopleDirectoryViewContext);

    const peopleDirectory = data.peopleDirectories[peopleDirectoryId];
    if (!peopleDirectory) {
        throw new Error('no business groups found');
    }

    const peopleDirectoryInstance = data.peopleDirectoryInstances[peopleDirectoryId];
    const missingInstanceConfiguredForEnvironment = data.missingInstancesPeopleDirectoryIds.includes(peopleDirectoryId);
    const groupMessage = peopleDirectoryInstance ? data.groupMessages[peopleDirectoryInstance.id] : undefined;
    const groupMessagesResults = data.groupMessagesResults[peopleDirectory.id];

    const individuals = data.individuals[peopleDirectory.id];
    const individualsSendResults = data.individualsSendResults[peopleDirectory.id];

    return {
        peopleDirectory,
        peopleDirectoryInstance,
        missingInstanceConfiguredForEnvironment,
        groupMessage,
        groupMessagesResults,
        individuals,
        individualsSendResults,
        mode,
        sendToGroupOfPeopleAs,
        peopleDirectorySendStatus: peopleDirectorySendStatuses?.[peopleDirectoryId],
        peopleDirectoryFriendlyError: peopleDirectoryFriendlyErrors?.[peopleDirectoryId],
    };
}

export function usePeopleDirectoriesView() {
    return useContext(PeopleDirectoryViewContext);
}

export default PeopleDirectoryViewContext;
