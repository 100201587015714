<div>
    <!-- Order by -->
    <div class="flex-vmiddle margin-top">
        <!-- Order by title -->
        <div class="margin-right">Order by:</div>

        <!-- Order by field selection (Datasource) -->
        <tnk-field-selector
            ng-if="!data.orderByTonkeanField"
            class="common-width-50"
            selected-field="data.orderByField"
            items-source="EXTERNAL"
            project-integration="data.projectIntegration"
            external-type="data.entityMetadata.pluralLabel || data.entityMetadata"
            on-field-selected="onOrderByFieldSelected(selectedField)"
            place-holder="Select a data source field"
            field-inspect-modal="true"
            allow-clear-selection="true"
            field-inspect-auto-open="true"
            workflow-version-id="{{ data.workflowVersionId }}"
            view-only="!data.projectIntegration.valid"
            tooltip-value="data.projectIntegration.valid ? undefined :'To select a field, you must first select a Data Source connection'"
        ></tnk-field-selector>

        <!-- Order by field selection (Tonkean Field) -->
        <tnk-field-selector
            ng-if="data.orderByTonkeanField"
            allow-clear-selection="true"
            class="common-width-50"
            items-source="COLUMN"
            ng-if="data.orderByTonkeanField"
            on-field-selected="onOrderByFieldSelected(selectedField, true)"
            place-holder="Select an order by field"
            selected-field="data.orderByField"
            workflow-version-id="{{ data.targetWorkflowVersionId }}"
        ></tnk-field-selector>

        <span>
            <i
                class="fa fa-info-circle margin-left-xs common-color-grey"
                uib-tooltip="If multiple items matched the condition, order by provided field and take the first. If no field provided, will take one arbitrarily."
            ></i>
        </span>
    </div>

    <!-- Order by order -->
    <div class="flex-vmiddle margin-top">
        <!-- Order by order title -->
        <div class="margin-right">Order:</div>

        <!-- Order by order selection -->
        <ui-select
            class="margin-right"
            ng-model="data.orderBySelectedOrder"
            ng-change="onOrderBySelectedOrderSelected(data.orderBySelectedOrder)"
            theme="bootstrap"
        >
            <ui-select-match placeholder="Order type...">
                {{ data.orderBySelectedOrder.displayName }}
            </ui-select-match>
            <ui-select-choices repeat="orderType in data.orderTypes | filter:$select.search ">
                <div ng-bind-html="orderType.displayName | escapeHtml | highlight: $select.search"></div>
            </ui-select-choices>
        </ui-select>
    </div>

    <!-- Order by field type -->
    <div class="flex-vmiddle margin-top">
        <!-- Order by field type title -->
        <div class="margin-right">Order field type:</div>

        <!-- Order by field type selection -->
        <ui-select
            class="margin-right"
            ng-model="data.selectedOrderByFieldType"
            ng-change="onOrderByFieldTypeSelectedOrderSelected(data.selectedOrderByFieldType)"
            theme="bootstrap"
        >
            <ui-select-match placeholder="Order type...">
                {{ data.selectedOrderByFieldType.displayName }}
            </ui-select-match>
            <ui-select-choices repeat="orderByFieldType in data.orderByFieldTypes | filter:$select.search ">
                <div ng-bind-html="orderByFieldType.displayName | escapeHtml | highlight: $select.search"></div>
            </ui-select-choices>
        </ui-select>
    </div>
</div>
