<div class="formula-field-definition" ng-if="data.formulaType === 'EXPRESSION'">
    <div class="common-size-xxs margin-bottom-lg">
        <div class="flex margin-bottom-xs">
            <!-- Example Item Selector -->
            <span ng-if="data.groupId" class="common-size-xxs flex-grow show-edit-when-hover">
                <i
                    class="fa fa-flask common-color-grey"
                    tooltip-placement="bottom-left"
                    uib-tooltip="Select example item to see what values will be sent."
                ></i>
                <span class="common-bold">Specific item to preview:</span>
                <i>{{ data.selectedExampleInitiative.title || 'None' }}</i>

                <a ng-click="data.changeExampleItem = !data.changeExampleItem" class="edit-button common-size-xxxxs">
                    <i class="fa fa-pencil"></i>
                </a>
            </span>

            <!-- Documentation link -->
            <div class="flex-grow margin-right-xs text-right flex-no-shrink">
                <a ng-href="{{::$root.knowledgeBase.pages.formulas}}" target="_blank">
                    See Documentation
                    <i class="fa fa-question-circle"></i>
                </a>
            </div>

            <!-- Menu -->
            <div class="margin-left">
                <div uib-dropdown>
                    <i
                        class="btn padding-normal-h btn-no-border svg-icon-md"
                        uib-dropdown-toggle
                        data-automation="column-formula-field-right-dropdown-button"
                    >
                        <tnk-icon src="../../../../../apps/tracks/images/icons/ellipsis-v.svg"></tnk-icon>
                    </i>

                    <ul uib-dropdown-menu class="dropdown-menu-right">
                        <!-- Toggle view -->
                        <li class="tnk-tooltip mod-top">
                            <span
                                ng-if="
                                    !data.validationResult.validFormulaExpression && !!data.evaluatedFormulaExpression
                                "
                                class="tnk-tooltip-text"
                            >
                                Disabled due to an invalid formula
                            </span>
                            <button
                                class="dropdown-menu-button"
                                data-automation="column-formula-field-show-advanced-view-button"
                                ng-class="{
                                    'common-disabled':
                                        !data.validationResult.validFormulaExpression &&
                                        !!data.evaluatedFormulaExpression
                                }"
                                type="button"
                                ng-disabled="!data.validationResult.validFormulaExpression && !!data.evaluatedFormulaExpression"
                                ng-click="data.treeViewMod = !data.treeViewMod"
                            >
                                Show {{ data.treeViewMod ? 'Advanced' : 'Builder' }} View
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Example initiative selection -->
        <div ng-if="data.changeExampleItem">
            <tnk-search-initiatives
                group-id="data.groupId"
                selected-initiative-id="ctm.workflowVersionIdToExampleItemsMap[data.workflowVersionId].root"
                can-clear="false"
                on-initiative-selected="onInitiativeSelected(selectedSimplifiedInitiative)"
                should-set-default-initiative="true"
                default-initiatives-amount="20"
                only-roots="false"
                only-draft-initiatives="data.workflowVersionType === 'DRAFT'"
            ></tnk-search-initiatives>
            <!-- Error state -->
            <div class="common-color-danger common-size-xxxxs margin-right" ng-if="data.exampleItemError">
                {{ data.exampleItemError }}
            </div>
        </div>
    </div>

    <div class="common-size-xxs margin-bottom">
        <!-- Formula builder -->
        <tnk-formula-expression-editor
            target-type="{{ data.targetType }}"
            field-definition-type="TNK_COLUMN_FORMULA"
            variable-entity-name="Field"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            column-formula-feature-name="{{ data.columnFormulaFeatureName }}"
            additional-tabs="data.additionalTabs"
            custom-trigger="data.customTrigger"
            project-integration="data.projectIntegration"
            formula-expression="data.originalFormulaExpression"
            evaluated-formula-expression="data.evaluatedFormulaExpression"
            formula-expression-tree="data.formulaExpressionTree"
            example-initiative="data.selectedExampleInitiative"
            tree-view-mod="data.treeViewMod"
            on-formula-expression-changed="
                onFormulaExpressionChanged(
                    evaluatedFormulaExpression,
                    validationResult,
                    originalFormulaExpression,
                    variablesUsedInExpression,
                    isInit,
                    expressionNode
                )
            "
            on-formula-data-change="(onFormulaDataChange)"
            on-inner-track-aggregation-selected="
                (!data.hideInnerTrackAggregationFormula && onSelectInnerTrackAggregation)
            "
            field-definition="data.existingFieldDefinition"
        ></tnk-formula-expression-editor>
    </div>
</div>

<tnk-aggregate-on-column-field
    ng-if="data.formulaType === 'STRUCTURED'"
    project-integration="data.projectIntegration"
    group-id="data.groupId"
    workflow-version-id="{{ data.workflowVersionId }}"
    target-type="data.targetType"
    create-mode="data.createMode"
    duplicate-mode="data.duplicateMode"
    no-advance="true"
    aggregation-on-column-feature-name="{{ data.aggregationOnColumnFeatureName }}"
    column-formula-feature-name="{{ data.columnFormulaFeatureName }}"
    on-formula-selected="(onFormulaSelected)"
    existing-field-definition="data.innerTracksAggregationDefinition"
    on-definition-change="
        onInnerTracksAggregationDefinitionChanged(newDefinition, doNotReloadPreview, actualFieldDefinitionType)
    "
    should-show-checkbox-for-inner-items-hirerachy="true"
    on-change="(onChange)"
></tnk-aggregate-on-column-field>
