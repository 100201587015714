<div class="invoice-view">
    <div class="text-right">
        <button class="btn btn-primary no-print margin-top-lg" onclick="window.print()">Print</button>
        <button class="btn btn-default no-print margin-top-lg margin-right-lg" ng-click="$dismiss()">Close</button>
    </div>
    <div class="container mod-center padding-normal" style="overflow: hidden">
        <div class="flex-vmiddle margin-bottom-lg padding-bottom-lg">
            <div class="flex-basis-50 relative">
                <tnk-icon src="/images/icons/tonkean.svg"></tnk-icon>
            </div>
            <div class="flex-basis-50 text-right common-size-xxl">INVOICE</div>
        </div>
        <div class="flex-vmiddle padding-top-lg">
            <div class="flex-basis-50">
                <p>Billed to:</p>
                <h1 class="mod-no-top common-bold">{{ license.customer.billingInformation.organizationName }}</h1>
                <div ng-if="selectedInvoice.chargeId">
                    <div>Charge ID:</div>
                    <h4 class="mod-no-top common-bold">{{ selectedInvoice.chargeId }}</h4>
                </div>
            </div>
            <div class="flex-basis-50 text-right">
                <p>Date/Amount:</p>
                <h1 class="mod-no-top common-bold">{{ selectedInvoice.periodStart | date: 'MMM dd, yyyy' }}</h1>
                <h2 class="mod-no-top">
                    {{ selectedInvoice.total / 100 }} {{ selectedInvoice.currency.toUpperCase() }}
                </h2>
            </div>
        </div>
        <hr style="border-width: 5px; margin: 30px 0" />
        <div class="margin-bottom-lg padding-normal-v">
            <h3>Description</h3>
            <div class="flex margin-bottom" ng-repeat="line in selectedInvoice.lines">
                <div class="flex-basis-50">{{ line.description }}</div>
                <div>{{ line.amount / 100 }} {{ line.currency.toUpperCase() }}</div>
            </div>
            <hr class="common-width-60 margin-left-none" />
            <div ng-if="selectedInvoice.discount">
                <div class="flex margin-bottom">
                    <div class="flex-basis-100px margin-right-xs">Subtotal:</div>
                    <div>{{ selectedInvoice.subtotal / 100 }} {{ selectedInvoice.currency.toUpperCase() }}</div>
                </div>
                <div class="flex margin-bottom">
                    <div class="flex-basis-100px margin-right-xs">Discount:</div>
                    <div>
                        -{{ (selectedInvoice.subtotal - selectedInvoice.total) / 100 }}
                        {{ selectedInvoice.currency.toUpperCase() }} ({{ selectedInvoice.discount.percentOff }}%)
                    </div>
                </div>
            </div>
            <div class="flex margin-bottom">
                <div class="flex-basis-100px">
                    <span class="common-underline">Total</span>
                    :
                </div>
                <div class="margin-left-xs">
                    {{ selectedInvoice.total / 100 }} {{ selectedInvoice.currency.toUpperCase() }}
                </div>
            </div>
            <div ng-if="selectedInvoice.chargeId">
                <h3>Payment:</h3>
                <i class="loading-small" ng-show="selectedInvoice.loadingCharge"></i>
                <p ng-hide="selectedInvoice.loadingCharge">
                    Charged
                    <strong>
                        {{ selectedInvoice.charge.amount / 100 }} {{ selectedInvoice.currency.toUpperCase() }}
                    </strong>
                    to
                    <strong>{{ selectedInvoice.charge.card.brand }}</strong>
                    card ending in
                    <strong>{{ selectedInvoice.charge.card.last4 }}</strong>
                    , on
                    <strong>{{ selectedInvoice.charge.created | date: 'MMM dd, yyyy HH:mm a' }}</strong>
                    .
                </p>
            </div>
        </div>
        <hr class="margin-bottom-xxlg" />
        <div class="margin-bottom-xxlg">
            <h3>Thank You!</h3>
            <p>
                Thanks for choosing Tonkean — we're working hard to provide the best product and service we can. If you
                have questions about your bill, email support@tonkean.com
            </p>
        </div>
        <hr style="border-width: 3px" />
        <div class="flex-vmiddle margin-bottom-xxlg">
            <div class="flex-basis-50 relative">
                <h4 class="mod-no-top common-bold">Tonkean, Inc.</h4>
                <div>support@tonkean.com</div>
                <a href="https://tonkean.com" target="_blank">https://tonkean.com</a>
            </div>
            <div>
                <span
                    ng-if="selectedInvoice.chargeId && selectedInvoice.paid"
                    class="common-size-xl invoice-status mod-paid"
                >
                    PAID
                </span>
                <span
                    ng-if="!selectedInvoice.chargeId && !selectedInvoice.paid"
                    class="common-size-xl invoice-status mod-open"
                >
                    OPEN
                </span>
            </div>
            <div class="flex-basis-50 text-right">
                650 California St
                <br />
                San Francisco, CA 94108
                <br />
                USA
            </div>
        </div>
    </div>
</div>
