import React, { useCallback } from 'react';

import ProjectIntegrationEntityHandleResponseView from '../common/ProjectIntegrationEntityHandleResponseView';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import type { WebhookPayloadHandlingType } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntityResponseHandlingDefinition } from '@tonkean/tonkean-entities';

interface Props {
    entity: ProjectIntegrationEntity;
    handlingDefinition: ProjectIntegrationEntityResponseHandlingDefinition | undefined;
    webhookPayloadHandlingType: WebhookPayloadHandlingType | undefined;
    payloadId: string;
}

const ProjectIntegrationEntityHandlePayloadResponseView: React.FC<Props> = ({
    entity,
    handlingDefinition,
    payloadId,
    webhookPayloadHandlingType,
}) => {
    // handle response preview lazy call.
    const [, getExternalActivities] = useLazyTonkeanService(
        'getProjectIntegrationActionExternalActivitiesHandleResponseByPayload',
    );

    // handle response preview call.
    const {
        error: errorSummaries,
        loading: loadingSummaries,
        data: summaries,
    } = useTonkeanService(
        'getProjectIntegrationActionExternalActivitiesHandleResponseByPayload',
        entity.projectIntegrationId,
        handlingDefinition,
        entity.id,
        payloadId,
    );

    // Debounce callback on search external activity.
    const getExternalActivitiesAutoComplete = useCallback(
        (debounced: string) => {
            return getExternalActivities(
                entity.projectIntegrationId,
                handlingDefinition,
                entity.id,
                payloadId,
                debounced,
            ).then((response) => response.entities);
        },
        [entity.id, entity.projectIntegrationId, getExternalActivities, handlingDefinition, payloadId],
    );

    const viewKey =
        payloadId +
        handlingDefinition?.entity?.evaluatedExpression +
        handlingDefinition?.id?.evaluatedExpression +
        handlingDefinition?.displayName?.evaluatedExpression +
        webhookPayloadHandlingType;

    return (
        <ProjectIntegrationEntityHandleResponseView
            entity={entity}
            key={viewKey}
            totalEntities={summaries?.totalEntities}
            error={errorSummaries}
            isLoadingSummaries={loadingSummaries}
            getExternalActivitiesAutoComplete={getExternalActivitiesAutoComplete}
            isUpdatedEverySave
        />
    );
};
export default ProjectIntegrationEntityHandlePayloadResponseView;
