/**
 * Converts a string from kebab case to camel case,
 *
 * @example
 * const camelCase = toCamelCase('tnk-angularjs-component');
 * // Returns tnkAngularjsComponent
 *
 * @param str - a string in kebab-case
 * @returns the same string in camelCase
 */
export function toCamelCase(str: string): string {
    return str.replaceAll(/-[a-z]/g, (chars) => chars.toUpperCase()[1] || '');
}
