import React from 'react';

import ContractCustomTriggerSelector from './ContractCustomTriggerSelector';
import ContractFieldsMapping, { type ContractFieldsMappingProps } from './ContractFieldsMapping';

import type { CustomTrigger } from '@tonkean/tonkean-entities';

const ContractCustomTriggerMapping: React.FC<Omit<ContractFieldsMappingProps<CustomTrigger>, 'selectorComponent'>> = (
    props,
) => {
    return <ContractFieldsMapping selectorComponent={ContractCustomTriggerSelector} {...props} />;
};

export default ContractCustomTriggerMapping;
