import template from './tnkEditGroup.template.html?angularjs';

function tnkEditGroup() {
    return {
        restrict: 'E',
        scope: {
            group: '=', // The group to edit, or null for a new group.
            controlObject: '=', // The directive adds a .submit function to the control object, allowing it to be called from outside.
            isValid: '=', // This boolean is true if the edit group directive state is valid and false otherwise.
            error: '=', // An error message if exists.
            loading: '=', // This boolean is true if an operation is underway (and a loading circle should be displayed) and false otherwise.

            hideListName: '<',
            onOwnersUpdated: '&',
        },
        template,
        controller: 'EditGroupCtrl',
    };
}
export default angular.module('tonkean.app').directive('tnkEditGroup', tnkEditGroup);
