/**
 * Holds all the possible steps of the field definition configuration modal.
 */
export const FIELD_DEFINITION_CONFIGURATION_MODAL_STEPS = {
    // Step for choosing the field definition name.
    nameConfiguration: {
        id: 'nameConfiguration',
    },
    // Step for choosing the data type of the field definition - manual, integration, formula, etc.
    dataTypeConfiguration: {
        id: 'dataTypeConfiguration',
    },
    // Step for selecting the integration for the field definition.
    integrationConfiguration: {
        id: 'integrationConfiguration',
    },
    // Step for selecting the field definition specific type (for example, external or aggregate).
    fieldDefinitionTypeConfiguration: {
        id: 'fieldDefinitionTypeConfiguration',
    },
    // Step for defining the field itself.
    fieldDefinitionConfiguration: {
        id: 'fieldDefinitionConfiguration',
    },
    // Step for choosing how the field definition should be displayed.
    displayConfiguration: {
        id: 'displayConfiguration',
    },
    // Step for choosing what ranges you want to apply on the field definition.
    rangesConfiguration: {
        id: 'rangesConfiguration',
    },
};

/**
 * Returns the fieldDefinitionConfigurationModalSteps configuration variable.
 */
export function getFieldDefinitionConfigurationModalSteps() {
    return structuredClone(FIELD_DEFINITION_CONFIGURATION_MODAL_STEPS);
}
