import React from 'react';
import styled from 'styled-components';

import FailureCriteriaHandleView from './FailureCriteriaViews/FailureCriteriaHandleView';
import {
    ExtendableRowColoredText,
    ExtendableRowText,
    HeaderWrapper,
    InnerContentWrapper,
} from './HandleResponseSharedCriteriaStyle';
import sqlIntegrations from '../../../utils/SqlIntegrations';

import { ExtendableRow, GroupedExtendableRows } from '@tonkean/infrastructure';
import type {
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionModuleResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const HandleLogErrorExplainText = styled.div`
    color: ${Theme.colors.gray_600};
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    margin-bottom: 20px;
`;

interface Props {
    projectIntegrationAction: ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>;
    setProjectIntegrationAction: React.Dispatch<
        React.SetStateAction<ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>>
    >;
    projectIntegration: ProjectIntegration;
}

const FailureCriteria: React.FC<Props> = ({
    projectIntegrationAction,
    setProjectIntegrationAction,
    projectIntegration,
}) => {
    const failureCriteriaText = sqlIntegrations.includes(projectIntegration.integrationType.toLowerCase())
        ? 'SQL errors'
        : '4xx or 5xx codes';

    return (
        <GroupedExtendableRows>
            <ExtendableRow
                header={
                    <HeaderWrapper isNonExtendable>
                        <ExtendableRowColoredText color={Theme.colors.error}>
                            Failure Criteria:
                        </ExtendableRowColoredText>
                        <ExtendableRowText>{failureCriteriaText} (default)</ExtendableRowText>
                    </HeaderWrapper>
                }
            />

            <ExtendableRow
                header={
                    <HeaderWrapper>
                        <ExtendableRowColoredText color={Theme.colors.gray_700}>Handle: </ExtendableRowColoredText>
                        <ExtendableRowText>Log errors and abort the flow run</ExtendableRowText>
                    </HeaderWrapper>
                }
            >
                <InnerContentWrapper>
                    <HandleLogErrorExplainText>
                        You can create a rule to display response message on the history page. Otherwise a default
                        message will be logged.
                    </HandleLogErrorExplainText>

                    <FailureCriteriaHandleView
                        projectIntegrationAction={projectIntegrationAction}
                        setProjectIntegrationAction={setProjectIntegrationAction}
                    />
                </InnerContentWrapper>
            </ExtendableRow>
        </GroupedExtendableRows>
    );
};

export default FailureCriteria;
