import actionTypeToPresentTense from './ActionTypePresentTense';

import { ActionType } from '@tonkean/tonkean-entities';

const options = [
    { value: ActionType.CREATE, label: actionTypeToPresentTense[ActionType.CREATE] },
    { value: ActionType.UPDATE, label: actionTypeToPresentTense[ActionType.UPDATE] },
    { value: ActionType.CREATE_OR_UPDATE, label: actionTypeToPresentTense[ActionType.CREATE_OR_UPDATE] },
    { value: ActionType.CUSTOM, label: actionTypeToPresentTense[ActionType.CUSTOM] },
    { value: ActionType.UPLOAD, label: actionTypeToPresentTense[ActionType.UPLOAD] },
    { value: ActionType.DOWNLOAD, label: actionTypeToPresentTense[ActionType.DOWNLOAD] },
    { value: ActionType.FETCHING, label: actionTypeToPresentTense[ActionType.FETCHING] },
    { value: ActionType.STREAM_IMAGE, label: actionTypeToPresentTense[ActionType.STREAM_IMAGE] },
];

export default options;
