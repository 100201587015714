/**
 * A Controller for both GitHub.
 */

export function GitServiceConfigCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    oauthHandler,
    utils,
    integrations,
    authenticationService,
    environment,
    projectManager,
) {
    $scope.temp = {};
    $scope.enableCustomWorkaround = $scope.currentIntegration.name === 'github';
    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.repositories = state.repositories;
        $scope.selections = state.selections || [];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        updateRepoLists();
        tryLoadFromEdit();
    };

    $scope.authorize = function () {
        $scope.connecting = true;
        $scope.error = null;
        let promise;
        if ($scope.currentIntegration.name === 'github') {
            promise = oauthHandler.github(environment.integrationKeysMap.github, 'repo').then(function (code) {
                return { code };
            });
        }
        promise
            .then(function (config) {
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    config,
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'GITHUB',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                );
            })
            .then(function (data) {
                $scope.integration = data;
                return createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.integration.id,
                    'repositories',
                );
            })
            .then(function (data) {
                $scope.selections = data.options.slice(0, 3).map(function (repo) {
                    return { repo };
                });
                $scope.repositories = data.options;
                updateRepoLists();
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.connecting = false;
            });
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.repositories = null;
        $scope.authorize();
    };

    $scope.onSelectionChange = function (selection) {
        if (selection.selected) {
            if (!selection.branch) {
                if (selection.repo.defaultBranch) {
                    selection.branch = {
                        displayName: selection.repo.defaultBranch,
                        value: selection.repo.defaultBranch,
                    };
                } else {
                    $scope.getBranches(selection);
                }
            }
        } else {
            selection.showEdit = false;
        }
    };

    $scope.onBranchKeyUp = function ($event, selection) {
        // Escape key
        if ($event.keyCode === 27) {
            selection.showEdit = false;
        }
    };

    $scope.editBranch = function (selection) {
        selection.showEdit = true;
        $scope.getBranches(selection);
    };

    $scope.getBranches = function (selection) {
        if (selection.repo && !selection.branches) {
            selection.loadingBranches = true;
            return createProjectApis
                .getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.integration.id,
                    'branches',
                    selection.repo.value,
                )
                .then(function (data) {
                    const branches = (selection.branches = data.options);
                    if (branches && branches.length > 0) {
                        // Select the master branch by default.
                        selection.branch =
                            branches.find(function (b) {
                                return b.displayName === (selection.repo.defaultBranch || 'master');
                            }) || branches[0];
                    }
                })
                .finally(function () {
                    selection.loadingBranches = false;
                    // Focus on ui-select
                    $scope.$broadcast(selection.repo.displayName);
                });
        }
    };

    $scope.$watch('temp.repo', function () {
        $scope.onRepoSelected($scope.temp.repo);
    });

    $scope.onRepoSelected = function (repo) {
        if (repo && repo.displayName) {
            if (!repo.custom) {
                const selection = { repo, selected: true };
                $scope.selections.push(selection);
                updateRepoLists();
                $scope.onSelectionChange(selection);
                $scope.temp.repo = null;
            } else {
                let validatedRepo;
                $scope.validatingRepo = true;
                createProjectApis
                    .getAutoCompleteOptions(
                        projectManager.project.id,
                        $scope.integration.id,
                        'repositories',
                        repo.value,
                    )
                    .then(function (data) {
                        validatedRepo =
                            data.options &&
                            data.options[0] &&
                            data.options[0].displayName.toLowerCase() === repo.originalName
                                ? data.options[0]
                                : null;
                    })
                    .finally(function () {
                        if (validatedRepo) {
                            $scope.onRepoSelected(validatedRepo);
                        } else {
                            $scope.$emit('alert', `Repository '${repo.originalName}' was not found`);
                        }
                        $scope.validatingRepo = false;
                        $scope.temp.repo = null;
                    });
            }
        }
    };

    $scope.addCustomRepo = function (name) {
        if (!$scope.otherRepos || !$scope.enableCustomWorkaround) {
            return;
        }

        if ($scope.otherRepos[$scope.otherRepos.length - 1] && $scope.otherRepos[$scope.otherRepos.length - 1].custom) {
            $scope.otherRepos.pop();
        }

        const lowerCase = name.toLowerCase();
        const alreadyExist = $scope.repositories.some(function (r) {
            return r.displayName === lowerCase;
        });
        if (!alreadyExist) {
            const parts = name.split('/');
            if (parts.length === 2 && parts[0] && parts[1]) {
                $scope.otherRepos.push({
                    custom: true,
                    displayName: `${name} (custom)`,
                    originalName: lowerCase,
                    value: { owner: parts[0], repository: parts[1] },
                });
            }
        }
    };

    /**
     * Refresh the repositories selection for each combo-box.
     */
    function updateRepoLists() {
        if ($scope.repositories) {
            const reposInSelection = new Set(
                $scope.selections.map(function (s) {
                    return s.repo.displayName;
                }),
            );
            $scope.otherRepos = $scope.repositories.filter(function (r) {
                return !reposInSelection.has(r.displayName);
            });
        }
    }

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        const s = {
            repositories: $scope.repositories,
            selections: $scope.selections,
            integration: $scope.integration,
        };
        s.integrations = $scope.selections
            .map(function (selection) {
                if (selection.selected && selection.branch) {
                    return {
                        integration: $scope.integration,
                        projectData: {
                            owner: selection.repo.value.owner,
                            repository: selection.repo.value.repository,
                            branch: selection.branch.value,
                        },
                    };
                }
            })
            .filter(angular.identity);

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });

            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'repositories')
                .then(function (data) {
                    $scope.repositories = data.options;
                    $scope.selections = data.options
                        .map(function (repo) {
                            // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                const originalIntegration = state.integrations[0];

                                if (
                                    originalIntegration.projectData &&
                                    originalIntegration.projectData.projectDatas &&
                                    originalIntegration.projectData.projectDatas.length > 0
                                ) {
                                    for (const i in originalIntegration.projectData.projectDatas) {
                                        const projectData = originalIntegration.projectData.projectDatas[i];

                                        if (
                                            projectData.owner === repo.value.owner &&
                                            projectData.repository === repo.value.repository
                                        ) {
                                            repo.defaultBranch = projectData.branch;

                                            return {
                                                repo,
                                                selected: true,
                                                branch: {
                                                    displayName: projectData.branch,
                                                    value: projectData.branch,
                                                },
                                            };
                                        }
                                    }
                                }
                            }
                        })
                        .filter(angular.identity);
                    updateRepoLists();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.connecting = false;
                });
        }
    }
}
export default angular.module('tonkean.shared').controller('GitServiceConfigCtrl', GitServiceConfigCtrl);
