import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const NlpBranchAnyOfTheRestLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.NLP_BRANCH_ANY_OF_THE_REST,
    displayName: 'Other active models',
    title: 'Other active models',
    iconClass: 'mod-nlp-branch',
    description: 'Activated when a model without a specific branch was matched with the received text.',
    cantChangeType: () => true,
    doNotDisplaySettingsEdit: true,
    doesntHaveDefinition: true,
    cantMove: true,
    cantDrag: false,
    allowedParentTypes: [LogicBlockConfigType.NLP_PROCESSOR],
};

export default NlpBranchAnyOfTheRestLogicBlockConfig;
