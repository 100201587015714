import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';

import InnerItemsAgGridWidgetDisplay from './InnerItemsAgGridWidgetDisplay';
import InnerItemsItemWidgetDisplay from './InnerItemsItemWidgetDisplay';
import { useSolutionSiteContext } from '../../../hooks';
import useFieldsValuesUpdater from '../../../hooks/useFieldsValuesUpdater';
import type { SolutionSitePageWidgetProps } from '../../../WidgetModule';
import type SolutionSiteItemWidgetConfiguration from '../SolutionSiteItemWidgetConfiguration';

import { useFeatureFlag, useGetStateParams } from '@tonkean/angular-hooks';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import type { Group } from '@tonkean/tonkean-entities';
import { useSolutionSiteThemeConfiguration, WorkflowVersionType } from '@tonkean/tonkean-entities';

const SolutionSiteItemWidget: React.FC<SolutionSitePageWidgetProps<SolutionSiteItemWidgetConfiguration>> = ({
    widget,
    permission,
}) => {
    const projectManager = useAngularService('projectManager');
    const customFieldsManager = useAngularService('customFieldsManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const [entityVersionTypeParam] = useGetStateParams<[WorkflowVersionType]>('env');
    const { solutionSite } = useSolutionSiteContext();
    const themeConfiguration = useSolutionSiteThemeConfiguration(solutionSite, projectManager.project);
    const updateFieldsValuesManager = useFieldsValuesUpdater();
    const entityVersionType = entityVersionTypeParam || WorkflowVersionType.PUBLISHED;

    const group: Group | undefined =
        widget.configuration.groupId && projectManager.groupsMap[widget.configuration.groupId];

    const workflowVersionId =
        entityVersionType === 'DRAFT' ? group?.draftWorkflowVersionId : group?.publishedWorkflowVersionId;

    const environmentIsActive = entityVersionType === 'DRAFT' ? group?.buildEnvironmentEnabled : group?.workerEnabled;

    // Loading the fields into the cache so the inner angular components have it.
    const [, getFieldDefinitions] = useLazyAsyncMethod(customFieldsManager, 'getFieldDefinitions');

    useEffect(() => {
        if (workflowVersionId) {
            getFieldDefinitions(workflowVersionId);
        }
    }, [getFieldDefinitions, workflowVersionId]);

    // Loading workflow version to keep the cache full for the tracks editor
    const [, getWorkflowVersions] = useLazyAsyncMethod(workflowVersionManager, 'getFromServerAndCacheWorkflowVersions');
    useEffect(() => {
        if (workflowVersionId) {
            getWorkflowVersions(widget.projectId, [workflowVersionId]);
        }
    }, [getWorkflowVersions, widget.projectId, workflowVersionId]);

    const showItemsTableWithLineItems = !!useFeatureFlag('tonkean_feature_workspace_app_inner_items_ag_grid');

    return showItemsTableWithLineItems ? (
        <InnerItemsAgGridWidgetDisplay
            workflowVersionType={entityVersionType}
            workflowVersionId={workflowVersionId}
            groupId={widget.configuration.groupId}
            widget={widget}
            permission={permission}
            environmentIsActive={environmentIsActive}
            headerBackgroundColor={themeConfiguration.headerBackgroundColor}
            showModuleOffIndication
        />
    ) : (
        <InnerItemsItemWidgetDisplay
            workflowVersionType={entityVersionType}
            parentInitiative={undefined}
            workflowVersionId={workflowVersionId}
            groupId={widget.configuration.groupId}
            widget={widget}
            permission={permission}
            environmentIsActive={environmentIsActive}
            headerBackgroundColor={themeConfiguration.headerBackgroundColor}
            updateFieldsValuesManager={updateFieldsValuesManager}
            showModuleOffIndication
        />
    );
};

export default SolutionSiteItemWidget;
