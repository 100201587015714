import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function OutlineFilterCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        filterMenuOpened: false,
        showDependenciesDropdown: false,
        selectedFieldDefinitionForDependencies: ctrl.selectedFieldDefinitionForDependencies,
        fieldFilterText: '',
        fieldDefinitionForDependenciesOptions: ctrl.fieldDefinitionForDependenciesOptions,
        entityName: ctrl.entityName,
    };

    ctrl.$onInit = function () {};

    ctrl.$onChanges = function (changeObj) {
        if (changeObj.fieldDefinitionForDependenciesOptions) {
            $scope.data.fieldDefinitionForDependenciesOptions = ctrl.fieldDefinitionForDependenciesOptions;
        }

        if (changeObj.selectedFieldDefinitionForDependencies) {
            $scope.data.selectedFieldDefinitionForDependencies = ctrl.selectedFieldDefinitionForDependencies;

            // If the parent component added field for dependencies, open the filter bar.
            if (ctrl.selectedFieldDefinitionForDependencies) {
                $scope.data.filterMenuOpened = true;
            }
        }
    };

    $scope.toggleFieldsFilterDefinitionBarOpened = function () {
        $scope.data.filterMenuOpened = !$scope.data.filterMenuOpened;
    };

    $scope.dependenciesClicked = function () {
        $scope.data.showDependenciesDropdown = !$scope.data.showDependenciesDropdown;
    };

    $scope.applyFilters = function () {
        ctrl.applyFilters({
            fieldTextFilter: $scope.data.fieldFilterText,
            fieldForDependencies: $scope.data.selectedFieldDefinitionForDependencies,
        });
    };

    $scope.clearSelectedFieldDependency = function () {
        $scope.data.selectedFieldDefinitionForDependencies = null;
        $scope.data.showDependenciesDropdown = false;
        $scope.applyFilters();
    };

    $scope.clearFilters = function () {
        $scope.data.selectedFieldDefinitionForDependencies = null;
        $scope.applyFilters();
    };

    $scope.selectField = function () {
        $scope.data.showDependenciesDropdown = false;
        $scope.applyFilters();
    };
}

export default angular.module('tonkean.app').controller('OutlineFilterCtrl', lateConstructController(OutlineFilterCtrl));
