export function getProjectIdPath(projectId: string) {
    return `project/${projectId}`;
}

export function getInitiativeIdPath(initiativeId: string) {
    return `initiative/${initiativeId}`;
}

export function getWorkflowVersionIdPath(workflowVersionId: string) {
    return `workflowVersion/${workflowVersionId}`;
}

export function getFieldDefinitionIdPath(fieldDefinitionId: string) {
    return `fieldDefinition/${fieldDefinitionId}`;
}

export function getCustomTriggerIdPath(customTriggerId: string) {
    return `customTrigger/${customTriggerId}`;
}

export function getFormIdPath(formId: string) {
    return `form/${formId}`;
}

export function getItemInterfaceIdPath(itemInterfaceId: string) {
    return `itemInterface/${itemInterfaceId}`;
}
