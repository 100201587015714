function TagsInputCtrl($scope, tonkeanService, utils) {
    this.$onInit = function () {
        if (!$scope.tags) {
            $scope.tags = [];
        }
    };

    $scope.onCreateTag = function (str) {
        return { name: limitNewTag(str), isNew: true };
    };

    $scope.onRefreshTags = function (tag) {
        if (tag || $scope.loadOnEmpty) {
            if ($scope.maxTags > 0 && $scope.tags.length >= $scope.maxTags) {
                return [];
            }
            $scope.loadingTags = true;
            $scope.emptyError = null;
            return tonkeanService
                .searchTags($scope.$root.pm.project.id, tag, $scope.isFunc)
                .then(function (data) {
                    const dataTags = $scope.isFunc ? data.entities : data.tags;

                    let tagIncluded;
                    if (!utils.isNullOrEmpty(tag)) {
                        const lowerTag = tag.toLowerCase();
                        tagIncluded = dataTags.some(function (t) {
                            return t.name.toLowerCase() === lowerTag;
                        });
                    } else {
                        // If the tag is an empty string, it is as if it's included.
                        tagIncluded = true;
                    }

                    return tagIncluded ? dataTags : [...dataTags, { name: tag, isNew: true, id: tag }];
                })
                .finally(function () {
                    $scope.loadingTags = false;
                });
        }
    };

    $scope.onTagAddedFunc = function (tag) {
        if ($scope.onTagAdded) {
            $scope.onTagAdded({ tag });
        }
    };

    $scope.onTagRemovedFunc = function ($tag) {
        if ($scope.onTagRemoved) {
            $scope.onTagRemoved({ tag: $tag });
        }
    };

    function limitNewTag(str) {
        return str ? str.slice(0, 50) : null;
    }
}

angular.module('tonkean.app').controller('TagsInputCtrl', TagsInputCtrl);
