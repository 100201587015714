import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import useEditableText from './useEditableText';
import { ReactComponent as PencilNewIcon } from '../../../../images/icons/pencil-new.svg';

import { TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure';
import { useInnerField } from '@tonkean/infrastructure';
import { Textarea } from '@tonkean/infrastructure';
import { useResizeObserver } from '@tonkean/infrastructure';
import { useOnClickOutside } from '@tonkean/infrastructure';
import { DisableableButton } from '@tonkean/tui-buttons/Button';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const theme = Theme.colors;

type WrapperProps = {
    isInErrorMode: boolean;
    isInEditMode: boolean;
    showUnderline: boolean;
};

const Wrapper = styled.div<WrapperProps>`
    display: flex;
    align-items: center;
    transition: border-color 0.2s ease-in-out;
    position: relative;

    ${({ isInEditMode, isInErrorMode, showUnderline }) => {
        return isInEditMode && showUnderline
            ? css`
                  border-bottom: 1px solid ${isInErrorMode ? theme.error : theme.gray_400};
                  justify-content: space-between;
              `
            : css`
                  border-bottom: 1px solid transparent;
              `;
    }}
`;

const InputIcon = styled(DisableableButton)<{ $beforeWidth: number; $beforeHeight: number; $show: boolean }>`
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 10px;

    &:not(:focus-visible) {
        ${({ $show }) =>
            !$show &&
            css`
                opacity: 0;
            `}
    }

    svg {
        display: block;
        height: 15px;
        width: 15px;
    }

    :before {
        content: '';
        position: absolute;
        top: 0;

        // adding 10px to account for the margin
        width: ${({ $beforeWidth }) => `${$beforeWidth + 10}px`};
        height: ${({ $beforeHeight }) => `${$beforeHeight ?? 15}px`};
        transform: translateX(calc(-100%));
    }
`;

const ViewableText = styled.div<{
    $isEditable: boolean;
    $isPlaceholder: boolean;
    $fontSize: string;
    $isTextarea: boolean;
}>`
    font-size: ${({ $fontSize }) => $fontSize};

    // So that the transition between text and textarea will look good
    ${({ $isTextarea }) =>
        $isTextarea
            ? css`
                  white-space: pre-wrap;
                  padding: 6px 8px 6px;
                  line-height: 14px;
                  border: 1px solid transparent;
              `
            : css`
                  line-height: 25px;
              `}
    min-width: 50px;
    min-height: 25px;
    color: ${({ $isPlaceholder }) => ($isPlaceholder ? Theme.colors.gray_500 : 'inherit')};
`;

const StyledInput = styled.input<{ $fontSize: string }>`
    padding: 0;
    margin: 0;
    line-height: 25px;
    box-shadow: none;
    border: none;
    width: 100%;
    font-size: ${({ $fontSize }) => $fontSize};
`;

const Error = styled.span`
    font-size: ${FontSize.XSMALL_10};
    color: ${theme.error};
    padding-left: 6px;
    margin-right: 5px;
    white-space: pre;
`;

const MetaWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TooltipContentWrapper = styled.div<{ isInEditMode: boolean }>`
    display: flex;
    align-items: center;

    ${({ isInEditMode }) =>
        isInEditMode &&
        css`
            width: 100%;
        `}
`;

export type ResponseOfIsValueValid = ResponseOfValueValid | ResponseOfInvalidValue;

type ResponseOfValueValid = {
    isValid: true;
};

type ResponseOfInvalidValue = {
    isValid: false;
    errorReason: string;
};

export interface EditableTextProps extends StyledComponentsSupportProps {
    name?: string;
    value?: string;
    onChange?: (value: string) => void;
    onSave?: (value: string) => void;
    onBlur?: () => void;
    fontSize?: string;
    placeholder?: string;
    isValueValid?: (value: string) => ResponseOfIsValueValid | true;
    isEditable?: boolean;
    /** If passed the isInEdit state is controlled completely from the outside */
    isInEdit?: boolean;
    notEditableTooltip?: string;
    maxLength?: number;
    showUnderline?: boolean;
    initialEditing?: boolean;
    minRows?: number;
    maxRows?: number;
    autoHidePencil?: boolean;
}

const EditableText: React.FC<EditableTextProps> = ({
    name,
    value: valueProp,
    onChange,
    onSave,
    onBlur,
    isValueValid,
    isEditable = true,
    isInEdit,
    notEditableTooltip,
    className,
    placeholder,
    maxLength,
    fontSize = FontSize.MEDIUM_14,
    showUnderline = false,
    initialEditing = false,
    minRows,
    maxRows,
    autoHidePencil = false,
}) => {
    const isTextarea = !!minRows;
    const [currentValue, setCurrentValue] = useState(valueProp);
    const [initialName, setInitialName] = useState(valueProp);
    const [validationError, setValidationError] = useState<string>();

    const [props, , fieldHelpers, fieldMeta] = useInnerField({
        name,
        value: currentValue,
        type: 'text',
        multiple: false,
    });

    const [internalIsEditing, setInternalIsEditing] = useState<boolean>(initialEditing);
    const isEditing = (isInEdit !== undefined ? isInEdit : internalIsEditing) && isEditable;

    const [wasInEdit, setWasInEdit] = useState(isInEdit);
    useEffect(() => {
        if (isInEdit === false && wasInEdit) {
            setWasInEdit(false);
            onSave?.(props.value);
        }
        if (isInEdit) {
            setWasInEdit(true);
        }
    }, [isInEdit, currentValue, wasInEdit, onSave, props.value]);
    const toggleEditing = (event: React.MouseEvent<HTMLElement>) => {
        if (!isEditable) {
            return;
        }
        if (isInEdit !== undefined) {
            // edit state is controlled from the outside
            return;
        }

        if (isEditing) {
            onStopEditing();
        } else {
            setInternalIsEditing(true);
            setInitialName(props.value);
        }

        event.stopPropagation();
    };

    // When stop and save
    const onStopEditing = useCallback(() => {
        setInternalIsEditing(false);

        buttonRef?.current?.focus();
        fieldHelpers?.setTouched(true, true);

        if (initialName !== props.value && !validationError) {
            setInitialName(props.value);
            onChange?.(props.value);
        }
    }, [fieldHelpers, initialName, onChange, props.value, validationError]);

    // Stop and reset
    const onCancelEditing = () => {
        setInternalIsEditing(false);
        fieldHelpers?.setValue(initialName);
        setCurrentValue(initialName);
    };

    const editingBoxRef = useEditableText((shouldSave) => {
        if (shouldSave) {
            onStopEditing();
        } else {
            onCancelEditing();
        }
    }, isEditing);

    const onDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        fieldHelpers?.setValue(event.target.value);
        setCurrentValue(event.target.value);

        const isValidResponse = isValueValid?.(event.target.value) || { isValid: true };
        const errorMessage =
            isValidResponse !== true && !isValidResponse.isValid ? isValidResponse.errorReason : undefined;

        fieldHelpers?.setError(errorMessage);
        setValidationError(errorMessage);
    };

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
    const inputMultipleRefCallback = useMultipleRefCallback(inputRef, editingBoxRef);
    const buttonRef = useRef<HTMLDivElement>(null);

    const [isHovering, setIsHovering] = useState(false);
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const [wrapperHeight, setWrapperHeight] = useState(0);
    const { setNode } = useResizeObserver((entry: ResizeObserverEntry) => {
        setWrapperWidth((entry.target as HTMLDivElement).offsetWidth);
        setWrapperHeight((entry.target as HTMLDivElement).offsetHeight);
    });

    const wrapperRef = useOnClickOutside(onStopEditing, isEditing);

    const onWrapperKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!isEditing) {
            return;
        }

        switch (event.key) {
            case ' ':
                event.preventDefault();
                break;
        }
    };

    /**
     * Focus on the input after user starts editing.
     */
    useEffect(() => {
        if (isEditing) {
            inputRef?.current?.focus();
        }
    }, [isEditing]);

    /* Store form display name in state when it changes */
    useEffect(() => {
        setInitialName(valueProp);
        setCurrentValue(valueProp);
        setValidationError(undefined);
    }, [valueProp]);

    const errorMessage = validationError || (fieldMeta?.touched && fieldMeta.error);

    const inputSharedProps = {
        'data-automation': 'editable-title-display-name-input',
        'data-automation-value': props.value,
        ref: inputMultipleRefCallback,
        value: props.value,
        onChange: onDisplayNameChange,
        name: props.name,
        id: props.id,
        autoFocus: true,
        onBlur,
        onClick,
        maxLength,
        placeholder,
        fontSize,
    };

    return (
        <Wrapper
            isInErrorMode={!!errorMessage}
            isInEditMode={isEditing}
            showUnderline={showUnderline}
            className={className}
            ref={wrapperRef}
        >
            <Tooltip content={notEditableTooltip} disabled={isEditable || !notEditableTooltip}>
                <TooltipContentWrapper onKeyUp={onWrapperKeyUp} isInEditMode={isEditing}>
                    {isEditing ? (
                        isTextarea ? (
                            <Textarea minRows={minRows} maxRows={maxRows} {...(inputSharedProps as any)} />
                        ) : (
                            <StyledInput {...(inputSharedProps as any)} />
                        )
                    ) : (
                        <ViewableText
                            data-automation="editable-title-display-name-trigger"
                            $isEditable={isInEdit === undefined ? isEditable : false}
                            $isPlaceholder={props.value === ''}
                            $isTextarea={isTextarea}
                            $fontSize={fontSize}
                            ref={setNode}
                        >
                            <TextEllipsis numberOfLines={maxRows ?? 1} tooltip={!isTextarea}>
                                {props.value === '' ? placeholder : props.value}
                            </TextEllipsis>
                        </ViewableText>
                    )}
                </TooltipContentWrapper>
            </Tooltip>
            {isEditable && isInEdit === undefined && (
                <MetaWrapper>
                    {errorMessage && <Error data-automation="editable-title-error-message">{errorMessage}</Error>}
                    <InputIcon
                        type="button"
                        className="svg-icon-smd"
                        onClick={toggleEditing}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        ref={buttonRef}
                        $beforeWidth={isEditing ? 0 : wrapperWidth}
                        $beforeHeight={wrapperHeight}
                        aria-label="edit"
                        $show={autoHidePencil ? isHovering : true}
                        buttonAsDiv
                    >
                        <PencilNewIcon />
                    </InputIcon>
                </MetaWrapper>
            )}
        </Wrapper>
    );
};

export default EditableText;
