import type CreateAfterOption from '../../../entities/CreateAfterOption';
import { RunOnTypes } from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import logicConfigModes from '../../../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../../../entities/TriggerParamsEnricher';

import { CustomTriggerType, MonitorTracksSecondaryType } from '@tonkean/tonkean-entities';

const addItemIsAddedCreateAfterOptionCanCreate: CreateAfterOptionCanCreate = (
    childImpacts,
    configuredLogic,
    logicBlockConfigsFormInfoRetriever,
    customTriggerManager,
) => {
    const directParentInWorkflowVersion = customTriggerManager.getDirectParentInWorkflowVersion(
        configuredLogic.node.workflowVersion.id,
        configuredLogic.node.id,
    );
    const formId = directParentInWorkflowVersion.node?.customTriggerActions?.[0]?.customTriggerActionDefinition.formId;

    if (formId) {
        const form = logicBlockConfigsFormInfoRetriever.getWorkerFormFromCache(
            configuredLogic.node.workflowVersion.id,
            formId,
        );

        return form?.formQuestionType === 'COLLECT_INNER_ITEMS';
    }

    return true;
};

const itemIsAddedCustomTriggerParamsEnricher: TriggerParamsEnricher = (params) => {
    params.customTriggerType = CustomTriggerType.MONITOR_TRACKS;
    params.displayName = addItemIsAddedCreateAfterOption.blockDisplayName;
    params.selectWithConfigMode = logicConfigModes.configuration;
    params.customTriggerSecondaryType = MonitorTracksSecondaryType.MONITOR_TRACKS_ITEM_CREATED;

    params.customTriggerActions = [
        {
            type: CustomTriggerType.MONITOR_TRACKS,
            customTriggerActionDefinition: {
                monitorActionItemQuantifierType: 'ANY_ACTION_ITEMS',
                monitorContextType: 'ACTION_ITEM',
                monitorItemsConditionType: 'IMMEDIATELY',
                workerItemContextType: 'TRIGGERED_ACTION_ITEM',
                parentCustomTriggerId: params?.parentNode?.node?.id,
            },
        },
    ];
};

const addItemIsAddedCreateAfterOption: CreateAfterOption = {
    label: 'Inner Item',
    runOnType: RunOnTypes.INNER_ITEM,
    blockDisplayName: 'Inner Item is added',
    triggerParamsEnricher: itemIsAddedCustomTriggerParamsEnricher,
    canCreate: addItemIsAddedCreateAfterOptionCanCreate,
};

export default addItemIsAddedCreateAfterOption;
