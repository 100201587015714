import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LockIcon } from '../../../../../../../images/icons/lock.svg';

import { Theme } from '@tonkean/tui-theme';

const EncryptedIcon = styled(LockIcon)`
    margin-right: 4px;
`;
const EncryptedContent = styled.span`
    display: flex;
    align-items: baseline;
    color: ${Theme.colors.gray_800};
    opacity: 0.4;
`;
interface Props {
    value: React.ReactNode;
}

const ViewDataBaseCellRenderer: React.FC<Props> = ({ value }) => {
    let formattedValue = value;
    if (Array.isArray(value)) {
        formattedValue = value.join(', ');
    }

    return typeof formattedValue === 'string' && formattedValue?.includes('ENCRYPTED_') ? (
        <EncryptedContent className="pointer-events-none user-select-none">
            <EncryptedIcon />
            Encrypted Content
        </EncryptedContent>
    ) : (
        <>{formattedValue}</>
    );
};

export default ViewDataBaseCellRenderer;
