import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';

import type { Account, DocuSignProjectIntegrationData } from './DocuSignProjectIntegrationData';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { TnkSelect } from '@tonkean/infrastructure';

const DocuSignSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    createProjectApis,
    onChangeOrInitIntegration,
    projectIntegration,
    projectIntegrationData,
}) => {
    const projectManager = useAngularService('projectManager');

    const [accountsList, setAccountsList] = useState<Account[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(undefined);

    // Loading Accounts.
    useEffect(() => {
        const init = async () => {
            // Fetching all of the available accounts.
            const accountsList = await createProjectApis.getAutoCompleteOptions(
                projectManager.project.id,
                integration.id,
                'accounts',
                {},
            );
            // Constructs the results to fits the dropdown model.
            const constructedAccounts: Account[] = constructAutoCompleteOptions(accountsList.options);
            if (accountsList.options) {
                setAccountsList(constructedAccounts);
            }

            // Edit mode.
            if (projectIntegration) {
                // Getting project data.
                const projectData = projectIntegration?.projectData?.projectDatas[0];
                if (projectData?.selectedAccount) {
                    setSelectedAccount(projectData.selectedAccount);
                }
            }
        };
        init();
    }, [createProjectApis, integration.id, projectIntegration, projectManager.project.id]);

    const onAccountSelected = useCallback(
        async (account) => {
            setSelectedAccount(account);
            const projectData = {
                selectedAccount: account,
            };
            // Fetching all of the available accounts.
            const accountsList = await createProjectApis.getAutoCompleteOptions(
                projectManager.project.id,
                integration.id,
                'accounts',
                projectData,
            );
            // Constructs the results to fits the dropdown model.
            const constructedAccounts: Account[] = constructAutoCompleteOptions(accountsList.options);
            if (accountsList.options) {
                setAccountsList(constructedAccounts);
            }
        },
        [createProjectApis, integration.id, projectManager.project.id],
    );

    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: `${option.displayName} - ${option.description}`,
        }));
    };

    useEffect(() => {
        const disabled = !!!selectedAccount;
        const projectData: DocuSignProjectIntegrationData = {
            selectedAccount,
            accountType: (projectIntegrationData as DocuSignProjectIntegrationData)?.accountType,
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData: projectData,
                projectIntegrationDisplayName: `DocuSign - ${selectedAccount?.label?.split(' - ')[0]}`,
            },
            disabled,
        );
    }, [onChangeOrInitIntegration, projectIntegrationData, selectedAccount]);

    return (
        <div className="flex flex-col">
            <span className="control-label margin-bottom">Select Account:</span>
            <TnkSelect
                isMulti={false}
                options={accountsList}
                onChange={onAccountSelected}
                value={selectedAccount}
                isSearchable
            />
        </div>
    );
};

export default DocuSignSetupComponent;
