import React from 'react';
import styled from 'styled-components';

import StatusProgressBarComponent from './StatusProgressBarComponent';
import type StatusProgressBarWidgetConfiguration from './StatusProgressBarWidgetConfiguration';
import type { ItemWidgetProps } from '../../WidgetModule';
import { ItemWidget } from '../../WidgetModule';

import { ProgressBarEmpty } from '@tonkean/svg';
import { FontSize } from '@tonkean/tui-theme';

const EmptyStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: fit-content;
    padding: 40px;
`;

const EmptyStateTitle = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    margin-top: 24px;
    font-weight: 500;
`;

const EmptyStateSubTitle = styled.div`
    margin-top: 8px;
    font-size: ${FontSize.MEDIUM_14};
`;

const StatusProgressBarWidget: React.FC<ItemWidgetProps<StatusProgressBarWidgetConfiguration>> = ({
    initiative,
    widget,
    permission,
}) => {
    return (
        <ItemWidget permission={permission} disableMinHeight>
            {widget.configuration.selectedStatuses && widget.configuration.selectedStatuses.length > 0 ? (
                <StatusProgressBarComponent configuration={widget.configuration} initiative={initiative} />
            ) : (
                <EmptyStateContainer>
                    <ProgressBarEmpty />
                    <EmptyStateTitle>No status fields</EmptyStateTitle>
                    <EmptyStateSubTitle>Select status type to display them here</EmptyStateSubTitle>
                </EmptyStateContainer>
            )}
        </ItemWidget>
    );
};

export default StatusProgressBarWidget;
