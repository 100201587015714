import React, { useMemo } from 'react';
import styled from 'styled-components';

import InterfaceListItem from './InterfaceListItem';

import type {
    ItemInterface,
    TonkeanId,
    TonkeanType,
    WorkflowFolder,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

const StyledInterfacesList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

export interface Props {
    itemInterfaces: ItemInterface[];
    itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    onItemInterfaceCreated(newItemInterface: ItemInterface): void;
    onItemInterfaceUpdated(newItemInterface: ItemInterface): void;
    onItemInterfaceDeleted(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
    ): void;
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowFolder: WorkflowFolder;
    workflowVersionType: WorkflowVersionType;
    exampleItemId: TonkeanId<TonkeanType.INITIATIVE>;
    setAsDefaultItemInterface(itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>, isDefault: boolean): void;
}

const InterfacesList: React.FC<Props> = ({
    itemInterfaces = [],
    workflowFolder,
    onItemInterfaceCreated,
    onItemInterfaceUpdated,
    onItemInterfaceDeleted,
    groupId,
    workflowVersionType,
    exampleItemId,
    setAsDefaultItemInterface,
    itemInterfaceId,
}) => {
    const sortedItemInterfaces = useMemo(
        () => [...itemInterfaces].sort((a, b) => a.displayName.localeCompare(b.displayName)),
        [itemInterfaces],
    );

    if (itemInterfaces.length == 0) {
        return null;
    }

    return (
        <StyledInterfacesList data-automation="interfaces-list">
            {sortedItemInterfaces.map((entity) => (
                <InterfaceListItem
                    itemInterface={entity}
                    key={entity.id}
                    onItemInterfaceCreated={onItemInterfaceCreated}
                    onItemInterfaceUpdated={onItemInterfaceUpdated}
                    onItemInterfaceDeleted={onItemInterfaceDeleted}
                    groupId={groupId}
                    workflowFolder={workflowFolder}
                    workflowVersionType={workflowVersionType}
                    exampleItemId={exampleItemId}
                    setAsDefaultItemInterface={setAsDefaultItemInterface}
                    activeItemInterfaceId={itemInterfaceId}
                />
            ))}
        </StyledInterfacesList>
    );
};

export default InterfacesList;
