import {
    ActionType,
    IntegrationActionType,
    IntegrationType,
    ProjectIntegrationPageMenuItemType,
} from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';
import { getFirstCustomTriggerAction, convertMatchSelectionToServerObject } from '@tonkean/tonkean-utils';

/* @ngInject */
function PerformIntegrationActionLogicConfigurationCtrl(
    $rootScope,
    $scope,
    $timeout,
    projectManager,
    integrationsConsts,
    customFieldsManager,
    utils,
    groupInfoManager,
    tonkeanService,
    authenticationService,
    $state,
    integrations,
    $q,
    integrationMetadataManager,
) {
    const ctrl = this;
    $scope.ActionType = ActionType;

    $scope.pm = projectManager;
    $scope.integrations = integrations;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        selectedProjectIntegration: ctrl.selectedProjectIntegration,
        isDynamicFieldsAllowed: true,
        isFieldListDynamicFieldsAllowed: true,
        isNotEmptyFieldMetadata: false,
        isGenericIntegrationActionsShown: true,
        isEntitiesFeatureFlagOn: $rootScope.features.currentProject.tonkean_feature_enable_project_integration_entities,
        isFieldListFeatureFlagOn: $rootScope.features.currentProject.tonkean_feature_custom_action_field_list,
        isShowCustomHttpActionFlagOn: $rootScope.features.currentProject.tonkean_feature_show_custom_http_action,
        isShowExtractTextFromFileAction:
            $rootScope.features.currentProject.tonkean_feature_enable_extract_text_from_file_action,
        isShowGetFileContentAction:
            $rootScope.features.currentProject.tonkean_feature_enable_get_file_content_action,
        actions: {
            customActions: {
                id: 'customActions',
                title: 'Actions',
                type: IntegrationActionType.CUSTOM_ACTIONS,
            },
            createNewItem: {
                id: 'createNewItem',
                title: 'Create New',
                type: IntegrationActionType.CREATE_NEW_ITEM,
            },
            createOrUpdateItem: {
                id: 'createOrUpdateItem',
                title: 'Create Or Update',
                type: IntegrationActionType.CREATE_OR_UPDATE_ITEM,
            },
            updateField: {
                id: 'updateField',
                title: 'Update',
                type: IntegrationActionType.MANUAL_FIELD_UPDATE,
            },
            genericIntegrationAction: {
                id: 'genericIntegrationAction',
                title: 'Custom API Action',
                type: IntegrationActionType.GENERIC_INTEGRATION_ACTION,
                displayName: 'Custom Data Source Action',
                apiBaseUrl: null,
            },
            projectIntegrationAction: {
                id: 'projectIntegrationAction',
                type: IntegrationActionType.PROJECT_INTEGRATION_ACTION,
            },
        },
        actionsByIntegrationsMap: integrationsConsts.getActionsByIntegrationsMap(),
        projectIntegrationActions: [],
        selectedProjectIntegrationAction: null,

        matchOptionSelectionObject: {},
        savedCustomTriggerAction: null,
        loading: true,

        selectedAction: null,
        cutsomActionFieldDefinitionToCreate: [],
        dynamicFields: [],

        relevantSpecialFields: [],
        specialFieldsMap: {},

        // The field the user choose - '--other--'
        selectedFieldForCreationIndex: null,

        reloadInputSelectorOptions: false,

        // Example Item values evaluation
        selectedExampleInitiative: null,
        exampleItemError: '',

        integrationState: {},

        actionPerformer: null,

        // Only used because its required by an inner component. Has no effect on logic
        fakeIntegrationsSettings: {},

        importActionConfig: ctrl.importActionConfig,

        workflowFolderProjectIntegrationsAccess: ctrl.workflowFolderProjectIntegrationsAccess,
        showDataSourcesAlternatives: false,

        projectIntegrationPageMenuItemType: ProjectIntegrationPageMenuItemType,

        entityFieldMetadata: [],
        entityFieldMetadataOptions: [],
        dataStorageExtendedMatchConfiguration: null,
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        $scope.init();
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.selectedProjectIntegration) {
            $scope.data.selectedProjectIntegration =
                ctrl.selectedProjectIntegration || changesObj.selectedProjectIntegration.currentValue;

            if ($scope.data.selectedProjectIntegration?.id) {
                tonkeanService
                    .getProjectIntegrationActions($scope.data.selectedProjectIntegration.id, [
                        ActionType.CREATE,
                        ActionType.UPDATE,
                        ActionType.CREATE_OR_UPDATE,
                        ActionType.CUSTOM,
                    ])
                    .then((customActionsResponse) => {
                        $scope.data.projectIntegrationActions = customActionsResponse.entities || [];
                    });

                if ($scope.data.selectedProjectIntegration.integrationType) {
                    $scope.data.fakeIntegrationsSettings[
                        $scope.data.selectedProjectIntegration.integrationType.toLowerCase()
                    ] = {};
                }
            }
        }

        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            if ($scope.data.configuredLogic.node.integrationType) {
                $scope.data.fakeIntegrationsSettings[$scope.data.configuredLogic.node.integrationType.toLowerCase()] =
                    {};
            }

            $scope.data.selectedAction = null;
            $scope.data.dynamicFields = [];

            initializeEditMode();

            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    $scope.reset = function () {
        $scope.data.selectedAction = null;
        $scope.data.dynamicFields = [];

        $scope.onActionsChanged(true);
    };

    $scope.init = function () {
        $scope.data.relevantSpecialFields = [];
        $scope.data.specialFieldsMap = {};

        $scope.data.relevantSpecialFields = getSpecialFieldsForFeatures(false, ['TRIGGER_ACTIONS']);

        for (let i = 0; i < $scope.data.relevantSpecialFields.length; i++) {
            const fieldDefinition = $scope.data.relevantSpecialFields[i];
            $scope.data.specialFieldsMap[fieldDefinition.id] = fieldDefinition;
        }

        if ($scope.data.selectedProjectIntegration?.id) {
            $scope.data.loading = true;

            tonkeanService
                .getIntegrationApiBaseUrl($scope.data.selectedProjectIntegration.id)
                .then((response) => ($scope.data.actions.genericIntegrationAction.apiBaseUrl = response.apiBaseUrl))
                .then(() =>
                    tonkeanService.getEnterpriseComponentAdmins(
                        $scope.data.selectedProjectIntegration.id,
                        $scope.data.selectedProjectIntegration.tonkeanEntityType,
                        projectManager.project.id,
                    ),
                )
                .then((admins) => ($scope.data.selectedProjectIntegrationAdmins = admins))
                .finally(() => {
                    $scope.data.loading = false;
                });
        }
    };

    /**
     * When user connect integration for the first time
     */
    $scope.updateIntegration = function (projectIntegration) {
        $scope.data.selectedProjectIntegration =
            projectIntegration || utils.objToArray($scope.data.integrationState)[0].value.projectIntegrations[0];
        $scope.data.dynamicFields = [];
        $scope.init();
        $scope.onActionsChanged(true);
    };

    $scope.isCurrentUserAnAdminInSelectedProjectIntegration = function () {
        return $scope.data.selectedProjectIntegrationAdmins?.some(
            (singleAdmin) => authenticationService.currentUser.id === singleAdmin.id,
        );
    };

    $scope.canActionBeSelected = function () {
        return (
            !$scope.data.selectedProjectIntegration?.id ||
            (!$scope.data.invalidLogics[$scope.data.configuredLogic.node.id]?.inAccessibleProjectIntegration &&
                !$scope.data.workflowFolderProjectIntegrationsAccess?.inAccessibleProjectIntegrations?.includes(
                    $scope.data.selectedProjectIntegration?.id,
                ))
        );
    };

    /**
     * Selects the create item action.
     */
    $scope.selectCreateItemAction = function (entityDefinition) {
        $scope.data.selectedAction = $scope.data.actions.createNewItem;
        $scope.data.selectedAction.displayName = `${$scope.data.actions.createNewItem.title} ${entityDefinition.displayName}`;
        $scope.data.selectedAction.definition = {
            entity: entityDefinition,
        };

        addActionFields();

        $scope.onActionsChanged(true);
    };

    $scope.selectCustomActionEntity = function (selectedEntity, actionDefinition, customActionKey) {
        actionDefinition.entity = { displayName: selectedEntity.label, ...selectedEntity };
        $scope.selectCustomAction(actionDefinition, customActionKey);
    };

    $scope.selectCreateItemActionFromEntitySelector = function (selectedEntity) {
        const entityDefinition = {
            entity: selectedEntity.entity,
            displayName: selectedEntity.label,
            pluralLabel: selectedEntity.pluralLabel,
            linkedFieldDefinitionsConfiguration: [
                {
                    field: 'DEFAULT',
                    friendlyName: `Created ${selectedEntity.label} ID`,
                },
            ],
        };
        const integrationType = $scope.getIntegrationType();
        integrationsConsts
            .getFieldsForAction(
                integrationType,
                $scope.data.actions.createNewItem.id,
                $scope.data.selectedProjectIntegration,
                tonkeanService,
            )
            .then((fieldsForAction) => {
                entityDefinition.requiredFields = fieldsForAction[selectedEntity.entity]?.requiredFields || [];
                entityDefinition.optionalFields = fieldsForAction[selectedEntity.entity]?.optionalFields || [];

                $scope.selectCreateItemAction(entityDefinition);
            });
    };

    /**
     * Selects the create or update item action.
     */
    $scope.selectCreateOrUpdateItemAction = function (entityDefinition) {
        $scope.data.selectedAction = $scope.data.actions.createOrUpdateItem;
        $scope.data.selectedAction.displayName = `${$scope.data.actions.createOrUpdateItem.title} ${entityDefinition.displayName}`;
        $scope.data.selectedAction.definition = {
            entity: entityDefinition,
        };

        addActionFields();

        $scope.onActionsChanged(true);
    };

    /**
     * Selects the update field action.
     */
    $scope.selectUpdateFieldAction = function (entityDefinition, fieldUpdateDefinition) {
        $scope.data.selectedAction = $scope.data.actions.updateField;
        $scope.data.selectedAction.displayName = `${$scope.data.actions.updateField.title} ${entityDefinition.displayName}`;
        $scope.data.selectedAction.definition = {
            entity: entityDefinition,
        };

        if (fieldUpdateDefinition) {
            $scope.data.dynamicFields.push(generateField([fieldUpdateDefinition], 0, true, null));
        } else {
            $scope.addNewDynamicFieldCreation();
        }

        addActionFields();

        $scope.onActionsChanged(true);
    };

    $scope.selectUpdateFieldActionFromEntitySelector = function (selectedEntity) {
        const entityDefinition = {
            entity: selectedEntity.entity,
            displayName: selectedEntity.label,
            pluralLabel: selectedEntity.pluralLabel,
        };
        $scope.selectUpdateFieldAction(entityDefinition);
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.fileSourceType = fileSourceType;
        $scope.data.storageIntegrationId = storageIntegrationId;
        $scope.data.dataStorageExtendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.urlExpression = urlExpression;
        $scope.data.workerStaticAssetId = workerStaticAssetId;
        $scope.data.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.parametersValues = parametersValues;
        $scope.data.storageIntegrationActionId = storageIntegrationActionId;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs on change of values for fields configuration.
     */
    $scope.onFieldsValuesDefinitionChanged = function (fieldsValuesDefinition, shouldSaveLogic) {
        const customActionKey = $scope.data.selectedAction.definition.customActionKey;
        const integrationType = $scope.data.selectedProjectIntegration.integrationType.toUpperCase();
        const customHttpActionConfiguration =
            $scope.data.actionsByIntegrationsMap[integrationType].customActions[customActionKey];

        if (
            customHttpActionConfiguration.convertToHttpDefinition &&
            (customHttpActionConfiguration?.fieldsConfiguration?.fields?.length === 0 ||
                !utils.isNullOrUndefined(fieldsValuesDefinition))
        ) {
            const actionDefinition = customHttpActionConfiguration.convertToHttpDefinition(fieldsValuesDefinition);

            if (actionDefinition) {
                actionDefinition.customActionKey = customActionKey;
                actionDefinition.fieldsValuesDefinition = fieldsValuesDefinition;
                actionDefinition.isCustomHttpAction = true;
                actionDefinition.entity = customHttpActionConfiguration.entity;

                $scope.data.selectedAction.definition = actionDefinition;
                return $scope.onActionsChanged(shouldSaveLogic);
            }
        }

        return $q.resolve();
    };

    /**
     * Selects the update field action.
     */
    $scope.selectCustomAction = function (actionDefinition, customActionKey) {
        $scope.data.selectedAction = actionDefinition;
        $scope.data.selectedAction.type = 'CUSTOM_ACTIONS';
        $scope.data.selectedAction.displayName = actionDefinition.displayName;
        $scope.data.selectedAction.definition = {
            customActionKey,
            entity: actionDefinition.entity,
        };

        if ($scope.data.selectedAction.isCustomHttpAction) {
            // If we're a custom http action, we use onFieldsValuesDefinitionChanged to create our definition for
            // the action, and it will also run onActionsChanged for us.
            $scope.onFieldsValuesDefinitionChanged(null, true);
        } else {
            // Otherwise, we call onActionsChanged ourselves.
            $scope.onActionsChanged(true);
        }
    };

    $scope.selectedGenericIntegrationAction = function () {
        $scope.data.selectedAction = {};
        $scope.data.selectedAction.type = 'GENERIC_INTEGRATION_ACTION';
        $scope.data.selectedAction.displayName = 'Custom Data Source Action';
        $scope.data.selectedAction.definition = {
            customActionKey: 'GENERIC_INTEGRATION_ACTION',
        };

        $scope.onActionsChanged(true);
    };

    function initEntityFieldMetadata() {
        if (
            $scope.data.isFieldListDynamicFieldsAllowed &&
            $scope.data.selectedProjectIntegrationAction.parametersDefinition.entitiesToRunOn[0]
        ) {
            $scope.data.loading = true;

            return tonkeanService
                .getAvailableExternalFields($scope.data.selectedProjectIntegrationAction.projectIntegrationId, [
                    $scope.data.selectedProjectIntegrationAction.parametersDefinition.entitiesToRunOn[0],
                ])
                .then((response) => {
                    $scope.data.entityFieldMetadata = response.entitiesWithLabels.filter(
                        (entity) =>
                            !['TNK_REAL_ENTITY_ID', 'TNK_NEW_CREATED_DATE', 'TNK_NEW_MODIFIED_DATE'].includes(
                                entity.name,
                            ),
                    );

                    $scope.data.isNotEmptyFieldMetadata = $scope.data.entityFieldMetadata.length > 0;

                    $scope.data.entityFieldMetadataOptions = $scope.data.entityFieldMetadata.map((fieldMetadata) => ({
                        value: fieldMetadata.name,
                        label: fieldMetadata.isLabelOverridable ? fieldMetadata.overridableLabel : fieldMetadata.label,
                    }));

                    $scope.data.loading = false;

                    return $q.resolve();
                });
        }

        return $q.resolve();
    }

    $scope.selectProjectIntegrationAction = function (action) {
        $scope.data.selectedAction = {};
        $scope.data.selectedAction.type = 'PROJECT_INTEGRATION_ACTION';
        $scope.data.selectedAction.displayName = action.displayName;
        $scope.data.loading = true;
        integrationMetadataManager
            .getIntegrationEntitiesAsDict(action.projectIntegrationId)
            .then((dict) => {
                const entityId = action?.parametersDefinition?.entitiesToRunOn?.[0];
                $scope.data.selectedAction.definition = {
                    customActionKey: 'PROJECT_INTEGRATION_ACTION',
                    projectIntegrationActionId: action.id,
                    entity: entityId
                        ? { displayName: dict[entityId]?.pluralLabel || entityId, entity: entityId }
                        : undefined,
                };

                $scope.data.selectedProjectIntegrationAction = action;

                $scope.data.isFieldListDynamicFieldsAllowed = $scope.calcIsFieldListDynamicFieldsAllowed(
                    $scope.data.selectedProjectIntegrationAction,
                );

                $scope.onActionsChanged(true);
                return initEntityFieldMetadata();
            })
            .finally(() => ({
                //skipping setting loading to false to avoid flickering. View will reload due to the call to onActionsChanged
                //$scope.data.loading = false

            }));
    };

    $scope.toggleShowDataSourcesAlternatives = function () {
        $scope.data.showDataSourcesAlternatives = !$scope.data.showDataSourcesAlternatives;
    };

    /**
     * Navigate to the specific project integration page using the return to state button
     */
    $scope.goToProjectIntegrationPageWithReturnTo = function (page) {
        $state.go('product.projectIntegrationPage', {
            fromName: projectManager.groupsMap[$scope.data.groupId].name,
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
            enterpriseComponentId: $scope.data.selectedProjectIntegration.id,
            page,
        });
    };

    /**
     * Add empty field mapping.
     */
    $scope.addNewDynamicFieldCreation = function () {
        $scope.data.dynamicFields.push({});
        $scope.onActionsChanged(true);
    };

    $scope.getEntityMetadataAsDynamicField = function (dynamicFieldCreation) {
        return dynamicFieldCreation.selectedField
            ? {
                  value: dynamicFieldCreation.selectedField.name,
                  label: dynamicFieldCreation.selectedField.label,
              }
            : {};
    };

    /**
     * Removes a field.
     */
    $scope.removeDynamicField = function (index) {
        const dynamicFieldToDelete = $scope.data.dynamicFields[index];
        $scope.data.dynamicFields.splice(index, 1);

        if ($scope.data.selectedAction.definition.parametersValues) {
            const fieldId = dynamicFieldToDelete.selectedField?.id
                ? dynamicFieldToDelete.selectedField.id
                : dynamicFieldToDelete.selectedField?.name;

            if (fieldId && $scope.data.selectedAction.definition.parametersValues[fieldId]) {
                delete $scope.data.selectedAction.definition.parametersValues[fieldId];
            }
        }

        $scope.onActionsChanged(true);
    };

    /**
     * Select a field for the mapping.
     */
    $scope.onDynamicInputFieldSelected = function (selectedField, index) {
        $scope.data.dynamicFields[index].selectedField = selectedField;
        $scope.onActionsChanged(true);
    };

    /**
     * Select a value for the mapping.
     */
    $scope.onDynamicValueInputChanged = function (fieldValueInputOption, index) {
        $scope.data.dynamicFields[index].valueInputConfiguration = fieldValueInputOption;
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs on change of the expression.
     */
    $scope.onTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        index,
        shouldSaveLogic,
        isStripHtmlDisabled,
    ) {
        $scope.data.dynamicFields[index].valueInputConfiguration = {
            valueInputOptionType: 'EXPRESSION',
            originalExpression,
            evaluatedExpression,
            isStripHtmlDisabled: !!isStripHtmlDisabled,
        };
        $scope.onActionsChanged(shouldSaveLogic);
    };

    function getAllCustomActions() {
        return [
            ...$scope.data.projectIntegrationActions,
        ];
    }

    /**
     * Initializes the edit mode of the component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (customTriggerAction) {
            // Saving the custom trigger action for later use.
            $scope.data.savedCustomTriggerAction = customTriggerAction;

            // Initialize action to perform. For Example: Create
            for (const key in $scope.data.actions) {
                if (
                    $scope.data.actions.hasOwnProperty(key) &&
                    $scope.data.actions[key].type ===
                        customTriggerAction.customTriggerActionDefinition.performedActionType
                ) {
                    $scope.data.selectedAction = $scope.data.actions[key];
                    break;
                }
            }

            // Initialize selected project integration.
            if (customTriggerAction.customTriggerActionDefinition.projectIntegrationId) {
                $scope.data.selectedProjectIntegration = projectManager.getProjectIntegrationById(
                    customTriggerAction.customTriggerActionDefinition.projectIntegrationId,
                );

                if (!$scope.data.selectedProjectIntegration) {
                    $scope.data.selectedProjectIntegration =
                        projectManager.groupsMap[$scope.data.groupId].projectIntegrationIdDict[
                            customTriggerAction.customTriggerActionDefinition.projectIntegrationId
                        ];
                }
            } else {
                // if temp integration type (no connected project integration)
                $scope.data.selectedProjectIntegration = {
                    name: customTriggerAction.customTriggerActionDefinition.integrationType.toLowerCase(),
                    integrationType: customTriggerAction.customTriggerActionDefinition.integrationType,
                };
            }

            // Make sure data.actionPerformer is initialized if exists on the definition.
            if (
                customTriggerAction.customTriggerActionDefinition.performedActionDefinition &&
                customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
            ) {
                $scope.data.actionPerformer =
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer;
            }

            // Initialize action specific properties.
            if ($scope.data.selectedAction) {
                switch ($scope.data.selectedAction.type) {
                    case 'CREATE_NEW_ITEM':
                    case 'CREATE_OR_UPDATE_ITEM': {
                        $scope.data.selectedAction.definition = {
                            entity: {},
                        };

                        // Selected entity metadata.
                        $scope.data.selectedAction.definition.entity =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.entityMetadata;

                        const integrationType = $scope.getIntegrationType();

                        // Getting required fields map.
                        integrationsConsts
                            .getFieldsForAction(
                                integrationType,
                                $scope.data.selectedAction.id,
                                $scope.data.selectedProjectIntegration,
                                tonkeanService,
                            )
                            .then((fieldsForAction) => {
                                const requiredFields =
                                    fieldsForAction[$scope.data.selectedAction.definition.entity.entity]
                                        ?.requiredFields || [];
                                const optionalFields =
                                    fieldsForAction[$scope.data.selectedAction.definition.entity.entity]
                                        ?.optionalFields || [];

                                // Initializing the already configured fields.
                                for (const key in customTriggerAction.customTriggerActionDefinition
                                    .performedActionDefinition.fieldsForCreation) {
                                    if (
                                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.fieldsForCreation.hasOwnProperty(
                                            key,
                                        )
                                    ) {
                                        $scope.data.dynamicFields.push({
                                            mandatoryField: requiredFields[key],
                                            definition: requiredFields[key] || optionalFields[key],
                                            selectedField:
                                                customTriggerAction.customTriggerActionDefinition
                                                    .performedActionDefinition.fieldsForCreation[key].selectedField,
                                            valueInputConfiguration:
                                                customTriggerAction.customTriggerActionDefinition
                                                    .performedActionDefinition.fieldsForCreation[key]
                                                    .valueInputConfiguration,
                                        });
                                    }
                                }

                                const shouldInitializeOptionalFields = $scope.data.dynamicFields.length === 0;

                                // Initializing the required fields.
                                for (const key in requiredFields) {
                                    if (
                                        !customTriggerAction.customTriggerActionDefinition.performedActionDefinition.fieldsForCreation.hasOwnProperty(
                                            key,
                                        ) &&
                                        requiredFields.hasOwnProperty(key)
                                    ) {
                                        $scope.data.dynamicFields.push(generateField(requiredFields, key, true, null));
                                    }
                                }
                                // Whether the user can add dynamic fields (Data Source Settings).
                                $scope.data.isDynamicFieldsAllowed = !utils.isNullOrEmpty(
                                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition
                                        .entityMetadata.isDynamicFieldsAllowed,
                                )
                                    ? customTriggerAction.customTriggerActionDefinition.performedActionDefinition
                                          .entityMetadata.isDynamicFieldsAllowed
                                    : true;

                                // If form is empty load optional as well
                                if (shouldInitializeOptionalFields) {
                                    for (const key in optionalFields) {
                                        if (
                                            !customTriggerAction.customTriggerActionDefinition.performedActionDefinition.fieldsForCreation.hasOwnProperty(
                                                key,
                                            ) &&
                                            optionalFields.hasOwnProperty(key)
                                        ) {
                                            $scope.data.dynamicFields.push(
                                                generateField(optionalFields, key, false, null),
                                            );
                                        }
                                    }
                                }
                            });
                        break;
                    }
                    case 'MANUAL_FIELD_UPDATE': {
                        $scope.data.selectedAction.definition = {
                            entity: {},
                            field: {},
                        };

                        if (customTriggerAction.customTriggerActionDefinition.performedActionDefinition) {
                            $scope.data.selectedAction.definition.entity =
                                customTriggerAction.customTriggerActionDefinition.performedActionDefinition.entityMetadata;

                            // This if is required for backwards compatibility.
                            if (
                                customTriggerAction.customTriggerActionDefinition.performedActionDefinition
                                    .fieldDefinitionIdToValueMap
                            ) {
                                // Initializing the already configured fields.
                                const fieldDefinitionIdToValueMap =
                                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition
                                        .fieldDefinitionIdToValueMap;
                                const existingFieldDefinitions =
                                    customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId];
                                const existingFieldDefinitionMap = utils.createMapFromArray(
                                    existingFieldDefinitions,
                                    'id',
                                );

                                for (const fieldDefinitionId in fieldDefinitionIdToValueMap) {
                                    if (fieldDefinitionIdToValueMap.hasOwnProperty(fieldDefinitionId)) {
                                        const existingFieldDefinition = existingFieldDefinitionMap[fieldDefinitionId];

                                        if (
                                            existingFieldDefinition.definition &&
                                            existingFieldDefinition.definition.FieldName &&
                                            existingFieldDefinition.definition.ExternalType
                                        ) {
                                            $scope.data.dynamicFields.push({
                                                selectedField: {
                                                    name: existingFieldDefinition.definition.FieldName,
                                                    entity: existingFieldDefinition.definition.ExternalType,
                                                },
                                                valueInputConfiguration: fieldDefinitionIdToValueMap[fieldDefinitionId],
                                            });
                                        }
                                    }
                                }
                            }

                            if (
                                customTriggerAction.customTriggerActionDefinition.performedActionDefinition
                                    .externalDefinitions
                            ) {
                                const externalDefinitions =
                                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition
                                        .externalDefinitions;

                                for (const externalFieldName in externalDefinitions) {
                                    if (externalDefinitions.hasOwnProperty(externalFieldName)) {
                                        $scope.data.dynamicFields.push({
                                            selectedField: externalDefinitions[externalFieldName],
                                            valueInputConfiguration: externalDefinitions[externalFieldName].value,
                                        });
                                    }
                                }
                            }
                        }

                        break;
                    }
                    case 'PROJECT_INTEGRATION_ACTION':
                        $scope.data.selectedAction.definition =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition;
                        $scope.data.existingFieldsValuesDefinition =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.fieldsValuesDefinition;

                        $scope.data.fileSourceType =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.fileSourceType;
                        $scope.data.storageIntegrationId =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.storageIntegrationId;
                        $scope.data.storageIntegrationActionId =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.storageIntegrationActionId;
                        $scope.data.dataStorageExtendedMatchConfiguration =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.extendedMatchConfiguration;
                        $scope.data.extendedMatchConfiguration =
                            customTriggerAction.customTriggerActionDefinition.extendedMatchConfiguration;
                        $scope.data.fileIdInStorageIntegration =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.fileId;
                        $scope.data.urlExpression =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.url;

                        $scope.data.workerStaticAssetId =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.workerStaticAssetId;
                        $scope.data.workerStaticAssetDisplayName =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.workerStaticAssetDisplayName;
                        $scope.data.parametersValues =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.source?.parametersValues;

                        const allCustomActions = getAllCustomActions();

                        if (
                            $scope.data.projectIntegrationActions?.length &&
                            $scope.data.selectedAction?.definition?.projectIntegrationActionId &&
                            allCustomActions.some(
                                (action) =>
                                    action?.id === $scope.data.selectedAction.definition.projectIntegrationActionId,
                            )
                        ) {
                            $scope.data.selectedProjectIntegrationAction = allCustomActions.find(
                                (action) =>
                                    action?.id === $scope.data.selectedAction.definition.projectIntegrationActionId,
                            );
                        } else {
                            if (
                                $scope.data.isGenericIntegrationActionsShown &&
                                $scope.data.selectedProjectIntegration?.id
                            ) {
                                tonkeanService
                                    .getProjectIntegrationActions($scope.data.selectedProjectIntegration.id)
                                    .then((customActionsResponse) => {
                                        $scope.data.projectIntegrationActions = customActionsResponse.entities || [];
                                    })
                                    .then(() => {
                                        const allCustomActions = getAllCustomActions();

                                        if ($scope.data.selectedAction?.definition?.projectIntegrationActionId) {
                                            $scope.data.selectedProjectIntegrationAction = allCustomActions.find(
                                                (action) =>
                                                    action?.id ===
                                                    $scope.data.selectedAction.definition.projectIntegrationActionId,
                                            );

                                            $scope.data.isFieldListDynamicFieldsAllowed =
                                                $scope.calcIsFieldListDynamicFieldsAllowed(
                                                    $scope.data.selectedProjectIntegrationAction,
                                                );

                                            return initEntityFieldMetadata().then(() => {
                                                const parameters = utils.objToArray(
                                                    $scope.data.selectedAction.definition.parametersValues,
                                                );

                                                parameters.forEach((parameter) => {
                                                    if (parameter.value.parameterType === 'FIELD_LIST') {
                                                        const fieldMetadata = $scope.data.entityFieldMetadata.find(
                                                            (fieldMetadata) => fieldMetadata.name === parameter.key,
                                                        );

                                                        if (fieldMetadata) {
                                                            $scope.data.dynamicFields.push({
                                                                selectedField: {
                                                                    name: fieldMetadata.name,
                                                                    entity: fieldMetadata.entity,
                                                                },
                                                                valueInputConfiguration:
                                                                    $scope.data.selectedAction.definition
                                                                        .parametersValues[fieldMetadata.name],
                                                            });
                                                        }
                                                    }
                                                });
                                            });
                                        }
                                    });
                            }
                        }

                        break;

                    case 'CUSTOM_ACTIONS':
                    case 'GENERIC_INTEGRATION_ACTION':
                        $scope.data.selectedAction.definition =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition;
                        $scope.data.existingFieldsValuesDefinition =
                            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.fieldsValuesDefinition;
                        break;
                }
            }
        }

        if (
            $scope.data.configuredLogic.node.customTriggerActions &&
            $scope.data.configuredLogic.node.customTriggerActions.length
        ) {
            for (let i = 0; i < $scope.data.configuredLogic.node.customTriggerActions.length; i++) {
                const customTriggerAction = $scope.data.configuredLogic.node.customTriggerActions[i];

                if (customTriggerAction.customTriggerActionType === 'MANUAL_FIELD_UPDATE') {
                    for (const key in customTriggerAction.customTriggerActionDefinition.fieldIdToValueMap) {
                        if (customTriggerAction.customTriggerActionDefinition.fieldIdToValueMap.hasOwnProperty(key)) {
                            let fieldDefinition = null;
                            if ($scope.data.fieldDefinitionMap[key]) {
                                fieldDefinition = $scope.data.fieldDefinitionMap[key];
                            } else if ($scope.data.specialFieldsMap[key]) {
                                fieldDefinition = $scope.data.specialFieldsMap[key];
                            }

                            $scope.selectFieldDefinition(fieldDefinition);
                            $scope.data.selectedValueToUpdate =
                                customTriggerAction.customTriggerActionDefinition.fieldIdToValueMap[key];
                            $scope.data.selectedProjectIntegration = fieldDefinition.projectIntegration;
                        }
                    }
                }
            }
        }
    }

    $scope.calcIsFieldListDynamicFieldsAllowed = function (projectIntegrationAction) {
        if (!projectIntegrationAction) {
            return false;
        }

        return (
            projectIntegrationAction.parametersDefinition.parameters.some(
                (parameter) => parameter.parameterType === 'FIELD_LIST',
            ) &&
            !projectIntegrationAction.actionDefinition.enableKeyValueParameters &&
            projectIntegrationAction.actionDefinition.methodType !== 'GET'
        );
    };

    /**
     * Occurs once the match option selection object is changed.
     */
    $scope.onMatchOptionSelectionChanged = function (selectionObject, shouldSave) {
        if (selectionObject && selectionObject.validMatchSelection) {
            $scope.data.matchOptionSelectionObject = selectionObject;
            $scope.onActionsChanged(shouldSave);
        }
    };

    /**
     * Occurs when the extended match configuration is changed.
     */
    $scope.onExtendedMatchConfigurationChanged = function (extendedMatchConfiguration, shouldSaveLogic) {
        $scope.data.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs when the custom integration action changes.
     */
    $scope.onCustomIntegrationActionChanged = function (definition, shouldSaveLogic) {
        $scope.data.selectedAction.definition = definition;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs when ever the definition of a project integration action is changed.
     */
    $scope.onProjectIntegrationActionDefinitionChanged = function (definition, shouldSaveLogic) {
        $scope.data.selectedAction.definition = definition;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.isLoading = function () {
        return $scope.data.loading || !$scope.data.selectedProjectIntegrationAction || !$scope.data.selectedAction;
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSave, childrenToCreate) {
        if (ctrl.onActionsChanged) {
            let actionToPerformDefinition = {};

            if ($scope.data.selectedAction) {
                switch ($scope.data.selectedAction.type) {
                    case 'CREATE_NEW_ITEM':
                    case 'CREATE_OR_UPDATE_ITEM': {
                        const fieldsForCreation = {};

                        for (let i = 0; i < $scope.data.dynamicFields.length; i++) {
                            const dynamicFieldCreation = $scope.data.dynamicFields[i];

                            // Only fields with data, in case of manual only if value is set
                            if (!dynamicFieldCreation.selectedField || !dynamicFieldCreation.valueInputConfiguration) {
                                continue;
                            }

                            if (
                                dynamicFieldCreation.valueInputConfiguration.valueInputOptionType === 'EXPRESSION' ||
                                dynamicFieldCreation.valueInputConfiguration.valueInputOptionType !== 'MANUAL_INPUT' ||
                                dynamicFieldCreation.valueInputConfiguration.value
                            ) {
                                const fieldId = dynamicFieldCreation.selectedField.id
                                    ? dynamicFieldCreation.selectedField.id
                                    : dynamicFieldCreation.selectedField.name;
                                fieldsForCreation[fieldId] = {
                                    selectedField: dynamicFieldCreation.selectedField,
                                    valueInputConfiguration: dynamicFieldCreation.valueInputConfiguration,
                                };
                            }
                        }

                        actionToPerformDefinition.fieldsForCreation = fieldsForCreation;
                        actionToPerformDefinition.entityMetadata = $scope.data.selectedAction.definition.entity;
                        actionToPerformDefinition.actionPerformer = $scope.data.actionPerformer;
                        break;
                    }
                    case 'MANUAL_FIELD_UPDATE':
                        actionToPerformDefinition.externalDefinitions = {};

                        for (let i = 0; i < $scope.data.dynamicFields.length; i++) {
                            const dynamicFieldForUpdate = $scope.data.dynamicFields[i];

                            if (
                                dynamicFieldForUpdate.selectedField &&
                                dynamicFieldForUpdate.selectedField.name &&
                                dynamicFieldForUpdate.valueInputConfiguration &&
                                !utils.isNullOrEmpty(
                                    dynamicFieldForUpdate.valueInputConfiguration.valueInputOptionType,
                                ) &&
                                (dynamicFieldForUpdate.valueInputConfiguration.valueInputOptionType !==
                                    'MANUAL_INPUT' ||
                                    dynamicFieldForUpdate.valueInputConfiguration.value)
                            ) {
                                dynamicFieldForUpdate.selectedField.value =
                                    dynamicFieldForUpdate.valueInputConfiguration;
                                actionToPerformDefinition.externalDefinitions[
                                    dynamicFieldForUpdate.selectedField.name
                                ] = dynamicFieldForUpdate.selectedField;
                            }
                        }

                        actionToPerformDefinition.entityMetadata = $scope.data.selectedAction.definition.entity;
                        actionToPerformDefinition.actionPerformer = $scope.data.actionPerformer;
                        break;

                    case 'CUSTOM_ACTIONS':
                    case 'PROJECT_INTEGRATION_ACTION':
                    case 'GENERIC_INTEGRATION_ACTION':
                        $scope.data.dynamicFields.forEach((dynamicFieldToCreate) => {
                            if (dynamicFieldToCreate.selectedField && dynamicFieldToCreate.valueInputConfiguration) {
                                const fieldId = dynamicFieldToCreate.selectedField?.id
                                    ? dynamicFieldToCreate.selectedField.id
                                    : dynamicFieldToCreate.selectedField.name;

                                if (!$scope.data.selectedAction.definition.parametersValues) {
                                    $scope.data.selectedAction.definition.parametersValues = {};
                                }

                                $scope.data.selectedAction.definition.parametersValues[fieldId] = {
                                    evaluatedExpression:
                                        dynamicFieldToCreate.valueInputConfiguration.evaluatedExpression,
                                    originalExpression: dynamicFieldToCreate.valueInputConfiguration.originalExpression,
                                    parameterType: 'FIELD_LIST',
                                };
                            }
                        });

                        actionToPerformDefinition = $scope.data.selectedAction.definition || {};
                        actionToPerformDefinition.actionPerformer = $scope.data.actionPerformer;
                        break;
                }
            }

            if (
                $scope.data.selectedProjectIntegrationAction?.actionDefinition?.definitionType === 'HTTP' &&
                $scope.data.selectedProjectIntegrationAction?.actionDefinition?.shouldIncludeFileDownloading
            ) {
                actionToPerformDefinition.source = {
                    fileSourceType: $scope.data.fileSourceType,
                    storageIntegrationId:
                        $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.storageIntegrationId : undefined,
                    storageIntegrationActionId:
                        $scope.data.fileSourceType === 'DATA_STORAGE'
                            ? $scope.data.storageIntegrationActionId
                            : undefined,
                    extendedMatchConfiguration:
                        $scope.data.fileSourceType === 'DATA_STORAGE'
                            ? $scope.data.dataStorageExtendedMatchConfiguration
                            : undefined,
                    url: $scope.data.fileSourceType === 'URL' ? $scope.data.urlExpression : undefined,
                    workerStaticAssetId:
                        $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetId : undefined,
                    workerStaticAssetDisplayName:
                        $scope.data.fileSourceType === 'UPLOAD_FILE'
                            ? $scope.data.workerStaticAssetDisplayName
                            : undefined,
                };
            }

            const fullActionDefinition = {
                performedActionType: $scope.data.selectedAction
                    ? $scope.data.selectedAction.type
                    : IntegrationActionType.UNKNOWN,
                projectIntegrationId: $scope.data.selectedProjectIntegration
                    ? $scope.data.selectedProjectIntegration.id
                    : null,
                integrationType: $scope.data.selectedProjectIntegration
                    ? $scope.data.selectedProjectIntegration.integration
                        ? $scope.data.selectedProjectIntegration.integration.integrationType
                        : $scope.data.selectedProjectIntegration.integrationType
                    : null,

                performedActionDefinition: actionToPerformDefinition,
            };

            if ($scope.data.matchOptionSelectionObject) {
                fullActionDefinition.matchConfiguration = convertMatchSelectionToServerObject(
                    $scope.data.matchOptionSelectionObject,
                );
            }

            if ($scope.data.extendedMatchConfiguration) {
                fullActionDefinition.extendedMatchConfiguration = $scope.data.extendedMatchConfiguration;
            }

            if (
                $scope.data.selectedAction &&
                $scope.data.selectedAction.definition &&
                $scope.data.selectedAction.definition.entity
            ) {
                fullActionDefinition.performOnEntity = $scope.data.selectedAction.definition.entity;
            }

            const actionDefinition = {
                actions: [
                    {
                        type: 'PERFORM_INTEGRATION_ACTION',
                        displayName: $scope.data.selectedAction ? $scope.data.selectedAction.displayName : null,
                        definition: fullActionDefinition,
                    },
                ],
            };

            if (childrenToCreate && childrenToCreate.length) {
                actionDefinition.childrenCustomTriggersToCreate = childrenToCreate;
            }

            return ctrl
                .onActionsChanged({ definition: actionDefinition, shouldSaveLogic: shouldSave })
                .then(() =>
                    customFieldsManager.getCustomTriggersLinkedFieldDefinitions($scope.data.workflowVersionId, [
                        $scope.data.configuredLogic.node.id,
                    ]),
                );
        }
    };

    $scope.onActionPerformerChanged = function (actionPerformer, shouldSaveLogic) {
        $scope.data.actionPerformer = actionPerformer;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Generate field by type
     */
    function generateField(fields, key, isMandatory, type) {
        const newField = {
            mandatoryField: isMandatory,
            selectedField: fields[key],
            valueInputConfiguration: { valueInputOptionType: '', definition: {} },
        };

        if (type) {
            newField.valueInputConfiguration.type = type;
        }

        if (fields[key].defaultValueType === 'MANUAL') {
            newField.valueInputConfiguration.valueInputOptionType = 'MANUAL_INPUT';
            newField.valueInputConfiguration.definition = { id: 'MANUAL_INPUT', name: 'Manual Input' };
        }
        if (fields[key].defaultValueType === 'TNK_TITLE') {
            newField.valueInputConfiguration.valueInputOptionType = 'SPECIAL_FIELD';
            newField.valueInputConfiguration.definition = { id: 'TNK_TITLE', name: 'Title' };
        }
        if (fields[key].defaultValueType === 'TNK_STAGE') {
            newField.valueInputConfiguration.valueInputOptionType = 'SPECIAL_FIELD';
            newField.valueInputConfiguration.definition = { id: 'TNK_STAGE', name: 'Status' };
        }
        if (fields[key].defaultValueType === 'TNK_OWNER_NAME') {
            newField.valueInputConfiguration.valueInputOptionType = 'SPECIAL_FIELD';
            newField.valueInputConfiguration.definition = { id: 'TNK_OWNER_NAME', name: 'Owner Name' };
        }
        if (fields[key].defaultValueType === 'TNK_OWNER_EMAIL') {
            newField.valueInputConfiguration.valueInputOptionType = 'SPECIAL_FIELD';
            newField.valueInputConfiguration.definition = { id: 'TNK_OWNER_EMAIL', name: 'Owner Email' };
        }
        if (fields[key].defaultValueType === 'TNK_DUE_DATE') {
            newField.valueInputConfiguration.valueInputOptionType = 'SPECIAL_FIELD';
            newField.valueInputConfiguration.definition = { id: 'TNK_DUE_DATE', name: 'Due Date' };
        }
        return newField;
    }

    $scope.getIntegrationType = function () {
        return $scope.data.selectedProjectIntegration
            ? $scope.data.selectedProjectIntegration.integrationType
            : $scope.data.configuredLogic.node.integrationType;
    };

    $scope.createNewField = function (selectedFieldForCreationIndex) {
        $scope.data.selectedFieldForCreationIndex = selectedFieldForCreationIndex;
        $rootScope.$broadcast('createNewField', [
            $scope.data.groupId,
            'COLUMN',
            null,
            null,
            true,
            false,
            null,
            $scope.onCreateDefModalClosed,
            $scope.onCreateDefModalClosed,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.data.workflowVersionId,
        ]);
    };

    /**
     * Once the create\edit modal is closed, we update the collections so that field selection will contain the new one.
     */
    $scope.onCreateDefModalClosed = function (newField) {
        if (!utils.isNullOrUndefined($scope.data.selectedFieldForCreationIndex)) {
            updateInputSelection($scope.data.selectedFieldForCreationIndex, newField);
        }

        // Get latest fields.
        groupInfoManager.getGroup($scope.data.groupId, true).then(function () {
            $scope.data.reloadInputSelectorOptions = true;
            $timeout(function () {
                $scope.data.reloadInputSelectorOptions = false;
            });
            $scope.onActionsChanged(false);
        });
    };

    /**
     * Opens the add new integration modal.
     */
    $scope.openAddNewIntegrationModal = function () {
        if (ctrl.openAddNewIntegrationModal) {
            ctrl.openAddNewIntegrationModal();
        }
    };

    $scope.filterDynamicFields = function (fields) {
        return fields.filter((field) => !$scope.fieldInDynamicFields(field));
    };

    /**
     * @param field The field to check whether it's in the dynamic fields list
     * @param uniqueFieldIdentifier {string | undefined?} the unique identifier field name to identify the unique field (for example, "id")
     * @returns {boolean}
     */
    $scope.fieldInDynamicFields = function (field, uniqueFieldIdentifier) {
        const fieldIdentifiersToUse = uniqueFieldIdentifier
            ? [uniqueFieldIdentifier]
            : ['name', 'label', 'displayName'];
        // List of possible names representing the field to check, filtering out undefined
        const fieldNames = fieldIdentifiersToUse.map((fieldIdentifier) => field[fieldIdentifier]).filter(Boolean);
        const fieldInDynamicFields = $scope.data.dynamicFields.some(({ selectedField }) => {
            // List of possible names representing a field that has been already selected, filtering out undefined
            const existingNames = new Set(
                fieldIdentifiersToUse.map((fieldIdentifier) => selectedField?.[fieldIdentifier]).filter(Boolean),
            );
            // Try to match at least on name in the existing field names list with a name from the list of names of the field to check
            return fieldNames.some((name) => existingNames.has(name));
        });

        return fieldInDynamicFields;
    };

    /**
     * Checks if a field is already being used in the logic
     * @param field - the field to check
     * @returns {boolean} false for all fields already in use, otherwise true
     */
    $scope.fieldInspectFilter = (field) => {
        const updateField =
            $scope.data.actionsByIntegrationsMap?.[$scope.data.selectedProjectIntegration?.integrationType]
                ?.updateField?.[$scope.data.selectedAction?.definition?.entity?.entity];

        if (updateField?.fieldFilter) {
            return updateField.fieldFilter(field);
        }

        return !$scope.fieldInDynamicFields(field, updateField?.uniqueFieldIdentifier);
    };

    $scope.navigateToActionInformationTabWithReturnTo = function () {
        $state.go('product.projectIntegrationActionManager', {
            tab: 'general',
            projectIntegrationActionId: $scope.data.selectedProjectIntegrationAction.id,
            projectIntegrationId: $scope.data.selectedProjectIntegration.id,
            fromName: projectManager.groupsMap[$scope.data.groupId].name,
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
        });
    };

    $scope.getEntityToRunOnName = function () {
        return $scope.data.selectedProjectIntegration?.integrationType === IntegrationType.WEBHOOK
            ? $scope.data.selectedProjectIntegration?.displayName
            : $scope.data.selectedAction.definition.entity.displayName;
    };

    $scope.getActionDefinitionEntityName = function () {
        return $scope.data.selectedProjectIntegration?.integrationType === IntegrationType.WEBHOOK
            ? $scope.data.selectedProjectIntegration?.displayName
            : $scope.data.selectedAction?.definition?.entity?.displayName;
    };

    function updateInputSelection(index, newField) {
        $scope.data.dynamicFields[$scope.data.selectedFieldForCreationIndex] = {
            mandatoryField: $scope.data.dynamicFields[$scope.data.selectedFieldForCreationIndex].mandatoryField,
            selectedField: $scope.data.dynamicFields[$scope.data.selectedFieldForCreationIndex].selectedField,
            valueInputConfiguration: {
                definition: {
                    id: newField.id,
                    name: newField.name,
                    value: undefined,
                    valueInputOptionType: 'FIELD_DEFINITION',
                },
            },
        };
    }

    function addActionFields() {
        // Required fields
        for (const key in $scope.data.selectedAction.definition.entity.requiredFields) {
            if ($scope.data.selectedAction.definition.entity.requiredFields.hasOwnProperty(key)) {
                $scope.data.dynamicFields.push(
                    generateField($scope.data.selectedAction.definition.entity.requiredFields, key, true, null),
                );
            }
        }

        // Optional fields
        for (const key in $scope.data.selectedAction.definition.entity.optionalFields) {
            if ($scope.data.selectedAction.definition.entity.optionalFields.hasOwnProperty(key)) {
                $scope.data.dynamicFields.push(
                    generateField($scope.data.selectedAction.definition.entity.optionalFields, key, false, null),
                );
            }
        }

        // If fields empty init with empty one
        if ($scope.data.dynamicFields.length === 0) {
            $scope.addNewDynamicFieldCreation();
        }
    }

    /**
     * Adds custom fields to integration's available fields.
     */
    $scope.addEntityFieldMetadataToCustomFields = function (fieldOptions, projectIntegration, externalType) {
        return $scope.data.entityFieldMetadata;
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'PerformIntegrationActionLogicConfigurationCtrl',
        lateConstructController(PerformIntegrationActionLogicConfigurationCtrl),
    );
