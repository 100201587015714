<div class="common-size-xs padding-top-xs">
    <strong>Your team's details:</strong>
</div>
<hr class="margin-top" />

<!-- Team name -->
<div class="row common-size-xs flex-vmiddle">
    <strong class="col-sm-3 text-right flex-no-shrink">Team name:</strong>
    <div class="col-sm-9">
        <input
            type="text"
            class="form-control inline-block setup-group-input-width"
            ng-model="valueResults['TEAM_NAME']"
            placeholder="Team name..."
            required
        />
    </div>
</div>

<!-- Team leader -->
<div class="row common-size-xs margin-top flex-vmiddle">
    <strong class="col-sm-3 text-right">Team leader:</strong>
    <div
        class="col-sm-9"
        ng-init="selectedPeople = [as.currentUser]; valueResults['INITIATIVES_OWNER_ID'] = selectedPeople.length ? selectedPeople[0].id : null;"
    >
        <div class="flex-vmiddle">
            <tnk-deprecated-people-selector
                class="flex-no-shrink setup-group-input-width"
                no-validation="false"
                selected-people="selectedPeople"
                on-tag-added="
                    selectedPeople.length ? (valueResults['INITIATIVES_OWNER_ID'] = selectedPeople[0].id) : null
                "
                max-tags="1"
                placeholder="Type a name or email"
            ></tnk-deprecated-people-selector>
        </div>
    </div>
</div>

<div class="row common-size-xs margin-top flex-vmiddle flex-xs-wrap" ng-init="valueResults['DATE_RANGE'] = 'Quarter'">
    <strong class="col-sm-3 text-right flex-no-shrink margin-bottom-xs">Track forecast by:</strong>
    <div class="col-sm-9 common-btn-input-height flex-grow flex-vmiddle margin-bottom-xs">
        <!-- Date range (month/quarter) -->
        <select
            class="col-sm-9 form-control inline-block common-width-auto margin-right"
            ng-model="valueResults['DATE_RANGE']"
            required
        >
            <option value="Quarter">Quarter</option>
            <option value="Month">Month</option>
        </select>

        <!-- Currency (display format dropdown) -->
        <tnk-display-format-selector
            default-display-format="dollar"
            field-type-filter="'Number'"
            selected-format-api-name="valueResults['DISPLAY_FORMAT_API_NAME']"
            selected-format-prefix="valueResults['DISPLAY_FORMAT_PREFIX']"
            selected-format-postfix="valueResults['DISPLAY_FORMAT_POSTFIX']"
            on-display-format-selected="onDisplayFormatSelected(selectedDisplayFormat, skipAnalytics)"
            field-type-filter="CURRENCY"
            disable-selection="posting"
        ></tnk-display-format-selector>
    </div>
</div>

<hr class="margin-top" />

<!-- Team members + quota -->
<div class="margin-top-lg">
    <div class="common-size-xs margin-bottom">
        <strong>Enter the Sales Reps names and quota:</strong>
    </div>

    <div ng-init="addDefaultsToInitiativesResults(variable('INITIATIVES_OWNER_ID'))">
        <div ng-repeat="initiative in initiativesResults" class="margin-bottom">
            <!-- Sales rep name -->
            <tnk-people-names-autocomplete
                classes="form-control inline-block common-width-auto common-input-only-line common-bg mod-transparent margin-right"
                model="initiative.title"
                is-required="$first ? (!initiative.title || !initiative.title.length) : false"
                placeholder="Sales rep full name"
                exclude-me="false"
            ></tnk-people-names-autocomplete>

            <!-- Sales rep quota -->
            <input
                type="text"
                class="
                    form-control
                    inline-block
                    common-width-auto common-input-only-line common-bg
                    mod-transparent
                    margin-right
                "
                ng-model="initiative.fields['FIELD_KEY_QUOTA']"
                placeholder="Quota"
                ng-required="$first ? (!initiative.fields['FIELD_KEY_QUOTA'] || !initiative.fields['FIELD_KEY_QUOTA'].length) : false"
                pattern="^[0-9]+([,\.][0-9]+)*$"
                title="This field should contain a valid number."
            />

            <!-- Delete button -->
            <button
                type="button"
                class="btn btn-xs btn-no-border common-no-outline flex-no-shrink"
                ng-click="removeFromInitiativesResults($index)"
                tabindex="-1"
                uib-tooltip="Remove sales rep"
                tooltip-placement="top"
            >
                <span class="svg-icon-xs common-color-grey">
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </span>
            </button>
        </div>
        <!-- Add button -->
        <button
            type="button"
            class="btn btn-secondary btn-sm common-color-white margin-top"
            ng-click="addToInitiativesResults(1, variable('INITIATIVES_OWNER_ID'))"
        >
            <i class="fa fa-plus-circle margin-right-xs common-no-outline"></i>
            <strong>Add sales rep</strong>
        </button>
    </div>
</div>
