import { useMemo } from 'react';

const useGridDataIsLoading = (
    loading: boolean,
    isFetched: boolean,
    fieldDefinitionsLoading: boolean,
    isInitialized: boolean,
) => {
    return useMemo(
        () => loading || !isFetched || fieldDefinitionsLoading || !isInitialized,
        [loading, isFetched, fieldDefinitionsLoading, isInitialized],
    );
};

export default useGridDataIsLoading;
