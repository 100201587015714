import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

const useEnrichServerResponseEntity = () => {
    const entityHelper = useAngularService('entityHelper');

    return useCallback(
        (entity: any) => {
            return entityHelper.enrichObj(entity);
        },
        [entityHelper],
    );
};

export default useEnrichServerResponseEntity;
