/**
 * Created by ShaLi on 5/13/15.
 */

function bigNumber() {
    return function (number) {
        if (!angular.isNumber(number)) {
            return number;
        } else if (number < 1000) {
            return number;
        } else if (number < 10_000) {
            return `${Math.floor(number / 100) / 10}K`;
        } else if (number < 1_000_000) {
            return `${Math.floor(number / 1000)}K`;
        } else if (number < 10_000_000) {
            return `${Math.floor(number / 100_000) / 10}M`;
        } else {
            // bigger than 10M
            return `${Math.floor(number / 1_000_000)}M`;
        }
    };
}
export default angular.module('tonkean.app').filter('bigNumber', bigNumber);
