import { useFormikContext } from 'formik';
import type { FormikErrors, FormikTouched } from 'formik/dist/types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import compliedGet from './compliedGet';
import FormikHelpersContext from './FormikHelpersContext';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

interface Watcher<T> {
    onFieldValueChangeWatcher?: () => void;
    currentValue: T;
    onFieldErrorChangeWatcher?: () => void;
    currentError: FormikErrors<{ field: T }>['field'];
    onFieldTouchedChangeWatcher?: () => void;
    currentTouched: FormikTouched<{ field: T }>['field'];
    field: string;
}
const FormikHelpers: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const formik = useFormikContext<any>();
    const getFormik = useConstantRefCallback(() => {
        return formik;
    });

    const getFieldValue = useCallback(
        <T,>(field: string): T => {
            return compliedGet(field)(getFormik().values);
        },
        [getFormik],
    );
    const getFieldError = useCallback(
        <T,>(field: string): FormikErrors<{ field: T }>['field'] => {
            return compliedGet(field)(getFormik().errors);
        },
        [getFormik],
    );
    const getFieldTouched = useCallback(
        <T,>(field: string): FormikTouched<{ field: T }>['field'] => {
            return compliedGet(field)(getFormik().touched);
        },
        [getFormik],
    );

    const watchersRef = useRef<Watcher<any>[]>([]);

    const addWatcher = useCallback(
        <T,>(
            field: string,
            onFieldValueChangeWatcher: (() => void) | undefined,
            onFieldErrorChangeWatcher: (() => void) | undefined,
            onFieldTouchedChangeWatcher: (() => void) | undefined,
        ) => {
            const newWatcher: Watcher<T> = {
                onFieldValueChangeWatcher,
                currentValue: getFieldValue(field),
                onFieldErrorChangeWatcher,
                currentError: getFieldError(field),
                onFieldTouchedChangeWatcher,
                currentTouched: getFieldTouched(field),
                field,
            };
            watchersRef.current = [...watchersRef.current, newWatcher];

            return () => {
                watchersRef.current = watchersRef.current.filter((watcher) => watcher !== newWatcher);
            };
        },
        [getFieldError, getFieldTouched, getFieldValue],
    );

    const addValueWatcher = useCallback(
        (field: string, watcher: () => void) => {
            return addWatcher(field, watcher, undefined, undefined);
        },
        [addWatcher],
    );
    const addErrorWatcher = useCallback(
        (field: string, watcher: () => void) => {
            return addWatcher(field, undefined, watcher, undefined);
        },
        [addWatcher],
    );
    const addTouchedWatcher = useCallback(
        (field: string, watcher: () => void) => {
            return addWatcher(field, undefined, undefined, watcher);
        },
        [addWatcher],
    );

    useEffect(() => {
        watchersRef.current.forEach((watcher) => {
            const value = compliedGet(watcher.field)(formik.values);
            if (value !== watcher.currentValue) {
                watcher.onFieldValueChangeWatcher?.();
                watcher.currentValue = value;
            }
        });
    }, [formik.values]);

    useEffect(() => {
        watchersRef.current.forEach((watcher) => {
            const touched = compliedGet(watcher.field)(formik.touched);
            if (touched !== watcher.currentTouched) {
                watcher.onFieldTouchedChangeWatcher?.();
                watcher.currentTouched = touched;
            }
        });
    }, [formik.touched]);

    useEffect(() => {
        watchersRef.current.forEach((watcher) => {
            const error = compliedGet(watcher.field)(formik.errors);
            if (error !== watcher.currentError) {
                watcher.onFieldErrorChangeWatcher?.();
                watcher.currentError = error;
            }
        });
    }, [formik.errors]);

    const contextValue = useMemo(
        () => ({
            addValueWatcher,
            addErrorWatcher,
            addTouchedWatcher,
            getFieldValue,
            getFieldError,
            getFieldTouched,
            getFormik,
        }),
        [addValueWatcher, addErrorWatcher, addTouchedWatcher, getFieldError, getFieldTouched, getFieldValue, getFormik],
    );

    return <FormikHelpersContext.Provider value={contextValue}>{children}</FormikHelpersContext.Provider>;
};

export default FormikHelpers;
