<div>
    <div class="padding-normal-sm-h margin-bottom flex-vmiddle" data-automation="{{ data.fieldType }}-group-fields-metadata-open-fields-group">
        <div
            class="pointer flex-grow common-bold"
            ng-click="onHideFieldsClick()"
            data-automation="group-fields-metadata-open-fields-group"
        >
            {{ getFieldsGroupTitleName() }}
            <!-- Field counter -->
            <span ng-if="data.fieldFilterByName !== '' || !!data.selectedFieldDefinitionForDependencies">
                ({{
                    (
                        data.filteredCustomFields
                        | filter
                            : {
                                  entityMappingKey: 'CUSTOM',
                                  idRelationField: false,
                                  type: '!TNK_COLUMN_FORMULA'
                              }
                    ).length || '0'
                }}/{{
                    (
                        data.columns.fieldDefinitions
                        | filter
                            : {
                                  entityMappingKey: 'CUSTOM',
                                  idRelationField: false,
                                  type: '!TNK_COLUMN_FORMULA'
                              }
                    ).length || '0'
                }})
            </span>

            <span ng-if="data.fieldFilterByName === '' && !data.selectedFieldDefinitionForDependencies">
                ({{
                    (
                        data.filteredCustomFields
                        | filter
                            : {
                                  entityMappingKey: 'CUSTOM',
                                  idRelationField: false,
                                  type: '!TNK_COLUMN_FORMULA'
                              }
                    ).length || '0'
                }})
            </span>

            <i class="margin-left" ng-class="!data.showYourFields ? 'chevron-right' : 'chevron-bottom'"></i>
        </div>
        <button
            data-ng-disabled="!data.isDraft"
            ng-click="data.canModifyBot() && data.onFieldSelected(data.groupId, 'COLUMN', null, null, true, false, null, data.reloadFields, data.reloadFields, null, null, data.fieldType.toLowerCase(), null, null, null, null, null, null, data.workflowVersionId);"
            class="btn-link"
            data-automation="group-fields-meta-data-table-add-custom-field"
        >
            <span tooltip-enable="!data.isDraft" uib-tooltip="{{ data.cantEditInProductionEnvironmentMessage }}">
                + Add Field
            </span>
        </button>
    </div>

    <!-- Custom fields -->
    <table class="table table-striped margin-bottom" ng-show="data.showYourFields">
        <tr
            ng-repeat="definition in (data.filteredCustomFields | filter:{entityMappingKey: 'CUSTOM', idRelationField: false, type: '!TNK_COLUMN_FORMULA'}) | orderBy:'name'"
        >
            <td class="padding-normal-xs flex-vmiddle">
                <span class="margin-right">
                    <span ng-if="definition.definitionSource === 'MANUAL'" class="opacity-80">
                        <tnk-field-type-icon field-type="definition.fieldType"></tnk-field-type-icon>
                    </span>

                    <!-- Integration Icon -->
                    <i
                        ng-if="definition.definitionSource !== 'MANUAL'"
                        class="initiative-integration-icon mod-dropdown margin-none"
                        ng-class="
                            definition.projectIntegration && !definition.projectIntegration.iconUrl
                                ? 'mod-' + definition.projectIntegration.integration.integrationType.toLowerCase()
                                : null
                        "
                        ng-style="{
                            background:
                                definition.projectIntegration && definition.projectIntegration.iconUrl
                                    ? 'url(' + definition.projectIntegration.iconUrl + ') no-repeat center'
                                    : null
                        }"
                    ></i>
                </span>
                <span class="common-break-long" data-automation="custom-field-option-{{definition.name}}">
                    <tnk-highlightable-text
                        highlight-text="data.fieldFilterByName"
                        data-automation="group-fields-metadata-table-custom-field-option-field"
                        text="definition.name"
                    ></tnk-highlightable-text>
                </span>
                <span
                    ng-if="definition.type === 'TNK_COLUMN_FORMULA'"
                    class="common-color-grey margin-left common-italic"
                >
                    (Formula)
                </span>
            </td>

            <td class="text-right">
                <!-- three dots button -->
                <div class="flex flex-vmiddle margin-top-minus-xxs float-options-right">
                    <!-- Edit button -->
                    <a
                        class="margin-bottom-xxs"
                        data-automation="group-field-meta-data-table-edit-button-{{ definition.name }}"
                        ng-click="data.onFieldSelected(data.groupId, definition.targetType, definition.type, definition.projectIntegration, false, false, definition, data.reloadFields, data.reloadFields, null, null, null, null, null, null, null, null, null, data.workflowVersionId);"
                    >
                        {{ data.workflowVersion.workflowVersionType === 'DRAFT' ? 'Edit' : 'View' }}
                    </a>

                    <!-- List options -->
                    <div uib-dropdown dropdown-append-to-body>
                        <!-- List options button -->
                        <div
                            class="
                                svg-icon-smd
                                margin-left-xs
                                common-color-grey
                                svg-icon-hover-primary svg-icon-align-text-base
                                btn-on-hover
                                pointer
                                flex-no-shrink
                            "
                            uib-dropdown-toggle
                            ng-click="data.dropdownFieldOpened(definition)"
                        >
                            <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                        </div>

                        <!-- List options menu -->
                        <ul class="field-option-select" uib-dropdown-menu>
                            <li>
                                <div
                                    ng-click="data.applyFieldDependenciesFilter(definition)"
                                    class="flex-vmiddle margin-right"
                                >
                                    <tnk-icon
                                        class="margin-top-xxs margin-right"
                                        src="/images/icons/columns.svg"
                                    ></tnk-icon>
                                    <div class="common-size-xxxxs">Filter Dependent Fields</div>
                                    <div
                                        class="common-size-xxxs margin-left-xs"
                                        ng-if="!data.loadDependenciesDropdownList"
                                    >
                                        ({{ data.fieldDefinitionDependenciesCounter }})
                                    </div>
                                    <i
                                        ng-if="data.loadDependenciesDropdownList"
                                        class="margin-left-xs loading-small"
                                    ></i>
                                </div>
                            </li>
                            <li>
                                <div
                                    ng-click="data.applyTriggerDependenciesFilter(definition)"
                                    class="flex-vmiddle margin-right"
                                >
                                    <tnk-icon
                                        class="margin-top-xxs margin-right margin-left-xxxs"
                                        src="/images/icons/lightning.svg"
                                    ></tnk-icon>
                                    <div class="common-size-xxxxs margin-left-field-option">
                                        Filter Dependent Triggers
                                    </div>
                                    <div
                                        class="common-size-xxxs margin-left-xs"
                                        ng-if="!data.loadDependenciesDropdownList"
                                    >
                                        ({{ data.triggerDependenciesCounter }})
                                    </div>
                                    <i
                                        ng-if="data.loadDependenciesDropdownList"
                                        class="margin-left-xs loading-small"
                                    ></i>
                                </div>
                            </li>
                            <li ng-if="data.workflowVersion.workflowVersionType === 'DRAFT'">
                                <div
                                    ng-click="data.openDuplicateFieldModal(definition)"
                                    class="flex-vmiddle margin-right"
                                >
                                    <tnk-icon
                                        class="margin-top-xxs margin-right"
                                        src="/images/icons/duplicate-new.svg"
                                    ></tnk-icon>
                                    <div class="common-size-xxxxs">Duplicate Field</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</div>
