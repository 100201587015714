import React from 'react';
import styled from 'styled-components';

import type UserMenuSectionConfig from '../../entities/UserMenuSectionConfig';

import { MenuItem } from '@tonkean/infrastructure';

const UserMenuItemWrapper = styled(MenuItem)`
    display: flex;
    align-items: center;
    padding-right: 24px;
`;

interface Props {
    setting: UserMenuSectionConfig;
}

const UserMenuItem: React.FC<Props> = ({ setting }) => {
    return (
        <UserMenuItemWrapper
            onClick={setting.onClick}
            state={setting.state}
            params={setting.params}
            data-automation="user-menu-item-options"
            data-automation-setting-name={setting.name}
            icon={<setting.icon />}
        >
            {setting.name}
        </UserMenuItemWrapper>
    );
};

export default UserMenuItem;
