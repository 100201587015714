import React, { useContext } from 'react';

import FormulaInnerRoundedBox from './FormulaInnerRoundedBox';
import { Comma, Plus } from './FormulaOperatorWrapper';
import FormulaContext from '../../entities/FormulaContext';
import HighlightedNodesSource from '../../entities/HighlightedNodesSource';

import type { FormulaFieldsMetadata } from '@tonkean/tonkean-entities';
import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';

interface Props {
    depth: number;
    fieldsMetadata: FormulaFieldsMetadata;
    disabled: boolean;
    onAdd: (index: number) => void;
}

const FormulaOperatorArrayOperandsWrapper: React.FC<React.PropsWithChildren<Props>> = ({
    depth,
    fieldsMetadata,
    disabled,
    children,
    onAdd,
}) => {
    const { highlightedNodes, setHighlightedNodes } = useContext(FormulaContext);

    const markAsActive = () => {
        if (disabled) {
            return;
        }

        setHighlightedNodes({ source: HighlightedNodesSource.HOVER, arrayField: fieldsMetadata.arrayField });
    };

    const unmarkAsActive = () => {
        if (
            highlightedNodes?.source === HighlightedNodesSource.HOVER &&
            highlightedNodes.arrayField === fieldsMetadata.arrayField
        ) {
            setHighlightedNodes(undefined);
        }
    };

    const roundedBoxProps = {
        onMouseEnter: markAsActive,
        onMouseLeave: unmarkAsActive,
        faded: disabled || (highlightedNodes && highlightedNodes.arrayField !== fieldsMetadata.arrayField),
        nodeType: FormulaTreeNodeType.ARRAY,
        compact: true,
        depth,
    };

    return (
        <>
            {!children && <Comma faded={disabled || !!highlightedNodes} />}

            <FormulaInnerRoundedBox {...roundedBoxProps} right>
                [
            </FormulaInnerRoundedBox>

            {children || <Plus type="button" onClick={() => onAdd(fieldsMetadata.fieldsBeforeArray)} />}

            <FormulaInnerRoundedBox {...roundedBoxProps} left>
                ]
            </FormulaInnerRoundedBox>

            {!!fieldsMetadata.fieldsAfterArray && <Comma faded={disabled || !!highlightedNodes} />}
        </>
    );
};

export default FormulaOperatorArrayOperandsWrapper;
