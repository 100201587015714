import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import useSubmitEnterpriseComponentVariable from '../../hooks/useSubmitEnterpriseComponentVariable';

import { useFeatureFlag, useToastMessage } from '@tonkean/angular-hooks';
import {
    Field,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    SimpleSelect,
    Textarea,
    Toggle,
    withModal,
} from '@tonkean/infrastructure';
import type { EnterpriseComponentVariable } from '@tonkean/tonkean-entities';
import { EnterpriseComponentVariableValueType, ValueTypeOptions } from '@tonkean/tonkean-entities';
import { yupEnum } from '@tonkean/utils';

const StyledFieldWithBottomMargin = styled(Field)`
    margin-bottom: 30px;
`;

const EnterpriseComponentVariableValidationSchema = Yup.object({
    displayName: Yup.string().max(100).required('Component Variable must have internal name'),
    description: Yup.string().max(200),
    isRequired: Yup.boolean(),
    isEncrypted: Yup.boolean(),
    valueType: yupEnum(EnterpriseComponentVariableValueType),
});

interface Props {
    enterpriseComponentVariable?: EnterpriseComponentVariable;
    onSubmit: () => void;
}

const EnterpriseComponentVariablesCreateOrUpdateModal: React.FC<Props> = ({
    enterpriseComponentVariable,
    onSubmit: onSubmitProp,
}) => {
    const allowCreateInternalSecretTextValueType =
        useFeatureFlag('tonkean_feature_allow_create_internal_secret_text_enterprise_component_variable') || false;
    const [{ error, loading }, submitEnterpriseComponentVariable] = useSubmitEnterpriseComponentVariable(
        enterpriseComponentVariable?.id,
    );

    const emitToast = useToastMessage();

    const onSubmit = async (
        displayName: string,
        description: string,
        isRequired: boolean,
        isEncrypted: boolean,
        valueType: EnterpriseComponentVariableValueType,
    ) => {
        await submitEnterpriseComponentVariable(displayName, description, isRequired, isEncrypted, valueType);
        emitToast(`${displayName} ${enterpriseComponentVariable?.id ? 'updated' : 'created'} successfully`, 'success');
        onSubmitProp();
    };

    return (
        <Formik
            initialValues={{
                displayName: enterpriseComponentVariable?.displayName || '',
                description: enterpriseComponentVariable?.description || '',
                isRequired: enterpriseComponentVariable?.isRequired || false,
                isEncrypted: enterpriseComponentVariable?.isEncrypted || false,
                valueType: enterpriseComponentVariable?.valueType || EnterpriseComponentVariableValueType.TEXT,
            }}
            validationSchema={EnterpriseComponentVariableValidationSchema}
            onSubmit={(values) =>
                onSubmit(
                    values.displayName,
                    values.description,
                    values.isRequired,
                    values.isEncrypted,
                    values.valueType,
                )
            }
        >
            <ModalForm>
                <ModalHeader>{`${!!enterpriseComponentVariable ? 'Update' : 'Create New'} Parameter`}</ModalHeader>

                <ModalBody>
                    <StyledFieldWithBottomMargin label="Display Name">
                        <Input name="displayName" placeholder="Enter parameter display name" maxLength={200} />
                    </StyledFieldWithBottomMargin>

                    <StyledFieldWithBottomMargin label="Parameter description">
                        <Textarea
                            name="description"
                            placeholder="Enter parameter description"
                            maxLength={200}
                            minRows={3}
                        />
                    </StyledFieldWithBottomMargin>

                    {allowCreateInternalSecretTextValueType && (
                        <StyledFieldWithBottomMargin label="Parameter value type">
                            <SimpleSelect options={ValueTypeOptions} name="valueType" />
                        </StyledFieldWithBottomMargin>
                    )}

                    <StyledFieldWithBottomMargin label="Is Required?">
                        <Toggle name="isRequired" />
                    </StyledFieldWithBottomMargin>

                    <Field label="Should Encrypt?">
                        <Toggle name="isEncrypted" />
                    </Field>
                </ModalBody>

                <ModalFooterActions
                    loading={loading}
                    error={error}
                    saveLabel={enterpriseComponentVariable ? 'Save' : 'Create'}
                    saveDisabled={loading}
                />
            </ModalForm>
        </Formik>
    );
};

export default withModal(EnterpriseComponentVariablesCreateOrUpdateModal, { fixedWidth: true });
