import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo } from 'react';

import type { ItemWidgetProps } from '../../../../WidgetModule';
import type {
    ActionWidgetOpenFormActionData,
    ActionWidgetTriggerActionData,
} from '../../components/actionButtons/ActionWidgetActionData';
import useMatchedItemsActionButtons from '../../components/actionButtons/useMatchedItemsActionButtons';
import ActionsItemWidgetDisplay from '../../components/ActionsItemWidgetDisplay';
import { useIntakeSequenceActions } from '../hooks/useIntakeSequenceActions';
import useItemInterfaceActionData from '../hooks/useItemInterfaceActionData';

import { useAsyncMethod, useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import {
    type ActionsItemWidgetConfiguration,
    AutonomousSecondaryType,
    type CustomTrigger,
    CustomTriggerType,
    InterfaceCTAActionType,
    type InterfaceCTASavedAction,
    type TonkeanId,
    type TonkeanType,
} from '@tonkean/tonkean-entities';

const ItemInterfaceActionsItemWidget: React.FC<ItemWidgetProps<ActionsItemWidgetConfiguration>> = ({
    initiative,
    workflowVersionId,
    widget,
    permission,
}) => {
    const { reloadInitiativeFromServer, itemInterface, actionsDisabledLiveDisplay, actionsHiddenLiveDisplay } =
        useItemInterfaceContext();

    const project = useProject();

    const widgetSpecificActionsDisabledLiveDisplay: Record<InterfaceCTASavedAction['id'], boolean> = useMemo(() => {
        if (!actionsDisabledLiveDisplay) {
            return null;
        }
        const widgetEntries = Object.entries(actionsDisabledLiveDisplay)
            .filter(([key]) => key.startsWith(widget.id))
            .map(([key, value]) => [key.replace(`${widget.id}-`, ''), value]);
        return Object.fromEntries(widgetEntries);
    }, [actionsDisabledLiveDisplay, widget.id]);

    const formManager = useAngularService('formManager');

    const [{ data: allForms, loading: loadingForms }, getAllWorkerForm] = useLazyAsyncMethod(
        formManager,
        'getAllWorkerForm',
    );

    const customTriggerManager = useAngularService('customTriggerManager');

    const { groupIdToMatchedItemId, matchedItemsActionButtons } = useMatchedItemsActionButtons(
        itemInterface.workflowVersionId,
        initiative,
    );

    useEffect(() => {
        if ((widget.configuration.actions?.length ?? 0) > 0) {
            getAllWorkerForm(workflowVersionId);
        }
    }, [getAllWorkerForm, widget.configuration.actions?.length, workflowVersionId]);

    const shownForms: ActionWidgetOpenFormActionData[] = useMemo(() => {
        if (!allForms) {
            return [];
        }

        return allForms.map((form) => ({
            type: InterfaceCTAActionType.OPEN_FORM,
            id: form.id as TonkeanId<TonkeanType.FORM>,
            form,
            initiativeId: initiative?.id,
            defaultLabel: form.displayName,
        }));
    }, [allForms, initiative?.id]);

    const { data: customTriggersAndGraph, loading: loadingCustomTriggers } = useAsyncMethod(
        customTriggerManager,
        'getWorkflowVersionCustomTriggersAndGraph',
        workflowVersionId,
    );

    const allCustomTriggers: CustomTrigger[] | undefined = customTriggersAndGraph?.customTriggers;

    const showTriggerActions: ActionWidgetTriggerActionData[] = useMemo(() => {
        if (!allCustomTriggers || !initiative?.id) {
            return [];
        }

        return allCustomTriggers
            .filter((customTrigger) => {
                return (
                    customTrigger.customTriggerType === CustomTriggerType.AUTONOMOUS &&
                    customTrigger.customTriggerSecondaryType === AutonomousSecondaryType.USER_INTERFACE_BUTTON_CLICK
                );
            })
            .map((customTrigger) => ({
                id: customTrigger.id,
                type: InterfaceCTAActionType.TRIGGER_WORKFLOW,
                initiativeId: initiative?.id,
                customTriggerDisplayName: customTrigger.displayName,
                defaultLabel: customTrigger.displayName,
            }));
    }, [allCustomTriggers, initiative?.id]);

    const intakeSequenceActionButtons = useIntakeSequenceActions(
        widget.configuration.actions,
        initiative?.groupId,
        initiative?.id,
        groupIdToMatchedItemId,
    );

    const actionsData = useItemInterfaceActionData(
        widget,
        actionsHiddenLiveDisplay,
        shownForms,
        showTriggerActions,
        matchedItemsActionButtons,
        intakeSequenceActionButtons,
        initiative,
    );

    return (
        <ActionsItemWidgetDisplay
            actionsData={actionsData}
            loadingActions={loadingForms || loadingCustomTriggers}
            widget={widget}
            permission={permission}
            actionButtonColor={
                project.forceThemeConfiguration
                    ? project.themeConfiguration.primaryColor
                    : itemInterface.themeConfiguration.headerBackgroundColor
            }
            workflowVersionType={itemInterface.workflowVersionType}
            reloadInitiativeFromServer={reloadInitiativeFromServer}
            actionsDisabledLiveDisplay={widgetSpecificActionsDisabledLiveDisplay}
            groupId={itemInterface.group.id}
        />
    );
};

export default ItemInterfaceActionsItemWidget;
