<div class="extended-match-configuration">
    <div class="flex flex-vmiddle common-normal-weight">
        <!-- Workflow -->
        <div class="margin-top-xs margin-left-xs flex-no-shrink">
            <tnk-radio-button
                radio-id="workflow-input-{{ data.workflowVersionId }}-{{ data.uniqueId }}"
                radio-name="file-input-selection-{{ data.workflowVersionId }}-{{ data.uniqueId }}"
                radio-value="WORKFLOW"
                model="data.extendedMatchConfiguration.extendedMatchConfigurationType"
                on-click="selectExtendedMatchConfigurationType(onClickParam)"
                on-click-param="('WORKFLOW')"
                is-required="true"
            >
                <div>Workflow</div>
            </tnk-radio-button>
        </div>

        <!-- Expression -->
        <div class="margin-top-xs margin-left-xs flex-no-shrink">
            <tnk-radio-button
                radio-id="expression-input-{{ data.workflowVersionId }}-{{ data.uniqueId }}"
                radio-name="file-input-selection-{{ data.workflowVersionId }}-{{ data.uniqueId }}"
                radio-value="EXPRESSION"
                model="data.extendedMatchConfiguration.extendedMatchConfigurationType"
                on-click="selectExtendedMatchConfigurationType(onClickParam)"
                on-click-param="('EXPRESSION')"
                is-required="true"
            >
                <div>Expression</div>
            </tnk-radio-button>
        </div>

        <!-- Match -->
        <div class="margin-top-xs margin-left-xs flex-no-shrink">
            <tnk-radio-button
                radio-id="match-input-{{ data.workflowVersionId }}-{{ data.uniqueId }}"
                radio-name="file-input-selection-{{ data.workflowVersionId }}-{{ data.uniqueId }}"
                radio-value="MATCH"
                model="data.extendedMatchConfiguration.extendedMatchConfigurationType"
                on-click="selectExtendedMatchConfigurationType(onClickParam)"
                on-click-param="('MATCH')"
                is-required="true"
            >
                <div>Match</div>
            </tnk-radio-button>
        </div>
    </div>

    <!-- Input selection -->
    <div class="margin-left-xs margin-right">
        <!-- Expression input -->
        <tnk-tonkean-expression
            ng-if="data.extendedMatchConfiguration.extendedMatchConfigurationType === 'EXPRESSION'"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.logicId }}"
            placeholder="The {{
                data.entityMetadata && data.entityMetadata.displayName ? data.entityMetadata.displayName : 'item'
            }} id to operate on"
            saved-expression="data.extendedMatchConfiguration.uiExpression"
            on-tonkean-expression-changed="onExpressionChanged(expression, shouldSaveLogic)"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>

        <!-- Workflow input -->
        <tnk-workflow-item-selection
            data-automation="tnk-extended-match-configuration-template-click-workflow-tab"
            ng-if="data.extendedMatchConfiguration.extendedMatchConfigurationType === 'WORKFLOW'"
            perform-on-worker-item="data.extendedMatchConfiguration.matchConfiguration.matchConfiguration"
            creating-custom-trigger-id="data.extendedMatchConfiguration.matchConfiguration.creatingCustomTriggerId"
            id-relation-field-definition-id="data.extendedMatchConfiguration.matchConfiguration.idRelationFieldDefinitionId"
            entity-metadata="data.entityMetadata"
            entities="data.entities"
            entities-metadata="data.entitiesMetadata"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            current-custom-trigger="data.currentCustomTrigger"
            on-workflow-item-selection-changed="
                onWorkflowItemSelectionChanged(
                    performOnWorkerItem,
                    creatingCustomTriggerId,
                    idRelationFieldDefinitionId,
                    shouldSaveLogic
                )
            "
        ></tnk-workflow-item-selection>

        <!-- Match input -->
        <tnk-workflow-match-by-configuration
            ng-if="data.extendedMatchConfiguration.extendedMatchConfigurationType === 'MATCH'"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            entity-metadata="data.entityMetadata"
            project-integration="data.projectIntegration"
            match-configuration="data.extendedMatchConfiguration.matchConfiguration"
            on-workflow-match-by-field-changed="
                onWorkflowMatchByFieldChanged(
                    entityMatchField,
                    workerItemMatchFieldDefinition,
                    workerItemMatchSpecialField,
                    shouldSaveLogic
                )
            "
            on-workflow-match-query-changed="onWorkflowMatchQueryChanged(selectionObject, shouldSaveLogic)"
            preview-evaluation-source="data.previewEvaluationSource"
            mod-fixed-width="data.modFixedWidth"
            tabs-field-selector-mod-narrow="data.tabsFieldSelectorModNarrow"
        ></tnk-workflow-match-by-configuration>
    </div>

    <div ng-if="data.validationObject.cantBeRootMatch" class="common-error">
        {{ data.validationObject.cantBeRootMatch }}
    </div>
</div>
