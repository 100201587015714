import { useEffect, useState } from 'react';

import type { TaggableEntityItem } from '../entities';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

const useGetTaggableIntakeCustomTriggers = (
    workflowVersionType?: WorkflowVersionType,
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>,
) => {
    const [{ loading: loadingIntakeCustomTrigger }, getIntakeCustomTriggerInterfacesByInitiativeID] =
        useLazyTonkeanService('getIntakeCustomTriggerInterfacesByInitiativeID');

    const [taggableCustomTriggerInterfaces, setTaggableCustomTriggerInterfaces] = useState<
        TaggableEntityItem[] | undefined
    >(undefined);

    useEffect(() => {
        let taggableCustomTriggerInterfacesData: TaggableEntityItem[] | undefined = taggableCustomTriggerInterfaces;
        const fetchInterfaceEntities = async () => {
            const data = await getIntakeCustomTriggerInterfacesByInitiativeID(
                initiativeId as TonkeanId<TonkeanType.INITIATIVE>,
                workflowVersionType as WorkflowVersionType,
            );
            taggableCustomTriggerInterfacesData = data.entities?.map((interfaceCustomTrigger) => ({
                id: `${interfaceCustomTrigger.customTriggerId}-${interfaceCustomTrigger.initiativeId}`,
                customTriggerId: interfaceCustomTrigger.customTriggerId,
                display: interfaceCustomTrigger.customTriggerDisplayName,
                initiativeId: interfaceCustomTrigger.initiativeId,
                groupId: interfaceCustomTrigger.groupId,
            }));
            setTaggableCustomTriggerInterfaces(taggableCustomTriggerInterfacesData);
        };
        if (!!initiativeId && !!workflowVersionType && !taggableCustomTriggerInterfaces) {
            fetchInterfaceEntities();
        }
    }, [
        getIntakeCustomTriggerInterfacesByInitiativeID,
        initiativeId,
        taggableCustomTriggerInterfaces,
        workflowVersionType,
    ]);

    return { taggableCustomTriggerInterfaces, loadingIntakeCustomTrigger };
};

export default useGetTaggableIntakeCustomTriggers;
