import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

class EvaluateTonkeanExpressionFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.EVALUATE_TONKEAN_EXPRESSION;
    override readonly sign = 'EvaluateTonkeanExpression';
    override readonly displayName = 'Evaluate Tonkean Expression';
    override readonly description = 'Will evaluate a tonkean expression';
    override readonly dataType = FieldType.String;

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Expression',
            dataType: [FieldType.String],
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            displayName: 'Dependency',
            pluralName: 'dependencies',
            minRepeats: 1,
            defaultRepeats: 1,
            generator: (repeatIndex) => ({
                fieldDefinitionType: FieldDefinitionType.SINGLE,
                displayName: `Dependency ${repeatIndex}`,
                dataType: undefined,
            }),
        },
    ];
}

export default EvaluateTonkeanExpressionFunction;
