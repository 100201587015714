import React, { useEffect, useMemo, useState } from 'react';

import type { InterfaceTab } from '@tonkean/tonkean-entities';
import { InterfaceQuickNavigationMode, type TonkeanId, type TonkeanType } from '@tonkean/tonkean-entities';

export type InterfaceQuickNavigationSelectedWidgetId = InterfaceTab['key'] | undefined;

type InterfaceQuickNavigationContextType = {
    quickNavigationMode: InterfaceQuickNavigationMode;
    selectedWidgetId?: InterfaceQuickNavigationSelectedWidgetId;
    setSelectedWidgetId?: (widgetId: InterfaceQuickNavigationSelectedWidgetId) => void;
    tabs: InterfaceTab[] | undefined;
    tabIdByWidgetId?: Record<TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>, string>;
};

const InterfaceQuickNavigationContext = React.createContext<InterfaceQuickNavigationContextType>({
    quickNavigationMode: InterfaceQuickNavigationMode.STANDARD,
    tabs: undefined,
});

export const useBuildInterfaceQuickNavigationContext = (
    quickNavigationMode: InterfaceQuickNavigationMode,
    tabs: InterfaceTab[] | undefined,
) => {
    const firstTabKey = tabs?.[0]?.key;
    const [selectedWidgetId, setSelectedWidgetId] = useState<InterfaceQuickNavigationSelectedWidgetId>(firstTabKey);
    useEffect(() => {
        // In tabs mode, default to first tab if none selected
        if (quickNavigationMode === InterfaceQuickNavigationMode.TABS && !selectedWidgetId && firstTabKey) {
            setSelectedWidgetId(firstTabKey);
        }
    }, [quickNavigationMode, selectedWidgetId, firstTabKey]);

    const contextValue = useMemo(() => {
        const tabIdByWidgetId = tabs?.reduce((accumulated, current) => {
            current.widgets.forEach((widgetId) => (accumulated[widgetId] = current.key));
            return accumulated;
        }, {});

        return { quickNavigationMode, selectedWidgetId, setSelectedWidgetId, tabs, tabIdByWidgetId };
    }, [quickNavigationMode, selectedWidgetId, tabs]);

    return contextValue;
};

export default InterfaceQuickNavigationContext;
