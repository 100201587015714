import React from 'react';
import styled from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const EventPlaceholderContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        'avatar eventText'
        'avatar dateText'
        'content content';
    column-gap: 8px;
    row-gap: 8px;
    align-items: center;
    padding: 15px 24px 0;

    border: 1px solid ${Theme.colors.gray_200};
    border-radius: 4px;
    margin-top: 30px;
`;

const AvatarPlaceholder = styled(Placeholder)`
    grid-area: avatar;
`;

const EventTextPlaceholder = styled(Placeholder)`
    grid-area: eventText;
`;

const DateTextPlaceholder = styled(Placeholder)`
    grid-area: dateText;
`;

const Content = styled.div`
    grid-area: content;
    margin-left: 14px;
    margin-bottom: 8px;
    padding-bottom: 16px;
    padding-left: 22px;
`;

interface Props {
    rowCount: number;
}

const EventsLoadingState: React.FC<Props> = ({ rowCount = 5 }) => {
    return (
        <>
            {range(rowCount).map((index) => (
                <EventPlaceholderContainer data-automation="comments-events-loading-state-placeholder" key={index}>
                    <AvatarPlaceholder $width="28px" $height="28px" $circle />
                    <EventTextPlaceholder $width="30%" $height="20px" />
                    <DateTextPlaceholder $width="5%" $height="5px" />
                    <Content>
                        <Placeholder $width="100%" $height="80px" />
                    </Content>
                </EventPlaceholderContainer>
            ))}
        </>
    );
};

export default EventsLoadingState;
