import React, { useMemo } from 'react';

import type { CustomSelectorProps } from './ContractFieldsMapping';

import { SimpleSelect } from '@tonkean/infrastructure';
import { InterfaceIcon } from '@tonkean/svg';
import type { ItemInterface } from '@tonkean/tonkean-entities';

const ContractInterfacesSelector: React.FC<CustomSelectorProps<ItemInterface>> = ({
    onChange,
    selectedField,
    loading,
    options,
}) => {
    const processedOptions = useMemo(() => {
        return options.map((summary) => ({
            value: summary.id.toString(),
            label: summary.displayName,
            icon: <InterfaceIcon />,
        }));
    }, [options]);

    return (
        <SimpleSelect
            placeholder="Select an interface"
            isCreatable={false}
            options={processedOptions}
            isLoading={loading}
            getOptionValue={(option) => option.value}
            value={selectedField}
            onChange={onChange}
            isClearable={false}
            readOnly={loading}
            isDisabled={false}
        />
    );
};

export default ContractInterfacesSelector;
