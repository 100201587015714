import FunctionOperatorBase from './FunctionOperatorBase';
import { separatorCharsPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class OcrOutputContainsWordsFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.OCR_OUTPUT_CONTAINS_WORDS;
    override readonly sign = 'OcrOutputContainsWords';
    override readonly displayName = 'OCR Output Contains Words';
    override readonly description = 'Returns whether ocr output contains given words.';
    override readonly dataType = FieldType.Boolean;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'OCR output',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Words to find',
            explanation: 'Separated by given separator',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Ignore casing',
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            dataType: FieldType.Boolean,
            defaultValue: false,
        },
        {
            displayName: 'Separator',
            explanation: 'The separator used to separate words in the words array string.',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: ',',
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: separatorCharsPreset,
        },
    ];
}
