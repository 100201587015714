import { useEffect } from 'react';

/**
 * Debounces requests so only the latest will operate (in the given interval.
 * Don't forget to use useCallback for the callback
 *
 * @example
 * function Component() {
 *     const [value, setValue] = useState();
 *
 *     const triggerAlert = useCallback(() => {
 *         if (value.length) {
 *             alert(`Hello, ${value}!`);
 *         }
 *     }, [value]),
 *     useDebounce(triggerAlert, 100);
 *
 *     return (
 *         <input type="text" onChange={({target}) => setValue(target.value)} placeholder="Name" />
 *     );
 * }
 *
 * @param callback - memorized callback function to trigger when the time passes
 * @param interval - the amount of time, in milliseconds, to wait from the last callback change before triggering it
 */
export function useDebouncer(callback: () => void, interval: number = 100) {
    useEffect(() => {
        // Set a timeout that will fire the callback when the time passes
        const timeout = setTimeout(callback, interval);
        // Clear the existing interval if the callback reference changes,
        // and then set a new timeout.
        return () => clearTimeout(timeout);
    }, [callback, interval]);
}
