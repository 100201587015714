import template from './tnkViewFunctionPopover.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';
import { STATUSES } from '@tonkean/constants';

/**
 * A wrapping directive for viewFunctionPopoverTemplate.
 */
function tnkViewFunctionPopover() {
    return {
        restrict: 'E',
        scope: {
            initiative: '=initiative',
            placement: '@',
            isOpen: '=',
            viewOnlyMode: '<',
            solutionBusinessReportId: '=',
            isInSharedMode: '=',
        },
        template,
        controller: [
            '$scope',
            '$rootScope',
            'initiativeCache',
            function ($scope, $rootScope, initiativeCache) {
                $scope.statuses = STATUSES;
                $scope.pm = $rootScope.pm;
                $scope.trackHelper = $rootScope.trackHelper;
                $scope.itemMap = initiativeCache.getInitiativesCache();
                $scope.popover = {};

                $scope.$watch('initiative.id', function () {
                    if ($scope.initiative && $scope.initiative.id) {
                        $scope.item = $scope.trackHelper.getInitiativeFromCache($scope.initiative.id);
                    }
                });

                $scope.updateDescription = function (description) {
                    if (description || description === '') {
                        analyticsWrapper.track('Update description', { category: 'View Popover' });
                        $scope.trackHelper
                            .updateInitiativeDescription(
                                $scope.item.id,
                                description,
                                $scope.isInSharedMode,
                                $scope.solutionBusinessReportId,
                            )
                            .then(function () {
                                $scope.item = $scope.trackHelper.getInitiativeFromCache($scope.initiative.id);
                                $scope.popover.editDesc = false;
                            });
                    }
                };
            },
        ],
    };
}
angular.module('tonkean.app').directive('tnkViewFunctionPopover', tnkViewFunctionPopover);
