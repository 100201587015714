<section class="login-page mod-reverse">
    <!-- desktop view -->
    <div class="login-page-desktop-container">
        <div class="login-page-content">
            <div class="login-page-content-container mod-centered">
                <div class="flex flex-col login-page-content-box">
                    <h1 class="login-page-title">Your Mission Control Center</h1>
                    <div class="common-size-s margin-top">Where everything comes together.</div>
                    <div class="common-size-xs margin-top common-thin">
                        Monitor and manage your entire mission from one place.
                    </div>

                    <div class="common-size-xs margin-top common-thin">
                        Tonkean seamlessly combines information from different tools and data sources, layered with
                        context from your team.
                    </div>

                    <div class="common-size-xs margin-top margin-bottom-lg common-thin">
                        All gathered automatically and always up to date.
                    </div>

                    <div class="margin-top-lg margin-bottom flex-vmiddle mod-inline">
                        <button
                            class="btn login-btn mod-primary btn-lg common-bold"
                            data-automation="login-column-lets-get-start-button"
                            ng-click="close()"
                        >
                            Let's get started!
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-page-image-box">
            <div class="login-page-image-inner-box">
                <div class="login-page-image mod-columns"></div>
            </div>
        </div>
    </div>

    <!-- mobile view -->
    <div class="login-page-mobile-container">
        <div>
            <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
        </div>

        <div class="login-page-content">
            <div class="login-page-title margin-top-lg">All the information you need in one place.</div>
        </div>

        <div class="login-page-image-inner-box">
            <div class="login-page-image mod-columns"></div>
        </div>

        <div class="flex mod-justify-center margin-top">
            <button ng-click="close()" class="next-btn">Let's get started!</button>
        </div>
    </div>
</section>
