import DocuSignAuthenticationComponent from './DocuSignAuthenticationComponent';
import DocuSignSetupComponent from './DocuSignSetupComponent';
import docuSignIcon from '../../../../apps/tracks/images/docusign.svg';
import docuSignCircleIcon from '../../../../apps/tracks/images/integrations/docusign-circle.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class DocuSignIntegration extends IntegrationDefinitionBase {
    override name = 'docusign';
    override displayName = 'DocuSign';
    override description = '';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [
        new IntegrationEntity('Envelope', 'Envelopes'),
        new IntegrationEntity('Template', 'Templates'),
        new IntegrationEntity('User', 'Users'),
    ];
    override customizedAuthenticationComponent = DocuSignAuthenticationComponent;
    override customizedSetupComponent = DocuSignSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(docuSignCircleIcon, '130px'),
        new IntegrationIcon(docuSignIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: { Envelope: true, Template: true, User: true },
        isOnlyDataRetention: {},
    };
}

export default DocuSignIntegration;
