import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import ManageSolutionBusinessReportModal from './ManageSolutionBusinessReportModal';
import SolutionReportDeleteModal from './SolutionReportDeleteModal';
import SolutionReportInfoModal from './SolutionReportInfoModal';

import { KebabMenuButton } from '@tonkean/infrastructure';
import { Menu } from '@tonkean/infrastructure';
import { MenuItem } from '@tonkean/infrastructure';
import { useToggle } from '@tonkean/infrastructure';
import { TrashIcon } from '@tonkean/svg';
import { InfoIcon } from '@tonkean/svg';
import { PencilIcon } from '@tonkean/svg';
import type { SolutionBusinessReport } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const SettingsButton = styled(KebabMenuButton)`
    width: 12px;
`;

const StyledTrash = styled(TrashIcon)`
    rect {
        stroke: ${Theme.colors.error};

        &:first-of-type {
            fill: ${Theme.colors.error};
        }
    }
    path {
        fill: ${Theme.colors.error};
    }
`;

const StyledPencil = styled(PencilIcon)`
    path {
        fill: ${Theme.colors.gray_500};
    }
`;

interface Props {
    solutionBusinessReport: SolutionBusinessReport;
    onUpdate: (solutionBusinessReport: SolutionBusinessReport) => void;
    onDelete: () => void;
}

const SolutionReportMenu: React.FC<Props> = ({ solutionBusinessReport, onUpdate, onDelete }) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const [open, toggleOpen] = useToggle(false);

    const [manageReportModalOpen, toggleManageReportModalOpen] = useToggle(false);
    const [infoModalOpen, toggleInfoModalOpen] = useToggle(false);
    const [deleteModalOpen, toggleDeleteModalOpen] = useToggle(false);

    const workflowFolder = useMemo(() => {
        return workflowFolderManager.projectIdToFolderIdToFolderMap[solutionBusinessReport.projectId]?.[
            solutionBusinessReport.solutionId
        ];
    }, [
        solutionBusinessReport.projectId,
        solutionBusinessReport.solutionId,
        workflowFolderManager.projectIdToFolderIdToFolderMap,
    ]);

    return (
        <>
            <Menu
                show={open}
                onClose={() => toggleOpen()}
                placement="right-start"
                menuItems={
                    <>
                        <MenuItem icon={<InfoIcon />} onClick={() => toggleInfoModalOpen()}>
                            Info
                        </MenuItem>
                        <MenuItem icon={<StyledPencil />} onClick={() => toggleManageReportModalOpen()}>
                            Edit Report
                        </MenuItem>
                        <MenuItem icon={<StyledTrash />} onClick={() => toggleDeleteModalOpen()}>
                            Delete Report
                        </MenuItem>
                    </>
                }
            >
                <SettingsButton onClick={() => toggleOpen()} flat thin />
            </Menu>

            <SolutionReportDeleteModal
                solutionBusinessReport={solutionBusinessReport}
                open={deleteModalOpen}
                onClose={() => toggleDeleteModalOpen()}
                onDelete={onDelete}
            />

            <SolutionReportInfoModal
                solutionBusinessReport={solutionBusinessReport}
                open={infoModalOpen}
                onClose={() => toggleInfoModalOpen()}
            />

            <ManageSolutionBusinessReportModal
                workflowFolder={workflowFolder!}
                solutionBusinessReport={solutionBusinessReport}
                open={manageReportModalOpen}
                onClose={() => toggleManageReportModalOpen()}
                onSave={onUpdate}
            />
        </>
    );
};

export default SolutionReportMenu;
