<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Upload File From Storage Provider or Url -->
        <div
            ng-if="
                data.definition.customActionKey === 'UPLOAD_ATTACHMENT' ||
                data.definition.customActionKey === 'UPLOAD_ATTACHMENT_TO_TABLE'
            "
            class="margin-bottom-xlg"
        >
            <div ng-if="data.definition.customActionKey === 'UPLOAD_ATTACHMENT_TO_TABLE'">
                <!-- Table Name -->
                <div class="margin-top">
                    <div class="margin-bottom-xs">
                        Table Name:
                        <i
                            class="fa fa-info-circle common-color-primary margin-left-xs"
                            uib-tooltip="If empty, the 'Incident' table will be used."
                        ></i>
                    </div>

                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Table Name"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.tableNameExpression"
                        expression-unique-identifier="ServiceNowUploadTableName"
                        on-tonkean-expression-changed="onTableNameExpressionChanged(expression, shouldSaveLogic)"
                    ></tnk-tonkean-expression>
                </div>

                <!-- Record ID -->
                <div class="margin-top">
                    <div class="margin-bottom-xs">Record ID:</div>

                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Record ID"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.recordIdExpression"
                        expression-unique-identifier="ServiceNowUploadRecordId"
                        on-tonkean-expression-changed="
                            onRecordIdTableNameExpressionChanged(expression, shouldSaveLogic)
                        "
                    ></tnk-tonkean-expression>
                </div>
            </div>

            <tnk-worker-file-selection
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                custom-trigger-id="{{ data.configuredLogic.node.id }}"
                file-source-type="{{ data.definition.fileSourceType }}"
                storage-integration-id="{{ data.definition.storageIntegrationId }}"
                storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                should-display-delete-sign="false"
                url-expression="data.definition.url"
                extended-match-configuration="data.definition.extendedMatchConfiguration"
                worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
                worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
                parameters-values="data.definition.parametersValues"
                on-worker-file-selection-changed="
                    onWorkerFileSelectionChanged(
                        fileSourceType,
                        storageIntegrationId,
                        extendedMatchConfiguration,
                        urlExpression,
                        workerStaticAssetId,
                        workerStaticAssetDisplayName,
                        parametersValues,
                        shouldSaveLogic,
                        storageIntegrationActionId
                    )
                "
            ></tnk-worker-file-selection>

            <div
                ng-if="data.validationObject.invalidFileChoice"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.invalidFileChoice }}
            </div>

            <!-- New Attachment Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    New Attachment Name:
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="If empty, the original name will be used."
                    ></i>
                </div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Document name"
                    saved-original-expression="data.definition.newAttachmentNameExpression.originalExpression"
                    saved-evaluated-expression="data.definition.newAttachmentNameExpression.evaluatedExpression"
                    on-tonkean-expression-changed="
                        onNewAttachmentNameTonkeanExpressionChanged(
                            originalExpression,
                            evaluatedExpression,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>
    </div>
</div>
