<div class="modal-body" ng-show="
            data.integration  && !data.checkIfBotIsConnectedToTeamLoading && !data.selectedTeam
        ">
    <!--        Title + refresh button-->
    <div class="flex margin-bottom-xs">
        <div class="common-title-sm">Please select the team you wish to connect:</div>

        <div class="flex-vmiddle pointer common-color-primary margin-left margin-top-xxs" ng-click="loadAllTeams()">
                <span
                        ng-show="!data.loading"
                        class="svg-icon-md pointer pull-right"
                        uib-tooltip="Refresh"
                        tooltip-placement="bottom-right"
                >
                    <tnk-icon src="../../../../svg/src/icons/refresh.svg"></tnk-icon>
                </span>

            <div class="margin-left-xxs margin-top-xxxs">Refresh</div>
        </div>
    </div>

    <!-- Step 1 Team selection -->
    <div class="integration-instance-selection-box">
        <!-- Loading -->
        <div ng-if="data.teamsOptionsLoading" class="text-center margin-top-lg">
            <i class="loading-medium"></i>
        </div>


        <!-- Teams -->
        <div ng-if="!data.teamsOptionsLoading" class="common-height-full">

            <div ng-if="data.errorMessage" class="common-size-xxs common-color-danger text-center padding-top-lg">
                <div>
                    {{data.errorMessage}}
                </div>
                <div class="padding-top-xs">Please reauthenticate and try again.</div>
            </div>
            <div ng-if="data.teamsOptions && data.teamsOptions.length">
                <div
                        ng-click="onTeamClicked(selection)"
                        ng-repeat="selection in data.teamsOptions"
                        class="list-item btn-default pointer common-size-xxs flex-vmiddle"
                >
                    <div class="flex-grow">
                        <strong>{{ selection.displayName }}</strong>
                        <div class="common-size-xxxxs common-color-grey">
                            <span>{{ selection.description }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Bot Authorization   Step 2-->
<form novalidate>
    <div ng-if="data.selectedTeam">
        <!-- Setup instructions -->
        <div class="margin-bottom-md common-size-xxxs common-color-dark-grey margin-left-md margin-top">
            <div class="common-title-sm margin-bottom-8">Complete connection setup in Microsoft Teams</div>
            <div class="common-subtitle margin-bottom-8">{{data.selectedTeam.displayName}}</div>
            <div>
                <ol class="padding-left-md common-size-xxs common-thin">
                    <li class="margin-top-xs margin-bottom-8">Open the Tonkean application in the <a
                            ng-click="connect()()">Microsoft App Store.</a></li>
                    <li class="margin-top-xs margin-bottom-8">Click on “Get it now” and follow the prompts to Install
                        the Tonkean Application in Microsoft Teams.
                    </li>
                    <li class="margin-top-xs margin-bottom-8">When installation completes you should be redirected to
                        Microsoft Teams with the Tonkean App window open. If not you can also open it from the App Store
                        within Microsoft Teams.
                    </li>
                    <li class="margin-top-xs margin-bottom-8">
                        Click on the downward facing arrow next to the “open” button and select “Add to team”.
                    </li>
                    <li class="margin-top-xs margin-bottom-8">
                        Search for the <strong>{{data.selectedTeam.displayName}}</strong> team and complete the process
                        of adding the Tonkean app.
                    </li>
                </ol>
            </div>
        </div>

        <div class="margin-top-lg flex mod-align-center mod-justify-center flex-col">
            <div>
                <button
                        type="button"
                        class="btn btn-primary btn-lg microsoft-teams-i-did-it-btn margin-right-xs"
                        ng-click="checkIfBotConnectedToSelectedTeam()"
                >
                    I’ve completed the setup in Microsoft Teams
                </button>
            </div>

            <!-- Error message for when we can't find the integration -->
            <div
                    ng-if="data.connectBotToTeamAndTryAgain && data.selectedTeam && !data.checkIfBotIsConnectedToTeamLoading"
                    class="common-color-danger padding-bottom-lg margin-top"
            >
                It seems like team
                <span class="common-bold">"{{ data.selectedTeam.displayName }}"</span>
                is not connected to Tonkean.
                <br/>
                Please follow the steps above and try again.
            </div>
        </div>
        <div class="flex mod-align-center mod-justify-center margin-top">
            <div ng-if="data.checkIfBotIsConnectedToTeamLoading" class="flex-vmiddle">
                <div class="margin-right">
                    Checking if the Tonkean bot is connected to team
                    <span class="common-bold">"{{ data.selectedTeam.displayName }}"</span>
                </div>
                <div class="loading-medium"></div>
            </div>
        </div>
    </div>
</form>
