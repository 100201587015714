import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SearchTriggersCtrl(
    $scope,
    $q,
    $timeout,
    tonkeanService,
    projectManager,
    customTriggerManager,
    requestThrottler,
    utils,
    workflowVersionManager,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        canClear: ctrl.canClear,
        shouldSetDefaultTrigger: ctrl.shouldSetDefaultTrigger,
        defaultTriggersAmount: ctrl.defaultTriggersAmount,
        simplifiedTriggers: [],
        selectedSimplifiedTrigger: null,
        customTriggers: null,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        init();
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.groupId) {
            $scope.data.groupId = changesObj.groupId.currentValue;
            init();
        }
    };

    /**
     * Occurs once an trigger is selected.
     */
    $scope.onTriggerSelected = function (selectedSimplifiedTrigger, isInit) {
        $scope.data.selectedSimplifiedTrigger = selectedSimplifiedTrigger;

        if (ctrl.onTriggerSelected) {
            ctrl.onTriggerSelected({
                selectedSimplifiedTrigger: selectedSimplifiedTrigger ? selectedSimplifiedTrigger : { id: null },
                isInit,
            });
        }
    };

    /**
     * Search triggers using request throttler.
     */
    $scope.searchTriggersThrottled = function (searchText) {
        requestThrottler.do(
            'loadTriggers',
            $scope.data.defaultTriggersAmount ? $scope.data.defaultTriggersAmount : 350,
            () => searchTriggers(searchText),
            (triggers) => {
                // Map into simplified triggers, because ui-select tries to do deep equals which results in a circular reference and an exception.
                $scope.data.simplifiedTriggers = triggers.map((trigger) => {
                    return {
                        id: trigger.id,
                        title: trigger.displayName,
                    };
                });
            },
        );
    };

    /**
     * Clear selected trigger.
     */
    $scope.clearTrigger = function () {
        $scope.onTriggerSelected(null);
    };

    /**
     *  Stop propagation, so the side pane will not be closed.
     */
    $scope.stopPropagation = function (event) {
        event.stopPropagation();
    };

    /**
     * Searches for triggers using search text.
     */
    function searchTriggers(searchText) {
        $scope.data.errorLoadingTriggers = false;

        return $q.resolve(
            $scope.data.customTriggers.filter((customTrigger) => customTrigger.displayName.includes(searchText)),
        );
    }

    /**
     * Set selected trigger on init.
     */
    function init() {
        customTriggerManager
            .getWorkflowVersionCustomTriggersAndGraph(
                workflowVersionManager.getPublishedVersionFromCache($scope.data.groupId).id,
            )
            .then((customTriggersAndGraph) => {
                $scope.data.customTriggers = customTriggersAndGraph.customTriggers;
                $scope.data.customTriggersMap = utils.createMapFromArray(customTriggersAndGraph.customTriggers, 'id');

                let selectedTrigger = null;
                if (ctrl.selectedTriggerId) {
                    selectedTrigger = $scope.data.customTriggersMap[ctrl.selectedTriggerId];
                } else if ($scope.data.shouldSetDefaultTrigger) {
                    selectedTrigger = $scope.data.customTriggers[0];
                }

                if (selectedTrigger) {
                    const selectedSimplifiedTrigger = { id: selectedTrigger.id, title: selectedTrigger.displayName };
                    $scope.onTriggerSelected(selectedSimplifiedTrigger, true);
                }
            });
    }
}

export default angular.module('tonkean.app').controller('SearchTriggersCtrl', lateConstructController(SearchTriggersCtrl));
