import React, { useMemo } from 'react';
import styled from 'styled-components';

import PredefinedConnectionsTableRowsView from './PredefinedConnectionsTableRowsView';

import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    width: 100%;
`;

const TableHeader = styled.div`
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

interface Props {
    filterByTextValue: string;
    dataSourceConnection?: DataSourceConnection;
    isLoadingDataSourceConnection: boolean;
    onPredefinedConnectionUpdated(): void;
}

const PredefinedConnectionsTableView: React.FC<Props> = ({
    filterByTextValue,
    dataSourceConnection,
    isLoadingDataSourceConnection,
    onPredefinedConnectionUpdated,
}) => {
    // Filtered SharedCredentials by text.
    const filteredSharedCredentials = useMemo(() => {
        if (dataSourceConnection) {
            return dataSourceConnection.sharedCredentials.filter((predefinedConnectionRow) =>
                predefinedConnectionRow.displayName.toLowerCase().includes(filterByTextValue.toLowerCase()),
            );
        } else {
            return [];
        }
    }, [filterByTextValue, dataSourceConnection]);

    return (
        <Wrapper>
            <TableHeader>Connection Name</TableHeader>
            <TableHeader>Datasource Type</TableHeader>
            <TableHeader>Connection Status</TableHeader>
            <TableHeader />

            <PredefinedConnectionsTableRowsView
                sharedCredentials={filteredSharedCredentials}
                isLoading={isLoadingDataSourceConnection}
                filterByTextValue={filterByTextValue}
                onPredefinedConnectionUpdated={onPredefinedConnectionUpdated}
                dataSourceConnection={dataSourceConnection}
            />
        </Wrapper>
    );
};

export default PredefinedConnectionsTableView;
