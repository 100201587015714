import React from 'react';
import styled, { css } from 'styled-components';

import NotAvailableDataCellPlaceholder from './NotAvailableDataCellPlaceholder';
import ViewDataBaseCellRenderer from './ViewDataBaseCellRenderer';

import { Placeholder } from '@tonkean/infrastructure';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 47px;
`;

const StyledClickableLink = styled(ClickableLink)<{ isLink: boolean }>`
    ${({ isLink }) =>
        !isLink &&
        css`
            color: black;

            &:hover {
                color: black;
            }
        `}
`;
interface Props {
    value: string | undefined;
    loading: boolean;
}

const ViewDataUrlCellRenderer: React.FC<Props> = ({ value, loading }) => {
    if (loading) {
        return (
            <LoadingWrapper>
                <Placeholder $height="20px" $width="200px" />
            </LoadingWrapper>
        );
    }

    return (
        <ViewDataBaseCellRenderer
            value={
                <StyledClickableLink href={value} isLink={!!value} openInNewTab>
                    {value || <NotAvailableDataCellPlaceholder />}
                </StyledClickableLink>
            }
        />
    );
};

export default ViewDataUrlCellRenderer;
