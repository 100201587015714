import CreateMarketplaceItemForNativeIntegration from './CreateMarketplaceItemForNativeIntegration';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkCreateMarketplaceItemForNativeIntegration',
        reactToAngular(CreateMarketplaceItemForNativeIntegration),
    );
