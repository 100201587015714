import React from 'react';

import type { TonkeanExpressionProps } from './TonkeanExpression';
import TonkeanExpression from './TonkeanExpression';

import { useInnerField } from '@tonkean/infrastructure';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import type { DataAutomationSupportProps } from '@tonkean/utils';

export interface FormikExpressionProps
    extends Omit<
        TonkeanExpressionProps,
        'savedEvaluatedExpression' | 'savedOriginalExpression' | 'onTonkeanExpressionChanged'
    > {}

interface Props extends FormikExpressionProps, DataAutomationSupportProps {
    name: string;
    dataAutomation?: string;
}

const FormikTonkeanExpression: React.FC<Props> = ({ name, dataAutomation, ...props }) => {
    const [fieldProps, hasError, formikFieldHelpers, formikFieldMetadata] = useInnerField({
        type: 'text',
        multiple: false,
        name,
        initialValue: null,
        mapError: (error) => error?.evaluatedExpression,
        ...props,
    });
    const tonkeanExpressionDefinition = fieldProps.value;
    const onChange = useConstantRefCallback(
        (
            originalExpression: string,
            evaluatedExpression: string,
            expression: TonkeanExpressionDefinition,
            shouldSaveLogic: boolean,
        ) => {
            // TonkeanExpression fires an onChange on init as well, this flag tells us not to announce an actual change
            if (!shouldSaveLogic) {
                return;
            }

            if (!formikFieldMetadata?.touched) {
                formikFieldHelpers?.setTouched(true);
            }

            formikFieldHelpers?.setValue({
                ...tonkeanExpressionDefinition,
                originalExpression,
                evaluatedExpression,
            });
        },
    );

    return (
        <TonkeanExpression
            {...props}
            savedOriginalExpression={tonkeanExpressionDefinition?.originalExpression}
            savedEvaluatedExpression={tonkeanExpressionDefinition?.evaluatedExpression}
            onTonkeanExpressionChanged={onChange}
            automationIdentifierExpressionValue={dataAutomation}
        />
    );
};

export default FormikTonkeanExpression;
