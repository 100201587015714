import { EllipsisVIcon } from '@tonkean/svg';
import { PencilOIcon } from '@tonkean/svg';
import { EnterIcon } from '@tonkean/svg';
import { CheckIconSVG as CheckIcon } from '@tonkean/svg';
import { TonkeanLogo as TonkeanLogoIcon } from '@tonkean/svg';
import { CalendarIcon as CalendarNewIcon } from '@tonkean/svg';
import React from 'react';
import { Swipeable } from 'react-swipeable';
import { TrackActions } from '@tonkean/flux';
import TrackField from './reactComponents/TrackField';
import TrackStatusDropdown from './reactComponents/TrackStatusDropdown';
import TrackTags from './reactComponents/TrackTags';
import TrackTitleContainer from './reactComponents/TrackTitleContainer';
import TrackOwner from './reactComponents/TrackOwner';
import TrackStatus from './reactComponents/TrackStatus';
import { DaysHoursDiffFilter } from '@tonkean/shared-services';
import Utils from '@tonkean/utils';
import TrackBullet from './reactComponents/TrackBullet';
import TrackEditStatus from './reactComponents/TrackEditStatus';
import { Popover } from '@tonkean/infrastructure';
import { DatepickerInput } from '@tonkean/infrastructure';
import { Menu } from '@tonkean/infrastructure';
import { MenuItem } from '@tonkean/infrastructure';
import { Separator } from '@tonkean/infrastructure';
import { angularToReact } from '@tonkean/angular-components';
import UpdateFormsActionSelector from './reactComponents/UpdateFormsActionSelector';
import { FieldChartModal } from '@tonkean/fields';
import { DUMMY_TRACK_TITLE } from '@tonkean/constants';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

const StatusPopover = angularToReact('tnk-status-popover');

export default class TrackListItem extends React.Component {
    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.listener = null;

        this.personCache = props.personCache;
        this.projectManager = props.projectManager;
        this.groupPermissions = props.groupPermissions;
        this.workflowVersionManager = props.workflowVersionManager;
        this.workflowVersionHelper = props.workflowVersionHelper;
        this.customFieldsManager = props.customFieldsManager;
        this.selectedColumnFieldsMap = props.data.selectedColumnFieldsMap;
        this.as = props.authenticationService;
        this.modal = props.modal;
        this.trackStore = props.trackStore;
        this.trackHelper = props.trackHelper;
        this.uiActions = props.uiActions;
        this.currentUserService = props.currentUserService;
        this.tonkeanService = props.tonkeanService;
        this.liveReportHelper = props.liveReportHelper;
        this.emitNotification = (name, type, message) => {
            this.props.$rootScope.$emit(name, { msg: message, type });
        };
        this.getIndex = () => {
            return this.props.$scope.$parent.$index;
        };
        this.ignoreColumnVisibility = props.ignoreColumnVisibility;
        this.lps = props.licensePermissionsService;
        this.hideContextMenu = props.hideContextMenu || false;
        this.disableGoToTrack = props.disableGoToTrack || false;
        this.isFullUser = this.projectManager.isUserLicensed || this.projectManager.isOwner;
        this.editorId = props.data.editorId;
        this.workflowVersionId = props.data.workflowVersionId;
        this.anonymous = props.data.anonymous === true;
        this.hideHistory = props.hideHistory || false;
        this.onTrackRemoved = props.onTrackRemoved;
        this.formViewInInitiative = props.formViewInInitiative || false;
        this.onManualFieldUpdate = props.onManualFieldUpdate;

        this.tagsPopoverRef = React.createRef();
        this.statusMenuTriggerRef = React.createRef();
        this.hideTitle = props.hideTitle || false;
        this.isUserMaker = this.groupPermissions.hasPermissionsToEditWorker(
            this.projectManager.groupsMap[this.props.data.groupId],
        );
        this.numerateItems = props.numerateItems || false;

        this.filterVisibleFieldDefinitions = props.filterVisibleFieldDefinitions;

        // Initialize the state of the track list item.
        this.state = this.trackStore.getNewTrackState(this.props.id, this.editorId);

        this.state.openFieldChartModal = false;
        this.state.fieldDefForFieldChartModal = undefined;
    }

    /**
     * Happens once, when the component is mounted to the page.
     * Registers our onStoreUpdated function as a listener on the store.
     * Also saves the returned remove listener, so we can remove the listener we've just added.
     */
    componentDidMount() {
        this.listener = this.trackStore.addListener(this.onStoreUpdated.bind(this));
    }

    /**
     * Happens once, when the component is unmounted from the page.
     * Removed the listener from the store, using our removeListener (if it was initialized).
     */
    componentWillUnmount() {
        if (this.listener) {
            this.listener.remove();
        }
    }

    /**
     * Registered as a listener to the store. Triggered whenever the store emits an update.
     */
    onStoreUpdated(idsObject) {
        // If we didn't get an id (means everyone should update) or if the id is ours - update the state (causes re-render).
        // Alternatively, if we are a linked item and the real track's id is the given id - we should also render.
        if (this.trackStore.shouldTrackUpdate(this.props.id, this.editorId, idsObject)) {
            this.setState(this.trackStore.getTrackState(this.props.id, this.editorId));
        }
    }

    // region: UI Actions - actions that are purely UI changes and use this.uiActions to do their change.

    /**
     * Toggles the tracks related items from being visible.
     */
    expandTrackToggle() {
        const realTrack = this.getRealTrackItem();
        const allItemsAreDone = realTrack.doneInnerItemsCount === realTrack.innerItemsCount;
        const disableToggle =
            !this.props.enableInnerItemsToggle ||
            (allItemsAreDone && !(this.props.showDone || realTrack.showCompletedInnerItems));

        // If track is expanded let it be closed. Also, to avoid running showRelated twice, (toggle overrides itself)
        // added condition when in edit mode since the trigger of itemClicked runs showRelated when in viewModeOnly
        if (!this.props.data.viewOnlyMode && (realTrack.expended || !disableToggle)) {
            // Call the showRelated ui action and update our state afterwards.
            this.uiActions.showRelated(this.state.track, true, true, true);
        }
    }

    /**
     * Opens the selected track.
     * @param track - the track to go to.
     * @param e - the click event to pass on.
     * @param openInNewTab - if true, will open the track in a new tab (optional)
     */
    goToTrack(track, e, openInNewTab) {
        this.uiActions.goToItem(track, e, openInNewTab);
    }

    /**
     * Checks if track have inner items, if so expand, otherwise if it's not in edit mode, it opens the selected track.
     * @param track - the track to go to.
     * @param e - the click event to pass on.
     */
    expandOrGoToTrack(track, e) {
        if (track.hasRelatedInitiatives) {
            this.expandTrackToggle();
        } else if (!track.inEditMode) {
            this.goToTrack(track, e);
        }
    }

    /**
     * Opens the field's graph or show the related items (according to the field).
     * @param fieldDef - the field definition itself.
     */
    fieldOpenOrExtend(fieldDef, openOnlyGraph) {
        const realTrack = this.getRealTrackItem();

        // Only apply the click if the field doesn't have multiple field values.
        if (!realTrack.multipleFieldsMap[fieldDef.id]) {
            if (openOnlyGraph) {
                this.setState({ fieldDefForFieldChartModal: fieldDef, openFieldChartModal: true });
            } else {
                this.uiActions.fieldOpenOrExtend(realTrack, fieldDef);
            }
        }
    }

    /**
     * Opens the edit status modal for the given track, hideActivity flag and the desired state (if any).
     */
    openEditStatus(track, hideActivity, state) {
        this.uiActions.openEditStatus(track, hideActivity, state);
    }

    askForUpdates(owner, realTrack) {
        this.uiActions.askForUpdates(owner, realTrack);
    }

    // endregion: UI Actions

    // region: Inner UI Actions

    /**
     * Toggles the shown\hidden state of more info when swiping in mobile (the three dots icon and track quick add)
     * @param newState
     */
    toggleTrackSwipeInMobile(newState) {
        if (this.props.data.allowItemMenuMobile && this.state.trackSwipeShownInMobile !== newState) {
            TrackActions.toggleTrackSwipeInMobile(this.state.track.id, this.props.data.editorId, newState);
        }
    }

    /**
     * Toggles the view of the track quick add.
     */
    toggleTrackQuickAddHover(isToggled) {
        TrackActions.toggleTrackQuickAddHover(this.props.id, this.editorId, isToggled);

        // Set a timeout only if the user is hovering and  we don't already show the tooltip.
        if (isToggled && !this.state.trackQuickAddTooltipShown) {
            setTimeout(() => {
                // Double check that there isn't a race
                if (this.state.trackQuickAddToggle && !this.state.trackQuickAddTooltipShown) {
                    TrackActions.toggleTrackQuickAddTooltip(this.props.id, this.editorId, true);
                }
            }, 750);
        }
    }

    closeContextMenuAndThreeDotsDropdown() {
        const updateContextMenu = this.state.contextMenuOpen ? { contextMenuOpen: false } : undefined;
        const updateThreeDotsDropdown = this.state.threeDotsDropdownOpen ? { threeDotsDropdownOpen: false } : undefined;

        if (updateContextMenu || updateThreeDotsDropdown) {
            this.setState({ ...updateContextMenu, ...updateThreeDotsDropdown });
        }
    }

    /**
     * Puts the user in the inner items of the given track so he'll be able to add inner tracks.
     */
    addInnerTracksFromMenu() {
        this.uiActions.setSelected(this.getIndex(), this.getRealTrackItem(), true);
        // Toggle off the three dots menu.
        this.closeContextMenuAndThreeDotsDropdown();
    }

    /**
     * Drag the item to top of list
     */
    sendItemToTop() {
        this.uiActions.sendItemTo(this.state.track, 0);
        // Toggle off the three dots menu.
        this.closeContextMenuAndThreeDotsDropdown();
    }

    /**
     * Drag the item to top of list
     */
    sendItemToBottom() {
        this.uiActions.sendItemTo(this.state.track, -1); // -1 means top cause we don't know the length from here
        // Toggle off the three dots menu.
        this.closeContextMenuAndThreeDotsDropdown();
    }

    /**
     * Puts the add new item text box after the current item.
     */
    setAddNewAfterItemFromMenu() {
        this.uiActions.setAddNewAfterItem(this.state.track, this.getIndex());
        // Toggle off the three dots menu.
        this.closeContextMenuAndThreeDotsDropdown();
    }

    /**
     * Repopulating initiative.
     */
    repopulateInitiative() {
        this.tonkeanService
            .repopulateInitiative(this.props.id, this.projectManager.project.id)
            .then(() => {
                this.emitNotification('alert', 'success', 'Item refreshed successfully');
            })
            .catch(() => {
                this.emitNotification('alert', 'warning', 'Error accord while refreshing track');
            });
        this.closeContextMenuAndThreeDotsDropdown();
    }

    setAddNewAfterItemFromQuickAdd() {
        this.toggleTrackQuickAddHover(false);
        this.setAddNewAfterItemFromMenu();
    }

    /**
     * Occurs when "set tags" is clicked in the three dots dropdown menu.
     */
    toggleTagsFromMenu() {
        // Toggle the tags as requested.
        this.tagsPopoverRef.current?.openPopover();
        // Toggle off the three dots menu.
        this.closeContextMenuAndThreeDotsDropdown();
    }

    /**
     * Opens the "Gather status" settings modal for the track when clicked from the three dots menu.
     */
    openSettingsModalFromMenu() {
        this.uiActions.openSettingsModal(this.getRealTrackItem());
        // Toggle off the three dots menu.
        this.closeContextMenuAndThreeDotsDropdown();
    }

    /**
     * Initiates an archive action and toggles off the three dot menu.
     */
    archiveItemFromMenu() {
        this.uiActions.archiveItem(this.state.track);

        if (this.onTrackRemoved) {
            this.onTrackRemoved(this.state.track);
        }

        // Toggle off the three dots menu.
        this.closeContextMenuAndThreeDotsDropdown();
    }

    /**
     * Starts bulk selection mode on all tracks and marks this track as selected.
     */
    startBulkSelectFromMenu() {
        this.uiActions.startBulkSelection(this.state.track.id);
        // Toggle off the three dots menu.
        this.closeContextMenuAndThreeDotsDropdown();
        // Mark ourselves as bulk select checked.
        TrackActions.setTrackBulkSelectionState(this.props.id, this.editorId, true);
    }

    /**
     * Toggles the state of the bulkSelected flag and updates angular with the state.
     */
    toggleItemBulkSelectState() {
        const newBulkSelectedState = !this.state.bulkSelected;
        // Update angular.
        this.uiActions.toggleItemBulkSelectState(this.state.track.id, newBulkSelectedState);
        // Update ourselves.
        TrackActions.setTrackBulkSelectionState(this.props.id, this.editorId, newBulkSelectedState);
    }

    /**
     * Toggles the filter of completed inner items.
     */
    toggleDoneItems() {
        this.closeContextMenuAndThreeDotsDropdown();
        const realTrack = this.getRealTrackItem();
        this.uiActions.toggleDoneItems(realTrack.id);
    }

    // endregion: Inner UI Actions

    // region: Render sub-functions

    /**
     * Renders the 3 doted menu for the track item according to the given state.
     */
    renderThreeDots() {
        if (
            this.props.data.quickCreationMode ||
            this.props.data.viewOnlyMode ||
            this.props.formViewInInitiative ||
            this.props.data.onlyUpdateExistingItems
        ) {
            return;
        }
        const realTrack = this.getRealTrackItem();

        if (!this.isFullUser) {
            // if not license, don't show this element
            return;
        }

        // Render the dropdown menu only if we have a realTrack (we might not, and it's required).
        const threeDotsDropdown = realTrack && this.renderThreeDotsDropdown();

        // Classes.
        let className = 'dropbox flex-no-shrink track-menu-button dropdown open visible-on-hover';
        className += !this.state.track.created ? ' track-menu-button-force-hidden' : '';
        className += this.state.threeDotsDropdownOpen ? ' mod-force-visible' : '';
        className += this.props.collectItemsMode || this.props.hideTitle ? ' no-margin-right' : '';
        const icon = (
            <i
                className="svg-icon-smd common-color-grey svg-icon-hover-primary btn-on-hover pointer flex-no-shrink"
                data-automation={`track-list-item-3-point-button-${realTrack?.title}`}
            >
                <span className="tnk-icon">
                    <EllipsisVIcon />
                </span>
            </i>
        );

        const updateOpenState = (isOpen) => this.setState({ threeDotsDropdownOpen: isOpen });

        return (
            <div className={className}>
                <Menu
                    show={this.state.threeDotsDropdownOpen && !this.props.dragging}
                    menuItems={threeDotsDropdown}
                    onClose={() => updateOpenState(false)}
                >
                    <div
                        className="pointer dropdown-toggle flex-vmiddle common-color-light-grey2"
                        onClick={() => updateOpenState(true)}
                    >
                        <div className="hidden-xs tracks-item-icon-menu">
                            {realTrack?.title !== DUMMY_TRACK_TITLE && icon}
                        </div>
                        {this.state.trackSwipeShownInMobile && (
                            <div className="visible-xs tracks-item-icon-menu">
                                {realTrack?.title !== DUMMY_TRACK_TITLE && icon}
                            </div>
                        )}
                    </div>
                </Menu>
            </div>
        );
    }

    renderThreeDotsDropdown() {
        let dropdown = null;

        const realTrack = this.getRealTrackItem();

        // initialized shared elements
        const openItemElement = (
            <MenuItem onClick={this.goToTrack.bind(this, realTrack)}>
                <span className="common-bold">Open Item</span>
            </MenuItem>
        );
        let addBelowElement;
        let addInnerElement;
        let sendToTopElement;
        let sendToBottomElement;
        const repopulateInitiative =
            !this.props.createdInFormId && !this.props.collectItemsMode ? (
                <MenuItem onClick={this.repopulateInitiative.bind(this)}>
                    <span data-automation="track-list-item-refresh-item-button">Refresh Item</span>
                </MenuItem>
            ) : null;
        if (
            !this.props.data.hideAddNewForce &&
            !this.props.createdInFormId &&
            !this.props.collectItemsMode &&
            !this.props.viewItemsMode
        ) {
            sendToTopElement = <MenuItem onClick={this.sendItemToTop.bind(this)}>Send to Top</MenuItem>;
            sendToBottomElement = <MenuItem onClick={this.sendItemToBottom.bind(this)}>Send to Bottom</MenuItem>;
            addBelowElement = this.props.enableAddingItems && (
                <MenuItem onClick={this.setAddNewAfterItemFromMenu.bind(this)}>
                    <span className="common-bold">Add below</span>
                </MenuItem>
            );
            addInnerElement = this.props.enableAddingItems && (
                <MenuItem onClick={this.addInnerTracksFromMenu.bind(this)}>
                    <div className="flex mod-align-start flex-grow">
                        <div className="flex-grow">
                            <div className="common-bold" data-automation="track-list-item-add-inner-item">
                                Add inner Item
                            </div>
                            <div className="common-color-grey">Tap edit icon and Enter</div>
                        </div>
                        <div className="flex-no-shrink flex-vmiddle">
                            <span className="svg-icon-xs icon-square-background common-color-black common-bg mod-lighter-grey">
                                <span className="tnk-icon">
                                    <PencilOIcon />
                                </span>
                            </span>
                            <span className="common-size-xxxxs common-color-grey margin-right-xxs margin-left-xxs">
                                +
                            </span>
                            <span className="svg-icon-xs icon-square-background common-color-black common-bg mod-lighter-grey">
                                <span className="tnk-icon">
                                    <EnterIcon />
                                </span>
                            </span>
                        </div>
                    </div>
                </MenuItem>
            );
        } else {
            addInnerElement =
                this.props.enableAddingItems &&
                !this.props.createdInFormId &&
                !this.props.collectItemsMode &&
                !this.props.viewItemsMode ? (
                    <MenuItem onClick={this.goToTrack.bind(this, realTrack)}>
                        <span className="common-bold" data-automation="track-list-item-add-inner-item">
                            Add inner Item
                        </span>
                    </MenuItem>
                ) : null;
        }

        // Normal item (not linked).
        dropdown = (
            <>
                {!this.props.createdInFormId && !this.props.collectItemsMode && openItemElement}
                {realTrack.hasRelatedInitiatives && (
                    <MenuItem onClick={this.toggleDoneItems.bind(this)}>
                        <div className="flex mod-justify-space">
                            <span
                                className="common-bold"
                                data-automation="track-list-item-filter-completed-inner-items"
                            >
                                Filter completed inner items
                            </span>
                            {!realTrack.showCompletedInnerItems && (
                                <span className="svg-icon-sm common-color-black">
                                    <span className="tnk-icon">
                                        <CheckIcon />
                                    </span>
                                </span>
                            )}
                        </div>
                    </MenuItem>
                )}
                {!this.props.createdInFormId &&
                    !this.props.collectItemsMode &&
                    !this.props.viewItemsMode &&
                    addBelowElement}
                {!this.props.createdInFormId &&
                    !this.props.collectItemsMode &&
                    !this.props.viewItemsMode &&
                    addInnerElement}

                {!this.props.createdInFormId && !this.props.collectItemsMode && !this.props.viewItemsMode && (
                    <>
                        <Separator />
                        <MenuItem onClick={this.openSettingsModalFromMenu.bind(this)}>
                            <div className="flex-vmiddle">
                                <span className="common-bold">Module follow-up settings</span>
                                <span className="svg-icon svg-icon-lg mod-static margin-left-xlg">
                                    <span className="tnk-icon">
                                        <TonkeanLogoIcon />
                                    </span>
                                </span>
                            </div>
                        </MenuItem>

                        <Separator />
                        <MenuItem className="hidden-xs" onClick={this.toggleTagsFromMenu.bind(this)}>
                            Set Tags
                        </MenuItem>
                    </>
                )}
                {!this.props.createdInFormId &&
                    !this.props.collectItemsMode &&
                    !this.props.viewItemsMode &&
                    sendToTopElement}
                {!this.props.createdInFormId &&
                    !this.props.collectItemsMode &&
                    !this.props.viewItemsMode &&
                    sendToBottomElement}
                {!this.props.createdInFormId && !this.props.collectItemsMode && repopulateInitiative}

                {!this.props.createdInFormId && !this.props.collectItemsMode && !this.props.viewItemsMode && (
                    <Separator />
                )}
                {!this.props.createdInFormId &&
                    !this.props.collectItemsMode &&
                    !this.props.viewItemsMode &&
                    !this.props.data.uiParent[this.state.track.id] && (
                        <MenuItem onClick={this.startBulkSelectFromMenu.bind(this)}>Bulk Select</MenuItem>
                    )}
                {!this.props.viewItemsMode && (
                    <MenuItem onClick={this.archiveItemFromMenu.bind(this)}>
                        {this.props.createdInFormId || this.props.collectItemsMode ? 'Remove' : 'Archive'}
                    </MenuItem>
                )}
            </>
        );

        return dropdown;
    }

    renderLoading() {
        const showLoading = !this.state.track.created || this.state.track.editing || this.state.loadingMovingItem;
        const style = showLoading ? { display: 'inline-block' } : {};
        return (
            <i
                className="track-item-bullet-loading loading-small"
                style={style}
                data-automation={`track-list-item-loading-state-${showLoading}`}
            />
        );
    }

    renderBullet() {
        let bulletElement = null;

        const realTrack = this.getRealTrackItem();

        if (realTrack && !this.state.bulkSelectionMode) {
            bulletElement = (
                <TrackBullet
                    realTrack={realTrack}
                    expandTrackToggle={this.expandTrackToggle.bind(this)}
                    showDone={this.props.showDone}
                    enableInnerItemsToggle={this.props.enableInnerItemsToggle}
                />
            );
        }

        return (
            <div className={this.props.formViewInInitiative ? 'hidden' : ''}>
                <div className="hidden-xs flex-no-shrink flex-vmiddle">{bulletElement}</div>
                <div className="visible-xs">
                    <Swipeable
                        className="flex-no-shrink flex-vmiddle"
                        onSwipedRight={
                            this.props.viewOnlyMode || this.props.data.onlyUpdateExistingItems
                                ? () => {}
                                : this.toggleTrackSwipeInMobile.bind(this, true)
                        }
                        onSwipedLeft={
                            this.props.viewOnlyMode || this.props.data.onlyUpdateExistingItems
                                ? () => {}
                                : this.toggleTrackSwipeInMobile.bind(this, false)
                        }
                    >
                        {bulletElement}
                    </Swipeable>
                </div>
                {this.props.enableAddingItems && !this.props.createdInFormId && !this.props.collectItemsMode && (
                    <div className="track-item-quick-add">
                        <div
                            className={`track-item-quick-add-hover-zone ${
                                this.props.data.viewOnlyMode || this.props.data.onlyUpdateExistingItems ? '' : 'pointer'
                            } tnk-tooltip mod-top padding-bottom-lg`}
                            onClick={
                                this.props.data.viewOnlyMode || this.props.data.onlyUpdateExistingItems
                                    ? () => {}
                                    : this.setAddNewAfterItemFromQuickAdd.bind(this)
                            }
                            onMouseEnter={
                                this.props.data.viewOnlyMode || this.props.data.onlyUpdateExistingItems
                                    ? () => {}
                                    : this.toggleTrackQuickAddHover.bind(this, true)
                            }
                            onMouseLeave={
                                this.props.data.viewOnlyMode || this.props.data.onlyUpdateExistingItems
                                    ? () => {}
                                    : this.toggleTrackQuickAddHover.bind(this, false)
                            }
                        >
                            {this.state.trackQuickAddTooltipShown && (
                                <span className="tnk-tooltip-text track-item-quick-add-tooltip">Add item</span>
                            )}
                        </div>
                        {(this.state.trackQuickAddToggle || this.state.trackSwipeShownInMobile) && (
                            <span className="track-item-quick-add-content">+</span>
                        )}
                    </div>
                )}
            </div>
        );
    }

    renderBulkSelectCheckbox() {
        let checkboxElement = null;

        if (this.state.bulkSelectionMode) {
            // Classes.
            let className = 'pointer flex-no-shrink common-color-grey fa common-size-s margin-right';
            className += this.state.bulkSelected ? ' fa-check-square-o common-color-primary' : ' fa-square-o';

            checkboxElement = <i className={className} onClick={this.toggleItemBulkSelectState.bind(this)} />;
        }

        return checkboxElement;
    }

    renderClickZone() {
        return (
            <div
                className={`${
                    this.props.collectItemsMode || this.props.hideTitle ? '' : 'flex-grow '
                }track-item-func hidden-xs pointer track-item-allow-context`}
                onClick={this.expandOrGoToTrack.bind(this, this.state.track)}
            />
        );
    }

    renderUpdateFormsActions() {
        // Get the real track (in case this is a linked item)
        const realTrack = this.getRealTrackItem();

        if (!realTrack.created || !this.props.showActionsColumn) {
            return null;
        }

        return (
            <div className="common-size-xxxxs common-color-grey tracks-col-info4 text-center pointer relative tnk-tooltip mod-top mod-hover-box">
                {realTrack.isFormQuestion || (
                    <UpdateFormsActionSelector
                        workerForms={this.props.workerForms}
                        onFormSelected={(formId) => {
                            this.modal.openFillFormModal(
                                this.props.data.groupId,
                                formId,
                                this.workflowVersionId,
                                () => {},
                                realTrack.id,
                                this.props.data.solutionBusinessReportId,
                                realTrack.isDraft ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
                            );
                        }}
                        viewOnlyMode={this.props.data.viewOnlyMode}
                    />
                )}
            </div>
        );
    }

    renderDueDate() {
        // Get the real track (in case this is a linked item)
        const realTrack = this.getRealTrackItem();

        if (!realTrack.created) {
            return null;
        }

        // Classes.
        let className = `common-size-xxxxs common-color-grey tracks-col-info2 text-center ${
            !this.props.data.viewOnlyMode && 'pointer'
        } relative tnk-tooltip mod-top mod-hover-box`;
        if (
            realTrack.status === 'FUTURE' &&
            !realTrack.dueDate &&
            !realTrack.inInbox &&
            !this.state.dueDatePopoverOpen
        ) {
            // Note: we add a mod-force-visible class in angular once the date popover is visible (cause the popover is angular).
            className += ' visible-on-hover';

            if (this.props.collectItemsMode) {
                className += ' mod-force-visible';
            }
        }

        // Due date inner element classes (presents the icon and the value).
        let innerClassName = 'relative flex-vmiddle common-color-grey';
        if (
            realTrack.status !== 'DONE' &&
            realTrack.dueDate &&
            (realTrack.dueDate < new Date() || realTrack.eta > realTrack.dueDate)
        ) {
            // If the track is not done and it has a due date which is either in the past or before the eta - mark it red.
            innerClassName += ' common-color-danger';
        }
        if (!realTrack.dueDate) {
            // If no due date was set, center the icon.
            innerClassName += ' mod-center';
        }

        // The date value element itself.
        let dueDateValue = null;
        if (realTrack.dueDate) {
            const dueDate = new Date(realTrack.dueDate);
            const dueDateDisplayValue = `${dueDate.getMonthName().slice(0, 3)} ${dueDate.getDate()}`;
            dueDateValue = <span className="padding-left-xxs tnk-tooltip-hover-box">{dueDateDisplayValue}</span>;
        }

        const updateOpenState = (dueDatePopoverOpen) => this.setState({ dueDatePopoverOpen });
        const onOpen = () => updateOpenState(true);
        const onClose = () => updateOpenState(false);

        return (
            <div className={className}>
                <Popover
                    content={
                        <DatepickerInput
                            selected={realTrack.dueDate}
                            onChange={(date) => {
                                this.uiActions.onDueDateChange(this.state.track.id, date);
                                onClose();
                            }}
                            onCalendarClose={onClose}
                            startOpen
                        />
                    }
                    show={this.state.dueDatePopoverOpen && !this.props.data.viewOnlyMode}
                    onClose={onClose}
                >
                    <div
                        className={innerClassName}
                        data-automation="track-list-item-due-date"
                        onClick={this.props.data.viewOnlyMode ? undefined : onOpen}
                        onKeyPress={() => {
                            if (this.props.collectItemsMode && !this.props.data.viewOnlyMode) {
                                onOpen();
                            }
                        }}
                        tabIndex={!this.props.collectItemsMode ? -1 : 0}
                    >
                        <i className="svg-icon-track svg-icon-hover-black common-color-light-grey svg-icon-align-text-top tnk-tooltip-hover-box">
                            <span className="tnk-icon">
                                <CalendarNewIcon />
                            </span>
                        </i>
                        {dueDateValue}
                        <span className="tnk-tooltip-text">
                            {realTrack.dueDate ? `Due: ${DaysHoursDiffFilter(realTrack.dueDate)}` : 'Set due date'}
                        </span>
                    </div>
                </Popover>
            </div>
        );
    }

    renderStatusContainer() {
        // Get the real track (in case this is a linked item)
        const realTrack = this.getRealTrackItem();

        // Inner items warning.
        let innerItemsWarning = null;
        if (realTrack.innerItemWarn) {
            innerItemsWarning = (
                <i className="fa fa-asterisk common-color-warning common-size-xxxxxs flex-no-shrink pointer inner-tracks-warn" />
            );
        }

        const updatePopoverOpenState = (isOpen) => this.setState({ statusPopoverOpen: isOpen });
        const onPopoverHover = () => updatePopoverOpenState(true);
        const onPopoverClose = () => updatePopoverOpenState(false);

        const updateMenuOpenState = (isOpen) => this.setState({ statusMenuOpen: isOpen });

        if (
            realTrack.created &&
            (!this.props.displayFieldsList || this.props.displayFieldsList?.includes('TNK_STAGE')) &&
            !this.props.data.quickCreationMode
        ) {
            const statusBoxContainerId = this.getStatusBoxContainerId();
            return (
                <div
                    className="flex-vmiddle flex-no-shrink tracks-col-status relative dropdown open fix-popover-position"
                    onMouseLeave={onPopoverClose}
                >
                    {innerItemsWarning}
                    <Popover
                        content={
                            <StatusPopover
                                initiativeId={realTrack.id}
                                workflowVersionId={this.workflowVersionId}
                                isViewOnly={this.props.data.viewOnlyMode}
                                onClose={onPopoverClose}
                            />
                        }
                        placement="left"
                        show={this.state.statusPopoverOpen}
                        onClose={onPopoverClose}
                        nodeRef={this.statusMenuTriggerRef}
                        overflowVisible
                        noPadding
                        openedByHover
                    />
                    <Menu
                        menuItems={
                            <TrackStatusDropdown
                                id={this.state.id}
                                groupId={this.props.data.groupId}
                                workflowVersionId={this.workflowVersionId}
                                realTrack={realTrack}
                                track={this.state.track}
                                uiActions={this.uiActions}
                                services={{
                                    as: this.as,
                                    pm: this.projectManager,
                                    wvm: this.workflowVersionManager,
                                    currentUserService: this.currentUserService,
                                }}
                                openEditStatus={this.openEditStatus.bind(this)}
                                askForUpdates={this.askForUpdates.bind(this)}
                            />
                        }
                        show={this.state.statusMenuOpen}
                        onClose={() => updateMenuOpenState(false)}
                        nodeRef={this.statusMenuTriggerRef}
                    />
                    <div
                        className={`track-padding-right-no-xs ${!this.props.data.viewOnlyMode && 'pointer'}`}
                        onClick={!this.props.data.viewOnlyMode ? () => updateMenuOpenState(true) : undefined}
                        onKeyPress={(e) => {
                            if (this.props.collectItemsMode && !this.props.data.viewOnlyMode) {
                                updateMenuOpenState(true);
                            }
                        }}
                        onMouseEnter={onPopoverHover}
                        ref={this.statusMenuTriggerRef}
                        tabIndex={!this.props.collectItemsMode ? -1 : 0}
                    >
                        {this.renderStatusBox(statusBoxContainerId)}
                    </div>
                </div>
            );
        }
        return null;
    }

    /**
     * Renders the status box - the status itself with it's progress/status line.
     * This element lives inside the status container.
     */
    renderStatusBox() {
        // Get the real track (in case this is a linked item)
        const realTrack = this.getRealTrackItem();

        return (
            <TrackStatus
                realTrack={realTrack}
                expandTrackToggle={this.expandTrackToggle.bind(this)}
                goToTrack={this.goToTrack.bind(this, this.state.track)}
                collectItemsMode={this.props.collectItemsMode}
            />
        );
    }

    /**
     * A function which calculates which columns should be displayed in the track
     * It can be fields from the field manager or from the
     */
    calculateVisibleColumns(fieldDefinitions) {
        // Filter our hidden fields, then create the field jsx elements using the map function.
        const visibleFromFieldManager = this.props.ignoreColumnVisibility
            ? fieldDefinitions
            : fieldDefinitions?.filter((f) => this.filterVisibleFieldDefinitions(f) === false);

        // Filter out the fields which are not configured to be displayed in displayFieldsList
        return this.props.displayFieldsList
            ? // Filter out non-displayable fields
              this.props.displayFieldsList
                  .map((fieldId) => visibleFromFieldManager.find((field) => field.id === fieldId))
                  .filter((o) => o) // Filter nulls
            : visibleFromFieldManager;
    }

    renderFields() {
        const fieldDefinitions = this.selectedColumnFieldsMap[this.workflowVersionId];
        const realTrack = this.getRealTrackItem();

        if (fieldDefinitions && fieldDefinitions.length) {
            const displayableFields = this.calculateVisibleColumns(fieldDefinitions);

            return displayableFields.map((f) => (
                <TrackField
                    key={`${this.state.track.id}-${f.id}`}
                    track={this.state.track}
                    realTrack={realTrack}
                    fieldDefinition={f}
                    uiActions={this.uiActions}
                    editorId={this.props.data.editorId}
                    viewOnlyMode={this.props.data.viewOnlyMode}
                    editFieldMode={this.state.editFieldMode}
                    validFields={realTrack.defIdToValidFieldsMap ? realTrack.defIdToValidFieldsMap[f.id] : null}
                    fields={realTrack.defIdToFieldsMap ? realTrack.defIdToFieldsMap[f.id] : null}
                    services={{ as: this.as }}
                    groupId={this.props.data.groupId}
                    personCache={this.personCache}
                    anonymous={this.anonymous}
                    goToTrack={this.goToTrack.bind(this)}
                    fieldOpenOrExtend={this.fieldOpenOrExtend.bind(this)}
                    currentUserService={this.currentUserService}
                    projectManager={this.projectManager}
                    validationMessage={this.props.fieldValidationMap?.[f.id]}
                    hideHistory={this.hideHistory}
                    restrictNumberInputs={!!this.props.collectItemsMode}
                    collectItemsMode={this.props.collectItemsMode}
                    onManualFieldUpdate={this.onManualFieldUpdate}
                />
            ));
        }

        return null;
    }

    // endregion: Render sub-functions

    // region: helper functions

    /**
     * A helper function that gets the "real" track (the current track might be a linked item pointing to a real track object).
     */
    getRealTrackItem() {
        return this.trackHelper.getInitiativeFromCache(this.state.track.trackId);
    }

    /**
     * Generates the container id for the status box popover container (sent to angular).
     * @returns {string}
     */
    getStatusBoxContainerId() {
        return Utils.getReactAngularContainerId('track-status-summary', this.state.track.id, this.props.data.editorId);
    }

    /**
     * Opens the track context menu at the cursor position if its in a context allowed zone.
     * @param event {SyntheticEvent}
     */
    onContextMenu(event) {
        // Only open the context menu if it is a context allowed element
        if (
            this.props.data.viewOnlyMode ||
            event.altKey ||
            !event.target.classList.contains('track-item-allow-context')
        ) {
            return;
        }

        event.preventDefault();

        const itemRect = event.currentTarget.getBoundingClientRect();
        const bodyRect = document.body.getBoundingClientRect();
        // Calculate the top-left corner position of the track item in the page
        const offset = { x: itemRect.left - bodyRect.left, y: itemRect.top - bodyRect.top };
        // Calculate the relative position of the click to the top-left corner
        const left = event.pageX - offset.x;
        const top = event.pageY - offset.y;

        this.setState({ contextMenuOpen: true, contextMenuPosition: { left, top } });
    }

    /**
     * Returns true if the given column should be displayed
     * @param columnName
     * @returns {boolean}
     */
    shouldRenderColumn(columnName) {
        if (columnName === 'TNK_TAGS' && (!this.state.track.created || this.props.data.quickCreationMode)) {
            return false;
        }
        // The column will always be displayed if no displayFieldsList is provided or if it exist in the displayFieldsList.
        // !this.displayFieldsList should be always true in the case of a live report.
        return !this.props.displayFieldsList || this.props.displayFieldsList.includes(columnName);
    }

    // endregion: helper functions

    /**
     * The main render function - this renders the track list item component.
     */
    render() {
        const realTrack = this.getRealTrackItem();

        // Classes.
        let className = 'tracks-item-box tracks-item-box-border-container flex-vmiddle tracks-items-flex-basis';
        className +=
            realTrack && realTrack.status === 'DONE' && !this.props.data.parentItem ? ' mod-background-grey' : '';
        className += this.state.trackQuickAddToggle || this.state.trackSwipeShownInMobile ? ' mod-no-border' : '';

        // We might not yet have the real track (in case of a link item), so we only show a loading state until it will be fetched and we'll be re-rendered.
        // Loading State.
        if (!realTrack) {
            return (
                <div id={`track-list-item-${this.props.id}`} className={className}>
                    {this.renderThreeDots()}
                    {this.renderLoading()}
                    {this.renderBullet()}
                </div>
            );
        }

        if (
            !this.props.data.viewOnlyMode &&
            !this.props.data.parentItem &&
            !this.props.data.hideColumnQuickCreateForce &&
            this.isUserMaker
        ) {
            className += ' mod-plus-placeholder';
        }

        const contextMenu = this.renderThreeDotsDropdown();
        const contextMenuShown = this.state.contextMenuPosition && this.state.contextMenuOpen && !this.hideContextMenu;

        return (
            <>
                <div>
                    <TrackEditStatus realTrack={realTrack} />

                    <div
                        id={`track-list-item-${this.props.id}`}
                        className={className}
                        data-automation={`track-list-item-${realTrack.title}`}
                        onContextMenu={this.onContextMenu.bind(this)}
                    >
                        {this.renderThreeDots()}
                        <div className="item-index">{this.numerateItems ? this.getIndex() + 1 : ''}</div>
                        {this.renderLoading()}
                        {this.renderBullet()}
                        {this.renderBulkSelectCheckbox()}
                        <div className="flex-vmiddle common-width-full flex-self-stretch track-item-allow-context">
                            {!this.hideTitle && (
                                <TrackTitleContainer
                                    track={this.state.track}
                                    realTrack={realTrack}
                                    title={realTrack.title}
                                    description={realTrack.description}
                                    status={realTrack.status}
                                    trackHelper={this.trackHelper}
                                    editTitleMode={this.state.editTitleMode}
                                    titleEditedValue={this.state.titleEditedValue}
                                    toggleTrackSwipeInMobile={this.toggleTrackSwipeInMobile.bind(this)}
                                    trackSwipeShownInMobile={this.state.threeDotsShownInMobile}
                                    doneTracksProgress={realTrack.metadata.progress}
                                    data={this.props.data}
                                    getIndex={this.getIndex}
                                    relatedInitiativesCount={
                                        realTrack.relatedInitiatives ? realTrack.relatedInitiatives.length : 0
                                    }
                                    uiActions={this.uiActions}
                                    goToTrack={this.goToTrack.bind(this)}
                                    disableGoToTrack={this.props.disableGoToTrack}
                                    expandTrackToggle={this.expandTrackToggle.bind(this)}
                                    expandOrGoToTrack={this.expandOrGoToTrack.bind(this)}
                                    collectItemsMode={this.props.collectItemsMode}
                                    titleFormLabel={this.props.titleFormLabel}
                                    enableInnerItemsToggle={this.props.enableInnerItemsToggle}
                                    loadingRelatedInitiatives={this.props.loadingRelatedInitiatives}
                                />
                            )}

                            {this.renderClickZone()}

                            {this.shouldRenderColumn('TNK_TAGS') && (
                                <TrackTags
                                    item={realTrack}
                                    ref={this.tagsPopoverRef}
                                    onTrackEdit={() => this.uiActions.addDummyIfNeeded(realTrack)}
                                    collectItemsMode={this.props.collectItemsMode}
                                    viewOnlyMode={this.props.data.viewOnlyMode}
                                    solutionBusinessReportId={this.props.data.solutionBusinessReportId}
                                />
                            )}

                            {this.props.workerForms?.length ? this.renderUpdateFormsActions() : ''}

                            {this.shouldRenderColumn('TNK_DUE_DATE') ? this.renderDueDate() : ''}

                            {this.shouldRenderColumn('TNK_STAGE') ? this.renderStatusContainer() : ''}

                            {this.shouldRenderColumn('TNK_OWNER_ID') && (
                                <TrackOwner
                                    trackCreated={this.state.track.created}
                                    realTrack={realTrack}
                                    track={this.state.track}
                                    editorId={this.props.data.editorId}
                                    uiActions={this.uiActions}
                                    setReminderDropdownOpen={this.state.setReminderDropdownOpen}
                                    services={{
                                        as: this.as,
                                        pm: this.projectManager,
                                        lps: this.lps,
                                        wvm: this.workflowVersionManager,
                                        wvh: this.workflowVersionHelper,
                                    }}
                                    groupId={this.props.data.groupId}
                                    trackHelper={this.trackHelper}
                                    currentUserService={this.currentUserService}
                                    viewOnlyMode={this.props.data.viewOnlyMode}
                                    modal={this.modal}
                                    workflowVersionId={this.workflowVersionId}
                                    disableOwnerInvite={this.props.disableOwnerInvite}
                                    $rootScope={this.props.$rootScope}
                                    collectItemsMode={this.props.collectItemsMode}
                                    data={this.props.data}
                                    solutionBusinessReportId={this.props.data.solutionBusinessReportId}
                                    isInSharedMode={this.props.data.isInSharedMode}
                                />
                            )}

                            {this.renderFields()}
                        </div>
                        {contextMenu && (
                            <Menu
                                show={contextMenuShown}
                                menuItems={contextMenu}
                                onClose={() => this.closeContextMenuAndThreeDotsDropdown()}
                                closeOnOutsideRightClick
                            >
                                <div
                                    className="open"
                                    style={{
                                        position: 'absolute',
                                        top: this.state.contextMenuPosition?.top,
                                        left: this.state.contextMenuPosition?.left,
                                        zIndex: 30,
                                    }}
                                />
                            </Menu>
                        )}
                    </div>
                </div>
                {this.state.openFieldChartModal && this.state.fieldDefForFieldChartModal && !!realTrack && (
                    <FieldChartModal
                        open={this.state.openFieldChartModal}
                        onClose={() => this.setState({ openFieldChartModal: false })}
                        fieldDefinition={this.state.fieldDefForFieldChartModal}
                        target={realTrack}
                    />
                )}
            </>
        );
    }
}
