import FunctionOperatorBase from './FunctionOperatorBase';

import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import type { FieldType } from '@tonkean/tonkean-entities';
import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

const expressionMatchingType = Symbol('expression and condition type');
const resultMatchingType = Symbol('result and default value type');

export default class ConditionalValuesFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.CONDITIONAL_VALUES;
    override readonly dataType = (operands: formulaTreeNode[]): FieldType | undefined => {
        // Both the default value and all the results must have the same data type. The last operand is always
        // the default value, and we can be sure that it's exists (because this function is being called only if
        // the validation passed), so we'll use it to infer the data type of the function.
        const lastOperand = operands[operands.length - 1];
        return lastOperand?.dataType;
    };
    override readonly sign = 'ConditionalValue';
    override readonly displayName = 'Conditional Value';
    override readonly description =
        'Map different values of a specific variable if it matches a specific condition (aka "switch case" formula)';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Expression',
            explanation: 'An expression whose result is matched against each case clause.',
            dataType: undefined,
            typeMatchingSymbol: expressionMatchingType,
        },
        {
            displayName: 'Case clause',
            pluralName: 'Case clauses',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => [
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: undefined,
                    typeMatchingSymbol: expressionMatchingType,
                    displayName: `Condition ${repeatIndex}`,
                    explanation: 'A Condition used to match against the expression',
                },
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: undefined,
                    typeMatchingSymbol: resultMatchingType,
                    displayName: `Result ${repeatIndex}`,
                    explanation: `If the expression matches the specified condition ${repeatIndex}, this statement will be returned`,
                },
            ],
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Default Value',
            explanation: "This value is returned if the value of expression doesn't match any of the case clauses.",
            dataType: undefined,
            typeMatchingSymbol: resultMatchingType,
        },
    ];
}
