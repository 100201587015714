import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ShopifyCustomActionsDefinitionCtrl($scope, integrations) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
        possibleBooleanOptions: ['Yes', 'No'],
        possibleFulfillmentStatuses: ['fulfilled', 'null', 'partial', 'restocked'],
        possibleFinancialStatuses: [
            'pending',
            'authorized',
            'partially_paid',
            'paid',
            'partially_refunded',
            'refunded',
            'voided',
        ],
        possibleDiscountTypes: ['fixed_amount', 'percentage', 'shipping'],
        possibleInventoryBehaviours: ['bypass', 'decrement_ignoring_policy', 'decrement_obeying_policy'],
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'CREATE_ORDER':
                if (!$scope.data.definition.lineItems) {
                    $scope.data.definition.lineItems = [];
                }
                if (!$scope.data.definition.discounts) {
                    $scope.data.definition.discounts = [];
                }
                if (!$scope.data.definition.addresses) {
                    $scope.data.definition.addresses = [];
                }
                if (!$scope.data.definition.sendReceipt) {
                    $scope.data.definition.sendReceipt = 'No';
                }
                if (!$scope.data.definition.sendFulfillmentReceipt) {
                    $scope.data.definition.sendFulfillmentReceipt = 'No';
                }
                if (!$scope.data.definition.financialStatus) {
                    $scope.data.definition.financialStatus = 'pending';
                }
                if (!$scope.data.definition.inventoryBehaviour) {
                    $scope.data.definition.inventoryBehaviour = 'bypass';
                }
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.addLineItem = function () {
        $scope.data.definition.lineItems.push({
            properties: [],
        });
    };

    $scope.addAddress = function (addressType) {
        $scope.data.definition.addresses.push({
            addressType,
        });
    };

    $scope.addProperty = function (parentIndex) {
        $scope.data.definition.lineItems[parentIndex].properties.push({});
    };

    $scope.addDiscount = function () {
        $scope.data.definition.discounts.push({});
    };

    $scope.removeLineItem = function (index) {
        $scope.data.definition.lineItems.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.removeAddress = function (index) {
        $scope.data.definition.addresses.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.removeProperty = function (parentIndex, index) {
        $scope.data.definition.lineItems[parentIndex].properties.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.removeDiscount = function (index) {
        $scope.data.definition.discounts.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.addressTypeExists = function (addressType) {
        if ($scope.data.definition.addresses.length == 0) {
            return false;
        } else {
            return $scope.data.definition.addresses.some((address) => address.addressType === addressType);
        }
    };

    $scope.onCustomerIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.customerIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onEmailTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.emailExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNoteTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.noteExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDiscountAmountTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.discounts[index].discountAmountExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDiscountCodeTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.discounts[index].discountCodeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTagsTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.tagsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLineItemProductIdTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.lineItems[index].productId = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLineItemVariantIdTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.lineItems[index].variantId = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLineItemQuantityTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.lineItems[index].quantity = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLineItemPriceTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.lineItems[index].price = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLineItemTitleTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.lineItems[index].title = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPropertyKeyTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.lineItems[parentIndex].properties[index].key = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onPropertyValueTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.lineItems[parentIndex].properties[index].value = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    // Addresss
    $scope.onAddressFirstNameExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].firstNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressLastNameExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].lastNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressCompanyExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].companyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressPhoneExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].phoneExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressAddress1ExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].address1Expression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressAddress2ExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].address2Expression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressCityExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].cityExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressCountryExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].countryExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressCountryCodeExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].countryCodeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressProvinceExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].provinceExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressProvinceCodeExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].provinceCodeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAddressZipExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.addresses[index].zipExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('ShopifyCustomActionsDefinitionCtrl', lateConstructController(ShopifyCustomActionsDefinitionCtrl));
