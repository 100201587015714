import type { FormEvent } from 'react';
import React, { useState } from 'react';

import { GoogleAuthenticationType } from '@tonkean/tonkean-entities';

interface Props {
    authenticate(config: Record<string, any>, projectIntegrationData?: any, integrationDisplayName?: string): void;
    shouldIndexContent: boolean | undefined;
}

const GoogleInternalGCPServiceAccount: React.FC<Props> = ({ authenticate, shouldIndexContent }) => {
    const [serviceAccountEmail, setServiceAccountEmail] = useState('');

    const authenticateCallback = (formEvent: FormEvent) => {
        formEvent.preventDefault();

        const config = { serviceAccountEmail, environment: GoogleAuthenticationType.INTERNAL_GCP, shouldIndexContent };
        const additionalData = { environment: GoogleAuthenticationType.INTERNAL_GCP };
        authenticate(config, additionalData);
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateCallback}>
                <div className="flex flex-col">
                    <div className="form-group">
                        <label htmlFor="serviceAccountEmail" className="col-sm-4 control-label">
                            Service Account Email
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={serviceAccountEmail}
                                onChange={({ target }) => setServiceAccountEmail(target.value)}
                                className="form-control"
                                id="serviceAccountEmail"
                                autoComplete="off"
                                placeholder="Service Account Email"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default GoogleInternalGCPServiceAccount;
