import React from 'react';

export const AnimatedSwitchContext = React.createContext<string | undefined>('');

interface Props {
    activeLabel: string | undefined;
}

const AnimatedSwitch: React.FC<React.PropsWithChildren<Props>> = ({ activeLabel, children }) => {
    return <AnimatedSwitchContext.Provider value={activeLabel}>{children}</AnimatedSwitchContext.Provider>;
};

export default AnimatedSwitch;
