<div class="padding-normal">
    <!-- Header -->
    <div class="flex">
        <!-- Title -->
        <div class="flex-grow common-title-sm">Auto Check-ins</div>

        <!-- Toggle off auto check ins-->
        <tnk-toggle
            size="sm"
            class="margin-right"
            is-active="wvm.getCachedWorkflowVersion(data.workflowVersionId).shouldSendGatherUpdates"
            on-toggle-click="toggleBotWorkerComponent()"
        ></tnk-toggle>
    </div>

    <hr />

    <!-- Description -->
    <div class="margin-bottom-lg">
        Tonkean will proactively interact with people using the smart coordination capabilities.
    </div>

    <div class="manage-triggers-smart-triggers-box">
        <div class="common-title-xxs">Tonkean Smart-Triggers™</div>
        <div class="margin-top-xs margin-bottom-xlg">
            <!-- Explanation -->
            <div class="common-size-xxs">
                Tonkean’s pre-built Smart-Triggers determine when the right time is to
                <strong>follow-up with item owners based on due dates, ETA’s, data anomaly detection</strong>
                and more.
            </div>
            <div class="margin-top-xs flex-vmiddle">
                <!-- Envelope or communication integration icon -->
                <i ng-if="!cis.anyIntegrationsConnected()" class="svg-icon-sm svg-icon-align-text-top margin-right-xs">
                    <tnk-icon src="/images/icons/envelope.svg"></tnk-icon>
                </i>
                <i
                    ng-if="cis.anyIntegrationsConnected()"
                    ng-init="data.communicationIntegration = cis.getFirstThatSupports(cis.features.notifications);"
                    class="initiative-integration-icon mod-sm mod-dropdown inline-block"
                    ng-class="
                        data.communicationIntegration
                            ? 'mod-' + data.communicationIntegration.integration.integrationType.toLowerCase()
                            : 'mod-slack'
                    "
                ></i>
                <!-- Send a test/preview button -->
                <a
                    class="common-link-blue common-bullet-separator"
                    ng-click="modalUtils.openTestBotModal()"
                    ng-disabled="data.viewOnly"
                >
                    Send me a test
                </a>
                <!-- Learn more -->
                <a
                    class="common-link-blue"
                    ng-click="modalUtils.openEducationItemModal(educationManager.getEducationItem(educationManager.educationItemType.bot), pm.groupsMap[data.groupId], data.workflowVersionId)"
                >
                    Learn more
                </a>
            </div>
        </div>
    </div>

</div>
