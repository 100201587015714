import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import InfoBox from './InfoBox';

import { H4 } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { ButtonShape, ClickableButton } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const StyledInfoBox = styled(InfoBox)`
    align-items: center;
`;

const ErrorIcon = styled.span`
    margin-bottom: 10px;
    font-size: ${FontSize.LARGE_16};
    color: ${Theme.colors.error};
`;

const TextContent = styled.div`
    flex-grow: 1;
`;

const StyledClickableButton = styled(ClickableButton)`
    min-width: auto;
    padding: 0 15px;
`;
interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    projectIntegrationSummary: {
        id: string;
        displayName: string;
    };
    hasError?: boolean;
}

const BrokenDatasourceIndication: React.FC<Props> = ({ workflowVersionId, projectIntegrationSummary, hasError }) => {
    const $stateParams = useAngularService('$stateParams');
    const projectManager = useAngularService('projectManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const groupId = $stateParams?.g;
    return (
        <StyledInfoBox hasError={hasError}>
            <ErrorIcon className="fa fa-info-circle" />
            <TextContent className="flex-grow">
                <H4 $bold>{projectIntegrationSummary.displayName}</H4>
                <div>Data source is broken.</div>
            </TextContent>
            <StyledClickableButton
                state="product.projectIntegrationPage"
                params={{
                    enterpriseComponentId: projectIntegrationSummary.id,
                    fromState: 'product.workerEditor',
                    fromStateParams: { ...$stateParams },
                    fromName: `connect ${projectIntegrationSummary.displayName} into ${
                        projectManager.groupsMap?.[groupId]?.name || 'the Module Editor'
                    }`,
                    page: ProjectIntegrationPageMenuItemType.CONNECTIONS,
                }}
                onClick={() => {
                    // We remove the cached version because after a successful fix we would need to update the workflowVersion.syncConfig.projectIntegration
                    // to see the result in the UI. However, when doing the reconnect we would not know which workflowVersion to update.
                    // This way, we force a reload when returning from the integrations screen, updating our projectintegration state
                    workflowVersionManager.removeCachedWorkflowVersion(workflowVersionId);
                }}
                shape={ButtonShape.RECTANGULAR}
                warning
            >
                Fix
            </StyledClickableButton>
        </StyledInfoBox>
    );
};

export default BrokenDatasourceIndication;
