import type { AngularServices } from 'angulareact';

import type EnterpriseComponentOverviewStepDefinition from './EnterpriseComponentOverviewStepDefinition';

import type { EnterpriseComponentOverviewResult } from '@tonkean/tonkean-entities';
import type { Person } from '@tonkean/tonkean-entities';
import { EnterpriseComponentOverviewStepStatus } from '@tonkean/tonkean-entities';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';

function getGeneralEnterpriseComponentOverviewStepDefinitions(
    stepValidationResult: EnterpriseComponentOverviewResult,
    enterpriseComponentName: string,
    projectManager: AngularServices['projectManager'],
    authenticationService: AngularServices['authenticationService'],
): (EnterpriseComponentOverviewStepDefinition | undefined)[] {
    const descriptionStep = stepValidationResult.descriptionExists
        ? {
              title: `Information`,
              status: EnterpriseComponentOverviewStepStatus.VALID,
              page: ProjectIntegrationPageMenuItemType.INFORMATION,
          }
        : {
              title: `Add a description of the ${enterpriseComponentName}`,
              description: `Best practice is to add a short description of your ${enterpriseComponentName} main usage`,
              status: EnterpriseComponentOverviewStepStatus.VALID,
              page: ProjectIntegrationPageMenuItemType.INFORMATION,
          };

    const allowedSolutionsStep =
        stepValidationResult.allowedSolutionsStepStatus === EnterpriseComponentOverviewStepStatus.VALID
            ? {
                  title: `Allowed solutions defined: ${stepValidationResult.allowedSolutionsCount}`,
                  status: stepValidationResult.allowedSolutionsStepStatus,
                  page: ProjectIntegrationPageMenuItemType.SOLUTIONS_ACCESS,
              }
            : {
                  title: `Allow solution access to this ${enterpriseComponentName}`,
                  description: `Provide access to at least one solution in your board`,
                  status: stepValidationResult.allowedSolutionsStepStatus,
                  page: ProjectIntegrationPageMenuItemType.SOLUTIONS_ACCESS,
              };

    const projectOwners = projectManager.project.owners.filter((owner: Person) => !owner.systemUtilized);
    const admins = [
        ...projectOwners,
        ...stepValidationResult.adminIds.filter((adminId) => !projectOwners.some((owner) => owner.id === adminId)),
    ];
    const isUserTheOnlyAdmin = admins.length === 1 && authenticationService.getCurrentUser().id === admins[0].id;

    const titleSuffix = isUserTheOnlyAdmin ? '(You)' : '';
    const description = isUserTheOnlyAdmin
        ? `You are the only admin of this ${enterpriseComponentName}, press to add / remove admins`
        : undefined;

    const adminsStep = admins.length
        ? {
              title: `Admins defined: ${admins.length} ${titleSuffix}`,
              description,
              status: EnterpriseComponentOverviewStepStatus.VALID,
              page: ProjectIntegrationPageMenuItemType.ADMINS,
          }
        : {
              title: `Add Admins to this ${enterpriseComponentName}`,
              description: `No Admins to this ${enterpriseComponentName}, press to add/remove admins`,
              status: EnterpriseComponentOverviewStepStatus.WARNING,
              page: ProjectIntegrationPageMenuItemType.ADMINS,
          };

    return [descriptionStep, allowedSolutionsStep, adminsStep];
}

export default getGeneralEnterpriseComponentOverviewStepDefinitions;
