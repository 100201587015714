import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useDataSourceConnectionConfiguration from './useDataSourceConnectionConfiguration';
import GenericContentBySidePaneLayout from '../../../GenericContentBySidePaneLayoutModule/GenericContentBySidePaneLayout';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';

const DataSourceConnectionPage: React.FC<{}> = ({}) => {
    const $state = useAngularService('$state');
    const project = useProject();

    const [dataSourceConnectionId] = useGetStateParams<[string]>('dataSourceConnectionId');

    const [{ error, loading }, updateDataSourceConnection] = useLazyTonkeanService('updateDataSourceConnection');
    // List of all of the existing data source connections
    const { data: dataSourceConnectionsListResponse } = useTonkeanService('getDataSourceConnections', project.id);

    // The data source connection object which returned from the server.
    const [
        { data: dataSourceConnectionResponse, loading: isLoadingDataSourceConnection },
        getDataSourceConnectionById,
    ] = useLazyTonkeanService('getDataSourceConnectionById');

    // We are saving the ORIGINAL data source connection in order to search for changes later.
    const [originalDataSourceConnection, setOriginalDataSourceConnection] = useState<DataSourceConnection>();
    // The cloned data source connection is initialized with the original and then we modify it.
    const [clonedDataSourceConnection, setClonedDataSourceConnection] = useState<DataSourceConnection>();

    const dataSourceConnectionConfiguration = useDataSourceConnectionConfiguration(
        clonedDataSourceConnection?.dataSourceType,
    );

    useEffect(() => {
        if (dataSourceConnectionResponse) {
            setOriginalDataSourceConnection(dataSourceConnectionResponse);
        }
    }, [dataSourceConnectionResponse]);

    useEffect(() => {
        (async function fetch() {
            if (dataSourceConnectionId) {
                const relevantDataSourceConnection = await getDataSourceConnectionById(
                    project.id,
                    dataSourceConnectionId,
                );
                if (relevantDataSourceConnection) {
                    setClonedDataSourceConnection(relevantDataSourceConnection);
                }
            }
        })();
    }, [dataSourceConnectionId, getDataSourceConnectionById, project.id]);

    const onClickBack = () => {
        return $state.go('product.settings.dataSources');
    };

    const isComponentReady: boolean = useMemo(
        () => !!clonedDataSourceConnection && !!dataSourceConnectionsListResponse?.entities,
        [clonedDataSourceConnection, dataSourceConnectionsListResponse?.entities],
    );

    const onClickSave = useCallback(async () => {
        if (clonedDataSourceConnection) {
            const updatedDataSourceConnection = await updateDataSourceConnection(
                project.id,
                clonedDataSourceConnection.id,
                clonedDataSourceConnection?.allowOnlySharedCredentials,
                clonedDataSourceConnection.enabled,
                clonedDataSourceConnection?.connectionPermission,
                clonedDataSourceConnection?.authorizedEntitiesIds,
            );
            setClonedDataSourceConnection(updatedDataSourceConnection);
            setOriginalDataSourceConnection(updatedDataSourceConnection);
        }
    }, [clonedDataSourceConnection, project.id, updateDataSourceConnection]);

    const onClickCancel = useCallback(() => {
        if (originalDataSourceConnection) {
            setClonedDataSourceConnection(originalDataSourceConnection);
        }
    }, [originalDataSourceConnection]);

    // Whether the user perform any changes leading to the data source connection to change.
    const hasChanges = useMemo(() => {
        if (originalDataSourceConnection && clonedDataSourceConnection) {
            // Comparing between the authorized entities ids array. The items in the array might be in a different order.
            // Thus, we ignore the order.
            const authorizedEntitiesIdsAreEqual =
                clonedDataSourceConnection?.authorizedEntitiesIds?.length ===
                    originalDataSourceConnection?.authorizedEntitiesIds?.length &&
                clonedDataSourceConnection?.authorizedEntitiesIds.every((value, index) =>
                    originalDataSourceConnection?.authorizedEntitiesIds?.includes(value),
                );

            // Comparing between the connection permission.
            const connectionPermissionIsEqual =
                clonedDataSourceConnection?.connectionPermission === originalDataSourceConnection?.connectionPermission;

            return !authorizedEntitiesIdsAreEqual || !connectionPermissionIsEqual;
        } else {
            return false;
        }
    }, [originalDataSourceConnection, clonedDataSourceConnection]);

    return (
        <>
            {isComponentReady && (
                <GenericContentBySidePaneLayout
                    sidePaneConfiguration={dataSourceConnectionConfiguration}
                    clickableBack={{ onClick: onClickBack }}
                    otherPossibleDataSourceConnections={
                        dataSourceConnectionsListResponse?.entities as DataSourceConnection[]
                    }
                    currentDataSourceConnection={clonedDataSourceConnection as DataSourceConnection}
                    setCurrentDataSourceConnection={setClonedDataSourceConnection}
                    onClickSave={onClickSave}
                    onClickCancel={onClickCancel}
                    hasChanges={hasChanges}
                    showBackButton
                />
            )}
        </>
    );
};

export default DataSourceConnectionPage;
