import React from 'react';

interface Props {
    isLoading: boolean;
}

const DatePickerCustomButton: React.FC<Props> = ({ isLoading }) => {
    return (
        <div className="btn btn-primary">
            <i className={isLoading ? 'loading-small mod-white' : 'fa fa-calendar'} />
        </div>
    );
};

export default DatePickerCustomButton;
