<div class="form-page">
    <div ng-if="!data.loadingIntake"> 
        <tnk-interface-module-header
            logo-url="data.form.definition.logoUrl" 
            version-type="data.formVersionType"
            form-type="data.form.formType"
            workflow-version-id="data.workflowVersionId"
            current-custom-trigger-id="data.customTriggerId"
            parent-initiative-id="data.parentInitiativeId"
            current-form-id="data.formId"
            theme-color="data.backgroundColor"
            project-url-slug="data.projectUrlSlug"
            class="form-header"
            >            
        </tnk-interface-module-header>
    </div>
    <tnk-initiatives-sidebar
        ng-if="
            data.form && data.form.formType === 'UPDATE' && data.form.definition.itemsHierarchyFilter !== 'SINGLE_ITEM'
        "
        on-initiatives-loaded="onInitiativesLoaded(initiatives)"
        on-initiative-selected="onInitiativeSelected(initiativeId)"
        is-forms="true"
        form-items-hierarchy-filter="data.form.definition.itemsHierarchyFilter"
        group-id="data.form.group.id"
    ></tnk-initiatives-sidebar>

    <div
        class="form-content form-content-large"
        ng-class="{
            'form-fullscreen': data.expanded,
            'no-top-padding': data.loadingIntake
        }"
    >
        <div ng-if="data.form.formType === 'UPDATE' && data.initiative" class="common-size">
            <span class="fs-censored">{{ data.initiative.title }}</span>
        </div>
        <div
            class="relative form-toggle-container form-toggle-container-full"
            ng-if="!data.loadingIntake && (data.form || data.itemInterfaceId)"
        >
            <tnk-fill-form
                class="tnk-fill-form tnk-fill-form-full"
                form-id="{{ data.formId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                form-version-type="data.formVersionType"
                group-id="{{ data.form.group.id }}"
                worker-run-id="{{ data.workerRunId }}"
                custom-trigger-id="{{ data.customTriggerId }}"
                on-custom-trigger-id-changed="onCustomTriggerIdChanged(customTriggerId)"
                show-border="true"
                initiative-id="{{ data.initiativeId }}"
                session-id="{{ data.sessionId }}"
                expand-hovered="data.expandHovered"
                on-initiative-created="onFormCreatedInitiative(createdInitiative, relatedWorkerRunId, formDisplayName)"
                view-mode="viewMode"
                form="isFormFetchedFromGraphql ? data.form : undefined"
                add-under-initiative="data.addUnderInitiative"
                collection-form="data.collectionForm"
                group="data.group"
                display-close-button-when-form-answered="!!data.onFormClosed"
                on-form-closed="onFormClosedWrapper()"
                parent-initiative-id="data.parentInitiativeId"
                on-submit-resolved="data.onSubmitResolved()"
                item-interface-id="data.itemInterfaceId"
                item-interface="data.itemInterface"
                workflow-version-id="data.workflowVersionId"
                background-color="data.backgroundColor"
                form-modal-ref="data.formModalRef"
                create-using-custom-trigger-id="data.createUsingCustomTriggerId"
            ></tnk-fill-form>
        </div>

        <div ng-if="!data.loadingIntake && !data.form && !data.itemInterface">Could not find requested form.</div>

        <div ng-if="data.loadingIntake && !data.backgroundColor" class="center">
            <tnk-loading-tonkean></tnk-loading-tonkean>
        </div>
    </div>

    <div
        class="form-bg-full"
        ng-style="{ 'background-color': data.backgroundColor }"
        ng-if="(data.form || data.itemInterface) && !data.isModal"
        data-automation="form-page-background-color"
        data-automation-label="{{ data.backgroundColor }}"
    ></div>
</div>
