function EditHtmlEditLinkModalCtrl($scope, groupId, workflowVersionId, logicId, url, label) {
    $scope.data = {
        groupId,
        workflowVersionId,
        logicId,

        url: url || '',
        label: label || '',
    };

    $scope.onVariableSelected = function (variableKey, selectedField) {
        $scope.data[variableKey] = `${$scope.data[variableKey]} {{${selectedField.label || selectedField.name}}}`;
    };

    $scope.submit = function () {
        $scope.$close({
            url: $scope.data.url,
            label: $scope.data.label,
        });
    };
}
angular.module('tonkean.app').controller('EditHtmlEditLinkModalCtrl', EditHtmlEditLinkModalCtrl);
