import { lighten } from 'polished';
import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const InfoBox = styled.div<{ hasError?: boolean }>`
    display: flex;
    gap: 8px;
    background: ${({ hasError }) => (hasError ? lighten(0.3, Theme.colors.error) : Theme.colors.gray_100)};
    border: 2px dashed ${({ hasError }) => (hasError ? Theme.colors.error : Theme.colors.gray_300)};
    color: ${Theme.colors.gray_500};
    padding: 15px;
    border-radius: 4px;
    font-size: ${FontSize.SMALL_12};
`;

export default InfoBox;
