import React from 'react';

import WidgetRequiredIndicationConfiguration from '../../components/WidgetRequiredIndicationConfiguration';
import { QUESTION_WIDGET_REQUIRED_FIELD_DEFAULT_MESSAGE } from '../QuestionWidget/QuestionWidget';

import { Field, Input } from '@tonkean/infrastructure';

const RequiredFieldsConfiguration: React.FC = () => {
    return (
        <>
            <Field
                label="Required Message (Optional)"
                informationTooltip="Replace the default text that is shown when a required field is missing"
                light
                inlineError
            >
                <Input
                    name="configuration.requiredMessage"
                    data-automation="widget-configuration-required-label"
                    placeholder={QUESTION_WIDGET_REQUIRED_FIELD_DEFAULT_MESSAGE}
                />
            </Field>
            <WidgetRequiredIndicationConfiguration name="configuration.requiredIndicatorType" />
        </>
    );
};

export default RequiredFieldsConfiguration;
