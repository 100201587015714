import styled from 'styled-components';

const SQLDatabaseInnerMiddleSectionTitle = styled.div`
    font-size: 12px;
    color: #34393e;
    margin-top: 6px;
    margin-bottom: 6px;
`;

export default SQLDatabaseInnerMiddleSectionTitle;
