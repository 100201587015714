import ProjectIntegrationActionLogicConfiguration from './ProjectIntegrationActionLogicConfiguration';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkProjectIntegrationActionLogicConfiguration',
        reactToAngular(ProjectIntegrationActionLogicConfiguration, [
            'groupId',
            'workflowVersionId',
            'customTriggerId',
            'previewEvaluationSource',
            'definition',
            'projectIntegrationAction',
            'onDefinitionChange',
            'doesHaveFieldListParams',
            'validationLogicErrorMessage',
            'validationLogicErrorMessageChildEntity',
            'loading',
        ]),
    );
