import React from 'react';

import useMatchedItemActionsData from '../../../components/InterfaceCTAActionsSelector/hooks/useMatchedItemActionsData';
import InterfaceCTAActionsSelector from '../../../components/InterfaceCTAActionsSelector/InterfaceCTAActionsSelector';

import type { CustomTrigger, Form, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';

interface Props {
    name: string;
    forms?: Form[];
    loadingForms?: boolean;
    errorForms?: ErrorResponseType;
    customTriggers?: CustomTrigger[];
    loadingCustomTriggers?: boolean;
    errorCustomTriggers?: ErrorResponseType;
    groupId: TonkeanId<TonkeanType.GROUP>;
    projectId: TonkeanId<TonkeanType.PROJECT>;
}

const ItemInterfaceActionsWidgetMatchedItemWithInterfaceActionSelector: React.FC<Props> = ({
    name,
    forms,
    errorForms,
    customTriggers,
    loadingCustomTriggers,
    errorCustomTriggers,
    loadingForms,
    groupId,
    projectId,
}) => {
    const {
        data: matchedItemsActionsData,
        loading: loadingMatchedItemsActionsData,
        error: errorMatchedItemsActionsData,
    } = useMatchedItemActionsData(groupId, projectId);

    return (
        <InterfaceCTAActionsSelector
            name={name}
            forms={forms}
            customTriggers={customTriggers}
            loadingForms={loadingForms}
            loadingCustomTriggers={loadingCustomTriggers}
            errorForms={errorForms}
            errorCustomTriggers={errorCustomTriggers}
            matchedItemsActionsData={matchedItemsActionsData}
            loadingMatchedItemsActionsData={loadingMatchedItemsActionsData}
            errorMatchedItemsActionsData={errorMatchedItemsActionsData}
            enableCloneAction
        />
    );
};

export default ItemInterfaceActionsWidgetMatchedItemWithInterfaceActionSelector;
