function SafeDomEvents(utils) {
    const _this = this;
    const eventHandlersMap = {};

    /**
     * Register an action to an element event.
     * Returns: a guid to be use to unregister when dispose
     **/
    _this.registerEvent = function (elementId, eventName, action) {
        const element = document.getElementById(elementId);
        if (element && eventName && action) {
            const key = `${elementId}.${eventName}`;
            const id = utils.guid();

            if (!eventHandlersMap[key]) {
                // init array
                eventHandlersMap[key] = [];

                // assign our executer to that event
                element[eventName] = function () {
                    // passing the right arguments
                    return executer(elementId, eventName);
                };
            }

            // add the callback to the array
            eventHandlersMap[key].push({ id, action });

            // console.log(key + ' count is ' + eventHandlersMap[key].length);

            return id;
        }
    };

    /**
     * Un-Register an action to an element event.
     * id: the guid you got on the register
     **/
    _this.unregisterEvent = function (elementId, eventName, id) {
        const key = `${elementId}.${eventName}`;
        if (id && eventHandlersMap[key]) {
            let index = -1;
            // find the specific executer id
            for (let i = 0; i < eventHandlersMap[key].length; i++) {
                if (eventHandlersMap[key][i].id === id) {
                    index = i;
                    break;
                }
            }
            // remove that executer
            if (index > -1) {
                eventHandlersMap[key].splice(index, 1);
            }

            // console.log(key + ' count is ' + eventHandlersMap[key].length);

            // if no one is left, let's clean it all together
            if (!eventHandlersMap[key].length) {
                // clean the event
                const element = document.getElementById(elementId);
                if (element) {
                    element[eventName] = null;
                }

                // clean the event handler
                eventHandlersMap[key] = null;
            }
        }
    };

    function executer(elementId, eventName) {
        const key = `${elementId}.${eventName}`;
        if (eventHandlersMap[key]) {
            for (let i = 0; i < eventHandlersMap[key].length; i++) {
                const handler = eventHandlersMap[key][i];
                // execute each handler action on by one
                if (handler.action) {
                    handler.action();
                }
            }
        }
    }
}

angular.module('tonkean.shared').service('safeDomEvents', SafeDomEvents);
export default new SafeDomEvents();
