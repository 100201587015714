<div class="outgoing-webhook-configuration-logic">
    <tnk-http-request-configuration
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        configured-logic="data.configuredLogic"
        invalid-logics="data.invalidLogics"
        preview-evaluation-source="data.previewEvaluationSource"
        existing-definition="data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition"
        is-integration-generic-action="false"
        on-definition-changed="onDefinitionChanged(definition, shouldSaveLogic)"
        import-action-config="{{ data.importActionConfig }}"
    ></tnk-http-request-configuration>
</div>
