import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function IntegrationSqlFieldCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        // Component bindings
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        targetType: ctrl.targetType,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
        existingFieldDefinition: ctrl.existingFieldDefinition,
        fieldDefinitionName: ctrl.fieldDefinitionName,
        fieldDefinitionNameEdited: ctrl.fieldDefinitionNameEdited,

        viewSqlExamples: false,
        sql: '',
    };

    /**
     * Adds keyword to sql query.
     */
    $scope.addKeywordToSQL = function (keyword) {
        $scope.data.sql += keyword;
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        if (!$scope.data.createMode && $scope.data.existingFieldDefinition.definition) {
            initializeEditMode();
        }

        // It is important to fire the event to get the containing controller to have the updated data.
        definitionChanged();
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function () {};

    /**
     * Occurs once the sql definition has changed.
     */
    $scope.onSqlQueryChanged = function () {
        definitionChanged();
    };

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged() {
        const validDefinition = !!$scope.data.sql;

        const definitionObject = {
            fieldConfigurationSummaryTitle: 'SQL Field',
            fieldConfigurationSummarySubTitle: null,
            definition: {
                SQL: $scope.data.sql,
            },
            validDefinition,
            updateable: false,
        };

        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({ newDefinition: definitionObject, doNotReloadPreview: true });
        }
    }

    /**
     * Initializes the edit mode of the component.
     */
    function initializeEditMode() {
        $scope.data.sql = $scope.data.existingFieldDefinition.definition['SQL'];
    }
}

export default angular.module('tonkean.app').controller('IntegrationSqlFieldCtrl', lateConstructController(IntegrationSqlFieldCtrl));
