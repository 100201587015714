import { createContext } from 'react';

import type { EnterpriseComponentExplorerListSectionConfiguration } from '../entities/EnterpriseComponentExplorerListConfiguration';
import type EnterpriseComponentOverviewStepDefinition from '../entities/overview/EnterpriseComponentOverviewStepDefinition';

import type { EnterpriseComponent, PersonPermissionRoleType } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';

export interface EnterpriseComponentPageLayoutContextProps<C extends EnterpriseComponent> {
    /**
     * The currently viewed enterprise component.
     */
    enterpriseComponent: C;
    /**
     * Current user currently viewed enterprise component access type.
     */
    currentEnterpriseComponentAccessType: PersonPermissionRoleType;
    /**
     * Mapping between the enterprise component ids to their data
     */
    enterpriseComponentsByIds: Record<string, C>;
    /**
     * Configuration for the enterprise components explorer.
     * Can either be sections configuration or an array of enterprise component ids.
     * In the second case there will be no sections
     */
    enterpriseComponentExplorerItems: EnterpriseComponentExplorerListSectionConfiguration[] | string[];
    /**
     * Callback to call when the user clicks on the Delete button in the menu.
     */
    onDelete?: (enterpriseComponentId: string) => void;
    /**
     * A list of ids for the enterprise components the user can delete.
     * If onDelete is provided but enterpriseComponentIdsUserCanDelete is null, all enterprise components
     * will have the Delete button enabled.
     */
    enterpriseComponentIdsUserCanDelete?: string[];
    /**
     * The enterprise component id that is currently loading. Will display a loading indicator when not null.
     */
    loadingEnterpriseComponentId?: string;
    /**
     * A function to get the overview steps definitions, different for each enterprise component type
     */
    getOverviewStepDefinitions: (
        result: ConvertEnterpriseComponentTypeToOverviewResult<any>,
    ) => (EnterpriseComponentOverviewStepDefinition | undefined)[];
    /**
     * The name of the enterprise component type
     */
    enterpriseComponentName: string;
}

const EnterpriseComponentPageLayoutContext = createContext<
    EnterpriseComponentPageLayoutContextProps<EnterpriseComponent>
>({} as EnterpriseComponentPageLayoutContextProps<any>);

export default EnterpriseComponentPageLayoutContext;
