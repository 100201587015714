import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import EnterpriseComponentPageTemplate from '../../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import SolutionsTable from '../../../../ProjectIntegrationPageModule/components/EnterpriseComponentSolutionsAccess/SolutionsTable';
import type { EnterpriseComponentMenuItemPageContentProps } from '../../../entities/EnterpriseComponentMenuItemPageContentProps';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { LoadingCircle, SearchBox, Toggle } from '@tonkean/infrastructure';
import type { EnterpriseComponent, EnterpriseComponentType } from '@tonkean/tonkean-entities';
import { enterpriseComponentTypeDisplayName } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const RowContainer = styled.div`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    margin-bottom: 16px;
    display: flex;
`;

const LoadingContainer = styled.i`
    margin-left: 5px;
    margin-top: 2px;
`;

const BottomErrorContainer = styled.div`
    margin-top: 10px;
`;

const ErrorContainer = styled.div`
    margin-left: 10px;
`;

const EnterpriseComponentAccessToggle = styled(Toggle)`
    // It's the height of the loading circle. To prevent a jump when it's shown, we will make the height of the toggle
    // fixed with the height of the loading circle.
    min-height: 15px;
`;

const EnterpriseComponentSolutionsAccessContent = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    enterpriseComponent,
    enterpriseComponentType,
    projectId,
    updateOverview,
}: EnterpriseComponentMenuItemPageContentProps<T, C>) => {
    const [inputFilterValue, setInputFilterValue] = useState('');
    const [enterpriseComponentAutoAccessStatus, setEnterpriseComponentAutoAccessStatus] = useState<boolean>(false);

    const [
        { loading: isLoadingUpdateEnterpriseComponentAutoAccess, error: errorUpdateEnterpriseComponentAutoAccess },
        updateEnterpriseComponentAutoAccess,
    ] = useLazyTonkeanService('updateEnterpriseComponentAutoAccess');

    const {
        data: allowNewWorkflowFolderAccessResponse,
        loading: isLoadingGetEnterpriseComponentAllowNewWorkflowFolderAccess,
    } = useTonkeanService(
        'getEnterpriseComponentAllowNewWorkflowFolderAccess',
        enterpriseComponent.id,
        enterpriseComponentType,
        projectId,
    );

    useEffect(
        () =>
            allowNewWorkflowFolderAccessResponse &&
            setEnterpriseComponentAutoAccessStatus(
                allowNewWorkflowFolderAccessResponse.enterpriseComponentAllowNewWorkflowFolder,
            ),
        [allowNewWorkflowFolderAccessResponse, setEnterpriseComponentAutoAccessStatus],
    );

    const toggleEnterpriseComponentAutoAccessStatus = useCallback(async () => {
        const oldState = enterpriseComponentAutoAccessStatus;
        try {
            setEnterpriseComponentAutoAccessStatus(!oldState);
            await updateEnterpriseComponentAutoAccess(enterpriseComponent.id, !oldState);
        } catch (error) {
            setEnterpriseComponentAutoAccessStatus(oldState);
            throw error;
        }
    }, [enterpriseComponent.id, enterpriseComponentAutoAccessStatus, updateEnterpriseComponentAutoAccess]);

    const [errorText, setErrorText] = useState<string>();

    return (
        <EnterpriseComponentPageTemplate
            name="Solution Access"
            description={`Allow solutions to access this ${enterpriseComponentTypeDisplayName[enterpriseComponentType].singular}.`}
            stickyItems={
                <>
                    <RowContainer>
                        <EnterpriseComponentAccessToggle
                            dataAutomation="allow-access-to-all-solutions"
                            disabled={
                                isLoadingUpdateEnterpriseComponentAutoAccess ||
                                isLoadingGetEnterpriseComponentAllowNewWorkflowFolderAccess
                            }
                            size={InputSize.SMALL}
                            onChange={() => {
                                setErrorText('');
                                toggleEnterpriseComponentAutoAccessStatus();
                            }}
                            checked={enterpriseComponentAutoAccessStatus}
                            labelBefore="Automatically allow access to new solutions"
                            labelAfter={
                                isLoadingGetEnterpriseComponentAllowNewWorkflowFolderAccess ? (
                                    <LoadingCircle />
                                ) : undefined
                            }
                        />
                        {isLoadingUpdateEnterpriseComponentAutoAccess && (
                            <LoadingContainer className="loading-small" data-automation="loading-circle" />
                        )}
                        {errorUpdateEnterpriseComponentAutoAccess && (
                            <ErrorContainer className="common-error flex-grow">
                                {getStateError(errorUpdateEnterpriseComponentAutoAccess)}
                            </ErrorContainer>
                        )}
                    </RowContainer>

                    <SearchBox
                        value={inputFilterValue}
                        onChange={(e) => setInputFilterValue(e.target.value)}
                        $height="26px"
                        $maxWidth="575px"
                    />
                </>
            }
        >
            <SolutionsTable
                onError={setErrorText}
                enterpriseComponentType={enterpriseComponentType}
                enterpriseComponentId={enterpriseComponent.id}
                updateOverview={updateOverview}
                filterValue={inputFilterValue}
            />

            {errorText && <BottomErrorContainer className="common-error flex-grow">{errorText}</BottomErrorContainer>}
        </EnterpriseComponentPageTemplate>
    );
};

export default EnterpriseComponentSolutionsAccessContent;
