import ModuleItemsWidget from './components/ModuleItemsWidget';
import ModuleItemsWidgetConfigurationEditor from './components/ModuleItemsWidgetConfigurationEditor';
import type ModuleItemsWidgetConfiguration from './entities/ModuleItemsWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const ModuleItemsWidgetPackage: ItemInterfaceWidgetPackage<ModuleItemsWidgetConfiguration> = {
    displayComponent: ModuleItemsWidget,
    configurationComponent: ModuleItemsWidgetConfigurationEditor,
};

export default ModuleItemsWidgetPackage;
