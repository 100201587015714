import { useMemo } from 'react';

import { type FieldDefinition, FormDefinitionType } from '@tonkean/tonkean-entities';

const useManualFieldDefinitions = (fieldDefinitions: FieldDefinition[] | undefined) => {
    return useMemo(() => {
        return (
            fieldDefinitions?.filter((fieldDefinition) => {
                return fieldDefinition.type === FormDefinitionType.MANUAL;
            }) || []
        );
    }, [fieldDefinitions]);
};

export default useManualFieldDefinitions;
