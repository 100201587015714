import React from 'react';

import ExploreTrainingSet from '../../../../../modules/TrainingSetsModule/modules/TrainingSetActionsUtils/ExploreTrainingSet';
import TrainingSetSelectorContainer from '../../../../../modules/TrainingSetsModule/modules/TrainingSetActionsUtils/TrainingSetSelectorContainer';

import { TrainingSetType } from '@tonkean/tonkean-entities';

interface Props {
    groupId: string;
    selectedTrainingSetId?: string;
    onItemSelected: (selectedItemId: string, selectedItemLabel: string | undefined) => void;
}

const TrainingSetSearchMatcherSelector: React.FC<Props> = ({ groupId, selectedTrainingSetId, onItemSelected }) => {
    return (
        <>
            <TrainingSetSelectorContainer
                groupId={groupId}
                selectedTrainingSetId={selectedTrainingSetId}
                onItemSelected={onItemSelected}
                trainingSetTypeAsText="NLP Searcher"
                trainingSetType={TrainingSetType.NLP_SEARCH}
            />
            <ExploreTrainingSet trainingSetId={selectedTrainingSetId} groupId={groupId} />
        </>
    );
};

export default TrainingSetSearchMatcherSelector;
