<div class="nlp-branch-configuration flex-vmiddle">
    <tags-input
        ng-if="data.textAnalysisType !== 'TRAINING_SET'"
        ng-model="data.keywordsTags"
        class="flex-grow"
        add-from-autocomplete-only="false"
        add-on-enter="true"
        add-on-blur="true"
        placeholder="Add Keywords..."
        replace-spaces-with-dashes="false"
        on-tag-added="onKeywordsTagsChanged()"
        on-tag-removed="onKeywordsTagsChanged()"
        data-automation="tnk-nlp-branch-configuration-template-tag-keywords"
    ></tags-input>

    <!-- Threshold -->
    <!--<input ng-model="data.threshold" class="form-control common-width-auto margin-right-xs padding-normal-xxs"-->
    <!--type="number" min="0" max="100"-->
    <!--ng-blur="onBranchDefinitionChanged(true)"-->
    <!--uib-tooltip="Minimum threshold of match likelihood in order to count as a match (0% - 100%)"-->
    <!--tooltip-placement="top-right"/>-->
    <!--<div>%</div>-->
</div>

<tnk-training-set-model-selector
    ng-if="data.textAnalysisType === 'TRAINING_SET'"
    training-set-id="data.selectedTrainingSetId"
    model-id="data.trainingSetModelId"
    on-change="(onTrainingSetModelSelected)"
    training-set-models-to-exclude="data.trainingSetModelsToExclude"
></tnk-training-set-model-selector>

<tnk-training-set-nlp-model-details
    ng-if="data.branchId === undefined && !!data.trainingSetModelId"
    group-id="data.groupId"
    selected-training-set-id="data.selectedTrainingSetId"
    selected-training-set-model-id="data.trainingSetModelId"
></tnk-training-set-nlp-model-details>
