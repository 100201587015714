import type { IHttpResponse } from 'angular';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import convertedDataForFieldMapperTable from './convertedDataForFieldMapperTable';
import EditCustomFieldModal from './EditCustomFieldModal';
import TableMetadataBody from './TableMetadataBody';
import useCreateOrUpdateProjectIntegrationEntityFieldMetadata from './useCreateOrUpdateProjectIntegrationEntityFieldMetadata';
import type { Section } from '../../../../infrastructure/components/FieldMapperTable/FieldMapperTable';
import EnterpriseComponentPageTemplate from '../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import ProjectIntegrationPageContext from '../ProjectIntegrationPageContext';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useFullDebouncedState } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntityFieldMetadata, ProjectIntegrationEntityFields } from '@tonkean/tonkean-entities';

const FieldMapperIntegrationPage: React.FC = () => {
    const [searchQuery, setSearchQuery] = useFullDebouncedState<string>('', 100);
    const [editedEntity, setEditedField] = useState<
        | {
              entityId: string;
              fieldName: string;
          }
        | undefined
    >();
    const [entitiesMetadata, setEntitiesMetadata] = useState<Record<string, ProjectIntegrationEntityFields>>();

    const { currentProjectIntegration, loadingProjectIntegrations, errorLoadingProjectIntegrations } =
        useContext(ProjectIntegrationPageContext);

    const [{ loading: getMetadataLoading, error: getMetadataError }, getProjectIntegrationEntityMetadata] =
        useLazyTonkeanService('getProjectIntegrationEntityMetadata');

    const updateTableData = useCallback(
        (fieldMetadata: ProjectIntegrationEntityFieldMetadata) => {
            if (entitiesMetadata && editedEntity) {
                const tempEntitiesMetadata = { ...entitiesMetadata };
                const tmpEntityFields = tempEntitiesMetadata[editedEntity.entityId]?.fields;
                if (tmpEntityFields) {
                    tmpEntityFields[editedEntity.fieldName] = fieldMetadata;
                    setEntitiesMetadata(tempEntitiesMetadata);
                }
            }
        },
        [editedEntity, entitiesMetadata],
    );

    const [error, setError] = useState<IHttpResponse<any> | undefined>(undefined);

    useEffect(() => {
        if (!editedEntity) {
            setError(undefined);
        }
    }, [editedEntity]);

    const onModalClose = () => {
        setEditedField(undefined);
    };

    const fieldToEdit: ProjectIntegrationEntityFieldMetadata | undefined | null = useMemo(() => {
        if (!!editedEntity) {
            return entitiesMetadata?.[editedEntity.entityId]?.fields[editedEntity.fieldName];
        }
        return undefined;
    }, [editedEntity, entitiesMetadata]);

    const { onSubmit, createAndUpdateLoading } = useCreateOrUpdateProjectIntegrationEntityFieldMetadata(
        updateTableData,
        onModalClose,
        currentProjectIntegration,
        editedEntity,
        fieldToEdit,
        setError,
    );

    const loading = createAndUpdateLoading || currentProjectIntegration === undefined;

    useEffect(() => {
        if (currentProjectIntegration) {
            getProjectIntegrationEntityMetadata(currentProjectIntegration?.id).then((response) => {
                setEntitiesMetadata(response.entitiesMetadata);
            });
        }
    }, [currentProjectIntegration, getProjectIntegrationEntityMetadata]);

    const convertedData = useMemo(() => {
        return convertedDataForFieldMapperTable(entitiesMetadata);
    }, [entitiesMetadata]);

    const onClickEditField = (name: string, entityId: string) => {
        setEditedField({ fieldName: name, entityId });
    };

    const filteredBySearchQuery: Section[] | undefined = useMemo(() => {
        // it's important to return undefined here, because if we return an empty array, the TableMetadataBody component will not render the empty state
        if (convertedData === undefined) return undefined;

        if (!searchQuery.length) {
            // if there is no search query, return the converted data as is
            return convertedData;
        }

        return convertedData.map((section: Section) => {
            const filteredFields = section.fields.filter((field) =>
                field.name.toLowerCase().includes(searchQuery.toLowerCase()),
            );
            return {
                ...section,
                fields: filteredFields,
            };
        });
    }, [convertedData, searchQuery]);

    const isInitialLoading = getMetadataLoading || loadingProjectIntegrations;
    const isEmptyState =
        errorLoadingProjectIntegrations ||
        getMetadataError ||
        !currentProjectIntegration ||
        (!isInitialLoading && !entitiesMetadata);

    return (
        <EnterpriseComponentPageTemplate
            name="Field Mapper"
            description="To enable using multiple data source environments there should be a 1-1 relationship between the fields from the primary connection to the other connections."
            searchTerm={searchQuery}
            onSearchTermChange={!isEmptyState ? (value) => setSearchQuery(value || '') : undefined}
            searchTermPlaceholder="Search for a field name/ field value"
        >
            <TableMetadataBody
                currentProjectIntegration={currentProjectIntegration}
                filteredBySearchQuery={filteredBySearchQuery}
                onClickEditField={onClickEditField}
                isInitialLoading={isInitialLoading}
            />

            {editedEntity && currentProjectIntegration && (
                <EditCustomFieldModal
                    open={!!editedEntity}
                    onClose={onModalClose}
                    fieldName={editedEntity.fieldName}
                    field={fieldToEdit}
                    error={error}
                    loading={loading}
                    onSubmit={onSubmit}
                />
            )}
        </EnterpriseComponentPageTemplate>
    );
};

export default FieldMapperIntegrationPage;
