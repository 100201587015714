import React from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { H4, Paragraph, Placeholder, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { ContractFieldType } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const SingleMappingWrapper = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const StyledSimpleErrorStateMessage = styled(SimpleErrorStateMessage)`
    margin-top: 24px;
`;

const RequiredIndication = styled.span`
    color: ${Theme.colors.error};
`;

interface Props {
    contractId: TonkeanId<TonkeanType.CONTRACT>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    onChange: (
        contractFieldToExpression: Map<TonkeanId<TonkeanType.CONTRACT_FIELD>, TonkeanExpressionDefinition>,
    ) => void;
    contractFieldToExpression?: Map<TonkeanId<TonkeanType.CONTRACT_FIELD>, TonkeanExpressionDefinition>;
}

const CreateInitiativeByContractActionDefinition: React.FC<Props> = ({
    contractId,
    projectId,
    workflowVersionId,
    onChange,
    contractFieldToExpression,
}) => {
    const {
        data: contractFieldsResponse,
        loading: isLoadingContractFields,
        error: errorLoadingContractFields,
    } = useTonkeanService('getContractFields', projectId, contractId, ContractFieldType.INPUT);

    if (isLoadingContractFields) {
        return (
            <>
                {range(3).map((rowNum) => {
                    return (
                        <SingleMappingWrapper key={rowNum}>
                            <TitleContainer>
                                <Placeholder $height="14px" $width="60%" />
                                <Placeholder $height="12px" $width="40%" />
                            </TitleContainer>
                            <Placeholder $height="38px" $width="100%" />
                        </SingleMappingWrapper>
                    );
                })}
            </>
        );
    }

    if (errorLoadingContractFields) {
        return <StyledSimpleErrorStateMessage error={errorLoadingContractFields} showSmallError />;
    }

    return (
        <>
            {contractFieldsResponse?.entities.map((contractField) => {
                const currentFieldExpression = contractFieldToExpression?.[contractField.contractFieldId];
                return (
                    <SingleMappingWrapper key={contractField.contractFieldId}>
                        <TitleContainer>
                            <H4 $bold>
                                {contractField.displayName}
                                {contractField.required && (
                                    <span>
                                        {' '}
                                        (
                                        <RequiredIndication data-automation="contract-field-mapping-required-indication">
                                            *
                                        </RequiredIndication>
                                        )
                                    </span>
                                )}
                            </H4>

                            {contractField.description && (
                                <Paragraph $color={Theme.colors.gray_700}>{contractField.description}</Paragraph>
                            )}
                        </TitleContainer>
                        <TonkeanExpression
                            groupId={projectId}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={currentFieldExpression?.originalExpression}
                            savedEvaluatedExpression={currentFieldExpression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                                if (contractFieldToExpression != null) {
                                    contractFieldToExpression[contractField.contractFieldId] = {
                                        originalExpression,
                                        evaluatedExpression,
                                    };
                                    onChange(contractFieldToExpression);
                                } else {
                                    onChange(
                                        new Map([
                                            [
                                                contractField.contractFieldId,
                                                { originalExpression, evaluatedExpression },
                                            ],
                                        ]),
                                    );
                                }
                            }}
                        />
                    </SingleMappingWrapper>
                );
            })}
        </>
    );
};

export default CreateInitiativeByContractActionDefinition;
