import template from './tnkAutoCompleteExternalItem.template.html?angularjs';

function tnkAutoCompleteExternalItem() {
    return {
        restrict: 'E',
        controller: 'AutoCompleteExternalItemCtrl',
        scope: {
            externalSource: '=',
            externalType: '=',
            externalTypeDisplayName: '=',
            externalItem: '=',
            searchTerm: '=',
            dontFixType: '=',
            groupId: '=',
        },
        template,
    };
}
export default angular.module('tonkean.app').directive('tnkAutoCompleteExternalItem', tnkAutoCompleteExternalItem);
