function ProductsListCtrl($scope, $state, $stateParams, requestThrottler, authenticationService, utils) {
    $scope.loadingProjects = true;

    $scope.data = {
        projectsLoaded: [],
        projectsSkip: 0,
        // Showing one less than the actual limit to do "fake" pagination
        projectsLimit: 52,
        projectsQuery: null,
        hasMore: false,
        hasPrevious: false,
        showError: false,
        fetchingProjects: false,
        showCreateNewBoard: ['tonkean.com', 'tonkean.xyz'].includes(
            utils.getCompanyEmailDomain(authenticationService.currentUser.email),
        ),
    };

    // Init function
    $scope.init = function () {
        $scope.tonkeanService
            .getProjects(0, $scope.data.projectsLimit, null)
            .then(function (projects) {
                if (!projects.length) {
                    $state.go('loginCreate', null, { location: 'replace' });
                } else {
                    handleProjectsResult(projects, false);
                }
            })
            .catch(() => {
                $scope.data.showError = true;
            });
    };

    // For search
    $scope.getProjectIdStateName = function () {
        return $stateParams.s ?? 'product';
    };
    $scope.getProjectIdStateParams = function (projectId) {
        const params = $stateParams.p ? JSON.parse($stateParams.p) : {};
        return { projectId, ...params };
    };

    // For search
    $scope.onSearchChanged = function () {
        $scope.data.projectsSkip = 0;
        fetchProjects(false);
    };

    // For load more
    $scope.fetchNextPage = function () {
        if ($scope.data.hasMore) {
            $scope.data.projectsSkip = $scope.data.projectsSkip + $scope.data.projectsLimit - 1;

            fetchProjects(true);
        }
    };

    function fetchProjects(concat) {
        $scope.data.fetchingProjects = true;
        const getSearchProjectsPromise = () => {
            return $scope.tonkeanService.getProjects(
                $scope.data.projectsSkip,
                $scope.data.projectsLimit,
                $scope.data.projectsQuery,
            );
        };

        requestThrottler
            .do('search-boards', 250, getSearchProjectsPromise)
            .then(function (projects) {
                $scope.data.fetchingProjects = false;
                handleProjectsResult(projects, concat);
            })
            .catch(() => {
                $scope.data.fetchingProjects = false;
                $scope.data.showError = true;
            });
    }

    function handleProjectsResult(projects, concat) {
        $scope.data.showError = false;
        let finalProjects = projects;

        if (projects.length === $scope.data.projectsLimit) {
            $scope.data.hasMore = true;

            // requesting +1 projects so we could know if there are more
            // if we reached the limit we remove the last project so we should have at least 1 more
            finalProjects = projects.slice(0, $scope.data.projectsLimit - 1);
        } else {
            $scope.data.hasMore = false;
        }

        if ($scope.data.projectsSkip !== 0) {
            $scope.data.hasPrevious = true;
        } else {
            $scope.data.hasPrevious = false;
        }

        $scope.loadingProjects = false;

        if (concat) {
            $scope.data.projectsLoaded = $scope.data.projectsLoaded.concat(finalProjects);
        } else {
            $scope.data.projectsLoaded = finalProjects;
        }
    }

    $scope.showSearch = () => {
        return $scope.data.projectsLoaded.length > 1 || $scope.data.projectsQuery;
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('ProductsListCtrl', ProductsListCtrl);
