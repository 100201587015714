import { useAngularService, useAngularWatch } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import GridDateCell from '../GridComponents/GridDateStatistic';
import GridModuleName from '../GridComponents/GridModuleName';
import GridStatistic from '../GridComponents/GridStatistic';
import GridToggle from '../GridComponents/GridToggle';
import GridViewMoreMenu from '../GridComponents/GridViewMoreMenu';

import { ZebraTableBodyCell, ZebraTableBodyRow } from '@tonkean/infrastructure';
import { RuntimePageTab } from '@tonkean/tonkean-entities';
import { MetricRange } from '@tonkean/tonkean-entities';
import type { Environment, Group } from '@tonkean/tonkean-entities';

const SmallStyledCell = styled(ZebraTableBodyCell)`
    max-width: 110px;
    min-width: 110px;
    width: 110px;
    margin-right: 40px;
`;

interface Props {
    folderId: string;
    module: Group;
    environment: Environment;
}

const MetricsRow: React.FC<Props> = ({ module, environment, folderId }) => {
    const $state = useAngularService('$state');
    const [moduleStats] = useAngularWatch(() => module.stats);

    return (
        <ZebraTableBodyRow key={module.id}>
            <GridModuleName module={module} />

            <ZebraTableBodyCell>
                <GridDateCell
                    date={moduleStats?.lastFlow}
                    loading={!moduleStats}
                    emptyMessage="No flows in the past 6 months"
                />
            </ZebraTableBodyCell>

            <GridStatistic range={MetricRange.LAST_DAY} stats={moduleStats?.matchedWorkerRunsCount} />

            <GridStatistic range={MetricRange.LAST_WEEK} stats={moduleStats?.matchedWorkerRunsCount} />

            <GridStatistic range={MetricRange.LAST_MONTH} stats={moduleStats?.matchedWorkerRunsCount} />

            <ZebraTableBodyCell>
                <GridToggle module={module} environment={environment} folderId={folderId} />
            </ZebraTableBodyCell>

            <SmallStyledCell>
                <GridViewMoreMenu
                    onHistoryClick={() =>
                        $state.go('product.runtime', {
                            tab: RuntimePageTab.HISTORY,
                            g: module.id,
                            environment,
                        })
                    }
                    onBackgroundProcessesClick={() =>
                        $state.go(
                            'product.runtime',
                            { g: module.id, tab: RuntimePageTab.BACKGROUND_PROCESSES },
                            { notify: false },
                        )
                    }
                />
            </SmallStyledCell>
        </ZebraTableBodyRow>
    );
};

export default MetricsRow;
