import React from 'react';
import styled from 'styled-components';

import { Checkbox, Input, Placeholder, useFormikField } from '@tonkean/infrastructure';
import type { ProjectIntegrationParameterMarketplaceSummary } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const StyledNoResults = styled.div`
    padding: 20px 10px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
`;

const TableGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
`;

const StyledInput = styled(Input)`
    height: 16px;
`;

const Cell = styled.div`
    color: ${Theme.colors.gray_500};
    height: 36px;
    font-size: ${FontSize.XSMALL_10};
    font-weight: 500;
    border-bottom: 1px solid ${Theme.colors.gray_400};

    display: flex;
    align-items: center;
    padding: 0 10px;
`;

interface Props {
    loading?: boolean;
}

const MarketplaceItemCreateModalDataSourceParametersTab: React.FC<Props> = ({ loading }) => {
    const { value: parameters, setValue: setParameters } =
        useFormikField<ProjectIntegrationParameterMarketplaceSummary[]>('configuration.parameters');

    return (
        <>
            {loading ? (
                range(7).map((index) => <Placeholder key={index} $width="100%" $height="30px" />)
            ) : (
                <>
                    <TableGrid>
                        <Cell>Name</Cell>
                        <Cell>Initial Value</Cell>
                        <Cell>Required?</Cell>
                        <Cell>Request On Connect?</Cell>
                        <Cell>Hide?</Cell>
                        <Cell>Presentation order</Cell>

                        {parameters.map((row) => {
                            return (
                                <React.Fragment key={row.displayName}>
                                    <Cell>{row.displayName}</Cell>
                                    <Cell>
                                        <StyledInput
                                            value={row.initialValue || ''}
                                            onChange={(e) =>
                                                setParameters((prevParameters) => {
                                                    return prevParameters.map((prevParameter) =>
                                                        prevParameter.displayName === row.displayName
                                                            ? { ...prevParameter, initialValue: e.target.value }
                                                            : prevParameter,
                                                    );
                                                })
                                            }
                                        />
                                    </Cell>
                                    <Cell>
                                        <Checkbox
                                            checked={row.isRequired}
                                            onChange={(e) =>
                                                setParameters((prevParameters) => {
                                                    return prevParameters.map((prevParameter) =>
                                                        prevParameter.displayName === row.displayName
                                                            ? {
                                                                  ...prevParameter,
                                                                  isRequired: !prevParameter.isRequired,
                                                              }
                                                            : prevParameter,
                                                    );
                                                })
                                            }
                                        />
                                    </Cell>
                                    <Cell>
                                        <Checkbox
                                            checked={row.requestOnConnect}
                                            onChange={() =>
                                                setParameters((prevParameters) => {
                                                    return prevParameters.map((prevParameter) =>
                                                        prevParameter.displayName === row.displayName
                                                            ? {
                                                                  ...prevParameter,
                                                                  requestOnConnect: !prevParameter.requestOnConnect,
                                                              }
                                                            : prevParameter,
                                                    );
                                                })
                                            }
                                        />
                                    </Cell>

                                    <Cell>
                                        <Checkbox
                                            checked={row.hide}
                                            onChange={() =>
                                                setParameters((prevParameters) => {
                                                    return prevParameters.map((prevParameter) =>
                                                        prevParameter.displayName === row.displayName
                                                            ? {
                                                                  ...prevParameter,
                                                                  hide: !prevParameter.hide,
                                                              }
                                                            : prevParameter,
                                                    );
                                                })
                                            }
                                        />
                                    </Cell>

                                    <Cell>
                                        <StyledInput
                                            value={row.index ?? 0}
                                            type="number"
                                            min={0}
                                            onChange={(e) =>
                                                setParameters((prevParameters) => {
                                                    return prevParameters.map((prevParameter) =>
                                                        prevParameter.displayName === row.displayName
                                                            ? { ...prevParameter, index: e.target.valueAsNumber }
                                                            : prevParameter,
                                                    );
                                                })
                                            }
                                        />
                                    </Cell>
                                </React.Fragment>
                            );
                        })}

                        {!parameters.length && <StyledNoResults>No parameters to show.</StyledNoResults>}
                    </TableGrid>
                </>
            )}
        </>
    );
};

export default MarketplaceItemCreateModalDataSourceParametersTab;
