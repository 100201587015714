import { analyticsWrapper } from '@tonkean/analytics';
import { getOnBoardingSteps, getOnBoardingHiddenSteps } from '@tonkean/constants';

/**
 * Manages the on boarding behaviour and updates the server when needed.
 */
function OnBoardingManager($rootScope, authenticationService, tonkeanService, utils) {
    /**
     * Flag used previous iteration of the OnBoardingManager: authenticationService.currentUser.metadata.showOnBoarding.
     */

    const steps = getOnBoardingSteps();
    const hiddenSteps = getOnBoardingHiddenSteps();
    const allSteps = utils.mergeObjects(steps, hiddenSteps);

    const _this = this;
    _this.metadata = {
        onBoardingVisible: false,
        currentStep: null,
        title: '',
        currentPercentage: 0,
        stepsList: [],
    };
    let continuousOnBoarding = null;

    // Update the manager when the user is loaded.
    $rootScope.$on('currentUserChanged', init);

    _this.finishOnBoarding = function () {
        // Set on boarding as done.
        if (authenticationService.currentUser.metadata) {
            _this.metadata.onBoardingVisible = authenticationService.currentUser.metadata.showOnBoarding2 = false;
        }

        window.Intercom('trackEvent', 'Completed all states'); // eslint-disable-line new-cap

        // Update server.
        tonkeanService.updateProfileMetadata(authenticationService.currentUser.metadata);
    };

    _this.isStepCompleted = function (stepKey) {
        if (!allSteps[stepKey]) {
            return false;
        }

        return (
            continuousOnBoarding &&
            continuousOnBoarding.steps &&
            continuousOnBoarding.steps[stepKey] &&
            continuousOnBoarding.steps[stepKey].completed
        );
    };

    _this.completeStep = function (stepKey, broadcastData) {
        // Only do something if we got a valid key and this state is not completed.
        if (
            allSteps[stepKey] &&
            continuousOnBoarding &&
            continuousOnBoarding.steps &&
            (!continuousOnBoarding.steps[stepKey] || !continuousOnBoarding.steps[stepKey].completed)
        ) {
            // Report analytics and update intercom.
            analyticsWrapper.track('Complete State', { category: 'Onboarding', label: stepKey });
            window.Intercom('trackEvent', `Completed state ${stepKey}`); // eslint-disable-line new-cap

            // Update the state to completed.
            if (!continuousOnBoarding.steps[stepKey]) {
                continuousOnBoarding.steps[stepKey] = { completed: true };
            } else {
                continuousOnBoarding.steps[stepKey].completed = true;
            }

            // Fail safe
            authenticationService.currentUser.metadata.continuousOnBoarding = continuousOnBoarding;

            // Update the server.
            tonkeanService.updateProfileMetadata(authenticationService.currentUser.metadata);

            // If this is a regular step (and not a hidden one) we should also update the title, and move to the next step.
            if (steps[stepKey]) {
                updateCurrentStep();
            }

            $rootScope.$broadcast('onBoardingStepCompleted', stepKey, broadcastData);
        }
    };

    _this.skipStep = function (stepKey) {
        // Only do something if we got a valid key and this state is not completed.
        if (
            allSteps[stepKey] &&
            continuousOnBoarding &&
            continuousOnBoarding.steps &&
            (!continuousOnBoarding.steps[stepKey] || !continuousOnBoarding.steps[stepKey].completed)
        ) {
            // Update the state to skipped.
            if (!continuousOnBoarding.steps[stepKey]) {
                continuousOnBoarding.steps[stepKey] = { skipped: true };
            } else {
                continuousOnBoarding.steps[stepKey].skipped = true;
            }

            analyticsWrapper.track('Skipped State', { category: 'Onboarding', label: stepKey });

            // Fail safe
            authenticationService.currentUser.metadata.continuousOnBoarding = continuousOnBoarding;

            // Update the server.
            tonkeanService.updateProfileMetadata(authenticationService.currentUser.metadata);

            // If this is a regular step (and not a hidden one) we should also update the title, and move to the next step.
            if (steps[stepKey]) {
                updateCurrentStep();
            }
        }
    };

    /**
     * Sets the given step key to be un-skipped, thus directing the current step to be the skipped step.
     * Will only do something if the step is actually skipped.
     */
    _this.goToSkippedStep = function (stepKey) {
        if (steps[stepKey]) {
            analyticsWrapper.track('Skipped State Re-visited', { category: 'Onboarding', label: stepKey });

            // if step is skipped
            if (continuousOnBoarding.steps[stepKey]) {
                // Mark the step as un-skipped.
                continuousOnBoarding.steps[stepKey].skipped = false;

                // Fail safe
                authenticationService.currentUser.metadata.continuousOnBoarding = continuousOnBoarding;

                // Update the server.
                tonkeanService.updateProfileMetadata(authenticationService.currentUser.metadata);

                updateCurrentStep();
            } else {
                // if just got here from clicking on the list
                _this.metadata.currentStep = steps[stepKey];
            }
        }
    };

    /**
     * Sets the current step, and updates the title (according to completion percentage).
     */
    function updateCurrentStep() {
        // Reset the steps list to re-fill it.
        _this.metadata.stepsList = [];
        let skippedCount = 0;
        let pendingCount = 0;
        let completedCount = 0;

        // Find out how many completed items we have.
        for (const stepKey in steps) {
            if (steps.hasOwnProperty(stepKey)) {
                // Add the step name to the step as well (for the UI).
                const step = createFullStepObject(stepKey);
                _this.metadata.stepsList.push(step);

                // Update counters. Completed is "stronger" then skipped so it gets a priority (if a step is completed it's not considered also skipped).
                if (step.completed) {
                    completedCount += 1;
                } else if (step.skipped) {
                    skippedCount += 1;
                } else {
                    pendingCount += 1;
                }
            }
        }

        // Sort the steps according to their index.
        sortStepsArray(_this.metadata.stepsList);
        _this.metadata.title = getCurrentTitle(completedCount);

        // We still have more steps to go over.
        if (pendingCount > 0) {
            // Get the first step in the array that isn't completed or skipped.
            _this.metadata.currentStep = utils.findFirst(_this.metadata.stepsList, function (item) {
                return !item.completed && !item.skipped;
            });
        } else {
            _this.metadata.currentStep = null;

            if (skippedCount > 0) {
                // We have a skipped step - on boarding is not done!
                _this.metadata.onBoardingVisible = true;
            } else {
                // All steps are completed - finish on boarding locally and update server.
                _this.metadata.onBoardingVisible = false;
                if (authenticationService.currentUser.metadata) {
                    authenticationService.currentUser.metadata.showOnBoarding2 = false;
                    tonkeanService.updateProfileMetadata(authenticationService.currentUser.metadata);
                }
            }
        }
    }

    function createFullStepObject(stepKey) {
        const step = steps[stepKey];
        step.name = stepKey;
        step.completed = false; // Default state if we don't find it in the server's metadata.
        step.skipped = false; // Default state.

        if (continuousOnBoarding.steps[stepKey]) {
            step.completed = continuousOnBoarding.steps[stepKey].completed;
            step.skipped = continuousOnBoarding.steps[stepKey].skipped;
        }

        return step;
    }

    function getCurrentTitle(completedStepsCount) {
        const numOfSteps = Object.keys(steps).length;
        _this.metadata.currentPercentage = Math.floor((completedStepsCount / numOfSteps) * 100);

        return `${completedStepsCount} / ${numOfSteps} Completed`;
    }

    /**
     * Sorts the given steps array according to the index attribute (from low to high index).
     */
    function sortStepsArray(stepsArray) {
        // Array.sort works in place. We just have to supply it with our own compare function to sort according to index.
        stepsArray.sort(function (a, b) {
            if (a.index < b.index) {
                return -1;
            }
            if (a.index > b.index) {
                return 1;
            }
            return 0; // Equals.
        });
    }

    function init() {
        // Only do something if continuousOnBoarding exists in the user's metadata. It will only exist for new users that finished the sign up flow.
        // Note that old users will have an object called onBoarding in metadata which is connected to the old onboarding.
        if (
            authenticationService.currentUser &&
            authenticationService.currentUser.metadata &&
            authenticationService.currentUser.metadata.continuousOnBoarding
        ) {
            // Create the steps if they do not exist.
            if (!authenticationService.currentUser.metadata.continuousOnBoarding.steps) {
                authenticationService.currentUser.metadata.continuousOnBoarding.steps = {};
            }

            // Save the continuousOnBoarding object in the service.
            continuousOnBoarding = authenticationService.currentUser.metadata.continuousOnBoarding;

            // This flag is created in loginCreate, when a user signs up. We only show the on boarding to these users and not to users that were invited.
            _this.metadata.onBoardingVisible = authenticationService.currentUser.metadata.showOnBoarding2;

            if (_this.metadata.onBoardingVisible) {
                // Initialize the current step we're in if the on boarding is displayed to the user.
                updateCurrentStep();
            } else {
                // Initialize the steps list, so it's available if needed later on.
                _this.metadata.stepsList = [];
                for (const stepKey in steps) {
                    if (steps.hasOwnProperty(stepKey)) {
                        // Add the step name to the step as well (for the UI).
                        const step = createFullStepObject(stepKey);
                        _this.metadata.stepsList.push(step);
                    }
                }
                // Sort the steps according to their index.
                sortStepsArray(_this.metadata.stepsList);
            }
        }
    }

    init();
}

angular.module('tonkean.app').service('onBoardingManager', OnBoardingManager);
