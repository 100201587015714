/**
 * Filter used to escape html characters.
 * Taken from https://stackoverflow.com/questions/14462612/escape-html-text-in-an-angularjs-directive
 */
export default angular.module('tonkean.app').filter('escapeHtml', function () {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        // eslint-disable-next-line quotes
        "'": '&#39;',
        '/': '&#x2F;',
    };

    return function (str) {
        return String(str).replaceAll(/[&<>"'/]/g, function (s) {
            return entityMap[s];
        });
    };
});
