import { FieldArray } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-new.svg';
import { keyValueName } from '../../utils/keyValueName';

import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import {
    LinkLikeButton,
    Placeholder,
    Radio,
    RadioGroup,
    useFormikArrayField,
    useFormikField,
} from '@tonkean/infrastructure';
import { HttpRequestBodyType, type FormBodyParameter } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const StyledRadioButton = styled(RadioGroup)`
    margin-bottom: 10px;
`;

const FormBodyWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 50px;
`;

const AddParameterButton = styled(LinkLikeButton)`
    color: ${Theme.colors.primaryHighlight};
`;

const InputWrapper = styled.div`
    margin-right: 12px;

    &:not(:first-child) {
        margin-bottom: 15px;
    }
`;

const TableHeader = styled.div`
    color: ${Theme.colors.gray_500};
    margin-bottom: 8px;
`;

const StyledIconButton = styled(IconButton)`
    margin: 3px auto auto auto;
`;

interface Props {
    namePrefix: string;
    expressionProps?: FormikExpressionProps;
    isLoading: boolean;
    isFormBody: boolean;
}

const BodyComponent: React.FC<Props> = ({ namePrefix, expressionProps, isLoading, isFormBody }) => {
    const { value: formBodyValue, push } = useFormikArrayField<FormBodyParameter[]>(`${namePrefix}.formBodyParameters`);
    const { value: enableKeyValueParameters } = useFormikArrayField<FormBodyParameter[]>(
        `${namePrefix}.enableKeyValueParameters`,
    );
    const { value: bodyType } = useFormikField<HttpRequestBodyType>(`${namePrefix}.bodyType`);

    const keyValueParamsTab = useMemo<TonkeanExpressionAdditionalTab>(
        () => ({
            id: 'KEY_VALUE',
            label: 'Key Value',
            iconClass: 'mod-actions',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: () => {
                const fieldsIds = [keyValueName];
                return Promise.resolve(
                    fieldsIds.map((fieldId) => ({
                        name: fieldId,
                        value: fieldId,
                        label: fieldId,
                        id: fieldId,
                    })),
                );
            },
        }),
        [],
    );

    const updatedExpressionProps = useMemo(() => {
        if (enableKeyValueParameters) {
            return {
                ...expressionProps,
                additionalTabs: expressionProps?.additionalTabs
                    ? [keyValueParamsTab, ...expressionProps?.additionalTabs]
                    : [keyValueParamsTab],
            };
        }

        return expressionProps;
    }, [enableKeyValueParameters, expressionProps, keyValueParamsTab]);

    return (
        <>
            <StyledRadioButton name={`${namePrefix}.bodyType`} size={InputSize.SMALL} direction="row">
                <Radio value={HttpRequestBodyType.RAW}>Raw</Radio>
                <Radio value={HttpRequestBodyType.FORM_DATA}>form-data</Radio>
            </StyledRadioButton>

            {bodyType === HttpRequestBodyType.RAW && (
                <>
                    {isLoading && <Placeholder $height="36px" $width="auto" />}
                    {!isLoading && (
                        <FormikTonkeanExpression
                            {...updatedExpressionProps}
                            placeholder="Insert Body"
                            name={`${namePrefix}.body`}
                        />
                    )}
                </>
            )}

            {bodyType === HttpRequestBodyType.FORM_DATA && (
                <>
                    <FormBodyWrapper>
                        {formBodyValue.length > 0 && (
                            <>
                                <TableHeader>Parameter Key</TableHeader>
                                <TableHeader>Parameter Value</TableHeader>
                                <TableHeader />
                            </>
                        )}

                        <FieldArray name={`${namePrefix}.formBodyParameters`}>
                            {({ remove }) => {
                                return formBodyValue.map((parameter, index) => (
                                    <React.Fragment key={parameter.id}>
                                        <InputWrapper>
                                            <FormikTonkeanExpression
                                                {...updatedExpressionProps}
                                                placeholder="Parameter Key"
                                                name={`${namePrefix}.formBodyParameters[${index}].key`}
                                            />
                                        </InputWrapper>

                                        <InputWrapper>
                                            <FormikTonkeanExpression
                                                {...updatedExpressionProps}
                                                placeholder="Parameter Value"
                                                name={`${namePrefix}.formBodyParameters[${index}].value`}
                                            />
                                        </InputWrapper>

                                        <StyledIconButton onClick={() => remove(index)} flat>
                                            <TrashIcon />
                                        </StyledIconButton>
                                    </React.Fragment>
                                ));
                            }}
                        </FieldArray>
                    </FormBodyWrapper>

                    <AddParameterButton
                        onClick={() =>
                            push({
                                id: utils.guid(),
                                key: {
                                    originalExpression: '',
                                    evaluatedExpression: '',
                                    isStripHtmlDisabled: true,
                                },
                                value: {
                                    originalExpression: '',
                                    evaluatedExpression: '',
                                    isStripHtmlDisabled: true,
                                },
                            })
                        }
                    >
                        + Add Parameter
                    </AddParameterButton>
                </>
            )}
        </>
    );
};

export default BodyComponent;
