<div class="change-external-status common-close-btn-container container text-center">
    <!-- Close button -->
    <span class="common-close-btn svg-icon" ng-click="cancel()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Title -->
    <div class="common-size-l common-bold margin-bottom">
        Select {{ data.initiative.externalSourceFriendly }} default status
    </div>
    <!-- Description -->
    <!--<div class="common-size-xxs margin-bottom-xlg">-->
    <!--Change the default status in <strong>{{data.initiative.externalSourceFriendly}}</strong> when<br />something is changed to <strong>Done</strong> in Tonkean-->
    <!--</div>-->

    <!-- Loading -->
    <div
        ng-if="data.loadingAvailableExternalStatuses && !data.errorLoadingAvailableExternalStatuses"
        class="flex-vmiddle"
    >
        <i class="loading-small margin-right"></i>
        <span>Loading external statuses...</span>
    </div>

    <!-- Error -->
    <div
        ng-if="!data.loadingAvailableExternalStatuses && data.errorLoadingAvailableExternalStatuses"
        class="common-color-danger flex-vmiddle"
    >
        <span class="margin-right-xs">There was an error trying to load external statuses.</span>
        <a ng-click="loadExternalStatuses()">Reload</a>
    </div>

    <!-- Items -->
    <div
        class="flex-vmiddle flex-wrap mod-justify-center"
        ng-if="!data.loadingAvailableExternalStatuses && !data.errorLoadingAvailableExternalStatuses"
    >
        <div ng-repeat="externalStatus in data.externalStatuses" class="margin-right-xs margin-bottom-xs">
            <button class="btn btn-default" ng-click="changeDefaultExternalStatus(externalStatus)">
                {{ externalStatus.displayName }}
            </button>
        </div>
    </div>

    <!-- Saving settings -->
    <div ng-if="data.savingExternalStatusDefault && !data.errorSavingExternalStatusDefault" class="flex-vmiddle">
        <i class="loading-small margin-right"></i>
        <span>Saving settings...</span>
    </div>
    <!-- Error saving settings -->
    <div
        ng-if="!data.savingExternalStatusDefault && data.errorSavingExternalStatusDefault"
        class="flex-vmiddle common-color-danger"
    >
        There was an error trying to save settings.
    </div>
</div>
