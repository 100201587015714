import FormsWorkerOutline from './FormsWorkerOutline';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkFormsWorkerOutline',
        reactToAngular(FormsWorkerOutline, [
            'formIdToFormMap',
            'switchToDraftDialog',
            'createNewForm',
            'editForm',
            'isLoading',
            'selectedFormId',
        ]),
    );
