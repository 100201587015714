import navigationCategoryToEnvironmentTypeMap from '../config/navigationCategoryToEnvironmentTypeMap';

import type { NavigationCategory } from '@tonkean/tonkean-entities';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';

function filterCategoriesByEnvironment(
    navigationCategories: NavigationCategory[],
    environment?: WorkflowVersionType,
): NavigationCategory[] {
    if (!environment) {
        return navigationCategories;
    } else {
        return navigationCategories.filter(
            (navigationCategory) =>
                !navigationCategoryToEnvironmentTypeMap[navigationCategory] ||
                navigationCategoryToEnvironmentTypeMap[navigationCategory] === environment,
        );
    }
}

export default filterCategoriesByEnvironment;
