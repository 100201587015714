export default angular.module('tonkean.app').filter('limitLength', function () {
    return function (content, length, tail) {
        if (Number.isNaN(length)) {
            length = 50;
        }

        if (tail === undefined) {
            tail = '...';
        }

        if (!content || content.length <= length || content.length - tail.length <= length) {
            return content;
        } else {
            return String(content).slice(0, Math.max(0, length - tail.length)) + tail;
        }
    };
});
