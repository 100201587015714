import { analyticsWrapper } from '@tonkean/analytics';

function OrgMembersCtrl($scope, $rootScope, $q, $state, consts, tonkeanService, projectManager) {
    /**
     * This component used to inherit the scope of the settingsCtrl when rendered from the $state.
     * Now it is rendered with `ng-transclude`, so it doesn't inherit the scope, and we must manually add
     * the values from the parent scope (`$scope.$parent.$parent`) into the `$scope`.
     */
    Object.entries($scope.$parent?.$parent).forEach(([key, value]) => {
        if (!key.startsWith('$')) {
            $scope[key] = value;
        }
    });

    $scope.pm = projectManager;
    $scope.lps = $rootScope.lps;
    $scope.consts = consts;

    $scope.removeInProgress = {};
    $scope.sortField = 'NAME';
    $scope.sortDesc = false;
    $scope.pageSize = 30;
    $scope.loadingPeople = false;
    $scope.loadingFreeMembers = true;

    $scope.data = {
        allMembers: [],
        freeMembers: [],
        userLabel: $scope.lps.isPricingVersion($scope.lps.pricingVersions.V1) ? 'full user' : 'user',
    };

    $scope.init = function () {
        // Enterprise projects have nothing to find here.
        if ($scope.pm.isEnterprise) {
            $state.go('product.settings.board');
        }

        $scope.loadingFreeMembers = true;
        $scope.initLoading = true;
        $scope.currentPage = 1;
        $scope.searchTotal = 0;
        $scope.peopleSearch = '';
        $scope.fullUsers = [];
        $scope.peopleToAdd = [];

        const initPromises = [];

        initPromises.push($scope.getOrganizationUsers());

        // Load all organization members, and extract the free members (so we can display them).
        initPromises.push(
            tonkeanService.getTeamMembers($scope.projectId, { excludeMe: true }).then(function (data) {
                $scope.data.allMembers = data.people;
                // Free members are the people we show in the right blue sidebar.
                $scope.data.freeMembers = extractFreeMembers($scope.data.allMembers);
                $scope.loadingFreeMembers = false;
            }),
        );

        return $q.all(initPromises).finally(function () {
            $scope.initLoading = false;
        });
    };

    $scope.$watchGroup(['currentPage', 'license'], function () {
        $scope.getOrganizationUsers();
    });

    $scope.$on('projectSelected', function () {
        if ($scope.pm.project) {
            $scope.data.userLabel = $scope.lps.isPricingVersion($scope.lps.pricingVersions.V1) ? 'full user' : 'user';
        }
    });

    $scope.sort = function (sortBy) {
        if ($scope.sortField === sortBy) {
            $scope.sortDesc = !$scope.sortDesc;
        } else {
            $scope.sortField = sortBy;
            $scope.sortDesc = false;
        }

        $scope.getOrganizationUsers();
    };

    $scope.addFullUsers = function () {
        if (!$scope.addingUsers && $scope.peopleToAdd.length) {
            $scope.addingUsers = true;
            $scope.loadingPeople = true;

            const peopleToAdd = angular.copy($scope.peopleToAdd);

            // Check if there are not enough licenses.
            if ($scope.plan.quantity < $scope.totalPeople + peopleToAdd.length) {
                $scope.updateSeats($scope.totalPeople + peopleToAdd.length, true, true, function (success) {
                    $scope.initLoading = true;

                    if (success) {
                        innerAddPeople(peopleToAdd).finally(() => {
                            $scope.initLoading = false;
                        });
                        $scope.peopleToAdd = [];
                        $scope.data.showAddUsers = false;
                    } else {
                        $scope.loadingPeople = false;
                        $scope.initLoading = false;
                    }
                    $scope.addingUsers = false;
                });
            } else {
                innerAddPeople(peopleToAdd);
                $scope.peopleToAdd = [];
                $scope.data.showAddUsers = false;
                $scope.addingUsers = false;
            }
        }
    };

    $scope.getOrganizationUsers = function () {
        const pageIndex = $scope.currentPage - 1;
        const skip = $scope.pageSize + (pageIndex - 1) * $scope.pageSize;
        if (!$scope.gettingOrganizationUsers && pageIndex !== -1 && (skip === 0 || skip < $scope.totalPeople)) {
            $scope.gettingOrganizationUsers = true; // Flag that marks a search is running so we don't search again during that time.
            $scope.loadingPeople = true;

            // In case of new pricing, get the seats from project potential seats api.
            // These potential users are only relevant when in Trial and not yet payed.
            if ($rootScope.lps.isV2NotLicensed()) {
                return getPotentialSeats();
            } else if ($rootScope.lps.supportsAssignableSeats()) {
                return getTeamMembers(true, false, skip);
            } else {
                // User can't assign seats yet (In trial or free V1), show him the potential seats (In V1 it means the users logged in).
                return getTeamMembers(false, true, skip);
            }
        } else {
            return $q.resolve();
        }
    };

    $scope.removeUser = function (person) {
        if (!$scope.removeInProgress[person.id]) {
            $scope.loadingPeople = true;
            $scope.removeInProgress[person.id] = true;
            analyticsWrapper.track('Removed a full user', { category: 'Subscription' });
            tonkeanService.updatePersonLicense($scope.pm.project.id, person.id, false).then(function () {
                $scope.getOrganizationUsers().finally(function () {
                    $scope.loadingPeople = false;
                    $scope.removeInProgress[person.id] = false;
                });
            });
        }
    };

    function getPotentialSeats() {
        const options = {
            excludeMe: false,
            excludeBuyer: false,
        };
        return tonkeanService
            .getProjectPotentialSeats($scope.projectId, options)
            .then(function (data) {
                $scope.fullUsers = data.seats;
                $scope.totalPeople = data.total;
                $scope.searchTotal = data.total;
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.loadingPeople = false;
                $scope.gettingOrganizationUsers = false;
            });
    }

    function getTeamMembers(onlyLicensed, onlyUsers, skip) {
        const options = {
            sortField: $scope.sortField,
            sortDesc: $scope.sortDesc,
            excludeMe: false,
            onlyLicensed,
            onlyUsers,
        };
        return tonkeanService
            .getTeamMembers($scope.projectId, options, null, $scope.pageSize, skip)
            .then(function (data) {
                $scope.fullUsers = data.people;
                $scope.totalPeople = data.total;
                $scope.searchTotal = data.total;
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.loadingPeople = false;
                $scope.gettingOrganizationUsers = false;
            });
    }

    function innerAddPeople(peopleToAdd) {
        analyticsWrapper.track('Added full users', { category: 'Subscription' });

        const addFullUserPromises = [];

        /* jshint loopfunc:true */
        for (const person of peopleToAdd) {
            addFullUserPromises.push(setPersonAsFullUser(person));
        }

        return $q.all(addFullUserPromises).finally(() => {
            $scope.loadingPeople = false;
        });
    }

    function setPersonAsFullUser(person) {
        if (!person.id) {
            // create the temp people first
            return tonkeanService
                .sendInvites($scope.pm.project.id, [
                    {
                        email: person.email,
                        name: person.email.split('@')[0],
                    },
                ])
                .then(function (data) {
                    person = data.invites[0].person;
                    // tonkeanService.addPeopleToFunction($scope.initiative.id, funcId, peopleIds)
                    return tonkeanService.updatePersonLicense($scope.pm.project.id, person.id, true).then(function () {
                        $scope.fullUsers.push(person);
                        $scope.totalPeople += 1;
                        analyticsWrapper.track('Seat added', { category: 'License' });
                        return $q.resolve();
                    });
                });
        } else {
            // tonkeanService.addPeopleToFunction($scope.initiative.id, funcId, peopleIds)
            return tonkeanService.updatePersonLicense($scope.pm.project.id, person.id, true).then(function () {
                $scope.fullUsers.push(person);
                $scope.totalPeople += 1;
                analyticsWrapper.track('Seat added', { category: 'License' });
                return $q.resolve();
            });
        }
    }

    /**
     * Goes over the given people, extracts the free members from it (members who haven't used the app) and returns it.
     * @param people - all the people in the current organization.
     */
    function extractFreeMembers(people) {
        if ($rootScope.lps.supportsAssignableSeats()) {
            // If in licensed mode.
            return people.filter(function (member) {
                return !member.projectContext.isLicensed;
            });
        } else {
            // If in trial mode.
            return people.filter(function (member) {
                return !member.isUser;
            });
        }
    }

    $scope.init();
}
export default angular.module('tonkean.app').controller('OrgMembersCtrl', OrgMembersCtrl);
