import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import useProjectActiveProcessContributors from '../../hooks/useProjectActiveProcessContributors';
import useProjectIntegrationsDocumentsCountPerEntity from '../../hooks/useProjectIntegrationsDocumentsCountPerEntity';
import type { showWarningProps } from '../Usage/Usage';

import { useToastMessage } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import { Placeholder } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const LicensePlanContainer = styled.div`
    display: flex;
`;

const LicensePlanTitleDataContainer = styled.div`
    display: grid;
    width: fit-content;
    margin-bottom: 49px;
`;

const BundleData = styled.div`
    margin-right: 63px;
`;

const BundleDataTitle = styled.div<showWarningProps>`
    color: ${({ $showWarning }) => ($showWarning ? Theme.colors.warning : Theme.colors.gray_600)};
    font-size: ${FontSize.MEDIUM_14};
`;

const BundleDataQuantity = styled.div<showWarningProps>`
    display: inline;
    color: ${({ $showWarning }) => ($showWarning ? Theme.colors.warning : Theme.colors.gray_700)};
    font-size: ${FontSize.XXXXLARGE_24};
`;

const BundleDataQuota = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.LARGE_16};
`;

const StyledSpacer = styled.div`
    display: inline;
    margin: 0px 7px;
`;

interface Props {}

const LicensePlan: React.FC<Props> = () => {
    const project = useProject();
    const { totalEntitiesCount, loading, error } = useProjectIntegrationsDocumentsCountPerEntity();
    const { activeProcessContributors, processContributorsCountExceedingQuota, loadActiveProcessContributors } =
        useProjectActiveProcessContributors();
    const utils = useAngularService('utils');
    const emit = useToastMessage();

    useEffect(() => {
        if (error) {
            emit('An error occurred while retrieving data source records');
        }
    }, [emit, error]);

    const liveEntitiesExceedingQuota = useMemo<boolean>(() => {
        return !!(
            totalEntitiesCount &&
            project?.projectBundleConfiguration &&
            totalEntitiesCount > project?.projectBundleConfiguration.liveEntitiesQuota
        );
    }, [totalEntitiesCount, project?.projectBundleConfiguration]);

    return (
        <LicensePlanTitleDataContainer>
            {!project.projectBundleConfiguration ? (
                <BundleDataQuota>License plan is unavailable</BundleDataQuota>
            ) : loading ? (
                <Placeholder $width="600px" $height="30px" />
            ) : (
                <LicensePlanContainer>
                    <BundleData>
                        <BundleDataTitle>Bundles</BundleDataTitle>
                        <BundleDataQuantity>{project.projectBundleConfiguration.bundlesCount}</BundleDataQuantity>
                    </BundleData>
                    <BundleData>
                        <BundleDataTitle $showWarning={processContributorsCountExceedingQuota}>
                            Process Contributors
                        </BundleDataTitle>
                        <BundleDataQuota>
                            <BundleDataQuantity $showWarning={processContributorsCountExceedingQuota}>
                                {activeProcessContributors && utils.nFormatter(activeProcessContributors)}
                            </BundleDataQuantity>
                            <StyledSpacer>/</StyledSpacer>
                            {utils.nFormatter(project.projectBundleConfiguration.processContributorsQuota)}
                        </BundleDataQuota>
                    </BundleData>
                    <BundleData>
                        <BundleDataTitle $showWarning={liveEntitiesExceedingQuota}>Data Source Records</BundleDataTitle>
                        <BundleDataQuota>
                            <BundleDataQuantity $showWarning={liveEntitiesExceedingQuota}>
                                {totalEntitiesCount && utils.nFormatter(totalEntitiesCount)}
                            </BundleDataQuantity>
                            <StyledSpacer>/</StyledSpacer>
                            {utils.nFormatter(project.projectBundleConfiguration.liveEntitiesQuota)}
                        </BundleDataQuota>
                    </BundleData>
                    <BundleData>
                        <BundleDataTitle>Deployment Type</BundleDataTitle>
                        <BundleDataQuantity>{project.projectBundleConfiguration.deploymentType}</BundleDataQuantity>
                    </BundleData>
                    <div>
                        <BundleDataTitle>Tier Of Support</BundleDataTitle>
                        <BundleDataQuantity>{project.projectBundleConfiguration.tierOfSupport}</BundleDataQuantity>
                    </div>
                </LicensePlanContainer>
            )}
        </LicensePlanTitleDataContainer>
    );
};

export default LicensePlan;
