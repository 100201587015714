import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import type { IntegrationType } from '@tonkean/tonkean-entities';

const useNativeEntitiesFromConsts = (integrationType: IntegrationType | string | undefined) => {
    const integrationConsts = useAngularService('integrationsConsts');

    return useMemo(() => {
        if (integrationType) {
            const projectIntegrationCustomEntitiesConfig: Record<string, { displayName: string; pluralLabel: string }> =
                integrationConsts.getEntitiesMetadata()[integrationType.toUpperCase()];

            return Object.values(projectIntegrationCustomEntitiesConfig || {}) || [];
        } else {
            return [];
        }
    }, [integrationConsts, integrationType]);
};

export default useNativeEntitiesFromConsts;
