import { useAngularService } from 'angulareact';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import HistoryFieldsTableFieldsGroup from './HistoryFieldsTableFieldsGroup';
import HistoryFieldsTableLoader from './HistoryFieldsTableLoader';
import { ReactComponent as EmptyFieldsIcon } from '../../../../../images/icons/history/empty-fields.svg';
import { ReactComponent as NetworkErrorIcon } from '../../../../../images/icons/network-error.svg';
import type HistoryField from '../../entities/HistoryField';
import useFilterHistoryFieldsTable from '../../hooks/useFilterHistoryFieldsTable';
import useGroupHistoryTableFieldsByType from '../../hooks/useGroupHistoryTableFieldsByType';

import { ErrorStateMessage, StateMessage } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const FieldsTable = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${Theme.current.palette.general.border};
    border-radius: 3px;
    line-height: normal;
    flex-grow: 1;
    overflow: hidden;
`;

const TableHeader = styled.div`
    display: flex;
    min-height: 28px;
    padding: 0 20px;
    border-bottom: 1px solid ${Theme.current.palette.general.border};
`;

const TableHeaderSection = styled.div`
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.current.palette.TUI.notSelected};
`;

const FirstTableSection = styled(TableHeaderSection)`
    flex-basis: 30px;
`;

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`;

interface Props {
    /** If true, the table will have before and after columns. */
    couldHaveChanged?: boolean;
    /** Should show loading state? */
    tableLoading?: boolean;
    /** Should show fetching error? */
    tableInError?: boolean;
    /** Show show the no data collected error? */
    noDataCollected?: boolean;
    /** List of fields to show in the table. */
    fields: HistoryField[];
    /** List of field definition ids that took part in the action. */
    relatedFieldsIds?: string[];
    workflowVersionId: string | undefined;
    syncConfig?: any;

    /** The free text filter. */
    freeTextFilter?: string;
    /** Should show only empty values? */
    filterOnlyEmptyValue?: boolean;
    /** Should show only fields that were changed? Should not be used if couldHaveChanged is false. */
    filterOnlyValueChanged?: boolean;
    /** Should show only fields that are in the relatedFieldsIds list? Should not be used if relatedFieldsIds is not set. */
    filterInvolvedInAction?: boolean;
}

const HistoryFieldsTable: React.FC<Props> = ({
    couldHaveChanged = true,
    tableLoading = false,
    tableInError = false,
    noDataCollected = false,
    fields,
    relatedFieldsIds = [],
    workflowVersionId,
    freeTextFilter = '',
    filterOnlyEmptyValue = false,
    filterOnlyValueChanged = false,
    filterInvolvedInAction = false,
}) => {
    const [isGroupOpenMap, setIsGroupOpenMap] = useState<Record<string, boolean>>({});

    const toggleGroupOpenState = useCallback((fieldsGroupId: string, isOpen: boolean): void => {
        setIsGroupOpenMap((currentIsGroupOpenMap) => ({
            ...currentIsGroupOpenMap,
            [fieldsGroupId]: isOpen,
        }));
    }, []);

    const customTriggerManager = useAngularService('customTriggerManager');
    const fieldsGroups = useGroupHistoryTableFieldsByType(fields, workflowVersionId, customTriggerManager);
    const fieldsGroupsFiltered = useFilterHistoryFieldsTable(
        fieldsGroups,
        freeTextFilter,
        filterOnlyEmptyValue,
        filterOnlyValueChanged,
        filterInvolvedInAction,
        relatedFieldsIds,
    );

    const showLoading = tableLoading;
    const showError = tableInError && !showLoading;
    const showNoDataCollected = noDataCollected && !showLoading && !showError;
    const showEmptyState = fieldsGroupsFiltered.length === 0 && !showNoDataCollected && !showLoading && !showError;
    const showFields = fieldsGroupsFiltered.length !== 0 && !showNoDataCollected && !showLoading && !showError;

    return (
        <FieldsTable>
            <TableHeader>
                <FirstTableSection> Field {filterInvolvedInAction && '(From Action)'}</FirstTableSection>
                <TableHeaderSection data-automation="history-fields-table-value-before-action-column">
                    {couldHaveChanged ? 'Value before Action' : 'Value'}
                </TableHeaderSection>
                {couldHaveChanged && (
                    <TableHeaderSection data-automation="history-fields-table-value-after-action-column">
                        Value after Action
                    </TableHeaderSection>
                )}
            </TableHeader>

            <TableContent>
                {showLoading && <HistoryFieldsTableLoader />}

                {showError && (
                    <ErrorStateMessage
                        icon={
                            <span className="tnk-icon">
                                <NetworkErrorIcon />
                            </span>
                        }
                    >
                        Error - Couldn’t load data, please try again later
                    </ErrorStateMessage>
                )}

                {showNoDataCollected && (
                    <StateMessage
                        icon={
                            <span className="tnk-icon">
                                <EmptyFieldsIcon />
                            </span>
                        }
                        title="No Data Collected for this Module Item"
                    >
                        Module Item was updated successfully, yet the History service doesn’t
                        <br />
                        collect changes in Module Item fields at the moment
                    </StateMessage>
                )}

                {showEmptyState && (
                    <StateMessage
                        icon={
                            <span className="tnk-icon">
                                <EmptyFieldsIcon />
                            </span>
                        }
                    >
                        No Fields were detected yet
                    </StateMessage>
                )}

                {showFields && (
                    <>
                        {fieldsGroupsFiltered.map((fieldsGroup) => (
                            <HistoryFieldsTableFieldsGroup
                                key={fieldsGroup.id}
                                unFilteredFieldsGroups={fieldsGroups}
                                fieldsGroup={fieldsGroup}
                                toggleGroupOpenState={toggleGroupOpenState}
                                isOpen={isGroupOpenMap[fieldsGroup.id]}
                                freeTextFilter={freeTextFilter}
                                couldHaveChanged={couldHaveChanged}
                            />
                        ))}
                    </>
                )}
            </TableContent>
        </FieldsTable>
    );
};

export default HistoryFieldsTable;
