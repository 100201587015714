import styled, { css } from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';

export const HeaderWrapper = styled.div<{ isNonExtendable?: boolean }>`
    display: inline-flex;
    ${({ isNonExtendable }) =>
        isNonExtendable &&
        css`
            margin-left: 33px;
        `};
`;

export const ExtendableRowColoredText = styled.div<{ color: string }>`
    font-weight: 700;
    font-size: ${FontSize.SMALL_12};
    color: ${({ color }) => color};
`;

export const ExtendableRowText = styled.div`
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    margin-left: 3px;
`;

export const InnerContentWrapper = styled.div`
    padding: 20px;
`;
