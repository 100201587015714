import { Theme } from '@tonkean/tui-theme';
import type { InputSize } from '@tonkean/tui-theme/sizes';

const taggableEntitiesStyle = ($isError: boolean, $isHighlighted: boolean, size: InputSize) => ({
    '&singleLine': {
        input: {
            width: '100%',
            border: 'none',
            color: Theme.colors.gray_800,
            padding: `0 ${Theme.sizes.input[size].paddingRightLeft}px`,
            height: `${Theme.sizes.input[size].height}px`,
            fontSize: `${Theme.sizes.input[size].fontSize}px`,
            lineHeight: `${Theme.sizes.input[size].lineHeight}px`,
            fontWeight: '400',
            outline: 'none',
        },
        highlighter: {
            width: '100%',
            borderRadius: '3px',
            padding: `0 ${Theme.sizes.input[size].paddingRightLeft}px`,
            height: `${Theme.sizes.input[size].height}px`,
            fontSize: `${Theme.sizes.input[size].fontSize}px`,
            lineHeight: `${Theme.sizes.input[size].lineHeight}px`,
            fontWeight: '400',
            outline: 'none',
        },
        suggestions: {
            zIndex: '5',
            backgroundColor: 'transparent',

            list: {
                borderRadius: '4px',
                border: `1px solid ${Theme.colors.gray_400}`,
                fontSize: 14,
                maxHeight: '20vh',
                overflow: 'scroll',
                boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
                overflowY: 'auto',
                overflowX: 'auto',
                paddingTop: '8px',
                paddingRight: '16px',
                paddingBottom: '16px',
                paddingLeft: '16px',
                marginTop: '16px',
                backgroundColor: `${Theme.colors.white}`,
            },
        },
    },
});

export default taggableEntitiesStyle;
