import type { HttpRequestDefinition } from '@tonkean/tonkean-entities';
import { ActionDefinitionType, HttpMethodType, HttpRequestBodyType } from '@tonkean/tonkean-entities';

const defaultCustomGetHttpActionDefinition: HttpRequestDefinition = {
    url: {
        originalExpression: '{{env_url}}/',
        evaluatedExpression: '{{env_url}}/',
        isStripHtmlDisabled: false,
    },
    body: {
        originalExpression: '',
        evaluatedExpression: '',
        isStripHtmlDisabled: false,
    },
    methodType: HttpMethodType.GET,
    headers: [],
    contentType: 'application/json',
    disableAutoCharset: false,
    disableFollowRedirects: false,
    ignoreUntrustedCertificates: false,
    keepTrailingForwardSlash: false,
    wasUrlConvertedToEnvUrlAsParameterFormat: true,
    shouldIncludeFileDownloading: false,
    removeKeysWithEmptyValueFromBody: false,
    enableKeyValueParameters: false,
    maxRetries: 3,
    definitionType: ActionDefinitionType.HTTP,
    queryParams: [],
    formBodyParameters: [],
    bodyType: HttpRequestBodyType.RAW,
};

export default defaultCustomGetHttpActionDefinition;
