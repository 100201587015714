import { useCallback, useEffect, useMemo, useState } from 'react';

import useFieldsValuesUpdater from './useFieldsValuesUpdater';
import { useInterfaceTabs } from './useInterfaceTabs';
import useItemInterfaceFieldDefinitions from './useItemInterfaceFieldDefinitions';
import useItemInterfaceUpdateAnalyticsContext from './useItemInterfaceUpdateAnalyticsContext';
import useItemInterfaceActionsState from '../SystemInterface/hooks/useItemInterfaceActionsState';

import { useProject } from '@tonkean/infrastructure';
import type { ItemInterfaceContextData } from '@tonkean/infrastructure';
import type { ItemInterfaceSection, ReactUseState } from '@tonkean/infrastructure';
import type {
    IncludedWidgetsSummaryByInterface,
    Initiative,
    ItemInterface,
    ItemInterfaceWidget,
    PluggableActionType,
    SelectedPluggableAction,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import type { SequenceState } from '@tonkean/tonkean-entities';

interface UseItemInterfaceContextDataBuilderProps {
    initiative: Initiative | undefined;
    initiativeInitialLoading: boolean;
    intakeInitiative?: Initiative;
    itemInterface: ItemInterface | undefined;
    section: ItemInterfaceSection | undefined;
    workflowVersion?: WorkflowVersion;
    intakeWorkflowVersion?: WorkflowVersion;
    includedWidgetsSummaryByInterfaceId?: IncludedWidgetsSummaryByInterface;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    currentCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    widgetsToDisplay: ItemInterfaceWidget[];
    allInterfaceWidgets: ItemInterfaceWidget[];
    itemInterfaceContextDataError?: any;

    reloadInitiativeFromServer?(updatedInitiative?: Initiative | undefined): void;

    applyFastReloadInterval: (() => void) | undefined;

    pluggableActionsToShowSettings?: PluggableActionType[];
    selectedPluggableActionStateOverride?: ReactUseState<SelectedPluggableAction | undefined>;
    currentSequenceState?: SequenceState;
    setCurrentSequenceState?: (sequenceState: SequenceState) => void;

    setPollInitiativeEnabled?: (pollInitiativeEnabled: boolean) => void;
}

function useItemInterfaceContextDataBuilder({
    initiative,
    initiativeInitialLoading,
    intakeInitiative,
    itemInterface,
    section,
    workflowVersion,
    intakeWorkflowVersion,
    includedWidgetsSummaryByInterfaceId,
    originatedCustomTriggerId,
    currentCustomTriggerId,
    widgetsToDisplay,
    allInterfaceWidgets,
    itemInterfaceContextDataError,
    reloadInitiativeFromServer,
    applyFastReloadInterval,
    pluggableActionsToShowSettings,
    selectedPluggableActionStateOverride,
    currentSequenceState,
    setCurrentSequenceState,
    setPollInitiativeEnabled,
}: UseItemInterfaceContextDataBuilderProps) {
    useItemInterfaceUpdateAnalyticsContext(itemInterface, initiative);

    const project = useProject();

    const { actionsHiddenLiveDisplay, actionsDisabledLiveDisplay, loadingActionsLiveDisplay } =
        useItemInterfaceActionsState({
            initiative,
            itemInterface,
            widgets: widgetsToDisplay,
        });

    const { itemInterfaceFieldDefinitions, loadingItemInterfaceFieldDefinitions } = useItemInterfaceFieldDefinitions({
        allInterfaceWidgets,
        projectId: project.id,
        workflowVersionId: workflowVersion?.id,
        isDraftInitiative: initiative?.isDraftInitiative ?? true,
        groupId: initiative?.group.id,
    });

    const selectedPluggableActionState = useState<SelectedPluggableAction | undefined>(undefined);
    const pluggableActionHoverState = useState<boolean>(false);

    const updateFieldsValuesManager = useFieldsValuesUpdater(reloadInitiativeFromServer);

    useEffect(() => {
        setPollInitiativeEnabled?.(!updateFieldsValuesManager.inActiveFieldsValuesUpdate);
    }, [setPollInitiativeEnabled, updateFieldsValuesManager.inActiveFieldsValuesUpdate]);

    const [widgetsInnerItems, setWidgetsInnerItems] = useState<{
        [widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>]: Initiative[];
    }>({});

    const updateWidgetsInnerItems = useCallback(
        (widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>, innerItems: Initiative[]) => {
            setWidgetsInnerItems((prev) => ({
                ...prev,
                [widgetId]: innerItems,
            }));
        },
        [setWidgetsInnerItems],
    );

    const tabs = useInterfaceTabs(widgetsToDisplay, includedWidgetsSummaryByInterfaceId);

    return useMemo<ItemInterfaceContextData>(() => {
        return {
            loadingRequiredInformation: loadingItemInterfaceFieldDefinitions || loadingActionsLiveDisplay,
            initiative,
            initiativeInitialLoading,
            intakeInitiative,
            itemInterface,
            section,
            project,
            workflowVersion,
            intakeWorkflowVersion,
            actionsDisabledLiveDisplay,
            actionsHiddenLiveDisplay,
            tabs,
            includedWidgetsSummaryByInterfaceId,
            originatedCustomTriggerId,
            currentCustomTriggerId,
            widgetsToDisplay,
            allInterfaceWidgets,
            itemInterfaceContextDataError,
            itemInterfaceFieldDefinitions,
            loadingItemInterfaceFieldDefinitions,
            reloadInitiativeFromServer,
            applyFastReloadInterval,
            selectedPluggableActionState: selectedPluggableActionStateOverride || selectedPluggableActionState,
            pluggableActionHoverState,
            pluggableActionsToShowSettings,
            updateWidgetsInnerItems,
            widgetsInnerItems,
            currentSequenceState,
            setCurrentSequenceState,
            updateFieldsValuesManager,
        };
    }, [
        loadingItemInterfaceFieldDefinitions,
        loadingActionsLiveDisplay,
        initiative,
        initiativeInitialLoading,
        intakeInitiative,
        itemInterface,
        section,
        project,
        workflowVersion,
        intakeWorkflowVersion,
        actionsDisabledLiveDisplay,
        actionsHiddenLiveDisplay,
        tabs,
        includedWidgetsSummaryByInterfaceId,
        originatedCustomTriggerId,
        currentCustomTriggerId,
        widgetsToDisplay,
        allInterfaceWidgets,
        itemInterfaceContextDataError,
        itemInterfaceFieldDefinitions,
        reloadInitiativeFromServer,
        applyFastReloadInterval,
        selectedPluggableActionStateOverride,
        selectedPluggableActionState,
        pluggableActionHoverState,
        pluggableActionsToShowSettings,
        updateWidgetsInnerItems,
        widgetsInnerItems,
        currentSequenceState,
        setCurrentSequenceState,
        updateFieldsValuesManager,
    ]);
}

export default useItemInterfaceContextDataBuilder;
