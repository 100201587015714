import React from 'react';

import NetsuiteAddJournalEntryCustomActionConfiguration from './NetsuiteAddJournalEntryCustomActionConfiguration';
import NetsuiteAddPurchaseOrderCustomActionConfiguration from './NetsuiteAddPurchaseOrderCustomActionConfiguration';
import NetsuiteAddUpdatePOItemsCustomActionConfiguration from './NetsuiteAddUpdatePOItemsCustomActionConfiguration';
import NetsuiteUpdateJournalEntryCustomActionConfiguration from './NetsuiteUpdateJournalEntryCustomActionConfiguration';

import type { LogicConfigurationProps } from '@tonkean/logic-configuration';
import { LogicConfigurationContainer } from '@tonkean/logic-configuration';

const NetsuiteCustomActions: React.FC<LogicConfigurationProps> = (props) => {
    return (
        <LogicConfigurationContainer>
            {props.definition.customActionKey === 'ADD_JOURNAL_ENTRY' && (
                <NetsuiteAddJournalEntryCustomActionConfiguration {...props} />
            )}

            {props.definition.customActionKey === 'UPDATE_JOURNAL_STATUS' && (
                <NetsuiteUpdateJournalEntryCustomActionConfiguration {...props} />
            )}

            {props.definition.customActionKey === 'ADD_PURCHASE_ORDER' && (
                <NetsuiteAddPurchaseOrderCustomActionConfiguration {...props} />
            )}

            {props.definition.customActionKey === 'ADD_UPDATE_PURCHASE_ORDER_LINE_ITEMS' && (
                <NetsuiteAddUpdatePOItemsCustomActionConfiguration {...props} />
            )}
        </LogicConfigurationContainer>
    );
};

export default NetsuiteCustomActions;
