import { useAngularService } from 'angulareact';
import React from 'react';

import type { WorkerRunReasonSourceWorkerRun } from '@tonkean/tonkean-entities';
import { ClickableLink, LinkUnderline } from '@tonkean/tui-buttons/Link';

interface Props {
    workerRunReasonSourceWorkerRun: WorkerRunReasonSourceWorkerRun;
}

const SingleWorkerRunModuleItemSourceWorkerRunLink: React.FC<Props> = ({ workerRunReasonSourceWorkerRun }) => {
    const groupInfoManager = useAngularService('groupInfoManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const group = groupInfoManager.getGroupByIdFromCache(workerRunReasonSourceWorkerRun.groupId);

    return group ? (
        <ClickableLink
            state="product.workerEditor.history"
            params={{
                g: workerRunReasonSourceWorkerRun.groupId,
                wv: workerRunReasonSourceWorkerRun.workflowVersionId,
                workflowVersionType: workflowVersionManager.isDraftVersion(
                    workerRunReasonSourceWorkerRun.workflowVersionId,
                )
                    ? 'build'
                    : 'production',
                f: ['FLOW_RUN', 'MODULE_ITEM', 'DATA_SOURCE'],
                workerRunInfo: undefined,
                wr: undefined,
                defaultFilterSearchTerm: workerRunReasonSourceWorkerRun.initiativeId,
                from: workerRunReasonSourceWorkerRun.startTime / 1000,
                to: workerRunReasonSourceWorkerRun.startTime / 1000 + 7200,
            }}
            underline={LinkUnderline.ALWAYS}
            openInNewTab
        >
            {group.name}
        </ClickableLink>
    ) : (
        <></>
    );
};

export default SingleWorkerRunModuleItemSourceWorkerRunLink;
