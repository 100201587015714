import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const ConfigurationSectionTitle = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
    color: ${Theme.colors.gray_700};
    margin-bottom: 10px;
`;

export default ConfigurationSectionTitle;
