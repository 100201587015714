import { useAngularService, useAngularWatch } from 'angulareact';
import { useCallback, useMemo } from 'react';

import useCurrentUserRole from './useCurrentUserRole';

import { useGetStateParams } from '@tonkean/angular-hooks';
import type {
    Person,
    SolutionBusinessReport,
    SolutionBusinessReportPermissions,
    SolutionBusinessReportPermissionType,
} from '@tonkean/tonkean-entities';
import { SolutionBusinessReportAccessPermission } from '@tonkean/tonkean-entities';

/**
 * A hook that checks whether the current user has access to the specified SolutionBusinessReport.
 * To check whether a user has any access to the Solution Report, you should check the 'view' property, as it will be true if the user has 'view' or 'edit' access.
 *
 * @returns an object describing which permission the user has.
 */
function useUserSolutionBusinessReportAccess(
    solutionBusinessReport: SolutionBusinessReport | undefined,
): Record<SolutionBusinessReportPermissionType, boolean> {
    const $rootScope = useAngularService('$rootScope');
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const [currentUser] = useAngularWatch(() => $rootScope['currentUser'] as Person);
    const currentUserRole = useCurrentUserRole();
    const [projectId] = useGetStateParams('projectId');

    const isMaker = useMemo(() => {
        if (!solutionBusinessReport?.solutionId) {
            return false;
        }

        return (
            workflowFolderManager.projectIdToFolderIdToFolderMap[projectId]?.[solutionBusinessReport.solutionId]
                ?.isUserOwner ?? false
        );
    }, [projectId, solutionBusinessReport?.solutionId, workflowFolderManager.projectIdToFolderIdToFolderMap]);

    const userHasAccess = useCallback(
        (permissions: SolutionBusinessReportPermissions) => {
            if (currentUserRole.systemUser && isMaker) {
                return true;
            }

            switch (permissions.permission) {
                case SolutionBusinessReportAccessPermission.NO_ONE: {
                    return false;
                }
                case SolutionBusinessReportAccessPermission.ALL_PROCESS_CONTRIBUTORS: {
                    return currentUserRole.processContributor;
                }
                case SolutionBusinessReportAccessPermission.SPECIFIC_PEOPLE: {
                    return permissions.peopleIds.includes(currentUser.id);
                }
                default: {
                    return false;
                }
            }
        },
        [currentUser.id, currentUserRole.processContributor, currentUserRole.systemUser, isMaker],
    );

    const userHasEditAccess: boolean = useMemo(() => {
        if (!solutionBusinessReport?.permissions.EDIT) {
            return false;
        }

        return userHasAccess(solutionBusinessReport.permissions.EDIT);
    }, [solutionBusinessReport?.permissions.EDIT, userHasAccess]);

    const userHasViewAccess: boolean = useMemo(() => {
        if (!solutionBusinessReport?.permissions.VIEW) {
            return false;
        }

        return userHasAccess(solutionBusinessReport.permissions.VIEW) || userHasEditAccess;
    }, [solutionBusinessReport?.permissions.VIEW, userHasAccess, userHasEditAccess]);

    return {
        VIEW: userHasViewAccess,
        EDIT: userHasEditAccess,
    };
}

export default useUserSolutionBusinessReportAccess;
