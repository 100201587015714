import { FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP } from '@tonkean/tonkean-utils';

/**
 * Hook for migrations on widget fields configurations.
 *
 * This is a hack so we can support changes on the fly without having to do backwards compatibility
 */
function useWidgetConfigurationFieldsSoftMigration<T>(configurationFields: T): T {
    const statusTextId = FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP.TNK_STATUS_TEXT.id;

    // TNK_STATUS_TEXT is the wrong special field, TNK_STAGE should be the one used
    if (configurationFields?.[statusTextId]) {
        const stageId = FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP.TNK_STAGE.id;
        configurationFields[stageId] = configurationFields[statusTextId];
        delete configurationFields[statusTextId];
    }
    return configurationFields;
}

export default useWidgetConfigurationFieldsSoftMigration;
