import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import SmartSearchLookupEntityBox from './SmartSearchLookupEntityBox';

import { AITypewriter } from '@tonkean/ai-builder';
import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    AppearFromTopAnimation,
    IconSvg,
    LIMIT_PARAM,
    PageBrowser,
    SimpleErrorStateMessage,
    SKIP_PARAM,
    useFetchManager,
} from '@tonkean/infrastructure';
import { SmartConversationResponseText } from '@tonkean/smart-conversation';
import { SmartSearchIcon } from '@tonkean/svg';
import type {
    SmartConversationMessage,
    SmartSearchEntityAnalysisResponse,
    SmartSearchGetInformationCategoryResponse,
    SmartSearchLookupEntityRelevance,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { SmartSearchLookupRelevanceType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const Container = styled.div`
    display: grid;
`;

const ResponseContainer = styled.div`
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'gutter text'
        'gutter buttons';
    column-gap: 10px;
`;

const ResponseIcon = styled(IconSvg)`
    grid-area: gutter;
    margin-top: 4px;
`;

const ResponseTextContainer = styled.div`
    grid-area: text;
    display: grid;
    gap: 20px;
`;

const CenteredAppearFromTopAnimation = styled(AppearFromTopAnimation)`
    justify-self: center;
`;

const MoreResults = styled.div`
    color: ${Theme.colors.gray_500};
`;

const getEntityLabel = (entity: SmartSearchLookupEntityRelevance) => {
    if (entity.type === SmartSearchLookupRelevanceType.EXTERNAL_ACTIVITY) {
        return `${entity.projectIntegrationId}-${entity.entityId}`;
    } else if (entity.type === SmartSearchLookupRelevanceType.INITIATIVE) {
        return `${entity.groupId}-${entity.entityId}}`;
    } else {
        return 'unknown';
    }
};

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    message: SmartConversationMessage<SmartSearchGetInformationCategoryResponse>;
    userQuestion: string;
    onRequestAnalysis: (data: SmartSearchEntityAnalysisResponse) => void;
    setLoading: (value: boolean) => void;
    onAnimationEnd: () => void;
}

const SmartSearchGetInformationSkillCategoryView: React.FC<Props> = ({
    projectId,
    message,
    userQuestion,
    onRequestAnalysis,
    setLoading,
    onAnimationEnd,
}) => {
    const tonkeanService = useAngularService('tonkeanService');
    const [activeEntityIndex, setActiveEntityIndex] = useState(0);

    const [
        [getSmartSearchLookupResult, cancelFetcher],
        { data: lookupResultEntities, loading, error, loadNextPage, hasMorePages },
    ] = useFetchManager(tonkeanService, 'getSmartSearchLookupResult', {
        limit: 20,
        checkHasMore: (response, currentItemsCount) => currentItemsCount < response.totalHits,
        compareItems: 'entityId',
    });

    useEffect(() => {
        const alternativeSearchKeywords = message.response.alternativeSearchKeywords
            ? message.response.alternativeSearchKeywords.split(',')
            : [];

        const queryBoost = [...alternativeSearchKeywords, message.response.informationToFind];

        const suggestDataSources = message.response.suggestDataSources
            ? message.response.suggestDataSources.split(',')
            : [];
        const suggestGroups = message.response.suggestGroups ? message.response.suggestGroups.split(',') : [];

        getSmartSearchLookupResult(
            projectId,
            message.response.entityName,
            queryBoost,
            userQuestion,
            suggestDataSources,
            suggestGroups,
            SKIP_PARAM,
            LIMIT_PARAM,
        );
        return cancelFetcher;
    }, [
        cancelFetcher,
        getSmartSearchLookupResult,
        projectId,
        message.response.alternativeSearchKeywords,
        message.response.informationToFind,
        message.response.entityName,
        userQuestion,
        message.response.suggestDataSources,
        message.response.suggestGroups,
    ]);

    const activeEntity = lookupResultEntities?.[activeEntityIndex];

    const activeEntityLabel = !!activeEntity ? getEntityLabel(activeEntity) : undefined;

    const requestAnalysis = useCallback(
        (result: Promise<SmartSearchEntityAnalysisResponse>) => {
            setLoading(true);
            result
                .then((e) => {
                    onRequestAnalysis(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [onRequestAnalysis, setLoading],
    );

    // Animation order flags
    const [doneTypingLookingFor, setDoneTypingLookingFor] = useState(false);
    const [doneTypingFoundText, setDoneTypingFoundText] = useState(false);
    const [doneShowingAnswerAnimation, setDoneShowingAnswerAnimation] = useState(false);
    const [showAllResults, setShowAllResults] = useState(false);

    useEffect(() => {
        setLoading(loading.any && doneTypingLookingFor);
    }, [doneTypingLookingFor, loading.any, setLoading]);

    return (
        <Container>
            <ResponseContainer>
                <ResponseIcon as={SmartSearchIcon} />
                <ResponseTextContainer>
                    <SmartConversationResponseText>
                        <AITypewriter
                            skipAnimation={!message.showAnimation}
                            text={`Looking for ${
                                message.response.informationToFind
                                    ? `"${message.response.informationToFind}" with relationship to `
                                    : ''
                            }"${message.response.entityName}"`}
                            onTypingDone={setDoneTypingLookingFor}
                        />
                    </SmartConversationResponseText>

                    {doneTypingLookingFor && !loading.any && !lookupResultEntities?.length && (
                        <SmartConversationResponseText>
                            <AITypewriter
                                skipAnimation={!message.showAnimation}
                                text={`I couldn't find any relevant entities`}
                                onTypingDone={(isDone) => {
                                    if (isDone) {
                                        onAnimationEnd();
                                    }
                                }}
                            />
                        </SmartConversationResponseText>
                    )}

                    {doneTypingLookingFor && lookupResultEntities?.length > 0 && (
                        <SmartConversationResponseText>
                            <AITypewriter
                                skipAnimation={!message.showAnimation}
                                text={`Here's what I found:`}
                                onTypingDone={setDoneTypingFoundText}
                            />
                        </SmartConversationResponseText>
                    )}

                    {doneTypingFoundText && lookupResultEntities?.length > 0 && (
                        <AppearFromTopAnimation
                            skipAnimation={!message.showAnimation}
                            onAnimationEnd={() => setDoneShowingAnswerAnimation(true)}
                        >
                            <AnimatedSwitch activeLabel={activeEntityLabel}>
                                {lookupResultEntities.map((entity, index) => {
                                    const label = getEntityLabel(entity);

                                    return (
                                        <AnimatedSwitchItem key={label} label={label}>
                                            <SmartSearchLookupEntityBox
                                                entityRelevance={entity}
                                                conversationId={message.conversationId}
                                                onAnalysisRequested={requestAnalysis}
                                                analyseOnLoad={index === 0}
                                            />
                                        </AnimatedSwitchItem>
                                    );
                                })}
                            </AnimatedSwitch>
                        </AppearFromTopAnimation>
                    )}

                    {doneShowingAnswerAnimation && (
                        <CenteredAppearFromTopAnimation skipAnimation={!message.showAnimation}>
                            <>
                                {showAllResults && (
                                    <PageBrowser
                                        state={activeEntityIndex + 1}
                                        setState={setActiveEntityIndex}
                                        max={lookupResultEntities.length}
                                        text="Result"
                                    />
                                )}
                                {!showAllResults && lookupResultEntities?.length > 1 && (
                                    <MoreResults>
                                        Not the right result?{' '}
                                        <ClickableLink onClick={() => setShowAllResults(true)}>
                                            Try other options...
                                        </ClickableLink>
                                    </MoreResults>
                                )}
                            </>
                        </CenteredAppearFromTopAnimation>
                    )}
                    {error && <SimpleErrorStateMessage error={error} showSmallError />}
                </ResponseTextContainer>
            </ResponseContainer>
        </Container>
    );
};

export default SmartSearchGetInformationSkillCategoryView;
