<div class="common-close-btn-container">
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <form class="padding-normal" ng-submit="import()">
        <div ng-if="data.isLoading" class="text-center">
            <div class="loading-large"></div>
        </div>

        <div ng-if="!data.isLoading">
            <!-- Title -->
            <div class="common-title margin-bottom">
                {{ data.isEdit ? 'Edit' : 'Add' }} {{ data.projectIntegration.displayName }} Item
                {{ data.isEdit ? "'" + data.item[data.idFieldPathName] + "'" : '' }}
            </div>

            <hr />

            <!-- Existing Fields -->
            <div>
                <!-- Required keys-->
                <div ng-if="data.requiredFields.length > 0" class="margin-bottom-lg">
                    <div class="common-title-xxs margin-bottom">Required Fields</div>

                    <div class="flex-vmiddle margin-bottom-xs">
                        <div class="common-width-25 common-title-xxxs">Name</div>
                        <div class="common-title-xxxs margin-left-xs">Value</div>
                    </div>

                    <div
                        ng-repeat="field in data.requiredFields"
                        class="flex-vmiddle margin-bottom-xs"
                        data-automation="edit-custom-webhook-item-modal-required-field-row"
                    >
                        <div
                            class="flex-basis-33 common-bold form-control margin-right-xs edit-custom-webhook-field-key"
                            data-automation="edit-custom-webhook-item-modal-required-field-key"
                        >
                            {{ field.key }}
                        </div>
                        <input
                            ng-disabled="data.isEdit && field.nonEditable"
                            ng-model="field.value"
                            ng-required="true"
                            class="form-control"
                            placeholder="Value..."
                            data-automation="edit-custom-webhook-item-modal-required-field-value"
                        />
                    </div>
                </div>

                <!-- Optional keys -->

                <div>
                    <div class="common-title-xxs margin-bottom">Optional Fields</div>

                    <div class="flex-vmiddle margin-bottom-xs">
                        <div class="common-width-25 common-title-xxxs">Name</div>
                        <div class="common-title-xxxs margin-left-xs">Value</div>
                    </div>

                    <div
                        ng-repeat="field in data.optionalFields"
                        class="flex-vmiddle margin-bottom-xs"
                        data-automation="edit-custom-webhook-item-modal-optional-field-row"
                    >
                        <!-- Key -->
                        <div
                            ng-if="!field.keyEditable"
                            class="
                                common-bold common-ellipsis
                                form-control
                                margin-right-xs
                                edit-custom-webhook-field-key
                            "
                            uib-tooltip="{{ field.key }}"
                            tooltip-enable="field.key.length > 13"
                        >
                            {{ field.key }}
                        </div>
                        <input
                            ng-if="field.keyEditable"
                            ng-model="field.key"
                            class="form-control margin-right-xs edit-custom-webhook-field-key"
                            data-automation="edit-custom-webhook-item-modal-optional-field-key"
                            placeholder="Field Name..."
                        />

                        <!-- Value -->
                        <input
                            ng-model="field.value"
                            class="form-control"
                            data-automation="edit-custom-webhook-item-modal-optional-field-value"
                            placeholder="Value..."
                        />
                    </div>

                    <!-- No custom fields -->
                    <div
                        ng-if="data.optionalFields.length === 0"
                        class="common-color-light-grey text-center common-width-full margin-top"
                    >
                        No custom fields
                    </div>
                </div>
            </div>

            <hr />

            <!-- Enter new Field -->
            <div class="common-width-full flex mod-justify-end">
                <button
                    type="button"
                    class="btn btn-primary"
                    data-automation="edit-custom-webhook-item-modal-add-new-field"
                    ng-click="addNewField()"
                >
                    + Add Field
                </button>
            </div>

            <!-- Done -->
            <div class="flex-vmiddle margin-top-lg">
                <button
                    type="submit"
                    data-automation="edit-custom-webhook-item-modal-submit-button"
                    class="btn btn-primary"
                >
                    Submit
                </button>
                <span ng-if="data.isSubmitLoading" class="loading-small margin-left"></span>
            </div>

            <!-- Error -->
            <div ng-if="data.error" class="common-color-danger">{{ data.error }}</div>
        </div>
    </form>
</div>
