function CsvHelper($log, utils, papaparse) {
    const _this = this;

    _this.parseFile = function (file, callback, onError) {
        papaparse.parse(file, {
            complete(results, csvFile) {
                $log.debug('CSV results:');
                $log.debug(results);

                const table = results.data;
                const headerRow = table[0];

                // Collect empty column indices.
                const emptyIndices = [];
                for (const [i, columnHeader] of headerRow.entries()) {
                    if (utils.isNullOrEmpty(columnHeader)) {
                        emptyIndices.push(i);
                    }
                }

                // Remove all empty columns starting from the end so we wouldn't mess up the indices.
                for (let i = emptyIndices.length - 1; i >= 0; i--) {
                    const indexToRemove = emptyIndices[i];
                    for (const row of table) {
                        row.splice(indexToRemove, 1);
                    }
                }

                callback(table, csvFile);
            },
            error(error) {
                console.log(error);
                if (onError) {
                    onError(error);
                }
            },
        });
    };
}

export default angular.module('tonkean.app').service('csvHelper', CsvHelper);
