import template from './tnkWorkerRunLogic.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerRunLogic', {
    bindings: {
        logic: '<',
        forceExpandMap: '<',
        selectedRunLogicsMap: '<',
        workerRun: '<',
        collapsed: '<',
        showInspect: '<',
        groupId: '<',
        workflowVersionId: '@',

        onInspect: '&',
    },
    template,
    controller: 'WorkerRunLogicCtrl',
});
