const groupedDepartments = [
    {
        label: 'Sales',
        options: [
            'Customer Service',
            'Customer Success',
            'Account Managers',
            'Account Executives',
            'Inside Sales',
            'SDR',
            'SDR Inbound',
            'SDR Outbound',
            'Business Development Representative',
            'Pre-Sales',
            'Solutions',
            'Sales Engineer',
            'Sales Enablement',
            'Sales Training',
            'Sales Operations',
            'Revenue Operations',
            'Account Exec - SMB',
            'Account Exec - MM',
            'Account Exec - Enterprise',
        ],
    },
    {
        label: 'Finance',
        options: [
            'Procurement',
            'Contracts',
            'Mail Services',
            'Purchasing',
            'Travel',
            'Cost Accounting',
            'Tax Compliance Officer',
            'Insurance Management',
            'Risk Management',
            'Budget & Payroll',
            'Controller & Treasurer',
            'Financial Records',
            'Fiscal Operations',
            'Finance Operations',
            'Compensation',
            'Accounts Payable',
            'Accounts Receivable',
        ],
    },
    {
        label: 'Marketing',
        options: [
            'Product Marketing',
            'Marketing Operations',
            'Graphic Designer',
            'Demand Gen',
            'Public Relations',
            'Community',
            'Content Manager',
        ],
    },
    {
        label: 'HR',
        options: ['Recruiting', 'People Operations', 'Human Resources'],
    },
    {
        label: 'Engineering',
        options: ['QA', 'Dev', 'Design', 'Product Management'],
    },
].map((group) => ({
    ...group,
    options: group.options.map((option) => ({
        label: option,
        value: option,
    })),
}));

export default groupedDepartments;
