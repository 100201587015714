function ReferralModalCtrl($scope, tonkeanService, utils, environment) {
    $scope.data = {};

    function init() {
        tonkeanService.getReferralCode().then(function (data) {
            $scope.data.referralCode = data.referralCode;
            $scope.data.referralUrl = `${environment.appUrl}login?r=${data.referralCode}`;
        });
    }

    $scope.copyUrlToClipboard = function (elementId) {
        const urlElement = document.getElementById(elementId);
        const copyResult = utils.copyToClipboardFromInput(urlElement);

        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'URL copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };

    $scope.socialShare = function (type) {
        let text =
            'I love @tonkean! A.I. powered dashboard with automatic insights from your team. Try it out using this link and get $50 off!';
        text = encodeURI(text);

        let windowUrl;
        const referralUrl = $scope.data.referralUrl;

        switch (type) {
            case 'facebook':
                windowUrl = `https://www.facebook.com/sharer/sharer.php?u=${referralUrl}&quote=${text}`;
                break;
            case 'twitter':
                windowUrl = `https://twitter.com/home?status=${text}+${referralUrl}`;
                break;
            case 'linkedin':
                windowUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${referralUrl}&title=Tonkean&summary=${text}`;
                break;
            case 'email':
                window.location.href = `mailto:?subject=Tonkean&body=${text}%20${referralUrl}`;
                break;
        }

        if (windowUrl) {
            window.open(
                windowUrl,
                'targetWindow',
                'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250',
            );
        }
    };

    init();
}

angular.module('tonkean.app').controller('ReferralModalCtrl', ReferralModalCtrl);
