import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import ProjectIntegrationActionParametersView from './ProjectIntegrationActionParametersView';
import ProjectIntegrationActionSqlConfiguration from './ProjectIntegrationActionSqlConfiguration';
import useEnterpriseComponentVariablesExpressionTab from '../../../../../../EnterpriseComponentsModule/hooks/useEnterpriseComponentVariablesExpressionTab';
import HttpRequestConfiguration from '../../../../../../HttpRequestConfiguration/HttpRequestConfiguration';
import type { ConstantActionConfig } from '../../../projectIntegrationActionConstantParamIdToConfig';
import ProjectIntegrationActionConstantParams from '../../../projectIntegrationActionConstantParamIdToConfig';

import { FormikAutosave } from '@tonkean/infrastructure';
import type {
    BaseActionParameter,
    HttpRequestDefinition,
    ProjectIntegration,
    ProjectIntegrationAction,
    TonkeanExpressionAdditionalTab,
} from '@tonkean/tonkean-entities';
import { ActionDefinitionType, ActionParameterType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const SectionsWrapper = styled.div`
    display: flex;
    overflow: auto;
    height: inherit;
    min-height: 600px;
`;

const LeftSection = styled.div`
    width: 532px;
    margin-right: 72px;
`;

const RightSection = styled.div`
    width: 302px;
`;

const RequestTitle = styled.div`
    color: ${Theme.colors.gray_800};
    margin-bottom: 5px;
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
`;

const QueryNoteText = styled.div`
    color: ${Theme.colors.gray_700};
    margin-bottom: 20px;
    font-size: ${FontSize.XSMALL_10};
`;

interface Props {
    projectIntegration: ProjectIntegration;
    projectIntegrationAction: ProjectIntegrationAction;
    setProjectIntegrationAction: React.Dispatch<React.SetStateAction<Partial<ProjectIntegrationAction>>>;
}

const ProjectIntegrationActionCRUDPage: React.FC<Props> = ({
    projectIntegration,
    projectIntegrationAction,
    setProjectIntegrationAction,
}) => {
    const integrations = useAngularService('integrations');
    const integrationMetadataManager = useAngularService('integrationMetadataManager');

    const constantParameters: ConstantActionConfig[] = useMemo(() => {
        const tempConstantParams = Object.values(ProjectIntegrationActionConstantParams)
            .filter((param) => param.definitionTypes.includes(projectIntegrationAction.actionDefinition.definitionType))
            .filter((param) => !param.excludeInActionTypes.includes(projectIntegrationAction.actionType));

        if (
            projectIntegrationAction.actionDefinition.definitionType === ActionDefinitionType.HTTP &&
            projectIntegrationAction.actionDefinition.shouldIncludeFileDownloading
        ) {
            tempConstantParams.push(
                ProjectIntegrationActionConstantParams.file_stream,
                ProjectIntegrationActionConstantParams.tonkean_file_name,
                ProjectIntegrationActionConstantParams.tonkean_mime_type,
            );
        }

        if (projectIntegrationAction.parametersDefinition?.isChildEntitiesEnabled) {
            tempConstantParams.push(ProjectIntegrationActionConstantParams.child_entities);
        }
        if (projectIntegrationAction.parametersDefinition?.isCustomFieldsEnabled) {
            tempConstantParams.push(
                ProjectIntegrationActionConstantParams.input_object,
                ProjectIntegrationActionConstantParams.custom_fields,
            );
        }

        return tempConstantParams;
    }, [
        projectIntegrationAction.actionDefinition,
        projectIntegrationAction.parametersDefinition?.isChildEntitiesEnabled,
        projectIntegrationAction.parametersDefinition?.isCustomFieldsEnabled,
        projectIntegrationAction.actionType,
    ]);

    const enterpriseComponentVariablesTab = useEnterpriseComponentVariablesExpressionTab(
        projectIntegration.projectId,
        projectIntegration.id,
        projectIntegration.id,
    );

    const additionalActionParameters = useMemo<BaseActionParameter[]>(
        () => projectIntegrationAction?.parametersDefinition?.parameters || [],
        [projectIntegrationAction?.parametersDefinition?.parameters],
    );

    const actionParametersToFilter = useMemo<string[]>(() => {
        return (
            projectIntegrationAction?.parametersDefinition?.parameters
                ?.filter((parameter) => parameter.parameterType === ActionParameterType.FIELD_LIST)
                .map((parameter) => parameter.id) || []
        );
    }, [projectIntegrationAction?.parametersDefinition?.parameters]);

    const customActionParamsTab = useMemo<TonkeanExpressionAdditionalTab>(
        () => ({
            id: 'CUSTOM_ACTION_PARAMS',
            label: 'Custom Action Params',
            iconClass: 'mod-actions',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: async () => {
                return Promise.resolve(
                    [...constantParameters, ...additionalActionParameters].map((param) => ({
                        name: param.id,
                        value: param.id,
                        label: param.displayName,
                        id: param.id,
                    })),
                );
            },
        }),
        [additionalActionParameters, constantParameters],
    );

    const actionsCustomFieldTab = useMemo<TonkeanExpressionAdditionalTab[]>(() => {
        const fromDataSourceTab = {
            id: 'FROM_INTEGRATION_FIELDS',
            label: 'From Data Source',
            iconClass: 'mod-plug',
            searchPlaceholder: 'Search for a data source field...',
            quickCreationField: true,
            shouldShowInFormulasChoices: true,
            getFields: async () => {
                return integrationMetadataManager
                    .getIntegrationEntities(projectIntegration.id)
                    .then((response) =>
                        integrations
                            .generateIntegrationFields(response?.supportedEntities, projectIntegration, true)
                            .filter((singleIntegrationCustomField) =>
                                projectIntegrationAction?.parametersDefinition?.entitiesToRunOn?.find(
                                    (singleEntityToRunOn) =>
                                        singleIntegrationCustomField.entity
                                            .toLowerCase()
                                            .includes(singleEntityToRunOn.toLowerCase()),
                                ),
                            ),
                    );
            },
        };

        const tabs = [fromDataSourceTab, enterpriseComponentVariablesTab];
        if (projectIntegrationAction?.actionDefinition?.definitionType === ActionDefinitionType.SQL) {
            tabs.push(customActionParamsTab);
        }

        return tabs;
    }, [
        customActionParamsTab,
        enterpriseComponentVariablesTab,
        projectIntegrationAction?.actionDefinition?.definitionType,
        projectIntegrationAction?.parametersDefinition.entitiesToRunOn,
        integrationMetadataManager,
        projectIntegration,
        integrations,
    ]);

    const onChangeHttpAction = ({ definition }) => {
        setProjectIntegrationAction((oldAction) => ({ ...oldAction, actionDefinition: definition }));
        // Formik onSubmit must return a promise (its one of the ways to end the formik save) to insure autoSave
        return Promise.resolve();
    };

    const shouldDeleteVariable = useCallback(
        (fieldId: string) => {
            const parametersDefinition = projectIntegrationAction?.parametersDefinition;

            if (!!parametersDefinition?.entitiesToRunOn.length) {
                const parameterName = fieldId.replaceAll('{{', '').replaceAll('}}', '');

                return !parametersDefinition.entitiesToRunOn.some((entity) => parameterName.startsWith(entity));
            }

            return true;
        },
        [projectIntegrationAction?.parametersDefinition],
    );

    const expressionProps = useMemo(
        () => ({
            additionalTabs: actionsCustomFieldTab,
            hideEditorButton: true,
            globalExpressionOnly: true,
            doNotEvaluatePreview: true,
            shouldDeleteVariable,
        }),
        [actionsCustomFieldTab, shouldDeleteVariable],
    );

    const fieldListParameters = useMemo(() => {
        return (
            projectIntegrationAction.parametersDefinition?.parameters.filter(
                (parameter) => parameter.parameterType === ActionParameterType.FIELD_LIST,
            ) || []
        );
    }, [projectIntegrationAction.parametersDefinition?.parameters]);

    return (
        <>
            <SectionsWrapper>
                <LeftSection>
                    {projectIntegrationAction.actionDefinition.definitionType === ActionDefinitionType.HTTP && (
                        <>
                            <Formik
                                initialValues={{ definition: projectIntegrationAction.actionDefinition }}
                                onSubmit={onChangeHttpAction}
                            >
                                {({ errors }) => (
                                    <>
                                        <FormikAutosave />
                                        <HttpRequestConfiguration
                                            urlHeader="Path"
                                            namePrefix="definition"
                                            expressionProps={expressionProps}
                                            additionalActionParameters={additionalActionParameters}
                                            errors={errors}
                                            hideEnvUrlPrefix={
                                                (projectIntegrationAction.actionDefinition as HttpRequestDefinition)
                                                    .wasUrlConvertedToEnvUrlAsParameterFormat
                                            }
                                            actionParametersToFilter={actionParametersToFilter}
                                            fieldListParameters={fieldListParameters}
                                            actionType={projectIntegrationAction.actionType}
                                            constantParameters={constantParameters}
                                            shouldIncludeFileDownloading={
                                                (projectIntegrationAction.actionDefinition as HttpRequestDefinition)
                                                    .shouldIncludeFileDownloading
                                            }
                                        />
                                    </>
                                )}
                            </Formik>
                        </>
                    )}

                    {projectIntegrationAction.actionDefinition.definitionType === ActionDefinitionType.SQL && (
                        <>
                            <RequestTitle>SQL Request</RequestTitle>

                            <QueryNoteText>
                                Please note: Sql SELECT commands are limited to one record and will only return the
                                first record received (Limit 1)
                            </QueryNoteText>

                            <ProjectIntegrationActionSqlConfiguration
                                actionDefinition={projectIntegrationAction.actionDefinition}
                                setProjectIntegrationAction={setProjectIntegrationAction}
                                additionalTabsForTonkeanExpression={actionsCustomFieldTab}
                            />
                        </>
                    )}
                </LeftSection>

                <RightSection>
                    <ProjectIntegrationActionParametersView
                        projectIntegration={projectIntegration}
                        parametersDefinition={projectIntegrationAction.parametersDefinition}
                        projectIntegrationAction={projectIntegrationAction}
                        onChange={(parametersDefinition) =>
                            setProjectIntegrationAction((oldAction) => ({
                                ...oldAction,
                                parametersDefinition,
                            }))
                        }
                        constantParameters={constantParameters}
                        fieldListParameters={fieldListParameters}
                    />
                </RightSection>
            </SectionsWrapper>
        </>
    );
};
export default ProjectIntegrationActionCRUDPage;
