import React from 'react';

import ItemDetailsIconTypeMapping from './ItemDetailsIconTypeMapping';
import ItemDetailsModuleEditorIcon from './ItemDetailsModuleEditorIcon';
import { ReactComponent as InnerItemDetailsIcon } from '../../../../../images/icons/module-editor-inner-item-details-icon.svg';

import { IconSvg } from '@tonkean/infrastructure';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';
import type { TonkeanId } from '@tonkean/tonkean-entities';
import type { ItemDetailsIconType } from '@tonkean/tonkean-entities';

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    targetId?: TonkeanId<TonkeanType.GROUP> | TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    sizeModifier: number;
    iconType?: ItemDetailsIconType;
}

const ItemDetailsIcon: React.FC<Props> = ({ workflowVersionId, targetId, sizeModifier, iconType }) => {
    if (!!iconType && !!ItemDetailsIconTypeMapping[iconType]) {
        const Icon = ItemDetailsIconTypeMapping[iconType];
        return <IconSvg as={Icon} size={44 * sizeModifier} />;
    } else if (targetId?.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.CUSTOM_TRIGGER])) {
        return <IconSvg as={InnerItemDetailsIcon} size={44 * sizeModifier} />;
    } else {
        return <ItemDetailsModuleEditorIcon workflowVersionId={workflowVersionId} sizeModifier={sizeModifier} />;
    }
};

export default ItemDetailsIcon;
