import React from 'react';

interface Props {
    subtrackNumber: number;
    onClick(): void;
    loadingRelatedInitiatives: boolean;
}

const SubtrackNumber: React.FC<Props> = ({ subtrackNumber, onClick, loadingRelatedInitiatives }) => {
    return (
        <div
            className="common-color-grey common-italic margin-xs-left-xs margin-left-xs common-size-xxxxs pointer padding-right-xs"
            onClick={onClick}
        >
            <span style={{ display: subtrackNumber > 0 ? 'inline' : 'none' }}>{subtrackNumber}</span>
            <i
                style={{ display: loadingRelatedInitiatives && subtrackNumber === undefined ? 'inline-block' : 'none' }}
                className="loading-small"
            />
        </div>
    );
};

export default SubtrackNumber;
