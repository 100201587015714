<div class="tnk-module-integrations-preview flex-vmiddle flex-xs-wrap common-width-100">
    <!-- Tonkean logo (when there are no integrations) -->
    <span
        class="workers-gallery-icon-container flex-vmiddle mod-justify-center margin-right-xs"
        ng-class="{ 'mod-circle': data.showCircle, small: data.size === 'sm', medium: data.size === 'md' }"
    >
        <span
            class="svg-icon mod-static"
            ng-class="{ 'svg-icon-16': data.size === 'sm', 'svg-icon-lg': data.size === 'md' }"
        >
            <tnk-icon class="margin-left-xxxs" src="/images/icons/tonkean-logo.svg"></tnk-icon>
        </span>
    </span>

    <!-- Arrow -->
    <span class="svg-icon-md margin-right-xs">
        <tnk-icon src="/images/icons/arrow-right-lg.svg"></tnk-icon>
    </span>

    <!-- Integrations icons -->
    <span
        ng-repeat="source in data.worker.originalGroupProjectIntegrations | limitTo: avatarsLimit(data.worker)"
        class="workers-gallery-icon-container margin-right-xs"
        ng-class="{ 'mod-circle': data.showCircle, small: data.size === 'sm', medium: data.size === 'md' }"
        uib-tooltip="{{ source.displayName }}"
        tooltip-placement="bottom"
    >
        <span ng-if="source.representativeGroupId" class="inline-block common-radius-50 mod-match"></span>

        <span
            ng-if="!source.iconUrl"
            class="inline-block initiative-integration-icon mod-{{ source.integration.integrationType.toLowerCase() }}"
            ng-class="{ 'common-radius-50': data.showCircle }"
        ></span>
        <img
            ng-if="source.iconUrl && !source.representativeGroupId"
            ng-src="{{ source.iconUrl }}"
            alt="{{ source.displayName }}"
            ng-class="{ 'common-radius-50': data.showCircle }"
            class="inline-block {{ source.iconClass }}"
        />
    </span>

    <!-- Bullet List -->
    <span
        class="workers-gallery-icon-container flex-vmiddle mod-justify-center"
        ng-class="{ 'mod-circle': data.showCircle, small: data.size === 'sm', medium: data.size === 'md' }"
        ng-if="data.worker.originalGroupProjectIntegrations.length === 0"
        ng-style="{ zIndex: 2 }"
    >
        <span
            class="flex svg-icon-hover-black common-color-grey3"
            ng-class="{ 'svg-icon-nav-xs': data.size === 'sm', 'svg-icon-nav-sm': data.size === 'md' }"
        >
            <tnk-icon class="bullet-icon" src="/images/icons/bullet-list.svg"></tnk-icon>
        </span>
    </span>

    <!-- Avatars -->
    <span
        class="workers-gallery-icon-container mod-big-icon margin-right-xs"
        ng-class="{ 'mod-circle': data.showCircle, small: data.size === 'sm', medium: data.size === 'md' }"
        ng-if="wvm.getPublishedVersionFromCache(data.worker.id).shouldSendGatherUpdates"
        uib-tooltip="Auto Check-ins"
        tooltip-placement="bottom"
        ng-style="{ zIndex: 1 }"
    >
        <img
            class="inline-block avatar {{ source.iconClass }}"
            ng-src="/images/avatars/avatar-{{ ((data.indexInGallery || 1) % 7) + 1 }}.png"
        />
    </span>

    <!-- "More" label (when there are integrations) -->
    <span
        class="workers-gallery-icon-container mod-wide flex-vmiddle"
        ng-class="{ 'mod-circle': data.showCircle, small: data.size === 'sm', medium: data.size === 'md' }"
        ng-if="data.worker.originalGroupProjectIntegrations.length > avatarsLimit(data.worker)"
        ng-style="{ zIndex: 2 }"
        uib-tooltip="{{ getMoreTooltip(data.worker) }}"
        tooltip-placement="bottom"
    >
        <span class="text-center">
            +{{ data.worker.originalGroupProjectIntegrations.length - avatarsLimit(data.worker) }}
        </span>
    </span>
</div>
