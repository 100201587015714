import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { LogicConfigurationTitle } from '@tonkean/logic-configuration';
import type { LogicConfigurationProps } from '@tonkean/logic-configuration';
import { TrashIcon as TrashNewIcon } from '@tonkean/svg';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const LineItemTitle = styled(LogicConfigurationTitle)`
    font-size: ${FontSize.MEDIUM_14};
`;

const LineItemContainer = styled.div`
    background: ${Theme.colors.HEX_F2F2F2};
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid ${Theme.current.palette.general.border};
`;

const LineItem = styled.div`
    margin-top: 10px;
`;

const RemoveLineItem = styled.span`
    float: right;
    margin-top: -6px;
`;

const NoteText = styled.div`
    margin-top: 10px;
    font-size: ${FontSize.SMALL_12};
`;

const AddLineItemButton = styled(ClickableLink)`
    margin-top: 20px;
`;

const RequiredSign = styled.span`
    color: ${Theme.colors.error};
    margin-left: 3px;
`;

const RequiredAsterisk: React.FC = () => (
    <Tooltip content="Required Field">
        <RequiredSign>*</RequiredSign>
    </Tooltip>
);

interface LineItemObject {
    line: string;
    item: string;
    quantity: string;
    amount: string;
    rate: string;
    description: string;
}

const NetsuiteAddUpdatePOItemsCustomActionConfiguration: React.FC<LogicConfigurationProps> = ({
    groupId,
    workflowVersionId,
    definition,
    previewEvaluationSource,
    configuredLogic,
    onDefinitionChanged,
}) => {
    const [lineItems, setLineItems] = useState<LineItemObject[]>([]);

    const lineItem: LineItemObject = useMemo(() => {
        return { line: '', item: '', quantity: '', amount: '', rate: '', description: '' };
    }, []);

    if (!definition.items) {
        definition.items = [lineItem];
    }

    const addLineItem = useCallback(() => {
        const itemsArray: LineItemObject[] = [...definition.items, lineItem];
        setLineItems(itemsArray);
        definition.items = itemsArray;
        onDefinitionChanged(true);
    }, [definition, lineItem, onDefinitionChanged]);

    const removeLineItem = useCallback(
        (itemIndex) => {
            definition.items.splice(itemIndex, 1);
            setLineItems(definition.items);
            onDefinitionChanged(true);
        },
        [definition, onDefinitionChanged],
    );

    const onLineItemLineChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].lineExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemIdChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].itemExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemQuantityChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].quantityExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemAmountChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].amountExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemRateChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].rateExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    const onLineItemDescriptionChanged = useCallback(
        (expression, itemIndex, shouldSaveLogic) => {
            definition.items[itemIndex].descriptionExpression = expression;
            onDefinitionChanged(shouldSaveLogic);
        },
        [definition.items, onDefinitionChanged],
    );

    return (
        <>
            <LogicConfigurationTitle size="large">Line Items</LogicConfigurationTitle>
            {definition.items.map((item, itemIndex) => (
                <LineItemContainer key={itemIndex}>
                    <LineItem>
                        <LineItemTitle>
                            Line Number
                            <RemoveLineItem>
                                <IconButton onClick={() => removeLineItem(itemIndex)} flat>
                                    <TrashNewIcon />
                                </IconButton>
                            </RemoveLineItem>
                        </LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].lineExpression?.originalExpression}
                            savedEvaluatedExpression={definition.items[itemIndex].lineExpression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemLineChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Line Number"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                        <NoteText>Note: If empty, a new line item will be added</NoteText>
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>
                            Item ID
                            {definition.items[itemIndex].line?.originalExpression === '' && <RequiredAsterisk />}
                        </LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].itemExpression?.originalExpression}
                            savedEvaluatedExpression={definition.items[itemIndex].itemExpression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemIdChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Line Item ID"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                        <NoteText>Note: If it's a new line, this field is required</NoteText>
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>Quantity</LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].quantityExpression?.originalExpression}
                            savedEvaluatedExpression={
                                definition.items[itemIndex].quantityExpression?.evaluatedExpression
                            }
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemQuantityChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Quantity"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>Amount</LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].amountExpression?.originalExpression}
                            savedEvaluatedExpression={definition.items[itemIndex].amountExpression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemAmountChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Amount"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>Rate</LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={definition.items[itemIndex].rateExpression?.originalExpression}
                            savedEvaluatedExpression={definition.items[itemIndex].rateExpression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemRateChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Rate"
                            showFormulasTab
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>

                    <LineItem>
                        <LineItemTitle>Description</LineItemTitle>
                        <TonkeanExpression
                            groupId={groupId}
                            logicId={configuredLogic.node.id}
                            previewEvaluationSource={previewEvaluationSource}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={
                                definition.items[itemIndex].descriptionExpression?.originalExpression
                            }
                            savedEvaluatedExpression={
                                definition.items[itemIndex].descriptionExpression?.evaluatedExpression
                            }
                            onTonkeanExpressionChanged={(_, __, expression, shouldSaveLogic) => {
                                if (expression) onLineItemDescriptionChanged(expression, itemIndex, shouldSaveLogic);
                            }}
                            placeholder="Description"
                            textAreaNumberOfRows={2}
                            showFormulasTab
                            modTextArea
                            doNotEvaluatePreview
                            saveOnKeyUp
                        />
                    </LineItem>
                </LineItemContainer>
            ))}
            <AddLineItemButton onClick={() => addLineItem()}>+ Add Line Item</AddLineItemButton>
        </>
    );
};

export default NetsuiteAddUpdatePOItemsCustomActionConfiguration;
