import React from 'react';
import styled from 'styled-components';

import type { ItemInterfacePermission } from '../../../entities';
import { ItemWidget, ItemWidgetSidepaneTriggerZone } from '../../../WidgetModule';
import type SeparatorWidgetConfiguration from '../SeparatorWidgetConfiguration';

import { useActualColorToDisplay } from '@tonkean/infrastructure';
import type { WidgetBase } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    widget: WidgetBase<SeparatorWidgetConfiguration>;
    permission: ItemInterfacePermission;
}

const HR = styled.div<{ color: Color; isDashed: boolean }>`
    height: 0;
    border: 1px ${({ color }) => color} ${({ isDashed }) => (isDashed ? 'dashed' : 'solid')};
`;

const SeparatorWidgetView: React.FC<Props> = ({ widget, permission }) => {
    const actualSeparatorColor = useActualColorToDisplay(
        widget?.configuration?.separatorColor,
        widget?.configuration?.separatorColorId,
        Theme.colors.gray_400,
    );

    return (
        <ItemWidget
            sidepaneTriggerZone={ItemWidgetSidepaneTriggerZone.FULL_WIDGET}
            permission={permission}
            containerOverflowOverride="visible"
            disableMinHeight
            noBackgroundBody
            noBorderBody
            noPaddingBody
        >
            <HR color={actualSeparatorColor} isDashed={widget.configuration.isDashed} />
        </ItemWidget>
    );
};

export default React.memo(SeparatorWidgetView);
