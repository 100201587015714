import type { PropsWithChildren } from 'react';
import React, { useContext } from 'react';

import InterfaceQuickNavigationContext, {
    useBuildInterfaceQuickNavigationContext,
} from './InterfaceQuickNavigationContext';

import type { InterfaceTab } from '@tonkean/tonkean-entities';
import { InterfaceQuickNavigationMode } from '@tonkean/tonkean-entities';

interface Props {
    quickNavigationMode?: InterfaceQuickNavigationMode;
    inheritQuickNavigationContext?: boolean;
    tabs: InterfaceTab[] | undefined;
}

/**
 * This component should ONLY contain things that apply to ALL types of interfaces
 * if there are ifs that are related to which type this component is wrapping, that logic should move to its appropriate wrapper
 *
 */
const GeneralInterfaceWrapper: React.FC<PropsWithChildren<Props>> = ({
    quickNavigationMode = InterfaceQuickNavigationMode.STANDARD,
    inheritQuickNavigationContext,
    tabs,
    children,
}) => {
    const quickNavigationContext = inheritQuickNavigationContext
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useContext(InterfaceQuickNavigationContext)
        : // eslint-disable-next-line react-hooks/rules-of-hooks
          useBuildInterfaceQuickNavigationContext(quickNavigationMode, tabs);

    return (
        <InterfaceQuickNavigationContext.Provider value={quickNavigationContext}>
            {children}
        </InterfaceQuickNavigationContext.Provider>
    );
};

export default GeneralInterfaceWrapper;
