import isEqual from 'lodash.isequal';
import { useCallback, useMemo } from 'react';
import { useDeepCompareMemo, useDeepCompareEffect } from 'use-deep-compare';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useGroupGlobalFields } from '@tonkean/infrastructure';
import {
    WorkflowVersionType,
    type ItemInterfaceWidget,
    type TonkeanId,
    type TonkeanType,
    type FieldDefinition,
} from '@tonkean/tonkean-entities';

interface UseItemInterfaceFieldDefinitionsProps {
    allInterfaceWidgets: ItemInterfaceWidget[];
    projectId: TonkeanId<TonkeanType.PROJECT>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
    isDraftInitiative: boolean;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
}

function useItemInterfaceFieldDefinitions({
    allInterfaceWidgets,
    projectId,
    workflowVersionId,
    isDraftInitiative,
    groupId,
}: UseItemInterfaceFieldDefinitionsProps): {
    itemInterfaceFieldDefinitions: FieldDefinition[];
    loadingItemInterfaceFieldDefinitions: boolean;
} {
    const globalFields = useGroupGlobalFields({
        groupId,
        workflowVersionType: isDraftInitiative ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
    });

    const [
        {
            data: itemInterfaceFieldDefinitionResult,
            loading: loadingItemInterfaceFieldDefinitions,
            called: calledItemInterfaceFieldDefinitions,
            args: getItemInterfaceFieldDefinitionsArgs,
        },
        getWidgetsConfigurationsFieldDefinitions,
    ] = useLazyTonkeanService('getWidgetsConfigurationsFieldDefinitions');

    const allInterfaceWidgetsConfigurations = useDeepCompareMemo(() => {
        return allInterfaceWidgets.map((widget) => widget.configuration);
    }, [allInterfaceWidgets]);

    const buildGetFieldDefinitionsRequestArgsFromCurrProps =
        useCallback((): typeof getItemInterfaceFieldDefinitionsArgs => {
            if (!workflowVersionId) {
                return undefined;
            }

            return [allInterfaceWidgetsConfigurations, projectId, workflowVersionId, isDraftInitiative];
        }, [allInterfaceWidgetsConfigurations, projectId, workflowVersionId, isDraftInitiative]);

    useDeepCompareEffect(() => {
        const getFieldDefinitionsRequestArgs = buildGetFieldDefinitionsRequestArgsFromCurrProps();

        if (!getFieldDefinitionsRequestArgs) {
            return;
        }

        if (!isEqual(getItemInterfaceFieldDefinitionsArgs, getFieldDefinitionsRequestArgs)) {
            getWidgetsConfigurationsFieldDefinitions(...getFieldDefinitionsRequestArgs);
        }
    }, [allInterfaceWidgetsConfigurations, projectId, workflowVersionId, isDraftInitiative]);

    return useMemo(() => {
        const hasFetchedFieldDefinitions =
            calledItemInterfaceFieldDefinitions &&
            !loadingItemInterfaceFieldDefinitions &&
            itemInterfaceFieldDefinitionResult;
        const fetchedFieldDefinitionsAreValidForRecentPassedProps = isEqual(
            getItemInterfaceFieldDefinitionsArgs,
            buildGetFieldDefinitionsRequestArgsFromCurrProps(),
        );
        const markAsLoading = !hasFetchedFieldDefinitions || !fetchedFieldDefinitionsAreValidForRecentPassedProps;
        return {
            loadingItemInterfaceFieldDefinitions: markAsLoading,
            itemInterfaceFieldDefinitions: [
                ...(itemInterfaceFieldDefinitionResult?.fieldDefinitions ?? []),
                ...Object.values(globalFields).map((globalField) => globalField.fieldDefinition),
            ],
        };
    }, [
        getItemInterfaceFieldDefinitionsArgs,
        buildGetFieldDefinitionsRequestArgsFromCurrProps,
        calledItemInterfaceFieldDefinitions,
        loadingItemInterfaceFieldDefinitions,
        itemInterfaceFieldDefinitionResult,
        globalFields,
    ]);
}

export default useItemInterfaceFieldDefinitions;
