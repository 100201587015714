import template from './tnkMultiFieldQuickSelection.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkMultiFieldQuickSelection', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        includeGlobalFields: '<',
        existingFields: '<',
        customActions: '<',
        specialFieldsForFeatures: '<',
        onFieldsChange: '&',
    },
    template,
    controller: 'multiFieldQuickSelectionCtrl',
});
