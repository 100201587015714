import React from 'react';
import styled from 'styled-components';

import { Field, TagsInput, Toggle } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Header = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: normal;
`;

const ToggleHeader = styled.div`
    color: ${Theme.colors.gray_800};
    line-height: 14px;
    font-size: ${FontSize.SMALL_12};
    padding-right: 3px;
    font-weight: normal;
`;

const FieldContent = styled.div`
    width: 650px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const Wrapper = styled.div`
    max-width: 650px;
`;

interface Props {
    header: string;
    toggleName: string;
    inputTagsName: string;
}

const EmailIntakeRestrictionBlockView: React.FC<Props> = ({ header, toggleName, inputTagsName }) => {
    return (
        <Wrapper>
            <Field
                touchedOnlyErrors={false}
                label={
                    <FieldContent>
                        <Header>{header}</Header>
                        <Toggle
                            name={toggleName}
                            size={InputSize.MEDIUM}
                            labelBefore={<ToggleHeader>Restriction is</ToggleHeader>}
                            showInnerLabel
                        />
                    </FieldContent>
                }
            >
                <TagsInput name={inputTagsName} />
            </Field>
        </Wrapper>
    );
};

export default EmailIntakeRestrictionBlockView;
