import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class IsInListFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.IS_IN_LIST;
    override readonly sign = 'IsInList';
    override readonly displayName = 'Is In List';
    override readonly description = 'Checks if the value is in the list';
    override readonly dataType = FieldType.Boolean;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Value',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Item',
            pluralName: 'Items',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => ({
                fieldDefinitionType: FieldDefinitionType.SINGLE,
                dataType: FieldType.String,
                displayName: `Item ${repeatIndex} In List`,
            }),
        },
    ];
}
