<div class="duplicate-field-definition-modal">
    <div>
        <!-- Modal title -->
        <h1 class="common-size-l common-bold margin-bottom-md margin-top-lg">
            Copy {{ data.fieldDefinition.targetType === 'GLOBAL' ? 'Global Field' : 'Field' }}
        </h1>

        <!-- Sub title -->
        <div class="flex-vmiddle-xs-block">
            <div class="margin-right margin-bottom-xs">
                Select the module you'd like to copy
                <strong>{{ data.fieldDefinition.name }}</strong>
                to
            </div>

            <!-- Duplicate into group selection -->
            <ui-select
                class="common-width-40-no-xs margin-bottom-xs"
                theme="bootstrap"
                ng-model="data.duplicateIntoGroup"
            >
                <ui-select-match placeholder="Search module...">
                    <div class="common-ellipsis">{{ data.duplicateIntoGroup.name }}</div>
                </ui-select-match>
                <ui-select-choices repeat="p in data.allAvailableGroups | filter:$select.search">
                    <div ng-bind-html="p.name | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <div class="common-color-light-grey common-size-xxxxs">* By default the field will be hidden.</div>

        <!-- Error -->
        <div class="common-size-xxs common-color-danger margin-top" ng-if="data.error">{{ data.error }}</div>

        <!-- Copy & Cancel buttons -->
        <div class="margin-top-lg">
            <!-- Copy button -->
            <button type="submit" class="btn btn-primary" ng-click="copyFieldDefinitionToGroup()">Copy</button>

            <!-- Cancel button -->
            <button type="button" class="btn btn-default" ng-click="close()">Cancel</button>
        </div>
    </div>
</div>
