import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import RichTextEditor from './RichTextEditor';
import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';
import InterfaceSubmitValidationContext from '../../utils/InterfaceSubmitValidationContext';
import type { SingleFieldChanged } from '../CommonWidgetConfiguration';
import useWidgetsSharedValidationLogic from '../hooks/useWidgetsValidationLogic';
import { QUESTION_WIDGET_REQUIRED_FIELD_DEFAULT_MESSAGE } from '../QuestionWidget/QuestionWidget';
import type QuestionWidgetConfiguration from '../QuestionWidget/QuestionWidgetConfiguration';

import { CoreEditorSerialize, type HTMLEditorPlatePlugin } from '@tonkean/editor';
import { useGetFieldInstance } from '@tonkean/fields';
import { FieldError } from '@tonkean/infrastructure';
import {
    Validation,
    FieldType,
    type FieldDefinition,
    type Initiative,
    type WidgetBase,
    type WorkflowVersion,
    type TonkeanId,
    type TonkeanType,
    type ValidationResult,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledTextDisplay = styled.div`
    padding: 16px;
`;

const StyledFieldError = styled(FieldError)`
    font-weight: 500;
`;

const StyledWrapper = styled.div<{ hasError: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${({ hasError }) => hasError && `border: 1px solid ${Theme.colors.error};`}
    ${({ hasError }) => hasError && `border-radius: 4px;`}
`;
const validate = (
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
    value: any,
    configuration: QuestionWidgetConfiguration,
    setValidationKey: (key: string, value: ValidationResult[]) => void,
) => {
    const validationResultsArray: ValidationResult[] = [];

    if (configuration.required) {
        const sanitizedValue = typeof value === 'string' ? value.trim() : value;
        validationResultsArray.push({
            validation: !!sanitizedValue ? Validation.VALID : Validation.INVALID,
            errorMessage: configuration.requiredMessage,
        });
    }

    setValidationKey(widgetId, validationResultsArray);
};

const generateBasicRichTextEditorStructure = (textContent: string) => {
    return [{ type: 'p', children: [{ text: textContent }] }];
};

interface Props {
    widget: WidgetBase<RichTextEditorInputWidgetConfiguration>;
    initiative: Initiative;
    workflowVersion: WorkflowVersion;
    coreEditorPlugins: HTMLEditorPlatePlugin[];
    currentFieldDefinition: FieldDefinition;
    onSave: (changes: SingleFieldChanged) => Promise<void>;
    isEditMode: boolean;
    setIsEditMode: (isShowEdit: boolean) => void;
}

const RichTextEditorViewItemExists: React.FC<Props> = ({
    widget,
    initiative,
    workflowVersion,
    coreEditorPlugins,
    currentFieldDefinition,
    onSave,
    isEditMode,
    setIsEditMode,
}) => {
    const { setValidationKey } = useContext(InterfaceSubmitValidationContext);

    const fieldInstance = useGetFieldInstance(
        initiative.group.id,
        currentFieldDefinition,
        initiative,
        workflowVersion,
        false,
    );

    const content = useMemo(() => {
        if (fieldInstance?.value) {
            const parsedContent = fieldInstance.value.toString();
            if (currentFieldDefinition.fieldType === FieldType.LongString) {
                return JSON.parse(fieldInstance.value.toString());
            } else {
                return generateBasicRichTextEditorStructure(parsedContent);
            }
        } else {
            return [{}];
        }
    }, [currentFieldDefinition.fieldType, fieldInstance?.value]);

    let isReadOnlyMode = false;
    if (typeof content == 'number') {
        isReadOnlyMode = !!content && !isEditMode;
    } else {
        isReadOnlyMode = content?.length > 0 && !isEditMode;
    }

    const initialContent = content?.[0] && Object.keys(content?.[0]).length === 0 ? [] : content;

    const onValueSet = useCallback(
        (value) => {
            validate(widget.id, value, widget.configuration, setValidationKey);
        },
        [setValidationKey, widget.configuration, widget.id],
    );

    useEffect(() => {
        const foundValue = fieldInstance?.value as unknown;
        onValueSet(foundValue);
    }, [fieldInstance?.value, onValueSet]);

    const { hasValidationError, markAsTouched, optionalFailedValidationResult } =
        useWidgetsSharedValidationLogic(widget);

    const saveWithValidation = useCallback(
        (changes: SingleFieldChanged) => {
            // This validation checks if there is text present in the rich text editor, focusing only on the first line.
            // The parsing structure is based on the output format of the library being used.
            // children props is a list that always contains only one object.
            const textToValidate = changes.newValue != null ? JSON.parse(changes.newValue)[0].children[0].text : null;
            onValueSet(textToValidate);
            markAsTouched();
            return onSave(changes);
        },
        [markAsTouched, onSave, onValueSet],
    );

    if (widget.configuration.isDisabled) {
        return (
            <StyledTextDisplay>
                <CoreEditorSerialize value={initialContent} plugins={coreEditorPlugins} />
            </StyledTextDisplay>
        );
    }

    return (
        <StyledWrapper hasError={hasValidationError}>
            <RichTextEditor
                widget={widget}
                plugins={coreEditorPlugins}
                initiative={initiative}
                isReadOnly={isReadOnlyMode}
                currentFieldDefinition={currentFieldDefinition}
                onSave={saveWithValidation}
                initialValueContent={content}
                isHideToolbar={isReadOnlyMode}
                setIsEditMode={setIsEditMode}
                workflowVersion={workflowVersion}
            />
            {hasValidationError && (
                <StyledFieldError
                    $inlineError={false}
                    $textAlign="right"
                    data-automation="rich-text-editor-widget-styled-field-error"
                >
                    {optionalFailedValidationResult?.errorMessage || QUESTION_WIDGET_REQUIRED_FIELD_DEFAULT_MESSAGE}
                </StyledFieldError>
            )}
        </StyledWrapper>
    );
};

export default RichTextEditorViewItemExists;
