import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function NextCloudCustomActionsDefinitionCtrl($scope, integrations) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    /**
     * File Source Selection
     */
    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (storageIntegrationId, newDocumentName, shouldSaveLogic) {
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.newDocumentName = newDocumentName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Upload, Move, Copy File & Move, Copy Folder
     */
    $scope.onNewNameTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.newNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDestinationPathTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationPathExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Share File
     */
    $scope.onShareTypeTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.shareTypeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSharePermissionsTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.sharePermissionsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onShareExpirationTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.shareExpirationExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('NextCloudCustomActionsDefinitionCtrl', lateConstructController(NextCloudCustomActionsDefinitionCtrl));
