import { useAngularService } from 'angulareact';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalSize,
} from '../../../../../../../../../../packages/infrastructure/src/components/TUI/Modal';
import { ReactComponent as DownloadIcon } from '../../../../../../../../images/icons/download.svg';
import type AuditLogFilterSearchQuery from '../../../entities/AuditLogFilterSearchQuery';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { Input, Modal, XCloseButton } from '@tonkean/infrastructure';
import type { EnterpriseComponent, TonkeanType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ModalSubtitle = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: 12px;
    margin-bottom: 12px;
`;

const ExportNotice = styled.div`
    color: ${Theme.colors.gray_500};
`;

const ModalHeaderWrapper = styled(ModalHeader)`
    display: flex;
    justify-content: space-between;
    padding: 8px 8px 0 14px;
`;

const ModalBodyWrapper = styled(ModalBody)`
    padding: 14px 20px 0 20px;
`;

const Header = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    margin-top: 8px;
    margin-left: 6px;
`;

interface Props {
    enterpriseComponent: EnterpriseComponent;
    enterpriseComponentType: TonkeanType;
    filterSearch?: AuditLogFilterSearchQuery;
    disabled?: boolean;
}

const EnterpriseComponentAuditLogExport: React.FC<Props> = ({
    enterpriseComponent,
    enterpriseComponentType,
    filterSearch,
    disabled,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [name, setName] = useState<string>(`${enterpriseComponent.displayName} Audit Logs`);
    const auditLogsService = useAngularService('auditLogService');
    const projectManager = useAngularService('projectManager');

    const [{ loading: exportCsvFileLoading, error: exportCsvFileError }, exportCsvFile] = useLazyAsyncMethod(
        auditLogsService,
        'exportCsv',
    );

    const handleExportClick = useCallback(async () => {
        await exportCsvFile(
            projectManager.project.id,
            enterpriseComponent.id,
            enterpriseComponentType,
            name,
            filterSearch,
        );
        setIsOpen(false);
    }, [exportCsvFile, projectManager.project.id, enterpriseComponent.id, enterpriseComponentType, name, filterSearch]);

    return (
        <>
            <Button onClick={() => setIsOpen(true)} disabled={disabled} outlined>
                <DownloadIcon />
                Export CSV
            </Button>
            <Modal open={isOpen} onClose={() => setIsOpen(false)} size={ModalSize.SMALL} fixedWidth>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        return handleExportClick();
                    }}
                >
                    <ModalHeaderWrapper $border={false}>
                        <Header>Export to CSV</Header>
                        <XCloseButton size={ButtonSize.MEDIUM} onClick={() => setIsOpen(false)} />
                    </ModalHeaderWrapper>

                    <ModalBodyWrapper>
                        <ModalSubtitle>
                            <div>Save changes log as CSV file</div>
                            <ExportNotice>* Notice you can export up to 5000 rows</ExportNotice>
                        </ModalSubtitle>

                        <Input
                            value={name}
                            placeholder="Type file name"
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        {exportCsvFileError && (
                            <div className="common-size-xxxxs common-color-danger flex-grow margin-top">
                                There was an error trying to export audit logs to file
                            </div>
                        )}
                    </ModalBodyWrapper>
                    <ModalFooter gap={8} align="right">
                        <Button onClick={() => setIsOpen(false)} type="button" cancel>
                            Cancel
                        </Button>
                        <Button disabled={exportCsvFileLoading} type="submit">
                            Download
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default EnterpriseComponentAuditLogExport;
