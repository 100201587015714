import React, { useMemo } from 'react';

import { FieldTypeIcon } from '@tonkean/fields';
import { FieldType } from '@tonkean/tonkean-entities';

interface Props {
    presetValues: (string | number)[];
    onSelected: (value: string, dataType: FieldType) => void;
    filter: string;
}

const FormulaPopoverTabPreset: React.FC<Props> = ({ presetValues, onSelected, filter }) => {
    // Handle formula preset filtering
    const filteredPresetList = useMemo(() => {
        if (!presetValues) {
            return [];
        }

        const searchString = filter.toLowerCase();
        if (!searchString.length) {
            return presetValues;
        }
        // Filter the pairs list based on the search string
        return presetValues.filter((presetValue) => presetValue.toString().toLowerCase().includes(searchString));
    }, [presetValues, filter]);

    const onClick = (value: string | number) => {
        onSelected(value.toString(), typeof value === 'number' ? FieldType.Number : FieldType.String);
    };

    return (
        <div className="inline-select-list">
            <div className="inline-select-list-data-type">
                {filteredPresetList.map((value) => (
                    <button className="inline-select-item" key={value} type="button" onClick={() => onClick(value)}>
                        <span className="tabs-field-selector-field-icon-container">
                            <span className="common-color-grey-soft">
                                <FieldTypeIcon
                                    fieldType={typeof value === 'number' ? FieldType.Number : FieldType.String}
                                    size="xs"
                                />
                            </span>
                        </span>
                        {value}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default FormulaPopoverTabPreset;
