import React, { useMemo } from 'react';
import * as Yup from 'yup';

import type { MarketplaceItemCreateSection } from '../components/MarketplaceItemCreateModalSection';
import MarketPlaceItemCreateModalUploadImagesSection from '../components/sections/MarketPlaceItemCreateModalUploadImagesSection';
import MarketplaceItemCreateModalModuleContentSection from '../components/sections/module/MarketplaceItemCreateModalModuleContentSection';

import { useFeatureFlag } from '@tonkean/angular-hooks';

const useGetModuleMarketplaceItemAdditionalSections = () => {
    const requireImageUpload = useFeatureFlag('tonkean_marketplace_item_must_upload_images');

    const additionalSections: MarketplaceItemCreateSection[] = useMemo(() => {
        return [
            {
                title: 'Content',
                contentComponent: <MarketplaceItemCreateModalModuleContentSection />,
            },
            {
                title: 'Upload images',
                contentComponent: <MarketPlaceItemCreateModalUploadImagesSection />,
                validationSchema: {
                    images: Yup.array().when([], {
                        is: () => requireImageUpload,
                        then: Yup.array()
                            .min(1, 'Must upload at least 1 image')
                            .required('Must upload at least 1 image'),
                        otherwise: Yup.array().notRequired(),
                    }),
                },
            },
        ];
    }, [requireImageUpload]);

    return additionalSections;
};

export default useGetModuleMarketplaceItemAdditionalSections;
