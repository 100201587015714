import template from './tnkUipathCustomActionsDefinition.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkUipathCustomActionsDefinition', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        projectIntegration: '<',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        invalidLogics: '<',
        definition: '<', // The selected action definition.
        validationObject: '<',
        onDefinitionChanged: '&',
    },
    template,
    controller: 'UipathCustomActionsDefinitionCtrl',
});
