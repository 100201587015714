import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

export interface ItemWidgetComponentProps {
    $borderBottom?: boolean;
    $noPadding?: boolean;
}

const ItemWidgetComponent = styled.div<ItemWidgetComponentProps>`
    ${({ $noPadding }) =>
        !$noPadding &&
        css`
            padding: 16px;
        `}

    ${({ $borderBottom }) =>
        $borderBottom &&
        css`
            border-bottom: 1px solid ${Theme.colors.gray_300};
        `}
`;

export default ItemWidgetComponent;
