import FunctionOperatorBase from './FunctionOperatorBase';

import { FieldType, FormulaPopoverTab } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import type { FormulaAllowedParams } from '@tonkean/tonkean-entities';

export default class ConvertInnerItemsToArray extends FunctionOperatorBase {
    override readonly key = OperatorKey.CONVERT_INNER_ITEMS_TO_ARRAY;
    override readonly sign = 'ConvertInnerItemsToArray';
    override readonly displayName = 'Convert Inner Items To Array';
    override readonly description = 'Allow to map inner items to an array';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Pair',
            pluralName: 'Pairs',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => [
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: [FieldType.String, FieldType.Number, FieldType.Boolean, FieldType.Date],
                    displayName: `key ${repeatIndex}`,
                    explanation: 'The key of the pair.',
                },
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: [FieldType.String, FieldType.Number, FieldType.Boolean, FieldType.Date],
                    displayName: `value ${repeatIndex}`,
                    explanation: 'The value of the pair.',
                },
            ],
        },
        {
            displayName: 'Sort By Field',
            dataType: [FieldType.Date, FieldType.Number],
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            explanation: 'Optional Sort field',
        },
        {
            displayName: 'Sort Field Type',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            explanation: 'Sort Field Type',
            defaultTab: FormulaPopoverTab.PRESET,
            defaultValue: 'NUMBER',
            presetValues: ['STRING', 'NUMBER', 'DATE', 'BOOLEAN'],
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Sort order',
            allowedTabsOnly: [FormulaPopoverTab.PRESET],
            dataType: FieldType.String,
            defaultValue: 'ASC',
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: ['ASC', 'DESC'],
        },
    ];

    override isAllowed(params: FormulaAllowedParams): boolean {
        // Allow to use the formula only if its in specific custom trigger
        return !!params.customTrigger;
    }
}
