<div class="front-door-action-logic">
    <!-- We do loading on all the logic content because we want them to init after the fields for the relevant group have been loaded -->
    <div ng-if="data.initiativeToUseLoading" class="loading"></div>

    <div ng-if="!data.initiativeToUseLoading && !data.reloadFields">

        <!-- Message -->
        <div class="common-title-xxs">Message</div>

        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            placeholder="Message"
            saved-expression="data.message"
            on-tonkean-expression-changed="onMessageExpressionChanged(expression, shouldSaveLogic)"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>

        <!-- Separator -->
        <hr />

        <!-- Conversation ID -->
        <div class="common-title-xxs">Conversation ID</div>

        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            placeholder="Conversation ID"
            saved-expression="data.conversationId"
            on-tonkean-expression-changed="onConversationIdExpressionChanged(expression, shouldSaveLogic)"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>
</div>
