import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function NlpBranchConfigurationCtrl($scope) {
    const ctrl = this;
    // let defaultThreshold = 100;
    $scope.data = {
        keywordsTags: [],
        textAnalysisType: ctrl.textAnalysisType || 'MANUAL',
        selectedTrainingSetId: ctrl.selectedTrainingSetId,
        trainingSetModelId: undefined,
        trainingSetModelsToExclude: ctrl.trainingSetModelsToExclude,
        branchId: ctrl.branchId,
        groupId: ctrl.groupId,
        // threshold: defaultThreshold,
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.definition) {
            initializeEditMode();
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = changesObj.validationObject.currentValue;
        }

        if (changesObj.textAnalysisType) {
            $scope.data.textAnalysisType = changesObj.textAnalysisType.currentValue;
        }

        if (changesObj.branchId) {
            $scope.data.branchId = changesObj.branchId.currentValue;
        }

        if (changesObj.selectedTrainingSetId) {
            if (!changesObj.selectedTrainingSetId.isFirstChange()) {
                $scope.onTrainingSetModelSelected(undefined);
            }
            $scope.data.selectedTrainingSetId = changesObj.selectedTrainingSetId.currentValue;
        }
    };

    $scope.onKeywordsTagsChanged = function () {
        $scope.onManualBranchDefinitionChanged(true);
    };

    $scope.onManualBranchDefinitionChanged = function (shouldSaveLogic) {
        const keywords = $scope.data.keywordsTags.map((tag) => tag.text);
        const definition = {
            keywords,
            textAnalysisType: 'MANUAL',
            // threshold: $scope.data.threshold,
        };

        if (ctrl.onBranchDefinitionChanged) {
            ctrl.onBranchDefinitionChanged({
                definition,
                shouldSaveLogic,
            });
        }
    };

    /**
     * Occurs on selection of a training set model
     */
    $scope.onTrainingSetModelSelected = function (selectedTrainingSetModelId, selectedTrainingSetModelLabel) {
        const definition = {
            trainingSetModelId: selectedTrainingSetModelId,
            textAnalysisType: 'TRAINING_SET',
        };

        $scope.data.trainingSetModelId = selectedTrainingSetModelId;

        if (ctrl.onBranchNameChanged) {
            ctrl.onBranchNameChanged($scope.data.branchId, selectedTrainingSetModelLabel, false);
        }

        if (ctrl.onBranchDefinitionChanged) {
            ctrl.onBranchDefinitionChanged({
                definition,
                shouldSaveLogic: true,
            });
        }
    };

    /**
     * Initialize the edit mode of the component.
     */
    function initializeEditMode() {
        if (ctrl.definition) {
            if ($scope.data.textAnalysisType === 'MANUAL') {
                const keywords = ctrl.definition.keywords || [];
                $scope.data.keywordsTags = keywords.map((keyword) => {
                    return {
                        text: keyword,
                    };
                });
            } else {
                $scope.data.trainingSetModelId = ctrl.definition.trainingSetModelId;
            }

            // $scope.data.threshold = ctrl.definition.threshold || defaultThreshold;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('NlpBranchConfigurationCtrl', lateConstructController(NlpBranchConfigurationCtrl));
