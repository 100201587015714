import type { AngularServices } from 'angulareact';
import { reactToAngular as publicReactToAngular } from 'angulareact';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const reactToAngular = <
    PROPS extends Record<any, any> = Record<any, any>,
    SERVICE_NAMES extends keyof AngularServices = string,
>(
    ReactComponent: React.ComponentType<PROPS>,
    propNames?: (keyof PROPS | 'children')[],
    serviceNames?: SERVICE_NAMES[],
) => {
    const ComponentWithFallback: React.FC<PROPS> = (props) => {
        return (
            <ErrorBoundary fallback={<></>}>
                <ReactComponent {...props} />
            </ErrorBoundary>
        );
    };

    return publicReactToAngular(ComponentWithFallback, propNames, serviceNames);
};

export default reactToAngular;
