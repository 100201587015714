import type { IHttpResponse } from 'angular';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type React from 'react';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { LineChart } from '@tonkean/infrastructure';
import { ChartDisplayType } from '@tonkean/infrastructure';
import type { FieldInstance, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { Activity } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const useGetFieldGraphValues = (
    field: FieldInstance,
    fieldName: string,
    rangeFrom: number,
    rangeTo: number,
    showStatusOverlay: boolean,
    chartType: ChartDisplayType,
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>,
): [
    React.ComponentProps<typeof LineChart>['data'] | undefined,
    Record<string, Record<Color, Activity[]>> | undefined,
    IHttpResponse<any> | undefined,
    boolean,
    any,
] => {
    const {
        data: fieldGraphValues,
        error: fieldGraphError,
        loading: fieldGraphLoading,
    } = useTonkeanService('getFieldGraphValues', field.id, rangeFrom, rangeTo, 10_000);
    const [{ data: activityData, loading: activityLoading }, getInitiativeActivity] =
        useLazyTonkeanService('getInitiativeActivity');

    const [dateToActivityMap, setDateToActivityMap] = useState<Record<string, Record<Color, Activity[]>> | undefined>();

    const getClosestNextDate = useCallback((activityDate: number, historyDates) => {
        if (historyDates?.[0]) {
            if (activityDate < historyDates[0]) {
                return historyDates[0];
            } else if (activityDate > historyDates[historyDates.length - 1]!) {
                return historyDates[historyDates.length - 1];
            } else {
                for (let i = 1; i < historyDates.length - 1; i++) {
                    if (activityDate > historyDates[i - 1] && activityDate < historyDates[i]) {
                        return historyDates[i];
                    }
                }
            }
        }
        return activityDate;
    }, []);

    // build the activity datasets
    const buildActivityDatasets = useCallback(
        (
            graphData: React.ComponentProps<typeof LineChart>['data'],
            chartValues: Record<string, number>,
            minValue: number,
            historyDates: number[],
        ) => {
            if (graphData && chartValues && activityData) {
                // The activity points dates
                const activityDataPointsDates: Record<Color, string[]> = {};
                // The activity points representation in the graph
                const activityDataPoints: Record<Color, { x: string; y: string } | {}[]> = {};
                // Map between color and activity label
                const colorToActivityMap: Record<Color, Activity> = {};
                // Map between date and activity collection
                const dateToActivityMap: Record<string, Record<Color, Activity[]>> = {};

                const chartValueKeys = Object.keys(chartValues).map((key) =>
                    dayjs(new Date(Number.parseInt(key))).format('MMM DD, YYYY'),
                );
                const chartValuesArray = Object.values(chartValues);

                for (const activity of activityData.activity) {
                    if (
                        !activity.metadata.nothingChanged &&
                        activity.created > rangeFrom &&
                        activity.created <= rangeTo
                    ) {
                        // TODO: TNKN-19429 Improve activity data point on field line chart (also, don't use colors)
                        if (!activityDataPointsDates[activity.metadata.newState.stateColor]) {
                            activityDataPointsDates[activity.metadata.newState.stateColor] = [];
                        }

                        let date = dayjs(activity.created).format('MMM DD, YYYY');
                        if (chartValueKeys.includes(date)) {
                            const currentDateChartValueIndex = chartValueKeys.indexOf(date);
                            if (utils.isNullOrUndefined(chartValuesArray[currentDateChartValueIndex])) {
                                // if there is no value in the date, find the next closest date and put the activity on this date.
                                const closestNextDate: number = getClosestNextDate(activity.created, historyDates);
                                date = dayjs(closestNextDate).format('MMM DD, YYYY');
                            }
                        }

                        activityDataPointsDates[activity.metadata.newState.stateColor]?.push(date);

                        if (!colorToActivityMap[activity.metadata.newState.stateColor]) {
                            colorToActivityMap[activity.metadata.newState.stateColor] =
                                activity.metadata.newState.stateText;
                        }

                        if (!dateToActivityMap[date]) {
                            dateToActivityMap[date] = {};
                        }

                        // we need to activity metadata later for the tooltips
                        if (
                            dateToActivityMap[date] &&
                            !dateToActivityMap[date]?.[activity.metadata.newState.stateColor]
                        ) {
                            dateToActivityMap[date]![activity.metadata.newState.stateColor] = [];
                        }
                        dateToActivityMap[date]?.[activity.metadata.newState.stateColor]?.push(activity);
                    }
                }

                setDateToActivityMap(dateToActivityMap);

                // first index dataset from the graph is the field history dataset
                const fieldHistoryGraphDataset = graphData.datasets[0];
                // create dataset for each activity color
                for (const state in activityDataPointsDates) {
                    const newPointArr: { x: string; y: string } | {}[] = [];
                    // before adding the new datasets for each activity color we only have one dataset of the history
                    // if the date of each point doesn't match we add empty point to push the points forward to their correct location
                    if (fieldHistoryGraphDataset) {
                        for (let i = 0; i < fieldHistoryGraphDataset.data.length; i++) {
                            const fieldHistoryData: { x: Date; y: number | undefined } | undefined =
                                fieldHistoryGraphDataset.data[i] as unknown as { x: Date; y: number | undefined };
                            if (!!fieldHistoryData) {
                                const dateHasActivity = activityDataPointsDates[state]?.includes(
                                    dayjs(fieldHistoryData.x).format('MMM DD, YYYY'),
                                );
                                if (!dateHasActivity) {
                                    newPointArr.push({});
                                } else {
                                    newPointArr.push({
                                        x: fieldHistoryData.x,
                                        y: 0,
                                    });
                                }
                            }
                        }
                        activityDataPoints[state] = newPointArr;
                    }

                    const fieldActivityDataSet: any = {
                        label: colorToActivityMap[state],
                        data: activityDataPoints[state],
                        backgroundColor: state,
                        borderColor: state,
                        borderWidth: 3,
                        fill: true,
                        pointRadius: 5,
                        pointHoverRadius: 7,
                        pointBorderWidth: 2,
                        hoverBorderWidth: 3,
                        showLine: false,
                        yAxisID: 'y',
                        type: 'line',
                    };

                    graphData.datasets.splice(0, 0, fieldActivityDataSet);
                }
            }
        },
        [activityData, getClosestNextDate, rangeFrom, rangeTo],
    );

    const getGradient = useCallback((ctx, chartArea) => {
        const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, `rgba(57, 179, 214, 0)`);
        gradient.addColorStop(0.5, `rgba(57, 179, 214, 0.5)`);
        return gradient;
    }, []);

    const tooltipData = useMemo(() => {
        if (fieldGraphValues?.entities) {
            const tooltipGraphData = {};
            for (let i = 0; i < fieldGraphValues.entities.length; i++) {
                const currentResult = fieldGraphValues.entities[i];
                const dateToEvaluate = currentResult.historyDate;
                const date = dayjs(dateToEvaluate).format('MMM DD, YYYY');

                if (!utils.isNullOrUndefined(currentResult.numberValue)) {
                    if (!tooltipGraphData[date]) {
                        tooltipGraphData[date] = [];
                    }
                    tooltipGraphData[date].push({
                        time: dayjs(dateToEvaluate).format('HH:mm'),
                        value: currentResult.numberValue,
                    });
                }
            }
            return tooltipGraphData;
        }
    }, [fieldGraphValues]);

    // build the values dataset
    const graphData = useMemo<React.ComponentProps<typeof LineChart>['data'] | undefined>(() => {
        if (fieldGraphValues) {
            const graphDatasets: any = [];
            const chartValues: Record<number, number> = {};

            if (fieldGraphValues.oldestFieldHistory) {
                // oldestFieldHistory is data from before the "from" date, so the graph always has a starting point.
                // So we add it to the entities array as the first date with the from date.

                // Make sure the entities array exists.
                if (!fieldGraphValues.entities) {
                    fieldGraphValues.entities = [];
                }

                if (!fieldGraphValues.entities.length) {
                    // This syntax creates a new copy of the oldestFieldHistory object as lastFieldHistory.
                    let lastFieldHistory = {};
                    lastFieldHistory = Object.assign(lastFieldHistory, fieldGraphValues.oldestFieldHistory);
                    lastFieldHistory = { ...lastFieldHistory, historyDate: rangeTo };
                    // If we don't have any entities, the oldestFieldHistory should also be the last entity with the "to" date.
                    fieldGraphValues.entities.push(lastFieldHistory);
                }
                fieldGraphValues.entities.unshift(fieldGraphValues.oldestFieldHistory);
            } else {
                chartValues[rangeFrom] = null as unknown as number;
            }

            let minValue: number = 0;
            const historyDates: number[] = [];

            let lastDate = '';
            for (let i = 0; i < fieldGraphValues.entities.length; i++) {
                const currentResult = fieldGraphValues.entities[i];

                const dateToEvaluate =
                    i === 0 && fieldGraphValues.oldestFieldHistory
                        ? fieldGraphValues.oldestFieldHistory.historyDate
                        : currentResult.historyDate;

                const date = dayjs(dateToEvaluate).format('MMM DD, YYYY');

                if (utils.isNullOrUndefined(currentResult.numberValue)) {
                    const val: number = Number.parseFloat(currentResult.value);
                    if (val !== Number.NaN && !Number.isNaN(val)) {
                        chartValues[dateToEvaluate] = val.toFixed(4) as unknown as number;
                        historyDates.push(dateToEvaluate);
                    }
                } else {
                    if (dayjs(lastDate).format('MMM DD, YYYY') === dayjs(dateToEvaluate).format('MMM DD, YYYY')) {
                        delete chartValues[lastDate];
                    }
                    chartValues[dateToEvaluate] = currentResult.numberValue;
                    historyDates.push(dateToEvaluate);
                }

                const dateNumberValue: number | undefined = chartValues[dateToEvaluate];
                if (!!dateNumberValue) {
                    if (i === 0) {
                        minValue = dateNumberValue;
                    } else {
                        if (minValue > dateNumberValue) {
                            minValue = dateNumberValue;
                        }
                    }
                }
                lastDate = dateToEvaluate;
            }

            // If there is no value in the rangeTo date, then add the last value in the chart
            if (dayjs(lastDate).format('MMM DD, YYYY') !== dayjs(rangeTo).format('MMM DD, YYYY')) {
                chartValues[rangeTo] = chartValues[lastDate] as unknown as number;
            }

            // Build the data structure with the values on the x-axis and y-axis
            const dataValues: { x: Date; y: number | undefined }[] = Object.keys(chartValues).map((key: string) => {
                return { x: new Date(Number.parseInt(key)), y: chartValues[key] };
            });

            const fieldHistoryDataSet = {
                label: fieldName,
                data: dataValues,
                borderColor: Theme.colors.primary,
                backgroundColor(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return;
                    }
                    return getGradient(ctx, chartArea);
                },
                pointHoverRadius: 7,
                pointBackgroundColor: Theme.colors.white,
                pointBorderWidth: 3,
                hoverBorderWidth: 3,
                radius: 5,
                borderWidth: 3,
                fill: true,
                type: chartType === ChartDisplayType.STEP ? ChartDisplayType.LINE : chartType,
                stepped: chartType === ChartDisplayType.STEP,
            };

            graphDatasets.push(fieldHistoryDataSet);

            const lineChartData: React.ComponentProps<typeof LineChart>['data'] = {
                labels: Object.values(chartValues).map((key) => dayjs(key).toDate()),
                datasets: graphDatasets,
            };

            if (showStatusOverlay) {
                buildActivityDatasets(lineChartData, chartValues, minValue, historyDates);
            }

            return lineChartData;
        }
    }, [
        fieldGraphValues,
        fieldName,
        chartType,
        showStatusOverlay,
        rangeFrom,
        rangeTo,
        getGradient,
        buildActivityDatasets,
    ]);

    useEffect(() => {
        if (!activityData && !activityLoading && !!initiativeId) {
            getInitiativeActivity(
                initiativeId,
                20,
                0,
                ['INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED'],
                false,
                false,
                false,
                false,
            );
        }
    }, [activityData, activityLoading, buildActivityDatasets, getInitiativeActivity, initiativeId]);

    return [graphData, dateToActivityMap, fieldGraphError, fieldGraphLoading, tooltipData];
};

export default useGetFieldGraphValues;
