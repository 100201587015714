import type { ColDef } from '@ag-grid-community/core';

import {
    EMPHASIZED_HEADER_CLASS_NAME,
    EMPTY_AND_REQUIRED_CLASS_NAME,
    FILLED_FILTER_ICON_CLASS_NAME,
    REQUIRED_HEADER_CLASS_NAME,
} from './customClassNames';
import isEditableColumn from './getIsEditableColumn';
import StatusSelectorCellEditor from '../components/CellEditors/StatusSelectorCellEditor';
import ColumnId from '../configs/config/columnId';
import type InitiativeRowData from '../entities/InitiativeRowData';

import type { State } from '@tonkean/tonkean-entities';
import { type FieldDefinitionKey, SpecialFieldsKey } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';

const getStatusDefinition = (
    commonDef: Partial<ColDef<InitiativeRowData>>,
    fieldDefinitionKeyToName: Record<FieldDefinitionKey, string | undefined>,
    enableFilter: boolean | undefined,
    isEditable: boolean,
    getIsFieldEmptyAndRequired: (fieldDefinitionKey: FieldDefinitionKey, row: InitiativeRowData) => boolean,
    shouldShowRequiredIndication: (fieldDefinitionKey: FieldDefinitionKey) => boolean,
    isEmphasizeText: boolean | undefined,
    filteredColumnIds: string[] | undefined,
    drilldownColumnId: string | undefined,
) => {
    return {
        ...commonDef,
        headerName: fieldDefinitionKeyToName[SpecialFieldsKey.STATUS] || 'Status',
        field: ColumnId.Status,
        editable: (params) => {
            return isEditableColumn(params.node.data?.state, ColumnId.Status, isEditable, drilldownColumnId);
        },
        filter: enableFilter && 'agTextColumnFilter',
        type: 'resizableColumn',
        cellRenderer: 'statusCellRenderer',
        menuTabs: ['filterMenuTab'],
        cellEditor: StatusSelectorCellEditor,
        cellRendererParams: {
            fontSize: isEmphasizeText ? FontSize.XXXXLARGE_24 : FontSize.MEDIUM_14,
        },
        filterValueGetter: (params) => {
            return params?.data?.state?.label;
        },
        headerClass: ({ column }) => {
            const classes: string[] = [];
            if (shouldShowRequiredIndication(SpecialFieldsKey.STATUS)) {
                classes.push(REQUIRED_HEADER_CLASS_NAME);
            }
            if (isEmphasizeText) {
                classes.push(EMPHASIZED_HEADER_CLASS_NAME);
            }
            if (column?.getColId() && filteredColumnIds?.includes(column.getColId())) {
                classes.push(FILLED_FILTER_ICON_CLASS_NAME);
            }
            return classes;
        },
        valueFormatter: (params) => {
            return params.data?.state?.label || '';
        },
        valueParser: (params) => {
            return params;
        },
        getQuickFilterText: ({ data }) => {
            return data?.state?.label || '';
        },
        equals: (statusA, statusB) => {
            return statusA?.label === statusB?.label;
        },
        comparator: (statusA: State, statusB: State) => {
            if (statusA?.label === statusB?.label) return 0;
            return (statusA?.label || '') > (statusB?.label || '') ? 1 : -1;
        },
        tooltipValueGetter: ({ data }) => {
            return data?.state?.label || undefined;
        },
        cellClassRules: {
            [EMPTY_AND_REQUIRED_CLASS_NAME]: (params) => {
                return !!params.data && getIsFieldEmptyAndRequired(SpecialFieldsKey.STATUS, params.data);
            },
        },
    } as ColDef<InitiativeRowData>;
};

export default getStatusDefinition;
