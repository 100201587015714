import { useAngularService } from 'angulareact';
import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import Section from '../../components/Section';
import WidgetRequiredIndicationConfiguration from '../../components/WidgetRequiredIndicationConfiguration';
import type { ItemWidgetEditorProps } from '../../WidgetModule';
import { WidgetConfigurationToggle } from '../CommonWidgetConfiguration';
import { WidgetConfigurationFieldSelector } from '../CommonWidgetConfiguration';
import { WidgetInlineActionsSelector } from '../CommonWidgetConfiguration';
import type { UploadWidgetConfiguration } from '../entities';
import { useGetWorkflowVersionFieldsAndBasicFields } from '../hooks';
import ItemsWidgetConfigurationEditorInterfaceSelector from '../InnerItemsWidgetModule/components/ItemsWidgetConfigurationEditorInterfaceSelector';
import ItemsWidgetMinMaxConfiguration from '../InnerItemsWidgetModule/components/ItemsWidgetMinMaxConfiguration';

import { TnkFieldSelector } from '@tonkean/angular-to-react-components';
import { TnkProjectIntegrationSelector } from '@tonkean/angular-to-react-components';
import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { FormikTnkCustomFilters } from '@tonkean/angular-to-react-components';
import { Field, InformationTooltip, Input, LoadingCircle, useFormikField } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { SavingState } from '@tonkean/tonkean-entities';
import type { FieldDefinition, ProjectIntegration, TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    padding: 0 16px;
`;

const InputSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 16px 0;
    justify-content: space-between;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-hight: 10px;
    color: ${Theme.colors.gray_700};
    min-width: 60px;
`;

const InputWrapper = styled.div`
    margin-top: 8px;
    width: 100%;
`;

const StyledInformationTooltip = styled(InformationTooltip)`
    margin-right: 20px;
    margin-left: 10px;
`;

const LabelTooltipWrap = styled.div`
    display: flex;
`;

const StyledWidgetConfigurationToggle = styled(WidgetConfigurationToggle)`
    margin-top: 8px;
`;

const StyledInput = styled(Input)`
    margin-top: 8px;
    width: 100%;
`;

const WidgetConfigurationFieldSelectorWrapper = styled.div`
    margin-top: 16px;
`;

const SectionTitle = styled(ConfigurationSectionTitle)`
    margin-bottom: 17px;
`;

const UploadWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<UploadWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const { initiative, itemInterface } = useItemInterfaceContext();

    const { value: selectedConfiguration, setValue: setSelectedConfiguration } =
        useFormikField<UploadWidgetConfiguration>('configuration');

    const { editedSections } = useContext(FormBuilderContext);

    const integrations = useAngularService('integrations');
    const dataStorageIntegrationTypes = useMemo(
        () => Object.keys(integrations.getSupportedUploadingStorageIntegrations()),
        [integrations],
    );

    // Show in the field selector only fields that are manual field, basic fields and
    const fieldsFilter = useCallback((fields: FieldDefinition[]) => {
        return fields?.filter((field) => {
            if (
                !field.isMultiValueField &&
                ['String', 'LongString'].includes(field.fieldType) &&
                (!field.idRelationField || !field?.linkedCustomTrigger?.id)
            ) {
                return field;
            }
        });
    }, []);

    const { loading, allFieldDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(workflowVersion.id, [
        'ITEM_INTERFACE_UPLOAD_WIDGET',
    ]);

    if (editedSections[FormBuilderSections.DataStorageId] === SavingState.LOADING || loading) {
        return <LoadingCircle large centered />;
    }

    const minimumItemsRequired = configuration.minMaxRequirementConfiguration?.minimum ?? 0;

    return (
        <>
            <Wrapper>
                <StyledWidgetConfigurationToggle
                    title="Allow uploading new files"
                    name="configuration.showFileUploadButton"
                />
                {selectedConfiguration.showFileUploadButton && (
                    <>
                        <Field inlineError>
                            <InputWrapper>
                                <Label>Upload button label</Label>
                                <StyledInput
                                    title="Upload button label"
                                    name="configuration.uploadFileButtonLabel"
                                    autoComplete="off"
                                />
                            </InputWrapper>
                        </Field>
                        <InputSectionWrapper>
                            <Label>File Storage (Required)</Label>
                            <InputWrapper>
                                <TnkProjectIntegrationSelector
                                    filterOnIntegrationType={dataStorageIntegrationTypes}
                                    onProjectIntegrationSelected={(projectIntegration: ProjectIntegration) => {
                                        setSelectedConfiguration({
                                            ...selectedConfiguration,
                                            uploadProjectIntegrationId: projectIntegration.id,
                                        });
                                    }}
                                    selectedProjectIntegrationId={selectedConfiguration.uploadProjectIntegrationId}
                                    isFormDefaultSelected={false}
                                    displayFormDefault={false}
                                />
                            </InputWrapper>
                        </InputSectionWrapper>
                        <InputSectionWrapper>
                            <LabelTooltipWrap>
                                <Label>Destination Folder (ID)</Label>
                                <StyledInformationTooltip>
                                    The folder ID is a unique sequence of characters found in the "External entity ID"
                                    data source field.
                                </StyledInformationTooltip>
                            </LabelTooltipWrap>
                            <InputWrapper>
                                <TonkeanExpression
                                    groupId={workflowVersion.groupId}
                                    workflowVersionId={workflowVersion.id}
                                    onTonkeanExpressionChanged={(
                                        originalExpression: string,
                                        evaluatedExpression: string,
                                        expression: TonkeanExpressionDefinition,
                                    ) => {
                                        setSelectedConfiguration({
                                            ...selectedConfiguration,
                                            destinationFolderExpression: expression,
                                        });
                                    }}
                                    placeholder="Folder ID"
                                    savedOriginalExpression={
                                        selectedConfiguration.destinationFolderExpression?.originalExpression
                                    }
                                    previewEvaluationSource={selectedConfiguration.destinationFolderExpression}
                                    savedEvaluatedExpression={
                                        selectedConfiguration.destinationFolderExpression?.evaluatedExpression
                                    }
                                    doNotEvaluatePreview
                                />
                            </InputWrapper>
                        </InputSectionWrapper>
                        <InputSectionWrapper>
                            <LabelTooltipWrap>
                                <Label>Field for uploaded file ID</Label>
                                <StyledInformationTooltip>
                                    The file ID of the uploaded file will be stored in the specified field so it can be
                                    referenced by other actions in the module flow.
                                </StyledInformationTooltip>
                            </LabelTooltipWrap>
                            <InputWrapper>
                                <TnkFieldSelector
                                    workflowVersionId={workflowVersion.id}
                                    groupBy="groupBy"
                                    selectedFieldIdentifier={
                                        configuration.fieldDefinitionToSaveFileId || 'TNK_DESCRIPTION'
                                    }
                                    itemsSource="COLUMN"
                                    onFieldSelected={(fieldDefinition: FieldDefinition) => {
                                        if (fieldDefinition.id) {
                                            setSelectedConfiguration({
                                                ...selectedConfiguration,
                                                fieldDefinitionToSaveFileId: fieldDefinition.id,
                                            });
                                        }
                                    }}
                                    placeHolder="Add a field"
                                    fieldInspectModal={false}
                                    fieldInspectAutoOpen={false}
                                    fieldsFilter={fieldsFilter}
                                    addFieldsQuickCreateOptions={false}
                                    quickCreateMatchedEntity={false}
                                    specialFieldsForFeatures={['UPLOAD_FILE_FORM_FIELD']}
                                    onlyUpdatableFieldDefinitions
                                    reloadFields
                                />
                            </InputWrapper>
                        </InputSectionWrapper>

                        <ItemsWidgetMinMaxConfiguration minMaxRequirementsEntityName="files" />
                        {minimumItemsRequired > 0 && (
                            <WidgetRequiredIndicationConfiguration name="configuration.requiredIndicatorType" />
                        )}
                    </>
                )}
                <StyledWidgetConfigurationToggle
                    title="Allow viewing upload in drill down interface"
                    name="configuration.allowDrillDown"
                    toolTip="Allow viewing the upload item in a drill down interface."
                />
                {selectedConfiguration.allowDrillDown && (
                    <ItemsWidgetConfigurationEditorInterfaceSelector
                        groupId={workflowVersion.groupId}
                        workflowVersionId={workflowVersion.id}
                    />
                )}

                <StyledWidgetConfigurationToggle
                    title="Display viewer's files only"
                    name="configuration.showUsersFilesOnly"
                    toolTip="Prevent viewing files that were uploaded to the request by other users."
                />
                <InputSectionWrapper>
                    <LabelTooltipWrap>
                        <Label>Filter displayed files</Label>
                    </LabelTooltipWrap>
                    <InputWrapper>
                        <FormikTnkCustomFilters
                            name="configuration.filters"
                            itemsSource="COLUMN"
                            groupId={workflowVersion.groupId}
                            workflowVersionId={workflowVersion.id}
                            specialFieldsForFeatures={['ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER']}
                            includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']}
                            hideTimeRangeSelection
                            hideCloseButton
                            editMode
                            useExpressionsForValue
                            verticalMod
                            smallMod
                        />
                    </InputWrapper>
                </InputSectionWrapper>
            </Wrapper>
            <Wrapper>
                <SectionTitle>No Files Empty State</SectionTitle>
                <Field inlineError>
                    <InputWrapper>
                        <Label>Title</Label>
                        <StyledInput
                            title="Empty state title"
                            name="configuration.emptyStateTitle"
                            autoComplete="off"
                        />
                    </InputWrapper>
                </Field>
                <Field inlineError>
                    <InputWrapper>
                        <Label>Subtitle</Label>
                        <StyledInput
                            title="Empty state subtitle"
                            name="configuration.emptyStateSubTitle"
                            autoComplete="off"
                        />
                    </InputWrapper>
                </Field>
            </Wrapper>
            <WidgetConfigurationFieldSelectorWrapper>
                <WidgetConfigurationFieldSelector
                    groups={undefined}
                    allFieldDefinitions={allFieldDefinitions}
                    deletedFieldsIds={[]}
                    workflowVersion={workflowVersion}
                    exampleItem={initiative}
                    configurationTitle="File table fields"
                    disabledFields={['TNK_TITLE']}
                    disableEditFields={['TNK_TITLE']}
                    disableOrderFields={['TNK_TITLE']}
                    fixedOrderForSpecialFields={false}
                    shouldToggleFilters
                />
            </WidgetConfigurationFieldSelectorWrapper>
            <Section>
                <WidgetInlineActionsSelector workflowVersionId={itemInterface.workflowVersionId} />
            </Section>
        </>
    );
};

export default UploadWidgetConfigurationEditor;
