import template from './tnkBotStatusView.template.html?angularjs';

/**
 * Component description goes here
 */
export default angular.module('tonkean.app').component('tnkBotStatusView', {
    bindings: {
        status: '<',
    },
    template,
    controller: 'BotStatusViewCtrl',
});
