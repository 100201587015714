<div class="search-initiatives-popover">
    <tnk-search-initiatives
        group-id="data.filter.group.id"
        can-clear="true"
        on-initiative-selected="onFilterByInitiative(selectedSimplifiedInitiative, isInit)"
        should-set-default-initiative="false"
        default-initiatives-amount="20"
        selected-initiative-id="data.filter.simplifiedInitiative.id"
        only-roots="true"
    ></tnk-search-initiatives>
</div>
