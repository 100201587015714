import React from 'react';
import styled from 'styled-components';

import EnterpriseComponentPageMenuItemOverviewIcon from './EnterpriseComponentPageMenuItemOverviewIcon';
import type {
    EnterpriseComponentOverViewStepKey,
    EnterpriseComponentPageMenuItemConfiguration,
} from '../../../entities/EnterpriseComponentPageMenuConfiguration';
import useEnterpriseComponentContext from '../../../hooks/useEnterpriseComponentContext';
import { EnterpriseComponentGeneralSectionIds } from '../../../utils/EnterpriseComponentGeneralSection';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { SideMenuItem } from '@tonkean/infrastructure';
import { EnterpriseComponentOverviewStepStatus } from '@tonkean/tonkean-entities';
import type { EnterpriseComponent, EnterpriseComponentType } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const StatusIcon = styled.span`
    margin-left: 6px;

    svg {
        width: 10px;
        height: 10px;
    }
`;

const Dot = styled.span<{ color: Color }>`
    margin-left: 6px;
    height: 4px;
    width: 4px;
    background-color: ${({ color }) => color};
    border-radius: 2px;
`;

interface Props<T extends EnterpriseComponentType, C extends EnterpriseComponent> {
    menuItem: EnterpriseComponentPageMenuItemConfiguration<T, C>;
    enterpriseComponentOverviewResult: ConvertEnterpriseComponentTypeToOverviewResult<T> | undefined;
    enterpriseComponentOverviewStepKeys: EnterpriseComponentOverViewStepKey<T>[];
}

const EnterpriseComponentMenuItem = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    menuItem,
    enterpriseComponentOverviewResult,
    enterpriseComponentOverviewStepKeys,
}: Props<T, C>) => {
    const { enterpriseComponent } = useEnterpriseComponentContext<C>();

    const [selectedMenuItemId] = useGetStateParams('page');

    const overviewResultStepStatus = menuItem.stepStatusKey
        ? (enterpriseComponentOverviewResult?.[menuItem.stepStatusKey] as
              | EnterpriseComponentOverviewStepStatus
              | undefined)
        : undefined;
    const defaultStepStatus = menuItem.showRedDot?.(enterpriseComponent)
        ? EnterpriseComponentOverviewStepStatus.ERROR
        : EnterpriseComponentOverviewStepStatus.VALID;
    const stepStatus = overviewResultStepStatus || defaultStepStatus;

    const selected = menuItem.id === selectedMenuItemId;

    return (
        <SideMenuItem
            data-automation={`enterprise-component-page-menu-item-option-${menuItem.title}`}
            options={{ inherit: true, notify: false }}
            state="."
            params={{ page: menuItem.id }}
            selected={selected}
        >
            <div>{menuItem.title}</div>
            {enterpriseComponentOverviewResult && menuItem.id === EnterpriseComponentGeneralSectionIds.OVERVIEW && (
                <StatusIcon>
                    <EnterpriseComponentPageMenuItemOverviewIcon
                        enterpriseComponentOverviewStepKeys={enterpriseComponentOverviewStepKeys}
                        enterpriseComponentOverviewResult={enterpriseComponentOverviewResult}
                    />
                </StatusIcon>
            )}
            {stepStatus === EnterpriseComponentOverviewStepStatus.ERROR && <Dot color={Theme.colors.error} />}
            {stepStatus === EnterpriseComponentOverviewStepStatus.WARNING && <Dot color={Theme.colors.marker} />}
        </SideMenuItem>
    );
};

export default EnterpriseComponentMenuItem;
