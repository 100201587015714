import type NavigationRequest from '../entities/NavigationRequest';
import StatePageType from '../entities/StatePageType';

import { NavigationCategory } from '@tonkean/tonkean-entities';

const statePageTypeToNavigationRequestMap: Record<StatePageType, NavigationRequest> = {
    [StatePageType.DATA_SOURCES]: {
        navigationCategories: [
            NavigationCategory.ALL_MODULES_IN_PROJECT,
            NavigationCategory.ALL_SOLUTIONS_IN_PROJECT,
            NavigationCategory.ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT,
            NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT,
            NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT,
            NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
        ],
    },
    [StatePageType.LIVE_REPORT]: {
        navigationCategories: [
            NavigationCategory.CURRENT_MODULE_BUILD_ITEMS,
            NavigationCategory.CURRENT_MODULE_PRODUCTION_ITEMS,
            NavigationCategory.CURRENT_MODULE_BUILD_CUSTOM_TRIGGERS,
            NavigationCategory.CURRENT_MODULE_PRODUCTION_CUSTOM_TRIGGERS,

            NavigationCategory.CURRENT_MODULE_USED_DATA_SOURCES,
            NavigationCategory.CURRENT_MODULE_SOLUTION_DATA_SOURCES,

            NavigationCategory.ALL_MODULES_IN_PROJECT,
            NavigationCategory.ALL_SOLUTIONS_IN_PROJECT,
            NavigationCategory.ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT,
            NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT,
            NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT,
            NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
        ],
    },
    [StatePageType.MODULE_EDITOR]: {
        navigationCategories: [
            NavigationCategory.CURRENT_MODULE_BUILD_CUSTOM_TRIGGERS,
            NavigationCategory.CURRENT_MODULE_PRODUCTION_CUSTOM_TRIGGERS,
            NavigationCategory.CURRENT_MODULE_USED_DATA_SOURCES,
            NavigationCategory.CURRENT_MODULE_SOLUTION_DATA_SOURCES,
            NavigationCategory.ALL_MODULES_IN_PROJECT,
            NavigationCategory.ALL_SOLUTIONS_IN_PROJECT,
            NavigationCategory.ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT,
            NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT,
            NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT,
            NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
            NavigationCategory.CURRENT_MODULE_BUILD_ITEMS,
            NavigationCategory.CURRENT_MODULE_PRODUCTION_ITEMS,
        ],
    },
    [StatePageType.RUNTIME]: {
        navigationCategories: [
            NavigationCategory.ALL_MODULES_IN_PROJECT,
            NavigationCategory.ALL_SOLUTIONS_IN_PROJECT,
            NavigationCategory.ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT,
            NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT,
            NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT,
            NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
        ],
    },
    [StatePageType.SOLUTIONS_GALLERY]: {
        navigationCategories: [
            NavigationCategory.ALL_MODULES_IN_PROJECT,
            NavigationCategory.ALL_SOLUTIONS_IN_PROJECT,
            NavigationCategory.ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT,
            NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT,
            NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT,
            NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
        ],
    },
    [StatePageType.PROJECT_INTEGRATION_PAGE]: {
        navigationCategories: [
            NavigationCategory.CURRENT_DATA_SOURCE_ACCESSIBLE_MODULES,
            NavigationCategory.CURRENT_DATA_SOURCE_ACCESSIBLE_SOLUTIONS,
            NavigationCategory.ALL_MODULES_IN_PROJECT,
            NavigationCategory.ALL_SOLUTIONS_IN_PROJECT,
            NavigationCategory.ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT,
            NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT,
            NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT,
            NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
        ],
    },
    [StatePageType.HOME_PAGE]: {
        navigationCategories: [
            NavigationCategory.ALL_HOMEPAGE_INTAKES_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
        ],
    },
    [StatePageType.SOLUTION_SITE]: {
        navigationCategories: [
            NavigationCategory.ALL_HOMEPAGE_INTAKES_IN_PROJECT,
            NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
        ],
    },
};

export default statePageTypeToNavigationRequestMap;
