<div class="modal-header">
    <!--<h3 class="modal-title">{{currentIntegration.displayName}}</h3>-->
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div>
        <!-- Warning And Alert -->
        <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>

        <form name="data.loginModal" class="form-horizontal" ng-submit="connect()" ng-show="!connecting">
            <div class="form-group">
                <label for="login-api-key" class="col-sm-4 control-label">API Token</label>

                <div class="col-sm-8">
                    <input
                        type="text"
                        ng-model="data.apikey"
                        class="form-control"
                        id="login-api-key"
                        autocomplete="off"
                        placeholder="API Token"
                        required
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <!--   Setup Instructions    -->
            <div class="margin-bottom-lg info-box common-size-xxs">
                <p class="common-color-dark-grey">Setup API key instructions:</p>
                <div class="common-color-dark-grey">
                    <ul class="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                        <li>
                            <div class="padding-left-xxs">Get into settings.</div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">Go to Plugins & API.</div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">Choose API.</div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">Go to Front tokens title.</div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">
                                Create a new token and select scopes as you wish (Note: in order to make this
                                integration meaningful, you have to chose at least the required front team).
                            </div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">Select the newly created token.</div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">Copy here the new token.</div>
                        </li>
                        <li>
                            <div class="padding-left-xxs">Done.</div>
                        </li>
                    </ul>
                </div>
            </div>

            <!--     Connect Button       -->
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7"></div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </form>

        <div class="text-center" ng-show="connecting">
            <h4>
                <span class="loading"></span>
                Connecting to {{ currentIntegration.displayName }}
            </h4>
        </div>
    </div>

    <!-- Create endpoint button -->
    <div
        class="flex-vmiddle mod-justify-center"
        ng-if="
            integration &&
            !loading &&
            !data.incomingWebhookUrl &&
            !data.loadingExistingIncomingWebhooks &&
            !data.errorLoadingExistingIncomingWebhooks
        "
    >
        <div class="btn btn-primary" ng-click="createFrontappIncomingWebhook()">Create Frontapp Webhooks Endpoint</div>
    </div>

    <!-- Existing endpoint -->
    <div class="flex-vmiddle mod-justify-center common-bold" ng-if="data.incomingWebhookUrl">
        Frontapp Webhooks Endpoint
    </div>

    <!-- Endpoint -->
    <div class="flex-vmiddle mod-justify-center" ng-if="data.incomingWebhookUrl">{{ data.incomingWebhookUrl }}</div>

    <div class="modal-footer">
        <button
            class="btn btn-default"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Cancel Integration"
            analytics-label="{{ currentIntegration.name }}"
            analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
            type="button"
            ng-click="onCancel()"
        >
            Cancel
        </button>
        <button
            class="btn btn-primary"
            ng-disabled="!data.integrationForm.$valid || !selections || !selections.length"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Save Integration"
            analytics-label="{{ currentIntegration.name }}"
            analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
            type="button"
            ng-click="ok()"
        >
            OK
        </button>
    </div>
</div>
