import React, { useMemo } from 'react';
import styled from 'styled-components';

import { SelectInputSourceTitleContainer } from './sharedComponents';
import { ReactComponent as ChatMessageIcon } from '../../../../../../images/icons/chat-message.svg';
import { ReactComponent as EnvelopeIcon } from '../../../../../../images/icons/envelope-rounded.svg';
import { ReactComponent as FormFlatIcon } from '../../../../../../images/icons/form-flat.svg';
import { ReactComponent as HallowDataSourceIcon } from '../../../../../../images/icons/hollow-data-source.svg';
import { ReactComponent as GrayScaleScheduledIcon } from '../../../../../../images/icons/module-editor-item-details-scheduled-trigger-icon-grayscale.svg';
import { ReactComponent as OpsModuleActionHallow } from '../../../../../../images/icons/ops-module-action-hallow.svg';
import WorkflowVersionInputSourceType from '../../../entities/WorkflowVersionInputSourceType';
import type { InputSourceContentComponentProps } from '../InputSourceContentComponentProps';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Chevron, ChevronDirection, H4, Paragraph } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const InputSourceOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const InputSourceOption = styled(Clickable)`
    display: flex;
    gap: 12px;
    align-items: center;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 8px;
    padding: 12px;

    transition: box-shadow 0.15s;

    &:hover {
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);

        .option-icon {
            ${colorSvg(Theme.colors.promotion)}
        }
    }
`;

const InputSourceOptionIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    height: 48px;
    width: 48px;

    background: ${Theme.colors.tabsHover};
    border-radius: 8px;

    svg {
        width: 22px;
        height: 22px;
        ${colorSvg(Theme.colors.gray_700)}
    }
`;

const InputSourceOptionTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
`;

interface InputSourceTypeDetails {
    title: string;
    description: string;
    icon: React.ReactElement;
}

const inputIntakeSourceToDetails: Record<
    Exclude<WorkflowVersionInputSourceType, WorkflowVersionInputSourceType.MANUAL>,
    InputSourceTypeDetails
> = {
    [WorkflowVersionInputSourceType.EMAIL]: {
        title: 'Email',
        description: 'Inbound emails will create new items.',
        icon: <EnvelopeIcon />,
    },
    [WorkflowVersionInputSourceType.CHAT]: {
        title: 'Chat',
        description: 'Inbound chat messages will create new items.',
        icon: <ChatMessageIcon />,
    },
    [WorkflowVersionInputSourceType.FORMS]: {
        title: 'Forms',
        description: 'Submission of custom forms and apps will create new items',
        icon: <FormFlatIcon />,
    },
    [WorkflowVersionInputSourceType.DATA_SOURCE]: {
        title: 'External Data Source',
        description: 'New entities created in a connected data source will create new items',
        icon: <HallowDataSourceIcon />,
    },
    [WorkflowVersionInputSourceType.SCHEDULED]: {
        title: 'Scheduled',
        description: 'New items will be created according to a scheduled frequency',
        icon: <GrayScaleScheduledIcon />,
    },
    [WorkflowVersionInputSourceType.ANOTHER_MODULE]: {
        title: 'Another Module',
        description: 'New items will be created by another module based on agreed contract',
        icon: <OpsModuleActionHallow />,
    },
};

const ManualInputSourceSelectionContent: React.FC<InputSourceContentComponentProps> = ({
    setSelectedInputSourceType,
}) => {
    const shouldShowModuleContracts = useFeatureFlag('tonkean_feature_enable_module_contract');

    const intakeSourceOptions = useMemo(() => {
        const allOptions = Object.entries(inputIntakeSourceToDetails);
        if (!shouldShowModuleContracts) {
            return allOptions.filter(([key]) => key !== WorkflowVersionInputSourceType.ANOTHER_MODULE);
        }

        return allOptions;
    }, [shouldShowModuleContracts]);
    return (
        <>
            <SelectInputSourceTitleContainer data-automation="manual-input-source-selection-content-title">
                <H4 $bold>Select Intake Source</H4>
            </SelectInputSourceTitleContainer>

            <InputSourceOptionsContainer>
                {intakeSourceOptions.map(([key, option]) => (
                    <InputSourceOption
                        data-automation={`input-source-option-${key.toLowerCase()}`}
                        key={key}
                        onClick={() => setSelectedInputSourceType(key as unknown as WorkflowVersionInputSourceType)}
                    >
                        <InputSourceOptionIconContainer className="option-icon">
                            {option.icon}
                        </InputSourceOptionIconContainer>

                        <InputSourceOptionTextContainer data-automation="manual-input-source-selection-content-intake-source">
                            <Paragraph $bold>{option.title}</Paragraph>
                            <Paragraph $light>{option.description}</Paragraph>
                        </InputSourceOptionTextContainer>

                        <Chevron direction={ChevronDirection.RIGHT} />
                    </InputSourceOption>
                ))}
            </InputSourceOptionsContainer>
        </>
    );
};

export default ManualInputSourceSelectionContent;
