import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function DataRetentionConfigurationCtrl(
    $scope,
    $rootScope,
    $q,
    modal,
    utils,
    integrations,
    tonkeanService,
    integrationMetadataManager,
) {
    const ctrl = this;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,

        /**
         * Holds a list of all configured entities in the data retention.
         */
        integrationConfiguredEntities: [],
        integrationEntityDocumentsCount: {},
        isInitializeLoading: true,
        errorMessage: '',
        dataRetentionSettings: {},
        showEntitiesCount: $rootScope.features.currentProject?.tonkean_feature_bundles_pricing_model,
        showShouldCollect: $rootScope.features.currentProject?.tonkean_feature_entities_inbound_configuration,
        nativeEntitiesInboundConfiguration: ctrl.projectIntegration.nativeEntitiesInboundConfiguration || {},
    };
    /**
     * The function extracts the data retention configuration from the project integration.
     * If the configuration is not exists, we fetch it from the server.
     * Eventually, we structure the object to fit the UI model.
     */
    async function getDataRetentionConfiguration(projectIntegrationId) {
        const { dataRetentionConfiguration } = await tonkeanService.getProjectIntegrationDataRetentionConfiguration(
            projectIntegrationId,
        );
        const projectIntegrationDataRetentionSettings = dataRetentionConfiguration.entitiesConfiguration;

        return Object.keys(dataRetentionConfiguration.entitiesConfiguration).map((key) => {
            return {
                displayName: projectIntegrationDataRetentionSettings[key].displayName,
                id: projectIntegrationDataRetentionSettings[key].id,
                isIndefinite: projectIntegrationDataRetentionSettings[key].isIndefinite,
                expirationDays: projectIntegrationDataRetentionSettings[key].expirationDays,
            };
        });
    }

    /**
     * The function returns a map of the documents count for each entity
     */
    async function getEntitiesDocumentsCount(projectIntegrationId) {
        const entitiesCount = await tonkeanService.getProjectDocumentsCountPerIntegrationPerEntity(
            $rootScope.pm.project?.id,
            [projectIntegrationId],
        );
        const entitiesDocumentsCountMap = new Map(Object.entries(entitiesCount.result[projectIntegrationId]));
        return entitiesDocumentsCountMap;
    }

    /**
     * This function loads all of the available integration entities and the project integration data retention settings.
     * @returns {Promise<void>}
     */
    async function loadEntitiesAndConfiguration() {
        try {
            // Loading the integration entities for the project integration we're working on.
            // Since we're having multiple entity selectors, having this call prior to the initialization
            // of the entity selector is helpful as it saves us from loading the entities multiple times.
            await integrationMetadataManager.getIntegrationEntities($scope.data.projectIntegration.id);

            // Extracting the configured data retention for the given project integration.
            $scope.data.integrationConfiguredEntities = await getDataRetentionConfiguration(
                $scope.data.projectIntegration.id,
            );

            // Extracting the documents count for the given project integration per entity.
            if ($scope.data.showEntitiesCount) {
                $scope.data.integrationEntityDocumentsCount = await getEntitiesDocumentsCount(
                    $scope.data.projectIntegration.id,
                );
            }

            // If integration data retention configuration contains ignore entities array.
            if ($scope.data.dataRetentionSettings.ignoreEntities) {
                // Filtering out entities that we don't want to display to the user.
                $scope.data.integrationConfiguredEntities = $scope.data.integrationConfiguredEntities.filter(
                    (entity) => !$scope.data.dataRetentionSettings.ignoreEntities.includes(entity.id),
                );
            }

            // UI indication: Whether the list will be empty or not (Info message will be displayed if true)
            $scope.data.isConfigurationIsEmpty = $scope.data.integrationConfiguredEntities.length === 0;

            if ($scope.data.showShouldCollect) {
                // Passing the entities and for each entity if there is not matching key in nativeEntitiesInboundConfiguration
                // than adding the entity to the nativeEntitiesInboundConfiguration with "should collect" value
                for (const entity of $scope.data.integrationConfiguredEntities) {
                    if (!$scope.data.nativeEntitiesInboundConfiguration[entity.displayName]) {
                        $scope.data.nativeEntitiesInboundConfiguration[entity.displayName] = {
                            collectConfiguration: {
                                isCollectEnabled: true,
                            },
                            livestreamConfiguration: {
                                isLivestreamEnabled: true,
                            },
                            entityType: entity.displayName,
                        };
                    }
                }
            }
        } finally {
            $scope.data.isInitializeLoading = false;
        }
    }

    ctrl.$onInit = function () {
        $scope.data.dataRetentionSettings = integrations.getDataRetentionSettingsByIntegration(
            $scope.data.projectIntegration.integration.integrationType,
        );
        return loadEntitiesAndConfiguration();
    };

    /**
     * Add new blank entity to the list.
     */
    $scope.addEntity = function () {
        $scope.data.integrationConfiguredEntities.push({
            // The identifier of the entity.
            id: null,

            // How long ago we save the entity (in case it's not an indefinite entity). Defaults to 365.
            expirationDays: 365,

            // The display name of the entity.
            displayName: null,

            // Indicates whether the entity will be saved for eternity (== indefinite).
            isIndefinite: false,
        });
    };

    /**
     * Checking whether entity is already selected.
     */
    $scope.isEntityAlreadySelected = function (selectedEntity) {
        return !!utils.findFirst(
            $scope.data.integrationConfiguredEntities,
            (configuredEntity) => configuredEntity.id && configuredEntity.id === selectedEntity.entity,
        );
    };

    /**
     * Closing the modal.
     */
    $scope.onCancel = function () {
        $scope.$dismiss('cancel');
    };

    /**
     * Saving the modal configuration and updating the project integration in the server.
     */
    $scope.saveConfiguration = async function () {
        $scope.data.savingDataRetention = true;

        try {
            const request = {
                entitiesConfiguration: {},
            };

            $scope.data.integrationConfiguredEntities
                .filter((entity) => entity && entity.id)
                .forEach((entity) => {
                    request.entitiesConfiguration[entity.id] = {
                        displayName: entity.displayName,
                        id: entity.id,
                        // If is indefinite set to true we will set expirationDays to null.
                        expirationDays: entity.isIndefinite ? null : entity.expirationDays,
                        // Expiration days and isIndefinite can't be defined in the same time.
                        // Thus, if is indefinite sets to false (means we have expiration days), we set the value to null.
                        isIndefinite: entity.isIndefinite || null,
                    };
                });

            if ($scope.data.showShouldCollect) {
                await Promise.all([
                    tonkeanService.updateProjectIntegrationEntitiesExpirationConfiguration(
                        $scope.data.projectIntegration.id,
                        request,
                    ),
                    tonkeanService.updateProjectIntegrationEntitiesInboundConfiguration(
                        $scope.data.projectIntegration.id,
                        $scope.data.nativeEntitiesInboundConfiguration,
                    ),
                ]);

                ctrl.setNativeEntitiesInboundConfiguration({
                    nativeEntitiesInboundConfiguration: $scope.data.nativeEntitiesInboundConfiguration,
                });
            } else {
                await tonkeanService.updateProjectIntegrationEntitiesExpirationConfiguration(
                    $scope.data.projectIntegration.id,
                    request,
                );
            }

            $rootScope.$emit('alert', {
                msg: `Data retention settings were saved successfully!`,
                type: 'success',
            });
        } catch {
            $scope.data.errorMessage = 'Error occurred while saving configuration.';
        } finally {
            $scope.data.savingDataRetention = false;
        }
    };

    /**
     * Removing entity from the integration configured entities.
     */
    $scope.removeEntity = function (index) {
        const entityToDelete = $scope.data.integrationConfiguredEntities[index];

        // An entity can be removed if the entire integration has canAddOrRemoveAll, or the entity itself is a removable entity.
        const canEntityBeRemoved =
            $scope.data.dataRetentionSettings.canAddOrRemoveAll ||
            ($scope.data.dataRetentionSettings.removableEntities &&
                $scope.data.dataRetentionSettings.removableEntities[entityToDelete.id]);

        if (!entityToDelete.id || canEntityBeRemoved) {
            $scope.data.integrationConfiguredEntities.splice(index, 1);
        }
    };

    $scope.onEntitySelected = function (selectedEntity, index) {
        $scope.data.integrationConfiguredEntities[index].id = selectedEntity.entity;
        $scope.data.integrationConfiguredEntities[index].displayName = selectedEntity.label;
    };

    $scope.onEntityInboundConfigurationToggle = function (selectedEntityType, isActive) {
        $scope.data.nativeEntitiesInboundConfiguration[selectedEntityType].collectConfiguration.isCollectEnabled =
            isActive;
        $scope.data.nativeEntitiesInboundConfiguration[selectedEntityType].livestreamConfiguration.isLivestreamEnabled =
            isActive;
    };

    $scope.onDataRetentionTypeToggle = function (entityId) {
        const entity = $scope.data.integrationConfiguredEntities.find((entity) => entity.id === entityId);

        const changeToggleCallback = () => {
            entity.isIndefinite = !entity.isIndefinite;

            if (!entity.isIndefinite && entity.expirationDays === null) {
                entity.expirationDays = 365;
            }

            return $q.resolve();
        };

        if (!entity.isIndefinite) {
            return modal
                .openQuestionConfirmModal({
                    windowClass: 'mod-primary',
                    controller: 'QuestionConfirmModalCtrl',
                    resolve: {
                        questionConfirmModalData() {
                            return {
                                title: 'Data Collection Settings',
                                body: 'Setting data collection to indefinite is not common. Are you sure?',
                                okLabel: 'Approve',
                                cancelLabel: 'Cancel',
                            };
                        },
                    },
                })
                .result.then(changeToggleCallback)
                .catch(() => {
                    return $q.reject();
                });
        } else {
            return changeToggleCallback();
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('DataRetentionConfigurationCtrl', lateConstructController(DataRetentionConfigurationCtrl));
