import ImageWidget from './components/ImageWidget';
import ImageWidgetEditor from './components/ImageWidgetEditor';
import type ImageWidgetConfiguration from './ImageWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const ImageWidgetPackage: ItemInterfaceWidgetPackage<ImageWidgetConfiguration> = {
    displayComponent: ImageWidget,
    configurationComponent: ImageWidgetEditor,
};

export default ImageWidgetPackage;
