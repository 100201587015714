import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import outgoingWebhookValidator from '../sharedConfigComponents/validators/outgoingWebhookValidator';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const OutgoingWebhookLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.OUTGOING_WEBHOOK,
    title: 'HTTP Action',
    iconClass: 'mod-webhook',
    description: 'Call an HTTP endpoint to perform a custom action.',
    getLinkedFieldDefinitionsConfiguration: () => {
        return [
            {
                field: 'RESPONSE_HEADERS_DATA_CONTAINER',
                friendlyName: 'Response Headers',
            },
            {
                field: 'RESPONSE_BODY_DATA_CONTAINER',
                friendlyName: 'Response Body',
            },
            {
                field: 'RESPONSE_STATUS_CODE_DATA_CONTAINER',
                friendlyName: 'Response Status Code',
            },
        ];
    },
    validator: outgoingWebhookValidator,
};

export default OutgoingWebhookLogicBlockConfig;
