import { useAngularService } from 'angulareact';
import React from 'react';

interface Props {}

const MarketplaceItemInstallSteps = React.lazy(
    () => import('../../modules/MarketplaceItemInstallModule/components/MarketplaceItemInstallSteps'),
);

const CreateEntityFromMarketplaceItemJsonInstallation: React.FC<Props> = ({}) => {
    const $state = useAngularService('$state');
    const marketplaceItem = JSON.parse($state.params.marketplaceItemJson);

    return <MarketplaceItemInstallSteps marketplaceItem={marketplaceItem} />;
};

export default CreateEntityFromMarketplaceItemJsonInstallation;
