import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';
import GoogleAuthenticationComponent from '../components/googleAuthenticationComponents/googleAuthenticationComponent/GoogleAuthenticationComponent';

import { IntegrationEntity } from '@tonkean/tonkean-entities';
import { DataSourcesSectionType } from '@tonkean/tonkean-entities';

export default class GoogleDriveIntegration extends IntegrationDefinitionBase {
    override name = 'googledrive';
    override displayName = 'Google Drive';
    override description = '';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override showEntitiesInboundConfiguration = false;

    override entities = [new IntegrationEntity('File', 'Files'), new IntegrationEntity('Folder', 'Folders')];

    override storageProviderSettings = new StorageProviderSettings(true, true);

    override customizedAuthenticationComponent = GoogleAuthenticationComponent;

    override dataSourceSectionType = DataSourcesSectionType.SPREADSHEET;

    override supportsSharedCredentials = true;

    override dataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { File: true },
        isOnlyDataRetention: { File: true, Folder: true },
        ignoreEntities: ['Sheet', 'Sheets'],
    };

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon('', '130px'),
        new IntegrationIcon('', '130px'),
    );
}
