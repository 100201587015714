import { reactToAngular } from 'angulareact';

import ProjectIntegrationRemoveModal from './ProjectIntegrationRemoveModal';

export default angular
    .module('tonkean.app')
    .component(
        'tnkProjectIntegrationRemoveModal',
        reactToAngular(ProjectIntegrationRemoveModal, [
            'projectIntegrationId',
            'projectIntegrationDisplayName',
            'checkDependencies',
            'warningMessage',
            'title',
        ]),
    );
