import React, { useContext } from 'react';
import styled from 'styled-components';

import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';

import { Checkbox, Paragraph, Tooltip } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ExpandCheckbox = styled(Checkbox)`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
`;

interface Props {
    form: Form;
    onFormDefinitionChanged: (emphasisRequiredFields: boolean) => void;
}

const FormSettingsEmphasisRequiredFieldsCheckbox: React.FC<Props> = ({ form, onFormDefinitionChanged }) => {
    const { isEditable } = useContext(FormBuilderContext);

    return (
        <>
            <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                <ExpandCheckbox
                    checked={form.definition.emphasisRequiredFields}
                    onChange={({ target: { checked } }) => onFormDefinitionChanged(checked)}
                    disabled={!isEditable}
                >
                    <Paragraph $color={Theme.colors.gray_700}>Emphasize Required Fields</Paragraph>
                </ExpandCheckbox>
            </Tooltip>
            <FormSettingsSectionHeaderSubtitle>
                Should the required fields be emphasized
            </FormSettingsSectionHeaderSubtitle>
        </>
    );
};

export default FormSettingsEmphasisRequiredFieldsCheckbox;
