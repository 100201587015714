import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as LockSVG } from '../../../../../images/icons/lock12.svg';
import EnterpriseComponentIcon from '../../../EnterpriseComponentsModule/components/EnterpriseComponentIcon';
import type EnterpriseComponentPageExplorerItem from '../../../ProjectIntegrationPageModule/entities/EnterpriseComponentPageExplorerItem';
import type { EnterpriseComponentPageExplorerLoadingItem } from '../../../ProjectIntegrationPageModule/entities/EnterpriseComponentPageExplorerItem';

import { Placeholder, TextEllipsis, Tooltip } from '@tonkean/infrastructure';
import { PersonPermissionRoleType } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';

const ItemContainer = styled.button<{ selected?: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    text-align: left;
    padding: 12px 0 10px 15px;

    width: 100%;

    &:hover {
        background-color: #e9ecf1;
    }

    ${({ selected }) =>
        selected &&
        css`
            background-color: #e9ecf1;
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed !important;
        `};

    ${styledFocus}
`;

const LockSvgWrapper = styled(LockSVG)`
    margin-left: auto;
    margin-right: 12px;
`;

const ItemContainerPlaceholder = styled.div`
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 15px;
`;

const ItemTitleContainer = styled.div``;

const ItemTitle = styled.div`
    font-size: 12px;
    max-width: 160px;
    color: #5b636c;
`;
const ItemSubTitle = styled.div`
    font-size: 10px;
    color: #9ca6b2;
`;

interface Props {
    item: EnterpriseComponentPageExplorerLoadingItem | EnterpriseComponentPageExplorerItem;
    selectedItemId?: string;
    onItemSelected: (selectedItemId: string) => void;
}

const ProjectIntegrationPageExplorerItemComponent: React.FC<Props> = ({ item, selectedItemId, onItemSelected }) => {
    const myRef = useRef<HTMLButtonElement>(null);

    // Scroll to this item if it's selected.
    // *NOTE* We need to keep the deps empty so this will happen only on the first load, and not on each click
    useEffect(() => {
        if (myRef?.current && selectedItemId === item.id) {
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {item.loading && (
                <ItemContainerPlaceholder>
                    <Placeholder $width="150px" $height="15px" />
                </ItemContainerPlaceholder>
            )}
            {!item.loading && (
                <ItemContainer
                    disabled={item.disabled}
                    ref={myRef}
                    selected={selectedItemId === item.id}
                    onClick={() => {
                        onItemSelected(item.id);
                    }}
                >
                    <EnterpriseComponentIcon
                        integrationType={item.integrationType}
                        iconUrl={item.iconUrl}
                        isValid={item.valid}
                    />

                    <ItemTitleContainer>
                        <ItemTitle
                            data-automation={`project-integration-page-explorer-item-data-source-name-${item.displayName}`}
                        >
                            <TextEllipsis numberOfLines={1} tooltip>
                                {item.displayName}
                            </TextEllipsis>
                        </ItemTitle>

                        <ItemSubTitle>{item.subtitle}</ItemSubTitle>
                    </ItemTitleContainer>

                    {(item.disabled || item.accessType === PersonPermissionRoleType.NOT_AUTHORIZED) && (
                        <Tooltip disabled={!item.disabled} content="Data source is inaccessible from this module.">
                            <LockSvgWrapper />
                        </Tooltip>
                    )}
                </ItemContainer>
            )}
        </>
    );
};

export default ProjectIntegrationPageExplorerItemComponent;
