import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';

const getCellRenderer = (fieldDefinition: FieldDefinition) => {
    switch (fieldDefinition.fieldType) {
        case FieldType.String:
            return 'stringFieldCellRenderer';
        case FieldType.URL:
            return 'urlFieldCellRenderer';
        default:
            return null;
    }
};

export default getCellRenderer;
