import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import TemplateCategoriesSelector from './TemplateCategoriesSelector';
import TemplateHeaderBackgroundSelect, { classNameToTheme } from './TemplateHeaderBackgroundSelect';
import { ReactComponent as SharedTemplateIcon } from '../../../../images/icons/template/shared-template.svg';
import { ReactComponent as UploadImageIcon } from '../../../../images/icons/upload-image.svg';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import type { TinyImage } from '@tonkean/infrastructure';
import {
    Chevron,
    ChevronDirection,
    CollapsibleContent,
    GridColorPicker,
    FileUpload,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Separator,
    SimpleSelect,
    Textarea,
    useUUID,
    XCloseButton,
} from '@tonkean/infrastructure';
import type { Category } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Label = styled.label<LabelProps>`
    font-weight: 500;
    font-size: 12px;
    color: ${(props) => (props.disabled ? Theme.colors.gray_600 : Theme.colors.gray_800)};
    display: inline-block;
    margin-bottom: ${(props) => (props.withSmallBottomMargin ? '10px' : 0)};

    &::after {
        content: '${(props) => (props.isRequired ? '*' : '')}';
        color: ${Theme.colors.error};
        margin-left: 4px;
    }
`;

const Spacer = styled.span`
    width: 6px;
`;

const UploadImageLabel = styled(Label)`
    display: flex;
    align-items: center;
    margin-right: 8px;
    min-width: 140px;
    margin-bottom: 16px;

    svg {
        margin-right: 6px;
    }
`;

const AdvancedSection = styled.button`
    color: ${Theme.colors.primary};
    border: none;
    font-weight: 500;
    font-size: 12px;
    background-color: transparent;
    padding: 0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    margin-top: 8px;
`;

const TemplateModalCloseButton = styled(XCloseButton)`
    position: absolute;
    right: 12px;
    top: 12px;
`;
const ModalSubtitle = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: 12px;
    margin-top: 6px;
`;

const PlusIconSvg = styled.span`
    margin: 4px 4px 0 0;
`;

const HeaderLabel = styled.div`
    margin-left: 10px;
`;

const AdvancedSettingsChevron = styled(Chevron)`
    margin-left: 2px;
    svg > path {
        stroke: ${Theme.colors.primary};
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
`;

const FormStyleGroupParametersWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
`;
const FormGroupParametersWrapper = styled.div`
    justify-content: space-between;
    display: flex;
    width: 100%;
`;

const FormParameterWrapper = styled.div<ParamWrapperProps>`
    justify-content: space-between;
    align-items: ${(props) => (props.direction === 'row' ? 'center' : 'flex-start')};
    display: flex;
    flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
    width: ${(props) => props.width};
    margin-bottom: 20px;
    margin-right: ${(props) => (props.withSmallMarginRight ? '20px' : 0)};
    opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};

    & > * {
        width: ${(props) => (props.fillWidth ? '100%' : '')};
    }
`;
const TemplateNameInput = styled(Input)`
    height: 28px;
`;

const ExpandAdvancedChevron = styled(Chevron)`
    margin-left: 6px;
    path {
        stroke: ${Theme.colors.primary};
    }
`;
const CancelButton = styled(Button)`
    margin-right: 5px;
`;

interface LabelProps {
    withSmallBottomMargin?: boolean;
    disabled?: boolean;
    isRequired?: boolean;
}

interface ParamWrapperProps {
    width?: string;
    direction?: string;
    fillWidth?: boolean;
    withSmallMarginRight?: boolean;
    isDisabled?: boolean;
}

interface Props {
    /**
     * Whether the modal is open.
     */
    isOpen: boolean;

    /**
     * The module default module we want to create the template from.
     */
    defaultFromModuleId?: string;

    /**
     * What callback to call when close action is executed.
     */
    onClose(): void;

    /**
     * The callback to call when an error occurred.
     */
    onError(error: string): void;
}

const TemplateConfigurationModal: React.FC<Props> = ({ isOpen, defaultFromModuleId, onClose, onError }) => {
    const groupInfoManager = useAngularService('groupInfoManager');
    const { data: moduleData, loading: moduleLoading } = useAsyncMethod(groupInfoManager, 'getGroups', false);
    const moduleOptions = useMemo<{ value: string; label: string }[]>(() => {
        return moduleData?.map((singleGroup) => ({ value: singleGroup.id, label: singleGroup.name })) ?? [];
    }, [moduleData]);

    const tonkeanService = useAngularService('tonkeanService');

    const environmentOptions = [
        {
            value: WorkflowVersionType.DRAFT,
            label: 'Build',
        },
        {
            value: WorkflowVersionType.PUBLISHED,
            label: 'Production',
        },
    ];
    const TITLE_DEFAULT_VALUE = '';
    const [title, setTitle] = useState(TITLE_DEFAULT_VALUE);

    const FROM_MODULE_ID_DEFAULT_VALUE = defaultFromModuleId || '';
    const [fromModuleId, setFromModuleId] = useState<string>(FROM_MODULE_ID_DEFAULT_VALUE);

    const FILE_DEFAULT_VALUE = null;
    const [file, setFile] = useState<string | ArrayBuffer | null>(FILE_DEFAULT_VALUE);

    const COLOR_DEFAULT_VALUE = Theme.current.palette.colorPicker.HEX_34393E;
    const [color, setColor] = useState(COLOR_DEFAULT_VALUE);

    const DESCRIPTION_DEFAULT_VALUE = '';
    const [description, setDescription] = useState(DESCRIPTION_DEFAULT_VALUE);

    const LONG_DESCRIPTION_DEFAULT_VALUE = '';
    const [longDescription, setLongDescription] = useState(LONG_DESCRIPTION_DEFAULT_VALUE);

    const IS_ADVANCED_OPEN_DEFAULT_VALUE = false;
    const [isAdvancedOpen, setIsAdvancedOpen] = useState(IS_ADVANCED_OPEN_DEFAULT_VALUE);

    const CATEGORIES_DEFAULT_VALUE: Category[] = [];
    const [categories, setCategories] = useState<Category[]>(CATEGORIES_DEFAULT_VALUE);

    const ENVIRONMENT_DEFAULT_VALUE = undefined;
    const [environment, setEnvironment] = useState<WorkflowVersionType | undefined>(ENVIRONMENT_DEFAULT_VALUE);

    const HEADER_BACKGROUND_DEFAULT_VALUE = classNameToTheme[0]!;
    const [headerBackground, setHeaderBackground] = useState<TinyImage>(HEADER_BACKGROUND_DEFAULT_VALUE);

    const CREATE_PRESSED_DEFAULT_VALUE = false;
    const [createPressed, setCreatePressed] = useState(CREATE_PRESSED_DEFAULT_VALUE);

    const titleInputId = useUUID();
    const descriptionInputId = useUUID();
    const longDescriptionInputId = useUUID();

    const areMandatoryFieldsFull = !!(title && fromModuleId && description && file && environment);

    const createCompanyTemplate = () => {
        if (areMandatoryFieldsFull) {
            console.log(categories);
            tonkeanService
                .createCompanyTemplate(
                    fromModuleId,
                    title,
                    color,
                    headerBackground.css,
                    description,
                    longDescription ? longDescription : description,
                    categories,
                    environment || WorkflowVersionType.PUBLISHED,
                )
                .then((result) => {
                    if (result.error) {
                        onError(result.error);
                    } else {
                        return tonkeanService
                            .uploadTemplateModuleImage(file, result.templateId)
                            .then(() => onClose())
                            .catch(() => onError('An error occurred while trying to upload file.'));
                    }
                })
                .catch((error) =>
                    onError(`An error occurred while trying to create template, Error Message: ${error.error || ''}`),
                )
                .finally(() => {
                    setTitle(TITLE_DEFAULT_VALUE);
                    setFromModuleId(FROM_MODULE_ID_DEFAULT_VALUE);
                    setFile(FILE_DEFAULT_VALUE);
                    setColor(COLOR_DEFAULT_VALUE);
                    setDescription(DESCRIPTION_DEFAULT_VALUE);
                    setLongDescription(LONG_DESCRIPTION_DEFAULT_VALUE);
                    setIsAdvancedOpen(IS_ADVANCED_OPEN_DEFAULT_VALUE);
                    setCategories(CATEGORIES_DEFAULT_VALUE);
                    setEnvironment(ENVIRONMENT_DEFAULT_VALUE);
                    setHeaderBackground(HEADER_BACKGROUND_DEFAULT_VALUE);
                    setCreatePressed(CREATE_PRESSED_DEFAULT_VALUE);
                });
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} fixedWidth>
            <ModalHeader>
                <HeaderWrapper>
                    <span className="tnk-icon">
                        <SharedTemplateIcon />
                    </span>
                    <HeaderLabel>Create Reusable Block</HeaderLabel>
                </HeaderWrapper>
                <TemplateModalCloseButton size={ButtonSize.BIG} onClick={onClose} />
                <ModalSubtitle>Create a Module Template that can be shared and reused across board</ModalSubtitle>
            </ModalHeader>
            <ModalBody>
                <FormParameterWrapper>
                    <Label htmlFor={titleInputId} isRequired withSmallBottomMargin>
                        Template Name
                    </Label>
                    <TemplateNameInput
                        placeholder="Type Name"
                        id={titleInputId}
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                    />
                </FormParameterWrapper>

                <FormGroupParametersWrapper>
                    <FormParameterWrapper width="49%" fillWidth>
                        <Label isRequired withSmallBottomMargin>
                            Base the template on Module
                        </Label>
                        <SimpleSelect
                            placeholder="Pick Module"
                            value={fromModuleId}
                            isLoading={moduleLoading}
                            options={moduleOptions}
                            onChange={(value: string = '') => setFromModuleId(value)}
                            thin
                        />
                    </FormParameterWrapper>

                    <FormParameterWrapper isDisabled={!fromModuleId} width="49%" fillWidth>
                        <Label disabled={!fromModuleId} isRequired withSmallBottomMargin>
                            From Environment
                        </Label>
                        <SimpleSelect
                            isDisabled={!fromModuleId}
                            placeholder="Pick Environment"
                            value={environment}
                            options={environmentOptions}
                            onChange={setEnvironment}
                            thin
                        />
                    </FormParameterWrapper>
                </FormGroupParametersWrapper>

                <FormParameterWrapper fillWidth>
                    <Label withSmallBottomMargin>Related Categories</Label>
                    <TemplateCategoriesSelector categories={categories} onChange={setCategories} />
                </FormParameterWrapper>

                <FormParameterWrapper>
                    <Label htmlFor={descriptionInputId} isRequired withSmallBottomMargin>
                        Summary
                    </Label>
                    <Textarea
                        placeholder="Write short summary..."
                        id={descriptionInputId}
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        minRows={5}
                    />
                </FormParameterWrapper>
                <AdvancedSection
                    className="margin-top"
                    onClick={() => setIsAdvancedOpen((previousValue) => !previousValue)}
                >
                    <span>Long Description</span>
                    <AdvancedSettingsChevron direction={isAdvancedOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
                </AdvancedSection>
                <CollapsibleContent open={isAdvancedOpen} animateOpacity>
                    <Textarea
                        id={longDescriptionInputId}
                        value={longDescription}
                        onChange={(event) => setLongDescription(event.target.value)}
                        minRows={5}
                    />
                </CollapsibleContent>
                <Separator color={Theme.colors.gray_300} $margin="20px" />
                <FormStyleGroupParametersWrapper>
                    <FormParameterWrapper direction="row" withSmallMarginRight>
                        <Label>Symbol Color</Label>
                        <Spacer />
                        <GridColorPicker value={color} onChange={setColor} />
                    </FormParameterWrapper>

                    <FormParameterWrapper direction="row">
                        <Label>Header Background</Label>
                        <TemplateHeaderBackgroundSelect value={headerBackground} onChange={setHeaderBackground} />
                    </FormParameterWrapper>
                </FormStyleGroupParametersWrapper>

                <FormStyleGroupParametersWrapper>
                    <UploadImageLabel isRequired>
                        <span className="tnk-icon">
                            <UploadImageIcon />
                        </span>
                        Module Screenshot
                    </UploadImageLabel>
                    <FileUpload onChange={setFile} label="Upload Module Screenshot" />
                </FormStyleGroupParametersWrapper>
            </ModalBody>

            <ModalFooter align="right" border>
                <CancelButton onClick={onClose} cancel>
                    Cancel
                </CancelButton>
                <Button
                    disabled={!areMandatoryFieldsFull || createPressed}
                    onClick={() => {
                        setCreatePressed(true);
                        createCompanyTemplate();
                    }}
                >
                    {createPressed ? 'Creating' : 'Create'}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default TemplateConfigurationModal;
