import { FieldArray, Formik } from 'formik';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import CustomFilters from '../TnkCustomFilters';

import {
    Checkbox,
    Field,
    H3,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    Paragraph,
    useCloseCallback,
    useSequentialIdentifier,
    withModal,
} from '@tonkean/infrastructure';
import { TrashIcon } from '@tonkean/svg';
import type { FormFieldValidation } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

interface FieldValidationWithKey extends FormFieldValidation {
    key: number;
}

export const EMPTY_ARRAY = [];

const EmptyValidation = styled(Paragraph)`
    font-weight: bold;
    margin-bottom: 10px;
`;

const ValidationBlock = styled.div`
    position: relative;
    background: ${Theme.colors.gray_100};
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 15px;
`;

const DeleteValidation = styled(IconButton)`
    position: absolute;
    top: 5px;
    right: 5px;
`;

const ConditionField = styled(Field)`
    margin: 25px 0 10px;
`;

const FieldValidationSchema = Yup.object({
    validation: Yup.array()
        .of(
            Yup.object({
                errorMessage: Yup.string().required('You must fill a warning message'),
                restrictSubmission: Yup.boolean().required(),
                queryDefinition: Yup.object().required('You must set condition'),
            }).required(),
        )
        .required(),
});

interface Props {
    groupId: string;
    workflowVersionId: string;
    displayName: string;
    fieldValidation?: FormFieldValidation[];
    onChange(fieldValidation: FormFieldValidation[]): void;
}

const FieldValidationConfigurationModal: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    displayName,
    fieldValidation: fieldValidationProp = EMPTY_ARRAY,
    onChange,
}) => {
    const { incrementValue } = useSequentialIdentifier();

    const initialValidation: FieldValidationWithKey[] = useMemo(() => {
        return fieldValidationProp.map((validation) => ({ ...validation, key: incrementValue() }));
    }, [fieldValidationProp, incrementValue]);

    const initialFiltersControlByIndex = useMemo(() => {
        const entries = initialValidation.map((validation) => [validation.key, {}]);
        return Object.fromEntries(entries);
    }, [initialValidation]);

    const filtersControlByIndexRef = useRef<Record<number, Record<string, any>>>(initialFiltersControlByIndex);

    const onClose = useCloseCallback();

    return (
        <Formik
            initialValues={{ validation: initialValidation }}
            onSubmit={(value) => {
                onChange(value.validation);
                onClose();
            }}
            validationSchema={FieldValidationSchema}
        >
            <ModalForm>
                <ModalHeader>
                    <div>
                        <H3 $inline $bold>
                            {displayName}
                        </H3>{' '}
                        <H3 $inline>Data Validation</H3>
                    </div>

                    <Paragraph>Define validation rules and show warning message accordingly</Paragraph>
                </ModalHeader>

                <ModalBody>
                    <FieldArray name="validation">
                        {({ form, push, remove }) => (
                            <>
                                {form.values.validation.length === 0 && (
                                    <EmptyValidation>You have no validation for {displayName}.</EmptyValidation>
                                )}

                                {form.values.validation.map((fieldValidation: FieldValidationWithKey, index) => (
                                    <ValidationBlock key={fieldValidation.key}>
                                        <Field label="Warning Message">
                                            <Input name={`validation.${index}.errorMessage`} />
                                        </Field>

                                        <ConditionField label="Condition">
                                            <CustomFilters
                                                existingDefinition={fieldValidation.queryDefinition}
                                                control={filtersControlByIndexRef.current[fieldValidation.key]}
                                                itemsSource="COLUMN"
                                                groupId={groupId}
                                                workflowVersionId={workflowVersionId}
                                                specialFieldsForFeatures={EMPTY_ARRAY}
                                                onFiltersChangeDefinition={(query) => {
                                                    form.setFieldValue(`validation.${index}.queryDefinition`, query);
                                                }}
                                                hideTimeRangeSelection
                                                hideCloseButton
                                                editMode
                                            />
                                        </ConditionField>

                                        <Field>
                                            <Checkbox name={`validation.${index}.restrictSubmission`}>
                                                Restrict form submission
                                            </Checkbox>
                                        </Field>

                                        <DeleteValidation
                                            onClick={() => {
                                                // Remove controller
                                                delete filtersControlByIndexRef.current[fieldValidation.key];
                                                // Remove from formik
                                                remove(index);
                                            }}
                                            flat
                                        >
                                            <TrashIcon />
                                        </DeleteValidation>
                                    </ValidationBlock>
                                ))}

                                <Button
                                    onClick={() => {
                                        const key = incrementValue();
                                        // Create the control object
                                        filtersControlByIndexRef.current[key] = {};
                                        // Add to formik
                                        push({
                                            errorMessage: '',
                                            restrictSubmission: false,
                                            queryDefinition: undefined,
                                            key,
                                        });
                                    }}
                                    size={ButtonSize.SMALL}
                                    outlined
                                >
                                    Add Validation Rule
                                </Button>
                            </>
                        )}
                    </FieldArray>
                </ModalBody>

                <ModalFooterActions />
            </ModalForm>
        </Formik>
    );
};

export default withModal(FieldValidationConfigurationModal, { size: ModalSize.MEDIUM, fixedWidth: true });
