import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function NlpBranchLogicConfigurationCtrl(
    $scope,
    projectManager,
    tonkeanUtils,
    authenticationService,
    trainedKeywordManager,
    customTriggerManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.tonkeanUtils = tonkeanUtils;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,

        inputExpression: null,
        evaluatedInputExpression: null,
        branches: [],

        hasPlaceholderBranches: false,
        branchesLoading: false,

        currentTrainExampleToAdd: null,
        currentTrainExampleSentiment: 'POSITIVE',

        loadingTrainedKeywords: false,
        errorLoadingTrainedKeywords: false,
        trainedKeywords: [],
        trainedKeywordsSet: {},

        loadingAddNewTrainingExample: false,
        errorLoadingAddNewTrainingExample: false,

        loadingDeleteKeyword: false,
        errorLoadingDeleteKeyword: false,
        loadingDeleteKeywordIndex: null,

        anyKeywordsAddedByWorker: false,

        textAnalysisType: null,
        parentNlpProcessorTrainingSetId: null,
        alreadyUsedModelIds: [],
    };

    ctrl.$onInit = function () {
        const logicParents = customTriggerManager.getWorkflowVersionLogicParents(
            $scope.data.workflowVersionId,
            $scope.data.configuredLogic.node.id,
        );

        // the direct parent is the NLP Processor
        const directParent = logicParents[0];
        $scope.data.textAnalysisType =
            directParent.node.customTriggerActions[0].customTriggerActionDefinition.textAnalysisType;

        if ($scope.data.textAnalysisType === 'TRAINING_SET') {
            $scope.data.parentNlpProcessorTrainingSetId =
                directParent.node.customTriggerActions[0].customTriggerActionDefinition.trainingSetId;

            $scope.data.alreadyUsedModelIds = directParent.impacts
                .map((impact) => impact.node)
                .filter((child) => child.customTriggerType === 'NLP_BRANCH')
                .filter((branchChild) => branchChild?.customTriggerActions?.[0]?.customTriggerActionDefinition)
                .map(
                    (branchChild) =>
                        branchChild.customTriggerActions[0].customTriggerActionDefinition.trainingSetModelId,
                );
        }
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
            loadTrainedKeywords();
            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    $scope.addBranch = function () {
        $scope.data.branches.push({
            displayName: null,
            definition: {},
            customTriggerId: null,
        });

        $scope.onActionsChanged(false);
    };

    $scope.onBranchDefinitionChanged = function (branch, definition) {
        branch.definition = definition;
    };

    $scope.onInputExpressionExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.inputExpression = originalExpression;
        $scope.data.evaluatedInputExpression = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (definition, shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const logicType = $scope.data.configuredLogic.node.customTriggerType;
            const logicConfigurationObject = {
                actions: [
                    {
                        type: logicType,
                        definition,
                    },
                ],
            };

            return ctrl.onActionsChanged({ definition: logicConfigurationObject, shouldSaveLogic });
        }
    };

    /**
     * Occurs on selection of current example sentiment.
     */
    $scope.selectCurrentTrainingExampleSentiment = function (sentiment) {
        $scope.data.currentTrainExampleSentiment = sentiment;
    };

    /**
     * Adds a new training example.
     */
    $scope.addNewTrainingExample = function () {
        $scope.data.loadingAddNewTrainingExample = true;
        $scope.data.errorLoadingAddNewTrainingExample = false;

        trainedKeywordManager
            .addTrainedKeyword(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node.id,
                $scope.data.currentTrainExampleToAdd,
                $scope.data.currentTrainExampleSentiment,
            )
            .then((createdKeyword) => {
                $scope.data.trainedKeywords.push(createdKeyword);
                calculateTrainedKeywordsSet();
            })
            .catch(() => {
                $scope.data.errorLoadingAddNewTrainingExample = true;

                // Removing from cache.
                $scope.data.trainedKeywords.splice(-1, 1);

                calculateTrainedKeywordsSet();
            })
            .finally(() => {
                $scope.data.loadingAddNewTrainingExample = false;
            });
    };

    /**
     * Removes a trained keyword.
     */
    $scope.removeTrainedKeyword = function (index) {
        $scope.data.loadingDeleteKeyword = true;
        $scope.data.errorLoadingDeleteKeyword = false;
        $scope.data.loadingDeleteKeywordIndex = index;

        const trainedKeyword = $scope.data.trainedKeywords[index];

        trainedKeywordManager
            .deleteTrainedKeyword($scope.data.workflowVersionId, trainedKeyword.id)
            .then(() => {
                // Remove from cache.
                $scope.data.trainedKeywords.splice(index, 1);
                calculateTrainedKeywordsSet();
            })
            .catch(() => {
                $scope.data.errorLoadingDeleteKeyword = true;
            })
            .finally(() => {
                $scope.data.loadingDeleteKeyword = false;
            });
    };

    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            $scope.data.configuredLogic.node.customTriggerType,
        );

        if (customTriggerAction) {
            $scope.data.definition = customTriggerAction.customTriggerActionDefinition;
        }
    }

    /**
     * Loads the trained keywords.
     */
    function loadTrainedKeywords() {
        $scope.data.loadingTrainedKeywords = true;
        $scope.data.errorLoadingTrainedKeywords = false;

        trainedKeywordManager
            .getTrainedKeywords($scope.data.workflowVersionId, $scope.data.configuredLogic.node.id)
            .then((data) => {
                $scope.data.trainedKeywords = data.entities;
                calculateTrainedKeywordsSet();
            })
            .catch(() => {
                $scope.data.errorLoadingTrainedKeywords = true;
            })
            .finally(() => {
                $scope.data.loadingTrainedKeywords = false;
            });
    }

    /**
     * Calculates the trained keywords keyword set.
     */
    function calculateTrainedKeywordsSet() {
        $scope.data.trainedKeywordsSet = {};
        $scope.data.anyKeywordsAddedByWorker = false;

        $scope.data.trainedKeywords.forEach((trainedKeyword) => {
            $scope.data.trainedKeywordsSet[trainedKeyword.keyword] = true;

            if (!trainedKeyword.createdManually) {
                $scope.data.anyKeywordsAddedByWorker = true;
            }
        });
    }

    $scope.onTrainingSetModelSelected = function (branchId, logicName) {
        if (ctrl.onBranchNameChanged && !$scope.data.configuredLogic.node.manuallyChangedName) {
            $scope.data.configuredLogic.node.displayName = logicName;
            return ctrl.onBranchNameChanged();
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('NlpBranchLogicConfigurationCtrl', lateConstructController(NlpBranchLogicConfigurationCtrl));
