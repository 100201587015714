<div class="train-trigger-logic">
    <!--  Radio button to choose between training types  -->
    <div>
        <h4>Choose Training Type</h4>
        <div class="flex flex-vmiddle common-normal-weight margin-top-lg">
            <!-- Trained Trigger -->
            <div class="margin-top-xs">
                <tnk-radio-button
                    radio-id="training-set-type-{{ data.workflowVersionId }}-{{ $index }}"
                    radio-name="training-set-type-selection-{{ data.workflowVersionId }}-{{ $index }}"
                    radio-value="TRAINING_SET"
                    model="data.trainingEntityType"
                    on-click="selectTrainingEntityType(onClickParam)"
                    on-click-param="('TRAINING_SET')"
                    is-required="true"
                >
                    <div>Training Set</div>
                </tnk-radio-button>
            </div>

            <!-- Trigger -->
            <div class="margin-top-xs margin-left-xs flex-no-shrink">
                <tnk-radio-button
                    radio-id="trigger-type-{{ data.workflowVersionId }}-{{ $index }}"
                    radio-name="trigger-type-selection-{{ data.workflowVersionId }}-{{ $index }}"
                    radio-value="CUSTOM_TRIGGER"
                    model="data.trainingEntityType"
                    on-click="selectTrainingEntityType(onClickParam)"
                    on-click-param="('CUSTOM_TRIGGER')"
                    is-required="true"
                >
                    <div>Trigger</div>
                </tnk-radio-button>
            </div>
        </div>

        <div ng-if="data.trainingEntityType === 'TRAINING_SET'">
            <tnk-train-training-set-trigger-logic-configuration
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                configured-logic="data.configuredLogic"
                preview-evaluation-source="data.previewEvaluationSource"
                on-training-set-train-trigger-configuration="(onTrainingSetTrainTriggerConfiguration)"
                validation-object="data.validationObject"
            ></tnk-train-training-set-trigger-logic-configuration>
        </div>
    </div>

    <div ng-if="data.trainingEntityType === 'CUSTOM_TRIGGER'">
        <!-- Branches configuration -->
        <div class="keyword-configuration-block margin-top-20" ng-repeat="trainedTrigger in data.trainedTriggers">
            <!-- Title -->
            <div class="flex-vmiddle mod-justify-space">
                <div>Trained Trigger:</div>

                <!-- Delete Icon -->
                <i class="fa fa-trash-o pointer" ng-click="deleteTrainedTrigger($index)"></i>
            </div>

            <!-- Radio buttons for selection of input type of custom trigger -->
            <div class="flex flex-vmiddle common-normal-weight margin-top-xs">
                <!-- Manual -->
                <div class="margin-top-xs">
                    <tnk-radio-button
                        radio-id="manual-input-{{ data.workflowVersionId }}-{{ $index }}"
                        radio-name="input-type-selection-{{ data.workflowVersionId }}-{{ $index }}"
                        radio-value="MANUAL"
                        model="trainedTrigger.inputTypeSelection"
                        on-click="selectInputType(onClickParam, secondOnClickParam)"
                        on-click-param="('MANUAL')"
                        second-on-click-param="$index"
                        is-required="true"
                    >
                        <div>Manual</div>
                    </tnk-radio-button>
                </div>

                <!-- Expression -->
                <div class="margin-top-xs margin-left-xs flex-no-shrink">
                    <tnk-radio-button
                        radio-id="expression-input-{{ data.workflowVersionId }}-{{ $index }}"
                        radio-name="input-type-selection-{{ data.workflowVersionId }}-{{ $index }}"
                        radio-value="EXPRESSION"
                        model="trainedTrigger.inputTypeSelection"
                        on-click="selectInputType(onClickParam, secondOnClickParam)"
                        on-click-param="('EXPRESSION')"
                        second-on-click-param="$index"
                        is-required="true"
                    >
                        <div>Expression</div>
                    </tnk-radio-button>
                </div>
            </div>

            <!-- Explaining the meaning of the expression value -->
            <div ng-if="trainedTrigger.inputTypeSelection === 'EXPRESSION'" class="common-size-xxxxs">
                * Expression value should be the custom trigger display name.
            </div>

            <!-- Manual custom trigger selector -->
            <tnk-custom-trigger-selector
                ng-if="trainedTrigger.inputTypeSelection === 'MANUAL'"
                workflow-version-id="{{ data.workflowVersionId }}"
                selected-custom-trigger-id="{{ trainedTrigger.customTriggerId }}"
                custom-trigger-types="['NLP_BRANCH']"
                empty-state-message="Could not find any NLP branches configured."
                on-custom-trigger-selected="onCustomTriggerSelected(selectedCustomTrigger, $index)"
            ></tnk-custom-trigger-selector>

            <!-- Expression Input -->
            <tnk-tonkean-expression
                ng-if="trainedTrigger.inputTypeSelection === 'EXPRESSION'"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Enter trigger name"
                saved-original-expression="trainedTrigger.customTriggerDisplayNameExpression.originalExpression"
                saved-evaluated-expression="trainedTrigger.customTriggerDisplayNameExpression.evaluatedExpression"
                preview-evaluation-source="data.previewEvaluationSource"
                on-tonkean-expression-changed="
                    onCustomTriggerDisplayNameTonkeanExpressionChanged(
                        originalExpression,
                        evaluatedExpression,
                        shouldSaveLogic,
                        $index
                    )
                "
            ></tnk-tonkean-expression>

            <!-- Keyword section title -->
            <div class="margin-top margin-bottom-xs">Examples:</div>

            <!-- Trained keywords -->
            <div ng-repeat="trainedKeyword in trainedTrigger.trainedKeywords" class="flex-vmiddle mod-align-start">
                <!-- Keyword expression -->
                <div class="margin-right keyword-expression">
                    <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="Enter keyword"
                        saved-original-expression="trainedKeyword.keywordExpression.originalExpression"
                        saved-evaluated-expression="trainedKeyword.keywordExpression.evaluatedExpression"
                        preview-evaluation-source="data.previewEvaluationSource"
                        on-tonkean-expression-changed="
                            onKeywordTonkeanExpressionChanged(
                                originalExpression,
                                evaluatedExpression,
                                shouldSaveLogic,
                                $parent.$index,
                                $index
                            )
                        "
                    ></tnk-tonkean-expression>
                </div>

                <!-- Sentiment dropdown -->
                <div uib-dropdown class="margin-right flex-no-shrink sentiment-dropdown">
                    <!-- Sentiment dropdown toggle -->
                    <div uib-dropdown-toggle class="pointer">
                        <div class="flex-vmiddle">
                            <!-- Current sentiment selection -->
                            <div
                                class="margin-right-xs"
                                ng-class="{
                                    'positive-example': trainedKeyword.keywordSentiment === 'POSITIVE',
                                    'negative-example': trainedKeyword.keywordSentiment === 'NEGATIVE'
                                }"
                            >
                                {{ trainedKeyword.keywordSentiment === 'POSITIVE' ? 'Positive' : 'Negative' }}
                            </div>

                            <!-- Dropdown chevron -->
                            <i class="fa fa-chevron-down common-size-xxxxxs"></i>
                        </div>
                    </div>

                    <!-- Sentiment dropdown options -->
                    <ul uib-dropdown-menu>
                        <li
                            class="sentiment-dropdown-option pointer negative-example"
                            ng-click="selectTrainedKeywordSentiment($parent.$index, $index, 'NEGATIVE')"
                        >
                            Negative
                        </li>
                        <li
                            class="sentiment-dropdown-option pointer positive-example"
                            ng-click="selectTrainedKeywordSentiment($parent.$index, $index, 'POSITIVE')"
                        >
                            Positive
                        </li>
                    </ul>
                </div>

                <!-- Delete Icon -->
                <i
                    class="fa fa-trash-o pointer trained-keywords-delete-icon"
                    ng-click="deleteTrainedKeyword($parent.$index, $index)"
                ></i>
            </div>

            <!-- Add trained keyword -->
            <div ng-click="addTrainedKeyword($index)" class="margin-top">
                <a>+ Add Trained Example</a>
            </div>
        </div>

        <!-- Add new custom trigger -->
        <div ng-click="addEmptyTrainedTrigger()">
            <a>+ Add Trigger To Train</a>
        </div>
    </div>
</div>
