import { atom } from 'recoil';

import type { WorkerRun } from '@tonkean/tonkean-entities';

const historyBreadcrumbsState = atom<
    { workerRun: WorkerRun; shouldClear(currentWorkerRun: WorkerRun): boolean } | undefined
>({
    key: 'historyBreadcrumbs',
    default: undefined,
});

export default historyBreadcrumbsState;
