import React, { useMemo } from 'react';
import styled from 'styled-components';

import EntityRetentionConfigurationRow from './EntityRetentionConfigurationRow';
import type { EntityRetentionSummary } from './useDataRetentionConfiguration';
import useDataRetentionConfiguration from './useDataRetentionConfiguration';
import useSaveProjectIntegrationDataRetention from './useSaveProjectIntegrationDataRetention';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { SavingIndicator, SimpleErrorStateMessage, Spacer } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const TableGrid = styled.div<{ includeAmountColumn: boolean }>`
    display: grid;
    grid-gap: 4px;

    grid-template-columns: ${({ includeAmountColumn }) =>
        `220px minmax(70px,140px) minmax(200px, 250px) ${
            includeAmountColumn ? 'minmax(40px,150px)' : ''
        } minmax(40px, 100px)`};
`;

const TableHeader = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.XSMALL_10};
`;

const StyledSimpleErrorStateMessage = styled(SimpleErrorStateMessage)`
    max-width: 600px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const EmptyState = styled.p``;

const Divider = styled.hr`
    margin: 8px 0;
    grid-column: 1/-1;
`;

interface Props {
    projectIntegration: ProjectIntegration;
}

const ProjectIntegrationDataRetentionTable: React.FC<Props> = ({ projectIntegration }) => {
    const isBundlePricingModel = useFeatureFlag('tonkean_feature_bundles_pricing_model');
    // Should show colum of active records?
    const shouldCalculateActiveRecords = isBundlePricingModel || false;

    const [{ error: errorSaveDataRetention, loading: loadingSaveDataRetention }, saveDataRetention] =
        useSaveProjectIntegrationDataRetention(projectIntegration.id);

    const {
        updatedConfiguration,
        canAddEntities,
        ignoredEntities,
        loading: loadingDataRetention,
        onChange,
        deleteEntity,
        canAddEntity,
        addEntity,
        discardChanges,
        error,
    } = useDataRetentionConfiguration(projectIntegration, shouldCalculateActiveRecords);

    // List of all the entities ids.
    const entitiesIds = useMemo(() => {
        return updatedConfiguration.map((entity) => entity.id);
    }, [updatedConfiguration]);

    // If one of the crucial request returns an error - show the error message
    if (error) {
        return <SimpleErrorStateMessage error={error} showSmallError />;
    }

    return (
        <>
            <TableGrid includeAmountColumn={shouldCalculateActiveRecords}>
                <TableHeader>Entity Name</TableHeader>
                <TableHeader>Is Collect Enabled?</TableHeader>
                <TableHeader>Entity Data Lifespan</TableHeader>
                {shouldCalculateActiveRecords && <TableHeader>Current Active Records</TableHeader>}
                <TableHeader>{/* Empty Space For Trash Icon */}</TableHeader>
                <Divider />

                {updatedConfiguration.map((entityConfig) => (
                    <>
                        <EntityRetentionConfigurationRow
                            key={entityConfig.id}
                            projectIntegration={projectIntegration}
                            entityDataRetentionSummary={entityConfig}
                            configuredEntitiesIds={entitiesIds}
                            showActiveRecordsColumn={shouldCalculateActiveRecords}
                            ignoreEntities={ignoredEntities}
                            onChange={(entity: EntityRetentionSummary) =>
                                onChange(entityConfig.reactIdForRender, entity)
                            }
                            deleteEntity={() => deleteEntity(entityConfig.reactIdForRender)}
                        />

                        <Divider />
                    </>
                ))}
            </TableGrid>

            {updatedConfiguration.length === 0 && !loadingDataRetention && (
                <EmptyState>Data collection configuration is empty, no data is being currently collected.</EmptyState>
            )}

            <Spacer height={20} />

            <ButtonsWrapper>
                {canAddEntities && (
                    <Button disabled={canAddEntity} onClick={addEntity} highlighted>
                        + Add Entity
                    </Button>
                )}

                <Button onClick={() => saveDataRetention(updatedConfiguration)}>Save</Button>
                <Button onClick={discardChanges} deleteButton>
                    Discard Changes
                </Button>

                <SavingIndicator error={errorSaveDataRetention} loading={loadingSaveDataRetention} />

                {errorSaveDataRetention && (
                    <StyledSimpleErrorStateMessage error={errorSaveDataRetention} showSmallError />
                )}
            </ButtonsWrapper>
        </>
    );
};

export default ProjectIntegrationDataRetentionTable;
