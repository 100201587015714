import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

// Make sure the key matches the type property of the value
const innerItemLogicBlockConfigs = [
    LogicBlockConfigType.MANUAL_NEXT_STEPS,
    LogicBlockConfigType.SYNC_INNER_MATCHED_ENTITY,
    LogicBlockConfigType.APPROVAL_CYCLE,
    LogicBlockConfigType.NEXT_STEPS,
    // LogicBlockConfigType.SEND_FORM_ANSWERED,
];

export default innerItemLogicBlockConfigs;
