import { useField } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { FormikTnkCustomFilters } from '@tonkean/angular-to-react-components';
import type { CustomFilterFieldMetadata } from '@tonkean/angular-to-react-components';
import { Radio, RadioGroup } from '@tonkean/infrastructure';
import type { BasicQueryDefinition } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledFormikTnkCustomFilters = styled(FormikTnkCustomFilters)`
    flex-grow: 1;
`;

const FilterWrapper = styled.div`
    display: flex;
    flex-grow: 1;
`;

const RadioGroupWrapper = styled.div`
    margin-bottom: 8px;
`;

interface Props {
    name: string;
    projectIntegrationId: string;
}

const ProjectIntegrationEntityLiveStreamFilterPayloadRadio: React.FC<Props> = ({ name, projectIntegrationId }) => {
    const [webhookFilterDefinition] = useField<{ shouldAcceptAll: boolean; queryDefinition: BasicQueryDefinition }>(
        name,
    );

    // Get First X webhook payload fields that received in the given project integration.
    const { data: fieldsResponse } = useTonkeanService(
        'getWebhookPayloadsFieldsMetadataOfProjectIntegration',
        projectIntegrationId,
        0,
        30,
    );

    // Map all the received webhook payload fields into CustomFilterFieldMetadata
    const additionalFields: CustomFilterFieldMetadata[] = useMemo(() => {
        return (
            fieldsResponse?.fields.map((field) => ({
                name: field.fieldName,
                label: field.fieldName,
                type: 'string',
            })) || []
        );
    }, [fieldsResponse]);

    return (
        <>
            <RadioGroupWrapper>
                <RadioGroup size={InputSize.MEDIUM} name={`${name}.shouldAcceptAll`} direction="column">
                    <Radio value>Accept all</Radio>
                    <Radio value={false}>Filter payloads</Radio>
                </RadioGroup>
            </RadioGroupWrapper>

            {!webhookFilterDefinition.value.shouldAcceptAll && fieldsResponse && (
                <FilterWrapper>
                    <StyledFormikTnkCustomFilters
                        itemsSource="CUSTOM"
                        specialFieldsForFeatures={EMPTY_ARRAY}
                        name="webhookFilter.queryDefinition"
                        additionalFields={additionalFields}
                        hideTimeRangeSelection
                        hideCloseButton
                        editMode
                    />
                </FilterWrapper>
            )}
        </>
    );
};

export default ProjectIntegrationEntityLiveStreamFilterPayloadRadio;
