import FunctionOperatorBase from './FunctionOperatorBase';
import { separatorCharsPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class CommentTranscriptFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.COMMENT_TRANSCRIPT;
    override readonly sign = 'CommentTranscript';
    override readonly displayName = 'Comment Transcript';
    override readonly description = 'Will return the transcript of the comments.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Skip',
            dataType: FieldType.Number,
            defaultValue: 0,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Limit',
            dataType: FieldType.Number,
            defaultValue: 250,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Separator',
            dataType: FieldType.String,
            defaultValue: '\n',
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: separatorCharsPreset,
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Sort order',
            dataType: FieldType.String,
            defaultValue: 'ASC',
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: ['ASC', 'DESC'],
        },
    ];
}
