import { Chart } from 'chart.js';
import { isInitiative } from '@tonkean/fields';
import { ChartDisplayType } from '@tonkean/infrastructure';
function FieldViewModalCtrl($scope, $timeout, $filter, modal, tonkeanService, utils, field, target, viewMode) {
    $scope.field = field;
    $scope.target = target;
    // Whether the field view modal will display graph or the field full value
    $scope.viewMode = viewMode;
    $scope.categoricalChartDisplayType = ChartDisplayType.DOUGHNUT;

    const bgColors = [
        'rgba(55, 161, 197, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
    ];
    const borderColors = [
        'rgba(55, 161, 197, 1)',
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
    ];

    $scope.init = function () {
        $scope.data = {
            intervalOptions: {
                '14 days': {
                    key: '1d',
                    days: 1,
                    range: 14,
                },
                '30 days': {
                    key: '1d',
                    days: 1,
                    range: 30,
                },
                '90 days': {
                    key: '1d',
                    days: 1,
                    range: 90,
                },
                '180 days': {
                    key: '1d',
                    days: 1,
                    range: 180,
                },
                '365 days': {
                    key: '1d',
                    days: 1,
                    range: 365,
                },
            },
            rangeTo: new Date(),
            historyGraphValues: {},
        };

        $scope.data.interval = $scope.data.intervalOptions['30 days'];
        $scope.data.isInitiative = isInitiative(target);

        if (!viewMode || !viewMode.length) {
            // means we want to see a graph and not the field value
            $scope.loading = true;
            getHistoryGraph();
        }
    };

    /**
     * Once the user selects an interval.
     */
    $scope.onIntervalSelect = function () {
        $scope.loading = true;
        getHistoryGraph();
    };

    $scope.dismiss = function () {
        if ($scope.data.fieldViewChart) {
            // destroy the chart
            $scope.data.fieldViewChart.destroy();
        }

        $scope.$dismiss();
    };

    /**
     * Opens the initiative modal view if target id of field is initiative.
     */
    $scope.openTarget = function () {
        $scope.dismiss();
        if (isInitiative(target)) {
            modal.openViewInitiative(target.id);
        }
    };

    /**
     * Returns the field aggregation values.
     */
    function getHistoryGraph() {
        const from = new Date($scope.data.rangeTo);
        from.setDate(from.getDate() - $scope.data.interval.days * $scope.data.interval.range);
        $scope.data.rangeFrom = from;

        tonkeanService
            .getFieldGraphValues(field.id, from.getTime(), $scope.data.rangeTo.getTime(), 10_000)
            .then((data) => {
                // convert to x and y
                const chartData = [];
                const chartLabels = [];
                const chartValues = {};

                $scope.data.min = null;
                $scope.data.max = null;

                if (data.oldestFieldHistory) {
                    // oldestFieldHistory is data from before the "from" date, so the graph always has a starting point.
                    // So we add it to the entities array as the first date with the from date.
                    data.oldestFieldHistory.historyDate = from.getTime();

                    // Make sure the entities array exists.
                    if (!data.entities) {
                        data.entities = [];
                    }

                    if (!data.entities.length) {
                        // This syntax creates a new copy of the oldestFieldHistory object as lastFieldHistory.
                        let lastFieldHistory = {};
                        lastFieldHistory = Object.assign(lastFieldHistory, data.oldestFieldHistory);
                        lastFieldHistory.historyDate = $scope.data.rangeTo.getTime();
                        // If we don't have any entities, the oldestFieldHistory should also be the last entity with the "to" date.
                        data.entities.push(lastFieldHistory);
                    }
                    data.entities.unshift(data.oldestFieldHistory);
                }

                for (let i = 0; i < data.entities.length; i++) {
                    const currentResult = data.entities[i];

                    const date = i === 0 ? '' : $filter('date')(currentResult.historyDate, 'mediumDate');
                    let valueNum;

                    if (utils.isNullOrUndefined(currentResult.numberValue)) {
                        // No number value.
                        valueNum = false;
                        chartValues[date] = currentResult.value || '';
                    } else {
                        // There's a number value.
                        valueNum = true;
                        chartValues[date] = currentResult.numberValue;
                    }

                    // Try to convert to number even if we didn't find a number.
                    let value = chartValues[date];
                    if (!valueNum) {
                        const val = Number.parseFloat(value);
                        if (!utils.isNullOrUndefined(val)) {
                            value = val.toFixed(4);
                            valueNum = true;
                        }
                    }

                    if (valueNum) {
                        // If the value is a number, calc the min and max for the y axe.
                        if ($scope.data.min === null || value < $scope.data.min) {
                            $scope.data.min = value;
                        }
                        if ($scope.data.max === null || value > $scope.data.max) {
                            $scope.data.max = value;
                        }
                    }
                }

                for (const key in chartValues) {
                    if (chartValues.hasOwnProperty(key)) {
                        chartLabels.push(key);
                        chartData.push(chartValues[key]);
                    }
                }

                $scope.data.chartRawData = chartValues;
                $scope.data.chartData = chartData;
                $scope.data.chartLabels = chartLabels;
                $scope.data.results = data.entities;

                $scope.loading = false;
                $timeout(loadChart);
            });
    }

    /**
     * If target id is initiative, loads related activities, and if there are such, displays them on the graph.
     */
    function loadActivity() {
        if (isInitiative(target)) {
            const types = ['INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED'];
            tonkeanService.getInitiativeActivity(target.id, 20, 0, types, false).then(function (data) {
                const minValue = $scope.data.fieldViewChart.scales['y-axis-0'].min;
                const points = {};
                const activitiesMap = {};

                $scope.data.activity = data.activity;
                for (let i = 0; i < data.activity.length; i++) {
                    const activity = data.activity[i];
                    if (
                        !activity.metadata.nothingChanged &&
                        activity.created > $scope.data.rangeFrom.getTime() &&
                        activity.created <= $scope.data.rangeTo.getTime()
                    ) {
                        if (!points[activity.metadata.newState.stateColor]) {
                            points[activity.metadata.newState.stateColor] = [];
                            activitiesMap[activity.metadata.newState.stateColor] = [];
                        }

                        const date = $filter('date')(activity.created, 'mediumDate');
                        const y = $scope.data.chartRawData[date] | minValue;
                        points[activity.metadata.newState.stateColor].push({ x: date, y });
                        activitiesMap[activity.metadata.newState.stateColor].push(activity);
                    }
                }

                const activitiesArr = [];
                for (const state in points) {
                    if (points.hasOwnProperty(state)) {
                        activitiesArr.splice(0, 0, activitiesMap[state]);
                        $scope.data.fieldViewChart.data.datasets.splice(0, 0, {
                            data: points[state],
                            backgroundColor: state,
                            borderColor: 'white',
                            borderWidth: 3,
                            fill: false,
                            pointRadius: 9,
                            pointHoverRadius: 10,
                            showLine: true,
                        });
                    }
                }

                $scope.data.activityStates = activitiesArr;
                $scope.data.fieldViewChart.update();
            });
        }
    }

    /**
     * Constructs tooltip element.
     */
    const customToolTip = function (tooltip) {
        // Tooltip Element
        let tooltipEl = document.querySelector('#chartjs-tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            $scope.data.fieldViewChart.canvas.parentNode.append(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltip.yAlign) {
            tooltipEl.classList.add(tooltip.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];

            let innerHtml = '<thead>';

            titleLines.forEach(function (title) {
                innerHtml += `<tr><th>${title}</th></tr>`;
            });
            innerHtml += '</thead><tbody>';

            for (let i = 0; i < tooltip.body.length; i++) {
                const body = tooltip.body[i].lines[0];
                const colors = tooltip.labelColors[i];
                let after = '';
                let style = `background:${colors.backgroundColor}`;
                style += `; border-color:${colors.borderColor}`;
                style += '; border-width: 2px';
                const span = `<span class="chartjs-tooltip-key" style="${style}"></span>`;
                if (tooltip.body[i].after.length) {
                    after = `<hr class="margin-normal-sm-v"/><div style="min-width: 300px;">${tooltip.body[i].after[0]}</div>`;
                }
                innerHtml += `<tr><td><hr class="margin-normal-sm-v"/>${span}<strong>${body}</strong>${after}</td></tr>`;
            }

            innerHtml += '</tbody>';

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
        }

        const positionY = $scope.data.fieldViewChart.canvas.offsetTop;
        const positionX = $scope.data.fieldViewChart.canvas.offsetLeft;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
        tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
        // tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
    };

    /**
     * Loads and draws the chart using the evaluated data from API.
     */
    function loadChart() {
        const data = {
            xLabels: $scope.data.chartLabels,
            datasets: [
                {
                    data: $scope.data.chartData,
                    backgroundColor: bgColors,
                    borderColor: borderColors,
                    borderWidth: 4,
                    pointRadius: 1,
                    pointHoverRadius: 4,
                    steppedLine: true,
                },
            ],
        };

        $scope.data.yAx = {};
        const yAx = $scope.data.yAx;
        yAx.gridLines = { color: 'rgba(0, 0, 0, 0.05)', zeroLineColor: 'rgba(0, 0, 0, 0.25)' };
        if (!utils.isNullOrEmpty($scope.data.min) && !utils.isNullOrEmpty($scope.data.max)) {
            // means it's a number
            yAx.ticks = {
                callback(value) {
                    // todo: support all our field definition display type
                    return $filter('number')(value) || value;
                },
                suggestedMin: Number.parseFloat(($scope.data.min * 0.5).toFixed(1)),
                suggestedMax: Number.parseFloat(($scope.data.max * 1.3).toFixed(1)),
            };
        } else {
            // means it's string
            yAx.type = 'category';
            yAx.position = 'left';
            yAx.display = true;
            yAx.ticks = {
                reverse: false,
            };
            data.datasets[0].lineTension = 0;
            data.yLabels = getUniqueValue($scope.data.chartData, true);
        }

        if ($scope.data.fieldViewChart) {
            // destroy the old one if exists
            $scope.data.fieldViewChart.destroy();
        }

        // load chart
        const ctx = document.querySelector('#fieldViewChart');
        $scope.data.fieldViewChart = new Chart(ctx, {
            type: 'line',
            data,
            options: {
                responsive: true,
                tooltips: {
                    enabled: false,
                    mode: 'point',
                    intersect: false,
                    custom: customToolTip,
                    callbacks: {
                        label(tooltipItem) {
                            if (
                                $scope.data.activityStates &&
                                tooltipItem.datasetIndex < $scope.data.activityStates.length
                            ) {
                                // it's an activity
                                const item = $scope.data.activityStates[tooltipItem.datasetIndex][tooltipItem.index];
                                return item.metadata.newState.stateText;
                            } else {
                                return $filter('number')(tooltipItem.yLabel) || tooltipItem.yLabel;
                            }
                        },
                        afterLabel(tooltipItem) {
                            if (
                                $scope.data.activityStates &&
                                tooltipItem.datasetIndex < $scope.data.activityStates.length
                            ) {
                                // it's an activity
                                const item = $scope.data.activityStates[tooltipItem.datasetIndex][tooltipItem.index];
                                const updateText =
                                    item.metadata.newUpdateText && item.metadata.newUpdateText.length
                                        ? `:<br/>"${item.metadata.newUpdateText}"`
                                        : '';
                                const html = `<div class="flex"><div class="margin-right"><img class="avatar img-circle" src="${item.actor.avatarUri}"></div><div><strong>${item.actor.name}</strong>${updateText}</div></div>`;
                                return html; // item.actor.name + updateText;
                            }
                            return null;
                        },
                    },
                },
                hover: {
                    mode: 'point',
                    intersect: false,
                },
                legend: { display: false },
                scales: {
                    yAxes: [yAx],
                    xAxes: [{ gridLines: { color: 'rgba(0, 0, 0, 0.01)' } }],
                },
            },
        });

        // load the tonkean activity to add as another layer
        loadActivity();
    }

    // function replaceData(chart, data) {
    //     // need to update the data, first clear
    //     chart.data.xLabels.length = 0;
    //     chart.data.datasets.length = 0;
    //
    //     chart.update();
    //
    //     for (let i = 0; i < data.xLabels.length; i++) {
    //         chart.data.xLabels.push(data.xLabels[i]);
    //     }
    //
    //     for (let j = 0; j < data.datasets.length; j++) {
    //         chart.data.datasets.push(data.datasets[j]);
    //     }
    //
    //     // chart.scales['y-axis-0'] = yAx;
    //
    //     chart.update();
    // }

    /**
     * Gets the unique value out of the given data.
     */
    function getUniqueValue(data, reverse) {
        const map = {};
        const result = ['']; // adding an empty one for the 100% point
        // reverse
        for (let i = 0; i < data.length; i++) {
            const val = reverse ? data[data.length - 1 - i] : data[i];
            if (!map[val]) {
                result.push(val);
                map[val] = true;
            }
        }
        result.push(''); // adding an empty one for the 0% point
        return result;
    }

    $scope.init();
}

angular.module('tonkean.app').controller('FieldViewModalCtrl', FieldViewModalCtrl);
