import React from 'react';
import styled from 'styled-components';

import type ItemsGridCellRendererParams from '../../entities/ItemsGridCellRendererParams';

import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const DueDate = styled.div<{ isDueDatePassed: boolean }>`
    color: ${({ isDueDatePassed }) => (isDueDatePassed ? '#df2525' : Theme.colors.gray_700)};
`;

const DueDateCellRenderer: React.FC<ItemsGridCellRendererParams> = ({ value }) => {
    const isDueDatePassed = value < Math.floor(Date.now());
    return (
        <DueDate isDueDatePassed={isDueDatePassed}>
            {value ? utils.formatDate(value, false, true, true, true) : ''}
        </DueDate>
    );
};

export default DueDateCellRenderer;
