import React from 'react';
import styled from 'styled-components';

import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { Icon } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const StyledTooltip = styled(Tooltip)`
    text-transform: capitalize;
`;

interface Props {
    icon: Icon;
    onChangeIcon: (icon: Icon) => void;
    disableTooltip?: boolean;
}

const IconDisplay: React.FC<React.PropsWithChildren<Props>> = ({
    icon,
    onChangeIcon,
    disableTooltip = false,
    children,
}) => {
    const tooltipContent = icon.name?.replaceAll('-', ' ')?.replaceAll('_', ' ')?.toLowerCase();
    return (
        <>
            <StyledTooltip content={tooltipContent} disabled={disableTooltip || !tooltipContent}>
                <Clickable
                    data-automation={`icon-${icon.name}`}
                    aria-label={icon.name}
                    onClick={() => onChangeIcon(icon)}
                >
                    {children}
                </Clickable>
            </StyledTooltip>
        </>
    );
};

export default IconDisplay;
