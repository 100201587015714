import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { TnkProjectIntegrationSelector } from '@tonkean/angular-to-react-components';
import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { SavingIndicator, Tooltip } from '@tonkean/infrastructure';
import type { Form, TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Theme } from '@tonkean/tui-theme';

const TooltipIconWrapper = styled.div`
    margin-left: 8px;
    color: ${Theme.colors.primary};
`;

const StyledStateLink = styled(StateLink)`
    margin: 0 4px;
`;
interface Props {
    groupId: string;
    workflowVersionId: string;
    currentSelectedDataStorageId?: string | undefined;
    currentFolderExpression?: TonkeanExpressionDefinition;
    previewEvaluationSource?: any;
    onDataStorageChanged(selectedDataStorageId?: string, folderNameExpression?: TonkeanExpressionDefinition): void;
    validationObject?: any;
    fileStorageLabel?: string;
    destinationFolderLabel?: string;
    form?: Form;
    environment?: string;
    defaultFormDateStorageDefinition?: boolean;
    dataStorageIsLoading?: boolean;
    disabled?: boolean;
}

const UploadFilesDataStorageSelection: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    onDataStorageChanged,
    currentSelectedDataStorageId,
    previewEvaluationSource,
    currentFolderExpression,
    validationObject,
    fileStorageLabel,
    destinationFolderLabel,
    form,
    environment,
    defaultFormDateStorageDefinition = true,
    dataStorageIsLoading,
    disabled,
}) => {
    const [selectedDataStorage, setSelectedDataStorage] = useState<string | undefined>(currentSelectedDataStorageId);
    const [folderIdExpression, setFolderIdExpression] = useState<TonkeanExpressionDefinition | undefined>(
        currentFolderExpression,
    );

    const [shouldUseDefaultFormDateStorage, setShouldUseDefaultFormDateStorage] = useState<boolean>(
        defaultFormDateStorageDefinition,
    );
    const integrations = useAngularService('integrations');

    const dataStorageIntegrationTypes = useMemo(
        () => Object.keys(integrations.getSupportedUploadingStorageIntegrations()),
        [integrations],
    );

    useEffect(() => {
        setSelectedDataStorage(currentSelectedDataStorageId);
    }, [currentSelectedDataStorageId]);

    useEffect(() => {
        setFolderIdExpression(currentFolderExpression);
    }, [currentFolderExpression]);

    const stateLinkTargetState = 'product.workerEditor';
    const stateLinkParams = useMemo(
        () => ({
            selectedOutlineFormId: form?.id,
            t: 'forms',
            env: environment,
            g: form?.group?.id,
        }),
        [environment, form?.group?.id, form?.id],
    );

    const onProjectIntegrationSelected = useCallback(
        (newDataStorage: { id: string; formDefault?: boolean }) => {
            setSelectedDataStorage(newDataStorage.id);
            setShouldUseDefaultFormDateStorage(!!newDataStorage?.formDefault);
            let folderExpression: TonkeanExpressionDefinition | undefined = undefined;
            if (newDataStorage?.formDefault) {
                folderExpression = form?.definition?.destinationFolderId;
            }
            setFolderIdExpression(folderExpression);
            onDataStorageChanged(newDataStorage.id, folderExpression);
        },
        [form?.definition?.destinationFolderId, onDataStorageChanged],
    );

    const onTonkeanExpressionChanged = useCallback(
        (originalExpression: string, evaluatedExpression: string, expression: TonkeanExpressionDefinition) => {
            setFolderIdExpression(expression);
            onDataStorageChanged(selectedDataStorage, expression);
        },
        [onDataStorageChanged, selectedDataStorage],
    );

    const isDataStorageSameToFormDefault = useMemo(
        () =>
            !!form?.definition?.dataStorageId &&
            form?.definition?.dataStorageId === selectedDataStorage &&
            !!form?.definition?.destinationFolderId &&
            form?.definition?.destinationFolderId.originalExpression === folderIdExpression?.originalExpression,
        [
            folderIdExpression?.originalExpression,
            form?.definition?.dataStorageId,
            form?.definition?.destinationFolderId,
            selectedDataStorage,
        ],
    );

    return (
        <div key={`${form?.id}-${disabled}`}>
            <hr />
            <div className="common-title-xxs margin-bottom-xs upload-file-data-storage-label">
                {fileStorageLabel || 'File Storage'}
                <SavingIndicator loading={dataStorageIsLoading || false} error={false} />
            </div>
            <TnkProjectIntegrationSelector
                filterOnIntegrationType={dataStorageIntegrationTypes}
                onProjectIntegrationSelected={onProjectIntegrationSelected}
                selectedProjectIntegrationId={selectedDataStorage}
                formDefaultSelectionDataStorageId={form?.definition?.dataStorageId}
                selectorLabelPlaceholder="Select file storage"
                isFormDefaultSelected={isDataStorageSameToFormDefault}
                displayFormDefault={defaultFormDateStorageDefinition}
                disabled={disabled}
            />
            {validationObject?.dataProviderIdError && (
                <div className="common-error margin-top-xxs"> {validationObject.dataProviderIdError}</div>
            )}
            <div className="common-title-xxs margin-bottom-xs margin-top-20 flex-inline upload-file-data-storage-label">
                {destinationFolderLabel || 'Destination Folder (ID)'}
                <Tooltip content='The folder ID is a unique sequence of characters found in the "External entity ID" data source field.'>
                    <TooltipIconWrapper>
                        <span className="fa fa-info-circle" />
                    </TooltipIconWrapper>
                </Tooltip>
            </div>

            {!!form?.definition?.destinationFolderId?.originalExpression &&
            isDataStorageSameToFormDefault &&
            shouldUseDefaultFormDateStorage ? (
                <div className="margin-bottom-xs upload-file-data-storage-label">
                    The folder configured on the
                    <StyledStateLink state={stateLinkTargetState} params={stateLinkParams}>
                        upload-file form
                    </StyledStateLink>
                    will be used
                </div>
            ) : (
                <TonkeanExpression
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    onTonkeanExpressionChanged={onTonkeanExpressionChanged}
                    placeholder="Folder ID"
                    savedOriginalExpression={folderIdExpression?.originalExpression}
                    previewEvaluationSource={previewEvaluationSource}
                    savedEvaluatedExpression={folderIdExpression?.evaluatedExpression}
                />
            )}

            {validationObject?.destinationFolderIdError && (
                <div className="common-error margin-top-xxs"> {validationObject.destinationFolderIdError}</div>
            )}
        </div>
    );
};

export default UploadFilesDataStorageSelection;
