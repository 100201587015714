import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const Label = styled.div`
    color: ${Theme.colors.gray_700};
`;
const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ProjectIntegrationCollectStatusMaxPages: React.FC = () => {
    return (
        <LabelWrapper>
            <Label>Collect Stopped after reaching the max page limit</Label>
        </LabelWrapper>
    );
};

export default ProjectIntegrationCollectStatusMaxPages;
