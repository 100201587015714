import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { InvalidLogics } from './InvalidLogicsValidationContext';
import InvalidLogicsValidationContext from './InvalidLogicsValidationContext';
import ItemDetailsModuleEditorSidePaneContent from './ItemDetailsModuleEditorSidePaneContent';
import WorkflowFolderProjectIntegrationsAccessContext from './WorkflowFolderProjectIntegrationsAccessContext';
import DisableOnProduction from '../../../../infrastructure/components/DisableOnProduction';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { LoadingCircle } from '@tonkean/infrastructure';
import { TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';
import type { TonkeanId, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationWorkflowFolderAccess } from '@tonkean/tonkean-entities';

const DisableOnProductionWrapper = styled(DisableOnProduction)`
    padding: 15px 18px;
    flex-grow: 1;
`;

interface Props {
    // We get the whole workflowversion because we need angular to re-render this component when the cached reference changes
    // Should only get id when we move to graphql
    workflowVersion: WorkflowVersion;
    workflowFolderProjectIntegrationsAccess?: ProjectIntegrationWorkflowFolderAccess;
    requestLogicsValidation(): void;
    invalidLogics: InvalidLogics;
}

const ItemDetailsModuleEditorSidePane: React.FC<Props> = ({
    workflowVersion,
    workflowFolderProjectIntegrationsAccess,
    requestLogicsValidation,
    invalidLogics,
}) => {
    const [targetIdQuery] = useGetStateParams<[TonkeanId]>('targetId');
    const [leftPanName] = useGetStateParams<[string]>('t');
    const invalidLogicsContext = useMemo(
        () => ({ invalidLogics, requestLogicsValidation }),
        [invalidLogics, requestLogicsValidation],
    );

    const targetId = useMemo(() => {
        if (!targetIdQuery) {
            return workflowVersion.groupId;
        } else if (targetIdQuery.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.GROUP])) {
            return targetIdQuery as TonkeanId<TonkeanType.GROUP>;
        } else {
            return targetIdQuery as TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
        }
    }, [targetIdQuery, workflowVersion.groupId]);

    return (
        <DisableOnProductionWrapper>
            <WorkflowFolderProjectIntegrationsAccessContext.Provider value={workflowFolderProjectIntegrationsAccess}>
                <InvalidLogicsValidationContext.Provider value={invalidLogicsContext}>
                    <React.Suspense fallback={<LoadingCircle centered large />}>
                        <ItemDetailsModuleEditorSidePaneContent
                            groupId={workflowVersion.groupId}
                            workflowVersionId={workflowVersion.id}
                            targetId={leftPanName === 'item-details' ? targetId : workflowVersion.groupId}
                        />
                    </React.Suspense>
                </InvalidLogicsValidationContext.Provider>
            </WorkflowFolderProjectIntegrationsAccessContext.Provider>
        </DisableOnProductionWrapper>
    );
};

export default ItemDetailsModuleEditorSidePane;
