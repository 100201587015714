import template from './tnkOutlookCustomActionsDefinition.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkOutlookCustomActionsDefinition', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        projectIntegration: '<',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        definition: '<', // The selected action definition.
        fieldDefinitionsToCreate: '<', // If we need to create additional fields
        matchConfigurationObject: '<',
        validationObject: '<',
        onDefinitionChanged: '&',
    },
    template,
    controller: 'OutlookCustomActionsDefinitionCtrl',
});
