import { lateConstructController } from '@tonkean/angular-components';
import { InitiativeStatus } from '@tonkean/tonkean-entities';
import { FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP, getFullFieldObject } from '@tonkean/tonkean-utils';

/* @ngInject */
function FieldToUpdateEditorCtrl(
    $scope,
    projectManager,
    tonkeanUtils,
    utils,
    customFieldsManager,
    workflowVersionManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.cfm = customFieldsManager;
    $scope.wvm = workflowVersionManager;
    $scope.formulaSpecialFieldIdToDefinitionMap = FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP;

    $scope.data = {
        fieldSelectorGroupId: ctrl.fieldSelectorGroupId,
        fieldSelectorWorkflowVersionId: ctrl.fieldSelectorWorkflowVersionId,
        expressionGroupId: ctrl.expressionGroupId,
        expressionWorkflowVersionId: ctrl.expressionWorkflowVersionId,
        isPreselectedField: ctrl.isPreselectedField,
        preselectedFieldLabel: ctrl.preselectedFieldLabel,
        preselectedFieldId: ctrl.preselectedFieldId,
        specialFieldsForFeatures: ctrl.specialFieldsForFeatures,
        onFieldRemovedCallbackParam: ctrl.onFieldRemovedCallbackParam,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        logicId: ctrl.logicId,
        shouldHideDeleteButton: ctrl.shouldHideDeleteButton,
        configuredField:
            ctrl.configuredField && ctrl.configuredField.inputTypeSelection
                ? ctrl.configuredField
                : {
                      inputTypeSelection: 'EXPRESSION',
                  },

        multipleValuesSeparator: ';',

        fullFieldObject: null,
        reloadFields: ctrl.reloadFields,
        callbackParam: ctrl.callbackParam,
        doNotIncludeFieldIdsSet: ctrl.doNotIncludeFieldIdsSet,
        multipleExpressionsValues: [{}],
        globalExpressionOnly: ctrl.globalExpressionOnly
    };

    /**
     * Initialization function for the controller.
     */
    ctrl.$onInit = function () {
        if (
            ctrl.preselectedFieldId &&
            $scope.data.configuredField &&
            ctrl.fieldSelectorWorkflowVersionId &&
            !ctrl.configuredField.inputTypeSelection
        ) {
            $scope.data.fullFieldObject = getFullFieldObject(
                ctrl.preselectedFieldId,
                $scope.data.fieldSelectorWorkflowVersionId,
                customFieldsManager.selectedFieldsMap,
            );
            $scope.data.configuredField.speciallyHandledField = tonkeanUtils.getSpeciallyHandledFieldForFieldEditor(
                $scope.data.fullFieldObject,
            );
            $scope.data.configuredField.inputTypeSelection = tonkeanUtils.getInputTypeSelectionForFieldEditor(
                $scope.data.configuredField.speciallyHandledField,
            );
            $scope.data.configuredField.fieldId = ctrl.preselectedFieldId;
        }

        parseMultipleValuesFromSingleExpression();
    };

    /**
     * Occurs on changes of the component.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.shouldHideDeleteButton) {
            $scope.data.shouldHideDeleteButton = ctrl.shouldHideDeleteButton;
        }

        if (changesObj.fieldSelectorWorkflowVersionId) {
            $scope.data.fieldSelectorWorkflowVersionId = ctrl.fieldSelectorWorkflowVersionId;

            if ($scope.data.fieldSelectorWorkflowVersionId) {
                // Adding the single selected option to the fields being updated.
                if ($scope.data.isPreselectedField && !$scope.data.configuredField.inputTypeSelection) {
                    $scope.data.fullFieldObject = getFullFieldObject(
                        $scope.data.preselectedFieldId,
                        $scope.data.fieldSelectorWorkflowVersionId,
                        customFieldsManager.selectedFieldsMap,
                    );
                    const speciallyHandledField = tonkeanUtils.getSpeciallyHandledFieldForFieldEditor(
                        $scope.data.fullFieldObject,
                    );

                    $scope.data.configuredField = {
                        fieldId: $scope.data.preselectedFieldId,
                        speciallyHandledField,
                        inputTypeSelection: tonkeanUtils.getInputTypeSelectionForFieldEditor(speciallyHandledField),
                    };
                } else if ($scope.data.configuredField.inputTypeSelection) {
                    $scope.data.fullFieldObject = getFullFieldObject(
                        $scope.data.configuredField.fieldId,
                        $scope.data.fieldSelectorWorkflowVersionId,
                        customFieldsManager.selectedFieldsMap,
                    );
                    $scope.data.configuredField.speciallyHandledField =
                        tonkeanUtils.getSpeciallyHandledFieldForFieldEditor($scope.data.fullFieldObject);

                    parseMultipleValuesFromSingleExpression();
                } else if (!$scope.data.configuredField.inputTypeSelection) {
                    $scope.data.configuredField.inputTypeSelection = tonkeanUtils.getInputTypeSelectionForFieldEditor(
                        $scope.data.configuredField.speciallyHandledField,
                    );
                }
            }
        }

        if (changesObj.expressionGroupId) {
            $scope.data.expressionGroupId = ctrl.expressionGroupId;
        }

        if (changesObj.doNotIncludeFieldIdsSet) {
            $scope.data.doNotIncludeFieldIdsSet = ctrl.doNotIncludeFieldIdsSet;
        }

        if (changesObj.fieldSelectorGroupId) {
            $scope.data.fieldSelectorGroupId = ctrl.fieldSelectorGroupId;
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = ctrl.previewEvaluationSource;
        }
    };

    /**
     * Occurs on field selection.
     */
    $scope.onFieldOptionSelected = function (selectedFieldFullFieldObject) {
        $scope.data.configuredField.fieldId = selectedFieldFullFieldObject?.id;
        $scope.data.fullFieldObject = selectedFieldFullFieldObject;
        $scope.data.configuredField.speciallyHandledField =
            tonkeanUtils.getSpeciallyHandledFieldForFieldEditor(selectedFieldFullFieldObject);
        $scope.data.configuredField.inputTypeSelection = tonkeanUtils.getInputTypeSelectionForFieldEditor(
            $scope.data.configuredField.speciallyHandledField,
        );
        $scope.onConfiguredFieldChanged(true);
    };

    /**
     * Occurs on input type selected.
     */
    $scope.selectInputType = function (inputTypeSelection) {
        $scope.data.configuredField.inputTypeSelection = inputTypeSelection;

        // If the input type selection is an expression, that means we are configured a "manual" value.
        // In that case, we just reset the expression value, to clear out any chance we will take
        // the multiple values concatenated string with us.
        if (inputTypeSelection === 'EXPRESSION') {
            $scope.data.configuredField.expressionValue = {
                originalExpression: null,
                evaluatedExpression: null,
            };
        }

        // If the input type selection is empty value we reset the expression value
        else if (inputTypeSelection === 'EMPTY_VALUE') {
            $scope.data.configuredField.expressionValue = undefined;
        } else {
            $scope.data.multipleInputExpressionsValues = [{}];
            $scope.data.configuredField.expressionMultipleValue = {};
        }

        $scope.onConfiguredFieldChanged(true);
    };

    /**
     * Notifies users with the change of configured field.
     */
    $scope.onConfiguredFieldChanged = function (shouldSave) {
        if (ctrl.onConfiguredFieldChanged) {
            ctrl.onConfiguredFieldChanged({
                configuredField: {
                    ...$scope.data.configuredField,
                    multipleInputExpressionsValues: $scope.data.multipleInputExpressionsValues,
                },
                shouldSaveLogic: shouldSave,
                callbackParam: $scope.data.callbackParam,
            });
        }
    };

    /**
     * Occurs once an user is updated.
     */
    $scope.userUpdated = function (emails) {
        $scope.data.configuredField.userValue = emails;
        $scope.onConfiguredFieldChanged(true);
    };

    /**
     * Occurs once an owner is selected.
     */
    $scope.ownerSelected = function (person) {
        $scope.data.configuredField.ownerValue = {
            ownerId: person.id,
        };
        $scope.onConfiguredFieldChanged(true);
    };

    /**
     * Removes a selection of a reviewer.
     */
    $scope.ownerRemoved = function () {
        $scope.data.configuredField.ownerValue = {};
        $scope.onConfiguredFieldChanged(true);
    };

    /**
     * Occurs once days from execution current time is changed.
     */
    $scope.onDaysFromExecutionCurrentTimeChanged = function () {
        $scope.onConfiguredFieldChanged(true);
    };

    /**
     * Occurs whenever a state (TNK_STAGE) is changed.
     */
    $scope.onStateChanged = function () {
        $scope.onConfiguredFieldChanged(true);
    };

    /**
     * Occurs once the value tonkean expression is changed.
     */
    $scope.onFieldValueTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        if (
            expression &&
            expression.originalExpression !== null &&
            expression.evaluatedExpression !== null &&
            (!$scope.data.configuredField.expressionValue ||
                $scope.data.configuredField.expressionValue.originalExpression === null ||
                $scope.data.configuredField.expressionValue.evaluatedExpression === null ||
                $scope.data.configuredField.originalExpression !== expression.originalExpression)
        ) {
            $scope.data.configuredField.expressionValue = expression;

            // When saving single value remove the multiple value input values
            $scope.data.multipleInputExpressionsValues = [{}];
            $scope.data.configuredField.expressionMultipleValue = {};

            $scope.onConfiguredFieldChanged(shouldSaveLogic);
        }
    };

    /**
     * Occurs once a value in the multiples values option is changed.
     */
    $scope.onFieldMultipleValueTonkeanExpressionChanged = function (expression, shouldSaveLogic, index) {
        if (
            expression?.originalExpression &&
            expression?.evaluatedExpression &&
            expression.originalExpression !== $scope.data.multipleInputExpressionsValues[index].originalExpression
        ) {
            // Remove old value and insert new one
            $scope.data.multipleInputExpressionsValues.splice(index, 1, {
                originalExpression: expression.originalExpression,
                evaluatedExpression: expression.evaluatedExpression,
            });

            // Temporary hack - concat the strings into a single value
            updateConcatenatedMultipleValue();

            $scope.onConfiguredFieldChanged(shouldSaveLogic);
        }

        // If the current expression is empty we set null to the original expression
        if (expression?.originalExpression === '' || expression?.evaluatedExpression === '') {
            $scope.data.multipleInputExpressionsValues.splice(index, 1, {
                originalExpression: null,
                evaluatedExpression: null,
            });
            $scope.onConfiguredFieldChanged(shouldSaveLogic);
        }
    };

    $scope.statusesGroupByType = function (status) {
        return status.type === InitiativeStatus.INTAKE ? "Intake" : "Triage And Coordination"
    };

    /**
     * Occurs on removal of a field.
     */
    $scope.onFieldRemoved = function () {
        if (ctrl.onFieldRemoved) {
            ctrl.onFieldRemoved({
                configuredField: $scope.data.configuredField,
                callbackParam: $scope.data.onFieldRemovedCallbackParam,
            });
        }
    };

    /**
     * Occurs when we click "Add Another Value"
     */
    $scope.addValueToField = function () {
        if (!$scope.data.multipleInputExpressionsValues) {
            $scope.data.multipleInputExpressionsValues = [];
        }
        $scope.data.multipleInputExpressionsValues.push({});
    };

    /**
     * Occurs when we click "Add Another Value"
     */
    $scope.deleteValueFromField = function (index) {
        $scope.data.multipleInputExpressionsValues.splice(index, 1);

        // Update the multi value field from the up-to-date array
        updateConcatenatedMultipleValue();

        $scope.onConfiguredFieldChanged(true);
    };

    /**
     * Initializes the field values from the concatenated value
     * If no expression exists, init with an empty value
     */
    function parseMultipleValuesFromSingleExpression() {
        // Only if we selected multiple values and have an expression to initialize from
        if (
            ctrl.configuredField.inputTypeSelection === 'MULTIPLE_VALUES' &&
            !utils.isEmpty($scope.data.configuredField.expressionValue)
        ) {
            // When the saved expression is empty, we want to initialize with an empty object
            if (utils.isEmpty(ctrl.configuredField.expressionValue)) {
                $scope.data.multipleInputExpressionsValues.push({});
                return;
            }

            // Initialize the values from the concatenated expression
            $scope.data.multipleInputExpressionsValues = [];

            // Make pairs from the concatenated original and evaluated expressions respectively
            utils
                .zipArrays(
                    $scope.data.configuredField.expressionValue.originalExpression.split(
                        $scope.data.multipleValuesSeparator,
                    ),
                    $scope.data.configuredField.expressionValue.evaluatedExpression.split(
                        $scope.data.multipleValuesSeparator,
                    ),
                )
                .forEach((zippedExpressionPair) => {
                    const originalExpression = zippedExpressionPair[0];
                    const evaluatedExpression = zippedExpressionPair[1];

                    if (originalExpression && evaluatedExpression) {
                        $scope.data.multipleInputExpressionsValues.push({
                            originalExpression,
                            evaluatedExpression,
                        });
                    }
                });

            // Update the concatenated field, so it will be saved when the on change will be called
            updateConcatenatedMultipleValue();
        }

        // Initialize with at least one empty multiple value
        if (utils.isEmpty($scope.data.multipleInputExpressionsValues)) {
            $scope.data.multipleInputExpressionsValues = [{}];
        }
    }

    /**
     * Updates the field that is concatenated and later saved instead of the single value (in onActionChanged function)
     */
    function updateConcatenatedMultipleValue() {
        $scope.data.configuredField.expressionValue = {
            evaluatedExpression: $scope.data.multipleInputExpressionsValues
                .map((expression) => expression.evaluatedExpression)
                .join($scope.data.multipleValuesSeparator),
            originalExpression: $scope.data.multipleInputExpressionsValues
                .map((expression) => expression.originalExpression)
                .join($scope.data.multipleValuesSeparator),
        };
    }

    /**
     * Filter function to filter out  matched entities
     * @param fields - the array to filter the file fields from
     * @returns {boolean}
     */
    $scope.noMatchedEntityFields = function (fields) {
        return fields?.filter((field) => !field?.idRelationField);
    };
}

export default angular.module('tonkean.app').controller('FieldToUpdateEditorCtrl', lateConstructController(FieldToUpdateEditorCtrl));
