import React, { useMemo } from 'react';

import type { CustomSelectorProps } from './ContractFieldsMapping';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { CustomTrigger } from '@tonkean/tonkean-entities';

type Props = CustomSelectorProps<CustomTrigger> & {
    placeholder?: string;
    icon?: JSX.Element;
};

const ContractCustomTriggerSelector: React.FC<Props> = ({
    onChange,
    selectedField,
    loading,
    options,
    placeholder,
    icon,
}) => {
    const processedOptions = useMemo(() => {
        return options.map((summary) => ({
            value: summary.id.toString(),
            label: summary.displayName,
            icon,
        }));
    }, [icon, options]);

    return (
        <SimpleSelect
            placeholder={placeholder || 'Select a custom trigger'}
            isCreatable={false}
            options={processedOptions}
            isLoading={loading}
            getOptionValue={(option) => option.value}
            value={selectedField}
            onChange={onChange}
            isClearable={false}
            readOnly={loading}
            isDisabled={false}
        />
    );
};

export default ContractCustomTriggerSelector;
