import { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const processMapperEdgeStyle = css`
    .view-wrapper .edge {
        // Reset so it won't affect the edge icon
        stroke: transparent;
        stroke-width: 1;
        marker-end: none;
        use {
            // Default fill for elements with no fill inside the use.
            display: none;
        }
        .edge-path {
            stroke: #979797;
            stroke-width: 2px;
            marker-end: url(#arrow-ending);
        }
        .no-path {
            marker-end: url(#line-ending);
        }
        .edge-inner-path {
            marker-end: none !important;
        }
        .selected-border {
            fill: transparent;
        }
        .edge-text {
            display: none;
        }
        &.selected {
            .selected-border {
                fill: #3800ae;
            }
            .edge-text {
                display: inline;
                fill: #5558af;
                stroke: ${Theme.colors.primaryHighlight};
            }
            .edge-path {
                stroke: ${Theme.colors.primaryHighlight};
                marker-end: url(#arrow-ending-selected);
            }
            .no-path {
                marker-end: url(#line-ending-selected);
            }
        }
    }
    .view-wrapper .graph-controls {
        z-index: 3;
        .slider-wrapper {
            > span {
                display: none;
            }
            .slider {
                margin: 0;
            }
        }
        .slider-button svg {
            vertical-align: middle;
        }
    }
`;
export default processMapperEdgeStyle;
