import template from './tnkGroupFieldsMetadataTable.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkGroupFieldsMetadataTable', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        openTriggersOutline: '&',
        reloadConfiguration: '<',
        canModifyBot: '<',
        miniMode: '<',
        onFieldsGroupExpanded: '&',
        onFieldSelected: '<',
        fieldEditorHasPendingChanges: '<',
        onTabSelected: '<',
    },
    template,
    controller: 'GroupFieldsMetadataTableCtrl',
});
