import PlusButtonAutonomousTypesDropdown from './PlusButtonAutonomousTypesDropdown';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkPlusButtonAutonomousTypesDropdown',
        reactToAngular(PlusButtonAutonomousTypesDropdown, ['onAutonomousTypeSelected', 'placement']),
    );
