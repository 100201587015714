import React from 'react';

import { Field, SimpleSelect } from '@tonkean/infrastructure';
import { RequiredIndication } from '@tonkean/tonkean-entities';

export const RequiredIndicatorTypeOptions = Object.keys(RequiredIndication).map((v) => ({
    value: v,
    label: RequiredIndication[v],
}));

interface Props {
    name: string;
}

const WidgetRequiredIndicationConfiguration: React.FC<Props> = ({ name }) => {
    return (
        <Field
            label="Required label"
            informationTooltip="Configure the style of indication that used to highlight required fields"
            dataAutomation="widget-required-indication-configuration"
            light
        >
            <SimpleSelect name={name} options={RequiredIndicatorTypeOptions} thin />
        </Field>
    );
};
export default WidgetRequiredIndicationConfiguration;
