import { useMemo } from 'react';

import { useInitiativeValuesFromExpressionsQueries } from '@tonkean/infrastructure';
import { useTDLMatchConditions } from '@tonkean/tonkean-data-layer';
import type { TonkeanType } from '@tonkean/tonkean-entities';
import {
    type MatchConditionExpression,
    type ProcessMapperNode,
    type Initiative,
    WorkflowVersionType,
    type ProcessMapperNodeStateConditionsConfiguration,
    ProcessMapperNodeStage,
    type TonkeanId,
} from '@tonkean/tonkean-entities';

function nodeWithStateConditionKey(
    node: ProcessMapperNode,
    stateCondition: ProcessMapperNodeStateConditionsConfiguration,
) {
    return `${node.id}-${stateCondition.stage}`;
}

interface UseProcessMapperNodesToStageProps {
    initiative: Initiative | undefined;
    nodes: ProcessMapperNode[];
}

interface UseProcessMapperNodesToStageResult {
    processMapperNodesToStage: Record<TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>, ProcessMapperNodeStage> | undefined;
    loadingProcessMapperNodesToStage: boolean;
}

const useProcessMapperNodesToStage = ({
    initiative,
    nodes,
}: UseProcessMapperNodesToStageProps): UseProcessMapperNodesToStageResult => {
    const conditionExpressionsToMatch: MatchConditionExpression[] = useMemo(() => {
        return nodes.reduce((acc, node) => {
            node.configuration.stateConditions.forEach((stateCondition) => {
                if (
                    stateCondition.condition?.query &&
                    (stateCondition.condition?.query.queries?.length > 0 ||
                        stateCondition.condition?.query.filters?.length > 0)
                ) {
                    acc.push({
                        entityId: nodeWithStateConditionKey(node, stateCondition),
                        queryDefinition: stateCondition.condition,
                    });
                }
            });

            return acc;
        }, [] as MatchConditionExpression[]);
    }, [nodes]);

    const allFieldsFromExpressions = useInitiativeValuesFromExpressionsQueries(initiative, conditionExpressionsToMatch);

    const { data: matchConditionsResponse, isLoading: matchConditionsLoading } = useTDLMatchConditions({
        fieldValues: allFieldsFromExpressions,
        matchInput: conditionExpressionsToMatch,
        workflowVersionType: initiative?.isDraftInitiative ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
    });

    const processMapperNodesToStage = useMemo(() => {
        if (matchConditionsLoading || !matchConditionsResponse) {
            return undefined;
        }

        return nodes.reduce(
            (acc, node) => {
                const nodeStageWithMatchedConditions = node.configuration.stateConditions.find((stateCondition) => {
                    const stateConditionKey = nodeWithStateConditionKey(node, stateCondition);
                    return matchConditionsResponse[stateConditionKey]?.matched;
                })?.stage;

                if (nodeStageWithMatchedConditions) {
                    acc[node.id] = nodeStageWithMatchedConditions;
                } else {
                    acc[node.id] = ProcessMapperNodeStage.NOT_ACTIVE;
                }

                return acc;
            },
            {} as Record<TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>, ProcessMapperNodeStage>,
        );
    }, [matchConditionsLoading, matchConditionsResponse, nodes]);

    return { processMapperNodesToStage, loadingProcessMapperNodesToStage: matchConditionsLoading };
};

export default useProcessMapperNodesToStage;
