import { useAngularService } from 'angulareact';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { SCIMTonkeanRole } from '@tonkean/tonkean-entities';

const useIsGuestData = (projectId: TonkeanId<TonkeanType.PROJECT>) => {
    const authenticationService = useAngularService('authenticationService');
    return (
        authenticationService.currentUser?.projectContexts?.[projectId]?.calculatedTonkeanRoles?.includes(
            SCIMTonkeanRole.GUEST,
        ) && authenticationService.currentUser?.projectContexts?.[projectId]?.calculatedTonkeanRoles?.length === 1
    );
};
export default useIsGuestData;
