import template from './tnkTrainTriggerLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkTrainTriggerLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        previewEvaluationSource: '<',
        onActionsChanged: '&',
    },
    template,
    controller: 'trainTriggerLogicConfigurationCtrl',
});
