import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import SolutionReportIcon from './SolutionReportIcon';
import SolutionReportListContext from './SolutionReportListContext';
import { ReactComponent as PencilIcon } from '../../../../images/icons/edit-pencil.svg';
import { ReactComponent as BusinessReportIcon } from '../../../../images/icons/navigation/live-reports.svg';
import EditBusinessReportAccessibilityModal from '../../SolutionViewerModule/components/EditBusinessReportAccessibilityModal';
import { SolutionReportTypes } from '../entities/SolutionReportType';
import type { SolutionReport } from '../entities/SolutionReportType';

import { KebabMenuButton, Menu, MenuItem, useToggle } from '@tonkean/infrastructure';
import { ManageSolutionBusinessReportModal } from '@tonkean/solution-business-report';
import type { BusinessReportAccessType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const KebabWrapper = styled.div`
    margin-right: 17px;
`;

const StyledBusinessMenuIcon = styled(BusinessReportIcon)`
    path {
        fill: ${Theme.colors.gray_500};
    }
`;

const StyledPencilMenuIcon = styled(PencilIcon)`
    path {
        fill: ${Theme.colors.gray_500};
    }
`;

interface Props {
    solutionReport?: SolutionReport;
}

const SolutionReportListItemKebabMenu: React.FC<Props> = ({ solutionReport }) => {
    const $state = useAngularService('$state');
    const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
    const [isAccessibilityModalOpen, setIsAccessibilityModalOpen] = useState(false);
    const [isEditModalOpen, toggleIsEditModalOpen] = useToggle(false);
    const { setBusinessReportAccessibility, workflowFolder, fullSolutionBusinessReportsById, setFullSolutionReport } =
        useContext(SolutionReportListContext);
    const [isUserMaker] = useAngularWatch(() => workflowFolder.isUserOwner);

    const goToReport = useCallback(() => {
        if (!solutionReport) {
            return;
        }

        const newStateParams =
            solutionReport.type === SolutionReportTypes.BUSINESS
                ? ['product.board', { filter: 'all', g: solutionReport.id }, {}]
                : ['product.solution-business-report', { solutionBusinessReportId: solutionReport.id }, {}];
        $state.go(...newStateParams);
    }, [solutionReport, $state]);

    const updateBusinessReportAccessibility = useCallback(
        (newBusinessReportAccessibilityType: BusinessReportAccessType, specificPeopleWithAccess: string[]) => {
            if (!solutionReport) {
                return;
            }
            setBusinessReportAccessibility(
                solutionReport.id,
                newBusinessReportAccessibilityType,
                specificPeopleWithAccess,
            );
        },
        [setBusinessReportAccessibility, solutionReport],
    );

    return (
        <>
            {!solutionReport ? null : (
                <Menu
                    show={isMenuOpen}
                    onClose={() => toggleIsMenuOpen()}
                    menuItems={
                        <>
                            <MenuItem onClick={goToReport} icon={<SolutionReportIcon type={solutionReport.type} />}>
                                Go to report
                            </MenuItem>
                            {solutionReport.type === SolutionReportTypes.SOLUTION && (
                                <MenuItem
                                    onClick={() => toggleIsEditModalOpen()}
                                    icon={<StyledPencilMenuIcon />}
                                    disabled={!isUserMaker}
                                >
                                    Edit report
                                </MenuItem>
                            )}
                            {solutionReport.type === SolutionReportTypes.BUSINESS && (
                                <MenuItem
                                    onClick={() => setIsAccessibilityModalOpen(true)}
                                    icon={<StyledBusinessMenuIcon />}
                                    disabled={!isUserMaker}
                                >
                                    Manage permissions
                                </MenuItem>
                            )}
                        </>
                    }
                >
                    <KebabWrapper>
                        <KebabMenuButton onClick={() => toggleIsMenuOpen()} thin flat />
                    </KebabWrapper>
                </Menu>
            )}
            {solutionReport?.type === SolutionReportTypes.BUSINESS && (
                <EditBusinessReportAccessibilityModal
                    open={isAccessibilityModalOpen}
                    onClose={() => setIsAccessibilityModalOpen(false)}
                    businessReport={solutionReport.permissions}
                    onEdit={updateBusinessReportAccessibility}
                />
            )}
            {solutionReport?.type === SolutionReportTypes.SOLUTION && (
                <ManageSolutionBusinessReportModal
                    workflowFolder={workflowFolder}
                    solutionBusinessReport={fullSolutionBusinessReportsById[solutionReport.id]}
                    open={isEditModalOpen}
                    onClose={() => toggleIsEditModalOpen()}
                    onSave={setFullSolutionReport}
                />
            )}
        </>
    );
};

export default SolutionReportListItemKebabMenu;
