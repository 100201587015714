import { useCallback, useRef } from 'react';

import { useToastMessage } from '@tonkean/angular-hooks';
import utils from '@tonkean/utils';

const useCopyToClipboardFromInput = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const emit = useToastMessage();

    const copyToClipboard = useCallback(async () => {
        if (inputRef?.current == null) {
            return;
        }

        let copySucceeded: boolean = false;
        if (navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(inputRef.current.value);
                copySucceeded = true;
            } catch {
                copySucceeded = false;
            }
        }
        if (!copySucceeded) {
            copySucceeded = utils.copyToClipboardFromInput(inputRef.current);
        }

        if (copySucceeded) {
            emit('copied to clipboard', 'success');
        } else {
            emit('There was a problem copying to clipboard', 'danger');
        }
    }, [emit]);

    return { copyToClipboard, inputRef };
};

export default useCopyToClipboardFromInput;
