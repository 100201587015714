import { Breakpoint, useBreakpoint } from '@tonkean/infrastructure';
import { ItemInterfaceWidgetConfigurationDisplayMode } from '@tonkean/tonkean-entities';

/**
 * Hook for getting widget display mode according to configuration and device size
 */
function useWidgetCardsDisplayMode(
    displayMode: ItemInterfaceWidgetConfigurationDisplayMode,
    processContributorDisplayMode?: ItemInterfaceWidgetConfigurationDisplayMode,
) {
    const effectiveBreakpoint = useBreakpoint();
    const isMobile = effectiveBreakpoint <= Breakpoint.MID_XSMALL_768;

    if (isMobile) {
        return displayMode !== ItemInterfaceWidgetConfigurationDisplayMode.TABLE;
    } else {
        const isWidgetConfiguresDisplayModeCards = displayMode === ItemInterfaceWidgetConfigurationDisplayMode.CARDS;
        const isPcConfiguredDisplayModeCards =
            processContributorDisplayMode === ItemInterfaceWidgetConfigurationDisplayMode.CARDS;
        return (isWidgetConfiguresDisplayModeCards && isPcConfiguredDisplayModeCards) || isPcConfiguredDisplayModeCards;
    }
}

export default useWidgetCardsDisplayMode;
