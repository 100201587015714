import type CreateAfterOption from '../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../entities/CreateAfterOptionCanCreate';
import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import logicConfigModes from '../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../entities/TriggerParamsEnricher';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import botQuestionAndSendNotificationValidator from '../sharedConfigComponents/validators/botQuestionAndSendNotificatinValidator';
import formQuestionTypeMustMatchFormType from '../sharedConfigComponents/validators/formQuestionTypeMustMatchFormType';
import validateFormBeforePublish from '../sharedConfigComponents/validators/validateFormBeforePublish';

import { CustomTriggerType, LogicBlockConfigType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const askForInformationAnsweredTriggerParamsEnricher: TriggerParamsEnricher = (params) => {
    params.customTriggerType = CustomTriggerType.ASK_FIELD_UPDATE_ANSWERED;
    params.displayName = addAskForInformationAnsweredBlockAfterOption.blockDisplayName;
    params.selectWithConfigMode = logicConfigModes.configuration;
    params.customTriggerActions = [
        {
            type: CustomTriggerType.ASK_FIELD_UPDATE_ANSWERED,
            customTriggerActionDefinition: {
                replyOnOriginalMessage: true,
            },
        },
    ];
};

const askForInformationAnsweredCanCreate: CreateAfterOptionCanCreate = (childImpacts) => {
    // If there are no child triggers with this type, it can be created
    return (
        !childImpacts?.length ||
        childImpacts.filter((trigger) => trigger.node.customTriggerType === CustomTriggerType.ASK_FIELD_UPDATE_ANSWERED)
            .length === 0
    );
};

const addAskForInformationAnsweredBlockAfterOption: CreateAfterOption = {
    label: 'Ask For Information Answered',
    blockDisplayName: 'When Ask For Information Answered',
    triggerParamsEnricher: askForInformationAnsweredTriggerParamsEnricher,
    canCreate: askForInformationAnsweredCanCreate,
};

const AskFieldUpdateLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.ASK_FIELD_UPDATE,
    title: 'Ask for Information',
    iconClass: 'mod-ask-field',
    description: 'Ask someone what value should be set to a field in Tonkean\\External systems.',
    hasPersonConfig: true,
    defaultActionDefinition: (project) => {
        return {
            initiativeOwner: true,
            communicationProjectIntegrationId: project?.defaultCommunicationProjectIntegration?.id,
        };
    },
    createAfterOptions: () => [addAskForInformationAnsweredBlockAfterOption],
    validator: ({
        definition,
        communicationIntegrationsService,
        group,
        syncConfigCacheManager,
        workflowVersionInfoRetriever,
        formInfoRetriever,
        projectManager,
    }) => {
        const validationObject: LogicBlockValidationObject = {};

        // Checking whether the user selected to send the form via dialog but doesn't have connected communication integration
        if (definition.formType === 'DIALOG' && !communicationIntegrationsService.anyIntegrationsConnected()) {
            validationObject.formTypeError = `No communication data source is connected. Please connect ${communicationIntegrationsService
                .getIntegrationTypesShortNames()
                .join('\\')}.`;
        }

        botQuestionAndSendNotificationValidator(
            validationObject,
            definition,
            workflowVersionInfoRetriever,
            syncConfigCacheManager,
            group,
            projectManager,
        );

        const form = formInfoRetriever.getWorkerFormFromCache(group.draftWorkflowVersionId, definition.formId);
        formQuestionTypeMustMatchFormType(validationObject, definition, form);
        validateFormBeforePublish(validationObject, form);

        // Check if no field was selected to ask
        if (!utils.anyMatch(definition.fields, (field: any) => field.isEditable)) {
            validationObject.noFieldChosen = 'Please select a field';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default AskFieldUpdateLogicBlockConfig;
