import { lazyReactToAngular } from '@tonkean/angular-components';

export default angular.module('tonkean.app').component(
    'tnkProjectIntegrationsView',
    lazyReactToAngular(
        () => import('../../../modules/ProjectIntegrationsViewModule/ProjectIntegrationsView'),
        [
            'viewType',
            'filterByText',
            'onClickConnectedDataSource',
            'onClickDisconnectedDataSource',
            'isListFilterBySolutionAccess',
            'filterIntegrationsWithNoEntities',
            'shouldBlockOnClickOfInaccessible',
        ],
    ),
);
