import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import useGetMatchedEntityWidgetIcon from './useGetMatchedEntityWidgetIcon';

import { NO_ICON, TonkeanIconDisplay } from '@tonkean/icon-picker';
import { Breakpoint } from '@tonkean/infrastructure';
import type { Icon, WidgetBase } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

// component that receives an icon and display the correct icon from the system accordingly

const Wrapper = styled.div<{ displayNoIcon: boolean; iconColor: string | undefined }>`
    margin-right: ${({ displayNoIcon }) => (displayNoIcon ? '8px' : '0')};
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        margin-right: 4px;
    }
    ${({ iconColor }) =>
        iconColor
            ? css`
                  ${colorSvg(iconColor)}
                  color: ${iconColor};
              `
            : css`
                  ${colorSvg(Theme.colors.gray_700)}
                  color: ${Theme.colors.gray_700};
              `}
`;

interface Props {
    icon?: Icon;
    widget: WidgetBase;
    size?: number;
    displayNoIcon?: boolean;
    // If this is widget header and no icon was chosen in the icon picker - nothing will be displayed
    // Else - icon of type 'no icon' will be displayed
    isWidgetHeader?: boolean;
}

const ItemInterfaceWidgetIcon: React.FC<Props> = ({
    icon,
    size,
    widget,
    isWidgetHeader = false,
    displayNoIcon = true,
}) => {
    const { widgetHasASingleIcon, integrationTypesForIcon, customTriggerTypes } = useGetMatchedEntityWidgetIcon(
        widget,
        widget.entityVersionId,
    );

    const showMatchedEntityIcon = useMemo(() => {
        return widgetHasASingleIcon && integrationTypesForIcon && integrationTypesForIcon.integrationTypes?.length > 0;
    }, [widgetHasASingleIcon, integrationTypesForIcon]);

    const dontShowEmptyIcon = isWidgetHeader;
    if ((!icon || icon?.name === NO_ICON) && dontShowEmptyIcon) {
        return <></>;
    }

    return (
        <Wrapper data-automation={`icon-${icon?.name}`} displayNoIcon={displayNoIcon} iconColor={icon?.iconColor}>
            <TonkeanIconDisplay
                icon={icon}
                size={size}
                dontShowEmptyIcon={dontShowEmptyIcon}
                integrationTypesForIcon={integrationTypesForIcon}
                showMatchedEntityIcon={showMatchedEntityIcon}
                customTriggerTypes={customTriggerTypes}
            />
        </Wrapper>
    );
};

export default ItemInterfaceWidgetIcon;
