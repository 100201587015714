import React from 'react';

import WidgetRequiredIndicationConfiguration from '../../../../components/WidgetRequiredIndicationConfiguration';
import ItemsWidgetMinMaxConfiguration from '../ItemsWidgetMinMaxConfiguration';

interface MinMaxRequirementsProps {
    minimumItemsRequired: number;
    minMaxRequirementsEntityName: string | undefined;
}
const MinMaxRequirements: React.FC<MinMaxRequirementsProps> = ({
    minMaxRequirementsEntityName,
    minimumItemsRequired,
}) => {
    return (
        <>
            <ItemsWidgetMinMaxConfiguration minMaxRequirementsEntityName={minMaxRequirementsEntityName} />
            {minimumItemsRequired > 0 && (
                <WidgetRequiredIndicationConfiguration name="configuration.requiredIndicatorType" />
            )}
        </>
    );
};

export default MinMaxRequirements;
