import ItemInterfaceIncludeInterfaceWidget from './components/ItemInterfaceIncludeInterfaceWidget';
import ItemInterfaceIncludeInterfaceWidgetEditor from './components/ItemInterfaceIncludeInterfaceWidgetEditor';
import type { IncludeInterfaceWidgetConfiguration } from './IncludeInterfaceWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

export const ItemInterfaceIncludeInterfaceWidgetPackage: ItemInterfaceWidgetPackage<IncludeInterfaceWidgetConfiguration> =
    {
        displayComponent: ItemInterfaceIncludeInterfaceWidget,
        configurationComponent: ItemInterfaceIncludeInterfaceWidgetEditor,
    };
