import template from './tnkBotHistory.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkBotHistory', {
    bindings: {
        isVisible: '<',
        filter: '<',
    },
    template,
    controller: 'BotHistoryCtrl',
});
