import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useTDLPollInitiative } from '@tonkean/tonkean-data-layer';
import type { Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const usePollInitiativeData = (
    projectId: TonkeanId<TonkeanType.PROJECT> | undefined,
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE> | undefined,
) => {
    const autoReloadInitiativeIntervalMS =
        useFeatureFlag<number>('tonkean_feature_initiative_default_polling_interval_in_interface_context') ?? 10_000;

    const [pollingEnabled, setPollingEnabled] = useState(true);

    const [polledInitiativeData, setPolledInitiativeData] = useState<Initiative | undefined>();

    const {
        data: polledInitiativeFromServer,
        invalidateData: refetchInitiative,
        isLoading: isLoadingInitiative,
        isPending: isPendingLoadingInitiative,
        isError: isErrorLoadingInitiative,
        errorStatusCode: loadingInitiaitveErrorStatusCode,
    } = useTDLPollInitiative({
        projectId,
        initiativeId,
        lastPolledInitiativeUpdateTime: polledInitiativeData?.id === initiativeId ? polledInitiativeData?.updated : 0,
        pollingInterval: autoReloadInitiativeIntervalMS,
        pollingEnabled,
    });

    const pollUpdatedInitiativeFromServer = useCallback(
        (updatedInitiative?: Initiative | undefined) => {
            if (updatedInitiative) {
                setPolledInitiativeData({
                    ...polledInitiativeData,
                    ...updatedInitiative,
                });
            }

            refetchInitiative();
        },
        [refetchInitiative, polledInitiativeData],
    );

    useEffect(() => {
        // deep equals check

        const hasValidResponseFromServer = !!polledInitiativeFromServer;

        if (!hasValidResponseFromServer) {
            return;
        }

        const cachedDataIsNotForCurrentInitiative = polledInitiativeData?.id !== polledInitiativeFromServer.id;

        if (cachedDataIsNotForCurrentInitiative) {
            setPolledInitiativeData(polledInitiativeFromServer);
            return;
        }

        const dataFromServerShouldOverrideCachedData =
            (!polledInitiativeData || polledInitiativeData.updated < polledInitiativeFromServer.updated) &&
            !isEqual(polledInitiativeData, polledInitiativeFromServer);

        if (dataFromServerShouldOverrideCachedData) {
            setPolledInitiativeData(polledInitiativeFromServer);
            return;
        }
    }, [polledInitiativeData, polledInitiativeFromServer]);

    return useMemo(() => {
        return {
            polledInitiative: polledInitiativeData,
            polledInitiativeUpdateTime: polledInitiativeData?.updated,
            error: isErrorLoadingInitiative,
            errorStatusCode: loadingInitiaitveErrorStatusCode,
            loading: isLoadingInitiative,
            pending: !!initiativeId && isPendingLoadingInitiative,
            reloadInitiativeFromServer: pollUpdatedInitiativeFromServer,
            applyFastReloadInterval: () => {},
            setPollInitiativeEnabled: setPollingEnabled,
        };
    }, [
        initiativeId,
        isErrorLoadingInitiative,
        isLoadingInitiative,
        isPendingLoadingInitiative,
        loadingInitiaitveErrorStatusCode,
        pollUpdatedInitiativeFromServer,
        polledInitiativeData,
    ]);
};

export default usePollInitiativeData;
