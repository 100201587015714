import template from './integrationExternalField.template.html?angularjs';

/**
 * Definition of an external integration field.
 */
export default angular.module('tonkean.app').component('tnkIntegrationExternalField', {
    bindings: {
        createMode: '<',
        duplicateMode: '<',
        projectIntegration: '<',
        groupId: '<',
        workflowVersionId: '@',
        targetType: '<',
        idOnlyMode: '<',

        existingFieldDefinition: '<',

        fieldDefinitionName: '<',
        fieldDefinitionNameEdited: '<',

        selectedEntity: '<',

        onDefinitionChange: '&',
        onFieldDefinitionNameChange: '&',
        onChange: '&', // occur once ranges are changed.
    },
    template,
    controller: 'IntegrationExternalFieldCtrl',
});
