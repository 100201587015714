import { lateConstructController } from '@tonkean/angular-components';
import { innerItemLogicBlockConfigs } from '@tonkean/logic-block-configs';
import { getFirstCustomTriggerAction, getDefaultTonkeanQuery } from '@tonkean/tonkean-utils';

/* @ngInject */
function MonitorTracksLogicConfigurationCtrl(
    $scope,
    $q,
    projectManager,
    customFieldsManager,
    consts,
    utils,
    customTriggerManager,
    workflowVersionManager,
    syncConfigCacheManager,
    formManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.cfm = customFieldsManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    let latestSavedQueryDefinition = null;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        control: ctrl.control,

        selectedContextOption: null,
        // If we selected to run on action items, holds whether we should run on ALL action items or ANY of the action items.
        selectedActionQuantifierOption: null,
        selectedMonitorItemsConditionType: null,
        apiNameToContextOptionMap: null,
        apiNameToActionQuantifierOptionMap: null,
        parentCustomTriggerId: null,

        selectedRunOnContext: 'TRIGGERED_ACTION_ITEM',
        selectedRunOnContextParent: null,
        allowSelectOnWhichItemToContinueTheFlow: false,
        parentItemOptions: [
            {
                type: 'ORIGINAL_ITEM',
                displayName: 'Root monitored item',
            },
        ],

        contextOptions: [
            {
                id: 'original',
                apiName: 'ORIGINAL_ITEM',
                label: 'The original Item (Opportunity)',
            },
            {
                id: 'actionItems',
                apiName: 'ACTION_ITEM',
                label: 'The actions items from previous step',
            },
        ],
        actionQuantifierOptions: [
            {
                id: 'any',
                apiName: 'ANY_ACTION_ITEMS',
                label: 'Any',
            },
            {
                id: 'all',
                apiName: 'ALL_ACTION_ITEMS',
                label: 'All',
            },
            {
                id: 'all_active',
                apiName: 'ALL_ACTIVE_ACTION_ITEMS',
                label: 'All (not archived)',
            },
        ],
        monitorItemsConditionTypes: {
            IMMEDIATELY: 'IMMEDIATELY',
            ON_CONDITION: 'ON_CONDITION',
        },
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            $scope.data.apiNameToContextOptionMap = utils.createMapFromArray($scope.data.contextOptions, 'apiName');
            $scope.data.apiNameToActionQuantifierOptionMap = utils.createMapFromArray(
                $scope.data.actionQuantifierOptions,
                'apiName',
            );

            $scope.data.selectedContextOption = $scope.data.apiNameToContextOptionMap['ACTION_ITEM'];
            $scope.data.selectedActionQuantifierOption =
                $scope.data.apiNameToActionQuantifierOptionMap['ANY_ACTION_ITEMS'];
            $scope.data.selectedMonitorItemsConditionType = 'ON_CONDITION';

            $scope.data.selectedRunOnContextParent = $scope.data.parentItemOptions[0];

            // Creating parent options.
            const actionItemsCreatingLogics =
                customTriggerManager.getRelevantActionItemsCreatingParentLogicsWorkflowVersion(
                    $scope.data.workflowVersionId,
                    $scope.data.configuredLogic.node.id,
                );
            // Note the direct parent of this monitor is not returned. The function only returns parents with child monitors (we are not included).
            for (const actionItemsCreatingLogic of actionItemsCreatingLogics) {
                $scope.data.parentItemOptions.push({
                    type: 'CREATED_BY_LOGIC',
                    displayName: `Action item of ${actionItemsCreatingLogic.node.displayName}`,
                    id: actionItemsCreatingLogic.node.id,
                });
            }

            $scope.data.parentCustomTrigger = customTriggerManager.getDirectParentInWorkflowVersion(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node.id,
            );

            // If it's a send form answered who is our parent, then it means we are monitoring
            // the inner items that represent files uploaded to the answered form.
            // Therefore, our real parent, which is the creator of the inner items is the send form
            // logic block and not the send form answered.
            if ($scope.data.parentCustomTrigger?.node.customTriggerType === 'SEND_FORM_ANSWERED') {
                $scope.data.parentCustomTrigger = customTriggerManager.getDirectParentInWorkflowVersion(
                    $scope.data.workflowVersionId,
                    $scope.data.parentCustomTrigger.node.id,
                );
            }

            $scope.data.parentCustomTriggerId = $scope.data.parentCustomTrigger.node.id;

            $scope.data.allowSelectOnWhichItemToContinueTheFlow =
                projectManager.project.features.tonkean_allow_inner_items_old_flow ||
                !innerItemLogicBlockConfigs.includes($scope.data.parentCustomTrigger?.node?.customTriggerType);

            initializeEditMode();
            $scope.onActionsChanged(false);
        }
    };

    /**
     * Occurs once a context option is selected.
     */
    $scope.onContextOptionSelected = function (contextOption) {
        $scope.data.selectedContextOption = contextOption;
        $scope.onActionsChanged(true);
    };

    $scope.onMonitorItemTypeChanged = function (selectedType) {
        if (selectedType.secondaryType === 'MONITOR_TRACKS_ITEM_CREATED') {
            return $scope.onMonitorItemsConditionTypeSelected($scope.data.monitorItemsConditionTypes.IMMEDIATELY);
        } else if (selectedType.secondaryType === 'MONITOR_TRACKS_MATCH_CONDITIONS') {
            return $scope.onMonitorItemsConditionTypeSelected($scope.data.monitorItemsConditionTypes.ON_CONDITION);
        }
    };

    /**
     * Occurs once a monitor item condition type is selected.
     */
    $scope.onMonitorItemsConditionTypeSelected = function (selectedMonitorItemsConditionType) {
        $scope.data.selectedMonitorItemsConditionType = selectedMonitorItemsConditionType;

        if ($scope.data.configuredLogic && $scope.data.configuredLogic.node) {
            if (selectedMonitorItemsConditionType === $scope.data.monitorItemsConditionTypes.IMMEDIATELY) {
                // If immediately is selected, we save the current query definition in a side object BUT clear it from the logic.
                // Save locally.
                latestSavedQueryDefinition = angular.copy($scope.data.configuredLogic.node.queryDefinition);

                // Clear on logic.
                if ($scope.data.control && $scope.data.control.clearFiltersAndQueries) {
                    $scope.data.control.clearFiltersAndQueries(true);
                }

                $scope.data.configuredLogic.node.queryDefinition = {
                    query: getDefaultTonkeanQuery(),
                };
                $scope.customTriggerSecondaryType = 'MONITOR_TRACKS_ITEM_CREATED';
            } else if (selectedMonitorItemsConditionType === $scope.data.monitorItemsConditionTypes.ON_CONDITION) {
                // If immediately is not selected, we try to retrieve the last query we know about.
                if (latestSavedQueryDefinition) {
                    $scope.data.configuredLogic.node.queryDefinition = angular.copy(latestSavedQueryDefinition);
                }
                // Clear the latest saved query definition.
                latestSavedQueryDefinition = null;

                $scope.customTriggerSecondaryType = 'MONITOR_TRACKS_MATCH_CONDITIONS';
            }
        }

        return $scope.onActionsChanged(true);
    };

    /**
     * Occurs once an action quantifier option is selected.
     */
    $scope.onActionQuantifierOptionSelected = function (actionQuantifierOption) {
        $scope.data.selectedActionQuantifierOption = actionQuantifierOption;
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs once a run on context selection is made.
     */
    $scope.onRunOnContextSelected = function (runOnContextSelection) {
        $scope.data.selectedRunOnContext = runOnContextSelection;
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs once a parent has been selected.
     */
    $scope.onRunOnContextParentSelect = function (selectedRunOnContextParent) {
        $scope.data.selectedRunOnContextParent = selectedRunOnContextParent;
        $scope.onActionsChanged(true);
    };

    $scope.getWorkingOnContext = function () {
        return (
            customTriggerManager.getWorkflowVersionFirstMonitorParentData(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node,
            )?.monitorActionDefinition?.workerItemContextType ??
            $scope.data.configuredLogic.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.workerItemContextType
        );
    };

    /**
     * Occurs once action definition is changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        // If the quantifier changes to 'ALL' quantifier we set the selected run context to PARENT_ITEM as it is
        // the only option we have for this quantifier
        if (
            $scope.data.selectedActionQuantifierOption.id === 'all' ||
            $scope.data.selectedActionQuantifierOption.id === 'all_active'
        ) {
            $scope.data.selectedRunOnContext = 'PARENT_ITEM';
        }

        if (ctrl.onActionsChanged) {
            const isParentSendFormOrFormAnsweredLogic =
                $scope.data.parentCustomTrigger.node.customTriggerType === consts.getLogicBlockTypes().SEND_FORM.type ||
                $scope.data.parentCustomTrigger.node.customTriggerType ===
                    consts.getLogicBlockTypes().SEND_FORM_ANSWERED.type;

            let formPromise = $q.resolve();
            if (
                isParentSendFormOrFormAnsweredLogic &&
                $scope.data.parentCustomTrigger.node.customTriggerActions?.length
            ) {
                const formId =
                    $scope.data.parentCustomTrigger.node.customTriggerActions[0].customTriggerActionDefinition?.formId;

                formPromise = formManager.getWorkerForm($scope.data.workflowVersionId, formId);
            }

            return formPromise.then((form) => {
                // If we have a send form custom trigger parent it means we are monitoring tabular form items.
                // For that specific case we want to run ONLY on the answers of the form that is why we run on the parent level 2.
                const runOnParentLevel =
                    isParentSendFormOrFormAnsweredLogic && form && form.formQuestionType === 'COLLECT_INNER_ITEMS'
                        ? 2
                        : null;

                const actionDefinition = {
                    actions: [
                        {
                            type: 'MONITOR_TRACKS',
                            definition: {
                                monitorContextType: $scope.data.selectedContextOption
                                    ? $scope.data.selectedContextOption.apiName
                                    : null,
                                monitorActionItemQuantifierType: $scope.data.selectedActionQuantifierOption
                                    ? $scope.data.selectedActionQuantifierOption.apiName
                                    : null,
                                monitorItemsConditionType: $scope.data.selectedMonitorItemsConditionType,
                                workerItemContextType: $scope.data.selectedRunOnContext,
                                workerItemContextTypeParentLogicId: $scope.data.selectedRunOnContextParent
                                    ? $scope.data.selectedRunOnContextParent.id
                                    : null,
                                workerItemContextTypeParentLogicName: $scope.data.selectedRunOnContextParent
                                    ? $scope.data.selectedRunOnContextParent.displayName
                                    : null,
                                parentCustomTriggerId: $scope.data.parentCustomTriggerId,
                                runOnParentLevel,
                            },
                        },
                    ],
                    customTriggerSecondaryType:
                        $scope.customTriggerSecondaryType ||
                        $scope.data.configuredLogic.node.customTriggerSecondaryType,
                };

                // Enrich logic with child to create if conditions are met
                if (
                    !$scope.data.configuredLogic?.impacts?.length > 0 &&
                    !projectManager.project.features.tonkean_allow_inner_items_old_flow &&
                    innerItemLogicBlockConfigs.includes($scope.data.parentCustomTrigger?.node?.customTriggerType)
                ) {
                    const workingOn = $scope.getWorkingOnContext();
                    if (workingOn === 'TRIGGERED_ACTION_ITEM') {
                        const logicBlockTypes = consts.getLogicBlockTypes();

                        if (actionDefinition.childrenCustomTriggersToCreate) {
                            actionDefinition.childrenCustomTriggersToCreate.push({
                                displayName: 'Send to another module',
                                type: logicBlockTypes.TONKEAN_ACTION.type,
                            });
                        } else {
                            const childrenCustomTriggersToCreate = [];
                            childrenCustomTriggersToCreate.push({
                                displayName: 'Send to another module',
                                type: logicBlockTypes.TONKEAN_ACTION.type,
                            });
                            actionDefinition.childrenCustomTriggersToCreate = childrenCustomTriggersToCreate;
                        }
                        shouldSaveLogic = true;
                    }
                }

                return ctrl.onActionsChanged({
                    definition: actionDefinition,
                    shouldSaveLogic,
                });
            });
        }
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'MONITOR_TRACKS',
        );
        if (customTriggerAction) {
            const definition = customTriggerAction.customTriggerActionDefinition;

            $scope.data.selectedContextOption = $scope.data.apiNameToContextOptionMap[definition.monitorContextType];
            $scope.data.selectedActionQuantifierOption =
                $scope.data.apiNameToActionQuantifierOptionMap[definition.monitorActionItemQuantifierType];
            $scope.data.selectedMonitorItemsConditionType = definition.monitorItemsConditionType;
            $scope.data.selectedRunOnContext = definition.workerItemContextType;
            if (definition.workerItemContextTypeParentLogicId) {
                const selectedOption = utils.findFirst(
                    $scope.data.parentItemOptions,
                    (option) => option.id && option.id === definition.workerItemContextTypeParentLogicId,
                );

                if (selectedOption) {
                    $scope.data.selectedRunOnContextParent = selectedOption;
                } else {
                    $scope.data.runOnContextParentError = `Could not find logic ${definition.workerItemContextTypeParentLogicName}`;
                }
            }
        } else {
            // Unlike other logics, the monitor tracks logic has default configuration and should immediately have an
            // action once created. If we get here and we don't have an action, it's the first time we're here and we
            // should create an action with the default settings. onActionsChanged with true will create an action.
            $scope.onActionsChanged(true);
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('MonitorTracksLogicConfigurationCtrl', lateConstructController(MonitorTracksLogicConfigurationCtrl));
