import React, { useCallback } from 'react';

import useProjectIntegrationEntityBreadCrumbsConfiguration from '../hooks/useProjectIntegrationEntityBreadCrumbsConfiguration';

import { Breadcrumbs } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntitySummaryWithIsImported } from '@tonkean/tonkean-entities';

interface Props {
    projectIntegration: ProjectIntegration;
    entity: ProjectIntegrationEntity;
    entitiesSummary: ProjectIntegrationEntitySummaryWithIsImported[];
    onExit?: () => Promise<boolean>;
}

const ProjectIntegrationEntityHeaderBreadCrumbs: React.FC<Props> = ({
    projectIntegration,
    entity,
    entitiesSummary,
    onExit: onExitProp,
}) => {
    // Alert about unsaved changes.
    const onExit = useCallback(
        async (destinationEntityId: string) => {
            if (destinationEntityId !== entity?.id && onExitProp) {
                return await onExitProp();
            }
            return true;
        },
        [entity?.id, onExitProp],
    );

    const configuration = useProjectIntegrationEntityBreadCrumbsConfiguration(
        projectIntegration,
        entity,
        entitiesSummary,
        onExit,
    );
    return <Breadcrumbs settings={configuration} />;
};

export default ProjectIntegrationEntityHeaderBreadCrumbs;
