import template from './tnkGatherUpdateLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkGatherUpdateLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        invalidLogics: '<',
        onActionsChanged: '&',
    },
    template,
    controller: 'GatherUpdateLogicConfigurationCtrl',
});
