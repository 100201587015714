<div class="worker-form-editor">
    <!-- Modal title -->
    <h1 ng-if="!data.isInFormBuilder" class="common-size-l common-bold flex-vmiddle">
        <span ng-if="data.form.formType === 'UPDATE'">Update Form</span>
        <span ng-if="data.form.formType !== 'UPDATE'">Create Form</span>
    </h1>

    <!-- Update form explanation -->
    <div
        ng-if="data.form.formType === 'UPDATE' && !data.isInFormBuilder"
        class="common-color-grey common-size-xxs margin-bottom-md"
    >
        Update forms can be sent using the send form action.
    </div>

    <!-- Create form explanation -->
    <div
        ng-if="data.form.formType !== 'UPDATE' && !data.isInFormBuilder"
        class="common-color-grey common-size-xxs margin-bottom-md"
    >
        Create forms can be filled from the /tonk slack command or the business report.
    </div>

    <hr ng-if="!data.isInFormBuilder" />

    <!-- Big initial loading -->
    <div ng-if="data.loadingForm" class="text-center">
        <div class="loading-large"></div>
    </div>

    <div ng-if="!data.loadingForm">
        <!-- Display Name -->
        <div class="margin-bottom-xs" ng-if="!data.isInFormBuilder">
            <div class="common-title-xxs margin-bottom-xs">Form Name</div>

            <span
                ng-if="data.form.displayName.length / data.formDisplayNameMaxLength >= 0.9"
                class="common-size-xxxxs common-color-grey3"
            >
                ({{ data.form.displayName.length }}/{{ data.formDisplayNameMaxLength }})
            </span>

            <input
                ng-model="data.form.displayName"
                ng-blur="onFormDisplayNameChanged()"
                class="form-control"
                id="form-control-form-name"
                placeholder="Name..."
                maxlength="{{ data.formDisplayNameMaxLength }}"
            />

            <div ng-if="data.errorDisplayNameRequired" class="common-error margin-top-xxs common-size-xxs">
                Display name is required
            </div>

            <!-- error due to duplicate display name -->
            <div class="common-error margin-top-xxs common-size-xxs" ng-if="data.duplicateDisplayNameError">
                {{ data.duplicateDisplayNameError }}
            </div>
        </div>

        <!-- Description -->
        <div class="margin-bottom-xs margin-top-md margin-bottom-md" ng-if="!data.isInFormBuilder">
            <div class="common-title-xxs margin-bottom-xs">Form Description</div>

            <!-- Input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                element-id="form-control-form-description"
                data-automation="worker-form-editor-form-description"
                placeholder="Description..."
                saved-expression="data.form.descriptionExpression"
                global-expression-only="data.form.formType === 'CREATE'"
                on-tonkean-expression-changed="onFormDescriptionExpressionChanged(expression)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Slack Command Configuration -->
        <div ng-if="data.form.formType !== 'UPDATE' && !data.isInFormBuilder" class="margin-bottom-xs">
            <div class="common-title-xxs">
                <span class="margin-right-xs">Form Slack Command Name</span>
                <span
                    ng-if="data.form.slackCommand.length / data.slackCommandMaxLength >= 0.9"
                    class="common-size-xxxxs common-color-grey3"
                >
                    ({{ data.form.slackCommand.length }}/{{ data.slackCommandMaxLength }})
                </span>
                <span class="common-size-xxxs">/tonk {{ data.form.slackCommand || '{name}' }}</span>
            </div>

            <p class="common-size-xxs common-color-grey3">
                <span>Optional: define a Slack command name that will open the form within Slack.</span>
            </p>

            <input
                ng-model="data.form.slackCommand"
                class="form-control"
                maxlength="{{ data.slackCommandMaxLength }}"
            />

            <!-- error due to duplicate slack command -->
            <div class="common-error margin-top-xxs common-size-xxs" ng-if="data.duplicateSlackCommandError">
                {{ data.duplicateSlackCommandError }}
            </div>
        </div>

        <!-- Add As Inner Item Of Configuration -->
        <div ng-if="data.form.formType !== 'UPDATE' && !data.isInFormBuilder" class="margin-bottom-md">
            <!-- Add As Inner Item Of Title -->
            <div class="common-title-xxs margin-bottom-xs">
                <span class="margin-right-xs">
                    New track location
                    <a
                        ng-if="!data.form.definition.addUnderInitiativeId && !data.hideInnerItemOf"
                        class="common-size-xxs margin-left-xs"
                        ng-click="data.hideInnerItemOf = !data.hideInnerItemOf"
                    >
                        Edit
                    </a>
                </span>
            </div>

            <p class="common-size-xxs common-color-grey3">
                <span>Optional: select under which track the newly created track will be stored.</span>
            </p>

            <!-- Choose existing track -->
            <div
                ng-if="data.hideInnerItemOf || data.form.definition.addUnderInitiativeId"
                class="search-autocomplete-hide-link-tags margin-bottom"
            >
                <tnk-search-initiatives
                    group-id="data.groupId"
                    on-initiative-selected="onInitiativeSelected(selectedSimplifiedInitiative)"
                    selected-initiative-id="data.form.definition.addUnderInitiativeId"
                    can-clear="true"
                ></tnk-search-initiatives>
            </div>
        </div>

        <!-- Choose between UPDATE_FIELDS and COLLECT_INNER_ITEMS form type -->
        <div ng-if="data.form.formType === 'UPDATE' && !data.isInFormBuilder">
            <!-- Type Title Label -->
            <div class="common-title-xxs margin-top-md">Form Type</div>

            <!-- Toggle Form Types -->
            <div class="flex-vmiddle margin-bottom-xs">
                <tnk-radio-button
                    radio-id="update-form-update-fields-label"
                    model="data.form.formQuestionType"
                    radio-name="update-fields"
                    radio-label="Update Fields"
                    radio-value="UPDATE_FIELDS"
                    is-required="true"
                    on-click="selectFormType(onClickParam)"
                    on-click-param="('UPDATE_FIELDS')"
                    classes="margin-top margin-right-md common-normal-weight"
                ></tnk-radio-button>
                <tnk-radio-button
                    radio-id="update-form-collect-inner-items-label"
                    model="data.form.formQuestionType"
                    radio-name="collect-inner-items"
                    radio-label="Collect Inner Items"
                    is-required="true"
                    radio-value="COLLECT_INNER_ITEMS"
                    on-click="selectFormType(onClickParam)"
                    on-click-param="('COLLECT_INNER_ITEMS')"
                    classes="margin-top margin-right-md common-normal-weight"
                ></tnk-radio-button>
            </div>
        </div>

        <!-- Collect Inner Items Form -->
        <div ng-if="data.form.formQuestionType === 'COLLECT_INNER_ITEMS'">
            <!-- Explanation -->
            <p class="common-size-xxs common-color-grey3">
                <span>Each form will contain the following question.</span>
                <br />
                <span>The user would be asked to provide multiple answers in a table-like view.</span>
                <br />
                <span>
                    Each of the answers will be presented as an inner-item, define what fields would be available for
                    the user to fill for each item.
                </span>
            </p>

            <!-- Question -->
            <div class="margin-bottom-xs">
                <input
                    ng-model="data.form.definition.formQuestion"
                    ng-change="onFormQuestionChanged()"
                    class="form-control"
                    data-automation="worker-form-editor-form-question-input"
                    placeholder="Enter your question here..."
                    maxlength="500"
                />
            </div>

            <!-- Fields List -->
            <div class="margin-bottom-md">
                <!-- No Title field -->
                <div
                    class="alert alert-warning margin-top-lg"
                    data-automation="worker-form-editor-template-no-title-field-in-create-form"
                    ng-if="
                        data.form.formQuestionType === 'COLLECT_INNER_ITEMS' &&
                        (data.formSpecialFieldsWrappers | filter: { fieldDefinition: { id: 'TNK_TITLE' } }).length === 0
                    "
                >
                    Title is not selected, it will be automatically generated with the format:
                    <br />
                    "Created by {Name of user} using '{Form name}' ({Created At (Timezone of user)})"
                    <br />
                    <br />
                    * Note: user refers to the person who filled the form.
                </div>
                <!-- Special Fields -->
                <tnk-form-fields-select
                    fixed-fields="data.fixedFields"
                    title-label="'Fill In Basic Fields'"
                    add-button-label="'Add Basic Field'"
                    fields="data.formSpecialFieldsWrappers"
                    group-id="data.groupId"
                    form-question-type="data.form.formQuestionType"
                    on-add="onFieldAdd(data.formSpecialFieldsWrappers)(field)"
                    on-remove="onFieldRemove(data.formSpecialFieldsWrappers)(field)"
                    on-edit="onFieldChange(data.formSpecialFieldsWrappers)(field)"
                    on-replace="onFormFieldReplace(data.formSpecialFieldsWrappers)(oldField, newFieldDefinition)"
                    is-draggable="false"
                    filter-fields-options="editableSpecialFields"
                    sort-fields="sortSpecialFields(fields)"
                    is-alias-enabled="true"
                    sort-options-by="specialFieldsComparator(first, second)"
                    workflow-version-id="data.workflowVersionId"
                    form-type="data.form.formType"
                ></tnk-form-fields-select>

                <!-- Not Special Fields -->
                <tnk-form-fields-select
                    class="margin-top"
                    title-label="'Fill In Custom Fields'"
                    add-button-label="'Add Custom Field'"
                    form-question-type="data.form.formQuestionType"
                    fields="data.formCustomFieldsWrappers"
                    group-id="data.groupId"
                    on-add="onFieldAdd(data.formCustomFieldsWrappers)(field)"
                    on-remove="onFieldRemove(data.formCustomFieldsWrappers)(field)"
                    on-edit="onFieldChange(data.formCustomFieldsWrappers)(field)"
                    on-move="onFormFieldMoved(data.formCustomFieldsWrappers)(oldIndex, newIndex)"
                    on-replace="onFormFieldReplace(data.formCustomFieldsWrappers)(oldField, newFieldDefinition)"
                    is-draggable="true"
                    filter-fields-options="noSpecialFields"
                    is-alias-enabled="true"
                    workflow-version-id="data.workflowVersionId"
                    form-type="data.form.formType"
                ></tnk-form-fields-select>
            </div>
        </div>

        <!-- Update Fields Form -->
        <div
            ng-if="
                !data.form.formQuestionType ||
                data.form.formQuestionType === 'UPDATE_FIELDS' ||
                data.form.formType === 'CREATE'
            "
        >
            <!-- Explanation -->
            <p class="common-size-xxs common-color-grey3">
                <span>Each form will contain the following questions, sorted by the order you set.</span>
                <br />
                <span>Fill each question with a text, Otherwise, the field name will be used.</span>
            </p>

            <!-- Fields -->
            <tnk-form-fields-select
                class="margin-top"
                default-fields="data.defaultFields"
                form-question-type="data.form.formQuestionType"
                title-label="'Fill In Fields'"
                add-button-label="'Add Question'"
                fields="data.formFieldsWrappers"
                group-id="data.groupId"
                on-add="onFieldAdd(data.formFieldsWrappers)(field)"
                on-remove="onFieldRemove(data.formFieldsWrappers)(field)"
                on-edit="onFieldChange(data.formFieldsWrappers)(field)"
                on-move="onFormFieldMoved(data.formFieldsWrappers)(oldIndex, newIndex)"
                on-replace="onFormFieldReplace(data.formFieldsWrappers)(oldField, newFieldDefinition)"
                is-draggable="true"
                is-alias-enabled="true"
                is-inline-enabled="true"
                display-name-character-limit="48"
                filter-fields-options="noFileFormatOrMatchedEntityFields"
                workflow-version-id="data.workflowVersionId"
                form-type="data.form.formType"
            ></tnk-form-fields-select>

            <!-- No field selected error -->
            <div
                ng-if="data.errorZeroFieldsSelected"
                class="common-error margin-top-xxs common-size-xxs"
                data-automation="worker-form-editor-template-one-field-required-message"
            >
                At least one field is required
            </div>

            <!-- No Title field -->
            <div
                ng-if="
                    data.form.formType === 'CREATE' &&
                    (data.formFieldsWrappers | filter: { fieldDefinition: { id: 'TNK_TITLE' } }).length === 0
                "
                data-automation="worker-form-editor-template-no-title-field-in-create-form"
                class="alert alert-warning margin-top-lg"
            >
                Title is not selected, it will be automatically generated with the format:
                <br />
                "Created by {Name of user} using '{Form name}' ({Created At (Timezone of user)})"
                <br />
                <br />
                * Note: user refers to the person who filled the form.
            </div>
        </div>

        <!-- Open advanced settings button -->
        <div class="flex mod-justify-end" ng-if="!data.isInFormBuilder">
            <a
                class="btn btn-link flex-vmiddle"
                data-automation="worker-form-editor-open-advanced-settings"
                ng-click="data.advancedSettingsOpen = !data.advancedSettingsOpen"
            >
                <span class="margin-right-xs">Advanced Settings</span>
                <span class="dropdown-chevron mod-static"></span>
            </a>
        </div>

        <!-- Advanced settings -->
        <div ng-if="data.advancedSettingsOpen && !data.isInFormBuilder">
            <!-- Update form advanced settings -->
            <div ng-if="data.form.formType === 'UPDATE'">
                <!-- Form Hierarchy Selection -->
                <div class="margin-bottom-lg">
                    <div class="common-title-xxs">Form Available On</div>
                    <p class="common-size-xxs common-color-grey3">
                        <span>Choose which level of items will be available in the update form.</span>
                    </p>
                    <ui-select class="margin-right" ng-model="data.selectedItemsHierarchyFilter" theme="bootstrap">
                        <ui-select-match placeholder="Level">
                            {{ data.selectedItemsHierarchyFilter.displayName }}
                        </ui-select-match>
                        <ui-select-choices
                            repeat="itemsHierarchyFilterOption in data.itemsHierarchyFilterOptions | filter:$select.search "
                        >
                            <div
                                ng-bind-html="itemsHierarchyFilterOption.displayName | highlight: $select.search"
                            ></div>
                        </ui-select-choices>
                    </ui-select>

                    <!-- Info Message for "Only Inner Items" -->
                    <div
                        class="margin-top-xs common-color-grey"
                        ng-if="data.selectedItemsHierarchyFilter.apiName === 'INNER_ITEMS'"
                    >
                        <i class="fa fa-info-circle margin-right-xs"></i>
                        Inner items will be available only for item owners
                    </div>
                </div>

                <!-- Fill Form Custom Label -->
                <div class="margin-bottom-lg">
                    <div class="common-title-xxs">Default custom label for Fill Form Button</div>
                    <p class="common-size-xxs common-color-grey3">
                        <span>Define a default label for the button that opens the form to fill.</span>
                        <br />
                        <span>You can override it in each send form action.</span>
                    </p>
                    <input
                        class="form-control"
                        placeholder="'Fill Form'"
                        ng-model="data.form.definition.fillFormButtonLabel"
                    />
                </div>

                <!-- Submit Form Custom Label -->
                <div ng-if="data.form.formQuestionType === 'COLLECT_INNER_ITEMS'" class="margin-bottom-lg">
                    <span class="common-title-xxs">Add Item Label</span>

                    <p class="common-size-xxs common-color-grey3">
                        <span>Define a label for the add item input.</span>
                    </p>
                    <input
                        class="form-control"
                        placeholder="'+ Add Item'"
                        ng-model="data.form.definition.addItemButtonLabel"
                    />
                </div>
            </div>

            <div ng-if="data.form.formType === 'CREATE'">
                <!-- Custom Logo -->
                <div class="margin-bottom-xs margin-top-md margin-bottom-md">
                    <div class="common-title-xxs margin-bottom-xs">Customize Logo Url</div>

                    <input
                        ng-model="data.form.definition.logoUrl"
                        class="form-control"
                        id="form-logo-url"
                        placeholder="Logo url..."
                        ng-change="onFormLogoChanged()"
                    />

                    <div ng-if="data.errorLogoUrlStartsWithHttp" class="common-error margin-top-xxs common-size-xxs">
                        Url is not valid. Please make sure to use https.
                    </div>
                </div>
            </div>

            <!-- Submit Form Custom Label -->
            <div class="margin-bottom-lg">
                <span class="common-title-xxs">Submit Form Button Label</span>
                <span
                    class="common-size-xxxxs common-color-grey3"
                    ng-if="
                        data.form.definition.submitFormButtonLabel.length / data.slackSubmitFormButtonLabelMaxLength >=
                        0.6
                    "
                >
                    {{ data.form.definition.submitFormButtonLabel.length }}/{{
                        data.slackSubmitFormButtonLabelMaxLength
                    }}
                </span>

                <p class="common-size-xxs common-color-grey3">
                    <span>
                        Define a label for the form submission button. Note: slack dialogs support only one word.
                    </span>
                </p>
                <input
                    class="form-control"
                    placeholder="'Submit'"
                    ng-model="data.form.definition.submitFormButtonLabel"
                    maxlength="{{ data.slackSubmitFormButtonLabelMaxLength }}"
                />
            </div>

            <!-- Colors -->
            <div class="margin-bottom-xs margin-top-md margin-bottom-md">
                <div class="common-title-xxs margin-bottom-xs">Form Colors</div>

                <!-- Primary color -->
                <div class="flex-vmiddle">
                    <tnk-color-options-picker
                        selected-color="data.primaryColor"
                        color-options="data.primaryColorOptions"
                        on-color-selected="onPrimaryColorSelected(color)"
                    ></tnk-color-options-picker>
                    <div class="common-bold margin-left-xs">Primary</div>
                </div>

                <!-- Secondary color -->
                <div class="flex-vmiddle margin-top">
                    <tnk-color-options-picker
                        selected-color="data.secondaryColor"
                        color-options="data.secondaryColorOptions"
                        on-color-selected="onSecondaryColorSelected(color)"
                    ></tnk-color-options-picker>
                    <div class="common-bold margin-left-xs">Secondary</div>
                </div>
            </div>

            <!-- Common advanced settings -->
            <div class="margin-bottom-lg" ng-if="$root.features[pm.project.id].tonkean_feature_auto_submit_form">
                <div class="flex">
                    <div class="common-title-xxs margin-right">Auto Submit Form</div>
                    <tnk-toggle
                        is-active="data.autoSubmitForm"
                        size="sm"
                        on-toggle-click="onAutoSubmitToggled(isActive)"
                    ></tnk-toggle>
                </div>
                <p class="common-size-xxs common-color-grey3">
                    <span>Whether to auto submit the form when a new data is entered.</span>
                </p>
            </div>
        </div>
    </div>

    <!-- Submit form button -->
    <div class="flex-vmiddle margin-top-lg mod-justify-end" ng-if="!data.isInFormBuilder">
        <button ng-click="submitForm()" class="btn btn-primary" id="form-control-submit-form">
            Save
            <i class="loading-small margin-left-xs" ng-if="data.formIsUpdating"></i>
        </button>
    </div>
</div>
