import type { TElement } from '@udecode/plate';
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import ProcessBuilderAnalyzeInitialProcessDescription from './ProcessBuilderAnalyzeInitialProcessDescription';
import ProcessBuilderInitialInputStateLoading from './ProcessBuilderInitialInputStateLoader';
import ProcessBuilderProcessName from './ProcessBuilderNameEditor';
import ProcessBuilderPageProcessMapping from './ProcessBuilderPageProcessMapping';
import ProcessBuilderAnswerMissingQuestions from './ProcessBuilderQuestionsPopup';
import { ReactComponent as PDFIcon } from '../../../../images/icons/pdf.svg';
import useLazyFetch from '../../../infrastructure/hooks/useLazyFetch';
import { ProcessBuilderVersion } from '../entities/ProcessBuilderVersion';

import { analyticsWrapper } from '@tonkean/analytics';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { HTMLEditor } from '@tonkean/editor';
import { ErrorMessage, SimpleSelect, TextEllipsis, useDebouncer } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { ProcessBuilderMissingQuestion } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { streamUtils } from '@tonkean/utils';

const BreadcrumbsRow = styled.div`
    display: flex;
    align-items: center;
    padding: 12px;
    background: ${Theme.colors.basicBackground};
    border-bottom: 1px solid ${Theme.colors.gray_300};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
    line-height: 16px;
`;

const SolutionNameLink = styled(StateLink)`
    text-decoration-line: underline;
    color: ${Theme.colors.gray_500};
    max-width: 200px;

    &:active {
        outline: none;
    }
`;

const Separator = styled.span`
    color: ${Theme.colors.gray_500};
    margin: 0 10px;
`;

const CurrentProcessName = styled.div`
    max-width: 300px;
    color: ${Theme.colors.gray_800};
`;

const BreadcrumbsTitleLoading = styled.span`
    padding-top: 1px;
    margin-left: 3px;
`;

const ContentContainer = styled.div`
    height: 95%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 10px 40px 50px 40px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;

const StyledHtmlEditor = styled(HTMLEditor)`
    height: 100%;
    border-radius: 0;
    overflow: scroll;
`;

const VersionSelectionRow = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

const VersionToggle = styled(SimpleSelect)`
    width: 180px;
    height: 34px;
    margin-left: 16px;
    font-size: ${FontSize.XSMALL_10};
    font-weight: 400;
`;

const Topbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
    height: 88px;
    border-radius: 16px 16px 0 0;
    border: 1px solid #eaecf0;
    font-weight: bold;
`;

const StyledProcessBuilderProcessName = styled(ProcessBuilderProcessName)`
    width: 100%;
    flex-grow: 1;
`;

const ErrorText = styled(ErrorMessage)`
    margin-top: 5px;
`;

const StreamingTextAsDiv = styled.div`
    display: block;
    padding: 16px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border: 1px solid #eaecf0;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 1.2;
    color: ${Theme.colors.gray_800};
    overflow: scroll;
    height: 100%;
`;

const ExportToPDFButton = styled(IconButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    background: ${Theme.current.palette.colorPicker.HEX_FFFFFF};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 3px;
    color: ${Theme.colors.gray_800};
    width: 120px;
    height: 34px;
    font-size: ${FontSize.XSMALL_10};
    font-weight: 400;

    svg {
        height: 16px;
    }
`;

const ProcessBuilderPage: React.FC = () => {
    const { id: projectId } = useProject();
    const [currentVersion, setCurrentVersion] = useState<ProcessBuilderVersion>(ProcessBuilderVersion.ORIGINAL_VERSION);
    const [workflowFolderId] = useGetStateParams<[TonkeanId<TonkeanType.WORKFLOW_FOLDER>]>('workflowFolderId');

    const [doneStreaming, setDoneStreaming] = useState<boolean>(false);
    const utils = useAngularService('utils');

    const { data: response, loading: generatedTextLoading, error: generatedTextError, fetchData } = useLazyFetch();

    const emitToastMessage = useToastMessage();

    const {
        data: existingProcessBuilder,
        loading: loadingGetProcessBuilder,
        error: getProcessBuilderError,
    } = useTonkeanService('getProcessBuilderByWorkflowFolderId', workflowFolderId);

    const [{ loading: updateLoading, error: updateError }, updateProcessBuilder] =
        useLazyTonkeanService('updateProcessBuilder');

    const [initialProcessDescription, setInitialProcessDescription] = useState<TElement[]>([]);

    const [generatedText, setGeneratedText] = useState<TElement[] | undefined>();
    const [generatedTextStream, setGeneratedTextStream] = useState<string>('');
    const [title, setTitle] = useState<string | undefined>();

    /* isInitialLoad is using a ref so mutating it does not trigger a re-render. */
    const isInitialLoadRef = useRef<boolean>(true);

    const workflowFolderManager = useAngularService('workflowFolderManager');

    const workFlowFolderName =
        workflowFolderManager.projectIdToFolderIdToFolderMap[projectId]?.[workflowFolderId]?.displayName;

    useEffect(() => {
        if (!isInitialLoadRef.current || !existingProcessBuilder) {
            return;
        }

        if (!!existingProcessBuilder?.originalText) {
            setInitialProcessDescription(existingProcessBuilder.originalText);
        }
        if (!!existingProcessBuilder?.suggestedText) {
            setGeneratedText(existingProcessBuilder.suggestedText);
            setDoneStreaming(true);
        }
        setTitle(existingProcessBuilder.title);
    }, [existingProcessBuilder, initialProcessDescription]);

    const [questions, setQuestions] = useState<ProcessBuilderMissingQuestion[]>([]);
    const [questionsReceived, setQuestionsReceived] = useState<boolean>(false);

    const onQuestionsReceived = useCallback((questionsReceived) => {
        setQuestions(questionsReceived?.response.question_results);
        setQuestionsReceived(true);
        analyticsWrapper.track('Missing Questions Received', {
            category: 'Process Builder',
            label: questionsReceived?.response?.question_results?.length || 0,
        });
    }, []);

    const onQuestionsAnswerCompleted = useCallback(
        async (userReplies: string[]) => {
            setGeneratedText(utils.plainTextToRichText(''));
            setGeneratedTextStream('');
            setDoneStreaming(false);

            try {
                const extractedUserInput = utils.richTextToPlainText(initialProcessDescription);

                const body = {
                    userReplies,
                    processTitle: title || '',
                    initialUserProcessDescription: extractedUserInput,
                };

                await fetchData(`${projectId}/fullProcessAnswer`, 'POST', body);
            } catch {
                emitToastMessage('Error generating full process', 'danger');
            }
        },
        [emitToastMessage, fetchData, initialProcessDescription, projectId, title, utils],
    );

    const saveGeneratedText = useCallback(
        (finalRes: string) => {
            const generatedTextAsNode = utils.plainTextToRichText(finalRes);
            updateProcessBuilder(workflowFolderId, undefined, initialProcessDescription, generatedTextAsNode);
            setGeneratedText(generatedTextAsNode);
            setGeneratedTextStream('');
            setDoneStreaming(true);

            analyticsWrapper.track('Generated Text Received', {
                category: 'Process Builder',
                label: finalRes.length || 0,
            });
        },
        [initialProcessDescription, updateProcessBuilder, workflowFolderId, utils],
    );

    useEffect(() => {
        if (!response) {
            return;
        }

        streamUtils.fetchAndParse(response, setGeneratedTextStream, saveGeneratedText);
        isInitialLoadRef.current = false;
        setCurrentVersion(ProcessBuilderVersion.SUGGESTED_VERSION);
    }, [saveGeneratedText, response, setGeneratedTextStream]);

    const autoSaveToServer = useCallback(() => {
        if (loadingGetProcessBuilder || isInitialLoadRef.current || !existingProcessBuilder) {
            return;
        }

        if (currentVersion === ProcessBuilderVersion.ORIGINAL_VERSION) {
            updateProcessBuilder(workflowFolderId, undefined, initialProcessDescription, undefined);
        } else {
            updateProcessBuilder(workflowFolderId, undefined, undefined, generatedText);
        }
    }, [
        loadingGetProcessBuilder,
        existingProcessBuilder,
        currentVersion,
        updateProcessBuilder,
        workflowFolderId,
        initialProcessDescription,
        generatedText,
    ]);

    useDebouncer(autoSaveToServer, 500);

    return (
        <>
            <BreadcrumbsRow>
                <SolutionNameLink
                    state="product.solution"
                    params={{ solutionId: workflowFolderId }}
                    data-automation="solution-mappers-dropdown-solution-name"
                >
                    <TextEllipsis numberOfLines={1} tooltipLimitWidth={200} tooltip>
                        {workFlowFolderName}
                    </TextEllipsis>
                </SolutionNameLink>

                <Separator>/</Separator>

                {loadingGetProcessBuilder ? (
                    <BreadcrumbsTitleLoading>
                        <i className="loading-small" />
                    </BreadcrumbsTitleLoading>
                ) : (
                    <CurrentProcessName>{title}</CurrentProcessName>
                )}
            </BreadcrumbsRow>

            <ContentContainer>
                <Wrapper>
                    <Topbar>
                        <StyledProcessBuilderProcessName
                            workflowFolderId={workflowFolderId}
                            currentName={title}
                            loading={loadingGetProcessBuilder}
                            onChange={(current) => setTitle(current)}
                        />
                        {(!!generatedText || generatedTextStream.length > 0) && (
                            <VersionSelectionRow>
                                <VersionToggle
                                    value={currentVersion.valueOf()}
                                    options={[
                                        {
                                            value: ProcessBuilderVersion.SUGGESTED_VERSION,
                                            label: 'Suggested Version',
                                        },
                                        {
                                            value: ProcessBuilderVersion.ORIGINAL_VERSION,
                                            label: 'Original Version',
                                        },
                                    ]}
                                    onChange={(value: ProcessBuilderVersion) => {
                                        setCurrentVersion(ProcessBuilderVersion[value]);
                                    }}
                                />
                                <ExportToPDFButton
                                    onClick={() => utils.exportHtmlEditorContentToPdf(generatedText, title)}
                                    iconMargin={10}
                                    leftIcon={<PDFIcon />}
                                    iconColor={Theme.current.palette.colorPicker.HEX_FF5454}
                                    disabled={!generatedText?.length || !!generatedTextStream.length || !title?.length}
                                >
                                    Export to PDF
                                </ExportToPDFButton>
                            </VersionSelectionRow>
                        )}
                    </Topbar>
                    {loadingGetProcessBuilder ? (
                        <ProcessBuilderInitialInputStateLoading />
                    ) : (
                        <>
                            {currentVersion === ProcessBuilderVersion.ORIGINAL_VERSION && (
                                <StyledHtmlEditor
                                    placeholder="Describe your process:
• What are the goals of your process?
• What is your ideal process?
• What’s wrong with your current process?
"
                                    initialValue={initialProcessDescription}
                                    onChange={(html) => {
                                        setInitialProcessDescription(html);
                                        isInitialLoadRef.current = false;
                                    }}
                                />
                            )}

                            {currentVersion === ProcessBuilderVersion.SUGGESTED_VERSION && (
                                <>
                                    {doneStreaming ? (
                                        <StyledHtmlEditor
                                            placeholder=""
                                            initialValue={generatedText || []}
                                            onChange={(html) => {
                                                setGeneratedText(html);
                                                isInitialLoadRef.current = false;
                                            }}
                                        />
                                    ) : (
                                        <StreamingTextAsDiv>{generatedTextStream}</StreamingTextAsDiv>
                                    )}

                                    {generatedTextError && (
                                        <ErrorText>Error while trying to get suggested process </ErrorText>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {generatedText && currentVersion === ProcessBuilderVersion.SUGGESTED_VERSION && (
                        <ProcessBuilderPageProcessMapping
                            workflowFolderId={workflowFolderId}
                            projectId={projectId}
                            processText={generatedText}
                        />
                    )}
                </Wrapper>
                {questions !== undefined && questionsReceived ? (
                    <ProcessBuilderAnswerMissingQuestions
                        questions={questions}
                        onQuestionsAnswerCompleted={onQuestionsAnswerCompleted}
                    />
                ) : (
                    <ProcessBuilderAnalyzeInitialProcessDescription
                        initialProcessDescription={initialProcessDescription}
                        onQuestionsReceived={onQuestionsReceived}
                    />
                )}
            </ContentContainer>
        </>
    );
};

export default ProcessBuilderPage;
