import React, { useMemo } from 'react';

import { useSolutionSiteActionOptions } from './useSolutionSiteActionOptions';
import InterfaceCTASelectorEntityDescription from '../../../components/InterfaceCTAActionsSelector/MultiEntitySelectorCustomComponents/InterfaceCTASelectorEntityDescription';
import MultiEntitySelector from '../../../components/MultiEntitySelector/MultiEntitySelector';
import useSolutionSiteContext from '../../../hooks/useSolutionSiteContext';

import type { InterfaceCTASavedAction } from '@tonkean/tonkean-entities';

interface Props {
    name: string;
    singleItemMode?: boolean;
}

const SolutionSiteActionSelector: React.FC<Props> = ({ name, singleItemMode }) => {
    const { solutionSite, workflowVersionType } = useSolutionSiteContext();
    const customComponents = useMemo(
        () => ({
            entityDescriptionComponent: InterfaceCTASelectorEntityDescription,
        }),
        [],
    );

    const { loading, error, actionOptions } = useSolutionSiteActionOptions(
        solutionSite.workflowFolderId,
        workflowVersionType,
    );

    return (
        <MultiEntitySelector<InterfaceCTASavedAction>
            name={name}
            loading={loading}
            error={error}
            entityTypeLabel="action"
            entitiesOptions={actionOptions}
            customComponents={customComponents}
            singleItemMode={singleItemMode}
        />
    );
};
export default SolutionSiteActionSelector;
