import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function StatusPopoverWrapperCtrl($scope, $attrs, initiativeCache) {
    const ctrl = this;
    $scope.popoverIdPrefix = 'popover-track-status-summary-';
    $scope.itemMap = initiativeCache.getInitiativesCache();

    $scope.data = {
        editorId: ctrl.editorId,
        emptyContainerId: ctrl.emptyContainerId,
        containerIdPrefix: ctrl.containerIdPrefix,
        viewOnlyMode: ctrl.viewOnlyMode,
        classes: ctrl.classes,

        placement: ctrl.placement,
        initiativeIdRetriever: ctrl.initiativeIdRetriever,

        itemId: ctrl.itemId,
        initiativeId: null,
        workflowVersionId: ctrl.workflowVersionId,
        popoverElement: null,

        // Changed by inner component
        isOpen: false,
        isLocked: false, // Should the popover be able to change its target or not.
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.itemId) {
            if ($scope.data.isLocked) {
                return;
            }

            const itemId = changesObj.itemId.currentValue;
            const containerId = `${$scope.data.containerIdPrefix + itemId}-${$scope.data.editorId}`;

            $scope.data.itemId = itemId;
            $scope.data.initiativeId = itemId;

            if ($attrs.initiativeIdRetriever) {
                $scope.data.initiativeId = $scope.data.initiativeIdRetriever({ itemId });
            }

            $scope.data.isOpen = !!itemId;
            $scope.togglePopover(containerId, itemId);
        }
    };

    $scope.reset = function () {
        // Reset the popover states.
        $scope.data.itemId = null;
        $scope.data.initiativeId = null;
        $scope.data.isLocked = false;
        $scope.data.isOpen = false;
        $scope.data.popoverElement = null;

        // Append the popover back to its tracks editor.
        appendPopoverToContainer($scope.popoverIdPrefix + $scope.data.editorId, $scope.data.emptyContainerId);
    };

    $scope.togglePopover = function (containerId, isOpen) {
        if (isOpen) {
            $scope.data.isLocked = false;

            appendPopoverToContainer($scope.popoverIdPrefix + $scope.data.editorId, containerId);
        } else {
            $scope.reset();
        }
    };

    /**
     * Appends the given popover element id to the given container id using angular's append function,
     * and returns the container's angular (jqLite) element.
     */
    function appendPopoverToContainer(popoverElementId, containerId) {
        // Get the element and the container as angular elements.
        let ngPopoverElement = angular.element(document.getElementById(popoverElementId));
        const ngContainer = angular.element(document.getElementById(containerId));

        // Make sure our popovers pool have this popover as backup.
        // This is done since in some rare cases the appended popover element might get deleted while in the container.
        // In this case, we'll reload it from the pool.
        if (ngPopoverElement && ngPopoverElement[0] && !$scope.data.popoverElement) {
            $scope.data.popoverElement = ngPopoverElement;
        }

        // In case the popover element has been deleted for some reason, we'll get it from the pool.
        if (!ngPopoverElement || !ngPopoverElement[0]) {
            ngPopoverElement = $scope.data.popoverElement;
        }

        // Append takes the element from where ever it is and puts in the given container.
        ngContainer.append(ngPopoverElement);

        return ngContainer;
    }
}

export default angular.module('tonkean.app').controller('StatusPopoverWrapperCtrl', lateConstructController(StatusPopoverWrapperCtrl));
