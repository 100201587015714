<div ng-controller="UpgradeCtrl">
    <span class="common-close-btn padding-normal" ng-click="close()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>
    <div ng-include="'../../state.upgrade/upgrade.template.html'"></div>
    <!-- Error loading license -->
    <div
        ng-if="getLicenseError"
        class="margin-auto-right-left margin-top-xxlg padding-right padding-left"
        style="width: 330px"
    >
        <uib-alert type="danger" class="common-size-xxs margin-bottom">
            <i class="fa fa-exclamation-triangle margin-right-xs"></i>
            Failed loading subscription & billing data
        </uib-alert>
        <div class="text-center common-width-full">
            <a href="" ng-click="updateOrganization()">Retry</a>
        </div>
    </div>
</div>
