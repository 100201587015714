import React from 'react';
import styled from 'styled-components';

import SolutionPageLink from './SolutionPageLink';
import useSolutionSitePagesContext from '../hooks/useSolutionSitePagesContext';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 0 16px;
`;

interface Props extends StyledComponentsSupportProps {
    onPageClick: () => void;
}

const PagesList: React.FC<Props> = ({ onPageClick, className }) => {
    const { solutionSitePages, selectedPage } = useSolutionSitePagesContext();

    return (
        <List className={className}>
            {solutionSitePages.map((page) => (
                <SolutionPageLink
                    key={page.id}
                    page={page}
                    selected={page.id === selectedPage?.id}
                    onClick={onPageClick}
                />
            ))}
        </List>
    );
};

export default PagesList;
