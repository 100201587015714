import AutonomousScheduleLogicBlockConfig from './AutonomousScheduleLogicBlockConfig';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const InnerAutonomousScheduleLogicBlockConfig: LogicBlockConfigDefinition = {
    ...AutonomousScheduleLogicBlockConfig,
    type: LogicBlockConfigType.INNER_AUTONOMOUS,
};

export default InnerAutonomousScheduleLogicBlockConfig;
