import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ModuleRootIcon } from '../../../../../../../images/icons/module-root.svg';

import { ZebraTableBodyCell } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const StyledEmptyModule = styled.div`
    background-color: ${Theme.colors.HEX_F2F2F2};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin-top: 10px;
    border: 1px solid #c9c9c9;

    svg {
        opacity: 0.4;
        height: 16px;
        width: 16px;
        margin-left: 2px;
    }
`;

const StyledEmptySolution = styled.tr`
    background-color: ${Theme.colors.brightestGray};
    height: 70px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

const EmptySolution: React.FC = () => {
    return (
        <StyledEmptySolution>
            <ZebraTableBodyCell>
                <span className="flex flex-col">
                    No modules were created yet
                    <StyledEmptyModule>
                        <span className="tnk-icon">
                            <ModuleRootIcon />
                        </span>
                    </StyledEmptyModule>
                </span>
            </ZebraTableBodyCell>
        </StyledEmptySolution>
    );
};

export default EmptySolution;
