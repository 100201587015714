import React from 'react';
import styled from 'styled-components';

import { LoadingCircle, MenuItem, TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import type { InterfaceCTASavedAction } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';

const MenuItemLabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 5px;
`;

interface Props {
    action: InterfaceCTASavedAction;
    handleActionClick: (action: InterfaceCTASavedAction) => void;
    blockActionsClick: boolean;
    primaryColor: Color;
    loadingIdMap: Record<string, boolean>;
    liveDisplay?: {
        actionsLiveDisplayKeyPrefix: string | undefined;
        actionsDisabledLiveDisplay: Record<InterfaceCTASavedAction['id'], boolean> | undefined | {};
    };
    noItemInitialized: boolean;
}

const InterfaceHeaderCallToActionMenuItem: React.FC<Props> = ({
    action,
    handleActionClick,
    blockActionsClick,
    primaryColor,
    loadingIdMap,
    liveDisplay,
    noItemInitialized,
}) => {
    const liveDisabled =
        liveDisplay?.actionsDisabledLiveDisplay?.[`${liveDisplay.actionsLiveDisplayKeyPrefix}-${action.id}`];
    const loading = loadingIdMap[action.id];
    const buttonDisabled = noItemInitialized || liveDisabled || loading || !!action.buttonStates?.disabled?.active;
    return (
        <Tooltip
            content={noItemInitialized ? 'No item found' : action.buttonStates?.disabled?.disabledText}
            disabled={!buttonDisabled || loading}
            placement="bottom"
        >
            <MenuItem
                data-automation="item-interface-header-call-to-action-menu-button"
                onClickCapture={
                    blockActionsClick
                        ? (event) => {
                              event.stopPropagation();
                              return false;
                          }
                        : undefined
                }
                onClick={() => handleActionClick(action)}
                disabled={buttonDisabled}
                // Solving a bug where you would be able to focus a disabled element due to having a tooltip.
                // This is especially problematic the action is first and will focus and show a double tooltip.
                tabIndex={liveDisabled ? -1 : undefined}
            >
                <MenuItemLabelContainer>
                    <TextEllipsis numberOfLines={1} tooltip>
                        {action.label}
                    </TextEllipsis>{' '}
                    {loading && <LoadingCircle color={primaryColor} thin />}
                </MenuItemLabelContainer>
            </MenuItem>
        </Tooltip>
    );
};

export default InterfaceHeaderCallToActionMenuItem;
