<div class="margin-left margin-right">
    <div class="margin-bottom" ng-if="data.loading">
        <i class="loading-small"></i>
    </div>

    <!-- Assign a conversation -->
    <div class="margin-bottom" ng-if="data.definition.customActionKey === 'SET_ASSIGNEE' && !data.loading">
        <!-- Comment -->
        <div class="margin-right">Set Assignee:</div>

        <div ng-if="data.validationObject.noAuthor" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noAuthor }}
        </div>
        <form ng-submit="" novalidate class="margin-top-xxs">
            <tnk-person-selection-configuration
                class="block margin-bottom-lg"
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                radio-id-prefix="'performer-' + data.workflowVersionId"
                validation-object="data.validationObject"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-person-selection-configuration="data.existingTeammateSelectionConfiguration"
                on-person-selection-configuration-changed="
                    onPerformingTeammateSelected(personSelectionConfiguration, shouldSaveLogic)
                "
                support-quick-create="false"
                specific-people-custom-options-refresh="refreshTeammates(q)"
                specific-people-custom-options="data.teammate"
                configured-logic="data.configuredLogic"
                unique-identifier="teammate"
            ></tnk-person-selection-configuration>
        </form>
    </div>

    <!-- Reply to conversation -->
    <div
        class="delay-configuration-logic"
        ng-if="!data.loading && data.definition.customActionKey === 'REPLY_CONVERSATION'"
    >
        <!-- Comment -->
        <div class="margin-right">Set Replier:</div>

        <div ng-if="data.validationObject.noReplier" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noReplier }}
        </div>
        <form ng-submit="" novalidate class="margin-top-xxs">
            <tnk-person-selection-configuration
                class="block margin-bottom-lg"
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                radio-id-prefix="'performer-' + data.workflowVersionId"
                validation-object="data.validationObject"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-person-selection-configuration="data.existingTeammateSelectionConfiguration"
                on-person-selection-configuration-changed="
                    onPerformingTeammateSelected(personSelectionConfiguration, shouldSaveLogic)
                "
                support-quick-create="false"
                specific-people-custom-options-refresh="refreshTeammates(q)"
                specific-people-custom-options="data.teammate"
                configured-logic="data.configuredLogic"
                unique-identifier="teammate"
            ></tnk-person-selection-configuration>
        </form>

        <!-- Title -->
        <div class="margin-bottom-xs">Text to reply:</div>

        <div ng-if="data.validationObject.noText" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noText }}
        </div>

        <!-- Input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Type a custom message to send"
            saved-original-expression="data.definition.text"
            saved-evaluated-expression="data.definition.evaluatedText"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>
    </div>
</div>
