import { useAngularService, useAngularWatch } from 'angulareact';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ModalBody, ModalHeader } from '@tonkean/infrastructure';
import { ModalForm } from '@tonkean/infrastructure';
import { ModalFooterActions } from '@tonkean/infrastructure';
import { Field } from '@tonkean/infrastructure';
import { Input } from '@tonkean/infrastructure';
import { SimpleSelect } from '@tonkean/infrastructure';
import { EnvironmentIndicator, environments } from '@tonkean/infrastructure';
import { withModal } from '@tonkean/infrastructure';
import { useCloseCallback } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure';
import {
    SolutionBusinessReportAccessPermission,
    SolutionBusinessReportPermissionType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type { Environment, SolutionBusinessReport, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledField = styled(Field)<{ last?: boolean }>`
    width: 300px;
    margin-bottom: ${({ last = false }) => (last ? 0 : 25)}px;
`;

const CreateReportSchema = Yup.object({
    displayName: Yup.string().required('You must fill a name'),
    environment: Yup.string().oneOf(environments).required('You must choose an environment'),
    groupIds: Yup.array().of(Yup.string().required()).min(1, 'Choose at least one module').required(),
});

interface Props {
    workflowFolder: WorkflowFolder;
    solutionBusinessReport?: SolutionBusinessReport;
    onSave(solutionBusinessReport: SolutionBusinessReport): void;
}

const ManageSolutionBusinessReportModal: React.FC<Props> = ({ workflowFolder, solutionBusinessReport, onSave }) => {
    const authenticationService = useAngularService('authenticationService');
    const projectManager = useAngularService('projectManager');

    const closeModal = useCloseCallback();

    const [groupIds] = useAngularWatch(() => workflowFolder.groupIds);
    const groupOptions = useMemo(() => {
        return groupIds
            .map((groupId) => projectManager.groupsMap[groupId])
            .map((group) => ({ label: group.name, value: group.id }));
    }, [groupIds, projectManager.groupsMap]);

    const [{ error: createError }, createSolutionBusinessReport] =
        useLazyTonkeanService('createSolutionBusinessReport');
    const [{ error: updateError }, updateSolutionBusinessReport] =
        useLazyTonkeanService('updateSolutionBusinessReport');

    const currentUserId = authenticationService.getCurrentUser().id;
    const afterSaveHandle = useCallback(
        (id: string, values, workflowVersionType: WorkflowVersionType) => {
            const upToDateSolutionBusinessReport: SolutionBusinessReport = {
                id: id as TonkeanId<TonkeanType.SOLUTION_BUSINESS_REPORT>,
                projectId: projectManager.project.id,
                solutionId: workflowFolder.id,
                created: Date.now(),
                updated: Date.now(),
                creatorId: currentUserId,
                displayName: values.displayName,
                workflowVersionType,
                permissions: {
                    [SolutionBusinessReportPermissionType.EDIT]: {
                        type: SolutionBusinessReportPermissionType.EDIT,
                        permission: SolutionBusinessReportAccessPermission.NO_ONE,
                        peopleIds: [],
                    },
                    [SolutionBusinessReportPermissionType.VIEW]: {
                        type: SolutionBusinessReportPermissionType.VIEW,
                        permission: SolutionBusinessReportAccessPermission.NO_ONE,
                        peopleIds: [],
                    },
                },
                groups: values.groupIds.map((groupId) => ({ groupId })),
            };

            onSave(upToDateSolutionBusinessReport);
            closeModal();
        },
        [currentUserId, closeModal, onSave, projectManager.project.id, workflowFolder.id],
    );

    const onSubmit = useCallback(
        (values) => {
            const workflowVersionType =
                values.environment === 'build' ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED;

            if (solutionBusinessReport) {
                const existingReportGroupsMap = Object.fromEntries(
                    (solutionBusinessReport?.groups || []).map((group) => [group.groupId, group]),
                );

                return updateSolutionBusinessReport(solutionBusinessReport.id, {
                    displayName: values.displayName,
                    groups: values.groupIds.map((id) => existingReportGroupsMap[id] || { groupId: id }),
                }).then(() => {
                    afterSaveHandle(solutionBusinessReport.id, values, workflowVersionType);
                });
            } else {
                return createSolutionBusinessReport(
                    workflowFolder.id,
                    values.displayName,
                    workflowVersionType,
                    values.groupIds,
                ).then((createdReport) => {
                    afterSaveHandle(createdReport.id, values, workflowVersionType);
                });
            }
        },
        [
            afterSaveHandle,
            createSolutionBusinessReport,
            solutionBusinessReport,
            updateSolutionBusinessReport,
            workflowFolder.id,
        ],
    );

    return (
        <Formik
            initialValues={{
                displayName: solutionBusinessReport?.displayName || '',
                environment:
                    (solutionBusinessReport?.workflowVersionType === WorkflowVersionType.DRAFT
                        ? 'build'
                        : 'production') || ('' as Environment),
                groupIds: solutionBusinessReport?.groups?.map((group) => group.groupId) || [],
            }}
            onSubmit={onSubmit}
            validationSchema={CreateReportSchema}
        >
            <ModalForm>
                <ModalHeader> Edit {solutionBusinessReport?.displayName} report</ModalHeader>
                <ModalBody>
                    <StyledField label="Report Name">
                        <Input size={InputSize.MEDIUM} name="displayName" />
                    </StyledField>
                    <StyledField
                        label={!!solutionBusinessReport ? 'Report Environment' : 'Select the report environment'}
                    >
                        <Tooltip content="Can't edit a report's environment" disabled={!solutionBusinessReport}>
                            <div>
                                <EnvironmentIndicator
                                    disabled={!!solutionBusinessReport}
                                    name="environment"
                                    placeholder=""
                                    thin
                                />
                            </div>
                        </Tooltip>
                    </StyledField>
                    <StyledField label="Select Modules" last>
                        <SimpleSelect name="groupIds" options={groupOptions} placeholder="" thin isMulti />
                    </StyledField>
                </ModalBody>
                <ModalFooterActions error={createError || updateError} />
            </ModalForm>
        </Formik>
    );
};

export default withModal(ManageSolutionBusinessReportModal);
