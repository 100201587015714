import EnterpriseComponentVariablesValuesModal from './EnterpriseComponentVariablesValuesModal';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkEnterpriseComponentVariablesValuesModal',
        reactToAngular(EnterpriseComponentVariablesValuesModal, ['isOpen', 'projectIntegrationId', 'onClose']),
    );
