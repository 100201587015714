import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import FormSettingsInput from './FormSettingsInput';
import FormSettingsOptionalSubTitle from './FormSettingsOptionalSubTitle';
import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { SavingIndicator, Spacer, Toggle, useUUID } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { Form, TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const TitleWrapper = styled.div`
    margin-bottom: 12px;

    display: flex;
    align-items: center;
`;
const DescriptionLabelWrapper = styled(TitleWrapper)`
    margin-bottom: 0;
`;

const ToggleText = styled.span`
    font-weight: 400;
`;

interface Props {
    groupId: string;
    workflowVersionId: string;
    form: Form;
    onFormChanged: Dispatch<SetStateAction<Form>>;
}

const FormSettingsExpandableDescription: React.FC<Props> = ({ groupId, workflowVersionId, form, onFormChanged }) => {
    const { editedSections, setSectionLoading, isEditable } = useContext(FormBuilderContext);

    const onExpandableDescriptionChange = useCallback(
        (originalExpression: string, evaluatedExpression: string, expression?: TonkeanExpressionDefinition) => {
            if (
                originalExpression !== form?.expandableDescriptionExpression?.originalExpression ||
                evaluatedExpression !== form?.expandableDescriptionExpression?.evaluatedExpression
            ) {
                onFormChanged((prevFormValues) => ({
                    ...prevFormValues,
                    expandableDescriptionExpression: {
                        originalExpression,
                        evaluatedExpression,
                        isStripHtmlDisabled: expression?.isStripHtmlDisabled ?? false,
                    },
                }));
                setSectionLoading(FormBuilderSections.ExpandableDescription);
            }
        },
        [form, onFormChanged, setSectionLoading],
    );

    const onCollapseByDefaultChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onFormChanged((prevFormValues) => ({
                ...prevFormValues,
                collapseDescriptionByDefault: event.target.checked,
            }));
            setSectionLoading(FormBuilderSections.ExpandableDescription);
        },
        [onFormChanged, setSectionLoading],
    );

    const onExpandableDescriptionLabelChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onFormChanged((prevFormValues) => ({
                ...prevFormValues,
                expandableDescriptionLabel: event.target.value,
            }));
            setSectionLoading(FormBuilderSections.ExpandableDescriptionLabel);
        },
        [onFormChanged, setSectionLoading],
    );

    const id = useUUID();
    const key = form.id ?? id;

    const labelId = useUUID();

    return (
        <>
            <Spacer height={Theme.sizes.formBuilder.standardMarginTop} />
            <TitleWrapper>
                <FormSettingsSectionHeaderTitle>Form Description</FormSettingsSectionHeaderTitle>
                <FormSettingsOptionalSubTitle>(optional)</FormSettingsOptionalSubTitle>
                <SavingIndicator state={editedSections[FormBuilderSections.ExpandableDescription]} />
            </TitleWrapper>
            <TonkeanExpression
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                savedOriginalExpression={form?.expandableDescriptionExpression?.originalExpression || ''}
                savedEvaluatedExpression={form?.expandableDescriptionExpression?.evaluatedExpression || ''}
                disabled={!isEditable}
                onTonkeanExpressionChanged={onExpandableDescriptionChange}
                key={key}
                includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']}
                showFormulasTab
                doNotEvaluatePreview
                saveOnKeyUp
            />
            <Spacer height={12} />
            <Toggle
                checked={form.collapseDescriptionByDefault}
                onChange={onCollapseByDefaultChange}
                labelAfter={<ToggleText>Collapse description by default</ToggleText>}
                size={InputSize.SMALL}
            />
            <Spacer height={30} />
            <DescriptionLabelWrapper as="label" htmlFor={labelId}>
                <FormSettingsSectionHeaderTitle>Form Description Label</FormSettingsSectionHeaderTitle>
                <FormSettingsOptionalSubTitle>(optional)</FormSettingsOptionalSubTitle>
                <SavingIndicator state={editedSections[FormBuilderSections.ExpandableDescriptionLabel]} />
            </DescriptionLabelWrapper>
            <FormSettingsInput
                value={form.expandableDescriptionLabel ?? ''}
                onChange={onExpandableDescriptionLabelChange}
                placeholder="Description"
                id={labelId}
            />
        </>
    );
};

export default FormSettingsExpandableDescription;
