function ArchiveCtrl(
    $scope,
    $stateParams,
    $state,
    trackHelper,
    tonkeanService,
    projectManager,
    groupInfoManager,
    groupPermissions,
) {
    $scope.pm = projectManager;
    $scope.gm = groupPermissions;
    $scope.filter = $stateParams.filter;

    $scope.data = {
        initiatives: [],
        allInitiatives: null,
        groupId: $stateParams.gid,
        isDraft: false,
        loading: true,
        limit: 50,
        skip: 0,
    };

    if (!$scope.data.groupId) {
        $scope.data.loading = true;
        groupInfoManager.getGroups().then(function () {
            $state.go('product.archive', { gid: projectManager.groups[0].id });
        });
        return;
    }

    $scope.init = function () {
        $scope.data.skip = 0;
        $scope.data.allInitiatives = null;
        $scope.loadInitiatives();
    };

    $scope.loadInitiatives = function (loadMore) {
        $scope.data.loading = !loadMore;
        if (loadMore) {
            $scope.data.skip += $scope.data.limit;
            $scope.data.loadingMore = true;
        }
        tonkeanService
            .searchInitiatives($scope.pm.project.id, {
                isArchived: true,
                groupId: $scope.data.groupId,
                searchString: $scope.data.search,
                skip: $scope.data.skip,
                limit: $scope.data.limit,
                isDraftInitiatives: $scope.data.isDraft,
            })
            .then(function (data) {
                const allInitiatives = trackHelper.cacheItemList(data.entities);

                if (!$scope.data.allInitiatives) {
                    $scope.data.allInitiatives = allInitiatives;
                } else {
                    Array.prototype.push.apply($scope.data.allInitiatives, allInitiatives);
                }
                $scope.data.hasMoreEntities = data.hasMoreEntities;
                $scope.data.loading = false;
                $scope.data.loadingMore = false;
            });
    };

    $scope.onEnvironmentToggleClick = function (environment) {
        const isProduction = environment === 'production';
        $scope.data.isDraft = !isProduction;

        $scope.init();
    };

    $scope.init();
}
export default angular.module('tonkean.app').controller('ArchiveCtrl', ArchiveCtrl);
