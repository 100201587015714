import React, { useMemo } from 'react';
import styled from 'styled-components';

import SimpleActionParameterSection from './SimpleActionParameterSection';

import { CustomFilters } from '@tonkean/angular-to-react-components';
import { Paragraph, Spacer } from '@tonkean/infrastructure';
import type { ActionParameterExpression, BaseActionParameter, BasicQueryDefinition } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

// it's mandatory to wrap the CustomFilters component in a div.
const CustomFiltersContainer = styled.div``;
const ParameterHeader = styled.div`
    margin-bottom: 10px;
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
`;

const IsRequiredErrorMessage = styled.div`
    color: ${Theme.colors.error};
    margin-bottom: 10px;
`;

export interface ChildEntitiesParameterSectionProps {
    childEntityKey: string;
    childEntityParameters: (BaseActionParameter & ActionParameterExpression)[];
    saveChildParameterExpressionValue: (
        key: string,
        originalExpression: string,
        evaluatedExpression: string,
        shouldSaveLogic: boolean,
        actionParameter: BaseActionParameter,
    ) => void;
    groupId: string;
    workflowVersionId: string;
    customTriggerId: string;
    innerItemsFilters: BasicQueryDefinition;
    previewEvaluationSource: unknown;
    saveChildEntitiesFilters: (key: string, query: BasicQueryDefinition) => void;
    childParametersValues: unknown;
    validationLogicErrorMessageChildEntity?: string;
}
const ChildEntitiesParameterSection: React.FC<ChildEntitiesParameterSectionProps> = ({
    childEntityKey,
    childEntityParameters,
    saveChildParameterExpressionValue,
    groupId,
    workflowVersionId,
    customTriggerId,
    innerItemsFilters,
    previewEvaluationSource,
    saveChildEntitiesFilters,
    childParametersValues,
    validationLogicErrorMessageChildEntity,
}) => {
    // The angular conditions component requires it... yep
    const control: any = useMemo(() => {
        return {};
    }, []);

    return (
        <>
            <Spacer height={24} width={10} />
            <ParameterHeader>{`${childEntityKey} Parameters:`}</ParameterHeader>
            {validationLogicErrorMessageChildEntity && (
                <IsRequiredErrorMessage>{validationLogicErrorMessageChildEntity}</IsRequiredErrorMessage>
            )}
            <Paragraph $light>
                Filter the inner items you wish to use as child items. Inner item fields will be passed as the values.
                Click on the tag if you want to select the parent value{' '}
            </Paragraph>
            <CustomFiltersContainer>
                <CustomFilters
                    control={control}
                    existingDefinition={innerItemsFilters}
                    itemsSource="COLUMN"
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    specialFieldsForFeatures={['WORKER_UPDATE_FIELD']}
                    onFiltersChangeDefinition={(query) => {
                        const validQuery = query.query.filters?.length === 0 || control?.anyNonEmptyConditions?.();

                        if (validQuery) {
                            saveChildEntitiesFilters(childEntityKey, query);
                        }
                    }}
                    hideTimeRangeSelection
                    hideCloseButton
                    editMode
                    useExpressionsForValue
                />
            </CustomFiltersContainer>
            {childEntityParameters.map((singleActionParameter) => (
                <div key={singleActionParameter.id} data-automation="simple_child-action-parameter">
                    <SimpleActionParameterSection
                        singleActionParameter={singleActionParameter}
                        saveParameterExpressionValue={(
                            originalExpression: string,
                            evaluatedExpression: string,
                            shouldSaveLogic: boolean,
                            actionParameter: BaseActionParameter,
                        ) =>
                            saveChildParameterExpressionValue(
                                childEntityKey,
                                originalExpression,
                                evaluatedExpression,
                                shouldSaveLogic,
                                actionParameter,
                            )
                        }
                        groupId={groupId}
                        workflowVersionId={workflowVersionId}
                        customTriggerId={customTriggerId}
                        parametersValues={childParametersValues}
                        previewEvaluationSource={previewEvaluationSource}
                    />
                </div>
            ))}
        </>
    );
};

export default ChildEntitiesParameterSection;
