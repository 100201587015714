<div class="create-initiative-modal map-integration-users-modal">
    <div class="text-center" ng-show="saving">
        <i class="loading-large"></i>
        <h3>Saving...</h3>
    </div>
    <form name="mapUsersForm" ng-hide="saving" ng-submit="submit()" novalidate>
        <div class="common-title-inner margin-none">
            Map {{ data.projectIntegration.displayName }} Users
            <small ng-if="data.totalCount !== 0" class="common-size-xs common-color-grey">
                (mapped {{ data.mappedCount }} out of {{ data.totalCount }})
            </small>
        </div>
        <hr />

        <div class="margin-normal-v">
            <div class="text-center" ng-show="loading"><i class="loading-large"></i></div>
            <div ng-if="!loading">
                <p class="common-color-grey common-size-xxs">
                    Map {{ data.projectIntegration.displayName }} users with Tonkean users in order to use human
                    coordination capabilities.
                </p>

                <div class="overflow-y-auto rounded-box margin-top-lg" style="max-height: 500px; height: 300px">
                    <div class="user-list">
                        <div ng-repeat-start="user in data.users">
                            <div>
                                {{ user.actorName }}
                                <a ng-if="user.externalUrl" ng-href="{{ user.externalUrl }}" target="_blank">
                                    <i class="fa fa-external-link-square margin-right-xs"></i>
                                </a>
                            </div>
                            <div
                                ng-if="data.showActorNameAndActorIdentifyingName || !user.actorName"
                                class="common-color-grey common-size-xxxs"
                            >
                                {{ user.actorIdentifyingName || user.comparePropertyKey }}
                            </div>
                        </div>

                        <i class="fa fa-long-arrow-right"></i>

                        <tnk-deprecated-people-selector
                            no-validation="false"
                            group="groupId"
                            selected-people="user.people"
                            max-tags="1"
                            show-intro="false"
                        ></tnk-deprecated-people-selector>

                        <div
                            ng-repeat-end
                            uib-tooltip="{{
                                !user.saved && user.autoSuggestion
                                    ? 'Auto-filled suggestion'
                                    : user.saved
                                    ? 'Saved match'
                                    : ''
                            }}"
                        >
                            <i
                                ng-if="!user.saved && user.autoSuggestion"
                                class="fa fa-question-circle"
                                uib-tooltip="Auto-filled suggestion"
                            ></i>
                            <i ng-if="user.saved" class="fa fa-check" uib-tooltip="Saved match"></i>
                        </div>
                    </div>
                    <div class="text-center common-size-xxs" ng-if="!data.users || !data.users.length">
                        No users found...
                    </div>
                </div>
            </div>
        </div>

        <div>
            <span class="text-danger" ng-if="error">{{ error | error }}</span>
        </div>
        <div class="clearfix flex-vmiddle">
            <div class="flex-grow"></div>
            <div class="margin-top-lg" ng-hide="loading">
                <button type="button" class="btn btn-lg btn-default mark-modal-btn" ng-click="$dismiss()">
                    Cancel
                </button>
                <button type="submit" class="btn btn-lg btn-primary mark-modal-btn">Save</button>
            </div>
        </div>
    </form>
</div>
