import { useMemo } from 'react';
import type { AnySchema } from 'yup';
import * as Yup from 'yup';

import { DayOfWeek, RecurrencePeriodType } from '@tonkean/tonkean-entities';
import { yupEnum } from '@tonkean/utils';

interface RecurrenceSelectionParts {
    timezone?: AnySchema;
    recurrenceMonthsInYear?: AnySchema;
    recurrenceDaysInMonth?: AnySchema;
    recurrenceDaysInWeek?: AnySchema;
    recurrenceHour?: AnySchema;
    recurrenceMinute?: AnySchema;
    everyXHours?: AnySchema;
    everyXMinutes?: AnySchema;
}

/**
 * Returns an object with the validation of needed for the provided recurrencePeriodType
 * If no validation returned that means that component should not be shown
 */
const useRecurrenceSelectionValidation = (recurrencePeriodType: RecurrencePeriodType, minEveryXMinutes: number) => {
    return useMemo(() => {
        const parts: RecurrenceSelectionParts = {};

        const showMonthlyDayPicker = recurrencePeriodType === RecurrencePeriodType.Monthly;
        if (showMonthlyDayPicker) {
            parts.recurrenceDaysInMonth = Yup.number().min(1).max(31).required();
        }

        const showMonthPicker = recurrencePeriodType === RecurrencePeriodType.Monthly;
        if (showMonthPicker) {
            parts.recurrenceMonthsInYear = Yup.array(Yup.number().min(1).max(12))
                .min(1, 'Must pick at least one')
                .required();
        }

        const showWeeklyDayPicker = recurrencePeriodType === RecurrencePeriodType.Weekly;
        if (showWeeklyDayPicker) {
            parts.recurrenceDaysInWeek = Yup.array(yupEnum(DayOfWeek)).min(1, 'Must pick at least one').required();
        }

        const showFullTimePicker =
            recurrencePeriodType !== RecurrencePeriodType.EveryXHours &&
            recurrencePeriodType !== RecurrencePeriodType.EveryXMinutes;
        if (showFullTimePicker) {
            parts.recurrenceHour = Yup.number().required();
            parts.recurrenceMinute = Yup.number().required();
        }

        const showHourPicker = recurrencePeriodType === RecurrencePeriodType.EveryXHours;
        if (showHourPicker) {
            parts.everyXHours = Yup.number()
                .integer('Must be a whole number')
                .moreThan(0, 'Must be greater than 0')
                .required('Must be filled');
        }

        const showMinutePicker = recurrencePeriodType === RecurrencePeriodType.EveryXMinutes;
        if (showMinutePicker) {
            parts.everyXMinutes = Yup.number()
                .integer('Must be a whole number')
                .min(minEveryXMinutes, `Must be ${minEveryXMinutes} or greater`)
                .required('Must be filled');
        }

        const showTimezonePicker =
            recurrencePeriodType !== RecurrencePeriodType.EveryXHours &&
            recurrencePeriodType !== RecurrencePeriodType.EveryXMinutes;
        if (showTimezonePicker) {
            parts.timezone = Yup.string().required();
        }

        return parts;
    }, [minEveryXMinutes, recurrencePeriodType]);
};

export default useRecurrenceSelectionValidation;
