import template from './tnkParentNavigation.template.html?angularjs';

angular.module('tonkean.app').component('tnkParentNavigation', {
    bindings: {
        // An item not directly connected to the parent chain but is considered the most early father (optional)
        // expects an object with name and sref(will be plugged into ui-sref)
        godFather: '<',
        // if true turns cursor on godfather to disabled
        disableGodFather: '<',
        // An array of parent items where each item has title and id
        parentsArray: '<',
        // An optional label for the immediate parent (current item), if not given will use title like the rest
        immediateParentLabel: '<',
        // A function to get the full parent object
        provideParent: '<',
        // A function to navigate to a parent id
        navigate: '<',
        // If true immediate parent label should be bold
        immediateParentBold: '<',
        // If exists, should replace original separator
        parentsSeparator: '<',
    },
    template,
    controller: 'ParentNavigationCtrl',
});
