import template from './tnkActivityPopup.template.html?angularjs';

function tnkActivityPopup() {
    return {
        restrict: 'E',
        scope: {
            inline: '<', // If true, will remove the button to open the popup and will not try to position.
            borderless: '<', // If true, will not have a border or shadow on the container div.
            customClass: '@', // Optional classes to put on the container div.

            isVisible: '<', // Is the content viewed right now, will be used to clean up the item count.
        },
        controller: 'ActivityPopupCtrl',
        template,
    };
}
angular.module('tonkean.app').directive('tnkActivityPopup', tnkActivityPopup);
