import type { ChartOptions, TooltipItem } from 'chart.js';
import { ArcElement, BarController, BarElement, CategoryScale, Chart, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import DoughnutLabel from 'chartjs-plugin-doughnutlabel-rebourne';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import styled from 'styled-components';

import { FieldChartWidgetConstants } from './FieldChartWidgetConstants';

import { ChartDisplayType, ChartEmptyState } from '@tonkean/infrastructure';
import { DoughnutLabelsLoader } from '@tonkean/svg';
import { DoughnutLoader } from '@tonkean/svg';
import { FieldType } from '@tonkean/tonkean-entities';
import type { FieldInstance, WorkflowVersion } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

Chart.register(ArcElement, BarController, BarElement, CategoryScale, Legend, Tooltip);

const DoughnutLoaderContainer = styled.div`
    height: 100%;
`;

const DoughnutLoaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    align-items: center;
`;

const DoughnutChart = styled(Doughnut)`
    width: 400px;
    height: 400px;
`;

const PieChart = styled(Pie)`
    width: 400px;
    height: 400px;
`;

interface Props {
    fieldId?: string;
    fieldType?: FieldType;
    fieldInstance?: FieldInstance;
    chartDisplayType: ChartDisplayType;
    optionallyEvaluatedResults?: string;
    loading?: boolean;
    workflowVersion?: WorkflowVersion;
}

const CategoricalFieldInstanceChart: React.FC<Props> = ({
    fieldId,
    fieldType,
    fieldInstance,
    chartDisplayType,
    optionallyEvaluatedResults,
    loading,
    workflowVersion,
}) => {
    const { chartLabels, chartData } = useMemo<{ chartLabels: string[]; chartData: string[] }>(() => {
        const innerChartData: string[] = [];
        const innerChartLabels: string[] = [];

        if (!loading) {
            const results: string | undefined = optionallyEvaluatedResults ?? fieldInstance?.value;
            if (results) {
                // The results from the aggregation query returns as such: "in progress : 5, on hold : 2, not started : 1"
                const fieldValueSplit = results.split(',\n');
                fieldValueSplit?.map((currentStatisticPiece) => {
                    // Splitting each piece ('in progress : 5') to labels ('in progress') and data (5) using separator ' :'
                    const splitVal: string[] = currentStatisticPiece.split(' :');
                    if (splitVal[0] && splitVal[1] && !isNaN(Number.parseInt(splitVal[1]))) {
                        let label = splitVal[0];
                        if (
                            label !== FieldChartWidgetConstants.NO_VALUE_LABEL &&
                            fieldType &&
                            fieldType === FieldType.Date
                        ) {
                            label = dayjs(label).format('MMM DD, YYYY');
                        }
                        innerChartLabels.push(label);
                        innerChartData.push(splitVal[1]);
                    }
                });
            }
        }
        return { chartLabels: innerChartLabels, chartData: innerChartData };
    }, [fieldInstance?.value, fieldType, loading, optionallyEvaluatedResults]);

    const colors = useMemo<string[]>(() => {
        // if the field is of type status we want to assign the actual status colors.
        if (fieldId === 'TNK_STAGE' && workflowVersion) {
            let statusesWithoutColorsCounter = 0;
            const statusColorList: string[] = [];
            chartLabels.forEach((label) => {
                const statusColor = workflowVersion?.states?.find((state) => state.label === label)?.color;
                if (statusColor) {
                    statusColorList.push(statusColor);
                } else if (label === FieldChartWidgetConstants.NO_VALUE_LABEL) {
                    statusColorList.push(Theme.colors.promotion);
                } else {
                    // This should not happen, but if for any reason a status color wasn't found and this is not a no value label add color from the list according to a counter.
                    if (statusesWithoutColorsCounter === FieldChartWidgetConstants.COLORS.length) {
                        statusesWithoutColorsCounter = 0;
                    }
                    const color = FieldChartWidgetConstants.COLORS[statusesWithoutColorsCounter++];
                    if (color) {
                        statusColorList.push(color);
                    }
                }
            });
            return statusColorList;
        }
        const newColorsList: string[] = [...FieldChartWidgetConstants.COLORS];
        // to avoid having the same color appearing one after the other in case we have
        // chartLabels.length % initialColors.length === 1 we push an extra color
        if (chartLabels.length % FieldChartWidgetConstants.COLORS.length === 1) {
            newColorsList.push(Theme.colors.promotion);
        }
        return newColorsList;
    }, [chartLabels, fieldId, workflowVersion]);

    // We calculate the total amount of initiative in all the labels to calculate the percentage of each label
    const totalAmount = useMemo(() => {
        if (chartData.length === 0) {
            return 0;
        }

        return chartData.reduce((counter, fieldValue) => counter + Number.parseInt(fieldValue), 0);
    }, [chartData]);

    const getLabel = useMemo(() => {
        return {
            label(tooltipItem: TooltipItem<'bar'> | TooltipItem<'pie'> | TooltipItem<'doughnut'>) {
                const label = tooltipItem.label || '';
                // Calculate the percentage value, if it is a bar the value is in the y axis otherwise it's just on parsed value.
                let value: number = 0;
                if (typeof tooltipItem.parsed === 'number') {
                    value = tooltipItem.parsed;
                } else if ('y' in tooltipItem.parsed) {
                    value = tooltipItem.parsed.y;
                }
                const percentage = Math.round((value * 100) / totalAmount);
                return `${label} : ${tooltipItem.formattedValue} (${percentage}%)`;
            },
        };
    }, [totalAmount]);

    const data = useMemo(() => {
        return {
            labels: chartLabels,
            datasets: [
                {
                    label: '',
                    data: chartData,
                    backgroundColor: colors,
                    hoverOffset: 4,
                    borderWidth: 1,
                },
            ],
        };
    }, [chartData, chartLabels, colors]);

    const doughnutLabels = useMemo(() => {
        if (chartDisplayType === ChartDisplayType.DOUGHNUT) {
            return [
                {
                    text: totalAmount,
                    font: {
                        size: 20,
                        weight: 'bold',
                    },
                },
                {
                    text: 'total',
                },
            ];
        }
        return [];
    }, [chartDisplayType, totalAmount]);

    const doughnutOptions = {
        legend: {
            display: false,
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        showTooltips: true,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: chartDisplayType !== ChartDisplayType.BAR,
                position: 'right',
            },
            tooltip: {
                callbacks: getLabel,
            },
            datalabels: {
                display: true,
                color: 'white',
                font: {
                    size: 14,
                },
                formatter(currentData, context) {
                    if (chartDisplayType !== ChartDisplayType.BAR) {
                        // Calculate the percentage value
                        const percentage = Math.round((Number.parseInt(currentData) * 100) / totalAmount);
                        if (percentage < 8) {
                            return '';
                        }

                        return `${percentage}%`;
                    }
                    return '';
                },
            },
            doughnutlabel: {
                labels: [...doughnutLabels],
            },
        },
        onAnimationComplete() {
            this.showTooltip(this.datasets[0].points, true);
        },
    };

    if (loading) {
        return (
            <DoughnutLoaderContainer>
                <DoughnutLoaderWrapper>
                    <DoughnutLoader />
                    <DoughnutLabelsLoader />
                </DoughnutLoaderWrapper>
            </DoughnutLoaderContainer>
        );
    }

    return (
        <>
            {chartData.length > 0 ? (
                chartDisplayType === ChartDisplayType.PIE ? (
                    <PieChart
                        data={data}
                        plugins={[ChartDataLabels, DoughnutLabel]}
                        options={doughnutOptions as ChartOptions<'pie'>}
                    />
                ) : chartDisplayType === ChartDisplayType.DOUGHNUT ? (
                    <DoughnutChart
                        data={data}
                        plugins={[ChartDataLabels, DoughnutLabel]}
                        options={doughnutOptions as ChartOptions<'doughnut'>}
                    />
                ) : (
                    <Bar
                        data={data}
                        plugins={[ChartDataLabels, DoughnutLabel]}
                        options={doughnutOptions as ChartOptions<'bar'>}
                    />
                )
            ) : (
                <ChartEmptyState chartType={chartDisplayType} />
            )}
        </>
    );
};

export default CategoricalFieldInstanceChart;
