import React from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import { useSolutionSiteContext } from '../../../hooks';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import { SolutionSitePageWidgetUrlExpression } from '../../CommonWidgetComponents';
import type EmbedWidgetConfiguration from '../EmbedWidgetConfiguration';
import { EmbedWidgetType } from '../EmbedWidgetConfiguration';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Field, Input, Radio, RadioGroup } from '@tonkean/infrastructure';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const EditorWrapper = styled.div`
    padding: 16px;
    padding-top: 0;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-hight: 10px;
    color: ${Theme.colors.gray_700};
    min-width: 60px;
    margin-bottom: 8px;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 16px 0;
`;

const SectionTitle = styled(ConfigurationSectionTitle)`
    margin-left: 16px;
`;

const HtmlFieldWrapper = styled.div`
    margin-top: 16px;
`;

const SolutionSitePageEmbedWidgetEditor: React.FC<ItemWidgetEditorProps<EmbedWidgetConfiguration>> = ({
    configuration,
}) => {
    const { solutionSite } = useSolutionSiteContext();
    const [entityVersionType] = useGetStateParams<[WorkflowVersionType]>('env');

    return (
        <>
            <SectionTitle>Embed Details</SectionTitle>
            <EditorWrapper>
                <RadioGroup name="configuration.embed.type" direction="row" size={InputSize.MEDIUM}>
                    <Radio value={EmbedWidgetType.URL} dataAutomation="embed-wodget-editor-type-url">
                        Url
                    </Radio>
                    <Radio value={EmbedWidgetType.HTML} dataAutomation="embed-wodget-editor-type-html">
                        HTML
                    </Radio>
                </RadioGroup>
                {configuration.embed.type === EmbedWidgetType.URL && (
                    <Field inlineError>
                        <SolutionSitePageWidgetUrlExpression
                            dataAutomation="solution-site-page-embed-widget-editor-url-input"
                            name="configuration.embed.url"
                            placeholder="Enter Url"
                        />
                    </Field>
                )}
                {configuration.embed.type === EmbedWidgetType.HTML && (
                    <HtmlFieldWrapper>
                        <Field inlineError>
                            <FormikTonkeanExpression
                                placeholder="HTML"
                                name="configuration.embed.html"
                                workflowFolderId={solutionSite.workflowFolderId}
                                entityVersionType={entityVersionType}
                                dataAutomation="solution-site-page-embed-widget-editor-html-input"
                                modTextArea
                                globalExpressionOnly
                            />
                        </Field>
                    </HtmlFieldWrapper>
                )}
                <Field inlineError>
                    <Wrapper>
                        <Label>Height</Label>
                        <Input
                            title="height"
                            placeholder="Height"
                            name="configuration.embed.height"
                            autoComplete="off"
                            data-automation="solution-site-page-embed-widget-editor-height-input"
                        />
                    </Wrapper>
                </Field>
            </EditorWrapper>
        </>
    );
};

export default SolutionSitePageEmbedWidgetEditor;
