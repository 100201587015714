import React, { useMemo, useRef } from 'react';
import type { GroupBase } from 'react-select';
import type Select from 'react-select/base';

import type { FieldsSelectorGroupSummaryBase } from './FieldsSelectorGroupSummaryBase';
import type { SingleFieldConfiguration } from './SingleFieldConfiguration';

import { FieldTypeIcon } from '@tonkean/fields';
import { TnkSelect } from '@tonkean/infrastructure';
import type {
    FieldDefinition,
    FieldType,
    Initiative,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

export interface WidgetFieldPackage {
    groupId: TonkeanId<TonkeanType.GROUP>;
    fieldDefinition: FieldDefinition;
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    environmentIsActive?: boolean;
}

export interface SingleFieldConfigurationWithDefinition extends SingleFieldConfiguration {
    package: WidgetFieldPackage | undefined;
    key: string;
}

export interface SingleFieldChanged {
    package: WidgetFieldPackage;
    newValue: any;
}

interface Label {
    fieldDefinition: FieldDefinition;
    value: string;
    label: string;
    icon: React.ReactElement;
    fieldType: FieldType;
}

interface Props {
    fieldDefinitions: FieldDefinition[];
    groups: FieldsSelectorGroupSummaryBase[] | undefined;
    groupByPredicate?: (item: Label) => string;
    selectedField?: SingleFieldConfigurationWithDefinition;
    size?: InputComponentSizes;

    addFieldToConfiguration(fieldId: FieldDefinition): void;
}

const FieldSelector: React.FC<Props> = ({
    addFieldToConfiguration,
    fieldDefinitions,
    groups,
    groupByPredicate,
    selectedField,
    size,
}) => {
    const options = useMemo<Label[] | GroupBase<Label>[]>(() => {
        const values = fieldDefinitions.map((fieldDefinition) => ({
            fieldDefinition,
            label: fieldDefinition.name,
            value: fieldDefinition.id,
            icon: <FieldTypeIcon fieldType={fieldDefinition.fieldType} />,
            fieldType: fieldDefinition.fieldType,
        }));

        if (!groups || !groupByPredicate) {
            return values;
        }

        const fieldDefinitionsGroupMap = utils.groupBy(values, groupByPredicate);

        return groups
            .sort((first, second) => second.orderNumber - first.orderNumber)
            .map((group) => ({
                label: group.name,
                options: fieldDefinitionsGroupMap[group.getGroupKey()] ?? [],
            }))
            .filter((group) => !!group.options.length);
    }, [fieldDefinitions, groupByPredicate, groups]);

    const selectedOption = useMemo<Label | undefined>(() => {
        let fieldDefinition;
        if (selectedField && (fieldDefinition = fieldDefinitions?.find((fide) => fide.id === selectedField.key))) {
            return {
                fieldDefinition,
                label: fieldDefinition.name,
                value: fieldDefinition.id,
                icon: <FieldTypeIcon fieldType={fieldDefinition.fieldType} />,
                fieldType: fieldDefinition.fieldType,
            };
        }

        return undefined;
    }, [fieldDefinitions, selectedField]);

    const selectRef = useRef<Select<Label>>(null);

    return (
        <TnkSelect
            dataAutomation="field-widget-add-field-add-field"
            options={options}
            value={selectedOption}
            onChange={(newValue) => {
                if (newValue) {
                    addFieldToConfiguration(newValue.fieldDefinition);
                    setTimeout(() => {
                        selectRef.current?.clearValue();
                    });
                }
            }}
            ref={selectRef}
            size={size}
        />
    );
};
export default React.memo(FieldSelector);
