import React from 'react';
import styled from 'styled-components';

import { BoxContainer, IntegrationBox, IntegrationNameContainer } from './ProjectIntegrationBoxCommons';

import { TextEllipsis, Tooltip } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { ProjectIntegrationSummary } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Dot = styled.div<{ isValid: boolean }>`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 4px 4px 0 auto;

    background-color: ${({ isValid }) =>
        isValid ? `${Theme.current.palette.mainColors.success}` : `${Theme.current.palette.mainColors.error}`};
`;

const CenteredProjectIntegrationIcon = styled(ProjectIntegrationIcon)`
    margin: 7px auto 0 auto;
`;

const BoxWithDotIndicator = styled(IntegrationBox)`
    grid-template-rows: 10px auto;
`;

interface Props {
    projectIntegration: ProjectIntegrationSummary;
    darkerBackground?: boolean;
    onClick: (projectIntegration: ProjectIntegrationSummary) => void;
}

const ProjectIntegrationBox: React.FC<Props> = ({ projectIntegration, darkerBackground, onClick }) => {
    return (
        <BoxContainer data-automation={`project-integration-box-data-actions-${projectIntegration.displayName}`}>
            <Tooltip content={projectIntegration.displayName}>
                <BoxWithDotIndicator darkerBackground={darkerBackground} onClick={() => onClick(projectIntegration)}>
                    <Dot isValid={projectIntegration.valid} />
                    <CenteredProjectIntegrationIcon projectIntegrationId={projectIntegration.id} width={25} />
                </BoxWithDotIndicator>
            </Tooltip>
            <IntegrationNameContainer>
                <TextEllipsis numberOfLines={1} tooltip>
                    {projectIntegration.displayName}
                </TextEllipsis>
            </IntegrationNameContainer>
        </BoxContainer>
    );
};

export default ProjectIntegrationBox;
