import { useAngularService } from 'angulareact';
import React from 'react';

import InfoBox from './InfoBox';
import { ReactComponent as LockIcon } from '../../../../../../../images/icons/lock.svg';

import { IconSvg } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    selectedProjectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    hasError?: boolean;
}

const InaccessibleDatasourceIndication: React.FC<Props> = ({ selectedProjectIntegrationId, hasError }) => {
    const $stateParams = useAngularService('$stateParams');
    const projectManager = useAngularService('projectManager');
    const groupId = $stateParams?.g;
    return (
        <InfoBox hasError={hasError}>
            <IconSvg as={LockIcon} size={22} color={Theme.colors.gray_500} />
            <span>
                This data source is inaccessible by modules under this solution (require data source admin).
                <ClickableLink
                    state="product.projectIntegrationPage"
                    params={{
                        fromName: projectManager.groupsMap?.[groupId]?.name || 'Module Editor',
                        fromState: 'product.workerEditor',
                        fromStateParams: { ...$stateParams },
                        enterpriseComponentId: selectedProjectIntegrationId,
                        page: ProjectIntegrationPageMenuItemType.SOLUTIONS_ACCESS,
                    }}
                >
                    Edit accessibility
                </ClickableLink>
            </span>
        </InfoBox>
    );
};

export default InaccessibleDatasourceIndication;
