import { Button } from '@udecode/plate';
import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { analyticsWrapper } from '@tonkean/analytics';
import { angularToReact } from '@tonkean/angular-components';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { DragAndDropFiles, LoadingCircle } from '@tonkean/infrastructure';
import { ImportFileIcon } from '@tonkean/svg';
import type { TonkeanUploadedFile, UpdateForm, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';

const SubmitFormButton = angularToReact<{
    label?: string;
    primaryColor: string;
    secondaryColor: string;
    buttonsColor: string;
    formFinished: boolean;

    onSubmit(): Promise<any>;
}>('tnk-submit-form-button');

const maxFilesSizeMB = 15;

const FormContent = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
`;

const FormFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    font-size: ${FontSize.LARGE_16};

    &:first-child {
        margin-right: 5px;
    }
`;

const BackButton = styled(Button)<{ primaryColor: string }>`
    cursor: pointer;
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid ${({ primaryColor }) => primaryColor};
    color: ${({ primaryColor }) => primaryColor};
    background-color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
`;

const ButtonsWrapper = styled.div<{ multipleButtons: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${({ multipleButtons }) => (multipleButtons ? 'space-between' : 'flex-end')};
`;

const ErrorMessage = styled.div`
    color: ${Theme.colors.error};
    display: flex;
    max-width: 100%;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    margin-left: 4px;
`;

interface Props {
    form?: UpdateForm;
    primaryColor: string;
    secondaryColor: string;
    buttonsColor: string;
    formFinished: boolean;
    isInPreview: boolean;
    canSkip: boolean;
    initiativeId: string;
    customTriggerId: string;
    workflowVersionType: WorkflowVersionType;

    onSubmit(
        formIndex: number,
        fields?: [],
        ignoreNonRestrictingErrors?: boolean,
        files?: TonkeanUploadedFile[],
    ): Promise<unknown>;

    workerRunId: string;
    formIndex: number;
    onBackClicked: () => void;
    showBack?: boolean;
    submittedFiles?: TonkeanUploadedFile[];
}

const UploadFilesForm: React.FC<Props> = ({
    form,
    primaryColor,
    secondaryColor,
    buttonsColor,
    formFinished,
    isInPreview,
    canSkip,
    initiativeId,
    customTriggerId,
    workflowVersionType,
    onSubmit: parentFormSubmit,
    workerRunId,
    formIndex,
    onBackClicked,
    showBack,
    submittedFiles = [],
}) => {
    const [files, setFiles] = useState<TonkeanUploadedFile[]>(submittedFiles);
    const [errorText, setErrorText] = useState<string | undefined>();
    const [, uploadFiles] = useLazyTonkeanService('uploadFilesFromForm');
    const $state = useAngularService('$state');
    const projectId = $state.params.projectId;
    const [backLoading, setBackLoading] = useState<boolean>(false);

    const handleBackClicked = useCallback(() => {
        setBackLoading(true);
        onBackClicked();
    }, [onBackClicked]);

    const onSubmit = useCallback(async () => {
        let uploadPromise;
        if (files?.length === 0) {
            if (canSkip) {
                uploadPromise = Promise.resolve();
            } else {
                setErrorText('Add files before submitting form');
                return;
            }
        } else {
            if (!form?.group) {
                setErrorText('form must have group in order to use upload files form');
                return;
            }

            // If there are submittedFiles we don't upload them again and only call the parent onSubmit method
            if (submittedFiles?.length > 0) {
                parentFormSubmit(formIndex, [], true, files);
                return;
            }

            uploadPromise = uploadFiles(
                initiativeId,
                customTriggerId || '',
                workerRunId || '',
                workflowVersionType,
                form.project?.id || projectId,
                form?.group?.id,
                form?.id,
                files,
            );
        }
        setErrorText('');

        await uploadPromise;
        return parentFormSubmit(formIndex, [], true, files);
    }, [
        files,
        canSkip,
        form?.group,
        form?.project?.id,
        form?.id,
        submittedFiles,
        uploadFiles,
        initiativeId,
        customTriggerId,
        workerRunId,
        workflowVersionType,
        projectId,
        parentFormSubmit,
        formIndex,
    ]);

    const $rootScope = useAngularService('$rootScope');
    const [pageLoadTime, formLoadEventSubmitted] = useAngularWatch(
        () => $rootScope.pageLoadTime,
        () => $rootScope.formLoadEventSubmitted,
    );

    useEffect(() => {
        if (!form) return;

        if (formLoadEventSubmitted) return;

        const now = Date.now();

        analyticsWrapper.track('form-loaded', {
            formId: form.id,
            groupId: form.group?.id,
            formLoadTime: now - pageLoadTime,
            formType: form.formType,
            formQuestionType: form.formQuestionType,
        });

        $rootScope.formLoadEventSubmitted = true;
    }, [pageLoadTime, form, formLoadEventSubmitted, $rootScope]);

    return (
        <div>
            <FormContent data-automation="upload-file-form-content">
                <DragAndDropFiles
                    mainText="Drag files here to upload"
                    dragAndDropLogo={<ImportFileIcon />}
                    isImageUpload={false}
                    existingFiles={files}
                    setExistingFiles={setFiles}
                    setErrorText={setErrorText}
                    maxFileSizeMB={maxFilesSizeMB}
                    isInPreview={isInPreview || submittedFiles?.length > 0}
                    isError={!!errorText}
                />
            </FormContent>
            {errorText && (
                <ErrorMessage data-automation="upload-file-form-error-message-text">{errorText}</ErrorMessage>
            )}
            {isInPreview || (
                <FormFooter>
                    <ButtonsWrapper multipleButtons={!!showBack}>
                        {showBack && (
                            <BackButton
                                primaryColor={buttonsColor}
                                onClick={handleBackClicked}
                                disabled={backLoading}
                                data-automation="sequence-back-button"
                            >
                                Back
                                {backLoading && <StyledLoadingCircle />}
                            </BackButton>
                        )}

                        <SubmitFormButton
                            label={
                                form?.definition?.overrideSubmitFormButtonLabel ||
                                form?.definition?.submitFormButtonLabel
                            }
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            buttonsColor={buttonsColor}
                            formFinished={formFinished}
                            onSubmit={onSubmit}
                        />
                    </ButtonsWrapper>
                </FormFooter>
            )}
        </div>
    );
};

export default UploadFilesForm;
