import React from 'react';
import DefaultHistoryReason from './DefaultHistoryReason';
import CustomTriggerHistoryReason from './CustomTriggerHistoryReason';
import FieldRangeChangedHistoryReason from './FieldRangeChangedHistoryReason';
import ExternalChangedHistoryReason from './ExternalChangedHistoryReason';
import DateHistoryReason from './DateHistoryReason';

function ReasonComponentFactory() {
    const self = {};
    const dueDateReasonConfig = {
        template: DateHistoryReason,
        prepare(props) {
            props.fieldName = 'dueDate';
            props.fieldDisplayName = 'Due date';
        },
    };

    const parentDueDateReasonConfig = {
        template: DateHistoryReason,
        prepare(props) {
            props.fieldName = 'parentDueDate';
            props.fieldDisplayName = 'Parent Due date';
        },
    };

    const etaReasonConfig = {
        template: DateHistoryReason,
        prepare(props) {
            props.fieldName = 'eta';
            props.fieldDisplayName = 'ETA';
        },
    };

    const parentEtaReasonConfig = {
        template: DateHistoryReason,
        prepare(props) {
            props.fieldName = 'parentEta';
            props.fieldDisplayName = "Parent's ETA";
        },
    };

    self.config = {
        DUE_DATE: dueDateReasonConfig,
        DUE_DATE_PAST: dueDateReasonConfig,
        DUE_DATE_EXACT: dueDateReasonConfig,
        DUE_DATE_FUTURE: dueDateReasonConfig,
        PARENT_DUE_DATE: parentDueDateReasonConfig,
        PARENT_DUE_DATE_PAST: parentDueDateReasonConfig,
        PARENT_DUE_DATE_EXACT: parentDueDateReasonConfig,
        PARENT_DUE_DATE_FUTURE: parentDueDateReasonConfig,
        ETA: etaReasonConfig,
        ETA_PAST: etaReasonConfig,
        ETA_EXACT: etaReasonConfig,
        ETA_FUTURE: etaReasonConfig,
        PARENT_ETA: parentEtaReasonConfig,
        PARENT_ETA_PAST: parentEtaReasonConfig,
        PARENT_ETA_EXACT: parentEtaReasonConfig,
        PARENT_ETA_FUTURE: parentEtaReasonConfig,
        EXTERNAL_DATE: {
            template: DateHistoryReason,
            prepare(props) {
                props.fieldName = 'externalDate';
                if (props.item.reasonMetadata) {
                    const fieldName = props.item.reasonMetadata['externalDateFieldName'];
                    const externalSourceName = props.item.reasonMetadata['externalSourceName'];
                    props.fieldDisplayName = fieldName;
                    if (externalSourceName) {
                        props.fieldDisplayName += ` in ${externalSourceName}`;
                    }
                }
            },
        },
        INITIATIVE_FIELD: {
            template: DateHistoryReason,
            prepare(props) {
                props.fieldName = 'initiativeDate';
                if (props.item.reasonMetadata) {
                    props.fieldDisplayName = `field ${props.item.reasonMetadata['initiativeDateFieldName']}`;
                }
            },
        },
        EXTERNAL_CHANGED: {
            template: ExternalChangedHistoryReason,
        },
        FIELD_VALUE_RANGE_CHANGED: {
            template: FieldRangeChangedHistoryReason,
        },
        CUSTOM_TRIGGER: {
            template: CustomTriggerHistoryReason,
        },
    };

    self.defaultTemplate = DefaultHistoryReason;

    self.get = function (reason, props) {
        let template = this.defaultTemplate;
        const reasonConfig = this.config[reason];
        if (reasonConfig) {
            if (reasonConfig.template) {
                template = reasonConfig.template;
            }

            if (reasonConfig.prepare) {
                reasonConfig.prepare(props);
            }
        }

        return React.createElement(template, props);
    };

    return self;
}

const instance = new ReasonComponentFactory();
export { instance as ReasonComponentFactory };
