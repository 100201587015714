import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import DraggableStateRow from './DraggableStateRow';
import StateLoadingRow from './StateLoadingRow';
import type StateSectionsFormikValues from '../entities/StateSectionsFormikValues';
import type { StateData } from '../entities/StateSectionsFormikValues';
import StatusListDataContext from '../StatusListDataContext';
import getNewStatus from '../utils/getNewStatus';

import { H4, Placeholder, useFormikField } from '@tonkean/infrastructure';
import type { State } from '@tonkean/tonkean-entities';
import { InitiativeStatus } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { range } from '@tonkean/utils';

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const Section = styled.div`
    margin: 0 8px;
`;

const Separator = styled.hr`
    width: 100%;
`;

// Drop zone is needed to allow dropping items between sections if the height of the section is 0 then the drop zone is not working
const DroppableZone = styled.div`
    min-height: 10px;
`;

interface Props {
    name: string;
    title: string;
    defaultStatusType: InitiativeStatus;
    typesToInclude?: Set<InitiativeStatus>;
    hideTypeSelection?: boolean;
}

const StatusSection: React.FC<Props> = ({ title, name, defaultStatusType, typesToInclude, hideTypeSelection }) => {
    const { values, setFieldValue } = useFormikContext<StateSectionsFormikValues>();
    const { loading, viewOnly } = useContext(StatusListDataContext);

    const { value: relevantStatuses } = useFormikField<StateData[]>(name);
    const inputRefs = useRef(relevantStatuses.map(() => React.createRef<HTMLInputElement>()));

    useEffect(() => {
        // Update refs when items change
        inputRefs.current = relevantStatuses.map(() => React.createRef());
    }, [relevantStatuses]);

    const getDeleteDisabledTooltip = (status: State) => {
        switch (status.type) {
            case InitiativeStatus.INTAKE: {
                const isLastIntakeStatus =
                    relevantStatuses.filter(({ state: { type, id } }) => type === InitiativeStatus.INTAKE).length === 1;

                if (isLastIntakeStatus) {
                    return "You must have at least one 'Intake' status";
                } else {
                    return undefined;
                }
            }
            case InitiativeStatus.DONE: {
                const isLastDoneStatus =
                    relevantStatuses.filter(({ state: { type } }) => type === InitiativeStatus.DONE).length === 1;

                if (isLastDoneStatus) {
                    return "You must have at least one 'Done' status";
                } else {
                    return undefined;
                }
            }
            default: {
                return undefined;
            }
        }
    };

    const getUpdateTypeDisabledTooltip = (status: State) => {
        switch (status.type) {
            case InitiativeStatus.DONE: {
                const isLastDoneStatus =
                    relevantStatuses.filter(({ state: { type } }) => type === InitiativeStatus.DONE).length === 1;

                if (isLastDoneStatus) {
                    return "You must have at least one 'Done' status";
                } else {
                    return undefined;
                }
            }
            default: {
                return undefined;
            }
        }
    };

    return (
        <React.Fragment>
            <Section>
                <TitleWrapper>
                    <H4>{title}</H4>
                    {loading && <Placeholder $width="75px" $height="12px" />}

                    {!loading && (
                        <ClickableLink
                            data-automation="status-selection-add-status-button"
                            onClick={() => {
                                const newState = getNewStatus(relevantStatuses.length + 1, defaultStatusType);

                                setFieldValue(name, [...relevantStatuses, newState]);
                                setTimeout(() => {
                                    const ref = inputRefs.current[inputRefs.current.length - 1]?.current;

                                    ref?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                    ref?.focus();
                                }, 100);
                            }}
                            disabled={!!viewOnly}
                            highlight
                        >
                            <b>+ Add Status</b>
                        </ClickableLink>
                    )}
                </TitleWrapper>

                {loading && range(5).map((index) => <StateLoadingRow key={index} />)}

                <Droppable droppableId={name}>
                    {(provided) => (
                        <DroppableZone {...provided.droppableProps} ref={provided.innerRef}>
                            {!loading &&
                                relevantStatuses.map(({ state, formikId }, index) => {
                                    const notDeletableTooltip = getDeleteDisabledTooltip(state);
                                    const notAllowToUpdateTypeTooltip = getUpdateTypeDisabledTooltip(state);
                                    const allStatuses = [
                                        ...values.intakeStatuses,
                                        ...values.triageAndCoordinationStatuses,
                                    ];

                                    const hasDuplicateName =
                                        allStatuses.filter(({ state: { label } }) => label === state.label).length > 1;

                                    const stateName = `${name}.[${index}].state`;

                                    return (
                                        <DraggableStateRow
                                            name={stateName}
                                            typesToInclude={typesToInclude}
                                            hideTypeSelection={hideTypeSelection}
                                            formikId={formikId}
                                            index={index}
                                            key={formikId}
                                            onDelete={() => {
                                                const updatedStatesList = relevantStatuses.filter(
                                                    (stateData) => stateData.formikId !== formikId,
                                                );

                                                setFieldValue(name, updatedStatesList);

                                                return Promise.resolve();
                                            }}
                                            inputRef={inputRefs?.current[index]}
                                            isErrored={hasDuplicateName}
                                            forceDisableDelete={!!notDeletableTooltip}
                                            deleteTooltip={notDeletableTooltip}
                                            forceDisableUpdateType={!!notAllowToUpdateTypeTooltip}
                                            updateTypeTooltip={notAllowToUpdateTypeTooltip}
                                        />
                                    );
                                })}
                            {provided.placeholder}
                        </DroppableZone>
                    )}
                </Droppable>
            </Section>

            <Separator />
        </React.Fragment>
    );
};
export default StatusSection;
