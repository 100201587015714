import template from './tnkSendNotificationLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSendNotificationLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        overrideTitle: '@',
        overrideActionType: '@',
        onActionsChanged: '&',
        previewEvaluationSource: '<',
    },
    template,
    controller: 'SendNotificationLogicConfigurationCtrl',
});
