import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { angularToReact } from '@tonkean/angular-components';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { CloseIcon, Modal, ModalBody } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

const FieldInspect = angularToReact<{
    projectIntegration: ProjectIntegration;
    externalType: string;
    exampleEntity: any;
    viewOnly: boolean;
    onlyUpdatable: boolean;
}>('tnk-field-inspect');

const Wrapper = styled(ModalBody)`
    .field-inspect-container {
        padding-right: 0;
    }
`;

interface Props {
    externalActivityId: string;
    entityType: string;
    projectIntegration: ProjectIntegration;
    shown: boolean;
    onClose(): void;
}

const SingleExternalActivityInspect: React.FC<Props> = ({
    entityType,
    externalActivityId,
    shown,
    onClose,
    projectIntegration,
}) => {
    const tonkeanService = useAngularService('tonkeanService');
    const projectManager = useAngularService('projectManager');

    const [{ data, error, loading }, getExternalActivityById] = useLazyAsyncMethod(
        tonkeanService,
        'getExternalActivityById',
    );

    useEffect(() => {
        if (shown) {
            getExternalActivityById(projectManager.project.id, projectIntegration.id, externalActivityId, true);
        }
    }, [externalActivityId, getExternalActivityById, projectIntegration.id, projectManager.project.id, shown]);

    return (
        <Modal open={shown} onClose={onClose}>
            <CloseIcon />
            {loading && <div className="loading-large" />}
            {data && (
                <Wrapper>
                    <FieldInspect
                        exampleEntity={data}
                        projectIntegration={projectIntegration}
                        externalType={entityType}
                        onlyUpdatable={false}
                        static-data
                        viewOnly
                    />
                </Wrapper>
            )}
            {error && (
                <div className="common-size-xxs common-color-danger margin-top margin-bottom" ng-if="data.error">
                    Failed to load item preview.
                </div>
            )}
        </Modal>
    );
};

export default SingleExternalActivityInspect;
