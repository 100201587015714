<div class="sync-inner-matched-entity-logic-configuration">
    <div class="margin-bottom">Select a Matched Entity that will be monitored:</div>

    <tnk-field-selector
        selected-field-identifier="data.matchedEntityId"
        items-source="COLUMN"
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        display-integration-indication="true"
        special-fields-for-features="['MATCHED_ENTITY_PREVIEW']"
        place-holder="Search matched entity"
        quick-create-matched-entity="true"
        only-id-relation-field="true"
        fields-filter="filterRootFields"
        on-field-selected="onMatchedEntitySelected(selectedField)"
    ></tnk-field-selector>

    <div
        ng-if="
            data.invalidLogics[data.configuredLogic.node.id] &&
            data.invalidLogics[data.configuredLogic.node.id].noMatchedEntity
        "
        class="margin-top-xs common-color-danger"
    >
        {{ data.invalidLogics[data.configuredLogic.node.id].noMatchedEntity }}
    </div>

    <div class="margin-top">
        <tnk-preview-matched-entity-items-modal-trigger
            workflow-version-id="data.workflowVersionId"
            matched-entity-id="data.matchedEntityId"
            initiative-id="ctm.workflowVersionIdToExampleItemsMap[data.workflowVersionId].root"
        ></tnk-preview-matched-entity-items-modal-trigger>

        <div class="margin-top">
            <tnk-radio-button
                radio-id="sync-inner-matched-entity-logic-import-sync"
                radio-name="sync-inner-matched-entity-logic-import-type"
                radio-value="sync"
                model="data.importMethod"
                on-click="onImportOnceCheckboxChanged('sync')"
                on-click-param="('sync')"
                is-required="true"
            >
                Continuously sync
            </tnk-radio-button>

            <tnk-radio-button
                radio-id="sync-inner-matched-entity-logic-import-once"
                radio-name="sync-inner-matched-entity-logic-import-type"
                radio-value="importOnce"
                model="data.importMethod"
                on-click="onImportOnceCheckboxChanged('importOnce')"
                on-click-param="('importOnce')"
                is-required="true"
            >
                Import only once
            </tnk-radio-button>

            <div
                ng-if="data.importMethod === 'importOnce'"
                class="margin-top-20"
            >
                <div class="margin-bottom-xs">Max amount of items to import:</div>
                <div class="margin-bottom-xs common-color-grey small">This should not exceed 10,000 items.</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="Max amount of items to import"
                    saved-original-expression="data.limitExpression.originalExpression"
                    saved-evaluated-expression="data.limitExpression.evaluatedExpression"
                    on-tonkean-expression-changed="
                        onLimitChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                    preview-evaluation-source="data.previewEvaluationSource"
                ></tnk-tonkean-expression>
            </div>
        </div>
    </div>
</div>
