import React, { useContext } from 'react';

import { ExpressionBlock } from '@tonkean/editor';
import { TonkeanExpressionEditorContext } from '@tonkean/infrastructure';
import type { ServerFormulaExpressionVariableNode } from '@tonkean/tonkean-entities';

interface Props {
    expression: ServerFormulaExpressionVariableNode;
    children: React.ReactNode;
}

const VariableBlock: React.FC<Props> = ({ expression, children }) => {
    const { fields: fieldsContext } = useContext(TonkeanExpressionEditorContext);
    return (
        <ExpressionBlock type="variable">
            {fieldsContext?.[expression.name]?.name || 'Missing field'}
            {children}
        </ExpressionBlock>
    );
};

export default VariableBlock;
