<div class="common-close-btn-container padding-normal">
    <span class="common-close-btn mod-xs" ng-click="$dismiss()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </span>

    <div class="common-title">Edit Hyperlink</div>

    <div class="margin-bottom margin-top-lg margin-bottom">
        <div class="flex-vmiddle margin-bottom">
            <input ng-model="data.url" type="text" placeholder="Url..." class="form-control" />

            <tnk-tabs-field-selector
                class="tonkean-expression-fields-btn"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.logicId }}"
                tooltip="Insert field"
                on-field-selected="onVariableSelected('url', selectedField)"
            ></tnk-tabs-field-selector>
        </div>

        <div class="flex-vmiddle">
            <input ng-model="data.label" type="text" placeholder="Label..." class="form-control" />

            <tnk-tabs-field-selector
                class="tonkean-expression-fields-btn"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.logicId }}"
                tooltip="Insert field"
                on-field-selected="onVariableSelected('label', selectedField)"
            ></tnk-tabs-field-selector>
        </div>
    </div>

    <button ng-click="submit()" class="btn btn-primary">submit</button>
</div>
