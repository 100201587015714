<div class="on-boarding-widget-wrapper" ng-if="obm.metadata.onBoardingVisible">
    <div class="on-boarding-widget-container" ng-class="{ finishing: finishing }">
        <div class="on-boarding-widget pointer" ng-click="openOnBoardingModal()">
            <!-- Steps -->
            <div class="flex-vmiddle mod-justify-space-around pointer margin-bottom-xs margin-top-xs">
                <span ng-repeat="step in obm.metadata.stepsList" class="flex-vmiddle relative">
                    <!-- Step check mark (if completed) -->
                    <span
                        class="svg-icon-xxs svg-icon-vmiddle flex-no-shrink common-color-white"
                        ng-if="step.completed"
                    >
                        <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                    </span>
                    <!-- Step name -->
                    <span>{{ step.shortTitle }}</span>
                </span>
            </div>
            <!-- Progress bar parts -->
            <div
                class="on-boarding-widget-progressbar"
                ng-style="{ 'margin-left': -1 * (obm.metadata.stepsList.length - 1) + 'px' }"
            >
                <span
                    ng-repeat="step in obm.metadata.stepsList"
                    ng-style="{ width: (1 / obm.metadata.stepsList.length) * 100 + '%' }"
                    ng-class="{
                        'mod-completed mod-current': step.completed,
                        'mod-current': obm.metadata.currentStep && obm.metadata.currentStep.name === step.name
                    }"
                ></span>
            </div>
        </div>
    </div>
</div>
