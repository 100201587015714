import React from 'react';

import {
    ActionsWidgetIcon,
    AggregationFieldsWidgetIcon,
    AIChatIcon,
    AllFieldsWidgetIcon,
    BasicFieldsWidgetIcon,
    ChartIcon,
    CommentsWidgetIcon,
    DateWidgetIcon,
    DetailedItemsWidgetIcon,
    EmbedIcon,
    FieldManagementWidgetIcon,
    FormulaFieldsWidgetIcon,
    GlobalFieldsWidgetIcon,
    ImageWidgetIcon,
    IncludeInterfaceWidgetIcon,
    InnerItemsWidgetIcon,
    LineItemWidgetIcon,
    ListWidgetIcon,
    ManualFieldsWidgetIcon,
    NoIcon,
    NumberWidgetIcon,
    ProcessMapperWidgetIcon,
    RichTextWidgetIcon,
    SearchWidgetIcon,
    SelectWidgetIcon,
    SeparatorWidgetIcon,
    StatusProgressBarIcon,
    StatusWidgetIcon,
    TextWidgetIcon,
    TimelineWidgetIcon,
    UploadWidgetIcon,
    RichTextEditorInput,
    ModuleItemWidgetIcon,
} from '@tonkean/svg';
import { PresetItemInterfaceWidgetType } from '@tonkean/tonkean-entities';

const widgetIconMapping: Record<PresetItemInterfaceWidgetType, React.ComponentType> = {
    [PresetItemInterfaceWidgetType.FIELD_MANAGEMENT]: () => <FieldManagementWidgetIcon />,
    [PresetItemInterfaceWidgetType.EMPTY_FIELDS]: () => <FieldManagementWidgetIcon />,
    [PresetItemInterfaceWidgetType.BASIC_FIELDS]: () => <BasicFieldsWidgetIcon />,
    [PresetItemInterfaceWidgetType.ALL_FIELDS]: () => <AllFieldsWidgetIcon />,
    [PresetItemInterfaceWidgetType.AGGREGATION_FIELDS]: () => <AggregationFieldsWidgetIcon />,
    [PresetItemInterfaceWidgetType.FORMULA_FIELDS]: () => <FormulaFieldsWidgetIcon />,
    [PresetItemInterfaceWidgetType.MANUAL_FIELDS]: () => <ManualFieldsWidgetIcon />,
    [PresetItemInterfaceWidgetType.GLOBAL_FIELDS]: () => <GlobalFieldsWidgetIcon />,
    [PresetItemInterfaceWidgetType.RICH_TEXT]: () => <RichTextWidgetIcon />,
    [PresetItemInterfaceWidgetType.SEPARATOR]: () => <SeparatorWidgetIcon />,
    [PresetItemInterfaceWidgetType.ACTIONS]: () => <ActionsWidgetIcon />,
    [PresetItemInterfaceWidgetType.INNER_ITEMS]: () => <InnerItemsWidgetIcon />,
    [PresetItemInterfaceWidgetType.COMMENTS]: () => <CommentsWidgetIcon />,
    [PresetItemInterfaceWidgetType.TIMELINE]: () => <TimelineWidgetIcon />,
    [PresetItemInterfaceWidgetType.STATUS_UPDATE]: () => <StatusWidgetIcon />,
    [PresetItemInterfaceWidgetType.MATCHED_ENTITY]: () => <NoIcon />,
    [PresetItemInterfaceWidgetType.STATUS_PROGRESS_BAR]: () => <StatusProgressBarIcon />,
    [PresetItemInterfaceWidgetType.FIELD_CHART]: () => <ChartIcon />,
    [PresetItemInterfaceWidgetType.IMAGE]: () => <ImageWidgetIcon />,
    [PresetItemInterfaceWidgetType.EMBED]: () => <EmbedIcon />,
    [PresetItemInterfaceWidgetType.AI_CHAT]: () => <AIChatIcon />,
    [PresetItemInterfaceWidgetType.QUESTION_TEXT]: () => <TextWidgetIcon />,
    [PresetItemInterfaceWidgetType.QUESTION_NUMBER]: () => <NumberWidgetIcon />,
    [PresetItemInterfaceWidgetType.QUESTION_DATE]: () => <DateWidgetIcon />,
    [PresetItemInterfaceWidgetType.QUESTION_SELECT]: () => <SelectWidgetIcon />,
    [PresetItemInterfaceWidgetType.QUESTION_LIST]: () => <ListWidgetIcon />,
    [PresetItemInterfaceWidgetType.LINE_ITEMS]: () => <LineItemWidgetIcon />,
    [PresetItemInterfaceWidgetType.MODULE_ITEMS]: () => <ModuleItemWidgetIcon />,
    [PresetItemInterfaceWidgetType.RICH_TEXT_EDITOR_INPUT]: () => <RichTextEditorInput />,
    [PresetItemInterfaceWidgetType.DETAILED_ITEMS]: () => <DetailedItemsWidgetIcon />,
    [PresetItemInterfaceWidgetType.UPLOAD]: () => <UploadWidgetIcon />,
    [PresetItemInterfaceWidgetType.PROCESS_MAPPER]: () => <ProcessMapperWidgetIcon />,
    [PresetItemInterfaceWidgetType.SEARCH]: () => <SearchWidgetIcon />,
    [PresetItemInterfaceWidgetType.INCLUDE_INTERFACE]: () => <IncludeInterfaceWidgetIcon />,
};

export default widgetIconMapping;
