import React, { useMemo } from 'react';
import styled from 'styled-components';

import EmbedWidgetLoadingSkeleton from './EmbedWidgetLoadingSkeleton';
import type { ItemInterfacePermission } from '../../../entities';
import { ItemWidget } from '../../../WidgetModule';
import { EmbedWidgetType } from '../EmbedWidgetConfiguration';

import { SafeHtml, StateMessage, useLastDefinedValue } from '@tonkean/infrastructure';
import { EmptyImage } from '@tonkean/svg';
import { isUrlValid } from '@tonkean/utils';

const StyledIframe = styled.iframe`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
`;

interface Props {
    loadingExpressions: boolean;
    permission: ItemInterfacePermission;
    embedContent: string | undefined;
    contentConfigured: boolean;
    embedType: EmbedWidgetType;
    hideWidgetBackground: boolean | undefined;
    height?: number;
}

const EmbedWidgetDisplay: React.FC<Props> = ({
    loadingExpressions,
    permission,
    embedContent,
    contentConfigured,
    embedType,
    hideWidgetBackground,
    height,
}) => {
    const lastEmbedContent = useLastDefinedValue<string | undefined>(embedContent);
    const isContentValid = useMemo(() => {
        if (lastEmbedContent) {
            if (embedType === EmbedWidgetType.URL) {
                return isUrlValid(lastEmbedContent);
            } else {
                return true;
            }
        }

        return false;
    }, [embedType, lastEmbedContent]);

    const loading = loadingExpressions && !lastEmbedContent;
    const showContent = isContentValid && lastEmbedContent && contentConfigured;
    const hideBackground = Boolean(loading || (showContent && hideWidgetBackground));

    return (
        <ItemWidget
            permission={permission}
            noPaddingBody={hideBackground}
            noBorderBody={hideBackground}
            noBackgroundBody={hideBackground}
            itemWidgetBodyHeight={height || 300}
            disableMinHeight
            disableMaxHeight
        >
            {loading ? (
                <EmbedWidgetLoadingSkeleton height={height} />
            ) : (
                <>
                    {showContent && (
                        <>
                            {embedType === EmbedWidgetType.URL ? (
                                <StyledIframe src={lastEmbedContent} title="embed iframe" />
                            ) : (
                                <SafeHtml unsafeHtml={lastEmbedContent} />
                            )}
                        </>
                    )}

                    {!showContent && (
                        <StateMessage icon={<EmptyImage />} title="Embed content not found">
                            The source {embedType} configured for this content might be invalid
                        </StateMessage>
                    )}
                </>
            )}
        </ItemWidget>
    );
};

export default EmbedWidgetDisplay;
