import FieldChartWidget from './FieldChartWidget';
import FieldChartWidgetEditor from './FieldChartWidgetEditor';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';

const FieldChartWidgetPackage: ItemInterfaceWidgetPackage<ItemInterfaceWidgetConfiguration> = {
    displayComponent: FieldChartWidget,
    configurationComponent: FieldChartWidgetEditor,
};

export default FieldChartWidgetPackage;
