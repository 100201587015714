<div class="multiple-field-to-update-editors">
    <div ng-if="data.fieldSelectorWorkflowVersionId">
        <!-- Fields to update selection -->
        <div ng-repeat="configuredField in data.configuredFields" class="field-update-information-configuration">
            <div
                class="margin-top-xs margin-bottom-xs common-color-danger"
                ng-if="data.validationObject[data.validationKeyPrefix + 'fieldToUpdate-nofield-' + $index]"
            >
                {{ data.validationObject[data.validationKeyPrefix + 'fieldToUpdate-nofield-' + $index] }}
            </div>
            <tnk-field-to-update-editor
                logic-id="{{ data.logicId }}"
                field-selector-group-id="{{ data.fieldSelectorGroupId }}"
                field-selector-workflow-version-id="{{ data.fieldSelectorWorkflowVersionId }}"
                expression-group-id="{{ data.expressionGroupId }}"
                expression-workflow-version-id="{{ data.expressionWorkflowVersionId }}"
                is-preselected-field="{{ data.isPreselectedField }}"
                preselected-field-label="{{ data.preselectedFieldLabel }}"
                preselected-field-id="{{ data.preselectedFieldId }}"
                configured-field="configuredField"
                reload-fields="data.reloadFields"
                on-field-removed="onFieldRemoved(configuredField, callbackParam)"
                should-hide-delete-button="data.configuredFields.length === 1 && !data.allowDeleteOfLastField"
                on-field-removed-callback-param="($index)"
                on-configured-field-changed="onConfiguredFieldChanged(configuredField, shouldSaveLogic, callbackParam)"
                special-fields-for-features="data.specialFieldsForFeatures"
                do-not-include-field-ids-set="data.doNotIncludeFieldIdsSet"
                preview-evaluation-source="data.previewEvaluationSource"
                callback-param="$index"
                global-expression-only="data.globalExpressionOnly"
            ></tnk-field-to-update-editor>
            <div
                class="margin-top-xs margin-bottom-xs common-color-danger"
                ng-if="data.validationObject[data.validationKeyPrefix + 'fieldsToUpdate-' + $index]"
            >
                {{ data.validationObject[data.validationKeyPrefix + 'fieldsToUpdate-' + $index] }}
            </div>
        </div>

        <div class="flex-vmiddle">
            <!-- Add new field -->
            <a ng-if="!data.isPreselectedField" ng-click="addFieldToUpdate()">+ Add field to update</a>
            <div
                ng-if="data.extraButtonData.text && data.extraButtonData.trigger && data.extraButtonData.visibility"
                class="common-size-xxxxs margin-left mod-align-end"
            >
                <a
                    ng-class="data.extraButtonData.isRed ? 'common-color-danger' : 'common-color-light-grey'"
                    ng-click="data.extraButtonData.trigger()"
                >
                    {{ data.extraButtonData.text }}
                </a>
            </div>
        </div>
    </div>
    <span ng-if="!data.fieldSelectorWorkflowVersionId" class="loading"></span>
</div>
