import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import { usePreventConcurrentManualReloadFetchManager } from '@tonkean/infrastructure';
import type { SmartConversationMessage, SmartSearchResponse } from '@tonkean/tonkean-entities';

const getItems = (data: SmartConversationMessage<SmartSearchResponse>) => data;

const useFetchSmartSearch = () => {
    const tonkeanService = useAngularService('tonkeanService');

    const [[smartIntakeAssistant], { loading: loadingSmartIntakeAssistant, error: errorSmartIntakeAssistant }] =
        usePreventConcurrentManualReloadFetchManager(tonkeanService, 'smartIntakeAssistant', {
            isSingle: true,
            getItems,
        });

    return useMemo(() => {
        const loading = loadingSmartIntakeAssistant;
        const error = errorSmartIntakeAssistant;

        return { smartIntakeAssistant, loading, error };
    }, [errorSmartIntakeAssistant, loadingSmartIntakeAssistant, smartIntakeAssistant]);
};

export default useFetchSmartSearch;
