import flattenValue from './flattenValue';

import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';

const valueGetter = (value, fieldDefinition: FieldDefinition): unknown => {
    if (fieldDefinition.fieldType === FieldType.Date && value) {
        return new Date(value);
    }

    return flattenValue(value, fieldDefinition.outputMultiValueSeparator);
};

export default valueGetter;
