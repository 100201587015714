import React from 'react';

import SearchWidgetCardsDensityConfigurations from './SearchWidgetCardsDensityConfigurations';
import SearchWidgetItemsList from './SearchWidgetItemsList';
import SearchWidgetResultItem from './SearchWidgetResultItem';
import type SearchWidgetConfiguration from '../SearchWidgetConfiguration';
import type { SearchWidgetCardsDensity } from '../SearchWidgetConfiguration';

import type {
    ItemInterfaceHeaderThemeConfiguration,
    ItemInterfaceWidget,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import type { SearchWidgetResponseItem } from '@tonkean/tonkean-entities';

interface Props {
    widget: ItemInterfaceWidget<SearchWidgetConfiguration>;
    themeConfiguration: ItemInterfaceHeaderThemeConfiguration;
    selectedItems: SearchWidgetResponseItem[];
    searchItemIdToInitiativeId: Record<string, TonkeanId<TonkeanType.INITIATIVE>> | undefined;
    isMobile: boolean;
    cardsDensity: SearchWidgetCardsDensity;
    changeItemSelectedState: (searchItem: SearchWidgetResponseItem, value: boolean) => Promise<unknown>;
}

const SearchWidgetSelectedOnlyState: React.FC<Props> = ({
    widget,
    themeConfiguration,
    selectedItems,
    searchItemIdToInitiativeId,
    isMobile,
    cardsDensity,
    changeItemSelectedState,
}) => {
    const cardsDensityConfiguration = SearchWidgetCardsDensityConfigurations[cardsDensity];

    return (
        <SearchWidgetItemsList
            data-automation="search-widget-selected-items-only-list"
            $itemsGap={cardsDensityConfiguration.itemsGap}
            $cardsDensity={cardsDensity}
        >
            {selectedItems.map((item) => (
                <SearchWidgetResultItem
                    key={item.id}
                    item={item}
                    editableResultFieldConfiguration={widget.configuration.editableResultField}
                    customColor={themeConfiguration.headerBackgroundColor}
                    associatedInitiativeId={searchItemIdToInitiativeId?.[item.id]}
                    isMobile={isMobile}
                    cardsDensity={cardsDensity}
                    imagePlaceholderMode={widget.configuration.imagePlaceholderMode}
                    changeItemSelectedState={changeItemSelectedState}
                    projectId={widget.projectId}
                    imageInputType={widget.configuration.imageInputType}
                    searchEntityProjectIntegrationId={widget.configuration.searchEntityProjectIntegrationId}
                    imageStreamSelectedActionId={widget.configuration.imageStreamSelectedActionId}
                    selected
                />
            ))}
        </SearchWidgetItemsList>
    );
};

export default SearchWidgetSelectedOnlyState;
