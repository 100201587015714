import template from './tnkWorkerOutline.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerOutline', {
    bindings: {
        // The sync object holds properties that populate through the tree: selectedLogicId.
        // We use a single object so no change will trigger full tree on change cycle.
        // Angular will stop us after 10 levels are reached so this is our solution.
        syncObject: '<',
        nodeTree: '<',
        isLoading: '<',
        groupId: '<',
        titleLabel: '<',
        selectedTabKey: '<',
        openActiveWorkflowVersion: '<',
        isOutlineOpen: '<',
        showExampleItemSelector: '<', // If true will show selector for example root item.
        workflowVersionId: '@',
        reloadConfiguration: '<',
        selectNode: '&', // A callback function for when a node is selected in the outline tree.
        createNewNode: '&', // A callback function to create a new logic node.
        selectDataSourceNode: '&', // A callback function to select the data source node.
        closePanel: '&', // A callback function to close the panel.
        onIsOpenChanged: '&',
        onTabSelected: '&',
        canModifyBot: '<',
        onOutlineFormSelected: '<',
        editFormId: '<',
        formType: '<',
        registerFormDeletedCallback: '<',
        onOutlineFieldSelected: '<',
        fieldEditorHasPendingChanges: '<',
        onTestWorkflowClicked: '<',
        onRevertWorkflowVersionClicked: '<',
        targetTypeId: '@',
        groupByToggleDefault: '@',
        workflowFolderProjectIntegrationsAccess: '<',
        requestLogicsValidation: '<',
        invalidLogics: '<',
    },
    template,
    controller: 'WorkerOutlineCtrl',
});
