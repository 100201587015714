import cloneDeep from 'lodash.clonedeep';

export const FIELD_CONDITION_TO_API_DEFINITION_MAP = {
    BasicEquals: {
        apiName: 'BasicEquals',
        displayName: 'Equal to',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            number: true,
            list: true,
            boolean: true,
        },
    },
    BasicNotEquals: {
        apiName: 'BasicNotEquals',
        displayName: 'Not equal to',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            number: true,
            list: true,
            boolean: true,
        },
    },
    Equals: {
        apiName: 'Equals',
        displayName: 'Like',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            number: true,
            list: true,
            boolean: true,
        },
    },
    NotEquals: {
        apiName: 'NotEquals',
        displayName: 'Not like',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            number: true,
            list: true,
            boolean: true,
        },
    },
    Contains: {
        apiName: 'Contains',
        displayName: 'Contains',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
        },
    },
    NotContains: {
        apiName: 'NotContains',
        displayName: 'Not contains',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
        },
    },
    Match: {
        apiName: 'Match',
        displayName: 'Match',
        displayValueOnSeparateLine: true,
        allowedTypesSet: {
            string: true,
        },
    },
    NotMatch: {
        apiName: 'NotMatch',
        displayName: 'Does not match',
        displayValueOnSeparateLine: true,
        allowedTypesSet: {
            string: true,
        },
    },
    IsMemberOf: {
        apiName: 'IsMemberOf',
        displayName: 'Is Member Of',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
        },
        typeFamily: 'Business Groups',
    },
    Between: {
        apiName: 'Between',
        displayName: 'Between',
        displayValueOnSeparateLine: false,
        requiresSecondValue: true,
        firstValuePlaceholder: 'From...',
        secondValuePlaceholder: 'To...',
        allowedTypesSet: {
            string: true,
            number: true,
        },
        typeFamily: 'Numbers',
        excludeConditionInOptions(fieldType, valueSelectionType, possibleValues) {
            const notAllowedValueSelectionTypes = ['List', 'Boolean', 'DateExact', 'DateRange', 'DatePeriod'];

            if (possibleValues?.length) {
                return true;
            }

            for (const notAllowedValueSelectionType of notAllowedValueSelectionTypes) {
                if (valueSelectionType === notAllowedValueSelectionType) {
                    return true;
                }
            }

            return false;
        },
    },
    GreaterThan: {
        apiName: 'GreaterThan',
        displayName: 'Greater than',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            number: true,
        },
        typeFamily: 'Numbers',
    },
    LessThan: {
        apiName: 'LessThan',
        displayName: 'Less than',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            number: true,
        },
        typeFamily: 'Numbers',
    },
    Past: {
        apiName: 'Past',
        displayName: 'In the past',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
        requiresSecondValue: true,
        firstValuePlaceholder: '5',
        secondValuePlaceholder: 'Days',
    },
    IncreasedBy: {
        apiName: 'IncreasedBy',
        displayName: 'Increased by',
        displayValueOnSeparateLine: true,
        allowedTypesSet: {
            string: true,
            number: true,
        },
        typeFamily: 'Numbers',
        inputDescriptionString: '%',
        requiresSecondValue: true,
        supportedItemsSourcesSet: {
            COLUMN: true,
        },
        supportsOnlyFieldDefinitions: true,
        changeCondition: true,
    },
    DecreasedBy: {
        apiName: 'DecreasedBy',
        displayName: 'Decreased by',
        displayValueOnSeparateLine: true,
        requiresSecondValue: true,
        allowedTypesSet: {
            string: true,
            number: true,
        },
        typeFamily: 'Numbers',
        inputDescriptionString: '%',
        supportedItemsSourcesSet: {
            COLUMN: true,
        },
        supportsOnlyFieldDefinitions: true,
        changeCondition: true,
    },
    Next: {
        apiName: 'Next',
        displayName: 'In the next',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
        requiresSecondValue: true,
        firstValuePlaceholder: '5',
        secondValuePlaceholder: 'Days',
    },
    Before: {
        apiName: 'Before',
        displayName: 'Before',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
    },
    After: {
        apiName: 'After',
        displayName: 'After',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
    },
    InThis: {
        apiName: 'InThis',
        displayName: 'In this',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
    },
    OlderThan: {
        apiName: 'OlderThan',
        displayName: 'Older than',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
        requiresSecondValue: true,
        firstValuePlaceholder: '5',
        secondValuePlaceholder: 'Days',
    },
    FurtherThan: {
        apiName: 'FurtherThan',
        displayName: 'Further than',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
        requiresSecondValue: true,
        firstValuePlaceholder: '5',
        secondValuePlaceholder: 'Days',
    },
    BeforeNow: {
        apiName: 'BeforeNow',
        displayName: 'Is past',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
        noValueRequired: true,
    },
    AfterNow: {
        apiName: 'AfterNow',
        displayName: 'In the future',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            datetime: true,
        },
        typeFamily: 'Dates',
        noValueRequired: true,
    },
    IsEmpty: {
        apiName: 'IsEmpty',
        displayName: 'Is empty',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            number: true,
            list: true,
            boolean: true,
        },
        noValueRequired: true,
    },
    IsNotEmpty: {
        apiName: 'IsNotEmpty',
        displayName: 'Is not empty',
        displayValueOnSeparateLine: false,
        allowedTypesSet: {
            string: true,
            date: true,
            number: true,
            list: true,
            boolean: true,
        },
        noValueRequired: true,
    },
};

/**
 * Gets the fieldConditionToApiDefinitionMap.
 * @returns {*}
 */
export function getFieldConditionToApiDefinitionMap() {
    return cloneDeep(FIELD_CONDITION_TO_API_DEFINITION_MAP);
}
