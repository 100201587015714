/**
 * A function that calculates the smallest available name, with increasing number suffix.
 *
 * @example
 * const newName = getSmallestAvailableName('Jira', ['Jira', 'Jira (1)', 'GitHub']); // newName will equal 'Jira (2)'
 *
 * @param requestedName the name you wish to use
 * @param existingNames existing names which must not be chosen
 */
function getSmallestAvailableName(requestedName: string, existingNames: string[]): string {
    let count = 0;
    let availableName: string | null = null;
    const existingNamesLowerCased = new Set(existingNames.map((existingName) => existingName.toLowerCase()));
    do {
        let possibleName = requestedName;
        if (count > 0) {
            possibleName = `${possibleName} (${count})`;
        }

        availableName = existingNamesLowerCased.has(possibleName.toLowerCase()) ? null : possibleName;
        count += 1;
    } while (!availableName);

    return availableName;
}

export default getSmallestAvailableName;
