import { useEffect, useMemo } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    InterfaceCTAActionType,
    type InterfaceCTASavedAction,
    type InterfaceCTASavedCustomTriggerAction,
    type InterfaceCTASavedFormAction,
    type TonkeanId,
    type TonkeanType,
    type WorkflowVersionType,
} from '@tonkean/tonkean-entities';

const useLoadCTAActionsEntities = (
    actions: InterfaceCTASavedAction[],
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
    workflowVersionType: WorkflowVersionType,
) => {
    const [
        {
            data: ctaActionsEntitiesSummary,
            error: errorCtaActionsEntitiesSummary,
            loading: loadingCtaActionsEntitiesSummary,
        },
        getCTAActionsEntitiesSummary,
    ] = useLazyTonkeanService('getCTAActionsEntitiesSummary');

    const [
        {
            data: ctaActionsEntitiesSummaryByWorkflowVersionType,
            error: errorCtaActionsEntitiesSummaryByWorkflowVersionType,
            loading: loadingCtaActionsEntitiesSummaryByWorkflowVersionType,
        },
        getCTAActionsEntitiesSummaryByWorkflowVersionType,
    ] = useLazyTonkeanService('getCTAActionsEntitiesSummaryByWorkflowVersionType');

    const formIds = useMemo(() => {
        return actions
            .filter((action): action is InterfaceCTASavedFormAction => action.type === InterfaceCTAActionType.OPEN_FORM)
            .map((action) => action.id);
    }, [actions]);

    const customTriggerIds = useMemo(() => {
        return actions
            .filter(
                (action): action is InterfaceCTASavedCustomTriggerAction =>
                    action.type === InterfaceCTAActionType.TRIGGER_WORKFLOW,
            )
            .map((action) => action.id);
    }, [actions]);

    useEffect(() => {
        if (formIds.length === 0 && customTriggerIds.length === 0) {
            return;
        }

        if (workflowVersionId) {
            if (!ctaActionsEntitiesSummary) {
                getCTAActionsEntitiesSummary(workflowVersionId, formIds, customTriggerIds);
            }
        } else if (!ctaActionsEntitiesSummaryByWorkflowVersionType) {
            getCTAActionsEntitiesSummaryByWorkflowVersionType(workflowVersionType, formIds, customTriggerIds);
        }
    }, [
        formIds,
        customTriggerIds,
        workflowVersionId,
        getCTAActionsEntitiesSummary,
        ctaActionsEntitiesSummary,
        ctaActionsEntitiesSummaryByWorkflowVersionType,
        getCTAActionsEntitiesSummaryByWorkflowVersionType,
        workflowVersionType,
    ]);

    return {
        data: ctaActionsEntitiesSummary || ctaActionsEntitiesSummaryByWorkflowVersionType,
        loading: loadingCtaActionsEntitiesSummary || loadingCtaActionsEntitiesSummaryByWorkflowVersionType,
        error: errorCtaActionsEntitiesSummary || errorCtaActionsEntitiesSummaryByWorkflowVersionType,
    };
};

export default useLoadCTAActionsEntities;
