import React from 'react';
import styled from 'styled-components';

import { ReactComponent as BranchEmptyStateIcon } from '../../../../../../images/icons/branch-empty-state.svg';

import { StateMessage } from '@tonkean/infrastructure';
import { ClickableLink, LinkUnderline } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { StateMessageSize } from '@tonkean/tui-theme/sizes';

const EmptyStateTitle = styled.div`
    font-size: ${FontSize.XLARGE_18};
    font-weight: 500;
    color: ${Theme.colors.gray_500};
    margin-top: 16px;
`;

const EmptyStateDescription = styled.div`
    margin-top: 10px;
    text-align: center;
`;

interface Props {
    logic: any;
}

const HistoryNlpInspectTabEmptyState: React.FC<Props> = ({ logic }) => {
    return (
        <StateMessage
            icon={<BranchEmptyStateIcon />}
            title={<EmptyStateTitle> No available NLP branches were found</EmptyStateTitle>}
            size={StateMessageSize.MEDIUM}
        >
            <EmptyStateDescription>
                <ClickableLink
                    state="product.workerEditor"
                    params={{
                        env: logic.node.workflowVersion.id,
                        lcm: 'configuration',
                        spt: 'logic',
                        l: logic.node.id,
                    }}
                    underline={LinkUnderline.ALWAYS}
                >
                    Go to action configuration
                </ClickableLink>{' '}
                and create NLP branches or
                <div>turn on branches that were manually disabled</div>
            </EmptyStateDescription>
        </StateMessage>
    );
};

export default HistoryNlpInspectTabEmptyState;
