import React, { useCallback } from 'react';

import type { FormSelectorProps } from './TnkFormSelector';
import FormSelector from './TnkFormSelector';

import { useFormikField } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { Form } from '@tonkean/tonkean-entities';

interface Props extends Omit<FormSelectorProps, 'selectedFormId' | 'onFormSelected'> {
    name: string;
}

const FormikFormSelector: React.FC<Props> = ({ name, ...props }) => {
    const { value: formId, setValue: setCreateForm } = useFormikField<TonkeanId<TonkeanType.FORM> | undefined>(name);

    const setCreatedFormIdCallback = useCallback(
        (selectedForm: Form) => {
            setCreateForm(selectedForm?.id as TonkeanId<TonkeanType.FORM> | undefined);
        },
        [setCreateForm],
    );

    return <FormSelector selectedFormId={formId} onFormSelected={setCreatedFormIdCallback} {...props} />;
};

export default FormikFormSelector;
