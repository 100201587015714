enum StatePageType {
    SOLUTIONS_GALLERY = 'SOLUTIONS_GALLERY',
    MODULE_EDITOR = 'MODULE_EDITOR',
    LIVE_REPORT = 'LIVE_REPORT',
    DATA_SOURCES = 'DATA_SOURCES',
    RUNTIME = 'RUNTIME',
    PROJECT_INTEGRATION_PAGE = 'PROJECT_INTEGRATION_PAGE',
    HOME_PAGE = 'HOME_PAGE',
    SOLUTION_SITE = 'SOLUTION_SITE',
}

export default StatePageType;
