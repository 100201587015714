/**
 * A Controller for both GitHub.
 */

export function GitLabModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.temp = {};
    $scope.data = {};

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.projects = state.projects;
        $scope.selections = state.selections || [];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        updateProjectsList();
        tryLoadFromEdit();
    };

    $scope.connect = function () {
        const appUri = $scope.data.appUri;
        const key = $scope.data.key;
        $scope.connecting = true;
        $scope.error = null;
        return createProjectApis
            .createIntegration(
                projectManager.project.id,
                $scope.currentIntegration.name,
                {
                    uri: appUri,
                    token: key,
                },
                integrations.getIntegrationUniqueType(
                    authenticationService.currentUser.id,
                    'GITLAB',
                    $scope.otherIntegrationOwner,
                ),
                undefined,
            )
            .then(function (data) {
                $scope.integration = data;
                return createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.integration.id,
                    'projects',
                    {
                        userId: data.integrationUser,
                    },
                );
            })
            .then(function (data) {
                $scope.selections = data.options.slice(0, 1).map(function (project) {
                    return { project };
                });
                $scope.projects = data.options;
                updateProjectsList();
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.connecting = false;
            });
    };

    $scope.addProject = function (name) {
        if (!$scope.otherProjects) {
            return;
        }

        if ($scope.otherProjects[$scope.otherProjects.length - 1]) {
            $scope.otherProjects.pop();
        }

        const lowerCase = name.toLowerCase();
        const alreadyExist = $scope.otherProjects.some(function (r) {
            return r.displayName === lowerCase;
        });
        if (!alreadyExist) {
            const parts = name.split('/');
            if (parts.length === 2 && parts[0] && parts[1]) {
                $scope.otherRepos.push({
                    custom: true,
                    displayName: `${name} (custom)`,
                    originalName: lowerCase,
                    value: { owner: parts[0], repository: parts[1] },
                });
            }
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.projects = null;
        $scope.connect();
    };

    $scope.onBranchKeyUp = function ($event, selection) {
        // Escape key
        if ($event.keyCode === 27) {
            selection.showEdit = false;
        }
    };

    $scope.$watch('temp.project', function () {
        $scope.onProjectSelected($scope.temp.project);
    });

    $scope.onProjectSelected = function (project) {
        if (project && project.displayName) {
            const selection = { project, selected: true };
            $scope.selections.push(selection);
            updateProjectsList();
            $scope.temp.project = null;
        }
    };

    /**
     * Refresh the projects selection for each combo-box.
     */
    function updateProjectsList() {
        if ($scope.projects) {
            const projectsInSelection = new Set(
                $scope.selections.map(function (s) {
                    return s.project.displayName;
                }),
            );
            $scope.otherProjects = $scope.projects.filter(function (r) {
                return !projectsInSelection.has(r.displayName);
            });
        }
    }

    $scope.ok = function () {
        const s = {
            projects: $scope.projects,
            selections: $scope.selections,
            integration: $scope.integration,
        };
        s.integrations = $scope.selections
            .map(function (selection) {
                if (selection.selected) {
                    return {
                        integration: $scope.integration,
                        projectData: {
                            projectId: selection.project.value,
                            projectName: selection.project.displayName,
                        },
                    };
                }
            })
            .filter(angular.identity);

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });

            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'projects')
                .then(function (data) {
                    $scope.projects = data.options;
                    $scope.selections = data.options
                        .map(function (project) {
                            // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                const originalIntegration = state.integrations[0];

                                if (
                                    originalIntegration.projectData &&
                                    originalIntegration.projectData.projectDatas &&
                                    originalIntegration.projectData.projectDatas.length > 0
                                ) {
                                    for (const i in originalIntegration.projectData.projectDatas) {
                                        const projectData = originalIntegration.projectData.projectDatas[i];

                                        if (
                                            projectData.projectId === project.value &&
                                            projectData.projectName === project.displayName
                                        ) {
                                            return {
                                                project,
                                                selected: true,
                                            };
                                        }
                                    }
                                }
                            }
                        })
                        .filter(angular.identity);
                    updateProjectsList();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.connecting = false;
                });
        }
    }
}
export default angular.module('tonkean.shared').controller('GitLabModalCtrl', GitLabModalCtrl);
