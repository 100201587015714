import React from 'react';
import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const UrlAndRequestTypeWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;

    margin-bottom: 20px;
`;

const RequestType = styled.div`
    color: ${Theme.colors.gray_600};
    height: 26px;
    padding: 0 8px;
    line-height: 26px;
    font-weight: 700;
    font-size: ${FontSize.XSMALL_10};
    vertical-align: center;
    background-color: ${Theme.colors.brightestGray};
    border-radius: 3px 0 0 3px;

    border: 1px solid ${Theme.colors.gray_300};
    border-right: none;
    width: fit-content;
    text-align: center;
`;

const UrlInput = styled.input`
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 0 3px 3px 0;
    height: 26px;
    line-height: 26px;
    background-color: ${Theme.colors.gray_100};
    color: ${Theme.colors.gray_600};
    flex-grow: 1;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 300;
`;
interface Props {
    url: string;
}

const RequestUrl: React.FC<Props> = ({ url }) => {
    return (
        <UrlAndRequestTypeWrapper>
            <RequestType>URL</RequestType>

            <UrlInput placeholder="Preview of the URL..." value={url} readOnly />
        </UrlAndRequestTypeWrapper>
    );
};

export default RequestUrl;
