import template from './tnkAutonomousTriggerConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkAutonomousTriggerConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        customTriggerType: '@',
        customTriggerId: '<',
        previewEvaluationSource: '<',
        control: '<',
        invalidLogics: '<',
        viewOnly: '<',
        isInnerAutonomous: '<',

        onAutonomousTriggerConfigurationChanged: '&',
        onActionsChanged: '&',
    },
    template,
    controller: 'AutonomousTriggerConfigurationCtrl',
});
