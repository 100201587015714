<!-- User name -->
<div class="admin-more-users-popover-content padding-normal-xxs">
    <div
        ng-repeat="avatar in data.avatars | limitTo: data.avatarsDesktopLimit + data.displayMoreLimit track by $index "
        ng-if="data.showAllInDisplayMore || $index > data.avatarsDesktopLimit - 1"
    >
        <div class="flex-vmiddle common-size-xxxs margin-left-sm margin-normal-sm-v margin-right">
            <!-- Avatar -->
            <tnk-avatar
                user="avatar"
                default-on-empty="true"
                class="image-avatar margin-right flex-no-shrink"
                size="'medium'"
                hide-default-avatar-photo="true"
            ></tnk-avatar>

            <!-- User name -->
            <span class="flex-grow margin-right-lg common-color-light-grey2 common-bold">
                {{ avatar.isAnonymousUser ? data.anonymousUserName : avatar.name }}
            </span>

            <!-- Last view time -->
            <span ng-if="data.shouldDisplayViewTime" class="flex-no-shrink common-color-grey-soft">
                {{ avatar.lastViewTime ? (avatar.lastViewTime | timeAgo) : 'Never Viewed' }}
            </span>
        </div>
    </div>

    <!-- +x More -->
    <div
        class="margin-left-xs margin-top margin-bottom-xs"
        ng-if="data.showMoreAvatarsLink && data.avatars.length - data.avatarsDesktopLimit - data.displayMoreLimit > 0"
    >
        <a ng-click="onMoreClick($event)">
            +{{ data.avatars.length - data.avatarsDesktopLimit - data.displayMoreLimit }} more...
        </a>
    </div>
</div>
