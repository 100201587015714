import React from 'react';

import { Button } from '@tonkean/tui-buttons/Button';

interface Props {
    onClickCreateTemplate: () => void;
}

const EnterpriseComponentOverviewTemplateStepActions: React.FC<Props> = ({ onClickCreateTemplate }) => {
    return (
        <Button onClick={onClickCreateTemplate} cancel>
            Create a New Template
        </Button>
    );
};

export default EnterpriseComponentOverviewTemplateStepActions;
