import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import CommunicationIntegrationByMessageSendBy from './CommunicationIntegrationByMessageSendBy';
import PeopleDirectoryCommunicationPreference from './PeopleDirectoryCommunicationPreference';
import SinglePeopleDirectorySummary from './SinglePeopleDirectorySummary';
import { ReactComponent as MailIcon } from '../../../../../../images/integrations/mail.svg';
import PeopleDirectoryViewMode from '../../../entities/PeopleDirectoryViewMode';
import { usePeopleDirectoriesView, usePeopleDirectoryView } from '../../../utils/PeopleDirectoryViewContext';

import { Accordion, CommunicationIntegrationIcon } from '@tonkean/infrastructure';
import { WarningTriangleIcon } from '@tonkean/svg';
import type { IntegrationType, ProjectIntegration } from '@tonkean/tonkean-entities';
import {
    MessageSendBy,
    PeopleDirectorySendStatus,
    PeopleDirectorySendStatusLabel,
    SendToGroupOfPeopleAs,
} from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { AccordionSize } from '@tonkean/tui-theme/sizes';
import type { Either } from '@tonkean/utils';

const PeopleDirectoryHeader = styled(Accordion)`
    background-color: ${Theme.colors.HEX_F7F8FA};
`;
const SinglePeopleDirectorySummaryWrapper = styled.span<{ gridColumns: number }>`
    grid-template-columns: repeat(${({ gridColumns }) => gridColumns}, minmax(20%, 35%));
    display: inline-grid;
    align-items: center;
    width: 100%;
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.MEDIUM_14};
`;
const Text = styled.span`
    display: inline-flex;
    align-items: center;
    white-space: pre;
    gap: 6px;
    color: ${Theme.colors.gray_700};

    svg {
        height: 14px;
        width: 14px;
    }
`;
const InsufficientConfigurationMessage = styled.span`
    display: inline-flex;
    align-items: center;
`;
const Warning = styled.span`
    display: inline-flex;
    align-items: center;
    gap: 6px;
    color: ${Theme.colors.warning};
    white-space: pre;

    svg {
        height: 14px;
        width: 14px;
    }
`;
const LinkToEnterpriseComponentPage = styled(Clickable)`
    color: ${Theme.colors.primary};
    text-decoration: underline;

    &:hover,
    &:focus {
        color: ${Theme.colors.primary};
        text-decoration: underline;
    }
`;
const GroupHistoryWrapper = styled.div`
    height: 41px;
    padding: 0 20px;
    background-color: ${Theme.colors.HEX_F7F8FA};
    border-bottom: 1px solid ${Theme.colors.gray_300};
    display: flex;
    align-items: center;
`;

interface PeopleDirectoryProps {
    peopleDirectoryId: string;
    children: React.ReactNode;
}

interface LoadingProps {
    loading: true;
    children: React.ReactNode;
}

type Props = Either<PeopleDirectoryProps, LoadingProps>;

const BusinessGroupNotConfiguredDisplay: React.FC<{
    peopleDirectoryId: string;
}> = (peopleDirectoryId) => {
    return (
        <InsufficientConfigurationMessage>
            <Warning>
                <WarningTriangleIcon />
                Business group was not configured for the environment.&nbsp;
            </Warning>
            <LinkToEnterpriseComponentPage
                state="product.peopleDirectoryPage"
                params={{
                    enterpriseComponentId: peopleDirectoryId,
                    page: 'business-groups',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                Configure
            </LinkToEnterpriseComponentPage>
        </InsufficientConfigurationMessage>
    );
};

const GroupMessageNotConfiguredDisplay: React.FC<{
    peopleDirectoryId: string;
    peopleDirectoryInstanceId: string | undefined;
}> = (peopleDirectoryId, peopleDirectoryInstanceId) => {
    return (
        <InsufficientConfigurationMessage>
            <Warning>
                <WarningTriangleIcon />
                Group communication method was not defined.&nbsp;
            </Warning>
            <LinkToEnterpriseComponentPage
                state="product.peopleDirectoryPage"
                params={{
                    enterpriseComponentId: peopleDirectoryId,
                    page: 'business-groups',
                    peopleDirectoryInstance: peopleDirectoryInstanceId,
                    peopleDirectoryInstanceTab: 'Group communication method',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                Create new method
            </LinkToEnterpriseComponentPage>
        </InsufficientConfigurationMessage>
    );
};

const IndividualsHistory: React.FC<React.PropsWithChildren<PeopleDirectoryProps>> = ({
    peopleDirectoryId,
    children,
}) => {
    const { peopleDirectorySendStatus, peopleDirectoryFriendlyError } = usePeopleDirectoryView(peopleDirectoryId);

    return (
        <PeopleDirectoryHeader
            size={AccordionSize.XSMALL}
            title={
                <SinglePeopleDirectorySummaryWrapper gridColumns={4}>
                    <SinglePeopleDirectorySummary peopleDirectoryId={peopleDirectoryId} />
                    {peopleDirectorySendStatus === PeopleDirectorySendStatus.FAIL && (
                        <>
                            <span />
                            <Warning>{PeopleDirectorySendStatusLabel.FAIL}</Warning>
                            <Text>{peopleDirectoryFriendlyError}</Text>
                        </>
                    )}
                </SinglePeopleDirectorySummaryWrapper>
            }
            initialOpen={peopleDirectorySendStatus !== PeopleDirectorySendStatus.FAIL}
            whiteContentBackground
        >
            {children}
        </PeopleDirectoryHeader>
    );
};

const GroupHistory: React.FC<PeopleDirectoryProps> = ({ peopleDirectoryId }) => {
    const { groupMessagesResults } = usePeopleDirectoryView(peopleDirectoryId);

    return (
        <GroupHistoryWrapper>
            <SinglePeopleDirectorySummaryWrapper gridColumns={4}>
                <SinglePeopleDirectorySummary peopleDirectoryId={peopleDirectoryId} showErrorIcon />
                <PeopleDirectoryCommunicationPreference>
                    <CommunicationIntegrationByMessageSendBy
                        messageSendBy={groupMessagesResults?.sendBy ?? MessageSendBy.EMAIL}
                        overrideText={groupMessagesResults?.communicationMethod}
                    />
                </PeopleDirectoryCommunicationPreference>
                {groupMessagesResults?.status === PeopleDirectorySendStatus.SUCCESS ? (
                    <Text>{PeopleDirectorySendStatusLabel.SUCCESS}</Text>
                ) : (
                    <Warning>{PeopleDirectorySendStatusLabel.FAIL}</Warning>
                )}
            </SinglePeopleDirectorySummaryWrapper>
        </GroupHistoryWrapper>
    );
};

const History: React.FC<PeopleDirectoryProps> = (props) => {
    const { sendToGroupOfPeopleAs } = usePeopleDirectoryView(props.peopleDirectoryId);

    return sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.PEOPLE_GROUP ? (
        <GroupHistory {...props} />
    ) : (
        <IndividualsHistory {...props} />
    );
};

const IndividualsPreview: React.FC<PeopleDirectoryProps> = (props) => {
    const { sendToGroupOfPeopleAs, missingInstanceConfiguredForEnvironment } = usePeopleDirectoryView(
        props.peopleDirectoryId,
    );

    return (
        <PeopleDirectoryHeader
            size={AccordionSize.XSMALL}
            title={
                <SinglePeopleDirectorySummaryWrapper gridColumns={2}>
                    <SinglePeopleDirectorySummary peopleDirectoryId={props.peopleDirectoryId} />
                    {missingInstanceConfiguredForEnvironment && (
                        <BusinessGroupNotConfiguredDisplay peopleDirectoryId={props.peopleDirectoryId} />
                    )}
                </SinglePeopleDirectorySummaryWrapper>
            }
            initialOpen={sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL}
            whiteContentBackground
        >
            {props.children}
        </PeopleDirectoryHeader>
    );
};

const GroupsPreview: React.FC<PeopleDirectoryProps> = (props) => {
    const { peopleDirectoryInstance, groupMessage, sendToGroupOfPeopleAs, missingInstanceConfiguredForEnvironment } =
        usePeopleDirectoryView(props.peopleDirectoryId);

    const projectManager = useAngularService('projectManager');

    const selectedCommunicationIntegration: ProjectIntegration | undefined = useMemo(() => {
        return projectManager.project.communicationIntegrations.find(
            (integration: ProjectIntegration) => integration.id === groupMessage?.selectedCommunicationIntegrationId,
        );
    }, [groupMessage?.selectedCommunicationIntegrationId, projectManager.project.communicationIntegrations]);

    const showGroupMessageUsing = sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.PEOPLE_GROUP;

    const misconfigurationDisplay = useMemo(() => {
        return missingInstanceConfiguredForEnvironment ? (
            <BusinessGroupNotConfiguredDisplay peopleDirectoryId={props.peopleDirectoryId} />
        ) : (
            <GroupMessageNotConfiguredDisplay
                peopleDirectoryId={props.peopleDirectoryId}
                peopleDirectoryInstanceId={peopleDirectoryInstance?.id}
            />
        );
    }, [missingInstanceConfiguredForEnvironment, peopleDirectoryInstance?.id, props.peopleDirectoryId]);

    return (
        <GroupHistoryWrapper>
            <SinglePeopleDirectorySummaryWrapper gridColumns={2}>
                <SinglePeopleDirectorySummary peopleDirectoryId={props.peopleDirectoryId} />
                {showGroupMessageUsing && (
                    <Text>
                        {selectedCommunicationIntegration || groupMessage?.forceEmail ? (
                            <>
                                Group message will be sent via:
                                {groupMessage?.forceEmail ? (
                                    <>
                                        <MailIcon />
                                        Individual's email address
                                    </>
                                ) : (
                                    <>
                                        <CommunicationIntegrationIcon
                                            integrationType={
                                                selectedCommunicationIntegration?.integrationType as IntegrationType
                                            }
                                        />
                                        {groupMessage?.slackChannel?.displayName}
                                    </>
                                )}
                            </>
                        ) : (
                            misconfigurationDisplay
                        )}
                    </Text>
                )}
            </SinglePeopleDirectorySummaryWrapper>
        </GroupHistoryWrapper>
    );
};

const Preview: React.FC<PeopleDirectoryProps> = (props) => {
    const { sendToGroupOfPeopleAs } = usePeopleDirectoryView(props.peopleDirectoryId);

    return sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.PEOPLE_GROUP ? (
        <GroupsPreview {...props} />
    ) : (
        <IndividualsPreview {...props} />
    );
};

const Loading: React.FC<LoadingProps> = ({ children }) => {
    const { sendToGroupOfPeopleAs } = usePeopleDirectoriesView();

    return (
        <PeopleDirectoryHeader
            size={AccordionSize.XSMALL}
            title={<SinglePeopleDirectorySummary loading />}
            initialOpen={sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL}
            whiteContentBackground
        >
            {children}
        </PeopleDirectoryHeader>
    );
};

const PeopleDirectoryPreviewTableEntranceHeader: React.FC<Props> = (props) => {
    const { mode } = usePeopleDirectoriesView();

    if (props.loading) {
        return <Loading {...props} />;
    }

    return mode === PeopleDirectoryViewMode.HISTORY ? <History {...props} /> : <Preview {...props} />;
};

export default PeopleDirectoryPreviewTableEntranceHeader;
