import { useAngularService } from 'angulareact';
import { useEffect } from 'react';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';

function useCreateWorkerForm() {
    const formManager = useAngularService('formManager');
    const $rootScope = useAngularService('$rootScope');

    const [{ data, error, loading }, createWorkerForm] = useLazyAsyncMethod(formManager, 'createWorkerForm');

    useEffect(() => {
        if (data) {
            $rootScope.$broadcast('formCreated', data);

            $rootScope.$emit('alert', {
                msg: `Created form successfully`,
                type: 'success',
            });
        }
    }, [$rootScope, data]);

    useEffect(() => {
        if (error) {
            const errorMessage =
                error.status === 409
                    ? `Failed saving form configuration. ${error.data.error.message}`
                    : 'Failed to update form';

            $rootScope.$emit('alert', {
                msg: errorMessage,
                type: 'danger',
            });
        }
    }, [$rootScope, error]);

    return {
        createWorkerForm,
        createWorkerFormLoading: loading,
        createWorkerFormData: data,
        createWorkerFormError: error,
    };
}

export default useCreateWorkerForm;
