import { useAngularService } from 'angulareact';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ItemWidget } from '../../WidgetModule';
import type { SolutionSitePageWidgetProps } from '../../WidgetModule';

import { usePreventConcurrentManualReloadFetchManager } from '@tonkean/infrastructure';
import { useSmartConversation } from '@tonkean/smart-conversation';
import { SmartSearch } from '@tonkean/smart-search';
import type {
    SmartConversationMessage,
    SmartConversationReplyMethodParams,
    SmartSearchResponse,
    WidgetConfiguration,
} from '@tonkean/tonkean-entities';

const CustomItemWidget = styled(ItemWidget)`
    overflow: visible;
    min-height: 45vh;
`;

const getItems = (data: SmartConversationMessage<SmartSearchResponse>) => data;

const SolutionSiteAIChatWidget: React.FC<SolutionSitePageWidgetProps<WidgetConfiguration>> = ({
    permission,
    widget,
}) => {
    const tonkeanService = useAngularService('tonkeanService');

    const [[smartSearchReply], { loading, error }] = usePreventConcurrentManualReloadFetchManager(
        tonkeanService,
        'smartSearchReply',
        {
            isSingle: true,
            getItems,
        },
    );

    const replyMethod = useCallback(
        ({ projectId, userReply, conversationId }: SmartConversationReplyMethodParams) => {
            return smartSearchReply(projectId, userReply, false, conversationId);
        },
        [smartSearchReply],
    );

    const smartConversationManager = useSmartConversation<SmartSearchResponse>(
        widget.projectId,
        replyMethod,
        loading.any,
        error,
    );

    return (
        <CustomItemWidget permission={permission}>
            <SmartSearch
                projectId={widget.projectId}
                smartConversationManager={smartConversationManager}
                searchRequired={false}
            />
        </CustomItemWidget>
    );
};

export default SolutionSiteAIChatWidget;
