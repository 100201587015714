/* eslint-disable no-nested-ternary */
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import CardsItemsWidgetDisplay from './CardsItemsWidgetDisplay';
import InnerItemsItemWidgetEmptyStates from './InnerItemsItemWidgetEmptyStates';
import InnerItemsItemWidgetHeaderActions from './InnerItemsItemWidgetHeaderActions';
import WidgetItemsFilters from '../../../components/WidgetItemsFilters';
import { ItemInterfacePermission } from '../../../entities';
import { ItemWidget } from '../../../WidgetModule';
import { ItemWidgetHeaderTitle } from '../../../WidgetModule';
import type { InnerItemWidgetConfiguration } from '../../entities';
import { useWidgetConfigurationFieldsSoftMigration } from '../../hooks';
import {
    useFieldsToDisplay,
    useGetFieldDefinitions,
    useCalculatedFiltersQueryDefinition,
    useEndUserFiltersQueryDefinition,
    useShowFilterFields,
} from '../hooks';
import useWidgetCardsDisplayMode from '../hooks/useWidgetCardsDisplayMode';
import EMPTY_CONDITIONS_DEFINITION from '../utils/emptyConditionsDefinition';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { TnkParentNavigation, TnkTracksEditor } from '@tonkean/angular-to-react-components';
import type { UpdateFieldsValuesManager } from '@tonkean/infrastructure';
import {
    Breakpoint,
    LAST_RESPONSE_METADATA_PARAM,
    LIMIT_PARAM,
    SKIP_PARAM,
    Spacer,
    useBreakpoint,
    useDebouncedState,
    useFetchManager,
    useIsProcessContributorData,
    useFastReload,
    DataNotUpToDateIndicator,
} from '@tonkean/infrastructure';
import {
    ItemInterfaceWidgetConfigurationDisplayMode,
    ItemInterfaceWidgetConfigurationUserAccessLevel,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import type {
    BasicQueryDefinition,
    FieldFilter,
    Initiative,
    TonkeanId,
    TonkeanType,
    WidgetBase,
} from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { typedObjectKeys } from '@tonkean/utils';

// We want to have a scroll container for the tracks editor that has no padding in order to solve a bug where the
// scrolled content goes below the header when its sticky
const TracksEditorContainer = styled.div`
    position: relative;
    overflow: auto;
    height: 100%;
    padding-right: 10px; // Solve weird bug were scroller would always display horizontal scrollbar
`;

const TitleContainer = styled.div`
    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: flex;
        flex-direction: row;
    }
`;

const HeaderWrapper = styled.div`
    align-items: center;
    justify-content: center;
`;

interface Props {
    parentInitiative: Initiative | undefined;
    widget: WidgetBase<InnerItemWidgetConfiguration>;
    permission: ItemInterfacePermission;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    workflowVersionType: WorkflowVersionType | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
    environmentIsActive?: boolean;
    showModuleOffIndication?: boolean;
    headerBackgroundColor?: string;
    reloadInitiativeFromServer?: () => void;
    /**
     * Amount of items for gallery and table view
     */
    innerItemsAmount?: [number, number];
    shouldForceDisableAddInnerItems?: (initiativesAmount: number) => boolean;
    showEmptyStateIfNeeded?: boolean;
    footer?: React.FC<{
        parentInitiativeId: Initiative['id'] | undefined;
        initiatives: Initiative[];
        widget: WidgetBase<InnerItemWidgetConfiguration>;
        workflowVersionType: WorkflowVersionType | undefined;
        hasMoreInitiatives: boolean;
        totalInitiatives: number | undefined;
    }>;
    collectItemsMode?: boolean;
    numerateInnerItems?: boolean;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    updateFieldsValuesManager: UpdateFieldsValuesManager;
}

const InnerItemsItemWidgetDisplay: React.FC<Props> = ({
    parentInitiative,
    groupId,
    workflowVersionId,
    widget,
    permission,
    workflowVersionType,
    environmentIsActive,
    showModuleOffIndication,
    headerBackgroundColor = Theme.colors.primary,
    reloadInitiativeFromServer,
    innerItemsAmount = [10, 50],
    shouldForceDisableAddInnerItems,
    footer: Footer,
    collectItemsMode,
    showEmptyStateIfNeeded = true,
    numerateInnerItems = false,
    originatedCustomTriggerId,
    updateFieldsValuesManager,
}) => {
    const $location = useAngularService('$location');
    const $state = useAngularService('$state');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useDebouncedState(debouncedSearchTerm, setDebouncedSearchTerm);
    const [showEmptyState, setShowEmptyState] = useState(true);
    const [processContributorDisplayMode, setProcessContributorDisplayMode] =
        useState<ItemInterfaceWidgetConfigurationDisplayMode>(widget.configuration.displayMode);

    const [totalInitiatives, setTotalInitiatives] = useState<number>();

    const manageAccessControlForItemsInWorkflowFolder = useFeatureFlag(
        'tonkean_manage_access_control_for_items_in_workflow_folder',
    ) as boolean;

    const userCanAddInnerItems = Boolean(
        permission === ItemInterfacePermission.USER_CAN_EDIT_ITEM &&
            widget.configuration.userAccessLevel === ItemInterfaceWidgetConfigurationUserAccessLevel.EDIT_AND_CREATE,
    );

    const isProcessContributor = useIsProcessContributorData(widget.projectId);

    useGetFieldDefinitions(workflowVersionId, workflowVersionType, typedObjectKeys, widget);

    useEffect(() => {
        if (widget.configuration.displayMode === ItemInterfaceWidgetConfigurationDisplayMode.AUTO) {
            setProcessContributorDisplayMode(ItemInterfaceWidgetConfigurationDisplayMode.TABLE);
        } else {
            setProcessContributorDisplayMode(widget.configuration.displayMode);
        }
    }, [widget.configuration.displayMode]);

    const effectiveBreakpoint = useBreakpoint();
    const isMobile = Number(effectiveBreakpoint) <= Breakpoint.MID_XSMALL_768;
    const shouldShowGalleryView = useWidgetCardsDisplayMode(
        widget.configuration.displayMode,
        processContributorDisplayMode,
    );

    const viewOnly =
        !environmentIsActive ||
        !Boolean(
            [ItemInterfacePermission.USER_CAN_EDIT_EXISTING_ITEM, ItemInterfacePermission.USER_CAN_EDIT_ITEM].includes(
                permission,
            ),
        ) ||
        widget.configuration.userAccessLevel === ItemInterfaceWidgetConfigurationUserAccessLevel.VIEW;

    const trackHelper = useAngularService('trackHelper');
    const initiativeManager = useAngularService('initiativeManager');

    const [endUserFilters, setEndUserFilters] = useState<FieldFilter[]>([]);
    const [endUserAdvancedQuery, setEndUserAdvancedQuery] = useState<BasicQueryDefinition>(EMPTY_CONDITIONS_DEFINITION);

    const endUserFiltersQueryDefinition = useEndUserFiltersQueryDefinition(endUserFilters, endUserAdvancedQuery);

    const calculatedFiltersQueryDefinition = useCalculatedFiltersQueryDefinition(endUserFiltersQueryDefinition, widget);

    const limitGalleryView = shouldShowGalleryView ? innerItemsAmount[0] : innerItemsAmount[1];
    const [initiativesInterval, fastReload] = useFastReload(30_000, 1000);

    const [[searchInitiatives], { data: initiatives, loading, loadNextPage, hasMorePages }] = useFetchManager(
        initiativeManager,
        'searchInitiatives',
        {
            getItems: (result: {
                data: { entities: { id: string; [key: string]: any }[]; hasMoreEntities: boolean };
            }) => result.data.entities as Initiative[],
            onLoaded(result: {
                data: { entities: { id: string; [key: string]: any }[]; hasMoreEntities: boolean; totalHits: number };
            }) {
                trackHelper.getRelatedInitiativesCount(
                    widget.projectId,
                    result.data.entities.map(({ id }) => id),
                );

                setTotalInitiatives(result.data.totalHits);
            },
            checkHasMore: (result: {
                data: { entities: { id: string; [key: string]: any }[]; hasMoreEntities: boolean };
            }) => {
                return result.data.hasMoreEntities;
            },
            getLastPollingReqMetadata: (
                result: {
                    data: { entities: { id: string; [key: string]: any }[]; hasMoreEntities: boolean };
                },
                lastValue: string | undefined,
            ) => {
                return result.data.entities
                    .reduce((max, current) => {
                        if (current.updated && (!max || current.updated > max)) {
                            return current.updated;
                        }
                        return max;
                    }, lastValue)
                    ?.toString();
            },
            limit: limitGalleryView,
            autoReloadInterval: initiativesInterval,
        },
    );

    const searchInitiativesQuery = useMemo(() => {
        const searchInitiativesQueryObject = {
            conditionsQuery: calculatedFiltersQueryDefinition,
            projectId: widget.projectId,
            isArchived: false,
            dateRange: undefined,
            returnFlat: undefined,
            groupId,
            searchString: debouncedSearchTerm,
            orderByFieldId: widget.configuration.sortByField?.fieldDefinitionId,
            orderByFieldType: widget.configuration.sortByField?.orderByFieldType,
            orderByType: widget.configuration.sortByField?.orderType,
            filterOnlyRootItems:
                !parentInitiative?.id &&
                !debouncedSearchTerm &&
                !widget.configuration.includeInnerItemsFromAllHierarchies
                    ? true
                    : undefined,
            isDraftInitiatives: workflowVersionType === WorkflowVersionType.DRAFT,
            parentId: widget.configuration.includeInnerItemsFromAllHierarchies ? undefined : parentInitiative?.id,
            anyParentId: parentInitiative?.id,
            shouldFilterByRequesterId: manageAccessControlForItemsInWorkflowFolder && isProcessContributor,
        };

        // If we search for a specific term, we should return both root and inner items
        if (!debouncedSearchTerm) {
            return { ...searchInitiativesQueryObject, isRootInitiative: !parentInitiative?.id };
        }

        return searchInitiativesQueryObject;
    }, [
        calculatedFiltersQueryDefinition,
        debouncedSearchTerm,
        groupId,
        isProcessContributor,
        manageAccessControlForItemsInWorkflowFolder,
        parentInitiative?.id,
        widget.configuration.includeInnerItemsFromAllHierarchies,
        widget.configuration.sortByField?.fieldDefinitionId,
        widget.configuration.sortByField?.orderByFieldType,
        widget.configuration.sortByField?.orderType,
        widget.projectId,
        workflowVersionType,
    ]);

    const reloadInitiatives = useCallback(() => {
        if (!groupId || !workflowVersionType) {
            return;
        }

        searchInitiatives(
            widget.projectId,
            undefined,
            undefined,
            undefined,
            groupId,
            calculatedFiltersQueryDefinition,
            debouncedSearchTerm,
            undefined,
            SKIP_PARAM,
            LIMIT_PARAM,
            searchInitiativesQuery.orderByFieldId,
            searchInitiativesQuery.orderByFieldType,
            searchInitiativesQuery.orderByType,
            // We only request root items only if we're a site items widget - meaning no parent id.
            !parentInitiative?.id && !debouncedSearchTerm && !widget.configuration.includeInnerItemsFromAllHierarchies
                ? true
                : undefined,
            workflowVersionType === WorkflowVersionType.DRAFT,
            LAST_RESPONSE_METADATA_PARAM,
            // If we request inner items from all hierarchies, we don't pass the parentId parameter.
            widget.configuration.includeInnerItemsFromAllHierarchies ? undefined : parentInitiative?.id,
            // The any parent id parameter covers us that any inner item returned has the current item in one of its parents.
            parentInitiative?.id,
            manageAccessControlForItemsInWorkflowFolder && isProcessContributor,
        );
    }, [
        groupId,
        workflowVersionType,
        searchInitiativesQuery,
        searchInitiatives,
        widget.projectId,
        widget.configuration.includeInnerItemsFromAllHierarchies,
        calculatedFiltersQueryDefinition,
        debouncedSearchTerm,
        parentInitiative?.id,
        manageAccessControlForItemsInWorkflowFolder,
        isProcessContributor,
    ]);

    useLayoutEffect(() => {
        reloadInitiatives();
    }, [
        calculatedFiltersQueryDefinition,
        debouncedSearchTerm,
        groupId,
        endUserFiltersQueryDefinition,
        parentInitiative?.id,
        searchInitiatives,
        widget.configuration.filters,
        widget.configuration.includeInnerItemsFromAllHierarchies,
        widget.projectId,
        workflowVersionType,
        reloadInitiatives,
        searchInitiativesQuery.orderByFieldId,
        searchInitiativesQuery.orderByFieldType,
        searchInitiativesQuery.orderByType,
    ]);

    const configurationFields = useWidgetConfigurationFieldsSoftMigration(
        shouldShowGalleryView ? widget.configuration.cardFields : widget.configuration.fields,
    );

    const showFilterFields = useShowFilterFields(widget, configurationFields);

    const fieldsToDisplay = useFieldsToDisplay(configurationFields);

    const hideTitle = useMemo(() => {
        return !fieldsToDisplay.includes('TNK_TITLE');
    }, [fieldsToDisplay]);

    const shouldDisplayItemBodyStyle = shouldShowGalleryView && initiatives?.length > 0;

    const emptyStateDisplayed =
        showEmptyStateIfNeeded &&
        ((loading.initial && !searchTerm) ||
            (initiatives.length === 0 && showEmptyState && !loading.initial && !searchTerm) ||
            ((!groupId || !workflowVersionId || !workflowVersionType || !groupId) && !loading.initial) ||
            (initiatives.length === 0 && !loading.initial && searchTerm) ||
            (shouldShowGalleryView && fieldsToDisplay?.length === 0));

    const navigateToItem = (id: string) => {
        if ($state.params.initiativeId) {
            $state.go('.', { initiativeId: id });
        } else {
            $location.search({ tid: id });
        }
    };

    const onSort = useCallback(
        (sortObject) => {
            if (searchInitiativesQuery) {
                searchInitiativesQuery.orderByFieldId = sortObject?.field;
                searchInitiativesQuery.orderByFieldType = sortObject?.fieldType;
                searchInitiativesQuery.orderByType = sortObject?.orderType;
            }
        },
        [searchInitiativesQuery],
    );

    const createdInWorkerRunInformation = useMemo(() => {
        if (!originatedCustomTriggerId) return null;

        return { createdByCustomTriggerId: originatedCustomTriggerId };
    }, [originatedCustomTriggerId]);

    return (
        <ItemWidget
            headerTitle={
                <TitleContainer>
                    <ItemWidgetHeaderTitle>
                        <HeaderWrapper>
                            {widget?.displayName ?? 'Inner Items '} <Spacer width={8} />
                            <DataNotUpToDateIndicator show={!environmentIsActive && showModuleOffIndication} />
                        </HeaderWrapper>
                    </ItemWidgetHeaderTitle>
                    {!isMobile && parentInitiative && (
                        <TnkParentNavigation
                            parentsArray={
                                [...parentInitiative.parentInitiatives, parentInitiative] as {
                                    id: string;
                                    title: string;
                                }[]
                            }
                            navigate={navigateToItem}
                            immediateParentLabel="Current Item"
                        />
                    )}
                </TitleContainer>
            }
            headerActions={
                <>
                    {!isMobile && (
                        <InnerItemsItemWidgetHeaderActions
                            searchText={searchTerm}
                            setSearchText={setSearchTerm}
                            widget={widget}
                            workflowVersionId={workflowVersionId}
                            headerBackgroundColor={headerBackgroundColor}
                            onInitiativeCreated={reloadInitiatives}
                            setDisplayMode={setProcessContributorDisplayMode}
                            displayMode={processContributorDisplayMode}
                            initiative={parentInitiative}
                            workflowVersionType={workflowVersionType}
                            reloadInitiativeFromServer={reloadInitiativeFromServer}
                            originatedCustomTriggerId={originatedCustomTriggerId}
                        />
                    )}
                </>
            }
            footer={
                Footer && (
                    <Footer
                        totalInitiatives={totalInitiatives}
                        hasMoreInitiatives={hasMorePages}
                        workflowVersionType={workflowVersionType}
                        initiatives={initiatives}
                        widget={widget}
                        parentInitiativeId={parentInitiative?.id}
                    />
                )
            }
            subHeader={
                isMobile ? (
                    <InnerItemsItemWidgetHeaderActions
                        searchText={searchTerm}
                        setSearchText={setSearchTerm}
                        widget={widget}
                        workflowVersionId={workflowVersionId}
                        headerBackgroundColor={headerBackgroundColor}
                        onInitiativeCreated={reloadInitiatives}
                        initiative={parentInitiative}
                        workflowVersionType={workflowVersionType}
                        reloadInitiativeFromServer={reloadInitiativeFromServer}
                        originatedCustomTriggerId={originatedCustomTriggerId}
                    />
                ) : showFilterFields && workflowVersionId ? (
                    <WidgetItemsFilters
                        endUserFilters={endUserFilters}
                        onEndUserFiltersChanged={setEndUserFilters}
                        workflowVersionId={workflowVersionId}
                        fields={shouldShowGalleryView ? widget.configuration.cardFields : widget.configuration.fields}
                        advancedFilter={endUserAdvancedQuery}
                        onChangeAdvancedFilter={setEndUserAdvancedQuery}
                        displayAdvancedFilters={widget.configuration.displayAdvancedFilters}
                    />
                ) : undefined
            }
            className="innerItemWidget"
            permission={permission}
            noBorderBody={shouldDisplayItemBodyStyle}
            noBackgroundBody={shouldDisplayItemBodyStyle}
            noPaddingBody={shouldDisplayItemBodyStyle}
            disableMaxHeight={shouldDisplayItemBodyStyle}
            enableHorizontalScrolling
        >
            {emptyStateDisplayed ? (
                <InnerItemsItemWidgetEmptyStates
                    initiatives={initiatives}
                    showEmptyState={showEmptyState}
                    userCanAddInnerItems={userCanAddInnerItems}
                    onClickAddItem={() => setShowEmptyState(false)}
                    viewOnly={viewOnly}
                    shouldShowGalleryView={shouldShowGalleryView}
                    numOfFieldsToDisplay={fieldsToDisplay?.length}
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    workflowVersionType={workflowVersionType}
                    loadingInitial={loading.initial}
                />
            ) : (
                <>
                    {shouldShowGalleryView ? (
                        <CardsItemsWidgetDisplay
                            initiatives={initiatives}
                            widget={widget}
                            viewOnly={viewOnly}
                            groupId={groupId}
                            projectId={widget.projectId}
                            workflowVersionType={workflowVersionType}
                            workflowVersionId={workflowVersionId as TonkeanId<TonkeanType.WORKFLOW_VERSION>}
                            showLoadMore={hasMorePages && !loading.nextPageLoading && !loading.initial}
                            loadMore={loadNextPage}
                            headerBackgroundColor={headerBackgroundColor}
                            shouldShowGalleryView={shouldShowGalleryView}
                            updateFieldsValuesManager={updateFieldsValuesManager}
                        />
                    ) : (
                        <TracksEditorContainer>
                            {workflowVersionId && groupId && workflowVersionType && (
                                <TnkTracksEditor
                                    showAddOnEmpty={!searchTerm}
                                    defaultFunc={parentInitiative?.function}
                                    showArchived={parentInitiative?.isArchived}
                                    tracks={initiatives}
                                    editorId={widget.id}
                                    allowDndOnRoot={!parentInitiative?.isArchived || !!searchTerm}
                                    parentItem={parentInitiative}
                                    groupId={groupId}
                                    onlyGroup={groupId}
                                    workflowVersionId={workflowVersionId}
                                    customFields={null}
                                    lazyLoadTracks={false}
                                    loading={loading.initial}
                                    hasMoreInitiatives={loading.nextPageLoading || loading.initial}
                                    displayFieldsList={fieldsToDisplay}
                                    viewOnlyMode={viewOnly || !!searchTerm}
                                    fieldsConfigurations={configurationFields}
                                    onlyUpdateExistingItems={!userCanAddInnerItems}
                                    defaultItemInterfaceId={widget.configuration.drillDownInterface}
                                    originWidget={widget.id}
                                    enableInnerItemsToggle={widget.configuration.enableInnerItemsToggle}
                                    enableAddingItems={userCanAddInnerItems}
                                    collectItemsMode={
                                        collectItemsMode && !shouldForceDisableAddInnerItems?.(initiatives.length)
                                    }
                                    hideAddNewForce={
                                        !collectItemsMode && // collectItemsMode has issues with adding items when this flag is true (will be hidden anyway)
                                        (parentInitiative?.isArchived ||
                                            viewOnly ||
                                            !userCanAddInnerItems ||
                                            shouldForceDisableAddInnerItems?.(initiatives.length) ||
                                            !!searchTerm)
                                    }
                                    hideTitle={hideTitle}
                                    hideBulkSelection={collectItemsMode}
                                    noSubitems={collectItemsMode}
                                    disableGoToTrack={collectItemsMode}
                                    onInitiativeCreated={() => fastReload()}
                                    onTrackRemoved={() => fastReload()}
                                    onManualFieldUpdate={() => fastReload()}
                                    numerateItems={numerateInnerItems}
                                    searchInitiativesQuery={searchInitiativesQuery}
                                    onSort={onSort}
                                    createdInWorkerRunInformation={createdInWorkerRunInformation}
                                    ignoreColumnVisibility
                                    stickyHeader
                                    hideColumnQuickCreateForce
                                    disableFieldHeaderDropdown
                                />
                            )}
                            {hasMorePages && !loading.nextPageLoading && !loading.initial && (
                                <Button onClick={loadNextPage}>Load More</Button>
                            )}
                        </TracksEditorContainer>
                    )}
                </>
            )}
        </ItemWidget>
    );
};

export default InnerItemsItemWidgetDisplay;
