import template from './tnkUpdateInitiativeState.template.html?angularjs';

/**
 * A component used to supply initiative state updates.
 */
export default angular.module('tonkean.app').component('tnkUpdateInitiativeState', {
    bindings: {
        initiativeId: '<', // The initiative id to load.
        onlyFieldDefinitionId: '<', // Optional. The field definition id of a specific field we want the user to give an update for. Only the field update will be displayed.
        customTriggerId: '<', // Optional. The custom trigger id that hold the form answered button.
        workerRunLogicActionId: '<',
        createdByCustomTriggerId: '<',
        workerRunLogicActionType: '<',

        onlyInnerItems: '<', // Optional. When true, will only allow to update inner items for the given initiativeId.
        onLoadInitiativeDone: '&', // Called when loadInitiative returns. Supplies 1 parameter: getInitiativeByIdData.
        onInitiativeClicked: '&', // Called when inner initiative clicked, param: initiative.
    },
    template,
    controller: 'UpdateInitiativeStateCtrl',
});
