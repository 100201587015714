import React from 'react';

const ProcessMapperGraphEdgeWrapper: React.ForwardRefRenderFunction<
    SVGSymbolElement,
    React.PropsWithChildren<{ id: string }>
> = ({ children, id }, ref) => {
    return (
        <symbol width={157} height={158} viewBox="0 0 157 158" id={id} ref={ref}>
            <rect
                width={132.042}
                height={133.287}
                rx={30}
                transform="matrix(.66667 -.74535 .72184 .69206 5.694 29.17)"
                fillOpacity={0.28}
                // We don't set fill color to infer it from the styling on the "use" element, to allow us to
                // change the fill when selected.
            />
            <>{children}</>
        </symbol>
    );
};

export default React.forwardRef(ProcessMapperGraphEdgeWrapper);
