import React from 'react';

import { HashtagIcon as NumberIcon } from '@tonkean/svg';
import { CalendarIcon as DateIcon } from '@tonkean/svg';
import { ChevronCircleIcon as ListIcon } from '@tonkean/svg';
import { TextIcon } from '@tonkean/svg';
import { FieldType } from '@tonkean/tonkean-entities';

interface Props {
    type: FieldType;
}

const FieldSelectorValueTypeIcons: React.FC<Props> = ({ type }) => {
    switch (type) {
        case FieldType.String:
            return <TextIcon />;
        case FieldType.Number:
            return <NumberIcon />;
        case FieldType.Date:
            return <DateIcon />;
        case FieldType.List:
            return <ListIcon />;
        case FieldType.LongString:
            return <TextIcon />;
        default:
            return <></>;
    }
};

export default FieldSelectorValueTypeIcons;
