import type { AngularServices } from 'angulareact';
import { STATUSES, TONKEAN_ENTITY_TYPE } from '@tonkean/constants';
import { DeprecatedDate } from '@tonkean/utils';
import { calculateProgressAndInnerItemWarn } from '@tonkean/tonkean-utils';
import type {
    FieldDefinition,
    FieldInstance,
    Initiative,
    SpecialFieldsKey,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

class EntityInitiativeHelper {
    public static $inject: (string & keyof AngularServices)[] = ['$rootScope', 'timeUtils'] as string[];

    constructor(
        private $rootScope: AngularServices['$rootScope'],
        private timeUtils: AngularServices['timeUtils'],
    ) {}

    /**
     * Enrich person entity by type.
     * @param entity an Object with Id.
     */
    enrichEntity(entity) {
        if (entity && entity.id && angular.isString(entity.id)) {
            switch (0) {
                case entity.id.indexOf('INIT'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.INITIATIVE;
                    return this.enrichInitiative(entity);

                    break;
                }
                // No default
            }
        }
        return entity;
    }

    /**
     * enrich the inquiry with data from the consts
     */
    enrichInitiative(initiative: Initiative) {
        if (initiative) {
            let blocked = false;

            initiative.trackId = initiative.id;

            if (initiative.eta) {
                initiative.etaDays = this.timeUtils.daysDiff(initiative.eta);
            }

            if (initiative.stateColor === STATUSES.BLOCKED.color) {
                blocked = true;
            }

            if (!initiative.updater && initiative.updateText && initiative.updateText.length) {
                // hotfix for some cases where the initiaitve was created from a copy or from a template and have update text but not updater
                // we set the owner or the creator
                initiative.updater = initiative.owner || initiative.creator;
            }

            initiative.isDone = initiative.status === 'DONE';

            if (!initiative.metadata) {
                initiative.metadata = {};
            }

            const innerCalculations = calculateProgressAndInnerItemWarn(initiative);
            initiative.blocked = blocked;
            initiative.innerItemWarn = innerCalculations.innerItemWarn;
            let progress =
                innerCalculations.totalCount > 0 ? innerCalculations.doneCount / innerCalculations.totalCount : 0;

            if (initiative.status === 'DONE') {
                progress = 1;
            } else if (initiative.status === 'ARCHIVE') {
                initiative.archived = true;
            }

            if (progress > 0) {
                initiative.metadata.progress = progress;
            }

            initiative.complex = initiative.hasRelatedInitiatives;

            // Next gather can be in the past if the user has the NO_ALL flag and we don't send him anything
            if (initiative.nextGatherUpdate && initiative.nextGatherUpdate < new Date()) {
                initiative.nextGatherUpdate = null;
            }

            if (initiative.dueDate && initiative.eta) {
                initiative.late = initiative.dueDate < initiative.eta;
                initiative.lateTime = initiative.dueDate < initiative.eta;
            }
            if (initiative.dueDate) {
                initiative.late = initiative.late || this.timeUtils.daysDiff(initiative.dueDate) > 0;
                initiative.lateTime = initiative.lateTime || DeprecatedDate.nowAsDate() > initiative.dueDate;
            }
            if (initiative.eta) {
                initiative.late = initiative.late || this.timeUtils.daysDiff(initiative.eta) > 0;
                initiative.lateTime = initiative.lateTime || DeprecatedDate.nowAsDate() > initiative.eta;
            }

            initiative.tracked = initiative.gatherUpdatesPreferences && !!initiative.gatherUpdatesPreferences.length;

            if (initiative.group) {
                initiative.groupId = initiative.group.id;
            }

            if (initiative.fields) {
                const definitions: Record<
                    TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}` | SpecialFieldsKey,
                    FieldDefinition
                > = {};

                initiative.defIdToFieldsMap = {};
                initiative.defIdToValidFieldsMap = {};

                for (let i = 0; i < initiative.fields.length; i++) {
                    const field = initiative.fields[i];

                    if (field && field.fieldDefinition) {
                        definitions[field.fieldDefinition.id] = field.fieldDefinition;

                        if (!initiative.defIdToFieldsMap[field.fieldDefinition.id]) {
                            initiative.defIdToFieldsMap[field.fieldDefinition.id] = [];
                        }

                        // To avoid cases in which we add empty fields (if they're not enriched), we make sure the field has a created date before pushing it in.
                        if (field.created) {
                            initiative.defIdToFieldsMap[field.fieldDefinition.id].push(field);
                        }

                        if (!initiative.defIdToValidFieldsMap[field.fieldDefinition.id]) {
                            initiative.defIdToValidFieldsMap[field.fieldDefinition.id] = [];
                        }

                        if (
                            field.value &&
                            (field.isAssociated ||
                                field.fieldDefinition.type === 'MANUAL' ||
                                field.fieldDefinition.definitionSource === 'MANUAL')
                        ) {
                            initiative.defIdToValidFieldsMap[field.fieldDefinition.id]!.push(field);
                        }
                    }
                }

                // Go over the field definitions and calculated and save the final value to our evaluatedDisplayValue.
                initiative.fieldDefinitionSortValueMap = {};
                // A map that indicates if this initiative's field definition has multiple fields results.
                initiative.multipleFieldsMap = {};
                for (const id in definitions) {
                    // If this initiative has a field (or fields) for this field definition.
                    if (initiative.defIdToFieldsMap.hasOwnProperty(id)) {
                        // Set the field's value for sorting.
                        initiative.fieldDefinitionSortValueMap[id] = this.setFieldSortValue(
                            initiative,
                            definitions[id],
                        );

                        // Set an indicator for multiple fields.
                        initiative.multipleFieldsMap[id] = this.getMultipleFieldFlag(initiative, definitions[id]);
                    }
                }
            }

            if (initiative.externalSource && (this.$rootScope as any).pm.project) {
                const projectIntegration = (this.$rootScope as any).pm.project.integrations.find(
                    (projectIntegration) =>
                        projectIntegration.integration.integrationUniqueType === initiative.externalSource,
                );

                if (projectIntegration) {
                    initiative.externalSourceFriendly = projectIntegration.displayName;
                }
            }
        }

        return initiative;
    }

    /**
     * Set the given field's sort value (the value used for sorting in the list).
     */
    setFieldSortValue(item: Initiative, fieldDef: FieldDefinition): string | number | undefined {
        if (!item || !item.created || !fieldDef || !item.defIdToFieldsMap![fieldDef.id]) {
            return ' ';
        }

        // We are doing this in here because of angular binding.
        const fields = item.defIdToValidFieldsMap![fieldDef.id];

        if (!fields) {
            // Manual without a value.
            return this.filterFieldVal(item.defIdToFieldsMap![fieldDef.id]![0]!, fieldDef.evaluatedDisplayType);
        } else if (fields.length === 1) {
            // Only one field.
            return this.filterFieldVal(fields[0], fieldDef.evaluatedDisplayType);
        } else if (fields.length > 1) {
            return 'Multiple';
        }
    }

    /**
     * Set a multiple value flag if there's more than one field per definition.
     */
    private getMultipleFieldFlag(item: Initiative, fieldDef: FieldDefinition): boolean {
        let multipleFlag = false;

        if (!item || !item.created || !fieldDef || !item.defIdToFieldsMap![fieldDef.id]) {
            return multipleFlag;
        }

        const fields = item.defIdToValidFieldsMap![fieldDef.id];

        if (fields!.length > 1) {
            multipleFlag = true;
        }

        return multipleFlag;
    }

    /**
     * Do our own "filter" on the SINGLE given field so it's displayed in a pretty way.
     */
    private filterFieldVal(field: FieldInstance | undefined, type: string): string | number | undefined {
        if (!field) {
            return ' ';
        }

        const val = field.value;

        switch (type) {
            case 'Number': {
                return val ? Number.parseFloat(val) : 0;
            }
            case 'Date': {
                return field.dateValue || (val ? new Date(val).getTime() : 0);
            }
            default: {
                const defaultValue = val && this.isNumeric(val) ? Number(val) : val;
                return field.dateValue || defaultValue;
            }
        }
    }

    private isNumeric(n: unknown): boolean {
        return !isNaN(Number(n)) && Number.isFinite(n);
    }
}

angular.module('tonkean.shared').service('entityInitiativeHelper', EntityInitiativeHelper);
