<div class="flex-vmiddle margin-top flex-xs-wrap">
    <div class="flex-grow">
        <!-- Displayed when field is associated - showing what it is associated with -->
        <div ng-if="data.field.isAssociated && data.integration">
            <div class="flex">
                <i class="fa fa-check common-color-primary margin-right-xs margin-top-xxs"></i>
                <div>
                    <div>
                        Connected to
                        <span class="common-bold common-color-light-grey">
                            {{ data.field.fieldDefinition.definition.FieldName }}
                        </span>
                        of
                        <a href="{{ data.connectedItem.externalUrl }}" target="_blank">
                            {{ data.connectedItem.title }}
                        </a>
                        ({{ data.field.fieldDefinition.projectIntegration.displayName }})
                    </div>
                    <div class="margin-top">
                        <button type="button" class="btn btn-secondary" ng-click="enterEditMode()" ng-show="!editing">
                            Edit connected {{ data.field.fieldDefinition.projectIntegration.displayName }} item
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Displayed when field is not associated - showing a message indicating we couldn't match the field -->
        <div ng-if="!data.field.isAssociated" class="flex info-box">
            <i class="fa fa-info-circle common-color-danger margin-right margin-top-xxs"></i>
            <div class="common-size-xs common-color-dark-grey">
                Couldn't find a matching {{ data.field.fieldDefinition.projectIntegration.displayName }} item for
                <strong>"{{ data.initiative.title }}"</strong>
                , connect this field manually below.
            </div>
        </div>

        <div ng-show="editing || !data.field.isAssociated" class="flex-vmiddle common-size-sm margin-top-lg">
            <span class="flex-no-shrink margin-right create-field-definition-modal-label">
                Search for {{ data.field.fieldDefinition.projectIntegration.displayName }} item:
            </span>
            <tnk-auto-complete-external-item
                class="form-control"
                external-source="data.field.fieldDefinition.projectIntegration.integration.integrationUniqueType"
                external-type="data.field.fieldDefinition.definition['ExternalType']"
                external-type-display-name="data.field.fieldDefinition.definition.entityMetadata.label"
                external-item="data.item"
                search-term="data.externalSearchTerm"
                group-id="data.groupId"
            ></tnk-auto-complete-external-item>
        </div>
    </div>
</div>
<hr />
