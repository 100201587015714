function LogoutCtrl($scope, authenticationService) {
    $scope.data = {
        fullLoading: false,
    };

    $scope.init = function () {
        $scope.fullLoading = true;
        authenticationService.logout();
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('LogoutCtrl', LogoutCtrl);
