import template from './tnkCustomTriggerSelector.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkCustomTriggerSelector', {
    bindings: {
        workflowVersionId: '@',
        selectedCustomTriggerId: '@',
        parentCustomTriggerId: '@',
        customTriggerTypes: '<',
        customTriggerSecondaryTypes: '<',
        customTriggerIdToExclude: '<',
        emptyStateMessage: '@',
        onCustomTriggerSelected: '&',
        dontAutoSelectFirstOne: '<',
        allowClear: '<',
    },
    template,
    controller: 'customTriggerSelectorCtrl',
});
