import { TrackActionTypes } from '@tonkean/flux';
import { TrackDispatcher } from '@tonkean/flux';
import Utils from '@tonkean/utils';
import TonkeanStore from './TonkeanStore';

/**
 * The main track store.
 * Since we are still in an AngularJS app, the store doesn't actively manage it's data, but delegates everything to the TrackHelper.
 */
class TrackStore extends TonkeanStore {
    /* @ngInject */

    constructor($rootScope, trackHelper) {
        // Initialize our super with the dispatchers we need.
        super([TrackDispatcher]);

        this.rootScope = $rootScope;
        this.trackHelper = trackHelper;

        // Dictionary from editor id to bulk selection information - whether bulk selection is selected,
        // and whether "All" option is selected.
        this.editorBulkSelectionStateMap = {};
    }

    getNewTrackState(id, editorId) {
        // Create the editor items dictionary if it doesn't exist.
        if (!this.states[editorId]) {
            this.states[editorId] = {};

            this.editorBulkSelectionStateMap[editorId] = {
                bulkSelectionMode: false,
                selectedAllSelected: false,
            };
        }

        // Reset the track state of the given id to a new one: requesting a new track state means the component was re-constructed.
        this.states[editorId][id] = {
            track: this.trackHelper.getInitiativeFromCache(id),
            statusDropdownOpen: false,
            setReminderDropdownOpen: false,
            trackSwipeShownInMobile: false,
            trackQuickAddToggle: false,
            trackQuickAddTooltipShown: false,
            bulkSelectionMode: !!this.editorBulkSelectionStateMap[editorId].bulkSelectionMode,
            bulkSelected: !!this.editorBulkSelectionStateMap[editorId].selectedAllSelected,
            titleEditedValue:
                this.states[editorId] && this.states[editorId][id] ? this.states[editorId][id].titleEditedValue : null,
            editTitleMode:
                this.states[editorId] && this.states[editorId][id] ? this.states[editorId][id].editTitleMode : false,
            editFieldMode: false,
            loadingMovingItem: false,
        };

        // Return the state to the component.
        return this.states[editorId][id];
    }

    getTrackState(id, editorId) {
        return this.states[editorId][id];
    }

    /**
     * A helper function for our components.
     * @param trackId - the component's id.
     * @param editorId - the component's editor id.
     * @param requestedIdsObject - the idsObject the component has received via the store's emit function (contains the id and editor id, if any).
     * @returns {boolean} - true if the component should re-render itself.
     */
    shouldTrackUpdate(trackId, editorId, requestedIdsObject) {
        // If there's no id and not editor id, all tracks should re-render.
        if (!requestedIdsObject) {
            return true;
        }
        // No editor specified - all components with the requested track id should re-render.
        if (!requestedIdsObject.editorId && trackId === requestedIdsObject.id) {
            return true;
        }
        // No id specified - all components of this editor should re-render.
        if (!requestedIdsObject.id && editorId === requestedIdsObject.editorId) {
            return true;
        }
        // The specified id and editor id match this tracks ids - re-render.
        if (trackId === requestedIdsObject.id && editorId === requestedIdsObject.editorId) {
            return true;
        }

        return false;
    }

    destroyEditor(editorId) {
        this.states[editorId] = null;
        this.editorBulkSelectionStateMap[editorId] = null;
    }

    /**
     * A helper function that constructs a new idsObject according to the given action.
     * If the action supplied an id and/or editorId - they will be populated in the object.
     * @param action - the action that was called.
     * @returns {{id, editorId: *|null|string}}
     */
    static getIdsObject(action) {
        return {
            id: action.id,
            editorId: action.editorId,
        };
    }

    /**
     * The listener function that waits for our dispatcher to dispatch a new update.
     * @param action - the action the dispatcher dispatched.
     */
    onDispatch(action) {
        switch (action.type) {
            case TrackActionTypes.FIELD_DEFINITIONS_UPDATED:
            case TrackActionTypes.NEW_FULL_DATA_UPDATED:
                // We should emit an update without an id so all the components re-render.
                this.emit();
                break;

            case TrackActionTypes.TRACK_DUE_DATE_UPDATED:
            case TrackActionTypes.TRACK_CREATE_DONE:
            case TrackActionTypes.TRACK_OWNER_UPDATED:
            case TrackActionTypes.TRACK_FUNCTION_UPDATED:
            case TrackActionTypes.TRACK_TAGS_UPDATED:
            case TrackActionTypes.TOGGLE_TRACK_EXPANDED:
            case TrackActionTypes.TRACK_TITLE_UPDATED:
            case TrackActionTypes.TRACK_DESCRIPTION_UPDATED:
            case TrackActionTypes.TRACK_ARCHIVED:
            case TrackActionTypes.TRACK_VISIBLE_TO_OWNER_UPDATED:
            case TrackActionTypes.TRACK_NEXT_GATHER_UPDATES_UPDATED:
            case TrackActionTypes.TRACK_FIELD_UPDATED:
            case TrackActionTypes.TRACK_STATUS_UPDATED:
            case TrackActionTypes.TRACK_DONE_RELATED_ITEMS_UPDATED:
            case TrackActionTypes.TRACK_RELATED_ITEMS_UPDATED:
            case TrackActionTypes.TRACK_DATA_UPDATED:
            case TrackActionTypes.TRACK_START_TIME_UPDATED:
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACK_MOVING:
                this.states[action.editorId][action.id].loadingMovingItem = action.isTrue;
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TOGGLE_TRACK_SET_REMINDER_DROPDOWN:
                if (this.states[action.editorId]) {
                    this.states[action.editorId][action.id].setReminderDropdownOpen = action.isTrue;
                    this.emit(TrackStore.getIdsObject(action));
                }
                break;

            case TrackActionTypes.TRACK_SWIPE_IN_MOBILE_TOGGLE:
                if (action.isTrue) {
                    const editorState = this.states[action.editorId];
                    // go over the item in the editor
                    for (const id in editorState) {
                        // try to find an item that has threedots shown and is not our item
                        if (id !== action.id && editorState[id].trackSwipeShownInMobile) {
                            // if found, fire an event to hide threedots
                            this.states[action.editorId][id].trackSwipeShownInMobile = false;
                            this.states[action.editorId][id].trackQuickAddToggle = false;
                            this.emit(TrackStore.getIdsObject({ id, editorId: action.editorId }));
                            break;
                        }
                    }
                }

                this.states[action.editorId][action.id].trackSwipeShownInMobile = action.isTrue;
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACKS_TRACK_QUICK_ADD_HOVER_TOGGLE:
                if (!action.isTrue) {
                    this.states[action.editorId][action.id].trackSwipeShownInMobile = false;
                    this.states[action.editorId][action.id].trackQuickAddTooltipShown = false;
                }
                this.states[action.editorId][action.id].trackQuickAddToggle = action.isTrue;
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACKS_TRACK_QUICK_ADD_TOOLTIP_TOGGLE:
                this.states[action.editorId][action.id].trackQuickAddTooltipShown = action.isTrue;
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACKS_BULK_SELECT_TOGGLE:
                // Toggle the bulk select mode and notify all components.
                const bulkSelectionPropertiesToChange = ['bulkSelectionMode'];
                // if we are toggling the bulk selection mode off, let all the items know they should also deselect
                // themselves so in the next bulk select they will not be selected
                if (!action.isTrue) {
                    bulkSelectionPropertiesToChange.push('bulkSelected');
                }
                this.editorBulkSelectionStateMap[action.editorId].bulkSelectionMode = action.isTrue;
                Utils.updateDictionaryValuesProperty(
                    this.states[action.editorId],
                    bulkSelectionPropertiesToChange,
                    action.isTrue,
                );
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACK_BULK_SELECTION_STATE_CHANGED:
                this.states[action.editorId][action.id].bulkSelected = action.selectionState;
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACKS_BULK_SELECT_ALL:
                Utils.updateDictionaryValuesProperty(this.states[action.editorId], ['bulkSelected'], true);
                this.editorBulkSelectionStateMap[action.editorId].selectedAllSelected = true;
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACKS_BULK_SELECT_NONE:
                Utils.updateDictionaryValuesProperty(this.states[action.editorId], ['bulkSelected'], false);
                this.editorBulkSelectionStateMap[action.editorId].selectedAllSelected = false;
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACK_TITLE_EDIT_VALUE_UPDATED:
                this.states[action.editorId][action.id].titleEditedValue = action.titleEditedValue;
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACK_EDIT_TITLE_TOGGLE:
                this.states[action.editorId][action.id].editTitleMode = action.isTrue;
                this.rootScope.$broadcast('blockInitiativeViewEscape', action.isTrue);
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.TRACK_EDIT_FIELD_TOGGLE:
                this.states[action.editorId][action.id].editFieldMode = action.isTrue
                    ? action.fieldDefinitionId
                    : false;
                this.rootScope.$broadcast('blockInitiativeViewEscape', action.isTrue);
                this.emit(TrackStore.getIdsObject(action));
                break;

            case TrackActionTypes.EDITOR_COLUMN_WIDTH_UPDATED:
                this.emit(TrackStore.getIdsObject(action));
                break;
        }
    }
}

angular.module('tonkean.app').service('trackStore', TrackStore);
