import { useEffect, useMemo, useRef } from 'react';

const useFocus = () => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    // Modals focus trap interrupts how autoFocus normally works on inputs, it focuses then selects the entire text.
    // That is unwanted behaviour so we have to make it work in another way
    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 100);
    }, []);

    return useMemo(() => {
        return { inputRef };
    }, [inputRef]);
};

export default useFocus;
