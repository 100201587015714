/**
 * Overrides AngularJS's default exception handler, and reports to Sentry (using it's js library) as well as logging the error.
 * Sentry is only initialized if needed, i.e if we are in the test or prod environments.
 * If sentry is not needed, exceptions are logged normally to the console.
 * @param environment {Environment} - environment service.
 * @param $window - the window service, users to get hold of the Sentry object.
 * @param $log - the logging service needed to log the exceptions through AngularJS.
 * @returns {Function} - returns a logging function that matches the current environment (and uses Sentry if needed to report errors).
 */
function ExceptionHandler(environment, $window, $log) {
    /* jshint unused:false*/

    // If Sentry was loaded to the window context.
    if (
        $window &&
        typeof $window.Sentry !== 'undefined' &&
        $window.Sentry &&
        environment &&
        environment.sentry &&
        environment.sentry.enable &&
        environment.sentry.exceptionHandler
    ) {
        console.log('Using Sentry exception handler.');
        try {
            return function (exception, cause) {
                // eslint-disable-line no-unused-vars
                // Log the exception normally.
                $log.error.apply($log, arguments);
                // Also report to Sentry.
                Sentry.captureException(exception);
            };
        } catch (error) {
            // The the failure to load Sentry.
            console.error('Failed to load Sentry!', error);
            console.log('Using default logging exception handler.');
            // Log errors normally.
            return function (exception, cause) {
                // eslint-disable-line  no-unused-vars
                $log.error.apply($log, arguments);
            };
        }
    }

    // In any other case, log errors normally.
    console.debug('Using default logging exception handler.');
    return function (exception, cause) {
        // eslint-disable-line  no-unused-vars
        $log.error.apply($log, arguments);
    };
}

export default angular.module('tonkean.app').factory('$exceptionHandler', ExceptionHandler);
