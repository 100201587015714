import React from 'react';
import DefaultHistoryReason from './DefaultHistoryReason';

export default class CustomTriggerHistoryReason extends DefaultHistoryReason {
    constructor(props) {
        super(props);
    }

    getTitle() {
        if (
            this.props.item.reasonMetadata &&
            this.props.item.reasonMetadata.triggersNames &&
            this.props.item.reasonMetadata.triggersNames.length
        ) {
            return (
                <div>
                    {this.props.person.name} was notified that {this.renderTitleLink()} was triggered for "
                    {this.props.item.reasonMetadata.triggersNames.join('", "')}"
                </div>
            );
        } else {
            return super.getTitle();
        }
    }
}
