import { getStateError } from '@tonkean/utils';

function CopyToListCtrl(
    $rootScope,
    $scope,
    $state,
    $uibModalInstance,
    projectManager,
    tonkeanService,
    customTrigger,
    groupId,
    includeCurrentGroup,
) {
    $scope.data = {
        customTrigger,
        groupId,
        allAvailableGroups: [], // will hold all groups apart from the group you opened the modal from.
        copyIntoGroup: null,
        error: null,
        savingLoading: false,
    };

    /**
     * Modal initialization function.
     */
    $scope.init = function () {
        $scope.loading = true;
        $scope.data.allAvailableGroups = projectManager.groups.filter(
            (filteredGroup) => includeCurrentGroup || filteredGroup.id !== groupId,
        );

        // We automatically choose the first group in the data.allAvailableGroups.
        if ($scope.data.allAvailableGroups && $scope.data.allAvailableGroups.length) {
            $scope.data.copyIntoGroup = $scope.data.allAvailableGroups[0];
        }

        tonkeanService
            .getCustomTriggerDependenciesDisplayNameMap(
                $scope.data.customTrigger.node.id,
                $scope.data.customTrigger.node.workflowVersion.id,
            )
            .then((result) => {
                $scope.data.customTriggersToCopy = result.customTriggers;
                $scope.data.statesToCopy = result.states;
                $scope.data.formsToCopy = result.forms;
                $scope.data.projectIntegrationsToCopy = result.projectIntegrations;
                $scope.data.fieldDefinitionsToCopy = result.fieldDefinitions;
                $scope.loading = false;
            })
            .catch((error) => {
                $scope.data.error = getStateError(error);
            })
            .finally(() => ($scope.loading = false));
    };

    /**
     * Closes the modal.
     */
    $scope.close = function () {
        $scope.$close();
    };

    /**
     * Occurs once you decide to copy the field.
     */
    $scope.copy = function () {
        $scope.data.savingLoading = true;
        tonkeanService
            .copyCustomTriggerToGroup(
                $scope.data.customTrigger.node.id,
                $scope.data.copyIntoGroup.id,
                $scope.data.customTrigger.node.workflowVersion.id,
            )
            .then((result) => {
                if (!!result.hasErrors) {
                    $rootScope.$emit('alert', {
                        msg: `An error occurred while trying to copy "${$scope.data.customTrigger.node.displayName}" to module "${$scope.data.copyIntoGroup.name}"`,
                        type: 'danger',
                    });
                } else {
                    $rootScope.$emit('alert', {
                        msg: `Successfully copied "${$scope.data.customTrigger.node.displayName}" to module`,
                        type: 'success',
                        linkText: `${$scope.data.copyIntoGroup.name}`,
                        linkTarget: '_blank',
                        linkHref: $state.href(
                            'product.workerEditor',
                            { g: $scope.data.copyIntoGroup.id },
                            { absolute: true },
                        ),
                    });
                }
                $scope.close();
            })
            .catch((error) => ($scope.data.error = getStateError(error)))
            .finally(() => ($scope.data.savingLoading = false));
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('CopyToListCtrl', CopyToListCtrl);
