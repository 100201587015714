import React from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { IconSvg, LoadingCircle, TextEllipsis } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { CheckIconSVG as CheckIcon } from '@tonkean/svg';
import { XIcon as XIconIcon } from '@tonkean/svg';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import type { ButtonProps } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const ButtonContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const StyledIconSvg = styled(IconSvg)`
    margin-right: 0 !important;
`;

interface Props extends StyledComponentsSupportProps, ButtonProps {
    customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    background: Color;
    displayText: string;
    disabled?: boolean;
    disabledTooltipText?: string;
    dontShowAsDisabled?: boolean;
    onButtonClicked?(): void;
}

const TriggerActionButton: React.FC<Props> = ({
    customTriggerId,
    initiativeId,
    background,
    displayText,
    disabled,
    disabledTooltipText,
    dontShowAsDisabled,
    className,
    shape,
    size,
    onButtonClicked,
}) => {
    const [{ loading, error, called }, triggerButtonCustomTrigger] =
        useLazyTonkeanService('triggerButtonCustomTrigger');

    const { applyFastReloadInterval } = useItemInterfaceContext();

    return (
        <Tooltip
            content={disabledTooltipText}
            disabled={!disabled || !disabledTooltipText}
            dataAutomation="action-button-tooltip"
        >
            <UserThemedClickableButton
                className={className}
                data-automation="action-button"
                background={background}
                disabled={loading || disabled || !initiativeId}
                $dontShowAsDisabled={dontShowAsDisabled}
                onClick={async (event) => {
                    if (initiativeId) {
                        await triggerButtonCustomTrigger(customTriggerId, initiativeId, displayText);
                        onButtonClicked?.();
                        applyFastReloadInterval?.();
                    }
                    applyFastReloadInterval?.();
                    onButtonClicked?.();
                }}
                shape={shape}
                size={size}
                $autoSvgColor
            >
                <ButtonContentWrapper>
                    <TextEllipsis numberOfLines={1} tooltip>
                        {displayText}
                    </TextEllipsis>

                    {loading && <LoadingCircle inheritColor />}
                    {called && !loading && (
                        <StyledIconSvg
                            data-automation="trigger-action-button-success-error-sign"
                            as={error ? XIconIcon : CheckIcon}
                        />
                    )}
                </ButtonContentWrapper>
            </UserThemedClickableButton>
        </Tooltip>
    );
};

export default TriggerActionButton;
