import * as Yup from 'yup';

import { EnterpriseComponentAuthorizationType, EnterpriseComponentCertificateType } from '@tonkean/tonkean-entities';
import { yupEnum, yupTonkeanExpression } from '@tonkean/utils';

const EnterpriseComponentAuthorizationPageValidationSchema = Yup.object({
    type: yupEnum(EnterpriseComponentAuthorizationType, 'Authorization Type is not allowed').required(),
    baseUrl: yupTonkeanExpression().required(),
    integrationRequestInterceptorParameters: Yup.array(),
    certificationType: yupEnum(EnterpriseComponentCertificateType, 'Authorization Type is not allowed').required(),
    certificationDetails: Yup.object().when('certificationType', {
        is: 'TWO_WAY_TLS',
        then: Yup.object({
            privateKey: yupTonkeanExpression('Must set a value expression').required(),
            certificate: yupTonkeanExpression('Must set a value expression').required(),
            caCertificate: yupTonkeanExpression('Must set a value expression').required(),
        }),
    }),
});

export default EnterpriseComponentAuthorizationPageValidationSchema;
