import { useEffect, useMemo } from 'react';
import * as React from 'react';

import MarketplaceItemCreateModalDataSourceDataSourceSection from './MarketplaceItemCreateModalDataSourceDataSourceSection';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import { useFormikField } from '@tonkean/infrastructure';
import { EnterpriseComponentAuthorizationType } from '@tonkean/tonkean-entities';
import type {
    IntegrationType,
    ProjectIntegrationActionMarketplaceSummary,
    ProjectIntegrationEntityMarketplaceSummary,
    ProjectIntegrationParameterMarketplaceSummary,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

class Props {
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
}

const CreateMarketplaceDataSourceItemInfo: React.FC<Props> = ({ projectIntegrationId }) => {
    const { id: projectId } = useProject();

    const { setValue: setActions } =
        useFormikField<ProjectIntegrationActionMarketplaceSummary[]>('configuration.actions');
    const { setValue: setEntities } =
        useFormikField<ProjectIntegrationEntityMarketplaceSummary[]>('configuration.entities');
    const { setValue: setParameters } =
        useFormikField<ProjectIntegrationParameterMarketplaceSummary[]>('configuration.parameters');
    const { setValue: setIntegrationType } = useFormikField<IntegrationType>('configuration.integrationType');

    const { setValue: setAuthorizationType } = useFormikField<EnterpriseComponentAuthorizationType>(
        'configuration.authorizationType',
    );

    // Requesting data source.
    const { data: projectIntegration, loading: loadingProjectIntegration } = useTonkeanService(
        'getProjectIntegrationById',
        projectIntegrationId,
    );

    // Requesting all data source variables.
    const { data: parametersResponse, loading: loadingVariables } = useTonkeanService(
        'getEnterpriseComponentVariables',
        projectId,
        projectIntegrationId,
    );

    // Requesting all data source actions.
    const { data: actionsResponse, loading: loadingActions } = useTonkeanService(
        'getProjectIntegrationActions',
        projectIntegrationId,
    );

    // Requesting all data source entities.
    const { data: entitiesResponse, loading: loadingEntities } = useTonkeanService(
        'getProjectIntegrationEntitySummaries',
        projectIntegrationId,
    );

    // map entity to ProjectIntegrationEntityMarketplaceSummary
    const entities: ProjectIntegrationEntityMarketplaceSummary[] = useMemo(() => {
        return entitiesResponse?.entities?.map((entity) => ({ displayName: entity.displayName })) || [];
    }, [entitiesResponse?.entities]);

    // map parameter to ProjectIntegrationParameterMarketplaceSummary
    const parameters: ProjectIntegrationParameterMarketplaceSummary[] = useMemo(() => {
        let maxIndex =
            parametersResponse?.entities?.reduce(
                (max, parameter) => (parameter.index ?? 0 > max ? parameter.index : max),
                0,
            ) ?? 0;

        return (
            parametersResponse?.entities?.map((parameter) => ({
                displayName: parameter.displayName,
                isRequired: parameter.isRequired,
                requestOnConnect: true,
                hide: false,
                initialValue: '',
                index: parameter.index ?? ++maxIndex,
            })) || []
        );
    }, [parametersResponse?.entities]);

    // map actions to ProjectIntegrationParameterMarketplaceSummary
    const actions: ProjectIntegrationActionMarketplaceSummary[] = useMemo(() => {
        return (
            actionsResponse?.entities?.map((action) => ({
                displayName: action.displayName,
                description: action.description,
            })) || []
        );
    }, [actionsResponse?.entities]);

    // integration type of the marketplace item
    const integrationType = useMemo(() => {
        return projectIntegration?.integrationType;
    }, [projectIntegration?.integrationType]);

    // is loading one of the requests.
    const isLoading = loadingVariables || loadingActions || loadingEntities || loadingProjectIntegration;

    // Set initial Actions.
    useEffect(() => {
        setActions(actions);
    }, [actions, setActions]);

    // Set initial Entities.
    useEffect(() => {
        setEntities(entities);
    }, [entities, setEntities]);

    // Set initial Parameters.
    useEffect(() => {
        setParameters(parameters);
    }, [parameters, setParameters]);

    // Set initial Authentication Type
    useEffect(() => {
        setAuthorizationType(
            projectIntegration?.integration?.authorizationDetails?.type || EnterpriseComponentAuthorizationType.NONE,
        );
    }, [projectIntegration?.integration?.authorizationDetails?.type, setAuthorizationType]);

    // Set initial Integration Type.
    useEffect(() => {
        if (integrationType) {
            setIntegrationType(integrationType);
        }
    }, [integrationType, setIntegrationType]);

    return (
        <MarketplaceItemCreateModalDataSourceDataSourceSection
            entities={entities}
            actions={actions}
            parameters={parameters}
            authorizationType={
                projectIntegration?.integration?.authorizationDetails?.type || EnterpriseComponentAuthorizationType.NONE
            }
            loading={isLoading}
        />
    );
};

export default CreateMarketplaceDataSourceItemInfo;
