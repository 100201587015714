import template from './tnkPerformIntegrationActionLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkPerformIntegrationActionLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        invalidLogics: '<',
        onActionsChanged: '&',
        selectedProjectIntegration: '<',
        openAddNewIntegrationModal: '&',
        importActionConfig: '@',
        workflowFolderProjectIntegrationsAccess: '<',
    },
    template,
    controller: 'PerformIntegrationActionLogicConfigurationCtrl',
});
