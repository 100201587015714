import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import ImportEntityFieldsValueModal from '../ImportEntityFieldsValueModal/ImportEntityFieldsValueModal';

import { Input, TextEllipsis } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { BaseActionParameter, ProjectIntegration } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const EntityTypeNameWrapper = styled.div<{ isFirst: boolean }>`
    color: ${Theme.current.palette.mainColors.gray_600};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    width: 100%;
    display: inline-flex;
    line-height: 20px;
    margin-bottom: 10px;

    margin-top: ${({ isFirst }) => (isFirst ? '0' : '20px')};
`;

const ImportValuesButton = styled(Button)`
    margin-left: auto;
`;

const ParameterOfEntityNameWrapper = styled.div<{ isFirst: boolean }>`
    color: ${Theme.colors.gray_700};
    font-weight: 400;
    padding: 4px 5px;
    font-size: ${FontSize.SMALL_12};
    margin-bottom: 7px;
    background-color: ${Theme.colors.gray_300};
    width: fit-content;
    display: inline-flex;
    gap: 6px;
    align-items: center;
    border-radius: 3px;

    margin-top: ${({ isFirst }) => (isFirst ? '5px' : '20px')};
`;

const EntityParamLabel = styled.label`
    width: 100%;
`;

interface Props {
    onChange: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    entityParams: BaseActionParameter[];
    parameterIdToParameterValueRecord: Record<string, string>;
    projectIntegration: ProjectIntegration;
}

const ProjectIntegrationActionTestEntityParamsView: React.FC<Props> = ({
    onChange,
    entityParams,
    parameterIdToParameterValueRecord,
    projectIntegration,
}) => {
    // Indicates which entity id to import from (inside the modal)
    const [entityIdToImportFrom, setEntityIdToImportFrom] = useState<string | undefined>();
    // Indicates if import entity values modal is open with the given entity id
    const [isEntityListModalOpen, setIsEntityListModalOpen] = useState<boolean>(false);

    // Memo for all the action parameter grouped by entity.
    const recordOfEntityTypeToArrayOfParameters: Record<string, BaseActionParameter[]> = useMemo(() => {
        if (!entityParams) {
            return {};
        }
        const validEntityParams: BaseActionParameter[] = entityParams.filter((param) => param.entityType);

        return validEntityParams.reduce(
            (prevRecord: Record<string, BaseActionParameter[]>, currActionParameter: BaseActionParameter) => {
                const entityType: string = currActionParameter.entityType as string;
                const oldParameterArray = prevRecord[entityType];

                if (oldParameterArray) {
                    prevRecord[entityType] = [...oldParameterArray, currActionParameter];
                } else {
                    prevRecord[entityType] = [currActionParameter];
                }

                return prevRecord;
            },
            {},
        );
    }, [entityParams]);

    // On single input change when update our state.
    const onInputChange = useCallback(
        (newValue: string, parameterId: string) => {
            onChange((oldRecord) => ({ ...oldRecord, [parameterId]: newValue }));
        },
        [onChange],
    );

    // On click import from real entity button
    const onImportValuesButtonClick = useCallback((entityType: string) => {
        setIsEntityListModalOpen(true);
        setEntityIdToImportFrom(entityType);
    }, []);

    // On click on specific entity we filter only the relevant ids and close the modal
    const onEntityClicked = useCallback(
        (paramsKeyValues: Record<string, string>) => {
            onChange((prevState) => {
                const filteredFields = Object.fromEntries(
                    Object.entries(paramsKeyValues).filter(([key, value]) => {
                        return Object.keys(prevState).includes(key);
                    }),
                );
                return { ...prevState, ...filteredFields };
            });

            setIsEntityListModalOpen(false);
            setEntityIdToImportFrom(undefined);
        },
        [onChange],
    );

    return (
        <>
            {Object.entries(recordOfEntityTypeToArrayOfParameters).map(([entityType, entityParameters], index) => (
                <React.Fragment key={entityType}>
                    <EntityTypeNameWrapper isFirst={index === 0}>
                        <TextEllipsis>{entityType}</TextEllipsis>

                        <ImportValuesButton
                            onClick={() => onImportValuesButtonClick(entityType)}
                            size={ButtonSize.SMALL}
                            outlined
                        >
                            Import Values
                        </ImportValuesButton>
                    </EntityTypeNameWrapper>

                    {entityParameters.map((parameter, paramIndex) => (
                        <EntityParamLabel key={parameter.entityType + parameter.id}>
                            <ParameterOfEntityNameWrapper isFirst={paramIndex === 0}>
                                <ProjectIntegrationIcon
                                    width={12}
                                    iconUrl={projectIntegration.iconUrl}
                                    integrationType={projectIntegration.integration.integrationType}
                                />

                                <TextEllipsis tooltip>{parameter.displayName}</TextEllipsis>
                            </ParameterOfEntityNameWrapper>

                            <Input
                                className="form-control"
                                placeholder="Insert or import value"
                                value={parameterIdToParameterValueRecord[parameter.id] || ''}
                                onChange={({ target }) => onInputChange(target.value, parameter.id)}
                            />
                        </EntityParamLabel>
                    ))}
                </React.Fragment>
            ))}

            <ImportEntityFieldsValueModal
                entityName={entityIdToImportFrom || ''}
                isOpen={isEntityListModalOpen}
                projectIntegration={projectIntegration}
                onCancel={() => {
                    setIsEntityListModalOpen(false);
                    setEntityIdToImportFrom(undefined);
                }}
                onEntityClicked={onEntityClicked}
            />
        </>
    );
};

export default ProjectIntegrationActionTestEntityParamsView;
