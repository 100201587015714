<div>
    <div
        ng-if="data.tabs && data.tabs.length"
        class="tnk-side-pane-tabs flex flex-col"
        ng-class="{ [data.tabsCustomClass]: true, 'mod-open': data.isOpen}"
    >
        <div class="tnk-side-pane-tab mod-close">
            <div
                ng-if="!data.isOpen"
                class="svg-icon svg-icon-lg flex-vmiddle mod-center common-height-full pointer"
                ng-class="{ 'common-flip-horizontal': !data.isOpen }"
                ng-click="setIsOpen(!data.isOpen)"
            >
                <tnk-icon src="/images/icons/line-and-arrow-left.svg"></tnk-icon>
            </div>
        </div>

        <div class="tnk-side-pane-tabs-container">
            <div ng-repeat="tab in data.tabs">
                <hr ng-if="tab.isSpacer" />

                <div
                    ng-if="!tab.isSpacer"
                    data-automation="side-pane-{{ tab.key }}-tab"
                    class="tnk-side-pane-tab flex-vmiddle mod-align-center relative pointer"
                    ng-click="onTabSelected(tab)"
                    ng-class="{ 'mod-selected': tab.key === data.selectedTab.key }"
                    uib-tooltip="{{ tab.name }}"
                    tooltip-placement="right"
                    tooltip-class="tnk-side-pane-tab-tooltip"
                >
                    <div class="tnk-side-pane-icon-placeholder" ng-class="tab.iconClass"></div>
                    <div
                        class="tnk-side-pane-icon-indication"
                        ng-if="
                            (tab.showIndication && tab.showIndication()) ||
                            (tab.indicationState && tab.indicationState[tab.indicationStateKey])
                        "
                        ng-class="tab.indicationClass"
                    ></div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="tnk-side-pane {{ data.customClass }}"
        ng-class="{
            'mod-shown': data.isOpen,
            'mod-double': data.isOpen && data.doubleSizePane,
            'mod-left': data.isLeft,
            'mod-tabs': data.tabs && data.tabs.length
        }"
        prevent-scroll-bubbling="{{ !data.dontPreventScrollBubbling }}"
    >
        <div
            ng-if="data.isOpen"
            class="tnk-side-pane-inner"
            ng-class="{ 'cursor-pointer': data.paneDisabled && data.onDisabledClick }"
            blocked-element="{{ data.paneDisabled }}"
            data-blocked-message-production
            ng-click="data.paneDisabled && data.onDisabledClick()"
        >
            <div
                ng-if="data.tabs && data.tabs.length"
                class="
                    tnk-side-pane-tabs-topbar
                    common-bold common-color-white common-size-xs
                    flex-vmiddle
                    padding-left
                "
            >
                <div class="flex-grow" data-automation="side-pane-inner-title">{{ data.selectedTab ? data.selectedTab.name : '' }}</div>
                <div
                    class="svg-icon svg-icon-lg flex-vmiddle mod-center common-height-full margin-right pointer"
                    data-automation="side-pane-template-close-side-pane-button"
                    ng-class="{ 'common-flip-horizontal': !data.isOpen }"
                    ng-click="setIsOpen(!data.isOpen)"
                >
                    <tnk-icon src="/images/icons/line-and-arrow-left.svg"></tnk-icon>
                </div>
            </div>

            <div class="tnk-side-pane-content">
                <ng-transclude class="flex flex-grow" ng-if="data.isOpen"></ng-transclude>
            </div>
        </div>
    </div>
</div>
