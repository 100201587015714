import { useAngularService } from 'angulareact';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { analyticsWrapper } from '@tonkean/analytics';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import type {
    CustomSolutionSitePage,
    SolutionSitePage,
    SolutionSitePageCreateParams,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { BusinessAppsAnalyticsEvents, SolutionSitePageType } from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';

export interface SolutionSitePagesContextType {
    getPagesResponse: {
        loading: boolean;
        error?: ErrorResponseType;
        rerun(boolean?): void;
    };
    pagesReader: () => { entities: SolutionSitePage[] };
    selectedPage: CustomSolutionSitePage | undefined;

    createSolutionSitePage: (params: SolutionSitePageCreateParams) => Promise<SolutionSitePage>;
    createSolutionSitePageResponse: {
        data?: SolutionSitePage;
        loading: boolean;
        error?: ErrorResponseType;
    };

    updateSolutionSitePage: (
        solutionSitePageId: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>,
        params: SolutionSitePageCreateParams,
    ) => Promise<void>;
    updateSolutionSitePageResponse: {
        loading: boolean;
        error?: ErrorResponseType;
    };

    deleteSolutionSitePage: (solutionSitePageId: SolutionSitePage) => Promise<void>;
    swapSolutionSitePageIndices: (page: SolutionSitePage, secondPage: SolutionSitePage) => Promise<void>;

    changesCount: Record<SolutionSitePage['id'], number>;
    incrementChangesCount(): void;
}

export const SolutionSitePagesContext = React.createContext<SolutionSitePagesContextType | undefined>(undefined);

export const useBuildSolutionSitePagesContext = (solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE>) => {
    const [solutionSitePageId, solutionSitePageUrlSlug] = useGetStateParams<
        [TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>, string]
    >('solutionSitePageId', 'solutionSitePageUrlSlug');

    const getPagesResponse = useTonkeanService('getPagesOfSolutionSite', solutionSiteId);

    const [
        {
            data: dataCreatedSolutionSitePage,
            loading: loadingCreateSolutionSitePage,
            error: errorCreateSolutionSitePage,
        },
        createSolutionSitePageInner,
    ] = useLazyTonkeanService('createSolutionSitePage');

    const sendMixpanelEvent = useCallback(
        (
            eventName: string,
            solutionSitePageId: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>,
            params: SolutionSitePageCreateParams,
        ) => {
            analyticsWrapper.track(eventName, {
                solutionSiteId,
                solutionSitePageId,
                solutionSitePageType: params.type,
                solutionSitePageDisplayName: params.displayName,
                solutionSitePageUrlSlug: params.urlSlug,
                solutionSitePageDescription: params.description,
                solutionSitePageConfiguration: params.configuration,
            });
        },
        [solutionSiteId],
    );

    const getCurrentPage = useCallback(() => {
        // If a page is selected it cannot be external, therefore it has to be custom, which allows us to have easier types
        return getPagesResponse.data?.entities
            ?.filter((page): page is CustomSolutionSitePage => page.type === SolutionSitePageType.CUSTOM)
            ?.find((page) => page.id === solutionSitePageId || page.urlSlug === solutionSitePageUrlSlug);
    }, [getPagesResponse, solutionSitePageUrlSlug, solutionSitePageId]);

    useEffect(() => {
        if (getPagesResponse && !getPagesResponse.loading) {
            const currentPage = getCurrentPage();

            if (currentPage) {
                document.title = `Tonkean - ${currentPage.displayName}`;
            }
        }
    }, [getPagesResponse, solutionSitePageUrlSlug, solutionSitePageId, getCurrentPage]);

    const createSolutionSitePage = useCallback(
        async (params: SolutionSitePageCreateParams) => {
            if (!solutionSiteId) {
                throw new Error('Called to createSolutionSitePage but one of the necessary params was not initialized');
            }
            const createdSolutionSitePage = await createSolutionSitePageInner(solutionSiteId, params);
            getPagesResponse.rerun();

            sendMixpanelEvent(
                params.type === SolutionSitePageType.EXTERNAL
                    ? BusinessAppsAnalyticsEvents.CREATE_EXTERNAL_URL
                    : BusinessAppsAnalyticsEvents.CREATE_SOLUTION_SITE_PAGE,
                createdSolutionSitePage.id,
                params,
            );

            return createdSolutionSitePage;
        },
        [createSolutionSitePageInner, getPagesResponse, sendMixpanelEvent, solutionSiteId],
    );

    const [
        { loading: loadingUpdateSolutionSitePage, error: errorUpdateSolutionSitePage },
        updateSolutionSitePageInner,
    ] = useLazyTonkeanService('updateSolutionSitePage');
    const updateSolutionSitePage = useCallback(
        async (
            solutionSitePageIdParam: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>,
            params: SolutionSitePageCreateParams,
        ): Promise<void> => {
            if (!solutionSiteId) {
                throw new Error('Called to createSolutionSitePage but one of the necessary params was not initialized');
            }

            sendMixpanelEvent(
                params.type === SolutionSitePageType.EXTERNAL
                    ? BusinessAppsAnalyticsEvents.UPDATE_EXTERNAL_URL
                    : BusinessAppsAnalyticsEvents.UPDATE_SOLUTION_SITE_PAGE,
                solutionSitePageIdParam,
                params,
            );

            await updateSolutionSitePageInner(solutionSitePageIdParam, params);
            return getPagesResponse.rerun();
        },
        [getPagesResponse, sendMixpanelEvent, solutionSiteId, updateSolutionSitePageInner],
    );

    const $state = useAngularService('$state');
    const [, deleteSolutionSitePageInner] = useLazyTonkeanService('deleteSolutionSitePage');
    const deleteSolutionSitePage = useCallback(
        async (_solutionSitePage: SolutionSitePage): Promise<void> => {
            await deleteSolutionSitePageInner(_solutionSitePage.id);
            analyticsWrapper.track(
                _solutionSitePage.type === SolutionSitePageType.EXTERNAL
                    ? BusinessAppsAnalyticsEvents.DELETE_EXTERNAL_URL
                    : BusinessAppsAnalyticsEvents.DELETE_SOLUTION_SITE_PAGE,
                {
                    solutionSiteId,
                    solutionSitePageId: _solutionSitePage.id,
                    solutionSitePageType: _solutionSitePage.type,
                    solutionSitePageDisplayName: _solutionSitePage.displayName,
                    solutionSitePageUrlSlug: _solutionSitePage.urlSlug,
                    solutionSitePageDescription: _solutionSitePage.description,
                    solutionSitePageConfiguration: _solutionSitePage.configuration,
                },
            );

            if (solutionSiteId) {
                getPagesResponse.rerun();
            }

            if ($state.params.pageId === _solutionSitePage.id) {
                $state.go('.', { pageId: undefined }, { location: 'replace', notify: false });
            }
        },
        [$state, deleteSolutionSitePageInner, getPagesResponse, solutionSiteId],
    );

    const [, swapSolutionSitePagesIndices] = useLazyTonkeanService('swapSolutionSitePagesIndices');
    const swapSolutionSitePageIndices = useCallback(
        async (page: SolutionSitePage, secondPage: SolutionSitePage) => {
            await swapSolutionSitePagesIndices(page.solutionSiteId, page.id, secondPage.id);
            getPagesResponse.rerun(false);
        },
        [getPagesResponse, swapSolutionSitePagesIndices],
    );

    const [changesCount, setChangesCount] = useState<Record<SolutionSitePage['id'], number>>({});
    useEffect(() => {
        if (!getPagesResponse.data) return;

        const changes = Object.fromEntries(
            getPagesResponse.data?.entities?.map((page) => {
                if ('changesCount' in page.buildEntityVersion) {
                    return [page.id, page.buildEntityVersion.changesCount];
                }

                return [page.id, 0];
            }),
        );

        setChangesCount(changes);
    }, [getPagesResponse.data]);

    const incrementChangesCount = useCallback(() => {
        setChangesCount((prevChanges) => {
            return {
                ...prevChanges,
                [solutionSitePageId]: (prevChanges[solutionSitePageId] ?? 0) + 1,
            };
        });
    }, [solutionSitePageId]);

    const context = useMemo(() => {
        return {
            getPagesResponse,
            pagesReader: getPagesResponse.reader,
            selectedPage: getCurrentPage(),

            createSolutionSitePage,
            createSolutionSitePageResponse: {
                data: dataCreatedSolutionSitePage,
                loading: loadingCreateSolutionSitePage,
                error: errorCreateSolutionSitePage,
            },

            updateSolutionSitePage,
            updateSolutionSitePageResponse: {
                loading: loadingUpdateSolutionSitePage,
                error: errorUpdateSolutionSitePage,
            },
            deleteSolutionSitePage,
            swapSolutionSitePageIndices,

            changesCount,
            incrementChangesCount,
        };
    }, [
        changesCount,
        createSolutionSitePage,
        dataCreatedSolutionSitePage,
        deleteSolutionSitePage,
        errorCreateSolutionSitePage,
        errorUpdateSolutionSitePage,
        getCurrentPage,
        getPagesResponse,
        incrementChangesCount,
        loadingCreateSolutionSitePage,
        loadingUpdateSolutionSitePage,
        swapSolutionSitePageIndices,
        updateSolutionSitePage,
    ]);

    return context;
};

const useSolutionSitePagesContext = () => {
    const context = useContext(SolutionSitePagesContext);

    if (!context) {
        throw new Error('SolutionSitePagesContext used but no context provided!');
    }

    const solutionSitePages = context.pagesReader().entities;

    return { solutionSitePages, ...context };
};

export default useSolutionSitePagesContext;
