import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ProcessMapperLogo } from '../../../../images/icons/empty-state-process.svg';

import { Spacer } from '@tonkean/infrastructure';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    width: 230px;
    margin: 40px auto;
    text-align: center;
`;
const Title = styled.div`
    font-weight: 700;
    font-size: ${FontSize.LARGE_16};
`;
const Text = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
`;

interface Props {
    onOpenNewProcessMapperModal?(): void;
}
const ProcessMapperListEmptyState: React.FC<Props> = ({ onOpenNewProcessMapperModal }) => {
    return (
        <Wrapper>
            <ProcessMapperLogo />
            <Spacer height={20} />
            <Title>Welcome to Process Mapper</Title>
            <Spacer height={10} />
            <Text>Create a process map that describes each step of the business process and its current status.</Text>
            {onOpenNewProcessMapperModal && (
                <>
                    <Spacer height={10} />
                    <ClickableLink
                        data-automation="process-mapper-list-empty-state-create-new-process-mapper"
                        onClick={onOpenNewProcessMapperModal}
                    >
                        Create a new Process Map
                    </ClickableLink>
                </>
            )}
        </Wrapper>
    );
};

export default ProcessMapperListEmptyState;
