import CreateNewProjectIntegrationModal from './CreateNewProjectIntegrationModal';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkCreateNewProjectIntegration',
        reactToAngular(CreateNewProjectIntegrationModal, ['integration', 'integrationType', 'closeModal', 'setUp']),
    );
