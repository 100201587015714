import { useAngularService } from 'angulareact';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { getIsInWidgetBuilder } from '../../WidgetModule';
import getInterfaceWidgetElementId from '../getItemInterfaceElementId';
import InterfaceQuickNavigationContext from '../InterfaceQuickNavigationContext';

import { NO_ICON, TonkeanIconDisplay } from '@tonkean/icon-picker';
import { H4, TextEllipsis } from '@tonkean/infrastructure';
import type { InterfaceTab } from '@tonkean/tonkean-entities';
import {
    isTonkeanType,
    ItemInterfaceThemeTextAlignment,
    type TonkeanId,
    TonkeanType,
    type WidgetBase,
} from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const WidgetList = styled.div<{ $alignment: ItemInterfaceThemeTextAlignment }>`
    display: flex;
    gap: 24px;
    margin: 10px 0;
    align-items: center;
    ${({ $alignment }) => {
        if ($alignment === ItemInterfaceThemeTextAlignment.CENTER) {
            return css`
                justify-content: center;
            `;
        }

        if ($alignment === ItemInterfaceThemeTextAlignment.RIGHT) {
            return css`
                flex-direction: row-reverse;
            `;
        }
    }}
    overflow: auto;
`;

const StyledIconButton = styled(IconButton)`
    width: auto;
    flex-shrink: 0;

    padding: 4px 12px;
`;
interface Props {
    widgets: WidgetBase[];
    alignment: ItemInterfaceThemeTextAlignment;
}

const StandardInterfaceQuickNav: React.FC<Props> = ({ widgets, alignment }) => {
    const $state = useAngularService('$state');
    const isInBuilder = getIsInWidgetBuilder($state);

    const { setSelectedWidgetId, tabs } = useContext(InterfaceQuickNavigationContext);

    const navigationAnchors = tabs?.filter(
        (tab): tab is InterfaceTab & { key: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET> } =>
            isTonkeanType(tab.key, TonkeanType.ITEM_INTERFACE_WIDGET),
    );

    if (!navigationAnchors?.length) {
        return <></>;
    }

    return (
        <WidgetList $alignment={alignment}>
            {navigationAnchors.map((tab) => {
                const sidepaneWidgetChangeParams = isInBuilder
                    ? {
                          state: '.',
                          params: {
                              widgetId: tab.key,
                              currentTab: undefined,
                          },
                          options: { location: 'replace' },
                      }
                    : {};

                const elementId = getInterfaceWidgetElementId(tab.key);
                const iconSize = 16;
                const icon = tab.icon;
                return (
                    <StyledIconButton
                        data-automation={`standard-interface-quick-nav-${tab.label}`}
                        iconColor={icon?.iconColor}
                        key={tab.key}
                        onClick={(event) => {
                            document.getElementById(elementId)?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                            });

                            // If we are in builder the edited widget mechanism will take care of it,
                            // and we dont want to have multiple states on what widget is selected
                            if (!isInBuilder) {
                                setSelectedWidgetId?.(tab.key);
                            }

                            // In the ITEM interface builder there is a header that wraps this component which will override this behaviour,
                            // so we stop the event from reaching it
                            event.stopPropagation();
                        }}
                        leftIcon={
                            icon &&
                            icon.name !== NO_ICON && <TonkeanIconDisplay size={16} icon={icon} dontShowEmptyIcon />
                        }
                        iconSize={iconSize}
                        flat
                        {...sidepaneWidgetChangeParams}
                    >
                        <H4>
                            <TextEllipsis tooltip>{tab.label}</TextEllipsis>
                        </H4>
                    </StyledIconButton>
                );
            })}
        </WidgetList>
    );
};

export default StandardInterfaceQuickNav;
