import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { VIEW_TYPES } from '@tonkean/constants';

/* @ngInject */
const ViewProjectIntegrationDataCtrl = function ($scope, $timeout, $state, modalUtils, integrationHelper) {
    const ctrl = this;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,
        viewTypes: VIEW_TYPES,
        isFiltered: false,
        overrideActivateBotObject: {
            getInitialValue: () => false,
            shouldOverride: true,
            overrideValue: false,
        },
        previewItems: {
            control: {
                customDefinedView: true,
            },
        },
        fullScreen: false,
    };

    ctrl.$onInit = function () {};

    $scope.toggleFullscreen = function () {
        $scope.data.fullScreen = !$scope.data.fullScreen;
    };

    function compileViewData(projectIntegration, entity) {
        const compiledViewData = {};
        // Adding integration type to the existing definition as it is required for the control
        compiledViewData.integrationType = projectIntegration.integration.integrationType;
        compiledViewData.integrationUniqueType = projectIntegration.integration.integrationUniqueType;
        compiledViewData.entity = entity;

        // If we do not have a query set, that means the query is defined only by the user.
        // In such case, it is best for us to define a default match-all query to be used by all components.
        if (!compiledViewData.query) {
            compiledViewData.query = {
                type: 'And',
                filters: [],
                queries: [],
            };
        }
        return compiledViewData;
    }

    $scope.loadData = function (selectedEntity) {
        $scope.data.previewItems.selectedEntity = null;
        $timeout(function () {
            $scope.data.previewItems.selectedEntity = selectedEntity;
            $scope.data.previewItems.entityType = selectedEntity.pluralLabel;
            $scope.data.previewItems.definition = compileViewData(
                $scope.data.projectIntegration,
                $scope.data.previewItems.entityType,
            );
        });
    };

    $scope.refreshData = function () {
        $scope.loadData($scope.data.previewItems.selectedEntity);
    };

    $scope.openEditCustomWebhookItemModal = function (existingItem) {
        modalUtils.openEditCustomWebhookItemModal($scope.data.projectIntegration, existingItem).result.then(() => {
            if ($scope.data.previewItems.selectedEntity) {
                $scope.loadData($scope.data.previewItems.selectedEntity, $scope.data.projectIntegration);
            }
        });
    };

    $scope.toggleFilter = (isBeingFiltered) => {
        if ($scope.data.isFiltered === isBeingFiltered) {
            return;
        }

        $scope.data.isFiltered = isBeingFiltered;

        // Clears conditions when selecting the all option
        if (!isBeingFiltered) {
            $scope.loadData($scope.data.previewItems.selectedEntity);
        }
    };

    /**
     * Executes collect for the project integration.
     */
    $scope.runCollect = function () {
        if ($scope.data.runningCollect === $scope.data.projectIntegration.id) {
            return;
        }

        $scope.data.runningCollect = $scope.data.projectIntegration.id;

        // Sync now command.
        integrationHelper.runCollectOnProjectIntegration($scope.data.projectIntegration).then(() => {
            $scope.data.runningCollect = null;

            $scope.onCollectComplete($scope.data.projectIntegration);
        });
    };

    $scope.onCollectComplete = function () {
        if ($scope.data.previewItems.selectedEntity) {
            $scope.loadData($scope.data.previewItems.selectedEntity);
        }
    };

    $scope.navigateToConnectionsTab = function () {
        $state.go('product.projectIntegrationPage', {
            enterpriseComponentId: $scope.data.projectIntegration.id,
            fromState: 'product.projectIntegrationPage',
            fromStateParams: $state.params,
            fromName: `${$scope.data.projectIntegration.name} View Data`,
            page: ProjectIntegrationPageMenuItemType.CONNECTIONS,
        });
    };
};

export default angular
    .module('tonkean.app')
    .controller('ViewProjectIntegrationDataCtrl', lateConstructController(ViewProjectIntegrationDataCtrl));
