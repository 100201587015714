import React from 'react';
import styled from 'styled-components';

import TaggingOption from './TaggingOption';
import { ReactComponent as IntakeSequenceItemIcon } from '../../../../../images/icons/intake-interface-item.svg';
import type { TaggableEntitiesItemsProps } from '../../../entities';

import { FontSize, Theme } from '@tonkean/tui-theme';

const IconWrapper = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    background: ${Theme.colors.gray_300};
`;

const IntakeTitle = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    margin-left: 8px;
`;

const IntakeSequenceTagging: React.FC<TaggableEntitiesItemsProps> = ({
    entry,
    search,
    highlightedDisplay,
    index,
    focused,
}) => {
    return (
        <TaggingOption $focused={focused}>
            <IconWrapper>
                <IntakeSequenceItemIcon />
            </IconWrapper>
            <IntakeTitle>{entry.display}</IntakeTitle>
        </TaggingOption>
    );
};

export default IntakeSequenceTagging;
