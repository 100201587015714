import { useCallback, useMemo, useState } from 'react';

import type { EntityRetentionSummary } from './useDataRetentionConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import type { EntityDataRetentionSettings } from '@tonkean/tonkean-entities';
import type { EntityInboundConfiguration, TonkeanId } from '@tonkean/tonkean-entities';
import { TonkeanType } from '@tonkean/tonkean-entities';
import { tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';

function useSaveProjectIntegrationDataRetention(
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
): [{ loading: boolean; error?: any }, (entitiesRetention: EntityRetentionSummary[]) => Promise<void>] {
    const shouldShowInboundConfiguration = useFeatureFlag('tonkean_feature_entities_inbound_configuration');

    // The return value of this hook.
    const [data, setData] = useState<{ error?: any; loading: boolean }>({ loading: false });

    // should update native entities on save?
    const shouldUpdateNativeEntitiesIsCollectStatus = shouldShowInboundConfiguration;

    // update project integration native entity expiration date configuration
    const [, updateProjectIntegrationEntitiesExpirationConfiguration] = useLazyTonkeanService(
        'updateProjectIntegrationEntitiesExpirationConfiguration',
    );

    // update project integration native entity inbound configuration (collect + entity fetcher)
    const [, updateProjectIntegrationEntitiesInboundConfiguration] = useLazyTonkeanService(
        'updateProjectIntegrationEntitiesInboundConfiguration',
    );

    // update project integration custom entity collect configuration
    const [, updateProjectIntegrationEntity] = useLazyTonkeanService('updateProjectIntegrationEntityIsCollectEnabled');

    // Callback that updates all collect states and expiration date of custom and native entities.
    const saveUpdatedDataRetention = useCallback(
        async (entitiesRetention: EntityRetentionSummary[]) => {
            setData({ loading: true });

            const dataRetentionRequest: Record<string, EntityDataRetentionSettings> = Object.fromEntries(
                entitiesRetention
                    .filter((entity) => entity.id !== '')
                    .map((entity) => ({
                        id: entity.id,
                        // For some reason the server can't handle if both of them defined so if the isIndefinite is true then the expirationDays must be null
                        // and if expirationDays set then the isIndefinite must be null
                        isIndefinite: entity.retention.isIndefinite.value ? true : null,
                        expirationDays: entity.retention.isIndefinite.value
                            ? null
                            : entity.retention.expirationDays.value,
                    }))
                    .map((entitiesRetentionRequest) => [entitiesRetentionRequest.id, entitiesRetentionRequest]),
            );

            // Splitting the custom entities and the native entities (isCollectEnabled are required specific handling).
            const [customEntities, nativeEntities]: [
                (EntityRetentionSummary & { id: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ENTITY> })[],
                EntityRetentionSummary[],
            ] = entitiesRetention.reduce(
                (prev, curr) => {
                    if (curr.id.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.PROJECT_INTEGRATION_ENTITY])) {
                        return [
                            [...prev[0], curr] as (EntityRetentionSummary & {
                                id: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ENTITY>;
                            })[],
                            prev[1],
                        ];
                    } else {
                        return [prev[0], [...prev[1], curr]];
                    }
                },
                [[], []],
            );

            const nativeEntitiesCollectStatusEntries = nativeEntities
                .filter((nativeEntity) => !nativeEntity.collectStatus.isLoading)
                .map((nativeEntity) => [
                    nativeEntity.id,
                    {
                        collectConfiguration: { isCollectEnabled: nativeEntity.collectStatus.isEnabled },
                        livestreamConfiguration: { isLivestreamEnabled: nativeEntity.collectStatus.isEnabled },
                        entityType: nativeEntity.id,
                    },
                ]);

            const nativeEntitiesConfiguration: Record<string, EntityInboundConfiguration> = Object.fromEntries(
                nativeEntitiesCollectStatusEntries,
            );

            const updateExpirationConfigurationPromise = updateProjectIntegrationEntitiesExpirationConfiguration(
                projectIntegrationId,
                { entitiesConfiguration: dataRetentionRequest },
            );

            const updateNativeIsCollectEnabledConfigurationPromise = shouldUpdateNativeEntitiesIsCollectStatus
                ? updateProjectIntegrationEntitiesInboundConfiguration(
                      projectIntegrationId,
                      nativeEntitiesConfiguration,
                  )
                : Promise.resolve();

            const updateIsEnabledForCustomEntitiesPromises = customEntities
                .filter((customEntity) => !customEntity.collectStatus.isLoading)
                .map((customEntity) =>
                    updateProjectIntegrationEntity(
                        projectIntegrationId,
                        customEntity.id,
                        customEntity.collectStatus.isEnabled as boolean, // its safe because we filter out all the loading,
                    ),
                );

            try {
                await Promise.all([
                    updateExpirationConfigurationPromise,
                    updateNativeIsCollectEnabledConfigurationPromise,
                    ...updateIsEnabledForCustomEntitiesPromises,
                ]);

                setData({ loading: false, error: undefined });
            } catch (error) {
                setData({ loading: false, error });
            }
        },
        [
            projectIntegrationId,
            shouldUpdateNativeEntitiesIsCollectStatus,
            updateProjectIntegrationEntitiesExpirationConfiguration,
            updateProjectIntegrationEntitiesInboundConfiguration,
            updateProjectIntegrationEntity,
        ],
    );

    return useMemo(() => {
        return [data, saveUpdatedDataRetention];
    }, [data, saveUpdatedDataRetention]);
}

export default useSaveProjectIntegrationDataRetention;
