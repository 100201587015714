import React from 'react';

import { HighlightableText, TextEllipsis } from '@tonkean/infrastructure';

interface Props {
    name: string;
    searchTerm?: string;
}

const WorkerTileName: React.FC<Props> = ({ name, searchTerm = '' }) => {
    return (
        <TextEllipsis numberOfLines={1} tooltip>
            <HighlightableText data-automaiton="worker-title-name" text={name} highlightText={searchTerm} />
        </TextEllipsis>
    );
};

export default WorkerTileName;
