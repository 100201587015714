import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import type { InnerItemWidgetConfiguration } from '../../entities';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { WorkflowVersionType, WidgetBase } from '@tonkean/tonkean-entities';
import { ItemInterfaceWidgetConfigurationUserAccessLevel } from '@tonkean/tonkean-entities';

const useLineItemPermission = (
    widget: WidgetBase<InnerItemWidgetConfiguration>,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    workflowVersionType: WorkflowVersionType | undefined,
) => {
    const groupInfoManager = useAngularService('groupInfoManager');

    return useMemo(() => {
        const group = groupInfoManager.getGroupByIdFromCache(groupId);

        if (workflowVersionType) {
            const environmentIsActive =
                workflowVersionType === 'DRAFT' ? group?.buildEnvironmentEnabled : group?.workerEnabled;

            if (!environmentIsActive) {
                return {
                    userCanAddLineItems: false,
                    userCanDeleteLineItems: false,
                    userCanEditLineItems: false,
                };
            }
        }

        const userCanAddLineItems = Boolean(
            widget.configuration.userAccessLevel === ItemInterfaceWidgetConfigurationUserAccessLevel.EDIT_AND_CREATE,
        );

        const userCanDeleteLineItems = userCanAddLineItems;

        const userCanEditLineItems = Boolean(
            widget.configuration.userAccessLevel === ItemInterfaceWidgetConfigurationUserAccessLevel.EDIT ||
                userCanAddLineItems,
        );

        return {
            userCanAddLineItems,
            userCanDeleteLineItems,
            userCanEditLineItems,
        };
    }, [groupId, groupInfoManager, widget.configuration.userAccessLevel, workflowVersionType]);
};

export default useLineItemPermission;
