import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function ZendeskCustomActionPerformerSelectorCtrl(
    $scope,
    $q,
    projectManager,
    tonkeanService,
    utils,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        definition: ctrl.definition,
        validationObject: ctrl.validationObject,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        configuredLogic: ctrl.configuredLogic,
        hasAdminRights: ctrl.projectIntegration.integration?.additionalData?.isAdmin,
        admins: [],
        actionPerformerSelectionConfiguration: null,
        existingActionPerformerSelectionConfiguration: null,
        tagsObjects: [],
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.definition) {
            $scope.data.definition = changesObj.definition.currentValue;

            // Initialization
            if ($scope.data.configuredLogic && $scope.data.definition) {
                $scope.data.loading = true;

                $scope
                    .refreshAdmins()
                    .then(initializeEditMode)
                    .then((value) => $scope.onPerformingAdminSelected(value, false))
                    .finally(() => {
                        $scope.data.loading = false;
                    });
            }
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.refreshAdmins = function (q) {
        const fieldFilters = [
            {
                fieldName: 'role',
                values: ['agent', 'admin'],
            },
        ];

        const queriesToSearch = [q && q.length ? q : null];

        if (
            $scope.data.configuredLogic.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.performedActionDefinition?.actionPerformer?.adminIdToNameMap
        ) {
            queriesToSearch.push(
                ...Object.values(
                    $scope.data.configuredLogic.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                        ?.performedActionDefinition?.actionPerformer?.adminIdToNameMap,
                ),
            );
        }

        const exampleEntitiesRequests = $scope.data.projectIntegration.id
            ? queriesToSearch.map((singleQuery) => {
                  return tonkeanService.getIntegrationExampleEntities(
                      $scope.data.projectIntegration.id,
                      'User',
                      singleQuery,
                      null,
                      null,
                      fieldFilters,
                  );
              })
            : [];

        return Promise.all(exampleEntitiesRequests).then((data) => {
            const exampleEntities = data.flatMap((value) => value.exampleEntities);
            if (exampleEntities && exampleEntities.length) {
                const admins = exampleEntities.map((entity) => {
                    entity.id = entity.originalEntity.email;
                    return entity;
                });

                // remove duplicates by id
                $scope.data.admins = [...new Set(admins.map((admin) => admin.id))].map((adminId) =>
                    admins.find((adminObj) => adminObj.id === adminId),
                );
            }
        });
    };

    $scope.onPerformingAdminSelected = function (actionPerformerSelectionConfiguration, shouldSaveLogic) {
        $scope.data.actionPerformerSelectionConfiguration = actionPerformerSelectionConfiguration;

        let actionPerformer = null;

        if (actionPerformerSelectionConfiguration) {
            actionPerformer = {
                personEmailExpressionDefinition: null,
                personIds: null,
                initiativeOwner: null,
                previousActor: null,
                useNone: null,
                failIfNoValue: null,
                adminIdToNameMap: actionPerformerSelectionConfiguration.selectedPersonIdToNameMap,
            };

            if (actionPerformerSelectionConfiguration.personSelectionType === 'fromField') {
                actionPerformer.personEmailExpressionDefinition =
                    actionPerformerSelectionConfiguration.fromFieldExpressionDefinition;
            }

            actionPerformer.personIds = [];
            if (
                actionPerformerSelectionConfiguration.personSelectionType === 'specificPeople' &&
                actionPerformerSelectionConfiguration.selectedPeopleIds
            ) {
                for (let i = 0; i < actionPerformerSelectionConfiguration.selectedPeopleIds.length; i++) {
                    actionPerformer.personIds.push(actionPerformerSelectionConfiguration.selectedPeopleIds[i]);
                }
            }

            actionPerformer.initiativeOwner =
                actionPerformerSelectionConfiguration.personSelectionType === 'initiativeOwner';
            actionPerformer.previousActor =
                actionPerformerSelectionConfiguration.personSelectionType === 'previousActor';
            actionPerformer.useNone = actionPerformerSelectionConfiguration.personSelectionType === 'none';
            actionPerformer.failIfNoValue = actionPerformerSelectionConfiguration.failIfNoValue;
        }

        if (ctrl.onActionPerformerChanged && actionPerformer) {
            ctrl.onActionPerformerChanged({
                actionPerformer,
                shouldSaveLogic,
            });
        }
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (
            customTriggerAction &&
            customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
        ) {
            $scope.data.existingActionPerformerSelectionConfiguration = {
                personEmailFieldDefinitionId:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
                        .personEmailFieldDefinitionId,
                personEmailExpressionDefinition:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
                        .personEmailExpressionDefinition,
                specificPeopleIds:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
                        .personIds,
                initiativeOwner:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
                        .initiativeOwner,
                previousActor:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
                        .previousActor,
                useNone: false,
                failIfNoValue:
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
                        .failIfNoValue,
            };

            if (
                customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer.personIds &&
                customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer.personIds
                    .length > 0
            ) {
                const itemId =
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.actionPerformer
                        .personIds[0];
                $scope.data.selectedActionPerfomer = utils.findFirstById($scope.data.admins, itemId);
                if (!$scope.data.selectedActionPerfomer) {
                    return tonkeanService
                        .getExternalActivityById(projectManager.project.id, $scope.data.projectIntegration.id, itemId)
                        .then((data) => {
                            $scope.data.admins.push(data);
                            $scope.data.selectedActionPerfomer = utils.findFirstById($scope.data.admins, itemId);
                        });
                }
            }
        }
        return $q.resolve();
    }
}

export default angular
    .module('tonkean.app')
    .controller(
        'ZendeskCustomActionPerformerSelectorCtrl',
        lateConstructController(ZendeskCustomActionPerformerSelectorCtrl),
    );
