import { useAngularService } from 'angulareact';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import OktaLoginFetcher from './OktaLoginFetcher';
import { ReactComponent as OktaLogo } from '../assets/okta-logo.svg';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { POPULATED_FAILED } from '@tonkean/constants';
import { Breakpoint, Field, H1, H3, Input, LoadingCircle, SimpleSelect } from '@tonkean/infrastructure';
import { TnkLogoFull as TnkLogo } from '@tonkean/svg';
import type { IDPLoginData } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const LoginTitle = styled(H1)`
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 64px;
    text-align: center;
`;

const TnkLogoStyled = styled(TnkLogo)`
    margin-bottom: 64px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        margin: 32px;
    }
`;

const SubmitButton = styled(Button)`
    height: 100%;
    margin-top: 25px;
    margin-bottom: 20px;
    padding-block: 13px;
    background: ${({ disabled }) => (disabled ? Theme.colors.gray_300 : Theme.colors.promotion)} !important;
    font-size: 18px;
    color: ${({ disabled }) => (disabled ? Theme.colors.gray_600 : Theme.colors.white)} !important;
    border-radius: 6px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
    width: 100%;
    text-align: center;
    border-color: transparent;
    &:hover {
        color: ${Theme.colors.white};
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        background: ${Theme.colors.gray_blue};
    }
`;

const EmailInput = styled(Input)<{ error?: boolean }>`
    background: ${Theme.colors.gray_200} !important;
    height: 50px !important;
    border: ${({ error }) => (error ? 'solid 1px red' : 'none')} !important;
    padding: 12px !important;
    width: 100%;

    + div {
        position: relative !important;
    }
`;

const DomainEmailInput = styled(Input)<{ error?: boolean }>`
    height: 50px !important;
    padding: 12px !important;
    width: 100%;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
`;

const DomainSelect = styled(SimpleSelect)<{ error?: boolean }>`
    width: 100%;
    background: ${Theme.colors.gray_200} !important;
    height: 50px !important;
    padding: 0 !important;
    border-radius: 4px;

    div:nth-child(3) {
        height: 100% !important;
        border: 1px solid ${Theme.colors.gray_300} !important;
        border-radius: 4px;
    }
`;

const InputLabel = styled(H3)`
    font-size: 16px;
    width: 100%;
    margin-bottom: 14px;
    font-weight: 400;
    color: ${Theme.colors.gray_700};
`;

const ChangeFormState = styled(H3)`
    font-size: 16px;
    line-height: 29px;
    color: ${Theme.colors.gray_700};
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const StyledLink = styled(ClickableLink)`
    cursor: pointer;
    color: ${Theme.colors.focus};
`;

const LoginPrompt = styled.div`
    width: 100%;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledForm = styled(Form)`
    width: inherit;
`;

const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Required'),
});

const subdomainValidationSchema = yup.object().shape({
    subDomain: yup.string().required('Required'),
    selectedOktaUrlSuffix: yup.string().required('Required'),
});

const HttpsPrefix = styled(H3)`
    font-size: 16px;
    color: #9ca6b2;
    font-weight: 400;
`;

const SubdomainFieldsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const StyledField = styled(Field)`
    width: 42%;
`;

const ErrorMessage = styled.p`
    color: ${Theme.colors.error};
    font-size: 12px;
    line-height: 14px;
    margin-top: 20px;
`;

interface Props {
    backToLogin: () => void;
    oktaAuth: (oktaClientId: string, oktaUri: string) => Promise<void>;
    environmentOktaDomain?: string;
}

const OktaLoginPrompt: React.FC<Props> = ({ backToLogin, oktaAuth, environmentOktaDomain }) => {
    const [isUseSubdomain, setIsUseSubdomain] = useState(false);
    const [error, setError] = useState('');
    const environment = useAngularService('environment');

    /**
     * This variable holds the okta login result fetched in OktaLoginFetcher. The value is a promise so we must await it.
     */
    const [oktaResult, setOktaResult] = useState<Promise<IDPLoginData>>();
    const [isFetchingCredentials, setIsFetchingCredentials] = useState<boolean>(true);
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [, getIDPLoginInfo] = useLazyTonkeanService('getIDPLoginInfo');

    useEffect(() => {
        if (environmentOktaDomain) {
            const getLoginIDPLoginInfo = async () => {
                const loginIDPInfo = await getIDPLoginInfo('okta', '', environmentOktaDomain);
                const clientId = loginIDPInfo.additionalData.clientId;
                const loginUrl = loginIDPInfo.additionalData.loginUrl;
                oktaAuth(clientId, loginUrl);
            };
            getLoginIDPLoginInfo();
        }
    }, [environmentOktaDomain, getIDPLoginInfo, oktaAuth]);

    const onSubmit = useCallback<React.ComponentProps<typeof Formik>['onSubmit']>(
        async (_, { setErrors }) => {
            try {
                setIsLoading(true);
                setError('');
                const result = await oktaResult;
                const clientId = result?.additionalData.clientId;
                const loginUrl = result?.additionalData.loginUrl;
                if (clientId && loginUrl) {
                    setIsFetchingCredentials(false);
                    await oktaAuth(clientId, loginUrl);
                } else {
                    setError(`An error occurred please try again later.`);
                }
            } catch (submitError: any) {
                setIsLoading(false);
                const errorMessage = submitError?.data?.data?.error?.message;
                if (errorMessage === 'access_denied' || submitError === 'access_denied') {
                    setError(
                        `Your organization's authentication service failed to sign in. The following error was generated: ${submitError}`,
                    );
                } else if (submitError === POPULATED_FAILED) {
                    setError(
                        `Your organization's authentication service failed to sign in. The following error was generated: To prevent unexpected behavior, please make sure the browser popup blocker is disabled.`,
                    );
                } else if (
                    (errorMessage && errorMessage !== 'Authentication window closed') ||
                    (submitError && submitError !== 'Authentication window closed')
                ) {
                    if (isUseSubdomain) {
                        setError(errorMessage);
                    } else {
                        setErrors({
                            email: errorMessage,
                        });
                    }
                }
            }
        },
        [isUseSubdomain, oktaAuth, oktaResult],
    );

    const oktaDomainSelectOptions: { value: string; label: string }[] = [
        {
            label: '.okta.com',
            value: '.okta.com',
        },
        {
            label: '.oktapreview.com',
            value: '.oktapreview.com',
        },
        ...(environment.oktaDomains || []),
    ];

    const setOkta = useCallback(async (values: Promise<IDPLoginData>) => {
        try {
            setOktaResult(values);

            const result = await values;
            const clientId = result.additionalData.clientId;
            const loginUrl = result.additionalData.loginUrl;
            if (clientId && loginUrl) {
                setIsFetchingCredentials(false);
                setError('');
            }
        } catch (error_: any) {
            const errorMessage = error_?.data?.data?.error?.message;
            setError(errorMessage);
            setEmailValid(false);
        }
    }, []);

    if (environmentOktaDomain) {
        return <LoadingCircle large />;
    }

    return (
        <LoginPrompt>
            <TnkLogoStyled />
            <LoginTitle>
                Log in with <OktaLogo />
            </LoginTitle>

            {isUseSubdomain ? (
                <>
                    <Formik validationSchema={subdomainValidationSchema} onSubmit={onSubmit} initialValues={{}}>
                        {({ errors, values, isSubmitting }) => (
                            <StyledForm>
                                <OktaLoginFetcher
                                    values={values}
                                    useSubdomain={isUseSubdomain}
                                    setOktaResult={setOkta}
                                    setEmailValid={setEmailValid}
                                    isEmailValid={emailValid}
                                />
                                <InputLabel>Enter your okta domain</InputLabel>
                                <SubdomainFieldsWrapper>
                                    <HttpsPrefix>https://</HttpsPrefix>
                                    <StyledField touchedOnlyErrors={false}>
                                        <DomainEmailInput
                                            data-automation="okta-login-prompt-input-subdomain"
                                            error={!!errors['subDomain']}
                                            name="subDomain"
                                            placeholder="Your subdomain"
                                        />
                                    </StyledField>
                                    <StyledField touchedOnlyErrors={false}>
                                        <DomainSelect
                                            defaultValue={oktaDomainSelectOptions[0]}
                                            options={oktaDomainSelectOptions}
                                            name="selectedOktaUrlSuffix"
                                        />
                                    </StyledField>
                                </SubdomainFieldsWrapper>
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                                <SubmitButton
                                    data-automation="okta-login-prompt-submit-button"
                                    type="submit"
                                    disabled={
                                        !!errors['subDomain'] || isSubmitting || isFetchingCredentials || isLoading
                                    }
                                >
                                    {isSubmitting || isLoading ? <LoadingCircle white /> : 'Login'}
                                </SubmitButton>
                            </StyledForm>
                        )}
                    </Formik>
                    <ChangeFormState>
                        <StyledLink onClick={() => setIsUseSubdomain(false)}>Back</StyledLink>
                    </ChangeFormState>
                </>
            ) : (
                <>
                    <Formik validationSchema={validationSchema} onSubmit={onSubmit} initialValues={{}}>
                        {({ errors, values, isSubmitting }) => (
                            <StyledForm>
                                <OktaLoginFetcher
                                    values={values}
                                    useSubdomain={isUseSubdomain}
                                    setEmailValid={setEmailValid}
                                    setOktaResult={setOkta}
                                    isEmailValid={emailValid}
                                />
                                <InputLabel>Enter your email</InputLabel>
                                <Field touchedOnlyErrors={false}>
                                    <EmailInput
                                        data-automation="okta-login-prompt-email-input"
                                        error={!!errors['email']}
                                        name="email"
                                        placeholder="Enter your email"
                                    />
                                </Field>
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                                <SubmitButton
                                    data-automation="okta-login-prompt-submit-button"
                                    type="submit"
                                    disabled={!!errors['email'] || isSubmitting || isFetchingCredentials || isLoading}
                                >
                                    {isSubmitting || (isFetchingCredentials && emailValid) || isLoading ? (
                                        <LoadingCircle white />
                                    ) : (
                                        'Log in'
                                    )}
                                </SubmitButton>
                            </StyledForm>
                        )}
                    </Formik>
                    <ChangeFormState>
                        <StyledLink onClick={backToLogin}> Back </StyledLink>
                        <StyledLink
                            data-automation="okta-login-prompt-manually-authentication-domain-button"
                            onClick={() => setIsUseSubdomain(true)}
                        >
                            Manually enter the authentication domain
                        </StyledLink>
                    </ChangeFormState>
                </>
            )}
        </LoginPrompt>
    );
};

export default OktaLoginPrompt;
