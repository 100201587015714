import type { Features } from 'angular';
import type { AngularServices } from 'angulareact';

import type CreateAfterOption from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import type LogicBlockConfigsFormInfoRetriever from '../../LogicBlockConfigsFormInfoRetriever';
import formAnsweredCanCreateInSameModule from '../canCreate/formAnsweredCanCreateInSameModule';
import formAnsweredCanCreateIsSequenceEnd from '../canCreate/formAnsweredCanCreateIsSequenceEnd';
import formAnsweredEndSequenceTriggerParamsEnricher from '../enrichers/formAnsweredEndSequenceTriggerParamsEnricher';

import { ItemDetailsIconType } from '@tonkean/tonkean-entities';
import type { LogicImpact } from '@tonkean/tonkean-entities';

const endSequenceOptionCanCreate: CreateAfterOptionCanCreate = (
    childImpacts: LogicImpact[],
    configuredLogic: LogicImpact,
    logicBlockConfigsFormInfoRetriever: LogicBlockConfigsFormInfoRetriever,
    customTriggerManager: AngularServices['customTriggerManager'],
    features: Features,
) => {
    return (
        !configuredLogic.node.disabled &&
        formAnsweredCanCreateInSameModule(configuredLogic, logicBlockConfigsFormInfoRetriever) &&
        formAnsweredCanCreateIsSequenceEnd(configuredLogic)
    );
};

const addEndSequenceBlockCreateAfterOption: CreateAfterOption = {
    label: 'End sequence',
    blockDisplayName: 'End sequence',
    triggerParamsEnricher: formAnsweredEndSequenceTriggerParamsEnricher,
    canCreate: endSequenceOptionCanCreate,
    iconType: ItemDetailsIconType.END_SEQUENCE,
};
export default addEndSequenceBlockCreateAfterOption;
