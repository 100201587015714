import React, { useState } from 'react';
import styled from 'styled-components';

import AuditLogPageConfig from '../../../config/AuditLogPageConfig';
import type AuditLogFilterSearchQuery from '../../../entities/AuditLogFilterSearchQuery';
import AuditLogType from '../../../entities/AuditLogType';
import EnterpriseComponentAuditLogFlatChangesList from '../../AuditLogChangesList/components/flat/EnterpriseComponentAuditLogFlatChangesList';
import PaginatedAuditLogAccordion from '../../AuditLogChangesList/components/grouped/PaginatedAuditLogAccordion';

import type { TonkeanType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ChangesCountLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
    padding-left: 8px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

const ChangesCountLabelNumber = styled.span`
    margin-left: 2px;
    line-height: 12px;
`;

interface Props {
    enterpriseComponentId: string;
    isGrouped?: boolean;
    filterSearch: AuditLogFilterSearchQuery;
    logsCount: number;
    loadingLogsCount: boolean;
    enterpriseComponentType: TonkeanType;
    logType: AuditLogType;
}

const EnterpriseComponentAuditLogChangesList: React.FC<Props> = ({
    enterpriseComponentId,
    isGrouped = false,
    filterSearch,
    logsCount,
    loadingLogsCount,
    enterpriseComponentType,
    logType,
}) => {
    const [isCollapseAllActive, setIsCollapseAllActive] = useState<boolean>(false);

    return (
        <>
            <ChangesCountLabel>
                <div>
                    {logType === AuditLogType.USER_ACTION ? 'Changes' : 'Collects'}

                    <ChangesCountLabelNumber>
                        {loadingLogsCount ? <span className="loading-xxsmall" /> : <span>({logsCount || 0})</span>}
                    </ChangesCountLabelNumber>
                </div>

                {logType === AuditLogType.SYSTEM && (
                    <ClickableLink
                        onClick={() => {
                            setIsCollapseAllActive(true);
                        }}
                        disabled={isCollapseAllActive}
                    >
                        Collapse All
                    </ClickableLink>
                )}
            </ChangesCountLabel>

            {isGrouped ? (
                <PaginatedAuditLogAccordion
                    enterpriseComponentId={enterpriseComponentId}
                    enterpriseComponentType={enterpriseComponentType}
                    headersPageSize={AuditLogPageConfig.GROUP_PAGE_SIZE}
                    itemsPageSize={AuditLogPageConfig.GROUP_ITEMS_PAGE_SIZE}
                    filterSearch={filterSearch}
                    logType={AuditLogType.USER_ACTION}
                />
            ) : (
                <EnterpriseComponentAuditLogFlatChangesList
                    enterpriseComponentId={enterpriseComponentId}
                    enterpriseComponentType={enterpriseComponentType}
                    total={logsCount || AuditLogPageConfig.ITEMS_PAGE_SIZE}
                    filterSearch={filterSearch}
                    pageSize={AuditLogPageConfig.ITEMS_PAGE_SIZE}
                    logType={logType}
                    isCollapseAllActive={isCollapseAllActive}
                    setIsCollapseAllActive={setIsCollapseAllActive}
                />
            )}
        </>
    );
};

export default EnterpriseComponentAuditLogChangesList;
