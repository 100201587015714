<div class="margin-top">
    <div class="margin-left margin-top">
        <div class="margin-bottom-xs">
            <div class="common-title-xxs">Comment</div>
            <span class="common-size-xxs common-color-grey3">Add comment to the item</span>
        </div>

        <!-- Tonkean custom expression input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            placeholder="Enter comment..."
            saved-original-expression="data.definition.comment"
            saved-evaluated-expression="data.definition.evaluatedCommentExpression"
            preview-evaluation-source="data.previewEvaluationSource"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <div ng-if="data.validationObject.noComment" class="common-color-danger margin-top-6">
            {{ data.validationObject.noComment }}
        </div>

        <!-- Separator -->
        <hr />

        <!-- From (sender) configuration -->
        <div>
            <div class="common-title-xxs">Send comment by:</div>

            <div class="margin-top-xs">
                <!-- From person selection options -->
                <tnk-person-selection-configuration
                    class="block margin-top-xs"
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    support-quick-create="true"
                    saved-person-selection-configuration="data.fromExistingPersonSelectionConfiguration"
                    on-person-selection-configuration-changed="
                        onFromPersonSelectionChanged(personSelectionConfiguration, shouldSaveLogic)
                    "
                    configured-logic="data.configuredLogic"
                    max-people="1"
                    radio-id-prefix="'from-' + data.workflowVersionId"
                    unique-identifier="messageBy"
                    preview-evaluation-source="data.previewEvaluationSource"
                    on-behalf-of-custom-person="true"
                ></tnk-person-selection-configuration>
            </div>
        </div>
    </div>
</div>
