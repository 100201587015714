import dayjs from 'dayjs';
import { useCallback } from 'react';

import type { FieldValidationParams } from '../entities';
import type FieldValidation from '../entities/FieldValidation';

const formatDateToAgGridDatePicker = (date: Date): string | undefined => {
    return date ? dayjs(date).format('YYYY-MM-DD') : undefined;
};

const useMinMaxDateRangeValidation = () => {
    return useCallback((fieldDefinitionValidation: FieldValidation<FieldValidationParams> | undefined) => {
        // If we have no validation configured at all (no min, no max), the defaults are 01.01.1000 - 31.12.9999
        const DEFAULT_MIN_DATE_WITHOUT_VALIDATION = '1000-01-01';
        const DEFAULT_MAX_DATE_WITHOUT_VALIDATION = '9999-12-31';

        if (!fieldDefinitionValidation) {
            return {
                min: DEFAULT_MIN_DATE_WITHOUT_VALIDATION,
                max: DEFAULT_MAX_DATE_WITHOUT_VALIDATION,
            };
        }

        return {
            min: formatDateToAgGridDatePicker(fieldDefinitionValidation?.params.min),
            max: formatDateToAgGridDatePicker(fieldDefinitionValidation?.params.max),
        };
    }, []);
};

export default useMinMaxDateRangeValidation;
