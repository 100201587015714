import SQLDatabaseEntityIdType from '../entities/SQLDatabaseEntityIdType';

function getEmptySQLDatabaseQueryConfiguration(queryId: number) {
    return {
        queryId,
        existingQueryConfiguration: false,

        queryName: 'Query Entity',
        query: '',
        queryNameInEditMode: false,

        entityIdType: SQLDatabaseEntityIdType.CUSTOM,
        identifierColumn: '',

        paginationEnabled: true,
        pagesAmount: 250,
        pageSize: 1000,

        customTimestampsEnabled: false,
        createdColumn: '',
        updatedColumn: '',

        expanded: false,
        queryTested: false,
    };
}

export default getEmptySQLDatabaseQueryConfiguration;
