import React from 'react';

import TonkeanExpressionBlock from './TonkeanExpressionBlock';

import { ExpressionBlock } from '@tonkean/editor';
import { operatorKeyToOperatorMap } from '@tonkean/forumla-operators';
import { OperatorFamily } from '@tonkean/tonkean-entities';
import type { ServerFormulaExpressionTreeNode } from '@tonkean/tonkean-entities';

interface Props {
    expression: ServerFormulaExpressionTreeNode;
    children: React.ReactNode;
}

const TreeBlock: React.FC<Props> = ({ expression, children }) => {
    const operator = operatorKeyToOperatorMap[expression.operator];
    let inners: JSX.Element;
    switch (operator.family) {
        case OperatorFamily.LOGICAL:
        case OperatorFamily.ARITHMETIC: {
            const [first, second] = expression.operands;
            inners = (
                <>
                    (<TonkeanExpressionBlock expression={first!} /> {operator.sign}{' '}
                    <TonkeanExpressionBlock expression={second!} />)
                </>
            );
            break;
        }

        case OperatorFamily.FUNCTION: {
            inners = (
                <>
                    {operator.sign}(
                    {expression.operands.map((operand, index) => (
                        <span key={index}>
                            <TonkeanExpressionBlock expression={operand} />
                            {index !== expression.operands.length - 1 ? ', ' : undefined}
                        </span>
                    ))}
                    )
                </>
            );
            break;
        }
    }
    return (
        <ExpressionBlock type="tree">
            {inners}
            {children}
        </ExpressionBlock>
    );
};

export default TreeBlock;
