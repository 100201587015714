import template from './tnkProjectStats.template.html?angularjs';

function tnkProjectStats() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            filterVisiblity: '=',
            refresh: '&',
        },
        template,
        controller: [
            '$scope',
            '$stateParams',
            '$state',
            'tonkeanService',
            'projectManager',
            'utils',
            'authenticationService',
            '$q',
            function ($scope, $stateParams, $state, tonkeanService, projectManager, utils, authenticationService, $q) {
                $scope.data = {
                    stateFilter: null,
                    selectedOwner: $stateParams.o,
                    loadingFilters: true,
                };

                $scope.utils = utils;
                $scope.as = authenticationService;
                $scope.pm = projectManager;
                $scope.pm.getProjectData().then(function () {
                    $scope.filterTextToFilter = utils.createMapFromArray($scope.pm.projectData.stats, 'stateText');
                });

                $q.all([$scope.pm.getFunctions(), $scope.pm.getTags()]).then(() => {
                    $scope.data.loadingFilters = false;
                });

                // init value
                setStateFilterToStateParam();

                $scope.addNewFunc = function () {
                    if ($scope.data.newFuncName && $scope.data.newFuncName.length && $scope.pm.project) {
                        const funcName = $scope.data.newFuncName.replace(new RegExp(' ', 'g'), '-');

                        tonkeanService.getOrCreateFunctionByName($scope.pm.project.id, funcName).then(function () {
                            $scope.pm.getFunctions(true).finally(function () {
                                $scope.data.showAddFunc = false;
                                // $scope.data.showFilter = false;
                                $scope.data.newFuncName = '';
                                // $state.go('product.board', {filter: data.name}, {location: 'replace'});
                            });
                        });
                    }
                };

                $scope.isFilterTextStateInMore = function (filterText) {
                    if (!$scope.filterTextToFilter) {
                        return false;
                    }

                    const filter = $scope.filterTextToFilter[filterText];
                    if (!filter) {
                        return false;
                    }

                    const filterType = filter.status;
                    return filterType !== 'WARN' && (filterType !== 'ON_HOLD' || filterText === 'Not started');
                };

                $scope.onFilterByOwner = function (personId) {
                    if (personId) {
                        $scope.data.selectedOwner = personId;
                        $state.go('product.board', {
                            o: personId,
                            filter: 'owner',
                            g: null,
                            st: null,
                            listFilter: null,
                            isGroupListFilter: null,
                        });
                    } else {
                        $scope.clearFilter();
                    }
                };

                $scope.onOwnerPopoverClickOutside = function (personId) {
                    $scope.onFilterByOwner(personId);
                    $scope.data.showOwnerMenu = false;
                };

                $scope.clearFilter = function () {
                    $scope.data.selectedOwner = null;

                    // Cancelling the collapse if there might be one.
                    if (projectManager.groups && projectManager.groups.length) {
                        for (let i = 0; i < projectManager.groups.length; i++) {
                            const group = projectManager.groups[i];

                            if (group) {
                                group.collapse = false;
                            }
                        }
                    }

                    // Navigating to view without filters.
                    $state.go('product.board', { filter: 'all', st: null, g: null, o: null });
                };

                // on location change update our variable for the st param
                $scope.$on('$locationChangeSuccess', function () {
                    setStateFilterToStateParam();
                });

                function setStateFilterToStateParam() {
                    $scope.data.stateFilter = $stateParams.st;
                }
            },
        ],
    };
}
export default angular.module('tonkean.app').directive('tnkProjectStats', tnkProjectStats);
