import React from 'react';
import styled from 'styled-components';

import SuggestedModuleConfigEditor from './SuggestedModuleConfigEditor';
import type { SuggestedModuleConfig, SuggestedSectionConfig } from './SuggestedSectionConfig';
import MarketPlaceItemCreateModalUploadImagesSection from '../MarketPlaceItemCreateModalUploadImagesSection';

import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { HTMLEditor } from '@tonkean/editor';
import {
    LinkLikeButton,
    RemoveItemButton,
    Spacer,
    TextEllipsis,
    useFormikArrayField,
    useFormikField,
} from '@tonkean/infrastructure';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Container = styled.div`
    position: relative;
    border: 1px solid ${Theme.colors.gray_300};
    padding: 12px 12px 12px 19px;
    margin-bottom: 16px;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const TitleRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const SectionLabel = styled.div`
    font-size: 10px;
    font-weight: 600;
    color: ${Theme.colors.gray_500};

    margin-left: 10px;
`;

const SectionDescription = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.XSMALL_10};
    margin-bottom: 18px;
`;

const BlueRibbon = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background: ${Theme.colors.primaryHighlight};
`;

interface Props {
    section: SuggestedSectionConfig;
    sectionIndex: number;
    onRemove: () => void;
    isLast: boolean;
}

const SuggestedSectionConfigEditor: React.FC<Props> = ({ section, sectionIndex, onRemove, isLast }) => {
    const { push: pushModuleToSections } = useFormikArrayField<SuggestedModuleConfig[]>(
        `configuration.solutionOutline.sections[${sectionIndex}].suggestedModules`,
    );

    const { setValue: setOutcomeDefinition } = useFormikField(
        `configuration.solutionOutline.sections[${sectionIndex}].outcomeDefinition`,
    );

    const addModule = () => {
        pushModuleToSections({ label: '', templateName: '', isEmbedded: false });
    };

    return (
        <Container>
            <BlueRibbon />
            <TitleContainer>
                <TextEllipsis dataAutomation="suggested-section-config-editor-section-name" numberOfLines={1} tooltip>
                    {section.title}
                </TextEllipsis>
                <TitleRightContainer>
                    <SectionLabel>SECTION</SectionLabel>

                    <RemoveItemButton
                        onClick={() => onRemove()}
                        noItemsMessage="Must have at least one section"
                        disabled={isLast}
                    />
                </TitleRightContainer>
            </TitleContainer>
            <SectionDescription data-automation="suggested-section-config-editor-section-description">
                {section.description}
            </SectionDescription>

            {section.suggestedModules.map((suggestedModule, index) => {
                return <SuggestedModuleConfigEditor key={index} sectionIndex={sectionIndex} moduleIndex={index} />;
            })}

            <LinkLikeButton data-automation="suggested-section-config-editor-add-suggested-module" onClick={addModule}>
                + Add suggested module
            </LinkLikeButton>

            <Spacer height={16} />

            <TextEllipsis>What does "Good" look like?</TextEllipsis>

            <Spacer height={8} />

            <HTMLEditor
                placeholder="Define what good looks like"
                initialValue={section.outcomeDefinition || EMPTY_ARRAY}
                onChange={setOutcomeDefinition}
            />

            <Spacer height={16} />

            <TextEllipsis>Upload Section Image</TextEllipsis>
            <Spacer height={8} />

            <MarketPlaceItemCreateModalUploadImagesSection
                formikFieldName={`configuration.solutionOutline.sections[${sectionIndex}].image`}
                maximumFiles={1}
                dataAutomation="upload-section-image"
                touchedErrorWorkAround
            />
        </Container>
    );
};

export default React.memo(SuggestedSectionConfigEditor);
