import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const NlpBranchLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.NLP_BRANCH,
    title: 'Branch (NLP)',
    iconClass: 'mod-nlp-branch',
    description:
        'Will activate the following flow when the input text of the Text Analysis block have found the defined keywords.',
    cantChangeType: () => true,
    doNotDisplaySettingsEdit: true,
    cantMove: true,
    cantDrag: false,
    allowedParentTypes: [LogicBlockConfigType.NLP_PROCESSOR],
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        // Can have no definition
        if (definition.textAnalysisType === 'MANUAL' && !definition.keywords?.length) {
            validationObject.noKeywords = 'Must have at least 1 keyword';
        }

        if (definition.textAnalysisType === 'TRAINING_SET' && !definition.trainingSetModelId) {
            validationObject.noModel = 'Must select a model';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default NlpBranchLogicBlockConfig;
