import { angularToReact } from '@tonkean/angular-components';
import type { Person, UserGroup } from '@tonkean/tonkean-entities';

const DeprecatedPeopleSelector = angularToReact<{
    selectedPeople: Person[] | UserGroup[] | undefined;
    disabled?: boolean;
    noCustom?: boolean;
    onTagAdded?: ($tag: any) => void;
    onTagRemoved?: ($tag: any) => void;
    doNotSearchOnlyOwnersInGroup?: boolean;
    noValidation?: boolean;
    includeGroups?: boolean;
    onlyGroups?: boolean;
    onlyProcessContributors?: boolean;
    onlyOwnersInGroup?: unknown;
    onlyCreatorsInGroup?: unknown;
    placeholder?: string;
    hidePlaceholderWhenDisabled?: boolean;
    includeExternal?: boolean;
}>('tnk-deprecated-people-selector');

export default DeprecatedPeopleSelector;
