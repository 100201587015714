import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import TriggeredModulesModal from './TriggeredModulesModal';
import SingleExternalActivityInspect from '../../../../../../ProjectIntegrationPageModule/components/ProjectIntegrationViewData/ExternalActivitiesTable/ExternalActivityInspect';

import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { HighlightableText, TextEllipsis } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const ExternalActivitiesTableHeaders = styled.div`
    display: grid;
    grid-template-columns: 460px 222px auto;
    column-gap: 8px;
    padding: 12px 0 12px 32px;
    font-weight: bold;
    border-bottom: 1px solid ${Theme.colors.gray_400};
    position: sticky;
    top: 0;
    color: ${Theme.colors.gray_500};
`;

const TriggeredModulesLink = styled(ClickableLink)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 460px 222px auto;
    column-gap: 8px;
    width: 100%;
    padding: 14px 0 14px 32px;
    background-color: ${Theme.colors.gray_100};

    &:hover {
        background-color: ${Theme.colors.gray_200};
    }
`;

const IdCell = styled.span`
    color: ${Theme.colors.gray_700};
`;

interface Props {
    externalActivities?: { id: string; title: string }[];
    entityName?: string;
    projectIntegration: ProjectIntegration;
    searchTerm?: string;
    collectLogTime: number;
}

const CollectedExternalActivitiesTable: React.FC<Props> = ({
    externalActivities = EMPTY_ARRAY,
    projectIntegration,
    entityName = '',
    searchTerm = '',
    collectLogTime,
}) => {
    const [isInspectItemModalShown, setIsInspectModalShown] = useState(false);
    const [currentTriggeredModalExternalActivity, setCurrentTriggeredModalExternalActivity] = useState<{
        id: string;
        title: string;
    } | null>();
    const [externalActivityId, setExternalActivityId] = useState<string>();

    // Filter external activities which do not correspond to the search term
    const filteredExternalActivities = useMemo(() => {
        const filteredExternalActivitiesByFilter = externalActivities?.filter(
            (externalActivity) =>
                externalActivity.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                externalActivity.title.toLowerCase().includes(searchTerm.toLowerCase()),
        );

        // If there are no matching external activities, show them all because that means the group is matching the search result
        // If the group won't match the search we won't show the group row anyway and the items won't be visible
        return filteredExternalActivitiesByFilter.length === 0
            ? externalActivities
            : filteredExternalActivitiesByFilter;
    }, [externalActivities, searchTerm]);

    return (
        <>
            <ExternalActivitiesTableHeaders>
                <div>Item Title</div>
                <div>ID</div>
            </ExternalActivitiesTableHeaders>

            {filteredExternalActivities.map((currentRowExternalActivity) => (
                <Row key={currentRowExternalActivity.id}>
                    <TextEllipsis numberOfLines={1} tooltip>
                        <ClickableLink
                            onClick={() => {
                                setExternalActivityId(currentRowExternalActivity.id);
                                setIsInspectModalShown(true);
                            }}
                        >
                            <HighlightableText text={currentRowExternalActivity.title} highlightText={searchTerm} />
                        </ClickableLink>
                    </TextEllipsis>

                    <TextEllipsis numberOfLines={1} tooltip>
                        <IdCell>
                            <HighlightableText text={currentRowExternalActivity.id} highlightText={searchTerm} />
                        </IdCell>
                    </TextEllipsis>

                    <TriggeredModulesLink
                        onClick={() => {
                            setCurrentTriggeredModalExternalActivity(currentRowExternalActivity);
                        }}
                    >
                        Inspect in modules
                    </TriggeredModulesLink>
                </Row>
            ))}

            {currentTriggeredModalExternalActivity && (
                <TriggeredModulesModal
                    onClose={() => {
                        setCurrentTriggeredModalExternalActivity(null);
                    }}
                    externalActivity={currentTriggeredModalExternalActivity}
                    projectIntegration={projectIntegration}
                    activityLogType="Continuous Data Collection"
                    externalType={entityName}
                    collectLogTime={collectLogTime}
                    open
                />
            )}

            {externalActivityId && entityName && (
                <SingleExternalActivityInspect
                    onClose={() => setIsInspectModalShown(false)}
                    shown={isInspectItemModalShown}
                    externalActivityId={externalActivityId}
                    entityType={entityName}
                    projectIntegration={projectIntegration}
                />
            )}
        </>
    );
};

export default CollectedExternalActivitiesTable;
