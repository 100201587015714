/**
 * Represents the different types of workflow relevant activities.
 */
export enum WorkflowActivityEvents {
    CUSTOM_TRIGGER_DISPLAY_NAME_CHANGED = 'CUSTOM_TRIGGER_DISPLAY_NAME_CHANGED',
    TRIGGER_ENABLED_STATE_CHANGED = 'TRIGGER_ENABLED_STATE_CHANGED',
    CUSTOM_TRIGGER_ACTION_DEFINITION_CHANGED = 'CUSTOM_TRIGGER_ACTION_DEFINITION_CHANGED',
    CUSTOM_TRIGGER_POST_STATE_CHANGED = 'CUSTOM_TRIGGER_POST_STATE_CHANGED',
    CUSTOM_TRIGGER_CREATED = 'CUSTOM_TRIGGER_CREATED',
    CUSTOM_TRIGGER_DELETED = 'CUSTOM_TRIGGER_DELETED',
    CUSTOM_TRIGGER_DUPLICATED = 'CUSTOM_TRIGGER_DUPLICATED',
    CREATED_BOT_FROM_TEMPLATE = 'CREATED_BOT_FROM_TEMPLATE',
    GROUP_SHOULD_GATHER_UPDATES = 'GROUP_SHOULD_GATHER_UPDATES',
    GROUP_IS_DASHBOARD_HIDDEN_TOGGLED = 'GROUP_IS_DASHBOARD_HIDDEN_TOGGLED',
    CUSTOM_TRIGGER_HIDDEN_STATE_CHANGE = 'CUSTOM_TRIGGER_HIDDEN_STATE_CHANGE',
    GROUP_DATA_SOURCE_CHANGED_TO_MANUAL = 'GROUP_DATA_SOURCE_CHANGED_TO_MANUAL',
    GROUP_DATA_SOURCE_CHANGED_TO_FORMS = 'GROUP_DATA_SOURCE_CHANGED_TO_FORMS',
    GROUP_DATA_SOURCE_REMOVED = 'GROUP_DATA_SOURCE_REMOVED',
    DATA_SOURCE_CHANGED_TO_SCHEDULED = 'DATA_SOURCE_CHANGED_TO_SCHEDULED',
    DATA_SOURCE_CHANGED_TO_INTEGRATION = 'DATA_SOURCE_CHANGED_TO_INTEGRATION',
    INTEGRATION_DATA_SOURCE_CONFIGURATION_CHANGED = 'INTEGRATION_DATA_SOURCE_CONFIGURATION_CHANGED',
    FIELD_DEFINITION_CREATED = 'FIELD_DEFINITION_CREATED',
    FIELD_DEFINITION_UPDATED = 'FIELD_DEFINITION_UPDATED',
    FIELD_DEFINITION_DELETED = 'FIELD_DEFINITION_DELETED',
    FORM_CREATED = 'FORM_CREATED',
    FORM_UPDATED = 'FORM_UPDATED',
    FORM_DELETED = 'FORM_DELETED',
    STATES_CHANGED = 'STATES_CHANGED',
    WORKER_CUSTOM_TRIGGER_GRAPH_CHANGED = 'WORKER_CUSTOM_TRIGGER_GRAPH_CHANGED',
    TRAINED_KEYWORD_ADDED = 'TRAINED_KEYWORD_ADDED',
    TRAINED_KEYWORD_DELETED = 'TRAINED_KEYWORD_DELETED',
    SCHEDULED_WORKER_CONFIGURATION_CHANGED = 'SCHEDULED_WORKER_CONFIGURATION_CHANGED',
    FIELD_GRAPH_UPDATE = 'FIELD_GRAPH_UPDATE',
    LIVE_REPORT_FIELD_IS_HIDDEN_UPDATED = 'LIVE_REPORT_FIELD_IS_HIDDEN_UPDATED',
    LIVE_REPORT_FIELD_REORDER = 'LIVE_REPORT_FIELD_REORDER',
    MODULE_REVERT = 'MODULE_REVERT',
    ITEM_INTERFACE_CREATED = 'ITEM_INTERFACE_CREATED',
    ITEM_INTERFACE_UPDATED = 'ITEM_INTERFACE_UPDATED',
    ITEM_INTERFACE_DELETED = 'ITEM_INTERFACE_DELETED',
    MODULE_CREATED_FROM_MARKETPLACE = 'MODULE_CREATED_FROM_MARKETPLACE',
    ITEM_INTERFACE_WIDGET_CREATED = 'ITEM_INTERFACE_WIDGET_CREATED',
    ITEM_INTERFACE_WIDGET_UPDATED = 'ITEM_INTERFACE_WIDGET_UPDATED',
    ITEM_INTERFACE_WIDGET_DELETED = 'ITEM_INTERFACE_WIDGET_DELETED',
    ITEM_INTERFACE_SET_AS_DEFAULT = 'ITEM_INTERFACE_SET_AS_DEFAULT',
    ITEM_INTERFACE_REMOVED_AS_DEFAULT = 'ITEM_INTERFACE_REMOVED_AS_DEFAULT',
    ITEM_INTERFACE_LOGO_ADDED = 'ITEM_INTERFACE_LOGO_ADDED',
    ITEM_INTERFACE_LOGO_REMOVED = 'ITEM_INTERFACE_LOGO_REMOVED',
    MODULE_CREATED_FROM_MODULE_DUPLICATED = 'MODULE_CREATED_FROM_MODULE_DUPLICATED',
    ITEM_DETAILS_UPDATED = 'ITEM_DETAILS_UPDATED',
    ITEM_DETAILS_CREATED = 'ITEM_DETAILS_CREATED',
    PROCESS_MAPPER_CREATED = 'PROCESS_MAPPER_CREATED',
    PROCESS_MAPPER_UPDATED = 'PROCESS_MAPPER_UPDATED',
    PROCESS_MAPPER_DELETED = 'PROCESS_MAPPER_DELETED',
    PROCESS_MAPPER_NODE_CREATED = 'PROCESS_MAPPER_NODE_CREATED',
    PROCESS_MAPPER_NODE_UPDATED = 'PROCESS_MAPPER_NODE_UPDATED',
    PROCESS_MAPPER_NODE_DELETED = 'PROCESS_MAPPER_NODE_DELETED',
    PROCESS_MAPPER_EDGE_CREATED = 'PROCESS_MAPPER_EDGE_CREATED',
    PROCESS_MAPPER_EDGE_UPDATED = 'PROCESS_MAPPER_EDGE_UPDATED',
    PROCESS_MAPPER_EDGE_DELETED = 'PROCESS_MAPPER_EDGE_DELETED',
    GROUP_INPUT_SOURCE_FROM_ANOTHER_MODULE_MAPPING_CONFIGURATION_CHANGED = 'GROUP_INPUT_SOURCE_FROM_ANOTHER_MODULE_MAPPING_CONFIGURATION_CHANGED',
    GROUP_INPUT_SOURCE_FROM_ANOTHER_MODULE_CONTRACT_CHANGED = 'GROUP_INPUT_SOURCE_FROM_ANOTHER_MODULE_CONTRACT_CHANGED',
    GROUP_INPUT_SOURCE_CHANGED_TO_FROM_ANOTHER_MODULE = 'GROUP_INPUT_SOURCE_CHANGED_TO_FROM_ANOTHER_MODULE',
    ITEM_INTERFACE_WIDGETS_SWAPPED = 'ITEM_INTERFACE_WIDGETS_SWAPPED',
    SOLUTION_SITE_PAGE_WIDGETS_SWAPPED = 'SOLUTION_SITE_PAGE_WIDGETS_SWAPPED',
}
