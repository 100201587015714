import React, { useContext, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import useItemInterfaceEditorContext from '../../../utils/ItemInterfaceEditorContext';
import type RichTextWidgetConfiguration from '../RichTextWidgetConfiguration';

import type { CoreEditorRef, HTMLEditorPlugin } from '@tonkean/editor';
import { HTMLEditor } from '@tonkean/editor';
import type { WidgetBase } from '@tonkean/tonkean-entities';
import { RouterContext } from '@tonkean/tui-basic/RouterLink';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Buttons = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 4px;
    justify-content: start;

    padding: 12px 15px;
    background: ${Theme.colors.disabled};
    border-top: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
`;

const StyledEditor = styled(HTMLEditor)`
    flex-grow: 1;
    border: none;
    border-radius: 0;
`;

interface Props {
    widget: WidgetBase<RichTextWidgetConfiguration>;
    plugins: HTMLEditorPlugin[];
}

const RichTextWidgetEditor: React.FC<Props> = ({ widget, plugins }) => {
    const routerContextValue = useContext(RouterContext);

    if (!routerContextValue) {
        throw new Error(
            'Attempted to use RouterLink (or Clickable with state/params) without passing TonkeanRouter to ReactRoot',
        );
    }

    const { go } = routerContextValue;
    const editorRef = useRef<CoreEditorRef>(null);

    const { updateItemInterfaceWidget } = useItemInterfaceEditorContext();

    const initialValue = widget.configuration.content;
    const [htmlValue, setHtmlValue] = useState(initialValue);
    const hasChanges = initialValue !== htmlValue;

    const onChange = useMemo(
        () => (newValue) => {
            setHtmlValue(newValue);
        },
        [],
    );

    const setEditorConfiguration = () => {
        const query: Record<string, any> | undefined = {
            widgetId: widget.id,
            currentTab: undefined,
        };

        go('.', query, true, { location: 'replace' });
    };

    return (
        <>
            <StyledEditor
                dataAutomation="rich-text-widget-editor"
                initialValue={initialValue}
                onChange={onChange}
                plugins={plugins}
                ref={editorRef}
                onClicked={setEditorConfiguration}
            />
            <Buttons onClick={setEditorConfiguration}>
                <Button
                    data-automation="rich-text-widget-editor-dismiss-button"
                    size={ButtonSize.SMALL}
                    disabled={!hasChanges}
                    onClick={() => {
                        editorRef.current?.setValue(initialValue);
                    }}
                    outlined
                >
                    Dismiss
                </Button>
                <Button
                    data-automation="rich-text-widget-editor-save-button"
                    size={ButtonSize.SMALL}
                    disabled={!hasChanges}
                    onClick={() => {
                        updateItemInterfaceWidget({
                            ...widget,
                            configuration: {
                                ...widget.configuration,
                                content: htmlValue ?? [],
                            } as RichTextWidgetConfiguration,
                        });
                    }}
                >
                    Save
                </Button>
            </Buttons>
        </>
    );
};

export default RichTextWidgetEditor;
