import React from 'react';

import type QuestionWidgetConfiguration from './QuestionWidgetConfiguration';
import QuestionWidgetWorkflowVersionFieldSelector from './QuestionWidgetWorkflowVersionFieldSelector';
import type { ItemWidgetEditorProps } from '../../WidgetModule';

const QuestionWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<QuestionWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    return (
        <QuestionWidgetWorkflowVersionFieldSelector configuration={configuration} workflowVersion={workflowVersion} />
    );
};

export default QuestionWidgetConfigurationEditor;
