import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CommentableCard, CommentMarkdown } from '@tonkean/collaboration';
import { Breakpoint, TextEllipsis, TonkeanAvatar } from '@tonkean/infrastructure';
import { ActivityType } from '@tonkean/tonkean-entities';
import type { Activity, NewActivityData, FieldInstance, Person } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const UserAvatar = styled.span`
    width: 24px;
    height: 24px;
    position: relative;
`;
const StyledCommentableCard = styled(CommentableCard)`
    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: 8px 0 0;
    }
`;

const Text = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_800};
`;

const Sub = styled.span`
    font-size: ${FontSize.XSMALL_10};
    line-height: 12px;
    color: ${Theme.colors.gray_500};
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    column-gap: 5px;
`;

const allowsComments = new Set([ActivityType.NEW_COMMENT, ActivityType.INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED]);

const eventToContent = (event: Activity, timeUtils: any): string | JSX.Element | null => {
    switch (event.type) {
        case ActivityType.INITIATIVE_DUE_DATE_CHANGED: {
            const newValue = event.metadata?.newDueDate
                ? utils.formatDate(event.metadata?.newDueDate, true, true, true, true)
                : 'None';
            const oldValue = event.metadata?.oldDueDate
                ? utils.formatDate(event.metadata?.oldDueDate, true, true, true, true)
                : 'None';

            return (
                <>
                    {newValue}
                    <p>
                        <Sub>Was: {oldValue}</Sub>
                    </p>
                </>
            );
        }
        case ActivityType.INITIATIVE_LEADER_CHANGED:
            if (!event.reference3) {
                return 'Owner: None';
            }

            return (
                <Row>
                    Owner:{' '}
                    <UserAvatar>
                        <TonkeanAvatar owner={event.reference3} />
                    </UserAvatar>
                    {event.reference3.name}
                </Row>
            );

        case ActivityType.NEW_COMMENT:
            return event.reference1.text;

        case ActivityType.INITIATIVE_CREATED:
        case ActivityType.NEW_INITIATIVE:
        case ActivityType.INITIATIVE_DESCRIPTION_CHANGED:
        case ActivityType.INITIATIVE_TAGS_CHANGED:
        case ActivityType.INITIATIVE_TITLE_CHANGED:
        case ActivityType.INITIATIVE_STATUS_CHANGED:
        case ActivityType.INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED:
            if (event.metadata?.newEta) {
                return <span>ETA: {timeUtils.daysDiffSentenceString(event.metadata.newEta)}</span>;
            }

            return event.metadata?.newUpdateText || null;
        case ActivityType.INITIATIVE_MANUAL_FIELD_VALUE_CHANGED: {
            if (typeof event.metadata?.newValue === 'string') {
                return event.metadata?.newValue;
            }
            return (
                (event.metadata?.newValue as unknown as FieldInstance)?.displayValue ??
                (event.metadata?.newValue as unknown as Person)?.name ??
                null
            );
        }
        case ActivityType.BOT_GATHER:
        case ActivityType.INNER_INITIATIVE_CREATED:
        case ActivityType.ITEM_INTERFACE_BUTTON_CLICKED:
        case ActivityType.FORM_SUBMITTED:
        case ActivityType.ITEM_INTERFACE_SUBMITTED:
            return null;

        default: {
            console.error(`Timeline Content Unhandled "event.type": ${event.type}`);
            return null;
        }
    }
};

interface Props {
    event: Activity;
    currentUser: Person;
    onCommentAdded: (newActivity: NewActivityData) => void;
    onCommentDelete: (commentId: string) => void;
    defaultShowAddCommentForm?: boolean;
    threadMaxHeight?: number;
    canUserEditItem: boolean;
}

const TimelineEventContent: React.VFC<Props> = ({ event, ...otherCommentableCardProps }) => {
    const timeUtils = useAngularService('timeUtils');

    const content = useMemo(() => eventToContent(event, timeUtils), [event, timeUtils]);
    const shouldAllowComments = useMemo(() => {
        return allowsComments.has(event.type);
    }, [event]);

    if (!content) return null;

    return (
        <StyledCommentableCard
            targetId={event.id}
            comments={event.comments}
            isCommentsAllowed={shouldAllowComments}
            isFromTimeline
            {...otherCommentableCardProps}
        >
            {content && (
                <Text>
                    {event.type === ActivityType.NEW_COMMENT ? (
                        <CommentMarkdown commentText={content as string} />
                    ) : (
                        <TextEllipsis numberOfLines={2} tooltip>
                            {content}
                        </TextEllipsis>
                    )}
                </Text>
            )}
        </StyledCommentableCard>
    );
};

export default TimelineEventContent;
