import { useAngularService } from 'angulareact';
import type { FormEvent } from 'react';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { Checkbox } from '@tonkean/infrastructure';

const CenteredDiv = styled.div`
    text-align: center;
    margin-bottom: 10px;
`;

const MicrosoftAdminComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [shouldIncludeExtraScopes, setShouldIncludeExtraScopes] = useState<boolean>(false);

    const authenticateWithMicrosoft = async (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();

            let scopePermissions =
                'https://graph.microsoft.com/User.ReadWrite.All ' +
                'https://graph.microsoft.com/Directory.ReadWrite.All ' +
                'https://graph.microsoft.com/Directory.AccessAsUser.All ' +
                'https://graph.microsoft.com/Group.Read.All ' +
                'https://graph.microsoft.com/Group.ReadWrite.All ' +
                'https://graph.microsoft.com/GroupMember.ReadWrite.All ' +
                'offline_access';

            if (shouldIncludeExtraScopes) {
                scopePermissions +=
                    ' https://graph.microsoft.com/UserAuthenticationMethod.ReadWrite.All ' +
                    ' https://graph.microsoft.com/eDiscovery.ReadWrite.All ' + // May be needed for legalHold actions
                    'https://graph.microsoft.com/Policy.ReadWrite.AuthenticationMethod';
            }

            const code = await oauthHandler.microsoft(
                scopePermissions,
                environment.integrationKeysMap.microsoftUri,
                true,
            );

            authenticate(
                { code, shouldIncludeExtraScopes, redirectUri: environment.integrationKeysMap.microsoftUri },
                {},
                `${integrationConfiguration.displayName}`,
            );

            return { code, shouldIncludeExtraScopes, redirectUri: environment.integrationKeysMap.microsoftUri };
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-col api-token-authentication margin-bottom-16">
                <div className="flex mod-center margin-top-20">
                    <button className=" btn btn-secondary btn-lg" onClick={authenticateWithMicrosoft}>
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
            <CenteredDiv>
                <Checkbox
                    checked={shouldIncludeExtraScopes}
                    onChange={() => setShouldIncludeExtraScopes(!shouldIncludeExtraScopes)}
                >
                    Include the permissions scopes below
                </Checkbox>
            </CenteredDiv>
            <div className="flex mod-center common-subtitle-inner common-size-xxs margin-bottom-md">
                <ul className="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                    <li>UserAuthenticationMethod.ReadWrite.All</li>
                    <li>Policy.ReadWrite.AuthenticationMethod</li>
                    <li>eDiscovery.ReadWrite.All</li>
                </ul>
            </div>
        </div>
    );
};

export default MicrosoftAdminComponent;
