import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

export const IntegrationBox = styled.button<{ darkerBackground?: boolean }>`
    border: 1px solid ${Theme.current.palette.mainColors.gray_400};
    border-radius: 8px;
    height: 60px;
    width: 60px;
    padding: 0;
    display: grid;

    background-color: ${({ darkerBackground }) =>
        darkerBackground
            ? `${Theme.current.palette.mainColors.brightestGray}`
            : `${Theme.current.palette.colorPicker.HEX_FFFFFF}`};

    &:hover {
        border: 1px solid ${Theme.current.palette.mainColors.gray_500};
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }
`;

export const BoxContainer = styled.div`
    width: 60px;
`;

export const IntegrationNameContainer = styled.div`
    margin-top: 4px;
    text-transform: capitalize;

    color: ${Theme.current.palette.mainColors.gray_600};
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
`;
