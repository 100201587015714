export function ExcelSheetsModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    oauthHandler,
    $uibModal,
    utils,
    projectManager,
    tonkeanService,
) {
    $scope.pm = projectManager;
    $scope.tonkeanService = tonkeanService;
    $scope.temp = {};

    $scope.spreadsheetSearch = [];

    $scope.init = function (settings) {
        const state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.projectIntegration = settings.projectIntegration;

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;
    };

    $scope.onExcelSheetSelected = function (projectIntegration) {
        const existingProjectIntegrationIndex = utils.indexOf(
            projectManager.project.integrations,
            (prin) => prin.id === projectIntegration.id,
        );

        if (existingProjectIntegrationIndex > -1) {
            projectManager.project.integrations[existingProjectIntegrationIndex].displayName =
                projectIntegration.displayName;
        } else {
            projectManager.project.integrations.push(projectIntegration);
        }

        const data = {
            createdProjectIntegration: projectIntegration,
            integration: projectIntegration,
            integrations: [
                {
                    integration: projectIntegration,
                },
            ],
        };

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(data);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };
}
export default angular.module('tonkean.shared').controller('ExcelSheetsModalCtrl', ExcelSheetsModalCtrl);
