/**
 * Originally a function from https://github.com/tj/parse-curl.js
 * A function which giving a cURL block will parse it into a JSON object
 * Example: "curl 'http://google.com/' -H 'Accept-Encoding: gzip, deflate, sdch'"
 * will be { "method": "GET", "header": { "Accept-Encoding": "gzip, deflate, sdch" }, "url": "http://google.com/" }
 * @param curlRawText - given cURL block
 * @returns {method: string, header: {}, body: string, url: string} the cURL parsed to JSON object
 */
export class CurlParser {
    public static parse(curlRawText: string) {
        const isUrl = (url) => /^https?:\/\//.test(url);
        const parseField = (field) => field.split(/: (.+)/);
        if (curlRawText.indexOf('curl ') !== 0) {
            return;
        }
        const curlArguments = CurlParser.splitToShellWords(curlRawText);
        const curlJson = { method: 'GET', header: {}, url: '', body: '' };
        let state = '';

        curlArguments.forEach(function (arg) {
            switch (true) {
                case isUrl(arg):
                    curlJson.url = arg;
                    break;
                case arg === '-A' || arg === '--user-agent':
                    state = 'user-agent';
                    break;
                case arg === '-H' || arg === '--header':
                    state = 'header';
                    break;
                case arg === '-d' || arg === '--data' || arg === '--data-ascii':
                    state = 'data';
                    break;
                case arg === '-u' || arg === '--user':
                    state = 'user';
                    break;
                case arg === '-I' || arg === '--head':
                    curlJson.method = 'HEAD';
                    break;
                case arg === '-X' || arg === '--request':
                    state = 'method';
                    break;
                case arg === '-b' || arg === '--cookie':
                    state = 'cookie';
                    break;
                case arg === '--compressed':
                    curlJson.header['Accept-Encoding'] = curlJson.header['Accept-Encoding'] || 'deflate, gzip';
                    break;

                case !!arg:
                    switch (state) {
                        case 'header':
                            const field = parseField(arg);
                            curlJson.header[field[0]] = field[1];
                            state = '';
                            break;
                        case 'user-agent':
                            curlJson.header['User-Agent'] = arg;
                            state = '';
                            break;
                        case 'data':
                            if (curlJson.method === 'GET' || curlJson.method === 'HEAD') {
                                curlJson.method = 'POST';
                            }
                            curlJson.header['Content-Type'] =
                                curlJson.header['Content-Type'] || 'application/x-www-form-urlencoded';
                            curlJson.body = curlJson.body ? `${curlJson.body}&${arg}` : arg;
                            state = '';
                            break;
                        case 'user':
                            curlJson.header['Authorization'] = `Basic ${btoa(arg)}`;
                            state = '';
                            break;
                        case 'method':
                            curlJson.method = arg;
                            state = '';
                            break;
                        case 'cookie':
                            curlJson.header['Set-Cookie'] = arg;
                            state = '';
                            break;
                    }
                    break;
            }
        });

        return curlJson;
    }

    /**
     * A function which concatenates callbacks on a given string when it matches a pattern
     * @param string
     * @param pattern
     * @param callback
     * @returns {string}
     */
    private static scan(string, pattern, callback): string {
        let match;
        let result;
        result = '';
        while (string.length > 0) {
            match = string.match(pattern);
            match = string.match(pattern);
            if (match) {
                result += string.slice(0, match.index);
                result += callback(match);
                string = string.slice(match.index + match[0].length);
            } else {
                result += string;
                string = '';
            }
        }
        return result;
    }

    /**
     * Originally a function from https://github.com/jimmycuadra/shellwords
     * Splits a given shell line and splits it into tokens according to the word parsing rules of the UNIX Bourne shell.
     * Example: "foo 'bar baz'" -->  ["foo", "bar baz"]
     * @param line
     * @returns [] Array with the tokens from the given line
     */
    private static splitToShellWords(line: string): string[] {
        let field = '';
        const words: string[] = [];
        const regex = new RegExp(
            '\\s*' + // Leading whitespace
                '(?:' +
                '([^\\s\\\\\\\'\\"]+)' + // Normal words
                '|' +
                "'((?:[^\\'\\\\]|\\\\.)*)'" + // Single quotes
                '|' +
                '"((?:[^\\"\\\\]|\\\\.)*)"' + // Double quotes
                '|' +
                '(\\\\.?)' + // Escaped character
                '|' +
                '(\\S))' + // Garbage
                '(\\s|$)?',
        ); // Separator

        if (!line) {
            return [];
        }

        CurlParser.scan(line, regex, function (match) {
            // @ts-ignore
            const [raw, word, sq, dq, escape, garbage, separator] = match;
            if (garbage) {
                throw new Error('Unmatched quote');
            }
            field += word || (sq || dq || escape).replace(/\\(?=.)/, '');
            if (!separator) {
                return;
            }
            words.push(field);
            field = '';
        });
        if (field) {
            words.push(field);
        }
        return words;
    }
}
