import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addButtonBlockCreateAfterOption from '../sharedConfigComponents/button/createAfterOptions/addButtonBlockCreateAfterOption';
import addFormAnsweredBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addFormAnsweredBlockCreateAfterOption';
import botQuestionAndSendNotificationValidator from '../sharedConfigComponents/validators/botQuestionAndSendNotificatinValidator';
import formMustBeSelectedValidator from '../sharedConfigComponents/validators/formMustBeSelectedValidator';
import formQuestionTypeMustMatchFormType from '../sharedConfigComponents/validators/formQuestionTypeMustMatchFormType';
import storageProviderValidator from '../sharedConfigComponents/validators/storageProviderValidator';
import validateFormBeforePublish from '../sharedConfigComponents/validators/validateFormBeforePublish';
import validateFormFromDifferentModule from '../sharedConfigComponents/validators/validateFormFromDifferentModule';

import { LogicBlockConfigType, WorkflowVersionType } from '@tonkean/tonkean-entities';

const MonitorTracksLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.SEND_FORM,
    title: 'Send Update Form',
    iconClass: 'mod-send-form',
    description: 'Ask someone to fill out an update form. The result will be stored in the track fields.',
    hasPersonConfig: true,
    creatingActionItems: true,
    defaultActionDefinition: (project) => {
        return {
            initiativeOwner: true,
            communicationProjectIntegrationId: project?.defaultCommunicationProjectIntegration?.id,
        };
    },
    createAfterOptions: () => [addFormAnsweredBlockCreateAfterOption, addButtonBlockCreateAfterOption],
    validatorAsync: async ({
        definition,
        communicationIntegrationsService,
        group,
        syncConfigCacheManager,
        workflowVersionInfoRetriever,
        formInfoRetriever,
        project,
        projectManager,
        childImpacts,
    }) => {
        const validationObject: LogicBlockValidationObject = {};

        // Checking whether the user selected to send the form via dialog but doesn't have connected communication integration
        if (definition.formType === 'DIALOG' && !communicationIntegrationsService.anyIntegrationsConnected()) {
            validationObject.formTypeError = `No communication data source is connected. Please connect ${communicationIntegrationsService
                .getIntegrationTypesShortNames()
                .join('\\')}.`;
        }

        botQuestionAndSendNotificationValidator(
            validationObject,
            definition,
            workflowVersionInfoRetriever,
            syncConfigCacheManager,
            group,
            projectManager,
        );

        formMustBeSelectedValidator(validationObject, definition);
        if (definition.formId) {
            const form = await formInfoRetriever.getFormByWorkflowVersionType(
                definition.formId,
                WorkflowVersionType.DRAFT,
            );
            formQuestionTypeMustMatchFormType(validationObject, definition, form);
            validateFormFromDifferentModule(
                validationObject,
                form,
                definition,
                group.draftWorkflowVersionId,
                childImpacts,
                project.features,
            );
            validateFormBeforePublish(validationObject, form);
            storageProviderValidator(validationObject, definition, form);
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default MonitorTracksLogicBlockConfig;
