import React from 'react';
import styled from 'styled-components';

import type ItemsGridCellRendererParams from '../../entities/ItemsGridCellRendererParams';

import { TonkeanAvatar } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const UserAvatar = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: relative;
    margin-left: 2px;
`;

const Owner = styled.div<{ assigned: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ assigned }) => (assigned ? Theme.colors.gray_700 : 'grey')};
`;

const OwnerWrapper = styled.div`
    margin-top: 0;
`;

const OwnerCellRenderer: React.FC<ItemsGridCellRendererParams> = ({ value }) => {
    return (
        <OwnerWrapper>
            <Owner assigned={!!value}>
                <UserAvatar>
                    <TonkeanAvatar owner={value} />
                </UserAvatar>
                {value?.name || 'Unassigned'}
            </Owner>
        </OwnerWrapper>
    );
};

export default OwnerCellRenderer;
