<div>
    <div ng-if="data.integrationViews" class="margin-bottom">
        <select
            ng-model="data.control.selectedView"
            data-ng-disabled="loadingViews"
            class="form-control import-combobox inline-block margin-right-xs margin-top-xs"
            ng-options="opt.name for opt in data.integrationViews"
            ng-change="onIntegrationViewSelected()"
        >
            <option disabled selected value="">Select a view...</option>
        </select>
    </div>

    <!-- Filters -->
    <!-- ng-show is important here. Do not change to ng-if.
         We need the custom filters directive to be alive all the time, regardless of user showing or hiding it. -->
    <div ng-hide="data.hideFilters" class="margin-bottom common-border-bottom padding-bottom-lg">
        <tnk-custom-filters
            control="data.control"
            items-source="EXTERNAL"
            existing-definition="data.existingDefinition"
            copy-properties-from-definition="data.copyPropertiesFromDefinition"
            project-integration="data.projectIntegration"
            from-sync="true"
            hide-close-button="true"
            edit-mode="false"
            selected-entity-type="data.selectedEntityType"
            hide-time-range-selection="true"
            on-initialization-finished="loadPreview()"
            is-special-entity="data.isSpecialEntity"
            fallback-entity-type="data.fallbackEntityType"
            on-filters-change="loadPreview()"
            show-field-inspect="true"
        ></tnk-custom-filters>
    </div>

    <!-- Preview -->
    <tnk-sync-preview
        project-integration="data.projectIntegration"
        plural-entity-type="data.selectedEntityPluralName"
        selected-entity="data.selectedEntity"
        view-data="data.viewData"
        view-type="data.viewType"
        sync-validity-state="data.syncValidityState"
        title-expression="data.titleExpression"
        default-title-if-expression-empty="data.defaultTitleIfExpressionEmpty"
        hide-information="data.hideInformation"
        hide-bot="data.hideBotInPreview"
        hide-manage-triggers-modal="data.hideManageTriggersModal"
        override-activate-bot-object="data.overrideActivateBotObject"
        view-mod="data.projectIntegration.integration.integrationType === 'WEBHOOK' ? 'table' : null"
        full-screen="data.fullScreen"
        on-data-changed="(onDataChanged)"
    ></tnk-sync-preview>
</div>
