<!-- Create mode -->
<div ng-switch="data.configurationStep">
    <!-- Configure login details -->
    <div ng-switch-when="configureLoginDetails">
        <!-- Configuring login details for tableau -->
        <form name="login-details-form" ng-submit="createTableauIntegration()">
            <!-- Uri -->
            <div class="row margin-bottom flex-vmiddle margin-top">
                <div class="col-sm-4 common-text-right-no-xs">Uri</div>
                <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="Uri" ng-model="data.tableauUri" />
                </div>
            </div>

            <!-- User -->
            <div class="row margin-bottom flex-vmiddle">
                <div class="col-sm-4 common-text-right-no-xs">User</div>
                <div class="col-sm-8">
                    <input type="text" class="form-control" placeholder="User" ng-model="data.tableauUser" />
                </div>
            </div>

            <!-- Password -->
            <div class="row flex-vmiddle">
                <div class="col-sm-4 common-text-right-no-xs">Password</div>
                <div class="col-sm-8">
                    <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        ng-model="data.tableauPassword"
                    />
                </div>
            </div>

            <div class="margin-top-lg text-right">
                <!-- Connect -->
                <button
                    class="btn btn-primary"
                    type="submit"
                    ng-click="authorize()"
                    analytics-on
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Authenticate"
                    analytics-label="Tableau Views"
                >
                    Connect
                </button>
            </div>
        </form>

        <!-- Loading state -->
        <div
            ng-if="data.creatingTableauIntegration || data.fetchingTableauViews"
            class="margin-top flex-vmiddle mod-justify-end"
        >
            <i class="loading margin-right-xs"></i>
            <span>Creating...</span>
        </div>

        <!-- Error state -->
        <div
            ng-if="data.errorCreatingTableauIntegration || data.errorFetchingTableauViews"
            class="common-color-danger margin-top flex-vmiddle"
        >
            {{ data.errorCreatingTableauIntegration }}
        </div>
    </div>

    <!-- Selecting a view to connect with Tonkean -->
    <div ng-switch-when="selectTableauView">
        <!-- Title -->
        <div class="margin-top margin-bottom common-bold">Select a Tableau View -</div>

        <!-- Views list -->
        <div class="integration-instance-selection-box margin-top">
            <div
                ng-click="onTableauViewSelected(view)"
                ng-repeat="view in data.tableauViews"
                class="list-item btn-default pointer common-size-xxs"
            >
                <!-- View title -->
                <strong>{{ view.displayName }}</strong>

                <!-- View description -->
                <div class="common-size-xxxxs common-color-grey" ng-if="view.description">
                    <span>{{ view.description }}</span>
                </div>
            </div>
        </div>

        <!-- Loading state -->
        <div ng-if="data.fetchingTableauColumns" class="flex-vmiddle mod-justify-end margin-top">
            <i class="loading margin-right-xs"></i>
            <span>Loading columns...</span>
        </div>

        <!-- Error state -->
        <div ng-if="data.errorFetchingTableauColumns" class="common-color-danger margin-top">
            {{ data.errorFetchingTableauColumns }}
        </div>
    </div>

    <!-- Mapping a tableau view column to external activity meaningful property -->
    <div ng-switch-when="mapTableauViewColumns">
        <!-- Title -->
        <div class="margin-top">Map Cells to Track fields:</div>

        <!-- Separator -->
        <hr class="margin-normal-sm-v" />

        <!-- Track title map -->
        <form name="mappingCellsForm" ng-submit="createOrUpdateProjectIntegration()">
            <!-- Title mapping selection -->
            <div class="row flex-vmiddle">
                <!-- Title -->
                <div
                    class="
                        col-sm-5
                        common-size-xxs common-text-right-no-xs
                        padding-right-none
                        margin-top-xs margin-bottom-xs
                        flex-xs
                        mod-align-center
                    "
                >
                    <span class="common-bold">Item Name/Title:</span>
                    <span class="common-size-xxxxs common-color-danger margin-left-xs">*</span>
                </div>

                <!-- Input and explanation -->
                <div class="col-sm-7">
                    <div class="relative">
                        <!-- Column dropdown -->
                        <ui-select
                            theme="bootstrap"
                            ng-model="data.titleColumnMapping"
                            required
                            ng-disabled="!data.tableauViewColumns"
                        >
                            <ui-select-match placeholder="Choose a column">
                                {{ $select.selected.displayName }}
                            </ui-select-match>
                            <ui-select-choices repeat="column in data.tableauViewColumns | filter:$select.search">
                                <div ng-bind-html="column.displayName | escapeHtml | highlight: $select.search"></div>
                                <div
                                    class="common-size-xxxxxs common-color-grey"
                                    ng-if="column.example && column.example !== ''"
                                >
                                    <i>EX: {{ column.example }}</i>
                                </div>
                            </ui-select-choices>
                        </ui-select>

                        <!-- Field inspector button. -->
                        <span
                            class="svg-icon-nav-sm field-selector-inspect-icon pointer"
                            ng-click="openFieldInspectModal('titleFieldColumn')"
                        >
                            <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                        </span>
                    </div>
                </div>
            </div>

            <!-- Cancel & OK buttons -->
            <div class="margin-top-lg flex-vmiddle">
                <!-- Cancel -->
                <button type="button" class="btn btn-default margin-right-xs" ng-click="cancel()">Cancel</button>

                <!-- Ok -->
                <button type="submit" class="btn btn-primary margin-right-xs">OK</button>

                <!-- Loading state -->
                <div ng-if="data.fetchingTableauColumns" class="flex-vmiddle margin-top">
                    <i class="loading margin-right-xs"></i>
                    <span>Loading columns...</span>
                </div>

                <!-- Error state -->
                <div ng-if="data.errorFetchingTableauColumns" class="common-color-danger margin-top">
                    {{ data.errorFetchingTableauColumns }}
                </div>
            </div>

            <!-- Loading state -->
            <div ng-if="data.creatingOrUpdatingProjectIntegration" class="flex-vmiddle mod-justify-end margin-top">
                <i class="loading margin-right-xs"></i>
                <span>Creating...</span>
            </div>

            <!-- Error state -->
            <div ng-if="data.errorCreatingOrUpdatingProjectIntegration" class="common-color-danger margin-top">
                {{ data.errorCreatingOrUpdatingProjectIntegration }}
            </div>
        </form>
    </div>
</div>
