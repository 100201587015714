import React, { useCallback } from 'react';

import useGetLoadedFieldDefinitionById from '../../widgets/hooks/useGetLoadedFieldDefinitionById';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { UpdateField } from '@tonkean/fields';
import type { FieldDefinition, FieldFilter, Group, Person, Project, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

interface Props {
    fieldFilter: FieldFilter | undefined;
    projectId: Project['id'];
    groupId: Group['id'];
    workflowVersion: WorkflowVersion;
    fieldDefinition: FieldDefinition;
    onFilterChanged: (newVal: any) => void;
    label: string;
    onChangeFormattedLabel?: (fieldFilterKey: FieldDefinitionKey, newFormattedLabel: string | undefined) => void;
}

const WidgetItemsPersonFilter: React.FC<Props> = ({
    fieldFilter,
    projectId,
    groupId,
    workflowVersion,
    fieldDefinition,
    onFilterChanged,
    label,
    onChangeFormattedLabel,
}) => {
    const emitToastMessage = useToastMessage();
    const [{ loading }, getProjectPersonById] = useLazyTonkeanService('getProjectPersonById');

    const getLoadedFieldDefinitionById = useGetLoadedFieldDefinitionById(workflowVersion?.id);

    const onValueChanged = useCallback(
        async (newVal: Person['id']) => {
            try {
                let finalValue = newVal;
                if (finalValue) {
                    const foundPerson = await getProjectPersonById(projectId, newVal);
                    if (foundPerson) {
                        if (!fieldDefinition.isSpecialField) {
                            // if this is the owner field, we need the id (original val from selector) otherwise we need the email.
                            finalValue = foundPerson.email;
                        }
                        onChangeFormattedLabel?.(fieldDefinition.id, foundPerson.name);
                    }
                }
                onFilterChanged(finalValue);
            } catch {
                emitToastMessage(`Failed filtering field "${label}"`, 'danger');
            }
        },
        [
            onFilterChanged,
            getProjectPersonById,
            projectId,
            fieldDefinition.isSpecialField,
            fieldDefinition.id,
            onChangeFormattedLabel,
            emitToastMessage,
            label,
        ],
    );

    return (
        <UpdateField
            fieldDefinition={fieldDefinition}
            onValueSave={onValueChanged}
            projectId={projectId}
            groupId={groupId}
            initiative={undefined}
            workflowVersion={workflowVersion}
            viewOnly={false}
            fieldsAreBeingEdited={false}
            isSubmittingForm={false}
            overrideValue={fieldFilter?.value}
            getLoadedFieldDefinitionById={getLoadedFieldDefinitionById}
            showEmptyFieldWhenNoInitiative
            hideColorsAndTrends
            revealByDefault
            shouldOverrideValue
            isInPopover
            isFilterInput
        />
    );
};

export default WidgetItemsPersonFilter;
