import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const Label = styled.div`
    color: ${Theme.colors.gray_700};
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ProjectIntegrationCollectStatusCollectIsDisabled: React.FC = () => {
    return (
        <LabelWrapper>
            <Label data-automation="project-integration-collect-status-collect">Collect is disabled</Label>
        </LabelWrapper>
    );
};

export default ProjectIntegrationCollectStatusCollectIsDisabled;
