import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function StorageUploadLogicConfigurationCtrl($scope, integrations, projectManager) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        customTriggerId: ctrl.customTriggerId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,

        filterByIntegrations: Object.keys(integrations.getSupportedUploadingStorageIntegrations()),

        storageProviderDestinationId: '',
        fileNameExpression: {
            originalExpression: '',
            evaluatedExpression: '',
        },

        destinationFolderIdExpression: {
            originalExpression: '',
            evaluatedExpression: '',
        },
    };

    $scope.initializeFileSourceProperties = (existingDefinition) => {
        if (existingDefinition.source?.fileSourceType) {
            $scope.data.source = {
                fileSourceType: existingDefinition.source?.fileSourceType,
                storageIntegrationId: existingDefinition.source?.storageIntegrationId,
                storageIntegrationActionId: existingDefinition.source?.storageIntegrationActionId,
                extendedMatchConfiguration: existingDefinition.source?.extendedMatchConfiguration,
                url: existingDefinition.source?.url,
                workerStaticAssetId: existingDefinition.source?.workerStaticAssetId,
                workerStaticAssetDisplayName: existingDefinition.source?.workerStaticAssetDisplayName,
                matchEntityConfiguration: existingDefinition.source?.matchEntityConfiguration,
            };
        }
    };

    $scope.initializeStorageUploadDefinition = (existingDefinition) => {
        if (existingDefinition.storageProviderDestinationId) {
            $scope.data.storageProviderDestinationId = existingDefinition.storageProviderDestinationId;
        }

        if (existingDefinition.destinationFolderIdExpression) {
            $scope.data.destinationFolderIdExpression = existingDefinition.destinationFolderIdExpression;
        }

        if (existingDefinition.fileNameExpression) {
            $scope.data.fileNameExpression = existingDefinition.fileNameExpression;
        }
    };

    ctrl.$onInit = function () {
        const oldDefinition =
            ctrl?.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition || {};

        $scope.initializeFileSourceProperties(oldDefinition);
        $scope.initializeStorageUploadDefinition(oldDefinition);
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }

        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
        }
    };

    $scope.onWorkerFileSelectionChanged = (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        matchEntityConfiguration,
        storageIntegrationActionId,
    ) => {
        $scope.data.fileSourceType = fileSourceType;
        $scope.data.storageIntegrationId = storageIntegrationId;
        $scope.data.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.urlExpression = urlExpression;
        $scope.data.workerStaticAssetId = workerStaticAssetId;
        $scope.data.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.matchEntityConfiguration = matchEntityConfiguration;
        $scope.data.parametersValues = parametersValues;

        $scope.onActionsChanged(true);
    };

    $scope.onDestinationProjectIntegrationSelected = function (selectedProjectIntegration) {
        if (selectedProjectIntegration) {
            $scope.data.storageProviderDestinationId = selectedProjectIntegration.id;
        } else {
            $scope.data.storageProviderDestinationId = null;
        }
        $scope.onActionsChanged(true);
    };

    $scope.onActionsChanged = function (shouldSaveLogic) {
        const source = {
            fileSourceType: $scope.data.fileSourceType,
            storageIntegrationId:
                $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.storageIntegrationId : undefined,
            extendedMatchConfiguration:
                $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.extendedMatchConfiguration : undefined,
            url: $scope.data.fileSourceType === 'URL' ? $scope.data.urlExpression : undefined,
            workerStaticAssetId:
                $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetId : undefined,
            workerStaticAssetDisplayName:
                $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetDisplayName : undefined,
            matchEntityConfiguration:
                $scope.data.fileSourceType === 'MATCHED_ENTITIES' ? $scope.data.matchEntityConfiguration : undefined,
        };

        const actionDefinition = {
            actions: [
                {
                    type: 'STORAGE_UPLOAD',
                    definition: {
                        source,
                        storageProviderDestinationId: $scope.data.storageProviderDestinationId,
                        fileNameExpression: $scope.data.fileNameExpression,
                        destinationFolderIdExpression: $scope.data.destinationFolderIdExpression,
                    },
                },
            ],
        };

        if (ctrl.onActionsChanged) {
            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    $scope.onTonkeanExpressionFileNameChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.fileNameExpression.originalExpression = originalExpression;
        $scope.data.fileNameExpression.evaluatedExpression = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onTonkeanExpressionFileDestinationChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.destinationFolderIdExpression.originalExpression = originalExpression;
        $scope.data.destinationFolderIdExpression.evaluatedExpression = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('StorageUploadLogicConfigurationCtrl', lateConstructController(StorageUploadLogicConfigurationCtrl));
