<tnk-history-modal
    ng-if="data.group"
    show="!!$state.params.history"
    group="data.group"
    environment="$state.params.environment"
    worker-run-info="$state.params.wr"
    worker-run-stage="pathParamToWorkerRunStage[$state.params.workerRunStage]"
    inspect="!!$state.params.inspect"
    worker-run-logic-id="$state.params.workerRunLogicId"
    tab="tabKeyToWorkerRunTabType[$state.params.tab]"
    default-filter-search-term="$state.params.defaultFilterSearchTerm"
></tnk-history-modal>

<tnk-form-builder
    form-id="data.selectedOutlineFormId"
    group-id="data.groupId"
    workflow-version-id="data.workflowVersionId"
    form-type="data.selectedOutlineFormType"
    on-form-unselected="(onFormUnselected)"
    on-form-selected="(onOutlineFormSelected)"
    class="flex-grow flex common-height-full common-z-index-1 form-builder"
    ng-if="
        data.selectedOutlineTabKey &&
        data.selectedOutlineTabKey === 'forms' &&
        (data.selectedOutlineFormId || data.selectedOutlineFormType)
    "
    ng-class="{
        'mod-left-side-pane-open': data.isOutlineSidePaneOpen,
        hidden: !(
            data.selectedOutlineTabKey &&
            data.selectedOutlineTabKey === 'forms' &&
            (data.selectedOutlineFormId || data.selectedOutlineFormType)
        )
    }"
></tnk-form-builder>

<tnk-item-interface-builder
    class="flex-grow flex common-height-full common-z-index-1 form-builder"
    ng-if="
        data.selectedOutlineTabKey &&
        data.selectedOutlineTabKey === 'interfaces' &&
        data.itemInterfaceId &&
        data.workflowVersionId
    "
    ng-class="{
        'mod-left-side-pane-open': data.isOutlineSidePaneOpen,
    }"
    workflow-version-type="data.workflowVersionType"
    workflow-version-id="data.workflowVersionId"
    group="data.group"
></tnk-item-interface-builder>

<tnk-process-mapper-builder
  class="flex-grow flex common-height-full common-z-index-1 form-builder"
  ng-if="
        data.selectedOutlineTabKey &&
        data.selectedOutlineTabKey === 'process-mappers' &&
        data.processMapperId &&
        data.workflowVersionId
    "
  ng-class="{
        'mod-left-side-pane-open': data.isOutlineSidePaneOpen,
    }"
  workflow-version-type="data.workflowVersionType"
  workflow-version-id="data.workflowVersionId"
  group="data.group"
></tnk-process-mapper-builder>

<tnk-datasource-intake-source-connection
    class="flex-grow flex common-height-full common-z-index-1 form-builder"
    ng-if="
        data.selectedOutlineTabKey &&
        data.selectedOutlineTabKey === 'item-details' &&
        data.workflowVersion &&
        data.syncProjectIntegrationId
    "
    ng-class="{
        'mod-left-side-pane-open': data.isOutlineSidePaneOpen,
    }"
    workflow-version-id="data.workflowVersionId"
    sync-project-integration-id="data.syncProjectIntegrationId"
></tnk-datasource-intake-source-connection>

<tnk-field-definition-configuration-modal
    ng-if="data.selectedFieldProps"
    ng-class="{
        'mod-left-side-pane-open': data.isOutlineSidePaneOpen,
        hidden: !(
            data.selectedOutlineTabKey &&
            (data.selectedOutlineTabKey === 'fields' || data.selectedOutlineTabKey === 'forms') &&
            data.selectedFieldProps
        )
    }"
    class="flex-grow flex common-height-full common-z-index-1 field-modal"
    on-close="(onOutlineFieldUnselected)"
    group-id="data.selectedFieldProps.groupId"
    target-type="data.selectedFieldProps.targetType"
    field-definition-type="data.selectedFieldProps.fieldDefinitionType"
    project-integration="data.selectedFieldProps.projectIntegration"
    create-mode="data.selectedFieldProps.createMode"
    duplicate-mode="data.selectedFieldProps.duplicateMode"
    existing-field-definition="data.selectedFieldProps.existingFieldDefinition"
    after-create-or-update-callback="data.selectedFieldProps.afterCreateOrUpdateCallback"
    delete-callback="data.selectedFieldProps.deleteCallback"
    open-in-step="data.selectedFieldProps.openInStep"
    quick-create-for-external="data.selectedFieldProps.quickCreateForExternal"
    start-with-data-source="data.selectedFieldProps.startWithDataSource"
    selected-entity="data.selectedFieldProps.selectedEntity"
    is-worker-mode="data.selectedFieldProps.isWorkerMode"
    is-system-utilized="data.selectedFieldProps.isSystemUtilized"
    override-formula-operator="data.selectedFieldProps.overrideFormulaOperator"
    override-field-is-hidden="data.selectedFieldProps.overrideFieldIsHidden"
    matched-entity-from-workflow-version-id="data.selectedFieldProps.matchedEntityFromWorkflowVersionId"
    opened-from-custom-trigger-id="data.selectedFieldProps.openedFromCustomTriggerId"
    manual-value="data.selectedFieldProps.manualValue"
    id-only-mode="data.selectedFieldProps.idOnlyMode"
    workflow-version-id="data.selectedFieldProps.workflowVersionId"
    secondary-id="data.selectedFieldProps.secondaryId"
    allow-only-field-definition-data-types="data.selectedFieldProps.allowOnlyFieldDefinitionDataTypes"
    environment="data.workflowVersionType === 'DRAFT' ? 'build' : 'production'"
    on-environment-changed="(onWorkflowVersionTypeSelected)"
    on-has-pending-changes-changed="(onFieldEditorHasPendingChangesChanged)"
    is-for-matched-item="data.selectedFieldProps.isForMatchedItem"
    data-automation="field-definition-configuration-modal"
></tnk-field-definition-configuration-modal>

<!-- Header section -->
<tnk-module-navigation
    ng-if="data.group"
    on-environment-change="(onWorkflowVersionTypeSelected)"
    current-environment="data.workflowVersionType === 'DRAFT' ? 'build' : 'production'"
    environment-is-active="isWorkflowVersionActive()"
    workflow-version-id="data.workflowVersionId"
    on-live-report-click="(toggleLiveReportActiveState)"
    is-live-report-on="!wvm.getCachedWorkflowVersion(data.workflowVersionId).dashboardHidden"
    group="data.group"
    on-test-workflow-clicked="(onTestWorkflowClicked)"
    successful-validation="(data.validationState === data.validationStates.VALID)"
    show-button-back-to-mark-for-publish="(showButtonBackToMarkForPublish)"
    on-successful-publish="(onSuccessfulPublish)"
    on-revert-workflow-version-clicked="(onRevertWorkflowVersionClicked)"
    on-ready-top-publish-workflow-clicked="(onReadyToPublishWorkflowClicked)"
    on-discard-pending-changes-modal-opened="(onDiscardPendingChangesModalOpened)"
></tnk-module-navigation>

<tnk-drilldown-header-navigation
    ng-if="data.group"
    on-environment-change="(onWorkflowVersionTypeSelected)"
    current-environment="data.workflowVersionType === 'DRAFT' ? 'build' : 'production'"
    environment-is-active="isWorkflowVersionActive()"
    group="data.group"
    disabled-environments="disabledEnvironments"
></tnk-drilldown-header-navigation>

<!-- Left (outline) side-pane -->
<tnk-worker-outline
    is-loading="!data.group"
    node-tree="ctm.workflowVersionIdToCustomTriggersGraph[data.workflowVersionId]"
    sync-object="::data.syncObject"
    workflow-version-id="{{ data.workflowVersionId }}"
    select-node="selectComponentFromOutline(node)"
    create-new-node="createNewLogic({parentNode, displayName, enricher})"
    select-data-source-node="selectDataSourceComponent()"
    close-panel="toggleOutlineSidePane()"
    group-id="data.groupId"
    title-label="workerTypeInfo.graphLabel"
    show-example-item-selector="true"
    selected-tab-key="data.selectedOutlineTabKey"
    reload-configuration="data.reloadOutlineConfiguration"
    on-tab-selected="onOutlineTabSelected(tab)"
    is-outline-open="data.isOutlineSidePaneOpen"
    on-is-open-changed="onOutlineIsOpenChanged(isOpen)"
    can-modify-bot="canModifyBot"
    on-outline-form-selected="(onOutlineFormSelected)"
    register-form-deleted-callback="registerFormDeletedCallback"
    edit-form-id="data.selectedOutlineFormId"
    form-type="data.selectedOutlineFormType"
    on-outline-field-selected="(onOutlineFieldSelected)"
    field-editor-has-pending-changes="data.fieldEditorHasPendingChanges"
    on-test-workflow-clicked="(onTestWorkflowClicked)"
    on-revert-workflow-version-clicked="(onRevertWorkflowVersionClicked)"
    workflow-folder-project-integrations-access="data.workflowFolderProjectIntegrationsAccess"
    request-logics-validation="(onValidationRequested)"
    invalid-logics="data.invalidLogics"
></tnk-worker-outline>

<!-- Editor section -->
<div
    class="worker-editor mod-item-details"
    data-automation="worker-editor-template-module-editor"
    id="worker-editor-scroll-container"
    ng-class="{
        'mod-right-side-pane-open': data.selectedSidePaneType,
        'mod-left-side-pane-open': data.isOutlineSidePaneOpen
    }"
    click-on-target-only="selectComponent(null,null, null)"
    enable-click-on-target-if="mod-right-side-pane-open"
    dont-click-target-if="dont-close-workers-sidepane-on-click"
>
    <!-- Magnifier control buttons -->
    <div class="worker-editor-control-panel flex-vmiddle">
        <div class="btn-group workflow-outline-btn" role="group">
            <button
                type="button"
                data-ng-disabled="data.zoom < 0.6"
                class="btn btn-default common-no-outline btn-slim dont-close-workers-sidepane-on-click"
                ng-click="zoomGraph(-0.1)"
            >
                <i class="fa fa-search-minus"></i>
            </button>
            <button
                type="button"
                data-ng-disabled="data.zoom > 1.4"
                class="btn btn-default common-no-outline btn-slim dont-close-workers-sidepane-on-click"
                ng-click="zoomGraph(0.1)"
            >
                <i class="fa fa-search-plus"></i>
            </button>
        </div>

        <a
            class="common-size-xxxxs margin-left dont-close-workers-sidepane-on-click"
            ng-if="data.zoom !== 1"
            ng-click="zoomGraph()"
        >
            Reset
        </a>
    </div>

    <!-- Return to draft version button -->
    <a
        ng-if="data.workflowVersion && !data.workflowVersionType"
        class="btn btn-default return-to-build"
        ui-sref="product.workerEditor({ env: 'DRAFT', historyVersion: '' })"
    >
        Return to {{ gm.currentUserIsOwnerMap[data.group.id] ? 'build' : 'production' }} environment
    </a>

    <div ng-if="!data.loadingCustomTriggers">
        <!-- Bob the builder line -->
        <div
            ng-if="data.workflowVersionType === 'DRAFT'"
            class="version-control-builder-line common-builder-line"
        ></div>

        <div
            class="flex mod-align-start flex-col margin-top-xxlg"
            id="worker-graph"
        >
            <tnk-item-details-module-editor
              workflow-version-id="data.workflowVersionId"
              target-id="data.groupId"
              on-open-tab-click="(selectOutlineTab)"
              on-view-items-clicked="(previewInitiatives)"
              has-validation-error="data.invalidLogics['DATASOURCE']"
            ></tnk-item-details-module-editor>
            <!-- Predefined components -->

            <!-- Graph components -->
            <div class="relative worker-editor-graph">

                <!-- Graph traversal UI -->
                <div
                    class="padding-left-lg"
                    tnk-dnd-list="{{ pm.groupsMap[data.groupId].workerType !== data.workerTypes.FULL_TIME.key }}"
                    tnk-dnd-list-id="{{::ctm.workflowVersionIdToCustomTriggersGraph[data.workflowVersionId].node}}"
                    tnk-dnd-drop="onDrop(dndData, dndIndex, dndListId)"
                    tnk-dnd-get-marker="getDndMarker()"
                >
                    <!--ng-class="pm.groupsMap[data.groupId].workerType === data.workerTypes.FULL_TIME.key ? 'padding-left-xxlg' : 'padding-left-lg'">-->
                    <!-- Iterating the children of root for starting point of graph -->
                    <div
                            ng-repeat="innerLogic in ctm.workflowVersionIdToCustomTriggersGraph[data.workflowVersionId].impacts"
                            class="flex relative worker-editor-logic-box mod-item-details"
                            ng-class="{
                            'mod-when': innerLogic.node.customTriggerType === 'AUTONOMOUS'  && !data.features.tonkean_show_inner_item_idd
                        }"
                    >
                        <!-- Graph connecting line -->
                        <tnk-Item-Details-When-Line
                                ng-if="data.features.tonkean_show_inner_item_idd"
                                workflow-version-id="data.workflowVersionId"
                                group-target-id="data.groupId"
                                custom-trigger-target-id="innerLogic.node.id"
                                monitor-inner-items="innerLogic.node.monitorInnerItems"
                                monitored-inner-item-custom-trigger="innerLogic.node.monitorInnerItemsCreatedByCustomTriggerId"
                                show-only-inner-item="false"
                        ></tnk-Item-Details-When-Line>
                        <div
                                ng-if="innerLogic.node.customTriggerType === 'AUTONOMOUS'  && !data.features.tonkean_show_inner_item_idd"
                                class="worker-editor-inner-hline mod-arrow"
                        >
                        </div>

                        <!-- Recursively adding rest of the graph tree to UI -->
                        <tnk-worker-logic
                                configured-logic="innerLogic"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                sync-object="::data.syncObject"
                                invalid-logics="data.invalidLogics"
                                group-id="data.groupId"
                                can-modify-bot="canModifyBot"
                                is-parent-disabled="false"
                                select-logic-callback="selectLogicComponent(logic,logicConfigMode)"
                                create-new-logic-callback="createNewLogic(params)"
                                close-side-pane-callback="closeSidePane()"
                                select-outline-tab-callback="selectOutlineTab(tabKey, additionalParams)"
                        ></tnk-worker-logic>

                        <!-- Plus Button -->
                        <span
                                class="worker-editor-add-logic-container mod-item-details"
                                ng-class="{'mod-item-details-with-icon': data.features.tonkean_show_inner_item_idd}"
                                ng-if="$last"
                        >
                            <span
                                    class="dont-close-workers-sidepane-on-click "
                                    data-automation="workerEditor-template-add-new-custom-trigger"
                                    ng-click="data.workflowVersionType !== 'DRAFT' && canModifyBot(true)"
                                    ng-disabled="data.workflowVersionType !== 'DRAFT'"
                            >
                                <tnk-plus-button-autonomous-types-dropdown on-autonomous-type-selected="(addNewAutonomousTrigger)"/>
                            </span>
                        </span>
                    </div>
                    <div
                            ng-if="!ctm.workflowVersionIdToCustomTriggersGraph[data.workflowVersionId].impacts.length"
                            class="flex relative worker-editor-logic-box mod-when mod-item-details"
                    >
                        <!-- Plus Button -->
                        <span
                                class="worker-editor-add-logic-container mod-item-details"
                                ng-class="{'mod-item-details-with-icon': data.features.tonkean_show_inner_item_idd}"
                        >
                            <span
                                    class="dont-close-workers-sidepane-on-click "
                                    data-automation="workerEditor-template-add-new-custom-trigger"
                                    ng-click="data.workflowVersionType !== 'DRAFT' && canModifyBot(true)"
                                    ng-disabled="data.workflowVersionType !== 'DRAFT'"
                            >
                                <tnk-plus-button-autonomous-types-dropdown on-autonomous-type-selected="(addNewAutonomousTrigger)"/>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Worker loading -->
    <div ng-if="data.loadingCustomTriggers" class="worker-editor-loading-container flex-vmiddle">
        <div class="worker-editor-loading loading-placeholder"></div>
    </div>
</div>

<!-- Right (data) side-pane -->
<tnk-side-pane
    is-open="data.selectedSidePaneType"
    custom-class="workers-right-side-pane"
    dont-prevent-scroll-bubbling="true"
    double-size-pane="data.sideBySideDiffDisplayed"
    pane-disabled="!data.workflowVersionType || (data.workflowVersionType !== 'DRAFT' && data.selectedSidePaneType !== 'dashboard')"
    on-disabled-click="(canModifyBot)"
>
    <!-- Return to mark for publish button -->
    <div
        class="btn btn-default return-from-compare-div"
        ng-if="data.sideBySideDiffDisplayed && data.showBackToMarkForPublishButton"
    >
        <button class="return-from-compare-button" ng-click="onBackToMarkForPublishClicked()">
            <span class="svg-icon-sm margin-right-lg back-compare-return">
                <tnk-icon src="/images/icons/return.svg"></tnk-icon>
            </span>
            Back to Mark for Publish
        </button>
        <button class="cancel-back-to-publish" ng-click="closeSidePane()">
            <span class="svg-icon-sm">
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </span>
        </button>
    </div>
    <div ng-switch="data.selectedSidePaneType">
        <!-- Data source configuration -->
        <div ng-switch-when="dataSource" class="bot-side-pane-space padding-normal-sm">
            <tnk-data-source-worker-component
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                invalid-logics="data.invalidLogics"
                on-data-source-changed="onActionsChanged()"
                on-component-closed="closeSidePane()"
                workflow-folder-project-integrations-access="data.workflowFolderProjectIntegrationsAccess"
                set-should-reload-fields="reloadOutlineConfiguration"
                on-recurrence-time-selection-changed="onRecurrenceTimeSelectionChanged()"
            ></tnk-data-source-worker-component>
        </div>

        <!-- Logic configuration -->
        <div
            ng-switch-when="logic"
            class="logic-component-side-pane"
            ng-class="{ 'mod-side-by-side': data.sideBySideDiffDisplayed }"
        >
            <!-- Published version -->
            <tnk-logic-worker-component
                ng-if="data.sideBySideDiffDisplayed"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ wvm.getPublishedVersionFromCache(data.groupId).id }}"
                workflow-version-type="{{ WorkflowVersionType.PUBLISHED }}"
                configured-logic="ctm.findLogicDataInGraphById(wvm.getPublishedVersionFromCache(data.groupId).id, data.selectedLogic.node.id).logic"
                displays-history-version="true"
                logic-config-mode="data.logicConfigMode"
                view-only="true"
                blocked-element="true"
                preview-evaluation-source="$scope.ctm.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][$scope.data.selectedLogic]"
            ></tnk-logic-worker-component>

            <div ng-if="data.sideBySideDiffDisplayed" class="logic-separator">
                <button
                    class="logic-separator-button"
                    type="button"
                    ng-click="onSideBySideDiffSelected(false)"
                    uib-tooltip="Hide history version"
                    tooltip-placement="bottom-auto"
                >
                    <i class="fa fa-forward"></i>
                </button>
            </div>

            <!-- Current version -->
            <tnk-logic-worker-component
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                workflow-version-type="{{ data.workflowVersionType }}"
                create-multiple-logic-components="createMultipleLogicComponents(parentNode, definitionsArray, replaceInPlace)"
                duplicate-logic="duplicateLogic(logicToDuplicate)"
                remove-special-children="removeSpecialChildren"
                get-special-children="getSpecialChildren"
                configured-logic="data.selectedLogic"
                sync-object="data.selectedLogicSyncObject"
                invalid-logics="data.invalidLogics"
                on-component-closed="closeSidePane()"
                create-new-logic-callback="createNewLogic(params)"
                on-actions-changed="onActionsChanged(saveFunction)"
                on-request-validation="onValidationRequested()"
                displays-history-version="false"
                include-space-filler-for-warning-message="data.sideBySideDiffDisplayed"
                on-side-by-side-diff-selected="onSideBySideDiffSelected(true)"
                logic-config-mode="data.logicConfigMode"
                workflow-folder-project-integrations-access="data.workflowFolderProjectIntegrationsAccess"
                open-field-modal="(openFieldModal)"
                preview-evaluation-source="$scope.ctm.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][$scope.data.selectedLogic]"
                view-only="data.workflowVersionType !== 'DRAFT'"
            ></tnk-logic-worker-component>
        </div>

        <!-- Dashboard configuration -->
        <div ng-switch-when="dashboard" class="bot-side-pane-space padding-normal-sm">
            <tnk-dashboard-worker-component
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                on-component-closed="closeSidePane()"
            ></tnk-dashboard-worker-component>
        </div>

        <!-- Bot configuration -->
        <div ng-switch-when="bot" class="bot-side-pane-space padding-normal-sm">
            <tnk-bot-worker-component
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                on-component-closed="closeSidePane()"
            ></tnk-bot-worker-component>
        </div>
    </div>
</tnk-side-pane>
