/**
 * The defaults for most of the form answered blocks.
 *
 * Created to prevent a circular dependency between the block and its enricher
 */
const addFormBlockCreateOptionDefaults = {
    label: 'Form Answered',
    blockDisplayName: 'When Form Answered',
};
export default addFormBlockCreateOptionDefaults;
