import ManualFieldValidatorUtils from './ManualFieldValidatorUtils';
import { integrationsConsts } from '../integrationsConsts.service';

import utils from '@tonkean/utils';

/**
 * This class is responsible on validating manual field action.
 */
class ManualFieldUpdateValidator {
    public static validateAction(definition: any, validationObject: any, projectManager: any): void {
        const performedActionDefinition = definition.performedActionDefinition;
        // Creating a new validation object.
        const fieldsErrorsValidation = {};
        this.validateMinimumRequiredFields(performedActionDefinition, fieldsErrorsValidation);

        // Making sure the update restrictions are met. In this case we check that google sheet append method does not update
        ManualFieldValidatorUtils.integrationFieldUpdateValidator(
            definition,
            performedActionDefinition,
            fieldsErrorsValidation,
            projectManager,
        );
        this.runActionValidator(definition, fieldsErrorsValidation);

        // Only if we have any errors to display to the user we assign them to the general validation object.
        if (Object.keys(fieldsErrorsValidation).length > 0) {
            validationObject.fieldsValidation = fieldsErrorsValidation;
        }
    }

    /**
     * If an action has custom validator, we run it.
     */
    private static runActionValidator(definition: any, validationObject: any) {
        const actionValidator =
            integrationsConsts.getActionsByIntegrationsMap()[definition.integrationType].updateField[
                definition?.performOnEntity?.entity
            ]?.actionValidator;
        if (actionValidator) {
            const externalDefinitions: { name: string; value: object }[] = Object.values(
                definition.performedActionDefinition.externalDefinitions,
            );
            const fields: Record<string, object> = externalDefinitions.reduce(
                (map: Record<string, object>, { name, value }) => {
                    map[name] = value;
                    return map;
                },
                {},
            );

            !utils.isEmpty(fields) && actionValidator(fields, validationObject);
        }
    }

    private static validateMinimumRequiredFields(performedActionDefinition, validationObject: any) {
        if (
            (!performedActionDefinition.fieldDefinitionIdToValueMap ||
                Object.keys(performedActionDefinition.fieldDefinitionIdToValueMap).length === 0) &&
            (!performedActionDefinition.externalDefinitions ||
                Object.keys(performedActionDefinition.externalDefinitions).length === 0)
        ) {
            validationObject.noSelectedFields = 'Must select at least 1 field to update.';
        }
    }
}

export default ManualFieldUpdateValidator;
