import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import ProjectIntegrationActionChildEntitiesPredefinedParameter from './ProjectIntegrationActionChildEntitiesPredefinedParameter';
import ProjectIntegrationActionInputPredefinedParameters from './ProjectIntegrationActionInputPredefinedParameters';
import ProjectIntegrationActionPredefinedParameter from './ProjectIntegrationActionPredefinedParameter';
import projectIntegrationActionConstantParamsIdToConfig, {
    ProjectIntegrationActionConstantParams,
} from '../../../projectIntegrationActionConstantParamIdToConfig';
import { ProjectIntegrationPaginatedActionConstantParams } from '../../../projectIntegrationPaginatedActionConstantParams';
import ProjectIntegrationActionFieldListModal from '../ProjectIntegrationActionFieldListModal/ProjectIntegrationActionFieldListModal';
import ProjectIntegrationActionRequestButtons from '../ProjectIntegrationActionRequestPage/ProjectIntegrationActionRequestButtons';

import { useAsyncMethod, useTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { H4, Paragraph, Separator, Toggle, Tooltip } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type {
    BaseActionParameter,
    FieldListInputParameter,
    IntegrationSupportedEntity,
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionParametersDefinition,
} from '@tonkean/tonkean-entities';
import { ActionDefinitionType, ActionParameterType, ActionType, HttpMethodType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import utils from '@tonkean/utils';

const PropRow = styled.li<{ isConstantParam?: boolean }>`
    ${({ isConstantParam }) =>
        !isConstantParam &&
        css`
            display: flex;
        `};
    border-bottom: 1px solid #cdd2d8;
    padding-bottom: 12px;
    padding-top: 12px;
`;

const FieldListParams = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 20px;
`;

const StyledClickableLink = styled(ClickableLink)`
    border-bottom: 1px solid #cdd2d8;
    padding-bottom: 12px;
    padding-top: 12px;
`;

const ActionParametersHeader = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.current.palette.colorPicker.HEX_34393E};
    font-weight: 500;
    flex-grow: 1;
`;

const FieldListParametersHeader = styled(ActionParametersHeader)`
    margin-top: 30px;
`;

const IntegrationEntityName = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.current.palette.colorPicker.HEX_34393E};
    margin-left: 6px;
    font-weight: 500;
`;

const ActionParametersDescription = styled.div`
    margin-top: 9px;
    color: ${Theme.colors.gray_600};
    font-size: 10px;
`;

const HeaderButtons = styled.div`
    margin-bottom: 20px;
`;

const RequiredEntityWrapper = styled.div``;

const RequiredEntityText = styled.div`
    color: ${Theme.colors.gray_700};
    margin-bottom: 10px;
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
`;

const ParamName = styled.span<{ colored?: boolean }>`
    ${({ colored }) =>
        colored &&
        css`
            color: ${Theme.current.palette.colorPicker.HEX_605CE4};
        `};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
`;

const ParamDescription = styled.div`
    font-size: 10px;
    color: ${Theme.colors.gray_700};
`;

const ListOfProps = styled.ul`
    padding: 0;
    list-style-type: none;
    margin-bottom: 20px;
`;

const AddParamButton = styled.button<{ isNotValid?: boolean }>`
    ${({ isNotValid }) =>
        isNotValid &&
        css`
            cursor: default !important;
            color: ${Theme.colors.gray_500} !important;
        `};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    text-transform: capitalize;
    color: ${Theme.colors.primary};
    cursor: pointer;
    margin-top: 8px;
`;

const ParamHeaderAndRunTestButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
`;

const ProjectIntegrationActionChildEntitiesPredefinedParameterWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
`;

const ProjectIntegrationActionCustomFieldsToggleWrapper = styled.div`
    margin-top: 30px;
    margin-bottom: 10px;
`;

const ProjectIntegrationActionCustomFieldsToggleTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface Props {
    projectIntegration: ProjectIntegration;
    parametersDefinition: ProjectIntegrationActionParametersDefinition;
    onChange: (parametersDefinition: ProjectIntegrationActionParametersDefinition) => void;
    constantParameters: BaseActionParameter[];
    projectIntegrationAction: ProjectIntegrationAction;
    fieldListParameters: BaseActionParameter[];
    shouldShowHeaderButtons?: boolean;
    isModuleParams?: boolean;
}

const ProjectIntegrationActionParametersView: React.FC<Props> = ({
    projectIntegration,
    parametersDefinition,
    onChange: onChangeProp,
    constantParameters,
    projectIntegrationAction,
    fieldListParameters,
    shouldShowHeaderButtons = true,
    isModuleParams = true,
}) => {
    const [activeInput, setActiveInput] = useState<'param' | 'childParam'>('param');
    const integrationMetadataManager = useAngularService('integrationMetadataManager');
    const [fieldListModalOpen, setFieldListModalOpen] = useState(false);
    const [selectedFieldList, setSelectedFieldList] = useState<FieldListInputParameter | undefined>();

    const [newParamName, setNewParamName] = useState<string>('');
    const [newChildParamName, setChildNewParamName] = useState<string>('');

    const { data: projectIntegrationBaseUrl } = useTonkeanService('getIntegrationApiBaseUrl', projectIntegration.id);

    const isFieldListEnabled = useFeatureFlag('tonkean_feature_custom_action_field_list');

    const shouldShowFieldList = ![ActionType.STREAM_IMAGE, ActionType.UPLOAD, ActionType.DOWNLOAD].includes(
        projectIntegrationAction.actionType,
    );

    const emitToastMessage = useToastMessage();

    const onChange = useCallback(
        (
            setParameterDefinition: (
                currentParameterDefinition: ProjectIntegrationActionParametersDefinition,
            ) => ProjectIntegrationActionParametersDefinition,
        ) => {
            const defaultParameterDefinition: ProjectIntegrationActionParametersDefinition = {
                entitiesToRunOn: [],
                parameters: [],
                isChildEntitiesEnabled: false,
                isCustomFieldsEnabled: true,
                childEntitiesParameterDefinitions: [],
            };

            onChangeProp(setParameterDefinition(parametersDefinition || defaultParameterDefinition));
        },
        [onChangeProp, parametersDefinition],
    );

    const isParameterNameValid = useCallback((name: string, parameterArray?: BaseActionParameter[], id?: string) => {
        const isNotUniq = parameterArray?.some(
            (parameter) => id !== parameter.id && parameter.displayName.toLowerCase() === name.toLowerCase(),
        );

        return !!(name && !isNotUniq);
    }, []);

    const onPressEnterAddNewProperty = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const isChildParam = activeInput === 'childParam';

        const parameter: BaseActionParameter = {
            id: utils.guid(),
            displayName: isChildParam ? newChildParamName : newParamName,
            parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        };
        addNewParameter(parameter, isChildParam);
    };

    const removeParamFromList = (paramIdToRemove: string, isChildEntity: boolean = false) => {
        const updateChildEntities = (childEntities: ProjectIntegrationActionParametersDefinition[]) => {
            return childEntities.map((childEntity) => ({
                ...childEntity,
                parameters: childEntity.parameters.filter((parameter) => parameter.id !== paramIdToRemove),
                childEntitiesParameterDefinitions: updateChildEntities(childEntity.childEntitiesParameterDefinitions),
            }));
        };

        onChange((currentParameterDefinition: ProjectIntegrationActionParametersDefinition) => ({
            ...parametersDefinition,
            parameters: isChildEntity
                ? parametersDefinition.parameters
                : parametersDefinition.parameters.filter((parameter) => parameter.id !== paramIdToRemove),
            childEntitiesParameterDefinitions: isChildEntity
                ? updateChildEntities(parametersDefinition.childEntitiesParameterDefinitions)
                : parametersDefinition.childEntitiesParameterDefinitions,
        }));
    };

    const updateChildEntitiesParameters = (currentDefinition, parameter) => {
        const updatedChildEntities = (currentDefinition.childEntitiesParameterDefinitions || []).map(
            (childDefinition) => ({
                ...childDefinition,
                parameters: childDefinition.parameters ? [...childDefinition.parameters, parameter] : [parameter],
            }),
        );

        // If no child entities exist, create a new one with the provided parameter
        if (updatedChildEntities.length === 0) {
            updatedChildEntities.push({
                childEntitiesParameterDefinitions: [],
                entitiesToRunOn: [],
                isChildEntitiesEnabled: false,
                parameters: [parameter],
            });
        }

        return {
            ...currentDefinition,
            childEntitiesParameterDefinitions: updatedChildEntities,
        };
    };

    const updateMainParameters = (currentDefinition, parameter) => ({
        ...currentDefinition,
        parameters: [...currentDefinition.parameters, parameter],
    });

    const addNewParameter = useCallback(
        (parameter, isChildParameter = false) => {
            // Determine the parameter list based on whether it's a child parameter or not
            const parameterList = isChildParameter
                ? parametersDefinition.childEntitiesParameterDefinitions?.[0]?.parameters
                : parametersDefinition.parameters;

            // Check if the parameter name is valid
            const valid = isParameterNameValid(parameter.displayName, parameterList);

            if (valid) {
                // Clear input if the parameter type is not FIELD_LIST
                if (parameter.parameterType !== ActionParameterType.FIELD_LIST) {
                    isChildParameter ? setChildNewParamName('') : setNewParamName('');
                }

                // Update the parameter definition based on whether it's a child parameter or not
                onChange((currentParameterDefinition) => {
                    const updatedDefinition = isChildParameter
                        ? updateChildEntitiesParameters(currentParameterDefinition, parameter)
                        : updateMainParameters(currentParameterDefinition, parameter);

                    return updatedDefinition;
                });
            }
        },
        [
            isParameterNameValid,
            onChange,
            parametersDefinition.childEntitiesParameterDefinitions,
            parametersDefinition.parameters,
        ],
    );

    const { data: supportedEntitiesDict } = useAsyncMethod(
        integrationMetadataManager,
        'getIntegrationEntitiesAsDict',
        projectIntegrationAction.projectIntegrationId,
    );

    const entity: IntegrationSupportedEntity | undefined = useMemo(() => {
        const entityName = projectIntegrationAction.parametersDefinition?.entitiesToRunOn?.[0];

        if (supportedEntitiesDict && entityName) {
            return supportedEntitiesDict[entityName];
        }
    }, [supportedEntitiesDict, projectIntegrationAction.parametersDefinition?.entitiesToRunOn]);

    // This dictionary maps between constant param id to tooltip,
    // This Tooltip will appear onHover constant action param.
    const constantParamToTooltipDictionary: Record<ProjectIntegrationActionConstantParams, string> = useMemo(() => {
        return {
            [ProjectIntegrationActionConstantParams.ENV_URL]: `Data Source Name: "${projectIntegration.displayName}" Auto Populated URL: "${projectIntegrationBaseUrl?.apiBaseUrl}"`,
            [ProjectIntegrationActionConstantParams.WEBHOOK_URL]: `Data Source Name: "${projectIntegration.displayName}" Auto Populated URL: "${projectIntegrationBaseUrl?.incomingWebhookUrl}"`,
            [ProjectIntegrationActionConstantParams.TODAY]: `Today date`,
            [ProjectIntegrationActionConstantParams.FILE_ID]: `The file ID that will be uploaded`,
            [ProjectIntegrationActionConstantParams.FILE_STREAM]: `The file stream`,
            [ProjectIntegrationActionConstantParams.FILE_NAME]: `The file name`,
            [ProjectIntegrationActionConstantParams.MIME_TYPE]: `The file mime type`,
            [ProjectIntegrationActionConstantParams.IMAGE_URL]: `The image private url`,
            [ProjectIntegrationActionConstantParams.IMAGE_ITEM_ID]: `The image private item id`,
            [ProjectIntegrationActionConstantParams.IMAGE_ETAG]: `The image private etag`,
            [ProjectIntegrationPaginatedActionConstantParams.IS_FIRST_PAGE]: `Is it the first page`,
            [ProjectIntegrationPaginatedActionConstantParams.PAGE_NUMBER]: `Represent the numeric page number`,
            [ProjectIntegrationPaginatedActionConstantParams.IS_INITIAL_COLLECT]: `Is it the initial collect`,
            [ProjectIntegrationPaginatedActionConstantParams.LAST_COLLECT]: `The Last collect time`,
            [ProjectIntegrationActionConstantParams.CHILD_ENTITIES]: `child entities`,
            [ProjectIntegrationActionConstantParams.CUSTOM_FIELDS]: `custom fields`,
            [ProjectIntegrationActionConstantParams.INPUT_OBJECT]: `input object`,
        };
    }, [
        projectIntegration.displayName,
        projectIntegrationBaseUrl?.apiBaseUrl,
        projectIntegrationBaseUrl?.incomingWebhookUrl,
    ]);

    const updateParameter = useCallback(
        (updatedParameter: BaseActionParameter, isChildParameter: boolean = false) => {
            const parameterList = isChildParameter
                ? parametersDefinition.childEntitiesParameterDefinitions?.[0]?.parameters
                : parametersDefinition.parameters;

            const isValid = isParameterNameValid(updatedParameter.displayName, parameterList, updatedParameter.id);

            if (!isValid) {
                emitToastMessage(`Parameter with the name "${updatedParameter.displayName}" already exists`);
                return;
            }

            onChange((currentParameterDefinition: ProjectIntegrationActionParametersDefinition) => {
                const updatedChildEntities = currentParameterDefinition.childEntitiesParameterDefinitions?.map(
                    (childDefinition) =>
                        childDefinition.parameters
                            ? {
                                  ...childDefinition,
                                  parameters: childDefinition.parameters.map((parameter) =>
                                      parameter.id === updatedParameter.id ? updatedParameter : parameter,
                                  ),
                              }
                            : childDefinition,
                );

                return {
                    ...(isChildParameter
                        ? {
                              ...currentParameterDefinition,
                              childEntitiesParameterDefinitions: updatedChildEntities || [],
                          }
                        : {
                              ...currentParameterDefinition,
                              parameters: currentParameterDefinition.parameters.map((parameter) =>
                                  parameter.id === updatedParameter.id ? updatedParameter : parameter,
                              ),
                          }),
                };
            });
        },
        [
            emitToastMessage,
            isParameterNameValid,
            onChange,
            parametersDefinition.childEntitiesParameterDefinitions,
            parametersDefinition.parameters,
        ],
    );

    const onFieldListChange = useCallback(
        (value: BaseActionParameter, isNew: boolean) => {
            if (isNew) {
                addNewParameter(value);
            } else {
                updateParameter(value);
            }
        },
        [addNewParameter, updateParameter],
    );

    const predefinedParameters = useMemo(() => {
        return (
            parametersDefinition?.parameters.filter(
                (parameter) => parameter.parameterType !== ActionParameterType.FIELD_LIST,
            ) || []
        );
    }, [parametersDefinition?.parameters]);

    return (
        <>
            <form onSubmit={onPressEnterAddNewProperty}>
                <ParamHeaderAndRunTestButtonWrapper>
                    {shouldShowHeaderButtons && (
                        <HeaderButtons>
                            <ProjectIntegrationActionRequestButtons
                                projectIntegration={projectIntegration}
                                projectIntegrationAction={projectIntegrationAction}
                                constantParameters={constantParameters}
                            />
                        </HeaderButtons>
                    )}

                    <ActionParametersHeader> Predefined action parameters</ActionParametersHeader>

                    <ActionParametersDescription>
                        Define Interface to invoke the action from the module.
                    </ActionParametersDescription>
                </ParamHeaderAndRunTestButtonWrapper>

                {parametersDefinition.entitiesToRunOn?.length > 0 && (
                    <RequiredEntityWrapper>
                        <RequiredEntityText>Require Entity:</RequiredEntityText>
                        <div className="flex">
                            <ProjectIntegrationIcon
                                iconUrl={projectIntegration?.iconUrl}
                                integrationType={projectIntegration?.integration.integrationType.toLowerCase()}
                                width={14}
                                projectIntegrationId={projectIntegration.id}
                            />
                            <IntegrationEntityName>{entity?.pluralLabel}</IntegrationEntityName>
                        </div>
                    </RequiredEntityWrapper>
                )}

                <ListOfProps>
                    {constantParameters.map((constantActionParam: BaseActionParameter) => {
                        return (
                            <Tooltip
                                content={constantParamToTooltipDictionary[constantActionParam.id]}
                                key={constantActionParam.id}
                            >
                                <PropRow isConstantParam>
                                    <ParamName className="common-break-long" colored>
                                        {constantActionParam.displayName}
                                    </ParamName>

                                    <ParamDescription>
                                        {
                                            projectIntegrationActionConstantParamsIdToConfig[constantActionParam.id]
                                                ?.description
                                        }
                                    </ParamDescription>
                                </PropRow>
                            </Tooltip>
                        );
                    })}

                    {predefinedParameters.map((actionParam: BaseActionParameter) => {
                        return (
                            <ProjectIntegrationActionPredefinedParameter
                                key={actionParam.id}
                                parameter={actionParam}
                                onRemove={removeParamFromList}
                                onUpdate={updateParameter}
                                isModuleParams={isModuleParams}
                            />
                        );
                    })}
                </ListOfProps>

                <ProjectIntegrationActionInputPredefinedParameters
                    isNameValid={(paramName: string, id?: string) =>
                        isParameterNameValid(paramName, parametersDefinition.parameters, id)
                    }
                    newParamName={newParamName}
                    setNewParamName={setNewParamName}
                    setActiveInput={() => setActiveInput('param')}
                />

                <ProjectIntegrationActionCustomFieldsToggleWrapper>
                    <ProjectIntegrationActionCustomFieldsToggleTitleContainer>
                        <H4 $bold>Enable Custom Fields</H4>
                        <Toggle
                            checked={parametersDefinition.isCustomFieldsEnabled}
                            dataAutomation="project-integration-action-custom-fields-toggle"
                            onChange={(e) => {
                                onChange(
                                    (currentParameterDefinition: ProjectIntegrationActionParametersDefinition) => ({
                                        ...currentParameterDefinition,
                                        isCustomFieldsEnabled: !!e.target.checked,
                                    }),
                                );
                            }}
                            size={InputSize.SMALL}
                        />
                    </ProjectIntegrationActionCustomFieldsToggleTitleContainer>
                    <Paragraph $color={Theme.colors.gray_600}>
                        Enable additional parameters from the entity's custom fields
                    </Paragraph>
                </ProjectIntegrationActionCustomFieldsToggleWrapper>

                <ProjectIntegrationActionChildEntitiesPredefinedParameterWrapper>
                    <ProjectIntegrationActionChildEntitiesPredefinedParameter
                        projectIntegration={projectIntegration}
                        parametersDefinition={parametersDefinition}
                        isNameValid={(paramName: string, id?: string) =>
                            isParameterNameValid(
                                paramName,
                                parametersDefinition.childEntitiesParameterDefinitions?.[0]?.parameters,
                                id,
                            )
                        }
                        setNewParamName={setChildNewParamName}
                        newParamName={newChildParamName}
                        removeChildParamFromList={(param) => removeParamFromList(param, true)}
                        updateParameter={(parameter) => updateParameter(parameter, true)}
                        isModuleParams={isModuleParams}
                        onChange={onChange}
                        setActiveInput={() => setActiveInput('childParam')}
                    />
                </ProjectIntegrationActionChildEntitiesPredefinedParameterWrapper>

                {shouldShowFieldList &&
                    !!parametersDefinition.entitiesToRunOn.length &&
                    isFieldListEnabled &&
                    projectIntegrationAction.actionDefinition.definitionType === ActionDefinitionType.HTTP && (
                        <>
                            <FieldListParametersHeader>Field JSON Format Override</FieldListParametersHeader>

                            <ActionParametersDescription>
                                Fields will automatically compiled to a basic JSON format of “name”: value If certain
                                fields require special treatment, you can add an override rule.
                            </ActionParametersDescription>

                            <Separator $margin="16px" />

                            <FieldListParams>
                                {fieldListParameters.map((fieldListParam: FieldListInputParameter) => {
                                    return (
                                        <StyledClickableLink
                                            key={fieldListParam.id}
                                            onClick={() => {
                                                setFieldListModalOpen(true);
                                                setSelectedFieldList(fieldListParam);
                                            }}
                                            black
                                        >
                                            {fieldListParam.displayName}
                                        </StyledClickableLink>
                                    );
                                })}
                            </FieldListParams>

                            <AddParamButton
                                onClick={() => setFieldListModalOpen(true)}
                                className="inline-button mod-outline"
                                isNotValid={
                                    !!projectIntegrationAction.actionDefinition.enableKeyValueParameters ||
                                    projectIntegrationAction.actionDefinition.methodType === HttpMethodType.GET
                                }
                                disabled={
                                    !!projectIntegrationAction.actionDefinition.enableKeyValueParameters ||
                                    projectIntegrationAction.actionDefinition.methodType === HttpMethodType.GET
                                }
                            >
                                + Add Field List
                            </AddParamButton>
                        </>
                    )}
            </form>
            <ProjectIntegrationActionFieldListModal
                open={fieldListModalOpen}
                existingFieldListParam={selectedFieldList}
                onClose={() => {
                    setFieldListModalOpen(false);
                    setSelectedFieldList(undefined);
                }}
                onSubmit={onFieldListChange}
                onDelete={removeParamFromList}
                parameters={parametersDefinition?.parameters || []}
            />
        </>
    );
};

export default ProjectIntegrationActionParametersView;
