<div class="workflow-version-selector">
    <ui-select
        class="ui-select-min-width"
        theme="bootstrap"
        ng-model="data.selectedWorkflowVersion"
        ng-change="onWorkflowVersionSelection(data.selectedWorkflowVersion)"
    >
        <ui-select-match placeholder="Select">{{ data.selectedWorkflowVersion.workflowVersionType }}</ui-select-match>

        <ui-select-choices repeat="workflowVersion in data.availableWorkflowVersions | filter:$select.search">
            <div ng-bind-html="workflowVersion.workflowVersionType | escapeHtml | highlight: $select.search"></div>
        </ui-select-choices>
    </ui-select>
</div>
