import { useAngularService } from 'angulareact';
import { type Dispatch, type SetStateAction, useEffect } from 'react';

import type { SingleFieldConfiguration } from '../../CommonWidgetConfiguration';
import type { InnerItemWidgetConfiguration } from '../../entities';

import {
    type FieldDefinition,
    type FieldDefinitionKey,
    tonkeanTypeToIdentifyingPrefixMap,
} from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType, WidgetBase, WorkflowVersionType } from '@tonkean/tonkean-entities';

const useGetFieldDefinitions = (
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
    workflowVersionType: WorkflowVersionType | undefined,
    typedObjectKeys: (fields: Record<FieldDefinitionKey, SingleFieldConfiguration>) => FieldDefinitionKey[],
    widget: WidgetBase<InnerItemWidgetConfiguration>,
    setFieldDefinitions?: Dispatch<SetStateAction<FieldDefinition[]>>,
): void => {
    const customFieldsManager = useAngularService('customFieldsManager');

    return useEffect(() => {
        const getFieldDefinitions = async () => {
            // Fetching all the fields to put them in the cache it's solving a problem when this widget opens after a form, and some of the fields may not be in the cache
            if (workflowVersionId && workflowVersionType) {
                const configuredFieldDefinitionIds = [
                    ...new Set([
                        ...typedObjectKeys(widget.configuration.cardFields),
                        ...typedObjectKeys(widget.configuration.fields),
                    ]).values(),
                ].filter((id): id is FieldDefinition['id'] =>
                    id.startsWith(tonkeanTypeToIdentifyingPrefixMap['FIELD_DEFINITION']),
                );

                const fieldDefinitionsResponse = await customFieldsManager.getFieldDefinitionsByIds(
                    workflowVersionId,
                    workflowVersionType,
                    configuredFieldDefinitionIds,
                    widget.projectId,
                );

                setFieldDefinitions?.(fieldDefinitionsResponse);
            }
        };

        getFieldDefinitions();
    }, [
        widget.projectId,
        customFieldsManager,
        widget.configuration.cardFields,
        widget.configuration.fields,
        workflowVersionId,
        workflowVersionType,
        typedObjectKeys,
        setFieldDefinitions,
    ]);
};

export default useGetFieldDefinitions;
