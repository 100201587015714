import { BaseWidgetHandler } from './BaseWidgetHandler';
import { getItemsFieldsSummaryFromConfiguration } from '../utils/ItemInterfaceWidgetFieldsUtils';
import type { InnerItemWidgetConfiguration } from '../widgets/entities';

import type { FieldDefinitionSummary, WidgetBase } from '@tonkean/tonkean-entities';

class InnerItemsWidgetHandler extends BaseWidgetHandler {
    override containsInnerItems = true;

    override getFieldsSummaryFromConfiguration(widget: WidgetBase): FieldDefinitionSummary[] {
        const configuration = widget.configuration as InnerItemWidgetConfiguration;
        return getItemsFieldsSummaryFromConfiguration(configuration);
    }
}

export default InnerItemsWidgetHandler;
