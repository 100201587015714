import type LogicBlockConfigsFormInfoRetriever from '../../LogicBlockConfigsFormInfoRetriever';

import type { LogicImpact } from '@tonkean/tonkean-entities';

const formAnsweredCanCreateInSameModule = (
    configuredLogic: LogicImpact,
    logicBlockConfigsFormInfoRetriever: LogicBlockConfigsFormInfoRetriever,
) => {
    const definition = configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition;
    let isSameFormModuleConfigured = true;
    if (definition?.formId) {
        const form = logicBlockConfigsFormInfoRetriever.getWorkerFormFromCache(
            configuredLogic.node.workflowVersion.id,
            definition.formId,
        );
        // If form found, its the same module
        isSameFormModuleConfigured = !!form;
    }
    return isSameFormModuleConfigured;
};
export default formAnsweredCanCreateInSameModule;
