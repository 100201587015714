import { useCallback, useMemo } from 'react';

import useProject from './useProject';

import type { State, WorkflowVersion } from '@tonkean/tonkean-entities';
import { getProjectStates } from '@tonkean/tonkean-utils';
import { EMPTY_ARRAY } from '@tonkean/utils';

const useStatesForWorkflowVersion = (workflowVersion: WorkflowVersion | undefined) => {
    const project = useProject();

    const states = useMemo<State[]>(() => {
        return getProjectStates(workflowVersion, project).filter((state) => state.label?.length) || EMPTY_ARRAY;
    }, [workflowVersion, project]);

    const stateById = useMemo(() => {
        return states.reduce(
            (acc, state) => {
                acc[state.id] = state;
                return acc;
            },
            {} as Record<string, State>,
        );
    }, [states]);

    const getStateByLabel = useCallback(
        (label: string) => {
            return states.find((state) => state.label === label);
        },
        [states],
    );

    return {
        states: workflowVersion ? states : EMPTY_ARRAY,
        stateById,
        getStateByLabel,
    };
};

export default useStatesForWorkflowVersion;
