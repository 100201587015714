import React from 'react';
import styled from 'styled-components';

import FailureCriteria from './HandleResponseCriteriasViews/FailureCriteria';
import RetryCriteria from './HandleResponseCriteriasViews/RetryCriteria';
import SuccessCriteria from './HandleResponseCriteriasViews/SuccessCriteria';
import { ReactComponent as ErrorIcon } from '../../../../../../../../images/icons/error-or-fail.svg';
import { ReactComponent as InfoIcon } from '../../../../../../../../images/icons/info10px.svg';
import { ReactComponent as SuccessIcon } from '../../../../../../../../images/icons/success.svg';

import { Tooltip } from '@tonkean/infrastructure';
import type {
    IntegrationSupportedEntity,
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionModuleResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import { ActionDefinitionType, EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    width: 100%;
`;

const Header = styled.div`
    color: ${Theme.colors.gray_800};
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    margin-bottom: 25px;
`;

const SubHeader = styled.div`
    color: ${Theme.colors.gray_800};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    margin-bottom: 17px;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
    margin-right: 6px;
    margin-top: auto;
`;

const StyledInfoIcon = styled(InfoIcon)`
    margin-left: 6px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
    margin-right: 6px;
`;

const RetryCriteriaWrapper = styled.div`
    margin-bottom: 16px;
`;

const SuccessCriteriaWrapper = styled.div`
    margin-bottom: 30px;
`;

interface Props {
    projectIntegration: ProjectIntegration;
    projectIntegrationAction: ProjectIntegrationAction;
    setProjectIntegrationAction: React.Dispatch<React.SetStateAction<ProjectIntegrationAction>>;
    viewingTestResponseId?: string;
    entity: IntegrationSupportedEntity | undefined;
}

const HandleResponseHandleActionResponse: React.FC<Props> = ({
    projectIntegration,
    projectIntegrationAction,
    setProjectIntegrationAction,
    viewingTestResponseId,
    entity,
}) => {
    return (
        <Wrapper>
            <Header>Run Action</Header>

            <SubHeader>
                <StyledSuccessIcon />
                IF request succeeds
                <Tooltip content="When the response succeed with success status code">
                    <StyledInfoIcon />
                </Tooltip>
            </SubHeader>

            <SuccessCriteriaWrapper>
                <SuccessCriteria
                    key={projectIntegrationAction.id}
                    projectIntegration={projectIntegration}
                    projectIntegrationAction={projectIntegrationAction}
                    setProjectIntegrationAction={setProjectIntegrationAction}
                    viewingTestResponseId={viewingTestResponseId}
                    entity={entity}
                />
            </SuccessCriteriaWrapper>

            <SubHeader>
                <StyledErrorIcon />
                IF request fails
                <Tooltip content="When the response have 4xx or 5xx status codes ">
                    <StyledInfoIcon />
                </Tooltip>
            </SubHeader>

            {projectIntegrationAction.actionDefinition.definitionType === ActionDefinitionType.HTTP && (
                <RetryCriteriaWrapper>
                    <RetryCriteria
                        key={projectIntegrationAction.id}
                        actionDefinition={projectIntegrationAction.actionDefinition}
                        setProjectIntegrationAction={setProjectIntegrationAction}
                    />
                </RetryCriteriaWrapper>
            )}

            {projectIntegrationAction?.responseHandlingDefinition?.responseHandlingType ===
                EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS && (
                <FailureCriteria
                    key={projectIntegrationAction.id}
                    projectIntegrationAction={
                        projectIntegrationAction as ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>
                    }
                    setProjectIntegrationAction={setProjectIntegrationAction}
                    projectIntegration={projectIntegration}
                />
            )}
        </Wrapper>
    );
};

export default HandleResponseHandleActionResponse;
