import type { FormEvent } from 'react';
import React, { useEffect, useMemo, useState } from 'react';

import SQLDatabaseType from './entities/SQLDatabaseType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { Checkbox } from '@tonkean/infrastructure';
import { SimpleSelect } from '@tonkean/infrastructure';

const SQLDatabaseAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const databaseTypes = useMemo(
        () =>
            [
                {
                    label: 'MySQL',
                    value: SQLDatabaseType.MYSQL,
                },
                {
                    label: 'Microsoft SQL Server',
                    value: SQLDatabaseType.MSSQL,
                },
                {
                    label: 'Postgres',
                    value: SQLDatabaseType.POSTGRES,
                },
                {
                    label: 'Amazon Redshift',
                    value: SQLDatabaseType.AMAZON_REDSHIFT,
                },
                {
                    label: 'Amazon Athena',
                    value: SQLDatabaseType.AMAZON_ATHENA,
                },
                {
                    label: 'Google BigQuery',
                    value: SQLDatabaseType.GOOGLE_BIGQUERY,
                },
                {
                    label: 'Oracle DB',
                    value: SQLDatabaseType.ORACLE_DB,
                },
            ] as const,
        [],
    );

    const [url, setUrl] = useState<string>('');
    const [userName, setUserName] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();
    const [schema, setSchema] = useState<string>('');
    const [port, setPort] = useState<string>('');
    const [useSSL, setUseSSL] = useState<boolean>(false);
    const [databaseType, setDatabaseType] = useState<SQLDatabaseType | undefined>(databaseTypes[0].value);
    const [credentialsJson, setCredentialsJson] = useState<string>('');

    const GOOGLE_BIGQUERY_URL = 'https://www.googleapis.com/bigquery/v2';
    const GOOGLE_BIGQUERY_PORT = '443';

    useEffect(() => {
        const init = () => {
            if (databaseType === SQLDatabaseType.GOOGLE_BIGQUERY) {
                if (url === '') {
                    setUrl(GOOGLE_BIGQUERY_URL);
                }
                if (port === '') {
                    setPort(GOOGLE_BIGQUERY_PORT);
                }
            } else {
                if (url === GOOGLE_BIGQUERY_URL) {
                    setUrl('');
                }
            }
        };

        init();
    }, [databaseType, port, url]);

    const authenticateSqlDatabaseIntegration = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();

            const credentials = {
                databaseType,
                url,
                userName,
                password,
                schema,
                port,
                useSSL,
            };

            let serviceAccount = {};

            if (databaseType === SQLDatabaseType.GOOGLE_BIGQUERY) {
                const parsedJson = JSON.parse(credentialsJson);
                if (typeof parsedJson !== 'object') {
                    reportError('JSON is not valid.');
                } else {
                    serviceAccount = {
                        credentialsJson: parsedJson,
                    };
                }
            }

            const config = {
                ...credentials,
                ...serviceAccount,
            };

            authenticate(config, {}, `${integrationConfiguration.displayName} (${databaseType} ${schema})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <form className="form-horizontal" onSubmit={authenticateSqlDatabaseIntegration}>
            {/* Database Type */}
            <div className="form-group">
                {/* Title */}
                <label htmlFor="url-region" className="col-sm-3 control-label">
                    Database Type
                </label>

                {/* Input */}
                <div className="col-sm-8">
                    <SimpleSelect
                        isClearable={false}
                        value={databaseType}
                        onChange={(selectedValue) => setDatabaseType(selectedValue)}
                        options={databaseTypes}
                        thin
                    />
                </div>
            </div>

            {/* URL Input */}
            <div className="form-group">
                {/* Title */}
                <label htmlFor="url-region" className="col-sm-3 control-label">
                    URL
                </label>

                {/* Input */}
                <div className="col-sm-8">
                    <input
                        type="text"
                        value={url}
                        className="form-control"
                        data-automation="sql-database-authentication-component-url"
                        id="url-region"
                        onChange={({ target }) => setUrl(target.value)}
                        autoComplete="off"
                        placeholder="Your server's address"
                        required
                    />
                    {databaseType !== SQLDatabaseType.GOOGLE_BIGQUERY && (
                        <i className="common-subtitle-inner">
                            i.e. <span className="common-underline">your-mysql-server</span>.domain.net
                        </i>
                    )}
                </div>
            </div>

            {databaseType !== SQLDatabaseType.GOOGLE_BIGQUERY && (
                <div>
                    {/* Username */}
                    <div className="form-group">
                        {/* Title */}
                        <label htmlFor="url-region" className="col-sm-3 control-label">
                            Username
                        </label>

                        {/* Input */}
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={userName}
                                className="form-control"
                                data-automation="sql-database-authentication-component-username"
                                id="url-region"
                                onChange={({ target }) => setUserName(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                            <i className="common-subtitle-inner">
                                Must be a user <span className="common-underline">with read access</span> to the server
                            </i>
                        </div>
                    </div>

                    {/* Password */}
                    <div className="form-group">
                        {/* Title */}
                        <label htmlFor="url-region" className="col-sm-3 control-label">
                            Password
                        </label>

                        {/* Input */}
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                data-automation="sql-database-authentication-component-password"
                                id="url-region"
                                onChange={({ target }) => setPassword(target.value)}
                                autoComplete="off"
                                required
                            />
                        </div>
                    </div>

                    {/* Schema/S3 bucket */}
                    <div className="form-group">
                        {/* Title */}
                        <label htmlFor="url-region" className="col-sm-3 control-label">
                            {databaseType === SQLDatabaseType.AMAZON_ATHENA ? 'Output S3 Bucket' : 'Schema'}
                        </label>

                        {/* Input */}
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={schema}
                                className="form-control"
                                data-automation="sql-database-authentication-component-schema"
                                id="url-region"
                                onChange={({ target }) => setSchema(target.value)}
                                autoComplete="off"
                                placeholder={
                                    databaseType === SQLDatabaseType.AMAZON_ATHENA ? 'Output S3 Bucket' : 'Schema'
                                }
                                required
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Port */}
            <div className="form-group">
                {/* Title */}
                <label htmlFor="url-region" className="col-sm-3 control-label">
                    Port
                </label>

                {/* Input */}
                <div className="col-sm-8">
                    <input
                        type="text"
                        value={port}
                        className="form-control"
                        data-automation="sql-database-authentication-component-port"
                        id="url-region"
                        onChange={({ target }) => setPort(target.value)}
                        autoComplete="off"
                        placeholder="Port"
                        required
                    />
                </div>
            </div>

            {/* Use SSL/UseResultsetStreaming */}
            {databaseType !== SQLDatabaseType.GOOGLE_BIGQUERY && (
                <div className="form-group">
                    {/* Empty Title */}
                    <div className="col-sm-3 control-label" />

                    {/* Checkbox */}
                    <div className="col-sm-8">
                        <Checkbox checked={useSSL} onChange={() => setUseSSL(!useSSL)}>
                            {databaseType === SQLDatabaseType.AMAZON_ATHENA ? 'Use Results Streaming' : 'Use SSL'}
                        </Checkbox>
                    </div>
                </div>
            )}

            {databaseType === SQLDatabaseType.GOOGLE_BIGQUERY && (
                <div>
                    <div className="form-group">
                        <label className="col-sm-3 control-label">Service Account Credentials</label>
                        <div className="col-sm-8">
                            <textarea
                                value={credentialsJson}
                                onChange={({ target }) => setCredentialsJson(target.value)}
                                className="form-control common-resize-y-only"
                                autoComplete="off"
                                placeholder="JSON Format"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-sm-3" />
                        <div className="col-sm-8 common-size-xxxs common-color-grey">
                            <ul className="common-ul-small-bullets padding-left-md">
                                <li>
                                    Login to your&nbsp;
                                    <a href="https://console.cloud.google.com/" target="_blank">
                                        Google Cloud Console
                                    </a>
                                    . Open the Menu on the side and Go to IAM -{'>'} Service Accounts.
                                </li>
                                <li>
                                    In the Service Accounts page, Click on the Create Service Account button on the top.
                                    You should now see a form to create a service account. Fill in any Service Account
                                    Name, Service Account ID, Service Account Description,
                                </li>
                                <li>
                                    Click on Create. Now you should see an option to assign Service Account permissions.
                                    Under that you should find a drop down. Choose BigQuery -{'>'} BigQuery Admin.
                                </li>
                                <li>
                                    Click on Continue. On the next screen, you should now see an option to Create Key.
                                    Click on Create Key. Choose json file format and click Create.
                                </li>
                                <li>
                                    In order to grant access to a dataset, Go to the&nbsp;
                                    <a href="https://console.cloud.google.com/bigquery" target="_blank">
                                        BigQuery page
                                    </a>
                                    . In the Explorer pane, expand your project and select a dataset to share. Enter a
                                    principal and select a role.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}

            {/* Connect */}
            <div className="flex mod-center" data-automation="sql-database-authentication-component-connect-button">
                <button type="submit" className="btn btn-primary">
                    Connect
                </button>
            </div>
        </form>
    );
};

export default SQLDatabaseAuthenticationComponent;
