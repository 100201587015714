import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Input } from '@tonkean/infrastructure';
import { LoadingCircle } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

const StyledClickable = styled(Clickable)`
    width: 100%;
    display: block;
`;

const StyledClickableLink = styled(ClickableLink)`
    margin-bottom: 8px;
    width: 100%;
`;

const SearchAndBackWrapper = styled.div`
    display: flex;
    margin-bottom: 5px;
    align-items: center;
`;

interface Props {
    onClickReset: () => void;
    onClickBack: () => void;
    onClickFolder: (id: string, name: string) => void;
    pathToFolder: { id: string; name: string }[];
    parentFolderLastName: string;
    options: { displayName: string; description: string }[];
    isLoading: boolean;
    canChooseFolder: boolean;
    onSaveProjectIntegration: () => void;
    hasError: any;
    defaultErrorMessage?: string;
}

const IntegrationSetupFileTreePicker: React.FC<Props> = ({
    onClickReset,
    pathToFolder,
    onClickBack,
    onClickFolder,
    parentFolderLastName,
    options,
    canChooseFolder,
    onSaveProjectIntegration,
    isLoading,
    hasError,
    defaultErrorMessage,
}) => {
    // Indicated the filter for the returned folders.
    const [foldersFilterText, setFoldersFilterText] = useState<string>('');

    const filteredOptions = useMemo(() => {
        return options.filter(
            (option) =>
                option.displayName.toLowerCase().includes(foldersFilterText.toLowerCase()) ||
                option.description.toLowerCase().includes(foldersFilterText.toLowerCase()),
        );
    }, [options, foldersFilterText]);

    return (
        <>
            {onClickReset && <StyledClickableLink onClick={onClickReset}>Reset Folder</StyledClickableLink>}

            <SearchAndBackWrapper>
                <StyledClickableLink disabled={pathToFolder.length === 0} onClick={onClickBack}>
                    Back
                </StyledClickableLink>

                <Input
                    placeholder="Search For Folder"
                    value={foldersFilterText}
                    onChange={({ target: { value } }) => setFoldersFilterText(value)}
                />
            </SearchAndBackWrapper>

            <div className="integration-instance-selection-box-header common-bold">{parentFolderLastName}</div>

            <div className="integration-instance-selection-box">
                {filteredOptions.map((item) => (
                    <StyledClickable
                        onClick={() => onClickFolder(item.description, item.displayName)}
                        key={item.displayName}
                        className="list-item btn-default pointer common-size-xxs flex-vmiddle"
                    >
                        <div className="flex-grow">
                            <strong> {item.displayName}</strong>

                            <div className="common-size-xxxxs common-color-grey">
                                <span>{item.description}</span>
                            </div>
                        </div>
                    </StyledClickable>
                ))}

                {(isLoading || hasError) && (
                    <div className="flex-vmiddle mod-justify-center common-height-full">
                        <div className="text-center">
                            {isLoading && <LoadingCircle />}

                            {hasError && <>{defaultErrorMessage}</>}
                        </div>
                    </div>
                )}
            </div>

            <button
                className="btn btn-primary flex-no-shrink common-no-radius margin-top-6"
                disabled={!canChooseFolder || hasError}
                onClick={onSaveProjectIntegration}
            >
                Use This Folder
            </button>
        </>
    );
};

export default IntegrationSetupFileTreePicker;
