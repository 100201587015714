import { useEffect } from 'react';

import type { ItemInterfaceWidget, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useUpdateWidgetsToValidate = (
    setWidgetsIdsToValidate: (keys: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[]) => void,
    widgets: ItemInterfaceWidget[],
) => {
    useEffect(() => {
        // When the widgets live display has changed there could be old validations that their widgets were removed,
        // so we set which widgets validations should be evaluated
        const validWidgetKeys = widgets?.map((widget) => widget.id);
        setWidgetsIdsToValidate(validWidgetKeys || []);
    }, [setWidgetsIdsToValidate, widgets]);
};

export default useUpdateWidgetsToValidate;
