import { FieldArray, Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as MediumSpaceShip } from '../../../../../images/icons/spaceship-medium.svg';
import { ReactComponent as SmallSpaceShip } from '../../../../../images/icons/spaceship-small.svg';
import projectIntegrationActionConstantParamsIdToConfig from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationActionConstantParamIdToConfig';
import projectIntegrationPaginatedActionConstantParamIdToConfig from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationPaginatedActionConstantParamIdToConfig';
import { ProjectIntegrationPaginatedActionConstantParams } from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/projectIntegrationPaginatedActionConstantParams';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import {
    CollapsibleContent,
    DatepickerInput,
    Field,
    H3,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    Radio,
    RadioGroup,
    SimpleSelect,
} from '@tonkean/infrastructure';
import type {
    ActionParameterCloseList,
    BaseActionParameter,
    CustomActionDefinition,
    ProjectIntegrationAction,
    ProjectIntegrationActionResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import { ActionParameterType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledDatepickerInput = styled(DatepickerInput)`
    width: 100%;
`;

const InlineWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const RadioButtonLabelText = styled.div<{ disabled?: boolean }>`
    font-weight: 400;
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.MEDIUM_14};

    color: ${({ disabled = false }) => (disabled ? Theme.colors.gray_500 : Theme.colors.gray_700)};
`;

const StyledField = styled(Field)`
    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

const StyledSimpleSelect = styled(SimpleSelect)`
    margin-left: 8px;
    width: 93px;
`;

const Wrapper = styled.div``;

const SpecificPageText = styled.div`
    color: ${Theme.colors.gray_700};
    font-weight: 400;
    font-style: italic;
    margin-bottom: 16px;
`;

const HeaderText = styled(H3)`
    margin-left: 10px;
    color: ${Theme.colors.gray_700};
`;

enum CollectTestRunType {
    SPECIFIC_PAGE = 'SPECIFIC_PAGE',
    LIMITED_PAGES = 'LIMITED_PAGES',
}

// For Boolean field.
const booleanOptions: SimpleSelectSingleOption<boolean>[] = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

// Limit page select options.
const limitPageOptions: SimpleSelectSingleOption<number>[] = [
    { value: 1, label: '1 Page' },
    { value: 2, label: '2 Pages' },
    { value: 3, label: '3 Pages' },
    { value: 4, label: '4 Pages' },
    { value: 5, label: '5 Pages' },
];

interface Props {
    onClose(): Promise<void> | void;

    projectIntegrationId: string;
    projectIntegrationAction: ProjectIntegrationAction;
    testActionDefinition: CustomActionDefinition;
    testResponseHandlingDefinition: ProjectIntegrationActionResponseHandlingDefinition;
    apiBaseUrl: string;
    incomingWebhookUrl: string;
    additionalActionParameters?: BaseActionParameter[];
    shouldHidePaginatedTest: boolean;
}

const ProjectIntegrationEntityContinuousCollectTestModal: React.FC<Props> = ({
    onClose,
    projectIntegrationId,
    projectIntegrationAction,
    testActionDefinition,
    testResponseHandlingDefinition,
    apiBaseUrl,
    incomingWebhookUrl,
    additionalActionParameters = [],
    shouldHidePaginatedTest,
}) => {
    const currentProjectIntegrationActionConfiguration = useMemo(() => {
        return {
            ...projectIntegrationAction,
            actionDefinition: testActionDefinition,
            responseHandlingDefinition: testResponseHandlingDefinition,
        };
    }, [projectIntegrationAction, testActionDefinition, testResponseHandlingDefinition]);

    // List of all the constant ActionParameter with the paginated parameters.
    const actionParameters: BaseActionParameter[] = useMemo(() => {
        const config = currentProjectIntegrationActionConfiguration.actionDefinition.pagination?.enabled
            ? projectIntegrationPaginatedActionConstantParamIdToConfig
            : projectIntegrationActionConstantParamsIdToConfig;

        const nextPageParameters: BaseActionParameter[] = currentProjectIntegrationActionConfiguration.actionDefinition
            .pagination?.enabled
            ? [
                  ...(currentProjectIntegrationActionConfiguration.actionDefinition.pagination?.nextPageParameters?.map(
                      (param) => ({
                          id: param.parameterName,
                          displayName: param.parameterName,
                          parameterType: ActionParameterType.TONKEAN_EXPRESSION,
                      }),
                  ) || []),
              ]
            : [];

        const constantParams = Object.values(config).map((param) => {
            if (param.parameterType === ActionParameterType.CLOSE_LIST) {
                return {
                    id: param.id,
                    displayName: param.displayName,
                    parameterType: param.parameterType,
                    options: param.defaultOptions,
                };
            } else {
                return {
                    id: param.id,
                    displayName: param.displayName,
                    parameterType: param.parameterType,
                };
            }
        });

        return [
            ...constantParams,
            ...nextPageParameters,
            ...additionalActionParameters,
            ...projectIntegrationAction.parametersDefinition.parameters,
        ];
    }, [
        currentProjectIntegrationActionConfiguration.actionDefinition.pagination?.enabled,
        currentProjectIntegrationActionConfiguration.actionDefinition.pagination?.nextPageParameters,
        additionalActionParameters,
        projectIntegrationAction.parametersDefinition.parameters,
    ]);

    // Run test for collect action for specific page.
    const [
        { loading: loadingProjectIntegrationActionTest, error: errorProjectIntegrationActionTest },
        runProjectIntegrationActionTest,
    ] = useLazyTonkeanService('runProjectIntegrationActionTest');

    // Run test for collect action for range of pages.
    const [
        { loading: loadingProjectIntegrationPaginatedActionTest, error: errorProjectIntegrationPaginatedActionTest },
        runProjectIntegrationPaginatedActionTest,
    ] = useLazyTonkeanService('runProjectIntegrationPaginatedActionTest');

    const isTestRunning = loadingProjectIntegrationPaginatedActionTest || loadingProjectIntegrationActionTest;
    const errorTestRun = errorProjectIntegrationActionTest || errorProjectIntegrationPaginatedActionTest;

    const submitModal = useCallback(
        async (
            testType: CollectTestRunType,
            parameters: Record<string, number | string | boolean | Date>,
            pageLimitCount: number,
        ) => {
            if (testType === CollectTestRunType.SPECIFIC_PAGE) {
                const parameterIdToValueMap: Record<string, string> = Object.fromEntries(
                    Object.entries(parameters).map(([key, value]) => {
                        // When handling dates, we'd like to take the epoch time.
                        const actualValue = value instanceof Date ? value.getTime() : value;
                        return [key, actualValue.toString()];
                    }),
                );

                await runProjectIntegrationActionTest(
                    projectIntegrationId,
                    projectIntegrationAction.id,
                    currentProjectIntegrationActionConfiguration,
                    parameterIdToValueMap,
                );
            } else {
                await runProjectIntegrationPaginatedActionTest(
                    projectIntegrationId,
                    projectIntegrationAction.id,
                    pageLimitCount,
                    currentProjectIntegrationActionConfiguration,
                );
            }
            onClose();
        },
        [
            currentProjectIntegrationActionConfiguration,
            onClose,
            projectIntegrationAction.id,
            projectIntegrationId,
            runProjectIntegrationActionTest,
            runProjectIntegrationPaginatedActionTest,
        ],
    );

    const shouldAllowPaginationTest = !!testActionDefinition.pagination?.enabled;

    return (
        <Formik
            initialValues={{
                collectTestType: CollectTestRunType.SPECIFIC_PAGE,
                pageLimitCount: 3,
                parameters: Object.fromEntries([
                    ...actionParameters.map((param) => [param.id, '']),
                    [ProjectIntegrationPaginatedActionConstantParams.ENV_URL, apiBaseUrl || ''],
                    [ProjectIntegrationPaginatedActionConstantParams.PAGE_NUMBER, 1],
                ]),
            }}
            onSubmit={(values) => {
                return submitModal(values.collectTestType, values.parameters, values.pageLimitCount);
            }}
        >
            {({ values: { collectTestType } }) => (
                <ModalForm>
                    <ModalHeader $flex>
                        <MediumSpaceShip />
                        <HeaderText $bold>Run Test</HeaderText>
                    </ModalHeader>

                    <ModalBody>
                        <StyledField>
                            <RadioGroup size={InputSize.MEDIUM} changeLabelColor={false} name="collectTestType">
                                {!shouldHidePaginatedTest && (
                                    <Radio
                                        disabled={!shouldAllowPaginationTest}
                                        value={CollectTestRunType.LIMITED_PAGES}
                                    >
                                        <InlineWrapper>
                                            <RadioButtonLabelText disabled={!shouldAllowPaginationTest}>
                                                Retrieve first
                                            </RadioButtonLabelText>
                                            <StyledSimpleSelect
                                                options={limitPageOptions}
                                                name="pageLimitCount"
                                                isDisabled={collectTestType !== CollectTestRunType.LIMITED_PAGES}
                                                thin
                                            />
                                        </InlineWrapper>
                                    </Radio>
                                )}
                                <Radio value={CollectTestRunType.SPECIFIC_PAGE}>
                                    <RadioButtonLabelText>Retrieve specific page</RadioButtonLabelText>
                                </Radio>
                            </RadioGroup>
                        </StyledField>

                        <CollapsibleContent open={collectTestType === CollectTestRunType.SPECIFIC_PAGE}>
                            <Wrapper>
                                <SpecificPageText>Please insert the collect parameters</SpecificPageText>

                                <FieldArray name="parameterToValue">
                                    {() => {
                                        return actionParameters.map((parameter) => {
                                            const isInputDisabled =
                                                projectIntegrationPaginatedActionConstantParamIdToConfig[parameter.id]
                                                    ?.editableInTest === false || isTestRunning;

                                            return (
                                                <StyledField label={parameter.displayName} key={parameter.id} tag light>
                                                    {parameter.parameterType === ActionParameterType.DATE && (
                                                        <StyledDatepickerInput
                                                            disabled={isInputDisabled}
                                                            name={`parameters.${parameter.id}`}
                                                        />
                                                    )}

                                                    {(parameter.parameterType ===
                                                        ActionParameterType.TONKEAN_EXPRESSION ||
                                                        parameter.parameterType ===
                                                            ActionParameterType.NUMBER_TONKEAN_EXPRESSION) && (
                                                        <Input
                                                            type={
                                                                parameter.parameterType ===
                                                                ActionParameterType.TONKEAN_EXPRESSION
                                                                    ? 'text'
                                                                    : 'number'
                                                            }
                                                            disabled={isInputDisabled}
                                                            name={`parameters.${parameter.id}`}
                                                        />
                                                    )}

                                                    {parameter.parameterType === ActionParameterType.CLOSE_LIST && (
                                                        <SimpleSelect
                                                            isDisabled={isInputDisabled}
                                                            options={
                                                                (parameter as ActionParameterCloseList).options ||
                                                                EMPTY_ARRAY
                                                            }
                                                            name={`parameters.${parameter.id}`}
                                                        />
                                                    )}

                                                    {parameter.parameterType === ActionParameterType.BOOLEAN && (
                                                        <SimpleSelect
                                                            isDisabled={isInputDisabled}
                                                            options={booleanOptions}
                                                            name={`parameters.${parameter.id}`}
                                                        />
                                                    )}
                                                </StyledField>
                                            );
                                        });
                                    }}
                                </FieldArray>
                            </Wrapper>
                        </CollapsibleContent>
                    </ModalBody>

                    <ModalFooterActions
                        error={errorTestRun}
                        loading={isTestRunning}
                        saveButtonProps={{ flex: true, highlighted: true }}
                        saveLabel={
                            <>
                                <SmallSpaceShip /> Test
                            </>
                        }
                    />
                </ModalForm>
            )}
        </Formik>
    );
};

export default ProjectIntegrationEntityContinuousCollectTestModal;
