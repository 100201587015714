import template from './tnkSetupColumns.template.html?angularjs';

function tnkSetupColumns() {
    return {
        restrict: 'E',
        scope: {
            columnsConfig: '=',
            integrationName: '=',
            integrationId: '=',
            entityType: '=',
        },
        template,
        controller: [
            '$scope',
            '$filter',
            'projectManager',
            'tonkeanService',
            function ($scope, $filter, projectManager, tonkeanService) {
                $scope.pm = projectManager;

                $scope.data = {
                    selectedFields: [],
                    indexMap: {},
                    greedyMatches: {},
                };

                $scope.init = function () {
                    const defaultFields = [];
                    for (let i = 0; i < $scope.columnsConfig.length; i++) {
                        const column = $scope.columnsConfig[i];
                        if (
                            column.fieldDefinition &&
                            column.fieldDefinition.type === 'EXTERNAL' &&
                            column.fieldDefinition.definition
                        ) {
                            const fieldName = column.fieldDefinition.definition['FieldName'];
                            if (fieldName) {
                                defaultFields.push(fieldName);
                                $scope.data.indexMap[fieldName] = i;
                                if (column.greedyMatch) {
                                    $scope.data.greedyMatches[fieldName] = true;
                                }
                            }
                        }
                    }

                    loadAvailableFields(defaultFields);
                };

                /**
                 * This watch is important. Once the entity type is changed, we need to reload
                 * the columns displayed to the user.
                 */
                $scope.$watchCollection('entityType', function () {
                    $scope.init();
                });

                $scope.fieldAdded = function (selectedField) {
                    $scope.columnsConfig.push(getDefinitionByField(selectedField));
                    $scope.data.indexMap[selectedField.name] = $scope.columnsConfig.length - 1;
                };

                $scope.fieldRemoved = function (selectedField) {
                    // console.log($tag);
                    removeFieldFromConfig(selectedField.name);
                };

                $scope.filterExternalFields = function (query) {
                    if ($scope.data.selectedFields.length >= 6) {
                        return [];
                    }
                    const result = $filter('filter')($scope.data.availableFields, { label: query });
                    return $filter('orderBy')(result, 'label');
                };

                $scope.init();

                function getDefinitionByField(selectedField) {
                    return {
                        fieldDefinition: {
                            key: selectedField.key,
                            name: selectedField.label,
                            projectIntegrationId: $scope.integrationId,
                            definition: {
                                ExternalType: $scope.entityType,
                                FieldName: selectedField.name,
                                FieldLabel: selectedField.label,
                                integrationType: $scope.integrationName.toUpperCase(),
                            },
                            updateable: selectedField.updateable,
                            possibleValues: selectedField.values,
                            targetType: 'COLUMN',
                            type: 'EXTERNAL',
                            ranges: selectedField.ranges || [],
                            fieldType: selectedField.type || 'String',
                            displayAs: selectedField.displayAs || null,
                            displayFormat: selectedField.displayFormat || null,
                            displayFormatPrefix: selectedField.displayFormatPrefix || null,
                            displayFormatPostfix: selectedField.displayFormatPostfix || null,
                        },
                    };
                }

                function removeFieldFromConfig(fieldName) {
                    const index = $scope.data.indexMap[fieldName];
                    if (index > -1) {
                        $scope.columnsConfig.splice(index, 1);
                        $scope.data.indexMap[fieldName] = -1;

                        for (const key in $scope.data.indexMap) {
                            if ($scope.data.indexMap.hasOwnProperty(key) && $scope.data.indexMap[key] >= index) {
                                $scope.data.indexMap[key] = $scope.data.indexMap[key] - 1;
                            }
                        }
                    }
                }

                function loadAvailableFields(defaultFields, fieldsBlackList) {
                    $scope.loadingExternal = true;
                    tonkeanService
                        .getAvailableExternalFields($scope.integrationId, [$scope.entityType])
                        .then(function (result) {
                            const availableFields = [];
                            for (let k = 0; k < result.entitiesWithLabels.length; k++) {
                                const field = result.entitiesWithLabels[k];
                                let blacked = false;
                                if (fieldsBlackList) {
                                    for (const black of fieldsBlackList) {
                                        if (field.name.toLowerCase().includes(black)) {
                                            blacked = true;
                                            break;
                                        }
                                    }
                                }
                                if (!blacked) {
                                    availableFields.push(field);
                                }
                            }

                            $scope.data.availableFields = availableFields;
                            const selectedFields = [];

                            for (const currentDefaultField of defaultFields) {
                                let foundMatch = false;

                                for (let j = 0; j < $scope.data.availableFields.length; j++) {
                                    let currentAvailableField = $scope.data.availableFields[j];

                                    if (
                                        currentAvailableField.name === currentDefaultField ||
                                        currentAvailableField.relatedSpecialFieldName === currentDefaultField ||
                                        ($scope.data.greedyMatches[currentDefaultField] &&
                                            currentAvailableField.name
                                                .toLowerCase()
                                                .includes(currentDefaultField.toLowerCase()))
                                    ) {
                                        // The field taken from the config (holds all the properties defined in config).
                                        const configField =
                                            $scope.columnsConfig[$scope.data.indexMap[currentDefaultField]];

                                        // Pass the KEY from the config defined field to the matched server field.
                                        currentAvailableField.key = configField.fieldDefinition.key;

                                        currentAvailableField = fillServerFieldWithConfigData(
                                            currentAvailableField,
                                            configField.fieldDefinition,
                                        );

                                        // Replacing the placeholder in config with the real field definition.
                                        $scope.columnsConfig[$scope.data.indexMap[currentDefaultField]] =
                                            getDefinitionByField(currentAvailableField);

                                        selectedFields.push(currentAvailableField);
                                        foundMatch = true;

                                        break;
                                    }
                                }

                                // if no match found, remove the config
                                if (!foundMatch) {
                                    removeFieldFromConfig(currentDefaultField);
                                }
                            }
                            $scope.data.selectedFields = selectedFields;

                            $scope.loadingExternal = false;
                        });
                }

                /**
                 * Enriches the given server field (a field from the server that was found as a match to a TNK_* field from config),
                 * with data about the field from the configuration.
                 * @param serverField - the field matched from the server.
                 * @param configFieldDefinition - the field defined in the configuration.
                 * @returns {*} - an enriched server field.
                 */
                function fillServerFieldWithConfigData(serverField, configFieldDefinition) {
                    // Only do something if the configuration field definition is not null.
                    if (configFieldDefinition) {
                        serverField.ranges = configFieldDefinition.ranges || [];

                        if (!serverField.fieldType || serverField.fieldType.toLowerCase() === 'string') {
                            serverField.fieldType = configFieldDefinition.fieldType || 'String';
                        }

                        serverField.displayAs = configFieldDefinition.displayAs || null;
                        serverField.displayFormat = configFieldDefinition.displayFormat || null;
                        serverField.displayFormatPrefix = configFieldDefinition.displayFormatPrefix || null;
                        serverField.displayFormatPostfix = configFieldDefinition.displayFormatPostfix || null;
                    }

                    return serverField;
                }
            },
        ],
    };
}

export default angular.module('tonkean.app').directive('tnkSetupColumns', tnkSetupColumns);
