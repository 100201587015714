import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const LogicConfigurationSubTitle = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
    margin-bottom: 9px;
`;

export default LogicConfigurationSubTitle;
