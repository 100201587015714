import { useField } from 'formik';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import ImageInputType, { ImageInputTypes } from '../../entities/ImageInputType';
import {
    SEARCH_WIDGET_DEFAULT_CARDS_DENSITY,
    SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE,
    SEARCH_WIDGET_DEFAULT_SELECTED_RESULT_MODE,
    SearchWidgetCardsDensity,
    SearchWidgetImagePlaceholderMode,
    SearchWidgetSelectedResultMode,
} from '../SearchWidgetConfiguration';
import type SearchWidgetConfiguration from '../SearchWidgetConfiguration';

import { useFeatureFlag, useTonkeanService } from '@tonkean/angular-hooks';
import { FormikEntitySelector } from '@tonkean/angular-to-react-components';
import { FormikTnkProjectIntegrationSelector } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { FieldSelector } from '@tonkean/fields';
import { FormikTonkeanIconPicker } from '@tonkean/icon-picker';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { useGetProjectIntegrationById } from '@tonkean/infrastructure';
import { useEntityAvailabeExternalFieldTonkeanExpressionTab } from '@tonkean/infrastructure';
import { Field, Input, SimpleSelect, useFormikField } from '@tonkean/infrastructure';
import { ProjectIntegrationActionSelector } from '@tonkean/infrastructure';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import { FormDefinitionType } from '@tonkean/tonkean-entities';
import { ActionType } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY } from '@tonkean/utils';

const ACTION_TYPES = [ActionType.STREAM_IMAGE];

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 16px;
`;

const StyledConfigurationSectionTitle = styled(ConfigurationSectionTitle)`
    margin-bottom: 0;
`;

const EntitySelectorWrapper = styled.div`
    .entity-selector-react {
        width: 100%;
    }
`;

const resultModeToLabelMap: Record<SearchWidgetSelectedResultMode, string> = {
    [SearchWidgetSelectedResultMode.LIST_FIELD]: 'Store as list in field',
    [SearchWidgetSelectedResultMode.INNER_ITEMS]: 'Create inner items',
};

const resultModeOptions: SimpleSelectSingleOption<SearchWidgetSelectedResultMode>[] = Object.entries(
    resultModeToLabelMap,
).map(([key, value]) => {
    return { value: key as SearchWidgetSelectedResultMode, label: value };
});

const cardsDensityOptions: SimpleSelectSingleOption<SearchWidgetCardsDensity>[] = Object.entries(
    SearchWidgetCardsDensity,
).map(([key, value]) => {
    return { value, label: `${key.charAt(0)}${key.slice(1).toLowerCase()}` };
});

const imagePlaceholderOptions: SimpleSelectSingleOption<SearchWidgetImagePlaceholderMode>[] = Object.entries(
    SearchWidgetImagePlaceholderMode,
).map(([key, value]) => {
    return { value, label: `${key.charAt(0)}${key.slice(1).toLowerCase()}` };
});

const fieldTypesForResultsStorageField = [FormDefinitionType.MANUAL];
const NO_ENTITY_CONFIGURED_REASON = 'Blocked for editing until search entity is configured';

const SearchWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<SearchWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const { setValue: setImageInputType, value: imageInputType } = useFormikField<string | undefined>(
        'configuration.imageInputType',
    );

    // Backward compatibility default assignment (widget was created before imageInputType was added
    if (imageInputType === undefined) {
        setImageInputType((prevValue) => (prevValue === undefined ? ImageInputType.IMAGE_FROM_FIELD : prevValue));
    }

    const enableStreamImageFeature = useFeatureFlag('tonkean_enable_stream_service_features');

    const { projectIntegration: searchEntityProjectIntegration } = useGetProjectIntegrationById(
        configuration.searchEntityProjectIntegrationId,
    );
    const { setValue: setSearchEntityApiName } = useField<string | undefined>('configuration.searchEntityApiName')[2];

    const { setValue: setImageAction } = useFormikField<string | undefined>(
        'configuration.imageStreamSelectedActionId',
    );

    const availableExternalFieldTab = useEntityAvailabeExternalFieldTonkeanExpressionTab(
        configuration.searchEntityProjectIntegrationId,
        configuration.searchEntityApiName,
    );
    const additionalTabs: TonkeanExpressionAdditionalTab[] = useMemo(() => {
        return [availableExternalFieldTab];
    }, [availableExternalFieldTab]);

    const [{ value: resultMode }, , { setValue: setResultMode }] = useField<SearchWidgetSelectedResultMode | undefined>(
        'configuration.resultMode',
    );

    const [{ value: cardsDensity }, , { setValue: setCardsDensity }] = useField<SearchWidgetCardsDensity | undefined>(
        'configuration.cardsDensity',
    );

    const [{ value: imagePlaceholderMode }, , { setValue: setImagePlaceholderMode }] = useField<
        SearchWidgetImagePlaceholderMode | undefined
    >('configuration.imagePlaceholderMode');

    const onResultModeChange = useCallback((value) => setResultMode(value), [setResultMode]);

    const noEntityConfigured = !configuration.searchEntityApiName;

    const { data: fieldsDefinitions, loading: fieldDefinitionsLoading } = useTonkeanService(
        'getFieldDefinitions',
        workflowVersion.id,
    );

    return (
        <Wrapper>
            <StyledConfigurationSectionTitle>Search States</StyledConfigurationSectionTitle>
            <Field label="Input placeholder" light>
                <FormikTonkeanExpression
                    workflowVersionId={workflowVersion.id}
                    groupId={workflowVersion.groupId}
                    name="configuration.searchInputPlaceholder"
                    dataAutomation="search-widget-configuration-editor-search-input-placeholder"
                    placeholder="Search"
                    hideEditorButton
                />
            </Field>

            <FormikTonkeanIconPicker name="configuration.noSearchStateIcon" addMargin={false} />

            <Field label="Title" light>
                <FormikTonkeanExpression
                    workflowVersionId={workflowVersion.id}
                    groupId={workflowVersion.groupId}
                    name="configuration.noSearchStateTitle"
                    dataAutomation="search-widget-configuration-editor-noSearchStateTitle"
                    placeholder="Title"
                    hideEditorButton
                />
            </Field>
            <Field label="Subtitle" light>
                <FormikTonkeanExpression
                    workflowVersionId={workflowVersion.id}
                    groupId={workflowVersion.groupId}
                    name="configuration.noSearchStateDescription"
                    dataAutomation="search-widget-configuration-editor-noSearchStateDescription"
                    placeholder="Subtitle"
                    hideEditorButton
                />
            </Field>

            <StyledConfigurationSectionTitle>Search Configuration</StyledConfigurationSectionTitle>
            <Field label="Results Source" light>
                <FormikTnkProjectIntegrationSelector
                    name="configuration.searchEntityProjectIntegrationId"
                    onProjectIntegrationSelected={() => {
                        setSearchEntityApiName(undefined);
                    }}
                    includeAllNoCodeDatasources
                />
            </Field>

            <Field label="Entity">
                <EntitySelectorWrapper>
                    <FormikEntitySelector
                        name="configuration.searchEntityApiName"
                        projectIntegration={searchEntityProjectIntegration}
                    />
                </EntitySelectorWrapper>
            </Field>

            <StyledConfigurationSectionTitle>Selected Configuration</StyledConfigurationSectionTitle>

            <Field label="Selected results action" light>
                <SimpleSelect
                    value={resultMode || SEARCH_WIDGET_DEFAULT_SELECTED_RESULT_MODE}
                    onChange={onResultModeChange}
                    options={resultModeOptions}
                    size={InputSize.MEDIUM}
                />
            </Field>

            <Field
                label="Field for storing selected results"
                informationTooltip="Only fields configured as multi-value can be used to store selected results"
                light
            >
                <FieldSelector
                    name="configuration.resultFieldDefinitionId"
                    fieldsDefinitions={fieldsDefinitions?.entities || EMPTY_ARRAY}
                    loading={fieldDefinitionsLoading}
                    typesToInclude={fieldTypesForResultsStorageField}
                    onlyMultiValueFields
                    thin
                />
            </Field>

            <StyledConfigurationSectionTitle>Editable Result Field</StyledConfigurationSectionTitle>

            <Field label="Field to edit" light>
                <FieldSelector
                    name="configuration.editableResultField.fieldDefinitionId"
                    fieldsDefinitions={fieldsDefinitions?.entities || EMPTY_ARRAY}
                    loading={fieldDefinitionsLoading}
                    typesToInclude={fieldTypesForResultsStorageField}
                    thin
                />
            </Field>

            <Field label="Label" light>
                <Input name="configuration.editableResultField.label" placeholder="Label" />
            </Field>

            <Field label="Default Value" light>
                <Input name="configuration.editableResultField.defaultValue" placeholder="Default Value" />
            </Field>

            <StyledConfigurationSectionTitle>View only Result Fields</StyledConfigurationSectionTitle>

            <Field label="Cards Density" light>
                <SimpleSelect
                    value={cardsDensity || SEARCH_WIDGET_DEFAULT_CARDS_DENSITY}
                    onChange={(value) => setCardsDensity(value)}
                    options={cardsDensityOptions}
                    size={InputSize.MEDIUM}
                />
            </Field>

            <Field
                label="Placeholder for images"
                informationTooltip="Select a placeholder method for the image column if no image exists on the search result."
                light
            >
                <SimpleSelect
                    value={imagePlaceholderMode || SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE}
                    onChange={(value) => setImagePlaceholderMode(value)}
                    options={imagePlaceholderOptions}
                    size={InputSize.MEDIUM}
                />
            </Field>

            {enableStreamImageFeature && (
                <>
                    <Field label="Image Input Method" informationTooltip="Select method of fetching image URL" light>
                        <SimpleSelect name="configuration.imageInputType" options={ImageInputTypes} thin />
                    </Field>

                    {imageInputType === ImageInputType.IMAGE_FROM_ACTION && (
                        <Field
                            label="Data Source Action"
                            informationTooltip="The action should be of type 'Stream Image' and fetch based on the image URL provided"
                            light
                        >
                            <ProjectIntegrationActionSelector
                                selectedProjectIntegrationActionId={configuration.imageStreamSelectedActionId}
                                projectIntegration={configuration.searchEntityProjectIntegrationId!}
                                actionTypes={ACTION_TYPES}
                                onProjectIntegrationActionChanged={setImageAction}
                            />
                        </Field>
                    )}
                </>
            )}

            <Field label="Image" light>
                <FormikTonkeanExpression
                    name="configuration.entityImageUrl"
                    dataAutomation="search-widget-configuration-editor-image-url"
                    placeholder="Image url"
                    additionalTabs={additionalTabs}
                    defaultTabId={availableExternalFieldTab.id}
                    showFormulasTab={false}
                    disabled={noEntityConfigured}
                    disabledReason={NO_ENTITY_CONFIGURED_REASON}
                    doNotEvaluatePreview
                    hideEditorButton
                />
            </Field>

            <Field label="Title" light>
                <FormikTonkeanExpression
                    name="configuration.title"
                    dataAutomation="search-widget-configuration-editor-title"
                    placeholder="Title"
                    additionalTabs={additionalTabs}
                    defaultTabId={availableExternalFieldTab.id}
                    showFormulasTab={false}
                    disabled={noEntityConfigured}
                    disabledReason={NO_ENTITY_CONFIGURED_REASON}
                    doNotEvaluatePreview
                    hideEditorButton
                />
            </Field>

            <Field label="Description" light>
                <FormikTonkeanExpression
                    name="configuration.description"
                    dataAutomation="search-widget-configuration-editor-description"
                    placeholder="Description"
                    additionalTabs={additionalTabs}
                    defaultTabId={availableExternalFieldTab.id}
                    showFormulasTab={false}
                    disabled={noEntityConfigured}
                    disabledReason={NO_ENTITY_CONFIGURED_REASON}
                    doNotEvaluatePreview
                    hideEditorButton
                />
            </Field>

            <Field label="Tag text" light>
                <FormikTonkeanExpression
                    name="configuration.tagText"
                    dataAutomation="search-widget-configuration-editor-tag-text"
                    placeholder="Tag text"
                    additionalTabs={additionalTabs}
                    defaultTabId={availableExternalFieldTab.id}
                    showFormulasTab={false}
                    disabled={noEntityConfigured}
                    disabledReason={NO_ENTITY_CONFIGURED_REASON}
                    doNotEvaluatePreview
                    hideEditorButton
                />
            </Field>

            <Field label="Secondary property" light>
                <FormikTonkeanExpression
                    name="configuration.secondaryProperty"
                    dataAutomation="search-widget-configuration-editor-secondary-property"
                    placeholder="Secondary property"
                    additionalTabs={additionalTabs}
                    defaultTabId={availableExternalFieldTab.id}
                    showFormulasTab={false}
                    disabled={noEntityConfigured}
                    disabledReason={NO_ENTITY_CONFIGURED_REASON}
                    doNotEvaluatePreview
                    hideEditorButton
                />
            </Field>
        </Wrapper>
    );
};

export default SearchWidgetConfigurationEditor;
