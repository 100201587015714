import React from 'react';

const SolutionMapperGraphEdgeWrapper: React.ForwardRefRenderFunction<
    SVGSymbolElement,
    React.PropsWithChildren<{ id: string }>
> = ({ children, id }, ref) => {
    return (
        <symbol width={57} height={58} viewBox="0 0 57 58" id={id} ref={ref}>
            <rect
                width={32.042}
                height={33.287}
                rx={3}
                transform="matrix(.66667 -.74535 .72184 .69206 5.694 29.17)"
                fillOpacity={0.28}
                // We don't set fill color to infer it from the styling on the "use" element, to allow us to
                // change the fill when selected.
            />
            <rect
                x={1.389}
                y={-0.053}
                width={21.142}
                height={22.04}
                rx={2}
                transform="matrix(.66667 -.74535 .72184 .69206 12.186 30.366)"
                fill="#fff"
                stroke="#979797"
                strokeWidth={2}
            />
            <>{children}</>
        </symbol>
    );
};

export default React.forwardRef(SolutionMapperGraphEdgeWrapper);
