import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import type { DropdownAction } from './ActionCheckboxRow';
import ModuleActionsSelectorEmptyState from './ModuleActionsSelectorEmptyState';
import SolutionsBusinessReportActionsManagementActionsList from './SolutionsBusinessReportActionsManagementActionsList';

import { SearchBox } from '@tonkean/infrastructure';
import type { GroupEnvironmentFormSummary } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import { Theme, FontSize } from '@tonkean/tui-theme';

const ACTION_TYPE_WIDTH_PERCENTAGE = 37;

const SearchAndHeadersDiv = styled.div<{ boxShadow?: boolean }>`
    padding: 24px 0 0;
    ${({ boxShadow }) =>
        boxShadow &&
        css`
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
        `};
    z-index: 1;
    position: relative;
`;

const SearchDiv = styled.div`
    margin: 0 19px;
`;

const StyledSearchBox = styled(SearchBox)`
    margin: 0 auto;
`;

const HeadersDiv = styled.div`
    display: flex;
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_600};
    margin-top: 15px;
`;
const ActionNameTitle = styled.div`
    flex-grow: 1;
    margin-left: 20px;
`;

const ActionTypeTitle = styled.span`
    width: ${ACTION_TYPE_WIDTH_PERCENTAGE}%;
`;

interface Props {
    actions: GroupEnvironmentFormSummary[];
    setActions: (actions: GroupEnvironmentFormSummary[]) => void;
    newFormsGroupId: TonkeanId<TonkeanType.GROUP> | undefined;
    headerBoxShadow?: boolean;
    additionalDataLabel: string;
    enableEditLabel?: boolean;
    setLabel?: (id: string, label: string) => void;
    actionEntityIdToDropDownExtraAction?: Record<string, DropdownAction[]>;
}

const ModuleActionsSelector: React.FC<Props> = ({
    actions,
    setActions,
    newFormsGroupId,
    headerBoxShadow,
    additionalDataLabel,
    enableEditLabel,
    setLabel,
    actionEntityIdToDropDownExtraAction,
}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const updateActions = useConstantRefCallback((formId: string, isAvailable: boolean, label?: string) => {
        setActions(
            actions.map((form) => {
                if (form.id === formId) {
                    return { ...form, isAvailable: form.id === formId ? isAvailable : form.isAvailable };
                }
                return form;
            }),
        );
    });

    if (actions.length) {
        return (
            <>
                <SearchAndHeadersDiv boxShadow={headerBoxShadow}>
                    <SearchDiv>
                        <StyledSearchBox
                            $height="26px"
                            $width="100%"
                            placeholder="Search an action"
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                        />
                    </SearchDiv>
                    <HeadersDiv>
                        <ActionNameTitle>Action Name</ActionNameTitle>
                        <ActionTypeTitle>{additionalDataLabel}</ActionTypeTitle>
                    </HeadersDiv>
                </SearchAndHeadersDiv>
                {actions && (
                    <SolutionsBusinessReportActionsManagementActionsList
                        records={actions}
                        updateRecords={updateActions}
                        searchTerm={searchTerm}
                        actionTypeWidthPercentage={ACTION_TYPE_WIDTH_PERCENTAGE}
                        enableEditLabel={enableEditLabel}
                        setLabel={setLabel}
                        actionEntityIdToDropDownExtraAction={actionEntityIdToDropDownExtraAction}
                    />
                )}
            </>
        );
    } else {
        return <ModuleActionsSelectorEmptyState groupId={newFormsGroupId} />;
    }
};

export default ModuleActionsSelector;
