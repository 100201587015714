import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { ExecutionResult } from '@urql/core';
import type { DocumentNode } from 'graphql';
import { Kind, OperationTypeNode } from 'graphql';
import type { Client } from 'urql';

import { mockMutationField, mockMutationResponse } from './addMockMutationForCacheUpdateToSchema';

import type { Either } from '@tonkean/utils';

const query: TypedDocumentNode = {
    kind: Kind.DOCUMENT,
    definitions: [
        {
            kind: Kind.OPERATION_DEFINITION,
            operation: OperationTypeNode.MUTATION,
            selectionSet: {
                kind: Kind.SELECTION_SET,
                selections: [
                    {
                        kind: Kind.FIELD,
                        name: { kind: Kind.NAME, value: mockMutationField.name },
                        selectionSet: {
                            kind: Kind.SELECTION_SET,
                            selections: mockMutationResponse.fields.map((field) => ({
                                kind: Kind.FIELD,
                                name: { kind: Kind.NAME, value: field.name },
                            })),
                        },
                    },
                ],
            },
        },
    ],
};
const queryResponse: ExecutionResult = {
    data: {
        [mockMutationField.name]: {
            __typename: mockMutationResponse.name,
            ...Object.fromEntries(mockMutationResponse.fields.map((field) => [field.name, 'FAKE_VALUE'] as const)),
        },
    },
};
const dataUri = `data:application/json;charset=utf-8;base64,${window.btoa(JSON.stringify(queryResponse))}`;

export interface UpdateGraphqlCachedEntityVariablesWithEntityName<T> {
    entityName: string;
    object: T;
}
export interface UpdateGraphqlCachedEntityVariablesWithFragment<T> {
    fragment: DocumentNode | TypedDocumentNode<T>;
    object: T;
}
export type UpdateGraphqlCachedEntityVariables<T> = Either<
    UpdateGraphqlCachedEntityVariablesWithFragment<T>,
    UpdateGraphqlCachedEntityVariablesWithEntityName<T>
>;

function updateGraphqlCachedEntity<T>(urql: Client, updateInformation: UpdateGraphqlCachedEntityVariables<T>) {
    return urql
        .mutation<any, UpdateGraphqlCachedEntityVariables<T>>(query, updateInformation, {
            // This is needed so we won't send a real http request.
            fetch: () => fetch(dataUri),
        })
        .toPromise() as unknown as Promise<void>;
}

export default updateGraphqlCachedEntity;
