import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

interface Props extends CustomizedAuthenticationComponentProps {}

const WorkdayComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [tenant, setTenant] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [baseUrl, setBaseUrl] = useState<string | undefined>(undefined);
    const [version, setVersion] = useState<string | undefined>(undefined);

    const authenticateWithUserAndOrg = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                tenant,
                username,
                password,
                baseUrl,
                version,
            };
            authenticate(config, {}, `${integrationConfiguration.displayName} (${tenant})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithUserAndOrg}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="login-tenant" className="col-sm-3 control-label">
                            Tenant Name
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={tenant}
                                className="form-control"
                                id="login-tenant"
                                onChange={({ target }) => setTenant(target.value)}
                                autoComplete="off"
                                placeholder="Tenant Name"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                Tenant ID can be found in the URL of your instance when you are in the Workday UI. e.g.{' '}
                                <span className="common-bold common-color-dark-grey">
                                    https://wd5-impl.workday.com/[Tenant]/...
                                </span>
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-username" className="col-sm-3 control-label">
                            API Username
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={username}
                                className="form-control"
                                id="login-username"
                                onChange={({ target }) => setUsername(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                The Username of the Integration System User
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-password" className="col-sm-3 control-label">
                            API Password
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                onChange={({ target }) => setPassword(target.value)}
                                id="login-password"
                                autoComplete="off"
                                placeholder="Password"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                The Password of the Integration System User
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-tenant" className="col-sm-3 control-label">
                            Service Base URL
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={baseUrl}
                                className="form-control"
                                id="login-tenant"
                                onChange={({ target }) => setBaseUrl(target.value)}
                                autoComplete="off"
                                placeholder="Base URL"
                                required
                            />
                            <div className="common-size-xxxs common-color-grey">
                                <ul className="common-ul-small-bullets mod-grey margin-top-xs padding-left-xxs margin-bottom-xxs">
                                    <li>
                                        In the Workday UI, search for "Public Web Services" and select the "Public Web
                                        Services" report
                                    </li>
                                    <li>
                                        On any of the web services, select the three dots to the right of the service
                                        name and take the action "Web Service" -&bt; "View WSDL"
                                    </li>
                                    <li>
                                        In the WSDL, search for "soapbind:address" and find the value in the "location"
                                        property of that field.
                                        <br />
                                        <span className="common-bold common-color-dark-grey">
                                            https://wd5-impl-services1.workday.com/ccx/service/[Tenant]/.../v31.0
                                        </span>
                                    </li>
                                    <li>
                                        The value for "Base URL" should be this value up to the tenant name.
                                        <br />
                                        In the example listed above, it would be
                                        <br />
                                        <span className="common-bold common-color-dark-grey">
                                            https://wd5-impl-services1.workday.com/ccx/service
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-tenant" className="col-sm-3 control-label">
                            Service Version
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={version}
                                className="form-control"
                                id="login-tenant"
                                onChange={({ target }) => setVersion(target.value)}
                                autoComplete="off"
                                placeholder="Service Version"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                In the example listed above, it would be 31.0
                            </span>
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default WorkdayComponent;

// TODO known fixed
// DONE css-loader - remove ~ from paths for node_module in scss
// DONE framer-motion - remove AnimateSharedLayout, it works without it (check if tabs still have indicator moving)
// Remove file-loader and use asset/resource instead (https://github.com/gregberge/svgr/issues/551)
// Fix typing for react-select (https://github.com/JedWatson/react-select/releases/tag/react-select%405.0.0)
