/**
 * Service for updating a user is viewing the group.
 */
function ViewingGroupUpdateService($interval, tonkeanService, projectManager) {
    const _this = this;

    /**
     * A map that holds all the promises for updating the viewing group.
     */
    _this.intervalUpdatePromisesMap = {};

    /**
     * Updates the user viewing the group using the API.
     */
    _this.updateUserViewingGroupApiCall = function (groupIdOrToken, isAnonymousViewer, anonymousUserId) {
        let updatePromise;

        if (isAnonymousViewer) {
            updatePromise = tonkeanService.updateUserViewingGroupWithToken(groupIdOrToken, anonymousUserId);
        } else {
            updatePromise = tonkeanService.updateUserViewingGroup(groupIdOrToken);
        }

        return updatePromise.then((groupIdToViewingUsersMap) => {
            if (!isAnonymousViewer) {
                projectManager.updateGroupIdToViewingUsersMap(groupIdOrToken, groupIdToViewingUsersMap[groupIdOrToken]);
            }
        });
    };

    /**
     * Registers an interval request for viewing group update.
     * Executes the user viewing group api call, and then sets the update interval running.
     */
    _this.registerIntervalViewingGroupUpdate = function (
        groupIdOrToken,
        isAnonymousViewer,
        anonymousUserId,
        interval,
        callback,
    ) {
        _this.updateUserViewingGroupApiCall(groupIdOrToken, isAnonymousViewer, anonymousUserId).then(() => callback());

        return (_this.intervalUpdatePromisesMap[groupIdOrToken] = $interval(() => {
            _this
                .updateUserViewingGroupApiCall(groupIdOrToken, isAnonymousViewer, anonymousUserId)
                .then(() => callback());
        }, interval));
    };

    /**
     * Cancels the interval at given id.
     */
    _this.cancelInterval = function (groupIdOrToken) {
        if (_this.intervalUpdatePromisesMap[groupIdOrToken]) {
            $interval.cancel(_this.intervalUpdatePromisesMap[groupIdOrToken]);
            delete _this.intervalUpdatePromisesMap[groupIdOrToken];
        }
    };
}
angular.module('tonkean.app').service('viewingGroupUpdateService', ViewingGroupUpdateService);
