import template from './cachedPeopleSelector.template.html?angularjs';

/**
 * Component to select a existing users in Tonkean using the person cache.
 */
export default angular.module('tonkean.app').component('tnkCachedPeopleSelector', {
    bindings: {
        selectedPeopleIds: '<',
        maxPeople: '<',
        placeholder: '@',

        onPersonSelected: '&',
        onPersonRemoved: '&',
        paramsObjectForCallbacks: '<',
    },
    template,
    controller: 'CachedPeopleSelectorCtrl',
});
