import { useMemo } from 'react';

import useCustomColorListFromProjectThemConfiguration from './useCustomColorListFromProjectThemConfiguration';
import useFilterOutHiddenColors from './useFilterOutHiddenColors';
import type { CustomColor } from '../components/TUI/ColorPicker';

import type { Color } from '@tonkean/tui-theme';

/**
 * This hook is used to get the actual color to display based on the color, customColorId, filteredHiddenColors and defaultColor
 * It is relevant in case the color is changed in the project theme configuration
 * @param color
 * @param customColorId
 * @param defaultColor
 */

const useActualColorToDisplay: (
    color: Color | undefined,
    customColorId: string | undefined,
    defaultColor: Color,
) => string = (color: Color | undefined, customColorId: string | undefined, defaultColor: Color) => {
    const projectThemeConfigurationCustomColorList: CustomColor[] = useCustomColorListFromProjectThemConfiguration();
    const filteredHiddenColors = useFilterOutHiddenColors(projectThemeConfigurationCustomColorList);

    return useMemo(() => {
        let actualColorToDisplay: string | undefined = '';

        if (customColorId) {
            actualColorToDisplay = filteredHiddenColors.find((customColor) => customColor.id === customColorId)?.color;
        }
        actualColorToDisplay = actualColorToDisplay || color || defaultColor;
        return actualColorToDisplay;
    }, [color, customColorId, defaultColor, filteredHiddenColors]);
};
export default useActualColorToDisplay;
