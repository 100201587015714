import React from 'react';
import styled from 'styled-components';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import type { ActionParameterExpression, BaseActionParameter } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ParameterTitle = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;

const ParamDescription = styled.div`
    font-size: 10px;
    color: ${Theme.colors.gray_700};
`;

const IsRequired = styled.span`
    color: ${Theme.colors.error};
    margin-right: 2px;
`;

export interface SimpleActionParameterSectionProps {
    singleActionParameter: BaseActionParameter & ActionParameterExpression;
    saveParameterExpressionValue: (
        originalExpression: string,
        evaluatedExpression: string,
        shouldSaveLogic: boolean,
        actionParameter: BaseActionParameter,
    ) => void;
    groupId: string;
    workflowVersionId: string;
    customTriggerId: string;
    parametersValues: unknown;
    previewEvaluationSource: unknown;
}
const SimpleActionParameterSection: React.FC<SimpleActionParameterSectionProps> = ({
    singleActionParameter,
    saveParameterExpressionValue,
    groupId,
    workflowVersionId,
    customTriggerId,
    parametersValues,
    previewEvaluationSource,
}) => (
    <>
        <ParameterTitle>
            {singleActionParameter.isRequired && <IsRequired>*</IsRequired>}
            {singleActionParameter.displayName}
            {singleActionParameter.displayName.endsWith(':') ? '' : ':'}
            {singleActionParameter.description && (
                <ParamDescription>{singleActionParameter.description}</ParamDescription>
            )}
        </ParameterTitle>

        <TonkeanExpression
            groupId={groupId}
            workflowVersionId={workflowVersionId}
            automationIdentifierExpressionValue="simple-action-parameter-section-tonkean-expression"
            logicId={customTriggerId}
            savedEvaluatedExpression={parametersValues?.[singleActionParameter.id]?.evaluatedExpression}
            savedOriginalExpression={parametersValues?.[singleActionParameter.id]?.originalExpression}
            previewEvaluationSource={previewEvaluationSource}
            onTonkeanExpressionChanged={(originalExpression, evaluatedExpression, ignored, shouldSaveLogic) =>
                saveParameterExpressionValue(
                    originalExpression,
                    evaluatedExpression,
                    !!shouldSaveLogic,
                    singleActionParameter,
                )
            }
            saveOnKeyUp
        />
    </>
);

export default SimpleActionParameterSection;
