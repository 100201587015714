import type { ColDef } from '@ag-grid-community/core';
import type { IRootScopeService } from 'angular';

import { IntegrationType } from '@tonkean/tonkean-entities';

const columnsDef = (
    projectIntegration,
    entityName,
    $rootScope: IRootScopeService,
    selectedEntity: string | undefined,
    onDataChanged?: () => void,
    isEditRecordsEnabled: boolean = false,
): ColDef<any>[] => {
    const enableEditItem =
        projectIntegration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE &&
        onDataChanged &&
        isEditRecordsEnabled;

    const columns: ColDef<any>[] = [
        {
            headerName: 'Title',
            field: 'tnk_title',
            type: 'resizableColumn',
            pinned: 'left',
            cellRenderer: 'viewDataTitleCellRenderer',
            suppressSizeToFit: true,
            maxWidth: 300,
            minWidth: 200,
        },
        {
            headerName: '',
            field: 'TNK_REAL_ENTITY_ID',
            cellRenderer: 'viewDataInspectCellRenderer',
            cellRendererParams: {
                projectIntegration,
                viewDataEntity: entityName,
                projectIntegrationEntityId: selectedEntity,
                onDataChanged,
                enableEdit: enableEditItem,
            },
            cellStyle: { border: 'none', background: 'transparent', display: 'flex' },
            headerClass: 'pointer-events-none',
            pinned: 'right',
            maxWidth: enableEditItem ? 180 : 110,
            minWidth: enableEditItem ? 180 : 110,
            suppressSizeToFit: true,
        },
    ];

    if (projectIntegration.integrationType === IntegrationType.WEBHOOK && isEditRecordsEnabled) {
        const webhookDef: ColDef<any> = {
            headerName: '',
            field: 'TNK_REAL_ENTITY_ID',
            cellRenderer: 'viewDataEditCellRenderer',
            cellRendererParams: {
                projectIntegration,
                viewDataEntity: entityName,
                projectIntegrationEntityId: selectedEntity,
                onDataChanged,
                enableEdit: false,
            },
            cellStyle: { border: 'none', background: 'transparent', display: 'flex' },
            headerClass: 'pointer-events-none',
            maxWidth: 110,
            minWidth: 110,
            pinned: 'right',
            suppressSizeToFit: true,
        };

        columns.push(webhookDef);
    }

    return columns;
};
export default columnsDef;
