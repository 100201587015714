import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import MarketplaceItemUpdateSourceModal from './MarketplaceItemUpdateSourceModal';
import { ReactComponent as DownloadIcon } from '../../../../images/icons/download.svg';
import { ReactComponent as PencilIcon } from '../../../../images/icons/edit-pencil.svg';
import { ReactComponent as EyeIcon } from '../../../../images/icons/eye.svg';
import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-new.svg';
import { ReactComponent as UploadIcon } from '../../../../images/icons/upload-12px.svg';
import type { MarketplaceItemCreateSection } from '../../../components/common/tnkWorkersBrowser/marketplace/components/MarketplaceItemCreateModalSection';
import MarketplaceItemCreateEditModal from '../../../components/common/tnkWorkersBrowser/MarketplaceItemCreateEditModal';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import {
    H4,
    KebabMenuButton,
    LoadingCircle,
    Menu,
    MenuItem,
    SavingIndicator,
    SimpleErrorStateMessage,
    TextEllipsis,
    Tooltip,
    useCreateDialog,
} from '@tonkean/infrastructure';
import { useCurrentUserRole } from '@tonkean/infrastructure';
import type { MarketplaceItem, TonkeanId, TonkeanType, TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const Container = styled.div`
    border: 1px solid ${Theme.colors.gray_300};
    padding: 16px 16px 16px 19px;
`;

const TopBar = styled.div`
    display: flex;
    align-items: baseline;
`;

const PreviewSummary = styled(TextEllipsis)`
    padding-top: 4px;
    color: ${Theme.colors.gray_700};
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
`;

const StyledMenuItem = styled(MenuItem)<{ disabled?: boolean }>`
    width: 150px;
    display: flex;
    align-items: center;

    ${({ disabled }) =>
        !disabled &&
        css`
            ${colorSvg(Theme.colors.gray_700)};
        `};
`;

const BasedOnEntity = styled.div`
    padding-top: 4px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
`;

interface Props {
    marketplaceItem: MarketplaceItem;
    rootEntityName: string | undefined;
    onItemSubmitted: (marketplaceItem: MarketplaceItem) => void;
    onItemDeleted: (marketplaceItemId: TonkeanId<TonkeanType.MARKETPLACE_ITEM>) => void;
    dataAutomation: string;
    additionalSections?: MarketplaceItemCreateSection[];
    validate: () => boolean;
    getItemsToDownload: () => MarketplaceItem[];
}

const MarketplaceGalleryItem: React.FC<Props> = ({
    marketplaceItem,
    rootEntityName,
    onItemSubmitted,
    onItemDeleted,
    dataAutomation,
    additionalSections,
    validate,
    getItemsToDownload,
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [templateModalOpen, setTemplateModalOpen] = useState(false);
    const [updateSourceModalOpen, setUpdateSourceModalOpen] = useState(false);
    const { userId, isAdmin } = useCurrentUserRole();

    const [{ error: deleteError, loading: deleteLoading }, deleteMarketplaceItem] =
        useLazyTonkeanService('deleteMarketplaceItem');
    const { confirm } = useCreateDialog();
    const onDelete = () => {
        return confirm('Delete Reusable Block?', `Are you sure you want to delete ${marketplaceItem.title}?`, {
            okLabel: 'Delete',
            cancelLabel: 'Cancel',
            delete: true,
        }).then((confirmed) => {
            if (confirmed) {
                deleteMarketplaceItem(marketplaceItem.projectId, marketplaceItem.id).then(() =>
                    onItemDeleted(marketplaceItem.id),
                );
            }
        });
    };

    const canEditOrDelete = isAdmin || marketplaceItem.creator.id === userId;

    const showDownload = useFeatureFlag('tonkean_feature_download_marketplace_item');

    const [{ loading: downloading }, getMarketplaceItemBlob] = useLazyTonkeanService('getMarketplaceItemBlob');

    const marketplaceItemImages: TonkeanUploadedFile[] = useMemo(() => {
        return (
            marketplaceItem.images?.map((imageUrl, index) => ({
                id: index,
                url: imageUrl,
            })) || []
        );
    }, [marketplaceItem.images]);

    const downloadItem = () => {
        getMarketplaceItemBlob(marketplaceItem.id).then((blob) => {
            const url = URL.createObjectURL(blob);

            const anchorElement = document.createElement('a');
            anchorElement.href = url;
            anchorElement.download = marketplaceItem.templateName;
            anchorElement.click();

            URL.revokeObjectURL(url);
        });
    };

    const getBlob = async (
        id: TonkeanId<TonkeanType.MARKETPLACE_ITEM>,
        templateName: string,
        marketplaceItemType: MarketplaceItemType,
    ) => {
        return new Promise<{ name: string; blob: Blob; marketplaceItemType: MarketplaceItemType }>((resolve) => {
            getMarketplaceItemBlob(id).then((blob) => {
                resolve({ name: templateName, marketplaceItemType, blob });
            });
        });
    };

    const getZip = async (marketplaceItemsToDownload: MarketplaceItem[]) => {
        const zip = new JSZip();

        const solutionItems = marketplaceItemsToDownload.filter((item) => item.type === MarketplaceItemType.SOLUTION);
        const moduleItems = marketplaceItemsToDownload.filter((item) => item.type === MarketplaceItemType.MODULE);
        const dataSourceItems = marketplaceItemsToDownload.filter(
            (item) => item.type === MarketplaceItemType.DATA_SOURCE,
        );

        const solutionBlueprintsFolder = solutionItems.length > 0 ? zip.folder('solution') : undefined;
        const modulesFolder = moduleItems.length > 0 ? zip.folder('module') : undefined;
        const dataSourcesFolder = dataSourceItems.length > 0 ? zip.folder('data-source') : undefined;

        const downloadRequests = marketplaceItemsToDownload.map((item) =>
            getBlob(item.id, item.templateName, item.type),
        );

        await Promise.all(downloadRequests).then((response) => {
            response.forEach((item) => {
                const fileName = `${item.name}.json`;
                const fileContent = item.blob;

                switch (item.marketplaceItemType) {
                    case MarketplaceItemType.SOLUTION:
                        solutionBlueprintsFolder?.file(fileName, fileContent);
                        break;

                    case MarketplaceItemType.MODULE:
                        modulesFolder?.file(fileName, fileContent);
                        break;

                    case MarketplaceItemType.DATA_SOURCE:
                        dataSourcesFolder?.file(fileName, fileContent);
                        break;
                }
            });
        });

        return zip;
    };

    const downloadAll = async (itemsToDownload: MarketplaceItem[]) => {
        const zipBlob = await getZip(itemsToDownload);
        zipBlob.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${marketplaceItem.title} Library JSON Files.zip`);
        });
    };

    const downloadPackage = async () => {
        const validationResult = validate();
        if (!validationResult) {
            return;
        }
        const itemsToDownload = getItemsToDownload();
        await downloadAll(itemsToDownload);
    };

    return (
        <Container data-automation={dataAutomation}>
            <TopBar>
                <div className="flex-grow">
                    <H4 data-automation="marketplace-gallery-item-title" $bold>
                        {marketplaceItem.title}
                    </H4>
                    {rootEntityName && <BasedOnEntity>Based on: {rootEntityName}</BasedOnEntity>}
                    <PreviewSummary data-automation="marketplace-gallery-item-sub-title" tooltip>
                        {marketplaceItem.previewSummary || 'No Summary text on library card defined'}
                    </PreviewSummary>
                </div>

                {(deleteLoading || deleteError) && <SavingIndicator loading={deleteLoading} error={deleteError} />}

                <Menu
                    show={menuOpen}
                    onClose={() => setMenuOpen(false)}
                    placement="left-start"
                    menuItems={
                        <>
                            <StyledMenuItem
                                data-automation="marketplace-gallery-item-view-option"
                                icon={<EyeIcon />}
                                state="product.marketplaceItemView"
                                params={{ marketplaceItemId: marketplaceItem.id }}
                            >
                                View
                            </StyledMenuItem>
                            {showDownload && (
                                <StyledMenuItem icon={<DownloadIcon />} onClick={() => downloadItem()}>
                                    Download
                                    {downloading && <LoadingCircle />}
                                </StyledMenuItem>
                            )}
                            {showDownload && marketplaceItem.type === MarketplaceItemType.SOLUTION && (
                                <StyledMenuItem icon={<DownloadIcon />} onClick={() => downloadPackage()}>
                                    Download Package
                                    {downloading && <LoadingCircle />}
                                </StyledMenuItem>
                            )}
                            <Tooltip content="Only board admins or creators can edit" disabled={canEditOrDelete}>
                                <StyledMenuItem
                                    data-automation="marketplace-gallery-item-edit-option"
                                    icon={<PencilIcon />}
                                    onClick={() => setTemplateModalOpen(true)}
                                    disabled={!canEditOrDelete}
                                >
                                    Edit
                                </StyledMenuItem>
                            </Tooltip>
                            <Tooltip
                                content="Only board admins or creators can update source"
                                disabled={canEditOrDelete}
                            >
                                <StyledMenuItem
                                    data-automation="marketplace-gallery-item-update-source-option"
                                    icon={<UploadIcon />}
                                    onClick={() => setUpdateSourceModalOpen(true)}
                                    disabled={!canEditOrDelete}
                                >
                                    Update Source
                                </StyledMenuItem>
                            </Tooltip>
                            <Tooltip
                                content="Only board admins or creators can update delete"
                                disabled={canEditOrDelete}
                            >
                                <StyledMenuItem
                                    data-automation="marketplace-gallery-item-delete-option"
                                    icon={<TrashIcon />}
                                    onClick={() => onDelete()}
                                    disabled={!canEditOrDelete}
                                >
                                    Delete
                                </StyledMenuItem>
                            </Tooltip>
                        </>
                    }
                >
                    <KebabMenuButton
                        dataAutomation="marketplace-gallery-item-kebab-menu"
                        onClick={() => setMenuOpen(true)}
                        flat
                        thin
                    />
                </Menu>
            </TopBar>
            {deleteError && <SimpleErrorStateMessage error={deleteError} showSmallError />}

            <MarketplaceItemCreateEditModal
                open={templateModalOpen}
                onClose={() => setTemplateModalOpen(false)}
                projectId={marketplaceItem.projectId}
                marketplaceItemType={marketplaceItem.type}
                existingMarketplaceItem={marketplaceItem}
                onSubmit={onItemSubmitted}
                additionalSections={additionalSections}
                marketplaceItemImages={marketplaceItemImages}
                blueprintSolutionType={
                    marketplaceItem.type === MarketplaceItemType.SOLUTION
                        ? marketplaceItem.configuration.blueprintSolutionType
                        : undefined
                }
            />

            <MarketplaceItemUpdateSourceModal
                open={updateSourceModalOpen}
                onSubmit={onItemSubmitted}
                onClose={() => setUpdateSourceModalOpen(false)}
                marketplaceItem={marketplaceItem}
                rootEntityName={rootEntityName}
            />
        </Container>
    );
};

export default MarketplaceGalleryItem;
