import MicrosoftTeamsAppCustomizeSetupComponent from './MicrosoftTeamsAppCustomizeSetupComponent';
import microsoftTeamsAppCircleIcon from '../../../../apps/tracks/images/integrations/microsoft teams-circle.svg';
import microsoftTeamsAppIcon from '../../../../apps/tracks/images/microsoftTeams.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class MicrosoftTeamsAppIntegration extends IntegrationDefinitionBase {
    override name = 'microsoftteamsapp';
    override displayName = 'MS Teams';
    override description = 'This integration only collects the “Message” entity.';
    override authenticationType = AuthenticationMethod.OAUTH;
    override entities = [new IntegrationEntity('Message', 'Messages')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(microsoftTeamsAppCircleIcon, '130px'),
        new IntegrationIcon(microsoftTeamsAppIcon, '130px'),
    );

    override customizedSetupComponent = MicrosoftTeamsAppCustomizeSetupComponent;

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Message: true },
        isOnlyDataRetention: {},
    };

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const scopes =
            'https%3A%2F%2Fgraph.microsoft.com%2FTeam.ReadBasic.All https%3A%2F%2Fgraph.microsoft.com%2FUser.Read';

        const redirectUri = environment.integrationKeysMap.microsoftUri;

        const code = await oAuthHandler.microsoft(scopes, redirectUri, true);

        return { code, redirectUri };
    }
}

export default MicrosoftTeamsAppIntegration;
