import React from 'react';
import styled from 'styled-components';

import UploadModal from './UploadModal';
import type { UploadWidgetConfiguration } from '../entities';

import { Breakpoint, SearchBox, useBreakpoint } from '@tonkean/infrastructure';
import type {
    Initiative,
    TonkeanId,
    TonkeanType,
    WidgetBase,
    WidgetParentTypes,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: center;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: inline;
        max-width: 210px;
    }
`;

interface Props {
    initiative?: Initiative;
    searchText: string;
    setSearchText: (text: string) => void;
    widget: WidgetBase<UploadWidgetConfiguration, WidgetParentTypes>;
    workflowVersionType?: WorkflowVersionType;
    headerBackgroundColor: string;
    groupId?: TonkeanId<TonkeanType.GROUP>;
    onFilesAdded: (newInitiatives: Initiative[]) => Promise<void>;
}

const UploadWidgetHeaderActions: React.FC<Props> = ({
    initiative,
    searchText,
    setSearchText,
    widget,
    workflowVersionType,
    groupId,
    onFilesAdded,
    headerBackgroundColor,
}) => {
    const effectiveBreakpoint = useBreakpoint();
    const isMobile = effectiveBreakpoint <= Breakpoint.MID_XSMALL_768;

    return (
        <ContentWrapper>
            {widget.configuration.showFileUploadButton && widget.configuration.uploadProjectIntegrationId && (
                <UploadModal
                    initiative={initiative}
                    widget={widget}
                    workflowVersionType={workflowVersionType}
                    groupId={groupId}
                    onFilesAdded={onFilesAdded}
                    headerBackgroundColor={headerBackgroundColor}
                />
            )}
            <SearchBox
                value={searchText}
                onChange={({ target: { value } }) => setSearchText(value)}
                placeholder="Search"
                $maxWidth={isMobile ? '100%' : '200px'}
                $height="30px"
                $width="210px"
                $borderRadius="18px"
                data-automation="upload-widget-header-actions-search"
            />
        </ContentWrapper>
    );
};

export default UploadWidgetHeaderActions;
