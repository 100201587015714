import { useAngularService } from 'angulareact';
import React, { useContext, useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { RadioGroup } from '@tonkean/infrastructure';
import { Radio } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

export interface ShopifyProps extends CustomizedAuthenticationComponentProps {}

const ShopifyComponent: React.FC<ShopifyProps> = ({ authenticate, reportError, integrationConfiguration }) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [authType, setAuthType] = useState<string>('oauth');
    const [storeKey, setStoreKey] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);

    const startAuthentication = async () => {
        try {
            if (authType === 'oauth') {
                const redirectUri = environment.integrationKeysMap.shopifyUri;
                const clientId = environment.integrationKeysMap.shopify;
                const redirectUriState = oauthHandler.publicGetState();
                const scopes =
                    'read_customers,read_inventory,write_inventory,write_customers,read_products,write_products,read_orders,write_orders,read_all_orders';
                const url = `https://${storeKey}.myshopify.com/admin/oauth/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${redirectUriState}`;

                const code = await oauthHandler.startOAuth(url, redirectUriState);
                authenticate({ code, authType, storeKey }, {}, `${integrationConfiguration.displayName} (${storeKey})`);
            } else {
                authenticate(
                    { accessToken: password, authType, storeKey },
                    {},
                    `${integrationConfiguration.displayName} (${storeKey})`,
                );
            }
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-col api-token-authentication">
                <div className="form-group flex-inline">
                    <label className="control-label col-md-3">Authentication Type:</label>

                    <div className="col-sm-8">
                        <RadioGroup value={authType} direction="row" onChange={setAuthType} size={InputSize.MEDIUM}>
                            <Radio value="oauth">OAuth</Radio>
                            <Radio value="password">API Password</Radio>
                        </RadioGroup>
                    </div>
                </div>

                <div className="form-group">
                    <label className="col-sm-3 control-label">Shop Name:</label>
                    <div className="col-sm-8">
                        <div className="flex-vmiddle">
                            <div>https://</div>
                            <div className="flex-grow">
                                <input
                                    type="text"
                                    value={storeKey}
                                    className="form-control"
                                    onChange={({ target }) => setStoreKey(target.value)}
                                    autoComplete="off"
                                    placeholder="Shop Name"
                                    required
                                />
                            </div>
                            <div>.myshopify.com/admin</div>
                        </div>
                        <span className="common-size-xxxs common-color-grey">
                            Your shop name e.g. shopname.myshopify.com/admin
                        </span>
                    </div>
                </div>

                {authType === 'password' && (
                    <div className="form-group">
                        <label className="col-sm-3 control-label">Private App Password</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={password}
                                className="form-control"
                                onChange={({ target }) => setPassword(target.value)}
                                autoComplete="off"
                                placeholder="Password"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                From your Shopify admin, go to{' '}
                                <a href="https://www.shopify.com/admin/apps" target="_blank">
                                    Apps
                                </a>
                                . Click <span className="bold">Manage private apps</span> and generate a new Private App
                                with the required permissions.
                            </span>
                        </div>
                    </div>
                )}

                <div className="flex mod-center margin-top-20">
                    <button className=" btn btn-secondary btn-lg" onClick={startAuthentication} disabled={!storeKey}>
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShopifyComponent;
