import React from 'react';
import styled from 'styled-components';

import FieldChartDataTypeEditorControllers from './FieldChartDataTypeEditorControllers';
import SolutionSiteFieldChartWidgetFieldSelector from './fieldChartWidgetFieldSelectors/SolutionSiteFieldChartWidgetFieldSelector';
import type SolutionSiteFieldChartWidgetConfiguration from './SolutionSiteFieldChartWidgetConfiguration';
import ConfigurationSectionTitle from '../../components/ConfigurationSectionTitle';
import type { SolutionSiteEditorProps } from '../../WidgetModule';
import { useGroupSelectorOptions } from '../InnerItemsWidgetModule/hooks';

import { ChartDataType, SimpleSelect } from '@tonkean/infrastructure';

const Wrapper = styled.div`
    padding: 0 20px 20px;
`;

const FieldsSectionTitle = styled(ConfigurationSectionTitle)`
    margin-bottom: 17px;
`;

const SolutionSiteFieldChartWidgetEditor: React.FC<
    SolutionSiteEditorProps<SolutionSiteFieldChartWidgetConfiguration>
> = ({ configuration }) => {
    const groupSelectorOptions = useGroupSelectorOptions();

    return (
        <>
            <FieldChartDataTypeEditorControllers configuration={configuration} />
            {configuration.chartDataType === ChartDataType.CATEGORICAL && (
                <Wrapper>
                    <FieldsSectionTitle>Module</FieldsSectionTitle>
                    <SimpleSelect name="configuration.groupId" options={groupSelectorOptions} isClearable />
                </Wrapper>
            )}
            <SolutionSiteFieldChartWidgetFieldSelector configuration={configuration} />
        </>
    );
};

export default SolutionSiteFieldChartWidgetEditor;
