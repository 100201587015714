<div class="bot-history-items-container clearfix" id="tnk-bot-history">
    <span
        ng-show="!data.loading"
        class="svg-icon-md pointer pull-right"
        ng-click="loadHistory(true)"
        uib-tooltip="Refresh"
        tooltip-placement="bottom-right"
    >
        <tnk-icon src="/images/icons/refresh.svg"></tnk-icon>
    </span>

    <div ng-if="!data.items.length && !data.loading" class="common-color-grey">Looks empty.</div>
    <div ng-if="(!data.items.length && data.loading) || data.hardLoading" class="flex mod-justify-center">
        <i class="loading-large"></i>
    </div>

    <div
        ng-repeat="section in data.historySections"
        ng-show="section.items.length && !data.hardLoading"
        class="margin-bottom-xlg"
    >
        <div class="flex-vmiddle margin-bottom">
            <div class="common-size-xxxs common-color-light-grey2">{{ section.label }}</div>

            <div class="flex-grow"></div>
            <i ng-if="data.loading" class="loading"></i>
        </div>
        <div ng-repeat="gather in section.items | orderBy:'-created'">
            <tnk-bot-history-item id="gather.id" ui-actions="::botHistoryUiActions"></tnk-bot-history-item>
        </div>
    </div>

    <!-- Load more -->
    <div class="flex mod-justify-center padding-top">
        <button
            ng-if="data.items.length !== data.totalCount && !data.loading"
            ng-click="loadHistory()"
            class="btn btn-slim btn-primary bot-side-pane-block-click-outside"
        >
            Load More
        </button>
        <i ng-if="data.items.length > 0 && data.loading" class="loading-medium"></i>
    </div>

    <!-- Track status summary popover -->
    <tnk-status-popover-wrapper
        editor-id="'tnk-bot-history'"
        container-id-prefix="'bot-history-item-title-'"
        empty-container-id="'tnk-bot-history'"
        item-id="data.popovers.trackStatusSummary"
        initiative-id-retriever="getInitiativeIdFromItemId(itemId)"
        placement="'auto bottom'"
        classes="line-height-none absolute-below padding-top-lg margin-top-minus"
    ></tnk-status-popover-wrapper>
</div>
