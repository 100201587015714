/**
 * Executes polling for the Tonkean system.
 */
function PollingManager(utils) {
    const _this = this;

    /**
     * Executes another polling in the system.
     */
    _this.registerPollingPromise = function (
        pollingAction,
        shouldStopCallingAction,
        onPollingStoppedAction,
        intervalMilliseconds,
        pollingIterationCount,
        isInitialFastPolling = false,
        fastPollingIntervalMilliseconds = 1000,
    ) {
        // Validating we have all needed params.
        if (!pollingAction || !shouldStopCallingAction || !onPollingStoppedAction || !intervalMilliseconds) {
            return;
        }

        // Execute the poll
        executeDelayedPromise(
            pollingAction,
            shouldStopCallingAction,
            onPollingStoppedAction,
            intervalMilliseconds,
            pollingIterationCount || 1,
            isInitialFastPolling,
            fastPollingIntervalMilliseconds
        );
    };

    /**
     * Execute function which has a delay at its end.
     * Once the polling action is done, will check if we should stop polling.
     * If we should not stop, calls registerPollingPromise() again.
     * If we do, calls the onPollingStoppedAction with the result from the latest poll.
     */
    function executeDelayedPromise(
        pollingAction,
        shouldStopPollingAction,
        onPollingStoppedAction,
        intervalMilliseconds,
        pollingIterationCount,
        isInitialFastPolling = false,
        fastPollingIntervalMilliseconds = 1000,
    ) {
        const shouldPollFast = isInitialFastPolling && pollingIterationCount < 10;
        pollingAction()
            // Delay the next action by the given interval.
            .then(utils.angularDelay( shouldPollFast ? fastPollingIntervalMilliseconds : intervalMilliseconds))
            .then((result) => {
                if (!shouldStopPollingAction(result, pollingIterationCount)) {
                    // If we shouldn't stop polling, we register another poll.
                    _this.registerPollingPromise(
                        pollingAction,
                        shouldStopPollingAction,
                        onPollingStoppedAction,
                        intervalMilliseconds,
                        pollingIterationCount + 1,
                        isInitialFastPolling,
                        fastPollingIntervalMilliseconds
                    );
                } else {
                    // Otherwise, we call the onPollingStoppedAction, for the polling has ended.
                    onPollingStoppedAction(result);
                }
            });
    }
}

angular.module('tonkean.app').service('pollingManager', PollingManager);
