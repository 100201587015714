<div>
    <!-- Id Type Selection -->
    <ui-select
        class="margin-bottom"
        ng-model="data.selectedIdType"
        ng-change="onUserIdTypeSelected()"
        theme="bootstrap"
    >
        <ui-select-match placeholder="Match user by">
            {{ data.idTypeDisplayNames[data.selectedIdType] }}
        </ui-select-match>
        <ui-select-choices repeat="idType in data.idTypeOptions | filter:$select.search ">
            <div ng-bind-html="data.idTypeDisplayNames[idType] | escapeHtml | highlight: $select.search"></div>
        </ui-select-choices>
    </ui-select>
</div>
