import { tonkeanCreate } from '../utils/zustand/tonkeanCreate';

const useTonkeanServerConnectivityStore = tonkeanCreate({
    name: 'TonkeanServerConnectivityStore',
    state: {
        tonkeanServerUrl: '',
        accessToken: '',
        projectToken: '',
    },
    actions: (set) => ({
        setTonkeanServerUrl: (tonkeanServerUrl: string) =>
            set((state) => {
                state.tonkeanServerUrl = tonkeanServerUrl;
            }),
        setAccessToken: (accessToken: string) =>
            set((state) => {
                state.accessToken = accessToken;
            }),
        setProjectToken: (projectToken: string) =>
            set((state) => {
                state.projectToken = projectToken;
            }),
    }),
});

export default useTonkeanServerConnectivityStore;
