import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LoadingIcon } from '../../../../../../images/icons/data-sources/collect-now-loader.svg';

import { Theme } from '@tonkean/tui-theme';

const Label = styled.div`
    display: flex;
    align-items: center;
    color: ${Theme.colors.gray_700};
`;

const StyledLoadingCircle = styled(LoadingIcon)`
    width: 10px;
    height: 10px;

    margin-left: 4px;
    animation: spin 1s infinite linear;

    @keyframes spin {
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
    }
`;

interface Props {
    lastUpdatedTime: Date;
}

const ProjectIntegrationCollectStatusInProgress: React.FC<Props> = ({ lastUpdatedTime }) => {
    return (
        <Label>
            A collect is running
            <StyledLoadingCircle viewBox="0 0 8 8" />
        </Label>
    );
};

export default ProjectIntegrationCollectStatusInProgress;
