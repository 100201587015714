import { motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import React from 'react';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    delay?: number;
    onAnimationEnd?: () => void;
    skipAnimation?: boolean;
}

const AppearFromBottomAnimation: React.FC<PropsWithChildren<Props>> = ({
    className,
    children,
    delay = 0.2,
    onAnimationEnd,
    skipAnimation,
}) => {
    if (skipAnimation) {
        onAnimationEnd?.();

        return <div className={className}>{children}</div>;
    }

    return (
        <motion.div
            className={className}
            initial={{
                y: '100%',
                opacity: 0,
            }}
            animate={{
                y: 0,
                opacity: 1,
                transition: {
                    delay,
                    y: {
                        duration: 0.4,
                    },
                    opacity: {
                        duration: 0.25,
                        delay: delay + 0.15,
                    },
                },
            }}
            onAnimationComplete={onAnimationEnd}
        >
            {children}
        </motion.div>
    );
};

export default AppearFromBottomAnimation;
