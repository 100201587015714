import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function EntitySelectorCtrl($scope, $timeout, integrations, utils, integrationMetadataManager) {
    /**
     * General consideration for the component -
     *
     * The app, historically, works with plural forms of entity names for import and sync.
     * The server knows how to handle those when he gets them, BUT we must remember, the plural forms are not the API name
     * of the entity, and in the scope of this new component, we should remember that and set it to be the pluralLabel, even
     * though we consider it to be the "API name" in other places.
     *
     * This will be refactored in the future.
     */

    const ctrl = this;
    let watchesToDismiss;

    $scope.data = {
        // Component parameters
        projectIntegration: ctrl.projectIntegration,
        mode: ctrl.mode,
        isRequired: ctrl.isRequired,
        forceEntitySelectionDisabled: ctrl.forceEntitySelectionDisabled,
        selectedEntity: null,
        setDefaultOnLoad: ctrl.setDefaultOnLoad,
        appendToBody: ctrl.appendToBody,
        forceFetchSupportedEntitiesFromServer: ctrl.forceFetchSupportedEntitiesFromServer,
        shouldNotChooseDefaultEntity: ctrl.shouldNotChooseDefaultEntity,
        ignoreEntities: ctrl.ignoreEntities || [],
        name: ctrl.name,
        showAsError: ctrl.showAsError || false,

        // Mode
        syncMode: ctrl.mode === 'sync',
        importMode: ctrl.mode === 'import',
        fieldsMode: ctrl.mode === 'fields',

        // Others
        loadingEntities: false,
        supportedEntities: [],
        errorFetchingEntities: null,

        tooltipMessage: '',
        showTooltip: false,
        isProjectIntegrationExists: true,

        useReactSelect: ctrl.useReactSelect,
        // Relevant to React component
        allowClear: ctrl.allowClear,
        disabled: ctrl.disabled,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        // Resetting the value of the flag.
        $scope.data.isProjectIntegrationExists = true;

        // If a project integration does not exists, we will display an empty dropdown to the user
        // with a tooltip which requesting from the user to connect the data source first.
        if (!$scope.data.projectIntegration || !$scope.data.projectIntegration.integration) {
            const integrationName = utils.capitalize($scope.data?.projectIntegration?.name) || 'data source';
            $scope.data.tooltipMessage = `Please connect ${integrationName} to select an entity`;
            $scope.data.isProjectIntegrationExists = false;
            return;
        }

        // Assigning the selected entity if given to the component.
        if (ctrl.selectedEntity) {
            $scope.data.selectedEntity = {
                pluralLabel: ctrl.selectedEntityDisplayName ? ctrl.selectedEntityDisplayName : ctrl.selectedEntity,
            };
        }

        // If integration supports all entities, we must get its entities from an API call.
        initializeSupportedEntitiesFromAPI();

        // Generate the options when the list of entity changes
        watchesToDismiss = [
            $scope.$watch('data.supportedEntities', () => {
                $scope.data.options = $scope.data.supportedEntities.map((supportedEntity) => ({
                    value: supportedEntity,
                    label: supportedEntity.label || supportedEntity.pluralLabel,
                }));
            }),

            $scope.$watch('data.selectedEntity', () => {
                $scope.data.selectedEntityOption = $scope.data.selectedEntity
                    ? {
                          value: $scope.data.selectedEntity,
                          label: $scope.data.selectedEntity.label || $scope.data.selectedEntity.pluralLabel,
                      }
                    : null;
            }),
        ];
    };

    ctrl.$onChanges = function (changes) {
        if (changes.projectIntegration && !changes.projectIntegration.isFirstChange()) {
            // if the project integration changed we need to reload the supported entites
            $scope.data.projectIntegration = ctrl.projectIntegration;
            ctrl.$onInit();
        } else if (changes.selectedEntity && !changes.selectedEntity.isFirstChange()) {
            // Cant be on first change because we need to make sure the init runs first as it sets the supported entites
            onSelectedEntityChanged();
        } else if (changes.showAsError) {
            $scope.data.showAsError = ctrl.showAsError;
        }
    };

    /**
     * Updates the tooltip scope property (Boolean).
     * @param isVisible Boolean parameter that indicates whether to display the tooltip or not.
     */
    $scope.updateTooltipVisibility = (isVisible) => {
        $scope.data.showTooltip = isVisible;
    };

    /**
     * Occurs when an entity selection is changed.
     */
    $scope.entitySelectionChange = function (isInit) {
        ctrl.onEntitySelected({
            selectedEntity: $scope.data.selectedEntity,
            isInit,
        });
    };

    /**
     * Occurs when an entity selection is changed. Used by the new react select component.
     */
    $scope.onEntityChange = function (selectedOption) {
        $scope.data.selectedEntity = selectedOption?.value;

        ctrl.onEntitySelected({
            selectedEntity: $scope.data.selectedEntity,
            inInit: true,
        });
    };

    /**
     * Whether to disable the dropdown choice (=option)
     * @returns {*|boolean}
     */
    $scope.shouldDisableChoice = function (choice) {
        // Calling the parent shouldDisableChoice function.
        // If shouldDisableChoice is undefined, we return false.
        return ctrl.shouldDisableChoice({ option: choice }) || false;
    };

    /**
     * Occurs when the user click the Refresh button, when there's an error getting entities from API.
     */
    $scope.refreshEntities = function () {
        initializeSupportedEntitiesFromAPI();
    };

    $scope.$on('$destroy', () => {
        // Unwatch for changes
        watchesToDismiss?.filter((watch) => !!watch).forEach((watchToDismiss) => watchToDismiss());
    });

    /**
     * Returns the supported entities from consts.
     */
    function getConstsSupportedEntities() {
        let constsSupportedEntities;

        if (
            $scope.data.syncMode &&
            integrations.getSyncSettingsByIntegration($scope.data.projectIntegration.integration.integrationType)
        ) {
            constsSupportedEntities = integrations.getSyncSettingsByIntegration(
                $scope.data.projectIntegration.integration.integrationType,
            ).supportedEntities;
        } else if (
            $scope.data.importMode &&
            integrations.getImportSettingsByIntegration($scope.data.projectIntegration.integration.integrationType)
        ) {
            constsSupportedEntities = integrations.getImportSettingsByIntegration(
                $scope.data.projectIntegration.integration.integrationType,
            );
        } else if (
            $scope.data.fieldsMode &&
            integrations.getFieldOptions()[$scope.data.projectIntegration.integration.integrationType.toLowerCase()]
        ) {
            constsSupportedEntities =
                integrations.getFieldOptions()[
                    $scope.data.projectIntegration.integration.integrationType.toLowerCase()
                ];
        }

        // Because the settings of import and sync are using plural names, and the settings of field settings
        // are using singular name, we need the booleans indicating what kind of form we are using,
        // to later on use to search inside the entities returned by the server.
        return {
            supportedEntities: constsSupportedEntities,
            singularForm: $scope.data.fieldsMode,
            pluralForm: !$scope.data.fieldsMode,
        };
    }

    /**
     * If there's only one entity, choosing it.
     */
    function chooseDefaultEntity() {
        // If requested not to choose entity by default, we exist the function.
        if ($scope.data.selectedEntity || $scope.data.shouldNotChooseDefaultEntity) {
            return;
        }

        if (
            $scope.data.supportedEntities &&
            ($scope.data.supportedEntities.length === 1 || $scope.data.setDefaultOnLoad)
        ) {
            $scope.data.selectedEntity = $scope.data.supportedEntities[0];
            $scope.entitySelectionChange(true);
        }
    }

    /**
     * Initializes $scope.data.supportedEntities with data from consts and not from API.
     */
    function initializeSupportedEntitiesFromConsts() {
        const constsSupportedEntities = getConstsSupportedEntities();

        // Modeling the entities from consts as the objects that return from API, so that HTML stays the same.
        // In the consts, the plural form is defined, so we set the term from consts to be the pluralLabel.
        $scope.data.supportedEntities = constsSupportedEntities.supportedEntities.map(function (entity) {
            return {
                entity,
                pluralLabel: entity,
            };
        });

        // Filtering out all of the entities that we have been asked to ignore,

        $scope.data.supportedEntities = $scope.data.supportedEntities.filter(
            (supportedEntity) => !$scope.data.ignoreEntities.includes(supportedEntity.pluralLabel),
        );

        sortSupportedEntities();

        chooseDefaultEntity();
    }

    /**
     * This function sorts the data.supportedEntities array.
     * It displays the entities that being configured in the project integration data collection first
     * and also by alphabetically order.
     */
    function sortSupportedEntities() {
        // Since Data Collection feature (=data retention), the user can decide which entities he would like to collect
        // and for how long to keep the data in Tonkean.
        // we would like to display the entities that he choose to collect first and then displaying the
        // rest of the entities. In addition we also would like to sort the entities alphabetically.

        // Extracting all of the entities that configured as collected under the project integration.
        const dataCollectionEntitiesIds = new Set(
            Object.values(ctrl.projectIntegration?.entitiesExpirationConfiguration?.entitiesConfiguration || {}).map(
                (entity) => entity.id,
            ),
        );

        // Grouping all of the entities that currently being collected.
        const currentlyCollectedEntities = $scope.data.supportedEntities
            .filter((entity) => dataCollectionEntitiesIds.has(entity.entity))
            .sort((a, b) => a.label?.localeCompare(b.label));
        // Grouping all of the entities that are not being collected at the moment.
        const notCollectedEntities = $scope.data.supportedEntities
            .filter((entity) => !dataCollectionEntitiesIds.has(entity.entity))
            .sort((a, b) => a.label?.localeCompare(b.label));
        $scope.data.supportedEntities = currentlyCollectedEntities.concat(notCollectedEntities);
    }

    function onSelectedEntityChanged() {
        if (ctrl.selectedEntity) {
            const foundByEntity = utils.findFirst(
                $scope.data.supportedEntities,
                (supportedEntity) => supportedEntity.entity === ctrl.selectedEntity,
            );
            const foundByDisplayName = utils.findFirst(
                $scope.data.supportedEntities,
                (supportedEntity) => supportedEntity.label === ctrl.selectedEntity,
            );
            const foundByPluralLabel = utils.findFirst(
                $scope.data.supportedEntities,
                (supportedEntity) => supportedEntity.pluralLabel === ctrl.selectedEntity,
            );

            $scope.data.selectedEntity = foundByEntity || foundByDisplayName || foundByPluralLabel;
            if ($scope.data.selectedEntity) {
                $scope.entitySelectionChange(true);
            }
        } else {
            $scope.data.selectedEntity = undefined;
        }
    }

    /**
     * Initializes $scope.data.supportedEntities with data from API.
     */
    function initializeSupportedEntitiesFromAPI() {
        $scope.data.loadingEntities = true;
        integrationMetadataManager
            .getIntegrationEntities($scope.data.projectIntegration.id)
            .then(function (data) {
                $scope.data.errorFetchingEntities = null;
                const constsSupportedEntities = getConstsSupportedEntities();

                if (
                    constsSupportedEntities &&
                    constsSupportedEntities.supportedEntities &&
                    constsSupportedEntities.supportedEntities.length &&
                    data &&
                    data.supportedEntities &&
                    data.supportedEntities.length
                ) {
                    // In order to make it easy on the user, we push the entities defined in consts to the top
                    // of the entity list, because the highest chance is that he would use one of these entities,
                    // so we show the, first.
                    let pushIndex = 0;
                    for (let i = 0; i < data.supportedEntities.length; i++) {
                        for (let j = 0; j < constsSupportedEntities.supportedEntities.length; j++) {
                            if (
                                (constsSupportedEntities.singularForm &&
                                    data.supportedEntities[i].entity ===
                                        constsSupportedEntities.supportedEntities[j]) ||
                                (constsSupportedEntities.pluralForm &&
                                    data.supportedEntities[i].pluralLabel ===
                                        constsSupportedEntities.supportedEntities[j])
                            ) {
                                const temp = data.supportedEntities[pushIndex];
                                data.supportedEntities[pushIndex] = data.supportedEntities[i];
                                data.supportedEntities[i] = temp;

                                pushIndex += 1;
                            }
                        }
                    }
                }

                $timeout(function () {
                    $scope.data.supportedEntities = data.supportedEntities;

                    // Filtering out all of the entities that we have been asked to ignore,
                    $scope.data.supportedEntities = $scope.data.supportedEntities.filter(
                        (supportedEntity) => !$scope.data.ignoreEntities.includes(supportedEntity.entity),
                    );

                    sortSupportedEntities();

                    onSelectedEntityChanged();

                    chooseDefaultEntity();
                });
            })
            .catch(function (error) {
                // If there's an error getting entities from API, we fallback to entities defined in consts.
                if (error && error.data && error.data.error) {
                    $scope.data.errorFetchingEntities = error.data.error.message;
                }

                $scope.data.errorFetchingEntities = 'Unknown error occurred.';
                initializeSupportedEntitiesFromConsts();
            })
            .finally(function () {
                $timeout(function () {
                    $scope.data.loadingEntities = false;
                });
            });
    }
}

angular.module('tonkean.app').controller('EntitySelectorCtrl', lateConstructController(EntitySelectorCtrl));
