// https://github.com/IamAdamJowett/angular-click-outside

import type { IDirectiveFactory, IDocumentService, IParseService, ITimeoutService } from 'angular';
import type { UtilsService } from '@tonkean/shared-services';

/** *
 * A directive that can activate a function/expression when the user clicks outside an element.
 * To use it, simply set click-outside="<expression or function>".
 * This directive has more attributes which can be set:
 * - outside-if-not="id1, id2, class1, class2" - Causes the directive to invoke the click-outside callback only if the click was not initiated in the given ids or classes.
 * - outside-activators="class1, class2" - Only activates outside-click if the given element has one of the activator classes set to it.
 *                                         IMPORTANT: It is always recommended to supply outside activators, to minimize the impact on the whole system.
 *                                         If an element with click-outside exists in the page (its ng-if/ng-show is true), every click will get processed here.
 *                                         Using activators reduces time to a fraction of a millisecond, instead of several milliseconds in full run.
 *                                         You can usually only activate click-outside when the element has 'mod-focused', 'mod-visible', etc., set on it.
 */
const clickOutside: IDirectiveFactory = (
    $document: IDocumentService,
    $parse: IParseService,
    $timeout: ITimeoutService,
    utils: UtilsService,
) => {
    return {
        restrict: 'A',
        link($scope, elem, attr) {
            // postpone linking to next digest to allow for unique id generation
            $timeout(function () {
                const outsideIfNot: string | undefined = attr.outsideIfNot;
                const outsideActivators: string | undefined = attr.outsideActivators;

                const classList = outsideIfNot?.split(',').flatMap((item) => item.trim().split(' ')) || [];
                // add the elements id so it is not counted in the click listening
                if (attr.id !== undefined) {
                    classList.push(attr.id);
                }

                const activators = outsideActivators?.split(',').map((item) => item.trim()) || null;

                function eventHandler(event: MouseEvent | TouchEvent) {
                    // If the user supplied activators classes, we should only run if one of them is set on the item.
                    if (activators) {
                        const activatorFound = activators.some((activator) => elem.hasClass(activator));
                        if (!activatorFound) {
                            return;
                        }
                    }

                    // check if our element already hidden and abort if so
                    if (elem.hasClass('ng-hide')) {
                        return;
                    }

                    // if there is no click target, no point going on
                    if (!event?.target) {
                        return;
                    }

                    // loop through the available elements, looking for classes in the class list that might match and so will eat
                    const path: EventTarget[] | undefined = event['path'] || event.composedPath?.();
                    const shouldntTrigger = path?.some((element: HTMLElement) => {
                        const matchById = classList.includes(element.id);
                        const matchByClassName = classList.some((className) => element.classList?.contains(className));
                        const matchByDataAttribute = element.dataset?.dontTriggerClickOutside !== undefined;

                        return matchById || matchByClassName || matchByDataAttribute;
                    });

                    if (shouldntTrigger) {
                        return;
                    }

                    // if we have got this far, then we are good to go with processing the command passed in via the click-outside attribute
                    utils.safeApply($scope, function () {
                        const func = $parse(attr.clickOutside);
                        func($scope);
                    });
                }

                // if the devices has a touchscreen, listen for this event
                if (_hasTouch()) {
                    document.addEventListener('touchstart', eventHandler, { capture: true });
                }

                // still listen for the click event even if there is touch to cater for touchscreen laptops
                document.addEventListener('click', eventHandler, { capture: true });

                // when the scope is destroyed, clean up the documents event handlers as we don't want it hanging around
                $scope.$on('$destroy', function () {
                    if (_hasTouch()) {
                        document.removeEventListener('touchstart', eventHandler, { capture: true });
                    }

                    document.removeEventListener('click', eventHandler, { capture: true });
                });

                // private function to attempt to figure out if we are on a touch device
                function _hasTouch() {
                    // works on most browsers, IE10/11 and Surface
                    return 'ontouchstart' in window || navigator.maxTouchPoints;
                }
            });
        },
    };
};
export default angular.module('tonkean.app').directive('clickOutside', clickOutside);
