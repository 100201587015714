import React, { useEffect, useMemo, useRef } from 'react';
import type { IGraphViewProps, INode } from 'react-digraph';
import { GraphView } from 'react-digraph';
import styled from 'styled-components';

import type GetViewTransform from './components/SolutionMapperGraph/GetViewTransform';
import graphConfig from './components/SolutionMapperGraph/graphConfig';
import SolutionMapperGraphItem from './components/SolutionMapperGraph/SolutionMapperGraphItem';
import type { SelectedEntity } from './hooks';

import type { OperationEdge, OperationNode } from '@tonkean/tonkean-entities';
import { OperationEntityType } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div<{ inert?: string | null }>`
    height: 100%;
    width: 100%;

    * {
        ${styledFocus}
    }

    .view-wrapper .edge {
        // Reset so it won't affect the edge icon
        stroke: transparent;
        stroke-width: 1;
        marker-end: none;

        use {
            // Default fill for elements with no fill inside the use.
            fill: transparent;
        }

        .edge-path {
            stroke: #979797;
            stroke-width: 2px;
            marker-end: url(#arrow-ending);
        }

        .selected-border {
            fill: transparent;
        }

        .edge-text {
            font-size: ${FontSize.SMALL_12};
            line-height: 14px;
            stroke: #f9f9f7;
            stroke-width: 3px;
            fill: #717171;
            paint-order: stroke;
            stroke-linecap: butt;
            stroke-linejoin: miter;
        }

        &.selected {
            use {
                // Default fill for elements with no fill inside the use.
                fill: #3800ae;
                stroke: transparent;
            }

            .selected-border {
                fill: #3800ae;
            }

            .edge-text {
                fill: #5558af;
                stroke: #f9f9f7;
            }
        }
    }

    .view-wrapper .graph-controls {
        .slider-wrapper {
            > span {
                display: none;
            }

            .slider {
                margin: 0;
            }
        }

        .slider-button svg {
            vertical-align: middle;
        }
    }
`;
// Because this is a very controlled component Pick out the specific props we want our components users to be able to
// control instead of Omit the ones we dont want
export interface Props extends Pick<IGraphViewProps, 'showGraphControls' | 'minZoom' | 'zoomDur' | 'zoomDelay'> {
    isEditingEnabled: boolean;
    nodes: OperationNode[];
    edges: OperationEdge[];
    loadingEdges?: any[];
    selectedEntity?: SelectedEntity | undefined;
    onSelectedEntity?: (type: OperationEntityType, id: string | undefined) => void;
    createEdge?: (source: string, target: string) => void;
    moveNode?: (node: OperationNode, x: number, y: number) => void;
    setGetViewTransform?: React.Dispatch<React.SetStateAction<GetViewTransform>>;
    showConfigurationIcons?: boolean;
    shouldInert?: boolean;
}

const SolutionMapperGraphView: React.FC<Props> = ({
    isEditingEnabled,
    nodes,
    edges,
    loadingEdges,
    selectedEntity: selectedEntityProp,
    onSelectedEntity,
    createEdge,
    moveNode,
    setGetViewTransform,
    showConfigurationIcons = false,
    shouldInert = false,
    ...props
}) => {
    const graphRef = useRef<React.Component<IGraphViewProps, any, any>>(null);

    /**
     * Send to
     `setGetViewTransform`
     a function that returns the current zoom level of the graph.
     *
     * This works because the graph is a class component, and refs of class components returns the instance of the
     * class. With that we can access the state of the component, and get the viewTransform, which is the zoom. Because
     * we can't subscribe to state updates from the outside, we return a function that will use the ref to get the
     * latest state value when called.
     */
    useEffect(() => {
        const currentGraphRef = graphRef.current;
        if (!currentGraphRef) {
            return;
        }

        const getViewTransform: GetViewTransform = () => currentGraphRef.state.viewTransform;
        setGetViewTransform?.(() => getViewTransform);
    }, [setGetViewTransform]);

    // We need this because the graph mutates the objects, so we clone the elements to prevent it from mutating the
    // real entities.
    const graphNodes = useMemo(() => {
        return nodes.map((node) => ({ ...node, type: 'defaultNode' }));
    }, [nodes]);

    const graphEdges = useMemo(() => {
        return [
            ...edges.map((edge) => ({
                ...edge,
                type: 'defaultEdge',
                handleText: <tspan y={-25}>{edge.operationConfiguration.displayName}</tspan>,
            })),
            ...(loadingEdges || []),
        ];
    }, [edges, loadingEdges]);

    // We can't just use selectedEntityProp?.entity because those are different objects and react digraph compares
    // by ref.
    const selectedEntity = useMemo(() => {
        switch (selectedEntityProp?.type) {
            case OperationEntityType.EDGE:
                return graphEdges.find((edge) => edge.id === selectedEntityProp?.entity.id);
            case OperationEntityType.NODE:
                return graphNodes.find((node) => node.id === selectedEntityProp?.entity.id);
        }
    }, [graphEdges, graphNodes, selectedEntityProp?.entity.id, selectedEntityProp?.type]);

    return (
        <Wrapper inert={shouldInert ? '' : null}>
            <GraphView
                {...props}
                nodeKey="id"
                nodes={graphNodes}
                edges={graphEdges}
                selected={selectedEntity}
                renderNodeText={(data: OperationNode, id: string | number, isSelected: boolean) => {
                    return (
                        <SolutionMapperGraphItem
                            node={data}
                            isSelected={isSelected}
                            showConfigurationIcons={showConfigurationIcons}
                        />
                    );
                }}
                renderBackground={() => {
                    return <rect className="background" x={0} y={0} width="100%" height="100%" fill="#f9f9f7" />;
                }}
                renderDefs={() => {
                    return (
                        <marker
                            id="arrow-ending"
                            viewBox="0 0 11 16"
                            refX="2"
                            refY="8"
                            markerWidth="6"
                            markerHeight="8"
                            orient="auto"
                        >
                            <path
                                d="M9.77522 8.72109C10.1664 8.33119 10.1674 7.69802 9.77747 7.30688L3.42366 0.932784C3.03375 0.541638 2.40059 0.540629 2.00944 0.930531C1.6183 1.32043 1.61729 1.9536 2.00719 2.34474L7.65503 8.0106L1.98917 13.6584C1.59802 14.0483 1.59701 14.6815 1.98691 15.0727C2.37681 15.4638 3.00998 15.4648 3.40112 15.0749L9.77522 8.72109ZM0.998407 9L9.06764 9.01286L9.07083 7.01286L1.00159 7L0.998407 9Z"
                                fill="#979797"
                            />
                        </marker>
                    );
                }}
                nodeTypes={graphConfig.nodeTypes}
                nodeSubtypes={graphConfig.nodeSubtypes}
                edgeTypes={graphConfig.edgeTypes}
                onSelectNode={(node) => onSelectedEntity?.(OperationEntityType.NODE, node?.id)}
                onSelectEdge={(edge) => {
                    if (edge?.type !== 'loadingEdge') {
                        onSelectedEntity?.(OperationEntityType.EDGE, edge?.id);
                    }
                }}
                onCreateEdge={(source, target) => createEdge?.(source.id, target.id)}
                onUpdateNode={(node: OperationNode & INode) => moveNode?.(node, node.x, node.y)}
                canDeleteNode={() => false}
                canSwapEdge={() => false}
                readOnly={!isEditingEnabled}
                ref={graphRef}
            />
        </Wrapper>
    );
};

export default SolutionMapperGraphView;
