import CreateEntityFromMarketplaceItemJsonInstallation from './CreateEntityFromMarketplaceItemJsonInstallation';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkCreateEntityFromMarketplaceItemJsonInstallation',
        reactToAngular(CreateEntityFromMarketplaceItemJsonInstallation, []),
    );
