import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Field, H4, Paragraph } from '@tonkean/infrastructure';
import type { ResponseHandlingValidationStatus, TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const CustomLabel = styled(H4)`
    color: ${Theme.colors.gray_800};
`;

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

const ExplanationParagraph = styled(Paragraph)`
    margin-top: 4px;
`;

interface Props {
    namePrefix: string;
    expressionProps: FormikExpressionProps;
    labelPrefix: string;
    responseHandlingDefinitionType: EntityResponseHandlingDefinitionType;
}

const ProjectIntegrationEntityHandleResponseAdditionalFieldsView: React.FC<Props> = ({
    namePrefix,
    expressionProps,
    labelPrefix,
    responseHandlingDefinitionType,
}) => {
    const [, { error: idError }] = useField<TonkeanExpressionDefinition>(`${namePrefix}.id`);
    const [, { error: displayNameError }] = useField<TonkeanExpressionDefinition>(`${namePrefix}.displayName`);
    const [, { value: validationStatus }] = useField<ResponseHandlingValidationStatus>(
        `${namePrefix}.validationStatus`,
    );

    function renderAdditionalFields(responseHandlingDefinition) {
        switch (responseHandlingDefinition) {
            case EntityResponseHandlingDefinitionType.METADATA_FIELDS:
                return (
                    <StyledField
                        error={displayNameError ? 'Invalid Display Name' : undefined}
                        label={<CustomLabel $bold $inline>{`${labelPrefix} Display Name`}</CustomLabel>}
                        showOptional
                    >
                        <FormikTonkeanExpression
                            {...expressionProps}
                            placeholder="Extract Display Name from the response body"
                            name={`${namePrefix}.displayName`}
                        />
                    </StyledField>
                );

            case EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES:
                return (
                    <>
                        <StyledField
                            error={displayNameError ? 'Invalid Display Name' : undefined}
                            label={<CustomLabel $inline>{`${labelPrefix} Display Name`}</CustomLabel>}
                            showOptional
                        >
                            <FormikTonkeanExpression
                                {...expressionProps}
                                placeholder="Extract Display Name from the response body"
                                name={`${namePrefix}.displayName`}
                            />
                        </StyledField>

                        <StyledField
                            label={<CustomLabel $inline>{`${labelPrefix} Created Time`}</CustomLabel>}
                            showOptional
                        >
                            <FormikTonkeanExpression
                                {...expressionProps}
                                placeholder="Extract Created Time from the response body"
                                name={`${namePrefix}.createdDate`}
                            />
                        </StyledField>

                        <StyledField
                            label={<CustomLabel $inline>{`${labelPrefix} Updated Time`}</CustomLabel>}
                            showOptional
                        >
                            <FormikTonkeanExpression
                                {...expressionProps}
                                placeholder="Extract Updated Time from the response body"
                                name={`${namePrefix}.updatedDate`}
                            />
                        </StyledField>

                        <Field label={<CustomLabel $inline>{`${labelPrefix} URL`}</CustomLabel>} showOptional>
                            <FormikTonkeanExpression
                                {...expressionProps}
                                placeholder="Extract URL from the response body"
                                name={`${namePrefix}.entityUrl`}
                            />
                        </Field>
                    </>
                );
        }
    }

    return (
        <>
            {validationStatus.isValid && (
                <>
                    <StyledField
                        error={idError ? 'Invalid Id' : undefined}
                        label={
                            <CustomLabel>
                                {`${labelPrefix} Unique Identifier`}
                                <ExplanationParagraph $light $small>
                                    Use the extracted fields in order to extract unique identifier.
                                </ExplanationParagraph>
                            </CustomLabel>
                        }
                    >
                        <FormikTonkeanExpression
                            {...expressionProps}
                            placeholder="Extract Id from the response body"
                            name={`${namePrefix}.id`}
                        />
                    </StyledField>

                    {renderAdditionalFields(responseHandlingDefinitionType)}
                </>
            )}
        </>
    );
};

export default ProjectIntegrationEntityHandleResponseAdditionalFieldsView;
