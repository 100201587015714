import React from 'react';
import styled from 'styled-components';

import { ReactComponent as BusinessReportIcon } from '../../../images/icons/business-report-empty.svg';

import { ClickableLink, LinkUnderline } from '@tonkean/tui-buttons/Link';

const InitiativesEmptyStateContainer = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
`;

const InitiativesEmptyStateInner = styled.div`
    width: 518px;
    height: 380px;
    text-align: center;
`;

const InitiativesEmptyState: React.FC = () => {
    return (
        <InitiativesEmptyStateContainer>
            <InitiativesEmptyStateInner>
                <BusinessReportIcon />
                <p className="common-size common-text-color-light-grey">No business reports to display</p>
                <p className="common-size-xxxs common-color-primary-black">
                    You haven’t created a module on this board yet, so there are no business reports to display.
                </p>
                <ClickableLink className="common-size-xs" state="product.workers" underline={LinkUnderline.ALWAYS}>
                    Visit the Solutions Studio to create a module.
                </ClickableLink>
            </InitiativesEmptyStateInner>
        </InitiativesEmptyStateContainer>
    );
};

export default InitiativesEmptyState;
