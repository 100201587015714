/* jshint ignore:start */

import React from 'react';
import DefaultInboxReason from './DefaultInboxReason';

export default class CustomTriggerInboxReason extends DefaultInboxReason {
    constructor(props) {
        super(props);
    }

    /**
     * The main render function
     */
    render() {
        const item = this.props.item;
        const initiative = this.props.initiative;

        let triggersNames = null;
        let message = 'Would you like to take any follow-up actions?';
        if (item.reasonMetadata) {
            const metadata = item.reasonMetadata;
            if (metadata.triggersNames && metadata.triggersNames.length) {
                triggersNames = metadata.triggersNames.join('", "');
            }

            if (metadata.customText) {
                message = metadata.customText;
            }
        }

        return (
            <div>
                <div>
                    {this.renderTitleLinkElement(initiative)} was triggered
                    {triggersNames ? `for "${triggersNames}"` : null}
                </div>
                <div>{message}</div>
            </div>
        );
    }
}
/* jshint ignore:end */
