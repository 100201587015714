import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MultiFormSelectionCtrl($scope, projectManager) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        forms: ctrl.existingForms ? ctrl.existingForms : [],
        menuOpenIndex: -1,
        selectedFormIdsSet: {},
        hideFileUploadForms: ctrl.hideFileUploadForms,
        hideCreateForms: ctrl.hideCreateForms,
    };

    ctrl.$onInit = function () {
        initializeSelectedFormIdsSet();
    };

    /**
     * Occurs once you select a form.
     */
    $scope.onFormSelected = function (selectedForm, index) {
        $scope.data.forms[index] = selectedForm;
        initializeSelectedFormIdsSet();
        $scope.onFormsChange();
    };

    /**
     * Occurs once you remove a form.
     */
    $scope.removeForm = function (index) {
        $scope.data.forms.splice(index, 1);
        initializeSelectedFormIdsSet();
        $scope.onFormsChange();
    };

    /**
     * Adds a new form.
     */
    $scope.addForm = function () {
        $scope.data.forms.push({});
    };

    /**
     * Occurs on forms change.
     */
    $scope.onFormsChange = function () {
        if (ctrl.onFormsChange) {
            ctrl.onFormsChange({ forms: $scope.data.forms });
        }
    };

    $scope.toggleMenu = function ($index) {
        $scope.data.menuOpenIndex = $scope.data.menuOpenIndex === $index ? -1 : $index;
    };

    /**
     * Initializes the set of selected forms.
     */
    function initializeSelectedFormIdsSet() {
        $scope.data.selectedFormIdsSet = {};

        if ($scope.data.forms && $scope.data.forms.length) {
            for (let i = 0; i < $scope.data.forms.length; i++) {
                const form = $scope.data.forms[i];

                if (form) {
                    $scope.data.selectedFormIdsSet[form.id] = true;
                }
            }
        }

        if (!$scope.data.forms?.length) {
            $scope.addForm();
        }
    }
}

export default angular.module('tonkean.app').controller('multiFormSelectionCtrl', lateConstructController(MultiFormSelectionCtrl));
