import React, { useMemo } from 'react';

import defaultTonkeanExpression from '../../../../../../../FormBuilder/entities/DefaultTonkeanExpression';
import type ActionOutput from '../entities/ActionOutput';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ModalFooter } from '@tonkean/infrastructure';
import type {
    CustomActionDefinition,
    ProjectIntegration,
    ProjectIntegrationActionParametersDefinition,
    ProjectIntegrationActionResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import { ActionParameterType, ActionType, EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';

interface Props {
    onCreate(id: string): void;
    onCancel: () => void;
    projectIntegration: ProjectIntegration;
    actionOutput: ActionOutput | undefined;
    isNameValid: boolean;
    name: string;
    description: string;
    defaultActionDefinition: CustomActionDefinition;
    dependentProjectIntegrationEntityIds?: string[];
}

const ProjectIntegrationActionCreateModalFooter: React.FC<Props> = ({
    onCreate,
    onCancel,
    projectIntegration,
    actionOutput,
    isNameValid,
    name,
    description,
    defaultActionDefinition,
    dependentProjectIntegrationEntityIds,
}) => {
    const [{ loading: isCreateActionLoading, error: isCreateActionError }, createProjectIntegrationAction] =
        useLazyTonkeanService('createProjectIntegrationAction');

    const selectedActionType = actionOutput?.type || ActionType.CREATE;
    const actionTypeWithOnlyIdLinkedField = useMemo(() => {
        return new Set([ActionType.CREATE, ActionType.CREATE_OR_UPDATE]);
    }, []);

    const defaultHandleResponse: ProjectIntegrationActionResponseHandlingDefinition = useMemo(() => {
        return {
            responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
            successDefinition: {
                outputLinkedFields: actionTypeWithOnlyIdLinkedField.has(selectedActionType)
                    ? [
                          {
                              value: {
                                  evaluatedExpression: '',
                                  originalExpression: '',
                                  parameterType: ActionParameterType.TONKEAN_EXPRESSION,
                                  isStripHtmlDisabled: true,
                              },
                              name: `${actionOutput?.entity?.pluralLabel} Id`,
                              containsEntityId: true,
                          },
                      ]
                    : [],
            },
            failureDefinition: { bodyContainsConditions: [] },
        };
    }, [actionOutput?.entity?.pluralLabel, actionTypeWithOnlyIdLinkedField, selectedActionType]);

    const defaultHandleEntityResponse: ProjectIntegrationActionResponseHandlingDefinition = useMemo(() => {
        return {
            responseHandlingType: EntityResponseHandlingDefinitionType.DATA_SOURCE_EXTERNAL_ACTIVITIES,
            entity: { ...defaultTonkeanExpression },
            entityFlatteningPath: { ...defaultTonkeanExpression },
            id: { ...defaultTonkeanExpression },
            displayName: { ...defaultTonkeanExpression },
            updatedDate: { ...defaultTonkeanExpression },
            createdDate: { ...defaultTonkeanExpression },
            entityUrl: { ...defaultTonkeanExpression },
            validationStatus: {
                outputFields: [],
                isValid: false,
            },
            fetcherActionId: '',
            subEntities: [],
            parametersMap: {},
        };
    }, []);

    const uploadHandleResponse: ProjectIntegrationActionResponseHandlingDefinition = useMemo(() => {
        return {
            responseHandlingType: EntityResponseHandlingDefinitionType.UPLOAD_FILE_INFORMATION,
            uploadFileId: {
                originalExpression: '',
                evaluatedExpression: '',
                isStripHtmlDisabled: true,
                parameterType: ActionParameterType.TONKEAN_EXPRESSION,
            },
            uploadFileName: {
                originalExpression: '',
                evaluatedExpression: '',
                isStripHtmlDisabled: true,
                parameterType: ActionParameterType.TONKEAN_EXPRESSION,
            },
            originalFileName: {
                originalExpression: '',
                evaluatedExpression: '',
                isStripHtmlDisabled: true,
                parameterType: ActionParameterType.TONKEAN_EXPRESSION,
            },
        };
    }, []);

    const HandleResponse: ProjectIntegrationActionResponseHandlingDefinition = useMemo(() => {
        switch (selectedActionType) {
            case ActionType.CREATE:
            case ActionType.CREATE_OR_UPDATE:
            case ActionType.UPDATE:
            case ActionType.CUSTOM_AUTHENTICATION:
            case ActionType.OAUTH2_REFRESH_AUTHENTICATION:
            case ActionType.CUSTOM:
            case ActionType.METADATA:
            case ActionType.DOWNLOAD:
                return defaultHandleResponse;
            case ActionType.FETCHING:
                return defaultHandleEntityResponse;
            case ActionType.UPLOAD:
                return uploadHandleResponse;
            case ActionType.STREAM_IMAGE:
                return defaultHandleResponse;
        }
    }, [defaultHandleEntityResponse, defaultHandleResponse, selectedActionType, uploadHandleResponse]);

    const onCreateClick = async () => {
        const parametersDefinition: ProjectIntegrationActionParametersDefinition = {
            entitiesToRunOn: actionOutput?.entity ? [actionOutput.entity.entity] : [],
            parameters: [],
            isChildEntitiesEnabled: false,
            isCustomFieldsEnabled: true,
            childEntitiesParameterDefinitions: [],
        };

        const createdProjectIntegrationAction = await createProjectIntegrationAction(
            projectIntegration.id,
            name,
            description,
            defaultActionDefinition,
            parametersDefinition,
            selectedActionType,
            HandleResponse,
            dependentProjectIntegrationEntityIds,
        );

        onCreate(createdProjectIntegrationAction.id);
    };

    return (
        <ModalFooter align="right" gap={10} border>
            {(isCreateActionError || !isNameValid) && <div className="common-error flex-grow">Cannot save action</div>}
            {isCreateActionLoading && <div className="loading" />}

            <Button
                onClick={() => onCancel()}
                data-automation="project-integration-action-create-modal-footer-cancel-button"
                cancel
            >
                Cancel
            </Button>
            <Button
                onClick={onCreateClick}
                data-automation="project-integration-action-create-modal-footer-create-button"
                disabled={
                    !isNameValid ||
                    (!actionOutput?.entity &&
                        actionOutput?.type !== ActionType.CUSTOM &&
                        actionOutput?.type !== ActionType.UPLOAD &&
                        actionOutput?.type !== ActionType.DOWNLOAD &&
                        actionOutput?.type !== ActionType.STREAM_IMAGE)
                }
            >
                Create
            </Button>
        </ModalFooter>
    );
};

export default ProjectIntegrationActionCreateModalFooter;
