import template from './tnkThreadReply.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkThreadReply', {
    bindings: {
        workflowVersionId: '@',
        onToggled: '&',
        configuredLogic: '<',
        definition: '<',
        monitorRepliesOnOriginalMessage: '=',
        groupId: '<',
    },
    template,
    controller: 'ThreadReplyCtrl',
});
