import type NetworkService from '../NetworkService';

import type { ContractSummary, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const URLS = {
    getContractsByProjectId: (projectId: TonkeanId<TonkeanType.PROJECT>) => `${projectId}/contracts`,
};

async function getContractsByProjectId(
    networkService: NetworkService,
    projectId: TonkeanId<TonkeanType.PROJECT>,
): Promise<{ projectId: TonkeanId<TonkeanType.PROJECT>; entities: ContractSummary[] }> {
    return networkService
        .getRequest<{ entities: ContractSummary[] }>({
            url: URLS.getContractsByProjectId(projectId),
        })
        .then((response) => ({ projectId, entities: response.entities }));
}

export { getContractsByProjectId };
