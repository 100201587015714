import FunctionOperatorBase from './FunctionOperatorBase';

import { logicBlockTypes } from '@tonkean/logic-block-configs';
import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

const outgoingWebhookType = logicBlockTypes.OUTGOING_WEBHOOK.type;

export default class DecryptFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.DECRYPT;
    override readonly sign = 'Decrypt';
    override readonly displayName = 'Decrypt';
    override readonly description = 'Will return the decrypted value of the given encrypted field.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Encrypted Field',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];

    override isAllowed({ customTrigger, projectIntegration }): boolean {
        if (customTrigger) {
            const { performedActionType, integrationType } =
                customTrigger.customTriggerActions?.[0]?.customTriggerActionDefinition || {};

            const isCustomDataSourceCustomHttpAction =
                performedActionType === 'GENERIC_INTEGRATION_ACTION' && integrationType === 'WEBHOOK';

            const isCustomHttpAction = customTrigger.customTriggerType === outgoingWebhookType;

            return isCustomDataSourceCustomHttpAction || isCustomHttpAction;
        }

        return !!projectIntegration;
    }
}
