import { ProjectIntegrationPaginatedActionConstantParams } from './projectIntegrationPaginatedActionConstantParams';

import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { ActionDefinitionType, ActionParameterType, ActionType } from '@tonkean/tonkean-entities';

export enum ProjectIntegrationActionConstantParams {
    ENV_URL = 'env_url',
    WEBHOOK_URL = 'webhook_url',
    FILE_ID = 'file_id',
    FILE_STREAM = 'file_stream',
    FILE_NAME = 'tonkean_file_name',
    MIME_TYPE = 'tonkean_mime_type',
    TODAY = 'today',
    CHILD_ENTITIES = 'child_entities',
    CUSTOM_FIELDS = 'custom_fields',
    INPUT_OBJECT = 'input_object',

    IMAGE_URL = 'image_url',
    IMAGE_ITEM_ID = 'image_item_id',
    IMAGE_ETAG = 'image_etag',
}

export interface ConstantActionConfig {
    id: ProjectIntegrationActionConstantParams | ProjectIntegrationPaginatedActionConstantParams;
    displayName: string;
    emptyValueTooltip: string;
    placeholder: string;
    description: string;
    definitionTypes: ActionDefinitionType[];
    editableInTest: boolean;
    parameterType: ActionParameterType;
    excludeInActionTypes: ActionType[];
    defaultOptions: SimpleSelectSingleOption<any>[];
}

const projectIntegrationActionConstantParamsIdToConfig: Record<
    ProjectIntegrationActionConstantParams,
    ConstantActionConfig
> = {
    [ProjectIntegrationActionConstantParams.ENV_URL]: {
        id: ProjectIntegrationActionConstantParams.ENV_URL,
        displayName: 'Env URL',
        emptyValueTooltip: `To configure this param go to “Connections” tab and edit the Data Source’s “Outgoing Configuration” Base URL`,
        placeholder: 'Preview of the URL...',
        description: 'Auto populate with the environment base url, based on the instance configuration',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: false,
        excludeInActionTypes: [],
    },
    [ProjectIntegrationActionConstantParams.WEBHOOK_URL]: {
        id: ProjectIntegrationActionConstantParams.WEBHOOK_URL,
        displayName: 'Webhook URL',
        emptyValueTooltip: `URL in order to send to tonkean internal webhooks`,
        placeholder: 'Preview of the URL...',
        description: 'Auto populate with tonkean webhook url',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: false,
        excludeInActionTypes: [ActionType.STREAM_IMAGE],
    },
    [ProjectIntegrationActionConstantParams.TODAY]: {
        id: ProjectIntegrationActionConstantParams.TODAY,
        displayName: 'Today',
        emptyValueTooltip: `Today date`,
        placeholder: '',
        description: 'Auto populate with today date',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: false,
        excludeInActionTypes: [ActionType.STREAM_IMAGE],
    },
    [ProjectIntegrationActionConstantParams.FILE_ID]: {
        id: ProjectIntegrationActionConstantParams.FILE_ID,
        displayName: 'File Id',
        emptyValueTooltip: `File id to identify the file to download`,
        placeholder: 'Preview of the file id',
        description: 'Auto populate with file id from module',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: false,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.UPLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.STREAM_IMAGE,
        ],
    },
    [ProjectIntegrationActionConstantParams.FILE_STREAM]: {
        id: ProjectIntegrationActionConstantParams.FILE_STREAM,
        displayName: 'File to upload',
        emptyValueTooltip: `File stream that will be uploaded.`,
        placeholder: 'Preview of the file input',
        description: 'Auto populate with file stream from file source',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: false,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.FETCHING,
            ActionType.METADATA,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.STREAM_IMAGE,
        ],
    },
    [ProjectIntegrationActionConstantParams.FILE_NAME]: {
        id: ProjectIntegrationActionConstantParams.FILE_NAME,
        displayName: 'File Name',
        emptyValueTooltip: `File name that will be uploaded.`,
        placeholder: 'Preview of the file name',
        description: 'Auto populate with file name',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: false,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.STREAM_IMAGE,
        ],
    },
    [ProjectIntegrationActionConstantParams.MIME_TYPE]: {
        id: ProjectIntegrationActionConstantParams.MIME_TYPE,
        displayName: 'Mime Type',
        emptyValueTooltip: `File mime type that will be uploaded.`,
        placeholder: 'Preview of the file mime type',
        description: 'Auto populate with file mime type',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: false,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.STREAM_IMAGE,
        ],
    },
    [ProjectIntegrationActionConstantParams.IMAGE_URL]: {
        id: ProjectIntegrationActionConstantParams.IMAGE_URL,
        displayName: 'Image URL',
        emptyValueTooltip: `Private Image URL to be Streamed`,
        placeholder: 'Preview of the URL...',
        description: 'Auto populate with the image URL',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: true,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.UPLOAD,
        ],
    },
    [ProjectIntegrationActionConstantParams.IMAGE_ITEM_ID]: {
        id: ProjectIntegrationActionConstantParams.IMAGE_ITEM_ID,
        displayName: 'Image Item ID',
        emptyValueTooltip: `Private Image Item ID to be Streamed`,
        placeholder: 'Preview of the Image Item ID...',
        description: 'Auto populate with the image item ID',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: true,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.UPLOAD,
        ],
    },
    [ProjectIntegrationActionConstantParams.IMAGE_ETAG]: {
        id: ProjectIntegrationActionConstantParams.IMAGE_ETAG,
        displayName: 'Image Item Etag',
        emptyValueTooltip: `Private Image Item Etag `,
        placeholder: 'Preview of the Image Etag...',
        description: 'Auto populate with the image Etag',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: true,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.UPLOAD,
        ],
    },
    [ProjectIntegrationActionConstantParams.CHILD_ENTITIES]: {
        id: ProjectIntegrationActionConstantParams.CHILD_ENTITIES,
        displayName: 'Child Entities',
        emptyValueTooltip: `Child Entities`,
        placeholder: 'Child Entities...',
        description: 'Child Entities',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: true,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.UPLOAD,
        ],
    },
    [ProjectIntegrationActionConstantParams.CUSTOM_FIELDS]: {
        id: ProjectIntegrationActionConstantParams.CUSTOM_FIELDS,
        displayName: 'Custom Fields',
        emptyValueTooltip: `Custom Fields`,
        placeholder: 'Custom Fields...',
        description: 'Custom Fields',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: true,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.UPLOAD,
        ],
    },
    [ProjectIntegrationActionConstantParams.INPUT_OBJECT]: {
        id: ProjectIntegrationActionConstantParams.INPUT_OBJECT,
        displayName: 'Input Object',
        emptyValueTooltip: `Input Object`,
        placeholder: 'Input Object...',
        description: 'Input Object',
        definitionTypes: [ActionDefinitionType.HTTP],
        parameterType: ActionParameterType.TONKEAN_EXPRESSION,
        defaultOptions: [],
        editableInTest: true,
        excludeInActionTypes: [
            ActionType.CREATE_OR_UPDATE,
            ActionType.DOWNLOAD,
            ActionType.CUSTOM,
            ActionType.CREATE,
            ActionType.UPDATE,
            ActionType.METADATA,
            ActionType.FETCHING,
            ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            ActionType.CUSTOM_AUTHENTICATION,
            ActionType.UPLOAD,
        ],
    },
};

export default projectIntegrationActionConstantParamsIdToConfig;
