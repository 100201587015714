import React from 'react';
import styled from 'styled-components';

import type ExternalNavigablePage from '../../entities/ExternalNavigablePage';
import type InternalNavigablePage from '../../entities/InternalNavigablePage';

import { Placeholder, TextEllipsis } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';

const CurrentPageIcon = styled.div`
    display: flex;
    margin-right: 8px;
`;

const CurrentPageTitle = styled.h1`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    font-family: Lato;
    font-size: ${FontSize.LARGE_16};
    color: ${Theme.colors.gray_700};
`;

interface Props {
    currentPage: InternalNavigablePage | ExternalNavigablePage | undefined;
}

const NavigationMenuCurrentPage: React.FC<Props> = ({ currentPage }) => {
    return (
        <CurrentPageTitle>
            {currentPage ? (
                <>
                    {currentPage.icon && (
                        <CurrentPageIcon>
                            <currentPage.icon />
                        </CurrentPageIcon>
                    )}
                    <TextEllipsis numberOfLines={1}>{currentPage.name}</TextEllipsis>
                </>
            ) : (
                <>
                    <Placeholder className="margin-right-xs" $width="30px" $circle />
                    <Placeholder $width="100px" $height="20px" />
                </>
            )}
        </CurrentPageTitle>
    );
};

export default NavigationMenuCurrentPage;
