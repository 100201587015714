import lateConstructController from '../../utils/lateConstructController';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function EditDropdownOptionsPopoverCtrl($scope, $rootScope, $timeout) {
    const ctrl = this;
    $scope.pm = $rootScope.pm;

    // Dropdown data
    $scope.data = {
        isMulti: ctrl.dropdownConfig.isMultiValueField,
        isCreatable: ctrl.dropdownConfig.isCreatable,
        onSave: ctrl.dropdownConfig.onSave,
        // Standardize the options to what tnk-select expects to get as input
        selected: ctrl.dropdownConfig.selectedValues?.map((value) => ({
            value,
            label: value,
        })),
        options: ctrl.dropdownConfig.possibleValues?.map((value) => ({
            value,
            label: value,
        })),
        fieldDefinitionId: ctrl.fieldDefinitionId,
        initiativeId: ctrl.initiativeId,
        dropdownSource: ctrl.dropdownSource,
    };

    $scope.updateOptions = function (options) {
        analyticsWrapper.track('Update Dropdown Options', { category: 'Initiative view' });

        $scope.data.selected = options ? options : [];

        // Close popover after a single value is chosen
        if (!$scope.data.isMulti) {
            $scope.saveOptions();
        }
    };

    $scope.saveOptions = function () {
        $timeout(() => {
            // This is to prevent a digest loop when saving options.
            let valuesToUpdate;
            if ($scope.data.isMulti) {
                valuesToUpdate = $scope.data.selected.map((item) => item.value);
            } else {
                // Try to extract value from an array of selected values, or a single chosen value
                valuesToUpdate = $scope.data.selected[0]?.value || $scope.data.selected?.value;
            }
            $scope.data.onSave(valuesToUpdate);
            $scope.close();
        }, 0);
    };

    $scope.close = function () {
        if (ctrl.onClose) {
            ctrl.onClose();
        }
    };
}

angular
    .module('tonkean.app')
    .controller('EditDropdownOptionsPopoverCtrl', lateConstructController(EditDropdownOptionsPopoverCtrl));
