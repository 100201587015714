import { useMemo } from 'react';

import type { UploadWidgetConfiguration } from '../../entities';
import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';

import type { FieldDefinitionKey, ItemInterfaceWidget } from '@tonkean/tonkean-entities';

const useFieldsToShow = (widget: ItemInterfaceWidget<LineItemsWidgetConfiguration | UploadWidgetConfiguration>) => {
    return useMemo(() => {
        return Object.entries(widget.configuration.fields)
            .filter(([, field]) => field.isShown)
            .sort(([, fieldA], [, fieldB]) => {
                const itemAIndex = Number(fieldA?.index) ?? 0;
                const itemBIndex = Number(fieldB?.index) ?? 0;

                return itemAIndex - itemBIndex;
            })
            .map(([key]) => key as FieldDefinitionKey);
    }, [widget.configuration.fields]);
};

export default useFieldsToShow;
