import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Field,
    H4,
    IconSvg,
    Input,
    LoadingCircle,
    ModalBody,
    ModalFooterState,
    ModalForm,
    ModalHeader,
    ModalSize,
    SimpleErrorStateMessage,
    useCloseCallback,
    withModal,
    XCloseButton,
} from '@tonkean/infrastructure';
import { SuccessOutlineIcon } from '@tonkean/svg';
import type { Initiative, ItemInterface } from '@tonkean/tonkean-entities';
import { Button, UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const ModalBodySuccess = styled(ModalBody)`
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    padding: 32px;
`;

const schema = yup.object({
    title: yup.string().required('Name is required'),
});

interface Props {
    itemInterface: ItemInterface;
    initiative: Initiative;
    backgroundColor: Color;
}

const DuplicateItemModal: React.FC<Props> = ({ itemInterface, initiative, backgroundColor }) => {
    const [{ data: duplicatedInitiative, loading, error }, duplicateInitiative] =
        useLazyTonkeanService('duplicateInitiative');

    const closeCallback = useCloseCallback();

    return (
        <>
            <ModalHeader $justifyContent="space-between" $flex>
                <H4>Clone "{initiative.title}"</H4>
                <XCloseButton />
            </ModalHeader>
            {!loading && !duplicatedInitiative && (
                <Formik
                    initialValues={{ title: `${initiative.title} (Copy)` }}
                    validationSchema={schema}
                    onSubmit={async ({ title }) => {
                        return await duplicateInitiative(initiative.id, title);
                    }}
                >
                    <ModalForm>
                        <ModalBody>
                            <Field label="Name">
                                <Input name="title" size={InputSize.LARGE} placeholder="Name" />
                            </Field>
                        </ModalBody>

                        <ModalFooterState>
                            <Button onClick={() => closeCallback()} cancel>
                                Cancel
                            </Button>

                            <UserThemedClickableButton
                                data-automation="clone-item-submit-button"
                                type="submit"
                                background={backgroundColor}
                            >
                                Clone
                            </UserThemedClickableButton>
                        </ModalFooterState>
                    </ModalForm>
                </Formik>
            )}

            {loading && <LoadingCircle color={backgroundColor} large centered />}
            {error && <SimpleErrorStateMessage error={error} showSmallError />}

            {duplicatedInitiative && (
                <ModalBodySuccess>
                    <IconSvg as={SuccessOutlineIcon} color={backgroundColor} size={96} />
                    <UserThemedClickableButton
                        state="product.customInterfaceView"
                        params={{
                            itemInterfaceId: itemInterface.id,
                            initiativeId: duplicatedInitiative.id,
                        }}
                        background={backgroundColor}
                        openInNewTab
                    >
                        Open Cloned Item in new tab
                    </UserThemedClickableButton>
                </ModalBodySuccess>
            )}
        </>
    );
};

export default withModal(DuplicateItemModal, { size: ModalSize.SMALL, fixedWidth: true });
