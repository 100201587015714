import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import type { ActionWidgetActionData } from './ActionWidgetActionData';
import CloneItemActionButton from './CloneItemActionButton';
import OpenFormActionButton from './OpenFormActionButton';
import OpenIntakeSequenceButton from './OpenIntakeSequenceButton';
import OpenMatchedItemButton from './OpenMatchedItemButton';
import TriggerActionButton from './TriggerActionButton';

import type { Group, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { InterfaceCTAActionType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    actionData: ActionWidgetActionData;
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    liveDisplayDisabled: boolean | undefined;
    buttonBackground: Color;
    workflowVersionType: WorkflowVersionType;
    openFormsInModal?: boolean;
    onActionCompleted?: () => void;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const WidgetActionButton: React.FC<Props> = ({
    actionData,
    widgetId,
    projectId,
    groupId,
    liveDisplayDisabled,
    workflowVersionType,
    openFormsInModal,
    buttonBackground,
    onActionCompleted,
    className,
    originatedCustomTriggerId,
}) => {
    const projectManager = useAngularService('projectManager');

    const usedGroupId = actionData.type === InterfaceCTAActionType.OPEN_FORM ? actionData.form.group?.id : groupId;
    const { disabled, disabledText } = useMemo(() => {
        if (usedGroupId) {
            const group: Group | undefined = projectManager.groupsMap[usedGroupId];
            const workerDisabled: boolean = Boolean(
                group && workflowVersionType === WorkflowVersionType.DRAFT
                    ? !group.buildEnvironmentEnabled
                    : !group?.workerEnabled,
            );

            if (workerDisabled) {
                return { disabled: true, disabledText: 'Module is disabled' };
            }

            if (liveDisplayDisabled) {
                return { disabled: true, disabledText: actionData.buttonStates?.disabled?.disabledText || 'Disabled' };
            }
        }

        return { disabled: false, disabledText: undefined };
    }, [
        actionData.buttonStates?.disabled?.disabledText,
        liveDisplayDisabled,
        projectManager.groupsMap,
        usedGroupId,
        workflowVersionType,
    ]);

    switch (actionData.type) {
        case InterfaceCTAActionType.OPEN_FORM:
            return (
                <OpenFormActionButton
                    className={className}
                    initiativeId={actionData.initiativeId}
                    openFormsInModal={openFormsInModal}
                    form={actionData.form}
                    projectId={projectId}
                    buttonBackground={buttonBackground}
                    params={{
                        projectId,
                        formId: actionData.form.id,
                        initiativeId: actionData.initiativeId,
                        tid: actionData.initiativeId,
                        formVersionType:
                            // Only put this param if draft for nicer url
                            workflowVersionType === WorkflowVersionType.DRAFT
                                ? WorkflowVersionType.DRAFT
                                : WorkflowVersionType.PUBLISHED,
                    }}
                    displayText={
                        actionData.label || actionData.form.definition?.clientDisplayName || actionData.form.displayName
                    }
                    disabled={disabled}
                    disabledTooltip={disabledText}
                    workflowVersionType={workflowVersionType}
                    onSubmitResolved={onActionCompleted}
                    originatedCustomTriggerId={originatedCustomTriggerId}
                />
            );

        case InterfaceCTAActionType.TRIGGER_WORKFLOW:
            return (
                <TriggerActionButton
                    className={className}
                    customTriggerId={actionData.id}
                    initiativeId={actionData.initiativeId}
                    background={buttonBackground}
                    displayText={actionData.label || actionData.customTriggerDisplayName}
                    disabled={disabled}
                    disabledTooltipText={disabledText}
                    onButtonClicked={onActionCompleted}
                />
            );

        case InterfaceCTAActionType.OPEN_MATCHED_ITEM:
            return (
                <OpenMatchedItemButton
                    className={className}
                    widgetId={widgetId}
                    initiativeId={actionData.initiativeId}
                    state={actionData.interface ? 'product.customInterfaceView' : 'product.interface.fullview'}
                    projectId={projectId}
                    buttonBackground={buttonBackground}
                    displayText={actionData.label || actionData.defaultLabel}
                    disabled={
                        disabled || !actionData.initiativeId || (actionData.contractField && !actionData.interface)
                    }
                    disabledTooltip={
                        disabled
                            ? disabledText
                            : !actionData.initiativeId
                              ? 'This action is not available for the current item (no matching items were found)'
                              : "Couldn't find the interface for this action."
                    }
                    openFormsInModal={openFormsInModal}
                    itemInterfaceId={actionData.interface?.interfaceId}
                />
            );

        case InterfaceCTAActionType.CLONE_ITEM:
            return (
                <CloneItemActionButton
                    className={className}
                    action={actionData}
                    buttonBackground={buttonBackground}
                    disabled={disabled}
                    disabledTooltip={disabledText}
                />
            );
        case InterfaceCTAActionType.OPEN_INTAKE_SEQUENCE:
            return (
                <OpenIntakeSequenceButton
                    className={className}
                    workflowVersionType={workflowVersionType}
                    state="product.intakeInterface"
                    projectId={projectId}
                    buttonBackground={buttonBackground}
                    disabled={disabled}
                    disabledTooltip={
                        disabled
                            ? disabledText
                            : 'This action is not available for the current item (no matching items were found)'
                    }
                    actionData={actionData}
                    groupId={groupId}
                />
            );
    }
};
export default WidgetActionButton;
