import React from 'react';
import styled from 'styled-components';

import type { EvaluatedProcessMapperNode } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ForeignObject = styled.foreignObject`
    position: absolute;
    overflow: visible;
    border-radius: 100%;
    cursor: pointer;
`;

const Wrapper = styled.div<{ $isSelected: boolean }>`
    background: ${Theme.colors.basicBackground};
    border: ${({ $isSelected }) =>
        $isSelected ? `2px solid ${Theme.colors.primaryHighlight}` : `2px solid ${Theme.colors.gray_400}`};
    border-radius: 100px;
    height: 100%;
    width: 100%;
`;

interface Props {
    node: EvaluatedProcessMapperNode;
    isSelected: boolean;
}

const ProcessMapperGraphJointNodeWrapper: React.FC<Props> = ({ node, isSelected }) => {
    return (
        <ForeignObject x={-10} y={-10} width={20} height={20}>
            <Wrapper $isSelected={isSelected} data-automation="process-mapper-joint-node" />
        </ForeignObject>
    );
};

export default ProcessMapperGraphJointNodeWrapper;
