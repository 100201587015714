import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo } from 'react';

import useSolutionSiteContext from '../../../../hooks/useSolutionSiteContext';
import type { SolutionSitePageWidgetProps } from '../../../../WidgetModule';
import type { ActionWidgetActionData } from '../../components/actionButtons/ActionWidgetActionData';
import ActionsItemWidgetDisplay from '../../components/ActionsItemWidgetDisplay';
import { useIntakeSequenceActions } from '../../itemInterface/hooks/useIntakeSequenceActions';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { FormType, InterfaceCTAActionType, useSolutionSiteThemeConfiguration } from '@tonkean/tonkean-entities';
import type { ActionsItemWidgetConfiguration, CreateForm, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { EMPTY_OBJECT } from '@tonkean/utils';

const SolutionSitePageActionsItemWidget: React.FC<SolutionSitePageWidgetProps<ActionsItemWidgetConfiguration>> = ({
    workflowVersionType,
    widget,
    permission,
}) => {
    const { solutionSite } = useSolutionSiteContext();
    const formManager = useAngularService('formManager');
    const [{ data: allForms, loading: loadingForms }, getAllWorkflowFolderForms] = useLazyAsyncMethod(
        formManager,
        'getAllWorkflowFolderForms',
    );
    useEffect(() => {
        if ((widget.configuration.actions?.length ?? 0) > 0) {
            getAllWorkflowFolderForms(solutionSite.workflowFolderId, workflowVersionType);
        }
    }, [
        getAllWorkflowFolderForms,
        widget.configuration.actions?.length,
        solutionSite.workflowFolderId,
        workflowVersionType,
    ]);

    const intakeSequenceActions = useIntakeSequenceActions(
        widget.configuration.actions,
        undefined,
        undefined,
        EMPTY_OBJECT,
    );

    const actionsData: ActionWidgetActionData[] = useMemo(() => {
        if (!allForms) {
            return [];
        }

        const openFormActions: ActionWidgetActionData[] = widget?.configuration?.actions
            .map((action) => allForms.find((form) => form.id === action.id))
            .filter((form): form is CreateForm => form?.formType === FormType.CREATE)
            .map((form) => ({
                type: InterfaceCTAActionType.OPEN_FORM,
                id: form.id as TonkeanId<TonkeanType.FORM>,
                form,
                initiativeId: undefined,
                defaultLabel: form.displayName,
            }));

        return [...openFormActions, ...intakeSequenceActions];
    }, [allForms, intakeSequenceActions, widget?.configuration?.actions]);

    const projectManager = useAngularService('projectManager');
    const themeConfiguration = useSolutionSiteThemeConfiguration(solutionSite, projectManager.project);

    return (
        <ActionsItemWidgetDisplay
            actionsData={actionsData}
            loadingActions={loadingForms}
            widget={widget}
            permission={permission}
            actionButtonColor={themeConfiguration.headerBackgroundColor}
            workflowVersionType={workflowVersionType}
        />
    );
};

export default SolutionSitePageActionsItemWidget;
