import { useEffect } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { ContractFieldType } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType, ContractFieldSummary } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const useGetContractFieldsSelector = (
    projectId: TonkeanId<TonkeanType.PROJECT>,
    contractId: TonkeanId<TonkeanType.CONTRACT> | undefined,
    contractFieldType?: ContractFieldType,
): { data: ContractFieldSummary[]; loading: boolean } => {
    const [{ data, loading }, getContractFieldsCaller] = useLazyTonkeanService('getContractFields');

    useEffect(() => {
        if (contractId) {
            getContractFieldsCaller(projectId, contractId, contractFieldType);
        }
    }, [contractId, getContractFieldsCaller, projectId, contractFieldType]);

    return { data: !contractId ? EMPTY_ARRAY : data?.entities ?? EMPTY_ARRAY, loading };
};

export default useGetContractFieldsSelector;
