import styled from 'styled-components';

import { Paragraph } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

export const SelectorTitle = styled(Paragraph)`
    color: ${Theme.colors.gray_700};
    margin-top: 16px;
    padding-bottom: 8px;
`;
