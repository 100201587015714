<div class="question-confirm common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </span>
    <!-- Title -->
    <div class="modal-header common-title flex-vmiddle">
        <span class="svg-icon svg-icon-70 mod-static" ng-if="questionConfirmModalData.showLogo">
            <tnk-icon src="../../../../apps/tracks/images/icons/tonkean-logo.svg"></tnk-icon>
        </span>
        <span>{{ questionConfirmModalData.title }}</span>
    </div>
    <!-- Body -->
    <div class="question-confirm-body" ng-bind-html="questionConfirmModalData.body"></div>
    <!-- Buttons -->
    <div class="modal-footer text-right" ng-class="questionConfirmModalData.footerClass">
        <button
            class="btn"
            data-automation="question-confirm-modal-cancel-button"
            ng-class="questionConfirmModalData.cancelClass || 'btn-default'"
            ng-click="$dismiss('cancelButton')"
        >
            {{ questionConfirmModalData.cancelLabel }}
        </button>

        <button
            ng-if="!questionConfirmModalData.okIsLink && questionConfirmModalData.okLabel"
            class="btn"
            data-automation="question-confirm-modal-ok-button"
            ng-class="questionConfirmModalData.okClass || 'btn-primary'"
            ng-click="$close()"
        >
            {{ questionConfirmModalData.okLabel }}
        </button>
        <div ng-if="questionConfirmModalData.okIsLink" ng-class="questionConfirmModalData.okClass">
            <a ng-click="$close()">{{ questionConfirmModalData.okLabel }}</a>
        </div>
        <div
            ng-if="questionConfirmModalData && questionConfirmModalData.okSubLabel"
            class="common-color-dark-grey common-size-xxxxs margin-top-xxs"
        >
            {{ questionConfirmModalData.okSubLabel }}
        </div>
    </div>
</div>
