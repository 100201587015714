import TrainingSetModelSelector from '../../../../../infrastructure/components/TrainingSetModelSelector';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkTrainingSetModelSelector',
        reactToAngular(TrainingSetModelSelector, [
            'modelId',
            'trainingSetId',
            'onChange',
            'trainingSetModelsToExclude',
        ]),
    );
