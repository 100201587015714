/**
 * Blocks an element from being clicked or focused
 */
export class ElementBlocker {
    /**
     * Map with the html element as key and the event listener callback as value
     */
    private blockedElementToEventListenerFunctionMap: Map<HTMLElement, (event: FocusEvent) => void> = new Map();

    /**
     * Blocking an element and it's child elements from being focused or clicked on
     *
     * @param element - the element to block
     */
    public blockElement(element: string | HTMLElement, message: string = 'This section is blocked from being edited') {
        const HTMLElement = this.getHtmlElement(element);
        if (!HTMLElement) {
            return;
        }

        // If element already blocked
        if (this.blockedElementToEventListenerFunctionMap.get(HTMLElement)) {
            return;
        }

        // If a child element being focused, unfocus it, and set a negative tabindex to prevent refocusing.
        const callback = (event: FocusEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if (event.target) {
                const target = event.target as HTMLElement;
                target.blur();
                target.tabIndex = -1;
                target.classList.add('parent-unfocusable');
            }
        };
        // Listen to focus events in child elements, set capture to true to be the first event listener that is
        // being triggered, so it can safely prevent propagation and default.
        HTMLElement.addEventListener('focus', callback, { capture: true });
        // Add the blocking ::after element to make it unclickable
        HTMLElement.classList.add('self-unfocusable');
        // If element has not position, or the position is static, add position relative
        if (!HTMLElement.style.position || HTMLElement.style.position === 'static') {
            // We are not using the regular 'relative' class, so when we unblock the element, it won't remove
            // the class and break the page if the element had it before the blocking.
            HTMLElement.classList.add('self-unfocusable-position');
        }

        if (message !== '') {
            HTMLElement.dataset.blockedMessage = message;
        }

        this.blockedElementToEventListenerFunctionMap.set(HTMLElement, callback);
    }

    /**
     * Unblocks an element from being blocked
     *
     * @param element - the element to block
     */
    public unblockElement(element: string | HTMLElement) {
        const HTMLElement = this.getHtmlElement(element);
        if (!HTMLElement) {
            return;
        }

        const callback = this.blockedElementToEventListenerFunctionMap.get(HTMLElement);

        // If element is not blocked
        if (!callback) {
            return;
        }

        // Stop blocking focus in child elements
        HTMLElement.querySelectorAll('.parent-unfocusable').forEach((item: HTMLElement) => {
            item.tabIndex = 0;
            item.classList.remove('parent-unfocusable');
        });
        // Stop listening to focus events
        HTMLElement.removeEventListener('focus', callback, { capture: true });
        // Remove the upper blocking element
        HTMLElement.classList.remove('self-unfocusable');
        // Remove position relative
        HTMLElement.classList.remove('self-unfocusable-position');

        this.blockedElementToEventListenerFunctionMap.delete(HTMLElement);
    }

    private getHtmlElement<T extends HTMLElement = HTMLElement>(element: T | string): T | null {
        if (typeof element === 'string') {
            return document.querySelector<T>(element);
        }
        return element;
    }
}

export default angular.module('tonkean.app').service('elementBlocker', ElementBlocker);
