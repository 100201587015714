import React from 'react';
import styled from 'styled-components';

import SingleWorkerRunModuleItemDetailsInterfaceIcon from './SingleWorkerRunModuleItemDetailsInterfaceIcon';
import SingleWorkerRunModuleItemInitiativeLink from './SingleWorkerRunModuleItemInitiativeLink';
import SingleWorkerRunModuleItemSourceWorkerRunLink from './SingleWorkerRunModuleItemSourceWorkerRunLink';
import workerRunReasonConfig from '../../../entities/workerRunReasonConfig';
import type WorkerRunReasonConfigItem from '../../../entities/WorkerRunReasonConfigItem';
import SingleWorkerRunInformationItem from '../SingleWorkerRunInformationItem';
import SingleWorkerRunInformationRow from '../SingleWorkerRunInformationRow';
import SingleWorkerRunWorkflowVersionInformationItem from '../SingleWorkerRunWorkflowVersionInformationItem';

import { ErrorMessage, Tooltip } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunReason } from '@tonkean/tonkean-entities';

const InterfaceWrapper = styled.div`
    display: flex;
`;
const InterfaceIcon = styled.div`
    height: 14px;
    width: 14px;
    margin-right: 5px;

    flex-shrink: 0;

    .tnk-icon {
        &,
        svg {
            display: block;
            height: 100%;
            width: 100%;
        }
    }
`;

const WorkerRunReasonDescription = styled.div`
    margin-right: 4px;
`;

interface Props {
    workerRun?: WorkerRun;
}

const SingleWorkerRunModuleItemDetails: React.FC<Props> = ({ workerRun }) => {
    const config: WorkerRunReasonConfigItem | undefined = workerRun && workerRunReasonConfig[workerRun.workerRunReason];

    return (
        <>
            {config?.duplicateTitle && (
                <ErrorMessage>Item creation failed because there is an item with the same title.</ErrorMessage>
            )}

            <SingleWorkerRunInformationRow>
                <SingleWorkerRunInformationItem
                    title="Involved Item"
                    value={
                        workerRun && (
                            <SingleWorkerRunModuleItemInitiativeLink
                                initiativeId={workerRun.initiativeId}
                                title={workerRun.initiative?.title}
                                groupId={workerRun.groupId}
                                testRun={workerRun.testRun}
                            />
                        )
                    }
                    placeholderCount={2}
                />

                <SingleWorkerRunWorkflowVersionInformationItem workerRun={workerRun} />

                <SingleWorkerRunInformationItem
                    title="Interface"
                    value={
                        config && (
                            <InterfaceWrapper>
                                <Tooltip
                                    content={config.moduleItemInterface}
                                    disabled={
                                        !config.description ||
                                        !config.moduleItemInterface ||
                                        config.description === config.moduleItemInterface
                                    }
                                >
                                    <InterfaceIcon>
                                        <SingleWorkerRunModuleItemDetailsInterfaceIcon
                                            moduleItemInterface={config.moduleItemInterface}
                                        />
                                    </InterfaceIcon>
                                </Tooltip>
                                {workerRun?.workerRunReasonData?.workerRunReasonSourceWorkerRun &&
                                workerRun.workerRunReason === WorkerRunReason.INITIATIVE_CREATED_FROM_LOGIC ? (
                                    <>
                                        <WorkerRunReasonDescription>{config.description}</WorkerRunReasonDescription>{' '}
                                        <SingleWorkerRunModuleItemSourceWorkerRunLink
                                            workerRunReasonSourceWorkerRun={
                                                workerRun.workerRunReasonData.workerRunReasonSourceWorkerRun
                                            }
                                        />
                                    </>
                                ) : (
                                    <>{config.description || config.moduleItemInterface} </>
                                )}
                            </InterfaceWrapper>
                        )
                    }
                />
            </SingleWorkerRunInformationRow>
        </>
    );
};

export default SingleWorkerRunModuleItemDetails;
