import React from 'react';
import styled from 'styled-components';

import { ReactComponent as EmptyTrigger } from '../../../../../../../..//images/icons/trigger-empty.svg';

import { Theme } from '@tonkean/tui-theme';

const EmptyTriggerSvg = styled(EmptyTrigger)`
    margin-bottom: 12px;
`;

const EmptyState = styled.div`
    margin-top: 70px;
    margin-bottom: 165px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${Theme.colors.gray_600};
    text-align: center;
`;

const TriggeredSolutionsModulesTableEmptyState: React.FC = () => {
    return (
        <EmptyState>
            <EmptyTriggerSvg />
            <div>Make sure this data source is properly connected in your modules</div>
        </EmptyState>
    );
};

export default TriggeredSolutionsModulesTableEmptyState;
