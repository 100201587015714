import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled, { css } from 'styled-components';

import { NO_ICON } from '@tonkean/icon-picker';
import { Spacer, TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { GreyCheckMarkIcon } from '@tonkean/svg';
import { InProgressCheckMarkIcon } from '@tonkean/svg';
import { GreenCheckMarkIcon } from '@tonkean/svg';
import { RedCheckMarkIcon } from '@tonkean/svg';
import type { EvaluatedProcessMapperNode } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

export const TITLE_HEIGHT = 60;
export const FIELD_HEIGHT = 33;

export const FIELDS_PADDING = 16;

const ForeignObject = styled.foreignObject<{ $isEditable: boolean }>`
    position: absolute;
    overflow: visible;
    border-radius: 100%;

    ${({ $isEditable }) =>
        $isEditable &&
        css`
            cursor: pointer;
        `}
`;
const Wrapper = styled.div<{ $isSelected: boolean }>`
    background: ${Theme.colors.basicBackground};
    border: ${({ $isSelected }) =>
        $isSelected ? `3px solid ${Theme.colors.primaryHighlight}` : `3px solid ${Theme.colors.gray_400}`};
    border-radius: 8px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;

const Title = styled.div<{ $showBorderBottom: boolean }>`
    width: 100%;
    padding: 16px;
    font-size: ${FontSize.XLARGE_18};
    font-weight: 500;
    color: ${Theme.colors.gray_800};
    text-align: center;
    align-items: center;

    display: flex;
    justify-content: flex-start;

    ${({ $showBorderBottom }) =>
        $showBorderBottom &&
        css`
            border-bottom: solid ${Theme.colors.gray_300};
        `}
`;

const SingleFieldWrapper = styled.div`
    padding: 5px 16px 5px 16px;
    display: flex;
    gap: 4px;
    width: 100%;
    font-size: ${FontSize.LARGE_16};
    color: ${Theme.colors.gray_600};
`;

const IconContainer = styled.div`
    width: 25px;
`;

interface Props {
    node: EvaluatedProcessMapperNode;
    isSelected: boolean;
    isEditable: boolean;
}

export const processMapperNodeStageToIcon = {
    IN_PROGRESS: <InProgressCheckMarkIcon />,
    DONE: <GreenCheckMarkIcon />,
    NOT_ACTIVE: <GreyCheckMarkIcon />,
    REJECTED: <RedCheckMarkIcon />,
};

const ProcessMapperGraphItem: React.FC<Props> = ({ node, isSelected, isEditable }) => {
    const elementHeight = useMemo(() => {
        // calculate the field section height according to the number of fields.
        let fieldSectionHeight = node.evaluatedFields.length * FIELD_HEIGHT;

        // if there is at least 1 field- add "padding" of 16 to the entire section
        // There is no real padding for the fields section but we simply add a spacer of 8 px when there is at least 1 field
        fieldSectionHeight = fieldSectionHeight > 0 ? fieldSectionHeight + FIELDS_PADDING : fieldSectionHeight;

        // add the title height
        return fieldSectionHeight + TITLE_HEIGHT;
    }, [node.evaluatedFields.length]);

    const calculatedY = useMemo(() => {
        return -1 * (elementHeight / 2);
    }, [elementHeight]);

    function iconNotEmpty(index: number, icon: JSX.Element | undefined) {
        return (
            icon &&
            node.configuration?.fields?.[index]?.icon?.name &&
            node.configuration?.fields?.[index]?.icon?.name !== NO_ICON
        );
    }

    return (
        <ForeignObject x={-150.5} y={calculatedY} width={300} height={elementHeight} $isEditable={isEditable}>
            <Wrapper $isSelected={isSelected} data-automation={`process-mapper-single-node-${node.id}`}>
                <Title $showBorderBottom={node.evaluatedFields.length > 0}>
                    {processMapperNodeStageToIcon[node.evaluatedStage] || processMapperNodeStageToIcon['NOT_ACTIVE']}
                    <Spacer width={10} />
                    <TextEllipsis numberOfLines={1}>{node.evaluatedTitle}</TextEllipsis>
                </Title>
                {node.evaluatedFields?.length > 0 && <Spacer height={8} width={100} />}
                {node.evaluatedFields?.map((field, index) => (
                    <Tooltip
                        key={`single_field_process_mapper_node_tooltip_${node.id}_${index}`}
                        content={field.text}
                        placement="right-end"
                        disabled={field.text?.length ? field.text.length < 30 : true}
                    >
                        <SingleFieldWrapper key={`single_field_process_mapper_node_${node.id}_${index}`}>
                            {iconNotEmpty(index, field.icon) && (
                                <ErrorBoundary
                                    key={`single_field_process_mapper_node_icon_${node.id}_${index}`}
                                    fallback={<></>}
                                >
                                    <IconContainer>{field.icon}</IconContainer>
                                </ErrorBoundary>
                            )}

                            <TextEllipsis key={`process_mapper_node_${node.id}_${index}`} numberOfLines={1}>
                                {field.text}
                            </TextEllipsis>
                        </SingleFieldWrapper>
                    </Tooltip>
                ))}
            </Wrapper>
        </ForeignObject>
    );
};

export default ProcessMapperGraphItem;
