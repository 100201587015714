import React from 'react';
import styled from 'styled-components';

import PeopleDirectoryPreviewTableEntrance from './PeopleDirectoryPreviewTableEntrance';
import PeopleDirectoryViewMode from '../../../entities/PeopleDirectoryViewMode';
import { usePeopleDirectoriesView } from '../../../utils/PeopleDirectoryViewContext';

import { SendToGroupOfPeopleAs } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const INNER_PADDING = 20;

const Wrapper = styled.main`
    width: 100%;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 3px;
    flex: 1 0 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
`;
const Header = styled.div<{ gridColumns: number }>`
    padding: 0 ${INNER_PADDING}px;
    min-height: 30px;
    font-size: ${FontSize.SMALL_12};
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    color: ${Theme.colors.gray_500};
    font-weight: 500;
    display: grid;
    grid-template-columns: repeat(${({ gridColumns }) => gridColumns}, minmax(20%, 35%));
    align-items: center;
    white-space: pre-wrap;
    z-index: 10;
    position: sticky;
    top: 0;
    background-color: white;
`;
const PeopleDirectoriesWrapper = styled.div`
    height: 100%;
`;

const SendMessageUsingHeader: Record<PeopleDirectoryViewMode, Record<SendToGroupOfPeopleAs, string>> = {
    [PeopleDirectoryViewMode.HISTORY]: {
        [SendToGroupOfPeopleAs.PEOPLE_GROUP]: 'Message communication method',
        [SendToGroupOfPeopleAs.EACH_INDIVIDUAL]: "Recipient's communication method",
    },
    [PeopleDirectoryViewMode.PREVIEW]: {
        [SendToGroupOfPeopleAs.PEOPLE_GROUP]: 'Group message per business group',
        [SendToGroupOfPeopleAs.EACH_INDIVIDUAL]: 'Direct message to each recipient',
    },
};

interface Props {
    loading: boolean;
}

const PeopleDirectoryRecipientsPreviewTable: React.FC<Props> = ({ loading }) => {
    const { peopleDirectories, sendToGroupOfPeopleAs, mode } = usePeopleDirectoriesView();

    const sendMessageUsingText = SendMessageUsingHeader[mode][sendToGroupOfPeopleAs];

    return (
        <Wrapper>
            <Header gridColumns={mode === PeopleDirectoryViewMode.PREVIEW ? 2 : 4}>
                <span>Recipients</span>
                <span>{sendMessageUsingText}</span>
                {mode === PeopleDirectoryViewMode.HISTORY && (
                    <>
                        <span>Status</span>
                        <span>Description</span>
                    </>
                )}
            </Header>
            <PeopleDirectoriesWrapper>
                {loading
                    ? Array.from({ length: 2 })
                          .fill(undefined)
                          .map((_, id) => (
                              <PeopleDirectoryPreviewTableEntrance
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={id}
                                  loading
                              />
                          ))
                    : Object.values(peopleDirectories)?.map((peopleDirectory) => (
                          <PeopleDirectoryPreviewTableEntrance
                              key={peopleDirectory.id}
                              peopleDirectoryId={peopleDirectory.id}
                          />
                      ))}
            </PeopleDirectoriesWrapper>
        </Wrapper>
    );
};

export default PeopleDirectoryRecipientsPreviewTable;
