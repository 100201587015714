import LineItemsWidget from './components/LineItemsWidget';
import LineItemsWidgetConfigurationEditor from './components/LineItemsWidgetConfigurationEditor';
import type LineItemsWidgetConfiguration from './entities/LineItemsWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const LineItemsWidgetPackage: ItemInterfaceWidgetPackage<LineItemsWidgetConfiguration> = {
    displayComponent: LineItemsWidget,
    configurationComponent: LineItemsWidgetConfigurationEditor,
};

export default LineItemsWidgetPackage;
