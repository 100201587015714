import { useAngularService } from 'angulareact';
import React from 'react';
import styled, { css } from 'styled-components';

import { TonkeanIconDisplay } from '@tonkean/icon-picker';
import { H4, IconSvg } from '@tonkean/infrastructure';
import { useSolutionSiteContext } from '@tonkean/interface-module';
import { OpenNewTabIcon } from '@tonkean/svg';
import type { SolutionSitePage } from '@tonkean/tonkean-entities';
import { SolutionSitePageType, useSolutionSiteThemeConfiguration } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import type { FullClickableProps } from '@tonkean/tui-buttons/Clickable';
import type { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

const StyledIconButton = styled(IconButton)<{ selected: boolean; $brandColor: string }>`
    width: 100%;
    padding: 12px;
    height: unset;

    ${({ selected }) =>
        selected &&
        css`
            background-color: ${Theme.colors.gray_300};
        `}
`;

const DisplayName = styled(H4)`
    flex-grow: 1;
`;

const IconContainer = styled.div`
    line-height: normal;
`;

interface Props {
    page: SolutionSitePage;
    selected: boolean;
    onClick?: React.ComponentProps<typeof Clickable>['onClick'];
}

const SolutionPageLink: React.FC<Props> = ({ page, selected, onClick }) => {
    const { solutionSite } = useSolutionSiteContext();
    const isExternal = page.type === SolutionSitePageType.EXTERNAL;
    const pageLinkProps: FullClickableProps = isExternal
        ? {
              href: page.configuration.externalUrl,
              openInNewTab: true,
          }
        : {
              state: '.',
              params: { solutionSitePageId: page.id, solutionSitePageUrlSlug: page.urlSlug },
          };

    const projectManager = useAngularService('projectManager');
    const themeConfiguration = useSolutionSiteThemeConfiguration(solutionSite, projectManager.project);

    return (
        <StyledIconButton
            data-automation={`solution-page-link-page-list-${page.displayName}`}
            selected={selected}
            $brandColor={themeConfiguration.headerBackgroundColor}
            onClick={onClick}
            leftIcon={
                page.configuration.icon && (
                    <IconContainer>
                        <TonkeanIconDisplay icon={page.configuration.icon} size={18} />
                    </IconContainer>
                )
            }
            rightIcon={isExternal ? <IconSvg as={OpenNewTabIcon} size={12} /> : null}
            flat
            {...pageLinkProps}
        >
            <DisplayName $bold>{page.displayName}</DisplayName>
        </StyledIconButton>
    );
};

export default SolutionPageLink;
