import React from 'react';

import DetailedItemsWidgetDisplay from './DetailedItemsWidgetDisplay';
import type ItemWidgetProps from '../../../WidgetModule/entities/ItemWidgetProps';
import type { InnerItemWidgetConfiguration } from '../../entities';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { useItemInterfaceHeaderThemeConfiguration } from '@tonkean/tonkean-entities';

const DetailedItemsWidget: React.FC<ItemWidgetProps<InnerItemWidgetConfiguration>> = ({
    initiative: parentInitiative,
    widget,
    permission,
}) => {
    const { project, itemInterface, workflowVersion, initiativeInitialLoading } = useItemInterfaceContext();
    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);

    return (
        <DetailedItemsWidgetDisplay
            permission={permission}
            theme={themeConfiguration}
            workflowVersion={workflowVersion}
            groupId={parentInitiative?.group?.id}
            initiative={parentInitiative}
            initiativeInitialLoading={initiativeInitialLoading}
            projectId={project.id}
            widget={widget}
        />
    );
};

export default DetailedItemsWidget;
