const personSelectorValidator = (
    validationObject: any,
    definition: any,
    dontPopulateValidationObject?: boolean,
    validateThread?: boolean,
): boolean => {
    if (
        !definition.initiativeOwner &&
        !definition.specificPeopleIds?.length &&
        !definition.personEmailFieldDefinitionId &&
        !definition.personEmailExpressionDefinition &&
        !definition.previousActor &&
        !(validateThread && (definition.replyToPreviousMessage || definition.replyToRootMonitoredItem))
    ) {
        if (!dontPopulateValidationObject) {
            validationObject.personSelection = 'Must select person option';
        }
        return false;
    }

    return true;
};

export default personSelectorValidator;
