import { useAngularService } from 'angulareact';
import { useEffect } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useFormikField } from '@tonkean/infrastructure';
import type { Group, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

const useGetEditorConfigDataByWorkflowVersionId = (workflowVersionType: WorkflowVersionType) => {
    const projectManager = useAngularService('projectManager');

    const { value: groupId } = useFormikField<TonkeanId<TonkeanType.GROUP> | undefined>('configuration.groupId');

    const [{ data: fieldDefinitions, loading: fieldDefinitionsLoading }, getFieldDefinitionsByWorkflowVersionType] =
        useLazyTonkeanService('getFieldDefinitionsByWorkflowVersionType');

    const group: Group | undefined = groupId && projectManager.groupsMap[groupId];

    useEffect(() => {
        if (group?.id && group?.publishedWorkflowVersionId && group?.draftWorkflowVersionId) {
            getFieldDefinitionsByWorkflowVersionType(
                group.id,
                workflowVersionType,
                workflowVersionType === WorkflowVersionType.PUBLISHED
                    ? group.publishedWorkflowVersionId
                    : group.draftWorkflowVersionId,
            );
        }
    }, [
        getFieldDefinitionsByWorkflowVersionType,
        group?.id,
        group?.draftWorkflowVersionId,
        group?.publishedWorkflowVersionId,
        projectManager.groupsMap,
        workflowVersionType,
    ]);

    const workflowVersionId =
        workflowVersionType === WorkflowVersionType.DRAFT
            ? group?.draftWorkflowVersionId
            : group?.publishedWorkflowVersionId;

    return { fieldDefinitions, workflowVersionId, group, fieldDefinitionsLoading };
};

export default useGetEditorConfigDataByWorkflowVersionId;
