<div class="margin-top" ng-if="table" ng-hide="loading">
    <form name="importTableForm" ng-if="!data.stage || data.stage === 'setup'">
        <h4 class="margin-top-xs margin-bottom">Basic mapping:</h4>

        <!-- Item Name -->
        <div class="flex-vmiddle common-size-xxs">
            <div class="flex-no-shrink margin-right flex-basis-33 text-right">Item Name: *</div>
            <div class="flex-grow">
                <select
                    class="form-control"
                    required
                    ng-options="option for option in table[0] | filter:isOptionEmpty"
                    ng-model="data.result.trackName"
                >
                    <option value="" selected></option>
                </select>
            </div>
        </div>

        <!-- Owner Name -->
        <div
            class="flex-vmiddle common-size-xxs margin-top"
            ng-if="!displayFieldsList || displayFieldsList.includes('Owner')"
        >
            <div class="flex-no-shrink margin-right flex-basis-33 text-right">Owner name/email:</div>
            <div class="flex-grow">
                <select
                    class="form-control"
                    ng-options="option for option in table[0] | filter:isOptionEmpty"
                    ng-model="data.result.owner"
                >
                    <option value="" selected></option>
                </select>
            </div>
        </div>

        <!-- Due Date -->
        <div
            class="flex-vmiddle common-size-xxs margin-top"
            ng-if="!displayFieldsList || displayFieldsList.includes('Due Date')"
        >
            <div class="flex-no-shrink margin-right flex-basis-33 text-right">Due date:</div>
            <div class="flex-grow">
                <select
                    class="form-control"
                    ng-options="option for option in table[0] | filter:isOptionEmpty"
                    ng-model="data.result.dueDate"
                >
                    <option value="" selected></option>
                </select>
            </div>
        </div>

        <!-- Dups -->
        <div ng-if="!handleDuplicatesDisabled" class="flex-vmiddle common-size-xxs margin-top">
            <div class="flex-no-shrink margin-right flex-basis-33 text-right">Handle duplicates:</div>
            <div class="flex-grow">
                <select class="form-control" ng-model="data.result.avoidDups">
                    <option value="ignore">Ignore (only take the first of each title)</option>
                    <option value="update" ng-if="!preventDuplicatesUpdate">
                        Update (update values if title already exists)
                    </option>
                    <option value="">None (import as is)</option>
                </select>
            </div>
        </div>

        <!-- Import Other Fields -->
        <hr />
        <h4 class="margin-top-xs margin-bottom">Additional fields to import:</h4>

        <div class="margin-bottom-lg" ng-if="isAdditionalFieldsPresent()">
            <p class="common-size-xxxxs">
                Select:
                <a ng-click="bulkSelect(true)">All</a>
                /
                <a ng-click="bulkSelect(false)">None</a>
            </p>

            <div class="import-review-box mod-sm">
                <div
                    class="list-item flex-vmiddle pointer"
                    ng-repeat="item in data.filteredFields | filter:isOptionEmpty track by $index"
                    ng-if="item !== data.result.trackName && item !== data.result.owner && item !== data.result.dueDate"
                >
                    <i
                        class="fa {{
                            !data.result.include[item] ? 'fa-square-o' : 'fa-check-square-o common-color-primary'
                        }}"
                        ng-click="data.result.include[item] = !data.result.include[item]"
                    ></i>
                    <span
                        class="flex-grow margin-normal-sm-h common-size-xxs padding-normal-sm-v"
                        ng-click="data.result.include[item] = !data.result.include[item]"
                    >
                        {{ item }}
                    </span>
                </div>
            </div>
            <hr />
        </div>

        <!-- Empty state for fields import -->
        <div class="common-size-xxs" ng-if="!isAdditionalFieldsPresent()">
            None of the imported items fields names are equal to the configured fields names.
        </div>

        <hr />

        <!-- Action Buttons -->
        <div class="flex-vmiddle">
            <div class="flex-grow common-color-danger">{{ error }}</div>
            <div>
                <button ng-if="onCancel" class="btn btn-default margin-right" ng-click="onCancel()">Cancel</button>
                <button type="submit" class="btn btn-primary margin-right" ng-click="import()">Import</button>
            </div>
        </div>
    </form>

    <!-- Progress Bar -->
    <div ng-if="data.stage === 'importing'" class="text-center">
        <div>
            <i class="loading"></i>
            <span class="margin-left-xs">{{ data.state || 'Importing' }}</span>
        </div>
        <div class="progress">
            <div
                class="progress-bar"
                ng-if="data.progress > 0"
                role="progressbar"
                aria-valuenow="{{ data.progress }}"
                aria-valuemin="0"
                aria-valuemax="100"
                ng-style="{ width: data.progress + '%' }"
            >
                {{ data.progress }}%
            </div>
        </div>
    </div>

    <!-- Finished Import Message -->
    <div ng-if="data.stage === 'completed'" class="text-center">
        <h3 class="common-title-md mod-light">Import Completed</h3>

        <!-- How many items imported -->
        <div class="margin-normal-v">
            {{ data.createdCount }} items imported
            <span ng-if="data.avoidDups">
                and {{ table.length - 1 - data.createdCount - data.failedTracksCount }} items skipped (were duplicates)
            </span>
            .
        </div>

        <!-- How many items were not imported -->
        <div ng-if="data.failedTracksCount">
            <p>Unfortunately, {{ data.failedTracksCount }} items could not be imported.</p>
        </div>

        <!-- Error -->
        <div class="common-error common-size-xxs">{{ data.createInitiativesError }}</div>
        <hr />

        <!-- Done Button -->
        <button class="btn btn-primary" ng-click="onDone({ failedImportsCount: !data.failedTracksCount })">
            Complete
        </button>
    </div>
</div>

<div class="text-center" ng-if="loading">
    <i class="loading"></i>
</div>
