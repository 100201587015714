<div class="text-center">
    <!-- Modal title -->
    <div class="padding-normal clearfix text-center" style="border-radius: 6px 6px 0 0">
        <div class="common-size-l margin-normal-v common-title-font">
            Cheers {{as.currentUser.firstName}} 🍻
            <br />
            Welcome to our shared adventure!
        </div>

        <hr />

        <p class="common-size-xs text-left">
            <strong>Your free trial has just ended.</strong>
            Hopefully, you’ve had a chance to see how Tonkean can help view, monitor and take actions on your important
            business operations, projects and initiatives.
        </p>
        <p class="common-size-xs common-color-black text-left">
            To continue our shared adventure, and maintain full access to all of Tonkean capabilities, with unlimited
            team-members, lists and integrations, you will need to upgrade your account:
        </p>

        <div class="margin-top-xlg margin-bottom-xlg">
            <button
                class="btn btn-lg btn-primary"
                analytics-on
                analytics-category="Trial Expired Modal"
                analytics-event="Upgrade Modal"
                ng-click="modalUtils.openUpgradeModal()"
                data-ng-disabled="data.loading"
            >
                Upgrade Your Account
            </button>
        </div>

        <!-- Show error -->
        <div ng-if="data.error">
            An error occurred:
            <br />
            {{ data.error }}
        </div>
    </div>

    <hr class="margin-none" />
    <div
        class="padding-normal text-left common-bg mod-light-grey flex-vmiddle flex-xs-col"
        style="border-radius: 0 0 6px 6px"
    >
        <p class="common-color-dark-grey commons-size-xxxs margin-right-no-xs flex-grow">
            Not ready to upgrade? No sweat! You can choose to continue with the free plan, though some features
            <a class="common-color-dark-grey common-underline" href="https://tonkean.com/pricing/" target="_blank">
                will be limited
            </a>
            .
        </p>
        <button
            class="btn btn-secondary btn-sm"
            analytics-on
            analytics-category="Trial Expired Modal"
            analytics-event="Continue free"
            ng-click="setLimitedState()"
            data-ng-disabled="data.loading"
        >
            <span class="flex-vmiddle">
                <span class="margin-right-xs">Continue with the Free plan</span>
                <i class="loading-small" ng-show="data.loading"></i>
            </span>
        </button>
        <!--<p class="common-color-grey common-size-xxxxs margin-top">-->
        <!--Some features will be <a class="common-color-grey" href="https://tonkean.com/pricing/" target="_blank">limited</a>.-->
        <!--</p>-->
    </div>
</div>
