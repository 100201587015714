import { htmlDecode } from '@tonkean/utils';
import { replaceExpressionVariableNamesToIds } from '@tonkean/tonkean-utils';
import { getParticipatingVariablesForFormula } from '@tonkean/tonkean-utils';

function HtmlEditorCtrl(
    $scope,
    $rootScope,
    taTools,
    projectManager,
    customFieldsManager,
    requestThrottler,
    tonkeanService,
    groupId,
    existingContent,
    exampleInitiative,
    doNotEvaluatePreview,
    logicId,
    workflowVersionId,
    globalExpressionOnly,
    fieldToInsert,
) {
    // Set params for the tool.
    taTools.tnkInsertField.isEnabled = true;
    taTools.tnkInsertField.groupId = groupId;
    taTools.tnkInsertField.workflowVersionId = workflowVersionId;
    taTools.tnkInsertField.logicId = logicId;
    taTools.tnkInsertField.globalTabsOnly = globalExpressionOnly;
    taTools.tnkInsertLink.groupId = groupId;
    taTools.tnkInsertLink.logicId = logicId;

    $scope.data = {
        content: existingContent,
        validationError: null,

        groupId,
        workflowVersionId,

        // Evaluated example
        evaluatedExpressionValue: null,
        evaluatedExpressionLoading: false,
        evaluationError: '',
        doNotEvaluatePreview,
        exampleInitiative,
        fieldToInsert,
    };

    $scope.init = function () {
        if ($scope.data.fieldToInsert) {
            const label = $scope.data.fieldToInsert.isFormula
                ? `<#${
                      $scope.data.fieldToInsert.readableFormulaExpression || $scope.data.fieldToInsert.formulaExpression
                  }#>`
                : `{{${$scope.data.fieldToInsert.label || $scope.data.fieldToInsert.name}}}`;
            const element = `<span>${label}</span>`;
            $scope.data.content = element + $scope.data.content;

            $scope.data.fieldToInsert = null;
        }

        if ($scope.data.content) {
            $scope.previewContent();
        }
    };

    $scope.saveHtml = function () {
        const displayableExpression = unescapeHtmlInFormulaTag($scope.data.content);

        const replacementResult = replaceFieldsLabelsWithIds(customFieldsManager, displayableExpression);

        $scope.data.validationError = replacementResult.error;
        if ($scope.data.validationError) {
            return;
        }

        $scope.$close({
            html: displayableExpression,
            evaluatedHtml: replacementResult.evaluatedExpression,
            waitingForNewField: false,
            newFieldArgs: null,
        });
    };

    $scope.togglePreview = function () {
        $scope.data.showPreview = !$scope.data.showPreview;
    };

    $scope.previewContent = function () {
        if ($scope.data.exampleInitiative && $scope.data.content) {
            const replacementResult = replaceFieldsLabelsWithIds(customFieldsManager, $scope.data.content);
            $scope.data.evaluatedContent = evaluateExpressionForPreview(replacementResult.evaluatedExpression);
        } else {
            // If no example item exists, show the placeholders as is
            $scope.data.evaluatedExpressionValue = $scope.data.content;
        }
    };

    /**
     * Evaluate expression for preview if should.
     */
    function evaluateExpressionForPreview(evaluatedExpression) {
        if (!$scope.data.doNotEvaluatePreview && $scope.data.exampleInitiative && evaluatedExpression) {
            const evaluatedExpressionKey = 'expression';

            evaluateInitiativeExpressions(
                [
                    {
                        key: evaluatedExpressionKey,
                        expression: evaluatedExpression,
                    },
                ],
                $scope.data.exampleInitiative,
                evaluatedExpressionKey,
            );
        } else {
            // clear the evaluated value
            $scope.data.evaluatedExpressionValue = '';
        }
    }

    /**
     * Evaluate Initiative expressions.
     */
    function evaluateInitiativeExpressions(expressionsToEvaluate, initiative, evaluatedExpressionKey) {
        $scope.data.evaluationError = '';
        const uniqueThrottlerId = `htmlEditorgetEvaluatedExpressionsForInitiative-${$scope.data.groupId}-${$scope.data.logicId}`;

        if (expressionsToEvaluate.length) {
            $scope.data.evaluatedExpressionLoading = true;
            requestThrottler.do(uniqueThrottlerId, 50, () =>
                tonkeanService
                    .getEvaluatedExpressionsForInitiative(projectManager.project.id, initiative, expressionsToEvaluate)
                    .then((result) => {
                        $scope.data.evaluatedExpressionValue = result.evaluatedExpressions[evaluatedExpressionKey];
                    })
                    .catch(() => {
                        $scope.data.evaluationError = "Couldn't evaluate";
                    })
                    .finally(() => {
                        $scope.data.evaluatedExpressionLoading = false;
                    }),
            );
        }
    }

    function replaceFieldsLabelsWithIds(customFieldsManager, displayableExpression) {
        // Replace all field labels with field ids
        const participatingVariablesInfo = getParticipatingVariablesForFormula(
            customFieldsManager.selectedGlobalFieldsMap[$scope.data.workflowVersionId],
            customFieldsManager.selectedColumnFieldsMap[workflowVersionId],
            'TONKEAN_EXPRESSION',
        );
        const replacementResult = replaceExpressionVariableNamesToIds(
            displayableExpression,
            '{{',
            '}}',
            participatingVariablesInfo.variableNameToVariableMap,
            participatingVariablesInfo.variableLabelToVariableMap,
            participatingVariablesInfo.variableIdToVariableMap,
        );
        return replacementResult;
    }

    /**
     * Unescape formula tags
     *
     * @param html {string} - the html with the escaped formula
     * @returns {string} the same html but with unescaped formula
     */
    function unescapeHtmlInFormulaTag(html) {
        // Find a string that starts with escaped `<#` (`&lt;#`) and ends with escaped `#>` (`#&gt;`)
        // and decodes the html encode of it.
        return html.replace(/&lt;#.*?#&gt;/g, (str) => htmlDecode(str));
    }

    $rootScope.$on('createNewField', (_, args) => {
        $scope.$close({
            waitingForNewField: true,
            newFieldArgs: args,
        });
    });

    $scope.init();
}

angular.module('tonkean.app').controller('HtmlEditorCtrl', HtmlEditorCtrl);
