import React from 'react';
import styled from 'styled-components';

import { H3, Spacer } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

interface Props {}

const Container = styled.div`
    text-align: center;
    max-width: 365px;
`;

const Title = styled.div`
    font-size: 32px;
    font-weight: 500;
`;

const SolutionSiteInterfacePageNotFound: React.FC<Props> = ({}) => {
    return (
        <Container data-automation="solution-site-interface-page-not-found">
            <Title>Page not found</Title>
            <Spacer height={24} />
            <H3 $color={Theme.colors.gray_700}>
                The page you’re looking for can’t be displayed since it doesn't exist or you don’t have access.
            </H3>
        </Container>
    );
};

export default SolutionSiteInterfacePageNotFound;
