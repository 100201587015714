import React from 'react';

import TonkeanIconDisplayInner from './TonkeanIconDisplayInner';

import { logicBlockTypes } from '@tonkean/logic-block-configs';
import type { CustomTriggerType, Icon, IntegrationType } from '@tonkean/tonkean-entities';

// component that receives an icon and display the correct icon from the system accordingly
interface Props {
    icon?: Icon;
    size?: number;
    dontShowEmptyIcon?: boolean;
    integrationTypesForIcon?: {
        integrationTypes: IntegrationType[];
        hasFieldsWithoutIcon: boolean;
    };
    showMatchedEntityIcon?: boolean | undefined;
    customTriggerTypes?: CustomTriggerType[];
}

const TonkeanIconDisplay: React.FC<Props> = ({
    icon,
    size,
    dontShowEmptyIcon = false,
    integrationTypesForIcon,
    showMatchedEntityIcon,
    customTriggerTypes,
}) => {
    return (
        <TonkeanIconDisplayInner
            icon={icon}
            size={size}
            dontShowEmptyIcon={dontShowEmptyIcon}
            integrationTypesForIcon={integrationTypesForIcon}
            showMatchedEntityIcon={showMatchedEntityIcon}
            logicBlocks={logicBlockTypes}
            customTriggerTypes={customTriggerTypes}
        />
    );
};

export default TonkeanIconDisplay;
