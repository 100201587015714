import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import ViewDataAddRecordRow from './ViewDataAddRecordRow';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import {
    Field,
    FormikHelpers,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    Placeholder,
    PlaceholderGrid,
    PlaceholderGridDirection,
    Separator,
    useCloseCallback,
    withModal,
} from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { range } from '@tonkean/utils';

const StyledField = styled(Field)`
    margin-bottom: 20px;
`;

const StyledPlaceholder = styled(Placeholder)`
    margin-bottom: 20px;
`;

const SeparatorWrapper = styled(Separator)`
    margin: 30px 0;
`;

const validateSchema = Yup.object({
    fields: Yup.array()
        .of(
            Yup.object({
                field: Yup.string().required('no empty field allowed'),
                isMandatory: Yup.boolean().required(),
                value: Yup.string().when('isMandatory', {
                    is: true,
                    then: Yup.string().required('Required Field'),
                }),
            }).required(),
        )
        .required(),
});

interface Props {
    entityName: string;
    projectIntegrationEntityId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ENTITY>;
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    projectIntegrationUniqueType: string;
    onSubmit(): void;
    externalActivityId?: string;
}

const ViewDataAddRecordModal: React.FC<Props> = ({
    entityName,
    projectIntegrationEntityId,
    projectIntegrationId,
    projectIntegrationUniqueType,
    onSubmit,
    externalActivityId,
}) => {
    const onClose = useCloseCallback();
    const $timeout = useAngularService('$timeout');
    const { id: projectId } = useProject();

    const {
        data: fieldMetadata,
        loading: fieldMetadataLoading,
        error: fieldMetadataError,
    } = useTonkeanService(
        'getEntityFieldMetadata',
        projectIntegrationEntityId,
        projectIntegrationId,
        projectIntegrationUniqueType,
    );
    const [newFieldNameInput, setNewFieldNameInput] = useState('');

    const [
        { data: externalActivity, error: getExternalActivityError, loading: getExternalActivityLoading },
        getExternalActivityById,
    ] = useLazyTonkeanService('getExternalActivityById');

    useEffect(() => {
        if (externalActivityId) {
            getExternalActivityById(projectId, projectIntegrationId, externalActivityId, true);
        }
    }, [externalActivityId, getExternalActivityById, projectIntegrationId, projectId]);

    const [{ loading: insertExternalActivityLoading, error: insertExternalActivityError }, insertExternalActivity] =
        useLazyTonkeanService('upsertExternalActivity');

    const metadataFields = useMemo(() => {
        const list =
            fieldMetadata?.entities.map((singleFieldMetadata) => ({
                fieldLabel: singleFieldMetadata.isLabelOverridable
                    ? singleFieldMetadata.overridableLabel
                    : singleFieldMetadata.label,
                field: singleFieldMetadata.name,
                value: externalActivity?.originalEntity?.[singleFieldMetadata.name] ?? '',
                isMandatory: singleFieldMetadata.idLookup,
            })) || [];

        // sort by is mandatory true firsts
        return list.sort((itemA, itemB) => {
            if (itemA.isMandatory === itemB.isMandatory) {
                return 0;
            }
            return itemA.isMandatory ? -1 : 1;
        });
    }, [externalActivity?.originalEntity, fieldMetadata?.entities]);

    return (
        <Formik
            initialValues={{
                fields: metadataFields,
            }}
            validationSchema={validateSchema}
            onSubmit={(values) => {
                return insertExternalActivity(
                    values.fields,
                    projectId,
                    projectIntegrationId,
                    projectIntegrationEntityId,
                    externalActivity?.created,
                    !!externalActivityId,
                ).then(() => {
                    $timeout(() => {
                        onSubmit();
                        onClose();
                    });
                });
            }}
            enableReinitialize
        >
            {({ values, setValues }) => (
                <ModalForm>
                    <ModalHeader>
                        {!!externalActivityId ? 'Edit' : 'Add new'} {entityName}
                    </ModalHeader>

                    <ModalBody>
                        <FormikHelpers>
                            {getExternalActivityLoading ? (
                                <PlaceholderGrid gap="20px" direction={PlaceholderGridDirection.ROWS}>
                                    {range(7).map((index) => (
                                        <Placeholder key={index} $width="100%" $height="26px" />
                                    ))}
                                </PlaceholderGrid>
                            ) : (
                                <>
                                    {values.fields?.map((fieldValue, index) => (
                                        <ViewDataAddRecordRow
                                            key={fieldValue.field}
                                            name={`fields.${index}`}
                                            isEdit={!!externalActivityId}
                                        />
                                    ))}
                                </>
                            )}

                            <SeparatorWrapper />

                            {getExternalActivityLoading ? (
                                <StyledPlaceholder $width="100%" $height="26px" />
                            ) : (
                                <StyledField>
                                    <Input
                                        value={newFieldNameInput}
                                        onChange={(e) => setNewFieldNameInput(e.target.value)}
                                        placeholder="Enter new field name"
                                        data-automation="view-data-add-record-modal-write-field-name"
                                    />
                                </StyledField>
                            )}

                            <ClickableLink
                                onClick={() => {
                                    setValues((prev) => ({
                                        ...prev,
                                        fields: [
                                            ...prev.fields,
                                            {
                                                field: newFieldNameInput,
                                                value: '',
                                                isMandatory: false,
                                                fieldLabel: newFieldNameInput,
                                            },
                                        ],
                                    }));
                                    setNewFieldNameInput('');
                                }}
                                disabled={
                                    getExternalActivityLoading ||
                                    insertExternalActivityLoading ||
                                    fieldMetadataLoading ||
                                    !newFieldNameInput ||
                                    values.fields
                                        .map((field) => field.field)
                                        .some((fieldName) => fieldName.trim() === newFieldNameInput.trim())
                                }
                                data-automation="view-data-add-record-modal-add-field"
                            >
                                + Add Field
                            </ClickableLink>
                        </FormikHelpers>
                    </ModalBody>

                    <ModalFooterActions
                        saveDisabled={!!fieldMetadataError}
                        loading={insertExternalActivityLoading || fieldMetadataLoading || getExternalActivityLoading}
                        error={fieldMetadataError || insertExternalActivityError || getExternalActivityError}
                        border
                    />
                </ModalForm>
            )}
        </Formik>
    );
};

export default withModal(ViewDataAddRecordModal, {
    size: ModalSize.MEDIUM,
    fixedWidth: true,
    height: '600px',
    escapeCloses: true,
});
