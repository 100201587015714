<span ng-if="mboxData.closeIcon" class="common-close-btn" ng-click="$dismiss('dismiss')">
    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
</span>

<div class="modal-header common-title">{{ mboxData.title }}</div>

<div ng-if="!mboxData.html && mboxData.body" class="message-box-body">{{ mboxData.body }}</div>
<div ng-if="mboxData.html" class="message-box-body" ng-bind-html="mboxData.html"></div>

<div
    class="modal-footer text-right flex"
    ng-class="{ 'flex-row-reverse': mboxData.isReversedButtons, 'mod-justify-end': !mboxData.isReversedButtons }"
>
    <button
        ng-if="mboxData.okLabel"
        class="btn margin-left-xs"
        data-automation="message-box-modal-confirm-message-box"
        ng-class="{ 'btn-primary': !mboxData.isWarn, 'btn-danger': mboxData.isWarn }"
        ng-click="$close()"
    >
        {{ mboxData.okLabel }}
    </button>
    <button class="btn btn-default" ng-if="mboxData.cancelLabel" ng-click="$dismiss('dismiss')">
        {{ mboxData.cancelLabel }}
    </button>
</div>
