import type ColumnId from '../configs/config/columnId';

const isEditableColumn = (
    value: unknown,
    columnId: ColumnId,
    isEditable: boolean,
    drilldownColumnId: string | undefined,
) => {
    if (isEditable) {
        return !drilldownColumnId || value === undefined || drilldownColumnId !== columnId;
    }
    return false;
};

export default isEditableColumn;
