import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import type BitBucketProjectIntegrationData from './BitBucketProjectIntegrationData';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { SimpleSelect } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';

const Divider = styled.hr`
    width: 100%;
`;

const RepositorySimpleSelect = styled(SimpleSelect)`
    width: 80%;
`;

const BitBucketCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps<BitBucketProjectIntegrationData>> = ({
    integration,
    onChangeOrInitIntegration,
    projectIntegrationData,
}) => {
    const existingSelectedRepositories = projectIntegrationData?.watchedRepositories.map(
        (repo) => `${repo.workspaceId}\\${repo.repositoryId}`,
    );
    const [selectedRepositories, setSelectedRepositories] = useState<string[]>(existingSelectedRepositories || []);
    const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
    const [query, setQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const tonkeanService = useAngularService('tonkeanService');
    const projectManager = useAngularService('projectManager');

    const setFilteredOptionsMemoed = useCallback((options) => setFilteredOptions(options), [setFilteredOptions]);
    useEffect(() => {
        setLoading(true);
        tonkeanService
            .getAutoCompleteOptions(projectManager.project.id, integration.id, 'repositories', { query, role: 'admin' })
            .then((options: any) => {
                if (!options) {
                    setFilteredOptionsMemoed([]);
                }
                const newOptions = options.options.map((op) => {
                    return {
                        value: `${op.value.workspaceId}\\${op.value.repositoryId}`,
                        label: op.displayName,
                    };
                });
                setFilteredOptionsMemoed(newOptions);
            })
            .catch((autoCompleteError) => {
                setError(autoCompleteError);
            })
            .finally(() => setLoading(false));
    }, [tonkeanService, setFilteredOptionsMemoed, integration.id, query, projectManager.project.id]);

    useEffect(() => {
        const projectIntegrationData = {
            watchedRepositories:
                selectedRepositories?.map((repoString) => {
                    const parts = repoString.split('\\');
                    return {
                        workspaceId: parts[0],
                        repositoryId: parts[1] || '',
                    };
                }) || [],
        };

        onChangeOrInitIntegration({ projectIntegrationData }, false);
    }, [onChangeOrInitIntegration, selectedRepositories]);

    return (
        <div className="flex flex-col">
            <label className="common-size-xs margin-bottom-lg">
                Which Repositories should we actively watch via webhooks?
            </label>
            <div className="flex flex-vmiddle">
                <label>Repositories</label>
                <div className="col-md-8 flex-vmiddle">
                    <RepositorySimpleSelect
                        placeholder="Search repositories..."
                        value={selectedRepositories}
                        isLoading={loading}
                        inputValue={query}
                        onInputChange={setQuery}
                        options={filteredOptions}
                        onChange={(selectedValue) => {
                            setSelectedRepositories(selectedValue);
                        }}
                        menuPosition="fixed"
                        isMulti
                        thin
                    />

                    {error && (
                        <Tooltip content="Unable to load services. Try to re-authenticate.">
                            <i className="fa fa-warning common-color-danger margin-left" />
                        </Tooltip>
                    )}
                </div>
            </div>
            <Divider />
        </div>
    );
};

export default BitBucketCustomizeSetupComponent;
