import React from 'react';

import SingleWorkerRunDataSource from './SingleWorkerRunDataSource/SingleWorkerRunDataSource';
import SingleWorkerRunFlowRun from './SingleWorkerRunFlowRun/SingleWorkerRunFlowRun';
import SingleWorkerRunLoading from './SingleWorkerRunLoading';
import SingleWorkerRunModuleItem from './SingleWorkerRunModuleItem/SingleWorkerRunModuleItem';

import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunStage } from '@tonkean/tonkean-entities';

interface Props {
    /**
     *  If undefined, it means it's the initial load and should show a generic loading screen. Otherwise,
     *  let the specific component handle the loading state by passing workerRun as undefined.
     */
    workerRunStage?: WorkerRunStage;
    /** If undefined, it means that it's the initial load of the worker run, and not just a reload. */
    workerRun?: WorkerRun;
    manuallyReloading: boolean;
    autoReloading: boolean;
    reload(): void;
    inspectWorkerRun(): void;
}

const SingleWorkerRunSwitch: React.FC<Props> = ({
    workerRunStage,
    workerRun,
    manuallyReloading,
    autoReloading,
    reload,
    inspectWorkerRun,
}) => {
    switch (workerRunStage) {
        case WorkerRunStage.DATA_SOURCE:
            return <SingleWorkerRunDataSource workerRun={workerRun} />;

        case WorkerRunStage.MODULE_ITEM:
            return <SingleWorkerRunModuleItem workerRun={workerRun} />;

        case WorkerRunStage.FLOW_RUN:
            return (
                <SingleWorkerRunFlowRun
                    inspectWorkerRun={inspectWorkerRun}
                    workerRun={workerRun}
                    reload={reload}
                    manuallyReloading={manuallyReloading}
                    autoReloading={autoReloading}
                />
            );

        default:
            return <SingleWorkerRunLoading />;
    }
};

export default React.memo(SingleWorkerRunSwitch);
