import React from 'react';

import { ReactComponent as BackgroundProcessIcon } from '../../../../../../images/icons/background-process.svg';
import { ReactComponent as CommunicationSourceIcon } from '../../../../../../images/icons/communication-source.svg';
import { ReactComponent as FieldsIcon } from '../../../../../../images/icons/fields.svg';
import { ReactComponent as FormsIcon } from '../../../../../../images/icons/forms.svg';
import { ReactComponent as DataSourceIcon } from '../../../../../../images/icons/history/data-source.svg';
import { ReactComponent as FlowRunIcon } from '../../../../../../images/icons/history/flow-run.svg';
import { ReactComponent as LiveReportIcon } from '../../../../../../images/icons/live-report.svg';
import { ReactComponent as MailIcon } from '../../../../../../images/icons/mail.svg';
import { ReactComponent as TestStrokeIcon } from '../../../../../../images/icons/test-stroke.svg';
import { ReactComponent as UnknownIcon } from '../../../../../../images/icons/unknown.svg';
import { ReactComponent as UserIcon } from '../../../../../../images/icons/user.svg';
import { ModuleItemInterface } from '../../../entities/WorkerRunReasonConfigItem';

interface Props {
    moduleItemInterface: ModuleItemInterface | undefined;
}

const SingleWorkerRunModuleItemDetailsInterfaceIcon: React.FC<Props> = ({ moduleItemInterface }) => {
    switch (moduleItemInterface) {
        case ModuleItemInterface.DATA_SOURCE:
            return (
                <span className="tnk-icon">
                    <DataSourceIcon />
                </span>
            );
        case ModuleItemInterface.BACKGROUND_PROCESS:
            return (
                <span className="tnk-icon">
                    <BackgroundProcessIcon />
                </span>
            );
        case ModuleItemInterface.FORM:
        case ModuleItemInterface.WEB_FORM:
        case ModuleItemInterface.COMMUNICATION_INTEGRATION_FORM:
            return (
                <span className="tnk-icon">
                    <FormsIcon />
                </span>
            );
        case ModuleItemInterface.TEST_BY_USER:
            return (
                <span className="tnk-icon">
                    <TestStrokeIcon />
                </span>
            );
        case ModuleItemInterface.LIVE_REPORT:
            return (
                <span className="tnk-icon">
                    <LiveReportIcon />
                </span>
            );
        case ModuleItemInterface.EMAIL_WEBHOOK:
            return (
                <span className="tnk-icon">
                    <MailIcon />
                </span>
            );
        case ModuleItemInterface.COMMUNICATION_INTEGRATION:
            return (
                <span className="tnk-icon">
                    <CommunicationSourceIcon />
                </span>
            );
        case ModuleItemInterface.MANUAL_BY_USER:
            return (
                <span className="tnk-icon">
                    <UserIcon />
                </span>
            );
        case ModuleItemInterface.MODULE_FLOW:
            return (
                <span className="tnk-icon">
                    <FlowRunIcon />
                </span>
            );
        case ModuleItemInterface.FIELDS_SETTINGS:
            return (
                <span className="tnk-icon">
                    <FieldsIcon />
                </span>
            );
        case ModuleItemInterface.LIVE_REPORT_OR_MODULE_FLOW:
        default:
            return (
                <span className="tnk-icon">
                    <UnknownIcon />
                </span>
            );
    }
};

export default SingleWorkerRunModuleItemDetailsInterfaceIcon;
