<div>
    <div class="project-stats common-size-xxxs padding-bottom-xs">
        <!-- Title -->
        <div class="common-color-grey3 padding-left padding-top">Filter cross reports items</div>

        <!-- Statuses -->
        <div class="padding-normal-sm">
            <div class="flex-vmiddle common-bold common-size-xxxs">
                <span class="flex-no-shrink common-color-black margin-right">Status</span>
                <hr class="flex-grow common-separator mod-darker margin-none" />
            </div>
            <div class="margin-top project-stats-all-box">
                <!-- Statuses we always show -->
                <div
                    ng-repeat="stat in pm.projectData.stats"
                    class="pointer relative common-color-black margin-right-xs margin-bottom-md"
                    data-automation="tnk-project-stats-template-status"
                    ng-style="filterVisiblity.stateFilter === stat.stateText ? { 'border-color': stat.stateColor } : ''"
                    analytics-on="click"
                    analytics-category="Filter"
                    analytics-event="Filter by State"
                    analytics-label="{{ stat.stateText }}"
                    ng-click="data.openAllStats = false;"
                    ng-if="pm.projectData.stats.length < 6 || !isFilterTextStateInMore(stat.stateText)"

                    ui-sref="product.board({filter:'all', st: data.stateFilter === stat.stateText ? null : stat.stateText, g:null, listFilter: null, isGroupListFilter: null, o:null})"
                >
                    <span class="relative inline-block">
                        <div class="flex-vmiddle">
                            <span
                                class="fa fa-circle margin-right-xs padding-right-xxs common-size-xxs"
                                ng-style="{ color: stat.stateColor }"
                            ></span>
                            <span class="margin-right-xs common-ellipsis line-height-normal">{{ stat.stateText }}</span>
                            <span class="common-color-grey">({{ stat.count }})</span>
                        </div>
                        <div
                            ng-if="filterVisiblity.stateFilter === stat.stateText"
                            class="selected-stat-bg"
                            ng-style="{
                                'background-color': utils.hexToRgbaWithOpacity(stat.stateColor, 0.1),
                                'border-color': stat.stateColor
                            }"
                        ></div>
                    </span>
                </div>

                <!-- Late -->
                <div ng-if="pm.projectData.lateInitiativesCount">
                    <div
                        class="pointer relative common-color-black margin-right-xs margin-bottom-md inline-block"
                        analytics-on="click"
                        analytics-category="Filter"
                        analytics-event="Filter by State"
                        analytics-label="Late"
                        ng-click="data.openAllStats = false;"
                        ng-style="filterVisiblity.baseFilter === 'Late' ? { 'border-color': '#a59165' } : ''"
                        ui-sref="product.board({filter:filterVisiblity.baseFilter === 'Late' ? 'all' : 'Late', st: null, g:null, listFilter: null, isGroupListFilter: null, o:null})"
                    >
                        <span class="flex-vmiddle">
                            <span
                                class="fa fa-circle margin-right-xs padding-right-xxs common-size-xxs"
                                style="color: #a59165"
                            ></span>
                            <span class="margin-right-xs line-height-normal">Late</span>
                            <span class="common-color-grey">({{ pm.projectData.lateInitiativesCount }})</span>
                        </span>
                        <span
                            ng-if="filterVisiblity.baseFilter === 'Late'"
                            class="selected-stat-bg"
                            style="background-color: rgba(165, 145, 101, 0.15); border-color: #a59165"
                        ></span>
                    </div>
                </div>

                <!-- To many statuses - More... -->
                <div
                    class="pointer dropbox relative"
                    uib-dropdown
                    ng-if="pm.projectData.stats && pm.projectData.stats.length > 5"
                >
                    <div
                        class="project-stats-selector dropdown-toggle flex-vmiddle"
                        ng-class="{
                            'mod-active':
                                filterVisiblity.stateFilter && isFilterTextStateInMore(filterVisiblity.stateFilter)
                        }"
                        uib-dropdown-toggle
                    >
                        <span class="flex-grow">More...</span>
                        <span class="flex-no-shink dropdown-chevron mod-static"></span>
                    </div>
                    <ul class="dropdown-menu mod-padding-sm-v" uib-dropdown-menu>
                        <li ng-repeat="stat in pm.projectData.stats" ng-if="isFilterTextStateInMore(stat.stateText)">
                            <a
                                class="padding-normal-xs relative"
                                ui-sref="product.board({filter:'all', st: data.stateFilter === stat.stateText ? null : stat.stateText, g:null, listFilter: null, isGroupListFilter: null, o:null})"
                            >
                                <span class="flex-vmiddle padding-left-xs">
                                    <span
                                        class="fa fa-circle margin-right-xs padding-right-xxs"
                                        ng-style="{ color: stat.stateColor }"
                                    ></span>
                                    <span class="margin-right-xs common-ellipsis common-size-xxxs">
                                        {{ stat.stateText }}
                                    </span>
                                    <span class="common-color-grey common-size-xxxs">({{ stat.count }})</span>
                                </span>
                                <span
                                    ng-if="
                                        filterVisiblity.stateFilter === stat.stateText &&
                                        isFilterTextStateInMore(filterVisiblity.stateFilter)
                                    "
                                    class="absolute-fill-view opacity-25"
                                    ng-style="{ 'background-color': stat.stateColor, 'border-color': stat.stateColor }"
                                ></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Owners -->
        <div class="padding-normal-sm">
            <div class="flex-vmiddle common-bold common-size-xxxs">
                <span class="flex-no-shrink common-color-black margin-right">Owner</span>
                <hr class="flex-grow common-separator mod-darker margin-none" />
            </div>
            <div ng-click="utils.eventStopAllPropagations($event)" class="margin-top-xs">
                <tnk-single-person-selector
                    on-person-selected="onFilterByOwner(person.id)"
                    class="project-stats-owner-popover"
                    on-person-removed="onFilterByOwner(null)"
                    selected-person-id="data.selectedOwner"
                    placeholder="Search by"
                ></tnk-single-person-selector>
            </div>
        </div>

        <!-- Tags -->
        <div class="padding-normal-sm">
            <!-- Title -->
            <div class="flex-vmiddle common-bold common-size-xxxs">
                <span class="flex-no-shrink common-color-black margin-right">Tag</span>
                <hr class="flex-grow common-separator mod-darker margin-none" />
            </div>
            <div class="pointer dropbox relative margin-top-xs" uib-dropdown>
                <!-- Tags selector that shows the selected tag -->
                <div
                    class="project-stats-selector dropdown-toggle flex-vmiddle"
                    ng-class="{ 'mod-active': filterVisiblity.showFunctionsBar === 'tags' }"
                    ng-disabled="data.loadingFilters || (pm.tags && pm.tags.length == 0)"
                    uib-dropdown-toggle
                >
                    <span class="flex-grow" ng-if="!data.loadingFilters && !(pm.tags && pm.tags.length == 0)">
                        {{ filterVisiblity.showFunctionsBar === 'tags' ? filterVisiblity.baseFilter : 'Select...' }}
                    </span>

                    <span class="flex-grow" ng-if="!data.loadingFilters && pm.tags && pm.tags.length == 0">
                        No tags
                    </span>

                    <span class="flex-grow" ng-if="data.loadingFilters">Loading tags...</span>

                    <span class="flex-no-shink dropdown-chevron mod-static"></span>
                </div>
                <ul class="dropdown-menu limited-dropdown-size" uib-dropdown-menu>
                    <li ng-repeat="tag in tags = (pm.tags | orderBy:'name')">
                        <a
                            class="common-ellipsis"
                            ui-sref="product.board({filter:tag.name, g:null, st:null, listFilter: null, isGroupListFilter: null, o:null})"
                        >
                            <span class="margin-right-xs common-ellipsis">{{ tag.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Functions -->
        <div class="padding-normal-sm">
            <!-- Title -->
            <div class="flex-vmiddle common-bold common-size-xxxs">
                <span class="flex-no-shrink common-color-black margin-right">Function</span>
                <hr class="flex-grow common-separator mod-darker margin-none" />
            </div>

            <div class="pointer dropbox relative margin-top-xs" uib-dropdown>
                <!-- Functions selector that shows the selected function -->
                <div
                    class="project-stats-selector dropdown-toggle flex-vmiddle"
                    ng-class="{ 'mod-active': filterVisiblity.showFunctionsBar === 'func' }"
                    ng-disabled="data.loadingFilters || (pm.functions && pm.functions.length == 0)"
                    uib-dropdown-toggle
                >
                    <span class="flex-grow" ng-if="!data.loadingFilters && !(pm.functions && pm.functions.length == 0)">
                        {{ filterVisiblity.showFunctionsBar === 'func' ? filterVisiblity.baseFilter : 'Select...' }}
                    </span>

                    <span class="flex-grow" ng-if="!data.loadingFilters && pm.functions && pm.functions.length == 0">
                        No functions
                    </span>

                    <span class="flex-grow" ng-if="data.loadingFilters">Loading functions...</span>

                    <span class="flex-no-shink dropdown-chevron mod-static"></span>
                </div>
                <ul class="dropdown-menu limited-dropdown-size" uib-dropdown-menu>
                    <!-- Functions -->
                    <li ng-repeat="func in functions = (pm.functions | orderBy:'name')">
                        <a
                            class="common-ellipsis"
                            ui-sref="product.board({filter:func.name, g:null, st:null, listFilter: null, isGroupListFilter: null, o:null})"
                        >
                            <span class="margin-right-xs">{{ func.name }}</span>
                        </a>
                    </li>
                    <!-- Create new function -->
                    <li>
                        <form ng-submit="addNewFunc()" ng-click="utils.eventStopPropagation($event)">
                            <div class="flex-vmiddle common-placeholder-color mod-link-blue">
                                <input
                                    class="form-control common-no-border common-no-outline padding-left"
                                    ng-model="data.newFuncName"
                                    placeholder="Create..."
                                />
                                <button
                                    type="submit"
                                    class="btn btn-sm btn-default flex-no-shrink margin-right-xxs"
                                    ng-if="data.newFuncName && data.newFuncName.length"
                                >
                                    add
                                </button>
                            </div>
                        </form>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Clear filter-->
        <span
            ng-if="
                filterVisiblity.stateFilter ||
                (filterVisiblity.baseFilter !== 'all' && filterVisiblity.baseFilter !== 'my')
            "
            class="common-color-grey pointer flex-vmiddle padding-normal-sm"
            data-automation="tnk-project-stats-template-clear-filter"
            ng-click="clearFilter()"
        >
            <span class="svg-icon-xxs margin-right-xs">
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </span>
            <span>Clear filter</span>
        </span>
    </div>
</div>
