import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function GoogleCalendarCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,

        attendeeEmail: '',
        attendeeEmailEvaluated: '',
        attendeeResponseStatusKey: 'NEEDS_ACTION',

        responseStatusOptions: {
            NEEDS_ACTION: {
                label: 'Unset',
            },
            DECLINED: {
                label: 'Declined',
            },
            TENTATIVE: {
                label: 'Tentative',
            },
            ACCEPTED: {
                label: 'Accepted',
            },
        },
        inviteAttendees: {
            attendeesEmails: '',
            attendeesEmailsEvaluated: '',
        },

        loading: false,
    };

    ctrl.$onInit = function () {
        if ($scope.data.configuredLogic && $scope.data.definition) {
            initializeEditMode();
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onEmailTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.attendeeEmail = originalExpression;
        $scope.data.attendeeEmailEvaluated = evaluatedExpression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAttendeesEmailsChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.inviteAttendees.attendeesEmails = originalExpression;
        $scope.data.inviteAttendees.attendeesEmailsEvaluated = evaluatedExpression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.selectResponseStatus = function (key) {
        $scope.data.attendeeResponseStatusKey = key;

        $scope.onDefinitionChanged(true);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        const definition = $scope.data.definition;
        if (definition) {
            if (!definition.attendees) {
                definition.attendees = [];
            }

            if (!definition.attendees.length) {
                definition.attendees.push({});
            }

            const attendee = definition.attendees[0];
            attendee.email = $scope.data.attendeeEmail;
            attendee.emailEvaluated = $scope.data.attendeeEmailEvaluated;
            attendee.responseStatus = $scope.data.attendeeResponseStatusKey;

            definition.inviteAttendees = $scope.data.inviteAttendees;
        }

        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (
            customTriggerAction &&
            customTriggerAction.customTriggerActionDefinition &&
            customTriggerAction.customTriggerActionDefinition.performedActionDefinition
        ) {
            const definition = customTriggerAction.customTriggerActionDefinition.performedActionDefinition;

            if (definition.attendees && definition.attendees.length) {
                const attendee = definition.attendees[0];
                $scope.data.attendeeEmail = attendee.email;
                $scope.data.attendeeEmailEvaluated = attendee.emailEvaluated;
                $scope.data.attendeeResponseStatusKey = attendee.responseStatus;
            }

            if (definition.inviteAttendees) {
                $scope.data.inviteAttendees.attendeesEmails = definition.inviteAttendees.attendeesEmails;
                $scope.data.inviteAttendees.attendeesEmailsEvaluated =
                    definition.inviteAttendees.attendeesEmailsEvaluated;
            }
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller(
        'GoogleCalendarCustomActionsDefinitionCtrl',
        lateConstructController(GoogleCalendarCustomActionsDefinitionCtrl),
    );
