import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { ReactComponent as BusinessReportIcon } from '../../../../images/icons/navigation/live-reports.svg';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { DeprecatedPeopleSelector } from '@tonkean/angular-to-react-components';
import {
    Field,
    Modal,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalSize,
    Radio,
    RadioGroup,
} from '@tonkean/infrastructure';
import { BusinessReportAccessType } from '@tonkean/tonkean-entities';
import type { BusinessReportAccessibilitySummary } from '@tonkean/tonkean-entities';
import type { Person } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { yupEnum } from '@tonkean/utils';

const BusinessReportAccessibilityAccessSchema = Yup.object({
    businessReportAccessibilityType: yupEnum(BusinessReportAccessType).required(
        'You must choose an accessibility type.',
    ),
});

const BusinessReportName = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    margin-left: 8px;
`;

const BusinessReportNameContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;

const PeopleSelectorWrapper = styled.div`
    margin-top: 15px;
`;

const SpecificPeopleLabel = styled(Field)`
    margin-top: 30px;
`;

const ErrorLoading = styled.div`
    color: ${Theme.colors.error};
`;
const StyledModal = styled(Modal)`
    overflow: visible;
`;
const StyledModalBody = styled(ModalBody)`
    min-height: 300px;
    overflow: visible;
    z-index: 100;
`;

const StyledModalForm = styled(ModalForm)`
    overflow: visible;
`;

interface Props {
    businessReport: BusinessReportAccessibilitySummary;
    onEdit: (newBusinessReportAccessibilityType: BusinessReportAccessType, specificPeopleWithAccess: string[]) => void;
    open: boolean;
    onClose(): void;
}

const EditBusinessReportAccessibilityModal: React.FC<Props> = ({ businessReport, onEdit, open, onClose }) => {
    const projectManager = useAngularService('projectManager');
    const [peopleWithAccess, setPeopleWithAccess] = useState<Person[]>();
    const [{ error }, updateBusinessReportAccessibility] = useLazyTonkeanService('updateBusinessReportAccessibility');
    const {
        data: getPeopleByIdData,
        error: getPeopleByIdsError,
        loading: getPeopleByIdsLoading,
    } = useTonkeanService(
        'getProjectPeopleByIds',
        projectManager.project.id,
        businessReport.specificPeopleWithAccess || [],
        0,
        9999,
    );

    useEffect(() => {
        if (getPeopleByIdData) {
            setPeopleWithAccess(getPeopleByIdData.entities || []);
        }
    }, [getPeopleByIdData]);

    return peopleWithAccess && !getPeopleByIdsLoading ? (
        <StyledModal size={ModalSize.MEDIUM} onClose={onClose} open={open} fixedWidth>
            <Formik
                initialValues={{
                    businessReportAccessibilityType:
                        businessReport.businessReportAccessibilityType || BusinessReportAccessType.EVERYONE,
                    specificPeople: peopleWithAccess || [],
                }}
                validationSchema={BusinessReportAccessibilityAccessSchema}
                onSubmit={(businessReportAccessForm) => {
                    const specificPeopleIds: string[] =
                        businessReportAccessForm.specificPeople?.map((person) => person.id) || [];

                    return updateBusinessReportAccessibility(
                        businessReport.groupId,
                        true,
                        businessReportAccessForm.businessReportAccessibilityType,
                        specificPeopleIds,
                    ).then(() => {
                        onEdit(
                            businessReportAccessForm.businessReportAccessibilityType,
                            businessReportAccessForm.specificPeople?.map((person) => person.id) || [],
                        );
                        onClose();
                    });
                }}
            >
                {({ values, setFieldValue }) => (
                    <StyledModalForm>
                        <StyledModalBody>
                            <BusinessReportNameContainer>
                                <BusinessReportIcon />
                                <BusinessReportName>{businessReport.groupName}</BusinessReportName>
                            </BusinessReportNameContainer>

                            {!getPeopleByIdsLoading && !getPeopleByIdsError && peopleWithAccess && (
                                <>
                                    <Field label="Only visible for:">
                                        <RadioGroup size={InputSize.MEDIUM} name="businessReportAccessibilityType">
                                            <Radio value={BusinessReportAccessType.EVERYONE}>Everyone</Radio>
                                            <Radio value={BusinessReportAccessType.ONLY_MAKERS}>Makers</Radio>
                                            <Radio value={BusinessReportAccessType.ONLY_SOLUTION_COLLABORATORS}>
                                                Makers & Publishers
                                            </Radio>
                                            <Radio
                                                value={
                                                    BusinessReportAccessType.SOLUTION_COLLABORATORS_AND_SPECIFIC_PEOPLE
                                                }
                                            >
                                                Makers, Publishers & Specific People
                                            </Radio>
                                            <Radio value={BusinessReportAccessType.SPECIFIC_PEOPLE}>
                                                Specific People
                                            </Radio>
                                        </RadioGroup>
                                    </Field>
                                    {(values.businessReportAccessibilityType ===
                                        BusinessReportAccessType.SOLUTION_COLLABORATORS_AND_SPECIFIC_PEOPLE ||
                                        values.businessReportAccessibilityType ===
                                            BusinessReportAccessType.SPECIFIC_PEOPLE) && (
                                        <>
                                            <SpecificPeopleLabel label="Choose specific people:" />
                                            <PeopleSelectorWrapper>
                                                <DeprecatedPeopleSelector
                                                    selectedPeople={values.specificPeople}
                                                    disabled={false}
                                                    onTagAdded={() => {
                                                        setFieldValue('specificPeople', values.specificPeople);
                                                    }}
                                                    onTagRemoved={() => {
                                                        setFieldValue('specificPeople', values.specificPeople);
                                                    }}
                                                    noCustom
                                                    doNotSearchOnlyOwnersInGroup
                                                />
                                            </PeopleSelectorWrapper>
                                        </>
                                    )}
                                </>
                            )}
                        </StyledModalBody>
                        <ModalFooterActions error={error} />
                    </StyledModalForm>
                )}
            </Formik>
        </StyledModal>
    ) : (
        <>
            {getPeopleByIdsError && <ErrorLoading>There was an error loading settings...</ErrorLoading>}
            {getPeopleByIdsLoading && <div>Loading settings...</div>}
        </>
    );
};
export default EditBusinessReportAccessibilityModal;
