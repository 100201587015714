import type { InitiativeRowData } from '@tonkean/items-grid';
import type { FieldValidationParams } from '@tonkean/items-grid';
import type { FieldValidation } from '@tonkean/items-grid';
import { isNotRealRow } from '@tonkean/items-grid';
import { isFieldEmpty } from '@tonkean/items-grid';
import { ValidationType } from '@tonkean/items-grid';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

interface FieldRequiredValidationParams extends FieldValidationParams {
    isEditable: boolean;
}

class FieldRequiredValidation implements FieldValidation<FieldRequiredValidationParams> {
    fieldDefinitionKey: FieldDefinitionKey;
    type: ValidationType;
    params: FieldRequiredValidationParams;

    isValid(data: InitiativeRowData): boolean {
        if (isNotRealRow(data) || !this.params.isEditable) {
            return true;
        }

        return !isFieldEmpty(data, this.fieldDefinitionKey);
    }

    constructor(fieldDefinitionKey: FieldDefinitionKey, params: FieldRequiredValidationParams) {
        this.fieldDefinitionKey = fieldDefinitionKey;
        this.type = ValidationType.REQUIRED;
        this.params = params;
    }
}

export default FieldRequiredValidation;
