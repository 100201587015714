import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const NextCloudAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [url, setUrl] = useState<string | undefined>();
    const [username, setUsername] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();

    const authenticateWithCredentials = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                url,
                username,
                password,
            };
            authenticate(config, {}, `${integrationConfiguration.displayName} (${username})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithCredentials}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="api-url" className="col-sm-3 control-label">
                            URL
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={url}
                                className="form-control"
                                id="api-instance"
                                onChange={({ target }) => setUrl(target.value)}
                                autoComplete="off"
                                placeholder="URL"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                For example: https://cloud.example.com
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-username" className="col-sm-3 control-label">
                            Username
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={username}
                                className="form-control"
                                id="api-username"
                                onChange={({ target }) => setUsername(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-secret" className="col-sm-3 control-label">
                            Password
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                onChange={({ target }) => setPassword(target.value)}
                                id="api-password"
                                autoComplete="off"
                                placeholder="Password"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NextCloudAuthenticationComponent;
