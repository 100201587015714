import useBreakpoint from './useBreakpoint';
import { Breakpoint } from '../components/BreakpointManager';

/**
 * A hook that returns whether the current breakpoint is extra small screen width
 *
 * @returns the is small screen width flag
 */
export default function useIsXSmallScreen(): boolean {
    const effectiveBreakpoint = useBreakpoint();
    return effectiveBreakpoint <= Breakpoint.XSMALL_640;
}
