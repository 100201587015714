import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import ItemDetailsWhenLineTooltipRow from './ItemDetailsWhenLineTooltipRow';
import { ReactComponent as InnerItemsCube } from '../../../../../../images/icons/inner-item-cube.svg';
import { ReactComponent as ItemDetailsCube } from '../../../../../../images/icons/item-details-cube.svg';

import { Tooltip } from '@tonkean/infrastructure';
import { innerItemLogicBlockConfigs } from '@tonkean/logic-block-configs';
import type { CustomTrigger, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { TooltipColor } from '@tonkean/tui-theme/colors';
import { TooltipSize } from '@tonkean/tui-theme/sizes';

const Container = styled.div`
    width: 85px;
    display: flex;
`;
const WhenLineWrapper = styled.span`
    color: ${Theme.colors.gray_800};
    box-sizing: border-box;
    background: #d8d8d8;
    height: 2px;
    margin-top: -2px;
    top: 55px;
    left: -20px;
    position: absolute;
    margin-right: 2px;
    width: 100px;
    &:after {
        content: '';
        border-color: #d8d8d8;
        border-width: 0 2px 2px 0;
        border-style: solid;
        padding: 4px;
        margin-top: -4px; // Compensate for the padding (so the arrow is in the middle of the line).
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: absolute;
        top: 0;
        right: 0;
    }
`;

const WhenLineLabel = styled.span<{ showDoubleIcon: boolean }>`
    position: absolute;
    padding: 4px;
    background: ${Theme.colors.gray_700};
    color: ${Theme.colors.white};
    font-size: 8pt;
    border-radius: 3px;
    left: ${({ showDoubleIcon }) => (showDoubleIcon ? '15%' : '25%')};
    margin-top: -9px;
    z-index: 1;
`;

const WhenLineLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
`;

const EvaluatesOn = styled.div`
    margin-bottom: 10px;
    text-align: left;
`;

const TooltipUpperLine = styled.div<{ hasAnotherLine: boolean }>`
    ${({ hasAnotherLine }) =>
        hasAnotherLine &&
        css`
            margin-bottom: 5px;
        `};
`;

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    groupTargetId?: TonkeanId<TonkeanType.GROUP>;
    customTriggerTargetId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    overrideDetailsTitlePlaceholder?: string;
    monitorInnerItems?: boolean;
    monitoredInnerItemCustomTrigger?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    showOnlyInnerItem?: boolean;
}
const ItemDetailsWhenLine: React.FC<Props> = ({
    workflowVersionId,
    groupTargetId,
    customTriggerTargetId,
    overrideDetailsTitlePlaceholder,
    monitorInnerItems,
    monitoredInnerItemCustomTrigger,
    showOnlyInnerItem,
}) => {
    const customTriggerManager = useAngularService('customTriggerManager');
    const consts = useAngularService('consts');
    const logicBlockTypes = consts.getLogicBlockTypes();
    const tonkeanUtils = useAngularService('tonkeanUtils');

    const [innerItemPlaceholder, setInnerItemPlaceholder] = useState<string | undefined>(
        overrideDetailsTitlePlaceholder,
    );

    useEffect(() => {
        if (!overrideDetailsTitlePlaceholder && monitorInnerItems) {
            if (monitoredInnerItemCustomTrigger) {
                customTriggerManager
                    .getCustomTriggerFromCacheOrFallbackServer(workflowVersionId, monitoredInnerItemCustomTrigger)
                    .then((customTrigger: CustomTrigger) => {
                        setInnerItemPlaceholder(
                            logicBlockTypes[customTrigger.customTriggerType].innerItemDetailsTitlePlaceholder,
                        );
                    });
            } else {
                customTriggerManager.getFirstParentOfTypesWorkflowVersion(
                    workflowVersionId,
                    customTriggerTargetId,
                    innerItemLogicBlockConfigs,
                    (parentLogic) => {
                        setInnerItemPlaceholder(
                            logicBlockTypes[parentLogic?.node?.customTriggerType]?.innerItemDetailsTitlePlaceholder,
                        );
                    },
                );
            }
        }
    }, [
        customTriggerManager,
        customTriggerTargetId,
        logicBlockTypes,
        monitorInnerItems,
        monitoredInnerItemCustomTrigger,
        overrideDetailsTitlePlaceholder,
        tonkeanUtils,
        workflowVersionId,
    ]);

    const showInnerItemCube = useMemo(() => {
        return monitorInnerItems || showOnlyInnerItem;
    }, [monitorInnerItems, showOnlyInnerItem]);

    const showRootItemCube = useMemo(() => {
        return !showOnlyInnerItem;
    }, [showOnlyInnerItem]);
    return (
        <Container>
            <WhenLineWrapper>
                <Tooltip
                    placement="bottom"
                    size={TooltipSize.SMALL}
                    color={TooltipColor.DARK}
                    content={
                        <>
                            <EvaluatesOn>Evaluates on</EvaluatesOn>
                            {customTriggerTargetId && showInnerItemCube && (
                                <TooltipUpperLine hasAnotherLine={!!(groupTargetId && showRootItemCube)}>
                                    <ItemDetailsWhenLineTooltipRow
                                        key={customTriggerTargetId}
                                        workflowVersionId={workflowVersionId}
                                        targetId={monitoredInnerItemCustomTrigger ?? customTriggerTargetId}
                                        overrideDetailsTitlePlaceholder={innerItemPlaceholder}
                                    />
                                </TooltipUpperLine>
                            )}
                            {groupTargetId && showRootItemCube && (
                                <ItemDetailsWhenLineTooltipRow
                                    key={groupTargetId}
                                    workflowVersionId={workflowVersionId}
                                    targetId={groupTargetId}
                                />
                            )}
                        </>
                    }
                >
                    <WhenLineLabel showDoubleIcon={!!(showInnerItemCube && showRootItemCube)}>
                        <WhenLineLabelContainer>
                            {showInnerItemCube && <InnerItemsCube />}
                            {showRootItemCube && <ItemDetailsCube />}
                            When
                        </WhenLineLabelContainer>
                    </WhenLineLabel>
                </Tooltip>
            </WhenLineWrapper>
        </Container>
    );
};

export default ItemDetailsWhenLine;
