import template from './tnkCollectInnerItemsForm.template.html?angularjs';

/**
 * Form which creates inner items as answers to a given initiative
 */
angular.module('tonkean.app').component('tnkCollectInnerItemsForm', {
    bindings: {
        formConfig: '<', // The form object configuration
        collectionFormConfig: '<',
        workflowVersionType: '<',
        workflowVersionId: '<',
        groupId: '<',
        customTriggerId: '<',
        initiativeId: '<',
        workerRunId: '<',
        sessionId: '<',
        primaryColor: '<',
        secondaryColor: '<',
        buttonsColor: '<',
        onError: '&', // Callback to an error function of the form
        onSubmit: '&', // Callback to the form submit
        formFinished: '<',
        itemsValidationMap: '<',
        allErrorsNotRestrictingErrors: '<',
        solutionBusinessReportId: '<',
        onBackClicked: '&',
        showBack: '<',
    },
    template,
    controller: 'CollectInnerItemsFormCtrl',
});
