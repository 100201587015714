import React from 'react';
import styled from 'styled-components';

import { VIcon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    doneInnerItemsCount: number;
    onClick(): void;
}

const ColoredText = styled.span`
    color: ${Theme.colors.successHover};
    font-weight: 700;
`;

const IconContainer = styled.div`
    .tnk-icon {
        margin-right: 5px;

        svg {
            width: 8px;
            height: 8px;

            path {
                stroke: ${Theme.colors.successHover};
            }
        }
    }
`;

const DoneTracksCounter: React.FC<Props> = ({ doneInnerItemsCount, onClick }) => {
    return (
        <ColoredText
            data-allow-on-view-only-mode="true"
            className="common-size-xxxxs flex-no-shrink flex-vmiddle margin-right-xs pointer common-italic"
            onClick={onClick}
        >
            <IconContainer>
                <span className="tnk-icon">
                    <VIcon />
                </span>
            </IconContainer>
            {doneInnerItemsCount}
        </ColoredText>
    );
};

export default DoneTracksCounter;
