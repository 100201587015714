import React, { useMemo } from 'react';
import styled from 'styled-components';

import { OperationConfigurationSections, operationNodeTypeConfig } from '../../entities';
import { isFilledAutomatedPart, isFilledManualPart } from '../../utils';
import SolutionMapperSectionIcon from '../SolutionMapperSectionIcon';

import { TextEllipsis } from '@tonkean/infrastructure';
import type { OperationNode, OperationNodeType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ForeignObject = styled.foreignObject`
    position: absolute;
    overflow: visible;
    border-radius: 100%;
    cursor: pointer;
`;
const Wrapper = styled.div<{ $isSelected: boolean; $nodeType: OperationNodeType }>`
    background: ${({ $isSelected }) => ($isSelected ? Theme.colors.primaryHighlight : Theme.colors.basicBackground)};
    border: 3px solid ${({ $nodeType }) => operationNodeTypeConfig[$nodeType].color};
    border-radius: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const TypeName = styled.div`
    font-weight: normal;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_800};
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-bottom: 5px;
    width: 141px;
    text-align: center;
`;
const TypeWrapper = styled.div<{ $nodeType: OperationNodeType }>`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    height: 32px;
    width: 32px;
    background: white;
    border: 2px solid ${({ $nodeType }) => operationNodeTypeConfig[$nodeType].color};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
// The type will be changed using the 'as' prop.
const TypeIcon = styled.svg<{ $nodeType: OperationNodeType }>`
    height: 16px;
    width: 16px;

    path {
        fill: ${({ $nodeType }) => operationNodeTypeConfig[$nodeType].color};
    }
`;
const Title = styled.div<{ $isSelected: boolean }>`
    font-size: ${FontSize.XLARGE_18};
    line-height: 21px;
    color: ${({ $isSelected }) => ($isSelected ? Theme.colors.basicBackground : Theme.colors.gray_800)};
    font-weight: ${({ $isSelected }) => ($isSelected ? 700 : 400)};
    padding: 5px;
    text-align: center;
`;
const Icons = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 10px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px;
    align-items: center;
`;

interface Props {
    node: OperationNode;
    isSelected: boolean;
    showConfigurationIcons: boolean;
}

const SolutionMapperGraphItem: React.FC<Props> = ({ isSelected, node, showConfigurationIcons }) => {
    const hasApps = !node.operationConfiguration.apps?.length;
    const hasAutomated = !node.operationConfiguration.automatedParts?.filter(isFilledAutomatedPart).length;
    const hasManual = !node.operationConfiguration.manualParts?.filter(isFilledManualPart).length;

    const isSafari = useMemo(() => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.includes('safari') && !ua.includes('chrome');
    }, []);

    return (
        <ForeignObject x={-70.5} y={-70.5} width={141} height={141}>
            <Wrapper $isSelected={isSelected} $nodeType={node.operationNodeType}>
                {!isSafari && (
                    <TypeWrapper $nodeType={node.operationNodeType}>
                        <TypeName>{operationNodeTypeConfig[node.operationNodeType].name}</TypeName>
                        <TypeIcon
                            as={operationNodeTypeConfig[node.operationNodeType].icon}
                            $nodeType={node.operationNodeType}
                        />
                    </TypeWrapper>
                )}
                <Title $isSelected={isSelected}>
                    <TextEllipsis
                        numberOfLines={3}
                        // Don't use tooltip or showMore, because it will need to use resize observer, which needs
                        // context, but ReactDigraph doesn't pass context so it will throw an error.
                    >
                        {node.operationConfiguration.displayName}
                    </TextEllipsis>
                </Title>
                {!isSafari && showConfigurationIcons && (
                    <Icons>
                        <SolutionMapperSectionIcon $section={OperationConfigurationSections.APPS} $gray={hasApps} />
                        <SolutionMapperSectionIcon
                            $section={OperationConfigurationSections.AUTOMATED}
                            $gray={hasAutomated}
                        />
                        <SolutionMapperSectionIcon $section={OperationConfigurationSections.MANUAL} $gray={hasManual} />
                    </Icons>
                )}
            </Wrapper>
        </ForeignObject>
    );
};

export default SolutionMapperGraphItem;
