import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import type { BaseProjectIntegrationActionManagerProps } from '../../../../../../../components/state.product.projectIntegrationActionManager/baseProjectIntegrationActionManagerProps';
import type { AdditionalSidePaneConfiguration } from '../../../../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ActionEntityOutputTypeSelect from '../ProjectIntegrationCreateAction/components/ActionEntityOutputTypeSelect';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { Field, FormikAutosave, Input, Textarea } from '@tonkean/infrastructure';
import { ActionType, IntegrationType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_700};
    font-weight: normal;
`;

const Wrapper = styled.div`
    padding-top: 30px;
    padding-left: 24px;
`;

const FieldSection = styled.div`
    margin-bottom: 30px;
`;

const FormSizedInput = styled(Input)`
    width: 400px;
`;

const FormSizedTextArea = styled(Textarea)`
    width: 400px;
    resize: vertical;
    min-height: 100px;
`;
const ValidationInformationSchema = Yup.object({ name: Yup.string().required('Must fill name') });

const ProjectIntegrationActionGeneralTab: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationActionManagerProps>
> = ({
    setProjectIntegrationAction,
    projectIntegrationAction,
    projectIntegration,
    setTabError,
    projectIntegrationActions,
    setOnExitTab,
}) => {
    const integrationMetadataManager = useAngularService('integrationMetadataManager');

    // Validation for integration name
    useEffect(() => {
        const otherNames = projectIntegrationActions
            .filter((action) => action.id !== projectIntegrationAction.id)
            .map((actions) => actions.displayName);

        const isNameAlreadyUsed = otherNames.includes(projectIntegrationAction.displayName);
        const isNameEmpty = projectIntegrationAction.displayName === '';

        if (isNameAlreadyUsed || isNameEmpty) {
            return setTabError(true);
        }
        return setTabError(false);
    }, [projectIntegrationAction, projectIntegrationActions, setTabError]);

    const { data: supportedEntitiesDict } = useAsyncMethod(
        integrationMetadataManager,
        'getIntegrationEntitiesAsDict',
        projectIntegrationAction.projectIntegrationId,
    );

    const entity = useMemo(() => {
        const entityName = projectIntegrationAction.parametersDefinition?.entitiesToRunOn?.[0];

        if (supportedEntitiesDict && entityName) {
            return supportedEntitiesDict[entityName];
        }
    }, [supportedEntitiesDict, projectIntegrationAction.parametersDefinition?.entitiesToRunOn]);

    return (
        <Wrapper>
            <Formik
                validationSchema={ValidationInformationSchema}
                initialValues={{
                    name: projectIntegrationAction.displayName,
                    description: projectIntegrationAction.description,
                }}
                onSubmit={(values) => {
                    setProjectIntegrationAction({
                        ...projectIntegrationAction,
                        displayName: values.name,
                        description: values.description,
                    });
                }}
                enableReinitialize
            >
                <>
                    <FormikAutosave />
                    <FieldSection>
                        <Field label={<Label>Display Name</Label>}>
                            <FormSizedInput
                                data-automation="project-integration-action-general-tab-display-name"
                                className="form-control"
                                type="text"
                                autoComplete="off"
                                placeholder="Display name"
                                name="name"
                            />
                        </Field>
                    </FieldSection>

                    <FieldSection>
                        {entity && (
                            <Field label={<Label>The action will be:</Label>}>
                                <ActionEntityOutputTypeSelect
                                    projectIntegration={projectIntegration}
                                    defaultActionType={ActionType[projectIntegrationAction.actionType]}
                                    defaultEntity={entity}
                                    shouldNotChooseDefaultEntity={
                                        projectIntegration.integrationType === IntegrationType.WEBHOOK
                                    }
                                    isEditable={false}
                                />
                            </Field>
                        )}
                    </FieldSection>

                    <FieldSection>
                        <Field label={<Label>Description</Label>}>
                            <FormSizedTextArea
                                data-automation="project-integration-action-general-tab-description"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Insert Description"
                                name="description"
                            />
                        </Field>
                    </FieldSection>
                </>
            </Formik>
        </Wrapper>
    );
};

export default ProjectIntegrationActionGeneralTab;
