import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import InterfaceButton from '../../components/InterfaceButton';

import { CloseButton, LoadingCircle, Modal, ModalHeader, ModalSize, TextEllipsis } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { XIcon as CloseIcon } from '@tonkean/svg';
import type { Initiative } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const theme = Theme.current.palette;

const CloseIconWrapper = styled.div`
    display: flex;
    margin: 6px 6px 0 auto;
    cursor: pointer;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 15px;
    margin: 0px 15px 15px auto;
`;

const ErrorWrapper = styled.div`
    margin-top: 6px;
    margin-left: 15px;
    color: ${theme.mainColors.error};
`;

const StyledInterfaceButton = styled(InterfaceButton)`
    border-radius: 100px !important;
    font-size: ${FontSize.MEDIUM_14} !important;
    height: 30px;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    margin-left: 10px;
`;

export enum CommonActionButtonTypes {
    MODAL,
}

interface Props extends StyledComponentsSupportProps {
    commonActionButtonType: CommonActionButtonTypes;
    className?: string;
    buttonBackground?: string;
    buttonLabel: string;
    disabled?: boolean;
    disabledTooltip?: string;
    onModalExited?: () => void;
    onModalSubmit: () => Promise<{ entities: Initiative[] } | undefined>;
    modalContent?: JSX.Element;
    submitButtonText: string;
    submitButtonDisabled?: boolean;
    onModalSubmitSuccess: (responseValue: { entities: Initiative[] }) => Promise<void>;
    onModalSubmitError: (responseError: any) => void;
    errorMessage?: string | undefined;
}

const CommonActionButton: React.FC<Props> = ({
    commonActionButtonType,
    className,
    buttonBackground,
    buttonLabel,
    disabled,
    disabledTooltip,
    onModalExited,
    onModalSubmit,
    modalContent,
    submitButtonText,
    submitButtonDisabled,
    onModalSubmitSuccess,
    onModalSubmitError,
    errorMessage,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [modalOpen, setModalOpen] = useState(false);

    const onSubmit = useCallback(() => {
        setIsSubmitting(true);

        onModalSubmit()
            .then((responseValue) => {
                setIsSubmitting(false);
                setModalOpen(false);

                onModalSubmitSuccess(responseValue!);
            })
            .catch((responseError) => {
                setIsSubmitting(false);
                onModalSubmitError(responseError);
            });
    }, [onModalSubmitError, onModalSubmitSuccess, onModalSubmit]);

    return (
        <>
            <Tooltip placement="bottom" content={disabledTooltip} disabled={!disabled}>
                <StyledInterfaceButton
                    dataAutomation="common-action-button"
                    className={className}
                    onClick={(event) => {
                        if (
                            commonActionButtonType === CommonActionButtonTypes.MODAL &&
                            !event.ctrlKey &&
                            !event.metaKey
                        ) {
                            setModalOpen(true);

                            //  Preventing default so that the regular open in new tab will not execute.
                            event.preventDefault();
                            event.stopPropagation();
                            return false;
                        }
                    }}
                    disabled={disabled}
                    buttonColor={buttonBackground}
                >
                    <TextEllipsis numberOfLines={1} tooltip>
                        {buttonLabel}
                    </TextEllipsis>
                </StyledInterfaceButton>
            </Tooltip>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onExited={onModalExited}
                size={ModalSize.MEDIUM}
                backdropCloses={false}
                fixedWidth
            >
                <CloseIconWrapper>
                    <CloseIcon onClick={() => setModalOpen(false)} />
                </CloseIconWrapper>
                <ModalHeader $border={false}>Upload Files</ModalHeader>
                {modalContent}
                <ButtonsWrapper>
                    {errorMessage && (
                        <ErrorWrapper data-automation="common-action-button-error-message">{errorMessage}</ErrorWrapper>
                    )}
                    <CloseButton data-automation="common-action-button-cancel-button" cancel>
                        Cancel
                    </CloseButton>
                    <StyledInterfaceButton
                        type="submit"
                        dataAutomation="common-action-button-submit-button"
                        disabled={submitButtonDisabled}
                        onClick={() => onSubmit()}
                        buttonColor={buttonBackground}
                    >
                        {submitButtonText}
                        {isSubmitting && (
                            <StyledLoadingCircle
                                data-automation="common-action-button-submit-button-loading-circle"
                                color={Theme.colors.basicBackground}
                            />
                        )}
                    </StyledInterfaceButton>
                </ButtonsWrapper>
            </Modal>
        </>
    );
};

export default CommonActionButton;
