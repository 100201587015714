import { Formik, useField } from 'formik';
import capitalize from 'lodash.capitalize';
import React from 'react';
import styled from 'styled-components';

import {
    Field,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    useCloseCallback,
    withModal,
} from '@tonkean/infrastructure';
import type { MultiEntitySelectorEntityOption, MultiEntitySelectorSavedEntity } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

const FieldLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;

interface Props {
    name: string;
    savedEntity: MultiEntitySelectorSavedEntity;
    entityOption: MultiEntitySelectorEntityOption<MultiEntitySelectorSavedEntity>;
    entityTypeLabel: string;
}
const MultiEntitySelectorEditEntityLabelModal: React.FC<Props> = ({
    name,
    savedEntity,
    entityOption,
    entityTypeLabel,
}) => {
    const { setValue: setLabel } = useField<string | undefined>(`${name}.label`)[2];

    const capitalizedEntityTypeLabel = capitalize(entityTypeLabel);

    const onClose = useCloseCallback();

    return (
        <Formik
            initialValues={{ label: savedEntity.label }}
            onSubmit={({ label }) => {
                setLabel(label);
                onClose();
            }}
            enableReinitialize
        >
            {({ setFieldValue }) => (
                <ModalForm>
                    <ModalHeader>{capitalizedEntityTypeLabel} Configuration</ModalHeader>
                    <ModalBody>
                        <Field
                            label={
                                <FieldLabelWrapper>
                                    Label
                                    <ClickableLink
                                        data-automation="multi-entity-selector-edit-entity-label-modal-restore-to-default"
                                        onClick={() => {
                                            setFieldValue('label', undefined);
                                        }}
                                    >
                                        Restore to default
                                    </ClickableLink>
                                </FieldLabelWrapper>
                            }
                            fullWidthLabel
                        >
                            <Input
                                name="label"
                                data-automation="multi-entity-selector-edit-entity-label-modal-label-name"
                                placeholder={entityOption.savedEntityTemplate.defaultLabel}
                            />
                        </Field>
                    </ModalBody>
                    <ModalFooterActions />
                </ModalForm>
            )}
        </Formik>
    );
};

export default withModal(MultiEntitySelectorEditEntityLabelModal, { size: ModalSize.SMEDIUM, fixedWidth: true });
