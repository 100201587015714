import { PersonPermissionRoleType, ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { WORKER_TYPES } from '@tonkean/constants';
import { VIEW_TYPES } from '@tonkean/constants';

function EditIntegrationsCtrl(
    $scope,
    $state,
    $stateParams,
    $timeout,
    authenticationService,
    projectManager,
    tonkeanService,
    modalUtils,
    integrations,
    communicationIntegrationsService,
    integrationHelper,
    workflowVersionManager,
) {
    $scope.pm = projectManager;
    $scope.authenticationService = authenticationService;
    $scope.projectIntegartionId = $stateParams.enterpriseComponentId;
    $scope.viewTypes = VIEW_TYPES;

    // Redirecting to new project integration page if feature flag is on.
    if ($stateParams.enterpriseComponentId) {
        $state.go('product.projectIntegrationPage', {
            enterpriseComponentId: $stateParams.enterpriseComponentId,
            page: ProjectIntegrationPageMenuItemType.OVERVIEW,
        });
    }

    const communicationIntegrations = integrations
        .getIntegrationGroups()
        .communication.integrations(projectManager.project.id);

    $scope.data = {
        previewItems: {
            control: {
                customDefinedView: true,
            },
        },
        overrideActivateBotObject: {
            getInitialValue: () => false,
            shouldOverride: true,
            overrideValue: false,
        },

        editIntegrationDisplayName: false,
        editIntegrationDisplayNameLoading: false,

        showCreateFromJsonButton:
            projectManager.project.features.tonkean_feature_enable_create_project_integration_entities,
        isImportFromCreationJsonModalOpen: false,
        isEnterpriseComponentVariablesValuesModalOpen: false,

        hideGroupProjectIntegrations: false,

        projectIntegration: null,

        isFiltered: false,

        projectIntegrationSummaries: null,

        integToGroupMap: {},

        otherProjIntegrations: [],
        collapseGroups: {},

        isManageShown: true,
        isViewShown: true,
        isNoAccessShown: false,

        isLoading: false,

        projectIntegrationPersonAccessType: PersonPermissionRoleType,
    };

    /**
     * Initializes the controller.
     */
    $scope.init = function () {
        initializeStateObjects();
        $scope.data.numberOfCommunicationIntegrations =
            communicationIntegrationsService.getNumberOfConnectedIntegration();

        if ($scope.projectIntegartionId === 'new') {
            $scope.openAddNewIntegrationModal();
        }
        $scope.loadGroupsIntegrations();
    };

    $scope.navigateToProjectIntegrationPage = function (projectIntegrationId) {
        $state.go('product.projectIntegrationPage', {
            enterpriseComponentId: projectIntegrationId,
            page: ProjectIntegrationPageMenuItemType.OVERVIEW,
        });
    };

    function addProjectIntegrationToMaps(projectIntegration, group) {
        if (!$scope.data.integToGroupMap[projectIntegration.id]) {
            $scope.data.integToGroupMap[projectIntegration.id] = [];
        }

        if (!$scope.data.integToGroupMap[projectIntegration.id].includes(group)) {
            // save the relationship
            $scope.data.integToGroupMap[projectIntegration.id].push(group);
        }
    }

    $scope.loadGroupsIntegrations = async function () {
        $scope.data.isLoading = true;
        $scope.data.loadProjectIntegrationsErrorMessage = null;

        try {
            const summaryResult = await tonkeanService.getProjectIntegrationsSummaries($scope.pm.project.id, true);

            $scope.data.projectIntegrationSummaries = summaryResult.entities;
        } catch (error) {
            console.error(error);
            $scope.data.loadProjectIntegrationsErrorMessage = 'Error while loading data sources';
        } finally {
            $scope.data.isLoading = false;
        }

        $scope.pm.getGroups().then(function () {
            // Filter modules which were never published
            const groups = $scope.pm.groups.filter(
                (group) => group.workerType !== WORKER_TYPES.UNINITIALIZED.key,
            );

            for (const group of groups) {
                const groupPublishedWorkflowVersion = workflowVersionManager.getPublishedVersionFromCache(group.id);
                const groupDraftWorkflowVersion = workflowVersionManager.getDraftVersionFromCache(group.id);

                if (groupPublishedWorkflowVersion?.syncConfig?.projectIntegration) {
                    addProjectIntegrationToMaps(groupPublishedWorkflowVersion.syncConfig.projectIntegration, group);
                }

                if (groupDraftWorkflowVersion?.syncConfig?.projectIntegration) {
                    addProjectIntegrationToMaps(groupDraftWorkflowVersion.syncConfig.projectIntegration, group);
                }

                for (let j = 0; j < group.groupProjectIntegrations.length; j++) {
                    addProjectIntegrationToMaps(group.groupProjectIntegrations[j], group);
                }
            }
        });
    };

    $scope.getPermissionsText = (projectIntegration) => {
        switch (projectIntegration.accessType) {
            case PersonPermissionRoleType.ADMIN: {
                return 'Manage';

                break;
            }
            case PersonPermissionRoleType.MAKER: {
                return 'View';

                break;
            }
            case PersonPermissionRoleType.NOT_AUTHORIZED: {
                return 'Inaccessible';

                break;
            }
            default: {
                return 'No Access';
            }
        }
    };

    $scope.combineGroupNames = function (groups) {
        return groups.map((g) => g.name).join(', ');
    };

    /**
     * Happens once an integration is added or an integration is edited.
     */
    $scope.onIntegrationSaved = function () {
        initializeStateObjects();

        // check if removed and currently viewing the integration
        if (
            $scope.projectIntegartionId &&
            $scope.projectIntegartionId !== 'new' &&
            !$scope.data.projectIntegrationMap[$scope.projectIntegartionId]
        ) {
            $scope.cleanProjectIntegrationId();
        }
    };

    /**
     * Opens the add new integration modal.
     */
    $scope.openAddNewIntegrationModal = function (filter) {
        modalUtils.openAddIntegration(null, null, null, 'all', filter).result.finally(function () {
            if ($scope.projectIntegartionId === 'new') {
                $scope.cleanProjectIntegrationId();
            }
            $scope.loadGroupsIntegrations();
        });
    };

    $scope.openImportFromCreationJsonModal = function () {
        $scope.data.isImportFromCreationJsonModalOpen = true;
    };

    $scope.closeImportFromCreationJsonModal = function (createdIntegrationId) {
        $scope.data.isImportFromCreationJsonModalOpen = false;

        if (createdIntegrationId) {
            $scope.openEnterpriseComponentVariablesModal(createdIntegrationId);
        }
    };

    $scope.openEnterpriseComponentVariablesModal = function (createdIntegrationId) {
        $scope.data.creationJsonEnterpriseComponentId = createdIntegrationId;
        $scope.data.isEnterpriseComponentVariablesValuesModalOpen = true;
    };

    $scope.closeEnterpriseComponentVariablesModal = function () {
        $scope.data.isEnterpriseComponentVariablesValuesModalOpen = false;

        $state.go('product.projectIntegrationPage', {
            enterpriseComponentId: $scope.data.creationJsonEnterpriseComponentId,
            page: ProjectIntegrationPageMenuItemType.OVERVIEW,
        });
    };

    $scope.openEditCustomWebhookItemModal = function (existingItem) {
        const projectIntegration = $scope.data.projectIntegrationMap[$scope.projectIntegartionId];
        modalUtils.openEditCustomWebhookItemModal(projectIntegration, existingItem).result.then(() => {
            if ($scope.data.previewItems.selectedEntity) {
                $scope.loadData($scope.data.previewItems.selectedEntity, projectIntegration);
            }
        });
    };

    /**
     * Executes collect for the project integration.
     */
    $scope.runCollect = function (projectIntegration) {
        if ($scope.data.runningCollect === projectIntegration.id) {
            return;
        }

        $scope.data.runningCollect = projectIntegration.id;

        // Sync now command.
        integrationHelper.runCollectOnProjectIntegration(projectIntegration).then(() => {
            $scope.data.runningCollect = null;

            $scope.onCollectComplete(projectIntegration);
        });
    };

    $scope.isNotCommunicationIntegration = function (projectIntegration) {
        return !communicationIntegrations.includes(projectIntegration.integrationType.toLowerCase());
    };

    /**
     * Filters out all of the project integration which are 'communication' type or their creator (=owner)
     * is not the same as the current logged in user.
     */
    $scope.filterOtherUsersProjectIntegrations = function (projectIntegration) {
        return (
            !communicationIntegrations.includes(projectIntegration.integrationType.toLowerCase()) &&
            projectIntegration.creator.id === authenticationService.currentUser.id
        );
    };

    /**
     * Filters out all of the project integration which are 'communication' type or their creator (=owner)
     * is the same as the current logged in user.
     */
    $scope.filterCurrentUserProjectIntegrations = function (projectIntegration) {
        return (
            !communicationIntegrations.includes(projectIntegration.integrationType.toLowerCase()) &&
            projectIntegration.creator.id !== authenticationService.currentUser.id
        );
    };

    /**
     * Filters out all of the project integration which are 'communication' type or their creator (=owner)
     * is not the same as the current logged in user, unless user is the board admin.
     */
    $scope.filterProjectIntegrationsThatCanManage = function (projectIntegration) {
        return (
            !communicationIntegrations.includes(projectIntegration.integrationType.toLowerCase()) &&
            (projectIntegration.creator.id === authenticationService.currentUser.id ||
                $scope.pm.isOwner.id === authenticationService.currentUser.id) &&
            !integrations
                .getIntegrationGroups()
                .hideIntegrations.integrations.includes(projectIntegration.integrationType.toLowerCase())
        );
    };

    /**
     * Filters out all of the project integration by search term
     */
    $scope.filterProjectIntegrationsBySearchTerm = function (projectIntegration) {
        const projectIntegrationDisplayName = projectIntegration.displayName.toLowerCase();
        const integrationDisplayName = integrations
            .getIntegrationsConfig()
            [projectIntegration.integrationType.toLowerCase()].displayName.toLowerCase();
        const integrationType = projectIntegration.integrationType.replace('_', ' ').toLowerCase();

        return (
            !$scope.data.searchTerm ||
            !$scope.data.searchTerm.length ||
            integrationDisplayName.includes($scope.data.searchTerm.toLowerCase()) ||
            projectIntegrationDisplayName.includes($scope.data.searchTerm.toLowerCase()) ||
            integrationType.includes($scope.data.searchTerm.toLowerCase())
        );
    };

    $scope.filterProjectIntegrationsBySystemUtilized = function (projectIntegration) {
        return !projectIntegration.systemUtilized;
    };

    /**
     * Filters out all of the project integration by search term
     */
    $scope.filterProjectIntegrationsByCheckboxes = function (projectIntegration) {
        const accessType = projectIntegration.accessType;

        return (
            (accessType === PersonPermissionRoleType.ADMIN && $scope.data.isManageShown) ||
            (accessType === PersonPermissionRoleType.MAKER && $scope.data.isViewShown) ||
            (accessType === PersonPermissionRoleType.NOT_AUTHORIZED && $scope.data.isNoAccessShown)
        );
    };

    $scope.$on('newIntegrationAdded', function () {
        initializeStateObjects();
    });

    $scope.cleanProjectIntegrationId = function () {
        $scope.projectIntegartionId = null;
        $state.go('product.enterpriseComponents', { tab: 'data-sources', enterpriseComponentId: null });
    };

    $scope.loadData = function (
        selectedEntity,
        projectIntegration = $scope.data.projectIntegrationMap[$scope.projectIntegartionId],
    ) {
        $scope.data.previewItems.selectedEntity = null;
        $timeout(function () {
            $scope.data.previewItems.selectedEntity = selectedEntity;
            $scope.data.previewItems.entityType = selectedEntity.pluralLabel;
            $scope.data.previewItems.definition = compileViewData(
                projectIntegration,
                $scope.data.previewItems.entityType,
            );
        });
    };

    $scope.updateProjectIntegrationDisplayName = function (projectIntegration, displayName) {
        $scope.data.editIntegrationDisplayNameLoading = true;
        tonkeanService
            .updateEnterpriseComponentDisplayName(projectManager.project.id, projectIntegration.id, displayName)
            .then(() => {
                $scope.data.editIntegrationDisplayName = false;
                projectIntegration.displayName = displayName;
            })
            .finally(() => {
                $scope.data.editIntegrationDisplayNameLoading = false;
            });
    };

    $scope.onCollectComplete = (projectIntegration) => {
        if ($scope.data.previewItems.selectedEntity) {
            $scope.loadData($scope.data.previewItems.selectedEntity, projectIntegration);
        }
    };

    $scope.toggleFilter = (isBeingFiltered) => {
        if ($scope.data.isFiltered === isBeingFiltered) {
            return;
        }

        $scope.data.isFiltered = isBeingFiltered;

        // Clears conditions when selecting the all option
        if (!isBeingFiltered) {
            $scope.loadData($scope.data.previewItems.selectedEntity, $scope.data.projectIntegration);
        }
    };

    function compileViewData(projectIntegration, entity) {
        const compiledViewData = {};
        // Adding integration type to the existing definition as it is required for the control
        compiledViewData.integrationType = projectIntegration.integration.integrationType;
        compiledViewData.integrationUniqueType = projectIntegration.integration.integrationUniqueType;
        compiledViewData.entity = entity;

        // If we do not have a query set, that means the query is defined only by the user.
        // In such case, it is best for us to define a default match-all query to be used by all components.
        if (!compiledViewData.query) {
            compiledViewData.query = {
                type: 'And',
                filters: [],
                queries: [],
            };
        }
        return compiledViewData;
    }

    /**
     * Initializes the state object for tnkIntegrationsGroup directive to use.
     */
    function initializeStateObjectForAllProjectIntegrations() {
        $scope.projectIntegrationToStateMap = {};
        $scope.data.projectIntegrationMap = {};

        for (let i = 0; i < $scope.pm.project.integrations.length; i++) {
            const projectIntegration = $scope.pm.project.integrations[i];

            if (!$scope.projectIntegrationToStateMap[projectIntegration.id]) {
                $scope.projectIntegrationToStateMap[projectIntegration.id] = {
                    state: {},
                };

                $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                    projectIntegration.integration.integrationType.toLowerCase()
                ] = {
                    integrations: [projectIntegration],
                };
            } else {
                $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                    projectIntegration.integration.integrationType.toLowerCase()
                ].integrations[0].displayName = projectIntegration.displayName;
                $scope.projectIntegrationToStateMap[projectIntegration.id].state[
                    projectIntegration.integration.integrationType.toLowerCase()
                ].integrations[0].disabled = projectIntegration.disabled;
            }

            $scope.data.projectIntegrationMap[projectIntegration.id] = projectIntegration;
        }
    }

    /**
     * Initializes the state object for groups project integrations.
     */
    function initializeStateObjectForAllGroupsProjectIntegrations() {
        $scope.groupsProjectIntegrationToStateMap = {};
        $scope.data.groupsProjectIntegrationMap = {};
        $scope.data.hideGroupProjectIntegrations = true;

        // Filter modules which were never published
        const groups = $scope.pm.groups.filter(
            (group) => group.workerType !== WORKER_TYPES.UNINITIALIZED.key,
        );

        for (const group of groups) {
            $scope.groupsProjectIntegrationToStateMap[group.id] = {};
            $scope.data.groupsProjectIntegrationMap[group.id] = {};

            if (group.groupProjectIntegrations) {
                for (let j = 0; j < group.groupProjectIntegrations.length; j++) {
                    const projectIntegration = group.groupProjectIntegrations[j];
                    $scope.data.hideGroupProjectIntegrations = false;
                    if (!$scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id]) {
                        $scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id] = {
                            state: {},
                        };

                        $scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id].state[
                            projectIntegration.integration.integrationType.toLowerCase()
                        ] = {
                            integrations: [projectIntegration],
                        };
                    } else {
                        $scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id].state[
                            projectIntegration.integration.integrationType.toLowerCase()
                        ].integrations[0].displayName = projectIntegration.displayName;
                        $scope.groupsProjectIntegrationToStateMap[group.id][projectIntegration.id].state[
                            projectIntegration.integration.integrationType.toLowerCase()
                        ].integrations[0].disabled = projectIntegration.disabled;
                    }

                    $scope.data.groupsProjectIntegrationMap[group.id][projectIntegration.id] = projectIntegration;
                }
            }
        }
    }

    function initializeStateObjects() {
        initializeStateObjectForAllProjectIntegrations();
        initializeStateObjectForAllGroupsProjectIntegrations();

        $scope.data.projectIntegration = $scope.data.projectIntegrationMap[$scope.projectIntegartionId];
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('EditIntegrationsCtrl', EditIntegrationsCtrl);
