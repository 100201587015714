import React, { useMemo } from 'react';

import { SimpleSelect } from '@tonkean/infrastructure';
import { InterfaceIcon } from '@tonkean/svg';
import type { TonkeanType } from '@tonkean/tonkean-entities';
import { type ContractFieldSummary, ContractFieldType, type TonkeanId } from '@tonkean/tonkean-entities';

export interface ContractFieldSelectorProps {
    options: ContractFieldSummary[];
    selectedField?: TonkeanId<TonkeanType.CONTRACT_FIELD> | undefined;
    onChange?: (fieldId: TonkeanId<TonkeanType.CONTRACT_FIELD> | undefined) => void;
    name?: string;
    isClearable?: boolean;
    loading: boolean;
    disabled: boolean;
    contractFieldType: ContractFieldType;
}

function getPlaceholder(type: ContractFieldType) {
    if (type === ContractFieldType.ITEM_INTERFACE) {
        return 'Select an interface';
    }
    return 'Select a field';
}

const ContractFieldSelector: React.FC<ContractFieldSelectorProps> = ({
    onChange,
    selectedField,
    loading,
    options,
    contractFieldType,
    name,
    isClearable = false,
    disabled,
}) => {
    const placeholder = getPlaceholder(contractFieldType);

    const processedOptions = useMemo(() => {
        const icon = contractFieldType === ContractFieldType.ITEM_INTERFACE ? <InterfaceIcon /> : null;
        return options
            .filter((option) => option.contractFieldType === contractFieldType)
            .map((summary) => ({
                value: summary.contractFieldId,
                label: summary.displayName,
                icon,
            }));
    }, [contractFieldType, options]);

    return (
        <SimpleSelect
            placeholder={placeholder}
            isCreatable={false}
            options={processedOptions}
            isLoading={loading}
            getOptionValue={(option) => option.value}
            value={selectedField}
            onChange={onChange}
            isClearable={isClearable}
            readOnly={loading}
            isDisabled={disabled}
            name={name}
        />
    );
};

export default ContractFieldSelector;
