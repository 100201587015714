const validateMultipleFieldEditors = (validationObject, fieldsToUpdateObject, requireAtLeastOne, keyPrefix = '') => {
    const isFieldsEmpty = !fieldsToUpdateObject?.length;
    if (requireAtLeastOne && isFieldsEmpty) {
        validationObject[`${keyPrefix}fieldsToUpdate`] = 'Must select at least one field to update.';
    } else {
        fieldsToUpdateObject?.forEach((field, index) => {
            if (!field.fieldId) {
                validationObject[`${keyPrefix}fieldToUpdate-nofield-${index}`] = 'Must select a field to update.';
            }

            const isExpressionValue =
                field.inputTypeSelection === 'EXPRESSION' || field.inputTypeSelection === 'MULTIPLE_VALUES';
            if (isExpressionValue && !field.expressionValue?.evaluatedExpression) {
                validationObject[`${keyPrefix}fieldsToUpdate-${index}`] =
                    'Must enter a value, if you want to clear the value use the Empty Value option';
            }
        });
    }
};

export default validateMultipleFieldEditors;
