import * as React from 'react';

import DocumentPreviewModalContent from './DocumentPreviewModalContent';

import { CloseButton, Modal, ModalBody, ModalFooter, ModalHeader, ModalSize } from '@tonkean/infrastructure';
import type { TextExtractionFieldInformation } from '@tonkean/tonkean-entities';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

interface Props {
    open: boolean;
    initiativeId?: string;
    fieldDefinitionId?: string;
    textExtractionFieldInformation: TextExtractionFieldInformation;
    afterAnchorExpression?: string;
    expectedValue?: string;

    onClose(): void;
}

const DocumentPreviewModal: React.FC<Props> = ({
    open,
    onClose,
    initiativeId,
    fieldDefinitionId,
    textExtractionFieldInformation,
    expectedValue,
}) => {
    return (
        <Modal open={open} onClose={onClose} size={ModalSize.LARGE} fixedWidth>
            <ModalHeader>Preview Full Text</ModalHeader>
            <ModalBody>
                <DocumentPreviewModalContent
                    initiativeId={initiativeId}
                    fieldDefinitionId={fieldDefinitionId}
                    textExtractionFieldInformation={textExtractionFieldInformation}
                    expectedValue={expectedValue}
                />
            </ModalBody>
            <ModalFooter align="right" gap={10}>
                <CloseButton size={ButtonSize.MEDIUM} outlined>
                    Close
                </CloseButton>
            </ModalFooter>
        </Modal>
    );
};

export default DocumentPreviewModal;
