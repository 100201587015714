import { useEffect, useMemo } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

function useProjectIntegrationsDocumentsCountPerEntity(
    filteredTonkeanIds: TonkeanId<TonkeanType.PROJECT_INTEGRATION>[] = [],
) {
    const project = useProject();

    const [{ data, error, loading }, getProjectDocumentsCountPerIntegrationPerEntity] = useLazyTonkeanService(
        'getProjectDocumentsCountPerIntegrationPerEntity',
    );

    useEffect(() => {
        if (!data && !loading && !error) {
            getProjectDocumentsCountPerIntegrationPerEntity(project.id, filteredTonkeanIds);
        }
    }, [data, error, filteredTonkeanIds, getProjectDocumentsCountPerIntegrationPerEntity, loading, project.id]);

    const liveEntitiesData = useMemo<{
        entitiesCountMap: Map<string, number>;
        totalCount: number;
    }>(() => {
        const projectIntegrationTotalCountMap: Map<string, number> = new Map();
        let totalCountAllIntegrations: number = 0;
        if (data?.result) {
            Object.entries(data?.result).forEach((entityEntry) => {
                const valueMap: Record<string, number> = data.result[entityEntry[0]];
                const totalCount: number = Object.values(valueMap).reduce((a, b) => a + b, 0);
                totalCountAllIntegrations += totalCount;
                projectIntegrationTotalCountMap.set(entityEntry[0], totalCount);
            });
        }
        return { entitiesCountMap: projectIntegrationTotalCountMap, totalCount: totalCountAllIntegrations };
    }, [data?.result]);

    return {
        entitiesCountMap: liveEntitiesData.entitiesCountMap,
        totalEntitiesCount: liveEntitiesData.totalCount,
        loading,
        error,
    };
}

export default useProjectIntegrationsDocumentsCountPerEntity;
