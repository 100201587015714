import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import AdditionalTabIcon from './AdditionalTabIcon';
import FormulaPopoverAdditionalTab from './FormulaPopoverAdditionalTab';
import FormulaPopoverTabConst from './FormulaPopoverTabConst';
import FormulaPopoverTabFormula from './FormulaPopoverTabFormula';
import FormulaPopoverTabPreset from './FormulaPopoverTabPreset';
import FormulaPopoverTabVariable from './FormulaPopoverTabVariable';
import type SharedFormulaNodeProps from '../../entities/SharedFormulaNodeProps';
import { ReactComponent as FormulaBuilderPopoverTabPresetsIcon } from '../../images/formula-builder-popover-tab-presets.svg';

import { CalculatorIcon } from '@tonkean/svg';
import { TextInputLgIcon } from '@tonkean/svg';
import { ColumnsIcon } from '@tonkean/svg';
import type { FormulaOperatorDefinitionBase } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import { FormulaTreeConstNode } from '@tonkean/tonkean-entities';
import { FormulaTreeVariableNode } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { getOperandTree } from '@tonkean/tonkean-entities';
import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { formulaTabConfiguration } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

interface NavButtonProps {
    active?: boolean;
    remove?: boolean;
}

const FormulaTabButton = styled.button<NavButtonProps>`
    outline: none;
    height: 35px;
    width: 45px;
    border: none;
    border-right: 1px solid #d8d8d8;
    color: ${Theme.colors.gray_500};
    font-size: 20px;
    background: ${Theme.colors.basicBackground};

    ${({ remove }) =>
        remove &&
        css`
            border-right: none;
            border-left: 1px solid #d8d8d8;
        `}

    ${({ active }) =>
        active &&
        css`
            background-color: ${Theme.colors.HEX_F2F2F2};
            cursor: default;
        `}
`;

const FormulaPopoverHeader = styled.nav`
    padding: 5px 10px;
    font-weight: bold;
    font-size: ${FontSize.MSMALL_13};
`;

interface Props extends Omit<SharedFormulaNodeProps, 'canDelete' | 'disabled'> {
    workflowVersionId: string;
    node: formulaTreeNode;
    depth: number;
    isWrapMode?: boolean;
    deleteInPopover?: boolean;
    closePopover: () => void;
    onInnerTrackAggregationSelected?: () => void;
}

const FormulaTreeNodePopoverTabs: React.FC<Props> = ({
    workflowVersionId,
    node,
    depth,
    isWrapMode = false,
    deleteInPopover = false,
    closePopover,
    onNodeChanged,
    onNodeDeleted,
    onInnerTrackAggregationSelected,
    additionalTabs = [],
    customTrigger,
    projectIntegration,
}) => {
    const customFieldsManager = useAngularService('customFieldsManager');
    const modal = useAngularService('modal');

    const filterRef = useRef<HTMLInputElement>(null);

    const [activeTab, setActiveTab] = useState<string>(() => {
        // Calculate default tab
        if (isWrapMode) {
            return FormulaPopoverTab.FORMULA;
        }

        // Show default type if it's an empty node or a manual node with a value from the presets tab. Otherwise,
        // show the tab that relates to the node type.
        switch (node.type) {
            case FormulaTreeNodeType.CONST:
                // If this is a manual field, and it's from the preset tab, make it the default tab
                if (
                    node.field?.defaultTab === FormulaPopoverTab.PRESET &&
                    node.field.presetValues?.includes(node.value)
                ) {
                    return FormulaPopoverTab.PRESET;
                }
                return FormulaPopoverTab.CONST;

            case FormulaTreeNodeType.EMPTY:
                return node.field?.defaultTab ?? !workflowVersionId?.length
                    ? FormulaPopoverTab.FORMULA
                    : FormulaPopoverTab.VARIABLE;

            case FormulaTreeNodeType.VARIABLE:
                return FormulaPopoverTab.VARIABLE;

            case FormulaTreeNodeType.TREE:
                return FormulaPopoverTab.FORMULA;
        }
    });
    const [filter, setFilter] = useState('');

    const displayName = node.toNodeDisplayName();

    useEffect(() => {
        filterRef.current?.focus();
    }, []);

    const canSelectInnerTrackAggregation = !!onInnerTrackAggregationSelected;

    const onFormulaOperatorSelected = (operator: FormulaOperatorDefinitionBase) => {
        if (operator.key === OperatorKey.INNER_TRACK_AGGREGATION) {
            onInnerTrackAggregationSelected?.();
            return;
        }

        let existingOperands: formulaTreeNode[] | undefined = undefined;
        if (isWrapMode) {
            existingOperands = [node];
        } else if (node.type === FormulaTreeNodeType.TREE) {
            const hasNonOperandsFields = node.operands.some((operand) => operand.type !== FormulaTreeNodeType.EMPTY);
            existingOperands = node.operands.length && hasNonOperandsFields ? node.operands : undefined;
        }
        const newTree = getOperandTree(operator, node.field, existingOperands);

        onNodeChanged(newTree, node);
        closePopover();
    };

    const onManualValueSelected = (value: string, dataType: FieldType) => {
        onNodeChanged(new FormulaTreeConstNode(dataType, value, node.field), node);
        closePopover();
    };

    const onFieldDefinitionSelected = (fieldDefinition: any) => {
        onNodeChanged(
            new FormulaTreeVariableNode(
                fieldDefinition.id,
                fieldDefinition.label || fieldDefinition.name,
                fieldDefinition.displayAs,
                node.field,
            ),
            node,
        );
        closePopover();
    };

    const { getProjectIntegrationById } = useAngularService('projectManager');

    const getCustomFieldFormulaTree = useCallback(
        (field: any) => new FormulaTreeVariableNode(field.id, field.name || field.label, FieldType.String, node.field),
        [node.field],
    );

    const onFieldSelectedInInspect = useCallback(
        (field, _, idRelationFieldDefinitionId) => {
            onNodeChanged(getCustomFieldFormulaTree(field), node);
            closePopover();
        },
        [onNodeChanged, getCustomFieldFormulaTree, node, closePopover],
    );

    const onCustomFieldSelected = useCallback(
        async (field: any) => {
            if (!field.quickCreationField) {
                onNodeChanged(getCustomFieldFormulaTree(field), node);
                return closePopover();
            }

            if (field.projectIntegrationId) {
                const projectIntegration = await getProjectIntegrationById(field.projectIntegrationId);

                return modal.openFieldInspectModal(
                    projectIntegration,
                    field.entity,
                    null,
                    onFieldSelectedInInspect,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    field.shouldHaveEntityPrefix,
                    field.id,
                    null,
                    null,
                    false,
                );
            }
        },
        [
            onNodeChanged,
            getCustomFieldFormulaTree,
            node,
            closePopover,
            getProjectIntegrationById,
            modal,
            onFieldSelectedInInspect,
        ],
    );

    const selectedAdditionalTab = useMemo(
        () => additionalTabs.find((singleAdditionalTab) => activeTab === singleAdditionalTab.id),
        [activeTab, additionalTabs],
    );

    const allowedTabsEntries: (FormulaPopoverTab | boolean)[][] = Object.keys(FormulaPopoverTab).map(
        (tabKey: FormulaPopoverTab) => [
            tabKey,
            node.field.allowedTabsOnly ? node.field.allowedTabsOnly.includes(tabKey) : true,
        ],
    );
    const allowedTabs: Record<FormulaPopoverTab, boolean> = Object.fromEntries(allowedTabsEntries);

    return (
        <div className="tabs">
            <FormulaPopoverHeader>{displayName}</FormulaPopoverHeader>

            <nav className="flex">
                {allowedTabs.FORMULA && (
                    <FormulaTabButton
                        onClick={() => setActiveTab(FormulaPopoverTab.FORMULA)}
                        active={activeTab === FormulaPopoverTab.FORMULA}
                        uib-tooltip="Functions"
                    >
                        <i className="svg-icon-md">
                            <span className="tnk-icon">
                                <CalculatorIcon />
                            </span>
                        </i>
                    </FormulaTabButton>
                )}
                {!isWrapMode && (
                    <>
                        {allowedTabs.CONST && (
                            <FormulaTabButton
                                onClick={() => setActiveTab(FormulaPopoverTab.CONST)}
                                active={activeTab === FormulaPopoverTab.CONST}
                                uib-tooltip="Manual"
                                data-automation="formula-tree-node-popover-tabs-manual"
                            >
                                <i className="svg-icon-md">
                                    <span className="tnk-icon">
                                        <TextInputLgIcon />
                                    </span>
                                </i>
                            </FormulaTabButton>
                        )}

                        {allowedTabs.VARIABLE && workflowVersionId?.length > 0 && (
                            <FormulaTabButton
                                onClick={() => setActiveTab(FormulaPopoverTab.VARIABLE)}
                                active={activeTab === FormulaPopoverTab.VARIABLE}
                                uib-tooltip="Fields"
                            >
                                <i className="svg-icon-md">
                                    <span className="tnk-icon">
                                        <ColumnsIcon />
                                    </span>
                                </i>
                            </FormulaTabButton>
                        )}
                        {allowedTabs.PRESET && node.field?.presetValues?.length && (
                            <FormulaTabButton
                                onClick={() => setActiveTab(FormulaPopoverTab.PRESET)}
                                active={activeTab === FormulaPopoverTab.PRESET}
                                uib-tooltip="Fields"
                            >
                                <i className="svg-icon-md">
                                    <span className="tnk-icon">
                                        <FormulaBuilderPopoverTabPresetsIcon />
                                    </span>
                                </i>
                            </FormulaTabButton>
                        )}
                    </>
                )}
                {additionalTabs.map((singleAdditionalTab) => (
                    <FormulaTabButton
                        key={singleAdditionalTab.id}
                        onClick={() => setActiveTab(singleAdditionalTab.id)}
                        active={activeTab === singleAdditionalTab.id}
                        uib-tooltip={singleAdditionalTab.label}
                    >
                        <i className="svg-icon-md">
                            <span className="tnk-icon">
                                <AdditionalTabIcon iconClass={singleAdditionalTab.iconClass} />
                            </span>
                        </i>
                    </FormulaTabButton>
                ))}
                {deleteInPopover && (
                    <>
                        <div className="flex-grow" />
                        <FormulaTabButton
                            type="button"
                            onClick={() => onNodeDeleted(node)}
                            uib-tooltip="Delete parameter"
                            remove
                        >
                            <i className="fa fa-trash" />
                        </FormulaTabButton>
                    </>
                )}
            </nav>
            <div className="tabs-field-selector">
                <div className="tabs-field-selector-tab-content inline-select-list-wrapper">
                    {formulaTabConfiguration[activeTab]?.hasSearch && (
                        <input
                            type="search"
                            className="form-control tabs-field-selector-tab-search"
                            value={filter}
                            onChange={({ target }) => setFilter(target.value)}
                            placeholder={`Search for a ${formulaTabConfiguration[activeTab].searchItemLabel} ...`}
                            ref={filterRef}
                            data-automation="formula-node-popover-field-selector-search"
                        />
                    )}
                    {selectedAdditionalTab &&
                        selectedAdditionalTab.id === activeTab &&
                        selectedAdditionalTab.searchPlaceholder && (
                            <input
                                type="search"
                                className="form-control tabs-field-selector-tab-search"
                                value={filter}
                                onChange={({ target }) => setFilter(target.value)}
                                placeholder={selectedAdditionalTab.searchPlaceholder}
                                ref={filterRef}
                            />
                        )}
                    {activeTab === FormulaPopoverTab.FORMULA && (
                        <FormulaPopoverTabFormula
                            depth={depth}
                            customTrigger={customTrigger}
                            projectIntegration={projectIntegration}
                            canSelectInnerTrackAggregation={canSelectInnerTrackAggregation}
                            onSelected={onFormulaOperatorSelected}
                            filter={filter}
                        />
                    )}

                    {activeTab === FormulaPopoverTab.CONST && (
                        <FormulaPopoverTabConst
                            allowedTypes={node.field?.dataType}
                            defaultType={node.type === FormulaTreeNodeType.CONST ? node.dataType : undefined}
                            defaultValue={node.type === FormulaTreeNodeType.CONST ? node.value : undefined}
                            isHTMLTag={node.field.isHTMLTag}
                            onSelected={onManualValueSelected}
                        />
                    )}

                    {workflowVersionId?.length > 0 && activeTab === FormulaPopoverTab.VARIABLE && (
                        <FormulaPopoverTabVariable
                            customFieldsManager={customFieldsManager}
                            workflowVersionId={workflowVersionId}
                            onSelected={onFieldDefinitionSelected}
                            filter={filter}
                        />
                    )}

                    {activeTab === FormulaPopoverTab.PRESET && node.field?.presetValues && (
                        <FormulaPopoverTabPreset
                            presetValues={node.field?.presetValues}
                            onSelected={onManualValueSelected}
                            filter={filter}
                        />
                    )}
                    {selectedAdditionalTab && selectedAdditionalTab.id === activeTab && (
                        <FormulaPopoverAdditionalTab
                            onSelected={onCustomFieldSelected}
                            additionalTab={selectedAdditionalTab}
                            filter={filter}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormulaTreeNodePopoverTabs;
