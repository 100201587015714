import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ChannelCheckerSelectorCtrl($scope, communicationIntegrationsService, projectManager, tonkeanService) {
    const ctrl = this;
    $scope.cis = communicationIntegrationsService;

    // Holding this variable only for the template of error.
    $scope.botName = null;

    $scope.data = {
        selectedChannel: ctrl.selectedChannel,
        integration: ctrl.integration,

        // Server call to validate slack channel.
        loadingPrivateSlackChannel: false,
        errorLoadingPrivateSlackChannel: false,

        // Selected private channel name.
        privateChannelName: null,
        validatedPrivateChannel: false,
        privateChannelNotFound: false,
        channelType: ctrl.channelType,

        // Holding this variable only for the template of error.
        wrongPrivateChannelName: null,

        communicationIntegration: communicationIntegrationsService.getFirstThatSupports(
            communicationIntegrationsService.features.channels,
        ),
    };

    /**
     * Initialization function of the component.
     */
    ctrl.$onInit = function () {
        if ($scope.data.selectedChannel) {
            // Delete the # from the beginning of the channel name.
            if ($scope.data.selectedChannel && $scope.data.selectedChannel.charAt(0) === '#') {
                $scope.data.selectedChannel = $scope.data.selectedChannel.slice(1);
            }

            $scope.data.privateChannelName = $scope.data.selectedChannel;
            $scope.data.validatedPrivateChannel = true;
        }

        if (ctrl.integration) {
            $scope.data.communicationIntegration = ctrl.integration;
        }
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.integration && changesObj.integration.currentValue) {
            $scope.data.integration = ctrl.integration;
            $scope.data.communicationIntegration = ctrl.integration;
            $scope.data.privateChannelName = null;
        }
    };

    /**
     * Occurs once the private channel name is changed.
     */
    $scope.onPrivateChannelNameChanged = function () {
        $scope.data.validatedPrivateChannel = false;
        $scope.onChannelSelected();
    };

    /**
     * Validates the selected channel.
     */
    $scope.validateChannel = function () {
        $scope.data.loadingPrivateSlackChannel = true;
        $scope.data.errorLoadingPrivateSlackChannel = false;

        $scope.data.validatedPrivateChannel = false;
        $scope.data.privateChannelNotFound = false;

        let checkPromise;
        if ($scope.data.integration) {
            checkPromise = tonkeanService.checkSlackChannel(
                projectManager.project.id,
                $scope.data.integration.id,
                $scope.data.privateChannelName,
                $scope.data.channelType
            );
        } else {
            checkPromise = tonkeanService.checkNotificationChannel(
                projectManager.project.id,
                tonkeanService.groupNotifications.private,
                $scope.data.privateChannelName,
            );
        }

        checkPromise
            .then((data) => {
                $scope.botName = data.botName;

                // Currently will only happen if going specifically to checkPrivateSlackChannel
                if (data.notificationSettings) {
                    $scope.data.privateChannelId = data.notificationSettings.channelId;
                }

                if (data.isValid) {
                    $scope.data.validatedPrivateChannel = true;
                    $scope.data.privateChannelNotFound = false;
                } else {
                    $scope.data.validatedPrivateChannel = false;
                    $scope.data.privateChannelNotFound = true;
                    $scope.data.wrongPrivateChannelName = $scope.data.privateChannelName;
                }
            })
            .catch(function (error) {
                $scope.data.validatedPrivateChannel = false;

                if (error.status === 404) {
                    // The private channel was not found.
                    $scope.data.privateChannelNotFound = false;
                    $scope.data.wrongPrivateChannelName = $scope.data.privateChannelName;
                } else {
                    // Something else failed.
                    $scope.data.errorLoadingPrivateSlackChannel = true;
                }
            })
            .finally(function () {
                $scope.data.loadingPrivateSlackChannel = false;

                $scope.onChannelSelected();
            });
    };

    /**
     * Occurs once a private slack channel is selected.
     */
    $scope.onChannelSelected = function () {
        const channelSelectionObject = {
            selectedChannel: $scope.data.privateChannelName,
            selectedChannelId: $scope.data.privateChannelId,
            validatedPrivateChannel: $scope.data.validatedPrivateChannel,
            privateChannelNotFound: $scope.data.privateChannelNotFound,
        };

        if (ctrl.onPrivateChannelSelected) {
            ctrl.onPrivateChannelSelected({
                channelSelectionObject,
            });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('ChannelCheckerSelectorCtrl', lateConstructController(ChannelCheckerSelectorCtrl));
