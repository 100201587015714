<div
    ng-controller="AdvancedSyncCtrl"
    ng-init="valueResults['ALL_ENTITIES_SUPPORTED'] = integrations.getAllEntitiesSupportedIntegrations()[integrationsResults['PRODUCT'].integration.integrationType.toLowerCase()]; syncSettings = integrations.getSyncSettings()[integrationsResults['PRODUCT'].integration.integrationType.toLowerCase()]; valueResults['SYNC_ENTITY'] ? valueResults['SYNC_ENTITY'] : valueResults['SYNC_ENTITY'] = syncSettings.supportedEntities[0]; valueResults['SYNC_CONSTS'] = syncSettings; valueResults['SYNC_ENTITY_TYPE'] = valueResults['SYNC_ENTITY']"
>
    <div class="common-size-xs">
        <strong>Product name:</strong>
        <input
            type="text"
            class="form-control inline-block margin-left setup-group-input-width"
            ng-model="valueResults['PRODUCT_NAME']"
            placeholder="Product name..."
            required
        />
    </div>

    <hr />

    <div ng-init="addDefaultsToInitiativesResults()">
        <div class="common-size-xs margin-bottom">
            <strong>What milestones should we track?</strong>
        </div>
        <div class="btn-group" ng-if="integrationsResults['PRODUCT']">
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': !valueResults['USE_MANUALLY'] }"
                uib-btn-radio="false"
                ng-model="ignore"
                ng-click="valueResults['USE_MANUALLY'] = false"
            >
                Take from {{ integrationsResults['PRODUCT'].displayName }}
            </label>
            <label
                class="btn btn-default"
                ng-class="{ 'btn-primary': valueResults['USE_MANUALLY'] }"
                uib-btn-radio="true"
                ng-model="ignore"
                ng-click="valueResults['USE_MANUALLY'] = true"
            >
                Enter manually
            </label>
        </div>
        <hr ng-if="integrationsResults['PRODUCT']" />

        <!--use sync-->
        <div
            ng-show="!valueResults['USE_MANUALLY']"
            ng-if="integrationsResults['PRODUCT']"
            ng-init="initAdvancedSync()"
        >
            <div
                ng-init="syncSettings = integrations.getSyncSettings()[integrationsResults['PRODUCT'].integration.integrationType.toLowerCase()]; valueResults['SYNC_ENTITY'] ? valueResults['SYNC_ENTITY'] : valueResults['SYNC_ENTITY'] = syncSettings.supportedEntities[0]; valueResults['SYNC_CONSTS'] = syncSettings"
                class="margin-normal-v"
            >
                <strong class="margin-right-xs">Use {{ integrationsResults['PRODUCT'].displayName }}'s:</strong>
                <tnk-entity-selector
                    project-integration="integrationsResults['PRODUCT']"
                    selected-entity="{{ valueResults['SYNC_ENTITY'] }}"
                    mode="sync"
                    on-entity-selected="selectEntityOption(selectedEntity)"
                ></tnk-entity-selector>
            </div>
            <div class="common-color-dark-grey common-size-xxs margin-bottom-xs">
                You can filter by project, topic, or any other relevant condition
            </div>

            <div ng-init="initAdvancedSync();">
                <div
                    ng-init="data.filtersSelectedEntityType = valueResults['ALL_ENTITIES_SUPPORTED'] ? valueResults['SYNC_ENTITY'] : syncSettings[valueResults['SYNC_ENTITY']].entityType;"
                >
                    <tnk-custom-filters
                        control="valueResults['SYNC_CONTROL']"
                        items-source="EXTERNAL"
                        existing-definition="valueResults['SYNC_DEFINITION']"
                        project-integration="integrationsResults['PRODUCT']"
                        from-sync="true"
                        edit-mode="false"
                        selected-entity-type="data.filtersSelectedEntityType"
                        hide-time-range-selection="true"
                        show-field-inspect="true"
                    ></tnk-custom-filters>
                </div>
            </div>
        </div>

        <!--enter manually-->
        <div ng-if="valueResults['USE_MANUALLY'] || !integrationsResults['PRODUCT']">
            <div class="common-color-dark-grey common-size-xxs margin-bottom" ng-init="initiativeLable = 'Milestone'">
                Enter your milestones names, due date and primary owner (team/function lead)
            </div>
            <hr />
            <div ng-include="'../directives/manualAddTracksTemplate.template.html'"></div>
        </div>
    </div>
</div>
