import { lateConstructController } from '@tonkean/angular-components';
import { DeprecatedDate } from "@tonkean/utils";

/* @ngInject */
function InboxCtrl($scope, $localStorage, botHistoryHelper, communicationIntegrationsService, projectManager) {
    const ctrl = this;

    $scope.cis = communicationIntegrationsService;
    $scope.pm = projectManager;

    $scope.filters = [
        createFilterObj('All', false),
        createFilterObj('Read', true),
        createFilterObj('Unread', true, true),
    ];

    $scope.data = {
        loading: false,
        userCommunicationIntegration: null,
        newItemsAvailable: false,
        forceCloseNewItemsAvailable: false,
        newItemsCount: 0,
        totalCount: 0,
        selectedFilter: $scope.filters[0],
        items: [],
    };

    $scope.init = function () {
        $scope.data.userCommunicationIntegration = $scope.cis.getFirstIntegration();

        $scope.loadMyHistory();
    };

    $scope.loadMyHistory = function (loadAfter) {
        $scope.data.loading = true;
        let before = null;
        let after = null;
        if ($scope.data.items && $scope.data.items.length) {
            if (loadAfter) {
                const firstItem = $scope.data.items[0];
                after = firstItem.created;
            } else {
                const lastItem = $scope.data.items[$scope.data.items.length - 1];
                before = lastItem.created;
            }
        }

        return botHistoryHelper.loadMyInbox($scope.pm.project.id, before, after).then((data) => {
            $scope.data.totalCount = data.totalCount;

            for (let i = 0; i < data.inbox.length; i++) {
                const item = data.inbox[i];
                for (let j = 0; j < $scope.data.items.length; j++) {
                    const existingItem = $scope.data.items[j];
                    // If the new gather is on the same initiative as one of the existing ones, we must take out the old item.
                    // Order doesn't matter because we order it in ngRepeat
                    if (existingItem.initiative.id === item.initiative.id) {
                        $scope.data.items.splice(j, 1);
                        break;
                    }
                }

                // In any case, push the new item.
                $scope.data.items.push(item);
            }

            const lastViewedTime = getLastViewedTime();
            $scope.data.newItemsCount = $scope.data.items.filter((item) => item.created > lastViewedTime).length;
            ctrl.onNewItemsCountUpdated({ newItemCount: $scope.data.newItemsCount });

            if (loadAfter) {
                $scope.data.forceCloseNewItemsAvailable = false;
                $scope.data.newItemsAvailable = false;
            }
            $scope.data.loading = false;
        });
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.isOpen && changesObj.isOpen.currentValue) {
            updateLastViewedTime();
            ctrl.onNewItemsCountUpdated({ newItemCount: 0 });

            if ($scope.data.newItemsAvailable) {
                $scope.data.forceCloseNewItemsAvailable = true;
                $scope.loadMyHistory(true);
            }
        }
    };

    $scope.setFilter = function (filter) {
        $scope.data.selectedFilter = filter;
    };

    $scope.onLoadNewClick = function () {
        $scope.data.forceCloseNewItemsAvailable = true;
        $scope.loadMyHistory(true);
    };

    function getLastViewedTime() {
        if (!$localStorage.lastInboxWatch || !$localStorage.lastInboxWatch[$scope.pm.project.id]) {
            return 0;
        }

        return $localStorage.lastInboxWatch[$scope.pm.project.id];
    }

    function updateLastViewedTime() {
        if (!$localStorage.lastInboxWatch) {
            $localStorage.lastInboxWatch = {};
        }

        $localStorage.lastInboxWatch[$scope.pm.project.id] = DeprecatedDate.nowAsDate();
    }

    function createFilterObj(label, shouldFilter, showUnanswered) {
        return {
            label,
            fieldName: shouldFilter ? 'answers' : null,
            showUnanswered,
        };
    }

    $scope.init();
}
export default angular.module('tonkean.app').controller('InboxCtrl', lateConstructController(InboxCtrl));
