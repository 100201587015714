import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import InterfaceViewersPopover from './InterfaceViewersPopover';
import useGetIntakeRequestViewers from '../hooks/useGetIntakeRequestViewers';

import { PersonAvatarDisplay, Popover } from '@tonkean/infrastructure';
import type { PersonAvatar, TonkeanId } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { debouncer } from '@tonkean/utils';

const AvatarRowWrapper = styled(Clickable)`
    display: flex;
    border-radius: 50px;
    border: 1px solid #e9ecf1;
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.03);
    padding: 2px 3px;
    cursor: pointer;
`;

const AvatarWrapper = styled.div<{ $isLast: boolean }>`
    margin-right: ${({ $isLast }) => ($isLast ? '0px' : '-4px')};
`;

const PlusAvatar = styled.div<{ $color?: string }>`
    width: 34px;
    height: 34px;
    color: white;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;

    border-radius: 62px;
    border: 2px solid #fff;
    background: ${(props) => (props.$color ? props.$color : 'gray')};
`;

interface Props {
    initiativeId: TonkeanId;
    itemInterfaceId: TonkeanId;
    themePrimaryColor: string;
    howManyViewersToShow: number;
}

const InterfaceViewers: React.FC<Props> = ({
    initiativeId,
    itemInterfaceId,
    themePrimaryColor,
    howManyViewersToShow,
}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const { getIntakeRequestViewers, viewersResponse } = useGetIntakeRequestViewers();

    useEffect(() => {
        getIntakeRequestViewers(initiativeId, itemInterfaceId);
    }, [getIntakeRequestViewers, initiativeId, itemInterfaceId]);

    const calculatedPersonAvatars = useMemo(() => {
        if (!viewersResponse?.viewers) return [];
        const now = Date.now();
        // If the last viewing time of the user is less than 75 seconds ago, we'll consider him as he's viewing the interface right now.
        const numberOfSecondsToConsiderAsViewingNow = 75;

        return viewersResponse?.viewers.map((viewer) => {
            return {
                personId: viewer.personId,
                avatarUrl: viewer.avatarUrl,
                isViewingNow: now - new Date(viewer.lastView).getTime() < numberOfSecondsToConsiderAsViewingNow * 1000,
                lastView: viewer.lastView,
                title: viewer.title,
                name: viewer.name,
            };
        });
    }, [viewersResponse?.viewers]);

    const shouldShowPlusAvatar = useMemo(() => {
        return (viewersResponse?.viewers?.length ?? 0) > howManyViewersToShow;
    }, [howManyViewersToShow, viewersResponse?.viewers]);

    const toggleVisiblityDebounce = useMemo(() => {
        return debouncer(100);
    }, []);

    return (
        <>
            {calculatedPersonAvatars?.length > 0 && (
                <Popover
                    content={
                        <InterfaceViewersPopover viewers={calculatedPersonAvatars} themeColor={themePrimaryColor} />
                    }
                    onClose={() => {
                        toggleVisiblityDebounce(() => {
                            setPopoverOpen(false);
                        });
                    }}
                    show={popoverOpen}
                >
                    <AvatarRowWrapper
                        data-automation="avatar-row-wrapper"
                        onClick={() => {
                            toggleVisiblityDebounce(() => {
                                setPopoverOpen(!popoverOpen);
                            });
                        }}
                    >
                        {calculatedPersonAvatars?.slice(0, howManyViewersToShow).map((person: PersonAvatar, index) => (
                            <AvatarWrapper key={person.personId} $isLast={index === calculatedPersonAvatars.length - 1}>
                                <PersonAvatarDisplay
                                    person={person}
                                    themeColor={themePrimaryColor}
                                    showBorder={person.isViewingNow}
                                    disableTooltip={popoverOpen}
                                />
                            </AvatarWrapper>
                        ))}
                        {shouldShowPlusAvatar && (
                            <PlusAvatar data-automation="plus-avatar" $color={themePrimaryColor}>
                                +{calculatedPersonAvatars.length - 3}
                            </PlusAvatar>
                        )}
                    </AvatarRowWrapper>
                </Popover>
            )}
        </>
    );
};

export default InterfaceViewers;
