import template from './tnkNoGatherUpdatePopover.template.html?angularjs';
import { TrackActions } from '@tonkean/flux';
import { analyticsWrapper } from '@tonkean/analytics';

/**
 * A wrapping directive for noGatherUpdatePopoverTemplate.
 * This popover presumes that the nextGatherUpdate is null, otherwise it wouldn't be opened.
 */
function tnkNoGatherUpdatePopover() {
    return {
        restrict: 'E',
        scope: {
            initiative: '=initiative',
            groupId: '<',
            editorId: '<',
            placement: '@',
            isOpen: '=',
            setDueDateCallback: '&',
            displayInPlace: '<',
        },
        template,
        controller: NoGatherUpdatePopoverCtrl,
    };
}

angular.module('tonkean.app').directive('tnkNoGatherUpdatePopover', tnkNoGatherUpdatePopover);

function NoGatherUpdatePopoverCtrl(
    $scope,
    $rootScope,
    modal,
    createProjectApis,
    utils,
    trackHelper,
    initiativeCache,
    currentUserHelper,
    workflowVersionManager,
    workflowVersionHelper,
    licenseManager,
    authenticationService,
) {
    $scope.pm = $rootScope.pm;
    $scope.as = authenticationService;
    $scope.trackHelper = trackHelper;
    $scope.itemMap = initiativeCache.getInitiativesCache();
    $scope.utils = utils;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        addingSeat: false,
        askingToAddOwner: false,
        askingToAddOwnerSuccess: false,

        loadingTurningBotMessagesOn: true,
        errorLoadingTurningBotMessagesOn: false,
    };

    $scope.$watch('initiative.id', function () {
        if ($scope.initiative && $scope.initiative.id) {
            $scope.item = $scope.trackHelper.getInitiativeFromCache($scope.initiative.id);
            $scope.group = $scope.pm.groupsMap[$scope.itemMap[$scope.item.trackId].group.id];
            $scope.workflowVersion = $scope.item.isDraftInitiative
                ? workflowVersionManager.getDraftVersionFromCache($scope.item.group.id)
                : workflowVersionManager.getPublishedVersionFromCache($scope.item.group.id);
            $scope.workflowVersionHasTriggers = workflowVersionHelper.workflowVersionHasTriggers(
                $scope.workflowVersion.id,
            );
            $scope.isOwnerCurrentUser = $scope.itemMap[$scope.item.trackId].owner.id === $scope.as.currentUser.id;
            $scope.isLinkedItem = $scope.item.id !== $scope.item.trackId;
            $scope.canOwnerUpdate = $rootScope.lps.canPersonUpdate($scope.item.owner);
        }
    });

    $scope.onPingManuallyClicked = function (event) {
        // Stop the propagation so React doesn't think a click outside happened for the set reminder dropdown.
        event.stopPropagation();
        // Close ourselves manually.
        $scope.isOpen = false;
        // Open the set reminder dropdown.
        TrackActions.toggleTrackSetReminderDropdown($scope.initiative.id, $scope.editorId, true);
    };

    $scope.toggleIsBotActive = function () {
        modal.openBotToggleValidationModal(false, function () {
            workflowVersionManager.toggleShouldSendGatherUpdates($scope.group.id, true);
        });
    };

    $scope.askToAddOwner = function (person) {
        analyticsWrapper.track('Seat requested', { category: 'License' });
        $scope.data.askingToAddOwner = true;
        $scope.data.askingToAddOwnerSuccess = false;
        createProjectApis
            .requestFullAccess($scope.pm.project.id, person.id)
            .then(() => {
                $scope.$emit('alert', { msg: 'Request sent', type: 'success' });
                $scope.data.askingToAddOwnerSuccess = true;
            })
            .finally(() => {
                $scope.data.askingToAddOwner = false;
            });
    };

    function tryAddSeatAndUpdateTracks(personId) {
        return licenseManager
            .tryToAddSeatToUser(personId)
            .then((updatedPerson) => {
                trackHelper.updateAllTracksWithOwner(
                    updatedPerson.id,
                    // Update initiative
                    (initiative) => {
                        if (updatedPerson.projectContext) {
                            initiative.owner.projectContext.isLicensed = updatedPerson.projectContext.isLicensed;
                        }
                    },
                );
            })
            .catch(() => {
                trackHelper.updateAllTracksWithOwner(
                    personId,
                    // Revert initiative
                    (initiative) => {
                        if (initiative.owner.projectContext && initiative.owner.projectContext.pendingSeatGrab) {
                            delete initiative.owner.projectContext.pendingSeatGrab;
                        }
                    },
                );
                return $q.reject();
            })
            .finally(() => {
                TrackActions.newFullDataUpdate();
            });
    }

    $scope.tryToAddSeatToUser = function (personId) {
        $scope.data.addingSeat = true;
        tryAddSeatAndUpdateTracks(personId).finally(() => {
            $scope.data.addingSeat = false;
            $scope.isOpen = false;
        });
    };

    $scope.turnBotMessagesOn = function () {
        $scope.isOpen = false;

        $scope.data.loadingTurningBotMessagesOn = true;
        $scope.data.errorLoadingTurningBotMessagesOn = false;

        currentUserHelper
            .removeNoGatherUpdateOptionFromCurrentUser()
            .catch(() => {
                $scope.data.errorLoadingTurningBotMessagesOn = true;
            })
            .finally(() => {
                $scope.data.loadingTurningBotMessagesOn = false;
            });
    };
}

angular.module('tonkean.app').controller('NoGatherUpdatePopoverCtrl', NoGatherUpdatePopoverCtrl);
