<!-- Definition type selection -->
<div class="btn-group margin-bottom-lg" ng-if="data.duplicateOrCreateMode">
    <!-- Condition -->
    <label
        class="btn btn-default"
        ng-class="{ 'btn-primary': !data.customJql }"
        uib-btn-radio="true"
        ng-model="ignore"
        ng-click="toggleFieldDefinitionType()"
    >
        Condition
    </label>

    <!-- Label -->
    <label
        class="btn btn-default"
        ng-class="{ 'btn-primary': data.customJql }"
        uib-btn-radio="false"
        ng-model="ignore"
        ng-click="toggleFieldDefinitionType()"
    >
        JQL
    </label>
</div>

<!-- JQL -->
<div ng-if="data.customJql">
    <div class="flex-vmiddle margin-top flex-xs-wrap">
        <!-- Title -->
        <span class="flex-no-shrink flex-self-start margin-right create-field-definition-modal-label margin-top-xs">
            Query to count:
        </span>

        <div class="flex-grow">
            <!-- JQL Input -->
            <textarea
                class="form-control common-resize-y-only"
                ng-model="data.jql"
                ng-change="onJqlChanged()"
                autocomplete="off"
                placeholder="Enter field JQL query"
                required
            ></textarea>

            <!-- Title keyword addition -->
            <div
                class="flex mod-align-baseline common-subtitle-inner common-size-xxs margin-top-xs"
                ng-hide="data.targetType === 'GLOBAL'"
            >
                <i class="fa fa-info-circle margin-right-xs"></i>
                <span>
                    You can use the keyword&nbsp;
                    <a ng-click="addKeywordToJQL('[title]')" class="common-color-primary">[title]</a>
                    &nbsp;to query by the track name.
                </span>
            </div>
        </div>
    </div>
    <hr />

    <!-- Explanation -->
    <div class="margin-top-lg">
        <span class="flex-no-shrink flex-self-start margin-right initiative-form-label margin-top-xs">
            Example JQL:
        </span>
        <span class="common-size-xxxxs">
            The {{ data.targetType === 'COLUMN' ? 'Column' : 'Key Metric' }} value will be the "count" of issues result
            of this query
        </span>

        <div class="common-subtitle-inner margin-top-xs">
            <pre class="common-size-xxxxs">
project = "{{ pm.project.name }}" AND {{
                    data.targetType === 'COLUMN' ? 'labels = [title] AND ' : ''
                }}type = Bug AND status != Done</pre
            >
        </div>
        <div class="common-subtitle-inner margin-top-xs">
            <pre class="common-size-xxxxs">
project = "{{ pm.project.name }}" AND {{
                    data.targetType === 'COLUMN' ? '"Epic Link" = [title] AND ' : ''
                }}type = Story</pre
            >
        </div>
    </div>
    <hr />
</div>

<!-- Custom conditions -->
<div ng-if="!data.customJql" class="margin-top">
    <tnk-integration-aggregate-field
        project-integration="data.projectIntegration"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        target-type="data.targetType"
        create-mode="data.createMode"
        duplicate-mode="data.duplicateMode"
        field-definition-name="data.fieldDefinitionName"
        field-definition-name-edited="data.fieldDefinitionNameEdited"
        existing-field-definition="data.existingFieldDefinition"
        on-definition-change="onDefinitionChange(newDefinition, doNotReloadPreview, actualFieldDefinitionType)"
    ></tnk-integration-aggregate-field>
</div>
