import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ModuleItemIcon } from '../../../../images/icons/history/module-item.svg';
import { ReactComponent as ModuleIcon } from '../../../../images/icons/module-purpule.svg';
import { ReactComponent as SolutionSitePageIcon } from '../../../../images/icons/site-page-version.svg';

import { TonkeanType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

interface IconProps {
    entityType?: TonkeanType;
}

const IconWrapper = styled.div`
    width: 18px;
    margin-right: 8px;
    display: flex;
`;

const SolutionVersionsSingleRowIcon: React.FC<React.PropsWithChildren<IconProps>> = ({ entityType }) => {
    return <IconWrapper>{entityType === TonkeanType.GROUP ? <ModuleIcon /> : <SolutionSitePageIcon />}</IconWrapper>;
};

const SingleChangedGroup = styled.div`
    height: 36px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
        border-bottom: 1px solid ${Theme.colors.gray_300};
    }
`;

const GroupIcon = styled.div<{ hadChanged: boolean }>`
    border-radius: 100px;
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    flex-shrink: 0;

    ${({ hadChanged = false }) =>
        hadChanged &&
        css`
            background: linear-gradient(180deg, ${Theme.colors.basicBackground} 0%, #f5f5f5 100%);
            border: 1px solid ${Theme.colors.success};
            box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.05);
        `};

    .tnk-icon {
        display: block;

        svg {
            display: block;
            height: 12px;
            width: 12px;
        }
    }
`;

const GroupName = styled.div<{ hadChanged: boolean }>`
    font-size: ${({ hadChanged = false }) => (hadChanged ? FontSize.SMALL_12 : FontSize.XSMALL_10)};
    line-height: 12px;
    color: ${({ hadChanged = false }) => (hadChanged ? Theme.colors.gray_800 : Theme.colors.gray_700)};
    flex-grow: 1;
`;

interface Props {
    hadChanged: boolean;
    entityType?: TonkeanType;
}

const SolutionVersionsSingleGroupRow: React.FC<React.PropsWithChildren<Props>> = ({
    hadChanged,
    entityType,
    children,
}) => {
    return (
        <SingleChangedGroup>
            {hadChanged ? (
                <SolutionVersionsSingleRowIcon entityType={entityType} />
            ) : (
                <GroupIcon hadChanged={hadChanged}>
                    <span className="tnk-icon">
                        <ModuleItemIcon />
                    </span>
                </GroupIcon>
            )}
            <GroupName hadChanged={hadChanged}>{children}</GroupName>
        </SingleChangedGroup>
    );
};

export default SolutionVersionsSingleGroupRow;
