import type { IHttpResponse } from 'angular';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import type { GetProjectIntegrationsSummaries } from '@tonkean/tonkean-entities';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

interface ProjectIntegrationPageContextDefinition {
    projectId: string | undefined;

    setCurrentProjectIntegration: Dispatch<SetStateAction<ProjectIntegration>>;
    currentProjectIntegration: ProjectIntegration | undefined;

    projectIntegrationSummaries: GetProjectIntegrationsSummaries | undefined;
    loadingProjectIntegrations: boolean;
    errorLoadingProjectIntegrations: IHttpResponse<any> | undefined;
}

const ProjectIntegrationPageContext = React.createContext<ProjectIntegrationPageContextDefinition>({
    projectId: undefined,

    setCurrentProjectIntegration: () => {},
    currentProjectIntegration: undefined,

    projectIntegrationSummaries: undefined,
    loadingProjectIntegrations: false,
    errorLoadingProjectIntegrations: undefined,
});

export default ProjectIntegrationPageContext;
