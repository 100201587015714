import { InterfaceQuickNavigationMode } from '@tonkean/tonkean-entities';

export const shouldHideWidget = (
    hideOverride: boolean | undefined,
    isInBuilder: boolean,
    quickNavigationMode: InterfaceQuickNavigationMode,
    tabIdOfCurrentWidget: string | undefined,
    selectedWidgetId: string | undefined,
) => {
    if (isInBuilder || quickNavigationMode !== InterfaceQuickNavigationMode.TABS) {
        return false;
    }

    if (hideOverride !== undefined) {
        return hideOverride;
    }

    return tabIdOfCurrentWidget !== selectedWidgetId;
};
