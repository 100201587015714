import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function BotGathersFeedCtrl($scope, projectManager) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        isVisible: ctrl.isVisible,

        isShowingFuture: false,
        ownerPopoverIsOpen: false,
        isCurrentListSelected: false,

        currentGroup: null,
        filter: {},
    };

    $scope.$watch('pm.currentlyViewedGroupId', () => {
        const newCurrentGroup = $scope.pm.groupsMap[$scope.pm.currentlyViewedGroupId];

        if ($scope.data.isCurrentListSelected) {
            $scope.setGroupFilter(newCurrentGroup, true);
        }

        $scope.data.currentGroup = newCurrentGroup;
    });

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.isVisible) {
            $scope.data.isVisible = changesObj.isVisible.currentValue;
        }
    };

    $scope.setFilter = function (group, owner) {
        $scope.data.filter = {
            group,
            owner,
        };
    };

    $scope.setGroupFilter = function (group, currentListSelected) {
        $scope.data.isCurrentListSelected = currentListSelected;
        $scope.setFilter(group, $scope.data.filter.owner);
    };

    $scope.onFilterByOwner = function (owner) {
        $scope.setFilter($scope.data.filter.group, owner);
        $scope.data.ownerPopoverIsOpen = false;
    };
}
export default angular.module('tonkean.app').controller('BotGathersFeedCtrl', lateConstructController(BotGathersFeedCtrl));
