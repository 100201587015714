<div class="common-size-xxxl ta-preview http-request-preview-modal">
    <button class="btn btn-primary copy-to-clipboard-btn" ng-click="copyToClipboard()">Copy To Clipboard</button>

    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <div class="flex-vmiddle">
        <span class="loading-small margin-right margin-bottom" ng-if="data.evaluatedExpressionLoading"></span>
        <!-- Modal title -->
        <h1 class="common-size-l common-bold mod-no-top">HTTP Request Preview</h1>
    </div>

    <!-- Seperator-->
    <hr />

    <div class="common-code-content">
        <span>curl -X {{ curl.method }} \</span>
        <br />
        <div class="margin-left-md">
            <span class="common-color-black">{{ curl.url }} \</span>
            <div ng-repeat="header in curl.headers">
                <span class="common-color-black">-H</span>
                <span>'{{ header.name.evaluatedExpression }}:</span>
                <span>{{ header.value.evaluatedExpression }}' \</span>
            </div>
            <div ng-if="curl.method !== 'GET' && !data.options.isHttpUploadAction">
                <span class="common-color-black">-d</span>
                <span>'{{ curl.body }}'</span>
            </div>
            <div ng-if="data.options.isHttpUploadAction">
                <span class="common-color-black">-d</span>
                <span>FILE STREAM</span>
            </div>
        </div>
    </div>
</div>
