import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { FieldSelector } from '@tonkean/fields';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { Field, SimpleSelect } from '@tonkean/infrastructure';
import { NArrowUpIcon as ArrowUpIcon } from '@tonkean/svg';
import { NArrowDownIcon as ArrowDownIcon } from '@tonkean/svg';
import { FieldType, SortOrderType } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { EMPTY_ARRAY } from '@tonkean/utils';

const FieldLabel = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
`;

const StyledField = styled(Field)`
    margin-top: 16px;
`;

const orderTypeOptions: SimpleSelectSingleOption<SortOrderType>[] = [
    {
        value: SortOrderType.ASC,
        label: 'Ascending',
        icon: <ArrowUpIcon />,
    },
    {
        value: SortOrderType.DESC,
        label: 'Descending',
        icon: <ArrowDownIcon />,
    },
];

const orderByFieldTypeOptions: SimpleSelectSingleOption<FieldType>[] = [
    {
        value: FieldType.String,
        label: 'Textual',
    },
    {
        value: FieldType.Number,
        label: 'Number',
    },
    {
        value: FieldType.Date,
        label: 'Date',
    },
];

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
}

const InnerItemsItemWidgetDefaultOrderConfiguration: React.FC<Props> = ({ workflowVersionId }) => {
    const [{ value: fieldDefinitionId }, , { setValue: setFieldDefinitionId }] = useField<string | undefined>(
        'configuration.sortByField.fieldDefinitionId',
    );

    const [{ value: orderType }, , { setValue: setOrderType }] = useField<string | undefined>(
        'configuration.sortByField.orderType',
    );

    const { setValue: setOrderByFieldType } = useField<FieldType>('configuration.sortByField.orderByFieldType')[2];

    const { data: fieldsDefinitions, loading: fieldDefinitionsLoading } = useTonkeanService(
        'getFieldDefinitions',
        workflowVersionId,
    );

    return (
        <>
            <Field label={<FieldLabel>Sort by field</FieldLabel>} light>
                <FieldSelector
                    fieldsDefinitions={fieldsDefinitions?.entities || EMPTY_ARRAY}
                    loading={fieldDefinitionsLoading}
                    onChange={(selectedValue: string | undefined, fieldType: FieldType | undefined) => {
                        setFieldDefinitionId(selectedValue);

                        if (!orderType) {
                            setOrderType(SortOrderType.ASC);
                        }

                        switch (fieldType) {
                            case FieldType.Date:
                                setOrderByFieldType(FieldType.Date);
                                break;

                            case FieldType.LongString:
                            case FieldType.String:
                                setOrderByFieldType(FieldType.String);
                                break;

                            case FieldType.Number:
                                setOrderByFieldType(FieldType.Number);
                                break;
                            default:
                                break;
                        }
                    }}
                    fieldDefinitionId={fieldDefinitionId}
                    includeSpecialFields
                    isClearable
                />
            </Field>

            {fieldDefinitionId && (
                <>
                    <StyledField label={<FieldLabel>Sort Order</FieldLabel>} light>
                        <SimpleSelect name="configuration.sortByField.orderType" options={orderTypeOptions} />
                    </StyledField>

                    <StyledField label={<FieldLabel>Sort order method</FieldLabel>} light>
                        <SimpleSelect
                            name="configuration.sortByField.orderByFieldType"
                            options={orderByFieldTypeOptions}
                        />
                    </StyledField>
                </>
            )}
        </>
    );
};

export default InnerItemsItemWidgetDefaultOrderConfiguration;
