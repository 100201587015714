<button
    ng-if="data.allErrorsNotRestrictingErrors"
    ng-click="submitForm(true)"
    class="btn btn-secondary margin-right back-button"
    ng-disabled="data.savingFormAnswer || data.disableSubmit"
    class="btn btn-secondary margin-right save-and-continue-button"
    ng-style="{
        'color': data.buttonsColor ,
        'border-color': data.buttonsColor,
    }"
>
    Save and Continue
    <i class="loading-small margin-left-xs" ng-if="data.savingFormAnswer && data.savingIgnoring"></i>
</button>

<button
    data-automation="tnk-submit-form-button-template"
    ng-click="submitForm(false)"
    class="btn submit-form-button"
    ng-class="data.formFinished ? 'btn-secondary' : 'btn-primary'"
    ng-style="{
            'background-color': data.formFinished ? '' : data.buttonsColor,
            'color': data.formFinished ? data.buttonsColor : '',
            'border-color': data.formFinished ? data.buttonsColor : '',
        }"
    ng-disabled="data.savingFormAnswer || data.disableSubmit"
    data-automation="submit-form"
>
    {{ data.label || 'Submit' }}
    <i class="loading-small margin-left-xs" ng-if="data.savingFormAnswer && !data.savingIgnoring"></i>
</button>
