import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useInitiativeNavigationContext } from '../../hooks/useIntitiativeNavigationContext';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { Initiative } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';

const StyledSimpleSelect = styled(SimpleSelect)`
    min-width: 200px;
`;

interface Props {
    initiative: Initiative;
}

const ItemsDropdownNavigation: React.FC<Props> = ({ initiative }) => {
    const $location = useAngularService('$location');
    const [loading, setLoading] = useState(false);
    const { items } = useInitiativeNavigationContext();

    const itemsOptions = useMemo(() => {
        return items?.map((item) => ({ label: item.title, value: item.id }));
    }, [items]);

    const styles = {
        control: (base, state) => ({
            borderColor: state.isFocused ? base.borderColor : 'transparent !important',
            fontSize: FontSize.MEDIUM_14,
            fontWeight: 'bold',
            '&:hover': {
                borderColor: `${base.borderColor} !important`,
            },
        }),
        valueContainer: (base, state) => ({
            padding: '0 0 0 2px',
        }),
    };

    const changeInitiative = useCallback(
        (itemId: string = '') => {
            if (itemId !== initiative.id) {
                setLoading(true);
                $location.search('tid', itemId);
            }
        },
        [$location, initiative.id],
    );

    return (
        <StyledSimpleSelect
            placeholder="Pick Item"
            value={initiative.id}
            isLoading={loading}
            options={itemsOptions}
            onChange={changeInitiative}
            styles={styles}
            thin
        />
    );
};

export default ItemsDropdownNavigation;
