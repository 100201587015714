<div data-automation="field-inspect-modal" class="field-inspect-container">
    <div class="field-inspect-content">
        <!-- Initial loading state -->
        <div ng-if="data.loadingInitialData || loadingGroup">
            <!-- Title of loading state -->
            <div class="common-size-xxxs common-bold common-color-dark-grey">
                <div ng-if="data.fieldQuery">Searching for a field or value that match what you've typed...</div>
                <div ng-if="!data.fieldQuery">Loading fields from {{ data.projectIntegration.displayName }}...</div>
            </div>
            <!-- Field search part of the loading state -->
            <div class="margin-top-lg">
                <!-- For when there's a search -->
                <div class="loading-fake-search common-size-xxs" ng-if="data.fieldQuery">
                    <span class="margin-right-xs svg-icon-sm common-color-grey">
                        <tnk-icon src="../../../../svg/src/icons/search.svg"></tnk-icon>
                    </span>
                    {{ data.fieldQuery }}
                </div>
                <!-- For when there's NO search -->
                <div ng-if="!data.fieldQuery">
                    <div class="field-inspect-loading-placeholder mod-long"></div>
                    <div class="field-inspect-loading-placeholder mod-short margin-top-xs"></div>
                </div>
            </div>
            <!-- Content part of the loading state -->
            <div class="loading-fake-content margin-top-lg">
                <div></div>
                <div>
                    <div>
                        <div class="field-inspect-loading-placeholder mod-long"></div>
                    </div>
                    <div>
                        <div class="field-inspect-loading-placeholder mod-medium"></div>
                    </div>
                    <div>
                        <div class="field-inspect-loading-placeholder mod-long"></div>
                    </div>
                    <div>
                        <div class="field-inspect-loading-placeholder mod-medium"></div>
                    </div>
                    <div>
                        <div class="field-inspect-loading-placeholder mod-long"></div>
                    </div>
                    <div>
                        <div class="field-inspect-loading-placeholder mod-medium"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content (when NOT loading) -->
        <div ng-if="!data.loadingInitialData && !data.loadingGroup">
            <div class="common-size-xxs">
                <div class="flex-vmiddle">
                    <div ng-if="!data.isForMatchedItem" class="common-bold common-size-xxxs flex-grow">
                        Search your data for the fields you need:
                    </div>
                    <div ng-if="data.isForMatchedItem" class="common-bold common-size-xxxs flex-grow">
                        Search your item data for the fields you need:
                    </div>
                    <div ng-if="data.projectIntegration && !data.isForMatchedItem" class="relative margin-right-lg">
                        <!-- Integration logo when icon url is NOT available -->
                        <div ng-if="!data.projectIntegration.iconUrl">
                            <i
                                class="integration-group-header-connected-logo hidden-xs margin-none mod-{{
                                    data.projectIntegration.integration.integrationType.toLowerCase()
                                }}"
                            ></i>
                        </div>
                        <!-- Integration logo when icon url IS available -->
                        <div
                            ng-if="data.projectIntegration.iconUrl"
                            class="integration-group-header-connected-logo hidden-xs mod-manual-icon margin-none"
                        >
                            <i
                                class="initiative-integration-icon mod-normal margin-right-xs"
                                ng-style="{
                                    background: 'url(' + data.projectIntegration.iconUrl + ') no-repeat center'
                                }"
                            ></i>
                        </div>
                    </div>
                    <div
                        ng-if="data.isForMatchedItem && data.targetGroup"
                        class="relative common-bold common-size margin-right-lg"
                    >
                        {{ data.targetGroup.name }}
                    </div>
                </div>
                <div class="margin-top-md">
                    <!-- When we have selected example entity. -->
                    <!-- If any entities exist for the given external type, we will always have an entity -->
                    <!-- If not, we show the fields but tell the user he doesn't have an  -->
                    <div ng-if="data.selectedExampleEntity && !data.staticData">
                        <div class="common-color-grey field-inspect-tiny-title">
                            EXAMPLE {{ data.selectedExampleEntity.externalTypeDisplayName | uppercase }}
                        </div>
                        <!-- Example entity -->
                        <div class="relative">
                            <!-- Selected example entity -->
                            <div class="flex-vmiddle" ng-show="!data.changeEntityToggle">
                                <!-- Entity name -->
                                <div
                                    data-automation="field-inspect-example-item"
                                    class="
                                        common-title-xxs
                                        margin-right-xs
                                        field-inspect-selected-entity
                                        common-ellipsis
                                    "
                                >
                                    {{ data.selectedExampleEntity.title }}
                                </div>
                                <!-- Change button -->
                                <span class="common-color-link-blue">
                                    <span ng-click="toggleEntitySearch(true)" class="common-link-no-style pointer">
                                        Change
                                    </span>
                                </span>
                            </div>
                            <!-- Example entity search -->
                            <div ng-if="data.changeEntityToggle">
                                <ui-select
                                    id="field-inspect-entity-selector"
                                    class="field-inspect-entity-selector relative"
                                    ng-class="{ 'mod-loading': data.loadingExampleEntities }"
                                    theme="bootstrap"
                                    ng-model="data.selectedExampleEntity"
                                    on-select="selectExampleEntity($item, $event)"
                                    ng-click="onEntitySelected($event)"
                                    click-outside="toggleEntitySearch(false)"
                                >
                                    <ui-select-match
                                        placeholder="Search {{::data.selectedExampleEntity.externalTypePluralName}}"
                                    >
                                        <span>{{ data.selectedExampleEntity.title }}</span>
                                    </ui-select-match>
                                    <!-- refresh is the function to refresh options while the user is typing. -->
                                    <!-- refresh-delay is 0 because our refresh function does the throttling itself (ui-select doesn't do a good job). -->
                                    <ui-select-choices
                                        repeat="exampleEntity in data.exampleEntities | filter:$select.search"
                                        refresh="loadExampleEntitiesThrottled($select.search)"
                                        refresh-delay="0"
                                    >
                                        <div>{{ exampleEntity.title }}</div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>
                    </div>
                    <!-- When we don't have a selected example entity. -->
                    <div
                        ng-if="!data.selectedExampleEntity && !data.staticData && !data.isForMatchedItem"
                        class="common-color-dark-grey common-size-xxs"
                    >
                        We couldn't find any
                        {{ data.externalTypeDisplayName || data.externalType | lowercase }} entities in your data.
                        <br />
                        Below are the fields we know about:
                    </div>

                    <div
                        ng-if="!data.selectedExampleEntity && !data.staticData && data.isForMatchedItem"
                        class="common-color-dark-grey common-size-xxs"
                    >
                        We couldn’t find any items ֿ{{ data.isForMatchedItem ? '' : 'or fields' }} in the module
                    </div>

                    <!-- Field search input -->
                    <div class="margin-top-lg">
                        <div class="field-inspect-field-search">
                            <input
                                id="field-inspect-field-search-input"
                                type="text"
                                ng-model="data.fieldQuery"
                                ng-trim="false"
                                class="form-control common-width-full"
                                placeholder="Search field names or values"
                                autocomplete="off"
                                ng-change="populateEntityFieldsWhileTyping()"
                                ng-keydown="onSearchFieldKeyDown($event)"
                                data-automation="field-inspect-template-write-search-field-name"
                            />
                            <!-- Search icon within the input -->
                            <span class="svg-icon-sm common-color-dark-grey field-inspect-field-search-icon">
                                <tnk-icon src="../../../../svg/src/icons/search.svg"></tnk-icon>
                            </span>
                            <!-- Clear "x" icon within the input -->
                            <span
                                class="svg-icon-xs common-color-grey field-inspect-field-search-x"
                                ng-if="data.fieldQuery && data.fieldQuery.length"
                                ng-click="clearQuery()"
                            >
                                <tnk-icon src="../../../../svg/src/icons/x.svg"></tnk-icon>
                            </span>
                        </div>
                    </div>

                    <div
                        ng-if="data.fieldQuery && data.fieldQuery !== data.fieldQuery.trim()"
                        class="common-bold margin-bottom-md margin-top"
                    >
                        Did you mean "
                        <a href="#" ng-click="trimSpaces($event)">{{ data.fieldQuery.trim() }}</a>
                        ", without the surrounding spaces?
                    </div>

                    <!-- Fields table (name and value) -->
                    <div
                        id="field-inspect-table"
                        class="field-inspect-table margin-top"
                        ng-hide="!data.entityFields.length"
                    >
                        <!-- Headers -->
                        <div class="field-inspect-table-line mod-header">
                            <div>Field name</div>
                            <div>Value</div>
                        </div>
                        <!-- Rows -->
                        <tnk-field-inspect-list
                            entity-fields="data.entityFields"
                            on-field-selected="(!data.viewOnly ? onFieldSelected : undefined)"
                            view-only="data.viewOnly"
                            field-query="data.fieldQuery"
                            multi-select-map="data.multiSelectMap"
                        ></tnk-field-inspect-list>
                    </div>

                    <!-- Fields table empty state (when there's no match for the query) -->
                    <div class="margin-top" ng-show="!data.entityFields.length">
                        <div ng-if="data.fieldQuery">
                            We couldn't find any fields or values named
                            <strong>{{ data.fieldQuery }}</strong>
                            .
                        </div>

                        <br />
                        <div class="field-inspect-field-search-no-results margin-top">
                            <div>
                                <span class="svg-icon-nav-sm margin-right-xs">
                                    <tnk-icon src="../../../../svg/src/icons/info-o.svg"></tnk-icon>
                                </span>
                                <strong>Try these search tips:</strong>
                                <ul class="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                                    <li>
                                        <div class="padding-left-xxs">
                                            Search for the
                                            <strong>field's value</strong>
                                            . The field might exist but have a different name.
                                        </div>
                                    </li>
                                    <li>
                                        <div class="padding-left-xxs">
                                            <strong>
                                                Change the example
                                                {{ data.selectedExampleEntity.externalTypeDisplayName | lowercase }}
                                            </strong>
                                            to one you're more familiar with.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- when multi value-->
    <div ng-if="data.multiSelect" class="text-right margin-top">
        <button
            class="btn btn-primary"
            data-automation="field-inspect-template-add-selected"
            ng-click="resolveMultiSelection()"
        >
            Add Selected
        </button>
    </div>
</div>
