import React, { useMemo } from 'react';
import styled from 'styled-components';

import SolutionReportListContext, { useCreateSolutionReportListContextValue } from './SolutionReportListContext';
import SolutionReportsListItem from './SolutionReportsListItem';
import { ReactComponent as EmptyStateSolutionList } from '../../../../images/icons/solution-list-empty-state.svg';

import { StateMessage } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SolutionReportsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 13px;
`;

const EmptyStateTitle = styled.span`
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
`;

const EmptyStateSubtitle = styled.span`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_600};
`;

interface Props {
    workflowFolder: WorkflowFolder;
}

const SolutionReportsList: React.FC<Props> = ({ workflowFolder }) => {
    const solutionReportListValue = useCreateSolutionReportListContextValue(workflowFolder);

    const allReportPairs = useMemo(
        () => Object.entries(solutionReportListValue.allReports),
        [solutionReportListValue.allReports],
    );

    return (
        <SolutionReportsListContainer>
            <SolutionReportListContext.Provider value={solutionReportListValue}>
                {!solutionReportListValue.isLoading &&
                    allReportPairs.length !== 0 &&
                    allReportPairs.map(([id, solution]) => (
                        <SolutionReportsListItem
                            key={id}
                            solutionReport={solution}
                            solutionReportId={id}
                            loading={solutionReportListValue.isLoading}
                        />
                    ))}
                {!solutionReportListValue.isLoading && allReportPairs.length === 0 && (
                    <StateMessage icon={<EmptyStateSolutionList />} title="No business reports">
                        Create a module to add reports
                    </StateMessage>
                )}
                {solutionReportListValue.isLoading && (
                    <>
                        <SolutionReportsListItem solutionReport={undefined} solutionReportId={undefined} loading />
                        <SolutionReportsListItem solutionReport={undefined} solutionReportId={undefined} loading />
                        <SolutionReportsListItem solutionReport={undefined} solutionReportId={undefined} loading />
                        <SolutionReportsListItem solutionReport={undefined} solutionReportId={undefined} loading />
                    </>
                )}
            </SolutionReportListContext.Provider>
        </SolutionReportsListContainer>
    );
};

export default SolutionReportsList;
