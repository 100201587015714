import React from 'react';

import type { ItemWidgetEditorProps } from '../../../../WidgetModule';
import ActionsItemWidgetConfigurationEditorDisplay from '../../components/ActionsItemWidgetConfigurationEditorDisplay';

import type { ActionsItemWidgetConfiguration } from '@tonkean/tonkean-entities';

const ItemInterfaceActionsItemWidgetConfigurationEditor: React.FC<
    ItemWidgetEditorProps<ActionsItemWidgetConfiguration>
> = ({ workflowVersion }) => {
    return <ActionsItemWidgetConfigurationEditorDisplay workflowVersion={workflowVersion} />;
};

export default ItemInterfaceActionsItemWidgetConfigurationEditor;
