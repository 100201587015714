<div class="workflow-item-selection">
    <ui-select
        theme="bootstrap"
        ng-model="data.selectedSpecificItem"
        ng-change="onSpecificItemSelected(data.selectedSpecificItem)"
    >
        <ui-select-match placeholder="Search...">{{ data.selectedSpecificItem.displayName }}</ui-select-match>
        <ui-select-choices
            repeat="p in data.specificItemsSelectionOptions | filter:$select.search"
            ui-disable-choice="data.viewOnly"
        >
            <div
                class="common-ellipsis" data-automation="tnk-workflow-item-selection-template-workflow-item"
                uib-tooltip="{{ p.fullName | highlight: $select.search }}"
                tooltip-append-to-body="true"
                ng-bind-html="p.displayName | highlight: $select.search"
            ></div>
        </ui-select-choices>
    </ui-select>
</div>
