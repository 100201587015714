import React, { useContext, useMemo } from 'react';

import CustomInterfaceView from '../../../components/CustomInterfaceView';
import InterfaceQuickNavigationContext from '../../../components/InterfaceQuickNavigationContext';
import { ItemWidget, type ItemWidgetProps } from '../../../WidgetModule';
import useMatchedItems from '../../ActionsWidgetModule/components/actionButtons/useMatchedItems';
import { FieldsWidgetDisplayType, FieldsWidgetLoadingSkeleton } from '../../FieldsWidgetModule';
import type { IncludeInterfaceWidgetConfiguration } from '../IncludeInterfaceWidgetConfiguration';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { InterfaceQuickNavigationMode } from '@tonkean/tonkean-entities';

const InnerInterfaceLoadingComponent: React.FC = () => (
    <FieldsWidgetLoadingSkeleton fieldDisplay={FieldsWidgetDisplayType.LIST} singleField />
);

const ItemInterfaceIncludeInterfaceWidget: React.FC<ItemWidgetProps<IncludeInterfaceWidgetConfiguration>> = ({
    widget,
    permission,
    initiative,
}) => {
    const { section, includedWidgetsSummaryByInterfaceId } = useItemInterfaceContext();
    const { tabIdByWidgetId, selectedWidgetId, quickNavigationMode } = useContext(InterfaceQuickNavigationContext);

    const includedItemInterfaceId = widget.configuration.matchingItemInterfaceId;

    const anyInnerWidgetsDisplayed = useMemo(() => {
        // In standard mode all widgets are shown
        if (quickNavigationMode === InterfaceQuickNavigationMode.STANDARD) {
            return true;
        }
        const includedWidgetsSummaries =
            includedItemInterfaceId && includedWidgetsSummaryByInterfaceId?.[includedItemInterfaceId];
        if (!includedWidgetsSummaries) {
            // if no included widgets this is probably a configuration problem so display to user so that they can fix
            return true;
        }

        const anyIncludedWidgetInCurrentTab = includedWidgetsSummaries.some(
            (widgetSummary) => tabIdByWidgetId?.[widgetSummary.id] === selectedWidgetId,
        );
        return anyIncludedWidgetInCurrentTab;
    }, [
        quickNavigationMode,
        includedItemInterfaceId,
        includedWidgetsSummaryByInterfaceId,
        selectedWidgetId,
        tabIdByWidgetId,
    ]);

    const matchedItemsData = useMatchedItems(widget.workflowVersionId, initiative);
    const targetMatchedItemData = useMemo(() => {
        return matchedItemsData.find(
            (data) => data.fieldDefinition.id === widget.configuration.matchingFieldDefinitionId,
        );
    }, [widget.configuration.matchingFieldDefinitionId, matchedItemsData]);

    const targetInitiative = widget.configuration.matchingFieldDefinitionId
        ? targetMatchedItemData?.matchedInitiative
        : initiative;

    return (
        <ItemWidget
            permission={permission}
            containerOverflowOverride="visible"
            hideOverride={!anyInnerWidgetsDisplayed}
            disableMinHeight
            disableMaxHeight
            noBorderBody
            noPaddingBody
        >
            {includedItemInterfaceId && targetInitiative ? (
                <CustomInterfaceView
                    itemInterfaceId={includedItemInterfaceId}
                    initiativeId={targetInitiative.id}
                    originWidget={undefined}
                    projectId={widget.projectId}
                    CustomLoadingComponent={InnerInterfaceLoadingComponent}
                    showTitle={false}
                    section={section}
                    inheritValidationContext
                    inheritQuickNavigationContext
                    cleanMode
                />
            ) : (
                <InnerInterfaceLoadingComponent />
            )}
        </ItemWidget>
    );
};

export default ItemInterfaceIncludeInterfaceWidget;
