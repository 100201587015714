import React, { useContext } from 'react';
import styled from 'styled-components';

import WorkerRunsEmptySwitch from './WorkerRunsEmptySwitch';
import WorkerRunsTableItem from './WorkerRunsTableItem';
import WorkerRunsTableLoading from './WorkerRunsTableLoading';
import { ReactComponent as NetworkErrorIcon } from '../../../../../../images/icons/network-error.svg';
import HistoryContext from '../../../entities/HistoryContext';

import { SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';

const TableItems = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
`;

const LoadMoreWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
`;

interface Props {
    workerRuns: any[];
    /** If not loading and actions list is empty, it means that it should show empty state */
    loading: boolean;
    hasMore: boolean;
    error: any;
    isFiltering: boolean;
    reload(): void;
    loadMore(): void;
    clearFilters(): void;
}

const WorkerRunsTable: React.FC<Props> = ({
    workerRuns,
    loading,
    hasMore,
    reload,
    loadMore,
    error,
    isFiltering,
    clearFilters,
}) => {
    const { environmentIsActive } = useContext(HistoryContext);
    const isInError = !!error;

    const isFullBlockError = isInError && !workerRuns?.length;
    const isEmptyState = !isInError && !loading && !workerRuns?.length;

    return (
        <TableItems>
            {isEmptyState && (
                <WorkerRunsEmptySwitch
                    isActive={environmentIsActive}
                    isFiltering={isFiltering}
                    clearFilters={clearFilters}
                />
            )}

            {isFullBlockError && (
                <SimpleErrorStateMessage
                    error={error}
                    reload={reload}
                    errorMessage="Error loading history"
                    icon={
                        <span className="tnk-icon">
                            <NetworkErrorIcon />
                        </span>
                    }
                />
            )}

            {workerRuns?.map((action) => <WorkerRunsTableItem key={action.id} workerRun={action} />)}

            {loading && <WorkerRunsTableLoading />}

            {hasMore && !loading && (
                <LoadMoreWrapper>
                    <Button onClick={() => loadMore()}>Load more</Button>
                </LoadMoreWrapper>
            )}
        </TableItems>
    );
};

export default React.memo(WorkerRunsTable);
