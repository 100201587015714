import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { TnkSelect } from '@tonkean/infrastructure';

interface EndPoint {
    label: string;
    value: string;
}

const BrazeAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [apiKey, setApiKey] = useState<string>('');
    const [apiEndpoint, setApiEndpoint] = useState<EndPoint>();
    const APIEndpoints = [
        {
            label: 'US-01',
            value: 'https://rest.iad-01.braze.com/',
        },
        {
            label: 'US-02',
            value: 'https://rest.iad-02.braze.com/',
        },
        {
            label: 'US-03',
            value: 'https://rest.iad-03.braze.com/',
        },
        {
            label: 'US-04',
            value: 'https://rest.iad-04.braze.com/',
        },
        {
            label: 'US-05',
            value: 'https://rest.iad-05.braze.com/',
        },
        {
            label: 'US-06',
            value: 'https://rest.iad-06.braze.com/',
        },
        {
            label: 'US-08',
            value: 'https://rest.iad-08.braze.com/',
        },
        {
            label: 'EU-01',
            value: 'https://rest.fra-01.braze.eu/',
        },
        {
            label: 'EU-02',
            value: 'https://rest.fra-02.braze.eu/',
        },
    ];

    const authenticateWithApiKey = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            authenticate(
                { apiKey, apiBaseUrl: apiEndpoint?.value },
                {},
                `${integrationConfiguration.displayName} (${apiEndpoint?.label})`,
            );
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <div className="flex api-token-authentication">
                <div className="form-group">
                    <label className="col-sm-4 control-label text-right">Instance</label>
                    <div className="col-sm-7">
                        <TnkSelect options={APIEndpoints} onChange={setApiEndpoint} value={apiEndpoint} isSearchable />
                        <span className="common-size-xxxs common-color-grey">
                            Use the instance you are provisioned to.
                        </span>
                    </div>
                </div>

                <div className="form-group">
                    <label className="col-sm-4 control-label text-right">API Key</label>
                    <div className="col-sm-7">
                        <input
                            value={apiKey}
                            className="form-control"
                            onChange={({ target }) => setApiKey(target.value)}
                            id="api_key"
                            autoComplete="off"
                            placeholder="API Key"
                            required
                        />
                        <span className="common-size-xxxs common-color-grey">
                            Your API keys can be found in the Braze Dashboard -{'>'} Settings -{'>'} Developer Console.
                            <br />
                            At the top of this new page, you will find the REST API Keys section.
                        </span>
                    </div>
                </div>

                <div className="flex mod-center margin-top-20">
                    <button className=" btn btn-secondary btn-lg" onClick={authenticateWithApiKey}>
                        Connect to {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BrazeAuthenticationComponent;
