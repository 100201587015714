import template from './functionCard.template.html?angularjs';

function tnkFunctionCard() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            initiative: '=',
            editMode: '=',
            hideDeliverables: '=',
            hideActivity: '=',
            showOwner: '=',
            titleText: '@',
            changeToggle: '=',
            initialState: '=',
            updateInitiative: '&',
            skipUpdateTextStep: '=',
            fullPageMode: '=',
            currentGroupId: '=', // The id of the group currently viewed or null (this is optional) - used for calculating needed group states to display.
            solutionBusinessReportId: '<',
            isInSharedMode: '<',
        },
        controller: 'FunctionCardCtrl',
        template,
    };
}
angular.module('tonkean.app').directive('tnkFunctionCard', tnkFunctionCard);
