import type AuditLogFilterSearchQuery from '../modules/EnterpriseComponentsModule/components/AuditLogPage/entities/AuditLogFilterSearchQuery';
import { bindThis } from '@tonkean/utils';
import type AuditLogsActivitiesResponse from '../modules/EnterpriseComponentsModule/components/AuditLogPage/entities/responses/AuditLogsActivitiesResponse';
import type AuditLogsGroupItemsResponse from '../modules/EnterpriseComponentsModule/components/AuditLogPage/components/UserActionItem/entities/responses/AuditLogsGroupItemsResponse';
import type AuditLogsGroupsResponse from '../modules/EnterpriseComponentsModule/components/AuditLogPage/components/AuditLogChangesList/entities/responses/AuditLogsGroupsResponse';
import { TonkeanService } from '@tonkean/shared-services';
import type { TonkeanType } from '@tonkean/tonkean-entities';
import type AuditLogType from '../modules/EnterpriseComponentsModule/components/AuditLogPage/entities/AuditLogType';

class AuditLogService extends TonkeanService {
    /**
     * This function generates the query params from a given filtering.
     * @param filterSearch - The filtering to convert to query
     * @private
     */
    private static buildQueryParamsFromFilter(filterSearch: AuditLogFilterSearchQuery) {
        let query = '';

        if (filterSearch?.filters?.actorId) {
            query += `&actorsIds=${filterSearch?.filters?.actorId}`;
        }

        if (filterSearch?.filters?.fromDate) {
            query += `&fromDate=${filterSearch?.filters?.fromDate.getTime()}`;
        }

        if (filterSearch?.filters?.toDate) {
            query += `&toDate=${filterSearch?.filters?.toDate.getTime()}`;
        }

        if (filterSearch?.filters?.activityType) {
            query += `&activityType=${filterSearch?.filters?.activityType}`;
        }

        if (filterSearch?.groupId) {
            query += `&auditLogGroupId=${filterSearch?.groupId}`;
        }

        if (filterSearch?.search) {
            query += `&searchText=${filterSearch?.search}`;
        }

        return query;
    }

    /**
     * Returns the overall log items changed according to a bucket and a given filter (without pagination)
     * @param projectId - The project id containing the audit logs
     * @param enterpriseComponentId - The actual id of the bucket, for example - PRIN1234
     * @param filterSearch - The filtering we want to apply to the logs
     */
    @bindThis
    public async getAuditLogCount(
        projectId: string,
        enterpriseComponentId: string,
        enterpriseComponentType: TonkeanType,
        logType: AuditLogType,
        filterSearch?: AuditLogFilterSearchQuery,
    ) {
        return this.getAuditLogItems(
            projectId,
            enterpriseComponentId,
            enterpriseComponentType,
            0,
            1,
            logType,
            filterSearch,
        ).then((response) => {
            return response.total;
        });
    }

    /**
     * This function returns the groups (categories) of the audit logs.
     *  @param projectId - The project id containing the audit logs
     * @param enterpriseComponentId - The actual id of the bucket, for example - PRIN1234
     * @param nextPageToken - The token for getting the next page of the groups
     * @param limit - Limit pagination parameter
     * @param filterSearch - The filtering we want to apply to the logs
     */
    @bindThis
    public async getAuditLogGroups(
        projectId: string,
        enterpriseComponentId: string,
        enterpriseComponentType: TonkeanType,
        limit: number,
        logType: AuditLogType,
        nextPageToken?: string,
        filterSearch?: AuditLogFilterSearchQuery,
    ) {
        let query = `enterpriseComponentId=${enterpriseComponentId}&enterpriseComponentType=${enterpriseComponentType}&size=${limit}&logType=${logType}`;

        if (nextPageToken) {
            query += `&nextPageToken=${nextPageToken}`;
        }

        if (filterSearch) {
            query += AuditLogService.buildQueryParamsFromFilter(filterSearch);
        }

        return this.$http.get<AuditLogsGroupsResponse>(this.buildUrl(`${projectId}/auditLogs/groups?${query}`));
    }

    /**
     * This function returns all the audit logs from a bucket id according to a given filter
     * @param projectId - The project id containing the audit logs
     * @param enterpriseComponentId - The actual id of the bucket, for example - PRIN1234
     * @param skip - Skip pagination parameter
     * @param limit - Limit pagination parameter
     * @param filterSearch? - The filtering we want to apply to the logs
     * @param enterpriseComponentType - The tonkean type of the component (communication/data source/training set)
     * @param logType - The type of the log, e.g. system log
     */
    @bindThis
    public async getAuditLogItems(
        projectId: string,
        enterpriseComponentId: string,
        enterpriseComponentType: TonkeanType,
        skip: number,
        limit: number,
        logType: AuditLogType,
        filterSearch?: AuditLogFilterSearchQuery,
    ) {
        let query = `enterpriseComponentId=${enterpriseComponentId}&enterpriseComponentType=${enterpriseComponentType}&limit=${limit}&skip=${skip}&logType=${logType}`;

        if (filterSearch) {
            query += AuditLogService.buildQueryParamsFromFilter(filterSearch);
        }

        return this.$http.get<AuditLogsGroupItemsResponse>(this.buildUrl(`${projectId}/auditLogs?${query}`));
    }

    /**
     * This endpoint downloads a file according to the given bucket and filtering.
     * @param projectId - The project id of the logs
     * @param enterpriseComponentId - The actual id of the bucket, for example - PRIN1234
     * @param fileName - The name we want to give to the file
     * @param filterSearch - The filtering we want to apply to the downloaded logs
     */
    @bindThis
    public async exportCsv(
        projectId: string,
        enterpriseComponentId: string,
        enterpriseComponentType: TonkeanType,
        fileName: string,
        filterSearch?: AuditLogFilterSearchQuery,
    ) {
        let query = `enterpriseComponentId=${enterpriseComponentId}&enterpriseComponentType=${enterpriseComponentType}&fileName=${fileName}`;

        if (filterSearch) {
            query += AuditLogService.buildQueryParamsFromFilter(filterSearch);
        }

        return this.$http
            .get<string>(this.buildUrl(`${projectId}/auditLogs/download?${query}`))
            .then((response) => this.utils.downloadFile(response, 'text/csv', fileName, 'csv'));
    }

    /**
     * Returns all the activity types available
     */
    @bindThis
    public async getAuditLogActivities(projectId, logType: AuditLogType) {
        return this.$http.get<AuditLogsActivitiesResponse>(
            this.buildUrl(`${projectId}/auditLogs/activities?logType=${logType}`),
        );
    }
}

export default angular.module('tonkean.app').service('auditLogService', AuditLogService);
