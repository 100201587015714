<mentio-menu
    mentio-for="textId"
    mentio-trigger-char="'@'"
    mentio-items="selectedItems"
    mentio-template-url="functions-mentions.tpl"
    mentio-search="searchFunctions(term)"
    mentio-select="getTextRaw(item)"
></mentio-menu>

<script type="text/ng-template" id="functions-mentions.tpl">
    <ul class="list-group product-search margin-top">
        <li mentio-menu-item="func" ng-repeat="func in items | limitTo:5" class="list-group-item">
            <div class="flex flex-vmiddle">
                <div class="flex-no-shrink avatar-size-xxs margin-right">
                    <tnk-avatar user="func" name="func.name" mod-secondary="true"></tnk-avatar>
                </div>
                <div class="flex-grow margin-left">
                    <div class="name">{{func.name}}</div>
                    <div class="common-color-grey common-size-xxs">{{func.defaultOwner ? func.defaultOwner.name :
                        func.title}}
                    </div>
                </div>
            </div>
        </li>
    </ul>
</script>
