import { TONKEAN_ENTITY_TYPE, FIELD_COMPARE_TIMEFRAME_MAP } from '@tonkean/constants';
import { WORKER_TYPES } from '@tonkean/constants';
import { countQueryFilters } from '@tonkean/tonkean-utils';
import { getFieldRangeAppliedStyle } from '@tonkean/tonkean-utils';
import { logicBlockTypes as logicBlockTypesConsts } from '@tonkean/logic-block-configs';

function EntityHelper(
    $rootScope,
    dateFilter,
    utils,
    integrations,
    fieldDisplay,
    entityPersonHelper,
    entityInitiativeHelper,
) {
    const integrationGroups = integrations.getIntegrationGroups();
    const dataIntegrationTypes = new Set(
        [].concat(
            integrationGroups.code.integrations,
            integrationGroups.issues.integrations,
            integrationGroups.support.integrations,
            integrationGroups.sales.integrations,
            integrationGroups.finance.integrations,
            integrationGroups.webhooks.integrations,
        ),
    );

    const fieldCompareTimeframeMap = FIELD_COMPARE_TIMEFRAME_MAP;
    const workerTypes = WORKER_TYPES;
    const logicBlockTypes = logicBlockTypesConsts;
    const logicBlockBySecondaryType = utils.createMapFromArray(
        utils.findAllInObj(
            logicBlockTypes,
            (blockType) => blockType.type === 'AUTONOMOUS' || blockType.type === 'MONITOR_TRACKS',
        ),
        'secondaryType',
    );

    const inquiryTypesOptions = {
        INITIAL: {
            showInList: true,
            displayType: 'tonkean',
            preTitle: 'General analysis',
            getTitle(project) {
                return project.name;
            },
        },
        SPRINT_RETROSPECT: {
            showInList: true,
            displayType: 'repeat',
            preTitle: 'Sprint retrospective',
            getTitle(project, inquiry) {
                const sprintLength = 21; // days

                const diffMs = Date.now() - inquiry.created;
                const diffDays = Math.floor(diffMs / 1000 / 60 / 60 / 24);
                if (diffDays < (sprintLength * 2) / 3) {
                    return 'Last Sprint';
                }

                const endDate = new Date(inquiry.created);
                const startDate = new Date(new Date().setDate(endDate.getDate() - sprintLength));

                return `Sprint of ${dateFilter(startDate, 'MMM dd')}-${dateFilter(endDate, 'MMM dd')}`;
            },
        },
        CHECK_PROBLEM: {
            showInList: false,
            displayType: 'tonkean',
            preTitle: 'problem',
            getTitle(problem) {
                return problem.text;
            },
        },
    };

    /**
     * Magic function that takes the related entities and puts them in the right place
     * @param obj The object that is being enriched
     * @param [relatedEntities] Optional. state param
     * @param [level] Optional. The recursion depth
     * @param [path] Optional. The path inside the object.
     * @param [minimumLevel] The minimum level to look for related entities.
     * @param [maximumLevel] The maximum level to look for related entities.
     * @param [skipPatch] skips the non-conventional workaround
     * @return {*} The enriched object.
     */
    this.enrichObj = function (obj, relatedEntities, level, path, minimumLevel, maximumLevel, skipPatch) {
        level = level || 0;
        minimumLevel = minimumLevel || 1;
        maximumLevel = maximumLevel || 10;
        path = path || '';
        relatedEntities = relatedEntities || {};

        // If we are at the base level of the recursion and the object has related entities within itself.
        if (level === 0 && obj && obj.relatedEntities) {
            // Save the related entities locally.
            relatedEntities = obj.relatedEntities;

            // If the object has both entities and relatedEntities, the related entities should include the entities as well.
            // We do this because each entity is returned once, so if it exists in entities it won't exist in relatedEntities and we won't have the full picture.
            if (obj.entities) {
                // Related entities is a map and entities is an array.
                for (let j = 0; j < obj.entities.length; j++) {
                    const currentEntity = obj.entities[j];
                    // Only add entities that have an id and do not already exist in the related entities dict.
                    if (currentEntity.id && !relatedEntities[currentEntity.id]) {
                        relatedEntities[currentEntity.id] = this.enrichEntity(currentEntity);
                    }
                }
            }

            // Cache the related entities we've got and make the related entities dict be updated with the cached entities (and not some other object reference).
            // CACHE MUST BE HERE - before the recursion start - cause in this place all the entities only represent "themselves" with references to "empty obj" (of only IDs), so they won't override each other.
            // Disabling cache for now...
            // cacheEntities(relatedEntities);

            // We enrich the related entities first we themselves.
            this.enrichObj(relatedEntities, relatedEntities, 0, '', 2, maximumLevel, true);
        }

        // If we exceeded the maximum levels of recursion allowed, return.
        if (level > maximumLevel) {
            return obj;
        }

        // If the current object is an array.
        if (angular.isArray(obj)) {
            // Go over the objects in the array and enrich them with the related entities we have.
            for (let i = 0, len = obj.length; i < len; i++) {
                obj[i] = this.enrichObj(
                    obj[i],
                    relatedEntities,
                    level + 1,
                    `${path + i}/`,
                    minimumLevel,
                    maximumLevel,
                    skipPatch,
                );
            }
        } else if (angular.isObject(obj)) {
            // If the current object is an Object.
            // If the recursion level is bigger than the minimum and the object exists in the related entities dict, enrich it via the this.entityHelper.
            if (level >= minimumLevel && obj.id && relatedEntities[obj.id]) {
                return this.enrichEntity(relatedEntities[obj.id]);
            } else {
                // If the level is below minimum, or this object does not exist in the related entities dict.
                // Go over the object's keys, and enrich them (this way any object with just an id will be replaced with the matching related entity).
                for (const key in obj) {
                    if (obj.hasOwnProperty(key) && key !== 'relatedEntities') {
                        obj[key] = this.enrichObj(
                            obj[key],
                            relatedEntities,
                            level + 1,
                            `${path + key}/`,
                            minimumLevel,
                            maximumLevel,
                            skipPatch,
                        );
                    }
                }
                // Also enrich this object via the this.entityHelper.
                this.enrichEntity(obj);
            }
        } else if (!skipPatch && level >= minimumLevel && angular.isString(obj) && relatedEntities[obj]) {
            // Enrich this related entity via the this.entityHelper and return it.
            return this.enrichEntity(relatedEntities[obj]);
        }

        return obj;
    };

    /**
     * Enrich entity by type. Should be called from the interceptor
     * @param entity an Object with Id.
     * @return {*} The enriched entity.
     */
    this.enrichEntity = function (entity) {
        if (entity && entity.id && angular.isString(entity.id)) {
            switch (0) {
                case entity.id.indexOf('INQR'): {
                    return this.enrichInquiry(entity);

                    break;
                }
                case entity.id.indexOf('PBLM'): {
                    return this.enrichProblem(entity);

                    break;
                }
                case entity.id.indexOf('PROJ'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.PROJECT;
                    return this.enrichProject(entity);

                    break;
                }
                case entity.id.indexOf('QSTN'): {
                    return this.enrichQuestion(entity);

                    break;
                }
                case entity.id.indexOf('ACTI'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.ACTIVITY;
                    return this.enrichActivity(entity);

                    break;
                }
                case entity.id.indexOf('FIDE'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.FIELD_DEFINITION;
                    return this.enrichFieldDefinition(entity);

                    break;
                }
                case entity.id.indexOf('FILD'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.FIELD;
                    return this.enrichField(entity);

                    break;
                }
                case entity.id.indexOf('INIT'): {
                    return entityInitiativeHelper.enrichEntity(entity);

                    break;
                }
                case entity.id.indexOf('GRUP'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.GROUP;
                    return this.enrichGroup(entity);

                    break;
                }
                case entity.id.indexOf('PRSN'): {
                    return entityPersonHelper.enrichEntity(entity);

                    break;
                }
                case entity.id.indexOf('PRIN'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.PROJECT_INTEGRATION;
                    return this.enrichProjectIntegration(entity);

                    break;
                }
                case entity.id.indexOf('CUTR'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.CUSTOM_TRIGGER;
                    return this.enrichCustomTrigger(entity);

                    break;
                }
                case entity.id.indexOf('WORU'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.WORKER_RUN;
                    return this.enrichWorkerRun(entity);

                    break;
                }
                case entity.id.indexOf('WOVE'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.WORKFLOW_VERSION;
                    return this.enrichWorkflowVersion(entity);

                    break;
                }
                case entity.id.indexOf('SYCO'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.SYNC_CONFIG;
                    return this.enrichSyncConfig(entity);

                    break;
                }
                case entity.id.indexOf('SCGR'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.SCIM_GROUP;
                    return this.enrichScimGroup(entity);
                    break;
                }
                // No default
            }
        }
        return entity;
    };

    /**
     * enrich the inquiry with data from the consts
     */
    this.enrichGroup = function (group) {
        if (!group) {
            return group;
        }

        if (group) {
            if (group.globalFields) {
                group.globalFieldsMap = {};

                for (let i = 0; i < group.globalFields.length; i++) {
                    const currentGlobalField = group.globalFields[i];

                    if (currentGlobalField.fieldDefinition) {
                        group.globalFieldsMap[currentGlobalField.fieldDefinition.id] = currentGlobalField;
                    }
                }
            }
            if (group.globalFieldsDraft) {
                group.globalFieldsDraftMap = {};

                for (let i = 0; i < group.globalFieldsDraft.length; i++) {
                    const currentGlobalField = group.globalFieldsDraft[i];

                    if (currentGlobalField.fieldDefinition) {
                        group.globalFieldsDraftMap[currentGlobalField.fieldDefinition.id] = currentGlobalField;
                    }
                }
            }
        }

        group.visibleToSummary = null;

        if (!group.workerType) {
            group.workerType = workerTypes.UNINITIALIZED.key;
        }

        if (!group.members || !group.members.length) {
            group.visibleToSummary = 'Everyone in this board';
        } else {
            if (group.members.length === 1) {
                group.visibleToSummary = 'Visible to ';
                group.visibleToSummary += group.members[0].name;
            } else if (group.members.length === 2) {
                group.visibleToSummary = 'Visible to ';
                group.visibleToSummary += group.members[0].name;
                group.visibleToSummary += ' and ';
                group.visibleToSummary += group.members[1].name;
            } else {
                group.visibleToSummary = 'Visible to ';
                group.visibleToSummary += group.members[0].name;
                group.visibleToSummary += ', ';
                group.visibleToSummary += group.members[1].name;
                group.visibleToSummary += ' and ';
                group.visibleToSummary += group.members.length - 2;
                group.visibleToSummary += ' more members';
            }

            // Enriching the group with a map of its members for easy access.
            group.membersMap = {};
            for (let i = 0; i < group.members.length; i++) {
                const member = group.members[i];
                group.membersMap[member.id] = member;
            }
        }

        // Store the original groupProjectIntegrations array to be used in the worker browser page
        group.originalGroupProjectIntegrations = [
            ...(group.originalGroupProjectIntegrations ?? group.groupProjectIntegrations ?? []),
        ];

        if (group && group.groupProjectIntegrations) {
            group.projectIntegrationIdDict = {};

            // Creating a map from unique type to project integration
            group.groupUniqueTypeToProjectIntegrationMap = {};

            let minGroupLastCollect = null;
            for (let j = 0; j < group.groupProjectIntegrations.length; j++) {
                const projectIntegration = group.groupProjectIntegrations[j];

                if (
                    projectIntegration &&
                    projectIntegration.integration &&
                    projectIntegration.integration.integrationUniqueType
                ) {
                    group.groupUniqueTypeToProjectIntegrationMap[projectIntegration.integration.integrationUniqueType] =
                        projectIntegration;
                    group.projectIntegrationIdDict[projectIntegration.id] = projectIntegration;

                    // Last collect of group project integrations
                    if (!minGroupLastCollect) {
                        minGroupLastCollect = projectIntegration.lastCollect;
                    } else {
                        if (projectIntegration.lastCollect < minGroupLastCollect) {
                            minGroupLastCollect = projectIntegration.lastCollect;
                        }
                    }
                }
            }

            group.groupIntegrationsLastCollect = minGroupLastCollect;

            // Creating a unified collection of both group project integrations and project integrations of the project.
            group.allProjectIntegrations = [];

            // Adding project integrations that exist in the project but are not selected in group
            if ($rootScope.pm.project && $rootScope.pm.project.integrations) {
                for (let j = 0; j < $rootScope.pm.project.integrations.length; j++) {
                    const integrationOfProject = $rootScope.pm.project.integrations[j];

                    if (
                        integrationOfProject &&
                        integrationOfProject.integrationUniqueType &&
                        !group.groupUniqueTypeToProjectIntegrationMap[integrationOfProject.integrationUniqueType]
                    ) {
                        group.allProjectIntegrations.push(integrationOfProject);
                    }
                }
            }

            // Adding the project integrations that are selected in the group
            for (const key in group.groupUniqueTypeToProjectIntegrationMap) {
                if (group.groupUniqueTypeToProjectIntegrationMap.hasOwnProperty(key)) {
                    group.allProjectIntegrations.push(group.groupUniqueTypeToProjectIntegrationMap[key]);
                }
            }

            // Last collect for all integrations
            let minAllIntegrationsLastCollect = null;

            for (let j = 0; j < group.allProjectIntegrations.length; j++) {
                const projectIntegration = group.allProjectIntegrations[j];

                if (!minAllIntegrationsLastCollect) {
                    minAllIntegrationsLastCollect = projectIntegration.lastCollect;
                } else {
                    if (projectIntegration.lastCollect < minAllIntegrationsLastCollect) {
                        minAllIntegrationsLastCollect = projectIntegration.lastCollect;
                    }
                }
            }

            group.allIntegrationsLastCollect = minAllIntegrationsLastCollect;

            // Unique type map for all integrations
            group.allUniqueTypeToProjectIntegrationMap = {};

            for (let j = 0; j < group.allProjectIntegrations.length; j++) {
                const projectIntegration = group.allProjectIntegrations[j];

                if (
                    projectIntegration &&
                    projectIntegration.integration &&
                    projectIntegration.integration.integrationUniqueType
                ) {
                    group.allUniqueTypeToProjectIntegrationMap[projectIntegration.integration.integrationUniqueType] =
                        projectIntegration;
                }
            }
        }

        // Enrich group metadata (make sure a metadata object exists).
        if (!group.metadata) {
            group.metadata = {};
        }

        return group;
    };

    this.enrichWorkflowVersion = function (workflowVersion) {
        if (!workflowVersion) {
            return workflowVersion;
        }

        // Enrich workflow version states (copy default project states if the workflow version doesn't have any states).
        if (!workflowVersion.states && $rootScope.pm.project && $rootScope.pm.project.states) {
            // If the workflow version didn't save any custom states, take the project's states.
            workflowVersion.states = $rootScope.pm.project.states;
        }

        if (workflowVersion.states && workflowVersion.states.length) {
            workflowVersion.stateIdToStateMap = utils.createMapFromArray(workflowVersion.states, 'id');
            workflowVersion.stateLabelToStateMap = utils.createMapFromArray(workflowVersion.states, 'label');
        }

        return workflowVersion;
    };

    this.enrichSyncConfig = function (syncConfig) {
        if (!syncConfig) {
            return syncConfig;
        }

        if (syncConfig.viewData && syncConfig.viewData.query) {
            syncConfig.viewData.query.filtersCount = countQueryFilters(syncConfig.viewData.query);
        }

        return syncConfig;
    };

    this.enrichScimGroup = function (scimGroup) {
        scimGroup.initials = utils.getInitials(scimGroup.displayName);
        return scimGroup;
    };

    /**
     * enrich the inquiry with data from the consts
     */
    this.enrichInquiry = function (inquiry) {
        if (inquiry) {
            inquiry.userHasUnansweredQuestions = inquiry.answered === false;
            const inquiryType = inquiryTypesOptions[inquiry.type];

            if (inquiryType) {
                inquiry.typeData = inquiryType;
                inquiry.title = inquiry.name || inquiryType.getTitle(inquiry.reference, inquiry);
            }
        }

        return inquiry;
    };

    /**
     * Enriches a FieldDefinition object
     */
    this.enrichFieldDefinition = function (fieldDefinition) {
        // Evaluating the source of the definition.
        // Defaults to MANUAL. If project integration & integration type exist, will evaluate to integration type.
        let definitionSource = 'MANUAL';

        if (
            fieldDefinition &&
            fieldDefinition.projectIntegration &&
            fieldDefinition.projectIntegration.integration &&
            fieldDefinition.projectIntegration.integration.integrationType
        ) {
            definitionSource = fieldDefinition.projectIntegration.displayName;
        }

        fieldDefinition.definitionSource = definitionSource;

        // If this field definition has a displayAs property - it should dictate how we display the field.
        fieldDefinition.evaluatedDisplayType = fieldDefinition.displayAs
            ? fieldDefinition.displayAs
            : fieldDefinition.fieldType;
        // If we don't have display as set, it should be equal to the evaluated display type.
        if (!fieldDefinition.displayAs) {
            fieldDefinition.displayAs = fieldDefinition.evaluatedDisplayType;
        }

        // Enriching the field definition with a boolean indicating whether we have any ranges with isGather = true.
        if (fieldDefinition.ranges && fieldDefinition.ranges.length) {
            fieldDefinition.anyIsGatherRanges = !!fieldDefinition.ranges.find((range) => range.isGather);
        }

        return fieldDefinition;
    };

    /**
     * Enriches a Field object
     */
    this.enrichField = function (field) {
        if (field.fieldDefinition) {
            if (field.fieldDefinition.fieldType && field.value && field.fieldDefinition.fieldType === 'Date') {
                field.valueDate =
                    field.dateValue || Date.parse(field.value) || new Date(Number.parseInt(field.value)).getTime();

                // Make sure we don't have a Date object in valueDate (but unix time).
                if (field.valueDate && field.valueDate instanceof Date) {
                    field.valueDate = field.valueDate.getTime();
                }
            }

            // Calculate style according to range
            if (
                field.fieldDefinition.ranges &&
                !utils.isNullOrUndefined(field.appliedRangeNumber) &&
                field.fieldDefinition.ranges[field.appliedRangeNumber]
            ) {
                const appliedRange = field.fieldDefinition.ranges[field.appliedRangeNumber];
                const appliedStyle = getFieldRangeAppliedStyle(field.fieldDefinition.isImportant, appliedRange.color);

                field.style = appliedStyle.style;
                field.reactStyle = appliedStyle.reactStyle;
            }

            // calculate change %
            if (field.value) {
                const numValue = Number.parseFloat(field.value);
                // only if value is number
                if (numValue !== undefined && numValue !== null) {
                    // range to compare to (yesterday / lastWeek / lastMonth)
                    let compareToRange = fieldCompareTimeframeMap[1];
                    if (
                        field.fieldDefinition.compareTimeframe &&
                        fieldCompareTimeframeMap[field.fieldDefinition.compareTimeframe]
                    ) {
                        compareToRange = fieldCompareTimeframeMap[field.fieldDefinition.compareTimeframe];
                    }

                    if (field[compareToRange]) {
                        const lastValue = Number.parseFloat(field[compareToRange]);
                        if (lastValue) {
                            // calculate %
                            const diff = numValue - lastValue;
                            if (diff < 10_000 && diff > -10_000) {
                                // ignoring too big of a change (it's just annoying to see huge percentage)

                                field.change = (diff / lastValue).toFixed(4);
                                field.trend = numValue > lastValue ? 'up' : 'down';

                                // Set positive and negative.
                                const positiveWhenUp = !field.fieldDefinition.isIncrementNegative;
                                if (positiveWhenUp) {
                                    field.positive = numValue > lastValue;
                                    field.negative = numValue < lastValue;
                                } else {
                                    field.positive = numValue < lastValue;
                                    field.negative = numValue > lastValue;
                                }
                            }
                        }
                    }
                }
            }

            /** Calculate the field's displayValue **/
            // Set the field's value as the default display value.
            field.displayValue = fieldDisplay.evaluateFieldDisplayValue(
                field.fieldDefinition ? field.fieldDefinition.evaluatedDisplayType : null,
                field.fieldDefinition ? field.fieldDefinition.displayFormat : null,
                field.value,
                field.dateValue,
                field.formattedValue,
            );

            // add the hasUrlLink flag to know if this is a link content
            if (field.value) {
                field.hasUrlLink =
                    field.value &&
                    typeof field.value === 'string' &&
                    (field.value.indexOf('http://') === 0 || field.value.indexOf('https://') === 0);
            }

            // add updateable
            if (utils.isNullOrUndefined(field.updateable)) {
                field.evaluatedUpdateable =
                    (field.fieldDefinition.type === 'MANUAL' || field.fieldDefinition.updateable) &&
                    field.fieldDefinition.canUpdateFromUI;
            } else {
                field.evaluatedUpdateable =
                    field.updateable && field.fieldDefinition.updateable && field.fieldDefinition.canUpdateFromUI;
            }
        }

        return field;
    };

    /**
     * Enriches a problem object
     */
    this.enrichProblem = function (problem) {
        // fills the project object in the inquiry reference.
        if (
            problem.inquiry &&
            problem.inquiry.reference &&
            problem.project &&
            problem.project.id === problem.inquiry.reference.id &&
            !problem.inquiry.reference.name &&
            problem.project.name
        ) {
            problem.inquiry.reference = problem.project;
            problem.inquiry = this.enrichInquiry(problem.inquiry);
        }
        return problem;
    };

    /**
     * Groups the project integrations
     * @param project project entity
     * @return {*}
     */
    this.enrichProject = function (project) {
        if (project) {
            if (!$rootScope.features) {
                $rootScope.features = {};
            }
            // Make sure we have the features in the feature map before we do the enrichment
            // So we can go to consts and be positive that we have all the relevant flags
            if (project.features) {
                $rootScope.features[project.id] = project.features;
            }

            if (project.integrations) {
                // A set of all used integration unique types
                project.integrationUniqueTypeDict = {};
                project.projectIntegrationIdDict = {};

                for (let j = 0; j < project.integrations.length; j++) {
                    const projectIntegration = project.integrations[j];

                    project.integrationUniqueTypeDict[projectIntegration.integration.integrationUniqueType] =
                        projectIntegration;
                    project.projectIntegrationIdDict[projectIntegration.id] = projectIntegration;
                }

                // Only data-related project integrations
                project.dataIntegrations = project.integrations.filter(function (projectIntegration) {
                    return dataIntegrationTypes.has(projectIntegration.integration.integrationType.toLowerCase());
                });

                // Only communication integrations.
                project.communicationIntegrations = project.integrations.filter(function (projectIntegration) {
                    return integrationGroups.communication
                        .integrations(project.id)
                        .includes(projectIntegration.integration.integrationType.toLowerCase());
                });

                // Initializing the last collect out of all project integrations in the project
                let lastIntegrationCollect;
                for (let i = 0; i < project.integrations.length; i++) {
                    const integ = project.integrations[i];
                    if (integ.lastCollect && (!lastIntegrationCollect || integ.lastCollect > lastIntegrationCollect)) {
                        lastIntegrationCollect = integ.lastCollect;
                    }
                }
                if (lastIntegrationCollect) {
                    project.lastIntegrationCollect = lastIntegrationCollect;
                }
            }

            if (!project.metadata) {
                // We make sure the metadata object is never null/undefined, so we don't fail on it later.
                project.metadata = {};
            }

            if (project.states && project.states.length) {
                project.stateIdToStateMap = utils.createMapFromArray(project.states, 'id');
            }
        }

        return project;
    };

    /**
     * Enrich project integrations objects (that start with 'PRIN').
     * @param projectIntegration - the projectIntegration object to enrich.
     */
    this.enrichProjectIntegration = function (projectIntegration) {
        if (projectIntegration && projectIntegration.integration) {
            projectIntegration.integrationType = projectIntegration.integration.integrationType;
            projectIntegration.integrationUniqueType = projectIntegration.integration.integrationUniqueType;

            // This one is for being backwards compatible (name used to be the display name).
            projectIntegration.name = projectIntegration.displayName;

            // display name + creator first name for multi users
            projectIntegration.displayNameFull =
                projectIntegration.displayName +
                (projectIntegration.integration.isNativeIntegration &&
                !projectIntegration.integration.supportsMultipleIntegrationsPerUser
                    ? ` (${projectIntegration.creator.firstName})`
                    : '');
        }

        return projectIntegration;
    };

    this.enrichWorkerRun = function (workerRun) {
        const workerRunReasonsMap = {
            AUTONOMOUS_TRIGGER_MATCH: {
                display: 'Trigger Match',
                description: 'One of the triggers matched',
                triggered: true,
            },
            RUN_SCHEDULED_AUTONOMOUS_TRIGGER_NOW: {
                display: 'Run Scheduled Trigger Now',
                description: 'User clicked “Run Now” on scheduled trigger',
                triggered: true,
            },
            NEW_CREATED_INITIATIVE: {
                display: 'Created Initiative',
                description: 'Trigger of type “Item is Added” was triggered',
                triggered: false,
            },
            NEW_CREATED_INNER_INITIATIVE: {
                display: 'Created Inner Initiative',
                description: 'An action item was created',
                triggered: false,
            },
            FIELD_CHANGE_AUTONOMOUS_TRIGGER_MATCH: {
                display: 'Field Change Trigger Match',
                description: 'Trigger of type “Field Change” was triggered',
                triggered: true,
            },
            SCHEDULED_AUTONOMOUS_TRIGGER: {
                display: 'Scheduled Trigger',
                description: 'Trigger of type “On Scheduled” was triggered',
                triggered: true,
            },
            TEST_RUN: {
                display: 'Test',
                description: 'User Clicked “Test Now” on Test Workflow',
                triggered: true,
            },
            INITIATIVE_CREATED_BY_FORM: {
                display: 'Item Created By a Form',
                description: 'The item was created and a trigger monitoring forms was triggered',
                triggered: true,
            },
            MONITOR_FORM_TRIGGER_MATCH: {
                display: 'Item Created By a Form',
                description: 'The item was created and a trigger monitoring forms was triggered',
                triggered: true,
            },
            NO_CHANGE_IN_AUTONOMOUS_TRIGGER: {
                display: 'No Triggers Triggered',
                description: 'None of the Triggers were Triggered',
                triggered: false,
            },
            CHANGE_BUT_NO_AUTONOMOUS_TRIGGERS_MATCHED: {
                display: 'No Triggers Triggered',
                description: 'The item was changed but none of the triggers were triggered',
                triggered: false,
            },
            USER_CLICKED_BUTTON_IN_INTERFACE: {
                display: 'Triggered by a user click',
                description: "A user has clicked on a button that's connected to this action trigger",
                triggered: true,
            },
        };
        if (workerRun) {
            if (workerRun.workerRunReason && workerRunReasonsMap[workerRun.workerRunReason]) {
                workerRun.workerRunReasonDisplayValue = workerRunReasonsMap[workerRun.workerRunReason].display;
                workerRun.workerRunReasonDescriptionValue = workerRunReasonsMap[workerRun.workerRunReason].description;
                workerRun.wasTriggered = workerRunReasonsMap[workerRun.workerRunReason].triggered;
            }

            // Enriching the workflowVersionId of the worker run with first published workflow version id if workflow version id doesn't exist on the worker run.
            if (!workerRun.workflowVersionId && workerRun.group && workerRun.group.firstPublishedWorkflowVersionId) {
                workerRun.workflowVersionId = workerRun.group.firstPublishedWorkflowVersionId;
            }
        }

        return workerRun;
    };

    this.enrichCustomTrigger = function (customTrigger) {
        if (customTrigger) {
            if (
                customTrigger.customTriggerType === 'AUTONOMOUS' ||
                customTrigger.customTriggerType === 'INNER_AUTONOMOUS' ||
                customTrigger.customTriggerType === 'MONITOR_TRACKS'
            ) {
                // If this is an old trigger that dont have secondaryType, we need to conclude the type by the customTrigger configuration.
                if (
                    utils.isNullOrEmpty(customTrigger.customTriggerSecondaryType) ||
                    customTrigger.customTriggerSecondaryType === 'UNKNOWN'
                ) {
                    customTrigger.customTriggerSecondaryType = getAutonomousTriggerSecondaryType(customTrigger);
                }
                if (customTrigger.customTriggerSecondaryType) {
                    customTrigger.secondaryIconClass =
                        logicBlockBySecondaryType[customTrigger.customTriggerSecondaryType].secondaryIconClass;
                }
            }

            if (customTrigger.customTriggerActions && customTrigger.customTriggerActions.length) {
                // Go over the trigger's actions.
                for (let i = 0; i < customTrigger.customTriggerActions.length; i++) {
                    const action = customTrigger.customTriggerActions[i];
                    if (action.customTriggerActionDefinition) {
                        // If the action has a project integration id, add it to the projectIntegrationIds array of the trigger.
                        if (action.customTriggerActionDefinition.projectIntegrationId) {
                            // Create a fresh projectIntegrationIds array.
                            customTrigger.projectIntegrationIds = [];
                            // Add the id.
                            customTrigger.projectIntegrationIds.push(
                                action.customTriggerActionDefinition.projectIntegrationId,
                            );
                        }

                        // if action has integrationType add the type
                        if (action.customTriggerActionDefinition.integrationType) {
                            customTrigger.integrationType = action.customTriggerActionDefinition.integrationType;
                        }

                        if (
                            logicBlockTypes[action.customTriggerActionType] &&
                            logicBlockTypes[action.customTriggerActionType].enricher
                        ) {
                            logicBlockTypes[action.customTriggerActionType].enricher(
                                customTrigger,
                                action.customTriggerActionDefinition,
                            );
                        }
                    }
                }
            } else {
                // No actions so no project integraion ids. Clean the array.
                customTrigger.projectIntegrationIds = [];
            }
        }

        return customTrigger;
    };

    /**
     * Get the autonomous trigger type based on his configuration or the secondary type.
     */
    function getAutonomousTriggerSecondaryType(customTrigger) {
        // If its a monitor_tracks custom trigger type
        if (customTrigger.customTriggerType === 'MONITOR_TRACKS') {
            if (
                customTrigger.customTriggerActions &&
                customTrigger.customTriggerActions[0].customTriggerActionDefinition.monitorItemsConditionType ===
                    'IMMEDIATELY'
            ) {
                return 'MONITOR_TRACKS_ITEM_CREATED';
            } else if (
                customTrigger.customTriggerActions &&
                customTrigger.customTriggerActions[0].customTriggerActionDefinition.monitorItemsConditionType ===
                    'ON_CONDITION'
            ) {
                return 'MONITOR_TRACKS_MATCH_CONDITIONS';
            }
            // If its an autonomous or inner autonomous trigger type
        } else if (
            customTrigger.customTriggerType === 'AUTONOMOUS' ||
            customTrigger.customTriggerType === 'INNER_AUTONOMOUS'
        ) {
            if (customTrigger.isScheduled) {
                return 'AUTONOMOUS_SCHEDULE';
            } else if (customTrigger.monitorForms && customTrigger.monitorForms.length) {
                return 'AUTONOMOUS_CREATED_FROM_FORM';
            } else if (customTrigger.monitorFieldDefinitions && customTrigger.monitorFieldDefinitions.length) {
                return 'AUTONOMOUS_FIELD_CHANGED';
            } else if (
                customTrigger.queryDefinition &&
                customTrigger.queryDefinition.query &&
                countQueryFilters(customTrigger.queryDefinition.query) === 0
            ) {
                return 'AUTONOMOUS_ITEM_CREATED';
            } else {
                return 'AUTONOMOUS_MATCH_CONDITIONS';
            }
        }
    }

    /**
     * Populate the answer with a statement object.
     * @param question
     * @return {*}
     */
    this.enrichQuestion = function (question) {
        if (question.answer) {
            for (let i = 0; i < question.statements.length; i++) {
                const st = question.statements[i];
                if (st.id === question.answer) {
                    question.answer = st;
                    break;
                }
            }
            if (question.otherStatements) {
                for (let j = 0; j < question.otherStatements.length; j++) {
                    const st2 = question.otherStatements[j];
                    if (st2.id === question.answer) {
                        question.answer = st2;
                        break;
                    }
                }
            }
        }

        return question;
    };

    /**
     * Hack. Creates a new activity type.
     * @param activity
     * @return {*}
     */
    this.enrichActivity = function (activity) {
        if (
            (activity.type === 'NEW_PROBLEM' || activity.type === 'PROBLEM_INDICATION') &&
            activity.reference1.isPositive
        ) {
            activity.type = 'NEW_POSITIVE_PROBLEM';
        }
        return activity;
    };
}

angular.module('tonkean.shared').service('entityHelper', EntityHelper);
