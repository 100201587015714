import type { ColDef } from '@ag-grid-community/core';

import { getFieldInitialWidth } from './customSizes';
import type InitiativeRowData from '../entities/InitiativeRowData';

import type { FieldDefinition, SpecialFieldsKey } from '@tonkean/tonkean-entities';
import type { TonkeanType, TonkeanId } from '@tonkean/tonkean-entities/';

const getCommonDef = (
    commonDef: Partial<ColDef<InitiativeRowData>>,
    fieldDefinitionKeyToWidth: Record<SpecialFieldsKey, number | undefined>,
    fieldKey: TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}` | SpecialFieldsKey,
    fieldDefinition: FieldDefinition | null = null,
) => {
    const titleWidth = fieldDefinitionKeyToWidth[fieldKey];
    const width = {
        minWidth: titleWidth || getFieldInitialWidth(fieldKey, fieldDefinition),
        maxWidth: titleWidth || undefined,
    };

    return { ...commonDef, ...width };
};

export default getCommonDef;
