import ConcurAuthenticationComponent from './ConcurAuthenticationComponent';
import concurIcon from '../../../../apps/tracks/images/concur.png';
import concurCircleIcon from '../../../../apps/tracks/images/integrations/concur-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class ConcurIntegration extends IntegrationDefinitionBase {
    override name = 'concur';
    override displayName = 'Concur';
    override description = 'We will collect Expense Reports and Travel Requests data';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [
        new IntegrationEntity('Report', 'Reports'),
        new IntegrationEntity('Item', 'Items'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('ExpenseGroup', 'Expense Groups'),
    ];
    override customizedAuthenticationComponent = ConcurAuthenticationComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(concurCircleIcon, '130px'),
        new IntegrationIcon(concurIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Report: true, Request: true, User: true, ExpenseGroup: true },
        isOnlyDataRetention: {},
    };
}

export default ConcurIntegration;
