import { FieldArray, useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationHandleResponseSingleDynamicField from './ProjectIntegrationHandleResponseSingleDynamicField';

import { Paragraph } from '@tonkean/infrastructure';
import type { DynamicField } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const DynamicFieldsWrapper = styled.div`
    background-color: ${Theme.colors.gray_900};
    margin-top: 21px;
    padding: 24px 28px 24px 24px;
`;

const DynamicFieldTitle = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
`;

const DynamicFieldsTitleInfoWrapper = styled.div`
    display: flex;
    align-items: baseline;
`;

const DynamicFieldsRemark = styled(Paragraph)`
    margin-bottom: 10px;
    font-size: 10px;
`;

interface Props {
    name: string;
    expressionTabs?: TonkeanExpressionAdditionalTab[];
}

const ProjectIntegrationHandleResponseDynamicFields: React.FC<Props> = ({ name, expressionTabs }) => {
    const [{ value: dynamicFields }, , { setValue: setDynamicFields }] = useField<DynamicField[]>(name);

    const addKeyValueParameter = () => {
        const newTonkeanExpression = {
            name: {
                originalExpression: '',
                evaluatedExpression: '',
            },
            value: {
                originalExpression: '',
                evaluatedExpression: '',
            },
            id: utils.guid(),
        };
        setDynamicFields([...dynamicFields, newTonkeanExpression]);
    };

    return (
        <DynamicFieldsWrapper>
            <DynamicFieldsTitleInfoWrapper>
                <DynamicFieldTitle>Dynamic Response Fields:</DynamicFieldTitle>
            </DynamicFieldsTitleInfoWrapper>
            <DynamicFieldsRemark>
                This fields should be used only when the entity fetcher is not used as a single collect in module
                actions.
            </DynamicFieldsRemark>
            <FieldArray name={name}>
                {({ remove }) => {
                    return dynamicFields?.map((dynamicField, index) => (
                        <ProjectIntegrationHandleResponseSingleDynamicField
                            key={dynamicField.id}
                            name={`${name}.${index}`}
                            remove={() => remove(index)}
                            expressionTabs={expressionTabs}
                        />
                    ));
                }}
            </FieldArray>
            <ClickableLink
                onClick={() => {
                    addKeyValueParameter();
                }}
            >
                + Add
            </ClickableLink>
        </DynamicFieldsWrapper>
    );
};

export default ProjectIntegrationHandleResponseDynamicFields;
