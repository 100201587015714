import { lateConstructController } from '@tonkean/angular-components';
import { parseParents } from '@tonkean/tonkean-utils';
import { getFormulaSpecialFieldIdToDefinitionMap } from '@tonkean/tonkean-utils';

/* @ngInject */
function UpdateInitiativeStateCtrl(
    $scope,
    $rootScope,
    $state,
    $q,
    trackHelper,
    tonkeanUtils,
    initiativeCache,
    utils,
    customFieldsManager,
    customTriggerManager,
    workflowVersionManager,
) {
    const ctrl = this;
    const tonkeanFieldsMap = getFormulaSpecialFieldIdToDefinitionMap();

    $scope.pm = $rootScope.pm;
    $scope.parseParents = parseParents;
    $scope.trackHelper = trackHelper;
    $scope.itemMap = initiativeCache.getInitiativesCache();
    $scope.loading = false;
    $scope.loadingTracks = false;
    $scope.tonkeanUtils = tonkeanUtils;

    $scope.data = {
        // Bindings.
        initiativeId: ctrl.initiativeId,
        customTriggerId: ctrl.customTriggerId,

        createdInWorkerRunInformation: {
            workerRunLogicActionId: ctrl.workerRunLogicActionId,
            createdByCustomTriggerId: ctrl.createdByCustomTriggerId,
            workerRunLogicActionType: ctrl.workerRunLogicActionType,
        },

        onlyFieldDefinitionId: ctrl.onlyFieldDefinitionId,
        onlyInnerItems: ctrl.onlyInnerItems,
        onInitiativeClicked: ctrl.onInitiativeClicked,

        showWebformMessageResponse: false,
        askForInfoAnsweredCustomTrigger: null,
        askForInfoAnsweredWebResponse: null,

        // Inner properties.
        initiative: null,
        workflowVersion: null,
        fieldDefinitions: null,
        showDescription: false, // A flag for the UI.
        tonkeanFieldToUpdate: null, // A flag when updating only a tonkean field.
    };

    $rootScope.$on('groupListUpdated', function (e, params) {
        if ($scope.data.initiative && $scope.data.initiative.group && params && params.groupIds) {
            const matchingGroupId = utils.findFirst(
                params.groupIds,
                (groupId) => groupId === $scope.data.initiative.group.id,
            );
            if (matchingGroupId) {
                loadInitiative($scope.data.initiativeId, true, true);
            }
        }
    });

    ctrl.$onInit = function () {
        if (!$scope.data.initiative && !$scope.loadingInitiativeChange && $scope.data.view !== 'preview') {
            loadInitiative();
        }

        // If the fieldDefinitionId we got is a tonkean field, raise the UI flag and make sure it is in uppercase.
        if ($scope.data.onlyFieldDefinitionId && tonkeanFieldsMap[$scope.data.onlyFieldDefinitionId.toUpperCase()]) {
            $scope.data.onlyFieldDefinitionId = $scope.data.onlyFieldDefinitionId.toUpperCase();
            $scope.data.tonkeanFieldToUpdate = tonkeanFieldsMap[$scope.data.onlyFieldDefinitionId];
        } else {
            $scope.data.tonkeanFieldToUpdate = null;
        }
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object.
     */
    ctrl.$onChanges = function (changes) {
        // Keep the data.initiativeId up to date.
        if (changes.initiativeId) {
            $scope.loadingInitiativeChange = true;
            $scope.data.initiativeId = ctrl.initiativeId;
            loadInitiative($scope.data.initiativeId, true, true);
        }

        if (changes.onlyFieldDefinitionId) {
            $scope.data.onlyFieldDefinitionId = changes.onlyFieldDefinitionId.currentValue;
            loadInitiative($scope.data.initiativeId, true, true);
        }
    };

    function loadInitiative(id, loadSoft, forceServer) {
        if (loadSoft) {
            $scope.loadingTracks = true;
        } else {
            $scope.loading = true;
        }

        if (id) {
            $scope.data.initiativeId = id;
        }

        let initiativePromise = $q.resolve();
        if ($scope.data.initiativeId) {
            initiativePromise = trackHelper
                .getInitiativeById($scope.data.initiativeId, forceServer)
                .then((data) => $scope.updateInitiativeData(data))
                .then(function (data) {
                    // Call onLoadInitiativeDone at this point if it was supplied.
                    if (ctrl.onLoadInitiativeDone) {
                        ctrl.onLoadInitiativeDone({ getInitiativeByIdData: data });
                    }

                    $scope.loading = false;
                    $scope.loadingTracks = false;
                    $scope.loadingInitiativeChange = false;
                })
                .catch(function () {
                    $state.go('product', { location: 'replace' });
                });
        } else {
            // The updateInitiativeCtrl puts the first initiative in the stateParams if no specific initiative is selected.
            // Therefore, we will only get here if the user owns no initiatives.
            $scope.loading = false;
        }

        return initiativePromise;
    }

    $scope.loadNextInnerItemsPage = function () {
        // before first page request hasMoreInnerItemsToLoad is undefined, but
        // we still want to get more pages. this makes sure that hasMoreInnerItems is
        // actually false and not falsy
        if ($scope.data.initiative.hasMoreInnerItemsToLoad === false) {
            return;
        }

        $scope.data.loadingRelated = true;

        // We need to fetch project data in case of opening the my items box from external link or refreshing
        return $scope.pm.getProjectData(false).then(() => {
            const pageSize = $scope.pm.projectData.initiativesInnerItemsPageSize;

            return trackHelper
                .loadRelatedInitiatives(
                    $scope.data.initiative,
                    false,
                    $scope.data.initiative.relatedInitiatives.length,
                    pageSize,
                    [],
                )
                .then(() => {
                    const itemIdsToCountRelated = $scope.data.initiative.relatedInitiatives.map(({ id }) => id);
                    trackHelper.getRelatedInitiativesCount($scope.data.initiative.project.id, itemIdsToCountRelated);
                    $scope.data.loadingRelated = false;
                });
        });
    };

    $scope.initiativeSaved = function (data) {
        $scope.$emit('alert', { type: 'success', msg: 'Saved. Thanks!' });
        $scope.updateInitiativeData(data);
    };

    $scope.onItemClicked = function (item) {
        if (ctrl.onInitiativeClicked) {
            ctrl.onInitiativeClicked({ item });
        }
    };

    $scope.updateInitiativeData = function (data) {
        $scope.data.initiative = data;

        const workflowVersionPromise = data.isDraftInitiative
            ? workflowVersionManager.getDraftVersion(data.group.id)
            : workflowVersionManager.getPublishedVersion(data.group.id);

        return workflowVersionPromise.then((workflowVersion) => {
            $scope.data.workflowVersion = workflowVersion;

            return customFieldsManager
                .getFieldDefinitions($scope.data.workflowVersion.id, false)
                .then((fieldDefinitions) => {
                    // Create a fieldDefinitions array from the fieldDefinitionMap on the initiative (if exists).
                    $scope.data.fieldDefinitions = fieldDefinitions;

                    if ($scope.data.fieldDefinitions) {
                        // Filter out fields definition which are not manual and do not have a field instance.
                        // External fields with no field instance are not matched, and the user can't update them.
                        $scope.data.fieldDefinitions = $scope.data.fieldDefinitions.filter((fieldDefinition) => {
                            return (
                                fieldDefinition.type === 'MANUAL' ||
                                (fieldDefinition.type !== 'MANUAL' &&
                                    $scope.data.initiative.defIdToValidFieldsMap[fieldDefinition.id])
                            );
                        });
                    }

                    $scope.loading = false;

                    return data;
                });
        });
    };

    $scope.onFieldSaved = function () {

        if ($scope.data.onlyFieldDefinitionId) {
            $scope.data.showWebformMessageResponse = true;

            if (!$scope.data.askForInfoAnsweredCustomTrigger) {
                customTriggerManager
                    .getCustomTriggerChildrenOfType(
                        $scope.data.workflowVersion.id,
                        $scope.data.customTriggerId,
                        'ASK_FIELD_UPDATE_ANSWERED',
                    )
                    .then((customTriggerChildren) => {
                        $scope.data.askForInfoAnsweredCustomTrigger = customTriggerChildren?.entities[0];
                        $scope.data.askForInfoAnsweredWebResponse =
                            $scope.data.askForInfoAnsweredCustomTrigger?.customTriggerActions[0]
                                ?.customTriggerActionDefinition?.webformResponseMessage ||
                            'Thank you for filling out the information!';
                    });
            }
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('UpdateInitiativeStateCtrl', lateConstructController(UpdateInitiativeStateCtrl));
