function InitiativesFilterPopoverCtrl($scope) {
    /**
     * Initialization function of the controller.
     */
    $scope.init = function () {
        $scope.initiativesFilterPopoverState.isBootstrap = true;
        // If we already have a controller defined, we'll try to initialize using it. That way, we always display to the user
        // the query he defined, and it never gets erased.
        if ($scope.initiativesFilterControl && $scope.initiativesFilterControl.createDefinitionFromCustomFilters) {
            $scope.data.existingDefinition = $scope.initiativesFilterControl.createDefinitionFromCustomFilters();

            if ($scope.data.existingDefinition) {
                $scope.initiativesFilterControl.reloadFromExistingDefinition($scope.data.existingDefinition);
            }
        }
    };

    /**
     * Closes the filter popover.
     */
    $scope.closeInitiativesFilterPopover = function () {
        $scope.initiativesFilterPopoverState.isOpen = false;
    };

    /**
     * Clears the filters defined in advanced.
     */
    $scope.clearAdvancedFilter = function () {
        if ($scope.initiativesFilterControl && $scope.initiativesFilterControl.clearFiltersAndQueries) {
            $scope.initiativesFilterControl.clearFiltersAndQueries(true);
        }

        $scope.closeInitiativesFilterPopover();
        $scope.applyFilter();
    };

    $scope.init();
}
export default angular.module('tonkean.app').controller('InitiativesFilterPopoverCtrl', InitiativesFilterPopoverCtrl);
