import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as HistoryIcon } from '../../../../../../../../images/icons/history.svg';
import GridModuleName from '../../../../../../RuntimeModule/components/tnkSolutionMetrics/SolutionMetricsGrid/GridComponents/GridModuleName';

import { ZebraTable, ZebraTableBodyCell, ZebraTableBodyRow } from '@tonkean/infrastructure';
import type { Environment, Group, ProjectIntegration } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Theme } from '@tonkean/tui-theme';

const StyledHistoryIcon = styled(HistoryIcon)`
    width: 12px;
    height: 12px;
    margin-right: 6px;

    path {
        fill: ${Theme.colors.primary};
    }
`;

const SmallCell = styled.div`
    display: flex;
    align-items: center;
    max-width: 106px;
    margin: auto 38px auto 0;
`;

const ViewHistoryCell = styled(ZebraTableBodyCell)`
    justify-content: flex-end;
`;

interface Props {
    externalActivity: { id: string; title: string };
    projectIntegration: ProjectIntegration;
    collectLogTime: number;
    groups: Group[];
    error?: string;
    loading?: boolean;
    environment: Environment;
}

const TriggeredModulesTable: React.FC<Props> = ({
    externalActivity,
    projectIntegration,
    collectLogTime,
    groups,
    environment,
}) => {
    const $state = useAngularService('$state');

    return (
        <ZebraTable>
            <tbody>
                {groups.map((group) => {
                    return (
                        <ZebraTableBodyRow key={group.id}>
                            <GridModuleName key={group.id} module={group} />

                            <ViewHistoryCell>
                                <StateLink
                                    state="product.workerEditor.history"
                                    params={{
                                        g: group.id,
                                        environment,
                                        f: ['FLOW_RUN', 'MODULE_ITEM', 'DATA_SOURCE'],
                                        q: externalActivity.id,
                                        from: collectLogTime / 1000,
                                        to: collectLogTime / 1000 + 7200,
                                        fromName: projectIntegration.name,
                                        fromState: 'product.projectIntegrationPage',
                                        fromStateParams: $state.params,
                                    }}
                                >
                                    <SmallCell>
                                        <StyledHistoryIcon />
                                        Show in History
                                    </SmallCell>
                                </StateLink>
                            </ViewHistoryCell>
                        </ZebraTableBodyRow>
                    );
                })}
            </tbody>
        </ZebraTable>
    );
};

export default TriggeredModulesTable;
