export enum HeightOptions {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    CUSTOM = 'CUSTOM',
}

export const heightConfig: Record<HeightOptions, { label: string; value: number }> = {
    [HeightOptions.SMALL]: { label: 'Small', value: 150 },
    [HeightOptions.MEDIUM]: { label: 'Medium', value: 300 },
    [HeightOptions.LARGE]: { label: 'Large', value: 450 },
    [HeightOptions.CUSTOM]: { label: 'Custom', value: 0 },
};
