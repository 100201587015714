import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class SplitAndTakeIndexFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.SPLIT_AND_TAKE;
    override readonly sign = 'SplitAndTake';
    override displayName = 'Split Text and Take';
    override description = 'Will split the given input by a given text and return the result by the index.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Split By',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Take Index',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: 0,
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Return last',
            explanation: 'Will return the last index and override the "Take Index" param',
            dataType: FieldType.Boolean,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.CONST,
            defaultValue: false,
        },
    ];
}
