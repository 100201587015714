import React from 'react';
import styled from 'styled-components';

import { FrontDoorEntitySettingsRowDivider } from './FrontDoorEntitySettingsRowDivider';

import { TnkEntitySelector } from '@tonkean/angular-to-react-components';
import { SavingIndicator, Toggle, useFormikField } from '@tonkean/infrastructure';
import { ErrorIcon, Filter2Icon, TrashIcon } from '@tonkean/svg';
import type { ProjectIntegration, TonkeanQueryDefinition } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import type { ErrorResponseType } from '@tonkean/utils';

const TableColumn = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const conditionsCounterSize = 11;
const ConditionsCounter = styled.div`
    position: absolute;
    top: -${conditionsCounterSize}px;
    right: -${conditionsCounterSize}px;

    width: ${conditionsCounterSize}px;
    height: ${conditionsCounterSize}px;
    line-height: normal;
    border-radius: 50%;

    background-color: ${Theme.colors.primaryHighlight};
    color: ${Theme.colors.white};

    text-align: center;
    font-size: ${FontSize.XSMALL_10};
`;

interface Props {
    projectIntegration: ProjectIntegration;
    onOpenConditions: () => void;
    onDelete: () => void;
    shouldDisableChoice: (externalType: string) => boolean;
    loading?: boolean;
    error?: ErrorResponseType;
    createEntityMode?: boolean;
}

const ProjectIntegrationFrontDoorEntitySettingRowColumns: React.FC<Props> = ({
    projectIntegration,
    onOpenConditions,
    onDelete,
    loading,
    error,
    createEntityMode,
    shouldDisableChoice,
}) => {
    const { value: externalType, setValue: setExternalType } = useFormikField<string | undefined>('externalType');
    const { value: conditions } = useFormikField<TonkeanQueryDefinition | undefined>('conditions');

    const conditionsCount = conditions?.query?.filters?.length;

    return (
        <>
            <TableColumn data-automation="add-row-column-external-type">
                <TnkEntitySelector
                    name="externalType"
                    projectIntegration={projectIntegration}
                    selectedEntity={externalType}
                    shouldDisableChoice={(selectedEntity) => shouldDisableChoice(selectedEntity.entity)}
                    mode="sync"
                    onEntitySelected={(entity: { entity: string }) => {
                        setExternalType(entity.entity);
                    }}
                    forceFetchSupportedEntitiesFromServer
                    shouldNotChooseDefaultEntity
                />
            </TableColumn>
            <TableColumn>
                <Toggle
                    name="allowAccess"
                    dataAutomation="add-row-allowAccess"
                    size={InputSize.MEDIUM}
                    disabled={createEntityMode}
                />
            </TableColumn>
            <TableColumn>
                <IconButton
                    dataAutomation="add-row-conditions"
                    onClick={onOpenConditions}
                    disabled={createEntityMode}
                    iconColor={conditions ? Theme.colors.primaryHighlight : undefined}
                >
                    <Filter2Icon />
                    {conditionsCount && (
                        <ConditionsCounter data-automation="add-row-conditions-counter">
                            {conditionsCount}
                        </ConditionsCounter>
                    )}
                </IconButton>
            </TableColumn>
            <TableColumn>
                <IconButton dataAutomation="add-row-delete" onClick={onDelete} flat>
                    <TrashIcon />
                </IconButton>
            </TableColumn>
            <TableColumn>
                <SavingIndicator loading={!!loading} error={error} />
                {error && <ErrorIcon />}
            </TableColumn>
            <FrontDoorEntitySettingsRowDivider />
        </>
    );
};

export default ProjectIntegrationFrontDoorEntitySettingRowColumns;
