<div>
    <div class="margin-top">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.fileSourceType }}"
            storage-integration-id="{{ data.storageIntegrationId }}"
            storage-integration-action-id="{{ data.storageIntegrationActionId }}"
            url-expression="data.urlExpression"
            preview-evaluation-source="data.previewEvaluationSource"
            parameters-values="data.parametersValues"
            should-display-delete-sign="false"
            extended-match-configuration="data.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.workerStaticAssetDisplayName }}"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <!-- Invalid File Choice Error -->
        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- Invalid source input (empty) -->
        <div ng-if="data.validationObject.noSource" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noSource }}
        </div>

        <!-- Google cloud provider selector panel -->
        <div class="margin-top-lg margin-bottom">
            <!-- Project integration title -->
            <div class="margin-bottom-xs">
                Google Cloud Provider (
                <a ui-sref="product.enterpriseComponents({tab: 'data-sources'})">Connect</a>
                ):
            </div>

            <!-- Project integration selector -->
            <tnk-project-integration-selector
                group-id="{{ data.groupId }}"
                selected-project-integration-id="{{ data.googleCloudProjectIntegrationId }}"
                allow-default-option="true"
                default-option-label="Tonkean Google Cloud Provider"
                filter-on-integration-type="data.filterByIntegrations"
                on-project-integration-selected="onProjectIntegrationSelected(selectedProjectIntegration)"
            ></tnk-project-integration-selector>
        </div>
    </div>
</div>
