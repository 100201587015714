import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function VideoCarouselCtrl($scope, $sce) {
    const ctrl = this;

    $scope.data = {
        currentTipVideo: null,
        tipIndex: 0,
        videos: ctrl.videos,
    };

    ctrl.$onInit = function () {
        $scope.selectTip(0);
    };

    ctrl.$onChanges = function (changesObject) {
        if (changesObject.videos) {
            $scope.data.videos = changesObject.videos.currentValue;
            $scope.selectTip(0);
        }
    };

    $scope.selectTip = function (index) {
        const video = $scope.data.videos[index];
        if (video && video.videoId && video.videoId.length) {
            $scope.data.tipIndex = index;
            $scope.data.currentTipVideo = $sce.trustAsResourceUrl(video.videoId);
        }
    };

    $scope.selectNextTip = function (offset) {
        let index = $scope.data.tipIndex + offset;
        if (index >= $scope.data.videos.length) {
            index = 0;
        } else if (index < 0) {
            index = $scope.data.videos.length - 1;
        }
        $scope.selectTip(index);
    };
}

export default angular.module('tonkean.app').controller('VideoCarouselCtrl', lateConstructController(VideoCarouselCtrl));
