import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import HorizontalIntakeProgressTrackerPresenter from './HorizontalIntakeProgressTrackerPresenter';
import VerticalIntakeProgressTrackerPresenter from './VerticalIntakeProgressTrackerPresenter';
import useIntakeProgressTrackerConfiguration from '../IntakeProgressTracker/hooks/useIntakeProgressTrackerConfiguration';
import { ReactComponent as IntakeProgressMenuIcon } from '../IntakeProgressTracker/images/status-tracker/intake-progress-menu.svg';

import { InterfaceViewers } from '@tonkean/collaboration';
import { useIsXSmallScreen } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { BuildIcon, TonkeanLogo } from '@tonkean/svg';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FormType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize, type Color } from '@tonkean/tui-theme';

const Container = styled.div`
    position: relative;
    width: 100%;
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: 'interface-module-header-left . interface-module-header-right';
    align-items: center;
    background-color: white;
    box-shadow:
        0px 3px 21px rgba(0, 0, 0, 0.12),
        0px 2px 4px rgba(0, 0, 0, 0.1),
        0px 1px 0px #d8d8d8;
`;

const StyledIconButton = styled(IconButton)`
    left: 0;
`;

const LogoCommon = css`
    max-width: 110px;
    max-height: 60px;
    width: auto;
    height: auto;
`;

const StyledTonkeanLogo = styled(TonkeanLogo)`
    ${LogoCommon}
`;

const StyledCustomLogo = styled.img`
    ${LogoCommon}
`;

const BuildEnvContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const BuildEnvTitle = styled.div`
    font-size: ${FontSize.MSMALL_13};
    color: ${Theme.colors.gray_600};
`;

const LeftSide = styled.div`
    grid-area: interface-module-header-left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`;

const RightSide = styled.div`
    grid-area: interface-module-header-right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const PluggableActionsWrapper = styled.div`
    margin-left: 16px;
`;

interface InterfaceModuleHeaderProps {
    logoUrl?: string;
    versionType: WorkflowVersionType;
    formType?: FormType;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    currentCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    parentInitiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    itemInterfaceId?: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    currentFormId?: TonkeanId<TonkeanType.FORM>;
    themeColor?: Color;
    pluggableActions?: React.ReactNode;
    projectUrlSlug: string | undefined;
}

const InterfaceModuleHeader: React.FC<InterfaceModuleHeaderProps> = ({
    logoUrl,
    versionType,
    formType,
    workflowVersionId,
    currentCustomTriggerId,
    parentInitiativeId,
    currentFormId,
    themeColor,
    pluggableActions,
    projectUrlSlug,
    initiativeId,
    itemInterfaceId,
}) => {
    const hasParentinitiative = !!parentInitiativeId;

    const { intakeProgressTrackerEnabled, intakeProgressTrackerTitle, showVerticalIntakeProgressTracker } =
        useIntakeProgressTrackerConfiguration({
            workflowVersionId,
            currentCustomTriggerId,
            currentFormId,
            hasParentinitiative,
        });

    const [vertialProgressTrackerOpen, setVertialProgressTrackerOpen] = useState<boolean>(true);
    const isXSmallScreen = useIsXSmallScreen();

    const toggleVerticalProgressTracker = useCallback(() => {
        setVertialProgressTrackerOpen((prev) => !prev);
    }, []);

    const closeButtonClicked = useCallback(() => {
        setVertialProgressTrackerOpen(false);
    }, []);

    const isHorizontalIntakeProgressTracker =
        intakeProgressTrackerEnabled && !showVerticalIntakeProgressTracker && !isXSmallScreen;
    const isVerticalIntakeProgressTracker =
        intakeProgressTrackerEnabled && (showVerticalIntakeProgressTracker || isXSmallScreen);

    return (
        <Container>
            <LeftSide>
                {isVerticalIntakeProgressTracker && (
                    <>
                        <VerticalIntakeProgressTrackerPresenter
                            workflowVersionId={workflowVersionId}
                            currentCustomTriggerId={currentCustomTriggerId}
                            currentFormId={currentFormId}
                            intakeProgressTrackerTitle={intakeProgressTrackerTitle}
                            themeColor={themeColor}
                            isOpen={vertialProgressTrackerOpen}
                            closeButtonClicked={closeButtonClicked}
                        />
                        <StyledIconButton onClick={toggleVerticalProgressTracker} flat>
                            <IntakeProgressMenuIcon />
                        </StyledIconButton>
                    </>
                )}
                <Tooltip content="Homepage">
                    <Clickable
                        state="homepageView"
                        params={{ projectUrlSlug }}
                        data-automation="interface-page-template-logo-container"
                    >
                        {logoUrl ? (
                            <StyledCustomLogo alt="logo" src={logoUrl} data-automation="form-page-template-logo" />
                        ) : (
                            <StyledTonkeanLogo data-automation="form-page-template-logo" />
                        )}
                    </Clickable>
                </Tooltip>
                {versionType.toUpperCase() === WorkflowVersionType.DRAFT && formType === FormType.CREATE && (
                    <BuildEnvContainer
                        className="padding-normal-sm"
                        data-automation="interface-module-header-build-env-container"
                    >
                        <BuildIcon aria-hidden="true" focusable="false" />
                        <BuildEnvTitle>Build environment</BuildEnvTitle>
                    </BuildEnvContainer>
                )}
            </LeftSide>
            {isHorizontalIntakeProgressTracker && (
                <HorizontalIntakeProgressTrackerPresenter
                    workflowVersionId={workflowVersionId}
                    currentCustomTriggerId={currentCustomTriggerId}
                    currentFormId={currentFormId}
                    title={intakeProgressTrackerTitle}
                    themeColor={themeColor}
                />
            )}
            <RightSide>
                {initiativeId && itemInterfaceId && (
                    <InterfaceViewers
                        data-automation="interface-module-header-intake-request-viewers"
                        itemInterfaceId={itemInterfaceId}
                        themePrimaryColor={themeColor || Theme.colors.primary}
                        howManyViewersToShow={3}
                        initiativeId={initiativeId}
                    />
                )}
                {pluggableActions && (
                    <PluggableActionsWrapper data-automation="interface-module-header-pluggable-actions">
                        {pluggableActions}
                    </PluggableActionsWrapper>
                )}
            </RightSide>
        </Container>
    );
};

export default InterfaceModuleHeader;
