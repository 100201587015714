import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { DeprecatedPeopleSelector } from '@tonkean/angular-to-react-components';
import { Radio, RadioGroup } from '@tonkean/infrastructure';
import type { Person } from '@tonkean/tonkean-entities';
import { ConnectionPermission } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledRadio = styled(Radio)`
    font-size: 12px;
    color: ${Theme.current.palette.colorPicker.HEX_34393E};
`;

const PeopleSelectorWrapper = styled.div`
    margin-top: 10px;
    width: 600px;
`;

interface Props {
    connectionPermission?: ConnectionPermission;
    authorizedPeople?: Person[];
    onChange: (connectionPermission: ConnectionPermission, authorizedPeople: Person[]) => void;
    noOneLabel?: string;
}

const PeoplePermissionsPicker: React.FC<Props> = ({
    connectionPermission = ConnectionPermission.ALL_MEMBERS,
    authorizedPeople = [],
    onChange,
    noOneLabel,
}) => {
    const [currentAuthorizedPeople, setCurrentAuthorizedPeople] = useState<Person[]>(authorizedPeople);
    const [connectionPermissionsType, setConnectionPermissionsType] = useState<ConnectionPermission>();

    useEffect(() => {
        if (connectionPermission) {
            setConnectionPermissionsType(connectionPermission);
        }
    }, [connectionPermission]);

    const onPermissionsChange = useCallback(
        (changedConnectionPermissionsType) => {
            let authorized: Person[] = [];
            if (changedConnectionPermissionsType === ConnectionPermission.SPECIFIC_MEMBERS) {
                authorized = currentAuthorizedPeople;
            }
            setCurrentAuthorizedPeople(authorized);
            setConnectionPermissionsType(changedConnectionPermissionsType);
            onChange(changedConnectionPermissionsType, authorized);
        },
        [onChange, currentAuthorizedPeople],
    );

    return (
        <div>
            <RadioGroup
                size={InputSize.MEDIUM}
                value={connectionPermissionsType}
                onChange={onPermissionsChange}
                direction="row"
            >
                <StyledRadio value={ConnectionPermission.ALL_MEMBERS}>All Users</StyledRadio>
                <StyledRadio value={ConnectionPermission.SPECIFIC_MEMBERS}>Specific users/groups</StyledRadio>
                <StyledRadio value={ConnectionPermission.NO_ONE}>{noOneLabel || 'No one'}</StyledRadio>
            </RadioGroup>

            {connectionPermissionsType === ConnectionPermission.SPECIFIC_MEMBERS && (
                <PeopleSelectorWrapper>
                    <DeprecatedPeopleSelector
                        selectedPeople={currentAuthorizedPeople}
                        onTagAdded={() => {
                            onChange(connectionPermissionsType, currentAuthorizedPeople);
                        }}
                        onTagRemoved={() => {
                            onChange(connectionPermissionsType, currentAuthorizedPeople);
                        }}
                        doNotSearchOnlyOwnersInGroup
                        noCustom
                        includeGroups
                    />
                </PeopleSelectorWrapper>
            )}
        </div>
    );
};

export default PeoplePermissionsPicker;
