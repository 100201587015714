import type { ICellEditor } from '@ag-grid-community/core';
import groupBy from 'lodash.groupby';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import styled from 'styled-components';

import type { SelectStatusOption } from './components/StatusSelectComponent';
import { Option, SingleValue } from './components/StatusSelectComponent';
import type InitiativeRowData from '../../entities/InitiativeRowData';
import type ItemsGridCellRendererParams from '../../entities/ItemsGridCellEditorParams';

import { useStatesForWorkflowVersion } from '@tonkean/infrastructure';
import { SimpleSelect } from '@tonkean/infrastructure';
import { InitiativeStatus } from '@tonkean/tonkean-entities';

const StyledSimpleSelect = styled(SimpleSelect)`
    width: 100%;
`;

interface Props extends ItemsGridCellRendererParams<InitiativeRowData['state']> {}

const StatusSelectorCellEditor: React.ForwardRefRenderFunction<ICellEditor<InitiativeRowData['state']>, Props> = (
    { context: { workflowVersion }, value },
    ref,
) => {
    const [updatedValue, setUpdatedValue] = useState<InitiativeRowData['state']>(value || undefined);
    const { states } = useStatesForWorkflowVersion(workflowVersion);

    const statesOptions = useMemo<SelectStatusOption[]>(() => {
        return states.map(
            (mappedState) =>
                ({
                    ...mappedState,
                    value: mappedState.label,
                    label: mappedState.label,
                }) as SelectStatusOption,
        );
    }, [states]);

    const groupedOptions = useMemo(() => {
        return Object.entries(
            groupBy(statesOptions, ({ type }) =>
                type === InitiativeStatus.INTAKE ? 'Intake' : 'Triage and Coordination',
            ),
        ).map(([label, options]) => ({ label, options }));
    }, [statesOptions]);

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return updatedValue;
            },
        };
    });

    return (
        <StyledSimpleSelect
            value={updatedValue?.label}
            onChange={(label: string, option: SelectStatusOption) => {
                setUpdatedValue(option);
            }}
            options={groupedOptions}
            components={{
                // TODO: change to specific type
                Option: Option as any,
                SingleValue: SingleValue as any,
            }}
            defaultMenuIsOpen
            autoFocus
        />
    );
};

export default forwardRef(StatusSelectorCellEditor);
