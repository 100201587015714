import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const BotThreadReplyLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.BOT_THREAD_REPLY,
    displayName: 'New message on Thread',
    title: 'When a new message is sent to the Thread',
    iconClass: 'mod-bot-thread-reply',
    description: 'This trigger will activate for each of the messages added to the Thread.',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplaySettingsEdit: true,
    doesntHaveDefinition: true,
    cantMove: true,
    cantDrag: true,
    specialChild: true,
};

export default BotThreadReplyLogicBlockConfig;
