import template from './tnkBotSidePane.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkBotSidePane', {
    bindings: {
        onSidepaneOpenChanged: '&',
    },
    transclude: true,
    template,
    controller: 'BotSidePaneCtrl',
});
