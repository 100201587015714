import { lateConstructController } from '@tonkean/angular-components';
import { getFieldHighlightColors } from '@tonkean/constants';

/* @ngInject */
function RangesConfigurationCtrl($scope, $timeout, utils) {
    const ctrl = this;

    $scope.data = {
        // Component Bindings.
        targetCaption: ctrl.targetCaption,

        ranges: ctrl.ranges || [],
        isImportant: ctrl.isImportant,

        fieldType: ctrl.fieldType,
        fieldTargetType: ctrl.fieldTargetType,
        possibleValues: ctrl.possibleValues,

        projectIntegration: ctrl.projectIntegration,

        colors: getFieldHighlightColors(),
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.ranges && $scope.data.ranges.length) {
            initializeEditMode();

            // Updating the emphasize coloring.
            if ($scope.data.isImportant) {
                $timeout(() => $scope.updateRangesColorsStyle(false));
            }
        }
    };

    /**
     * @param changeObject {angular.IOnChangesObject}
     * @returns {void}
     */
    ctrl.$onChanges = (changeObject) => {
        if (changeObject.targetCaption) {
            $scope.data.targetCaption = changeObject.targetCaption.currentValue;
        }
        if (changeObject.ranges) {
            $scope.data.ranges = changeObject.ranges.currentValue || [];

            if (!changeObject.ranges.isFirstChange()) {
                initializeEditMode();
            }
        }
        if (changeObject.isImportant) {
            $scope.data.isImportant = changeObject.isImportant.currentValue;
        }
        if (changeObject.fieldType) {
            $scope.data.fieldType = changeObject.fieldType.currentValue;
        }
        if (changeObject.fieldTargetType) {
            $scope.data.fieldTargetType = changeObject.fieldTargetType.currentValue;
        }
        if (changeObject.possibleValues) {
            $scope.data.possibleValues = changeObject.possibleValues.currentValue;
        }
        if (changeObject.projectIntegration) {
            $scope.data.projectIntegration = changeObject.projectIntegration.currentValue;
        }
    };

    /**
     * Occurs once a condition of a range is selected.
     */
    $scope.onConditionSelection = function (selectedCondition, param) {
        const didChange = param.condition !== selectedCondition.apiName;
        const range = param;
        range.condition = selectedCondition.apiName;

        $scope.onRangesChange(didChange);
    };

    /**
     * Occurs once the value is changed. Note that for conditions with to and from values, the value is also the from value.
     */
    $scope.onValueChanged = function (valueObject, param) {
        const didChange = param.value !== valueObject.value;
        const range = param;
        range.value = valueObject.value;

        $scope.onRangesChange(didChange);
    };

    /**
     * Occurs when the to value is changed.
     */
    $scope.secondValueChanged = function (valueObject, param) {
        const didChange = param.secondValue !== valueObject.value;
        const range = param;
        range.secondValue = valueObject.value;

        $scope.onRangesChange(didChange);
    };

    /**
     * Occurs once a range or is important are changed.
     */
    $scope.onRangesChange = function (didChange) {
        if (ctrl.onRangesChange) {
            ctrl.onRangesChange({ ranges: $scope.data.ranges, isImportant: $scope.data.isImportant, didChange });
        }
    };

    /**
     * Selects the color for the range.
     */
    $scope.selectRangeColor = function (range, colorObj, index) {
        const didChange = range.color !== colorObj.color;

        range.color = colorObj.color;
        range.selectedColor = colorObj;

        $scope.updateRangesColorsStyle(false);
        updateRangeAlertBellColor(range.color, index);

        $scope.onRangesChange(didChange);
    };

    /**
     * Adds new range.
     */
    $scope.addNewRange = function () {
        $scope.data.ranges.push({
            selectedColor: $scope.data.colors[0],
            value: '',
            color: $scope.data.colors[0].color,
            isWarn: $scope.data.colors[0].isWarn,
            isAlert: true,
            isGather: $scope.data.fieldTargetType === 'COLUMN', // When GLOBAL can't alert because there is no owner.
        });

        // Update the colors' style in main thread.
        $timeout(() => {
            $scope.updateRangesColorsStyle(false);
            const rangeIndex = $scope.data.ranges.length - 1;
            updateRangeAlertBellColor($scope.data.ranges[rangeIndex].color, rangeIndex);
        }, 0);

        $scope.onRangesChange();
    };

    $scope.notifyAndAskClicked = function () {
        $scope.onRangesChange(true);
    };

    $scope.onEmphasizeRange = function () {
        $scope.data.isImportant = !$scope.data.isImportant;
        $scope.updateRangesColorsStyle(true);
    };

    /**
     * Coloring the isImportant field with the correct colors.
     */
    $scope.updateRangesColorsStyle = function (didChange) {
        const colorButtons = [...document.querySelectorAll('.field-definition-range-color')];

        if (colorButtons && colorButtons.length) {
            for (const [i, colorButton] of colorButtons.entries()) {
                const btn = angular.element(colorButton);

                // If "emphasize color" is checked.
                if ($scope.data.isImportant) {
                    // The indexes should match between the ranges view and the model itself (using the same i).
                    const color = $scope.data.ranges[i].color;
                    if (color) {
                        // Set the background color with the opacity it will have (same as in the cfm).
                        const rgb = utils.hexToRgb(color);
                        const emphasizedColor = `rgba(${rgb.join(',')},0.06)`;

                        btn.css('background-color', emphasizedColor);
                    } else {
                        btn.css('background-color', '');
                    }
                } else {
                    // If "emphasize color" is not checked clear the background.
                    btn.css('background-color', '');
                }
            }
        }

        $scope.onRangesChange(didChange);
    };

    /**
     * Deletes a range.
     */
    $scope.deleteRange = function (rangeIndex) {
        $scope.data.ranges.splice(rangeIndex, 1);

        $scope.onRangesChange(true);
    };

    /**
     * Updates the alert bell color.
     */
    function updateRangeAlertBellColor(color, index) {
        const alertTitles = document.getElementsByClassName(`field-definition-alert-title-${index}`);
        if (alertTitles && alertTitles.length) {
            for (const titleElement of alertTitles) {
                angular.element(titleElement).find('path').css('fill', color);
            }
        }
    }

    /**
     * Initializing the edit mode.
     */
    function initializeEditMode() {
        // Create a range colors array, so we can later go over it and update the rangeAlertBellColor (which needs a color and an index).
        const rangeColors = [];

        for (let i = 0; i < $scope.data.ranges.length; i++) {
            const range = $scope.data.ranges[i];

            range.selectedColor = getSelectedColor(range.color);
            rangeColors.push(range.color);
        }

        // Must update the range alert-bell color withing timeout, to update the html.
        $timeout(() => {
            for (const [i, rangeColor] of rangeColors.entries()) {
                updateRangeAlertBellColor(rangeColor, i);
            }
        });
    }

    /**
     * Gets the color for the range.
     */
    function getSelectedColor(colorCode) {
        for (let i = 0; i < $scope.data.colors.length; i++) {
            const color = $scope.data.colors[i];

            if (color.color === colorCode) {
                return color;
            }
        }

        // We didn't match on anything. Take the first one.
        return $scope.data.colors[0];
    }
}

export default angular.module('tonkean.app').controller('RangesConfigurationCtrl', lateConstructController(RangesConfigurationCtrl));
