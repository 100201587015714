<div>
    <!-- OAuth only integrations buttons -->
    <div
        ng-if="integration.login"
        class="tnk-tooltip mod-top"
        tnk-require="{{
            $root.pm.projectData.allowIntegrationsInTrial
                ? 'free'
                : integration && integrationRequireMap[integration.name]
                ? integrationRequireMap[integration.name]
                : defaultLicenseTierToAllow
        }}"
    >
        <!-- Using tnk-tooltip and not uib-tooltip cause the latter doesn't work on disabled buttons -->
        <span class="tnk-tooltip-text" ng-if="ngDisabled && ngDisabledTooltip">{{ ngDisabledTooltip }}</span>
        <!-- Will be shown if the integration is not at all connected -->
        <button
            type="button"
            class="btn btn-primary"
            ng-click="integration.login(integration)"
            ng-disabled="state[integration.name].connecting || ngDisabled"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Authenticate"
            analytics-label="{{ integration.name }}"
            ng-hide="state[integration.name].integrations || inlineMode"
        >
            {{ state[integration.name].connecting ? 'Connecting' : 'Connect' }}
        </button>

        <div
            ng-if="
                state[integration.name].integrations &&
                (state[integration.name].integrations[0].valid === false ||
                    state[integration.name].integrations[0].disabled)
            "
            class="btn-group flex"
            uib-dropdown
        >
            <!-- Will be shown if the integration is invalid and needs fixing -->
            <button
                type="button"
                class="btn btn-warning"
                ng-click="integration.login(integration, true)"
                ng-disabled="ngDisabled"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Fix"
                analytics-label="{{ integration.name }}"
            >
                <span class="relative">
                    Fix
                    <i
                        ng-if="isFixingMap[integration.name]"
                        class="loading-small mod-white absolute-right margin-right-md-minus margin-top-xxs"
                    ></i>
                </span>
            </button>

            <!-- Options dropdown -->
            <button type="button" class="btn btn-warning" uib-dropdown-toggle ng-disabled="ngDisabled">
                <span class="caret"></span>
                <span class="sr-only">More options</span>
            </button>
            <ul
                uib-dropdown-menu
                role="menu"
                class="common-no-min-width common-width-100"
                aria-labelledby="split-button"
            >
                <li role="menuitem">
                    <tnk-project-integration-remove-modal
                        project-integration-id="projectIntegration.id"
                        project-integration-display-name="projectIntegration.displayName"
                    ></tnk-project-integration-remove-modal>
                </li>
            </ul>
        </div>

        <!-- Will be shown if the integration exists and is valid -->
        <!-- $scope.state is map of IntegrationType to Collection of Project Integrations. For example -
            jira: { integrations : [ {prin}, {prin}, ... ] }
        -->
        <div
            class="btn-group flex"
            uib-dropdown
            ng-hide="
                fixMode &&
                state[integration.name].integrations &&
                state[integration.name].integrations[0].valid !== false &&
                !state[integration.name].integrations[0].disabled
            "
            ng-if="
                state[integration.name].integrations &&
                state[integration.name].integrations[0].valid !== false &&
                !state[integration.name].integrations[0].disabled
            "
        >
            <button
                type="button"
                class="btn btn-default"
                ng-click="integration.login(integration)"
                ng-disabled="ngDisabled"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ integration.name }}"
            >
                Edit
            </button>
            <button type="button" class="btn btn-default" uib-dropdown-toggle ng-disabled="ngDisabled">
                <span class="caret"></span>
                <span class="sr-only">More options</span>
            </button>
            <ul uib-dropdown-menu role="menu" class="common-no-min-width" aria-labelledby="split-button">
                <li role="menuitem">
                    <a
                        ng-click="integration.login(integration)"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth Dropdown"
                        analytics-label="{{ integration.name }}"
                    >
                        Change User
                    </a>
                </li>
                <li role="menuitem" ng-if="!hideUpdateDataMenuOption">
                    <a
                        ng-click="runCollect(integration)"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Collect project integration"
                        analytics-label="{{ integration.name }}"
                    >
                        Update Data
                    </a>
                </li>

                <li
                    ng-if="
                        !hideDataRetentionMenuOption &&
                        $root.features[pm.project.id].tonkean_feature_data_retention_salesforce &&
                                integration.name === 'salesforce'
                    "
                    role="menuitem"
                >
                    <a
                        ng-click="openEntitiesConfigurationModal(projectIntegration, integration)"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Collect project integration"
                        analytics-label="{{ integration.name }}"
                    >
                        Configure Data Collection
                    </a>
                </li>
                <li
                    class="divider"
                    ng-if="
                        !hideUpdateDataMenuOption ||
                        (!hideDataRetentionMenuOption &&
                            $root.features[pm.project.id].tonkean_feature_data_retention_salesforce &&
                                    integration.name === 'salesforce')
                    "
                ></li>
                <li role="menuitem" class="padding-left-md padding-top-xs padding-bottom-xs">
                    <tnk-project-integration-remove-modal
                        project-integration-id="projectIntegration.id"
                        project-integration-display-name="projectIntegration.displayName"
                    ></tnk-project-integration-remove-modal>
                </li>
            </ul>
        </div>
    </div>

    <!-- Modal integration buttons -->
    <div
        ng-if="!integration.login"
        class="tnk-tooltip mod-top"
        tnk-require="{{
            $root.pm.projectData.allowIntegrationsInTrial
                ? 'free'
                : integration && integrationRequireMap[integration.name]
                ? integrationRequireMap[integration.name]
                : defaultLicenseTierToAllow
        }}"
    >
        <!-- Using tnk-tooltip and not uib-tooltip cause the latter doesn't work on disabled buttons -->
        <span class="tnk-tooltip-text" ng-if="ngDisabled && ngDisabledTooltip">{{ ngDisabledTooltip }}</span>
        <button
            type="button"
            class="btn btn-primary"
            ng-click="setUp(integration, projectIntegration)"
            ng-disabled="ngDisabled"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Set up"
            analytics-label="{{ integration.name }}"
            ng-hide="state[integration.name] || inlineMode"
        >
            Connect
        </button>

        <!-- Edit state -->
        <div
            class="btn-group flex"
            uib-dropdown
            ng-if="
                state[integration.name] &&
                state[integration.name].integrations[0].valid !== false &&
                !state[integration.name].integrations[0].disabled
            "
        >
            <!-- Edit button -->
            <button
                type="button"
                class="btn btn-default"
                data-automation="integration-group-button-template-edit-button"
                ng-click="setUp(integration, projectIntegration)"
                ng-disabled="ngDisabled"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Edit"
                analytics-label="{{ integration.name }}"
                ng-if="
                    state[integration.name] &&
                    state[integration.name].integrations[0].valid !== false &&
                    !state[integration.name].integrations[0].disabled
                "
            >
                Edit
            </button>

            <!-- Options dropdown -->
            <button type="button" data-automation="integration-group-button-template-template-more-options-button" class="btn btn-default" uib-dropdown-toggle ng-disabled="ngDisabled">
                <span class="caret"></span>
                <span class="sr-only">More options</span>
            </button>

            <ul
                uib-dropdown-menu
                role="menu"
                class="common-no-min-width common-width-100"
                aria-labelledby="split-button"
            >
                <li role="menuitem" ng-if="!hideUpdateDataMenuOption">
                    <a
                        ng-click="runCollect(integration)"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Collect project integration"
                        analytics-label="{{ integration.name }}"
                    >
                        Update Data
                    </a>
                </li>
                <li
                    ng-if="
                        !hideDataRetentionMenuOption &&
                        $root.features[pm.project.id].tonkean_feature_data_retention_salesforce &&
                                integration.name === 'salesforce'
                    "
                    role="menuitem"
                >
                    <a
                        ng-click="openEntitiesConfigurationModal(projectIntegration, integration)"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Collect project integration"
                        analytics-label="{{ integration.name }}"
                    >
                        Configure Data Collection
                    </a>
                </li>
                <li
                    class="divider"
                    ng-if="
                        !hideUpdateDataMenuOption ||
                        (!hideDataRetentionMenuOption &&
                            $root.features[pm.project.id].tonkean_feature_data_retention_salesforce &&
                                    integration.name === 'salesforce')
                    "
                ></li>
                <li role="menuitem">
                    <tnk-project-integration-remove-modal
                        project-integration-id="projectIntegration.id"
                        project-integration-display-name="projectIntegration.displayName"
                    ></tnk-project-integration-remove-modal>
                </li>
            </ul>
        </div>

        <!-- Fix state -->
        <div
            class="btn-group flex"
            uib-dropdown
            ng-if="
                state[integration.name] &&
                (state[integration.name].integrations[0].valid === false ||
                    state[integration.name].integrations[0].disabled)
            "
        >
            <!-- Fix button -->
            <button
                type="button"
                class="btn btn-warning"
                ng-click="setUp(integration, projectIntegration, true)"
                ng-disabled="ngDisabled"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Fix"
                analytics-label="{{ integration.name }}"
            >
                Fix
            </button>

            <!-- Options dropdown -->
            <button type="button" class="btn btn-warning" uib-dropdown-toggle ng-disabled="ngDisabled">
                <span class="caret"></span>
                <span class="sr-only">More options</span>
            </button>

            <ul
                uib-dropdown-menu
                role="menu"
                class="common-no-min-width common-width-100"
                aria-labelledby="split-button"
            >
                <li role="menuitem">
                    <tnk-project-integration-remove-modal
                        project-integration-id="projectIntegration.id"
                        project-integration-display-name="projectIntegration.displayName"
                    ></tnk-project-integration-remove-modal>
                </li>
            </ul>
        </div>
    </div>
</div>
