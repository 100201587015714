import type { ColDef } from '@ag-grid-community/core';

import {
    EMPHASIZED_HEADER_CLASS_NAME,
    EMPTY_AND_REQUIRED_CLASS_NAME,
    FILLED_FILTER_ICON_CLASS_NAME,
    REQUIRED_HEADER_CLASS_NAME,
} from './customClassNames';
import isEditableColumn from './getIsEditableColumn';
import ColumnId from '../configs/config/columnId';
import type InitiativeRowData from '../entities/InitiativeRowData';

import { SpecialFieldsKey, type FieldDefinitionKey } from '@tonkean/tonkean-entities';

const getTitleDefinition = (
    commonDef: Partial<ColDef<InitiativeRowData>>,
    fieldDefinitionKeyToName: Record<FieldDefinitionKey, string | undefined>,
    enableFilter: boolean | undefined,
    userCanEdit: boolean,
    getIsFieldEmptyAndRequired: (fieldDefinitionKey: FieldDefinitionKey, row: InitiativeRowData) => boolean,
    shouldShowRequiredIndication: (fieldDefinitionKey: FieldDefinitionKey) => boolean,
    isEmphasizeText: boolean | undefined,
    filteredColumnIds: string[] | undefined,
    drilldownColumnId: string | undefined,
) => {
    return {
        ...commonDef,
        headerName: fieldDefinitionKeyToName[SpecialFieldsKey.TITLE] || 'Title',
        field: ColumnId.Title,
        cellRenderer: 'titleCellRenderer',
        editable: (params) => {
            return isEditableColumn(params.node.data?.title, ColumnId.Title, userCanEdit, drilldownColumnId);
        },
        equals: (title1: string, title2: string) => {
            return title1?.trim() === title2?.trim();
        },
        filter: enableFilter && 'agTextColumnFilter',
        tooltipValueGetter: ({ value }) => {
            return value;
        },
        cellClassRules: {
            [EMPTY_AND_REQUIRED_CLASS_NAME]: (params) => {
                return !!params.data && getIsFieldEmptyAndRequired(SpecialFieldsKey.TITLE, params.data);
            },
        },
        headerClass: ({ column }) => {
            const classes: string[] = [];
            if (shouldShowRequiredIndication(SpecialFieldsKey.TITLE)) {
                classes.push(REQUIRED_HEADER_CLASS_NAME);
            }
            if (isEmphasizeText) {
                classes.push(EMPHASIZED_HEADER_CLASS_NAME);
            }
            if (column?.getColId() && filteredColumnIds?.includes(column.getColId())) {
                classes.push(FILLED_FILTER_ICON_CLASS_NAME);
            }
            return classes;
        },
    } as ColDef<InitiativeRowData>;
};

export default getTitleDefinition;
