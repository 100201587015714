import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import botQuestionAndSendNotificationValidator from '../sharedConfigComponents/validators/botQuestionAndSendNotificatinValidator';
import itemInterfaceMustBeSelectedValidator from '../sharedConfigComponents/validators/itemInterfaceMustBeSelectedValidator';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const MonitorTracksLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.SEND_ITEM_INTERFACE,
    title: 'Send Item Interface',
    iconClass: 'mod-send-custom-interface',
    description:
        'Send information about the current item in a custom interface to someone who participates in the process.',
    hasPersonConfig: true,
    creatingActionItems: true,
    doNotDisplayNotificationsEdit: true,
    defaultActionDefinition: (project) => {
        return {
            initiativeOwner: true,
            communicationProjectIntegrationId: project?.defaultCommunicationProjectIntegration?.id,
        };
    },
    validatorAsync: ({
        definition,
        communicationIntegrationsService,
        group,
        syncConfigCacheManager,
        workflowVersionInfoRetriever,
        projectManager,
    }) => {
        const validationObject: LogicBlockValidationObject = {};

        // Checking whether the user selected to send the form via dialog but doesn't have connected communication integration
        if (definition.formType === 'DIALOG' && !communicationIntegrationsService.anyIntegrationsConnected()) {
            validationObject.formTypeError = `No communication data source is connected. Please connect ${communicationIntegrationsService
                .getIntegrationTypesShortNames()
                .join('\\')}.`;
        }

        botQuestionAndSendNotificationValidator(
            validationObject,
            definition,
            workflowVersionInfoRetriever,
            syncConfigCacheManager,
            group,
            projectManager,
        );

        itemInterfaceMustBeSelectedValidator(validationObject, definition);

        return Promise.resolve(Object.keys(validationObject).length > 0 ? validationObject : null);
    },
    dataAutomation: 'send-item-interface',
};

export default MonitorTracksLogicBlockConfig;
