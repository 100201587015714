<label
    for="{{::data.id}}"
    class="tnk-radio-container {{ data.classes }}"
    ng-click="onClick($event, data.onClickParam)"
    ng-class="{ 'tnk-radio-disabled': data.viewOnly, pointer: !data.viewOnly }"
    data-automation="{{ data.automationIdentifier }}"
>
    <!-- Regular text (and possibly angular bindings) label -->
    <span ng-if="data.label" ng-bind="data.label"></span>

    <!-- Html template label -->
    <span ng-if="!data.label" ng-transclude></span>

    <input
        type="radio"
        id="{{::data.id}}"
        name="{{::data.name}}"
        ng-model="data.model"
        value="{{::data.value}}"
        ng-attr-required="{{::data.isRequired}}"
        ng-disabled="data.viewOnly"
    />
    <span class="tnk-radio" data-automation="tnk-radio-button-template-click-radio-button-{{::data.value}}"></span>
</label>
