import FormulaTreeNodeBase from './FormulaTreeNodeBase';
import { FieldType } from '../../FieldDefinition';
import type FormulaField from '../FormulaField';
import { FormulaTreeNodeType } from '../FormulaTreeNodeType';
import type { ServerFormulaExpressionConstNode, ServerFormulaExpressionNode } from '../ServerFormulaExpressionNode';

export class FormulaTreeConstNode extends FormulaTreeNodeBase {
    public declare type: FormulaTreeNodeType.CONST;

    constructor(
        public override dataType: FieldType,
        public value: string,
        public override field: FormulaField,
        id?: number,
    ) {
        super(FormulaTreeNodeType.CONST, field, id);
    }

    public override toString() {
        switch (this.dataType) {
            case FieldType.Number:
            case FieldType.Boolean:
                return this.value;

            case FieldType.Date:
                return `d"${this.escapeString(this.value)}"`;

            case FieldType.List:
            case FieldType.LongString:
            case FieldType.String:
                return `"${this.escapeString(this.value)}"`;
            case FieldType.URL:
                return this.value;
        }
    }

    /**
     * Returns the value to show in the formula builder
     */
    public override toDisplayString(): string {
        switch (this.dataType) {
            case FieldType.Number:
            case FieldType.Boolean:
            case FieldType.Date:
                return this.value;

            case FieldType.List:
            case FieldType.LongString:
            case FieldType.String:
                return this.field.isHTMLTag ? `<${this.value}>` : `"${this.value}"`;
            case FieldType.URL:
                return this.value;
        }
    }

    public override getServerFormulaNode(): ServerFormulaExpressionNode {
        return {
            '@type': 'const',
            value: this.value,
            constType: this.dataType,
        } as ServerFormulaExpressionConstNode;
    }

    protected override getType() {
        return this.dataType;
    }

    public override clone(field: FormulaField) {
        return new FormulaTreeConstNode(this.dataType, this.value, field, this.id);
    }

    /**
     * Prepend `\` to `\` and `"`
     *
     * @param str - the source string
     * @returns the escaped string
     */
    public escapeString(str: string) {
        return str.replaceAll('"', '\\"');
    }
}
