import React from 'react';

import { Placeholder, ZebraTableBodyCell } from '@tonkean/infrastructure';
import { MetricRange } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

interface Props {
    stats: object;
    range: MetricRange;
}

const defaultStatisticLabel = '-';

const GridStatistic: React.FC<Props> = ({ stats, range = MetricRange.ALL_YEAR }) => {
    // Loading
    if (!stats) {
        return (
            <ZebraTableBodyCell>
                <Placeholder $width={FontSize.SMALL_12} $height={FontSize.SMALL_12} $circle />
            </ZebraTableBodyCell>
        );
    }

    // Get the statistic in the given range
    const value = stats[range];

    if (utils.isNullOrEmpty(value)) {
        // If it does not exist, put a default label
        return <ZebraTableBodyCell>{defaultStatisticLabel}</ZebraTableBodyCell>;
    } else {
        // Format value to K notation (15K, 4M etc)
        const shortStatisticLabel = utils.nFormatter(value || 0);
        return <ZebraTableBodyCell>{shortStatisticLabel}</ZebraTableBodyCell>;
    }
};

export default GridStatistic;
