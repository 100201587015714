import { useEffect, useRef } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type {
    InterfaceCTASavedCustomTriggerAction,
    ItemInterface,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

interface LastActivatedOnLoadActionsInfo {
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>;
    interfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>;
}

const useActivateOnLoadActions = (
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>,
    itemInterface?: ItemInterface,
    reloadInitiativeFromServer?: () => void,
    applyFastReloadInterval?: () => void,
) => {
    const [{}, triggerButtonCustomTrigger] = useLazyTonkeanService('triggerButtonCustomTrigger');
    const hasEffectRunForCurrentInputs = useRef<LastActivatedOnLoadActionsInfo | null>(null);

    useEffect(() => {
        if (!initiativeId || !itemInterface) {
            return;
        }

        if (
            hasEffectRunForCurrentInputs?.current?.initiativeId === initiativeId &&
            hasEffectRunForCurrentInputs?.current?.interfaceId === itemInterface?.id
        ) {
            return;
        }

        const triggerButtonCustomTriggerPromises: Promise<{
            solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER>;
        }>[] = [];

        const actionsToRun = itemInterface?.configuration?.onLoadActions?.filter((action) => action?.shown);

        if (!actionsToRun) {
            return;
        }

        actionsToRun?.forEach((action: InterfaceCTASavedCustomTriggerAction) => {
            triggerButtonCustomTriggerPromises.push(
                triggerButtonCustomTrigger(action.id, initiativeId, action.label, true),
            );
        });

        Promise.all(triggerButtonCustomTriggerPromises).then(() => {
            reloadInitiativeFromServer?.();
            applyFastReloadInterval?.();
        });

        hasEffectRunForCurrentInputs.current = {
            initiativeId,
            interfaceId: itemInterface.id,
        };
    }, [applyFastReloadInterval, initiativeId, itemInterface, reloadInitiativeFromServer, triggerButtonCustomTrigger]);
};

export default useActivateOnLoadActions;
