<div class="tnk-docusign-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'UPLOAD_ATTACHMENT'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            parameters-values="data.definition.parametersValues"
            info-message="'Maximum document size is 5MB'"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'TRANSFER_DOCUMENT'" class="margin-bottom-xlg">
        <!-- Document ID -->
        <div class="margin-top margin-bottom">
            <div class="margin-bottom-xs">
                Document ID:
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="If empty, all documents will be combined"
                ></i>
            </div>

            <div class="common-color-light-grey common-size-xxxs margin-bottom-sm">
                <b>Options:</b>
                The ID of the document to transfer.
                <br/>
                Alternatively, you can use one of the following:
                <br/>
                combined, archive, certificate & portfolio.
            </div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Document ID"
                    saved-expression="data.definition.documentIdExpression"
                    on-tonkean-expression-changed="
                    onDocumentIdTonkeanExpressionChanged(expression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <tnk-worker-target-storage-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            should-display-delete-sign="false"
            new-document-name="data.definition.newDocumentName"
            info-message="'Maximum document size is 5MB'"
            on-worker-target-storage-selection-changed="
                onWorkerTargetStorageSelectionChanged(storageIntegrationId, newDocumentName, shouldSaveLogic)
            "
        ></tnk-worker-target-storage-selection>

        <div
            ng-if="data.validationObject.storageIntegrationId"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.storageIntegrationId }}
        </div>

        <!-- Target Path -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Target Path:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the root folder will be used"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Target Path"
                saved-original-expression="data.definition.targetPathExpression.originalExpression"
                saved-evaluated-expression="data.definition.targetPathExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onTargetPathTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'ADD_RECIPIENTS_WITH_NAME'" class="margin-bottom-xlg">
        <div class="margin-bottom">
            <div
                ng-repeat="recipientObject in data.definition.recipientsObjects"
                class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs"
            >
                <div class="margin-bottom">
                    <div class="flex-vmiddle">
                        <div class="margin-right margin-top-xs">
                            Recipient Name:
                            <i
                                class="fa fa-info-circle common-color-primary margin-left-xs"
                                uib-tooltip="You may have multiple names separated by commas"
                            ></i>
                        </div>

                        <div class="flex-grow"></div>

                        <button
                            class="
                                svg-icon-xs
                                common-color-grey
                                svg-icon-hover-primary
                                btn btn-no-border btn-on-hover
                                pointer
                                flex-no-shrink
                                margin-left-xxs
                            "
                            ng-click="removeRecipientObject($index)"
                        >
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </button>
                    </div>
                    <tnk-tonkean-expression
                        class="flex-grow"
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        preview-evaluation-source="data.previewEvaluationSource"
                        expression-unique-identifier="{{ $index }}"
                        saved-expression="data.definition.recipientsObjects[$index].name"
                        on-tonkean-expression-changed="
                            onRecipientNameTonkeanExpressionChanged(expression, expressionIdentifier, shouldSaveLogic)
                        "
                    ></tnk-tonkean-expression>
                </div>

                <div class="margin-bottom">
                    <div class="margin-right margin-top-xs">
                        Recipient Email:
                        <i
                            class="fa fa-info-circle common-color-primary margin-left-xs"
                            uib-tooltip="You may have multiple emails separated by commas"
                        ></i>
                    </div>
                    <tnk-tonkean-expression
                        class="flex-grow"
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        preview-evaluation-source="data.previewEvaluationSource"
                        expression-unique-identifier="{{ $index }}"
                        saved-expression="data.definition.recipientsObjects[$index].email"
                        on-tonkean-expression-changed="
                            onRecipientEmailTonkeanExpressionChanged(expression, expressionIdentifier, shouldSaveLogic)
                        "
                    ></tnk-tonkean-expression>
                </div>

                <div class="margin-bottom">
                    <div class="margin-right margin-top-xs">Recipient Type:</div>
                    <ui-select
                        theme="bootstrap"
                        ng-model="data.definition.recipientsObjects[$index].type"
                        ng-change="onRecipientTypeTonkeanExpressionChanged(true)"
                    >
                        <ui-select-match placeholder="Choose a recipient type">
                            {{ data.definition.recipientsObjects[$index].type }}
                        </ui-select-match>

                        <ui-select-choices
                            repeat="possibleRecipientType in data.possibleRecipientTypes | filter:$select.search"
                        >
                            <div ng-bind-html="possibleRecipientType | escapeHtml | highlight: $select.search"></div>
                        </ui-select-choices>
                    </ui-select>
                </div>

                <div class="margin-bottom">
                    <div class="margin-right margin-top-xs">Routing Order:</div>
                    <tnk-tonkean-expression
                        class="flex-grow"
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        preview-evaluation-source="data.previewEvaluationSource"
                        expression-unique-identifier="{{ $index }}"
                        saved-expression="data.definition.recipientsObjects[$index].routingOrder"
                        on-tonkean-expression-changed="
                            onRoutingOrderTonkeanExpressionChanged(expression, expressionIdentifier, shouldSaveLogic)
                        "
                    ></tnk-tonkean-expression>
                </div>
            </div>

            <a ng-click="addRecipientObject()">+ Add Recipient</a>

            <div ng-if="data.validationObject.noRecipients" class="common-color-danger">
                {{ data.validationObject.noRecipients }}
            </div>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'CREATE_ENVELOPE_WITH_TABS'" class="margin-bottom-xlg">
        <div class="margin-bottom">
            <!-- Email Subject -->
            <div class="margin-right margin-top-xs">Email Subject:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Email Subject"
                saved-expression="data.definition.emailSubjectExpression"
                on-tonkean-expression-changed="onEmailSubjectTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div
                ng-if="data.validationObject.emailSubjectError"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.emailSubjectError }}
            </div>
        </div>

        <div class="margin-bottom">
            <!-- Email Message -->
            <div class="margin-right margin-top-xs">Email Message (Optional):</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Email Message"
                    mod-text-area="true"
                    text-area-number-of-rows="3"
                    saved-expression="data.definition.emailMessageExpression"
                    on-tonkean-expression-changed="onEmailMessageTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-bottom">
            <!-- Status -->
            <div class="margin-right margin-top-xs">
                Status:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Select 'sent' for sending the envelope immediately, or 'created' for a draft."
                ></i>
            </div>
            <ui-select theme="bootstrap" ng-model="data.definition.status" ng-change="onStatusChanged(true)">
                <ui-select-match placeholder="Choose a Envelope status">
                    {{ data.definition.status }}
                </ui-select-match>

                <ui-select-choices
                    repeat="possibleREnvelopeStatus in data.possibleREnvelopeStatuses | filter:$select.search"
                >
                    <div ng-bind-html="possibleREnvelopeStatus | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>

            <div ng-if="data.validationObject.statusError" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.statusError }}
            </div>
        </div>

        <div class="margin-bottom">
            <!-- Template ID -->
            <div class="margin-right margin-top-xs">Template ID:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Template ID"
                saved-expression="data.definition.templateIdExpression"
                on-tonkean-expression-changed="onTemplateIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-bottom">
            <tnk-worker-file-selection
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                custom-trigger-id="{{ data.configuredLogic.node.id }}"
                file-source-type="{{ data.definition.fileSourceType }}"
                preview-evaluation-source="data.previewEvaluationSource"
                storage-integration-id="{{ data.definition.storageIntegrationId }}"
                storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
                should-display-delete-sign="false"
                url-expression="data.definition.url"
                extended-match-configuration="data.definition.extendedMatchConfiguration"
                worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
                worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
                info-message="'Maximum document size is 5MB'"
                on-worker-file-selection-changed="
                    onWorkerFileSelectionChanged(
                        fileSourceType,
                        storageIntegrationId,
                        extendedMatchConfiguration,
                        urlExpression,
                        workerStaticAssetId,
                        workerStaticAssetDisplayName,
                        parametersValues,
                        shouldSaveLogic,
                        storageIntegrationActionId
                    )
                "
            ></tnk-worker-file-selection>

            <div
                ng-if="data.validationObject.invalidFileChoice"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.invalidFileChoice }}
            </div>
        </div>

        <div class="margin-bottom">
            <div ng-if="data.definition.recipientsObjects.length > 0" class="margin-right margin-top-xs margin-bottom">
                {{ data.definition.templateIdExpression.evaluatedExpression === '' ? 'Recipients' : 'Template Roles' }}
                ({{ data.definition.recipientsObjects.length }}):
            </div>

            <div ng-repeat="(recipientIndex, recipientObject) in data.definition.recipientsObjects" class="flex">
                <div class="margin-left-xs margin-right-xs margin-top">{{ recipientIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">Name:</div>

                            <div class="flex-grow"></div>

                            <button
                                class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                ng-click="removeRecipientObject(recipientIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.recipientsObjects[recipientIndex].name"
                            on-tonkean-expression-changed="
                                onRecipientNameTonkeanExpressionChanged(expression, recipientIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Email:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.recipientsObjects[recipientIndex].email"
                            on-tonkean-expression-changed="
                                onRecipientEmailTonkeanExpressionChanged(expression, recipientIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <div class="margin-bottom" ng-if="data.definition.templateIdExpression.evaluatedExpression !== ''">
                        <div class="margin-right margin-top-xs">Role Name:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.recipientsObjects[recipientIndex].roleName"
                            on-tonkean-expression-changed="
                                onRoleNameTonkeanExpressionChanged(expression, recipientIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <div class="margin-bottom" ng-if="data.definition.templateIdExpression.evaluatedExpression === ''">
                        <div class="margin-right margin-top-xs">Recipient Type:</div>
                        <ui-select
                            theme="bootstrap"
                            ng-model="data.definition.recipientsObjects[recipientIndex].type"
                            ng-change="onRecipientTypeTonkeanExpressionChanged(true)"
                        >
                            <ui-select-match placeholder="Choose a recipient type">
                                {{ data.definition.recipientsObjects[recipientIndex].type }}
                            </ui-select-match>

                            <ui-select-choices
                                repeat="possibleRecipientType in data.possibleRecipientTypes | filter:$select.search"
                            >
                                <div
                                    ng-bind-html="possibleRecipientType | escapeHtml | highlight: $select.search"
                                ></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <div class="margin-bottom" ng-if="data.definition.templateIdExpression.evaluatedExpression === ''">
                        <div class="margin-right margin-top-xs">Routing Order:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            expression-unique-identifier="{{ $index }}"
                            saved-expression="data.definition.recipientsObjects[$index].routingOrder"
                            on-tonkean-expression-changed="
                                onRoutingOrderTonkeanExpressionChanged(
                                    expression,
                                    expressionIdentifier,
                                    shouldSaveLogic
                                )
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <div
                        ng-repeat="(tabIndex, recipientTabObject) in data.definition.recipientsObjects[recipientIndex].recipientTabObjects"
                        class="flex"
                    >
                        <div class="margin-left-xs margin-right-xs margin-top">
                            {{ recipientIndex + 1 }}.{{ tabIndex + 1 }}.
                        </div>
                        <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                            <div class="margin-bottom">
                                <div class="flex-vmiddle">
                                    <div class="margin-right margin-top-xs">Tab Type:</div>

                                    <div class="flex-grow"></div>

                                    <button
                                        class="
                                            svg-icon-xs
                                            common-color-grey
                                            svg-icon-hover-primary
                                            btn btn-no-border btn-on-hover
                                            pointer
                                            flex-no-shrink
                                            margin-left-xxs
                                        "
                                        ng-click="removeRecipientTabObject(recipientIndex, tabIndex)"
                                    >
                                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                    </button>
                                </div>

                                <ui-select
                                    theme="bootstrap"
                                    ng-model="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].tabType"
                                    ng-change="onRecipientTabTypeChanged(true)"
                                >
                                    <ui-select-match placeholder="Choose a tab type">
                                        {{
                                            data.definition.recipientsObjects[recipientIndex].recipientTabObjects[
                                                tabIndex
                                            ].tabType
                                        }}
                                    </ui-select-match>

                                    <ui-select-choices
                                        repeat="possibleTab in data.possibleTabs | filter:$select.search"
                                    >
                                        <div ng-bind-html="possibleTab | escapeHtml | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Tab Label:</div>
                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    expression-unique-identifier="{{
                                        'tabLabelExpression_' + recipientIndex + '_' + tabIndex
                                    }}"
                                    disabled="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].anchorStringExpression.originalExpression !== ''"
                                    saved-expression="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].tabLabelExpression"
                                    on-tonkean-expression-changed="
                                        onTabLabelTonkeanExpressionChanged(
                                            expression,
                                            recipientIndex,
                                            tabIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Or</div>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Anchor String:</div>
                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    expression-unique-identifier="{{
                                        'anchorStringExpression_' + recipientIndex + '_' + tabIndex
                                    }}"
                                    disabled="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].tabLabelExpression.originalExpression !== ''"
                                    saved-expression="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].anchorStringExpression"
                                    on-tonkean-expression-changed="
                                        onAnchorStringTonkeanExpressionChanged(
                                            expression,
                                            recipientIndex,
                                            tabIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Anchor X Offset:</div>
                                <tnk-tonkean-expression
                                        class="flex-grow"
                                        group-id="{{ data.groupId }}"
                                        workflow-version-id="{{ data.workflowVersionId }}"
                                        logic-id="{{ data.configuredLogic.node.id }}"
                                        preview-evaluation-source="data.previewEvaluationSource"
                                        saved-expression="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].anchorXOffset"
                                        on-tonkean-expression-changed="
                                        onAnchorXOffsetTonkeanExpressionChanged(
                                            expression,
                                            recipientIndex,
                                            tabIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Anchor Y Offset:</div>
                                <tnk-tonkean-expression
                                        class="flex-grow"
                                        group-id="{{ data.groupId }}"
                                        workflow-version-id="{{ data.workflowVersionId }}"
                                        logic-id="{{ data.configuredLogic.node.id }}"
                                        preview-evaluation-source="data.previewEvaluationSource"
                                        saved-expression="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].anchorYOffset"
                                        on-tonkean-expression-changed="
                                        onAnchorYOffsetTonkeanExpressionChanged(
                                            expression,
                                            recipientIndex,
                                            tabIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Tooltip:</div>
                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    saved-expression="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].tooltipExpression"
                                    on-tonkean-expression-changed="
                                        onTooltipTonkeanExpressionChanged(
                                            expression,
                                            recipientIndex,
                                            tabIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Value:</div>
                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    saved-expression="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].valueExpression"
                                    on-tonkean-expression-changed="
                                        onValueTonkeanExpressionChanged(
                                            expression,
                                            recipientIndex,
                                            tabIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Font:</div>
                                <ui-select
                                        theme="bootstrap"
                                        ng-model="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].tabFont"
                                        ng-change="onDefinitionChanged(true)"
                                >
                                    <ui-select-match placeholder="Choose a font">
                                        {{
                                        data.definition.recipientsObjects[recipientIndex].recipientTabObjects[
                                                tabIndex
                                                ].tabFont || 'Default'
                                        }}
                                    </ui-select-match>

                                    <ui-select-choices
                                            repeat="possibleFont in data.possibleFonts | filter:$select.search"
                                    >
                                        <div ng-bind-html="possibleFont | escapeHtml | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Font Size:</div>
                                <ui-select
                                        theme="bootstrap"
                                        ng-model="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].tabFontSize"
                                        ng-change="onDefinitionChanged(true)"
                                >
                                    <ui-select-match placeholder="Choose a font size">
                                        {{
                                        data.definition.recipientsObjects[recipientIndex].recipientTabObjects[
                                                tabIndex
                                                ].tabFontSize || 'Default'
                                        }}
                                    </ui-select-match>

                                    <ui-select-choices
                                            repeat="possibleFontSize in data.possibleFontSizes | filter:$select.search"
                                    >
                                        <div ng-bind-html="possibleFontSize | escapeHtml | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>

                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Font Color:</div>
                                <ui-select
                                        theme="bootstrap"
                                        ng-model="data.definition.recipientsObjects[recipientIndex].recipientTabObjects[tabIndex].tabFontColor"
                                        ng-change="onDefinitionChanged(true)"
                                >
                                    <ui-select-match placeholder="Choose a font color">
                                        {{
                                        data.definition.recipientsObjects[recipientIndex].recipientTabObjects[
                                                tabIndex
                                                ].tabFontColor || 'Default'
                                        }}
                                    </ui-select-match>

                                    <ui-select-choices
                                            repeat="possibleFontColor in data.possibleFontColors | filter:$select.search"
                                    >
                                        <div ng-bind-html="possibleFontColor | escapeHtml | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>
                    </div>
                    <a ng-click="addRecipientTabObject(recipientIndex)">+ Add Tab</a>
                </div>
            </div>

            <a ng-click="addRecipientObject()">
                + Add
                {{ data.definition.templateIdExpression.evaluatedExpression === '' ? 'Recipient' : 'Template Role' }}
            </a>
            <div ng-if="data.validationObject.recipientsDataError" class="common-color-danger">
                {{ data.validationObject.recipientsDataError }}
            </div>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'LOCK_ENVELOPE'" class="margin-bottom-xlg">
        <div class="margin-bottom">
            <!-- Lock Duration In Seconds -->
            <div class="margin-right margin-top-xs">Lock Duration In Seconds:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Lock Duration"
                saved-expression="data.definition.lockDurationInSecondsExpression"
                on-tonkean-expression-changed="
                    onLockDurationInSecondsTonkeanExpressionChanged(expression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div
                ng-if="data.validationObject.emailSubjectError"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.lockDurationInSeconds }}
            </div>
        </div>
    </div>

    <div
            ng-if="
            data.definition.customActionKey === 'CREATE_ENVELOPE_WITH_TABS' ||
            data.definition.customActionKey === 'SEND_ENVELOPE_BACKEND'
        "
            class="margin-bottom-xlg"
    >
        <div class="margin-bottom">
            <!-- Sender User ID -->
            <div class="margin-right margin-top-xs">Sender User ID (Optional):</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Sender User ID"
                    saved-expression="data.definition.senderUserIdExpression"
                    on-tonkean-expression-changed="onSenderUserIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div
        ng-if="
            data.definition.customActionKey === 'UPLOAD_ATTACHMENT' ||
            data.definition.customActionKey === 'ADD_RECIPIENTS_WITH_NAME' ||
            data.definition.customActionKey === 'REMOVE_ENVELOPE_LOCK' ||
            data.definition.customActionKey === 'SEND_ENVELOPE_BACKEND'
        "
        class="margin-bottom-xlg"
    >
        <div class="margin-bottom">
            <!-- Lock Token -->
            <div class="margin-right margin-top-xs">
                Lock Token:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Use this field in case you've locked the envelope."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Lock Token"
                saved-expression="data.definition.lockTokenExpression"
                on-tonkean-expression-changed="onLockTokenTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
