import { useCallback, useEffect, useState } from 'react';

import type { DefaultGetItems, SettingsObject } from './useFetchManager';
import useFetchManager from './useFetchManager';

import type { KeysThatExtend, ResolveValue } from '@tonkean/utils';

/**
 * A decorator for useFetchManager that makes sure only one request is active at a time.
 * In case multiple requests are made while a request is active, only the last one will be actually fired
 */
function usePreventConcurrentManualReloadFetchManager<
    ERROR = any,
    OBJECT extends Record<string, any> = any,
    KEY extends KeysThatExtend<OBJECT> = any,
    IS_SINGLE extends boolean = false,
    ITEM = DefaultGetItems<ResolveValue<ReturnType<OBJECT[KEY]>>>,
>(object: OBJECT, methodKey: KEY, settings: SettingsObject<ResolveValue<ReturnType<OBJECT[KEY]>>, IS_SINGLE, ITEM>) {
    const [fetchFunctions, fetchStateObj] = useFetchManager<ERROR, OBJECT, KEY, IS_SINGLE, ITEM>(
        object,
        methodKey,
        settings,
    );

    const [concurrentFetchRequested, setConcurrentFetchRequested] = useState(false);
    const manuallyReload = useCallback(() => {
        if (!fetchStateObj.loading.any) {
            fetchStateObj.manuallyReload();
            setConcurrentFetchRequested(false);
            return;
        }
        setConcurrentFetchRequested(true);
    }, [fetchStateObj]);

    useEffect(() => {
        if (concurrentFetchRequested && !fetchStateObj.loading.any) {
            fetchStateObj.manuallyReload();
            setConcurrentFetchRequested(false);
        }
    }, [concurrentFetchRequested, fetchStateObj]);

    return [
        fetchFunctions,
        {
            ...fetchStateObj,
            manuallyReload,
        },
    ] as const;
}

export default usePreventConcurrentManualReloadFetchManager;
