<div class="person-selection-configuration">
    <div ng-if="data.validationObject.personSelection" class="margin-bottom-xs common-color-danger">
        {{ data.validationObject.personSelection }}
    </div>

    <!-- Tonkean option (when requested) -->
    <tnk-radio-button
            ng-if="data.showTonkeanOption"
            radio-id="{{ data.radioIdPrefix }}tonkean-sender-{{ data.workflowVersionId }}"
            class="margin-top-xs"
            radio-name="{{ data.radioIdPrefix }}person-selection-sender-configuration-option-{{ data.workflowVersionId }}"
            radio-label="Tonkean"
            radio-value="{{ personContainerOptions.tonkean }}"
            model="data.personSenderConfigurationOption"
            is-required="true"
            on-click="onPersonSenderConfigurationOptionSelected(onClickParam)"
            on-click-param="::personContainerOptions.tonkean"
    ></tnk-radio-button>

    <!-- Person option (when Tonkean option is requested). -->
    <tnk-radio-button
            ng-if="data.showTonkeanOption || data.showServiceAccountOption"
            radio-id="{{ data.radioIdPrefix }}person-sender-{{ data.workflowVersionId }}"
            class="margin-top-xs flex-inline"
            radio-name="{{ data.radioIdPrefix }}person-selection-sender-configuration-{{ data.workflowVersionId }}"
            radio-label="Person"
            radio-value="{{ personContainerOptions.person }}"
            model="data.personSenderConfigurationOption"
            is-required="true"
            on-click="onPersonSenderConfigurationOptionSelected(onClickParam)"
            on-click-param="::personContainerOptions.person"
            view-only="data.onlyTonkeanOption"
    ></tnk-radio-button>

    <i
            ng-if="data.onlyTonkeanOption"
            class="fa fa-info-circle margin-left-xs flex-inline"
            uib-tooltip="Microsoft Teams currently doesn't support messaging by person"
    ></i>

    <div
            ng-show="!data.showTonkeanOption || data.personSenderConfigurationOption === 'person'"
            ng-class="{ 'padding-left-md': data.showTonkeanOption || data.showServiceAccountOption }"
    >
        <!-- Track's owner -->
        <tnk-radio-button
                ng-if="!data.noTracksOwnerOption"
                radio-id="{{ data.radioIdPrefix }}initiative-owner-{{ data.workflowVersionId }}"
                class="margin-top-xs"
                data-automation="person-selection-configuration-add-flow-item-owner"
                radio-name="{{ data.radioIdPrefix }}person-selection-configuration-option-{{ data.workflowVersionId }}"
                radio-value="initiativeOwner"
                model="data.personSelectionConfigurationOption"
                is-required="true"
                on-click="onPersonSelectionConfigurationOptionSelected(onClickParam)"
                on-click-param="('initiativeOwner')"
                automation-identifier="person-selector-flow-items-owner"
        >
            Flow item's owner
            <i
                    class="fa fa-info-circle margin-left-xs"
                    data-automation="person-selection-configuration-click-flow-item-owner"
                    uib-tooltip="The owner of the item the flow is working on. Make sure the Owner field is being set prior to this action."
            ></i>
        </tnk-radio-button>

        <!-- Actor of previous logic -->
        <tnk-radio-button
                ng-if="!data.noPreviousActorOption"
                radio-id="{{ data.radioIdPrefix }}previous-actor-{{ data.workflowVersionId }}"
                class="margin-top-xs"
                radio-name="{{ data.radioIdPrefix }}person-selection-configuration-option-{{ data.workflowVersionId }}"
                radio-label="{{ 'Actor of previous action' }}"
                radio-value="previousActor"
                model="data.personSelectionConfigurationOption"
                is-required="true"
                on-click="onPersonSelectionConfigurationOptionSelected(onClickParam)"
                on-click-param="('previousActor')"
                automation-identifier="person-selector-previous-actor"
        ></tnk-radio-button>

        <!-- Specific people -->
        <tnk-radio-button
                radio-id="{{ data.radioIdPrefix }}specific-people-{{ data.workflowVersionId }}"
                class="margin-top-xs"
                radio-name="{{ data.radioIdPrefix }}person-selection-configuration-option-{{ data.workflowVersionId }}"
                radio-label="Specific {{ data.specificPeopleCustomOptions || data.maxPeople === 1 ? 'person' : 'people' }}"
                radio-value="specificPeople"
                model="data.personSelectionConfigurationOption"
                is-required="true"
                on-click="onPersonSelectionConfigurationOptionSelected(onClickParam)"
                on-click-param="('specificPeople')"
                automation-identifier="person-selector-specific-people"
        ></tnk-radio-button>

        <!-- Specific people Selected-->
        <div
                ng-if="data.personSelectionConfigurationOption === data.personSelectionConfigurationOptions.specificPeople"
                class="margin-top-xs margin-bottom-xs margin-left-md"
        >
            <!-- No specific options - use people selector. -->
            <div ng-if="!data.specificPeopleCustomOptions">
                <tnk-cached-people-selector
                        max-people="data.maxPeople"
                        selected-people-ids="data.selectedPeopleIds"
                        on-person-selected="onPersonSelected(people, paramsObject)"
                        on-person-removed="onPersonRemoved(people, paramsObject)"
                ></tnk-cached-people-selector>
            </div>

            <!-- Specific option supplied - use only them. -->
            <div ng-if="data.specificPeopleCustomOptions" uib-dropdown>
                <ui-select theme="bootstrap" ng-model="data.selectedPerson" on-select="onCustomPersonSelected($item)">
                    <ui-select-match
                            placeholder="Select a person"
                            data-automation="tnk-person-selection-configuration-template-select-persons"
                    >
                        {{ $select.selected.title }}
                        <i
                                class="svg-icon-vmiddle svg-icon-hover-dark-grey common-color-grey common-select-x"
                                ng-click="$select.clear($event)"
                        >
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </i>
                    </ui-select-match>
                    <ui-select-choices
                            repeat="person in data.specificPeopleCustomOptions | filter:$select.search"
                            refresh="refreshSpecificPeopleCustomOptions($select.search)"
                            refresh-delay="0"
                            spinner-enabled="true"
                    >
                        <div
                                data-automation="tnk-person-selection-configuration-template-select-person"
                                ng-bind-html="person.title | highlight: $select.search"
                        ></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <!-- From Field -->
        <tnk-radio-button
                radio-id="{{ data.radioIdPrefix }}from-field-{{ data.workflowVersionId }}"
                class="margin-top-xs"
                radio-name="{{ data.radioIdPrefix }}person-selection-configuration-option-{{ data.workflowVersionId }}"
                radio-value="fromField"
                model="data.personSelectionConfigurationOption"
                is-required="true"
                on-click="onPersonSelectionConfigurationOptionSelected(onClickParam)"
                on-click-param="('fromField')"
                automation-identifier="person-selection-configuration-email-from-field"
        >
            <span>Email from field</span>
            <span
                    ng-if="data.showFromFieldTooltip"
                    class="fa fa-info-circle margin-left-xs"
                    uib-tooltip="{{ data.fromFieldTooltip }}"
            ></span>
        </tnk-radio-button>

        <!-- From Field Selected -->
        <div
                ng-if="data.personSelectionConfigurationOption === data.personSelectionConfigurationOptions.fromField"
                class="margin-top-xs margin-bottom-xs margin-left-md"
        >
            <!-- Field to filter on -->
            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    expression-unique-identifier="personSelectionFromField-{{ data.uniqueIdentifier }}"
                    saved-original-expression="data.selectedField.originalExpression"
                    saved-evaluated-expression="data.selectedField.evaluatedExpression"
                    placeholder="Enter field or expression..."
                    on-tonkean-expression-changed="
                    onFieldOptionSelected(
                        originalExpression,
                        evaluatedExpression,
                        expressionIdentifier,
                        shouldSaveLogic
                    )
                "
                    automation-identifier-expression-value="email-from-field-expression-value"
                    automation-identifier-expression-add-field="email-from-field-expression-add-field"
                    preview-evaluation-source="data.previewEvaluationSource"
            ></tnk-tonkean-expression>
        </div>

        <!-- Custom -->
        <tnk-radio-button
                ng-if="data.allowCustom"
                radio-id="{{ data.radioIdPrefix }}custom-{{ data.workflowVersionId }}"
                class="margin-top-xs"
                radio-name="{{ data.radioIdPrefix }}person-selection-configuration-option-{{ data.workflowVersionId }}"
                radio-label="Custom"
                radio-value="custom"
                model="data.personSelectionConfigurationOption"
                is-required="true"
                on-click="onPersonSelectionConfigurationOptionSelected(onClickParam)"
                on-click-param="('custom')"
        ></tnk-radio-button>

        <!-- Specific people Selected-->
        <div
                ng-if="data.personSelectionConfigurationOption === data.personSelectionConfigurationOptions.custom"
                class="margin-top-xs margin-bottom-xs margin-left-md"
        >
            <input class="form-control" ng-model="data.customInput" ng-blur="onCustomInputChanged()"/>
        </div>

        <!-- Select none -->
        <tnk-radio-button
                radio-id="{{ data.radioIdPrefix }}none-{{ data.workflowVersionId }}"
                class="margin-top-xs"
                radio-name="{{ data.radioIdPrefix }}person-selection-configuration-option-{{ data.workflowVersionId }}"
                radio-label="{{ data.noneOptionLabel || 'None' }}"
                radio-value="none"
                model="data.personSelectionConfigurationOption"
                is-required="true"
                ng-if="data.allowNone"
                on-click="onPersonSelectionConfigurationOptionSelected(onClickParam)"
                on-click-param="('none')"
        ></tnk-radio-button>

        <!-- Tonkean -->
        <tnk-radio-button
                ng-if="data.enableChoosingTonkean"
                radio-id="{{ data.radioIdPrefix }}none-{{ data.workflowVersionId }}"
                class="margin-top-xs"
                radio-name="Tonkean"
                radio-label="Tonkean"
                radio-value="isTonkeanRootUser"
                model="data.personSelectionConfigurationOption"
                is-required="true"
                on-click="onPersonSelectionConfigurationOptionSelected(onClickParam)"
                on-click-param="('isTonkeanRootUser')"
        ></tnk-radio-button>
    </div>

    <!-- Person option (when Tonkean option is requested). -->
    <tnk-radio-button
            ng-if="data.showServiceAccountOption"
            radio-id="{{ data.radioIdPrefix }}person-sender-{{ data.workflowVersionId }}"
            class="margin-top-xs"
            radio-name="{{ data.radioIdPrefix }}person-selection-sender-configuration-{{ data.workflowVersionId }}"
            radio-label="Service Account"
            radio-value="{{ personContainerOptions.serviceAccount }}"
            model="data.personSenderConfigurationOption"
            is-required="true"
            on-click="onPersonSenderConfigurationOptionSelected(onClickParam)"
            on-click-param="::personContainerOptions.serviceAccount"
            view-only="data.onlyTonkeanOption"
    ></tnk-radio-button>

    <div
            ng-if="data.personSenderConfigurationOption === 'serviceAccount'"
            class="margin-top-xs margin-bottom-xs margin-left-md"
    >
        <!-- Field to filter on -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                expression-unique-identifier="serviceAccountFromField-{{ data.uniqueIdentifier }}"
                saved-original-expression="data.serviceAccountSelectedField.originalExpression"
                saved-evaluated-expression="data.serviceAccountSelectedField.evaluatedExpression"
                placeholder="Enter field or expression..."
                on-tonkean-expression-changed="
                    onServiceAccountSelected(
                        originalExpression,
                        evaluatedExpression,
                        expressionIdentifier,
                        shouldSaveLogic
                    )
                "
                automation-identifier-expression-value="service-account-from-field-expression-value"
                automation-identifier-expression-add-field="service-account-from-field-expression-add-field"
                preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>

    <div
            ng-if="
            data.showSendTo &&
            $root.features[pm.project.id].tonkean_feature_allow_ask_a_question_external_emails &&
            data.personSelectionConfigurationOption === 'fromField'
        "
            class="flex flex-vmiddle common-normal-weight"
    >
        <span class="flex-no-shrink">Send to</span>

        <!-- Email -->
        <div class="margin-top-xs margin-left-xs">
            <tnk-radio-button
                    radio-id="send-to-internal-{{ data.workflowVersionId }}"
                    radio-name="send-to-internal-{{ data.workflowVersionId }}"
                    radio-value="INTERNAL"
                    model="data.selectedSendTo"
                    on-click="selectSendTo(onClickParam)"
                    on-click-param="('INTERNAL')"
                    is-required="true"
            >
                <div>Internal</div>
            </tnk-radio-button>
        </div>

        <!-- Other (div is to support firefox on windows-->
        <div class="margin-top-xs margin-left-xs flex-no-shrink">
            <tnk-radio-button
                    radio-id="send-to-external-{{ data.workflowVersionId }}"
                    radio-name="send-to-external-{{ data.workflowVersionId }}"
                    radio-value="EXTERNAL"
                    model="data.selectedSendTo"
                    on-click="selectSendTo(onClickParam)"
                    on-click-param="('EXTERNAL')"
                    is-required="false"
            >
                <div>External</div>
            </tnk-radio-button>
        </div>
    </div>

    <!-- Email provider -->
    <div
            ng-if="
            data.showSendTo &&
            $root.features[pm.project.id].tonkean_feature_allow_ask_a_question_external_emails &&
            data.selectedSendTo === 'EXTERNAL' &&
            data.personSelectionConfigurationOption === 'fromField'
        "
    >
        <!-- Provider title -->
        <div class="common-underline">Provider:</div>

        <!-- Choosing email provider -->
        <div class="margin-top-xs margin-bottom">
            <tnk-project-integration-selector
                    group-id="{{ data.groupId }}"
                    selected-project-integration-id="{{ data.selectedEmailProviderProjectIntegrationId }}"
                    allow-default-option="true"
                    default-option-label="Tonkean Email Provider"
                    filter-on-integration-type="'SENDGRID'"
                    on-project-integration-selected="onEmailProviderProjectIntegrationSelected(selectedProjectIntegration)"
            ></tnk-project-integration-selector>
        </div>
    </div>

    <!-- Check box for fail if no value. -->
    <div ng-if="!data.hideFailIfNoValue" class="margin-top-xs">
        <tnk-checkbox
                class="margin-right-xs"
                value="data.failIfNoValue"
                label="'Fail if no value'"
                on-toggle="onFailIfNoValueChanged(value)"
        ></tnk-checkbox>

        <div
                class="common-size-xxxxs common-color-grey"
                ng-if="
                !data.failIfNoValue &&
                pm.project.projectIntegrationIdDict[data.configuredLogic.node.projectIntegrationIds[0]].creator.email
            "
        >
            (Current default:
            {{ pm.project.projectIntegrationIdDict[data.configuredLogic.node.projectIntegrationIds[0]].creator.email }})
        </div>
    </div>

    <div ng-if="data.onBehalfOfCustomPerson" class="margin-top margin-bottom">
        <span class="common-title-xxs">Send on behalf of: </span>
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                expression-unique-identifier="onBehalfOfExpression-{{ data.uniqueIdentifier }}"
                saved-original-expression="data.onBehalfOfExpressionDefinition.originalExpression"
                saved-evaluated-expression="data.onBehalfOfExpressionDefinition.evaluatedExpression"
                placeholder="Enter field or expression..."
                on-tonkean-expression-changed="
                    onOnBehalfOfExpressionChanged(
                        originalExpression,
                        evaluatedExpression,
                        expressionIdentifier,
                        shouldSaveLogic
                    )
                "
                automation-identifier-expression-value="on-behalf-of-expression-value"
                preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>

    <!-- Reset selection. -->
    <div class="cursor-pointer margin-top-xs" ng-if="data.enableClearSelection" ng-click="clearSelection()">
        <a>Reset</a>
    </div>

    <div ng-if="data.communicationProjectIntegrationSelector" class="margin-bottom-md">
        Communication Source:
        <tnk-communication-project-integration-selector
                selected-communication-project-integration="data.selectedCommunicationProjectIntegration"
                on-communication-project-integration-changed="
                onCommunicationIntegrationChanged(selectedCommunicationProjectIntegration)
            "
                force-email="data.selectedCommunicationMode==='email'"
                email-option="true"
                automation-identifier="communication-source-drop-down"
        ></tnk-communication-project-integration-selector>
    </div>

    <div ng-if="data.showSendAsGroupMessage && data.selectedCommunicationMode === 'email'" class="common-normal-weight">
        <tnk-checkbox
                label="'Send as group message'"
                value="data.sendAsGroupMessage"
                is-reversed="true"
                on-toggle-async="onSendAsGroupMessageChanged(value)"
        ></tnk-checkbox>
    </div>
</div>
