import React from 'react';

import { LoadingCircle, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import type { Color } from '@tonkean/tui-theme';
import type { ErrorResponseType } from '@tonkean/utils';

export interface SearchWidgetHeaderProps {
    selectedItemsCount: number;
    showOnlySelected: boolean;
    setShowOnlySelected: React.Dispatch<React.SetStateAction<boolean>>;
    selectedItemsLoading: boolean;
    selectedItemsError: ErrorResponseType | undefined;
    themeColor: Color;
}

const SearchWidgetHeader: React.FC<SearchWidgetHeaderProps> = ({
    selectedItemsCount,
    showOnlySelected,
    setShowOnlySelected,
    selectedItemsLoading,
    selectedItemsError,
    themeColor,
}) => {
    return (
        <>
            {selectedItemsLoading && <LoadingCircle color={themeColor} thin />}
            {selectedItemsError && <SimpleErrorStateMessage error={selectedItemsError} showSmallError />}
            {selectedItemsCount > 0 && (
                <ClickableLink
                    data-automation="search-widget-header-selected-view-toggle"
                    onClick={() => setShowOnlySelected((value) => !value)}
                >
                    {showOnlySelected ? 'Clear filter' : `Filter Selected items (${selectedItemsCount})`}
                </ClickableLink>
            )}
        </>
    );
};

export default SearchWidgetHeader;
