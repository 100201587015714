import { Form, Formik } from 'formik';
import type { FormikHelpers as FormikHelpersType } from 'formik/dist/types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import type QuestionWidgetConfiguration from './QuestionWidgetConfiguration';
import WidgetRequiredIndication from '../../components/WidgetRequiredIndication';
import { ItemInterfacePermission } from '../../entities';
import InterfaceSubmitValidationContext from '../../utils/InterfaceSubmitValidationContext';
import { ItemWidget, ItemWidgetHeaderTitle } from '../../WidgetModule';
import type { SingleFieldChanged, WidgetFieldPackage } from '../CommonWidgetConfiguration';
import { FieldsWidgetDisplayType, FieldsWidgetLoadingSkeleton, SingleField } from '../FieldsWidgetModule';
import useWidgetsSharedValidationLogic from '../hooks/useWidgetsValidationLogic';

import { CollaborationActionsPluginMenu } from '@tonkean/collaboration';
import { FieldError, FormikHelpers, H3, SavingIndicator, TextEllipsis } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import {
    Validation,
    type FieldDefinitionSummary,
    type TonkeanId,
    type TonkeanType,
    type ValidationResult,
    type Widget,
} from '@tonkean/tonkean-entities';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import { Theme } from '@tonkean/tui-theme';
import { testRegex, ValidationTypeToErrorMessage } from '@tonkean/utils';

const EmptyState = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;

    svg > path {
        fill: #c4c4c4;
    }

    ${H3} {
        color: ${Theme.colors.gray_700};
    }
`;

const FormWrapper = styled(Form)`
    display: contents;
`;

const QuestionWidgetHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledFieldError = styled(FieldError)`
    font-weight: 500;
`;

const validate = (
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
    value: any,
    isMultiValueField: boolean | undefined,
    configuration: QuestionWidgetConfiguration,
    setValidationKey: (key: string, value: ValidationResult[]) => void,
    inputMultiValueSeparator: string | undefined,
) => {
    const validationResultsArray: ValidationResult[] = [];

    if (configuration.required) {
        const sanitizedValue = typeof value === 'string' ? value.trim() : value;
        validationResultsArray.push({
            validation: !!sanitizedValue ? Validation.VALID : Validation.INVALID,
            errorMessage: configuration.requiredMessage,
        });
    }

    if (configuration.regexValidationType && typeof value === 'string') {
        const isValid = testRegex(configuration.regexValidationType, value.toString(), configuration.customRegex);
        validationResultsArray.push({
            validation: isValid ? Validation.VALID : Validation.INVALID,
            errorMessage:
                configuration?.customRegexErrorMessage ||
                ValidationTypeToErrorMessage[configuration.regexValidationType],
        });
    }

    const shouldValidateMinOptionsSelectedInList =
        isMultiValueField && configuration.listConfiguration?.displayAsList && typeof value === 'string';
    if (shouldValidateMinOptionsSelectedInList) {
        const numSelectedOptions = value.split(inputMultiValueSeparator || ',').length;
        const isValid =
            !configuration.listConfiguration?.minimumToSelect ||
            numSelectedOptions >= configuration.listConfiguration?.minimumToSelect;

        validationResultsArray.push({
            validation: isValid ? Validation.VALID : Validation.INVALID,
            errorMessage: `At least ${configuration.listConfiguration?.minimumToSelect} items should be selected`,
        });
    }

    setValidationKey(widgetId, validationResultsArray);
};

export const QUESTION_WIDGET_REQUIRED_FIELD_DEFAULT_MESSAGE = 'Field is required';

interface Props {
    onChange(changes: SingleFieldChanged[]): Promise<void>;

    field: WidgetFieldPackage | undefined;
    showEmptyFieldWhenNoInitiative: boolean;
    loading: boolean;
    widget: Widget<QuestionWidgetConfiguration>;
    permission: ItemInterfacePermission;
}

const QuestionWidget: React.FC<Props> = ({
    field,
    loading,
    showEmptyFieldWhenNoInitiative,
    widget,
    onChange,
    permission,
}) => {
    const [savingIndicatorLoading, setSavingIndicatorLoading] = useState<boolean>(false);
    const [savingIndicatorError, setSavingIndicatorError] = useState<unknown>(undefined);

    const { touchedWidgetsIds, setTouchedWidgetId, setValidationKey, forceShowValidation, validationResult } =
        useContext(InterfaceSubmitValidationContext);

    const {
        initiative,
        itemInterface,
        selectedPluggableActionState,
        pluggableActionHoverState,
        pluggableActionsToShowSettings,
    } = useItemInterfaceContext();

    const [, setSelectedPluggableAction] = selectedPluggableActionState ?? [];
    const [, setPluggableActionHover] = pluggableActionHoverState ?? [];

    const fieldDefinitionSummary: FieldDefinitionSummary | undefined = useMemo(() => {
        if (!field) return undefined;
        return {
            fieldDefinitionId: field?.fieldDefinition.id,
            name: widget.displayName,
            index: 0,
        };
    }, [field, widget.displayName]);

    const defaultTaggableEntity = useMemo(() => {
        if (fieldDefinitionSummary) {
            {
                return {
                    fieldDefinitionId: fieldDefinitionSummary.fieldDefinitionId,
                    fieldName: fieldDefinitionSummary.name,
                    fieldValue: fieldDefinitionSummary.value ?? '',
                    label: fieldDefinitionSummary.label,
                };
            }
        }
    }, [fieldDefinitionSummary]);

    type FormikValueType = Record<TonkeanId<TonkeanType.FIELD_DEFINITION>, SingleFieldChanged>;

    const onSubmit = useConstantRefCallback((values: FormikValueType, helpers: FormikHelpersType<FormikValueType>) => {
        return onChange(Object.values(values)).then(() => {
            // Reset the form so next submit will only submit the newly touched fields
            helpers.resetForm({});
        });
    });

    const canUserEditItem = [
        ItemInterfacePermission.USER_CAN_EDIT_ITEM,
        ItemInterfacePermission.USER_CAN_EDIT_EXISTING_ITEM,
    ].includes(permission);

    const onValueSet = useCallback(
        (value) => {
            validate(
                widget.id,
                value,
                field?.fieldDefinition?.isMultiValueField,
                widget.configuration,
                setValidationKey,
                field?.fieldDefinition.inputMultiValueSeparator,
            );
        },
        [setValidationKey, widget.configuration, widget.id, field?.fieldDefinition],
    );

    const { hasValidationError, markAsTouched, optionalFailedValidationResult } =
        useWidgetsSharedValidationLogic(widget);

    if (loading) {
        return <FieldsWidgetLoadingSkeleton fieldDisplay={FieldsWidgetDisplayType.LIST} singleField />;
    }

    return (
        <Formik initialValues={{} as FormikValueType} onSubmit={onSubmit}>
            <FormikHelpers>
                <FormWrapper>
                    <ItemWidget
                        permission={permission}
                        headerSavingIndicator={
                            <SavingIndicator loading={savingIndicatorLoading} error={savingIndicatorError} />
                        }
                        headerTitle={
                            <QuestionWidgetHeader>
                                <ItemWidgetHeaderTitle>
                                    {widget.configuration?.wrapDisplayName ? (
                                        widget.displayName
                                    ) : (
                                        <TextEllipsis numberOfLines={1}>{widget.displayName}</TextEllipsis>
                                    )}
                                </ItemWidgetHeaderTitle>
                                {widget.configuration.required && (
                                    <WidgetRequiredIndication type={widget.configuration.requiredIndicatorType} />
                                )}
                            </QuestionWidgetHeader>
                        }
                        pluggableActions={
                            <CollaborationActionsPluginMenu
                                primaryColor={itemInterface.themeConfiguration.primaryColor}
                                setSelectedPluggableAction={setSelectedPluggableAction}
                                setPluggableActionHover={setPluggableActionHover}
                                defaultFieldEntity={defaultTaggableEntity}
                                pluggableActionsToShowSettings={pluggableActionsToShowSettings}
                            />
                        }
                        showIcon={widget.configuration.icon !== undefined}
                        noBackgroundBody={!!widget.configuration.selectedField}
                        itemWidgetBodyMinHeight={40}
                        disableMinHeight
                        disableMaxHeight
                        noPaddingBody
                        noBorderBody
                        showAsQuestionWidget
                    >
                        {widget.configuration.selectedField ? (
                            <>
                                <SingleField
                                    key={widget.configuration.selectedField.key}
                                    projectId={widget.projectId}
                                    onChange={(newValue) => {
                                        // On change is triggered on blur as well so we mark it as touched here.
                                        markAsTouched();

                                        if (field) {
                                            validate(
                                                widget.id,
                                                newValue,
                                                field?.fieldDefinition?.isMultiValueField,
                                                widget.configuration,
                                                setValidationKey,
                                                field?.fieldDefinition.inputMultiValueSeparator,
                                            );
                                            return onChange([{ package: field, newValue }]);
                                        }
                                        throw new Error('Unable to update field without filed definition');
                                    }}
                                    onValueSet={onValueSet}
                                    field={field}
                                    fieldDefinitionId={widget.configuration.selectedField.key}
                                    showEmptyFieldWhenNoInitiative={showEmptyFieldWhenNoInitiative}
                                    hideColorsAndTrends={widget.configuration.selectedField.hideColorsAndTrends}
                                    submitAsForm={false}
                                    fullWidth={widget.configuration.selectedField?.fullWidth}
                                    onSave={setSavingIndicatorLoading}
                                    onSavingError={setSavingIndicatorError}
                                    placeholderText={widget.configuration.placeholderText}
                                    hasError={hasValidationError}
                                    canUserEditItem={canUserEditItem}
                                    updateListFieldProps={{
                                        displayAsList: widget.configuration.listConfiguration?.displayAsList ?? false,
                                        minimumToSelect: widget.configuration.listConfiguration?.minimumToSelect,
                                        maximumToSelect: widget.configuration.listConfiguration?.maximumToSelect,
                                    }}
                                    isDisabled={widget.configuration.isDisabled}
                                    editingEnabledInWidget
                                    showAsQuestionWidget
                                />
                                {hasValidationError && (
                                    <StyledFieldError
                                        $inlineError={false}
                                        $textAlign="right"
                                        data-automation="question-widget-styled-field-error"
                                    >
                                        {optionalFailedValidationResult?.errorMessage ||
                                            QUESTION_WIDGET_REQUIRED_FIELD_DEFAULT_MESSAGE}
                                    </StyledFieldError>
                                )}
                            </>
                        ) : (
                            <EmptyState data-automation="fields-widget-empty-state">
                                <H3 $bold>No field selected</H3>
                            </EmptyState>
                        )}
                    </ItemWidget>
                </FormWrapper>
            </FormikHelpers>
        </Formik>
    );
};

export default React.memo(QuestionWidget);
