import styled from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';

const SmartConversationResponseText = styled.div`
    color: #24064b;
    font-weight: 300;
    font-size: ${FontSize.XLARGE_18};
    line-height: 30px;

    // This is so that new-line characters would actually enter a new line
    white-space: pre-line;
`;
export default SmartConversationResponseText;
