import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import WorkerAuditLogGroupTooltipContent from './WorkerAuditLogGroupTooltipContent';
import WorkerAuditLogItem from './WorkerAuditLogItem';

import { LogicIcon } from '@tonkean/infrastructure';
import { Accordion, LIMIT_PARAM, SKIP_PARAM, TextEllipsis, Tooltip, useFetchManager } from '@tonkean/infrastructure';
import type { ActivitiesResponse } from '@tonkean/tonkean-entities';
import type { Person } from '@tonkean/tonkean-entities';
import type { ActivitiesGroup } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { ActivitiesEntityType, ActivitiesGroupEntityStatus } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { TooltipColor } from '@tonkean/tui-theme/colors';
import { AccordionSize } from '@tonkean/tui-theme/sizes';
import { TooltipSize } from '@tonkean/tui-theme/sizes';
import { classNames } from '@tonkean/utils';

const Title = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.SMALL_12};
    display: flex;
    align-items: center;
    flex-grow: 1;
`;

const activityGroupStatusToBackgroundColor: Record<ActivitiesGroupEntityStatus, string> = {
    [ActivitiesGroupEntityStatus.CREATED]: Theme.colors.primary,
    [ActivitiesGroupEntityStatus.DELETED]: Theme.colors.warning,
    [ActivitiesGroupEntityStatus.UPDATED]: Theme.colors.success,
    [ActivitiesGroupEntityStatus.REVERTED]: Theme.colors.primary,
};

const StatusIndicator = styled.div<{ status: ActivitiesGroupEntityStatus }>`
    position: absolute;
    width: 2px;
    left: 0;
    top: 3px;
    bottom: 3px;
    border-radius: 2px;
    background: ${({ status }) => activityGroupStatusToBackgroundColor[status]};
`;

const StyledLogicIcon = styled(LogicIcon)`
    margin-right: 8px;
`;

const TitleText = styled.strong`
    line-height: 16px;
`;

const Status = styled.span`
    color: ${Theme.colors.gray_500};
    font-weight: normal;
    margin-right: 5px;
`;

const CompareButton = styled(StateLink)`
    color: ${Theme.colors.primary};
`;

interface VersionHistoryFilter {
    activityType?: any;
    actor?: Person;
    fromDate?: Date;
    toDate?: Date;
}

interface Props {
    user: Person;
    activitiesGroup: ActivitiesGroup;
    entityId: string;
    groupId: string;
    pageSize: number;
    filters: VersionHistoryFilter;
    workflowVersionId: string;
    onCloseModal?: () => void;
    loadingIndicator: React.ReactNode;
    showBackToMarkForPublishButton?: () => void;
}

const WorkerAuditLogGroupItem: React.FC<Props> = ({
    user,
    activitiesGroup,
    entityId,
    groupId,
    pageSize,
    filters,
    workflowVersionId,
    onCloseModal,
    loadingIndicator,
    showBackToMarkForPublishButton,
}) => {
    const tonkeanService = useAngularService('tonkeanService');
    const modalUtils = useAngularService('modalUtils');
    const tonkeanUtils = useAngularService('tonkeanUtils');
    const consts = useAngularService('consts');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const customTriggerManager = useAngularService('customTriggerManager');

    const [[getGroupActivity, cancelFetcher], { data: activities, loadNextPage, hasMorePages, loading }] =
        useFetchManager(tonkeanService, 'getGroupActivity', {
            getItems: (activities: ActivitiesResponse) => activities.activity,
            limit: pageSize,
        });

    const [expanded, setExpanded] = useState(false);
    const [shouldLoadActivities, setShouldLoadActivities] = useState(expanded);

    useEffect(() => {
        if (!shouldLoadActivities) {
            return;
        }

        // In case there are both filtered activity types and default group types and they are not matched.
        // we don't need to show the activities, so return without requesting from server.
        if (
            activitiesGroup.activityTypesForGroupEntity &&
            filters.activityType?.activityType &&
            !activitiesGroup.activityTypesForGroupEntity.includes(filters.activityType.activityType)
        ) {
            return;
        }

        // because there might be some groups related to the same GROUP entity we want to filter by activityType
        const defaultsActivityTypes = activitiesGroup.activityTypesForGroupEntity || [];
        const activityTypes = filters.activityType?.activityType
            ? [filters.activityType.activityType]
            : defaultsActivityTypes;

        getGroupActivity(
            groupId,
            LIMIT_PARAM,
            activityTypes,
            null,
            SKIP_PARAM,
            false,
            filters.actor ? [filters.actor] : [],
            filters.fromDate?.getTime(),
            filters.toDate?.getTime(),
            false,
            workflowVersionId,
            entityId,
            null,
        );

        return cancelFetcher;
    }, [
        activitiesGroup.activityTypesForGroupEntity,
        cancelFetcher,
        entityId,
        filters.activityType?.activityType,
        filters.actor,
        filters.fromDate,
        filters.toDate,
        getGroupActivity,
        groupId,
        shouldLoadActivities,
        workflowVersionId,
    ]);

    /**
     * we want to load the activities only once, when it is first expanded
     */
    useEffect(() => {
        if (expanded) {
            setShouldLoadActivities(true);
        }
    }, [expanded]);

    const blockTypes = useMemo(() => {
        // get the logic of the group
        const logicBlockTypes = consts.getLogicBlockTypes();
        const logicBlockType =
            // Match condition is an edge case between the autonomous triggers because its key in the logicBlockTypes array is AUTONOMOUS
            activitiesGroup.entity?.customTriggerType &&
            logicBlockTypes[
                activitiesGroup.entity.customTriggerType === 'AUTONOMOUS' &&
                activitiesGroup.entity.customTriggerSecondaryType != 'AUTONOMOUS_MATCH_CONDITIONS'
                    ? activitiesGroup.entity.customTriggerSecondaryType
                    : activitiesGroup.entity.customTriggerType
            ];

        // get the parent of the group's logic
        if (activitiesGroup.entityType === 'CUSTOM_TRIGGER') {
            const logic = customTriggerManager.findLogicDataInGraphById(workflowVersionId, entityId);
            const parent = logic?.parent.node;

            if (parent?.customTriggerType === 'PERFORM_INTEGRATION_ACTION') {
                parent.title = parent.displayName;
                const parentBlockType = parent;
                return { logicBlockType, parentBlockType };
            }
            // Match condition is an edge case between the autonomous triggers because its key in the logicBlockTypes array is AUTONOMOUS
            const parentBlockType =
                parent?.customTriggerSecondaryType &&
                parent.customTriggerSecondaryType != 'UNKNOWN' &&
                parent.customTriggerSecondaryType != 'AUTONOMOUS_MATCH_CONDITIONS'
                    ? logicBlockTypes[parent.customTriggerSecondaryType]
                    : parent?.customTriggerType && logicBlockTypes[parent.customTriggerType];

            return { logicBlockType, parentBlockType };
        }

        return { logicBlockType };
    }, [
        activitiesGroup.entity?.customTriggerSecondaryType,
        activitiesGroup.entity?.customTriggerType,
        activitiesGroup.entityType,
        consts,
        customTriggerManager,
        entityId,
        workflowVersionId,
    ]);

    const integrationTypeByEntityType = {
        FIELD_DEFINITION: activitiesGroup.entity?.definition?.integrationType,
        CUSTOM_TRIGGER: activitiesGroup.entity?.integrationType,
    };

    const displayNameByType: Record<ActivitiesEntityType, string> = useMemo(() => {
        return {
            FIELD_DEFINITION: activitiesGroup.entity?.name,
            CUSTOM_TRIGGER: activitiesGroup.entity?.displayName,
            FORM: activitiesGroup.entity?.displayName,
            STATE: 'Status',
            GRAPH: 'Graph',
            INPUT_SOURCE: 'Intake Source',
            LIVE_REPORT: 'Business Report',
            AUTO_CHECKINS: 'Auto Check-ins',
            DELETED_CUSTOM_TRIGGER_GROUP: 'Blocks',
            DELETED_FIELD_DEFINITION_GROUP: 'Fields',
            DELETED_FORM_GROUP: 'Forms',
            TEMPLATE: 'Module',
            INPUT_SOURCE_GROUP: 'Intake Source',
            REVERT: `Version ${activitiesGroup.entity?.sequentialIdentifier}${
                activitiesGroup.entity?.subSequentialIdentifier > 0
                    ? `.${activitiesGroup.entity?.subSequentialIdentifier}`
                    : ''
            }`,
            ITEM_INTERFACE: activitiesGroup.entity?.displayName,
            DELETED_ITEM_INTERFACE_GROUP: 'Item Interface',
            MODULE_OVERVIEW: 'Module Overview',
            ITEM_DETAILS: 'Item Details',
            PROCESS_MAPPER: activitiesGroup.entity?.displayName,
            DELETED_PROCESS_MAPPER_GROUP: 'Process Map',
        };
    }, [
        activitiesGroup.entity?.displayName,
        activitiesGroup.entity?.name,
        activitiesGroup.entity?.sequentialIdentifier,
        activitiesGroup.entity?.subSequentialIdentifier,
    ]);

    const displayStatusValue: Record<ActivitiesGroupEntityStatus, string> = {
        [ActivitiesGroupEntityStatus.CREATED]: 'Created',
        [ActivitiesGroupEntityStatus.UPDATED]: 'Updated',
        [ActivitiesGroupEntityStatus.DELETED]: 'Deleted',
        [ActivitiesGroupEntityStatus.REVERTED]: 'Restored back to',
    };

    const deletedDisplayStatusToEntity: Record<ActivitiesEntityType, ActivitiesEntityType> = {
        PROCESS_MAPPER: ActivitiesEntityType.DELETED_PROCESS_MAPPER_GROUP,
        ITEM_INTERFACE: ActivitiesEntityType.DELETED_ITEM_INTERFACE_GROUP,
    } as Record<ActivitiesEntityType, ActivitiesEntityType>;

    const isDeleted = activitiesGroup.status === ActivitiesGroupEntityStatus.DELETED;

    const title = activitiesGroup.entity
        ? displayNameByType[activitiesGroup.activitiesEntityType]
        : isDeleted
          ? displayNameByType[deletedDisplayStatusToEntity[activitiesGroup.entityType]]
          : 'Unknown';
    const groupStatus = displayStatusValue[activitiesGroup.status];

    const showCompare = useMemo(() => {
        const workflowVersion = workflowVersionManager.getCachedWorkflowVersion(workflowVersionId);
        const publishedWorkflowVersion = workflowVersionManager.getPublishedVersionFromCache(groupId);
        const isPublished =
            publishedWorkflowVersion &&
            !!customTriggerManager.getCachedCustomTrigger(publishedWorkflowVersion.id, entityId);

        return (
            workflowVersion?.workflowVersionType === WorkflowVersionType.DRAFT &&
            activitiesGroup?.entityType === 'CUSTOM_TRIGGER' &&
            isPublished &&
            activitiesGroup?.status === ActivitiesGroupEntityStatus.UPDATED
        );
    }, [
        activitiesGroup?.entityType,
        activitiesGroup?.status,
        customTriggerManager,
        entityId,
        groupId,
        workflowVersionId,
        workflowVersionManager,
    ]);

    const handleCompareClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        if (showBackToMarkForPublishButton) {
            showBackToMarkForPublishButton();
        }
        if (onCloseModal) {
            onCloseModal();
        }
    };

    return (
        <Accordion
            open={expanded && (loading.any || activities.length > 0)}
            onChange={setExpanded}
            size={AccordionSize.LARGE}
            title={
                <>
                    <StatusIndicator status={activitiesGroup.status} />
                    <Title>
                        <StyledLogicIcon
                            integrationType={integrationTypeByEntityType[activitiesGroup.entityType]}
                            logicBlockType={blockTypes.logicBlockType}
                            fieldType={activitiesGroup.entity?.fieldType}
                            definitionSource={activitiesGroup.entity?.definitionSource}
                            formType={activitiesGroup.entity?.formType}
                            revert={activitiesGroup.activitiesEntityType === ActivitiesEntityType.REVERT}
                            projectIntegrationId={activitiesGroup.entity?.projectIntegration?.id}
                            itemInterface={activitiesGroup.activitiesEntityType === ActivitiesEntityType.ITEM_INTERFACE}
                            itemDetails={activitiesGroup.activitiesEntityType === ActivitiesEntityType.ITEM_DETAILS}
                            processMapper={activitiesGroup.activitiesEntityType === ActivitiesEntityType.PROCESS_MAPPER}
                        />
                        <Tooltip
                            size={TooltipSize.MEDIUM}
                            disabled={!blockTypes.parentBlockType?.title}
                            color={TooltipColor.WHITE}
                            content={
                                <WorkerAuditLogGroupTooltipContent
                                    parentBlock={blockTypes.parentBlockType}
                                    activitiesGroup={activitiesGroup}
                                    logicBlock={blockTypes.logicBlockType}
                                    groupTitle={blockTypes.parentBlockType?.title && title}
                                />
                            }
                        >
                            <TitleText data-automation="worker-audit-log-group-item-history-list">
                                <TextEllipsis tooltip={!blockTypes.parentBlockType?.title} tooltipLimitWidth={10}>
                                    <Status>{groupStatus}</Status>
                                    {title} ({activitiesGroup.count})
                                </TextEllipsis>
                            </TitleText>
                        </Tooltip>
                        <div className="flex-grow" />
                        {showCompare && (
                            <CompareButton
                                state="."
                                params={{
                                    lcm: 'configuration',
                                    spt: 'logic',
                                    l: entityId,
                                    openCompare: 'true',
                                }}
                                onClick={handleCompareClicked}
                            >
                                compare
                            </CompareButton>
                        )}
                    </Title>
                </>
            }
        >
            {activities?.map(
                (activity) =>
                    activity && (
                        <WorkerAuditLogItem
                            key={activity.id}
                            activity={activity}
                            currentUser={user}
                            tonkeanUtils={tonkeanUtils}
                            modalUtils={modalUtils}
                            consts={consts}
                            icon={
                                <LogicIcon
                                    integrationType={integrationTypeByEntityType[activitiesGroup.entityType]}
                                    logicBlockType={blockTypes.logicBlockType}
                                    fieldType={activitiesGroup.entity?.fieldType}
                                    definitionSource={activitiesGroup.entity?.definitionSource}
                                    formType={activitiesGroup.entity?.formType}
                                    revert={activitiesGroup.activitiesEntityType === ActivitiesEntityType.REVERT}
                                    projectIntegrationId={activitiesGroup.entity?.projectIntegration?.id}
                                    itemInterface={
                                        activitiesGroup.activitiesEntityType === ActivitiesEntityType.ITEM_INTERFACE
                                    }
                                    itemDetails={
                                        activitiesGroup.activitiesEntityType === ActivitiesEntityType.ITEM_DETAILS
                                    }
                                    processMapper={
                                        activitiesGroup.activitiesEntityType === ActivitiesEntityType.PROCESS_MAPPER
                                    }
                                />
                            }
                        />
                    ),
            )}
            {hasMorePages && !loading.any && (
                <button
                    className={classNames('btn-primary', 'block btn btn-sm margin-auto margin-top margin-bottom')}
                    type="button"
                    onClick={loadNextPage}
                >
                    Load more
                </button>
            )}

            {loading.any && loadingIndicator}
        </Accordion>
    );
};

export default WorkerAuditLogGroupItem;
