<div class="integration-group" ng-hide="inlineSetupScreen">
    <div class="clearfix" ng-if="group">
        <div class="pull-left">
            <div class="integration-group-header-title">{{ group.title }}</div>
            <div class="integration-group-header-info" ng-if="group.info">{{ group.info }}</div>
        </div>
    </div>
    <div class="new-product-button-group">
        <div class="new-product-button-group-inner">
            <div
                ng-if="!isCommunication"
                class="new-product-integration-item box-with-shadow mod-full"
                ng-repeat="integration in integrations  | filter:{name:''} track by integration.id"
                ng-init="projectIntegration = state[integration.name].integrations[0]"
            >
                <!-- Integration logo when icon url is NOT available -->
                <div ng-if="!projectIntegration || !projectIntegration.iconUrl">
                    <!-- Integration logo (not inline) -->
                    <div class="integration-icon mod-{{ integration.name }}"></div>
                </div>
                <!-- Integration logo when icon url IS available -->
                <div
                    ng-if="projectIntegration && projectIntegration.iconUrl"
                    class="integration-group-header-connected-logo mod-confirm hidden-xs mod-manual-icon margin-none"
                    uib-tooltip="{{ state[integration.name].integrations[0].displayName || integration.displayName }}"
                >
                    <i
                        class="initiative-integration-icon mod-normal margin-right-xs"
                        ng-style="{ background: 'url(' + projectIntegration.iconUrl + ') no-repeat center' }"
                    ></i>
                    <span>{{ state[integration.name].integrations[0].displayName || integration.displayName }}</span>
                </div>

                <div class="flex-vmiddle flex-grow common-width-auto flex-xs-wrap">
                    <div class="flex-grow">
                        <!-- Integration description (not inline) -->
                        <div>{{ integration.description }}</div>

                        <!-- Integration subtitle (not inline) -->
                        <span class="common-subtitle-inner" ng-swich="integration.name">
                            <span ng-switch-when="github">
                                <span ng-include="'./gitService/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="git">
                                <span ng-include="'./gitService/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="jira">
                                <span ng-include="'./jira/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="sql">
                                <span ng-include="'./mysql/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="trello">
                                <span ng-include="'./trello/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="asana">
                                <span ng-include="'./asana/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="wrike">
                                <span ng-include="'./wrike/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="harvest">
                                <span ng-include="'./harvest/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="google">
                                <span ng-include="'./google/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="wunderlist">
                                <span ng-include="'./wunderlist/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="salesforce">
                                <span ng-include="'./salesforce/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="zendesk">
                                <span ng-include="'./zendesk/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="smartsheet">
                                <span ng-include="'./smartsheet/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="nutshell">
                                <span ng-include="'./nutshell/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="pipedrive">
                                <span ng-include="'./pipedrive/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="monday">
                                <span ng-include="'./monday/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="intercom">
                                <span ng-include="'./intercom/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="twiliochat">
                                <span ng-include="'./twiliochat/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="agilecrm">
                                <span ng-include="'./agileCrm/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="facebookads">
                                <span ng-include="'./facebookads/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="salesforceiq">
                                <span ng-include="'./salesforceiq/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="googlesheets">
                                <span ng-include="'./googlesheets/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="googledrive">
                                <span ng-include="'./googledrive/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="dropbox">
                                <span ng-include="'./dropbox/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="tableau">
                                <span ng-include="'./tableau/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="teamwork">
                                <span ng-include="'./teamwork/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="prosperworks">
                                <span ng-include="'./prosperworks/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="google_calendar">
                                <span ng-include="'./googlecalendar/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="gmail">
                                <span ng-include="'./gmail/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="gitlab">
                                <span ng-include="'./gitlab/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="stripeapp">
                                <span ng-include="'./stripeapp/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="excel365">
                                <span ng-include="'./excel365/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="dynamics365">
                                <span ng-include="'./dynamics365/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="zohocrm">
                                <span ng-include="'./zohocrm/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="basecamp">
                                <span ng-include="'./basecamp/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="kanbanize">
                                <span ng-include="'./kanbanize/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="rss">
                                <span ng-include="'./rss/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="sendgrid">
                                <span ng-include="'./sendgrid/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="freshdesk">
                                <span ng-include="'./freshdesk/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="slack_app">
                                <span ng-include="'./slackApp/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="emailwebhook">
                                <span ng-include="'./emailwebhook/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="webhook">
                                <span ng-include="'./webhook/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="slack">
                                <span ng-include="'./communication/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="microsoft_teams">
                                <span ng-include="'./communication/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="googlechat">
                                <span ng-include="'./communication/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="mavenlink">
                                <span ng-include="'./mavenlink/subtitle.template.html'"></span>
                            </span>
                            <span ng-switch-when="teamconnect">
                                <span ng-include="'./teamconnect/subtitle.template.html'"></span>
                            </span>
                        </span>
                    </div>

                    <div ng-include="'./integrationGroupButtonTemplate.template.html'"></div>
                </div>
            </div>

            <div ng-if="isCommunication" class="communication-integrations">
                <div class="margin-top-md flex-vmiddle">
                    <!-- Integration logo (inline) -->
                    <div>
                        <i
                            class="integration-group-header-connected-logo hidden-xs margin-none mod-{{
                                integrationsConfig[sources[0]].name
                            }}"
                        ></i>
                    </div>
                    <div ng-if="data.removingOrCreatingCommunicationIntegration">
                        <i class="margin-left margin-bottom-xxs loading-small"></i>
                    </div>
                </div>
                <div class="flex flex-wrap">
                    <!-- Add new communication integration tile -->
                    <div
                        ng-class="{ disabled: !pm.isOwner }"
                        tooltip-enable="!pm.isOwner"
                        uib-tooltip="Only board owners can edit communication sources"
                        class="
                            communication-integration-box
                            box-with-shadow
                            margin-right-md margin-bottom-md margin-top-md
                        "
                    >
                        <div class="flex-vmiddle common-color-grey">
                            <div
                                class="initiative-integration-icon mod-17 common-fill-grey-text"
                                ng-class="'mod-' + integrationsConfig[sources[0]].name.toLowerCase()"
                            ></div>
                            <div class="margin-left">{{ integrationsConfig[sources[0]].workspaceName }}</div>
                        </div>
                        <div
                            class="flex flex-justify-space-around margin-top-20"
                            data-automation="integration-group-full-mode-template-template-add-new-slack-communication"
                            ng-click="loginCommunicationIntegration(integrationsConfig[sources[0]], null, true)"
                        >
                            <div
                                tnk-require="{{
                                    $root.pm.projectData.allowIntegrationsInTrial
                                        ? 'free'
                                        : integration && integrationRequireMap[sources[0]]
                                        ? integrationRequireMap[sources[0]]
                                        : defaultLicenseTierToAllow
                                }}"
                                class="btn-round-blue"
                            >
                                New Instance
                            </div>
                        </div>
                    </div>

                    <!-- Gallery of all communication integrations -->
                    <div
                        class="
                            communication-integration-box
                            box-with-shadow
                            margin-right-md margin-bottom-md margin-top-md
                            flex flex-col
                        "
                        ng-class="{
                            'regular-border':
                                pm.project.defaultCommunicationProjectIntegration.id !== projectIntegration.id,
                            'selected-border':
                                pm.project.defaultCommunicationProjectIntegration.id === projectIntegration.id
                        }"
                        ng-repeat="integrationObj in integrations"
                        ng-init="projectIntegration = data.uniqueTypeToProjectIntegration[integrationObj.projectIntegrationUniqueType];
                            integration = integrationObj.integrationDefinition;"
                    >
                        <div
                            class="flex flex-col flex-grow"
                            ng-include="'./communicationIntegrationGroupBoxModeTemplate.template.html'"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
