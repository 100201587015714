import template from './fieldDefinitionConfigurationModal.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkFieldDefinitionConfigurationModal', {
    bindings: {
        groupId: '<',
        targetType: '<',
        fieldDefinitionType: '<',
        projectIntegration: '<',
        openedFromCustomTriggerId: '<',
        secondaryId: '<',
        createMode: '<',
        duplicateMode: '<',
        existingFieldDefinition: '<',
        afterCreateOrUpdateCallback: '<',
        deleteCallback: '<',
        openInStep: '<',
        quickCreateForExternal: '<',
        startWithDataSource: '<',
        selectedEntity: '<',
        isWorkerMode: '<',
        overrideFormulaOperator: '<',
        overrideFieldIsHidden: '<',
        manualValue: '<',
        idOnlyMode: '<',
        workflowVersionId: '<',
        matchedEntityFromWorkflowVersionId: '<',
        allowOnlyFieldDefinitionDataTypes: '<',
        onClose: '<',
        environment: '<',
        onEnvironmentChanged: '<',
        onHasPendingChangesChanged: '<',
        isSystemUtilized: '<',
        isForMatchedItem: '<',
    },
    template,
    controller: 'FieldDefinitionConfigurationCtrl',
});
