import React from 'react';
import type { ValueContainerProps } from 'react-select';
import { components } from 'react-select';
import styled from 'styled-components';

import type { NavigationSelectionGroupType, NavigationSelectionSingleItemType } from './NavigationSelectionTypes';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { IconSvg } from '@tonkean/infrastructure';
import { HistorySearchIcon as SearchIcon } from '@tonkean/svg';
import { SmartSearchIcon as AtomIcon } from '@tonkean/svg';

const PaddedValue = styled.div`
    margin-left: 4px;
    display: grid;
    align-items: center;
`;

const NavigationSearchItemValueContainer: React.FC<
    ValueContainerProps<NavigationSelectionSingleItemType, false, NavigationSelectionGroupType>
> = ({ children, theme: reactSelectTheme, ...props }) => {
    const showSmartSearch = useFeatureFlag('tonkean_feature_smartsearch');
    return (
        // The styles for this is configured in NavigationSearch.tsx so we can overwrite them in other components that use this
        <components.ValueContainer {...props} theme={reactSelectTheme}>
            {showSmartSearch ? <IconSvg as={AtomIcon} color={reactSelectTheme.colors.primary} /> : <SearchIcon />}
            <PaddedValue>{children}</PaddedValue>
        </components.ValueContainer>
    );
};

export default NavigationSearchItemValueContainer;
