import { useField } from 'formik';
import React from 'react';

import type { ProjectIntegrationSelectorProps } from './TnkProjectIntegrationSelector';
import TnkProjectIntegrationSelector from './TnkProjectIntegrationSelector';

interface Props
    extends Omit<ProjectIntegrationSelectorProps, 'selectedProjectIntegrationId' | 'onProjectIntegrationSelected'> {
    name: string;
    onProjectIntegrationSelected?: ProjectIntegrationSelectorProps['onProjectIntegrationSelected'];
}
const FormikTnkProjectIntegrationSelector: React.FC<Props> = ({ name, onProjectIntegrationSelected, ...props }) => {
    const [, { value }, { setValue }] = useField<string | undefined>(name);

    return (
        <TnkProjectIntegrationSelector
            {...props}
            selectedProjectIntegrationId={value}
            onProjectIntegrationSelected={(projectIntegration) => {
                setValue(projectIntegration.id);
                onProjectIntegrationSelected?.(projectIntegration);
            }}
        />
    );
};

export default FormikTnkProjectIntegrationSelector;
