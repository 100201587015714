<div class="add-scheduled-report-modal relative">
    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold mod-no-top">{{ createMode ? 'Create' : 'Edit' }} a scheduled report</h1>

    <hr />

    <form name="addScheduledReportForm" ng-submit="addOrUpdateScheduledReport()">
        <!-- Report type -->
        <div class="row margin-xs-bottom margin-bottom-lg">
            <div class="col-sm-3 common-size-xs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                Report type:
            </div>
            <div class="col-sm-9 btn-group hidden-xs">
                <button
                    type="button"
                    ng-repeat="(reportTypeDisplayName, reportTypeApiName) in data.reportTypeOptions"
                    class="btn btn-default common-no-outline"
                    ng-class="{ 'btn-primary': data.selectedReportType === reportTypeDisplayName }"
                    ng-model="ignore"
                    ng-click="changeReportType(reportTypeDisplayName)"
                >
                    {{ reportTypeDisplayName }}
                </button>
            </div>
            <div class="col-sm-9 visible-xs-block">
                <ui-select
                    ng-model="data.selectedReportTypePair"
                    theme="bootstrap"
                    ng-change="changeReportType(data.selectedReportTypePair.displayName)"
                >
                    <ui-select-match placeholder="Select report type">
                        {{ data.selectedReportTypePair.displayName }}
                    </ui-select-match>
                    <ui-select-choices
                        repeat="(displayName, option) in data.reportTypeOptions | filter:$select.search | orderBy:'label'"
                    >
                        <div ng-bind-html="option.displayName | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <!-- Only show rest of configuration is report type was selected -->
        <div ng-if="data.selectedReportType">
            <div class="row margin-bottom-lg">
                <div class="col-sm-3 common-size-xs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                    Select Fields & Modules:
                </div>
                <div class="col-sm-9">
                    <div class="modules-fields-table">
                        <tnk-picker-list
                            class="fields-picker-list"
                            title="'Module Name'"
                            title-counter="false"
                            items-by-id="data.groupList"
                            on-click-select="(true)"
                            on-select-changed="(selectGroupFromList)"
                            on-check-changed="(checkGroupsFromList)"
                            selected-id="data.selectedGroup.id"
                            show-checkboxes="data.selectedReportType !== 'List'"
                        ></tnk-picker-list>
                        <tnk-picker-list
                            class="fields-picker-list"
                            items-by-id="data.groupList[data.selectedGroup.id].fieldsList"
                            title="'Field Name'"
                            title-counter="false"
                            on-check-changed="(checkFieldsFromList)"
                            loading="!data.groupList[data.selectedGroup.id].fieldsList || data.groupList[data.selectedGroup.id].loadingFieldsList"
                        ></tnk-picker-list>
                    </div>
                </div>
            </div>

            <!-- Type specific selection (according to configuraiton) -->
            <div class="margin-xs-bottom margin-bottom-lg" ng-switch="data.selectedReportTypeFamily">
                <!-- For all the single selection report types (List, Status, Function, Tag, Due/ETA) -->
                <div class="row" ng-switch-when="SingleSelection">
                    <!-- Single entity selection -->
                    <div class="col-sm-3 common-size-xs common-bold padding-right-none margin-bottom-xs margin-top-xs">
                        <span ng-if="data.selectedReportType !== 'List'">
                            {{
                                data.selectedReportTypeConfig.description
                                    ? data.selectedReportTypeConfig.description
                                    : ('Select ' + data.selectedReportTypeConfig.type.toLowerCase() | capitalize)
                            }}:
                        </span>
                    </div>

                    <div class="col-sm-9">
                        <!-- Entity selection -->
                        <ui-select
                            ng-if="
                                data.selectedReportTypeConfig.relevantEntities &&
                                data.selectedReportTypeConfig.relevantEntities.length &&
                                data.selectedReportType !== 'List'
                            "
                            required
                            ng-model="data.selectedReportTypeConfig.selectedEntity"
                            theme="bootstrap"
                            class="group-select common-width-75-no-xs"
                            ng-change="onSelectedEntity(data.selectedReportTypeConfig)"
                        >
                            <ui-select-match
                                placeholder="Select {{
                                    data.selectedReportTypeConfig.description
                                        ? data.selectedReportTypeConfig.description
                                        : (data.selectedReportTypeConfig.type | lowercase)
                                }}"
                            >
                                {{ data.selectedReportTypeConfig.selectedEntity.displayName }}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="p in data.selectedReportTypeConfig.relevantEntities | filter:$select.search | orderBy:'label'"
                            >
                                <div class="flex-vmiddle">
                                    <div ng-bind-html="p.displayName | escapeHtml | highlight: $select.search"></div>

                                    <!-- Private group indication -->
                                    <div
                                        class="margin-left-xs common-size-xxxxxs common-color-grey"
                                        ng-if="data.selectedReportType === 'List' && p.visibilityType === 'PRIVATE'"
                                    >
                                        (Private)
                                    </div>
                                </div>
                            </ui-select-choices>
                        </ui-select>

                        <!-- Include first level of inner tracks -->
                        <label class="margin-top-xs" ng-if="data.selectedReportType === 'List'">
                            <input
                                class="margin-right-xs"
                                type="checkbox"
                                ng-model="data.includeFirstLevelOfInnerTracks"
                            />
                            <span>Include first level of inner tracks</span>
                        </label>

                        <!-- No entity selected message -->
                        <div
                            class="common-size-xs margin-top-xs"
                            ng-if="
                                !data.selectedReportTypeConfig.errorMessage &&
                                (!data.selectedReportTypeConfig.relevantEntities ||
                                    !data.selectedReportTypeConfig.relevantEntities.length)
                            "
                        >
                            <span class="common-size-xxs">
                                No
                                {{
                                    data.selectedReportTypeConfig.description
                                        ? data.selectedReportTypeConfig.description
                                        : (data.selectedReportTypeConfig.type | lowercase)
                                }}s defined.
                            </span>
                        </div>

                        <!-- Error handling -->
                        <div class="common-size-xxxxxs" ng-if="data.selectedReportTypeConfig.errorMessage">
                            {{ data.selectedReportTypeConfig.errorMessage }}
                        </div>
                    </div>
                </div>

                <div class="row margin-bottom-lg">
                    <div class="col-sm-3 common-size-xs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                        Order by:
                    </div>
                    <div class="col-sm-7">
                        <ui-select
                            ng-if="!data.groupList[data.selectedGroup.id].loadingFieldList"
                            required
                            ng-model="data.selectedOrderByField"
                            theme="bootstrap"
                            ng-change="onSelectedOrderByField(data.selectedOrderByField)"
                        >
                            <ui-select-match>
                                {{ data.selectedOrderByField.label }}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="p in data.orderByOptions | filter:$select.search | orderBy:'label'"
                            >
                                <div ng-bind-html="p.label | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>

                        <div ng-if="data.groupList[data.selectedGroup.id].loadingFieldList" class="loading-small"></div>
                    </div>
                    <select
                        ng-disabled="data.selectedOrderByField.id === 'default'"
                        class="col-sm-2 form-control common-width-auto margin-bottom-xs"
                        ng-options="opt for opt in data.orderBySortTypes"
                        ng-model="data.orderBySortType"
                    ></select>
                </div>

                <!-- For the other types, we do an ng-switch over data.selectedReportType -->
                <div ng-switch-default>
                    <div ng-switch="data.selectedReportType">
                        <!-- Custom report type -->
                        <div ng-switch-when="Custom" class="row">
                            <div
                                class="
                                    col-sm-3
                                    common-size-xs common-bold
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                "
                            >
                                Conditions:
                            </div>
                            <div class="col-sm-9 margin-bottom margin-top-xs">
                                <tnk-custom-filters
                                    group-id="groupId"
                                    workflow-version-id="wvm.getPublishedVersionFromCache(groupId).id"
                                    control="data.customReportControl"
                                    items-source="COLUMN"
                                    consider-all-groups="true"
                                    existing-definition="editedReport.scheduledReportType === 'CUSTOM' ? editedReport.conditions : data.customReportControl.existingDefinition"
                                    hide-time-range-selection="true"
                                    special-fields-for-features="['SCHEDULED_REPORTS']"
                                ></tnk-custom-filters>
                            </div>
                        </div>

                        <!-- Default not supported action for unknown type -->
                        <div ng-switch-default class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-9 margin-bottom-lg">Not supported.</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Send to (People selector) -->
            <div class="row margin-bottom-lg">
                <div class="col-sm-3 common-size-xs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                    Send to:
                </div>
                <div class="col-sm-9">
                    <tnk-deprecated-people-selector
                        selected-people="data.subscribers"
                        no-custom="!pm.isInTrial && !pm.isFree"
                        only-licensed="!pm.isInTrial && !pm.isFree"
                        max-tags="20"
                        no-validation="false"
                        group="data.selectedReportTypeConfig.selectedEntity"
                        on-tag-removed="subscriberUnselected()"
                        show-intro="true"
                    ></tnk-deprecated-people-selector>

                    <!-- No subscribers warning message -->
                    <div
                        class="common-size-xxxxs common-bold common-color-danger margin-top-xxs"
                        ng-if="!data.subscribers || !data.subscribers.length"
                    >
                        Please select a person to send the report to.
                    </div>

                    <!-- Subscribers that do not have permissions warning message -->
                    <div
                        class="common-size-xxxxs common-bold common-color-danger margin-top-xxs"
                        ng-if="
                            data.subscribers &&
                            data.subscribers.length &&
                            data.peopleWithNoPermissions &&
                            data.peopleWithNoPermissions.length
                        "
                    >
                        <span ng-repeat="person in data.peopleWithNoPermissions">
                            {{ person.firstName | capitalize
                            }}{{
                                data.peopleWithNoPermissions.length - 2 > $index
                                    ? ',
                            '
                                    : $index === data.peopleWithNoPermissions.length - 2
                                    ? ' and '
                                    : ''
                            }}
                        </span>
                        <span>
                            {{ data.peopleWithNoPermissions.length > 1 ? 'do not' : 'does not' }} have permissions to
                            the list. Report will not be sent to them.
                        </span>
                    </div>

                    <!-- If project is licensed, let the user know that only full users can receive the email -->
                    <div ng-if="pm.isLicensed" class="common-size-xxxxs common-bold margin-top-xxs">
                        <div
                            ng-if="
                                data.subscribers &&
                                data.subscribers.length &&
                                data.peopleThatAreNotFullUsers &&
                                (!data.peopleWithNoPermissions || !data.peopleWithNoPermissions.length) &&
                                data.peopleThatAreNotFullUsers.length
                            "
                        >
                            <span ng-repeat="person in data.peopleThatAreNotFullUsers">
                                {{ person.firstName | capitalize
                                }}{{
                                    data.peopleThatAreNotFullUsers.length - 2 > $index
                                        ? ', '
                                        : $index === data.peopleThatAreNotFullUsers.length - 2
                                        ? ' and '
                                        : ''
                                }}
                            </span>
                            <span>
                                {{
                                    data.peopleThatAreNotFullUsers.length > 1
                                        ? 'are not full users'
                                        : 'is not a full
                                user'
                                }}
                                and will not get the report.
                            </span>
                        </div>
                        <div class="padding-left-xxs">
                            Only full users can receive scheduled reports. Go to
                            <a ng-click="goToManageUsers()">manage users</a>
                            to add additional full users.
                        </div>
                    </div>
                </div>
            </div>

            <!-- Frequency -->
            <div class="row margin-xs-bottom margin-bottom-lg">
                <div class="col-sm-3 common-size-xs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                    Frequency:
                </div>
                <div class="col-sm-2 margin-bottom-xs">
                    <!-- Frequency type drop down -->
                    <select
                        class="form-control common-width-auto margin-right-lg"
                        ng-options="opt for opt in data.reportFrequenciesOptions"
                        ng-model="data.selectedFrequency"
                        ng-change="reloadReportName()"
                    ></select>
                </div>
                <div class="col-sm-7" ng-switch="data.selectedFrequency">
                    <!-- Weekly -->
                    <div ng-switch-when="Weekly" class="flex-vmiddle-no-xs visible-lg-flex">
                        <div class="common-size-xs common-bold margin-right margin-bottom-xs margin-xs-top">Every:</div>

                        <div class="btn-group">
                            <button
                                type="button"
                                ng-repeat="(dayInWeekName, dayInWeekSelection) in data.selectedDaysInWeek"
                                class="btn btn-default common-no-outline"
                                ng-class="{ 'btn-primary': dayInWeekSelection.selected }"
                                ng-model="ignore"
                                ng-click="selectDayInWeek(dayInWeekSelection)"
                            >
                                {{ dayInWeekName }}
                            </button>
                        </div>
                    </div>

                    <!-- Monthly -->
                    <div ng-switch-when="Monthly" class="flex-vmiddle-no-xs">
                        <div class="common-size-xs common-bold margin-right margin-bottom-xs margin-xs-top">
                            Choose day:
                        </div>

                        <select
                            class="form-control common-width-auto margin-bottom-xs"
                            ng-options="day for day in data.daysInMonth"
                            ng-model="data.selectedDayInMonth"
                        ></select>
                    </div>
                </div>
            </div>

            <!-- Weekly day selection only when screen is xs-sm-md-->
            <div class="row margin-bottom-lg hidden-lg" ng-if="data.selectedFrequency === 'Weekly'">
                <div
                    class="
                        col-sm-3
                        common-size-xs common-bold common-text-right-no-xs
                        padding-right-none
                        margin-top-xs margin-bottom-xs
                    "
                >
                    Every:
                </div>
                <div class="col-sm-9 report-button-pills-group">
                    <button
                        type="button"
                        ng-repeat="(dayInWeekName, dayInWeekSelection) in data.selectedDaysInWeek"
                        class="btn btn-default common-no-outline"
                        ng-class="{ 'btn-primary': dayInWeekSelection.selected }"
                        ng-model="ignore"
                        ng-click="selectDayInWeek(dayInWeekSelection)"
                    >
                        {{ dayInWeekName }}
                    </button>
                </div>
            </div>

            <!-- Hour in day -->
            <div class="row margin-bottom-lg">
                <div class="col-sm-3 common-size-xs common-bold padding-right-none margin-top-xs">Choose hour:</div>
                <div class="col-sm-2 margin-bottom-xs">
                    <select
                        class="margin-right-lg col-sm-2 form-control common-width-auto margin-bottom-xs"
                        ng-options="hourName for (hourName, hourNumber) in data.hoursInDay"
                        ng-model="data.selectedHourInDay"
                    ></select>
                </div>
                <!-- Minute in hour -->
                <div class="col-sm-7">
                    <div class="flex-vmiddle-no-xs">
                        <div class="common-size-xs common-bold margin-right margin-bottom-xs margin-xs-top">
                            Minute:
                        </div>
                        <select
                            class="form-control common-width-auto margin-bottom-xs"
                            ng-options="minute for minute in data.minutesToSelectOptions"
                            ng-model="data.selectedMinute"
                        ></select>
                    </div>
                </div>
            </div>
        </div>

        <!-- Report name -->
        <div class="row margin-bottom-lg">
            <div class="col-sm-3 common-size-xs common-bold padding-right-none margin-top-xs margin-bottom-xs">
                Report name:
            </div>
            <div class="col-sm-9">
                <input
                    type="text"
                    ng-change="data.userChangedReportName = true"
                    ng-model="data.reportName"
                    class="form-control"
                    placeholder="Enter name"
                    required
                />
                <!-- Report name subtitle -->
                <div class="common-size-xxxxs margin-top-xxs">This will also be the email subject</div>
            </div>
        </div>

        <hr />

        <!-- Create & Cancel buttons -->
        <div class="flex-vmiddle">
            <button
                type="button"
                class="btn btn-danger flex-vmiddle"
                ng-if="!createMode"
                ng-click="deleteScheduledReport()"
            >
                <i class="loading-small margin-right-xs" ng-if="data.deletingReport"></i>
                Delete
            </button>
            <div class="flex-vmiddle mod-justify-end flex-grow">
                <!-- Error and loading handling -->
                <div
                    ng-if="data.errorMessage"
                    class="hidden-xs common-color-danger common-bold common-size-xxxxs margin-right-xs"
                >
                    {{ data.errorMessage }}
                </div>
                <div ng-if="data.previewSent" class="hidden-xs common-bold common-size-xxxxs margin-right-xs">
                    Preview was sent!
                </div>

                <!-- Actual buttons -->
                <button
                    type="button"
                    ng-disabled="!addScheduledReportForm.$valid || (data.selectedFrequency === 'Weekly' && data.atLeastOneDaySelected === false) || (data.selectedReportTypeFamily === 'SingleSelection' && !data.selectedReportTypeConfig.selectedEntity) || !data.subscribers || !data.subscribers.length || !data.reportName || data.reportName === ''"
                    ng-click="sendPreview()"
                    class="btn btn-default margin-xs-left-xs margin-right-xs flex-vmiddle"
                >
                    <i class="loading-small margin-right-xs" ng-if="data.sendingPreview"></i>
                    Send me a preview
                </button>
                <button
                    type="submit"
                    ng-disabled="!addScheduledReportForm.$valid || (data.selectedFrequency === 'Weekly' && data.atLeastOneDaySelected === false) || (data.selectedReportTypeFamily === 'SingleSelection' && !data.selectedReportTypeConfig.selectedEntity) || !data.subscribers || !data.subscribers.length || !data.reportName || data.reportName === ''"
                    class="btn btn-primary flex-vmiddle"
                    tnk-require="license"
                >
                    <i
                        class="loading-small margin-right-xs common-color-white"
                        ng-if="data.creatingOrUpdatingReport"
                    ></i>
                    {{ createMode ? 'Create' : 'Save' }}
                </button>
            </div>
        </div>
        <div
            ng-if="data.deletingError"
            class="flex-vmiddle common-size-xxxxs common-color-danger common-bold margin-top-xxs"
        >
            {{ data.deletingError }}
        </div>

        <!-- Error and loading handling -->
        <div class="margin-top-xs">
            <div
                ng-if="data.errorMessage"
                class="common-color-danger common-bold common-size-xxxxs margin-right-xs visible-xs-block"
            >
                {{ data.errorMessage }}
            </div>
            <div ng-if="data.previewSent" class="common-bold common-size-xxxxs margin-right-xs visible-xs-block">
                Preview was sent!
            </div>
        </div>
    </form>
</div>
