import type React from 'react';
import { useCallback } from 'react';

import {
    Validation,
    type TonkeanId,
    type TonkeanType,
    type ValidationResult,
    type ValidationState,
} from '@tonkean/tonkean-entities';

const useSetDetailedItemsValidation = (
    minMaxValidationErrors: string[],
    visitedItemInterfacesValidation: Record<TonkeanId<TonkeanType.INITIATIVE>, ValidationState>,
    setVisitedItemInterfacesValidation: React.Dispatch<
        React.SetStateAction<Record<TonkeanId<TonkeanType.INITIATIVE>, ValidationState>>
    >,
    setValidationKey: (key: string, validator: ValidationResult[]) => void,
    widgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
) => {
    return useCallback(
        (key: TonkeanId<TonkeanType.INITIATIVE> | undefined, value: ValidationState) => {
            if (!key) {
                return;
            }

            const newVisitedItemInterfaceValidation = { ...visitedItemInterfacesValidation, [key]: value };
            setVisitedItemInterfacesValidation((previous) => ({ ...previous, [key]: value }));

            const isAtLeastOneInterfaceInvalid = Object.values(newVisitedItemInterfaceValidation)
                .map((validationState: ValidationState) => validationState.trueValidation)
                .includes(Validation.INVALID);

            const validationResultsArray: ValidationResult[] = [];
            if (isAtLeastOneInterfaceInvalid) {
                validationResultsArray.push({
                    validation: Validation.INVALID,
                    errorMessage: 'You have one or more invalid items',
                });
            }

            if (minMaxValidationErrors.length > 0) {
                minMaxValidationErrors.forEach((errorMessage) =>
                    validationResultsArray.push({
                        validation: Validation.INVALID,
                        errorMessage,
                    }),
                );
            }

            if (validationResultsArray.length === 0) {
                validationResultsArray.push({
                    validation: Validation.VALID,
                });
            }

            setValidationKey(widgetId, validationResultsArray);
        },
        [
            minMaxValidationErrors,
            setValidationKey,
            setVisitedItemInterfacesValidation,
            visitedItemInterfacesValidation,
            widgetId,
        ],
    );
};

export default useSetDetailedItemsValidation;
