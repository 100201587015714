import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import validateMultipleFieldEditors from '../sharedConfigComponents/validators/validateMultipleFieldEditors';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const ManualFieldUpdateLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.MANUAL_FIELD_UPDATE,
    title: 'Update Field',
    iconClass: 'mod-update-field',
    description: 'Update a field in Tonkean\\External systems.',
    canSkipCustomTriggerWhenConditionWasNotMet: true,
    defaultActionDefinition: () => {
        return {
            personDefinition: {
                personEmailExpressionDefinition: null,
                initiativeOwner: false,
                previousActor: false,
                useNone: false,
                ailIfNoValue: true,
                forceEmail: false,
                isTonkeanRootUser: true,
                specificPeopleIds: [],
            },
        };
    },
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        validateMultipleFieldEditors(validationObject, definition.fieldsToUpdate, true);

        const hasTitleToUse = definition.initiativeTitleToUse?.evaluatedExpression;
        if (
            (hasTitleToUse && !definition.groupToUseWithTrackTitle) ||
            (!hasTitleToUse && definition.groupToUseWithTrackTitle)
        ) {
            validationObject.trackToUpdate =
                'When selecting Track to update by title, must have an identifying title and an associated Worker';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    dataAutomation: 'update-field',
};

export default ManualFieldUpdateLogicBlockConfig;
