import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationCollectGenericCollectTimeLabel from './ProjectIntegrationCollectStatusGenericCollectTimeLabel';

import { Theme } from '@tonkean/tui-theme';

const Label = styled.div`
    color: ${Theme.colors.gray_700};
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface Props {
    nextCollectTime: number | null;
    lastUpdatedTime: Date;
}

const ProjectIntegrationCollectStatusNextCollectTime: React.FC<Props> = ({ nextCollectTime, lastUpdatedTime }) => {
    return (
        <LabelWrapper>
            <Label>
                <ProjectIntegrationCollectGenericCollectTimeLabel
                    dateTime={nextCollectTime}
                    lastUpdatedTime={lastUpdatedTime}
                    label="Next collect time"
                />
            </Label>
        </LabelWrapper>
    );
};

export default ProjectIntegrationCollectStatusNextCollectTime;
