import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';

export interface NextCloudProjectData {
    folderPath: string | undefined;
}

const NextCloudSetupComponent: React.FC<CustomizedSetupComponentProps<NextCloudProjectData>> = ({
    integration,
    onChangeOrInitIntegration,
    projectIntegrationData,
    projectIntegration,
}) => {
    const projectManager = useAngularService('projectManager');
    const [{ loading }, callExecuteIntegrationCommand] = useLazyTonkeanService('executeIntegrationCommand');
    const [selectedFolderPath, setSelectedFolderPath] = useState<string>('');
    const [verifiedProjectData, setVerifiedProjectData] = useState<NextCloudProjectData | undefined>();
    const [loadingIntegrationError, setLoadingIntegrationError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const init = () => {
            if (projectIntegrationData?.folderPath) {
                setSelectedFolderPath(projectIntegrationData.folderPath);
            }
        };

        init();
    }, [projectIntegrationData]);

    useEffect(() => {
        const disabled =
            !!loadingIntegrationError ||
            !!!selectedFolderPath ||
            selectedFolderPath !== verifiedProjectData?.folderPath;

        const projectData = {
            folderPath: selectedFolderPath || '',
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData: projectData,
                projectIntegrationDisplayName: `NextCloud (${verifiedProjectData?.folderPath})`,
            },
            disabled,
        );
    }, [loadingIntegrationError, onChangeOrInitIntegration, selectedFolderPath, verifiedProjectData?.folderPath]);

    const verifyFolder = () => {
        if (!selectedFolderPath) {
            return;
        }

        if (selectedFolderPath === '') {
            setSelectedFolderPath('');
            return;
        }

        setLoadingIntegrationError(undefined);

        callExecuteIntegrationCommand(
            projectManager.project.id,
            integration.id,
            'VERIFY_FOLDER',
            { folderPath: selectedFolderPath },
            projectIntegration?.id,
        )
            .then((data) => {
                setVerifiedProjectData(data.projectData);
            })
            .catch((error) => {
                setLoadingIntegrationError(
                    error?.data?.error?.cause?.message || 'An error occurred while verifying the path.',
                );
            });
    };

    const onFolderPathChange = useCallback((folderPath) => {
        setVerifiedProjectData(undefined);
        setSelectedFolderPath(folderPath);
    }, []);

    return (
        <div className="flex flex-col">
            <div className="form-group flex-inline mod-vmiddle">
                <label className="control-label col-md-4 margin-right">Folder Path</label>

                <div className="col-sm-7">
                    <input
                        type="text"
                        value={selectedFolderPath}
                        className="form-control"
                        onChange={({ target }) => onFolderPathChange(target.value)}
                        autoComplete="off"
                    />
                </div>

                <button
                    type="button"
                    disabled={loading || !selectedFolderPath || selectedFolderPath === verifiedProjectData?.folderPath}
                    className="btn btn-primary"
                    onClick={verifyFolder}
                >
                    {loading && <span className="loading" />}
                    {!loading &&
                        ((selectedFolderPath === verifiedProjectData?.folderPath && !!selectedFolderPath) ||
                        !!projectIntegrationData?.folderPath
                            ? 'Verified Folder'
                            : 'Verify Folder')}
                </button>
            </div>

            <div className="flex mod-center">
                {selectedFolderPath === verifiedProjectData?.folderPath && (
                    <span className="control-label">
                        Selected{' '}
                        {verifiedProjectData?.folderPath && selectedFolderPath
                            ? `'${verifiedProjectData?.folderPath}'`
                            : 'root'}{' '}
                        folder.
                    </span>
                )}
            </div>

            <div className="flex mod-center margin-top-xs margin-bottom-xs common-color-danger">
                {loadingIntegrationError && <span>{loadingIntegrationError}</span>}
            </div>
        </div>
    );
};

export default NextCloudSetupComponent;
