import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { TonkeanAvatar } from '@tonkean/infrastructure';
import type { Person } from '@tonkean/tonkean-entities';

const BoardAdminWrapper = styled.div`
    margin-right: 35px;
`;

const BoardAdminTitle = styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: #838a92;
    margin-top: 5px;
`;

const BoardAdminName = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    text-decoration-line: underline;
`;

const BoardAdmins: React.FC = () => {
    const projectManager = useAngularService('projectManager');

    return (
        <div className="margin-top-lg flex">
            {projectManager.project.owners
                .filter((owner: Person) => !owner.systemUtilized)
                .map((boardOwner: Person) => (
                    <BoardAdminWrapper key={boardOwner.id} className="flex flex-vmiddle ">
                        <div className="avatar-main avatar-md margin-right flex-no-shrink">
                            <TonkeanAvatar owner={boardOwner} />
                        </div>
                        <div>
                            <BoardAdminName>{boardOwner.name}</BoardAdminName>
                            <BoardAdminTitle>Board Admin</BoardAdminTitle>
                        </div>
                    </BoardAdminWrapper>
                ))}
        </div>
    );
};
export default BoardAdmins;
