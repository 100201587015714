import template from './tnkOnBoardingWidget.template.html?angularjs';

/**
 * A continuous on boarding widget.
 */
export default angular.module('tonkean.app').component('tnkOnBoardingWidget', {
    bindings: {},
    template,
    controller: 'OnBoardingWidgetCtrl',
});
