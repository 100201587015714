import workerRunReasonConfig from '../../../HistoryModule/entities/workerRunReasonConfig';
import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SolutionMetricsCtrl($timeout, $scope, $state, projectManager, tonkeanService, workflowFolderManager, utils) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.wfm = workflowFolderManager;
    $scope.utils = utils;

    $scope.data = {
        selectedEnv: $state.params.environment,
        activeBotsCount: {
            production: projectManager.groups
                ? projectManager.groups.filter((worker) => worker.workerEnabled).length
                : 0,
            build: projectManager.groups
                ? projectManager.groups.filter((worker) => worker.buildEnvironmentEnabled).length
                : 0,
        },
        folders: [],
        groups: undefined,
        loadingSolutions: true,
        collapsedSolutions: {},
    };

    function updateGroupsStats() {
        const modules = {};

        const workflowVersionType = getWorkflowVersionTypeFromEnvironment($scope.data.selectedEnv);

        // Filter out deleted groups, build a map of group id to group so we can access the group fast
        $scope.pm.groups.forEach((group) => {
            modules[group.id] = group;
        });

        const filterByWorkerRunReasons = Object.entries(workerRunReasonConfig)
            .filter(([, workerRunConfig]) => !workerRunConfig.isDeprecated && !workerRunConfig.hasNotRun)
            .map(([workerRunReason]) => workerRunReason);

        // Get metrics according to group version
        tonkeanService
            .getWorkerRunsStatsNewVersion(
                $scope.pm.project.id,
                Object.keys(modules),
                workflowVersionType,
                true,
                true,
                true,
                true,
                true,
                filterByWorkerRunReasons,
            )
            .then(function (data) {
                // Stop loading
                $scope.data.loadingSolutions = false;

                // Fill the folders object with the group stats data
                const folders = utils
                    .objToArray($scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id])
                    ?.map((folder) => folder.value);
                $scope.data.folders = folders
                    ?.filter((folder) => folder.isVisible)
                    .map((folder) => ({
                        ...folder,
                        groups: folder.groupIds
                            .filter((groupId) => !!modules[groupId]) // Filter our deleted modules
                            .map((groupId) => modules[groupId]),
                    }));

                Object.entries(data.groupIdToMetricsMap).forEach(([metricGroupId, metric]) => {
                    if (metricGroupId && metric) {
                        modules[metricGroupId].stats = metric;
                    }
                });
            });
    }

    /**
     * Get the stats of a group and merge it under the relevant solution (folder)
     */
    function getModulesStats() {
        // Remove current stats
        $scope.pm.groups.forEach((group) => (group.stats = null));

        // Update stats of each group
        updateGroupsStats();
    }

    function getWorkflowVersionTypeFromEnvironment(environment) {
        return environment === 'production' ? 'PUBLISHED' : 'DRAFT';
    }

    $scope.getIntegrationsFromGroup = function (groupId) {
        return $scope.data.modules[groupId]?.groupProjectIntegrations.map(function (integ) {
            return integ.displayName;
        });
    };

    $scope.toggleEnvironment = function (environment) {
        if ($scope.data.selectedEnv !== environment) {
            $state.go('product.runtime', { environment }, { notify: false });
            $scope.data.selectedEnv = environment;
            getModulesStats();
        }
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.selectedEnv) {
            $scope.data.selectedEnv = ctrl.selectedEnv;
            getModulesStats();
        }

        if (changesObj.moduleSearch) {
            $scope.data.moduleSearch = changesObj.moduleSearch.currentValue;
        }
    };

    $scope.onSolutionToggle = function (solutionId) {
        $timeout(() => {
            if ($scope.data.collapsedSolutions[solutionId]) {
                $scope.data.collapsedSolutions[solutionId] = false;
            } else {
                $scope.data.collapsedSolutions[solutionId] = true;
            }
        });
    };
}

export default angular.module('tonkean.app').controller('SolutionMetricsCtrl', lateConstructController(SolutionMetricsCtrl));
