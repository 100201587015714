import * as Yup from 'yup';

const ProjectIntegrationEntitySchema = Yup.object({
    displayName: Yup.string()
        .required('Display Name is required')
        .max(255, "Display name can't be longer than 255 characters"),
    description: Yup.string(),
});

export default ProjectIntegrationEntitySchema;
