import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import type { FormikValues } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import PredefinedConnectionAuthenticationModal from './PredefinedConnectionAuthenticationModal';
import PredefinedConnectionBox from './PredefinedConnectionBox';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { DeprecatedPeopleSelector } from '@tonkean/angular-to-react-components';
import { useProject } from '@tonkean/infrastructure';
import {
    Field,
    Input,
    Modal,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    Radio,
    RadioGroup,
    Textarea,
    Toggle,
    useToggle,
} from '@tonkean/infrastructure';
import { ConnectionPermission, IntegrationType } from '@tonkean/tonkean-entities';
import type { SharedCredential } from '@tonkean/tonkean-entities';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { yupEnum } from '@tonkean/utils';

const FieldWrapper = styled.div`
    margin-bottom: 25px;
`;

const ButtonFieldWrapper = styled.div`
    text-align: center;
`;

const RadioGroupWrapper = styled.div`
    margin-bottom: 10px;
`;

const PeopleSelectorWrapper = styled.div`
    height: 60px;
`;

const HeaderTitle = styled.div`
    margin-right: 5px;
`;

interface Props {
    integrationType: IntegrationType;
    sharedCredential?: SharedCredential;
    isOpen: boolean;
    onClose(): void;
    isCreate?: boolean;
    onPredefinedConnectionUpdated(): void;
    dataSourceConnection?: DataSourceConnection;
}

const PredefinedConnectionCrudModal: React.FC<Props> = ({
    isOpen,
    onClose,
    sharedCredential,
    integrationType,
    isCreate = false,
    onPredefinedConnectionUpdated,
    dataSourceConnection,
}) => {
    const integrations = useAngularService('integrations');
    const project = useProject();
    const [isAuthenticationModalOpen, setIsAuthenticationModalOpen] = useState<boolean>(false);
    const [isEnabled, toggleIsEnabled] = useToggle(sharedCredential?.enabled || false);

    const openAuthenticationWindow = (type?: IntegrationType) => {
        if (type) {
            setIsAuthenticationModalOpen(true);
        }
    };

    const [
        { loading: loadingCreatePredefinedConnection, error: createPredefinedConnectionError },
        createPredefinedConnection,
    ] = useLazyTonkeanService('createPredefinedConnection');

    const [
        { loading: loadingUpdatePredefinedConnection, error: updatePredefinedConnectionError },
        updatePredefinedConnection,
    ] = useLazyTonkeanService('updatePredefinedConnection');

    const [{ data: authorizedUsersEntities }, getAuthorizablesByIds] = useLazyTonkeanService('getAuthorizablesByIds');

    useEffect(() => {
        if (sharedCredential?.authorizedUsers) {
            getAuthorizablesByIds(project.id, sharedCredential?.authorizedUsers);
        }
    }, [getAuthorizablesByIds, project.id, sharedCredential?.authorizedUsers]);

    // Callback to get if the name in unique by the integration type.
    const isNameUnique = useCallback(
        (newName: string | undefined, selectedIntegrationType: string) => {
            if (dataSourceConnection && newName) {
                return !dataSourceConnection.sharedCredentials
                    .filter((singleSharedCredential) => singleSharedCredential.id !== sharedCredential?.id)
                    .map((singleSharedCredential) => singleSharedCredential.displayName.trim())
                    .includes(newName.trim());
            } else {
                return !selectedIntegrationType || !newName;
            }
        },
        [dataSourceConnection, sharedCredential?.id],
    );

    // Schema validation for formik.
    const createPredefinedConnectionSchema = useMemo(() => {
        return Yup.object({
            displayName: Yup.string()
                .test('uniqueName', 'Display name already exists', (value, context) => {
                    return isNameUnique(value, context.parent.integrationType);
                })
                .required("Connection name can't be empty"),
            description: Yup.string(),
            connectionPermission: yupEnum(ConnectionPermission).required(),
            authorizedUsers: Yup.array().required(),
            integrationType: yupEnum(IntegrationType, 'invalid integration').required('Choose integration type'),
            integrationId: Yup.string().required('Must connect integration'),
        }).required();
    }, [isNameUnique]);

    // Formik submit callback.
    const onSubmit = async (values: FormikValues) => {
        if (isCreate && dataSourceConnection && values.integrationId) {
            await createPredefinedConnection(
                project.id,
                dataSourceConnection.id,
                values.integrationId,
                values.description,
                values.displayName,
                values.authorizedUsers.map((user) => user.id),
                values.connectionPermission,
            );
        } else if (sharedCredential && values.integrationId) {
            await updatePredefinedConnection(
                project.id,
                sharedCredential.id,
                isEnabled,
                values.integrationId,
                values.description,
                values.authorizedUsers.map((user) => user.id),
                values.displayName,
                values.connectionPermission,
            );
        }
        onPredefinedConnectionUpdated();

        onClose();
    };

    return (
        <>
            {(isCreate || authorizedUsersEntities) && (
                <Formik
                    validationSchema={createPredefinedConnectionSchema}
                    initialValues={{
                        displayName: sharedCredential?.displayName || '',
                        description: sharedCredential?.description || '',
                        integrationType: integrationType || undefined,
                        connectionPermission:
                            sharedCredential?.connectionPermission || ConnectionPermission.ALL_MEMBERS,
                        authorizedUsers: authorizedUsersEntities || [],
                        integrationId: sharedCredential?.integration.id,
                    }}
                    onSubmit={onSubmit}
                >
                    {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
                        <Modal height="675px" size={ModalSize.MEDIUM} open={isOpen} onClose={onClose} fixedWidth>
                            <ModalHeader $flex>
                                <HeaderTitle>{isCreate ? 'New' : 'Edit'} Predefined Connection</HeaderTitle>
                                {!isCreate && (
                                    <Toggle
                                        size={InputSize.MEDIUM}
                                        onChange={() => toggleIsEnabled()}
                                        checked={isEnabled}
                                        disabled={loadingUpdatePredefinedConnection}
                                        showInnerLabel
                                    />
                                )}
                            </ModalHeader>

                            <ModalForm>
                                <ModalBody>
                                    <FieldWrapper>
                                        <Field label="Connection display name">
                                            <Input
                                                name="displayName"
                                                data-automation="predefined-connection-crud-modal-connection-name"
                                                placeholder="Connection Name"
                                            />
                                        </Field>
                                    </FieldWrapper>

                                    <FieldWrapper>
                                        <Field label="Description" showOptional>
                                            <Textarea name="description" placeholder="Connection Description" />
                                        </Field>
                                    </FieldWrapper>

                                    <FieldWrapper>
                                        <RadioGroupWrapper>
                                            <Field label="Only available for:">
                                                <RadioGroup
                                                    direction="row"
                                                    size={InputSize.MEDIUM}
                                                    name="connectionPermission"
                                                >
                                                    <Radio value={ConnectionPermission.ALL_MEMBERS}>
                                                        Same permissions as defined in Accessibility tab
                                                    </Radio>
                                                    <Radio value={ConnectionPermission.SPECIFIC_MEMBERS}>
                                                        Only specific groups/users
                                                    </Radio>
                                                </RadioGroup>
                                            </Field>
                                        </RadioGroupWrapper>

                                        {values.connectionPermission === ConnectionPermission.SPECIFIC_MEMBERS && (
                                            <Field>
                                                <PeopleSelectorWrapper>
                                                    <DeprecatedPeopleSelector
                                                        onTagAdded={() => {
                                                            setFieldValue('authorizedUsers', values.authorizedUsers);
                                                        }}
                                                        onTagRemoved={() => {
                                                            setFieldValue('authorizedUsers', values.authorizedUsers);
                                                        }}
                                                        selectedPeople={values.authorizedUsers}
                                                        doNotSearchOnlyOwnersInGroup
                                                        noCustom
                                                        includeGroups
                                                    />
                                                </PeopleSelectorWrapper>
                                            </Field>
                                        )}
                                    </FieldWrapper>

                                    {!values.integrationId && (
                                        <ButtonFieldWrapper>
                                            <Field
                                                error={
                                                    errors.integrationId && touched.integrationId
                                                        ? (errors.integrationId as string)
                                                        : undefined
                                                }
                                            >
                                                <Button
                                                    data-automation="predefined-connection-crud-modal-create-a-new-connection"
                                                    size={ButtonSize.HUGE}
                                                    onClick={() => openAuthenticationWindow(values.integrationType)}
                                                    disabled={!values.integrationType}
                                                    outlined
                                                >
                                                    Create a new connection
                                                </Button>
                                            </Field>
                                        </ButtonFieldWrapper>
                                    )}

                                    {values.integrationType && (
                                        <PredefinedConnectionAuthenticationModal
                                            isModalOpen={isAuthenticationModalOpen}
                                            onClose={() => {
                                                setIsAuthenticationModalOpen(false);
                                                setFieldTouched('integrationId', true);
                                            }}
                                            integrationType={values.integrationType}
                                            onIntegrationCreate={(integration?: any) => {
                                                setFieldValue('integrationId', integration?.id);
                                                setIsAuthenticationModalOpen(false);
                                            }}
                                        />
                                    )}

                                    {values.integrationId && values.integrationType && (
                                        <PredefinedConnectionBox
                                            onEditConnectionClicked={() =>
                                                openAuthenticationWindow(values.integrationType)
                                            }
                                            integrationType={values.integrationType}
                                            integrationDisplayName={integrations.getIntegrationDisplayNameByIntegrationType(
                                                values.integrationType.toLowerCase(),
                                            )}
                                        />
                                    )}
                                </ModalBody>

                                <ModalFooterActions
                                    error={createPredefinedConnectionError || updatePredefinedConnectionError}
                                    loading={loadingCreatePredefinedConnection || loadingUpdatePredefinedConnection}
                                    saveLabel={isCreate ? 'Create' : 'Save'}
                                />
                            </ModalForm>
                        </Modal>
                    )}
                </Formik>
            )}
        </>
    );
};

export default PredefinedConnectionCrudModal;
