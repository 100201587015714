<div
    class="publish-workflow-version-modal"
    data-automation="publish-workflow-version-modal"
    ng-class="{
        'new-properties-step': data.currentStep.key === 'SELECT_NEW_PROPERTIES_TO_APPLY_ON_OLD_ITEMS_STEP',
        'comment-step': data.currentStep.key === 'COMMENT_STEP',
        'changes-step': data.currentStep.key === 'CHANGES_REVIEW_STEP',
    }"
>
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>
    <div class="ready-to-publish-container">
        <h1 class="ready-to-publish-workflow-title">
            {{ data.actions[data.action].title }}
        </h1>
        <h2 class="ready-to-publish-workflow-subtitle">{{ pm.groupsMap[data.groupId].name }}</h2>
        <div ng-if="data.loading" class="flex-vmiddle center">
            <i class="loading-large"></i>
        </div>

        <div ng-if="data.error" class="flex-vmiddle center common-color-danger">
            <span>There was an error loading publish information...</span>
        </div>

        <!-- Changes review step -->
        <div ng-if="data.currentStep.key === 'CHANGES_REVIEW_STEP'">
            <div class="margin-top">
                <tnk-worker-audit-logs
                    group-id="data.groupId"
                    open-activity-actor-person-selection-callback="openActivityActorPersonSelection"
                    selected-actor-people-to-filter-on="data.selectedActorPeopleToFilterOn"
                    page-size="15"
                    workflow-version-id="data.workflowVersionId"
                    only-activity-types="data.onlyActivityTypes"
                    one-line-message="true"
                    control-object="data.workerAuditLogsControlObject"
                    load-more-button-secondary="true"
                    tooltip-direction="right"
                    append-filter-dialog-to-body="true"
                    on-close-modal="(cancel)"
                    group-by-toggle-default="true"
                    show-back-to-mark-for-publish-button="data.onReadyToPublishCompareClicked"
                    should-load-commit-activities="true"
                    target-type-id="data.targetTypeId"
                    hide-group-by-toggle="data.hideGroupByToggle"
                    entity-version-id="data.entityVersionId"
                    use-total-committed="data.useTotalCommitted"
                ></tnk-worker-audit-logs>
            </div>

            <!-- Activity actor selection popover -->
            <div id="activity-actor-selection-popover-parent">
                <div id="activity-actor-selection-popover" tnk-dnd-undraggable>
                    <div
                        click-outside="resetActivityActorSelectionPopover()"
                        outside-if-not="activity-actor-selection-popover, activity-actor-selection-popover-container, tag-item, autocomplete, track-leader-avatar, track-owner-name"
                    >
                        <div
                            uib-popover-template="'../workflowVersionChangesModal/activityActorSelectionPopover.template.html'"
                            popover-is-open="data.activityActorSelectionPopoverOpen"
                            popover-placement="bottom"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Bot didnt tested step -->
        <div ng-if="data.currentStep.key === 'TEST_ITEM_VERIFICATION_STEP'">
            <p class="section-explanation">Did you test your changes before publishing?</p>

            <h2>
                <tnk-icon src="/images/icons/test-publish-modal.svg"></tnk-icon>
                Test Workflow
            </h2>

            <tnk-test-workflow
                class="sides-padding block"
                group-id="{{ data.groupId }}"
                on-test-item-finished="onTestItemFinished()"
                test-button-secondary="true"
            ></tnk-test-workflow>
        </div>

        <!-- Run on new items confirmation step -->
        <div ng-if="data.currentStep.key === 'RUN_ON_NEW_ITEMS_CONFIRMATION_STEP'" class="center should-run-once">
            <strong>Would you like to run changes only from now on?</strong>
            <div class="buttons">
                <button class="btn btn-secondary margin-right-xs" ng-click="makeRunOnNewItems(true)">
                    No, Go to Advanced
                </button>
                <button
                    class="btn btn-primary"
                    data-automation="publish-workflow-version-modal-continue-button"
                    ng-click="makeRunOnNewItems(false)"
                >
                    Yes, Continue
                </button>
            </div>
        </div>

        <!-- Run on new items confirmation step -->
        <div
            ng-if="data.currentStep.key === 'SELECT_NEW_PROPERTIES_TO_APPLY_ON_OLD_ITEMS_STEP'"
            class="select-new-properties"
        >
            <p class="section-explanation">Please review below the components that were changed in this version.</p>
            <p class="section-explanation">
                For each changed component, please indicate If you wish to retroactively run the published change on
                existing items or not, using the adjacent toggle button.
            </p>
            <p class="section-explanation">
                Important note: Retroactively running a change will probably have an effect on existing items.
            </p>

            <div ng-class="{ empty: !data.publishImplicationsResponse.changedSyncConfig }" class="sync sides-padding">
                <strong class="property-type-title">Data Source items</strong>

                <div class="flex-vmiddle" ng-if="data.publishImplicationsResponse.changedSyncConfig">
                    <p class="property-type-desc">
                        Data source sync conditions changed, should import items from data source according to the new
                        sync conditions?
                    </p>

                    <tnk-toggle
                        size="sm"
                        async-update="true"
                        is-active="data.executeInitialSyncInGroup"
                        on-toggle-click="toggleExecuteInitialSyncInGroup(isActive)"
                    ></tnk-toggle>
                </div>
                <p class="property-type-desc" ng-if="!data.publishImplicationsResponse.changedSyncConfig">
                    No Data source condition was changed
                </p>
            </div>

            <div class="flex triggers-fields-container">
                <div
                    ng-class="{ empty: !data.publishImplicationsResponse.changedCustomTriggers.length }"
                    class="triggers"
                >
                    <strong class="property-type-title">Triggers</strong>

                    <p
                        ng-if="!data.publishImplicationsResponse.changedCustomTriggers.length"
                        class="property-type-desc"
                    >
                        No Triggers were changed
                        <tnk-icon src="/images/icons/empty-state-trigger.svg"></tnk-icon>
                    </p>

                    <p ng-if="data.publishImplicationsResponse.changedCustomTriggers.length" class="property-type-desc">
                        The following triggers changed, select what items should rerun on the triggers below
                    </p>

                    <div
                        ng-if="data.publishImplicationsResponse.changedCustomTriggers.length"
                        class="trigger"
                        ng-repeat="changedAutonomousTrigger in data.publishImplicationsResponse.changedCustomTriggers"
                    >
                        <strong class="trigger-name">
                            <tnk-icon src="/images/icons/lightning.svg"></tnk-icon>
                            {{ changedAutonomousTrigger.displayName }}
                        </strong>
                        <p class="related-fields">
                            Dependent Fields:
                            <strong>
                                {{
                                    data.customTriggerIdToDependentFieldDefinitionMap[changedAutonomousTrigger.id]
                                        .length || 0
                                }}
                            </strong>
                            <button
                                ng-if="
                                    data.customTriggerIdToDependentFieldDefinitionMap[changedAutonomousTrigger.id]
                                        .length
                                "
                                class="inline-button"
                                ng-class="{ disabled: data.customTriggerToFilterFieldsBy === changedAutonomousTrigger }"
                                ng-click="data.customTriggerToFilterFieldsBy = changedAutonomousTrigger"
                            >
                                Show in Fields list
                            </button>
                        </p>
                        <div class="big-toggle">
                            <button
                                class="inline-button"
                                ng-class="{ selected: !data.calculateCustomTriggerIdsSet[changedAutonomousTrigger.id] }"
                                ng-click="toggleCalculateTriggersOnExistingItems(changedAutonomousTrigger.id, false)"
                            >
                                Only new items
                            </button>
                            <button
                                class="inline-button"
                                ng-class="{ selected: data.calculateCustomTriggerIdsSet[changedAutonomousTrigger.id] }"
                                ng-click="toggleCalculateTriggersOnExistingItems(changedAutonomousTrigger.id, true)"
                            >
                                Existing & new items
                            </button>
                        </div>
                    </div>
                </div>

                <div ng-class="{ empty: !data.customTriggerIdToDependentFieldDefinitionMap.all.length }" class="fields">
                    <strong class="property-type-title">Fields</strong>

                    <p ng-if="!data.customTriggerIdToDependentFieldDefinitionMap.all.length" class="property-type-desc">
                        No Fields were changed
                        <tnk-icon src="/images/icons/empty-state-field.svg"></tnk-icon>
                    </p>

                    <p ng-if="data.customTriggerIdToDependentFieldDefinitionMap.all.length" class="property-type-desc">
                        Fields changed, should update existing items fields values?
                    </p>

                    <div ng-if="data.customTriggerToFilterFieldsBy" class="filter">
                        <small>
                            List filtered by Trigger:
                            <strong>{{ data.customTriggerToFilterFieldsBy.displayName }}</strong>
                        </small>
                        <button class="inline-button" ng-click="data.customTriggerToFilterFieldsBy = null">
                            Clear Filter
                        </button>
                    </div>

                    <div ng-if="data.customTriggerIdToDependentFieldDefinitionMap.all.length" class="fields-grid">
                        <span
                            class="field-icon"
                            ng-repeat-start="changedFieldDefinition in data.customTriggerIdToDependentFieldDefinitionMap[data.customTriggerToFilterFieldsBy.id || 'all']"
                        >
                            <span ng-if="changedFieldDefinition.definitionSource === 'MANUAL'" class="opacity-80">
                                <tnk-field-type-icon
                                    field-type="changedFieldDefinition.fieldType"
                                ></tnk-field-type-icon>
                            </span>
                            <i
                                ng-if="changedFieldDefinition.definitionSource !== 'MANUAL'"
                                class="initiative-integration-icon mod-dropdown margin-none"
                                ng-class="
                                    changedFieldDefinition.projectIntegration &&
                                    !changedFieldDefinition.projectIntegration.iconUrl
                                        ? 'mod-' +
                                          changedFieldDefinition.projectIntegration.integration.integrationType.toLowerCase()
                                        : null
                                "
                                ng-style="{
                                    background:
                                        changedFieldDefinition.projectIntegration &&
                                        changedFieldDefinition.projectIntegration.iconUrl
                                            ? 'url(' +
                                              changedFieldDefinition.projectIntegration.iconUrl +
                                              ') no-repeat center'
                                            : null
                                }"
                            ></i>
                        </span>
                        <strong class="field-name">{{ changedFieldDefinition.name }}</strong>
                        <tnk-toggle
                            ng-repeat-end
                            size="sm"
                            async-update="true"
                            is-active="data.calculateFieldDefinitionIdsSet[changedFieldDefinition.id]"
                            on-toggle-click="toggleCalculateFieldsOnExistingItems(changedFieldDefinition.id)"
                        ></tnk-toggle>
                    </div>
                </div>
            </div>
        </div>

        <!-- Comment and publish step -->
        <div ng-if="data.currentStep.key === 'COMMENT_STEP'">
            <p class="section-explanation" ng-bind-html="data.actions[data.action].commentStepDescription"></p>
            <div class="flex">
                <div>
                    <label for="workflow-version-publish-comment-textarea" class="publish-label">
                        Describe your change {{ data.actions[data.action].commentRequired ? '* ' : '(optional)' }}
                    </label>
                </div>
                <div
                    ng-if="data.action === 'isCreateVersion' || data.action === 'createSolutionSitePageVersion'"
                    class="publish-label comment-view-change"
                >
                    {{ wvm.workflowVersionIdToChangesCounterMap[data.workflowVersionId] }} pending changes (
                    <button
                        class="inline-button close-and-view-changes-button"
                        ng-click="closeAndViewChanges()"
                        type="button"
                    >
                        close and view changes
                    </button>
                    )
                </div>
            </div>
            <div class="sides-padding">
                <textarea
                    id="workflow-version-publish-comment-textarea"
                    class="common-resize-y-only"
                    data-automation="ready-to-publish-workflow-version-add-description"
                    ng-model="data.comment"
                    ng-required="data.actions[data.action].commentRequired"
                    maxlength="1000"
                    ng-change="onCommentChanged()"
                    placeholder="{{ data.actions[data.action].placeholder }}"
                    prevent-scroll-bubbling
                ></textarea>
            </div>
            <div
                ng-if="data.actions[data.action].subTitle"
                class="publish-label version-description-subtitle"
                ng-bind-html="data.actions[data.action].subTitle"
            ></div>

            <!-- Error -->
            <span
                class="block common-color-danger margin-top sides-padding"
                ng-if="data.errorPublishingWorkflowVersion"
            >
                {{ data.errorPublishingWorkflowVersionMessage }}
            </span>
        </div>
    </div>

    <footer>
        <p class="section-tip" ng-if="data.actions[data.action].tip" ng-bind-html="data.actions[data.action].tip"></p>
        <div>
            <button
                ng-if="
                    data.currentStep.index !== data.firstStepIndex &&
                    (data.currentStep.key != 'COMMENT_STEP' ||
                        (data.action != 'isCreateVersion' && data.action !== 'createSolutionSitePageVersion'))
                "
                class="inline-button back-button"
                ng-disabled="data.error || data.loading"
                ng-click="(!data.error && !data.loading) && previousStep()"
                type="button"
            >
                <i class="fa fa-caret-left"></i>
                Back
            </button>
        </div>

        <div class="flex-grow"></div>

        <!-- Loading -->
        <span class="loading-small margin-right" ng-if="data.publishingWorkflowVersion"></span>
        <button
            ng-if="data.action === 'isCreateVersion' || data.action === 'createSolutionSitePageVersion'"
            class="inline-button cancel-button"
            ng-click="cancel()"
            type="button"
        >
            Cancel
        </button>
        <div
            ng-if="data.currentStep.key !== 'RUN_ON_NEW_ITEMS_CONFIRMATION_STEP'"
            ng-switch="data.currentStep.key"
            class="same-width-buttons"
        >
            <button
                ng-switch-when="SELECT_NEW_PROPERTIES_TO_APPLY_ON_OLD_ITEMS_STEP"
                class="btn btn-secondary margin-right-xs"
                ng-click="resetNewProperties()"
            >
                Reset
            </button>
            <button
                ng-switch-when="SELECT_NEW_PROPERTIES_TO_APPLY_ON_OLD_ITEMS_STEP"
                class="btn btn-primary"
                ng-click="(!data.error && !data.loading) && nextStep()"
            >
                Apply
            </button>

            <button
                ng-switch-when="COMMENT_STEP"
                class="btn btn-primary"
                data-automation="ready-to-publish-workflow-version-modal-mark-for-publish-button"
                ng-disabled="(!data.comment && data.actions[data.action].commentRequired) || data.publishingWorkflowVersion"
                ng-click="workflowVersionAction()"
            >
                {{ data.actions[data.action].button }}
            </button>

            <button
                ng-switch-default
                ng-if="data.currentStep.index !== data.lastStepIndex"
                data-automation="ready-to-publish-workflow-version-modal-click-next"
                class="btn btn-primary"
                ng-disabled="data.loading"
                ng-click="!data.loading && nextStep()"
            >
                Next
                <i class="fa fa-caret-right"></i>
            </button>
        </div>
    </footer>
</div>
