<div class="autonomous-trigger-configuration" data-automation="autonomous-trigger-logic-configuration">
    <hr class="margin-none"/>

    <!-- Secondary type badge -->
    <tnk-secondary-type-side-pane
            group-id="data.groupId"
            workflow-version-id="data.workflowVersionId"
            custom-trigger="data.configuredLogic"
            on-type-changed="changeAutonomousType(selectedType)"
            is-monitor-track="false"
            is-inner-autonomous="data.isInnerAutonomous"
    ></tnk-secondary-type-side-pane>

    <!-- Execute When -->
    <div
            ng-if="data.configuredLogic.customTriggerSecondaryType !== 'USER_INTERFACE_BUTTON_CLICK'"
            class="margin-top-xlg"
    >
        <!-- Schedule configuration -->
        <div ng-if="data.selectedTriggerType === 'ON_SCHEDULE'" class="common-size-xxs">
            <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg">
                Activate Trigger When
            </div>

            <!-- Time recurrence selection -->
            <tnk-recurrence-time-selection
                    existing-recurrence-time-selection="data.existingRecurrenceTimeSelection"
                    on-recurrence-time-selection-changed="onRecurrenceTimeSelectionChanged(recurrenceTimeSelection)"
                    hide-timezone-if-not-configured="wvm.getCachedWorkflowVersion(data.workflowVersionId).workflowVersionType !== 'DRAFT'"
                    validation-object="data.invalidLogics[data.configuredLogic.id]"
                    min-every-x-minutes-interval="pm.project.features.tonkean_feature_minimum_scheduled_trigger_interval_minutes || 30"
            ></tnk-recurrence-time-selection>

            <!-- Next scheduled evaluation -->
            <div
                    ng-if="
                    wvm.getCachedWorkflowVersion(data.workflowVersionId).workflowVersionType !== 'DRAFT' &&
                    data.configuredLogic.nextScheduledEvaluation
                "
                    class="flex-vmiddle margin-bottom"
            >
                <span class="common-bold margin-right-xs">Next run:</span>
                <span>{{ data.configuredLogic.nextScheduledEvaluation | date: 'MMMM d, y, h:mm:ss Z' }}</span>
            </div>

            <!-- Run now -->
            <div
                    class="flex-vmiddle margin-bottom margin-top common-size-xxs"
                    ng-if="wvm.getCachedWorkflowVersion(data.workflowVersionId).workflowVersionType !== 'PUBLISHED'"
            >
                <!-- Run now button -->
                <a
                        class="margin-right"
                        data-automation="tnk-autonomous-trigger-configuration-template-run-trigger-now"
                        ng-click="runAutonomousTriggerNow()"
                >
                    Run Now
                </a>

                <!-- Loading state -->
                <div class="flex-vmiddle" ng-if="data.sendingRunAutonomousTriggerNow">
                    <i class="loading-small margin-right-xs"></i>
                    <span></span>
                </div>
            </div>

            <!-- Error state -->
            <div
                    class="common-color-danger common-size-xxxs"
                    ng-if="!data.sendingRunAutonomousTriggerNow && data.errorSendingRunAutonomousTriggerNow"
            >
                {{ data.errorSendingRunAutonomousTriggerNow }}
            </div>
        </div>

        <!-- On field value change configuration -->
        <div ng-if="data.selectedTriggerType === 'ON_FIELD_CHANGE'">
            <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg">
                Activate Trigger When
            </div>

            <div
                    ng-if="data.invalidLogics[data.configuredLogic.id].noFieldSelectedError"
                    class="common-color-danger margin-bottom-xs"
            >
                {{ data.invalidLogics[data.configuredLogic.id].noFieldSelectedError }}
            </div>

            <!-- Fields selection -->
            <tnk-multi-field-quick-selection
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    include-global-fields="false"
                    on-fields-change="onMonitorFieldsChanged(fields)"
                    existing-fields="data.existingMonitorFieldDefinitions"
                    special-fields-for-features="['']"
            ></tnk-multi-field-quick-selection>

            <!-- Loading -->
            <div ng-if="data.savingMonitorFieldDefinitions">
                <i class="loading-small margin-right-xs"></i>
                <span>Loading...</span>
            </div>

            <!-- Error -->
            <div
                    class="common-color-danger common-size-xxxs"
                    ng-if="!data.savingMonitorFieldDefinitions && data.errorSavingMonitorFieldDefinitions"
            >
                {{ data.errorSavingMonitorFieldDefinitions }}
            </div>
        </div>

        <!-- Once initiative created by forms -->
        <div ng-if="data.selectedTriggerType === 'ON_INITIATIVE_CREATED_BY_FORM'">
            <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg">
                Activate Trigger When
            </div>

            <div
                    ng-if="data.invalidLogics[data.configuredLogic.id].formNotSelectedError"
                    class="common-color-danger margin-bottom-xs"
            >
                {{ data.invalidLogics[data.configuredLogic.id].formNotSelectedError }}
            </div>

            <!-- Forms selection -->
            <tnk-multi-form-selection
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    existing-forms="data.configuredLogic.monitorForms"
                    on-forms-change="onMonitorFormsChanged(forms)"
                    hide-create-forms="data.configuredLogic.customTriggerType === 'INNER_AUTONOMOUS'"
            ></tnk-multi-form-selection>

            <!-- Loading -->
            <div ng-if="data.savingMonitorForms">
                <i class="loading-small margin-right-xs"></i>
                <span>Loading...</span>
            </div>

            <!-- Error -->
            <div
                    class="common-color-danger common-size-xxxs"
                    ng-if="!data.savingMonitorForms && data.errorSavingMonitorForms"
            >
                {{ data.errorSavingMonitorForms }}
            </div>
        </div>
        <!-- Once interface submitted -->
        <div ng-if="data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_INTERFACE_SUBMITTED'">
            <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg">
                Activate Trigger When
            </div>

            <div
                    ng-if="data.invalidLogics[data.configuredLogic.id].interfaceNotSelectedError"
                    class="common-color-danger margin-bottom-xs"
            >
                {{ data.invalidLogics[data.configuredLogic.id].interfaceNotSelectedError }}
            </div>

            <!-- Item Interface Selector-->
            <tnk-item-interface-selector
                    group-id="data.groupId"
                    workflow-version-id="data.workflowVersionId"
                    selected-item-interface-id="data.itemInterfaceId"
                    on-item-interface-selected="(onItemInterfaceSelected)"
                    environment="data.environment"
            ></tnk-item-interface-selector>
            <div class="sequence-links-wrapper" ng-if="data.itemInterfaceId && !data.configuredLogic.disabled">
                <a class="sequence-link" ng-click="goToIntakePage()">
                    <tnk-icon class="sequence-link-icon" src="/images/icons/new_tab.svg"></tnk-icon>
                    Open sequence</a>
                <a class="sequence-link" ng-click="copyIntakeLinkToClipboard()">
                    <tnk-icon class="sequence-link-icon" src="/images/icons/link-primary.svg"></tnk-icon>
                    Copy sequence link</a>
            </div>
            <div>
                <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg">
                    Submit button label:
                </div>
                <input
                        class="form-control margin-bottom-xs margin-top common-width-90"
                        ng-model="data.submitButtonCustomLabel"
                        ng-change="onSubmitButtonLabelChanged(data.submitButtonCustomLabel)"
                        placeholder="Enter submit button label"
                />
            </div>
            <div>
                <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg">
                    Intake Sequence Tracker
                </div>

                <tnk-checkbox
                        label="'Show intake sequence tracker'"
                        value="data.intakeProgressTrackerEnabled"
                        on-toggle="onIntakeProgressTrackerEnabledChanged(value)"
                ></tnk-checkbox>

                <div ng-if="data.intakeProgressTrackerEnabled">
                    <input
                            class="form-control margin-bottom-xs margin-top"
                            ng-model="data.intakeProgressTrackerTitle"
                            ng-change="onIntakeStatusTrackerTitleChanged(data.intakeProgressTrackerTitle)"
                            placeholder="Enter sequence tracker title"
                    />
                    <div>

                    </div>

                    <tnk-toggle-buttons-group
                            class="flex full-width margin-top-md"
                            title-label="'Tracker orientation'"
                            buttons="data.intakeProgressTrackerOrientationButtons"
                            selected-button="getSelectedIntakeProgressTrackerOrientationButton()"
                            on-button-selected="onShowVerticalIntakeProgressTracker(selectedButton.value)"
                            wrapper-classes="full-width"
                            toggle-buttons-group-classes="flex-space-between full-width margin-none"
                            title-classes="common-size-xxxs common-bold thin-font common-color-dark-grey"
                    ></tnk-toggle-buttons-group>
                </div>
            </div>

            <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-lg">
                Run Settings
            </div>

            <div class="flex flex-space-between margin-bottom-xxs">
                <span class="margin-right common-size-13">AI front door</span>
                <tnk-toggle
                        is-active="data.showInFrontDoor"
                        size="xs"
                        on-toggle-click="onShowInFrontDoorToggle()"
                ></tnk-toggle>
            </div>
            <div class="front-door-comment">Query params will override the initial values</div>

            <br/>

            <div class="flex flex-space-between margin-bottom-xxs">
                <span class="margin-right common-size-13">Home page search</span>
                <tnk-toggle
                        is-active="data.showInHomePage"
                        size="xs"
                        on-toggle-click="onShowInHomePageToggle()"
                ></tnk-toggle>
            </div>
            <div class="front-door-comment">Will show as a dropdown option in search</div>

            <br/>
            <div class="flex flex-space-between margin-bottom">
                <span class="margin-right common-size-13">Show AI Concierge</span>
                <tnk-toggle
                        is-active="data.showCollaborationPopoverConcierge"
                        size="xs"
                        on-toggle-click="onSetCollaborationPopoverShowConciergeToggle()"
                ></tnk-toggle>
            </div>
            <div class="front-door-comment">Provide requests with AI assistance</div>

            <br/>
            <div class="flex flex-space-between margin-bottom">
                <span class="margin-right common-size-13">Show Comments</span>
                <tnk-toggle
                        data-automation="interface-submitted-show-comments-toggle"
                        is-active="data.showCollaborationPopoverComments"
                        size="xs"
                        on-toggle-click="onSetCollaborationPopoverShowCommentsToggle()"
                ></tnk-toggle>
            </div>
            <div class="front-door-comment">Collaborate on requests with comments, tagging and context</div>

            <br/>
            <div class="flex flex-space-between margin-bottom">
                <span class="margin-right common-size-13">Set initial values </span>
                <tnk-toggle
                        is-active="data.setInitialValues"
                        size="xs"
                        on-toggle-click="onSetInitialValuesToggle()"
                ></tnk-toggle>
            </div>

            <div
                    ng-if="data.invalidLogics[data.configuredLogic.id].fieldNotSelectedError"
                    class="common-color-danger margin-bottom-xs"
            >
                {{ data.invalidLogics[data.configuredLogic.id].fieldNotSelectedError }}
            </div>

            <tnk-multiple-field-to-update-editors
                    ng-if="data.setInitialValues"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    field-selector-group-id="{{ data.groupId }}"
                    field-selector-workflow-version-id="{{ data.workflowVersionId }}"
                    expression-group-id="{{ data.groupId }}"
                    expression-workflow-version-id="{{ data.workflowVersionId }}"
                    configured-fields="data.fieldsToUpdate"
                    special-fields-for-features="['INTERFACE_SUBMITTED_INITIAL_FIELDS']"
                    preview-evaluation-source="$scope.ctm.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][$scope.data.selectedLogic]"
                    reload-fields="data.reloadFields"
                    validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                    on-configured-fields-changed="onConfiguredFieldsChanged(configuredFields, shouldSaveLogic)"
                    global-expression-only="true"
            ></tnk-multiple-field-to-update-editors>
        </div>

        <div
                ng-if="data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_CREATED_FROM_FORM'"
        >
            <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg">
                Intake Sequence Tracker
            </div>
            <tnk-checkbox
                    label="'Show intake sequence tracker'"
                    value="data.intakeProgressTrackerEnabled"
                    on-toggle="onIntakeProgressTrackerEnabledChanged(value)"
            ></tnk-checkbox>
            <div ng-if="data.intakeProgressTrackerEnabled">
                <input
                        class="form-control margin-bottom-xs margin-top"
                        ng-model="data.intakeProgressTrackerTitle"
                        ng-change="onIntakeStatusTrackerTitleChanged(data.intakeProgressTrackerTitle)"
                        placeholder="Enter sequence tracker title"
                />
                <tnk-toggle-buttons-group
                        class="flex full-width margin-top-md"
                        title-label="'Tracker orientation'"
                        buttons="data.intakeProgressTrackerOrientationButtons"
                        selected-button="getSelectedIntakeProgressTrackerOrientationButton()"
                        on-button-selected="onShowVerticalIntakeProgressTracker(selectedButton.value)"
                        wrapper-classes="full-width"
                        toggle-buttons-group-classes="flex-space-between full-width margin-none"
                        title-classes="common-size-xxxs common-bold thin-font common-color-dark-grey"
                ></tnk-toggle-buttons-group>
            </div>
        </div>


        <div class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg"
             ng-if="data.configuredLogic.customTriggerSecondaryType !== 'AUTONOMOUS_INTERFACE_SUBMITTED'">
            Run Settings
        </div>

        <!-- Run only once -->
        <div
                class="margin-top"
                ng-if="data.configuredLogic.customTriggerSecondaryType === 'AUTONOMOUS_MATCH_CONDITIONS'"
        >
            <tnk-checkbox
                    label="'Run only once'"
                    value="data.runOneTimeOnly"
                    uib-tooltip="This trigger will only be triggered one time"
                    tooltip-placement="right"
                    on-toggle="onRunOneTimeOnlyChanged(value)"
            ></tnk-checkbox>
        </div>

        <!-- Monitor inner items checkbox -->
        <div class="margin-top"
             ng-if="data.configuredLogic.customTriggerSecondaryType !== 'AUTONOMOUS_INTERFACE_SUBMITTED'">
            <tnk-checkbox
                    label="'Run also on inner items'"
                    value="data.monitorInnerItems"
                    uib-tooltip="Will trigger the module for inner items as well (not only root items)"
                    tooltip-placement="right"
                    on-toggle="onMonitorInnerItemsChanged(value)"
            ></tnk-checkbox>
        </div>

        <div ng-if="data.monitorInnerItems" class="margin-top-xs margin-bottom-xs">
            <div class="margin-bottom-xs">Created by (Optional):</div>
            <tnk-custom-trigger-selector
                    workflow-version-id="{{ data.workflowVersionId }}"
                    custom-trigger-types="data.creatingInnerItemsLogicTypes"
                    empty-state-message="All Triggers"
                    dont-auto-select-first-one="true"
                    allow-clear="true"
                    on-custom-trigger-selected="onCustomTriggerSelected(selectedCustomTrigger, $index)"
                    selected-custom-trigger-id="{{ data.monitorInnerItemsCreatedByCustomTriggerId }}"
            ></tnk-custom-trigger-selector>
        </div>

        <!-- Dont run on done -->
        <div
                class="margin-top flex-vmiddle"
                ng-if="$root.features.currentProject.tonkean_feature_show_dont_run_on_done_items_checkbox"
        >
            <tnk-checkbox
                    label="'Don\'t run on items with any status of type Done'"
                    value="data.dontRunOnDone"
                    on-toggle="onDontRunOnDoneChange(value)"
            ></tnk-checkbox>
        </div>

        <!-- run also on new items -->
        <div class="margin-top" ng-if="data.selectedTriggerType === 'ON_FIELD_CHANGE'">
            <tnk-checkbox
                    class="checkbox-run-on-new-items"
                    label="'Evaluate trigger conditions when a new item is created'"
                    value="data.runAlsoOnNewItems"
                    uib-tooltip="Use this option if you want to evaluate trigger conditions on two occasions: when the item is created and when a field value is changed. Do not use  this option if you only want to evaluate trigger conditions after the item is created. The trigger will be evaluated for newly created items that hold values in the evaluated fields."
                    tooltip-placement="right"
                    on-toggle="onRunAlsoOnNewItemsChange(value)"
            ></tnk-checkbox>
        </div>

        <!-- run also on new items -->
        <div class="margin-top"
             ng-if="data.configuredLogic.customTriggerSecondaryType !== 'AUTONOMOUS_INTERFACE_SUBMITTED'">
            <tnk-checkbox
                    label="'Run also on intake items'"
                    value="data.runAlsoOnIntakeItems"
                    uib-tooltip="Use this option if you want to run also in items with intake status"
                    tooltip-placement="right"
                    on-toggle="onRunAlsoOnIntakeItemsChange(value)"
            ></tnk-checkbox>
        </div>
    </div>

    <!-- Filters -->
    <div
            ng-if="
            data.configuredLogic.customTriggerSecondaryType !== 'AUTONOMOUS_ITEM_CREATED' &&
            data.configuredLogic.customTriggerSecondaryType !== 'USER_INTERFACE_BUTTON_CLICK' &&
            data.configuredLogic.customTriggerSecondaryType !== 'AUTONOMOUS_INTERFACE_SUBMITTED'
        "
    >
        <!-- Title -->
        <div
                ng-if="data.configuredLogic.customTriggerSecondaryType !== 'AUTONOMOUS_MATCH_CONDITIONS'"
                class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg"
        >
            And Only If
        </div>

        <div
                ng-if="data.configuredLogic.customTriggerSecondaryType == 'AUTONOMOUS_MATCH_CONDITIONS'"
                class="autonomous-trigger-title common-bold common-size-xs margin-bottom margin-top-xlg"
        >
            Activate Trigger When
        </div>

        <div
                ng-if="data.invalidLogics[data.configuredLogic.id].emptyQuery"
                class="common-color-danger margin-bottom-xs"
        >
            {{ data.invalidLogics[data.configuredLogic.id].emptyQuery }}
        </div>

        <tnk-custom-filters
                display-integration-indication="true"
                project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                hide-close-button="true"
                control="data.control"
                items-source="COLUMN"
                existing-definition="data.configuredLogic.queryDefinition"
                group-id="data.groupId"
                workflow-version-id="data.workflowVersionId"
                logic-id="data.configuredLogic.id"
                preview-evaluation-source="data.previewEvaluationSource"
                evaluate-preview="true"
                edit-mode="data.editMode"
                small-mod="true"
                hide-time-range-selection="true"
                bold-title="true"
                special-fields-for-features="['CUSTOM_TRIGGER_CONDITIONS']"
                on-filters-change="onFilterChange(shouldNotSaveLogic)"
                filters-change-only-after-init="true"
                reload-fields-on-new-field-definition-creation="'true'"
                show-field-inspect="false"
                show-field-inspect-modal="!!scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
                force-expand="data.viewOnly"
                not-allowed-conditions-set="data.notAllowedConditionsSet"
        ></tnk-custom-filters>
    </div>
</div>
