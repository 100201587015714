import template from './tnkCustomNotificationSettings.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkCustomNotificationSettings', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        overrideTitle: '@',
        existingCustomNotificationSettings: '<',
        validationObject: '<',
        supportCustomFields: '<',
        onCustomNotificationSettingsChanged: '&',
    },
    template,
    controller: 'CustomNotificationSettingsCtrl',
});
