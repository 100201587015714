import React from 'react';
import styled from 'styled-components';

import { AITypewriter } from '@tonkean/ai-builder';
import { IconSvg } from '@tonkean/infrastructure';
import { SmartConversationResponseText } from '@tonkean/smart-conversation';
import { SmartSearchIcon } from '@tonkean/svg';
import type { SmartConversationMessage, SmartSearchUnknownCategoryResponse } from '@tonkean/tonkean-entities';

const ResponseContainer = styled.div`
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'gutter text';
    column-gap: 10px;
`;

const ResponseIcon = styled(IconSvg)`
    grid-area: gutter;
    margin-top: 4px;
`;

interface Props {
    message: SmartConversationMessage<SmartSearchUnknownCategoryResponse>;
    onAnimationEnd: () => void;
}

const SmartSearchUnknownCategoryView: React.FC<Props> = ({ message, onAnimationEnd }) => {
    return (
        <ResponseContainer>
            <ResponseIcon as={SmartSearchIcon} />
            <SmartConversationResponseText>
                <AITypewriter
                    skipAnimation={!message.showAnimation}
                    text={message.response?.answerText}
                    onTypingDone={(isDone) => {
                        if (isDone) {
                            onAnimationEnd();
                        }
                    }}
                />
            </SmartConversationResponseText>
        </ResponseContainer>
    );
};

export default SmartSearchUnknownCategoryView;
