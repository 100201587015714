import DetailedItemsWidget from './components/DetailedItemsWidget';
import DetailedItemsWidgetConfigurationEditor from './components/DetailedItemsWidgetConfigurationEditor';
import type ItemInterfaceWidgetPackage from '../../WidgetModule/entities/ItemWidgetPackage';
import type InnerItemWidgetConfiguration from '../entities/InnerItemWidgetConfiguration';

const DetailedItemsWidgetPackage: ItemInterfaceWidgetPackage<InnerItemWidgetConfiguration> = {
    displayComponent: DetailedItemsWidget,
    configurationComponent: DetailedItemsWidgetConfigurationEditor,
};

export default DetailedItemsWidgetPackage;
