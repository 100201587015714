import type { IRootScopeService } from 'angular';
import { useAngularService } from 'angulareact';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import OtherLoginButtons from './3rdPartyLoginButtons';
import { ReactComponent as GIcon } from './assets/google-icon.svg';
import { ReactComponent as MIcon } from './assets/microsoftIcon.svg';
import { ReactComponent as OIcon } from './assets/oktaIcon.svg';
import { ReactComponent as PIcon } from './assets/pingIdentityIcon.svg';
import { ReactComponent as SIcon } from './assets/slack-icon.svg';
import AuthPageLayoutWrapper from './AuthPageLayoutWrapper';
import LoginErrorMessage from './LoginErrorMessage';
import { type Auth, AuthTypes } from './LoginPageTypes';
import OktaLoginPrompt from './okta/OktaLoginPrompt';
import PingLoginPrompt from './ping/PingLoginPrompt';
import { getRedirectUri } from './utils';

import { analyticsWrapper } from '@tonkean/analytics';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { Breakpoint, Field, H1, H3, Input, LoadingCircle } from '@tonkean/infrastructure';
import { TnkLogoFull as TnkLogo } from '@tonkean/svg';
import { SlackAuthenticationType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const LoginTitle = styled(H1)`
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 36px;
`;

const TnkLogoStyled = styled(TnkLogo)`
    margin-bottom: 64px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        margin: 32px;
    }
`;

const Hr = styled.hr`
    width: 44%;
    border-color: #cdd2d8;
    z-index: 1;
`;

const EmailLoginSeperator = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const OrSeperatorSpan = styled.span`
    color: #5b636c;
    font-size: 14px;
    display: flex;
    align-items: center;
    z-index: 2;
`;

const SubmitButton = styled(Button)`
    height: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-block: 13px;
    background: ${({ disabled }) => (disabled ? Theme.colors.gray_300 : '#605ce4')} !important;
    font-size: 18px;
    color: ${({ disabled }) => (disabled ? Theme.colors.gray_600 : 'white')} !important;
    border-radius: 6px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} !important;
    width: 100%;
    text-align: center;
    border-color: transparent;

    &:hover {
        color: white;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        background: #322f89;
    }
`;

const EmailInput = styled(Input)<{ error?: boolean }>`
    background: #f2f4f7 !important;
    height: 50px !important;
    border: ${({ error }) => (error ? 'solid 1px red' : 'none')} !important;
    padding: 12px !important;
    font-size: 16px !important;
`;

const InputLabel = styled(H3)`
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
`;

const ChangeFormState = styled(H3)`
    font-size: 16px;
    line-height: 29px;
    color: #5b636c;
    margin-top: 10px;
`;

const StyledLink = styled(ClickableLink)`
    cursor: pointer;
    color: #3799ff;
`;

const PolicyBottomText = styled(H3)`
    font-size: 16px;
    font-size: 13px;
    color: #5b636c;
    position: absolute;
    bottom: 40px;
    padding-inline: 20px;
    text-align: center;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        position: initial;
    }
`;

const LoginPrompt = styled.div`
    width: 100%;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyledForm = styled(Form)`
    width: inherit;
`;

const LoginContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

const REDIRECT_AFTER_AUTH_PREFIX = '_redirectAfterAuth_';

const AuthList: Auth[] = [
    {
        name: 'Google',
        icon: GIcon,
        type: AuthTypes.GOOGLE,
    },
    {
        name: 'Microsoft',
        icon: MIcon,
        type: AuthTypes.MICROSOFT,
    },
    {
        name: 'Slack',
        icon: SIcon,
        type: AuthTypes.SLACK,
    },
    {
        name: 'Okta',
        icon: OIcon,
        type: AuthTypes.OKTA,
    },
    {
        name: 'Ping Identity',
        icon: PIcon,
        type: AuthTypes.PING,
    },
];

enum PageAuthTypes {
    SIGNUP = 'signup',
    SIGNIN = 'signin',
}

interface rootScopeExtended extends IRootScopeService {
    stateData: {
        pageType: PageAuthTypes;
    };
}

const LoginPage: React.FC = () => {
    const environment = useAngularService('environment');
    const oauthHandler = useAngularService('oauthHandler');
    const tonkeanService = useAngularService('tonkeanService');
    const authService = useAngularService('authenticationService');
    const projectManager = useAngularService('projectManager');
    const $rootScope = useAngularService('$rootScope') as rootScopeExtended;
    const $state = useAngularService('$state');
    const $localStorage = useAngularService('$localStorage');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOktaLogin, setIsOktaLogin] = useState<boolean>(false);
    const [isPingLogin, setIsPingLogin] = useState<boolean>(false);
    const [thirdPartyError, setThirdPartyError] = useState<{ type: AuthTypes; message: string } | undefined>(undefined);
    const [emailLoginError, setEmailLoginError] = useState<string | undefined>(undefined);
    const formikRef = useRef<FormikProps<any>>(null);
    const [authCode, email, referralCode, authTypeState] = useGetStateParams<[string, string, string, string]>(
        'code',
        'email',
        'r',
        'state',
    );

    const { data: globalConfig } = useTonkeanService('getLoginOptions');
    const envDomain = globalConfig?.['FEATURE_ENV_DOMAIN_TO_OPEN_OKTA_LOGIN_DIRECTLY'] ?? null;
    const [shouldRedirectToNewPage, setShouldRedirectToNewPage] = useState<boolean>(false);

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (globalConfig) {
            setShouldRedirectToNewPage(globalConfig?.['FEATURE_OPEN_LOGIN_WITH_OKTA_SSO_IN_REDIRECT'] ?? false);
        }
    }, [globalConfig]);

    const pageType = $rootScope.stateData.pageType;

    const authWith3rdParty = async (authType: AuthTypes, authCode?: string) => {
        const name = AuthList.find((auth) => auth.type === authType)?.name;
        analyticsWrapper.track('Login oAuth Clicked', {
            category: `Login ${name}`,
            label: referralCode ? 'Referral' : null,
        });

        let data;
        try {
            switch (authType) {
                case AuthTypes.GOOGLE:
                    data = await googleAuth(authCode);
                    break;

                case AuthTypes.MICROSOFT:
                    data = await microsoftAuth(authCode);
                    break;

                case AuthTypes.SLACK:
                    data = await slackAuth(authCode);
                    break;

                case AuthTypes.OKTA:
                    data = await continueOktaAuth(authCode);
                    break;

                case AuthTypes.PING:
                    data = await pingIdentityAuth(authCode);
                    break;
            }

            postAuth(data);

            analyticsWrapper.track('Login oAuth Completed', {
                category: `Login ${name}`,
                label: referralCode ? 'Referral' : null,
            });
        } catch (error: any) {
            const errorMessage = error?.data?.data?.error?.message;
            if (errorMessage && errorMessage !== 'Authentication window closed')
                setThirdPartyError({
                    type: authType,
                    message: error?.data?.data?.error?.message,
                });
            analyticsWrapper.track('Login oAuth Failed', {
                category: `Login ${name}`,
            });
        }
    };

    const googleAuth = async (authCode?: string) => {
        const redirectUri = getRedirectUri();
        const code =
            authCode ||
            ((await oauthHandler.google(
                environment.integrationKeysMap.google,
                'profile%20email',
                redirectUri,
                '',
            )) as string);

        analyticsWrapper.track('Login oAuth Approved', {
            category: 'Login Google',
            label: referralCode ? 'Referral' : null,
        });

        return tonkeanService.loginOAuth('google', code, environment.isLocal, redirectUri, referralCode, null, null);
    };

    const microsoftAuth = (authCode?: string) => {
        const redirectUri = environment.defaultRedirectUri;

        if (authCode) {
            analyticsWrapper.track('Login oAuth from com', {
                category: 'Login Microsoft',
                label: referralCode ? 'Referral' : null,
            });
            return tonkeanService.loginOAuth(
                'microsoft',
                authCode,
                environment.isLocal,
                redirectUri,
                referralCode,
                null,
                null,
            );
        } else {
            const scope = 'https%3A%2F%2Fgraph.microsoft.com%2Fuser.read';
            location.href = `${environment.integrationKeysMap.microsoftAuthUri}/${environment.integrationKeysMap.microsoftTenant_login}/oauth2/v2.0/authorize?client_id=${environment.integrationKeysMap.microsoftClientId_login}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&state=com_msft&scope=${scope}`;
        }
    };

    const slackAuth = async (authCode?: string) => {
        const code =
            authCode ||
            ((await oauthHandler.slack(
                environment.integrationKeysMap.slack,
                '',
                'users:read,users:read.email',
                true,
            )) as string);

        analyticsWrapper.track('Login oAuth Approved', {
            category: 'Login Slack',
            label: referralCode ? 'Referral' : null,
        });

        return tonkeanService.loginOAuth(
            'slack',
            code,
            environment.isLocal,
            null,
            referralCode,
            null,
            SlackAuthenticationType.OAUTH2,
        );
    };

    const pingIdentityAuth = async (code?: string) => {
        if (!code) {
            return setIsPingLogin(true);
        }

        const identityProviderUrl = getIDPUri(`_ping_`);
        const redirectUri = getRedirectUri();

        analyticsWrapper.track('Login oAuth Approved', {
            category: 'Login PingIdentity',
            label: referralCode ? 'Referral' : null,
        });

        return tonkeanService.loginOAuth(
            'ping_identity',
            code,
            environment.isLocal,
            redirectUri,
            referralCode,
            identityProviderUrl,
            null,
        );
    };

    const getRedirectUrlAfterAuthBeforeLogin = () => {
        if (shouldRedirectToNewPage && location.search?.includes('redirect=')) {
            const redirectArray = location.search?.split('redirect=');
            if (redirectArray) {
                const redirect = redirectArray[1] || '';
                return decodeURIComponent(redirect)?.replaceAll('&', '_');
            }
        }
        return null;
    };

    const getRedirectUrlAfterAuthAfterLogin = () => {
        if (shouldRedirectToNewPage && authTypeState?.includes(REDIRECT_AFTER_AUTH_PREFIX)) {
            const redirectArray = authTypeState.split(REDIRECT_AFTER_AUTH_PREFIX);
            if (redirectArray) {
                const redirectUrl = redirectArray[1] || '';
                return decodeURIComponent(redirectUrl)?.replaceAll('_', '&');
            }
        }
        return null;
    };

    const getIDPUri = (prefix: string) => {
        const idpUri = authTypeState.split(prefix)[1];
        if (idpUri?.includes(REDIRECT_AFTER_AUTH_PREFIX) && idpUri?.split(REDIRECT_AFTER_AUTH_PREFIX)) {
            return idpUri?.split(REDIRECT_AFTER_AUTH_PREFIX)[0];
        }
        return idpUri;
    };

    const oktaAuth = async (oktaClientId: string, oktaUri: string) => {
        const redirectUri = environment.defaultRedirectUri;
        const redirectAfterAuth = getRedirectUrlAfterAuthBeforeLogin();

        const code = await oauthHandler.okta(
            oktaClientId,
            redirectUri,
            oktaUri,
            'openid profile email',
            shouldRedirectToNewPage,
            redirectAfterAuth,
        );
        analyticsWrapper.track('Login oAuth Approved', {
            category: 'Login Okta',
            label: referralCode ? 'Referral' : null,
        });

        const data = await tonkeanService.loginOAuth(
            'okta',
            code,
            environment.isLocal,
            redirectUri,
            referralCode,
            oktaUri,
            null,
        );

        analyticsWrapper.track('Login oAuth Completed', {
            category: `Login Okta`,
            label: referralCode ? 'Referral' : null,
        });

        postAuth(data);
    };

    const pingAuth = async (pingClientId: string, pingUri: string, authSuffix: string) => {
        const redirectUri = environment.defaultRedirectUri;
        const redirectAfterAuth = getRedirectUrlAfterAuthBeforeLogin();

        const code = await oauthHandler.pingIdentity(
            pingUri,
            authSuffix,
            pingClientId,
            redirectUri,
            shouldRedirectToNewPage,
            redirectAfterAuth,
        );
        analyticsWrapper.track('Login oAuth Approved', {
            category: 'Login Ping',
            label: referralCode ? 'Referral' : null,
        });

        const data = await tonkeanService.loginOAuth(
            'ping_identity',
            code,
            environment.isLocal,
            redirectUri,
            referralCode,
            pingUri,
            null,
        );

        analyticsWrapper.track('Login oAuth Completed', {
            category: `Login Ping`,
            label: referralCode ? 'Referral' : null,
        });

        postAuth(data);
    };

    const continueOktaAuth = async (code?: string) => {
        if (!code) {
            return setIsOktaLogin(true);
        }
        const redirectUri = getRedirectUri();
        const oktaUri = getIDPUri('_okta_');

        return tonkeanService.loginOAuth('okta', code, environment.isLocal, redirectUri, referralCode, oktaUri, null);
    };

    const postAuth = (data: any) => {
        // Save additional data from slack to the user object.
        data.user.additionalData = data.additionalData;
        authService.finishAuthentication(data.user, data.accessToken, data.isNewUser, true);

        const authenticationCompleteRedirectUrl = getRedirectUrlAfterAuthAfterLogin();
        const redirectAfterAuth = Boolean(authenticationCompleteRedirectUrl);

        projectManager.getProjects(true).then(function () {
            if (!projectManager.projects?.length) {
                $state.go('loginCreate');
            } else {
                authService.handleAuthenticationComplete(false, redirectAfterAuth);
            }
        });
    };

    const onSubmit = async (values, { setErrors, resetForm }) => {
        if (values.email) {
            setIsLoading(true);

            analyticsWrapper.track('Login', {
                category: 'Login Email',
                label: referralCode ? 'Referral' : null,
            });

            $localStorage.LastLoginWithEmailAddress = values.email;

            try {
                await tonkeanService.sendLoginEmail(values.email, null, referralCode);
                setEmailLoginError('');
                $rootScope.$broadcast('emailLoginSent', values.email);
                $state.go('loginSent', null, {});
            } catch (error: any) {
                const errorMessage = error?.data?.data?.error?.message;
                setEmailLoginError(errorMessage);
                setErrors({ email: errorMessage });
                setIsLoading(false);
            }
        }
    };

    const init = () => {
        if (!authCode) {
            setIsLoading(false);
        }
        if (email) {
            formikRef.current?.submitForm();
        } else if (authCode) {
            const code = authCode.replaceAll(new RegExp('dot', 'g'), '.');
            if (authTypeState === 'com_google' || authTypeState === 'ios_google') {
                authWith3rdParty(AuthTypes.GOOGLE, code);
            } else if (authTypeState === 'com_msft') {
                authWith3rdParty(AuthTypes.MICROSOFT, code);
            } else if (authTypeState.includes('okta')) {
                authWith3rdParty(AuthTypes.OKTA, code);
            } else if (authTypeState.includes('_ping_')) {
                authWith3rdParty(AuthTypes.PING, code);
            } else {
                authWith3rdParty(AuthTypes.SLACK, code);
            }
        }
    };

    const validationSchema = yup.object().shape({
        email: yup.string().email('Invalid email').required('Required'),
    });

    const title = pageType === PageAuthTypes.SIGNIN ? 'Sign In' : 'Sign Up';
    const changePageTypeText =
        pageType === PageAuthTypes.SIGNIN ? (
            <ChangeFormState>
                Not a member? <StyledLink state="signup">Sign Up</StyledLink>
            </ChangeFormState>
        ) : (
            <ChangeFormState>
                Already a member? <StyledLink state="login">Sign In</StyledLink>
            </ChangeFormState>
        );

    return (
        <LoginContainer>
            {isLoading ? (
                <LoadingCircle white />
            ) : (
                <AuthPageLayoutWrapper>
                    {isOktaLogin ? (
                        <OktaLoginPrompt
                            oktaAuth={oktaAuth}
                            backToLogin={() => setIsOktaLogin(false)}
                            environmentOktaDomain={envDomain}
                        />
                    ) : isPingLogin ? (
                        <PingLoginPrompt
                            pingAuth={pingAuth}
                            backToLogin={() => setIsPingLogin(false)}
                            environmentPingDomain={envDomain}
                        />
                    ) : (
                        <LoginPrompt>
                            <TnkLogoStyled />

                            <LoginTitle>{title}</LoginTitle>

                            <OtherLoginButtons
                                error={thirdPartyError}
                                onClick={authWith3rdParty}
                                authTypes={AuthList.filter((auth) =>
                                    environment.loginTypes.includes(AuthTypes[auth.type]),
                                )}
                            />

                            <EmailLoginSeperator>
                                <Hr />
                                <OrSeperatorSpan>OR</OrSeperatorSpan>
                                <Hr />
                            </EmailLoginSeperator>

                            <Formik
                                innerRef={formikRef}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                initialValues={{ email }}
                            >
                                {({ errors, touched }) => (
                                    <StyledForm>
                                        <InputLabel>Use your work email</InputLabel>
                                        <Field touchedOnlyErrors={false}>
                                            <EmailInput
                                                error={!!errors['email']}
                                                data-automation="tkn-input-login-using-email"
                                                name="email"
                                                placeholder="youremail@gmail.com"
                                            />
                                        </Field>
                                        <SubmitButton
                                            data-automation="tkn-btn-login-using-email"
                                            type="submit"
                                            disabled={!!errors['email'] || isLoading}
                                        >
                                            {isLoading ? <LoadingCircle white /> : title}
                                        </SubmitButton>
                                        {!!emailLoginError && (
                                            <LoginErrorMessage data-automation="email-login-error-message">
                                                {emailLoginError}
                                            </LoginErrorMessage>
                                        )}
                                    </StyledForm>
                                )}
                            </Formik>

                            {changePageTypeText}

                            {pageType === PageAuthTypes.SIGNUP && (
                                <PolicyBottomText>
                                    By signing up, you agree to our{' '}
                                    <StyledLink href="https://tonkean.com/terms/" openInNewTab>
                                        terms of services
                                    </StyledLink>{' '}
                                    and{' '}
                                    <StyledLink href="https://tonkean.com/privacy/" openInNewTab>
                                        privacy policy
                                    </StyledLink>
                                    .
                                </PolicyBottomText>
                            )}
                        </LoginPrompt>
                    )}
                </AuthPageLayoutWrapper>
            )}
        </LoginContainer>
    );
};

export default LoginPage;
