import React, { useCallback } from 'react';

import useProjectIntegrationEntityFetcherBreadCrumbsConfiguration from '../hooks/useProjectIntegrationEntityFetcherBreadCrumbsConfiguration';

import { Breadcrumbs } from '@tonkean/infrastructure';
import type { ProjectIntegration, ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntitySummaryWithIsImported } from '@tonkean/tonkean-entities';

interface Props {
    projectIntegration: ProjectIntegration;
    entity: ProjectIntegrationEntity;
    entitiesSummary: ProjectIntegrationEntitySummaryWithIsImported[];
    projectIntegrationAction?: ProjectIntegrationAction;
    onExit?: () => Promise<boolean>;
}

const ProjectIntegrationEntityFetcherBreadCrumbs: React.FC<Props> = ({
    projectIntegration,
    entity,
    entitiesSummary,
    projectIntegrationAction,
    onExit: onExitProp,
}) => {
    // Alert about unsaved changes.
    const onExit = useCallback(
        async (destinationEntityId: string) => {
            if (destinationEntityId !== entity?.id && onExitProp) {
                return await onExitProp();
            }
            return true;
        },
        [entity?.id, onExitProp],
    );

    const configuration = useProjectIntegrationEntityFetcherBreadCrumbsConfiguration(
        projectIntegration,
        entity,
        projectIntegrationAction,
        onExit,
    );
    return <Breadcrumbs settings={configuration} />;
};

export default ProjectIntegrationEntityFetcherBreadCrumbs;
