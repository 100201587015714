<div class="margin-top flex-xs-wrap">
    <div ng-if="data.field.isAssociated && data.integration" class="flex margin-top">
        <i class="fa fa-check common-color-primary margin-right-xs margin-top-xxs"></i>
        <div class="flex-grow">
            <span class="common-color-grey">
                Match found in {{ data.filter.source }} with [title] - "{{ data.connectedItem.id }}".
            </span>
        </div>
    </div>

    <div ng-if="!data.field.isAssociated" class="flex info-box">
        <i class="fa fa-times common-color-danger margin-right margin-top-xxs"></i>
        <div class="flex-grow common-color-dark-grey">
            Couldn't find a match for "{{ data.initiative.title }}" using the configured SQL statement. Manually match
            it by changing the value below:
        </div>
    </div>

    <div class="flex margin-top-lg">
        <span
            class="
                flex-no-shrink flex-self-start
                create-field-definition-modal-label
                margin-top-xs margin-right
                text-right
            "
        >
            Field SQL:
        </span>
        <pre class="flex-grow common-size-xxxxs common-text-wrap">{{ data.field.fieldDefinition.definition.SQL }}</pre>
    </div>

    <div class="flex-vmiddle common-size-sm margin-top-lg">
        <div ng-if="data.field.isAssociated && data.integration">
            <button type="button" class="btn btn-secondary" ng-click="enterEditMode()" ng-show="!editing">
                Edit value
            </button>
        </div>

        <span
            ng-show="editing || !data.field.isAssociated"
            class="flex-no-shrink create-field-definition-modal-label margin-bottom-xs margin-right text-right"
        >
            Value:
        </span>

        <input
            type="text"
            id="fieldExternalIdInput"
            name="fieldExternalIdInput"
            ng-show="editing || !data.field.isAssociated"
            class="form-control"
            ng-model="data.item.id"
            autocomplete="off"
            placeholder="Enter a custom value for [title]"
            required
        />
    </div>
</div>
<hr />
