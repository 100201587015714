<div>
    <!-- Close button -->
    <div class="common-close-btn mod-big mod-with-text margin-normal" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <!-- Menu bar -->
    <div class="initiative-menu-bar initiative-view-padding-h flex-vmiddle">
        <!-- Back / Close button -->
        <button
            type="button"
            class="btn btn-sm btn-primary common-bold margin-right-xs"
            ng-if="!fullMode"
            ng-click="$dismiss()"
        >
            Back
        </button>
    </div>

    <!-- Initiative state -->
    <tnk-update-initiative-state initiative-id="data.initiativeId"></tnk-update-initiative-state>
</div>
