import type { AngularServices } from 'angulareact';

import convertFieldDefinitionsToHistoryTableFields from './convertFieldDefinitionsToHistoryTableFields';
import LogicType from '../components/SingleWorkerRun/LogicType';
import type HistoryField from '../entities/HistoryField';

/** When the logic type is trigger, we don't populate the initiative with fields that are not part of the trigger's conditions,
    so we show this message instead of empty values.
 */
const VALUE_HISTORY_NOT_AVAILABLE = 'Value history is not available for this step';

/**
 * This function converts list of field definitions into a list of TableFields.
 *
 * @param fieldDefinitions - list of field definitions with isSpecialField and the fake intake source field of type ROOT.
 * @param initialInitiativeFieldsState - the initial state of the initiative.
 * @param finalInitiativeFieldsState - the final state of the initiative.
 * @param couldHaveChanged - does the action preformed could have changed the values? (for example, if it's a custom trigger, it can't).
 */
function getInitiativeTableFieldsWithChangeValues(
    fieldDefinitions: any[],
    initialInitiativeFieldsState,
    finalInitiativeFieldsState,
    couldHaveChanged: boolean,
    workFlowVersionId: string | undefined,
    customTriggerManager: AngularServices['customFieldsManager'],
    logicType?: LogicType,
): HistoryField[] {
    return convertFieldDefinitionsToHistoryTableFields(
        fieldDefinitions,
        (fieldDefinition) => {
            return (
                initialInitiativeFieldsState?.fieldIdentifierToValueInformationMap[fieldDefinition.id]?.fieldValues
                    .map((fieldValueObject) => fieldValueObject.value)
                    .join(', ') || (logicType === LogicType.TRIGGER ? VALUE_HISTORY_NOT_AVAILABLE : undefined)
            );
        },
        (fieldDefinition) => {
            return (
                finalInitiativeFieldsState?.fieldIdentifierToValueInformationMap[fieldDefinition.id]?.fieldValues
                    .map((fieldValueObject) => fieldValueObject.value)
                    .join(', ') || (logicType === LogicType.TRIGGER ? VALUE_HISTORY_NOT_AVAILABLE : undefined)
            );
        },
        couldHaveChanged,
        workFlowVersionId,
        customTriggerManager,
    );
}

export default getInitiativeTableFieldsWithChangeValues;
