import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ModFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.MOD;
    override readonly sign = 'Mod';
    override readonly displayName = 'Mod';
    override readonly description = 'Will return the remainder after a division operation.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'DIVIDEND',
            dataType: FieldType.Number,
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'DIVISOR',
            dataType: FieldType.Number,
        },
    ];
}
