import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { LoadingCircle, Modal, SearchBox, TextEllipsis, XCloseButton } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import type { ExternalEntity } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ROW_HEIGHT = '40px';

const ModalDescription = styled.span`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    font-weight: 400;
`;

const NoEntitiesFound = styled.span`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    font-weight: 400;
    text-align: center;
    width: 100%;
    margin-top: 20px;
`;

const HeaderWrapper = styled.h2`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.XXLARGE_20};
    font-weight: 400;
    margin: 15px 15px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ListHeader = styled.div`
    color: ${Theme.colors.gray_800};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid ${Theme.colors.gray_400};
    padding-left: 20px;
`;

const ListItemsWrapper = styled.div`
    overflow-y: auto;
`;

const HeaderNameAndIconWrapper = styled.div`
    display: inline-flex;
    align-items: center;
`;

const StyledProjectIntegrationIcon = styled(ProjectIntegrationIcon)`
    margin-right: 8px;
`;

const ListItemWrapper = styled.div`
    width: 100%;
    padding-left: 20px;
    line-height: ${ROW_HEIGHT};
    cursor: pointer;
    color: ${Theme.colors.gray_700};
    display: inline-flex;
    font-weight: 400;
    border-bottom: 1px solid ${Theme.colors.gray_300};

    text-transform: capitalize;
    &:hover {
        background-color: ${Theme.colors.gray_300};
    }
`;

const ListItemNameWrapper = styled.button`
    flex-grow: 1;
    background-color: transparent;
    text-align: left;
    border: none;
    padding: 0;
`;

const StyledXCloseButton = styled(XCloseButton)`
    margin-left: auto;
    position: absolute;
    right: 15px;
    top: 15px;
`;

const ViewDataButton = styled.button`
    color: ${Theme.colors.primary};
    line-height: 12px;
    border: none;
    margin-left: auto;
    margin-right: 15px;
    background-color: transparent;

    &:hover {
        text-decoration: underline;
    }
`;

const StyledModal = styled(Modal)`
    height: 600px;
`;

interface Props {
    isOpen: boolean;
    onCancel(): void;
    projectIntegration: ProjectIntegration;
    entityName: string;

    onEntityClicked: (fieldIdToValueRecord: Record<string, string>) => void;
}

const ImportEntityFieldsValueModal: React.FC<Props> = ({
    isOpen,
    onCancel,
    projectIntegration,
    entityName,
    onEntityClicked,
}) => {
    // Input search box value.
    const [searchValue, setSearchValue] = useState<string>('');

    // region Angular services.
    const projectManager = useAngularService('projectManager');
    const integrations = useAngularService('integrations');
    const modal = useAngularService('modal');
    // endregion

    // Lazy request for getting specific external activity.
    // use getExternalEntityValues instead.
    const [, getExternalActivityById] = useLazyTonkeanService('getExternalActivityById');

    // ViewData option for getting fields of entity.
    const viewData = useMemo(
        () => ({
            entity: entityName,
            integrationType: projectIntegration.integration.integrationType,
            integrationUniqType: projectIntegration.integration.integrationUniqueType,
            query: { type: 'And', filters: [] },
            timeRangeFilter: {
                range: 'NoRange',
                timeFieldName: '',
            },
        }),
        [
            entityName,
            projectIntegration.integration.integrationType,
            projectIntegration.integration.integrationUniqueType,
        ],
    );

    // Request for getting all the entities for current project integration.
    const { data: externalEntitiesList, loading } = useTonkeanService(
        'previewSyncView',
        projectManager?.project?.id,
        projectIntegration.id,
        viewData,
        'Custom',
    );

    // make a request for getting specific external activity.
    const getExternalEntityValues = useCallback(
        async (externalActivityRealEntityId: string) => {
            return getExternalActivityById(
                projectManager?.project?.id,
                projectIntegration.id,
                externalActivityRealEntityId,
                true,
            );
        },
        [getExternalActivityById, projectIntegration.id, projectManager?.project?.id],
    );

    // Open openFieldInspectModal for a specific entity id.
    const openModalViewData = useCallback(
        async (id: string) => {
            const externalEntityValues = await getExternalEntityValues(id);

            modal.openFieldInspectModal(
                projectIntegration,
                entityName,
                null,
                null,
                null,
                null,
                externalEntityValues,
                null,
                null,
                null,
                null,
                null,
                null,
                true,
                null,
                null,
                null,
                false,
            );
        },
        [entityName, getExternalEntityValues, modal, projectIntegration],
    );

    // Memo for filtered entities by search box.
    const filteredExternalEntities = useMemo(() => {
        const externalEntities = externalEntitiesList?.entities;
        if (externalEntities) {
            return externalEntities.filter((entity) => entity.name.toLowerCase().includes(searchValue.toLowerCase()));
        }
        return [];
    }, [externalEntitiesList?.entities, searchValue]);

    // We will iterate over the external fields and construct a dictionary between the field name to its values.
    const formatExternalFields = useCallback(
        (externalEntityFieldIdToExternalEntityValue: Record<string, string>) => {
            // Adding entity name before each key because that's how the action parameter id implemented
            const entries = Object.entries(externalEntityFieldIdToExternalEntityValue || {}).map(([key, value]) => {
                return [`${entityName}-${key}`, value];
            });

            return Object.fromEntries(entries);
        },
        [entityName],
    );

    // Memo for integrationName form the const integrations config.
    const integrationName = useMemo(() => {
        return (
            integrations?.getIntegrationsConfig()[projectIntegration.integration.integrationType.toLowerCase()]
                ?.displayName || projectIntegration.integration.integrationType.toLowerCase()
        );
    }, [integrations, projectIntegration.integration.integrationType]);

    // On user click on specific entity we getting original entity format the keys and call parent component onEntityClicked.
    const onSelectExternalEntity = useCallback(
        async (entityId: string) => {
            const externalEntityValues = await getExternalEntityValues(entityId);

            onEntityClicked(formatExternalFields(externalEntityValues?.originalEntity || {}));
        },
        [formatExternalFields, getExternalEntityValues, onEntityClicked],
    );

    return (
        <StyledModal onClose={onCancel} open={isOpen} size={700} fixedWidth>
            <StyledXCloseButton onClick={onCancel} size={ButtonSize.MEDIUM} />
            <HeaderWrapper>
                <ModalDescription>Import fields and test on entity param </ModalDescription>

                <HeaderNameAndIconWrapper>
                    <StyledProjectIntegrationIcon
                        width={20}
                        iconUrl={projectIntegration.iconUrl}
                        integrationType={projectIntegration.integration.integrationType}
                    />
                    {`${integrationName} ${entityName}`}
                </HeaderNameAndIconWrapper>

                <SearchBox
                    value={searchValue}
                    onChange={({ target }) => {
                        setSearchValue(target.value);
                    }}
                />
            </HeaderWrapper>

            <ListHeader>
                Pick {entityName} item ({filteredExternalEntities.length})
            </ListHeader>

            <ListItemsWrapper>
                {filteredExternalEntities.map((externalEntity: ExternalEntity) => {
                    return (
                        <ListItemWrapper key={externalEntity.id}>
                            <ListItemNameWrapper onClick={() => onSelectExternalEntity(externalEntity.id)}>
                                <TextEllipsis numberOfLines={2} tooltip>
                                    {externalEntity.name}
                                </TextEllipsis>
                            </ListItemNameWrapper>
                            <ViewDataButton onClick={() => openModalViewData(externalEntity.id)}>View</ViewDataButton>
                        </ListItemWrapper>
                    );
                })}
            </ListItemsWrapper>

            {loading && <LoadingCircle className="margin-auto margin-top-20" />}

            {!loading && filteredExternalEntities.length === 0 && <NoEntitiesFound>No Entities found</NoEntitiesFound>}
        </StyledModal>
    );
};

export default ImportEntityFieldsValueModal;
