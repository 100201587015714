import { AngularInjectorContext, useAngularService } from 'angulareact';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { IEdge, IGraphViewProps, INode } from 'react-digraph';
import { GraphView } from 'react-digraph';
import styled, { css } from 'styled-components';

import ProcessMapperEdgeArrow from './ProcessMapperEdgeArrow';
import processMapperGraphConfig from './processMapperGraphConfig';
import ProcessMapperGraphItem from './ProcessMapperGraphItem';
import ProcessMapperGraphJointNodeWrapper from './ProcessMapperGraphJointNodeWrapper';
import processMapperEdgeStyle from '../processMapperEdgeStyle';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { TonkeanIconDisplayInner } from '@tonkean/icon-picker';
import { useInitiativeExpressionServerEvaluation } from '@tonkean/infrastructure';
import { logicBlockTypes } from '@tonkean/logic-block-configs';
import type {
    Initiative,
    ProcessMapper,
    ProcessMapperEdge,
    ProcessMapperNode,
    ProcessMapperNodeStage,
    TonkeanExpressionDefinition,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import type { EvaluatedField, EvaluatedProcessMapperNode } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { classNames, EMPTY_ARRAY } from '@tonkean/utils';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    * {
        ${styledFocus}
    }
    ${processMapperEdgeStyle}
`;

const Icon = styled.div<{ width: number; height: number }>`
    background-size: contain !important;
    background: no-repeat;

    ${({ width, height }) => css`
        height: ${height}px;
        width: ${width}px;
    `}
`;

interface Props {
    processMapper: ProcessMapper;
    initiative: Initiative | undefined;
    graphRef: React.RefObject<React.Component<IGraphViewProps, any, any>>;
    selectedNode: ProcessMapperNode | ProcessMapperEdge | undefined;
    processMapperNodeToStage: Record<`PMNO${string}`, ProcessMapperNodeStage> | undefined;
    setSelectedNodeOrEdge: (node: ProcessMapperNode | ProcessMapperEdge | undefined) => void;
    processMapperNodes: ProcessMapperNode[];
    processMapperEdges: ProcessMapperEdge[];
    moveNode: (processMapperNode: ProcessMapperNode, x: number, y: number) => void;
    createProcessMapperEdge: (
        source: TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>,
        target: TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>,
    ) => void;
    readOnly?: boolean;
    background?: ((gridSize?: number | undefined) => any) | undefined;
    deleteProcessMapperEdge?: (edgeId: TonkeanId<TonkeanType.PROCESS_MAPPER_EDGE>) => Promise<void>;
    deleteProcessMapperNode?: (nodeId: TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>) => void;
    addJointNode?: (edge: ProcessMapperEdge) => void;
}

const ProcessMapperGraph: React.FC<Props> = ({
    processMapper,
    initiative,
    graphRef,
    selectedNode,
    processMapperNodeToStage,
    setSelectedNodeOrEdge,
    processMapperNodes,
    processMapperEdges,
    moveNode,
    createProcessMapperEdge,
    readOnly = false,
    background,
    deleteProcessMapperEdge,
    deleteProcessMapperNode,
    addJointNode,
}) => {
    // react-digraph renders components outside of the React AngularInjectorContext,
    // hence, we need to provide the AngularInjectorContext to the components rendered by react-digraph.
    const $injector = useContext(AngularInjectorContext);

    const expressions = useMemo<TonkeanExpressionDefinition[]>(() => {
        const expressionList: TonkeanExpressionDefinition[] = [];
        processMapperNodes.forEach((node) => {
            node.configuration?.title && expressionList.push(node.configuration?.title);
            if (node.configuration?.fields) {
                node.configuration.fields.forEach(
                    (field) => field?.expression && expressionList.push(field.expression),
                );
            }
        });
        return expressionList;
    }, [processMapperNodes]);

    const { values: evaluatedExpressions, loading: loadingExpressions } = useInitiativeExpressionServerEvaluation(
        expressions || [],
        initiative,
    );

    // React-Digraph optimizes rendering by requiring a new list of nodes to detect changes and re-renders only the modified nodes, ensuring optimal performance.
    const [evaluatedNodes, setEvaluatedNodes] = useState<EvaluatedProcessMapperNode[]>([]);

    const projectIntegrationCache = useAngularService('projectIntegrationCache');

    const [, getProjectIntegrationById] = useLazyAsyncMethod(projectIntegrationCache, 'getProjectIntegrationById');

    const getFieldIcon = useCallback(
        async (field) => {
            // Note: This function is a workaround and may not be the optimal solution for achieving the desired results.
            // We require integration icons, but the regular icon component cannot be utilized due to being out of context within the GraphView.
            let projectIntegration;

            if (field.icon?.integrationId) {
                projectIntegration = await getProjectIntegrationById(field.icon.integrationId);
            }

            if (projectIntegration) {
                const iconUrl = projectIntegration?.iconUrl;
                const backgroundImage = iconUrl ? `url('${iconUrl}')` : undefined;
                const className = classNames(
                    'initiative-integration-icon',
                    !backgroundImage &&
                        projectIntegration.integrationType &&
                        `mod-${projectIntegration.integrationType.toLowerCase()}`,
                );
                return <Icon className={className} style={{ backgroundImage }} width={20} height={20} />;
            } else if (field.icon?.integrationName) {
                const className = classNames(
                    'initiative-integration-icon',
                    `mod-${field.icon.integrationName.toLowerCase()}`,
                );

                return <Icon className={className} width={20} height={20} />;
            } else {
                return (
                    <TonkeanIconDisplayInner
                        icon={field.icon}
                        integrationTypesForIcon={undefined}
                        showMatchedEntityIcon={undefined}
                        customTriggerTypes={EMPTY_ARRAY}
                        logicBlocks={logicBlockTypes}
                        dontShowEmptyIcon
                    />
                );
            }
        },
        [getProjectIntegrationById],
    );

    useEffect(() => {
        // In the absence of initiative, we construct the evaluated nodes using the original expressions.
        // When initiative exists we re-render nodes only when the evaluated expressions are available to prevent 'flickering' with the original expressions.
        if (!loadingExpressions && (!initiative || expressions.length === 0 || evaluatedExpressions.length > 0)) {
            const evaluatedNodes = processMapperNodes.map((node) => {
                let titleValue = 'Title';
                const fields: EvaluatedField[] = new Array(node.configuration?.fields?.length || 0);
                if (node.configuration?.title?.originalExpression) {
                    titleValue = node.configuration.title.originalExpression;
                    const expressionIndex = expressions.findIndex((exp) => exp.originalExpression === titleValue);
                    titleValue = evaluatedExpressions[expressionIndex] || '';
                }
                if (node.configuration?.fields) {
                    node.configuration.fields.forEach((field, index) => {
                        let value: string = '';
                        if (field.expression?.originalExpression) {
                            value = field.expression.originalExpression;
                            const expressionIndex = expressions.findIndex((exp) => exp.originalExpression === value);
                            value = evaluatedExpressions[expressionIndex] || '';
                        }
                        if (field.icon) {
                            getFieldIcon(field).then((icon) => {
                                const evaluatedField: EvaluatedField = { text: value, icon };
                                fields[index] = evaluatedField;
                            });
                        } else {
                            const evaluatedField: EvaluatedField = { text: value };
                            fields[index] = evaluatedField;
                        }
                    });
                }

                const stage = processMapperNodeToStage?.[node.id];
                return {
                    ...node,
                    evaluatedTitle: titleValue,
                    evaluatedFields: fields,
                    evaluatedStage: stage,
                } as EvaluatedProcessMapperNode;
            });
            setEvaluatedNodes(evaluatedNodes);
        }
    }, [
        evaluatedExpressions,
        expressions,
        getFieldIcon,
        initiative,
        loadingExpressions,
        processMapperNodeToStage,
        processMapperNodes,
    ]);

    // For each evaluated edge, we attach a text that appears only when the edge is selected (focused).
    // This text acts as a button labeled 'Add joint', which allows splitting the edge into two separate edges
    // with a 'joint' node placed between them.
    const evaluatedEdges = useMemo(() => {
        return processMapperEdges.map((edge) => {
            const targetNode = processMapperNodes.find((node) => node.id === edge.target);
            const isTargetJointNode = targetNode?.type === 'JOINT' || targetNode?.type === 'DISPLAYABLE_JOINT';

            const handleText = (
                <tspan y={-25}>
                    <a href="#" onClick={() => addJointNode?.(edge)}>
                        + Add joint
                    </a>
                </tspan>
            );

            return {
                ...edge,
                type: isTargetJointNode ? 'TARGET_JOINT' : edge.type,
                handleText,
            } as ProcessMapperEdge;
        });
    }, [addJointNode, processMapperEdges, processMapperNodes]);

    // This function is used to add a CSS class to the edge, effectively removing the arrow from the end of the edge.
    // It takes an element ID as a parameter, finds the corresponding element, and adds the 'no-path' class to a child element with the class 'edge-path'.
    const removePathEnd = useCallback((elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            const childElement = element.querySelector('.edge-path');
            if (childElement) {
                childElement.classList.add('no-path');
            }
        }
    }, []);

    return (
        <Wrapper>
            <GraphView
                nodeKey="id"
                key={processMapper.id}
                selected={selectedNode}
                renderNodeText={(data: EvaluatedProcessMapperNode, id: string | number, isSelected: boolean) => {
                    if (data.type === 'JOINT') {
                        return (
                            <AngularInjectorContext.Provider value={$injector}>
                                <ProcessMapperGraphJointNodeWrapper
                                    key={`node_${id}`}
                                    node={data}
                                    isSelected={isSelected}
                                />
                            </AngularInjectorContext.Provider>
                        );
                    }
                    if (data.type !== 'DISPLAYABLE_JOINT') {
                        return (
                            <AngularInjectorContext.Provider value={$injector}>
                                <ProcessMapperGraphItem
                                    key={`node_${id}`}
                                    node={data}
                                    isSelected={isSelected}
                                    isEditable={!readOnly}
                                />
                            </AngularInjectorContext.Provider>
                        );
                    }
                }}
                onSelectNode={(node: ProcessMapperNode & INode) => setSelectedNodeOrEdge(node)}
                afterRenderEdge={(id, element, edge) => {
                    if (edge.type === 'TARGET_JOINT') {
                        removePathEnd(`${id}-container`);
                    }
                }}
                renderDefs={() => <ProcessMapperEdgeArrow />}
                nodes={evaluatedNodes}
                edges={evaluatedEdges}
                edgeTypes={processMapperGraphConfig.edgeTypes}
                nodeTypes={processMapperGraphConfig.nodeTypes}
                nodeSubtypes={processMapperGraphConfig.nodeSubtypes}
                onUpdateNode={(node: ProcessMapperNode & INode) => moveNode?.(node, node.x, node.y)}
                onCreateEdge={(source, target) => createProcessMapperEdge(source.id, target.id)}
                ref={graphRef}
                readOnly={readOnly}
                allowMultiSelect={false}
                renderBackground={background}
                onDeleteEdge={(selectedEdge: ProcessMapperEdge & IEdge) => {
                    deleteProcessMapperEdge?.(selectedEdge.id).then(() => setSelectedNodeOrEdge(undefined));
                }}
                onDeleteNode={(nodeToDelete: ProcessMapperNode & INode) => {
                    if (nodeToDelete.type === 'JOINT') {
                        deleteProcessMapperNode?.(nodeToDelete.id);
                    }
                }}
                onSelectEdge={(selectedEdge: ProcessMapperEdge & IEdge) => setSelectedNodeOrEdge(selectedEdge)}
            />
        </Wrapper>
    );
};

export default ProcessMapperGraph;
