import cloneDeep from 'lodash.clonedeep';

import type { Project, WorkflowVersion, State } from '@tonkean/tonkean-entities';

/**
 * Get the most relevant states
 * @param workflowVersion
 * @param project
 */
function getProjectStates(workflowVersion: WorkflowVersion | undefined, project: Project): State[] {
    let states: State[];
    if (workflowVersion?.states?.length) {
        // If we are managing a workflow version's states.
        states = cloneDeep(workflowVersion.states) || [];
    } else {
        // We are managing the project's states.
        states = cloneDeep(project.states) || [];
    }

    // Make sure we don't show states with no labels.
    return removeNoLabelStates(states);
}

/**
 * Goes over the given states array, removes states without a label.
 * @param statesArray - the states array to clean from empty states.
 * @returns {Array}
 */
function removeNoLabelStates(statesArray: State[]): State[] {
    const statesToKeep: State[] = [];

    if (statesArray?.length) {
        for (const state of statesArray) {
            if (state?.label) {
                statesToKeep.push(state);
            }
        }
    }

    return statesToKeep;
}

export { getProjectStates };
