import styled from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

export const ChainOfEventsTitle = styled.div`
    position: relative;
    padding: 45px 50px 30px;
    font-size: ${FontSize.LARGE_16};
    line-height: 19px;
    color: ${Theme.current.palette.mainColors.gray_800};

    ::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 24px;
        width: 2px;
        background: linear-gradient(0deg, ${Theme.current.palette.mainColors.gray_300} 56.88%, transparent 100%);
    }
`;

export const SubTitle = styled.div`
    font-size: 12px;
    color: ${Theme.colors.gray_700};
`;
