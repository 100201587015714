import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function EnterpriseManageBoardsCtrl(
    $scope,
    $rootScope,
    $state,
    tonkeanService,
    authenticationService,
    enterpriseHelper,
) {
    $scope.pm = $rootScope.pm;

    $scope.data = {
        showEnterpriseBoards: true,
        enterprise: authenticationService.currentUser.enterprise,
        enterpriseBoards: [],

        // Loading states
        addingBoardToEnterpriseMap: {},
        removingBoardFromEnterpriseMap: {},
        goingToBoardMap: {},
    };

    $scope.init = function () {
        $scope.loadProjects();
    };

    /**
     * Remove board from enterprise
     */
    $scope.clearProjectEnterpriseId = function (projectId) {
        $scope.data.removingBoardFromEnterpriseMap[projectId] = true;
        enterpriseHelper
            .removeProjectFromEnterpriseById($scope.data.enterprise.id, projectId)
            .then(() => {
                $scope.loadProjects();
            })
            .catch((error) => {
                $scope.error = error;
            })
            .finally(() => {
                delete $scope.data.removingBoardFromEnterpriseMap[projectId];
            });
    };

    $scope.selectProject = function (project) {
        $scope.data.goingToBoardMap[project.id] = true;

        tonkeanService
            .getProjectById(project.id)
            .then(() => {
                // User has permissions for this project, send him there.
                $state.go(
                    'product.board',
                    {
                        filter: 'all',
                        projectId: project.id,
                        st: null,
                    },
                    { reload: true },
                );
            })
            .catch((error) => {
                if (error && error.status === 404) {
                    $scope.mboxData = {
                        title: 'No permissions for board ',
                        body: `You don't have permissions for the board: ${project.name}. Please ask ${project.creator.name} for permissions.`,
                        cancelLabel: 'Got it',
                        windowClass: 'mod-danger',
                    };

                    $rootScope.modal
                        .openMessageBox({
                            scope: $scope,
                            size: 'md',
                            windowClass: 'mod-danger',
                        })
                        .result.then(() => {});
                } else {
                    $rootScope.$emit('alert', {
                        type: 'error',
                        msg: 'Could not load project due to an error. Please try again in a little while.',
                    });
                }
            })
            .finally(() => {
                $scope.data.goingToBoardMap[project.id] = false;
            });
    };

    /**
     * Add board to enterprise.
     */
    $scope.setProjectEnterpriseId = function (projectId) {
        $scope.data.addingBoardToEnterpriseMap[projectId] = true;
        enterpriseHelper
            .addProjectToEnterpriseById($scope.data.enterprise.id, projectId)
            .then(() => {
                $scope.loadProjects();
            })
            .catch((error) => {
                $scope.data.error = error;
            })
            .finally(() => {
                delete $scope.data.addingBoardToEnterpriseMap[projectId];
            });
    };

    /**
     * Load all regular projects and enterprise projects.
     */
    $scope.loadProjects = function () {
        $scope.data.loadingProjects = true;
        $scope.pm.getProjects(true).finally(function () {
            if (!$scope.pm.projects.length) {
                $state.go('loginCreate', null, { location: 'replace' });
            } else {
                $scope.data.loadingProjects = false;
            }
        });

        $scope.pm
            .getEnterpriseProjects($scope.data.enterprise.id)
            .then((data) => {
                $scope.data.enterpriseBoards = data;
            })
            .catch((error) => {
                $scope.data.error = error;
            });
    };

    $scope.init();
}

export default angular
    .module('tonkean.app')
    .controller('EnterpriseManageBoardsCtrl', lateConstructController(EnterpriseManageBoardsCtrl));
