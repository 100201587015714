import React from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import Section from '../../../components/Section';
import { WidgetInlineActionsSelector } from '../../CommonWidgetConfiguration';
import type { InnerItemWidgetConfiguration } from '../../entities';
import FilterAndSortItems from '../../InnerItemsWidgetModule/components/configurationEditorSettingsComponents/FilterAndSortItems';
import ItemCreationButtonCreateForm from '../../InnerItemsWidgetModule/components/configurationEditorSettingsComponents/ItemCreationButtonCreateForm';
import ItemInterfaceSelectorContainer from '../../InnerItemsWidgetModule/components/configurationEditorSettingsComponents/ItemInterfaceSelectorContainer';
import TableSize from '../../InnerItemsWidgetModule/components/configurationEditorSettingsComponents/RowHeightSelector';
import SelectCreateForm from '../../InnerItemsWidgetModule/components/configurationEditorSettingsComponents/SelectCreateForm';
import StyledWidgetConfigurationToggle from '../../InnerItemsWidgetModule/components/configurationEditorSettingsComponents/StyledWidgetConfigurationToggle';
import ItemsWidgetConfigurationEditorFieldSelector from '../../InnerItemsWidgetModule/components/ItemsWidgetConfigurationEditorFieldSelector';
import ItemsWidgetConfigurationEditorInterfaceSelector from '../../InnerItemsWidgetModule/components/ItemsWidgetConfigurationEditorInterfaceSelector';

import type { FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const StyledTableSize = styled(TableSize)`
    margin-bottom: 8px;
`;

interface Props {
    configuration: InnerItemWidgetConfiguration;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    fieldDefinitions: FieldDefinition[] | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
    isLoadingFieldDefinitions: boolean;
}

const ModuleItemsWidgetAgGridConfigurationEditor: React.FC<Props> = ({
    configuration,
    groupId,
    fieldDefinitions,
    workflowVersionId,
    isLoadingFieldDefinitions,
}) => {
    return (
        <>
            <ItemInterfaceSelectorContainer>
                <ConfigurationSectionTitle>Items</ConfigurationSectionTitle>

                <StyledTableSize />

                {groupId && workflowVersionId && (
                    <>
                        <ItemCreationButtonCreateForm />

                        <SelectCreateForm
                            itemCreationType={configuration.itemCreationType}
                            workflowVersionId={workflowVersionId}
                            groupId={groupId}
                        />

                        <ItemsWidgetConfigurationEditorInterfaceSelector
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                        />

                        <FilterAndSortItems groupId={groupId} workflowVersionId={workflowVersionId} />

                        <StyledWidgetConfigurationToggle
                            title="Display advanced filters"
                            toolTip="Display advanced filters option at the top of the widget"
                            name="configuration.displayAdvancedFilters"
                        />

                        <StyledWidgetConfigurationToggle title="Emphasize Text" name="configuration.emphasizeText" />
                    </>
                )}
            </ItemInterfaceSelectorContainer>

            {workflowVersionId && (
                <>
                    <ItemsWidgetConfigurationEditorFieldSelector
                        configuration={configuration}
                        fieldDefinitions={fieldDefinitions}
                        initiative={undefined}
                        workflowVersionId={workflowVersionId}
                        fixedOrderForSpecialFields={false}
                        supportCardsViewFields={false}
                    />
                    <Section>
                        <WidgetInlineActionsSelector workflowVersionId={workflowVersionId} />
                    </Section>
                </>
            )}
        </>
    );
};

export default ModuleItemsWidgetAgGridConfigurationEditor;
