function ImportWebhookDataModalCtrl(
    $scope,
    $rootScope,
    $timeout,
    $http,
    modal,
    tonkeanService,
    csvHelper,
    projectIntegration,
    incomingWebhook,
) {
    $scope.pm = $rootScope.pm;

    $scope.data = {
        result: {
            include: {},
        },
        mapping: {},
        incomingWebhook,
        projectIntegration,
        idFieldPathName: incomingWebhook.definition.idFieldPath.name || null,
        titleFieldPathName: incomingWebhook.definition.titleFieldPath.name || null,
    };

    if (!$scope.data.incomingWebhook.definition.idFieldPath.name) {
        // means they started from here even before server got any mapping / file
        $scope.data.stage = 'initial';
    }

    $scope.initialImport = function () {
        if (
            $scope.data.selectedIdColumn &&
            $scope.data.selectedIdColumn.length &&
            $scope.data.selectedTitleColumn &&
            $scope.data.selectedTitleColumn.length
        ) {
            $scope.data.initialSetupLoading = true;
            $scope.error = null;

            // create the first mapping
            for (let i = 0; i < $scope.data.table[0].length; i++) {
                const col = $scope.data.table[0][i];
                $scope.data.mapping[`Column-${i}`] = { name: col };
            }
            const definition = {};
            definition.idFieldPath = $scope.data.selectedIdColumn;
            definition.titleFieldPath = $scope.data.selectedTitleColumn;

            $scope.data.idFieldPathName = $scope.data.selectedIdColumn;
            $scope.data.titleFieldPathName = $scope.data.selectedTitleColumn;

            tonkeanService
                .updateIncomingWebhook(
                    $scope.data.incomingWebhook.id,
                    $scope.data.incomingWebhook.displayName,
                    definition,
                )
                .then(function () {
                    // now the server knows how to map items, we can send the file over.
                    $scope.import();
                })
                .catch(function (error) {
                    $scope.data.initialSetupLoading = false;
                    $scope.error = error.data;
                });
        }
    };

    $scope.import = function () {
        $scope.error = '';

        let idMapped;
        let titleMapped;
        // verify mapping
        for (let i = 0; i < $scope.data.table[0].length; i++) {
            const field = $scope.data.mapping[`Column-${i}`];
            if (field && field.name === $scope.data.idFieldPathName) {
                idMapped = true;
            }
            if (field && field.name === $scope.data.titleFieldPathName) {
                titleMapped = true;
            }
        }

        if (idMapped && titleMapped) {
            // do the import
            $scope.data.stage = 'importing';

            $scope.data.progress = 0;
            $scope.data.state = 'Uploading data...';

            updateProgress(0.1);
            uploadItems(1, 50);
        } else {
            $scope.error = `You must select one column for "${$scope.data.incomingWebhook.definition.idFieldPath.label}" and one column for "${$scope.data.incomingWebhook.definition.titleFieldPath.label}".`;
        }
    };

    /**
     * Fired when the user selects a new field option for one of the path fields.
     * @param selectedField - the new field option selected.
     * @param fieldIdentifier - the field name in the incoming webhook's definition.
     */
    $scope.onFieldOptionSelected = function (selectedField, selectedFieldIdentifier) {
        $scope.data.mapping[selectedFieldIdentifier] = selectedField;
    };

    $scope.openFieldInspectModal = function (modelKeyToUpdate) {
        // Parse the columns to a dict so the inspector knows how to use it.
        // We pass that data as staticData - a different mode the inspector knows how to use.
        const columnsDict = { data: {}, displayNameMap: {} };

        // create the first mapping
        for (let i = 0; i < $scope.data.table[0].length; i++) {
            columnsDict.data[i] = $scope.data.table[1][i];
            columnsDict.displayNameMap[i] = $scope.data.table[0][i];
        }

        modal.openFieldInspectModal(
            null,
            null,
            null,
            (field) => ($scope.data[modelKeyToUpdate] = $scope.data.table[0][field.name]),
            columnsDict,
        );
    };

    function uploadItems(startIndex, count) {
        let to = startIndex + count;
        if (to > $scope.data.table.length) {
            to = $scope.data.table.length;
        }

        const bulkItems = [];

        // go over the rows create a json for each and send in bulk
        for (let i = startIndex; i < to; i++) {
            const row = $scope.data.table[i];

            const item = getItemFromRow(row);
            if (item) {
                bulkItems.push(item);
            }
        }

        // send bulk, when done update progress and call next bulk
        $http.post($scope.data.incomingWebhook.url, bulkItems).finally(function () {
            updateProgress(to / $scope.data.table.length);

            if (to < $scope.data.table.length) {
                // more to go
                uploadItems(to, count);
            } else {
                // done!
                $scope.data.stage = 'completed';
                // $rootScope.$broadcast('newActivityUpdate');
            }
        });
    }

    function getItemFromRow(row) {
        const item = {};
        let idMapped;
        let titleMapped;

        for (const [i, value] of row.entries()) {
            const field = $scope.data.mapping[`Column-${i}`];
            if (field && field.name && value) {
                // build & set the field
                const nodes = field.name.split('__');
                let rootNode = item;
                for (let j = 0; j < nodes.length; j++) {
                    const node = nodes[j];
                    if (j < nodes.length - 1) {
                        // means there are more to go
                        if (!rootNode[node]) {
                            rootNode[node] = {};
                        }
                        rootNode = rootNode[node];
                    } else {
                        // means it's the last node
                        rootNode[node] = value;
                    }
                }

                // check if this is id or title
                if (field.name === $scope.data.idFieldPathName) {
                    idMapped = true;
                }
                if (field.name === $scope.data.titleFieldPathName) {
                    titleMapped = true;
                }
            }
        }

        if (idMapped && titleMapped) {
            return item;
        }

        return null;
    }

    function updateProgress(num) {
        $timeout(function () {
            $scope.data.progress = (num * 100).toFixed(0);
        });
    }

    /** ****** region: Import CSV ********/

    $scope.handleFileSelect = function (el) {
        console.log('file changed');
        $scope.error = null;

        const file = el.files[0];
        $scope.data.fileName = file.name;
        $scope.loadingFile = true;

        csvHelper.parseFile(
            file,
            (table, csvFile) => {
                $scope.$apply(function ($scope) {
                    $scope.data.file = csvFile;
                    $scope.data.table = table;
                    $scope.loadingFile = false;
                });
            },
            (error) => {
                $scope.$apply(function ($scope) {
                    $scope.loadingFile = false;
                    $scope.error = error;
                });
            },
        );
    };

    $scope.onFileChange = function (e) {
        console.log('calling handleFileSelect in the current scope');
        $scope.handleFileSelect(e.target);
    };

    $scope.clearFile = function () {
        $scope.data.file = null;
        $scope.data.table = null;
    };

    /** endregion */
}

export default angular.module('tonkean.app').controller('ImportWebhookDataModalCtrl', ImportWebhookDataModalCtrl);
