import ItemInterfaceActionsItemWidgetPackage from './ActionsWidgetModule/itemInterface/ItemInterfaceActionsItemWidgetPackage';
import SolutionSitePageActionsItemWidgetPackage from './ActionsWidgetModule/solutionSitePage/SolutionSitePageActionsItemWidgetPackage';
import { SolutionSiteAIChatWidgetPackage } from './AIChatWidget';
import { AIChatWidgetPackage } from './AIChatWidget';
import { CommentsWidgetPackage } from './CommentsAndTimelineWidgetsModule';
import { TimelineWidgetPackage } from './CommentsAndTimelineWidgetsModule';
import DetailedItemsWidgetPackage from './DetailedItemsWidgetModule/DetailedItemsWidgetPackage';
import { SolutionSitePageEmbedWidgetPackage } from './EmbedWidgetModule';
import { EmbedWidgetPackage } from './EmbedWidgetModule';
import { SolutionSiteFieldChartWidgetPackage } from './FieldChartWidget';
import { FieldChartWidgetPackage } from './FieldChartWidget';
import { SolutionSiteFieldsWidgetPackage } from './FieldsWidgetModule';
import { ItemInterfaceFieldsWidgetPackage } from './FieldsWidgetModule';
import { SolutionSitePageImageWidgetPackage } from './ImageWidgetModule';
import { ImageWidgetPackage } from './ImageWidgetModule';
import { ItemInterfaceIncludeInterfaceWidgetPackage } from './IncludeInterfaceWidgetModule/ItemInterfaceIncludeInterfaceWidgetPackage';
import { SolutionSitePageIncludeInterfaceWidgetPackage } from './IncludeInterfaceWidgetModule/SolutionSitePageIncludeInterfaceWidgetPackage';
import InnerItemsWidgetPackage from './InnerItemsWidgetModule/InnerItemsItemWidgetPackage';
import SolutionSitePageItemsWidgetPackage from './InnerItemsWidgetModule/SolutionSitePageItemsWidgetPackage';
import LineItemsWidgetPackage from './LineItemsWidgetModule/LineItemsWidgetPackage';
import ModuleItemsWidgetPackage from './ModuleItemsWidgetModule/ModuleItemsWidgetPackage';
import { ProcessMapperWidgetPackage } from './ProcessMapperWidget';
import { QuestionWidgetPackage } from './QuestionWidget';
import { RichTextEditorInputWidgetPackage } from './RichTextEditorInputWidget';
import RichTextWidgetPackage from './RichTextWidgetModule/RichTextWidgetPackage';
import SolutionSitePageRichTextWidgetPackage from './RichTextWidgetModule/SolutionSitePageRichTextWidgetPackage';
import { SearchWidgetPackage } from './SearchWidgetModule';
import { SeparatorWidgetPackage } from './SeparatorWidgetModule';
import { SolutionSiteSeparatorWidgetPackage } from './SeparatorWidgetModule';
import { StatusProgressBarWidgetPackage } from './StatusProgressBarWidget';
import UploadWidgetPackage from './UploadWidget/UploadWidgetPackage';
import type { WidgetPackage } from '../WidgetModule';
import type { ItemInterfaceWidgetPackage } from '../WidgetModule';

import { ItemInterfaceWidgetType, TonkeanType } from '@tonkean/tonkean-entities';
import type { WidgetParentTypes } from '@tonkean/tonkean-entities';

export type InterfaceWidgetPackageMap = Record<ItemInterfaceWidgetType, ItemInterfaceWidgetPackage>;

const itemInterfaceWidgetsPackageMap: InterfaceWidgetPackageMap = {
    [ItemInterfaceWidgetType.ACTIONS]: ItemInterfaceActionsItemWidgetPackage,
    [ItemInterfaceWidgetType.INNER_ITEMS]: InnerItemsWidgetPackage,
    [ItemInterfaceWidgetType.FIELDS]: ItemInterfaceFieldsWidgetPackage,
    [ItemInterfaceWidgetType.COMMENTS]: CommentsWidgetPackage,
    [ItemInterfaceWidgetType.TIMELINE]: TimelineWidgetPackage,
    [ItemInterfaceWidgetType.RICH_TEXT]: RichTextWidgetPackage,
    [ItemInterfaceWidgetType.SEPARATOR]: SeparatorWidgetPackage,
    [ItemInterfaceWidgetType.STATUS_PROGRESS_BAR]: StatusProgressBarWidgetPackage,
    [ItemInterfaceWidgetType.FIELD_CHART]: FieldChartWidgetPackage,
    [ItemInterfaceWidgetType.IMAGE]: ImageWidgetPackage,
    [ItemInterfaceWidgetType.EMBED]: EmbedWidgetPackage,
    [ItemInterfaceWidgetType.AI_CHAT]: AIChatWidgetPackage,
    [ItemInterfaceWidgetType.QUESTION]: QuestionWidgetPackage,
    [ItemInterfaceWidgetType.LINE_ITEMS]: LineItemsWidgetPackage,
    [ItemInterfaceWidgetType.MODULE_ITEMS]: ModuleItemsWidgetPackage,
    [ItemInterfaceWidgetType.DETAILED_ITEMS]: DetailedItemsWidgetPackage,
    [ItemInterfaceWidgetType.UPLOAD]: UploadWidgetPackage,
    [ItemInterfaceWidgetType.PROCESS_MAPPER]: ProcessMapperWidgetPackage,
    [ItemInterfaceWidgetType.SEARCH]: SearchWidgetPackage,
    [ItemInterfaceWidgetType.INCLUDE_INTERFACE]: ItemInterfaceIncludeInterfaceWidgetPackage,
    [ItemInterfaceWidgetType.RICH_TEXT_EDITOR_INPUT]: RichTextEditorInputWidgetPackage,
};

function packageMapTypeEnforcer<
    T extends Record<WidgetParentTypes, Partial<Record<ItemInterfaceWidgetType, WidgetPackage>>>,
>(packageMap: T) {
    return packageMap;
}

export const solutionSitePageWidgetsPackageMap = {
    [ItemInterfaceWidgetType.RICH_TEXT]: SolutionSitePageRichTextWidgetPackage,
    [ItemInterfaceWidgetType.ACTIONS]: SolutionSitePageActionsItemWidgetPackage,
    [ItemInterfaceWidgetType.INNER_ITEMS]: SolutionSitePageItemsWidgetPackage,
    [ItemInterfaceWidgetType.FIELDS]: SolutionSiteFieldsWidgetPackage,
    [ItemInterfaceWidgetType.FIELD_CHART]: SolutionSiteFieldChartWidgetPackage,
    [ItemInterfaceWidgetType.IMAGE]: SolutionSitePageImageWidgetPackage,
    [ItemInterfaceWidgetType.EMBED]: SolutionSitePageEmbedWidgetPackage,
    [ItemInterfaceWidgetType.AI_CHAT]: SolutionSiteAIChatWidgetPackage,
    [ItemInterfaceWidgetType.SEPARATOR]: SolutionSiteSeparatorWidgetPackage,
    [ItemInterfaceWidgetType.INCLUDE_INTERFACE]: SolutionSitePageIncludeInterfaceWidgetPackage,
};

export const widgetsPackageMap = packageMapTypeEnforcer({
    [TonkeanType.ITEM_INTERFACE]: itemInterfaceWidgetsPackageMap,
    [TonkeanType.SOLUTION_SITE_PAGE]: solutionSitePageWidgetsPackageMap,
});
export type WidgetsPackageMap = typeof widgetsPackageMap;

export default itemInterfaceWidgetsPackageMap;
