import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addItemIsAddedCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addItemIsAddedCreateAfterOption';
import addMonitorBlockCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addMonitorBlockCreateAfterOption';

import { CustomTriggerType, LogicBlockConfigType } from '@tonkean/tonkean-entities';

const SyncInnerMatchedEntityLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.SYNC_INNER_MATCHED_ENTITY,
    title: 'Iterate Related Items',
    iconClass: 'mod-sync-inner-matched-entity',
    creatingActionItems: true,
    description:
        'Monitors the data source items matched by the configured matched entity and adds them as action items.',
    cantMoveInto: true,
    doNotDisplayStatusEdit: true,
    doNotDisplayNotificationsEdit: true,
    allowedChildrenTypes: () => [LogicBlockConfigType.MONITOR_TRACKS],
    innerItemDetailsTitlePlaceholder: 'Related Item',
    innerItemDetailsDescriptionPlaceholder: 'An item from an external datasource related to the business request',
    parentValidationOnCreation: (parent, $emit) => {
        if (
            parent?.customTriggerType &&
            (parent.customTriggerType === CustomTriggerType.AUTONOMOUS ||
                parent.customTriggerType === CustomTriggerType.INNER_AUTONOMOUS) &&
            parent.monitorInnerItems
        ) {
            $emit('alert', {
                msg: 'You cannot create Create Sub Items as an inner child of a trigger that monitor inner items creation',
                type: 'error',
            });
            return false;
        } else {
            return true;
        }
    },
    createAfterOptionsTitle: 'Run on',
    createAfterOptions: (features) => {
        if (features?.tonkean_show_inner_item_idd) {
            return [addItemIsAddedCreateAfterOption, addMonitorBlockCreateAfterOption];
        } else {
            return [addMonitorBlockCreateAfterOption];
        }
    },
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (!definition.matchedEntityId) {
            validationObject.noMatchedEntity = 'Must select a Matched Entity';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default SyncInnerMatchedEntityLogicBlockConfig;
