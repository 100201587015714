import type React from 'react';
import { useMemo, useState } from 'react';

import useDebouncedState from './useDebouncedState';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

interface useSearchData<T> {
    /**
     * the current search term
     */
    searchTerm: string | undefined;

    /**
     * function to update the search term
     */
    setSearchTerm: React.Dispatch<React.SetStateAction<string | undefined>>;

    /**
     * the list containing items that passed the search filtering
     */
    debouncedFilteredItems: T[];
}

/**
 * This hooks gives you all the definitions needed for the search box component,
 * and enables searches on given list when the outputted state changes
 *
 * @example
 *  const comparer =
 *      (field: FieldDefinition, search: string) => field.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
 *
 *  const {
 *      searchTerm,
 *      setSearchTerm,
 *      filteredItems,
 *  } = useSearch<FieldDefinition>(allFieldDefinitions, comparer);
 *
 *
 * @param allItems - The list on which the search should be done
 * @param comparer - The compare function where you notify wheater an item has the search ter on not, cached in
 *                   the hook - no useCallback needed
 */
function useSearch<T>(allItems: T[], comparer: (item: T, searchString: string) => boolean): useSearchData<T> {
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useDebouncedState<string | undefined>(
        debouncedSearchTerm,
        setDebouncedSearchTerm,
    );

    const comparerFunction = useConstantRefCallback(comparer);

    const debouncedFilteredItems = useMemo(() => {
        let items = allItems;
        if (debouncedSearchTerm && debouncedSearchTerm !== '') {
            items = allItems.filter((item) => comparerFunction(item, debouncedSearchTerm));
        }

        return items;
    }, [allItems, comparerFunction, debouncedSearchTerm]);

    return { searchTerm, setSearchTerm, debouncedFilteredItems };
}

export default useSearch;
