import React from 'react';
import styled from 'styled-components';

import { H1, H4, Spacer } from '@tonkean/infrastructure';
import { UnderConstruction } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';

interface Props {}

const Container = styled.div`
    margin-top: 150px;
    text-align: center;
`;

const SolutionSiteInterfaceEmptyView: React.FC<Props> = ({}) => {
    return (
        <Container data-automation="solution-site-interface-empty-view-empty-state-view">
            <UnderConstruction />
            <Spacer height={20} />
            <H1>Page still under construction</H1>
            <Spacer height={15} />
            <H4 $color={Theme.colors.gray_700} $bold>
                There is nothing to see here yet ...
            </H4>
        </Container>
    );
};

export default SolutionSiteInterfaceEmptyView;
