// tslint:disable:ordered-imports

import htmlToFormattedText from 'html-to-formatted-text';
import Papa from 'papaparse';
import rangy from 'rangy';
import rangySelectionSaveRestore from 'rangy/lib/rangy-selectionsaverestore';
import 'textangular/dist/textAngularSetup';
import 'textangular/dist/textAngular';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactRoot from '@tonkean/infrastructure/components/ReactRoot';
import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';
import uiSelect from 'ui-select';
import 'ng-img-crop/compile/minified/ng-img-crop';
import ZeroClipboard from 'zeroclipboard';
import 'ng-clip';
import 'ngstorage';
import 'ng-tags-input';
import 'angular-timeago';
import 'ng-infinite-scroll/build/ng-infinite-scroll.js';
import 'ng-light-markdown';
import 'angular-drag-and-drop-lists';
import 'ment.io';
import angularScroll from 'angular-scroll';
import './external/date.js';
import 'oclazyload';
import { SCIMTonkeanRole, isUserOnlyInRole, noRolesUser } from '@tonkean/tonkean-entities';
import { KnowledgeBase } from '@tonkean/constants';
import { DeprecatedDate, initDayJs } from '@tonkean/utils';
import { AngularInjectorContext, ReactToAngularPortals } from 'angulareact';
import { RecoilRoot } from 'recoil';
import UrqlClientProvider from './UrqlClientProvider';
import { analyticsWrapper, posthogWrapper, mixpanelWrapper, datadogWrapper } from '@tonkean/analytics';
import { TDLServerConnectivityAngularBridge, TonkeanDataLayerProvider } from '@tonkean/tonkean-data-layer';

// Required by `ng-clip`
window['ZeroClipboard'] = ZeroClipboard;
// Required by text angular
window['taTools'] = {};
// required by text angular
window['rangy'] = rangy;
window['rangy'].saveSelection = rangySelectionSaveRestore;

initDayJs();

/**
 * This is the only time we define the 'app' variable
 * @type {angular.IModule}
 */
const app = angular
    .module('tonkean.app', [
        'tonkean.shared',
        uiRouter,
        uiBootstrap,
        uiSelect,
        'ngTagsInput',
        'ngStorage',
        'textAngular',
        'ngClipboard',
        'ngImgCrop',
        'yaru22.angular-timeago',
        'infinite-scroll',
        'ngLightMarkdown',
        'dndLists',
        'mentio',
        'oc.lazyLoad',
        angularScroll,
    ])
    // value is the way to add js libraries to angular so we can inject them.
    .value('papaparse', Papa)
    .value('htmlToFormattedText', htmlToFormattedText);

// For help with tracing angulars "possibly unhandled rejection":
// 1. Uncomment the call to AngularStackTraceHelper
// 2. Search for "possibly unhandled rejection" in angulars code and put a breakpoint
// 3. Print "toCheck.stack" to get the correct callstack
// Taken from https://stackoverflow.com/questions/41281515/possibly-unhandled-rejection-in-angular-1-6
// AngularStackTraceHelper(app);

const root = ReactDOM.createRoot(document.querySelector('#react-root'));

app.run([
    '$injector',
    '$state',
    '$timeout',
    '$rootScope',
    'authenticationService',
    'environment',
    ($injector, $state, $timeout, $rootScope, authenticationService, environment) => {
        /** @type {TonkeanRouter} */
        const router = {
            go(path = '.', query = {}, replace = false, options = {}) {
                const modifiedOptions = {
                    ...(path === '.' ? { notify: false } : {}),
                    ...(replace ? { replace } : {}),
                    ...options,
                };
                $timeout(() => {
                    $state.go(path, { ...$state.params, ...query }, modifiedOptions);
                });
            },
            getHref(path = '.', query = {}) {
                return $state.href(path, { ...$state.params, ...query });
            },
        };

        root.render(
            <AngularInjectorContext.Provider value={$injector}>
                <UrqlClientProvider
                    authenticationService={authenticationService}
                    environment={environment}
                    $rootScope={$rootScope}
                >
                    <ReactRoot optimizedImage={undefined} router={router}>
                        <TonkeanDataLayerProvider>
                            <RecoilRoot>
                                <ReactToAngularPortals />
                            </RecoilRoot>
                        </TonkeanDataLayerProvider>
                    </ReactRoot>
                </UrqlClientProvider>
            </AngularInjectorContext.Provider>,
        );
    },
]);

/**
 * @param environment {Environment} - Environment service
 * @param $location {angular.ILocationService} - Location service
 * @param $rootScope {object} - angular's root scope
 */
function setEnvironment(environment, $location, $rootScope) {
    if (environment.isLocal) {
        $rootScope.log = console.log;
    }

    const search = $location.search();

    // Check for the mobile app flag. If it's set, mark the flag as true.
    const isMobileApp = !!search.mobileApp;
    localStorage?.setItem('mobileApp', JSON.stringify(isMobileApp));
    $rootScope.mobileApp = isMobileApp;

    // Check for the no menus flag is ON
    const noMenus = !!search.noMenus;
    localStorage?.setItem('noMenus', JSON.stringify(noMenus));
    $rootScope.noMenus = noMenus;

    TDLServerConnectivityAngularBridge.setTonkeanServerUrl(environment.apiUrl);
}

function trackUserTokens(authenticationService, $rootScope) {
    $rootScope.$on('currentUserChanged', () => {
        TDLServerConnectivityAngularBridge.setAccessToken(authenticationService.currentUser?.accessToken);
    });

    TDLServerConnectivityAngularBridge.setAccessToken(authenticationService.currentUser?.accessToken);

    $rootScope.$on('currentProjectTokenChanged', () => {
        TDLServerConnectivityAngularBridge.setProjectToken(authenticationService.getProjectToken());
    });

    TDLServerConnectivityAngularBridge.setProjectToken(authenticationService.getProjectToken());
}

/**
 * @param environment {Environment}
 * @param authenticationService
 * @param projectManager
 * @param tonkeanService {TonkeanService}
 * @param $rootScope {object}
 * @param $log {angular.ILogService}
 * @param $window {angular.IWindowService}
 */
function initIntercom(environment, authenticationService, projectManager, tonkeanService, $rootScope, $log, $window) {
    try {
        let intercomBooted = false;
        const bootIntercom = () => {
            if (intercomBooted) {
                return;
            }

            intercomBooted = true;
            window.Intercom('boot', {
                app_id: environment.apps.intercomAppId,
                custom_launcher_selector: '#intercom-open-button',
                hide_default_launcher: $rootScope.hideIntercom ?? false,
            });
        };

        const identify = function () {
            const user = authenticationService.currentUser;

            if (user?.isAdminLoginAs) {
                if (intercomBooted) {
                    intercomBooted = false;
                    window.Intercom('shutdown');
                }
                return;
            } else {
                bootIntercom();
            }

            if (user && user.id) {
                tonkeanService.generateIntercomUserHash().then((data) => {
                    const userHash = data.value;

                    window.Intercom('update', {
                        // eslint-disable-line new-cap
                        name: user.name, // Full name
                        email: user.email, // Email address
                        user_hash: userHash,
                        hide_default_launcher: ($rootScope.isMobile || $rootScope.hideIntercom) ?? false,
                        user_id: user.id,
                        job_title: user.title,
                        phone: user.metadata && user.metadata.phoneNumber ? user.metadata.phoneNumber : null,
                        created_at: new Date(user.created), // Signup date as a Unix timestamp
                    });
                });
            }
        };
        $rootScope.$on('currentUserChanged', identify);
        identify();

        // track project id
        const trackProject = function () {
            const project = projectManager.project;
            if (project) {
                const projectContext = authenticationService?.currentUser?.projectContexts[project.id];

                window.Intercom('update', {
                    // eslint-disable-line new-cap
                    isOwner: !!projectManager.isOwner,
                    isFullUser: !!(projectContext && projectContext.isLicensed),
                    isFullUserPreview: !!projectManager.isFullUserPreview,
                    company: {
                        id: project.id,
                        name: project.name,
                        usecases: project.usecases && project.usecases.length ? project.usecases.join(', ') : null,
                        created_at: new Date(project.created),
                        has_bot: !!(project.communicationIntegrations && project.communicationIntegrations.length),
                        trial_expiration_at: project.expirationDate ? new Date(project.expirationDate) : null,
                        is_trial_expired: !!project.isExpired,
                        licensed: !!project.license,
                        limited: !!project.isLimitedLicense,
                        plan: project.license ? project.license.plan : project.expirationDate ? 'TRIAL' : 'FREE',
                        seats: project.license ? project.license.seats : 1,
                        monthly_spend: project.license ? project.license.seats * 69.99 : 0,
                    },
                });
            }
        };
        trackProject();
        $rootScope.$on('currentProjectChanged', trackProject);

        $rootScope.$on('emailLoginSent', function (e, email) {
            window.Intercom('update', {
                // eslint-disable-line new-cap
                unverified_email: email, // Email address
            });
        });

        $window.addEventListener('beforeunload', function () {
            window.Intercom('shutdown'); // eslint-disable-line new-cap
            $rootScope.intercomLoaded = false;
        });

        // check when intercom has booted
        const checkIfBooted = function (count) {
            if (window.Intercom.booted) {
                $rootScope.intercomLoaded = true;
            } else if (count < 10) {
                setTimeout(function () {
                    checkIfBooted(count + 1);
                }, 1500);
            }
        };
        checkIfBooted(0);
    } catch (error) {
        $log.error(error);
    }
}

function initFullStory(authenticationService, projectManager, $rootScope, $log, environment) {
    try {
        /* eslint-disable */
        window['_fs_org'] = environment.fullStory.orgId;
        window['_fs_debug'] = environment.fullStory.debug ?? false;
        window['_fs_host'] = environment.fullStory.host ?? 'www.fullstory.com';
        window['_fs_script'] = environment.fullStory.script ?? 'edge.fullstory.com/s/fs.js';
        window['_fs_namespace'] = environment.fullStory.namespace ?? 'FS';

        (function (m, n, e, t, l, o, g, y) {
            if (e in m && m.console && m.console.log) {
                m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
                return;
            }
            g = m[e] = function (a, b) {
                g.q ? g.q.push([a, b]) : g._api(a, b);
            };
            g.q = [];
            o = n.createElement(t);
            o.async = 1;
            o.src = 'https://' + _fs_script;
            y = n.getElementsByTagName(t)[0];
            y.parentNode.insertBefore(o, y);
            g.identify = function (i, v) {
                g(l, { uid: i });
                if (v) g(l, v);
            };
            g.setUserVars = function (v) {
                g(l, v);
            };
            g.identifyAccount = function (i, v) {
                o = 'account';
                v = v || {};
                v.acctId = i;
                g(o, v);
            };
            g.clearUserCookie = function (c, d, i) {
                if (!c || document.cookie.match('fs_uid=[`;`]*`[`;`]*`[`;`]*`')) {
                    d = n.domain;
                    while (1) {
                        n.cookie = 'fs_uid=;domain=' + d + ';path=/;expires=' + new Date(0).toUTCString();
                        i = d.indexOf('.');
                        if (i < 0) break;
                        d = d.slice(i + 1);
                    }
                }
            };
        })(window, document, window['_fs_namespace'], 'script', 'user');
        /* eslint-enable */

        const identify = function () {
            const user = authenticationService.currentUser;
            if (user && user.id) {
                FS.identify(user.id, {
                    displayName: user.name,
                    email: user.email,
                    jobTitle_str: user.title,
                    created_date: new Date(user.created), // Signup date as a Unix timestamp
                    // TODO: Add your own custom user variables here.
                });
            }
        };
        $rootScope.$on('currentUserChanged', identify);
        identify();

        // track project id
        const trackProject = function () {
            const project = projectManager.project;
            if (project) {
                FS.setUserVars({
                    isOwner_bool: !!projectManager.isOwner,
                    projectId_str: project.id,
                    projectName_str: project.name,
                    usecases_str: project.usecases && project.usecases.length ? project.usecases.join(', ') : '',
                    created_date: new Date(project.created),
                    hasBot_bool: !!(project.communicationIntegrations && project.communicationIntegrations.length),
                });
            }
        };
        trackProject();
        $rootScope.$on('currentProjectChanged', trackProject);
    } catch (error) {
        $log.error(error);
    }
}

function initGoogleAnalytics($location, authenticationService, projectManager, $rootScope, $log, gaId) {
    try {
        ga('create', gaId, 'auto');

        // Setting the user ID.
        const trackUser = function () {
            const user = authenticationService.currentUser;
            if (user && user.id) {
                let userEmail = user ? user.email : '';

                if (user && user.isAdminLoginAs) {
                    userEmail = 'ADMIN';
                }

                ga('set', '&uid', userEmail);
                if (userEmail) {
                    console.debug(`Tracking user: ${userEmail}`);
                    ga('set', 'dimension1', userEmail);
                }
            }
        };
        trackUser();
        $rootScope.$on('currentUserChanged', trackUser);

        // track project id
        const trackProject = function () {
            const project = projectManager.project;
            if (project) {
                const value = `${project.name} [${project.id}]`;
                // console.log('Tracking project: ' + value);
                ga('set', 'dimension2', value);
            }
        };
        trackProject();
        $rootScope.$on('currentProjectChanged', trackProject);

        $rootScope.$on('emailLoginSent', function (e, email) {
            ga('set', 'dimension1', email);
        });

        // track state change as a "view" even
        $rootScope.$on('$stateChangeSuccess', function (e, toState) {
            ga('send', 'event', toState.name, 'View');
            ga('send', 'pageview', $location.url());
        });
    } catch (error) {
        $log.error(error);
    }
}

function initDatadog(environment, authenticationService, $rootScope) {
    datadogWrapper.init(environment.name, environment.datadog);
    datadogWrapper.setGlobalContext({});

    // This data is too heavy to be shared from each client (network-wise), also, it's uncensored,
    // so we decided to turn it off in the meantime
    // datadogRum.startSessionReplayRecording();

    // Setting the user ID.
    const trackUser = function () {
        const user = authenticationService.currentUser;
        if (user && user.id) {
            datadogWrapper.trackUser({
                id: user.id,
                name: user.name,
                email: user.email,
                projectIdentifier: user.projectContext?.projectId,
                projectName: user.projectContext?.name,
                enterpriseIdentifier: user.enterprise?.id,
                enterpriseName: user.enterprise?.name,
            });
        }
    };

    trackUser();
    $rootScope.$on('currentUserChanged', trackUser);
}

function initMixpanel($location, authenticationService, projectManager, $rootScope, $log, $timeout, mpId) {
    if (mpId) {
        mixpanelWrapper.init(mpId, authenticationService, projectManager, $rootScope, $log, $timeout);
    }
}

function initPosthog($location, authenticationService, projectManager, $rootScope, $log, $timeout, phId) {
    if (phId) {
        posthogWrapper.init(phId, authenticationService, projectManager, $rootScope, $log, $timeout);
    }
}

function initSentry(authenticationService, projectManager, $rootScope, $log, environment) {
    try {
        // Run the inner initialization functions only if Sentry was loaded and installed.
        if (typeof Sentry !== 'undefined' && Sentry) {
            // Configure and install the Sentry client.
            const sentryPayload = {
                dsn: environment.sentry.dsn,
                environment: environment.name,
                integrations: [],
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [window.location.hostname],
            };
            if (environment.sentry.browserTracing) {
                sentryPayload.integrations.push(new Sentry.BrowserTracing());

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                sentryPayload.tracesSampleRate = environment.sentry.tracesSampleRate ?? 1;
            }
            if (environment.sentry.replay) {
                sentryPayload.integrations.push(new Sentry.Replay());

                // Capture Replay for 10% of all sessions,
                // plus for 100% of sessions with an error
                sentryPayload.replaysSessionSampleRate = environment.sentry.replaysSessionSampleRate ?? 0.1;
                sentryPayload.replaysOnErrorSampleRate = environment.sentry.replaysOnErrorSampleRate ?? 1;
            }

            if (environment.config.releaseId) {
                sentryPayload.release = environment.config.releaseId;
            }

            Sentry.init(sentryPayload);

            // Track the user id.
            const trackUser = function () {
                const user = authenticationService.currentUser;
                if (user && user.id) {
                    // Configure the user in Sentry.
                    Sentry.setUser({
                        id: user.id,
                        email: user.email,
                        username: user.name,
                    });
                }
            };
            trackUser();
            $rootScope.$on('currentUserChanged', trackUser);

            // Track the project id.
            const trackProject = function () {
                const project = projectManager.project;
                if (project && project.id) {
                    // Configure the project id in Sentry. Sentry allows supplying 'tags' and we set one as the current project id.
                    Sentry.setTag({
                        projectId: project.id,
                    });
                    Sentry.setContext('project', {
                        id: project.id,
                        name: project.name,
                    });
                }
            };
            trackProject();
            $rootScope.$on('currentProjectChanged', trackProject);
        }
    } catch (error) {
        $log.error(`Failed to load Sentry: ${error}`);
    }
}

// init StatusPage integration based on environment
function initStatusPage($location, $window, $log, scriptSrc) {
    try {
        if (scriptSrc) {
            const script = document.createElement('script');

            script.src = scriptSrc;
            script.async = true;

            $window.document.body.append(script);
        }
    } catch (error) {
        $log.error(`Failed to load StatusPage: ${error}`);
    }
}

// The list of pages a process contributor can access to
const processContributorRoutingWhiteList = new Set([
    'product.form', // Deprecated but kept for backwards compat
    'form',
    'noaccesspage',
    'updateState',
    'link',
    'products',
    'product.processContributorSolutionBusinessReport',
    'product.itemInterfaceBuilderPreview',
    'product.interface.group',
    'product.interface.fullview',
    'product.customInterfaceView',
    'solution-site',
    'homepageView',
    'product.intakeInterface',
]);

// The list of pages a guest user can access to
const guestRoutingWhiteList = new Set([
    'product.form', // Deprecated but kept for backwards compat
    'form',
    'noaccesspage',
    'link',
    'products',
    'product.interface.group',
    'product.customInterfaceView',
    'homepageView',
    'updateState',
]);

// The list of pages a person with no roles can access to
const noRolesRoutingWhiteList = new Set(['products', 'noaccesspage', 'product.expired']);

function blockProcessContributors(e, toState, toParams, $rootScope, authenticationService, $state, projectManager) {
    // If we are not in project context, then only return and let the user access.
    if (!projectManager.project?.id && !toParams.projectId && !toParams.projectUrlSlug) {
        return;
    }

    let shouldPreventAccess = true;
    // If the user have project context
    if (authenticationService.currentUser?.projectContext) {
        shouldPreventAccess = checkIfUserHasAccessToPage(authenticationService, toState);
    } else if (authenticationService.currentUser) {
        shouldPreventAccess = checkIfUserIsSystemUserInOneProjectAndHasAccessToPage(authenticationService, toState);
    }

    if (shouldPreventAccess) {
        e?.preventDefault?.();
        $state.go('noaccesspage');
        return;
    }
}

function processContributorsPermissionBlocker($rootScope, authenticationService, $state, projectManager) {
    $rootScope.$on('$stateChangeSuccess', (e, toState, toParams, fromState, fromParams) =>
        blockProcessContributors(e, toState, toParams, $rootScope, authenticationService, $state, projectManager),
    );
    $rootScope.$on('currentProjectChanged', (e) => {
        blockProcessContributors(
            e,
            $state.current,
            $state.params,
            $rootScope,
            authenticationService,
            $state,
            projectManager,
        );
    });
}

function checkIfUserHasAccessToPage(authenticationService, toState) {
    const isProcessContributorOnly = isUserOnlyInRole(
        authenticationService.currentUser.projectContext,
        SCIMTonkeanRole.PROCESS_CONTRIBUTOR,
    );
    const isGuestOnly = isUserOnlyInRole(authenticationService.currentUser.projectContext, SCIMTonkeanRole.GUEST);
    const hasNoRoles = noRolesUser(authenticationService.currentUser.projectContext);

    // If process contributor and not permitted to the page or has no roles
    if (isProcessContributorOnly && !processContributorRoutingWhiteList.has(toState.name)) {
        return true;
    } else if (isGuestOnly && !guestRoutingWhiteList.has(toState.name)) {
        return true;
        // If has no roles
    } else if (hasNoRoles && !noRolesRoutingWhiteList.has(toState.name)) {
        return true;
    }

    return false;
}

function checkIfUserIsSystemUserInOneProjectAndHasAccessToPage(authenticationService, toState) {
    const isUserSystemUserInOneProject =
        authenticationService.currentUser.projectContext?.calculatedTonkeanRoles?.includes(SCIMTonkeanRole.SYSTEM_USER);

    // If the user just logged in and don't have project context yet because he still didn't choose a project, and also don't have system user in any project
    // Then we won't have projectContext and we'll need to check if has at least one board with system user role in order to show him the board list page.
    if (
        !authenticationService.currentUser.projectContext &&
        isUserSystemUserInOneProject === false &&
        !processContributorRoutingWhiteList.has(toState.name)
    ) {
        return true;
    }

    return false;
}

function mainRun(
    authenticationService,
    $rootScope,
    $state,
    $log,
    $location,
    $localStorage,
    $timeout,
    projectManager,
    syncConfigCacheManager,
    workflowVersionManager,
    tonkeanService,
    utils,
    modal,
    trackHelper,
    activityManager,
    onBoardingManager,
    customFieldsManager,
    customTriggerManager,
    licensePermissionsService,
    $window,
    apiConsts,
    timeAgo,
    currentUserHelper,
    environment,
) {
    analyticsWrapper.timeEvent('angular_init');

    // Logging the time the app starts running, will be used to calculate how long forms take to load.
    $rootScope.pageLoadTime = Date.now();
    $rootScope.formLoadEventSubmitted = false;

    timeAgo.settings.allowFuture = true;
    timeAgo.settings.strings['en_US'] = {
        prefixAgo: null,
        prefixFromNow: null,
        suffixAgo: 'ago',
        suffixFromNow: 'from now',
        seconds: '1 min',
        minute: '1 min',
        minutes: '%d mins',
        hour: '1 hr',
        hours: '%d hrs',
        day: '1d',
        days: '%dd',
        month: '1 mo',
        months: '%d mos',
        year: '1 yr',
        years: '%d yrs',
        numbers: [],
    };

    $rootScope.isMobile = window.innerWidth < 768;

    $rootScope.knowledgeBase = KnowledgeBase;
    setEnvironment(environment, $location, $rootScope);
    trackUserTokens(authenticationService, $rootScope);

    if (environment.googleAnalytics && environment.googleAnalytics.enable) {
        initGoogleAnalytics(
            $location,
            authenticationService,
            projectManager,
            $rootScope,
            $log,
            environment.googleAnalytics.gaId,
        );
    }
    if (environment.mixPanel && environment.mixPanel.enable) {
        initMixpanel(
            $location,
            authenticationService,
            projectManager,
            $rootScope,
            $log,
            $timeout,
            environment.mixPanel.mpId,
        );
    }
    if (environment.posthog && environment.posthog.enable) {
        initPosthog(
            $location,
            authenticationService,
            projectManager,
            $rootScope,
            $log,
            $timeout,
            environment.posthog.phId,
        );
    }
    if (environment.datadog && environment.datadog.enable) {
        initDatadog(environment, authenticationService, $rootScope);
    }
    if (environment.intercom && environment.intercom.enable) {
        initIntercom(environment, authenticationService, projectManager, tonkeanService, $rootScope, $log, $window);
    }
    if (environment.fullStory && environment.fullStory.enable && !$localStorage.disableFS) {
        initFullStory(authenticationService, projectManager, $rootScope, $log, environment);
    }
    if (environment.sentry && environment.sentry.enable) {
        initSentry(authenticationService, projectManager, $rootScope, $log, environment);
    }
    if (environment.statusPage && environment.statusPage.enable) {
        initStatusPage($location, $window, $log, environment.statusPage.script);
    }

    processContributorsPermissionBlocker($rootScope, authenticationService, $state, projectManager);

    $rootScope.scm = syncConfigCacheManager;
    $rootScope.wvm = workflowVersionManager;
    $rootScope.as = authenticationService;

    $rootScope.utils = utils;
    $rootScope.modal = modal;
    $rootScope.trackHelper = trackHelper;
    $rootScope.activityManager = activityManager;
    $rootScope.onBoardingManager = onBoardingManager;
    $rootScope.cfm = customFieldsManager;
    $rootScope.ctm = customTriggerManager;
    $rootScope.lps = licensePermissionsService;
    $rootScope.tonkeanService = tonkeanService;
    $rootScope.pageTitle = '';

    $rootScope.hideEnvMessage = $localStorage.hideDevInfo || environment.isProd;
    $rootScope.lasttimeIn = new Date();
    $rootScope.isPageActive = true;

    $rootScope.$on('currentUserChanged', () => {
        $rootScope.isCurrentUserSystemUtilized = authenticationService?.currentUser?.systemUtilized || false;
    });

    $window.addEventListener('focus', function () {
        // console.log("focused");
        $rootScope.isPageActive = true;
        $rootScope.lasttimeIn = new Date();
    });
    $window.addEventListener('blur', function () {
        // console.log("out");
        $rootScope.isPageActive = false;
        $rootScope.lasttimeOut = new Date();
    });

    function checkForUpdates(firstTime, isLocal) {
        if (!firstTime) {
            analyticsWrapper.track('Page Viewed', {
                category: 'App kept open',
                label: $rootScope.currentRouteStateName,
                nonInteraction: true,
            });
            updateLastLoginAndTimeZone(authenticationService.currentUser);
        }

        if (!isLocal) {
            // check new version
            tonkeanService
                .getDeployJson()
                .then(function (response) {
                    if (response && response.data) {
                        const data = response.data;
                        if ($rootScope.buildNumber > 0 && data.build > $rootScope.buildNumber) {
                            // means there is a new version
                            $rootScope.hasNewVersion = true;
                            analyticsWrapper.track('Old Version Detected', {
                                category: 'AppVersion',
                                label: $rootScope.buildNumber,
                                nonInteraction: true,
                            });

                            if (
                                !$rootScope.isPageActive &&
                                DeprecatedDate.nowAsDate() - $rootScope.lasttimeOut > 7_200_000
                            ) {
                                // older than 2 hour ago
                                // auto refresh
                                analyticsWrapper.track('Force restart', {
                                    category: 'AppVersion',
                                    label: $rootScope.buildNumber,
                                });
                                $timeout(function () {
                                    window.location.reload();
                                });
                            }
                        } else {
                            $rootScope.buildNumber = data.build;
                        }
                    }
                })
                .catch((error) => {
                    console.error('Couldnt get deploy.json', error);
                })
                .finally(function () {
                    // check again in 30min
                    $timeout(function () {
                        checkForUpdates(false, isLocal);
                        //  //}, 10000);
                        // }, 1800000);
                    }, 300_000);
                });
        }

        // check if project expired
        if (!firstTime && $rootScope.pm.project && ($rootScope.pm.isInTrial || $rootScope.pm.project.isExpired)) {
            $rootScope.pm.getProjectData(true).then(checkExpired);
        }
    }

    function updateLastLoginAndTimeZone(freshUser) {
        // Don't update the last login and timezone if it is an admin login as situation.
        if (freshUser && freshUser.isAdminLoginAs) {
            return;
        }

        const now = new Date();
        const currentTimezone = Math.floor(now.getTimezoneOffset() / 60) * -1;

        // Async call to update the user's last login and current timezone.
        // We don't call authenticationService.updateCurrentUser as we don't want an event to be fired,
        // because this is a pretty minor change of user details. Instead, we update the currentUser object
        // manually.
        $log.info('Updating server with last login and current timezone');
        tonkeanService.updateUserLastLogin(currentTimezone).then(function () {
            if (authenticationService.currentUser) {
                authenticationService.currentUser.timezone = currentTimezone;
                authenticationService.currentUser.lastLogin = now.getTime();
            }
        });
    }

    // If the urls is exactly "board", we replace it with the last project id. This enables us to have urls in blueprints that would work for all users
    // the url should look like "/board#enterprise-component/data-sources" anything after the # would be taken as is
    if ($localStorage.lastProjectId && $location.path().replaceAll('/', '') === 'board') {
        $location.url(`/${$localStorage.lastProjectId}/${$location.hash()}`);
    } else if (!authenticationService.isUserAuthenticated()) {
        if (
            !isLoginPage($location.url()) &&
            !isLinkPage($location.url()) &&
            !isAnonymousPage($location.url()) &&
            !isSignupPage($location.url())
        ) {
            $log.log('Not Authenticated. Navigating to login page.');
            authenticationService.setAuthenticationCompleteRedirectUrl();
            // We don't use $state.go() because ui.route doesn't handle not fully initialized state well, creates a strange loop
            $location.url(`/login?redirect=${location.pathname + location.search}`);
        }
    } else {
        const user = authenticationService.currentUser;

        $rootScope.pm = projectManager.init();
        $rootScope.apiConsts = apiConsts.init();

        if (!user.isPartial && isLoginPage($location.url())) {
            $log.log('Authenticated navigation to login, redirect to board...');
            $location.url('/');
        }

        if ($rootScope.isMobile) {
            $rootScope.showFooter = false;
        }

        if (!isLogoutPage($location.url())) {
            tonkeanService.getCurrentUser().then(function (freshUser) {
                // make sure logout did happen before refreshing user
                if (
                    !authenticationService.currentUser?.user?.id ||
                    authenticationService.currentUser?.user?.id !== freshUser.id
                ) {
                    return;
                }

                authenticationService.updateCurrentUser(freshUser);
                updateLastLoginAndTimeZone(authenticationService.currentUser);
                $rootScope.showFixedFooter = currentUserHelper.checkUserMessagePreferenceExists('NO_GATHER_UPDATE');
                checkForUpdates(true, environment.isLocal);
            });
        }
    }

    let customTitle;

    function updatePageTitle() {
        // We change the title directly to the DOM and before the state change is complete
        // so the analytics could pickup the correct title
        const projectName = $rootScope.pm.project ? $rootScope.pm.project.name || '' : '';
        let title = customTitle || '%p';
        title = title.replace('%p', projectName);
        title = title.replace('%t', $rootScope.pageTitle);
        title += title.length ? ' - Tonkean' : 'Tonkean';
        $window.document.title = title;
    }

    function checkExpired() {
        if (
            $rootScope.pm.project &&
            $rootScope.pm.project.isExpired &&
            (!$rootScope.currentRouteStateName ||
                (!$rootScope.currentRouteStateName.includes('license') &&
                    !$rootScope.currentRouteStateName.includes('expired') &&
                    !$rootScope.currentRouteStateName.includes('login') &&
                    !$rootScope.currentRouteStateName.includes('products') &&
                    !$rootScope.currentRouteStateName.includes('notifications'))) && // if ($rootScope.pm.isNewPricing) {
            !$rootScope.pm.project.isLimitedLicense
        ) {
            $rootScope.showHeader = false;
            $state.go('product.expired', { projectId: $rootScope.pm.project.id }, { location: 'replace' });
        }
        // return;
        // }

        // if ($rootScope.as.currentUser) {
        //     $timeout(function () {
        //         // if project is expired, and limited message not shown before then show it
        //         if (!$rootScope.pm.isNewPricing && $rootScope.pm.project.isExpired && (!$rootScope.as.currentUser.metadata || !$rootScope.as.currentUser.metadata.limitedMessageShown || !$rootScope.as.currentUser.metadata.limitedMessageShown[$rootScope.pm.project.id])) {
        //             $rootScope.modalUtils.openTrialExpiredModal();
        //         }
        //     }, 3000);
        // }
    }

    $rootScope.$on('currentProjectChanged', function () {
        if ($rootScope.pm.project?.id) {
            projectManager.selectProject($rootScope.pm.project.id).then(() => {
                // update title
                updatePageTitle();

                // clear caches
                $rootScope.trackHelper.clearCaches();

                checkExpired();
            });
        }
    });

    $rootScope.$on('$stateChangeStart', function (e, toState) {
        customTitle = toState.data && angular.isString(toState.data.title) ? toState.data.title : null;
        updatePageTitle();

        $rootScope.loading = true;

        if (
            isLoginPage(toState.url) ||
            isLinkPage(toState.url) ||
            isAnonymousPage(toState.url) ||
            isSignupPage(toState.url)
        ) {
            return; // no need to redirect
        }

        // now, redirect only not authenticated
        if (!authenticationService.isUserAuthenticated()) {
            e.preventDefault(); // stop current execution
            $state.go('login', null, { location: 'replace' }); // go to login
        }
    });

    $rootScope.$on('$stateChangeSuccess', function (e, toState, toParams, fromState, fromParams) {
        $rootScope.loading = false;

        $log.debug('state change to %s', toState.name);
        $rootScope.stateData = toState.data;
        $rootScope.showFooter = toState.data ? !toState.data.hideFooter : true;
        if ($rootScope.isMobile) {
            $rootScope.showFooter = false;
        }
        $rootScope.showHeader = toState.data ? !toState.data.hideHeader : true;

        $rootScope.showLogo = toState.data ? !toState.data.hideLogo : true;
        $rootScope.showProjectFilter = toState.data ? toState.data.showProjectFilter : false;
        $rootScope.showBotSidePane = toState.data ? toState.data.showBotSidePane : false;
        $rootScope.showBotSidePaneCollapsed = toState.data ? toState.data.showBotSidePaneCollapsed : false;
        $rootScope.allowOverflowX = toState.data ? toState.data.allowOverflowX : false;
        $rootScope.currentRouteStateName = toState.name;
        $rootScope.hideMobileBottomMenu = $rootScope.isMobile && toState.data && toState.data.hideMobileBottomMenu;
        $rootScope.showHelp = toState.data && toState.data.showHelp;
        $rootScope.fullscreenXs = toState.data && toState.data.fullscreenXs;

        if ($rootScope.noMenus) {
            $rootScope.showFooter = false;
            $rootScope.showHeader = false;
            $rootScope.showLogo = true;
            $rootScope.hideMobileBottomMenu = true;
        }

        if (toState.data && toState.data.hideIntercom) {
            $rootScope.hideIntercom = true;
            hideIntercom(true);
        }

        // If the current page doesn't show the filter, it should certainly not show the inner filter.
        if (!toState.data.showProjectFilter) {
            $rootScope.showProjectInnerFilter = false;
        }

        checkExpired();
    });

    $rootScope.requestedDemo = $localStorage.requestedDemo;
    $rootScope.requestDemoWithExperts = function () {
        // tonkeanService.postFeedback('Demo/Setup request', 'Asked to get a schedule a demo');
        $timeout(function () {
            if (window.Intercom) {
                // eslint-disable-next-line new-cap
                window.Intercom('showNewMessage', 'Hi, I would like to schedule time with an expert');
                $timeout(function () {
                    const btn = document['intercom-messenger-frame'].document.querySelectorAll(
                        '.intercom-composer-send-button',
                    );
                    if (btn && btn.length) {
                        btn[0].click();
                    }
                }, 2000);
            }
            $localStorage.requestedDemo = true;
            $rootScope.requestedDemo = $localStorage.requestedDemo;
        });
    };

    $rootScope.currentYear = new Date().getFullYear();

    $rootScope.$watch('pageTitle', updatePageTitle);

    function hideIntercom(hideState) {
        $timeout(function () {
            if (window.Intercom) {
                // eslint-disable-next-line new-cap
                window.Intercom('update', {
                    hide_default_launcher: hideState,
                });
            }
        });
    }

    document.addEventListener('keydown', function (evt) {
        evt = evt || window.event;

        // Add toggle to envs with Option + 1/2
        const keyCodeToParams = { 49: { env: 'PUBLISHED' }, 50: { env: 'DRAFT' } };
        Object.keys(keyCodeToParams).forEach((keyCode) => {
            if (evt.altKey && evt.keyCode == keyCode) {
                evt.preventDefault();
                $state.go('.', keyCodeToParams[keyCode]);
            }
        });
    });
}

function isLoginPage(url) {
    return url.indexOf('/login') === 0 && !url.includes('ref=');
}

function isLogoutPage(url) {
    return url.indexOf('/logout') === 0 && !url.includes('ref=');
}

function isSignupPage(url) {
    return url.indexOf('/signup') === 0;
}

function isLinkPage(url) {
    return url.indexOf('/link') === 0 || url.indexOf('/notifications') === 0 || url.indexOf('/group/link/') === 0;
}

function isAnonymousPage(url) {
    const anonymousPages = ['/listGallery', '/triggerGallery'];

    // Check if one of the anonymous pages is being called now. If
    for (const anonymousPage of anonymousPages) {
        if (url.indexOf(anonymousPage) === 0) {
            return true;
        }
    }

    return false;
}

app.run(mainRun);
