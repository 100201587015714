import { reactToAngular } from '@tonkean/angular-components';
import { PeopleSelectorByPersonIdentifier } from '@tonkean/infrastructure';

angular
    .module('tonkean.app')
    .component(
        'tnkPeopleSelectorByPersonIdentifier',
        reactToAngular(PeopleSelectorByPersonIdentifier, [
            'selectedEmailsOrIds',
            'onChange',
            'isMulti',
            'includeExternal',
            'isDisabled',
        ]),
    );
