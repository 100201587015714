<div class="worker-file-selection common-border mod-radius common-bg mod-grey5 padding-normal-sm">
    <!-- File source selection title -->
    <div class="margin-bottom-md flex-vmiddle mod-justify-space">
        <!-- Title -->
        <div class="common-size-xxs common-bold">
            File Source
            <i
                ng-if="data.infoMessage"
                class="fa fa-info-circle common-color-primary margin-left-xs"
                uib-tooltip="{{ data.infoMessage }}"
            ></i>
        </div>

        <!-- Delete icon -->
        <button
            ng-if="data.shouldDisplayDeleteSign"
            class="svg-icon-xs btn btn-no-style common-color-light-grey"
            ng-click="deleteSignClicked()"
        >
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </button>
    </div>

    <!-- Source selection -->
    <div ng-class="data.isVerticalRadioBoxes ? 'display-grid' : 'flex-vmiddle margin-bottom-lg'">
        <!-- Data storage option -->
        <tnk-radio-button
            class="margin-right-md common-size-xxxxs"
            classes="{{
                (data.fileSourceType === 'DATA_STORAGE' ? 'selected-radio-option' : '') +
                    (data.fileSourceType !== 'DATA_STORAGE' ? 'not-selected-radio-option' : '')
            }}"
            radio-id="worker-file-selection-data-storage-{{ data.workflowVersionId }}-{{ data.attachmentIndex }}"
            radio-name="worker-file-selection-data-storage-{{ data.workflowVersionId }}-{{ data.attachmentIndex }}"
            radio-value="DATA_STORAGE"
            model="data.fileSourceType"
            on-click="selectFileSource(onClickParam)"
            on-click-param="('DATA_STORAGE')"
            is-required="true"
        >
            Data Storage
        </tnk-radio-button>

        <!-- URL option -->
        <tnk-radio-button
            class="margin-right-md"
            classes="{{
                (data.fileSourceType === 'URL' ? 'selected-radio-option' : '') +
                    (data.fileSourceType !== 'URL' ? 'not-selected-radio-option' : '')
            }}"
            radio-id="worker-file-selection-data-storage-{{ data.workflowVersionId }}-{{ data.attachmentIndex }}"
            radio-name="worker-file-selection-data-storage-{{ data.workflowVersionId }}-{{ data.attachmentIndex }}"
            radio-value="URL"
            model="data.fileSourceType"
            on-click="selectFileSource(onClickParam)"
            on-click-param="('URL')"
            is-required="true"
        >
            URL
        </tnk-radio-button>

        <!-- Upload file option -->
        <tnk-radio-button
            ng-if="$root.features[pm.project.id].tonkean_feature_allow_static_assets"
            class="common-size-xxxxs"
            classes="{{
                (data.fileSourceType === 'UPLOAD_FILE' ? 'selected-radio-option' : '') +
                    (data.fileSourceType !== 'UPLOAD_FILE' ? 'not-selected-radio-option' : '')
            }}"
            radio-id="worker-file-selection-data-storage-{{ data.workflowVersionId }}-{{ data.attachmentIndex }}"
            radio-name="worker-file-selection-data-storage-{{ data.workflowVersionId }}-{{ data.attachmentIndex }}"
            radio-value="UPLOAD_FILE"
            model="data.fileSourceType"
            on-click="selectFileSource(onClickParam)"
            on-click-param="('UPLOAD_FILE')"
            is-required="true"
        >
            Upload File
        </tnk-radio-button>

        <!-- Matched Entity option -->
        <tnk-radio-button
            ng-if="data.shouldAddMatchedEntityOption"
            class="common-size-xxxxs"
            classes="{{
                (data.fileSourceType === 'MATCHED_ENTITIES' ? 'selected-radio-option' : '') +
                    (data.fileSourceType !== 'MATCHED_ENTITIES' ? 'not-selected-radio-option' : '')
            }}"
            radio-id="worker-file-selection-data-storage-{{ data.workflowVersionId }}-{{ data.attachmentIndex }}"
            radio-name="worker-file-selection-data-storage-{{ data.workflowVersionId }}-{{ data.attachmentIndex }}"
            radio-value="MATCHED_ENTITIES"
            model="data.fileSourceType"
            on-click="selectFileSource(onClickParam)"
            on-click-param="('MATCHED_ENTITIES')"
            is-required="true"
        >
            Matched Entity
        </tnk-radio-button>
    </div>

    <!-- Data Storage Configuration -->
    <div ng-if="data.fileSourceType === 'DATA_STORAGE'">
        <!-- Data Storage Selection -->
        <div class="margin-bottom-xs common-bold common-size-xxxs">Data Storage:</div>

        <!-- Storage provider -->
        <tnk-project-integration-selector
            selected-project-integration-id="{{ data.storageIntegrationId }}"
            filter-on-integration-type="data.storageIntegrationTypes"
            selector-label-placeholder="Select data storage"
            allow-default-option="false"
            on-project-integration-selected="onProjectIntegrationSelected(selectedProjectIntegration)"
            is-for-download="true"
        ></tnk-project-integration-selector>

        <div ng-if="data.storageIntegration.integrationType === 'NO_CODE_DATA_SOURCE'" class="margin-top">
            <div class="margin-bottom-xs common-bold common-size-xxxs">Download Action:</div>

            <tnk-project-integration-action-selector
                class="margin-top"
                selected-project-integration-action-id="data.storageIntegrationActionId"
                project-integration="data.storageIntegrationId"
                on-project-integration-action-changed="(onProjectIntegrationActionSelected)"
                action-types="data.actionTypesToIncludeInSelector"
            ></tnk-project-integration-action-selector>
        </div>

        <!-- File Selection -->
        <div class="margin-bottom-xs common-bold common-size-xxxs margin-top-lg" ng-if="data.storageIntegration">
            Choose File:
            <i
                ng-if="data.extendedMatchConfiguration.extendedMatchConfigurationType === 'EXPRESSION'"
                class="fa fa-info-circle margin-left-xs"
                uib-tooltip="To upload multiple attachments, wrap them with convertToArray formula."
            ></i>
        </div>

        <!-- File selection - extended match configuration -->
        <tnk-extended-match-configuration
            ng-if="data.storageIntegration"
            project-integration="data.storageIntegration"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.customTriggerId }}"
            entity-metadata="data.fileEntityMetadata"
            existing-extended-match-configuration="data.extendedMatchConfiguration"
            preview-evaluation-source="data.previewEvaluationSource"
            on-extended-match-configuration-changed="
                onExtendedMatchConfigurationChanged(extendedMatchConfiguration, shouldSaveLogic)
            "
            mod-fixed-width="true"
            tabs-field-selector-mod-narrow="true"
        ></tnk-extended-match-configuration>
    </div>

    <!-- URL Configuration -->
    <div ng-if="data.fileSourceType === 'URL'">
        <div class="margin-bottom-xs common-bold common-size-xxxs">
            File Download URL:
            <i
                class="fa fa-info-circle margin-left-xs"
                uib-tooltip="To upload multiple attachments, wrap them with convertToArray formula."
            ></i>
        </div>

        <!-- Download URL expression -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.customTriggerId }}"
            placeholder="Enter file download url..."
            saved-original-expression="data.urlExpression.originalExpression"
            saved-evaluated-expression="data.urlExpression.evaluatedExpression"
            on-tonkean-expression-changed="
                onFileDownloadUrlTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-tonkean-expression>
    </div>

    <!-- Upload file -->
    <div ng-if="data.fileSourceType === 'UPLOAD_FILE'">
        <!-- Upload new asset -->
        <div
            ng-if="!data.workerStaticAssetId && !data.uploadingAsset && !data.errorUploadingAsset"
            class="choose-file-container"
        >
            <!-- File picker -->
            <input type="file" custom-on-change="onFileChange" class="custom-file-input common-no-outline" />
        </div>

        <!-- Loading state -->
        <div ng-if="data.uploadingAsset" class="loading-state flex-vmiddle mod-justify-center">
            <!-- Loading icon -->
            <span class="loading-small common-size-xxxs"></span>

            <!-- Loading text -->
            <div class="common-size-xxxs margin-left-xs">Uploading {{ data.fileBeingUploadedName }}</div>
        </div>

        <!-- Error state -->
        <div
            ng-if="data.errorUploadingAsset"
            class="error-state flex-vmiddle mod-justify-center common-size-xxxs common-color-danger"
        >
            Error uploading asset...
        </div>

        <!-- Existing asset -->
        <div ng-if="data.workerStaticAssetId">
            <div class="flex-vmiddle margin-bottom">
                <!-- File icon -->
                <span class="svg-icon margin-right-xs file-icon">
                    <tnk-icon src="/images/icons/file.svg"></tnk-icon>
                </span>

                <!-- File name -->
                <div class="common-size-xxxs common-bold">{{ data.workerStaticAssetDisplayName }}</div>

                <!-- Separator -->
                <div class="flex-grow"></div>

                <!-- Delete icon -->
                <button
                    class="svg-icon-xs flex-no-shrink btn btn-no-style common-color-light-grey"
                    ng-click="removeAsset()"
                >
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </button>
            </div>

            <!-- Separator -->
            <hr class="existing-file-separator" />
        </div>
    </div>

    <!-- Matched Entity Configuration -->
    <div ng-if="data.fileSourceType === 'MATCHED_ENTITIES'">
        <!-- Download URL expression -->
        <tnk-field-selector
            selected-field-identifier="data.matchEntityConfiguration.matchedEntityId"
            items-source="COLUMN"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            display-integration-indication="true"
            special-fields-for-features="['MATCHED_ENTITY_PREVIEW']"
            place-holder="Search matched entity"
            quick-create-matched-entity="true"
            only-id-relation-field="true"
            on-field-selected="onMatchedEntitySelected(selectedField)"
        ></tnk-field-selector>

        <div class="common-color-grey common-size-xxs margin-top-xxs">
            Aggregate matched entities data into a single file
        </div>
        <div class="margin-top">
            <tnk-preview-matched-entity-items-modal-trigger
                workflow-version-id="data.workflowVersionId"
                matched-entity-id="data.matchEntityConfiguration.matchedEntityId"
                initiative-id="ctm.workflowVersionIdToExampleItemsMap[data.workflowVersionId].root"
            ></tnk-preview-matched-entity-items-modal-trigger>
        </div>
    </div>

    <tnk-no-code-project-integration-download-predefined-parameters
        class="margin-top"
        ng-if="data.storageIntegrationId && data.storageIntegration"
        storage-project-integration="data.storageIntegration"
        on-storage-integration-dynamic-parameters-changed="(onStorageConfigurationDynamicParametersChanged)"
        group-id="data.groupId"
        workflow-version-id="data.workflowVersionId"
        custom-trigger-id="data.customTriggerId"
        parameters-values="data.parametersValues"
        storage-project-integration-action-id="data.storageIntegrationActionId"
    ></tnk-no-code-project-integration-download-predefined-parameters>

    <div ng-if="data.validationObject.noSource" class="common-color-danger margin-bottom-xs">
        {{ data.validationObject.noSource }}
    </div>
</div>

<div ng-if="data.fileSourceType === 'MATCHED_ENTITIES'">
    <div class="common-size-s common-bold margin-top-md margin-bottom-md">File Formatting</div>

    <div class="flex-vmiddle">
        <div class="common-title-xxs margin-bottom-xs">Rows Limit</div>

        <i
            ng-if="!data.allowedUnlimitedExportData"
            class="fa fa-info-circle margin-left-xs margin-bottom-xxs"
            uib-tooltip="The maximum allowed rows is: {{ data.maximumDefaultExportDataLimit }}"
            tooltip-append-to-body="true"
        ></i>
    </div>

    <div class="common-color-grey common-size-xxs margin-bottom-xs">Limit the maximum amount of items to export.</div>

    <div class="flex-vmiddle margin-bottom-xs">
        <input
            ng-model="data.matchEntityConfiguration.rowsLimit"
            ng-change="onChangeLimitNumber()"
            class="limit-input form-control expression-input"
            ng-disabled="(data.matchEntityConfiguration.isUnlimitedRows && data.allowedUnlimitedExportData)"
            type="number"
        />
        <tnk-checkbox
            ng-if="data.allowedUnlimitedExportData"
            class="margin-left-xs"
            label="'No Limit'"
            value="data.matchEntityConfiguration.isUnlimitedRows"
            on-toggle-async="onToggleIsUnlimitedRows()"
        ></tnk-checkbox>
    </div>

    <div>
        <div class="common-title-xxs margin-bottom-xs margin-top-md">File Format</div>
        <tnk-select
            on-change="(onFormatChanged)"
            value="data.selectedFormatObject"
            options="data.matchedEntitiesFormatsOptions"
            read-only="true"
        ></tnk-select>

        <div class="common-title-xxs margin-bottom-xs margin-top-md">Delimiter</div>

        <div class="flex-vmiddle margin-bottom-xs">
            <input
                    ng-model="data.matchEntityConfiguration.delimiter"
                    ng-change="onChangeDelimiter()"
                    type="text"
                    placeholder=","
                    class="form-control flex-grow expression-input"
            />
        </div>
        <div class="common-color-grey common-size-xxs margin-bottom-xs">Default delimiter is ','</div>

        <div class="common-title-xxs margin-bottom-xs margin-top-md">Column Template</div>

        <div class="common-color-grey common-size-xxs margin-bottom-xs">
            Define the new csv columns template, The value will be evaluated for each item.
        </div>

        <div ng-repeat="(index, columnTemplate) in data.matchEntityConfiguration.columnsTemplates">
            <div class="name-wrapper common-bold margin-bottom-xs">
                Name

                <button
                    class="
                        remove-column
                        margin-left-auto
                        svg-icon-xxxs
                        common-color-grey
                        svg-icon-hover-primary
                        btn btn-no-border
                        pointer
                        flex-no-shrink
                    "
                    ng-click="removeColumn(index)"
                >
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </button>
            </div>

            <input
                type="text"
                class="form-control flex-grow expression-input"
                placeholder="column name"
                ng-model="columnTemplate.name"
                ng-change="onWorkerFileSelectionChanged(true)"
            />
            <div class="margin-top-xs common-bold margin-bottom-xs">Value</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.logicId }}"
                placeholder="value:"
                saved-expression="columnTemplate.value"
                expression-unique-identifier="{{ index }}"
                on-tonkean-expression-changed="
                    onTonkeanExpressionValueChanged(expression, expressionIdentifier, shouldSaveLogic)
                "
                preview-evaluation-source="data.previewEvaluationSource"
            ></tnk-tonkean-expression>

            <hr
                ng-if="data.matchEntityConfiguration.columnsTemplates.length - 1 !== index"
                class="common-separator mod-darker margin-bottom-none"
            />
        </div>

        <button class="btn btn-link padding-left-none" ng-click="addDestinationMatchedEntityParam()">
            + Add column
        </button>
    </div>
</div>
