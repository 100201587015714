<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Upload File From Storage Provider or Url -->
        <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">
            <tnk-worker-file-selection
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                custom-trigger-id="{{ data.configuredLogic.node.id }}"
                file-source-type="{{ data.definition.fileSourceConfiguration.fileSourceType }}"
                storage-integration-id="{{ data.definition.fileSourceConfiguration.storageIntegrationId }}"
                storage-integration-action-id="{{ data.definition.fileSourceConfiguration.storageIntegrationActionId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                should-display-delete-sign="false"
                url-expression="data.definition.fileSourceConfiguration.url"
                extended-match-configuration="data.definition.fileSourceConfiguration.extendedMatchConfiguration"
                worker-static-asset-id="{{ data.definition.fileSourceConfiguration.workerStaticAssetId }}"
                worker-static-asset-display-name="{{
                    data.definition.fileSourceConfiguration.workerStaticAssetDisplayName
                }}"
                parameters-values="data.definition.parametersValues"
                on-worker-file-selection-changed="
                    onWorkerFileSelectionChanged(
                        fileSourceType,
                        storageIntegrationId,
                        extendedMatchConfiguration,
                        urlExpression,
                        workerStaticAssetId,
                        workerStaticAssetDisplayName,
                        parametersValues,
                        shouldSaveLogic,
                        storageIntegrationActionId
                    )
                "
            ></tnk-worker-file-selection>

            <div ng-if="data.validationObject.errorMessage" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.errorMessage }}
            </div>

            <!-- New Document Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    New File Name:
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="If empty, the original name will be used."
                    ></i>
                </div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    placeholder="File name"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-original-expression="data.definition.newFileNameExpression.originalExpression"
                    saved-evaluated-expression="data.definition.newFileNameExpression.evaluatedExpression"
                    on-tonkean-expression-changed="
                        onNewFileNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Generating a signed URL -->
        <div ng-if="data.definition.customActionKey === 'GENERATE_SIGNED_URL'" class="margin-bottom-xlg">

            <!-- Object Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">
                    File Name (path):
                </div>

                <tnk-tonkean-expression
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        placeholder="File Name (path)"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-expression="data.definition.objectNameExpression"
                        on-tonkean-expression-changed="onObjectNameExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>
        </div>
    </div>
</div>
