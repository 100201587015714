import { useAngularService } from 'angulareact';
import { LayoutGroup } from 'framer-motion';
import React, { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled, { css } from 'styled-components';

import InterfaceQuickNavigationContext from './InterfaceQuickNavigationContext';
import ItemWidgetContextProvider from './ItemWidgetContextProvider';
import { ItemInterfacePermission } from '../entities';
import {
    getIsInWidgetBuilder,
    ItemWidget,
    ItemWidgetError,
    type ItemWidgetProps,
    type SolutionSitePageWidgetProps,
    type WidgetProps,
} from '../WidgetModule';
import { widgetsPackageMap } from '../widgets/itemInterfaceWidgetsPackageMap';

import { Breakpoint, LayoutGridElements, LoadingCircle, widthByLayoutGridColumns } from '@tonkean/infrastructure';
import type { ItemInterfaceSection } from '@tonkean/infrastructure';
import { ItemInterfaceLoadingState, WIDGET_MAX_DISPLAY_COLUMNS, type WidgetBase } from '@tonkean/tonkean-entities';
import type { Either } from '@tonkean/utils';

const Container = styled.div`
    z-index: 0; // Set a zIndex so that the ItemWidgetSidepaneTrigger ItemClickArea doesnt interfere with clicks on the header
    display: flex;
    align-items: baseline; // This will make sure that the quick nav will behave as sticky should behave
    justify-content: center;
    width: 100%;
`;

const Panel = styled.article<{ $cleanMode?: boolean }>`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 10;
    ${widthByLayoutGridColumns};

    width: 100%;
    max-width: ${({ $cleanMode }) => ($cleanMode ? '100%' : css`var(${LayoutGridElements.WIDTH})`)};

    flex-grow: 1;

    display: grid;
    grid-template-columns: repeat(${WIDGET_MAX_DISPLAY_COLUMNS}, minmax(0, 1fr));
    gap: 32px;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        grid-template-columns: 1fr;
        gap: 16px;
    }
`;

const CreationLoadingContainer = styled.div`
    height: 360px;
    width: 100%;
    display: grid;
    place-items: center;
`;

const Loading = styled(LoadingCircle)`
    grid-column: span 2;
    margin: auto;
    height: 100px;
    width: 100px;
`;

export type WidgetsPanelProps<T extends WidgetProps> = {
    itemInterfaceWidgets: WidgetBase[];
    permission: ItemInterfacePermission;
    widgetProps: Omit<T, 'widget' | 'onWidgetClickParams'>;
    itemInterfaceWidgetsState: ItemInterfaceLoadingState;
    cleanMode?: boolean;
    section: ItemInterfaceSection;
};

type LoadingProps = {
    loading: true;
};

type ExternalProps<T extends WidgetProps> = Either<WidgetsPanelProps<T>, LoadingProps>;

type Props = ExternalProps<WidgetProps>;

const WidgetsPanel: React.FC<Props> = (props) => {
    if (props.loading || props.itemInterfaceWidgetsState === ItemInterfaceLoadingState.LOADING) {
        return (
            <Panel $cleanMode={props.cleanMode}>
                <ItemWidget permission={ItemInterfacePermission.INTERFACE_IS_EDITABLE}>
                    <CreationLoadingContainer>
                        <Loading />
                    </CreationLoadingContainer>
                </ItemWidget>
            </Panel>
        );
    }
    return <WidgetsPanelDisplay {...props} />;
};

/**
 * Widgets panel after the loading phase
 */
const WidgetsPanelDisplay: React.FC<WidgetsPanelProps<WidgetProps>> = (props) => {
    const { selectedWidgetId, quickNavigationMode } = useContext(InterfaceQuickNavigationContext);
    const $state = useAngularService('$state');
    const isInBuilder = getIsInWidgetBuilder($state);

    return (
        <Container>
            <Panel $cleanMode={props.cleanMode}>
                <LayoutGroup>
                    {props.itemInterfaceWidgets.map((widget) => {
                        const widgetPackage = widgetsPackageMap[widget.parentEntityType][widget.type];
                        const WidgetComponent = widgetPackage.displayComponent;
                        return (
                            <ErrorBoundary key={widget.id} FallbackComponent={ItemWidgetError}>
                                <ItemWidgetContextProvider
                                    widget={widget as any}
                                    editableWidgets={props.permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE}
                                    section={props.section}
                                >
                                    <WidgetComponent {...props.widgetProps} widget={widget} />
                                </ItemWidgetContextProvider>
                            </ErrorBoundary>
                        );
                    })}

                    {props.itemInterfaceWidgetsState === ItemInterfaceLoadingState.CREATING && (
                        <ItemWidget permission={props.permission}>
                            <CreationLoadingContainer>
                                <Loading />
                            </CreationLoadingContainer>
                        </ItemWidget>
                    )}
                </LayoutGroup>
            </Panel>
        </Container>
    );
};

// These are necessary to provide the props type
const ItemInterfaceWidgetsPanel: React.FC<ExternalProps<ItemWidgetProps>> = (props) => {
    return <WidgetsPanel {...props} />;
};

export const SolutionSitePageWidgetsPanel: React.FC<ExternalProps<SolutionSitePageWidgetProps>> = (props) => {
    return <WidgetsPanel {...props} />;
};

export default ItemInterfaceWidgetsPanel;
