function SetOwnerCtrl($scope, initiative) {
    $scope.initiative = initiative;

    /**
     * Occurs once an owner is defined.
     */
    $scope.ownerSaved = function () {
        $scope.$dismiss();
    };
}

export default angular.module('tonkean.app').controller('SetOwnerCtrl', SetOwnerCtrl);
