function tnkTrackWeekViewCtrl($scope, $rootScope, timeUtils, trackHelper, initiativeCache, utils, tonkeanUtils, modalUtils) {
    $scope.as = $rootScope.as;
    $scope.today = new Date();
    $scope.days = [];
    $scope.itemMap = initiativeCache.getInitiativesCache();
    $scope.timeUtils = timeUtils;
    $scope.tonkeanUtils = tonkeanUtils;

    $scope.modalUtils = modalUtils;
    $scope.cfm = $rootScope.cfm;

    $scope.init = function () {
        if (!$scope.startDate) {
            $scope.start = $scope.today;
        } else {
            $scope.start = $scope.startDate;
        }

        // console.log('group: ' + $scope.groupId);
        // console.log('owner: ' + $scope.ownerId);
        // console.log('func: ' + $scope.funcId);
        // console.log('tag: ' + $scope.tag);

        $scope.days = [];
        for (let i = 0; i < 7; i++) {
            const weekDay = new Date($scope.start);
            weekDay.setDate($scope.start.getDate() + i);
            const alt = weekDay.getMonth() % 2 > 0;

            const dateToken = weekDay.toDateString();

            let getCachedDateTracks = trackHelper.getTracksByDateFromCache(dateToken);
            if (!getCachedDateTracks) {
                getCachedDateTracks = {};
            }

            const today = new Date();
            const day = {
                date: weekDay,
                dateToken,
                diff: i,
                alt,
                items: getCachedDateTracks,
                isToday: timeUtils.isSameDay(weekDay, today),
            };
            $scope.days.push(day);
            // $scope.days.push({date: weekDay, diff: i, alt: alt, items: []});
            // console.log(day.items);
        }
    };

    $scope.onDrop = function (day, itemId) {
        const item = trackHelper.getInitiativeFromCache(itemId);
        if (item) {
            trackHelper.updateDueDate(item.id, day.date, true);

            return true;
        } else {
            return false;
        }
    };

    /**
     * Last but not least, we have to remove the previously dragged items in the
     * dnd-moved callback.
     */
    $scope.onMoved = function () {
        // day.items.splice($index, 1);
    };

    $scope.hasItems = function (obj) {
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                return true;
            }
        }
        return false;
    };

    $scope.selectDay = function (day) {
        if ($scope.selectedState) {
            $scope.selectedState.day = day;
        }
    };

    /**
     * A filter predicate used to filter items that are in the selected group
     * if no group is selected then returns all
     * @param item the item to predicate
     * @returns {boolean} true if item is in selected group or if no group selected
     */
    $scope.filterItemsByGroup = function (item) {
        if (!$scope.groupId) {
            return true;
        }

        return item.groupId === $scope.groupId;
    };

    // used to pass filters arrays and not objects
    $scope.objValues = utils.objValues;

    $scope.init();
}
export default angular.module('tonkean.app').controller('tnkTrackWeekViewCtrl', tnkTrackWeekViewCtrl);
