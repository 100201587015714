import React from 'react';
import styled from 'styled-components';

import RequestUrl from './RequestURL';
import SingleWorkerRunActionInspectJson from './SingleWorkerRunActionInspectJson';
import type { TabComponentProps } from '../../../entities/WorkerRunLogicInspectTabData';

import { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';

const RequestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SingleWorkerRunActionInspectRequestJson: React.FC<
    TabComponentProps<WorkerRunLogicInspectTabType.REQUEST_JSON>
> = ({ requestJson, url }) => {
    return (
        <RequestWrapper>
            {url && <RequestUrl url={url} />}
            {requestJson && (
                <SingleWorkerRunActionInspectJson
                    object={requestJson}
                    inspectTabType={WorkerRunLogicInspectTabType.REQUEST_JSON}
                />
            )}
        </RequestWrapper>
    );
};

export default SingleWorkerRunActionInspectRequestJson;
