import type { NavigationCategoryResults } from '@tonkean/tonkean-entities';
import type { NavigationEntitySummary } from '@tonkean/tonkean-entities';

function concatenateResults(navigationCategoryResults: NavigationCategoryResults): NavigationEntitySummary[] {
    const allParticipatingNavigationEntities: NavigationEntitySummary[] = [];
    allParticipatingNavigationEntities.push(
        ...(navigationCategoryResults?.initiatives || []),
        ...(navigationCategoryResults?.customTriggers || []),
        ...(navigationCategoryResults?.projectIntegrations || []),
        ...(navigationCategoryResults?.groups || []),
        ...(navigationCategoryResults?.workflowFolders || []),
        ...(navigationCategoryResults?.solutionBusinessReports || []),
        ...(navigationCategoryResults?.itemInterfaces || []),
        ...(navigationCategoryResults?.solutionSitePages || []),
        ...(navigationCategoryResults?.forms || []),
        ...(navigationCategoryResults?.intakes || []),
    );
    return allParticipatingNavigationEntities;
}

export default concatenateResults;
