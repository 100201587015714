<div class="padding-normal padding-top-none">
    <div ng-if="!data.atMentionedInEdit" class="flex-vmiddle margin-left-lg padding-left-md">
        <button class="btn btn-default margin-right" ng-click="nextFollowUpStep()">
            Just notify {{ data.atMentioned.length === 1 ? data.atMentioned[0].firstName : 'them' }}
        </button>
        <button
            ng-if="data.atMentioned.length === 1 && initiative.group.canUserWrite"
            class="btn btn-default margin-right"
            ng-click="setOwnerAndContinue(data.atMentioned[0])"
        >
            Assign as owner
        </button>
        <button class="btn btn-default" ng-click="startTrackEdit()">Create inner track</button>
    </div>
    <div id="tracks-wrapper" class="tracks-wrapper" ng-if="data.atMentionedInEdit">
        <tnk-tracks-editor
            scroller-element-id="tracks-wrapper"
            list-parent-element-id="tracks-wrapper"
            tracks="initiative.relatedInitiatives"
            editor-id="bot-atMention"
            override-item-click="data.atMentionedTrackOverrideClick"
            hide-header="true"
            quick-creation-mode="true"
            default-func="data.atMentionedNextOwner"
            default-new-item-text="{{ initiative.updateText }}"
            on-submit="setNextAtMentionedOwner()"
            parent-item="initiative"
            group-id="initiative.group.id"
            workflow-version-id="data.workflowVersion.id"
            load-next-initiatives-page-callback="loadNextInnerItemsPage()"
            has-more-initiatives="initiative.hasMoreInnerItemsToLoad"
            loading="initiative.loadingRelated"
            lazy-load-tracks="true"
        ></tnk-tracks-editor>
        <div class="common-color-link-blue" ng-click="nextFollowUpStep()">
            <a>Continue</a>
        </div>
    </div>
</div>
