import React from 'react';
import styled, { css } from 'styled-components';

import { InformationTooltip, Toggle } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-hight: 10px;
    color: ${Theme.colors.gray_700};
    min-width: 60px;
`;

const StyledInformationTooltip = styled(InformationTooltip)`
    margin-right: 20px;
    margin-left: 10px;
`;

const Wrapper = styled.div<{ $isVertical?: boolean }>`
    height: 28px;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${({ $isVertical }) =>
        $isVertical &&
        css`
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
        `}
`;

const LabelTooltipWrap = styled.div`
    display: flex;
`;

const StyledToggle = styled(Toggle)`
    margin-top: 4px;
`;

interface Props {
    /**
     * The title for the toggle
     */
    title: string;
    /**
     * The tooltip text
     */
    toolTip?: string;
    /**
     * Formik field name
     */
    name: string;

    dataAutomation?: string;

    isVertical?: boolean;
}

const WidgetConfigurationToggle: React.FC<Props> = ({ title, toolTip, name, dataAutomation, isVertical = false }) => {
    return (
        <Wrapper $isVertical={isVertical}>
            <LabelTooltipWrap>
                <Label>{title}</Label>
                {toolTip && <StyledInformationTooltip>{toolTip}</StyledInformationTooltip>}
            </LabelTooltipWrap>
            <StyledToggle
                dataAutomation={`widget-configuration-toggle-${dataAutomation}`}
                size={InputSize.SMALL}
                name={name}
            />
        </Wrapper>
    );
};

export default WidgetConfigurationToggle;
