import React from 'react';
import type { Dispatch, SetStateAction } from 'react';

import type HighlightedNodes from './HighlightedNodes';
import type SpecificEditorMetadata from './SpecificEditorMetadata';

import type { FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const FormulaContext = React.createContext<{
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    highlightedNodes: HighlightedNodes | undefined;
    setHighlightedNodes: Dispatch<SetStateAction<HighlightedNodes | undefined>>;
    specificEditor: SpecificEditorMetadata | undefined;
    setSpecificEditor: Dispatch<SetStateAction<SpecificEditorMetadata | undefined>>;
    formulaDataMap: Map<string, any> | undefined;
    onInnerTrackAggregationSelected?: () => void;
    formulaChangedOutsideSpecificEditorCallback: () => void;
    fieldDefinition: FieldDefinition | undefined;
}>({} as any);

export default FormulaContext;
