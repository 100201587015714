/**
 * Returns an expression object ({originalExpression: <string>, evaluatedExpression: <string>}) from the given field definition, if valid.
 */
export function convertFieldDefinitionToExpression(fieldDefinition) {
    if (fieldDefinition?.id && fieldDefinition.name) {
        return {
            originalExpression: `{{${fieldDefinition.name}}}`,
            evaluatedExpression: `{{${fieldDefinition.id}}}`,
        };
    }

    return null;
}
