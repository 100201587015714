import template from './previewKeyMetrics.template.html?angularjs';

/**
 * Component to preview key metrics.
 */
angular.module('tonkean.app').component('tnkPreviewKeyMetrics', {
    bindings: {
        groupId: '@', // The group id we're previewing
        workflowVersionId: '@', // The group id we're previewing
        definitionsToPreview: '<', // The definitions we'd like to preview
        initiativeDefinitionsToPreview: '<', // The initiative definitions we need to preview in the list preview control.
        highlightedDefinitionId: '@', // The definition we'd like to highlight
        hidePreviewTitle: '@', // If true, will hide the Preview upper title.
        reloadPreview: '<', // If toggled to true, will reload the preview.
        softLoading: '<', // If true, won't show loading state, but rather a small loading circle
        isBrokenIntegration: '<', // A boolean indicating whether the integration is broken or not.
        showTracksLoading: '<', // A boolean indicating whether to show the loading state of the tracks
    },
    template,
    controller: 'PreviewKeyMetricsCtrl',
});
