import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import hasActiveSolutionBusinessReportGroupFilters from './hasActiveSolutionBusinessReportGroupFilters';
import mergeFieldQueries from './mergeFieldQueries';
import { useSolutionBusinessReportContext } from './SolutionBusinessReportContext';
import SolutionBusinessReportCustomFilters from './SolutionBusinessReportCustomFilters';
import SolutionBusinessReportFilters from './SolutionBusinessReportFilters';
import type { SearchInitiativeMethod } from './SolutionBusinessReportSearchInitiativesMethods';
import { INITIAL_SOLUTION_BUSINESS_REPORT_GROUP_FILTERS } from './SolutionReportConsts';
import SolutionsBusinessReportActionsModal from './SolutionsBusinessReportActionsModal/SolutionsBusinessReportActionsModal';

import { useAsyncMethod, useToastMessage } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { useIsInSharedReport } from '@tonkean/angular-hooks';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { TnkTracksEditor } from '@tonkean/angular-to-react-components';
import { useModifiableList } from '@tonkean/infrastructure';
import { InformationTooltip } from '@tonkean/infrastructure';
import { InitiativesPlaceholderContainer } from '@tonkean/infrastructure';
import { Popover } from '@tonkean/infrastructure';
import { Placeholder } from '@tonkean/infrastructure';
import { MenuItem } from '@tonkean/infrastructure';
import { SearchBox } from '@tonkean/infrastructure';
import { useDebouncedState } from '@tonkean/infrastructure';
import { KebabMenuButton } from '@tonkean/infrastructure';
import { Menu } from '@tonkean/infrastructure';
import { Accordion } from '@tonkean/infrastructure';
import { useFetchManager, LIMIT_PARAM, SKIP_PARAM } from '@tonkean/infrastructure';
import { H4 } from '@tonkean/infrastructure';
import { SystemInterfaceNavigationContextWrapper } from '@tonkean/interface-module';
import { FilterIcon } from '@tonkean/svg';
import { ColumnsIcon as ManageFieldsIcon } from '@tonkean/svg';
import { FlowRunIcon } from '@tonkean/svg';
import { FormType, InitiativeStatus, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { GroupEnvironmentFormSummary } from '@tonkean/tonkean-entities';
import type {
    BasicQueryDefinition,
    Group,
    Person,
    SolutionBusinessReport,
    SolutionBusinessReportSavedFilter,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { AccordionSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY } from '@tonkean/utils';

const Header = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
`;
const GroupAccordion = styled(Accordion).attrs({
    contentSeparator: false,
    whiteContentBackground: true,
    borderBottom: true,
    borderTop: true,
    sideBorders: true,
    shadow: true,
    radius: true,
})`
    position: relative;
    margin-top: 24px;
`;

const StyledKebab = styled(KebabMenuButton)`
    margin: 0 8px;
    width: 12px;
`;
const StyledSearch = styled(SearchBox)`
    width: 300px;
    margin-left: auto;
`;
const ItemsWrapper = styled.div<{ hasLoadMore: boolean }>`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-left: 15px;

    ${({ hasLoadMore }) =>
        hasLoadMore &&
        css`
            padding-bottom: 24px;
        `}
`;
const TracksEditorWrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
`;
const LoadMoreButton = styled(Button)`
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
`;

const LoadingState = styled(InitiativesPlaceholderContainer).attrs({ loading: true })`
    flex-grow: 1;
`;

const ViewDataInformationTooltip = styled(InformationTooltip)`
    margin-left: 8px;
`;

export interface ModuleDataFilters {
    definition?: BasicQueryDefinition;
}

interface Props {
    solutionBusinessReport: SolutionBusinessReport | undefined;
    groupId: string | undefined;
    moduleData: ModuleDataFilters | undefined;
    savedFilters: SolutionBusinessReportSavedFilter[] | undefined;

    onModuleDataChange?(moduleData: ModuleDataFilters): void;
}

const SolutionBusinessReportGroup: React.FC<Props> = ({
    solutionBusinessReport,
    groupId,
    moduleData,
    savedFilters,
    onModuleDataChange,
}) => {
    const trackHelper = useAngularService('trackHelper');
    const initiativeManager = useAngularService('initiativeManager');
    const projectManager = useAngularService('projectManager');
    const groupInfoManager = useAngularService('groupInfoManager');
    const customFieldsManager = useAngularService('customFieldsManager');
    const $rootScope = useAngularService('$rootScope');
    const modal = useAngularService('modal');
    const $location = useAngularService('$location');

    const [searchInitiativeMethod] = useGetStateParams<[SearchInitiativeMethod]>('searchInitiativeMethod');
    const [{ data: workerForms, loading: loadingForms }, getSolutionBusinessReportGroupFormsSummary] =
        useLazyTonkeanService('getSolutionBusinessReportGroupFormsSummary');

    const [forms, setForms] = useState<Omit<GroupEnvironmentFormSummary, 'additionalDataValue'>[]>();
    const [availableForms, setAvailableForms] = useState<Omit<GroupEnvironmentFormSummary, 'additionalDataValue'>[]>();

    const [accordionOpen, setAccordionOpen] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [filtersPopoverOpen, setFiltersPopoverOpen] = useState(false);
    const [initializedCaches, setInitializedCaches] = useState(false);

    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useDebouncedState(debouncedSearchTerm, setDebouncedSearchTerm);

    const [filters, setFilters] = useState(INITIAL_SOLUTION_BUSINESS_REPORT_GROUP_FILTERS);

    const modifiableSavedFilters = useModifiableList(savedFilters);

    const [actionsModalOpen, setActionsModalOpen] = useState(false);

    useEffect(() => {
        if (searchTerm) {
            setAccordionOpen(true);
        }
    }, [searchTerm]);

    useMemo(() => {
        if (workerForms) {
            setForms(workerForms?.entities);
            setAvailableForms(workerForms?.entities?.filter((form) => form.isAvailable));
        }
    }, [workerForms]);

    const group: Group | undefined = groupId ? projectManager.groupsMap[groupId] : undefined;
    const workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined = solutionBusinessReport
        ? solutionBusinessReport.workflowVersionType === WorkflowVersionType.DRAFT
            ? group?.draftWorkflowVersionId
            : group?.publishedWorkflowVersionId
        : undefined;

    const isInSharedReport = useIsInSharedReport();
    const [currentUser] = useAngularWatch(() => $rootScope['currentUser'] as Person);
    const { currentUserAccess } = useSolutionBusinessReportContext();

    const [{ data: fieldDefinitions, loading: loadingFieldDefinitions }, getFieldDefinitions] = useLazyAsyncMethod(
        customFieldsManager,
        'getFieldDefinitions',
    );

    const [{ data: fullGroup, loading: loadGroup }, getGroup] = useLazyAsyncMethod(groupInfoManager, 'getGroup');

    useEffect(() => {
        if (group) {
            getGroup(group.id, true);
        }
    }, [getGroup, group]);

    useEffect(() => {
        if (workflowVersionId && fullGroup) {
            getFieldDefinitions(workflowVersionId);
        }
    }, [fullGroup, getFieldDefinitions, workflowVersionId]);

    useEffect(() => {
        if (workflowVersionId && fieldDefinitions && solutionBusinessReport && groupId) {
            customFieldsManager.cacheFieldDefinitionsForWorkflowVersion(
                workflowVersionId,
                fieldDefinitions,
                solutionBusinessReport.id,
                groupId as TonkeanId<TonkeanType.GROUP>,
            );
            setInitializedCaches(true);
        }
    }, [customFieldsManager, fieldDefinitions, solutionBusinessReport, workflowVersionId, groupId]);

    const [[searchInitiatives], { data: initiatives, loading, loadNextPage, hasMorePages, isEmpty }] = useFetchManager(
        initiativeManager,
        searchInitiativeMethod,
        {
            getItems: (result: { data: { entities: { id: string; [key: string]: any }[] } }) => result.data.entities,
            onLoaded(result) {
                if (solutionBusinessReport) {
                    trackHelper.getRelatedInitiativesCount(
                        solutionBusinessReport.projectId,
                        result.data.entities.map(({ id }) => id),
                    );
                }
            },
            limit: 10,
        },
    );

    const searchInitiativesQuery = useMemo(() => {
        const hasFilters =
            hasActiveSolutionBusinessReportGroupFilters(filters, true) ||
            !!debouncedSearchTerm ||
            !!moduleData?.definition;

        const query = {
            projectId: projectManager.project.id,
            excludeStatuses: filters.hideDone ? [InitiativeStatus.DONE] : undefined,
            dateRange: undefined,
            returnFlat: undefined,
            onlyGroupId: groupId,
            query: isInSharedReport
                ? filters.definition
                : mergeFieldQueries(filters.definition?.query, moduleData?.definition?.query),
            searchString: debouncedSearchTerm,
            commonFilters: {
                statuses: filters.statuses,
                owners: filters.owners.map((person) => person.id),
                creators: filters.creators.map((person) => person.id),
            },
            orderByField: undefined,
            orderByFieldType: undefined,
            orderByType: undefined,
            filterOnlyRootItems: hasFilters ? undefined : true,
            onlyDraftInitiatives: solutionBusinessReport?.workflowVersionType === WorkflowVersionType.DRAFT,
            updatedAfter: solutionBusinessReport?.id as any,
        };

        return query;
    }, [
        debouncedSearchTerm,
        filters,
        groupId,
        isInSharedReport,
        moduleData?.definition,
        projectManager.project.id,
        solutionBusinessReport?.id,
        solutionBusinessReport?.workflowVersionType,
    ]);

    useEffect(() => {
        if (!solutionBusinessReport?.projectId || !groupId || !moduleData) {
            return;
        }

        const hasFilters =
            hasActiveSolutionBusinessReportGroupFilters(filters, true) ||
            !!debouncedSearchTerm ||
            !!moduleData.definition;

        searchInitiatives(
            solutionBusinessReport.projectId,
            filters.hideDone ? [InitiativeStatus.DONE] : undefined,
            undefined,
            undefined,
            groupId,
            isInSharedReport
                ? filters.definition
                : mergeFieldQueries(filters.definition?.query, moduleData.definition?.query),
            debouncedSearchTerm,
            {
                statuses: filters.statuses,
                owners: filters.owners.map((person) => person.id),
                creators: filters.creators.map((person) => person.id),
            },
            SKIP_PARAM,
            LIMIT_PARAM,
            undefined,
            undefined,
            undefined,
            hasFilters ? undefined : true,
            solutionBusinessReport.workflowVersionType === WorkflowVersionType.DRAFT,
            solutionBusinessReport.id as any,
        );
    }, [
        filters,
        debouncedSearchTerm,
        groupId,
        searchInitiatives,
        moduleData,
        isInSharedReport,
        currentUser.id,
        solutionBusinessReport?.projectId,
        solutionBusinessReport?.workflowVersionType,
        solutionBusinessReport?.id,
    ]);

    const emptySearchTermState = searchTerm && isEmpty;
    const loadingState =
        !emptySearchTermState &&
        (loading.initial || loadingFieldDefinitions || !solutionBusinessReport || !groupId) &&
        !initializedCaches;

    const moduleDataFiltersCount = moduleData?.definition?.query.filters.length;

    const showLoadMoreButton = hasMorePages && !loading.nextPageLoading;

    const onCloseActionsModal = useCallback(() => {
        setActionsModalOpen(false);
    }, []);

    const emit = useToastMessage();

    const [
        { error: updateActionsError, loading: loadingUpdateForms },
        updateSolutionBusinessReportGroupFormsAccessibility,
    ] = useLazyTonkeanService('updateSolutionBusinessReportGroupFormsAccessibility');

    const updateForms = useCallback(
        async (param: GroupEnvironmentFormSummary[]) => {
            if (solutionBusinessReport && group) {
                try {
                    const updateFormsResponse = await updateSolutionBusinessReportGroupFormsAccessibility(
                        solutionBusinessReport.projectId,
                        group.id,
                        solutionBusinessReport.id,
                        param
                            .filter((record) => record.isAvailable)
                            .map((record) => {
                                return record.id;
                            }),
                    );
                    // Only if we succeed we update the forms list
                    if (updateFormsResponse.status === 200) {
                        setForms(param);
                        setAvailableForms(param.filter((form) => form.isAvailable));
                        setActionsModalOpen(false);
                    }
                } catch {
                    emit(
                        updateActionsError?.data?.error?.message ||
                            'Failed to update solution business report actions.',
                    );
                }
            }
        },
        [
            solutionBusinessReport,
            group,
            updateSolutionBusinessReportGroupFormsAccessibility,
            emit,
            updateActionsError?.data?.error?.message,
        ],
    );

    useEffect(() => {
        if (solutionBusinessReport && group) {
            getSolutionBusinessReportGroupFormsSummary(
                solutionBusinessReport.projectId,
                group.id,
                solutionBusinessReport.id,
                solutionBusinessReport.workflowVersionType,
                '',
                FormType.UPDATE,
                false,
            );
        }
    }, [getSolutionBusinessReportGroupFormsSummary, group, group?.id, solutionBusinessReport]);

    const [{ data: defaultItemInterfaceId }, getDefaultItemInterfaceId] =
        useLazyTonkeanService('getDefaultItemInterfaceId');
    useEffect(() => {
        if (workflowVersionId) {
            getDefaultItemInterfaceId(workflowVersionId);
        }
    }, [getDefaultItemInterfaceId, workflowVersionId]);

    const [urlInitiativeId] = useAngularWatch(() => $location.search().tid) as [TonkeanId<TonkeanType.INITIATIVE>];
    const { data: initiative } = useAsyncMethod(trackHelper, 'getInitiativeById', urlInitiativeId);

    /**
     * We must validate that the initiative belongs to the group, otherwise we have a security issue where users can
     * swap the initiative id in the URL and view the item.
     * We either check that the initiative is actually displayed on the screen, or that the group of the initiative is the one we need.
     * We use the second check because inner items are not included in the `initiatives` array.
     */
    const isSelectedInitiativeInGroup = useMemo(() => {
        return (
            initiatives.some((_initiative) => _initiative.id === urlInitiativeId) || initiative?.group.id === groupId
        );
    }, [groupId, initiative?.group.id, initiatives, urlInitiativeId]);

    return (
        <>
            <GroupAccordion
                open={accordionOpen}
                onChange={setAccordionOpen}
                size={AccordionSize.SMALL}
                title={
                    <Header>
                        <H4 $bold>{group?.name ?? <Placeholder $width="100px" $height="20px" />}</H4>
                        {!isInSharedReport && (
                            <>
                                <Menu
                                    show={menuOpen}
                                    menuItems={
                                        <>
                                            <MenuItem
                                                icon={<ManageFieldsIcon />}
                                                disabled={!solutionBusinessReport}
                                                onClick={() => {
                                                    if (solutionBusinessReport) {
                                                        modal.openManageFieldsModal(
                                                            groupId,
                                                            workflowVersionId,
                                                            true,
                                                            solutionBusinessReport.id,
                                                            solutionBusinessReport.workflowVersionType,
                                                        );
                                                    }
                                                }}
                                            >
                                                Manage Fields
                                            </MenuItem>
                                            <MenuItem
                                                icon={<FlowRunIcon />}
                                                disabled={!solutionBusinessReport}
                                                onClick={() => setActionsModalOpen(true)}
                                            >
                                                Manage Report Actions
                                            </MenuItem>
                                        </>
                                    }
                                    onClose={() => setMenuOpen(false)}
                                >
                                    <StyledKebab
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setMenuOpen(true);
                                        }}
                                        buttonAsDiv
                                        flat
                                        thin
                                    />
                                </Menu>
                                {group && workflowVersionId && (
                                    <>
                                        <Popover
                                            content={
                                                groupId && workflowVersionId ? (
                                                    <SolutionBusinessReportCustomFilters
                                                        groupId={groupId}
                                                        workflowVersionId={workflowVersionId}
                                                        filters={moduleData?.definition}
                                                        onFiltersChange={(definition) => {
                                                            onModuleDataChange?.({ definition });
                                                            setFiltersPopoverOpen(false);
                                                        }}
                                                        onCancel={() => setFiltersPopoverOpen(false)}
                                                    />
                                                ) : null
                                            }
                                            show={filtersPopoverOpen}
                                            onClose={() => setFiltersPopoverOpen(false)}
                                        >
                                            <Button
                                                size={ButtonSize.SMALL}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setFiltersPopoverOpen(true);
                                                }}
                                                disabled={!groupId || !workflowVersionId}
                                                cancel={!moduleDataFiltersCount}
                                                outlined={!!moduleDataFiltersCount}
                                                buttonAsDiv
                                                flex
                                            >
                                                <FilterIcon />
                                                Module Data {!!moduleDataFiltersCount && `(${moduleDataFiltersCount})`}
                                            </Button>
                                        </Popover>

                                        <ViewDataInformationTooltip>
                                            "Module data" defines the data which is used for this specific module in the
                                            report.
                                            <br />
                                            The "Module data" conditions can not be revised when using the shareable
                                            link.
                                        </ViewDataInformationTooltip>
                                    </>
                                )}
                            </>
                        )}
                        <StyledSearch
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                            onClick={(event) => event.stopPropagation()}
                        />
                    </Header>
                }
            >
                <SolutionBusinessReportFilters
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    filters={filters}
                    savedFiltersModifiableList={modifiableSavedFilters}
                    solutionBusinessReportId={solutionBusinessReport?.id}
                    onFiltersChange={setFilters}
                />

                <ItemsWrapper hasLoadMore={showLoadMoreButton}>
                    {emptySearchTermState && (
                        <div className="padding-normal common-size-xxs common-color-grey flex-vmiddle">
                            <i className="fa fa-search fa-2x common-color-primary margin-right" />
                            <div>
                                <div className="common-section-title-sm">No matches found for what you searched</div>
                                <div className="common-subtitle-inner">Try searching again. Sorry!</div>
                            </div>
                        </div>
                    )}

                    {loadingState && <LoadingState />}

                    {!emptySearchTermState && !loadingState && (
                        <TracksEditorWrapper>
                            <TnkTracksEditor
                                tracks={initiatives}
                                editorId={`SolutionBusinessReport-${solutionBusinessReport?.id}-${groupId}`}
                                lazyLoadTracks={false}
                                customFields={fieldDefinitions}
                                groupId={groupId}
                                onlyGroup={groupId}
                                workflowVersionId={workflowVersionId}
                                searchInitiativesQuery={searchInitiativesQuery}
                                loadNextInitiativesPageCallback={loadNextPage}
                                hasMoreInitiatives={loading.nextPageLoading}
                                viewOnlyMode={currentUserAccess.VIEW && !currentUserAccess.EDIT}
                                onlyUpdateExistingItems={isInSharedReport}
                                solutionBusinessReportId={solutionBusinessReport?.id}
                                environment={solutionBusinessReport?.workflowVersionType}
                                showActionsColumn={!isInSharedReport || currentUserAccess.EDIT}
                                workerForms={availableForms}
                                defaultItemInterfaceId={defaultItemInterfaceId?.defaultItemInterfaceId}
                                showDone={!filters.hideDone}
                                allowDndOnRoot
                                showAddOnEmpty
                            />
                        </TracksEditorWrapper>
                    )}
                </ItemsWrapper>

                {showLoadMoreButton && (
                    <LoadMoreButton onClick={loadNextPage} size={ButtonSize.MEDIUM} outlined>
                        Load more...
                    </LoadMoreButton>
                )}
            </GroupAccordion>
            {solutionBusinessReport && group && !loadingForms && (
                <SolutionsBusinessReportActionsModal
                    open={actionsModalOpen}
                    onClose={onCloseActionsModal}
                    workerForms={forms || EMPTY_ARRAY}
                    group={group}
                    updateForms={updateForms}
                    loadingUpdateForms={loadingUpdateForms}
                />
            )}
            <SystemInterfaceNavigationContextWrapper open={Boolean(isSelectedInitiativeInGroup)} />
        </>
    );
};

export default SolutionBusinessReportGroup;
