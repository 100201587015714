function InlineTextboxCtrl($scope) {
    $scope.as = $scope.$root.as;

    $scope.data = {
        isFocus: false,
    };

    $scope.$watch('data.isFocus', function () {
        if (!$scope.data.isFocus && $scope.message && $scope.message.length && $scope.onLeave) {
            $scope.onLeave();
        }
    });

    $scope.onMessageKeydown = function ($event) {
        // console.log($event);
        if ($scope.submit && $event.ctrlKey && $event.keyCode === 13) {
            // on Ctrl+Enter
            $scope.submit();
        }
    };
}
export default angular.module('tonkean.app').controller('InlineTextboxCtrl', InlineTextboxCtrl);
