import FunctionOperatorBase from './FunctionOperatorBase';

import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';

/**
 * This is a mock function for inner track aggregation.
 */
class InnerAggregationFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.INNER_TRACK_AGGREGATION;
    override readonly sign = 'Inner items aggregation field';
    override readonly displayName = 'Inner items aggregation field';
    override readonly description = 'Defines an aggregation over the inner items of a workflow item.';
    override readonly dataType = FieldType.String;

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [];
}

export default InnerAggregationFunction;
