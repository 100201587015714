import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import type { StylesConfig } from 'react-select';
import styled from 'styled-components';

import type BaseProjectIntegrationEntityProps from '../../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import type { AdditionalSidePaneConfiguration } from '../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { AutocompleteSelector, FormikAutosave, H1, H4, Paragraph, SavingIndicator } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const BodyWrapper = styled(Form)`
    margin: 20px 0;
    padding: 16px;
    max-width: 500px;
    border: 1px solid ${Theme.colors.gray_300};
    background-color: #f7f8fa;
    border-radius: 4px;
`;

const TitleWrapper = styled.div`
    margin-bottom: 10px;

    display: flex;
    align-items: center;
`;

const StyledParagraph = styled(Paragraph)`
    line-height: 16px;
    color: ${Theme.colors.gray_600};
`;

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Subtitle = styled(H4)`
    margin-bottom: 8px;
    font-weight: 400;
`;

const selectStyles: StylesConfig<SimpleSelectSingleOption<string>> = {
    multiValue: () => ({
        height: 20,
        background: Theme.colors.gray_300,
        borderRadius: 100,
        display: 'flex',
        alignItems: 'center',
        margin: 3,
    }),
    multiValueLabel: () => ({
        background: 'transparent',
        color: Theme.colors.gray_800,
        fontWeight: 'normal',
        lineHeight: '14px',
    }),
    multiValueRemove: (_, state) => ({
        padding: 0,
        marginRight: 3,
        background: Theme.colors.gray_300,
        color: Theme.colors.gray_500,
        [`${state['isFocused'] ? '&, ' : ''}&:hover`]: {
            color: Theme.colors.gray_800,
            background: Theme.colors.gray_300,
        },
    }),
};

const ProjectIntegrationEntityEncryptionPage: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>
> = ({ projectIntegration, entity, setEntity }) => {
    const [{ loading }, getAvailableExternalFields] = useLazyTonkeanService('getAvailableExternalFields');

    const [{ loading: updateLoading, error: updateError }, updateProjectIntegrationEntityEncryptedFields] =
        useLazyTonkeanService('updateProjectIntegrationEntityEncryptedFields');

    const onSubmit = useCallback(
        async ({ encryptedFields }) => {
            setEntity(
                await updateProjectIntegrationEntityEncryptedFields(projectIntegration.id, entity.id, encryptedFields),
            );
        },
        [setEntity, updateProjectIntegrationEntityEncryptedFields, projectIntegration.id, entity],
    );

    const getFieldsAutocomplete = useCallback(
        async (_) => {
            const data = await getAvailableExternalFields(projectIntegration.id, entity.id);
            const entitiesWithLabels: { name: string; label: string }[] = data.entitiesWithLabels || [];
            return entitiesWithLabels.map((field) => ({ id: field.name, displayName: field.label }));
        },
        [getAvailableExternalFields, projectIntegration, entity],
    );

    return (
        <>
            <TitleWrapper>
                <H1 style={{ marginRight: '8px' }}>Encryption</H1>
                <SavingIndicator loading={updateLoading} error={updateError} />
            </TitleWrapper>

            <StyledParagraph $light>
                Select data source fields to encrypt, protecting sensitive data in your data source.
                <br />
                Once selected, the fields are immediately encrypted.
                <br />
                <br />
                You must generate an "Encryption Key" to encrypt selected values.
            </StyledParagraph>

            <Formik initialValues={{ encryptedFields: entity.encryptedFields }} onSubmit={onSubmit}>
                <BodyWrapper>
                    <FieldWrapper>
                        <Subtitle>Exact JSON/XML keys to encrypt</Subtitle>
                        <AutocompleteSelector
                            placeholder={loading ? 'Loading fields...' : 'Select Field'}
                            onDebouncedSearchGetResults={getFieldsAutocomplete}
                            valueKey="id"
                            labelKey="displayName"
                            name="encryptedFields"
                            styles={selectStyles}
                            isMulti
                            isCreatable
                        />
                    </FieldWrapper>
                    <FormikAutosave />
                </BodyWrapper>
            </Formik>
        </>
    );
};

export default ProjectIntegrationEntityEncryptionPage;
