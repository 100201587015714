<div class="margin-left margin-right">
    <i ng-if="data.loading" class="loading"></i>

    <!-- RSVP Event Custom Action -->
    <div ng-if="!data.loading && data.definition.customActionKey === 'RSVP_EVENT'">
        <!-- Attendee Email -->
        <div class="margin-bottom">
            <div>Attendee Email</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Attendee email address"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.attendeeEmail"
                saved-evaluated-expression="data.attendeeEmailEvaluated"
                expression-unique-identifier="GCAttendeeEmail"
                on-tonkean-expression-changed="
                    onEmailTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Response Status-->
        <div class="flex-vmiddle">
            <div class="margin-right">Response:</div>

            <div uib-dropdown>
                <button type="button" class="btn btn-default dropdown-toggle" uib-dropdown-toggle>
                    <span>{{ data.responseStatusOptions[data.attendeeResponseStatusKey].label }}</span>
                    <span class="dropdown-chevron mod-static"></span>
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu>
                    <li ng-repeat="(key, option) in data.responseStatusOptions">
                        <a ng-click="selectResponseStatus(key)">{{ option.label }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Invite Attendees Custom Action -->
    <div ng-if="!data.loading && data.definition.customActionKey === 'INVITE_ATTENDEES'">
        <!-- Attendees Email -->
        <div class="margin-bottom">
            <div>Attendees Email</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Attendees email address"
                saved-original-expression="data.inviteAttendees.attendeesEmails"
                saved-evaluated-expression="data.inviteAttendees.attendeesEmailsEvaluated"
                on-tonkean-expression-changed="
                    onAttendeesEmailsChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
