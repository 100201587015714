import template from './channelSelector.template.html?angularjs';

/**
 * Component to select communication integration's channels.
 */
angular.module('tonkean.app').component('tnkChannelSelector', {
    bindings: {
        selectedChannel: '<', // The selected channel reference.
        reloadChannels: '<', // A flag passed from parents that tells the component it should update its channel options.
        enableClearChannel: '<', // When set to true, a small "x" appears in the input, allowing the user to clear the selected channel.
        classes: '@', // Classes to apply on the field selector ui-select.
        onChannelsLoaded: '&', // A function called every time the channel auto-complete options are reloaded.
        onChannelSelected: '&', // A function called when a channel is selected.
        inSentenceMode: '<', // When set to true, the whole component is rendered for in-sentence purposes, using the tnkDropdownSearch component.
        viewOnly: '<',
        appendToBody: '<',
        selectorTooltip: '<',
        showRefreshButton: '<',
        selectedIntegration: '<', // An integration object (not project integration) to work as a replacement for the current communication object in this component
    },
    template,
    controller: 'ChannelSelectorCtrl',
});
