import ManualFieldValidatorUtils from './ManualFieldValidatorUtils';

/**
 * This class is responsible on validating Create Or Update action.
 */
class ManualFieldCreateOrUpdateValidator {
    public static validateAction(definition: any, validationObject: any, projectManager: any): void {
        const fieldsErrorsValidation = {};
        switch (definition.integrationType) {
            case 'GOOGLESHEETS':
                ManualFieldValidatorUtils.integrationFieldUpdateValidator(
                    definition,
                    definition.performedActionDefinition,
                    fieldsErrorsValidation,
                    projectManager,
                );
                if (Object.keys(fieldsErrorsValidation).length > 0) {
                    validationObject.requiredFields = "Can't use 'Create Or Update Row' action in append.";
                    return;
                }
        }

        // Validating required fields
        ManualFieldValidatorUtils.integrationFieldCreateValidator(definition, validationObject);
    }
}

export default ManualFieldCreateOrUpdateValidator;
