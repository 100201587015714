import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Input } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ErrorParameterText = styled.div`
    cursor: default;
    color: ${Theme.colors.error};
`;

const AddParamInput = styled(Input)`
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 3px;
`;

const AddParamButton = styled(Clickable)<{ $isNotValid?: boolean }>`
    ${({ $isNotValid }) =>
        $isNotValid &&
        css`
            cursor: default !important;
            color: ${Theme.colors.gray_500} !important;
        `};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.primary};
    margin-top: 8px;
`;

interface Props {
    isNameValid: (name: string, id?: string) => boolean;
    setNewParamName: (name: string) => void;
    newParamName: string;
    setActiveInput: () => void;
}

const ProjectIntegrationActionInputPredefinedParameters: React.FC<Props> = ({
    isNameValid,
    setNewParamName,
    newParamName,
    setActiveInput,
}) => {
    const isPredefinedNameValid = useMemo(() => {
        return isNameValid(newParamName);
    }, [isNameValid, newParamName]);

    return (
        <>
            <AddParamInput
                isError={!isPredefinedNameValid && newParamName !== ''}
                onFocus={() => setActiveInput()}
                placeholder="Type param name"
                value={newParamName}
                onChange={(event) => setNewParamName(event.target.value)}
                data-automation="project-integration-action-parameters-add-param"
            />
            <AddParamButton
                $isNotValid={!isPredefinedNameValid}
                disabled={!isPredefinedNameValid}
                type="submit"
                data-automation="project-integration-action-parameters-add-param-button"
            >
                + Add Param
            </AddParamButton>
            {!isPredefinedNameValid && newParamName && (
                <ErrorParameterText>Parameter already exists</ErrorParameterText>
            )}
        </>
    );
};

export default ProjectIntegrationActionInputPredefinedParameters;
