import { useMemo } from 'react';

import type { BaseProjectIntegrationActionManagerProps } from './baseProjectIntegrationActionManagerProps';
import { ProjectIntegrationActionManagerTabKey } from './ProjectIntegrationActionManagerTabKey';
import type {
    SidePaneBlockConfiguration,
    SidePaneConfiguration,
} from '../../modules/GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ProjectIntegrationActionGeneralTab from '../../modules/ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationActionGeneralTab/ProjectIntegrationActionGeneralTab';
import ProjectIntegrationActionHandleResponseTab from '../../modules/ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationActionHandleResponseTab/ProjectIntegrationActionHandleResponseTab';
import ProjectIntegrationActionPageHeader from '../../modules/ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationActionPageHeader/ProjectIntegrationActionPageHeader';
import ProjectIntegrationActionRequestTab from '../../modules/ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationActionRequestPage/ProjectIntegrationActionRequestTab';

const useProjectIntegrationActionManagerConfiguration = () => {
    return useMemo(() => {
        const settingBlock: SidePaneBlockConfiguration<BaseProjectIntegrationActionManagerProps> = {
            displayName: 'Settings',
            key: 'settings',
            subTabsConfigurations: [
                {
                    displayName: 'General',
                    key: 'general',
                    component: ProjectIntegrationActionGeneralTab,
                },
                {
                    displayName: 'Request',
                    key: ProjectIntegrationActionManagerTabKey.REQUEST,
                    component: ProjectIntegrationActionRequestTab,
                },
                {
                    displayName: 'Response Handling',
                    key: ProjectIntegrationActionManagerTabKey.HANDLE_RESPONSE,
                    component: ProjectIntegrationActionHandleResponseTab,
                },
            ],
        };

        const sidePaneConfiguration: SidePaneConfiguration<BaseProjectIntegrationActionManagerProps> = {
            sidePaneBlockConfiguration: [settingBlock],
            headerComponent: ProjectIntegrationActionPageHeader,
        };

        return sidePaneConfiguration;
    }, []);
};

export default useProjectIntegrationActionManagerConfiguration;
