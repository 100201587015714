/**
 * Related entities is a map of id to entity, and not an array of entities.
 * In order to extract the entities from it, we need to loop through its keys and take the entities.
 */
export function getEntitiesFromRelatedEntitiesMap<T>(relatedEntitiesMap: Record<string, T>) {
    if (!relatedEntitiesMap) {
        return;
    }

    const extractedEntitiesArray: T[] = [];
    for (const key in relatedEntitiesMap) {
        if (relatedEntitiesMap.hasOwnProperty(key)) {
            extractedEntitiesArray.push(relatedEntitiesMap[key]!);
        }
    }

    return extractedEntitiesArray;
}
