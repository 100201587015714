import React, { useMemo } from 'react';

import SolutionVersionsList from './components/SolutionVersionsList';

import { SidePane } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { getScrollbarWidth } from '@tonkean/utils';

const SIDE_PANE_WIDTH = 350;

interface Props {
    workflowFolder?: WorkflowFolder;
    onClose(): void;
    onExitComplete?(): void;
}

const SolutionVersions: React.FC<Props> = ({ workflowFolder, onClose, onExitComplete }) => {
    const sidepaneWidth = useMemo(() => {
        const scrollbarWidth = getScrollbarWidth();
        return scrollbarWidth + SIDE_PANE_WIDTH;
    }, []);

    return (
        <SidePane
            open={!!workflowFolder}
            width={sidepaneWidth}
            onClose={onClose}
            top="50px"
            onExitComplete={onExitComplete}
        >
            {workflowFolder && <SolutionVersionsList onClose={onClose} workflowFolder={workflowFolder} />}
        </SidePane>
    );
};

export default SolutionVersions;
