import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from '../../../../../images/icons/trash-form.svg';

import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Field, Input } from '@tonkean/infrastructure';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const NameField = styled(Field)`
    margin-bottom: 20px;
`;

const RightIconWrapper = styled.div`
    margin-left: auto;
    position: absolute;
    top: 5px;
    right: 20px;
`;

const EditorWrapper = styled.div`
    position: relative;
    background: ${Theme.colors.gray_100};
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 3px;
    padding: 14px;
    margin: 12px 0;
`;

const StyledIconButton = styled(IconButton)`
    z-index: 2;
`;

interface Props {
    name: string;

    onRemove(): void;

    expressionProps?: FormikExpressionProps;
}

const SingleNextPageParameterEditor: React.FC<Props> = ({ name, onRemove, expressionProps }) => {
    const [, valueProps] = useField<TonkeanExpressionDefinition>(`${name}.parameterValue.originalExpression`);

    return (
        <EditorWrapper>
            <RightIconWrapper>
                <StyledIconButton onClick={onRemove} flat>
                    <TrashIcon />
                </StyledIconButton>
            </RightIconWrapper>

            <NameField label="Name">
                <Input placeholder="Enter parameter name" name={`${name}.parameterName`} size={InputSize.LARGE} />
            </NameField>

            <Field label="Value" error={valueProps.error}>
                <FormikTonkeanExpression
                    {...expressionProps}
                    placeholder="Extract value from previous response"
                    name={`${name}.parameterValue`}
                />
            </Field>
        </EditorWrapper>
    );
};

export default SingleNextPageParameterEditor;
