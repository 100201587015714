import React, { useMemo } from 'react';

import { ContractIcon } from '@tonkean/contracts';
import { IconSvg } from '@tonkean/infrastructure';
import { ModuleActionIcon } from '@tonkean/svg';
import { GoToSequenceIcon } from '@tonkean/svg';
import { DuplicateIcon } from '@tonkean/svg';
import { CursorClickIcon, FormIcon } from '@tonkean/svg';
import {
    type ContractFieldSummary,
    type CustomTrigger,
    type Form,
    FormQuestionType,
    FormType,
    type IntakeSequenceCustomTriggerData,
    type InterfaceCTAActionOption,
    InterfaceCTAActionType,
    type InterfaceCTASavedIntakeSequenceAction,
    type InterfaceCTASavedMatchedItemAction,
    type MatchedItemActionData,
    OptionsTypeTitles,
    type TonkeanId,
    type TonkeanType,
    type ContractInterfaceData,
} from '@tonkean/tonkean-entities';

export const getOpenFormActionOptions = (forms: Form[]): InterfaceCTAActionOption[] => {
    return (
        forms
            .filter((form): form is { id: TonkeanId<TonkeanType.FORM> } & Form => !!form.id)
            .map((form) => ({
                savedEntityTemplate: {
                    type: InterfaceCTAActionType.OPEN_FORM,
                    id: form.id,
                    defaultLabel: form.displayName,
                },
                icon: <IconSvg as={FormIcon} size={16} />,
                groupTitle: OptionsTypeTitles.UPDATE_FORMS,
            })) || []
    );
};

export const getIntakeSequenceActionOption = (
    item: IntakeSequenceCustomTriggerData,
    matchedItemName?: string,
): InterfaceCTAActionOption => {
    let itemLabel: string = item.customTriggerDisplayName;
    let groupItemTitle: string = OptionsTypeTitles.INTAKE_SEQUENCES;

    const isMatchedItemOption = Boolean(matchedItemName);
    if (isMatchedItemOption) {
        itemLabel = `${matchedItemName} - ${itemLabel}`;
        groupItemTitle = `${OptionsTypeTitles.MATCHED_ITEM} - ${matchedItemName} - ${OptionsTypeTitles.INTAKE_SEQUENCES}`;
    }

    const savedEntityTemplate: InterfaceCTASavedIntakeSequenceAction = {
        id: item.customTriggerId,
        label: undefined,
        type: InterfaceCTAActionType.OPEN_INTAKE_SEQUENCE,
        defaultLabel: itemLabel,
        groupId: item.groupId,
    };

    return {
        savedEntityTemplate,
        icon: <IconSvg as={GoToSequenceIcon} size={16} />,
        groupTitle: groupItemTitle,
    };
};

export const getContractFieldActionOption = (
    item: ContractFieldSummary,
    groupId: TonkeanId<TonkeanType.GROUP>,
    matchedItemName: string | undefined,
    fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>,
): InterfaceCTAActionOption => {
    let itemLabel: string = item.displayName;
    let groupItemTitle: string = OptionsTypeTitles.CONTRACT_FIELDS;

    const isMatchedItemOption = Boolean(matchedItemName);
    if (isMatchedItemOption) {
        itemLabel = `${matchedItemName} - ${itemLabel}`;
        groupItemTitle = `${OptionsTypeTitles.MATCHED_ITEM} - ${matchedItemName} - ${OptionsTypeTitles.CONTRACT_FIELDS}`;
    }

    const interfaceData: ContractInterfaceData = {
        contractId: item.contractId,
        contractFieldId: item.contractFieldId,
        interfaceDisplayName: item.displayName,
        groupId,
    };

    const savedEntityTemplate: InterfaceCTASavedMatchedItemAction = {
        id: `${fieldDefinitionId}-${item.contractFieldId}`,
        label: undefined,
        type: InterfaceCTAActionType.OPEN_MATCHED_ITEM,
        contractField: interfaceData,
        defaultLabel: itemLabel,
    };

    return {
        savedEntityTemplate,
        icon: <ContractIcon />,
        groupTitle: groupItemTitle,
    };
};

const useMatchedItemOptions = (
    forms: Form[] | undefined,
    buttonCustomTriggers: CustomTrigger[],
    enableCloneAction: boolean | undefined,
    matchedItemsActionsData?: MatchedItemActionData[],
) => {
    const systemOptionData = useMemo(() => {
        const systemOption: InterfaceCTAActionOption[] = [];
        if (enableCloneAction) {
            systemOption.push({
                savedEntityTemplate: {
                    type: InterfaceCTAActionType.CLONE_ITEM,
                    id: InterfaceCTAActionType.CLONE_ITEM,
                    defaultLabel: 'Clone Item',
                },
                icon: <IconSvg as={DuplicateIcon} size={16} />,
                groupTitle: OptionsTypeTitles.SYSTEM_OPTIONS,
            });
        }

        return systemOption;
    }, [enableCloneAction]);

    const updateForms = useMemo(() => {
        const filteredForms: Form[] =
            forms
                ?.filter(
                    (form) =>
                        form.formType === FormType.UPDATE &&
                        (form.formQuestionType !== FormQuestionType.UPLOAD_FILES ||
                            (form.formQuestionType === FormQuestionType.UPLOAD_FILES &&
                                !!form.definition.dataStorageId &&
                                !!form.definition.destinationFolderId?.originalExpression)),
                )
                .map((item) => {
                    return { ...item, groupTitle: OptionsTypeTitles.FORMS };
                }) || [];

        return getOpenFormActionOptions(filteredForms);
    }, [forms]);

    const customTriggerButtonActions: InterfaceCTAActionOption[] = useMemo(() => {
        return buttonCustomTriggers.map((customTrigger) => {
            return {
                savedEntityTemplate: {
                    type: InterfaceCTAActionType.TRIGGER_WORKFLOW,
                    id: customTrigger.id,
                    defaultLabel: customTrigger.displayName,
                },
                icon: <IconSvg as={CursorClickIcon} size={16} color="#FF7A00" />,
                groupTitle: OptionsTypeTitles.EVENT_TRIGGERS,
            };
        });
    }, [buttonCustomTriggers]);

    const matchedItemOptions: InterfaceCTAActionOption[] = useMemo(() => {
        return (
            matchedItemsActionsData?.flatMap((matchedField) => {
                const matchedItemName = matchedField.linkedCustomTriggerDisplayName || '';
                const actionsMap =
                    matchedField.interfaces?.map((item) => {
                        const copiedItem = { ...item };
                        // original values for current item interfaces options
                        let itemId = copiedItem.interfaceId.toString();
                        let itemLabel = copiedItem.interfaceDisplayName;
                        let groupItemTitle = OptionsTypeTitles.INTERFACES.toString();

                        // matched items interfaces options
                        if (Boolean(matchedField.fieldDefinitionId)) {
                            itemId = `${matchedField.fieldDefinitionId}-${itemId}`;
                            itemLabel = `${matchedItemName} - ${itemLabel}`;
                            groupItemTitle = `${OptionsTypeTitles.MATCHED_ITEM} - ${matchedItemName}`;
                        }

                        // remove the prefix of the interface id to match the interface id format
                        if (copiedItem.interfaceId.startsWith('Interface-')) {
                            copiedItem.interfaceId = copiedItem.interfaceId.replace(
                                'Interface-',
                                '',
                            ) as `ITIN${string}`;
                        }

                        return {
                            savedEntityTemplate: {
                                id: itemId,
                                label: itemLabel,
                                type: InterfaceCTAActionType.OPEN_MATCHED_ITEM,
                                interface: copiedItem,
                                defaultLabel: itemLabel,
                            } as InterfaceCTASavedMatchedItemAction,
                            icon: <IconSvg as={ModuleActionIcon} size={16} />,
                            groupTitle: groupItemTitle,
                        };
                    }) || [];

                if (matchedField.fieldDefinitionId) {
                    const defaultTitle = `${matchedItemName} (Default Interface)`;

                    const defaultTemplate: InterfaceCTASavedMatchedItemAction = {
                        id: matchedField.fieldDefinitionId,
                        label: defaultTitle,
                        type: InterfaceCTAActionType.OPEN_MATCHED_ITEM,
                        defaultLabel: defaultTitle,
                    };
                    actionsMap.push({
                        savedEntityTemplate: defaultTemplate,
                        icon: <IconSvg as={ModuleActionIcon} size={16} />,
                        groupTitle: `${OptionsTypeTitles.MATCHED_ITEM} - ${matchedItemName}`,
                    });
                }
                return actionsMap;
            }) || []
        );
    }, [matchedItemsActionsData]);

    const intakeSequenceOptions: InterfaceCTAActionOption[] = useMemo(() => {
        if (!matchedItemsActionsData) {
            return [];
        }
        return matchedItemsActionsData?.flatMap((matchedField) => {
            if (!matchedField.intakeSequences) {
                return [];
            }

            const matchedItemName = matchedField.fieldDefinitionId
                ? matchedField.linkedCustomTriggerDisplayName
                : undefined;

            const actionsMap = matchedField.intakeSequences?.flatMap((intakeSequence) =>
                getIntakeSequenceActionOption(intakeSequence, matchedItemName),
            );
            return actionsMap;
        });
    }, [matchedItemsActionsData]);

    const contractFieldOptions: InterfaceCTAActionOption[] = useMemo(() => {
        if (!matchedItemsActionsData) {
            return [];
        }
        return matchedItemsActionsData
            .filter((matchedActionData) => matchedActionData.fieldDefinitionId != null)
            .flatMap((matchedActionData) => {
                if (!matchedActionData.contractFields || !matchedActionData.groupId) {
                    return [];
                }

                const groupId = matchedActionData.groupId;
                const fieldDefinitionId = matchedActionData.fieldDefinitionId!;
                const actionsMap = matchedActionData.contractFields?.flatMap((contractField) =>
                    getContractFieldActionOption(
                        contractField,
                        groupId,
                        matchedActionData.linkedCustomTriggerDisplayName,
                        fieldDefinitionId,
                    ),
                );
                return actionsMap;
            });
    }, [matchedItemsActionsData]);

    return [
        ...systemOptionData,
        ...updateForms,
        ...customTriggerButtonActions,
        ...matchedItemOptions,
        ...intakeSequenceOptions,
        ...contractFieldOptions,
    ];
};

export default useMatchedItemOptions;
