import React, { useState } from 'react';
import styled from 'styled-components';

import { Chevron, ChevronDirection, CollapsibleContent, SafeHtml, Spacer } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';

const ExpandButton = styled(Clickable)`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_800};
    display: flex;
    align-items: center;
    line-height: 1.4;
`;

const ExpandedDescription = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    white-space: pre-wrap;
`;

interface Props {
    form: Form;
    expandableDescription: string;
}

const ExpandableFormDescription: React.FC<Props> = ({ form, expandableDescription }) => {
    const [open, setOpen] = useState(!form.collapseDescriptionByDefault);

    if (!expandableDescription?.trim()) {
        return <></>;
    }

    return (
        <>
            <Spacer height={10} />
            <ExpandButton onClick={() => setOpen((prevOpen) => !prevOpen)}>
                {form.expandableDescriptionLabel && form.expandableDescriptionLabel.length > 0
                    ? form.expandableDescriptionLabel
                    : 'Description'}
                <Spacer width={5} />
                <Chevron direction={open ? ChevronDirection.DOWN : ChevronDirection.RIGHT} />
            </ExpandButton>
            <Spacer height={6} />
            <CollapsibleContent open={open}>
                <ExpandedDescription>
                    <SafeHtml unsafeHtml={expandableDescription} />
                </ExpandedDescription>
            </CollapsibleContent>
        </>
    );
};

export default ExpandableFormDescription;
