import type React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

import { CORE_EDITOR_TOOLBAR_BUTTON_WIDTH_HEIGHT } from '../../CoreEditorModule/components/toolbar/CoreEditorToolbarButton';
import CoreEditorToolbarSeparator, {
    CORE_EDITOR_VERTICAL_SEPARATOR_WIDTH,
} from '../../CoreEditorModule/components/toolbar/CoreEditorToolbarSeparator';

import { useResizeObserver } from '@tonkean/infrastructure';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';

interface ShowMoreOptionsConfiguration {
    showMoreOptions: boolean;
    visiblePluginsCount: number;
    visibleEndsWithSeparator: boolean;
}

/**
 * Calculate how many toolbar buttons can fit the toolbar row, and how much should be in the more options menu.
 */
function useCoreEditorCalculateShowMoreOptions(toolbarComponents: React.ReactElement[][]) {
    const [showMoreOptionsConfiguration, setShowMoreOptionsConfiguration] = useState<ShowMoreOptionsConfiguration>();

    const wrapperRef = useRef<HTMLDivElement>(null);

    const calculateShowMoreOptionsConfiguration = useConstantRefCallback(
        (width: number = wrapperRef.current?.offsetWidth || 0) => {
            const moreOptionsWidth = CORE_EDITOR_VERTICAL_SEPARATOR_WIDTH + CORE_EDITOR_TOOLBAR_BUTTON_WIDTH_HEIGHT;
            const allowedWidth = width - moreOptionsWidth;

            const { visiblePluginsCount, visibleEndsWithSeparator, showMoreOptions } = toolbarComponents.reduce(
                (prevData, components) => {
                    if (prevData.showMoreOptions) {
                        return prevData;
                    }

                    const pluginWidth = components.reduce((prevPluginWidth, component) => {
                        const componentWidth =
                            component.type === CoreEditorToolbarSeparator
                                ? CORE_EDITOR_VERTICAL_SEPARATOR_WIDTH
                                : CORE_EDITOR_TOOLBAR_BUTTON_WIDTH_HEIGHT;

                        return prevPluginWidth + componentWidth;
                    }, 0);
                    const calculatedWidth = prevData.calculatedWidth + pluginWidth;

                    const isVisible = calculatedWidth < allowedWidth;
                    if (isVisible) {
                        return {
                            calculatedWidth,
                            visibleEndsWithSeparator: pluginWidth === CORE_EDITOR_VERTICAL_SEPARATOR_WIDTH,
                            visiblePluginsCount: prevData.visiblePluginsCount + 1,
                            showMoreOptions: false,
                        };
                    }
                    return { ...prevData, showMoreOptions: true };
                },
                {
                    calculatedWidth: 0,
                    visibleEndsWithSeparator: false,
                    visiblePluginsCount: 0,
                    showMoreOptions: false,
                },
            );

            setShowMoreOptionsConfiguration({
                visiblePluginsCount,
                visibleEndsWithSeparator,
                showMoreOptions,
            });
        },
    );

    const toolbarComponentsCount = toolbarComponents.flat().length;
    useLayoutEffect(
        () => calculateShowMoreOptionsConfiguration(),
        [
            calculateShowMoreOptionsConfiguration,
            // Recalculate whenever the toolbar items count changes.
            toolbarComponentsCount,
        ],
    );

    const { setNode } = useResizeObserver(({ contentRect: { width } }) => calculateShowMoreOptionsConfiguration(width));
    const wrapperMultipleRefCallback = useMultipleRefCallback(wrapperRef, setNode);

    return [wrapperMultipleRefCallback, showMoreOptionsConfiguration] as const;
}

export default useCoreEditorCalculateShowMoreOptions;
