import { analyticsWrapper } from '@tonkean/analytics';

function MoveGroupModalCtrl($scope, $rootScope, $uibModalInstance, trackHelper, item, allowLinking) {
    $scope.groupType = { public: 'PUBLIC', private: 'PRIVATE' };

    $scope.data = {
        item,
        groups: $scope.pm.groups.filter((group) => group.id !== item.groupId),
        shouldCreateLink: false,
        allowLinking,
    };

    $scope.submit = function () {
        if (!$scope.data.selectedGroup) {
            return;
        }

        analyticsWrapper.track('Move to list', {
            category: 'Move List Modal',
            label: $scope.data.selectedGroup.visibilityType,
        });

        // Move the item to the selected group.
        $scope.loading = true;
        // We check if the item is a root item before the change.
        const isRootItem = !item.parent;

        trackHelper
            .moveInitiative(
                item.id,
                item.parent ? item.parent.id : null,
                null,
                null,
                null,
                $scope.data.selectedGroup.id,
            )
            .then(() => {
                $scope.loading = false;
                $scope.$emit('alert', {
                    msg: `Item "${$scope.data.item.title}" moved to "${$scope.data.selectedGroup.name}" successfully!`,
                    type: 'success',
                });

                // It is important to broadcast this event so lists will be updated with initiative that has been moved.
                if (isRootItem) {
                    $rootScope.$broadcast('initiativeMovedFromRoot', { initiativeId: item.id });
                }

                $uibModalInstance.close({ shouldCreateLink: $scope.data.shouldCreateLink });
            })
            .catch((error) => {
                $scope.error = error;
                $scope.loading = false;
            });
    };
}

angular.module('tonkean.app').controller('MoveGroupModalCtrl', MoveGroupModalCtrl);
