import template from './integrationSqlField.template.html?angularjs';

/**
 * Definition of an integration aggregation field.
 */
export default angular.module('tonkean.app').component('tnkIntegrationSqlField', {
    bindings: {
        createMode: '<',
        duplicateMode: '<',
        projectIntegration: '<',
        groupId: '<',
        targetType: '<',

        existingFieldDefinition: '<',

        fieldDefinitionName: '<',
        fieldDefinitionNameEdited: '<',

        onDefinitionChange: '&',
        onFieldDefinitionNameChange: '&',
    },
    template,
    controller: 'IntegrationSqlFieldCtrl',
});
