<div>
    <!-- Changed existing sync -->
    <div ng-if="data.syncConfig.viewData && !data.editMode" class="import-tracks-modal relative">
        <div class="common-close-btn" ng-click="$dismiss()">
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </div>

        <div class="relative">
            <div class="flex-vmiddle">
                <div class="margin-right">
                    <i
                        class="integration-group-header-connected-logo margin-none mod-{{
                            data.syncConfig.projectIntegration.integration.integrationType.toLowerCase()
                        }}"
                    ></i>
                </div>
                <h4 class="flex-grow">
                    {{ data.targetEntityName }} is connected to: {{ data.syncConfig.projectIntegration.displayName }}
                </h4>
            </div>
            <hr />
            <div class="margin-top-lg common-size-xxs">
                <!-- Matched entity sync indication -->
                <div ng-if="data.syncConfig.syncedMatchedEntity.id">
                    Synced with matched entity
                    <strong>{{ data.syncConfig.syncedMatchedEntity.name }}</strong>
                    .
                </div>

                <div ng-if="!data.syncConfig.projectIntegration.disabled && !data.syncConfig.syncedMatchedEntity.id">
                    <p ng-if="data.syncConfig.viewData.Entity || data.syncConfig.viewData.entity">
                        <strong class="margin-right-xs">Type:</strong>
                        {{
                            data.syncConfig.viewData.entityMetadata
                                ? data.syncConfig.viewData.entityMetadata.label
                                : data.syncConfig.viewData.Entity || data.syncConfig.viewData.entity
                        }}
                    </p>
                    <p ng-if="data.syncConfig.viewData.Name">
                        <strong class="margin-right-xs">View:</strong>
                        <span ng-bind="data.syncConfig.viewData.Name"></span>
                    </p>
                    <p ng-if="data.syncConfig.viewType === viewTypes.custom">
                        <strong class="margin-right-xs">Criteria:</strong>
                        {{
                            data.syncConfig.viewData.query &&
                            data.syncConfig.viewData.query.filters &&
                            data.syncConfig.viewData.query.filters &&
                            data.syncConfig.viewData.query.filters.length
                                ? data.syncConfig.viewData.query.filters.length + ' conditions'
                                : 'All items will be synced'
                        }}
                    </p>
                    <p>
                        <strong class="margin-right-xs">Last sync:</strong>
                        <span>{{ data.syncConfig.projectIntegration.lastCollect | date: 'medium' }}</span>
                    </p>
                </div>

                <div ng-if="data.syncConfig.projectIntegration.disabled">
                    <div class="flex mod-align-baseline">
                        <i class="fa fa-warning flex-no-shrink margin-right-xs"></i>
                        <div class="flex-grow">
                            <div>
                                Integration with
                                <strong>
                                    {{ data.syncConfig.projectIntegration.integration.integrationType | capitalize }}
                                </strong>
                                is disabled.
                            </div>
                            <a ui-sref="product.integrations({projectId:pm.project.id})" ng-click="$dismiss()">
                                Fix in manage integrations
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <hr />
            <div class="flex-vmiddle">
                <!-- Edit criteria button -->
                <button
                    class="btn btn-default margin-right"
                    ng-if="!data.syncConfig.projectIntegration.disabled && !data.syncConfig.syncedMatchedEntity.id"
                    ng-click="data.editMode = true"
                >
                    Edit Criteria
                </button>

                <!-- Separator for grow -->
                <div class="flex-grow"></div>

                <a
                    class="common-size-xxxxs"
                    ng-if="!data.syncConfig.syncedMatchedEntity.id"
                    ng-click="modalUtils.openImportTracks(null, data.isInitiativeTargetEntity ? data.targetEntity : null, data.groupId, data.workflowVersionId, null, true); $dismiss()"
                >
                    <span class="common-color-grey"></span>
                    Import items manually
                </a>
            </div>
            <span ng-show="data.error">
                Something wrong happened while trying to remove the sync... Please try again later.
            </span>
            <span ng-show="data.error">{{ data.error }}</span>
        </div>
    </div>

    <!-- Sync from scratch (new sync) -->
    <div ng-if="!data.syncConfig.viewData || data.editMode">
        <tnk-import-tracks
            title="Connect {{ data.targetEntityName }} to an external list"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            parent-initiative="data.isInitiativeTargetEntity ? data.targetEntity : null"
            on-complete-callback="$close()"
            on-delete-callback="$close()"
            on-saved-callback="hideManualImportOption = true"
            view-type="data.syncConfig.viewType"
            sync-data="data.syncConfig.viewData"
            connected-integration-unique-type="data.syncConfig.projectIntegration.integration.integrationUniqueType"
            hide-time-range-selection="true"
            only-sync="true"
            only-import="false"
            btn-label="Preview sync"
            dismiss="$dismiss()"
        ></tnk-import-tracks>
    </div>
</div>
