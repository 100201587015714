import template from './multiplePersonSelectorByEmail.template.html?angularjs';

/**
 * Component to select a multiple existing users in Tonkean by email.
 */
export default angular.module('tonkean.app').component('tnkMultiplePersonSelectorByEmail', {
    bindings: {
        onUpdate: '&',
        onUpdateReact: '<',
        paramsObjectForCallbacks: '<',
        selectedEmails: '<',
        placeholder: '@',
        disabled: '<',
        maxTags: '<',
        hidePlaceholderWhenDisabled: '<',
        hideTagsBackgroundWhenDisabled: '<',
        includeExternal: '<',
    },
    template,
    controller: 'MultiplePersonSelectorByEmailCtrl',
});
