import template from './tnkTwilioChatCustomActionsDefinition.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkTwilioChatCustomActionsDefinition', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        projectIntegration: '<',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        definition: '<', // The selected action definition.
        validationObject: '<',
        onDefinitionChanged: '&',
    },
    template,
    controller: 'TwilioChatCustomActionsDefinitionCtrl',
});
