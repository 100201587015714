import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ProjectIntegrationActionManagerTabKey } from './ProjectIntegrationActionManagerTabKey';
import useProjectIntegrationActionManagerConfiguration from './useProjectIntegrationActionManagerConfiguration';
import useProjectIntegrationActionWarningModal from './useProjectIntegrationActionWarningModal';
import GenericContentBySidePaneLayout from '../../modules/GenericContentBySidePaneLayoutModule/GenericContentBySidePaneLayout';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useGetProjectIntegrationById } from '@tonkean/infrastructure';
import type {
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionWithIsImported,
} from '@tonkean/tonkean-entities';
import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

interface Props {}

const ProjectIntegrationActionManagerState: React.FC<Props> = () => {
    const [projectIntegrationId, projectIntegrationActionId] = useGetStateParams<[string, string]>(
        'projectIntegrationId',
        'projectIntegrationActionId',
    );

    const openWarning = useProjectIntegrationActionWarningModal();

    const $state = useAngularService('$state');

    // The cloned project integration action, we pass to all the layout this object t
    const [clonedProjectIntegrationAction, setClonedProjectIntegrationAction] = useState<ProjectIntegrationAction>();

    // Get the configuration for the generic layout
    const sidePaneConfiguration = useProjectIntegrationActionManagerConfiguration();

    const [{ loading: isUpdateActionLoading, error: updateActionError }, updateProjectIntegrationAction] =
        useLazyTonkeanService('updateProjectIntegrationAction');

    // get projectIntegrationAction for the breadcrumbs
    // Todo: We should add command of getting only the id and the name of all the actions because we dont use other data.
    const { data: projectIntegrationActions } = useTonkeanService('getProjectIntegrationActions', projectIntegrationId);

    // Get projectIntegration by state param projectIntegrationId
    const { projectIntegration } = useGetProjectIntegrationById(projectIntegrationId);

    const [{ data: projectIntegrationAction }, getProjectIntegrationActionById] = useLazyTonkeanService(
        'getProjectIntegrationActionById',
    );

    // When projectIntegrationAction changed (On component loaded) we cloning the action for edit purposes.
    useEffect(() => {
        if (projectIntegrationAction) {
            setClonedProjectIntegrationAction({ ...projectIntegrationAction });
        }
    }, [projectIntegrationAction]);

    useEffect(() => {
        getProjectIntegrationActionById(projectIntegrationId, projectIntegrationActionId);
    }, [getProjectIntegrationActionById, projectIntegrationId, projectIntegrationActionId]);

    const actionTabChanges = useMemo(() => {
        const changedTabs: ProjectIntegrationActionManagerTabKey[] = [];

        if (!projectIntegrationAction || !clonedProjectIntegrationAction) {
            return [];
        }

        // Detect changes in request tab.
        const actionDefinitionChange =
            JSON.stringify(utils.sortObject(projectIntegrationAction?.actionDefinition)) !==
            JSON.stringify(utils.sortObject(clonedProjectIntegrationAction?.actionDefinition));

        if (actionDefinitionChange) {
            changedTabs.push(ProjectIntegrationActionManagerTabKey.REQUEST);
        }

        // Detect changes in handle response tab.
        const handleResponseChange =
            clonedProjectIntegrationAction.responseHandlingDefinition &&
            JSON.stringify(utils.sortObject(projectIntegrationAction.responseHandlingDefinition)) !==
                JSON.stringify(utils.sortObject(clonedProjectIntegrationAction.responseHandlingDefinition));

        if (handleResponseChange) {
            changedTabs.push(ProjectIntegrationActionManagerTabKey.HANDLE_RESPONSE);
        }

        // Detect changes in general tab.
        const generalActionChange =
            projectIntegrationAction?.displayName !== clonedProjectIntegrationAction?.displayName ||
            projectIntegrationAction?.description !== clonedProjectIntegrationAction?.description;

        if (generalActionChange) {
            changedTabs.push(ProjectIntegrationActionManagerTabKey.GENERAL);
        }

        // When we didn't detect any change, we validate again all the action.
        // Because maybe we add something to the action
        const hasUncheckedChange =
            changedTabs.length === 0 &&
            JSON.stringify(utils.sortObject(projectIntegrationAction)) !==
                JSON.stringify(utils.sortObject(clonedProjectIntegrationAction));

        // Its a bit broken but its safe to avoid future bugs.
        if (hasUncheckedChange) {
            return ['Unknown Tab changed'];
        }

        return changedTabs;
    }, [projectIntegrationAction, clonedProjectIntegrationAction]);

    // On Click Save callback
    const onClickSave = useCallback(async () => {
        if (clonedProjectIntegrationAction) {
            clonedProjectIntegrationAction?.parametersDefinition.childEntitiesParameterDefinitions?.forEach(
                (childEntity, index) => {
                    if (childEntity.entityKey === '' && index === 0) {
                        childEntity.entityKey = 'child-entity';
                    } else if (childEntity.entityKey === '') {
                        childEntity.entityKey = `child-entity-${index}`;
                    }
                },
            );

            await updateProjectIntegrationAction(
                projectIntegrationActionId,
                clonedProjectIntegrationAction.displayName,
                clonedProjectIntegrationAction.description,
                clonedProjectIntegrationAction.actionDefinition,
                clonedProjectIntegrationAction.parametersDefinition,
                clonedProjectIntegrationAction.responseHandlingDefinition,
            );
            getProjectIntegrationActionById(projectIntegrationId, projectIntegrationActionId);
        }
    }, [
        clonedProjectIntegrationAction,
        updateProjectIntegrationAction,
        projectIntegrationActionId,
        getProjectIntegrationActionById,
        projectIntegrationId,
    ]);

    const isComponentReady: boolean = useMemo(
        () => !!clonedProjectIntegrationAction && !!projectIntegrationActions && !!projectIntegration,
        [clonedProjectIntegrationAction, projectIntegration, projectIntegrationActions],
    );

    const onClickBack = async () => {
        let confirmed = true;
        if (actionTabChanges.length > 0) {
            confirmed = await openWarning('Remove unsaved changes');
        }

        if (confirmed) {
            return $state.go('product.projectIntegrationPage', {
                page: ProjectIntegrationPageMenuItemType.ACTIONS,
                enterpriseComponentId: projectIntegrationId,
            });
        }
    };

    return (
        <>
            {isComponentReady && (
                <GenericContentBySidePaneLayout
                    isUpdateActionLoading={isUpdateActionLoading}
                    updateActionError={updateActionError}
                    onClickSave={onClickSave}
                    setProjectIntegrationAction={setClonedProjectIntegrationAction}
                    // Using as cast because we validate in isComponentReady that 'clonedProjectIntegrationAction' defined
                    projectIntegrationAction={clonedProjectIntegrationAction as ProjectIntegrationAction}
                    projectIntegrationActionId={projectIntegrationActionId}
                    // Using as cast because we validate in isComponentReady that 'projectIntegration' defined
                    projectIntegration={projectIntegration as ProjectIntegration}
                    sidePaneConfiguration={sidePaneConfiguration}
                    // Using as cast because we validate in isComponentReady that 'projectIntegrationActions' defined
                    projectIntegrationActions={
                        projectIntegrationActions?.entities as ProjectIntegrationActionWithIsImported[]
                    }
                    clickableBack={{ onClick: onClickBack }}
                    actionTabChanges={actionTabChanges}
                    layoutMarginRight={150}
                    layoutMarginBottom={100}
                    showBackButton
                />
            )}
            {/*    TODO: Add Loading/Error indicators*/}
        </>
    );
};
export default ProjectIntegrationActionManagerState;
