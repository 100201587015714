<div class="calendar-subscription-modal common-close-btn-container">
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>
    <!-- Modal title -->
    <div class="modal-title clearfix margin-top">
        <h4 class="common-bold mod-no-top common-size-l">Subscribe to Tonkean's Calendar</h4>
        <p class="common-color-grey common-size-xxs" ng-if="groupId && pm.groupsMap[groupId]">
            Only items from {{ pm.groupsMap[groupId].name }}
        </p>
    </div>

    <hr class="margin-normal-sm-v" />

    <div class="text-center" ng-show="!data.downloadUrl && !data.error">
        <i class="loading"></i>
    </div>
    <div ng-show="data.downloadUrl || data.error">
        <div class="margin-top-xlg text-center">
            <a href="{{ data.webCalUrl }}" class="btn btn-primary btn-lg">Open in Calendar App</a>
        </div>

        <!--<a class="common-bold common-size-xxxxs margin-bottom-xs"-->
        <!--ng-click="data.showAdditionalOptions = !data.showAdditionalOptions">Additional options</a>-->
        <div class="common-size-xxs">
            <div class="flex-vmiddle margin-normal-v common-color-grey">
                <div class="flex-grow">
                    <hr />
                </div>
                <i class="common-size-xxxxs margin-normal">Or</i>
                <div class="flex-grow">
                    <hr />
                </div>
            </div>

            <div>Connect manually by copying the iCal url to your calendar tool:</div>
            <div class="flex-vmiddle margin-top">
                <div class="flex-vmiddle flex-grow mod-center common-color-dark-grey common-size-xxs">
                    <!--<i class="fa fa-info-circle margin-right-xs"></i>-->
                    <!--<span class="flex-no-shrink margin-right-xs">Copy iCal url:</span>-->
                    <input
                        type="text"
                        readonly="readonly"
                        value="{{ data.downloadUrl }}"
                        class="form-control-transparent common-bg mod-transparent common-size-xxx margin-right-xs"
                        ng-click="urlFocused($event)"
                        ng-blur="urlUnFocused($event)"
                    />
                </div>
                <!--<div class="margin-top-xs common-size-xxxxs hidden-xs">-->
                <!--<span class="common-color-grey margin-right-xs margin-left-xs">or</span>-->
                <!--<a href="{{data.downloadUrl}}" target="_blank" ng-click="downloadClicked()"-->
                <!--ng-disabled="data.downloading">-->
                <!--<span>Download</span>-->
                <!--&lt;!&ndash;<i class="loading-small margin-left-xs" ng-show="data.downloading"></i>&ndash;&gt;-->
                <!--</a>-->
                <!--</div>-->
                <!--<button class="btn btn-default" ng-click="cancel()">Done</button>-->
            </div>
            <div class="margin-top common-size-xxxxs common-color-grey">
                See instructions for
                <a
                    href="https://www.google.com/search?q=subscribe+to+ical+in+outlook&oq=adding+ical+to+outlook&gs_l=psy-ab.3.0.0i71k1l4.0.0.0.18759.0.0.0.0.0.0.0.0..0.0....0...1..64.psy-ab..0.0.0.y-34XwXKQx0"
                    target="_blank"
                >
                    Outlook
                </a>
                and
                <a
                    href="https://www.google.com/search?q=subscribe+to+ical+in+google+calendar&oq=subscribe+to+ical+in+go&gs_l=psy-ab.3.0.0j0i22i30k1.39041.41094.0.42347.4.3.1.0.0.0.116.295.2j1.3.0....0...1.1.64.psy-ab..0.4.306.NpMz7w9rqqM"
                    target="_blank"
                >
                    Gmail
                </a>
            </div>
        </div>
    </div>

    <!-- Show error -->
    <div ng-if="data.error">
        An error occurred,
        <a ng-click="init()">Click to retry</a>
        .
        <br />
        {{ data.error }}
    </div>
</div>
