import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CollectedExternalActivitiesTable from './CollectedExternalActivitiesTable';
import useGetWorkerRunsItemFormattedDate from '../../../../../../HistoryModule/hooks/useGetWorkerRunsItemFormattedDate';
import type AuditLogItem from '../../../entities/AuditLogItem';

import { Accordion, HighlightableText, Message, MessageType, Tooltip } from '@tonkean/infrastructure';
import { useGetProjectIntegrationById } from '@tonkean/infrastructure';
import { WarningTriangleIcon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';
import { AccordionSize } from '@tonkean/tui-theme/sizes';

const ActionType = styled(HighlightableText)`
    color: ${Theme.colors.gray_500};
    text-transform: capitalize;
`;

const LogSummary = styled.div`
    display: flex;
    align-items: flex-start;
`;

const BoldedSearchableText = styled(HighlightableText)`
    margin-left: 2px;
    margin-right: 2px;
    font-weight: 500;
    text-transform: capitalize;
`;

const SearchableText = styled(HighlightableText)`
    margin-left: 2px;
    margin-right: 2px;
    color: ${Theme.colors.gray_800};
    font-weight: 500;
`;

const NonSearchableLabel = styled.span`
    color: ${Theme.colors.gray_500};
`;

const Date = styled.span`
    margin-left: 2px;
    color: ${Theme.colors.gray_500};
`;

const Title = styled.div`
    display: flex;
    align-items: center;
`;

const ErrorBox = styled(Message)`
    margin-left: 32px;
    margin-bottom: 8px;
`;

const WarningIcon = styled(WarningTriangleIcon)`
    height: 14px;
`;

interface Props {
    log: AuditLogItem;
    searchTerm?: string;
    isCollapseAllActive?: boolean;
    setIsCollapseAllActive?: (isCollapseAllActive: boolean) => void;
}

const SystemLogItemRow: React.FC<Props> = ({
    log,
    searchTerm,
    setIsCollapseAllActive = () => {},
    isCollapseAllActive = false,
}) => {
    const { formattedDate, formattedTime, fullDate } = useGetWorkerRunsItemFormattedDate(log.created);
    const { projectIntegration } = useGetProjectIntegrationById(log.targetEntityId);
    const [isSystemLogOpen, setIsSystemLogOpen] = useState(isCollapseAllActive);

    // If we got an entity name we use it, otherwise we use the project integration display name.
    const entityName = log.additionalData?.entityName || projectIntegration?.displayNameFull;

    const hasError = log.additionalData?.error;

    // When we collapse all, we change the state of the accordion to closed
    useEffect(() => {
        if (isCollapseAllActive) {
            setIsSystemLogOpen(false);
        }
    }, [isCollapseAllActive, setIsSystemLogOpen]);

    return (
        <Accordion
            size={AccordionSize.SMALL}
            open={isSystemLogOpen}
            onChange={() => {
                const newIsSystemLogOpen = !isSystemLogOpen;
                if (newIsSystemLogOpen) {
                    setIsCollapseAllActive(false);
                }
                setIsSystemLogOpen(newIsSystemLogOpen);
            }}
            title={
                <LogSummary>
                    <Title>
                        {hasError && <WarningIcon />}

                        <ActionType
                            highlightText={searchTerm}
                            text={log.actionTypeFriendlyName || 'Completed'}
                            inline
                        />
                        <SearchableText
                            highlightText={searchTerm}
                            text={log.activityTypeFriendlyName || 'Unknown Action'}
                            inline
                        />
                        {log.additionalData?.numberOfExternalEntitiesCollected > 0 && (
                            <>
                                <NonSearchableLabel>and fetched</NonSearchableLabel>
                                <BoldedSearchableText
                                    highlightText={searchTerm}
                                    text={`${
                                        log.additionalData?.numberOfExternalEntitiesCollected || 0
                                    } ${entityName?.toLowerCase()}`}
                                    inline
                                />
                                <NonSearchableLabel>items</NonSearchableLabel>
                            </>
                        )}
                        {(log.activityType === 'PROJECT_INTEGRATION_ENTITY_COLLECT_SKIPPED' ||
                            log.activityType === 'PROJECT_INTEGRATION_ENTITY_WEBHOOK_COLLECT_SKIPPED') && (
                            <BoldedSearchableText
                                highlightText={searchTerm}
                                text={`${log.targetEntityDisplayName}`}
                                inline
                            />
                        )}
                        {hasError && <NonSearchableLabel>and encountered an error</NonSearchableLabel>}

                        <Tooltip content={fullDate}>
                            <Date>
                                ({formattedDate}
                                {formattedDate ? ', ' : ''}
                                {formattedTime})
                            </Date>
                        </Tooltip>
                    </Title>
                </LogSummary>
            }
            contentSeparator={false}
            borderBottom={false}
            borderTop
            whiteContentBackground
        >
            <div>
                {log.additionalData?.collectedExternalEntities?.length && projectIntegration && (
                    <CollectedExternalActivitiesTable
                        externalActivities={log.additionalData?.collectedExternalEntities}
                        projectIntegration={projectIntegration}
                        entityName={entityName}
                        searchTerm={searchTerm}
                        collectLogTime={log.created}
                    />
                )}

                {log.additionalData?.error && (
                    <ErrorBox type={MessageType.WARNING}>
                        <HighlightableText highlightText={searchTerm} text={log.additionalData?.error} inline />
                    </ErrorBox>
                )}
            </div>
        </Accordion>
    );
};

export default SystemLogItemRow;
