import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';

import SolutionSiteCategoricalGroupSelectedFieldSelector from './SolutionSiteCategoricalGroupSelectedFieldSelector';
import { useSolutionSiteContext } from '../../../hooks';
import { WidgetConfigurationFirstFieldSelector } from '../../CommonWidgetConfiguration';
import { useWidgetConfigurationDeletedFields } from '../../hooks';
import type SolutionSiteFieldChartWidgetConfiguration from '../SolutionSiteFieldChartWidgetConfiguration';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { FieldChartWidgetConstants } from '@tonkean/fields';
import { ChartDataType, LoadingCircle } from '@tonkean/infrastructure';
import type { FieldDefinition, Group, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';

interface Props {
    configuration: SolutionSiteFieldChartWidgetConfiguration;
}

const SolutionSiteFieldChartWidgetFieldSelector: React.FC<Props> = ({ configuration }) => {
    const { solutionSite } = useSolutionSiteContext();
    const projectManager = useAngularService('projectManager');

    // we only need the group and workflow version if we are in categorical chart
    const selectedGroup = useMemo<Group | undefined>(() => {
        if (configuration.groupId && configuration.chartDataType === ChartDataType.CATEGORICAL) {
            return projectManager.groupsMap[configuration.groupId];
        }
        return undefined;
    }, [configuration.chartDataType, configuration.groupId, projectManager.groupsMap]);

    const [entityVersionType] = useGetStateParams<[WorkflowVersionType]>('env');

    const { data: globalFieldDefinitions, loading } = useTonkeanService(
        'getWorkflowFolderGlobalFieldDefinitions',
        solutionSite.workflowFolderId,
        entityVersionType,
    );

    const globalFieldOptions = useMemo<FieldDefinition[]>(() => {
        const globalFieldDefinitionsOptions: FieldDefinition[] = [];
        globalFieldDefinitions?.entities.forEach((field: FieldDefinition) => {
            if (configuration.chartDataType === ChartDataType.HISTORICAL) {
                if (field.fieldType === FieldType.Number) {
                    globalFieldDefinitionsOptions.push(field);
                }
            } else {
                const definition: any = field.definition;
                if (
                    definition?.aggregationType === FieldChartWidgetConstants.TERMS &&
                    (definition?.formulaType === FieldChartWidgetConstants.STRUCTURED ||
                        field.type === FieldChartWidgetConstants.AGGREGATE_QUERY)
                ) {
                    globalFieldDefinitionsOptions.push(field);
                }
            }
        });
        return globalFieldDefinitionsOptions;
    }, [configuration, globalFieldDefinitions?.entities]);

    const deletedGlobalFieldsIds = useWidgetConfigurationDeletedFields(
        globalFieldOptions ?? [],
        Object.keys(
            (configuration.chartDataType === ChartDataType.HISTORICAL
                ? configuration.fields
                : configuration.categoricalFields) || {},
        ),
    );

    return (
        <>
            {selectedGroup ? (
                <SolutionSiteCategoricalGroupSelectedFieldSelector
                    configuration={configuration}
                    selectedGroup={selectedGroup}
                />
            ) : loading ? (
                <LoadingCircle centered />
            ) : (
                <WidgetConfigurationFirstFieldSelector
                    allFieldDefinitions={globalFieldOptions}
                    groups={undefined}
                    workflowVersion={undefined}
                    deletedFieldsIds={deletedGlobalFieldsIds}
                    workflowVersionType={entityVersionType}
                    enableSearch={false}
                    fieldConfigurationPath={
                        configuration.chartDataType === ChartDataType.HISTORICAL
                            ? 'configuration.fields'
                            : 'configuration.categoricalFields'
                    }
                />
            )}
        </>
    );
};
export default SolutionSiteFieldChartWidgetFieldSelector;
