import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type { CustomizedAuthenticationComponentProps } from '@tonkean/integrations';

const ApiKeyAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
}) => {
    const [apiToken, setApiToken] = useState('');
    const authenticateWithApiKey = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = { apiToken };
            authenticate(config);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithApiKey}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        {/* API token text input*/}
                        <label htmlFor="login-api-key" className="col-sm-3 control-label">
                            API Token
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={apiToken}
                                onChange={({ target }) => setApiToken(target.value)}
                                className="form-control"
                                id="login-api-key"
                                autoComplete="off"
                                placeholder="API Token"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ApiKeyAuthenticationComponent;
