import React from 'react';
import DefaultHistoryReason from './DefaultHistoryReason';
import { TimeUtils } from '@tonkean/shared-services';

export default class DateHistoryReason extends DefaultHistoryReason {
    constructor(props) {
        super(props);
    }

    getTitle() {
        const reasonMetadata = this.props.item.reasonMetadata;
        if (reasonMetadata && reasonMetadata[this.props.fieldName]) {
            const daysDiff = TimeUtils.daysDiff(reasonMetadata[this.props.fieldName], this.props.created);
            let daysDiffString;
            if (daysDiff === 0) {
                daysDiffString = 'on the same day';
            } else if (daysDiff > 0) {
                daysDiffString = `${daysDiff} days from then`;
            } else {
                daysDiffString = `${-daysDiff} days before that`;
            }

            return (
                <div>
                    {this.props.person.name} was pinged on {this.renderTitleLink()} because{' '}
                    {this.props.fieldDisplayName} was {daysDiffString}.
                </div>
            );
        } else {
            return super.getTitle();
        }
    }
}
