import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as RedTooltipIcon } from '../../../../../../../../images/icons/red-error.svg';
import projectIntegrationActionConstantParamsConfig from '../../../projectIntegrationActionConstantParamIdToConfig';

import { Input, TextEllipsis, Tooltip } from '@tonkean/infrastructure';
import type { BaseActionParameter } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ParamNameWrapper = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.current.palette.colorPicker.HEX_34393E};
`;

const ParamNameAndIdWrapper = styled.div`
    display: inline-flex;
    gap: 5px;
    line-height: 14px;
    align-items: center;
`;

const ActionParamLabel = styled.label<{ isFirst?: boolean }>`
    width: 100%;
    margin-top: ${({ isFirst }) => (isFirst ? '5px' : '20px')};
`;

interface Props {
    actionParams: BaseActionParameter[];
    onChange: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    parameterIdToParameterValueRecord: Record<string, string>;
    constantParameters: BaseActionParameter[];
}

const ProjectIntegrationActionTestParamsView: React.FC<Props> = ({
    actionParams,
    constantParameters,
    onChange,
    parameterIdToParameterValueRecord,
}) => {
    // On input change, we will update the state.
    const handleChange = useCallback(
        (paramId: string, newValue: string) => {
            onChange((oldValues) => ({ ...oldValues, [paramId]: newValue }));
        },
        [onChange],
    );

    // Constant params and action additional params.
    const allParams = useMemo(() => [...constantParameters, ...actionParams], [constantParameters, actionParams]);

    return (
        <>
            {allParams.map((param, index) => (
                <ActionParamLabel
                    isFirst={index === 0}
                    key={param.id}
                    data-automation="project-integration-action-test-params-view-parameter-wrapper"
                >
                    <ParamNameAndIdWrapper>
                        <ParamNameWrapper data-automation="project-integration-action-test-params-view-param-name">
                            <TextEllipsis numberOfLines={1} tooltip>
                                {param.displayName}
                            </TextEllipsis>
                        </ParamNameWrapper>

                        {projectIntegrationActionConstantParamsConfig[param.id] &&
                            parameterIdToParameterValueRecord[param.id] === '' && (
                                <Tooltip
                                    key={param.id}
                                    limitWidth={18}
                                    placement="bottom"
                                    content={projectIntegrationActionConstantParamsConfig[param.id].emptyValueTooltip}
                                >
                                    <RedTooltipIcon />
                                </Tooltip>
                            )}
                    </ParamNameAndIdWrapper>

                    <Input
                        placeholder="Insert value"
                        className="form-control"
                        data-automation="project-integration-action-test-params-view-param-value"
                        key={param.id}
                        disabled={
                            projectIntegrationActionConstantParamsConfig[param.id] &&
                            !projectIntegrationActionConstantParamsConfig[param.id]?.editableInTest
                        }
                        value={parameterIdToParameterValueRecord[param.id] || ''}
                        onChange={({ target }) => handleChange(param.id, target.value)}
                    />
                </ActionParamLabel>
            ))}
        </>
    );
};

export default ProjectIntegrationActionTestParamsView;
