import { useCallback } from 'react';

import type {
    FetchResults,
    Initiative,
    ItemInterface,
    TonkeanId,
    TonkeanType,
    WidgetBase,
    WidgetConfiguration,
    WidgetParentTypes,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import type { SequenceState } from '@tonkean/tonkean-entities';

const useBackNavigation = (
    previousSequenceState: SequenceState[],
    scrollToTop: () => void,
    setSequenceState: (
        stepItemInterface: ItemInterface,
        stepCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        stepWidgets: WidgetBase<WidgetConfiguration, WidgetParentTypes>[] | undefined,
        isStepLastInSequence: boolean,
        nextInSequence: boolean,
        initative: Initiative | undefined,
        workflowVersion: WorkflowVersion | undefined,
        evaluatedIntakeSequenceButtonLabel: string | undefined,
    ) => void,
    getSequenceStepWidgets: (
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        stepWorkflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
        stepInitiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    ) => Promise<FetchResults<WidgetBase<WidgetConfiguration, WidgetParentTypes>>>,
    setbackLoadingActive: (isLoading: boolean) => void,
    backLoadingActive?: boolean,
    onBackClicked?: () => void,
    onSwitchedToPrevInterfaceInternally?: (customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => void,
): (() => void) => {
    const getPreviousStackInSequence = useCallback(async () => {
        scrollToTop();
        const previousSequence = previousSequenceState.pop();
        const lastItemInterface = previousSequence?.itemInterface as ItemInterface;
        const lastCustomTriggerId = previousSequence?.customTriggerId as TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
        const lastInitiative = previousSequence?.initiative;
        const lastWorkflowVersion = previousSequence?.workflowVersion;
        const widgetsData = await getSequenceStepWidgets(
            lastItemInterface?.id,
            lastWorkflowVersion?.id,
            lastInitiative?.id,
        );
        onSwitchedToPrevInterfaceInternally?.(lastCustomTriggerId);
        setSequenceState(
            lastItemInterface,
            lastCustomTriggerId,
            widgetsData?.entities,
            false,
            false,
            lastInitiative,
            lastWorkflowVersion,
            previousSequence?.evaluatedIntakeSequenceButtonLabel,
        );
    }, [
        getSequenceStepWidgets,
        onSwitchedToPrevInterfaceInternally,
        scrollToTop,
        setSequenceState,
        previousSequenceState,
    ]);

    const handleBackClicked = useCallback(() => {
        if (backLoadingActive) return;
        setbackLoadingActive(true);
        if (previousSequenceState?.length > 0) {
            getPreviousStackInSequence();
            setbackLoadingActive(false);
        } else if (onBackClicked) {
            onBackClicked();
        }
    }, [
        backLoadingActive,
        getPreviousStackInSequence,
        onBackClicked,
        setbackLoadingActive,
        previousSequenceState?.length,
    ]);

    return handleBackClicked;
};

export default useBackNavigation;
