<div class="modal-header">
    <!--<h3 class="modal-title">{{currentIntegration.displayName}}</h3>-->
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<form novalidate>
    <div ng-form="data.integrationForm">
        <div class="modal-body">
            <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
            <div ng-hide="connecting || integration" class="text-center">
                <button
                    type="button"
                    class="btn btn-secondary btn-lg"
                    analytics-on
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Authenticate"
                    analytics-label="{{ currentIntegration.name }}"
                    ng-click="authorize()"
                >
                    Authenticate with {{ currentIntegration.displayName }}
                </button>
                <div class="margin-normal-sm-v common-subtitle-inner">We only need read permissions!</div>
            </div>
            <div class="text-center" ng-show="connecting">
                <h4>
                    <span class="loading"></span>
                    Connecting to {{ currentIntegration.displayName }}
                </h4>
            </div>
            <div ng-show="integration && !connecting">
                <div class="alert alert-info">
                    <a
                        class="pull-right"
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Change User
                    </a>

                    <div class="integration-group-header-connected" ng-if="integration">
                        Authenticated
                        <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                            as
                            <span class="integration-group-header-connected-username">
                                {{ integration.integrationUserDisplayName || integration.integrationUser }}
                            </span>
                        </span>
                    </div>
                </div>
                <h4>Which repositories are relevant to this team?</h4>
                <hr />
                <span>Suggested Repositories:</span>

                <div class="margin-normal-v">
                    <div ng-repeat="selection in selections" class="new-product-git-repo">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    ng-model="selection.selected"
                                    ng-required="(selections|filter:{selected:true}:true).length===0"
                                    ng-change="onSelectionChange(selection)"
                                />
                                <strong>{{ selection.repo.displayName }}</strong>
                            </label>

                            <div class="common-subtitle-inner margin-normal-h">
                                <span>{{ selection.repo.description }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <span>Add Other Repositories:</span>

                <div class="flex-vmiddle margin-normal-sm-v">
                    <div class="flex-grow margin-right">
                        <ui-select theme="bootstrap" ng-model="temp.repo" ng-disabled="validatingRepo || !otherRepos">
                            <ui-select-match placeholder="Add another repository">
                                {{ $select.selected.displayName }}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="repo in otherRepos | filter:$select.search"
                                refresh="addCustomRepo($select.search)"
                                refresh-delay="0"
                            >
                                <div ng-bind-html="repo.displayName | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
                    <button
                        type="button"
                        ng-click="onRepoSelected(temp.repo)"
                        ng-disabled="validatingRepo"
                        class="btn btn-secondary"
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div
                class="common-color-danger pull-left"
                ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid"
            >
                You should select at least one repository
            </div>
            <button
                class="btn btn-default"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="onCancel()"
            >
                Cancel
            </button>
            <button
                class="btn btn-primary"
                ng-disabled="(selections|filter:{loadingBranches:true}:true).length"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="ok()"
            >
                OK
            </button>
        </div>
    </div>
</form>
