import { lateConstructController } from '@tonkean/angular-components';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';

/* @ngInject */
function CustomNotificationSettingsCtrl(
    $scope,
    customFieldsManager,
    tonkeanUtils,
    utils,
    projectManager,
    communicationIntegrationsService,
    authenticationService,
    workflowVersionManager,
    syncConfigCacheManager,
    customTriggerManager,
    consts,
) {
    const ctrl = this;

    $scope.tonkeanUtils = tonkeanUtils;
    $scope.pm = projectManager;
    $scope.cis = communicationIntegrationsService;
    $scope.as = authenticationService;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;
    const logicBlockTypes = consts.getLogicBlockTypes();

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        overrideTitle: ctrl.overrideTitle,
        validationObject: ctrl.validationObject,
        existingCustomNotificationSettings: ctrl.existingCustomNotificationSettings || {},
        supportCustomFields: ctrl.supportCustomFields,
        botMessageText: null,
        evaluatedPostText: '{{TNK_TITLE}}',
        addInitiativePageLink: false,
        channelOrPersonSelectionConfiguration: {},
        selectedCommunicationProjectIntegrationId:
            ctrl.existingCustomNotificationSettings?.communicationProjectIntegrationId,
        notificationAdditionalFields: [],
        hasParentMonitoringThread: false,
    };

    /**
     * Initialization function of the component.
     */
    ctrl.$onInit = function () {
        if ($scope.data.existingCustomNotificationSettings) {
            initializeEditMode();

            $scope.onCustomNotificationSettingsChanged(false);
        }
    };

    /**
     * Occurs on changes in component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.existingCustomNotificationSettings) {
            $scope.data.existingCustomNotificationSettings = ctrl.existingCustomNotificationSettings || {};
            initializeEditMode();
            $scope.onCustomNotificationSettingsChanged(false);
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Occurs once a channel or a person selection is changed.
     */
    $scope.onChannelOrPersonSelectionChanged = function (channelOrPersonSelectionConfiguration, shouldSaveLogic) {
        $scope.data.channelOrPersonSelectionConfiguration = channelOrPersonSelectionConfiguration;
        $scope.onCustomNotificationSettingsChanged(shouldSaveLogic);
    };

    $scope.onCommunicationIntegrationChanged = function (selectedCommunicationProjectIntegration) {
        $scope.data.selectedCommunicationProjectIntegrationId = selectedCommunicationProjectIntegration?.id;
        $scope.onCustomNotificationSettingsChanged(true);
    };

    /**
     * Occurs on multi field quick selection fields changes.
     */
    $scope.onNotificationAdditionalFieldsChanges = function (notificationAdditionalFields) {
        $scope.data.notificationAdditionalFields = notificationAdditionalFields;
        $scope.onCustomNotificationSettingsChanged(true);
    };

    /**
     * Occurs on a change of the custom notification settings.
     */
    $scope.onCustomNotificationSettingsChanged = function (shouldSaveLogic) {
        if (ctrl.onCustomNotificationSettingsChanged) {
            const customNotificationSettings = {
                botMessageText: $scope.data.botMessageText,
                evaluatedBotMessageText: $scope.data.evaluatedBotMessageText,
                channelOrPersonSelectionConfiguration: $scope.data.channelOrPersonSelectionConfiguration,
                notificationAdditionalFields: $scope.data.notificationAdditionalFields,
                hideInitiativePageLink: !$scope.data.addInitiativePageLink,
                selectedCommunicationProjectIntegrationId: $scope.data.selectedCommunicationProjectIntegrationId,
            };

            ctrl.onCustomNotificationSettingsChanged({
                customNotificationSettings,
                shouldSaveLogic,
            });
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.botMessageText = originalExpression;
        $scope.data.evaluatedBotMessageText = evaluatedExpression;
        $scope.onCustomNotificationSettingsChanged(shouldSaveLogic);
    };

    /**
     * Edit mode initialization.
     */
    function initializeEditMode() {
        $scope.data.botMessageText = $scope.data.existingCustomNotificationSettings.text;
        $scope.data.evaluatedBotMessageText = $scope.data.existingCustomNotificationSettings.evaluatedText;
        // Add initiative page link
        const hideInitiativePageLinkValue =
            $scope.data.existingCustomNotificationSettings.hideInitiativePageLink === undefined
                ? true
                : $scope.data.existingCustomNotificationSettings.hideInitiativePageLink;
        $scope.data.addInitiativePageLink = !hideInitiativePageLinkValue;
        $scope.data.hasParentMonitoringThread = customTriggerManager.isParentsTypeBeforeMonitoringActionItem(
            $scope.data.workflowVersionId,
            $scope.data.configuredLogic.node.id,
            [
                logicBlockTypes.PERSON_INQUIRY.type,
                logicBlockTypes.SEND_NOTIFICATION.type,
                logicBlockTypes.BOT_THREAD_REPLY.type,
                logicBlockTypes.SEND_FORM.type,
            ],
        );

        // Fields to append to message
        if (
            $scope.data.existingCustomNotificationSettings.fields &&
            $scope.data.existingCustomNotificationSettings.fields.length
        ) {
            const fieldDefinitionMap = utils.createMapFromArray(
                customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                'id',
            );
            const specialFieldMap = utils.createMapFromArray(
                getSpecialFieldsForFeatures(true, ['ADDED_FIELDS_TO_BOT_MESSAGE']),
                'id',
            );

            $scope.data.notificationAdditionalFields = $scope.data.existingCustomNotificationSettings.fields
                .filter((field) => field.fieldDefinitionId || field.specialFieldId)
                .map((field) => {
                    if (field.fieldDefinitionId) {
                        return fieldDefinitionMap[field.fieldDefinitionId];
                    } else if (field.specialFieldId) {
                        return specialFieldMap[field.specialFieldId];
                    }
                })
                .filter((field) => field);
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('CustomNotificationSettingsCtrl', lateConstructController(CustomNotificationSettingsCtrl));
