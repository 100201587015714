import React, { useMemo } from 'react';

import ProjectIntegrationEntitiesList from './ProjectIntegrationEntitiesList';
import type { ProjectIntegrationEntityForView } from './ProjectIntegrationEntityForView';

interface Props {
    entities: ProjectIntegrationEntityForView[];
    projectIntegrationId: string;
}

const ProjectIntegrationEntitiesLists: React.FC<Props> = ({ entities, projectIntegrationId }) => {
    const [customEntities, nativeEntities] = useMemo(() => {
        return [entities.filter((entity) => entity.isClickable), entities.filter((entity) => !entity.isClickable)];
    }, [entities]);

    return (
        <>
            <ProjectIntegrationEntitiesList
                projectIntegrationId={projectIntegrationId}
                entities={customEntities}
                header={`Custom Entities (${customEntities?.length || 0})`}
            />

            <ProjectIntegrationEntitiesList
                projectIntegrationId={projectIntegrationId}
                entities={nativeEntities}
                header={`Native Entities (${nativeEntities?.length || 0})`}
            />
        </>
    );
};

export default ProjectIntegrationEntitiesLists;
