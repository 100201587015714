import type { AngularServices } from 'angulareact';
import { SUBSCRIPTION_TIERS } from '@tonkean/constants';
import IntegrationManager from './IntegrationManager';
import { IntegrationType, Integration, ProjectIntegration } from '@tonkean/tonkean-entities';

export class Integrations {
    public static $inject: (string & keyof AngularServices)[] = [
        '$rootScope',
    ];

    /**
    * Do not use this property directly, please use getIntegrationsConfig instead.
    */
    private integrationsConfig;

    private integrationConfigMap = {};

    constructor(
        private $rootScope?: AngularServices['$rootScope'],
    ) {
        this.integrationsConfig = {
            zuora: {
                // Indicates whether when this integration is connected, it will be included in the group project integration regardless
                // of if a user selected them in the group.
                globalIntegration: false,
                dataRetentionSettings: {
                    // Is the integration supports data retention.
                    isSupportsDataRetention: true,
                    /**
                     * Is the user able to add or remove all of the entities.
                     * Set to true when you know that user can add/remove all of the entities instead of mentioning each one in the removableEntities dictionary below.
                     */
                    canAddOrRemoveAll: false,
                    /**
                     * Is the user able to edit all of the entities
                     * Set to true when you know that user can edit all of the entities instead of mentioning each one in the editableEntities dictionary below.
                     */
                    canEditAll: true,
                    // Specify which entities are removable
                    removableEntities: {},
                    // Specify which entities are editable
                    editableEntities: {},
                    /**
                     * Specify which of the entities configuration are affects only the data retention and not on the collect.
                     * In some of the collectors, the collectors doesn't count the last collect time when fetching the items.
                     * Thus, we will always collect all of the items and doesn't consider the last collect time (no continues collect).
                     */
                    isOnlyDataRetention: {},
                },
                importSettings: ['Subscriptions', 'Accounts', 'Invoices'],
                // Integrations that require collect to complete in order to import.
                importIndexOnly: false,
                // Indicates for which integrations we need to get the supported entities from API.
                allEntitiesSupportedIntegrations: false,
                // Indicates which integrations support customization of owner fields.
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                // If true, will only allow syncing using defined custom filters. If false, will show views from external system.
                syncSettings: {
                    supportedEntities: ['Accounts', 'Invoices', 'Subscriptions'],
                    onlyCustomFilterSettings: true,
                    Accounts: {
                        entityType: 'Account',
                        pluralLabel: 'Accounts',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Invoices: {
                        entityType: 'Invoice',
                        pluralLabel: 'Invoices',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Subscriptions: {
                        entityType: 'Subscription',
                        pluralLabel: 'Subscriptions',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Zuora field',
                        description: 'Example: Account, Subscription, Invoice',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of invoices per Account',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of subscriptions',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['Account', 'Invoice', 'Subscription'],
                filterOptions: ['Account', 'Invoice', 'Subscription'],
                // Indicates which integrations map to which subscription tier tnk-require condition. If not in this, default is standardLicense.
                integrationRequireMap: '',
                displayName: 'Zuora',
            },
            okta: {
                globalIntegration: true,
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: true,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Group: true, User: true },
                    isOnlyDataRetention: { UserGroups: true },
                },
                importSettings: ['Users', 'Groups', 'UserGroups'],
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Users', 'Groups', 'UserGroups'],
                    onlyCustomFilterSettings: true,
                    Users: {
                        entityType: 'User',
                        pluralLabel: 'Users',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Groups: {
                        entityType: 'Group',
                        pluralLabel: 'Groups',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    UserGroup: {
                        entityType: 'UserGroup',
                        pluralLabel: 'UserGroups',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Okta field',
                        description: 'Example: Envelope',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of envelopes per Account',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of envelopes.',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['Users', 'Groups', 'UserGroups'],
                filterOptions: ['Users', 'Groups', 'UserGroups'],
                integrationRequireMap: '',
                displayName: 'Okta',
            },
            googlecloud: {
                displayName: 'Google Cloud',
            },
            salesforce: {
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: true,
                    canEditAll: true,
                    editableEntities: {},
                    removableEntities: {},
                    isOnlyDataRetention: {},
                },
                importIndexOnly: false,
                allEntitiesSupportedIntegrations: true,
                integrationSupportsCustomOwnerFields: true,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    onlyCustomFilterSettings: false,
                    // Whether the whole integration supports view or not. This flag is not per entity but for all entities under the integration.
                    wholeIntegrationViewSupported: false,
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Salesforce field',
                        description: 'Example: Stage, Probability, ExpectedRevenue',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open tasks per opportunity',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Salesforce',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open tasks per opportunity',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {
                    supportedEntityTypes: [
                        'Opportunities',
                        'TNK_OPP',
                        'Opportunity',
                        'opportunity',
                        'opportunities',
                        'opportunitys',
                        'OPPORTUNITY',
                        'OPPORTUNITIES',
                        'OPPORTUNITYS',
                    ],
                },
                createDateSettings: 'CreatedDate',
                fieldOptions: ['Opportunity', 'Account', 'Task', 'Case'],
                filterOptions: ['Account', 'Opportunity'],
                integrationRequireMap: '',
                displayName: 'Salesforce',
            },
            intercom: {
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: {
                        Conversation: true,
                        Admin: true,
                        Company: true,
                        User: true,
                        Lead: true,
                        Contact: true,
                        DataAttribute: true,
                    },
                    isOnlyDataRetention: { Tag: true, Admin: true, Company: true, User: true, Lead: true, DataAttribute: true },
                },
                globalIntegration: true,
                importSettings: () => {
                    const settings = ['Companies', 'Contacts', 'Conversations', 'Admins', 'Attachments', 'DataAttributes'];
                    if (this.$rootScope?.features?.currentProject?.tonkean_feature_collect_intercom_users_and_leads) {
                        settings.push('Users', 'Leads');
                    }
                    return settings;
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: true,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: () => {
                        const entities = ['Conversations', 'Companies', 'Contacts', 'Admins', 'Tags', 'Attachments', 'DataAttributes'];
                        if (this.$rootScope?.features?.currentProject?.tonkean_feature_collect_intercom_users_and_leads) {
                            entities.push('Users', 'Leads');
                        }
                        return entities;
                    },
                    onlyCustomFilterSettings: true,
                    Attachments: {
                        entityType: 'Attachment',
                        pluralLabel: 'Attachments',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Conversations: {
                        entityType: 'Conversation',
                        pluralLabel: 'Conversations',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Companies: {
                        entityType: 'Company',
                        pluralLabel: 'Companies',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Contacts: {
                        entityType: 'Contact',
                        pluralLabel: 'Contacts',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Users: {
                        entityType: 'User',
                        pluralLabel: 'Users',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Admins: {
                        entityType: 'Admin',
                        pluralLabel: 'Admins',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Tags: {
                        entityType: 'Tag',
                        pluralLabel: 'Tags',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    DataAttributes: {
                        entityType: 'DataAttribute',
                        pluralLabel: 'Data Attributes',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Intercom field',
                        description: 'Example: Organization, Deal, Activity',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of deals per Organization',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of deals',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Company', 'Contact', 'Conversation', 'Admin', 'Tag', 'Attachment', 'DataAttribute'].concat(
                    this.$rootScope?.features?.currentProject?.tonkean_feature_collect_intercom_users_and_leads
                        ? ['User', 'Lead']
                        : [],
                ),
                filterOptions: ['Company', 'Contact', 'Conversation', 'Admin', 'Attachment', 'DataAttribute'].concat(
                    this.$rootScope?.features?.currentProject?.tonkean_feature_collect_intercom_users_and_leads
                        ? ['User', 'Lead']
                        : [],
                ),
                integrationRequireMap: '',
                displayName: 'Intercom',
            },
            // eslint-disable-next-line camelcase
            slack_app: {
                importSettings: ['Users', 'Channels', 'Messages', 'User Groups'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: true,
                    removableEntities: { UserGroup: true },
                    editableEntities: {},
                    isOnlyDataRetention: { User: true, Channel: true, UserGroup: true },
                },
                importIndexOnly: false,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Users', 'Channels', 'Messages', 'User Groups'],
                    onlyCustomFilterSettings: true,
                    Users: {
                        entityType: 'User',
                        pluralLabel: 'Users',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Channels: {
                        entityType: 'Channel',
                        pluralLabel: 'Channels',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Messages: {
                        entityType: 'Message',
                        pluralLabel: 'Messages',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    UserGroups: {
                        entityType: 'UserGroup',
                        pluralLabel: 'User Groups',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Slack field',
                        description: 'Example: Channel, User',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of channels',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of channels',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['Channel', 'User', 'Message', 'User Groups'],
                filterOptions: ['Channel', 'User', 'Message', 'User Groups'],
                integrationRequireMap: '',
                displayName: 'Slack',
            },
            freshdesk: {
                importSettings: ['Companies', 'Tickets', 'Contacts'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Contact: true, Ticket: true, Company: true, Agent: true },
                    isOnlyDataRetention: { Agent: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Tickets', 'Companies', 'Contacts'],
                    onlyCustomFilterSettings: true,
                    Tickets: {
                        entityType: 'Ticket',
                        pluralLabel: 'Tickets',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Companies: {
                        entityType: 'Company',
                        pluralLabel: 'Companies',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Contacts: {
                        entityType: 'Contact',
                        pluralLabel: 'Contacts',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Freshdesk field',
                        description: 'Example: Ticket Status',
                        image: '/images/createFieldDefinition/zendesk_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open tickets',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open tickets',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Company', 'Ticket', 'Contacts'],
                filterOptions: ['Company', 'Ticket', 'Contacts'],
                integrationRequireMap: '',
                displayName: 'Freshdesk',
            },
            twiliochat: {
                importSettings: ['Tasks', 'Channels', 'Users'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: {},
                    isOnlyDataRetention: { User: true, Task: true, Channel: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Tasks', 'Channels', 'Users'],
                    Tasks: {
                        entityType: 'Task',
                        pluralLabel: 'Tasks',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Channels: {
                        entityType: 'Channel',
                        pluralLabel: 'Channels',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Users: {
                        entityType: 'User',
                        pluralLabel: 'Users',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Twilio Chat field',
                        description: 'Example: Channel, Message, User',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of Messages per Channel',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of Messages',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'date_created',
                fieldOptions: ['Task', 'Channel', 'User'],
                filterOptions: ['Task', 'Channel', 'User'],
                integrationRequireMap: '',
                displayName: 'Twilio Chat',
            },
            github: {
                importSettings: ['Issues'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Issue: true },
                    isOnlyDataRetention: {},
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Issues'],
                    onlyCustomFilterSettings: true,
                    Issues: {
                        entityType: 'Issue',
                        pluralLabel: 'Issues',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Issue field',
                        description: 'Example: Status',
                        image: '/images/createFieldDefinition/git_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate issues',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open issues',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate issues',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open issues',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Issue'],
                filterOptions: ['Issue'],
                displayName: 'GitHub',
            },
            gitlab: {
                importSettings: ['Projects', 'Issues', 'Milestones'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Issue: true, Project: true, Milestone: true },
                    isOnlyDataRetention: { Project: true, Milestone: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Projects', 'Issues', 'Milestones'],
                    onlyCustomFilterSettings: true,
                    Projects: {
                        entityType: 'PROJECT',
                        pluralLabel: 'Projects',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Issues: {
                        entityType: 'ISSUE',
                        pluralLabel: 'Issues',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Milestones: {
                        entityType: 'MILESTONE',
                        pluralLabel: 'Milestones',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'GitLab field',
                        description: 'Example: Issue completed',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of issues',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of high priority issues',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {
                    supportedEntityTypes: ['Issues', 'TNK_TASK', 'Issue', 'issue', 'issues', 'Issues', 'ISSUE', 'ISSUES'],
                },
                createDateSettings: 'created_at',
                fieldOptions: ['Project', 'Issue', 'Milestone'],
                filterOptions: ['Project', 'Issue', 'Milestone'],
                integrationRequireMap: '',
                displayName: 'GitLab',
            },
            trello: {
                importSettings: ['Boards'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Board: true },
                    isOnlyDataRetention: { Board: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Boards', 'Lists', 'Cards'],
                    onlyCustomFilterSettings: true,
                    Boards: {
                        entityType: 'Board',
                        pluralLabel: 'Boards',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Lists: {
                        entityType: 'List',
                        pluralLabel: 'Lists',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Cards: {
                        entityType: 'Card',
                        pluralLabel: 'Cards',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Epics: {
                        entityType: 'EPIC',
                        pluralLabel: 'Epics',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Trello field',
                        description: 'Example: Card Status',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of critical bugs',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of critical bugs',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {
                    supportedEntityTypes: ['Cards', 'TNK_TASK', 'Card', 'card', 'cards', 'Cards', 'CARD', 'CARDS'],
                },
                createDateSettings: 'dateLastActivity',
                fieldOptions: ['Board', 'List', 'Card'],
                filterOptions: ['Board', 'List'],
                integrationRequireMap: SUBSCRIPTION_TIERS.BASIC.tnkRequire,
                displayName: 'trello',
            },
            jira: {
                importSettings: [
                    'Epics',
                    'Versions',
                    'Components',
                    'Issues',
                    'Organizations',
                    'Customers',
                    'Users',
                    'Issue Types',
                    'Issue Types Scheme',
                    'Workflows',
                    'Workflows Scheme',
                    'Screens',
                    'Screens Scheme',
                    'Issue Type Screens Scheme',
                    'Field Configurations',
                    'Field Configurations Scheme',
                    'Permissions Scheme',
                    'Fields',
                ],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { VERSION: true, COMPONENT: true },
                    isOnlyDataRetention: {
                        ISSUE: true,
                        EPIC: true,
                        Organization: true,
                        VERSION: true,
                        USER: true,
                        PROJECT: true,
                        COMPONENT: true,
                    },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: true,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: [
                        'Issues',
                        'Versions',
                        'Epics',
                        'Components',
                        'Organizations',
                        'Customers',
                        'Users',
                        'Issue Types',
                        'Issue Types Scheme',
                        'Workflows',
                        'Workflows Scheme',
                        'Screens',
                        'Screens Scheme',
                        'Issue Type Screens Scheme',
                        'Field Configurations',
                        'Field Configurations Scheme',
                        'Permissions Scheme',
                        'Fields',
                    ],
                    onlyCustomFilterSettings: false,
                    Issues: {
                        entityType: 'ISSUE',
                        pluralLabel: 'Issues',
                        integrationViewSupported: true,
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Versions: {
                        entityType: 'VERSION',
                        pluralLabel: 'Versions',
                        integrationViewSupported: false,
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Components: {
                        entityType: 'COMPONENT',
                        pluralLabel: 'Components',
                        integrationViewSupported: false,
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Epics: {
                        entityType: 'EPIC',
                        pluralLabel: 'Epics',
                        integrationViewSupported: false,
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Organizations: {
                        entityType: 'ORGANIZATION',
                        pluralLabel: 'Organizations',
                        integrationViewSupported: false,
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Customers: {
                        entityType: 'CUSTOMERS',
                        pluralLabel: 'Customers',
                        integrationViewSupported: false,
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Users: {
                        entityType: 'USERS',
                        pluralLabel: 'Users',
                        integrationViewSupported: false,
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Issue field',
                        description: 'Example: Priority, Estimation, Status',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'JIRA_COUNT_FILTER',
                        buttonText: 'Aggregate issues',
                        overrideFieldType: 'number',
                        description: 'Example: No. of bugs per client',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'JIRA_COUNT_FILTER',
                        buttonText: 'Aggregate issues',
                        overrideFieldType: 'number',
                        description: 'Example: No. of bugs per client',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {
                    supportedEntityTypes: [
                        'Epics',
                        'TNK_BUCKET',
                        'Epic',
                        'epic',
                        'epics',
                        'Epics',
                        'EPIC',
                        'EPICS',
                        'Issues',
                        'TNK_TASK',
                        'Issue',
                        'issue',
                        'issues',
                        'Issues',
                        'ISSUE',
                        'ISSUES',
                    ],
                },
                createDateSettings: 'created',
                fieldOptions: [
                    'Epic',
                    'Issue',
                    'Version',
                    'Component',
                    'Organization',
                    'Customer',
                    'User',
                    'Issue Type',
                    'Issue Type Scheme',
                    'Workflow',
                    'Workflow Scheme',
                    'Screen',
                    'Screen Scheme',
                    'Issue Type Screen Scheme',
                    'Field Configuration',
                    'Field Configuration Scheme',
                    'Permission Scheme',
                    'Field',
                ],
                filterOptions: [
                    'ISSUE',
                    'VERSION',
                    'COMPONENT',
                    'EPIC',
                    'ORGANIZATION',
                    'CUSTOMER',
                    'USER',
                    'ISSUETYPE',
                    'ISSUETYPESCHEME',
                    'WORKFLOW',
                    'WORKFLOWSSCHEME',
                    'SCREEN',
                    'SCREENSSCHEME',
                    'ISSUETYPESSCREENSSCHEME',
                    'FIELDCONFIGURATION',
                    'FIELDCONFIGURATIONSSCHEME',
                    'PERMISSIONSSCHEME',
                    'FIELD',
                ],
                integrationRequireMap: '',
                displayName: 'Jira',
            },
            asana: {
                importSettings: ['Projects', 'Sections', 'Tasks', 'Comments'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Project: true, Task: true },
                    isOnlyDataRetention: { User: true, Project: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Projects', 'Tasks', 'Users', 'Sections', 'Comments'],
                    onlyCustomFilterSettings: true,
                    Projects: {
                        entityType: 'Project',
                        pluralLabel: 'Projects',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Tasks: {
                        entityType: 'Task',
                        pluralLabel: 'Tasks',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Users: {
                        entityType: 'User',
                        pluralLabel: 'Users',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Sections: {
                        entityType: 'Section',
                        pluralLabel: 'Sections',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Comments: {
                        entityType: 'Comment',
                        pluralLabel: 'Comments',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Asana field',
                        description: 'Example: Task completed',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of critical tasks',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of critical tasks',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Project', 'Task', 'User', 'Sections', 'Comments'],
                filterOptions: ['Project', 'Sections', 'Comments'],
                integrationRequireMap: SUBSCRIPTION_TIERS.BASIC.tnkRequire,
                displayName: 'Asana',
            },
            teamwork: {
                importSettings: ['Projects', 'Companies', 'Tasks', 'Time Logs'],
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Projects', 'Tasks', 'Companies'],
                    onlyCustomFilterSettings: true,
                    Projects: {
                        entityType: 'Project',
                        pluralLabel: 'Projects',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Tasks: {
                        entityType: 'Task',
                        pluralLabel: 'Tasks',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Companies: {
                        entityType: 'Company',
                        pluralLabel: 'Companies',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Teamwork field',
                        description: 'Example: Task completed',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of critical tasks',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of critical tasks',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created-on',
                fieldOptions: ['Project', 'Task', 'Company', 'Time Log'],
                filterOptions: ['Project', 'Company', 'Task'],
                integrationRequireMap: '',
                displayName: 'Teamwork',
            },
            wunderlist: {
                importSettings: ['Lists'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { List: true },
                    isOnlyDataRetention: { List: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {},
                fieldSettings: [],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: [],
                filterOptions: ['List'],
                displayName: 'Wunderlist',
            },
            wrike: {
                importSettings: ['Accounts', 'Folders', 'Tasks'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Folder: true, Task: true, Account: true },
                    isOnlyDataRetention: { Account: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Accounts', 'Folders', 'Tasks'],
                    onlyCustomFilterSettings: true,
                    Accounts: {
                        entityType: 'Account',
                        pluralLabel: 'Accounts',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Folders: {
                        entityType: 'Folder',
                        pluralLabel: 'Folders',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Tasks: {
                        entityType: 'Task',
                        pluralLabel: 'Tasks',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Wrike field',
                        description: 'Example: Account, Folder, Task',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of Tasks per Account',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of Accounts',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'createdDate',
                fieldOptions: ['Accounts', 'Folders', 'Tasks'],
                filterOptions: ['Accounts', 'Folders', 'Tasks'],
                integrationRequireMap: '',
                displayName: 'Wrike',
            },
            basecamp: {
                importSettings: ['Projects', 'Todos', 'TodoList'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Project: true, ToDoList: true, Todo: true },
                    isOnlyDataRetention: { Project: true, ToDoList: true, Todo: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Projects', 'Todos', 'TodoLists'],
                    onlyCustomFilterSettings: true,
                    Projects: {
                        entityType: 'Project',
                        pluralLabel: 'Projects',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    TodosList: {
                        entityType: 'TodoList',
                        pluralLabel: 'Todo Lists',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Todos: {
                        entityType: 'Todo',
                        pluralLabel: 'Todos',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Basecamp field',
                        description: 'Example: Todo list progress',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of Todos',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open Todo items',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Projects', 'Todos', 'TodoList'],
                filterOptions: ['Projects', 'Todos', 'TodoList'],
                integrationRequireMap: '',
                displayName: 'Basecamp',
            },
            monday: {
                importSettings: [],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Item: true, Board: true },
                    isOnlyDataRetention: {
                        Item: true,
                        Board: true,
                        Update: true,
                        Tag: true,
                        User: true,
                        Team: true,
                        Group: true,
                    },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: true,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Items', 'Groups', 'Boards', 'Users', 'Teams', 'Tags', 'Updates'],
                    onlyCustomFilterSettings: true,
                    Items: {
                        entityType: 'Item',
                        pluralLabel: 'Items',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Groups: {
                        entityType: 'Group',
                        pluralLabel: 'Groups',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Boards: {
                        entityType: 'Board',
                        pluralLabel: 'Boards',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Users: {
                        entityType: 'User',
                        pluralLabel: 'Users',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Teams: {
                        entityType: 'Team',
                        pluralLabel: 'Teams',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Tags: {
                        entityType: 'Tag',
                        pluralLabel: 'Tags',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Updates: {
                        entityType: 'Update',
                        pluralLabel: 'Updates',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Monday field',
                        description: 'Example: Item',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of items per Board',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of items',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Item', 'Board'],
                filterOptions: ['Item', 'Board'],
                integrationRequireMap: '',
                displayName: 'Monday',
            },
            kanbanize: {
                importSettings: ['Boards', 'Initiatives', 'Cards'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Board: true },
                    isOnlyDataRetention: { Board: true, SubTask: true, Initiative: true, Card: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Boards', 'Initiatives', 'Cards'],
                    onlyCustomFilterSettings: true,
                    Boards: {
                        entityType: 'Board',
                        pluralLabel: 'Boards',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Initiatives: {
                        entityType: 'Initiative',
                        pluralLabel: 'Initiatives',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Cards: {
                        entityType: 'Card',
                        pluralLabel: 'Cards',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Kanbanize field',
                        description: 'Example: Initiative completed',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of blocked cards',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of critical tasks',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'createdat',
                fieldOptions: ['Boards', 'Initiatives', 'Cards'],
                filterOptions: ['Boards', 'Initiatives', 'Cards'],
                integrationRequireMap: '',
                displayName: 'Kanbanize',
            },
            harvest: {
                importSettings: ['Projects', 'Clients'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Work_Log: true },
                    isOnlyDataRetention: { Client: true, Project: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Projects', 'Clients'],
                    onlyCustomFilterSettings: true,
                    Projects: {
                        entityType: 'Project',
                        pluralLabel: 'Projects',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Clients: {
                        entityType: 'Client',
                        pluralLabel: 'Clients',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Harvest field',
                        description: 'Example: Budget, Hourly rate',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of hours spent',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Client', 'Project', 'Work_Log'],
                filterOptions: ['Client', 'Project'],
                integrationRequireMap: '',
                displayName: 'Harvest',
            },
            namely: {
                importSettings: ['Profiles'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Profile: true },
                    isOnlyDataRetention: {},
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Profiles'],
                    onlyCustomFilterSettings: true,
                    Profiles: {
                        entityType: 'Profile',
                        pluralLabel: 'Profiles',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Namely field',
                        description: 'Example: Profile',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of profiles',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['Profile'],
                filterOptions: ['Profile'],
                integrationRequireMap: '',
                displayName: 'Namely',
            },
            smartsheet: {
                importSettings: [],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Row: true, Attachment: true, Discussion: true, Column: true },
                    isOnlyDataRetention: { Attachment: true, Discussion: true, Column: true },
                },
                dataSourceSectionType: 'SPREADSHEET',
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: true,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    onlyCustomFilterSettings: true,
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Smartsheet field',
                        description: 'A specific column in a spreadsheet',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of rows per field',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open rows',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: [],
                filterOptions: [],
                integrationRequireMap: '',
                displayName: 'Smartsheet',
            },
            tableau: {
                importSettings: [],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Row: true },
                    isOnlyDataRetention: { Row: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: true,
                integrationSupportsCustomOwnerFields: true,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    onlyCustomFilterSettings: true,
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Tableau field',
                        description: 'A specific column in a tableau view',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of rows per field',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open rows',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: [],
                filterOptions: [],
                displayName: 'Tableau',
            },
            excel365: {
                importSettings: ['Row', 'Table'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Row: true, Table: true },
                    isOnlyDataRetention: { Row: true, Table: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: true,
                integrationSupportsCustomOwnerFields: true,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Row', 'Table'],
                    onlyCustomFilterSettings: true,
                    Rows: {
                        entityType: 'Row',
                        pluralLabel: 'Rows',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Tables: {
                        entityType: 'Table',
                        pluralLabel: 'Tables',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Excel Online field',
                        description: 'A specific column in a spreadsheet',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of rows per field',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open rows',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['Row', 'Table'],
                filterOptions: ['Row', 'Table'],
                integrationRequireMap: SUBSCRIPTION_TIERS.BASIC.tnkRequire,
                displayName: 'Excel',
            },
            // eslint-disable-next-line camelcase
            google_calendar: {
                importSettings: ['Events'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Event: true },
                    isOnlyDataRetention: { Event: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Events'],
                    onlyCustomFilterSettings: true,
                    Events: {
                        entityType: 'Event',
                        pluralLabel: 'Events',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Google Calendar field',
                        description: 'Example: Date',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of events',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of events',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created',
                fieldOptions: ['Event'],
                filterOptions: ['Events'],
                integrationRequireMap: SUBSCRIPTION_TIERS.BASIC.tnkRequire,
                displayName: 'Google Calendar',
            },
            rss: {
                importSettings: ['Items'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Item: true },
                    isOnlyDataRetention: { Item: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Items'],
                    onlyCustomFilterSettings: true,
                    Items: {
                        entityType: 'Item',
                        pluralLabel: 'Items',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: ['description', 'link'],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'RSS field',
                        description: 'Example: Author, Source',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of items per Feed',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of items',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['Item'],
                filterOptions: ['Item'],
                integrationRequireMap: SUBSCRIPTION_TIERS.BASIC.tnkRequire,
                displayName: 'RSS',
            },
            facebookads: {
                importSettings: ['Campaigns', 'AdSets', 'Ads', 'Lead Gens'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Ad: true, Campaign: true, AdSet: true, LeadGen: true },
                    isOnlyDataRetention: { Campaign: true, AdSet: true },
                },
    
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Campaigns', 'AdSets', 'Ads', 'Lead Gens'],
                    onlyCustomFilterSettings: true,
                    Campaigns: {
                        entityType: 'Campaign',
                        pluralLabel: 'Campaigns',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    AdSets: {
                        entityType: 'AdSet',
                        pluralLabel: 'AdSets',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    Ads: {
                        entityType: 'Ad',
                        pluralLabel: 'Ads',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                    LeadGens: {
                        entityType: 'LeadGen',
                        pluralLabel: 'Lead Gens',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Facebook ads field',
                        description: 'Example: Campaign, AdSet, Ad',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of ads per Campaign',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of ads',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_time',
                fieldOptions: ['Campaign', 'AdSet', 'Ad', 'LeadGen'],
                filterOptions: ['Campaign', 'AdSet', 'Ad', 'LeadGen'],
                integrationRequireMap: '',
                displayName: 'Facebook Ads',
            },
            gmail: {
                importSettings: ['Messages'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Message: true },
                    isOnlyDataRetention: {},
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: [],
                    onlyCustomFilterSettings: true,
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Gmail field',
                        description: 'Example: Date',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of events',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of messages',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'internalDate',
                fieldOptions: [],
                filterOptions: [],
                integrationRequireMap: '',
                displayName: 'Gmail',
            },
            sendgrid: {
                importSettings: [],
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {},
                fieldSettings: [],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: [],
                filterOptions: [],
                integrationRequireMap: '',
                displayName: 'SendGrid',
            },
            dropbox: {
                importSettings: ['Folders', 'Files'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Folder: true },
                    isOnlyDataRetention: { File: true, Folder: true },
                },
                importIndexOnly: true,
                isDataProvider: true,
                integrationType: 'DROPBOX',
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Folders', 'Files'],
                    Folders: {
                        entityType: 'Folder',
                        pluralLabel: 'Folders',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Files: {
                        entityType: 'File',
                        pluralLabel: 'Files',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Dropbox field',
                        description: 'A specific property on a file or folder',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of files per folder',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of starred files',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['File', 'Folder'],
                filterOptions: [],
                integrationRequireMap: SUBSCRIPTION_TIERS.BASIC.tnkRequire,
                displayName: 'Dropbox',
            },
            greenhouse: {
                importSettings: ['Candidate', 'Application', 'Job', 'Offer', 'User', 'Interview'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: true,
                    canEditAll: true,
                    removableEntities: {},
                    editableEntities: {},
                    isOnlyDataRetention: {},
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Candidates', 'Applications', 'Jobs', 'Offers', 'Users', 'Interviews'],
                    onlyCustomFilterSettings: true,
                    Candidates: {
                        entityType: 'Candidate',
                        pluralLabel: 'Candidates',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Applications: {
                        entityType: 'Application',
                        pluralLabel: 'Applications',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Jobs: {
                        entityType: 'Job',
                        pluralLabel: 'Jobs',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Offers: {
                        entityType: 'Offer',
                        pluralLabel: 'Offers',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Users: {
                        entityType: 'User',
                        pluralLabel: 'Users',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Interviews: {
                        entityType: 'Interview',
                        pluralLabel: 'Interviews',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Greenhouse field',
                        description: 'Example: Candidate Name, Application',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of candidates',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of candidates',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Candidate', 'Application', 'Job', 'Offer', 'User', 'Interview'],
                filterOptions: ['Candidate', 'Application', 'Job', 'Offer', 'User', 'Interview'],
                integrationRequireMap: '',
                displayName: 'Greenhouse',
            },
            nutshell: {
                importSettings: ['Leads', 'Companies'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Company: true, Lead: true, Task: true },
                    isOnlyDataRetention: { Company: true, Task: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: true,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Leads', 'Companies'],
                    onlyCustomFilterSettings: true,
                    Leads: {
                        entityType: 'Lead',
                        pluralLabel: 'Leads',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: ['confidence', 'Status'],
                    },
                    Companies: {
                        entityType: 'Company',
                        pluralLabel: 'Companies',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Nutshell field',
                        description: 'Example: Company, Lead',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of lead per Company',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of leads',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'createdTime',
                fieldOptions: ['Lead', 'Company', 'Task'],
                filterOptions: ['Company', 'Lead'],
                displayName: 'nutshell',
            },
            pipedrive: {
                importSettings: ['Deals', 'Organizations', 'Activities'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Activity: true, Deal: true, Organization: true },
                    isOnlyDataRetention: { Activity: true, Deal: true, Organization: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: true,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Deals', 'Organizations'],
                    onlyCustomFilterSettings: true,
                    Deals: {
                        entityType: 'Deal',
                        pluralLabel: 'Deals',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: ['formatted_value', 'stage_id'],
                    },
                    Organizations: {
                        entityType: 'Organization',
                        pluralLabel: 'Organizations',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Pipedrive field',
                        description: 'Example: Organization, Deal, Activity',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of deals per Organization',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of deals',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'add_time',
                fieldOptions: ['Deal', 'Organization', 'Activity'],
                filterOptions: ['Organization', 'Deal', 'Activity'],
                integrationRequireMap: '',
                displayName: 'Pipedrive',
            },
            salesforceiq: {
                importSettings: ['List Items'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { List_Item: true },
                    isOnlyDataRetention: {},
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: true,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['List Items'],
                    onlyCustomFilterSettings: true,
                    'List Items': {
                        entityType: 'List_Item',
                        pluralLabel: 'List Items',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'SalesforceIQ field',
                        description: 'Example: Account, List Item',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of List items per Account',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of list items',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {
                    supportedEntityTypes: [
                        'ListItems',
                        'TNK_OPP',
                        'List_Item',
                        'ListItem',
                        'List item',
                        'list item',
                        'List items',
                        'List Items',
                        'list items',
                        'LIST_ITEM',
                        'LIST_ITEMS',
                        'ListItems',
                    ],
                },
                createDateSettings: 'createdDate',
                fieldOptions: ['List_Item'],
                filterOptions: ['List_Item'],
                integrationRequireMap: '',
                displayName: 'salesforceIQ',
            },
            prosperworks: {
                importSettings: ['Opportunities', 'Projects', 'Companies', 'Tasks', 'Leads'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Task: true, Opportunity: true, Lead: true, Company: true, Project: true },
                    isOnlyDataRetention: {},
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Opportunities', 'Projects', 'Tasks', 'Companies', 'Leads'],
                    onlyCustomFilterSettings: true,
                    Projects: {
                        entityType: 'Project',
                        pluralLabel: 'Projects',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Tasks: {
                        entityType: 'Task',
                        pluralLabel: 'Tasks',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Companies: {
                        entityType: 'Company',
                        pluralLabel: 'Companies',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Opportunities: {
                        entityType: 'Opportunity',
                        pluralLabel: 'Opportunities',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Leads: {
                        entityType: 'Lead',
                        pluralLabel: 'Leads',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Prosperworks field',
                        description: 'Example: Task completed',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of tasks',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of high priority tasks',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'date_created',
                fieldOptions: ['Opportunity', 'Project', 'Task', 'Company', 'Lead'],
                filterOptions: ['Project', 'Company', 'Opportunity', 'Lead', 'Task'],
                integrationRequireMap: '',
                displayName: 'Prosperworks',
            },
            dynamics365: {
                importSettings: ['Accounts', 'Opportunities', 'Tasks'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Task: true },
                    isOnlyDataRetention: { Account: true, Opportunity: true, Task: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Accounts', 'Opportunities', 'Tasks'],
                    onlyCustomFilterSettings: true,
                    Accounts: {
                        entityType: 'Account',
                        pluralLabel: 'Accounts',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Opportunities: {
                        entityType: 'Opportunity',
                        pluralLabel: 'Opportunities',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Tasks: {
                        entityType: 'Task',
                        pluralLabel: 'Tasks',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Dynamics 365 field',
                        description: 'Example: Opportunity close date',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of leads',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of high priority opportunities',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'createdon',
                fieldOptions: ['Account', 'Opportunity', 'Task'],
                filterOptions: ['Account', 'Opportunity', 'Task'],
                integrationRequireMap: '',
                displayName: 'Dynamics 365',
            },
            zohocrm: {
                importSettings: ['Accounts', 'Deals', 'Leads'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Deal: true, Account: true, Lead: true },
                    isOnlyDataRetention: {},
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Accounts', 'Deals', 'Leads'],
                    onlyCustomFilterSettings: true,
                    Accounts: {
                        entityType: 'Account',
                        pluralLabel: 'Accounts',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Deals: {
                        entityType: 'Deal',
                        pluralLabel: 'Deals',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Leads: {
                        entityType: 'Lead',
                        pluralLabel: 'Leads',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'ZohoCRM field',
                        description: 'Example: Deal, Account',
                        image: '/images/createFieldDefinition/zendesk_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of deals per Account',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of high priority deals',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'Created_Time',
                fieldOptions: ['Account', 'Deal', 'Lead'],
                filterOptions: ['Account', 'Deal', 'Lead'],
                integrationRequireMap: '',
                displayName: 'Zohocrm',
            },
            stripeapp: {
                importSettings: ['Customers', 'Subscriptions', 'Products', 'Invoices'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Customer: true, Subscription: true, Product: true, Invoice: true },
                    isOnlyDataRetention: { Customer: true, Subscription: true, Product: true, Invoice: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Customers', 'Subscriptions', 'Products', 'Invoices'],
                    onlyCustomFilterSettings: true,
                    Customers: {
                        entityType: 'Customer',
                        pluralLabel: 'Customers',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Subscriptions: {
                        entityType: 'Subscription',
                        pluralLabel: 'Subscriptions',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Products: {
                        entityType: 'Product',
                        pluralLabel: 'Products',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Invoices: {
                        entityType: 'Invoice',
                        pluralLabel: 'Invoices',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Stripe field',
                        description: 'Example: Customer, Invoice',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of customers',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of high priority customers',
                        image: '/images/createFieldDefinition/filter_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['Customer', 'Subscription', 'Product', 'Invoice'],
                filterOptions: ['Customer', 'Subscription', 'Product', 'Invoice'],
                integrationRequireMap: '',
                displayName: 'Stripe',
            },
            agilecrm: {
                importSettings: ['Deals', 'Companies'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Deal: true, Company: true },
                    isOnlyDataRetention: { Deal: true, Company: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: true,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Deals', 'Companies'],
                    onlyCustomFilterSettings: true,
                    Deals: {
                        entityType: 'Deal',
                        pluralLabel: 'Deals',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Companies: {
                        entityType: 'Company',
                        pluralLabel: 'Companies',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Agile CRM field',
                        description: 'Example: Organization, Deal, Activity',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of deals per Organization',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of deals',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_time',
                fieldOptions: ['Deal', 'Company'],
                filterOptions: ['Deal', 'Company'],
                integrationRequireMap: '',
                displayName: 'Agile CRM',
            },
            frontapp: {
                importSettings: ['Conversations', 'Teammates'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Teammate: true, Conversation: true },
                    isOnlyDataRetention: { Teammate: true, Conversation: true },
                },
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Conversations', 'Teammates'],
                    onlyCustomFilterSettings: true,
                    Conversations: {
                        entityType: 'Conversation',
                        pluralLabel: 'Conversations',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Teammates: {
                        entityType: 'Teammate',
                        pluralLabel: 'Teammates',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Frontapp field',
                        description: 'Example: Organization, Deal, Activity',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of deals per Organization',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of subscriptions',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created_at',
                fieldOptions: ['Conversation', 'Teammate'],
                filterOptions: ['Conversation', 'Teammate'],
                integrationRequireMap: '',
                displayName: 'Front',
            },
            // Other
            emailwebhook: {
                importSettings: ['Emails', 'Threads'],
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: true,
                    removableEntities: {},
                    editableEntities: {},
                    isOnlyDataRetention: {},
                },
                importIndexOnly: true,
                syncSettings: {
                    supportedEntities: ['Emails', 'Threads'],
                    onlyCustomFilterSettings: true,
                    Emails: {
                        entityType: 'Email',
                        pluralLabel: 'Emails',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Threads: {
                        entityType: 'Thread',
                        pluralLabel: 'Threads',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        buttonText: 'Webhook field',
                        description: 'Example: Stage, Probability, ExpectedRevenue',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                        type: 'EXTERNAL',
                    },
                    {
                        buttonText: 'Aggregate items',
                        description: 'Example: No. of open tasks per opportunity',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                        overrideFieldType: 'number',
                        type: 'AGGREGATE_QUERY',
                    },
                ],
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open issues',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                createDateSettings: 'created',
                fieldOptions: ['Email', 'Thread'],
                filterOptions: ['Email', 'Thread'],
                dataSourceSectionType: 'EMAIL_INBOX',
                integrationRequireMap: SUBSCRIPTION_TIERS.BASIC.tnkRequire,
                displayName: 'Email Inbox Intake',
            },
            webhook: {
                displayName: 'Custom',
                importSettings: [],
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: true,
                integrationSupportsCustomOwnerFields: true,
                dataSourceSectionType: 'CUSTOM',
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: false,
                    canEditAll: true,
                    removableEntities: {},
                    editableEntities: {},
                    isOnlyDataRetention: {},
                },
                syncSettings: {
                    onlyCustomFilterSettings: true,
                },
                fieldSettings: [
                    {
                        buttonText: 'Webhook field',
                        description: 'Example: Stage, Probability, ExpectedRevenue',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                        type: 'EXTERNAL',
                    },
                    {
                        buttonText: 'Aggregate items',
                        description: 'Example: No. of open tasks per opportunity',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                        overrideFieldType: 'number',
                        type: 'AGGREGATE_QUERY',
                    },
                ],
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open issues',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                createDateSettings: 'created',
            },
            manual: {
                fieldSettings: [{ type: 'MANUAL' }],
                globalMetricSettings: [
                    {
                        type: 'MANUAL',
                    },
                ],
            },
            sql: {
                fieldSettings: [
                    {
                        overrideFieldType: 'number',
                        type: 'SQL',
                    },
                ],
                globalMetricSettings: [
                    {
                        overrideFieldType: 'number',
                        type: 'SQL',
                    },
                ],
                displayName: 'SQL',
            },
            google: {
                fieldSettings: [
                    {
                        type: 'GOOGLE',
                    },
                ],
                globalMetricSettings: [
                    {
                        type: 'GOOGLE',
                    },
                ],
                displayName: 'Google Analytics',
            },
            outlook: {
                importSettings: ['Events'],
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Events'],
                    onlyCustomFilterSettings: true,
                    Events: {
                        entityType: 'Event',
                        pluralLabel: 'Events',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Outlook Calendar field',
                        description: 'Example: Date',
                        image: '/images/createFieldDefinition/external_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of events',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of open events',
                        image: '/images/createFieldDefinition/git_field_count.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'created',
                fieldOptions: ['Event'],
                filterOptions: ['Events'],
                integrationRequireMap: SUBSCRIPTION_TIERS.BASIC.tnkRequire,
                displayName: 'Outlook',
            },
            mavenlink: {
                importSettings: ['Projects', 'Tasks', 'CustomFields'],
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                dataRetentionSettings: {
                    isSupportsDataRetention: true,
                    canAddOrRemoveAll: true,
                    canEditAll: false,
                    removableEntities: {},
                    editableEntities: { Project: true, Task: true, User: true },
                    isOnlyDataRetention: { Project: true, Task: true, User: true },
                },
                syncSettings: {
                    supportedEntities: ['Projects', 'Tasks', 'CustomFields'],
                    onlyCustomFilterSettings: true,
                    Projects: {
                        entityType: 'Project',
                        pluralLabel: 'Projects',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    Tasks: {
                        entityType: 'Task',
                        pluralLabel: 'Tasks',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                    CustomFields: {
                        entityType: 'CustomField',
                        pluralLabel: 'CustomFields',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'Mavenlink field',
                        description: 'Example: Project, Template, Task',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of Tasks per Project',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of Projects',
                        image: '/images/createFieldDefinition/zendesk_count_field.png',
                    },
                ],
                externalStatusUpdateSettings: {},
                createDateSettings: 'createdDate',
                fieldOptions: ['Projects', 'Tasks', 'CustomFields'],
                filterOptions: ['Projects', 'Tasks', 'CustomFields'],
                integrationRequireMap: '',
                displayName: 'Mavenlink',
            },
            teamconnect: {
                importSettings: ['Matters'],
                importIndexOnly: true,
                allEntitiesSupportedIntegrations: false,
                integrationSupportsCustomOwnerFields: false,
                integrationSupportsChangingFieldType: false,
                syncSettings: {
                    supportedEntities: ['Matters'],
                    onlyCustomFilterSettings: true,
                    Matters: {
                        entityType: 'Matter',
                        pluralLabel: 'Matters',
                        viewsBlackList: [],
                        fieldsBlackList: [],
                        defaultFields: [],
                    },
                },
                fieldSettings: [
                    {
                        type: 'EXTERNAL',
                        buttonText: 'TeamConnect field',
                        description: 'Example: Matter',
                        image: '/images/createFieldDefinition/salesforce_entity_field.png',
                    },
                    {
                        type: 'AGGREGATE_QUERY',
                        buttonText: 'Aggregate items',
                        overrideFieldType: 'number',
                        description: 'Example: No. of matters',
                        image: '/images/createFieldDefinition/salesforce_count_field.png',
                    },
                ],
                integrationCompilerType: 'Elastic',
                globalMetricSettings: [],
                externalStatusUpdateSettings: {},
                createDateSettings: '',
                fieldOptions: ['Matter'],
                filterOptions: ['Matters'],
                integrationRequireMap: '',
                displayName: 'Teamconnect',
            },
        };
    }

    private integrationGroups = {
        code: {
            title: 'Configure the code repository',
            info: 'Used to bridge your high-level tracks with commits, pull-requests and issues',
            integrations: [
                'github',
                'bitbucket',
                // Add Code Integration Name Here - DO NOT DELETE OR MODIFY THIS LINE
            ],
        },
        issues: {
            title: 'Tasks management tool',
            info: 'Used to bridge your high-level tracks with tasks, bugs and issues',
            integrations: [
                'trello',
                'jira',
                'asana',
                'teamwork',
                'gitlab',
                'wunderlist',
                'wrike',
                'basecamp',
                'monday',
                'kanbanize',
                'mavenlink',
                'datadog',
                'origamirisk',
                'kustomer',
                'pagerduty',
                'miuros',
                // Add Issues Integration Name Here - DO NOT DELETE OR MODIFY THIS LINE
            ],
        },
        finance: {
            title: 'Finance',
            info: 'Used to match your high-level tracks with clients and projects',
            integrations: [
                'harvest',
                'zuora',
                'namely',
                'netsuite',
                'concur',
                'dynamics365fo',
                'coupa',
                'allocadia',
                // Add Finance Integration Name Here - DO NOT DELETE OR MODIFY THIS LINE
            ],
        },
        data: {
            title: 'External data sources',
            info: 'Used to bridge your high-level tracks with those hard to reach important values',
            integrations: [
                'microsoftteamsapp',
                'google',
                'smartsheet',
                'smartsheetworkspace',
                'googlesheets',
                'googledrive',
                'tableau',
                'excel365',
                'sharepoint',
                'google_calendar',
                'rss',
                'facebookads',
                'gmail',
                'sendgrid',
                'dropbox',
                'greenhouse',
                'googlecloud',
                'googlegroups',
                'docusign',
                'okta',
                'outlook',
                'teamconnect',
                'ironclad',
                'servicenow',
                'amazons3',
                'sapopenconnector',
                'amazonkinesis',
                'marketo',
                'workdayreports',
                'sqldatabase',
                'typeform',
                'springcm',
                'lattice',
                'goodtime',
                'workday',
                'sftp',
                'box',
                'shopify',
                'microsoftadmin',
                'lever',
                'googlecloudstorage',
                'onedrive365',
                'onedrive',
                'uipath',
                'netdocuments',
                'azureblobstorage',
                'evisort',
                'simplelegal',
                'notion',
                'googleslides',
                'workfront',
                'pardot',
                'cloudconvert',
                'braze',
                'no_code_data_source',
                'adobesign',
                'ukg',
                'nextcloud',
                'amazontextract',
                // Add Data Integration Name Here - DO NOT DELETE OR MODIFY THIS LINE
            ],
        },
        support: {
            title: 'Support ticketing tool',
            info: 'Used to bridge your high-level tracks with support cases',
            integrations: ['zendesk', 'intercom', 'freshdesk', 'twiliochat', 'slack_app', 'frontapp', 'twiliosms'],
            // Add Support Integration Name Here - DO NOT DELETE OR MODIFY THIS LINE
        },
        sales: {
            title: 'CRM tool',
            info: 'Used to bridge your high-level tracks with deals',
            integrations: [
                'salesforce',
                'hubspot',
                'nutshell',
                'pipedrive',
                'salesforceiq',
                'prosperworks',
                'dynamics365',
                'zohocrm',
                'stripeapp',
                'agilecrm',
                'mailchimptransactional',
                'mailchimpmarketing',
                'gong',
                'airtable',
                'outreach',
                // Add Sales Integration Name Here - DO NOT DELETE OR MODIFY THIS LINE
            ],
        },
        communication: {
            title: 'Communication Tools',
            info:
                'Communication tools are used by people coordination actions that are defined in your modules and ' +
                'Tonkean auto check-in capabilities to reach out to your team and collect the relevant information ' +
                'of the items you track.',
            integrations: (projectId) => {
                const integrations = ['slack', 'microsoft_teams'];
                if (this.$rootScope?.features?.[projectId]?.tonkean_feature_google_chat_communication_integration) {
                    integrations.push('googlechat');
                }
                return integrations;
            },
        },
        hideIntegrations: {
            integrations: ['microsoft_teams_user'],
        },
        webhooks: {
            title: 'Webhooks',
            info: 'Used to integrate Tonkean with various types of integrations.',
            integrations: ['emailwebhook', 'webhook'],
        },
    };

    /**
    * This object is a combination of the integrationsConfig which definied below and the new integrations configuration
    * we import from IntegrationManager.
    * This object is assigned from 'getIntegrationsConfig' function at the bottom of this file.
    */
    private cachedIntegrationsConfig: Record<string, any>;

    // Template for new integration creation
    /* eslint-disable no-unused-vars */
    // const integrationTemplate = {
    //     importSettings: [],
    //     importIndexOnly: true,
    //     allEntitiesSupportedIntegrations: false,
    //     integrationSupportsCustomOwnerFields: false,
    //     integrationSupportsChangingFieldType: false,
    //     syncSettings: {},
    //     fieldSettings: [],
    //     integrationCompilerType: 'Elastic',
    //     globalMetricSettings: [],
    //     externalStatusUpdateSettings: {},
    //     createDateSettings: '',
    //     fieldOptions: [],
    //     filterOptions: [],
    //     integrationRequireMap: '',
    // };
    /* eslint-disable no-unused-vars */

    getUniqueIntegrationsDataSources(
        integrationsTypesToFilter,
        canBeCreatedByUserIntegrationTypes,
        projectId,
    ) {
        // Constructing a list of existing integration unique types without duplicates.
        const excludeIntegrations = {};

        if (integrationsTypesToFilter) {
            for (const element of integrationsTypesToFilter) {
                excludeIntegrations[element.toLowerCase()] = true;
            }
        }

        // We also want to filter out all the communications integrations from the available sources.
        const communicationIntegrations = this.getIntegrationGroups().communication.integrations(projectId);
        for (const communicationIntegration of communicationIntegrations) {
            excludeIntegrations[communicationIntegration] = true;
        }

        // Taking only the sources that do not already exist, out of the imp
        let sources = this.getAllIntegrationTypes();
        sources = sources.filter(function (source) {
            return !excludeIntegrations[source];
        });

        // Creating a set of can be created by user integration types.
        const canBeCreatedByUserIntegrationTypesSet = {};
        if (canBeCreatedByUserIntegrationTypes?.length) {
            canBeCreatedByUserIntegrationTypes.forEach((canBeCreatedByUserIntegrationType) => {
                if (canBeCreatedByUserIntegrationType) {
                    canBeCreatedByUserIntegrationTypesSet[canBeCreatedByUserIntegrationType.toLowerCase()] = true;
                    canBeCreatedByUserIntegrationTypesSet[canBeCreatedByUserIntegrationType.toUpperCase()] = true;
                }
            });
        }

        return sources.filter((source) => canBeCreatedByUserIntegrationTypesSet[source]);
    }

    /**
     * Returns all integration types supported in Tonkean.
     */
    getAllIntegrationTypes(exclude?: Record<string, boolean>) {
        let allIntegrationTypes = [];

        for (const group in this.integrationGroups) {
            if (
                this.integrationGroups.hasOwnProperty(group) &&
                this.integrationGroups[group].integrations &&
                (!exclude || !exclude[group])
            ) {
                allIntegrationTypes = allIntegrationTypes.concat(this.integrationGroups[group].integrations);
            }
        }

        return allIntegrationTypes;
    }

    /**
     * Gets a map for all integration groups
     * @return {{}}
     */
    getIntegrationGroups() {
        return angular.copy(this.integrationGroups);
    }

    /**
     * This function returns the complete config of the integrations.
     * In this file we have integrationsConfig object which holds all of the integrations config.
     * From now, there is a new way to create integration config and we should create each configuration
     * in a separate file.
     * In order both configuration (old above, new in other files) will still work together as expected
     * we combine them together in this function.
     */
    public getIntegrationsConfig() {
        // First, we cache if needed.
        if (!this.cachedIntegrationsConfig) {
            // Calling to Integration Manager in order to retrieve the new integrations config.
            this.cachedIntegrationsConfig = Object.assign(
                this.integrationsConfig,
                IntegrationManager.getOldIntegrationsConfiguration(this.$rootScope),
            );
        }

        return this.cachedIntegrationsConfig;
    }

    public getGlobalIntegrations() {
        const globalIntegrations: string[] = [];

        const integrationsConfig = this.getIntegrationsConfig();
        for (const key in integrationsConfig) {
            if (integrationsConfig.hasOwnProperty(key)) {
                const currentIntegrationConfiguration = integrationsConfig[key];

                if (currentIntegrationConfiguration.globalIntegration) {
                    globalIntegrations.push(key);
                }
            }
        }

        return globalIntegrations;
    }

    /**
    * Extracts the data source settings by the setting name.
    */
    private extractIntegrationsSettingByName(settingName, defaultValue, specialTreatmentAction?) {
        if (this.integrationConfigMap[settingName] && !specialTreatmentAction) {
            return this.integrationConfigMap[settingName];
        } else {
            const settings = {};
            const integrationsConfig = this.getIntegrationsConfig();
            for (const integrationKey in integrationsConfig) {
                if (integrationsConfig.hasOwnProperty(integrationKey)) {
                    const setting = integrationsConfig[integrationKey][settingName] || defaultValue;
                    settings[integrationKey] = specialTreatmentAction ? specialTreatmentAction(setting) : setting;
                }
            }
            // Fill the cached integration config object
            this.integrationConfigMap[settingName] = settings;
            return settings;
        }
    }

    public getImportSettings() {
        return this.extractIntegrationsSettingByName('importSettings', [], (setting) => this.invokeFunctionIfNeeded(setting));
    }

    public getDataRetentionSettings() {
        return this.extractIntegrationsSettingByName('dataRetentionSettings', {});
    }

    public getImportIndexOnlySettings() {
        return this.extractIntegrationsSettingByName('importIndexOnly', false);
    }

    public getAllEntitiesSupportedIntegrations() {
        return this.extractIntegrationsSettingByName('allEntitiesSupportedIntegrations', false);
    }

    public getSyncSettings() {
        return this.extractIntegrationsSettingByName('syncSettings', {}, (setting) => {
            setting.supportedEntities = this.invokeFunctionIfNeeded(setting.supportedEntities);
            return setting;
        })
    }

    public getFieldSettings() {
        return this.extractIntegrationsSettingByName('fieldSettings', []);
    }

    public getGlobalMetricSettings() {
        return this.extractIntegrationsSettingByName('globalMetricSettings', []);
    }

    public getCreateDateSettings() {
        return this.extractIntegrationsSettingByName('createDateSettings', '');
    }

    public getAllIntegrationsDisplayName() {
        return this.extractIntegrationsSettingByName('displayName', '');
    }

    public getIntegrationSupportsCustomOwnerFields() {
        return this.extractIntegrationsSettingByName('integrationSupportsCustomOwnerFields', false);
    }

    public getIntegrationSupportsChangingFieldType() {
        return this.extractIntegrationsSettingByName('integrationSupportsChangingFieldType', false);
    }

    public getFieldOptions() {
        return this.extractIntegrationsSettingByName('fieldOptions', [], (setting) => this.invokeFunctionIfNeeded(setting));
    }

    public getFilterOptions() {
        return this.extractIntegrationsSettingByName('filterOptions', [], (setting) => this.invokeFunctionIfNeeded(setting));
    }

    public getIntegrationRequireMap() {
        return this.extractIntegrationsSettingByName('integrationRequireMap', '');
    }

    public getExternalStatusUpdateSettings() {
        return this.extractIntegrationsSettingByName('externalStatusUpdateSettings', {});
    }

    /**
     * Gets the import settings for the given integration type.
     */
    public getImportSettingsByIntegration(integrationType) {
        return this.getImportSettings()[integrationType.toLowerCase()];
    }

    public getDataRetentionSettingsByIntegration(integrationType) {
        return this.getDataRetentionSettings()[integrationType.toLowerCase()];
    }

    /**
     * Returns true if given integration type supports all entities.
     */
    public integrationSupportsAllEntities(integrationType) {
        return !!this.getAllEntitiesSupportedIntegrations()[integrationType.toLowerCase()];
    }

    /**
     * Gets the relevant sync settings according to the given integration type.
     */
    public getSyncSettingsByIntegration(integrationType) {
        return this.getSyncSettings()[integrationType.toLowerCase()];
    }

    /**
    * Trying to fetch the configuration of the given entity of given integration from consts if possible.
    */
    public getSyncSettingsByEntity(integrationType, entity) {
        // Trying to fetch the configuration of the selected entity from consts if possible.
        const syncSettings = this.getSyncSettings()[integrationType.toLowerCase()];
        for (const key in syncSettings) {
            if (syncSettings.hasOwnProperty(key)) {
                const value = syncSettings[key];

                if (
                    value &&
                    ((value.entityType && value.entityType.toUpperCase() === entity.toUpperCase()) ||
                        (value.pluralLabel && value.pluralLabel.toUpperCase() === entity.toUpperCase()))
                ) {
                    return value;
                }
            }
        }
    }

    /**
     * Returns the field settings of the given integration type.
     */
    public getFieldSettingsByIntegration(integrationType) {
        return this.getFieldSettings()[integrationType.toLowerCase()];
    }

    public getIntegrationDisplayNameByIntegrationType(integrationType) {
        return this.getAllIntegrationsDisplayName()[integrationType];
    }

    /**
     * Returns true if the initiative supports external status update.
     * Otherwise, returns false.
     */
    public initiativeSupportsExternalStatusChange(initiative) {
        // All mandatory properties.
        if (
            !initiative ||
            !initiative.externalSource ||
            !initiative.externalType ||
            !initiative.externalId ||
            !initiative.group ||
            !initiative.group.groupUniqueTypeToProjectIntegrationMap
        ) {
            return false;
        }

        // Verifying we can find the project integration it's synced with.
        const initiativeSyncedProjectIntegration =
            initiative.group.groupUniqueTypeToProjectIntegrationMap[initiative.externalSource];
        if (!initiativeSyncedProjectIntegration) {
            return false;
        }

        // Only if initiative external type and integration are supported!
        const integrationType = initiativeSyncedProjectIntegration.integration.integrationType.toUpperCase();
        return (
            this.getExternalStatusUpdateSettings()[integrationType] &&
            this.getExternalStatusUpdateSettings()[integrationType].supportedEntityTypes.includes(
                initiative.externalType,
            )
        );
    }

    /**
    * Returns the integrations names that implemented the Download method in their StorageProvider implementation.
    */
    public getSupportedDownloadingStorageIntegrations() {
        const integrationsStorageProviderSettings =
            IntegrationManager.getConfigurationByPropertyName('storageProviderSettings');

        const supportedIntegrations = integrationsStorageProviderSettings
            .filter((integrationSettings) => integrationSettings.storageProviderSettings.supportsDownloading)
            .map((integrationSettings) => integrationSettings.integrationName);

        const oldIntegrationConfiguration = ['GOOGLEDRIVE', 'DROPBOX', 'SMARTSHEET', 'SALESFORCE', 'JIRA'];

        return [...supportedIntegrations, ...oldIntegrationConfiguration];
    }
  
    /**
     * Returns the integrations names that implemented the Upload method in their StorageProvider implementation.
     */
    public getSupportedUploadingStorageIntegrations() {
        const integrationsStorageProviderSettings =
            IntegrationManager.getConfigurationByPropertyName('storageProviderSettings');

        const newSupportedIntegrations = integrationsStorageProviderSettings
            .filter((integrationSettings) => integrationSettings.storageProviderSettings.supportsUploading)
            .reduce((acc, value) => {
                acc[value.integrationName] = value.integrationDisplayName;
                return acc;
            }, {});

        const oldIntegrationConfiguration = {
            GOOGLEDRIVE: 'Google Drive',
            DROPBOX: 'Dropbox',
            AMAZONS3: 'Amazon S3',
            JIRA: 'Jira',
            EXCEL365: 'OneDrive', // Temp solution until we merge Excel365 into an Ofiice 365 and make a OneDrive Integration
        };

        return Object.assign(newSupportedIntegrations, oldIntegrationConfiguration);
    }

    public getNewIntegrationUniqueType(personId, integrationName) {
        return `${personId}_${integrationName.toUpperCase()}`;
    }

    public getAllFastIntegrations() {
        return IntegrationManager.getAllFastIntegrations();
    };

    /**
     * Getting the relevant integration unique type.
     * If theres an old project integration, take the unique type of that integration, this is so when editing other peoples integrations there wouldnt be any mismatches
     */
    public getIntegrationUniqueType(personId, integrationName, oldProjectIntegration) {
        if (oldProjectIntegration) {
            return oldProjectIntegration.integrationUniqueType;
        } else {
            return this.getNewIntegrationUniqueType(personId, integrationName);
        }
    }

    /**
    * Get integration array of old & new (fast) integrations
    */
    public getIntegrationsConfigList() {
        const excludedIntegrations = new Set(['manual']);

        return (
            Object.entries(this.getIntegrationsConfig())
                .map(([key, value]) => {
                    if (!excludedIntegrations.has(key)) {
                        return {
                            ...value,
                            name: IntegrationType[key.toUpperCase()],
                        };
                    } else {
                        return null;
                    }
                })
                // Remove nulls
                .filter((integration) => integration)
        );
    }

    /**
    * Generate fields for each entity of the integration
    */
    public generateIntegrationFields = function (entitiesMap, projectIntegration, shouldHaveEntityPrefix) {
        const integrationFieldsToAdd: Record<string, any>[] = [];

        // Create field for each integration entity
        if (entitiesMap && entitiesMap.length) {
            for (const entity of entitiesMap) {
                if (projectIntegration.integration) {
                    const entityField = {
                        id: `${projectIntegration.integration.integrationType}_${entity.entity}_EXTERNAL_INTEGRATION`,
                        label: `${entity.label} Field (${projectIntegration.displayName})`,
                        name: `${projectIntegration.integration.integrationType}_${entity.entity}_EXTERNAL_INTEGRATION`,
                        entity: entity.entity,
                        entityDisplayName: entity.label,
                        shouldHaveEntityPrefix,
                        quickCreationField: true,
                        source: projectIntegration.integration.integrationType.toLowerCase(),
                        updateable: true, // Adding flag so this fields will not be filtered out when filtering on only updateable field definitions.
                        canUpdateFromUI: true,
                        groupBy: 'DATA SOURCES',
                        projectIntegrationId: projectIntegration.id,
                    };

                    integrationFieldsToAdd.push(entityField);
                }
            }
        }
        return integrationFieldsToAdd;
    };

    /**
    * Generates fields for each connected integration entities.
    */
    public generateIntegrationCustomFields(
        connectedProjectIntegration: ProjectIntegration,
        remainingIntegrations: ProjectIntegration[],
        shouldHaveEntityPrefix: boolean,
    ) {
        if (!connectedProjectIntegration && (!remainingIntegrations || !remainingIntegrations.length)) {
            return [];
        }
        // A set of the project integration ids we already processed.
        const alreadySeenProjectIntegrationId = {};

        // A collection of project integrations we need to process without duplicated project integrations.
        const uniqueProjectIntegrations: ProjectIntegration[] = [];

        // Creating the unique set of project integrations.
        [connectedProjectIntegration, ...remainingIntegrations]
            .filter((projectIntegration) => projectIntegration && projectIntegration.id)
            .forEach((projectIntegration) => {
                if (!alreadySeenProjectIntegrationId[projectIntegration.id]) {
                    uniqueProjectIntegrations.push(projectIntegration);
                    alreadySeenProjectIntegrationId[projectIntegration.id] = true;
                }
            });

        const entities = this
            .getFieldOptions()
            [connectedProjectIntegration.integration?.integrationType.toLowerCase()].map((entityType) => ({
                entity: entityType,
                label: entityType,
            }));

        // Gathering all the fields we need to display in the quick create.
        return uniqueProjectIntegrations.flatMap((projectIntegration) =>
            this.generateIntegrationFields(entities, projectIntegration, shouldHaveEntityPrefix),
        );
    };

    private invokeFunctionIfNeeded(possibleFunction) {
        if (possibleFunction instanceof Function) {
            return possibleFunction();
        }
        return possibleFunction;
    }
}

angular.module('tonkean.shared').service('integrations', Integrations);
export const integrationsService = new Integrations(undefined);