import SolutionSiteFieldsWidget from './components/SolutionSiteFieldsWidget';
import SolutionSiteFieldsWidgetConfigurationEditor from './components/SolutionSiteFieldsWidgetConfigurationEditor';
import type FieldsItemWidgetConfiguration from './FieldsItemWidgetConfiguration';
import type { SolutionSitePageWidgetPackage } from '../../WidgetModule';

const SolutionSiteFieldsWidgetPackage: SolutionSitePageWidgetPackage<FieldsItemWidgetConfiguration> = {
    displayComponent: SolutionSiteFieldsWidget,
    configurationComponent: SolutionSiteFieldsWidgetConfigurationEditor,
};

export default SolutionSiteFieldsWidgetPackage;
