import template from './tnkSendEmailAttachments.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSendEmailAttachments', {
    bindings: {
        groupId: '<',
        workflowVersionId: '<',
        configuredLogicId: '<',
        validationObject: '<',
        existingAttachmentsConfiguration: '<',
        previewEvaluationSource: '<',

        onAttachmentsChanged: '&',
    },
    template,
    controller: 'SendEmailAttachmentsCtrl',
});
