import React from 'react';

export default class WorkerHistoryItemAction extends React.Component {
    constructor(props) {
        // The constructor of Component must also call the father's constructor passing its props.
        super(props);

        this.state = this.getInitialState();
    }

    toggleActionMetadata() {
        this.setState({ showActionMetadata: !this.state.showActionMetadata });
    }

    getInitialState() {
        return { showActionMetadata: false };
    }

    renderDefault(action) {
        const type = action.customTriggerActionType;
        const logicBlockType = this.props.logicBlockTypes[type];
        let iconClass = logicBlockType.iconClass;
        let integrationClass = null;
        const statusClass = action.completed ? 'mod-success' : 'mod-fail';
        const statusTooltip = action.completed ? 'Success' : 'Fail';
        let title = null;

        if (action.customTriggerActionType === 'POST_ACTION_STATE_UPDATE') {
            title = 'Update Tonkean Track Status';
        } else if (action.customTriggerActionType === 'POST_ACTION_SEND_NOTIFICATION') {
            title = 'Send post action notification';
            iconClass = 'mod-notification';
        } else {
            const publishedVersionId = this.props.workflowVersionManager.getPublishedVersionFromCache(
                this.props.groupId,
            ).id;
            const cachedCustomTrigger = this.props.customTriggerManager.getCachedCustomTrigger(
                publishedVersionId,
                action.customTriggerId,
            );
            title = cachedCustomTrigger ? cachedCustomTrigger.displayName : 'Unknown';
            if (
                action.customTriggerAction &&
                action.customTriggerAction.customTriggerActionDefinition &&
                action.customTriggerAction.customTriggerActionDefinition.integrationType
            ) {
                integrationClass =
                    action.customTriggerAction.customTriggerActionDefinition.integrationType.toLowerCase();
            }
        }

        const customTriggerId = action.customTriggerId;
        return this.renderAction(
            action,
            integrationClass,
            iconClass,
            title,
            statusClass,
            statusTooltip,
            customTriggerId,
        );
    }

    renderNotMetAction(action) {
        const publishedVersionId = this.props.workflowVersionManager.getPublishedVersionFromCache(
            this.props.groupId,
        ).id;
        const cachedCustomTrigger = this.props.customTriggerManager.getCachedCustomTrigger(
            publishedVersionId,
            action.customTriggerId,
        );

        const type = cachedCustomTrigger ? cachedCustomTrigger.customTriggerType : null;
        const logicBlockType = this.props.logicBlockTypes[type];
        const iconClass = logicBlockType ? logicBlockType.iconClass : null;
        let integrationClass = null;
        const statusClass = 'mod-not-met';
        const statusTooltip = 'Condition Not Met';

        const title = cachedCustomTrigger ? cachedCustomTrigger.displayName : 'Unknown';

        if (cachedCustomTrigger && cachedCustomTrigger.integrationType) {
            integrationClass = cachedCustomTrigger.integrationType.toLowerCase();
        }

        const customTriggerId = action.customTriggerId;

        return this.renderAction(
            action,
            integrationClass,
            iconClass,
            title,
            statusClass,
            statusTooltip,
            customTriggerId,
        );
    }

    renderAction(action, integrationClass, iconClass, title, statusClass, statusTooltip, customTriggerId) {
        return (
            <div
                key={`action${action.id}`}
                onClick={this.toggleActionMetadata.bind(this)}
                className="worker-history-action-container common-size-xxxxs padding-normal-sm pointer"
            >
                <div className="flex flex-vmiddle">
                    {/* Action icon */}
                    <div className="tnk-tooltip mod-top margin-right-xs">
                        {integrationClass ? (
                            <span
                                className={`inline-block mod-worker-history initiative-integration-icon mod-${integrationClass}`}
                            />
                        ) : (
                            <div className={`${iconClass} logic-template-icon mod-xxs`} />
                        )}
                    </div>

                    {/* Title */}
                    <div className="flex-grow">{title}</div>

                    {/* Filter By Trigger */}
                    <span
                        className="tnk-tooltip mod-top pointer margin-right"
                        onClick={this.props.filterTrigger.bind(this, { id: customTriggerId, displayName: title })}
                    >
                        <span className="tnk-tooltip-text ">Filter by this action</span>
                        <span className="edit-button filter-icon common-size-xxxxxs">
                            <i className="fa fa-filter" />
                        </span>
                    </span>

                    {/* Status*/}
                    <span className="common-bold tnk-tooltip mod-top">
                        <div className={`worker-status-bullet ${statusClass}`} />

                        <span className="tnk-tooltip-text">{statusTooltip}</span>
                    </span>
                </div>

                {/* Action metadata*/}
                {this.state.showActionMetadata && (
                    <div className="worker-history-action-metadata">
                        <div>Executed at: {new Date(action.endTime).toLocaleString()}</div>
                        {action.friendlyErrorMessage && (
                            <span className="common-color-danger">{action.friendlyErrorMessage}</span>
                        )}
                    </div>
                )}
            </div>
        );
    }

    render() {
        if (!this.props.action.workerRunLogicActions.length) {
            return this.renderNotMetAction(this.props.action, null);
        }

        return this.props.action.workerRunLogicActions.map((action) => {
            if (action.customTriggerActionType) {
                return this.renderDefault(action);
            }
        });
    }
}
