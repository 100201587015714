import React, { useState } from 'react';
import styled from 'styled-components';

import KeyMetricTrendButton from './KeyMetricTrendButton';
import { ReactComponent as ArrowDownGreenIcon } from '../../../../../../../../images/icons/arrow-down-green.svg';
import { ReactComponent as ArrowDownRedRedIcon } from '../../../../../../../../images/icons/arrow-down-red.svg';
import { ReactComponent as ArrowUpGreenIcon } from '../../../../../../../../images/icons/arrow-up-green.svg';
import { ReactComponent as ArrowUpRedIcon } from '../../../../../../../../images/icons/arrow-up-red.svg';
import { ReactComponent as TrashNewIcon } from '../../../../../../../../images/icons/trash-new.svg';

import { Field, Input, Popover, useFormikField } from '@tonkean/infrastructure';
import {
    MarketPlaceItemKeyMetric,
    MarketplaceItemKeyMetricTrendType,
    marketplaceItemKeyMetricTrendTypeToColor,
} from '@tonkean/tonkean-entities';
import marketplaceItemKeyMetricTrendTypeToIcon from '@tonkean/tonkean-entities/marketplaceItemKeyMetricTrendTypeToIcon';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Metric = styled.div`
    display: grid;
    grid-template-columns: 1fr 26px 1.5fr 28px;
    grid-gap: 5px;
    align-items: center;
    margin-top: 24px;
`;

const StyledInput = styled(Input)`
    width: 100%;
`;

const ArrowIconButton = styled(IconButton)`
    height: 26px;
    width: 26px;
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 4px;
`;

const TrendIconsPopoverContentRow = styled.div`
    display: flex;
`;

interface Props {
    onRemove: () => void;
    index: number;
}

const MarketplaceItemCreateModalSingleKeyMetric: React.FC<Props> = ({ onRemove, index }) => {
    const [trendArrowsOpen, setTrendArrowsOpen] = useState<boolean>(false);

    const { value: keyMetric, setValue: setKeyMetric } = useFormikField<MarketPlaceItemKeyMetric>(
        `configuration.keyMetricsConfig.keyMetrics[${index}]`,
    );

    const onTrendClicked = (trendType: MarketplaceItemKeyMetricTrendType) => {
        setTrendArrowsOpen(false);
        setKeyMetric({ ...keyMetric, trendType });
    };

    return (
        <Metric>
            <Field>
                <StyledInput
                    data-automation="marketplace-item-create-modal-single-key-metric-metric-title"
                    name={`configuration.keyMetricsConfig.keyMetrics.${index}.metric`}
                    size={InputSize.MEDIUM}
                    autoComplete="off"
                    placeholder="Metric"
                />
            </Field>
            <Popover
                onClose={() => setTrendArrowsOpen(false)}
                show={trendArrowsOpen}
                placement="bottom"
                content={
                    <>
                        <TrendIconsPopoverContentRow>
                            <KeyMetricTrendButton
                                trendType={MarketplaceItemKeyMetricTrendType.GREEN_UP}
                                onTrendClicked={onTrendClicked}
                            >
                                <ArrowUpGreenIcon />
                            </KeyMetricTrendButton>

                            <KeyMetricTrendButton
                                trendType={MarketplaceItemKeyMetricTrendType.GREEN_DOWN}
                                onTrendClicked={onTrendClicked}
                            >
                                <ArrowDownGreenIcon />
                            </KeyMetricTrendButton>
                        </TrendIconsPopoverContentRow>

                        <TrendIconsPopoverContentRow>
                            <KeyMetricTrendButton
                                trendType={MarketplaceItemKeyMetricTrendType.RED_UP}
                                onTrendClicked={onTrendClicked}
                            >
                                <ArrowUpRedIcon />
                            </KeyMetricTrendButton>

                            <KeyMetricTrendButton
                                trendType={MarketplaceItemKeyMetricTrendType.RED_DOWN}
                                onTrendClicked={onTrendClicked}
                            >
                                <ArrowDownRedRedIcon />
                            </KeyMetricTrendButton>
                        </TrendIconsPopoverContentRow>
                    </>
                }
            >
                <ArrowIconButton
                    onClick={() => setTrendArrowsOpen(true)}
                    iconColor={marketplaceItemKeyMetricTrendTypeToColor[keyMetric.trendType]}
                    flat
                >
                    {marketplaceItemKeyMetricTrendTypeToIcon[keyMetric.trendType]}
                </ArrowIconButton>
            </Popover>

            <Field>
                <StyledInput
                    data-automation="marketplace-item-create-modal-single-key-metric-metric-sub-title"
                    name={`configuration.keyMetricsConfig.keyMetrics.${index}.subTitle`}
                    size={InputSize.MEDIUM}
                    autoComplete="off"
                    placeholder="Subtitle"
                />
            </Field>
            <IconButton
                data-automation="marketplace-item-create-modal-single-key-metric-metric-delete-icon"
                onClick={onRemove}
            >
                <TrashNewIcon />
            </IconButton>
        </Metric>
    );
};

export default MarketplaceItemCreateModalSingleKeyMetric;
