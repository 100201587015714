import template from './tnkExtendedMatchConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkExtendedMatchConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        logicId: '@',
        entityMetadata: '<',
        entitiesMetadata: '<',
        validationObject: '<', // Validation object on which to check if the match is valid
        existingExtendedMatchConfiguration: '<',
        previewEvaluationSource: '<',
        projectIntegration: '<',
        modFixedWidth: '<',
        tabsFieldSelectorModNarrow: '<',
        onExtendedMatchConfigurationChanged: '&',
        currentCustomTrigger: '<',
        entities: '<', // An array of strings that contains the entities we allow in the extended matching.
    },
    template,
    controller: 'ExtendedMatchConfigurationCtrl',
});
