import { DeprecatedDate } from '@tonkean/utils';
import { convertPersonToPersonSummary } from '@tonkean/tonkean-entities';

/**
 * Holds the cache of people in Tonkean. Note not all places use this cache yet, to avoid a huge refactor.
 * The use of this service will grow in the future.
 */
function PersonCache($q, utils, entityPersonHelper, tonkeanService, projectManager, $log) {
    const _this = this;

    // Single item time to leave threshold in milliseconds.
    const singleItemTtl = 60_000;

    // Initiatives cache.
    let cacheItemMap = {};
    let cacheItemMapByEmail = {};
    let cacheTtl = {};
    const everLastingItemsSet = {};

    const currentlyFetchingById = {};
    const currentlyFetchingByEmail = {};

    let nextTickTimerByEmail = null;
    let nextTickItemsByEmail = [];

    _this.cacheItemMap = cacheItemMap;
    _this.cacheItemMapByEmail = cacheItemMapByEmail;

    /**
     * Clears the cacheItemMap and cacheTtl.
     */
    _this.clearCaches = function () {
        cacheItemMap = {};
        cacheItemMapByEmail = {};
        cacheTtl = {};
    };

    /**
     * Gets the cacheItemMap, a map between entity id and the entity.
     */
    _this.getEntityCache = function () {
        return cacheItemMap;
    };

    /**
     * Returns true if given entity id is in our cache. False otherwise.
     */
    _this.cacheHasEntity = function (entityId) {
        return !!cacheItemMap[entityId];
    };

    /**
     * Returns true if given entity email is in our cache. False otherwise.
     */
    _this.cacheHasEntityByEmail = function (entityEmail) {
        return !!cacheItemMapByEmail[entityEmail];
    };

    /**
     * Returns the entity with id of entity id from the cache.
     * @param entityId The entity we'd like to fetch from the cache.
     */
    _this.getEntityFromCache = function (entityId) {
        return cacheItemMap[entityId];
    };

    /**
     * Returns the entity of entity email from the cache.
     * @param entityEmail Entity's email we'd like to fetch from the cache.
     */
    _this.getEntityFromCacheByEmail = function (entityEmail) {
        return cacheItemMapByEmail[entityEmail];
    };

    /**
     * Runs getEntitiesByEmail Facebook's dataloader style - it will collect all requests in a single execution cycle,
     * and send a single request. The request looks for the items in the cache first, and only if needed will send
     * an http requests.
     * @param entityEmails - list of emails
     * @returns Promise<Person[]>
     */
    _this.getEntitiesByEmailDataLoader = (entityEmails) => {
        if (!nextTickTimerByEmail) {
            nextTickItemsByEmail = [];
            nextTickTimerByEmail = new Promise((resolve) => setTimeout(() => resolve(), 50)).then(() => {
                nextTickTimerByEmail = null;
                return _this.getEntitiesByEmails(
                    nextTickItemsByEmail.filter((currentEmail, index, array) => array.indexOf(currentEmail) === index),
                );
            });
        }

        nextTickItemsByEmail = [...nextTickItemsByEmail, ...entityEmails];
        return nextTickTimerByEmail.then(() =>
            entityEmails
                .filter((email) => _this.cacheHasEntityByEmail(email))
                .map((email) => _this.getEntityFromCacheByEmail(email)),
        );
    };

    /**
     * Gets an entity by id.
     * If forceServer is true, will fetch it from the server regardless of its existence in the cache.
     * Otherwise, will try to fetch it from cache and if it does not exist there, will fetch it from the server.
     * @param entityId The entity we'd like to fetch from the cache.
     * @param forceServer Indicates whether to fetch entity from server regardless of existence in cache.
     * @param doNotMarkTtl If set to true, will not set TTL to the item.
     */
    _this.getEntityById = function (entityId, forceServer, doNotMarkTtl) {
        // If we didn't get an entity id, resolve with null cause it's not in the cache...
        if (!entityId) {
            return $q.resolve();
        }

        // If currently fetching a user, no need to make another http request - simply return the other promise
        // which will be resolved with the user. If forceServer set to true, it will do a real fetch
        if (!forceServer && currentlyFetchingById[entityId]) {
            return currentlyFetchingById[entityId];
        }

        const shouldFetchFromServer = forceServer || _this.shouldInvalidate(entityId);

        let retrieveEntityPromise = $q.resolve();

        if (shouldFetchFromServer) {
            // Fetching from server, and then caching the retrieved entity and returning it.
            retrieveEntityPromise = tonkeanService
                .getProjectPersonById(projectManager.project.id, entityId)
                .then((person) => {
                    _this.cacheEntity(person, doNotMarkTtl);
                    return $q.resolve(person);
                });

            currentlyFetchingById[entityId] = retrieveEntityPromise
                .then((retrievedEntity) => {
                    delete currentlyFetchingById[entityId];
                    return retrievedEntity;
                })
                .catch((error) => {
                    delete currentlyFetchingById[entityId];
                    return Promise.reject(error);
                });
        } else {
            // Returning the entity from cache.
            retrieveEntityPromise = $q.resolve(_this.getEntityFromCache(entityId));
        }

        return retrieveEntityPromise;
    };

    /**
     * Gets an entity by email.
     * If forceServer is true, will fetch it from the server regardless of its existence in the cache.
     * Otherwise, will try to fetch it from cache and if it does not exist there, will fetch it from the server.
     * @param entityId The entity we'd like to fetch from the cache.
     * @param forceServer Indicates whether to fetch entity from server regardless of existence in cache.
     * @param doNotMarkTtl If set to true, will not set TTL to the item.
     */
    _this.getEntityByEmail = function (entityEmail, forceServer, doNotMarkTtl) {
        // If we didn't get an entity id, resolve with null cause it's not in the cache...
        if (!entityEmail) {
            return $q.resolve();
        }

        // If currently fetching a user, no need to make another http request - simply return the other promise
        // which will be resolved with the user. If forceServer set to true, it will do a real fetch
        if (!forceServer && currentlyFetchingByEmail[entityEmail]) {
            return currentlyFetchingByEmail[entityEmail];
        }

        const existingEntity = _this.getEntityFromCacheByEmail(entityEmail);
        const shouldFetchFromServer = forceServer || !existingEntity || _this.shouldInvalidate(existingEntity.id);

        let retrieveEntityPromise = $q.resolve();

        if (shouldFetchFromServer) {
            // Fetching from server, and then caching the retrieved entity and returning it.
            retrieveEntityPromise = tonkeanService
                .getProjectPeopleByEmails(projectManager.project.id, [entityEmail])
                .then((person) => {
                    _this.cacheEntity(person?.entities?.[0], doNotMarkTtl);
                    return $q.resolve(person?.entities?.[0]);
                });

            currentlyFetchingByEmail[entityEmail] = retrieveEntityPromise
                .then((m) => {
                    delete currentlyFetchingByEmail[entityEmail];
                    return m;
                })
                .catch((error) => {
                    delete currentlyFetchingByEmail[entityEmail];
                    return Promise.reject(error);
                });
        } else {
            // Returning the entity from cache.
            retrieveEntityPromise = $q.resolve(existingEntity);
        }

        return retrieveEntityPromise;
    };

    /**
     * Gets project people by ids from server and caches them.
     */
    _this.getEntitiesByIds = function (entityIds) {
        const alreadyFetching = {};
        entityIds.forEach((id) => {
            const promise = currentlyFetchingById[id];
            if (promise) {
                alreadyFetching[id] = promise;
            }
        });
        const idsToFetch = entityIds.filter((id) => !alreadyFetching[id] && !_this.cacheHasEntity(id));

        let fetchPeoplePromise = $q.resolve();
        if (idsToFetch && idsToFetch.length) {
            fetchPeoplePromise = tonkeanService
                .getProjectPeopleByIds(projectManager.project.id, idsToFetch)
                .then((data) => {
                    _this.cacheEntities(data.entities);
                });
            idsToFetch.forEach((id) => {
                currentlyFetchingById[id] = fetchPeoplePromise
                    .then((m) => {
                        delete currentlyFetchingById[id];
                        return m;
                    })
                    .catch((error) => {
                        delete currentlyFetchingById[id];
                        return Promise.reject(error);
                    })
                    .then(() => _this.getEntityFromCache(id));
            });
        }

        return Promise.all([fetchPeoplePromise, ...Object.values(alreadyFetching)]).then(() =>
            $q.resolve(entityIds.filter((id) => _this.cacheHasEntity(id)).map((id) => _this.getEntityFromCache(id))),
        );
    };

    /**
     * Gets project people by emails from server and caches them.
     */
    _this.getEntitiesByEmails = function (entityEmails) {
        const alreadyFetching = {};
        entityEmails.forEach((email) => {
            const promise = currentlyFetchingByEmail[email];
            if (promise) {
                alreadyFetching[email] = promise;
            }
        });
        const emailsToFetch = entityEmails.filter(
            (email) => !alreadyFetching[email] && !_this.cacheHasEntityByEmail(email),
        );

        let fetchPeoplePromise = $q.resolve();
        if (emailsToFetch && emailsToFetch.length) {
            fetchPeoplePromise = tonkeanService
                .getProjectPeopleByEmails(projectManager.project.id, emailsToFetch)
                .then((data) => {
                    _this.cacheEntities(data.entities);
                });
            emailsToFetch.forEach((email) => {
                currentlyFetchingByEmail[email] = fetchPeoplePromise
                    .then((m) => {
                        delete currentlyFetchingByEmail[email];
                        return m;
                    })
                    .catch((error) => {
                        delete currentlyFetchingByEmail[email];
                        return Promise.reject(error);
                    })
                    .then(() => _this.getEntityFromCacheByEmail(email));
            });
        }

        return Promise.all([fetchPeoplePromise, ...Object.values(alreadyFetching)]).then(() =>
            $q.resolve(
                entityEmails
                    .filter((email) => _this.cacheHasEntityByEmail(email))
                    .map((email) => _this.getEntityFromCacheByEmail(email)),
            ),
        );
    };

    /**
     * Gets project people by personId or email. this function only support one type of value (personId or email)
     */
    _this.getEntitiesByEmailOrIds = function (entityEmailsOrIds) {
        const isValuePerson = (entityEmailOrId) => {
            return typeof entityEmailOrId === 'string' ? entityEmailOrId?.includes('PRSN') : false;
        };
        if (!entityEmailsOrIds || entityEmailsOrIds.length === 0) {
            return [];
        }
        const firstValueIsPerson = isValuePerson(entityEmailsOrIds[0]);
        const allEntitiesTheSameType = entityEmailsOrIds.every(
            (entity) => isValuePerson(entity) === firstValueIsPerson,
        );
        if (!allEntitiesTheSameType) {
            $log.error('Mixed values of person ids and emails are not supported');
        }
        if (firstValueIsPerson) {
            return _this
                .getEntitiesByIds(entityEmailsOrIds)
                .then((entities) => entities.map((person) => convertPersonToPersonSummary(person)));
        } else {
            return _this
                .getEntitiesByEmails(entityEmailsOrIds)
                .then((entities) => entities.map((person) => convertPersonToPersonSummary(person)));
        }
    };

    /**
     * Returns the entities with given ids of entityIds from the cache, if they exist.
     */
    _this.getEntitiesFromCache = function (entityIds) {
        if (!entityIds || !entityIds.length) {
            return null;
        }

        return entityIds
            .filter((entityId) => _this.cacheHasEntity(entityId))
            .map((entityId) => _this.getEntityFromCache(entityId));
    };

    /**
     * Returns the entities with given emails of entities from the cache, if they exist.
     */
    _this.getEntitiesFromCacheByEmail = function (entityEmails) {
        if (!entityEmails || !entityEmails.length) {
            return null;
        }

        return entityEmails
            .filter((entityEmail) => _this.cacheHasEntityByEmail(entityEmail))
            .map((entityEmail) => _this.getEntityFromCacheByEmail(entityEmail));
    };

    /**
     * Removes given entity id from the cache.
     */
    _this.removeEntityFromCache = function (entityId) {
        if (!entityId || !_this.cacheHasEntity(entityId)) {
            return;
        }

        delete cacheItemMapByEmail[cacheItemMap[entityId]];
        delete cacheItemMap[entityId];
        delete cacheTtl[entityId];
    };

    /**
     * Caches the given entity.
     * @param entity The entity we'd like to cache.
     * @param doNotMarkTtl If set to true, will not set TTL to the item.
     */
    _this.cacheEntity = function (entity, doNotMarkTtl) {
        if (!entity) {
            return entity;
        }

        // Trying to retrieve item from cache.
        const cached = cacheItemMap[entity.id];

        if (!cached) {
            // If entity isn't cached yet, we just insert it into the cache.
            cacheItemMap[entity.id] = entity;

            if (!doNotMarkTtl) {
                // If we are not asked to not mark TTL, we mark TTL for the item.
                cacheTtl[entity.id] = new Date();
            } else {
                // Otherwise, we are asked to not mark TTL, meaning it's an ever lasting item.
                everLastingItemsSet[entity.id] = true;
            }
        } else {
            // Otherwise, entity is already cached, we only update its properties.
            utils.copyEntityFields(entity, cached);
        }

        // Enriching the freshly cached entity.
        cacheItemMap[entity.id] = entityPersonHelper.enrichEntity(cacheItemMap[entity.id]);
        cacheItemMapByEmail[entity.email] = entityPersonHelper.enrichEntity(cacheItemMap[entity.id]);

        return cacheItemMap[entity.id];
    };

    /**
     * Caches the given entities in the cacheItemMap.
     * @param entities The entities we'd like to cache.
     * @param doNotMarkTtl If set to true, will not set TTL to the item.
     */
    _this.cacheEntities = function (entities, doNotMarkTtl) {
        if (!entities || !entities.length) {
            return entities;
        }

        for (const entity of entities) {
            _this.cacheEntity(entity, doNotMarkTtl);
        }

        return entities
            .filter((entity) => _this.cacheHasEntity(entity.id))
            .map((entity) => _this.getEntityFromCache(entity.id));
    };

    /**
     * Returns true if we should invalidate the entity in cache. False otherwise.
     */
    _this.shouldInvalidate = function (entityId) {
        // If it's an ever lasting item in the cache, we never need invalidating.
        if (everLastingItemsSet[entityId]) {
            return false;
        }

        // Otherwise, if the item is not in the cacheTtl, we should invalidate it.
        if (!cacheTtl[entityId]) {
            return true;
        }

        const now = DeprecatedDate.nowAsDate();
        const cacheTime = cacheTtl[entityId];
        const diff = now - cacheTime;

        return diff >= singleItemTtl;
    };
}

angular.module('tonkean.app').service('personCache', PersonCache);
