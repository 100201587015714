<!-- Go to board button (when there are initiatives for the user) -->
<a
    ng-if="!data.noInitiatives && !pm.isExpired && ((pm.isLicensed && pm.isUserLicensed) || !pm.isLicensed) && !noMenus"
    ng-click="licenseManager.goToBoard()"
    class="btn btn-primary margin-top margin-right absolute-right"
>
    Go to board
</a>

<!-- Request access button (only for not licensed users) -->
<div class="margin-top margin-right absolute-right" ng-if="!pm.isExpired && pm.isLicensed && !pm.isUserLicensed">
    <button
        type="button"
        class="btn btn-primary"
        ng-if="!licenseManager.states.requestForAccessSent"
        ng-click="licenseManager.goToBoardGrabLicense()"
        ng-disabled="licenseManager.states.askingForAccess"
        analytics-on
        analytics-category="Request full access"
        analytics-label="MainNav"
    >
        <i class="margin-right-xs" ng-class="{ loading: licenseManager.states.askingForAccess }"></i>
        Go to board
    </button>
    <div class="btn btn-success disabled" ng-if="licenseManager.states.requestForAccessSent">
        Request for full access sent
    </div>
</div>

<div class="flex update-initiative-page" ng-if="!data.loading">
    <tnk-initiatives-sidebar
        ng-if="!noMenus && !hideSidebar"
        on-initiatives-loaded="onInitiativesLoaded(initiatives)"
        on-initiative-selected="onInitiativeSelected(initiativeId)"
        is-forms="false"
    ></tnk-initiatives-sidebar>

    <!-- Initiative data -->
    <div class="flex-grow flex-shrink padding-normal-h margin-top">
        <!-- No initiatives and no specific initiative was requested -->
        <div
            class="flex-vmiddle mod-center margin-auto text-center common-size-xs common-color-grey"
            ng-if="data.noInitiatives || data.noActiveInitiatives"
        >
            <div class="margin-top-xlg padding-top-xlg text-center">
                <div class="svg-icon tonkean-inline-logo mod-static relative common-color-black">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/tonkean-logo.svg"></tnk-icon>
                </div>
                <div class="common-title-md mod-light text-center margin-top">Your item box is empty</div>
                <p class="common-color-black common-size-xxs margin-top-lg">
                    Here you'll be able to see and update items you own.
                    <br />
                    You're currently not set as an owner of any active items.
                </p>
                <div ng-if="pm.isLicensed || !pm.project.isLimitedLicense">
                    <p class="common-color-black common-size-xxs margin-bottom-lg">
                        Click "Go to board" to view the rest of
                        <strong>{{ pm.project.name }}</strong>
                        dashboards, lists and items.
                    </p>
                    <!-- Go to board button (when there aren't any initiatives for the user) -->
                    <button
                        type="button"
                        class="btn btn-primary btn-lg"
                        ng-click="licenseManager.goToBoard()"
                        ng-if="!licenseManager.states.requestForAccessSent"
                    >
                        Go to board
                    </button>
                    <div class="btn btn-success disabled" ng-if="licenseManager.states.requestForAccessSent">
                        Request for full access sent
                    </div>
                </div>
                <!-- Messages for when in Free but user is not licensed. -->
                <div ng-if="pm.isLimitedLicense && !pm.isUserLicensed">
                    <div class="info-box common-size-xxs margin-top-lg">
                        <div class="common-color-dark-grey">
                            <strong>{{ pm.project.name }}</strong>
                            is on the FREE (limited) tier. Only {{ pm.project.creator.name }} have full access.
                            <br />
                            To add more seats
                            <a ui-sref="product.settings.license({tab: 'upgrade'})">Upgrade your account</a>
                            .
                        </div>
                    </div>
                    <div class="margin-top-xlg text-center common-color-dark-grey common-size-xxs">
                        If you don't work with {{ pm.project.creator.name }} you can
                        <a ui-sref="products">create a new Board here</a>
                        .
                    </div>
                </div>
                <!-- Message for when in Free and user IS licensed. -->
                <div ng-if="pm.isLimitedLicense && pm.isUserLicensed" class="margin-top-xlg">
                    <button
                        type="button"
                        class="btn btn-primary btn-lg"
                        ng-click="licenseManager.goToBoard()"
                        ng-if="!licenseManager.states.requestForAccessSent"
                    >
                        Go to board
                    </button>
                </div>
            </div>
        </div>

        <div class="item-interface">
            <tnk-system-interface initiative-id="sp.id" show-breadcrumbs="false"></tnk-system-interface>
        </div>
    </div>
</div>
