import template from './recurrenceTimeSelection.template.html?angularjs';

/**
 * Component to select fields of integrations.
 */
export default angular.module('tonkean.app').component('tnkRecurrenceTimeSelection', {
    bindings: {
        existingRecurrenceTimeSelection: '<',
        onRecurrenceTimeSelectionChanged: '&',
        hideTimezoneIfNotConfigured: '<',
        validationObject: '<',
        minEveryXMinutesInterval: '<',
    },
    template,
    controller: 'RecurrenceTimeSelectionCtrl',
});
