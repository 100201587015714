import template from './tnkSelectIntegrationInstance.template.html?angularjs';

/**
 * A wrapping directive for tnkSelectIntegrationInstancePopover.
 */
function tnkSelectIntegrationInstance() {
    return {
        restrict: 'E',
        scope: {
            placement: '@',
            integrationType: '<',
            projectIntegrationOptions: '<',
            onIntegrationSelected: '&',
            selectedProjectIntegration: '<',
        },
        transclude: true,
        template,
        controller: SelectIntegrationInstanceCtrl,
    };
}
export const tnkSelectIntegrationInstanceDirective = angular.module('tonkean.app').directive('tnkSelectIntegrationInstance', tnkSelectIntegrationInstance);

function SelectIntegrationInstanceCtrl($scope) {
    $scope.data = {
        fakeIntegrationState: {},
        dropdownIsOpen: false,
    };

    $scope.closeDropdown = () => {
        $scope.data.dropdownIsOpen = false;
    };

    $scope.selectIntegration = function (selectedIntegration) {
        $scope.onIntegrationSelected({ selectedIntegration });
    };
}
export const SelectIntegrationInstanceCtrlController = angular.module('tonkean.app').controller('SelectIntegrationInstanceCtrl', SelectIntegrationInstanceCtrl);
