import { analyticsWrapper } from '@tonkean/analytics';
import lateConstructController from '../../utils/lateConstructController';
import {
    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP,
    getFormulaSpecialFieldIdToDefinitionMap,
} from '@tonkean/tonkean-utils';

/* @ngInject */
function UpdateFieldsFormCtrl(
    $scope,
    $timeout,
    $q,
    $rootScope,
    trackHelper,
    utils,
    groupInfoManager,
    customFieldsManager,
    workflowVersionManager,
    requestThrottler,
    projectManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        fields: ctrl.fields,
        workflowVersionId: ctrl.workflowVersionId,
        groupId: ctrl.groupId,
        formConfig: ctrl.formConfig,
        initiativeId: ctrl.initiativeId,
        workerRunId: ctrl.workerRunId,
        onSubmit: ctrl.onSubmit,
        onError: ctrl.onError,
        customTriggerId: ctrl.customTriggerId,
        sessionId: ctrl.sessionId,
        formFinished: ctrl.formFinished,
        validationMap: ctrl.validationMap || {},
        primaryColor: ctrl.primaryColor,
        secondaryColor: ctrl.secondaryColor,
        buttonsColor: ctrl.buttonsColor,
        workflowVersionType: ctrl.workflowVersionType,
        prepopulatedValues: ctrl.prepopulatedValues,

        fieldDefinitionIdToExistingFieldInstanceMap: {},
        fieldDefinitionsMap: {},
        specialFieldIdToExistingValueMap: {},
        specialFieldIdToValueMap: {},
        specialFieldsMap: getFormulaSpecialFieldIdToDefinitionMap(),
        fieldIdToValueMap: {},
        initiative: {},
        savingInitiative: false,
        errorSavingInitiative: false,
        allErrorsNotRestrictingErrors: false,
        fieldToErrorMessageMap: {},
        group: ctrl.group,
        environmentIsActive: true,
        isPrevious: ctrl.isPrevious,
        showBack: ctrl.showBack,
        backLoading: false,
        workflowVersion: undefined,
    };

    ctrl.$onInit = function () {
        const groupPromise =
            $scope.data.group && $scope.data.group.id === $scope.data.groupId
                ? $q.resolve($scope.data.group)
                : groupInfoManager.getGroup($scope.data.groupId, true, false);
        $scope.data.workflowVersion = workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId);
        const formLoadedPromise = loadForm();

        $q.all([groupPromise, formLoadedPromise]).then(([group, _]) => {
            $scope.data.group = group;

            $scope.data.environmentIsActive =
                $scope.data.workflowVersionType === 'DRAFT' || $scope.data.workflowVersionType === 'draft'
                    ? group.buildEnvironmentEnabled
                    : group.workerEnabled;

            $scope.data.fieldDefinitionsMap = utils.createMapFromArray(
                customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                'id',
            );
            finishFormLoading();
            const pageLoadTimestamp = $rootScope.pageLoadTime;
            const now = Date.now();

            // We want to make sure we only log this event once
            if ($rootScope.formLoadEventSubmitted) return;

            analyticsWrapper.track('form-loaded', {
                formId: $scope.data.formConfig.id,
                groupId: $scope.data.formConfig.group.id,
                formLoadTime: now - pageLoadTimestamp,
                formType: $scope.data.formConfig.formType,
                formQuestionType: $scope.data.formConfig.formQuestionType,
            });

            $rootScope.formLoadEventSubmitted = true;
        });
    };

    ctrl.$onChanges = function (changesObj) {
        [
            'initiatives',
            'formQuestion',
            'workflowVersionId',
            'groupId',
            'customTriggerId',
            'initiativeId',
            'workerRunId',
            'onSubmit',
            'onError',
            'sessionId',
            'formFinished',
            'allErrorsNotRestrictingErrors',
        ].forEach((variable) => {
            if (changesObj[variable]) {
                $scope.data[variable] = changesObj[variable].currentValue;
            }
        });

        if (changesObj.fields) {
            $scope.data.fields = changesObj.fields.currentValue;
            calculateInlineGroups();
        }

        if (changesObj.formConfig) {
            $scope.data.formConfig = changesObj.formConfig.currentValue;
            loadForm();
        }

        if (changesObj.validationMap) {
            $scope.data.validationMap = ctrl.validationMap || {};
        }
    };

    /**
     * Occurs once a custom field value is changed.
     */
    $scope.onCustomFieldValueChanged = function (changeValue, fieldId) {
        $scope.data.fieldIdToValueMap[fieldId] = changeValue;

        validateField(ctrl.formConfig.definition.fields.find((field) => field.fieldDefinitionIdentifier === fieldId));

        $scope.autoSubmitForm();
    };

    /**
     * Occurs once a special field value is changed.
     */
    $scope.onSpecialFieldValueChanged = function (changeValue, fieldId) {
        $scope.data.specialFieldIdToValueMap[fieldId] = changeValue;

        validateField(ctrl.formConfig.definition.fields.find((field) => field.fieldDefinitionIdentifier === fieldId));

        $scope.autoSubmitForm();
    };

    $scope.autoSubmitForm = function () {
        const shouldAutoSubmitForm =
            $scope.data.formConfig.definition.autoSubmitForm &&
            $rootScope.features[$scope.pm.project.id].tonkean_feature_auto_submit_form;

        if (shouldAutoSubmitForm) {
            requestThrottler.do('autoSubmitForm', 350, () => $scope.submitForm());
        }
    };

    $scope.handleBackClicked = function () {
        $scope.data.allErrorsNotRestrictingErrors = false;
        $scope.data.backLoading = true;
        ctrl.onBackClicked();
    };

    /**
     * Submits the filled form (and creates an initiative).
     */
    $scope.submitForm = function (ignoreNonRestrictingErrors = false) {
        if (!areAllFieldsValid()) {
            return $q.resolve();
        }

        startSaveFormLoading();

        // Call parent callback
        return $scope.data
            .onSubmit({
                fields: buildFieldsFromMapping(),
                ignoreNonRestrictingErrors,
            })
            .then(() => {
                $scope.data.formFinished = true;
            })
            .catch((error) => {
                if (error !== 'cancelButton' && !error?.data?.error?.data?.formValidationError) {
                    // Call parent error callback
                    $scope.data.onError({ errorMessage: getStateError(error) });
                }
                finishSaveFormLoading();
            })
            .finally(() => {
                finishSaveFormLoading();
            });
    };

    /**
     * Loads the edited form.
     */
    function loadForm() {
        startFormLoading();

        if ($scope.data.formConfig) {
            return handleConfig($scope.data.formConfig).then(() => {
                calculateInlineGroups();
            });
        }

        return $q.resolve();
    }

    /**
     * This function extracts the relevant information from the config and builds the form according to it
     * @param form - the given form configuration
     * @returns {*} - A promise for finishing building the form
     */
    function handleConfig(form) {
        // If we received initiative id.
        if ($scope.data.initiativeId && (form.formType === 'UPDATE' || $scope.data.isPrevious)) {
            // We only care for filling out the initiative fields if it's an update form or any form that's not the one to be filled.
            return trackHelper.getInitiativeById($scope.data.initiativeId, true).then((initiative) => {
                $scope.data.initiative = initiative;
                $scope.data.specialFieldIdToExistingValueMap = {};
                $scope.data.fieldDefinitionIdToExistingFieldInstanceMap = {};
                form.definition.fields.forEach((field) => buildMapsFromField(field));
            });
        } else {
            const prepopulatedValues = { ...form?.prepopulatedValues, ...$scope.data.prepopulatedValues };
            if (prepopulatedValues) {
                Object.entries(prepopulatedValues).forEach((entry) => {
                    const key = entry[0];
                    const value = entry[1];
                    if ($scope.data.specialFieldsMap[key]) {
                        $scope.data.specialFieldIdToValueMap[key] = {
                            value,
                        };
                        $scope.data.specialFieldIdToExistingValueMap[key] = {
                            value,
                        };
                    } else {
                        $scope.data.fieldIdToValueMap[key] = value;
                        $scope.data.fieldDefinitionIdToExistingFieldInstanceMap[key] = {
                            id: key,
                            value,
                        };
                    }
                });
            }
            return $q.resolve();
        }
    }

    /**
     * This function take the given fields to update/create and build maps for filling the form
     * @param formField
     */
    function buildMapsFromField(formField) {
        if (formField && formField.fieldDefinitionIdentifier) {
            if ($scope.data.specialFieldsMap[formField.fieldDefinitionIdentifier]) {
                // If it's a special field.
                if (
                    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[formField.fieldDefinitionIdentifier] &&
                    FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[formField.fieldDefinitionIdentifier]
                        .extractValueFromInitiative
                ) {
                    const existingSpecialFieldValue = FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[
                        formField.fieldDefinitionIdentifier
                    ].extractValueFromInitiative($scope.data.initiative, $scope.data.workflowVersion);
                    if (existingSpecialFieldValue) {
                        $scope.data.specialFieldIdToExistingValueMap[formField.fieldDefinitionIdentifier] = {
                            value: existingSpecialFieldValue,
                        };
                    }
                }
            } else {
                // Otherwise, it's a field definition.
                if ($scope.data.initiative.fields && $scope.data.initiative.fields.length) {
                    const existingField = utils.findFirst(
                        $scope.data.initiative.fields,
                        (field) =>
                            field &&
                            field.fieldDefinition &&
                            field.fieldDefinition.id === formField.fieldDefinitionIdentifier,
                    );
                    if (existingField) {
                        $scope.data.fieldDefinitionIdToExistingFieldInstanceMap[formField.fieldDefinitionIdentifier] =
                            existingField;
                    }
                }
            }
        }
    }

    /**
     * This function take the maps of the filled form and generates the updated/created fields to send to the server
     */
    function buildFieldsFromMapping() {
        const supportedSpecialFieldsSet = utils.arrayToSet([
            'TNK_TITLE',
            'TNK_STAGE',
            'TNK_DUE_DATE',
            'TNK_TAGS',
            'TNK_OWNER_ID',
            'TNK_DESCRIPTION',
            'TNK_ETA',
            'TNK_UPDATE_TEXT',
        ]);

        const fields = [];

        // Adding special fields to the fields array.
        for (const specialFieldId in $scope.data.specialFieldIdToValueMap) {
            if (
                $scope.data.specialFieldIdToValueMap.hasOwnProperty(specialFieldId) &&
                supportedSpecialFieldsSet[specialFieldId]
            ) {
                fields.push({
                    isSpecialField: true,
                    specialFieldId,
                    fieldDefinitionId: null,
                    valueObject: $scope.data.specialFieldIdToValueMap[specialFieldId],
                });
            }
        }

        // Adding field definitions to the fields array.
        for (const fieldDefinitionId in $scope.data.fieldIdToValueMap) {
            if ($scope.data.fieldIdToValueMap.hasOwnProperty(fieldDefinitionId)) {
                const valueObject = $scope.data.fieldDefinitionsMap[fieldDefinitionId]?.isMultiValueField
                    ? { values: $scope.data.fieldIdToValueMap[fieldDefinitionId] }
                    : { value: $scope.data.fieldIdToValueMap[fieldDefinitionId] };

                fields.push({
                    valueObject,
                    isSpecialField: false,
                    specialFieldId: null,
                    fieldDefinitionId,
                });
            }
        }
        return fields;
    }

    /**
     * Calculates the inline groups for display.
     */
    function calculateInlineGroups() {
        const inlineGroups = [];
        let currentInlineGroup = [];

        if ($scope.data.fields) {
            for (let i = 0; i < $scope.data.fields.length; i++) {
                const formField = $scope.data.fields[i];

                if (!formField.isInline) {
                    // If current field is not inline, we break current inline group and add it to the inline groups.
                    if (currentInlineGroup.length) {
                        inlineGroups.push(currentInlineGroup);
                        currentInlineGroup = [];
                    }

                    // We add current field as inline group of itself.
                    inlineGroups.push([formField]);
                } else {
                    // Otherwise (it is inline), we add it to current inline group).
                    currentInlineGroup.push(formField);
                }
            }

            // If we're done iterating and are left with an inline group, we add it.
            if (currentInlineGroup.length) {
                inlineGroups.push(currentInlineGroup);
            }

            $scope.data.inlineGroups = inlineGroups;
        }
    }

    /**
     * This function handles initializing the params when a form config loading is started
     */
    function startFormLoading() {
        $scope.data.loadingForm = true;
        $scope.data.errorLoadingForm = true;
    }

    /**
     * This function handles initializing the params when a form config loading is finished
     * We can use this function to delay the finish of the loading, because sometimes we want to wait for the UI components to load.
     * @param milliseconds - how many milliseconds to wait before the loading is finished
     */
    function finishFormLoading(milliseconds) {
        if (milliseconds) {
            $timeout(function () {
                $scope.data.loadingForm = false;
            }, milliseconds);
        } else {
            $scope.data.loadingForm = false;
        }
    }

    /**
     * This function handles initializing the params when a form submit loading is started
     */
    function startSaveFormLoading() {
        $scope.data.savingInitiative = true;
        $scope.data.errorSavingInitiative = false;
    }

    /**
     * This function handles initializing the params when a form submit loading is finished
     */
    function finishSaveFormLoading() {
        $scope.data.savingInitiative = false;
    }

    function areAllFieldsValid() {
        $scope.data.validationMap = {};

        ctrl.formConfig.definition.fields.forEach((formField) => {
            validateField(formField);
        });

        return Object.values($scope.data.validationMap).every((value) => !value?.length);
    }

    function validateField(formField) {
        $scope.data.validationMap[formField.fieldDefinitionIdentifier] = [];
        validateRequiredField(formField);
    }

    function validateRequiredField(formField) {
        if (!formField.isRequired) {
            return;
        }

        // Get value either form custom fields or special fields changed value maps
        let fieldValueFromValueMaps =
            $scope.data.specialFieldIdToValueMap[formField.fieldDefinitionIdentifier] ||
            $scope.data.fieldIdToValueMap[formField.fieldDefinitionIdentifier];

        // If the value hasn't been changed yet but had value before the form, we need to check existing values
        if (utils.isNullOrUndefined(fieldValueFromValueMaps)) {
            fieldValueFromValueMaps =
                $scope.data.fieldDefinitionIdToExistingFieldInstanceMap[formField.fieldDefinitionIdentifier] ||
                $scope.data.specialFieldIdToExistingValueMap[formField.fieldDefinitionIdentifier];
        }

        // Sometimes value is actually in an object with the property value
        // The value itself can be undefined or an empty array
        if (!!fieldValueFromValueMaps && fieldValueFromValueMaps.hasOwnProperty('value')) {
            fieldValueFromValueMaps = fieldValueFromValueMaps?.value;
        }

        // We check for null, undefined, empty string or an empty array - all indicate an empty value
        if (
            utils.isNullOrEmpty(fieldValueFromValueMaps) ||
            (Array.isArray(fieldValueFromValueMaps) && utils.isEmpty(fieldValueFromValueMaps))
        ) {
            $scope.data.validationMap[formField.fieldDefinitionIdentifier].unshift({
                errorMessage: 'Required Field',
                restrictSubmission: true,
            });
            $scope.data.allErrorsNotRestrictingErrors = false;
        }
    }
}

angular.module('tonkean.app').controller('UpdateFieldsFormCtrl', lateConstructController(UpdateFieldsFormCtrl));
