import { BaseWidgetHandler } from './BaseWidgetHandler';
import type QuestionWidgetConfiguration from '../widgets/QuestionWidget/QuestionWidgetConfiguration';

import type { FieldDefinitionSummary, WidgetBase } from '@tonkean/tonkean-entities';

class QuestionWidgetHandler extends BaseWidgetHandler {
    override getFieldsSummaryFromConfiguration(widget: WidgetBase): FieldDefinitionSummary[] {
        const configuration = widget.configuration as QuestionWidgetConfiguration;
        if (configuration.selectedField?.key) {
            return [
                {
                    fieldDefinitionId: configuration.selectedField.key,
                    name: widget.displayName,
                } as FieldDefinitionSummary,
            ];
        }
        return [];
    }
}

export default QuestionWidgetHandler;
