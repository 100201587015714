import { useAngularService } from 'angulareact';
import React, { useContext, useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { SimpleSelect } from '@tonkean/infrastructure';

const NetDocumentsComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const [location, setLocation] = useState<string>();
    const locationList = [
        {
            label: 'United States (VAULT)',
            value: 'vault.netvoyage.com',
        },
        {
            label: 'United Kingdom (EU)',
            value: 'eu.netdocuments.com',
        },
        {
            label: 'Australia (AU)',
            value: 'au.netdocuments.com',
        },
        {
            label: 'Germany (DE)',
            value: 'de.netdocuments.com',
        },
    ];

    const authenticateWithOAuth = async () => {
        try {
            const redirectUri = environment.integrationKeysMap.netdocumentsUri;
            const clientId = environment.integrationKeysMap.netdocuments;
            const redirectUriState = oauthHandler.publicGetState();

            const url = `https://${location}/neWeb2/OAuth.aspx?whr=clear&response_type=code&client_id=${clientId}&scope=full&redirect_uri=${redirectUri}&state=${redirectUriState}`;
            const code = await oauthHandler.startOAuth(url, redirectUriState);

            authenticate({ code, location, redirectUri });
        } catch {
            reportError('Error occurred while authenticating using OAuth.');
        }
    };

    return (
        <div className="flex flex-col">
            <div className="api-token-authentication">
                <div className="flex flex-col">
                    <div className="form-group flex-inline">
                        <label className="control-label col-md-3 text-right margin-top-xs">Location</label>
                        <div className="col-sm-7">
                            <SimpleSelect
                                isClearable={false}
                                value={location}
                                onChange={(selectedValue) => setLocation(selectedValue)}
                                options={locationList}
                                thin
                            />
                        </div>
                    </div>

                    <div className="flex mod-center margin-top-20">
                        <button
                            className="btn btn-secondary btn-lg"
                            onClick={authenticateWithOAuth}
                            disabled={!location}
                        >
                            Authenticate with {integrationConfiguration.displayName}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NetDocumentsComponent;
