import React from 'react';
import styled from 'styled-components';

import type UserMenuSectionConfig from '../../../entities/UserMenuSectionConfig';
import UserMenuItem from '../UserMenuItem';

import { Theme } from '@tonkean/tui-theme';

const StyledUserMenuItem = styled.div`
    pointer-events: none;
    background-color: ${Theme.colors.gray_300};
    font-weight: bold;
`;

interface Props {
    setting: UserMenuSectionConfig;
}

const CurrentProjectMenuItem: React.FC<Props> = ({ setting }) => {
    return (
        <StyledUserMenuItem>
            <UserMenuItem setting={setting} />
        </StyledUserMenuItem>
    );
};

export default CurrentProjectMenuItem;
