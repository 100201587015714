import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import type QuestionWidgetListConfiguration from './QusetionWidgetListConfiguration';

import { Input, useFormikField } from '@tonkean/infrastructure';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { debouncer } from '@tonkean/utils';

interface Props {
    listConfiguration: QuestionWidgetListConfiguration;
    possibleValuesSize: number;
    debounceMs?: number;
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-top: 20px;
`;

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    min-width: 60px;
    margin-bottom: 8px;
`;

const StyledInput = styled(Input)`
    height: 36px;
`;

const QuestionWidgetListMinMaxConfigurationEditor: React.FC<Props> = ({
    listConfiguration,
    possibleValuesSize,
    debounceMs = 1000,
}) => {
    const { setValue: setMinimumToSelect } = useFormikField<number>('configuration.listConfiguration.minimumToSelect');
    const { setValue: setMaximumToSelect } = useFormikField<number>('configuration.listConfiguration.maximumToSelect');

    const debounceMinField = useMemo(() => {
        return debouncer(debounceMs);
    }, [debounceMs]);

    const debounceMaxField = useMemo(() => {
        return debouncer(debounceMs);
    }, [debounceMs]);

    const handleMinChange = useCallback(
        (event) => {
            debounceMinField(() => {
                let newMinimum = Number(event.target.value);

                if (newMinimum < 0) {
                    newMinimum = 0;
                } else if (newMinimum > possibleValuesSize) {
                    newMinimum = possibleValuesSize;
                }

                setMinimumToSelect(newMinimum);

                const newMinimumIsLargerThanMaximum = listConfiguration.maximumToSelect < newMinimum;
                if (newMinimumIsLargerThanMaximum) {
                    setMaximumToSelect(newMinimum);
                }
            });
        },
        [
            debounceMinField,
            listConfiguration.maximumToSelect,
            possibleValuesSize,
            setMaximumToSelect,
            setMinimumToSelect,
        ],
    );

    const handleMaxChange = useCallback(
        (event) => {
            debounceMaxField(() => {
                let newwMaximum = Number(event.target.value);

                if (newwMaximum < 1) {
                    newwMaximum = 1;
                } else if (newwMaximum > possibleValuesSize) {
                    newwMaximum = possibleValuesSize;
                }

                setMaximumToSelect(newwMaximum);

                const newMaximumIsSmallerThanMinimum = listConfiguration.minimumToSelect > newwMaximum;
                if (newMaximumIsSmallerThanMinimum) {
                    setMinimumToSelect(newwMaximum);
                }
            });
        },
        [
            debounceMaxField,
            listConfiguration.minimumToSelect,
            possibleValuesSize,
            setMaximumToSelect,
            setMinimumToSelect,
        ],
    );

    if (possibleValuesSize <= 0) {
        return null;
    }

    return (
        <Wrapper>
            <InputWrapper>
                <Label>Minimum Selected</Label>
                <StyledInput
                    name="configuration.listConfiguration.minimumToSelect"
                    autoComplete="off"
                    type="number"
                    min={1}
                    max={possibleValuesSize}
                    data-automation="question-widget-configuration-list-configuration-minimum-to-selected"
                    onChange={handleMinChange}
                />
            </InputWrapper>
            <InputWrapper>
                <Label>Maximum Selected</Label>
                <StyledInput
                    name="configuration.listConfiguration.maximumToSelect"
                    autoComplete="off"
                    type="number"
                    min={1}
                    max={possibleValuesSize}
                    data-automation="question-widget-configuration-list-configuration-maximum-to-selected"
                    onChange={handleMaxChange}
                />
            </InputWrapper>
        </Wrapper>
    );
};

export default QuestionWidgetListMinMaxConfigurationEditor;
