<section class="sign-up-screen flex">
    <div class="flex-grow padding-normal-v overflow-y-auto relative">
        <div class="hidden-xs">
            <tnk-icon src="/images/icons/tonkean.svg"></tnk-icon>
        </div>
        <div ng-if="initialLoading" class="sign-up-content mod-lg padding-normal-v text-center">
            <i class="loading-medium margin-top-xxlg"></i>
        </div>
        <div ng-if="!initialLoading" class="sign-up-content mod-lg padding-normal-v" ng-switch="step">
            <!-- Import -->
            <div class="margin-top-xxlg" ng-switch-when="connect">
                <tnk-import-tracks
                    group-id="pm.groupDefaultId"
                    workflow-version-id="{{ wvm.getPublishedVersionFromCache(pm.groupDefaultId).id }}"
                    class="padding-none"
                    title="Connect to a list from existing system"
                    show-back="true"
                    update-group-name="true"
                    hide-time-range-selection="true"
                    on-complete-callback="onImportCompleteCallback()"
                    dismiss="changeStep('select')"
                ></tnk-import-tracks>
            </div>

            <!-- Select -->
            <div ng-switch-when="select" class="margin-top-xxlg">
                <a class="margin-bottom-lg block common-size-xxs" ng-click="changeStep()">Back</a>

                <div ng-if="$root.selectedUsecase" class="">
                    <div class="clearfix flex flex-col padding-top-lg">
                        <div class="onboarding-start-question">
                            <h1 class="">Create your first list of tracks</h1>

                            <!--<div class="form-group margin-normal-v  common-size-xxs">-->
                            <!--<label>List name:</label>-->
                            <!--<input ng-model="$root.firstListName" placeholder="List name"-->
                            <!--class="form-control inline-block margin-left common-input-only-line common-width-auto"/>-->
                            <!--</div>-->
                            <div class="margin-top-xxlg">
                                <div
                                    ui-sref="quickSetup({step: 'connect'})"
                                    class="btn btn-primary common-no-outline btn-block padding-normal common-shadow-sm"
                                >
                                    <h4 class="margin-none">Connect to a list from existing system</h4>
                                    <div class="opacity-50 common-size-xxs">
                                        We connect with Salesforce, Hubspot, JIRA and many others
                                    </div>
                                </div>
                            </div>
                            <div class="flex-vmiddle margin-normal-v common-color-grey">
                                <div class="flex-grow">
                                    <hr />
                                </div>
                                <i class="common-size-xxxxs margin-normal">Or</i>
                                <div class="flex-grow">
                                    <hr />
                                </div>
                            </div>
                            <div class="text-center">
                                <div class="common-color-grey common-size-xs pointer" ng-click="createSampleTracks()">
                                    Start
                                    {{
                                        data.usecases[$root.selectedUsecase].noExamples
                                            ? 'with an empty list'
                                            : 'with examples'
                                    }}
                                    <i class="loading-small margin-left" ng-if="creating"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Choose usecase -->
            <div class="margin-top-xxlg" ng-switch-default>
                <div class="clearfix flex flex-col padding-top-lg">
                    <div class="onboarding-start-question">
                        <h1>Create your first list of tracks</h1>

                        <br />
                        <h3 class="margin-normal-v">What do you want to track?</h3>
                        <p>You can always change it later and create more lists.</p>

                        <div class="margin-top-lg text-left" style="max-width: 300px">
                            <div ng-repeat="(id, usecase) in data.usecases" class="margin-bottom-xs">
                                <button
                                    class="btn btn-default common-no-outline btn-block btn-lg text-left"
                                    ng-class="{
                                        'btn-primary-black': $root.selectedUsecase === id,
                                        'btn-no-border btn-sm': usecase.noExamples
                                    }"
                                    ng-click="selectUsecase(id)"
                                    style="text-align: left"
                                >
                                    <span
                                        class="text-left"
                                        ng-class="{
                                            'common-color-grey':
                                                usecase.noExamples ||
                                                ($root.selectedUsecase && $root.selectedUsecase !== id)
                                        }"
                                    >
                                        {{ usecase.btn }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <!--<a class="flex-no-shrink common-color-grey common-size-xxxxxs" ng-click="close(false)">I'll do it-->
                        <!--manually →</a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="flex-basis-50 sign-up-screen-image-box common-bg hidden-xs"
        ng-class="step === 'connect' ? 'mod-black' : 'mod-primary'"
        ng-if="!$root.isMobile"
    >
        <img
            ng-if="step !== 'connect'"
            src="/images/onBoarding/onboarding-list.png"
            style="max-height: 478px; left: 10%"
        />
        <img
            ng-if="step === 'connect'"
            src="/images/onBoarding/onboarding-connect-source.png"
            style="max-height: 500px; left: 22%; top: 16%"
        />
    </div>
</section>
