/**
 * Creates a template name from given display name. (Hello $World$ 123 will become hello-world-123).
 */
export default function createMarketplaceItemTemplateNameFromDisplayName(displayName: string) {
    if (!displayName) {
        return '';
    }

    return displayName
        .trim()
        .toLowerCase()
        .replaceAll(/[^a-zA-Z\d]+/g, '-');
}
