<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <tnk-zendesk-authentication
            ng-if="!data.isSharableIntegration"
            authenticate="connect"
        ></tnk-zendesk-authentication>
    </form>

    <div ng-show="integration && !loading">
        <div class="alert alert-info" ng-if="!data.isSharableIntegration">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
        <form class="margin-normal-v" novalidate>
            <div class="row" ng-form="data.integrationForm">
                <div class="col-md-2">Tags</div>
                <div class="col-md-10">
                    <tags-input
                        ng-model="selected.tags"
                        display-property="displayName"
                        placeholder="Specify specific tags (optional)"
                        key-property="value"
                    >
                        <auto-complete
                            source="getTags($query)"
                            min-length="0"
                            load-on-focus="true"
                            load-on-empty="true"
                            max-results-to-show="32"
                        ></auto-complete>
                        <i class="loading mod-tags-input" ng-show="loadingTags"></i>
                    </tags-input>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        ng-if="integration"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
