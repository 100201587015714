import { angularToReact } from '@tonkean/angular-components';

const TnkParentNavigation = angularToReact<{
    parentsArray: { id: string; title: string }[];
    navigate: (id: string) => void;
    immediateParentLabel?: string;
    godFather?: any;
    disableGodFather?: boolean;
    provideParent?: (id: string) => any;
    immediateParentBold?: boolean;
    parentsSeparator?: string;
}>('tnk-parent-navigation');

export default TnkParentNavigation;
