import GenericIntegrationConnectionComponent from './GenericIntegrationConnectionComponent';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.shared')
    .component(
        'tnkGenericIntegrationConnectionComponent',
        reactToAngular(
            GenericIntegrationConnectionComponent,
            [
                'integrationType',
                'integration',
                'setProjectIntegrationData',
                'setIntegrationDisplayName',
                'onIntegrationCreate',
                'isCreatedFromSharedCredentials',
            ],
            [],
        ),
    );
