import React, { useMemo } from 'react';
import styled from 'styled-components';

import { SidePane } from './EnterpriseComponentExplorer/EnterpriseComponentsExplorer';
import { PageContent } from './EnterpriseComponentMenuItemPageContent/EnterpriseComponentMenuItemPageContent';
import { Page } from './EnterpriseComponentPageLayoutView';

import { Placeholder, SideMenuContainer } from '@tonkean/infrastructure';
import { range } from '@tonkean/utils';

const randomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const TitlePlaceholder = styled(Placeholder)`
    margin: 0 auto 50px auto;
`;

const ExplorerItemPlaceholder = styled(Placeholder)`
    margin-left: 15px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

const MenuItemPlaceholder = styled(Placeholder)`
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

const StyledSidePane = styled(SidePane)`
    padding-top: 24px;
`;

interface Props {}

const EnterpriseComponentPageLayoutLoading: React.FC<Props> = ({}) => {
    const explorerItems = useMemo(() => range(randomInt(5, 10)), []);
    const menuItems = useMemo(() => range(randomInt(9, 12)), []);

    return (
        <Page>
            <StyledSidePane>
                <TitlePlaceholder $width="75%" />
                {explorerItems.map((i) => (
                    <ExplorerItemPlaceholder key={i} $width="75%" />
                ))}
            </StyledSidePane>
            <SideMenuContainer>
                {menuItems.map((i) => (
                    <MenuItemPlaceholder key={i} $width="70%" />
                ))}
            </SideMenuContainer>
            <PageContent>
                <Placeholder $width="30%" />
            </PageContent>
        </Page>
    );
};

export default EnterpriseComponentPageLayoutLoading;
