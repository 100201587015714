import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import EnterpriseComponentsExplorerListSection from './EnterpriseComponentsExplorerListSection';
import PersonRoleFilters from '../../../../ProjectIntegrationsViewModule/components/ProjectIntegrationsListView/PersonRoleFilters';
import type { EnterpriseComponentExplorerListSectionConfiguration } from '../../../entities/EnterpriseComponentExplorerListConfiguration';
import useEnterpriseComponentContext from '../../../hooks/useEnterpriseComponentContext';

import type { EnterpriseComponentsPersonPermissionRole } from '@tonkean/tonkean-entities';
import type { EnterpriseComponent, TonkeanType } from '@tonkean/tonkean-entities';
import { enterpriseComponentTypeDisplayName, PersonPermissionRoleType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const FilterHeader = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.current.palette.mainColors.gray_600};
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 10px;
`;

const SectionsContainer = styled.div`
    margin-bottom: 28px;
`;

const SectionsWrapper = styled.div`
    margin-top: 10px;
`;

const FiltersWrapper = styled.div`
    padding: 0 14px;
`;

const NoItemsFound = styled.div`
    margin-top: 15px;
    margin-left: 14px;
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    enterpriseComponentSections: EnterpriseComponentExplorerListSectionConfiguration[];
    enterpriseComponentsCurrentPersonPermissionRole: EnterpriseComponentsPersonPermissionRole;
    searchText?: string;
    enterpriseComponentType: TonkeanType;
}

const EnterpriseComponentsExplorerListView: React.FC<Props> = ({
    enterpriseComponentSections,
    enterpriseComponentsCurrentPersonPermissionRole,
    searchText,
    enterpriseComponentType,
}) => {
    const { enterpriseComponentsByIds } = useEnterpriseComponentContext();
    const [filteredSections, setFilteredSections] =
        useState<EnterpriseComponentExplorerListSectionConfiguration[]>(enterpriseComponentSections);

    // Filter items by given search text
    const filterIntegrationBySearchText = useCallback(
        (item: EnterpriseComponent) => {
            if (!searchText) {
                // If search text is empty, we won't perform filter by text.
                return true;
            } else {
                return item.displayName?.toLowerCase().includes(searchText.toLowerCase());
            }
        },
        [searchText],
    );

    const onPersonRoleFilterChanged = useCallback(
        (isManageableShown, isViewShown, isInaccessibleShown) => {
            const personAccessTypeToFilter: Record<PersonPermissionRoleType, boolean> = {
                [PersonPermissionRoleType.ADMIN]: isManageableShown,
                [PersonPermissionRoleType.MAKER]: isViewShown,
                [PersonPermissionRoleType.NOT_AUTHORIZED]: isInaccessibleShown,
            };

            setFilteredSections(
                enterpriseComponentSections.map((section) => ({
                    ...section,
                    itemIds: section.itemIds.filter((itemId) => {
                        const item = enterpriseComponentsByIds[itemId];
                        if (!item) {
                            return false;
                        }

                        const itemAccessType =
                            enterpriseComponentsCurrentPersonPermissionRole.enterpriseComponentPersonAccessType[
                                item.id
                            ] || item.accessType;
                        return (
                            itemAccessType &&
                            personAccessTypeToFilter[itemAccessType] &&
                            filterIntegrationBySearchText(item)
                        );
                    }),
                })),
            );
        },
        [
            enterpriseComponentsByIds,
            enterpriseComponentSections,
            enterpriseComponentsCurrentPersonPermissionRole?.enterpriseComponentPersonAccessType,
            filterIntegrationBySearchText,
        ],
    );

    const sectionsWithItems = useMemo(() => {
        return filteredSections.filter((section) => section.itemIds.length);
    }, [filteredSections]);

    return (
        <div>
            <SectionsContainer>
                <FiltersWrapper>
                    <FilterHeader>
                        Show {enterpriseComponentTypeDisplayName[enterpriseComponentType].plural} with the permission
                        levels:{' '}
                    </FilterHeader>
                    <PersonRoleFilters onFilterChanged={onPersonRoleFilterChanged} />
                </FiltersWrapper>

                {sectionsWithItems.length === 0 && (
                    <>
                        <NoItemsFound>
                            No data sources to show.
                            <br />
                            Consider changing the filters.
                        </NoItemsFound>
                    </>
                )}
                <SectionsWrapper>
                    {sectionsWithItems.map((section) => {
                        return (
                            <EnterpriseComponentsExplorerListSection
                                key={section.id}
                                enterpriseComponentsCurrentPersonPermissionRole={
                                    enterpriseComponentsCurrentPersonPermissionRole
                                }
                                enterpriseComponentsByIds={enterpriseComponentsByIds}
                                enterpriseComponentType={enterpriseComponentType}
                                section={section}
                            />
                        );
                    })}
                </SectionsWrapper>
            </SectionsContainer>
        </div>
    );
};

export default EnterpriseComponentsExplorerListView;
