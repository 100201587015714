import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const ItemBox = styled.div`
    flex-grow: 1;
    display: flex;
    gap: 16px;
    padding: 2px 3px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;

    .move-button {
        visibility: collapse;
    }
    &:hover {
        .move-button {
            visibility: visible;
        }
    }
`;

const ColorBanner = styled.div`
    flex-shrink: 0;
    width: 2px;
    border-radius: 4px;
    background: ${Theme.colors.primaryHighlight};
`;

const MultiEntitySelectorItemBox: React.FC<PropsWithChildren<StyledComponentsSupportProps>> = ({
    children,
    className,
}) => {
    return (
        <ItemBox className={className}>
            <ColorBanner />
            {children}
        </ItemBox>
    );
};

export default MultiEntitySelectorItemBox;
