import template from './tnkHttpUploadLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkHttpUploadLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        invalidLogics: '<',
        onActionsChanged: '&',
        importActionConfig: '@',
        previewEvaluationSource: '<',
    },
    template,
    controller: 'HttpUploadLogicConfigurationCtrl',
});
