import { reactToAngular } from '@tonkean/angular-components';
import { FieldInspectList } from '@tonkean/fields';

angular
    .module('tonkean.app')
    .component(
        'tnkFieldInspectList',
        reactToAngular(FieldInspectList, [
            'entityFields',
            'viewOnly',
            'onFieldSelected',
            'fieldQuery',
            'multiSelectMap',
        ]),
    );
