import { useAngularService } from 'angulareact';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { useGetStateParams } from '@tonkean/angular-hooks';
import type { BreadcrumbsSettings } from '@tonkean/infrastructure';
import { Breadcrumbs, BreadcrumbsZone, H1, Paragraph, SearchBox, useDebouncedState } from '@tonkean/infrastructure';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

export const EnterpriseComponentPageTemplateContext = React.createContext<
    | {
          currentPageState: string;
          currentPageParams: Record<string, any>;
      }
    | undefined
>(undefined);

const Wrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    padding: 0 64px;

    ${childrenStyledFocus}
`;

const Sticky = styled.div<{ paddingBottom: boolean }>`
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 24px 0 ${({ paddingBottom }) => !paddingBottom && '0'};
    background: ${Theme.colors.basicBackground};
`;

const StickyItems = styled.div`
    margin-top: 24px;
`;

const SpacedBreadcrumbs = styled(Breadcrumbs)`
    margin-bottom: 24px;
`;

const Description = styled(Paragraph)`
    margin: 10px 0 0;
    line-height: 20px;
`;

const FilteringRow = styled.div`
    margin-top: 16px;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
`;

const Body = styled.div`
    padding-bottom: 24px;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    z-index: 0;
`;

interface Props extends StyledComponentsSupportProps {
    name: string;
    title?: React.ReactNode;
    description?: React.ReactNode;
    searchTerm?: string;
    onSearchTermChange?(searchTerm: string): void;
    searchTermPlaceholder?: string;
    actions?: React.ReactNode;
    stickyItems?: React.ReactNode;
    headingPaddingBottom?: boolean;
}

const EnterpriseComponentPageTemplate: React.FC<React.PropsWithChildren<Props>> = ({
    className,
    name,
    title = <H1>{name}</H1>,
    description,
    searchTerm,
    onSearchTermChange,
    searchTermPlaceholder,
    actions,
    children,
    stickyItems,
    headingPaddingBottom = true,
}) => {
    const [q] = useGetStateParams('q');
    const $state = useAngularService('$state');

    const onDebouncedQueryChanged = (value) => {
        $state.go('.', { q: value || '' }, { ...$state.params, reload: false, notify: false });

        if (onSearchTermChange) {
            onSearchTermChange(value || '');
        }
    };

    const [notDebouncedSearchTerm, setNotDebouncedSearchTerm] = useDebouncedState(
        searchTerm,
        onDebouncedQueryChanged,
        300,
    );

    // Read the value from the url and initialize the search term with it
    useEffect(() => {
        if (!searchTerm) {
            setNotDebouncedSearchTerm(q);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pageTemplateContext = useContext(EnterpriseComponentPageTemplateContext);

    // If it's a breadcrumb without a display name, we will add the page name to it.
    const breadcrumb = useMemo<BreadcrumbsSettings>(
        () => ({
            id: `EnterpriseComponentPageTemplate - ${name}`,
            displayName: name,
            clickable: pageTemplateContext && {
                state: pageTemplateContext.currentPageState,
                params: pageTemplateContext.currentPageParams,
                options: { notify: false },
            },
        }),
        [name, pageTemplateContext],
    );

    return (
        <Wrapper>
            <Sticky paddingBottom={headingPaddingBottom}>
                {/* We put Breadcrumbs outside of the new zone because we don't want to show the current page in it, but if it's a child page or a modal then we want it to be shown. */}
                <SpacedBreadcrumbs />

                <BreadcrumbsZone append={breadcrumb}>
                    {title}
                    {description && <Description $light>{description}</Description>}

                    {(onSearchTermChange || actions) && (
                        <FilteringRow>
                            {onSearchTermChange && (
                                <SearchBox
                                    value={notDebouncedSearchTerm || ''}
                                    onChange={(event) => setNotDebouncedSearchTerm(event?.target?.value)}
                                    placeholder={searchTermPlaceholder}
                                />
                            )}

                            {actions}
                        </FilteringRow>
                    )}

                    {stickyItems && <StickyItems>{stickyItems}</StickyItems>}
                </BreadcrumbsZone>
            </Sticky>
            <BreadcrumbsZone append={breadcrumb}>
                <Body className={className}>{children}</Body>
            </BreadcrumbsZone>
        </Wrapper>
    );
};

export default EnterpriseComponentPageTemplate;
