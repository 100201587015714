import { useCallback } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useUpdateField } from '@tonkean/items-grid';
import type { InitiativeRowData } from '@tonkean/items-grid';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';
import type { Initiative, TonkeanId, WorkflowVersion } from '@tonkean/tonkean-entities';
import { WorkflowVersionType, TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const useItemsGridCrudHandlers = (
    projectId: TonkeanId<TonkeanType.PROJECT>,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    parentInitiativeId: Initiative['id'] | undefined,
    workflowVersion: WorkflowVersion | undefined,
    originatedCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER> | undefined,
    initialExpressionValues:
        | {
              fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>;
              expression: TonkeanExpressionDefinition;
          }[]
        | undefined,
) => {
    const [, createPartialInitiative] = useLazyTonkeanService('createPartialInitiative');
    const [, updateInitiativeArchiveState] = useLazyTonkeanService('updateInitiativeArchiveState');

    const updateField = useUpdateField(workflowVersion);

    const handleUpdate = useCallback(
        async (
            initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
            updatedRow: InitiativeRowData,
            updatedCell: string,
            updatedValue: unknown,
        ) => {
            const updatedInitiative = await updateField(initiativeId, updatedRow, updatedCell, updatedValue);
            return { id: initiativeId, initiative: updatedInitiative };
        },
        [updateField],
    );

    const handleCreate = useCallback(
        (updatedRow: InitiativeRowData): Promise<Initiative> => {
            if (!groupId) {
                console.error("no group id, can't create initiative");

                return Promise.reject('No group id');
            }

            return createPartialInitiative(
                projectId,
                updatedRow.title || `New Line Item (${utils.formatDate(new Date(), true, false, false)})`,
                groupId,
                workflowVersion?.workflowVersionType === WorkflowVersionType.DRAFT,
                updatedRow.owner?.id,
                parentInitiativeId,
                updatedRow.dueDate ? Number(updatedRow.dueDate) : undefined,
                Object.entries(updatedRow)
                    .filter(([fieldId]) =>
                        fieldId.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.FIELD_DEFINITION]),
                    )
                    .map(([fieldId, value]) => ({ fieldDefinitionId: fieldId, value: value?.toString() || '' })),
                updatedRow.state,
                originatedCustomTriggerId,
                initialExpressionValues,
            );
        },
        [
            createPartialInitiative,
            groupId,
            initialExpressionValues,
            parentInitiativeId,
            originatedCustomTriggerId,
            projectId,
            workflowVersion?.workflowVersionType,
        ],
    );

    const handleDelete = useCallback(
        (id: TonkeanId<TonkeanType.INITIATIVE>) => {
            return updateInitiativeArchiveState(id, true, '');
        },
        [updateInitiativeArchiveState],
    );

    return { handleCreate, handleDelete, handleUpdate };
};

export default useItemsGridCrudHandlers;
