import template from './tnkTabsFieldSelector.template.html?angularjs';

angular.module('tonkean.app').component('tnkTabsFieldSelector', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        workflowFolderId: '@',
        entityVersionType: '@',
        logicId: '@',
        tooltip: '@', // Optional. A tooltip the will be displayed above the button.

        onFieldSelected: '&',
        onFieldSelectedReact: '<',
        globalTabsOnly: '<', // When true, will not show anything related to initiative like fields.
        additionalTabs: '<', // Array of tabs to ADD to the default ones
        paramForTabs: '<',
        tabsFieldSelectorModNarrow: '<', // Making the width of the tnkTabsFieldSelector narrower.
        tabsFieldSelectorModFixedWidth: '<', // Making the width of the tnkTabsFieldSelector narrower.
        showFormulasTab: '<', // Hiding or showing the formula tab. Defaults to true.
        onPopoverOpen: '&',
        isFromHtmlEditor: '<',
        buttonClassOverride: '<',

        automationIdentifierExpressionAddField: '@',

        showPeopleDirectories: '<',
        peopleDirectories: '<', // People directories to show in picker
        defaultTabId: '<', // Optional, if exists this tab will open by default.

        popoverPlacement: '<',
        excludedTabSelectorSpecialFields: '<', // remove from special fields tab selector fields (for example ['TNK_TODAY']) that are considerInTypes:'FIELDS_TAB_SELECTOR' or any provided specialFieldsValuesForFeatures.
        includeTabSelectorSpecialFieldsForFeatures: '<', // Use 'considerInTypes' to add fields under this category to the special fields tab selector
        fieldsToFilter: '<', // list of fields that should be filtered from options
    },
    template,
    controller: 'TabsFieldSelectorCtrl',
});
