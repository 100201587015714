import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import SolutionBusinessReportAccessBlocker from './SolutionBusinessReportAccessBlocker';
import type { SolutionBusinessReportContextType } from './SolutionBusinessReportContext';
import SolutionBusinessReportContext from './SolutionBusinessReportContext';
import type { ModuleDataFilters } from './SolutionBusinessReportGroup';
import SolutionBusinessReportGroup from './SolutionBusinessReportGroup';
import SolutionReportMenu from './SolutionReportMenu';

import {
    useAsyncMethod,
    useGetStateParams,
    useIsInSharedReport,
    useLazyTonkeanService,
    useToastMessage,
} from '@tonkean/angular-hooks';
import { BusinessReportShareModal } from '@tonkean/business-report';
import {
    EnvironmentIndicator,
    H2,
    Placeholder,
    SavingIndicator,
    StateMessage,
    TextEllipsis,
    Tooltip,
    useModifiableList,
    useUserSolutionBusinessReportAccess,
} from '@tonkean/infrastructure';
import { ArrowLeft, ArrowRight, EmptyItemsIcon, ShareIcon } from '@tonkean/svg';
import type { SolutionBusinessReport, SolutionBusinessReportAccessPermission } from '@tonkean/tonkean-entities';
import { SolutionBusinessReportPermissionType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { debouncer, EMPTY_OBJECT, range } from '@tonkean/utils';

const Wrapper = styled.div`
    padding: 8px;
`;

const Header = styled.div`
    border-bottom: 1px solid ${Theme.colors.gray_300};
    display: flex;
    justify-content: start;
    align-items: center;
    padding-bottom: 8px;
`;

const StyledTitle = styled(H2)`
    margin: 0 17px;
`;

const Left = styled.div`
    margin-left: auto;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8px;
`;

const StyledClickable = styled(Clickable)`
    display: flex;
`;

const SolutionBusinessReportState: React.FC = () => {
    const $timeout = useAngularService('$timeout');
    const $rootScope = useAngularService('$rootScope');
    const $state = useAngularService('$state');
    const liveReportHelper = useAngularService('liveReportHelper');
    const toast = useToastMessage();
    const [solutionBusinessReport, setSolutionBusinessReport] = useState<SolutionBusinessReport | undefined>(undefined);
    const isInSharedReport = useIsInSharedReport();

    const [solutionBusinessReportId] = useGetStateParams('solutionBusinessReportId');
    const [
        {
            data: solutionBusinessReportData,
            error: solutionBusinessReportError,
            loading: loadingSolutionBusinessReport,
        },
        getSolutionBusinessReportById,
    ] = useLazyTonkeanService('getSolutionBusinessReportById');

    const solutionBusinessReportResponse = solutionBusinessReportData?.solutionBusinessReport;
    const groupIdToSavedFilters = solutionBusinessReportData?.groupIdToSavedFilters;

    const { loading: liveReportFieldsLoading, error: liveReportFieldsError } = useAsyncMethod(
        liveReportHelper,
        'getLiveReportFieldDefinitions',
        undefined,
        undefined,
        solutionBusinessReportId,
    );

    useEffect(() => {
        liveReportHelper.initializeCache(undefined, undefined, solutionBusinessReportId);
    }, [liveReportHelper, solutionBusinessReportId]);

    useEffect(() => {
        if (solutionBusinessReportResponse) {
            setSolutionBusinessReport(solutionBusinessReportResponse);
        }
    }, [solutionBusinessReportResponse]);

    useEffect(() => {
        const errorMessage =
            solutionBusinessReportError?.data.error?.message || liveReportFieldsError?.data.error?.message;

        if (errorMessage) {
            toast(errorMessage, 'danger');
            $state.go('noaccesspage', {}, { location: 'replace' });
        }
    }, [$state, solutionBusinessReportError?.data, liveReportFieldsError?.data, toast]);

    const [showProjectFilter] = useAngularWatch(() => $rootScope['showProjectFilter'] as boolean);
    const toggleShowProjectFilter = () => {
        $timeout(() => {
            $rootScope['showProjectFilter'] = !showProjectFilter;
        });
    };

    const [groups, { onUpdate }] = useModifiableList(solutionBusinessReport?.groups, (group) => group.groupId);

    const [
        { loading: updatingSolutionBusinessReport, error: updateSolutionBusinessReportError },
        updateSolutionBusinessReport,
    ] = useLazyTonkeanService('updateSolutionBusinessReport');
    const updateDebounce = useMemo(() => debouncer(500), []);
    const updateFilters = (groupId: string, filters: ModuleDataFilters) => {
        onUpdate(groupId, { filters });
        updateDebounce(() =>
            updateSolutionBusinessReport(solutionBusinessReportId, {
                groups: groups?.map((group) => (group.groupId === groupId ? { groupId, filters } : group)),
            }),
        );
    };

    const [shareModalOpen, setShareModalOpen] = useState(false);

    useEffect(() => {
        getSolutionBusinessReportById(solutionBusinessReportId, isInSharedReport);
    }, [getSolutionBusinessReportById, isInSharedReport, solutionBusinessReportId]);

    const onCloseShareModal = useCallback(
        (
            viewPermissionType: SolutionBusinessReportAccessPermission,
            viewPermissionPeople: string[],
            editPermissionType: SolutionBusinessReportAccessPermission,
            editPermissionPeople: string[],
        ) => {
            setShareModalOpen(false);
            setSolutionBusinessReport(
                (prevSolutionBusinessReport) =>
                    prevSolutionBusinessReport && {
                        ...prevSolutionBusinessReport,
                        permissions: {
                            EDIT: {
                                type: SolutionBusinessReportPermissionType.EDIT,
                                permission: editPermissionType,
                                peopleIds: editPermissionPeople,
                            },
                            VIEW: {
                                type: SolutionBusinessReportPermissionType.VIEW,
                                permission: viewPermissionType,
                                peopleIds: viewPermissionPeople,
                            },
                        },
                    },
            );
        },
        [],
    );

    const currentUserAccess = useUserSolutionBusinessReportAccess(solutionBusinessReport);

    const contextValue = useMemo<SolutionBusinessReportContextType>(
        () => ({
            solutionBusinessReport,
            currentUserAccess,
        }),
        [currentUserAccess, solutionBusinessReport],
    );

    const emptyState =
        !loadingSolutionBusinessReport && !solutionBusinessReport?.groups?.length && !solutionBusinessReportError;
    const showGroupsState = !loadingSolutionBusinessReport && !solutionBusinessReportError && !emptyState;

    return (
        <SolutionBusinessReportContext.Provider value={contextValue}>
            <SolutionBusinessReportAccessBlocker>
                <Wrapper>
                    <Header>
                        {!isInSharedReport && (
                            <StyledClickable onClick={toggleShowProjectFilter}>
                                {showProjectFilter ? <ArrowLeft /> : <ArrowRight />}
                            </StyledClickable>
                        )}
                        <StyledTitle>
                            <TextEllipsis numberOfLines={1}>
                                {solutionBusinessReport?.displayName ?? <Placeholder $width="150px" $height="25px" />}
                            </TextEllipsis>
                        </StyledTitle>
                        {!isInSharedReport && solutionBusinessReport && (
                            <SolutionReportMenu
                                solutionBusinessReport={{ ...solutionBusinessReport, groups }}
                                onUpdate={setSolutionBusinessReport}
                                onDelete={() => {
                                    $state.go('product.board', {}, { reload: true });
                                }}
                            />
                        )}
                        <Tooltip content="Saving filters" disabled={!updatingSolutionBusinessReport}>
                            <SavingIndicator
                                error={updateSolutionBusinessReportError}
                                loading={updatingSolutionBusinessReport}
                            />
                        </Tooltip>
                        <Left>
                            {solutionBusinessReport && !isInSharedReport && (
                                <EnvironmentIndicator
                                    currentEnvironment={
                                        solutionBusinessReport.workflowVersionType === WorkflowVersionType.DRAFT
                                            ? 'build'
                                            : 'production'
                                    }
                                    readonly
                                />
                            )}
                            {!isInSharedReport && (
                                <Button onClick={() => setShareModalOpen(true)} flex>
                                    <ShareIcon /> Share
                                </Button>
                            )}
                        </Left>
                    </Header>

                    {showGroupsState &&
                        !liveReportFieldsLoading &&
                        groups.map((group) => (
                            <SolutionBusinessReportGroup
                                key={group.groupId}
                                solutionBusinessReport={solutionBusinessReport}
                                moduleData={group.filters ?? EMPTY_OBJECT}
                                onModuleDataChange={(filters) => updateFilters(group.groupId, filters)}
                                groupId={group.groupId}
                                savedFilters={groupIdToSavedFilters?.[group.groupId]}
                            />
                        ))}

                    {loadingSolutionBusinessReport &&
                        range(2).map((index) => (
                            <SolutionBusinessReportGroup
                                key={index}
                                solutionBusinessReport={undefined}
                                moduleData={undefined}
                                groupId={undefined}
                                savedFilters={undefined}
                            />
                        ))}

                    {emptyState && (
                        <StateMessage icon={<EmptyItemsIcon />} title="There are no modules in the report" />
                    )}

                    {shareModalOpen && solutionBusinessReport && !liveReportFieldsLoading && (
                        <BusinessReportShareModal
                            open={shareModalOpen}
                            onClose={onCloseShareModal}
                            solutionBusinessReport={solutionBusinessReport}
                        />
                    )}
                </Wrapper>
            </SolutionBusinessReportAccessBlocker>
        </SolutionBusinessReportContext.Provider>
    );
};

export default SolutionBusinessReportState;
