import { useAngularService } from 'angulareact';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ImportCustomActionFromWsdlModal from './ProjectIntegrationPageActionsPage/ImportCustomActionFromWsdlModal/ImportCustomActionFromWsdlModal';
import ProjectIntegrationActionView from './ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationActionCRUDPage/ProjectIntegrationActionView';
import type { ProjectIntegrationActionForView } from './ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationActionCRUDPage/ProjectIntegrationActionView';
import ProjectIntegrationActionCreateModal from './ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationCreateAction/components/ProjectIntegrationActionCreateModal';
import sqlIntegrations from './ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/utils/SqlIntegrations';
import ProjectIntegrationPageContext from './ProjectIntegrationPageContext';
import { ReactComponent as ActionsEmptyStateIcon } from '../../../../images/icons/actions-empty-state.svg';
import EnterpriseComponentPageTemplate from '../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import useNativeActionsFromConsts from '../hooks/useNativeActionsFromConsts';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { LoadingCircle, Modal, ModalSize, StateMessage, Tooltip } from '@tonkean/infrastructure';
import { ActionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const StyledModal = styled(Modal)`
    height: 600px;
`;

interface Props {}

const ProjectIntegrationPageActionsPage: React.FC<Props> = () => {
    const featureImportCustomActionFromWsdl = useFeatureFlag('tonkean_feature_import_custom_action_from_wsdl');

    const $state = useAngularService('$state');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    // Indicates whether import from WSDL modal is open.
    const [isImportFromWsdlModalOpen, setIsImportFromWsdlModalOpen] = useState(false);

    const { currentProjectIntegration } = useContext(ProjectIntegrationPageContext);

    const nativeActionsSummary = useNativeActionsFromConsts(currentProjectIntegration?.integrationType);

    const [
        { data: projectIntegrationActionsResponse, loading: loadingProjectIntegrationActions },
        getProjectIntegrationActions,
    ] = useLazyTonkeanService('getProjectIntegrationActions');

    const actions: ProjectIntegrationActionForView[] = useMemo(() => {
        const native = nativeActionsSummary.map((nativeActionSummary) => ({
            id: nativeActionSummary.id,
            displayName: nativeActionSummary.displayName,
            description: nativeActionSummary.description || '',
            isCustom: false,
        }));

        const custom =
            projectIntegrationActionsResponse?.entities.map((action) => ({
                id: action.id,
                displayName: action.displayName,
                description: action.description || '',
                isCustom: !action.isImported,
            })) || [];

        return [...native, ...custom];
    }, [nativeActionsSummary, projectIntegrationActionsResponse?.entities]);

    // State for search action Input
    const [searchValue, setSearchValue] = useState<string>('');

    // On page load requesting action list.
    useEffect(() => {
        if (currentProjectIntegration?.id) {
            getProjectIntegrationActions(currentProjectIntegration.id);
        }
    }, [currentProjectIntegration?.id, getProjectIntegrationActions]);

    const closeModal = () => {
        setIsCreateModalOpen(false);
    };

    const hasNoActions =
        !loadingProjectIntegrationActions &&
        !projectIntegrationActionsResponse?.entities?.length &&
        !nativeActionsSummary.length;

    const projectIntegrationActionNames: string[] = useMemo(() => {
        return projectIntegrationActionsResponse?.entities?.map((action) => action.displayName) || [];
    }, [projectIntegrationActionsResponse]);

    const shouldShowImportFromWsdlButton: boolean | undefined =
        featureImportCustomActionFromWsdl &&
        !sqlIntegrations.includes(currentProjectIntegration?.integrationType.toLowerCase() || '');

    return currentProjectIntegration ? (
        <EnterpriseComponentPageTemplate
            name="Actions"
            searchTerm={searchValue}
            onSearchTermChange={setSearchValue}
            searchTermPlaceholder="Search Action"
            actions={
                <>
                    <Button
                        size={ButtonSize.MEDIUM}
                        data-automation="project-integration-page-actions-page-create-new-button"
                        onClick={() => {
                            setIsCreateModalOpen(true);
                        }}
                        flex
                    >
                        Create New Custom Action
                    </Button>

                    {shouldShowImportFromWsdlButton && (
                        <Tooltip
                            placement="bottom"
                            limitWidth={20}
                            content="The Web Services Description Language (WSDL) is an XML-based interface description language that is used for describing the functionality offered by a web service"
                        >
                            <Button
                                size={ButtonSize.MEDIUM}
                                onClick={() => {
                                    setIsImportFromWsdlModalOpen(true);
                                }}
                                data-automation="project-integration-page-actions-page-create-wsdl-button"
                                highlighted
                            >
                                Create from WSDL
                            </Button>
                        </Tooltip>
                    )}
                </>
            }
            description={
                <>
                    Actions are predefined processes (for example, creating or updating data in an application) that
                    correspond to action blocks in the module builder.
                    <br />
                    View the native actions or create a new custom action to add new logic to the data source.
                </>
            }
        >
            <ProjectIntegrationActionView
                projectIntegrationActions={actions}
                loading={loadingProjectIntegrationActions}
                filterValue={searchValue}
                projectIntegration={currentProjectIntegration}
            />

            {hasNoActions && (
                <StateMessage
                    icon={
                        <span className="tnk-icon">
                            <ActionsEmptyStateIcon />
                        </span>
                    }
                    title="No actions were created yet"
                >
                    Click on the ‘Create New’ to create a new custom action.
                </StateMessage>
            )}

            <ProjectIntegrationActionCreateModal
                isOpen={isCreateModalOpen}
                onCancel={closeModal}
                onCreate={(id) => {
                    closeModal();
                    $state.go('product.projectIntegrationActionManager', {
                        tab: 'general',
                        projectIntegrationActionId: id,
                        projectIntegrationId: currentProjectIntegration.id,
                    });
                }}
                projectIntegration={currentProjectIntegration}
                projectIntegrationActionNames={projectIntegrationActionNames}
                customActionTypesToExclude={[ActionType.FETCHING]}
                defaultActionType={ActionType.CUSTOM}
            />

            <StyledModal
                open={isImportFromWsdlModalOpen}
                onClose={() => setIsImportFromWsdlModalOpen(false)}
                size={ModalSize.MEDIUM}
                fixedWidth
            >
                <ImportCustomActionFromWsdlModal
                    projectIntegrationId={currentProjectIntegration.id}
                    onImportCompleted={() => {
                        getProjectIntegrationActions(currentProjectIntegration.id);
                        setIsImportFromWsdlModalOpen(false);
                    }}
                />
            </StyledModal>
        </EnterpriseComponentPageTemplate>
    ) : (
        <LoadingCircle />
    );
};

export default ProjectIntegrationPageActionsPage;
