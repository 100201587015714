import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useState } from 'react';
import styled from 'styled-components';

import UserMenuContent from './UserMenuContent';

import { Chevron, ChevronDirection, Menu } from '@tonkean/infrastructure';
import { TonkeanAvatar } from '@tonkean/infrastructure';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const UserSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const AvatarMenuWrapper = styled(Menu)`
    max-height: 80vh;
`;

const StyledUserButton = styled.button<{ $transparentMode?: boolean; $chevronPosition: 'left' | 'right' }>`
    border: none;
    display: flex;
    align-items: center;
    background: ${({ $transparentMode }) => ($transparentMode ? 'transparent' : 'white')};
    flex-direction: ${({ $chevronPosition }) => ($chevronPosition === 'left' ? 'row' : 'row-reverse')};
    gap: 10px;
`;

const UserAvatar = styled.span`
    width: 24px;
    height: 24px;
    position: relative;
`;

interface Props extends StyledComponentsSupportProps {
    transparentMode: boolean | undefined;
    chevronPosition?: 'left' | 'right';
    isSystemUser: boolean;
}

const UserMenu: React.FC<Props> = ({ transparentMode, chevronPosition = 'left', isSystemUser, className }) => {
    const authenticationService = useAngularService('authenticationService');

    const [avatarMenuIsOpen, setAvatarMenuIsOpen] = useState(false);
    const [currentUser] = useAngularWatch(() => authenticationService.currentUser);

    return (
        <UserSectionWrapper className={className}>
            {currentUser && (
                <AvatarMenuWrapper
                    aria-label="Open User Menu"
                    show={avatarMenuIsOpen}
                    onClose={() => setAvatarMenuIsOpen(false)}
                    menuItems={<UserMenuContent isSystemUser={isSystemUser} />}
                >
                    <StyledUserButton
                        data-automation="user-menu-navigation-icon"
                        onClick={() => setAvatarMenuIsOpen(!avatarMenuIsOpen)}
                        $transparentMode={transparentMode}
                        $chevronPosition={chevronPosition}
                    >
                        <Chevron
                            direction={avatarMenuIsOpen ? ChevronDirection.UP : ChevronDirection.DOWN}
                            data-automation="user-menu-navigation-icon-direction"
                        />
                        <UserAvatar>
                            <TonkeanAvatar owner={currentUser} />
                        </UserAvatar>
                    </StyledUserButton>
                </AvatarMenuWrapper>
            )}
        </UserSectionWrapper>
    );
};

export default UserMenu;
