import type InnerItemWidgetConfiguration from '../widgets/entities/InnerItemWidgetConfiguration';
import type UploadWidgetConfiguration from '../widgets/entities/UploadWidgetConfiguration';
import type FieldsItemWidgetConfiguration from '../widgets/FieldsWidgetModule/FieldsItemWidgetConfiguration';

import { ItemInterfaceWidgetConfigurationDisplayMode, type FieldDefinitionSummary } from '@tonkean/tonkean-entities';

const getFieldsSummaryFromConfiguration = (
    configuration: InnerItemWidgetConfiguration | FieldsItemWidgetConfiguration | UploadWidgetConfiguration,
): FieldDefinitionSummary[] => {
    return Object.entries(configuration.fields)
        .filter(([_, config]) => !!config.isShown)
        .map(
            ([key, config]) =>
                ({
                    fieldDefinitionId: key,
                    name: config.label,
                    index: Number(config.index),
                }) as FieldDefinitionSummary,
        );
};

const getItemsFieldsSummaryFromConfiguration = (
    configuration: InnerItemWidgetConfiguration,
): FieldDefinitionSummary[] => {
    let fields: FieldDefinitionSummary[] = [];
    if (configuration.displayMode === ItemInterfaceWidgetConfigurationDisplayMode.CARDS) {
        fields = Object.entries(configuration.cardFields).map(
            ([key, config]) =>
                ({
                    fieldDefinitionId: key,
                    name: config.label,
                    index: Number(config.index),
                }) as FieldDefinitionSummary,
        );
    } else {
        fields = getFieldsSummaryFromConfiguration(configuration);
    }

    return fields;
};

export { getFieldsSummaryFromConfiguration, getItemsFieldsSummaryFromConfiguration };
