import { useAngularService } from 'angulareact';
import React, { useMemo, useReducer } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as CopyIcon } from '../../../images/icons/x.svg';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { Tooltip } from '@tonkean/infrastructure';
import {
    ActionDefinitionType,
    type ProjectIntegrationAction,
    type TonkeanExpressionDefinition,
} from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ParameterTitle = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
`;

const ParameterTitleInfoWrapper = styled.div`
    display: flex;
    align-items: baseline;
`;

const TooltipIconWrapper = styled.div`
    margin-left: 8px;
    color: ${Theme.colors.primary};
`;

const KeyValueWrapper = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
`;

enum KeyValueProperty {
    KEY = 'key',
    VALUE = 'value',
}

interface KeyValueActionParameter {
    key: TonkeanExpressionDefinition;
    value: TonkeanExpressionDefinition;
    id: string;
}

interface Props {
    /**
     * The group id this action is in.
     */
    groupId: string;

    /**
     * The version of the group this action is in.
     */
    workflowVersionId: string;

    /**
     * The preview evaluation source whether it is example item or worker run logic id
     */
    previewEvaluationSource: any;

    /**
     * The custom trigger id this action is defined in.
     */
    customTriggerId: string;

    /**
     * The definition of the current custom trigger.
     */
    definition: any;

    /**
     * The project integration action we base the configuration on.
     */
    projectIntegrationAction: ProjectIntegrationAction;

    /**
     * A callback to be called when ever the definition of the custom trigger is changed.
     * @param definition is the new definition we modified.
     * @param shouldSaveLogic whether we should save the logic or not when a change in definition has happened.
     */
    onDefinitionChange(definition: unknown, shouldSaveLogic: boolean): void;
}

const KeyValueActionParameters: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    previewEvaluationSource,
    customTriggerId,
    definition,
    projectIntegrationAction,
    onDefinitionChange,
}) => {
    const isEnableKeyValueParameters = useMemo(() => {
        return (
            projectIntegrationAction?.actionDefinition?.definitionType === ActionDefinitionType.HTTP &&
            projectIntegrationAction.actionDefinition?.enableKeyValueParameters
        );
    }, [projectIntegrationAction.actionDefinition]);

    const $timeout = useAngularService('$timeout');

    const saveKeyValueParameters = (newKeyValueParametersValues) => {
        const newDefinition = {
            ...definition,
            keyValueParametersValues: newKeyValueParametersValues,
        };

        $timeout(() => onDefinitionChange(newDefinition, true));
    };

    const [keyValueParameters, setKeyValueParameters] = useReducer(
        (oldValue, newValue) => {
            saveKeyValueParameters(newValue);
            return newValue;
        },
        definition?.keyValueParametersValues?.map((keyValue) => ({ ...keyValue, id: uuidv4() })) ?? [],
    );

    const removeKeyValueParameter = (keyValueParameter: KeyValueActionParameter) => {
        const updatedParameters = keyValueParameters.filter((keyValue) => keyValue !== keyValueParameter);
        setKeyValueParameters(updatedParameters);
    };

    const updateKeyValueParameter = (
        originalExpression: string,
        evaluatedExpression: string,
        keyValueToUpdate: KeyValueActionParameter,
        keyValueProperty: KeyValueProperty,
    ) => {
        const updatedKeyValueParameters = keyValueParameters.map((keyValue) => {
            if (keyValue === keyValueToUpdate) {
                keyValue[keyValueProperty].originalExpression = originalExpression;
                keyValue[keyValueProperty].evaluatedExpression = evaluatedExpression;
            }
            return keyValue;
        });
        setKeyValueParameters(updatedKeyValueParameters);
    };

    const addKeyValueParameter = () => {
        const newTonkeanExpression = {
            key: {
                originalExpression: '',
                evaluatedExpression: '',
            },
            value: {
                originalExpression: '',
                evaluatedExpression: '',
            },
            id: uuidv4(),
        };
        setKeyValueParameters([...keyValueParameters, newTonkeanExpression]);
    };

    const keyValueParametersRows = keyValueParameters.map((keyValue: KeyValueActionParameter, index) => {
        return (
            <tr key={keyValue.id}>
                <td className="padding-normal-xs col-sm-10">
                    <KeyValueWrapper>Key:</KeyValueWrapper>
                    <TonkeanExpression
                        groupId={groupId}
                        workflowVersionId={workflowVersionId}
                        logicId={customTriggerId}
                        savedEvaluatedExpression={keyValue.key.evaluatedExpression}
                        savedOriginalExpression={keyValue.key.originalExpression}
                        previewEvaluationSource={previewEvaluationSource}
                        onTonkeanExpressionChanged={(
                            originalExpression,
                            evaluatedExpression,
                            ignored,
                            shouldSaveLogic,
                        ) => {
                            if (shouldSaveLogic) {
                                updateKeyValueParameter(
                                    originalExpression,
                                    evaluatedExpression,
                                    keyValue,
                                    KeyValueProperty.KEY,
                                );
                            }
                        }}
                        saveOnKeyUp
                    />
                    <KeyValueWrapper>Value:</KeyValueWrapper>
                    <TonkeanExpression
                        groupId={groupId}
                        workflowVersionId={workflowVersionId}
                        logicId={customTriggerId}
                        savedEvaluatedExpression={keyValue.value.evaluatedExpression}
                        savedOriginalExpression={keyValue.value.originalExpression}
                        previewEvaluationSource={previewEvaluationSource}
                        onTonkeanExpressionChanged={(
                            originalExpression,
                            evaluatedExpression,
                            ignored,
                            shouldSaveLogic,
                        ) => {
                            if (shouldSaveLogic) {
                                updateKeyValueParameter(
                                    originalExpression,
                                    evaluatedExpression,
                                    keyValue,
                                    KeyValueProperty.VALUE,
                                );
                            }
                        }}
                        saveOnKeyUp
                    />
                </td>
                <td className="col-sm-1">
                    <Tooltip content="Remove field">
                        <button
                            onClick={() => {
                                removeKeyValueParameter(keyValue);
                            }}
                            className="btn btn-no-style svg-icon-xs common-color-grey"
                        >
                            <CopyIcon />
                        </button>
                    </Tooltip>
                </td>
            </tr>
        );
    });
    return (
        <div className="margin-top-lg margin-bottom-30">
            {isEnableKeyValueParameters && (
                <ParameterTitleInfoWrapper>
                    <ParameterTitle>Dynamic Request Fields:</ParameterTitle>
                    <Tooltip content="Dynamically add fields to your request. These fields will be added to the item schema">
                        <TooltipIconWrapper>
                            <span className="fa fa-info-circle" />
                        </TooltipIconWrapper>
                    </Tooltip>
                </ParameterTitleInfoWrapper>
            )}

            {isEnableKeyValueParameters && keyValueParameters && (
                <table className="table table-striped">
                    <tbody>{keyValueParametersRows}</tbody>
                </table>
            )}
            {isEnableKeyValueParameters && (
                <>
                    <ClickableLink
                        data-automation="key-value-action-parameters-add-parameter"
                        onClick={() => {
                            addKeyValueParameter();
                        }}
                    >
                        + Add
                    </ClickableLink>
                </>
            )}
        </div>
    );
};

export default KeyValueActionParameters;
