import SolutionSitePageEmbedWidget from './components/SolutionSitePageEmbedWidget';
import SolutionSitePageEmbedWidgetEditor from './components/SolutionSitePageEmbedWidgetEditor';
import type EmbedWidgetConfiguration from './EmbedWidgetConfiguration';
import type { SolutionSitePageWidgetPackage } from '../../WidgetModule';

const SolutionSitePageEmbedWidgetPackage: SolutionSitePageWidgetPackage<EmbedWidgetConfiguration> = {
    displayComponent: SolutionSitePageEmbedWidget,
    configurationComponent: SolutionSitePageEmbedWidgetEditor,
};

export default SolutionSitePageEmbedWidgetPackage;
