import CommentsWidget from './CommentsWidget';
import CommentsWidgetConfigurationEditor from './CommentsWidgetConfigurationEditor';
import type { ItemInterfaceWidgetPackage } from '../../../../WidgetModule';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';

const CommentsWidgetPackage: ItemInterfaceWidgetPackage<ItemInterfaceWidgetConfiguration> = {
    displayComponent: CommentsWidget,
    configurationComponent: CommentsWidgetConfigurationEditor,
};

export default CommentsWidgetPackage;
