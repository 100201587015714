import { useCallback } from 'react';

import type NextStepSummary from '../entities/NextStepSummary';

import type {
    FetchResults,
    Initiative,
    ItemInterface,
    TonkeanId,
    TonkeanType,
    WidgetBase,
    WidgetConfiguration,
    WidgetParentTypes,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';

interface UpdateNextSequenceStepResponse {
    isNextStepThankYouPage: boolean;
    isWaitingForNextInitiative: boolean;
}

const useUpdateNextSequenceStep = (
    updateSequenceStep: (
        stepItemInterface: ItemInterface,
        stepCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        stepWidgets: WidgetBase<WidgetConfiguration, WidgetParentTypes>[] | undefined,
        isStepLastInSequence: boolean,
        nextInSequence: boolean,
        initative: Initiative | undefined,
        workflowVersion: WorkflowVersion | undefined,
        evaluatedIntakeSequenceButtonLabel: string | undefined,
    ) => void,
    getStepInterfaceWidgets: (
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
        stepInitiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    ) => Promise<FetchResults<WidgetBase<WidgetConfiguration, WidgetParentTypes>>>,
    getStepItemInterfaceById: (
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
    ) => Promise<ItemInterface>,
) => {
    const updateSummaryHandle = useCallback(
        async (nextStepSummary: NextStepSummary): Promise<UpdateNextSequenceStepResponse> => {
            // if next in sequence is interface, we handle it here
            // otherwise we return to the form state manager and continue to the next form
            const {
                showInterfaceInNextStep,
                goToSequenceTriggerId,
                goToSequenceInterfaceId,
                isStepLastInSequence,
                isNextStepThankYouPage,
                firstActionDefinition,
                answeredCustomTriggerId,
                nextStepInitiative,
                nextStepWorkflowVersion,
                evaluatedIntakeSequenceButtonLabel,
            } = nextStepSummary;

            if (isNextStepThankYouPage) {
                return { isNextStepThankYouPage: true, isWaitingForNextInitiative: false };
            }

            let nextItemInterfaceId;
            let nextCustomTriggerId;

            if (showInterfaceInNextStep) {
                nextItemInterfaceId = firstActionDefinition.itemInterfaceId;
                nextCustomTriggerId = answeredCustomTriggerId;

                if (nextStepInitiative === undefined && firstActionDefinition.matchedInterfaceFieldDefinitionId) {
                    return { isNextStepThankYouPage: false, isWaitingForNextInitiative: true };
                }
            } else if (!!goToSequenceInterfaceId && !!goToSequenceTriggerId) {
                nextItemInterfaceId = goToSequenceInterfaceId;
                nextCustomTriggerId = goToSequenceTriggerId;
            }

            const itemInterfaceDataById = await getStepItemInterfaceById(
                nextItemInterfaceId,
                nextStepWorkflowVersion?.id,
            );
            const widgetsData = await getStepInterfaceWidgets(
                nextItemInterfaceId,
                nextStepWorkflowVersion?.id,
                nextStepInitiative?.id,
            );

            updateSequenceStep(
                itemInterfaceDataById,
                nextCustomTriggerId,
                widgetsData?.entities,
                isStepLastInSequence,
                true,
                nextStepInitiative,
                nextStepWorkflowVersion,
                evaluatedIntakeSequenceButtonLabel,
            );
            return { isNextStepThankYouPage: false, isWaitingForNextInitiative: false };
        },
        [getStepItemInterfaceById, getStepInterfaceWidgets, updateSequenceStep],
    );

    return updateSummaryHandle;
};

export default useUpdateNextSequenceStep;
