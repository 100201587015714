import React, { useMemo } from 'react';

import WorkerRunsTableLoadingItem from './WorkerRunsTableLoadingItem';

import { range } from '@tonkean/utils';

interface Props {
    count?: number;
}

const WorkerRunsTableLoading: React.FC<Props> = ({ count = 10 }) => {
    const items = useMemo(() => range(count), [count]);

    return (
        <>
            {items.map((i) => (
                <WorkerRunsTableLoadingItem key={i} />
            ))}
        </>
    );
};

export default WorkerRunsTableLoading;
