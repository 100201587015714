import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import SingleWorkerRunFlowRunTableLogicRow from './SingleWorkerRunFlowRunTableLogicRow';
import SingleWorkerRunFlowRunTableRowGrid from './SingleWorkerRunFlowRunTableRowGrid';
import { ReactComponent as NotificationsIcon } from '../../../../../../../images/icons/notifications.svg';
import { ReactComponent as StatusIcon } from '../../../../../../../images/icons/status.svg';
import { ReactComponent as FieldsIcon } from '../../../../../../../images/icons/text-input-lg.svg';
import LogicType from '../../LogicType';

import { CollapsibleContent, HighlightableText } from '@tonkean/infrastructure';
import { LogicIcon } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { CustomTriggerActionType } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';
import { isLogicBlockTypeTrigger } from '@tonkean/utils';

const FlowRunTableLogicRow = styled(SingleWorkerRunFlowRunTableRowGrid)<{
    isGroupHeader: boolean;
    isLastRowOrGroup: boolean;
    shouldBeButton: boolean;
}>`
    ${({ shouldBeButton }) =>
        shouldBeButton &&
        css`
            tab-index: 0;
        `}
    ${({ shouldBeButton }) =>
        shouldBeButton &&
        css`
            role: 'button';
        `}
    ${({ isLastRowOrGroup }) =>
        !isLastRowOrGroup &&
        css`
            border-bottom: 1px solid ${Theme.colors.gray_300};
        `}
    ${({ isGroupHeader }) =>
        isGroupHeader &&
        css`
            background: rgba(248, 248, 248, 0.5);
        `}
    &:hover {
        background: rgba(248, 248, 248, 1);
    }

    ${styledFocus}
`;

const StyledLogicIcon = styled(LogicIcon)`
    ${({ integrationType }) =>
        !integrationType &&
        css`
            margin-right: 8px;
        `}
`;

const StateColorIndicator = styled.div<{ color: string }>`
    background-color: ${({ color }) => color};
    width: 7px;
    height: 7px;
    border-radius: 2px;
    margin: 0px 4px;
`;

const MainGroupContainer = styled.div<{ isActualMainGroup: boolean }>`
    ${({ isActualMainGroup }) =>
        isActualMainGroup &&
        css`
            border-bottom: 1px solid ${Theme.current.palette.general.border};
        `};
`;

function shouldLogicBeOpen(logic, infoToCustomTriggerMap): boolean {
    const isOpen = !!infoToCustomTriggerMap[logic.node.id];
    if (isOpen) {
        return true;
    }

    return logic.impacts?.some((childLogic) => shouldLogicBeOpen(childLogic, infoToCustomTriggerMap));
}

function postCustomTriggerActionsConfigurationTypeGuard<
    T extends Partial<Record<CustomTriggerActionType, { text: string; icon: React.ReactElement }>>,
>(configuration: T): T {
    return configuration;
}

const postCustomTriggerActionsConfiguration = postCustomTriggerActionsConfigurationTypeGuard({
    [CustomTriggerActionType.POST_ACTION_SEND_NOTIFICATION]: {
        text: 'Send Notification',
        icon: <NotificationsIcon />,
        fill: true,
    },
    [CustomTriggerActionType.POST_ACTION_STATE_UPDATE]: { text: 'Update Status ', icon: <StatusIcon />, fill: true },
    [CustomTriggerActionType.POST_ACTION_FIELDS_UPDATE]: { text: 'Update Fields', icon: <FieldsIcon />, fill: false },
});

interface Props {
    depth?: number;
    logic: any;
    workerRun: WorkerRun;
    infoToCustomTriggersMap: Record<string, any[] | undefined>;
    isLastChild?: boolean;
    searchTerm: string;
    isParentActionCompleted?: boolean;
}

const SingleWorkerRunFlowRunTableLogic: React.FC<Props> = ({
    depth = 0,
    logic,
    workerRun,
    infoToCustomTriggersMap,
    isLastChild,
    searchTerm,
    isParentActionCompleted,
}) => {
    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key !== 'Enter' && event.key !== ' ') {
            return;
        }

        setIsOpen(!isOpen);
    };

    const consts = useAngularService('consts');
    const logicBlockTypes = consts.getLogicBlockTypes();
    const logicBlockType = logic.node.customTriggerType && logicBlockTypes[logic.node.customTriggerType];
    const isTrigger = useMemo(() => isLogicBlockTypeTrigger(logicBlockType), [logicBlockType]);

    const customTriggerActionsOfLogicInfo = infoToCustomTriggersMap[logic.node.id];
    const filteredActionsInfo = useMemo(() => {
        return customTriggerActionsOfLogicInfo?.filter(
            (action) =>
                action.workerRunLogicActions.length === 0 ||
                !action.workerRunLogicActions.some((workerRunLogic) =>
                    Object.keys(postCustomTriggerActionsConfiguration).includes(workerRunLogic.customTriggerActionType),
                ),
        );
    }, [customTriggerActionsOfLogicInfo]);

    const logicInfo = filteredActionsInfo?.[0];

    const isActivatedOrContainActivatedLogic = useMemo(() => {
        return shouldLogicBeOpen(logic, infoToCustomTriggersMap);
    }, [logic, infoToCustomTriggersMap]);

    const [isOpen, setIsOpen] = useState<boolean>(isActivatedOrContainActivatedLogic);

    const isGroupHeader: boolean = depth === 0;

    const hasLogicInfo = !!logicInfo;
    useEffect(() => {
        if (searchTerm !== '') {
            setIsOpen(true);
            return;
        }

        setIsOpen(isActivatedOrContainActivatedLogic);
    }, [searchTerm, hasLogicInfo, isActivatedOrContainActivatedLogic]);

    const displayedChildren = useMemo(() => {
        return logic.impacts?.filter((impact) => !impact?.node?.isHidden);
    }, [logic.impacts]);

    const logicInlineActions = useMemo(() => {
        return customTriggerActionsOfLogicInfo?.filter(
            (action) => !filteredActionsInfo?.some((filteredAction) => action.id === filteredAction.id),
        );
    }, [customTriggerActionsOfLogicInfo, filteredActionsInfo]);

    const workflowVersionManager = useAngularService('workflowVersionManager');
    const getInnerActionNewState = useMemo(() => {
        if (
            logicInlineActions?.some(
                (logicInlineActionInfo) =>
                    logicInlineActionInfo.workerRunLogicActions[0].customTriggerActionType ===
                    CustomTriggerActionType.POST_ACTION_STATE_UPDATE,
            )
        ) {
            const version = workflowVersionManager.getCachedWorkflowVersion(logic.node.workflowVersion.id);

            if (version?.['stateIdToStateMap']) {
                return version['stateIdToStateMap'][logic.node.stateId];
            } else {
                return;
            }
        }
    }, [logic.node.stateId, logic.node.workflowVersion.id, logicInlineActions, workflowVersionManager]);

    return (
        <MainGroupContainer isActualMainGroup={isGroupHeader && !isLastChild}>
            <FlowRunTableLogicRow
                onClick={() => setIsOpen(!isOpen)}
                onKeyPress={onKeyDown}
                shouldBeButton={displayedChildren?.length !== 0}
                isLastRowOrGroup={(isLastChild && displayedChildren?.length === 0) || (isGroupHeader && !isOpen)}
                isGroupHeader={isGroupHeader}
            >
                <SingleWorkerRunFlowRunTableLogicRow
                    logic={logic}
                    logicInfo={logicInfo}
                    workerRun={workerRun}
                    depth={depth}
                    displayedChildren={displayedChildren}
                    isActivatedOrContainActivatedLogic={isActivatedOrContainActivatedLogic}
                    isOpen={isOpen}
                    logicType={isTrigger ? LogicType.TRIGGER : LogicType.ACTION}
                    displayName={logic.node.displayName}
                    isParentActionCompleted={isParentActionCompleted}
                    icon={
                        <StyledLogicIcon
                            integrationType={logic.node.integrationType}
                            logicBlockType={logicBlockType}
                            projectIntegrationId={logic.node.projectIntegrationIds?.[0]}
                        />
                    }
                >
                    <HighlightableText highlightText={searchTerm} text={logic.node.displayName} inline />
                </SingleWorkerRunFlowRunTableLogicRow>

                {logicInlineActions?.map((logicInlineActionInfo) => (
                    <SingleWorkerRunFlowRunTableLogicRow
                        key={logicInlineActionInfo.id}
                        logic={logic}
                        logicInfo={logicInlineActionInfo}
                        workerRun={workerRun}
                        depth={depth + 2}
                        isActivatedOrContainActivatedLogic={isActivatedOrContainActivatedLogic}
                        isOpen={isOpen}
                        logicType={LogicType.INLINE_ACTION}
                        displayName={
                            postCustomTriggerActionsConfiguration[
                                logicInlineActionInfo.workerRunLogicActions[0].customTriggerActionType
                            ].text
                        }
                        icon={
                            postCustomTriggerActionsConfiguration[
                                logicInlineActionInfo.workerRunLogicActions[0].customTriggerActionType
                            ].icon
                        }
                        fillIcon={
                            postCustomTriggerActionsConfiguration[
                                logicInlineActionInfo.workerRunLogicActions[0].customTriggerActionType
                            ].fill
                        }
                        isInlineAction
                    >
                        {
                            postCustomTriggerActionsConfiguration[
                                logicInlineActionInfo.workerRunLogicActions[0].customTriggerActionType
                            ].text
                        }

                        {logicInlineActionInfo.workerRunLogicActions[0].customTriggerActionType ===
                            CustomTriggerActionType.POST_ACTION_STATE_UPDATE && (
                            <>
                                <span>:</span>
                                <StateColorIndicator color={getInnerActionNewState?.color || ''} />
                                <div>{getInnerActionNewState?.label}</div>
                            </>
                        )}
                    </SingleWorkerRunFlowRunTableLogicRow>
                ))}
            </FlowRunTableLogicRow>
            <CollapsibleContent open={isOpen}>
                {(displayedChildren as any[])?.map((impact, index) => (
                    <SingleWorkerRunFlowRunTableLogic
                        key={impact.node.id}
                        depth={logicInlineActions && logicInlineActions.length > 0 ? depth + 2 : depth + 1}
                        logic={impact}
                        workerRun={workerRun}
                        infoToCustomTriggersMap={infoToCustomTriggersMap}
                        isLastChild={(isGroupHeader || isLastChild) && index === displayedChildren?.length - 1}
                        searchTerm={searchTerm}
                        isParentActionCompleted={
                            isParentActionCompleted !== undefined
                                ? isParentActionCompleted &&
                                  (logicInfo?.completed !== undefined ? logicInfo.completed : false)
                                : logicInfo?.completed
                        }
                    />
                ))}
            </CollapsibleContent>
        </MainGroupContainer>
    );
};

export default SingleWorkerRunFlowRunTableLogic;
