import type HistoryField from '../entities/HistoryField';

/**
 * Get the matched entity title.
 *
 * @param historyField - the matched entity history field.
 * @param workflowVersionId - the workflow version id.
 * @param customTriggerManager - angular's custom trigger manager.
 */
function getHistoryMatchedEntityFieldName(
    historyField: HistoryField<any>,
    workflowVersionId: string | undefined,
    customTriggerManager,
): string {
    if (workflowVersionId && historyField.id.startsWith('CUTR')) {
        const customTrigger = customTriggerManager.getCachedCustomTrigger(workflowVersionId, historyField.id);
        return customTrigger?.displayName || historyField.displayName;
    } else if (historyField?.fieldDefinition?.definition?.matchConfiguration?.performOnWorkerItem) {
        return `${historyField.displayName} (Root)`;
    } else if (historyField?.fieldDefinition?.definition?.matchConfiguration?.creatingCustomTriggerId) {
        const customTrigger = customTriggerManager.getCachedCustomTrigger(
            workflowVersionId,
            historyField?.fieldDefinition?.definition?.matchConfiguration?.creatingCustomTriggerId,
        );
        return customTrigger?.displayName || historyField.displayName;
    }

    // If it's a root element, it's name is being set in getHistoryInitiativeFields.
    // If it's a regular matched entity field, we should use the given name.
    return historyField.displayName;
}

export default getHistoryMatchedEntityFieldName;
