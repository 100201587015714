import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function SmartsheetSheetSelectorCtrl(
    $scope,
    $q,
    createProjectApis,
    oauthHandler,
    utils,
    projectManager,
    customFieldsManager,
    modal,
    requestThrottler,
    environment,
) {
    const ctrl = this;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,
        configuredInWorkflowVersionId: ctrl.configuredInWorkflowVersionId,
        displayColumnSelection: ctrl.displayColumnSelection,
        configSelections: {},

        connecting: false,
        error: null,
        selectedColumnsFlags: {},
        selectedColumns: {},
        filteredColumns: [],
        spreadsheets: null,
        selections: null,
        isEdit: false,

        // if this flag is true we show all columns, by default show only columns with example
        showAllColumns: false,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.projectIntegration) {
            initializeEditMode();
        }
    };

    $scope.cancel = function () {
        if (ctrl.onCancel) {
            ctrl.onCancel();
        }
    };

    $scope.selectAllColumns = function () {
        if (!$scope.data.selectedColumnsFlags) {
            $scope.data.selectedColumnsFlags = {};
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const column = $scope.data.filteredColumns[i];

            // if we are not showing all the columns, dont mark as selected if the column isnt shown (has example)
            if (!$scope.data.showAllColumns && !column.example) {
                continue;
            }

            $scope.data.selectedColumnsFlags[column.value] = true;
        }
    };

    $scope.unselectAllColumns = function () {
        if (!$scope.data.selectedColumnsFlags) {
            $scope.data.selectedColumnsFlags = {};
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const column = $scope.data.filteredColumns[i];

            $scope.data.selectedColumnsFlags[column.value] = false;
        }
    };

    $scope.columnSelected = function (event) {
        event.stopPropagation();
    };

    $scope.authorize = function () {
        $scope.data.connecting = true;
        $scope.data.error = null;

        const redirectUri = environment.integrationKeysMap.smartsheetUri;

        oauthHandler
            .smartsheet(
                environment.integrationKeysMap.smartsheet,
                'READ_SHEETS WRITE_SHEETS ADMIN_WEBHOOKS ADMIN_SHEETS',
                redirectUri,
            )
            .then(function (code) {
                return $q.resolve({ code, redirectUri });
            })
            .then(function (config) {
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    'smartsheet',
                    config,
                    undefined,
                    undefined,
                );
            })
            .then(function (integration) {
                $scope.data.integration = integration;
                return createProjectApis.getAutoCompleteOptions(projectManager.project.id, integration.id, 'sheets');
            })
            .then(function (data) {
                $scope.data.spreadsheets = data.options;
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.smartsheetSheetsStep = 'selectSpreadSheet';
                $scope.data.connecting = false;
            });
    };

    $scope.onSearchChanged = function (queryString) {
        if (queryString.length > 2 || queryString === '') {
            $scope.data.spreadsheets = [];

            requestThrottler.do(
                'searchSheets',
                350,
                () => searchSheets(queryString),
                (sheets) => {
                    $scope.data.spreadsheets = sheets.options;
                },
            );
        }
    };

    function searchSheets(queryString) {
        return createProjectApis.getAutoCompleteOptions(
            projectManager.project.id,
            $scope.data.integration.id,
            'sheets',
            { name: queryString },
        );
    }

    $scope.changeUser = function () {
        $scope.data.integration = null;
        $scope.data.spreadsheets = null;
        $scope.authorize();
    };

    $scope.onSpreadSheetSelected = function (spreadsheet) {
        if (!spreadsheet || !spreadsheet.displayName) {
            return;
        }
        $scope.data.connecting = true;
        $scope.data.spreadsheet = spreadsheet;
        $scope.data.sheet = spreadsheet;

        $scope.data.smartsheetSheetsStep = 'setupSpreadSheet';

        const autoCompleteObject = {
            sheetId: $scope.data.spreadsheet.value,
        };

        return createProjectApis
            .getAutoCompleteOptions(
                projectManager.project.id,
                $scope.data.integration.id,
                'columns2',
                autoCompleteObject,
            )
            .then(function (data) {
                $scope.data.columns = data.options;
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.connecting = false;
            });
    };

    $scope.onColumnNameInputClick = function (event) {
        // stop propagation so the surrounding div wont get the event and activates its click (selecting the column)
        event.stopPropagation();
    };

    $scope.toggleShowAllColumns = function () {
        $scope.data.showAllColumns = !$scope.data.showAllColumns;
    };

    function saveColumnConfiguration() {
        $scope.data.filteredColumns = $scope.data.columns;

        if ($scope.data.configSelections.titleFieldColumn) {
            $scope.data.config.titleFieldColumn = $scope.data.configSelections.titleFieldColumn.value;
            removeFromFilteredColumns($scope.data.configSelections.titleFieldColumn);
        }
        if ($scope.data.configSelections.descriptionFieldColumn) {
            $scope.data.config.descriptionFieldColumn = $scope.data.configSelections.descriptionFieldColumn.value;
            removeFromFilteredColumns($scope.data.configSelections.descriptionFieldColumn);
        }
        if ($scope.data.configSelections.ownerEmailFieldColumn) {
            $scope.data.config.ownerEmailFieldColumn = $scope.data.configSelections.ownerEmailFieldColumn.value;
            // $scope.data.config.ownerNameFieldColumn = $scope.data.configSelections.ownerEmailFieldColumn.displayName;
            removeFromFilteredColumns($scope.data.configSelections.ownerEmailFieldColumn);
        }
        if ($scope.data.configSelections.ownerNameFieldColumn) {
            $scope.data.config.ownerNameFieldColumn = $scope.data.configSelections.ownerNameFieldColumn.value;
            removeFromFilteredColumns($scope.data.configSelections.ownerNameFieldColumn);
        }

        for (let i = 0; i < $scope.data.filteredColumns.length; i++) {
            const filteredColumn = $scope.data.filteredColumns[i];

            if (filteredColumn && filteredColumn.example && filteredColumn.example !== '') {
                $scope.data.selectedColumnsFlags[filteredColumn.value] = true;
                $scope.data.selectedColumns[filteredColumn.value] = filteredColumn.example;
            }
        }
    }

    function removeFromFilteredColumns(columnToRemove) {
        const index = $scope.data.filteredColumns.indexOf(columnToRemove);

        if (index > -1) {
            $scope.data.filteredColumns.splice(index, 1);
        }
    }

    function getFieldDefinitionsToCreate() {
        if (!$scope.data.selectedColumnsFlags) {
            return [];
        }

        const editedColumns = Object.keys($scope.data.selectedColumnsFlags);
        const fieldDefinitionsToCreate = [];

        for (const col of editedColumns) {
            if ($scope.data.selectedColumnsFlags[col]) {
                const definition = {
                    name: $scope.data.selectedColumns[col],
                    type: 'EXTERNAL',
                    targetType: 'COLUMN',
                    projectIntegrationId: $scope.data.projectIntegration.id,
                    definition: {
                        ExternalType: 'Row',
                        FieldName: col,
                        FieldLabel: col,
                    },
                    ranges: [],
                    fieldType: 'String',
                    updateable: false,
                };
                fieldDefinitionsToCreate.push(definition);
            }
        }

        return fieldDefinitionsToCreate;
    }

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }
        $scope.data.config = {};
        saveColumnConfiguration();

        if (
            ($scope.data.configuredInWorkflowVersionId || $scope.data.displayColumnSelection) &&
            $scope.data.smartsheetSheetsStep !== 'selectColumns'
        ) {
            $scope.data.filteredColumns = [];
            $scope.data.config = {};
            saveColumnConfiguration();
            $scope.data.smartsheetSheetsStep = 'selectColumns';

            return;
        }
        $scope.data.saving = true;

        if (!$scope.data.config) {
            $scope.data.config = {};
            saveColumnConfiguration();
        }

        const projectIntegrationDisplayName = $scope.data.spreadsheet.displayName;
        const projectData = {
            sheet: $scope.data.spreadsheet,
            config: $scope.data.config,
        };

        // NOTE: Since edit project integration is relying on the third parameter object to hold an array of projectDatas,
        // we inject it into the integration object, which does not have a project data property at all.
        $scope.data.integration.projectDatas = [projectData];

        let projectIntegrationPromise;
        const oldProject = projectManager.project;
        if ($scope.data.isEdit) {
            projectIntegrationPromise = createProjectApis.editProjectIntegration(
                projectManager.project.id,
                $scope.data.projectIntegration.id,
                $scope.data.integration
            );
        } else {
            projectIntegrationPromise = createProjectApis.createProjectIntegration(
                projectManager.project,
                $scope.data.integration,
                projectIntegrationDisplayName,
            );
        }

        projectIntegrationPromise.then((updatedProject) => {
            $scope.data.projectIntegration = utils.findFirst(
                updatedProject.integrations,
                (prin) =>
                    prin.id === $scope?.data?.projectIntegration?.id ||
                    !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
            );
            const fieldDefinitionsToCreate = getFieldDefinitionsToCreate();

            let createFieldsPromise = $q.resolve();
            if (
                $scope.data.configuredInWorkflowVersionId &&
                fieldDefinitionsToCreate &&
                fieldDefinitionsToCreate.length
            ) {
                createFieldsPromise = customFieldsManager.createMultipleFieldDefinitions(
                    $scope.data.configuredInGroupId,
                    fieldDefinitionsToCreate,
                    null,
                );
            }

            createFieldsPromise.then(() => {
                projectManager.getProjectData(true).then(() => {
                    $scope.data.saving = false;
                    if (ctrl.onProjectIntegrationCreated) {
                        ctrl.onProjectIntegrationCreated({
                            projectIntegration: $scope.data.projectIntegration,
                            fieldDefinitionsToCreate,
                        });
                    }
                });
            });
        });
    };

    $scope.onCancel = function () {
        if (ctrl.onClosed) {
            ctrl.onClosed();
        }
    };

    $scope.openFieldInspectModal = function (modelKeyToUpdate) {
        analyticsWrapper.track('Toggle field inspect modal', { category: 'Smartsheet selector', label: true });

        // Parse the columns to a dict so the inspector knows how to use it.
        // We pass that data as staticData - a different mode the inspector knows how to use.
        const columnsDict = parseColumnForFieldInspector($scope.data.columns);
        modal.openFieldInspectModal(
            null,
            null,
            null,
            (fieldName) =>
                ($scope.data.configSelections[modelKeyToUpdate] = utils.findFirst(
                    $scope.data.columns,
                    (column) => column.displayName === fieldName.displayName,
                )),
            columnsDict,
        );
    };

    /**
     * Parses the given columns array to a dictionary for the field inspector.
     * @returns {{}}
     */
    function parseColumnForFieldInspector(columns) {
        const columnsDict = {
            data: {},
            displayNameMap: {},
        };

        if (columns) {
            for (const column of columns) {
                if (!columnsDict.data[column.displayName]) {
                    columnsDict.data[column.displayName] = column.example;
                }
            }
        }

        return columnsDict;
    }

    /**
     * If component was given a project integration, will load the component's edit mode.
     */
    function initializeEditMode() {
        $scope.data.isEdit = true;

        $scope.data.integration = $scope.data.projectIntegration.integration;
        const projectData = $scope.data.projectIntegration.projectData.projectDatas[0];
        $scope.data.connecting = true;

        $scope.data.error = null;

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.projectIntegration.integration.id, 'sheets')
            .then(function (data3) {
                $scope.data.spreadsheets = data3.options;

                const currentSheetAutoCompleteOptions = {
                    name: projectData.sheet.displayName,
                };

                return createProjectApis
                    .getAutoCompleteOptions(
                        projectManager.project.id,
                        $scope.data.projectIntegration.integration.id,
                        'sheets',
                        currentSheetAutoCompleteOptions,
                    )
                    .then((data1) => {
                        for (let i = 0; i < data1.options.length; i++) {
                            const spreadsheet = data1.options[i];

                            if (projectData.sheet.value === spreadsheet.value) {
                                $scope.data.spreadsheet = spreadsheet;
                                $scope.data.sheet = projectData.sheet;

                                /* jshint ignore:start */
                                const autoCompleteObject = {
                                    sheetId: spreadsheet.value,
                                };

                                return createProjectApis
                                    .getAutoCompleteOptions(
                                        projectManager.project.id,
                                        $scope.data.projectIntegration.integration.id,
                                        'columns2',
                                        autoCompleteObject,
                                    )
                                    .then((data2) => {
                                        $scope.data.columns = data2.options;
                                        $scope.data.config = projectData.config;
                                        $scope.data.configSelections = {};

                                        if ($scope.data.config.titleFieldColumn) {
                                            const title = utils.findFirst(
                                                $scope.data.columns,
                                                (col) => col.value === $scope.data.config.titleFieldColumn,
                                            ).displayName;
                                            $scope.data.configSelections.titleFieldColumn = {
                                                displayName: title,
                                                value: $scope.data.config.titleFieldColumn,
                                            };
                                        }

                                        if ($scope.data.config.descriptionFieldColumn) {
                                            const desc = utils.findFirst(
                                                $scope.data.columns,
                                                (col) => col.value === $scope.data.config.descriptionFieldColumn,
                                            ).displayName;
                                            $scope.data.configSelections.descriptionFieldColumn = {
                                                displayName: desc,
                                                value: $scope.data.config.descriptionFieldColumn,
                                            };
                                        }

                                        if ($scope.data.config.ownerEmailFieldColumn) {
                                            const ownerEmail = utils.findFirst(
                                                $scope.data.columns,
                                                (col) => col.value === $scope.data.config.ownerEmailFieldColumn,
                                            ).displayName;
                                            $scope.data.configSelections.ownerEmailFieldColumn = {
                                                displayName: ownerEmail,
                                                value: $scope.data.config.ownerEmailFieldColumn,
                                            };
                                        }

                                        if ($scope.data.config.ownerNameFieldColumn) {
                                            const ownerName = utils.findFirst(
                                                $scope.data.columns,
                                                (col) => col.value === $scope.data.config.ownerNameFieldColumn,
                                            ).displayName;
                                            $scope.data.configSelections.ownerNameFieldColumn = {
                                                displayName: ownerName,
                                                value: $scope.data.config.ownerNameFieldColumn,
                                            };
                                        }
                                    });
                                /* jshint ignore:end */
                            }
                        }
                    });
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.smartsheetSheetsStep = 'setupSpreadSheet';
                $scope.data.connecting = false;
            });
    }
}

export default angular
    .module('tonkean.app')
    .controller('SmartsheetSheetSelectorCtrl', lateConstructController(SmartsheetSheetSelectorCtrl));
