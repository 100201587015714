import { useAngularService, useAngularWatch } from 'angulareact';
import isEquals from 'lodash.isequal';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import CategoricalFieldChartWidgetComponent from './CategoricalFieldChartWidgetComponent';
import type FieldChartWidgetConfiguration from './FieldChartWidgetConfiguration';
import type { ItemWidgetProps } from '../../WidgetModule';
import { ItemWidget } from '../../WidgetModule';

import { HistoricalFieldChartComponent } from '@tonkean/fields';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { ChartDataType, ChartDisplayType, ChartEmptyState } from '@tonkean/infrastructure';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { FieldInstance } from '@tonkean/tonkean-entities';

const CustomItemWidget = styled(ItemWidget)`
    overflow: visible;
    min-height: 45vh;
`;

const FieldChartWidget: React.FC<ItemWidgetProps<FieldChartWidgetConfiguration>> = ({
    initiative,
    widget,
    permission,
}) => {
    const projectManager = useAngularService('projectManager');
    const [groupsMap, setGroupMap] = useState({});
    useAngularWatch(() => {
        if (!isEquals(projectManager.groupsMap, groupsMap)) setGroupMap(projectManager.groupsMap);
    });
    const { workflowVersion } = useItemInterfaceContext();

    const globalFields = useMemo(() => {
        return Object.assign(
            {},
            ...Object.values(groupsMap).map((groupMap: any) => {
                if (workflowVersion?.workflowVersionType === WorkflowVersionType.PUBLISHED) {
                    return groupMap.globalFieldsMap;
                }
                return groupMap.globalFieldsDraftMap;
            }),
        );
    }, [groupsMap, workflowVersion?.workflowVersionType]);

    const fieldInstance = useMemo<FieldInstance | undefined>(() => {
        const isHistoricalChart = widget.configuration.chartDataType === ChartDataType.HISTORICAL;
        const configuredFields = isHistoricalChart
            ? widget.configuration.fields
            : widget.configuration.categoricalFields;

        if (configuredFields) {
            const fields = Object.keys(configuredFields);
            const field = fields[0];
            if (field) {
                if (globalFields?.[field]) {
                    return globalFields[field];
                }
                if (initiative?.defIdToFieldsMap) {
                    const fieldDefMap = initiative.defIdToFieldsMap[field];
                    if (fieldDefMap) {
                        return fieldDefMap[0];
                    }
                }
            }
        }
    }, [
        globalFields,
        initiative,
        widget.configuration.categoricalFields,
        widget.configuration.chartDataType,
        widget.configuration.fields,
    ]);

    const fieldLabel = useMemo<string | undefined>(() => {
        const isHistoricalChart = widget.configuration.chartDataType === ChartDataType.HISTORICAL;
        const configuredFields = isHistoricalChart
            ? widget.configuration.fields
            : widget.configuration.categoricalFields;
        const fields = configuredFields ? Object.keys(configuredFields) : [];
        const field: string | undefined = fields[0];
        if (field && configuredFields[field]?.label) {
            return configuredFields[field]?.label;
        } else {
            return fieldInstance?.fieldDefinition.name ?? '';
        }
    }, [
        fieldInstance?.fieldDefinition.name,
        widget.configuration.categoricalFields,
        widget.configuration.chartDataType,
        widget.configuration.fields,
    ]);

    const chartDisplayType: ChartDisplayType | undefined = useMemo(() => {
        return widget.configuration.chartDataType === ChartDataType.CATEGORICAL
            ? widget.configuration.chartCategoricalDisplayType
            : widget.configuration.chartHistoricalDisplayType;
    }, [
        widget.configuration.chartCategoricalDisplayType,
        widget.configuration.chartDataType,
        widget.configuration.chartHistoricalDisplayType,
    ]);

    const hasCategoricalFields: boolean = useMemo(() => {
        return !!(
            widget.configuration.categoricalFields && Object.entries(widget.configuration.categoricalFields).length > 0
        );
    }, [widget.configuration.categoricalFields]);

    return (
        <CustomItemWidget permission={permission}>
            {widget.configuration.chartDataType === ChartDataType.CATEGORICAL ? (
                hasCategoricalFields ? (
                    <CategoricalFieldChartWidgetComponent widget={widget} fieldInstance={fieldInstance} />
                ) : (
                    <ChartEmptyState
                        chartType={widget.configuration.chartCategoricalDisplayType ?? ChartDisplayType.PIE}
                    />
                )
            ) : (
                <HistoricalFieldChartComponent
                    fieldInstance={fieldInstance}
                    fieldName={fieldLabel ?? ''}
                    showStatusOverlay={widget.configuration.showStatusOverlay}
                    initiativeId={initiative?.id}
                    configuredChartType={chartDisplayType}
                />
            )}
        </CustomItemWidget>
    );
};

export default FieldChartWidget;
