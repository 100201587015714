import template from './tnkActivityItem.template.html?angularjs';
/**
 * Created by ShaLi on 9/24/15.
 */

function tnkActivityItem() {
    return {
        restrict: 'E',
        scope: {
            item: '=',
            notification: '=',
            miniMode: '=',
            targetId: '=',
            isNew: '=',
            customCss: '@',
            showLeftLine: '<',
            onClick: '&',
            onDelete: '<',
        },
        controller: 'ActivityItemCtrl',
        template,
    };
}
angular.module('tonkean.app').directive('tnkActivityItem', tnkActivityItem);
