import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import ContractFieldsMappingView from './ContractFieldsMappingView';
import useInitialModuleInputSourceContractConfiguration from './hooks/useInitialModuleInputSourceContractConfiguration';

import { useCustomTriggersOfTypeInWorkflowVersion, useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { useLazyTonkeanService, useTonkeanService } from '@tonkean/angular-hooks';
import { ContractSelector } from '@tonkean/contracts';
import {
    H4,
    LoadingCircle,
    Paragraph,
    SavingIndicator,
    SimpleErrorStateMessage,
    Spacer,
} from '@tonkean/infrastructure';
import {
    AutonomousSecondaryType,
    type ContractFieldMapping,
    type ContractSummary,
    CustomTriggerType,
    type TonkeanId,
    type TonkeanType,
    type WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { EMPTY_ARRAY } from '@tonkean/utils';

const Wrapper = styled.div`
    gap: 12px;
    display: flex;
    flex-direction: column;
`;

const Title = styled(H4)`
    display: flex;
    gap: 4px;
    align-items: 'center';
`;

const Description = styled(Paragraph)`
    margin-bottom: 12px;
`;

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionType: WorkflowVersionType | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    contracts: ContractSummary[] | undefined;
    loading: boolean;
    onConfigurationChanged: () => void;
}

const InputSourceSelectionContentConfiguration: React.FC<Props> = ({
    contracts,
    loading,
    workflowVersionType,
    workflowVersionId,
    groupId,
    onConfigurationChanged,
}) => {
    const groupManager = useAngularService('groupManager');

    const [
        { loading: loadingSettingInputSourceContact, error: errorLoadingSettingInputSourceContact },
        setInputSourceContract,
    ] = useLazyAsyncMethod(groupManager, 'setInputSourceContract');

    const [, updateContractFieldMapping] = useLazyTonkeanService('updateContractFieldMapping');

    const [selectedContractId, setSelectedContractId] = useState<TonkeanId<TonkeanType.CONTRACT> | undefined>();
    const [contractFieldsMapping, setContractFieldsMapping] = useState<ContractFieldMapping[]>();

    const [
        { loading: isLoadingMapping, data: contractFieldsMappingResponse, error: errorLoadingFieldsMapping },
        getContractFieldsMapping,
    ] = useLazyTonkeanService('getContractFieldsMapping');

    const onInitialContractLoaded = useCallback(
        (contractId: TonkeanId<TonkeanType.CONTRACT>) => {
            setSelectedContractId(contractId);

            if (contractId) {
                getContractFieldsMapping(groupId, workflowVersionType, workflowVersionId, contractId);
            }
        },
        [getContractFieldsMapping, groupId, workflowVersionId, workflowVersionType],
    );

    const [errorLoadingInitialContract, loadingInitialContractId] = useInitialModuleInputSourceContractConfiguration(
        groupId,
        workflowVersionType,
        workflowVersionId,
        onInitialContractLoaded,
    );

    useEffect(() => {
        setContractFieldsMapping(contractFieldsMappingResponse?.entities);
    }, [contractFieldsMappingResponse?.entities]);

    const {
        data: fieldDefinitions,
        loading: isLoadingFieldDefinitions,
        error: errorLoadingFieldDefinitions,
    } = useTonkeanService('getFieldDefinitionsByWorkflowVersionType', groupId, workflowVersionType, workflowVersionId);

    const {
        data: itemInterfaces,
        loading: isLoadingItemInterfaces,
        error: errorLoadingItemInterfaces,
    } = useTonkeanService(
        'getItemInterfacesSummaryByGroupIdOrByWorkflowVersionId',
        groupId,
        workflowVersionType,
        workflowVersionId,
    );

    const {
        data: intakeSequences,
        loading: isLoadingIntakeSequences,
        error: errorLoadingIntakeSequences,
    } = useCustomTriggersOfTypeInWorkflowVersion(
        workflowVersionId,
        CustomTriggerType.AUTONOMOUS,
        AutonomousSecondaryType.AUTONOMOUS_INTERFACE_SUBMITTED,
    );

    if (errorLoadingInitialContract) {
        return (
            <SimpleErrorStateMessage
                dataAutomation="error-loading-initial-contract"
                error={errorLoadingInitialContract}
            />
        );
    }

    if (errorLoadingFieldsMapping) {
        return (
            <SimpleErrorStateMessage dataAutomation="error-loading-field-mappings" error={errorLoadingFieldsMapping} />
        );
    }

    if (errorLoadingFieldDefinitions) {
        return (
            <SimpleErrorStateMessage
                dataAutomation="error-loading-field-definitions"
                error={errorLoadingFieldDefinitions}
            />
        );
    }

    if (errorLoadingItemInterfaces) {
        return (
            <SimpleErrorStateMessage
                dataAutomation="error-loading-item-interfaces"
                error={errorLoadingItemInterfaces}
            />
        );
    }

    if (errorLoadingIntakeSequences) {
        return (
            <SimpleErrorStateMessage
                dataAutomation="error-loading-intake-sequences"
                error={errorLoadingIntakeSequences}
            />
        );
    }

    return (
        <Wrapper data-automation="another-module-configuration-form">
            <Title $bold>
                Select a field contract for this module
                {loadingInitialContractId && <LoadingCircle />}
                <SavingIndicator
                    error={errorLoadingSettingInputSourceContact}
                    loading={loadingSettingInputSourceContact}
                />
            </Title>

            <Description $color={Theme.colors.gray_700}>
                A Tonkean field contract defines the inputs and outputs for this module, simplifying how consumers of
                the business logic can interact with it
            </Description>

            <ContractSelector
                contracts={contracts}
                loading={loading || loadingInitialContractId}
                selectedContract={selectedContractId}
                onChange={(newContractId) => {
                    setSelectedContractId(newContractId);
                    setInputSourceContract(groupId, newContractId);
                    setContractFieldsMapping([]);
                    onConfigurationChanged();

                    if (newContractId) {
                        getContractFieldsMapping(groupId, workflowVersionType, workflowVersionId, newContractId);
                    }
                }}
            />

            {!selectedContractId && (
                <Paragraph $color={Theme.colors.gray_700} data-automation="no-contract-selected-warning">
                    If no contract is defined, all of this module fields can be used as inputs and output
                </Paragraph>
            )}

            {selectedContractId && (
                <ContractFieldsMappingView
                    isLoadingFieldDefinitions={isLoadingFieldDefinitions}
                    isLoadingItemInterfaces={isLoadingItemInterfaces}
                    isLoadingIntakeSequences={isLoadingIntakeSequences}
                    isLoadingMapping={isLoadingMapping}
                    fieldsMapping={contractFieldsMapping || EMPTY_ARRAY}
                    fieldDefinitions={fieldDefinitions?.entities || EMPTY_ARRAY}
                    itemInterfaces={itemInterfaces?.entities || EMPTY_ARRAY}
                    intakeSequences={intakeSequences || EMPTY_ARRAY}
                    onChangeEach={(fieldMapping) => {
                        onConfigurationChanged();

                        const newMapping =
                            contractFieldsMapping?.map((fieldMap) => {
                                if (fieldMap.contractFieldId === fieldMapping.contractFieldId) {
                                    return fieldMapping;
                                } else {
                                    return fieldMap;
                                }
                            }) || [];

                        setContractFieldsMapping(newMapping);
                        updateContractFieldMapping(
                            groupId,
                            fieldMapping.contractFieldId,
                            fieldMapping.targetId,
                            fieldMapping.contractFieldType,
                        );
                    }}
                />
            )}

            <Spacer height={20} />
        </Wrapper>
    );
};

export default InputSourceSelectionContentConfiguration;
