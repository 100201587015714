import { useAngularService } from 'angulareact';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import FormBuilderSelectIcon from './FormBuilderSelect/FormBuilderSelectIcon';
import { ReactComponent as TestFormIcon } from '../../../../images/icons/test-form.svg';
import FormBuilderPreviewType from '../entities/FormBuilderPreviewType';

import { SimpleSelect, Tooltip } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { Form, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FormType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { StateLinkButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Wrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;

    height: 100%;

    display: flex;
    align-items: center;
`;

const PreviewOn = styled.span`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
    flex-shrink: 0;
`;

const SelectWrapper = styled.span`
    margin-left: 10px;
    width: 150px;
`;

const TestButton = styled(StateLinkButton)`
    margin-left: 8px;
`;

const DisabledButton = styled(Button)`
    margin-left: 8px;
`;

const ButtonContent = styled.span`
    height: 100%;

    display: flex;
    align-items: center;
`;

const DisabledButtonWrapper = styled.div``;

const options = [
    {
        value: FormBuilderPreviewType.Web,
        label: FormBuilderPreviewType.Web,
        icon: <FormBuilderSelectIcon label={FormBuilderPreviewType.Web} />,
    },
    {
        value: FormBuilderPreviewType.Slack,
        label: FormBuilderPreviewType.Slack,
        icon: <FormBuilderSelectIcon label={FormBuilderPreviewType.Slack} />,
    },
];

interface Props {
    selectedPreviewType: FormBuilderPreviewType;
    onPreviewTypeSelected: (preview: FormBuilderPreviewType) => void;
    form: Form;
    workflowVersionId: string;
    groupId: string | undefined;
}

const FormBuilderPreviewOn: React.FC<Props> = ({
    selectedPreviewType,
    onPreviewTypeSelected,
    form,
    workflowVersionId,
    groupId,
}) => {
    const projectManager = useAngularService('projectManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const groupInfoManager = useAngularService('groupInfoManager');
    const { isEditable } = useContext(FormBuilderContext);

    const { params, page, environmentIsActive } = useMemo(() => {
        if (!form || !workflowVersionId) {
            return { stateProps: {}, page: '' };
        }

        const formVersionType =
            workflowVersionManager.getCachedWorkflowVersion(workflowVersionId)?.workflowVersionType ===
            WorkflowVersionType.DRAFT
                ? WorkflowVersionType.DRAFT
                : WorkflowVersionType.PUBLISHED;

        const group = groupInfoManager.getGroupByIdFromCache(groupId as TonkeanId<TonkeanType.GROUP>);
        return {
            params: {
                projectId: projectManager.project.id,
                formId: form.id,
                formVersionType: formVersionType === WorkflowVersionType.DRAFT ? 'draft' : 'published',
                viewMode: form.formType === FormType.CREATE ? 'real' : 'preview',
            },
            environmentIsActive:
                group &&
                (formVersionType === WorkflowVersionType.DRAFT ? group.buildEnvironmentEnabled : group.workerEnabled),
            page: 'form',
        };
    }, [form, projectManager.project.id, workflowVersionId, groupInfoManager, groupId, workflowVersionManager]);

    return (
        <Wrapper>
            <PreviewOn>Preview On</PreviewOn>
            <SelectWrapper data-automation="form-builder-preview-on-container">
                <SimpleSelect
                    value={selectedPreviewType}
                    options={options}
                    onChange={onPreviewTypeSelected}
                    isSearchable={false}
                    thin
                />
            </SelectWrapper>
            {isEditable && environmentIsActive ? (
                <TestButton
                    data-automation="form-builder-preview-on-link-button"
                    state={page}
                    params={params}
                    size={ButtonSize.MEDIUM}
                    openInNewTab
                    outlined
                >
                    <ButtonContent>
                        <span className="tnk-icon">
                            <TestFormIcon />
                        </span>
                        Link
                    </ButtonContent>
                </TestButton>
            ) : (
                <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                    <DisabledButtonWrapper>
                        <DisabledButton size={ButtonSize.MEDIUM} outlined disabled>
                            <ButtonContent>
                                <span className="tnk-icon">
                                    <TestFormIcon />
                                </span>
                                Link
                            </ButtonContent>
                        </DisabledButton>
                    </DisabledButtonWrapper>
                </Tooltip>
            )}
        </Wrapper>
    );
};

export default FormBuilderPreviewOn;
