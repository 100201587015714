import template from './formSelector.template.html?angularjs';

/**
 * Component to select fields of integrations.
 */
angular.module('tonkean.app').component('tnkFormSelector', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        selectedFormId: '<',

        clearable: '<', // If true will be able to clear the selection
        hideCreateForms: '<',
        hideUpdateForms: '<',
        existingFormMatchedEntityFieldId: '<',
        openedFromCustomTriggerId: '<',
        validationObject: '<',

        onFormSelectedParameter: '<',

        doNotIncludeFormIdsSet: '<', // If given, will not include forms whose ids are in the doNotIncludeFormIdsSet collection.

        onFormCreated: '&',
        onFormSelected: '&',
        onFormSelectedOpenConfirmation: '&',
        onMatchedEntityCreatedOrUpdated: '&',
        showWorkflowFolderForms: '<',
        formType: '<?', // Is a Dialog or a WebForm
        formQuestionTypes: '<', // if exists, only show forms of specified question type (UPDATE_FIELDS,COLLECT_INNER_ITEMS...)

        automationIdentifier: '@',
        hideFileUploadForms: '<',
        openFieldModal: '<',
    },
    template,
    controller: 'FormSelectorCtrl',
});
