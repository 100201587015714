function TestWorkflowModalCtrl($scope, $uibModalInstance, projectManager, groupId) {
    $scope.pm = projectManager;

    $scope.data = {
        groupId,
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    $scope.onTestItemFinished = function () {
        $uibModalInstance.close();
    };
}

export default angular.module('tonkean.app').controller('TestWorkflowModalCtrl', TestWorkflowModalCtrl);
