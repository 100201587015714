import BuiltInListsUtils from '../../../../shared/services/builtInLists/builtInListsUtils';
import { TONKEAN_ENTITY_TYPE } from '@tonkean/constants';
import { getTonkeanEntityType } from '@tonkean/tonkean-utils';

// open the setup group in a modal
function SetupGroupModalCtrl(
    $scope,
    showGroupSettings,
    duplicateGroup,
    workersTemplateMode,
    isInline,
    emptyOnly,
    customTriggerManager,
    utils,
    customFieldsManager,
    formManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    $scope.init = function () {
        $scope.showGroupSettings = showGroupSettings;
        $scope.workersTemplateMode = workersTemplateMode;
        $scope.isInline = isInline;
        $scope.isModal = true;
        $scope.emptyOnly = emptyOnly;

        if (duplicateGroup) {
            $scope.isDuplicatedList = true;
            prepareDuplicateGroup(angular.copy(duplicateGroup));
        }
    };

    /**
     * Parses the definition of a column aggregation for a duplication of list.
     */
    function parseColumnAggregationForDuplication(definition) {
        if (definition.aggregatedDefinitionId) {
            // Parsing the aggregated column to be a built in list variable.
            definition.aggregatedDefinitionId = BuiltInListsUtils.variable(definition.aggregatedDefinitionId, true);
        }

        if (definition.aggregateQueryDefinition) {
            // Resetting the compiled query, so that the server regards the new definition.
            definition.aggregateQueryDefinition.compiledQuery = null;
            definition.aggregateQueryDefinition.compiledQueryTimestamp = null;
            definition.aggregateQueryDefinition.groupId = null;

            // Parsing the query to switch field definition ids to variables.
            parseTonkeanColumnAggregateQueryForCompilation(definition.aggregateQueryDefinition.query);
        }

        // Setting the group id to a variable, so that the correct group id is injected into the definition.
        definition.groupId = BuiltInListsUtils.variable('GROUP_ID', true);
    }

    /**
     * Prepares the needed group data for group duplication.
     * @param group - the group to parse into duplication data supplied to the setupGroupCtrl.
     */
    function prepareDuplicateGroup(group) {
        // The $scope.group reflects the desired groupSettings.
        $scope.group = {
            isDuplicatedList: true,
            duplicatedListWorkflowVersionId: workflowVersionManager.getDraftVersionFromCache(group.id).id,
            sourceGroupId: group.id,
            shortTitle: `Duplicate list: ${group.name}`,
            category: null,
            name: `Copy of ${group.name}`,
            visibilityType: group.visibilityType,
            members: group.members,
            notificationSettings: group.notificationSettings,
            visibleToOwner: group.visibleToOwner,
            states: workflowVersionManager.getDraftVersionFromCache(group.id).states,
            metadata: {
                builtInListId: group.metadata.builtInListId,
                defaultManualOwner: group.metadata.defaultManualOwner,
                states: workflowVersionManager.getDraftVersionFromCache(group.id).states,
            },
            doNotSetOwner: false,
            defaultOwnerId: null,
            workerType: group.workerType || null,
            columns: [],
            globalFields: [],
            globalFieldsDraft: [],
            customTriggers: [],
            customTriggersGraph: {},
            owners: group.owners,
        };

        // If we have a project integration connected to the group, that means we have a sync in the group,
        // and we need to show the user the preview step before creating the list.
        if (group.projectIntegration) {
            // Set the 'duplicateSync' property which tells the setupGroupCtrl we have a sync to duplicate.
            $scope.group.duplicateSync = {
                projectIntegration: syncConfigCacheManager.getSyncConfig(
                    workflowVersionManager.getDraftVersionFromCache(group.id).id,
                ).projectIntegration,
                viewType: syncConfigCacheManager.getSyncConfig(workflowVersionManager.getDraftVersionFromCache(group.id).id)
                    .viewType,
                viewData: syncConfigCacheManager.getSyncConfig(workflowVersionManager.getDraftVersionFromCache(group.id).id)
                    .viewData,
            };

            // Setting the owner and email fields to be null, so it won't be copied from the duplicated list.
            $scope.group.duplicateSync.viewData.ownerNameField = null;
            $scope.group.duplicateSync.viewData.ownerEmailField = null;
            $scope.group.steps = [
                {
                    type: 'preview',
                    data: {},
                },
            ];
        }

        // Update custom triggers keys
        if (
            customTriggerManager.workflowVersionIdToCustomTriggersMap &&
            customTriggerManager.workflowVersionIdToCustomTriggersMap[
                workflowVersionManager.getDraftVersionFromCache(group.id).id
            ] &&
            customTriggerManager.workflowVersionIdToCustomTriggersMap[
                workflowVersionManager.getDraftVersionFromCache(group.id).id
            ].length
        ) {
            $scope.group.customTriggers = customTriggerManager.workflowVersionIdToCustomTriggersMap[
                workflowVersionManager.getDraftVersionFromCache(group.id).id
            ].map((customTrigger) => {
                customTrigger.key = customTrigger.id;

                // Replace variables in actions
                if (customTrigger.customTriggerActions && customTrigger.customTriggerActions.length) {
                    customTrigger.customTriggerActions = customTrigger.customTriggerActions.map((action) => {
                        return {
                            type: action.customTriggerActionType,
                            definition: replaceVariablesRecursively(action.customTriggerActionDefinition),
                        };
                    });
                }

                if (customTrigger.stateId) {
                    const state = utils.findFirst(
                        workflowVersionManager.getDraftVersionFromCache($scope.group.id).states,
                        (state) => state.id === customTrigger.stateId,
                    );
                    if (state) {
                        const key = `STATE_${state.label.toUpperCase().replaceAll(' ', '_')}`;
                        customTrigger.stateId = BuiltInListsUtils.variable(key, true);
                    }
                }
                return customTrigger;
            });
        }

        // Update custom trigger graph node variables
        if (
            customTriggerManager.workflowVersionIdToCustomTriggersGraph[
                workflowVersionManager.getDraftVersionFromCache(group.id).id
            ] &&
            customTriggerManager.workflowVersionIdToCustomTriggersGraph[
                workflowVersionManager.getDraftVersionFromCache(group.id).id
            ].impacts
        ) {
            // Copy inner nodes because the first node is root.
            $scope.group.customTriggersGraph.node =
                customTriggerManager.workflowVersionIdToCustomTriggersGraph[
                    workflowVersionManager.getDraftVersionFromCache(group.id).id
                ].node;
            $scope.group.customTriggersGraph.impacts = customTriggerManager.workflowVersionIdToCustomTriggersGraph[
                workflowVersionManager.getDraftVersionFromCache(group.id).id
            ].impacts.map((node) => {
                return replaceNodesWithVariablesRecursively(node);
            });
        }

        const columnFieldDefinitions =
            customFieldsManager.selectedColumnFieldsMap[workflowVersionManager.getDraftVersionFromCache(group.id).id];

        // Copying the column field definitions in the group.
        if (columnFieldDefinitions) {
            for (const fieldDefinitionObject of columnFieldDefinitions) {
                // We don't care for hidden fields, so we continue to next iteration if it's a hidden field.
                if (fieldDefinitionObject) {
                    const definition = fieldDefinitionObject.definition;

                    // Specific handling for the different types of field definitions.
                    switch (fieldDefinitionObject.type) {
                        case 'TNK_COLUMN_FORMULA':
                            if (definition) {
                                switch (definition.formulaType) {
                                    case 'STRUCTURED':
                                        if (definition.formulaOperator === 'INNER_TRACK_AGGREGATION') {
                                            definition.groupId = BuiltInListsUtils.variable('GROUP_ID', true);
                                            parseColumnAggregationForDuplication(
                                                definition.innerTracksAggregationDefinition,
                                            );
                                        } else {
                                            // Replacing column A and column B with a variable so we can inject it later inside the definition.
                                            if (
                                                getTonkeanEntityType(
                                                    definition.columnASelection.fieldDefinitionId,
                                                ) === TONKEAN_ENTITY_TYPE.FIELD_DEFINITION
                                            ) {
                                                definition.columnASelection.fieldDefinitionId =
                                                    BuiltInListsUtils.variable(
                                                        definition.columnASelection.fieldDefinitionId,
                                                        true,
                                                    );
                                            }
                                            if (
                                                getTonkeanEntityType(
                                                    definition.columnBSelection.fieldDefinitionId,
                                                ) === TONKEAN_ENTITY_TYPE.FIELD_DEFINITION
                                            ) {
                                                definition.columnBSelection.fieldDefinitionId =
                                                    BuiltInListsUtils.variable(
                                                        definition.columnBSelection.fieldDefinitionId,
                                                        true,
                                                    );
                                            }
                                        }
                                        break;
                                }
                            }
                            break;
                    }

                    // The server requires a projectIntegrationId property, while we have the whole projectIntegration.
                    if (fieldDefinitionObject.projectIntegration) {
                        fieldDefinitionObject.projectIntegrationId = fieldDefinitionObject.projectIntegration.id;
                    }

                    // We set the key of the field definition to be the field definition id so later, the key metric can evaluate, if needed, to the created fields.
                    fieldDefinitionObject.key = fieldDefinitionObject.id;

                    // Setting the id field to null, just to make sure we're not doing any update on existing field definition here.
                    fieldDefinitionObject.id = null;

                    $scope.group.columns.push(fieldDefinitionObject);
                }
            }
        }

        // Copying the global field definitions in the group.
        if (group.globalFields) {
            for (let i = 0; i < group.globalFields.length; i++) {
                const fieldDefinitionObject = group.globalFields[i].fieldDefinition;
                if (fieldDefinitionObject) {
                    // We need special copy logic per field definition type.
                    switch (fieldDefinitionObject.type) {
                        case 'MANUAL':
                            // If it's a manual field, we want to copy the value of the field as well.
                            if (group.globalFieldsMap[fieldDefinitionObject.id]) {
                                fieldDefinitionObject.manualValue =
                                    group.globalFieldsMap[fieldDefinitionObject.id].value;
                            }
                            break;

                        case 'TNK_COLUMN_AGGREGATE':
                            if (fieldDefinitionObject.definition) {
                                parseColumnAggregationForDuplication(fieldDefinitionObject.definition);
                            }
                            break;
                    }

                    // The server requires a projectIntegrationId property, while we have the whole projectIntegration.
                    if (fieldDefinitionObject.projectIntegration) {
                        fieldDefinitionObject.projectIntegrationId = fieldDefinitionObject.projectIntegration.id;
                    }

                    // Setting the id field to null, just to make sure we're not doing any update on existing field definition here.
                    fieldDefinitionObject.id = null;

                    $scope.group.globalFields.push(fieldDefinitionObject);
                }
            }
        }

        formManager
            .getAllWorkerForm(workflowVersionManager.getDraftVersionFromCache(group.id).id)
            .then((forms) => ($scope.group.forms = forms));
    }

    function doesStringContainTonkeanId(str) {
        return (
            str.includes('FIDE') ||
            str.includes('CUTR') ||
            str.includes('PRIN') ||
            str.includes('PRSN') ||
            str.includes('GRUP')
        );
    }

    function replaceVariablesRecursively(obj) {
        if (!obj) {
            return obj;
        }

        if (typeof obj === 'string') {
            if (doesStringContainTonkeanId(obj)) {
                // Replace all field definitions or custom triggers
                // with variables
                let isExpression = false;
                let isVariable = false;

                // Regex explanation:
                // ((?:(?:FIDE)|(?:CUTR)|(?:PRIN)) -> look for one of these sequences of chars
                // [\da-zA-Z]+ -> followed by any number or letter, as many times as possible
                let value = obj.replace(
                    /((?:(?:FIDE)|(?:CUTR)|(?:PRIN)|(?:PRSN)|(?:GRUP))[\da-zA-Z]+)/g,
                    function (match) {
                        // If its a person, no need to replace cus its the same project (ppl are the same)
                        // or if its a project integration, also the same in project
                        if (match.indexOf('PRSN') === 0 || match.indexOf('PRIN') === 0) {
                            return match;
                        }
                        if (match.indexOf('GRUP') === 0) {
                            return BuiltInListsUtils.variable('GROUP_ID', true);
                        }

                        // If the variable is the whole string return variable
                        if (match.length === obj.length) {
                            isVariable = true;
                            return match;
                        } else {
                            isExpression = true;
                            return `expressionVariable(${match})`;
                        }
                    },
                );

                // Surrond the whole string with something the app can parse
                if (isExpression) {
                    value = `TNK_VALUE_EXPRESSION(${value})`;
                }

                // If it was marked as a variable exchange the string value to a variable object
                if (isVariable) {
                    value = BuiltInListsUtils.variable(value, true);
                }

                return value;
            } else {
                // normal string
                return obj;
            }
        }

        // Cache the original set of properties, because they might change if one of them is a tonkean id that needs to be replaced
        const propertiesKeys = Object.keys(obj);
        for (const propertyKey of propertiesKeys) {
            let value = obj[propertyKey];

            if (value) {
                switch (typeof value) {
                    case 'object':
                        if (Array.isArray(value)) {
                            for (let i = 0; i < value.length; i++) {
                                value[i] = replaceVariablesRecursively(value[i]);
                            }
                        } else {
                            replaceVariablesRecursively(value);
                        }
                        break;
                    case 'string':
                        value = replaceVariablesRecursively(value);
                        break;
                }
            }

            // If the key of the property is a tonkean id, signal that this property's key needs to be replaces
            if (doesStringContainTonkeanId(propertyKey)) {
                const targetKeyName = propertyKey;
                obj[targetKeyName] = {
                    replaceKey: true,
                    value,
                };

                // Delete old property that has been replaced (so we won't have tonkean ids in the template)
                delete obj[propertyKey];
            } else {
                obj[propertyKey] = value;
            }
        }

        return obj;
    }

    /**
     * Parsing the query of the tonkean column aggregate, so that everywhere a field definition id is mentioned, we change it to use a built in list variable.
     */
    function parseTonkeanColumnAggregateQueryForCompilation(query) {
        if (!query) {
            return;
        }

        // Going through the filters.
        if (query.filters) {
            for (let i = 0; i < query.filters.length; i++) {
                const filter = query.filters[i];

                if (
                    filter &&
                    filter.fieldName &&
                    getTonkeanEntityType(filter.fieldName) ===
                    TONKEAN_ENTITY_TYPE.FIELD_DEFINITION
                ) {
                    filter.fieldName = BuiltInListsUtils.variable(filter.fieldName, true);
                }
            }
        }

        // Going through the inner queries.
        if (query.queries) {
            for (let i = 0; i < query.queries.length; i++) {
                parseTonkeanColumnAggregateQueryForCompilation(query.queries[i]);
            }
        }
    }

    /**
     * Replace node with variable object
     */
    function replaceNodesWithVariablesRecursively(node) {
        if (node.node) {
            node.node = BuiltInListsUtils.variable(node.node.id, true);
        }

        for (let i = 0; i < node.impacts.length; i++) {
            const impact = node.impacts[i];
            replaceNodesWithVariablesRecursively(impact);
        }

        return node;
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('SetupGroupModalCtrl', SetupGroupModalCtrl);
