/**
 * Created by sagieliyahu on 22/12/15.
 */

import { getSubscriptionTiers } from '@tonkean/constants';

/**
 * Validates the user has permissions for the feature, if it doesn't shows a relevant modal.
 * This has 2 modes, string mode and condition mode.
 * If string mode will match the value in tnk-require to known licenses.
 * If condition mode will watch the condition, when it evaluates to false, block the action
 */
function tnkRequire($rootScope, projectManager, modalUtils, utils) {
    const tiers = getSubscriptionTiers();
    const notLicensedOrFree = () => !projectManager.isUserLicensed || projectManager.project.isLimitedLicense;
    const validators = [
        {
            name: 'free',
            validate() {
                return false;
            },
            watch: 'pm.isUserLicensed',
            onClick() {},
        },
        {
            name: 'license',
            validate() {
                // fail if user is not licensed (project is licensed, but user isn't).
                // If no expiration date we are on enterprise plan, so everything should be allowed.
                return !projectManager.project.isEnterprise && notLicensedOrFree();
            },
            watch: 'pm.isUserLicensed && pm.isLimitedLicense',
            onClick() {
                modalUtils.openRequiresUpgrade();
            },
        },
        {
            name: 'fullLicense',
            validate() {
                // fail if user is not licensed, if in a trial but not licensed
                return (
                    !projectManager.project.isEnterprise &&
                    (notLicensedOrFree() || (projectManager.project.isInTrial && !projectManager.project.licensed))
                );
            },
            watch: 'pm.isUserLicensed',
            onClick() {
                modalUtils.openRequiresUpgrade();
            },
        },
        {
            name: 'standardLicenseNoTrial',
            validate() {
                // fail if user is not licensed, if in a trial but not licensed or licensed but it's basic
                return (
                    !projectManager.project.isEnterprise &&
                    (notLicensedOrFree() ||
                        (projectManager.project.isInTrial && !projectManager.project.licensed) ||
                        (projectManager.project.license && projectManager.project.license.tier === tiers.BASIC.apiName))
                );
            },
            watch: 'pm.isUserLicensed',
            onClick() {
                modalUtils.openRequiresUpgrade(`${tiers.STANDARD.displayName} (or higher)`);
            },
        },
        {
            name: 'standardLicense',
            validate() {
                // fail if user is not licensed or licensed but it's basic
                return (
                    !projectManager.project.isEnterprise &&
                    (notLicensedOrFree() ||
                        (projectManager.project.license && projectManager.project.license.tier === tiers.BASIC.apiName))
                );
            },
            watch: 'pm.isUserLicensed',
            onClick() {
                modalUtils.openRequiresUpgrade(`${tiers.STANDARD.displayName} (or higher)`);
            },
        },
        {
            name: 'premiumLicense',
            validate() {
                // fail if user is not licensed, if in a trial but not licensed or licensed but it's not premium
                return (
                    !projectManager.project.isEnterprise &&
                    (notLicensedOrFree() ||
                        (projectManager.project.isInTrial && !projectManager.project.licensed) ||
                        (projectManager.project.license &&
                            projectManager.project.license.tier !== tiers.PREMIUM.apiName))
                );
            },
            watch: 'pm.isUserLicensed',
            onClick() {
                modalUtils.openRequiresUpgrade(tiers.PREMIUM.displayName);
            },
        },
    ];
    return {
        restrict: 'A',
        scope: {},
        priority: -1, // Set priority to lower than that of ngClick so our click handler runs first (lower priority runs link function first).
        link(scope, element, attrs) {
            scope.isAlreadySet = angular.isDefined(attrs.tnkRequireSet) && attrs.tnkRequireSet;
            const givenParam = attrs.tnkRequire.toLowerCase();
            // If the attribute for condition is undefined or false, treat it as a string
            const isParamString =
                !angular.isDefined(attrs.tnkRequireIsCondition) || !(attrs.tnkRequireIsCondition === 'true');
            const modalText = angular.isDefined(attrs.tnkRequireModalText) ? attrs.tnkRequireModalText : undefined;
            scope.requestedValidatorName = isParamString ? givenParam : null;
            scope.element = element;

            if (!scope.isAlreadySet) {
                if (isParamString) {
                    const appliedValidators = validators.filter(function (v) {
                        return v.name.toLowerCase() === scope.requestedValidatorName;
                    });

                    const watchGroup = appliedValidators.map(function (v) {
                        return v.watch;
                    });

                    // Add a listener to the rootScope and register to remove it on destroy.
                    scope.watchListener = $rootScope.$watchGroup(watchGroup, onValidateWatch);
                    scope.$on('$destroy', function () {
                        // Calling the registered watch listener will remove it from watching.
                        scope.watchListener();
                    });
                } else {
                    // If its not a string, watch the value, when it changes run the validation watch again
                    attrs.$observe('tnkRequire', function (val) {
                        scope.conditionParam = val === 'false';
                        onValidateWatch();
                    });
                }

                // set flag so no infinate loop
                attrs.$set('tnkRequireSet', 'true');
            }

            // Add event listener on click in the capture phase.
            // This way, block all clicks from js, angular and 3rd party libraries (like ui-router)
            scope.element[0].addEventListener('click', blockClickIfNeeded, true);
            // Initialize the isBlocking flag to false, toggle it when block is needed.
            scope.isBlocking = false;

            scope.onHandleTnkRequiredClick = function ($event, validator) {
                $event.preventDefault();

                if (validator) {
                    validator.onClick(scope, attrs);
                }

                return false;
            };

            /**
             * Block all clicks and call the tnk-require handler if the isBlocking flag is true
             * @param event
             * @returns {boolean}
             */
            function blockClickIfNeeded(event) {
                if (scope.isBlocking) {
                    event.stopImmediatePropagation();
                    event.stopPropagation();
                    event.preventDefault();
                    scope.onHandleTnkRequiredClick(event, scope.validator);
                    return false;
                }
            }

            function onValidateWatch() {
                let validator;
                if (isParamString) {
                    validator = utils.findFirst(validators, function (v) {
                        return scope.requestedValidatorName === v.name.toLowerCase() && v.validate(scope);
                    });
                } else {
                    validator = scope.conditionParam
                        ? {
                              onClick: () => modalUtils.openRequiresUpgrade(modalText),
                          }
                        : null;
                }

                // Cache validator (or clear it) for when we need to block a click.
                scope.validator = validator;

                if (validator) {
                    // Activate directive.
                    scope.isBlocking = true;
                    scope.element.addClass('require-tnk');
                } else {
                    // Deactivate directive.
                    scope.isBlocking = false;
                    scope.element.removeClass('require-tnk');

                    // rendering using angular to restore the ng-click (that we disabled using the unbind)
                    /** ********************
                     * removed this because this
                     * A. didnt seem to do anything
                     * B. messed up any angular binding in child elements (they would bind once but didnt have a watch on them)
                     */
                    // $compile(scope.element)(scope.$parent);
                }
            }
        },
    };
}

export default angular.module('tonkean.app').directive('tnkRequire', tnkRequire);
