import React, { useMemo } from 'react';
import styled from 'styled-components';

import getAdminsAsTextObject from '../../../ProjectIntegrationPageModule/utils/getAdminsAsTextObjects';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { EnterpriseComponent, EnterpriseComponentType, Person } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const NotAllowedMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 80%;
    width: 600px;
    margin: 0 auto;
    grid-column: auto / span 2;
`;

const NotAllowedShortExplanation = styled.div`
    font-size: 18px;
    color: ${Theme.colors.gray_500};
`;

const NotAllowedDetailedExplanation = styled.div`
    margin-top: 8px;
    font-size: 12px;
    color: ${Theme.colors.gray_500};
`;

interface Props {
    enterpriseComponent: EnterpriseComponent;
    enterpriseComponentType: EnterpriseComponentType;
    projectId: string;
    projectOwners: Person[];
}

const EnterpriseComponentNotAllowed: React.FC<Props> = ({
    enterpriseComponent,
    enterpriseComponentType,
    projectId,
    projectOwners,
}) => {
    // getting fixed board admins with prefix
    const boardAdminsAsTextObject = useMemo(() => getAdminsAsTextObject(projectOwners), [projectOwners]);

    const { loading: loadingAdminsResult, data: adminsResult } = useTonkeanService(
        'getEnterpriseComponentAdmins',
        enterpriseComponent.id,
        enterpriseComponentType,
        projectId,
    );

    const itAdminsAsTextObject = useMemo(() => {
        if (!adminsResult) {
            return [];
        }
        const boardAdminIds = new Set(boardAdminsAsTextObject.map((admin) => admin.id));
        const projectIntegrationAdminsWithoutCreator = adminsResult.filter(
            (admin: Person) => !boardAdminIds.has(admin?.id),
        );
        return getAdminsAsTextObject(projectIntegrationAdminsWithoutCreator);
    }, [boardAdminsAsTextObject, adminsResult]);

    return (
        <NotAllowedMessage>
            <NotAllowedShortExplanation>
                You are not allowed to view {enterpriseComponent.displayName}
            </NotAllowedShortExplanation>
            <NotAllowedDetailedExplanation>
                You have to be an admin in order to be able to view and edit this enterprise component. Please contact
                the board owner{boardAdminsAsTextObject.length > 1 ? 's ' : ' '}
                {boardAdminsAsTextObject.map((adminTextObject) => (
                    <React.Fragment key={adminTextObject.id}>
                        {adminTextObject.prefix}
                        <a href={`mailto:${adminTextObject.email}`}>{adminTextObject.name}</a>
                    </React.Fragment>
                ))}
                {!loadingAdminsResult && itAdminsAsTextObject?.length > 0 && (
                    <>
                        {' '}
                        or one of the IT admin{itAdminsAsTextObject.length > 1 ? 's ' : ' '}
                        {itAdminsAsTextObject.map((adminTextObject) => (
                            <React.Fragment key={adminTextObject.id}>
                                {adminTextObject.prefix}
                                <a href={`mailto:${adminTextObject.email}`}>{adminTextObject.name}</a>
                            </React.Fragment>
                        ))}
                    </>
                )}{' '}
                to get access.
            </NotAllowedDetailedExplanation>
        </NotAllowedMessage>
    );
};

export default EnterpriseComponentNotAllowed;
