import type ItemInterface from './ItemInterface';
import type ItemInterfaceHeaderThemeConfiguration from './ItemInterfaceHeaderThemeConfiguration';
import type { FullProject } from '../Project';

export const useItemInterfaceHeaderThemeConfiguration = (
    itemInterface: ItemInterface,
    project: FullProject,
): ItemInterfaceHeaderThemeConfiguration => {
    const projectThemeConfiguration = project.themeConfiguration;
    if (project.forceThemeConfiguration) {
        return {
            ...itemInterface.themeConfiguration,
            headerBackgroundColor: projectThemeConfiguration.primaryColor,
            primaryColor:
                projectThemeConfiguration.primaryColor || itemInterface.themeConfiguration.headerBackgroundColor,
            logoUrl: projectThemeConfiguration.logoUrl,
        };
    }

    return itemInterface.themeConfiguration;
};
