/**
 * Counts how many filters in the query.
 */
export function countQueryFilters(query: any) {
    if (!query?.filters?.length) {
        return 0;
    }

    let filterCounter = query.filters.length;

    if (query.queries?.length) {
        for (let i = 0; i < query.queries.length; i++) {
            filterCounter += countQueryFilters(query.queries[i]);
        }
    }

    return filterCounter;
}
