import toFixedWithoutRounding from './toFixedWithoutRounding';

const formatDecimalPlaces = (decimalPlaces: number, includeTrailingZeros: boolean, number: number) => {
    const parsedNumber = toFixedWithoutRounding(number, decimalPlaces);

    if (includeTrailingZeros) {
        return parsedNumber;
    } else {
        return Number(parsedNumber).toLocaleString('en-US', { maximumFractionDigits: decimalPlaces });
    }
};

export default formatDecimalPlaces;
