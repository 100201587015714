import template from './tnkTestWorkflow.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkTestWorkflow', {
    bindings: {
        groupId: '@',
        onTestItemFinished: '&',
        testButtonSecondary: '<',
    },
    template,
    controller: 'TestWorkflowCtrl',
});
