import type { IComponentOptions } from 'angular';
import React from 'react';

import reactToAngular from './reactToAngular';

export const defaultLazyFallback = (
    <div className="common-height-full flex mod-align-center mod-center mod-justify-center">
        <i className="loading-large" />
    </div>
);

function lazyReactToAngular<PROPS = Record<any, any>>(
    reactComponentDynamicImport: () => Promise<{ default: React.ComponentType<PROPS> }>,
    propNames?: (keyof PROPS | 'children')[],
    fallback: React.ReactNode = defaultLazyFallback,
): IComponentOptions {
    const ReactComponent = React.lazy(reactComponentDynamicImport);

    const EntryPoint: React.FC<PROPS> = (props) => {
        return (
            <React.Suspense fallback={fallback}>
                <ReactComponent {...(props as any)} />
            </React.Suspense>
        );
    };

    return reactToAngular(EntryPoint, propNames);
}

export default lazyReactToAngular;
