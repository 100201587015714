import type { ItemInterfaceWidgetOptionsConfiguration } from '@tonkean/tonkean-entities';
import {
    ItemInterfaceWidgetConfigurationDisplayConditionsMethod,
    ItemInterfaceWidgetConfigurationDisplayMode,
    ItemInterfaceWidgetConfigurationUserAccessLevel,
} from '@tonkean/tonkean-entities';

const userAccessLevelOptions: ItemInterfaceWidgetOptionsConfiguration[] = [
    { value: ItemInterfaceWidgetConfigurationUserAccessLevel.VIEW, label: 'View only' },
    { value: ItemInterfaceWidgetConfigurationUserAccessLevel.EDIT, label: 'View & Edit' },
    { value: ItemInterfaceWidgetConfigurationUserAccessLevel.EDIT_AND_CREATE, label: 'View, Edit, & Create' },
];

const displayModeOptions: ItemInterfaceWidgetOptionsConfiguration[] = [
    { value: ItemInterfaceWidgetConfigurationDisplayMode.AUTO, label: 'Auto (adjusts to device width)' },
    { value: ItemInterfaceWidgetConfigurationDisplayMode.TABLE, label: 'Table' },
    { value: ItemInterfaceWidgetConfigurationDisplayMode.CARDS, label: 'Cards' },
];

const displayConditionMethod: ItemInterfaceWidgetOptionsConfiguration[] = [
    {
        value: ItemInterfaceWidgetConfigurationDisplayConditionsMethod.ON_LOAD,
        label: 'Evaluate conditions on the first load',
    },
    { value: ItemInterfaceWidgetConfigurationDisplayConditionsMethod.CONTINUOUSLY, label: 'Continuously evaluate' },
];

const WidgetConfigurationSelectorTypes = {
    userAccessLevelOptions,
    displayModeOptions,
    displayConditionMethod,
};

export default WidgetConfigurationSelectorTypes;
