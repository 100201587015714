import React, { useEffect, useState } from 'react';

import { authTypeToDisplayName } from './CoupaAuthType';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

export interface CoupaProjectData {
    authType: string | undefined;
    scopes: string | undefined;
    instanceUrl: string | undefined;
}

const CoupaCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps<CoupaProjectData>> = ({
    onChangeOrInitIntegration,
    projectIntegrationData,
}) => {
    const [scopes, setScopes] = useState<string | undefined>();
    const [authType, setAuthType] = useState<string | undefined>();
    const [instanceUrl, setInstanceUrl] = useState<string | undefined>();

    useEffect(() => {
        const init = () => {
            if (projectIntegrationData) {
                setScopes(projectIntegrationData?.scopes);
                setInstanceUrl(`https://${projectIntegrationData?.instanceUrl}.com/`);

                const selectedAuthType = projectIntegrationData?.authType;
                if (selectedAuthType) {
                    setAuthType(authTypeToDisplayName[selectedAuthType]);
                }
            }
        };

        init();
    }, [projectIntegrationData]);

    useEffect(() => {
        onChangeOrInitIntegration(
            {
                projectIntegrationData,
                projectIntegrationDisplayName: `Coupa (${instanceUrl})`,
            },
            false,
        );
    }, [instanceUrl, onChangeOrInitIntegration, projectIntegrationData]);

    return (
        <div className="flex flex-col">
            <div className="form-group">
                <label className="col-sm-4 control-label">Authentication Type</label>
                <div className="col-sm-7">{authType}</div>
            </div>
            <div className="form-group">
                <label className="col-sm-4 control-label">Coupa Instance</label>
                <div className="col-sm-7">{instanceUrl}</div>
            </div>
            <div className="form-group">
                <label className="col-sm-4 control-label">Selected Scopes</label>
                <div className="col-sm-7">{scopes}</div>
            </div>
        </div>
    );
};

export default CoupaCustomizeSetupComponent;
