import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function BotWorkerComponentCtrl(
    $scope,
    modalUtils,
    communicationIntegrationsService,
    educationManager,
    projectManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.modalUtils = modalUtils;
    $scope.cis = communicationIntegrationsService;
    $scope.educationManager = educationManager;
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,

        syncEditMode: false,
    };

    $scope.init = function () {
        if (syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId)) {
            $scope.data.syncEditMode = true;
        }
    };

    /**
     * Toggles the bot worker component (by toggling the group's send gather updates).
     */
    $scope.toggleBotWorkerComponent = function () {
        analyticsWrapper.track('Toggle worker auto check-ins', {
            category: 'Bot worker component',
            label: !workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId)
                .shouldSendGatherUpdates,
        });
        return workflowVersionManager.toggleShouldSendGatherUpdates(
            $scope.data.groupId,
            !workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).shouldSendGatherUpdates,
        );
    };

    $scope.onSyncComplete = function () {
        $scope.data.editOwnerSettings = false;
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('BotWorkerComponentCtrl', lateConstructController(BotWorkerComponentCtrl));
