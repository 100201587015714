import type { TElement } from '@udecode/plate';
import React from 'react';
import styled from 'styled-components';

import { HTMLEditor } from '@tonkean/editor';
import { Field, useFormikField } from '@tonkean/infrastructure';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

const MarketplaceItemCreateModalDataSourceInstructionsSection: React.FC = () => {
    const { value: instructions, setValue: setInstructions } = useFormikField<TElement[] | undefined>(
        'configuration.instructions',
    );

    return (
        <StyledField label="Instructions">
            <HTMLEditor
                dataAutomation="marketplace-item-create-modal-instructions"
                initialValue={instructions}
                onChange={(value) => setInstructions(value)}
            />
        </StyledField>
    );
};

export default MarketplaceItemCreateModalDataSourceInstructionsSection;
