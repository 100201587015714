import NetDocumentsAuthenticationComponent from './NetDocumentsAuthenticationComponent';
import NetDocumentsSetupComponent from './NetDocumentsSetupComponent';
import netDocumentsCircleIcon from '../../../../apps/tracks/images/integrations/netdocuments-circle.png';
import netDocumentsIcon from '../../../../apps/tracks/images/netdocuments.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class NetDocumentsIntegration extends IntegrationDefinitionBase {
    override name = 'netdocuments';
    override displayName = 'NetDocuments';
    override description = 'We will only collect Documents, Users & Attributes data.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override externalActivityCreatedDateFieldName = 'created';
    override storageProviderSettings = new StorageProviderSettings(true, true);

    override entities = [
        new IntegrationEntity('Client', 'Client'),
        new IntegrationEntity('CustomAttribute', 'Custom Attributes'),
        new IntegrationEntity('Document', 'Documents'),
        new IntegrationEntity('Folder', 'Folders'),
        new IntegrationEntity('Matter', 'Matters'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Workspace', 'Workspaces'),
    ];
    override customizedAuthenticationComponent = NetDocumentsAuthenticationComponent;
    override customizedSetupComponent = NetDocumentsSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(netDocumentsCircleIcon, '130px'),
        new IntegrationIcon(netDocumentsIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: {
            Client: true,
            CustomAttribute: true,
            Document: true,
            Folder: true,
            Matter: true,
            User: true,
            Workspace: true,
        },
        isOnlyDataRetention: {},
    };
}

export default NetDocumentsIntegration;
