import React, { useCallback } from 'react';

import FormulaNode from './FormulaNode';
import FormulaNodePopoverTrigger from './FormulaNodePopoverTrigger';
import type SharedFormulaNodeProps from '../../entities/SharedFormulaNodeProps';

import type { FormulaTreeTreeNode } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';

interface Props extends SharedFormulaNodeProps {
    /** The node to display */
    node: FormulaTreeTreeNode;
    /** Function that will be triggered when the operand changes */
    onOperandChanged: (newOperand: formulaTreeNode, oldOperand: formulaTreeNode) => void;
}

const FormulaOperandBinary: React.FC<Props> = ({
    depth,
    canDelete,
    disabled,
    node,
    onOperandChanged,
    onNodeChanged,
    onNodeDeleted,
    additionalTabs = [],
    customTrigger,
}) => {
    // The node cannot be deleted, so we use an empty function and hide the delete button with canDelete={false}
    const stubOnNodeDeleted = useCallback(() => {}, []);

    return (
        <>
            <FormulaNodePopoverTrigger
                content="("
                depth={depth}
                additionalTabs={additionalTabs}
                customTrigger={customTrigger}
                canDelete={canDelete}
                disabled={disabled}
                node={node}
                onNodeChanged={onNodeChanged}
                onNodeDeleted={onNodeDeleted}
                right
            />

            <FormulaNode
                depth={depth + 1}
                additionalTabs={additionalTabs}
                customTrigger={customTrigger}
                canDelete={false}
                disabled={disabled}
                node={node.operands[0]!}
                onNodeChanged={onOperandChanged}
                onNodeDeleted={stubOnNodeDeleted}
            />

            <FormulaNodePopoverTrigger
                content={node.operator.sign}
                depth={depth}
                additionalTabs={additionalTabs}
                customTrigger={customTrigger}
                canDelete={canDelete}
                disabled={disabled}
                node={node}
                onNodeChanged={onNodeChanged}
                onNodeDeleted={onNodeDeleted}
                left
                right
            />

            <FormulaNode
                depth={depth + 1}
                additionalTabs={additionalTabs}
                customTrigger={customTrigger}
                canDelete={false}
                disabled={disabled}
                node={node.operands[1]!}
                onNodeChanged={onOperandChanged}
                onNodeDeleted={stubOnNodeDeleted}
            />

            <FormulaNodePopoverTrigger
                content=")"
                depth={depth}
                additionalTabs={additionalTabs}
                customTrigger={customTrigger}
                canDelete={canDelete}
                disabled={disabled}
                node={node}
                onNodeChanged={onNodeChanged}
                onNodeDeleted={onNodeDeleted}
                left
            />
        </>
    );
};

export default FormulaOperandBinary;
