import type EmbedUrlInputType from '../entities/EmbedUrlInputType';

import type {
    TonkeanExpressionDefinition,
    TonkeanId,
    TonkeanType,
    WidgetConfiguration,
} from '@tonkean/tonkean-entities';

export enum EmbedWidgetType {
    URL = 'URL',
    HTML = 'HTML',
}

interface BaseEmbedConfiguration {
    type: EmbedWidgetType;
    height?: number;
}

interface UrlEmbedConfiguration extends BaseEmbedConfiguration {
    type: EmbedWidgetType.URL;
    urlInputType?: EmbedUrlInputType;
    url: TonkeanExpressionDefinition;
    selectedProjectIntegrationId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    selectedActionId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>;
    integrationEntityApiName?: string;
    externalIdForAction?: TonkeanExpressionDefinition;
}

interface HTMLEmbedConfiguration extends BaseEmbedConfiguration {
    type: EmbedWidgetType.HTML;
    height?: number;
    html: TonkeanExpressionDefinition;
}

export type EmbedConfiguration = UrlEmbedConfiguration | HTMLEmbedConfiguration;

interface EmbedWidgetConfiguration extends WidgetConfiguration {
    embed: EmbedConfiguration;
    hideWidgetBackground: boolean | undefined;
}

export default EmbedWidgetConfiguration;
