<div
    ng-if="item.reference1.id !== item.target.id"
    class="box-with-shadow problem-item padding-normal flex-vmiddle"
    analytics-on="click"
    analytics-category="Problem Page"
    analytics-event="Open"
    analytics-label="Activity"
    analytics-value="{{ item.reference1.peopleCount }}"
    ui-sref="product.analyze.problems.details({problemId: item.reference1.id})"
>
    <div class="problem-item-content flex-grow">
        <div class="problem-item-details" ng-if="!item.reference1.isPositive">
            <div class="problem-item-prefix" ng-if="item.reference1.categoryFormats">
                {{ item.reference1.categoryFormats | categoryFormatsPrefix }}
            </div>
            <div class="problem-item-name">{{ item.reference1.text }}</div>
            <div
                class="problem-item-info"
                ng-show="item.reference1.elaborateTags && item.reference1.elaborateTags.length"
            >
                <div ng-if="item.reference1.elaborateTags.length < 5" class="list-comma">
                    Specifically:
                    <span ng-repeat="tag in item.reference1.elaborateTags">{{ tag.tag }}</span>
                </div>
                <div ng-if="item.reference1.elaborateTags.length > 4">
                    Specifically on:
                    <strong>{{ item.reference1.elaborateTags.length }} different areas</strong>
                </div>
            </div>
        </div>

        <div class="problem-item-details" ng-if="item.reference1.isPositive && item.reference2.text">
            <strong>
                {{ item.reference1.peopleCount }} {{ item.reference1.peopleCount > 1 ? 'people' : 'person' }}
            </strong>
            {{ item.reference2.text }}
        </div>

        <div class="problem-item-participants">
            <div class="flex-grow">
                <div ng-if="!item.reference1.isPositive">
                    By
                    <strong>
                        {{ item.reference1.peopleCount }} {{ item.reference1.peopleCount > 1 ? 'people' : 'person' }}
                    </strong>
                </div>
            </div>
        </div>
    </div>

    <div class="problem-item-arrow pull-right"><i class="icon-arrow mod-right"></i></div>
</div>
