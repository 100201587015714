import { useAngularService } from 'angulareact';
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import ItemInterfaceHeaderTitles from './ItemInterfaceHeaderTitles';
import ItemInterfacePermission from '../../../entities/ItemInterfacePermission';
import { getIsInWidgetBuilder } from '../../../WidgetModule';

import {
    Breakpoint,
    ItemInterfaceSection,
    LayoutGridElements,
    useItemInterfaceContext,
    widthByLayoutGridColumns,
} from '@tonkean/infrastructure';
import {
    type Initiative,
    ItemInterfaceBuilderMenuItem,
    useItemInterfaceHeaderThemeConfiguration,
    type WidgetBase,
} from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const HeaderTitlesContainer = styled.div<{ $tabsView?: boolean; $noBackground: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${({ $noBackground }) => ($noBackground ? Theme.colors.gray : 'transparent')};

    ${({ $tabsView }) =>
        $tabsView &&
        css`
            background-color: ${Theme.colors.basicBackground};
        `};
`;

const ItemInterfaceHeaderTitlesStyled = styled(ItemInterfaceHeaderTitles)<{ hasTitles: boolean }>`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 10;
    ${widthByLayoutGridColumns};
    --gutter-width: 0px;
    --margin-width: 0px;

    width: 100%;
    max-width: var(${LayoutGridElements.WIDTH});

    padding: ${({ hasTitles }) => (hasTitles ? '32px' : '4px')} 0;

    ${({ tabsView }) =>
        tabsView &&
        css`
            --gutter-width: -16px; // The tabs need to be farther out to align with the other content, so we pull everything out, and add padding inside
        `};

    @media screen and (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: 32px;
    }
`;

export interface ItemInterfaceHeaderProps {
    initiative: Initiative | undefined;
    permission: ItemInterfacePermission;
    widgets: WidgetBase[];
    section?: ItemInterfaceSection;
    tabsView: boolean;
    actionButton?: React.ReactNode;
}

const ItemInterfaceHeader: React.FC<ItemInterfaceHeaderProps> = ({
    initiative,
    permission,
    widgets,
    section = ItemInterfaceSection.MAIN,
    tabsView,
    actionButton,
}) => {
    const { itemInterface, project } = useItemInterfaceContext();
    const headerThemeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);
    const $state = useAngularService('$state');

    const isInBuilder = getIsInWidgetBuilder($state);

    const isHeaderEnabled = [
        ItemInterfacePermission.INTERFACE_IS_EDITABLE,
        ItemInterfacePermission.INTERFACE_IS_VIEW_ONLY,
    ].includes(permission);

    const headerClickableProps =
        isInBuilder && isHeaderEnabled
            ? {
                  state: '.',
                  params: {
                      currentTab: ItemInterfaceBuilderMenuItem.THEME,
                      widgetId: undefined,
                  },
              }
            : {};

    const [hasTitles, setHasTitles] = useState(
        Boolean(
            headerThemeConfiguration.title.evaluatedExpression || headerThemeConfiguration.subtitle.evaluatedExpression,
        ),
    );

    const onHasTitlesChanged = useCallback((value) => setHasTitles(value), [setHasTitles]);

    return (
        <HeaderContainer>
            <Clickable {...headerClickableProps} aria-label="Open theme configuration panel">
                <HeaderTitlesContainer
                    data-automation="item-interface-view-header"
                    $tabsView={tabsView}
                    $noBackground={section === ItemInterfaceSection.MAIN}
                >
                    <ItemInterfaceHeaderTitlesStyled
                        initiative={initiative}
                        widgets={widgets}
                        hasTitles={hasTitles}
                        onHasTitlesChanged={onHasTitlesChanged}
                        tabsView={tabsView}
                        actionButton={actionButton}
                    />
                </HeaderTitlesContainer>
            </Clickable>
        </HeaderContainer>
    );
};

export default ItemInterfaceHeader;
