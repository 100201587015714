import styled from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';

const FormSettingsSectionHeaderTitle = styled.span`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: bold;
    margin-right: 3px;
`;

export default FormSettingsSectionHeaderTitle;
