import template from './botPostMessageActionConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkBotPostMessageActionConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        customTriggerActionType: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        invalidLogics: '<',
        openFieldModal: '<',
        onActionsChanged: '&',
    },
    template,
    controller: 'BotPostMessageActionConfigurationCtrl',
});
