import React, { useContext } from 'react';
import styled from 'styled-components';

import FormulaContext from '../entities/FormulaContext';
import HighlightedNodesSource from '../entities/HighlightedNodesSource';
import type { JoinedValidationError } from '../utils/triageValidations';

const ErrorList = styled.ul`
    margin: 0 0 20px;
    padding: 0;
`;

const Error = styled.li<{ faded: boolean; isWarning?: boolean }>`
    color: ${({ isWarning }) => (isWarning ? '#FF611C' : '#d10027')};
    font-weight: bold;
    list-style: none;
    margin-bottom: 4px;
    outline: none;

    ${({ faded }) =>
        faded &&
        `
        opacity: 0.5;
    `}
`;

interface Props {
    warnings: JoinedValidationError[];
    errors: JoinedValidationError[];
}

const FormulaValidationErrors: React.FC<Props> = ({ errors, warnings }) => {
    const { highlightedNodes, setHighlightedNodes } = useContext(FormulaContext);

    const onErrorHover = (error: JoinedValidationError) => {
        setHighlightedNodes({ source: HighlightedNodesSource.ERROR, nodes: error.sources });
    };
    const onErrorUnhover = (error: JoinedValidationError) => {
        if (highlightedNodes?.nodes === error.sources && highlightedNodes.source === HighlightedNodesSource.ERROR) {
            setHighlightedNodes(undefined);
        }
    };

    return (
        <ErrorList>
            {errors.map((error) => (
                <Error
                    key={error.message}
                    tabIndex={0}
                    onMouseEnter={() => onErrorHover(error)}
                    onMouseLeave={() => onErrorUnhover(error)}
                    onFocus={() => onErrorHover(error)}
                    onBlur={() => onErrorUnhover(error)}
                    faded={
                        highlightedNodes?.source === HighlightedNodesSource.ERROR &&
                        highlightedNodes.nodes !== error.sources
                    }
                >
                    {error.message}
                </Error>
            ))}

            {warnings.map((warning) => (
                <Error
                    key={warning.message}
                    tabIndex={0}
                    onMouseEnter={() => onErrorHover(warning)}
                    onMouseLeave={() => onErrorUnhover(warning)}
                    onFocus={() => onErrorHover(warning)}
                    onBlur={() => onErrorUnhover(warning)}
                    faded={
                        highlightedNodes?.source === HighlightedNodesSource.ERROR &&
                        highlightedNodes.nodes !== warning.sources
                    }
                    isWarning
                >
                    {warning.message}
                </Error>
            ))}
        </ErrorList>
    );
};

export default FormulaValidationErrors;
