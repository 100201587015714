import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class RoundFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.ROUND;
    override readonly sign = 'Round';
    override readonly displayName = 'Round';
    override readonly description = 'Will round a number to a specified number of digits.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Number',
            dataType: FieldType.Number,
        },
        {
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            displayName: 'Number of digits',
            dataType: FieldType.Number,
        },
    ];
}
