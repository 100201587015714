import type PopperJS from '@popperjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import IconsPickerContent from './IconsPickerContent';
import SelectedIcon from './SelectedIcon';

import { LoadingCircle } from '@tonkean/infrastructure';
import { SearchBox } from '@tonkean/infrastructure';
import { useDebouncedState } from '@tonkean/infrastructure';
import { Chevron, ChevronDirection, Popover } from '@tonkean/infrastructure';
import type { Icon, IconsByCategory } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';

const DropdownWrapper = styled.div`
    margin-top: 8px;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: ${Theme.colors.white};
`;

const ClickableIcon = styled(Clickable)<{ $pickerWidth: number; $pickerHeight: number; disabled?: boolean }>`
    border: 1px solid ${Theme.colors.gray_400};
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ $pickerWidth }) => $pickerWidth}px;
    height: ${({ $pickerHeight }) => $pickerHeight}px;
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.6;
        `}
`;

const ContentWrapper = styled.div<{ width: number }>`
    width: ${({ width }) => width}px;
`;

const StyledChevron = styled(Chevron)`
    margin-left: 4px;
`;

interface Props {
    icons: IconsByCategory[];
    value: Icon | undefined;
    onChangeIcon: (icon: Icon) => void;
    onSearch: (searchTerm: string) => void;
    showCategories?: boolean;
    SelectedIconComponent: ReactElement;
    dropdownWidth?: number;
    dropdownHeight?: number;
    loading?: boolean;
    showLabel?: boolean;
    placement?: PopperJS.Placement;
    pickerWidth?: number;
    pickerHeight?: number;
    color?: string;
    disabled?: boolean;
}

const IconPicker: React.FC<Props> = ({
    value,
    onChangeIcon,
    icons,
    onSearch,
    showCategories = true,
    SelectedIconComponent,
    dropdownWidth = 310,
    dropdownHeight = 375,
    loading = false,
    showLabel = true,
    placement,
    pickerWidth = 65,
    pickerHeight = 30,
    color,
    disabled,
}) => {
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
    const [searchTerm, setSearchTerm] = useDebouncedState('', setDebouncedSearchTerm, 300);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
        onSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm, onSearch]);

    const onSelectIcon = useCallback(
        (icon: Icon) => {
            onChangeIcon(icon);
            setIsDropdownOpen(false);
        },
        [onChangeIcon],
    );

    return (
        <>
            <Popover
                content={
                    <ContentWrapper data-automation="icon-picker-menu" width={dropdownWidth}>
                        <SearchBox value={searchTerm} onChange={({ target: { value } }) => setSearchTerm(value)} />
                        <DropdownWrapper>
                            {loading ? (
                                <LoadingCircle centered />
                            ) : (
                                <IconsPickerContent
                                    iconsByCategories={icons}
                                    onChangeIcon={onSelectIcon}
                                    showCategories={showCategories}
                                    width={dropdownWidth}
                                    height={dropdownHeight}
                                    selectedIcon={value}
                                    searchTerm={debouncedSearchTerm}
                                    key={searchTerm}
                                    color={color}
                                />
                            )}
                        </DropdownWrapper>
                    </ContentWrapper>
                }
                show={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
                showArrow={false}
                placement={placement}
            >
                <Wrapper>
                    {showLabel && <Label>Icon</Label>}
                    <ClickableIcon
                        $pickerWidth={pickerWidth}
                        $pickerHeight={pickerHeight}
                        onClick={() => setIsDropdownOpen(true)}
                        disabled={disabled}
                        data-automation="icon-picker-menu-button"
                    >
                        <SelectedIcon
                            width={18}
                            height={18}
                            icon={value}
                            SelectedIconComponent={SelectedIconComponent}
                            color={color}
                        />
                        <StyledChevron direction={isDropdownOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
                    </ClickableIcon>
                </Wrapper>
            </Popover>
        </>
    );
};

export default IconPicker;
