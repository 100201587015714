import React from 'react';
import styled from 'styled-components';

import { Breadcrumbs, H1 } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const Sticky = styled.div`
    background: ${Theme.colors.basicBackground};
    position: sticky;
    top: 0;
    z-index: 1;
`;

const TopView = styled(Sticky)`
    display: flex;
    padding-right: 15px;
    margin-bottom: 25px;
    padding-top: 25px;
    justify-content: space-between;
`;

const Header = styled(Sticky)`
    display: grid;
`;

const Wrapper = styled.div`
    flex-shrink: 1;
    display: grid;
    grid-template-columns: 1fr minmax(0, 1fr);

    @media screen and (max-width: 768px) {
        display: block;
    }

    height: 100%;
`;

const TitleAndDescriptionWrapper = styled.div`
    margin-bottom: 25px;
`;

const Side = styled.div`
    display: flex;
    flex-direction: column;
`;

const LeftSide = styled(Side)`
    overflow-y: auto;
    border-right: 1px solid ${Theme.colors.gray_300};
    padding-bottom: 50px;
`;
const RightSide = styled(Side)`
    background-color: ${Theme.colors.gray_100};
    overflow: hidden;
`;

const Description = styled.div``;

const Body = styled.div`
    z-index: 0;
    flex-grow: 1;
`;

const ActionItems = styled.div`
    display: flex;
    margin-left: auto;
`;

interface Props {
    name: string;
    title?: React.ReactNode;
    description?: string;
    actions?: React.ReactNode;
    leftStickyItems?: React.ReactNode;

    rightBody: React.ReactNode;
    breadcrumbs?: React.ReactNode;
}

const SplitPageTemplate: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    name,
    title = <H1>{name}</H1>,
    description,
    actions,
    leftStickyItems,
    rightBody,
    breadcrumbs = <Breadcrumbs />,
}) => {
    return (
        <Wrapper>
            <LeftSide>
                <Header>
                    <TopView>
                        {breadcrumbs}
                        {actions && <ActionItems>{actions}</ActionItems>}
                    </TopView>
                    <TitleAndDescriptionWrapper>
                        {title}
                        {description && <Description>{description}</Description>}
                    </TitleAndDescriptionWrapper>
                    {leftStickyItems && leftStickyItems}
                </Header>
                <Body>{children}</Body>
            </LeftSide>
            <RightSide>{rightBody}</RightSide>
        </Wrapper>
    );
};

export default SplitPageTemplate;
