import template from './tnkWorkerTileNewWorker.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerTileNewWorker', {
    bindings: {
        folderId: '<',
        openWorker: '<',
    },
    template,
    controller: 'WorkerTileNewWorkerCtrl',
});
