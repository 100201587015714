import type { NavigablePageType } from './BaseNavigablePage';
import type BaseNavigablePage from './BaseNavigablePage';

interface ExternalNavigablePage extends BaseNavigablePage {
    type: NavigablePageType.EXTERNAL;
    path: string;
}

export default ExternalNavigablePage;

export function isExternalNavigablePage(page: BaseNavigablePage): page is ExternalNavigablePage {
    return typeof page?.path === 'string';
}
