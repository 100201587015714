import React, { useState } from 'react';
import styled from 'styled-components';

import { AITypewriter } from '@tonkean/ai-builder';
import { IconSvg } from '@tonkean/infrastructure';
import { AppearFromTopAnimation } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { SmartConversationResponseText } from '@tonkean/smart-conversation';
import { DownloadIcon } from '@tonkean/svg';
import type { FormulaAIServerResponse, SmartConversationMessage } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme, FontSize } from '@tonkean/tui-theme';

const FormulaResultBox = styled(AppearFromTopAnimation)`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 6px;
    background: ${Theme.colors.gray_800};
    color: ${Theme.colors.white};
    font-size: ${FontSize.XXLARGE_20};
    padding: 20px;
`;

const StyledIconButton = styled(IconButton)`
    flex-shrink: 0;
`;

interface Props {
    message: SmartConversationMessage<FormulaAIServerResponse>;
    onAnimationEnd: () => void;
    onUseFormulaClick: (formula: string) => void;
}

const FormulaAIConversationMessageView: React.FC<Props> = ({ message, onAnimationEnd, onUseFormulaClick }) => {
    const [doneTyping, setDoneTyping] = useState(false);
    const showFormulaResult = !message.response.chat_message || doneTyping;
    const formulaResult = message.response.formula_result;
    return (
        <>
            {message.response.chat_message && (
                <SmartConversationResponseText>
                    <AITypewriter
                        skipAnimation={!message.showAnimation}
                        text={message.response.chat_message}
                        onTypingDone={setDoneTyping}
                    />
                </SmartConversationResponseText>
            )}
            {showFormulaResult && formulaResult && (
                <AppearFromTopAnimation skipAnimation={!message.showAnimation} onAnimationEnd={onAnimationEnd}>
                    <>
                        <FormulaResultBox>
                            {formulaResult}
                            <Tooltip content="Use this formula">
                                <StyledIconButton onClick={() => onUseFormulaClick(formulaResult)}>
                                    <IconSvg as={DownloadIcon} size={24} color={Theme.colors.gray_300} />
                                </StyledIconButton>
                            </Tooltip>
                        </FormulaResultBox>
                    </>
                </AppearFromTopAnimation>
            )}
        </>
    );
};

export default FormulaAIConversationMessageView;
