import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function DropboxSelectorCtrl(
    $scope,
    $q,
    createProjectApis,
    oauthHandler,
    utils,
    projectManager,
    environment,
) {
    const ctrl = this;

    $scope.utils = utils;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,
        configSelections: {},

        connecting: false,
        error: null,
        isEdit: false,
        integrationPermissions: 'readonly',
        initIntegrationPermissions: 'readonly',

        configStep: 'selectFolder',

        integration: null,

        currentFolder: null,
        folders: [],
        folderNavigationHistory: [], // Used as a stack to keep the history of navigation (in order to go back)
        foldersLoading: false,

        folderSearch: '',
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.projectIntegration) {
            initializeEditMode();
        }
    };

    $scope.cancel = function () {
        if (ctrl.onCancel) {
            ctrl.onCancel();
        }
    };

    $scope.authorize = function () {
        $scope.data.connecting = true;
        $scope.data.error = null;
        const redirectUri = environment.integrationKeysMap.dropboxUri;

        oauthHandler
            .dropbox(environment.integrationKeysMap.dropbox, redirectUri)
            .then(function (code) {
                return $q.resolve({ code, redirectUri });
            })
            .then(function (config) {
                return createProjectApis.createIntegration(projectManager.project.id, 'dropbox', config, undefined);
            })
            .then(function (integration) {
                $scope.data.integration = integration;
                $scope.moveToHomeFolder();
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.connecting = false;
            });
    };

    $scope.moveToHomeFolder = function () {
        const folder = {
            displayName: 'Home',
            value: '',
        };

        // Get folders under root
        $scope.getChildFolders(folder);
    };

    $scope.onOpenClicked = function (folder) {
        // Save previous folder in history (unless its root)
        if ($scope.data.currentFolder) {
            $scope.data.folderNavigationHistory.push($scope.data.currentFolder);
        }

        return $scope.getChildFolders(folder);
    };

    $scope.getChildFolders = function (folder, queryOnRootShared) {
        $scope.data.folders = [];
        $scope.data.foldersLoading = true;

        // Set Current folder
        $scope.data.currentFolder = folder;

        const params = {};
        if (queryOnRootShared || ($scope.data.currentFolder && $scope.data.currentFolder.isShared)) {
            params.isShared = true;
        }

        params.value = folder.value;

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'dropbox', params)
            .then(function (data) {
                $scope.data.folders = data.options;
            })
            .finally(() => {
                $scope.data.foldersLoading = false;
            });
    };

    $scope.goToPreviousFolder = function () {
        if ($scope.data.folderNavigationHistory.length) {
            const targetFolder = $scope.data.folderNavigationHistory.pop();
            return $scope.getChildFolders(targetFolder);
        } else {
            $scope.moveToHomeFolder();
        }
    };

    $scope.onSearchChanged = function (queryString) {
        $scope.data.folderes = [];
        $scope.data.foldersLoading = true;
        const query = {};
        if (queryString && queryString.length) {
            query.name = queryString;
        } else {
            query.parent = '';
        }

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'dropbox', query)
            .then(function (data) {
                $scope.data.folders = data.options;
            })
            .finally(() => {
                $scope.data.foldersLoading = false;
            });
    };

    $scope.changeUser = function () {
        $scope.data.integration = null;
        $scope.data.folders = [];
        $scope.data.folderNavigationHistory = [];
        $scope.data.currentFolder = null;
        $scope.data.configStep = 'selectFolder';
    };

    $scope.onFolderSelected = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        $scope.data.saving = true;

        const folderName = $scope.data.currentFolder ? $scope.data.currentFolder.displayName : 'Home';
        const projectIntegrationDisplayName = `Dropbox (${folderName} Folder)`;
        const redirectUri = environment.integrationKeysMap.dropboxUri;

        const reauthenticate = oauthHandler
            .dropbox(environment.integrationKeysMap.dropbox, redirectUri)
            .then(function (code) {
                return $q.resolve({
                    code,
                    redirectUri,
                    folderPath: $scope.data.currentFolder ? $scope.data.currentFolder.value : '',
                });
            })
            .then(function (config) {
                return createProjectApis.createIntegration(projectManager.project.id, 'dropbox', config, undefined);
            })
            .then(function (integration) {
                $scope.data.integration = integration;
                return $q.resolve(integration);
            });

        const projectData = {
            folder: $scope.data.currentFolder,
        };

        // NOTE: Since edit project integration is relying on the third parameter object to hold an array of projectDatas,
        // we inject it into the integration object, which does not have a project data property at all.
        $scope.data.integration.projectDatas = [projectData];

        let projectIntegrationPromise;
        const oldProject = projectManager.project;

        projectIntegrationPromise = reauthenticate.then((updatedIntegration) => {
            $scope.data.integration = updatedIntegration;
            $scope.data.integration.projectDatas = [projectData];
            if ($scope.data.isEdit) {
                return createProjectApis.editProjectIntegration(
                    projectManager.project.id,
                    $scope.data.projectIntegration.id,
                    $scope.data.integration
                );
            } else {
                return createProjectApis.createProjectIntegration(
                    projectManager.project,
                    $scope.data.integration,
                    projectIntegrationDisplayName,
                );
            }
        });

        projectIntegrationPromise.then((updatedProject) => {
            $scope.data.projectIntegration = utils.findFirst(
                updatedProject.integrations,
                (prin) =>
                    prin.id === $scope.data?.projectIntegration?.id ||
                    !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
            );

            projectManager.getProjectData(true).then(() => {
                $scope.data.saving = false;
                if (ctrl.onProjectIntegrationCreated) {
                    ctrl.onProjectIntegrationCreated({
                        projectIntegration: $scope.data.projectIntegration,
                    });
                }
            });
        });
    };

    $scope.onCancel = function () {
        if (ctrl.onClosed) {
            ctrl.onClosed();
        }
    };

    /**
     * If component was given a project integration, will load the component's edit mode.
     */
    function initializeEditMode() {
        $scope.data.isEdit = true;

        $scope.data.integration = $scope.data.projectIntegration.integration;
        const projectData = $scope.data.projectIntegration.projectData.projectDatas[0];
        $scope.data.currentFolder = projectData.folder;

        if ($scope.data.currentFolder) {
            $scope.data.configStep = 'selectFolder';
        }

        $scope.data.connecting = true;

        $scope.data.error = null;

        createProjectApis
            .getAutoCompleteOptions(
                projectManager.project.id,
                $scope.data.projectIntegration.integration.id,
                'dropbox',
                {
                    value: $scope.data.currentFolder ? $scope.data.currentFolder.value : '',
                },
            )
            .then(function (data) {
                $scope.data.folders = data.options;
            })
            .catch(function (error) {
                $scope.data.error = error;
            })
            .finally(function () {
                $scope.data.connecting = false;
            });
    }
}

export default angular.module('tonkean.app').controller('DropboxSelectorCtrl', lateConstructController(DropboxSelectorCtrl));
