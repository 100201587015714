import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';

import ProjectIntegrationFrontDoorEntityConditionsModal from './ProjectIntegrationFrontDoorEntityConditionsModal';
import ProjectIntegrationFrontDoorEntitySettingRowColumns from './ProjectIntegrationFrontDoorEntitySettingRowColumns';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { FormikAutosave, FormikHelpers, type PartialListItem } from '@tonkean/infrastructure';
import type {
    ProjectIntegration,
    ProjectIntegrationSmartSearchEntityConfig,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

interface Props {
    projectIntegration: ProjectIntegration;
    entity: ProjectIntegrationSmartSearchEntityConfig;
    onUpdate: (
        itemId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_SMART_SEARCH_ENTITY_CONFIG>,
        updateFunc: (
            current: ProjectIntegrationSmartSearchEntityConfig,
        ) => PartialListItem<ProjectIntegrationSmartSearchEntityConfig>,
    ) => void;
    onDelete: (itemId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_SMART_SEARCH_ENTITY_CONFIG>) => void;
    shouldDisableChoice: (externalType: string) => boolean;
}

const ProjectIntegrationFrontDoorEntitySettingRow: React.FC<Props> = ({
    projectIntegration,
    entity,
    onUpdate,
    onDelete,
    shouldDisableChoice,
}) => {
    const [{ loading: deleteLoading, error: deleteError }, deleteProjectIntegrationSmartSearchAccessEntityConfig] =
        useLazyTonkeanService('deleteProjectIntegrationSmartSearchAccessEntityConfig');

    const deleteItem = useCallback(() => {
        return deleteProjectIntegrationSmartSearchAccessEntityConfig(entity.projectId, entity.id).then(() => {
            onDelete(entity.id);
        });
    }, [deleteProjectIntegrationSmartSearchAccessEntityConfig, entity.id, entity.projectId, onDelete]);

    const [{ loading: updateLoading, error: updateError }, updateProjectIntegrationSmartSearchAccessEntityConfig] =
        useLazyTonkeanService('updateProjectIntegrationSmartSearchAccessEntityConfig');

    const [conditionsModalOpen, setConditionsModalOpen] = useState(false);

    return (
        <Formik
            initialValues={entity}
            onSubmit={async ({ externalType, allowAccess, conditions }) => {
                await updateProjectIntegrationSmartSearchAccessEntityConfig(
                    entity.projectId,
                    entity.id,
                    externalType,
                    allowAccess,
                    conditions,
                );
                return onUpdate(entity.id, () => ({ externalType, allowAccess, conditions }));
            }}
        >
            <FormikHelpers>
                <FormikAutosave />
                <ProjectIntegrationFrontDoorEntitySettingRowColumns
                    projectIntegration={projectIntegration}
                    onDelete={deleteItem}
                    shouldDisableChoice={shouldDisableChoice}
                    onOpenConditions={() => setConditionsModalOpen(true)}
                    loading={deleteLoading || updateLoading}
                    error={deleteError || updateError}
                />
                <ProjectIntegrationFrontDoorEntityConditionsModal
                    name="conditions"
                    open={conditionsModalOpen}
                    projectIntegration={projectIntegration}
                    entityType={entity.externalType}
                    onClose={() => setConditionsModalOpen(false)}
                />
            </FormikHelpers>
        </Formik>
    );
};

export default ProjectIntegrationFrontDoorEntitySettingRow;
