import { useAngularService } from 'angulareact';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import ItemInterfaceSelector from '../../../components/ItemInterfaceSelector';
import useItemInterfaceEditorContext from '../../../utils/ItemInterfaceEditorContext';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import type { IncludeInterfaceWidgetConfiguration } from '../IncludeInterfaceWidgetConfiguration';

import { useFeatureFlag, useLazyTonkeanService, useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { ContractFieldSelector, ContractSelector } from '@tonkean/contracts';
import { FieldSelector } from '@tonkean/fields';
import {
    Field,
    LoadingCircle,
    SimpleErrorStateMessage,
    useFormikField,
    useItemInterfaceContext,
} from '@tonkean/infrastructure';
import {
    ContractFieldType,
    type MatchItemFieldDefinition,
    type TonkeanId,
    type TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY } from '@tonkean/utils';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 20px;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const ItemInterfaceIncludeInterfaceWidgetEditor: React.FC<
    ItemWidgetEditorProps<IncludeInterfaceWidgetConfiguration>
> = ({ workflowVersion }) => {
    const { itemInterface } = useItemInterfaceContext();

    const supportContracts = useFeatureFlag('tonkean_feature_enable_module_contract');

    const { reloadItemInterfaceWidgets } = useItemInterfaceEditorContext();

    const {
        data: fieldDefinitions,
        loading: fieldDefinitionsLoading,
        error: fieldDefinitionsError,
    } = useTonkeanService('getFieldDefinitions', workflowVersion.id);

    const [
        { loading: isLoadingContracts, data: contractsSummary, error: errorLoadingContractSummaries },
        getContractSummaries,
    ] = useLazyTonkeanService('getContractSummaries');

    const [
        { data: contractFieldsResponse, loading: isLoadingContractFields, error: errorLoadingContractFields },
        getContractFields,
    ] = useLazyTonkeanService('getContractFields');

    const { setValue: setMatchingItemInterfaceId } = useFormikField<TonkeanId<TonkeanType.ITEM_INTERFACE> | undefined>(
        'configuration.matchingItemInterfaceId',
    );

    const { value: matchingFieldDefinitionId } = useFormikField<TonkeanId<TonkeanType.FIELD_DEFINITION> | undefined>(
        'configuration.matchingFieldDefinitionId',
    );

    const { value: matchingContractId, setValue: setMatchingContractId } = useFormikField<
        TonkeanId<TonkeanType.CONTRACT> | undefined
    >('configuration.matchingContractId');

    const { setValue: setMatchingContractFieldId } = useFormikField<TonkeanId<TonkeanType.CONTRACT_FIELD> | undefined>(
        'configuration.matchingContractFieldId',
    );

    const { setValue: setMatchingGroupId } = useFormikField<TonkeanId<TonkeanType.GROUP> | undefined>(
        'configuration.matchingGroupId',
    );

    const contractsEnabledAndSelected = supportContracts && matchingContractId;

    const { submitForm } = useFormikContext();
    const onItemInterfaceSelected = useCallback(() => {
        setMatchingContractId(undefined);
        setMatchingContractFieldId(undefined);
        submitForm().then(() => {
            // After changing the interface the included tabs might be different, so we have to reload the widgets
            reloadItemInterfaceWidgets?.();
        });
    }, [reloadItemInterfaceWidgets, setMatchingContractFieldId, setMatchingContractId, submitForm]);

    const workflowVersionManager = useAngularService('workflowVersionManager');
    const [
        { data: matchingWorkflowVersion, loading: matchingWorkflowVersionLoading, error: matchingWorkflowVersionError },
        getGroupWorkflowVersion,
    ] = useLazyAsyncMethod(workflowVersionManager, 'getGroupWorkflowVersion');

    const matchingGroupId = useMemo(() => {
        if (!matchingFieldDefinitionId) {
            return;
        }
        const foundFieldDefinition = fieldDefinitions?.entities?.find(
            (entity) => entity.id === matchingFieldDefinitionId,
        );
        if (!foundFieldDefinition) {
            return;
        }

        const groupId = foundFieldDefinition.secondaryId as TonkeanId<TonkeanType.GROUP>;
        getGroupWorkflowVersion(groupId, workflowVersion.workflowVersionType || WorkflowVersionType.DRAFT);
        setMatchingGroupId(groupId);

        return groupId;
    }, [
        fieldDefinitions?.entities,
        getGroupWorkflowVersion,
        matchingFieldDefinitionId,
        setMatchingGroupId,
        workflowVersion.workflowVersionType,
    ]);

    const excludedInterfaceIdsFromSelector = useMemo(() => {
        if (matchingGroupId) {
            return undefined;
        }

        return [itemInterface.id];
    }, [matchingGroupId, itemInterface.id]);

    useEffect(() => {
        if (contractsEnabledAndSelected) {
            setMatchingItemInterfaceId(undefined);
            getContractFields(workflowVersion.project.id, matchingContractId, ContractFieldType.ITEM_INTERFACE);
        }
    }, [
        contractsEnabledAndSelected,
        getContractFields,
        matchingContractId,
        setMatchingItemInterfaceId,
        workflowVersion.project.id,
    ]);

    useEffect(() => {
        if (supportContracts) {
            getContractSummaries(workflowVersion.groupId);
        }
    }, [getContractSummaries, supportContracts, workflowVersion.groupId]);

    const error =
        fieldDefinitionsError ||
        matchingWorkflowVersionError ||
        errorLoadingContractSummaries ||
        errorLoadingContractFields;

    return (
        <Wrapper>
            {supportContracts && (
                <Field label="From contract" light>
                    <ContractSelector
                        size={InputSize.LARGE}
                        loading={isLoadingContracts}
                        contracts={contractsSummary?.entities || EMPTY_ARRAY}
                        onChange={(newVal) => {
                            if (newVal !== matchingContractId) {
                                setMatchingContractFieldId(undefined);
                            }
                        }}
                        disabled={false}
                        name="configuration.matchingContractId"
                    />
                </Field>
            )}

            <Field label="From module" dataAutomation="interface-include-widget-configuration-from-module" light>
                <FieldSelector
                    name="configuration.matchingFieldDefinitionId"
                    fieldsDefinitions={
                        fieldDefinitions?.entities.filter((fd) => {
                            const definition = fd?.definition as MatchItemFieldDefinition;
                            return (
                                !contractsEnabledAndSelected ||
                                definition?.matchConfiguration?.contractId === matchingContractId
                            );
                        }) || EMPTY_ARRAY
                    }
                    loading={fieldDefinitionsLoading}
                    onlyForMatchedItems
                    isClearable
                />
            </Field>

            {!contractsEnabledAndSelected && (
                <Field
                    label={
                        <LabelWrapper>
                            Interface {matchingWorkflowVersionLoading && <LoadingCircle thin />}
                        </LabelWrapper>
                    }
                    dataAutomation="interface-include-widget-configuration-interface"
                    light
                >
                    <ItemInterfaceSelector
                        name="configuration.matchingItemInterfaceId"
                        workflowVersionId={(matchingGroupId && matchingWorkflowVersion?.id) || workflowVersion.id}
                        groupId={matchingGroupId || workflowVersion.groupId}
                        excludeIds={excludedInterfaceIdsFromSelector}
                        onItemInterfaceSelected={onItemInterfaceSelected}
                        allowClear
                    />
                </Field>
            )}

            {contractsEnabledAndSelected && (
                <Field
                    label={
                        <LabelWrapper>
                            Interface {matchingWorkflowVersionLoading && <LoadingCircle thin />}
                        </LabelWrapper>
                    }
                    dataAutomation="interface-include-widget-configuration-interface"
                    light
                >
                    <ContractFieldSelector
                        name="configuration.matchingContractFieldId"
                        options={contractFieldsResponse?.entities || EMPTY_ARRAY}
                        loading={isLoadingContractFields}
                        contractFieldType={ContractFieldType.ITEM_INTERFACE}
                        disabled={false}
                    />
                </Field>
            )}

            {error && <SimpleErrorStateMessage error={error} showSmallError />}
        </Wrapper>
    );
};

export default ItemInterfaceIncludeInterfaceWidgetEditor;
