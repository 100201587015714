import React from 'react';
import styled from 'styled-components';

import { ReactComponent as InformationIcon } from '../../../images/icons/info20px.svg';

import { useCreateDialog } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ModalHeaderContent = styled.div`
    display: flex;
`;

const HeaderText = styled.div`
    font-weight: 400;
    font-size: ${FontSize.XXLARGE_20};
    color: ${Theme.colors.gray_800};
    margin-left: 10px;
    margin-right: 5px;
`;

const useProjectIntegrationActionWarningModal = () => {
    const { confirm } = useCreateDialog();

    const openWarningFunc = async (
        header: string,
        content = 'Notice - all the changes you have made after you clicked "Save Action Changes" will lost forever.',
    ) =>
        confirm(
            <ModalHeaderContent>
                <InformationIcon />
                <HeaderText>{header}</HeaderText>
            </ModalHeaderContent>,
            content,
            { okLabel: 'Continue' },
        );
    return openWarningFunc;
};

export default useProjectIntegrationActionWarningModal;
