<div class="turn-gather-updates-off-modal common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn svg-icon" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold margin-bottom-md margin-top-lg flex-vmiddle">Turn Module follow-ups off?</h1>

    <div class="margin-top-lg">
        <div class="common-bold">This means the Module won't be able to send you any alerts or follow-ups</div>
        <div>
            You'll miss alerts on items you own or are related to you, and notifications when you're assigned an item.
        </div>
    </div>

    <div class="margin-top-lg flex flex-row-reverse">
        <button ng-click="$dismiss()" class="btn btn-default margin-left-lg">Cancel</button>
        <button ng-click="$close()" class="btn btn-danger">Turn OFF</button>
    </div>
</div>
