import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import type { AirtableProjectIntegrationData } from './AirtableProjectIntegrationData';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { SimpleSelect } from '@tonkean/infrastructure';
import { Placeholder } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { XIcon } from '@tonkean/svg';
import Utils from '@tonkean/utils';

const TableNameWrapper = styled.label`
    margin-right: 15px;
    width: 20%;
`;

const Divider = styled.hr`
    width: 100%;
`;

const AddTableSectionWrapper = styled.div`
    width: 37%;
`;

const CustomSimpleSelect = styled(SimpleSelect)`
    width: 40%;
`;

const AirtableCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps<AirtableProjectIntegrationData>> = ({
    integration,
    createProjectApis,
    onChangeOrInitIntegration,
    projectIntegration,
    projectIntegrationData,
}) => {
    const projectManager = useAngularService('projectManager');

    // Map between table name to all available columns names.
    const [tableToColumnsMap, setTableToColumnsMap] = useState<Record<string, string[]>>({});

    // Represents the user selection.
    const [internalProjectIntegrationData, setInternalProjectIntegrationData] =
        useState<AirtableProjectIntegrationData>(projectIntegrationData || { tableToSelectedColumn: {} });

    const [newTableName, setNewTableName] = useState<string>('');
    const [insertNewTableError, setInsertNewTableError] = useState<string | undefined>(undefined);
    // Loaders
    const [shouldDisplayInitialLoader, setShouldDisplayInitialLoader] = useState<boolean>(false);
    const [shouldDisplayInsertNewTableLoader, setShouldDisplayInsertNewTableLoader] = useState<boolean>(false);

    // Using inputRef in order to set the focus on the INSERT TABLE input once the user submitted the form.
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // Init function should be called only on the initialisation of this component.
        const init = async () => {
            const tablesNames: string[] = Object.keys(internalProjectIntegrationData?.tableToSelectedColumn || {});
            if (Object.keys(tableToColumnsMap).length === 0 && tablesNames.length > 0) {
                try {
                    setShouldDisplayInitialLoader(true);
                    // Fetching all of the available buckets from Amazon's API.
                    const { options: tablesColumns } = await createProjectApis.getAutoCompleteOptions(
                        projectManager.project.id,
                        integration.id,
                        'columns',
                        tablesNames,
                    );

                    const tableToColumnsMap = tablesColumns.reduce((acc, tableColumns) => {
                        acc[tableColumns.displayName] = tableColumns.value;
                        return acc;
                    }, {});

                    setTableToColumnsMap(tableToColumnsMap);
                } finally {
                    setShouldDisplayInitialLoader(false);
                }
            }
        };
        init();
    }, [
        createProjectApis,
        integration.id,
        internalProjectIntegrationData,
        projectManager.project.id,
        tableToColumnsMap,
    ]);

    const insertNewTable = useCallback(
        async (e, tableName) => {
            e.preventDefault();

            // If table name is empty, exist function.
            if (Utils.isNullOrEmpty(tableName)) {
                return;
            }

            // If table is already exists in the map, exit function.
            if (tableToColumnsMap[tableName]) {
                setInsertNewTableError('Table already exists.');
                return;
            }

            try {
                setShouldDisplayInsertNewTableLoader(true);
                const { options: tablesColumns } = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'columns',
                    [tableName],
                );

                if (!tablesColumns[0]?.value?.length) {
                    throw new Error('Got empty columns for the given table');
                }

                setTableToColumnsMap((currentTableToColumnsMap) => ({
                    ...currentTableToColumnsMap,
                    [tableName]: tablesColumns[0].value,
                }));

                setNewTableName('');
                setInsertNewTableError('');
            } catch {
                setInsertNewTableError(
                    'Table could not be found. Make sure the table exists and that you spell its name correctly.',
                );
                return;
            } finally {
                setShouldDisplayInsertNewTableLoader(false);
                inputRef.current?.focus();
            }
        },
        [createProjectApis, integration.id, projectManager.project.id, tableToColumnsMap],
    );

    const removeTable = useCallback((tableName) => {
        setTableToColumnsMap((prevState) => {
            const { [tableName]: _ignored, ...rest } = prevState;
            return rest;
        });

        setInternalProjectIntegrationData((current) => {
            const {
                tableToSelectedColumn: { [tableName]: _ignored, ...rest1 },
                ...rest
            } = current;

            return { rest, tableToSelectedColumn: rest1 };
        });
    }, []);

    const updateTableSelectedColumn = useCallback((tableName: string, selectedColumn: string | undefined) => {
        // Update the object
        setInternalProjectIntegrationData((currentInternalProjectIntegrationData) => ({
            ...currentInternalProjectIntegrationData,
            tableToSelectedColumn: {
                ...currentInternalProjectIntegrationData.tableToSelectedColumn,
                [tableName]: selectedColumn,
            },
        }));
    }, []);

    const constructAutoCompleteOptions = (columnsArray) => {
        return columnsArray.map((columnName) => ({
            value: columnName,
            label: columnName,
        }));
    };

    useEffect(() => {
        onChangeOrInitIntegration(
            {
                projectIntegrationData: internalProjectIntegrationData,
            },
            false,
        );
    }, [internalProjectIntegrationData, onChangeOrInitIntegration]);

    return (
        <div className="flex flex-col">
            <span className="control-label margin-bottom">
                For each table, select the primary column which will be used as your items title. Fields names must be
                identical to the ones that exists in Airtable. If you make any change, make sure to update it here.
            </span>

            <Divider />

            {!shouldDisplayInitialLoader && Object.keys(tableToColumnsMap).length === 0 && (
                <span className="margin-top">
                    <i className="fa fa-info-circle margin-right" />
                    Airtable configuration is empty. Add tables below.
                </span>
            )}

            {shouldDisplayInitialLoader &&
                [...Array.from({ length: 3 }).keys()].map((key) => {
                    return (
                        <div key={key} className="flex-inline mod-vmiddle margin-bottom">
                            <Placeholder $width="18%" $height="20px" className="margin-right-md" />
                            <Placeholder $width="40%" $height="20px" />
                        </div>
                    );
                })}

            {!shouldDisplayInitialLoader && (
                <div className="flex flex-col">
                    {Object.keys(tableToColumnsMap).map((tableName) => {
                        return (
                            <div className="flex-inline mod-vmiddle margin-bottom" key={tableName}>
                                <TableNameWrapper className="margin-right-md common-ellipsis">
                                    {tableName}
                                </TableNameWrapper>
                                <CustomSimpleSelect
                                    options={constructAutoCompleteOptions(tableToColumnsMap[tableName])}
                                    onChange={(selectedValue) => updateTableSelectedColumn(tableName, selectedValue)}
                                    value={internalProjectIntegrationData?.tableToSelectedColumn[tableName]}
                                    isSearchable
                                />
                                <a
                                    className="svg-icon-xs common-color-grey margin-left-xs cursor-pointer"
                                    onClick={() => removeTable(tableName)}
                                >
                                    <span className="tnk-icon">
                                        <XIcon />
                                    </span>
                                </a>

                                {tableToColumnsMap[tableName]?.length === 0 && (
                                    <Tooltip content="Table name was changed or deleted.">
                                        <i className="fa fa-warning common-color-danger margin-left" />
                                    </Tooltip>
                                )}
                            </div>
                        );
                    })}

                    <Divider />
                    <AddTableSectionWrapper>
                        <form
                            className="flex flex-inline mod-vmiddle margin-top"
                            onSubmit={(e) => insertNewTable(e, newTableName)}
                        >
                            <input
                                type="text"
                                value={newTableName}
                                onChange={({ target }) => setNewTableName(target.value)}
                                className="form-control margin-right"
                                id="new-table-name"
                                autoComplete="off"
                                placeholder="Add a table.."
                                ref={inputRef}
                                autoFocus
                            />
                            <a onClick={(e) => insertNewTable(e, newTableName)}>
                                {!shouldDisplayInsertNewTableLoader && <i className="fa fa-plus margin-right-xs" />}
                                {shouldDisplayInsertNewTableLoader && <span className="loading" />}
                            </a>
                            <input type="submit" hidden />
                        </form>
                        {!!insertNewTableError && (
                            <div className="margin-top">
                                <span>{insertNewTableError}</span>
                            </div>
                        )}
                    </AddTableSectionWrapper>
                </div>
            )}
        </div>
    );
};

export default AirtableCustomizeSetupComponent;
