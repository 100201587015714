import { angularToReact } from '@tonkean/angular-components';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

const TnkTabsFieldSelector = angularToReact<{
    groupId: string;
    workflowVersionId: string;
    logicId?: string;
    tooltip?: string; // Optional. A tooltip the will be displayed above the button.

    onFieldSelectedReact: (selectedField, expressionNode) => void;
    globalTabsOnly?: boolean; // When true, will not show anything related to initiative like fields.
    additionalTabs?: TonkeanExpressionAdditionalTab[]; // Array of tabs to ADD to the default ones
    paramForTabs?: any;
    tabsFieldSelectorModNarrow?: boolean; // Making the width of the tnkTabsFieldSelector narrower.
    tabsFieldSelectorModFixedWidth?: boolean; // Making the width of the tnkTabsFieldSelector narrower.
    showFormulasTab?: boolean; // Hiding or showing the formula tab. Defaults to true.
    onPopoverOpen?: (arg: any) => any;
    isFromHtmlEditor?: boolean;
    popoverPlacement?: string;
    buttonClassOverride?: string;

    automationIdentifierExpressionAddField?: string;

    showPeopleDirectories?: boolean;
    peopleDirectories?: any[]; // People directories to show in picker
    defaultTabId?: string; // Optional; if exists this tab will open by default.
    excludedTabSelectorSpecialFields?: string[]; // remove from special fields tab selector fields (for example ['TNK_TODAY']) that are considerInTypes:'FIELDS_TAB_SELECTOR' or any provided specialFieldsValuesForFeatures.
    includeTabSelectorSpecialFieldsForFeatures?: string[]; // Use 'considerInTypes' to add fields under this category to the special fields tab selector
}>('tnk-tabs-field-selector');

export default TnkTabsFieldSelector;
