import { useAngularService } from 'angulareact';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import HistoryContext from '../../entities/HistoryContext';

import { angularToReact } from '@tonkean/angular-components';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { CloseIcon, Modal, ModalBody } from '@tonkean/infrastructure';

const FieldInspect = angularToReact<{
    projectIntegration?: any;
    externalType: string;
    exampleEntity: any;
    viewOnly: boolean;
    useTracksOfGroupId: string;
    onlyUpdatable: boolean;
    workflowVersionId: string;
}>('tnk-field-inspect');

const Wrapper = styled(ModalBody)`
    .field-inspect-container {
        padding-right: 0;
    }
`;

interface Props {
    workflowVersionId?: string;
    initiative?: any;
    groupId?: string;
    shown: boolean;
    onClose(): void;
}

const SingleWorkerRunInspect: React.FC<Props> = ({ workflowVersionId, initiative, shown, onClose }) => {
    const { state } = useContext(HistoryContext);

    const syncConfigCacheManager = useAngularService('syncConfigCacheManager');
    const trackHelper = useAngularService('trackHelper');
    const projectIntegration =
        workflowVersionId && syncConfigCacheManager.getSyncConfig(workflowVersionId)?.projectIntegration;

    const [{ called, loading, data, error, args }, getInitiativeById] = useLazyAsyncMethod(
        trackHelper,
        'getInitiativeById',
    );

    const initiativeId = initiative?.id;
    useEffect(() => {
        const alreadyLoadedInitiative = called && args?.[0] === initiativeId;
        if (!initiativeId || !shown || alreadyLoadedInitiative) {
            return;
        }

        getInitiativeById(initiativeId);
    }, [called, args, shown, getInitiativeById, initiativeId]);

    return (
        <Modal open={shown && workflowVersionId && initiative} onClose={onClose}>
            <CloseIcon />
            {loading && <div className="loading-large" />}
            {data && (
                <Wrapper>
                    <FieldInspect
                        useTracksOfGroupId={state.groupId}
                        exampleEntity={data}
                        workflowVersionId={workflowVersionId!}
                        projectIntegration={projectIntegration}
                        externalType="TONKEAN"
                        onlyUpdatable={false}
                        viewOnly
                    />
                </Wrapper>
            )}
            {error && (
                <div className="common-size-xxs common-color-danger margin-top margin-bottom" ng-if="data.error">
                    Failed to load item preview.
                </div>
            )}
        </Modal>
    );
};

export default SingleWorkerRunInspect;
