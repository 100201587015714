import React, { useState } from 'react';
import styled from 'styled-components';

import SmartSearchActionButton from './SmartSearchActionButton';

import { AITypewriter } from '@tonkean/ai-builder';
import { AppearFromTopAnimation } from '@tonkean/infrastructure';
import { SmartConversationResponseText } from '@tonkean/smart-conversation';
import type { SmartSearchSuggestAction } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const ResponseTextContainer = styled.div`
    grid-area: text;
`;

const ResponseButtonsContainer = styled(AppearFromTopAnimation)`
    grid-area: buttons;
`;

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    suggestedAction: SmartSearchSuggestAction;
    onAnimationEnd: () => void;
    skipAnimation?: boolean;
    conversationId?: string;
}

const SmartSearchSuggestActionCategoryView: React.FC<Props> = ({
    projectId,
    suggestedAction,
    onAnimationEnd,
    skipAnimation,
    conversationId,
}) => {
    const [showActions, setShowActions] = useState(false);

    return (
        <>
            <ResponseTextContainer>
                <SmartConversationResponseText>
                    <AITypewriter
                        skipAnimation={skipAnimation}
                        text={suggestedAction.answerText}
                        onTypingDone={setShowActions}
                    />
                </SmartConversationResponseText>
            </ResponseTextContainer>

            {showActions && (
                <ResponseButtonsContainer skipAnimation={skipAnimation} onAnimationEnd={onAnimationEnd}>
                    <SmartSearchActionButton
                        projectId={projectId}
                        action={suggestedAction}
                        conversationId={conversationId}
                    />
                </ResponseButtonsContainer>
            )}
        </>
    );
};

export default SmartSearchSuggestActionCategoryView;
