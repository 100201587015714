import React from 'react';

import IntakeProgressTracker from '../IntakeProgressTracker/components/IntakeProgressTracker';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';

export interface HorizontalIntakeProgressTrackerPresenterProps {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    currentCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    currentFormId?: TonkeanId<TonkeanType.FORM>;
    themeColor?: Color;
    title?: string;
}

const HorizontalIntakeProgressTrackerPresenter: React.FC<HorizontalIntakeProgressTrackerPresenterProps> = ({
    workflowVersionId,
    currentCustomTriggerId,
    currentFormId,
    themeColor,
    title,
}: HorizontalIntakeProgressTrackerPresenterProps) => {
    return (
        <IntakeProgressTracker
            workflowVersionId={workflowVersionId}
            currentCustomTriggerId={currentCustomTriggerId}
            currentFormId={currentFormId}
            title={title}
            themeColor={themeColor}
            isVertical={false}
        />
    );
};

export default HorizontalIntakeProgressTrackerPresenter;
