import type { AccessControlDefinition } from '@tonkean/tonkean-entities';
import { AccessControlType, IntegrationType, IpRestrictionType } from '@tonkean/tonkean-entities';

/**
 * Getting the relevant access control definition by integrationType
 * mostly for old but supported integrationTypes
 *
 * @param integrationType of the current integration
 */
const getDefaultAccessControlDefinition = (integrationType?: IntegrationType): AccessControlDefinition => {
    switch (integrationType) {
        case IntegrationType.EMAILWEBHOOK:
            return {
                accessControlType: AccessControlType.EMAIL_INBOX,
                fromEmailDomains: {
                    isEnabled: false,
                    emailDomains: [],
                },
                fromEmailAddresses: {
                    isEnabled: false,
                    emailAddresses: [],
                },
            };
        case IntegrationType.WEBHOOK:
        case IntegrationType.NO_CODE_DATA_SOURCE:
            return {
                accessControlType: AccessControlType.WEBHOOK,
                tokenRestriction: {
                    token: '',
                    headerName: '',
                    isEnabled: false,
                },
                ipRestrictionGroups: {
                    ipRestrictions: [
                        {
                            displayName: '',
                            ipRangeValues: [
                                {
                                    fromIp: '',
                                    toIp: '',
                                    ipRestrictionType: IpRestrictionType.SINGLE_IP,
                                },
                            ],
                        },
                    ],
                    isEnabled: false,
                },
            };
        default:
            return {
                accessControlType: AccessControlType.NATIVE_INTEGRATION,
            };
    }
};

export default getDefaultAccessControlDefinition;
