import React from 'react';

interface Props {}

const ProcessBuilderAnalyzeAndOptimizeMessage: React.FC<Props> = () => {
    return (
        <div>
            Begin by writing a summary of your process, either as a bulleted list or in long text form. When finished,
            select <b>Analyze & Optimize</b>.
        </div>
    );
};

export default ProcessBuilderAnalyzeAndOptimizeMessage;
