import { atom } from 'recoil';

import type ChainOfEventSidePaneParams from '../entities/ChainOfEventSidePaneParams';

const chainOfEventsSidePaneState = atom<ChainOfEventSidePaneParams>({
    key: 'chainOfEventsSidePaneState',
    default: { isOpen: false },
});

export default chainOfEventsSidePaneState;
