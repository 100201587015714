import type { IHttpResponse } from 'angular';

import { SavingState } from '@tonkean/tonkean-entities';

/**
 * Converts the parameters of an api call to the relevant saving state
 * @param loading - Whether the api call is still loading
 * @param error - Whether the api call resulted an error
 * @param called - Whether the api call was initiated
 */
export function toSavingState(loading: boolean, error: IHttpResponse<any> | undefined, called: boolean): SavingState {
    if (loading) {
        return SavingState.LOADING;
    }
    if (error) {
        return SavingState.ERROR;
    }
    if (called) {
        return SavingState.SAVED;
    }
    return SavingState.NONE;
}
