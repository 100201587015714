import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

import formatNumber from '../utils/formatNumber';

import { type FieldDefinition, FieldType } from '@tonkean/tonkean-entities';

const usePreviewValue = () => {
    const fieldDisplay = useAngularService('fieldDisplay');

    return useCallback(
        (value, fieldDefinition: FieldDefinition) => {
            if (Array.isArray(value)) {
                return value?.join(fieldDefinition.outputMultiValueSeparator);
            } else {
                const prefix = fieldDefinition.displayFormatPrefix || '';
                const suffix = fieldDefinition.displayFormatPostfix || '';

                if (value === undefined || value === null) {
                    return '';
                }

                if (fieldDefinition.fieldType === FieldType.Number && !Number.isNaN(Number(value))) {
                    return prefix + formatNumber(value, fieldDefinition)?.toString() + suffix;
                }

                return fieldDisplay.evaluateFieldDisplayValue(
                    fieldDefinition.fieldType,
                    fieldDefinition?.displayFormat,
                    value,
                    value,
                    fieldDefinition?.urlLabel ? fieldDefinition.urlLabel : value,
                );
            }
        },
        [fieldDisplay],
    );
};

export default usePreviewValue;
