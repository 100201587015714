import React from 'react';
import styled from 'styled-components';

import ActionButtonSharedSizingCss from './actionButtons/ActionButtonSharedSizingCss';

import { Placeholder } from '@tonkean/infrastructure';
import type { Widget } from '@tonkean/tonkean-entities';
import type { ActionsItemWidgetConfiguration } from '@tonkean/tonkean-entities';

const ActionButtonPlaceholder = styled(Placeholder)`
    ${ActionButtonSharedSizingCss}
`;

interface Props {
    widget: Widget<ActionsItemWidgetConfiguration>;
}

const ActionsItemWidgetLoadingSkeleton: React.FC<Props> = ({ widget }) => {
    return (
        <>
            {widget.configuration?.actions?.map((action) => (
                <ActionButtonPlaceholder key={action.id} $width="135px" $height="30px" />
            ))}
        </>
    );
};

export default ActionsItemWidgetLoadingSkeleton;
