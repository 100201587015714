<div class="worker-history-items-container clearfix" id="tnk-worker-history">
    <!-- Refresh -->
    <span
        ng-show="!data.loading"
        class="svg-icon-md pointer pull-right"
        ng-click="loadHistory(true)"
        uib-tooltip="Refresh"
        tooltip-placement="bottom-right"
    >
        <tnk-icon src="/images/icons/refresh.svg"></tnk-icon>
    </span>

    <!-- Empty State -->
    <div ng-if="!data.items.length && !data.loading" class="common-color-grey">
        No history (Test runs are not included)
    </div>

    <!-- Loading State -->
    <div ng-if="(!data.items.length && data.loading) || data.hardLoading" class="flex mod-justify-center">
        <i class="loading-large"></i>
    </div>

    <!-- History Sections -->
    <div
        ng-repeat="section in data.historySections"
        ng-show="section.items.length && !data.hardLoading"
        class="margin-bottom-xlg"
    >
        <div class="flex-vmiddle margin-bottom">
            <div class="common-size-xxxs common-color-light-grey2">{{ section.label }}</div>

            <div class="flex-grow"></div>
            <i ng-if="data.loading" class="loading"></i>
        </div>
        <div ng-repeat="gather in section.items | orderBy:'-created'">
            <tnk-worker-history-item
                id="gather.id"
                data="data.workerHistoryData"
                group-id="data.groupId || data.filter.group.id"
                ui-actions="workerHistoryUiActions"
                close-side-pane="data.closeSidePane"
            ></tnk-worker-history-item>
        </div>
    </div>

    <!-- Load more -->
    <div class="flex mod-justify-center padding-top">
        <button
            ng-if="data.hasMore && !data.loading"
            ng-click="loadHistory()"
            class="btn btn-slim btn-primary bot-side-pane-block-click-outside"
        >
            Load More
        </button>
        <i ng-if="data.items.length > 0 && data.loading" class="loading-medium"></i>
    </div>
</div>
