import { reactToAngular } from 'angulareact';

import { ContractFieldSelector } from '@tonkean/contracts';

export default angular
    .module('tonkean.app')
    .component(
        'tnkContractFieldSelector',
        reactToAngular(ContractFieldSelector, [
            'options',
            'loading',
            'selectedField',
            'onChange',
            'contractFieldType',
            'name',
            'isClearable',
            'disabled',
        ]),
    );
