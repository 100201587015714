import FormPageModal from './FormPageModal';

import { reactToAngular } from '@tonkean/angular-components';

angular
    .module('tonkean.app')
    .component(
        'tnkFormPageModal',
        reactToAngular(FormPageModal, [
            'onClose',
            'onInitiativeCreated',
            'formId',
            'workflowVersionId',
            'formVersionType',
            'initiativeId',
            'parentInitiativeId',
            'open',
        ]),
    );
