import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function FacebookAdsCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,

        loading: false,
    };

    ctrl.$onInit = function () {
        if ($scope.data.configuredLogic && $scope.data.definition) {
            initializeEditMode();
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.onEmailTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.email = originalExpression;
        $scope.data.evaluatedEmail = evaluatedExpression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFirstNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.firstName = originalExpression;
        $scope.data.evaluatedFirstName = evaluatedExpression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLastNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.lastName = originalExpression;
        $scope.data.evaluatedLastName = evaluatedExpression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        const definition = $scope.data.definition;
        if (definition) {
            if ($scope.data.evaluatedEmail) {
                definition.email = {};
                definition.email.originalExpression = $scope.data.email;
                definition.email.evaluatedExpression = $scope.data.evaluatedEmail;
            }

            if ($scope.data.evaluatedFirstName) {
                definition.firstName = {};
                definition.firstName.originalExpression = $scope.data.firstName;
                definition.firstName.evaluatedExpression = $scope.data.evaluatedFirstName;
            }

            if ($scope.data.evaluatedLastName) {
                definition.lastName = {};
                definition.lastName.originalExpression = $scope.data.lastName;
                definition.lastName.evaluatedExpression = $scope.data.evaluatedLastName;
            }

            definition.audienceId = $scope.data.audienceId;
            definition.isRemove = $scope.data.isRemove;
        }

        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (
            customTriggerAction &&
            customTriggerAction.customTriggerActionDefinition &&
            customTriggerAction.customTriggerActionDefinition.performedActionDefinition
        ) {
            const definition = customTriggerAction.customTriggerActionDefinition.performedActionDefinition;

            if (definition.email) {
                $scope.data.email = definition.email.originalExpression;
                $scope.data.evaluatedEmail = definition.email.evaluatedExpression;
            }

            if (definition.firstName) {
                $scope.data.firstName = definition.firstName.originalExpression;
                $scope.data.evaluatedFirstName = definition.firstName.evaluatedExpression;
            }

            if (definition.lastName) {
                $scope.data.lastName = definition.lastName.originalExpression;
                $scope.data.evaluatedLastName = definition.lastName.evaluatedExpression;
            }
            $scope.data.audienceId = definition.audienceId;
            $scope.data.isRemove = definition.isRemove;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller(
        'FacebookAdsCustomActionsDefinitionCtrl',
        lateConstructController(FacebookAdsCustomActionsDefinitionCtrl),
    );
