import { FormQuestionType, PersonInquirySendTo, SendToGroupOfPeopleAs } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { getStatesColors } from '@tonkean/constants';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function PersonInquiryLogicConfigurationCtrl(
    $rootScope,
    $scope,
    $q,
    $timeout,
    $state,
    projectManager,
    utils,
    tonkeanUtils,
    authenticationService,
    customTriggerManager,
    customFieldsManager,
    communicationIntegrationsService,
    consts,
    modal,
    workflowVersionManager,
    syncConfigCacheManager,
    formManager,
) {
    const ctrl = this;
    const logicBlockTypes = consts.getLogicBlockTypes();
    $scope.pm = projectManager;
    $scope.cis = communicationIntegrationsService;
    $scope.as = authenticationService;
    $scope.tonkeanUtils = tonkeanUtils;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        enableBotButtons: ctrl.enableBotButtons,
        includeFormAttachment: ctrl.includeFormAttachment,
        includeFieldAttachment: ctrl.includeFieldAttachment,
        includeItemInterfaceAttachment: ctrl.includeItemInterfaceAttachment,
        openFieldModal: ctrl.openFieldModal,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        availableColors: getStatesColors(),
        defaultMessageColor: '#e8e8e8',

        // "to" configuration.
        channelOrPersonSelectionConfiguration: {},
        threadReplyDefinition: {
            attachmentsStorageProjectIntegrationId: null,
        },
        existingChannelOrPersonSelectionConfiguration: null,
        // "from" configuration.
        fromPersonSelectionConfiguration: {},
        fromExistingPersonSelectionConfiguration: null,
        fromExistingServiceAccountConfiguration: null,

        botMessageText: null,
        evaluatedBotMessageText: null,
        evaluatedPostText: '{{TNK_TITLE}}',
        emailSubject: null,
        sendAsPlainText: true,
        evaluatedEmailSubject: null,
        buttons: [],
        existingFields: [],
        nonEditableExistingFields: [],
        monitorRepliesOnOriginalMessage: {
            isActive: false,
        },
        monitorRepliesIsLoading: false,
        saveAggregatedAlternativeId: false,
        addInitiativePageLink: false,
        messageColor: '',
        formType: 'WEBFORM',
        hasPlaceholderButtons: false,
        buttonsLoading: false,
        formId: null,
        form: null,
        itemInterfaceId: null,
        hasParentMonitoringThread: false,
        hasSlackDatasource: false,
        selectedCommunicationProjectIntegration: null,
        communicationProjectIntegrationOptions: $scope.pm.project.communicationIntegrations,
        slackSyncAndReplyOnRootMonitorItem: false,
        selectedField: null,
        specialFieldsForFeatures: ctrl.specialFieldsForFeatures,
        formMatchedEntityFieldId: null,

        customActionsOnFields: [
            {
                name: 'shortFieldAction',
                action: (field) => (field.isShort = !field.isShort),
            },
        ],

        sendToType: PersonInquirySendTo.CUSTOM,
        peopleDirectoryIds: [],
        peopleDirectoriesNameExpression: undefined,
        sendToGroupOfPeopleAs: SendToGroupOfPeopleAs.PEOPLE_GROUP,
        state: $state,
        showFormDefaultDataStorage: true,
        environment: $scope.wvm.isDraftVersion ? 'DRAFT' : 'PUBLISH',
        disableStorageSelection: false,
        messageSenderDisclaimer: $scope.pm.project.features.tonkean_feature_external_email_from_address_disclaimer,
    };

    $scope.data.messageColor = $scope.data.defaultMessageColor;

    /**
     * Occurs on component initialization.
     */
    ctrl.$onInit = function () {
        $rootScope.$on('formUpdate', (event, form) => {
            if ($scope.data.formId === form?.id) {
                $scope.data.form = form;
            }
        });

        $scope.data.selectedCommunicationProjectIntegration = getSelectedCommunicationIntegrationOrProjectDefault(
            $scope.data.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                ?.communicationProjectIntegrationId,
        );

        $scope.data.sendToType =
            $scope.data.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition.sendToType ??
            PersonInquirySendTo.CUSTOM;
        $scope.data.peopleDirectoriesNameExpression =
            $scope.data.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                .peopleDirectoriesNameExpression ?? {};
        $scope.data.sendToGroupOfPeopleAs =
            $scope.data.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
                .sendToGroupOfPeopleAs ?? SendToGroupOfPeopleAs.PEOPLE_GROUP;

        hasSlackMessagesAsDataSource();
    };

    function hasSlackMessagesAsDataSource() {
        const logicActionDefinition =
            $scope.data.configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition;

        if (!$scope.data.selectedCommunicationProjectIntegration) {
            $scope.data.selectedCommunicationProjectIntegration = getSelectedCommunicationIntegrationOrProjectDefault(
                logicActionDefinition?.communicationProjectIntegrationId,
            );
        }

        const hasSlackMessagesAsDataSource = syncConfigCacheManager.workflowVersionHasDatasourceOfType(
            $scope.data.workflowVersionId,
            'SLACK_APP',
            'Message',
        );

        $scope.data.slackSyncAndReplyOnRootMonitorItem =
            $scope.data.channelOrPersonSelectionConfiguration.targetType === 'thread' &&
            !!hasSlackMessagesAsDataSource &&
            $scope.data.channelOrPersonSelectionConfiguration.replyToRootMonitoredItem;
    }

    function getSelectedCommunicationIntegrationOrProjectDefault(communicationProjectIntegration) {
        if (communicationProjectIntegration && $scope.pm.getProjectIntegrationById(communicationProjectIntegration)) {
            return $scope.pm.getProjectIntegrationById(communicationProjectIntegration);
        }
    }

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    $scope.onCommunicationIntegrationChanged = function (selectedCommunicationProjectIntegration) {
        $scope.data.selectedCommunicationProjectIntegration = selectedCommunicationProjectIntegration;
        if ($scope.data.selectedCommunicationProjectIntegration.integrationType !== 'SLACK') {
            $scope.data.formType = 'WEBFORM';
        }

        $scope.onActionsChanged(true);
    };

    /**
     * Occurs once a channel or person selection is changed.
     */
    $scope.onChannelOrPersonSelectionChanged = function (channelOrPersonSelectionConfiguration, shouldSaveLogic) {
        $scope.data.channelOrPersonSelectionConfiguration = channelOrPersonSelectionConfiguration;

        // Whether the user selected to send via email and the dialog form type option is selected
        if ($scope.data.channelOrPersonSelectionConfiguration.forceEmail && $scope.data.formType === 'DIALOG') {
            // In this condition, only webform option is available.
            $scope.data.formType = 'WEBFORM';
        }

        hasSlackMessagesAsDataSource();

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Opens edit form modal.
     */
    $scope.editForm = function (formId, formType) {
        $rootScope.$broadcast('editForm', { formId, formType });
    };

    /**
     * Updates the Form Fill button name.
     */
    $scope.updateFormFillButtonName = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.fillFormButtonLabel = originalExpression;
        $scope.data.evaluatedFillFormButtonLabel = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once a "from" person selection is changed.
     */
    $scope.onFromPersonSelectionChanged = function (fromPersonSelectionConfiguration, shouldSaveLogic) {
        $scope.data.fromPersonSelectionConfiguration = fromPersonSelectionConfiguration;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs when the form type radio button selection is changed.
     * @param formType
     */
    $scope.selectFormType = function (formType) {
        $timeout(() => {
            if ($scope.data.sendToType === PersonInquirySendTo.PEOPLE_DIRECTORY) {
                $scope.data.formType = formType;
                $scope.onActionsChanged(true);
            } else if (
                $scope.data.slackSyncAndReplyOnRootMonitorItem ||
                (!$scope.data.channelOrPersonSelectionConfiguration.forceEmail &&
                    $scope.data.selectedCommunicationProjectIntegration.integrationType === 'SLACK')
            ) {
                $scope.data.formType = formType;
                $scope.onActionsChanged(true);
            }
        });
    };

    /**
     * Adds a new button.
     */
    $scope.addButton = function () {
        if (ctrl.createNewLogicCallback) {
            ctrl.createNewLogicCallback({
                params: {
                    parentNode: $scope.data.configuredLogic,
                    disabled: projectManager.groupsMap[$scope.data.groupId].workerEnabled,
                    enricher: consts.getAddButtonBlockCreateAfterOption().triggerParamsEnricher,
                },
            });
        }
    };

    /**
     * Occurs on fields changed.
     */
    $scope.onFieldsChange = function (fields) {
        $scope.data.existingFields = fields;
        $scope.data.nonEditableExistingFields = fields.filter((field) => !field.isEditable);
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.botMessageText = originalExpression;
        $scope.data.evaluatedBotMessageText = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once the email subject expression has changed.
     */
    $scope.onEmailSubjectTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.emailSubject = originalExpression;
        $scope.data.evaluatedEmailSubject = evaluatedExpression;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onMessageColorSelected = function (color) {
        $scope.data.messageColor = color;
        $scope.onActionsChanged(true);
    };

    $scope.onToggleAddInitiativePageLink = function () {
        $scope.data.addInitiativePageLink = !$scope.data.addInitiativePageLink;
        $scope.onActionsChanged(true);
    };

    $scope.onFormCreated = function (form) {
        $scope.data.formId = form.id;
        $scope.data.form = form;
        $scope.onActionsChanged(true);
    };

    $scope.onSaveAggregatedAlternativeIdChanged = function (value) {
        $scope.data.saveAggregatedAlternativeId = value;
        $scope.onActionsChanged(true);
    };

    $scope.onAttachmentsChanged = function (attachments, shouldSaveLogic) {
        $scope.data.attachments = attachments;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic, saveButtons, childrenToCreate) {
        if (ctrl.onActionsChanged) {
            const logicConfigurationObject = {
                actions: [],
            };

            const logicType = $scope.data.configuredLogic.node.customTriggerType;

            // Message method
            switch ($scope.data.channelOrPersonSelectionConfiguration.targetType) {
                case 'person': {
                    const action = tonkeanUtils.enrichPersonActionDefinition(
                        logicType,
                        logicConfigurationObject,
                        $scope.data.channelOrPersonSelectionConfiguration,
                        $scope.data.botMessageText,
                        $scope.data.evaluatedBotMessageText,
                    );
                    if (action) {
                        action.definition.forceEmail = $scope.data.channelOrPersonSelectionConfiguration.forceEmail;
                        action.definition.communicationProjectIntegrationId = action.definition.forceEmail
                            ? undefined
                            : $scope.data.selectedCommunicationProjectIntegration?.id;
                        action.definition.saveAggregatedAlternativeId = $scope.data.saveAggregatedAlternativeId;
                    }
                    break;
                }
                case 'channel': {
                    const action = {
                        type: logicType,
                        definition: {
                            text: $scope.data.botMessageText,
                            evaluatedText: $scope.data.evaluatedBotMessageText,
                        },
                    };

                    action.definition.notificationSettings =
                        $scope.data.channelOrPersonSelectionConfiguration.notificationSettings;
                    action.definition.communicationProjectIntegrationId =
                        $scope.data.selectedCommunicationProjectIntegration?.id;
                    action.definition.saveAggregatedAlternativeId = $scope.data.saveAggregatedAlternativeId;

                    logicConfigurationObject.actions.push(action);
                    break;
                }

                case 'thread': {
                    const action = {
                        type: logicType,
                        definition: {
                            text: $scope.data.botMessageText,
                            evaluatedText: $scope.data.evaluatedBotMessageText,
                        },
                    };

                    action.definition.replyToPreviousMessage =
                        $scope.data.channelOrPersonSelectionConfiguration.replyToPreviousMessage;
                    action.definition.replyToRootMonitoredItem =
                        $scope.data.channelOrPersonSelectionConfiguration.replyToRootMonitoredItem;
                    action.definition.saveAggregatedAlternativeId = $scope.data.saveAggregatedAlternativeId;
                    action.definition.communicationProjectIntegrationId = null;

                    logicConfigurationObject.actions.push(action);
                    hasSlackMessagesAsDataSource();
                    break;
                }

                default: {
                    break;
                }
            }

            if (!logicConfigurationObject.actions.length) {
                logicConfigurationObject.actions.push({
                    type: logicType,
                    definition: {
                        text: $scope.data.botMessageText,
                        evaluatedText: $scope.data.evaluatedBotMessageText,
                    },
                });
            }

            // Since we rely on enrichPersonActionDefinition to create our action object,
            // and it takes some digest cycles to get the person selection component to initialize
            // our selection object, we check for existence of the definition.
            if (logicConfigurationObject.actions[0]) {
                // Set the from property.
                const definition = logicConfigurationObject.actions[0].definition;
                if ($scope.data.fromPersonSelectionConfiguration) {
                    if ($scope.data.fromPersonSelectionConfiguration.tonkeanSelected) {
                        // If tonkean is selected as the from sender, we clear the from property on the definition (Tonkean is the default behaviour).
                        definition.from = null;
                        definition.fromServiceAccountExpressionDefinition = null;
                    } else if ($scope.data.fromPersonSelectionConfiguration.serviceAccountSelected) {
                        definition.from = null;
                        definition.fromServiceAccountExpressionDefinition = $scope.data.fromPersonSelectionConfiguration.fromServiceAccountExpressionDefinition;
                    } else {
                        definition.from = tonkeanUtils.buildPersonSelectionConfiguration(
                            $scope.data.fromPersonSelectionConfiguration,
                        );
                        definition.fromServiceAccountExpressionDefinition = null;
                    }
                }

                // If its the default, reset to null.
                definition.color =
                    $scope.data.messageColor !== $scope.data.defaultMessageColor ? $scope.data.messageColor : null;
                definition.hideInitiativePageLink = !$scope.data.addInitiativePageLink;

                definition.formType = $scope.data.formType;

                if ($scope.data.form && $scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES) {
                    definition.dataStorageId = $scope.data.dataStorageId;
                    definition.folderIdExpression = $scope.data.folderIdExpression;
                }

                definition.fillFormButtonLabel = $scope.data.fillFormButtonLabel;
                definition.evaluatedFillFormButtonLabel = $scope.data.evaluatedFillFormButtonLabel;

                // Fields to append to message
                definition.fields = $scope.data.nonEditableExistingFields
                    .map((existingField) => {
                        const field = {
                            isShort: existingField.isShort,
                        };

                        if (existingField.isSpecialField) {
                            field.specialFieldId = existingField.id;
                        } else {
                            field.completeField = existingField;
                            field.fieldDefinitionId = existingField.id;
                        }
                        return field;
                    })
                    .filter((loadedField) => loadedField);

                // Setting the field selected
                if ($scope.data.selectedField) {
                    const field = {
                        isShort: null,
                        isEditable: true,
                    };
                    if ($scope.data.selectedField.isSpecialField) {
                        // If it's a special field definition (tonkean field).
                        field.specialFieldId = $scope.data.selectedField.id;
                    } else if (!$scope.data.selectedField.isSpecialField) {
                        // If it's a regular field definition.
                        field.completeField = $scope.data.selectedField;
                        field.fieldDefinitionId = $scope.data.selectedField.id;
                    }
                    definition.fields.push(field);
                }

                definition.sendAsPlainText = $scope.data.sendAsPlainText;
                definition.emailSubject = $scope.data.emailSubject;
                definition.evaluatedEmailSubject = $scope.data.evaluatedEmailSubject;
                definition.formId = $scope.data.formId;
                definition.itemInterfaceId = $scope.data.itemInterfaceId;
                definition.formMatchedEntityFieldId = $scope.data.formMatchedEntityFieldId;
                definition.attachments = $scope.data.attachments || null;
                definition.peopleDirectoryIds = $scope.data.peopleDirectoryIds;
                definition.peopleDirectoriesNameExpression = $scope.data.peopleDirectoriesNameExpression;
                definition.sendToType = $scope.data.sendToType;
                definition.sendToGroupOfPeopleAs = $scope.data.sendToGroupOfPeopleAs;

                definition.attachmentsStorageProjectIntegrationId =
                    $scope.data.threadReplyDefinition.attachmentsStorageProjectIntegrationId;
            }

            if (childrenToCreate && childrenToCreate.length) {
                logicConfigurationObject.childrenCustomTriggersToCreate = childrenToCreate;
            }

            return ctrl
                .onActionsChanged({
                    definition: logicConfigurationObject,
                    shouldSaveLogic,
                })
                .then(() => {
                    if ($scope.data.buttonsLoading) {
                        $scope.data.buttonsLoading = false;
                        initializeEditMode();
                        $scope.onActionsChanged(false);
                        hasSlackMessagesAsDataSource();
                    }
                });
        }
    };

    /**
     * This function sets the selected field from the 'Select a field to ask about' part in the `$scope.data`
     * @param {CustomTriggerAction} customTriggerAction
     */
    function setSelectedField(customTriggerAction) {
        const field = customTriggerAction.customTriggerActionDefinition?.fields?.find((f) => f.isEditable);

        // field is undefined no fields are editable
        if (!field) {
            return;
        }

        if (field.fieldDefinitionId) {
            // If it's a regular field definition.
            const fieldDefinitions = customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId];
            $scope.data.selectedField = utils.findFirst(fieldDefinitions, (def) => def.id === field.fieldDefinitionId);
        } else if (field.specialFieldId) {
            // If it's a special field definition (tonkean field).
            const specialFields = getSpecialFieldsForFeatures(false, $scope.data.specialFieldsForFeatures);
            $scope.data.selectedField = utils.findFirst(specialFields, (def) => def.id === field.specialFieldId);
        }
    }

    /**
     * Edit mode initialization.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            $scope.data.configuredLogic.node.customTriggerType,
        );

        // In order to reveal the "Reply in thread" option, an asking parent for the logic is a must.
        $scope.data.hasParentMonitoringThread = customTriggerManager.isParentsTypeBeforeMonitoringActionItem(
            $scope.data.workflowVersionId,
            $scope.data.configuredLogic.node.id,
            [
                logicBlockTypes.PERSON_INQUIRY.type,
                logicBlockTypes.SEND_NOTIFICATION.type,
                logicBlockTypes.BOT_THREAD_REPLY.type,
                logicBlockTypes.SEND_FORM.type,
            ],
        );
        $scope.data.hasSlackMessagesAsDatasource = syncConfigCacheManager.workflowVersionHasDatasourceOfType(
            $scope.data.workflowVersionId,
            'SLACK_APP',
            'Message',
        );

        $scope.data.hasTeamsMessagesAsDataSource = syncConfigCacheManager.workflowVersionHasDatasourceOfType(
            $scope.data.workflowVersionId,
            'MICROSOFTTEAMSAPP',
            'Message',
        );

        $scope.data.hasEmailMessagesAsDatasource = syncConfigCacheManager.workflowVersionHasDatasourceOfType(
            $scope.data.workflowVersionId,
            'EMAILWEBHOOK',
            'Email',
        );

        if (customTriggerAction) {
            // Inquired person configuration
            $scope.data.existingChannelOrPersonSelectionConfiguration =
                customTriggerAction.customTriggerActionDefinition;
            // "From" person configuration.
            if (customTriggerAction.customTriggerActionDefinition) {
                $scope.data.fromExistingPersonSelectionConfiguration =
                    customTriggerAction.customTriggerActionDefinition.from;

                $scope.data.fromExistingServiceAccountConfiguration =
                    customTriggerAction.customTriggerActionDefinition.fromServiceAccountExpressionDefinition;
            }

            $scope.data.threadReplyDefinition = {
                attachmentsStorageProjectIntegrationId:
                    customTriggerAction.customTriggerActionDefinition.attachmentsStorageProjectIntegrationId,
            };

            // Cast boolean or undefined to boolean
            const hideInitiativeValue =
                customTriggerAction.customTriggerActionDefinition.hideInitiativePageLink === undefined
                    ? true
                    : customTriggerAction.customTriggerActionDefinition.hideInitiativePageLink;

            // Hide initiative page link
            $scope.data.addInitiativePageLink = !hideInitiativeValue;

            // Color
            $scope.data.messageColor =
                customTriggerAction.customTriggerActionDefinition.color || $scope.data.defaultMessageColor;

            // The type of the form (currently can be, Dialog / Webform)
            $scope.data.formType = customTriggerAction.customTriggerActionDefinition.formType || 'WEBFORM';

            // Message text
            $scope.data.botMessageText = customTriggerAction.customTriggerActionDefinition.text;
            $scope.data.evaluatedBotMessageText = customTriggerAction.customTriggerActionDefinition.evaluatedText;

            // Form preview
            $scope.data.formId = customTriggerAction.customTriggerActionDefinition.formId;
            $scope.data.formMatchedEntityFieldId =
                customTriggerAction.customTriggerActionDefinition.formMatchedEntityFieldId;

            $scope.data.fillFormButtonLabel = customTriggerAction.customTriggerActionDefinition.fillFormButtonLabel;
            $scope.data.evaluatedFillFormButtonLabel =
                customTriggerAction.customTriggerActionDefinition.evaluatedFillFormButtonLabel;

            $scope.data.form = null;
            if ($scope.data.formId) {
                formManager.getWorkerForm($scope.data.workflowVersionId, $scope.data.formId).then((form) => {
                    $scope.data.form = form;
                    fillFormLabel();

                    if ($scope.data.form && $scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES) {
                        $scope.data.dataStorageId = customTriggerAction.customTriggerActionDefinition.dataStorageId;
                        $scope.data.folderIdExpression =
                            customTriggerAction.customTriggerActionDefinition.folderIdExpression;
                        if ($scope.data.dataStorageId) {
                            $scope.data.disableStorageSelection = true;
                        }
                    }
                });
            }

            // Custom interface preview
            $scope.data.itemInterfaceId = customTriggerAction.customTriggerActionDefinition.itemInterfaceId;

            // Email subject
            $scope.data.emailSubject = customTriggerAction.customTriggerActionDefinition.emailSubject;
            $scope.data.evaluatedEmailSubject = customTriggerAction.customTriggerActionDefinition.evaluatedEmailSubject;

            // Whether to send it as plain text or HTML
            $scope.data.sendAsPlainText = customTriggerAction.customTriggerActionDefinition.sendAsPlainText !== false;

            $scope.data.attachments = customTriggerAction.customTriggerActionDefinition.attachments;

            $scope.data.saveAggregatedAlternativeId =
                customTriggerAction.customTriggerActionDefinition.saveAggregatedAlternativeId;
            if ($scope.data.configuredLogic.impacts) {
                // Buttons
                $scope.data.buttons = $scope.data.configuredLogic.impacts
                    .filter((impact) => impact.node.customTriggerType === logicBlockTypes.BOT_BUTTON_PRESSED.type)
                    .map((impact) => {
                        return {
                            customTriggerId: impact.node.id,
                            text: impact.node.displayName,
                        };
                    });
            } else {
                $scope.data.buttons = [];
            }

            // Fields to append to message
            if (
                customTriggerAction.customTriggerActionDefinition.fields &&
                customTriggerAction.customTriggerActionDefinition.fields.length
            ) {
                const fieldDefinitionMap = utils.createMapFromArray(
                    customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                    'id',
                );
                const specialFieldMap = utils.createMapFromArray(
                    getSpecialFieldsForFeatures(true, ['ADDED_FIELDS_TO_BOT_MESSAGE']),
                    'id',
                );

                $scope.data.existingFields = customTriggerAction.customTriggerActionDefinition.fields
                    .filter((field) => field.fieldDefinitionId || field.specialFieldId)
                    .map((field) => {
                        let existingField;
                        if (field.fieldDefinitionId) {
                            existingField = fieldDefinitionMap[field.fieldDefinitionId];
                        } else if (field.specialFieldId) {
                            existingField = specialFieldMap[field.specialFieldId];
                        }
                        existingField.isShort = field.isShort;
                        existingField.isEditable = field.isEditable ?? false;
                        return existingField;
                    })
                    .filter((field) => field);

                $scope.data.nonEditableExistingFields = $scope.data.existingFields.filter((field) => !field.isEditable);
            }

            setSelectedField(customTriggerAction);
        }
    }

    /**
     * Fills the form label from the form data if it doesnt have a value already
     */
    function fillFormLabel() {
        if ($scope.data.form && $scope.data.form.definition && utils.isNullOrEmpty($scope.data.fillFormButtonLabel)) {
            $scope.data.fillFormButtonLabel = $scope.data.form.definition.fillFormButtonLabel;
            $scope.data.evaluatedFillFormButtonLabel = $scope.data.form.definition.fillFormButtonLabel;
        }
    }

    /**
     * Occurs once a form is selected from the dropdown.
     */
    $scope.onFormSelected = function (form) {
        $scope.data.form = form;
        $scope.data.formId = form.id;

        if ($scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES) {
            // upload files form is accessible nly through web form
            $scope.data.formType = 'WEBFORM';
        }

        fillFormLabel();
        return $scope.onActionsChanged(true);
    };

    $scope.onFormMatchedEntityCreatedOrUpdated = function (field) {
        $scope.data.formMatchedEntityFieldId = field?.id;
        $scope.onActionsChanged(true);
    };

    /**
     * Opens a confirmation modal in case we need to remove monitor items trigger
     */
    $scope.onFormSelectedOpenConfirmation = function (form) {
        let confirmationPromise = $q.resolve();

        if (
            $scope.data.form?.formQuestionType === 'COLLECT_INNER_ITEMS' &&
            form.formQuestionType !== 'COLLECT_INNER_ITEMS' &&
            hasAnyMonitorItemsImpacts()
        ) {
            confirmationPromise = modal.openQuestionConfirmModal({
                controller: 'QuestionConfirmModalCtrl',
                windowClass: 'mod-warning',
                resolve: {
                    questionConfirmModalData() {
                        return {
                            title: 'Change form type',
                            body: 'Please remove all Monitor Items action blocks before changing form.',
                            cancelLabel: 'Close',
                        };
                    },
                },
            }).result;
        }

        return confirmationPromise;
    };

    /**
     * Occurs once a custom interface is selected from the dropdown.
     */
    $scope.onItemInterfaceSelected = function (itemInterfaceId) {
        $scope.data.itemInterfaceId = itemInterfaceId;

        return $scope.onActionsChanged(true);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onHtmlEditorContentTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.botMessageText = originalExpression;
        $scope.data.evaluatedBotMessageText = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.openHtmlEditor = function () {
        let exampleInitiative = null;
        if (customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId]) {
            exampleInitiative =
                customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][
                    $scope.data.configuredLogic.node.id
                ];
        }

        modal
            .openHtmlEditor(
                $scope.data.groupId,
                $scope.data.botMessageText,
                exampleInitiative,
                false,
                $scope.data.configuredLogic.node.id,
                $scope.data.workflowVersionId,
            )
            .then((result) => {
                $scope.onHtmlEditorContentTonkeanExpressionChanged(result.html, result.evaluatedHtml, true);
            });
    };

    function hasAnyMonitorItemsImpacts() {
        return $scope.data.configuredLogic.impacts.some(
            (singleImpact) => singleImpact.node.customTriggerType === 'MONITOR_TRACKS',
        );
    }

    /**
     * Occurs once integration field selection was made.
     */
    $scope.onFieldOptionSelected = function (selectedField) {
        $scope.data.selectedField = selectedField;
        $scope.onActionsChanged(true);
    };

    $scope.onDataStorageChanged = function (selectedDataStorage, folderIdExpression) {
        $scope.data.dataStorageId = selectedDataStorage;
        $scope.data.folderIdExpression = folderIdExpression;
        if (selectedDataStorage) {
            $scope.data.disableStorageSelection = true;
        }
        $scope.onActionsChanged(true);
    };

    /**
     * If the form is of type 'upload' and has default storage definition -
     * save the storage on the custom trigger when side pane closes
     */
    $scope.$on('closeSidePaneEvent', function (e) {
        if (
            $scope.data.form &&
            $scope.data.form.formQuestionType === FormQuestionType.UPLOAD_FILES &&
            !$scope.data.dataStorageId
        ) {
            $scope.data.dataStorageId = $scope.data.form.definition.dataStorageId;
            $scope.data.folderIdExpression = $scope.data.form.definition.destinationFolderId;
            $scope.data.disableStorageSelection = true;
            $scope.onActionsChanged(true);
        }
    });

    $scope.onSendToChange = function (sendToType) {
        $timeout(() => {
            $scope.data.sendToType = sendToType;
            $scope.onActionsChanged(true);
        });
    };

    $scope.updatePeopleDirectoryIds = function (updatedPeopleDirectoryIds) {
        $timeout(() => {
            $scope.data.peopleDirectoryIds = updatedPeopleDirectoryIds;
            $scope.onActionsChanged(true);
        });
    };

    $scope.updatePeopleDirectoriesNameExpression = function (updatedPeopleDirectoriesNameExpression) {
        $timeout(() => {
            $scope.data.peopleDirectoriesNameExpression = updatedPeopleDirectoriesNameExpression;
            $scope.onActionsChanged(true);
        });
    };

    $scope.updateSendToGroupOfPeopleAs = function (sendToGroupOfPeopleAs) {
        $timeout(() => {
            $scope.data.sendToGroupOfPeopleAs = sendToGroupOfPeopleAs;
            $scope.onActionsChanged(true);
        });
    };
}

export default angular
    .module('tonkean.app')
    .controller('PersonInquiryLogicConfigurationCtrl', lateConstructController(PersonInquiryLogicConfigurationCtrl));
