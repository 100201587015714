import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import type SelectedStatusWidgetType from './SelectedStatusWidgetType';

import { Checkbox, Input, KebabMenuButton, Menu, MenuItem, Spacer } from '@tonkean/infrastructure';
import { CheckIconSVG } from '@tonkean/svg';
import { XIcon as XIconSVG } from '@tonkean/svg';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const StatusTitleStyled = styled.span<{ active: boolean }>`
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    color: ${({ active }) => (active ? Theme.colors.gray_700 : Theme.colors.gray_500)};
`;

const CheckIcon = styled(CheckIconSVG)`
    height: 12px !important;
    width: 12px !important;
    margin-right: 0 !important;
`;
const XIcon = styled(XIconSVG)`
    height: 12px !important;
    width: 12px !important;
    margin-right: 0 !important;
`;

const PencilWrapper = styled.span<{ collapseWidth: boolean; menuOpen: boolean }>`
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    height: 100%;
    padding: 4px;
    display: grid;
    place-items: center;

    ${({ collapseWidth }) =>
        collapseWidth &&
        css`
            &,
            * {
                border: 0;
                padding: 0;
            }
        `}

    ${({ menuOpen }) =>
        menuOpen &&
        css`
            background: rgba(47, 132, 220, 0.05);
            border: 1px solid #2f84dc;
            box-sizing: border-box;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-left: none;
        `}
`;

const StatusIndicator = styled.div`
    width: 2px;
    border-radius: 2px;
    background: ${Theme.colors.primaryHighlight};
    height: 100%;
`;

const StatusWrapper = styled.div<{ active: boolean }>`
    border-left-style: solid;
    border-left-width: 2px;
    border-color: ${({ active }) => (active ? Theme.colors.primaryHighlight : Theme.colors.gray_500)};
    padding-left: 5px;
    display: flex;
    align-items: center;
    padding: 13px;
`;

const BorderBox = styled.div`
    background: #ffffff;
    border: 1px solid #e9ecf1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 5px;
    padding-left: 5px;
    margin-bottom: 8px;
    width: 100%;
    justify-content: space-between;
`;

const CheckboxAndStatusWrapper = styled.div`
    display: flex;
`;

interface Props {
    onChange: (isChecked: boolean, statusId: string) => void;
    status: SelectedStatusWidgetType;
    onChangeLabel: (label: string, statusId: string) => void;
}

const StatusProgressBarRowEditor: React.FC<Props> = ({ onChange, status, onChangeLabel }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isEditingLabel, setIsEditingLabel] = useState(false);
    const [statusLabel, setStatusLabel] = useState(status.overrideLable || status.label);
    const kebabMenuRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <CheckboxAndStatusWrapper key={status.id}>
            <Checkbox
                dataAutomation="status-progress-bar-row-editor-checkbox"
                changeLabelColor={false}
                boldLabel={false}
                name={status.id}
                checked={status.isChecked}
                onChange={(event) => {
                    onChange(event.target.checked, status.id);
                }}
            />

            <Spacer width={10} />

            <BorderBox>
                <StatusWrapper active={status.isChecked}>
                    {isEditingLabel ? (
                        <>
                            <Input
                                data-automation="status-progress-bar-row-editor-edit-field-input"
                                value={statusLabel}
                                onChange={(e) => setStatusLabel(e.target.value)}
                                // onKeyDown={handleLabelInputKeyPress}
                                ref={inputRef}
                            />
                            <Button
                                data-automation="status-progress-bar-row-editor-save-edit-field-input"
                                size={ButtonSize.MEDIUM_SNUG}
                                shape={ButtonShape.RECTANGULAR}
                                onClick={() => {
                                    onChangeLabel(statusLabel, status.id);
                                    setIsEditingLabel(false);
                                }}
                                aria-label="save status label"
                                flex
                                highlighted
                            >
                                <CheckIcon />
                            </Button>
                            <Button
                                data-automation="status-progress-bar-row-editor-discard-edit-field-input"
                                size={ButtonSize.MEDIUM_SNUG}
                                shape={ButtonShape.RECTANGULAR}
                                onClick={() => setIsEditingLabel(false)}
                                aria-label="discard editing of status label"
                                flex
                                highlighted
                                outlined
                            >
                                <XIcon />
                            </Button>
                        </>
                    ) : (
                        <StatusTitleStyled
                            data-automation="status-progress-bar-row-editor-status"
                            data-automation-label={status.label}
                            active={status.isChecked}
                        >
                            <StatusIndicator />
                            {status.overrideLable || status.label}
                        </StatusTitleStyled>
                    )}
                </StatusWrapper>

                <Menu
                    nodeRef={kebabMenuRef}
                    // Fixing an issue with input not focusing because of the focus trap
                    show={menuOpen}
                    onClose={() => setMenuOpen(false)}
                    menuItems={[
                        <MenuItem
                            data-automation="fields-widget-config-field-row-edit-field"
                            key="edit"
                            onClick={() => setIsEditingLabel(true)}
                        >
                            Edit field label
                        </MenuItem>,
                        <MenuItem
                            data-automation="fields-widget-config-field-row-restore-to-default"
                            key="restore"
                            onClick={() => {
                                setStatusLabel(status.label);
                                onChangeLabel(status.label, status.id);
                            }}
                        >
                            Restore to default
                        </MenuItem>,
                    ]}
                    // nodeRef={kebabMenuRef}
                    // Fixing an issue with input not focusing because of the focus trap
                    // restoreFocusElementRef={isEditingLabel ? inputRef : kebabMenuRef}
                />

                <KebabMenuButton
                    data-automation="status-progress-bar-row-editor-add-field-kebab-menu"
                    onClick={() => setMenuOpen(true)}
                    ref={kebabMenuRef}
                    flat
                />
            </BorderBox>
        </CheckboxAndStatusWrapper>
    );
};

export default StatusProgressBarRowEditor;
