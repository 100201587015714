import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function FormAnsweredLogicConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        openFieldModal: ctrl.openFieldModal,
    };

    $scope.onActionsChanged = function (definition, shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            return ctrl.onActionsChanged({ definition, shouldSaveLogic });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('FormAnsweredLogicConfigurationCtrl', lateConstructController(FormAnsweredLogicConfigurationCtrl));
