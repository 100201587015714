import type { AngularServices } from 'angulareact';

import type { LogicImpact } from '@tonkean/tonkean-entities';

const formAnsweredValidateRootParentInSequence = (
    configuredLogic: LogicImpact,
    customTriggerManager: AngularServices['customTriggerManager'],
    customTriggerSecondaryType: string,
    customTriggerTypes?: string[],
) => {
    const parents = customTriggerManager.getWorkflowVersionLogicParents(
        configuredLogic.node.workflowVersion.id,
        configuredLogic.node.id,
    );
    return parents.some(
        (parent) =>
            parent.node.customTriggerSecondaryType === customTriggerSecondaryType ||
            configuredLogic.node.customTriggerSecondaryType === customTriggerSecondaryType ||
            customTriggerTypes?.includes(parent.node.customTriggerType) ||
            customTriggerTypes?.includes(configuredLogic.node.customTriggerType),
    );
};
export default formAnsweredValidateRootParentInSequence;
