import template from './tnkManualAddTracks.template.html?angularjs';

/**
 * A credit card component that uses Stripe to post the credit card data and passes on a a Stripe token.
 */
export default angular.module('tonkean.app').component('tnkManualAddTracks', {
    bindings: {
        initiativeLabel: '<',
        currentUserAsDefaultOwner: '<',
        onSave: '&',
    },
    template,
    controller: 'tnkManualAddTracksCtrl',
});
