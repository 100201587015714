<div class="add-linked-column-modal common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold mod-no-top">Link Field from another List</h1>

    <!-- Workflow version selection title -->
    <div class="margin-top-xlg margin-bottom-xs">Choose List:</div>

    <!-- Workflow version selection -->
    <ui-select
        class="ui-select-min-width"
        theme="bootstrap"
        ng-model="data.selectedWorkflowVersionToLinkFrom"
        ng-change="onSelectedWorkflowVersionChanged()"
    >
        <ui-select-match>{{ pm.groupsMap[data.selectedWorkflowVersionToLinkFrom.groupId].name }}</ui-select-match>
        <ui-select-choices repeat="workflowVersion in data.workflowVersions | filter:$select.search">
            <div
                ng-bind-html="pm.groupsMap[workflowVersion.groupId].name | escapeHtml | highlight: $select.search"
            ></div>
        </ui-select-choices>
    </ui-select>

    <!-- Choose column title -->
    <div ng-if="data.fieldDefinitions && data.fieldDefinitions.length" class="margin-top-lg">
        <div class="margin-bottom-xs" ng-if="data.selectedWorkflowVersionToLinkFrom">Choose Column:</div>

        <!-- Choose column selection -->
        <ui-select
            ng-if="data.selectedWorkflowVersionToLinkFrom"
            class="ui-select-min-width"
            theme="bootstrap"
            ng-model="data.selectedFieldDefinitionToLink"
        >
            <ui-select-match>{{ data.selectedFieldDefinitionToLink.name }}</ui-select-match>
            <ui-select-choices
                repeat="fieldDefinition in (data.fieldDefinitions | filter:$select.search) track by fieldDefinition.id"
            >
                <div ng-bind-html="fieldDefinition.name | escapeHtml | highlight: $select.search"></div>
            </ui-select-choices>
        </ui-select>
    </div>

    <!-- Message for when there are no additional field definitions that can be linked -->
    <div ng-if="!data.fieldDefinitions || !data.fieldDefinitions.length" class="margin-top-lg">
        All columns from list
        <strong>{{ data.selectedWorkflowVersionToLinkFrom.name }}</strong>
        are already in current list.
    </div>

    <!-- Link button -->
    <div class="flex-vmiddle margin-top-lg">
        <button
            class="btn btn-primary"
            ng-click="linkField()"
            ng-disabled="!data.selectedWorkflowVersionToLinkFrom || !data.selectedFieldDefinitionToLink"
        >
            Link
        </button>

        <!-- Loading state for when waiting for link to be done -->
        <span class="loading-small margin-left" ng-if="data.savingFieldDefinitionOnWorkflowVersion"></span>

        <!-- Error state -->
        <div
            class="common-color-danger common-size-xxxxs margin-left"
            ng-if="data.errorSavingFieldDefinitionOnWorkflowVersion"
        >
            There was an error trying to add a linked field.
        </div>
    </div>
</div>
