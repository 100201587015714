/**
 * Refreshes request so only the latest (in a designated time period) will be executed
 *
 * @param $timeout {angular.ITimeoutService}
 * @param $q {angular.IQService}
 */
function RequestThrottler($timeout, $q) {
    const _this = this;
    const promiseMap = {};

    /**
     * Also returns a Promise that will complete once the final request is done
     * @param requestId A unique id for this request type
     * @param interval - how long to wait for new requests to merge them.
     * @param serverAction An action that returns a promise that will be executed after the interval time.
     *                     Will only happen if no other request with same requestId has happened during the interval time.
     * @param successCallback An action that happens after the serverAction returned.
     *                           Will only happen if it is the latest request.
     *                           If 1 request executed the serverAction and then another was initiated before the first one returned,
     *                           only the second call will call the successCallback
     * @param useFirstRequest By default only takes into account the last request made, if this flag is true will make the first request the main one
     *                          and return it to new requesters
     */
    _this.do = function (
        requestId,
        interval,
        serverAction,
        successCallback,
        errorCallback,
        finallyCallback,
        useFirstRequest,
    ) {
        // Try to get the promise from the map.
        if (promiseMap[requestId]) {
            if (useFirstRequest) {
                return promiseMap[requestId].returnValue;
            } else {
                // If we have a promise, we first need to try and cancel it.
                $timeout.cancel(promiseMap[requestId].timeoutPromise);
            }
        }

        // Set a timeoutId so we can identify the this timeout action later on.
        const timeoutId = Date.now();
        const deffered = $q.defer();
        // Set up the timeout
        const timeoutPromise = $timeout(() => {
            $q.resolve(serverAction())
                .then((data) => {
                    if (isRequestCanceled(requestId) || !isLatestCall(requestId, timeoutId)) {
                        // If the timeoutId we're expecting is not the one we've got, discard the server response - it's not the latest one.
                        return;
                    }

                    deffered.resolve(data);
                    if (successCallback) {
                        return successCallback(data);
                    }
                })
                .catch((error) => {
                    if (isRequestCanceled(requestId) || !isLatestCall(requestId, timeoutId)) {
                        return;
                    }

                    deffered.reject(error);
                    if (errorCallback) {
                        errorCallback(error);
                    }
                })
                .finally(() => {
                    if (isLatestCall(requestId, timeoutId)) {
                        // Clean the promise map from this request since we're done (until the next time).
                        delete promiseMap[requestId];

                        if (finallyCallback) {
                            finallyCallback();
                        }
                    }
                });
        }, interval);

        // Save the promise data to the promise map.
        promiseMap[requestId] = {
            timeoutPromise,
            timeoutId,
            returnValue: deffered.promise,
        };

        return deffered.promise;
    };

    _this.cancel = function (requestId) {
        if (!promiseMap[requestId]) {
            return;
        }

        // Cancel the timeout promise so any pending timeout will be canceled
        $timeout.cancel(promiseMap[requestId].timeoutPromise);

        // Delete the request from the promise map to clean up and to discard any responses from ongoing server actions
        delete promiseMap[requestId];
    };

    function isRequestCanceled(requestId) {
        // If there isn't a requested with that id in the promise map it means it is canceled
        return !promiseMap[requestId];
    }

    function isLatestCall(requestId, timeoutId) {
        return promiseMap[requestId] && timeoutId === promiseMap[requestId].timeoutId;
    }
}

angular.module('tonkean.app').service('requestThrottler', RequestThrottler);

export const getRequestThrottler = () => {
    return getInjector().get('requestThrottler');
};
