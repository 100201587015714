import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { Checkbox, Input } from '@tonkean/infrastructure';
import { useCopyToClipboardFromInput } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { TooltipIcon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const ToolTipIconWrapper = styled(TooltipIcon)`
    margin-left: 5px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledInput = styled(Input)`
    margin-right: 6px;
    color: ${Theme.colors.gray_600};
    width: 400px;
`;

const ServicenowSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    projectIntegration,
    onChangeOrInitIntegration,
}) => {
    const projectManager = useAngularService('projectManager');
    const [includeCaseRecords, setIncludeCaseRecords] = useState<boolean>(false);
    const [includeProjectRecords, setIncludeProjectRecords] = useState<boolean>(false);
    const [incomingWebhookUrl, setIncomingWebhookUrl] = useState<string | undefined>();
    const { copyToClipboard, inputRef } = useCopyToClipboardFromInput();

    useEffect(() => {
        const init = async () => {};
        if (projectIntegration) {
            // Getting project data.
            const projectData = projectIntegration?.projectData?.projectDatas[0];
            if (projectData?.incomingWebhookUrl) {
                setIncomingWebhookUrl(projectData.incomingWebhookUrl);
            }
            if (projectData?.includeCaseRecords) {
                setIncludeCaseRecords(projectData?.includeCaseRecords);
            }
            if (projectData?.includeProjectRecords) {
                setIncludeProjectRecords(projectData?.includeProjectRecords);
            }
        }
        init();
    }, [projectIntegration, projectManager]);

    useEffect(() => {
        const projectIntegrationData = {
            includeCaseRecords,
            includeProjectRecords,
            incomingWebhookUrl,
        };
        onChangeOrInitIntegration(
            {
                projectIntegrationData,
            },
            false,
        );
    }, [includeCaseRecords, includeProjectRecords, incomingWebhookUrl, onChangeOrInitIntegration]);

    return (
        <div className="flex flex-col">
            <div className="flex-vmiddle mod-justify-center">
                <div className="common-title-xxs margin-bottom">Customer Service Management (CSM)</div>
            </div>
            <div className="common-size-xxxs info-box">
                <div className="flex-vmiddle mod-justify-center">
                    <Checkbox
                        checked={includeCaseRecords}
                        name="includeCaseRecords"
                        onChange={() => setIncludeCaseRecords(!includeCaseRecords)}
                    >
                        Include case records
                    </Checkbox>
                    <Tooltip content="The Case API requires the Customer Service plugin (com.sn_customerservice) installed.">
                        <ToolTipIconWrapper />
                    </Tooltip>
                </div>
            </div>

            <div className="flex-vmiddle mod-justify-center margin-top-xlg">
                <div className="common-title-xxs margin-bottom">Project Management (PPM)</div>
            </div>
            <div className="common-size-xxxs info-box">
                <div className="flex-vmiddle mod-justify-center">
                    <Checkbox
                        checked={includeProjectRecords}
                        name="includeProjectRecords"
                        onChange={() => setIncludeProjectRecords(!includeProjectRecords)}
                    >
                        Include projects
                    </Checkbox>
                    <Tooltip content="The Projects API requires the PPM Standard plugin installed.">
                        <ToolTipIconWrapper />
                    </Tooltip>
                </div>
            </div>

            {incomingWebhookUrl && (
                <div className="form-group margin-auto margin-top-md">
                    <div className="flex-vmiddle mod-justify-center">
                        <div className="common-title-xxs margin-bottom">ServiceNow Webhook Endpoint</div>
                    </div>

                    <div className="common-size-xxxs info-box">
                        <div className="flex-vmiddle mod-justify-center">
                            <div className="common-title-xxxs margin-bottom">{incomingWebhookUrl}</div>
                        </div>
                        <div className="common-color-dark-grey common-bold">Currently supported webhooks:</div>
                        <div className="common-color-light-grey">Incidents, Cases.</div>
                        <div className="common-color-dark-grey common-bold margin-top-md">Setup instructions:</div>
                        <div className="common-color-light-grey">
                            <ol>
                                <li className="margin-bottom-xs margin-top-xs">
                                    In your ServiceNow instance, go to System Definition {'>'} Business Rules.
                                </li>
                                <li className="margin-bottom-xs">
                                    Click on 'Create New' and give the rule a name.
                                    <br />* You can use the same webhook URL on different rules.
                                </li>
                                <li className="margin-bottom-xs">
                                    Select the relevant table and check the Advanced box.
                                </li>
                                <li className="margin-bottom-xs">
                                    Choose 'After' for when you want it to run and select the actions.
                                </li>
                                <li className="margin-bottom-xs">
                                    Go to the Advanced tab, and override the Script with the code below (it already
                                    includes the webhook URL).
                                    <br />
                                    <div className="flex mod-align-end">
                                        <Wrapper className="common-size-xxxxs">
                                            <StyledInput
                                                ref={inputRef}
                                                value={`(function executeRule(current, previous) { try { var request = new sn_ws.RESTMessageV2(); request.setEndpoint("${incomingWebhookUrl}"); request.setHttpMethod("post"); request.setRequestHeader("Content-Type", "application/json"); var bodyObj = { "table": current.getTableName(), "sys_id": current.getValue("sys_id") }; request.setRequestBody(JSON.stringify(bodyObj)); var response = request.execute(); var httpStatus = response.getStatusCode(); gs.info("Tonkean - Webhook target HTTP status response: " + httpStatus); } catch (ex) { var message = ex.message; gs.error("Tonkean - Error message: " + message); } })(current, previous);`}
                                                size={InputSize.MEDIUM_LARGE}
                                                readOnly
                                            />
                                            <button
                                                className="btn btn-secondary btn-slim"
                                                onClick={copyToClipboard}
                                                type="button"
                                            >
                                                Copy
                                            </button>
                                        </Wrapper>
                                    </div>
                                </li>
                                <li className="margin-bottom-xs">Click on the 'Submit' button.</li>
                            </ol>
                            <div>For more information please contact our support team.</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServicenowSetupComponent;
