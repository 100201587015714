import type { Form } from '@tonkean/tonkean-entities';
import { getSmallestAvailableName } from '@tonkean/utils';

function generateUniqueFormName(allForms: Form[], formIdsToIgnore: (string | undefined)[], requestedName?: string) {
    const allFormsNames = Object.values(allForms)
        .filter((_form) => !formIdsToIgnore.includes(_form.id))
        .map((_form) => _form.displayName);

    return getSmallestAvailableName(requestedName ?? 'New Form', allFormsNames);
}

export default generateUniqueFormName;
