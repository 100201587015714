import getDataSourceSectionConfigurationMap from './getDataSourceSectionConfiguration';
import getIntegrationPageBrowsedItemSubtitle from './getIntegrationPageBrowsedItemSubtitle';
import type ProjectIntegrationPageExplorerConfiguration from '../entities/ProjectIntegrationPageExplorerConfiguration';

import type { OldIntegrationConfiguration } from '@tonkean/integrations';
import { IntegrationManager } from '@tonkean/integrations';
import type { ProjectIntegrationSummary } from '@tonkean/tonkean-entities';
import type { EnterpriseComponent } from '@tonkean/tonkean-entities';
import { DataSourcesSectionType } from '@tonkean/tonkean-entities';
import { TonkeanType } from '@tonkean/tonkean-entities';

/**
 * This function organize, group, all of the given project integrations under known sections.
 * For example - GoogleSheet, SpreadSheet and Airtable will be under the 'SPREADSHEET' section.
 * @param projectIntegrations
 * @param integrationsConfig
 */
function getIntegrationsSectionsConfiguration(
    projectIntegrations: ProjectIntegrationSummary[],
    integrationsConfig?: Record<string, OldIntegrationConfiguration>,
): ProjectIntegrationPageExplorerConfiguration {
    // Next, we gather a map of data source section to its browsed items. Each data source section in map will later become an item browser section.
    const dataSourceSectionToItems: Partial<Record<DataSourcesSectionType, EnterpriseComponent[]>> = projectIntegrations
        .filter((projectIntegrationSummery: ProjectIntegrationSummary) => !projectIntegrationSummery.systemUtilized)
        .map((projectIntegrationSummary: ProjectIntegrationSummary) => {
            const newIntegrationConfiguration = IntegrationManager.getIntegrationByName(
                projectIntegrationSummary.integration.integrationType,
            );

            const oldIntegrationConfiguration: OldIntegrationConfiguration | undefined = integrationsConfig
                ? integrationsConfig[projectIntegrationSummary.integration.integrationType.toLowerCase()]
                : undefined;
            return {
                id: projectIntegrationSummary.id,
                type: TonkeanType.PROJECT_INTEGRATION,
                displayName: projectIntegrationSummary.displayName,
                subtitle: getIntegrationPageBrowsedItemSubtitle(projectIntegrationSummary),
                description: projectIntegrationSummary.description,
                iconUrl: projectIntegrationSummary.iconUrl,
                isAllowUpdateIcon: projectIntegrationSummary.isAllowUpdateIcon,
                publicMarketplaceItemTemplateName: projectIntegrationSummary.publicMarketplaceItemTemplateName,
                publicMarketplaceItemTemplateVersion: projectIntegrationSummary.publicMarketplaceItemTemplateVersion,
                integrationType: projectIntegrationSummary.integration.integrationType,
                valid: projectIntegrationSummary.valid,
                accessType: projectIntegrationSummary.accessType,
                dataSourceSection:
                    newIntegrationConfiguration?.dataSourceSectionType ||
                    oldIntegrationConfiguration?.dataSourceSectionType ||
                    DataSourcesSectionType.CLOUD,
                hasEntities:
                    oldIntegrationConfiguration?.allEntitiesSupportedIntegrations ||
                    oldIntegrationConfiguration?.fieldOptions?.length ||
                    newIntegrationConfiguration?.entities?.length ||
                    newIntegrationConfiguration?.hasDynamicEntities,
                disabled: projectIntegrationSummary.disabled,
            };
        })
        .reduce((dataSourceSectionToItemsMap, item) => {
            // Grouping by section type.
            if (!dataSourceSectionToItemsMap[item.dataSourceSection]) {
                dataSourceSectionToItemsMap[item.dataSourceSection] = [];
            }

            dataSourceSectionToItemsMap[item.dataSourceSection].push(item);

            return dataSourceSectionToItemsMap;
        }, {});

    // Get display configuration for each data source section.
    const dataSourceSectionConfiguration: Partial<Record<DataSourcesSectionType, { displayName: string }>> =
        getDataSourceSectionConfigurationMap();

    // At last, we return the configuration, converting data source section keys to browser sections.
    return {
        sections: Object.entries(dataSourceSectionToItems).map(([dataSourceSection, items]) => {
            return {
                id: dataSourceSection,
                title: dataSourceSectionConfiguration[dataSourceSection].displayName,
                items: items || [],
                description: '',
            };
        }),
    };
}

export default getIntegrationsSectionsConfiguration;
