import asanaIcon from '../../../../apps/tracks/images/asana.png';
import asanaCircleIcon from '../../../../apps/tracks/images/integrations/asana-circle.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class AsanaIntegration extends IntegrationDefinitionBase {
    override name = 'asana';
    override displayName = 'Asana';
    override description = 'We will collect Projects, Sections ans Tasks and Users.';
    override authenticationType = AuthenticationMethod.OAUTH;
    override supportsSharedCredentials = true;
    override showEntitiesInboundConfiguration = false;

    override entities = [
        new IntegrationEntity('Project', 'Projects'),
        new IntegrationEntity('Section', 'Sections'),
        new IntegrationEntity('Task', 'Tasks'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Comment', 'Comments'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(asanaCircleIcon, '130px'),
        new IntegrationIcon(asanaIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
    };

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const clientId = environment.integrationKeysMap.asana;
        const redirectUri = environment.integrationKeysMap.asanaUri;
        const state = oAuthHandler.publicGetState();
        const url = `https://app.asana.com/-/oauth_authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${state}`;
        const code = await oAuthHandler.startOAuth(url, state);

        return { code, redirectUri };
    }
}

export default AsanaIntegration;
