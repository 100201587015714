function EmailEndpointCtrl(
    $scope,
    modal,
    tonkeanService,
    utils,
    projectManager,
    authenticationService,
    groupInfoManager,
    groupPermissions,
    groupId,
) {
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.gm = groupPermissions;

    $scope.groupId = groupId;
    $scope.data = {
        emailAddress: null,

        // Disable email endpoint
        loadingDisableEmailEndpoint: false,
        errorLoadingDisableEmailEndpoint: null,

        // Enable email endpoint
        loadingEnableEmailEndpoint: false,
        errorLoadingEnableEmailEndpoint: null,
    };

    $scope.init = function () {
        // if we allow link then it's a group, so checking if there is already a link
        if (projectManager.groupsMap[groupId] && projectManager.groupsMap[groupId].emailEndpoint) {
            $scope.data.emailAddress = projectManager.groupsMap[groupId].emailEndpoint;
        }
    };

    /**
     * Enables email endpoint.
     */
    $scope.enableEmailEndpoint = function () {
        // Not the owner of project and not any of the group owners
        if (!groupPermissions.currentUserIsOwnerMap[groupId] && !projectManager.isOwner) {
            return;
        }

        $scope.mboxData = {
            title: 'Enable email endpoint',
            body: 'Are you sure you want to enable email endpoint?',
            isWarn: true,
            okLabel: 'Enable',
            cancelLabel: 'Cancel',
        };

        modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(() => {
                // okLabel clicked.

                $scope.data.loadingEnableEmailEndpoint = true;
                $scope.data.errorLoadingEnableEmailEndpoint = null;

                tonkeanService
                    .enableEmailEndpoint(groupId)
                    .then((data) => {
                        projectManager.groupsMap[groupId].emailEndpoint = data.emailAddress;
                        $scope.data.emailAddress = data.emailAddress;
                    })
                    .catch(() => {
                        $scope.data.errorLoadingEnableEmailEndpoint = 'Error updating list.';
                    })
                    .finally(() => {
                        $scope.data.loadingEnableEmailEndpoint = false;
                    });
            });
    };

    /**
     * revoke token
     */
    $scope.disableEmailEndpoint = function () {
        $scope.mboxData = {
            title: 'Revoke email endpoint',
            body: 'Are you sure you want to revoke email endpoint?',
            isWarn: true,
            okLabel: 'Revoke',
            cancelLabel: 'Cancel',
        };

        modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(() => {
                // okLabel clicked.
                $scope.data.loadingDisableEmailEndpoint = true;
                $scope.data.errorLoadingDisableEmailEndpoint = null;

                tonkeanService
                    .disableEmailEndpoint(groupId)
                    .then(() => {
                        $scope.data.emailAddress = null;
                        projectManager.groupsMap[groupId].emailEndpoint = null;
                        groupInfoManager.getGroup(groupId, true);
                    })
                    .catch(() => {
                        $scope.data.errorLoadingDisableEmailEndpoint = 'Error updating list.';
                    })
                    .finally(() => {
                        $scope.data.loadingDisableEmailEndpoint = false;
                    });
            });
    };

    $scope.copyUrlToClipboard = function (elementId) {
        const addressElement = document.getElementById(elementId);
        const copyResult = utils.copyToClipboardFromInput(addressElement);

        if (copyResult) {
            $scope.$emit('alert', {
                msg: 'Email address copied to clipboard',
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };

    $scope.init();

    // Focus the message input element when the document finishes loading.
    angular.element(document).ready(function () {
        // Auto focus the message input.
        const messageInput = document.getElementById(`message-${$scope.entityId}`);
        if (messageInput) {
            messageInput.focus();
        }
    });
}

export default angular.module('tonkean.app').controller('EmailEndpointCtrl', EmailEndpointCtrl);
