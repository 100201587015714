import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ConvertToNumberFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.CONVERT_TO_NUMBER;
    override readonly sign = 'ConvertToNumber';
    override readonly displayName = 'Convert to Number';
    override readonly description = 'Will get the number value of any input.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: undefined,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
