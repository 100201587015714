import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { InformationTooltip, Input, useCloseCallback, useDebouncedState } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const Title = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    font-size: 12px;
    color: ${Theme.colors.gray_800};
`;
const TitleDescription = styled.div`
    font-size: 12px;
    color: ${Theme.colors.gray_600};
    margin-bottom: 10px;
`;
const StyledInput = styled(Input)`
    margin-bottom: 15px;
`;

interface Props {
    customTriggerId: string | undefined;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    workflowVersionId: string | undefined;
    showDefaultValue: boolean;
    fieldDefinition: Record<any, any> | null | undefined;
    defaultValue: string | undefined;
    onDefaultValueChange(defaultValue: string | undefined): void;
    flowItemParent: string | undefined;
    onFlowItemParentChange(flowItemParent: string | undefined): void;
}

const ExpressionEditorVariablePopover: React.FC<Props> = ({
    customTriggerId,
    groupId,
    workflowVersionId,
    showDefaultValue,
    fieldDefinition,
    defaultValue: defaultValueParam,
    onDefaultValueChange,
    flowItemParent: flowItemParentParam,
    onFlowItemParentChange,
}) => {
    const $rootScope = useAngularService('$rootScope');
    const $timeout = useAngularService('$timeout');
    const groupInfoManager = useAngularService('groupInfoManager');
    const closePopover = useCloseCallback();
    const editFieldDefinition = () => {
        if (!fieldDefinition || !workflowVersionId || !groupId) {
            return;
        }

        const reloadFields = () => groupInfoManager.getGroup(groupId, true);

        closePopover();
        $timeout(
            () => {
                $rootScope.$broadcast('createNewField', [
                    groupId,
                    fieldDefinition.targetType,
                    fieldDefinition.type,
                    fieldDefinition.projectIntegration,
                    false,
                    false,
                    fieldDefinition,
                    reloadFields,
                    reloadFields,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    workflowVersionId,
                ]);
            },
            // Wait for the closing animation to complete
            200,
        );
    };

    // Because updating the editor is a heavy operation (it converts it to string and then runs the regex again),
    // we will debounce the changes.
    const [defaultValue, setDefaultValue] = useDebouncedState(defaultValueParam, onDefaultValueChange);
    const [flowItemParent, setFlowItemParent] = useDebouncedState(flowItemParentParam, onFlowItemParentChange);

    return (
        <>
            {customTriggerId && (
                <>
                    <Title>Get value from relative item</Title>
                    <TitleDescription>
                        0 (default) represents current level,
                        <br />
                        use 1 for parent level, 2 for grandparent level
                    </TitleDescription>
                    <StyledInput
                        type="number"
                        min={0}
                        value={flowItemParent || ''}
                        onChange={(event) => setFlowItemParent(event.target.value || undefined)}
                    />
                </>
            )}
            {showDefaultValue && (
                <>
                    <Title>
                        Enter a default value
                        <InformationTooltip>
                            Define a default value to use when the field is empty or not found
                        </InformationTooltip>
                    </Title>
                    <StyledInput
                        type="text"
                        value={defaultValue || ''}
                        onChange={(event) => setDefaultValue(event.target.value || undefined)}
                    />
                </>
            )}
            {fieldDefinition && (
                <Button onClick={editFieldDefinition} size={ButtonSize.SMALL} outlined>
                    Edit Field
                </Button>
            )}
        </>
    );
};

export default ExpressionEditorVariablePopover;
