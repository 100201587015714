import SQLDatabaseEntityIdType from '../entities/SQLDatabaseEntityIdType';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';

function getSQLDatabaseQueryConfigurationsInvalidMessage(queryConfigurations: SQLDatabaseQueryConfiguration[]) {
    for (const queryConfiguration of queryConfigurations) {
        if (!queryConfiguration.queryName) {
            return 'All query entities must have names.';
        } else if (!queryConfiguration.query) {
            return 'All query entities must have queries.';
        } else if (
            queryConfiguration.entityIdType === SQLDatabaseEntityIdType.CUSTOM &&
            !queryConfiguration.identifierColumn
        ) {
            return 'Custom defined Entity Id must have identifier column defined.';
        } else if (
            queryConfiguration.customTimestampsEnabled &&
            !queryConfiguration.createdColumn &&
            !queryConfiguration.updatedColumn
        ) {
            return 'Timestamps fields must have created or updated columns defined.';
        } else if (queryConfiguration.nameValidationError) {
            return 'All names of query entities should be unique.';
        } else if (queryConfiguration.pagingValidationError) {
            if (queryConfiguration?.pageSize && queryConfiguration.pageSize > 1000) {
                return 'SQL SELECT commands are limited to 1000 records per page';
            }

            return 'All queries using pagination must use {{Limit}}.';
        } else if (!queryConfiguration.queryTested) {
            return 'All query entities must be tested successfully before saving.';
        }
    }
}

export default getSQLDatabaseQueryConfigurationsInvalidMessage;
