import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { prettifyJson } from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/utils/tryPrettifyJsonText';
import JsonPickerOutputType from '../../entities/JsonPickerOutputType';
import projectIntegrationEntityCollectPageInputState from '../../states/projectIntegrationEntityCollectPageInputState';

import { useToastMessage } from '@tonkean/angular-hooks';
import type { JsonPickerAllowedTypes } from '@tonkean/infrastructure';
import { InputWithButton, JsonPickerBlock, Textarea } from '@tonkean/infrastructure';

const JsonPickerBlockWrapper = styled.div`
    margin-bottom: 16px;
    overflow: hidden;
    flex-grow: 1;
`;

const Wrapper = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: inherit;
`;

const InputWithButtonWrapper = styled.div`
    // Because of Intercom icon - 100% - (icon size) - (padding left) - (padding right).
    width: calc(100% - 60px - 16px - 8px);
`;

interface Props {
    jsonToDisplay: JsonPickerAllowedTypes;
    showAsJsonPicker: boolean;
    jsonPickerOutputType: JsonPickerOutputType;
    jsonStringParam?: string;
}

const ProjectIntegrationJsonViewerLayout: React.FC<Props> = ({
    showAsJsonPicker,
    jsonToDisplay,
    jsonPickerOutputType,
    jsonStringParam = 'body',
}) => {
    const emitToastMessage = useToastMessage();

    const utils = useAngularService('utils');

    // The input value for the selected jsonpath.
    const [jsonPath, setJsonPath] = useState<string>('');

    // The json we want to display
    const jsonAsPrettyText: string = useMemo(() => {
        if (!jsonToDisplay) {
            return '';
        }

        if (typeof jsonToDisplay === 'object') {
            return prettifyJson(jsonToDisplay);
        }

        return jsonToDisplay.toString();
    }, [jsonToDisplay]);

    const [{ isJsonPickerDisabled }] = useRecoilState(projectIntegrationEntityCollectPageInputState);

    // Copy the jsonPath content.
    const copyJsonPathToClipboard = () => {
        if (jsonPath) {
            const isCopied = utils.copyToClipboardFromText(jsonPath);

            if (isCopied) {
                emitToastMessage('Copied successfully', 'success');
            } else {
                emitToastMessage("Couldn't copy, Try again", 'warning');
            }
        }
    };

    useEffect(() => {
        if (isJsonPickerDisabled && jsonPath) {
            setJsonPath('');
        }
    }, [isJsonPickerDisabled, jsonPath, setJsonPath]);

    return (
        <>
            {showAsJsonPicker && (
                <Wrapper>
                    <JsonPickerBlockWrapper>
                        <JsonPickerBlock
                            height="100%"
                            json={jsonToDisplay}
                            onClick={(path) => {
                                if (jsonPickerOutputType === JsonPickerOutputType.JSON_TRAVERSE) {
                                    // The RegExp removes the arrays from the path.
                                    // The slice remove the initial '$.'.
                                    const pathWithoutIndex = path.replace(new RegExp('\\[([^\\]]*)]'), '').slice(2);
                                    setJsonPath(`<# JsonTraverse({{${jsonStringParam}}, '${pathWithoutIndex}') #>`);
                                } else {
                                    setJsonPath(`<# JsonPath({{${jsonStringParam}}, '${path}', false) #>`);
                                }
                            }}
                            disabled={isJsonPickerDisabled}
                        />
                    </JsonPickerBlockWrapper>

                    <InputWithButtonWrapper>
                        <InputWithButton
                            onInputChange={() => {}}
                            buttonClickableProps={{
                                onClick: copyJsonPathToClipboard,
                                disabled: !jsonPath || isJsonPickerDisabled,
                            }}
                            buttonText="Copy"
                            inputValue={jsonPath || ''}
                            placeholder={
                                jsonPickerOutputType === JsonPickerOutputType.JSON_PATH
                                    ? `JsonPath({{${jsonStringParam}}, '$.id', false)`
                                    : `TraverseJson('path', {{${jsonStringParam}})`
                            }
                            disabled={isJsonPickerDisabled}
                        />
                    </InputWithButtonWrapper>
                </Wrapper>
            )}

            {!showAsJsonPicker && <Textarea value={jsonAsPrettyText} disabled />}
        </>
    );
};

export default ProjectIntegrationJsonViewerLayout;
