import type { CreateInitiativeRequest } from './entities/CreateInitiativeRequest';
import type { SearchInitiativesResponse } from './entities/SearchInitiativesResponse';
import type NetworkService from '../NetworkService';

import type { Initiative, TonkeanId, TonkeanType, SearchInitiativesQuery } from '@tonkean/tonkean-entities';

const URLS = {
    createInitiative: (projectId: TonkeanId<TonkeanType.PROJECT>) => `${projectId}/initiatives`,
    getInitiative: (initiativeId: TonkeanId<TonkeanType.INITIATIVE>) => `initiative/${initiativeId}`,
    searchInitiatives: (projectId: TonkeanId<TonkeanType.PROJECT>) => `${projectId}/searchInitiatives`,
    pollInitiative: (
        projectId: TonkeanId<TonkeanType.PROJECT>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        lastPolledInitiativeUpdateTime: number,
    ) => `project/${projectId}/initiative/${initiativeId}/poll?lastModified=${lastPolledInitiativeUpdateTime}`,
};

async function createInitiative(
    networkService: NetworkService,
    projectId: TonkeanId<TonkeanType.PROJECT>,
    groupId: TonkeanId<TonkeanType.GROUP>,
    title: string,
    isDraftInitiative: boolean,
): Promise<Initiative> {
    const body: CreateInitiativeRequest = {
        title,
        groupId,
        isDraftInitiative,
        isFormQuestion: false,
        metadata: {},
        tags: [],
        functions: [],
    };
    return networkService.postRequest<Initiative, CreateInitiativeRequest>({
        url: URLS.createInitiative(projectId),
        body,
    });
}

async function getInitiative(
    networkService: NetworkService,
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
): Promise<Initiative> {
    return networkService.getRequest<Initiative>({
        url: URLS.getInitiative(initiativeId),
    });
}

async function searchInitiatives(
    networkService: NetworkService,
    projectId: TonkeanId<TonkeanType.PROJECT>,
    searchInitiaitvesParams: SearchInitiativesQuery,
): Promise<SearchInitiativesResponse> {
    return networkService.postRequest<SearchInitiativesResponse, SearchInitiativesQuery>({
        url: URLS.searchInitiatives(projectId),
        body: searchInitiaitvesParams,
    });
}

async function pollInitiative(
    networkService: NetworkService,
    projectId: TonkeanId<TonkeanType.PROJECT>,
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
    lastPolledInitiativeUpdateTime: number,
    initiativeForNotModifiedResponse?: Initiative,
): Promise<Initiative | undefined> {
    const result = await networkService.getRequest<{ initiative: Initiative } | undefined>({
        url: URLS.pollInitiative(projectId, initiativeId, lastPolledInitiativeUpdateTime),
        defaultResponseForNotModifiedStatus: initiativeForNotModifiedResponse
            ? {
                  initiative: initiativeForNotModifiedResponse,
              }
            : undefined,
    });

    return result?.initiative;
}

export { createInitiative, getInitiative, searchInitiatives, pollInitiative };
