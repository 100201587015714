import React from 'react';
import styled from 'styled-components';

import { H3, Spacer } from '@tonkean/infrastructure';
import { InterfacesLogo } from '@tonkean/svg';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme, FontSize } from '@tonkean/tui-theme';

const EmptyStateContainer = styled.div`
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 230px;
    text-align: center;
`;

const EmptyStateText = styled.div`
    margin: 8px 0;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
    line-height: 20px;
`;

interface Props {
    onOpenNewInterfaceModal?(): void;
}

const InterfacesWorkerOutlineEmptyState: React.FC<Props> = ({ onOpenNewInterfaceModal }) => {
    return (
        <EmptyStateContainer>
            <InterfacesLogo />
            <Spacer height={30} />
            <H3 $bold>Welcome to custom interfaces</H3>
            <EmptyStateText>
                Create customized and delightful views that are focused on the needs of the people that participate in
                your business process and that enable them to easily take action and view information relevant to the
                items in your module
            </EmptyStateText>
            {onOpenNewInterfaceModal && (
                <ClickableLink
                    onClick={onOpenNewInterfaceModal}
                    data-automation="interfaces-worker-outline-empty-state-create-button"
                >
                    Create a new interface
                </ClickableLink>
            )}
        </EmptyStateContainer>
    );
};

export default InterfacesWorkerOutlineEmptyState;
