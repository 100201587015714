<div
    ng-if="(!disableEdit || data.channel) && cis.anyIntegrationSupports(cis.features.channels)"
    class="notification-settings-box"
    ng-class="{ 'notification-settings-updated-style': data.hideTagsBackgroundWhenDisabled }"
>
    <div ng-if="data.channel && !controlledSave" class="common-color-dark-grey">
        <span
            ng-if="!disableEdit"
            class="svg-icon-xs common-color-dark-grey svg-icon-hover-black margin-right-xs pointer"
            ng-click="data.editChannel = true;"
        >
            <tnk-icon src="../../../../svg/src/icons/pencil.svg"></tnk-icon>
        </span>
        <strong ng-bind="data.channel.channelName || 'Direct message'"></strong>
        &nbsp;is used for notifications
    </div>
    <div
        ng-if="!disableEdit && (!data.channel || !data.channel.channelId) && !data.hideTagsBackgroundWhenDisabled"
        class="common-color-dark-grey"
    >
        <i class="fa fa-exclamation-triangle common-color-level2 margin-right-xs"></i>
        <strong>
            Choose a {{ cis.integrations[data.communicationIntegration.integrationUniqueType].channelLabel }} for
            notifications:
        </strong>
    </div>
    <div
        class="margin-top-xs"
        ng-if="!disableEdit && (data.editChannel || controlledSave || !data.channel || !data.channel.channelId)"
    >
        <p ng-if="!explanationInTooltip" class="common-size-xxxxs common-color-dark-grey">
            {{
                overwriteId
                    ? 'Will be used for notification on this Track and all inner tracks'
                    : 'Will be used as the
            default channel for all notifications'
            }}:
        </p>
        <form novalidate id="notificationSettings">
            <div class="flex mod-align-start">
                <tnk-channel-selector
                    class="flex-grow"
                    selected-channel="data.newChannel"
                    reload-channels="data.reloadChannels"
                    enable-clear-channel="!overwriteId"
                    selector-tooltip="
                        !controlledSave || data.hideTagsBackgroundWhenDisabled
                            ? undefined
                            : overwriteId
                                ? 'Will be used for notification on this Track and all inner tracks'
                                : 'Will be used as the default channel for all notifications'
                    "
                    on-channels-loaded="autoselectPublicChannel(channels)"
                    on-channel-selected="onChannelSelected(channel)"
                    view-only="viewOnly"
                    show-refresh-button="!data.hideTagsBackgroundWhenDisabled"
                ></tnk-channel-selector>
                <button
                    class="btn btn-primary margin-left-xs flex-no-shrink"
                    type="submit"
                    ng-if="!controlledSave"
                    ng-click="save(data.newChannel)"
                    ng-disabled="loadingOptions || data.channel.channelId === data.newChannel.channelId"
                >
                    <span class="flex-vmiddle">
                        <span class="loading-small mod-white margin-right-xxs" ng-show="savingChannel"></span>
                        <span>Save</span>
                    </span>
                </button>
            </div>
            <div ng-if="!data.newChannel && !overwriteId" class="flex mod-align-start margin-top-xs">
                <i class="fa fa-exclamation-triangle common-color-danger margin-top-xxs"></i>
                <div class="common-color-grey common-size-xxxxxs margin-left-xs">
                    <div>If you save after disabling board notifications you won't be able to enable it again.</div>
                    <div>You can always set notifications per list or track.</div>
                </div>
            </div>
        </form>
    </div>
</div>
