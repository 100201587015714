import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function IntercomCustomActionsDefinitionCtrl(
    $scope,
    $filter,
    $q,
    $rootScope,
    tonkeanService,
    projectManager,
    utils,
    integrationsConsts,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        fieldDefinitionsToCreate: ctrl.fieldDefinitionsToCreate, // Definitions to create.
        validationObject: ctrl.validationObject,
        admins: [],
        adminsAndTeams: [],
        selectedAdmin: null,
        selectedAssignee: null,

        intercomUserTypeOptions: ['USER', 'CONTACT'],
        intercomUserTypeDisplayNames: {
            USER: 'User',
            CONTACT: 'Contact',
        },

        messageTypeOptions: ['INAPP', 'EMAIL'],
        messageTypeDisplayNames: {
            INAPP: 'In-App',
            EMAIL: 'Email',
        },

        templateStyleOptions: ['PLAIN', 'PERSONAL'],
        templateStyleDisplayNames: {
            PLAIN: 'Plain',
            PERSONAL: 'Personal',
        },

        loading: false,

        personSelectionConfiguration: null,
        existingPersonSelectionConfiguration: null,
        adminSelectionConfiguration: null,
        existingAdminSelectionConfiguration: null,

        actionsByIntegrationsMap: integrationsConsts.getActionsByIntegrationsMap(),
    };

    ctrl.$onInit = function () {
        if ($scope.data.definition && !$scope.data.definition.customAttributes) {
            $scope.data.definition.customAttributes = [
                {
                    keyExpression: {},
                    valueExpression: {},
                },
            ];
        }

        if ($scope.data.configuredLogic && $scope.data.definition) {
            $scope.data.loading = true;

            $scope
                .refreshAdmins()
                .then(initializeEditMode)
                .finally(() => {
                    $scope.data.loading = false;
                });
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    $scope.addEmptyAttributeToUpdate = function () {
        $scope.data.definition.customAttributes.push({
            keyExpression: {},
            valueExpression: {},
        });
    };

    $scope.refreshAdmins = function (q) {
        const queriesToSearch = [q && q.length ? q : null];

        if ($scope.data.definition?.admin?.adminIdToNameMap) {
            queriesToSearch.push(...Object.values($scope.data.definition?.admin?.adminIdToNameMap));
        }

        const exampleEntitiesRequests = queriesToSearch.map((singleQuery) => {
            return tonkeanService
                .getIntegrationExampleEntities($scope.data.projectIntegration.id, 'Admin', singleQuery)
                .then((response) => response.exampleEntities);
        });

        return Promise.all(exampleEntitiesRequests).then((values) => {
            const exampleEntities = [...new Set(values.flat())];
            if (exampleEntities && exampleEntities.length) {
                $scope.data.adminsAndTeams = exampleEntities;
                $scope.data.admins = $filter('filter')($scope.data.adminsAndTeams, {
                    originalEntity: { type: 'admin' },
                });

                const unassignAdmin = angular.copy($scope.data.admins[0]);
                unassignAdmin.id = '0';
                unassignAdmin.title = 'Un-assigned';
                unassignAdmin.text = 'Un-assigned';
                $scope.data.admins.push(unassignAdmin);
                $scope.data.adminsAndTeams.push(unassignAdmin);
            }
        });
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.text = originalExpression;
        $scope.data.definition.evaluatedText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTaggingEntityIdTypeSelected = function (idTypeSelected) {
        $scope.data.definition.taggingEntityIdType = idTypeSelected;
        $scope.onDefinitionChanged(true);
    };

    $scope.onArchiveUserIdTypeSelected = function (idTypeSelected) {
        $scope.data.definition.archiveUserIdType = idTypeSelected;
        $scope.onDefinitionChanged(true);
    };

    $scope.onAttributeKeyTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.customAttributes[index].keyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAttributeValueTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.customAttributes[index].valueExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onBodyExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.bodyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRecipientExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.recipientEntityExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRecipientEntityIdTypeSelected = function (idTypeSelected) {
        $scope.data.definition.recipientEntityIdType = idTypeSelected;
        $scope.onDefinitionChanged(true);
    };

    $scope.onUpdateCustomAttributeEntityIdTypeSelected = function (idTypeSelected) {
        $scope.data.definition.updateCustomAttributeEntityIdType = idTypeSelected;
        $scope.onDefinitionChanged(true);
    };

    $scope.onRecipientTypeSelected = function () {
        $scope.onDefinitionChanged(true);
    };

    $scope.onMessageTypeSelected = function () {
        $scope.onDefinitionChanged(true);
    };

    $scope.onSubjectExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.subjectExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onMessageTemplateStyleSelected = function () {
        $scope.onDefinitionChanged(true);
    };

    /**
     * Occurs once tonkean tag expression has changed.
     */
    $scope.onTonkeanTagExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.tag = originalExpression;
        $scope.data.definition.evaluatedTagText = evaluatedExpression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean email expression has changed.
     */
    $scope.onTonkeanEmailExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.emailExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean user id expression has changed.
     */
    $scope.onTonkeanUserIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.userIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean phone expression has changed.
     */
    $scope.onTonkeanPhoneExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.phoneExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean allow blanks toggle has changed.
     */
    $scope.onAllowBlankFieldsToggled = function (action, value) {
        $scope.data.definition.allowBlankFields = value;
        $scope.onDefinitionChanged(true);
    };

    /**
     * Occurs once tonkean name expression has changed.
     */
    $scope.onTonkeanNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.nameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean message id expression has changed.
     */
    $scope.onTonkeanMessageIdExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.messageIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFormSelected = function (form) {
        $scope.data.definition.formId = form.id;
        $scope.data.definition.formType = form.formType;
        $scope.onDefinitionChanged(true);
    };

    $scope.editForm = function () {
        $rootScope.$broadcast('editForm', {
            formId: $scope.data.definition.formId,
            formType: $scope.data.definition.formType,
        });
    };

    $scope.onPerformingAdminSelected = function (adminSelectionConfiguration, shouldSaveLogic) {
        $scope.data.adminSelectionConfiguration = adminSelectionConfiguration;

        const data = {
            personEmailExpressionDefinition: null,
            adminIds: null,
            customInput: null,
            initiativeOwner: null,
            previousActor: null,
            useNone: null,
            failIfNoValue: null,
            adminIdToNameMap: adminSelectionConfiguration.selectedPersonIdToNameMap,
        };

        if (adminSelectionConfiguration) {
            if (adminSelectionConfiguration.personSelectionType === 'fromField') {
                data.personEmailExpressionDefinition = adminSelectionConfiguration.fromFieldExpressionDefinition;
            }

            if (adminSelectionConfiguration.personSelectionType === 'custom') {
                data.customInput = adminSelectionConfiguration.customInput;
            }

            data.adminIds = [];
            if (
                adminSelectionConfiguration.personSelectionType === 'specificPeople' &&
                adminSelectionConfiguration.selectedPeopleIds
            ) {
                for (let i = 0; i < adminSelectionConfiguration.selectedPeopleIds.length; i++) {
                    data.adminIds.push(adminSelectionConfiguration.selectedPeopleIds[i]);
                }
            }

            data.initiativeOwner = adminSelectionConfiguration.personSelectionType === 'initiativeOwner';
            data.previousActor = adminSelectionConfiguration.personSelectionType === 'previousActor';
            data.useNone = adminSelectionConfiguration.personSelectionType === 'none';
            data.failIfNoValue = adminSelectionConfiguration.failIfNoValue;
        }

        $scope.data.definition.admin = data;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        if (ctrl.onDefinitionChanged) {
            ctrl.onDefinitionChanged({ shouldSaveLogic });
        }
    };

    /**
     * Deletes a custom attribute.
     */
    $scope.removeCustomAttribute = function (index) {
        $scope.data.definition.customAttributes.splice(index, 1);
    };

    /**
     * Occurs once the person selection configuration is changed.
     */
    $scope.onPersonSelectionConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        $scope.data.personSelectionConfiguration = personSelectionConfiguration;

        const data = {
            personEmailExpressionDefinition: null,
            assigneeIds: null,
            initiativeOwner: null,
            previousActor: null,
            useNone: null,
            failIfNoValue: null,
        };

        if (personSelectionConfiguration) {
            if (personSelectionConfiguration.personSelectionType === 'fromField') {
                data.personEmailExpressionDefinition = personSelectionConfiguration.fromFieldExpressionDefinition;
            }

            data.assigneeIds = [];
            if (
                personSelectionConfiguration.personSelectionType === 'specificPeople' &&
                personSelectionConfiguration.selectedPeopleIds
            ) {
                for (let i = 0; i < personSelectionConfiguration.selectedPeopleIds.length; i++) {
                    data.assigneeIds.push(personSelectionConfiguration.selectedPeopleIds[i]);
                }
            }

            data.initiativeOwner = personSelectionConfiguration.personSelectionType === 'initiativeOwner';
            data.previousActor = personSelectionConfiguration.personSelectionType === 'previousActor';
            data.useNone = personSelectionConfiguration.personSelectionType === 'none';
            data.failIfNoValue = personSelectionConfiguration.failIfNoValue;
        }

        $scope.data.definition.assignee = data;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );
        let promise1 = $q.resolve();
        let promise2 = $q.resolve();

        if (customTriggerAction) {
            if (customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee) {
                $scope.data.existingPersonSelectionConfiguration = {
                    personEmailFieldDefinitionId:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee
                            .personEmailFieldDefinitionId,
                    personEmailExpressionDefinition:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee
                            .personEmailExpressionDefinition,
                    specificPeopleIds:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee
                            .assigneeIds,
                    initiativeOwner:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee
                            .initiativeOwner,
                    previousActor:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee
                            .previousActor,
                    useNone: false,
                    failIfNoValue:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee
                            .failIfNoValue,
                };

                if (
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee.assigneeIds &&
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee.assigneeIds
                        .length > 0
                ) {
                    const assigneeId =
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.assignee
                            .assigneeIds[0];
                    $scope.data.selectedPerson = utils.findFirstById($scope.data.adminsAndTeams, assigneeId);

                    if (!$scope.data.selectedPerson) {
                        promise1 = tonkeanService
                            .getExternalActivityById(
                                projectManager.project.id,
                                $scope.data.projectIntegration.id,
                                assigneeId,
                            )
                            .then((data) => {
                                $scope.data.adminsAndTeams.push(data);
                                $scope.data.selectedPerson = utils.findFirstById(
                                    $scope.data.adminsAndTeams,
                                    assigneeId,
                                );
                            });
                    }
                }
            }

            if (customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin) {
                $scope.data.existingAdminSelectionConfiguration = {
                    personEmailFieldDefinitionId:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin
                            .personEmailFieldDefinitionId,
                    personEmailExpressionDefinition:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin
                            .personEmailExpressionDefinition,
                    specificPeopleIds:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin.adminIds,
                    customInput:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin.customInput,
                    initiativeOwner:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin
                            .initiativeOwner,
                    previousActor:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin.previousActor,
                    useNone: false,
                    failIfNoValue:
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin.failIfNoValue,
                };

                if (
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin.adminIds &&
                    customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin.adminIds.length >
                        0
                ) {
                    const adminId =
                        customTriggerAction.customTriggerActionDefinition.performedActionDefinition.admin.adminIds[0];
                    $scope.data.selectedAdmin = utils.findFirstById($scope.data.adminsAndTeams, adminId);

                    if (!$scope.data.selectedAdmin) {
                        promise2 = tonkeanService
                            .getExternalActivityById(
                                projectManager.project.id,
                                $scope.data.projectIntegration.id,
                                adminId,
                            )
                            .then((data) => {
                                $scope.data.adminsAndTeams.push(data);
                                $scope.data.selectedAdmin = utils.findFirstById($scope.data.adminsAndTeams, adminId);
                            });
                    }
                }
            }
        }

        return promise1.then(promise2);
    }
}

export default angular
    .module('tonkean.app')
    .controller('IntercomCustomActionsDefinitionCtrl', lateConstructController(IntercomCustomActionsDefinitionCtrl));
