<div id="manage-columns-modal" class="manage-columns-modal common-close-btn-container" ng-hide="hideSelf">
    <!-- Exit button -->
    <span class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Title-->
    <div class="common-title flex-grow">Manage fields</div>

    <!-- Loading-->
    <div ng-if="loading" class="text-center margin-top-xxlg margin-bottom-xxlg"><i class="loading-medium"></i></div>

    <tnk-manage-fields
        on-close="$close()"
        target-type="COLUMN"
        control-object="data.columnsCtrlObject"
        group-id="groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
    ></tnk-manage-fields>

    <!-- Footer -->
    <div class="flex padding-top-lg">
        <!-- Buttons -->
        <div class="flex-vmiddle">
            <!-- Cancel -->
            <button type="button" class="btn btn-default margin-right-xs" ng-click="$dismiss()">Cancel</button>
            <!-- Save -->
            <button type="button" class="btn btn-primary" ng-click="saveChanges()">Save Changes</button>
        </div>
    </div>

    <!-- Errors -->
    <div ng-if="errorSavingFields" class="padding-top common-size-xxs common-color-danger">{{ errorSavingFields }}</div>
</div>
