import { reactToAngular } from '@tonkean/angular-components';
import { ItemInterfaceSelector } from '@tonkean/interface-module';

/**
 * Component to select custom interface
 */

export default angular
    .module('tonkean.app')
    .component(
        'tnkItemInterfaceSelector',
        reactToAngular(ItemInterfaceSelector, [
            'groupId',
            'workflowVersionId',
            'selectedItemInterfaceId',
            'onItemInterfaceSelected',
            'environment',
            'allowClear',
        ]),
    );
