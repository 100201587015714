export enum CoupaAuthType {
    API_KEY = 'api_key',
    OAUTH = 'oauth',
}

export const authTypeToDisplayName: Record<CoupaAuthType, string> = {
    [CoupaAuthType.API_KEY]: 'API Key',
    [CoupaAuthType.OAUTH]: 'OAuth 2.0',
};
export default CoupaAuthType;
