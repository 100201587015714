import React from 'react';
import styled from 'styled-components';

import { Field, Input } from '@tonkean/infrastructure';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

interface Props {
    entityName: string;
}

const MarketplaceItemCreateModalVersionSection: React.FC<Props> = ({ entityName }) => {
    return (
        <StyledField label="Version" informationTooltip={<>The {entityName} release version.</>}>
            <Input data-automation="marketplace-item-create-modal-version" name="version" />
        </StyledField>
    );
};

export default MarketplaceItemCreateModalVersionSection;
