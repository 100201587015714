<div>
    <!-- Saving to... -->
    <div class="text-center" ng-if="data.saving">
        <h4>
            <span class="loading"></span>
            Saving...
        </h4>
    </div>

    <form name="data.integrationForm" ng-hide="data.saving" novalidate ng-submit="ok()">
        <div class="modal-body">
            <!-- Error -->
            <div class="alert alert-danger" ng-show="data.error">{{ data.error | error }}</div>

            <!-- Authenticate -->
            <div ng-if="(!data.integration || data.error) && !data.connecting" class="text-center">
                <button
                    type="button"
                    class="btn btn-secondary btn-lg"
                    analytics-on
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Authenticate"
                    analytics-label="Excel Online Spreadsheets"
                    ng-click="authorize()"
                >
                    Authenticate with Excel Online
                </button>
                <div class="margin-normal-sm-v common-subtitle-inner">We only need read permissions!</div>
            </div>

            <!-- Connecting to... -->
            <h4 ng-if="data.connecting" class="flex-vmiddle mod-justify-center">
                <span class="loading"></span>
                <span class="margin-left-xs">Connecting to Excel Online</span>
            </h4>

            <div ng-if="data.integration && !data.connecting">
                <div ng-switch="data.excelSheetsStep">
                    <!-- Select Spreadsheet -->
                    <div ng-switch-when="selectSpreadSheet">
                        <div class="alert alert-info flex-vmiddle">
                            <a
                                href="#"
                                analytics-on="click"
                                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                                analytics-event="Change Auth"
                                analytics-label="{{ currentIntegration.name }}"
                                ng-click="changeUser()"
                            >
                                Switch account
                            </a>

                            <div class="integration-group-header-connected" ng-if="integration">
                                Authenticated
                                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                                    as
                                    <span class="integration-group-header-connected-username">
                                        {{
                                            data.integration.integrationUserDisplayName ||
                                                data.integration.integrationUser
                                        }}
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="flex-vmiddle">
                            <b class="flex-grow">Select a Spreadsheet:</b>

                            <div class="flex-no-shrink">
                                <label class="sr-only" for="spreadsheetSearch">Search</label>
                                <input
                                    type="search"
                                    class="form-control"
                                    ng-model-options='{ debounce: 1000 }'
                                    id="spreadsheetSearch"
                                    placeholder="Search"
                                    ng-change="onSearchChanged(spreadsheetSearch)"
                                    ng-model="spreadsheetSearch"
                                />
                            </div>
                        </div>
                        <div class="integration-instance-selection-box margin-top">
                            <div ng-if="loadingExternal" class="text-center margin-top-lg">
                                <i class="loading-medium"></i>
                            </div>
                            <div
                                ng-click="onSpreadSheetSelected(selection)"
                                ng-repeat="selection in data.spreadsheets"
                                class="list-item btn-default pointer common-size-xxs"
                            >
                                <strong>{{ selection.displayName }}</strong>
                                <div class="common-size-xxxxs common-color-grey">
                                    <span>{{ selection.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Setup Spreadsheet -->
                    <div ng-switch-when="setupSpreadSheet">
                        Selected Spreadsheet:
                        <b>{{ data.spreadsheet.displayName }}</b>
                        -
                        <a ng-click="updateSpreadsheetsList(); data.excelSheetsStep= 'selectSpreadSheet';">
                            Change Spreadsheet
                        </a>
                        <hr />
                        <!--Select sheet option-->
                        <div class="flex-vmiddle margin-normal-sm-v" ng-hide="data.sheets.length == 1">
                            <div
                                class="
                                    col-sm-5
                                    common-size-xxs common-text-right-no-xs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Select sheet:</div>
                            </div>
                            <div class="col-sm-7">
                                <ui-select
                                    theme="bootstrap"
                                    ng-model="data.sheet"
                                    ng-disabled="!data.sheets || data.sheets.length == 1"
                                >
                                    <ui-select-match placeholder="Choose a sheet">
                                        {{ $select.selected.displayName }}
                                    </ui-select-match>
                                    <ui-select-choices
                                        ng-click="onSheetChanged(data.sheet)"
                                        repeat="sheet in data.sheets | filter:$select.search"
                                    >
                                        <div
                                            ng-bind-html="sheet.displayName | escapeHtml | highlight: $select.search"
                                        ></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>

                        <div class="flex-vmiddle margin-top-lg flex-xs-wrap">
                            <div
                                class="
                                    col-sm-5
                                    common-size-xxs common-text-right-no-xs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Start from row:</div>
                                <div class="common-size-xxxxs common-color-grey margin-left-xs hidden">Optional</div>
                            </div>

                            <!-- Input and explanation -->
                            <div class="col-sm-7">
                                <ui-select
                                    theme="bootstrap"
                                    ng-model="data.configSelections.startingRow"
                                    ng-disabled="!data.rows"
                                >
                                    <ui-select-match placeholder="Choose a row">
                                        {{ $select.selected.displayName }}
                                        <i
                                            class="
                                                svg-icon-vmiddle svg-icon-hover-dark-grey
                                                common-color-grey common-select-x
                                            "
                                            ng-click="$select.clear($event)"
                                        >
                                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                        </i>
                                    </ui-select-match>
                                    <ui-select-choices repeat="row in data.rows | filter:$select.search">
                                        <div
                                            ng-bind-html="row.displayName | escapeHtml | highlight: $select.search"
                                        ></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>

                        <div class="margin-top-lg padding-top">
                            Map Cells to item fields:
                            <hr class="margin-normal-sm-v" />
                        </div>
                        <!-- Title Field path -->
                        <div class="flex-vmiddle margin-top flex-xs-wrap">
                            <!-- Title -->
                            <div
                                class="
                                    col-sm-5
                                    common-size-xxs common-text-right-no-xs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Item Name/Title:</div>
                                <div class="common-size-xxxxs common-color-danger margin-left-xs">*</div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-7">
                                <div class="relative">
                                    <ui-select
                                        theme="bootstrap"
                                        ng-model="data.configSelections.titleFieldColumn"
                                        required
                                        ng-disabled="!data.columns"
                                    >
                                        <ui-select-match placeholder="Choose a column">
                                            {{ $select.selected.displayName }}
                                        </ui-select-match>
                                        <ui-select-choices repeat="column in data.columns | filter:$select.search">
                                            <div
                                                ng-bind-html="column.displayName | escapeHtml | highlight: $select.search"
                                            ></div>
                                            <div
                                                class="common-size-xxxxxs common-color-grey"
                                                ng-if="column.example && column.example !== ''"
                                            >
                                                <i>EX: {{ column.example }}</i>
                                            </div>
                                        </ui-select-choices>
                                    </ui-select>
                                    <!-- Field inspector button. -->
                                    <span
                                        class="svg-icon-nav-sm field-selector-inspect-icon pointer"
                                        ng-click="openFieldInspectModal('titleFieldColumn')"
                                    >
                                        <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- Id Field path -->
                        <div class="flex mod-align-start margin-top flex-xs-wrap">
                            <div
                                class="
                                    col-sm-5
                                    common-size-xxs common-text-right-no-xs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Unique Identifier:</div>
                                <div class="common-size-xxxxs common-color-danger margin-left-xs">*</div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-7">
                                <div class="relative">
                                    <ui-select
                                        theme="bootstrap"
                                        ng-model="data.configSelections.idFieldColumn"
                                        required
                                        ng-disabled="!data.columns"
                                    >
                                        <ui-select-match placeholder="Choose a column">
                                            {{ $select.selected.displayName }}
                                        </ui-select-match>
                                        <ui-select-choices repeat="column in data.columns | filter:$select.search">
                                            <div
                                                ng-bind-html="column.displayName | escapeHtml | highlight: $select.search"
                                            ></div>
                                            <div
                                                class="common-size-xxxxxs common-color-grey"
                                                ng-if="column.example && column.example !== ''"
                                            >
                                                <i>EX: {{ column.example }}</i>
                                            </div>
                                        </ui-select-choices>
                                    </ui-select>
                                    <!-- Field inspector button. -->
                                    <span
                                        class="svg-icon-nav-sm field-selector-inspect-icon pointer"
                                        ng-click="openFieldInspectModal('idFieldColumn')"
                                    >
                                        <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                                    </span>
                                </div>

                                <div class="common-size-xxxxs margin-top-xxs common-color-grey">
                                    This column will be used as a unique identifier of the row. If a row with the same
                                    id already exists in Tonkean, it will update it.
                                </div>
                            </div>
                        </div>

                        <!-- Description Field path -->
                        <div class="flex-vmiddle margin-top flex-xs-wrap">
                            <!-- Title and subtitle -->
                            <div
                                class="
                                    col-sm-5
                                    common-size-xxs common-text-right-no-xs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Item Description:</div>
                                <div class="common-size-xxxxs common-color-grey margin-left-xs hidden">Optional</div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-7">
                                <div class="relative">
                                    <ui-select
                                        theme="bootstrap"
                                        ng-model="data.configSelections.descriptionFieldColumn"
                                        ng-disabled="!data.columns"
                                    >
                                        <ui-select-match placeholder="Choose a column">
                                            {{ $select.selected.displayName }}
                                            <i
                                                class="
                                                    svg-icon-vmiddle svg-icon-hover-dark-grey
                                                    common-color-grey common-select-x
                                                "
                                                ng-click="$select.clear($event)"
                                            >
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </i>
                                        </ui-select-match>
                                        <ui-select-choices repeat="column in data.columns | filter:$select.search">
                                            <div
                                                ng-bind-html="column.displayName | escapeHtml | highlight: $select.search"
                                            ></div>
                                            <div
                                                class="common-size-xxxxxs common-color-grey"
                                                ng-if="column.example && column.example !== ''"
                                            >
                                                <i>EX: {{ column.example }}</i>
                                            </div>
                                        </ui-select-choices>
                                    </ui-select>
                                    <!-- Field inspector button. -->
                                    <span
                                        class="svg-icon-nav-sm field-selector-inspect-icon pointer"
                                        ng-click="openFieldInspectModal('descriptionFieldColumn')"
                                    >
                                        <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- Owner-Email Field path -->
                        <div class="flex-vmiddle margin-top flex-xs-wrap">
                            <!-- Title and subtitle -->
                            <div class="col-sm-5 common-size-xxs padding-right-none margin-top-xs margin-bottom-xs">
                                <div class="common-bold">Item Owner:</div>
                                <div class="common-size-xxxxxs common-color-grey">Values must be valid emails</div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-7">
                                <div class="relative">
                                    <ui-select
                                        theme="bootstrap"
                                        ng-model="data.configSelections.ownerEmailFieldColumn"
                                        ng-disabled="!data.columns"
                                    >
                                        <ui-select-match placeholder="Choose a column">
                                            {{ $select.selected.displayName }}
                                            <i
                                                class="
                                                    svg-icon-vmiddle svg-icon-hover-dark-grey
                                                    common-color-grey common-select-x
                                                "
                                                ng-click="$select.clear($event)"
                                            >
                                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                            </i>
                                        </ui-select-match>
                                        <ui-select-choices repeat="column in data.columns | filter:$select.search">
                                            <div
                                                ng-bind-html="column.displayName | escapeHtml | highlight: $select.search"
                                            ></div>
                                            <div
                                                class="common-size-xxxxxs common-color-grey"
                                                ng-if="column.example && column.example !== ''"
                                            >
                                                <i>EX: {{ column.example }}</i>
                                            </div>
                                        </ui-select-choices>
                                    </ui-select>
                                    <!-- Field inspector button. -->
                                    <span
                                        class="svg-icon-nav-sm field-selector-inspect-icon pointer"
                                        ng-click="openFieldInspectModal('ownerEmailFieldColumn')"
                                    >
                                        <tnk-icon src="/images/icons/search-book.svg"></tnk-icon>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- Use unique identifier column as external id -->
                        <label
                                class="margin-top-lg margin-bottom"
                                ng-hide="!data.showUniqueIdentifierAsExternalIdCheckbox"
                                uib-tooltip="If checked, the unique identifier column will be used as the external entity ID."
                        >
                            <input
                                    type="checkbox"
                                    ng-model="data.uniqueIdentifierAsExternalID"
                                    ng-change="onDefinitionChanged(true)"
                            />
                            <span class="common-normal-weight">Use the unique identifier as the external entity ID</span>
                        </label>

                        <!-- Button to show advanced settings -->
                        <!--<a ng-if="!data.advancedOptions"-->
                        <!--class="common-size-xxs"-->
                        <!--ng-click="data.advancedOptions = true;">Advanced</a>-->
                        <!--<div ng-if="data.advancedOptions">-->

                        <!--&lt;!&ndash; Owner-Name Field path &ndash;&gt;-->
                        <!--<div class="flex-vmiddle margin-top flex-xs-wrap">-->
                        <!--&lt;!&ndash; Title and subtitle &ndash;&gt;-->
                        <!--<div class="col-sm-5 common-size-xxs common-text-right-no-xs padding-right-none margin-top-xs margin-bottom-xs flex-xs mod-align-center">-->
                        <!--<div class="common-bold">Owner name:</div>-->
                        <!--<div class="common-size-xxxxs common-color-grey margin-left-xs">-->
                        <!--Optional-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--&lt;!&ndash; Input and explanation &ndash;&gt;-->
                        <!--<div class="col-sm-7">-->
                        <!--<ui-select theme="bootstrap" ng-model="data.configSelections.ownerNameFieldColumn"-->
                        <!--ng-disabled="!data.columns">-->
                        <!--<ui-select-match placeholder="Choose a column">{{$select.selected.displayName}}-->
                        <!--<span class="svg-icon-vmiddle svg-icon-hover-dark-grey absolute-right margin-right-lg margin-top-xs common-color-grey"-->
                        <!--ng-click="$select.clear($event)">-->
                        <!--<tnk-icon src="/images/icons/x.svg"></tnk-icon>-->
                        <!--</span>-->
                        <!--</ui-select-match>-->
                        <!--<ui-select-choices repeat="column in data.columns | filter:$select.search">-->
                        <!--<div ng-bind-html="column.displayName | escapeHtml | highlight: $select.search"></div>-->
                        <!--<div class="common-size-xxxxxs common-color-grey"-->
                        <!--ng-if="column.example && column.example !== ''"><i>EX: {{column.example}}</i></div>-->
                        <!--</ui-select-choices>-->
                        <!--</ui-select>-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--</div>-->
                    </div>

                    <!-- Select Columns -->
                    <div ng-switch-when="selectColumns">
                        <div>
                            <b>What columns do you want to see in Tonkean?</b>
                        </div>
                        <div class="flex-vmiddle margin-top flex-xs-wrap">
                            <div
                                class="
                                    col-sm-1
                                    common-size-xxs common-text-right-no-xs
                                    padding-right
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            ></div>
                            <!-- Title and subtitle -->
                            <div
                                class="
                                    col-sm-4
                                    common-size-xxs
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                    flex-xs
                                    mod-align-center
                                "
                            >
                                <div class="common-bold">Spreadsheet Column</div>
                            </div>
                            <!-- Input and explanation -->
                            <div class="col-sm-7">
                                <div class="common-bold">Tonkean Field name</div>
                            </div>
                        </div>
                        <div class="integration-instance-selection-box margin-top">
                            <div ng-if="loadingExternal" class="text-center margin-top-lg">
                                <i class="loading-medium"></i>
                            </div>
                            <div
                                ng-repeat="column in data.filteredColumns"
                                ng-if="column.example || data.showAllColumns"
                                ng-click="data.selectedColumnsFlags[column.value] = !data.selectedColumnsFlags[column.value]"
                                class="list-item btn-default pointer common-size-xxs"
                            >
                                <div class="flex-vmiddle margin-top flex-xs-wrap">
                                    <div
                                        class="
                                            col-sm-1
                                            common-size-xxs common-text-right-no-xs
                                            padding-right-none
                                            margin-top-xs margin-bottom-xs
                                            mod-align-center
                                        "
                                    >
                                        <input
                                            type="checkbox"
                                            ng-model="data.selectedColumnsFlags[column.value]"
                                            ng-click="columnSelected($event)"
                                        />
                                    </div>
                                    <!-- Title and subtitle -->
                                    <div
                                        class="
                                            col-sm-4
                                            common-size-xxs
                                            padding-right
                                            margin-top-xs margin-bottom-xs
                                            mod-align-center
                                            common-border-right common-border-left
                                        "
                                    >
                                        <div class="common-bold">Column {{ column.displayName }}</div>
                                        <div ng-if="column.example" class="common-size-xxxxs common-color-grey">
                                            Ex: {{ column.example }}
                                        </div>
                                    </div>
                                    <!-- Input and explanation -->
                                    <div class="">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter column name..."
                                            ng-click="onColumnNameInputClick($event)"
                                            ng-model="data.selectedColumns[column.value]"
                                            ng-required="data.selectedColumnsFlags[column.value]"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div
                                ng-if="!data.showAllColumns"
                                ng-click="toggleShowAllColumns()"
                                class="list-item btn-default pointer common-size-xxs flex-vmiddle mod-center"
                            >
                                <a class="margin-top-xs margin-bottom-xs">Show all columns</a>
                            </div>
                        </div>
                        <div class="margin-top">
                            Select
                            <a ng-click="selectAllColumns()">All</a>
                            &nbsp;\&nbsp;
                            <a ng-click="unselectAllColumns()">None</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-vmiddle">
            <!-- Error -->
            <div class="common-color-danger" ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid">
                You must choose a title and unique identifier
            </div>

            <!-- Placeholder -->
            <div class="flex-grow"></div>

            <!-- Cancel button -->
            <button
                class="btn btn-default margin-right-xs flex-no-shrink"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (data.selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="cancel()"
            >
                Cancel
            </button>

            <!-- OK button -->
            <button
                class="btn btn-primary flex-no-shrink"
                ng-if="data.sheet"
                ng-disabled="(data.selections|filter:{loadingBranches:true}:true).length || !data.integrationForm.$valid"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (data.selections | filter: { selected: true }:true).length }}"
                type="submit"
            >
                OK
            </button>
        </div>
    </form>
</div>
