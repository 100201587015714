import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ModulePicker from './ModulePicker';
import type { ModuleOption } from './ModulePickerMenuSection';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { ModuleBrowserSolutionBoxTabsState } from '@tonkean/infrastructure';
import { TextEllipsis } from '@tonkean/infrastructure';
import type { Group } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SolutionOpsModulesDropdownWrapper = styled.div`
    display: flex;
    align-items: baseline;
`;

const Separator = styled.span`
    color: ${Theme.colors.gray_500};
    margin: 0 10px;
`;

const SolutionNameLink = styled(StateLink)`
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    text-decoration-line: underline;
    color: ${Theme.colors.gray_500};
    max-width: 200px;

    &:active {
        outline: none;
    }
`;

const SolutionName = styled.span`
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 16px;
    color: ${Theme.colors.gray_500};
    max-width: 200px;
`;

interface Props {
    group: Group;
    onMenuItemClicked: (item: ModuleOption) => void;
    linkToSolution?: boolean;
}

const SolutionOpsModulesDropdown: React.FC<Props> = ({ group, onMenuItemClicked, linkToSolution = true }) => {
    const projectManager = useAngularService('projectManager');
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const tonkeanService = useAngularService('tonkeanService');
    const [groups, setGroups] = useState<Group[]>();

    // Get the solution of the module
    const folder = useMemo(() => {
        return workflowFolderManager.getContainingWorkflowFolder(projectManager.project.id, group.id);
    }, [workflowFolderManager, projectManager, group]);

    // Get other module ids in the solution
    const groupIds = useMemo(() => {
        return workflowFolderManager.projectIdToFolderIdToFolderMap[projectManager.project.id]?.[folder.id]?.groupIds;
    }, [folder, projectManager, workflowFolderManager]);

    // Fetch the modules by their ids
    const { data: groupsData, loading: groupsDataLoading } = useTonkeanService(
        'getGroupsBySolution',
        projectManager.project.id,
        folder.id,
    );

    // Fetch the modules by their ids
    const { data: workflowFolderCategoriesResponse, loading: workflowFolderCategoriesLoading } = useTonkeanService(
        'getWorkflowFolderCategories',
        projectManager.project.id,
        folder.id,
    );

    useEffect(() => {
        setGroups(groupsData?.entities);
    }, [groupsData]);

    // Update the options when the group changes (for example, we renamed a group)
    useEffect(() => {
        // Filter out the current group
        const currentGroupIndex = groupsData?.entities.findIndex((groupData) => groupData.id === group.id);
        if (groupsData && currentGroupIndex > -1) {
            groupsData.entities[currentGroupIndex] = group;
            setGroups([...groupsData.entities]);
        } else {
            setGroups([group]);
        }
    }, [group, groupsData, groupIds]);

    const loading = groupsDataLoading || workflowFolderCategoriesLoading;
    const solutionPageLinkParams = {
        solutionId: folder.id,
        tab: ModuleBrowserSolutionBoxTabsState.MODULES,
        categoryId: group?.workflowFolderCategoryId,
    };

    return (
        <SolutionOpsModulesDropdownWrapper>
            {linkToSolution ? (
                <SolutionNameLink
                    state="product.solution"
                    params={solutionPageLinkParams}
                    data-automation="solution-ops-module-current-solution"
                    data-automation-solution-display-name={folder.displayName}
                >
                    <TextEllipsis numberOfLines={1} tooltipLimitWidth={200} tooltip>
                        {folder.displayName}
                    </TextEllipsis>
                </SolutionNameLink>
            ) : (
                <SolutionName
                    data-automation="solution-ops-module-current-solution"
                    data-automation-solution-display-name={folder.displayName}
                >
                    <TextEllipsis numberOfLines={1} tooltipLimitWidth={200} tooltip>
                        {folder.displayName}
                    </TextEllipsis>
                </SolutionName>
            )}

            <Separator>/</Separator>

            <ModulePicker
                groups={groups}
                workflowFolderCategories={workflowFolderCategoriesResponse?.workflowFolderCategories}
                selectedGroup={group}
                isLoading={loading}
                onMenuItemClicked={onMenuItemClicked}
            />
        </SolutionOpsModulesDropdownWrapper>
    );
};

export default SolutionOpsModulesDropdown;
