<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div
            ng-if="
            !data.loading &&
            !data.actionsByIntegrationsMap['INTERCOM'].customActions[data.definition.customActionKey]
                .doesNotRequireActionPerformer
        "
    >
        <div class="margin-right">Action Performer:</div>

        <div ng-if="data.validationObject.noAdmin" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noAdmin }}
        </div>

        <form ng-submit="" novalidate class="margin-top-xxs">
            <tnk-person-selection-configuration
                    class="block margin-bottom-lg"
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    radio-id-prefix="'performer-' + data.workflowVersionId"
                    validation-object="data.validationObject"
                    saved-person-selection-configuration="data.existingAdminSelectionConfiguration"
                    preview-evaluation-source="data.previewEvaluationSource"
                    on-person-selection-configuration-changed="
                    onPerformingAdminSelected(personSelectionConfiguration, shouldSaveLogic)
                "
                    support-quick-create="false"
                    allow-custom="true"
                    specific-people-custom-options-refresh="refreshAdmins(q)"
                    specific-people-custom-options="data.admins"
                    configured-logic="data.configuredLogic"
                    unique-identifier="performer"
            ></tnk-person-selection-configuration>
        </form>
    </div>

    <!-- Snooze -->
    <div
            class="delay-configuration-logic"
            ng-if="!data.loading && data.definition.customActionKey === 'SNOOZE_CONVERSATION'"
    >
        <!-- Title -->
        <div class="margin-bottom-xs">Time to snooze in minutes:</div>

        <div ng-if="data.validationObject.noSnooze" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noSnooze }}
        </div>

        <!-- Input -->
        <input
                type="number"
                class="form-control"
                ng-model="data.definition.timeToSnoozeInMinutes"
                ng-change="onDefinitionChanged(true)"
        />
    </div>

    <!-- Reply to conversation -->
    <div
            class="delay-configuration-logic"
            ng-if="!data.loading && data.definition.customActionKey === 'REPLY_CONVERSATION'"
    >
        <!-- Title -->
        <div class="margin-bottom-xs">Text to reply:</div>

        <div ng-if="data.validationObject.noText" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noText }}
        </div>

        <!-- Input -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Type a custom message to send"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.definition.text"
                saved-evaluated-expression="data.definition.evaluatedText"
                on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <div>
            <tnk-checkbox
                    class="margin-top-xs"
                    label="'Reply to user?'"
                    value="data.definition.isPublic"
                    on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>
    </div>

    <!-- Start conversation -->
    <div class="padding-right" ng-if="!data.loading && data.definition.customActionKey === 'START_CONVERSATION'">
        <!-- Message Body -->
        <div class="">Message body:</div>
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Message body..."
                show-toggle-strip-html="true"
                saved-expression="data.definition.bodyExpression"
                on-tonkean-expression-changed="onBodyExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>

        <div ng-if="data.validationObject.noBody" class="common-error margin-top-xs">
            {{ data.validationObject.noBody }}
        </div>

        <!-- Recipient -->
        <div class="margin-top">Recipient:</div>
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Email, Intercom user ID or Custom ID..."
                saved-expression="data.definition.recipientEntityExpression"
                on-tonkean-expression-changed="onRecipientExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>

        <div ng-if="data.validationObject.noRecipient" class="common-error margin-top-xs">
            {{ data.validationObject.noRecipient }}
        </div>

        <!-- Recipient Type -->
        <div class="margin-top margin-bottom-xs">
            <div>Recipient Content Type:</div>
            <span class="common-size-xxs common-color-grey3">
                What is the type of the value of the "Recipient" field
            </span>
        </div>

        <!-- Id Type Selection -->
        <tnk-intercom-id-type-picker
                on-id-type-selected="onRecipientEntityIdTypeSelected(idTypeSelected)"
                selected-id-type="data.definition.recipientEntityIdType"
        ></tnk-intercom-id-type-picker>

        <div ng-if="data.validationObject.noRecipientEntityIdType" class="common-error">
            {{ data.validationObject.noRecipientEntityIdType }}
        </div>

        <!-- Recipient Type -->
        <div class="margin-top margin-bottom-xs">
            <div>Recipient Type:</div>
            <span class="common-size-xxs common-color-grey3">What's the role of the Recipient</span>
        </div>

        <!-- Recipient Content Type Selection -->
        <ui-select
            class="margin-bottom"
            ng-model="data.definition.recipientType"
            ng-change="onRecipientTypeSelected()"
            theme="bootstrap"
        >
            <ui-select-match placeholder="Recipient type">
                {{ data.intercomUserTypeDisplayNames[data.definition.recipientType] }}
            </ui-select-match>
            <ui-select-choices repeat="userType in data.intercomUserTypeOptions | filter:$select.search ">
                <div
                    ng-bind-html="data.intercomUserTypeDisplayNames[userType] | escapeHtml | highlight: $select.search"
                ></div>
            </ui-select-choices>
        </ui-select>

        <div ng-if="data.validationObject.noRecipientType" class="common-error">
            {{ data.validationObject.noRecipientType }}
        </div>

        <!-- Message Type -->
        <div class="margin-top margin-bottom-xs">
            <div>Message Type:</div>
            <span class="common-size-xxs common-color-grey3">How to send the message</span>
        </div>

        <!-- Message Type Selection -->
        <ui-select
            class="margin-bottom"
            ng-model="data.definition.messageType"
            ng-change="onMessageTypeSelected()"
            theme="bootstrap"
        >
            <ui-select-match placeholder="Message type">
                {{ data.messageTypeDisplayNames[data.definition.messageType] }}
            </ui-select-match>
            <ui-select-choices repeat="messageType in data.messageTypeOptions | filter:$select.search ">
                <div
                    ng-bind-html="data.messageTypeDisplayNames[messageType] | escapeHtml | highlight: $select.search"
                ></div>
            </ui-select-choices>
        </ui-select>

        <div ng-if="data.validationObject.noMessageType" class="common-error">
            {{ data.validationObject.noMessageType }}
        </div>

        <div ng-if="data.definition.messageType === 'EMAIL'">
            <div class="margin-bottom-xs common-title-xxs">Email Settings</div>

            <!-- Subject -->
            <div class="margin-top">Subject:</div>
            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Subject of Email..."
                    saved-expression="data.definition.subjectExpression"
                    on-tonkean-expression-changed="onSubjectExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.noSubject" class="common-error margin-top-xs">
                {{ data.validationObject.noSubject }}
            </div>

            <!-- Message Type -->
            <div class="margin-top margin-bottom-xs">
                <div>Message Template Style:</div>
                <span class="common-size-xxs common-color-grey3">What template would the message use</span>
            </div>

            <!-- Message Type Selection -->
            <ui-select
                class="margin-bottom"
                ng-model="data.definition.template"
                ng-change="onMessageTemplateStyleSelected()"
                theme="bootstrap"
            >
                <ui-select-match placeholder="Message Style">
                    {{ data.templateStyleDisplayNames[data.definition.template] }}
                </ui-select-match>
                <ui-select-choices repeat="style in data.templateStyleOptions | filter:$select.search ">
                    <div
                        ng-bind-html="data.templateStyleDisplayNames[style] | escapeHtml | highlight: $select.search"
                    ></div>
                </ui-select-choices>
            </ui-select>
        </div>
    </div>

    <!-- Assign an owner to a conversation -->
    <div
            class="delay-configuration-logic"
            ng-if="!data.loading && data.definition.customActionKey === 'ASSIGN_CONVERSATION'"
    >
        <div class="margin-right margin-top-xs">Who should be assigned?</div>

        <div ng-if="data.validationObject.noAssignee" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noAssignee }}
        </div>

        <form ng-submit="" novalidate class="margin-top-xxs">
            <tnk-person-selection-configuration
                    class="block margin-bottom-lg"
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    radio-id-prefix="'assignee-' + data.workflowVersionId"
                    validation-object="data.validationObject"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-person-selection-configuration="data.existingPersonSelectionConfiguration"
                    on-person-selection-configuration-changed="
                    onPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
                "
                    no-tracks-owner-option="true"
                    support-quick-create="false"
                    specific-people-custom-options="data.adminsAndTeams"
                    specific-people-custom-options-refresh="refreshAdmins(q)"
                    configured-logic="data.configuredLogic"
                    unique-identifier="assignee"
            ></tnk-person-selection-configuration>
        </form>
    </div>

    <!-- Create a note -->
    <div class="delay-configuration-logic" ng-if="!data.loading && data.definition.customActionKey === 'CREATE_NOTE'">
        <!-- Title -->
        <div class="margin-bottom-xs">Add the following note to the User this conversation is with:</div>

        <div ng-if="data.validationObject.noText" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noText }}
        </div>

        <!-- Input -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type a custom text for the note"
                saved-original-expression="data.definition.text"
                saved-evaluated-expression="data.definition.evaluatedText"
                on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>
    </div>

    <!-- Add\Update User Custom Attributes-->
    <div
            class="delay-configuration-logic margin-right"
            ng-if="data.definition.customActionKey === 'UPDATE_CUSTOM_ATTRIBUTES'"
    >
        <!-- Validation error message -->
        <div
                ng-if="data.validationObject.noCustomAttributes"
                class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.noCustomAttributes }}
        </div>

        <!-- Select user id type to match by -->
        <div class="margin-bottom-xs">Match user by:</div>

        <!-- Id Type Selection -->
        <tnk-intercom-id-type-picker
                on-id-type-selected="onUpdateCustomAttributeEntityIdTypeSelected(idTypeSelected)"
                selected-id-type="data.definition.updateCustomAttributeEntityIdType"
        ></tnk-intercom-id-type-picker>

        <!-- Title -->
        <div class="margin-bottom-xs flex flex-inline">
            Which Custom Attributes to update?
            <div
                    class="margin-left"
                    uib-popover-template="'./customAttributesMessageTemplate.template.html'"
                    popover-placement="{{ placement || 'bottom-middle' }}"
                    popover-class="common-width-60"
                    popover-trigger="mouseenter"
            >
                <a class="inline-block common-italic" target="_blank"><i class="fa fa-info-circle"></i></a>
            </div>
        </div>

        <!-- Attributes filling -->
        <div
                ng-repeat="customAttribute in data.definition.customAttributes"
                class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs"
        >
            <!-- Title for key -->
            <div class="margin-bottom-xs flex-vmiddle mod-justify-space">
                <div>Key:</div>

                <i class="fa fa-trash-o pointer" ng-click="removeCustomAttribute($index)"></i>
            </div>

            <!-- Expression for key -->
            <div class="margin-bottom">
                <tnk-tonkean-expression
                        class="margin-bottom-xs"
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        preview-evaluation-source="data.previewEvaluationSource"
                        placeholder="Attribute Key"
                        saved-expression="customAttribute.keyExpression"
                        expression-unique-identifier="{{ $index }}"
                        on-tonkean-expression-changed="
                        onAttributeKeyTonkeanExpressionChanged(expression, expressionIdentifier, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>

            <!-- Title for value -->
            <div class="margin-bottom-xs">Value:</div>

            <!-- Expression for value -->
            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Attribute Value"
                    saved-expression="customAttribute.valueExpression"
                    expression-unique-identifier="{{ $index }}"
                    on-tonkean-expression-changed="
                    onAttributeValueTonkeanExpressionChanged(expression, expressionIdentifier, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Add another attribute -->
        <a ng-click="addEmptyAttributeToUpdate()">+ Add Attribute</a>
    </div>

    <!-- Tag/Untag intercom entities (User/Company/Lead) -->
    <div
            class="margin-left-xs margin-right"
            ng-if="
            data.definition.customActionKey === 'TAG_USER' ||
            data.definition.customActionKey === 'TAG_LEAD' ||
            data.definition.customActionKey === 'TAG_COMPANY' ||
            data.definition.customActionKey === 'UNTAG_USER' ||
            data.definition.customActionKey === 'UNTAG_LEAD' ||
            data.definition.customActionKey === 'UNTAG_COMPANY' ||
            data.definition.customActionKey === 'TAG_CONVERSATION' ||
            data.definition.customActionKey === 'UNTAG_CONVERSATION'
        "
    >
        <!-- Id Type Title -->
        <div
                class="margin-bottom-xs"
                ng-if="data.definition.customActionKey === 'TAG_USER' || data.definition.customActionKey === 'UNTAG_USER'"
        >
            Match user by:
        </div>

        <!-- Id Type Selection -->
        <tnk-intercom-id-type-picker
                ng-if="
                data.definition.customActionKey !== 'TAG_CONVERSATION' &&
                data.definition.customActionKey !== 'UNTAG_CONVERSATION'
            "
                on-id-type-selected="onTaggingEntityIdTypeSelected(idTypeSelected)"
                selected-id-type="data.definition.taggingEntityIdType"
        ></tnk-intercom-id-type-picker>

        <!-- Tag Title -->
        <div class="margin-bottom-xs">{{ data.definition.customActionKey.startsWith('UNTAG') ? 'Untag' : 'Tag' }}:</div>

        <!-- Tag Input -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type a custom tag"
                saved-original-expression="data.definition.tag"
                saved-evaluated-expression="data.definition.evaluatedTagText"
                on-tonkean-expression-changed="
                onTonkeanTagExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <!-- Validation error -->
        <div class="common-error">{{ data.validationObject.emptyTagWarning }}</div>
    </div>

    <!-- Archive user -->
    <div class="margin-left-xs margin-right" ng-if="data.definition.customActionKey === 'ARCHIVE_USER'">
        <!-- Id Type Title -->
        <div class="margin-bottom-xs">Match user by:</div>

        <tnk-intercom-id-type-picker
                on-id-type-selected="onArchiveUserIdTypeSelected(idTypeSelected)"
                selected-id-type="data.definition.archiveUserIdType"
        ></tnk-intercom-id-type-picker>

        <!-- Validation error -->
        <div class="common-error">{{ data.validationObject.emptyUserIdType }}</div>
    </div>

    <!-- Create intercom user -->
    <div ng-if="data.definition.customActionKey == 'CREATE_USER'">
        <tnk-checkbox
                label="'Overwrite blank fields'"
                value="data.definition.allowBlankFields"
                class="margin-top-md"
                on-toggle-async="onAllowBlankFieldsToggled(action, value)"
        ></tnk-checkbox>

        <a
                uib-tooltip="If checked and value is not provided, will overwrite the value of a field to blank, otherwise if not checked, will ignore the field."
        >
            <i class="fa fa-info-circle margin-left-xs"></i>
        </a>

        <!-- Email Title -->
        <div class="margin-top-md">Email:</div>

        <!-- Email Input -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type the user email"
                saved-expression="data.definition.emailExpression"
                on-tonkean-expression-changed="onTonkeanEmailExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>

        <!-- Validation error -->
        <div class="common-error">{{ data.validationObject.atLeastEmailOrUserIdWarning }}</div>

        <!-- User id Title -->
        <div class="margin-top-md">User id:</div>

        <!-- User id Input -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type the user id"
                saved-expression="data.definition.userIdExpression"
                on-tonkean-expression-changed="onTonkeanUserIdExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>

        <!-- Validation error -->
        <div class="common-error">{{ data.validationObject.atLeastEmailOrUserIdWarning }}</div>

        <!-- Name Title -->
        <div class="margin-top-md">Name:</div>

        <!-- Name Input -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type the user name"
                saved-expression="data.definition.nameExpression"
                on-tonkean-expression-changed="onTonkeanNameExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>

        <!-- Phone Title -->
        <div class="margin-top-md">Phone:</div>

        <!-- Phone Input -->
        <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Type the user phone"
                saved-expression="data.definition.phoneExpression"
                on-tonkean-expression-changed="onTonkeanPhoneExpressionChanged(expression, shouldSaveLogic)"
        ></tnk-tonkean-expression>
    </div>

    <!-- Add form to conversation -->
    <div class="margin-left-xs margin-right" ng-if="data.definition.customActionKey === 'ADD_FORM_TO_CONVERSATION'">
        <div class="common-title-xxs margin-bottom-xs">Choose form</div>

        <tnk-form-selector
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                selected-form-id="data.definition.formId"
                hide-create-forms="true"
                on-form-selected="onFormSelected(form)"
        ></tnk-form-selector>
    </div>

    <!-- Redact message from conversation -->
    <div
            class="margin-left-xs margin-right"
            ng-if="data.definition.customActionKey === 'REDACT_MESSAGE_FROM_CONVERSATION'"
    >
        <div>
            <!-- Message ID Title -->
            <div class="margin-top-md">Message ID:</div>

            <!-- Message id Input -->
            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    on-tonkean-expression-changed="onTonkeanMessageIdExpressionChanged(expression, shouldSaveLogic)"
                    placeholder="Message ID"
                    saved-expression="data.definition.messageIdExpression"
                    workflow-version-id="{{ data.workflowVersionId }}"
            ></tnk-tonkean-expression>
        </div>

        <!-- Validation error -->
        <div class="common-error">{{ data.validationObject.messageIDMissingError }}</div>

        <!-- Is it the first message in the conversation? (the request body will look different) -->
        <div class="margin-top">
            <tnk-checkbox
                    class="margin-top-xs"
                    label="'Is it the first message?'"
                    value="data.definition.isFirstMessage"
                    on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>
    </div>

    <!-- Form type error -->
    <div ng-if="data.validationObject.formTypeError" class="margin-top common-color-danger">
        {{ data.validationObject.formTypeError }}

        <a ng-click="editForm()">Edit</a>
    </div>
</div>
