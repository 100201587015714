import React from 'react';
import styled from 'styled-components';

import EnterpriseComponentsExplorerListItem from './EnterpriseComponentsExplorerListItem';
import type { EnterpriseComponentExplorerListSectionConfiguration } from '../../../entities/EnterpriseComponentExplorerListConfiguration';

import { Separator } from '@tonkean/infrastructure';
import { PersonPermissionRoleType } from '@tonkean/tonkean-entities';
import type { EnterpriseComponent, TonkeanType } from '@tonkean/tonkean-entities';
import type { EnterpriseComponentsPersonPermissionRole } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const SectionTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    margin-left: 14px;
    margin-top: 18px;
    margin-bottom: 18px;
`;

interface Props {
    enterpriseComponentsCurrentPersonPermissionRole: EnterpriseComponentsPersonPermissionRole;
    enterpriseComponentsByIds: Record<string, EnterpriseComponent>;
    enterpriseComponentType: TonkeanType;
    section: EnterpriseComponentExplorerListSectionConfiguration;
}

function getEnterpriseComponentAccessType(enterpriseComponentsCurrentPersonPermissionRole, componentId) {
    return (
        enterpriseComponentsCurrentPersonPermissionRole.enterpriseComponentPersonAccessType[componentId] ??
        PersonPermissionRoleType.NOT_AUTHORIZED
    );
}

const EnterpriseComponentsExplorerListSection: React.FC<Props> = ({
    section,
    enterpriseComponentsCurrentPersonPermissionRole,
    enterpriseComponentType,
    enterpriseComponentsByIds,
}) => {
    return (
        <React.Fragment>
            {section.title !== '' && (
                <>
                    <SectionTitle>{section.title}</SectionTitle>
                    <Separator color={Theme.colors.gray_400} />
                </>
            )}
            {section.itemIds.map((itemId) => {
                return (
                    <div key={itemId}>
                        <EnterpriseComponentsExplorerListItem
                            enterpriseComponentAccessType={getEnterpriseComponentAccessType(
                                enterpriseComponentsCurrentPersonPermissionRole,
                                itemId,
                            )}
                            enterpriseComponentId={itemId}
                            enterpriseComponentType={enterpriseComponentType}
                            enterpriseComponentsByIds={enterpriseComponentsByIds}
                        />
                        <Separator color={Theme.colors.gray_300} />
                    </div>
                );
            })}
        </React.Fragment>
    );
};
export default EnterpriseComponentsExplorerListSection;
