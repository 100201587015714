import * as React from 'react';
import { useMemo } from 'react';

import { SimpleSelect } from '@tonkean/infrastructure';
import { AuthorizationTypeToDisplayName, EnterpriseComponentAuthorizationType } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {}

const EnterpriseComponentAuthorizationSelector: React.FC<Props> = ({ className }) => {
    // All Supported Authorization types.
    const supportedTypes = Object.keys(EnterpriseComponentAuthorizationType) as EnterpriseComponentAuthorizationType[];

    const options = useMemo(() => {
        return supportedTypes.map((authorizationType) => ({
            value: authorizationType,
            label: AuthorizationTypeToDisplayName[authorizationType],
        }));
    }, [supportedTypes]);

    return <SimpleSelect name="type" className={className} options={options} />;
};

export default EnterpriseComponentAuthorizationSelector;
