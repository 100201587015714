<div>
    <!-- Close button -->
    <div class="common-close-btn mod-big mod-no-container mod-with-text" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <div class="flex mod-center on-boarding-continuous-content">
        <div>
            <!-- Title -->
            <h2 class="common-title">How to setup your first list</h2>

            <!-- Steps content -->
            <div class="margin-top-xlg">
                <div
                    ng-repeat="step in obm.metadata.stepsList"
                    class="on-boarding-continuous-step flex mod-align-start"
                >
                    <!-- Check marks -->
                    <span
                        class="
                            on-boarding-continuous-step-bullet
                            svg-icon-xxs svg-icon-o
                            flex-vmiddle flex-no-shrink
                            margin-right-lg
                        "
                        ng-class="{ 'mod-completed': step.completed }"
                    >
                        <tnk-icon src="/images/icons/check.svg"></tnk-icon>
                    </span>
                    <!-- Steps -->
                    <div>
                        <!-- Step title -->
                        <div class="common-title-xxs mod-light">
                            <strong>
                                <ng-pluralize
                                    count="::$index"
                                    when="{'0': '1st ', '1': '2nd ', '2': '3rd ', '3': '4th '}"
                                ></ng-pluralize>
                                step:&nbsp;
                            </strong>
                            {{::step.title}}
                        </div>
                        <!-- Step body -->
                        <div ng-if="!step.completed">
                            <!-- Step body text -->
                            <div class="common-size-xxs margin-top-xxs" ng-bind-html="::step.text"></div>
                            <!-- Step action (if exists) -->
                            <div ng-if="step.action" class="margin-top-xs">
                                <a href="#" ng-click="stepClicked(step.name)" class="common-bold">{{ step.action }}</a>
                            </div>
                            <!-- Step image (if exists) -->
                            <div ng-if="::step.img" class="on-boarding-step-img-container margin-top">
                                <img ng-src="{{::step.img}}" alt="{{::step.shortTitle}}" />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="
                        margin-left-xlg
                        padding-left-xs
                        margin-top-xlg
                        padding-top-xlg
                        flex
                        mod-align-end mod-justify-space
                    "
                >
                    <!-- Close button -->
                    <button
                        type="button"
                        class="btn btn-primary-black padding-right-xlg padding-left-xlg flex-no-shrink"
                        ng-click="close()"
                    >
                        Got it!
                    </button>
                    <!-- Dismiss button -->
                    <a href="#" ng-click="dismissOnBoarding()" class="common-color-grey common-size-xxxxs">
                        Dismiss on boarding
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
