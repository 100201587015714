import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addNewLogicOption from '../sharedConfigComponents/addNewLogicOption';
import addButtonBlockCreateAfterOption from '../sharedConfigComponents/button/createAfterOptions/addButtonBlockCreateAfterOption';
import botQuestionAndSendNotificationValidator from '../sharedConfigComponents/validators/botQuestionAndSendNotificatinValidator';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const PersonInquiryLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.PERSON_INQUIRY,
    title: 'Ask a Question',
    iconClass: 'mod-ask',
    description:
        'Define a question that the module sends to a specified user or group of users. The recipient can respond using action buttons to continue the workflow.',
    hasPersonConfig: true,
    // Note: canceling the fact that only a button is allowed after ask a question. Buttons will be added through the ask a question UI.
    createAfterOptions: () => [addButtonBlockCreateAfterOption, addNewLogicOption],
    defaultActionDefinition: (project) => {
        return {
            initiativeOwner: true,
            hideInitiativePageLink: true,
            text: '',
            buttons: [],
            communicationProjectIntegrationId: project?.defaultCommunicationProjectIntegration?.id,
        };
    },
    validator: ({ definition, group, syncConfigCacheManager, workflowVersionInfoRetriever, projectManager }) => {
        const validationObject: LogicBlockValidationObject = {};
        botQuestionAndSendNotificationValidator(
            validationObject,
            definition,
            workflowVersionInfoRetriever,
            syncConfigCacheManager,
            group,
            projectManager,
        );
        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default PersonInquiryLogicBlockConfig;
