/**
 * Use the dnd-draggable attribute to make your element draggable.
 * This infrastructure uses mouse events (instead of drag-drop events) which is much much faster and doesn't get stuck
 * when complex html is dragged.
 *
 *** Draggable item attributes:
 * - tnk-dnd-draggable          Required attribute. This attribute is the directive itself and it makes the item draggable.
 *                              The value is the data you wish to pass to your callbacks
 *                              The value should be surrounded with {{}} since it's not parsed to save on loading time.
 *
 * - tnk-dnd-no-dragover        Optional attribute. Use this attribute to dynamically disable the drag over option on a draggable item.
 *                              Dragging over is considered when the user's mouse is under the top 25% but above the bottom 25%.
 *                              When drag-over is disabled, the tnk-dnd-drop-into callback will never be called.
 *                              The value should be surrounded with {{}} since it's not parsed to have better performance.
 *
 * - tnk-dnd-disabled           Optional attribute. Use this attribute to dynamically disable the draggability of the element.
 *                              The value should be surrounded with {{}} since it's not parsed to have better performance.
 *
 * - tnk-dnd-relative-to        Optional attribute. Expects an element id. If provided, the positioning of the dragged element
 *                              will be according to the found item.
 *                              The value should be surrounded with {{}} (if dynamic) since it's not parsed to have better performance.
 *
 *** Draggable item callbacks:
 * - tnk-dnd-dragstart          Callback that is invoked when dragging starts. Dragging is considered started if the mouse left button
 *                              is clicked and the mouse is moved. The drag operation itself starts after 100 milliseconds, so we don't
 *                              start dragging after every click.
 *                              Parameters supplied: event, dndData (the data supplied through tnk-dnd-draggable).
 *
 * - tnk-dnd-dragend            Callback that is invoked when dragging ends.
 *                              Parameters supplied: event.
 *
 * - thk-dnd-moved              Callback that is invoked when the element was moved. Usually you will remove your element from the original
 *                              list in this callback, since the directive is not doing that for you automatically.
 *                              Parameters supplied: event, dndData (data supplied via tnk-dnd-draggable), dndIndex (new item's index in list).
 *
 * - tnk-dnd-drop-into          Callback that is invoked when an element is dropped into this element.
 *                              Dragging over is considered when the user's mouse is under the top 25% but above the bottom 25%.
 *                              Parameters supplied: event, dndData (tnk-dnd-draggable data of the dropped item), dndIndex (new item's index in list),
 *                                                   dndListId (tnk-dnd-draggable data of the this item - the item dropped into).
 *
 * - tnk-dnd-get-placeholder    An optional callback that should return an HTML (string) of a placeholder that's left behind instead of the
 *                              dragged item when dragging. If not supplied, no dragging placeholder is left.
 *                              Parameters supplied: event, dndData (data supplied via tnk-dnd-draggable).
 *
 *** Draggable LIST attributes:
 * - tnk-dnd-list               Required list attribute. Marks the list in which the items are located.
 *                              No value should be passed, this is only a marker.
 *
 * - tnk-dnd-list-id            Optional list attribute. Use this attribute to supply the list id. This id is later passed to the tnk-dnd-drop
 *                              callback and can be used to identify the the list the item is dropped into (if dropping into an inner list for example).
 *
 * - tnk-dnd-list-full-dropzone Optional list attribute. When true, the infrastructure won't try to calculate if the dnd marker should be placed before or after the
 *                              dragged over child. It will simply insert the marker after that child.
 *                              Use this when you just want a simple dropzone that should not behave as a list (where you can drag above or under an item).
 *
 *** Draggable LIST callbacks:
 * - tnk-dnd-drop               Callback that is invoked when an item is dropped in the list. Use this callback to move the dropped item to its
 *                              new list, since the infrastructure won't do that for you.
 *                              Parameters supplied: event, dndData (tnk-dnd-draggable data of the dropped item), dndIndex (new item's index in list),
 *                                                   dndListId (tnk-dnd-draggable data of the this item - the item dropped into).
 *
 * - tnk-dnd-get-marker         An optional callback that should return an HTML (string) of a marker that's placed between draggable items when
 *                              dragging in between them. If not supplied, a default marker is placed: '<li class="tnkDndMarker"></li>'.
 *                              Use this to set a custom marker (it's recommended to to use an li element).
 *                              Parameters supplied: none.
 *
 *** CSS classes:
 * - tnkDndDraggedItem          This class will be added to the item that's being currently dragged if the drag operation starts.
 *                              Popular things to do include adding some rotation to the item (transform-origin is set for you by the infrastructure)
 *                              and adding some opacity.
 *
 * - tnkDndDraggingOver         This class will be added to the element currently dragged over (if exists). Use this to style an
 *                              element when it's dragged over.
 *
 * - tnkDndDragoverList         This class will be added to the list currently dragged over (if exists).
 *
 * - tnkDndMarker               This class will be added to the marker placed between draggable items when dragging in between them.
 *                              Use this to give your dnd markers their unique style.
 */
function tnkDndDraggable($parse, $timeout, $document, $window, utils) {
    return {
        restrict: 'A',
        link(scope, element, attrs) {
            const moveStartThreshold = 100;
            const draggingOverItemClass = 'tnkDndDraggingOver';
            const draggingOverListClass = 'tnkDndDragoverList';
            const draggedItemClass = 'tnkDndDraggedItem';

            const draggedDomElement = element[0];

            let originalTargetDomElement = null; // The original drag target element.
            let originalListDomElement = null; // The original list in which the dragged items resides.
            let list = null; // The current list we're hovering over.
            let listDomElement = null;
            let relativeContainerX = 0;
            let relativeContainerY = 0;
            let draggedElementOffsetX = 0;
            let draggedElementOffsetY = 0;
            let draggedElementOriginalWidth = 0;
            let draggedElementTransformOrigin = '50% 50% 0';
            let marker = null; // a marker that's placed between draggable items when dragging (jqLite element).
            let markerDomElement = null;
            let placeholder = null; // A placeholder for the item that's being dragged. It is left in the item's old position until the drop.
            let mouseDownTimePoint = null;
            let firstMove = true;

            const isIE = utils.isIE(); // We need to know in some cases if this is IE.

            /**
             * Since this handler is called upon every click on the draggable item, this function should
             * remain LIGHTWEIGHT and never do anything that will slow down its performance!!!
             * Notice that it mostly save parameters or runs light calculations.
             */
            element.on('mousedown', function (event) {
                if (isTargetUndraggable(event.target)) {
                    return;
                }

                if (attrs.tnkDndDisabled && attrs.tnkDndDisabled !== 'false') {
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                    return false;
                }

                // If the target element (the element that was clicked) has this attribute set to true, the drag won't happen.
                // This attribute is great for children elements which their father is draggable but you dont want them (the children) to initiate the dragging process.
                if (
                    event.target.attributes &&
                    event.target.attributes['tnk-dnd-prevent-default-drag'] &&
                    event.target.attributes['tnk-dnd-prevent-default-drag'].value === 'true'
                ) {
                    return false;
                }

                // Only do something if the left mouse button was clicked.
                // and the target is not from type text or is textarea (allow selecting text in inputs).
                if (event.which === 1 && event.target.type !== 'text' && event.target.tagName !== 'TEXTAREA') {
                    mouseDownTimePoint = Date.now();

                    // If a move will happen, it will be the first one.
                    firstMove = true;

                    // Calculate the offset of the user's mouse to where he clicked on the object,
                    // so the dragging will be from that point exactly.
                    const itemRect = draggedDomElement.getBoundingClientRect();
                    draggedElementOffsetX = event.pageX - itemRect.left;
                    draggedElementOffsetY = event.pageY - itemRect.top;
                    draggedElementOriginalWidth = itemRect.width;

                    // Save the original drag target so we can check later who it was.
                    originalTargetDomElement = event.target;

                    // Register to the document's mouse move.
                    $document.on('mousemove', onMouseMove);

                    // Register to the list item's mouse up event so we can stop the dragging.
                    $window.addEventListener('mouseup', onMouseUp);
                    // Register to the window's blur event so when the user changes app we can handle it.
                    $window.addEventListener('blur', onWindowBlur);

                    // Prevent default drag when dragging an image (so it won't get separately dragged - happens on firefox).
                    if (event.target.tagName && event.target.tagName.toLowerCase() === 'img' && event.preventDefault) {
                        event.preventDefault();
                    }
                }
            });

            function onMouseMove(event) {
                // Prevent other move events from happen. This solves a problem where dragging an inner item would also drag the parent.
                // The checks are needed for IE, it doesn't always have those functions.
                if (event.stopImmediatePropagation) {
                    event.stopImmediatePropagation();
                }
                if (event.stopPropagation) {
                    event.stopPropagation();
                }

                // Prevent the text from getting marked while dragging the mouse.
                if (event.preventDefault) {
                    event.preventDefault();
                } // The normal way - for chrome.
                event.returnValue = false; // For safari.
                if (document.selection) {
                    // The ugly way - for firefox.
                    document.selection.empty();
                } else {
                    window.getSelection().removeAllRanges();
                }

                // We only start moving after the threshold time has passed.
                const currentTime = Date.now();
                if (currentTime - mouseDownTimePoint < moveStartThreshold) {
                    return false;
                }

                // Get the current list we're hovering over. It might be null if we're hovering over another zone or we're out of the window.
                const newList = findListParent(event.target);
                if (newList) {
                    // Before saving the new list as the current list, if it's a different list - remove the class marker from the previous one.
                    if (newList !== listDomElement && list && list.hasClass(draggingOverListClass)) {
                        list.removeClass(draggingOverListClass);
                    }

                    // Only replace our previously saved list if we found a new one.
                    listDomElement = newList;
                    list = angular.element(listDomElement);
                }

                // If the list element is null, this means we never a list we are dragging inside (the event target was never a dnd list).
                // We can't go further cause this is basic info.
                // firstMove will only be true if this is not null. If we return here, first move never had happened (and will happen as soon as we find a list).
                if (!listDomElement) {
                    return false;
                }

                // There are things we only do on first move - initializing.
                if (firstMove) {
                    firstMove = false;

                    // Call the drag-start callback with $timeout, so it causes a digest (if the user wants anything to update).
                    $timeout(function () {
                        invokeCallback(element.attr('tnk-dnd-dragstart'), event, attrs.tnkDndDraggable);
                    });

                    // Mark the dragged item with the needed attributes.
                    draggedDomElement.style.position = 'fixed';
                    draggedDomElement.style.zIndex = 100_000;
                    draggedDomElement.style.width = `${draggedElementOriginalWidth}px`;
                    // This is needed so that the dragged item is not considered a target of the mouse move event.
                    draggedDomElement.style.pointerEvents = 'none'; // Not supported in IE < 11!
                    // Add transform origin so rotate styles are spun across the correct axis point
                    draggedElementTransformOrigin = `${draggedElementOffsetX}px ${draggedElementOffsetY}px`;
                    setElementTransformOrigin(draggedDomElement, draggedElementTransformOrigin);
                    // Mark the dragged item with a class.
                    element.addClass(draggedItemClass);

                    // Get the container we are dragging related to (if supplied), so we can fix our position relatively to it.
                    // In IE, fixed position is always calculated according to the whole window, so this is no needed.
                    if (!isIE && attrs.tnkDndRelativeTo && attrs.tnkDndRelativeTo.length) {
                        const relativeContainerDomElement = document.getElementById(attrs.tnkDndRelativeTo);
                        if (relativeContainerDomElement) {
                            // Set the relative left and top for future position calculations.
                            const relativeRect = relativeContainerDomElement.getBoundingClientRect();
                            relativeContainerX = relativeRect.left;
                            relativeContainerY = relativeRect.top;
                        }
                    }

                    // Get the original list in which the dragged item resides.
                    originalListDomElement = findListParent(draggedDomElement);

                    // Get the marker from the list so we can use it while moving.
                    marker = getMarker(list);
                    markerDomElement = marker[0];

                    // When starting to drag, put a marker right away for the user.
                    // This ensures we never have a situation where there's no marker/dragoverClass in the list.
                    const domContainer = draggedDomElement.parentNode || originalListDomElement;
                    draggedDomElement.before(markerDomElement);

                    // If supplied with a function, leave a placeholder where the dragged item once was.
                    if (attrs.tnkDndGetPlaceholder) {
                        const callbackPlaceholder = invokeCallback(
                            attrs.tnkDndGetPlaceholder,
                            event,
                            attrs.tnkDndDraggable,
                        );
                        if (callbackPlaceholder) {
                            placeholder = angular.element(callbackPlaceholder);
                            // Mark the placeholder so we can identify it later.
                            placeholder.attr('tnk-dnd-placeholder', true);
                            domContainer.insertBefore(placeholder[0], draggedDomElement);
                        }
                    }
                }

                // Move the dragged element to the mouse.
                moveDraggedElementTo(draggedDomElement, event.pageX, event.pageY);

                // Mark the list (the current one - it might change while dragging over different lists: inner/outer lists, etc.) with the drag over class.
                if (!list.hasClass(draggingOverListClass)) {
                    list.addClass(draggingOverListClass);
                }

                // If the immediate event target is not the list itself, we should find the list we are dragging over.
                // If the list has the tnk-dnd-list-full-dropzone flag, we should skip here and continue to its following specific treatment.
                if (event.target !== listDomElement && !list.attr('tnk-dnd-list-full-dropzone')) {
                    // Try to find the list node directly below the cursor.
                    const listChildDomElement = findListChildElement(event.target);

                    if (listChildDomElement && listChildDomElement !== markerDomElement) {
                        const listChild = angular.element(listChildDomElement);
                        const rect = listChildDomElement.getBoundingClientRect();
                        let upDownFactor = 0.25;
                        if (listChild.attr('tnk-dnd-no-dragover')) {
                            // Setting the factor to 0.5 will make it impossible for the item to be considered as dragged over.
                            upDownFactor = 0.5;
                        }

                        const isUp = event.clientY <= rect.top + rect.height * upDownFactor;
                        const isDown = event.clientY > rect.top + rect.height * (1 - upDownFactor);
                        if (isUp) {
                            if (markerDomElement.nextSibling !== listChildDomElement) {
                                listChildDomElement.before(markerDomElement);
                                unmarkDraggedOverElements(null);
                            }
                        } else if (isDown) {
                            if (markerDomElement.previousSibling !== listChildDomElement) {
                                listDomElement.insertBefore(markerDomElement, listChildDomElement.nextSibling);
                                unmarkDraggedOverElements(null);
                            }
                        } else {
                            // If we are not at the top or bottom, we are in the middle of the item.
                            // We should mark the element with the proper class (only if it has not been marked already).
                            if (!listChild.hasClass(draggingOverItemClass)) {
                                unmarkDraggedOverElements(listChildDomElement);

                                // Only remove if this is not the current element we are dragging over!
                                listChild.addClass(draggingOverItemClass);

                                // Remove the marker.
                                if (markerDomElement.parentNode) {
                                    marker.remove();
                                }
                            }
                        }
                    }
                }

                // If this flag is present, we don't care if we are pointing at the list or its children.
                if (list.attr('tnk-dnd-list-full-dropzone')) {
                    // Try to find the list node directly below the cursor.
                    const listChildDomElement = findListChildElement(event.target);

                    // If this flag is present, we should not try to calculate if the marker should be placed above
                    // or under the child element just simply inject it.
                    if (listChildDomElement) {
                        // If there's some child element, only insert if we didn't already insert before.
                        if (
                            listChildDomElement !== markerDomElement &&
                            markerDomElement.nextSibling !== listChildDomElement
                        ) {
                            // We insert after the child element in this case.
                            listChildDomElement.before(markerDomElement);
                            unmarkDraggedOverElements(null);
                        }
                    } else {
                        // No children, just insert.
                        listDomElement.append(markerDomElement);
                        unmarkDraggedOverElements(null);
                    }
                }

                // To make sure that dragging doesn't select the text.
                return false;
            }

            function onMouseUp(event) {
                // Un-register our listeners now that we're done.
                unregisterListeners();

                // Only do our logic if a first move has happened (which means we are not in first move).
                if (!firstMove) {
                    // Now that we are done dragging:
                    if (event.preventDefault) {
                        event.preventDefault();
                    }
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }

                    const index = getMarkerIndex();
                    if (index >= 0) {
                        // Marker found - meaning we are dropping between items. We should call the drop function of the list.
                        invokeCallback(
                            list.attr('tnk-dnd-drop'),
                            event,
                            attrs.tnkDndDraggable,
                            index,
                            list.attr('tnk-dnd-list-id'),
                        );
                    } else {
                        // Marker not found - meaning we are dropping into an item. We should call the dragged over item's drop-into function.
                        const draggingOver = getElementDraggingOver();
                        // Only do something if we managed to find the dragged over item.
                        if (draggingOver) {
                            const draggedOverData = draggingOver.attr('tnk-dnd-draggable');
                            draggingOver.removeClass(draggingOverItemClass);
                            invokeCallback(
                                draggingOver.attr('tnk-dnd-drop-into'),
                                event,
                                attrs.tnkDndDraggable,
                                0,
                                draggedOverData,
                            );
                        }
                    }

                    invokeCallback(element.attr('tnk-dnd-moved'), event, attrs.tnkDndDraggable, index);
                    // Call the drag-end callback with $timeout, so it causes a digest (if the user wants anything to update).
                    $timeout(function () {
                        invokeCallback(element.attr('tnk-dnd-dragend'), event);
                    });

                    // Reset the dragged element positioning parameters.
                    relativeContainerX = 0;
                    relativeContainerY = 0;
                    draggedElementOffsetX = 0;
                    draggedElementOffsetY = 0;
                    draggedElementOriginalWidth = 0;
                    draggedElementTransformOrigin = '50% 50% 0'; // The default value.

                    // Reset the original drag target element.
                    originalTargetDomElement = null;

                    $timeout(function () {
                        // Remove the Marker.
                        marker.remove();

                        // Remove the drag-over class from the list.
                        unmarkDraggedOverLists();

                        // Reset the special dragged item flag.
                        draggedDomElement.style.position = 'static';
                        draggedDomElement.style.zIndex = 'auto';
                        draggedDomElement.style.top = 'auto';
                        draggedDomElement.style.left = 'auto';
                        draggedDomElement.style.width = 'auto';
                        setElementTransformOrigin(draggedDomElement, draggedElementTransformOrigin);
                        // This is needed so that the dragged item is not considered a target of the mouse move event.
                        draggedDomElement.style.pointerEvents = 'auto'; // Not supported in IE < 11!
                        // Remove the dragged item class from it.
                        element.removeClass(draggedItemClass);

                        // Remove the placeholder if supplied and inserted.
                        if (attrs.tnkDndGetPlaceholder && placeholder) {
                            placeholder.remove();
                            placeholder = null;
                        }
                    });
                }
            }

            function onWindowBlur(event) {
                onMouseUp(event);
            }

            function invokeCallback(func, event, data, index, listId) {
                return $parse(func)(scope, {
                    event,
                    dndData: data,
                    dndIndex: index,
                    dndListId: listId,
                });
            }

            function unregisterListeners() {
                // Un-register from the document's mouse move.
                $document.off('mousemove', onMouseMove);
                // Un-register from the list item's mouse up (this event).
                $window.removeEventListener('mouseup', onMouseUp);
                // Un-register from window's blur event.
                $window.removeEventListener('blur', onWindowBlur);
            }

            function getMarker(list) {
                let newMarker = null;

                // Call the lists getMarker callback if it exists.
                if (list.attr('tnk-dnd-get-marker')) {
                    newMarker = angular.element(invokeCallback(list.attr('tnk-dnd-get-marker')));
                }

                // If it doesn't exist, we set a default marker.
                if (!newMarker) {
                    newMarker = angular.element('<li class="tnkDndMarker"></li>');
                }

                return newMarker;
            }

            function getMarkerIndex() {
                if (markerDomElement.parentNode) {
                    // Find the index of the marker while only counting draggable items.
                    // This way the marker itself and the placeholder don't interfere with the count.
                    let index = -1;
                    if (listDomElement.children) {
                        for (let i = 0; i < listDomElement.children.length; i++) {
                            const childElement = listDomElement.children[i];
                            // Don't count items with the placeholder attribute.
                            if (!childElement.attributes || !childElement.attributes['tnk-dnd-placeholder']) {
                                index += 1;
                            }
                            // Stop when we find the marker.
                            if (childElement === markerDomElement) {
                                break;
                            }
                        }
                    }

                    return index;
                }

                // We didn't find the marker.
                return -1;
            }

            function getElementDraggingOver() {
                const markedDomElements = document.getElementsByClassName(draggingOverItemClass);
                if (markedDomElements && markedDomElements.length) {
                    return angular.element(markedDomElements[0]);
                }

                // We didn't find any element.
                return null;
            }

            function findListChildElement(dragOverDomElement) {
                while (dragOverDomElement.parentNode && dragOverDomElement.parentNode !== listDomElement) {
                    dragOverDomElement = dragOverDomElement.parentNode;
                }

                // If we found an element who's parent is the list AND this item is draggable, return it.
                if (dragOverDomElement.parentNode === listDomElement) {
                    return dragOverDomElement;
                }

                return null;
            }

            function findListParent(dragOverDomElement) {
                // If we got the parent list, return it immediately.
                if (
                    dragOverDomElement &&
                    dragOverDomElement.attributes['tnk-dnd-list'] &&
                    dragOverDomElement.attributes['tnk-dnd-list'].nodeValue !== 'false'
                ) {
                    return dragOverDomElement;
                }

                // Move the dragOverDomElement to be his parent node (if exists).
                dragOverDomElement = dragOverDomElement.parentNode;

                // Go over the dragOverDomElement's parents until we find the list parent.
                while (
                    dragOverDomElement &&
                    (!dragOverDomElement.attributes || !dragOverDomElement.attributes['tnk-dnd-list'])
                ) {
                    dragOverDomElement = dragOverDomElement.parentNode;
                }

                // If we found the list parent return it, otherwise return null.
                return dragOverDomElement &&
                    dragOverDomElement.attributes['tnk-dnd-list'] &&
                    dragOverDomElement.attributes['tnk-dnd-list'].nodeValue !== 'false'
                    ? dragOverDomElement
                    : null;
            }

            function isTargetUndraggable(target) {
                // Traverse the target's tree upwards until we find the 'tnk-dnd-undraggable' or we hit a draggable parent or null.
                while (target && (!target.attributes || !target.attributes['tnk-dnd-draggable'])) {
                    if (target.attributes && target.attributes['tnk-dnd-undraggable']) {
                        return true;
                    }
                    target = target.parentNode;
                }

                return false;
            }

            function moveDraggedElementTo(dragged, mouseX, mouseY) {
                dragged.style.left = `${mouseX - draggedElementOffsetX - relativeContainerX}px`;
                dragged.style.top = `${mouseY - draggedElementOffsetY - relativeContainerY}px`;
            }

            /**
             * Removes the draggingOverItemClass from all elements that have it.
             * @param draggingOverElement - the element we are currently dragging over (that the mouse is over now).
             */
            function unmarkDraggedOverElements(draggingOverElement) {
                // Remove the dragging-over class from all the list elements that have it.
                const markedElements = document.getElementsByClassName(draggingOverItemClass);

                for (const markedElement of markedElements) {
                    const childElement = angular.element(markedElement);
                    if (markedElement !== draggingOverElement && childElement.hasClass(draggingOverItemClass)) {
                        childElement.removeClass(draggingOverItemClass);
                    }
                }
            }

            /**
             * Finds all the elements marked with the draggingOverListClass and removes the class.
             * We currently mark all the lists we encounter with the draggingOverListClass class instead of marking
             * only the current list. So we also clear it from all lists.
             * We should apply this change and only have the active list marked when relevant to our use-cases.
             */
            function unmarkDraggedOverLists() {
                const markedDomElements = document.getElementsByClassName(draggingOverListClass);
                const domElementsToClean = [];

                // We create a copy of the array returned from domElementsToClean because it gets updated live if we remove the class from the element.
                // So if we remove the class directly, the array will get smaller and we'll miss elements.
                for (const markedDomElement of markedDomElements) {
                    const listElement = angular.element(markedDomElement);
                    if (listElement.hasClass(draggingOverListClass)) {
                        domElementsToClean.push(listElement);
                    }
                }

                // Now just go over the elements in the array prepared and remove the class from them.
                for (const element_ of domElementsToClean) {
                    element_.removeClass(draggingOverListClass);
                }
            }

            /**
             * Marks the given DOM dragged element with the given transform origin style.
             * The function defines this property for cross-browser use.
             */
            function setElementTransformOrigin(dragged, transformOrigin) {
                dragged.style.transformOrigin = transformOrigin;
                dragged.style.webkitTransformOrigin = transformOrigin;
                dragged.style.MozTransformOrigin = transformOrigin;
                dragged.style.msTransformOrigin = transformOrigin;
            }
        },
    };
}

export default angular.module('tonkean.app').directive('tnkDndDraggable', tnkDndDraggable);
