function InsidedLoginCtrl(
    $scope,
    $state,
    $stateParams,
    $timeout,
    $location,
    tonkeanService,
    authenticationService,
) {
    const replaceOptions = { location: 'replace' };

    $scope.data = {
        authenticating: false,
    };

    $scope.init = function () {
        $scope.data.authenticating = true;
        if (!authenticationService || !authenticationService.currentUser) {
            authenticationService.setAuthenticationCompleteRedirectUrl();
            $scope.data.authenticating = false;
            $state.go('login', null, replaceOptions);
            return;
        } else {
            tonkeanService
                .getCommunityToken()
                .then((response) => {
                    location.href = response.redirectUrl;
                })
                .catch(() => {
                    // authentication failed, need to login first but keep the original page for after login to complete the operation
                    authenticationService.setAuthenticationCompleteRedirectUrl();
                    $scope.$emit('alert', { type: 'warning', msg: 'Could not authenticate. Please sign in.' });
                    $state.go('login', null, replaceOptions);
                })
                .finally(() => {
                    $scope.data.authenticating = false;
                });
        }
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('InsidedLoginCtrl', InsidedLoginCtrl);
