import { useAngularService } from 'angulareact';

import { useGetStateParams } from '@tonkean/angular-hooks';

/**
 * Lets you know what environment\version you are viewing based on the URL param
 * Make sure to add in reactToAngular root - $state.
 */
function useEnvironmentFlags() {
    const [env] = useGetStateParams('env');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const workflowVersion = workflowVersionManager.getCachedWorkflowVersion(env);

    let isDraft = !env?.length || env === 'DRAFT';
    let isPublished = env === 'PUBLISHED';

    if (!isPublished && !isDraft) {
        isPublished = workflowVersion?.workflowVersionType === 'PUBLISHED';
        isDraft = workflowVersion?.workflowVersionType === 'DRAFT';
    }
    const sequentialIdentifier = workflowVersion?.sequentialIdentifier;
    const subSequentialIdentifier = workflowVersion?.subSequentialIdentifier;
    return { isDraft, isPublished, sequentialIdentifier, subSequentialIdentifier };
}

export default useEnvironmentFlags;
