import type { Descendant, Text, Element as SlateElement } from 'slate';

import createFormulaElement from './createFormulaElement';
import createVariableElement from './createVariableElement';

function getFormulas(value: string) {
    return value.split(/<#(.*?)#>/g).map((part, index) => {
        // All odd indexes are the inner value of the formula
        const isFormula = index % 2 === 1;

        return { text: part, isFormula };
    });
}

export function getVariables(value: string) {
    return value.split(/{{(.*?)}}/).map((part, index) => {
        // All odd indexes are the inner value of the variable
        const isVariable = index % 2 === 1;

        return { text: part, isVariable };
    });
}

function convertStringToNodes(value: string): SlateElement[] {
    return value.split('\n').map((paragraph) => {
        return {
            type: 'paragraph',
            children: getFormulas(paragraph).flatMap((optionallyFormula): Descendant | Descendant[] => {
                // All odd indexes are the inner value of the formulas
                if (optionallyFormula.isFormula) {
                    return createFormulaElement(optionallyFormula.text);
                }

                return getVariables(optionallyFormula.text).map((optionallyVariable) => {
                    if (optionallyVariable.isVariable) {
                        return createVariableElement(optionallyVariable.text);
                    }

                    return { text: optionallyVariable.text } as Text;
                });
            }),
        };
    });
}

export default convertStringToNodes;
