import { useEffect, useState } from 'react';

import { useEscapeCallback } from '@tonkean/infrastructure';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

function useEditableText(onStopEditing: (shouldSave: boolean) => void, editing: boolean) {
    const [editingBox, setEditingBox] = useState<HTMLElement | null>();
    const onStopEditingConstantRefCallback = useConstantRefCallback(onStopEditing);
    useEffect(() => {
        if (editingBox && editing) {
            // Handle close on focus outside.
            const onFocusOut = (event: FocusEvent) => {
                const stillFocusedInsideEditingBox = editingBox.contains(event.relatedTarget as Element | null);
                if (!stillFocusedInsideEditingBox) {
                    onStopEditingConstantRefCallback(true);
                }
            };
            editingBox.addEventListener('focusout', onFocusOut);

            // Handle close on enter press when it's an input element.
            const onKeyDown = (event: KeyboardEvent) => {
                if (editingBox.tagName !== 'INPUT') {
                    return;
                }

                switch (event.key) {
                    case 'Enter':
                        onStopEditingConstantRefCallback(true);
                        event.preventDefault();
                        break;
                }
            };
            editingBox.addEventListener('keydown', onKeyDown);

            return () => {
                editingBox.removeEventListener('focusout', onFocusOut);
                editingBox.removeEventListener('keydown', onKeyDown);
            };
        }
    }, [editing, editingBox, onStopEditingConstantRefCallback]);

    // Handle escape press
    useEscapeCallback(() => {
        onStopEditing(false);
    }, editing);

    return setEditingBox;
}

export default useEditableText;
