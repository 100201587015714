import { lateConstructController } from '@tonkean/angular-components';
import { countQueryFilters } from '@tonkean/tonkean-utils';
import { getDefaultTonkeanQuery, getFormulaSpecialFieldIdToDefinitionMap } from '@tonkean/tonkean-utils';

/* @ngInject */
function IntegrationAggregateFieldCtrl(
    $scope,
    tonkeanService,
    integrations,
    tonkeanUtils,
    utils,
    projectManager,
    requestThrottler,
    customFieldsManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        // Component bindings
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        isWorkflowVersionPublished: $scope.wvm.isPublishedVersion(ctrl.workflowVersionId),
        targetType: ctrl.targetType,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
        existingFieldDefinition: ctrl.existingFieldDefinition,
        fieldDefinitionName: ctrl.fieldDefinitionName,
        fieldDefinitionNameEdited: ctrl.fieldDefinitionNameEdited,
        showConditions: false,

        secondaryAggregations: [],
        anyNotHiddenSecondaryAggregations: false,

        formulaExpression: null,
        validatingFormulaExpression: false,
        validationResult: null,

        isWorkerMode: ctrl.isWorkerMode,

        // Consts
        apiNameToAggregationTypeMap: {},
        singleAggregationTypes: [],
        reduceFunctions: [
            {
                id: 'Concat',
                apiName: 'Concat',
                displayName: 'Concat',
            },
        ],
        groupByFunctions: [
            {
                id: 'Count',
                apiName: 'Count',
                displayName: 'Count',
            },
            {
                id: 'Sum',
                apiName: 'Sum',
                displayName: 'Sum',
            },
            {
                id: 'Avg',
                apiName: 'Avg',
                displayName: 'Average',
            },
        ],
        aggregationTypes: [
            {
                id: 'Count',
                apiName: 'Count',
                displayName: 'Count',
            },
            {
                id: 'Avg',
                apiName: 'Avg',
                displayName: 'Avg',
                requiresField: true,
            },
            {
                id: 'Sum',
                apiName: 'Sum',
                displayName: 'Sum',
                requiresField: true,
            },
            {
                id: 'Max',
                apiName: 'Max',
                displayName: 'Max',
                requiresField: true,
            },
            {
                id: 'Min',
                apiName: 'Min',
                displayName: 'Min',
                requiresField: true,
            },
            {
                id: 'Terms',
                apiName: 'Terms',
                displayName: 'Grouped by',
                requiresField: true,
            },
            {
                id: 'First',
                apiName: 'First',
                displayName: 'First',
                requiresField: true,
            },
            {
                id: 'Reduce',
                apiName: 'Reduce',
                displayName: 'Reduce',
                requiresField: true,
            },
            {
                id: 'Percentage',
                apiName: 'Percentage',
                displayName: 'Percentage',
                connectionLabel: 'out of',
                requiresSecondaryAggregation: true,
                requiresSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsNames: [null, 'All'],
            },
            {
                id: 'Multiplication',
                apiName: 'Multiplication',
                displayName: 'Multiplication',
                connectionLabel: 'and',
                requiresSecondaryAggregation: true,
                requiresSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsNames: [null, 'All'],
            },
            {
                id: 'Division',
                apiName: 'Division',
                displayName: 'Division',
                connectionLabel: 'by',
                requiresSecondaryAggregation: true,
                requiresSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsNames: [null, 'All'],
            },
            {
                id: 'Subtraction',
                apiName: 'Subtraction',
                displayName: 'Diff',
                connectionLabel: 'and',
                requiresSecondaryAggregation: true,
                requiresSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsNames: [null, 'All'],
            },
            {
                id: 'Formula',
                apiName: 'Formula',
                displayName: 'Formula',
                requiresSecondaryAggregation: true,
                requiresUnlimitedSecondaryAggregationsCount: true,
                defaultSecondaryAggregationsCount: 2,
                defaultSecondaryAggregationsNames: ['A', 'B'],
                defaultFormulaExpression: '{A} + {B}',
            },
        ],
        matchTypeDisplayNameToApiNameMap: {
            Match: 'EXACT_MATCH',
            Contain: 'CONTAINS',
        },
        matchTypeApiNameToDisplayNameMap: {
            EXACT_MATCH: 'Match',
            CONTAINS: 'Contain',
        },
        allEntitiesSupportedIntegrations: integrations.getAllEntitiesSupportedIntegrations(),

        // Control object
        customFiltersControl: {},

        // Definition variables
        aggregationType: null,
        matchType: 'Match',
        aggregatedEntity: ctrl.aggregatedEntity,
        aggregatedField: null,
        matchTrackField: null,
        reduceFunction: null,
        groupByFunction: null,
        concatenationSeparator: '',

        fieldToMatch: null,
        orderByField: null,
        orderBySelectedOrder: null,
        orderByFieldType: null,
        orderByFieldTypes: [
            {
                apiName: 'String',
                displayName: 'Textual',
            },
            {
                apiName: 'Number',
                displayName: 'Number',
            },
            {
                apiName: 'Date',
                displayName: 'Date',
            },
        ],

        // Order types
        orderTypes: [
            {
                apiName: 'ASC',
                displayName: 'Ascending',
            },
            {
                apiName: 'DESC',
                displayName: 'Descending',
            },
        ],

        // Available fields
        allAvailableFields: [],
        loadingAllAvailableFields: false,
        errorLoadingAllAvailableFields: null,

        // Entities fields
        loadingAllEntitiesFields: false,
        errorLoadingAllEntitiesFields: null,
        notAllowedConditionsSet: {
            Contains: true,
            NotContains: true,
        },
        useExpressionsForValue: false,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        $scope.data.apiNameToAggregationTypeMap = utils.createMapFromArray($scope.data.aggregationTypes, 'apiName');
        $scope.data.apiNameToReduceFunctionMap = utils.createMapFromArray($scope.data.reduceFunctions, 'apiName');
        $scope.data.apiNameToGroupByFunctionMap = utils.createMapFromArray($scope.data.groupByFunctions, 'apiName');
        $scope.data.singleAggregationTypes = $scope.data.aggregationTypes.filter(
            (type) => !type.requiresSecondaryAggregation,
        );
        $scope.data.orderBySelectedOrder = $scope.data.orderTypes[0];
        $scope.data.orderByFieldType = $scope.data.orderByFieldTypes[0];

        loadAllEntitiesFields();
        initializeEditMode();

        // It is important to fire the event to get the containing controller to have the updated data.
        definitionChanged();
        $scope.data.useExpressionsForValue = $scope.data.targetType === 'COLUMN';
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function (changes) {
        if (changes.projectIntegration) {
            $scope.data.projectIntegration = ctrl.projectIntegration;
        }
        if (changes.existingFieldDefinition) {
            $scope.data.existingFieldDefinition = ctrl.existingFieldDefinition;
        }
        if (changes.targetType) {
            $scope.data.targetType = ctrl.targetType;
        }
        if (changes.fieldDefinitionName) {
            $scope.data.fieldDefinitionName = ctrl.fieldDefinitionName;
        }
        if (changes.fieldDefinitionNameEdited) {
            $scope.data.fieldDefinitionNameEdited = ctrl.fieldDefinitionNameEdited;
        }
        if (changes.aggregatedEntity) {
            $scope.data.aggregatedEntity = ctrl.aggregatedEntity;
        }

        // Save for last so all we be taken into account in initializeEditMode
        if (changes.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
            $scope.data.isWorkflowVersionPublished = $scope.wvm.isPublishedVersion(ctrl.workflowVersionId);

            if (!changes.workflowVersionId.isFirstChange()) {
                initializeEditMode();
            }
        }
    };

    /**
     * Occurs once a reduce function is selected.
     */
    $scope.reduceFunctionSelected = function () {
        definitionChanged(true);
    };

    /**
     * Occurs once a groupBy function is selected.
     */
    $scope.groupByFunctionSelected = function () {
        definitionChanged(true);
    };

    /**
     * Selecting a field in the search input box for group by aggregate field.
     */
    $scope.selectGroupByAggregateField = function (aggregatedField, dontSaveChanges) {
        const previousAggregatedField = $scope.data.groupByAggregateField;

        $scope.data.groupByAggregateField = aggregatedField;

        definitionChanged(previousAggregatedField !== aggregatedField && !dontSaveChanges);
    };

    /**
     * Occurs once an aggregation type is selected.
     */
    $scope.aggregationTypeSelected = function () {
        $scope.data.secondaryAggregations = [];

        if ($scope.data.aggregationType.id === 'Formula') {
            $scope.data.formulaExpression = $scope.data.aggregationType.defaultFormulaExpression;
        }

        if ($scope.data.aggregationType.id === 'Reduce' || $scope.data.aggregationType.id === 'First') {
            $scope.onSortByFieldChanged(undefined);
        }

        if ($scope.data.aggregationType.requiresSecondaryAggregation) {
            for (let i = 0; i < $scope.data.aggregationType.defaultSecondaryAggregationsCount; i++) {
                $scope.data.secondaryAggregations.push({
                    fieldTitle: $scope.data.aggregationType.defaultSecondaryAggregationsNames[i],
                    aggregationType: $scope.data.apiNameToAggregationTypeMap['Count'],
                    aggregatedField: null,
                    showConditions: false,
                    matchType: $scope.data.matchType,
                    aggregatedEntity: $scope.data.aggregatedEntity,
                    matchTrackField: $scope.data.matchTrackField,
                    trackFieldToMatchWith: $scope.data.trackFieldToMatchWith,
                    customFiltersControl: {},
                    hidden: false,
                });
            }
        }

        definitionChanged(true);
    };

    /**
     * Occurs once the aggregation type of the secondary aggregation is selected.
     */
    $scope.onSecondaryAggregationTypeSelected = function () {
        definitionChanged(true);
    };

    /**
     * Occurs once a field title for a secondary aggregation is changed.
     */
    $scope.secondaryAggregationFieldTitleChanged = function () {
        definitionChanged(true, true);
    };

    /**
     * Displays the conditions of the secondary aggregation.
     */
    $scope.displaySecondaryAggregationConditions = function (secondaryAggregation) {
        secondaryAggregation.showConditions = true;
    };

    /**
     * Occurs once secondary aggregation aggregated field is selected.
     */
    $scope.selectSecondaryAggregationAggregatedField = function (
        aggregatedField,
        secondaryAggregation,
        dontSaveChanges,
    ) {
        secondaryAggregation.aggregatedField = aggregatedField;
        definitionChanged(!dontSaveChanges);
    };

    /**
     * Occurs once the custom filters of the secondary aggregation is changed.
     */
    $scope.onSecondaryAggregationCustomFiltersChanged = function () {
        definitionChanged(true);
    };

    /**
     * Occurs once the custom filters are changed.
     */
    $scope.onCustomFiltersChanged = function (shouldNotSaveLogic) {
        definitionChanged(!shouldNotSaveLogic);
    };

    /**
     * Occurs on the change of the concatenation separator.
     */
    $scope.onConcatenationSeparatorChanged = function () {
        definitionChanged(true);
    };

    /**
     * Occurs once the match type selection is changed.
     */
    $scope.onMatchTypeChanged = function () {
        definitionChanged(true);
    };

    /**
     * Occurs once a formula expression is changed.
     */
    $scope.formulaExpressionChanged = function (formulaExpression) {
        if (formulaExpression) {
            $scope.data.validatingFormulaExpression = true;
            $scope.data.errorValidatingFormulaExpression = null;

            // Validating in the server.
            requestThrottler.do(
                'validateFormulaExpression',
                200,
                () =>
                    tonkeanService.validateFormulaExpression(
                        projectManager.project.id,
                        formulaExpression,
                        null,
                        null,
                        true,
                        null,
                        true,
                        $scope.data.workflowVersionId,
                    ),
                (data) => {
                    $scope.data.validationResult = data;
                    analyzeFormulaExpressionParticipatingDefinitions(formulaExpression);
                    definitionChanged(true);
                },
                () => {
                    $scope.data.validationResult = {
                        errorMessage: 'There was an error trying to validate expression.',
                    };
                },
                () => {
                    $scope.data.validatingFormulaExpression = false;
                },
            );
        } else {
            for (let i = 0; i < $scope.data.secondaryAggregations.length; i++) {
                $scope.data.secondaryAggregations[i].hidden = true;
            }

            $scope.data.anyNotHiddenSecondaryAggregations = false;
            definitionChanged(true);
        }
    };

    /**
     * Occurs once an order by field is selected.
     */
    $scope.onOrderByFieldSelected = function (selectedField, dontSaveChanges) {
        $scope.data.orderByField = selectedField;
        definitionChanged(!dontSaveChanges);
    };

    /**
     * Occurs once the order of the order by is selected.
     */
    $scope.onOrderBySelectedOrderSelected = function (selectedOrder) {
        $scope.data.orderBySelectedOrder = selectedOrder;
        definitionChanged(true);
    };

    /**
     * Occurs once the order of the order by is selected.
     */
    $scope.onOrderByFieldTypeSelectedOrderSelected = function (selectedOrderType) {
        $scope.data.orderByFieldType = selectedOrderType;
        definitionChanged(true);
    };

    /**
     * If integration supports all entities, we will retrieve fields using an API that searches for fields regardless of the external type.
     */
    $scope.getAllEntitiesFieldsFromServer = function (queryString) {
        $scope.data.loadingAllEntitiesFields = true;
        $scope.data.errorLoadingAllEntitiesFields = null;

        return tonkeanService
            .getEntityAvailableExternalFields($scope.data.projectIntegration.id, queryString, ['number'], 100)
            .then((data) => {
                let allResults = [];

                for (const externalType in data) {
                    if (data.hasOwnProperty(externalType)) {
                        allResults = allResults.concat(data[externalType]);
                    }
                }

                if (allResults && allResults.length === 0) {
                    $scope.data.noFieldResults = true;
                }

                return allResults;
            })
            .catch(() => {
                $scope.data.errorLoadingAllEntitiesFields = 'There was an error trying to fetch fields.';
            })
            .finally(() => {
                $scope.data.loadingAllEntitiesFields = false;
            });
    };

    /**
     * Selecting a field in the search input box.
     */
    $scope.selectAggregatedField = function (aggregatedField, dontSaveChanges) {
        const previousAggregatedField = $scope.data.aggregatedField;

        $scope.data.aggregatedField = aggregatedField;

        definitionChanged(previousAggregatedField !== aggregatedField && !dontSaveChanges);
    };

    $scope.onSortByFieldChanged = function (sortByFieldObject, shouldSave = true) {
        $scope.data.sortByFieldObject = sortByFieldObject;
        definitionChanged(shouldSave);
    };

    /**
     * Selects an entity to filter upon for aggregate count field.
     */
    $scope.selectAggregateEntityOption = function (selectedEntity, isInit) {
        $scope.data.aggregatedEntity = selectedEntity;
        loadAllEntitiesFields();

        if (!isInit) {
            $scope.data.aggregatedField = null;
            resetMatchTrackField();
        }

        definitionChanged(!isInit);
    };

    /**
     * Occurs once a match item title to field is chosen.
     */
    $scope.onFieldOptionSelected = function (selectedField, dontSaveChanges) {
        $scope.data.matchTrackField = selectedField;
        definitionChanged(!dontSaveChanges);
    };

    $scope.onTrackMatchFieldSelected = function (selectedField, dontSaveChanges) {
        $scope.data.trackFieldToMatchWith = {
            fieldDefinitionId: selectedField.isSpecialField ? null : selectedField.id,
            specialFieldId: selectedField.isSpecialField ? selectedField.id : null,
        };

        $scope.data.fieldToMatch = selectedField;
        definitionChanged(!dontSaveChanges);
    };

    /**
     * Displays the conditions section.
     */
    $scope.displayConditions = function () {
        $scope.data.showConditions = true;
    };

    /**
     * Calculates the data.anyNotHiddenSecondaryAggregations property.
     */
    function evaluateAnyNotHiddenSecondaryAggregations() {
        $scope.data.anyNotHiddenSecondaryAggregations = utils.anyMatch(
            $scope.data.secondaryAggregations,
            (secondaryAggregation) => !secondaryAggregation.hidden,
        );
    }

    /**
     * Initializes the edit mode of the component.
     */
    function initializeEditMode() {
        if (!$scope.data.createMode && $scope.data.existingFieldDefinition.definition) {
            $scope.data.aggregationType =
                $scope.data.apiNameToAggregationTypeMap[$scope.data.existingFieldDefinition.definition.aggregationType];
            $scope.data.matchType =
                $scope.data.matchTypeApiNameToDisplayNameMap[$scope.data.existingFieldDefinition.definition.matchType];
            $scope.data.aggregatedEntity =
                $scope.data.existingFieldDefinition.definition.entityMetadata ||
                $scope.data.existingFieldDefinition.definition.entity;
            $scope.data.aggregatedField = $scope.data.existingFieldDefinition.definition.aggregatedField;
            $scope.data.matchTrackField = $scope.data.existingFieldDefinition.definition.matchTrackField;
            $scope.data.orderByField = $scope.data.existingFieldDefinition.definition.orderByField;
            $scope.data.formulaExpression = $scope.data.existingFieldDefinition.definition.formulaExpression;
            $scope.data.reduceFunction =
                $scope.data.apiNameToReduceFunctionMap[
                    $scope.data.existingFieldDefinition.definition.reduceAggregationDefinition?.reduceFunction
                ];
            $scope.data.concatenationSeparator =
                $scope.data.existingFieldDefinition.definition.reduceAggregationDefinition?.reduceFunctionDefinition?.concatenationSeparator;

            $scope.data.groupByAggregateField =
                $scope.data.existingFieldDefinition?.definition?.groupByAggregationDefinition?.aggregateByField;
            $scope.data.groupByFunction =
                $scope.data.apiNameToGroupByFunctionMap[
                    $scope.data.existingFieldDefinition.definition.groupByAggregationDefinition?.groupByFunction ||
                        'Count'
                ];

            $scope.data.trackFieldToMatchWith = $scope.data.existingFieldDefinition.definition.trackFieldToMatchWith;

            const fieldDefinitionMap = utils.createMapFromArray(
                customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                'id',
            );
            if ($scope.data.existingFieldDefinition?.definition?.trackFieldToMatchWith?.fieldDefinitionId) {
                $scope.data.fieldToMatch =
                    fieldDefinitionMap[
                        $scope.data.existingFieldDefinition.definition.trackFieldToMatchWith.fieldDefinitionId
                    ];
            } else {
                $scope.data.fieldToMatch =
                    getFormulaSpecialFieldIdToDefinitionMap()[
                        $scope.data.existingFieldDefinition.definition.trackFieldToMatchWith.specialFieldId
                    ];
            }

            const apiNameToOrderObjectMap = utils.createMapFromArray($scope.data.orderTypes, 'apiName');
            $scope.data.orderBySelectedOrder =
                apiNameToOrderObjectMap[$scope.data.existingFieldDefinition.definition.orderByOrder];

            $scope.data.selectedOrderByFieldType =
                apiNameToOrderObjectMap[$scope.data.existingFieldDefinition.definition.selectedOrderByFieldType];

            const filtersCount = countQueryFilters($scope.data.existingFieldDefinition.definition.query);
            $scope.data.showConditions = filtersCount > 0;

            if ($scope.data.aggregationType.requiresSecondaryAggregation) {
                $scope.data.secondaryAggregations = [];

                for (const key in $scope.data.existingFieldDefinition.definition.secondaryAggregationsMap) {
                    if ($scope.data.existingFieldDefinition.definition.secondaryAggregationsMap.hasOwnProperty(key)) {
                        const secondaryAggregationForUI =
                            $scope.data.existingFieldDefinition.definition.secondaryAggregationsMap[key];

                        secondaryAggregationForUI.customFiltersControl = {};
                        secondaryAggregationForUI.hidden = false;
                        secondaryAggregationForUI.aggregationType =
                            $scope.data.apiNameToAggregationTypeMap[secondaryAggregationForUI.aggregationType];

                        const filtersCount = countQueryFilters(secondaryAggregationForUI.query);
                        secondaryAggregationForUI.showConditions = filtersCount > 0;

                        $scope.data.secondaryAggregations.push(secondaryAggregationForUI);
                    }
                }

                evaluateAnyNotHiddenSecondaryAggregations();
            }
        }

        if (!$scope.data.fieldToMatch) {
            $scope.data.fieldToMatch = getFormulaSpecialFieldIdToDefinitionMap()['TNK_TITLE'];
        }
    }

    /**
     * Analyzes the formula expression for participating definitions inside of it.
     */
    function analyzeFormulaExpressionParticipatingDefinitions(formulaExpression) {
        if (!formulaExpression) {
            return;
        }

        const expressionVariables = tonkeanUtils.getFormulaExpressionUsedVariables(formulaExpression);

        // A set of all used variables in the formula expression.
        const expressionVariablesSet = {};
        for (const expressionVariable of expressionVariables) {
            expressionVariablesSet[expressionVariable] = true;
        }

        // A map of all defined variables (so we won't delete a variable if you're just messing around).
        const existingVariableDefinitionsMap = {};
        for (let i = 0; i < $scope.data.secondaryAggregations.length; i++) {
            existingVariableDefinitionsMap[$scope.data.secondaryAggregations[i].fieldTitle] =
                $scope.data.secondaryAggregations[i];
        }

        // Adding any variables that do not already exist, and making not hidden those who do exist.
        for (const variable of expressionVariables) {
            if (!existingVariableDefinitionsMap[variable]) {
                $scope.data.secondaryAggregations.push({
                    fieldTitle: variable,
                    aggregationType: $scope.data.apiNameToAggregationTypeMap['Count'],
                    aggregatedField: null,
                    showConditions: false,
                    matchType: $scope.data.matchType,
                    aggregatedEntity: $scope.data.aggregatedEntity,
                    matchTrackField: $scope.data.matchTrackField,
                    trackFieldToMatchWith: $scope.data.trackFieldToMatchWith,
                    customFiltersControl: {},
                    hidden: false,
                });
            } else {
                existingVariableDefinitionsMap[variable].hidden = false;
            }
        }

        // Hiding exisitng variables definition that no longer participate in the formula expression.
        for (let i = 0; i < $scope.data.secondaryAggregations.length; i++) {
            const secondaryAggregation = $scope.data.secondaryAggregations[i];

            if (!expressionVariablesSet[secondaryAggregation.fieldTitle]) {
                secondaryAggregation.hidden = true;
            }
        }

        evaluateAnyNotHiddenSecondaryAggregations();
    }

    /**
     * Validates a definition of an AGGRGATE_QUERY field definition.
     */
    function validateDefinition(definitionJson) {
        if (!definitionJson || !definitionJson.entity || !definitionJson.aggregationType) {
            return false;
        }

        const aggregateTypeObject = $scope.data.apiNameToAggregationTypeMap[definitionJson.aggregationType];
        if (aggregateTypeObject.requiresField && !definitionJson.aggregatedField) {
            return false;
        }

        if (
            definitionJson.aggregationType === 'Reduce' &&
            (!definitionJson.reduceAggregationDefinition || !definitionJson.reduceAggregationDefinition.reduceFunction)
        ) {
            return false;
        }

        if (
            definitionJson.aggregationType === 'Terms' &&
            definitionJson.groupByAggregationDefinition &&
            definitionJson.groupByAggregationDefinition.groupByFunction !== 'Count' &&
            !definitionJson.groupByAggregationDefinition.aggregateByField
        ) {
            return false;
        }

        if (
            $scope.data.targetType === 'COLUMN' &&
            (!definitionJson.matchTrackField || !definitionJson.matchTrackField.name || !definitionJson.matchType)
        ) {
            return false;
        }

        if (
            definitionJson.timeRangeFilter &&
            definitionJson.timeRangeFilter.range !== 'NoRange' &&
            !definitionJson.timeRangeFilter.timeFieldName
        ) {
            return false;
        }

        if (aggregateTypeObject.requiresSecondaryAggregation && !definitionJson.secondaryAggregationsMap) {
            return false;
        }

        if (aggregateTypeObject.requiresSecondaryAggregation) {
            for (const key in definitionJson.secondaryAggregationsMap) {
                if (definitionJson.secondaryAggregationsMap.hasOwnProperty(key)) {
                    const secondaryAggregation = definitionJson.secondaryAggregationsMap[key];

                    // Ignoring hidden definitions.
                    if (secondaryAggregation.hidden) {
                        continue;
                    }

                    if (
                        !secondaryAggregation ||
                        !secondaryAggregation.entity ||
                        !secondaryAggregation.aggregationType
                    ) {
                        return false;
                    }

                    const secondaryAggregationTypeObject =
                        $scope.data.apiNameToAggregationTypeMap[secondaryAggregation.aggregationType];
                    if (secondaryAggregationTypeObject.requiresField && !secondaryAggregation.aggregatedField) {
                        return false;
                    }

                    if (
                        $scope.data.targetType === 'COLUMN' &&
                        (!secondaryAggregation.matchTrackField ||
                            !secondaryAggregation.matchTrackField.name ||
                            !secondaryAggregation.matchType)
                    ) {
                        return false;
                    }

                    if (
                        secondaryAggregation.timeRangeFilter &&
                        secondaryAggregation.timeRangeFilter.range !== 'NoRange' &&
                        !secondaryAggregation.timeRangeFilter.timeFieldName
                    ) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged(hadChanges = false, doNotReloadPreview = false) {
        // We must have an aggregation type to act.
        if (!$scope.data.aggregationType || !$scope.data.aggregatedEntity) {
            return;
        }

        const entity =
            $scope.data.aggregatedEntity && $scope.data.aggregatedEntity.pluralLabel
                ? $scope.data.aggregatedEntity.pluralLabel
                : $scope.data.aggregatedEntity;
        const matchTrackField = $scope.data.targetType === 'COLUMN' ? $scope.data.matchTrackField : null;
        const matchTypeDisplayName = $scope.data.matchTypeDisplayNameToApiNameMap[$scope.data.matchType];
        const fieldConfigurationSummaryTitle =
            entity && $scope.data.aggregationType.displayName
                ? `${entity} ${$scope.data.aggregationType.displayName}`
                : 'Aggregation';
        let fieldConfigurationSummarySubTitle =
            $scope.data.aggregationType && entity ? `${$scope.data.aggregationType.displayName}: ${entity}` : null;
        if (matchTrackField) {
            const fieldToMatch = $scope.data.fieldToMatch.label
                ? $scope.data.fieldToMatch.label
                : $scope.data.fieldToMatch.name;
            fieldConfigurationSummarySubTitle += `, Match "${fieldToMatch}" to "${matchTrackField.label}"`;
        }

        let definitionJson = {};

        definitionJson.entity = entity;
        definitionJson.aggregationType = $scope.data.aggregationType ? $scope.data.aggregationType.apiName : null;
        definitionJson.aggregatedField = $scope.data.aggregatedField;
        definitionJson.matchTrackField = matchTrackField;
        definitionJson.orderByField = $scope.data.orderByField;
        definitionJson.orderByOrder = $scope.data.orderBySelectedOrder.apiName;
        definitionJson.matchType = matchTypeDisplayName;
        definitionJson.formulaExpression = $scope.data.formulaExpression;
        definitionJson.trackFieldToMatchWith = $scope.data.trackFieldToMatchWith;
        if ($scope.data.reduceFunction) {
            definitionJson.reduceAggregationDefinition = {
                reduceFunction: $scope.data.reduceFunction.apiName,
                reduceFunctionDefinition: {
                    concatenationSeparator: $scope.data.concatenationSeparator || undefined,
                },
            };
        }

        if ($scope.data.sortByFieldObject) {
            definitionJson.orderByFieldConfiguration = {
                orderByField: $scope.data.sortByFieldObject.orderByField,
                orderByFieldType: $scope.data.sortByFieldObject.orderByFieldType.apiName,
                orderByOrder: $scope.data.sortByFieldObject.orderBySelectedOrder.apiName,
            };
        }

        let additionalDefinitionsToPreview = [];

        if ($scope.data.fieldToMatch.id !== 'TNK_TITLE') {
            additionalDefinitionsToPreview = [
                {
                    name: $scope.data.fieldToMatch.label || $scope.data.fieldToMatch.name,
                    definitionId: $scope.data.fieldToMatch.id,
                    fieldDefinitionTargetType: $scope.data.targetType,
                    validDefinition: true,
                    previewDefinitionType: $scope.data.fieldToMatch.isSpecialField
                        ? 'INITIATIVE_FIELD'
                        : 'EXISTING_FIELD_DEFINITION',
                },
            ];
        }

        if ($scope.data.aggregationType.requiresSecondaryAggregation) {
            definitionJson.secondaryAggregationsMap = {};

            for (let i = 0; i < $scope.data.secondaryAggregations.length; i++) {
                const secondaryAggregation = $scope.data.secondaryAggregations[i];

                // Ignoring hidden definitions.
                if (secondaryAggregation.hidden) {
                    continue;
                }

                let secondaryAggregationQuery = {
                    query: getDefaultTonkeanQuery(),
                };
                if (
                    secondaryAggregation.customFiltersControl &&
                    secondaryAggregation.customFiltersControl.createDefinitionFromCustomFilters
                ) {
                    secondaryAggregationQuery =
                        secondaryAggregation.customFiltersControl.createDefinitionFromCustomFilters();
                }

                secondaryAggregationQuery.fieldTitle = secondaryAggregation.fieldTitle;
                secondaryAggregationQuery.entity = entity;
                secondaryAggregationQuery.aggregationType = secondaryAggregation.aggregationType
                    ? secondaryAggregation.aggregationType.apiName
                    : null;
                secondaryAggregationQuery.aggregatedField = secondaryAggregation.aggregatedField;
                secondaryAggregationQuery.matchTrackField = matchTrackField;
                secondaryAggregationQuery.matchType = matchTypeDisplayName;
                secondaryAggregationQuery.trackFieldToMatchWith = secondaryAggregation.trackFieldToMatchWith;
                if (secondaryAggregation.reduceAggregationDefinition) {
                    secondaryAggregationQuery.reduceAggregationDefinition =
                        secondaryAggregation.reduceAggregationDefinition;
                }
                const secondaryAggregationIsValid = validateDefinition(secondaryAggregationQuery);

                additionalDefinitionsToPreview.push({
                    definitionId: i + 1, // Incrementing by 1 as 0 counts as false in JS.
                    name:
                        (secondaryAggregationQuery.fieldTitle ? `${secondaryAggregationQuery.fieldTitle} ` : '') +
                        ($scope.data.aggregatedEntity.pluralLabel || $scope.data.aggregatedEntity || 'items'),
                    previewDefinitionType: 'NEW_DEFINITION',
                    doNotCalculatePreviewForDefinition: false,
                    fieldDefinitionType: 'AGGREGATE_QUERY',
                    fieldDefinitionTargetType: $scope.data.targetType,
                    projectIntegrationId: $scope.data.projectIntegration.id,
                    fieldType: 'Number',
                    displayAs: 'Number',
                    displayFormat: 'Number',
                    evaluatedDisplayType: 'Number',
                    displayFormatPrefix: null,
                    displayFormatPostfix: null,
                    ranges: null,
                    definition: secondaryAggregationQuery,
                    validDefinition: secondaryAggregationIsValid,
                });

                if (definitionJson.aggregationType === 'Formula') {
                    definitionJson.secondaryAggregationsMap[secondaryAggregationQuery.fieldTitle] =
                        secondaryAggregationQuery;
                } else {
                    definitionJson.secondaryAggregationsMap[i] = secondaryAggregationQuery;
                }
            }
        } else {
            definitionJson = {
                query: getDefaultTonkeanQuery(),
            };
            if (
                $scope.data.customFiltersControl &&
                $scope.data.customFiltersControl.createDefinitionFromCustomFilters
            ) {
                definitionJson = $scope.data.customFiltersControl.createDefinitionFromCustomFilters();
            }

            if ($scope.data.sortByFieldObject) {
                definitionJson.orderByFieldConfiguration = {
                    orderByField: $scope.data.sortByFieldObject.orderByField,
                    orderByFieldType: $scope.data.sortByFieldObject.orderByFieldType.apiName,
                    orderByOrder: $scope.data.sortByFieldObject.orderBySelectedOrder.apiName,
                };
            }

            definitionJson.entity = entity;
            definitionJson.aggregationType = $scope.data.aggregationType ? $scope.data.aggregationType.apiName : null;
            definitionJson.aggregatedField = $scope.data.aggregatedField;
            definitionJson.matchTrackField = matchTrackField;
            definitionJson.orderByField = $scope.data.orderByField;
            definitionJson.orderByOrder = $scope.data.orderBySelectedOrder.apiName;
            definitionJson.matchType = matchTypeDisplayName;
            definitionJson.formulaExpression = $scope.data.formulaExpression;
            definitionJson.trackFieldToMatchWith = $scope.data.trackFieldToMatchWith;
            if ($scope.data.reduceFunction) {
                definitionJson.reduceAggregationDefinition = {
                    reduceFunction: $scope.data.reduceFunction.apiName,
                    reduceFunctionDefinition: {
                        concatenationSeparator: $scope.data.concatenationSeparator || undefined,
                    },
                };
            }

            if ($scope.data.groupByFunction) {
                definitionJson.groupByAggregationDefinition = {
                    groupByFunction: $scope.data.groupByFunction.apiName,
                    aggregateByField: $scope.data.groupByAggregateField,
                };
            }

            if (definitionJson) {
                const filtersCount = countQueryFilters(definitionJson.query);
                if (filtersCount) {
                    fieldConfigurationSummarySubTitle += `, ${filtersCount} conditions`;
                }
            }
        }

        let fieldType = 'Number';
        if (
            definitionJson.aggregationType === 'First' &&
            definitionJson.aggregatedField &&
            definitionJson.aggregatedField.type
        ) {
            fieldType = definitionJson.aggregatedField.type;
        }

        const validDefinition = validateDefinition(definitionJson);

        const evaluatedDefinitionObject = {
            fieldConfigurationSummaryTitle,
            fieldConfigurationSummarySubTitle,
            definition: definitionJson,
            validDefinition,
            updateable: false,
            fieldType,
            evaluatedDisplayType: fieldType,
            possibleValues: [],
            manualValue: null,
            additionalDefinitionsToPreview,
        };

        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({
                newDefinition: evaluatedDefinitionObject,
                doNotReloadPreview: !validDefinition || doNotReloadPreview,
            });
        }

        if (hadChanges) {
            ctrl.onChange?.();
        }
    }

    /**
     * Loads the fields of all possible entities of integration.
     */
    function loadAllEntitiesFields() {
        $scope.data.loadingAllAvailableFields = true;
        $scope.data.errorLoadingAllAvailableFields = null;

        tonkeanService
            .getAvailableExternalFields($scope.data.projectIntegration.id, [])
            .then((result) => {
                $scope.data.allAvailableFields = result.entitiesWithLabels;
            })
            .catch(() => {
                $scope.data.errorLoadingAllAvailableFields = 'Error occurred trying to get fields for auto complete.';
            })
            .finally(() => {
                $scope.data.loadingAllAvailableFields = false;
            });
    }

    /**
     * Resets the match track field.
     */
    function resetMatchTrackField() {
        $scope.data.matchTrackField = null; // It is important it is null to keep key metric version of this field to still work.

        let aggregatedEntity = $scope.data.aggregatedEntity;
        if ($scope.data.aggregatedEntity.entity) {
            aggregatedEntity = $scope.data.aggregatedEntity.entity;
        } else if ($scope.data.aggregatedEntity.pluralLabel) {
            aggregatedEntity = $scope.data.aggregatedEntity.pluralLabel;
        }
        aggregatedEntity = aggregatedEntity.toLowerCase();

        switch ($scope.data.projectIntegration.integration.integrationType.toLowerCase()) {
            case 'salesforce':
                if (aggregatedEntity === 'task') {
                    $scope.data.matchTrackField = {
                        name: 'What-Name',
                        label: 'Opportunity Name',
                        type: 'string',
                    };
                }
                break;

            case 'zendesk':
                if (aggregatedEntity === 'organization') {
                    $scope.data.matchTrackField = {
                        name: 'name',
                        label: 'name',
                        type: 'string',
                    };
                }
                break;

            case 'hubspot':
                if (aggregatedEntity === 'company') {
                    $scope.data.matchTrackField = {
                        name: 'name',
                        label: 'Name',
                        type: 'string',
                    };
                }
                break;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('IntegrationAggregateFieldCtrl', lateConstructController(IntegrationAggregateFieldCtrl));
