import template from './orderByField.template.html?angularjs';

/**
 * Definition of an integration aggregation field.
 */
export default angular.module('tonkean.app').component('tnkOrderByField', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        targetWorkflowVersionId: '@',
        projectIntegration: '<',
        savedMatchOptionSelection: '<',
        orderByTonkeanField: '<',
        entityMetadata: '<',
        onSortByFieldChanged: '&',
    },
    template,
    controller: 'OrderByFieldCtrl',
});
