import { lateConstructController } from '@tonkean/angular-components';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP, getFullFieldObject } from '@tonkean/tonkean-utils';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function UpdateFieldsLogicConfigurationCtrl(
    $scope,
    $timeout,
    $q,
    projectManager,
    tonkeanUtils,
    trackHelper,
    customFieldsManager,
    modal,
    utils,
    groupInfoManager,
    workflowVersionManager,
) {
    const ctrl = this;
    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        cantControlNotification: ctrl.cantControlNotification,
        singleFieldSelector: ctrl.singleFieldSelector,
        singleFieldSelectorLabel: ctrl.singleFieldSelectorLabel,
        singleFieldSelectorFieldId: ctrl.singleFieldSelectorFieldId,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        fieldsToUpdate: [{}],
        workflowVersionIdForFieldSelector: ctrl.workflowVersionId,

        existingFieldUpdaterPersonSelectionConfiguration: {},

        initiativeToUseOption: 'flow-track',
        initiativeToUse: null,
        initiativeToUseLoading: false,
        initiativeToUseEditMode: false,
        initiativeTitleToUse: null,
        groupToUseWithTrackTitle: null,
        groupToUseWithTrackTitleLoading: false,
        initiativeGroupToUse: null,
        groupsOptions: [],
        initiativeSearch: {
            results: [],
        },
    };

    /**
     * Initialization function for the controller.
     */
    ctrl.$onInit = function () {
        $scope.data.groupsOptions = $scope.pm.groups.filter((group) => !group.isExample);
        $scope.updateWorkflowVersionIdForFieldSelector();
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();

            if ($scope.data.configuredLogic.node.customTriggerType !== 'UNKNOWN') {
                $scope.onActionsChanged(false);
            }
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = ctrl.invalidLogics;
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = ctrl.previewEvaluationSource;
        }
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            // If we saved while loading the definition would delete the initiativeToUse
            if (shouldSaveLogic && $scope.data.initiativeToUseLoading) {
                shouldSaveLogic = false;
                $scope.$emit('alert', {
                    msg: "Can't save logic while a part of it is loading. Please try again.",
                    type: 'warning',
                });
            }

            const definition = {
                personDefinition: tonkeanUtils.buildPersonSelectionConfiguration(
                    $scope.data.fieldUpdaterPersonSelectionConfiguration,
                ),
                fieldsToUpdate: $scope.data.fieldsToUpdate,
                skipNotification: !$scope.data.shouldSendNotification,
            };

            if ($scope.data.initiativeToUseOption === 'custom-track') {
                definition.initiativeToUse = $scope.data.initiativeToUse && $scope.data.initiativeToUse.id;
            } else if ($scope.data.initiativeToUseOption === 'by-title-track') {
                definition.initiativeTitleToUse = $scope.data.initiativeTitleToUse;
                definition.groupToUseWithTrackTitle =
                    $scope.data.groupToUseWithTrackTitle && $scope.data.groupToUseWithTrackTitle.id;
            }

            const actionDefinition = {
                actions: [
                    {
                        type: 'MANUAL_FIELD_UPDATE',
                        definition,
                    },
                ],
            };

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Occurs on changes in configured fields.
     */
    $scope.onConfiguredFieldsChanged = function (configuredFields, shouldSaveLogic) {
        $scope.data.fieldsToUpdate = configuredFields;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Choose who will be the updater of the field
     */
    $scope.onFieldUpdaterPersonSelectionConfigurationChanged = function (
        personSelectionConfiguration,
        shouldSaveLogic,
    ) {
        $scope.data.fieldUpdaterPersonSelectionConfiguration = personSelectionConfiguration;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onInitiativeTitleToUseExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.initiativeTitleToUse = expression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * On selected initiative updated.
     */
    $scope.onInitiativeToUseSelect = function ($item) {
        $scope.validateInitiativeSelection($scope.data.workflowVersionId, $item.group.id).then(() => {
            $scope.data.initiativeToUse = $item;
            reloadFields().then(() => {
                $scope.data.initiativeToUseEditMode = false;
                $scope.onActionsChanged(true);
            });
        });
    };

    /**
     * On flow track option selected.
     */
    $scope.selectFlowTrackOption = function () {
        let validationPromise = $q.resolve();

        if ($scope.data.initiativeToUse) {
            validationPromise = $scope.validateInitiativeSelection(
                workflowVersionManager.getPublishedVersionFromCache($scope.data.initiativeToUse.group.id).id,
                workflowVersionManager.getPublishedVersionFromCache($scope.data.groupId).id,
            );
        }

        validationPromise.then(() => {
            $scope.data.initiativeToUseOption = 'flow-track';
            $scope.data.initiativeToUse = null;
            $scope.data.initiativeGroupToUse = null;

            $scope.updateWorkflowVersionIdForFieldSelector();

            $scope.onActionsChanged(true);
        });
    };

    /**
     * Selects custom track option.
     */
    $scope.selectCustomTrackOption = function () {
        $scope.data.initiativeToUseOption = 'custom-track';
        $scope.data.initiativeToUseEditMode = true;
        $scope.onActionsChanged(true);
    };

    $scope.selectByTitleTrackOption = function () {
        $scope.data.initiativeToUseOption = 'by-title-track';
    };

    /**
     * Make sure that when changing the target initiative, there are no fields that are related to the previous group.
     * If there is, ask the user if its ok to remove them from the definition.
     */
    $scope.validateInitiativeSelection = function (previousWorkflowVersionId, targetWorkflowVersionId) {
        if (previousWorkflowVersionId === targetWorkflowVersionId) {
            return $q.resolve();
        }

        const previousWorkflowVersionFieldDefinitions =
            customFieldsManager.selectedFieldsMap[previousWorkflowVersionId];
        const foundFields = [];
        const foundFieldDefinitions = [];

        for (let i = 0; i < $scope.data.fieldsToUpdate.length; i++) {
            const field = $scope.data.fieldsToUpdate[i];
            const key = getFieldToUpdateKey(field);
            const fieldDefinition = utils.findFirstById(previousWorkflowVersionFieldDefinitions, key);
            if (fieldDefinition) {
                foundFields.push(field);
                foundFieldDefinitions.push(fieldDefinition);
            }
        }

        if (!foundFields.length) {
            return $q.resolve();
        }

        const foundFieldsNames = foundFieldDefinitions.map((fieldDefinition) => fieldDefinition.name);
        $scope.mboxData = {
            title: 'Changing Track Context',
            body: `You seem to have some fields that are related to the List\\Worker of the previous Track context (
                ${utils.joinNames(foundFieldsNames)}), changing the track would remove them from the action definition. 
                Are you sure?`,
            isWarn: true,
            okLabel: 'Yes, remove them.',
            cancelLabel: 'Cancel',
        };

        return modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(() => {
                const foundFieldKeys = new Set(foundFields.map((field) => getFieldToUpdateKey(field)));
                // The user chose to remove these fields from the definition.
                // Remove the found fields from the fields we update
                $scope.data.fieldsToUpdate = $scope.data.fieldsToUpdate.filter((field) => {
                    const key = getFieldToUpdateKey(field);
                    return !foundFieldKeys.has(key);
                });
            });
    };

    $scope.onGroupToUseWithTrackTitleChanged = function () {
        $scope.updateWorkflowVersionIdForFieldSelector();
        $scope.onActionsChanged(true);
    };

    $scope.updateWorkflowVersionIdForFieldSelector = function () {
        const workflowVersionType = workflowVersionManager.isPublishedVersion($scope.data.workflowVersionId)
            ? WorkflowVersionType.PUBLISHED
            : WorkflowVersionType.DRAFT;
        switch ($scope.data.initiativeToUseOption) {
            case 'flow-track': {
                $scope.data.workflowVersionIdForFieldSelector = $scope.data.workflowVersionId;
                break;
            }
            case 'custom-track': {
                if ($scope.data.initiativeGroupToUse) {
                    workflowVersionManager
                        .getGroupWorkflowVersion($scope.data.initiativeGroupToUse.id, workflowVersionType)
                        .then((workflowVersion) => {
                            $scope.data.workflowVersionIdForFieldSelector = workflowVersion.id;
                        });
                }

                break;
            }
            case 'by-title-track': {
                if ($scope.data.groupToUseWithTrackTitle) {
                    workflowVersionManager
                        .getGroupWorkflowVersion($scope.data.groupToUseWithTrackTitle.id, workflowVersionType)
                        .then((workflowVersion) => {
                            $scope.data.workflowVersionIdForFieldSelector = workflowVersion.id;
                        });
                }

                break;
            }
        }

        return reloadFields();
    };

    function getFieldToUpdateKey(field) {
        return field.key || field.fieldId || field.selectedFieldDefinition.id;
    }

    /**
     * Reloads the fields available in the fields selector, this is relevant if the target initiative is not
     * in the same group as the flow initiative
     */
    function reloadFields() {
        let promise = $q.resolve();
        if (
            $scope.data.workflowVersionIdForFieldSelector &&
            $scope.data.workflowVersionIdForFieldSelector !== $scope.data.workflowVersionId &&
            !customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionIdForFieldSelector]
        ) {
            promise = customFieldsManager.getFieldDefinitions($scope.data.workflowVersionIdForFieldSelector);
        }

        return promise.then(() => {
            $scope.data.reloadFields = true;
            return $timeout(() => ($scope.data.reloadFields = false));
        });
    }

    /**
     * Migrates fieldIdToValueMap to new fieldsToUpdate array.
     */
    function getMigratedFieldsToUpdate(fieldIdToValueMap) {
        const fieldsToUpdate = [];

        for (const fieldDefinitionIdToUpdate in fieldIdToValueMap) {
            if (fieldIdToValueMap.hasOwnProperty(fieldDefinitionIdToUpdate)) {
                const fieldDefinitionToUpdate = getFullFieldObject(
                    fieldDefinitionIdToUpdate,
                    $scope.data.workflowVersionId,
                    customFieldsManager.selectedFieldsMap,
                );
                const valueObject = fieldIdToValueMap[fieldDefinitionIdToUpdate];

                if (valueObject.takeValueFromField) {
                    if (valueObject.specialFieldId) {
                        const valueSpecialField =
                            FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP[valueObject.specialFieldId];

                        fieldsToUpdate.push({
                            fieldId: fieldDefinitionIdToUpdate,
                            expressionValue: {
                                originalExpression: `{{${valueSpecialField.name}}`,
                                evaluatedExpression: `{{${valueSpecialField.id}}}`,
                            },
                            inputTypeSelection: valueObject.useEmptyValue ? 'EMPTY_VALUE' : 'EXPRESSION',
                        });
                    } else if (valueObject.fieldDefinitionId) {
                        const valueFieldDefinition = utils.findFirst(
                            customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                            (field) => field.id === valueObject.fieldDefinitionId,
                        );

                        if (valueFieldDefinition) {
                            fieldsToUpdate.push({
                                fieldId: fieldDefinitionIdToUpdate,
                                expressionValue: {
                                    originalExpression: `{{${valueFieldDefinition.name}}`,
                                    evaluatedExpression: `{{${valueFieldDefinition.id}}}`,
                                },
                                inputTypeSelection: valueObject.useEmptyValue ? 'EMPTY_VALUE' : 'EXPRESSION',
                            });
                        }
                    }
                } else {
                    if (fieldDefinitionIdToUpdate === 'TNK_STAGE') {
                        fieldsToUpdate.push({
                            fieldId: fieldDefinitionIdToUpdate,
                            statusValue: {
                                id: valueObject.id,
                                label: valueObject.label,
                                color: valueObject.color,
                            },
                            inputTypeSelection: valueObject.useEmptyValue ? 'EMPTY_VALUE' : 'MANUAL',
                        });
                    } else if (fieldDefinitionIdToUpdate === 'TNK_OWNER_ID') {
                        fieldsToUpdate.push({
                            fieldId: fieldDefinitionIdToUpdate,
                            ownerValue: {
                                ownerId: valueObject.id,
                            },
                            inputTypeSelection: valueObject.useEmptyValue ? 'EMPTY_VALUE' : 'MANUAL',
                        });
                    } else if (fieldDefinitionToUpdate.type === 'Date' || fieldDefinitionToUpdate.type === 'date') {
                        fieldsToUpdate.push({
                            fieldId: fieldDefinitionIdToUpdate,
                            dateValue: {
                                daysFromExecutionCurrentTime: valueObject.value,
                            },
                            inputTypeSelection: valueObject.useEmptyValue ? 'EMPTY_VALUE' : 'MANUAL',
                        });
                    } else {
                        fieldsToUpdate.push({
                            fieldId: fieldDefinitionIdToUpdate,
                            expressionValue: {
                                originalExpression: valueObject.value,
                                evaluatedExpression: valueObject.value,
                            },
                            inputTypeSelection: valueObject.useEmptyValue ? 'EMPTY_VALUE' : 'EXPRESSION',
                        });
                    }
                }
            }
        }

        return fieldsToUpdate;
    }

    /**
     * Initializes the edit mode of the logic configure component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'MANUAL_FIELD_UPDATE',
        );

        if (customTriggerAction) {
            const definition = customTriggerAction.customTriggerActionDefinition;

            let migrated = false;

            // Check if we need to do migration.
            if (definition.fieldIdToValueMap) {
                definition.fieldsToUpdate = getMigratedFieldsToUpdate(definition.fieldIdToValueMap);
                delete definition.fieldIdToValueMap;
                migrated = true;
            }

            // Fields
            if (definition.fieldsToUpdate && definition.fieldsToUpdate.length) {
                $scope.data.fieldsToUpdate = definition.fieldsToUpdate;
            }

            // Updater
            $scope.data.existingFieldUpdaterPersonSelectionConfiguration = definition.personDefinition;

            // Send notification
            $scope.data.shouldSendNotification = !definition.skipNotification;

            // Track to use
            if (definition.initiativeToUse) {
                $scope.data.initiativeToUseOption = 'custom-track';
                $scope.data.initiativeToUseLoading = true;

                // Track might not be in the same group, therefore it might not be in cache
                trackHelper.getInitiativeById(definition.initiativeToUse).then((initiative) => {
                    $scope.data.initiativeToUse = initiative;
                    $scope.data.initiativeGroupToUse = initiative.group;

                    // If the group of the initiative is different from the original group, we must reload the fields
                    // so it shows the fields relevant to the group
                    $scope
                        .updateWorkflowVersionIdForFieldSelector()
                        .then(() => ($scope.data.initiativeToUseLoading = false));
                });
            }

            if (definition.initiativeTitleToUse) {
                $scope.data.initiativeToUseOption = 'by-title-track';
                $scope.data.initiativeTitleToUse = definition.initiativeTitleToUse;
            }

            if (definition.groupToUseWithTrackTitle) {
                $scope.data.groupToUseWithTrackTitleLoading = true;

                groupInfoManager
                    .getGroupById(definition.groupToUseWithTrackTitle, true)
                    .then((group) => {
                        $scope.data.groupToUseWithTrackTitle = group;
                        $scope.updateWorkflowVersionIdForFieldSelector();
                    })
                    .finally(() => ($scope.data.groupToUseWithTrackTitleLoading = false));
            }

            if (migrated) {
                $scope.onActionsChanged(true);
            }
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('UpdateFieldsLogicConfigurationCtrl', lateConstructController(UpdateFieldsLogicConfigurationCtrl));
