import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function DocusignCustomActionsDefinitionCtrl($scope, integrations) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
        possibleRecipientTypes: [
            'signers',
            'agents',
            'editors',
            'intermediaries',
            'carbonCopies',
            'certifiedDeliveries',
            'inPersonSigners',
            'seals',
            'witnesses',
        ],
        possibleREnvelopeStatuses: [
            'created', // The envelope is created as a draft. It can be modified and sent later
            'sent', // The envelope will be sent to the recipients after the envelope is created.
            'completed', // The recipients have finished working with the envelope: the documents are signed and all required tabs are filled in.
            'declined', // The envelope has been declined by the recipients.
            'delivered', // The envelope has been delivered to the recipients.
            'signed', // The envelope has been signed by the recipients.
            'voided', // The envelope is no longer valid and recipients cannot access or sign the envelope.
        ],
        possibleTabs: [
            'approve',
            'checkbox',
            'company',
            'dateSigned',
            'date',
            'decline',
            'emailAddress',
            'email',
            'envelopeId',
            'firstName',
            'formulaTab',
            'fullName',
            'initialHere',
            'lastName',
            'list',
            'notarize',
            'note',
            'number',
            'radioGroup',
            'signHere',
            'signerAttachment',
            'ssn',
            'text',
            'title',
            'view',
            'zip',
        ],
        possibleFonts: [
            'Default',
            'Arial',
            'ArialNarrow',
            'Calibri',
            'CourierNew',
            'Garamond',
            'Georgia',
            'Helvetica',
            'LucidaConsole',
            'MSGothic',
            'MSMincho',
            'OCR-A',
            'Tahoma',
            'TimesNewRoman',
            'Trebuchet',
            'Verdana',
        ],
        possibleFontSizes: [
            'Default',
            'Size7',
            'Size8',
            'Size9',
            'Size10',
            'Size11',
            'Size12',
            'Size14',
            'Size16',
            'Size18',
            'Size20',
            'Size22',
            'Size24',
            'Size26',
            'Size28',
            'Size36',
            'Size48',
            'Size72',
        ],
        possibleFontColors: [
            'Default',
            'Black',
            'BrightBlue',
            'BrightRed',
            'DarkGreen',
            'DarkRed',
            'Gold',
            'Green',
            'NavyBlue',
            'Purple',
            'White',
        ],
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'ADD_RECIPIENTS_WITH_NAME':
            case 'CREATE_ENVELOPE_WITH_TABS':
                if (!$scope.data.definition.recipientsObjects) {
                    $scope.data.definition.recipientsObjects = [];
                }
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (storageIntegrationId, newDocumentName, shouldSaveLogic) {
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.newDocumentName = newDocumentName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTargetPathTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.targetPathExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDocumentIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.documentIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.addRecipientObject = function () {
        $scope.data.definition.recipientsObjects.push({
            name: {},
            email: {},
            roleName: {},
            routingOrder: {},
            type: $scope.data.possibleRecipientTypes[0],
            recipientId: $scope.data.definition.recipientsObjects.length + 1,
            recipientTabObjects: [],
        });
    };

    $scope.addRecipientTabObject = function (parentIndex) {
        $scope.data.definition.recipientsObjects[parentIndex].recipientTabObjects.push({
            tabType: 'text',
            anchorStringExpression: {},
            tabLabelExpression: {},
            tooltipExpression: {},
            tabFont: 'Default',
            tabFontSize: 'Default',
            tabFontColor: 'Default',
        });
    };

    $scope.removeRecipientObject = function (index) {
        $scope.data.definition.recipientsObjects.splice(index, 1);

        $scope.data.definition.recipientsObjects.forEach((recipient, index) => {
            recipient.recipientId = index + 1;
        });

        $scope.onDefinitionChanged(true);
    };

    $scope.removeRecipientTabObject = function (parentIndex, index) {
        $scope.data.definition.recipientsObjects[parentIndex].recipientTabObjects.splice(index, 1);

        $scope.onDefinitionChanged(true);
    };

    $scope.onRecipientNameTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[index].name = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRecipientEmailTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[index].email = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRoleNameTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[index].roleName = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRoutingOrderTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[index].routingOrder = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRecipientTypeTonkeanExpressionChanged = function (shouldSaveLogic) {
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Create Envelope with Tabs
     */

    $scope.onEmailSubjectTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.emailSubjectExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onEmailMessageTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.emailMessageExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStatusChanged = function (shouldSaveLogic) {
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTemplateIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.templateIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onRecipientTabTypeChanged = function (shouldSaveLogic) {
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTabLabelTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[parentIndex].recipientTabObjects[index].tabLabelExpression =
            expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAnchorStringTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[parentIndex].recipientTabObjects[index].anchorStringExpression =
            expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAnchorXOffsetTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[parentIndex].recipientTabObjects[index].anchorXOffset = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAnchorYOffsetTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[parentIndex].recipientTabObjects[index].anchorYOffset = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTooltipTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[parentIndex].recipientTabObjects[index].tooltipExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onValueTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.recipientsObjects[parentIndex].recipientTabObjects[index].valueExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSenderUserIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.senderUserIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Lock Envelope
     */
    $scope.onLockDurationInSecondsTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.lockDurationInSecondsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onLockTokenTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.lockTokenExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('DocusignCustomActionsDefinitionCtrl', lateConstructController(DocusignCustomActionsDefinitionCtrl));
