import notionCircleIcon from '../../../../apps/tracks/images/integrations/notion-circle.png';
import notionIcon from '../../../../apps/tracks/images/notion.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

export default class NotionIntegration extends IntegrationDefinitionBase {
    override name = 'notion';
    override displayName = 'notion';
    override description = `We only collect Users & Data Objects`;
    override authenticationType = AuthenticationMethod.OAUTH;
    override entities = [
        new IntegrationEntity('Block', 'Blocks'),
        new IntegrationEntity('Database', 'Databases'),
        new IntegrationEntity('Page', 'Pages'),
        new IntegrationEntity('User', 'Users'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(notionCircleIcon, '130px'),
        new IntegrationIcon(notionIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Block: true, Database: true, Page: true, User: true },
        isOnlyDataRetention: {},
    };

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const clientId = environment.integrationKeysMap.notion;
        const redirectUri = environment.integrationKeysMap.notionUri;
        const redirectUriState = oAuthHandler.publicGetState();
        const url = `https://api.notion.com/v1/oauth/authorize?owner=user&client_id=${clientId}&redirect_uri=${encodeURIComponent(
            redirectUri,
        )}&state=${redirectUriState}&response_type=code`;
        const code = await oAuthHandler.startOAuth(url, redirectUriState);
        return { code, redirectUri };
    }
}
