import MarketoAuthenticationComponent from './MarketoAuthenticationComponent';
import marketoCircleImage from '../../../../apps/tracks/images/integrations/marketo-circle.png';
import marketoImage from '../../../../apps/tracks/images/marketo.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class MarketoIntegration extends IntegrationDefinitionBase {
    override name = 'marketo';
    override displayName = 'Marketo';
    override description = '';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override storageProviderSettings = new StorageProviderSettings(true, false);

    override entities = [
        new IntegrationEntity('Campaign', 'Campaigns'),
        new IntegrationEntity('ExportJob', 'Export Jobs'),
        new IntegrationEntity('Lead', 'Leads'),
        new IntegrationEntity('SmartList', 'Smart Lists'),
        new IntegrationEntity('StaticList', 'Static Lists'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(marketoCircleImage, '130px'),
        new IntegrationIcon(marketoImage, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: {
            Campaign: true,
            ExportJob: false,
            Lead: true,
            SmartList: true,
            StaticList: true,
        },
        isOnlyDataRetention: {},
    };

    override customizedAuthenticationComponent = MarketoAuthenticationComponent;
}

export default MarketoIntegration;
