import { useAngularService } from 'angulareact';
import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import TrainingSetSelector from '../../../../infrastructure/components/TrainingSetSelector';

import { Field, InformationTooltip } from '@tonkean/infrastructure';
import type { TrainingSetType } from '@tonkean/tonkean-entities';

const ExtractorInput = styled.div`
    margin-top: 24px;
`;

const ChooseTextExtractorLabel = styled.div`
    display: flex;
    align-items: center;
`;

const InformationTooltipWrapper = styled(InformationTooltip)`
    margin-left: 5px;
`;

interface Props {
    groupId: string;
    selectedTrainingSetId?: string;
    trainingSetTypeAsText: string;
    trainingSetType?: TrainingSetType;
    onItemSelected: (selectedItemId: string, selectedItemLabel: string | undefined) => void;
}

const TrainingSetSelectorContainer: React.FC<Props> = ({
    groupId,
    selectedTrainingSetId,
    trainingSetTypeAsText,
    trainingSetType,
    onItemSelected,
}) => {
    const projectManager = useAngularService('projectManager');
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const currentWorkflowFolder = useMemo(() => {
        return workflowFolderManager.getContainingWorkflowFolder(projectManager.project.id, groupId);
    }, [workflowFolderManager, projectManager, groupId]);

    return (
        <ExtractorInput>
            <Field
                label={
                    <ChooseTextExtractorLabel>
                        <span>Choose {trainingSetTypeAsText}</span>
                        <InformationTooltipWrapper>
                            Notice: Only training sets that are allowed in this solution can be chosen.
                        </InformationTooltipWrapper>
                    </ChooseTextExtractorLabel>
                }
            >
                <TrainingSetSelector
                    workflowFolderId={currentWorkflowFolder.id}
                    preselectedItem={selectedTrainingSetId}
                    onItemSelected={onItemSelected}
                    trainingSetType={trainingSetType}
                />
            </Field>
        </ExtractorInput>
    );
};

export default TrainingSetSelectorContainer;
