import type { IHttpResponse } from 'angular';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { RegexExpressionViewer } from './components/RegexExpressionViewer';
import { RegexGroupsResult } from './components/RegexGroupsResult';
import { RegexInputResult } from './components/RegexInputResult';
import { RegexViewerBlock } from './components/RegexViewerBlock';
import { RegexMatchedGroupPart } from './entities/RegexMatchedGroupPart';
import type SpecificEditorProps from '../../entities/SpecificEditorProps';
import expressionToFormulaTree from '../../utils/expressionToFormulaTree';
import formulaTreeToExpression from '../../utils/formulaTreeToExpression';
import type { FormulaTreeConversionResult } from '../../utils/formulaTreeToExpression';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { operatorKeyToOperatorMap } from '@tonkean/forumla-operators';
import { useFlag } from '@tonkean/infrastructure';
import { OperatorKey } from '@tonkean/tonkean-entities';
import type { EvaluatedRegexExpressionsError } from '@tonkean/tonkean-entities';
import { debouncer } from '@tonkean/utils';

const changesEmitterDebouncer = debouncer(200);
const formulaFields = operatorKeyToOperatorMap[OperatorKey.REGEX_FIND].getFieldsList();

/**
 * Regex fields.
 * The enum value is the index in the operands list.
 */
enum RegexField {
    INPUT_STRING = 0,
    REGULAR_EXPRESSION = 1,
    REQUESTED_GROUPS = 2,
    REQUESTED_MATCHES = 3,
    GROUPS_SEPARATOR = 4,
}

const RegexViewer: React.FC<SpecificEditorProps> = ({
    groupId,
    workflowVersionId,
    exampleInitiativeId,
    tonkeanService,
    customFieldsManager,
    setFormulaChangedOutsideSpecificEditorCallback,
    operands,
    onChanges,
}) => {
    const createValuesMap = () =>
        ({
            [RegexField.INPUT_STRING]: formulaTreeToExpression(operands[RegexField.INPUT_STRING]!),
            [RegexField.REGULAR_EXPRESSION]: formulaTreeToExpression(operands[RegexField.REGULAR_EXPRESSION]!),
            [RegexField.REQUESTED_GROUPS]: formulaTreeToExpression(operands[RegexField.REQUESTED_GROUPS]!),
            [RegexField.REQUESTED_MATCHES]: formulaTreeToExpression(operands[RegexField.REQUESTED_MATCHES]!),
            [RegexField.GROUPS_SEPARATOR]: formulaTreeToExpression(operands[RegexField.GROUPS_SEPARATOR]!),
        }) as Record<RegexField, FormulaTreeConversionResult>;

    const [initialValues, setInitialValues] = useState(() => createValuesMap());

    const valuesRef = useRef(initialValues);
    const updateValues = useCallback((field: RegexField, value: FormulaTreeConversionResult) => {
        valuesRef.current = {
            ...valuesRef.current,
            [field]: value,
        };
    }, []);

    const updateInitialValuesFlag = useFlag(() => {
        const newInitialValues = createValuesMap();
        valuesRef.current = { ...newInitialValues };
        setInitialValues(newInitialValues);
    });

    useEffect(() => {
        setFormulaChangedOutsideSpecificEditorCallback(() => updateInitialValuesFlag);
        return () => setFormulaChangedOutsideSpecificEditorCallback(undefined);
    }, [setFormulaChangedOutsideSpecificEditorCallback, updateInitialValuesFlag]);

    const [matches, setMatches] = useState<RegexMatchedGroupPart[][]>([]);
    const [regexEvaluated, setRegexEvaluated] = useState('');
    const [inputEvaluated, setInputEvaluated] = useState('');
    const [output, setOutput] = useState('');
    const [selectedGroup, setSelectedGroup] = useState<{ match: number; group: number | undefined }>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{ index?: number; error: string }>();

    const fetchEvaluatedRegex = useCallback(async () => {
        const inputExpression = valuesRef.current[RegexField.INPUT_STRING].evaluatedExpression;
        const regexExpression = valuesRef.current[RegexField.REGULAR_EXPRESSION].evaluatedExpression;
        const groupIndexes = valuesRef.current[RegexField.REQUESTED_GROUPS].evaluatedExpression;
        const matchIndexes = valuesRef.current[RegexField.REQUESTED_MATCHES].evaluatedExpression;
        const groupsSeparator = valuesRef.current[RegexField.GROUPS_SEPARATOR].evaluatedExpression || '';

        if (!regexExpression || !matchIndexes || !groupIndexes || !exampleInitiativeId) {
            setMatches(() => []);
            setRegexEvaluated(() => '');
            setInputEvaluated(() => '');
            setOutput(() => '');
            setSelectedGroup(() => undefined);
            setError(() => undefined);
            return;
        }

        setSelectedGroup(() => undefined);
        setLoading(true);

        try {
            const evaluatedRegexExpressions = await tonkeanService.getEvaluatedRegexExpressions(
                exampleInitiativeId,
                regexExpression,
                inputExpression,
                matchIndexes,
                groupIndexes,
                groupsSeparator,
            );
            const matches = evaluatedRegexExpressions.matches.map((match, matchIndex) =>
                match.map(
                    (group, groupIndex) =>
                        new RegexMatchedGroupPart(
                            group.name,
                            group.value,
                            group.startIndex,
                            group.endIndex,
                            matchIndex + 1,
                            groupIndex,
                        ),
                ),
            );

            setMatches(() => matches);
            setRegexEvaluated(() => evaluatedRegexExpressions.evaluatedRegex);
            setInputEvaluated(() => evaluatedRegexExpressions.evaluatedInput);
            setOutput(() => evaluatedRegexExpressions.output);
            setError(() => undefined);
        } catch (regexError) {
            const errorObj = (regexError as IHttpResponse<{ error: EvaluatedRegexExpressionsError }>).data.error;

            setMatches(() => []);
            setRegexEvaluated(() => errorObj.data?.regex ?? '');
            setInputEvaluated(() => errorObj.data?.input ?? '');
            setError(() => ({
                index: errorObj.data?.index,
                error: errorObj.data?.error ?? errorObj.message,
            }));
        } finally {
            setLoading(false);
        }
    }, [exampleInitiativeId, tonkeanService]);

    // When the example item or the initial values changes, evaluate the regex.
    useEffect(() => {
        fetchEvaluatedRegex();
    }, [initialValues, fetchEvaluatedRegex]);

    // Callback function to mark a group as selected
    const onGroupSelect = useCallback(
        (match: number, group: number, selected: boolean) => {
            // If the currently selected group is the group sending an unselect, unselect.
            // Otherwise, ignore.
            if (selected || selectedGroup?.match !== match || selectedGroup?.group !== group) {
                setSelectedGroup(() => ({ match, group }));
            } else {
                setSelectedGroup(() => undefined);
            }
        },
        [selectedGroup],
    );

    // Returns a callback to emit changes
    const onValueChanged = useCallback(
        (fieldType: RegexField) => {
            return (newOriginalExpression: string, newEvaluatedExpression: string) => {
                const { originalExpression, evaluatedExpression, hasFormula, formulaTree } =
                    valuesRef.current[fieldType];

                if (newOriginalExpression === originalExpression && newEvaluatedExpression === evaluatedExpression) {
                    return;
                }

                if (hasFormula) {
                    return;
                }

                // Update stored values
                updateValues(fieldType, {
                    originalExpression: newOriginalExpression,
                    evaluatedExpression: newEvaluatedExpression,
                    hasFormula,
                    formulaTree,
                });

                // Emit and debounce changes to the formula tree.
                changesEmitterDebouncer(() => {
                    const newOperands = Array.from({
                        ...valuesRef.current,
                        length: Object.keys(valuesRef.current).length,
                    }).map((operandValue, index) =>
                        operandValue.hasFormula
                            ? operandValue.formulaTree
                            : expressionToFormulaTree(
                                  operandValue.evaluatedExpression,
                                  formulaFields[index]!,
                                  workflowVersionId,
                                  customFieldsManager,
                              ),
                    );

                    // Update formula tree
                    onChanges(newOperands);
                    // Update regex evaluation
                    fetchEvaluatedRegex();
                });
            };
        },
        [onChanges, workflowVersionId, customFieldsManager, updateValues, fetchEvaluatedRegex],
    );

    const onRegexChanged = useMemo(() => onValueChanged(RegexField.REGULAR_EXPRESSION), [onValueChanged]);
    const onInputChanged = useMemo(() => onValueChanged(RegexField.INPUT_STRING), [onValueChanged]);
    const onMatchChanged = useMemo(() => onValueChanged(RegexField.REQUESTED_MATCHES), [onValueChanged]);
    const onGroupChanged = useMemo(() => onValueChanged(RegexField.REQUESTED_GROUPS), [onValueChanged]);
    const onSeparatorChanged = useMemo(() => onValueChanged(RegexField.GROUPS_SEPARATOR), [onValueChanged]);

    return (
        <div className="regex-viewer">
            {!exampleInitiativeId && (
                <>
                    <div className="alert-warning block common-color-black empty-state-box margin-bottom-md padding-normal-sm">
                        To preview the evaluated regex expression, sync or import items to your module. <br /> It's
                        currently empty.
                    </div>
                </>
            )}
            <RegexExpressionViewer
                evaluatedRegex={regexEvaluated}
                loading={loading}
                unevaluatedRegex={valuesRef.current[RegexField.REGULAR_EXPRESSION].originalExpression}
                error={error}
                selectedGroup={selectedGroup}
            >
                <TonkeanExpression
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    savedOriginalExpression={initialValues[RegexField.REGULAR_EXPRESSION].originalExpression}
                    savedEvaluatedExpression={initialValues[RegexField.REGULAR_EXPRESSION].evaluatedExpression}
                    disabled={initialValues[RegexField.REGULAR_EXPRESSION].hasFormula}
                    onTonkeanExpressionChanged={onRegexChanged}
                    showFormulasTab={false}
                    doNotEvaluatePreview
                    saveOnKeyUp
                />
            </RegexExpressionViewer>
            <RegexInputResult
                evaluatedInput={inputEvaluated}
                loading={loading}
                unevaluatedInput={valuesRef.current[RegexField.INPUT_STRING].originalExpression}
                matches={matches}
                selectedGroup={selectedGroup}
            >
                <TonkeanExpression
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    savedOriginalExpression={initialValues[RegexField.INPUT_STRING].originalExpression}
                    savedEvaluatedExpression={initialValues[RegexField.INPUT_STRING].evaluatedExpression}
                    disabled={initialValues[RegexField.INPUT_STRING].hasFormula}
                    onTonkeanExpressionChanged={onInputChanged}
                    showFormulasTab={false}
                    doNotEvaluatePreview
                    saveOnKeyUp
                />
            </RegexInputResult>
            <RegexViewerBlock
                title="Output (result)"
                loading={loading}
                unevaluated={output || ''}
                className="regex-groups-output"
                evaluated
            >
                {output || ''}
            </RegexViewerBlock>
            <RegexGroupsResult
                showError={!!regexEvaluated && !!inputEvaluated}
                matches={matches}
                loading={loading}
                selectedGroup={selectedGroup}
                onGroupSelect={onGroupSelect}
            >
                <>
                    <div>
                        <h5 className="margin-bottom-xs">Requested Matches</h5>
                        <small className="margin-bottom-xs common-color-grey">
                            For multiple matches, separate by commas. 0 means all matches.
                        </small>
                        <TonkeanExpression
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={initialValues[RegexField.REQUESTED_MATCHES].originalExpression}
                            savedEvaluatedExpression={initialValues[RegexField.REQUESTED_MATCHES].evaluatedExpression}
                            disabled={initialValues[RegexField.REQUESTED_MATCHES].hasFormula}
                            overrideExampleInitiative={exampleInitiativeId}
                            onTonkeanExpressionChanged={onMatchChanged}
                            showFormulasTab={false}
                            saveOnKeyUp
                        />
                    </div>
                    <div>
                        <h5 className="margin-bottom-xs">Requested Groups</h5>
                        <small className="margin-bottom-xs common-color-grey">
                            For multiple groups, separate by commas. 0 means full match.
                        </small>
                        <TonkeanExpression
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={initialValues[RegexField.REQUESTED_GROUPS].originalExpression}
                            savedEvaluatedExpression={initialValues[RegexField.REQUESTED_GROUPS].evaluatedExpression}
                            disabled={initialValues[RegexField.REQUESTED_GROUPS].hasFormula}
                            overrideExampleInitiative={exampleInitiativeId}
                            onTonkeanExpressionChanged={onGroupChanged}
                            showFormulasTab={false}
                            saveOnKeyUp
                        />
                    </div>
                    <div>
                        <h5 className="margin-bottom-xs">Groups separator</h5>
                        <small className="margin-bottom-xs common-color-grey">
                            Will separate groups in a match if more than one requested.
                        </small>
                        <TonkeanExpression
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={initialValues[RegexField.GROUPS_SEPARATOR].originalExpression}
                            savedEvaluatedExpression={initialValues[RegexField.GROUPS_SEPARATOR].evaluatedExpression}
                            disabled={initialValues[RegexField.GROUPS_SEPARATOR].hasFormula}
                            overrideExampleInitiative={exampleInitiativeId}
                            onTonkeanExpressionChanged={onSeparatorChanged}
                            showFormulasTab={false}
                            saveOnKeyUp
                        />
                    </div>
                </>
            </RegexGroupsResult>
        </div>
    );
};

export default React.memo(RegexViewer);
