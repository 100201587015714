import React, { useMemo } from 'react';

import EnterpriseComponentMenuItem from './EnterpriseComponentPageMenuItem';
import type {
    EnterpriseComponentOverViewStepKey,
    EnterpriseComponentPageMenuSectionConfiguration,
} from '../../../entities/EnterpriseComponentPageMenuConfiguration';
import useEnterpriseComponentContext from '../../../hooks/useEnterpriseComponentContext';
import { isMenuItemVisible } from '../../../utils/IsMenuItemVisible';

import { SideMenuSection } from '@tonkean/infrastructure';
import type { EnterpriseComponent, EnterpriseComponentType } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';

interface Props<T extends EnterpriseComponentType, C extends EnterpriseComponent> {
    section: EnterpriseComponentPageMenuSectionConfiguration<T, C>;
    enterpriseComponentOverviewResult: ConvertEnterpriseComponentTypeToOverviewResult<T> | undefined;
    enterpriseComponentOverviewStepKeys: EnterpriseComponentOverViewStepKey<T>[];
}

const EnterpriseComponentPageMenuSection = <T extends EnterpriseComponentType, C extends EnterpriseComponent>({
    section,
    enterpriseComponentOverviewResult,
    enterpriseComponentOverviewStepKeys,
}: Props<T, C>) => {
    const { currentEnterpriseComponentAccessType } = useEnterpriseComponentContext();
    const visibleItems = useMemo(
        () => section.menuItems.filter((menuItem) => isMenuItemVisible(menuItem, currentEnterpriseComponentAccessType)),
        [section.menuItems, currentEnterpriseComponentAccessType],
    );

    return (
        <SideMenuSection title={section.title}>
            {visibleItems.map((menuItem) => (
                <EnterpriseComponentMenuItem
                    key={menuItem.id}
                    menuItem={menuItem}
                    enterpriseComponentOverviewResult={enterpriseComponentOverviewResult}
                    enterpriseComponentOverviewStepKeys={enterpriseComponentOverviewStepKeys}
                />
            ))}
        </SideMenuSection>
    );
};

export default EnterpriseComponentPageMenuSection;
