<div>
    <!-- Header -->
    <div class="margin-top">
        <!--Worker-->
        <div class="flex-vmiddle margin-bottom" ng-show="!data.hideWorkerSettings">
            <!--Worker Selection-->
            <div class="flex-no-shrink dropdown" uib-dropdown>
                <a class="common-link-no-style common-title-xxxs" data-ng-click="openGroupFilter()" uib-dropdown-toggle>
                    <span class="margin-right-xxs">
                        {{
                            data.isCurrentListSelected
                                ? 'Current module'
                                : data.filter.group
                                ? data.filter.group.name
                                : 'All modules'
                        }}
                    </span>
                    <i class="dropdown-chevron mod-static"></i>
                </a>

                <ul uib-dropdown-menu class="dropdown-menu overflow-y-auto" style="max-height: 200px">
                    <li ng-if="data.currentGroup" ng-click="setGroupFilter(data.currentGroup, true)">
                        <a>Current worker</a>
                    </li>
                    <li ng-if="data.currentGroup" class="divider"></li>
                    <li ng-repeat="group in pm.groups" ng-click="setGroupFilter(group)">
                        <a>
                            <i
                                class="fa margin-right-xs"
                                ng-class="group.visibilityType === 'PUBLIC' ? 'fa-globe' : 'fa-lock'"
                            ></i>
                            <span>{{ group.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="flex-grow"></div>

            <!-- Status -->
            <div ng-if="data.filter.group" class="margin-right">
                <span
                    class="common-bold"
                    ng-class="
                        pm.groupsMap[data.filter.group.id].workerEnabled ? 'common-color-success' : 'common-color-grey'
                    "
                >
                    {{ pm.groupsMap[data.filter.group.id].workerEnabled ? 'Active' : 'OFF' }}
                </span>
            </div>

            <!-- Open Worker -->
            <div ng-if="data.filter.group">
                <a
                    class="btn btn-no-border flex-vmiddle common-size-xxxs btn-bold-on-active-container"
                    ng-click="data.closeSidePane()"
                    ui-sref="product.workerEditor({g: data.filter.group.id})"
                    uib-tooltip="Open Module Editor"
                    tooltip-placement="top-right"
                >
                    <div class="bold-on-active common-size-xxs svg-icon-sm">
                        <tnk-icon src="/images/icons/customise.svg"></tnk-icon>
                    </div>
                </a>
            </div>
        </div>

        <!-- Filters -->
        <div class="flex-vmiddle margin-bottom-md">
            <!-- Explore history button -->
            <button class="btn btn-default btn-no-border btn-slim" ng-click="openWorkerRunsModal()">
                <i class="fa fa-history margin-right"></i>
                <span>Explore</span>
            </button>

            <div class="flex-grow"></div>

            <!-- Filter By Trigger -->
            <div class="flex-no-shrink dropdown margin-right-lg" uib-dropdown>
                <a
                    class="flex-vmiddle common-link-no-style common-title-xxxs"
                    uib-popover-template="'../tnkBotWorkerFeed/filterByTriggerPopover.template.html'"
                    popover-placement="bottom"
                    popover-is-open="data.triggerPopoverIsOpen"
                >
                    <span class="margin-right-xxs">
                        {{ data.filter.simplifiedTrigger.title ? data.filter.simplifiedTrigger.title : 'All Triggers' }}
                    </span>
                    <i class="dropdown-chevron mod-static"></i>
                </a>
            </div>

            <!-- Filter By Initiative -->
            <div class="flex-no-shrink dropdown" uib-dropdown>
                <a
                    class="flex-vmiddle common-link-no-style common-title-xxxs"
                    uib-popover-template="'../tnkBotWorkerFeed/filterByInitiativePopover.template.html'"
                    popover-placement="bottom-right"
                    popover-is-open="data.initiativePopoverIsOpen"
                >
                    <span class="margin-right-xxs">
                        {{
                            data.filter.simplifiedInitiative.title
                                ? data.filter.simplifiedInitiative.title
                                : 'All Items'
                        }}
                    </span>
                    <i class="dropdown-chevron mod-static"></i>
                </a>
            </div>
        </div>

        <hr class="margin-top-none" />
    </div>

    <!-- Worker History -->
    <tnk-worker-history
        ng-show="!data.isShowingFuture"
        filter="data.filter"
        group-id="{{ data.groupId }}"
        close-side-pane="data.closeSidePane"
    ></tnk-worker-history>
</div>
