import { getStateError } from '@tonkean/utils';
import { logicBlockTypes } from '@tonkean/logic-block-configs';
import { convertFieldDefinitionToExpression } from '@tonkean/tonkean-utils';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/**
 * A service to help with custom trigger operations.
 */
function CustomTriggerManager(
    $rootScope,
    $q,
    $log,
    tonkeanService,
    utils,
    modal,
    requestSimpleDebouncer,
    projectManager,
    customFieldsManager,
    integrationsConsts,
    communicationIntegrationsService,
    workflowVersionManager,
    syncConfigCacheManager,
    formManager,
    $localStorage,
    workflowFolderManager,
) {
    const _this = this;

    const workflowVersionIdToCustomTriggerIdToCustomTriggerMap = {};

    const workflowVersionIdToCustomTriggersMap = {};
    const workflowVersionIdToExampleItemsMap = {};

    const logicBlockTypesBySecondaryType = utils.createMapFromArray(
        utils.findAllInObj(
            logicBlockTypes,
            (blockType) => blockType.type === 'AUTONOMOUS' || blockType.type === 'MONITOR_TRACKS',
        ),
        'secondaryType',
    );

    let exampleItemToWorkflowMapCache;

    const validActionForTriggerTypeMap = {
        PERSON_INQUIRY: 'PERSON_INQUIRY',
        NLP_PROCESSOR: 'NLP_PROCESSOR',
        NLP_BRANCH: 'NLP_BRANCH',
        FRONT_DOOR_ACTION: 'FRONT_DOOR_ACTION',
        APPROVAL_CYCLE: 'APPROVAL_CYCLE',
        MANUAL_FIELD_UPDATE: 'MANUAL_FIELD_UPDATE',
        TRAIN_TRIGGER: 'TRAIN_TRIGGER',
        SEND_NOTIFICATION: 'SEND_NOTIFICATION',
        PERFORM_INTEGRATION_ACTION: 'PERFORM_INTEGRATION_ACTION',
        NEXT_STEPS: 'NEXT_STEPS',
        MANUAL_NEXT_STEPS: 'MANUAL_NEXT_STEPS',
        SYNC_INNER_MATCHED_ENTITY: 'SYNC_INNER_MATCHED_ENTITY',
        DELAY: 'DELAY',
        ASK_FIELD_UPDATE: 'ASK_FIELD_UPDATE',
        SEND_FORM: 'SEND_FORM',
        SEND_FORM_ANSWERED: 'SEND_FORM_ANSWERED',
        SEND_ITEM_INTERFACE: 'SEND_ITEM_INTERFACE',
        BOT_BUTTON_PRESSED: 'BOT_BUTTON_PRESSED',
        MANUAL_OWNER_UPDATE: 'MANUAL_FIELD_UPDATE',
        GATHER_UPDATE: 'GATHER_UPDATE',
        MONITOR_TRACKS: 'MONITOR_TRACKS',
        MOVE_INITIATIVE: 'MOVE_INITIATIVE',
        CLAIM: 'CLAIM',
        SEND_EMAIL: 'SEND_EMAIL',
        OUTGOING_WEBHOOK: 'OUTGOING_WEBHOOK',
        TONKEAN_ACTION: 'TONKEAN_ACTION',
        HTTP_UPLOAD: 'HTTP_UPLOAD',
        ASK_FIELD_UPDATE_ANSWERED: 'ASK_FIELD_UPDATE_ANSWERED',
        OCR_CONVERSION: 'OCR_CONVERSION',
        TEXT_EXTRACTOR: 'TEXT_EXTRACTOR',
        STORAGE_UPLOAD: 'STORAGE_UPLOAD',
        AUTONOMOUS: 'AUTONOMOUS',
    };

    /**
     * Making the custom trigger map public.
     */
    _this.workflowVersionIdToCustomTriggerIdToCustomTriggerMap = workflowVersionIdToCustomTriggerIdToCustomTriggerMap;

    /**
     * Making the workflow version id to example items map public.
     */
    _this.workflowVersionIdToExampleItemsMap = workflowVersionIdToExampleItemsMap;

    /**
     * Map from workflow version id to logic graph.
     */
    _this.workflowVersionIdToCustomTriggersGraph = {};

    /**
     * Loads the custom trigger from server by given id, and updates the cache.
     */
    _this.loadCustomTriggerById = function (workflowVersionId, customTriggerId) {
        return tonkeanService.getCustomTrigger(workflowVersionId, customTriggerId).then((customTrigger) => {
            if (!_this.getCachedCustomTrigger(workflowVersionId, customTriggerId)) {
                // Caching custom trigger if does not exist.
                _this.cacheCustomTrigger(workflowVersionId, customTrigger);
            } else {
                // Otherwise, overriding existing one.
                utils.copyEntityFields(customTrigger, _this.getCachedCustomTrigger(workflowVersionId, customTriggerId));
            }

            return $q.resolve(customTrigger);
        });
    };

    /**
     * Loads the custom triggers from server by given ids, and updates the cache.
     */
    _this.loadCustomTriggersByIds = function (workflowVersionId, customTriggerIds) {
        return tonkeanService.getCustomTriggersByCustomTriggerIds(workflowVersionId, customTriggerIds).then((data) => {
            data.customTriggers.forEach((customTrigger) => {
                if (!_this.getCachedCustomTrigger(workflowVersionId, customTrigger.id)) {
                    // Caching custom trigger if does not exist.
                    _this.cacheCustomTrigger(workflowVersionId, customTrigger);
                } else {
                    // Otherwise, overriding existing one.
                    utils.copyEntityFields(
                        customTrigger,
                        _this.getCachedCustomTrigger(workflowVersionId, customTrigger.id),
                    );
                }
            });
            return $q.resolve(data.customTriggers);
        });
    };

    /**
     * Returns whether custom triggers and graph exist for the given workflow version id.
     */
    _this.hasWorkflowVersionCustomTriggersAndGraph = function (workflowVersionId) {
        return (
            !!_this.workflowVersionIdToCustomTriggerIdToCustomTriggerMap[workflowVersionId] &&
            !!_this.workflowVersionIdToCustomTriggersGraph[workflowVersionId]
        );
    };

    /**
     * Gets the custom triggers and graph for workflow version
     */
    _this.getWorkflowVersionCustomTriggersAndGraph = function (workflowVersionId, forceServer) {
        if (
            !forceServer &&
            workflowVersionIdToCustomTriggersMap[workflowVersionId] &&
            _this.workflowVersionIdToCustomTriggersGraph[workflowVersionId]
        ) {
            return $q.resolve({
                customTriggers: workflowVersionIdToCustomTriggersMap[workflowVersionId],
                logicComponentsGraphRoot: _this.workflowVersionIdToCustomTriggersGraph[workflowVersionId],
            });
        } else {
            // Debouncing this function call, since calling it two times at the same time will cause the reference
            // to change in the workflowVersionIdToCustomTriggersGraph cache and break our angular usage of it.
            return requestSimpleDebouncer
                .debounce(
                    `ctm-getCustomTriggers-1-${workflowVersionId}`,
                    tonkeanService.getCustomTriggersOfWorkflowVersion,
                    workflowVersionId,
                )
                .then((data) => {
                    workflowVersionIdToCustomTriggersMap[workflowVersionId] = data.entities;
                    _this.workflowVersionIdToCustomTriggersGraph[workflowVersionId] = data.graph;

                    // Populating map with retrieved custom triggers.
                    for (let i = 0; i < data.entities.length; i++) {
                        _this.cacheCustomTrigger(workflowVersionId, data.entities[i]);
                    }

                    // Enriches the custom trigger graph with custom trigger entities.
                    enrichCustomTriggerGraph(workflowVersionId, data.graph);

                    return $q.resolve({
                        customTriggers: workflowVersionIdToCustomTriggersMap[workflowVersionId],
                        logicComponentsGraphRoot: _this.workflowVersionIdToCustomTriggersGraph[workflowVersionId],
                    });
                });
        }
    };

    /**
     * Caches given custom trigger.
     */
    _this.cacheCustomTrigger = function (workflowVersionId, customTrigger) {
        if (workflowVersionId && customTrigger) {
            if (!workflowVersionIdToCustomTriggerIdToCustomTriggerMap[workflowVersionId]) {
                workflowVersionIdToCustomTriggerIdToCustomTriggerMap[workflowVersionId] = {};
            }

            workflowVersionIdToCustomTriggerIdToCustomTriggerMap[workflowVersionId][customTrigger.id] = customTrigger;
        }
    };

    /**
     * Returns cached custom trigger.
     */
    _this.getCachedCustomTrigger = function (workflowVersionId, customTriggerId) {
        if (workflowVersionIdToCustomTriggerIdToCustomTriggerMap[workflowVersionId]) {
            return workflowVersionIdToCustomTriggerIdToCustomTriggerMap[workflowVersionId][customTriggerId];
        } else {
            return null;
        }
    };

    _this.getCachedCustomTriggerMapByWorkflowVersionId = function (workflowVersionId) {
        return workflowVersionIdToCustomTriggerIdToCustomTriggerMap[workflowVersionId];
    };

    /**
     * Returns cached custom trigger if exists in cache, or fallback and call an API to cache it
     */
    _this.getCustomTriggerFromCacheOrFallbackServer = function (workflowVersionId, customTriggerId) {
        const cached = _this.getCachedCustomTrigger(workflowVersionId, customTriggerId);

        if (cached) {
            return $q.resolve(cached);
        }

        return _this.loadCustomTriggerById(workflowVersionId, customTriggerId);
    };

    /**
     * Returns cached custom triggers by ids if they exist in cache, or fallback and call an API to cache them
     */
    _this.getCustomTriggerForCustomTriggerIdsFromCacheOrFallbackServer = function (
        workflowVersionId,
        customTriggerIds,
    ) {
        const uncachedCustomTriggers = [];
        const cachedCustomTriggers = [];
        customTriggerIds.forEach((customTriggerId) => {
            const cached = _this.getCachedCustomTrigger(workflowVersionId, customTriggerId);
            if (cached) {
                cachedCustomTriggers.push(cached);
            } else {
                uncachedCustomTriggers.push(customTriggerId);
            }
        });
        if (uncachedCustomTriggers.length > 0) {
            return _this.loadCustomTriggersByIds(workflowVersionId, uncachedCustomTriggers).then((customTriggers) => {
                customTriggers.forEach((customTrigger) => cachedCustomTriggers.push(customTrigger));
                return cachedCustomTriggers;
            });
        } else {
            return $q.resolve(cachedCustomTriggers);
        }
    };

    /**
     * Returns the icon class of the custom trigger by type
     */
    _this.getCustomTriggerIconClassByType = function (customTriggerType) {
        return logicBlockTypes[customTriggerType].iconClass;
    };

    /**
     * Checks if the workflow already has a cached example item.
     * @param workflowVersionId {string} - the workflow version id.
     * @returns {boolean}
     */
    _this.isWorkflowExampleItemInCache = function (workflowVersionId) {
        if (!exampleItemToWorkflowMapCache) {
            initExampleItemMapCacheFromStorage();
        }

        return exampleItemToWorkflowMapCache.has(workflowVersionId);
    };

    /**
     * Get a cached example item if exists.
     * @param workflowVersionId {string} - the workflow version id.
     * @returns {boolean}
     */
    _this.getWorkflowExampleItemIdFromCache = function (workflowVersionId) {
        if (!exampleItemToWorkflowMapCache) {
            initExampleItemMapCacheFromStorage();
        }

        return exampleItemToWorkflowMapCache.get(workflowVersionId);
    };

    _this.removeWorkflowExampleItemIdFromCache = function (workflowVersionId) {
        if (!exampleItemToWorkflowMapCache) {
            return;
        }

        setExampleItemInStorage(workflowVersionId, undefined);
    };

    /**
     * Calculate the example item for each trigger of workflow version.
     */
    _this.buildWorkflowVersionExampleItems = function (workflowVersionId, initiativeId) {
        if (!_this.workflowVersionIdToExampleItemsMap[workflowVersionId]) {
            _this.workflowVersionIdToExampleItemsMap[workflowVersionId] = {};
        }

        if (!exampleItemToWorkflowMapCache) {
            initExampleItemMapCacheFromStorage();
        }

        // In case example root item has changed, triggered by update in the graph
        if (initiativeId) {
            setExampleItemInStorage(workflowVersionId, initiativeId);
        }

        if (exampleItemToWorkflowMapCache.has(workflowVersionId)) {
            _this.workflowVersionIdToExampleItemsMap[workflowVersionId].root =
                exampleItemToWorkflowMapCache.get(workflowVersionId);
        }

        const rootInitiativeId = workflowVersionIdToExampleItemsMap[workflowVersionId]
            ? workflowVersionIdToExampleItemsMap[workflowVersionId].root
            : '';

        return _this.getWorkflowVersionCustomTriggersAndGraph(workflowVersionId).then(() => {
            const customTriggers = workflowVersionIdToCustomTriggersMap[workflowVersionId];
            for (const customTrigger of customTriggers) {
                const firstMonitorParentData = _this.getWorkflowVersionFirstMonitorParentData(
                    workflowVersionId,
                    customTrigger,
                );

                // If there is monitor parent we don't allow example item for now.
                if (!firstMonitorParentData) {
                    _this.workflowVersionIdToExampleItemsMap[workflowVersionId][customTrigger.id] = rootInitiativeId;
                } else {
                    _this.workflowVersionIdToExampleItemsMap[workflowVersionId][customTrigger.id] = null;
                }
            }
        });
    };

    /**
     * Gets all custom triggers of given type.
     */
    _this.getCustomTriggersOfTypeInWorkflowVersionFromCache = function (
        workflowVersionId,
        customTriggerType,
        customTriggerSecondaryType = undefined,
    ) {
        if (!workflowVersionId || !customTriggerType || !workflowVersionIdToCustomTriggersMap[workflowVersionId]) {
            return [];
        }

        const relevantCustomTriggers = [];

        for (const key in workflowVersionIdToCustomTriggersMap[workflowVersionId]) {
            if (workflowVersionIdToCustomTriggersMap[workflowVersionId].hasOwnProperty(key)) {
                const customTrigger = workflowVersionIdToCustomTriggersMap[workflowVersionId][key];

                if (
                    customTrigger &&
                    customTrigger.customTriggerType === customTriggerType &&
                    (!customTriggerSecondaryType ||
                        customTrigger.customTriggerSecondaryType === customTriggerSecondaryType)
                ) {
                    relevantCustomTriggers.push(customTrigger);
                }
            }
        }

        return relevantCustomTriggers;
    };

    _this.getCustomTriggersOfTypeInWorkflowVersion = async function (
        workflowVersionId,
        customTriggerType,
        customTriggerSecondaryType = undefined,
    ) {
        await _this.getWorkflowVersionCustomTriggersAndGraph(workflowVersionId);
        return _this.getCustomTriggersOfTypeInWorkflowVersionFromCache(
            workflowVersionId,
            customTriggerType,
            customTriggerSecondaryType,
        );
    };

    _this.getCustomTriggerChildrenOfType = function (workflowVersionId, customTriggerId, childrenType) {
        return tonkeanService.getCustomTriggerChildrenOfType(workflowVersionId, customTriggerId, childrenType);
    };

    /**
     * Updates the actions of the custom trigger.
     */
    _this.updateCustomTriggerLogic = function (
        groupId,
        customTriggerId,
        disabled,
        customTriggerType,
        customTriggerActions,
        queryDefinition,
        pingOwner,
        isScheduled,
        recurrencePeriodType,
        recurrenceDaysInWeek,
        recurrenceDaysInMonth,
        recurrenceHour,
        everyXMinutes,
        everyXHours,
        doNotRunOnWeekends,
        workerItemContextType,
        recurrenceMinute,
        monitorInnerItems,
        runOneTimeOnly,
        monitorFieldDefinitions,
        recurrenceMonthsInYear,
        timezone,
        linkedFieldDefinitionsMap,
        displayName,
        monitorForms,
        customTriggerSecondaryType,
        monitorInnerItemsCreatedByCustomTriggerId,
        resolveBeforeUpdatingCache = false,
        skipWhenConditionNotMet,
        dontRunOnDone,
        isReset = false,
        runAlsoOnNewItems,
        runAlsoOnIntakeItems,
    ) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const cachedCustomTrigger = _this.getCachedCustomTrigger(draftWorkflowVersionId, customTriggerId);
        let currentCustomTriggerSecondaryTypeProperties;
        const updateCachedCustomTrigger = () => {
            if (
                cachedCustomTrigger.customTriggerSecondaryType &&
                cachedCustomTrigger.customTriggerSecondaryType !== 'UNKNOWN'
            ) {
                currentCustomTriggerSecondaryTypeProperties = {
                    customTriggerSecondaryType: cachedCustomTrigger.customTriggerSecondaryType,
                    secondaryIconClass:
                        logicBlockTypesBySecondaryType[cachedCustomTrigger.customTriggerSecondaryType]
                            .secondaryIconClass,
                };
            }
            cachedCustomTrigger.workerItemContextType = workerItemContextType;
            cachedCustomTrigger.customTriggerType = customTriggerType;
            cachedCustomTrigger.disabled = disabled;
            cachedCustomTrigger.monitorInnerItems = monitorInnerItems;
            cachedCustomTrigger.monitorInnerItemsCreatedByCustomTriggerId = monitorInnerItemsCreatedByCustomTriggerId;
            cachedCustomTrigger.runOneTimeOnly = runOneTimeOnly;
            cachedCustomTrigger.monitorFieldDefinitions = monitorFieldDefinitions;
            cachedCustomTrigger.monitorForms = monitorForms;
            cachedCustomTrigger.customTriggerActions = customTriggerActions;
            cachedCustomTrigger.customTriggerSecondaryType = customTriggerSecondaryType;
            cachedCustomTrigger.skipWhenConditionNotMet = skipWhenConditionNotMet;
            cachedCustomTrigger.dontRunOnDone = dontRunOnDone;
            cachedCustomTrigger.runAlsoOnNewItems = runAlsoOnNewItems;
            cachedCustomTrigger.runAlsoOnIntakeItems = runAlsoOnIntakeItems;

            if (isReset || !cachedCustomTrigger.manuallyChangedName) {
                cachedCustomTrigger.displayName = displayName;
            }

            if (isReset) {
                cachedCustomTrigger.manuallyChangedName = false;
            }

            if (customTriggerSecondaryType) {
                cachedCustomTrigger.secondaryIconClass =
                    logicBlockTypesBySecondaryType[customTriggerSecondaryType].secondaryIconClass;
            }
        };

        if (!resolveBeforeUpdatingCache) {
            updateCachedCustomTrigger();
            enrichCustomTriggerGraph(
                draftWorkflowVersionId,
                _this.workflowVersionIdToCustomTriggersGraph[draftWorkflowVersionId],
            );
        }

        const validCustomTriggerActions = [];
        const validActionType = validActionForTriggerTypeMap[customTriggerType];

        if (validActionType) {
            const firstValidAction = utils.findFirst(customTriggerActions, (action) => action.type === validActionType);
            if (firstValidAction) {
                firstValidAction.customTriggerActionDefinition =
                    firstValidAction?.definition || firstValidAction?.customTriggerActionDefinition;
                validCustomTriggerActions.push(firstValidAction);
            }
        }

        // If the original param has issues in it, report it.
        if (
            customTriggerActions &&
            (customTriggerActions.length > 1 || (!validActionType && customTriggerActions.length !== 0))
        ) {
            $log.error(
                `Invalid actions when trying to update custom trigger. Actions count: [${customTriggerActions.length}] - Expected action type: [${validActionType}] - Got: [${customTriggerActions[0].type}].`,
            );
        }

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService
            .updateCustomTrigger(
                customTriggerId,
                disabled,
                displayName,
                null,
                queryDefinition,
                pingOwner,
                null,
                null,
                null,
                validCustomTriggerActions,
                null,
                customTriggerType,
                isScheduled,
                recurrencePeriodType,
                recurrenceDaysInWeek,
                recurrenceDaysInMonth,
                recurrenceHour,
                everyXMinutes,
                everyXHours,
                workerItemContextType,
                recurrenceMinute,
                monitorInnerItems,
                monitorFieldDefinitions ? monitorFieldDefinitions.map((fieldDefinition) => fieldDefinition.id) : null,
                doNotRunOnWeekends,
                recurrenceMonthsInYear,
                timezone,
                runOneTimeOnly,
                linkedFieldDefinitionsMap,
                monitorForms
                    ? monitorForms.filter((monitorForm) => monitorForm).map((monitorForm) => monitorForm.id)
                    : null,
                customTriggerSecondaryType ? customTriggerSecondaryType : null,
                monitorInnerItemsCreatedByCustomTriggerId,
                skipWhenConditionNotMet,
                dontRunOnDone,
                isReset,
                runAlsoOnNewItems,
                runAlsoOnIntakeItems,
            )
            .then(({ customTrigger, fieldDefinitionsChanged }) => {
                if (fieldDefinitionsChanged) {
                    $rootScope.$broadcast('linkedFieldDefinitionsChanged');
                }
                if (resolveBeforeUpdatingCache) {
                    updateCachedCustomTrigger();
                }

                $rootScope.$broadcast('customTriggerUpdated', customTrigger);
                return $q.resolve(customTrigger);
            })
            .catch((error) => {
                if (currentCustomTriggerSecondaryTypeProperties) {
                    // Revert secondary type to the original one
                    cachedCustomTrigger.customTriggerSecondaryType =
                        currentCustomTriggerSecondaryTypeProperties.customTriggerSecondaryType;
                    cachedCustomTrigger.secondaryIconClass =
                        currentCustomTriggerSecondaryTypeProperties.secondaryIconClass;
                }

                return $q.reject(error);
            });
    };

    /**
     * Updates the display name of the custom trigger.
     */
    _this.updateCustomTriggerDisplayName = function (groupId, customTriggerId, displayName) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const cachedCustomTrigger = _this.getCachedCustomTrigger(draftWorkflowVersionId, customTriggerId);

        cachedCustomTrigger.manuallyChangedName = true;
        cachedCustomTrigger.displayName = displayName;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        const updateCustomTriggerDisplayNameResponse = tonkeanService.updateCustomTriggerDisplayName(
            customTriggerId,
            displayName,
        );

        $rootScope.$broadcast('linkedFieldDefinitionsChanged');

        return updateCustomTriggerDisplayNameResponse;
    };

    /**
     * Updates the disabled mode of the custom trigger.
     */
    _this.updateCustomTriggerDisableMode = function (groupId, customTriggerId, disabled) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const cachedCustomTrigger = _this.getCachedCustomTrigger(draftWorkflowVersionId, customTriggerId);
        cachedCustomTrigger.disabled = disabled;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.updateCustomTriggerDisableMode(customTriggerId, disabled);
    };

    /**
     * Updates the isHidden of the custom trigger.
     */
    _this.updateCustomTriggerIsHidden = function (groupId, customTriggerId, isHidden) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const cachedCustomTrigger = _this.getCachedCustomTrigger(draftWorkflowVersionId, customTriggerId);
        cachedCustomTrigger.isHidden = isHidden;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.updateCustomTriggerIsHidden(customTriggerId, isHidden);
    };

    /**
     * Updates the custom notification settings of the custom trigger.
     */
    _this.updateCustomTriggerCustomNotificationSettings = function (
        groupId,
        customTriggerId,
        customNotificationSettings,
    ) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const cachedCustomTrigger = _this.getCachedCustomTrigger(draftWorkflowVersionId, customTriggerId);
        cachedCustomTrigger.customNotificationSettings = customNotificationSettings;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.updateCustomTriggerCustomNotificationSettings(
            customTriggerId,
            customNotificationSettings,
        );
    };

    /**
     * Clear custom notification settings of the custom trigger.
     */
    _this.clearCustomTriggerCustomNotificationSettings = function (groupId, customTriggerId) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const cachedCustomTrigger = _this.getCachedCustomTrigger(draftWorkflowVersionId, customTriggerId);
        cachedCustomTrigger.customNotificationSettings = null;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.updateCustomTriggerCustomNotificationSettings(customTriggerId, null);
    };

    /**
     * Updates the state id of the custom trigger.
     */
    _this.updateCustomTriggerStateId = function (
        groupId,
        customTriggerId,
        stateId,
        updateText,
        evaluatedUpdateText,
        externalStatusValue,
        stateUpdaterIsOwner,
        stateUpdaterIsPreviousActor,
        stateUpdaterPersonId,
        stateUpdaterExpressionDefinition,
        skipStatusNotification,
        stateUpdaterIsTonkean,
        stateUpdaterInnerItemsStateId,
        stateUpdaterFieldsToUpdate,
    ) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const cachedCustomTrigger = _this.getCachedCustomTrigger(draftWorkflowVersionId, customTriggerId);
        cachedCustomTrigger.stateId = stateId;
        cachedCustomTrigger.updateText = updateText;
        cachedCustomTrigger.evaluatedUpdateText = evaluatedUpdateText;
        cachedCustomTrigger.externalStatusValue = externalStatusValue;
        cachedCustomTrigger.stateUpdateSkipNotification = skipStatusNotification;
        cachedCustomTrigger.stateUpdaterIsOwner = stateUpdaterIsOwner;
        cachedCustomTrigger.stateUpdaterIsPreviousActor = stateUpdaterIsPreviousActor;
        cachedCustomTrigger.stateUpdaterPersonId = stateUpdaterPersonId;
        cachedCustomTrigger.stateUpdaterExpressionDefinition = stateUpdaterExpressionDefinition;
        cachedCustomTrigger.stateUpdaterIsTonkean = stateUpdaterIsTonkean;
        cachedCustomTrigger.stateUpdaterInnerItemsStateId = stateUpdaterInnerItemsStateId;
        cachedCustomTrigger.stateUpdaterFieldsToUpdate = stateUpdaterFieldsToUpdate;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.updateCustomTriggerStateId(
            customTriggerId,
            stateId,
            updateText,
            evaluatedUpdateText,
            externalStatusValue,
            stateUpdaterIsOwner,
            stateUpdaterIsPreviousActor,
            stateUpdaterPersonId,
            stateUpdaterExpressionDefinition,
            skipStatusNotification,
            stateUpdaterIsTonkean,
            stateUpdaterInnerItemsStateId,
            stateUpdaterFieldsToUpdate,
        );
    };

    /**
     * Creates a custom trigger and stores it in cache in workflow version.
     */
    _this.createCustomTriggerInWorkflowVersion = function (
        groupId,
        displayName,
        description,
        queryDefinition,
        pingOwner,
        alert,
        channelId,
        channelName,
        customTriggerActions,
        fieldDefinitionIdsToAskWhenGather,
        customTriggerType,
        autonomous,
        parentCustomTriggerId,
        disabled,
        stateId,
        updateText,
        evaluatedUpdateText,
        runOneTimeOnly,
        customTriggerSecondaryType,
    ) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService
            .createCustomTriggerInWorkflowVersion(
                groupId,
                displayName,
                description,
                queryDefinition,
                pingOwner,
                alert,
                channelId,
                channelName,
                customTriggerActions,
                fieldDefinitionIdsToAskWhenGather,
                customTriggerType,
                autonomous,
                parentCustomTriggerId,
                disabled,
                stateId,
                updateText,
                evaluatedUpdateText,
                runOneTimeOnly,
                customTriggerSecondaryType,
            )
            .then((data) => {
                const createdCustomTrigger = data.customTrigger;

                _this.cacheCustomTrigger(draftWorkflowVersionId, createdCustomTrigger);
                workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId].push(createdCustomTrigger);

                if (
                    data.parentCustomTrigger &&
                    _this.getCachedCustomTrigger(draftWorkflowVersionId, data.parentCustomTrigger.id)
                ) {
                    utils.copyEntityFields(
                        data.parentCustomTrigger,
                        _this.getCachedCustomTrigger(draftWorkflowVersionId, data.parentCustomTrigger.id),
                    );
                }

                return $q.resolve(createdCustomTrigger);
            });
    };

    /**
     * Duplicates a custom trigger and stores it in cache in a workflow version.
     */
    _this.duplicateCustomTriggerInWorkflowVersion = function (groupId, customTriggerId) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.duplicateCustomTrigger(customTriggerId).then((data) => {
            const createdCustomTrigger = data.createdCustomTrigger;
            const childrenCustomTriggers = data.childrenCustomTriggers;

            _this.cacheCustomTrigger(draftWorkflowVersionId, createdCustomTrigger);
            workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId].push(createdCustomTrigger);

            if (childrenCustomTriggers && childrenCustomTriggers.length) {
                for (const child of childrenCustomTriggers) {
                    _this.cacheCustomTrigger(draftWorkflowVersionId, child);
                    workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId].push(child);
                }
            }

            $rootScope.$broadcast('linkedFieldDefinitionsChanged');

            return $q.resolve({
                createdCustomTrigger,
                childrenCustomTriggers,
            });
        });
    };

    /**
     * Creates multiple custom triggers and stores them in cache for a workflow version.
     */
    _this.createMultipleCustomTriggersInWorkflowVersion = function (groupId, customTriggersToCreate) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService
            .createMultipleCustomTriggersInWorkflowVersion(groupId, customTriggersToCreate)
            .then((data) => {
                for (let i = 0; i < data.creationResults.length; i++) {
                    const creationResult = data.creationResults[i];

                    _this.cacheCustomTrigger(draftWorkflowVersionId, creationResult.createdCustomTrigger);

                    if (!workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId]) {
                        workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId] = [];
                    }
                    workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId].push(
                        creationResult.createdCustomTrigger,
                    );
                }

                return $q.resolve(data);
            });
    };

    /**
     * Moving the custom trigger to a different place in the custom trigger graph
     * @param groupId
     * @param parentCustomTriggerId - The parent of the custom trigger in the graph
     * @param childCustomTriggerId - The custom trigger we want to move
     * @param belowCustomTriggerId - If the parent has multiple impacts, below which one to put the moved custom trigger
     * @returns {*}
     */
    _this.moveCustomTrigger = function (groupId, parentCustomTriggerId, movedCustomTriggerId, belowCustomTriggerId) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;
        _this.buildWorkflowVersionExampleItems(draftWorkflowVersionId);
        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.updateCustomTriggerParent(
            groupId,
            parentCustomTriggerId,
            movedCustomTriggerId,
            belowCustomTriggerId,
        );
    };

    /**
     * Moving the custom trigger to a different place in the custom trigger graph and remove custom triggers
     * @param groupId
     * @param parentCustomTriggerId - The parent of the custom trigger in the graph
     * @param childCustomTriggerId - The custom trigger we want to move
     * @param belowCustomTriggerId - If the parent has multiple impacts, below which one to put the moved custom trigger
     * @param customTriggerToRemoveIds - custom triggers to remove
     * @returns {*}
     */
    _this.moveAndRemoveCustomTrigger = function (
        groupId,
        parentCustomTriggerId,
        movedCustomTriggerIds,
        belowCustomTriggerId,
        customTriggerToRemoveIds,
    ) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;
        _this.buildWorkflowVersionExampleItems(draftWorkflowVersionId);
        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.moveAndRemoveCustomTrigger(
            groupId,
            parentCustomTriggerId,
            movedCustomTriggerIds,
            belowCustomTriggerId,
            customTriggerToRemoveIds,
        );
    };

    /**
     * Converts UI model to API model and updates the custom triggers graph for workflow version.
     */
    _this.updateWorkflowVersionCustomTriggerGraph = function (groupId, logicComponentsGraphRoot) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        // recalculate the example initiative graph.
        _this.buildWorkflowVersionExampleItems(draftWorkflowVersionId);

        // Update the custom triggers graph.
        const apiGraphJson = {};
        createApiJsonGraph(logicComponentsGraphRoot, apiGraphJson);

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService.updateWorkflowVersionCustomTriggersGraph(groupId, apiGraphJson);
    };

    /**
     * Tabs a logic node IN if possible in the components graph root, and updates the server.
     */
    _this.tabInComponentsGraphLogicInWorkflowVersion = function (groupId, logic, logicParent) {
        // If we have a parent and siblings.
        if (logicParent && logicParent.impacts.length > 1) {
            // Find out index in the impacts array, while tracking the previous node.
            let previousChildNodeBlock = null;
            for (let i = 0; i < logicParent.impacts.length; i++) {
                const nodeBlock = logicParent.impacts[i];

                if (nodeBlock.node.id === logic.node.id) {
                    // If we found ourselves, and there's a previous sibling in the array, we should become his son.
                    // If there's no previous sibling, we have no one to be added into.
                    if (previousChildNodeBlock) {
                        const movedNode = _this.moveComponentsGraphLogic(i, logicParent, -1, previousChildNodeBlock);

                        // If the node was moved.
                        if (movedNode) {
                            const addTriggerBelowTriggerId = previousChildNodeBlock.impacts.length
                                ? previousChildNodeBlock.impacts[previousChildNodeBlock.impacts.length - 1].node.id
                                : null;

                            // Call the update parent so the server updates.
                            _this.moveCustomTrigger(
                                groupId,
                                previousChildNodeBlock.node.id,
                                movedNode.node.id,
                                addTriggerBelowTriggerId,
                            );
                        }

                        break;
                    }
                } else {
                    // The id is not ours. Mark this node as a previous one.
                    previousChildNodeBlock = nodeBlock;
                }
            }
        }
    };

    /**
     * Tabs a logic node OUT if possible in the components graph root, and updates the server.
     */
    _this.tabOutComponentsGraphLogicInWorkflowVersion = function (groupId, logic, logicParent, logicGrandParent) {
        if (logicParent && logicGrandParent && logicParent.node.customTriggerType !== 'AUTONOMOUS') {
            // Find the index of the parent inside the grand parent.
            const indexOfParent = utils.indexOf(
                logicGrandParent.impacts,
                (nodeBlock) => nodeBlock.node.id === logicParent.node.id,
            );
            // Find the index of the current node inside the parent.
            const indexOfNode = utils.indexOf(logicParent.impacts, (nodeBlock) => nodeBlock.node.id === logic.node.id);

            // Do the move.
            const movedNode = _this.moveComponentsGraphLogic(
                indexOfNode,
                logicParent,
                indexOfParent + 1,
                logicGrandParent,
            );

            const addBelowTriggerId = logicGrandParent.impacts[indexOfParent]?.node?.id;

            // If the node was moved, update the server.
            if (movedNode) {
                // Call the update parent so the server updates.
                _this.moveCustomTrigger(groupId, logicGrandParent.node.id, movedNode.node.id, addBelowTriggerId);
            }
        }
    };

    /**
     * Find and move the target according to given indexInParent inside the current parent, to the indexInNewParent in the newParent.
     * @param indexInParent - the current index in the current parent.
     * @param currentParent - the current parent to take the node from.
     * @param indexInNewParent - the desired index in the new parent. Can be set as -1 to signal the function to append the item to the end of the given parent.
     * @param newParent - the new parent to add the node too.
     * @returns the moved node if moved or null if not moved.
     */
    _this.moveComponentsGraphLogic = function (indexInParent, currentParent, indexInNewParent, newParent) {
        // Make sure we have all the data we need and it's valid.
        if (
            indexInParent < 0 ||
            !newParent ||
            !currentParent ||
            !currentParent.impacts ||
            !currentParent.impacts.length
        ) {
            return null;
        }

        // *** Move validations ***
        // If no type, it is root
        if (newParent.node.customTriggerType) {
            // Check if this node is allowed to be moved.
            const nodeBlockConfig = logicBlockTypes[currentParent.impacts[indexInParent].node.customTriggerType];
            if (nodeBlockConfig.cantMove) {
                $rootScope.$emit('alert', { msg: 'This logic type can not be moved.', type: 'warning' });
                return null;
            }
            // Check if the parent node support moving into.
            if (logicBlockTypes[newParent.node.customTriggerType].cantMoveInto) {
                $rootScope.$emit('alert', {
                    msg: 'You can not move other logics into this target logic.',
                    type: 'warning',
                });
                return null;
            }
        }

        // If we got here, the move is allowed.
        // Extract the node from the parent.
        const nodeBlock = currentParent.impacts.splice(indexInParent, 1)[0];

        // Make sure new parent has an impacts array.
        if (!newParent.impacts) {
            newParent.impacts = [];
        }

        // The caller can set "indexInNewParent" as -1. This means he wants us to add it last.
        if (indexInNewParent < 0) {
            indexInNewParent = newParent.impacts.length;
        }

        // Insert the item into the grand parent.
        newParent.impacts.splice(indexInNewParent, 0, nodeBlock);

        // Overriding the indexes from the server because they are not sequential.
        for (let i = 0; i < newParent.impacts.length; i++) {
            newParent.impacts[i].node.index = i;
        }

        return nodeBlock;
    };

    /**
     * Deletes a custom trigger and shows an "Are you sure" modal.
     */
    _this.deleteCustomTriggerInWorkflowVersion = function (groupId, scope, logic, dontValidate) {
        let validationModalPromise = $q.resolve();
        if (!dontValidate) {
            const haveChildrenTriggers =
                logic.impacts && logic.impacts.length && areAnyOfTheLogicImpactsVisible(logic.impacts);
            const willDeleteChildrenPostfix = haveChildrenTriggers ? ' All children would be deleted too.' : '';

            scope.mboxData = {
                title: 'Delete logic',
                body: `Are you sure you want to delete "${logic.node.displayName}"?${willDeleteChildrenPostfix}`,
                isWarn: true,
                okLabel: 'Delete',
                cancelLabel: 'Cancel',
            };

            validationModalPromise = modal.openMessageBox({
                scope,
                size: 'md',
                windowClass: 'mod-danger',
            }).result;
        }

        return (
            validationModalPromise
                // okLabel clicked.
                .then(() => {
                    return deleteCustomTriggerInnerInWorkflowVersion(groupId, logic.node.id);
                })
                // Returning true means the logic was deleted.
                .then(() => {
                    $rootScope.$broadcast('deletedCustomTrigger', logic.node.id);

                    return $q.resolve(true);
                })
                .catch((error) => {
                    // cancelLabel clicked.
                    // Clicking on cancel is not really an error, so we return a normal promise resolve.
                    // Returning false means the logic was not deleted at the end.
                    if (error === 'dismiss' || error === 'backdrop click' || error === 'escape key press') {
                        return $q.resolve(false);
                    }

                    if (error.status === 409) {
                        const errorMessage = getStateError(error, {
                            fallbackErrorMessage: `An error occurred while trying to delete the trigger`,
                        });

                        modal.alert('Delete Trigger', {
                            body: errorMessage,
                            isWarn: false,
                            okLabel: 'Ok',
                        });
                        return $q.resolve(false);
                    }

                    // Otherwise keep propagating the error
                    throw error;
                })
        );
    };

    /**
     * Finds the given logicId in the graph of the given workflowVersionId and returns
     * an object: {logic: <logic>, parent: <logic>, indexInParent: <int>}.
     * Returns null if not found.
     */
    _this.findLogicDataInWorkflowVersionGraph = function (workflowVersionId, predicate) {
        // We find the logic using DFS.

        // Make sure the workflowVersionId has a graph.
        const root = _this.workflowVersionIdToCustomTriggersGraph[workflowVersionId];
        if (!root || !root.impacts || !root.impacts.length) {
            return null;
        }

        // Check if the logicId is the root itself. If so, return.
        if (predicate(root.node)) {
            return { logic: root, parent: null, indexInParent: 0 };
        }

        // Create a stack.
        const nodeStack = [];
        // Create an id to parent map so we can return the parent of the found node as well.
        const nodeIdToParentAndIndexMap = {};
        // Push the starting nodes to the stack.
        for (let i = 0; i < root.impacts.length; i++) {
            nodeStack.push(root.impacts[i]);
            nodeIdToParentAndIndexMap[root.impacts[i].node.id] = { parent: root, indexInParent: i };
        }

        while (nodeStack.length) {
            // Go to the next one in the stack, but keep a hold over the previous one without mixing references.
            const currentNode = nodeStack.pop();

            // If we found the node.
            if (predicate(currentNode.node)) {
                return {
                    logic: currentNode,
                    parent: nodeIdToParentAndIndexMap[currentNode.node.id].parent,
                    indexInParent: nodeIdToParentAndIndexMap[currentNode.node.id].indexInParent,
                };
            }

            // Push its children in the stack (if he has any).
            if (currentNode.impacts && currentNode.impacts.length) {
                for (let i = 0; i < currentNode.impacts.length; i++) {
                    nodeStack.push(currentNode.impacts[i]);
                    nodeIdToParentAndIndexMap[currentNode.impacts[i].node.id] = {
                        parent: currentNode,
                        indexInParent: i,
                    };
                }
            }
        }

        // We didn't find the node, return null.\
        return null;
    };

    /**
     * Finds the given logicId in the graph of the given workflowVersionId and returns
     * an object: {logic: <logic>, parent: <logic>, indexInParent: <int>}.
     * Returns null if not found.
     */
    _this.findLogicDataInGraphById = function (workflowVersionId, logicId) {
        return _this.findLogicDataInWorkflowVersionGraph(workflowVersionId, (node) => node.id === logicId);
    };

    /**
     * Gets a map of each logic and its direct parent, under a workflow version.
     */
    _this.getLogicIdToParentMap = function (workflowVersionId) {
        // Make sure the workflowVersionId has a graph.
        const root = _this.workflowVersionIdToCustomTriggersGraph[workflowVersionId];
        if (!root || !root.impacts || !root.impacts.length) {
            return {};
        }

        const nodeStack = [];
        const nodeIdToParentMap = {};

        // Push the root children to the stack.
        for (let i = 0; i < root.impacts.length; i++) {
            const rootChild = root.impacts[i];

            nodeStack.push(rootChild);
            nodeIdToParentMap[rootChild.node.id] = root;
        }

        // As long as we have nodes in the stack, we aren't finished traversing.
        while (nodeStack.length) {
            const currentNode = nodeStack.pop();

            // Push current node's children into the stack (if it has any).
            if (currentNode.impacts && currentNode.impacts.length) {
                for (let i = 0; i < currentNode.impacts.length; i++) {
                    const child = currentNode.impacts[i];

                    nodeStack.push(child);
                    nodeIdToParentMap[child.node.id] = currentNode;
                }
            }
        }

        return nodeIdToParentMap;
    };

    _this.getFirstParentByPredicateInWorkflowVersion = function (workflowVersionId, logicId, predicate) {
        const logicParents = _this.getWorkflowVersionLogicParents(workflowVersionId, logicId);
        for (const parent of logicParents) {
            if (predicate(parent)) {
                return parent;
            }
        }

        return null;
    };

    /**
     * Gets the direct parent of the given logic and workflow version.
     */
    _this.getDirectParentInWorkflowVersion = function (workflowVersionId, logicId, predicate) {
        const logicParents = _this.getWorkflowVersionLogicParents(workflowVersionId, logicId);

        if (!logicParents || !logicParents.length || (!!predicate && !predicate(logicParents[0]))) {
            return null;
        }

        return logicParents[0];
    };

    /**
     * Returns whether there is any parent whose type is within the given parentLogicTypes array.
     */
    _this.anyParentOfTypesWorkflowVersion = function (workflowVersionId, logicId, parentLogicTypes) {
        const parentLogicTypesSet = utils.arrayToSet(parentLogicTypes);
        const logicParents = _this.getWorkflowVersionLogicParents(workflowVersionId, logicId);

        return utils.anyMatch(
            logicParents,
            (parent) => parent.node.customTriggerType && parentLogicTypesSet[parent.node.customTriggerType],
        );
    };

    /**
     * Returns a parent whose type is within the given parentLogicTypes array.
     */
    _this.getFirstParentOfTypesWorkflowVersion = function (workflowVersionId, logicId, parentLogicTypes, predicate) {
        const parentLogicTypesSet = utils.arrayToSet(parentLogicTypes);
        const logicParents = _this.getWorkflowVersionLogicParents(workflowVersionId, logicId);

        return logicParents.find(
            (parent) =>
                parent.node.customTriggerType &&
                parentLogicTypesSet[parent.node.customTriggerType] &&
                (!predicate ? true : predicate(parent)),
        );
    };

    /**
     * Returns true if one of the logic types provided in the list occurs and there are no actions between that interrupt:
     * 1. an inner item action (not including forms)
     * 2. wait trigger which also monitors the inner items
     */
    _this.isParentsTypeBeforeMonitoringActionItem = function (workflowVersionId, logicId, parentLogicTypes) {
        const parentLogicTypesSet = utils.arrayToSet(parentLogicTypes);

        if (!workflowVersionId || !logicId) {
            return false;
        }
        // Retrieving parents list.
        const logicParents = _this.getWorkflowVersionLogicParents(workflowVersionId, logicId);
        if (!logicParents || !logicParents.length) {
            return false;
        }
        let previousParent = null;
        for (const parent of logicParents) {
            // we monitor the ordered parents from the closest to the current action
            if (parent.node.customTriggerType && parentLogicTypesSet[parent.node.customTriggerType]) {
                return true;
            } else if (
                // if the current parent is creating action items & the previous parent was monitoring items || parent is monitoring inner items
                (parent.node.customTriggerType &&
                    logicBlockTypes[parent.node.customTriggerType].creatingActionItems &&
                    previousParent?.node?.customTriggerType === logicBlockTypes.MONITOR_TRACKS.type) ||
                parent.node.monitorInnerItems
            ) {
                return false;
            }
            previousParent = parent;
        }
        return false;
    };

    /**
     * Gets all logic parents, ordered from direct parent up to root node, for given logic id, and under given workflow version.
     */
    _this.getWorkflowVersionLogicParents = function (workflowVersionId, logicId) {
        const logicIdToParentMap = _this.getLogicIdToParentMap(workflowVersionId);

        // If we can't find the logic in the map, it means it doesn't exist in the graph, or it does not have any parents (root node),
        // we return null to indicate nothing found.
        if (!logicIdToParentMap[logicId]) {
            return null;
        }

        // This array will hold an ordered list of parents of given logic, going from direct parent up to the root.
        // For example, if A -> B -> C is the graph, the parents list for C will be: [B, A].
        const parents = [];

        // The parent that exists in the map is the direct parent of the logic.
        // For example, if A -> B -> C is the graph, the map is B: A, C: B.
        if (logicIdToParentMap[logicId]) {
            parents.push(logicIdToParentMap[logicId]);
            let currentParent = logicIdToParentMap[logicId];

            // As long as current parent has a parent.
            while (logicIdToParentMap[currentParent.node.id]) {
                if (logicIdToParentMap[currentParent.node.id]) {
                    parents.push(logicIdToParentMap[currentParent.node.id]);
                }
                currentParent = logicIdToParentMap[currentParent.node.id];
            }
        }

        return parents;
    };

    /**
     * Runs a predicate on all logic's children (recursively), if any matches return true
     */
    _this.anyLogicChildren = function (logic, predicate) {
        return innerAnyLogicChildren(logic, predicate, true);
    };

    /**
     * Gets the relevant parents which create action items and have a monitor child.
     * If the monitor of the creating logics parent points to another parent, it is not returned.
     * Thus, only parents that point to use their own action items are returned.
     * Notice: Root is never returned. If it is needed, it should br added to the function's result.
     */
    _this.getRelevantActionItemsCreatingParentLogicsWorkflowVersion = function (workflowVersionId, logicId) {
        const creatingParentLogics = [];

        if (!workflowVersionId || !logicId) {
            return creatingParentLogics;
        }

        // Retrieving parents list.
        const logicParents = _this.getWorkflowVersionLogicParents(workflowVersionId, logicId);
        if (!logicParents || !logicParents.length) {
            return creatingParentLogics;
        }

        // The previous parent in the loop, which is actually the child of the current parent (since we move up the parents chain).
        let previousParent = null;
        let nextWantedParentId = null;
        for (const parent of logicParents) {
            // Only take this parent if it is a "creatingActionItems" parent (and if it's valid and has all expected properties).
            if (
                parent.node &&
                parent.node.customTriggerType &&
                logicBlockTypes[parent.node.customTriggerType] &&
                logicBlockTypes[parent.node.customTriggerType].creatingActionItems &&
                parent.node.customTriggerActions &&
                parent.node.customTriggerActions.length &&
                parent.node.customTriggerActions[0].customTriggerActionDefinition && // If the parent has a monitor direct child (the child is the previous parent), we only take it (the parent)
                // if the monitor is mapped to run on "action item". This way, if the monitor is mapped to run on a
                // parent we won't add it and WILL add the his parent eventually. If the monitor maps to a parent, we
                // skip all parents until we reach the one pointed to. This way we ensures we don't take parents which are irrelevant.
                previousParent &&
                previousParent.node.customTriggerType === logicBlockTypes.MONITOR_TRACKS.type
            ) {
                if (nextWantedParentId && nextWantedParentId !== parent.node.id) {
                    // If we have parent id we need to find, and this previous parent isn't it, we can skip it.
                    continue;
                } else {
                    // This is the wanted parent. Clear the nextWantedParentId and move on to grab this parent if needed.
                    nextWantedParentId = null;
                }

                // The previous parent (our child) is a monitor. We only take this parent if its child monitor
                // points to the "action item" (and not parent). If it points to a parent, we add its id as the
                // nextWantedParentId, so we can skip all parents until we reach it (they are irrelevant if their
                // descendant points higher in the chain).
                if (
                    previousParent.node.customTriggerActions[0].customTriggerActionDefinition.workerItemContextType ===
                    'TRIGGERED_ACTION_ITEM'
                ) {
                    creatingParentLogics.push(parent);
                } else if (
                    previousParent.node.customTriggerActions[0].customTriggerActionDefinition.workerItemContextType ===
                    'PARENT_ITEM'
                ) {
                    if (
                        !previousParent.node.customTriggerActions[0].customTriggerActionDefinition
                            .workerItemContextTypeParentLogicId
                    ) {
                        // If there's no logic id, the parent is the root item. Since we don't return the root we can stop now.
                        break;
                    }

                    nextWantedParentId =
                        previousParent.node.customTriggerActions[0].customTriggerActionDefinition
                            .workerItemContextTypeParentLogicId;
                }
            }

            // Update the previous parent.
            previousParent = parent;
        }

        return creatingParentLogics;
    };

    /**
     * Validates the logics of the given workflow version workers graph.
     * @returns {*} invalidLogics - if any.
     */
    _this.validateCustomTriggers = function (workflowVersionId, group, project) {
        const workflowVersion = workflowVersionManager.getCachedWorkflowVersion(workflowVersionId);

        // Getting the workflow folder id the current group is contained in.
        const containingWorkflowFolder = workflowFolderManager.getContainingWorkflowFolder(
            projectManager.project.id,
            group.id,
        );

        // Fetching the project integration summary which contains the workflow folder -
        // project integration access.
        return tonkeanService
            .getProjectIntegrationsSummaries(projectManager.project.id, true, containingWorkflowFolder.id)
            .then(({ workflowFolderAccess }) => {
                const invalidLogics = {};

                // Validating data source.
                const dataSourceValidationObject = dataSourceValidation(workflowVersion, workflowFolderAccess);
                if (dataSourceValidationObject) {
                    invalidLogics['DATASOURCE'] = dataSourceValidationObject;
                }

                const validationObjects = {};
                // Validating logics graph.
                validateLogicsInner(
                    _this.workflowVersionIdToCustomTriggersGraph[workflowVersionId],
                    invalidLogics,
                    group,
                    null,
                    project,
                    workflowFolderAccess,
                    validationObjects,
                );

                return Promise.all(Object.values(validationObjects)).then((validationObjectsData) => {
                    addValidateObjectsToNodes(
                        _this.workflowVersionIdToCustomTriggersGraph[workflowVersionId],
                        invalidLogics,
                        validationObjectsData,
                        Object.keys(validationObjects),
                    );
                    // Evaluating final validation (whether worker is valid or not).
                    if (Object.keys(invalidLogics).length) {
                        return $q.reject(invalidLogics);
                    }

                    return $q.resolve();
                });
            });
    };

    /**
     * Returns a tuple of the first parent that is a monitor tracks logic, and the parent logic of it (which is a creating-inner-items type of logic), for a workflow version.
     */
    _this.getWorkflowVersionFirstMonitorParentData = function (workflowVersionId, customTrigger) {
        // Make sure we have a custom trigger.
        if (!customTrigger) {
            return null;
        }

        // Monitor Tracks blocks don't have a run context, because they are the configuration setters.
        if (customTrigger.customTriggerType === 'MONITOR_TRACKS') {
            return null;
        }

        // First, we get the logic parents ordered list. It is a list ordered from the direct logic parent up to root custom trigger.
        const logicParents = this.getWorkflowVersionLogicParents(workflowVersionId, customTrigger.id);
        if (logicParents && logicParents.length) {
            // Who we are running on is determined by a monitor tracks logic. So, we find the first one in our parents
            // list, and this is the one that will determine our running context.
            const firstMonitoringParent = utils.findFirst(
                logicParents,
                (logic) => logic.node.customTriggerType === 'MONITOR_TRACKS',
            );
            if (firstMonitoringParent) {
                // The definition of the monitor tracks logic is defined in the first and only action it has.
                const monitorTracksAction = getFirstCustomTriggerAction(
                    firstMonitoringParent.node.customTriggerActions,
                    'MONITOR_TRACKS',
                );

                if (monitorTracksAction && monitorTracksAction.customTriggerActionDefinition) {
                    // If the selected running context is running on action item, in order to display the name of the logic that created the action item,
                    // we need to fetch the parent of the monitor tracks definition - it will be a logic of a type that is creating inner items.
                    const logicToParentMap = this.getLogicIdToParentMap(workflowVersionId);
                    const creatingInnerItemsLogic = logicToParentMap[firstMonitoringParent.node.id];

                    return {
                        creatingInnerItemsLogic,
                        monitorActionDefinition: monitorTracksAction.customTriggerActionDefinition,
                    };
                }
            }
        }

        return null;
    };

    /**
     * Gets a string representing on which item the logic is running on.
     */
    _this.getWorkingOnLabelInWorkflowVersion = function (workflowVersionId, customTrigger) {
        // Default running context is the root monitored item.
        let runningOnString = 'Root Monitored Item';

        const firstMonitorParentData = _this.getWorkflowVersionFirstMonitorParentData(workflowVersionId, customTrigger);
        if (firstMonitorParentData) {
            switch (firstMonitorParentData.monitorActionDefinition.workerItemContextType) {
                case 'ORIGINAL_ITEM':
                    // Running the root monitored item in the flow.
                    runningOnString = 'Root Monitored Item';
                    break;
                case 'TRIGGERED_ACTION_ITEM':
                    // Running on the action item in hand (that was created by creatingInnerItemsLogic).
                    runningOnString = `Action item of '${firstMonitorParentData.creatingInnerItemsLogic.node.displayName}'`;
                    break;
                case 'PARENT_ITEM':
                    // Running on a parent of the action item.
                    let associatedParentName =
                        firstMonitorParentData.monitorActionDefinition.workerItemContextTypeParentLogicName;
                    const associatedParent = this.getCachedCustomTrigger(
                        workflowVersionId,
                        firstMonitorParentData.monitorActionDefinition.workerItemContextTypeParentLogicId,
                    );

                    // Its better to take the up-to-date parent's name in case of updates, but if it doesn't exist we use the default one.
                    if (associatedParent) {
                        associatedParentName = `created by '${associatedParent.displayName}'`;
                    }

                    runningOnString = `Parent Item ${associatedParentName}`;
                    break;
            }
        }

        return runningOnString;
    };

    /**
     * Migrates old personEmailFieldDefinitionId to personEmailExpressionDefinition by finding the full fieldDefinition,
     * converting it to an expression and updating the given personConfiguration with a 'personEmailExpressionDefinition'
     * property.
     * @param groupId - the group we're migrating in.
     * @param personConfiguration - the person configuration to migrate.
     * @returns {boolean} - true if a migration has happened, false otherwise.
     */
    _this.migratePersonEmailFieldDefinitionIdToExpressionIfNeeded = function (groupId, personConfiguration) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        // Old configurations with fieldDefinitionId instead of an expression are migrated to the new form.
        if (personConfiguration.personEmailFieldDefinitionId && !personConfiguration.personEmailExpressionDefinition) {
            // Load the fields.
            const fieldDefinitionMap = utils.createMapFromArray(
                customFieldsManager.selectedColumnFieldsMap[draftWorkflowVersionId],
                'id',
            );
            const globalFieldDefinitionMap = utils.createMapFromArray(
                customFieldsManager.selectedGlobalFieldsMap[draftWorkflowVersionId],
                'id',
            );
            // Get the field definition.
            const fieldDefinition =
                fieldDefinitionMap[personConfiguration.personEmailFieldDefinitionId] ||
                globalFieldDefinitionMap[personConfiguration.personEmailFieldDefinitionId];
            // Convert it to an expression and set the data.
            personConfiguration.personEmailExpressionDefinition = convertFieldDefinitionToExpression(fieldDefinition);
            // We did the migration. Return true.
            return true;
        }

        return false;
    };

    function innerAnyLogicChildren(logic, predicate, isFirstRun) {
        // If its the first run it means its the parent logic, and we don't want to run the predicate
        // on the parent.
        if (!isFirstRun && predicate(logic)) {
            return true;
        }

        if (!logic.impacts || !logic.impacts.length) {
            return false;
        }

        for (let i = 0; i < logic.impacts.length; i++) {
            const child = logic.impacts[i];

            if (innerAnyLogicChildren(child, predicate)) {
                return true;
            }
        }

        return false;
    }

    _this.howManyInnerLogicMatchCondition = function (logic, predicate) {
        if (!logic.impacts || !logic.impacts.length) {
            return predicate(logic) ? 1 : 0;
        }

        let result = predicate(logic) ? 1 : 0;
        for (let i = 0; i < logic.impacts.length; i++) {
            const child = logic.impacts[i];
            result += _this.howManyInnerLogicMatchCondition(child, predicate);
        }

        return result;
    };

    /**
     * Validates modules the data source config.
     */
    function dataSourceValidation(workflowVersion, workflowFolderProjectIntegrationsAccess) {
        // Validating data source.
        let dataSourceValidationObject = null;

        // Getting the sync config in order the configured project integration access.
        const optionalSyncConfigProjectIntegrationId = syncConfigCacheManager.getSyncConfig(workflowVersion.id)
            ?.projectIntegration?.id;

        if (workflowVersion.isFromAnotherModule) {
            return dataSourceValidationObject;
        } else if (workflowVersion.isScheduled) {
            dataSourceValidationObject = recurrenceTimeSelectionValidation(workflowVersion);
        } else if (workflowVersion.dataSourceType === 'UNKNOWN') {
            dataSourceValidationObject = {
                noDataSource: 'A data source must be configured (Manual is OK)',
            };
        } else if (
            optionalSyncConfigProjectIntegrationId &&
            workflowFolderProjectIntegrationsAccess.inAccessibleProjectIntegrations.includes(
                optionalSyncConfigProjectIntegrationId,
            )
        ) {
            dataSourceValidationObject = {
                inAccessibleProjectIntegration: true,
            };
        }
        return dataSourceValidationObject;
    }

    function recurrenceTimeSelectionValidation(workflowVersion) {
        const dataSourceValidationObject = {};

        if (!workflowVersion.recurrencePeriodType) {
            Object.assign(dataSourceValidationObject, {
                recurrencePeriodType: 'A frequency must be selected.',
            });
        }

        const minInterval =
            projectManager.project.features?.tonkean_feature_minimum_scheduled_input_source_interval_minutes || 30;
        if (
            workflowVersion.recurrencePeriodType === 'EveryXMinutes' &&
            (workflowVersion.everyXMinutes || 0) < minInterval
        ) {
            Object.assign(dataSourceValidationObject, {
                invalidEveryXMinutes: `Minutes value must be ${minInterval} or greater.`,
            });
        }

        if (workflowVersion.recurrencePeriodType === 'EveryXHours' && (workflowVersion.everyXHours || 0) < 1) {
            Object.assign(dataSourceValidationObject, {
                invalidEveryXHours: 'Hours value must greater than 0.',
            });
        }

        if (
            !dataSourceValidationObject.recurrencePeriodType &&
            !dataSourceValidationObject.invalidEveryXMinutes &&
            !dataSourceValidationObject.invalidEveryXHours
        ) {
            return null;
        }

        return dataSourceValidationObject;
    }

    /**
     * Recursive function that's validate the inner logics.
     * @param logic The logic being validated in current run
     * @param invalidLogics Object that holds all the errors found
     * @param group The group the triggers are related to
     * @param parent The current parent in the recursion. This should be null for the root item
     */
    function validateLogicsInner(
        logic,
        invalidLogics,
        group,
        parent,
        project,
        workflowFolderProjectIntegrationsAccess,
        validationObjects,
    ) {
        if (!logic || !logic.node) {
            return;
        }

        if (logic.node.customTriggerType) {
            let typeConfig;
            if (logic.node.customTriggerSecondaryType && logic.node.customTriggerSecondaryType !== 'UNKNOWN') {
                typeConfig = utils.findInObj(
                    logicBlockTypes,
                    (logicType) => logicType.secondaryType === logic.node.customTriggerSecondaryType,
                ).value;
            } else {
                typeConfig = logicBlockTypes[logic.node.customTriggerType];
            }

            const node = logic.node;
            if (node.disabled) {
                return;
            }

            let validationObject = null;
            if (node.customTriggerType === 'UNKNOWN') {
                validationObject = {
                    noType: 'Must select a type.',
                };
            } else if (typeConfig.validator || typeConfig.validatorAsync) {
                let definition = null;
                if (
                    node.customTriggerActions &&
                    node.customTriggerActions[0] &&
                    node.customTriggerActions[0].customTriggerActionDefinition
                ) {
                    definition = node.customTriggerActions[0].customTriggerActionDefinition;
                }

                if (!typeConfig.doesntHaveDefinition && !definition) {
                    validationObject = {
                        noDefinition: 'This block has not been configured.',
                    };
                } else {
                    const customTriggerManager = $rootScope.ctm;

                    const validatorParams = {
                        definition,
                        customTrigger: logic.node,
                        parentTrigger: parent,
                        integrationsConsts,
                        communicationIntegrationsService,
                        group,
                        syncConfigCacheManager,
                        customTriggerManager,
                        workflowVersionInfoRetriever: workflowVersionManager,
                        formInfoRetriever: formManager,
                        project,
                        projectManager,
                        childImpacts: logic.impacts,
                        tonkeanService,
                    };
                    if (typeConfig.validator) {
                        validationObjects[node.id] = typeConfig.validator(validatorParams);
                    } else {
                        validationObjects[node.id] = typeConfig.validatorAsync(
                            validatorParams,
                            $rootScope.features[$rootScope.pm.project.id],
                        );
                    }

                    if (logic.node?.projectIntegrationIds?.length > 0) {
                        // If the custom trigger (logic's node) has related project integration
                        // it means it is a project integration action.
                        // Thus, we will check whether the workflow folder has access to the
                        // project integration.
                        const whetherProjectIntegrationIsInAccessible = utils.anyMatch(
                            logic.node.projectIntegrationIds,
                            (projectIntegrationId) =>
                                workflowFolderProjectIntegrationsAccess.inAccessibleProjectIntegrations.includes(
                                    projectIntegrationId,
                                ),
                        );
                        if (whetherProjectIntegrationIsInAccessible) {
                            if (!validationObject) {
                                validationObject = {};
                            }
                            validationObject.inAccessibleProjectIntegration = true;
                        }
                    }
                }
            }

            if (validationObject) {
                invalidLogics[node.id] = validationObject;
            }
        }

        if (logic.impacts) {
            for (let i = 0; i < logic.impacts.length; i++) {
                const impact = logic.impacts[i];
                validateLogicsInner(
                    impact,
                    invalidLogics,
                    group,
                    logic,
                    project,
                    workflowFolderProjectIntegrationsAccess,
                    validationObjects,
                );
            }
        }
    }

    /**
     * Recursive function that's validate the inner logics.
     * @param logic The logic being validated in current run
     * @param invalidLogics Object that holds all the errors found
     * @param validationObjectsData Array of objects of validations for triggers
     * @param validateObjectKeys Array of trigger keys to get the correct validation object from validationObjectsData
     */
    function addValidateObjectsToNodes(logic, invalidLogics, validationObjectsData, validateObjectKeys) {
        const node = logic.node;
        const validationIndex = validateObjectKeys.indexOf(node.id);
        if (validationIndex > -1) {
            if (invalidLogics[node.id] && validationObjectsData[validationIndex]) {
                invalidLogics[node.id] = { ...invalidLogics[node.id], ...validationObjectsData[validationIndex] };
            } else if (validationObjectsData[validationIndex]) {
                invalidLogics[node.id] = validationObjectsData[validationIndex];
            }
        }
        if (logic.impacts) {
            for (let i = 0; i < logic.impacts.length; i++) {
                const impact = logic.impacts[i];
                addValidateObjectsToNodes(impact, invalidLogics, validationObjectsData, validateObjectKeys);
            }
        }
    }

    /**
     * Enriches the trigger graph with the cached custom triggers.
     */
    function enrichCustomTriggerGraph(workflowVersionId, currentNode) {
        if (
            currentNode.node &&
            ((currentNode.node.id && _this.getCachedCustomTrigger(workflowVersionId, currentNode.node.id)) ||
                (currentNode.node && _this.getCachedCustomTrigger(workflowVersionId, currentNode.node)))
        ) {
            currentNode.node = _this.getCachedCustomTrigger(workflowVersionId, currentNode.node.id || currentNode.node);
        }

        if (currentNode.impacts && currentNode.impacts.length) {
            for (let i = 0; i < currentNode.impacts.length; i++) {
                enrichCustomTriggerGraph(workflowVersionId, currentNode.impacts[i]);
            }
        }
    }

    /**
     * Returns true if any of the impacts are visible else returns false.
     */
    function areAnyOfTheLogicImpactsVisible(impacts) {
        for (let index = 0; impacts && index < impacts.length; index++) {
            if (impacts[index].node && !impacts[index].node.isHidden) {
                return true;
            }
        }

        return false;
    }

    /**
     * Deletes a custom trigger from cache and server.
     */
    function deleteCustomTriggerInnerInWorkflowVersion(groupId, customTriggerId) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        const triggerOriginObj = deleteCustomTriggerFromGraph(
            _this.workflowVersionIdToCustomTriggersGraph[draftWorkflowVersionId],
            customTriggerId,
        );

        workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
        return tonkeanService
            .deleteCustomTrigger(customTriggerId)
            .then((data) => {
                if (data.parentCustomTrigger) {
                    const parentCustomTrigger = data.parentCustomTrigger;
                    utils.copyEntityFields(
                        parentCustomTrigger,
                        _this.getCachedCustomTrigger(draftWorkflowVersionId, parentCustomTrigger.id),
                    );
                }

                if (!workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId]) {
                    workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId] = {};
                }

                data.deletedCustomTriggerIds.forEach((deletedCustomTriggerId) => {
                    const existingTriggerIndex = utils.indexOf(
                        workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId],
                        (customTrigger) => customTrigger.id === deletedCustomTriggerId,
                    );
                    workflowVersionIdToCustomTriggersMap[draftWorkflowVersionId].splice(existingTriggerIndex, 1);

                    const customTriggerIdToCustomTriggerMap =
                        workflowVersionIdToCustomTriggerIdToCustomTriggerMap[draftWorkflowVersionId];
                    if (customTriggerIdToCustomTriggerMap[deletedCustomTriggerId]) {
                        delete customTriggerIdToCustomTriggerMap[deletedCustomTriggerId];
                    }

                    // Just to be sure, we run deleteCustomTriggerFromGraph over each deleted child custom trigger.
                    if (customTriggerId !== deletedCustomTriggerId) {
                        deleteCustomTriggerFromGraph(
                            _this.workflowVersionIdToCustomTriggersGraph[draftWorkflowVersionId],
                            deletedCustomTriggerId,
                        );
                    }
                });

                return $q.resolve();
            })
            .catch((error) => {
                if (triggerOriginObj) {
                    triggerOriginObj.parent.impacts.splice(triggerOriginObj.index, 0, triggerOriginObj.node);
                }

                // Keep propagating the exception
                throw error;
            });
    }

    /**
     * Recursively finds the custom trigger in the graph and deletes it from the graph.
     * Returns the parent of the deleted custom trigger,
     *         the index in which the trigger was deleted from,
     *         and the node that was deleted
     */
    function deleteCustomTriggerFromGraph(graphNode, customTriggerId) {
        if (graphNode.impacts && graphNode.impacts.length) {
            let indexToDelete = -1;

            for (let i = 0; i < graphNode.impacts.length; i++) {
                const impact = graphNode.impacts[i];

                if (impact.node.id === customTriggerId) {
                    indexToDelete = i;
                    break;
                }
            }

            if (indexToDelete !== -1) {
                const deletedNodesArray = graphNode.impacts.splice(indexToDelete, 1);
                return {
                    parent: graphNode,
                    node: deletedNodesArray[0],
                    index: indexToDelete,
                };
            }

            let deletedTriggerParent = null;
            for (let i = 0; i < graphNode.impacts.length; i++) {
                const impact = graphNode.impacts[i];

                deletedTriggerParent = deleteCustomTriggerFromGraph(impact, customTriggerId);

                if (deletedTriggerParent) {
                    break;
                }
            }

            return deletedTriggerParent;
        }

        return null;
    }

    /**
     * Creates to the API json object the server is accepting.
     */
    function createApiJsonGraph(originalGraphNode, apiGraphNode) {
        apiGraphNode.node = originalGraphNode.node.id ? originalGraphNode.node.id : originalGraphNode.node;

        if (originalGraphNode.impacts && originalGraphNode.impacts.length) {
            apiGraphNode.impacts = [];

            for (let i = 0; i < originalGraphNode.impacts.length; i++) {
                const originalImpact = originalGraphNode.impacts[i];
                const newGraphNode = {};
                apiGraphNode.impacts.push(newGraphNode);
                createApiJsonGraph(originalImpact, newGraphNode);
            }
        }
    }

    /**
     * Set example item in storage.
     * @param workflowVersionId {string} - the workflow version id.
     * @param initiativeId {TonkeanId<TonkeanType> | undefined} - the initiative id.
     * @returns {void}
     */
    function setExampleItemInStorage(workflowVersionId, initiativeId) {
        exampleItemToWorkflowMapCache.set(workflowVersionId, initiativeId);
        $localStorage.workflowExampleItemMap = JSON.stringify([...exampleItemToWorkflowMapCache]);
    }

    /**
     * Init runtime cache instead of accessing the localstorage every time
     */
    function initExampleItemMapCacheFromStorage() {
        if (!$localStorage.workflowExampleItemMap) {
            exampleItemToWorkflowMapCache = new Map();
        } else {
            exampleItemToWorkflowMapCache = new Map(JSON.parse($localStorage.workflowExampleItemMap));
        }
    }
}

angular.module('tonkean.app').service('customTriggerManager', CustomTriggerManager);
