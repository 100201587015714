import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function OcrConversionLogicConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        onActionsChanged: ctrl.onActionsChanged,
        existingDefinition: undefined,
        googleCloudProjectIntegrationId: null,
        filterByIntegrations: ['GOOGLECLOUD'],
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        const source = {
            fileSourceType: $scope.data.fileSourceType,
            storageIntegrationId:
                $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.storageIntegrationId : undefined,
            storageIntegrationActionId:
                $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.storageIntegrationActionId : undefined,
            extendedMatchConfiguration:
                $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.extendedMatchConfiguration : undefined,
            parametersValues: $scope.data.parametersValues,
            url: $scope.data.fileSourceType === 'URL' ? $scope.data.urlExpression : undefined,
            workerStaticAssetId:
                $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetId : undefined,
            workerStaticAssetDisplayName:
                $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetDisplayName : undefined,
        };

        const actionDefinition = {
            actions: [
                {
                    type: 'OCR_CONVERSION',
                    definition: {
                        googleCloudProjectIntegrationId: $scope.data.googleCloudProjectIntegrationId,
                        source,
                    },
                },
            ],
        };

        ctrl.onActionsChanged?.({ definition: actionDefinition, shouldSaveLogic });
    };

    ctrl.$onInit = function () {
        initializeExistingFieldDefinition();
        initializeEditMode();
    };

    function initializeExistingFieldDefinition() {
        if ($scope.data.configuredLogic?.node?.customTriggerActions) {
            $scope.data.existingDefinition =
                $scope.data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition;
        }
    }

    function initializeEditMode() {
        if ($scope.data.existingDefinition) {
            const existingDefinition = $scope.data.existingDefinition;

            if ($scope.data.invalidLogics && $scope.data.invalidLogics[$scope.data.configuredLogic.node.id]) {
                $scope.data.validationObject = $scope.data.invalidLogics[$scope.data.configuredLogic.node.id];
            }

            // Set google cloud integration if exists
            if (existingDefinition.googleCloudProjectIntegrationId) {
                $scope.data.googleCloudProjectIntegrationId = existingDefinition.googleCloudProjectIntegrationId;
            }

            // Set file source properties
            $scope.data.fileSource = existingDefinition.source?.type;
            $scope.data.storageIntegration = { id: existingDefinition.source?.storageIntegrationId };
            $scope.data.storageIntegrationActionId = existingDefinition.source?.storageIntegrationActionId;
            $scope.data.extendedMatchConfiguration = existingDefinition.source?.extendedMatchConfiguration;
            $scope.data.fileIdInStorageIntegration = existingDefinition.source?.fileId;
            $scope.data.fileDownloadUrl = existingDefinition.source?.url;
            $scope.data.parametersValues = existingDefinition.source?.parametersValues;

            initializeNewConfigurationAsNeeded();
        }
    }

    function initializeNewConfigurationAsNeeded() {
        if ($scope.data.existingDefinition.source.fileSourceType) {
            $scope.data.fileSourceType = $scope.data.existingDefinition.source?.fileSourceType;
            $scope.data.storageIntegrationId = $scope.data.existingDefinition.source?.storageIntegrationId;
            $scope.data.storageIntegrationActionId = $scope.data.existingDefinition.source?.storageIntegrationActionId;
            $scope.data.extendedMatchConfiguration = $scope.data.existingDefinition.source?.extendedMatchConfiguration;
            $scope.data.urlExpression = $scope.data.existingDefinition.source?.url;
            $scope.data.workerStaticAssetId = $scope.data.existingDefinition.source?.workerStaticAssetId;
            $scope.data.workerStaticAssetDisplayName =
                $scope.data.existingDefinition.source?.workerStaticAssetDisplayName;
        }
    }

    $scope.onProjectIntegrationSelected = function (selectedProjectIntegration) {
        if (selectedProjectIntegration) {
            $scope.data.googleCloudProjectIntegrationId = selectedProjectIntegration.id;
        } else {
            $scope.data.googleCloudProjectIntegrationId = null;
        }

        $scope.onDefinitionChanged(true);
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.fileSourceType = fileSourceType;
        $scope.data.storageIntegrationId = storageIntegrationId;
        $scope.data.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.urlExpression = urlExpression;
        $scope.data.workerStaticAssetId = workerStaticAssetId;
        $scope.data.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('OcrConversionLogicConfigurationCtrl', lateConstructController(OcrConversionLogicConfigurationCtrl));
