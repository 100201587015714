import React from 'react';

import { ReactComponent as EmptyImage } from '../../../../../images/big-upload-image.svg';
import DragAndDropModal from '../DragAndDropModal';

import type { TonkeanUploadedFile } from '@tonkean/tonkean-entities';

interface Props {
    isUploadImageModalOpen: boolean;
    onSave: (files: TonkeanUploadedFile[]) => Promise<void>;
    onCancel: () => void;
    onDragged?: (files: TonkeanUploadedFile[]) => void;
    maximumFiles?: number;
    shouldTryToShowFile?: boolean;
    shouldAllowUploadFromUrl?: boolean;
    acceptedFileTypes?: string[] | string;
    limitationsDescriptionText?: string;
}

const DragAndDropImagesModal: React.FC<Props> = ({
    isUploadImageModalOpen,
    onSave,
    onCancel,
    onDragged,
    maximumFiles = 1,
    shouldAllowUploadFromUrl = true,
    acceptedFileTypes,
    limitationsDescriptionText,
}) => {
    return (
        <DragAndDropModal
            modalHeader="Upload Image"
            dragAndDropMainText="Drag image here to upload"
            dragAndDropLogo={<EmptyImage />}
            maximumFiles={maximumFiles}
            onDragged={onDragged}
            isOpen={isUploadImageModalOpen}
            onCancel={onCancel}
            onSave={onSave}
            shouldAllowUploadFromUrl={shouldAllowUploadFromUrl}
            acceptedFileTypes={acceptedFileTypes}
            limitationsDescriptionText={limitationsDescriptionText}
            isImageUpload
        />
    );
};

export default DragAndDropImagesModal;
