import React from 'react';
import styled from 'styled-components';

import useGetFieldInstance from './useGetFieldInstance';

import { FIELD_COMPARE_TIMEFRAME_LABELS_MAP } from '@tonkean/constants';
import type { FieldDefinition, Initiative, TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const TrendNumberWrapper = styled.div<{ positive?: boolean }>`
    display: flex;
    align-items: center;
    font-size: ${FontSize.SMALL_12};
    font-weight: 300;
    color: ${({ positive }) => {
        return positive ? Theme.colors.success : Theme.colors.error;
    }};
`;

interface Props extends StyledComponentsSupportProps {
    groupId: TonkeanId<TonkeanType.GROUP>;
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    fieldDefinition: FieldDefinition;
    showEmptyFieldWhenNoInitiative: boolean;
}

const fieldCompareTimeframeLabelsMap = FIELD_COMPARE_TIMEFRAME_LABELS_MAP;

const FieldTrendIndicator: React.FC<Props> = ({
    fieldDefinition,
    showEmptyFieldWhenNoInitiative,
    groupId,
    workflowVersion,
    initiative,
    className,
}) => {
    const fieldInstance = useGetFieldInstance(
        groupId,
        fieldDefinition,
        initiative,
        workflowVersion,
        showEmptyFieldWhenNoInitiative,
    );

    if (fieldDefinition.isSpecialField) {
        return null;
    }

    if (!fieldInstance?.change) {
        return null;
    }
    const change = Number.parseFloat(fieldInstance.change);
    if (!change) {
        return null;
    }
    const formattedChange = (change * 100).toFixed(2);

    return (
        <div className={className}>
            <TrendNumberWrapper positive={fieldInstance.positive}>
                <i className={`fa fa-caret-${fieldInstance.trend} trend-indicator-icon`} />
                {formattedChange}%
            </TrendNumberWrapper>
            <div className="common-size-xxxs common-color-grey compare-time-frame common-one-liner">
                Vs. {fieldCompareTimeframeLabelsMap[fieldDefinition.compareTimeframe] || 'yesterday'}
            </div>
        </div>
    );
};

export default FieldTrendIndicator;
