import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Modal, ModalBody, ModalHeader, ModalSize, TextEllipsis, XCloseButton } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ViewDetailsButton = styled(Clickable)`
    display: flex;
    align-items: center;
    color: ${Theme.colors.primary};
`;

const ChangesTable = styled.div`
    display: grid;
    grid-template-columns: 150px 1fr 1fr;
    column-gap: 10px;
    margin-bottom: 15px;
`;

const ChangesTableHeaders = styled(ChangesTable)`
    font-weight: bold;
    border-bottom: 1px solid ${Theme.colors.gray_400};
    margin-bottom: 8px;
`;

const ModalHeaderWrapper = styled(ModalHeader)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 20px 20px;
`;

const Header = styled.div`
    margin-top: 20px;
`;

const LimitedPreWrapper = styled.pre`
    width: 150px;
    overflow: auto;
`;

interface Props {
    before: Record<string, string> | undefined;
    after: Record<string, string> | undefined;
    name: string;
}

const EMPTY_OBJECT = {};

const readableWrapper = (value) => {
    if (!value) {
        return '-';
    }

    if (typeof value === 'object') {
        let valueJson = '-';
        try {
            valueJson = JSON.stringify(value, null, ' ');
        } catch {
            console.warn('There was a problem to stringify JSON from the value:', value);
            return 'Unable to preview field';
        }

        return <LimitedPreWrapper>{valueJson}</LimitedPreWrapper>;
    } else {
        return (
            <TextEllipsis numberOfLines={2} showMore>
                {value?.toString()}
            </TextEllipsis>
        );
    }
};

const AuditLogDefaultItem: React.FC<Props> = ({ name, before = EMPTY_OBJECT, after = EMPTY_OBJECT }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const changedFields = useMemo(() => {
        return [...new Set([...Object.keys(before), ...Object.keys(after)])]
            .filter((key) => JSON.stringify(before[key]) !== JSON.stringify(after[key]))
            .map((key) => ({
                key,
                name: key
                    .replaceAll(/([a-zA-Z]*)\.([a-zA-Z]*)/g, '$1 - $2')
                    .replaceAll(/([A-Z][a-z])/g, ' $1')
                    .trim()
                    .toLowerCase(),
                before: readableWrapper(before[key]),
                after: readableWrapper(after[key]),
            }));
    }, [after, before]);

    return (
        <>
            {!!changedFields.length && (
                <ViewDetailsButton onClick={() => setIsOpen(true)}>View Details</ViewDetailsButton>
            )}

            <Modal open={isOpen} onClose={() => setIsOpen(false)} size={ModalSize.MEDIUM} fixedWidth>
                <ModalHeaderWrapper $border={false}>
                    <Header>View Changes for {name}</Header>
                    <XCloseButton size={ButtonSize.MEDIUM} onClick={() => setIsOpen(false)} />
                </ModalHeaderWrapper>

                <ModalBody>
                    <ChangesTableHeaders>
                        <div>Field Name</div>
                        <div>Old Value</div>
                        <div>New Value</div>
                    </ChangesTableHeaders>

                    {changedFields.map((field) => (
                        <ChangesTable key={field.key}>
                            <div>
                                <TextEllipsis numberOfLines={1} tooltip>
                                    {field.name}
                                </TextEllipsis>
                            </div>

                            <div>{field.before}</div>

                            <div>{field.after}</div>
                        </ChangesTable>
                    ))}
                </ModalBody>
            </Modal>
        </>
    );
};

export default AuditLogDefaultItem;
