<div class="modal-header">
    <!--<h3 class="modal-title">{{currentIntegration.displayName}}</h3>-->
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<form novalidate>
    <div ng-form="data.integrationForm">
        <div class="modal-body">
            <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
            <div ng-hide="connecting || integration" class="text-center">
                <input ng-model="data.channelName" placeholder="Enter name" class="form-control" />
                <input ng-model="data.channelUrl" placeholder="Enter RSS link" class="form-control margin-top" />

                <button class="btn btn-primary margin-top" ng-click="connect()">Connect</button>

                <div class="margin-normal-sm-v common-subtitle-inner"></div>
            </div>
            <div class="text-center" ng-show="connecting">
                <h4>
                    <span class="loading"></span>
                    Connecting to {{ currentIntegration.displayName }}
                </h4>
            </div>
            <div ng-show="integration && !connecting">
                <div class="alert alert-info">
                    <a
                        class="pull-right"
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Change Feed
                    </a>

                    <div class="integration-group-header-connected" ng-if="integration">Authenticated</div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button
                class="btn btn-default"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="onCancel()"
            >
                Cancel
            </button>
        </div>
    </div>
</form>
