import { NavigablePageType } from '../entities/BaseNavigablePage';
import type ExternalNavigablePage from '../entities/ExternalNavigablePage';
import NavigablePageKey from '../entities/NavigablePageKey';

import { KnowledgeBase } from '@tonkean/constants';
import { EducationCenterIcon } from '@tonkean/svg';
import { AnnotationQuestionIcon } from '@tonkean/svg';

const externalPages: ExternalNavigablePage[] = [
    {
        id: NavigablePageKey.EDUCATION_CENTER,
        type: NavigablePageType.EXTERNAL,
        name: 'Tonkean Docs',
        path: KnowledgeBase.baseUrl,
        icon: EducationCenterIcon,
        menuIcon: AnnotationQuestionIcon,
        isShownInNavigationMenu: () => true,
        openInNewPage: true,
        accessibleToAllUsers: false,
    },
];

export default externalPages;
