<div class="tnk-formula-expression-editor">
    <div ng-show="!data.treeViewMod">
        <!-- Variable addition to formula -->
        <div class="flex flex-vmiddle margin-bottom-xs margin-top">
            <div class="flex-grow common-bold">Formula Editor:</div>

            <div class="flex">
                <!-- Dropdown selection -->
                <ui-select
                    ng-model="variableObject"
                    theme="bootstrap"
                    class="column-filter-min-width"
                    on-select="addVariableToExpression(variableObject)"
                    ng-disabled="!data.variables || !data.variables.length"
                >
                    <ui-select-match placeholder="Insert {{ data.variableEntityName | lowercase }}...">
                        Insert {{ data.variableEntityName | lowercase }}...
                    </ui-select-match>
                    <ui-select-choices
                        class="ui-select-choices-hover"
                        group-by="'displayAs'"
                        repeat="p in data.variables | filter:$select.search | orderBy:'label'"
                    >
                        <div class="flex-vmiddle ui-select-item-hover">
                            <div ng-bind-html="p.name | highlight: $select.search" class="margin-right-xs"></div>
                            <div class="common-size-xxxxs common-color-grey" ng-if="p.isColumnFieldDefinition">
                                (Field)
                            </div>
                        </div>
                    </ui-select-choices>
                </ui-select>

                <!-- Function Dropdown selection -->
                <ui-select
                    ng-model="variableObject"
                    theme="bootstrap"
                    class="column-filter-min-width margin-left"
                    on-select="addFormulaToExpression(variableObject)"
                    ng-disabled="!data.availableFunctions || !data.availableFunctions.length"
                >
                    <ui-select-match placeholder="Functions">Functions</ui-select-match>
                    <ui-select-choices
                        group-by="operatorStringDataType"
                        repeat="p in data.filteredAvailableFunctions | orderBy:'displayName'"
                        refresh="filterOperators($select.search)"
                        refresh-delay="0"
                    >
                        <div class="flex-vmiddle">
                            <div ng-bind-html="p.displayName | highlight: $select.search" class="margin-right-xs"></div>
                        </div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <pre
            id="{{::data.expressionPreId}}"
            contenteditable="true"
            class="tnk-tags-expression common-no-outline"
            data-automation="formula-expression-editor-row"
            ng-keydown="onKeydown($event)"
            ng-keyup="onExpressionPreChanged()"
            ng-mouseup="saveCursorPosition()"
            ng-paste="onExpressionPreChanged()"
        ></pre>
    </div>

    <div ng-if="data.treeViewMod">
        <tnk-formula-builder
            project-id="data.projectId"
            group-id="data.groupId"
            example-initiative-id="data.exampleInitiative.id"
            workflow-version-id="data.workflowVersionId"
            evaluated-formula="data.evaluatedFormulaExpression"
            formula-expression-tree="data.formulaExpressionTree"
            on-formula-change="(onFormulaBuilderChanged)"
            on-formula-data-change="(onFormulaDataChange)"
            emit-validation-result="emitValidationResult"
            data-automation="formula-experssion-editor-formula"
            on-inner-track-aggregation-selected="(data.onInnerTrackAggregationSelected)"
            field-definition="data.fieldDefinition"
            additional-tabs="data.additionalTabs"
            custom-trigger="data.customTrigger"
            project-integration="data.projectIntegration"
        ></tnk-formula-builder>
    </div>

    <tnk-example-item-evaluated-expression
        ng-if="data.exampleInitiative"
        ng-hide="data.validatingAdvancedFormula || (data.validationResult && data.validationResult.errorMessage)"
        class="flex-grow margin-right-xs"
        expression-to-evaluate='data.validationResult.validFormulaExpression ? "<#" + (data.evaluatedFormulaExpression || "\"\"") + "#>" : undefined'
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        override-example-initiative-id="data.exampleInitiative.id"
    ></tnk-example-item-evaluated-expression>

    <!-- Loading -->
    <div ng-if="data.validatingAdvancedFormula" class="flex-vmiddle margin-top-xs">
        <i class="loading-small margin-right-xs"></i>
        <span class="common-size-xxxs">Validating...</span>
    </div>

    <!-- Error message -->
    <div
        class="margin-top-xs common-color-danger common-size-xxs"
        ng-if="!data.validatingAdvancedFormula && data.validationResult.errorMessage"
    >
        {{ data.validationResult.errorMessage }}
    </div>
</div>
