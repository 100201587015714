<div class="view-users common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn svg-icon-sm" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="padding-left-md padding-top-md common-size-xl common-bold mod-no-top">All Users</h1>

    <!-- Users -->
    <div class="avatars-list-wrapper">
        <div class="avatars-list-wrapper padding-14">
            <div ng-repeat="avatar in data.avatars track by $index">
                <div class="padding-xs flex-vmiddle common-size-xxxs margin-left-sm margin-normal-sm-v margin-right">
                    <!-- Avatar -->
                    <tnk-avatar
                        user="avatar"
                        default-on-empty="true"
                        class="image-avatar margin-right flex-no-shrink"
                        size="medium"
                        hide-default-avatar-photo="true"
                    ></tnk-avatar>

                    <!-- User name -->
                    <span class="flex-grow margin-right-lg common-color-light-grey2 common-bold">
                        {{ avatar.isAnonymousUser ? data.anonymousUserName : avatar.name }}
                    </span>

                    <!-- Last view time -->
                    <span ng-if="data.shouldDisplayViewTime" class="flex-no-shrink common-color-grey-soft">
                        {{ avatar.lastViewTime ? (avatar.lastViewTime | timeAgo) : 'Never Viewed' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
