import type { EnvConfig, EnvironmentApps, SingleEnvironmentConfig } from '@tonkean/env';

const ENVIRONMENT_LOCAL_STORAGE_KEY = 'environment';
const SLACK_CLIENT_ID_LOCAL_STORAGE_KEY = 'ngStorage-slackAppClientId';

// Add ENV_CONFIG to the window interface
declare global {
    interface Window {
        ENV_CONFIG: EnvConfig;
    }
}

class Environment {
    public config: EnvConfig;

    public name: string;
    public isLocal: boolean;
    public isProd: boolean;
    public appUrl: string;
    public apiUrl: string;
    public marketplaceUrl?: string;
    public withCredentials: boolean;
    public integrationKeysMap: Record<string, string>;
    public stripePurchaseUrl?: string;
    public apps: EnvironmentApps;
    public loginTypes: string[];
    public defaultRedirectUri: string;
    public oktaDomains: {
        label: string;
        value: string;
    }[];
    public googleAnalytics: {
        enable: boolean;
        gaId: string;
    };
    public mixPanel: {
        enable: boolean;
        mpId: string;
    };
    public posthog: {
        enable: boolean;
        phId: string;
    };
    public datadog: {
        enable: boolean;
        applicationId: string;
        clientToken: string;
        site: string;
        service: string;
        sampleRate: number;
        sessionReplaySampleRate: number;
        trackInteractions: boolean;
        trackResources: boolean;
        trackLongTasks: boolean;
        defaultPrivacyLevel: string;
    };
    public sentry: {
        enable: boolean;
        dsn: string;
        exceptionHandler: boolean;
        browserTracing: boolean;
        tracesSampleRate: number;
        replaysSessionSampleRate: number;
        replaysOnErrorSampleRate: number;
    };
    public fullStory: {
        enable: boolean;
        orgId: string;
        debug: boolean;
        host: string;
        script: string;
        namespace: string;
    };
    public statusPage: {
        enable: boolean;
        script: string;
    };
    public intercom: {
        enable: boolean;
    };

    protected constructor() {
        this.config = globalThis.ENV_CONFIG;

        const environmentName = this.getEnvironmentName();
        this.selectEnvironment(environmentName);
    }

    public static instance = new Environment();

    /**
     * Gets the environment name to use. It checks if there is an environment in the local storage, or in the url. If
     * there is no env in both, it will fallback to the default environment.
     *
     * @returns the environment name.
     */
    private getEnvironmentName(): string {
        const availableEnvironments = Object.keys(this.config.environments);

        // If has an environment in the local storage, look for it's configuration JSON and set it as the current one.
        const localStorageEnvironment = localStorage.getItem(ENVIRONMENT_LOCAL_STORAGE_KEY);
        if (localStorageEnvironment) {
            const previousLocalStorageEnvironment = availableEnvironments.find(
                (env) => env === localStorageEnvironment,
            );
            if (previousLocalStorageEnvironment) {
                return previousLocalStorageEnvironment;
            }
        }

        // If one of the available environments are in the search param but has no value, use this environment and
        // store it in the localstorage.
        const searchParams = new URL(window.location.href).searchParams;
        const searchParamEnvironment = availableEnvironments.find(
            (env) => searchParams.has(env) && !searchParams.get(env),
        );
        if (searchParamEnvironment) {
            localStorage.setItem(ENVIRONMENT_LOCAL_STORAGE_KEY, searchParamEnvironment);
            return searchParamEnvironment;
        }

        return this.config.defaultEnvironment;
    }

    /**
     * Set the selected environment.
     *
     * @param environmentName - the name of the environment to be selected.
     */
    private selectEnvironment(environmentName: string): void {
        const selectedEnvironment = this.config.environments[environmentName];
        if (!selectedEnvironment) {
            throw new Error(`${environmentName} is not a valid environment name`);
        }

        this.name = environmentName;
        this.apiUrl = selectedEnvironment.apiUrl;
        this.appUrl = this.getAppUrl(selectedEnvironment);
        this.marketplaceUrl = selectedEnvironment.marketplaceUrl;
        this.isProd = selectedEnvironment.isProd ?? false;
        this.isLocal = selectedEnvironment.isLocal ?? false;
        this.withCredentials = selectedEnvironment.withCredentials ?? false;
        this.integrationKeysMap = this.getIntegrationKeysMap(selectedEnvironment);
        this.stripePurchaseUrl = selectedEnvironment.stripePurchaseUrl;
        this.loginTypes = selectedEnvironment.loginTypes;
        this.apps = selectedEnvironment.apps;
        this.oktaDomains = selectedEnvironment.oktaDomains;
        this.defaultRedirectUri = selectedEnvironment.defaultRedirectUri;

        // tracking and analytics tools, all disabled by default
        this.googleAnalytics = selectedEnvironment.googleAnalytics ?? { enable: false };
        this.mixPanel = selectedEnvironment.mixPanel ?? { enable: false };
        this.posthog = selectedEnvironment.posthog ?? { enable: false };
        this.datadog = selectedEnvironment.datadog ?? { enable: false };
        this.sentry = selectedEnvironment.sentry ?? { enable: false };
        this.fullStory = selectedEnvironment.fullStory ?? { enable: false };
        this.statusPage = selectedEnvironment.statusPage ?? { enable: false };
        this.intercom = selectedEnvironment.intercom ?? { enable: false };
    }

    /**
     * Join the default integration keys map with the environment specific one.
     *
     * @param environment - the environment to get it's specific integration keys map.
     * @returns a map with both the default and the environment specific keys. If have duplicates, the value in the
     * specific map is used.
     */
    private getIntegrationKeysMap(environment: SingleEnvironmentConfig): Record<string, string> {
        const defaultMap = this.config.integrationKeysMap.default;
        const environmentMap = environment.integrationKeysMap
            ? this.config.integrationKeysMap[environment.integrationKeysMap]
            : {};

        // The slack keys in the localstorage. Used in local.
        const slackKeysOverride = this.getSlackKeysToOverride(environment);

        return {
            ...defaultMap,
            ...environmentMap,
            ...slackKeysOverride,
        };
    }

    /**
     * Get an object that have the slack keys from the localstorage if they exist. This works only if the environment
     * is local. This is to be merged with the integration map.
     *
     * @param environment - the environment to get it's specific integration keys map.
     * @returns if it's local environment and the used have slack client id in the localstorage, a map with the slack
     * client id. Otherwise, an empty object.
     */
    private getSlackKeysToOverride(environment: SingleEnvironmentConfig): Record<string, string> {
        const slackClientId = localStorage.getItem(SLACK_CLIENT_ID_LOCAL_STORAGE_KEY);
        if (slackClientId && environment.isLocal) {
            const parsedClientId = JSON.parse(slackClientId);

            return {
                slack: parsedClientId,
                slackApp: parsedClientId,
            };
        }

        return {};
    }

    /**
     * Get app url. If it's a local environment, it will get the url from the location.
     *
     * @param environment - the environment to get it's specific integration keys map.
     * @returns the app url.
     */
    private getAppUrl(environment: SingleEnvironmentConfig) {
        if (environment.isLocal) {
            return `${window.location.origin}/`;
        }

        return environment.appUrl;
    }
}

export default Environment;

export const environment = Environment.instance;

angular.module('tonkean.app').value('environment', Environment.instance);
