import React from 'react';

import StandardInterfaceQuickNav from './StandardInterfaceQuickNav';
import TabsInterfaceQuickNav from './TabsInterfaceQuickNav';

import type { ItemInterfaceThemeTextAlignment } from '@tonkean/tonkean-entities';
import { InterfaceQuickNavigationMode, type WidgetBase } from '@tonkean/tonkean-entities';
import type { Color } from '@tonkean/tui-theme';

interface Props {
    quickNavigationMode?: InterfaceQuickNavigationMode;
    widgets: WidgetBase[];
    themeColor: Color;
    tabsViewEnabled: boolean;
    alignment: ItemInterfaceThemeTextAlignment;
}

const InterfaceQuickNav: React.FC<Props> = ({
    quickNavigationMode = InterfaceQuickNavigationMode.STANDARD,
    widgets,
    themeColor,
    tabsViewEnabled,
    alignment,
}) => {
    if (tabsViewEnabled && quickNavigationMode === InterfaceQuickNavigationMode.TABS) {
        return (
            <TabsInterfaceQuickNav
                data-automation="interface-quick-nav-tabs"
                widgets={widgets}
                themeColor={themeColor}
                alignment={alignment}
            />
        );
    } else {
        return <StandardInterfaceQuickNav widgets={widgets} alignment={alignment} />;
    }
};

export default InterfaceQuickNav;
