import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class AddTimeFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.ADD_TIME;
    override readonly sign = 'AddTime';
    override readonly displayName = 'Add Time';
    override readonly description = 'Will add unit of time (Days/Hours/Minutes) for a given date and time';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Time',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Amount Of Time',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Unit Of Time',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: ['Days', 'Hours', 'Minutes'],
        },
    ];
}
