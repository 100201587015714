import React from 'react';

import type { BaseDataSourceConnectionConfigurationProps } from './baseDataSourceConnectionConfigurationProps';
import type { AdditionalSidePaneConfiguration } from '../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import PredefinedConnections from '../PredefinedConnection/PredefinedConnections';

const DataSourceConnectionConnectionsTab: React.FC<
    AdditionalSidePaneConfiguration<BaseDataSourceConnectionConfigurationProps>
> = ({ setCurrentDataSourceConnection }) => {
    return <PredefinedConnections setCurrentDataSourceConnection={setCurrentDataSourceConnection} />;
};

export default DataSourceConnectionConnectionsTab;
