type FunctionController = (...args: any[]) => void | angular.IController;

function lateConstructController(controllerConstructor: FunctionController): angular.IControllerConstructor {
    // We use symbols instead of naming the methods because the same `this` will be used in the controller,
    // so we don't want to "stain" it.
    const injectionsSymbol = Symbol();
    const originalControllerInitiatedSymbol = Symbol();
    const initiateOriginalControllerSymbol = Symbol();

    const newController = class Controller implements angular.IController {
        private [injectionsSymbol]: any[] = [];
        private [originalControllerInitiatedSymbol]: boolean = false;

        constructor(...args: any[]) {
            this[injectionsSymbol] = args;
        }

        $onInit() {
            this[initiateOriginalControllerSymbol](() => {
                this.$onInit?.();
            });
        }

        $onChanges(onChangesObj: angular.IOnChangesObject) {
            this[initiateOriginalControllerSymbol](() => {
                this.$onChanges?.(onChangesObj);
            });
        }

        $onDestroy() {
            this[initiateOriginalControllerSymbol](() => {
                this.$onDestroy?.();
            });
        }

        $doCheck() {
            this[initiateOriginalControllerSymbol](() => {
                this.$doCheck?.();
            });
        }

        $postLink() {
            this[initiateOriginalControllerSymbol](() => {
                this.$postLink?.();
            });
        }

        private [initiateOriginalControllerSymbol](onInitiated: () => void) {
            if (!this[originalControllerInitiatedSymbol]) {
                controllerConstructor.call(this, ...this[injectionsSymbol]);
                this[originalControllerInitiatedSymbol] = true;

                onInitiated();
            }
        }
    };

    newController.$inject = controllerConstructor.$inject;

    return newController;
}

export default lateConstructController;
