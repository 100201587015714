export enum ServicenowAuthType {
    BASIC = 'basicAuth',
    OAUTH = 'OAuth',
}

export const authTypeToDisplayName: Record<ServicenowAuthType, string> = {
    [ServicenowAuthType.BASIC]: 'Basic Authentication',
    [ServicenowAuthType.OAUTH]: 'OAuth (Custom App)',
};
export default ServicenowAuthType;
