import ItemInterfaceRichTextWidgetConfigurationEditor from './components/ItemInterfaceRichTextWidgetConfigurationEditor';
import RichTextWidget from './components/RichTextWidget';
import type RichTextWidgetConfiguration from './RichTextWidgetConfiguration';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

const RichTextWidgetPackage: ItemInterfaceWidgetPackage<RichTextWidgetConfiguration> = {
    displayComponent: RichTextWidget,
    configurationComponent: ItemInterfaceRichTextWidgetConfigurationEditor,
};

export default RichTextWidgetPackage;
