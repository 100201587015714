import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as CompletedStatusIcon } from './assets/completedStatusIcon.svg';
import { ReactComponent as InProgressStatusIcon } from './assets/inProgressStatusIcon.svg';
import { ReactComponent as NotStartedStatusIcon } from './assets/notStartedStatusIcon.svg';
import type SelectedStatusWidgetType from './SelectedStatusWidgetType';
import type StatusProgressBarWidgetConfiguration from './StatusProgressBarWidgetConfiguration';

import { TextEllipsis } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import type { Initiative } from '@tonkean/tonkean-entities';
import { getProjectStates } from '@tonkean/tonkean-utils';
import { Theme } from '@tonkean/tui-theme';

const ProgressBarWrapper = styled.div`
    display: flex;
    justify-content: stretch;
    align-items: center;
    overflow: auto;
`;

const StatusBoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 0;
`;

const StatusBoxText = styled.div`
    padding-top: 10px;
    padding-inline: 10px;
    display: flex;
    justify-content: center;
`;

const TextEllipsisStyled = styled(TextEllipsis)<{ color?: string }>`
    max-width: 130px;
    margin-left: -8px;
    color: ${({ color }) => color || ''};
`;

const StyledIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-grow: 1;

    ::before,
    ::after {
        content: '';
        position: absolute;
        top: 50%;
        height: 1px;
        background: ${Theme.colors.gray_400};
        display: block;
        z-index: 1;
    }

    ::before {
        left: 0;
        right: 50%;
    }
    ::after {
        left: 50%;
        right: 0;
    }

    ${StatusBoxWrapper}:first-child &::before {
        display: none;
    }

    ${StatusBoxWrapper}:last-child &::after {
        display: none;
    }
`;

const StatusIconStyled = styled.svg`
    z-index: 2;
`;

const InProgressIconStyled = styled(InProgressStatusIcon)<{ color: string }>`
    circle:first-child {
        stroke: ${({ color }) => color};
    }
    circle:last-child {
        fill: ${({ color }) => color};
    }
`;

const StyledLi = styled.li<{ color: string }>`
    color: ${({ color }) => color};
`;

interface StatusBoxComponentProps {
    status: SelectedStatusWidgetType;
    statusIndex: number;
    currentStatusIndex: number;
}

const StatusBoxComponent: React.FC<StatusBoxComponentProps> = ({ status, statusIndex, currentStatusIndex }) => {
    const statusLable = status.overrideLable || status.label;
    let StatusIcon;
    let isCurrentStatusSelected = false;
    if (statusIndex === currentStatusIndex) {
        StatusIcon = InProgressIconStyled;
        isCurrentStatusSelected = true;
    } else if (statusIndex > currentStatusIndex) {
        StatusIcon = NotStartedStatusIcon;
    } else {
        StatusIcon = CompletedStatusIcon;
    }

    return (
        <StatusBoxWrapper data-automation="status-progress-bar-component-box-wrapper">
            <StyledIconWrapper>
                <StatusIconStyled
                    data-automation="status-progress-bar-component-status-icon"
                    color={status.color}
                    as={StatusIcon}
                />
            </StyledIconWrapper>
            <StatusBoxText>
                <StyledLi data-automation="status-progress-bar-component-status-color" color={status.color} />
                <TextEllipsisStyled
                    dataAutomation="status-progress-bar-component-status-text"
                    color={isCurrentStatusSelected ? status.color : undefined}
                    tooltipContentOverride={statusLable}
                    numberOfLines={1}
                    tooltip
                >
                    {statusLable}
                </TextEllipsisStyled>
            </StatusBoxText>
        </StatusBoxWrapper>
    );
};

interface ProgressBarProps {
    configuration: StatusProgressBarWidgetConfiguration;
    initiative?: Initiative;
}

const findClosestPreviousItem = (
    allStatuses: SelectedStatusWidgetType[],
    selectedStatuses: string[] | undefined,
    selectedStatus: string,
) => {
    const selectedStatusIndexInAllList = allStatuses.findIndex((item) => item.id === selectedStatus);

    const filteredStatuses = selectedStatuses?.filter((currentStatus, index) => {
        const currentStatusIndexInAllList = allStatuses.findIndex((status) => status.id === currentStatus);
        return currentStatusIndexInAllList !== -1 && currentStatusIndexInAllList < selectedStatusIndexInAllList;
    });

    return filteredStatuses?.pop() || null;
};

const StatusProgressBarComponent: React.FC<ProgressBarProps> = ({ initiative, configuration }) => {
    const projectManager = useAngularService('projectManager');
    const { workflowVersion } = useItemInterfaceContext();

    const allStatuses: SelectedStatusWidgetType[] = useMemo(() => {
        if (!workflowVersion) {
            return [];
        }

        return getProjectStates(workflowVersion, projectManager.project).map(
            (status) =>
                ({
                    ...status,
                    overrideLable: configuration.overrideLables?.[status.id],
                }) as SelectedStatusWidgetType,
        );
    }, [projectManager.project, workflowVersion, configuration.overrideLables]);

    const filteredStatuses: SelectedStatusWidgetType[] = useMemo(() => {
        return allStatuses.filter((status) => configuration.selectedStatuses?.includes(status.id));
    }, [allStatuses, configuration.selectedStatuses]);

    const statusToDisplayIndex = useMemo(() => {
        if (!initiative?.status) return 0;

        const currentStatus: SelectedStatusWidgetType | undefined = allStatuses.find((status) => {
            return status.label === initiative?.stateText;
        });

        const currentStatusId = currentStatus?.id || '';

        if (configuration.selectedStatuses?.includes(currentStatusId)) {
            return filteredStatuses.map((status) => status.id).indexOf(currentStatusId);
        } else {
            const currentStatusIndex = allStatuses.map((status) => status.id).indexOf(currentStatusId);
            if (currentStatusIndex === -1) return 0;
            const statusToDisplay = findClosestPreviousItem(
                allStatuses,
                configuration.selectedStatuses,
                currentStatusId,
            );

            return filteredStatuses.map((status) => status.id).indexOf(statusToDisplay || '');
        }
    }, [allStatuses, configuration.selectedStatuses, filteredStatuses, initiative]);

    return (
        <ProgressBarWrapper>
            {filteredStatuses.map((status, index) => (
                <StatusBoxComponent
                    key={status.id}
                    statusIndex={index}
                    status={status}
                    currentStatusIndex={statusToDisplayIndex || 0}
                />
            ))}
        </ProgressBarWrapper>
    );
};

export default StatusProgressBarComponent;
