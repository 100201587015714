import { reactToAngular } from 'angulareact';

import { ContractSelector } from '@tonkean/contracts';

export default angular
    .module('tonkean.app')
    .component(
        'tnkContractSelector',
        reactToAngular(ContractSelector, ['contracts', 'loading', 'selectedContract', 'onChange', 'disabled']),
    );
