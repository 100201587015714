import type WorkerRunLogicInspectTabData from './WorkerRunLogicInspectTabData';
import SingleWorkerRunActionInspectFields from '../components/SingleWorkerRun/SingleWorkerRunActionInspect/SingleWorkerRunActionInspectFields';
import SingleWorkerRunActionInspectManualNlpAction from '../components/SingleWorkerRun/SingleWorkerRunActionInspect/SingleWorkerRunActionInspectManualNlpAction';
import SingleWorkerRunActionInspectPeopleAction from '../components/SingleWorkerRun/SingleWorkerRunActionInspect/SingleWorkerRunActionInspectPeopleAction';
import SingleWorkerRunActionInspectRequestJson from '../components/SingleWorkerRun/SingleWorkerRunActionInspect/SingleWorkerRunActionInspectRequestJson';
import SingleWorkerRunActionInspectResponseJson from '../components/SingleWorkerRun/SingleWorkerRunActionInspect/SingleWorkerRunActionInspectResponseJson';
import SingleWorkerRunActionInspectSendNotification from '../components/SingleWorkerRun/SingleWorkerRunActionInspect/SingleWorkerRunActionInspectSendNotification';
import SingleWorkerRunActionInspectTrainingSetNlpAction from '../components/SingleWorkerRun/SingleWorkerRunActionInspect/SingleWorkerRunActionInspectTrainingSetNlpAction';

import { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';

const workerRunLogicInspectTabToComponent: {
    [K in WorkerRunLogicInspectTabType]: WorkerRunLogicInspectTabData<K>;
} = {
    [WorkerRunLogicInspectTabType.MANUAL_NLP]: {
        component: SingleWorkerRunActionInspectManualNlpAction,
        tabName: 'NLP Action',
        tabKey: 'manual-nlp',
    },
    [WorkerRunLogicInspectTabType.TRAINING_SET_NLP]: {
        component: SingleWorkerRunActionInspectTrainingSetNlpAction,
        tabName: 'NLP Action',
        tabKey: 'training-set-nlp',
    },
    [WorkerRunLogicInspectTabType.FIELDS]: {
        component: SingleWorkerRunActionInspectFields,
        tabName: 'Fields',
        tabKey: 'fields',
    },
    [WorkerRunLogicInspectTabType.REQUEST_JSON]: {
        component: SingleWorkerRunActionInspectRequestJson,
        tabName: 'Request',
        tabKey: 'request',
    },
    [WorkerRunLogicInspectTabType.RESPONSE_JSON]: {
        component: SingleWorkerRunActionInspectResponseJson,
        tabName: 'Response',
        tabKey: 'response',
    },
    [WorkerRunLogicInspectTabType.PEOPLE_DIRECTORY]: {
        component: SingleWorkerRunActionInspectPeopleAction,
        tabName: 'People Actions',
        tabKey: 'people-directory',
    },
    [WorkerRunLogicInspectTabType.SEND_NOTIFICATION]: {
        component: SingleWorkerRunActionInspectSendNotification,
        tabName: 'Notification Details',
        tabKey: 'send-notification',
    },
} as const;

export const tabKeyToWorkerRunTabType: Record<string, WorkerRunLogicInspectTabType> = Object.fromEntries(
    Object.entries(workerRunLogicInspectTabToComponent).map(
        ([tabType, tabData]) => [tabData.tabKey, tabType as WorkerRunLogicInspectTabType] as const,
    ),
);

export default workerRunLogicInspectTabToComponent;
