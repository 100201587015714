import type EnterpriseComponentPageExplorerItem from '../entities/EnterpriseComponentPageExplorerItem';
import type ProjectIntegrationPageExplorerSection from '../entities/ProjectIntegrationPageExplorerSection';

export function getEnterpriseComponentByIdsFromProjectIntegrationSections(
    sections: ProjectIntegrationPageExplorerSection[],
): Record<string, EnterpriseComponentPageExplorerItem> {
    const entries = sections.flatMap(({ items }) => items.map((item) => [item.id, item] as const));

    return Object.fromEntries(entries);
}
