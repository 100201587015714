<div class="group-fields-metadata-table common-size-xxxxs">
    <span ng-if="!data.miniMode" class="flex">
        <tnk-outline-filter
                apply-filters="applyFilters(fieldTextFilter, fieldForDependencies)"
                field-definition-for-dependencies-options="data.columns.fieldDefinitions"
                selected-field-definition-for-dependencies="data.selectedFieldDefinitionForDependencies"
                entity-name="Fields"
        ></tnk-outline-filter>
        <a
                data-automation="tnk-group-fields-metadata-table-refresh-button"
                ng-if="data.workflowVersion.workflowVersionType === 'DRAFT'"
                ng-click="reloadFields(null, true)"
                class="margin-top-md"
        >
            <i class="fa fa-refresh"></i>
        </a>
    </span>
    <!-- field filter end -->

    <!-- Separator -->
    <hr class="margin-top-xxs margin-bottom-xs"/>

    <div class="padding-left padding-right">
        <div
                class="alert alert-info common-size-xxxxs padding-normal-sm pointer"
                ng-if="data.expandFieldGroup || (data.fieldFilterByName && data.fieldFilterByName.length)"
                ng-click="data.expandFieldGroup = null; data.fieldFilterByName = null; applyFilter(); reloadFields();"
        >
            <i class="fa fa-times"></i>
            <span>Clear filter</span>
        </div>

        <div>
            <!-- Tonkean Item -->
            <div
                    class="box-with-shadow margin-bottom"
                    ng-class="{ 'mod-clickable': data.miniMode }"
                    ng-hide="
                    data.expandFieldGroup &&
                    data.expandFieldGroup !== 'showYourFields' &&
                    data.expandFieldGroup !== 'showAggregationFields' &&
                    data.expandFieldGroup !== 'showBasicFields'
                "
            >
                <div class="flex padding-normal-sm" ng-click="data.miniMode ? showHideFields('showYourFields') : null">
                    <div class="margin-right">
                        <span class="svg-icon-sm mod-static relative common-color-grey">
                            <tnk-icon src="/images/icons/text-input.svg"></tnk-icon>
                        </span>
                    </div>
                    <div class="flex-grow">
                        <div class="flex-vmiddle mod-justify-space">
                            <div class="common-bold">Item Metadata Fields</div>
                        </div>

                        <div class="common-italic common-size-xxxxs" ng-if="!data.miniMode">
                            <span>Custom metadata you can use for every item that runs in this flow</span>
                            (
                            <a
                                    ng-click="$root.modal.openFieldInspectModal(null, 'TONKEAN', null, null, null, null, null, data.groupId)"
                            >
                                Preview
                            </a>
                            )
                        </div>
                    </div>
                    <div class="flex-self-center margin-left" ng-if="data.miniMode">
                        <i class="fa fa-chevron-right common-color-grey"></i>
                    </div>
                </div>

                <div ng-if="!data.miniMode">
                    <hr class="margin-none"/>

                    <div ng-if="data.shouldReloadFields">
                        <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v"></div>
                        <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v"></div>
                    </div>

                    <div ng-if="!data.shouldReloadFields" class="common-size-xxxxs">
                        <div class="pointer common-bold padding-normal-sm" ng-click="showHideFields('showBasicFields')">
                            Basic fields
                            <!-- Field counter -->
                            <!-- When there is filter -->
                            <span ng-if="!!data.selectedFieldDefinitionForDependencies || !!data.fieldFilterByName">
                                ({{ (data.filteredSpecialFields && data.filteredSpecialFields.length) || 0 }}/{{
                                (data.specialFields && data.specialFields.length) || 0
                                }})
                            </span>
                            <!-- When there is no filter -->
                            <span ng-if="!(!!data.selectedFieldDefinitionForDependencies || !!data.fieldFilterByName)">
                                ({{ (data.filteredSpecialFields && data.filteredSpecialFields.length) || 0 }})
                            </span>

                            <i
                                    class="margin-left"
                                    ng-class="!data.showBasicFields ? 'chevron-right' : 'chevron-bottom'"
                            ></i>
                        </div>

                        <!-- Special fields -->
                        <table class="table table-striped margin-bottom" ng-show="data.showBasicFields">
                            <tr ng-repeat="definition in data.filteredSpecialFields">
                                <td class="padding-normal-xs flex-vmiddle">
                                    <span class="margin-right opacity-80">
                                        <tnk-field-type-icon field-type="definition.fieldType"></tnk-field-type-icon>
                                    </span>
                                    <span class="common-break-long">
                                        <tnk-highlightable-text
                                                highlight-text="data.fieldFilterByName"
                                                data-automation="fields-metadata-table-special-fields-names"
                                                text="definition.name"
                                        ></tnk-highlightable-text>
                                    </span>
                                </td>
                                <td></td>
                            </tr>
                        </table>

                        <tnk-specific-group-fields-metadata-table
                                field-type="'Manual'"
                                on-field-selected="(onFieldSelected)"
                                reload-fields="(reloadFields)"
                                apply-trigger-dependencies-filter="(applyTriggerDependenciesFilter)"
                                field-editor-has-pending-changes="fieldEditorHasPendingChanges"
                                on-tab-selected="(onTabSelected)"
                                dropdown-field-opened="(dropdownFieldOpened)"
                                field-filter-by-name="data.fieldFilterByName"
                                selected-field-definition-for-dependencies="data.selectedFieldDefinitionForDependencies"
                                filtered-custom-fields="data.filteredCustomFields"
                                columns="data.columns"
                                show-your-fields="data.showYourFields"
                                show-fields="'showYourFields'"
                                can-modify-bot="data.canModifyBot"
                                cant-edit-in-production-environment-message="data.cantEditInProductionEnvironmentMessage"
                                group-id="data.groupId"
                                workflow-version="data.workflowVersion"
                                show-hide-fields="showHideFields"
                                is-draft="data.isDraft"
                                apply-field-dependencies-filter="(applyFieldDependenciesFilter)"
                                field-definition-dependencies-counter="data.fieldDefinitionDependenciesCounter"
                                trigger-dependencies-counter="data.triggerDependenciesCounter"
                                load-dependencies-dropdown-list="data.loadDependenciesDropdownList"
                                open-duplicate-field-modal="(openDuplicateFieldModal)"
                        ></tnk-specific-group-fields-metadata-table>

                        <tnk-specific-group-fields-metadata-table
                                field-type="'Aggregate'"
                                on-field-selected="(onFieldSelected)"
                                reload-fields="(reloadFields)"
                                apply-trigger-dependencies-filter="(applyTriggerDependenciesFilter)"
                                field-editor-has-pending-changes="fieldEditorHasPendingChanges"
                                on-tab-selected="(onTabSelected)"
                                dropdown-field-opened="(dropdownFieldOpened)"
                                field-filter-by-name="data.fieldFilterByName"
                                selected-field-definition-for-dependencies="data.selectedFieldDefinitionForDependencies"
                                filtered-custom-fields="data.filteredAggregateFields"
                                columns="data.columns"
                                show-your-fields="data.showAggregationFields"
                                show-fields="'showAggregationFields'"
                                can-modify-bot="data.canModifyBot"
                                cant-edit-in-production-environment-message="data.cantEditInProductionEnvironmentMessage"
                                group-id="data.groupId"
                                workflow-version="data.workflowVersion"
                                show-hide-fields="showHideFields"
                                is-draft="data.isDraft"
                                apply-field-dependencies-filter="(applyFieldDependenciesFilter)"
                                field-definition-dependencies-counter="data.fieldDefinitionDependenciesCounter"
                                trigger-dependencies-counter="data.triggerDependenciesCounter"
                                load-dependencies-dropdown-list="data.loadDependenciesDropdownList"
                                open-duplicate-field-modal="(openDuplicateFieldModal)"
                        ></tnk-specific-group-fields-metadata-table>
                    </div>
                </div>
            </div>

            <!-- Tonkean Formulas -->
            <div
                    class="box-with-shadow margin-bottom"
                    ng-class="{ 'mod-clickable': data.miniMode }"
                    ng-hide="data.expandFieldGroup && data.expandFieldGroup !== 'showFormulaFields'"
            >
                <div
                        class="flex padding-normal-sm"
                        ng-click="data.miniMode ? showHideFields('showFormulaFields') : null"
                >
                    <div class="margin-right">
                        <span class="svg-icon-sm svg-icon-nav mod-static relative common-color-grey">
                            <tnk-icon src="/images/icons/calculator.svg"></tnk-icon>
                        </span>
                    </div>
                    <div class="flex-grow">
                        <div class="flex-vmiddle mod-justify-space">
                            <div class="common-bold">Text Extraction & Manipulation</div>
                        </div>

                        <div class="common-italic common-size-xxxxs" ng-if="!data.miniMode">
                            Formulas fields can extract, format or manipulate data from any field(s)
                        </div>
                    </div>
                    <div class="flex-self-center margin-left" ng-if="data.miniMode">
                        <i class="fa fa-chevron-right common-color-grey"></i>
                    </div>
                </div>
                <div ng-if="!data.miniMode">
                    <hr class="margin-none"/>

                    <div ng-if="data.shouldReloadFields">
                        <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v"></div>
                    </div>

                    <div
                            class="padding-normal-sm-h margin-bottom margin-top flex-vmiddle"
                            ng-if="!data.shouldReloadFields"
                    >
                        <div
                                class="pointer flex-grow common-bold"
                                ng-click="showHideFields('showFormulaFields')"
                                data-automation="group-fields-metadata-open-formula-fields-group"
                        >
                            Formula fields
                            <!-- Field counter -->
                            <span
                                    ng-if="data.fieldFilterByName !== '' || !!data.selectedFieldDefinitionForDependencies"
                            >
                                ({{
                                (
                                        data.filteredCustomFields
                                        | filter
                                                : {
                                                    entityMappingKey: 'CUSTOM',
                                                    idRelationField: false,
                                                    type: 'TNK_COLUMN_FORMULA'
                                                }
                                ).length || '0'
                                }}/{{
                                (
                                        data.columns.fieldDefinitions
                                        | filter
                                                : {
                                                    entityMappingKey: 'CUSTOM',
                                                    idRelationField: false,
                                                    type: 'TNK_COLUMN_FORMULA'
                                                }
                                ).length || '0'
                                }})
                            </span>
                            <span ng-if="data.fieldFilterByName === '' && !data.selectedFieldDefinitionForDependencies">
                                ({{
                                (
                                        data.filteredCustomFields
                                        | filter
                                                : {
                                                    entityMappingKey: 'CUSTOM',
                                                    idRelationField: false,
                                                    type: 'TNK_COLUMN_FORMULA'
                                                }
                                ).length || '0'
                                }})
                            </span>

                            <span
                                    ng-if="
                                    (
                                        data.filteredCustomFields
                                        | filter
                                            : {
                                                  definitionSource: '!MANUAL',
                                                  type: 'TNK_COLUMN_FORMULATNK_COLUMN_FORMULA'
                                              }
                                    ).length > 0
                                "
                                    class="common-color-warning"
                            >
                                *
                            </span>
                            <i
                                    class="margin-left"
                                    ng-class="!data.showFormulaFields ? 'chevron-right' : 'chevron-bottom'"
                            ></i>
                        </div>
                        <button
                                data-ng-disabled="!data.isDraft"
                                data-automation="group-fields-metadata-table-add-formula-field"
                                ng-click="data.canModifyBot() && onFieldSelected(data.groupId, 'COLUMN', null, null, true, false, null, reloadFields, reloadFields, null, null, 'formula', null, null , null, null, null, null, data.workflowVersionId);"
                                class="btn-link"
                        >
                            <span
                                    tooltip-enable="!data.isDraft"
                                    uib-tooltip="{{ data.cantEditInProductionEnvironmentMessage }}"
                            >
                                + Add Field
                            </span>
                        </button>
                    </div>

                    <!-- Formulas fields -->
                    <table class="table table-striped margin-bottom" ng-show="data.showFormulaFields">
                        <tr
                                ng-repeat="definition in (data.filteredCustomFields | filter:{entityMappingKey: 'CUSTOM', idRelationField: false, type: 'TNK_COLUMN_FORMULA'}  | orderBy:['fieldGroupName', 'name'])"
                        >
                            <td class="padding-normal-xs flex-vmiddle">
                                <span class="margin-right">
                                    <span ng-if="definition.definitionSource === 'MANUAL'" class="opacity-80">
                                        <tnk-field-type-icon field-type="definition.fieldType"></tnk-field-type-icon>
                                    </span>
                                </span>
                                <span class="common-break-long"
                                      data-automation="custom-field-option-{{definition.name}}">
                                    <tnk-highlightable-text
                                            data-automation="group-fields-metadata-table-formula-field-option"
                                            highlight-text="data.fieldFilterByName"
                                            text="definition.name"
                                    ></tnk-highlightable-text>
                                </span>
                                <span
                                        ng-if="definition.type === 'TNK_COLUMN_FORMULA'"
                                        class="common-color-grey margin-left common-italic"
                                >
                                    (Formula)
                                </span>
                            </td>

                            <td class="text-right">
                                <span class="flex flex-vmiddle margin-top-minus-xxs float-options-right">
                                    <!-- Edit -->
                                    <a
                                            data-automation="group-field-meta-data-table-edit-button-{{ definition.name }}"
                                            class="margin-bottom-xxs"
                                            ng-click="onFieldSelected(data.groupId, definition.targetType, definition.type, definition.projectIntegration, false, false, definition, reloadFields, reloadFields, null, null, null, null, null, null, null, null, null, data.workflowVersionId);"
                                    >
                                        {{ data.workflowVersion.workflowVersionType === 'DRAFT' ? 'Edit' : 'View' }}
                                    </a>

                                    <!-- List options -->
                                    <div uib-dropdown dropdown-append-to-body>
                                        <!-- List options button -->

                                        <div
                                                class="
                                                svg-icon-smd
                                                margin-left-xs
                                                common-color-grey
                                                svg-icon-hover-primary svg-icon-align-text-base
                                                btn-on-hover
                                                pointer
                                                flex-no-shrink
                                            "
                                                uib-dropdown-toggle
                                                ng-click="dropdownFieldOpened(definition)"
                                        >
                                            <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                                        </div>

                                        <!-- List options menu -->
                                        <ul class="field-option-select" uib-dropdown-menu>
                                            <li>
                                                <div
                                                        ng-click="applyFieldDependenciesFilter(definition)"
                                                        class="flex-vmiddle margin-right"
                                                >
                                                    <tnk-icon
                                                            class="margin-top-xxs margin-right"
                                                            src="/images/icons/columns.svg"
                                                    ></tnk-icon>
                                                    <div class="common-size-xxxxs">Filter Dependent Fields</div>
                                                    <div
                                                            class="common-size-xxxs margin-left-xs"
                                                            ng-if="!data.loadDependenciesDropdownList"
                                                    >
                                                        ({{ data.fieldDefinitionDependenciesCounter }})
                                                    </div>
                                                    <i
                                                            ng-if="data.loadDependenciesDropdownList"
                                                            class="margin-left-xs loading-small"
                                                    ></i>
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                        ng-click="applyTriggerDependenciesFilter(definition)"
                                                        class="flex-vmiddle margin-right"
                                                >
                                                    <tnk-icon
                                                            class="margin-top-xxs margin-right margin-left-xxxs"
                                                            src="/images/icons/lightning.svg"
                                                    ></tnk-icon>
                                                    <div class="common-size-xxxxs margin-left-field-option">
                                                        Filter Dependent Triggers
                                                    </div>
                                                    <div
                                                            class="common-size-xxxs margin-left-xs"
                                                            ng-if="!data.loadDependenciesDropdownList"
                                                    >
                                                        ({{ data.triggerDependenciesCounter }})
                                                    </div>
                                                    <i
                                                            ng-if="data.loadDependenciesDropdownList"
                                                            class="margin-left-xs loading-small"
                                                    ></i>
                                                </div>
                                            </li>
                                            <li ng-if="data.workflowVersion.workflowVersionType === 'DRAFT'">
                                                <div
                                                        ng-click="openDuplicateFieldModal(definition)"
                                                        class="flex-vmiddle margin-right"
                                                >
                                                    <tnk-icon
                                                            class="margin-top-xxs margin-right"
                                                            src="/images/icons/duplicate-new.svg"
                                                    ></tnk-icon>
                                                    <div class="common-size-xxxxs">Duplicate Field</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <hr ng-hide="data.expandFieldGroup"/>

            <div ng-hide="data.expandFieldGroup && data.expandFieldGroup.indexOf('showDS') === -1">
                <!-- Title on mini mode -->
                <div
                        ng-if="data.miniMode"
                        ng-class="{ 'box-with-shadow padding-normal-sm': data.miniMode && data.hideMatchedEntities }"
                        ng-click="showHideMatchedEntities()"
                        class="margin-bottom pointer"
                >
                    <div class="flex common-size-xxxxs">
                        <div class="margin-right">
                            <span class="svg-icon-sm svg-icon-nav mod-static relative">
                                <tnk-icon src="/images/icons/columns.svg"></tnk-icon>
                            </span>
                        </div>
                        <div class="flex-grow common-bold common-color-dark-grey">
                            Matched Entities
                            <div
                                    class="common-color-link-blue common-size-xxxxxs margin-top-xs"
                                    ng-if="data.hideMatchedEntities"
                            >
                                View {{ data.columns.matchedEntitiesFieldDefinitionsRelatedMapping.length }}
                                entities
                            </div>
                        </div>
                        <i
                                class="common-color-grey margin-left fa flex-self-center"
                                ng-class="!data.hideMatchedEntities ? 'fa-chevron-down' : 'fa-chevron-right'"
                        ></i>
                    </div>
                </div>

                <!-- Title on regular mode -->
                <div ng-if="!data.miniMode" class="text-uppercase common-color-grey common-size-xxxxs margin-bottom">
                    Matched Entities:
                </div>
                <!-- Explanation -->
                <div
                        class="common-color-dark-grey common-size-xxxxs margin-bottom margin-top-xs"
                        ng-if="!data.miniMode"
                >
                    To enrich the monitored item with data from other data sources, create a new matched entity, and
                    define how they match.
                </div>
            </div>

            <div
                    ng-if="
                    data.shouldReloadFields &&
                    (!data.expandFieldGroup || data.expandFieldGroup.indexOf('showDS') !== -1)
                "
            >
                <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v padding-normal-lg"></div>
                <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v padding-normal-lg"></div>
                <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v padding-normal-lg"></div>
            </div>

            <!-- Repeat for every entity -->
            <div ng-if="!data.shouldReloadFields && (!data.miniMode || !data.hideMatchedEntities)">
                <div
                        class="box-with-shadow margin-bottom"
                        ng-repeat="defSource in data.columns.matchedEntitiesFieldDefinitionsRelatedMapping"
                        ng-class="{ 'mod-clickable': data.miniMode }"
                        ng-hide="data.expandFieldGroup && data.expandFieldGroup !== 'showDS' + defSource"
                >
                    <div
                            class="flex padding-normal-sm"
                            ng-init="loadDefinitionsSources(defSource)"
                            ng-click="data.miniMode ? showHideFields('showDS' + defSource) : null"
                    >
                        <div class="margin-right flex mod-align-center">
                            <!-- Integration Icon -->
                            <span
                                    ng-if="data.projectIntegrations[defSource] && !data.customTriggerToIcon[defSource]"
                                    class="initiative-integration-icon mod-dropdown matched-entity-icon"
                                    ng-class="
                                    !data.projectIntegrations[defSource].iconUrl
                                        ? 'mod-' +
                                          data.projectIntegrations[defSource].integration.integrationType.toLowerCase()
                                        : null
                                "
                                    ng-style="{
                                    background: data.projectIntegrations[defSource].iconUrl
                                        ? 'url(' + data.projectIntegrations[defSource].iconUrl + ') no-repeat center'
                                        : null
                                }"
                            ></span>

                            <!-- Custom Trigger Icon -->
                            <span
                                    ng-if="data.customTriggerToIcon[defSource] && !data.projectIntegrations[defSource]"
                                    class="logic-template-icon matched-entity-icon"
                                    ng-class="data.customTriggerToIcon[defSource]"
                            ></span>

                            <!-- Manual Hamburger -->
                            <i
                                    class="margin-right svg-icon"
                                    ng-if="!data.projectIntegrations[defSource] && !data.customTriggerToIcon[defSource]"
                            >
                                <tnk-icon src="/images/icons/bullet-list.svg"></tnk-icon>
                            </i>
                        </div>
                        <div class="flex-vmiddle mod-justify-space flex-grow">
                            <!-- Content of the titles -->
                            <div>
                                <div data-automation="tnk-matched-entity-group-field-metadata-table-title-{{ getMatchedEntityFieldTitle(defSource) }}"
                                     class="common-bold common-break-long">
                                    <!--Entity Name-->
                                    <span>
                                      {{ getMatchedEntityFieldTitle(defSource) }}
                                  </span>
                                </div>

                                <!--Subtitle-->
                                <div class="common-italic common-size-xxxxs common-break-long">
                                    <span class="common-break-long" ng-if="data.projectIntegrations[defSource]">
                                        {{ data.projectIntegrations[defSource].displayNameFull }} /
                                    </span>

                                    <!--Entity name-->
                                    <span ng-if="isRootMatchedEntity(defSource)">
                                        {{
                                        scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata
                                                .pluralLabel ||
                                        scm.getSyncConfig(data.workflowVersionId).viewData['Entity'] ||
                                        utils.pluralize(
                                                scm.getSyncConfig(data.workflowVersionId).viewData['entity']
                                        )
                                        }}
                                        (Intake Source)
                                    </span>
                                    <span ng-if="isMatchedEntityFromCustomTrigger(defSource)">
                                        {{
                                        data.actionsByIntegrationsMap[
                                                ctm.getCachedCustomTrigger(data.workflowVersionId, defSource)
                                                        .customTriggerActions[0].customTriggerActionDefinition
                                                        .integrationType
                                                ].customActions[
                                                ctm.getCachedCustomTrigger(data.workflowVersionId, defSource)
                                                        .customTriggerActions[0].customTriggerActionDefinition
                                                        .performedActionDefinition.customActionKey
                                                ].entity.displayName ||
                                        ctm.getCachedCustomTrigger(data.workflowVersionId, defSource)
                                                .customTriggerActions[0].customTriggerActionDefinition
                                                .performOnEntity.displayName ||
                                        (ctm.getCachedCustomTrigger(data.workflowVersionId, defSource)
                                                        .customTriggerActions[0].customTriggerActionDefinition
                                                        .dataStorageId &&
                                                'File')
                                        }}
                                    </span>
                                    <span ng-if="isManualMatchedEntity(defSource)">
                                        {{ getManualMatchedEntityName(data, defSource) }}
                                    </span>
                                </div>
                            </div>

                            <!-- Action dropdown -->
                            <div
                                    uib-dropdown
                                    dropdown-append-to-body
                                    class=""
                                    ng-if="isManualMatchedEntity(defSource) && !data.miniMode"
                            >
                                <!-- Three dots -->
                                <div
                                        class="
                                        svg-icon-smd
                                        common-color-grey
                                        svg-icon-hover-primary svg-icon-align-text-base
                                        btn-on-hover
                                        pointer
                                        flex-no-shrink
                                    "
                                        uib-dropdown-toggle
                                        ng-click="dropdownFieldOpened(data.columns.fieldDefinitionsMap[defSource])"
                                >
                                    <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                                </div>

                                <!-- Menu -->
                                <ul class="field-option-select" uib-dropdown-menu>
                                    <!-- Make inline -->
                                    <li
                                            class="common-size-xxxxs margin-left-xxs"
                                            ng-if="isManualMatchedEntity(defSource)"
                                    >
                                        <!-- Edit -->
                                        <div class="padding-top-xxs padding-bottom-xxs">
                                            <div
                                                    ng-click="onFieldSelected(data.groupId, 'COLUMN', data.columns.fieldDefinitionsMap[defSource].type, data.columns.fieldDefinitionsMap[defSource].projectIntegration, false, false, data.columns.fieldDefinitionsMap[defSource], null, null, null, null, null, null, null, null, null, null, true, data.workflowVersionId)"
                                            >
                                                <!-- Pencil icon -->
                                                <i class="fa fa-pencil margin-right"></i>

                                                {{
                                                data.workflowVersion.workflowVersionType === 'DRAFT'
                                                ? 'Edit'
                                                : 'View'
                                                }}
                                                Matched By Field
                                            </div>
                                        </div>
                                    </li>

                                    <!-- Remove -->
                                    <li
                                            class="common-size-xxxxs margin-left-xxs"
                                            ng-if="data.workflowVersion.workflowVersionType === 'DRAFT'"
                                    >
                                        <div class="padding-top-xxs padding-bottom-xxs">
                                            <div
                                                    ng-click="data.canModifyBot() && deleteFieldDefinition(data.columns.fieldDefinitionsMap[defSource])"
                                            >
                                                <!-- X icon -->
                                                <i class="fa fa-times margin-right"></i>
                                                Delete
                                            </div>
                                        </div>
                                    </li>
                                    <!-- Filter Dependent Fields -->
                                    <li>
                                        <div
                                                class="flex-vmiddle"
                                                ng-click="applyFieldDependenciesFilter(data.columns.fieldDefinitionsMap[defSource])"
                                        >
                                            <!-- Pencil icon -->
                                            <tnk-icon
                                                    class="margin-top-xxs margin-right"
                                                    src="/images/icons/columns.svg"
                                            ></tnk-icon>
                                            <div class="common-size-xxxxs">Filter Dependent Fields</div>

                                            <div
                                                    class="common-size-xxxs margin-left-xs"
                                                    ng-if="!data.loadDependenciesDropdownList"
                                            >
                                                ({{ data.fieldDefinitionDependenciesCounter }})
                                            </div>
                                            <i
                                                    ng-if="data.loadDependenciesDropdownList"
                                                    class="margin-left-xs loading-small"
                                            ></i>
                                        </div>
                                    </li>

                                    <!-- Filter Dependent Triggers -->
                                    <li>
                                        <div
                                                ng-click="applyTriggerDependenciesFilter(data.columns.fieldDefinitionsMap[defSource])"
                                                class="flex-vmiddle margin-right"
                                        >
                                            <tnk-icon
                                                    class="margin-top-xxs margin-right margin-left-xxxs"
                                                    src="/images/icons/lightning.svg"
                                            ></tnk-icon>
                                            <div class="common-size-xxxxs margin-left-field-option">
                                                Filter Dependent Triggers
                                            </div>
                                            <div
                                                    class="common-size-xxxs margin-left-xs"
                                                    ng-if="!data.loadDependenciesDropdownList"
                                            >
                                                ({{ data.triggerDependenciesCounter }})
                                            </div>
                                            <i
                                                    ng-if="data.loadDependenciesDropdownList"
                                                    class="margin-left-xs loading-small"
                                            ></i>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="flex-self-center margin-left" ng-if="data.miniMode">
                            <i class="fa fa-chevron-right common-color-grey"></i>
                        </div>
                    </div>

                    <div ng-if="!data.miniMode">
                        <hr class="margin-none"/>

                        <div class="flex-vmiddle mod-justify-space">
                            <div
                                    class="pointer common-bold padding-normal-sm common-size-xxxxs"
                                    ng-click="showHideFields('showDS' + defSource)"
                                    data-automation="group-fields-metadata-open-matched-entity-list"
                            >
                                Fields
                                <!-- Field counter -->
                                <span
                                        ng-if="
                                        data.fieldFilterByName !== '' || !!data.selectedFieldDefinitionForDependencies
                                    "
                                >
                                    ({{
                                    (
                                            data.filteredCustomFields
                                            | filter: filterRelatedFieldsByMatchedEntity(defSource)
                                    ).length || '0'
                                    }}/{{
                                    (
                                            data.columns.fieldDefinitions
                                            | filter: filterRelatedFieldsByMatchedEntity(defSource)
                                    ).length || '0'
                                    }})
                                </span>
                                <span
                                        ng-if="
                                        !(
                                            data.fieldFilterByName !== '' ||
                                            !!data.selectedFieldDefinitionForDependencies
                                        )
                                    "
                                >
                                    ({{
                                    (
                                            data.filteredCustomFields
                                            | filter: filterRelatedFieldsByMatchedEntity(defSource)
                                    ).length || '0'
                                    }})
                                </span>
                                <i
                                        class="margin-left"
                                        ng-class="!data['showDS' + defSource] ? 'chevron-right' : 'chevron-bottom'"
                                ></i>
                            </div>

                            <button
                                    data-ng-disabled="!data.isDraft"
                                    ng-if="data.isMatchedEntityMutableMap[defSource]"
                                    ng-click="data.canModifyBot() && addFieldByRelatedEntity(data.projectIntegrations[defSource], defSource)"
                                    class="margin-right btn-link"
                                    data-automation="tnk-group-fields-metadata-table-template-add-field-{{ getMatchedEntityFieldTitle(defSource) }}"
                            >
                                <span
                                        tooltip-enable="!data.isDraft"
                                        uib-tooltip="{{ data.cantEditInProductionEnvironmentMessage }}"
                                >
                                    + Add Field
                                </span>
                            </button>
                        </div>

                        <!-- Special fields -->
                        <table class="table table-striped margin-bottom" ng-show="data['showDS' + defSource]">
                            <tr
                                    ng-repeat="definition in (data.filteredCustomFields | filter:filterRelatedFieldsByMatchedEntity(defSource) | orderBy:'name')"
                            >
                                <td
                                        ng-init="loadDefinitionsSources(definition.id)"
                                        class="padding-normal-xs flex-vmiddle"
                                >
                                    <!-- Integration Icon -->
                                    <span
                                            class="margin-right"
                                            ng-if="
                                            data.projectIntegrations[definition.id] &&
                                            !data.customTriggerToIcon[defSource]
                                        "
                                    >
                                        <i
                                                class="initiative-integration-icon mod-dropdown matched-entity-icon"
                                                ng-class="
                                                data.projectIntegrations[definition.id] &&
                                                !data.projectIntegrations[definition.id].iconUrl
                                                    ? 'mod-' +
                                                      data.projectIntegrations[
                                                          definition.id
                                                      ].integration.integrationType.toLowerCase()
                                                    : null
                                            "
                                                ng-style="{
                                                background:
                                                    data.projectIntegrations[definition.id] &&
                                                    data.projectIntegrations[definition.id].iconUrl
                                                        ? 'url(' +
                                                          data.projectIntegrations[definition.id].iconUrl +
                                                          ') no-repeat center'
                                                        : null
                                            }"
                                        ></i>
                                    </span>

                                    <!-- Custom Trigger Icon -->
                                    <span class="margin-right" ng-if="data.customTriggerToIcon[defSource]">
                                        <i
                                                class="logic-template-icon matched-entity-icon"
                                                ng-class="data.customTriggerToIcon[defSource]"
                                        ></i>
                                    </span>

                                    <!-- Field Name -->
                                    <span class="common-break-long">
                                        <tnk-highlightable-text
                                                data-automation="group-fields-metadata-table-matched-entity-names"
                                                highlight-text="data.fieldFilterByName"
                                                text="definition.name"
                                        ></tnk-highlightable-text>
                                    </span>
                                </td>

                                <!-- Field Actions -->
                                <td class="text-right">
                                    <div class="flex flex-vmiddle margin-top-minus-xxs float-options-right">
                                        <!-- Edit button -->
                                        <a
                                                class="margin-bottom-xxs"
                                                ng-if="
                                                data.isMatchedEntityMutableMap[defSource] &&
                                                isFieldEditable(defSource, definition)
                                            "
                                                ng-click="onFieldSelected(data.groupId, definition.targetType, definition.type, definition.projectIntegration, false, false, definition, reloadFields, reloadFields, null, null, null, null, null, null, null, null, null, data.workflowVersionId);"
                                        >
                                            {{ data.workflowVersion.workflowVersionType === 'DRAFT' ? 'Edit' : 'View' }}
                                        </a>

                                        <!-- Three Dots Button -->
                                        <div uib-dropdown dropdown-append-to-body>
                                            <div
                                                    class="
                                                    svg-icon-smd
                                                    margin-left-xs
                                                    common-color-grey
                                                    svg-icon-hover-primary svg-icon-align-text-base
                                                    btn-on-hover
                                                    pointer
                                                    flex-no-shrink
                                                "
                                                    uib-dropdown-toggle
                                                    ng-click="dropdownFieldOpened(definition)"
                                            >
                                                <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                                            </div>

                                            <!-- three dots button dropdown -->
                                            <ul class="field-option-select" uib-dropdown-menu>
                                                <li>
                                                    <!-- Field definition dependencies -->
                                                    <div
                                                            ng-click="applyFieldDependenciesFilter(definition)"
                                                            class="flex-vmiddle margin-right"
                                                    >
                                                        <tnk-icon
                                                                class="margin-top-xxs margin-right"
                                                                src="/images/icons/columns.svg"
                                                        ></tnk-icon>
                                                        <div class="common-size-xxxxs">Filter Dependent Fields</div>
                                                        <div
                                                                class="common-size-xxxs margin-left-xs"
                                                                ng-if="!data.loadDependenciesDropdownList"
                                                        >
                                                            ({{ data.fieldDefinitionDependenciesCounter }})
                                                        </div>
                                                        <i
                                                                ng-if="data.loadDependenciesDropdownList"
                                                                class="margin-left-xs loading-small"
                                                        ></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <!-- Custom trigger dependencies -->
                                                    <div
                                                            ng-click="applyTriggerDependenciesFilter(definition)"
                                                            class="flex-vmiddle margin-right"
                                                    >
                                                        <tnk-icon
                                                                class="margin-top-xxs margin-right margin-left-xxxs"
                                                                src="/images/icons/lightning.svg"
                                                        ></tnk-icon>
                                                        <div class="common-size-xxxxs margin-left-field-option">
                                                            Filter Dependent Triggers
                                                        </div>
                                                        <div
                                                                class="common-size-xxxs margin-left-xs"
                                                                ng-if="!data.loadDependenciesDropdownList"
                                                        >
                                                            ({{ data.triggerDependenciesCounter }})
                                                        </div>
                                                        <i
                                                                ng-if="data.loadDependenciesDropdownList"
                                                                class="margin-left-xs loading-small"
                                                        ></i>
                                                    </div>
                                                </li>
                                                <li
                                                        ng-if="
                                                        data.isMatchedEntityMutableMap[defSource] &&
                                                        isFieldEditable(defSource, definition) &&
                                                        data.workflowVersion.workflowVersionType === 'DRAFT'
                                                    "
                                                >
                                                    <div
                                                            ng-click="openDuplicateFieldModal(definition)"
                                                            class="flex-vmiddle margin-right"
                                                    >
                                                        <tnk-icon
                                                                class="margin-top-xxs margin-right"
                                                                src="/images/icons/duplicate-new.svg"
                                                        ></tnk-icon>
                                                        <div class="common-size-xxxxs">Duplicate Field</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="margin-top-lg" ng-hide="data.expandFieldGroup && data.expandFieldGroup.indexOf('showDS') === -1">
            <button
                    data-ng-disabled="!data.isDraft"
                    data-automation="group-fields-metadata-table-open-add-match-entity-frame"
                    ng-click="data.canModifyBot() && onFieldSelected(data.groupId, 'COLUMN', null, null, true, false, null, reloadFields, reloadFields, null, true, false, null, true, false, true, false, true, data.workflowVersionId,true);"
                    class="margin-right btn-link"
            >
                <span
                        tooltip-enable="!data.isDraft"
                        uib-tooltip="{{ data.cantEditInProductionEnvironmentMessage }}"
                        tooltip-append-to-body="true"
                >
                    + Add Matched Entity
                </span>
            </button>
        </div>

        <div
                class="margin-top"
                ng-hide="data.expandFieldGroup && data.expandFieldGroup.indexOf('showMI') === -1"
        >
            <!-- Title on mini mode -->
            <div
                    ng-if="data.miniMode"
                    ng-class="{ 'box-with-shadow padding-normal-sm': data.miniMode && data.hideMatchedItems }"
                    ng-click="showHideMatchedItems()"
                    class="margin-bottom pointer"
            >
                <div class="flex common-size-xxxxs">
                    <div class="margin-right">
                        <span class="svg-icon-sm svg-icon-nav mod-static relative">
                            <tnk-icon src="/images/icons/columns.svg"></tnk-icon>
                        </span>
                    </div>
                    <div class="flex-grow common-bold common-color-dark-grey">
                        Matched Items
                        <div
                                class="common-color-link-blue common-size-xxxxxs margin-top-xs"
                                ng-if="data.hideMatchedItems"
                        >
                            View {{ data.columns.matchedItemsFieldDefinitionsRelatedMapping.length }}
                            matched items
                        </div>
                    </div>
                    <i
                            class="common-color-grey margin-left fa flex-self-center"
                            ng-class="!data.hideMatchedItems ? 'fa-chevron-down' : 'fa-chevron-right'"
                    ></i>
                </div>
            </div>

            <!-- Title on regular mode -->
            <div ng-if="!data.miniMode" class="text-uppercase common-color-grey common-size-xxxxs margin-bottom">
                <hr/>
                MATCHED ITEMS:
            </div>
            <!-- Explanation -->
            <div class="common-color-dark-grey common-size-xxxxs margin-bottom margin-top-xs" ng-if="!data.miniMode">
                Extend item fields with dynamic data from other modules to monitor the holistic state of a solution and
                easily orchestrate the interaction between modules. Matched items are added automatically when you
                create an item using the module action.
                <span>
                    <a href="https://docs.tonkean.com/en/design---customize/customize-module-workflows/set-up-actions/workflow/module-actions.html#add-item-to-module"
                       target="_blank">Learn more</a>
                </span>
            </div>
        </div>

        <div
                ng-if="
                data.shouldReloadFields && (!data.expandFieldGroup || data.expandFieldGroup.indexOf('showMI') !== -1)
            "
        >
            <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v padding-normal-lg"></div>
            <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v padding-normal-lg"></div>
            <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v padding-normal-lg"></div>
        </div>

        <!-- Repeat for every entity -->
        <div
                ng-if="!data.shouldReloadFields && (!data.miniMode || !data.hideMatchedItems)"
        >
            <div
                    class="box-with-shadow margin-bottom"
                    ng-repeat="defSource in data.columns.matchedItemsFieldDefinitionsRelatedMapping | filter:'!CUSTOM'"
                    ng-class="{ 'mod-clickable': data.miniMode }"
                    ng-hide="data.expandFieldGroup && data.expandFieldGroup !== 'showMI' + defSource"
            >
                <div
                        class="flex padding-normal-sm"
                        ng-init="loadDefinitionsSources(defSource)"
                        ng-click="data.miniMode ? showHideFields('showMI' + defSource) : null"
                >
                    <div class="margin-right flex mod-align-center">
                        <!-- Integration Icon -->
                        <span
                                ng-if="data.projectIntegrations[defSource] && !data.customTriggerToIcon[defSource]"
                                class="initiative-integration-icon mod-dropdown matched-entity-icon"
                                ng-class="
                                !data.projectIntegrations[defSource].iconUrl
                                    ? 'mod-' +
                                      data.projectIntegrations[defSource].integration.integrationType.toLowerCase()
                                    : null
                            "
                                ng-style="{
                                background: data.projectIntegrations[defSource].iconUrl
                                    ? 'url(' + data.projectIntegrations[defSource].iconUrl + ') no-repeat center'
                                    : null
                            }"
                        ></span>

                        <!-- Custom Trigger Icon -->
                        <span
                                ng-if="data.customTriggerToIcon[defSource]"
                                class="logic-template-icon matched-entity-icon"
                                ng-class="data.customTriggerToIcon[defSource]"
                        ></span>

                        <!-- Manual Hamburger -->
                        <i
                                class="margin-right svg-icon"
                                ng-if="!data.projectIntegrations[defSource] && !data.customTriggerToIcon[defSource]"
                        >
                            <tnk-icon src="/images/icons/bullet-list.svg"></tnk-icon>
                        </i>
                    </div>
                    <div class="flex-vmiddle mod-justify-space flex-grow">
                        <!-- Content of the titles -->
                        <div>
                            <div data-automation="tnk-matched-item-group-field-metadata-table-title-{{ getMatchedItemsFieldTitle(defSource) }}"
                                 class="common-bold common-break-long">
                                <!--Entity Name-->
                                <span ng-if="isMatchedEntityFromCustomTrigger(defSource)">
                                    {{ getMatchedItemsFieldTitle(defSource) }}
                                </span>
                            </div>

                            <!--Subtitle-->
                            <div class="common-italic common-size-xxxxs common-break-long">
                                <span class="common-break-long" ng-if="data.projectIntegrations[defSource]">
                                    {{ getMatchedItemGroupByRelatedCustomTrigger(defSource).name }}
                                </span>

                                <!--Entity name-->
                                <span ng-if="isMatchedEntityFromCustomTrigger(defSource)">
                                    {{
                                    data.actionsByIntegrationsMap[
                                            ctm.getCachedCustomTrigger(data.workflowVersionId, defSource)
                                                    .customTriggerActions[0].customTriggerActionDefinition.integrationType
                                            ].customActions[
                                            ctm.getCachedCustomTrigger(data.workflowVersionId, defSource)
                                                    .customTriggerActions[0].customTriggerActionDefinition
                                                    .performedActionDefinition.customActionKey
                                            ].entity.displayName ||
                                    ctm.getCachedCustomTrigger(data.workflowVersionId, defSource)
                                            .customTriggerActions[0].customTriggerActionDefinition.performOnEntity
                                            .displayName ||
                                    (ctm.getCachedCustomTrigger(data.workflowVersionId, defSource)
                                                    .customTriggerActions[0].customTriggerActionDefinition.dataStorageId &&
                                            'File')
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="flex-self-center margin-left" ng-if="data.miniMode">
                        <i class="fa fa-chevron-right common-color-grey"></i>
                    </div>
                </div>

                <div ng-if="!data.miniMode">
                    <hr class="margin-none"/>

                    <div class="flex-vmiddle mod-justify-space">
                        <div
                                class="pointer common-bold padding-normal-sm common-size-xxxxs"
                                ng-click="showHideFields('showMI' + defSource)"
                                data-automation="group-fields-metadata-open-matched-entity-list"
                        >
                            Fields
                            <!-- Field counter -->
                            <span
                                    ng-if="data.fieldFilterByName !== '' || !!data.selectedFieldDefinitionForDependencies"
                            >
                                ({{
                                (data.filteredCustomFields | filter: filterRelatedFieldsByMatchedEntity(defSource))
                                        .length || '0'
                                }}/{{
                                (
                                        data.columns.fieldDefinitions
                                        | filter: filterRelatedFieldsByMatchedEntity(defSource)
                                ).length || '0'
                                }})
                            </span>
                            <span
                                    ng-if="
                                    !(data.fieldFilterByName !== '' || !!data.selectedFieldDefinitionForDependencies)
                                "
                            >
                                ({{
                                (data.filteredCustomFields | filter: filterRelatedFieldsByMatchedEntity(defSource))
                                        .length || '0'
                                }})
                            </span>
                            <i
                                    class="margin-left"
                                    ng-class="!data['showMI' + defSource] ? 'chevron-right' : 'chevron-bottom'"
                            ></i>
                        </div>

                        <button
                                data-ng-disabled="!data.isDraft"
                                ng-if="data.isMatchedEntityMutableMap[defSource] && !getContractOfCustomTrigger(defSource)"
                                ng-click="data.canModifyBot() && addFieldByRelatedEntity(data.projectIntegrations[defSource], defSource)"
                                class="margin-right btn-link"
                                data-automation="matched-items-add-field-button-{{getMatchedItemsFieldTitle(defSource)}}"
                        >
                            <span
                                    tooltip-enable="!data.isDraft"
                                    uib-tooltip="{{ data.cantEditInProductionEnvironmentMessage }}"
                            >
                                + Add Field
                            </span>
                        </button>

                        <button
                                data-ng-disabled="!data.isDraft"
                                ng-if="data.isMatchedEntityMutableMap[defSource] && !!getContractOfCustomTrigger(defSource)"
                                ng-click="data.canModifyBot() && openUpdateContractFieldsModal(defSource)"
                                class="margin-right btn-link"
                                data-automation="matched-items-contract-add-field-button-{{getMatchedItemsFieldTitle(defSource)}}"
                        >
                            <span
                                    tooltip-enable="!data.isDraft"
                                    uib-tooltip="{{ data.cantEditInProductionEnvironmentMessage }}"
                            >
                                + Add Field (Contract)
                            </span>
                        </button>


                    </div>

                    <!-- Special fields -->
                    <table class="table table-striped margin-bottom" ng-show="data['showMI' + defSource]">
                        <tr
                                ng-repeat="definition in (data.filteredCustomFields | filter:filterRelatedFieldsByMatchedEntity(defSource) | orderBy:'name')"
                        >
                            <td ng-init="loadDefinitionsSources(definition.id)" class="padding-normal-xs flex-vmiddle">
                                <!-- Integration Icon -->
                                <span
                                        class="margin-right"
                                        ng-if="
                                        data.projectIntegrations[definition.id] && !data.customTriggerToIcon[defSource]
                                    "
                                >
                                    <i
                                            class="initiative-integration-icon mod-dropdown matched-entity-icon"
                                            ng-class="
                                            data.projectIntegrations[definition.id] &&
                                            !data.projectIntegrations[definition.id].iconUrl
                                                ? 'mod-' +
                                                  data.projectIntegrations[
                                                      definition.id
                                                  ].integration.integrationType.toLowerCase()
                                                : null
                                        "
                                            ng-style="{
                                            background:
                                                data.projectIntegrations[definition.id] &&
                                                data.projectIntegrations[definition.id].iconUrl
                                                    ? 'url(' +
                                                      data.projectIntegrations[definition.id].iconUrl +
                                                      ') no-repeat center'
                                                    : null
                                        }"
                                    ></i>
                                </span>

                                <!-- Custom Trigger Icon -->
                                <span class="margin-right" ng-if="data.customTriggerToIcon[defSource]">
                                    <i
                                            class="logic-template-icon matched-entity-icon"
                                            ng-class="data.customTriggerToIcon[defSource]"
                                    ></i>
                                </span>

                                <!-- Field Name -->
                                <span class="common-break-long">
                                    <tnk-highlightable-text
                                            data-automation="group-fields-metadata-table-matched-entity-names"
                                            highlight-text="data.fieldFilterByName"
                                            text="definition.name"
                                    ></tnk-highlightable-text>
                                </span>
                            </td>

                            <!-- Field Actions -->
                            <td class="text-right">
                                <div class="flex flex-vmiddle margin-top-minus-xxs float-options-right">
                                    <!-- Edit button -->
                                    <a
                                            class="margin-bottom-xxs"
                                            ng-if="
                                            data.isMatchedEntityMutableMap[defSource] &&
                                            isFieldEditable(defSource, definition)
                                        "
                                            ng-click="onFieldSelected(data.groupId, definition.targetType, definition.type, definition.projectIntegration, false, false, definition, reloadFields, reloadFields, null, null, null, null, null, null, null, null, null, data.workflowVersionId);"
                                    >
                                        {{ data.workflowVersion.workflowVersionType === 'DRAFT' ? 'Edit' : 'View' }}
                                    </a>

                                    <!-- Three Dots Button -->
                                    <div uib-dropdown dropdown-append-to-body>
                                        <div
                                                class="
                                                svg-icon-smd
                                                margin-left-xs
                                                common-color-grey
                                                svg-icon-hover-primary svg-icon-align-text-base
                                                btn-on-hover
                                                pointer
                                                flex-no-shrink
                                            "
                                                uib-dropdown-toggle
                                                ng-click="dropdownFieldOpened(definition)"
                                        >
                                            <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                                        </div>

                                        <!-- three dots button dropdown -->
                                        <ul class="field-option-select" uib-dropdown-menu>
                                            <li>
                                                <!-- Field definition dependencies -->
                                                <div
                                                        ng-click="applyFieldDependenciesFilter(definition)"
                                                        class="flex-vmiddle margin-right"
                                                >
                                                    <tnk-icon
                                                            class="margin-top-xxs margin-right"
                                                            src="/images/icons/columns.svg"
                                                    ></tnk-icon>
                                                    <div class="common-size-xxxxs">Filter Dependent Fields</div>
                                                    <div
                                                            class="common-size-xxxs margin-left-xs"
                                                            ng-if="!data.loadDependenciesDropdownList"
                                                    >
                                                        ({{ data.fieldDefinitionDependenciesCounter }})
                                                    </div>
                                                    <i
                                                            ng-if="data.loadDependenciesDropdownList"
                                                            class="margin-left-xs loading-small"
                                                    ></i>
                                                </div>
                                            </li>
                                            <li>
                                                <!-- Custom trigger dependencies -->
                                                <div
                                                        ng-click="applyTriggerDependenciesFilter(definition)"
                                                        class="flex-vmiddle margin-right"
                                                >
                                                    <tnk-icon
                                                            class="margin-top-xxs margin-right margin-left-xxxs"
                                                            src="/images/icons/lightning.svg"
                                                    ></tnk-icon>
                                                    <div class="common-size-xxxxs margin-left-field-option">
                                                        Filter Dependent Triggers
                                                    </div>
                                                    <div
                                                            class="common-size-xxxs margin-left-xs"
                                                            ng-if="!data.loadDependenciesDropdownList"
                                                    >
                                                        ({{ data.triggerDependenciesCounter }})
                                                    </div>
                                                    <i
                                                            ng-if="data.loadDependenciesDropdownList"
                                                            class="margin-left-xs loading-small"
                                                    ></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div
                class="box-with-shadow margin-bottom"
                ng-repeat="defSource in data.columns.customMatchedItemsFieldDefinitions"
                ng-class="{ 'mod-clickable': data.miniMode }"
                ng-hide="data.expandFieldGroup && data.expandFieldGroup !== 'showMI' + defSource"
        >
            <tnk-custom-item-match-fields-metadata-table
                    field-definition="defSource"
                    on-field-selected="(onFieldSelected)"
                    group-id="data.groupId"
                    workflow-version-id="data.workflowVersionId"
                    reload-fields="(reloadFields)"
                    add-field-by-related-entity="(addFieldByRelatedEntity)"
                    all-field-definitions="data.columns.fieldDefinitionsMap"
                    apply-field-dependencies-filter="(applyFieldDependenciesFilter)"
                    apply-trigger-dependencies-filter="(applyTriggerDependenciesFilter)"
                    delete-field-definition="(deleteFieldDefinition)"
                    field-filter-by-name="data.fieldFilterByName"
            ></tnk-custom-item-match-fields-metadata-table>
        </div>

        <div class="margin-top-lg" ng-hide="data.expandFieldGroup && data.expandFieldGroup.indexOf('showDS') === -1">
            <button
                    data-ng-disabled="!data.isDraft"
                    data-automation="group-fields-metadata-table-open-add-match-item-frame"
                    ng-click="data.canModifyBot() && onFieldSelected(data.groupId, 'COLUMN', null, null, true, false, null, reloadFields, reloadFields, null, true, false, null, true, false, true, false, true, data.workflowVersionId, true, true);"
                    class="margin-right btn-link"
            >
                <span
                        tooltip-enable="!data.isDraft"
                        uib-tooltip="{{ data.cantEditInProductionEnvironmentMessage }}"
                        tooltip-append-to-body="true"
                >
                    + Add Matched Item
                </span>
            </button>
        </div>


        <!-- Manage Group Key Metrics-->
        <div ng-hide="data.expandFieldGroup && data.expandFieldGroup !== 'showGlobalFields'" class="padding-bottom-lg">
            <hr/>

            <div class="text-uppercase common-color-grey common-size-xxxxs margin-bottom" ng-if="!data.miniMode">
                Global fields:
            </div>
            <div class="margin-top margin-bottom box-with-shadow" ng-class="{ 'mod-clickable': data.miniMode }">
                <div
                        class="common-size-xxxxs common-color-dark-grey margin-top-sm padding-normal-sm flex"
                        ng-click="data.miniMode ? showHideFields('showGlobalFields') : null"
                >
                    <div class="flex-grow">
                        <div class="common-bold">Global fields</div>
                        <div ng-if="!data.miniMode">
                            Stored and can be used across your module and not per specific Item
                        </div>
                    </div>
                    <div class="flex-self-center margin-left" ng-if="data.miniMode">
                        <i class="fa fa-chevron-right common-color-grey"></i>
                    </div>
                </div>

                <div ng-if="!data.miniMode">
                    <hr class="margin-none"/>

                    <div ng-if="data.shouldReloadFields">
                        <div class="loading-placeholder margin-normal-sm-h margin-normal-sm-v"></div>
                    </div>

                    <table
                            class="table table-striped"
                            ng-if="!data.shouldReloadFields && data.filteredKeyMetrices && data.filteredKeyMetrices.length"
                    >
                        <!-- Global fields -->
                        <tr ng-repeat="definition in data.filteredKeyMetrices | orderBy:'name'">
                            <td class="padding-normal-xs flex-vmiddle">
                                <span class="margin-right">
                                    <span ng-if="definition.definitionSource === 'MANUAL'" class="opacity-80">
                                        <tnk-field-type-icon field-type="definition.fieldType"></tnk-field-type-icon>
                                    </span>

                                    <!-- integration -->
                                    <i
                                            ng-if="definition.definitionSource !== 'MANUAL'"
                                            class="initiative-integration-icon mod-dropdown margin-none"
                                            ng-class="
                                            definition.projectIntegration && !definition.projectIntegration.iconUrl
                                                ? 'mod-' +
                                                  definition.projectIntegration.integration.integrationType.toLowerCase()
                                                : null
                                        "
                                            ng-style="{
                                            background:
                                                definition.projectIntegration && definition.projectIntegration.iconUrl
                                                    ? 'url(' +
                                                      definition.projectIntegration.iconUrl +
                                                      ') no-repeat center'
                                                    : null
                                        }"
                                    ></i>
                                </span>

                                <span class="common-break-long">
                                    <tnk-highlightable-text
                                            data-automation="group-fields-metadata-table-global-field-option"
                                            highlight-text="data.fieldFilterByName"
                                            text="definition.name"
                                    ></tnk-highlightable-text>
                                </span>

                                <span
                                        ng-if="definition.type === 'TNK_COLUMN_AGGREGATE'"
                                        class="common-color-grey margin-left common-italic"
                                >
                                    (Formula)
                                </span>
                            </td>
                            <td class="text-right">
                                <span class="flex flex-vmiddle margin-top-minus-xxs float-options-right">
                                    <!-- Edit button -->
                                    <a
                                            data-automation="group-field-meta-data-table-edit-button-{{ definition.name }}"
                                            class="margin-bottom-xxs"
                                            ng-click="onFieldSelected(data.groupId, definition.targetType, definition.type, definition.projectIntegration, false, false, definition, reloadFields, reloadFields, null, null, null, null, null, null, null, null, null, data.workflowVersionId);"
                                    >
                                        {{ data.workflowVersion.workflowVersionType === 'DRAFT' ? 'Edit' : 'View' }}
                                    </a>
                                    <!-- List options -->
                                    <div uib-dropdown dropdown-append-to-body>
                                        <!-- List options button -->
                                        <div
                                                class="
                                                svg-icon-smd
                                                margin-left-xs
                                                common-color-grey
                                                svg-icon-hover-primary svg-icon-align-text-base
                                                btn-on-hover
                                                pointer
                                                flex-no-shrink
                                            "
                                                uib-dropdown-toggle
                                                ng-click="dropdownFieldOpened(definition)"
                                        >
                                            <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                                        </div>

                                        <!-- List options menu -->
                                        <ul class="field-option-select" uib-dropdown-menu>
                                            <li>
                                                <div
                                                        ng-click="applyFieldDependenciesFilter(definition)"
                                                        class="flex-vmiddle margin-right"
                                                >
                                                    <tnk-icon
                                                            class="margin-top-xxs margin-right"
                                                            src="/images/icons/columns.svg"
                                                    ></tnk-icon>
                                                    <div class="common-size-xxxxs">Filter Dependent Fields</div>
                                                    <div
                                                            class="common-size-xxxs margin-left-xs"
                                                            ng-if="!data.loadDependenciesDropdownList"
                                                    >
                                                        ({{ data.fieldDefinitionDependenciesCounter }})
                                                    </div>
                                                    <i
                                                            ng-if="data.loadDependenciesDropdownList"
                                                            class="margin-left-xs loading-small"
                                                    ></i>
                                                </div>
                                            </li>
                                            <li>
                                                <div
                                                        ng-click="applyTriggerDependenciesFilter(definition)"
                                                        class="flex-vmiddle margin-right"
                                                >
                                                    <tnk-icon
                                                            class="margin-top-xxs margin-right margin-left-xxxs"
                                                            src="/images/icons/lightning.svg"
                                                    ></tnk-icon>
                                                    <div class="common-size-xxxxs margin-left-field-option">
                                                        Filter Dependent Triggers
                                                    </div>
                                                    <div
                                                            class="common-size-xxxs margin-left-xs"
                                                            ng-if="!data.loadDependenciesDropdownList"
                                                    >
                                                        ({{ data.triggerDependenciesCounter }})
                                                    </div>
                                                    <i
                                                            ng-if="data.loadDependenciesDropdownList"
                                                            class="margin-left-xs loading-small"
                                                    ></i>
                                                </div>
                                            </li>
                                            <li ng-if="data.workflowVersion.workflowVersionType === 'DRAFT'">
                                                <div
                                                        ng-click="openDuplicateFieldModal(definition)"
                                                        class="flex-vmiddle margin-right"
                                                >
                                                    <tnk-icon
                                                            class="margin-top-xxs margin-right"
                                                            src="/images/icons/duplicate-new.svg"
                                                    ></tnk-icon>
                                                    <div class="common-size-xxxxs">Duplicate Field</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <!-- Add -->
            <div class="margin-bottom-xlg" ng-if="!data.miniMode">
                <button
                        data-ng-disabled="!data.isDraft"
                        data-automation="group-fields-metadata-table-add-global-field"
                        ng-click="data.canModifyBot() && onFieldSelected(data.groupId, 'GLOBAL', null, null, true, false, null, reloadFields, reloadFields, null, null, null, null, null, null, null, null, null, data.workflowVersionId);"
                        class="margin-right btn-link"
                >
                    <span
                            tooltip-enable="!data.isDraft"
                            uib-tooltip="{{ data.cantEditInProductionEnvironmentMessage }}"
                            tooltip-append-to-body="true"
                    >
                        + Add New Global Field
                    </span>
                </button>
            </div>
        </div>
    </div>

    <div ng-if='data.updateContractFieldsModalState'>
        <tnk-add-contract-fields-modal
          initial-fields-state="data.updateContractFieldsModalState.fieldsState"
          open='!!data.updateContractFieldsModalState'
          on-submit="(submitUpdateContractFields)"
          on-close="(closeUpdateContractFieldsModal)"
        />
    </div>
</div>
