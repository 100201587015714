import { useEffect, useMemo } from 'react';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type {
    EnterpriseComponentId,
    EnterpriseComponentVariableRelatedEntityId,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';

const useEnterpriseComponentVariableValues = (
    projectId: TonkeanId<TonkeanType.PROJECT>,
    enterpriseComponentId: EnterpriseComponentId,
    relatedEntityId: EnterpriseComponentVariableRelatedEntityId | undefined,
) => {
    // Getting all the enterprise component variable of related entity.
    const {
        data: enterpriseComponentVariables,
        loading: loadingEnterpriseComponentVariables,
        error: errorEnterpriseComponentVariables,
    } = useTonkeanService('getEnterpriseComponentVariables', projectId, enterpriseComponentId, relatedEntityId);

    // Lazy call to get the variables values.
    const [
        {
            data: enterpriseComponentVariablesValues,
            loading: loadingEnterpriseComponentVariablesValues,
            error: errorEnterpriseComponentVariablesValues,
        },
        getEnterpriseComponentVariablesValues,
    ] = useLazyTonkeanService('getEnterpriseComponentVariablesValues');

    // Initiating the get values after the getEnterpriseComponentVariables complete.
    useEffect(() => {
        if (enterpriseComponentVariables) {
            const ids = enterpriseComponentVariables.entities.map((variable) => variable.id);
            getEnterpriseComponentVariablesValues(projectId, enterpriseComponentId, ids);
        }
    }, [enterpriseComponentId, enterpriseComponentVariables, getEnterpriseComponentVariablesValues, projectId]);

    return useMemo(() => {
        return {
            variables: enterpriseComponentVariables,
            variablesValues: enterpriseComponentVariablesValues,
            loading: loadingEnterpriseComponentVariables || loadingEnterpriseComponentVariablesValues,
            error: errorEnterpriseComponentVariables || errorEnterpriseComponentVariablesValues,
        };
    }, [
        enterpriseComponentVariables,
        enterpriseComponentVariablesValues,
        errorEnterpriseComponentVariables,
        errorEnterpriseComponentVariablesValues,
        loadingEnterpriseComponentVariables,
        loadingEnterpriseComponentVariablesValues,
    ]);
};

export default useEnterpriseComponentVariableValues;
