<div class="">
    <strong>Move into item</strong>
    <form name="move-to-track-form" class="clearfix">
        <div class="margin-none">
            <tnk-search-auto-complete
                class="flex-grow"
                ng-keydown="onKeyDown($event)"
                search-text="popover.search.searchText"
                set-autofocus="true"
                placeholder="Search Item"
                is-open="popover.search.isOpen"
                is-focused="popover.search.isFocused"
                results="popover.search.results"
                input-id="move-to-track-input"
                on-select="onSelect($item, $event)"
            ></tnk-search-auto-complete>
        </div>
    </form>
</div>
