<div class="padding-normal-xs actions-gallery" data-automation="tnk-logic-worker-component">
    <div
        ng-show="data.showBigLoading || (data.isSaving && !data.configuredLogicInitialized)"
        class="flex-vmiddle mod-justify-center"
    >
        <i class="loading-large"></i>
    </div>

    <div ng-show="!data.showBigLoading && (!data.isSaving || data.configuredLogicInitialized)">
        <!-- Logic Header-->
        <div ng-if="!data.noHeader" class="logic-header">
            <div class="flex-vmiddle relative">
                <!-- Logic Name -->
                <div class="logic-name flex-grow margin-right">
                    <!-- Display name non-edit-mode -->
                    <span
                        class="flex mod-align-center pointer"
                        ng-if="!data.displayEditLogicName"
                        ng-click="toggleEditLogicDisplayName()"
                    >
                        <tnk-text-ellipsis
                            class="common-title-sm"
                            data-automation="tnk-logic-worker-component-template-logic-worker-component-edit-action-name"
                            number-of-lines="1"
                            tooltip="true"
                            children="data.configuredLogic.node.displayName || 'Action Block'"
                        ></tnk-text-ellipsis>
                        <i class="fa fa-pencil common-color-link-blue common-size-xxxs edit-button mod-title"></i>
                    </span>

                    <!-- Display name edit-mode -->
                    <div ng-if="data.displayEditLogicName" class="logic-name-edit flex-vmiddle">
                        <!-- Text input -->
                        <input
                            type="text"
                            data-automation="logic-worker-component-action-name-input"
                            class="form-control common-title-sm common-input-only-line"
                            ng-blur="data.displayEditLogicName = false"
                            ng-model="data.editedLogicName"
                            ng-change="updateLogicDisplayName(data.editedLogicName)"
                            autofocus
                        />

                        <!-- Error state -->
                        <div class="common-color-danger common-size-xxxs">{{ data.errorSavingLogicDisplayName }}</div>
                    </div>
                </div>
                <!-- Loading state -->
                <i
                    class="loading-small margin-right-xxs"
                    data-automation="tnk-logic-worker-component-template-loading-state"
                    ng-class="{ 'visibility-hidden': !(data.loadingSavingLogic || data.loadingSavingLogicDisplayName) }"
                ></i>

                <!-- Toggle Logic enabled on & off -->
                <tnk-toggle
                    size="sm"
                    class="margin-right"
                    is-active="!data.configuredLogic.node.disabled"
                    data="data.configuredLogic.node"
                    on-toggle-click="toggleCustomTriggerDisabled($event, data)"
                ></tnk-toggle>

                <!-- Logic options -->
                <div class="dropbox relative flex-with-align-center" uib-dropdown>
                    <!-- Logic options button -->
                    <button
                        class="btn btn-no-style svg-icon-mdl svg-icon-hover-primary pointer"
                        uib-dropdown-toggle
                        uib-tooltip="No available actions"
                        tooltip-placement="bottom-right"
                        tooltip-append-to-body="true"
                        tooltip-enable="getThreeDotMenuIsDisabled()"
                        ng-class="{ disabled: getThreeDotMenuIsDisabled() }"
                    >
                        <span class="flex-vmiddle">
                            <span data-automation="logic-worker-component-option-menu" class="svg-icon-mdl">
                                <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                            </span>
                        </span>
                    </button>

                    <!-- Logic options menu -->
                    <ul
                        uib-dropdown-menu
                        class="dropdown-menu-right logic-menu padding-1"
                        ng-class="{ 'mod-wide': !cantChangeType() }"
                        role="menu"
                    >
                        <li
                            class="flex padding-right"
                            ng-if="
                                data.configuredLogic.node.customTriggerType === 'AUTONOMOUS' &&
                                !data.selectedLogicTypeConfiguration.cantCopyToOtherModule
                            "
                            ng-click="onCopyToListButtonClick()"
                        >
                            <span class="svg-icon-sm svg-icon-align-text-top margin-right common-color-grey">
                                <tnk-icon src="/images/icons/copy-trigger.svg"></tnk-icon>
                            </span>
                            <div>
                                <span>Copy to</span>
                            </div>
                        </li>

                        <!-- Duplicate -->
                        <li
                            data-automation="logic-worker-component-duplicate-action"
                            class="flex padding-right mod-align-center"
                            ng-if="!data.selectedLogicTypeConfiguration.cantDuplicate"
                            ng-click="duplicateLogic()"
                        >
                            <span class="svg-icon-sm svg-icon-align-text-top margin-right mod-line-height-12">
                                <tnk-icon src="/images/icons/duplicate.svg"></tnk-icon>
                            </span>
                            <span>Duplicate</span>
                        </li>

                        <!-- Change -->
                        <li
                            data-automation="logic-worker-component-change-type-reset"
                            class="flex padding-right mod-align-center"
                            ng-if="!cantChangeType()"
                            ng-click="setLogicSelectTypeMode()"
                        >
                            <span class="margin-right common-color-grey mod-line-height-12">
                                <i class="fa fa-eraser mod-line-height-12 mod-width-14"></i>
                            </span>
                            <span>Change Type (Reset)</span>
                        </li>

                        <!-- Compare -->
                        <li
                            data-automation="logic-worker-component-compare-action"
                            ng-if="
                                ctm.getCachedCustomTrigger(
                                    wvm.getPublishedVersionFromCache(data.groupId).id,
                                    data.configuredLogic.node.id
                                )
                            "
                            class="flex padding-right mod-align-center"
                            ng-click="sideBySideDiff()"
                        >
                            <span class="svg-icon-sm svg-icon-align-text-top margin-right mod-line-height-12">
                                <tnk-icon src="/images/icons/compare.svg"></tnk-icon>
                            </span>
                            <span>Compare</span>
                        </li>

                        <!-- Import Action Config -->
                        <li
                            class="flex padding-right mod-align-center"
                            ng-if="data.selectedLogicTypeConfiguration.type === 'OUTGOING_WEBHOOK'"
                            ng-click="openImportActionConfigModal(data.selectedLogicTypeConfiguration.type)"
                        >
                            <span
                                class="
                                    svg-icon-sm svg-icon-align-text-top
                                    margin-right
                                    common-color-grey
                                    mod-line-height-12
                                "
                            >
                                <tnk-icon src="/images/icons/import.svg"></tnk-icon>
                            </span>
                            <span>Import</span>
                        </li>

                        <!-- Delete -->
                        <li
                            data-automation="logic-worker-component-delete"
                            class="flex padding-right mod-align-center"
                            ng-if="!data.selectedLogicTypeConfiguration.cantDelete"
                            ng-click="deleteLogic()"
                        >
                            <span
                                class="
                                    svg-icon-sm svg-icon-align-text-top
                                    margin-right
                                    common-color-grey
                                    mod-line-height-12
                                "
                            >
                                <tnk-icon src="/images/icons/delete.svg"></tnk-icon>
                            </span>
                            <span>Delete</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div ng-if="data.invalidLogics[data.configuredLogic.node.id].noDefinition" class="common-color-danger">
                {{ data.invalidLogics[data.configuredLogic.node.id].noDefinition }}
            </div>

            <!-- Pending changes alert -->
            <div
                ng-if="data.includeSpaceFillerForWarningMessage || data.displaysHistoryVersion"
                class="margin-top history-version-alert-box"
            >
                <div class="alert alert-warning flex-vmiddle padding-xs" ng-if="data.displaysHistoryVersion">
                    <div>History version cannot be edited.</div>
                </div>
            </div>
        </div>

        <div
            ng-switch="data.configuredLogic.node.customTriggerType"
            ng-if="!data.initializing"
            class="logic-worker-component-switch-wrapper margin-bottom-xxlg"
            data-automation="tnk-logic-worker-component-template-condition-logic-configuration"
        >
            <!-- Autonomous -->
            <div ng-switch-when="AUTONOMOUS">
                <tnk-autonomous-trigger-configuration
                    ng-show="data.logicConfigMode === data.logicConfigModes.configuration"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    control="data.control"
                    preview-evaluation-source="data.previewEvaluationSource"
                    custom-trigger-id="data.configuredLogic.node.id"
                    custom-trigger-type="{{ data.configuredLogic.node.customTriggerType }}"
                    on-autonomous-trigger-configuration-changed="
                        onAutonomousTriggerConfigurationChanged(autonomousTriggerConfiguration)
                    "
                    on-actions-changed="onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)"
                    invalid-logics="data.invalidLogics"
                    view-only="data.viewOnly"
                    is-inner-autonomous="false"
                ></tnk-autonomous-trigger-configuration>
            </div>

            <!-- Inner autonomous -->
            <div ng-switch-when="INNER_AUTONOMOUS">
                <tnk-autonomous-trigger-configuration
                    ng-show="data.logicConfigMode === data.logicConfigModes.configuration"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    control="data.control"
                    custom-trigger-id="data.configuredLogic.node.id"
                    preview-evaluation-source="data.previewEvaluationSource"
                    custom-trigger-type="{{ data.configuredLogic.node.customTriggerType }}"
                    on-autonomous-trigger-configuration-changed="
                        onAutonomousTriggerConfigurationChanged(autonomousTriggerConfiguration)
                    "
                    view-only="data.viewOnly"
                    is-inner-autonomous="true"
                ></tnk-autonomous-trigger-configuration>
            </div>

            <!-- Others -->
            <div ng-switch-default>
                <!-- Conditions -->
                <div ng-show="data.logicConfigMode === data.logicConfigModes.conditions" class="logics-part-container">
                    <!-- When title-->
                    <div
                        class="common-bold common-size-xxxs logics-part-title text-uppercase padding-normal-xs"
                        ng-click="data.showConditionsConfiguration = !data.showConditionsConfiguration"
                    >
                        IF
                        <span ng-if="data.selectedConditionTypeConfiguration">
                            -
                            <span class="logic-template-header-title">
                                {{ data.selectedConditionTypeConfiguration.title }}
                            </span>
                        </span>
                    </div>

                    <!-- Condition templates gallery -->
                    <div
                        ng-show="!data.selectedConditionTypeConfiguration || data.showConditionsConfiguration"
                        class="margin-top"
                    >
                        <!-- Condition templates in category -->
                        <div class="flex flex-vmiddle flex-wrap flex-grow common-width-100">
                            <div
                                ng-repeat="conditionTypeConfiguration in data.conditionTypeConfigurations"
                                class="margin-left margin-bottom"
                                ng-click="selectConditionType(conditionTypeConfiguration)"
                            >
                                <div
                                    class="logic-template-box flex-vmiddle mod-center"
                                    ng-class="{
                                        'mod-selected':
                                            data.selectedConditionTypeConfiguration.type ===
                                            conditionTypeConfiguration.type
                                    }"
                                    data-automation="logic-worker-component-workflow-action-block-{{ (logicTypeConfiguration.dataAutomation || '') }}"
                                >
                                    <div
                                        data-automation="logic-worker-component-{{ conditionTypeConfiguration.title }}"
                                        class="logic-template-icon"
                                        ng-class="conditionTypeConfiguration.iconClass"
                                    ></div>
                                </div>
                                <div
                                    class="logic-template-box-title"
                                    data-automation="logic-worker-component-action-block-title"
                                >
                                    {{ conditionTypeConfiguration.title }}
                                </div>
                            </div>
                        </div>

                        <!-- Filters -->
                        <div
                            class="padding-left padding-bottom"
                            ng-show="data.selectedConditionTypeConfiguration.type === 'FILTER'"
                        >
                            <!-- Separator -->
                            <hr class="margin-top margin-bottom" />

                            <!-- Filters title -->
                            <div class="common-bold margin-bottom">Only run if:</div>

                            <!-- Conditions -->
                            <tnk-custom-filters
                                class="margin-right"
                                display-integration-indication="true"
                                project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                                hide-close-button="true"
                                control="data.control"
                                items-source="COLUMN"
                                existing-definition="data.configuredLogic.node.queryDefinition"
                                group-id="data.groupId"
                                workflow-version-id="data.workflowVersionId"
                                logic-id="data.configuredLogic.node.id"
                                evaluate-preview="true"
                                edit-mode="data.editMode"
                                small-mod="true"
                                hide-time-range-selection="true"
                                bold-title="true"
                                on-filters-change="onTriggerConditionFiltersChange(shouldNotSaveLogic)"
                                filters-change-only-after-init="true"
                                special-fields-for-features="['CUSTOM_TRIGGER_CONDITIONS']"
                                show-field-inspect-modal="!!scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
                                inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
                                force-expand="data.viewOnly"
                                view-only="data.viewOnly"
                                filters-result-map="data.filtersResultMap"
                                allow-contains="true"
                            ></tnk-custom-filters>
                            <div
                                ng-if="
                                    data.logicBlockTypes[data.configuredLogic.node.customTriggerType]
                                        .canSkipCustomTriggerWhenConditionWasNotMet
                                "
                                class="margin-top-md"
                            >
                                <div class="common-size-xxs common-color-grey3 margin-bottom-md">Otherwise</div>
                                <div class="flex-vmiddle">
                                    <tnk-react-checkbox
                                        data-automation="logic-worker-component-skip-checkbox"
                                        on-change="(onSkipCustomTriggerChange)"
                                        checked="data.skipWhenConditionNotMet"
                                    ></tnk-react-checkbox>
                                    <span class="margin-left-xs skip-custom-trigger-input">
                                        Skip block if conditions didn't meet
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Gallery -->
                <div ng-show="data.logicConfigMode === data.logicConfigModes.selectType">
                    <!-- Search bar -->
                    <input
                        autofocus
                        type="text"
                        placeholder="Search actions..."
                        class="form-control common-border-dark-softer"
                        ng-model="data.searchAction"
                        data-automation="logic-worker-component-search-action-input"
                    />

                    <div
                        ng-if="data.invalidLogics[data.configuredLogic.node.id].noType"
                        class="common-color-danger margin-bottom"
                    >
                        {{ data.invalidLogics[data.configuredLogic.node.id].noType }}
                    </div>

                    <div class="padding-bottom logics-part-container mod-do margin-top">
                        <!-- Logic templates gallery -->
                        <div>
                            <!-- People Coordination-->
                            <div
                                ng-init="peopleCategory = data.logicConfigurationTypesMap.categories.PEOPLECOORDINATION"
                            >
                                <!-- Logic template category title -->
                                <div
                                    class="
                                        common-bold common-size-xxxxs
                                        text-uppercase
                                        padding-top padding-bottom padding-left-md
                                        margin-top
                                        common-color-dark-grey
                                    "
                                >
                                    {{ peopleCategory.title }}
                                </div>

                                <!-- Logic templates in category -->
                                <div class="flex flex-vmiddle flex-wrap flex-grow common-width-100 padding-left-md">
                                    <div
                                        ng-repeat="logicTypeConfiguration in (peopleCategory.logicTypeConfigurations | filter:data.searchAction)"
                                        class="logic-template-box-container margin-bottom-xs"
                                        ng-click="selectLogicType(logicTypeConfiguration)"
                                    >
                                        <div
                                            class="logic-template-box flex-vmiddle mod-center"
                                            ng-class="{
                                                'mod-selected':
                                                    data.selectedLogicTypeConfiguration.type ===
                                                    logicTypeConfiguration.type
                                            }"
                                            data-automation="logic-worker-component-workflow-action-block-{{ (logicTypeConfiguration.dataAutomation || '') }}"
                                        >
                                            <div
                                                class="logic-template-icon"
                                                ng-class="logicTypeConfiguration.iconClass"
                                            ></div>
                                        </div>
                                        <div
                                            class="logic-template-box-title"
                                            data-automation="logic-worker-component-action-block-title"
                                        >
                                            {{ logicTypeConfiguration.title }}
                                        </div>
                                    </div>

                                    <!-- No Matches for People Coordination -->
                                    <div
                                        class="common-color-light-grey common-break-long"
                                        ng-if="
                                            (peopleCategory.logicTypeConfigurations | filter: data.searchAction)
                                                .length === 0
                                        "
                                    >
                                        No people coordination actions match the search keyword "{{
                                            data.searchAction
                                        }}"
                                    </div>
                                </div>
                            </div>

                            <!-- WORKFLOW -->
                            <div ng-init="workflowCategory = data.logicConfigurationTypesMap.categories.WORKFLOW">
                                <!-- Separator -->
                                <hr class="common-separator mod-darker margin-top-md margin-bottom-md" />

                                <!-- Logic template category title -->
                                <div
                                    class="
                                        common-bold common-size-xxxxs
                                        text-uppercase
                                        padding-top padding-bottom padding-left-md
                                        margin-top
                                        common-color-dark-grey
                                    "
                                >
                                    {{ workflowCategory.title }}
                                </div>

                                <!-- Logic templates in category -->
                                <div class="flex flex-vmiddle flex-wrap flex-grow common-width-100 padding-left-md">
                                    <div
                                        ng-repeat="logicTypeConfiguration in (workflowCategory.logicTypeConfigurations | filter:data.searchAction)"
                                        class="logic-template-box-container mod-small margin-bottom-xs"
                                        ng-click="selectLogicType(logicTypeConfiguration)"
                                    >
                                        <div
                                            class="logic-template-box flex-vmiddle mod-center"
                                            ng-class="{
                                                'mod-selected':
                                                    data.selectedLogicTypeConfiguration.type ===
                                                    logicTypeConfiguration.type
                                            }"
                                            data-automation="logic-worker-component-workflow-action-block-{{ (logicTypeConfiguration.dataAutomation || '') }}"
                                        >
                                            <div
                                                class="logic-template-icon mod-small"
                                                ng-class="logicTypeConfiguration.iconClass"
                                            ></div>
                                        </div>
                                        <div
                                            class="logic-template-box-title"
                                            data-automation="logic-worker-component-action-block-title"
                                        >
                                            {{ logicTypeConfiguration.title }}
                                        </div>
                                    </div>

                                    <!-- No Matches for Workflow actions -->
                                    <div
                                        class="common-color-light-grey common-break-long"
                                        ng-if="
                                            (workflowCategory.logicTypeConfigurations | filter: data.searchAction)
                                                .length == 0
                                        "
                                    >
                                        No workflow actions match the search keyword "{{ data.searchAction }}"
                                    </div>
                                </div>
                            </div>

                            <!-- Data Piping -->
                            <div ng-init="dataCategory = data.logicConfigurationTypesMap.categories.DATA">
                                <!-- Separator -->
                                <hr class="common-separator mod-darker margin-top-md margin-bottom-md" />

                                <!-- Logic template category title -->
                                <div
                                    class="
                                        common-bold common-size-xxxxs
                                        text-uppercase
                                        padding-top padding-bottom padding-left-md
                                        margin-top
                                        common-color-dark-grey
                                    "
                                >
                                    {{ dataCategory.title }}
                                </div>

                                <!-- Project integration selection -->
                                <div class="flex mod-align-start flex-wrap padding-left-md">
                                    <tnk-project-integrations-view
                                        view-type="'BOXES'"
                                        filter-by-text="data.searchAction"
                                        on-click-connected-data-source="(selectProjectIntegration)"
                                        on-click-disconnected-data-source="(selectIntegrationByName)"
                                    ></tnk-project-integrations-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- DO -->
                <div
                    ng-show="data.logicConfigMode === data.logicConfigModes.configuration"
                    class="padding-bottom logics-part-container mod-do margin-top"
                    data-automation="tnk-logic-worker-component-template-configuration"
                >
                    <!-- Working On -->
                    <div
                        ng-if="data.configuredLogic.node.customTriggerType !== 'MONITOR_TRACKS' && !data.isCreateTrackAction"
                        class="text-center logics-part-container-working-on common-size-xxxxs"
                    >
                        <span class="common-bold margin-right-xs">Working on</span>
                        <span class="common-color-light-grey">{{ data.workingOn }}</span>
                    </div>

                    <!-- Process in another module-->
                    <div
                      ng-if="data.isCreateTrackAction"
                      class="text-center logics-new-working-on-container"
                    >
                        <div class="module-editor-item-details-icon-wrapper">
                            <div class="icon-inner-item-details">

                            </div>
                        </div>
                        <div class="common-bold common-size-xs">
                            Process in another module
                        </div>
                    </div>

                    <div ng-if="!data.isCreateTrackAction" class="margin-left margin-top">
                        <div class="flex-vmiddle">
                            <div
                                class="logic-template-icon mod-sm margin-right"
                                ng-class="data.selectedLogicTypeConfiguration.iconClass"
                            ></div>
                            <div class="margin-right">{{ data.selectedLogicTypeConfiguration.title }}</div>
                        </div>
                    </div>

                    <!-- Logic type configuration -->
                    <div class="margin-left margin-right">
                        <div ng-if="!data.isCreateTrackAction && data.selectedLogicTypeConfiguration.description">
                            <hr class="common-separator mod-darker margin-top margin-bottom" />
                            <div class="common-color-grey">{{ data.selectedLogicTypeConfiguration.description }}</div>
                        </div>

                        <hr  ng-if="!data.isCreateTrackAction" class="common-separator mod-darker margin-top margin-bottom" />

                        <!-- Linked field definitions -->
                        <div
                            ng-if="
                                data.customTriggerIdTolinkedFieldDefinitionsConfiguration[
                                    data.configuredLogic.node.id
                                ] &&
                                data.customTriggerIdTolinkedFieldDefinitionsConfiguration[data.configuredLogic.node.id]
                                    .length &&
                                $root.features.currentProject
                                    .tonkean_feature_show_where_to_store_output_linked_field_definitions_of_actions
                            "
                        >
                            <!-- Linked field definition title -->
                            <div class="common-title-xxs">Where to store the output?</div>

                            <!-- Summary -->
                            <div class="common-size-xxs common-color-grey3">
                                Store the
                                <span
                                    class="common-bold"
                                    ng-repeat="linkedFieldDefinitionConfiguration in data.customTriggerIdTolinkedFieldDefinitionsConfiguration[data.configuredLogic.node.id]"
                                >
                                    {{ linkedFieldDefinitionConfiguration.friendlyName }}{{ !$last ? ',' : '' }}
                                </span>
                                in&nbsp;
                                <span
                                    ng-if="
                                        data.customTriggerIdTolinkedFieldDefinitionsConfiguration[
                                            data.configuredLogic.node.id
                                        ].length === 1
                                    "
                                    class="common-bold"
                                >
                                    {{
                                        data.customTriggerIdToLinkedFieldDefinitionsMap[data.configuredLogic.node.id][
                                            data.customTriggerIdTolinkedFieldDefinitionsConfiguration[
                                                data.configuredLogic.node.id
                                            ][0].field
                                        ].name
                                    }}
                                </span>
                                <a
                                    ng-click="data.hideDetailedLInkedFieldDefinitionsSections = !data.hideDetailedLInkedFieldDefinitionsSections"
                                >
                                    Edit
                                </a>
                            </div>

                            <!-- Actual fields -->
                            <div
                                ng-if="!data.hideDetailedLInkedFieldDefinitionsSections"
                                ng-repeat="linkedField in data.customTriggerIdTolinkedFieldDefinitionsConfiguration[data.configuredLogic.node.id]"
                                class="margin-top-xs"
                            >
                                <div
                                    class="common-size-xxs common-color-grey3"
                                    ng-if="
                                        data.customTriggerIdTolinkedFieldDefinitionsConfiguration[
                                            data.configuredLogic.node.id
                                        ].length > 1
                                    "
                                >
                                    Store the
                                    <b>{{ linkedField.friendlyName || 'entity' }}</b>
                                    in:
                                </div>

                                <!-- Field selector for linked field definition -->
                                <div class="common-size-xxs margin-bottom-lg padding-right margin-top-xxs">
                                    <tnk-field-selector
                                        class="ui-select-container"
                                        selected-field="data.customTriggerIdToLinkedFieldDefinitionsMap[data.configuredLogic.node.id][linkedField.field]"
                                        selected-field-identifier="linkedField.field"
                                        items-source="COLUMN"
                                        group-id="{{ data.groupId }}"
                                        workflow-version-id="{{ data.workflowVersionId }}"
                                        project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
                                        inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
                                        display-integration-indication="true"
                                        only-updatable-field-definitions="true"
                                        add-fields-quick-create-options="true"
                                        group-by="groupBy"
                                        auto-create-column-fields="true"
                                        place-holder="Search data field"
                                        on-field-selected="
                                            onLinkedFieldDefinitionOptionSelected(
                                                selectedField,
                                                selectedFieldIdentifier
                                            )
                                        "
                                        include-global-fields="false"
                                        allow-clear-selection="true"
                                        do-not-display-special-fields="true"
                                    ></tnk-field-selector>
                                </div>
                            </div>
                            <hr class="common-separator mod-darker margin-top margin-bottom" />
                        </div>

                        <div ng-switch="data.selectedLogicTypeConfiguration.type">
                            <!-- Person Inquiry -->
                            <div ng-switch-when="PERSON_INQUIRY">
                                <tnk-person-inquiry-logic-configuration
                                    create-new-logic-callback="createNewLogic(params)"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    enable-bot-buttons="true"
                                ></tnk-person-inquiry-logic-configuration>
                            </div>

                            <!-- Approval Cycle -->
                            <div ng-switch-when="APPROVAL_CYCLE">
                                <tnk-approval-cycle-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-approval-cycle-logic-configuration>
                            </div>

                            <!-- Next Steps -->
                            <div ng-switch-when="NEXT_STEPS">
                                <tnk-next-steps-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-next-steps-logic-configuration>
                            </div>

                            <!-- Manual Next Steps -->
                            <div ng-switch-when="MANUAL_NEXT_STEPS">
                                <tnk-manual-next-steps-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                ></tnk-manual-next-steps-logic-configuration>
                            </div>

                            <!-- Sync Inner Matched Entity -->
                            <div ng-switch-when="SYNC_INNER_MATCHED_ENTITY">
                                <tnk-sync-inner-matched-entity-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                ></tnk-sync-inner-matched-entity-logic-configuration>
                            </div>

                            <!-- Manual field update -->
                            <div ng-switch-when="MANUAL_FIELD_UPDATE">
                                <tnk-update-fields-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    reload-fields="data.reloadFields"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-update-fields-logic-configuration>
                            </div>

                            <!-- Train Trigger -->
                            <div ng-switch-when="TRAIN_TRIGGER">
                                <tnk-train-trigger-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-train-trigger-logic-configuration>
                            </div>

                            <!-- Manual owner update -->
                            <div ng-switch-when="MANUAL_OWNER_UPDATE">
                                <tnk-update-owner-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    reload-fields="data.reloadFields"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-update-owner-logic-configuration>
                            </div>

                            <!-- Tonkean Action -->
                            <div ng-switch-when="TONKEAN_ACTION">
                                <tnk-tonkean-action-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    workflow-version-type="{{ data.workflowVersionType }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-update-logic-type="onUpdateCustomTriggerType(type)"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    on-selected-logic-type-configuration-changed="
                                        onSelectedLogicTypeConfigurationChanged(
                                            canSkipCustomTriggerWhenConditionWasNotMet
                                        )
                                    "
                                ></tnk-tonkean-action-logic-configuration>
                            </div>

                            <!-- Perform Action -->
                            <div ng-switch-when="PERFORM_INTEGRATION_ACTION">
                                <tnk-perform-integration-action-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    selected-project-integration="data.selectedProjectIntegration"
                                    open-add-new-integration-modal="openAddNewIntegrationModal()"
                                    workflow-folder-project-integrations-access="data.workflowFolderProjectIntegrationsAccess"
                                ></tnk-perform-integration-action-logic-configuration>
                            </div>

                            <!-- Notification -->
                            <div ng-switch-when="SEND_NOTIFICATION">
                                <div>
                                    <tnk-send-notification-logic-configuration
                                        group-id="{{ data.groupId }}"
                                        workflow-version-id="{{ data.workflowVersionId }}"
                                        configured-logic="data.configuredLogic"
                                        invalid-logics="data.invalidLogics"
                                        on-actions-changed="
                                            onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                        "
                                        preview-evaluation-source="data.previewEvaluationSource"
                                    ></tnk-send-notification-logic-configuration>
                                </div>
                            </div>

                            <!-- Email -->
                            <div ng-switch-when="SEND_EMAIL">
                                <tnk-send-email-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-send-email-logic-configuration>
                            </div>

                            <!-- Delay -->
                            <div ng-switch-when="DELAY">
                                <tnk-delay-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                                ></tnk-delay-logic-configuration>
                            </div>

                            <!-- Ask Field Update -->
                            <div ng-switch-when="ASK_FIELD_UPDATE">
                                <tnk-ask-person-field-update-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    reload-fields="data.reloadFields"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-ask-person-field-update-logic-configuration>
                            </div>

                            <!-- SEND FORM -->
                            <div ng-switch-when="SEND_FORM">
                                <tnk-send-attachment-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    reload-fields="data.reloadFields"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    open-field-modal="data.openFieldModal"
                                    include-form-attachment="true"
                                ></tnk-send-attachment-logic-configuration>
                            </div>

                            <div ng-switch-when="SEND_FORM_ANSWERED"></div>

                            <!-- SEND CUSTOM INTERFACE -->
                            <div ng-switch-when="SEND_ITEM_INTERFACE">
                                <tnk-send-attachment-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    reload-fields="data.reloadFields"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    open-field-modal="data.openFieldModal"
                                    include-item-interface-attachment="true"
                                    data-automation="send-item-interface-logic-configuration"
                                ></tnk-send-attachment-logic-configuration>
                            </div>

                            <!-- Gather update  -->
                            <div ng-switch-when="GATHER_UPDATE">
                                <tnk-gather-update-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-gather-update-logic-configuration>
                            </div>

                            <!-- Module button pressed  -->
                            <div ng-switch-when="BOT_BUTTON_PRESSED">
                                <tnk-bot-button-pressed-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    open-field-modal="data.openFieldModal"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-bot-button-pressed-logic-configuration>
                            </div>

                            <!-- Form answered  -->
                            <div ng-switch-when="SEND_FORM_ANSWERED">
                                <tnk-form-answered-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    open-field-modal="data.openFieldModal"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-form-answered-logic-configuration>
                            </div>

                            <!-- Form answered  -->
                            <div ng-switch-when="ASK_FIELD_UPDATE_ANSWERED">
                                <tnk-form-answered-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-form-answered-logic-configuration>
                            </div>

                            <!-- Monitor tracks -->
                            <div ng-switch-when="MONITOR_TRACKS">
                                <tnk-monitor-tracks-logic-configuration
                                    control="data.control"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-monitor-tracks-logic-configuration>
                            </div>

                            <!-- Outgoing Webhook -->
                            <div ng-switch-when="OUTGOING_WEBHOOK">
                                <tnk-outgoing-webhook-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    import-action-config="{{ data.importActionConfig }}"
                                ></tnk-outgoing-webhook-logic-configuration>
                            </div>

                            <!-- HTTP Upload -->
                            <div ng-switch-when="HTTP_UPLOAD">
                                <tnk-http-upload-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    import-action-config="{{ data.importActionConfig }}"
                                ></tnk-http-upload-logic-configuration>
                            </div>

                            <div ng-switch-when="STORAGE_UPLOAD">
                                <tnk-storage-upload-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="onActionsChanged(definition, shouldSaveLogic)"
                                ></tnk-storage-upload-logic-configuration>
                            </div>

                            <!-- NLP Processor -->
                            <div ng-switch-when="NLP_PROCESSOR">
                                <tnk-nlp-processor-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    create-new-logic-callback="createNewLogic(params)"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-nlp-processor-logic-configuration>
                            </div>

                            <!-- NLP Branch -->
                            <div ng-switch-when="NLP_BRANCH">
                                <tnk-nlp-branch-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    on-branch-name-changed="saveLogic(false, true)"
                                ></tnk-nlp-branch-logic-configuration>
                            </div>

                            <!-- Text Extractor -->
                            <div ng-switch-when="TEXT_EXTRACTOR">
                                <tnk-text-extractor-logic-configuration
                                    group-id="data.groupId"
                                    workflow-version-id="data.workflowVersionId"
                                    configured-logic="data.configuredLogic"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    invalid-logics="data.invalidLogics"
                                    validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                                    on-change="(onActionsChanged)"
                                ></tnk-text-extractor-logic-configuration>
                            </div>

                            <!-- OCR Conversion -->
                            <div ng-switch-when="OCR_CONVERSION">
                                <tnk-ocr-conversion-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    import-action-config="{{ data.importActionConfig }}"
                                ></tnk-ocr-conversion-logic-configuration>
                            </div>

                            <!-- Front Door Action -->
                            <div ng-switch-when="FRONT_DOOR_ACTION">
                                <tnk-front-door-action-logic-configuration
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    configured-logic="data.configuredLogic"
                                    invalid-logics="data.invalidLogics"
                                    on-actions-changed="
                                        onActionsChanged(definition, shouldSaveLogic, reloadOnFieldsError)
                                    "
                                    preview-evaluation-source="data.previewEvaluationSource"
                                ></tnk-front-door-action-logic-configuration>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- When finished-->
            <div
                ng-show="data.logicConfigMode === data.logicConfigModes.whenFinished"
                class="logics-part-container margin-top"
            >
                <tnk-post-status-update-logic-configuration
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    configured-logic="data.configuredLogic"
                    invalid-logics="data.invalidLogics"
                    working-on="data.workingOn"
                    view-only-mode="(data.configuredLogic.node.customTriggerType === 'AUTONOMOUS' || data.configuredLogic.node.customTriggerType === 'INNER_AUTONOMOUS') && data.isPublishedWorkflowVersion"
                    on-post-status-changed="onPostStatusChanged(shouldSaveLogic, stateConfiguration)"
                    data-automation="tnk-logic-worker-component-template-status-update-logic-configuration"
                ></tnk-post-status-update-logic-configuration>
            </div>

            <!-- Notification -->
            <div
                ng-show="data.logicConfigMode === data.logicConfigModes.logicNotification"
                class="logics-part-container pointer padding-top padding-left padding-right"
                data-automation="tnk-logic-worker-component-template-custom-notification-logic-configuration"
            >
                <!-- Configuration -->
                <tnk-custom-notification-settings
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    configured-logic="data.configuredLogic"
                    existing-custom-notification-settings="data.configuredLogic.node.customNotificationSettings"
                    on-custom-notification-settings-changed="
                        onCustomNotificationSettingsChanged(customNotificationSettings, shouldSaveLogic)
                    "
                ></tnk-custom-notification-settings>

                <!-- Separator -->
                <hr />

                <!-- Clear, Loading, Error section -->
                <div class="margin-bottom">
                    <!-- Clear button-->
                    <a ng-click="clearCustomNotificationSettings()">Clear</a>

                    <!-- Loading -->
                    <i class="margin-top loading-small" ng-if="data.savingCustomNotificationSettings"></i>

                    <!-- Error -->
                    <div
                        class="margin-top common-color-danger common-size-xxxs"
                        ng-if="!data.savingCustomNotificationSettings && data.errorSavingCustomNotificationSettings"
                    >
                        {{ data.errorSavingCustomNotificationSettings }}
                    </div>
                </div>
            </div>

            <!-- Error state -->
            <div class="common-color-danger common-size-xxxs margin-top" ng-if="data.errorSavingLogic">
                {{ data.errorSavingLogic }}
            </div>

            <!-- Save trigger button -->
            <!--<button ng-if="(data.logicConfigMode != data.logicConfigModes.selectType && data.logicConfigMode != data.logicConfigModes.whenFinished && data.selectedLogicTypeConfiguration.type !== 'BOT_BUTTON_PRESSED') || data.configuredLogic.node.customTriggerType == 'AUTONOMOUS'" ng-click="saveLogic()" class="btn btn-primary margin-right-xs margin-top">-->
            <!--Save-->
            <!--</button>-->
        </div>
    </div>
</div>
