import template from './tnkToggle.template.html?angularjs';
import lateConstructController from '../../utils/lateConstructController';

angular.module('tonkean.app').component('tnkToggle', {
    bindings: {
        tooltipContent: '@',
        tooltipPlacement: '@',
        displayTooltip: '@',
        applyClassesWhenDisabled: '@',
        asyncUpdate: '<', // If true, the isActive prop is the only one who decides if it's active, and it is not being updated internally
        isActive: '<', // Initial state of isActive, updates will be noticed.
        onToggleClick: '&', // A method that will be activated each time the toggle has been clicked.
        validationPromise: '&', // A promise that is called before toggling, if the promise is rejected no toggle occurs (optional)
        data: '<', // An object to be passed to the onToggleClick.
        size: '@', // Sets the side of the toggle (can be one of these values: sm, lg). If not given, will be default size.
        onLabel: '<', // Sets the label for when the toggle is in an 'on' state (optional).
        offLabel: '@', // Sets the label for when the toggle is in an 'off' state (optional).
        toggleDisabled: '<', // When set to true, the toggle is not clickable and doesn't have a pointer icon. Will still respond to isActive state changes.
        onToggleClickParameter: '<', // Parameter for the on toggle click callback.
        fireOnToggleClickWhenDisabled: '<', // Indicates whether we should fire the toggle click callback even when the toggle is disabled (toggleDisabled = true).
    },
    template,
    controller: 'ToggleCtrl',
});

/* @ngInject */
function ToggleCtrl($scope, $timeout, $q) {
    const ctrl = this;
    $scope.data = {
        tooltipContent: ctrl.tooltipContent,
        tooltipPlacement: ctrl.tooltipPlacement,
        displayTooltip: ctrl.displayTooltip,
        applyClassesWhenDisabled: ctrl.applyClassesWhenDisabled,
        isActive: ctrl.isActive,
        onToggleClick: ctrl.onToggleClick,
        validationPromise: ctrl.validationPromise,
        associatedData: ctrl.data,
        sizeClass: calculateSizeClass(ctrl.size),
        onLabel: ctrl.onLabel || '',
        offLabel: ctrl.offLabel || '',
        toggleDisabled: ctrl.toggleDisabled,
        onToggleClickParameter: ctrl.onToggleClickParameter,
        fireOnToggleClickWhenDisabled: ctrl.fireOnToggleClickWhenDisabled,
        transitionActive: false,
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.data) {
            $scope.data.associatedData = changesObj.data.currentValue;
        }

        if (changesObj.isActive) {
            $scope.data.isActive = ctrl.isActive;
        }

        if (changesObj.toggleDisabled) {
            $scope.data.toggleDisabled = ctrl.toggleDisabled;
        }

        if (changesObj.tooltipContent) {
            $scope.data.tooltipContent = changesObj.tooltipContent.currentValue;
        }

        if (changesObj.tooltipPlacement) {
            $scope.data.tooltipPlacement = changesObj.tooltipPlacement.currentValue;
        }

        if (changesObj.displayTooltip) {
            $scope.data.displayTooltip = changesObj.displayTooltip.currentValue;
        }

        if (changesObj.applyClassesWhenDisabled) {
            $scope.data.applyClassesWhenDisabled = changesObj.applyClassesWhenDisabled.currentValue;
        }
    };

    $scope.onToggleClick = function ($event) {
        // Don't bubble the click event upwards..
        $event.preventDefault();
        $event.stopPropagation();

        // Only do something on click if the toggle is not disabled.
        if (!$scope.data.toggleDisabled || $scope.data.fireOnToggleClickWhenDisabled) {
            // Wait for the validation promise (if there is one) and then toggle.
            $q.all([$scope.data.validationPromise()]).then(() => toggle($event));
        }
    };

    /**
     * Calculates the classes needs to be assigned to the toggle button
     * We use this instead of ng-class because we also have dynamic classes to assign
     * @returns {string|string}
     */
    $scope.getRelevantClassProperties = function () {
        const classesToAdd = [$scope.data.sizeClass];

        if ($scope.data.toggleDisabled && $scope.data.applyClassesWhenDisabled) {
            classesToAdd.push($scope.data.applyClassesWhenDisabled);
        }

        if ($scope.data.isActive) {
            classesToAdd.push('toggle-active');
        } else {
            classesToAdd.push('toggle-inactive');
        }

        if ($scope.data.toggleDisabled) {
            classesToAdd.push('toggle-disabled');
        }

        if ($scope.data.transitionActive) {
            classesToAdd.push('transition');
        }

        return classesToAdd.join(' ');
    };

    function toggle($event) {
        const previousState = $scope.data.isActive;
        if (!ctrl.asyncUpdate) {
            $scope.data.isActive = !previousState;
        }
        // Signal a transition to the css (this will make the text fade-out-in).
        $scope.data.transitionActive = true;
        $timeout(() => ($scope.data.transitionActive = false), 200);

        const promise = $scope.data.onToggleClick({
            isActive: !previousState,
            $event,
            data: $scope.data.associatedData,
            parameter: $scope.data.onToggleClickParameter,
        });

        // If actually got a promise
        if (promise && promise.catch) {
            promise.catch(() => ($scope.data.isActive = previousState));
        }
    }

    /**
     * Calculates the size class according to the given size string.
     * @param size - a size string. If null, 'mod-md' is returned.
     */
    function calculateSizeClass(size) {
        if (!size) {
            return 'mod-md';
        }

        return `mod-${size}`;
    }
}

angular.module('tonkean.app').controller('ToggleCtrl', lateConstructController(ToggleCtrl));
