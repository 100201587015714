<div class="margin-left margin-right">
    <div class="margin-bottom" ng-if="data.loading">
        <i class="loading-small"></i>
    </div>

    <!-- Create comment -->
    <div ng-if="data.definition.customActionKey === 'ADD_ISSUE_COMMENT' && !data.loading">
        <!-- Comment -->
        <div class="margin-bottom">
            <!-- Title -->
            <div class="margin-right margin-top-xs">Comment:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Enter comment..."
                saved-original-expression="data.definition.commentText"
                saved-evaluated-expression="data.definition.evaluatedCommentExpression"
                on-tonkean-expression-changed="
                    onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.noComment" class="common-color-danger">
                {{ data.validationObject.noComment }}
            </div>
        </div>
    </div>

    <!-- Assign Issue -->
    <div ng-if="data.definition.customActionKey === 'ASSIGN_ISSUE' && !data.loading">
        <div class="margin-right margin-top-xs">Who should be assigned?</div>

        <div ng-if="data.validationObject.noAssignee" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.noAssignee }}
        </div>

        <div class="margin-top-xxs">
            <tnk-person-selection-configuration
                class="block margin-bottom-lg"
                group-id="data.groupId"
                workflow-version-id="{{ data.workflowVersionId }}"
                radio-id-prefix="'assignee-' + data.workflowVersionId"
                validation-object="data.validationObject"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-person-selection-configuration="data.savedPersonSelectionConfiguration"
                on-person-selection-configuration-changed="
                    onPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
                "
                no-tracks-owner-option="true"
                support-quick-create="false"
                specific-people-custom-options="data.users"
                specific-people-custom-options-refresh="refreshUsers(q)"
                configured-logic="data.configuredLogic"
                unique-identifier="assignee"
            ></tnk-person-selection-configuration>
        </div>
    </div>

    <!-- Update Status -->
    <div ng-if="data.definition.customActionKey === 'UPDATE_ISSUE_STATUS' && !data.loading">
        <!-- Title -->
        <div class="margin-right margin-top-xs">Update issue status to:</div>

        <div class="common-color-warning margin-left-xs common-size-xxxs margin-top-xs">
            <b>Note:</b>
            If your Jira instance has issue transitions configured, copy and paste the name of the relevant transition into the manual field below. Otherwise, select a status from the dropdown.
            <br/>
            For more information about issue transition, see <a target="_blank" href="https://support.atlassian.com/jira-cloud-administration/docs/work-with-issue-workflows/">Work with issue workflows</a>.
        </div>

        <div
            class="margin-top-xxs"
            ng-class="{
                'common-disabled': data.loadingStatusesError,
            }"
        >
            <tnk-dropdown-search
                ng-class="{
                    'pointer-events-none': data.loadingStatusesError,
                    'common-disabled': data.loadingStatusesError
                }"
                items="data.statusOptions"
                selected-item="data.selectedStatus"
                on-item-selected="onStatusSelected(selectedItem)"
                selected-label="{{ data.selectedStatus.displayName }}"
                closed-placeholder="Search status or type the name"
                label-classes="common-bold pointer form-control flex-grow"
                allow-custom-input="true"
                hide-dropdown-chevron="false"
                items-display-name-property="displayName"
                placeholder="Search status or type the name..."
            ></tnk-dropdown-search>
        </div>

        <div class="margin-top-6 common-error" ng-if="data.loadingStatusesError">{{ data.loadingStatusesError }}</div>
    </div>

    <!-- Add Service Desk Customer -->
    <div
        ng-if="
            (data.definition.customActionKey === 'ADD_SERVICE_DESK_CUSTOMER' ||
                data.definition.customActionKey === 'REMOVE_SERVICE_DESK_CUSTOMER') &&
            !data.loading
        "
    >
        <div class="margin-top-xs margin-bottom-xs">
            <div class="margin-right margin-top margin-bottom-xs common-color-light-grey">
                * This action is using an experimental
                <a
                    target="_blank"
                    href="{{
                        data.definition.customActionKey === 'ADD_SERVICE_DESK_CUSTOMER'
                            ? 'https://developer.atlassian.com/cloud/jira/service-desk/rest/#api-rest-servicedeskapi-servicedesk-serviceDeskId-customer-post'
                            : 'https://developer.atlassian.com/cloud/jira/service-desk/rest/#api-rest-servicedeskapi-servicedesk-serviceDeskId-customer-delete'
                    }}"
                >
                    API
                </a>
            </div>

            <!-- Service Desk ID Title -->
            <div class="margin-right margin-top margin-bottom-xs">Service Desk ID:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Enter service desk ID..."
                saved-original-expression="data.definition.serviceDeskId.originalExpression"
                saved-evaluated-expression="data.definition.serviceDeskId.evaluatedExpression"
                on-tonkean-expression-changed="
                    onServiceDeskIdChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top-xs margin-bottom-xs">
            <!-- Account IDs Title -->
            <div class="margin-right margin-top-xs margin-bottom-xs">
                Accounts IDs To
                {{ data.definition.customActionKey === 'ADD_SERVICE_DESK_CUSTOMER' ? 'Add' : 'Remove' }}:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Accounts IDs will be splitted by comma."
                ></i>
            </div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Enter accounts ids..."
                saved-original-expression="data.definition.accountsIds.originalExpression"
                saved-evaluated-expression="data.definition.accountsIds.evaluatedExpression"
                on-tonkean-expression-changed="
                    onAccountsIdsChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>
    </div>

    <!-- Add Attachment -->
    <div ng-if="data.definition.customActionKey === 'ADD_ATTACHMENT_TO_ISSUE'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            parameters-values="data.definition.parametersValues"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>
    </div>
</div>
