import type FormulaElement from '../entities/FormulaElement';

function createFormulaElement(formulaContent: string): FormulaElement {
    return {
        formulaContent,
        type: 'formula',
        children: [{ text: '' }],
    };
}

export default createFormulaElement;
