<div class="workflow-version-change-details-compare">
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <tnk-worker-audit-log-item
        activity="data.activity"
        current-user="data.currentUser"
        hide-button="true"
    ></tnk-worker-audit-log-item>

    <div class="table">
        <div class="header">Property</div>
        <div class="header">Old value</div>
        <div class="header">New value</div>
        <div class="value" ng-repeat-start="change in data.changes">{{ change.key }}</div>
        <div class="value">{{ change.old }}</div>
        <div class="value" ng-repeat-end>{{ change.new }}</div>
    </div>
</div>
