import React, { useContext } from 'react';

import ProjectIntegrationPageContext from './ProjectIntegrationPageContext';
import useEnterpriseComponentContext from '../../EnterpriseComponentsModule/hooks/useEnterpriseComponentContext';

import { angularToReact } from '@tonkean/angular-components';

const AngularViewProjectIntegrationData = angularToReact<{
    projectIntegration: any;
}>('tnk-view-project-integration-data');

const ViewProjectIntegrationData: React.FC = () => {
    const { currentProjectIntegration } = useContext(ProjectIntegrationPageContext);
    const { enterpriseComponent } = useEnterpriseComponentContext();

    // Don't show component if the current project integration is different from the key
    if (enterpriseComponent.id !== currentProjectIntegration?.id) {
        return null;
    }

    return (
        <AngularViewProjectIntegrationData
            projectIntegration={currentProjectIntegration}
            key={currentProjectIntegration?.id}
        />
    );
};

export default ViewProjectIntegrationData;
