import { Form, Formik } from 'formik';
import * as React from 'react';
import { useCallback, useContext } from 'react';
import styled from 'styled-components';

import EnterpriseComponentAuthorizationConfiguration from './EnterpriseComponentAuthorizationConfiguration';
import enterpriseComponentAuthorizationPageValidationSchema from './EnterpriseComponentAuthorizationPageValidationSchema';
import EnterpriseComponentPageTemplate from '../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import ProjectIntegrationPageContext from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageContext';

import { useLazyTonkeanService, useToastMessage } from '@tonkean/angular-hooks';
import { ErrorMessage, FormikHelpers, H1, Placeholder, SavingIndicator, Spacer } from '@tonkean/infrastructure';
import type { EnterpriseComponentAuthorization, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { EnterpriseComponentAuthorizationType, EnterpriseComponentCertificateType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { getStateError } from '@tonkean/utils';

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SavingButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const EnterpriseComponentAuthorizationPage: React.FC = () => {
    const { currentProjectIntegration, setCurrentProjectIntegration } = useContext(ProjectIntegrationPageContext);
    const emitToast = useToastMessage();

    // Lazy call to update the enterprise component variables.
    const [{ loading, error }, updateEnterpriseComponentAuthorization] = useLazyTonkeanService(
        'updateEnterpriseComponentAuthorization',
    );

    // Saving the changed authorization
    const onSave = useCallback(
        async (
            projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
            authorization: EnterpriseComponentAuthorization,
        ) => {
            try {
                await updateEnterpriseComponentAuthorization(projectIntegrationId, authorization);

                // Update local page state with the new authorization.
                setCurrentProjectIntegration((projectIntegration) => ({
                    ...projectIntegration,
                    integration: { ...projectIntegration.integration, authorizationDetails: authorization },
                }));
            } catch {
                emitToast(`Unable to update enterprise component authorization`, 'danger');
            }
        },
        [emitToast, setCurrentProjectIntegration, updateEnterpriseComponentAuthorization],
    );

    return (
        <Formik
            key={currentProjectIntegration?.id}
            validationSchema={enterpriseComponentAuthorizationPageValidationSchema}
            onSubmit={(values: EnterpriseComponentAuthorization) => {
                if (currentProjectIntegration) {
                    return onSave(currentProjectIntegration.id, values);
                }
            }}
            initialValues={{
                ...(currentProjectIntegration?.integration?.authorizationDetails || {
                    baseUrl: { originalExpression: '', evaluatedExpression: '', isStripHtmlDisabled: true },
                    integrationRequestInterceptorParameters: [],
                    type: EnterpriseComponentAuthorizationType.NONE,
                }),
                certificationType:
                    currentProjectIntegration?.integration?.authorizationDetails?.certificationType ||
                    EnterpriseComponentCertificateType.NONE,
            }}
            enableReinitialize
        >
            <FormikHelpers>
                <Form>
                    <EnterpriseComponentPageTemplate
                        name="Authorization"
                        title={
                            <HeaderWrapper>
                                <H1>Authentication</H1>

                                <SavingButtonWrapper>
                                    {error && <ErrorMessage>{getStateError(error)}</ErrorMessage>}
                                    <SavingIndicator error={error} loading={loading} />
                                    <Spacer width={8} />
                                    <Button type="submit">Save</Button>
                                </SavingButtonWrapper>
                            </HeaderWrapper>
                        }
                        description={
                            <>
                                <div>
                                    Authentication is the process by which a user is validated and given permission to
                                    access the data source.
                                </div>
                                <div>
                                    Select the authentication method and enter the credential details or use parameters
                                    to allow users to provide their credentials.
                                </div>
                            </>
                        }
                    >
                        {!currentProjectIntegration && <Placeholder $height="24px" $width="200px" />}

                        {currentProjectIntegration && (
                            <EnterpriseComponentAuthorizationConfiguration
                                projectIntegration={currentProjectIntegration}
                            />
                        )}
                    </EnterpriseComponentPageTemplate>
                </Form>
            </FormikHelpers>
        </Formik>
    );
};

export default EnterpriseComponentAuthorizationPage;
