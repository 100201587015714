import type { ITimeoutService } from 'angular';
import { useAngularService } from 'angulareact';

/**
 * A hook that returns the timeout service.
 *
 * @returns the timeout service
 */
export default function useTimeoutService(): ITimeoutService {
    return useAngularService('$timeout');
}
