<div class="share-list-modal flex flex-col mod-align-center common-close-btn-container">
    <span class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Title-->
    <div class="margin-bottom-lg text-center">
        <div class="common-size-l common-title-font">Create a public share link</div>
        <div class="common-size-xxs">{{ pm.groupsMap[groupId].name }}</div>
    </div>

    <!-- Create share link-->
    <div class="common-size-xs margin-top-xlg margin-bottom-xlg text-center" ng-hide="data.publicToken">
        <a
            class="btn btn-secondary"
            ng-click="createShareableLink()"
            data-ng-disabled="creatingLink || (pm.groupsMap[entityId] && !pm.groupsMap[entityId].canAccessWithToken && gm.currentUserIsOwnerMap[entityId])"
        >
            <span class="flex-vmiddle">
                <span class="common-color-primary margin-right-xs">
                    {{ creatingLink ? 'Creating...' : 'Create Public View Only Link' }}
                </span>
                <span class="svg-icon-sm">
                    <tnk-icon src="/images/icons/info-o.svg"></tnk-icon>
                </span>
            </span>
        </a>
        <div
            class="common-color-dark-grey common-size-xxxxs margin-top-lg"
            ng-if="
                pm.groupsMap[groupId] && !pm.groupsMap[groupId].canAccessWithToken && !gm.currentUserIsOwnerMap[groupId]
            "
        >
            <i class="fa fa-exclamation-triangle common-color-warning"></i>
            <span>Creation of a public link is disabled for this list.</span>
            <br />
            <span>
                Please ask the list creator ({{ pm.groupsMap[groupId].creator.name }}) or one of the owners to enable it
                from the list settings.
            </span>
        </div>
    </div>

    <!-- Copy share link-->
    <div ng-if="data.publicToken" class="margin-top-lg padding-top-xlg">
        <div class="margin-bottom">
            <div class="common-size-xxs common-bold">
                Anyone with this link can view this Business Report
                <span
                    class="svg-icon-sm margin-left-xxs"
                    uib-tooltip="NOTE: This link will allow anyone that have it, including guest users (non registered users), to view the Tracks in this list and their statues. Make sure you only share it with people that are allowed to see this information."
                >
                    <tnk-icon src="/images/icons/info-o.svg"></tnk-icon>
                </span>
            </div>
        </div>
        <!-- Input (readonly in angular) with copy button -->
        <div class="input-group">
            <div class="relative flex">
                <input
                    type="text"
                    id="input-publicToken"
                    class="form-control inline-block text-center common-width-full share-list-modal-link-input"
                    ng-model="data.publicTokenUrl"
                />
                <button
                    class="btn btn-no-border common-color-danger share-list-modal-revoke-link common-height-full"
                    ng-click="revokeShareableLink()"
                >
                    <span>Revoke</span>
                </button>
            </div>
            <span class="input-group-btn">
                <button
                    type="button"
                    class="btn btn-default share-list-modal-copy-btn"
                    ng-click="copyUrlToClipboard('input-publicToken')"
                >
                    Copy
                </button>
            </span>
        </div>
    </div>

    <div class="flex-grow"></div>

    <button ng-if="data.publicToken" ng-click="$dismiss()" class="btn btn-lg btn-primary padding-normal-lg-h">
        Close
    </button>
</div>
