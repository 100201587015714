import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import UserActionAvatar from './UserActionAvatar';
import ViewDetailsModal from './ViewDetailsModal';
import useGetWorkerRunsItemFormattedDate from '../../../../../../HistoryModule/hooks/useGetWorkerRunsItemFormattedDate';
import AuditLogGroupChip from '../../AuditLogChangesList/components/grouped/AuditLogGroupChip';
import type UserActionItem from '../entities/UserActionItem';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { HighlightableText, Placeholder, Tooltip } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const AuditLogItemWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 13px 4px 13px 19px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
`;

const LogSummary = styled.div`
    display: flex;
    align-items: flex-start;
`;

const ActionType = styled(HighlightableText)`
    color: ${Theme.colors.gray_500};
    text-transform: capitalize;
`;

const EntityName = styled(HighlightableText)`
    margin-left: 4px;
    font-weight: 500;
`;

const ActivityType = styled(HighlightableText)`
    margin-left: 4px;
    color: ${Theme.colors.gray_500};
`;

const LogContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const Subtitle = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    margin-top: 5px;
`;

const Date = styled.span`
    margin-left: 2px;
`;

const ActorNamePlaceholder = styled(Placeholder)`
    display: inline-block;
    margin-right: 4px;
`;

const ActorName = styled(HighlightableText)`
    margin-right: 4px;
`;

const Title = styled.div`
    display: flex;
`;

const GroupChip = styled(AuditLogGroupChip)`
    margin-right: 8px;
`;

interface Props {
    log: UserActionItem;
    showGroupLabel?: boolean;
    searchTerm?: string;
}

const UserActionRow: React.FC<Props> = ({ log, showGroupLabel, searchTerm }) => {
    const personCache = useAngularService('personCache');

    // We have to use cache because a lot of the logs have the same actor
    const { data: actor, loading: actorLoading } = useAsyncMethod(personCache, 'getEntityById', log.actorId, false);
    const { formattedDate, formattedTime, fullDate } = useGetWorkerRunsItemFormattedDate(log.created);

    return (
        <AuditLogItemWrapper>
            <LogSummary>
                <UserActionAvatar loading={actorLoading} person={actor} />
                <LogContent>
                    <Title>
                        {showGroupLabel && <GroupChip>{log.categoryTypeFriendlyName}</GroupChip>}
                        <ActionType highlightText={searchTerm} text={log.actionTypeFriendlyName} inline />
                        <EntityName highlightText={searchTerm} text={log.targetEntityDisplayName} inline />
                        <ActivityType highlightText={searchTerm} text={log.activityTypeFriendlyName} inline />
                    </Title>

                    <Subtitle>
                        {actorLoading ? (
                            <ActorNamePlaceholder $width="100px" $height="12px" />
                        ) : (
                            <ActorName highlightText={searchTerm} text={actor?.name} inline /> || 'Unknown User'
                        )}
                        -
                        <Tooltip content={fullDate}>
                            <Date>
                                {formattedDate}
                                {formattedDate ? ',' : ''} {formattedTime}
                            </Date>
                        </Tooltip>
                    </Subtitle>

                    <div />
                </LogContent>
            </LogSummary>

            {(log.before || log.after) && (
                <ViewDetailsModal name={log.targetEntityDisplayName} before={log.before} after={log.after} />
            )}
        </AuditLogItemWrapper>
    );
};

export default UserActionRow;
