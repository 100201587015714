import type { AngularServices } from 'angulareact';
import type { SCIMTonkeanRole } from '@tonkean/tonkean-entities';
import { getInviteMessages } from '@tonkean/constants';

/**
 * A single point service used to send invites to tonkean.
 */
class InviteManager {
    protected constructor(
        private $rootScope: AngularServices['$rootScope'],
        private $q: AngularServices['$q'],
        private tonkeanService: AngularServices['tonkeanService'],
        private onBoardingManager: any,
        private projectManager: AngularServices['projectManager'],
    ) {}

    /**
     * Sends out invites to the given users for the given project.
     * @param projectId - the project id to invite users to.
     * @param usersToInvite - the users to invite (an object of the form of {email: <email>, name: <name>}.
     * @param message - the invite message to send to the user. If not supplied, a default message is used.
     * @param isManual - true if the invite should be sent even if it was already sent, false otherwise.
     * @param groupIds - an array of private group ids to add the invitees to.
     *                   This is allowed only if the caller is the project's owner or the group's creator.
     * @param showSuccessMessage - whether a success message should be emitted in the app or not.
     * @returns {*} - a promise.
     */
    async sendInvites(
        projectId: string,
        usersToInvite: { email: string; name: string }[],
        message: string | undefined,
        isManual: boolean,
        groupIds: string[],
        showSuccessMessage: boolean,
        noOp: boolean = false,
        tonkeanRoles: SCIMTonkeanRole[],
    ) {
        const defaultMessage = getInviteMessages(this.projectManager.project.name).default;

        const data = await this.tonkeanService.sendInvites(
            projectId,
            usersToInvite,
            message || defaultMessage,
            isManual,
            groupIds,
            noOp,
            tonkeanRoles,
        );
        // Mark the invite step in the on boarding as completed.
        this.onBoardingManager.completeStep('invite');
        // Pop a success alert if requested.
        if (showSuccessMessage) {
            this.$rootScope.$emit('alert', { msg: 'Invites sent.', type: 'success' });
        }
        return this.$q.resolve(data);
    }
}

angular.module('tonkean.app').service('inviteManager', InviteManager);

export default InviteManager;
