<div ng-show="!data.duplicateOrCreateMode && !data.showGoogleFieldSetup" class="clearfix">
    <div class="common-bold padding-bottom">
        <span>Live data settings:</span>
        <a ng-click="toggleShowGoogleFieldStep()">Change</a>
    </div>
    <div class="common-size-xxxxs common-color-grey pull-right text-right">
        {{ data.view.displayName }}
        <br />
        {{ data.view.description }}
    </div>
    <div>
        Metric: {{ data.metric.displayName }} (Last{{ data.timespan }} day)
        <br />
        Dimension: "{{ data.dimension.displayName }}"
        <span ng-if="data.segment">(of segment: {{ data.segment.displayName }})</span>
    </div>
</div>
<div ng-if="data.duplicateOrCreateMode || data.showGoogleFieldSetup" ng-switch="data.googleStep">
    <!--Views setup-->
    <div ng-switch-when="selectView">
        <b>Select a View:</b>
        <div class="integration-instance-selection-box margin-top">
            <div ng-if="data.loadingExternal" class="text-center margin-top-lg"><i class="loading-medium"></i></div>
            <div
                ng-click="onViewSelected(selection)"
                ng-repeat="selection in data.availableViews"
                class="list-item btn-default pointer common-size-xxs"
            >
                <strong>{{ selection.displayName }}</strong>
                <div class="common-size-xxxxs common-color-grey">
                    <span>{{ selection.description }}</span>
                </div>
            </div>
        </div>
    </div>
    <!--Metrics and dimensions setup-->
    <div ng-switch-when="selectFields">
        Selected View:
        <b>{{ data.view.displayName }}</b>
        -
        <a ng-click="loadExternalViews(true); data.googleStep= 'selectView';">Change view</a>
        <hr />
        <div class="flex-vmiddle margin-top flex-wrap">
            <div class="flex-grow margin-right-lg margin-bottom">
                <div class="common-size-xxs margin-bottom">Value (Metric/Dimension):</div>
                <ui-select
                    required
                    theme="bootstrap"
                    ng-model="data.metric"
                    ng-change="externalFieldSelected(data.metric.displayName)"
                    ng-disabled="!data.availableFields"
                >
                    <ui-select-match placeholder="Select a metric/dimension">
                        {{ $select.selected.displayName }} - ({{ $select.selected.value }})
                    </ui-select-match>
                    <ui-select-choices
                        group-by="'type'"
                        group-filter="['metric','dimension']"
                        repeat="p in data.availableFields | filter:$select.search"
                    >
                        <div
                            ng-bind-html="p.displayName + ' (' + p.value +')' | escapeHtml | highlight: $select.search"
                        ></div>
                    </ui-select-choices>
                </ui-select>
                <i class="loading-small create-field-selection-loading" ng-if="loadingExternal"></i>
            </div>
            <div class="flex-grow relative margin-bottom">
                <div class="common-size-xxs margin-bottom">Select a timeframe:</div>
                <select
                    required
                    ng-model="data.timespan"
                    class="form-control"
                    ng-change="onTimeFrameSelected()"
                    placeholder="Select a timeframe"
                >
                    <option value="1">Today</option>
                    <option value="3">Last 3 days</option>
                    <option value="7">Last 7 days</option>
                    <option value="14">Last 14 days</option>
                    <option value="30">Last 30 days</option>
                </select>
            </div>
        </div>

        <div class="flex-grow margin-bottom-xs margin-top" ng-if="data.targetType !== 'GLOBAL'">
            <div class="common-size-xxs margin-bottom">
                Match Track to dimension:
                <i
                    class="fa fa-info-circle margin-left common-color-grey"
                    uib-tooltip="The dimension in Google Analytics that will contain your track field value(for ex: Customer special identifier, Initiative name, Campaign name etc)"
                ></i>
            </div>
            <ui-select
                required
                theme="bootstrap"
                ng-model="data.dimension"
                ng-change="onDimensionSelected()"
                ng-disabled="!data.availableFields"
            >
                <ui-select-match placeholder="Select a dimension">
                    {{ $select.selected.displayName }} - ({{ $select.selected.value }})
                </ui-select-match>
                <ui-select-choices
                    repeat="p in data.availableFields | filter: { type: 'dimension' } | filter:$select.search"
                >
                    <div
                        ng-bind-html="p.displayName + ' (' + p.value +')' | escapeHtml | highlight: $select.search"
                    ></div>
                </ui-select-choices>
            </ui-select>
            <i class="loading-small create-field-selection-loading" ng-if="loadingExternal"></i>
        </div>

        <div class="common-size-xxs margin-top margin-bottom-xs" ng-if="data.targetType !== 'GLOBAL'">
            Match it with the Track's:
        </div>
        <!-- Field to filter on -->
        <tnk-field-selector
            ng-if="data.targetType !== 'GLOBAL'"
            class="filter-min-width flex-no-shrink"
            ng-class="{ 'margin-bottom-xs': smallMod }"
            selected-field="data.selectedField"
            items-source="COLUMN"
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            consider-all-groups="{{ considerAllGroups }}"
            project-integration="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
            display-integration-indication="true"
            external-type="selectedEntityType"
            show-field-inspect-modal="scm.getSyncConfig(data.workflowVersionId).projectIntegration"
            inspect-entity-type="scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata.entity"
            on-field-selected="onFieldOptionSelected(selectedField, selectedFieldIdentifier)"
            add-custom-fields="addCustomFields(fieldOptions, projectIntegration, externalType)"
            reload-fields="data.reloadFields"
            group-by="groupBy"
            place-holder="Search data field"
            view-only="viewOnly"
            append-to-body="appendToBody"
            special-fields-for-features="specialFieldsForFeatures"
            reload-fields-on-new-field-definition-creation="reloadFieldsOnNewFieldDefinitionCreation"
        ></tnk-field-selector>

        <div class="margin-top">
            <a ng-if="!data.advancedOptions" class="common-size-xxs" ng-click="data.advancedOptions = true;">
                Advanced
            </a>
        </div>

        <div ng-if="data.advancedOptions" class="flex-grow margin-bottom-xs margin-top">
            <div class="common-size-xxs margin-bottom">Select a segment:</div>
            <ui-select
                theme="bootstrap"
                ng-model="data.segment"
                ng-change="onDimensionSelected()"
                ng-disabled="!data.availableSegments"
            >
                <ui-select-match placeholder="Select a segment">
                    {{ $select.selected.displayName }} - ({{ $select.selected.value }})
                </ui-select-match>
                <ui-select-choices repeat="p in data.availableSegments | filter:$select.search">
                    <div
                        ng-bind-html="p.displayName + ' (' + p.value +')' | escapeHtml | highlight: $select.search"
                    ></div>
                </ui-select-choices>
            </ui-select>
            <i class="loading-small create-field-selection-loading" ng-if="loadingExternal"></i>
        </div>
    </div>
</div>

<hr />
