<div class="private-group-modal">
    <div class="common-close-btn-group flex mod-align-baseline">
        <!-- List settings menu (only in mobile) -->
        <div
                class="dropbox relative flex-no-shrink margin-right visible-xs-inline-block"
                uib-dropdown
                on-toggle="toggled(open)"
        >
            <span class="svg-icon-smd svg-icon-hover-dark-grey pointer dropdown-toggle" uib-dropdown-toggle>
                <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
            </span>
            <ul uib-dropdown-menu class="dropdown-menu-right" role="menu">
                <!-- Duplicate list -->
                <li>
                    <a ng-click="openDuplicateGroup()">
                        <span class="svg-icon-sm svg-icon-align-text-top margin-right-xs">
                            <tnk-icon src="/images/icons/duplicate.svg"></tnk-icon>
                        </span>
                        Duplicate List
                    </a>
                </li>
                <!-- Delete list\worker -->
                <li>
                    <a ng-click="removeGroupModal()">
                        <span class="svg-icon-sm svg-icon-align-text-top margin-right-xs">
                            <tnk-icon src="/images/icons/delete.svg"></tnk-icon>
                        </span>
                        Delete Module
                    </a>
                </li>
            </ul>
        </div>

        <!-- Close button -->
        <div class="common-close-btn mod-inline mod-big mod-with-text margin-normal-sm" ng-click="$dismiss()">
            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
        </div>
    </div>

    <!-- Loading state -->
    <div class="text-center" ng-show="loading">
        <i class="loading-large"></i>
        <h3>{{ data.group ? 'Saving' : 'Creating' }}...</h3>
    </div>
    <div class="private-group-modal-wrapper" ng-hide="loading">
        <!-- Modal menu (desktop) -->
        <div class="private-group-modal-menu common-size-xxs hidden-xs">
            <div
                    class="private-group-modal-menu-item"
                    ng-class="{ 'mod-selected': selectedTab === tabs.settings }"
                    ng-click="selectTab(tabs.settings)"
            >
                Settings
            </div>
            <div
                    class="private-group-modal-menu-item require-mod-left2"
                    ng-class="{ 'mod-selected': selectedTab === tabs.innerTracksTemplate }"
                    ng-click="selectTab(tabs.innerTracksTemplate)"
            >
                Item Defaults
            </div>
            <div
                    class="private-group-modal-menu-item require-mod-left2"
                    ng-class="{ 'mod-selected': selectedTab === tabs.activity }"
                    ng-click="selectTab(tabs.activity)"
            >
                Activity
            </div>
        </div>
        <!-- Modal content (tabs) -->
        <div id="private-group-modal-content" class="private-group-modal-content relative">
            <form name="privateGroupForm" class="padding-top" ng-submit="submit()" novalidate>
                <!-- A transparent screen for when the group can't be edited. -->
                <div ng-if="data.isReadonly" class="block-view"></div>

                <!-- Shared title -->
                <div class="common-title margin-right">
                    {{ data.group ? data.group.name : 'Create a new module' }}
                    <span class="common-thin common-size-s" ng-if="data.group">
                        ({{ data.group.visibilityType | lowercase }})
                    </span>
                </div>

                <!-- Group is readonly message -->
                <div
                        ng-if="data.isReadonly"
                        class="alert-warning common-bold common-size-xxs inline-block padding-normal-sm"
                        uib-alert
                >
                    <!-- When user is not allowed -->
                    <div ng-if="!pm.groupsMap[data.groupId].updateStartTime" class="flex-vmiddle">
                        <i class="svg-icon margin-right-xs">
                            <tnk-icon src="/images/icons/info-o.svg"></tnk-icon>
                        </i>
                        <span class="common-color-primary-black">
                            Only the module creator ({{ pm.groupsMap[data.groupId].creator.name }}) and makers can edit
                            these settings or delete this module
                        </span>
                    </div>
                    <!-- When the group is being saved -->
                    <div ng-if="pm.groupsMap[data.groupId].updateStartTime" class="flex-vmiddle">
                        <i class="loading-small margin-right-xs"></i>
                        <span class="common-color-primary-black">
                            The list's settings cannot be changed because a save is currently is progress.
                        </span>
                    </div>
                </div>

                <!-- Modal menu (mobile) -->
                <div class="private-group-modal-menu-mobile common-size-xs visible-xs-flex">
                    <div
                            class="private-group-modal-menu-item"
                            ng-class="{ 'mod-selected': selectedTab === tabs.settings }"
                            ng-click="selectTab(tabs.settings)"
                    >
                        Settings
                    </div>
                    <div
                            class="private-group-modal-menu-item"
                            ng-class="{ 'mod-selected': selectedTab === tabs.innerTracksTemplate }"
                            ng-click="selectTab(tabs.innerTracksTemplate)"
                    >
                        Defaults
                    </div>
                </div>

                <!-- Settings tab -->
                <div id="private-group-modal-{{ tabs.settings }}" ng-show="selectedTab === tabs.settings">
                    <h3 class="common-title-xxs margin-top-lg padding-top hidden-xs">Settings</h3>
                    <hr class="common-separator hidden-xs"/>
                    <tnk-edit-group
                            group="data.group"
                            control-object="data.editGroupControlObject"
                            inner-tracks-template="data.innerTracksTemplate"
                            on-owners-updated="onOwnersUpdated()"
                            is-valid="data.isValid"
                            error="error"
                            loading="loading"
                            hide-list-name="data.hideListName"
                    ></tnk-edit-group>
                </div>

                <!-- Inner tracks template tab -->
                <div
                        id="private-group-modal-{{ tabs.innerTracksTemplate }}"
                        ng-show="selectedTab === tabs.innerTracksTemplate"
                        class="padding-top"
                >
                    <!-- Inner tracks template title -->
                    <div class="common-bold common-size-xs">Item Defaults</div>
                    <hr class="margin-top-xs margin-bottom-xlg"/>

                    <!-- Default owner -->
                    <div class="common-bold common-size-xs">Default Owner</div>
                    <div class="common-size-xxs margin-top margin-bottom-xs">
                        Assign an Owner of every new manually created high-level Item.
                    </div>
                    <p class="common-color-grey common-size-xxxxs">
                        * Owners on Items created via integration intake source will be assigned based on intake source
                        settings.
                    </p>
                    <div class="default-owner-selector margin-top">
                        <tnk-deprecated-people-selector
                                no-validation="false"
                                placeholder="Search for a name"
                                selected-people="data.defaultManualSyncOwners"
                                no-custom="true"
                                max-tags="1"
                                group="groupId"
                        ></tnk-deprecated-people-selector>
                    </div>

                    <hr class="common-separator mod-margin-xlg"/>
                    <div class="common-bold common-size-xs margin-bottom-xs margin-top">Auto-Archive Items</div>

                    <div class="flex-vmiddle flex-wrap common-size-xxs margin-bottom">
                        <div class="padding-right-none margin-right">Auto-archive done items after</div>

                        <input
                                type="number"
                                min="0"
                                class="form-control input org-seats-box mod-input block margin-right"
                                ng-model="data.group.archiveDoneAfterDays"
                        />
                        days.
                    </div>
                    <hr class="common-separator mod-margin-xlg"/>
                </div>

                <!-- Activity tab-->
                <div
                        id="private-group-modal-{{ tabs.activity }}"
                        ng-show="selectedTab === tabs.activity"
                        class="padding-top"
                >
                    <tnk-worker-audit-logs
                            group-id="data.groupId"
                            open-activity-actor-person-selection-callback="openActivityActorPersonSelection"
                            selected-actor-people-to-filter-on="data.selectedActorPeopleToFilterOn"
                            control-object="data.workerAuditLogsControlObject"
                            group-by-toggle-default="false"
                            hide-group-by-toggle="true"
                    ></tnk-worker-audit-logs>
                </div>

                <!-- Activity actor selection popover -->
                <div id="activity-actor-selection-popover-parent">
                    <div id="activity-actor-selection-popover" tnk-dnd-undraggable>
                        <div
                                click-outside="resetActivityActorSelectionPopover()"
                                outside-if-not="activity-actor-selection-popover, activity-actor-selection-popover-container, tag-item, autocomplete, track-leader-avatar, track-owner-name"
                        >
                            <div
                                    uib-popover-template="'./activityActorSelectionPopover.template.html'"
                                    popover-is-open="data.activityActorSelectionPopoverOpen"
                                    popover-placement="bottom"
                            ></div>
                        </div>
                    </div>
                </div>

                <!-- Error message -->
                <div class="text-danger" ng-if="error">{{ error | error }}</div>

                <div>
                    <!-- Action buttons -->
                    <div class="flex-vmiddle margin-top-lg">
                        <button
                                type="button"
                                class="btn btn-lg btn-default margin-right"
                                ng-click="$dismiss()"
                                ng-class="{ 'dont-block': data.isReadonly }"
                                ng-if="selectedTab !== tabs.activity"
                        >
                            Cancel
                        </button>

                        <button
                                type="submit"
                                class="btn btn-lg btn-primary margin-right"
                                ng-disabled="!privateGroupForm.$valid || !data.isValid"
                                ng-if="selectedTab !== tabs.activity"
                        >
                            {{ data.group ? 'Save' : 'Create' }}
                        </button>
                        <div class="flex-grow"></div>
                        <div
                                class="btn-group common-size-xxs flex-no-shrink hidden-xs flex mod-align-center"
                                ng-if="data.group && selectedTab === tabs.settings"
                        >
                            <label class="btn btn-default" ng-click="openDuplicateGroup()">
                            <span class="svg-icon-sm svg-icon-align-text-top margin-right-xxs">
                                <tnk-icon src="/images/icons/duplicate.svg"></tnk-icon>
                            </span>
                                <span
                                        class="common-color-grey"
                                        data-automation="private-group-modal-duplicate-module-button"
                                >
                                Duplicate
                            </span>
                            </label>
                            <label
                                    class="btn btn-default"
                                    ng-click="removeGroupModal()"
                            >
                            <span class="svg-icon-sm svg-icon-align-text-top margin-right-xxs">
                                <tnk-icon src="/images/icons/delete.svg"></tnk-icon>
                            </span>
                                <span class="common-color-grey"
                                      data-automation="private-group-modal-delete-module-button">
                                Delete Module
                            </span>
                            </label>
                            <i ng-if="data.enableDuplicationLoading" class="loading-small margin-left-6"
                               data-automation="loading-circle"></i>
                        </div>
                    </div>
                    <div ng-if="data.showMatchedItemsExistInModuleError" class="common-color-danger margin-top-6">Cannot
                        duplicate a module that has a dependency to another module
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
