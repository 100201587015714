import template from './tnkCreateProductForm.template.html?angularjs';
/**
 * Created by ShaLi on 22/11/2015.
 */

function tnkCreateProductForm() {
    return {
        restrict: 'E',
        scope: {
            project: '=', // The created project obj
            editProject: '=', // A project to edit.
            onCancel: '&', // A cancel callback.
            state: '=?', // The current state of create from.
        },
        transclude: true,
        template,
        controller: 'CreateProductCtrl',
    };
}
export default angular.module('tonkean.shared').directive('tnkCreateProductForm', tnkCreateProductForm);
