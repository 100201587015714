import isEqual from 'lodash.isequal';
import { useEffect, useMemo, useState } from 'react';

import useAllInitiativeFieldsValues from './useAllInitiativeFieldsValues';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type {
    Initiative,
    ServerFormulaExpressionNode,
    StatelessJsonExpressionsEvaluationRequest,
    StatelessJsonExpressionsEvaluationResponse,
} from '@tonkean/tonkean-entities';
import { FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP } from '@tonkean/tonkean-utils';

function useInitiativeJsonExpressionServerEvaluation(
    initiative: Initiative | undefined,
    expression: ServerFormulaExpressionNode,
) {
    const initiativeFieldsMap = useAllInitiativeFieldsValues(initiative);

    const fieldsFromExpression = useMemo(() => {
        if (!initiative) {
            return {};
        }

        const expressionStr = JSON.stringify(expression);

        const initiativeFieldsFoundInExpression: Record<string, string> = {
            [FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP.TNK_PROJECT_ID.id]: initiative.project.id,
            [FORMULA_SPECIAL_FIELD_ID_TO_DEFINITION_MAP.TNK_INITIATIVE_ID.id]: initiative.id,
        };

        Object.keys(initiativeFieldsMap).forEach((fieldId) => {
            if (expressionStr.includes(fieldId)) {
                initiativeFieldsFoundInExpression[fieldId] = initiativeFieldsMap[fieldId] || '';
            }
        });

        return initiativeFieldsFoundInExpression;
    }, [initiativeFieldsMap, expression, initiative]);

    const [{ data: evaluateJsonExpressionsResponse, args: evaluateJsonExpressionsArgs }, evaluateJsonExpressions] =
        useLazyTonkeanService('statelessJsonEvaluateExpressions');

    const [lastLoadedEvaluateExpressionsResponse, setLastLoadedEvaluateJsonExpressionsResponse] =
        useState<StatelessJsonExpressionsEvaluationResponse>({});
    const [lastEvaluateJsonExpressionsParams, setLastEvaluateJsonExpressionsParams] = useState<
        StatelessJsonExpressionsEvaluationRequest | undefined
    >();

    useEffect(() => {
        if (evaluateJsonExpressionsResponse && evaluateJsonExpressionsArgs?.[0]) {
            setLastEvaluateJsonExpressionsParams(evaluateJsonExpressionsArgs?.[0]);
            setLastLoadedEvaluateJsonExpressionsResponse(evaluateJsonExpressionsResponse);
        }
    }, [evaluateJsonExpressionsResponse, evaluateJsonExpressionsArgs]);

    useEffect(() => {
        const evaluateJsonExpressionsRequest = {
            fieldValues: fieldsFromExpression,
            expressions: [
                {
                    key: 'expression',
                    expression,
                },
            ],
        };

        const hasCachedResponseForRequestWithSameParams = isEqual(
            lastEvaluateJsonExpressionsParams,
            evaluateJsonExpressionsRequest,
        );
        const currentlyOngoingRequestHasSameParams = isEqual(
            evaluateJsonExpressionsRequest,
            evaluateJsonExpressionsArgs?.[0],
        );

        if (hasCachedResponseForRequestWithSameParams || currentlyOngoingRequestHasSameParams) {
            return;
        }

        evaluateJsonExpressions(evaluateJsonExpressionsRequest);
    }, [
        fieldsFromExpression,
        expression,
        evaluateJsonExpressions,
        lastEvaluateJsonExpressionsParams,
        evaluateJsonExpressionsArgs,
    ]);

    return lastLoadedEvaluateExpressionsResponse?.['expression']?.result || '';
}

export default useInitiativeJsonExpressionServerEvaluation;
