import React from 'react';
import styled from 'styled-components';

import AmazonGiftCard from './assets/amazonGiftCard.png';
import { ReactComponent as G2 } from './assets/G2.svg';
import { ReactComponent as Tnk14Days } from './assets/tnk14days.svg';
import FreeTrialBackgroundImageUrl from './freeTrialBox/assets/background.png?url';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { Breakpoint } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';

const LoginPageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        grid-template-columns: auto;
    }
`;

const LoginColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 20px;
`;

const Tnk14DaysStyled = styled(Tnk14Days)`
    width: 80%;
    height: 80%;
    min-width: 500px;
    max-width: 750px;
`;

const FreeTrialColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e3e1ff;

    background-image: url(${FreeTrialBackgroundImageUrl});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;

const AmazonGiftCardStyled = styled.img`
    height: 82px;
`;

const WriteUsReviewContainer = styled.div`
    position: fixed;
    width: 463px;
    bottom: 20px;
    right: 88px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 14px;
    background: ${Theme.colors.white};
    box-shadow: 0px 4px 30px rgba(54, 33, 88, 0.14);
    border-radius: 8px;
    padding: 17px 15px;
`;

const WriteUsReviewRightSideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const WriteUsReviewText = styled.div`
    color: #080607;
    font-size: ${FontSize.MEDIUM_14};
    line-height: 20px;
`;

const WriteUsReviewButton = styled(Clickable)`
    background: #ff492c;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 8px;
    border-radius: 6px;
    width: 150px;
`;

const WriteUsReviewButtonText = styled.div`
    color: ${Theme.colors.white};
    font-weight: 700;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
`;

const AuthPageLayoutWrapper: React.FC<{ children }> = ({ children }) => {
    const { data: globalConfig } = useTonkeanService('getLoginOptions');
    const showGiftCard = globalConfig?.['FEATURE_SHOW_GIFT_CARD_IN_LOGIN'] ?? null;

    return (
        <LoginPageWrapper>
            <LoginColumn>{children}</LoginColumn>
            <FreeTrialColumn>
                <Tnk14DaysStyled />
                {showGiftCard && (
                    <WriteUsReviewContainer>
                        <AmazonGiftCardStyled src={AmazonGiftCard} />
                        <WriteUsReviewRightSideWrapper>
                            <WriteUsReviewText>
                                Take a moment to share your feedback about Tonkean and receive a{' '}
                                <strong>$50 Amazon gift card.</strong>
                            </WriteUsReviewText>
                            <WriteUsReviewButton
                                href="https://www.g2.com/contributor/tonkean-v?secure%5Bpage_id%5D=tonkean-v&secure%5Brewards%5D=true&secure%5Btoken%5D=d9142a9fc275c9c2c6748d4b0307290ff8746b983691ef17e79fa3f1f261d80f"
                                openInNewTab
                            >
                                <G2 />
                                <WriteUsReviewButtonText>Write A Review</WriteUsReviewButtonText>
                            </WriteUsReviewButton>
                        </WriteUsReviewRightSideWrapper>
                    </WriteUsReviewContainer>
                )}
            </FreeTrialColumn>
        </LoginPageWrapper>
    );
};

export default AuthPageLayoutWrapper;
