import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const FormSettingsInput = styled.input<{
    withMarginTop?: boolean;
    halfWidth?: boolean;
    valid?: boolean;
}>`
    width: ${({ halfWidth }) => (halfWidth ? '50%' : '100%')};
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 3px;
    line-height: 2;
    padding-left: 8px;
    height: 26px;
    max-height: 26px;

    &[disabled] {
        background-color: ${Theme.colors.brightestGray};
    }

    ${({ withMarginTop = true }) =>
        withMarginTop &&
        css`
            margin-top: 8px;
        `}

    ${({ valid = true }) =>
        !valid &&
        css`
            border: 1px solid ${Theme.colors.error};
        `}
`;

export default FormSettingsInput;
