<div class="activity-actor-selection-popover-content">
    <tnk-deprecated-people-selector
        class="common-width-full"
        no-validation="false"
        selected-people="data.selectedActorPeopleToFilterOn"
        on-tag-added="onActorPersonAdded()"
        on-tag-removed="onActorPersonRemoved()"
        placeholder="Choose people..."
    ></tnk-deprecated-people-selector>
</div>
