import useWorkflowVersionCacheSynchronizer from './useWorkflowVersionCacheSynchronizer';
import useTonkeanQuery from '../utils/reactQuery/useTonkeanQuery';

import { getWorkflowVersion } from '@tonkean/network-service';
import type { TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';

const useTDLWorkflowVersionById = (id: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined) => {
    const workflowVersionCacheSynchronizer = useWorkflowVersionCacheSynchronizer();

    return useTonkeanQuery<WorkflowVersion | undefined>({
        queryUniqueKey: 'getWorkflowVersionById',
        propsUniqueKeysArray: [id],
        queryFn: async (networkService) => {
            return getWorkflowVersion(networkService, id!);
        },
        enabledFn: () => !!id,

        synchronizeCachesFn: workflowVersionCacheSynchronizer,
    });
};

export default useTDLWorkflowVersionById;
