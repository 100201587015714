import React, { useContext } from 'react';
import styled from 'styled-components';

import { Field, InformationTooltip, Input, SavingIndicator } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import { FormBuilderSections } from '@tonkean/tonkean-entities';
import { InformationIconSize } from '@tonkean/tui-theme/sizes';

const Hr = styled.hr`
    width: 100%;
    margin-bottom: 10px;
`;

const MinimumItemsAmountFieldLabel = styled.div`
    display: inline-flex;
    align-items: center;
    line-height: 14px;
`;

const InformationTooltipWrapper = styled(InformationTooltip)`
    margin-left: 6px;
`;

const MinimumItemsAmountInput = styled(Input)`
    width: 180px;
`;

interface Props {
    minimumItemsAmount?: number;
    onFormDefinitionChanged: (amountRequired: number) => void;
}

const FormSettingsCollectInnerItemsAmountValidation: React.FC<Props> = ({
    minimumItemsAmount,
    onFormDefinitionChanged,
}) => {
    const { editedSections } = useContext(FormBuilderContext);
    return (
        <>
            <Hr />
            <Field
                label={
                    <MinimumItemsAmountFieldLabel>
                        <span>Set minimum number of required items (answers)</span>
                        <InformationTooltipWrapper iconSize={InformationIconSize.SMALL}>
                            Validation for the required number of answers to be provided
                        </InformationTooltipWrapper>
                    </MinimumItemsAmountFieldLabel>
                }
            >
                <div className="flex-vmiddle">
                    <MinimumItemsAmountInput
                        type="number"
                        min="1"
                        value={minimumItemsAmount}
                        onChange={({ target: { valueAsNumber } }) => onFormDefinitionChanged(valueAsNumber)}
                    />
                    <SavingIndicator state={editedSections[FormBuilderSections.ItemsAmountValidation]} />
                </div>
            </Field>
        </>
    );
};

export default FormSettingsCollectInnerItemsAmountValidation;
