import React from 'react';
import styled from 'styled-components';

import AuditLogGroupChip from './AuditLogGroupChip';
import AuditLogActionType from '../../../UserActionItem/entities/AuditLogActionType';
import type AuditLogGroup from '../../entities/AuditLogGroup';

import { Theme } from '@tonkean/tui-theme';

const Header = styled.div`
    display: flex;
    align-items: center;
    height: 19px;
`;

const GroupSummary = styled.span`
    margin-left: 7px;
`;

const GroupSummaryTypeLabel = styled.span`
    color: ${Theme.colors.gray_500};
    text-transform: capitalize;
    margin-right: 4px;
`;

const GroupSummaryDescriptionLabel = styled.span`
    font-weight: 500;
`;

const StatusIndicator = styled.div<{ status: AuditLogActionType }>`
    position: absolute;
    width: 2px;
    left: 0;
    top: 3px;
    bottom: 3px;
    border-radius: 2px;
    background: ${({ status }) => actionTypeToBackgroundColor[status]};
`;

const actionTypeToBackgroundColor: Record<AuditLogActionType, string> = {
    [AuditLogActionType.CREATE]: Theme.colors.primary,
    [AuditLogActionType.DELETE]: Theme.colors.warning,
    [AuditLogActionType.UPDATE]: Theme.colors.success,
    [AuditLogActionType.VIEW]: Theme.colors.gray_300,
};

interface Props {
    group: AuditLogGroup;
    itemsCount: number;
}

const AuditLogGroupRow: React.FC<Props> = ({ group, itemsCount }) => {
    return (
        <Header>
            <StatusIndicator status={group.lastActionType} />
            <AuditLogGroupChip>{group.groupCategoryFriendlyName}</AuditLogGroupChip>
            <GroupSummary>
                <GroupSummaryTypeLabel>{group.lastActionTypeFriendlyName}</GroupSummaryTypeLabel>
                <GroupSummaryDescriptionLabel>
                    {group.lastTargetEntityDisplayName} ({itemsCount})
                </GroupSummaryDescriptionLabel>
            </GroupSummary>
        </Header>
    );
};

export default AuditLogGroupRow;
