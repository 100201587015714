import React, { useEffect, useState } from 'react';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

const WorkdayCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps> = ({ onChangeOrInitIntegration }) => {
    const [url, setUrl] = useState<string>('');
    const [uniqueIdField, setUniqueIdField] = useState<string>('');
    const [itemTitleField, setItemTitleField] = useState<string>('');

    useEffect(() => {
        const projectData = {
            url,
            uniqueIdField,
            itemTitleField,
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData: projectData,
            },
            false,
        );
    }, [itemTitleField, onChangeOrInitIntegration, uniqueIdField, url]);

    return (
        <div className="flex flex-col">
            <label className="control-label text-center common-size-s">Custom Report</label>
            <div className="margin-bottom-lg info-box common-size-xxs common-color-dark-grey">
                You can use Workday Custom Reports with web service enabled in order to continuously collect data.
                <div className="margin-top-md">* Tonkean supports the JSON format of the Custom Report.</div>
            </div>

            <div className="form-group">
                <label htmlFor="login-api-url" className="col-sm-3 control-label">
                    Report Web Service URL
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        value={url}
                        className="form-control"
                        id="login-api-url"
                        onChange={({ target }) => setUrl(target.value)}
                        autoComplete="off"
                        placeholder="URL"
                    />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="login-api-unique-id-field" className="col-sm-3 control-label">
                    Unique Identifier field
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        value={uniqueIdField}
                        className="form-control"
                        onChange={({ target }) => setUniqueIdField(target.value)}
                        id="login-api-unique-id-field"
                        autoComplete="off"
                        placeholder="Unique Identifier field"
                    />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="login-api-item-title-field" className="col-sm-3 control-label">
                    Item Title field
                </label>
                <div className="col-sm-8">
                    <input
                        type="text"
                        value={itemTitleField}
                        className="form-control"
                        onChange={({ target }) => setItemTitleField(target.value)}
                        id="login-api-item-title-field"
                        autoComplete="off"
                        placeholder="Item Title field"
                    />
                </div>
            </div>
        </div>
    );
};

export default WorkdayCustomizeSetupComponent;
