import type CreateAfterOption from '../../../entities/CreateAfterOption';
import { RunOnTypes } from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import logicConfigModes from '../../../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../../../entities/TriggerParamsEnricher';

import { CustomTriggerType, MonitorTracksSecondaryType } from '@tonkean/tonkean-entities';

const addMonitorBlockCreateAfterOptionCanCreate: CreateAfterOptionCanCreate = (
    childImpacts,
    configuredLogic,
    logicBlockConfigsFormInfoRetriever,
    customTriggerManager,
) => {
    if (configuredLogic.node.customTriggerType === CustomTriggerType.SEND_FORM_ANSWERED) {
        // If this trigger is when form answered - check which form is configured on the parent.
        const directParentInWorkflowVersion = customTriggerManager.getDirectParentInWorkflowVersion(
            configuredLogic.node.workflowVersion.id,
            configuredLogic.node.id,
        );
        const isAutonomous = directParentInWorkflowVersion.node?.autonomous;
        if (isAutonomous) {
            // autonomous triggers can monitor multiple forms so we check if at least one of them in collect inner items.
            const formIds = directParentInWorkflowVersion.node?.monitorForms?.map((form) => form.id);
            let hasCollectInnerItems = false;
            formIds?.every((formId) => {
                const form = logicBlockConfigsFormInfoRetriever.getWorkerFormFromCache(
                    configuredLogic.node.workflowVersion.id,
                    formId,
                );
                if (form?.formQuestionType === 'COLLECT_INNER_ITEMS') {
                    hasCollectInnerItems = true;
                    return false;
                }
                return true;
            });
            return hasCollectInnerItems;
        } else {
            const formId =
                directParentInWorkflowVersion.node?.customTriggerActions?.[0]?.customTriggerActionDefinition.formId;

            if (formId) {
                const form = logicBlockConfigsFormInfoRetriever.getWorkerFormFromCache(
                    configuredLogic.node.workflowVersion.id,
                    formId,
                );

                return form?.formQuestionType === 'COLLECT_INNER_ITEMS';
            }
        }
        // no form configured on the send form action - monitor items should not be an available option.
        return false;
    }
    // all other actions configured with this option should have monitor item as an available option.
    return true;
};

const monitorCustomTriggerParamsEnricher: TriggerParamsEnricher = (params, customTriggerManager, features) => {
    params.customTriggerType = CustomTriggerType.MONITOR_TRACKS;
    params.displayName = addMonitorBlockCreateAfterOption.blockDisplayName;
    params.selectWithConfigMode = logicConfigModes.configuration;
    params.customTriggerSecondaryType = MonitorTracksSecondaryType.MONITOR_TRACKS_MATCH_CONDITIONS;

    if (features?.tonkean_show_inner_item_idd) {
        params.customTriggerActions = [
            {
                type: CustomTriggerType.MONITOR_TRACKS,
                customTriggerActionDefinition: {
                    monitorActionItemQuantifierType: 'ANY_ACTION_ITEMS',
                    monitorContextType: 'ACTION_ITEM',
                    monitorItemsConditionType: 'ON_CONDITION',
                    workerItemContextType: 'PARENT_ITEM',
                    workerItemContextTypeParentLogicName: 'Root monitored item',
                    parentCustomTriggerId: params?.parentNode?.node?.id,
                },
            },
        ];
    }
};

const addMonitorBlockCreateAfterOption: CreateAfterOption = {
    label: 'Monitor Items',
    runOnType: RunOnTypes.NEW_REQUEST,
    blockDisplayName: 'Monitor Items',
    triggerParamsEnricher: monitorCustomTriggerParamsEnricher,
    canCreate: addMonitorBlockCreateAfterOptionCanCreate,
};

export default addMonitorBlockCreateAfterOption;
