import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import type { StylesConfig } from 'react-select';
import type { GroupBase } from 'react-select';

import useLightSearchInitiatives from './hooks/useLightSearchInitiatives';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { SimpleSelect, useDebouncedState } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Option {
    value: string;
    label: string;
}

interface Props extends StyledComponentsSupportProps {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    preselectedItem?: TonkeanId<TonkeanType.INITIATIVE>;
    itemIdsToExclude?: TonkeanId<TonkeanType.INITIATIVE>[];
    overrideZIndex?: boolean;
    id?: string;
    autoSelectFirst?: boolean;

    onItemSelected: (selectedItemId: TonkeanId<TonkeanType.INITIATIVE> | undefined) => void;
}

const InitiativeSelector: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    preselectedItem,
    onItemSelected,
    itemIdsToExclude,
    overrideZIndex,
    autoSelectFirst,
    ...props
}) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const trackHelper = useAngularService('trackHelper');

    const [selectedItem, setSelectedItem] = useState<string | undefined>(preselectedItem);
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
    const [notDebouncedSearchTerm, setNotDebouncedSearchTerm] = useDebouncedState('', setDebouncedSearchTerm, 300);

    const workflowVersionType = useMemo(
        () =>
            workflowVersionManager.isDraftVersion(workflowVersionId)
                ? WorkflowVersionType.DRAFT
                : WorkflowVersionType.PUBLISHED,
        [workflowVersionId, workflowVersionManager],
    );

    const { initiatives, loading, error } = useLightSearchInitiatives({
        groupId,
        workflowVersionType,
        searchText: debouncedSearchTerm,
        limit: 10,
    });

    const [
        { data: fetchedPreselectedItem, error: errorFetchingPreselectedItem, loading: loadingPreselectedItem },
        getInitiativeById,
    ] = useLazyAsyncMethod(trackHelper, 'getInitiativeById');

    /**
     * Fetching pre selected item upon initialisation of component.
     */
    useEffect(() => {
        if (preselectedItem) {
            getInitiativeById(preselectedItem, false);
        }
    }, [preselectedItem, getInitiativeById]);

    /**
     * Once pre selected item has been fetched, we add it to the filtered options if they do not already contain it.
     * The reason we're using the setState(() => ...) lambda flavour is to not have to include the filteredOptions
     * in the dependency array, causing us to set selected item each time we're filtering the options.
     */
    useEffect(() => {
        if (fetchedPreselectedItem && !errorFetchingPreselectedItem && !loadingPreselectedItem) {
            setSelectedItem(fetchedPreselectedItem.id);

            setFilteredOptions((currentOptions) => [
                ...(!currentOptions.some((option) => option.value === fetchedPreselectedItem.id)
                    ? [
                          {
                              value: fetchedPreselectedItem.id,
                              label: fetchedPreselectedItem.title,
                          },
                      ]
                    : []),
                ...currentOptions,
            ]);
        }
    }, [fetchedPreselectedItem, errorFetchingPreselectedItem, loadingPreselectedItem]);

    /**
     * Once we have fetched initiatives, we set the filtered options in the API SimpleSelect expects to receive them.
     */
    useEffect(() => {
        if (initiatives?.length && !loading && !error) {
            const relevantInitiatives = initiatives.filter((initiative) => !itemIdsToExclude?.includes(initiative.id));
            setFilteredOptions(
                relevantInitiatives.map((entity) => {
                    return {
                        value: entity.id,
                        label: entity.title,
                    };
                }),
            );

            const firstInitiative = relevantInitiatives[0];
            if (autoSelectFirst && !selectedItem && firstInitiative) {
                setSelectedItem(firstInitiative.id);
                onItemSelected(firstInitiative.id);
            }
        }
    }, [initiatives, loading, error, itemIdsToExclude, autoSelectFirst, selectedItem, onItemSelected]);

    const styles: StylesConfig<Option, false, GroupBase<Option>> = overrideZIndex
        ? {
              // Fixes the overlapping problem of the component
              menuPortal: (provided) => ({ ...provided, zIndex: 1050 }),
          }
        : {};

    return (
        <SimpleSelect
            placeholder="Search item..."
            value={selectedItem}
            isLoading={loading || loadingPreselectedItem}
            inputValue={notDebouncedSearchTerm}
            onInputChange={setNotDebouncedSearchTerm}
            options={filteredOptions}
            onChange={(selectedValue: TonkeanId<TonkeanType.INITIATIVE>) => {
                setSelectedItem(selectedValue);
                onItemSelected(selectedValue);
            }}
            menuPosition="fixed"
            styles={styles}
            thin
            {...props}
        />
    );
};

export default InitiativeSelector;
