import { lateConstructController } from '@tonkean/angular-components';
import { DUE_DATE_EVALUATION_OPTIONS } from '@tonkean/constants';

/* @ngInject */
function AssignValueForInnerItemFieldCtrl($scope, tonkeanUtils, personCache) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        initiative: ctrl.initiative,
        configuredLogic: ctrl.configuredLogic,
        fieldsToUpdate: ctrl.initiative.fieldsToUpdate || [{}],
        showRemoveButton: ctrl.showRemoveItem,
        index: ctrl.index,
        isDynamic: ctrl.isDynamic,

        ownerConfiguration: ctrl.ownerConfiguration,
        selectedOwnerPersonObject: null,
        // We maintain a variable separate from the initiative because we want to support backward compatibility to not
        // Fill this in unless needed
        inputType: ctrl.initiative.inputType || 'TEXT',
    };

    /**
     * Occurs whenever a change has been made for fields to update.
     */
    $scope.onConfiguredFieldsChanged = function (configuredFields, shouldSaveLogic) {
        $scope.data.fieldsToUpdate = configuredFields;
        $scope.data.initiative.fieldsToUpdate = configuredFields;

        $scope.postOnInnerTracksTemplateConfigChanged(shouldSaveLogic);
    };

    /**
     * Occurs whenever due date is changed.
     */
    $scope.onDueDateChanged = function (initiative) {
        // Note that !initiative.relativeDueDateInDays will be true if the value is zero.
        if (!initiative.relativeDueDateInDays) {
            initiative.dueDateEvaluationOption = DUE_DATE_EVALUATION_OPTIONS.noDueDate;
            initiative.relativeDueDateInDays = null;
        } else {
            initiative.dueDateEvaluationOption = DUE_DATE_EVALUATION_OPTIONS.relativeToCreation;
        }

        $scope.postOnInnerTracksTemplateConfigChanged(true);
    };

    $scope.selectInputType = function (inputTypeSelection) {
        $scope.data.inputType = inputTypeSelection;
        $scope.data.initiative.inputType = $scope.data.inputType;
        $scope.postOnInnerTracksTemplateConfigChanged(true);
    };

    $scope.postOnInnerTracksTemplateConfigChanged = function (shouldSaveLogic) {
        ctrl.onInnerTracksTemplateConfigChanged({
            index: ctrl.index,
            initiative: $scope.data.initiative,
            shouldSaveLogic,
        });
    };

    /**
     * This stops the all the click events that are inside the popover so it wont trigger the outside click.
     */
    $scope.stopOutsideClick = function ($event) {
        $event.stopPropagation();
        $event.stopImmediatePropagation();
        $event.preventDefault();
    };

    $scope.stopOuterOutsideClick = function ($event) {
        if ($scope.data.ownerAlertOpen) {
            $scope.data.ownerAlertOpen = false;
        }
        if ($scope.data.dueDateAlertOpen) {
            $scope.data.dueDateAlertOpen = false;
        }

        $scope.stopOutsideClick($event);
    };

    /**
     * Occurs once a owner person selection is changed.
     */
    $scope.onOwnerPersonSelectionChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        // Load person to use it avatar
        if (personSelectionConfiguration.selectedPeopleIds && personSelectionConfiguration.selectedPeopleIds.length) {
            personCache.getEntityById(personSelectionConfiguration.selectedPeopleIds[0], false, true).then((person) => {
                $scope.data.selectedOwnerPersonObject = person;
            });
        } else {
            $scope.data.selectedOwnerPersonObject = null;
        }

        if (ctrl.onOwnerChanged) {
            ctrl.onOwnerChanged({
                index: $scope.data.index,
                personSelectionConfiguration:
                    tonkeanUtils.buildPersonSelectionConfiguration(personSelectionConfiguration),
                shouldSaveLogic,
            });
        }
    };

    $scope.removeInitiative = function () {
        ctrl.onRemoveItem($scope.data.initiative);
    };
}

export default angular
    .module('tonkean.app')
    .controller('AssignValueForInnerItemFieldCtrl', lateConstructController(AssignValueForInnerItemFieldCtrl));
