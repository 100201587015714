import React, { useState } from 'react';
import styled from 'styled-components';

import JsonPickerOutputType from '../../entities/JsonPickerOutputType';
import ProjectIntegrationJsonViewerLayout from '../common/ProjectIntegrationJsonViewerLayout';

import type { JsonPickerAllowedTypes } from '@tonkean/infrastructure';
import { AnimatedSwitch, Tab, Tabs, TabsBorderType } from '@tonkean/infrastructure';

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    height: inherit;
    flex-direction: column;
`;

const StyledTab = styled(Tab)`
    margin-left: 0;
    padding-left: 0;

    &,
    &:hover,
    &:focus {
        background-color: unset;
    }
`;

enum TabName {
    BODY = 'BODY',
    URL = 'URL',
    HEADERS = 'HEADERS',
}

interface Props {
    jsonPayload: JsonPickerAllowedTypes;
    isResponse: boolean;
}

const ProjectIntegrationEntityLiveStreamWebhookPayloadViewer: React.FC<Props> = ({ jsonPayload, isResponse }) => {
    const [selectedTab, setSelectedTab] = useState<TabName>(TabName.BODY);

    return (
        <Wrapper>
            <Tabs
                borderType={TabsBorderType.NONE}
                onChange={setSelectedTab}
                value={selectedTab}
                showBackground={false}
                small
                hideIndicator
            >
                <StyledTab label={TabName.BODY}>Webhook Body</StyledTab>
            </Tabs>

            <AnimatedSwitch activeLabel={selectedTab}>
                <ProjectIntegrationJsonViewerLayout
                    jsonToDisplay={jsonPayload}
                    showAsJsonPicker={isResponse}
                    jsonPickerOutputType={JsonPickerOutputType.JSON_TRAVERSE}
                    jsonStringParam="payload"
                />
            </AnimatedSwitch>
        </Wrapper>
    );
};

export default ProjectIntegrationEntityLiveStreamWebhookPayloadViewer;
