import React from 'react';

import SeparatorWidgetView from './SeparatorWidgetView';
import type { SolutionSitePageWidgetProps } from '../../../WidgetModule';
import type SeparatorWidgetConfiguration from '../SeparatorWidgetConfiguration';

import { coreEditorTonkeanVariablePlugin } from '@tonkean/core-editor';
import { HTMLEditorFullPluginsList, HTMLEditorToolbarSeparatorPlugin } from '@tonkean/editor';

const htmlEditorPlugins = [
    coreEditorTonkeanVariablePlugin,
    HTMLEditorToolbarSeparatorPlugin,
    ...HTMLEditorFullPluginsList,
];

const SolutionSiteSeparatorWidget: React.FC<SolutionSitePageWidgetProps<SeparatorWidgetConfiguration>> = ({
    widget,
    permission,
}) => {
    return <SeparatorWidgetView widget={widget} permission={permission} />;
};

export default SolutionSiteSeparatorWidget;
