import { type FieldDefinition, FieldType, type TonkeanType } from '@tonkean/tonkean-entities';
import { SpecialFieldsKey, type TonkeanId } from '@tonkean/tonkean-entities';

export const DROPDOWN_MIN_WIDTH = 250;
export const DEFAULT_MIN_WIDTH = 100;
export const STATUS_MIN_WIDTH = 200;
export const OWNER_MIN_WIDTH = 250;
export const TITLE_MIN_WIDTH = 200;
export const DUE_DATE_MIN_WIDTH = 100;
export const CREATED_DATE_MIN_WIDTH = 100;

export const getFieldInitialWidth = (
    field: TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}` | SpecialFieldsKey,
    fieldDefinition: FieldDefinition | null = null,
): number => {
    switch (field) {
        case SpecialFieldsKey.OWNER:
            return OWNER_MIN_WIDTH;
        case SpecialFieldsKey.STATUS:
            return STATUS_MIN_WIDTH;
        case SpecialFieldsKey.CREATED_DATE:
            return CREATED_DATE_MIN_WIDTH;
        case SpecialFieldsKey.DUE_DATE:
            return DUE_DATE_MIN_WIDTH;
        case SpecialFieldsKey.TITLE:
            return TITLE_MIN_WIDTH;
        default:
            if (fieldDefinition) {
                return fieldDefinition.fieldType === FieldType.List ? DROPDOWN_MIN_WIDTH : DEFAULT_MIN_WIDTH;
            }
            return DEFAULT_MIN_WIDTH;
    }
};
