import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const GroupChip = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Theme.colors.gray_300};
    border-radius: 100px;
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    line-height: 12px;
    color: ${Theme.colors.gray_700};
    min-width: 76px;
    height: 16px;
    padding: 0 10px;
`;

export default GroupChip;
