/**
 * obj to array
 */
function objToArray(utils) {
    return function (obj) {
        return utils.objToArray(obj);
    };
}
export const objToArrayFilter = angular.module('tonkean.shared').filter('objToArray', objToArray);

function objLength(utils) {
    return function (obj) {
        return utils.size(obj);
    };
}
export const objLengthFilter = angular.module('tonkean.shared').filter('objLength', objLength);
