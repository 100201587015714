<div class="create-initiative-modal">
    <div class="text-center" ng-show="loading">
        <i class="loading-large"></i>
        <h3>Moving...</h3>
    </div>
    <form name="moveToTrackForm" ng-hide="loading" ng-submit="submit()" novalidate>
        <!-- Title -->
        <div class="common-title-lg">
            <span class="svg-icon-xlg svg-icon-align-text-top margin-right common-color-black">
                <tnk-icon src="/images/icons/move-to-track.svg"></tnk-icon>
            </span>
            Move this Track into...
        </div>

        <hr />

        <!-- Content -->
        <div class="clearfix margin-top-lg search-autocomplete-hide-link-tags">
            <div class="common-color-dark-grey margin-bottom common-size-xxs">
                Choose a Track to move "{{ data.initiative.title }}" into:
            </div>

            <div
                ng-include="'../../common/tnkPopovers/tnkMoveToTrackPopover/tnkMoveToTrackPopover.template.html'"
            ></div>

            <div class="clearfix margin-top-xlg">
                <button
                    type="submit"
                    class="btn btn-lg btn-primary mark-modal-btn"
                    ng-disabled="!selectedItem || !selectedItem.id"
                >
                    Move
                </button>
                <button type="button" class="btn btn-lg btn-default mark-modal-btn" ng-click="$dismiss()">
                    Cancel
                </button>
            </div>
        </div>

        <!-- Error -->
        <div>
            <span class="text-danger" ng-if="error">{{ error | error }}</span>
        </div>
    </form>
</div>
