<!-- Timeline -->
<div ng-if="pm.projectData.isTimelineAvailable && $state.params.tab === 'timeline'">
    <div class="common-page-load" ng-show="data.loading || data.loadingGroups">
        <i class="loading-full-circle margin-top-xxlg inline-block"></i>
    </div>
    <tnk-gantt-timeline
        ng-if="!data.loading || !data.loadingGroups"
        groups="pm.groups"
        start-with-group-id="$state.params.g"
        initiatives="data.timelineInitiatives"
        load-more-initiatives="loadMoreInitiativesForTimeline"
    ></tnk-gantt-timeline>
</div>

<!-- Old Calendar -->
<div ng-if="!$state.params.tab" class="initiatives-calendar-page">
    <div
        class="
            initiatives-header
            padding-normal-sm
            common-border-bottom
            flex-vmiddle flex-xs-wrap
            common-size-xxxxs common-color-grey
        "
        ng-class="{ 'mod-show-inner-project-filter': $root.showProjectInnerFilter, 'mod-full': !showProjectFilter }"
    >
        <div class="initiatives-list-title common-title-md flex-grow-xs margin-right-lg relative common-color-black">
            <span class="capital-first-letter padding-left-lg">Calendar</span>
        </div>
        <!--<button type="button" class="btn btn-sm btn-primary flex-no-shrink visible-xs-inline-block"-->
        <!--ng-click="openCalendarSubscriptionModal(data.onlyGroupId)">-->
        <!--Subscribe to Calendar-->
        <!--</button>-->
        <!--<div class="initiatives-toolbar flex-grow initiative-view-action-bar common-size-xxxxs common-color-grey flex-vmiddle ">-->
        <div class="flex-vmiddle relative">
            <strong
                ng-bind="data.currentMonth | date:'MMMM, yyyy'"
                class="margin-right pointer flex-grow-xs"
                ng-click="data.dateOpened = !data.dateOpened"
                style="width: 110px"
            ></strong>
            <button
                class="btn btn-default btn-no-border margin-right common-no-outline"
                ng-click="loadMonth(data.currentStartDate)"
            >
                <i class="fa fa-chevron-up"></i>
            </button>
            <button
                class="btn btn-default btn-no-border margin-right common-no-outline"
                ng-click="loadMonth(data.currentEndDate)"
            >
                <i class="fa fa-chevron-down"></i>
            </button>
            <input
                class="margin-top track-due-date-input mod-no-adjustment common-no-outline"
                uib-datepicker-popup="fullDate"
                datepicker-options="{showWeeks: false, datepickerMode: 'month', minMode: 'month'}"
                ng-model="data.tempStartDate"
                ng-change="loadMonth(data.tempStartDate)"
                is-open="data.dateOpened"
                close-text="Close"
            />
        </div>
        <div ng-show="data.loadingInner"><i class="loading-small"></i></div>

        <div class="flex-grow hidden-xs"></div>

        <div
            class="margin-right flex-no-shrink"
            ng-if="pm.groups && pm.groups.length"
            uib-dropdown
            on-toggle="toggled(open)"
        >
            List:
            <a class="pointer" uib-dropdown-toggle>
                {{ data.onlyGroup ? data.onlyGroup.name : 'All' }}
                <i class="fa fa-caret-down margin-left-xs"></i>
            </a>
            <ul class="dropdown-menu" uib-dropdown-menu style="max-height: 400px; overflow-y: auto">
                <li>
                    <a
                        ng-click="data.onlyGroup = null; data.onlyGroupId = null"
                        analytics-on="click"
                        analytics-category="Calendar"
                        analytics-event="Filter by group"
                        analytics-label="Active"
                    >
                        All
                    </a>
                </li>
                <li class="divider"></li>
                <li ng-repeat="group in pm.groups">
                    <a
                        ng-click="data.onlyGroup = group; data.onlyGroupId = group.id"
                        analytics-on="click"
                        analytics-category="Calendar"
                        analytics-event="Filter by group"
                        analytics-label="Active"
                    >
                        {{ group.name }}
                    </a>
                </li>
            </ul>
        </div>
        <a
            class="btn btn-default btn-no-border margin-right hidden-xs common-color-grey common-size-xxxxs"
            ng-click="data.onlyMine = !data.onlyMine"
        >
            <i class="fa pointer common-color-{{ data.onlyMine ? 'primary fa-check-square' : 'grey fa-square-o' }}"></i>
            Only mine
        </a>

        <div uib-dropdown class="margin-right">
            <div class="svg-icon-mdl common-color-grey svg-icon-hover-primary pointer" uib-dropdown-toggle>
                <span class="initiatives-list-settings-icon">
                    <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                </span>
            </div>
            <ul uib-dropdown-menu class="dropdown-menu dropdown-menu-right">
                <li class="common-border-bottom">
                    <a
                        ng-click="openCalendarSubscriptionModal(data.onlyGroupId)"
                        class="initiatives-calendar-menu-item"
                    >
                        Subscribe to Calendar
                    </a>
                </li>
                <li>
                    <a class="initiatives-calendar-menu-item">
                        <div class="margin-bottom-xs">Start week on</div>
                        <div class="btn-group btn-group-no-float">
                            <div
                                ng-repeat="day in data.startDayOptions"
                                class="btn pointer"
                                ng-class="
                                    data.weekStartDay.name === day.name
                                        ? 'btn-primary'
                                        : 'btn-default common-color-light-grey common-color-light-grey-hover'
                                "
                                ng-click="selectWeekStartDay(day)"
                            >
                                {{ day.name }}
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <!--</div>-->
    </div>

    <div
        class="common-page-load"
        ng-show="(data.loading && (!data.initiatives || !data.initiatives.length)) || data.loadingGroups"
    >
        <i class="loading-full-circle margin-top-xxlg inline-block"></i>
    </div>

    <div
        class="initiatives-content padding-bottom-lg"
        ng-if="(!data.loading || (data.initiatives && data.initiatives.length)) && !data.loadingGroups"
    >
        <div class="margin-bottom-lg initiatives-calendar-content padding-top-xlg box-with-shadow padding-normal-sm">
            <div class="flex-grow">
                <div class="visible-xs">
                    <div class="flex-vmiddle">
                        <div
                            class="initiatives-calendar-week-days-day"
                            ng-repeat="day in ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
                        >
                            {{ day }}
                        </div>
                    </div>
                </div>
                <div
                    ng-repeat="week in data.weeks"
                    class="initiatives-calendar-week"
                    ng-class="{ 'common-border-bottom': !$last }"
                >
                    <tnk-track-week-view
                        items="data.allInitiatives"
                        full-mode="true"
                        group-id="data.onlyGroupId"
                        is-draft="false"
                        include-links="false"
                        func-id="null"
                        owner-id="data.onlyMine ? as.currentUser.id : null"
                        tag="null"
                        selected-state="data.selectedDay"
                        start-date="week.startDate"
                    ></tnk-track-week-view>
                </div>
            </div>
            <div ng-if="data.selectedDay.day" class="margin-top initiatives-calendar-week-day-list hidden-sm relative">
                <span class="common-close-btn" ng-click="data.selectedDay.day = null">
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </span>
                <div class="common-bold clearfix">
                    {{ data.selectedDay.day.date | date: 'EEE, MMM dd' }}:
                    <a
                        class="common-link-no-style common-color-grey pull-right fa fa-times hidden-xs"
                        ng-click="data.selectedDay.day = null"
                    ></a>
                </div>
                <hr class="margin-normal-sm-v" />
                {{day = data.selectedDay.day;""}}
                <div ng-include="'../common/tnkTrackWeekView/tnkTrackWeekDayItemsList.template.html'"></div>
                <div ng-if="!(day.items | objLength)" class="common-size-xxxxs common-color-grey">
                    No items for this day.
                </div>
            </div>
        </div>
    </div>
</div>
