import { Form, Formik } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

import { IconSvg, Input } from '@tonkean/infrastructure';
import { AppearFromTopAnimation } from '@tonkean/infrastructure';
import { SendIcon } from '@tonkean/svg';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Button } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { colorSvg } from '@tonkean/utils';

const ReplyBarForm = styled(Form)`
    position: relative;
    display: flex;
    align-items: center;
`;

const ReplyInput = styled(Input)`
    border-radius: 10px;
    font-size: ${FontSize.MEDIUM_14};
    padding-left: 24px;
    padding-right: 36px;
`;

const ReplyButton = styled(Button)<{ disabled: boolean }>`
    position: absolute;
    right: 8px;
    background: transparent;
    border: none;
    width: 28px;
    min-width: unset;
    padding: 0;

    svg {
        ${colorSvg(Theme.colors.gray_600)}
        margin-right: 0;
    }

    &:hover,
    &:focus {
        background: ${Theme.colors.gray_200};

        svg {
            ${colorSvg(Theme.colors.gray_600)}
        }
    }

    &:before {
        content: ' ';
        width: 100%;
        height: 100%;
        background: red;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.6;

            &:before {
                content: '';
                position: absolute;
                top: 27%;
                left: 48%;
                display: block;
                width: 2px;
                transform: rotateZ(-45deg);
                height: 50%;
                background: red;
            }
        `};

    ${styledFocus}
`;

const NeedHelpButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 23px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    padding-bottom: 23px;
`;

const NeedHelpButton = styled(Clickable)`
    display: flex;
    width: 338px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid ${Theme.colors.gray_300};
    color: ${Theme.colors.gray_600};
    text-align: center;
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
    &:hover {
        color: ${Theme.colors.gray_600};
    }
`;

interface Props {
    onReply: (message: string) => void;
    loading: boolean;
    showTalkToExpert?: boolean;
    needHelpClicked?: () => void;
}

const SmartConversationReply: React.FC<Props> = ({ onReply, loading, showTalkToExpert, needHelpClicked }) => {
    return (
        <AppearFromTopAnimation>
            {showTalkToExpert && (
                <NeedHelpButtonWrapper>
                    <NeedHelpButton onClick={needHelpClicked}>Need more help? Talk to an expert.</NeedHelpButton>
                </NeedHelpButtonWrapper>
            )}
            <Formik
                initialValues={{ replyText: '' }}
                onSubmit={({ replyText }, { resetForm }) => {
                    resetForm();
                    onReply(replyText);
                }}
            >
                {({ submitForm }) => (
                    <ReplyBarForm>
                        <ReplyInput
                            name="replyText"
                            autoComplete="off"
                            placeholder="Reply..."
                            size={InputSize.XLARGE}
                            autoFocus
                        />
                        <ReplyButton type="submit" disabled={loading}>
                            <IconSvg as={SendIcon} size={24} />
                        </ReplyButton>
                    </ReplyBarForm>
                )}
            </Formik>
        </AppearFromTopAnimation>
    );
};

export default SmartConversationReply;
