import type { TElement } from '@udecode/plate';
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';

import ProcessBuilderAIChat from './ProcessBuilderAIChat';
import ProcessBuilderAnalyzeAndOptimizeMessage from './ProcessBuilderAnalyzeAndOptimizeMessage';
import { ReactComponent as AtomIcon } from '../../../../images/icons/atom.svg';

import { analyticsWrapper } from '@tonkean/analytics';
import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage, SavingIndicator } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { ProcessBuilderMissingQuestionsResponse } from '@tonkean/tonkean-entities';
import { ClickableButton, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const ChatMessage = styled.div`
    margin-left: 18px;
    font-size: ${FontSize.XLARGE_18};
`;

const AIMessage = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 18px;
    margin-bottom: 10px;
    color: #34393e;
`;

const AnalyzeButton = styled(ClickableButton)<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    height: 42px;
    margin-top: 10px;
    max-width: 220px;

    ${({ disabled }) =>
        disabled &&
        css`
            background-color: #babce9;
            color: #7f82b8;

            &:hover,
            &:focus {
                background-color: #babce9;
                color: #7f82b8;
            }
        `};
`;

const IconWrapper = styled.div`
    margin-top: 6px;
`;

const SendInitialProcessDescriptionButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
`;

const StyledSavingIndicator = styled(SavingIndicator)`
    margin-top: 10px;
    line-height: 18px;
`;

interface Props {
    initialProcessDescription: TElement[];
    onQuestionsReceived: (questionsResult: ProcessBuilderMissingQuestionsResponse | undefined) => void;
}

const ProcessBuilderAnalyzeInitialProcessDescription: React.FC<Props> = ({
    initialProcessDescription,
    onQuestionsReceived,
}) => {
    const { id: projectId } = useProject();

    const utils = useAngularService('utils');

    const [
        {
            data: questionsToAnswer,
            loading: sendInitialProcessDescriptionLoading,
            error: sendInitialProcessDescriptionError,
        },
        sendInitialProcessDescription,
    ] = useLazyTonkeanService('sendInitialProcessDescription');

    const sendInitialProcessDescriptionToChatGpt = useCallback(() => {
        sendInitialProcessDescription(projectId, utils.richTextToPlainText(initialProcessDescription));

        analyticsWrapper.track('Analyze & Optimize Button Clicked', {
            category: 'Process Builder',
            label: initialProcessDescription?.length || 0,
        });
    }, [projectId, sendInitialProcessDescription, initialProcessDescription, utils]);

    useEffect(() => {
        onQuestionsReceived(questionsToAnswer);
    }, [questionsToAnswer, onQuestionsReceived]);

    return (
        <ProcessBuilderAIChat>
            <IconWrapper>
                <AtomIcon />
            </IconWrapper>

            <ChatMessage>
                <AIMessage>
                    <ProcessBuilderAnalyzeAndOptimizeMessage />
                </AIMessage>

                <SendInitialProcessDescriptionButton>
                    <AnalyzeButton
                        shape={ButtonShape.ROUND}
                        backgroundColor={Theme.current.palette.colorPicker.HEX_605CE4}
                        size={ButtonSize.HUGE}
                        disabled={!initialProcessDescription.length || sendInitialProcessDescriptionLoading}
                        onClick={sendInitialProcessDescriptionToChatGpt}
                        flex
                    >
                        Analyze & Optimize
                    </AnalyzeButton>

                    <StyledSavingIndicator
                        error={sendInitialProcessDescriptionError}
                        loading={sendInitialProcessDescriptionLoading}
                    />
                </SendInitialProcessDescriptionButton>
                {sendInitialProcessDescriptionError && (
                    <ErrorMessage>{getStateError(sendInitialProcessDescriptionError)}</ErrorMessage>
                )}
            </ChatMessage>
        </ProcessBuilderAIChat>
    );
};

export default ProcessBuilderAnalyzeInitialProcessDescription;
