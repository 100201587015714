import template from './tnkInbox.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkInbox', {
    bindings: {
        isOpen: '<',

        onNewItemsCountUpdated: '&',
    },
    template,
    controller: 'InboxCtrl',
});
