/**
 * Created by ShaLi on 10/11/2015.
 */

function tnkImgEvents() {
    return {
        restrict: 'A',
        scope: {
            ngOnload: '&',
            ngOnerror: '&',
        },
        link(scope, element, attrs) {
            if (element[0].tagName === 'IMG') {
                // do your stuff
                element.on('error', function () {
                    if (angular.isDefined(attrs.hideOnError)) {
                        element.addClass('hidden');
                    } else if (angular.isFunction(scope.ngOnerror)) {
                        return scope.ngOnerror();
                    }
                });

                if (angular.isFunction(scope.ngOnload)) {
                    const onLoad = function () {
                        if (angular.isFunction(scope.ngOnload)) {
                            return scope.ngOnload();
                        }
                    };
                    if (element[0].complete) {
                        onLoad();
                    } else {
                        element.on('load', onLoad);
                    }
                }
            }
        },
    };
}
export default angular.module('tonkean.app').directive('tnkImgEvents', tnkImgEvents);
