function EditGroupCtrl(
    $scope,
    $rootScope,
    $q,
    tonkeanService,
    utils,
    modal,
    modalUtils,
    inviteManager,
    groupManager,
    groupInfoManager,
    groupPermissions,
    communicationIntegrationsService,
    projectManager,
) {
    const maxMembersForDirectMessage = 7;

    $scope.groupType = { public: 'public', private: 'private' };

    $scope.pm = $rootScope.pm;
    $scope.as = $rootScope.as;
    $scope.gm = groupPermissions;
    $scope.modalUtils = modalUtils;
    $scope.cis = communicationIntegrationsService;
    $scope.groupNotifications = tonkeanService.groupNotifications;

    $scope.groupWritePermissionTypes = [
        {
            apiName: 'EVERYONE',
            displayName: 'View and edit',
        },
        {
            apiName: 'SPECIFIC_PEOPLE',
            displayName: 'View',
        },
    ];

    $scope.shortArchivePeriodInYears = 3;
    $scope.extendedArchivePeriodInYears = 7;
    $scope.daysInYear = 365;
    $scope.archivePeriodOptions = [
        { value: 1, displayName: 'Days' },
        { value: 7, displayName: 'Weeks' },
        { value: 30, displayName: 'Months' },
        { value: 365, displayName: 'Years' },
    ];

    $scope.data = {
        group: $scope.group,
        isEdit: $scope.group && $scope.group.id,
        type:
            !$scope.group || !$scope.group.visibilityType
                ? $scope.groupType.public
                : $scope.group.visibilityType.toLowerCase(),
        notificationType: tonkeanService.groupNotifications.direct,
        members: [],
        channel: $scope.group ? $scope.group.notificationSettings : null,
        privateChannelName: null,
        allowOwnersNotFromList: false,
        reloadChannels: false, // Used for the tnkChannelSelector (when set to true, tells him to reload the channels).
        communicationIntegration: $scope.group
            ? $scope.group.notificationSettings &&
              projectManager.getProjectIntegrationById(
                  $scope.group.notificationSettings.communicationProjectIntegrationId,
              )
            : $scope.cis.supportsFeature(
                  $scope.pm.project?.defaultCommunicationProjectIntegration?.integration?.integrationType,
                  $scope.cis.features.channels,
              )
            ? $scope.pm.project.defaultCommunicationProjectIntegration
            : $scope.cis.getFirstThatSupports($scope.cis.features.channels),
        selectedGroupPermissionsType: $scope.groupWritePermissionTypes[0], // Default is everyone
        writePermissionMembers: [], // List of people the user sees as having write access
        defaultActor: $scope.group.defaultActor || $scope.as.currentUser,
        allowWorkerErrorAlerts: $scope.group && !$scope.group.blockWorkerErrorAlerts,
        smartSearchEnabled: $scope.group && $scope.group.smartSearchEnabled,
        owners: [],
        showEditOwners: false,
        ownersIsLoading: false,
        workflowFolderId: null,
        supportUsersInMembers: [],
        supportUsersInWritePermissions: [],
        supportUsersInOwners: [],
        workflowFolderCategoryId: null,
        shouldShowSmartSearch: $rootScope.features.currentProject?.tonkean_feature_smartsearch,
        maxArchivePeriod: ($rootScope.features.currentProject?.tonkean_allow_extended_archive_period ? $scope.extendedArchivePeriodInYears : $scope.shortArchivePeriodInYears) * $scope.daysInYear,
        selectedArchivePeriodType: $scope.archivePeriodOptions[0],
        deleteArchivedAfterDays: null,
        isValidArchiveDays: true,
    };

    $scope.getMaxArchivePeriod = function () { 
        return Math.floor($scope.data.maxArchivePeriod/$scope.data.selectedArchivePeriodType.value);
    };

    $scope.checkArchiveDaysValue = function () {
        $scope.data.isValidArchiveDays = $scope.data.deleteArchivedAfterDays >= 1 && $scope.data.deleteArchivedAfterDays <= $scope.getMaxArchivePeriod();
    }

    if ($scope.data.channel && $scope.data.channel.type !== tonkeanService.groupNotifications.none) {
        $scope.data.newChannel = $scope.data.channel;
        $scope.data.newChannel.displayName = $scope.data.channel.channelName;
    }

    // This watch is important. privateGroupModalCtrl refreshes the group and changes the reference,
    // so if we won't update it we will be using an old reference without all the needed changes made there (e.g innerTracks).
    $scope.$watch('group', () => {
        $scope.data.group = $scope.group;
    });

    /**
     * Occurs once the default actor is selected.
     */
    $scope.onDefaultActorSelected = function (person) {
        $scope.data.defaultActor = person;
        updateValidity();
    };

    $scope.init = function () {
        const group = $scope.data.group;
        // Set the submit function to the controlObject so that it can called from outside.
        $scope.controlObject.submit = $scope.submit;

        if (group && group.members) {
            $scope.data.supportUsersInMembers = group.members.filter((member) => member.systemUtilized);
            $scope.data.members = group.members.filter(function (p) {
                return p.id !== $scope.as.currentUser.id && !p.systemUtilized;
            });
        }

        // We always have a group in edit mode, but we might also have a group in create mode too (when duplicating one).
        if (group) {
            // Initialize notificationSettings both for create (if a given group exists) and edit group.
            if (group.notificationSettings && group.notificationSettings.channelType) {
                $scope.data.notificationType = group.notificationSettings.channelType;
            } else if ($scope.data.type === $scope.groupType.public) {
                $scope.data.notificationType = tonkeanService.groupNotifications.none;
            } else if ($scope.data.type === $scope.groupType.private) {
                $scope.data.notificationType =
                    group.notificationSettings?.type || tonkeanService.groupNotifications.direct;
            }

            if ($scope.data.notificationType === tonkeanService.groupNotifications.private) {
                let channelName = group.notificationSettings.channelName;
                // Delete the # from the beginning of the channel name.
                if (channelName.charAt(0) === '#') {
                    channelName = channelName.slice(1);
                }
                $scope.data.privateChannelName = channelName;

                // This group was already validated when creating this private list.
                $scope.validatedPrivateChannel = true;

                updateValidity();
            }

            // Calling this will make sure we don't have too many users for the direct message option.
            $scope.onPeopleSelectionChanged();

            $scope.data.canAccessWithToken = group.canAccessWithToken;

            // Set the group name. If in duplicate, we expect a name to be given for the new group in the group data.
            $scope.data.name = group.name;

            $scope.data.selectedArchivePeriodType = getPeriodType(group.deleteArchivedAfterDays);
            $scope.data.deleteArchivedAfterDays = (group.deleteArchivedAfterDays || $scope.pm.projectData.archiveInitiativeDaysBeforeCensoring)/$scope.data.selectedArchivePeriodType.value;

            // Try to find the correct write permissions type, if doesn't exist or not found defaults to everyone (from initialization)
            const foundWritePermissionType = utils.findFirst(
                $scope.groupWritePermissionTypes,
                (type) => type.apiName === group.writePermissionType,
            );
            if (foundWritePermissionType) {
                $scope.data.selectedGroupPermissionsType = foundWritePermissionType;
            }

            if (group.writePermissionMembers) {
                $scope.data.supportUsersInWritePermissions = group.writePermissionMembers.filter(
                    (member) => member.systemUtilized,
                );
                $scope.data.writePermissionMembers = group.writePermissionMembers.filter(
                    (member) => member.id !== $scope.as.currentUser.id && !member.systemUtilized,
                );
            }

            if (group.owners) {
                $scope.data.supportUsersInOwners = group.owners.filter((member) => member.systemUtilized);
                $scope.data.owners = group.owners.filter((member) => !member.systemUtilized);
            }
        }

        // When creating a new list, the default state is to allow adding owners not from list.
        // If we've gotten a group (i.e to duplicate from), take the setting from there.
        $scope.data.allowOwnersNotFromList = group ? group.visibleToOwner : true;

        if ($scope.data.isEdit) {
            // Edit mode code goes here...
        } else {
            // Create mode code goes here...
        }
    };

    $scope.selectType = function (typeName) {
        $scope.data.type = typeName;

        setNotificationType();

        updateValidity();
    };

    $scope.communicationSourceSelected = function (selectedCommunicationProjectIntegration) {
        $scope.data.communicationIntegration = selectedCommunicationProjectIntegration;
        $scope.data.newChannel = null;
    };

    $scope.onPrivateGroupKeyPress = function (event) {
        $scope.validatedPrivateChannel = false;
        updateValidity();

        if ($scope.data.privateChannelName && (event.charCode === 13 || event.keyCode === 13)) {
            $scope.validateChannel();
        }
    };

    $scope.onOwnerTagRemoving = function (tag) {
        const canRemove = tag.id !== $scope.group.creator.id;
        if (!canRemove) {
            $scope.$emit('alert', { msg: "Can't remove Creator from owners list.", type: 'warning' });
        } else if (tag.id === $scope.as.currentUser.id) {
            $scope.questionConfirmModalData = {
                title: 'Removing yourself as owner',
                body: 'Are you sure you want to remove yourself as an owner?',
                okLabel: 'Yes',
                cancelLabel: 'Cancel',
            };

            return modal.openQuestionConfirmModal({
                scope: $scope,
                windowClass: 'mod-warning',
            }).result;
        }

        return canRemove;
    };

    $scope.saveOwners = function () {
        const ownersIds = $scope.data.owners.concat($scope.data.supportUsersInOwners).map((owner) => owner.id);
        $scope.data.ownersIsLoading = true;

        return groupManager.updateGroupOwners($scope.group.id, ownersIds).finally(() => {
            $scope.data.ownersIsLoading = false;
            $scope.data.showEditOwners = false;
            if ($scope.onOwnersUpdated) {
                $scope.onOwnersUpdated();
            }
        });
    };

    $scope.validateChannel = function () {
        $scope.loadingPrivateGroup = true;
        $scope.serverError = false;

        tonkeanService
            .checkNotificationChannel(
                $scope.pm.project.id,
                tonkeanService.groupNotifications.private,
                $scope.data.privateChannelName,
            )
            .then(function (data) {
                $scope.botName = data.botName;
                if (data.isValid) {
                    $scope.loadingPrivateGroup = false;
                    $scope.failedFindingPrivateChannel = false;
                    $scope.validatedPrivateChannel = true;
                } else {
                    $scope.loadingPrivateGroup = false;
                    $scope.validatedPrivateChannel = false;

                    $scope.data.wrongPrivateChannelName = $scope.data.privateChannelName;
                    $scope.failedFindingPrivateChannel = true;
                }
            })
            .catch(function (error) {
                $scope.loadingPrivateGroup = false;
                $scope.validatedPrivateChannel = false;

                // The group was not found.
                if (error.status === 404) {
                    $scope.data.wrongPrivateChannelName = $scope.data.privateChannelName;
                    $scope.failedFindingPrivateChannel = true;
                } else {
                    // Something else failed.
                    $scope.serverError = true;
                    console.log(error);
                }
            })
            .finally(function () {
                updateValidity();
            });
    };

    $scope.selectPublicChannel = function (item) {
        $scope.data.editChannel = false;
        $scope.data.channel = item ? { channelId: item.value, channelName: item.displayName } : null;
        $scope.data.notificationType = tonkeanService.groupNotifications.public;
    };

    /**
     * Selects the private channel type according to the supplied type.
     */
    $scope.selectPrivateChannelType = function (notificationType) {
        $scope.data.notificationType = notificationType;

        // Update validity since it has different states for different notification types.
        updateValidity();
    };

    /**
     * Fired when members are added or removed in the tnk-people-selector.
     */
    $scope.onPeopleSelectionChanged = function () {
        // It's only possible to direct message 7 people in Slack (+creator, +tonkean).
        // So if the user passes this amount, we auto-change to private channel and disable the direct option.
        if (
            $scope.cis.anyIntegrationSupports($scope.cis.features.multiDirectMessage) &&
            $scope.data.type === $scope.groupType.private &&
            $scope.data.members &&
            $scope.data.members.length > maxMembersForDirectMessage
        ) {
            $scope.selectPrivateChannelType($scope.groupNotifications.none);
        }
    };

    $scope.onWorkflowFolderSelected = function (workflowFolder) {
        $scope.data.workflowFolderId = workflowFolder?.id;
        $scope.data.workflowFolderCategoryId = undefined;
    };

    $scope.onWorkflowFolderCategorySelected = function (workflowFolderCategoryId) {
        $scope.data.workflowFolderCategoryId = workflowFolderCategoryId;
    };

    /**
     * If shouldNotSendGatherUpdates is true, we should update or create a list with bot not activated.
     * The reason we go with the negative (should not instead of should), is because we're meddling with an existing
     * reality of bot being active all of the times EXCEPT the times we care for it not to be active.
     * So if we were to have the boolean say should, we would have to update (and find) all the calls to the function
     * and set it to true.
     */
    $scope.submit = function (shouldNotSendGatherUpdates, workerType, updateGroupAsync) {
        // If the user is editing a group and changing its type.
        if ($scope.data.isEdit && $scope.data.type !== $scope.group.visibilityType.toLowerCase()) {
            // If the user pressed Ok continue with the submit.
            // If the user pressed cancel, dont submit and close the 'are you sure' modal without showing an error alert.
            // We cant use catch because if there is an error in doSubmit, we do want to show an alert message.
            return showAreYouSure().then(
                () => doSubmit(shouldNotSendGatherUpdates, workerType, updateGroupAsync),
                () => $q.reject('dismissed'),
            ); // then gets success callback and error callback (which for this modal is the dismiss function)
        } else {
            // If we are creating a list, or the edited group's type was not changed, submit normally.
            return doSubmit(shouldNotSendGatherUpdates, workerType, updateGroupAsync);
        }
    };

    $scope.autoselectPublicChannel = function (channels) {
        let shouldAutoSelect = false;

        // If we are editing a group and if this is a public group with public/none notifications set (or there's a default channel for the project).
        if (
            $scope.data.isEdit &&
            ($scope.data.notificationType === tonkeanService.groupNotifications.public ||
                $scope.data.notificationType === tonkeanService.groupNotifications.none)
        ) {
            // If we are editing a group, and changing its type (say, from private to public).
            if ($scope.data.group && $scope.data.group.visibilityType.toLowerCase() !== $scope.data.type) {
                // We should only do something if a channel was not already set.
                if (!$scope.data.channel) {
                    if ($scope.pm.project.notificationSettings) {
                        // If there's a default channel, we should auto select it.
                        shouldAutoSelect = true;
                    } else {
                        // If we don't have a default channel, we should let the user to select one, and clear any channel set to the private group.
                        $scope.data.editChannel = true;
                    }
                }
            } else {
                // Just load the edited group's channel (it was public and it's still public).
                $scope.data.channel = $scope.data.group.notificationSettings;
                $scope.data.editChannel = false;
            }
        } else {
            // We are not in edit mode - always auto select channel.
            shouldAutoSelect = true;
        }

        // Auto select the main channel only if we have one and if the user didn't select a channel by himself.
        if (shouldAutoSelect && $scope.pm.project.notificationSettings && !$scope.data.newChannel) {
            // Search for the channel in the channel list.
            let i;
            for (i = 0; i < channels.length; i++) {
                // If found, auto select the channel.
                if (channels[i].value === $scope.pm.project.notificationSettings.channelId) {
                    $scope.data.newChannel = channels[i];
                    break;
                }
            }
        }
    };

    $scope.shouldShowWorkflowFolderSelection = function () {
        return $scope.group?.isDuplicatedList || !$scope.data.isEdit;
    };

    $scope.shouldShowOutlineSectionSelection = function () {
        return $scope.data.workflowFolderId;
    };

    function getPeriodType(deleteArchivedAfterDays) {
        const periodType = [...$scope.archivePeriodOptions].sort((periodOptionA, periodOptionB) => periodOptionB.value - periodOptionA.value).find((option) => {
            return deleteArchivedAfterDays % option.value === 0;
        });

        return periodType || $scope.archivePeriodOptions[0];
    }

    /**
     * typeName - 'public' or 'private' according to the group type.
     * */
    function setNotificationType() {
        if ($scope.data.type === $scope.groupType.public) {
            // If no default channel is set for the project.
            if (!$scope.data.channel) {
                // Set the correct notificationType.
                $scope.data.notificationType = tonkeanService.groupNotifications.none;
            } else {
                // Set the correct notificationType - we have a default channel for a public group.
                $scope.data.notificationType = tonkeanService.groupNotifications.public;

                // If the user have previously saved channel, we will choose it for him by default. Otherwise, set null in order to let the channel selection component know that a channel need to be chosen.
                if ($scope.data.channel && $scope.data.channel.channelName) {
                    $scope.data.newChannel = $scope.data.channel;
                    $scope.data.newChannel.displayName = $scope.data.channel.channelName;
                } else {
                    $scope.data.newChannel = null;
                    $scope.data.channel = null;
                }
            }
        } else {
            $scope.data.notificationType = tonkeanService.groupNotifications.direct;
        }
    }

    /**
     * Updates the outer isValid directive attribute.
     */
    function updateValidity() {
        if (!$scope.data.defaultActor) {
            $scope.isValid = false;
        } else {
            // If this is a private group.
            if ($scope.data.type === $scope.groupType.private) {
                // If private channel option was chosen, validity is according to the validity of the channel.
                // Otherwise, we're valid.
                $scope.isValid =
                    $scope.data.notificationType === $scope.groupNotifications.private
                        ? $scope.validatedPrivateChannel
                        : true;
            } else {
                // This is a public group, nothing to validate.
                $scope.isValid = true;
            }
        }
    }

    function showAreYouSure() {
        $scope.questionConfirmModalData = {
            title: 'Changing list visibility',
            body: `Are you sure you want to make this list ${
                $scope.data.type
            }? It was ${$scope.group.visibilityType.toLowerCase()} until now.`,
            okLabel: 'Change',
            cancelLabel: 'Cancel',
        };

        return modal.openQuestionConfirmModal({
            scope: $scope,
            windowClass: 'mod-warning',
        }).result;
    }

    $scope.onSupportAccessToggle = function (isSupportUserPermitted) {
        $scope.data.isSupportUserPermitted = isSupportUserPermitted;
    };

    /**
     * If shouldNotSendGatherUpdates is true, we should update or create a list with bot not activated.
     * The reason we go with the negative (should not instead of should), is because we're meddling with an existing
     * reality of bot being active all of the times EXCEPT the times we care for it not to be active.
     * So if we were to have the boolean say should, we would have to update (and find) all the calls to the function
     * and set it to true.
     */
    function doSubmit(shouldNotSendGatherUpdates, workerType, updateGroupAsync) {
        if (
            !$scope.data.name ||
            !$scope.data.name.length ||
            ($scope.shouldShowWorkflowFolderSelection() && !$scope.data.workflowFolderId?.length)
        ) {
            return $q.reject('dismissed');
        }

        if (!$scope.loading) {
            $scope.loading = true;

            // Push the current user into the array to let the server know (we removed them in the init so we wouldn't show them to user)
            $scope.data.members.push($scope.as.currentUser);
            $scope.data.writePermissionMembers.push($scope.as.currentUser);

            const ids = [];
            const peopleToInvite = [];
            angular.forEach($scope.data.members.concat($scope.data.supportUsersInMembers), function (person) {
                if (person.id) {
                    // If the person has an id, he's not a new user - we know him.
                    ids.push(person.id);
                } else {
                    // If the person doesn't have an id, that's a new user (custom mail) - we should invite him.
                    peopleToInvite.push({ email: person.email, name: person.email.split('@')[0] });
                }
            });

            let channel;

            if ($scope.data.type === $scope.groupType.public) {
                // Reset visibleToOwner (since it's not relevant to public groups).
                $scope.data.allowOwnersNotFromList = false;

                if (
                    $scope.data.channel &&
                    $scope.data.channel.type !== 'NONE' &&
                    $scope.data.communicationIntegration &&
                    $scope.cis.supportsFeature(
                        $scope.data.communicationIntegration.integrationType,
                        $scope.cis.features.channels,
                    )
                ) {
                    channel = $scope.data.channel.channelId;
                    $scope.data.notificationType = tonkeanService.groupNotifications.public;
                } else {
                    // if no channel selected, server expects the notification type to be 'none'
                    channel = null;
                    $scope.data.notificationType = tonkeanService.groupNotifications.none;
                }
            } else {
                // In case of private.
                channel = $scope.data.privateChannelName;
            }

            const shouldSendGatherUpdates = !shouldNotSendGatherUpdates;

            return $q
                .resolve()
                .then(() => {
                    // Invite new people (if any exist).
                    if (peopleToInvite.length > 0) {
                        return inviteManager.sendInvites($scope.pm.project.id, peopleToInvite).then(function (data) {
                            // Go over all the new users that were created and add them to the group member ids.
                            if (data && data.invites) {
                                for (let i = 0; i < data.invites.length; i++) {
                                    if (data.invites[i].person) {
                                        ids.push(data.invites[i].person.id);
                                    }
                                }
                            }

                            return $q.resolve();
                        });
                    } else {
                        return $q.resolve();
                    }
                })
                .then(() => {
                    let writePermissionType;
                    let writePermissionMembers;
                    if ($scope.data.type === $scope.groupType.public) {
                        writePermissionType = $scope.data.selectedGroupPermissionsType.apiName;
                        writePermissionMembers = $scope.data.writePermissionMembers
                            .concat($scope.data.supportUsersInWritePermissions)
                            .map((member) => member.id);
                    } else {
                        writePermissionType = 'EVERYONE';
                        writePermissionMembers = [];
                    }

                    if ($scope.data.isEdit) {
                        // In edit mode.
                        return groupManager.updateGroup(
                            updateGroupAsync,
                            $scope.data.group.id,
                            $scope.data.name,
                            $scope.data.type,
                            ids,
                            $scope.data.notificationType,
                            channel,
                            $scope.data.allowOwnersNotFromList,
                            $scope.data.group.metadata,
                            $scope.data.canAccessWithToken,
                            null,
                            writePermissionType,
                            writePermissionMembers,
                            !$scope.data.allowWorkerErrorAlerts,
                            $scope.data.defaultActor.id,
                            $scope.data.group.archiveDoneAfterDays,
                            $scope.data.deleteArchivedAfterDays * $scope.data.selectedArchivePeriodType.value,
                            $scope.data.isSupportUserPermitted,
                            $scope.data.communicationIntegration?.id,
                            $scope.data.smartSearchEnabled,
                        );
                    } else {
                        if ($scope.group.isDuplicatedList) {
                            const owners = $scope.data.group.owners.map((member) => member.id);
                            // In duplicate mode.
                            return groupManager
                                .duplicateGroup(
                                    $scope.data.group.sourceGroupId,
                                    $scope.data.name,
                                    $scope.data.type,
                                    ids,
                                    $scope.data.notificationType,
                                    channel,
                                    $scope.data.allowOwnersNotFromList,
                                    $scope.data.group.metadata,
                                    shouldSendGatherUpdates,
                                    $scope.data.canAccessWithToken,
                                    false,
                                    null,
                                    writePermissionType,
                                    writePermissionMembers,
                                    false,
                                    false,
                                    workerType,
                                    $scope.data.defaultActor.id,
                                    $scope.data.group.archiveDoneAfterDays,
                                    $scope.data.deleteArchivedAfterDays * $scope.data.selectedArchivePeriodType.value,
                                    owners,
                                    $scope.data.workflowFolderId,
                                    $scope.data.workflowFolderCategoryId,
                                )
                                .then((groupData) => {
                                    return $q.resolve(groupData);
                                });
                        } else {
                            // In create mode.
                            return groupManager
                                .createGroup(
                                    $scope.pm.project.id,
                                    $scope.data.name,
                                    $scope.data.type,
                                    ids,
                                    $scope.data.notificationType,
                                    channel,
                                    $scope.data.allowOwnersNotFromList,
                                    $scope.data.group.metadata,
                                    shouldSendGatherUpdates,
                                    $scope.data.canAccessWithToken,
                                    false,
                                    null,
                                    writePermissionType,
                                    writePermissionMembers,
                                    false,
                                    false,
                                    workerType,
                                    null,
                                    null,
                                    null,
                                    null,
                                    null,
                                    null,
                                    null,
                                    null,
                                    null,
                                    $scope.data.defaultActor.id,
                                    $scope.data.group.archiveDoneAfterDays,
                                    $scope.data.deleteArchivedAfterDays * $scope.data.selectedArchivePeriodType.value,
                                    $scope.data.workflowFolderId,
                                    $scope.data.workflowFolderCategoryId,
                                )
                                .then((groupData) => {
                                    // update groups list and maps
                                    return groupInfoManager.getGroup(groupData.id, true).then(() => $q.resolve(groupData));
                                });
                        }
                    }
                })
                .catch((error) => {
                    $scope.error = error;
                    // Pop the current user from the array.
                    $scope.data.members.pop();
                    // Re-throw the error we've got.
                    return $q.reject(error);
                });
        }

        return $q.resolve();
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('EditGroupCtrl', EditGroupCtrl);
