<div class="margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'CREATE_FEEDBACK'" class="margin-bottom">
        <!-- Opportunity ID -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Opportunity ID:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Opportunity ID"
                saved-expression="data.definition.opportunityIdExpression"
                on-tonkean-expression-changed="onOpportunityIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.opportunityIdError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.opportunityIdError }}
        </div>

        <!-- Feedback Template ID -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Feedback Template ID:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Feedback Template ID"
                saved-expression="data.definition.feedbackIdExpression"
                on-tonkean-expression-changed="onFeedbackIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div ng-if="data.validationObject.feedbackIdError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.feedbackIdError }}
        </div>

        <!-- Perform As -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Perform As:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Perform this create on behalf of a specified user ID."
                ></i>
            </div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="User ID"
                saved-expression="data.definition.performAsExpression"
                on-tonkean-expression-changed="onPerformAsTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div ng-if="data.validationObject.performAsError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.performAsError }}
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'UPDATE_FEEDBACK'" class="margin-bottom">
        <!-- Opportunity ID -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Opportunity ID:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Opportunity ID"
                saved-expression="data.definition.opportunityIdExpression"
                on-tonkean-expression-changed="onOpportunityIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.opportunityIdError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.opportunityIdError }}
        </div>

        <!-- Feedback ID -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Opportunity Feedback ID:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Opportunity Feedback ID"
                saved-expression="data.definition.feedbackIdExpression"
                on-tonkean-expression-changed="onFeedbackIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div ng-if="data.validationObject.feedbackIdError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.feedbackIdError }}
        </div>

        <!-- Perform As -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Perform As:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Perform this create on behalf of a specified user ID."
                ></i>
            </div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="User ID"
                saved-expression="data.definition.performAsExpression"
                on-tonkean-expression-changed="onPerformAsTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div ng-if="data.validationObject.performAsError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.performAsError }}
        </div>

        <!-- TimeStamp -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Completed At TimeStamp:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="TimeStamp"
                saved-expression="data.definition.completedAtExpression"
                on-tonkean-expression-changed="onCompletedAtTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Fields Array -->
        <div class="margin-bottom-lg">
            <div ng-if="data.definition.fields.length > 0" class="margin-right margin-top-xs margin-bottom">
                Fields ({{ data.definition.fields.length }}):
            </div>

            <div ng-repeat="(fieldIndex, field) in data.definition.fields" class="flex">
                <div class="margin-left-xs margin-right-xs margin-top">{{ fieldIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Field ID -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">
                                Field ID:
                                <i
                                    class="fa fa-info-circle common-color-primary margin-left-xs"
                                    uib-tooltip="The field's identifier."
                                ></i>
                            </div>

                            <div class="flex-grow"></div>

                            <button
                                class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                ng-click="removeField(fieldIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Field ID"
                            saved-expression="data.definition.fields[fieldIndex].fieldIdExpression"
                            on-tonkean-expression-changed="
                                onFieldIdTonkeanExpressionChanged(expression, fieldIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Field Value -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Value:</div>
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Field Value"
                            saved-expression="data.definition.fields[fieldIndex].fieldValueExpression"
                            on-tonkean-expression-changed="
                                onFieldValueTonkeanExpressionChanged(expression, fieldIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>
                </div>
            </div>

            <a ng-click="addField()">+ Add Field</a>
        </div>

        <div ng-if="data.validationObject.fieldsError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.fieldsError }}
        </div>
    </div>
</div>
