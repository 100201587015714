import React from 'react';
import type { Element } from 'slate';
import { Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react';
import styled, { css } from 'styled-components';

import { useKeyShortcutCallback } from '@tonkean/infrastructure';
import { XIcon } from '@tonkean/svg';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { colorSvg } from '@tonkean/utils';

const Wrapper = styled.span<{
    showFocus: boolean;
    thin: boolean;
}>`
    display: inline;
    vertical-align: baseline;
    cursor: pointer;
    outline: none;

    padding: ${({ thin }) => (thin ? 0 : 2)}px 3px;
    margin: ${({ thin }) => (thin ? 0 : 1)}px 1px;
    border-radius: 5px;

    color: ${Theme.colors.gray_700};
    font-size: 12px;
    line-height: 18px;

    ${({ showFocus }) =>
        showFocus &&
        css`
            box-shadow: 0 0 0 2px #afeafb;
        `};
`;

const RemoveIcon = styled.span`
    display: inline-flex;
    vertical-align: baseline;
    height: 12px;
    width: 12px;
    padding: 2px;
    margin-left: 3px;

    background: #ffffff;
    border-radius: 100%;

    svg {
        display: flex;
        width: 100%;
        height: 100%;
        ${colorSvg('#3fa7b8')};
    }
`;

interface Props extends StyledComponentsSupportProps {
    active?: boolean;
    thin?: boolean;
    onClick(): void;
    elementToDelete?: Element;
}

const ExpressionEditorTagWrapper: React.ForwardRefRenderFunction<HTMLSpanElement, React.PropsWithChildren<Props>> = (
    { onClick, children, elementToDelete, active = true, thin = false, className },
    ref,
) => {
    const selected = useSelected();
    const focused = useFocused();
    const isActive = selected && focused && active;

    const editor = useSlateStatic();
    const deleteTag = () => {
        if (!elementToDelete) {
            return;
        }

        const path = ReactEditor.findPath(editor as ReactEditor, elementToDelete);
        Transforms.removeNodes(editor, { at: path });
    };

    useKeyShortcutCallback('Enter', onClick, false, isActive, false);
    useKeyShortcutCallback('Backspace', deleteTag, false, isActive, false);

    // We use editor's focused and selected state, and useEventListener to handle clicks using keyboard.
    /* eslint-disable styled-components-a11y/click-events-have-key-events,styled-components-a11y/no-static-element-interactions */
    return (
        <Wrapper
            showFocus={isActive}
            thin={thin}
            className={className}
            onClick={(event) => {
                event.stopPropagation();
                onClick();
            }}
            ref={ref}
        >
            {children}

            {elementToDelete && (
                <RemoveIcon
                    onClick={(event) => {
                        event.stopPropagation();
                        deleteTag();
                    }}
                >
                    <XIcon />
                </RemoveIcon>
            )}
        </Wrapper>
    );
};

export default React.forwardRef(ExpressionEditorTagWrapper);
