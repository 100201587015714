import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

import useItemInterfaceContextIfValid from '../../utils/useItemInterfaceContextIfValid';

import type { TonkeanType, TonkeanId, FieldDefinition } from '@tonkean/tonkean-entities';

function useGetLoadedFieldDefinitionById(
    workflowVersion: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
): (fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>) => FieldDefinition | undefined {
    const customFieldsManager = useAngularService('customFieldsManager');

    const itemInterfaceContext = useItemInterfaceContextIfValid();

    return useCallback(
        (fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>): FieldDefinition | undefined => {
            const fieldDefinitionFromContext = itemInterfaceContext?.itemInterfaceFieldDefinitions?.find(
                (fieldDefinition) => fieldDefinition.id === fieldDefinitionId,
            );

            if (fieldDefinitionFromContext) {
                return fieldDefinitionFromContext;
            }

            if (!!workflowVersion) {
                return (
                    customFieldsManager.getFieldDefinitionFromCachesById(workflowVersion, fieldDefinitionId) ??
                    undefined
                );
            }

            return undefined;
        },
        [customFieldsManager, itemInterfaceContext?.itemInterfaceFieldDefinitions, workflowVersion],
    );
}

export default useGetLoadedFieldDefinitionById;
