import React, { useCallback, useEffect } from 'react';

import PeopleSelector from './PeopleSelector';
import type { PeopleSelectorProps } from './PeopleSelector';
import useLazyGetEntitiesFromPersonCache from '../../hooks/useLazyGetEntitiesFromPersonCache';

import type { PersonSummary } from '@tonkean/tonkean-entities';

/**
 * This component is an adapter for the NewPeopleSelector component.
 * Its purpose is to convert the selectedPeople prop between Person[] to string.
 */
interface PeopleSelectorByPersonIdentifierProps extends Omit<PeopleSelectorProps, 'onValueChange' | 'selectedPeople'> {
    selectedEmailsOrIds: string | undefined;
    onChange: (emails: string | null) => void;
}

const PeopleSelectorByPersonIdentifier: React.FC<PeopleSelectorByPersonIdentifierProps> = ({
    selectedEmailsOrIds,
    onChange,
    isMulti,
    ...props
}: PeopleSelectorByPersonIdentifierProps) => {
    const [people, getEntitiesByEmailOrIds] = useLazyGetEntitiesFromPersonCache();

    useEffect(() => {
        let arrayOfSelectedEmailsOrIds: string[] | undefined;
        if (selectedEmailsOrIds === undefined) {
            arrayOfSelectedEmailsOrIds = [];
        } else if (isMulti) {
            arrayOfSelectedEmailsOrIds = selectedEmailsOrIds?.replaceAll(' ', '').split(',');
        } else {
            arrayOfSelectedEmailsOrIds = [selectedEmailsOrIds];
        }
        getEntitiesByEmailOrIds(arrayOfSelectedEmailsOrIds);
    }, [getEntitiesByEmailOrIds, isMulti, selectedEmailsOrIds]);

    const onChangeNewPeopleSelector = useCallback(
        (newPeople: PersonSummary[]) => {
            if (isMulti) {
                const newPeopleEmails = newPeople.map((person) => person.email);
                onChange(newPeopleEmails.join(','));
            } else {
                if (newPeople.length > 0) {
                    onChange(newPeople.map((person) => person.id).join(','));
                } else {
                    onChange(null);
                }
            }
        },
        [isMulti, onChange],
    );
    return (
        <PeopleSelector
            {...props}
            onValueChange={onChangeNewPeopleSelector}
            selectedPeople={people}
            isMulti={isMulti}
        />
    );
};

export default PeopleSelectorByPersonIdentifier;
