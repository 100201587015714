import type { MouseEvent, ReactElement } from 'react';
import React, { useRef, useState } from 'react';

interface Props {
    /**
     * should the tooltip be displayed? If undefined,
     * it will show when hovering.
     */
    show?: boolean;
    /**
     * the tooltip text or JSX
     */
    tooltipContent: ReactElement | string;
    /**
     * the css class for the tooltip. If undefined, will
     * use `tooltip-zone-tooltip tnk-tooltip-text`.
     */
    tooltipClassName?: string;
}

/**
 * Shows a tooltip above the cursor.
 *
 * @deprecated use the Tooltip component
 */
const TooltipZone: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    tooltipContent,
    show,
    tooltipClassName = 'tooltip-zone-tooltip tnk-tooltip-text',
}) => {
    const containerElement = useRef<HTMLDivElement>(null);

    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const setPosition = (event: MouseEvent<HTMLDivElement>) => {
        if (!containerElement.current) {
            return;
        }

        const x = event.clientX;
        const y = event.clientY;

        setTooltipPosition({ x, y });
    };

    const showTooltip = show ?? tooltipVisible;

    return (
        <div
            className="tnk-tooltip mod-top"
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            onMouseMove={setPosition}
            ref={containerElement}
        >
            {children}

            <div
                className={tooltipClassName}
                style={{
                    top: tooltipPosition.y,
                    left: tooltipPosition.x,
                    display: showTooltip ? 'table' : 'none',
                    visibility: showTooltip ? 'visible' : 'hidden',
                }}
            >
                {tooltipContent}
            </div>
        </div>
    );
};

export { TooltipZone };
