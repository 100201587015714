import React, { useState } from 'react';
import styled from 'styled-components';

import { EnvironmentIndicator, Placeholder } from '@tonkean/infrastructure';
import type { Environment } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const EnvironmentPicker = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_800};
    margin-bottom: 10px;
    padding: 20px;
`;

const StyledEnvironmentIndicator = styled(EnvironmentIndicator)`
    margin-left: 8px;
    margin-right: 8px;
`;

const StyledPlaceholder = styled(Placeholder)`
    margin-right: 2px;
`;

interface Props {
    groupsCount: number;
    loading?: boolean;
    error?: string;
    onChange: (environment: Environment) => void;
}

const TriggeredModulesEnvironmentPicker: React.FC<Props> = ({ groupsCount, loading = false, error = '', onChange }) => {
    const [environment, setEnvironment] = useState<Environment>('production' as Environment);

    return (
        <EnvironmentPicker>
            In
            <StyledEnvironmentIndicator
                currentEnvironment={environment}
                onChanges={(newEnvironment) => {
                    setEnvironment(newEnvironment);
                    onChange(newEnvironment);
                }}
                disabled
            />
            {loading && <StyledPlaceholder $width="16px" $circle />}
            {error && <>Unknown number of </>}
            {!loading && !error && <>{groupsCount} </>}
            modules were triggered by this item
        </EnvironmentPicker>
    );
};

export default TriggeredModulesEnvironmentPicker;
