import { FieldsWidgetDisplayType } from '../FieldsWidgetModule/FieldsItemWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useIsMobile } from '@tonkean/infrastructure';

function useFieldsWidgeEffectiveDisplayType(
    fieldDisplay?: FieldsWidgetDisplayType,
    fieldDisplayInMobile?: FieldsWidgetDisplayType,
): FieldsWidgetDisplayType {
    const enableTableDisplayMode = useFeatureFlag('tonkean_feature_table_display_mode_in_fields_widget') as boolean;

    const isMobile = useIsMobile();

    if (isMobile) {
        if (fieldDisplayInMobile === FieldsWidgetDisplayType.TABLE && !enableTableDisplayMode) {
            return FieldsWidgetDisplayType.GALLERY;
        }

        return fieldDisplayInMobile ?? FieldsWidgetDisplayType.GALLERY;
    } else {
        if (fieldDisplayInMobile === FieldsWidgetDisplayType.TABLE && !enableTableDisplayMode) {
            return FieldsWidgetDisplayType.LIST;
        }

        return fieldDisplay ?? FieldsWidgetDisplayType.LIST;
    }
}

export default useFieldsWidgeEffectiveDisplayType;
