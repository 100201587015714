import { useField } from 'formik';
import * as React from 'react';

import EnterpriseComponentAuthorizationApiToken from './EnterpriseComponentAuthorizationApiToken';
import EnterpriseComponentAuthorizationOAuth2 from './EnterpriseComponentAuthorizationOAuth2';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { EnterpriseComponentAuthorizationType } from '@tonkean/tonkean-entities';

interface Props {
    expressionProps: Partial<TonkeanExpressionProps>;
    projectIntegration: ProjectIntegration;
}

const RelevantAuthorizationComponent: React.FC<Props> = ({ expressionProps, projectIntegration }) => {
    const [{ value: authorizationType }] = useField<EnterpriseComponentAuthorizationType>('type');

    switch (authorizationType) {
        case EnterpriseComponentAuthorizationType.API_KEY:
            return <EnterpriseComponentAuthorizationApiToken expressionProps={expressionProps} />;

        case EnterpriseComponentAuthorizationType.OAUTH2:
            return (
                <EnterpriseComponentAuthorizationOAuth2
                    projectIntegration={projectIntegration}
                    expressionProps={expressionProps}
                />
            );

        case EnterpriseComponentAuthorizationType.NONE:
            return <></>;

        default:
            return <>Unsupported Authorization</>;
    }
};

export default RelevantAuthorizationComponent;
