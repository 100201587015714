/**
 * Created by sagieliyahu on 9/25/15.
 */

export const personPatternFilter = angular.module('tonkean.app').filter('personPatternFilter', function () {
    return function (input, pattern) {
        if (!pattern || pattern.id === -1) {
            return input;
        }
        const result = [];

        angular.forEach(input, function (item) {
            if (item.peoplePatternsScore[pattern.key]) {
                result.push(item);
            }
        });
        return result;
    };
});

export const onlyActiveFilter = angular.module('tonkean.app').filter('onlyActiveFilter', function () {
    return function (input, onlyActive) {
        if (!onlyActive) {
            return input;
        }
        const result = [];

        angular.forEach(input, function (item) {
            if (item.isActiveContributer) {
                result.push(item);
            }
        });
        return result;
    };
});
