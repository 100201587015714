import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import FieldsWidgetConfigFieldWidth from './FieldsWidgetConfigFieldWidth';
import type FieldsWidgetMenuItem from './FieldsWidgetMenuItem';
import getFieldLabelToDisplay from '../../../utils/getFieldLabelToDisplay';
import type { SingleFieldConfiguration } from '../../CommonWidgetConfiguration/SingleFieldConfiguration';

import { getFieldInstance } from '@tonkean/fields';
import { FieldTypeIcon } from '@tonkean/fields';
import {
    Checkbox,
    Input,
    KebabMenuButton,
    Menu,
    MenuItem,
    TextEllipsis,
    useFormikField,
} from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { CheckIconSVG } from '@tonkean/svg';
import { XIcon as XIconSVG } from '@tonkean/svg';
import { ArrowDownIcon } from '@tonkean/svg';
import { ArrowUpIcon } from '@tonkean/svg';
import { FieldDefinitionTargetType, FieldType, FormDefinitionType } from '@tonkean/tonkean-entities';
import type { FieldDefinition, Initiative, WorkflowVersion, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { getInitiativeFieldValue } from '@tonkean/tonkean-utils';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY, UtilsClass } from '@tonkean/utils';

const FieldRow = styled.div`
    margin-bottom: 8px;

    display: grid;
    grid-template-columns: 5fr auto;
    align-items: center;

    .move-button {
        visibility: hidden;
    }

    &:hover .move-button {
        visibility: unset;
    }
`;

const FieldDetailsWrapper = styled.div<{ fullBorder: boolean; checked: boolean | undefined; menuOpen: boolean }>`
    margin-left: 6.1%;
    border: 1px solid ${Theme.colors.gray_300};
    box-sizing: border-box;
    border-radius: 4px;

    ${({ fullBorder }) =>
        !fullBorder &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        `}

    font-size: ${FontSize.SMALL_12};
    ${({ checked }) =>
        checked
            ? css`
                  color: ${Theme.colors.gray_700};
              `
            : css`
                  color: ${Theme.colors.gray_500};
              `}
    order: 2;
    flex-grow: 1;
    display: flex;
    gap: 6px;
    align-items: center;
    align-content: center;

    ${({ menuOpen }) =>
        menuOpen &&
        css`
            background: rgba(47, 132, 220, 0.05);
            border: 1px solid ${Theme.colors.focus};
            box-sizing: border-box;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right: none;
        `}
`;

const FieldTexts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 5px 0;
    max-width: 90%;
`;

const SubtitleRow = styled.div<{ pushContentLeft?: boolean }>`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    min-height: 12px;
    left: 0;
    top: calc(50% - 10px / 2);
    order: 0;
    flex-grow: 0;
    margin: 2px 10px;

    ${({ pushContentLeft }) =>
        pushContentLeft &&
        css`
            & > * {
                margin-left: auto;
            }
        `}
`;

const PencilWrapper = styled.span<{ collapseWidth: boolean; menuOpen: boolean }>`
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    height: 100%;
    padding: 4px;
    display: grid;
    place-items: center;

    ${({ collapseWidth }) =>
        collapseWidth &&
        css`
            &,
            * {
                border: 0;
                padding: 0;
            }
        `}

    ${({ menuOpen }) =>
        menuOpen &&
        css`
            background: rgba(47, 132, 220, 0.05);
            border: 1px solid #2f84dc;
            box-sizing: border-box;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-left: none;
        `}
`;

const EditFieldLabelRow = styled.span`
    display: flex;
    gap: 4px;
`;

const CheckIcon = styled(CheckIconSVG)`
    height: 12px !important;
    width: 12px !important;
    margin-right: 0 !important;
`;
const XIcon = styled(XIconSVG)`
    height: 12px !important;
    width: 12px !important;
    margin-right: 0 !important;
`;

const FieldNameDisplayWrapper = styled.span`
    letter-spacing: normal;
    position: static;
    height: 10px;
    left: 0;
    top: calc(50% - 10px / 2);
    order: 0;
    flex-grow: 1;
    margin: 2px 10px;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    gap: 4px;
`;

const ExtraBorder = styled.div<{ checked?: boolean }>`
    width: 2px;
    height: 60px;
    margin-left: 3px;
    border-radius: 4px;
    ${({ checked }) =>
        checked
            ? css`
                  background: ${Theme.colors.primaryHighlight};
              `
            : css`
                  background: ${Theme.colors.gray_500};
              `}
`;

const ActionWrapper = styled.div`
    display: flex;
`;

const RequiredIndication = styled.div`
    color: #d10027;
`;

const FieldDefinitionNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const StyledFieldTypeIcon = styled(FieldTypeIcon)`
    margin-right: 4px;
`;

const StyledSubtitleRow = styled(SubtitleRow)`
    margin-bottom: 0;
`;

interface Props {
    formikName: string;
    fieldDefinition: FieldDefinition;
    exampleItem: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    workflowVersionType?: WorkflowVersionType;
    isShown?: boolean;
    maxFieldIndex?: number;
    minFieldIndex?: number;
    fieldIndex: number;
    disabledCheckbox?: boolean;
    shouldToggleFilters?: boolean;
    disableEditingField?: boolean;
    disableChangeOrder?: boolean;
    additionalMenuItems?: FieldsWidgetMenuItem[];
    supportRequiredFields?: boolean;

    setIsFieldShown(fieldId: string, isShown: boolean): void;

    onRemoveDeletedFields(fieldIds: string[]): void;

    updateFieldIndex(fieldId: string, index: number): void;

    fieldWidthConfig: {
        increaseFieldWidth?(fieldId: FieldDefinition['id']): void;
        decreaseFieldWidth?(fieldId: FieldDefinition['id']): void;
    };
}

const utils = new UtilsClass();

const FieldsWidgetConfigFieldRow: React.FC<Props> = ({
    fieldDefinition,
    formikName,
    exampleItem,
    workflowVersion,
    workflowVersionType,
    isShown,
    setIsFieldShown,
    onRemoveDeletedFields,
    maxFieldIndex,
    minFieldIndex,
    updateFieldIndex,
    fieldIndex,
    disableEditingField,
    disabledCheckbox = false,
    shouldToggleFilters = false,
    disableChangeOrder = false,
    additionalMenuItems,
    supportRequiredFields = false,
    fieldWidthConfig,
}) => {
    const { value, setValue } = useFormikField<SingleFieldConfiguration | undefined>(formikName);
    const { value: labelValue, setValue: setLabelValueInForm } = useFormikField<string | undefined>(
        `${formikName}.label`,
    );
    const { increaseFieldWidth, decreaseFieldWidth } = fieldWidthConfig;
    const projectManager = useAngularService('projectManager');

    // fix old configuration
    useEffect(() => {
        if (isShown === undefined) {
            setIsFieldShown(fieldDefinition.id, true);
        }
    }, [fieldDefinition.id, isShown, setIsFieldShown]);

    const initialLabel = getFieldLabelToDisplay(fieldDefinition, labelValue);
    const [fieldLabel, setFieldLabel] = useState<string | undefined>(initialLabel);

    const [isEditingLabel, setIsEditingLabel] = useState(false);
    const [showKebabMenu, setShowKebabMenu] = useState(true);

    useEffect(() => {
        setShowKebabMenu(!isEditingLabel);
    }, [isEditingLabel]);

    const [menuOpen, setMenuOpen] = useState(false);
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const exampleValue = useMemo(() => {
        if (!exampleItem || !workflowVersion) {
            return getFieldInstance(
                fieldDefinition,
                exampleItem,
                workflowVersion,
                undefined,
                true,
                groupsMap,
                workflowVersionType,
            )?.formattedValue;
        }
        const initiativeValue = getInitiativeFieldValue(exampleItem, fieldDefinition, workflowVersion, true)?.value;

        if (fieldDefinition.fieldType === FieldType.Date) {
            return initiativeValue && new Date(initiativeValue).toLocaleDateString();
        }

        return initiativeValue;
    }, [exampleItem, fieldDefinition, groupsMap, workflowVersion, workflowVersionType]);

    useEffect(() => {
        if (!value) {
            setFieldLabel(fieldDefinition.name);
        }
    }, [fieldDefinition.name, value]);

    const updateLabelValue = useCallback(
        (e?: React.MouseEvent<HTMLButtonElement>) => {
            setLabelValueInForm(fieldLabel);
            setIsEditingLabel(false);

            e?.preventDefault();
        },
        [fieldLabel, setLabelValueInForm],
    );

    const cancelLabelEditing = useCallback(
        (e?: React.MouseEvent<HTMLButtonElement>) => {
            setFieldLabel(initialLabel);
            setIsEditingLabel(false);

            e?.preventDefault();
        },
        [initialLabel],
    );

    const handleLabelInputKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            switch (e.key) {
                case 'Enter': {
                    return updateLabelValue();
                }
                case 'Escape': {
                    return cancelLabelEditing();
                }
            }
        },
        [cancelLabelEditing, updateLabelValue],
    );

    const restoreLabelToDefault = useCallback(() => {
        setLabelValueInForm(undefined);
        setFieldLabel(getFieldLabelToDisplay(fieldDefinition, ''));
    }, [fieldDefinition, setLabelValueInForm]);

    const toggleTrendAndColors = useCallback(() => {
        setValue({ ...value, hideColorsAndTrends: !value?.hideColorsAndTrends });
    }, [value, setValue]);

    const toggleIsFilterColumn = useCallback(() => {
        setValue({ ...value, isFilterColumn: !value?.isFilterColumn });
    }, [value, setValue]);

    const toggleIsRequired = useCallback(() => {
        setValue({ ...value, isRequired: !value?.isRequired });
    }, [value, setValue]);

    const removeField = useCallback(() => {
        onRemoveDeletedFields([fieldDefinition.id]);
    }, [onRemoveDeletedFields, fieldDefinition.id]);

    const moveField = useCallback(
        (change: number) => {
            updateFieldIndex(fieldDefinition.id, fieldIndex + change);
        },
        [fieldDefinition.id, updateFieldIndex, fieldIndex],
    );

    const kebabMenuRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const isGlobalField = fieldDefinition.targetType === FieldDefinitionTargetType.GLOBAL;
    const disableToggleFilterColumn =
        !shouldToggleFilters || isGlobalField || fieldDefinition.fieldType === FieldType.Date;

    return (
        <FieldRow>
            <Checkbox
                dataAutomation="fields-widget-config-field-row"
                checked={isShown}
                onChange={(e) => setIsFieldShown(fieldDefinition.id, e.target.checked)}
                boldLabel={false}
                disabled={disabledCheckbox}
                highlighted
                labelFillSpace
            >
                <FieldDetailsWrapper fullBorder={isEditingLabel} checked={isShown} menuOpen={menuOpen}>
                    <ExtraBorder checked={isShown} />
                    <FieldTexts>
                        {isEditingLabel ? (
                            <EditFieldLabelRow>
                                <Input
                                    data-automation="field-widget-config-field-row-edit-field-input"
                                    value={getFieldLabelToDisplay(fieldDefinition, fieldLabel)}
                                    onChange={(e) => setFieldLabel(e.target.value)}
                                    onKeyDown={handleLabelInputKeyPress}
                                    ref={inputRef}
                                />
                                <Button
                                    data-automation="field-widget-config-field-row-save-edit-field-input"
                                    size={ButtonSize.MEDIUM_SNUG}
                                    shape={ButtonShape.RECTANGULAR}
                                    onClick={updateLabelValue}
                                    aria-label="save field label"
                                    flex
                                    highlighted
                                >
                                    <CheckIcon />
                                </Button>
                                <Button
                                    data-automation="field-widget-config-field-row-discard-edit-field-input"
                                    size={ButtonSize.MEDIUM_SNUG}
                                    shape={ButtonShape.RECTANGULAR}
                                    onClick={cancelLabelEditing}
                                    aria-label="discard editing of field label"
                                    flex
                                    highlighted
                                    outlined
                                >
                                    <XIcon />
                                </Button>
                            </EditFieldLabelRow>
                        ) : (
                            <FieldNameDisplayWrapper data-automation="field-widget-config-field-row-selected-field-name">
                                <TextEllipsis numberOfLines={1} tooltip>
                                    {getFieldLabelToDisplay(fieldDefinition, fieldLabel)}
                                </TextEllipsis>
                                {value?.isRequired && <RequiredIndication>(*)</RequiredIndication>}
                            </FieldNameDisplayWrapper>
                        )}
                        <StyledSubtitleRow pushContentLeft={isEditingLabel}>
                            <FieldDefinitionNameWrapper>
                                <StyledFieldTypeIcon fieldType={fieldDefinition.fieldType || ''} size="xxxs" />
                                <TextEllipsis numberOfLines={1} tooltip>
                                    {fieldDefinition.name}
                                </TextEllipsis>
                            </FieldDefinitionNameWrapper>
                        </StyledSubtitleRow>
                        <SubtitleRow pushContentLeft={isEditingLabel}>
                            <TextEllipsis numberOfLines={1} tooltip>
                                {exampleValue}
                            </TextEllipsis>
                        </SubtitleRow>
                    </FieldTexts>
                </FieldDetailsWrapper>
            </Checkbox>

            <PencilWrapper collapseWidth={isEditingLabel} menuOpen={menuOpen}>
                <Menu
                    show={menuOpen}
                    onClose={() => setMenuOpen(false)}
                    menuItems={[
                        <MenuItem
                            data-automation="fields-widget-config-field-row-edit-field"
                            key="edit"
                            onClick={() => setIsEditingLabel(true)}
                            disabled={disableEditingField}
                        >
                            Edit field label
                        </MenuItem>,
                        <MenuItem
                            data-automation="fields-widget-config-field-row-restore-to-default"
                            key="restore"
                            onClick={restoreLabelToDefault}
                            disabled={disableEditingField}
                        >
                            Restore to default
                        </MenuItem>,
                        <MenuItem
                            data-automation="fields-widget-config-field-row-color-and-trends"
                            key="hide-color-and-trends"
                            onClick={toggleTrendAndColors}
                            disabled={disableEditingField}
                        >
                            {value?.hideColorsAndTrends ? 'Enable' : 'Disable'} Colors and Trends
                        </MenuItem>,
                        <MenuItem
                            data-automation="fields-widget-config-field-row-toggle-column-filter"
                            key="filter"
                            onClick={toggleIsFilterColumn}
                            disabled={disableToggleFilterColumn}
                        >
                            <Tooltip
                                content={`${isGlobalField ? 'Global' : 'Date'} field can't be displayed as filter`}
                                disabled={!disableToggleFilterColumn}
                                placement="left-start"
                            >
                                <div>{value?.isFilterColumn ? 'Hide' : 'Show'} column filters</div>
                            </Tooltip>
                        </MenuItem>,
                        ...(supportRequiredFields
                            ? [
                                  <MenuItem
                                      data-automation="fields-widget-config-field-row-toggle-column-require"
                                      key="require"
                                      onClick={toggleIsRequired}
                                      disabled={
                                          !(
                                              fieldDefinition.type === FormDefinitionType.MANUAL ||
                                              fieldDefinition.isSpecialField
                                          )
                                      }
                                  >
                                      <Tooltip
                                          content="Only manual fields can be required"
                                          disabled={
                                              fieldDefinition.type === FormDefinitionType.MANUAL ||
                                              fieldDefinition.isSpecialField
                                          }
                                          placement="left-start"
                                      >
                                          <div>
                                              {value?.isRequired ? 'Set field as optional' : 'Set field as required'}
                                          </div>
                                      </Tooltip>
                                  </MenuItem>,
                              ]
                            : []),
                        ...(!utils.isEmpty(fieldWidthConfig)
                            ? [
                                  <MenuItem
                                      data-automation="fields-widget-config-field-row-column-width"
                                      key="column-width"
                                      disabled={disableEditingField}
                                      preventCloseMenu
                                  >
                                      <FieldsWidgetConfigFieldWidth
                                          decreaseFieldWidth={decreaseFieldWidth}
                                          fieldDefinition={fieldDefinition}
                                          increaseFieldWidth={increaseFieldWidth}
                                          disableEditingField={disableEditingField}
                                      />
                                  </MenuItem>,
                              ]
                            : []),
                        (additionalMenuItems || EMPTY_ARRAY).map(({ key, onClick, title, isDisabled }) => (
                            <MenuItem
                                disabled={isDisabled(fieldDefinition.displayAs || fieldDefinition.fieldType)}
                                key={key}
                                onClick={() =>
                                    onClick(
                                        fieldDefinition.id,
                                        fieldDefinition.name,
                                        fieldDefinition.displayAs || fieldDefinition.fieldType,
                                    )
                                }
                            >
                                {typeof title === 'function' ? title(fieldDefinition.id) : title}
                            </MenuItem>
                        )),
                        <MenuItem
                            data-automation="fields-widget-config-field-row-remove-field"
                            key="remove-field"
                            onClick={removeField}
                            disabled={disableEditingField}
                        >
                            Remove Field
                        </MenuItem>,
                        <MenuItem
                            data-automation="fields-widget-config-field-row-move-up"
                            key="move-up"
                            onClick={() => moveField(-1)}
                            disabled={fieldIndex === minFieldIndex || disableChangeOrder}
                        >
                            Move Up
                        </MenuItem>,
                        <MenuItem
                            data-automation="fields-widget-config-field-row-move-down"
                            key="move-down"
                            onClick={() => moveField(+1)}
                            disabled={fieldIndex === maxFieldIndex || disableChangeOrder}
                        >
                            Move Down
                        </MenuItem>,
                    ]}
                    nodeRef={kebabMenuRef}
                    // Fixing an issue with input not focusing because of the focus trap
                    restoreFocusElementRef={isEditingLabel ? inputRef : kebabMenuRef}
                />
                {showKebabMenu && (
                    <ActionWrapper>
                        <IconButton
                            disabled={fieldIndex === maxFieldIndex || disableChangeOrder}
                            onClick={() => moveField(+1)}
                            aria-label="Move down"
                            className="move-button"
                            flat
                        >
                            <ArrowDownIcon />
                        </IconButton>
                        <IconButton
                            disabled={fieldIndex === minFieldIndex || disableChangeOrder}
                            onClick={() => moveField(-1)}
                            aria-label="Move down"
                            className="move-button"
                            flat
                        >
                            <ArrowUpIcon />
                        </IconButton>
                        <KebabMenuButton
                            data-automation="fields-widget-config-field-row-add-field-kebab-menu"
                            onClick={() => setMenuOpen(true)}
                            aria-label={`edit ${getFieldLabelToDisplay(fieldDefinition, fieldLabel)} label`}
                            ref={kebabMenuRef}
                            flat
                        />
                    </ActionWrapper>
                )}
            </PencilWrapper>
        </FieldRow>
    );
};

export default React.memo(FieldsWidgetConfigFieldRow);
