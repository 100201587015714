import template from './tnkWorkflowItemSelection.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkflowItemSelection', {
    bindings: {
        performOnWorkerItem: '<',
        creatingCustomTriggerId: '<',
        idRelationFieldDefinitionId: '<',
        viewOnly: '<',
        entityMetadata: '<',
        entitiesMetadata: '<',
        groupId: '@',
        workflowVersionId: '@',
        onWorkflowItemSelectionChanged: '&',
        currentCustomTrigger: '<',
        entities: '<', // An array of strings that contains the entities we allow in the extended matching.
    },
    template,
    controller: 'WorkflowItemSelectionCtrl',
});
