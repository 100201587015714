import React, { useContext } from 'react';
import styled from 'styled-components';

import FormSettingsInput from './FormSettingsInput';
import FormSettingsLabel from './FormSettingsLabel';
import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';

import { Tooltip, useUUID } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { UpdateForm } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const FillFormTitle = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    form: UpdateForm;
    onFormDefinitionChanged: (logoUrl: string | undefined) => void;
}

const FormSettingsFillFormButtonLabel: React.FC<Props> = ({ form, onFormDefinitionChanged }) => {
    const { isEditable } = useContext(FormBuilderContext);

    const id = useUUID();

    return (
        <>
            <FormSettingsLabel htmlFor={id}>
                <FillFormTitle>Edit ‘Fill Form’ Button Label</FillFormTitle>
                <FormSettingsSectionHeaderSubtitle>
                    Add a default label for the button that opens the form.
                </FormSettingsSectionHeaderSubtitle>
            </FormSettingsLabel>
            <Tooltip content="Form cannot be edited when creating" disabled={isEditable}>
                <FormSettingsInput
                    data-automation="form-settings-fill-form-button-label-open-form"
                    id={id}
                    placeholder="Open Form"
                    value={form.definition.fillFormButtonLabel}
                    onChange={({ target: { value } }) => onFormDefinitionChanged(value)}
                    disabled={!isEditable}
                    halfWidth
                />
            </Tooltip>
        </>
    );
};

export default FormSettingsFillFormButtonLabel;
