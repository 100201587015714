<div class="container mod-main mod-center manage-account-page">
    <div class="common-title">Profile information</div>
    <div class="common-subtitle">
        Manage your profile information. This information will be visible to users that are part of the same projects
        you are.
    </div>
    <form name="formManageAccount" class="flex manage-account-profile-details">
        <div class="manage-account-avatar" ng-click="changeAvatar()">
            <img
                ng-if="data.currentUser.avatarUri"
                class="manage-account-avatar-img"
                tnk-img-events
                hide-on-error
                src="{{ data.currentUser.avatarUri }}"
            />

            <div class="manage-account-avatar-empty">Avatar</div>

            <div ng-if="data.currentUser.avatarUri" class="avatar-change-button">Change</div>
        </div>
        <div class="common-separator-v hidden-xs"></div>
        <div>
            <div class="form-group">
                <label for="userFullname">Full Name:</label>
                <input
                    required
                    type="text"
                    ng-model="data.name"
                    class="form-control"
                    id="userFullname"
                    placeholder="Your full name"
                    data-automation="manage-profile-add-first-name"
                />
            </div>
            <div class="form-group">
                <label for="userEmail">Work email:</label>
                <input
                    required
                    type="email"
                    disabled
                    ng-model="data.email"
                    class="form-control"
                    id="userEmail"
                    placeholder="Your Email"
                />
            </div>
            <div class="form-group">
                <label for="userTitle">What you do:</label>
                <input type="text" ng-model="data.title" class="form-control" id="userTitle" placeholder="Your title" />
            </div>
            <div class="form-group">
                <label for="userTitle">Phone number:</label>
                <input
                    type="text"
                    ng-model="data.phone"
                    class="form-control"
                    id="userPhone"
                    placeholder="Your phone number"
                    tnk-phone-input="true"
                />
            </div>

            <div class="form-group">
                <div class="common-bold">Contact me only by email</div>
                <div class="btn-group margin-top">
                    <label
                        class="btn btn-default"
                        ng-class="{ 'btn-primary': !data.preferEmail }"
                        uib-btn-radio="false"
                        ng-model="data.preferEmail"
                    >
                        No
                    </label>
                    <label
                        class="btn btn-default"
                        ng-class="{ 'btn-primary': data.preferEmail }"
                        uib-btn-radio="true"
                        ng-model="data.preferEmail"
                    >
                        Yes
                    </label>
                </div>
            </div>

            <div class="margin-top-xlg">
                <div class="common-bold">Notification settings</div>

                <div ng-if="cis.isIntegrationConnected('slack')" class="margin-left-md">
                    <tnk-checkbox
                        label="'Don\'t send notifications to slack bot'"
                        value="data.preferImportantNotificationsAsUser"
                    ></tnk-checkbox>
                </div>

                <tnk-message-preferences
                    working-on-current-user="true"
                    on-selected-preferences-changed="onSelectedPreferencesChanged(selectedPreferences)"
                ></tnk-message-preferences>
            </div>

            <div class="form-group flex flex-row-reverse">
                <button type="submit" class="btn btn-primary common-width-full-xs" ng-click="saveChanges()">
                    Save changes
                </button>
            </div>
        </div>
    </form>
</div>
