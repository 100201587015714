<div ng-show="!data.hideSelectedAction" class="perform-integration-action-logic-configuration">
    <!-- Header -->
    <div class="flex-vmiddle padding-left margin-bottom">
        <!-- Integration -->
        <div class="flex-vmiddle mod-justify-center">
            <!-- Integration Icon (for when there's an integration) -->
            <span
                class="flex"
                ng-if="data.selectedProjectIntegration.integrationType"
                tooltip-placement="right"
                popover-append-to-body="true"
                uib-tooltip="{{ data.selectedProjectIntegration.displayName }}"
            >
                <i
                    ng-if="!data.selectedProjectIntegration.iconUrl"
                    class="integration-group-header-connected-logo hidden-xs margin-none mod-{{
                        data.selectedProjectIntegration.integrationType.toLowerCase()
                    }}"
                ></i>
                <i
                    ng-if="data.selectedProjectIntegration.iconUrl"
                    class="custom-icon-view integration-group-header-connected-logo hidden-xs margin-none mod-{{data.selectedProjectIntegration.integrationType.toLowerCase()"
                    ng-style="{ 'background-image': 'url(' + data.selectedProjectIntegration.iconUrl + ')' }"
                ></i>
            </span>
        </div>

        <!-- Arrow -->
        <i class="svg-icon-sm margin-right-xs margin-left-xs common-color-black">
            <tnk-icon src="/images/icons/arrow-right.svg"></tnk-icon>
        </i>

        <!-- Action -->
        <div class="flex-grow common-break-long">
            {{ data.selectedAction.title }} {{ getActionDefinitionEntityName() }}
        </div>

        <!-- Back button -->
        <div
            class="common-size-xxxs margin-left margin-right"
            ng-if="data.selectedAction"
            uib-tooltip="Remove current configuration and choose a different action"
            tooltip-placement="left-bottom"
        >
            <a ng-click="reset()" class="margin-left common-color-grey">
                <i class="fa fa-eraser margin-right-xs"></i>
                Reset
            </a>
        </div>
    </div>

    <div
        class="margin-left margin-right common-size-xxxs"
        ng-if="
            data.selectedProjectIntegrationAction &&
            !data.selectedProjectIntegrationAction.id.startsWith('NIAC') &&
            !data.selectedProjectIntegrationAction.isImported &&
            isCurrentUserAnAdminInSelectedProjectIntegration()
        "
    >
        Custom action

        <a ng-click="navigateToActionInformationTabWithReturnTo()">
            {{ data.selectedProjectIntegrationAction.displayName }}
        </a>

        in
        <a ng-click="goToProjectIntegrationPageWithReturnTo(data.projectIntegrationPageMenuItemType.OVERVIEW)">
            {{ data.selectedProjectIntegration.displayName }}
        </a>

        <div class="common-color-light-grey">
            {{ data.selectedProjectIntegrationAction.description }}
        </div>
    </div>

    <!--  Action selected but integration is not configured (User selected integration which is not connected at all) -->
    <div ng-if="data.selectedAction && !data.selectedProjectIntegration.id">
        <div
            ng-init="displayName = integrations.getAllIntegrationsDisplayName()[getIntegrationType().toLowerCase()]"
            class="info-box margin-normal-sm flex"
        >
            <i class="fa fa-exclamation-triangle common-color-danger margin-right"></i>
            <span>
                To configure this action, you must first select a Data Source connection.
                <br />
                <a ng-click="toggleShowDataSourcesAlternatives()">Select {{ displayName }} data source</a>
            </span>
        </div>
    </div>

    <!-- Action selected but project integratino is not valid (=broken,fix mode)-->
    <div
        ng-if="
            data.selectedAction &&
            data.selectedProjectIntegration.id &&
            (!data.selectedProjectIntegration.valid || data.selectedProjectIntegration.disabled)
        "
    >
        <div
            ng-init="displayName = integrations.getAllIntegrationsDisplayName()[getIntegrationType().toLowerCase()]"
            class="info-box margin-normal-sm flex"
        >
            <i class="fa fa-exclamation-triangle common-color-danger margin-right"></i>
            <span>
                The selected {{ displayName }} requires a fix.
                <a
                    ng-click="goToProjectIntegrationPageWithReturnTo(data.projectIntegrationPageMenuItemType.CONNECTIONS)"
                >
                    Manage data source
                </a>
                or
                <a ng-click="toggleShowDataSourcesAlternatives()">Switch {{ displayName }} data source</a>
            </span>
        </div>
    </div>

    <!-- If the data source (project integration) is inaccessible, means the workflow folder (=solution)
        does not have permissions -->
    <div
        ng-if="
            data.invalidLogics[data.configuredLogic.node.id].inAccessibleProjectIntegration ||
            data.workflowFolderProjectIntegrationsAccess.inAccessibleProjectIntegrations.includes(
                data.selectedProjectIntegration.id
            )
        "
        class="margin-bottom"
    >
        <div class="info-box margin-normal-sm flex">
            <tnk-icon class="margin-right-xs margin-top-xxxs" src="/images/icons/lock.svg"></tnk-icon>
            <span>
                This data source is inaccessible by modules under this solution (require data source admin).
                <a
                    target="_blank"
                    class="flex-vmiddle text-right"
                    ng-click="goToProjectIntegrationPageWithReturnTo(data.projectIntegrationPageMenuItemType.SOLUTIONS_ACCESS)"
                >
                    Edit accessibility
                </a>
            </span>
        </div>
    </div>

    <!-- Connect integration-->
    <div ng-show="!data.selectedProjectIntegration.valid && data.showDataSourcesAlternatives" class="margin-left">
        <!-- Systems to sync from -->
        <tnk-integration-selection
            integration-state="data.integrationState"
            integrations-settings="data.fakeIntegrationsSettings"
            select-provider="updateIntegration(projectIntegration)"
            open-modal-for-configuration="true"
            only-integrations-of-types="[data.selectedProjectIntegration.integrationType || data.configuredLogic.node.integrationType]"
            open-new-in-modal="false"
            hide-add-button="true"
            configured-in-group-id="{{ data.groupId }}"
            configured-in-workflow-version-id="{{ data.workflowVersionId }}"
        ></tnk-integration-selection>
    </div>

    <!-- No action selected -->
    <div ng-if="!data.selectedAction">
        <div
            ng-if="data.invalidLogics[data.configuredLogic.node.id].noExternalAction"
            class="common-color-danger margin-left"
        >
            {{ data.invalidLogics[data.configuredLogic.node.id].noExternalAction }}
        </div>

        <div
            ng-repeat="(actionKey, actionDefinition) in data.actionsByIntegrationsMap[data.selectedProjectIntegration.integration.integrationType || data.selectedProjectIntegration.integrationType]"
        >
            <!-- Title -->
            <div class="common-size-xxxs padding-left text-uppercase common-color-grey margin-bottom margin-top-lg">
                {{ data.actions[actionKey].title }}
            </div>
            <hr class="margin-none" />

            <div ng-switch="actionKey">
                <!-- Create new item -->
                <div ng-switch-when="createNewItem">
                    <div ng-if="actionDefinition.useEntitySelector" class="padding-normal-sm">
                        <tnk-entity-selector
                            should-not-choose-default-entity="true"
                            project-integration="data.selectedProjectIntegration"
                            on-entity-selected="selectCreateItemActionFromEntitySelector(selectedEntity)"
                        ></tnk-entity-selector>
                    </div>

                    <div
                        ng-repeat="(entity, entityDefinition) in actionDefinition"
                        ng-if="!actionDefinition.useEntitySelector"
                        class="padding-normal-sm generic-action-label"
                        ng-class="{ 'pointer common-selectable-grey mod-selectable': canActionBeSelected() }"
                        ng-click="canActionBeSelected() && selectCreateItemAction(entityDefinition)"
                    >
                        <div
                            class="common-size-xs generic-action-display-name"
                            data-automation="tnk-perform-integration-action-logic-configuration-template-action-name"
                        >
                            {{ data.actions[actionKey].title }} {{ entityDefinition.displayName }}
                        </div>
                    </div>
                </div>

                <!-- Create or update item -->
                <div ng-switch-when="createOrUpdateItem">
                    <div
                        ng-repeat="(entity, entityDefinition) in actionDefinition"
                        class="padding-normal-sm generic-action-label"
                        ng-class="{ 'pointer common-selectable-grey mod-selectable': canActionBeSelected() }"
                        ng-click="canActionBeSelected() && selectCreateOrUpdateItemAction(entityDefinition)"
                    >
                        <div
                            class="common-size-xs generic-action-display-name"
                            data-automation="tnk-perform-integration-action-logic-configuration-template-action-name"
                        >
                            {{ data.actions[actionKey].title }} {{ entityDefinition.displayName }}
                        </div>
                    </div>
                </div>

                <!-- Update field -->
                <div ng-switch-when="updateField">
                    <div ng-if="actionDefinition.useEntitySelector" class="padding-normal-sm">
                        <tnk-entity-selector
                            should-not-choose-default-entity="true"
                            project-integration="data.selectedProjectIntegration"
                            on-entity-selected="selectUpdateFieldActionFromEntitySelector(selectedEntity)"
                        ></tnk-entity-selector>
                    </div>

                    <div
                        ng-if="!actionDefinition.useEntitySelector"
                        class="padding-normal-sm generic-action-label"
                        ng-class="{ 'pointer common-selectable-grey mod-selectable': canActionBeSelected() }"
                        ng-repeat="(entity, entityDefinition) in actionDefinition"
                        ng-click="canActionBeSelected() && selectUpdateFieldAction(entityDefinition)"
                    >
                        <div
                            class="common-size-xs generic-action-display-name"
                            data-automation="tnk-perform-integration-action-logic-configuration-template-action-name"
                        >
                            {{ data.actions[actionKey].title }} {{ entityDefinition.displayName }}
                        </div>
                    </div>
                </div>

                <!-- Custom Actions -->
                <div ng-switch-when="customActions">
                    <div
                        class="padding-normal-sm generic-action-label"
                        ng-repeat="(customActionKey, actionDefinition) in actionDefinition"
                        ng-class="{
                            'pointer common-selectable-grey mod-selectable':
                                !actionDefinition.canPerformOnVariousEntities && canActionBeSelected()
                        }"
                        ng-hide="
                            actionDefinition.hide ||
                            (customActionKey === 'EXTRACT_TEXT_FROM_DOCUMENT' && !data.isShowExtractTextFromFileAction) ||
                            (customActionKey === 'GET_FILE_CONTENT' && !data.isShowGetFileContentAction)
                        "
                        ng-click="!actionDefinition.canPerformOnVariousEntities && canActionBeSelected() && selectCustomAction(actionDefinition, customActionKey)"
                    >
                        <div
                            class="generic-action-display-name common-size-xs"
                            data-automation="tnk-perform-integration-action-logic-configuration-template-action-name"
                        >
                            {{ actionDefinition.displayName }}
                        </div>

                        <div
                            class="generic-action-entity-selector margin-top-xs"
                            ng-if="actionDefinition.canPerformOnVariousEntities"
                        >
                            <tnk-entity-selector
                                project-integration="data.selectedProjectIntegration"
                                on-entity-selected="
                                    selectCustomActionEntity(selectedEntity, actionDefinition, customActionKey)
                                "
                            ></tnk-entity-selector>
                        </div>
                    </div>
                </div>

                <!-- Default -->
                <div ng-switch-default></div>
            </div>
        </div>

        <!-- Custom API Action -->
        <div
            ng-if="
                (data.projectIntegrationActions &&
                    data.projectIntegrationActions.length > 0 &&
                    data.isGenericIntegrationActionsShown) ||
                data.isShowCustomHttpActionFlagOn
            "
        >
            <!-- Title -->
            <div class="common-size-xxxs padding-left text-uppercase common-color-grey margin-bottom margin-top-lg">
                {{ data.actions.genericIntegrationAction.title }}
            </div>

            <hr class="margin-none" />

            <div
                ng-if="
                    data.projectIntegrationActions &&
                    data.projectIntegrationActions.length &&
                    data.isGenericIntegrationActionsShown
                "
                class="generic-action-label padding-normal-sm"
                data-automation="tnk-perform-integration-action-logic-configuration-template-action-name-{{ action.displayName }}"
                ng-class="{ 'pointer common-selectable-grey mod-selectable': canActionBeSelected() }"
                ng-click="canActionBeSelected() && selectProjectIntegrationAction(action)"
                ng-repeat="action in data.projectIntegrationActions"
            >
                <div class="common-size-xs generic-action-display-name">{{ action.displayName }}</div>
            </div>

            <!-- Generic Integration Action -->
            <div
                class="generic-action-label padding-normal-sm"
                ng-if="data.isShowCustomHttpActionFlagOn"
                ng-class="{ 'pointer common-selectable-grey mod-selectable': canActionBeSelected() }"
                ng-click="canActionBeSelected() && selectedGenericIntegrationAction()"
            >
                <div class="common-size-xs generic-action-display-name">
                    {{ data.actions.genericIntegrationAction.displayName }}
                </div>
            </div>
        </div>
    </div>

    <div
        ng-if="data.invalidLogics[data.configuredLogic.node.id].integrationIsBroken"
        class="common-color-danger margin-left margin-right"
    >
        {{ data.invalidLogics[data.configuredLogic.node.id].integrationIsBroken }}
    </div>

    <!-- Action selected -->
    <div ng-if="data.selectedAction">
        <!-- Separator -->
        <hr class="margin-bottom-xs margin-top-xs" />

        <div ng-switch="data.selectedProjectIntegration.integrationType">
            <div ng-switch-when="ZENDESK">
                <tnk-zendesk-custom-action-performer-selector
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    configured-logic="data.configuredLogic"
                    preview-evaluation-source="data.previewEvaluationSource"
                    definition="data.selectedAction.definition"
                    project-integration="data.selectedProjectIntegration"
                    validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                    on-action-performer-changed="onActionPerformerChanged(actionPerformer, shouldSaveLogic)"
                ></tnk-zendesk-custom-action-performer-selector>
            </div>
        </div>
        <div ng-switch="data.selectedAction.type">
            <!-- Create new item -->
            <div ng-switch-when="CREATE_NEW_ITEM">
                <div
                    ng-if="data.invalidLogics[data.configuredLogic.node.id].requiredFields"
                    class="common-color-danger margin-left"
                >
                    {{ data.invalidLogics[data.configuredLogic.node.id].requiredFields }}
                </div>

                <!-- Table Header -->
                <div class="padding-normal-sm">
                    <div class="flex flex-vmiddle">
                        <!-- Person selection title -->
                        <div class="flex-grow common-title-xxs">
                            What fields to create?
                            <i
                                ng-if="data.selectedAction.definition.entity.description"
                                class="fa fa-info-circle margin-left-xs"
                                uib-tooltip="{{ data.selectedAction.definition.entity.description }}"
                            ></i>
                        </div>
                    </div>
                </div>

                <div class="common-border-top-dark-softer">
                    <div
                        ng-repeat="dynamicFieldCreation in data.dynamicFields"
                        class="perform-integration-action-fields-container common-border-bottom-dark-softer flex"
                    >
                        <div class="padding-normal-xs col-sm-11">
                            <!-- Field selection - mandatory -->
                            <div class="padding-normal-xs flex" ng-if="dynamicFieldCreation.mandatoryField">
                                <span class="common-color-danger">*</span>
                                {{ dynamicFieldCreation.selectedField.label }}:
                                <i
                                    ng-if="dynamicFieldCreation.mandatoryField.description"
                                    class="fa fa-info-circle margin-left-xs flex-vmiddle"
                                    uib-tooltip="{{ dynamicFieldCreation.mandatoryField.description }}"
                                ></i>
                            </div>

                            <!-- Field selection -->
                            <div
                                class="padding-normal-xs flex"
                                ng-if="dynamicFieldCreation.selectedField.label && !dynamicFieldCreation.mandatoryField"
                            >
                                {{ dynamicFieldCreation.selectedField.label }}:
                                <i
                                    ng-if="dynamicFieldCreation.selectedField.description"
                                    class="fa fa-info-circle margin-left-xs flex-vmiddle"
                                    uib-tooltip="{{ dynamicFieldCreation.selectedField.description }}"
                                ></i>
                            </div>

                            <div
                                class="padding-left-xs margin-bottom-xs margin-top"
                                ng-if="
                                    !dynamicFieldCreation.mandatoryField && !dynamicFieldCreation.selectedField.label
                                "
                            >
                                <!-- Field selection - not mandatory -->
                                <tnk-field-selector
                                    items-source="EXTERNAL"
                                    selected-field="dynamicFieldCreation.selectedField"
                                    project-integration="data.selectedProjectIntegration"
                                    selected-field-identifier="$index"
                                    on-field-selected="
                                        onDynamicInputFieldSelected(selectedField, selectedFieldIdentifier)
                                    "
                                    external-type="data.selectedAction.definition.entity.pluralLabel"
                                    place-holder="Select field..."
                                    field-inspect-modal="true"
                                    field-inspect-filter="fieldInspectFilter"
                                    field-inspect-auto-open="true"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    view-only="!data.selectedProjectIntegration.valid"
                                    tooltip-value="data.selectedProjectIntegration.valid ? undefined :'To select a field, you must first select a Data Source connection'"
                                ></tnk-field-selector>
                            </div>

                            <!-- Input selection -->
                            <div class="padding-left-xs">
                                <tnk-tonkean-expression
                                    group-id="{{ data.groupId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    expression-unique-identifier="{{ $index }}"
                                    saved-original-expression="dynamicFieldCreation.valueInputConfiguration.originalExpression"
                                    saved-evaluated-expression="dynamicFieldCreation.valueInputConfiguration.evaluatedExpression"
                                    saved-is-strip-html-disabled="dynamicFieldCreation.valueInputConfiguration.isStripHtmlDisabled"
                                    placeholder="Enter value..."
                                    additional-tabs="dynamicFieldCreation.definition.valuesAdditionalTabs"
                                    param-for-tabs="data.selectedProjectIntegration"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    on-tonkean-expression-changed="
                                        onTonkeanExpressionChanged(
                                            originalExpression,
                                            evaluatedExpression,
                                            expressionIdentifier,
                                            shouldSaveLogic,
                                            isStripHtmlDisabled
                                        )
                                    "
                                    show-toggle-strip-html="true"
                                ></tnk-tonkean-expression>
                            </div>
                        </div>

                        <!-- Remove button -->
                        <div class="margin-top-xs margin-left-xs">
                            <button
                                class="btn btn-no-style svg-icon-xs common-color-grey"
                                data-ng-disabled="dynamicFieldCreation.mandatoryField"
                                ng-click="removeDynamicField($index)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Add new dynamic field -->
                <div ng-if="data.isDynamicFieldsAllowed" class="margin-top margin-bottom-lg padding-left">
                    <a ng-click="addNewDynamicFieldCreation()">+ Add</a>
                </div>
            </div>

            <!-- Create or update item -->
            <div ng-switch-when="CREATE_OR_UPDATE_ITEM">
                <div
                    ng-if="data.invalidLogics[data.configuredLogic.node.id].requiredFields"
                    class="common-color-danger margin-left"
                >
                    {{ data.invalidLogics[data.configuredLogic.node.id].requiredFields }}
                </div>

                <!-- Table Header -->
                <div class="padding-normal-sm">
                    <div class="flex flex-vmiddle">
                        <!-- Person selection title -->
                        <div class="flex-grow common-title-xxs">What fields to create/update?</div>
                    </div>
                </div>

                <table class="table table-striped">
                    <tr ng-repeat="dynamicFieldCreation in data.dynamicFields">
                        <td class="padding-normal-xs col-sm-10">
                            <!-- Field selection - mandatory -->
                            <div class="padding-normal-xs flex" ng-if="dynamicFieldCreation.mandatoryField">
                                <span class="common-color-danger">*</span>
                                {{ dynamicFieldCreation.selectedField.label }}:
                                <i
                                    ng-if="dynamicFieldCreation.mandatoryField.description"
                                    class="fa fa-info-circle margin-left-xs flex-vmiddle"
                                    uib-tooltip="{{ dynamicFieldCreation.mandatoryField.description }}"
                                ></i>
                            </div>

                            <!-- Field selection -->
                            <div
                                class="padding-normal-xs flex"
                                ng-if="dynamicFieldCreation.selectedField.label && !dynamicFieldCreation.mandatoryField"
                            >
                                {{ dynamicFieldCreation.selectedField.label }}:
                                <i
                                    ng-if="dynamicFieldCreation.selectedField.description"
                                    class="fa fa-info-circle margin-left-xs flex-vmiddle"
                                    uib-tooltip="{{ dynamicFieldCreation.selectedField.description }}"
                                ></i>
                            </div>

                            <div
                                class="padding-left-xs margin-bottom-xs margin-top"
                                ng-if="
                                    !dynamicFieldCreation.mandatoryField && !dynamicFieldCreation.selectedField.label
                                "
                            >
                                <!-- Field selection - not mandatory -->
                                <tnk-field-selector
                                    items-source="EXTERNAL"
                                    selected-field="dynamicFieldCreation.selectedField"
                                    project-integration="data.selectedProjectIntegration"
                                    selected-field-identifier="$index"
                                    on-field-selected="
                                        onDynamicInputFieldSelected(selectedField, selectedFieldIdentifier)
                                    "
                                    external-type="data.selectedAction.definition.entity.pluralLabel"
                                    place-holder="Select field..."
                                    field-inspect-modal="true"
                                    field-inspect-filter="fieldInspectFilter"
                                    field-inspect-auto-open="true"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    view-only="!data.selectedProjectIntegration.valid"
                                    tooltip-value="data.selectedProjectIntegration.valid ? undefined :'To select a field, you must first select a Data Source connection'"
                                ></tnk-field-selector>
                            </div>

                            <!-- Input selection -->
                            <div class="padding-left-xs margin-bottom-xs">
                                <tnk-tonkean-expression
                                    group-id="{{ data.groupId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    expression-unique-identifier="{{ $index }}"
                                    saved-original-expression="dynamicFieldCreation.valueInputConfiguration.originalExpression"
                                    saved-evaluated-expression="dynamicFieldCreation.valueInputConfiguration.evaluatedExpression"
                                    saved-is-strip-html-disabled="dynamicFieldCreation.valueInputConfiguration.isStripHtmlDisabled"
                                    placeholder="Enter value..."
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    on-tonkean-expression-changed="
                                        onTonkeanExpressionChanged(
                                            originalExpression,
                                            evaluatedExpression,
                                            expressionIdentifier,
                                            shouldSaveLogic,
                                            isStripHtmlDisabled
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>
                        </td>

                        <!-- Remove button -->
                        <td class="col-sm-1">
                            <button
                                class="btn btn-no-style svg-icon-xs common-color-grey"
                                data-ng-disabled="dynamicFieldCreation.mandatoryField"
                                ng-click="removeDynamicField($index)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </td>
                    </tr>
                </table>

                <!-- Add new dynamic field -->
                <div class="margin-top margin-bottom-lg padding-left">
                    <a ng-click="addNewDynamicFieldCreation()">+ Add</a>
                </div>
            </div>

            <!-- Update fields -->
            <div ng-switch-when="MANUAL_FIELD_UPDATE">
                <!-- Match option custom title -->
                <div
                    ng-if="data.selectedAction.definition.entity.customExtendedMatchConfigurationTitle"
                    class="margin-left margin-top-lg common-size-xxs margin-bottom"
                >
                    <span class="margin-right common-bold">
                        {{ data.selectedAction.definition.entity.customExtendedMatchConfigurationTitle }}
                    </span>
                </div>

                <!-- Match option generic title -->
                <div
                    ng-if="!data.selectedAction.definition.entity.customExtendedMatchConfigurationTitle"
                    class="margin-left margin-top-lg common-size-xxs margin-bottom"
                >
                    <span class="margin-right common-bold">
                        Which {{ data.selectedAction.definition.entity.displayName }} to update?
                    </span>
                </div>

                <!-- Match option settings -->
                <div class="padding-left common-size-xxs margin-bottom-lg margin-right">
                    <!-- Match option selection -->
                    <tnk-match-option-selection
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="data.configuredLogic.node.id"
                        project-integration="data.selectedProjectIntegration"
                        preview-evaluation-source="data.previewEvaluationSource"
                        entity-metadata="data.selectedAction.definition.entity"
                        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                        vertical-mod="true"
                        saved-match-option-selection="data.savedCustomTriggerAction.customTriggerActionDefinition.matchConfiguration"
                        on-match-option-selection-changed="onMatchOptionSelectionChanged(selectionObject, shouldSave)"
                    ></tnk-match-option-selection>
                </div>

                <!-- Separator -->
                <hr class="margin-bottom-xs margin-top-xs" />

                <!-- Table Header -->
                <div class="padding-normal-sm">
                    <div class="flex flex-vmiddle">
                        <!-- Person selection title -->
                        <div class="flex-grow common-title-xxs">What information to update?</div>
                    </div>
                </div>

                <table class="table table-striped table-fixed">
                    <tr ng-repeat="dynamicFieldCreation in data.dynamicFields">
                        <td class="padding-normal-xs col-sm-10">
                            <!-- Already created field definition -->
                            <div ng-if="dynamicFieldCreation.existingFieldDefinition" class="padding-normal-xs">
                                {{ dynamicFieldCreation.existingFieldDefinition.definition['FieldLabel'] }}:
                            </div>

                            <!-- Field selection - not mandatory -->
                            <div
                                ng-if="!dynamicFieldCreation.existingFieldDefinition"
                                class="padding-left-xs margin-bottom-xs margin-top"
                            >
                                <tnk-field-selector
                                    selected-field="dynamicFieldCreation.selectedField"
                                    project-integration="data.selectedProjectIntegration"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    items-source="COLUMN"
                                    selected-field-identifier="$index"
                                    on-field-selected="
                                        onDynamicInputFieldSelected(selectedField, selectedFieldIdentifier)
                                    "
                                    external-type="data.selectedAction.definition.entity.pluralLabel"
                                    field-inspect-filter="fieldInspectFilter"
                                    place-holder="Select field..."
                                    field-inspect-modal="true"
                                    only-updatable-field-definitions="!!data.selectedProjectIntegration.updateable"
                                    field-inspect-auto-open="true"
                                    view-only="!data.selectedProjectIntegration.valid"
                                    tooltip-value="data.selectedProjectIntegration.valid ? undefined :'To select a field, you must first select a Data Source connection'"
                                ></tnk-field-selector>
                            </div>

                            <div class="padding-left-xs margin-bottom-xs">
                                <!-- Input selection -->
                                <tnk-tonkean-expression
                                    group-id="{{ data.groupId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    expression-unique-identifier="{{ $index }}"
                                    saved-original-expression="dynamicFieldCreation.valueInputConfiguration.originalExpression"
                                    saved-evaluated-expression="dynamicFieldCreation.valueInputConfiguration.evaluatedExpression"
                                    saved-is-strip-html-disabled="dynamicFieldCreation.valueInputConfiguration.isStripHtmlDisabled"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    placeholder="Enter value..."
                                    on-tonkean-expression-changed="
                                        onTonkeanExpressionChanged(
                                            originalExpression,
                                            evaluatedExpression,
                                            expressionIdentifier,
                                            shouldSaveLogic,
                                            isStripHtmlDisabled
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>
                        </td>

                        <!-- Remove button -->
                        <td class="col-sm-1">
                            <button
                                class="btn btn-no-style svg-icon-xs common-color-grey"
                                data-ng-disabled="dynamicFieldCreation.mandatoryField"
                                ng-click="removeDynamicField($index)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </td>
                    </tr>
                </table>

                <!-- Validation Error Messages -->
                <div class="common-color-danger margin-left">
                    <span
                        class="flex margin-bottom"
                        ng-repeat="validationMessage in data.invalidLogics[data.configuredLogic.node.id].fieldsValidation"
                    >
                        {{ validationMessage }}
                        <br />
                    </span>
                </div>

                <!-- Add new dynamic field -->
                <div class="margin-top margin-bottom-lg padding-left">
                    <a ng-click="addNewDynamicFieldCreation()">+ Add</a>
                </div>
            </div>

            <!-- Custom Actions -->
            <div ng-switch-when="CUSTOM_ACTIONS">
                <div
                    ng-if="
                        !data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()]
                            .doesNotRequireEntity &&
                        !data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()]
                            .customActions[data.selectedAction.definition.customActionKey].doesNotRequireEntity
                    "
                >
                    <!-- Match option custom title -->
                    <div
                        ng-if="
                            data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()]
                                .customActions[data.selectedAction.definition.customActionKey]
                                .customExtendedMatchConfigurationTitle
                        "
                        class="margin-left margin-top-lg common-size-xxs margin-bottom"
                    >
                        <span class="margin-right common-bold">
                            {{
                                data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].customActions[data.selectedAction.definition.customActionKey]
                                    .customExtendedMatchConfigurationTitle
                            }}
                        </span>
                    </div>

                    <!-- Match option generic title -->
                    <div
                        ng-if="
                            !data.actionsByIntegrationsMap[
                                data.selectedProjectIntegration.integrationType.toUpperCase()
                            ].customActions[data.selectedAction.definition.customActionKey]
                                .customExtendedMatchConfigurationTitle
                        "
                        class="margin-left margin-top-lg common-size-xxs margin-bottom"
                    >
                        <!-- Match question from config if exists -->
                        <span
                            ng-if="
                                data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].customActions[data.selectedAction.definition.customActionKey].itemMatchQuestion
                            "
                            class="margin-right common-bold"
                        >
                            {{
                                data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].customActions[data.selectedAction.definition.customActionKey].itemMatchQuestion
                            }}
                        </span>

                        <!-- Generic question -->
                        <span
                            ng-if="
                                !data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].customActions[data.selectedAction.definition.customActionKey].itemMatchQuestion
                            "
                            class="margin-right common-bold"
                        >
                            Which
                            {{
                                data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].customActions[data.selectedAction.definition.customActionKey].entity.displayName ||
                                    'entity'
                            }}
                            to update?
                        </span>
                    </div>

                    <!-- Match option settings -->
                    <div class="padding-left common-size-xxs margin-bottom-lg margin-right">
                        <!-- Match option selection -->
                        <tnk-match-option-selection
                            ng-if="
                                !data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].useExtendedMatchConfiguration &&
                                !data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].customActions[data.selectedAction.definition.customActionKey]
                                    .useExtendedMatchConfiguration
                            "
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="data.configuredLogic.node.id"
                            preview-evaluation-source="data.previewEvaluationSource"
                            project-integration="data.selectedProjectIntegration"
                            entity-metadata="data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()].customActions[data.selectedAction.definition.customActionKey].entity"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            saved-match-option-selection="data.savedCustomTriggerAction.customTriggerActionDefinition.matchConfiguration"
                            on-match-option-selection-changed="
                                onMatchOptionSelectionChanged(selectionObject, shouldSave)
                            "
                            mod-fixed-width="true"
                            vertical-mod="true"
                            tabs-field-selector-mod-narrow="true"
                        ></tnk-match-option-selection>
                        <!-- Extended match configuration -->
                        <div
                            ng-if="
                                data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].useExtendedMatchConfiguration ||
                                data.actionsByIntegrationsMap[
                                    data.selectedProjectIntegration.integrationType.toUpperCase()
                                ].customActions[data.selectedAction.definition.customActionKey]
                                    .useExtendedMatchConfiguration
                            "
                        >
                            <tnk-extended-match-configuration
                                project-integration="data.selectedProjectIntegration"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                entity-metadata="data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()].customActions[data.selectedAction.definition.customActionKey].entity"
                                entities-metadata="data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()].customActions[data.selectedAction.definition.customActionKey].entities"
                                validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                                preview-evaluation-source="data.previewEvaluationSource"
                                existing-extended-match-configuration="data.savedCustomTriggerAction.customTriggerActionDefinition.extendedMatchConfiguration"
                                on-extended-match-configuration-changed="
                                    onExtendedMatchConfigurationChanged(extendedMatchConfiguration, shouldSaveLogic)
                                "
                                mod-fixed-width="true"
                                tabs-field-selector-mod-narrow="true"
                                current-custom-trigger="data.configuredLogic.node.id"
                            ></tnk-extended-match-configuration>
                        </div>
                    </div>
                    <hr />
                </div>

                <!-- Custom actions (that are not http custom actions) -->
                <div
                    ng-switch="data.selectedProjectIntegration.integrationType"
                    ng-if="
                        !data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()]
                            .customActions[data.selectedAction.definition.customActionKey].isCustomHttpAction
                    "
                >
                    <div ng-switch="GOOGLESHEETS">
                        <div
                            ng-if="data.invalidLogics[data.configuredLogic.node.id].appendDeleteError"
                            class="margin-top-xs margin-bottom-xs common-color-danger"
                        >
                            {{ data.invalidLogics[data.configuredLogic.node.id].appendDeleteError }}
                        </div>
                    </div>
                    <!-- INTERCOM -->
                    <div ng-switch-when="INTERCOM">
                        <tnk-intercom-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            field-definitions-to-create="data.cutsomActionFieldDefinitionToCreate"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-intercom-custom-actions-definition>
                    </div>

                    <!-- OKTA -->
                    <div ng-switch-when="OKTA">
                        <tnk-okta-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-okta-custom-actions-definition>
                    </div>

                    <!-- TWILIOCHAT -->
                    <div ng-switch-when="TWILIOCHAT">
                        <tnk-twilio-chat-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-twilio-chat-custom-actions-definition>
                    </div>

                    <!-- JIRA -->
                    <div ng-switch-when="JIRA">
                        <tnk-jira-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            definition="data.selectedAction.definition"
                            preview-evaluation-source="data.previewEvaluationSource"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSave)"
                        ></tnk-jira-custom-actions-definition>
                    </div>

                    <!-- TRELLO -->
                    <div ng-switch-when="TRELLO">
                        <tnk-trello-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-trello-custom-actions-definition>
                    </div>

                    <!-- GOOGLEDRIVE -->
                    <div ng-switch-when="GOOGLEDRIVE">
                        <tnk-google-drive-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-google-drive-custom-actions-definition>
                    </div>

                    <!-- DROPBOX -->
                    <div ng-switch-when="DROPBOX">
                        <tnk-dropbox-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-dropbox-custom-actions-definition>
                    </div>

                    <!-- ZENDESK -->
                    <div ng-switch-when="ZENDESK">
                        <tnk-zendesk-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            definition="data.selectedAction.definition"
                            preview-evaluation-source="data.previewEvaluationSource"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSave)"
                        ></tnk-zendesk-custom-actions-definition>
                    </div>

                    <!-- HUBSPOT -->
                    <div ng-switch-when="HUBSPOT">
                        <tnk-hubspot-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            definition="data.selectedAction.definition"
                            preview-evaluation-source="data.previewEvaluationSource"
                            field-definitions-to-create="data.cutsomActionFieldDefinitionToCreate"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-hubspot-custom-actions-definition>
                    </div>

                    <!-- SENDGRID -->
                    <div ng-switch-when="SENDGRID">
                        <tnk-sendgrid-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            field-definitions-to-create="data.cutsomActionFieldDefinitionToCreate"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            match-configuration-object="data.matchOptionSelectionObject"
                            on-definition-changed="onActionsChanged(shouldSaveLogic, childrenToCreate)"
                        ></tnk-sendgrid-custom-actions-definition>
                    </div>

                    <!-- GMAIL -->
                    <div ng-switch-when="GMAIL">
                        <tnk-gmail-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            field-definitions-to-create="data.cutsomActionFieldDefinitionToCreate"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            match-configuration-object="data.matchOptionSelectionObject"
                            on-definition-changed="onActionsChanged(shouldSaveLogic, childrenToCreate)"
                        ></tnk-gmail-custom-actions-definition>
                    </div>

                    <!-- GOOGLE CALENDAR -->
                    <div ng-switch-when="GOOGLE_CALENDAR">
                        <tnk-google-calendar-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-google-calendar-custom-actions-definition>
                    </div>

                    <!-- FACEBOOK ADS -->
                    <div ng-switch-when="FACEBOOKADS">
                        <tnk-facebook-ads-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-facebook-ads-custom-actions-definition>
                    </div>

                    <!-- SLACK APP -->
                    <div ng-switch-when="SLACK_APP">
                        <tnk-slack-app-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            project-integration="data.selectedProjectIntegration"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-slack-app-custom-actions-definition>
                    </div>

                    <!-- FRONTAPP -->
                    <div ng-switch-when="FRONTAPP">
                        <tnk-frontapp-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            definition="data.selectedAction.definition"
                            preview-evaluation-source="data.previewEvaluationSource"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            project-integration="data.selectedProjectIntegration"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-frontapp-custom-actions-definition>
                    </div>

                    <!-- SMARTSHEETWORKSPACE -->
                    <div ng-switch-when="SMARTSHEETWORKSPACE">
                        <tnk-smartsheet-workspace-custom-action-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            project-integration="data.selectedProjectIntegration"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-smartsheet-workspace-custom-action-definition>
                    </div>

                    <!-- OUTLOOK -->
                    <div ng-switch-when="OUTLOOK">
                        <tnk-outlook-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            field-definitions-to-create="data.cutsomActionFieldDefinitionToCreate"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            match-configuration-object="data.matchOptionSelectionObject"
                            on-definition-changed="onActionsChanged(shouldSaveLogic, childrenToCreate)"
                        ></tnk-outlook-custom-actions-definition>
                    </div>

                    <!-- Asana -->
                    <div ng-switch-when="ASANA">
                        <tnk-asana-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-asana-custom-actions-definition>
                    </div>

                    <!-- AMAZONS3 -->
                    <div ng-switch-when="AMAZONS3">
                        <tnk-amazon-s3-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            definition="data.selectedAction.definition"
                            preview-evaluation-source="data.previewEvaluationSource"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-amazon-s3-custom-actions-definition>
                    </div>

                    <!-- Twilio SMS -->
                    <div ng-switch-when="TWILIOSMS">
                        <tnk-twilio-sms-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-twilio-sms-custom-actions-definition>
                    </div>

                    <!-- DocuSign -->
                    <div ng-switch-when="DOCUSIGN">
                        <tnk-docusign-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-docusign-custom-actions-definition>
                    </div>

                    <!-- NetSuite -->
                    <div ng-switch-when="NETSUITE">
                        <tnk-netsuite-custom-actions
                            group-id="data.groupId"
                            workflow-version-id="data.workflowVersionId"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="(onActionsChanged)"
                        ></tnk-netsuite-custom-actions>
                    </div>

                    <div ng-switch-when="MAILCHIMPTRANSACTIONAL">
                        <tnk-mailchimp-transactional-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-mailchimp-transactional-custom-actions-definition>
                    </div>

                    <div ng-switch-when="MAILCHIMPMARKETING">
                        <tnk-mailchimp-marketing-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-mailchimp-marketing-custom-actions-definition>
                    </div>

                    <div ng-switch-when="SHAREPOINT">
                        <tnk-share-point-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-share-point-custom-actions-definition>
                    </div>

                    <div ng-switch-when="EXCEL365">
                        <tnk-excel-365-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-excel-365-custom-actions-definition>
                    </div>

                    <!-- SALESFORCE -->
                    <div ng-switch-when="SALESFORCE">
                        <tnk-salesforce-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-salesforce-custom-actions-definition>
                    </div>

                    <!-- SpringCM -->
                    <div ng-switch-when="SPRINGCM">
                        <tnk-springcm-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-springcm-custom-actions-definition>
                    </div>

                    <!-- SFTP -->
                    <div ng-switch-when="SFTP">
                        <tnk-sftp-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-sftp-custom-actions-definition>
                    </div>

                    <!-- GoodTime -->
                    <div ng-switch-when="GOODTIME">
                        <tnk-goodtime-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-goodtime-custom-actions-definition>
                    </div>

                    <!-- Box -->
                    <div ng-switch-when="BOX">
                        <tnk-box-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-box-custom-actions-definition>
                    </div>

                    <!-- Shopify -->
                    <div ng-switch-when="SHOPIFY">
                        <tnk-shopify-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-shopify-custom-actions-definition>
                    </div>

                    <!-- Lever -->
                    <div ng-switch-when="LEVER">
                        <tnk-lever-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-lever-custom-actions-definition>
                    </div>

                    <!-- GOOGLE CLOUD STORAGE -->
                    <div ng-switch-when="GOOGLECLOUDSTORAGE">
                        <tnk-google-cloud-storage-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-google-cloud-storage-custom-actions-definition>
                    </div>

                    <!-- SERVICENOW -->
                    <div ng-switch-when="SERVICENOW">
                        <tnk-servicenow-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-servicenow-custom-actions-definition>
                    </div>

                    <!-- UiPath -->
                    <div ng-switch-when="UIPATH">
                        <tnk-uipath-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-uipath-custom-actions-definition>
                    </div>

                    <!-- NetDocuments -->
                    <div ng-switch-when="NETDOCUMENTS">
                        <tnk-net-documents-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            definition="data.selectedAction.definition"
                            preview-evaluation-source="data.previewEvaluationSource"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-net-documents-custom-actions-definition>
                    </div>

                    <!-- AzureBlobStorage -->
                    <div ng-switch-when="AZUREBLOBSTORAGE">
                        <tnk-azure-blob-storage-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-azure-blob-storage-custom-actions-definition>
                    </div>

                    <!-- Evisort -->
                    <div ng-switch-when="EVISORT">
                        <tnk-evisort-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-evisort-custom-actions-definition>
                    </div>

                    <!-- MARKETO -->
                    <div ng-switch-when="MARKETO">
                        <tnk-marketo-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-marketo-custom-actions-definition>
                    </div>

                    <!-- GOOGLESLIDES -->
                    <div ng-switch-when="GOOGLESLIDES">
                        <tnk-google-slides-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-google-slides-custom-actions-definition>
                    </div>

                    <!-- CLOUDCONVERT -->
                    <div ng-switch-when="CLOUDCONVERT">
                        <tnk-cloud-convert-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-cloud-convert-custom-actions-definition>
                    </div>

                    <!-- Adobe Sign -->
                    <div ng-switch-when="ADOBESIGN">
                        <tnk-adobesign-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-adobesign-custom-actions-definition>
                    </div>

                    <!-- COUPA -->
                    <div ng-switch-when="COUPA">
                        <tnk-coupa-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-coupa-custom-actions-definition>
                    </div>

                    <!-- NetSuite -->
                    <div ng-switch-when="NETSUITE">
                        <tnk-netsuite-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-netsuite-custom-actions-definition>
                    </div>

                    <!-- NextCloud -->
                    <div ng-switch-when="NEXTCLOUD">
                        <tnk-next-cloud-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-next-cloud-custom-actions-definition>
                    </div>

                    <!-- Google Groups -->
                    <div ng-switch-when="GOOGLEGROUPS">
                        <tnk-google-groups-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-google-groups-custom-actions-definition>
                    </div>

                    <!-- Amazon Textract -->
                    <div ng-switch-when="AMAZONTEXTRACT">
                        <tnk-amazon-textract-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-amazon-textract-custom-actions-definition>
                    </div>

                    <!-- EMAIL WEBHOOK -->
                    <div ng-switch-when="EMAILWEBHOOK">
                        <tnk-email-webhook-custom-actions-definition
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            configured-logic="data.configuredLogic"
                            preview-evaluation-source="data.previewEvaluationSource"
                            definition="data.selectedAction.definition"
                            project-integration="data.selectedProjectIntegration"
                            validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                            on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-email-webhook-custom-actions-definition>
                    </div>

                    <!-- IRONCLAD -->
                    <div ng-switch-when="IRONCLAD">
                        <tnk-ironclad-custom-actions-definition
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                configured-logic="data.configuredLogic"
                                preview-evaluation-source="data.previewEvaluationSource"
                                definition="data.selectedAction.definition"
                                project-integration="data.selectedProjectIntegration"
                                validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                                on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-ironclad-custom-actions-definition>
                    </div>

                    <!-- OrigamiRisk -->
                    <div ng-switch-when="ORIGAMIRISK">
                        <tnk-origami-risk-custom-actions-definition
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                configured-logic="data.configuredLogic"
                                preview-evaluation-source="data.previewEvaluationSource"
                                definition="data.selectedAction.definition"
                                project-integration="data.selectedProjectIntegration"
                                validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                                on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-origami-risk-custom-actions-definition>
                    </div>

                    <!-- MicrosoftAdmin -->
                    <div ng-switch-when="MICROSOFTADMIN">
                        <tnk-microsoft-admin-custom-actions-definition
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                configured-logic="data.configuredLogic"
                                preview-evaluation-source="data.previewEvaluationSource"
                                definition="data.selectedAction.definition"
                                project-integration="data.selectedProjectIntegration"
                                validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                                on-definition-changed="onActionsChanged(shouldSaveLogic)"
                        ></tnk-microsoft-admin-custom-actions-definition>
                    </div>
                </div>

                <!-- Custom http actions -->
                <div
                    ng-if="
                        data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()]
                            .customActions[data.selectedAction.definition.customActionKey].isCustomHttpAction
                    "
                    class="margin-left margin-top"
                >
                    <tnk-fields-configuration-interface
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                        preview-evaluation-source="data.previewEvaluationSource"
                        fields-configuration="data.actionsByIntegrationsMap[data.selectedProjectIntegration.integrationType.toUpperCase()].customActions[data.selectedAction.definition.customActionKey].fieldsConfiguration"
                        existing-fields-values-definition="data.existingFieldsValuesDefinition"
                        on-fields-values-definition-changed="
                            onFieldsValuesDefinitionChanged(fieldsValuesDefinition, shouldSaveLogic)
                        "
                    ></tnk-fields-configuration-interface>
                </div>
            </div>

            <div
                ng-switch-when="GENERIC_INTEGRATION_ACTION"
                ng-if="!data.loading"
                class="padding-left padding-right padding-top padding-bottom"
            >
                <tnk-http-request-configuration
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    configured-logic="data.configuredLogic"
                    invalid-logics="data.invalidLogics"
                    is-integration-generic-action="true"
                    api-base-url="{{ data.actions.genericIntegrationAction.apiBaseUrl }}"
                    existing-definition="data.selectedAction.definition"
                    on-definition-changed="onCustomIntegrationActionChanged(definition, shouldSaveLogic)"
                    import-action-config="{{ data.importActionConfig }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                ></tnk-http-request-configuration>
            </div>
            <div
                ng-switch-when="PROJECT_INTEGRATION_ACTION"
                class="padding-left padding-right padding-top padding-bottom"
            >
                <span
                    ng-if="
                        data.selectedProjectIntegrationAction &&
                        data.selectedProjectIntegrationAction.parametersDefinition &&
                        data.selectedProjectIntegrationAction.parametersDefinition.entitiesToRunOn &&
                        data.selectedProjectIntegrationAction.parametersDefinition.entitiesToRunOn.length > 0 &&
                        data.selectedProjectIntegrationAction.actionType !== ActionType.CREATE
                    "
                >
                    <div class="common-bold margin-bottom-xs entities-to-run-on-wrapper">
                        Which
                        <span class="common-ellipsis entities-to-run-on" uib-tooltip="{{ getEntityToRunOnName() }}">
                            {{ getEntityToRunOnName() }}
                        </span>
                        would you like to operate on?
                    </div>
                    <tnk-extended-match-configuration
                        project-integration="data.selectedProjectIntegration"
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        logic-id="{{ data.configuredLogic.node.id }}"
                        entities="data.selectedProjectIntegrationAction.parametersDefinition.entitiesToRunOn"
                        entity-metadata="data.selectedAction.definition.entity"
                        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
                        preview-evaluation-source="data.previewEvaluationSource"
                        existing-extended-match-configuration="data.savedCustomTriggerAction.customTriggerActionDefinition.extendedMatchConfiguration"
                        on-extended-match-configuration-changed="
                            onExtendedMatchConfigurationChanged(extendedMatchConfiguration, shouldSaveLogic)
                        "
                        mod-fixed-width="true"
                        tabs-field-selector-mod-narrow="true"
                        current-custom-trigger="data.configuredLogic.node.id"
                    ></tnk-extended-match-configuration>

                    <hr class="margin-bottom-lg" />
                </span>

                <div
                    class="margin-top margin-bottom"
                    ng-if="
                        data.selectedProjectIntegrationAction.actionDefinition.definitionType === 'HTTP' &&
                        data.selectedProjectIntegrationAction.actionDefinition.shouldIncludeFileDownloading
                    "
                >
                    <tnk-worker-file-selection
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        custom-trigger-id="{{ data.configuredLogic.node.id }}"
                        file-source-type="{{ data.fileSourceType }}"
                        storage-integration-id="{{ data.storageIntegrationId }}"
                        storage-integration-action-id="{{ data.storageIntegrationActionId }}"
                        url-expression="data.urlExpression"
                        preview-evaluation-source="data.previewEvaluationSource"
                        should-display-delete-sign="false"
                        extended-match-configuration="data.dataStorageExtendedMatchConfiguration"
                        worker-static-asset-id="{{ data.workerStaticAssetId }}"
                        worker-static-asset-display-name="{{ data.workerStaticAssetDisplayName }}"
                        parameters-values="data.parametersValues"
                        on-worker-file-selection-changed="
                            onWorkerFileSelectionChanged(
                                fileSourceType,
                                storageIntegrationId,
                                extendedMatchConfiguration,
                                urlExpression,
                                workerStaticAssetId,
                                workerStaticAssetDisplayName,
                                parametersValues,
                                shouldSaveLogic,
                                storageIntegrationActionId
                            )
                        "
                    ></tnk-worker-file-selection>
                </div>

                <tnk-project-integration-action-logic-configuration
                    group-id="data.groupId"
                    workflow-version-id="data.workflowVersionId"
                    custom-trigger-id="data.configuredLogic.node.id"
                    preview-evaluation-source="data.previewEvaluationSource"
                    project-integration-action="data.selectedProjectIntegrationAction"
                    definition="data.selectedAction.definition"
                    on-definition-change="(onProjectIntegrationActionDefinitionChanged)"
                    does-have-field-list-params="(data.isNotEmptyFieldMetadata || data.dynamicFields.length > 0)"
                    validation-logic-error-message="data.invalidLogics[data.configuredLogic.node.id].requiredFields"
                    validation-logic-error-message-child-entity="data.invalidLogics[data.configuredLogic.node.id].childParametersRequiredFields"
                    loading="isLoading()"
                ></tnk-project-integration-action-logic-configuration>
                <div
                    ng-if="
                        data.isFieldListDynamicFieldsAllowed &&
                        data.isFieldListFeatureFlagOn &&
                        (data.isNotEmptyFieldMetadata || data.dynamicFields.length > 0) &&
                        !isLoading()
                    "
                    class="dynamic-params-title"
                >
                    Field List Parameters:
                </div>
                <div
                    ng-repeat="dynamicFieldCreation in data.dynamicFields"
                    class="perform-integration-action-fields-container common-border-bottom-dark-softer flex"
                >
                    <div class="padding-normal-xs col-sm-11">
                        <!-- Field selection - mandatory -->
                        <div class="padding-normal-xs flex" ng-if="dynamicFieldCreation.mandatoryField">
                            <span class="common-color-danger">*</span>
                            {{ dynamicFieldCreation.selectedField.label }}:
                            <i
                                ng-if="dynamicFieldCreation.mandatoryField.description"
                                class="fa fa-info-circle margin-left-xs flex-vmiddle"
                                uib-tooltip="{{ dynamicFieldCreation.mandatoryField.description }}"
                            ></i>
                        </div>

                        <!-- Field selection -->
                        <div class="padding-normal-xs flex" ng-if="dynamicFieldCreation.selectedField.name">
                            {{ dynamicFieldCreation.selectedField.name }}:
                        </div>

                        <div
                            class="padding-left-xs margin-bottom-xs margin-top"
                            ng-if="!dynamicFieldCreation.selectedField.name"
                        >
                            <!-- Field selection - not mandatory -->
                            <tnk-field-selector
                                items-source="CUSTOM"
                                selected-field="dynamicFieldCreation.selectedField"
                                project-integration="data.selectedProjectIntegration"
                                selected-field-identifier="$index"
                                on-field-selected="onDynamicInputFieldSelected(selectedField, selectedFieldIdentifier)"
                                external-type="data.selectedAction.definition.entity.pluralLabel"
                                place-holder="Select field..."
                                fields-filter="filterDynamicFields"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                view-only="!data.selectedProjectIntegration.valid"
                                tooltip-value="data.selectedProjectIntegration.valid ? undefined :'To select a field, you must first select a Data Source connection'"
                                add-custom-fields="addEntityFieldMetadataToCustomFields(fieldOptions, projectIntegration, externalType)"
                            ></tnk-field-selector>
                        </div>

                        <!-- Input selection -->
                        <div class="padding-left-xs">
                            <tnk-tonkean-expression
                                group-id="{{ data.groupId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                expression-unique-identifier="{{ $index }}"
                                saved-original-expression="dynamicFieldCreation.valueInputConfiguration.originalExpression"
                                saved-evaluated-expression="dynamicFieldCreation.valueInputConfiguration.evaluatedExpression"
                                saved-is-strip-html-disabled="dynamicFieldCreation.valueInputConfiguration.isStripHtmlDisabled"
                                placeholder="Enter value..."
                                additional-tabs="dynamicFieldCreation.definition.valuesAdditionalTabs"
                                param-for-tabs="data.selectedProjectIntegration"
                                preview-evaluation-source="data.previewEvaluationSource"
                                on-tonkean-expression-changed="
                                    onTonkeanExpressionChanged(
                                        originalExpression,
                                        evaluatedExpression,
                                        expressionIdentifier,
                                        shouldSaveLogic,
                                        isStripHtmlDisabled
                                    )
                                "
                                show-toggle-strip-html="true"
                            ></tnk-tonkean-expression>
                        </div>
                    </div>

                    <!-- Remove button -->
                    <div class="margin-top-xs margin-left-xs" ng-if="!isLoading()">
                        <button
                            class="btn btn-no-style svg-icon-xs common-color-grey"
                            data-ng-disabled="dynamicFieldCreation.mandatoryField"
                            ng-click="removeDynamicField($index)"
                        >
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Add new dynamic field -->
            <div
                ng-if="
                    data.isFieldListDynamicFieldsAllowed &&
                    data.isFieldListFeatureFlagOn &&
                    data.isNotEmptyFieldMetadata &&
                    !isLoading()
                "
                class="margin-top margin-bottom-lg padding-left"
            >
                <a ng-click="addNewDynamicFieldCreation()">+ Add</a>
            </div>
        </div>
    </div>
</div>
