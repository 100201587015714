<tnk-empty-trial-board
    ng-if="data.isInTrial && isMapEmpty(wfm.projectIdToFolderIdToFolderMap[pm.project.id])"
></tnk-empty-trial-board>
<div
    class="workers-gallery-page flex"
    ng-if="!data.isInTrial || !isMapEmpty(wfm.projectIdToFolderIdToFolderMap[pm.project.id])"
>
    <div class="workers-gallery-content">
        <!-- Workers Tab -->
        <div class="workers-tab" ng-if="!$state.params.tab">
            <tnk-solution-versions
                workflow-folder="data.sidePaneWorkflowFolder"
                on-close="(onWorkflowFolderVersionsSidePaneClose)"
                on-exit-complete="(onWorkflowFolderVersionsSidePaneExitComplete)"
            ></tnk-solution-versions>

            <!-- Title -->
            <div class="ops-modules-title common-title-xlg mod-light">Business Operations Solutions</div>
            <div class="ops-modules-description common-size-xxxs common-color-grey margin-top">
                <div>Solutions monitor, execute, and manage your most critical business processes.</div>
                <div>Each solution is made up of Tonkean modules that handle your custom workflows.</div>
                <div>
                    Please visit our
                    <a ng-href="{{ $root.knowledgeBase.baseUrl }}">Docs</a>
                    for detailed guides and tutorials.
                </div>
            </div>

            <div ng-if="!data.loadingGroups">
                <!-- Workers boxes -->
                <div>
                    <!-- New Module -->
                    <div class="add-solution-button-wrapper flex-vmiddle mod-justify-space">
                        <div>
                            <!-- New Solution Button -->
                            <div
                              ng-if="
                                    pm.projectData.maxAllowedWorkflowFolders &&
                                    pm.projectData.maxAllowedSandboxWorkflowFolders
                                "
                              uib-tooltip="You have reached your license limit and can not create a new solution."
                              tooltip-enable="data.reachedSolutionsLimit"
                              tooltip-append-to-body="true"
                            >
                                <button
                                    ng-disabled="data.reachedSolutionsLimit"
                                    ng-click="createNewWorkflowFolder()"
                                    data-automation="worker-browser-create-new-worker"
                                    class="btn new-solution-button margin-right mod-purple"
                                >
                                    + New Solution
                                </button>
                            </div>
                        </div>

                        <input
                            autofocus
                            type="input"
                            ng-model="data.searchTerm"
                            placeholder="Search by solution name..."
                            class="form-control common-width-30"
                            data-automation="workers-browser-search-module-textbox"
                            ng-change="onSearchTermChange()"
                        />
                    </div>

                    <div class="text-center relative">
                        <!-- Worker with folders -->
                        <div ng-if="!isMapEmpty(wfm.projectIdToFolderIdToFolderMap[pm.project.id])">
                            <div ng-if="!data.searchTerm.length || anyModuleSearchFilter()">
                                <div class="solution-box-grid">
                                    <div
                                        ng-repeat="(folderId, folder) in wfm.projectIdToFolderIdToFolderMap[pm.project.id]"
                                        class="workers-gallery-solution-box"
                                        ng-if="folder.isVisible && (!data.searchTerm || solutionSearchFilter(folder))"
                                    >
                                        <tnk-solution-box
                                            project-id="pm.project.id"
                                            folder-id="folderId"
                                            scrolled-to="data.scrolledToFolderId === folder.id"
                                            highlight-text="data.searchTerm"
                                        ></tnk-solution-box>
                                    </div>
                                </div>

                                <div
                                    ng-if="hasAnyInaccessibleFolders()"
                                    class="margin-top-xxxlg"
                                >
                                    <div class="text-left common-title-sm">
                                        Other Solutions at {{ pm.project.name }}
                                    </div>
                                    <div class="solution-box-grid">
                                        <div
                                            ng-repeat="(folderId, folder) in wfm.projectIdToFolderIdToFolderMap[pm.project.id]"
                                            class="workers-gallery-solution-box margin-bottom-xlg"
                                            ng-if="
                                                !folder.isVisible && (!data.searchTerm || solutionSearchFilter(folder))
                                            "
                                        >
                                            <tnk-solution-box
                                                project-id="pm.project.id"
                                                folder-id="folder.id"
                                                scrolled-to="data.scrolledToFolderId === folder.id"
                                                highlight-text="data.searchTerm"
                                            ></tnk-solution-box>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                ng-if="data.searchTerm.length && !anyModuleSearchFilter()"
                                class="workers-gallery-box-container common-title-xs padding-normal-lg"
                                data-automation="workers-browser-no-search-results-message"
                            >
                                No Solutions answer the search term
                            </div>
                        </div>

                        <!-- Worker with NO folders -->
                        <div
                            ng-if="isMapEmpty(wfm.projectIdToFolderIdToFolderMap[pm.project.id])"
                            class="text-center margin-top-lg flex-vmiddle"
                        >
                            <!-- Arrow pointing to new solution button -->
                            <div class="flex-basis-33">
                                <tnk-icon src="/images/icons/solutions-arrow.svg"></tnk-icon>
                            </div>

                            <div class="flex-basis-33">
                                <div class="svg-icon-55">
                                    <tnk-icon src="/images/icons/solutions-empty.svg"></tnk-icon>
                                </div>
                                <div class="common-size-s common-color-light-grey margin-top-30">
                                    Create your first solution & modules
                                </div>
                                <div class="common-size-xxxxs margin-top">
                                    Press the ‘New Solution’ button to create your first solution. Once a solution is
                                    created, you can create new modules.
                                </div>
                            </div>

                            <!-- helps center the icons -->
                            <div class="flex-basis-33"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div ng-if="data.loadingGroups" class="flex margin-top-xlg mod-justify-center">
                <i class="loading-large"></i>
            </div>
        </div>
    </div>

    <!-- Videos -->
    <tnk-learning-sidebar
        ng-hide="['metrics', 'history'].includes($state.params.tab)"
        category="'opsModules'"
    ></tnk-learning-sidebar>
</div>
