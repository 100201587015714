import React from 'react';
import styled from 'styled-components';

import { INITIAL_SOLUTION_BUSINESS_REPORT_GROUP_FILTERS } from './SolutionReportConsts';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { TextEllipsis } from '@tonkean/infrastructure';
import { useCreateDialog } from '@tonkean/infrastructure';
import { TrashIcon } from '@tonkean/svg';
import type { SolutionBusinessReportSavedFilter } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { colorSvg } from '@tonkean/utils';

const SavedFilterButtonsWrapper = styled.div`
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SavedFilterButton = styled(Button)`
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 0;
    max-width: 130px;
`;

const TrashFilterButton = styled(Button)`
    min-width: 0;
    padding: 0 5px;
    border-left: 1px solid ${Theme.colors.gray_300};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
        svg {
            ${colorSvg(Theme.colors.gray_700)}
        }
    }
`;

interface Props {
    solutionBusinessReportId: string;
    filter: SolutionBusinessReportSavedFilter;
    isSelectedFilter: boolean;
    onDefinitionChange: (SolutionBusinessReportGroupFilters) => void;
    onDelete: (filterId: string) => void;
}
const SolutionBusinessReportSingleSavedFilter: React.FC<Props> = ({
    solutionBusinessReportId,
    filter,
    isSelectedFilter,
    onDefinitionChange,
    onDelete,
}) => {
    const [, deleteSolutionBusinessReportFilter] = useLazyTonkeanService('deleteSolutionBusinessReportFilter');

    const { confirm } = useCreateDialog();

    const onDeleteInner = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        confirm('Are you sure?', <div>Are you sure you want to delete the filter "{filter.displayName}"</div>, {
            onOk: () => {
                return deleteSolutionBusinessReportFilter(solutionBusinessReportId, filter.id).then(() => {
                    onDelete(filter.id);
                });
            },
        });
    };

    return (
        <SavedFilterButtonsWrapper key={filter.id}>
            <SavedFilterButton
                size={ButtonSize.SMALL}
                onClick={(event) => {
                    event.stopPropagation();
                    const definition = isSelectedFilter
                        ? filter.definition
                        : INITIAL_SOLUTION_BUSINESS_REPORT_GROUP_FILTERS;
                    onDefinitionChange(definition);
                }}
                outlined={isSelectedFilter}
                flex
            >
                <TextEllipsis numberOfLines={1} tooltip>
                    {filter.displayName}
                </TextEllipsis>
            </SavedFilterButton>
            <TrashFilterButton
                onClick={(event) => onDeleteInner(event)}
                size={ButtonSize.SMALL}
                outlined={isSelectedFilter}
                flex
            >
                <TrashIcon />
            </TrashFilterButton>
        </SavedFilterButtonsWrapper>
    );
};

export default SolutionBusinessReportSingleSavedFilter;
