import React from 'react';
import styled from 'styled-components';

import type { ItemInterfaceHeaderProps } from '../../../SystemInterface/components/ItemInterface/ItemInterfaceHeader';

import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Title = styled.div`
    font-size: ${FontSize.XXXXLARGE_24};
    font-weight: 500;
    color: ${Theme.colors.gray_800};
    background: ${Theme.colors.HEX_F7F8FA};
    padding: 24px 32px 0px 0px;
`;

const DetailedItemsWidgetDrillDownHeader: React.FC<ItemInterfaceHeaderProps> = ({}) => {
    const { itemInterface } = useItemInterfaceContext();
    return <Title>{itemInterface.displayName}</Title>;
};

export default DetailedItemsWidgetDrillDownHeader;
