import React from 'react';
import styled from 'styled-components';

import type InitialFieldDefinitionValueExpression from '../entities/InitialFieldDefinitionValueExpression';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { ManualFieldSelector } from '@tonkean/fields';
import { Paragraph } from '@tonkean/infrastructure';
import { TrashIcon } from '@tonkean/svg';
import type { FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const Row = styled.div`
    display: grid;
    gap: 8px;
    align-items: top;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Header = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

interface Props {
    value: InitialFieldDefinitionValueExpression[];
    onChange: (newValue: InitialFieldDefinitionValueExpression[]) => void;
    fieldDefinitions: FieldDefinition[];

    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    isLoadingFieldDefinitions: boolean;
}

const ManualFieldWithTonkeanExpressionList: React.FC<Props> = ({
    value: allValues,
    onChange,
    groupId,
    workflowVersionId,
    isLoadingFieldDefinitions,
    fieldDefinitions,
}) => {
    return (
        <Wrapper>
            {allValues.map((value, index) => {
                return (
                    <Row key={value.id}>
                        <Header>
                            <Paragraph $bold>Value ({index + 1})</Paragraph>

                            <IconButton
                                iconSize={12}
                                onClick={() => onChange(allValues.filter((val) => val.id !== value.id))}
                                flat
                            >
                                <TrashIcon />
                            </IconButton>
                        </Header>

                        <ManualFieldSelector
                            selectedField={value.fieldDefinitionId}
                            loading={isLoadingFieldDefinitions}
                            fieldDefinitions={fieldDefinitions}
                            onChange={(newValue) => {
                                const newValues = allValues.map((val) => {
                                    return val.id === value.id ? { ...val, fieldDefinitionId: newValue } : val;
                                });

                                onChange(newValues);
                            }}
                        />
                        <TonkeanExpression
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                            savedOriginalExpression={value.expression?.originalExpression}
                            savedEvaluatedExpression={value.expression?.evaluatedExpression}
                            onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                                const newValues = allValues.map((val) => {
                                    return val.id === value.id
                                        ? {
                                              ...val,
                                              expression: { originalExpression, evaluatedExpression },
                                          }
                                        : val;
                                });

                                onChange(newValues);
                            }}
                        />
                    </Row>
                );
            })}
        </Wrapper>
    );
};

export default ManualFieldWithTonkeanExpressionList;
