import { useMemo, useState } from 'react';

import useIntakeCustomTriggersSequence from './useIntakeCustomTriggersSequence';
import type { IntakeProgressStatusInfo } from '../entities/IntakeProgressStatusInfo';
import { IntakeProgressStatusCompletionStatus } from '../entities/IntakeProgressStatusInfo';

import { useStatesForWorkflowVersionId } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

function getCompletionStatusForTriggerInSequence({
    triggerIndexInSequence,
    triggerIdInSequence,
    lastVisitedTriggerIndexWithValidState,
    triggersIdsInSessionSet,
    isInContinousSeesionMode,
}: {
    triggerIndexInSequence: number;
    triggerIdInSequence: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    lastVisitedTriggerIndexWithValidState: number;
    triggersIdsInSessionSet: Set<TonkeanId<TonkeanType.CUSTOM_TRIGGER>>;
    isInContinousSeesionMode: boolean;
}): IntakeProgressStatusCompletionStatus {
    if (lastVisitedTriggerIndexWithValidState === triggerIndexInSequence) {
        return IntakeProgressStatusCompletionStatus.CURRENT;
    }

    if (lastVisitedTriggerIndexWithValidState < triggerIndexInSequence) {
        return IntakeProgressStatusCompletionStatus.FUTURE;
    }

    if (isInContinousSeesionMode && !triggersIdsInSessionSet.has(triggerIdInSequence)) {
        return IntakeProgressStatusCompletionStatus.SKIPPED;
    }

    return IntakeProgressStatusCompletionStatus.COMPLETE;
}

export interface useIntakeStatusesSummaryProps {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    currentCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    currentFormId?: TonkeanId<TonkeanType.FORM>;
    // In continous session mode, the hook assumes current trigger ids are changed in the order of the sequence and hence supports marking skipped statuses as imcomplete.
    isInContinousSeesionMode?: boolean;
}

/*
 * This hook returns an array of statuses info, each containing the title of the status and whether it is complete or not.
 * The statuses are ordered by the order of the triggers in the sequence, according to which status the trigger move the initiative.
 */
const useIntakeStatusesSummary = ({
    workflowVersionId,
    currentCustomTriggerId,
    currentFormId,
    isInContinousSeesionMode = true,
}: useIntakeStatusesSummaryProps): IntakeProgressStatusInfo[] | undefined => {
    // We manage the triggers passed in the session. This is our way to know which triggers are complete and which are skipped (in continous session mode)
    const [triggerIdsInSession, setTriggerIdsInSeesion] = useState<TonkeanId<TonkeanType.CUSTOM_TRIGGER>[]>([]);

    if (
        isInContinousSeesionMode &&
        !!currentCustomTriggerId &&
        triggerIdsInSession[triggerIdsInSession.length - 1] !== currentCustomTriggerId
    ) {
        setTriggerIdsInSeesion([...triggerIdsInSession, currentCustomTriggerId]);
    }

    const customTriggersSequence = useIntakeCustomTriggersSequence({
        workflowVersionId,
        customTriggerIdInSequence: currentCustomTriggerId,
        currentFormId,
    });

    const { stateById } = useStatesForWorkflowVersionId({ workflowVersionId });

    return useMemo(() => {
        if (!customTriggersSequence) {
            return undefined;
        }

        const triggersIdsInSessionSet = new Set(triggerIdsInSession);
        const currentCustomTriggerIndex = currentCustomTriggerId
            ? customTriggersSequence.findIndex((trigger) => trigger.id === currentCustomTriggerId)
            : -1;

        let lastVisitedTriggerIndexWithValidState = currentCustomTriggerIndex;

        if (currentCustomTriggerIndex >= 0 && !customTriggersSequence[currentCustomTriggerIndex]?.stateId) {
            lastVisitedTriggerIndexWithValidState = customTriggersSequence
                .slice(0, currentCustomTriggerIndex)
                .findLastIndex((trigger) => !!trigger.stateId);
        }

        const statusesInfo: IntakeProgressStatusInfo[] = [];

        customTriggersSequence.forEach((triggerInSequenceInfo, triggerIndexInSequence) => {
            const triggerStateId = triggerInSequenceInfo.stateId;
            if (!triggerStateId) {
                return;
            }

            const stateInfo = stateById[triggerStateId];
            if (!stateInfo) {
                return;
            }

            const completionStatus = getCompletionStatusForTriggerInSequence({
                triggerIndexInSequence,
                triggerIdInSequence: triggerInSequenceInfo.id,
                lastVisitedTriggerIndexWithValidState,
                triggersIdsInSessionSet,
                isInContinousSeesionMode,
            });

            statusesInfo.push({
                title: stateInfo.label,
                completionStatus,
            });
        });

        return statusesInfo;
    }, [customTriggersSequence, stateById, triggerIdsInSession, currentCustomTriggerId, isInContinousSeesionMode]);
};

export default useIntakeStatusesSummary;
