<span ng-hide="state[integration.name]">No {{ integration.displayName }} project configured</span>
<span ng-show="state[integration.name]">
    <span class="list-comma mod-and" ng-show="state[integration.name].integrations[0].projectData.tags.length">
        Using tags
        <span ng-repeat="tag in state[integration.name].integrations[0].projectData.tags">
            <strong>{{ tag }}</strong>
        </span>
    </span>
    <span ng-hide="state[integration.name].integrations[0].projectData.tags.length">Connected</span>
</span>
