import { reactToAngular } from 'angulareact';

import { ProjectIntegrationActionSelector } from '@tonkean/infrastructure';

export default angular
    .module('tonkean.app')
    .component(
        'tnkProjectIntegrationActionSelector',
        reactToAngular(ProjectIntegrationActionSelector, [
            'selectedProjectIntegrationActionId',
            'projectIntegration',
            'onProjectIntegrationActionChanged',
            'actionTypes',
        ]),
    );
