<div class="margin-left margin-right">
    <div class="margin-bottom" ng-if="data.loading">
        <i class="loading-small"></i>
    </div>

    <!-- Create comment -->
    <div ng-if="data.definition.customActionKey === 'CREATE_COMMENT_ON_TICKET' && !data.loading">
        <!-- Comment -->
        <div class="margin-bottom">
            <div>
                <div class="margin-right">Comment Author:</div>

                <div ng-if="data.validationObject.noAuthor" class="margin-top-xs margin-bottom-xs common-color-danger">
                    {{ data.validationObject.noAuthor }}
                </div>

                <form ng-submit="" novalidate class="margin-top-xxs">
                    <tnk-person-selection-configuration
                        class="block margin-bottom-lg"
                        group-id="data.groupId"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        radio-id-prefix="'performer-' + data.workflowVersionId"
                        validation-object="data.validationObject"
                        preview-evaluation-source="data.previewEvaluationSource"
                        saved-person-selection-configuration="data.existingAuthorSelectionConfiguration"
                        on-person-selection-configuration-changed="
                            onPerformingAuthorSelected(personSelectionConfiguration, shouldSaveLogic)
                        "
                        configured-logic="data.configuredLogic"
                        support-quick-create="false"
                        specific-people-custom-options-refresh="refreshAuthors(q)"
                        specific-people-custom-options="data.authors"
                        unique-identifier="author"
                    ></tnk-person-selection-configuration>
                </form>
            </div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Enter comment..."
                saved-original-expression="data.definition.comment"
                saved-evaluated-expression="data.definition.evaluatedCommentExpression"
                on-tonkean-expression-changed="
                    onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div ng-if="data.validationObject.noComment" class="common-color-danger">
                {{ data.validationObject.noComment }}
            </div>
        </div>

        <!-- Is Public?-->
        <div>
            <tnk-checkbox
                label="'Send to customer?'"
                value="data.definition.isPublic"
                on-toggle-async="onIsPublicToggled()"
            ></tnk-checkbox>
        </div>
    </div>

    <!-- Add\Remove tag -->
    <div
        ng-if="
            data.definition.customActionKey === 'ADD_TAG_ON_TICKET' ||
            data.definition.customActionKey === 'REMOVE_TAG_FROM_TICKET'
        "
    >
        <!-- Title -->
        <div class="margin-bottom-xs margin-top">
            {{ data.definition.customActionKey === 'ADD_TAG_ON_TICKET' ? 'Add' : 'Remove' }} these Tags:
        </div>

        <!-- Comment -->
        <div class="margin-bottom">
            <div ng-repeat="tagObject in data.tagsObjects" class="flex-vmiddle margin-bottom">
                <tnk-tonkean-expression
                    class="flex-grow"
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    expression-unique-identifier="{{ $index }}"
                    saved-original-expression="tagObject.text"
                    saved-evaluated-expression="tagObject.evaluatedText"
                    on-tonkean-expression-changed="
                        onTonkeanExpressionChangedForTags(
                            originalExpression,
                            evaluatedExpression,
                            expressionIdentifier,
                            shouldSaveLogic
                        )
                    "
                ></tnk-tonkean-expression>

                <button
                    class="
                        svg-icon-xs
                        common-color-grey
                        svg-icon-hover-primary
                        btn btn-no-border btn-on-hover
                        pointer
                        flex-no-shrink
                        margin-left-xxs
                    "
                    ng-click="removeTagObject($index)"
                >
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </button>
            </div>

            <a ng-click="addTagObject()">+ Add</a>

            <div ng-if="data.validationObject.noTags" class="common-color-danger">
                {{ data.validationObject.noTags }}
            </div>
        </div>

        <div class="common-color-grey common-size-xxxxs">
            <div>Note: Spaces will be replaces with underscores</div>
            <div>For example: "Tag 1" will be replaced with "Tag_1"</div>
        </div>
    </div>

    <!-- Upload Attachment -->
    <div ng-if="data.definition.customActionKey === 'UPLOAD_ATTACHMENT_TO_TICKET' && !data.loading">
        <!-- Author -->
        <div>
            <div class="margin-right">Comment Author:</div>

            <div ng-if="data.validationObject.noAuthor" class="margin-top-xs margin-bottom-xs common-color-danger">
                {{ data.validationObject.noAuthor }}
            </div>

            <form ng-submit="" novalidate class="margin-top-xxs">
                <tnk-person-selection-configuration
                    class="block margin-bottom-lg"
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    radio-id-prefix="'performer-' + data.workflowVersionId"
                    validation-object="data.validationObject"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-person-selection-configuration="data.existingAuthorSelectionConfiguration"
                    on-person-selection-configuration-changed="
                        onPerformingAuthorSelected(personSelectionConfiguration, shouldSaveLogic)
                    "
                    configured-logic="data.configuredLogic"
                    support-quick-create="false"
                    specific-people-custom-options-refresh="refreshAuthors(q)"
                    specific-people-custom-options="data.authors"
                    unique-identifier="author"
                ></tnk-person-selection-configuration>
            </form>
        </div>

        <!-- Comment -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Enter comment..."
            saved-original-expression="data.definition.comment"
            saved-evaluated-expression="data.definition.evaluatedCommentExpression"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>

        <div ng-if="data.validationObject.noComment" class="common-color-danger">
            {{ data.validationObject.noComment }}
        </div>

        <!-- File -->
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            parameters-values="data.definition.parametersValues"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- Is Public?-->
        <div>
            <tnk-checkbox
                label="'Is private?'"
                value="data.definition.isPrivate"
                on-toggle-async="onIsPublicToggled()"
            ></tnk-checkbox>
        </div>
    </div>

    <!-- Add Followers -->
    <div
            ng-if="
            data.definition.customActionKey === 'ADD_FOLLOWERS_TO_TICKET'
        "
    >
        <div class="margin-bottom">
            <!-- Title -->
            <div class="margin-right margin-top-xs">
                Followers:
                <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="Comma-separated list of User ID or User Email."
                ></i>
            </div>

            <!-- Followers -->
            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Followers"
                    saved-expression="data.definition.followersExpression"
                    on-tonkean-expression-changed="onFollowersExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
