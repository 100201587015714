import template from './tnkFieldTypeIcon.template.html?angularjs';

function tnkFieldTypeIcon() {
    return {
        restrict: 'E',
        scope: {
            fieldType: '=',
            size: '@',
        },
        template,
    };
}

angular.module('tonkean.app').directive('tnkFieldTypeIcon', tnkFieldTypeIcon);
