import { ActionType } from '@tonkean/tonkean-entities';

const actionTypeToPresentTense = {
    [ActionType.CREATE]: 'Creating',
    [ActionType.UPDATE]: 'Updating',
    [ActionType.CREATE_OR_UPDATE]: 'Creating or Updating',
    [ActionType.CUSTOM]: 'Custom Action',
    [ActionType.UPLOAD]: 'Upload',
    [ActionType.DOWNLOAD]: 'Download',
    [ActionType.FETCHING]: 'Fetching',
    [ActionType.STREAM_IMAGE]: 'Stream Image',
};

export default actionTypeToPresentTense;
