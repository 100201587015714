import WorkerAuditLogs from './WorkerAuditLogs';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkWorkerAuditLogs',
        reactToAngular(
            WorkerAuditLogs,
            [
                'groupId',
                'pageSize',
                'workflowVersionId',
                'onlyActivityTypes',
                'openActivityActorPersonSelectionCallback',
                'controlObject',
                'appendFilterDialogToBody',
                'oneLineMessage',
                'loadMoreButtonSecondary',
                'tooltipDirection',
                'onCloseModal',
                'showBackToMarkForPublishButton',
                'groupByToggleDefault',
                'hideGroupByToggle',
                'shouldLoadCommitActivities',
                'targetTypeId',
                'entityVersionId',
                'subSequentialIdentifier',
                'useTotalCommitted',
            ],
            ['tonkeanService', 'projectManager', 'authenticationService', 'consts', 'tonkeanUtils', 'modalUtils'],
        ),
    );
