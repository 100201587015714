import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { ReactComponent as ClassificationIcon } from '../../../../../../images/icons/classification.svg';
import { ReactComponent as TextExtractionIcon } from '../../../../../../images/icons/text-extraction.svg';
import { ReactComponent as TrainerIcon } from '../../../../../../images/icons/trainer.svg';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Field,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalHeaderActionButtons,
    SimpleSelect,
    Textarea,
} from '@tonkean/infrastructure';
import { ProjectIntegrationPageMenuItemType, TrainingSetType, trainingSetTypeToLabel } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { yupEnum } from '@tonkean/utils';

const StyledModalHeader = styled(ModalHeader)`
    display: flex;
    align-items: center;
`;

const StyledTrainingSetIcon = styled(TrainerIcon)`
    margin-right: 10px;
`;

const StyledField = styled(Field)`
    margin-bottom: 30px;
`;

const StyledIcon = styled.svg`
    margin-right: 6px;
    width: 14px;
    height: 14px;
`;

const textExtractorTrainingSetOption = [
    {
        label: 'Text Extractor',
        value: TrainingSetType.TEXT_EXTRACTOR,
        icon: <StyledIcon as={TextExtractionIcon} />,
    },
];

const trainingSetTypeOptions = Object.entries(trainingSetTypeToLabel).map(
    ([type, label]: [TrainingSetType, string]) => {
        return {
            value: type,
            label,
            icon: <StyledIcon as={type === TrainingSetType.TEXT_EXTRACTOR ? TextExtractionIcon : ClassificationIcon} />,
        };
    },
);

const NewTrainingSetSchema = Yup.object({
    displayName: Yup.string().required('Training Set must have a name'),
    type: yupEnum(TrainingSetType).required(),
    description: Yup.string(),
});

type NewTrainingSet = Yup.InferType<typeof NewTrainingSetSchema>;

interface Props {
    projectId: string;
}

const NewTrainingSetModal: React.FC<Props> = ({ projectId }) => {
    const [{ error: createTrainingSetError }, createTrainingSet] = useLazyTonkeanService('createTrainingSet');
    const $state = useAngularService('$state');

    const handleSaving = (values: NewTrainingSet) => {
        return createTrainingSet(projectId, values.displayName, values.type, values.description).then(
            ({ trainingSetId }) =>
                $state.go('product.trainingSetPage', {
                    page: ProjectIntegrationPageMenuItemType.OVERVIEW,
                    enterpriseComponentId: trainingSetId,
                }),
        );
    };

    return (
        <Formik
            initialValues={{
                type: TrainingSetType.TEXT_EXTRACTOR,
                displayName: '',
                description: '',
            }}
            onSubmit={(values) => handleSaving(values)}
            validationSchema={NewTrainingSetSchema}
        >
            <ModalForm>
                <StyledModalHeader $border>
                    <ModalHeaderActionButtons />
                    <StyledTrainingSetIcon />
                    <span>Create new training set</span>
                </StyledModalHeader>
                <ModalBody>
                    <StyledField label="Training type">
                        <SimpleSelect options={trainingSetTypeOptions} menuPosition="fixed" name="type" />
                    </StyledField>
                    <StyledField label="Name">
                        <Input size={InputSize.LARGE} name="displayName" placeholder="Type name" />
                    </StyledField>
                    <StyledField label="Description" showOptional>
                        <Textarea
                            placeholder="Insert description..."
                            minRows={3}
                            size={InputSize.LARGE}
                            name="description"
                        />
                    </StyledField>
                </ModalBody>
                <ModalFooterActions error={createTrainingSetError} saveLabel="Create" />
            </ModalForm>
        </Formik>
    );
};

export default NewTrainingSetModal;
