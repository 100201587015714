import { analyticsWrapper } from '@tonkean/analytics';

function OnBoardingContinuousModalCtrl($scope, onBoardingManager, modal, onDismissed) {
    $scope.obm = onBoardingManager;

    $scope.stepClicked = function (stepName) {
        if (stepName) {
            switch (stepName) {
                case 'invite':
                    analyticsWrapper.track('Step clicked', {
                        category: 'OnBoardingContinuousModal',
                        label: onBoardingManager.metadata.currentStep.name,
                    });
                    modal.openInvite();
                    break;
            }
        }
    };

    $scope.close = function () {
        $scope.$close();
    };

    $scope.dismissOnBoarding = function () {
        analyticsWrapper.track('On boarding dismissed', { category: 'OnBoardingContinuousModal' });

        // Call the onDismissed callback we we're provided with.
        if (onDismissed && typeof onDismissed === 'function') {
            onDismissed();
        }

        // Close the modal.
        $scope.close();
    };
}

export default angular.module('tonkean.app').controller('OnBoardingContinuousModalCtrl', OnBoardingContinuousModalCtrl);
