import type { IRootScopeService } from 'angular';
import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import type { VersionsListsFilter } from './VersionsListsFilter';
import WorkflowVersionInfo from './WorkflowVersionInfo';
import WorkflowVersionLoadingInfo from './WorkflowVersionLoadingInfo';
import WorkflowVersionsPaginatedList from './WorkflowVersionsPaginatedList';
import { ReactComponent as VIcon } from '../../../../images/icons/v.svg';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    KebabMenuButton,
    LIMIT_PARAM,
    Menu,
    MenuItem,
    SKIP_PARAM,
    TonkeanAvatar as TrackAvatar,
    Tooltip,
    useCurrentlyAuthenticatedUser,
    useFetchManager,
    useToggle,
} from '@tonkean/infrastructure';
import type { WorkflowVersion } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { classNames } from '@tonkean/utils';

const PAGE_SIZE = 20;

const WorkflowVersionSummary = styled.div`
    position: relative;
    .move-to-version-btn {
        position: absolute;
        bottom: 0;
        display: none;
    }

    &:hover {
        .move-to-version-btn {
            display: inline-block;
        }
    }
`;

const StyledKebabMenuItem = styled(KebabMenuButton)`
    margin-left: auto;
    width: 30px;
`;

const StyledMenuItem = styled(MenuItem)`
    font-size: 12px;
`;

interface Props {
    groupId: string;
    draftVersionChanges: number;
    productionWorkflowVersionId: string;
    setWorkflowVersionsCache(workflowVersions: WorkflowVersion[]): void;
    onWorkflowVersionSelected(workflowVersionId: string, changeOnlyHistoryVersion: boolean): void;
    onTestWorkflowClicked: (error, invalid: string) => void;
    onRevertWorkflowVersionClicked: () => void;
}

const WorkflowVersionsList: React.FC<Props> = ({
    groupId,
    onWorkflowVersionSelected,
    draftVersionChanges,
    setWorkflowVersionsCache,
    onTestWorkflowClicked,
    onRevertWorkflowVersionClicked,
}) => {
    const authenticationService = useAngularService('authenticationService');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const modalUtils = useAngularService('modalUtils');
    const customTriggerManager = useAngularService('customTriggerManager');
    const projectManager = useAngularService('projectManager');
    const $rootScope = useAngularService('$rootScope') as IRootScopeService & Record<any, any>;
    const tonkeanService = useAngularService('tonkeanService');

    const draftWorkflowVersionId = useMemo(() => {
        const draftWorkflowVersionId = workflowVersionManager.groupIdToDraftWorkflowVersionIdMap[groupId];

        if (!draftWorkflowVersionId) {
            throw new Error(`No draft version id of group id ${groupId} in cache`);
        }

        return draftWorkflowVersionId;
    }, [groupId, workflowVersionManager.groupIdToDraftWorkflowVersionIdMap]);

    const [draftWorkflowVersion] = useAngularWatch(() => {
        const draftVersionFromCache =
            workflowVersionManager.workflowVersionIdToWorkflowVersionMap[draftWorkflowVersionId];

        if (!draftVersionFromCache) {
            throw new Error(`No draft version of group id ${groupId} in cache`);
        }

        return draftVersionFromCache;
    });

    const [isPublishReady] = useAngularWatch(() => {
        return draftWorkflowVersion?.isPublishReady ?? false;
    });

    const [filters, setFilters] = useState<VersionsListsFilter>({
        searchTerm: undefined,
        from: undefined,
        to: undefined,
        publisher: undefined,
        sequentialIdentifier: undefined,
    });

    const [{ data: peopleWhoMadeChangesResponse }, getPeopleWhoMadeChangesInWorkflowVersion] = useLazyTonkeanService(
        'getPeopleWhoMadeChangesInWorkflowVersion',
    );

    const [[getGroupWorkflowVersions, cancelFetcher], { data: workflowVersions, hasMorePages, loadNextPage, loading }] =
        useFetchManager(tonkeanService, 'getGroupWorkflowVersions', { limit: PAGE_SIZE });

    const [currentWorkflowVersionChanges] = useAngularWatch(() => {
        return workflowVersionManager.workflowVersionIdToChangesCounterMap[draftWorkflowVersionId];
    });

    const [lastWorkflowVersionId] = useAngularWatch(() => {
        return workflowVersionManager.lastWorkflowVersionIdMap[groupId];
    });

    useEffect(() => {
        const publisherPersonIds = filters.publisher?.id ? [filters.publisher?.id] : undefined;
        const sequentialIdentifiers = filters.sequentialIdentifier?.toString().length
            ? [filters.sequentialIdentifier]
            : undefined;

        getGroupWorkflowVersions(
            groupId,
            SKIP_PARAM,
            LIMIT_PARAM,
            undefined,
            'DRAFT',
            undefined,
            filters.searchTerm || undefined,
            filters.from?.getTime(),
            filters.to?.getTime(),
            publisherPersonIds,
            sequentialIdentifiers,
            true,
        );

        return () => cancelFetcher();
    }, [
        cancelFetcher,
        filters.from,
        filters.publisher?.id,
        filters.searchTerm,
        filters.sequentialIdentifier,
        filters.to,
        getGroupWorkflowVersions,
        groupId,
        lastWorkflowVersionId,
    ]);

    /**
     * Update cache with the current list of workflow versions.
     */
    useEffect(() => {
        setWorkflowVersionsCache(workflowVersions);
    }, [setWorkflowVersionsCache, workflowVersions]);

    /**
     * Fetch people who made changes to the draft workflow version.
     */
    useEffect(() => {
        getPeopleWhoMadeChangesInWorkflowVersion(draftWorkflowVersion.id);
    }, [draftWorkflowVersion.id, getPeopleWhoMadeChangesInWorkflowVersion]);

    // If we have a clean build environment, and a user makes a change, we would like to add his avatar to the displayed
    // avatars, without issuing another API call. So, we listen to the change of the changes counter
    // and if we have yet to initialize the avatars, we just add the user avatar to the list.
    const currentUser = useCurrentlyAuthenticatedUser();
    const peopleWhoMadeChangesToDraft = useMemo(() => {
        return !peopleWhoMadeChangesResponse?.length ? [currentUser] : peopleWhoMadeChangesResponse;
    }, [currentUser, peopleWhoMadeChangesResponse]);

    const [open, toggleOpen] = useToggle(false);
    const [showCreateVersionError, setShowCreateVersionError] = useState<boolean>();
    const [workflowVersionChanges, setWorkflowVersionChanges] = useState<number>();

    useEffect(() => {
        if (workflowVersionChanges !== currentWorkflowVersionChanges) {
            setWorkflowVersionChanges(currentWorkflowVersionChanges);
            setShowCreateVersionError(false);
        }
    }, [currentWorkflowVersionChanges, workflowVersionChanges]);

    const commitWorkflowVersion = () => {
        customTriggerManager
            .validateCustomTriggers(draftWorkflowVersion.id, projectManager.groupsMap[groupId], projectManager.project)
            .then(() => {
                if (onTestWorkflowClicked) {
                    onTestWorkflowClicked({}, 'VALID');
                }

                modalUtils.openWorkflowVersionActionModal(groupId, undefined, 'isCreateVersion', undefined);
            })
            .catch((error) => {
                if (onTestWorkflowClicked) {
                    onTestWorkflowClicked(error, 'INVALID');
                }
                setShowCreateVersionError(true);
                toggleOpen();
                return Promise.reject(error);
            });
    };

    const discardPendingChanges = () => {
        if (currentWorkflowVersionChanges === 0) {
            return;
        }

        modalUtils.openRevertDraftVersionModal(groupId)?.then((successfulRevert) => {
            if (successfulRevert) {
                if (onRevertWorkflowVersionClicked) {
                    onRevertWorkflowVersionClicked();
                }
            } else {
                return Promise.resolve();
            }
        });
    };

    const draftComment = isPublishReady && draftWorkflowVersion.comment;
    return (
        <>
            {draftVersionChanges !== undefined && (
                <Clickable
                    className="workflow-versions-draft inline-button"
                    onClick={() => onWorkflowVersionSelected(draftWorkflowVersion.id, true)}
                    buttonAsDiv
                >
                    <header>
                        <strong className="workflow-versions-draft-title">Build version</strong>
                        {isPublishReady && (
                            <div className="workflow-versions-draft-ready-to-publish svg-fix-size">
                                <span className="tnk-icon">
                                    <VIcon />
                                </span>
                                <div>Marked for publish</div>
                            </div>
                        )}
                        {!isPublishReady && (
                            <span
                                className={classNames(
                                    'workflow-versions-draft-changes-count',
                                    isPublishReady && 'mod-ready-to-publish',
                                )}
                            >
                                {isPublishReady ? 'Changes' : 'Pending Changes'} <strong>{draftVersionChanges}</strong>
                            </span>
                        )}
                    </header>
                    <main>
                        <div className="workflow-versions-draft-creators">
                            {isPublishReady && draftWorkflowVersion.publishApprover ? (
                                <TrackAvatar owner={draftWorkflowVersion.publishApprover} />
                            ) : (
                                peopleWhoMadeChangesToDraft.map((person) => {
                                    return <TrackAvatar key={person.id} owner={person} />;
                                })
                            )}
                        </div>
                        <p className={classNames(!peopleWhoMadeChangesToDraft.length && 'margin-left-none')}>
                            {draftComment ? (
                                <>
                                    <strong>{draftWorkflowVersion.publishApprover?.firstName}:</strong> {draftComment}
                                </>
                            ) : (
                                'Work in progress'
                            )}
                        </p>
                        {draftVersionChanges !== undefined && (
                            <Menu
                                show={open}
                                onClose={() => toggleOpen()}
                                placement="left-start"
                                menuItems={
                                    <>
                                        <StyledMenuItem
                                            onClick={() => discardPendingChanges()}
                                            disabled={currentWorkflowVersionChanges == 0}
                                        >
                                            Discard Pending Changes
                                        </StyledMenuItem>
                                        <Tooltip
                                            content="There are errors in the module editor"
                                            disabled={!showCreateVersionError}
                                        >
                                            <StyledMenuItem
                                                onClick={() => commitWorkflowVersion()}
                                                disabled={showCreateVersionError || currentWorkflowVersionChanges == 0}
                                            >
                                                Create Version
                                            </StyledMenuItem>
                                        </Tooltip>
                                    </>
                                }
                            >
                                <StyledKebabMenuItem
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        toggleOpen();
                                    }}
                                    flat
                                    thin
                                />
                            </Menu>
                        )}
                    </main>
                </Clickable>
            )}
            <WorkflowVersionsPaginatedList
                filters={filters}
                filterType="versions"
                hasLoadMore={hasMorePages}
                isEmpty={!workflowVersions.length}
                loading={loading.initial || loading.nextPageLoading}
                onLoadMoreClick={loadNextPage}
                onFiltersChange={setFilters}
                loadingIndicator={[...Array.from({ length: 5 }).keys()].map((index) => (
                    <WorkflowVersionLoadingInfo key={index} />
                ))}
                emptyListMessage="No changes were found..."
                appendFilterDialogToBody
            >
                {workflowVersions.map((workflowVersion) => (
                    <WorkflowVersionSummary key={workflowVersion.id}>
                        <button
                            className="workflow-version-info-button"
                            type="button"
                            onClick={() => onWorkflowVersionSelected(workflowVersion.id, false)}
                        >
                            <WorkflowVersionInfo workflowVersion={workflowVersion} searchTerm={filters.searchTerm} />
                        </button>
                    </WorkflowVersionSummary>
                ))}
            </WorkflowVersionsPaginatedList>
        </>
    );
};

export default WorkflowVersionsList;
