import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import SolutionVersionsFiltersPopover, { publishSolutionSidePaneFiltersHelper } from './SolutionVersionsFiltersPopover';
import { ReactComponent as FilterIcon } from '../../../../images/icons/history/filter.svg';
import type SolutionVersionsFilters from '../entities/SolutionVersionsFilters';

import { Popover, SearchBox, useDebouncedState } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${Theme.colors.gray_400};
`;

const FiltersButton = styled.button<{ hasFilters: boolean }>`
    position: relative;
    background: transparent;
    border: none;
    padding: 0;
    margin-right: 10px;
    height: 30px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    .tnk-icon {
        display: inline-flex;
    }

    &:hover .tnk-icon svg path {
        fill: ${Theme.current.palette.colorPicker.HEX_34393E};
    }

    ${({ hasFilters }) =>
        hasFilters &&
        css`
            ::after {
                content: '';
                display: block;
                position: absolute;
                top: 5px;
                right: 2px;
                width: 7px;
                height: 7px;
                background: ${Theme.colors.primaryActive};
                border-radius: 100%;
            }
        `}
`;

interface Props {
    workflowFolder: WorkflowFolder;
    searchTerm: string;
    onSearchTermChanged(searchTerm: string): void;
    filters: SolutionVersionsFilters;
    onFiltersChanged(filters: SolutionVersionsFilters): void;
}

const SolutionVersionsFiltersRow: React.FC<Props> = ({
    workflowFolder,
    searchTerm,
    onSearchTermChanged,
    filters,
    onFiltersChanged,
}) => {
    const [internalSearchTerm, setInternalSearchTerm] = useDebouncedState(searchTerm, onSearchTermChanged);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const hasFilters = useMemo(() => {
        return publishSolutionSidePaneFiltersHelper.hadChanged(filters);
    }, [filters]);

    return (
        <Wrapper>
            <SearchBox
                value={internalSearchTerm}
                onChange={(event) => {
                    setInternalSearchTerm(event.target.value);
                }}
                $height="30px"
                $leftIconPadding={14}
                $borders="none"
                placeholder="Search Publish Message"
            />

            <Popover
                content={
                    <SolutionVersionsFiltersPopover
                        workflowFolder={workflowFolder}
                        filters={filters}
                        onChange={onFiltersChanged}
                    />
                }
                show={popoverOpen}
                onClose={() => setPopoverOpen(false)}
            >
                <FiltersButton onClick={() => setPopoverOpen(true)} hasFilters={hasFilters}>
                    <span className="tnk-icon">
                        <FilterIcon />
                    </span>
                </FiltersButton>
            </Popover>
        </Wrapper>
    );
};

export default SolutionVersionsFiltersRow;
