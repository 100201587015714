import type PopperJS from '@popperjs/core';
import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';

import icoMoonIcons from './IcoMoonIcons.json';
import IconPicker from './IconPicker';
import thirdPartyIconsByCategory from './ThirdPartyIconsByCategory.json';
import thirdPartyIconsMetadata from './ThirdPartyIconsMetadataMinimal.json';

import type { Icon, IconsByCategory } from '@tonkean/tonkean-entities';
import { classNames } from '@tonkean/utils';

const Container = styled.div<{ $addMargin: boolean }>`
    margin: ${({ $addMargin }) => ($addMargin ? '16px 0' : '0px')};
`;

export interface TonkeanIconPickerProps {
    icon?: Icon;
    onChangeIcon: (icon: Icon) => void;
    SelectedIconComponent: ReactElement;
    showLabel?: boolean;
    placement?: PopperJS.Placement;
    addMargin?: boolean;
    pickerWidth?: number;
    pickerHeight?: number;
    color?: string;
    disabled?: boolean;
    predefinedIcons?: IconsByCategory[];
}

const TonkeanIconPicker: React.FC<TonkeanIconPickerProps> = ({
    icon,
    onChangeIcon,
    SelectedIconComponent,
    showLabel = true,
    placement,
    addMargin = true,
    pickerWidth,
    pickerHeight,
    color,
    disabled,
    predefinedIcons = [],
}) => {
    const integrations = useAngularService('integrations');
    const projectManager = useAngularService('projectManager');

    const [showCategories, setShowCategories] = useState<boolean>(true);
    const [searchTerm, setSearchTerm] = useState<string>();

    const integrationTypes: string[] = integrations.getUniqueIntegrationsDataSources(
        [],
        projectManager.projectData?.canBeCreatedByUserIntegrationTypes,
        projectManager.project.id,
    );

    const onSearch = useCallback((search) => {
        setShowCategories(!search);
        setSearchTerm(search);
    }, []);

    // get font awesome icons in IconsByCategory format to pass to icon picker components
    const thirdPartyIcons = useMemo(() => {
        const icons: IconsByCategory[] = [];
        const icoMoonIconsCategories = Object.values(icoMoonIcons);
        icoMoonIconsCategories.forEach((category) => {
            icons.push({
                label: category.label,
                icons: category.icons.map((categoryIcon) => ({
                    name: categoryIcon,
                    iconClass: classNames(`icon-moon`, `icon-${categoryIcon}`),
                    isFullClassName: true,
                })),
            });
        });

        const thirdPartyIconsCategories = Object.values(thirdPartyIconsByCategory);
        thirdPartyIconsCategories.forEach((category) => {
            icons.push({
                label: category.label,
                icons: category.icons.map((categoryIcon) => ({
                    name: categoryIcon,
                    iconClass: thirdPartyIconsMetadata[categoryIcon].styles[0],
                })),
            });
        });
        return icons;
    }, []);

    const uniqueThirdPartyIcons = useMemo(() => {
        const allThirdPartyIconFlat = thirdPartyIcons.flatMap((category) => category.icons);
        return [...new Set(allThirdPartyIconFlat)];
    }, [thirdPartyIcons]);

    const filterIconsBySearchTerm = useCallback((resultIcons, lowerSearchTerm, iconsToFilter) => {
        const result: IconsByCategory = { ...resultIcons };
        // filter the icons search term
        const filteredIcons = iconsToFilter.filter((item) => item.name.toLowerCase().includes(lowerSearchTerm));

        // filter out the double icons (when an icon is in more than one category)
        if (filteredIcons) {
            filteredIcons.forEach((filteredIcon) => {
                if (
                    !result.icons.some(
                        (icon) => icon.name === filteredIcon.name && icon.iconClass === filteredIcon.iconClass,
                    )
                ) {
                    result.icons.push(filteredIcon);
                }
            });
        }
        return result;
    }, []);

    // Combine icons list of multiple icon types - pre defined widget icons and a closed list of suggested icons
    // List of icons from font awesome
    // Matched entity icons
    // If component was filtered, it will search all the integrations in the system for a matching icon
    const icons = useMemo(() => {
        if (!searchTerm) {
            return [...predefinedIcons, ...thirdPartyIcons];
        } else {
            const lowerSearchTerm = searchTerm?.toLowerCase() || '';
            let resultIcons: IconsByCategory = { label: '', icons: [] };
            resultIcons = filterIconsBySearchTerm(resultIcons, lowerSearchTerm, uniqueThirdPartyIcons);
            resultIcons = filterIconsBySearchTerm(
                resultIcons,
                lowerSearchTerm,
                predefinedIcons.flatMap((category) => category.icons),
            );

            if (integrationTypes) {
                integrationTypes.push('tonkean');
                const integrationIcons = integrationTypes?.filter((integration) =>
                    integration?.toLowerCase().includes(lowerSearchTerm),
                );
                if (integrationIcons) {
                    integrationIcons.forEach((item) => {
                        resultIcons.icons.push({
                            name: item,
                            integrationName: item,
                            iconClass: `mod-${item}`,
                        });
                    });
                }
            }
            return [resultIcons];
        }
    }, [
        filterIconsBySearchTerm,
        integrationTypes,
        searchTerm,
        thirdPartyIcons,
        uniqueThirdPartyIcons,
        predefinedIcons,
    ]);

    return (
        <Container $addMargin={addMargin}>
            <IconPicker
                value={icon}
                icons={icons}
                onChangeIcon={onChangeIcon}
                showCategories={showCategories}
                onSearch={onSearch}
                SelectedIconComponent={SelectedIconComponent}
                showLabel={showLabel}
                placement={placement}
                pickerWidth={pickerWidth}
                pickerHeight={pickerHeight}
                color={color}
                disabled={disabled}
            />
        </Container>
    );
};

export default TonkeanIconPicker;
