import React from 'react';

import { Placeholder, PlaceholderGrid, PlaceholderGridDirection } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const FormsListLoading: React.FC = () => {
    return (
        <PlaceholderGrid direction={PlaceholderGridDirection.ROWS} gap="20px">
            {range(3).map((i) => (
                <PlaceholderGrid key={i} direction={PlaceholderGridDirection.COLUMNS} gap="8px" template="2px 18px 1fr">
                    <Placeholder $width="0" $circle />
                    <Placeholder $width="18px" $circle />
                    <PlaceholderGrid direction={PlaceholderGridDirection.ROWS} gap="4px">
                        <Placeholder $height={FontSize.SMALL_12} $width="150px" />
                        <Placeholder $height={FontSize.XSMALL_10} $width="100px" />
                    </PlaceholderGrid>
                </PlaceholderGrid>
            ))}
        </PlaceholderGrid>
    );
};

export default FormsListLoading;
