<div class="send-form-configuration">
    <tnk-person-inquiry-logic-configuration
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        configured-logic="data.configuredLogic"
        preview-evaluation-source="data.previewEvaluationSource"
        invalid-logics="data.invalidLogics"
        include-form-attachment="data.includeFormAttachment"
        include-item-interface-attachment="data.includeItemInterfaceAttachment"
        on-actions-changed="onActionsChanged(definition, shouldSaveLogic)"
        open-field-modal="data.openFieldModal"
    ></tnk-person-inquiry-logic-configuration>
</div>
