import type { Dispatch, SetStateAction } from 'react';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import ActionEntityOutputTypeSelect from './ActionEntityOutputTypeSelect';
import sqlIntegrations from '../../../utils/SqlIntegrations';
import type ActionOutput from '../entities/ActionOutput';

import { ModalBody } from '@tonkean/infrastructure';
import type { IntegrationSupportedEntity, ProjectIntegration } from '@tonkean/tonkean-entities';
import { ActionType, IntegrationType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const FormSizedTextarea = styled.textarea`
    max-width: 490px;
    min-width: 490px;
    min-height: 55px;
`;

const FormTitle = styled.div`
    margin-top: 30px;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_800};
    margin-bottom: 14px;
`;

interface Props {
    projectIntegration: ProjectIntegration;
    setActionOutput: Dispatch<SetStateAction<ActionOutput>>;
    setDescription: Dispatch<SetStateAction<string>>;
    customActionTypesToExclude?: ActionType[];
    defaultActionType?: ActionType;
    defaultEntity?: IntegrationSupportedEntity;
    canChooseEntity?: boolean;
}

const ProjectIntegrationActionCreateModalBody: React.FC<Props> = ({
    projectIntegration,
    setActionOutput,
    setDescription,
    customActionTypesToExclude,
    defaultActionType,
    defaultEntity,
    canChooseEntity,
}) => {
    const [descriptionTextValue, setDescriptionTextValue] = useState<string>();

    const isCustomDataSource = useMemo(() => {
        return projectIntegration.integrationType === IntegrationType.WEBHOOK;
    }, [projectIntegration]);

    const isNoCodeDataSource = useMemo(() => {
        return projectIntegration.integrationType === IntegrationType.NO_CODE_DATA_SOURCE;
    }, [projectIntegration]);

    const isSqlIntegration = useMemo(() => {
        return sqlIntegrations.includes(projectIntegration.integrationType.toLowerCase());
    }, [projectIntegration]);

    const customActionsToExclude = useMemo(() => {
        const customActionToExcludeList: ActionType[] = [];

        if (isSqlIntegration) {
            return [
                ActionType.CREATE_OR_UPDATE,
                ActionType.UPDATE,
                ActionType.CREATE,
                ActionType.UPLOAD,
                ActionType.DOWNLOAD,
                ActionType.STREAM_IMAGE,
            ];
        }

        // We add support for upload/download actions just for no-code data source
        if (!isNoCodeDataSource) {
            customActionToExcludeList.push(ActionType.UPLOAD, ActionType.DOWNLOAD, ActionType.STREAM_IMAGE);
        }

        customActionToExcludeList.push(...(customActionTypesToExclude ?? []));

        return customActionToExcludeList;
    }, [isSqlIntegration, isNoCodeDataSource, customActionTypesToExclude]);

    return (
        <ModalBody>
            <Label>The action will be:</Label>
            <ActionEntityOutputTypeSelect
                projectIntegration={projectIntegration}
                defaultActionType={
                    defaultActionType
                        ? defaultActionType
                        : isCustomDataSource || isSqlIntegration
                          ? ActionType.CUSTOM
                          : ActionType.CREATE
                }
                onChange={(actionOutput) => setActionOutput(actionOutput)}
                canChooseEntity={!isSqlIntegration}
                customActionTypesToExclude={customActionsToExclude}
                shouldNotChooseDefaultEntity={isCustomDataSource}
                defaultEntity={defaultEntity}
                shouldDisableChoice={!canChooseEntity}
            />

            <FormTitle>Description</FormTitle>
            <InputContainer>
                <FormSizedTextarea
                    value={descriptionTextValue}
                    className="form-control"
                    onChange={({ target }) => {
                        setDescriptionTextValue(target.value);
                        setDescription(target.value);
                    }}
                    placeholder="Insert description"
                />
            </InputContainer>
        </ModalBody>
    );
};

export default ProjectIntegrationActionCreateModalBody;
