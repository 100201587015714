import template from './tnkManageFields.template.html?angularjs';

angular.module('tonkean.app').component('tnkManageFields', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        createFieldsAsVisible: '<',
        targetType: '@',
        hideVisibilityButton: '<',
        dndDisabled: '<',
        editColumn: '&',
        onClose: '&',
        controlObject: '<',
        reloadFields: '<',
        modeSmall: '<', // When true, the buttons can float on top of each field item to handle small widths.
        saveOnChange: '<',
        solutionBusinessReportId: '<',
        environment: '<',
    },
    template,
    controller: 'ManageFieldsCtrl',
});
