import React from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { Placeholder } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const ContentContainer = styled.div`
    height: 450px;
    overflow-y: visible;
`;

const ErrorText = styled.div`
    color: ${Theme.colors.error};
`;

const ItemContent = styled.div`
    margin-top: 14px;
    margin-bottom: 14px;
`;

const Divider = styled.hr`
    margin: 0;
`;

interface Props {
    workflowVersionId: string;
    matchedEntityId: string;
    initiativeId?: string;
}

const PreviewMatchedEntityItems: React.FC<Props> = ({ workflowVersionId, matchedEntityId, initiativeId }) => {
    const { data, error, loading } = useTonkeanService(
        'getMatchedEntityItemsPreview',
        workflowVersionId,
        matchedEntityId,
        initiativeId,
    );

    return (
        <ContentContainer>
            {loading &&
                [1, 2, 3, 4, 5].map((number) => {
                    return (
                        <ItemContent key={number}>
                            <Placeholder $width="250px" $height="15px" />
                        </ItemContent>
                    );
                })}

            {error && <ErrorText>There was an error trying to load items.</ErrorText>}

            {!loading &&
                !error &&
                data?.entities.map((externalActivity) => {
                    return (
                        <div key={externalActivity.id}>
                            <ItemContent>{externalActivity.title}</ItemContent>

                            <Divider />
                        </div>
                    );
                })}
        </ContentContainer>
    );
};

export default PreviewMatchedEntityItems;
