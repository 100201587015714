import type CreateAfterOption from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import addFormBlockCreateOptionDefaults from '../addFormBlockCreateOptionDefaults';
import isOpenFormFromOtherGroup from '../canCreate/isOpenFormFromOtherGroup';
import formAnsweredTriggerParamsEnricher from '../enrichers/formAnsweredTriggerParamsEnricher';

import type { LogicImpact } from '@tonkean/tonkean-entities';

export const isOpenFormAction = (configuredLogic: LogicImpact, features) => {
    const customTriggerActionDefinition =
        configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition;

    return (
        customTriggerActionDefinition?.selectedButtonType === 'open-form' ||
        customTriggerActionDefinition?.selectedActionType === 'open-form' ||
        customTriggerActionDefinition?.selectedButtonType === 'open-custom-interface-in-sequence' ||
        customTriggerActionDefinition?.selectedActionType === 'open-custom-interface-in-sequence'
    );
};

const formAnsweredAfterButtonCanCreate: CreateAfterOptionCanCreate = (
    childImpacts,
    configuredLogic,
    logicBlockConfigsFormInfoRetriever,
    customTriggerManager,
    features,
) => {
    return (
        isOpenFormAction(configuredLogic, features) &&
        !isOpenFormFromOtherGroup(configuredLogic, logicBlockConfigsFormInfoRetriever)
    );
};

const addFormAnsweredBlockAfterButtonCreateAfterOption: CreateAfterOption = {
    label: addFormBlockCreateOptionDefaults.label,
    blockDisplayName: addFormBlockCreateOptionDefaults.blockDisplayName,
    triggerParamsEnricher: formAnsweredTriggerParamsEnricher,
    canCreate: formAnsweredAfterButtonCanCreate,
};
export default addFormAnsweredBlockAfterButtonCreateAfterOption;
