import { useMemo } from 'react';

import type {
    ActionWidgetActionData,
    ActionWidgetOpenFormActionData,
    ActionWidgetOpenIntakeSequenceActionData,
    ActionWidgetOpenMatchedItemActionData,
    ActionWidgetTriggerActionData,
} from '../../components/actionButtons/ActionWidgetActionData';

import {
    type ActionsItemWidgetConfiguration,
    type Initiative,
    InterfaceCTAActionType,
    type ItemInterfaceWidget,
} from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY, Truthy } from '@tonkean/utils';

const useItemInterfaceActionData = (
    widget: ItemInterfaceWidget<ActionsItemWidgetConfiguration>,
    actionsHiddenLiveDisplay: {} | undefined,
    shownForms: ActionWidgetOpenFormActionData[],
    showTriggerActions: ActionWidgetTriggerActionData[],
    matchedItemsActionButtons: ActionWidgetOpenMatchedItemActionData[] | undefined,
    intakeSequenceActionButtons: ActionWidgetOpenIntakeSequenceActionData[] | undefined,
    initiative: Initiative | undefined,
): ActionWidgetActionData[] => {
    const enrichActionWithFetchedEntityData: ActionWidgetActionData[] = useMemo(() => {
        return (
            widget.configuration?.actions
                ?.filter((action) => action.shown && !actionsHiddenLiveDisplay?.[`${widget.id}-${action.id}`])
                ?.map((action) => {
                    if (action.type === InterfaceCTAActionType.CLONE_ITEM) {
                        return action;
                    }
                    switch (action.type) {
                        case InterfaceCTAActionType.OPEN_FORM: {
                            const enrichment = shownForms.find((item) => item.id === action.id);
                            if (enrichment) {
                                return { ...enrichment, ...action };
                            }
                            break;
                        }
                        case InterfaceCTAActionType.TRIGGER_WORKFLOW: {
                            const enrichment = showTriggerActions.find((item) => item.id === action.id);
                            if (enrichment) {
                                return { ...enrichment, ...action };
                            }
                            break;
                        }
                        case InterfaceCTAActionType.OPEN_MATCHED_ITEM: {
                            const enrichment = matchedItemsActionButtons?.find((item) => {
                                const fieldDefinitionId = action.id.split('-');
                                return item.id === fieldDefinitionId[0];
                            });
                            if (enrichment) {
                                return { ...enrichment, ...action };
                            } else {
                                return {
                                    ...action,
                                    initiativeId: initiative?.id,
                                    customTriggerDisplayName: action.label || '',
                                };
                            }
                        }
                        case InterfaceCTAActionType.OPEN_INTAKE_SEQUENCE:
                            const enrichment = intakeSequenceActionButtons?.find((item) => item.id === action.id);
                            if (enrichment) {
                                return { ...action, ...enrichment };
                            }
                            break;
                    }

                    return undefined;
                })
                .filter(Truthy) || EMPTY_ARRAY
        );
    }, [
        widget.configuration?.actions,
        widget.id,
        actionsHiddenLiveDisplay,
        intakeSequenceActionButtons,
        shownForms,
        showTriggerActions,
        matchedItemsActionButtons,
        initiative?.id,
    ]);

    return enrichActionWithFetchedEntityData;
};

export default useItemInterfaceActionData;
