import React from 'react';

import { SolutionSiteBuilderHeaderContainer } from './SolutionSiteBuilderHeader';

import { Placeholder } from '@tonkean/infrastructure';

interface Props {}

const SolutionSiteBuilderHeaderLoading: React.FC<Props> = ({}) => {
    return (
        <SolutionSiteBuilderHeaderContainer $isMobile={false} $tabsView={false}>
            <Placeholder $width="25%" $height="23px" />
        </SolutionSiteBuilderHeaderContainer>
    );
};

export default SolutionSiteBuilderHeaderLoading;
