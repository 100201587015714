import type { SearchWidgetCardsDensity } from '../SearchWidgetConfiguration';

export interface SearchWidgetCardsDensityConfiguration {
    gap: number;
    itemsGap: number; // gap between items
    textsGap: number;
    maxTextLines: number;
    image: {
        width: number;
        height: number;
        initialsFontSize: number;
    };
}
const SearchWidgetCardsDensityConfigurations: Record<SearchWidgetCardsDensity, SearchWidgetCardsDensityConfiguration> =
    {
        SPACED: {
            gap: 16,
            itemsGap: 16,
            textsGap: 10,
            maxTextLines: 3,
            image: { width: 280, height: 160, initialsFontSize: 32 },
        },
        COMPACT: {
            gap: 8,
            itemsGap: 0,
            textsGap: 4,
            maxTextLines: 1,
            image: { width: 42, height: 42, initialsFontSize: 16 },
        },
    };

export default SearchWidgetCardsDensityConfigurations;
