import { lateConstructController } from '@tonkean/angular-components';
import { OWNER_EVALUATION_OPTIONS, DUE_DATE_EVALUATION_OPTIONS } from '@tonkean/constants';

/* @ngInject */
function InnerTracksTemplateConfigCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        // Component bindings
        existingInnerTracksTemplate: ctrl.existingInnerTracksTemplate,
        hideApplyOnPreviouslyCreatedTracks: ctrl.hideApplyOnPreviouslyCreatedTracks,

        // Component model
        initiatives: [],
        applySettingsOnPreviouslyCreatedTracks: false,
        dueDateAlertOpen: {},
        ownerAlertOpen: {},
        peopleSelectorCustomOptions: [getParentOwnerCustomOption()],

        newTrackPlaceholder: ctrl.newTrackPlaceholder || 'Inner Track name',
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.existingInnerTracksTemplate && $scope.data.existingInnerTracksTemplate.length) {
            // Edit mode.

            // applySettingsOnPreviouslyCreatedTracks setting.
            $scope.data.applySettingsOnPreviouslyCreatedTracks =
                $scope.data.existingInnerTracksTemplate.applySettingsOnPreviouslyCreatedTracks;

            for (let i = 0; i < $scope.data.existingInnerTracksTemplate.length; i++) {
                const trackTemplate = $scope.data.existingInnerTracksTemplate[i];
                // Setting the inviteSent flag to true, so that the people selector won't display the send an invite message.
                if (trackTemplate.manualOwnerInfo) {
                    trackTemplate.manualOwnerInfo.inviteSent = true;
                }

                const initiative = {
                    // Title
                    title: trackTemplate.title,

                    // Due date
                    dueDateEvaluationOption: trackTemplate.dueDateEvaluationOption,
                    relativeDueDateInDays: trackTemplate.relativeDueDateInDays,

                    // Owner
                    ownerEvaluationOption: trackTemplate.ownerEvaluationOption,
                    manualOwnerInfo: trackTemplate.manualOwnerInfo,
                    selectedPeople: [],
                };

                // Evaluating the owner configuration for the initiative.
                if (trackTemplate.ownerEvaluationOption === OWNER_EVALUATION_OPTIONS.manual) {
                    initiative.selectedPeople = [trackTemplate.manualOwnerInfo];
                } else if (trackTemplate.ownerEvaluationOption === OWNER_EVALUATION_OPTIONS.takeFromParent) {
                    initiative.selectedPeople = [getParentOwnerCustomOption()];
                }

                $scope.data.initiatives.push(initiative);
            }
        }
    };

    /**
     * Adds an empty initiative to the initiatives.
     */
    $scope.addEmptyInitiative = function () {
        $scope.data.initiatives.push(getEmptyInitiative());
    };

    /**
     * Removes initiative at given index from the initiatives.
     * @param index
     */
    $scope.removeInitiative = function (index) {
        $scope.data.initiatives.splice(index, 1);

        $scope.onInnerTracksTemplateConfigChanged();
    };

    /**
     * Occurs once a person is selected in the owner selector component.
     */
    $scope.onPersonSelected = function (initiative) {
        // Setting the selected person as the owner of the initiative.
        if (initiative.selectedPeople.length) {
            const selectedPerson = initiative.selectedPeople[0];

            // If the id of the selected person is the takeFromParent, we need to set it to be the evaluation option.
            // Otherwise, a manual owner was selected.
            if (selectedPerson.id === OWNER_EVALUATION_OPTIONS.takeFromParent) {
                initiative.ownerEvaluationOption = OWNER_EVALUATION_OPTIONS.takeFromParent;
            } else {
                initiative.ownerEvaluationOption = OWNER_EVALUATION_OPTIONS.manual;
                initiative.manualOwnerInfo = selectedPerson;
            }
        }

        $scope.onInnerTracksTemplateConfigChanged();
    };

    /**
     * Occurs once a person is removed from the owner selector component.
     */
    $scope.onPersonRemoved = function (initiative) {
        initiative.ownerEvaluationOption = OWNER_EVALUATION_OPTIONS.noOwner;
        initiative.manualOwnerInfo = null;

        $scope.onInnerTracksTemplateConfigChanged();
    };

    /**
     * Occurs once the due date is changed.
     */
    $scope.onDueDateChanged = function (initiative) {
        // Note that !initiative.relativeDueDateInDays will be true if the value is zero.
        if (!initiative.relativeDueDateInDays) {
            initiative.dueDateEvaluationOption = DUE_DATE_EVALUATION_OPTIONS.noDueDate;
            initiative.relativeDueDateInDays = null;
        } else {
            initiative.dueDateEvaluationOption = DUE_DATE_EVALUATION_OPTIONS.relativeToCreation;
        }

        $scope.onInnerTracksTemplateConfigChanged();
    };

    /**
     * Occurs each time there's a change in the initiatives configuration.
     */
    $scope.onInnerTracksTemplateConfigChanged = function (shouldSaveLogic) {
        if (!$scope.data.initiatives || !$scope.data.initiatives.length) {
            ctrl.onInnerTracksTemplateConfigChanged(getServerTemplateConfiguration([], shouldSaveLogic));
            return;
        }

        // We only care for initiatives that don't have an empty title.
        const nonEmptyInitiatives = $scope.data.initiatives.filter(
            (initiative) => initiative && initiative.title && initiative.title.length,
        );
        if (!nonEmptyInitiatives || !nonEmptyInitiatives.length) {
            ctrl.onInnerTracksTemplateConfigChanged(getServerTemplateConfiguration([], shouldSaveLogic));
            return;
        }

        // Fire the event to send the updated model to the user of the component.
        if (ctrl.onInnerTracksTemplateConfigChanged) {
            ctrl.onInnerTracksTemplateConfigChanged(
                getServerTemplateConfiguration(nonEmptyInitiatives, shouldSaveLogic),
            );
        }
    };

    /**
     * Toggles the apply settings on previously created tracks check box.
     */
    $scope.toggleApplySettingsOnPreviouslyCreatedTracks = function () {
        $scope.data.applySettingsOnPreviouslyCreatedTracks = !$scope.data.applySettingsOnPreviouslyCreatedTracks;
        $scope.onInnerTracksTemplateConfigChanged();
    };

    /**
     * Gets the server configuration for tracks template.
     */
    function getServerTemplateConfiguration(initiatives, shouldSaveLogic) {
        return {
            innerTracksTemplate: {
                innerTrackTemplatesCollection: initiatives,
                applySettingsOnPreviouslyCreatedTracks: $scope.data.applySettingsOnPreviouslyCreatedTracks,
            },
            shouldSaveLogic,
        };
    }

    /**
     * Returns an empty initiative (title is empty, and important fields are initialized).
     */
    function getEmptyInitiative() {
        return {
            title: '',
            ownerEvaluationOption: OWNER_EVALUATION_OPTIONS.noOwner,
            selectedPeople: [],
            peopleSelectorControl: {},
        };
    }

    /**
     * Returns the custom option of take parent track's owner.
     */
    function getParentOwnerCustomOption() {
        return {
            id: OWNER_EVALUATION_OPTIONS.takeFromParent,
            name: "Auto-Assign Parent Track's owner",
            selectedModeDisplayName: "Parent Track's owner",
            email: OWNER_EVALUATION_OPTIONS.takeFromParent,
            tonkeanEntityType: 'PERSON',
            inviteSent: true,

            // It is very important to set the isCustomOption to true, so that the people selector
            // knows how to display it correctly as a custom option and not as a regular user.
            isCustomOption: true,
        };
    }
}

export default angular
    .module('tonkean.app')
    .controller('InnerTracksTemplateConfigCtrl', lateConstructController(InnerTracksTemplateConfigCtrl));
