import template from './tnkTriggersGallery.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkTriggersGallery', {
    bindings: {
        isIframe: '<',
        iframeAction: '&',
        title: '@',
        onClose: '&',
        onStepChanged: '&',
    },
    template,
    controller: 'tnkTriggersGalleryCtrl',
});
