<div class="flex-vmiddle">
    <div>
        <tnk-avatar class="activity-comment-avatar" user="item.actor" noclass="true"></tnk-avatar>
    </div>
    <div class="flex-grow">
        <span>
            <strong>{{ item.actor.id === as.currentUser.id ? 'You' : item.actor.name }}</strong>
            have marked&nbsp;
            <a ui-sref="product.analyze.problems.details({problemId: item.reference1.id})">
                <strong>{{ item.reference1.text }}</strong>
            </a>
            as
            <strong>
                {{ (item.metadata ? item.metadata.newStatus : item.reference1.status).toLowerCase().replace('_', ' ') }}
            </strong>
        </span>
    </div>
</div>
