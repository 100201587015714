import kustomerCircleIcon from '../../../../apps/tracks/images/integrations/kustomer-circle.png';
import kustomerIcon from '../../../../apps/tracks/images/kustomer.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class KustomerIntegration extends IntegrationDefinitionBase {
    override name = 'kustomer';
    override displayName = 'Kustomer';
    override description = 'We will monitor and collect all conversation data';
    override authenticationType = AuthenticationMethod.API_TOKEN;
    override supportsMultipleIntegrationPerUser = true;
    override externalActivityCreatedDateFieldName = 'createdate';
    override entities = [
        new IntegrationEntity('Conversation', 'Conversations'),
        new IntegrationEntity('Event', 'Events'),
        new IntegrationEntity('WorkSession', 'Work Sessions'),
        new IntegrationEntity('Message', 'Messages'),
        new IntegrationEntity('Customer', 'Customers'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(kustomerCircleIcon, '130px'),
        new IntegrationIcon(kustomerIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Conversation: true, Event: true, WorkSession: true, Message: true, Customer: true },
        isOnlyDataRetention: {},
    };
}

export default KustomerIntegration;
