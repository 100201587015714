import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class TakeNotEmptyFieldByFieldGroupFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.FIELD_GROUP_TAKE_NOT_EMPTY;
    override readonly sign = 'FieldGroupTakeNotEmpty';
    override readonly displayName = 'Field Group Take Not Empty';
    override readonly description = 'Will take the fields by the group field name and find the first not empty.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Group name',
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            dataType: FieldType.String,
        },
    ];
}
