import type React from 'react';

import type AuthenticationMethod from './AuthenticationMethod';
import type CustomizedAuthenticationComponentProps from './CustomizedAuthenticationComponentProps';
import type CustomizedSetupComponentProps from './CustomizedSetupComponentProps';
import type DataRetentionSettings from './DataRetentionSettings';
import type IntegrationIconsSettings from './IntegrationIconsSettings';
import type StorageProviderSettings from './StorageProviderSettings';
import type OAuthHandler from '../oauth-handler.service';

import type { Environment } from '@tonkean/shared-services';
import type { DataSourcesSectionType } from '@tonkean/tonkean-entities';
import type { IntegrationEntity } from '@tonkean/tonkean-entities';

/**
 * This abstract class represents a whole integration configuration.
 * In this file we configure the integration name, supported entities, authentication configuration and
 * everything that relates to the configuration.
 *
 * Generic Explanation:
 * For every integration with a specific configuration we have project integration data.
 * For example, In google drive we configure the ROOT folder - Thus, we save the root folder
 * ID in the project integration data. In order to give our project integration data type
 * please pass the interface which represents your project integration structure.
 * If you don't need to use project integration data, leave this blank.
 */
abstract class IntegrationDefinitionBase<T = unknown> {
    // region Required Fields

    /**
     * The integration unique name, this name should be identical to the name configured in the server side.
     */
    public abstract name: string;

    /**
     * Integration display name.
     */
    public abstract displayName: string;

    /**
     * Description, message, that will be displayed to the user in the creation modal.
     */
    public abstract description: string;

    /**
     * Array of the entities the integration is support. For example: Ticket, User, etc.
     */
    public abstract entities: IntegrationEntity[];

    /**
     * The integration authentication method type.
     */
    public abstract authenticationType: AuthenticationMethod;

    /**
     * The integration icons settings.
     * Includes the info about the circled icon (used for specific location where the icon should be small and circle)
     * and rectangle icon (used for places where the icon should be big enough).
     */
    public abstract iconsSettings: IntegrationIconsSettings;

    // endregion

    // region Optional Fields

    /**
     * The data source section of the integration.
     */
    public dataSourceSectionType?: DataSourcesSectionType;

    /**
     * Defines the settings for the data retention of the integration.
     */
    public dataRetentionSettings?: DataRetentionSettings;

    /**
     * Indicates whether the integration has dynamic entities (that can not be set hard coded).
     */
    public hasDynamicEntities?: boolean = false;

    /**
     * The settings of a storage provider for the integration.
     */
    public storageProviderSettings?: StorageProviderSettings;

    /**
     * An optional field which represents the field name in the external activity
     * which we can use to find external activity by time range.
     * This field indicates when the external activity created and used by the custom filters in
     * the integration sync settings;
     * For example: 'created_at' (depends on the external activity)
     */
    public externalActivityCreatedDateFieldName?: string;

    /**
     * When user connect the integration using API token / user name and password / client id and secret key -
     * we must to explain to him from where exactly he can bring that information.
     * If you wish to give to the user those guidelines - fill in the field the steps he needs to do in chronological
     * order.
     */
    public credentialsGuidelines?: string[];

    /**
     * Whether the user can create this integration multiple times.
     * Defaults set to false.
     */
    public supportsMultipleIntegrationPerUser?: boolean = false;

    /**
     * If you want to have a specific setup component, you can override this property to return your customized setup.
     */
    public customizedSetupComponent?: React.FC<CustomizedSetupComponentProps<T>>;

    /**
     * If you want to have a specific authentication set component, you can override this property to return you
     * own type of authentication.
     * Use this override when you authentication is not usual like a simple OAuth or API token.
     * Please view AuthenticationMethod enum and see if there is an implementation that can fit to your needs.
     */
    public customizedAuthenticationComponent?: React.FC<CustomizedAuthenticationComponentProps<T>>;

    /**
     * Whether the integration can share it's credentials in order to connect more integrations
     */
    public supportsSharedCredentials?: boolean = false;

    /**
     * Whether the sync integration add a default X DateTime filter
     */
    public shouldRemoveTheDefaultSyncDateTimeFilter?: boolean = false;

    /**
     * Whether to give the user an ability to decide witch entities to collect
     */
    public showEntitiesInboundConfiguration?: boolean = true;

    // endregion

    /**
     * This function is responsible on the integration first authentication.
     * You can have your own implementation and the response will be used for integration creation.
     *
     * @param oAuthHandler The oAuth handler service that is used by oAuth implementation.
     * @param environment The environment service, from which we can get the client ids.
     */
    public authenticate(oAuthHandler: OAuthHandler, environment: Environment): Promise<Record<string, any>>;
    public authenticate() {
        return Promise.resolve({});
    }
}

export default IntegrationDefinitionBase;
