function isUrlValid(url?: string) {
    if (!url) {
        return true;
    }

    const validUrlExpression = /^https:\/\/(www\.)?[-\w@:%.+~#=]{1,256}\.[a-z\d()]{1,6}\b([-\w()@:%+.~#?&/=]*) ?/gi;
    return validUrlExpression.test(url);
}

function getFullLinkUrl(url: string, defaultHostUrl: string) {
    if (!url || url.indexOf('https://') === 0 || url.indexOf('http://') === 0) return url;

    const fullAppUrl = defaultHostUrl.endsWith('/') ? defaultHostUrl : `${defaultHostUrl}/`;
    const relativeUrl = url.startsWith('/') ? url.slice(1) : url;
    return `${fullAppUrl}${relativeUrl}`;
}

export { isUrlValid, getFullLinkUrl };
