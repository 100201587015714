import { UtilsClass } from '@tonkean/utils';
import type { TDescendant } from '@udecode/plate';
import jsPDF from 'jspdf';
import { Text } from 'slate';

export class UtilsService extends UtilsClass {
    /* @ngInject */

    private constructor(
        private $window: angular.IWindowService,
        private $document: angular.IDocumentService,
        private $timeout: angular.ITimeoutService,
    ) {
        super();
    }

    /**
     * Parse the parameters of an url to an Object.
     * @param url The url to parse (should contain '?param=value').
     * @return The parsed parameters.
     */
    public parseParams(url: string): Record<any, string | true> {
        let qIndex = url.indexOf('?');
        if (qIndex < 0) {
            qIndex = url.indexOf('#'); // to support trello
        }
        const params = {};
        if (qIndex !== -1) {
            const paramsStr = url.slice(qIndex + 1); // .replace(/#$/, ''); //Trim trailing #
            const paramsSplit = paramsStr.split(/[&#]+/g);
            angular.forEach(paramsSplit, (paramSplit) => {
                const kv = paramSplit.split('=');
                params[kv[0]!] = this.$window.decodeURIComponent(kv[1] || '') || true;
            });
        }
        return params;
    }

    /**
     * Open a popup window in the center of the current window.
     * @param url - the URL to open.
     * @param title - The window title.
     * @param width - The desired width.
     * @param height - The desired height.
     * @return The popup window object.
     */
    public popupWindow(url: string, title: string, width: number, height: number): Window | null {
        const wLeft = this.$window.screenLeft || this.$window.screenX;
        const wTop = this.$window.screenTop || this.$window.screenY;
        const left = wLeft + window.innerWidth / 2 - width / 2;
        const top = wTop + window.innerHeight / 2 - height / 2;

        const options = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`;
        return this.$window.open(url, title, options);
    }

    /**
     * Gets a friendly browser name of the current browser.
     */
    public getBrowserName(): string {
        const nAgt = this.$window.navigator.userAgent;
        let browserName = this.$window.navigator.appName;
        let nameOffset;
        let verOffset;

        // In Opera 15+, the true version is after 'OPR/'
        if ((verOffset = nAgt.indexOf('OPR/')) !== -1) {
            browserName = 'Opera';
        } else if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
            // In older Opera, the true version is after 'Opera' or after 'Version'
            browserName = 'Opera';
        } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
            // In MSIE, the true version is after 'MSIE' in userAgent
            browserName = 'Microsoft Internet Explorer';
        } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
            // In Chrome, the true version is after 'Chrome'
            browserName = 'Chrome';
        } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
            // In Safari, the true version is after 'Safari' or after 'Version'
            browserName = 'Safari';
        } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
            // In Firefox, the true version is after 'Firefox'
            browserName = 'Firefox';
        } else if (
            (nameOffset = nAgt.lastIndexOf(' ') + 1) <
            // In most other browsers, 'name/version' is at the end of userAgent
            (verOffset = nAgt.lastIndexOf('/'))
        ) {
            browserName = nAgt.substring(nameOffset, verOffset);
        }

        return browserName;
    }

    /**
     * Returns the operating system name (windows, linux, mac)
     */
    public getOSName(): string | undefined {
        const appVersion = this.$window.navigator.appVersion;
        if (appVersion.includes('Win')) {
            return 'windows';
        } else if (appVersion.includes('Linux')) {
            return 'linux';
        } else if (appVersion.includes('Mac')) {
            return 'mac';
        }
    }

    /**
     * Loads a script to the head of the html page.
     * Note: the function doesn't check to see if the script is already loaded.
     * @param url - the url of the script to load (the src).
     * @param callback - a callback for when the script is loaded.
     */
    public loadScript(url: string, callback: (this: GlobalEventHandlers, ev: Event) => any) {
        // Adding the script tag to the head as suggested before
        const document = this.$document[0]! as Document;
        const head = document.querySelectorAll('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;

        // Then bind the event to the callback function.
        // There are several events for cross browser compatibility.
        script['onreadystatechange'] = callback;
        script.addEventListener('load', callback);

        // Fire the loading
        head?.append(script);
    }

    /**
     * Returns true if an DOM element has an ellipsis CSS overflow
     * Using the answer from here https://stackoverflow.com/questions/36723600/how-to-detect-if-the-text-is-overflowtext-overflow-ellipsis-in-angular-contro
     */
    public hasEllipsis(elementSelector: string): boolean | undefined {
        const document = this.$document[0]! as Document;
        const element = document.querySelector(elementSelector) as HTMLElement;

        if (element) {
            return element.offsetWidth < element.scrollWidth;
        }
    }

    /**
     * Function which returns a promise with a delay of time milliseconds
     * @param time milliseconds to delay
     * @returns function that delays a promise by time milliseconds
     */
    public angularDelay<T>(time: number): (result: T) => angular.IPromise<T> {
        return (result: T) => {
            return this.$timeout(() => result, time);
        };
    }

    /**
     * Function which given a word, returns the plural version of it
     * @param word - the word to pluralize
     * @returns plural form of the given word
     */
    public pluralize(word: string, number?: number): string {
        if (!word) {
            return '';
        }

        if (number === 1) {
            return word;
        }

        const plural: { [key: string]: string } = {
            '(quiz)$': '$1zes',
            '^(ox)$': '$1en',
            '([m|l])ouse$': '$1ice',
            '(matr|vert|ind)ix|ex$': '$1ices',
            '(x|ch|ss|sh)$': '$1es',
            '([^aeiouy]|qu)y$': '$1ies',
            '(hive)$': '$1s',
            '(?:([^f])fe|([lr])f)$': '$1$2ves',
            '(shea|lea|loa|thie)f$': '$1ves',
            sis$: 'ses',
            '([ti])um$': '$1a',
            '(tomat|potat|ech|her|vet)o$': '$1oes',
            '(bu)s$': '$1ses',
            '(alias)$': '$1es',
            '(octop)us$': '$1i',
            '(ax|test)is$': '$1es',
            '(us)$': '$1es',
            '([^s]+)$': '$1s',
        };
        const irregular: { [key: string]: string } = {
            move: 'moves',
            foot: 'feet',
            goose: 'geese',
            sex: 'sexes',
            child: 'children',
            man: 'men',
            tooth: 'teeth',
            person: 'people',
        };
        const uncountable: string[] = [
            'sheep',
            'fish',
            'deer',
            'moose',
            'series',
            'species',
            'money',
            'rice',
            'information',
            'equipment',
            'bison',
            'cod',
            'offspring',
            'pike',
            'salmon',
            'shrimp',
            'swine',
            'trout',
            'aircraft',
            'hovercraft',
            'spacecraft',
            'sugar',
            'tuna',
            'you',
            'wood',
        ];
        // save some time in the case that singular and plural are the same
        if (uncountable.includes(word.toLowerCase())) {
            return word;
        }
        // check for irregular forms
        for (const w in irregular) {
            const pattern = new RegExp(`${w}$`, 'i');
            const replace = irregular[w]!;
            if (pattern.test(word)) {
                return word.replace(pattern, replace);
            }
        }
        // check for matches using regular expressions
        for (const reg in plural) {
            const pattern = new RegExp(reg, 'i');
            const pluralElement = plural[reg];
            if (pattern.test(word) && pluralElement) {
                return word.replace(pattern, pluralElement);
            }
        }
        return word;
    }

    /**
     * Invokes the given function in an angular apply scope if not already in one.
     * @param scope - the current scope.
     * @param fn - the function to apply.
     */
    public safeApply(scope: angular.IScope, fn: () => any): void {
        // Safely invoke method in a $apply phase.
        const phase = scope.$root ? scope.$root.$$phase : scope.$$phase;
        if (phase === '$apply' || phase === '$digest') {
            fn();
        } else {
            scope.$apply(fn);
        }
    }

    /**
     * Downloads a given content to a file with the given filename and a given typ
     * @param data - The content to be downloaded
     * @param fileName
     */
    public downloadFile(data, fileType: string, fileName: string, fileExtension: string) {
        const blob = new Blob([data], { type: fileType });
        const downloadLink = angular.element('<a></a>');
        downloadLink.attr('href', window.URL.createObjectURL(blob));
        downloadLink.attr('download', `${fileName}.${fileExtension}`);
        downloadLink[0]?.click();
    }

    private addElementsFromHtmlEditorToPdfDoc(elements: TDescendant[], doc: jsPDF) {
        let currentY = 20;

        elements.map((element) => {
            const paddingBottom = 20;
            if (Text.isText(element)) {
                const lines = doc.splitTextToSize(element.text, 500);
                const lineHeight = doc.getLineHeight();

                for (const line of lines) {
                    if (currentY + paddingBottom + lineHeight > doc.internal.pageSize.height) {
                        doc.addPage(); // Add a new page if the content won't fit with padding
                        currentY = 10;
                    }

                    doc.text(line, 10, currentY + paddingBottom);
                    currentY += lineHeight;
                }
            } else {
                this.addElementsFromHtmlEditorToPdfDoc(element.children as TDescendant[], doc);
            }
        });
    }

    /**
     * Downloads a given html editor content to a pdf file with the given filename
     * @param data - The content to be downloaded
     * @param fileName
     */
    public exportHtmlEditorContentToPdf(text?: TDescendant[], title?: string) {
        const doc = new jsPDF('portrait', 'pt', 'a4', true);

        doc.text(title || '', 10, 20);
        doc.setFontSize(12);

        this.addElementsFromHtmlEditorToPdfDoc(text || [], doc);
        doc.save(title);
    }
}

angular.module('tonkean.shared').service('utils', UtilsService);
