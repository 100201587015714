import ngClipPath from 'zeroclipboard/dist/ZeroClipboard.swf?raw';
import { SearchInitiativeMethod } from '@tonkean/solution-business-report';

function appConfig(
    $provide,
    $stateProvider,
    $urlRouterProvider,
    $httpProvider,
    $locationProvider,
    ngClipProvider,
    $logProvider,
    $compileProvider,
    $qProvider,
) {
    // Hide unhandled rejections error.
    $qProvider.errorOnUnhandledRejections(false);

    // Disable debug logs in prod environments.
    $logProvider.debugEnabled(
        window.location.hostname !== 'app.tonkean.com' && window.location.hostname !== 'tracks.tonkean.com',
    );

    // $compileProvider.debugInfoEnabled(window.location.hostname !== 'app.tonkean.com' || window.location.hostname !== 'tracks.tonkean.com');
    $compileProvider.debugInfoEnabled(false);

    // Add webcal to the sanitation regex. We took angular's default regex and added the missing prefix to it.
    const newSanitizationWhitelist = /^\s*(https?|ftp|mailto|tel|file|webcal):/;
    $compileProvider.aHrefSanitizationWhitelist(newSanitizationWhitelist);

    // Add authentication interceptor.
    $httpProvider.interceptors.push('httpInterceptor');

    $locationProvider.html5Mode(true).hashPrefix('!');

    ngClipProvider.setPath(ngClipPath);

    $stateProvider
        .state('login', {
            url: '/login?email&code&state&r&redirect',
            template: '<tnk-authentication-state></tnk-authentication-state>',
            data: {
                title: 'Login',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
                pageType: 'signin',
            },
        })
        .state('signup', {
            url: '/signup',
            template: '<tnk-authentication-state></tnk-authentication-state>',
            data: {
                title: 'Sign Up',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
                pageType: 'signup',
            },
        })
        .state('botButtonPressed', {
            url: '/bot_button_pressed',
            // Template of an empty page
            template: '<tnk-bot-button-pressed-empty-page></tnk-bot-button-pressed-empty-page>',
            data: {
                title: 'Button pressed',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
            },
        })
        .state('loginSent', {
            url: '/login/sent',
            template: '<tnk-login-sent-state></tnk-login-sent-state>',
            data: {
                title: 'Login',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
            },
        })
        .state('loginToken', {
            url: '/login/token/:token?create&access&ref&referralCode',
            template: '<tnk-login-token-state></tnk-login-token-state>',
            data: {
                title: 'Login',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
            },
        })
        .state('loginCreate', {
            url: '/login/new',
            template: '<tnk-login-create-state></tnk-login-create-state>',
            data: {
                title: 'Login',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
            },
        })
        .state('loginColumn', {
            url: '/login/column',
            template: '<tnk-login-column-state></tnk-login-column-state>',
            data: {
                title: 'Login',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
            },
        })
        .state('logout', {
            url: '/logout',
            template: '<tnk-logout-state></tnk-logout-state>',
            data: {
                title: 'Logout',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
            },
        })
        .state('loginAddBot', {
            url: '/connect',
            template: '<tnk-login-add-bot-state></tnk-login-add-bot-state>',
            data: {
                title: 'Login',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
            },
        })
        .state('loginTriggers', {
            url: '/login/triggers',
            template: '<tnk-login-triggers-state></tnk-login-triggers-state>',
            data: {
                title: 'Login',
                hideFooter: true,
                hideHeader: true,
                hideMobileBottomMenu: true,
                fullscreenXs: true,
            },
        })
        .state('quickSetup', {
            url: '/setup/:step',
            template: '<tnk-quick-setup-state></tnk-quick-setup-state>',
            data: { hideFooter: true, hideHeader: true },
        })
        .state('link', {
            url: '/link/:page/:secret?t&p&a&e&i&f&s&v&d&g&hr&wrla&cutr&sId&action&wf&wr&fvt&wrl&wv&workflowVersionType&int&wid&wrst&initialValues',
            template: '<tnk-link-state></tnk-link-state>',
            data: { title: '', hideFooter: true, hideHeader: true }, // We hide the project name here since it's a transition state.
        })
        .state('profile', {
            url: '/profile',
            template: '<tnk-manage-profile-state></tnk-manage-profile-state>',
            data: { title: 'Your Profile', hideHeader: true },
        })
        .state('referrals', {
            url: '/referrals',
            template: '<tnk-referral-modal-state></tnk-referral-modal-state>',
            data: { title: 'Referrals' },
        })
        .state('updateState', {
            url: '/update/:projectId?hr&id&customTriggerId&answer&fieldId&inner&workerRunLogicActionId&createdByCustomTriggerId&workerRunLogicActionType&workflowVersionId&formId&formVersionType&sessionId&workerRunId?originWidget&itemInterfaceId&initialValues',
            template: '<tnk-update-initiatives-state></tnk-update-initiatives-state>',
            data: { hideFooter: false, hideHeader: true, hideIntercom: true },
        })
        .state('viewOnly', {
            url: '/group/link/:token',
            template: '<tnk-view-only-state></tnk-view-only-state>',
            data: { hideFooter: false, hideHeader: false },
        })
        .state('viewOnlyEmbed', {
            url: '/group/link/:token/embed',
            template: '<tnk-view-only-state></tnk-view-only-state>',
            data: { hideFooter: true, hideHeader: true, hideIntercom: true },
        })
        .state('createListNew', {
            url: '/createnew/:mainType/:listType?g&autoCreateList',
            template: '<tnk-deprecated-copy-from-json-note></tnk-deprecated-copy-from-json-note>',
            data: {
                showProjectFilter: false,
                hideHeader: true,
                allowOverflowX: true,
                hideFooter: true,
            },
        })
        .state('createList', {
            url: '/createnew/:mainType/?listType&g&autoCreateList',
            template: '<tnk-setup-group-new-state></tnk-setup-group-new-state>',
            data: { hideFooter: true, hideHeader: true, showHelp: true },
        })
        .state('listGallery', {
            url: '/listGallery?listType',
            template: '<tnk-list-gallery-state></tnk-list-gallery-state>',
            data: { hideFooter: true, hideHeader: true, showHelp: false, hideIntercom: true },
        })
        .state('triggerGallery', {
            url: '/triggerGallery?id',
            template: '<tnk-triggers-gallery-state></tnk-triggers-gallery-state>',
            data: { hideFooter: true, hideHeader: true, showHelp: false, hideIntercom: true },
        })
        .state('enterprise', {
            url: '/enterprise/:enterpriseTab',
            template: '<tnk-manage-enterprise-page-state></tnk-manage-enterprise-page-state>',
            data: { title: 'Enterprise Management', hideHeader: true, hideFooter: true },
        })
        .state('knowledgebase', {
            url: '/knowledgebase',
            template: '<tnk-kb-popover-state></tnk-kb-popover-state>',
            data: { title: 'Knowledge base' },
        })
        .state('products', {
            url: '/boards?s&p',
            template: '<tnk-products-list-state></tnk-products-list-state>',
            data: { title: 'All Boards', hideHeader: true, hideFooter: true },
        })
        .state('products.new', {
            url: '/new/{type:private|open|local}',
            views: {
                '@': {
                    template: '<tnk-new-product-page-state></tnk-new-product-page-state>',
                },
            },
            data: { title: 'Create Board' },
        })
        .state('noaccesspage', {
            url: '/noaccesspage',
            template: '<tnk-user-no-access></tnk-user-no-access>',
            data: {
                showProjectFilter: false,
                hideHeader: true,
                title: 'No Access',
                allowOverflowX: true,
                hideFooter: true,
            },
        })
        .state('homepageView', {
            url: '/home/:projectUrlSlug',
            template: '<tnk-homepage></tnk-homepage>',
            data: {
                hideFooter: true,
                hideHeader: true, // Manually being put in by the homepage component
                hideIntercom: true,
            },
            params: {
                hideSearchInHeader: true,
                hideNavigationCurrentPage: true,
            },
        })
        .state('homepageViewFallback', {
            url: '/home',
            template: '<tnk-homepage></tnk-homepage>',
            data: {
                hideFooter: true,
                hideHeader: true, // Manually being put in by the homepage component
                hideIntercom: true,
            },
            params: {
                hideSearchInHeader: true,
                hideNavigationCurrentPage: true,
            },
        })
        .state('product', {
            url: '/:projectId',
            template: '<tnk-project-board-state></tnk-project-board-state>',
            data: { hideFooter: true },
        })
        .state('product.archive', {
            url: '/archive?gid&tid',
            template: '<tnk-archive-state></tnk-archive-state>',
            data: { showProjectFilter: true, title: '%p - Archive', hideFooter: true, showBotSidePane: true },
        })
        .state('product.form', {
            // product.form is deprecated, but kept for backwards compat
            url: '/form/:formId?initiativeId&fieldId&inner&workerRunId&customTriggerId&workerRunLogicActionId&sessionId&formVersionType&workerRunLogicId&viewMode',
            template: '<tnk-tonkean-form-redirect></tnk-tonkean-form-redirect>',
            data: { hideIntercom: true },
        })
        .state('product.board', {
            url: '/board/:filter?g&st&o&listFilter&isGroupListFilter&createTriggerId&env',
            template: '<tnk-initiatives-state></tnk-initiatives-state>',
            data: {
                showProjectFilter: true,
                title: '%p - %t',
                allowOverflowX: true,
                hideFooter: true,
                showBotSidePane: false,
            },
            params: { startManually: null, widgetId: undefined }, // Anything defined in params can be passed as usual when changing state, but it's not visible to the user.
        })
        .state('product.createEntityFromMarketplaceItemJson', {
            url: '/create-entity-from-marketplace-item-json',
            template: '<tnk-create-entity-from-marketplace-item-json></tnk-create-entity-from-marketplace-item-json>',
        })
        .state('product.createEntityFromMarketplaceItemJsonInstallation', {
            url: '/create-entity-from-marketplace-item-json-installation/:marketplaceItemType?projectId',
            template:
                '<tnk-create-entity-from-marketplace-item-json-installation></tnk-create-entity-from-marketplace-item-json-installation>',
            data: {
                showProjectFilter: false,
                hideHeader: true,
                hideFooter: true,
            },
            params: { marketplaceItemJson: null },
        })
        .state('product.solution-site-builder', {
            url: '/solution-site/:solutionSiteId/page/:solutionSitePageId/builder/:env?currentTab&widgetId',
            template: '<tnk-solution-site-builder></tnk-solution-site-builder>',
        })
        .state('product.solutionSiteBuilderPreview', {
            url: '/solution-site/:solutionSiteId/preview/:solutionSitePageId/:env',
            template: '<tnk-solution-site-interface-preview></tnk-solution-site-interface-preview>',
            data: {
                showProjectFilter: false,
                hideFooter: true,
                hideHeader: true,
                hideIntercom: true,
            },
            params: {
                widgetId: undefined,
                hideNavigationCurrentPage: true,
            },
        })
        .state('solution-site', {
            url: '/site/:projectUrlSlug/:solutionSiteUrlSlug/:solutionSitePageUrlSlug?nav',
            template: '<tnk-solution-site-interface></tnk-solution-site-interface>',
            data: {
                showProjectFilter: false,
                hideFooter: true,
                hideHeader: true,
                fullscreenXs: true,
                hideIntercom: true,
                hideMobileBottomMenu: true,
            },
            params: {
                widgetId: undefined,
                hideNavigationCurrentPage: true,
            },
        })
        .state('product.solution', {
            url: '/solution/:solutionId?tab&categoryId&pageId&library',
            template: '<tnk-solution-page></tnk-solution-page>',
            data: {
                showProjectFilter: false,
                hideFooter: true,
            },
            params: {},
        })
        .state('product.itemInterfaceBuilderPreview', {
            url: '/solution/:solutionId/itemInterface/:itemInterfaceId/preview?initiativeId&workflowVersionId&originWidget',
            template: '<tnk-item-interface-preview></tnk-item-interface-preview>',
            data: {
                showProjectFilter: false,
                hideFooter: true,
                hideHeader: true,
                hideIntercom: true,
            },
            params: {
                widgetId: undefined,
            },
        })
        .state('product.customInterfaceView', {
            url: '/itemInterface/:itemInterfaceId/interfaceView?initiativeId&originWidget&cleanMode',
            template: '<tnk-custom-interface-view></tnk-custom-interface-view>',
            params: { widgetId: undefined },
            data: {
                showProjectFilter: false,
                hideFooter: true,
                hideHeader: true,
                title: 'Item Interface',
                hideIntercom: true,
            },
        })
        .state('product.intakeInterface', {
            url: '/itemInterfaceIntake/:customTriggerId/:workflowVersionType?:initiativeId&:initialValues&:conversationId',
            template: '<tnk-intake-item-interface></tnk-intake-item-interface>',
            data: { hideFooter: false, hideHeader: true, hideIntercom: true },
            params: {
                itemInterfaceId: undefined,
                customTriggerId: undefined,
                workflowVersionType: undefined,
                initiativeId: undefined,
                initialValues: undefined,
            },
        })
        .state('product.solution-business-report', {
            url: '/business-report/:solutionBusinessReportId',
            template: '<tnk-solution-business-report-state></tnk-solution-business-report-state>',
            data: {
                showProjectFilter: true,
            },
            params: {
                startManually: null,
                searchInitiativeMethod: SearchInitiativeMethod.SEARCH_INITIATIVES,
            }, // Anything defined in params can be passed as usual when changing state, but it's not visible to the user.
        })
        .state('product.processContributorSolutionBusinessReport', {
            url: '/process-contributor-business-report/:solutionBusinessReportId',
            template: '<tnk-solution-business-report-state></tnk-solution-business-report-state>',
            data: {
                showProjectFilter: false,
            },
            params: {
                startManually: null,
                hideNavigationInHeader: true,
                searchInitiativeMethod: SearchInitiativeMethod.GET_SOLUTION_REPORT_INITIATIVES,
            }, // Anything defined in params can be passed as usual when changing state, but it's not visible to the user.
        })
        .state('product.workerEditorOld', {
            url: '/worker?g&wr&init&t&s',
            controller: ($state, $stateParams) => {
                // Redirect to the newer url
                $state.go('product.workerEditor', $stateParams, { location: 'replace' });
            },
        })
        .state('product.solutionMapper', {
            url: '/:workflowFolderId/process-mapper/:solutionMapperId',
            template: '<tnk-solution-mapper-state></tnk-solution-mapper-state>',
            data: {
                hideFooter: true,
                hideHeader: false,
            },
        })
        .state('product.workerEditor', {
            url: '/module/:g/:env?init&/:t&s&l&spt&lcm&historyVersion&openCompare&mrp&field&itemInterfaceId&initiativeId&currentTab&widgetId&createNew&originWidget&library&projectIntegration&targetId&processMapperId&nodeId',
            template: '<tnk-worker-editor-state-wrapper></tnk-worker-editor-state-wrapper>',
            reloadOnSearch: false,
            data: {
                showProjectFilter: false,
                title: '%p - %t',
                allowOverflowX: true,
                hideFooter: true,
                hideHeader: false,
            },
            params: {
                isFromList: false,
                fieldCreateStartWithDataSource: null,
                selectedFieldProps: null,
                selectedOutlineFormId: null,
                selectedOutlineFormType: null,
                fromName: null,
                fromState: null,
                fromStateParams: null,
                /**
                 * Which project integration to show for syncConfig create\edit.
                 * We dont want it in the URL as it can become a flaky situation if the user has an old URL, and the syncConfig has been changed\deleted.
                 * If syncConfig already exists it should disregard the value of this param and show the one connected into the syncConfig
                 */
                syncProjectIntegrationId: null,
            },
        })
        // Show the history modal
        .state('product.workerEditor.history', {
            url: '/history/:environment/:wr?defaultFilterSearchTerm&f&q&from&to',
            params: {
                history: true,
            },
        })
        // Used to show a specific loading screen for the worker run type
        .state('product.workerEditor.history.workerRunStage', {
            url: '/:workerRunStage',
        })
        // Show the inspect modal of the entire worker run
        .state('product.workerEditor.history.workerRunStage.inspect', {
            url: '/inspect',
            params: {
                inspect: true,
            },
        })
        // Show the inspect modal of a specific workerRunLogic in the fields tab
        .state('product.workerEditor.history.workerRunStage.inspect.workerRunLogic', {
            url: '/:workerRunLogicId',
        })
        // Show request or result tab in thew worker run logic
        .state('product.workerEditor.history.workerRunStage.inspect.workerRunLogic.tab', {
            url: '/:tab',
        })
        .state('product.workers', {
            url: '/workers?g&wr&init&listType&solutionOnReportTab',
            template: '<tnk-workers-state></tnk-workers-state>',
            data: {
                showProjectFilter: false,
                title: 'Solutions',
                allowOverflowX: true,
                hideFooter: true,
            },
            params: {
                folderId: null,
                environment: 'production',
                defaultFilterSearchTerm: '',
                fromName: null,
                fromState: null,
                fromStateParams: null,
            },
        })
        .state('product.calendar', {
            url: '/calendar/:tab?g',
            template: '<tnk-initiatives-calendar-state></tnk-initiatives-calendar-state>',
            data: { showProjectFilter: true, title: '%p - Calendar', hideFooter: true, showBotSidePane: true },
        })
        .state('product.integrations', {
            url: '/integrations',
            template: '<tnk-edit-integrations-state></tnk-edit-integrations-state>',
            data: { showProjectFilter: false, title: '%p - Data Sources' },
        })
        .state('product.triggers', {
            url: '/triggers/:tab?id',
            template: '<tnk-product-triggers-state></tnk-product-triggers-state>',
            data: { showProjectFilter: false },
        })
        .state('product.projectIntegrationActionManager', {
            url: '/projectIntegrationActionManager/:projectIntegrationId/:projectIntegrationActionId/:tab',
            template: '<tnk-project-integration-action-manager-state></tnk-project-integration-action-manager-state>',
            params: {
                fromName: null,
                fromState: null,
                fromStateParams: null,
            },
        })
        .state('product.enterpriseComponents', {
            url: '/enterprise-components/:tab',
            template: '<tnk-enterprise-components-page></tnk-enterprise-components-page>',
            data: {
                showProjectFilter: false,
                title: '%p - Enterprise Components',
                allowOverflowX: true,
                hideFooter: true,
            },
            params: {
                fromName: null,
                fromState: null,
                fromStateParams: null,
            },
        })
        .state('product.marketplaceItemView', {
            url: '/marketplace/:marketplaceItemId',
            template: '<tnk-marketplace-item-view-page></tnk-marketplace-item-view-page>',
            data: {
                showProjectFilter: false,
                title: '%p - Marketplace',
                hideFooter: true,
            },
            params: {},
        })
        .state('product.createMarketplaceItemForNativeIntegration', {
            url: '/create-marketplace-item-for-native-integration',
            template:
                '<tnk-create-marketplace-item-for-native-integration></tnk-create-marketplace-item-for-native-integration>',
        })
        .state('product.processBuilder', {
            url: '/:workflowFolderId/process-builder',
            template: '<tnk-process-builder-page></tnk-process-builder-page>',
            params: {},
            data: {
                title: 'Process Builder',
            },
        })
        .state('marketplaceItemInstall', {
            url: '/library/install/:marketplaceItemType/:marketplaceItemTemplateName?projectId&solutionId&sectionId&t',
            template: '<tnk-marketplace-item-install-page></tnk-marketplace-item-install-page>',
            data: {
                showProjectFilter: false,
                hideHeader: true,
                hideFooter: true,
            },
            params: {},
        })
        .state('product.projectIntegrationPage', {
            url: '/projectIntegrationPage/:enterpriseComponentId/:page?q&library',
            template: '<tnk-project-integration-page-state></tnk-project-integration-page-state>',
            data: { title: '%p - Data Sources' },
            params: {
                fromName: null,
                fromState: null,
                fromStateParams: null,
            },
        })
        .state('product.projectIntegrationPageEntity', {
            url: '/projectIntegrationPage/:enterpriseComponentId/entities/:entityId/:tab',
            template: '<tnk-project-integration-entity-state/>',
            data: { title: '%p - Entities' },
            params: {
                fromName: null,
                fromState: null,
                fromStateParams: null,
            },
        })
        .state('product.projectIntegrationPageEntityFetcher', {
            url: '/projectIntegrationPage/:enterpriseComponentId/entities/:entityId/:fetcherActionId/entityFetcher',
            template: '<tnk-project-integration-entity-fetcher-action-state/>',
            data: { title: '%p - Entity fetcher action' },
            params: {
                props: {},
            },
        })
        .state('product.peopleDirectoryPage', {
            url: '/peopleDirectoryPage/:enterpriseComponentId/:page/:peopleDirectoryInstance/:peopleDirectoryInstanceTab',
            template: '<tnk-people-directory-page></tnk-people-directory-page>',
            data: { title: '%p - Business Groups' },
        })
        .state('product.trainingSetPage', {
            url: '/training-set/:enterpriseComponentId/:page',
            template: '<tnk-training-set-page></tnk-training-set-page>',
            data: { title: '%p - Training Sets' },
            params: {
                /**
                 * @type {{id: string, displayName: string} | null}
                 */
                filterByMatchedModel: null,

                /**
                 * @type {{id: string, displayName: string} | null}
                 */
                filterByExtractedModel: null,

                /**
                 * @type {string[] | null}
                 */
                filterByDocumentCollectionsIds: null,
                fromState: '',
                fromStateParams: {},
                fromName: '',
            },
        })
        .state('product.trainingSetPage.trainingSetItemModal', {
            url: '/:trainingSetItemId',
        })
        .state('product.settings', {
            url: '/settings',
            template: '<tnk-board-settings></tnk-settings-state>',
            abstract: true,
        })
        .state('product.dataSourceConnection', {
            url: '/settings/dataSources/:dataSourceConnectionId/:tab',
            template: '<tnk-data-source-connection-settings-state></tnk-data-source-connection-settings-state>',
            data: { title: '%p - Data Sources', hideHeader: true },
        })
        .state('product.settings.edit', {
            url: '/edit',
            views: {
                '@': {
                    template: '<tnk-edit-project-state></tnk-edit-project-state>',
                },
            },
            data: { title: '%p - Edit Project', hideHeader: true },
        })
        .state('product.settings.tab', {
            url: '/:tab',
            template: '<tnk-board-settings></tnk-settings-state>',
        })
        .state('product.settings.profile', {
            url: '/profile',
        })
        .state('product.settings.board', {
            url: '/preferences',
        })
        .state('product.settings.dataSources', {
            url: '/dataSources',
        })
        .state('product.dataSourcesSingle', {
            url: '/settings/dataSources/single/:id',
            template: '<tnk-people-directory-page></tnk-people-directory-page>',
        })
        .state('product.settings.license', {
            url: '/subscription/:tab',
            template: '<tnk-board-settings></tnk-board-settings>',
        })
        .state('product.expired', {
            url: '/expired',
            template: '<tnk-product-expired-state></tnk-product-expired-state>',
            data: { title: 'Upgrade Subscription', hideHeader: true, hideFooter: true },
        })
        .state('product.newUnlicensedMember', {
            url: '/joinBoard',
            template: '<tnk-product-expired-state></tnk-product-expired-state>',
            data: { title: 'Upgrade Subscription', hideHeader: true, hideFooter: true },
        })
        .state('ssoInsided', {
            url: '/sso/insided/signin?return_url',
            template: '<tnk-insided-login-state></tnk-insided-login-state>',
            data: { title: '', hideFooter: true, hideHeader: true }, // We hide the project name here since it's a transition state.
        })
        .state('product.runtime', {
            url: '/runtime/:tab/:environment?g&wr&init&listType',
            template: '<tnk-runtime-page></tnk-runtime-page>',
            data: {
                showProjectFilter: false,
                title: 'Runtime',
                allowOverflowX: true,
                hideFooter: true,
            },
            params: {
                environment: 'production',
                defaultFilterSearchTerm: '',
                defaultFilterBackgroundProcessId: '',
                defaultFilterStartTime: '',
            },
        })
        .state('product.interface', {
            url: '/item',
            template: '<tnk-system-interface show-breadcrumbs="false" show-panel="true"></tnk-system-interface>',
            data: {
                title: 'Item Interface',
                hideIntercom: true,
            },
        })
        .state('product.interface.fullview', {
            url: '/fullView/:groupId/:initiativeId?originWidget',
            template: '<tnk-system-interface show-breadcrumbs="false"></tnk-system-interface>',
            data: {
                showProjectFilter: false,
                hideFooter: true,
                hideHeader: true,
                title: 'Item Interface',
                hideIntercom: true,
            },
        })
        .state('product.interface.report', {
            url: '/:solutionBusinessReportId/:groupId/:initiativeId?originWidget',
            template: '<tnk-system-interface show-breadcrumbs="false"></tnk-system-interface>',
            data: { hideIntercom: true },
        })
        .state('product.interface.group', {
            url: '/:groupId/:initiativeId?originWidget',
            template: '<tnk-system-interface show-breadcrumbs="false"></tnk-system-interface>',
            data: { hideIntercom: true },
        })
        .state('form', {
            url: '/form/:projectId/:formId?initiativeId&fieldId&inner&workerRunId&customTriggerId&workerRunLogicActionId&sessionId&formVersionType&workerRunLogicId&viewMode&initialValues&workflowVersionId',
            template: '<tnk-tonkean-form></tnk-tonkean-form>',
            data: { hideFooter: false, hideHeader: true, hideIntercom: true },
            params: { widgetId: undefined },
        });

    // Else
    $urlRouterProvider.otherwise('/');

    // Decorate for textAngular, configure the toolbar.
    $provide.decorator('taOptions', [
        'taRegisterTool',
        '$delegate',
        'modal',
        function (taRegisterTool, taOptions, modal) {
            taOptions.forceTextAngularSanitize = false;
            // $delegate is the taOptions we are decorating
            /**
             * Store the current selection range to later
             * reselect it programmatically.
             *
             * @param editorScope - textAngular editor's scope object
             * @return {void}
             */
            function storeSelectedRange(editorScope) {
                editorScope.range = document.getSelection()?.getRangeAt?.(0) ?? editorScope.range;
            }

            /**
             * Re-select the selection range we saved to get the cursor in the right location
             *
             * @param editorScope - textAngular editor's scope object
             * @return {void}
             */
            function reselectSelectedRange(editorScope) {
                const selection = document.getSelection();
                if (selection.rangeCount > 0) {
                    selection.removeAllRanges();
                }
                selection.addRange(editorScope.range);
            }

            function storeEditorSelection(editorScope) {
                editorScope.displayElements.text[0].focus();
                storeSelectedRange(editorScope);
            }

            taRegisterTool('tnkInsertField', {
                tooltiptext: 'Insert Field',
                class: 'flex-vmiddle', // Don't leave this empty or the button will unwanted default classes 'btn btn-default'
                display:
                    '<span ng-if="isEnabled">' +
                    '   <span class="margin-left margin-right">Insert Field:</span>' +
                    '   <tnk-tabs-field-selector class="tonkean-expression-fields-btn"\n' +
                    '       group-id="{{groupId}}"\n' +
                    '       workflow-version-id="{{workflowVersionId}}"\n' +
                    '       logic-id="{{logicId}}"\n' +
                    '       tooltip="Insert field"\n' +
                    '       global-tabs-only="globalTabsOnly"\n' +
                    '       on-popover-open="onPopoverOpen($editor)"\n' +
                    '       on-field-selected="onVariableSelected($editor, selectedField)"\n' +
                    '       is-from-html-editor="true">\n' +
                    '   </tnk-tabs-field-selector>' +
                    '</span>',

                action() {
                    const editorScope = this.$editor();
                    storeEditorSelection(editorScope);
                },

                onPopoverOpen($editor) {
                    const editorScope = $editor();
                    storeEditorSelection(editorScope);
                },

                // Used in the display element
                onVariableSelected($editor, selectedField) {
                    const label = selectedField.isFormula
                        ? `<#${selectedField.readableFormulaExpression || selectedField.formulaExpression}#>`
                        : `{{${selectedField.label || selectedField.name}}}`;
                    const element = `<span>${label}</span>`;

                    const editorScope = $editor();

                    // This will set the cursor to the stored cursor location (we store the cursor location when the
                    // 'action' callback is called in `taRegisterTool`), so when we will call `wrapSelection` it will
                    // place the element in the correct location.
                    reselectSelectedRange(editorScope);

                    return editorScope.wrapSelection('insertHtml', element);
                },

                // Set by using component
                isEnabled: true,
                groupId: '',
                workflowVersionId: '',
                logicId: '',
                globalTabsOnly: false,
            });

            taRegisterTool('tnkInsertLink', {
                tooltiptext: 'Insert Hyperlink',
                class: 'inline-block', // Don't leave this empty or the button will unwanted default classes 'btn btn-default'
                display:
                    '<div>' +
                    '   <tnk-html-editor-edit-link class="tonkean-expression-fields-btn"\n' +
                    '       group-id="{{groupId}}"\n' +
                    '       workflow-version-id="{{workflowVersionId}}"\n' +
                    '       logic-id="{{logicId}}"\n' +
                    '       tooltip="Insert field"\n' +
                    '       on-submit="onSubmit($editor, url, label)"\n' +
                    '   </tnk-html-editor-edit-link>' +
                    '</div>',
                // Happens when tool is clicked
                action() {
                    storeSelectedRange(this.$editor());
                },

                onSubmit: ($editor, url, label) => {
                    const element = `<a href="${url}" target="">${label && label.length ? label : url}</a>`;

                    const editorScope = $editor();
                    reselectSelectedRange(editorScope);
                    return editorScope.wrapSelection('insertHtml', element);
                },
                // On any element 'a' that is clicked
                onElementSelect: {
                    element: 'a',
                    action: (event, $element, editorScope) => {
                        modal
                            .openEditHtmlEditLinkModal(
                                editorScope.$parent.data.groupId,
                                editorScope.$parent.data.workflowVersionId,
                                editorScope.$parent.data.logicId,
                                $element.attr('href'),
                                $element.html(),
                            )
                            .then((result) => {
                                $element.attr('href', result.url);
                                $element.html(result.label);
                            });
                    },
                },

                // Set by using component
                groupId: '',
                workflowVersionId: '',
                logicId: '',
            });

            taOptions.toolbar = [
                ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'pre', 'quote'],
                ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
                ['html', 'insertLink'],
                ['wordcount', 'charcount', 'tnkInsertField'],
                // ['']
            ];

            return taOptions;
        },
    ]);
}

angular.module('tonkean.app').config(appConfig);
