import { useAngularService } from 'angulareact';
import { useEffect, useMemo } from 'react';

import { useLazyTonkeanService, useFetchProject } from '@tonkean/angular-hooks';
import { useIsDataRestricted } from '@tonkean/infrastructure';
import { useTDLWorkflowVersionByInitiativeId } from '@tonkean/tonkean-data-layer';
import type {
    FetchResults,
    FullProject,
    IncludedWidgetsSummaryByInterface,
    ItemInterface,
    ItemInterfaceWidget,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';

type InterfaceDataResult = {
    showNotFoundPage: boolean;
    showLoading: boolean;
    itemInterface?: ItemInterface;
    project: FullProject;
    workflowVersion?: WorkflowVersion;
    widgetsResponse?: FetchResults<ItemInterfaceWidget>;
    includedWidgetsSummaryByInterfaceId?: IncludedWidgetsSummaryByInterface;
};

const useGetItemInterfaceData = (
    projectId: TonkeanId<TonkeanType.PROJECT>,
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>,
    originWidget?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
    itemInterfaceId?: TonkeanId<TonkeanType.ITEM_INTERFACE>,
): InterfaceDataResult => {
    const { data: workflowVersionFromTDL, isLoading: workflowVersionFromTDLLoading } =
        useTDLWorkflowVersionByInitiativeId(projectId, initiativeId);

    const [
        { data: itemInterfaceData, loading: loadingItemInterface, error: itemInterfaceError },
        getItemInterfaceDataForDisplay,
    ] = useLazyTonkeanService('getItemInterfaceDataForDisplay');

    const { project, loadingProject, projectError } = useFetchProject(projectId);
    const projectManager = useAngularService('projectManager');

    const workflowVersionManager = useAngularService('workflowVersionManager');

    useEffect(() => {
        const getInterfaceFullData = async () => {
            if (itemInterfaceId && projectId && initiativeId && workflowVersionFromTDL) {
                const InterfaceDataResult = await getItemInterfaceDataForDisplay(
                    projectId,
                    initiativeId,
                    itemInterfaceId,
                    originWidget,
                    workflowVersionFromTDL?.id,
                    workflowVersionFromTDL?.workflowVersionType,
                );
                projectManager.addGroup(InterfaceDataResult.itemInterface?.group);
            }
        };
        getInterfaceFullData();
    }, [
        itemInterfaceId,
        projectId,
        initiativeId,
        originWidget,
        getItemInterfaceDataForDisplay,
        projectManager,
        workflowVersionFromTDL,
    ]);

    const showNotFoundPage = useIsDataRestricted([itemInterfaceError?.status, projectError]);

    const showLoading = [loadingItemInterface, loadingProject, workflowVersionFromTDLLoading].some(Boolean);

    return useMemo(() => {
        if (workflowVersionFromTDL) {
            workflowVersionManager.cacheWorkflowVersion(workflowVersionFromTDL);
        }

        return {
            showNotFoundPage,
            showLoading,
            itemInterface: itemInterfaceData?.itemInterface,
            project,
            workflowVersion: workflowVersionFromTDL,
            widgetsResponse: itemInterfaceData?.widgets,
            includedWidgetsSummaryByInterfaceId: itemInterfaceData?.includedWidgetsSummaryByInterfaceId,
        };
    }, [project, showLoading, showNotFoundPage, itemInterfaceData, workflowVersionManager, workflowVersionFromTDL]);
};

export default useGetItemInterfaceData;
