import UiPathAuthenticationComponent from './UiPathAuthenticationComponent';
import UiPathCustomizeSetupComponent from './UiPathCustomizeSetupComponent';
import uiPathCircleIcon from '../../../../apps/tracks/images/integrations/uipath-circle.png';
import uiPathIcon from '../../../../apps/tracks/images/uipath.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class UiPathIntegration extends IntegrationDefinitionBase {
    override name = 'uipath';
    override displayName = 'UiPath';
    override description = 'We will collect Folders, Releases (Processes) & Jobs (Only new Jobs will be collected).';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override externalActivityCreatedDateFieldName = 'createdate';
    override entities = [
        new IntegrationEntity('Folder', 'Folders'),
        new IntegrationEntity('Job', 'Jobs'),
        new IntegrationEntity('Release', 'Releases'),
    ];
    override customizedAuthenticationComponent = UiPathAuthenticationComponent;
    override customizedSetupComponent = UiPathCustomizeSetupComponent;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(uiPathCircleIcon, '130px'),
        new IntegrationIcon(uiPathIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Folder: true, Job: true, Release: true },
        isOnlyDataRetention: {},
    };
}

export default UiPathIntegration;
