import React from 'react';
import styled from 'styled-components';

import { ReactComponent as EmptyStateDataTable } from '../../../../../images/icons/empty_data_table.svg';

import { FontSize } from '@tonkean/tui-theme';

const EmptyStateTitle = styled.h1`
    font-size: ${FontSize.XLARGE_18};
    font-weight: 400;
    color: #9ca6b2;
`;

const EmptyStateWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const FieldMapperEmptyState: React.FC = () => {
    return (
        <EmptyStateWrapper>
            <EmptyStateDataTable />
            <EmptyStateTitle>No entities found</EmptyStateTitle>
            <span>Collect in order to see the entities and fields</span>
        </EmptyStateWrapper>
    );
};

export default FieldMapperEmptyState;
