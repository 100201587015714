/**
 * Created by ShaLi on 6/15/15.
 */
import { getUsersToMap } from '../../../../app/components/modals/mapIntegrationUsersModal/mapIntegrationUsers';

export function JiraModalCtrl(
    $scope,
    createProjectApis,
    $rootScope,
    utils,
    $q,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.temp = {};

    $scope.data = {
        usersToMap: {},
        jiraDomain: 'atlassian.net',
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.projects = state.projects;
        $scope.filteredProjects = [];
        $scope.selectedProjects = state.selectedProjects || [];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        updateProjectsList();
        tryLoadFromEdit();
    };
    /**
     * Refresh the repositories selection for each combo-box.
     */
    function updateProjectsList() {
        if ($scope.projects) {
            const projectsInSelection = new Set(
                $scope.selectedProjects.map(function (s) {
                    return s.project.value;
                }),
            );
            $scope.filteredProjects = $scope.projects.filter(function (r) {
                return !projectsInSelection.has(r.value);
            });
        }
    }

    $scope.$watch('temp.project', function () {
        $scope.addProject($scope.temp.project);
    });

    $scope.signIn = function () {
        if ($scope.data.loginModal.$valid) {
            $scope.loading = true;
            $scope.error = null;

            $scope.data.uri = `https://${$scope.data.subDomain}.${$scope.data.jiraDomain}`;

            createProjectApis
                .createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    $scope.data,
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'JIRA',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                )
                .then(function (integObj) {
                    $scope.integration = integObj;
                    return createProjectApis.getAutoCompleteOptions(
                        projectManager.project.id,
                        $scope.integration.id,
                        'projects',
                    );
                })
                .then(function (data) {
                    $scope.projects = data.options;
                    updateProjectsList();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.loading = false;
                });
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.projects = null;
        $scope.components = null;
    };

    $scope.addProject = function (project) {
        if (project) {
            $scope.addingProject = true;
            const selected = { project };
            $scope
                .getComponents(selected)
                .then(function () {
                    $scope.temp = {};
                    $scope.selectedProjects.push(selected);
                    updateProjectsList();
                })
                .finally(function () {
                    $scope.addingProject = false;
                });
        }
    };

    $scope.removeProject = function ($index) {
        $scope.selectedProjects.splice($index, 1);
        updateProjectsList();
    };

    $scope.getComponents = function (selected) {
        selected.projectComponents = null;
        selected.components = null;
        if (selected.project) {
            return createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'components', {
                    project: selected.project.value,
                })
                .then(function (data) {
                    selected.projectComponents = data.options;
                    return data.options;
                });
        }
    };

    $scope.ok = function () {
        $scope.okClicked = true;
        if ($scope.selectedProjects.length === 0) {
            return;
        }

        const s = {
            projects: $scope.projects,
            selectedProjects: $scope.selectedProjects,
            integration: $scope.integration,
        };

        s.integrations = [];
        $scope.selectedProjects.forEach(function (selected) {
            const finalInteg = {
                integration: $scope.integration,
                projectData: { project: selected.project.value },
            };
            if (selected.components && selected.components.length) {
                finalInteg.projectData.components = selected.components.map(function (c) {
                    return c.value;
                });
            }
            s.integrations.push(finalInteg);
        });

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    $scope.onUsersMapped = (usersToMap) => {
        $scope.data.usersToMap = usersToMap;
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            // Extract subdomain
            $scope.data.subDomain = /https:\/\/(.+)\.atlassian\.net/.exec(
                state.integrations[0].integration.integrationUri,
            )[1];

            // Extract user
            $scope.data.user = state.integrations[0].integration.integrationUser;

            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.projectIntegration = state.integrations[0];
            $scope.integration = state.integrations[0].integration;

            $scope.loading = true;
            $scope.error = null;
            Promise.all([
                getUsersToMap(
                    createProjectApis,
                    'externalId',
                    $scope.projectIntegration.id,
                    projectManager.project.id,
                    'jira',
                ),
                createProjectApis.getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'projects'),
            ])
                .then(([usersToMap, data]) => {
                    $scope.data.usersToMap = usersToMap;
                    $scope.projects = data.options;
                    const promises = [];

                    // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                    if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                        const originalIntegration = state.integrations[0];

                        if (
                            originalIntegration.projectData &&
                            originalIntegration.projectData.projectDatas &&
                            originalIntegration.projectData.projectDatas.length > 0
                        ) {
                            originalIntegration.projectData.projectDatas.forEach(function (projectData) {
                                const project = utils.findFirst($scope.projects, function (p) {
                                    return p.value === projectData.project;
                                });
                                const selected = { project };
                                $scope.selectedProjects.push(selected);
                                const promise = $scope.getComponents(selected).then(function (components) {
                                    if (projectData.components) {
                                        selected.components = projectData.components.map(function (c) {
                                            return utils.findFirst(components, function (component) {
                                                return c === component.value;
                                            });
                                        });
                                    }
                                });
                                promises.push(promise);
                            });
                        }
                        updateProjectsList();
                        return $q.all(promises);
                    } else {
                        console.warn(
                            'Either there is no existing project integration... Or there should not be more than one for Jira!',
                        );
                        // TODO: @Ziv @Ran, what can be returned here?
                    }
                })
                .catch(function (error) {
                    if (error.status === 500) {
                        $scope.changeUser();
                        $scope.error = 'Failed to edit integration. Please re-configure your connection to Jira';
                    } else {
                        $scope.error = error;
                    }
                })
                .finally(function () {
                    $scope.loading = false;
                });

            $scope.connecting = true;
            $scope.error = null;
        }
    }
}
export default angular.module('tonkean.shared').controller('JiraModalCtrl', JiraModalCtrl);
