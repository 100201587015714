import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function TableauProjectIntegrationConfigurationCtrl(
    $scope,
    createProjectApis,
    utils,
    projectManager,
    modal,
) {
    const ctrl = this;

    $scope.data = {
        projectIntegration: ctrl.projectIntegration,

        steps: {
            configureLoginDetails: 'configureLoginDetails',
            selectTableauView: 'selectTableauView',
            mapTableauViewColumns: 'mapTableauViewColumns',
        },
        configurationStep: null,

        editMode: false,

        integration: null,
        creatingTableauIntegration: false,
        errorCreatingTableauIntegration: null,

        tableauViews: null,
        fetchingTableauViews: false,
        errorFetchingTableauViews: null,

        selectedTableauView: null,

        tableauViewColumns: null,
        fetchingTableauColumns: false,
        errorFetchingTableauColumns: null,

        creatingOrUpdatingProjectIntegration: false,
        errorCreatingOrUpdatingProjectIntegration: null,

        titleColumnMapping: null,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.projectIntegration) {
            $scope.data.editMode = true;

            $scope.data.integration = $scope.data.projectIntegration.integration;
            $scope.data.selectedTableauView = {
                value: $scope.data.projectIntegration.projectData.projectDatas[0].selectedTableauViewId,
                displayName: $scope.data.projectIntegration.projectData.projectDatas[0].selectedTableauViewDisplayName,
            };
            $scope.data.titleColumnMapping =
                $scope.data.projectIntegration.projectData.projectDatas[0].titleColumnMapping;

            $scope.onTableauViewSelected($scope.data.selectedTableauView);

            $scope.data.configurationStep = $scope.data.steps.mapTableauViewColumns;
        } else {
            $scope.data.configurationStep = $scope.data.steps.configureLoginDetails;
        }
    };

    /**
     * Occurs once the user clicks on the cancel button.
     */
    $scope.cancel = function () {
        if (ctrl.onCancel) {
            ctrl.onCancel();
        }
    };

    /**
     * Creates the integration object for Tableau.
     */
    $scope.createTableauIntegration = function () {
        $scope.data.creatingTableauIntegration = true;
        $scope.data.errorCreatingTableauIntegration = null;

        const siteIndex = $scope.data.tableauUri.indexOf('/#/site/');

        const autoCompleteParams = {
            uri: $scope.data.tableauUri.slice(0, Math.max(0, siteIndex)),
            contentUrl: $scope.data.tableauUri.substring(
                siteIndex + 8,
                $scope.data.tableauUri.indexOf('/', siteIndex + 8),
            ),
            user: $scope.data.tableauUser,
            password: btoa($scope.data.tableauPassword),
        };

        return createProjectApis
            .createIntegration(projectManager.project.id, 'TABLEAU', autoCompleteParams, undefined, undefined)
            .then((integration) => {
                $scope.data.integration = integration;
                return getTableauViews();
            })
            .catch((error) => {
                if (error && error.data && error.data.error && error.data.error.message) {
                    $scope.data.errorCreatingTableauIntegration = error.data.error.message;
                } else {
                    $scope.data.errorCreatingTableauIntegration = 'There was an error trying to login to Tableau.';
                }
            })
            .finally(() => {
                $scope.data.creatingTableauIntegration = false;
            });
    };

    /**
     * Fetches the options for tableau views.
     */
    function getTableauViews() {
        $scope.data.fetchingTableauViews = true;
        $scope.data.errorFetchingTableauViews = null;

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.data.integration.id, 'views')
            .then((data) => {
                $scope.data.tableauViews = data.options;
                $scope.data.configurationStep = $scope.data.steps.selectTableauView;
            })
            .catch((error) => {
                if (error && error.data && error.data.error && error.data.error.message) {
                    $scope.data.errorFetchingTableauViews = error.data.error.message;
                } else {
                    $scope.data.errorFetchingTableauViews = 'There was an error trying to login to Tableau.';
                }
            })
            .finally(() => {
                $scope.data.fetchingTableauViews = false;
            });
    }

    /**
     * Occurs once you selected a view in Tableau.
     */
    $scope.onTableauViewSelected = function (view) {
        if (!view || !view.displayName) {
            return;
        }

        $scope.data.selectedTableauView = view;

        const autoCompleteObject = {
            viewId: view.value,
        };

        $scope.data.fetchingTableauColumns = true;
        $scope.data.errorFetchingTableauColumns = null;

        return createProjectApis
            .getAutoCompleteOptions(
                projectManager.project.id,
                $scope.data.integration.id,
                'columns',
                autoCompleteObject,
            )
            .then((data) => {
                $scope.data.tableauViewColumns = data.options;
                $scope.data.configurationStep = $scope.data.steps.mapTableauViewColumns;
            })
            .catch((error) => {
                if (error && error.data && error.data.error && error.data.error.message) {
                    $scope.data.errorFetchingTableauColumns = error.data.error.message;
                } else {
                    $scope.data.errorFetchingTableauColumns = 'There was an error trying to login to Tableau.';
                }
            })
            .finally(() => {
                $scope.data.fetchingTableauColumns = false;
            });
    };

    /**
     * Opens the field inspect modal.
     */
    $scope.openFieldInspectModal = function (modelKeyToUpdate) {
        analyticsWrapper.track('Toggle field inspect modal', { category: 'Tableau view selector', label: true });

        // Parse the columns to a dict so the inspector knows how to use it.
        // We pass that data as staticData - a different mode the inspector knows how to use.
        const columnsDict = parseColumnForFieldInspector($scope.data.tableauViewColumns);
        modal.openFieldInspectModal(
            null,
            null,
            null,
            (fieldName) =>
                ($scope.data[modelKeyToUpdate] = utils.findFirst(
                    $scope.data.tableauViewColumns,
                    (column) => column.displayName === fieldName,
                )),
            columnsDict,
        );
    };

    /**
     * Parses the given columns array to a dictionary for the field inspector.
     */
    function parseColumnForFieldInspector(columns) {
        const columnsDict = {
            data: {},
            displayNameMap: {},
        };

        if (columns) {
            for (const column of columns) {
                if (!columnsDict.data[column.displayName]) {
                    columnsDict.data[column.displayName] = column.example;
                }
            }
        }

        return columnsDict;
    }

    /**
     * Creates or updates the project integration for the tableau integration.
     */
    $scope.createOrUpdateProjectIntegration = function () {
        $scope.data.creatingOrUpdatingProjectIntegration = true;
        $scope.data.errorCreatingOrUpdatingProjectIntegration = null;

        $scope.data.integration.projectDatas = [
            {
                selectedTableauViewId: $scope.data.selectedTableauView.value,
                selectedTableauViewDisplayName: $scope.data.selectedTableauView.displayName,
                titleColumnMapping: $scope.data.titleColumnMapping,
            },
        ];

        let projectIntegrationPromise;
        const oldProject = projectManager.project;
        if ($scope.data.editMode) {
            projectIntegrationPromise = createProjectApis.editProjectIntegration(
                projectManager.project.id,
                $scope.data.projectIntegration.id,
                $scope.data.integration,
                $scope.data.selectedTableauView.displayName,
            );
        } else {
            projectIntegrationPromise = createProjectApis.createProjectIntegration(
                projectManager.project,
                $scope.data.integration,
                $scope.data.selectedTableauView.displayName,
            );
        }

        return projectIntegrationPromise
            .then((updatedProject) => {
                // Getting the updated project integration from the project.
                $scope.data.projectIntegration = utils.findFirst(
                    updatedProject.integrations,
                    (prin) =>
                        prin.id === $scope?.data?.projectIntegration?.id ||
                        !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
                );

                if (ctrl.onProjectIntegrationCreated) {
                    ctrl.onProjectIntegrationCreated({
                        projectIntegration: $scope.data.projectIntegration,
                    });
                }
            })
            .catch((error) => {
                if (error && error.data && error.data.error && error.data.error.message) {
                    $scope.data.errorCreatingOrUpdatingProjectIntegration = error.data.error.message;
                } else {
                    $scope.data.errorCreatingOrUpdatingProjectIntegration =
                        'There was an error trying to login to Tableau.';
                }
            })
            .finally(() => {
                $scope.data.creatingOrUpdatingProjectIntegration = false;
            });
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'TableauProjectIntegrationConfigurationCtrl',
        lateConstructController(TableauProjectIntegrationConfigurationCtrl),
    );
