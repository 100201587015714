import { useAngularService } from 'angulareact';
import { Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as HallowDataSourceIcon } from '../../../../../images/icons/hollow-data-source.svg';
import { ProjectIntegrationEntityTabKey } from '../../../../components/state.product.projectIntegrationPageEntity/ProjectIntegrationEntityTabKey';
import ProjectIntegrationEntitySchema from '../../entities/ProjectIntegrationEntitySchema';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Field,
    Input,
    Modal,
    ModalBody,
    ModalFooterActions,
    ModalHeader,
    ModalSize,
    Textarea,
    XCloseButton,
} from '@tonkean/infrastructure';

const StyledField = styled(Field)`
    &:not(:last-child) {
        margin-bottom: 30px;
    }
`;

const HeaderText = styled.div`
    margin-left: 10px;
`;

interface Props {
    projectIntegrationId: string;
    isOpen: boolean;
    closeModal(): void;
}

const ProjectIntegrationCreateEntityModal: React.FC<Props> = ({ projectIntegrationId, isOpen, closeModal }) => {
    const [{ loading: createEntityLoading, error: createEntityError }, createProjectIntegrationEntity] =
        useLazyTonkeanService('createProjectIntegrationEntity');

    // $state Angular service.
    const $state = useAngularService('$state');
    const integrationMetadataManager = useAngularService('integrationMetadataManager');

    // Navigation to entity single page view.
    const navigateToCreatedEntity = (entityId: string) => {
        $state.go('product.projectIntegrationPageEntity', {
            entityId,
            tab: ProjectIntegrationEntityTabKey.GENERAL,
            enterpriseComponentId: projectIntegrationId,
        });
    };

    // On create new entity.
    const onSubmit = async (displayName: string, description: string | undefined) => {
        const entityIdObject = await createProjectIntegrationEntity(projectIntegrationId, displayName, description);
        // Remove current cache of all entities metadata in this project integration.
        delete integrationMetadataManager.projectIntegrationIdToEntitiesMap[projectIntegrationId];
        navigateToCreatedEntity(entityIdObject.createdEntityId);
    };

    return (
        <Modal size={ModalSize.SMALL} onClose={closeModal} open={isOpen} fixedWidth>
            <ModalHeader $padding={11} $shadow $flex>
                <HallowDataSourceIcon />
                <HeaderText>Create Entity</HeaderText>
                <XCloseButton onClick={closeModal} autoMarginLeft />
            </ModalHeader>

            <Formik
                initialValues={{
                    displayName: '',
                    description: '',
                }}
                onSubmit={(values) => {
                    return onSubmit(values.displayName, values.description);
                }}
                validationSchema={ProjectIntegrationEntitySchema}
            >
                <Form>
                    <ModalBody>
                        <StyledField label="Entity display name">
                            <Input
                                placeholder="Insert Name"
                                name="displayName"
                                data-automation="project-integration-create-entity-modal-display-name"
                            />
                        </StyledField>

                        <StyledField label="Description" showOptional>
                            <Textarea minRows={3} placeholder="Insert Description" name="description" />
                        </StyledField>
                    </ModalBody>

                    <ModalFooterActions
                        saveLabel="Next"
                        onCancel={closeModal}
                        error={createEntityError}
                        loading={createEntityLoading}
                    />
                </Form>
            </Formik>
        </Modal>
    );
};

export default ProjectIntegrationCreateEntityModal;
