import DatadogAuthenticationComponent from './DatadogAuthenticationComponent';
import datadogImage from '../../../../apps/tracks/images/datadog.png';
import datadogCircleImage from '../../../../apps/tracks/images/integrations/datadog-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class DatadogIntegration extends IntegrationDefinitionBase {
    override name = 'datadog';
    override displayName = 'Datadog';
    override description = 'We only collect Datadog Incidents';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override supportsMultipleIntegrationPerUser = true;

    override entities = [new IntegrationEntity('Event', 'Events'), new IntegrationEntity('Incident', 'Incidents')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(datadogCircleImage, '130px'),
        new IntegrationIcon(datadogImage, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Events: true, Incidents: true },
        isOnlyDataRetention: {},
    };

    override customizedAuthenticationComponent = DatadogAuthenticationComponent;
}

export default DatadogIntegration;
