<div class="org-members org-content-page mod-no-padding flex-no-xs members" ng-if="!pm.isExpired || !pm.project.plan">
    <!-- Full users container -->
    <div class="flex-grow org-content-padded org-members-content-container">
        <!-- Title -->
        <div class="flex-vmiddle flex-xs-wrap">
            <h2 class="common-title flex-grow flex-vmiddle margin-top-none margin-right margin-bottom-xs">
                Users&nbsp;
                <i class="loading-small hidden-xs" ng-show="loadingPeople && !initLoading"></i>
            </h2>
            <button
                type="button"
                class="btn btn-primary margin-bottom-xs"
                ng-if="!license || pm.project.isLimitedLicense"
                ui-sref="product.settings.license({tab:'overview'})"
            >
                Upgrade your account
            </button>
        </div>

        <hr class="common-separator" />
        <div class="text-center common-page-load" ng-if="initLoading">
            <i class="loading-full-circle"></i>
        </div>

        <!-- Subtitle and text -->
        <div class="common-size-xs common-bold flex-vmiddle" ng-hide="initLoading">
            <div class="margin-right-lg flex-no-shrink">Current {{ data.userLabel }}s ({{ totalPeople }})</div>
            <button
                type="button"
                class="btn btn-default btn-sm"
                ng-click="data.showAddUsers = true"
                ng-if="
                    (pm.isBuyer || pm.isOwner) &&
                    lps.supportsAssignableSeats() &&
                    (!plan.maximumQuantity || (plan.maximumQuantity && totalPeople < plan.maximumQuantity))
                "
            >
                + Add {{ data.userLabel }}s
            </button>
            <div class="flex-grow"></div>
            <div
                class="margin-left flex-no-shrink common-size-xxs common-tag-full org-members-seats-counter"
                ng-if="lps.supportsAssignableSeats()"
            >
                <span
                    ng-style="{
                        width: totalPeople / plan.quantity > 1 ? '100%' : (totalPeople / plan.quantity) * 100 + '%'
                    }"
                ></span>
                <span>{{ totalPeople }}/{{ plan.quantity }} seats</span>
            </div>
        </div>
        <div
            ng-if="!initLoading && !lps.supportsAssignableSeats()"
            class="common-size-xxs common-color-light-grey margin-top-xs"
        >
            These users have logged in to Tonkean
            <span ng-if="pm.project.expirationDate">
                &nbsp;and are the most likely to be your
                <br class="hidden-xs" />
                {{ data.userLabel }}s when you purchase a paid plan
            </span>
            :
        </div>

        <!-- Add full users form -->
        <div ng-if="data.showAddUsers || (!initLoading && !totalPeople)" class="margin-top-lg">
            <hr />
            <form name="ownersForm" ng-submit="addFullUsers()">
                <h4 class="common-size-xs common-bold">Add {{ data.userLabel }}s:</h4>
                <div class="flex mod-align-start flex-xs-wrap">
                    <tnk-deprecated-people-selector
                        class="flex-grow margin-right-xs margin-bottom-xs"
                        no-validation="false"
                        exclude-me="false"
                        selected-people="peopleToAdd"
                        options="{onlyNotLicensed: true}"
                        max-tags="{{ !pm.isBuyer ? plan.quantity - totalPeople : '' }}"
                        placeholder="Type a name or email"
                        show-intro="true"
                    ></tnk-deprecated-people-selector>
                    <button
                        type="button"
                        class="btn btn-default margin-right-xs margin-bottom-xs flex-no-shrink"
                        ng-click="data.showAddUsers = false"
                        ng-disabled="savingOwners"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary margin-bottom-xs flex-no-shrink"
                        ng-disabled="!ownersForm.$valid || savingOwners"
                    >
                        Save
                    </button>
                </div>
                <div
                    class="common-size-xxs common-color-dark-grey margin-top"
                    ng-if="pm.isLicensed && !pm.isBuyer && plan.quantity - totalPeople - peopleToAdd.length <= 0"
                >
                    * {{ plan.quantity - totalPeople === 0 ? 'No more available seats left. You' : 'If needed, you' }}
                    can ask
                    <i class="common-underline">{{ pm.project.license.buyer.name }}</i>
                    to add more seats.
                </div>
            </form>
        </div>

        <!-- if has license but still in trial-->
        <div
            ng-if="lps.isV1TrialLicensed()"
            ng-hide="initLoading || data.showAddUsers"
            class="alert alert-info margin-top-lg flex"
        >
            <div><i class="fa fa-info-circle margin-right"></i></div>
            <div>
                You are currently still in the free trial period, which includes
                <strong>unlimited Full Users</strong>
                . Once the paid plan begins ({{ pm.project.expirationDate | daysDiffInSentence }}) only the following
                will have full access. Click on
                <a ng-click="data.showAddUsers = true">Add Full Users</a>
                to add more.
            </div>
        </div>

        <table class="table margin-top-xlg" ng-hide="!fullUsers || !fullUsers.length || initLoading">
            <thead>
                <tr>
                    <th>
                        <a
                            ng-click="sort('NAME')"
                            class="
                                inline-block
                                flex-vmiddle
                                common-link-no-style common-color-light-grey2 common-bold
                                margin-right-xxs
                            "
                        >
                            <span class="margin-right-xxs">Name</span>
                            <i class="svg-icon-xs svg-icon-align-text-top" ng-show="sortField === 'NAME' && sortDesc">
                                <tnk-icon src="/images/icons/arrow-up.svg"></tnk-icon>
                            </i>
                            <i class="svg-icon-xs svg-icon-align-text-top" ng-show="sortField === 'NAME' && !sortDesc">
                                <tnk-icon src="/images/icons/arrow-down.svg"></tnk-icon>
                            </i>
                        </a>
                        <i class="loading visible-xs-inline-block" ng-show="loadingPeople && !initLoading"></i>
                    </th>
                    <th>
                        <a
                            ng-click="sort('EMAIL')"
                            class="flex-vmiddle common-link-no-style common-color-light-grey2 common-bold"
                        >
                            <span class="margin-right-xxs">Email</span>
                            <i class="svg-icon-xs svg-icon-align-text-top" ng-show="sortField === 'EMAIL' && sortDesc">
                                <tnk-icon src="/images/icons/arrow-up.svg"></tnk-icon>
                            </i>
                            <i class="svg-icon-xs svg-icon-align-text-top" ng-show="sortField === 'EMAIL' && !sortDesc">
                                <tnk-icon src="/images/icons/arrow-down.svg"></tnk-icon>
                            </i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr ng-repeat="user in fullUsers">
                    <td>
                        <div class="flex-vmiddle">
                            <tnk-avatar user="user" class="margin-right"></tnk-avatar>
                            {{ user.name }}
                        </div>
                    </td>
                    <td>
                        <div class="flex-vmiddle">
                            <div class="flex-grow common-break-long">{{ user.email }}</div>

                            <div
                                class="dropbox flex-no-shrink"
                                uib-dropdown
                                on-toggle="toggleUserOptions[user.id] = open"
                                ng-if="
                                    user.id !== currentUser.id &&
                                    user.id !== pm.masterOwner.id &&
                                    lps.supportsAssignableSeats() &&
                                    (pm.isBuyer || pm.isOwner)
                                "
                            >
                                <span
                                    class="svg-icon-smd svg-icon-hover-dark-grey pointer dropdown-toggle"
                                    uib-dropdown-toggle
                                >
                                    <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                                </span>
                                <ul
                                    uib-dropdown-menu
                                    class="dropdown-menu-right"
                                    role="menu"
                                    ng-if="toggleUserOptions[user.id]"
                                >
                                    <li ng-class="{ disabled: removeInProgress[user.id] }">
                                        <a ng-click="!removeInProgress[user.id] && removeUser(user)">
                                            <i class="loading-small" ng-show="removeInProgress[user.id]"></i>
                                            Remove user
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-center" ng-show="searchTotal > pageSize">
            <uib-pagination
                total-items="searchTotal"
                items-per-page="pageSize"
                ng-model="currentPage"
                class="pagination-sm"
                max-size="7"
                force-ellipses="true"
                previous-text="&lsaquo;"
                next-text="&rsaquo;"
            ></uib-pagination>
        </div>
    </div>
    <!-- Side bar place holder (since it's absolute positioned) -->
    <div class="org-side-bar-blue mod-placeholder" ng-if="lps.isPricingVersion(lps.pricingVersions.V1)"></div>
    <!-- Side bar -->
    <div class="org-side-bar-blue" ng-if="lps.isPricingVersion(lps.pricingVersions.V1)">
        <!-- Trial/Free mode -->
        <div ng-if="!license">
            <!-- Trial title -->
            <h3 class="common-title-lg mod-light margin-none">
                You are on the
                {{
                    pm.project.isInTrial
                        ? consts.trialDaysLength + ' day trial'
                        : !pm.project.isEnterprise
                        ? 'Free plan'
                        : 'Enterprise plan'
                }}
            </h3>
            <h3 class="common-title-lg mod-light mod-primary margin-top" ng-if="pm.project.isInTrial">
                {{ sharedData.trialDaysLeft }} days remaining
            </h3>
            <!-- Message -->
            <div ng-if="pm.project.expirationDate">
                <div class="common-title-font common-size-xs margin-top-lg">
                    Upgrade your account to ensure continuous access to Tonkean and to manage the list of your
                    <strong>Full users</strong>
                    .
                    <br />
                    <span class="common-size-xxs" ng-if="pm.project.isInTrial">
                        (the remaining days of your trial will stay free)
                    </span>
                </div>
                <button
                    type="button"
                    ui-sref="product.settings.license({tab:'overview'})"
                    class="btn btn-primary-black margin-top-lg common-title-font common-size-xs"
                >
                    Upgrade your account
                </button>
            </div>
        </div>

        <!-- Licensed mode -->
        <div ng-if="license" class="common-size-xs common-title-font">
            <h3>You are currently on the {{ plan.name }} plan</h3>
            <br />
            <span>Number of seats: {{ plan.quantity }}</span>
        </div>

        <!-- Free users -->
        <div
            ng-if="!loadingFreeMembers && data.freeMembers && data.freeMembers.length"
            class="org-free-members-box"
            ng-class="{ 'mod-active': !loadingFreeMembers }"
        >
            <hr class="common-separator-dark-grey margin-top-xlg margin-bottom-xlg" />
            <h4 class="common-title-sm mod-light">+ {{ data.freeMembers.length }} Free users</h4>
            <p class="common-color-light-grey2 common-size-xxs">
                The following team members can only use the light version of Tonkean, free of charge:
            </p>
            <div
                class="margin-top-lg org-free-members-avatars"
                ng-class="{ 'mod-overflow': data.freeMembers.length > 48 }"
            >
                <span ng-repeat="freeMember in freeMembers = (data.freeMembers | limitTo: 48) track by freeMember.id">
                    <tnk-avatar class="org-member-avatar" user="freeMember"></tnk-avatar>
                </span>
            </div>
        </div>
    </div>
</div>
