<div class="bot-off-banner flex-vmiddle mod-justify-center">
    <div>
        You have turned off the A.I Module Follow ups. It can't reach you when colleagues and Triggers need your
        attention.
    </div>
    <a
        class="
            common-color-danger common-hover-danger-light common-underline
            flex-no-shrink
            margin-left margin-right-lg
        "
        ng-click="turnBotMessagesOn()"
    >
        Turn it ON
    </a>

    <!-- Loading circle -->
    <i class="loading-small" ng-if="data.loadingTurningBotMessagesOn"></i>

    <!-- Error -->
    <span class="common-size-xxxxs common-color-danger" ng-if="data.errorLoadingTurningBotMessagesOn">
        Error trying to set Tonkean Module on.
        <a onClick="turnBotMessagesOn()">Retry</a>
    </span>

    <div class="common-close-btn mod-xs" ng-click="close()">
        <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </div>
</div>
