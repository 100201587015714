import React from 'react';
import styled from 'styled-components';

import { H4, TextEllipsis } from '@tonkean/infrastructure';
import type { EnterpriseComponentVariable } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px;
    position: sticky;
    top: 0;
`;

const Divider = styled.hr`
    margin: 0 16px 16px;
`;

const SingleVariableDescriptionWrapper = styled.div`
    padding: 0 16px 8px;
    height: 80px;
`;

const SingleVariableFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
    border-top: 1px solid ${Theme.colors.gray_300};
`;

const StyledClickableLink = styled(ClickableLink)`
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    font-weight: 500;
`;

const EmptyState = styled.div`
    color: ${Theme.colors.gray_600};
`;

interface Props {
    variable: EnterpriseComponentVariable;
    onEdit: (variable: EnterpriseComponentVariable) => void;
    onDelete: (variable: EnterpriseComponentVariable) => void;
    canUpdateOrDelete: boolean;
}

const EnterpriseComponentSingleVariableComponent: React.FC<Props> = ({
    variable,
    onEdit,
    onDelete,
    canUpdateOrDelete,
}) => {
    const onClickEdit = () => onEdit(variable);
    const onClickDelete = () => onDelete(variable);

    return (
        <>
            <HeaderSection>
                <H4 $bold>
                    <TextEllipsis numberOfLines={1} tooltip>
                        {variable.displayName}
                    </TextEllipsis>
                </H4>
            </HeaderSection>

            <Divider />

            <SingleVariableDescriptionWrapper>
                {variable.description ? (
                    <TextEllipsis numberOfLines={5} tooltip>
                        <div>{variable.description}</div>
                    </TextEllipsis>
                ) : (
                    <EmptyState>No Description</EmptyState>
                )}
            </SingleVariableDescriptionWrapper>

            {canUpdateOrDelete && (
                <SingleVariableFooter>
                    <StyledClickableLink onClick={onClickEdit}>Edit</StyledClickableLink>

                    <StyledClickableLink onClick={onClickDelete}>Delete</StyledClickableLink>
                </SingleVariableFooter>
            )}
        </>
    );
};

export default EnterpriseComponentSingleVariableComponent;
