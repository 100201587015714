import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useGetIntakeInitiative from './hooks/useGetIntakeInitiative';
import { INTAKE_ITEM_INTERFACEC_CONSTS } from './IntakeItemInterfaceConsts';
import IntakeItemInterfaceWrapper from './IntakeItemInterfaceWrapper';
import IntakeThankYouPage from './IntakeThankYouPage/IntakeThankYouPage';
import ItemInterfaceInSequence from './InterfacesSequence/ItemInterfaceInSequence';
import type ThankYouPageData from '../../entities/ThankYouPageData';
import { useItemInterfaceContextDataBuilder } from '../../hooks';
import useGetItemInterfaceData from '../../SystemInterface/hooks/useGetItemInterfaceData';
import usePollInitiativeData from '../../SystemInterface/hooks/usePollInitiativeData';
import useGetWidgetsToDisplay from '../../widgets/hooks/useGetWidgetsToDisplay';

import { useGetStateParams, useTonkeanService } from '@tonkean/angular-hooks';
import {
    ItemInterfaceContext,
    ItemInterfaceFieldDefinitionSummaryContext,
    ItemInterfaceSection,
    NotFoundPage,
    useIsDataRestricted,
    useOrBuildItemInterfaceFieldDefinitionSummaryContext,
} from '@tonkean/infrastructure';
import LoadingTonkean from '@tonkean/infrastructure/components/LoadingTonkean';
import { PluggableActionType } from '@tonkean/tonkean-entities';
import type { ItemInterfaceWidget, TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { SequenceState } from '@tonkean/tonkean-entities';

const addInitiativeIdToUrl = (newInitiativeId: TonkeanId<TonkeanType.INITIATIVE>) => {
    const urlParams = new URLSearchParams();
    urlParams.set('initiativeId', newInitiativeId);
    const currentUrl = window.location.href;
    const updatedUrl = `${currentUrl.split('?')[0]}?${urlParams.toString()}`;
    window.history.pushState({ path: updatedUrl }, '', updatedUrl);
};

const IntakeItemInterface: React.FC = () => {
    const [initiativeId, workflowVersionType, projectId, intakeCustomTriggerId, initialValues, conversationId] =
        useGetStateParams<
            [
                TonkeanId<TonkeanType.INITIATIVE>,
                WorkflowVersionType,
                TonkeanId<TonkeanType.PROJECT>,
                TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
                string | undefined,
                string | undefined,
            ]
        >('initiativeId', 'workflowVersionType', 'projectId', 'customTriggerId', 'initialValues', 'conversationId');

    const {
        data: intakeCustomTriggerDetails,
        error: errorIntakeCustomTriggerDetails,
        loading: loadingIntakeCustomTriggerDetails,
    } = useTonkeanService('getIntakeCustomTriggerByWorkflowVersionType', intakeCustomTriggerId, workflowVersionType);

    const { initiative, loadingInitatiative, errorInitiative } = useGetIntakeInitiative(
        projectId,
        workflowVersionType,
        intakeCustomTriggerDetails?.id,
        intakeCustomTriggerDetails?.disabled,
        initiativeId,
        initialValues,
        conversationId,
    );

    useEffect(() => {
        if (initiative?.id && !initiativeId) {
            addInitiativeIdToUrl(initiative.id);
        }
    }, [initiative, initiativeId]);

    const [currentCustomTriggerId, setCurrentCustomTriggerId] =
        useState<TonkeanId<TonkeanType.CUSTOM_TRIGGER>>(intakeCustomTriggerId);

    const onSwitchedInterfaceInternally = useCallback(
        (newCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => {
            setCurrentCustomTriggerId(newCustomTriggerId);
        },
        [setCurrentCustomTriggerId],
    );

    const {
        showNotFoundPage: showNotFoundPageInitial,
        showLoading: showLoadingInitial,
        itemInterface,
        project,
        workflowVersion,
        widgetsResponse,
        includedWidgetsSummaryByInterfaceId,
    } = useGetItemInterfaceData(
        projectId,
        initiativeId || initiative?.id,
        undefined,
        intakeCustomTriggerDetails?.itemInterfaceId,
    );

    const [currentSequenceState, setCurrentSequenceState] = useState<SequenceState | undefined>();
    const currentItemInterface = currentSequenceState?.itemInterface || itemInterface;
    const currentWorkflowVersion = currentSequenceState?.workflowVersion || workflowVersion;

    const {
        polledInitiative,
        error: pollInitiativeError,
        reloadInitiativeFromServer,
        applyFastReloadInterval,
        setPollInitiativeEnabled,
    } = usePollInitiativeData(
        projectId,
        (currentSequenceState?.initiative || initiative)?.id,
        currentItemInterface?.id,
    );

    const widgets = currentSequenceState?.widgets || widgetsResponse?.entities;

    const widgetsToDisplay: ItemInterfaceWidget[] = useGetWidgetsToDisplay(
        widgets as ItemInterfaceWidget[],
        polledInitiative,
    );

    const pluggableActionsToShowSettings: PluggableActionType[] = useMemo(() => {
        const pluggableActionTypeList: PluggableActionType[] = [];
        if (intakeCustomTriggerDetails?.showCollaborationPopoverConcierge) {
            pluggableActionTypeList.push(PluggableActionType.CONCIERGE);
        }
        if (intakeCustomTriggerDetails?.showCollaborationPopoverComments) {
            pluggableActionTypeList.push(PluggableActionType.COLLABORATION);
        }
        return pluggableActionTypeList;
    }, [
        intakeCustomTriggerDetails?.showCollaborationPopoverConcierge,
        intakeCustomTriggerDetails?.showCollaborationPopoverComments,
    ]);

    // Initiative = initial initiative loading in the interface; ItemInterfaceData = the initiative from polling (most updated initiative data).
    const currentInitiative = polledInitiative || initiative;

    const itemInterfaceContextValue = useItemInterfaceContextDataBuilder({
        initiative: currentInitiative,
        initiativeInitialLoading: false,
        intakeInitiative: initiative,
        itemInterface: currentItemInterface,
        section: ItemInterfaceSection.MAIN,
        workflowVersion: currentWorkflowVersion,
        intakeWorkflowVersion: workflowVersion,
        includedWidgetsSummaryByInterfaceId,
        originatedCustomTriggerId: intakeCustomTriggerId,
        currentCustomTriggerId,
        widgetsToDisplay: widgetsToDisplay || [],
        allInterfaceWidgets: (widgets || []) as ItemInterfaceWidget[],
        itemInterfaceContextDataError: pollInitiativeError,
        reloadInitiativeFromServer,
        applyFastReloadInterval,
        pluggableActionsToShowSettings,
        currentSequenceState: currentItemInterface
            ? {
                  initiative: currentInitiative,
                  itemInterface: currentItemInterface,
                  workflowVersion: currentWorkflowVersion,
                  widgets: widgets as ItemInterfaceWidget[],
                  isLastInSequence:
                      currentSequenceState?.isLastInSequence ?? intakeCustomTriggerDetails?.isLastInSequence,
                  customTriggerId: currentCustomTriggerId,
                  evaluatedIntakeSequenceButtonLabel:
                      intakeCustomTriggerId === currentCustomTriggerId
                          ? intakeCustomTriggerDetails?.submitButtonCustomLabel
                          : currentSequenceState?.evaluatedIntakeSequenceButtonLabel,
              }
            : undefined,
        setCurrentSequenceState,
        setPollInitiativeEnabled,
    });

    const showNotFoundPage =
        useIsDataRestricted([errorInitiative?.status, errorIntakeCustomTriggerDetails?.status]) ||
        showNotFoundPageInitial;

    const themeColor = itemInterface?.themeConfiguration?.primaryColor || project?.themeConfiguration?.primaryColor;

    const fieldDefinitionSummaryContext = useOrBuildItemInterfaceFieldDefinitionSummaryContext();

    if (showNotFoundPage) {
        return (
            <NotFoundPage
                text="Oops, we can't find that page"
                dataAutomation={INTAKE_ITEM_INTERFACEC_CONSTS.INTAKE_ITEM_INTERFACE_NOT_FOUND_DATA_AUTOMATION}
            />
        );
    }

    if (
        [showLoadingInitial, loadingInitatiative, loadingIntakeCustomTriggerDetails].some(Boolean) ||
        itemInterface === undefined ||
        currentItemInterface === undefined ||
        project === undefined ||
        workflowVersion === undefined ||
        currentWorkflowVersion === undefined ||
        widgetsResponse === undefined ||
        initiative === undefined
    ) {
        return <LoadingTonkean />;
    }

    if (intakeCustomTriggerDetails?.disabled) {
        const disabledThankYouPageData: ThankYouPageData = {
            isDisabledMode: true,
        };
        return (
            <ItemInterfaceFieldDefinitionSummaryContext.Provider value={fieldDefinitionSummaryContext}>
                <ItemInterfaceContext.Provider value={itemInterfaceContextValue}>
                    <IntakeItemInterfaceWrapper
                        projectThemeConfiguration={project.themeConfiguration}
                        forceThemeConfiguration={project.forceThemeConfiguration}
                        workflowVersionType={workflowVersionType}
                        workflowVersionId={intakeCustomTriggerDetails.workflowVersionId}
                        currentCustomTriggerId={currentCustomTriggerId}
                        projectUrlSlug={project.urlSlug}
                        initiative={currentInitiative}
                        projectId={project.id}
                        itemInterfaceId={currentItemInterface.id}
                        itemInterfaceWidgetIds={widgetsToDisplay.map((widget) => widget.id)}
                    >
                        <IntakeThankYouPage thankYouPageData={disabledThankYouPageData} color={themeColor} />
                    </IntakeItemInterfaceWrapper>
                </ItemInterfaceContext.Provider>
            </ItemInterfaceFieldDefinitionSummaryContext.Provider>
        );
    }

    return (
        <ItemInterfaceFieldDefinitionSummaryContext.Provider value={fieldDefinitionSummaryContext}>
            <ItemInterfaceContext.Provider value={itemInterfaceContextValue}>
                <IntakeItemInterfaceWrapper
                    projectThemeConfiguration={project.themeConfiguration}
                    forceThemeConfiguration={project.forceThemeConfiguration}
                    itemInterfaceThemeConfiguration={itemInterface.themeConfiguration}
                    workflowVersionType={workflowVersionType}
                    workflowVersionId={workflowVersion.id}
                    currentCustomTriggerId={currentCustomTriggerId}
                    projectUrlSlug={project.urlSlug}
                    initiative={currentInitiative}
                    projectId={project.id}
                    itemInterfaceId={currentItemInterface.id}
                    itemInterfaceWidgetIds={widgetsToDisplay?.map((widget) => widget.id)}
                >
                    <ItemInterfaceInSequence
                        itemInterface={currentItemInterface}
                        workflowVersion={currentWorkflowVersion}
                        widgets={widgetsResponse}
                        project={project}
                        showBackButton={false}
                        buttonColor={themeColor}
                        onSwitchedToNextInterfaceInternally={onSwitchedInterfaceInternally}
                        onSwitchedToPrevInterfaceInternally={onSwitchedInterfaceInternally}
                        setCurrentSequenceState={setCurrentSequenceState}
                    />
                </IntakeItemInterfaceWrapper>
            </ItemInterfaceContext.Provider>
        </ItemInterfaceFieldDefinitionSummaryContext.Provider>
    );
};

export default IntakeItemInterface;
