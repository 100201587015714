<div class="margin-bottom">
    <div class="common-title-xxxs margin-bottom-xs">Recipients:</div>
    <!-- Recipients -->
    <tnk-person-selection-configuration
        on-person-selection-configuration-changed="
            onRecipientsConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
        "
        saved-person-selection-configuration="data.savedRecipientsConfiguration"
        uniqe-identifier="{{ data.identifier }}-recipients-selector"
        radio-id-prefix="'{{ data.identifier }}-recipients-selector' + data.workflowVersionId"
        class="block margin-top-xs"
        configured-logic="data.configuredLogic"
        preview-evaluation-source="data.previewEvaluationSource"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        from-field-tooltip="Will accept multiple values separated by a comma. Person name should be written inside parenthesis, for example: JohnB@tonkean.com(John)"
        default-selected-option="fromField"
        show-send-as-group-message="false"
        show-from-field-tooltip="true"
        do-not-evaluate-preview="true"
        hide-fail-if-no-value="true"
        support-quick-create="true"
        show-send-by="false"
        hide-preview="false"
    ></tnk-person-selection-configuration>

    <!-- Validation error -->
    <div ng-if="data.validationObject.noToRecipients" class="margin-top-xs margin-bottom-xs common-color-danger">
        {{ data.validationObject.noToRecipients }}
    </div>
    <div ng-if="data.validationObject.emptyToRecipient" class="margin-top-xs margin-bottom-xs common-color-danger">
        {{ data.validationObject.emptyToRecipient }}
    </div>
</div>

<div class="margin-bottom-xs common-size-xxxs">
    <a ng-if="!data.showCC" ng-click="data.showCC = true">+ Add CC</a>
</div>

<!-- Email cc (address and name) -->
<div ng-if="data.showCC" class="margin-bottom">
    <div class="common-title-xxxs margin-bottom-xs">CC:</div>
    <!-- Email CC -->
    <tnk-person-selection-configuration
        on-person-selection-configuration-changed="
            onCCConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
        "
        saved-person-selection-configuration="data.savedCCConfiguration"
        uniqe-identifier="{{ data.identifier }}-cc-selector"
        radio-id-prefix="'{{ data.identifier }}-cc-selector' + data.workflowVersionId"
        class="block margin-top-xs"
        preview-evaluation-source="data.previewEvaluationSource"
        configured-logic="data.configuredLogic"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        from-field-tooltip="Will accept multiple values separated by a comma. Person name should be written inside parenthesis, for example: JohnB@tonkean.com(John)"
        default-selected-option="fromField"
        show-send-as-group-message="false"
        show-from-field-tooltip="true"
        do-not-evaluate-preview="true"
        hide-fail-if-no-value="true"
        support-quick-create="true"
        show-send-by="false"
        hide-preview="false"
    ></tnk-person-selection-configuration>

    <!-- Validation error -->
    <div ng-if="data.validationObject.emptyCcRecipient" class="margin-top-xs margin-bottom-xs common-color-danger">
        {{ data.validationObject.emptyCcRecipient }}
    </div>
</div>

<div class="margin-bottom common-size-xxxs">
    <a ng-if="!data.showBCC" ng-click="data.showBCC = true">+ Add BCC</a>
</div>

<!-- Email bcc (address and name) -->
<div ng-if="data.showBCC" class="margin-bottom">
    <div class="common-title-xxxs margin-bottom-xs">BCC:</div>
    <!-- Email BCC -->
    <tnk-person-selection-configuration
        from-field-tooltip="Will accept multiple values separated by a comma. Person name should be written inside parenthesis, for example: JohnB@tonkean.com(John)"
        on-person-selection-configuration-changed="
            onBCCConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
        "
        saved-person-selection-configuration="data.savedBCCConfiguration"
        configured-logic="data.configuredLogic"
        preview-evaluation-source="data.previewEvaluationSource"
        uniqe-identifier="{{ data.identifier }}-bcc-selector"
        radio-id-prefix="'{{ data.identifier }}-bcc-selector' + data.workflowVersionId"
        class="block margin-top-xs"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        default-selected-option="fromField"
        show-send-as-group-message="false"
        show-from-field-tooltip="true"
        do-not-evaluate-preview="true"
        hide-fail-if-no-value="true"
        support-quick-create="true"
        show-send-by="false"
        hide-preview="false"
    ></tnk-person-selection-configuration>

    <!-- Validation error -->
    <div ng-if="data.validationObject.emptyBccRecipient" class="margin-top-xs margin-bottom-xs common-color-danger">
        {{ data.validationObject.emptyBccRecipient }}
    </div>
</div>
