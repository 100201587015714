<div class="margin-left margin-top">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="!data.loading">
        <!-- Create Presentation from template -->
        <div ng-if="data.definition.customActionKey === 'CREATE_PRESENTATION_FROM_TEMPLATE'">
            <!-- Destination Folder -->
            <div>
                <div class="margin-bottom-xs">Destination Folder ID:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="Destination Folder ID"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-expression="data.definition.destinationFolderIdExpression"
                    expression-unique-identifier="GoogleDriveFolderDestination"
                    on-tonkean-expression-changed="
                        onDestinationFolderIdTonkeanExpressionChanged(expression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>
            <div class="margin-top-6 common-error" ng-if="data.validationObject.destinationFolderError">
                {{ data.validationObject.destinationFolderError }}
            </div>

            <!-- New Name -->
            <div class="margin-top">
                <div class="margin-bottom-xs">New Presentation Name:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="New Presentation Name"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-expression="data.definition.newPresentationNameExpression"
                    expression-unique-identifier="NewPresentationName"
                    on-tonkean-expression-changed="
                        onNewPresentationNameTonkeanExpressionChanged(expression, shouldSaveLogic)
                    "
                ></tnk-tonkean-expression>
            </div>
            <div class="margin-top-6 common-error" ng-if="data.validationObject.newPresentationNameError">
                {{ data.validationObject.newPresentationNameError }}
            </div>

            <!-- Template Presentation ID -->
            <div class="margin-top">
                <div class="margin-bottom-xs">Template Presentation ID:</div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Template Presentation ID"
                    saved-expression="data.definition.templateIdExpression"
                    expression-unique-identifier="templatePresentationId"
                    on-tonkean-expression-changed="onTemplateIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
                <div
                    ng-if="data.validationObject.noTemplateDocId"
                    class="margin-top-xs margin-bottom-xs common-color-danger"
                >
                    {{ data.validationObject.noTemplateDocId }}
                </div>
            </div>
            <div class="margin-top-6 common-error" ng-if="data.validationObject.templateIdError">
                {{ data.validationObject.templateIdError }}
            </div>

            <!-- Slides to use -->
            <div>
                <div class="margin-bottom-xs">
                    Slides Numbers (1,2,3-5):
                    <i
                        class="fa fa-info-circle common-color-primary margin-left-xs"
                        uib-tooltip="If empty, all slides will be used."
                    ></i>
                </div>

                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    placeholder="Slides Numbers"
                    preview-evaluation-source="data.previewEvaluationSource"
                    saved-expression="data.definition.slidesNumbersExpression"
                    expression-unique-identifier="GoogleDriveFolderDestination"
                    on-tonkean-expression-changed="onSlidesNumbersTonkeanExpressionChanged(expression, shouldSaveLogic)"
                ></tnk-tonkean-expression>
            </div>

            <!-- Replacement Actions -->
            <div class="margin-bottom margin-top-lg">
                <div
                    ng-if="data.definition.replacementActions.length > 0"
                    class="margin-right margin-top-xs margin-bottom"
                >
                    Replacement Actions ({{ data.definition.replacementActions.length }}):
                </div>

                <div ng-repeat="(actionIndex, action) in data.definition.replacementActions" class="flex">
                    <div class="margin-left-xs margin-right-xs margin-top">{{ actionIndex + 1 }}.</div>
                    <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                        <!-- Text to find -->
                        <div class="margin-bottom">
                            <div class="flex-vmiddle">
                                <div class="margin-right margin-top-xs">Text To Find:</div>

                                <div class="flex-grow"></div>

                                <button
                                    class="
                                        svg-icon-xs
                                        common-color-grey
                                        svg-icon-hover-primary
                                        btn btn-no-border btn-on-hover
                                        pointer
                                        flex-no-shrink
                                        margin-left-xxs
                                    "
                                    ng-click="removeReplacementAction(actionIndex)"
                                >
                                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                </button>
                            </div>
                            <tnk-tonkean-expression
                                class="flex-grow"
                                placeholder="Text to find..."
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.replacementActions[actionIndex].placeholderTextExpression"
                                on-tonkean-expression-changed="
                                    onPlaceholderTextTonkeanExpressionChanged(actionIndex, expression, shouldSaveLogic)
                                "
                            ></tnk-tonkean-expression>
                        </div>

                        <!-- New text -->
                        <div class="margin-bottom">
                            <div class="margin-right margin-top-xs">New Text:</div>
                            <tnk-tonkean-expression
                                class="flex-grow"
                                placeholder="Text to replace..."
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.replacementActions[actionIndex].newTextExpression"
                                on-tonkean-expression-changed="
                                    oneNewTextTonkeanExpressionChanged(actionIndex, expression, shouldSaveLogic)
                                "
                            ></tnk-tonkean-expression>
                        </div>

                        <!-- Is match case -->
                        <div class="margin-bottom">
                            <tnk-checkbox
                                label="'Case-sensitive?'"
                                value="action.isMatchCase"
                                class="margin-top-xs"
                                on-toggle-async="onMatchCaseToggled(actionIndex, value)"
                            ></tnk-checkbox>
                        </div>
                    </div>
                </div>

                <a ng-click="addReplacementAction()">+ Add Replacement Action</a>
            </div>
            <div class="margin-top-6 common-error" ng-if="data.validationObject.replacementActionsError">
                {{ data.validationObject.replacementActionsError }}
            </div>
        </div>
    </div>
</div>
