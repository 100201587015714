import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import { useGetUpgradeLink } from '@tonkean/infrastructure';
import { useCurrentUserRole } from '@tonkean/infrastructure';
import { ArrowRightLgIcon } from '@tonkean/svg';
import { BoardSettingsTab } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const StatusWrapperContainer = styled.div`
    margin-right: 20px;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    display: flex;
    min-width: 150px;
    align-items: center;
    border-radius: 44px;
    padding: 0 10px;
    background: rgba(249, 245, 255, 1);
    color: ${Theme.colors.purple};
    line-height: 18px;
`;

const StyledArrowRightLineIcon = styled(ArrowRightLgIcon)`
    path {
        fill: ${Theme.colors.white};
    }
`;

const UpgradeButton = styled(Clickable)`
    background-color: ${Theme.colors.purple};
    color: ${Theme.colors.white};
    border-radius: 16px;
    width: 80px;
    height: 22px;
    padding: 2px 6px 2px 8px;
    margin-left: 8px;
    &:focus,
    &:hover {
        color: ${Theme.colors.white};
    }
`;

const TextContainer = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TrialStatus: React.FC = () => {
    const project = useProject();
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const { data, error } = useTonkeanService('getTrialStatus', project.id);

    const url = useGetUpgradeLink();

    const { isAdmin } = useCurrentUserRole();

    if (error) {
        return <ErrorMessage>Error Loading Trial Status</ErrorMessage>;
    }

    if (!data?.isTrialMode) {
        return <></>;
    }

    const solutionsMap = workflowFolderManager.projectIdToFolderIdToFolderMap[project.id];
    const hasSolution = solutionsMap && Object.keys(solutionsMap).length > 0;
    const stateProp = isAdmin && hasSolution ? 'product.settings.tab' : undefined;
    const statePropsParams = isAdmin && hasSolution ? { tab: BoardSettingsTab.LICENSE_PLAN } : {};

    return (
        <StatusWrapperContainer>
            <Clickable state={stateProp} params={statePropsParams}>
                Trial Account{' '}
            </Clickable>
            <UpgradeButton href={url}>
                <TextContainer>
                    Upgrade <StyledArrowRightLineIcon />
                </TextContainer>
            </UpgradeButton>
        </StatusWrapperContainer>
    );
};

export default TrialStatus;
