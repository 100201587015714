import { useAngularService } from 'angulareact';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import SingleWorkerRunFlowRunInnerChildTitle from './SingleWorkerRunFlowRunInnerChildTitle';
import { ReactComponent as FilterIcon } from '../../../../../../../images/icons/history/filter.svg';
import { ReactComponent as TriggerIcon } from '../../../../../../../images/icons/lightning.svg';
import HistoryContext from '../../../../entities/HistoryContext';
import chainOfEventsSidePaneState from '../../../../states/chainOfEventsSidePaneState';
import historyBreadcrumbsState from '../../../../states/historyBreadcrumbsState';
import originatedWorkerRunState from '../../../../states/originatedWorkerRunState';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Popover } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { CustomTriggerType, WorkerRunReason } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { colorSvg } from '@tonkean/utils';

const LIMIT_WORKER_RUN_SIZE = 100;

const TriggerIconWrapper = styled(TriggerIcon)`
    display: inline-flex;
    margin-right: 5px;
    height: 17px;
    width: 10px;
    ${colorSvg(Theme.colors.primary)}
`;

const FilterIconWrapper = styled.div`
    display: inline-flex;
    svg {
        height: 12px;
        width: 10px;
    }
`;

const MonitoringActionButton = styled.button`
    color: ${Theme.colors.primary};
    border: none;
    font-weight: 700;
    font-size: ${FontSize.SMALL_12};
    background-color: transparent;
    display: inline-flex;
    padding: 0;
    margin-left: 6px;
    align-items: center;
`;

const FilterInEventListButton = styled(Button)`
    font-size: ${FontSize.XSMALL_10};
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    padding: 5px;
`;

const FilterRow = styled.div`
    margin-top: 20px;
`;

const BoldTitle = styled.div`
    font-weight: 500;
`;

const ErrorText = styled.div`
    color: ${Theme.colors.error};
    font-weight: 500;
`;

const FilterLoader = styled.div`
    margin-left: 5px;
`;

interface Props {
    workerRun: WorkerRun;
    logic: any;
}

const SingleWorkerRunFlowRunInnerFlowRuns: React.FC<Props> = ({ workerRun, logic }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const setOriginatedWorkerRun = useSetRecoilState(originatedWorkerRunState);
    const setHistoryBreadcrumbs = useSetRecoilState(historyBreadcrumbsState);
    const chainOfEventsSidePaneParams = useRecoilValue(chainOfEventsSidePaneState);
    const projectManager = useAngularService('projectManager');
    const { rangeFilter } = useContext(HistoryContext);

    const [{ loading: getOriginatedWorkerRunLoading }, getOriginatedWorkerRun] =
        useLazyTonkeanService('getWorkerRunById');

    // If the current logic is same as the custom trigger of the worker run we want to take the originated worker run
    // To get the worker runs of the parent worker run.
    const workerRunIdToGetInnerFlowFrom = useMemo(() => {
        if (workerRun.originatedFromWorkerRunId && workerRun.customTriggerIds?.includes(logic.node.id)) {
            return workerRun.originatedFromWorkerRunId;
        }

        return workerRun.id;
    }, [logic.node.id, workerRun.customTriggerIds, workerRun.id, workerRun.originatedFromWorkerRunId]);

    const [{ data: childWorkerRuns, loading: innerFlowRunsLoading, error: innerFlowRunsError }, getChildWorkerRuns] =
        useLazyTonkeanService('getWorkFlowRunsByParentId');

    useEffect(() => {
        if (popoverOpen && rangeFilter?.dateFrom) {
            getChildWorkerRuns(
                workerRun.projectId,
                workerRun.groupId,
                workerRunIdToGetInnerFlowFrom,
                logic.node.id,
                LIMIT_WORKER_RUN_SIZE,
                rangeFilter.dateFrom.getTime(),
                rangeFilter.dateTo?.getTime(),
            );
        }
    }, [
        getChildWorkerRuns,
        logic.node.id,
        popoverOpen,
        workerRun.groupId,
        workerRun.id,
        workerRun.projectId,
        workerRunIdToGetInnerFlowFrom,
        rangeFilter,
    ]);

    // If the side pane and the popover is open, close the popover.
    useEffect(() => {
        if (chainOfEventsSidePaneParams.isOpen && popoverOpen) {
            setPopoverOpen(false);
        }
    }, [chainOfEventsSidePaneParams.isOpen, popoverOpen]);

    const isLinkingToFlowRuns = useMemo(() => {
        return (
            logic.node.customTriggerType === CustomTriggerType.DELAY ||
            logic.node.customTriggerType === CustomTriggerType.SEND_FORM_ANSWERED ||
            logic.node.customTriggerType === CustomTriggerType.BOT_THREAD_REPLY ||
            logic.node.customTriggerType === CustomTriggerType.BOT_BUTTON_PRESSED
        );
    }, [logic.node.customTriggerType]);

    function setOriginWorkerRun() {
        const updateOriginatedWorkerRun = (newOriginatedWorkerRun: WorkerRun) => {
            if (childWorkerRuns?.entities.length) {
                setOriginatedWorkerRun({
                    workerRun: newOriginatedWorkerRun,
                    customTriggerId: logic.node.id,
                    // We can take just the first child worker run because they all have the same worker run reason.
                    isReplyMessage:
                        childWorkerRuns.entities[0]?.workerRunReason === WorkerRunReason.NEW_MONITORED_THREAD_MESSAGE,
                });
            }
        };

        if (workerRunIdToGetInnerFlowFrom !== workerRun.id && workerRun.originatedFromWorkerRunId) {
            getOriginatedWorkerRun(
                projectManager.project.id,
                workerRun.originatedFromWorkerRunId,
                workerRun.startTime,
                true,
            ).then((parentWorkerRun) => {
                const originatedWorkerRun = parentWorkerRun.entities[0];
                if (originatedWorkerRun) {
                    updateOriginatedWorkerRun(originatedWorkerRun);
                }
            });
        } else {
            updateOriginatedWorkerRun(workerRun);
        }
    }

    const onChildClick = (childWorkerRun: WorkerRun) => {
        setHistoryBreadcrumbs({
            workerRun,
            shouldClear(currentWorkerRun) {
                return currentWorkerRun.id !== childWorkerRun.id;
            },
        });
    };

    return (
        <div onClick={(event) => event.stopPropagation()}>
            <Popover
                show={popoverOpen}
                onClose={() => setPopoverOpen(false)}
                width="300px"
                content={
                    <>
                        {innerFlowRunsLoading && <div className="loading mod-large" />}

                        {innerFlowRunsError && <ErrorText>Error trying to get inner flow runs</ErrorText>}

                        {!innerFlowRunsLoading &&
                            !innerFlowRunsError &&
                            childWorkerRuns &&
                            childWorkerRuns.entities.length > 0 && <BoldTitle>Items in current time range</BoldTitle>}

                        {childWorkerRuns && !childWorkerRuns?.entities.length && (
                            <BoldTitle>No flow runs found</BoldTitle>
                        )}

                        {childWorkerRuns?.entities.map((childWorkerRun) => (
                            <SingleWorkerRunFlowRunInnerChildTitle
                                key={childWorkerRun.id}
                                childWorkerRun={childWorkerRun}
                                isCurrentChildSameAsWorkerRun={childWorkerRun.id === workerRun.id}
                                onClick={() => onChildClick(childWorkerRun)}
                            />
                        ))}

                        {(childWorkerRuns?.entities.length || 0) > 0 && (
                            <FilterRow>
                                <FilterInEventListButton
                                    size={ButtonSize.SMALL}
                                    onClick={(event) => {
                                        setOriginWorkerRun();
                                        event.stopPropagation();
                                    }}
                                    outlined
                                >
                                    <FilterIconWrapper>
                                        <FilterIcon />
                                    </FilterIconWrapper>
                                    Filter in events list
                                </FilterInEventListButton>

                                <FilterLoader>
                                    {getOriginatedWorkerRunLoading && <div className="loading mod-large" />}
                                </FilterLoader>
                            </FilterRow>
                        )}
                    </>
                }
            >
                <MonitoringActionButton
                    onClick={(event) => {
                        setPopoverOpen(!popoverOpen);
                        event.stopPropagation();
                    }}
                >
                    <TriggerIconWrapper />
                    {isLinkingToFlowRuns ? 'Flow Runs' : 'Module Items'}
                </MonitoringActionButton>
            </Popover>
        </div>
    );
};

export default SingleWorkerRunFlowRunInnerFlowRuns;
