import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';
import { TrainingSetTrainType } from '@tonkean/tonkean-entities';

const TrainTriggerLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.TRAIN_TRIGGER,
    title: 'Train',
    iconClass: 'mod-train-trigger',
    description: 'Trains an existing trigger logic block with keywords.',
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        // Validation for train trigger with training set entity type
        if (definition.trainingEntityType === 'TRAINING_SET') {
            if (!definition.trainingSetTrainTriggerConfiguration) {
                validationObject.noTrainingSet = 'Must choose training set';
            }
            if (!definition.trainingSetTrainTriggerConfiguration?.itemNameExpression?.evaluatedExpression) {
                validationObject.noItemName = 'Must fill item name';
            }
            if (
                !definition.trainingSetTrainTriggerConfiguration?.inputTextExpression?.evaluatedExpression &&
                definition.trainingSetTrainTriggerConfiguration?.trainingSetTrainType ===
                    TrainingSetTrainType.NEW_TRAINED_ITEM
            ) {
                validationObject.noInputText = 'Must fill item input';
            }
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default TrainTriggerLogicBlockConfig;
