import React from 'react';
import styled from 'styled-components';

import SingleSnippetComponent from './SingleSnippetComponent';

import type { PreviewFindWordsSnippet } from '@tonkean/tonkean-entities';

interface Props {
    snippets: PreviewFindWordsSnippet[];
}

const SingleSnippetWrapper = styled.div`
    margin-bottom: 10px;
`;

const SnippetComponent: React.FC<Props> = ({ snippets }) => {
    return (
        <>
            {(snippets || []).map((snippet) => (
                <SingleSnippetWrapper key={snippet.snippetIndex}>
                    <SingleSnippetComponent snippet={snippet} />
                </SingleSnippetWrapper>
            ))}
        </>
    );
};

export default SnippetComponent;
