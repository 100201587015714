import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class ConvertToStringFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.CONVERT_TO_STRING;
    override readonly sign = 'ConvertToString';
    override readonly displayName = 'Convert to String';
    override readonly description = 'Will get the string value of any input.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: undefined,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
