import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import styled from 'styled-components';

import type AuditLogItem from '../../../../entities/AuditLogItem';
import AuditLogType from '../../../../entities/AuditLogType';
import SystemLogItemRow from '../../../SystemLogItem/components/SystemLogItemRow';
import UserActionRow from '../../../UserActionItem/components/UserActionRow';
import type UserActionItem from '../../../UserActionItem/entities/UserActionItem';
import UserActionItemPlaceholder from '../../../UserActionItem/loaders/UserActionItemPlaceholder';
import AuditLogPaginatedLoadingPlaceholder from '../loaders/AuditLogPaginatedLoadingPlaceholder';

import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const LoadMoreButton = styled(Button)`
    display: flex;
    margin: 8px auto;
`;

interface Props {
    items: UserActionItem[] | AuditLogItem[];
    loading: boolean;
    hasMore: boolean;
    loadNextPage: () => void;
    pageSize: number;
    itemsCount: number;
    showGroupLabel?: boolean;
    searchTerm?: string;
    logType: AuditLogType;
    isCollapseAllActive?: boolean;
    setIsCollapseAllActive?: Dispatch<SetStateAction<boolean>>;
}
const logTypeToItemRowComponentMap = {
    [AuditLogType.USER_ACTION]: UserActionRow,
    [AuditLogType.SYSTEM]: SystemLogItemRow,
};

const PaginatedAuditLogItemsList: React.FC<Props> = ({
    items,
    loading,
    hasMore,
    loadNextPage,
    itemsCount,
    pageSize,
    showGroupLabel,
    searchTerm,
    logType,
    isCollapseAllActive = false,
    setIsCollapseAllActive,
}) => {
    const AuditLogItemRowComponent = logTypeToItemRowComponentMap[logType] || <>Unsupported log type</>;

    return (
        <>
            {items.map((item) => (
                <AuditLogItemRowComponent
                    key={item.id}
                    log={item}
                    showGroupLabel={showGroupLabel}
                    searchTerm={searchTerm}
                    isCollapseAllActive={isCollapseAllActive}
                    setIsCollapseAllActive={setIsCollapseAllActive}
                />
            ))}

            {!loading && hasMore && (
                <LoadMoreButton size={ButtonSize.SMALL} onClick={loadNextPage} outlined>
                    Load More
                </LoadMoreButton>
            )}

            {loading && (
                <AuditLogPaginatedLoadingPlaceholder
                    total={itemsCount}
                    current={items?.length || 0}
                    pageSize={pageSize}
                    component={UserActionItemPlaceholder}
                />
            )}
        </>
    );
};

export default PaginatedAuditLogItemsList;
