import { useAngularService } from 'angulareact';
import { saturate } from 'polished';
import React from 'react';
import styled from 'styled-components';

import type { ProjectThemeConfiguration } from '@tonkean/tonkean-entities';
import { Clickable, type ClickableProps } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonStyle } from '@tonkean/tui-theme/styles';

const StyledButton = styled(Clickable)<{ $mainColor: string; $secondaryColor: string }>`
    background-color: ${({ disabled }) =>
        disabled
            ? Theme.current.palette.TUI.button[ButtonStyle.FILLED].backgroundDisabledColor
            : ({ $mainColor }) => $mainColor};
    color: ${({ disabled }) =>
        disabled
            ? Theme.current.palette.TUI.button[ButtonStyle.FILLED].textDisabledColor
            : ({ $secondaryColor }) => $secondaryColor};
    border: 1px solid ${({ $secondaryColor }) => $secondaryColor};
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 6px;
    font-weight: 500;
    font-size: ${FontSize.LARGE_16};
    line-height: 16px;

    &:hover,
    &:active,
    &:focus {
        color: ${({ disabled }) =>
            disabled ? Theme.colors.gray_600 : (secondaryColor) => saturate(0.25, secondaryColor.$secondaryColor)};
        background-color: ${({ disabled }) =>
            disabled ? Theme.colors.gray_300 : (mainColor) => saturate(0.25, mainColor.$mainColor)};
    }
`;

interface Props extends ClickableProps {
    invertButtonColors?: boolean;
    dataAutomation?: string;
    buttonColor?: string;
    children: React.ReactNode;
}

const InterfaceButton: React.FC<Props> = ({ invertButtonColors, dataAutomation, buttonColor, children, ...props }) => {
    const projectManager = useAngularService('projectManager');
    const projectThemeConfiguration: ProjectThemeConfiguration = projectManager.project.themeConfiguration;
    const themeColorToDisplay = buttonColor ? buttonColor : projectThemeConfiguration.primaryColor;

    return (
        <StyledButton
            $mainColor={!!invertButtonColors ? Theme.colors.basicBackground : themeColorToDisplay}
            $secondaryColor={!!invertButtonColors ? themeColorToDisplay : Theme.colors.basicBackground}
            data-automation={dataAutomation}
            {...props}
        >
            {children}
        </StyledButton>
    );
};

export default InterfaceButton;
