import isEqual from 'lodash.isequal';
import { useEffect, useMemo, useState } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

import useAllInitiativeFieldsValues from './useAllInitiativeFieldsValues';
import type { UseInitiativeExpressionServerEvaluationResult } from './useInitiativeExpressionServerEvaluationResult';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type {
    Initiative,
    StatelessExpressionsEvaluationRequest,
    StatelessExpressionsEvaluationResponse,
    TonkeanExpressionDefinition,
} from '@tonkean/tonkean-entities';

function useInitiativeExpressionServerEvaluation(
    expressions: TonkeanExpressionDefinition[],
    initiative?: Initiative,
): UseInitiativeExpressionServerEvaluationResult {
    const initiativeFieldsMap = useAllInitiativeFieldsValues(initiative);

    const expressionWithKey = useDeepCompareMemo(() => {
        return expressions.map((expression, index) => ({
            key: `expression-${index}`,
            expression: expression.evaluatedExpression,
        }));
    }, [expressions]);

    const fieldsFromExpressions = useDeepCompareMemo(() => {
        const combinedExpressionsStr = JSON.stringify(expressions);

        const initiativeFieldsFoundInExpressions = {};
        Object.keys(initiativeFieldsMap).forEach((fieldId) => {
            if (combinedExpressionsStr.includes(fieldId)) {
                initiativeFieldsFoundInExpressions[fieldId] = initiativeFieldsMap[fieldId];
            }
        });

        return initiativeFieldsFoundInExpressions;
    }, [initiativeFieldsMap, expressions]);

    const nonEmptyExpressionsToEvaluate = useMemo(() => {
        return expressionWithKey.filter((expression) => !!expression.expression);
    }, [expressionWithKey]);

    const [
        { data: evaluateExpressionsResponse, args: evaluateExpressionsArgs, loading: loadingEvaluatedExpressions },
        evaluateExpressions,
    ] = useLazyTonkeanService('statelessEvaluateExpressions');

    const [lastLoadedEvaluateExpressionsResponse, setLastLoadedEvaluateExpressionsResponse] =
        useState<StatelessExpressionsEvaluationResponse>({});
    const [lastEvaluateExpressionsParams, setLastEvaluateExpressionsParams] = useState<
        StatelessExpressionsEvaluationRequest | undefined
    >();

    useEffect(() => {
        if (evaluateExpressionsResponse && evaluateExpressionsArgs?.[0]) {
            setLastEvaluateExpressionsParams(evaluateExpressionsArgs?.[0]);
            setLastLoadedEvaluateExpressionsResponse(evaluateExpressionsResponse);
        }
    }, [evaluateExpressionsResponse, evaluateExpressionsArgs]);

    useEffect(() => {
        const haveExpressionsToEvaluate = nonEmptyExpressionsToEvaluate.length > 0;
        if (!haveExpressionsToEvaluate) {
            return;
        }

        const evaluateExpressionsRequest = {
            fieldValues: fieldsFromExpressions,
            expressions: nonEmptyExpressionsToEvaluate,
        };

        const hasCachedResponseForRequestWithSameParams = isEqual(
            lastEvaluateExpressionsParams,
            evaluateExpressionsRequest,
        );
        const currentlyOngoingRequestHasSameParams = isEqual(evaluateExpressionsRequest, evaluateExpressionsArgs?.[0]);

        if (hasCachedResponseForRequestWithSameParams || currentlyOngoingRequestHasSameParams) {
            return;
        }

        evaluateExpressions(evaluateExpressionsRequest);
    }, [
        fieldsFromExpressions,
        nonEmptyExpressionsToEvaluate,
        lastEvaluateExpressionsParams,
        evaluateExpressions,
        evaluateExpressionsArgs,
    ]);

    return useMemo(() => {
        const evaluatedExpressions = expressionWithKey.map((expression) => {
            return lastLoadedEvaluateExpressionsResponse?.[expression.key]?.result || '';
        });

        return { values: evaluatedExpressions, loading: loadingEvaluatedExpressions };
    }, [expressionWithKey, lastLoadedEvaluateExpressionsResponse, loadingEvaluatedExpressions]);
}

export default useInitiativeExpressionServerEvaluation;
