import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { SavingIndicator } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { WarningIcon } from '@tonkean/svg';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
`;

const PendingChangesTextWrapper = styled.div`
    display: flex;
    align-items: center;
    color: ${Theme.colors.gray_700};
    margin-left: 8px;

    svg {
        ${colorSvg(Theme.colors.gray_600)};
    }
`;
const PendingChangesText = styled.div`
    margin-left: 8px;
    margin-right: 8px;
`;

const StyledSavingIndicator = styled(SavingIndicator)`
    margin: 0 8px;
`;

interface Props {
    canUserEditItem: boolean;
}

const ItemWidgetSubmitFooter: React.FC<Props> = ({ canUserEditItem }) => {
    const { dirty, isSubmitting, values, errors } = useFormikContext();

    const canPost = dirty && values && Object.keys(values as object)?.length > 0 && canUserEditItem;

    return (
        <Footer data-automation="item-widget-submit-footer">
            <StyledSavingIndicator loading={isSubmitting} error={!!errors && !!errors[0]} />
            {dirty && (
                <PendingChangesTextWrapper>
                    <WarningIcon />
                    <PendingChangesText data-automation="item-widget-submit-footer-pending-changes-indication">
                        There are unsaved changes in this section
                    </PendingChangesText>
                </PendingChangesTextWrapper>
            )}
            <Tooltip content="You don't have sufficient permissions to post status updates" disabled={canUserEditItem}>
                <Button data-automation="item-widget-submit-footer-submit-button" type="submit" disabled={!canPost}>
                    Submit
                </Button>
            </Tooltip>
        </Footer>
    );
};

export default ItemWidgetSubmitFooter;
