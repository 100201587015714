import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';
import { useCallback, useEffect, useRef } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import type { Initiative, TonkeanType, TonkeanId, WorkflowVersionType } from '@tonkean/tonkean-entities';
import Utils from '@tonkean/utils';

interface IntakeInitiativeResponse {
    initiative?: Initiative;
    loadingInitatiative: boolean;
    errorInitiative: IHttpResponse<any> | undefined;
}

const useGetIntakeInitiative = (
    projectId: TonkeanId<TonkeanType.PROJECT>,
    workflowVersionType: WorkflowVersionType,
    intakeCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
    intakeCustomTriggerDisabled?: boolean,
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>,
    initialValues?: string,
    conversationId?: string,
): IntakeInitiativeResponse => {
    const trackHelper = useAngularService('trackHelper');

    const [{ data: initiative, error: initiativeError, loading: initiativeLoading }, getInitiativeById] =
        useLazyAsyncMethod(trackHelper, 'getInitiativeById');

    const [
        {
            data: initiativeCreatedByIntake,
            loading: loadinInitiativeCreatedByIntake,
            error: errorInitiativeCreatedByIntake,
        },
        createInitiativeByIntake,
    ] = useLazyTonkeanService('createInitiativeByIntake');

    const createInitiativeCalledRef = useRef(false);

    const createInitiative = useCallback(
        (customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>) => {
            const initialValuesFromIntakeCreator: Record<string, string> = initialValues
                ? JSON.parse(Utils.fromBase64(initialValues))
                : {};
            createInitiativeCalledRef.current = true;
            createInitiativeByIntake(
                projectId,
                customTriggerId,
                workflowVersionType,
                initialValuesFromIntakeCreator,
                conversationId,
            );
        },
        [initialValues, createInitiativeByIntake, projectId, workflowVersionType, conversationId],
    );

    useEffect(() => {
        if (
            !initiativeId &&
            !createInitiativeCalledRef.current &&
            !loadinInitiativeCreatedByIntake &&
            !initiativeCreatedByIntake &&
            !intakeCustomTriggerDisabled &&
            !!intakeCustomTriggerId
        ) {
            createInitiative(intakeCustomTriggerId);
        }
    }, [
        initiativeId,
        loadinInitiativeCreatedByIntake,
        createInitiative,
        initiativeCreatedByIntake,
        intakeCustomTriggerDisabled,
        intakeCustomTriggerId,
    ]);

    useEffect(() => {
        if (!initiative && initiativeId && !initiativeLoading && !initiativeCreatedByIntake) {
            getInitiativeById(initiativeId);
        }
    }, [initiative, initiativeId, initiativeLoading, getInitiativeById, initiativeCreatedByIntake]);

    return {
        initiative: initiativeCreatedByIntake || initiative,
        loadingInitatiative: initiativeLoading || loadinInitiativeCreatedByIntake,
        errorInitiative: initiativeError || errorInitiativeCreatedByIntake,
    };
};

export default useGetIntakeInitiative;
