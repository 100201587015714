import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const StyledNotAvailableNote = styled.span`
    color: ${Theme.colors.gray_400};
`;

const NotAvailableDataCellPlaceholder: React.FC = () => {
    return <StyledNotAvailableNote>N/A</StyledNotAvailableNote>;
};

export default NotAvailableDataCellPlaceholder;
