import React from 'react';

import { SelectorTitle } from './SelectorTitle';

import { SimpleSelect, useFormikField } from '@tonkean/infrastructure';

export enum RowHeight {
    SMALL = 'Small',
    MEDIUM = 'Medium',
    LARGE = 'Large',
}

const sizes = [
    { value: RowHeight.SMALL, label: 'Small' },
    { value: RowHeight.MEDIUM, label: 'Medium' },
    { value: RowHeight.LARGE, label: 'Large' },
];
const RowHeightSelector: React.FC = () => {
    const { value: rowHeight, setValue: setRowHeight } = useFormikField<RowHeight | undefined>(
        'configuration.rowHeight',
    );

    return (
        <>
            <SelectorTitle>Row Height</SelectorTitle>
            <SimpleSelect
                value={rowHeight || RowHeight.SMALL}
                onChange={setRowHeight}
                name="configuration.rowHeight"
                options={sizes}
                thin
            />
        </>
    );
};

export default RowHeightSelector;
