import React from 'react';

import useDataSourceConnectionBreadCrumbsSettings from './useDataSourceConnectionBreadCrumbsSettings';

import { Breadcrumbs } from '@tonkean/infrastructure';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';

interface Props {
    currentDataSourceConnection: DataSourceConnection;
    otherPossibleDataSourceConnections: DataSourceConnection[];
}

const DataSourceConnectionBreadCrumbs: React.FC<Props> = ({
    currentDataSourceConnection,
    otherPossibleDataSourceConnections,
}) => {
    const breadCrumbsSettings = useDataSourceConnectionBreadCrumbsSettings(
        currentDataSourceConnection,
        otherPossibleDataSourceConnections,
    );

    return <Breadcrumbs settings={breadCrumbsSettings} />;
};

export default DataSourceConnectionBreadCrumbs;
