import SolutionMetricsGrid from './SolutionMetricsGrid';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkSolutionMetricsGrid',
        reactToAngular(SolutionMetricsGrid, [
            'folderName',
            'modules',
            'environment',
            'filterModulesTerm',
            'collapsed',
            'onToggle',
            'folderId',
        ]),
    );
