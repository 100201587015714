import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class AvgFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.AVG;
    override readonly sign = 'Avg';
    override readonly displayName = 'Average';
    override readonly description = 'Will calculate the average of the given fields.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Number',
            pluralName: 'Numbers',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => ({
                fieldDefinitionType: FieldDefinitionType.SINGLE,
                dataType: FieldType.Number,
                displayName: `Number ${repeatIndex}`,
            }),
        },
    ];
}
