import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { OperatorFamily } from '@tonkean/tonkean-entities';
import { FormulaOperatorDefinitionBase } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default abstract class NumericBinaryOperatorBase extends FormulaOperatorDefinitionBase {
    public readonly family = OperatorFamily.ARITHMETIC;
    public readonly dataType = FieldType.Number;

    public readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field A',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Field B',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
