<div class="bot-projected-items-container">
    <div ng-if="!data.hasItems && !data.loading" class="common-color-grey">Looks empty.</div>
    <div ng-if="(!data.hasItems && data.loading) || data.hardLoading" class="flex mod-justify-center">
        <i class="loading-large"></i>
    </div>

    <div ng-repeat="section in data.sections" ng-if="section.items.length && !data.hardLoading">
        <div class="flex-vmiddle margin-bottom-xs margin-top-md">
            <div class="common-size-xxxxs common-color-light-grey2 common-bold">{{ section.label }}</div>

            <div class="flex-grow"></div>
            <i ng-if="data.loading" class="loading"></i>
        </div>
        <tnk-bot-projected-item
            ng-repeat="groupedInitiatives in section.items"
            section-id="section.id"
            initiatives="groupedInitiatives.value"
            owner-id="::key"
            ui-actions="uiActions"
        ></tnk-bot-projected-item>
    </div>
</div>
