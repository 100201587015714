import BotProjectedItem from './BotProjectedItem';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkBotProjectedItem',
        reactToAngular(
            BotProjectedItem,
            ['sectionId', 'ownerId', 'initiatives', 'uiActions'],
            ['botProjectedItemStore', 'authenticationService', 'modalUtils', 'tonkeanUtils', 'trackHelper'],
        ),
    );
