import { useAngularService } from 'angulareact';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { getIsInWidgetBuilder } from '../../WidgetModule';
import getInterfaceWidgetElementId from '../getItemInterfaceElementId';
import InterfaceQuickNavigationContext from '../InterfaceQuickNavigationContext';

import { NO_ICON, TonkeanIconDisplay } from '@tonkean/icon-picker';
import { HorizontalScroller, Tab, Tabs, TabsBorderType } from '@tonkean/infrastructure';
import type { ItemInterfaceThemeTextAlignment, WidgetBase } from '@tonkean/tonkean-entities';
import { Theme, FontSize, type Color } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    width: 100%;
    margin-top: 10px;
`;

const TabsStyled = styled(Tabs)`
    height: auto;
    display: block;
    width: 100%;
`;

const StyledTab = styled(Tab)`
    flex-shrink: 0;
    padding: 4px 12px;
    gap: 10px;
    font-size: ${FontSize.LARGE_16};
    height: 40px;
    font-weight: 400;
`;

const HorizontalScrollerStyled = styled(HorizontalScroller)`
    gap: 20px;
`;

interface Props {
    widgets: WidgetBase[];
    themeColor: Color;
    alignment: ItemInterfaceThemeTextAlignment;
}

const TabsInterfaceQuickNav: React.FC<Props> = ({ widgets, themeColor, alignment }) => {
    const $state = useAngularService('$state');
    const isInBuilder = getIsInWidgetBuilder($state);

    const { selectedWidgetId, setSelectedWidgetId, tabs } = useContext(InterfaceQuickNavigationContext);

    // If the tabs arent memoed they are rerendered with layout changes (like element appears above them) which causes the selected tab-indicator to jump around
    const tabsElements = useMemo(() => {
        return tabs?.map((tab) => {
            const sidepaneWidgetChangeParams = isInBuilder
                ? {
                      state: '.',
                      params: {
                          widgetId: tab.widgets[0],
                          currentTab: undefined,
                      },
                      options: { location: 'replace' },
                  }
                : {};
            const icon = tab.icon;

            return (
                <StyledTab
                    key={tab.key}
                    data-automation="interface-quick-nav-tab"
                    label={tab.key}
                    onClick={(event) => {
                        if (!isInBuilder) {
                            return;
                        }

                        if (tab.widgets[0]) {
                            const elementId = getInterfaceWidgetElementId(tab.widgets[0]);
                            document.getElementById(elementId)?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                            });
                        }

                        // In the ITEM interface builder there is a header that wraps this component which will override this behaviour,
                        // so we stop the event from reaching it
                        event.stopPropagation();
                    }}
                    {...sidepaneWidgetChangeParams}
                >
                    {icon && icon.name !== NO_ICON && <TonkeanIconDisplay size={16} icon={icon} dontShowEmptyIcon />}
                    {tab.label}
                </StyledTab>
            );
        });
    }, [isInBuilder, tabs]);

    return (
        <Wrapper>
            <TabsStyled
                value={selectedWidgetId}
                onChange={setSelectedWidgetId}
                showBackground={false}
                borderType={TabsBorderType.NONE}
                indicatorHeight={4}
                selectedColor={Theme.colors.gray_800}
                notSelectedColor={Theme.colors.gray_700}
                indicatorColor={themeColor}
                disableIndicatorAnimation
            >
                <HorizontalScrollerStyled alignment={alignment}>{tabsElements}</HorizontalScrollerStyled>
            </TabsStyled>
        </Wrapper>
    );
};

export default TabsInterfaceQuickNav;
