import React, { useCallback, useMemo } from 'react';

import DeprecatedPeopleSelector from './DeprecatedPeopleSelector';

import type { Person } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    selectedPeople: Person[];
    noValidation?: boolean;
    disabled: boolean;
    noCustom: boolean;
    doNotSearchOnlyOwnersInGroup: boolean;
    onPeopleAltered(people: Person[]): void;
    onlyProcessContributors?: boolean;
    includeExternal?: boolean;
}

/**
 * A wrapper to make it easier to work with tnkPeopleSelector in React.
 * The main problem is that the angular component modifies the people array in-place, so we perform some magic tricks to circumvent that.
 */
const DeprecatedPeopleSelectorWrapper: React.FC<Props> = ({
    selectedPeople,
    onPeopleAltered: onPeopleAlteredProp,
    ...props
}) => {
    const people = useMemo(
        () =>
            /**
             * We are casting people with `customEmail` property to a custom email object PeopleSelector can work with,
             * making it think it is a genuine custom email.
             */
            selectedPeople.map((person) =>
                person.customEmail ? { customEmail: true, email: person.email, isValid: true } : person,
            ),
        [selectedPeople],
    ) as Person[];

    const onPeopleAltered = useCallback(() => {
        // The angular people selector calls the `onPeopleAltered` on mount, so we must make sure there is a real change in the array (add/remove).
        if (selectedPeople.length !== people.length) {
            // TnkPeopleSelector modifies the selectedPeople array in-place, so we must send out transformed data.
            onPeopleAlteredProp(people);
        }
    }, [onPeopleAlteredProp, people, selectedPeople.length]);

    return (
        <DeprecatedPeopleSelector
            {...props}
            selectedPeople={people}
            onTagAdded={onPeopleAltered}
            onTagRemoved={onPeopleAltered}
        />
    );
};

export default DeprecatedPeopleSelectorWrapper;
