<div class="margin-left margin-right margin-top">
    <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            preview-evaluation-source="data.previewEvaluationSource"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            parameters-values="data.parametersValues"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- New File Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                New File Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="File Name"
                saved-expression="data.definition.fileNameExpression"
                expression-unique-identifier="AzureBlobStorageUploadFileName"
                on-tonkean-expression-changed="onFileNameExpressionChanged(expression, shouldSaveLogic)"
                preview-evaluation-source="data.previewEvaluationSource"
            ></tnk-tonkean-expression>
        </div>

        <!-- Destination ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">Path:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Path"
                saved-expression="data.definition.pathExpression"
                expression-unique-identifier="AzureBlobStorageUploadPath"
                on-tonkean-expression-changed="onPathExpressionChanged(expression, shouldSaveLogic)"
                preview-evaluation-source="data.previewEvaluationSource"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
