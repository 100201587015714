<div
    class="common-size-xxs cursor-default"
    style="width: 240px; max-width: 240px"
    ng-click="utils.eventStopPropagation($event)"
>
    <!-- Content when owner is not licensed -->
    <div ng-if="!canOwnerUpdate" class="padding-normal-sm">
        <!-- Padded content -->
        <div class="no-gather-update-content flex">
            <div class="flex-no-shrink">
                <i class="fa fa-exclamation-triangle common-color-danger margin-right-xs"></i>
            </div>
            <div class="flex-grow common-color-black">
                <div ng-if="!pm.project.isLimitedLicense">
                    <!-- Ask again message (for not buyer) -->
                    <div ng-if="!pm.isBuyer && !pm.isOwner">
                        <p>
                            {{ item.owner.firstName }} isn't a member of this Tonkean board. Ask
                            <strong>{{ pm.project.license.buyer.name || pm.project.creator.name }}</strong>
                            to add them.
                        </p>
                        <div class="flex-vmiddle" ng-if="pm.isUserLicensed">
                            <button
                                type="button"
                                class="btn btn-default margin-right-xs"
                                ng-click="askToAddOwner(item.owner)"
                                ng-disabled="data.askingToAddOwner || data.askingToAddOwnerSuccess"
                            >
                                Ask {{ pm.project.license.buyer.firstName || pm.project.creator.firstName }}
                            </button>
                            <i class="loading" ng-show="data.askingToAddOwner"></i>
                            <i
                                class="svg-icon-xs common-color-success margin-right-xs"
                                ng-show="!data.askingToAddOwner && data.askingToAddOwnerSuccess"
                            >
                                <tnk-icon src="../../../../../apps/tracks/images/icons/check.svg"></tnk-icon>
                            </i>
                        </div>
                    </div>
                    <!-- Add seat message (for buyer) -->
                    <div ng-if="pm.isBuyer || pm.isOwner">
                        <div>
                            You need to assign a seat to
                            <strong>{{ item.owner.firstName }}</strong>
                            so they can access Tonkean.
                            <!--Adding {{item.owner.firstName}} will add a seat to your license.-->
                            <div class="common-size-xxxxs">
                                <a ng-click="showNewUserDetails = !showNewUserDetails">Details</a>
                            </div>
                            <p class="common-size-xxxxs" ng-show="showNewUserDetails">
                                If there is an open seat it will be taken, otherwise a new seat will be added according
                                to your
                                <a
                                    ui-sref="product.settings.license({tab: 'members'})"
                                    target="_blank"
                                    href="/PROJuNXp9l15x01/settings/subscription/members"
                                >
                                    subscription
                                </a>
                                .
                            </p>
                        </div>
                        <div class="flex-vmiddle">
                            <button
                                type="button"
                                class="btn btn-primary margin-right-xs"
                                ng-click="tryToAddSeatToUser(item.owner.id)"
                            >
                                Add {{ item.owner.firstName }}
                            </button>
                            <i class="loading" ng-show="data.addingSeat"></i>
                        </div>
                    </div>
                </div>
                <div ng-if="pm.project.isLimitedLicense">
                    <p class="common-bold">{{ item.owner.firstName }} doesn't have access to this board.</p>
                    <p>Add a seat or upgrade your account to add them.</p>
                    <button type="button" class="btn btn-default" ui-sref="product.settings.license({tab: 'members'})">
                        View Subscription
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Content when Module is OFF for owner (if user is the same as current user look at that reference as well) -->
    <div ng-if="canOwnerUpdate && item.owner.isGatherUpdateDisabled" class="padding-normal-sm">
        <!-- Padded content -->
        <div class="no-gather-update-content flex">
            <div class="flex-grow common-color-black">
                <div class="common-bold">
                    {{ isOwnerCurrentUser ? 'You' : item.owner.firstName }} turned
                    {{ isOwnerCurrentUser ? 'your' : 'their' }} Module off.
                </div>
                <div>
                    The Module can't reach out to {{ isOwnerCurrentUser ? 'you' : 'them' }},
                    <br />
                    get updated or send trigger notifications.
                </div>

                <button
                    ng-if="isOwnerCurrentUser"
                    type="button"
                    class="btn btn-default margin-top-xs"
                    ng-click="turnBotMessagesOn()"
                >
                    Turn the Module ON
                </button>
            </div>
        </div>
    </div>

    <!-- Content when Module is NOT active -->
    <div
        ng-if="canOwnerUpdate && !item.owner.isGatherUpdateDisabled && !workflowVersion.shouldSendGatherUpdates"
        class="padding-normal-sm"
    >
        <!-- Padded content -->
        <div class="no-gather-update-content flex">
            <div class="flex-no-shrink">
                <i class="fa fa-exclamation-triangle common-color-danger margin-right-xs"></i>
            </div>
            <div class="flex-grow common-color-black">
                <p>
                    <strong>The A.I. Module is currently off.</strong>
                    {{ isOwnerCurrentUser ? 'You' : item.owner.firstName }} will not be pinged for follow ups until it’s
                    turned on.
                </p>
                <!-- Toggle Module -->
                <span
                    class="inline-block"
                    ng-if="pm.isUserLicensed"
                    tooltip-enable="isLinkedItem"
                    tooltip-placement="auto bottom"
                    uib-tooltip="This is a linked item. You can turn the module on in the item's original module."
                >
                    <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        ng-click="toggleIsBotActive()"
                        ng-disabled="isLinkedItem"
                        ng-class="{ 'pointer-events-none': isLinkedItem }"
                    >
                        Turn the Module ON
                    </button>
                </span>
            </div>
        </div>
    </div>

    <!-- Content when the Module IS active -->
    <div ng-if="canOwnerUpdate && !item.owner.isGatherUpdateDisabled && !workflowVersion.shouldSendGatherUpdates">
        <!-- No nextGather, no triggers -->
        <div ng-if="!itemMap[item.trackId].nextGatherUpdate && !workflowVersionHasTriggers">
            <!-- tip -->
            <div class="no-gather-update-popover-tip flex">
                <div class="flex-no-shrink">
                    <i class="fa fa-exclamation-triangle common-color-danger margin-right-xs"></i>
                </div>
                <div class="flex-grow common-color-black common-bold">
                    <span ng-if="!itemMap[item.trackId].dueDate">
                        There is not enough info for the Module to ping
                        {{ isOwnerCurrentUser ? 'you' : item.owner.firstName }}.
                    </span>
                    <span ng-if="itemMap[item.trackId].dueDate">
                        The due date has long past so the Module stopped pinging
                        {{ isOwnerCurrentUser ? 'you' : item.owner.firstName }}.
                    </span>
                </div>
            </div>
            <!-- Padded content -->
            <div class="no-gather-update-content margin-top-xs">
                <div ng-click="setDueDateCallback()" class="no-gather-update-btn">
                    <i class="svg-icon-nav-sm margin-right-md svg-icon-align-text-top">
                        <tnk-icon src="../../../../../apps/tracks/images/icons/calendar-new.svg"></tnk-icon>
                    </i>
                    {{ itemMap[item.trackId].dueDate ? 'Change the ' : 'Add a ' }}due date
                </div>
                <div ng-click="onPingManuallyClicked($event)" class="no-gather-update-btn">
                    <i class="svg-icon-nav margin-right svg-icon-align-text-top">
                        <tnk-icon src="../../../../../apps/tracks/images/icons/wave.svg"></tnk-icon>
                    </i>
                    Set manually
                </div>
            </div>
        </div>

        <!-- No nextGather, yes triggers -->
        <div ng-if="!itemMap[item.trackId].nextGatherUpdate && workflowVersionHasTriggers">
            <!-- tip -->
            <div class="no-gather-update-popover-tip common-color-black">
                <div>
                    No follow ups currently scheduled.
                    <strong>{{ isOwnerCurrentUser ? 'you' : item.owner.firstName }} will be pinged</strong>
                    when a trigger fires.
                </div>
            </div>
            <!-- Padded content -->
            <div class="no-gather-update-content margin-top-xs">
                <div ng-click="setDueDateCallback()" class="no-gather-update-btn">
                    <i class="svg-icon-nav-sm margin-right-md svg-icon-align-text-top">
                        <tnk-icon src="../../../../../apps/tracks/images/icons/calendar-new.svg"></tnk-icon>
                    </i>
                    {{ itemMap[item.trackId].dueDate ? 'Change the ' : 'Add a ' }}due date
                </div>
                <div ng-click="onPingManuallyClicked($event)" class="no-gather-update-btn">
                    <i class="svg-icon-nav margin-right svg-icon-align-text-top">
                        <tnk-icon src="../../../../../apps/tracks/images/icons/wave.svg"></tnk-icon>
                    </i>
                    Ping manually
                </div>
            </div>
        </div>
    </div>
</div>
