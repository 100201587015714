import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import SmartSearchConversationView from './SmartSearchConversationView';
import SmartSearchForm from './SmartSearchForm';

import type { SmartConversationManager } from '@tonkean/smart-conversation';
import type {
    SmartConversationMessage,
    SmartConversationUserMessageContent,
    SmartSearchResponse,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const InstructionHelpersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 13px;
`;

const InstructionHelperContainer = styled.div`
    border-radius: 8px;
    border: 1px solid ${Theme.colors.gray_300};
    background: ${Theme.colors.gray};
    display: flex;
    padding: 16px 19px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
`;

const InstructionHelperTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
`;

const InstructionHelperTitle = styled.div`
    color: ${Theme.colors.gray_800};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 400;
`;

const InstructionHelperOptions = styled.ul`
    margin: 0 0 0 20px;
    padding: 0;
`;

const InstructionHelperOption = styled.li`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 400;
    line-height: 20px;
`;

export interface SmartSearchInstructionHelper {
    id: number;
    title: string;
    options: string[];
}

export interface SmartSearchBaseProps {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    initialSearchString?: string;
    searchRequired?: boolean;
    mobileView?: boolean;
}

export interface SmartSearchProps {
    smartConversationManager: SmartConversationManager<SmartSearchResponse>;
    showTalkToExpert?: boolean;
    needHelpClicked?: (originalMessage: string) => void;
    instructionHelpers?: SmartSearchInstructionHelper[];
}

const SmartSearch: React.FC<SmartSearchProps & SmartSearchBaseProps> = ({
    projectId,
    initialSearchString,
    searchRequired = true,
    mobileView,
    showTalkToExpert,
    needHelpClicked,
    smartConversationManager,
    instructionHelpers,
}) => {
    const firstUserMessage = useMemo(() => {
        const userMessage = smartConversationManager.messages?.find(
            (message) => message.response.userResponse && message.response.hidden,
        ) as SmartConversationMessage<SmartConversationUserMessageContent>;

        return userMessage?.response.text;
    }, [smartConversationManager.messages]);

    const [searchedString, setSearchedString] = useState(!firstUserMessage ? initialSearchString : undefined);

    const needHelpClickedCallback = useCallback(() => {
        const originalMessage = searchedString ?? firstUserMessage;
        if (needHelpClicked && originalMessage) {
            needHelpClicked(originalMessage);
        }
    }, [firstUserMessage, needHelpClicked, searchedString]);

    return (
        <Container>
            <SmartSearchForm
                searchString={initialSearchString ?? firstUserMessage}
                searchRequired={searchRequired}
                mobileView={mobileView}
                onSubmit={(search) => {
                    smartConversationManager.callReplyMethod(search, '', true, true);
                    setSearchedString(search);
                }}
            />
            <SmartSearchConversationView
                projectId={projectId}
                showTalkToExpert={showTalkToExpert}
                needHelpClicked={needHelpClickedCallback}
                smartConversationManager={smartConversationManager}
            />
            {smartConversationManager.messages.length === 0 && instructionHelpers && (
                <InstructionHelpersContainer>
                    {instructionHelpers.map((instructionHelper) => (
                        <InstructionHelperContainer key={instructionHelper.id}>
                            <InstructionHelperTitleWrapper>
                                <InstructionHelperTitle data-automation="smart-search-instruction-helper-title">
                                    {instructionHelper.title}
                                </InstructionHelperTitle>
                            </InstructionHelperTitleWrapper>
                            <InstructionHelperOptions>
                                {instructionHelper.options.map((option) => (
                                    <InstructionHelperOption key={option}>{option}</InstructionHelperOption>
                                ))}
                            </InstructionHelperOptions>
                        </InstructionHelperContainer>
                    ))}
                </InstructionHelpersContainer>
            )}
        </Container>
    );
};

export default SmartSearch;
