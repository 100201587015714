import ViewDataAddRecord from './ViewDataAddRecord';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkViewDataAddRecord',
        reactToAngular(ViewDataAddRecord, [
            'entityName',
            'projectIntegrationEntityId',
            'projectIntegrationId',
            'projectIntegrationUniqueType',
            'onSubmit',
        ]),
    );
