import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import PaginatedAuditLogAccordionCard from './PaginatedAuditLogAccordionCard';
import type AuditLogFilterSearchQuery from '../../../../entities/AuditLogFilterSearchQuery';
import type AuditLogType from '../../../../entities/AuditLogType';
import type AuditLogsGroupsResponse from '../../entities/responses/AuditLogsGroupsResponse';
import AuditLogGroupPlaceholder from '../loaders/AuditLogGroupPlaceholder';
import AuditLogPaginatedLoadingPlaceholder from '../loaders/AuditLogPaginatedLoadingPlaceholder';
import NoAuditLogsMessage from '../loaders/NoAuditLogsMessage';

import { NEXT_PAGE_TOKEN_PARAM, useFetchManager } from '@tonkean/infrastructure';
import type { TonkeanType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const LoadMoreButton = styled(Button)`
    margin: 16px auto;
`;

interface Props {
    enterpriseComponentId: string;
    enterpriseComponentType: TonkeanType;
    headersPageSize: number;
    itemsPageSize: number;
    filterSearch: AuditLogFilterSearchQuery;
    logType: AuditLogType;
}

const PaginatedAuditLogAccordion: React.FC<Props> = ({
    enterpriseComponentId,
    enterpriseComponentType,
    headersPageSize,
    itemsPageSize,
    filterSearch,
    logType,
}) => {
    const auditLogService = useAngularService('auditLogService');
    const projectManager = useAngularService('projectManager');

    const [
        [getAuditLogGroups, stopFetcher],
        {
            data: auditLogsGroups,
            loadNextPage: loadNextGroupsPage,
            hasMorePages: hasMoreGroupsPages,
            loading: loadingGroups,
        },
    ] = useFetchManager(auditLogService, 'getAuditLogGroups', {
        getItems: (auditLogs: AuditLogsGroupsResponse) => auditLogs.entities,
        getNextPageToken: 'nextPageToken',
        checkHasMore: (auditLogs: AuditLogsGroupsResponse) => auditLogs.entities.length === headersPageSize,
        compareItems: 'groupUniqueIdentifier',
    });

    useEffect(() => {
        getAuditLogGroups(
            projectManager.project.id,
            enterpriseComponentId,
            enterpriseComponentType,
            headersPageSize,
            logType,
            NEXT_PAGE_TOKEN_PARAM,
            filterSearch,
        );

        return () => {
            stopFetcher();
        };
    }, [
        headersPageSize,
        stopFetcher,
        projectManager.project.id,
        enterpriseComponentId,
        enterpriseComponentType,
        getAuditLogGroups,
        filterSearch,
        logType,
    ]);

    return (
        <>
            {auditLogsGroups?.map((header, index) => (
                <PaginatedAuditLogAccordionCard
                    key={header.groupUniqueIdentifier}
                    enterpriseComponentId={enterpriseComponentId}
                    enterpriseComponentType={enterpriseComponentType}
                    pageSize={itemsPageSize}
                    group={header}
                    itemsCount={header.groupItemsCount}
                    filterSearch={filterSearch}
                    logType={logType}
                />
            ))}

            {loadingGroups.any && (
                <AuditLogPaginatedLoadingPlaceholder
                    current={auditLogsGroups?.length || 0}
                    total={Math.max(headersPageSize, auditLogsGroups?.length)}
                    pageSize={headersPageSize}
                    component={AuditLogGroupPlaceholder}
                />
            )}

            {hasMoreGroupsPages && !loadingGroups.any && (
                <LoadMoreButton size={ButtonSize.MEDIUM} onClick={loadNextGroupsPage} outlined>
                    Load More
                </LoadMoreButton>
            )}

            {!loadingGroups.any && !auditLogsGroups.length && <NoAuditLogsMessage filterSearch={filterSearch} />}
        </>
    );
};

export default PaginatedAuditLogAccordion;
