import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function BotStatusViewCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        status: ctrl.status,
    };
}

export default angular.module('tonkean.app').controller('BotStatusViewCtrl', lateConstructController(BotStatusViewCtrl));
