<div>
    <div class="common-close-btn" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <div class="common-page-load" ng-show="data.loading">
        <i class="loading-full-circle mod-center-screen"></i>
    </div>
    <!-- Modal title -->
    <div
        ng-if="!data.loading"
        class="padding-normal common-size-xs clearfix margin-auto margin-bottom-xlg"
        style="border-radius: 6px 6px 0 0; max-width: 800px"
    >
        <div class="common-size-l margin-normal-v common-title-font text-center">Moving to our new pricing</div>
        <p class="common-size-xs common-color-black text-left">
            In our new pricing we moved away from having two user types (Full users, Team members) to just "Users".
        </p>
        <p class="common-size-xs common-color-black text-left">
            In the old model, Team members were free but limited to only using the Module, while Full users had full
            access and required a paid seat. In the new model,
            <strong>all users</strong>
            requires a paid seat, but all users have full access.
        </p>
        <p class="common-size-xs margin-top-xlg common-color-black text-left common-color-primary text-center">
            <strong>As part of moving all users to be paid seats, we significantly lowered the price per seat.</strong>
        </p>

        <hr />
        <p class="common-size-xs text-left">
            Being an early customer with Tonkean, you can choose to stay with the old model, or move to the new one. You
            can review what that will mean in your case below:
        </p>

        <div class="rounded-box">
            <div class="padding-normal common-bg mod-light-grey">
                <p class="common-color-dark-grey"><strong>Your current plan:</strong></p>
                <p class="common-color-dark-grey">
                    Today you are currently paying for
                    <strong>{{ data.currentPlan.seats }} seats</strong>
                    (of full users),
                    <span ng-show="data.migrateData.seats.length > data.currentPlan.seats">
                        with an addition of
                        <strong>{{ data.migrateData.seats.length - data.currentPlan.seats }} free Team members</strong>
                        (can only access the module).
                    </span>
                    On a {{ data.currentPlan.interval }} plan, your current total price is
                    <strong>
                        ${{ ((data.currentPlan.amountPerSeatPerMonth * data.currentPlan.seats) / 100).toFixed(2) }} per
                        month
                    </strong>
                    .
                </p>
            </div>
            <hr class="margin-none" />
            <div class="padding-normal">
                <p class="margin-top-xl"><strong>In the new pricing:</strong></p>
                <p class="common-size-xs common-color-black text-left">
                    In the new pricing, to allow all
                    <strong class="common-color-primary">{{ data.migrateData.seats.length }} active users</strong>
                    to maintain access, you will need
                    <strong>{{ data.migrateData.seats.length }} seats</strong>
                    . Staying on a {{ data.currentPlan.interval }} plan, your total price will be
                    <strong class="common-color-primary">
                        ${{ ((data.newPlan.amountPerSeatPerMonth * data.migrateData.seats.length) / 100).toFixed(2) }}
                        per month
                    </strong>
                    .
                </p>
            </div>
        </div>

        <!-- Show error -->
        <div ng-if="data.error">
            An error occurred:
            <br />
            {{ data.error }}
        </div>

        <hr />
        <div class="text-center margin-bottom-xlg">
            <button class="btn btn-default btn-lg margin-right" ng-click="$dismiss()">Stay on current plan</button>
            <button class="btn btn-primary btn-lg" ng-click="moveToNewPlan()">Move to the new plan</button>
        </div>
    </div>
</div>
