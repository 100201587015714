<div class="sync-preview">
    <!-- Loading state -->
    <div ng-if="!data.showNewViewDataTable">
        <div ng-if="loadingItems">
            <div class="common-bold common-size-xs">Waiting for preview to load...</div>

            <!-- Items box -->
            <div class="synced-items-box">
                <div ng-repeat="item in [1, 2, 3, 4, 5, 6]">
                    <hr class="margin-top margin-bottom" />
                    <div class="flex-vmiddle">
                        <div class="flex-grow">
                            <div class="loading-item-name-placeholder"></div>
                        </div>
                        <div class="flex-no-shrink margin-right loading-avatar-placeholder"></div>
                        <div class="flex-no-shrink margin-right-lg loading-name-placeholder"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- After finished loading -->
    <div ng-if="!loadingItems && !error">
        <!-- Entity not Configured in Data Retention -->
        <div ng-if="data.syncedItems.length === 0 && !data.isEntityConfiguredInDataCollection">
            <div class="common-bold common-size-xs">Entity is not configured under data collection settings.</div>
            <div class="common-bold common-size-xs">
                Please visit
                <a ng-click="openDataCollectionConfigurationModal()">data collection window</a>
                in order to collect data for this entity.
            </div>
        </div>

        <div ng-if="!data.showNewViewDataTable">
            <!-- Headline - What we're about to sync -->
            <div ng-if="data.syncedItems.length === 0 && data.isEntityConfiguredInDataCollection">
                <div class="common-bold common-size-xs">
                    No
                    {{ data.integrationUniqueType !== data.pluralEntityType ? data.pluralEntityType : 'entities' }}
                    found from {{ data.projectIntegration.displayName }}
                </div>
                <div>
                    Edit the criteria to find
                    {{
                        data.integrationUniqueType !== data.pluralEntityType
                            ? data.pluralEntityType
                            : data.projectIntegration.displayName + ' entities'
                    }}
                    you would like to sync
                </div>
            </div>

            <div ng-if="data.syncedItems.length > 0">
                <div ng-hide="data.hideInformation">
                    <div ng-hide="data.syncedItems.length > data.syncItemsLimitation">
                        <div class="common-size-xs">
                            <span class="common-bold">
                                Showing {{ data.syncedItems.length }}
                                {{ data.totalSyncedItemsCount > -1 ? 'out of ' + data.totalSyncedItemsCount : '' }}
                                {{
                                    data.integrationUniqueType !== data.pluralEntityType
                                        ? data.pluralEntityType
                                        : 'entities'
                                }}
                                from "{{ data.projectIntegration.displayName }}"
                            </span>
                        </div>
                    </div>
                    <div class="margin-top-xs" ng-if="data.syncedItems.length > data.syncItemsLimitation">
                        <div class="common-size-xs common-bold common-color-danger">
                            Are these the {{ data.syncedItems.length }}
                            {{
                                data.integrationUniqueType !== data.pluralEntityType
                                    ? data.pluralEntityType
                                    : 'entities'
                            }}
                            you need context and updates on?
                        </div>
                        <div class="common-size-xxs">
                            Tonkean works best when you only sync
                            {{
                                data.integrationUniqueType !== data.pluralEntityType
                                    ? data.pluralEntityType
                                    : 'entities'
                            }}
                            you need your team's insights and updates on.
                        </div>
                        <div class="common-size-xxs">
                            We recommend syncing no more than {{ data.syncItemsLimitation }}
                            {{
                                data.integrationUniqueType !== data.pluralEntityType
                                    ? data.pluralEntityType
                                    : 'entities'
                            }}. Go back and edit your criteria.
                        </div>
                    </div>
                </div>
                <div ng-if="data.hideInformation">
                    <span class="common-bold">
                        {{ data.syncedItems.length }}
                        {{ data.totalSyncedItemsCount > -1 ? 'out of ' + data.totalSyncedItemsCount : '' }}
                        {{ data.integrationUniqueType !== data.pluralEntityType ? data.pluralEntityType : 'entities' }}
                    </span>
                </div>
            </div>

            <!-- No items -->
            <div
                class="synced-items-box relative"
                ng-if="
                    data.syncedItems.length === 0 &&
                    data.isEntityConfiguredInDataCollection &&
                    !data.showNewViewDataTable
                "
            >
                <div class="zero-items">
                    0
                    {{
                        data.integrationUniqueType !== data.pluralEntityType
                            ? data.pluralEntityType
                            : data.projectIntegration.displayName + ' entities'
                    }}
                    found
                </div>
                <div ng-repeat="item in [1, 2, 3, 4, 5]">
                    <hr class="margin-top margin-bottom" />
                    <div class="flex-vmiddle">
                        <div class="flex-grow">
                            <div class="empty-item-name-placeholder"></div>
                        </div>
                        <div class="flex-no-shrink margin-right empty-avatar-placeholder"></div>
                        <div class="flex-no-shrink margin-right-lg empty-name-placeholder"></div>
                    </div>
                </div>
            </div>

            <!-- Items to sync -->
            <div
                class="synced-items-box"
                ng-if="data.syncedItems.length > 0 && !data.showNewViewDataTable"
                ng-class="{ 'mod-table': data.viewMod === syncPreviewViewMods.TABLE }"
            >
                <!-- LIST view -->
                <div ng-if="data.viewMod === syncPreviewViewMods.LIST">
                    <div
                        ng-repeat="item in items = (data.syncedItems | limitTo: data.limitTo)"
                        ng-class="{ 'common-border-bottom': $last }"
                    >
                        <hr class="margin-top margin-bottom-md" />
                        <div class="flex-vmiddle common-width-full common-size-xxs margin-bottom-md avatar-size-xxs">
                            <!-- Item name -->
                            <div class="margin-left common-break-long common-ellipsis item-name">{{ item.name }}</div>

                            <!-- Item custom fields -->
                            <div class="common-size-xxxxs margin-right-md common-ellipsis custom-fields">
                                <span
                                    class="text-left"
                                    ng-class="{ 'margin-left': $first, 'margin-left-xs': !$first }"
                                    ng-repeat="field in item.customFields"
                                >
                                    {{ field }}
                                </span>
                            </div>

                            <!-- Inspect -->
                            <a class="common-size-xxxxs margin-normal-sm-h" ng-click="inspectItem(item)">View</a>

                            <a
                                class="common-size-xxxxs margin-normal-sm-h"
                                ng-click="editWebhookItem(item)"
                                ng-if="
                                    data.projectIntegration.integration.integrationType === 'WEBHOOK' &&
                                    data.projectIntegration.creator.id === $root.as.currentUser.id
                                "
                            >
                                Edit
                            </a>

                            <!-- View link -->
                            <a
                                ng-if="item.url"
                                class="common-size-xxxxs margin-normal-sm-h flex-no-shrink"
                                href="{{ item.url }}"
                                target="_blank"
                            >
                                Open
                                <i class="fa fa-external-link"></i>
                            </a>
                        </div>
                    </div>
                    <a
                        ng-if="data.syncedItems.length > items.length"
                        ng-click="data.limitTo = data.limitTo + data.syncItemsLimitation"
                        class="padding-normal block"
                    >
                        Load more..
                    </a>
                </div>

                <!-- TABLE view -->
                <div ng-if="data.viewMod === syncPreviewViewMods.TABLE" class="sync-preview-table-container">
                    <div class="sync-preview-table" prevent-scroll-bubbling>
                        <!-- Header-->
                        <div class="sync-preview-table-header">
                            <div class="sync-preview-table-row">
                                <!-- Edit Header -->
                                <div
                                    class="sync-preview-table-column mod-small"
                                    ng-if="data.projectIntegration.integration.integrationType === 'WEBHOOK'"
                                ></div>

                                <div class="sync-preview-table-column">id</div>
                                <div
                                    class="sync-preview-table-column"
                                    ng-repeat="fieldMetadata in data.tableFieldsMetadata"
                                    uib-tooltip="{{ fieldMetadata.label }}"
                                    tooltip-enable="fieldMetadata.label.length > 13"
                                    tooltip-placement="bottom auto"
                                    tooltip-append-to-body="true"
                                >
                                    {{ fieldMetadata.label }}
                                </div>
                            </div>
                        </div>

                        <!-- Body -->
                        <div class="sync-preview-table-body">
                            <div
                                ng-repeat="item in items = (data.syncedItems | limitTo: data.limitTo)"
                                class="sync-preview-table-row"
                                ng-class="{ 'common-border-bottom': $last }"
                            >
                                <div class="sync-preview-table-column mod-small text-center common-bg mod-light-grey">
                                    <!-- Inspect -->
                                    <a class="common-size-xxxxs" ng-click="inspectItem(item)">View</a>

                                    <span class="margin-left-xs margin-right-xs common-color-light-grey">|</span>

                                    <a
                                        class="common-size-xxxxs"
                                        data-automation="sync-preview-edit-data-automation"
                                        ng-click="editWebhookItem(item)"
                                        ng-if="data.projectIntegration.integration.integrationType === 'WEBHOOK'"
                                    >
                                        Edit
                                    </a>
                                </div>

                                <div
                                    class="sync-preview-table-column"
                                    data-automation="sync-preview-id-item"
                                    uib-tooltip="{{ item.id }}"
                                    tooltip-enable="item.id.length > 13"
                                    tooltip-append-to-body="true"
                                >
                                    {{ item.id }}
                                </div>
                                <div
                                    ng-repeat="fieldMetadata in data.tableFieldsMetadata"
                                    class="sync-preview-table-column"
                                    uib-tooltip="{{ item.originalEntity[fieldMetadata.name] }}"
                                    tooltip-enable="item.originalEntity[fieldMetadata.name].length > 13"
                                    tooltip-append-to-body="true"
                                >
                                    {{ item.originalEntity[fieldMetadata.name] }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <a
                        ng-if="data.syncedItems.length > items.length"
                        ng-click="data.limitTo = data.limitTo + data.syncItemsLimitation"
                        class="padding-normal block"
                    >
                        Load more..
                    </a>
                </div>
            </div>

            <!-- Module on\off box -->
            <div
                ng-if="data.syncedItems.length > 0"
                ng-hide="data.hideInformation || data.hideBot"
                class="
                    flex
                    mod-justify-space
                    flex-xs-wrap
                    margin-top
                    sync-preview-owners-box
                    mod-sync-preview
                    common-bg
                    mod-grey3
                "
            >
                <div class="flex flex-col mod-align-center common-bold">
                    <tnk-bot-toggle
                        class="margin-bottom-xs"
                        is-bot-active="isBotActive"
                        override-toggle-method="toggleIsBotActive()"
                        no-validation="true"
                    ></tnk-bot-toggle>
                    <div ng-if="isBotActive">
                        <a ng-click="modalUtils.openTestBotModal()">Test me!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ng-if="data.showNewViewDataTable">
        <tnk-project-integration-view-data-list
            ng-if="
                data.projectIntegration &&
                data.pluralEntityType &&
                data.viewData &&
                data.viewType &&
                (data.syncedItems.length > 0 || data.isEntityConfiguredInDataCollection)
            "
            entity-plural-label="data.pluralEntityType"
            project-integration="data.projectIntegration"
            view-data="data.viewData"
            view-type="data.viewType"
            full-screen="data.fullScreen"
            selected-entity="data.selectedEntity"
            on-data-changed="(onDataChanged)"
            edit-allowed="true"
        ></tnk-project-integration-view-data-list>
    </div>

    <!-- Error state -->
    <div ng-if="error && data.projectIntegration.valid">
        <!-- Error message -->
        <div class="common-bold">View data is not available due to the following error - {{ error }}</div>
    </div>
</div>
