import React, { useState } from 'react';
import styled from 'styled-components';

import { Chevron, ChevronDirection, CollapsibleContent, Spacer } from '@tonkean/infrastructure';
import type { FieldDefinition, FormField } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';

const ExpandButton = styled(Clickable)`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_700};
    display: flex;
    align-items: center;
    line-height: 1.4;
`;

const ExpandedDescription = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    white-space: pre-wrap;
`;

interface Props {
    formField: FormField;
    fieldDefinition: FieldDefinition;
}

const ExpandableFieldDescription: React.FC<Props> = ({ formField, fieldDefinition }) => {
    const [open, setOpen] = useState(!formField.collapseDescriptionByDefault);

    return (
        <>
            <ExpandButton onClick={() => setOpen((prevOpen) => !prevOpen)}>
                Description
                <Spacer width={5} />
                <Chevron direction={open ? ChevronDirection.DOWN : ChevronDirection.RIGHT} />
            </ExpandButton>
            <Spacer height={6} />
            <CollapsibleContent open={open}>
                <ExpandedDescription dangerouslySetInnerHTML={{ __html: fieldDefinition.description }} />
            </CollapsibleContent>
        </>
    );
};

export default ExpandableFieldDescription;
