import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class StripHtmlFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.STRIP_HTML;
    override readonly sign = 'StripHtml';
    override readonly displayName = 'Strip HTML';
    override readonly description = 'Will strip any html code that exists in the given input.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
