<div class="flex-grow enterprise-manageBoards-content">
    <div class="flex-vmiddle">
        <div class="flex-grow">
            <span class="common-title mod-light">Manage Boards</span>
        </div>
        <a
            class="btn btn-primary flex-no-shrink common-size-xs padding-left-lg padding-right-lg"
            ui-sref="products.new({type:'private'})"
            tnk-require
            tnk-require-non-partial
            analytics-on
            analytics-category="Manage Enterprise"
            analytics-event="Create Another"
            analytics-label="Private"
        >
            Create new
        </a>
    </div>

    <!-- show enterprise selection -->
    <div class="flex-vmiddle margin-auto padding-top-xlg">
        <div class="flex-vmiddle">
            <div
                class="
                    common-size-xs
                    mod-light
                    margin-right-lg
                    clickable-bottom-border
                    letter-spacing-sm
                    common-color-grey common-bold
                "
                ng-class="{ 'mod-active': data.showEnterpriseBoards }"
                ng-click="data.showEnterpriseBoards = true"
            >
                Enterprise Boards
            </div>
            <div
                class="
                    common-size-xs
                    mod-light
                    clickable-bottom-border
                    letter-spacing-sm
                    margin-left-xs
                    common-color-grey common-bold
                "
                ng-class="{ 'mod-active': !data.showEnterpriseBoards }"
                ng-click="data.showEnterpriseBoards = false"
            >
                Other Boards
            </div>
        </div>
    </div>
    <hr class="common-separator mod-darker margin-bottom boards-separator" />

    <section ng-show="pm.projects.length && !data.showEnterpriseBoards">
        <p class="common-color-dark-grey enterprise-manageBoards-information">
            <span class="block">Below are boards you have access to, but are not part of your enterprise account.</span>
            <span class="block flex-vmiddle">
                Click on
                <span class="svg-icon-smd icon-square-background margin-right-xs margin-left-xs common-border">
                    <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                </span>
                to add them to the enterprise account.
            </span>
        </p>
        <div class="padding-top">
            <!-- Boards -->
            <div
                class="project clearfix"
                ng-repeat="project in pm.projects | filter:{ isEnterprise: data.showEnterpriseBoards}"
            >
                <div class="pull-right" ng-hide="data.addingBoardToEnterpriseMap[project.id]">
                    <div
                        class="dropbox flex-no-shrink pull-right"
                        uib-dropdown
                        on-toggle="toggleProjectOptions[project.id] = open"
                    >
                        <span class="svg-icon-mdl svg-icon-hover-dark-grey pointer dropdown-toggle" uib-dropdown-toggle>
                            <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                        </span>
                        <ul uib-dropdown-menu class="dropdown-menu-right" role="menu">
                            <li>
                                <a ng-click="setProjectEnterpriseId(project.id)">Add to enterprise</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Doing action loading state -->
                <div class="pull-right" ng-show="data.addingBoardToEnterpriseMap[project.id]">
                    <span class="loading"></span>
                </div>

                <div class="project-name common-bold padding-bottom-lg">
                    <a class="common-color-black" ui-sref="product.board({projectId: project.id, filter: 'all'})">
                        {{ project.name }}
                    </a>
                </div>

                <div class="project-details">
                    <span class="common-size-xxxxs">Created by {{ project.creator.name }}</span>
                </div>

                <div class="project-details">
                    <span class="common-size-xxxxs">{{ project.created | date: 'mediumDate' }}</span>
                </div>
            </div>
            <!-- Empty state -->
            <div
                ng-if="!(pm.projects | filter: { isEnterprise: data.showEnterpriseBoards }).length"
                class="margin-top common-color-grey common-italic"
            >
                No boards found...
            </div>
        </div>
    </section>
    <section ng-show="data.showEnterpriseBoards">
        <div class="padding-top">
            <!-- Boards -->
            <div class="project clearfix" ng-repeat="project in data.enterpriseBoards.projects">
                <div class="pull-right" ng-hide="data.removingBoardFromEnterpriseMap[project.id]">
                    <div
                        class="dropbox flex-no-shrink pull-right"
                        uib-dropdown
                        on-toggle="toggleProjectOptions[project.id] = open"
                    >
                        <span class="svg-icon-mdl svg-icon-hover-dark-grey pointer dropdown-toggle" uib-dropdown-toggle>
                            <tnk-icon src="/images/icons/ellipsis-v.svg"></tnk-icon>
                        </span>
                        <ul uib-dropdown-menu class="dropdown-menu-right" role="menu">
                            <li>
                                <a ng-click="clearProjectEnterpriseId(project.id)">Remove from enterprise</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Doing action loading state -->
                <div class="pull-right" ng-show="data.removingBoardFromEnterpriseMap[project.id]">
                    <span class="loading"></span>
                </div>

                <div class="project-name common-bold padding-bottom-lg">
                    <a class="common-color-black margin-right-xs" ng-click="selectProject(project)">
                        {{ project.name }}
                    </a>
                    <i class="loading-small" ng-if="data.goingToBoardMap[project.id]"></i>
                </div>

                <div class="project-details">
                    <span class="common-size-xxxxs">Created by {{ project.creator.name }}</span>
                </div>

                <div class="project-details">
                    <span class="common-size-xxxxs">{{ project.created | date: 'mediumDate' }}</span>
                </div>
            </div>
            <!-- Empty state -->
            <div ng-if="!data.enterpriseBoards.projects.length" class="margin-top common-color-grey common-italic">
                No enterprise boards found...
            </div>
        </div>
    </section>
</div>
