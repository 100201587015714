<div class="inner-tracks-template-config">
    <!-- For each initiative in initiative creation -->
    <div ng-repeat="initiative in data.initiatives" class="flex-vmiddle margin-bottom-lg">
        <!-- Initiative title input text -->
        <input
            type="text"
            class="form-control common-input-only-line common-bg mod-transparent margin-right common-input-grow"
            ng-model="initiative.title"
            ng-change="onInnerTracksTemplateConfigChanged()"
            placeholder="{{ data.newTrackPlaceholder }}"
        />

        <!-- Due date setting -->
        <div class="common-bubble-inplace-container flex-no-shrink margin-right">
            <!-- Add due date button -->
            <div
                uib-tooltip="Set Due Date"
                tooltip-class="add-due-date-tooltip"
                ng-click="data.dueDateAlertOpen = {}; data.dueDateAlertOpen[$index] = true"
            >
                <span
                    class="flex-vmiddle"
                    ng-class="{
                        'common-color-grey': !initiative.relativeDueDateInDays,
                        'common-color-black': initiative.relativeDueDateInDays
                    }"
                >
                    <!-- Due date icon -->
                    <span
                        class="svg-icon-xs svg-icon-vmiddle pointer"
                        ng-class="{ 'margin-right-xs': initiative.relativeDueDateInDays }"
                    >
                        <tnk-icon src="/images/icons/calendar-new.svg"></tnk-icon>
                    </span>
                    <!-- Add Due date text -->
                    <span class="line-height-normal flex-no-shrink">
                        {{ initiative.relativeDueDateInDays ? '+ ' + initiative.relativeDueDateInDays : '' }}
                    </span>
                </span>
            </div>

            <!-- Add due date bubble -->
            <div
                class="common-bubble-inplace mod-right"
                ng-class="{ 'mod-visible': data.dueDateAlertOpen[$index] }"
                ng-show="data.dueDateAlertOpen[$index]"
                click-outside="data.dueDateAlertOpen[$index] = false"
                outside-if-not="common-bubble-inplace-container"
                outside-activators="mod-visible"
            >
                <!-- Add due date title -->
                <div class="flex-grow flex-vmiddle text-left margin-left-xs">
                    <!-- Due date icon -->
                    <span class="svg-icon-xs common-color-black svg-icon-vmiddle margin-right">
                        <tnk-icon src="/images/icons/calendar-new.svg"></tnk-icon>
                    </span>
                    <!-- Add Due date text -->
                    <span class="common-color-dark-grey line-height-normal flex-grow">Add Due Date</span>

                    <!-- Close button -->
                    <span
                        class="pointer margin-top-none margin-right svg-icon flex-no-shrink common-color-grey"
                        ng-click="data.dueDateAlertOpen[$index] = false"
                    >
                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                    </span>
                </div>
                <hr class="common-separator mod-darker margin-top margin-bottom-md" />

                <!-- Due date actual value picking -->
                <div class="flex-vmiddle margin-left margin-bottom-md">
                    <input
                        type="number"
                        min="0"
                        class="form-control org-seats-box mod-input block margin-right"
                        ng-model="initiative.relativeDueDateInDays"
                        ng-change="onDueDateChanged(initiative)"
                    />
                    <span class="common-bold">days after Track added</span>
                </div>
            </div>
        </div>

        <!-- Owner picking -->
        <div class="common-bubble-inplace-container flex-no-shrink margin-right">
            <!-- Owner button -->
            <div
                uib-tooltip="Set Owner"
                tooltip-class="add-due-date-tooltip"
                ng-click="data.ownerAlertOpen = {}; data.ownerAlertOpen[$index] = true"
            >
                <span
                    class="flex-vmiddle"
                    ng-class="{
                        'common-color-grey': !initiative.selectedPeople,
                        'common-color-black': initiative.selectedPeople
                    }"
                >
                    <!-- Due date icon -->
                    <tnk-avatar
                        ng-if="initiative.selectedPeople[0]"
                        class="avatar-sm"
                        user="initiative.selectedPeople[0]"
                        default-on-empty="true"
                    ></tnk-avatar>
                    <span ng-if="!initiative.selectedPeople[0]" class="svg-icon-md svg-icon-vmiddle pointer">
                        <tnk-icon src="/images/icons/add-owner.svg"></tnk-icon>
                    </span>
                </span>
            </div>

            <!-- Add owner bubble -->
            <div
                class="common-bubble-inplace mod-right"
                ng-class="{ 'mod-visible': data.ownerAlertOpen[$index] }"
                ng-show="data.ownerAlertOpen[$index]"
                click-outside="data.ownerAlertOpen[$index] = false"
                outside-if-not="common-bubble-inplace-container"
                outside-activators="mod-visible"
            >
                <!-- Add owner title -->
                <div class="flex-grow flex-vmiddle text-left margin-left-xs">
                    <!-- Owner icon -->
                    <span class="svg-icon-md common-color-black svg-icon-vmiddle margin-right">
                        <tnk-icon src="/images/icons/add-owner.svg"></tnk-icon>
                    </span>
                    <!-- Add owner text -->
                    <span class="common-color-dark-grey line-height-normal flex-grow">Add owner</span>

                    <!-- Close button -->
                    <span
                        class="pointer margin-top-none margin-right svg-icon flex-no-shrink common-color-grey"
                        ng-click="data.ownerAlertOpen[$index] = false"
                    >
                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                    </span>
                </div>
                <hr class="common-separator mod-darker margin-top margin-bottom-md" />

                <!-- Owner actual value picking -->
                <div class="flex-vmiddle margin-left margin-bottom-md">
                    <tnk-deprecated-people-selector
                        class="common-width-full margin-right"
                        no-validation="false"
                        control-object="initiative.peopleSelectorControl"
                        selected-people="initiative.selectedPeople"
                        on-tag-added="onPersonSelected(initiative)"
                        on-tag-removed="onPersonRemoved(initiative)"
                        on-person-invite="onPersonSelected(initiative)"
                        max-tags="1"
                        custom-options="data.peopleSelectorCustomOptions"
                        no-custom="true"
                        placeholder="Owner name"
                        show-intro="true"
                    ></tnk-deprecated-people-selector>
                </div>
            </div>
        </div>

        <!-- Remove button -->
        <button
            type="button"
            class="btn btn-xs btn-no-border common-no-outline flex-no-shrink"
            ng-click="removeInitiative($index)"
            tabindex="-1"
            uib-tooltip="Remove track"
            tooltip-placement="top"
        >
            <span class="svg-icon-xs common-color-grey">
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </span>
        </button>
    </div>

    <!-- Add new initiative -->
    <div class="common-size-xxs">
        <a ng-click="addEmptyInitiative()">+ Add</a>
    </div>

    <!-- Apply on previously created tracks -->
    <div class="flex-vmiddle margin-top common-size-xxs" ng-hide="data.hideApplyOnPreviouslyCreatedTracks">
        <input
            type="checkbox"
            ng-change="onInnerTracksTemplateConfigChanged()"
            ng-model="data.applySettingsOnPreviouslyCreatedTracks"
            id="applySettingsOnPreviouslyCreatedTracks"
            class="margin-top-none"
        />
        <span class="margin-left-xs pointer" ng-click="toggleApplySettingsOnPreviouslyCreatedTracks()">
            Apply to all previously created Items in this Module.
        </span>
    </div>
</div>
