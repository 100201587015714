import template from './tnkSendAttachmentLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSendAttachmentLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        previewEvaluationSource: '<',
        invalidLogics: '<',
        reloadFields: '<',
        onActionsChanged: '&',
        openFieldModal: '<',
        includeFormAttachment: '<',
        includeItemInterfaceAttachment: '<',
    },
    template,
    controller: 'SendAttachmentLogicConfigurationCtrl',
});
