import type { IntrospectionQuery } from 'graphql';
import { useMemo } from 'react';

import createUrqlTonkeanClient from './createUrqlTonkeanClient';
import type { TonkeanGraphqlCacheQueryResolversConfig } from './getTonkeanGraphqlCacheQueryResolversConfig';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

function useUrqlTonkeanClient(
    schema: IntrospectionQuery,
    queryResolversConfig: TonkeanGraphqlCacheQueryResolversConfig[] | undefined,
    apiUrl: string,
    getHeaders: (() => HeadersInit) | undefined,
) {
    const getHeadersConstantRef = useConstantRefCallback(getHeaders);

    return useMemo(() => {
        return createUrqlTonkeanClient(schema, queryResolversConfig, apiUrl, getHeadersConstantRef);
    }, [schema, queryResolversConfig, apiUrl, getHeadersConstantRef]);
}

export default useUrqlTonkeanClient;
