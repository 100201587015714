import type NavigationCategoryConfiguration from '../entities/NavigationCategoryConfiguration';

import { NavigationCategory } from '@tonkean/tonkean-entities';

const navigationCategoriesConfigurationMap: Record<NavigationCategory, NavigationCategoryConfiguration> = {
    [NavigationCategory.CURRENT_MODULE_BUILD_ITEMS]: {
        navigationCategory: NavigationCategory.CURRENT_MODULE_BUILD_ITEMS,
        displayName: 'Module Items',
    },
    [NavigationCategory.CURRENT_MODULE_PRODUCTION_ITEMS]: {
        navigationCategory: NavigationCategory.CURRENT_MODULE_PRODUCTION_ITEMS,
        displayName: 'Module Items',
    },
    [NavigationCategory.ALL_MODULES_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_MODULES_IN_PROJECT,
        displayName: 'Modules In Board',
    },
    [NavigationCategory.ALL_SOLUTIONS_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_SOLUTIONS_IN_PROJECT,
        displayName: 'Solutions In Board',
    },
    [NavigationCategory.CURRENT_MODULE_BUILD_CUSTOM_TRIGGERS]: {
        navigationCategory: NavigationCategory.CURRENT_MODULE_BUILD_CUSTOM_TRIGGERS,
        displayName: 'Triggers Used In Module',
    },
    [NavigationCategory.CURRENT_MODULE_PRODUCTION_CUSTOM_TRIGGERS]: {
        navigationCategory: NavigationCategory.CURRENT_MODULE_PRODUCTION_CUSTOM_TRIGGERS,
        displayName: 'Triggers Used In Module',
    },
    [NavigationCategory.CURRENT_MODULE_SOLUTION_DATA_SOURCES]: {
        navigationCategory: NavigationCategory.CURRENT_MODULE_SOLUTION_DATA_SOURCES,
        displayName: 'Data Sources Accessible in Solution',
    },
    [NavigationCategory.CURRENT_MODULE_USED_DATA_SOURCES]: {
        navigationCategory: NavigationCategory.CURRENT_MODULE_USED_DATA_SOURCES,
        displayName: 'Data Sources Used In Module',
    },
    [NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT,
        displayName: 'All Data Sources',
    },
    [NavigationCategory.CURRENT_DATA_SOURCE_ACCESSIBLE_MODULES]: {
        navigationCategory: NavigationCategory.CURRENT_DATA_SOURCE_ACCESSIBLE_MODULES,
        displayName: 'Accessible Modules',
    },
    [NavigationCategory.CURRENT_DATA_SOURCE_ACCESSIBLE_SOLUTIONS]: {
        navigationCategory: NavigationCategory.CURRENT_DATA_SOURCE_ACCESSIBLE_SOLUTIONS,
        displayName: 'Accessible Solutions',
    },
    [NavigationCategory.ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_COMMUNICATION_INTEGRATIONS_IN_PROJECT,
        displayName: 'All Communication Integrations',
    },
    [NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT,
        displayName: 'Reports',
    },
    [NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT,
        displayName: 'Interfaces',
    },
    [NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT,
        displayName: 'Actions',
    },
    [NavigationCategory.ALL_HOMEPAGE_INTAKES_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_HOMEPAGE_INTAKES_IN_PROJECT,
        displayName: 'Actions',
    },
    [NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT]: {
        navigationCategory: NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
        displayName: 'Pages',
    },
};

export default navigationCategoriesConfigurationMap;
