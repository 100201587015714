import { reactToAngular } from 'angulareact';

import StatusesWorkerOutline from './StatusesWorkerOutline';

export default angular
    .module('tonkean.app')
    .component(
        'tnkStatusesWorkerOutline',
        reactToAngular(StatusesWorkerOutline, ['workflowVersionId', 'groupId', 'viewOnly']),
    );
