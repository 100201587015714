import SQLDatabaseAuthenticationComponent from './SQLDatabaseAuthenticationComponent';
import SQLDatabaseConfigurationComponent from './SQLDatabaseConfigurationComponent';
import sqlCircleImage from '../../../../apps/tracks/images/integrations/sql-circle.png';
import sqlImage from '../../../../apps/tracks/images/sql.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { DataSourcesSectionType } from '@tonkean/tonkean-entities';

class SQLDatabaseIntegration extends IntegrationDefinitionBase {
    override name = 'sqldatabase';
    override displayName = 'SQL Database';
    override description = 'We will collect the records of your chosen SQL database queries.';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(sqlCircleImage, '130px'),
        new IntegrationIcon(sqlImage, '130px'),
    );
    override entities = [];

    // We are a dynamic entities integration.
    override hasDynamicEntities = true;

    override dataSourceSectionType = DataSourcesSectionType.DATABASE;

    // We define data retention settings to allow the sql database integration to support multiple dynamic entities.
    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: true,
        editableEntities: {},
        removableEntities: {},
        isOnlyDataRetention: {},
    };

    override customizedAuthenticationComponent = SQLDatabaseAuthenticationComponent;
    override customizedSetupComponent = SQLDatabaseConfigurationComponent;
}

export default SQLDatabaseIntegration;
