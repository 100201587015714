import { useField } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';

import useMatchedItemOptions from './hooks/useMatchedItemOptions';
import InterfaceCTASelectorEntityDescription from './MultiEntitySelectorCustomComponents/InterfaceCTASelectorEntityDescription';
import ActionDisabledStateModal from '../../widgets/ActionsWidgetModule/components/ActionDisabledStateModal';
import type { MultiEntitySelectorGetCustomMenuItems } from '../MultiEntitySelector';
import { MultiEntitySelector } from '../MultiEntitySelector';

import { MenuItem } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { AutonomousSecondaryType } from '@tonkean/tonkean-entities';
import type {
    CustomTrigger,
    Form,
    InterfaceCTAActionOption,
    InterfaceCTASavedAction,
    MatchedItemActionData,
} from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';

interface Props {
    name: string;
    forms?: Form[];
    loadingForms?: boolean;
    errorForms?: ErrorResponseType;
    customTriggers?: CustomTrigger[];
    loadingCustomTriggers?: boolean;
    errorCustomTriggers?: ErrorResponseType;
    matchedItemsActionsData?: MatchedItemActionData[];
    loadingMatchedItemsActionsData?: boolean;
    errorMatchedItemsActionsData?: ErrorResponseType;
    enableCloneAction?: boolean;
    hideEdit?: boolean;
}

const InterfaceCTAActionsSelector: React.FC<Props> = ({
    name,
    forms,
    errorForms,
    customTriggers,
    loadingCustomTriggers,
    errorCustomTriggers,
    loadingForms,
    matchedItemsActionsData,
    loadingMatchedItemsActionsData,
    errorMatchedItemsActionsData,
    enableCloneAction,
    hideEdit,
}) => {
    const { itemInterface } = useItemInterfaceContext();

    const buttonCustomTriggers: CustomTrigger[] = useMemo(() => {
        return (customTriggers || [])?.filter(
            (trigger) => trigger.customTriggerSecondaryType === AutonomousSecondaryType.USER_INTERFACE_BUTTON_CLICK,
        );
    }, [customTriggers]);

    const actionOptions: InterfaceCTAActionOption[] = useMatchedItemOptions(
        forms,
        buttonCustomTriggers,
        enableCloneAction,
        matchedItemsActionsData,
    );

    const [selectedActionForButtonStatesModal, setSelectedActionForButtonStatesModal] =
        useState<InterfaceCTASavedAction>();

    const [{ value: actions }, , { setValue: setActions }] = useField<InterfaceCTASavedAction[] | undefined>(name);

    const getCustomMenuItems: MultiEntitySelectorGetCustomMenuItems<InterfaceCTASavedAction> = useCallback(
        (value) => [
            <MenuItem key="configure-button-states" onClick={() => setSelectedActionForButtonStatesModal(value)}>
                Configure Button States
            </MenuItem>,
        ],
        [],
    );

    const customComponents = useMemo(
        () => ({
            entityDescriptionComponent: InterfaceCTASelectorEntityDescription,
        }),
        [],
    );

    const loading = loadingForms || loadingCustomTriggers || loadingMatchedItemsActionsData;
    return (
        <>
            <MultiEntitySelector<InterfaceCTASavedAction>
                name={name}
                loading={!!loading}
                error={errorForms || errorCustomTriggers || errorMatchedItemsActionsData}
                entityTypeLabel="action"
                entitiesOptions={actionOptions}
                customComponents={customComponents}
                hideEditLabel={hideEdit}
                hideEditIndex={hideEdit}
                getCustomMenuItems={!hideEdit ? getCustomMenuItems : undefined}
            />

            <ActionDisabledStateModal
                open={!!selectedActionForButtonStatesModal}
                groupId={itemInterface.group.id}
                workflowVersionId={itemInterface.workflowVersionId}
                existingConfiguration={selectedActionForButtonStatesModal?.buttonStates}
                onSave={(actionDisabledConfig) => {
                    setActions(
                        actions?.map((action) =>
                            action.id === selectedActionForButtonStatesModal?.id
                                ? { ...selectedActionForButtonStatesModal, buttonStates: actionDisabledConfig }
                                : action,
                        ),
                    );
                }}
                onClose={() => setSelectedActionForButtonStatesModal(undefined)}
            />
        </>
    );
};

export default InterfaceCTAActionsSelector;
