import NumericBinaryOperatorBase from './NumbericBinaryOperatorBase';

import { OperatorKey } from '@tonkean/tonkean-entities';

export default class DivideOperator extends NumericBinaryOperatorBase {
    override readonly key = OperatorKey.DIVIDE;
    override readonly sign = '/';
    override readonly displayName = 'Divide';
    override readonly description = 'Will divide the left hand field by the right hand field.';
}
