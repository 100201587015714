import { useAngularService, useAngularWatch } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as EyeIcon } from '../../../../images/icons/Invisble-Eye.svg';

import { KebabMenuButton, Menu, MenuItem, Tooltip, useToggle } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';
import { FormQuestionType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    form: Form;
}

const StyledEye = styled(EyeIcon)`
    path {
        stroke: ${Theme.colors.gray_600};
    }
    circle {
        fill: ${Theme.colors.gray_600};
    }
`;

const InitiativeViewFormMenu: React.FC<Props> = ({ form }) => {
    const [open, toggleOpen] = useToggle(false);
    const projectManager = useAngularService('projectManager');
    const $location = useAngularService('$location');
    const [projectId] = useAngularWatch(() => projectManager.project.id as string);
    const [initiativeId, environment] = useAngularWatch(
        () => $location.search().tid,
        () => $location.search().env,
    );

    return (
        <Menu
            show={open}
            onClose={() => toggleOpen()}
            placement="right-start"
            menuItems={
                <Tooltip
                    disabled={form.formQuestionType !== FormQuestionType.UPLOAD_FILES}
                    content="Going to an Upload Files forms is not supported from this view"
                >
                    <MenuItem
                        disabled={form.formQuestionType === FormQuestionType.UPLOAD_FILES}
                        icon={<StyledEye />}
                        state="product.form"
                        options={{ inherit: false, location: true, notify: true }}
                        dontChangeState={false}
                        params={{
                            projectId,
                            formId: form.id,
                            initiativeId,
                            formVersionType: environment,
                        }}
                        openInNewTab
                    >
                        View Form
                    </MenuItem>
                </Tooltip>
            }
        >
            <KebabMenuButton
                onClick={(e) => {
                    e.stopPropagation();
                    toggleOpen();
                }}
                flat
            />
        </Menu>
    );
};

export default InitiativeViewFormMenu;
