import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationActionInputPredefinedParameters from './ProjectIntegrationActionInputPredefinedParameters';
import ProjectIntegrationActionPredefinedParameter from './ProjectIntegrationActionPredefinedParameter';

import { EntitySelector } from '@tonkean/angular-to-react-components';
import { H4, Input, Paragraph, Spacer, Toggle } from '@tonkean/infrastructure';
import type {
    ProjectIntegration,
    ProjectIntegrationActionChildParametersDefinition,
    ProjectIntegrationActionParametersDefinition,
} from '@tonkean/tonkean-entities';
import type { BaseActionParameter } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

const ChildEntityToggleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RequiredIndication = styled.span`
    color: red;
    font-size: 12px;
`;

const EntityNameWarning = styled.div`
    margin: 5px 0;
`;

const StyledEntityWrapper = styled.div`
    padding-top: 5px;
    padding-bottom: 20px;

    .entity-selector-react {
        width: 100% !important;
    }
`;

const defaultParameterDefinition: ProjectIntegrationActionChildParametersDefinition = {
    entityKey: 'child-entity',
    entitiesToRunOn: [],
    parameters: [],
    isChildEntitiesEnabled: false,
    isCustomFieldsEnabled: false,
    childEntitiesParameterDefinitions: [],
};

interface Props {
    projectIntegration: ProjectIntegration;
    parametersDefinition: ProjectIntegrationActionParametersDefinition;
    isNameValid: (name: string, id?: string) => boolean;
    setNewParamName: (name: string) => void;
    newParamName: string;
    removeChildParamFromList: (name: string) => void;
    updateParameter: (action: BaseActionParameter) => void;
    isModuleParams: boolean;
    onChange: (
        setParameterDefinition: (
            currentParameterDefinition: ProjectIntegrationActionParametersDefinition,
        ) => ProjectIntegrationActionParametersDefinition,
    ) => void;
    setActiveInput: () => void;
}

const ProjectIntegrationActionChildEntitiesPredefinedParameter: React.FC<Props> = ({
    projectIntegration,
    parametersDefinition,
    isNameValid,
    setNewParamName,
    newParamName,
    removeChildParamFromList,
    updateParameter,
    isModuleParams,
    onChange,
    setActiveInput,
}) => {
    const childParams: ProjectIntegrationActionChildParametersDefinition = parametersDefinition
        ?.childEntitiesParameterDefinitions[0] || { ...defaultParameterDefinition };

    return (
        <>
            <ChildEntityToggleWrapper>
                <H4 $bold>Child Entities</H4>
                <Toggle
                    checked={parametersDefinition.isChildEntitiesEnabled}
                    dataAutomation="project-integration-action-child-entities-toggle"
                    onChange={(e) => {
                        onChange((currentParameterDefinition: ProjectIntegrationActionParametersDefinition) => ({
                            ...currentParameterDefinition,
                            isChildEntitiesEnabled: !!e.target.checked,
                            childEntitiesParameterDefinitions:
                                currentParameterDefinition.childEntitiesParameterDefinitions
                                    ? [...currentParameterDefinition.childEntitiesParameterDefinitions]
                                    : [{ ...defaultParameterDefinition }],
                        }));
                    }}
                    size={InputSize.SMALL}
                />
            </ChildEntityToggleWrapper>

            {parametersDefinition.isChildEntitiesEnabled && childParams && (
                <>
                    <Paragraph $light>
                        Enable additional parameters for field values taken from the inner items level
                    </Paragraph>

                    <Spacer height={20} width={10} />

                    <Paragraph $bold>
                        Entity Name <RequiredIndication>*</RequiredIndication>
                    </Paragraph>
                    <Paragraph $light>
                        The name of the object array parameter to be used in the editor body
                        <EntityNameWarning>Default value: child-entity</EntityNameWarning>
                    </Paragraph>

                    <Input
                        placeholder="Entity Name"
                        data-automation="project-integration-action-child-entities-key"
                        value={childParams.entityKey}
                        onChange={(e) => {
                            onChange((currentParameterDefinition: ProjectIntegrationActionParametersDefinition) => {
                                const newParameterDefinition: ProjectIntegrationActionParametersDefinition = {
                                    ...currentParameterDefinition,
                                };

                                let modifiedChildDefinition: ProjectIntegrationActionChildParametersDefinition =
                                    defaultParameterDefinition;

                                // At the moment there should only be one childEntitiesParameterDefinitions
                                newParameterDefinition.childEntitiesParameterDefinitions.map((entity) => {
                                    modifiedChildDefinition = {
                                        ...entity,
                                        entityKey: e.target.value,
                                    };
                                });

                                newParameterDefinition.childEntitiesParameterDefinitions = [
                                    { ...modifiedChildDefinition },
                                ];

                                // Return the modified object
                                return newParameterDefinition;
                            });
                        }}
                    />

                    <Spacer height={20} width={10} />
                    <Paragraph $bold>Data Source Entity</Paragraph>
                    <StyledEntityWrapper>
                        <EntitySelector
                            projectIntegration={projectIntegration}
                            selectedEntity={childParams.entitiesToRunOn[0]}
                            mode="sync"
                            onEntitySelected={(selectedEntity) => {
                                onChange((currentParameterDefinition: ProjectIntegrationActionParametersDefinition) => {
                                    const newParameterDefinition: ProjectIntegrationActionParametersDefinition = {
                                        ...currentParameterDefinition,
                                    };

                                    let modifiedChildDefinition: ProjectIntegrationActionChildParametersDefinition =
                                        defaultParameterDefinition;

                                    // At the moment there should only be one childEntitiesParameterDefinitions
                                    newParameterDefinition.childEntitiesParameterDefinitions.map((entity) => {
                                        modifiedChildDefinition = {
                                            ...entity,
                                            entitiesToRunOn: [selectedEntity.entity],
                                        };
                                    });

                                    newParameterDefinition.childEntitiesParameterDefinitions = [
                                        { ...modifiedChildDefinition },
                                    ];

                                    // Return the modified object
                                    return newParameterDefinition;
                                });
                            }}
                            useReactSelect
                            allowClear
                            forceFetchSupportedEntitiesFromServer
                        />
                    </StyledEntityWrapper>
                    <Paragraph $bold>Child Parameters</Paragraph>
                    {childParams.parameters?.map((actionParam: BaseActionParameter) => {
                        return (
                            <ProjectIntegrationActionPredefinedParameter
                                key={actionParam.id}
                                parameter={actionParam}
                                onRemove={removeChildParamFromList}
                                onUpdate={updateParameter}
                                isModuleParams={isModuleParams}
                            />
                        );
                    })}

                    <Spacer height={20} width={10} />

                    <ProjectIntegrationActionInputPredefinedParameters
                        setActiveInput={setActiveInput}
                        isNameValid={isNameValid}
                        setNewParamName={setNewParamName}
                        newParamName={newParamName}
                    />
                </>
            )}
        </>
    );
};

export default ProjectIntegrationActionChildEntitiesPredefinedParameter;
