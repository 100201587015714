import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import CreateOrEditProcessMapperModal from './CreateOrEditProcessMapperModal';
import ProcessMapperListEmptyState from './ProcessMapperListEmptyState';
import ProcessMapperListItem from './ProcessMapperListItem';
import ProcessMapperLoadingState from '../entities/ProcessMapperLoadingState';
import { useProcessMapperCache } from '../utils/ProcessMapperCache';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { ErrorMessage, LoadingCircle, Message as RawMessage, Separator } from '@tonkean/infrastructure';
import type { ProcessMapper, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const CreateBar = styled.div<{ $isDraft: boolean }>`
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: ${({ $isDraft }) => ($isDraft ? 'flex-end' : 'center')};
    height: 45px;
`;

const ProcessMappersList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const Message = styled(RawMessage)`
    width: 90%;
`;

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    workflowVersionType: WorkflowVersionType;
}

const ProcessMappersWorkerOutline: React.FC<Props> = ({ workflowVersionId, workflowVersionType }) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const isDraft = workflowVersionType === WorkflowVersionType.DRAFT;
    const $state = useAngularService('$state');
    const $rootScope = useAngularService('$rootScope');

    const {
        data,
        loading: processMappersLoadingFromServer,
        error,
    } = useTonkeanService('getProcessMappersByWorkflowVersionId', workflowVersionId);

    const [projectId, groupId, solutionMapperId, createNew] = useGetStateParams<
        [TonkeanId<TonkeanType.PROJECT>, TonkeanId<TonkeanType.GROUP>, TonkeanId<TonkeanType.SOLUTION_MAPPER>, boolean]
    >('projectId', 'g', 'solutionMapperId', 'createNew');

    const workflowFolder = useMemo(() => {
        return workflowFolderManager.getContainingWorkflowFolder(projectId, groupId);
    }, [groupId, projectId, workflowFolderManager]);

    const [showCreateProcessMapperModal, setShowCreateProcessMapperModal] = useState(!!createNew);

    const {
        getProcessMappers,
        setProcessMappers,
        onProcessMapperCreated,
        onProcessMapperDeleted,
        onProcessMapperUpdated,
        setLoading,
        getLoading,
    } = useProcessMapperCache();

    useEffect(() => {
        if (data?.entities) {
            setProcessMappers(workflowVersionId, data?.entities);
        }
    }, [data?.entities, setProcessMappers, workflowVersionId]);

    useEffect(() => {
        setLoading(
            workflowVersionId,
            processMappersLoadingFromServer ? ProcessMapperLoadingState.LOADING : ProcessMapperLoadingState.IDLE,
        );
    }, [processMappersLoadingFromServer, setLoading, workflowVersionId]);

    const processMappers = getProcessMappers(workflowVersionId);

    const showLoading =
        getLoading(workflowVersionId) === ProcessMapperLoadingState.LOADING && processMappers.length === 0;
    const showEmptyState =
        getLoading(workflowVersionId) === ProcessMapperLoadingState.IDLE && processMappers.length === 0;

    const onProcessMapperIsCreated = useCallback(
        (newProcessMapper: ProcessMapper | undefined, processMapperWasCreated?: boolean) => {
            onProcessMapperCreated(newProcessMapper);
            setShowCreateProcessMapperModal(false);

            if (processMapperWasCreated) {
                $state.go('.', { processMapperId: newProcessMapper?.id, nodeId: undefined }, { location: 'replace' });
                $rootScope.$emit('onSidePaneClose');
            } else {
                $state.go('.', { createNew: undefined }, { location: 'replace' });
            }
        },
        [$rootScope, $state, onProcessMapperCreated],
    );

    return (
        <div>
            <ErrorBoundary fallback={<ErrorMessage>{error ? getStateError(error) : ''}</ErrorMessage>}>
                <CreateBar $isDraft={isDraft}>
                    {isDraft ? (
                        <Button
                            size={ButtonSize.SMALL}
                            onClick={() => setShowCreateProcessMapperModal(true)}
                            data-automation="process-mapper-list-create-button"
                            highlighted
                        >
                            New Process Map
                        </Button>
                    ) : (
                        <Message>Cannot be edited in production</Message>
                    )}
                </CreateBar>
                <Separator />
                {showEmptyState ? (
                    <ProcessMapperListEmptyState
                        onOpenNewProcessMapperModal={isDraft ? () => setShowCreateProcessMapperModal(true) : undefined}
                    />
                ) : (
                    <>
                        {showLoading ? (
                            <LoadingCircle large centered />
                        ) : (
                            <ProcessMappersList>
                                {processMappers.map((entity) => (
                                    <ProcessMapperListItem
                                        key={entity.id}
                                        workflowFolder={workflowFolder}
                                        processMapper={entity}
                                        workflowVersionType={workflowVersionType}
                                        workflowVersionId={workflowVersionId}
                                        onProcessMapperDeleted={onProcessMapperDeleted}
                                        onProcessMapperUpdated={onProcessMapperUpdated}
                                        groupId={groupId}
                                    />
                                ))}
                            </ProcessMappersList>
                        )}
                    </>
                )}
                {workflowFolder && (
                    <CreateOrEditProcessMapperModal
                        open={showCreateProcessMapperModal}
                        onClose={onProcessMapperIsCreated}
                        workflowFolder={workflowFolder}
                        groupId={groupId}
                    />
                )}
            </ErrorBoundary>
        </div>
    );
};

export default ProcessMappersWorkerOutline;
