import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import { useAsyncMethod } from '@tonkean/angular-hooks';

export interface AutoCompleteOptions {
    displayName: string;
    description: string;
    additionalData: Record<string, string>;
    value: string;
}

function useAutoCompleteOptions(
    integrationId: string,
    entityType: string,
    additionalParameters?: Record<string, string>,
) {
    const createProjectApis = useAngularService('createProjectApis');

    const { data, ...response } = useAsyncMethod(
        createProjectApis,
        'getAutoCompleteOptions',
        integrationId,
        entityType,
        additionalParameters,
    );

    const autoCompleteOptions: AutoCompleteOptions[] = useMemo(() => {
        return data?.options;
    }, [data?.options]);

    return {
        ...response,
        autoCompleteOptions,
    };
}

export default useAutoCompleteOptions;
