import type { EntityRef, FieldDefinition, Initiative, Person, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

export const ROW_ID_PREFIX = 'ITEMS_GRID_ID';
export type RowId = `${typeof ROW_ID_PREFIX}${string}`;

type InitiativeRowData = {
    tableRowId: RowId;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    _internalOriginalInitiativeId?: Promise<Initiative['id']> | undefined;
    _createdAsPlaceholder?: boolean;
    _index?: number;

    title?: string;
    titleIcon?: { name: string; icon: JSX.Element | undefined };
    owner?: EntityRef<Person> | undefined;
    created?: Date;
    dueDate?: Date;
    state?: {
        type: string;
        color: string;
        label: string;
    };
} & Record<FieldDefinition['id'], unknown>;

export const generateItemsGridId = (): RowId => `${ROW_ID_PREFIX}${Math.random().toString()}`;

export const isNotRealRow = (row: InitiativeRowData): boolean => {
    return !row.initiativeId && !row._internalOriginalInitiativeId;
};

export const extractInitiativeId = async (item: InitiativeRowData) => {
    return item.initiativeId || item._internalOriginalInitiativeId;
};

export const isPlaceholderRow = (row: InitiativeRowData): boolean => {
    return !!row._createdAsPlaceholder && !row._internalOriginalInitiativeId && !row.initiativeId;
};
export const isTempRowForAddition = (row: InitiativeRowData): boolean => {
    return !row._internalOriginalInitiativeId && !row.initiativeId && !row._createdAsPlaceholder;
};

export const isRowLoading = (row: InitiativeRowData): boolean => {
    return !!row._internalOriginalInitiativeId && !row.initiativeId;
};

export default InitiativeRowData;
