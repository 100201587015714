import template from './dropboxSelector.template.html?angularjs';

/**
 * Component for selecting a google drive.
 */
export default angular.module('tonkean.app').component('tnkDropboxSelector', {
    bindings: {
        projectIntegration: '<', // The project integration object.
        onProjectIntegrationCreated: '&', // Occurs once the creation of the project integration is finished.
        onCancel: '&', // Occurs when an integration is closed
    },
    template,
    controller: 'DropboxSelectorCtrl',
});
