<div class="custom-filters-container">
    <!-- custom-filters-container is important for the full screen capability -->
    <div
        class="custom-filters padding-normal-sm flex"
        ng-hide="hideIfNoMetadata && data.noFields"
        ng-class="{ 'mod-fullscreen': isFullscreen }"
    >
        <!-- Content -->
        <div
            ng-if="!data.forceRefresh"
            ng-init="query = control.customFiltersDefinition.query"
            class="custom-filters-content flex-grow"
        >
            <!-- Text input -->
            <input
                class="search-bar"
                type="text"
                ng-model="query.filter"
                ng-change="onFilterSearchChange(query.filter)"
                placeholder="Search..."
                ng-if="data.searchAllFields"
            />

            <span
                class="common-close-btn padding-normal"
                ng-if="isFullscreen && !hideCloseButton"
                ng-click="closeFullscreen()"
            >
                <tnk-icon src="../../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
            </span>

            <div class="custom-query">
                <tnk-custom-filters-inner-query
                    query="query"
                    error-object="errorObject"
                    force-expand="forceExpand"
                    group-name-being-edit-id="data.groupNameBeingEditId"
                    on-group-name-edit="(onGroupNameEdit)"
                    invoke-on-filter-change="invokeOnFilterChange"
                    hide-query-on-single-condition="hideQueryOnSingleCondition"
                    query-types="data.queryTypes"
                    loading-fields="data.loadingFields"
                    filter-in-focus-id="data.filterInFocusId"
                    on-filter-focus="(onFilterFocus)"
                    filters-result-map="filtersResultMap"
                    field-definition-map="data.fieldDefinitionMap"
                    project-integration-description-class="data.projectIntegrationDescriptionClass"
                    items-source="itemsSource"
                    small-mod="smallMod"
                    group-id="data.groupId"
                    target-workflow-version-id="targetWorkflowVersionId"
                    workflow-version-id="workflowVersionId"
                    consider-all-groups="considerAllGroups"
                    project-integration="projectIntegration"
                    display-integration-indication="displayIntegrationIndication"
                    selected-entity-type="selectedEntityType"
                    inspect-entity-type="inspectEntityType"
                    on-field-option-selected="(onFieldOptionSelected)"
                    add-custom-fields="addCustomFields"
                    reload-fields="data.reloadFields"
                    show-field-inspect="showFieldInspect"
                    show-field-inspect-modal="showFieldInspectModal"
                    view-only="viewOnly"
                    append-to-body="appendToBody"
                    special-fields-for-features="specialFieldsForFeatures"
                    reload-fields-on-new-field-definition-creation="reloadFieldsOnNewFieldDefinitionCreation"
                    on-condition-selection="(onConditionSelection)"
                    on-value-changed="(onValueChanged)"
                    on-second-value-changed="(onSecondValueChanged)"
                    on-expression-changed="(onExpressionChanged)"
                    tabs-field-selector-mod-narrow="tabsFieldSelectorModNarrow"
                    tabs-field-selector-mod-fixed-width="tabsFieldSelectorModFixedWidth"
                    mod-fixed-width="modFixedWidth"
                    vertical-mod="verticalMod"
                    preview-evaluation-source="previewEvaluationSource"
                    allowed-conditions-set="allowedConditionsSet"
                    not-allowed-conditions-set="notAllowedConditionsSet"
                    excluded-tab-selector-special-fields="excludedTabSelectorSpecialFields"
                    include-tab-selector-special-fields-for-features="includeTabSelectorSpecialFieldsForFeatures"
                    unique-identifier="data.uniqueIdentifier"
                    evaluate-preview="evaluatePreview"
                    selected-logic-type-configuration="data.selectedLogicTypeConfiguration"
                    remove-field-filter="removeFieldFilter"
                    add-new-aggregate-condition="addNewAggregateCondition"
                    add-new-aggregate-query="addNewAggregateQuery"
                    logic-id="logicId"
                    allow-contains="data.allowContains"
                    show-people-directories="true"
                    use-expressions-for-value="useExpressionsForValue"
                    include-viewer-fields="includeViewerFields"
                ></tnk-custom-filters-inner-query>
            </div>

            <!--<div ng-if="fromSync" class="common-size-xxxxs margin-top">* If no conditions-->
            <!--are set, all items will be-->
            <!--imported.-->
            <!--</div>-->

            <!-- Picking time range -->
            <div class="margin-bottom margin-top common-size-xxs" ng-if="!hideTimeRangeSelection">
                <input
                    type="checkbox"
                    class="margin-right"
                    ng-disabled="viewOnly"
                    ng-model="control.customFiltersDefinition.enableTimeRange"
                    ng-click="onEnabledTimeRangeToggled()"
                />
                <span
                    class="common-bold margin-right-xs common-size-xxs"
                    ng-class="{
                        'common-color-grey': !control.customFiltersDefinition.enableTimeRange,
                        'common-color-black': control.customFiltersDefinition.enableTimeRange
                    }"
                >
                    {{ editMode ? 'Time range' : 'Add a time range' }}
                </span>
                <!-- ng-style used to show and hide without divs moving -->
                <select
                    ng-model="control.customFiltersDefinition.selectedTimeRangeOption"
                    ng-disabled="viewOnly"
                    ng-change="onTimeRangeOptionSelected()"
                    ng-style="{
                        visibility: (control.customFiltersDefinition.enableTimeRange && 'visible') || 'hidden'
                    }"
                    class="form-control margin-right-xs common-width-auto inline-block"
                    ng-options="opt for opt in data.timeRangesOptions"
                ></select>
            </div>
        </div>

        <div class="light-box-container" ng-if="data.filterInFocusId" ng-click="onFilterFocus(null)">
            <div class="light-box"></div>
        </div>
    </div>
</div>
