import template from './projectIntegrationSelector.template.html?angularjs';

angular.module('tonkean.app').component('tnkProjectIntegrationSelector', {
    bindings: {
        groupId: '@', // Optional
        selectedProjectIntegrationId: '@',
        allowDefaultOption: '<',
        defaultOptionLabel: '@',
        selectorLabelPlaceholder: '@',
        filterOnIntegrationType: '<',
        onProjectIntegrationSelected: '&',
        disabled: '<',
        formDefaultSelectionDataStorageId: '@',
        displayFormDefault: '<',
        isFormDefaultSelected: '<',
        includeAllNoCodeDatasources: '<',
        isForUpload: '<',
        isForDownload: '<',
    },
    template,
    controller: 'ProjectIntegrationSelectorCtrl',
});
