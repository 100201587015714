/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';

import { H3 } from '@tonkean/infrastructure';
import { InitiativesPlaceholderContainer } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { InnerItemsWidgetIcon } from '@tonkean/svg';
import { EmptySearchIcon as SearchIcon } from '@tonkean/svg';
import type { Initiative, TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';

const EmptyState = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    svg > path {
        fill: #c4c4c4;
    }

    ${H3} {
        color: ${Theme.colors.gray_700};
    }
`;

const EmptyStateCallToAction = styled(Clickable)`
    color: ${Theme.colors.primaryHighlight};
    font-weight: 500;

    &:hover,
    &:focus {
        color: ${Theme.colors.primaryHighlight};
    }
`;
const SearchEmptyStateContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SearchEmptyStateSearchIcon = styled(SearchIcon)`
    height: 72px;
    width: 56px;
    margin-bottom: 16px;
    margin-left: -10px;
`;

const SearchEmptyStateTitle = styled(H3)`
    margin-bottom: 16px;
`;

const SearchEmptyStateSubtitle = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
`;

interface Props {
    loadingInitial: boolean | undefined;
    searchTerm?: string;
    groupId?: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId?: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    workflowVersionType?: WorkflowVersionType;
    initiatives: Initiative[];
    showEmptyState: boolean;
    userCanAddInnerItems: boolean;
    onClickAddItem: () => void;
    viewOnly: boolean;
    shouldShowGalleryView: boolean;
    numOfFieldsToDisplay?: number;
}

const InnerItemsItemWidgetEmptyStates: React.FC<Props> = ({
    loadingInitial,
    searchTerm,
    groupId,
    workflowVersionId,
    workflowVersionType,
    initiatives,
    showEmptyState,
    userCanAddInnerItems,
    onClickAddItem,
    viewOnly,
    shouldShowGalleryView,
    numOfFieldsToDisplay = 0,
}) => {
    return (
        <>
            {loadingInitial && !searchTerm ? (
                <InitiativesPlaceholderContainer />
            ) : (!groupId || !workflowVersionId || !workflowVersionType) && !loadingInitial ? (
                <EmptyState>
                    <InnerItemsWidgetIcon />
                    <H3 $bold>No item selected</H3>
                </EmptyState>
            ) : initiatives.length === 0 && showEmptyState && !loadingInitial && !searchTerm ? (
                <EmptyState>
                    <InnerItemsWidgetIcon />
                    <Tooltip content="Either you do not have any associated items or lack the necessary permissions to view them">
                        <H3 $bold>No items</H3>
                    </Tooltip>
                    {userCanAddInnerItems && !viewOnly && (
                        <EmptyStateCallToAction
                            data-automation="inner-items-item-widget-empty-states"
                            onClick={onClickAddItem}
                        >
                            + Add Item
                        </EmptyStateCallToAction>
                    )}
                </EmptyState>
            ) : initiatives.length === 0 && !loadingInitial && searchTerm ? (
                <SearchEmptyStateContainer>
                    <SearchEmptyStateSearchIcon />
                    <SearchEmptyStateTitle>No results for '{searchTerm}'</SearchEmptyStateTitle>
                    <SearchEmptyStateSubtitle>Try to refine your search</SearchEmptyStateSubtitle>
                </SearchEmptyStateContainer>
            ) : shouldShowGalleryView && numOfFieldsToDisplay <= 1 ? (
                <EmptyState>
                    <InnerItemsWidgetIcon />
                    <H3 $bold>No fields selected</H3>
                </EmptyState>
            ) : (
                <EmptyState />
            )}
        </>
    );
};

export default InnerItemsItemWidgetEmptyStates;
