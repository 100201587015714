import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { Field, SimpleErrorStateMessage, TnkSelect, useFormikField } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { MarketplaceItemLandingPageConfiguration } from '@tonkean/tonkean-entities';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

interface LandingPageOption {
    label: string;
    value: MarketplaceItemLandingPageConfiguration | undefined;
}

interface Props {
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
}

const LandingPageSelector: React.FC<Props> = ({ workflowFolderId }) => {
    const { value: landingPage, setValue: setLandingPage } = useFormikField<
        MarketplaceItemLandingPageConfiguration | undefined
    >('configuration.landingPage');

    const {
        data: solutionSitePages,
        loading: loadingPages,
        error: errorPages,
    } = useTonkeanService('getPagesOfSolutionSiteByWorkflowFolder', workflowFolderId);

    const [selectedLandingPage, setSelectedLandingPage] = useState<LandingPageOption | null>();
    const possibleLandingPages: LandingPageOption[] = useMemo(() => {
        const landingPagesOptions: LandingPageOption[] = [
            {
                label: 'Solution Page (Default)',
                value: undefined,
            },
        ];
        if (solutionSitePages) {
            // Draft pages
            const draftSitePagesOptions = solutionSitePages.entities.map((page) => {
                return {
                    label: `${page.displayName} (Site Page, Build)`,
                    value: {
                        state: 'product.solutionSiteBuilderPreview',
                        params: {
                            solutionSiteId: page.solutionSiteId,
                            solutionSitePageId: page.id,
                            env: 'DRAFT',
                        },
                    },
                };
            });
            landingPagesOptions.push(...draftSitePagesOptions);

            // Published pages
            const publishedSitePagesOptions = solutionSitePages.entities.map((page) => {
                return {
                    label: `${page.displayName} (Site Page, Production)`,
                    value: {
                        state: 'product.solutionSiteBuilderPreview',
                        params: {
                            solutionSiteId: page.solutionSiteId,
                            solutionSitePageId: page.id,
                            env: 'PUBLISHED',
                        },
                    },
                };
            });
            landingPagesOptions.push(...publishedSitePagesOptions);

            // Only way to find the actual previously selected landing page is with deep compare
            // so we use angular
            const foundPage = landingPagesOptions.find((page) => angular.equals(landingPage, page.value));
            if (foundPage) {
                setSelectedLandingPage(foundPage);
            }
        }

        return landingPagesOptions;
    }, [landingPage, solutionSitePages]);

    return (
        <>
            <StyledField label="Landing Page" informationTooltip={<></>}>
                <TnkSelect
                    placeholder="Select"
                    options={possibleLandingPages}
                    isLoading={loadingPages}
                    value={selectedLandingPage}
                    onChange={(value) => {
                        setSelectedLandingPage(value);
                        setLandingPage(value?.value);
                    }}
                    thin
                />
                {errorPages && <SimpleErrorStateMessage error={errorPages} showSmallError />}
            </StyledField>
        </>
    );
};

export default LandingPageSelector;
