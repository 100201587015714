import { analyticsWrapper } from '@tonkean/analytics';

export function SqlModalCtrl(
    $scope,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.temp = {};

    $scope.data = {
        credentials: {
            useSsl: false,
        },
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.signIn = function () {
        $scope.loading = true;
        $scope.error = null;
        createProjectApis
            .createIntegration(
                projectManager.project.id,
                $scope.currentIntegration.name,
                $scope.data.credentials,
                integrations.getIntegrationUniqueType(
                    authenticationService.currentUser.id,
                    'SQL',
                    $scope.otherIntegrationOwner,
                ),
                undefined,
            )
            .then(function (integObj) {
                $scope.integration = integObj;
                return createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.integration.id,
                    'projects',
                );
            })
            .then(function (data) {
                $scope.projects = data.options;

                if ($scope.inlineMode) {
                    $scope.ok();
                }
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.loading = false;
            });
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.projects = null;
        $scope.components = null;
    };

    $scope.ok = function () {
        let analyticsCategory = 'Config ';
        analyticsCategory += $scope.editProject ? 'Edit' : 'New';
        analyticsWrapper.track('Save Integration', {
            category: analyticsCategory,
            label: $scope.currentIntegration.name,
        });

        $scope.okClicked = true;
        $scope.integration.projectData = { dbType: $scope.data.credentials.dbType };
        const s = {
            integration: $scope.integration,
            projectData: { dbType: $scope.data.credentials.dbType },
        };
        s.integrations = [];
        s.integrations.push(s);

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
        }
    }
}
export default angular.module('tonkean.shared').controller('SqlModalCtrl', SqlModalCtrl);
