import { lateConstructController } from '@tonkean/angular-components';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';

/* @ngInject */
function WorkflowMatchByFieldCtrl($scope, utils, customFieldsManager) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        viewOnly: ctrl.viewOnly,
        entityMetadata: ctrl.entityMetadata,
        projectIntegration: ctrl.projectIntegration,

        entityMatchField: ctrl.entityMatchField,
        workerItemMatchFieldDefinition: ctrl.workerItemMatchFieldDefinition,
        workerItemMatchSpecialField: ctrl.workerItemMatchSpecialField,

        workerItemMatchField: null,
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        initEditMode();
    };

    /**
     * Occurs on changes to the component bindings.
     */
    ctrl.$onChanges = function (changesObj) {
        let reinitiateEditMode = false;
        if (changesObj.workflowVersionId) {
            $scope.data.workflowVersionId = ctrl.workflowVersionId;
        }
        if (changesObj.entityMetadata) {
            $scope.data.entityMetadata = ctrl.entityMetadata;
        }
        if (changesObj.entityMatchField) {
            $scope.data.entityMatchField = ctrl.entityMatchField;
            reinitiateEditMode = !changesObj.entityMatchField.isFirstChange();
        }
        if (changesObj.workerItemMatchFieldDefinition) {
            $scope.data.workerItemMatchFieldDefinition = ctrl.workerItemMatchFieldDefinition;
            reinitiateEditMode = !changesObj.workerItemMatchFieldDefinition.isFirstChange();
        }
        if (changesObj.workerItemMatchSpecialField) {
            $scope.data.workerItemMatchSpecialField = ctrl.workerItemMatchSpecialField;
            reinitiateEditMode = !changesObj.workerItemMatchSpecialField.isFirstChange();
        }

        if (reinitiateEditMode) {
            initEditMode();
        }
    };

    /**
     * Occurs once a matched field is selected.
     */
    $scope.onEntityMatchFieldSelected = function (selectedField, dontSaveChanges) {
        $scope.data.entityMatchField = selectedField;
        $scope.onWorkflowMatchByFieldChanged(!dontSaveChanges);
    };

    /**
     * Occurs once worker item match field is selected.
     */
    $scope.onWorkerItemMatchFieldSelected = function (selectedField) {
        $scope.data.workerItemMatchField = selectedField;
        $scope.onWorkflowMatchByFieldChanged(true);
    };

    $scope.onAdvancedMatchingChanged = function (shouldNotSaveLogic) {
        const selectionObject = {};

        if ($scope.data.customQueryControl && $scope.data.customQueryControl.createDefinitionFromCustomFilters) {
            selectionObject.customQuery = $scope.data.customQueryControl.createDefinitionFromCustomFilters();
            selectionObject.customQuery.entity =
                $scope.data.entityMetadata && $scope.data.entityMetadata.entity
                    ? $scope.data.entityMetadata.entity
                    : $scope.data.entityMetadata;
            selectionObject.customQuery.entityMetadata =
                $scope.data.entityMetadata && $scope.data.entityMetadata.entity
                    ? $scope.data.entityMetadata
                    : { entity: $scope.data.entityMetadata };
        }

        $scope.onWorkflowMatchByFieldChanged(!shouldNotSaveLogic);
    };

    /**
     * Occurs on changes of the field match conditions.
     */
    $scope.onWorkflowMatchByFieldChanged = function (shouldSaveLogic) {
        if (ctrl.onWorkflowMatchByFieldChanged) {
            ctrl.onWorkflowMatchByFieldChanged({
                entityMatchField: $scope.data.entityMatchField,
                workerItemMatchFieldDefinition:
                    $scope.data.workerItemMatchField && !$scope.data.workerItemMatchField.isSpecialField
                        ? $scope.data.workerItemMatchField.id
                        : null,
                workerItemMatchSpecialField:
                    $scope.data.workerItemMatchField && $scope.data.workerItemMatchField.isSpecialField
                        ? $scope.data.workerItemMatchField.id
                        : null,
                shouldSaveLogic,
            });
        }
    };

    function initEditMode() {
        if (
            $scope.data.entityMatchField ||
            $scope.data.workerItemMatchFieldDefinition ||
            $scope.data.workerItemMatchSpecialField
        ) {
            if ($scope.data.workerItemMatchFieldDefinition) {
                const fieldDefinitionMap = utils.createMapFromArray(
                    customFieldsManager.selectedFieldsMap[$scope.data.workflowVersionId],
                    'id',
                );
                $scope.data.workerItemMatchField = fieldDefinitionMap[$scope.data.workerItemMatchFieldDefinition];
            }

            if ($scope.data.workerItemMatchSpecialField) {
                const specialFieldsMap = utils.createMapFromArray(getSpecialFieldsForFeatures(true), 'id');
                $scope.data.workerItemMatchField = specialFieldsMap[$scope.data.workerItemMatchSpecialField];
            }
        }

        $scope.onWorkflowMatchByFieldChanged(false);
    }
}

export default angular.module('tonkean.app').controller('WorkflowMatchByFieldCtrl', lateConstructController(WorkflowMatchByFieldCtrl));
