import styled from 'styled-components';

const sizeMapping = {
    large: {
        circleSize: '18px',
        fontSize: '16px',
        paddingSize: '6px',
    },
    medium: {
        circleSize: '14px',
        fontSize: '12px',
        paddingSize: '4px',
    },
    small: {
        circleSize: '10px',
        fontSize: '8px',
        paddingSize: '2px',
    },
};

interface Props {
    size?: keyof typeof sizeMapping;
    textColor?: string;
    backgroundColor?: string;
}

const TnkChip = styled.span<Props>`
    // Reset all CSS rules
    all: unset;

    display: inline-block;
    height: ${({ size = 'medium' }) => sizeMapping[size].circleSize};
    min-width: ${({ size = 'medium' }) => sizeMapping[size].circleSize};
    padding: ${({ size = 'medium' }) => sizeMapping[size].paddingSize};
    border-radius: 30px;
    text-align: center;
    background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'red')};
    color: ${({ textColor }) => (textColor ? textColor : 'green')};
    font-size: ${({ size = 'medium' }) => sizeMapping[size].fontSize};
    font-weight: bold;
    font-family: 'Roboto', serif;
`;

export default TnkChip;
