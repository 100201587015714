import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type { ShareBusinessReportFormElements } from './BusinessReportShareModal';
import { shareBusinessReportFormFields } from './BusinessReportShareModal';
import BusinessReportShareModalTabs from '../../types/BusinessReportShareModalTabs';

import { DeprecatedPeopleSelectorWrapper } from '@tonkean/angular-to-react-components';
import { Radio, RadioGroup, Spacer } from '@tonkean/infrastructure';
import type { Person } from '@tonkean/tonkean-entities';
import {
    businessReportSharePermissionTypeDisplayName,
    SolutionBusinessReportAccessPermission,
} from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Text = styled.div`
    font-style: italic;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
`;

const StyledPeopleSelectorWrapper = styled(DeprecatedPeopleSelectorWrapper)`
    width: 100%;
`;

interface Props {
    permissionTab: BusinessReportShareModalTabs;
}

const PermissionTypeInputs: React.FC<Props> = ({ permissionTab }) => {
    const { setFieldValue, values } = useFormikContext<ShareBusinessReportFormElements>();

    const getUpdatePeopleFunc = (name: string) => (people: Person[]) => {
        setFieldValue(name, people);
    };

    const permissionTabFieldName = useMemo(() => {
        switch (permissionTab) {
            case BusinessReportShareModalTabs.VIEW_PERMISSIONS: {
                return shareBusinessReportFormFields.viewPermissions;
            }
            case BusinessReportShareModalTabs.EDIT_PERMISSIONS: {
                return shareBusinessReportFormFields.editPermissions;
            }
            default: {
                throw new Error('no tab found!');
            }
        }
    }, [permissionTab]);

    return (
        <>
            <RadioGroup name={`${permissionTabFieldName}.accessType`} size={InputSize.MEDIUM}>
                <Radio value={SolutionBusinessReportAccessPermission.ALL_PROCESS_CONTRIBUTORS}>
                    {
                        businessReportSharePermissionTypeDisplayName[
                            SolutionBusinessReportAccessPermission.ALL_PROCESS_CONTRIBUTORS
                        ]
                    }
                </Radio>
                <Radio value={SolutionBusinessReportAccessPermission.SPECIFIC_PEOPLE}>
                    {
                        businessReportSharePermissionTypeDisplayName[
                            SolutionBusinessReportAccessPermission.SPECIFIC_PEOPLE
                        ]
                    }
                </Radio>
                {values[permissionTabFieldName].accessType ===
                    SolutionBusinessReportAccessPermission.SPECIFIC_PEOPLE && (
                    <div style={{ width: '100%' }}>
                        <StyledPeopleSelectorWrapper
                            selectedPeople={values[permissionTabFieldName].peopleChosen as unknown as Person[]}
                            disabled={false}
                            onPeopleAltered={getUpdatePeopleFunc(`${permissionTabFieldName}.peopleChosen`)}
                            noCustom
                            doNotSearchOnlyOwnersInGroup
                            onlyProcessContributors
                        />
                        <Spacer height={8} />
                    </div>
                )}
                <Radio value={SolutionBusinessReportAccessPermission.NO_ONE}>
                    {businessReportSharePermissionTypeDisplayName[SolutionBusinessReportAccessPermission.NO_ONE]}
                </Radio>
            </RadioGroup>
            <Spacer height={16} />
            <Text>Users will only see items they created</Text>
        </>
    );
};

export default PermissionTypeInputs;
