<div class="fields-configuration-interface">
    <!-- Displaying all fields one by one -->
    <div ng-repeat="field in data.fieldsConfiguration.fields">
        <!-- Display name -->
        <div class="margin-bottom-xs flex flex-vmiddle">
            <div>{{ field.displayName }}:</div>
            <i
                ng-if="field.tooltipDescription"
                class="fa fa-info-circle margin-left-xs"
                uib-tooltip="{{ field.tooltipDescription }}"
            ></i>
        </div>

        <!-- Expression input -->
        <div ng-if="field.type === integrationsConsts.presentationalComponentType.tonkeanExpressionInput.id">
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.logicId }}"
                placeholder="{{ field.placeholder }}"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.tonkeanExpressionsValuesMap[field.id]"
                expression-unique-identifier="{{ field.id }}"
                on-tonkean-expression-changed="
                    onTonkeanExpressionChanged(expression, expressionIdentifier, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Field validation error -->
        <div class="margin-top-xs margin-bottom-xs common-color-danger" ng-if="data.validationObject[field.id]">
            {{ data.validationObject[field.id] }}
        </div>
    </div>

    <!-- All fields validation error -->
    <div class="margin-top-xs margin-bottom-xs common-color-danger" ng-if="data.validationObject.generalError">
        {{ data.validationObject.generalError }}
    </div>
</div>
