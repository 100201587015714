import React from 'react';

import { useItemDetailsModuleEditorIcon_Query_IconDataQuery } from './itemDetailsModuleEditorIcon_query_iconData.graphql';
import { ReactComponent as FormGradientIcon } from '../../../../../images/icons/form-gradient-fill.svg';
import { ReactComponent as DatasourceTriggerIcon } from '../../../../../images/icons/module-editor-item-details-datasource-trigger-icon.svg';
import { ReactComponent as ManualIcon } from '../../../../../images/icons/module-editor-item-details-icon.svg';
import { ReactComponent as ScheduledTriggerIcon } from '../../../../../images/icons/module-editor-item-details-scheduled-trigger-icon.svg';

import { IconSvg } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { DataSourceType } from '@tonkean/tonkean-entities';

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    sizeModifier?: number;
}

const ItemDetailsModuleEditorIcon: React.FC<Props> = ({ workflowVersionId, sizeModifier = 1 }) => {
    const [
        {
            data: { workflowVersion },
        },
    ] = useItemDetailsModuleEditorIcon_Query_IconDataQuery({ variables: { workflowVersionId } });

    if (workflowVersion.isScheduled) {
        return <IconSvg as={ScheduledTriggerIcon} size={36 * sizeModifier} />;
    }

    if (
        workflowVersion.dataSourceType === DataSourceType.INTEGRATION &&
        workflowVersion.syncConfig?.projectIntegration
    ) {
        return <IconSvg as={DatasourceTriggerIcon} size={36 * sizeModifier} />;
    }

    if (workflowVersion.dataSourceType === DataSourceType.FORMS) {
        return <IconSvg as={FormGradientIcon} size={38 * sizeModifier} />;
    }

    return <IconSvg as={ManualIcon} size={44 * sizeModifier} />;
};

export default ItemDetailsModuleEditorIcon;
