import React, { useMemo, useState } from 'react';

import type { ImageWidgetDisplayBaseProps } from './ImageWidgetDisplayBase';
import { ImageWrapper, StyledImage } from './ImageWidgetDisplayBase';
import ImageWidgetLoadingSkeleton from './ImageWidgetLoadingSkeleton';
import { ItemWidget } from '../../../WidgetModule';
import type { ImageInputType } from '../../entities';
import { useStreamServiceImage } from '../../hooks';

import { StateMessage } from '@tonkean/infrastructure';
import { useInitiativeExpressionServerEvaluation } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { EmptyImage } from '@tonkean/svg';
import type { ProjectIntegration, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionDefinition } from '@tonkean/tonkean-entities';

interface Props extends ImageWidgetDisplayBaseProps {
    imageInputType?: ImageInputType;
    imageStreamSelectedActionId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>;
    imageStreamSelectedProjectIntegration?: ProjectIntegration;
    imageExternalIdentifier?: TonkeanExpressionDefinition;
}

const StreamActionImageWidgetDisplay: React.FC<Props> = ({
    isUrlExpressionValid,
    loadingExpressions,
    permission,
    altText,
    url,
    originalExpression,
    imageStreamSelectedActionId,
    imageStreamSelectedProjectIntegration,
    imageExternalIdentifier,
}) => {
    const [imageError, setImageError] = useState<boolean>(false);

    const urlIsInvalid = !isUrlExpressionValid || imageError;

    const { initiative } = useItemInterfaceContext();
    const memoImageExternalIdentifier = useMemo(() => {
        if (imageExternalIdentifier) {
            return [imageExternalIdentifier];
        } else {
            return [];
        }
    }, [imageExternalIdentifier]);

    const {
        values: [imageExternalIdExpression],
    } = useInitiativeExpressionServerEvaluation(memoImageExternalIdentifier, initiative);

    const fetchedImageUrl = useStreamServiceImage(
        url || undefined,
        imageStreamSelectedProjectIntegration?.id,
        imageStreamSelectedProjectIntegration?.projectId,
        imageExternalIdExpression,
        imageStreamSelectedActionId,
    );

    const borderlessWidgetView = loadingExpressions || !urlIsInvalid;
    return (
        <ItemWidget
            permission={permission}
            noPaddingBody={borderlessWidgetView}
            noBorderBody={borderlessWidgetView}
            noBackgroundBody={borderlessWidgetView}
            disableMaxHeight
            disableMinHeight
        >
            {loadingExpressions && !url && !!fetchedImageUrl ? (
                <ImageWidgetLoadingSkeleton />
            ) : (
                <ImageWrapper>
                    {!urlIsInvalid && url && originalExpression && fetchedImageUrl && (
                        <StyledImage
                            src={fetchedImageUrl}
                            alt={altText}
                            title={altText}
                            onError={() => setImageError(true)}
                        />
                    )}
                    {((!loadingExpressions && urlIsInvalid) || !originalExpression) && (
                        <StateMessage icon={<EmptyImage />} title="Image content not found">
                            The source URL configured for this content might be invalid
                        </StateMessage>
                    )}
                </ImageWrapper>
            )}
        </ItemWidget>
    );
};

export default StreamActionImageWidgetDisplay;
