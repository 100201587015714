import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import PaginatedAuditLogItemsList from './PaginatedAuditLogItemsList';
import type AuditLogFilterSearchQuery from '../../../../entities/AuditLogFilterSearchQuery';
import AuditLogType from '../../../../entities/AuditLogType';
import type AuditLogsGroupItemsResponse from '../../../UserActionItem/entities/responses/AuditLogsGroupItemsResponse';
import NoAuditLogsMessage from '../loaders/NoAuditLogsMessage';

import { LIMIT_PARAM, SKIP_PARAM, useFetchManager } from '@tonkean/infrastructure';
import type { TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    enterpriseComponentId: string;
    enterpriseComponentType: TonkeanType;
    pageSize: number;
    total: number;
    filterSearch: AuditLogFilterSearchQuery;
    logType: AuditLogType;
    isCollapseAllActive?: boolean;
    setIsCollapseAllActive: Dispatch<SetStateAction<boolean>>;
}

const logTypeToNoAuditLogsSubtitle = {
    [AuditLogType.USER_ACTION]:
        'Once changes are made in this source configurations you will be able to view them here',
    [AuditLogType.SYSTEM]: 'Once this data source will be updated you will be able to view the changes here',
};

const EnterpriseComponentAuditLogFlatChangesList: React.FC<Props> = ({
    enterpriseComponentId,
    enterpriseComponentType,
    pageSize,
    total,
    filterSearch,
    logType,
    isCollapseAllActive = false,
    setIsCollapseAllActive,
}) => {
    const auditLogService = useAngularService('auditLogService');
    const projectManager = useAngularService('projectManager');

    const [
        [getAuditLogItems],
        {
            data: auditLogsItems,
            loadNextPage: loadNextItemsPage,
            hasMorePages: hasMoreItemsPages,
            loading: loadingItems,
        },
    ] = useFetchManager(auditLogService, 'getAuditLogItems', {
        getItems: (auditLogs: AuditLogsGroupItemsResponse) => auditLogs.entities,
        limit: pageSize,
    });

    useEffect(() => {
        getAuditLogItems(
            projectManager.project.id,
            enterpriseComponentId,
            enterpriseComponentType,
            SKIP_PARAM,
            LIMIT_PARAM,
            logType,
            filterSearch,
        );
    }, [
        projectManager.project.id,
        filterSearch,
        enterpriseComponentId,
        getAuditLogItems,
        enterpriseComponentType,
        logType,
    ]);

    return (
        <>
            {!loadingItems.any && !auditLogsItems.length && (
                <NoAuditLogsMessage
                    filterSearch={filterSearch}
                    emptyMessageSubtitle={logTypeToNoAuditLogsSubtitle[logType]}
                />
            )}

            <PaginatedAuditLogItemsList
                items={auditLogsItems}
                loading={loadingItems.any}
                hasMore={hasMoreItemsPages}
                loadNextPage={loadNextItemsPage}
                pageSize={pageSize}
                itemsCount={total}
                searchTerm={filterSearch.search}
                logType={logType}
                isCollapseAllActive={isCollapseAllActive}
                setIsCollapseAllActive={setIsCollapseAllActive}
                showGroupLabel
            />
        </>
    );
};

export default EnterpriseComponentAuditLogFlatChangesList;
