export enum SharePointPermissionLevel {
    ALL_SITES = 'all_sites',
    SELECTED_SITES = 'selected_sites',
}

export const sharePointPermissionLevelToDisplayName: Record<SharePointPermissionLevel, string> = {
    [SharePointPermissionLevel.ALL_SITES]: 'All Sites',
    [SharePointPermissionLevel.SELECTED_SITES]: 'Selected Site',
};

export default SharePointPermissionLevel;
