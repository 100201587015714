import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function ChannelOrPersonSelectorCtrl(
    $scope,
    communicationIntegrationsService,
    projectManager,
    authenticationService,
    utils,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        logicId: ctrl.logicId,
        savedChannelOrPersonSelection: ctrl.savedChannelOrPersonSelection,
        showReplyInFlowItemThread: ctrl.showReplyInFlowItemThread,
        showReplyInResumeThread: ctrl.showReplyInResumeThread,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        validationObject: ctrl.validationObject,
        hideFailIfNoValue: ctrl.hideFailIfNoValue,
        showSendTo: ctrl.showSendTo,

        personSelectionConfiguration: null,
        selectedChannel: null,
        selectedPrivateChannel: null,
        targetType: null,
        channelVisibilityType: 'publicChannel',

        projectHasCommunicationIntegration: false,
        possibleCommunicationIntegrationsNamesString: null,

        automationIdentifier: ctrl.automationIdentifier,

        // preview
        doNotEvaluatePreview: ctrl.doNotEvaluatePreview,
        hidePreview: ctrl.hidePreview,
        exampleInitiative: null,

        uniqueIdentifier: ctrl.uniqueIdentifier,

        selectedReplyInThreadOption: null,
        replyInThreadOptions: [
            {
                key: 'flow-item',
                displayName: 'Root Monitored Item',
                disabledTooltip: 'Only available when the data source is Slack or MS Teams Messages',
                shouldDisplay: ctrl.showReplyInFlowItemThread,
            },
            {
                key: 'resume',
                displayName: 'Thread of previous block',
                disabledTooltip: 'Only available when one of the parent logic blocks sends a message',
                shouldDisplay: ctrl.showReplyInResumeThread,
            },
        ],
        communicationProjectIntegration: null,
        communicationProjectIntegrationSelector: ctrl.communicationProjectIntegrationSelector,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        initializeCommunicationIntegrationsVariables();
        initializeEditMode();

        if (!$scope.data.targetType) {
            $scope.data.targetType = 'person';
        }

        $scope.onChannelOrPersonSelectionChanged(false);

        if (ctrl.overrideExampleInitiative) {
            $scope.data.exampleInitiative = ctrl.overrideExampleInitiative;
        }

        $scope.data.communicationProjectIntegration = getCommunicationProjectIntegration();

        resetChannelTypeSelectionForPrivateInMST();
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }

        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = ctrl.configuredLogic;
        }

        if (changesObj.savedChannelOrPersonSelection && changesObj.savedChannelOrPersonSelection.currentValue) {
            $scope.data.savedChannelOrPersonSelection = ctrl.savedChannelOrPersonSelection;
            initializeEditMode();
        }

        if (
            changesObj.overrideExampleInitiative &&
            !utils.isNullOrUndefined(changesObj.overrideExampleInitiative.currentValue)
        ) {
            $scope.data.exampleInitiative = ctrl.overrideExampleInitiative;
        }

        if (changesObj.automationIdentifier) {
            $scope.data.automationIdentifier = ctrl.automationIdentifier;
        }
    };

    /**
     * Occurs once a target type is selected.
     */
    $scope.selectTargetType = function (typeName) {
        $scope.data.targetType = typeName;

        // Must save because we want to delete the old settings (so validation would fail now)
        $scope.onChannelOrPersonSelectionChanged(true);
    };

    $scope.onCommunicationProjectIntegrationChanged = function (selectedCommunicationProjectIntegration) {
        $scope.data.communicationProjectIntegration = selectedCommunicationProjectIntegration;
        $scope.data.selectedChannel = {};
        $scope.data.selectedPrivateChannel = null;

        resetChannelTypeSelectionForPrivateInMST();

        if (ctrl.onCommunicationProjectIntegrationChanged) {
            ctrl.onCommunicationProjectIntegrationChanged({
                selectedCommunicationProjectIntegration,
            });
        }
    };

    function getCommunicationProjectIntegration() {
        // This check is for a custom notification on an action.
        // Should happen first because the next check will override it if its a people's action
        let communicationProjectIntegration = projectManager.getProjectIntegrationById(
            $scope.data.savedChannelOrPersonSelection?.communicationProjectIntegrationId,
        );

        if (!communicationProjectIntegration && $scope.data?.configuredLogic?.node?.customTriggerActions?.[0]) {
            communicationProjectIntegration = projectManager.getProjectIntegrationById(
                $scope.data.configuredLogic.node.customTriggerActions[0].customTriggerActionDefinition
                    .communicationProjectIntegrationId,
            );
        }

        return communicationProjectIntegration;
    }

    /**
     * Occurs once a channel visibility type is selected.
     */
    $scope.selectChannelVisibilityType = function (channelVisibilityType) {
        $scope.data.channelVisibilityType = channelVisibilityType;
        $scope.onChannelOrPersonSelectionChanged(false);
    };

    /**
     * Occurs once a private channel is selected.
     */
    $scope.onPrivateChannelSelected = function (channelSelectionObject) {
        if (channelSelectionObject && channelSelectionObject.validatedPrivateChannel) {
            $scope.data.selectedPrivateChannel = channelSelectionObject.selectedChannel;
            $scope.onChannelOrPersonSelectionChanged(true);
        } else {
            $scope.data.selectedPrivateChannel = null;
            $scope.onChannelOrPersonSelectionChanged(false);
        }
    };

    /**
     * Occurs once a communication channel is selected.
     */
    $scope.onCommunicationChannelSelected = function (channel) {
        $scope.data.selectedChannel = channel;
        $scope.onChannelOrPersonSelectionChanged(true);
    };

    /**
     * Occurs once the person selection configuration is changed.
     */
    $scope.onPersonSelectionConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        $scope.data.personSelectionConfiguration = personSelectionConfiguration;
        $scope.onChannelOrPersonSelectionChanged(shouldSaveLogic);
    };

    $scope.onCustomChannelTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.selectedCustomChannel = expression;

        $scope.onChannelOrPersonSelectionChanged(shouldSaveLogic);
    };

    $scope.onSelectedReplyInThreadOptionChanged = function () {
        $scope.onChannelOrPersonSelectionChanged(true);
    };

    $scope.replyThreadDisplayFilter = function (option) {
        return option.shouldDisplay;
    };

    /**
     * Occurs on definition changed.
     */
    $scope.onChannelOrPersonSelectionChanged = function (shouldSaveLogic) {
        if (ctrl.onChannelOrPersonSelectionChanged) {
            let channelOrPersonSelectionConfiguration = {};
            if ($scope.data.targetType === 'person') {
                channelOrPersonSelectionConfiguration = angular.copy($scope.data.personSelectionConfiguration);
            }
            if (!channelOrPersonSelectionConfiguration) {
                channelOrPersonSelectionConfiguration = {};
            }

            channelOrPersonSelectionConfiguration.targetType = $scope.data.targetType;

            // Channel settings
            if ($scope.data.targetType === 'channel') {
                switch ($scope.data.channelVisibilityType) {
                    case 'publicChannel':
                        if ($scope.data.selectedChannel) {
                            channelOrPersonSelectionConfiguration.notificationSettings = {
                                channelId: $scope.data.selectedChannel.value,
                                channelName: $scope.data.selectedChannel.displayName,
                                channelType: 'PUBLIC_CHANNEL',
                            };
                        }
                        break;

                    case 'privateChannel':
                        if ($scope.data.selectedPrivateChannel) {
                            channelOrPersonSelectionConfiguration.notificationSettings = {
                                channelName: $scope.data.selectedPrivateChannel,
                                channelType: 'PRIVATE_CHANNEL',
                            };
                        }
                        break;

                    case 'customChannel':
                        if ($scope.data.selectedCustomChannel) {
                            channelOrPersonSelectionConfiguration.notificationSettings = {
                                channelNameExpression: $scope.data.selectedCustomChannel,
                                channelType: 'CUSTOM_CHANNEL',
                            };
                        }
                        break;

                    default:
                        break;
                }
            }

            // Thread settings
            if ($scope.data.targetType === 'thread') {
                channelOrPersonSelectionConfiguration.replyToRootMonitoredItem =
                    $scope.data.selectedReplyInThreadOption?.key === 'flow-item';
                channelOrPersonSelectionConfiguration.replyToPreviousMessage =
                    $scope.data.selectedReplyInThreadOption?.key === 'resume';
            }

            channelOrPersonSelectionConfiguration.communicationProjectIntegrationId =
                $scope.data.personSelectionConfiguration?.communicationProjectIntegrationId;

            ctrl.onChannelOrPersonSelectionChanged({
                channelOrPersonSelectionConfiguration,
                shouldSaveLogic,
            });
        }
    };

    /**
     * Initializes the edit mode of the component.
     */
    function initializeEditMode() {
        if ($scope.data.savedChannelOrPersonSelection) {
            if ($scope.data.savedChannelOrPersonSelection.notificationSettings) {
                $scope.data.targetType = 'channel';
                const settings = $scope.data.savedChannelOrPersonSelection.notificationSettings;

                switch (settings.channelType) {
                    case 'PUBLIC_CHANNEL': {
                        $scope.data.channelVisibilityType = 'publicChannel';

                        $scope.data.selectedChannel = {
                            value: settings.channelId,
                            displayName: settings.channelName,
                        };

                        break;
                    }
                    case 'PRIVATE_CHANNEL': {
                        $scope.data.channelVisibilityType = 'privateChannel';
                        $scope.data.selectedPrivateChannel = settings.channelName;

                        break;
                    }
                    case 'CUSTOM_CHANNEL': {
                        $scope.data.channelVisibilityType = 'customChannel';
                        $scope.data.selectedCustomChannel =
                            $scope.data.savedChannelOrPersonSelection.notificationSettings.channelNameExpression;

                        break;
                    }
                    // No default
                }
            } else if (
                $scope.data.savedChannelOrPersonSelection.initiativeOwner ||
                $scope.data.savedChannelOrPersonSelection.specificPeopleIds ||
                $scope.data.savedChannelOrPersonSelection.personEmailFieldDefinitionId ||
                $scope.data.savedChannelOrPersonSelection.personEmailExpressionDefinition
            ) {
                $scope.data.targetType = 'person';
            } else if (
                $scope.data.savedChannelOrPersonSelection.replyToPreviousMessage ||
                $scope.data.savedChannelOrPersonSelection.replyToRootMonitoredItem
            ) {
                $scope.data.targetType = 'thread';
                const requestedKey = $scope.data.savedChannelOrPersonSelection.replyToRootMonitoredItem
                    ? 'flow-item'
                    : 'resume';
                $scope.data.selectedReplyInThreadOption = $scope.data.replyInThreadOptions.find(
                    (option) => option.key === requestedKey,
                );
            } else {
                $scope.data.selectedChannel = {};
            }
        }
    }

    /**
     * Initializes all the variables relevant to communication integrations.
     */
    function initializeCommunicationIntegrationsVariables() {
        $scope.data.projectHasCommunicationIntegration = communicationIntegrationsService.anyIntegrationsConnected();

        const projectIntegration = communicationIntegrationsService.getFirstIntegration();
        if (projectIntegration) {
            $scope.data.possibleCommunicationIntegrationsNamesString = projectIntegration.displayName;
        } else {
            $scope.data.possibleCommunicationIntegrationsNamesString = communicationIntegrationsService
                .getIntegrationTypesShortNames()
                .join('\\');
        }
    }

    function resetChannelTypeSelectionForPrivateInMST() {
        if (
            $scope.data.communicationProjectIntegration?.integrationType === 'MICROSOFT_TEAMS' &&
            $scope.data.channelVisibilityType === 'privateChannel'
        ) {
            $scope.data.channelVisibilityType = undefined;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('ChannelOrPersonSelectorCtrl', lateConstructController(ChannelOrPersonSelectorCtrl));
