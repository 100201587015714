import getIndexedCollection from './getIndexedCollection';

/**
 * Returns a multi dimension collection that is made of the given elements with an incremental index for each of
 * the inner collections (with regards to the single collection and not the entirety of the two dimensional collection).
 */
export default function getIndexedMultiDimensionCollection<T>(
    twoDimensionalCollection: T[][],
): { index: number; collection: { index: number; original: T }[] }[] {
    return twoDimensionalCollection.map((collection, index) => {
        return {
            collection: getIndexedCollection(collection),
            index,
        };
    });
}
