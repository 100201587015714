import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import CollectParametersView from './CollectParametersView';
import NextPageParameters from './NextPageParameters';
import StopCriteria from './StopCriteria';

import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { H4, Placeholder, Toggle } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const PaginationWrapper = styled.div`
    padding-top: 36px;
    padding-bottom: 10px;
`;

const ToggleWrapper = styled.div`
    margin-left: 16px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 17px;
`;

interface Props {
    name: string;
    expressionProps?: FormikExpressionProps;
    isLoading: boolean;
}

const Pagination: React.FC<Props> = ({ name, expressionProps, isLoading }) => {
    const [{ value: enabled }] = useField(`${name}.enabled`);

    return (
        <PaginationWrapper>
            <HeaderWrapper>
                <H4 $bold>Pagination</H4>
                <ToggleWrapper>
                    {!isLoading && <Toggle name={`${name}.enabled`} size={InputSize.MEDIUM} showInnerLabel />}
                    {isLoading && <Placeholder $width="36px" />}
                </ToggleWrapper>
            </HeaderWrapper>
            {enabled && !isLoading && (
                <>
                    <StopCriteria name={`${name}.stopCriteria`} />
                    <FormikTonkeanExpression
                        {...expressionProps}
                        name={`${name}.stopCriteriaExpression`}
                        placeholder="Condition to Stop On"
                    />
                    <CollectParametersView name={`${name}.nextPageParameters`} />
                    <NextPageParameters expressionProps={expressionProps} name={`${name}.nextPageParameters`} />
                </>
            )}
        </PaginationWrapper>
    );
};

export default Pagination;
