import { useMemo } from 'react';

import type { CustomColor } from '../components/TUI/ColorPicker';

import type { GlobalColor } from '@tonkean/tonkean-entities';

const useFilterOutHiddenColors: (allCustomColors: GlobalColor[]) => CustomColor[] = (allCustomColors) => {
    return useMemo(() => {
        return allCustomColors.filter((color) => !color.isHidden);
    }, [allCustomColors]);
};

export default useFilterOutHiddenColors;
