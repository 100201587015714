import { analyticsWrapper } from '@tonkean/analytics';

function InitiativeSettingsCtrl($scope, $rootScope, trackHelper, $attrs) {
    $scope.data = {
        advanceNextReminder: $scope.showSetReminder,
        datePickerOptions: {
            showWeeks: false,
            minDate: new Date(),
        },
        dateIsInPast: false,
        selectedFollowUpType: 'AUTO',
        followUpTypes: {
            auto: 'AUTO',
            recurring: 'RECURRING',
        },
        daysOptions: {
            MONDAY: {
                label: 'Mon',
                isSelected: false,
            },
            TUESDAY: {
                label: 'Tue',
                isSelected: false,
            },
            WEDNESDAY: {
                label: 'Wed',
                isSelected: false,
            },
            THURSDAY: {
                label: 'Thu',
                isSelected: false,
            },
            FRIDAY: {
                label: 'Fri',
                isSelected: false,
            },
        },
    };

    $scope.$watch('initiative.updated', function () {
        if ($scope.initiative) {
            if (!$scope.initiative.gatherUpdatesPreferences) {
                $scope.data.selectedFollowUpType = $scope.data.followUpTypes.auto;
            } else {
                $scope.data.selectedFollowUpType = $scope.data.followUpTypes.recurring;
                const preferences = $scope.initiative.gatherUpdatesPreferences;
                for (const dayKey of preferences) {
                    $scope.data.daysOptions[dayKey].isSelected = true;
                }
            }

            if ($scope.data.advanceNextReminder && $scope.initiative.nextGatherUpdate) {
                $scope.data.reminderDate = new Date($scope.initiative.nextGatherUpdate);
            }
        }
    });

    $scope.showAdvanceNextReminder = function (item) {
        if (item.nextGatherUpdate) {
            $scope.data.reminderDate = new Date(item.nextGatherUpdate);
        }

        $scope.data.advanceNextReminder = true;
    };

    $scope.setReminder = function () {
        if ($scope.data.reminderDate && $scope.data.reminderDate <= new Date()) {
            $scope.data.dateIsInPast = true;
            return;
        }

        const dateTime = $scope.data.reminderDate ? $scope.data.reminderDate.getTime() : null;

        // make sure to close the view for selecting a custom next reminder
        $scope.data.advanceNextReminder = false;
        if ($scope.manualGatherUpdate) {
            $scope.manualGatherUpdate.date = dateTime;
        } else {
            analyticsWrapper.track('Remind me', { category: 'Initiative Settings', label: 'Advanced' });
            trackHelper.updateNextReminderTime($scope.initiative, dateTime).then(function (data) {
                $scope.initiative.nextGatherUpdate = data.nextGatherUpdate;

                $scope.onSetReminderSaved();
            });
        }
    };

    $scope.onSetReminderSaved = function () {
        if ($attrs.onReminderSet) {
            $scope.onReminderSet();
        }
    };

    $scope.setSelectedFollowUpType = function (type) {
        $scope.data.selectedFollowUpType = type;
    };

    $scope.onSaveButtonClick = function () {
        analyticsWrapper.track('Changed gather settings', { category: 'Initiative Settings' });

        // Put all the days keys (like, "MONDAY"...) that has isSelected === true into gatherDays
        const gatherDays = [];
        if ($scope.data.selectedFollowUpType === $scope.data.followUpTypes.recurring) {
            const keys = Object.keys($scope.data.daysOptions);
            for (const dayKey of keys) {
                const day = $scope.data.daysOptions[dayKey];
                if (day.isSelected) {
                    gatherDays.push(dayKey);
                }
            }
        }

        if (gatherDays.length > 0) {
            // Update the server on the new gather options
            trackHelper
                .setGatherOptions($scope.initiative, gatherDays)
                .then((data) => {
                    $scope.initiative.nextGatherUpdate = data.nextGatherUpdate;
                })
                .catch(() => {
                    $rootScope.$emit(
                        'alert',
                        'There was an error when trying to save the custom follow up rules, please try again.',
                    );
                });
        }

        // Call onSaved here in order to close the modal
        $scope.onSaved();
    };
}
angular.module('tonkean.app').controller('InitiativeSettingsCtrl', InitiativeSettingsCtrl);
