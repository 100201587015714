import React from 'react';
import { TonkeanAvatar as TrackAvatar } from '@tonkean/infrastructure';
import { DaysDiffShorterFilter } from '@tonkean/shared-services';
import Utils from '@tonkean/utils';
import { TrackSetReminderDropdown } from '@tonkean/infrastructure';
import { TrackActions } from '@tonkean/flux';
import { angularToReact } from '@tonkean/angular-components';
import { Popover } from '@tonkean/infrastructure';
import { Menu } from '@tonkean/infrastructure';
import { DeprecatedDate } from '@tonkean/utils';

const AddFunctionPopover = angularToReact('tnk-add-function-popover');
const NoGatherUpdatePopover = angularToReact('tnk-no-gather-update-popover');

/**
 * A components that renders the track list owner.
 */
export default class TrackOwner extends React.Component {
    nextGatherTriggerRef = React.createRef();
    mountedRef = React.createRef();

    constructor(props) {
        super(props);

        this.onCloseOwnerPopover = this.onCloseOwnerPopover.bind(this);
    }

    componentDidMount() {
        this.mountedRef.current = true;
        const onBoardingFirstNoGatherListener = this.props.$rootScope.$on(
            'onBoardingStepCompleted',
            (event, stepKey, data) => {
                // If the user completed the no first gather update step.
                if (stepKey === 'firstNoGatherUpdate') {
                    if (data.trackId === this.params.realTrack.id && data.editorId === this.props.editorId) {
                        this.setNoGatherPopoverOpenState(true);
                    }

                    // unsubscribe to onboarding updates
                    onBoardingFirstNoGatherListener();
                }
            },
        );
    }

    componentWillUnmount() {
        this.mountedRef.current = false;
    }

    // region: UI Actions
    loadWorkflowVersion() {
        this.props.services.wvm
            .getCachedWorkflowVersionOrGetFromServer(this.props.workflowVersionId)
            .then((workflowVersion) => {
                if (this.mountedRef.current) {
                    this.setState({ workflowVersion });
                }
            });
    }

    setOwnerPopoverOpenState(ownerPopoverOpen) {
        this.setState({ ownerPopoverOpen, noGatherPopoverOpen: false });
    }

    setNoGatherPopoverOpenState(noGatherPopoverOpen) {
        this.setState({ noGatherPopoverOpen });
    }

    setSetReminderMenuOpen(setReminderMenuOpen) {
        TrackActions.toggleTrackSetReminderDropdown(this.props.track.id, this.props.editorId, setReminderMenuOpen);
    }

    onCloseOwnerPopover() {
        this.props.uiActions.addDummyIfNeeded?.(this.props.track);
        this.setOwnerPopoverOpenState(false);
    }

    // endregion: UI Actions

    render() {
        if (!this.state?.workflowVersion) {
            this.loadWorkflowVersion();
            return null;
        }

        if (this.props.trackCreated) {
            const realTrack = this.props.realTrack;
            const owner = realTrack.owner;
            const as = this.props.services.as;
            const wvh = this.props.services.wvh;
            const lps = this.props.services.lps;

            const workflowVersion = this.state.workflowVersion;

            const openPopover = () => this.setOwnerPopoverOpenState(true);

            // No access message element.
            let noAccessMessage = null;
            if (owner && realTrack.isVisibleToOwner === false) {
                noAccessMessage = (
                    <div className="flex-no-shrink track-owner-no-access">
                        <i className="fa fa-exclamation-triangle common-color-warning pointer" />
                    </div>
                );
            }

            const className = 'tracks-col-who flex-vmiddle common-size-xxxxs relative';

            let rightZoneElements;
            if (owner) {
                let nameWrapperElementClasses = '';
                const showTooltip = owner.name.length > 13;
                if (showTooltip) {
                    nameWrapperElementClasses += 'tnk-tooltip mod-top mod-block';
                }

                let nextPingElement = null;
                if (realTrack.status !== 'DONE' && !this.props.viewOnlyMode) {
                    const noGatherUpdateContainerId = Utils.getReactAngularContainerId(
                        'no-gather-update',
                        this.props.track.id,
                        this.props.editorId,
                    );
                    const parentDoneOrArchiveReason = realTrack.nextGatherUpdateReason === 'PARENT_DONE_ARCHIVE';
                    let nextGatherClickEvent =
                        !owner.isGatherUpdateDisabled &&
                        workflowVersion.shouldSendGatherUpdates &&
                        (realTrack.nextGatherUpdate || parentDoneOrArchiveReason)
                            ? () => this.setSetReminderMenuOpen(true)
                            : () => this.setNoGatherPopoverOpenState(true);
                    let nextGatherUpdateClasses = '';
                    const nextGatherUpdateInThePast =
                        realTrack.nextGatherUpdate && realTrack.nextGatherUpdate < DeprecatedDate.nowAsDate();

                    if (
                        !workflowVersion.shouldSendGatherUpdates ||
                        (!realTrack.nextGatherUpdate &&
                            realTrack.status !== 'DONE' &&
                            !wvh.workflowVersionHasTriggers(workflowVersion.id) &&
                            !parentDoneOrArchiveReason) ||
                        nextGatherUpdateInThePast ||
                        owner.isGatherUpdateDisabled
                    ) {
                        nextGatherUpdateClasses += ' common-color-danger';
                    }
                    let nextGatherUpdate;
                    if (owner.isGatherUpdateDisabled) {
                        nextGatherUpdate = 'Module OFF';
                    } else if (realTrack.nextGatherUpdate) {
                        if (nextGatherUpdateInThePast) {
                            nextGatherUpdate = 'None';
                        } else {
                            nextGatherUpdate = DaysDiffShorterFilter(realTrack.nextGatherUpdate);
                        }
                    } else if (
                        wvh.workflowVersionHasTriggers(workflowVersion.id) &&
                        workflowVersion.shouldSendGatherUpdates &&
                        !parentDoneOrArchiveReason
                    ) {
                        nextGatherUpdate = 'On Trigger';
                    } else {
                        nextGatherUpdate = 'None';
                    }

                    let nextPingLabelClasses = 'common-color-grey margin-right-xxs';

                    if (lps.isPricingVersion(lps.pricingVersions.V2) && !lps.canPersonUpdate(owner)) {
                        nextPingLabelClasses += ' hidden';

                        // If we are trying to grab a seat for this owner, show a loading circle.
                        // The pendingSeatGrab is a client set property.
                        if (owner.projectContext.pendingSeatGrab) {
                            nextGatherClickEvent = null;
                            nextGatherUpdate = <i className="loading-xsmall" />;
                        } else {
                            // If project is licensed but the user is not (not even temporary), we want to show the user needs access.
                            nextGatherUpdate = 'Not a Member';

                            // If the user has no access (not even temporary) we should open the noGatherUpdatePopover that shows the relevant message.
                            nextGatherClickEvent = () => this.setNoGatherPopoverOpenState(true);
                            nextGatherUpdateClasses += ' common-color-danger';
                        }
                    }

                    // We only show the next ping element if the status of the track is not done, and this is a real logged in user (hiding it in sandbox).
                    nextPingElement = (
                        <div className="common-size-xxxxs relative open">
                            <Menu
                                menuItems={
                                    <TrackSetReminderDropdown
                                        isOpen={this.props.setReminderDropdownOpen}
                                        id={this.props.track.id}
                                        realTrack={realTrack}
                                        editorId={this.props.editorId}
                                        currentUserId={as.currentUser ? as.currentUser.id : null}
                                        modal={this.props.modal}
                                        overrideOpenSettingsModal={this.props.uiActions.openSettingsModal}
                                        trackHelper={this.props.trackHelper}
                                        currentUserService={this.props.currentUserService}
                                        callerForAnalyticsForAskForUpdates="Tracks editor"
                                        callerForAnalytics="Track next gather dropdown"
                                        newMenu
                                    />
                                }
                                show={this.props.setReminderDropdownOpen}
                                onClose={() => this.setSetReminderMenuOpen(false)}
                                nodeRef={this.nextGatherTriggerRef}
                            />
                            <Popover
                                content={
                                    <NoGatherUpdatePopover
                                        initiative={this.props.realTrack}
                                        editorId={this.props.editorId}
                                        setDueDateCallback={() => this.setOwnerPopoverOpenState(true)}
                                        displayInPlace
                                    />
                                }
                                show={this.state.noGatherPopoverOpen}
                                onClose={() => this.setNoGatherPopoverOpenState(false)}
                                nodeRef={this.nextGatherTriggerRef}
                                overflowVisible
                            />
                            <span
                                className={workflowVersion.shouldSendGatherUpdates ? 'pointer' : 'hidden'}
                                onClick={nextGatherClickEvent}
                                ref={this.nextGatherTriggerRef}
                            >
                                <span className={nextPingLabelClasses}>Next ping</span>
                                <span id={noGatherUpdateContainerId}>
                                    <span className={nextGatherUpdateClasses}>{nextGatherUpdate}</span>
                                    <span className="dropdown-chevron mod-small mod-static visible-on-hover" />
                                </span>
                            </span>
                        </div>
                    );
                }

                rightZoneElements = (
                    <React.Fragment>
                        <div className={nameWrapperElementClasses}>
                            <div
                                className={`track-owner-name common-ellipsis ${
                                    !this.props.data.viewOnlyMode && 'pointer'
                                }`}
                                data-automation="track-owner-people-owner-names"
                                onClick={this.props.data.viewOnlyMode ? undefined : openPopover}
                            >
                                {owner.name}
                            </div>
                            {showTooltip && (
                                <span className="tnk-tooltip-text list-item-owner-tooltip">{owner.name}</span>
                            )}
                        </div>
                        {nextPingElement}
                    </React.Fragment>
                );
            } else {
                rightZoneElements = (
                    <div
                        className={`common-color-grey user-select-none ${!this.props.data.viewOnlyMode && 'pointer'}`}
                        data-automation="track-owner-Unassigned"
                        onClick={this.props.data.viewOnlyMode ? undefined : openPopover}
                    >
                        Unassigned
                    </div>
                );
            }

            return (
                <div
                    className={className}
                    tabIndex={!this.props.collectItemsMode ? -1 : 0}
                    onKeyPress={(e) => {
                        if (this.props.collectItemsMode) {
                            openPopover();
                        }
                    }}
                >
                    {noAccessMessage}
                    <Popover
                        content={
                            <AddFunctionPopover
                                initiative={this.props.realTrack}
                                onClose={this.onCloseOwnerPopover}
                                disableInvite={this.props.disableOwnerInvite}
                                solutionBusinessReportId={this.props.solutionBusinessReportId}
                                isInSharedMode={this.props.isInSharedMode}
                                displayInPlace
                            />
                        }
                        show={this.state.ownerPopoverOpen}
                        onClose={this.onCloseOwnerPopover}
                        placement="left"
                        overflowVisible
                    >
                        <div
                            className={`track-leader-avatar mod-border avatar-main img-circle avatar common-color-grey svg-icon-hover-black margin-right-xs user-select-none ${
                                !this.props.data.viewOnlyMode && 'pointer'
                            }`}
                            onClick={this.props.data.viewOnlyMode ? undefined : openPopover}
                        >
                            <TrackAvatar owner={owner} />
                        </div>
                    </Popover>
                    <div className="margin-left-xs text-left">{rightZoneElements}</div>
                </div>
            );
        } else {
            return null;
        }
    }
}
