import React from 'react';

import { ExpressionBlock } from '@tonkean/editor';
import type { ServerFormulaExpressionConstNode } from '@tonkean/tonkean-entities';

interface Props {
    expression: ServerFormulaExpressionConstNode;
    children: React.ReactNode;
}

const ConstBlock: React.FC<Props> = ({ expression, children }) => {
    return (
        <ExpressionBlock type="const">
            {expression.value}
            {children}
        </ExpressionBlock>
    );
};

export default ConstBlock;
