<div class="send-email-logic-configuration">
    <!-- Person selection title -->
    <div class="common-title-xxs margin-bottom">Who should Tonkean module Email?</div>

    <div ng-if="data.invalidLogics[data.configuredLogic.node.id].notifyType" class="margin-top-xs common-color-danger">
        {{ data.invalidLogics[data.configuredLogic.node.id].notifyType }}
    </div>

    <!-- Selecting notification method -->
    <tnk-person-selection-configuration
        class="block margin-bottom-lg"
        support-quick-create="true"
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        validation-object="data.invalidLogics[data.configuredLogic.node.id]"
        saved-person-selection-configuration="data.existingPersonSelectionConfiguration"
        preview-evaluation-source="data.previewEvaluationSource"
        configured-logic="data.configuredLogic"
        on-person-selection-configuration-changed="
            onPersonSelectionConfigurationChanged(personSelectionConfiguration, shouldSaveLogic)
        "
        allow-none="true"
    ></tnk-person-selection-configuration>

    <!-- separator -->
    <hr />

    <!-- External email selection title -->
    <div class="common-title-xxs margin-bottom">External email addresses:</div>

    <!-- Select external emails -->
    <tnk-deprecated-people-selector
        class="block margin-bottom-lg common-width-full"
        no-validation="false"
        selected-people="data.externalEmails"
        no-custom="false"
        only-custom="true"
        placeholder="Enter emails..."
        on-tag-added="onActionsChanged(true)"
        on-tag-removed="onActionsChanged(true)"
    ></tnk-deprecated-people-selector>

    <!-- separator -->
    <hr />

    <!-- Subject -->
    <div class="margin-bottom-xs">Subject of the email:</div>

    <!-- Input -->
    <input
        ng-model="data.emailSubject"
        placeholder="Type a custom subject the Module will use"
        ng-change="onActionsChanged(true)"
        ng-required="true"
        class="form-control margin-bottom"
    />

    <!-- separator -->
    <hr />

    <!-- Title -->
    <div class="margin-bottom-xs">Text to include in the email:</div>

    <!-- Input -->
    <input
        ng-model="data.emailText"
        placeholder="Type a custom message the Module will use"
        ng-change="onActionsChanged(true)"
        ng-required="true"
        class="form-control margin-bottom"
    />

    <!-- Separator -->
    <hr />

    <!-- Preview -->
    <div class="margin-top-lg">
        <!-- Title -->
        <div class="margin-bottom common-bold">Preview message:</div>

        <!-- Preview -->
        <tnk-preview-bot-bubble-ctrl
            text="data.botEmailText"
            post-text=""
            preview-type="text"
            placeholder=""
            is-email="true"
            user="as.currentUser"
            no-buttons="true"
            logic-id="data.configuredLogic.node.id"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            no-triangle="true"
            preview-evaluation-source="data.previewEvaluationSource"
        ></tnk-preview-bot-bubble-ctrl>
    </div>
</div>
