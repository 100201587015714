<div class="display-configuration">
    <!-- Display options -->
    <div class="flex-vmiddle flex-xs-wrap" ng-if="data.fieldType !== 'List'">
        <!-- Display as - dropdown -->
        <div class="flex-vmiddle margin-right margin-xs-bottom" ng-if="data.fieldDefinitionType !== 'MANUAL'">
            <!-- Display as title -->
            <span
                class="
                    common-color-black
                    flex-no-shrink
                    text-right
                    common-size-xxs
                    field-definition-display-col
                    margin-right
                "
            >
                Display as:
            </span>
            <!-- Dropdown -->
            <div class="field-definition-modal-dropbox mod-bigger margin-right-xs" uib-dropdown>
                <button
                    type="button"
                    class="btn btn-default common-no-outline dropdown-toggle"
                    uib-dropdown-toggle
                    ng-disabled="data.disabled"
                >
                    <span class="flex-vmiddle">
                        <span class="flex-grow text-left margin-right">
                            {{ data.displayAsApiNameToLabel[data.displayAs] || 'Text' }}
                        </span>
                        <span class="dropdown-chevron flex-no-shrink"></span>
                    </span>
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="split-button">
                    <li
                        ng-repeat="(displayAsApiName, displayAsLabel) in ::data.displayAsApiNameToLabel"
                        ng-click="selectDisplayAs(displayAsApiName)"
                        ng-if="displayAsApiName !== 'LongString' || data.targetType === 'COLUMN' || data.evaluatedDisplayType === 'LongString'"
                    >
                        <a>{{ displayAsLabel }}</a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Field format selection -->
        <div
            class="flex-vmiddle margin-xs-bottom"
            ng-if="
                data.displayAs && data.displayAs.toLowerCase() !== 'boolean' && data.displayAs.toLowerCase() !== 'list'
            "
        >
            <span
                class="
                    common-color-black
                    flex-no-shrink
                    text-right
                    common-size-xxs
                    field-definition-display-col
                    margin-right
                "
            >
                {{ data.evaluatedDisplayType.toLowerCase() === 'string' || data.evaluatedDisplayType.toLowerCase() === 'longstring'  ? 'Text' : data.evaluatedDisplayType }} format:
            </span>

            <div class="field-definition-modal-dropbox mod-bigger">
                <tnk-display-format-selector
                    selected-format-api-name="data.displayFormat"
                    selected-format-prefix="data.displayFormatPrefix"
                    selected-format-postfix="data.displayFormatPostfix"
                    on-display-format-selected="
                        onDisplayFormatSelected(selectedDisplayFormat, skipAnalytics, didDataChange)
                    "
                    field-type-filter="data.evaluatedDisplayType"
                    disable-selection="data.disabled"
                ></tnk-display-format-selector>
            </div>
        </div>
    </div>

    <hr class="margin-top margin-bottom" ng-if="data.displayAs === 'String' && data.isMultiValueField" />

    <div
        class="flex-vmiddle flex-xs-wrap"
        ng-if="(data.displayAs === 'String' || data.displayAs === 'LongString') && data.isMultiValueField"
    >
        <div class="margin-right-xs flex-inline">
            <div class="margin-right-xs margin-top">Display Separator:</div>
            <input
                data-automation="display-configuration-display-separator"
                type="text"
                class="margin-top-xs form-control mod-width-100 common-center-input"
                ng-model="data.outputMultiValueSeparator"
                ng-change="displayConfigurationChanged(true)"
                placeholder="Display values separated by..."
                autocomplete="off"
            />
        </div>
    </div>

    <hr class="margin-top margin-bottom" ng-if="data.evaluatedDisplayType === 'Number'" />

    <div ng-show="data.evaluatedDisplayType === 'Number'">
        <div class="flex flex-vmiddle">
            <!-- Decimal Places -->
            <div class="margin-bottom">
                Decimal places:
                <span uib-dropdown>
                <button uib-dropdown-toggle class="btn btn-default">
                    {{ data.decimalPlaces }}
                    <span class="dropdown-chevron mod-static"></span>
                </button>

                <ul class="dropdown-menu" uib-dropdown-menu>
                    <li
                      ng-repeat="decimalPlace in data.decimalPlacesOptions"
                      ng-click="selectDecimalPlaces(decimalPlace)">

                        <a>{{ decimalPlace }}</a>
                    </li>
                </ul>
            </span>

                <i
                  class="fa fa-info-circle margin-left-xs common-color-grey"
                  uib-tooltip="The number of digits displayed after the decimal place. This affects how the number is displayed (not how the number is stored)"
                ></i>
            </div>

            <!-- Trailing Zeros -->
            <span class="margin-bottom flex margin-left-md">
                Show trailing zeros
                <tnk-toggle
                  class="margin-left-xs"
                  is-active="data.includeTrailingZeros"
                  on-toggle-click="toggleIncludeTrailingZeros()"
                  size="xs"
                ></tnk-toggle>
            </span>
        </div>

        <div class="flex-vmiddle flex-xs-wrap">
            <!-- Compare trend to - dropdown -->
            <span
                class="
                    common-color-black
                    flex-no-shrink
                    text-right
                    common-size-xxs
                    field-definition-display-col
                    margin-right margin-bottom
                "
            >
                Compare trend to:
            </span>
            <div class="field-definition-modal-dropbox mod-bigger margin-right-lg margin-bottom" uib-dropdown>
                <button
                    type="button"
                    class="btn btn-default common-no-outline dropdown-toggle"
                    uib-dropdown-toggle
                    ng-disabled="posting"
                >
                    <span class="flex-vmiddle">
                        <span class="flex-grow text-left margin-right">
                            {{ data.timeframeApiNameToLabel[data.compareTimeframe] || data.timeframeApiNameToLabel[1] }}
                        </span>
                        <span class="dropdown-chevron flex-no-shrink"></span>
                    </span>
                </button>
                <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="split-button">
                    <li
                        ng-repeat="(timeframeApiName, timeframeLabel) in data.timeframeApiNameToLabel"
                        ng-click="selectCompareTimeframe(timeframeApiName)"
                    >
                        <a>{{ timeframeLabel }}</a>
                    </li>
                </ul>
            </div>

            <!-- Increment is - toggle radio buttons -->
            <span class="common-size-xxs common-color-black flex-no-shrink margin-right margin-bottom">
                Increment is:
            </span>
            <div class="btn-group margin-bottom">
                <label
                    class="btn btn-default"
                    ng-class="{ 'btn-primary': !data.isIncrementNegative }"
                    uib-btn-radio="false"
                    ng-model="data.isIncrementNegative"
                    ng-click="selectIsIncrementNegative(false)"
                >
                    Positive
                </label>
                <label
                    class="btn btn-default"
                    ng-class="{ 'btn-primary': data.isIncrementNegative }"
                    uib-btn-radio="true"
                    ng-model="data.isIncrementNegative"
                    ng-click="selectIsIncrementNegative(true)"
                >
                    Negative
                </label>
            </div>
        </div>
    </div>

    <!-- label settings -->
    <div>
        <div class="margin-top-md" ng-if="!data.showFieldLabelInput">
            <tnk-icon src="/images/icons/Label.svg" class="common-fill-grey-text"></tnk-icon>
            <button class="btn-link" ng-click="toggleFieldLabel(true)">+ Add field label</button>
        </div>

        <div ng-if="data.showFieldLabelInput">
            <div class="flex flex-col">
                <span class="h6">
                    <tnk-icon src="/images/icons/Label.svg" class="common-fill-grey-text"></tnk-icon>
                    Field label
                </span>
                <span class="h6 common-color-grey margin-top-none margin-bottom-md">
                    Will represent this field to process contributors (in messages, forms and business reports)
                </span>
            </div>
            <div class="field-label">
                <!-- Integration Icon -->
                <tnk-icon
                    ng-if="data.isFromMatchedItems"
                    class="margin-right-md"
                    src="/images/icons/union.svg"
                ></tnk-icon>

                <tnk-project-integration-icon
                    ng-if="!data.isFromMatchedItems && data.projectIntegrationId"
                    class="margin-right-md"
                    project-integration-id="data.projectIntegrationId"
                ></tnk-project-integration-icon>

                <tnk-field-type-icon
                    field-type="definition.fieldType"
                    class="margin-right-md"
                    ng-if="!data.projectIntegrationId"
                ></tnk-field-type-icon>
                <input
                    type="text"
                    ng-model="data.fieldLabel"
                    ng-model-options="{debounce: 300}"
                    ng-change="fieldLabelChanged()"
                    class="full-width tnk-message-textbox-textarea form-control"
                    maxlength="500"
                />
                <span class="field-label-length">{{ data.fieldLabel.length || 0 }}/500</span>
                <tnk-icon
                    src="/images/icons/delete.svg"
                    class="margin-left-md flex mod-align-center pointer"
                    ng-click="toggleFieldLabel(false)"
                ></tnk-icon>
            </div>
        </div>
    </div>
</div>
