import { TONKEAN_E_TAG_STR } from '@tonkean/constants';
import type { Color } from '@tonkean/tui-theme';
import {
    AccessControlDefinition,
    ActionParameterExpression,
    ActionType,
    ActivitiesResponse,
    Activity,
    BackgroundProcess,
    BackgroundProcessesFetchResults,
    BackgroundProcessesStatsByModule,
    BackgroundProcessStatus,
    BackgroundProcessType,
    BaseActionParameter,
    BaseOauth2,
    BasicQueryDefinition,
    BusinessLabel,
    BusinessReportAccessibilitySummary,
    BusinessReportAccessType,
    CalculateOcrOutputResponse,
    Category,
    Comment,
    ConnectionPermission,
    ContextResponse,
    ContractFieldMapping,
    ContractFieldMappingTargetType,
    ContractFieldSummary,
    ContractFieldType,
    ContractSummary,
    ConvertEnterpriseComponentTypeToOverviewResult,
    CreateFromMarketplaceResponse,
    CreateIdentityProviderResponse,
    type CreateProjectIntegrationEntityFieldMetadataRequest,
    CreationJsonDependencies,
    CustomActionDefinition,
    CustomTrigger,
    CustomTriggersInSequenceSummaryDataResponse,
    CustomTriggerSummary,
    DataSourceConnection,
    DeleteItemsSelectionType,
    DocumentCollectionsResponse,
    EndSequence,
    EnrichedTrainingSetItemResponse,
    EnrichedTrainingSetModelSummary,
    EnterpriseComponentAuthorization,
    EnterpriseComponentId,
    EnterpriseComponentInfoFromMarketplaceItemResponse,
    EnterpriseComponentsPersonPermissionRole,
    EnterpriseComponentType,
    EnterpriseComponentVariable,
    EnterpriseComponentVariableRelatedEntityId,
    EnterpriseComponentVariableValueType,
    EnterpriseComponentVariableWithIsImported,
    EnterpriseSummary,
    EntitiesSyncPreviewResponse,
    EntitiesToPublishSummary,
    EntityDataRetentionSettings,
    EntityInboundConfiguration,
    EntityResponseHandlingDefinitionType,
    EntityVersion,
    EvaluatedRegexExpressions,
    ExternalActivity,
    FetchInitiativeFieldsResponse,
    FetchResults,
    FieldDefinition,
    type FieldDefinitionIdToFetch,
    FieldMetadata,
    type FieldQuery,
    FieldType,
    FieldValue,
    FilterTrainingData,
    FormReferralData,
    FormType,
    FormulaAIServerResponse,
    formulaTreeNode,
    FromMarketplaceItemCreationData,
    FullProject,
    GcsMimeType,
    GenerateTokenForIdentityProviderResponse,
    GetCollectStatusResponse,
    GetCTAActionsEntitiesSummaryResponse,
    type GetInterfaceWidgetResponse,
    GetOperationNamesFromWsdlUrlResponse,
    GetProjectIntegrationsSummaries,
    GetTeamMemberOptions,
    GetWorkerRunsApiFilters,
    Group,
    GroupEnvironmentFormSummary,
    GroupOutdatedResponse,
    GroupVersionsExportData,
    Icon,
    IdentityProvider,
    type IncludedWidgetsSummaryByInterface,
    Initiative,
    InitiativeNavigation,
    IntakeCustomTriggerDetails,
    IntakeRequestViewer,
    IntakeSequenceCustomTriggerData,
    IntegrationType,
    InviteUsersResponse,
    ItemInterface,
    ItemInterfaceConfiguration,
    ItemInterfaceFullData,
    ItemInterfaceHeaderMainActionType,
    ItemInterfaceWidget,
    ItemInterfaceWidgetConfiguration,
    ItemInterfaceWidgetType,
    ItemToDocumentCollectionSummary,
    JobStatusResponse,
    LightSearchInitiativesResponse,
    LiveReportFieldDefinition,
    MarketplaceItem,
    MarketplaceItemCreationData,
    MarketplaceItemDirectInstallResponse,
    MarketplaceItemSummary,
    MarketplaceItemType,
    MatchedEntityPreviewItemsResponse,
    MatchedItemActionData,
    MatchHandlingType,
    MenuActionInfo,
    ModuleMarketplaceItem,
    MyRequestesResponse,
    NavigationCategory,
    NavigationSearchResponse,
    NewWorkflowFolderEnterpriseComponentAccess,
    OAuth2AuthorizationCode,
    OcrOutputResponse,
    OcrOutputType,
    IDPLoginData,
    OperationAutomatedPart,
    OperationEdge,
    OperationManualPart,
    OperationNode,
    OperationNodeType,
    PeopleDirectory,
    PeopleDirectoryInstance,
    PeopleDirectoryInstanceGroupMessage,
    PeopleDirectoryInstanceGroupMessagesChannelType,
    PeopleDirectoryInstanceGroupMessagesEmailType,
    PeopleDirectoryInstanceViewIndividualsResponse,
    PeopleInProject,
    PeopleSummaryInProject,
    Person,
    PreviewEvaluatedResponse,
    PreviewFindWordsResponse,
    PricingSummary,
    ProcessBuilder,
    ProcessBuilderAnsweredMissingQuestionAIServerMessage,
    ProcessBuilderMissingQuestionsResponse,
    ProcessMapper,
    ProcessMapperEdge,
    ProcessMapperNode,
    ProcessMapperNodeStage,
    ProcessParticipant,
    ProcessParticipantSystemBeingUsed,
    Project,
    ProjectHomepageSummary,
    ProjectIntegration,
    ProjectIntegrationAction,
    ProjectIntegrationActionParametersDefinition,
    ProjectIntegrationActionResponseHandlingDefinition,
    ProjectIntegrationActionTestRunsResponse,
    ProjectIntegrationActionWithIsImported,
    ProjectIntegrationCollectToggles,
    ProjectIntegrationEntitiesDataRetention,
    ProjectIntegrationEntity,
    ProjectIntegrationEntityFieldMetadata,
    ProjectIntegrationEntityFieldMetadataSubField,
    type ProjectIntegrationEntityFields,
    ProjectIntegrationEntityResponseHandlingDefinition,
    ProjectIntegrationEntityResponseHandlingDefinitionBase,
    ProjectIntegrationEntitySummaryWithIsImported,
    ProjectIntegrationEntityWebhookDefinition,
    type ProjectIntegrationSmartSearchEntityConfig,
    ProjectIntegrationTestActionResponse,
    ProjectPeopleByIds,
    ProjectSourceChangeBehavior,
    ProjectThemeConfiguration,
    ProjectUserGroups,
    ProjectUsersSource,
    RecipientsPreviewFromTonkeanExpressionsResponse,
    ResponseHandlingValidationStatus,
    RevokeTokenForIdentityProviderResponse,
    SCIMRolesType,
    SCIMTonkeanRole,
    SearchInitiativesByHistoryFilterResponse,
    SearchInitiativesQuery,
    SearchWidgetSearchResponse,
    SearchWidgetUpdateSelectedResponse,
    SearchWorkerRunsResponse,
    ServerFormulaExpressionNode,
    SingleWorkflowFolderEnterpriseComponentAccess,
    SmartConversationMessage,
    SmartSearchEntityAnalysisResponse,
    SmartSearchLookupResponse,
    SmartSearchResponse,
    SmartSearchSuggestActionResponse,
    SolutionBusinessReport,
    SolutionBusinessReportFormSummary,
    SolutionBusinessReportGroup,
    SolutionBusinessReportGroupFilters,
    SolutionBusinessReportPermissionsUpdate,
    SolutionBusinessReportSavedFilter,
    SolutionMapper,
    SolutionSite,
    type SolutionSiteConfiguration,
    SolutionSiteHomepageSummary,
    SolutionSitePage,
    SolutionSitePageCreateParams,
    StatelessExpressionsEvaluationRequest,
    StatelessExpressionsEvaluationResponse,
    StatelessJsonExpressionsEvaluationRequest,
    StatelessJsonExpressionsEvaluationResponse,
    StorageIntegrationConfiguration,
    SubEntityInfo,
    supportUserDisabledResponse,
    SupportUserInGroupResponse,
    SyncItemsCreatorConfiguration,
    SyncItemsCreatorSelectionType,
    TaggableCustomTriggerInterface,
    TextExtractionFieldInformation,
    TonkeanExpressionDefinition,
    TonkeanExpressionForServerEvaluation,
    TonkeanExpressionServerEvaluationResult,
    TonkeanId,
    TonkeanQueryDefinition,
    TonkeanType,
    TrainingSet,
    TrainingSetField,
    TrainingSetFieldType,
    TrainingSetGetModelResponse,
    TrainingSetIdentifiersSummary,
    TrainingSetItemExpectedValue,
    TrainingSetItemIdentifiers,
    TrainingSetItemsAndEvaluationRelatedEntities,
    TrainingSetItemsRowType,
    TrainingSetItemToSnippet,
    TrainingSetItemUploadingStatus,
    TrainingSetModel,
    TrainingSetModelConfiguration,
    TrainingSetModelIdentifiers,
    TrainingSetModelMethodType,
    TrainingSetModelsOfAllFields,
    TrainingSetModelsOfAllFieldsByCollections,
    TrainingSetModelSummary,
    TrainingSetModelToItemIdentifiersMatched,
    TrainingSetsGallery,
    TrainingSetType,
    TrialStatusResult,
    type UpdateProjectIntegrationEntityFieldMetadataRequest,
    UpdateWorkflowVersionRecurrenceTimeParams,
    UserGroup,
    WebhookPayload,
    WebhookPayloadMetadataField,
    WidgetBase,
    WidgetConfiguration,
    WidgetParentTypes,
    WidgetPreset,
    WorkerRun,
    WorkerRunLogicInspectTabBase,
    WorkerRunStage,
    WorkflowFolder,
    WorkflowFolderAccessControl,
    WorkflowFolderAccessibleEnterpriseComponentsResponse,
    WorkflowFolderCategory,
    WorkflowFolderVersionsExportData,
    WorkflowFolderVersionSummary,
    WorkflowVersion,
    WorkflowVersionType,
    type TonkeanUploadedFile,
    EnterpriseLoginSecrets,
} from '@tonkean/tonkean-entities';
import { bindThis, toArray } from '@tonkean/utils';
import type { TElement } from '@udecode/plate';
import type { IHttpResponse, IRequestConfig as _IRequestConfig, IRequestShortcutConfig } from 'angular';
import type { AngularServices } from 'angulareact';
import {
    getCustomTriggerIdPath,
    getFieldDefinitionIdPath,
    getFormIdPath,
    getInitiativeIdPath,
    getItemInterfaceIdPath,
    getProjectIdPath,
    getWorkflowVersionIdPath,
} from './urlPathUtils';
import { getProjectStates } from '@tonkean/tonkean-utils';

class TonkeanService {
    public static $inject: (string & keyof AngularServices)[] = [
        '$http',
        '$httpParamSerializer',
        'environment',
        '$q',
        'utils',
        'authenticationService',
        'apiJobManager',
    ];

    // region enums
    private groupNotifications = {
        none: 'NONE',
        public: 'PUBLIC_CHANNEL',
        private: 'PRIVATE_CHANNEL',
        direct: 'DIRECT_MESSAGE',
    };
    // endregion

    /**
     * The cached projects map. Keys are the project ids.
     * There's another key 'all' with an array of all projects.
     */
    private projects: { all: any[]; [key: string]: any } = { all: [] };

    protected constructor(
        protected $http: AngularServices['$http'],
        protected $httpParamSerializer: AngularServices['$httpParamSerializer'],
        protected environment: AngularServices['environment'],
        protected $q: AngularServices['$q'],
        protected utils: AngularServices['utils'],
        protected authenticationService: AngularServices['authenticationService'],
        protected apiJobManager: AngularServices['apiJobManager'],
    ) {}

    /**
     * Gets all projects from the API. This will also updated the cache.
     * @returns  a promise with the data.
     */
    @bindThis
    public getProjects(
        skip: number,
        limit: number,
        nameQuery,
        excludeExpiredAndLocked = false,
    ): Promise<FullProject[]> {
        const innerProjects = this.projects;

        limit = angular.isNumber(limit) && limit >= 0 ? limit : 1000;
        skip = angular.isNumber(skip) && skip >= 0 ? skip : 0;

        const params = {
            limit,
            skip,
            q: nameQuery,
            excludeExpiredAndLocked,
        };

        const path = 'me/projects';

        return this.$http.get<any>(this.buildUrl(path), { params }).then((data) => {
            angular.forEach(data.entities, (project) => {
                innerProjects[project.id] = project;
            });
            innerProjects.all = data;
            return data.entities;
        });
    }

    /**
     * Gets the enterprise projects list.
     */
    @bindThis
    public getEnterpriseProjects(enterpriseId: string) {
        return this.$http.get<any>(this.buildUrl(`${enterpriseId}/projects`));
    }

    /**
     * Gets is user allowed to create trial board.
     */
    @bindThis
    public getIsUserAllowedToCreateBoard() {
        return this.$http.get<{ isAllowed: boolean }>(this.buildUrl(`me/projects/isAllowedToCreate`));
    }

    @bindThis
    public getFormulaTree(
        projectId: string,
        workflowVersionId: string,
        evaluatedFormulaExpression: string,
    ): Promise<formulaTreeNode> {
        const params = {
            workflowVersionId,
            evaluatedFormulaExpression,
        };

        return this.$http.get<any>(this.buildUrl(`${projectId}/formulaTree`), { params });
    }

    @bindThis
    public getFormulaTreeString(projectId: string, tree) {
        const params = {
            formulaTree: tree,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/formulaTreeString`), params);
    }

    /**
     * Gets application global config.
     */
    @bindThis
    public getGlobalConfig() {
        return this.$http.get<any>(this.buildUrl('global/config'));
    }

    /**
     * Gets the current user from the API.
     */
    @bindThis
    public getCurrentUser(accessToken?: string) {
        let options;
        if (accessToken) {
            options = {
                headers: {
                    Authorization: `token ${accessToken}`,
                },
            };
        }
        return this.$http.get<any>(this.buildUrl('me'), options);
    }

    /**
     * Gets my enterprises.
     */
    @bindThis
    public getMyEnterprises() {
        return this.$http.get<{ entities: EnterpriseSummary[] }>(this.buildUrl('me/enterprises'));
    }

    /**
     * Gets a report progress from the API.
     */
    @bindThis
    public getReferralCode() {
        return this.$http.post<any>(this.buildUrl('generateReferralCode'), undefined);
    }

    /**
     * Gets a report progress from the API.
     * @param projectId project id.
     */
    @bindThis
    public getReportProgress(projectId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/progress`));
    }

    /**
     * Gets the full project object from the API.
     * @param projectId project id.
     */
    @bindThis
    public getProjectById(projectId: string, forceUpdate: boolean, dontUseCache: boolean) {
        const config: IRequestShortcutConfig = {
            params: {
                excludeDisabled: true,
            },
        };

        if (dontUseCache) {
            config.params['dontUseCache'] = dontUseCache;
        }

        if (forceUpdate) {
            config.headers = {
                ['Cache-Control']: 'max-age=0',
            };
        }

        return this.$http.get<any>(this.buildUrl(projectId), config);
    }

    /**
     * Gets the full project object from the API.
     * @param projectId project id.
     */
    @bindThis
    public updateProjectSystemUserSettings(
        projectId: string,
        systemUsersSource: ProjectUsersSource,
        sendInvitationEmail: boolean,
        invitationEmailContent: string,
        sourceChangeBehavior: ProjectSourceChangeBehavior,
    ) {
        const data = {
            systemUsersSource,
            sendInvitationEmail,
            invitationEmailContent,
            sourceChangeBehavior,
        };
        return this.$http.post<void>(this.buildUrl(`${projectId}/updateSystemUserSettings`), data);
    }

    @bindThis
    public updateProjectIntegrationShouldHandleWebhooks(projectIntegrationId: string, shouldHandleWebhooks: boolean) {
        const data = {
            shouldHandleWebhooks,
        };
        return this.$http.post<void>(this.buildUrl(`${projectIntegrationId}/updateShouldHandleWebhooks`), data);
    }

    @bindThis
    public getProjectIntegrationCollectToggles(
        projectIntegrationId: string,
    ): Promise<ProjectIntegrationCollectToggles> {
        return this.$http.get<ProjectIntegrationCollectToggles>(
            this.buildUrl(`${projectIntegrationId}/dataSourceInformationCollectToggles`),
        );
    }

    @bindThis
    public updateProjectIntegrationShouldPerformContinuousCollect(
        projectIntegrationId: string,
        shouldPerformContinuousCollect: boolean,
    ) {
        const data = {
            shouldPerformContinuousCollect,
        };
        return this.$http.post<void>(
            this.buildUrl(`${projectIntegrationId}/updateShouldPerformContinuousCollect`),
            data,
        );
    }

    @bindThis
    public updateProjectIntegrationSmartSearchEnabled(
        projectId: string,
        projectIntegrationId: string,
        smartSearchEnabled: boolean,
    ) {
        const data = {
            smartSearchEnabled,
        };
        return this.$http.post<void>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/updateSmartSearchEnabled`),
            data,
        );
    }

    @bindThis
    public updateProjectIntegrationSmartSearchSpecificEntitiesOnly(
        projectId: string,
        projectIntegrationId: string,
        smartSearchSpecificEntitiesOnly: boolean,
    ) {
        const data = {
            smartSearchSpecificEntitiesOnly,
        };
        return this.$http.post<void>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/updateSmartSearchSpecificEntitiesOnly`),
            data,
        );
    }

    @bindThis
    public updateProjectIntegrationSystemAIAccessEnabled(
        projectId: string,
        projectIntegrationId: string,
        systemAIAccessEnabled: boolean,
    ) {
        const data = {
            systemAIAccessEnabled,
        };
        return this.$http.post<void>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/updateSystemAIAccessEnabled`),
            data,
        );
    }

    /**
     * Gets the full project object from the API.
     * @param id project id.
     */
    @bindThis
    public getInitiativeById(initiativeId: string) {
        return this.$http.get<Initiative>(this.buildUrl(`${getInitiativeIdPath(initiativeId)}`));
    }

    /**
     * Gets the preview of matched entity items.
     */
    @bindThis
    public getMatchedEntityItemsPreview(
        workflowVersionId: string,
        matchedEntityId: string,
        initiativeId?: string,
    ): Promise<MatchedEntityPreviewItemsResponse> {
        const config: IRequestShortcutConfig = {
            params: {
                initiativeId: initiativeId || undefined,
            },
        };

        return this.$http.get<any>(
            this.buildUrl(`${workflowVersionId}/${matchedEntityId}/matchedEntityItemsPreview`),
            config,
        );
    }

    /**
     * Exchange code with token in oauth request.
     */
    @bindThis
    public exchangeCodeFromToken(
        enterpriseComponentId: EnterpriseComponentId,
        authorization: OAuth2AuthorizationCode,
        code: string,
    ): Promise<any> {
        const data = {
            code,
            authorization,
        };

        return this.$http.post<any>(this.buildUrl(`${enterpriseComponentId}/oauth/init`), data);
    }

    /**
     * Exchange code with token in oauth request.
     */
    @bindThis
    public oauthInitRequest(enterpriseComponentId: EnterpriseComponentId, authorization: BaseOauth2): Promise<any> {
        const data = {
            authorization,
        };

        return this.$http.post<any>(this.buildUrl(`${enterpriseComponentId}/oauth/init`), data);
    }

    /**
     * Evaluate code request with authorization details
     */
    @bindThis
    public evaluateCodeRequest(
        enterpriseComponentId: EnterpriseComponentId,
        authorization: OAuth2AuthorizationCode,
        state: string,
    ): Promise<{ evaluatedCodeRequestUrl: string }> {
        const data = {
            authorization,
            state,
        };

        return this.$http.post(this.buildUrl(`${enterpriseComponentId}/oauth/evaluateCodeRequest`), data);
    }

    /**
     * Gets the count of test runs in a group.
     */
    @bindThis
    public getAnyTestRunsOccurred(groupId: string) {
        return this.$http.get<any>(this.buildUrl(`${groupId}/anyTestRunsOccurred`));
    }

    /**
     * Check if the Tonkean bot is already connected to the team.
     */
    @bindThis
    public isBotConnectedToTeam(
        teamId: string,
        integrationId: string,
        projectIntegrationId?: string,
        projectId?: string,
    ) {
        const data = {
            commandRequest: {
                commandType: 'VERIFY_TEAM',
                commandData: {
                    aaGroupId: teamId,
                },
            },
            projectIntegrationId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/${integrationId}/integrationCommand`), data);
    }

    /**
     * Gets a person by id, and will also return the project context of the given projectId filled in the person entity.
     */
    @bindThis
    public getProjectPersonById(projectId: string, personId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/${personId}`));
    }

    /**
     * Returns the project integration of given id.
     */
    @bindThis
    public getProjectIntegrationById(projectIntegrationId: string): Promise<any> {
        return this.$http.get<any>(this.buildUrl(projectIntegrationId));
    }

    /**
     * Returns the data which will be used by the bubble support tool.
     */
    @bindThis
    public getSupportToolsData(
        projectId: string,
        entitiesIds: string[],
        additionalData?: Record<string, unknown>,
    ): Promise<ContextResponse | undefined> {
        return this.$http.post<any>(this.buildUrl(`${projectId}/dev/tools`), {
            existingContextIds: entitiesIds,
            additionalData,
        });
    }

    /**
     * Returns the training set of given id.
     */
    @bindThis
    public getTrainingSetById(trainingSetId: string): Promise<TrainingSet> {
        return this.$http.get<TrainingSet>(this.buildUrl(trainingSetId));
    }

    /**
     * Returns the training set document types by given id.
     */
    @bindThis
    public calculateDocumentsInitialCollections(trainingSetId: string) {
        return this.$http.get<void>(this.buildUrl(`${trainingSetId}/calculateDocumentsInitialCollections`));
    }

    /**
     * Returns the training set document collections by given id.
     */
    @bindThis
    public getTrainingSetDocumentCollections(
        trainingSetId: string,
        size: number,
        skip: number,
        searchTerm?: string,
        ignoreDocumentCollectionIds?: string[],
    ): Promise<DocumentCollectionsResponse> {
        const params = {
            size,
            skip,
            searchTerm,
            ignoreDocumentCollectionIds,
        };

        return this.$http.get(this.buildUrl(`${trainingSetId}/documentCollections`), {
            params,
        });
    }

    /**
     * Returns document collection identifiers.
     */
    @bindThis
    public getDocumentCollectionIdentifiers(
        projectId: string,
        trainingSetId: string,
        trainingSetItemId: string,
    ): Promise<any> {
        return this.$http.get(
            this.buildUrl(`${projectId}/${trainingSetId}/${trainingSetItemId}/documentCollectionIdentifiers`),
        );
    }

    /**
     * Returns the business labels of a training set.
     */
    @bindThis
    public getBusinessLabelsByTrainingSet(
        trainingSetId: string,
        size: number,
        skip: number,
        searchTerm?: string,
        ignoreBusinessLabelIds?: string[],
    ): Promise<{ businessLabels: BusinessLabel[] }> {
        const params = {
            size,
            skip,
            searchTerm,
            ignoreBusinessLabelIds,
        };

        return this.$http.get(this.buildUrl(`${trainingSetId}/businessLabels`), {
            params,
        });
    }

    /**
     * Returns summary of project integrations in a project.
     */
    @bindThis
    public getProjectIntegrationsSummaries(
        projectId: string,
        excludeCommunicationIntegrations: boolean,
        workflowFolderId?: string,
        filterByIntegrationType?: IntegrationType[],
    ): Promise<GetProjectIntegrationsSummaries> {
        const params: Record<string, any> = {
            excludeCommunicationIntegrations,
            workflowFolderId,
            filterByIntegrationType,
        };
        return this.$http.get<any>(this.buildUrl(`${projectId}/projectIntegrations/summary`), { params });
    }

    /**
     * Delete a project integration by given id
     * @param projectId - The project in which the project integration belongs to
     * @param projectIntegrationId - The id to delete
     */
    @asyncApiJob
    @bindThis
    public deleteProjectIntegration(projectId, projectIntegrationId) {
        return this.$http.delete(this.buildUrl(`${projectId}/${projectIntegrationId}`));
    }

    /**
     * Gets the implications of a publish operation.
     */
    @bindThis
    public getPublishImplications(groupId: string) {
        return this.$http.get<any>(this.buildUrl(`${groupId}/publishImplications`));
    }

    /**
     * Gets the full project object from the API.
     */
    @bindThis
    public getPlans(id: string, promoCode: string) {
        let suffix = '';
        if (promoCode) {
            suffix = `?coupon=${promoCode}`;
        }
        return this.$http.get<any>(this.buildUrl(`${id}/plans${suffix}`));
    }

    @bindThis
    public getNextGathers(projectId: string, groupId: string, ownerId: string) {
        const params = {
            groupId,
            ownerId,
        };

        return this.$http.get<any>(this.buildUrl(`${projectId}/nextGathers`), { params });
    }

    @bindThis
    public getGatherHistory(projectId: string, groupId: string, ownerId: string, query, before, after, limit: number) {
        const params = {
            groupId,
            ownerId,
            query,
            before,
            after,
            limit,
        };

        return this.$http.get<any>(this.buildUrl(`${projectId}/gatherHistory`), { params });
    }

    @bindThis
    public getInbox(projectId: string, before, after, limit: number) {
        const params = {
            before,
            after,
            limit,
        };

        return this.$http.get<any>(this.buildUrl(`${projectId}/inbox`), { params });
    }

    @bindThis
    public markGatherHistoryRead(initiativeId: string, value) {
        return this.$http.post<any>(this.buildUrl(`${initiativeId}/markRead`), { value });
    }

    @bindThis
    public getGatherHistoryById(gatherHistoryId: string) {
        return this.$http.get<any>(this.buildUrl(gatherHistoryId));
    }

    @bindThis
    public addEnterpriseBankAccount(enterpriseId: string, token) {
        return this.$http.post<any>(this.buildUrl(`${enterpriseId}/bankAccounts`), { token });
    }

    /**
     * Gets the full project object from the API.
     * @param id project id.
     */
    @bindThis
    public getProjectLicense(id: string) {
        return this.$http.get<any>(this.buildUrl(`${id}/license`));
    }

    /**
     * Gets the full project object from the API.
     * @param id project id.
     */
    @bindThis
    public getProjectInvoices(id: string) {
        return this.$http.get<any>(this.buildUrl(`${id}/invoices`));
    }

    /**
     * Gets the full project object from the API.
     * @param projectId project id.
     */
    @bindThis
    public getInvoiceCharge(projectId: string, id: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/charges/${id}`));
    }

    /**
     * Updates the project name and emailDomain
     * @param projectId the project Id
     * @param projectName The new name
     * @param emailDomain the new Domain
     * @return }
     */
    @bindThis
    public updateProjectMetadata(projectId: string, projectName, emailDomain) {
        return this.$http.post<any>(this.buildUrl(`${projectId}/metadata`), { name: projectName, emailDomain });
    }

    @bindThis
    public updateProjectIntegrationEntitiesExpirationConfiguration(
        projectIntegrationId: string,
        configuration: { entitiesConfiguration: Record<string, EntityDataRetentionSettings> },
    ) {
        return this.$http.post<any>(
            this.buildUrl(`${projectIntegrationId}/entityExpirationConfiguration`),
            configuration,
        );
    }

    @bindThis
    public updateProjectIntegrationEntitiesInboundConfiguration(
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        nativeEntitiesInboundConfiguration: Record<string, EntityInboundConfiguration>,
    ) {
        return this.$http.post<any>(this.buildUrl(`${projectIntegrationId}/entitiesInboundConfiguration`), {
            nativeEntitiesInboundConfiguration,
        });
    }

    @bindThis
    public updateProjectUrlSlug(projectId: string, urlSlug: string | undefined) {
        return this.$http.post<{ urlSlugEncoded: string }>(this.buildUrl(`${projectId}/urlSlug`), { urlSlug });
    }

    @bindThis
    public updateProjectForceThemeConfiguration(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        forceThemeConfiguration: boolean,
    ) {
        return this.$http.post(this.buildUrl(`${projectId}/setForceThemeConfiguration`), {
            forceThemeConfiguration,
        });
    }

    @bindThis
    public deleteProjectThemeLogoByProjectId(projectId: TonkeanId<TonkeanType.PROJECT>) {
        return this.$http.delete<any>(this.buildUrl(`${projectId}/themeConfiguration/logo`));
    }

    @bindThis
    public deleteProjectThemeBackgroundByProjectId(projectId: TonkeanId<TonkeanType.PROJECT>) {
        return this.$http.delete<any>(this.buildUrl(`${projectId}/themeConfiguration/background`));
    }

    @bindThis
    public deleteProjectThemeCoverLogoByProjectId(projectId: TonkeanId<TonkeanType.PROJECT>) {
        return this.$http.delete<any>(this.buildUrl(`${projectId}/themeConfiguration/coverLogo`));
    }

    @bindThis
    public updateThemeConfiguration(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        themeConfiguration: ProjectThemeConfiguration,
    ) {
        return this.$http.put(this.buildUrl(`${projectId}/themeConfiguration`), {
            themeConfiguration,
        });
    }

    @bindThis
    public updateProjectThemeConfigurationLogoById(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        logo: Blob,
    ): Promise<ProjectThemeConfiguration> {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': logo.type,
            },
        };
        return this.$http.post<ProjectThemeConfiguration>(
            this.buildUrl(`${projectId}/themeConfiguration/logo`),
            logo,
            options,
        );
    }

    @bindThis
    public updateProjectThemeConfigurationBackgroundById(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        file: Blob,
    ): Promise<ProjectThemeConfiguration> {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': file.type,
            },
        };
        return this.$http.post<ProjectThemeConfiguration>(
            this.buildUrl(`${projectId}/themeConfiguration/background`),
            file,
            options,
        );
    }

    @bindThis
    public updateProjectThemeConfigurationCoverLogoById(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        file: Blob,
    ): Promise<ProjectThemeConfiguration> {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': file.type,
            },
        };
        return this.$http.post<ProjectThemeConfiguration>(
            this.buildUrl(`${projectId}/themeConfiguration/coverLogo`),
            file,
            options,
        );
    }

    /**
     * Shares an item with people or communication integration channels
     * @param id
     * @param people People to share with
     * @param communicationChannel Communication channel to share with
     * @param shareType How to send the share message (tonkeanService.shareTypes enum)
     * @param shareMessage The textual share message
     */
    @bindThis
    public shareItem(id: string, people, communicationChannel, shareType, shareMessage) {
        const data: Record<string, any> = {
            shareType,
            users: people,
        };

        if (communicationChannel) {
            data.channel = communicationChannel;
        }

        if (shareMessage) {
            data.message = shareMessage;
        }

        return this.$http.post<any>(this.buildUrl(`${id}/share`), data);
    }

    /**
     * Get a token for annoynimus group access
     */
    @bindThis
    public createGuestAccessToken(groupId: string) {
        return this.$http.post<any>(this.buildUrl(`${groupId}/shared/access?allowAnonymousAccess=true`), {});
    }

    /**
     * Revoke a token for annoynimus group access
     */
    @bindThis
    public revokeGuestAccessToken(groupId: string) {
        return this.$http.delete<any>(this.buildUrl(`${groupId}/shared/access`));
    }

    /**
     * Enable/Regenerate email endpoint info
     */
    @bindThis
    public enableEmailEndpoint(groupId: string) {
        return this.$http.post<any>(this.buildUrl(`${groupId}/workerEmailEndpoint`), {});
    }

    /**
     * Disable email endpoint
     */
    @bindThis
    public disableEmailEndpoint(groupId: string) {
        return this.$http.delete<any>(this.buildUrl(`${groupId}/workerEmailEndpoint`), {});
    }

    /**
     * Updates that user is currently viewing given group.
     */
    @bindThis
    public updateUserViewingGroup(groupId: string) {
        const data = {};
        return this.$http.post<any>(this.buildUrl(`${groupId}/updateViewingGroup`), data);
    }

    /**
     * Updates that user is currently viewing given group, using token.
     */
    @bindThis
    public updateUserViewingGroupWithToken(token, anonymousUserUniqueId: string) {
        const data = {
            anonymousUserUniqueId,
        };

        return this.$http.post<any>(this.buildUrl(`shared/access/${token}/updateViewingGroup`), data);
    }

    /**
     * Gets anonymous group by token.
     */
    @bindThis
    public getGroupByGuestToken(token, anonymousUserUniqueId: string) {
        return this.$http.get<any>(
            this.buildUrl(`shared/access/${token}/group?anonymousUserUniqueId=${anonymousUserUniqueId}`),
        );
    }

    /**
     * Gets anonymous group by token.
     */
    @bindThis
    public getInitiativesByGuestToken(token, limit: number, anonymousUserUniqueId: string) {
        // Set some limit if we haven't received one.
        if (!limit) {
            limit = 50;
        }
        return this.$http.get<any>(
            this.buildUrl(
                `shared/access/${token}/initiatives?limit=${limit}&anonymousUserUniqueId=${anonymousUserUniqueId}`,
            ),
        );
    }

    /**
     * Approve that question will be sent to people.
     * @param projectId The project id.
     * @param owners An array of owners (ids or people).
     */
    @bindThis
    public saveOwners(projectId: string, owners) {
        owners = owners.map((p) => {
            return p.id || p;
        });
        return this.$http.post<any>(this.buildUrl(`${projectId}/owners`), { owners });
    }

    /**
     * Gets the contribute Leaderboard of the current user.
     * @param projectId The project id.
     */
    @bindThis
    public getContributionLeaderboard(projectId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/leaderboard`));
    }

    @bindThis
    public getTempUsers(projectId: string) {
        return this.getTeamMembers(projectId, { onlyTemporary: true, includeTemporary: true });
    }

    /**
     * Gets the team Members Base.
     * @param projectId The project id.
     * @param [uri] Decide if to bring the summary or the full person.
     * @param options The api options.
     * @param [query] search term.
     * @param [limit] The result page number.
     * @param [skip] The result page number.
     */
    @bindThis
    private getTeamMembersBase<T>(
        projectId: string,
        uri: string,
        options?: GetTeamMemberOptions,
        query?: string,
        limit: number = 100,
        skip: number = 0,
    ) {
        options = options || {};
        limit = angular.isNumber(limit) && limit >= 0 ? limit : 100;
        skip = angular.isNumber(skip) && skip >= 0 ? skip : 0;
        const params: Record<string, any> = {
            includeTemporary: !!options.includeTemporary,
            onlyTemporary: !!options.onlyTemporary,
            includeDisabled: !!options.includeDisabled,
            includeActive: options.includeActive !== undefined ? options.includeActive : true,
            onlyAsked: !!options.onlyAsked,
            includePatternsData: !!options.includePatternsData,
            excludeMe: !!options.excludeMe,
            onlyLicensed: !!options.onlyLicensed,
            onlyNotLicensed: !!options.onlyNotLicensed,
            onlyUsers: !!options.onlyUsers, // users who have signed into the app.
            onlyOwnersInGroupId: options.onlyOwnersInGroupId,
            onlyCreatorsInGroupId: options.onlyCreatorsInGroupId,
            onlyProvisionedUsers: options.onlyProvisionedUsers,
            disabledAsRole: options.disabledAsRole,
            scimRoleType: options.SCIMRolesType,
            onlyProcessContributors: options.onlyProcessContributors,
            excludePersonIds: options.excludePersonIds,
            includeExternal: options.includeExternal,
            limit,
        };

        if (angular.isString(options.sortField)) {
            params.sortField = options.sortField;
        }
        if (options.hasOwnProperty('sortDesc')) {
            params.sortDesc = !!options.sortDesc;
        }
        if (
            options.hasOwnProperty('group') &&
            options.group &&
            !options.doNotSearchOnlyOwnersInGroup &&
            options.group.visibilityType === 'PRIVATE'
        ) {
            params.privateGroupId = options.group.id;
        }

        if (query) {
            params.q = query;
        }
        if (skip) {
            params.skip = skip;
        }
        return this.$http.get<T>(this.buildUrl(`${projectId}/${uri}`), { params });
    }

    /**
     * Gets the team Members.
     * @param projectId The project id.
     * @param options The api options.
     * @param [query] search term.
     * @param [limit] The result page number.
     * @param [skip] The result page number.
     */
    @bindThis
    public getTeamMembers(
        projectId: string,
        options?: GetTeamMemberOptions,
        query?: string,
        limit: number = 100,
        skip: number = 0,
    ) {
        return this.getTeamMembersBase<PeopleInProject>(projectId, 'people', options, query, limit, skip);
    }

    /**
     * Gets the team Members Summary.
     * @param projectId The project id.
     * @param options The api options.
     * @param [query] search term.
     * @param [limit] The result page number.
     * @param [skip] The result page number.
     */
    @bindThis
    public getTeamMembersSummary(
        projectId: string,
        options?: GetTeamMemberOptions,
        query?: string,
        limit: number = 100,
        skip: number = 0,
    ) {
        return this.getTeamMembersBase<PeopleSummaryInProject>(projectId, 'peopleSummary', options, query, limit, skip);
    }

    @bindThis
    public getBoardPeople(projectId: string, limit: number, skip: number, options?: GetTeamMemberOptions, query?) {
        return this.getTeamMembers(projectId, options, query, limit, skip);
    }

    /**
     * Gets the projects user groups.
     * @param projectId The project id.
     * @param [query] search term.
     * @param [limit] The result page number.
     * @param [skip] The result page number.
     */
    @bindThis
    public getProjectGroups(
        projectId: string,
        limit: number,
        skip: number,
        query?: string | Record<string, unknown>,
        userGroupIds?: string[],
    ) {
        limit = angular.isNumber(limit) && limit >= 0 ? limit : 100;
        skip = angular.isNumber(skip) && skip >= 0 ? skip : 0;

        const params: Record<string, any> = {
            limit,
        };

        if (query) {
            params.q = query;
        }
        if (skip) {
            params.skip = skip;
        }
        if (userGroupIds) {
            params.userGroupIds = userGroupIds;
        }

        return this.$http.get<ProjectUserGroups>(this.buildUrl(`${projectId}/userGroups`), { params });
    }

    @bindThis
    public getProjectGroupsCount(projectId: string) {
        return this.$http.get<{ count: number }>(this.buildUrl(`${projectId}/userGroupsCount`));
    }

    /**
     * Gets project people by given ids.
     */
    @bindThis
    public getProjectPeopleByIds(
        projectId: string,
        peopleIds: string[],
        skip: number,
        limit: number,
        includeEnabled?: boolean,
        includeDisabled?: boolean,
        scimRoleType?: SCIMRolesType,
        searchTerm?: string,
    ) {
        const data = {
            peopleIds,
        };

        return this.$http.post<ProjectPeopleByIds>(this.buildUrl(`${projectId}/getPeopleByIds`), data, {
            params: { skip, limit, includeDisabled, includeEnabled, scimRoleType, searchTerm },
        });
    }

    /**
     * Gets project people by given emails.
     */
    @bindThis
    public getProjectPeopleByEmails(projectId: string, peopleEmails) {
        const data = {
            peopleEmails,
        };

        return this.$http.post<{ entities: Person[] }>(this.buildUrl(`${projectId}/getPeopleByEmails`), data);
    }

    /**
     * Gets project potential seats - owners of tracks + owner of list + owner of project.
     */
    @bindThis
    public getProjectPotentialSeats(projectId: string, options) {
        const params = {
            excludeMe: !!options.excludeMe,
            excludeBuyer: !!options.excludeBuyer,
        };
        return this.$http.get<any>(this.buildUrl(`${projectId}/potentialSeats`), { params });
    }

    /** Save enterprise owners **/
    @bindThis
    public saveEnterpriseOwners(enterpriseId: string, owners) {
        owners = owners.map((p) => {
            return { name: p.name, email: p.email } || p;
        });
        return this.$http.post<any>(this.buildUrl(`${enterpriseId}/owners`), { owners });
    }

    /** Deletes enterprise Seat **/
    @bindThis
    public deleteEnterpriseSeat(enterpriseId: string, personId: string) {
        return this.$http.delete<any>(this.buildUrl(`${enterpriseId}/seats/${personId}`));
    }

    /** Update project enterprise id **/
    @bindThis
    public updateProjectEnterpriseId(projectId: string, enterpriseId: string) {
        return this.$http.post<any>(this.buildUrl(`${projectId}/enterprise/${enterpriseId}`), undefined);
    }

    /**
     * Deletes project enterprise id.
     */
    @bindThis
    public deleteProjectEnterpriseId(projectId: string, enterpriseId: string) {
        return this.$http.delete<any>(this.buildUrl(`${projectId}/enterprise/${enterpriseId}`));
    }

    /** Update enterprise name **/
    @bindThis
    public updateEnterpriseName(enterpriseId: string, name) {
        const data = {
            value: name,
        };

        return this.$http.post<any>(this.buildUrl(`${enterpriseId}/name`), data);
    }

    /** Update Enterprise Preferred Login Type - slack/google **/
    @bindThis
    public updateEnterprisePreferredLoginType(
        enterpriseId: string,
        preferredLoginType: string,
        identityProviderUrl: string,
        secrets: EnterpriseLoginSecrets,
    ) {
        const data: Record<string, any> = {
            value: preferredLoginType,
        };

        if (identityProviderUrl) {
            data.identityProviderUrl = identityProviderUrl;
        }

        if (secrets) {
            data.loginSecrets = secrets;
        }

        return this.$http.post<any>(this.buildUrl(`${enterpriseId}/preferredLoginType`), data);
    }

    /** Update Enterprise Domains **/
    @bindThis
    public updateEnterpriseDomains(enterpriseId: string, domains?: string[]) {
        const data = {
            domains,
        };
        return this.$http.post<any>(this.buildUrl(`${enterpriseId}/domains`), data);
    }

    /** Update Enterprise Domains **/
    @bindThis
    public getEnterpriseDomains(enterpriseId: string) {
        return this.$http.get<any>(this.buildUrl(`${enterpriseId}/domains`));
    }

    /**
     *  Get enterprise members
     * @param orgId The organization id.
     * @param options The api options.
     * @param [query] search term.
     * @param [limit] The result page number.
     * @param [skip] The result page number.
     * **/
    @bindThis
    public getEnterpriseMembers(enterpriseId: string, options, query, limit: number, skip: number) {
        options = options || {};
        limit = angular.isNumber(limit) && limit >= 0 ? limit : 100;
        skip = angular.isNumber(skip) && skip >= 0 ? skip : 0;
        const params: Record<string, any> = {
            limit,
        };

        if (angular.isString(options.sortField)) {
            params.sortField = options.sortField;
        }
        if (options.hasOwnProperty('sortDesc')) {
            params.sortDesc = !!options.sortDesc;
        }

        if (query) {
            params.q = query;
        }
        if (skip) {
            params.skip = skip;
        }
        return this.$http.get<any>(this.buildUrl(`${enterpriseId}/seats`), { params });
    }

    /**
     * Gets the team Members.
     * @param orgId The organization id.
     * @param options The api options.
     * @param [query] search term.
     * @param [limit] The result page number.
     * @param [skip] The result page number.
     */
    @bindThis
    public getOrganizationUsers(orgId: string, options, query, limit: number, skip: number) {
        options = options || {};
        limit = angular.isNumber(limit) && limit >= 0 ? limit : 100;
        skip = angular.isNumber(skip) && skip >= 0 ? skip : 0;
        const params: Record<string, any> = {
            limit,
        };

        if (angular.isString(options.sortField)) {
            params.sortField = options.sortField;
        }
        if (options.hasOwnProperty('sortDesc')) {
            params.sortDesc = !!options.sortDesc;
        }

        if (query) {
            params.q = query;
        }
        if (skip) {
            params.skip = skip;
        }
        return this.$http.get<any>(this.buildUrl(`${orgId}/users`), { params });
    }

    /**
     * Mark yourself as a stakeholder
     * @param projectId The project ID
     * @param personId The person ID.
     * @param disableValue true/false
     */
    @bindThis
    public disablePersonInProject(projectId: string, personId: string, disableValue) {
        return this.$http.post<any>(this.buildUrl(`${projectId}/${personId}/disabled`), null, {
            params: { value: !!disableValue },
        });
    }

    @bindThis
    public setProjectLimitedLicense(projectId: string) {
        return this.$http.post<any>(this.buildUrl(`${projectId}/freeLicense`), undefined);
    }

    @bindThis
    public getFieldGraphValues(fieldId: string, from, to, limit: number) {
        const config: IRequestShortcutConfig = {
            params: {
                from,
                to,
                limit,
            },
        };

        return this.$http.get<any>(this.buildUrl(`${fieldId}/history`), config);
    }

    @bindThis
    public getChangesCountersForWorkflowVersions(
        groupId: string,
        workflowVersionIds: string[],
        includePeopleWhoMadeChangesToDraft: boolean,
    ) {
        const config: IRequestShortcutConfig = {
            params: {
                workflowVersionIds,
                includePeopleWhoMadeChangesToDraft,
            },
        };

        return this.$http.get<{ peopleWhoMadeChangesToDraft: Person[]; changesCountsMap: Record<string, number> }>(
            this.buildUrl(`${groupId}/changesCountersForWorkflowVersions`),
            config,
        );
    }

    /**
     * Gets the evaluated expression .
     * * @param projectId
     * * @param initiativeId
     * * @param expressions.
     * @param returnFullLengthEvaluatedValue Optional parameter. If true the full-length evaluated value will be returned.
     * Otherwise, it will be truncated.
     */
    @bindThis
    public getEvaluatedExpressionsForInitiative(
        projectId: string,
        initiativeId: string | undefined,
        expressions: TonkeanExpressionForServerEvaluation[],
        returnFullLengthEvaluatedValue?: boolean,
    ) {
        const config: IRequestShortcutConfig = {
            params: {
                returnFullLengthEvaluatedValue,
            },
        };

        let url = `${projectId}/evaluatedExpressionForInitiative`;
        if (initiativeId) {
            url = `${url}?initiativeId=${initiativeId}`;
        }
        return this.$http.post<TonkeanExpressionServerEvaluationResult>(this.buildUrl(url), { expressions }, config);
    }

    @bindThis
    public getEvaluatedFormulaExpressionsForInitiative(
        projectId: string,
        initiativeId: string | undefined,
        expressions: { expression: ServerFormulaExpressionNode; key: string }[],
    ) {
        const config: IRequestShortcutConfig = {
            params: {},
        };

        return this.$http.post<{ evaluatedExpressions: Record<string, string> }>(
            this.buildUrl(`${projectId}/evaluatedJsonExpressionForInitiative?initiativeId=${initiativeId}`),
            { expressions },
            config,
        );
    }

    /**
     * Gets the evaluated expression .
     * * @param projectId - project Id
     * * @param workerRunLogicId - worker run logic id
     * * @param workerRunId - worker run id
     * * @param workerRunStartTime - worker run start time
     * * @param expressions.
     * @param returnFullLengthEvaluatedValue Optional parameter. If true the full-length evaluated value will be returned.
     * Otherwise, it will be truncated.
     */
    @bindThis
    public getEvaluatedExpressionsForWorkerRunLogic(
        projectId: string,
        workerRunLogicId: string,
        workerRunId: string,
        workerRunStartTime: number,
        expressions: { expression: string; key: string }[],
        returnFullLengthEvaluatedValue?: boolean,
    ) {
        const config: IRequestShortcutConfig = {
            params: {
                returnFullLengthEvaluatedValue,
            },
        };

        const data = { expressions, workerRunId, workerRunStartTime };

        return this.$http.post<{ evaluatedExpressions: Record<string, string> }>(
            this.buildUrl(`${projectId}/${workerRunLogicId}/evaluatedExpression`),
            data,
            config,
        );
    }

    /**
     * Gets the evaluated expression when you dont have an initiative.
     * * @param expressions.
     */
    @bindThis
    public getEvaluatedExpressions(groupId: string, expressions) {
        const data = {
            expressions,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/evaluatedExpression`), data);
    }

    /**
     * Gets the evaluated expression when you dont have an initiative and group.
     * * @param projectId.
     * * @param workflowVersionType.
     * * @param expressions.
     */
    @bindThis
    public getEvaluatedExpressionForProject(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowVersionType: WorkflowVersionType,
        expressions: TonkeanExpressionForServerEvaluation[],
    ) {
        const data = {
            workflowVersionType,
            expressions,
        };

        return this.$http.post<TonkeanExpressionServerEvaluationResult>(
            this.buildUrl(`${projectId}/evaluatedExpression`),
            data,
        );
    }

    /**
     * Gets the groups from the evaluated regex
     * @param initiativeId
     * @param regexExpression
     * @param inputExpression
     * @param matchIndexes
     * @param groupIndexes
     * @param groupsSeparator
     */
    @bindThis
    public getEvaluatedRegexExpressions(
        initiativeId: string,
        regexExpression: string,
        inputExpression: string,
        matchIndexes: string,
        groupIndexes: string,
        groupsSeparator: string,
    ): Promise<EvaluatedRegexExpressions> {
        const body = {
            regexExpression,
            inputExpression,
            matchIndexes,
            groupIndexes,
            groupsSeparator,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/evaluateRegexExpression`), body);
    }

    /**
     * Start login/register by sending email to the given email address
     * @param email email to login/register with
     */
    @bindThis
    public sendLoginEmail(email, useConfiguration, referralCode) {
        const options = {
            params: {
                email,
                noOp: useConfiguration,
                referralCode,
            },
        };
        return this.$http.post<any>(this.buildUrl('authenticate/send_email'), null, options);
    }

    @bindThis
    public resendLoginEmail(secret, referralCode) {
        const data = {
            secret,
        };

        const options = {
            params: {
                referralCode,
            },
        };

        return this.$http.post<any>(this.buildUrl('authenticate/resend_email'), data, options);
    }

    /**
     * Adds a authentication token for the given identity provider in the given project.
     */
    public generateAccessTokenForIdentityProvider(projectId, identityProviderId, displayName) {
        const data = {
            projectId,
            displayName,
        };

        return this.$http.post<GenerateTokenForIdentityProviderResponse>(
            this.buildUrl(`${projectId}/${identityProviderId}/authToken`),
            data,
        );
    }

    public revokeAccessTokenForIdentityProvider(projectId, identityProviderId, tokenId) {
        return this.$http.delete<RevokeTokenForIdentityProviderResponse>(
            this.buildUrl(`${projectId}/${identityProviderId}/${tokenId}`),
        );
    }

    /**
     * Gets the identity provider configured for project
     */
    public getProjectIdentityProvider(projectId) {
        return this.$http.get<IdentityProvider>(this.buildUrl(`${projectId}/identityProvider`));
    }

    /**
     * Update the identity provider configured for project
     */
    public createProjectIdentityProvider(projectId, applicationName, displayName) {
        const data = {
            applicationName,
            displayName,
        };
        return this.$http.post<CreateIdentityProviderResponse>(this.buildUrl(`${projectId}/identityProvider`), data);
    }

    /**
     * Login read-only mode for quick access without sign-up using link from message.
     * @param secret the secret we got from link
     */
    @bindThis
    public loginPartial(secret) {
        const data = {
            secret,
        };
        const options: IRequestShortcutConfig = {
            params: {
                tokenName: this.utils.getBrowserName(),
                timezone: Math.floor(new Date().getTimezoneOffset() / 60) * -1,
            },
        };
        return this.$http.post<any>(this.buildUrl('authenticate/partial'), data, options);
    }

    /**
     * Complete the login by email process via the server
     * @param secret the secret we got from email
     * @param userName (optional) the name of the user if this is the first time (register)
     */
    @bindThis
    public loginEmail(secret, userName, referralCode) {
        const data: Record<string, any> = {
            secret,
        };

        if (userName) {
            data.name = userName;
        }

        const options: IRequestShortcutConfig = {
            params: {
                tokenName: this.utils.getBrowserName(),
                timezone: Math.floor(new Date().getTimezoneOffset() / 60) * -1,
                referralCode,
            },
        };

        return this.$http.post<any>(this.buildUrl('authenticate/email'), data, options);
    }

    /**
     * Complete the login process via the server
     */
    @bindThis
    public loginOAuth(provider, code, isLocal, redirectUri, referralCode, identityProviderUri, authType) {
        const tokenName = this.utils.getBrowserName();
        const options: IRequestShortcutConfig = {
            params: {
                tokenName,
                timezone: Math.floor(new Date().getTimezoneOffset() / 60) * -1,
                referralCode,
            },
        };

        const data: Record<string, any> = {
            code,
            isLocal,
        };

        if (redirectUri) {
            data.redirectUri = redirectUri;
        }

        if (identityProviderUri) {
            data.identityProviderUri = identityProviderUri;
        }

        if (authType) {
            data.authType = authType;
        }

        return this.$http.post<any>(this.buildUrl(`authenticate/${provider}`), data, options);
    }

    /**
     * Get additional information for the provider given
     */
    @bindThis
    public getIDPLoginInfo(provider, identityProviderUri, domain) {
        const data: Record<string, any> = {};

        if (identityProviderUri) {
            data.identityProviderUri = identityProviderUri;
        }
        if (domain) {
            data.domain = domain;
        }

        return this.$http.post<IDPLoginData>(this.buildUrl(`loginInfo/${provider}`), data);
    }

    /**
     * Gets the login options (configurations)
     */
    @bindThis
    public getLoginOptions() {
        return this.$http.get<any>(this.buildUrl('authenticate/options'));
    }

    /**
     * Ends the current session
     */
    @bindThis
    public logout() {
        if (this.authenticationService.currentUser && this.authenticationService.currentUser.accessToken) {
            // We add the access token manually here since
            // the current user is deleted right after this call (without waiting for completion)
            const options: IRequestShortcutConfig = {
                headers: { Authorization: `token ${this.authenticationService.currentUser.accessToken}` },
            };

            return this.$http.post<any>(this.buildUrl('logout'), null, options);
        }
    }

    /**
     * Creates an organization
     * @param emailDomain  the company email domain
     * @param [name]  The name of the organization
     * @return }
     */
    @bindThis
    public createOrganization(emailDomain, name) {
        const data: Record<string, any> = { emailDomain };
        if (name) {
            data.name = name;
        }
        return this.$http.post<any>(this.buildUrl('me/organizations'), data);
    }

    /**
     * Adds an email for the current user
     * @param email  the new email
     * @param ref  where should we go back to
     * @return }
     */
    @bindThis
    public addUserEmail(email, ref) {
        return this.$http.post<any>(this.buildUrl('me/emails'), null, { params: { email, ref } });
    }

    /**
     * Gets the activity feed of a project from the API.
     */
    @bindThis
    public getProjectActivity(
        projectId: string,
        limit: number,
        types: string | any[],
        groupId: string,
        targetType: any,
        initiativeId: string,
        targetTypeId: string,
        loadTotal: boolean,
        skip: number,
        entityVersionId: string,
        shouldLoadCommitActivities: boolean,
        subSequentialIdentifier?: number,
    ) {
        let suffix = '';
        if (limit) {
            suffix = `?limit=${limit}`;
        }

        if (skip) {
            suffix += `&skip=${skip}`;
        }

        if (targetTypeId) {
            suffix += `&targetTypeId=${targetTypeId}`;
        }

        if (loadTotal) {
            suffix += `&loadTotal=${loadTotal}`;
        }

        if (types && types.length) {
            for (const type of types) {
                suffix += `&type=${type}`;
            }
        }

        if (groupId) {
            suffix += `&groupId=${groupId}`;
        }

        if (targetType) {
            suffix += `&targetType=${targetType}`;
        }

        if (entityVersionId) {
            suffix += `&entityVersionId=${entityVersionId}`;
        }

        if (subSequentialIdentifier != undefined) {
            suffix += `&subSequentialIdentifier=${subSequentialIdentifier}`;
        }

        if (shouldLoadCommitActivities) {
            suffix += `&shouldLoadCommitActivities=${shouldLoadCommitActivities}`;
        }

        if (initiativeId) {
            return this.$http.get<any>(this.buildUrl(`${getInitiativeIdPath(initiativeId)}/activity2${suffix}`));
        }

        return this.$http.get<any>(this.buildUrl(`${getProjectIdPath(projectId)}/activity2${suffix}`));
    }

    @bindThis
    public getGroupWorkflowVersion(groupId: string, workflowVersionType: WorkflowVersionType) {
        return this.$http.get<WorkflowVersion>(this.buildUrl(`${groupId}/${workflowVersionType}`));
    }

    /**
     * Returns the workflow versions of given group.
     */
    @bindThis
    public getGroupWorkflowVersions(
        groupId: string,
        skip: number,
        limit: number,
        workflowVersionType,
        excludeWorkflowVersionType,
        workflowVersionIds,
        commentText?,
        createdFrom?,
        createdTo?,
        publisherPersonIds?,
        sequentialIdentifiers?,
        doNotReturnFirstPublishedVersion?,
    ) {
        const config: IRequestShortcutConfig = {
            params: {
                skip,
                limit,
                workflowVersionType,
                excludeWorkflowVersionType,
                workflowVersionIds,
                commentText,
                createdFrom,
                createdTo,
                publisherPersonIds,
                sequentialIdentifiers,
                doNotReturnFirstPublishedVersion,
            },
        };

        return this.$http.get<{ entities: WorkflowVersion[] }>(this.buildUrl(`${groupId}/workflowVersions`), config);
    }

    /**
     * Returns a workflow version based on given workflow version id.
     */
    @bindThis
    public getWorkflowVersionById(workflowVersionId: string, includePublishedConfiguration: boolean = false) {
        return this.$http.get<WorkflowVersion>(this.buildUrl(workflowVersionId), {
            params: { includePublishedConfiguration },
        });
    }

    /**
     * Returns workflow versions based on given workflow version ids.
     */
    @bindThis
    public getWorkflowVersionsByIds(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowVersionIds: TonkeanId<TonkeanType.WORKFLOW_VERSION>[],
    ) {
        return this.$http.get<{ entities: WorkflowVersion[] }>(this.buildUrl(`${projectId}/workflowVersions`), {
            params: { workflowVersionIds },
        });
    }

    /**
     * Gets the activity feed of a group from the API
     */
    @bindThis
    public getGroupActivity(
        groupId: string,
        limit: number,
        types: string[],
        targetType,
        skip: number,
        shouldLoadCommitActivities: boolean,
        actors: Person[] | null,
        fromDate: number | undefined | null,
        toDate: number | undefined | null,
        loadTotal: boolean,
        workflowVersionId: string,
        entityId: string | null,
        subSequentialIdentifier: number | null,
    ) {
        let suffix = '';

        if (limit) {
            suffix = `?limit=${limit}`;
        }

        if (skip) {
            suffix += `&skip=${skip}`;
        }

        if (actors && actors.length) {
            for (const actor of actors) {
                suffix += `&actorId=${actor!.id}`;
            }
        }

        if (types && types.length) {
            for (const type of types) {
                suffix += `&type=${type}`;
            }
        }

        if (targetType) {
            suffix += `&targetType=${targetType}`;
        }

        if (fromDate) {
            suffix += `&fromDate=${fromDate}`;
        }

        if (toDate) {
            suffix += `&toDate=${toDate}`;
        }

        if (loadTotal) {
            suffix += `&loadTotal=${loadTotal}`;
        }

        if (workflowVersionId) {
            suffix += `&workflowVersionId=${workflowVersionId}`;
        }

        if (entityId) {
            suffix += `&entityId=${entityId}`;
        }

        if (subSequentialIdentifier) {
            suffix += `&subSequentialIdentifier=${subSequentialIdentifier}`;
        }

        if (shouldLoadCommitActivities) {
            suffix += `&shouldLoadCommitActivities=${shouldLoadCommitActivities}`;
        }

        return this.$http.get<ActivitiesResponse>(this.buildUrl(`${groupId}/activity2${suffix}`));
    }

    /**
     * Gets the activity feed of a group from the API
     */
    @bindThis
    public getActivitiesGroupsByEntityId(
        groupId: string,
        limit: number,
        types: string[],
        skip: number,
        actors: Person[],
        fromDate: number,
        toDate: number,
        loadTotal: boolean,
        workflowVersionId: string,
        projectId: string,
        shouldLoadCommitActivities: boolean,
        subSequentialIdentifier: number | null,
    ) {
        const actorsIds = actors?.map((actor) => actor.id);

        const params = {
            limit,
            skip,
            fromDate,
            toDate,
            workflowVersionId,
            loadTotal,
            actorsIds,
            types,
            projectId,
            shouldLoadCommitActivities,
            subSequentialIdentifier,
        };

        return this.$http.get<any>(this.buildUrl(`${groupId}/activity2/grouped`), { params });
    }

    /**
     * Get custom trigger children of type by workflow version id
     */
    @bindThis
    public getCustomTriggerChildrenOfType(workflowVersionId: string, customTriggerId: string, childrenType: string) {
        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${customTriggerId}/childrenOfType`), {
            params: { childrenType },
        });
    }

    /**
     * Gets the activity feed of a problem from the API.
     */
    @bindThis
    public getInitiativeActivity(
        initiativeId: string,
        limit: number,
        skip: number,
        types,
        includeDescendants,
        loadTotal,
        includeIntake: boolean,
        excludeOtherTargetsComments: boolean,
        includeOnlyComments?: boolean,
    ) {
        let suffix = `?limit=${limit}`;

        if (skip) {
            suffix += `&skip=${skip}`;
        }

        if (types && types.length) {
            for (const type of types) {
                suffix += `&type=${type}`;
            }
        }

        if (includeDescendants) {
            suffix += '&includeDescendantsActivity=true';
        }

        if (loadTotal) {
            suffix += '&loadTotal=true';
        }

        if (includeIntake) {
            suffix += '&includeIntakeActivities=true';
        }

        if (excludeOtherTargetsComments) {
            suffix += '&excludeOtherTargetsComments=true';
        }

        if (includeOnlyComments) {
            suffix += '&includeOnlyComments=true';
        }

        return this.$http.get<any>(this.buildUrl(`${getInitiativeIdPath(initiativeId)}/activity2${suffix}`));
    }

    /**
     * Gets the questions from the API.
     */
    @bindThis
    public getQuestions(projectId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/questions?getAnswerCount=true`));
    }

    /**
     * Post answer to a question with the API.
     * @param questionId the id of the question to post answer to
     * @param answerId the id of the answer statement
     */
    @bindThis
    public postAnswerById(questionId: string, answerId: string) {
        return this.$http.post<any>(this.buildUrl(`${questionId}/answer/${answerId}?getAnswerCount=true`), undefined);
    }

    /**
     * Post answer with other text.
     * @param questionId the id of the question to post answer to
     * @param otherText the id of the answer statement
     */
    @bindThis
    public postOtherAnswer(questionId: string, otherText) {
        return this.$http.post<any>(this.buildUrl(`${questionId}/answer/other?getAnswerCount=true`), { otherText });
    }

    /**
     * Post answer to a question with the API.
     */
    @bindThis
    public postAnswerBySecret(secret, answerIndex) {
        return this.$http.post<any>(this.buildUrl(`${secret}/answer/${answerIndex}`), {});
    }

    /**
     * Get used project integration action entities by definition
     */
    @bindThis
    public extractProjectIntegrationActionParams(
        projectIntegrationId: string,
        httpRequestDefinition: CustomActionDefinition,
        entitiesToRunOn: string[],
    ) {
        const data = {
            requestDefinition: httpRequestDefinition,
            entitiesToRunOn,
        };

        return this.$http.post<{ entityParameters: BaseActionParameter[] }>(
            this.buildUrl(`${projectIntegrationId}/test/params`),
            data,
        );
    }

    /**
     * Post a link in a message was clicked.
     */
    @bindThis
    public sendMessageClick(secret, messageTypeId: string, projectId: string) {
        let uri = `message/click/${secret}/${messageTypeId}`;
        if (projectId) {
            uri += `?projectId=${projectId}`;
        }
        return this.$http.get<any>(this.buildUrl(uri));
    }

    /**
     * Gets the tags autocomplete options
     */
    @bindThis
    public searchFunctions(projectId: string, term?, limit?) {
        let suffix = '';
        if (limit) {
            suffix = `&limit=${limit}`;
        }
        let q = '';
        if (term) {
            q = `q=${term}`;
        }
        return this.$http.get<any>(this.buildUrl(`${projectId}/functions?${q}${suffix}`));
    }

    /**
     * Gets the supported entities of the given integration.
     * @param projectId The relevant project.
     * @param projectIntegrationId The integration to return entities of.
     */
    @bindThis
    public getIntegrationSupportedEntities(projectIntegrationId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectIntegrationId}/supportedEntities`));
    }

    @bindThis
    public getProjectIntegrationDataRetentionConfiguration(projectIntegrationId: string) {
        return this.$http.get<ProjectIntegrationEntitiesDataRetention>(
            this.buildUrl(`${projectIntegrationId}/entitiesDataRetentionSettings`),
        );
    }

    /**
     * Gets the project groups
     */
    @bindThis
    public getGroups(projectId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/groups`));
    }

    /**
     * Gets the groups by ids
     */
    @bindThis
    public getGroupsByIds(projectId: string, groupIds: string[]) {
        const config: IRequestShortcutConfig = {
            params: {
                groupIds,
            },
        };

        return this.$http.get<Group[]>(this.buildUrl(`${projectId}/groupsByIds`), config);
    }

    /**
     * Gets the groups that a specific user is in.
     * @returns the ids of groups which the user belongs to
     */
    @bindThis
    public getGroupsByUserId(projectId: string, userId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/groups/${userId}`));
    }

    /**
     * Gets the group names and ids of a given solution
     */
    @bindThis
    public getGroupsBySolution(projectId: string, folderId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/${folderId}/groups`));
    }

    @bindThis
    public getWorkerRuns(
        projectId: string,
        groupId: string,
        workerRunsApiFilter: GetWorkerRunsApiFilters,
        skip: number = 0,
        limit: number = 0,
        nextPageToken: string | undefined,
        returnTotalCount?: boolean,
    ): Promise<SearchWorkerRunsResponse> {
        const body = {
            workerRunsApiFilterParameters: workerRunsApiFilter || undefined,
            excludeWorkerRunFields:
                'workerRunReasonData.fieldDefinitions,matchQueryResultUnmapped,matchQueryResultExplanation,initiativeUpdateType',
            excludeWorkerRunLogicFields:
                'finalInitiativeFieldsStateUnmapped,initialInitiativeFieldsStateUnmapped,workerRun,executionOutput,requestJson',
            skip: skip || undefined,
            limit: limit || undefined,
            nextPageToken: nextPageToken || undefined,
            returnTotalCount: returnTotalCount || undefined,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/${groupId}/workerRuns/search`), body);
    }

    @bindThis
    public getWorkerRunsStats(
        projectId,
        groupId,
        includeYear,
        includeMonth,
        includeWeek,
        includeDay,
        includeBillingUsageReported,
    ) {
        const params = {
            groupId,
            includeYear,
            includeMonth,
            includeWeek,
            includeDay,
            includeBillingUsageReported,
        };

        const queryString = this.$httpParamSerializer(params);

        return this.$http.get<any>(this.buildUrl(`${projectId}/workerRunsStats?${queryString}`));
    }

    @bindThis
    public getWorkerRunsStatsNewVersion(
        projectId: string,
        groupIds: string[] | null,
        workflowVersionType,
        includeYear,
        includeMonth,
        includeWeek,
        includeDay,
        includeLastFlowTime,
        filterByWorkerRunReasons,
    ) {
        const params = {
            groupIds,
            includeYear,
            includeMonth,
            includeWeek,
            includeDay,
            includeLastFlowTime,
            workflowVersionType,
            filterByWorkerRunReasons,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/workerRunsStatsNewVersion`), params);
    }

    @bindThis
    public generateIntercomUserHash() {
        return this.$http.get<any>(this.buildUrl('generateIntercomUserHash'));
    }

    /**
     * Gets the tags autocomplete options
     */
    @bindThis
    public getFunctionByName(projectId: string, name) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/functions/${name}`));
    }

    /**
     * Gets the custom triggers of given workflow version.
     */
    @bindThis
    public getCustomTriggersOfWorkflowVersion(workflowVersionId: string) {
        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/customTriggers`));
    }

    /**
     * Gets summary information of custom triggers for the purpose calculating custom triggers sequences
     */
    @bindThis
    public getCustomTriggersInSequenceSummaryOfWorkflowVersion(workflowVersionId: string) {
        return this.$http.get<CustomTriggersInSequenceSummaryDataResponse>(
            this.buildUrl(`${workflowVersionId}/customTriggersInSequenceSummary`),
        );
    }

    @bindThis
    public getIntakeSequenceCustomTriggersForWorkflowFolder(
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        workflowVersionType: WorkflowVersionType,
    ) {
        return this.$http.get<{ entities: IntakeSequenceCustomTriggerData[] }>(
            this.buildUrl(`${workflowFolderId}/intakeSequences/${workflowVersionType}`),
        );
    }

    /**
     * Updates a custom trigger graph for a workflow version.
     */
    @bindThis
    public updateWorkflowVersionCustomTriggersGraph(groupId: string, graph) {
        const data = {
            root: graph,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/customTriggersGraph`), data);
    }

    /**
     * Updates a custom trigger parent for a workflow version.
     */
    @bindThis
    public updateCustomTriggerParent(
        groupId: string,
        parentCustomTriggerId: string,
        movedCustomTriggerId: string,
        belowCustomTriggerId: string,
    ) {
        const data = {
            parentCustomTriggerId,
            belowCustomTriggerId,
            groupId,
        };

        return this.$http.post<any>(this.buildUrl(`${movedCustomTriggerId}/updateCustomTriggerParent`), data);
    }

    /**
     * Updates a custom trigger parent for a workflow version and remove custom triggers.
     */
    @bindThis
    public moveAndRemoveCustomTrigger(
        groupId: string,
        parentCustomTriggerId: string,
        movedCustomTriggerIds,
        belowCustomTriggerId: string,
        childrenCustomTriggerToRemoveIds,
    ) {
        const data = {
            movedCustomTriggerIds,
            belowCustomTriggerId,
            parentCustomTriggerId,
            childrenCustomTriggerToRemoveIds,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/moveAndRemoveCustomTrigger`), data);
    }

    /**
     * Upload files on upload files form submit
     */
    @bindThis
    public uploadFilesFromForm(
        initiativeId: string,
        customTriggerId: string,
        workerRunId: string,
        workflowVersionType: WorkflowVersionType,
        projectId: string,
        groupId: string,
        formId: string,
        files: TonkeanUploadedFile[],
    ) {
        const formData = new FormData();
        files.forEach((file) => {
            file.blob && formData.append('files', file.blob, file.name);
        });

        formData.append('customTriggerId', customTriggerId);
        formData.append('workerRunId', workerRunId);
        formData.append('workflowVersionType', workflowVersionType);
        formData.append('groupId', groupId);
        formData.append('projectId', projectId);
        formData.append('formId', formId);

        return this.$http.post<any>(this.buildUrl(`${getInitiativeIdPath(initiativeId)}/uploadFiles`), formData, {
            headers: { 'Content-Type': undefined },
        });
    }

    /**
     * Upload files on upload files form submit
     */
    @bindThis
    public uploadFilesFromWidget(
        initiativeId: string,
        customTriggerId: string,
        workerRunId: string,
        workflowVersionType: WorkflowVersionType,
        projectId: string,
        groupId: string,
        widgetId: string,
        files: TonkeanUploadedFile[],
        createdByCustomTriggerId?: string,
        intakeGroupId?: string,
    ) {
        const formData = new FormData();
        files.forEach((file) => {
            file.blob && formData.append('files', file.blob, file.name);
        });

        formData.append('customTriggerId', customTriggerId);
        formData.append('workerRunId', workerRunId);
        formData.append('workflowVersionType', workflowVersionType);
        formData.append('groupId', groupId);
        formData.append('projectId', projectId);
        formData.append('widgetId', widgetId);
        if (intakeGroupId) {
            formData.append('intakeGroupId', intakeGroupId);
        }

        if (!!createdByCustomTriggerId) {
            formData.append('createdByCustomTriggerId', createdByCustomTriggerId);
        }

        return this.$http.post<{
            entities: Initiative[];
        }>(this.buildUrl(`${getInitiativeIdPath(initiativeId)}/uploadFiles`), formData, {
            headers: { 'Content-Type': undefined },
        });
    }

    /**
     * Gets anonymous custom triggers by token.
     */
    @bindThis
    public getCustomTriggersByGuestToken(token, onlyAutonomous) {
        return this.$http.get<any>(this.buildUrl(`shared/access/${token}/triggers?onlyAutonomous=${!!onlyAutonomous}`));
    }

    /**
     * Gets a custom trigger.
     */
    @bindThis
    public getCustomTrigger(workflowVersionId: string, customTriggerId: string) {
        return this.$http.get<any>(
            this.buildUrl(`${getWorkflowVersionIdPath(workflowVersionId)}/${getCustomTriggerIdPath(customTriggerId)}`),
        );
    }

    /**
     * Gets a intake custom trigger by workflow version type.
     */
    @bindThis
    public getIntakeCustomTriggerByWorkflowVersionType(
        customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        workflowVersionType: WorkflowVersionType,
    ) {
        return this.$http.get<IntakeCustomTriggerDetails>(this.buildUrl(`${customTriggerId}/${workflowVersionType}`));
    }

    /**
     * Gets a intake custom trigger interfaces by workflow version type.
     */
    @bindThis
    public getIntakeCustomTriggerInterfacesByInitiativeID(
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        workflowVersionType: WorkflowVersionType,
    ) {
        return this.$http.get<{ entities: TaggableCustomTriggerInterface[] }>(
            this.buildUrl(`${initiativeId}/${workflowVersionType}/intakeItemInterfaces`),
        );
    }

    /**
     * Gets custom triggers by ids.
     */
    @bindThis
    public getCustomTriggersByCustomTriggerIds(
        workflowVersionId: string,
        customTriggerIds: TonkeanId<TonkeanType.CUSTOM_TRIGGER>[],
    ) {
        return this.$http.get<{ customTriggers: CustomTrigger[] }>(
            this.buildUrl(`${workflowVersionId}/customTriggerByIds`),
            {
                params: { customTriggerIds },
            },
        );
    }

    /**
     * Gets a worker run.
     */
    @bindThis
    public getWorkerRunById(
        projectId: string,
        workerRunId: string,
        workerRunStartTime: number,
        excludePlaceholderWorkerRunLogic: boolean,
    ): Promise<{ entities: WorkerRun[] }> {
        const data = { excludePlaceholderWorkerRunLogic, workerRunStartTime };

        return this.$http.post<any>(this.buildUrl(`${projectId}/${workerRunId}`), data);
    }

    /**
     * Gets a worker run chain of events.
     */
    @bindThis
    public getChainOfEvents(
        projectId: string,
        groupId: string,
        workerRunId: string,
        workerRunStartTime: number,
        from: number,
        to: number | undefined,
    ): Promise<{ entities: WorkerRun[] }> {
        const data = { workerRunStartTime, from, to };

        return this.$http.post<{ entities: WorkerRun[] }>(
            this.buildUrl(`${projectId}/${groupId}/${workerRunId}/chainOfEvents`),
            data,
        );
    }

    /**
     * Gets worker runs child by parents
     */
    @bindThis
    public getWorkFlowRunsByParentId(
        projectId: string,
        groupId: string,
        workerRunId: string,
        customTriggerId: string,
        limit: number,
        from: number,
        to: number | undefined,
    ): Promise<{ entities: WorkerRun[] }> {
        const data = { from, to };

        return this.$http.post<{ entities: WorkerRun[] }>(
            this.buildUrl(`${projectId}/${groupId}/${workerRunId}/${customTriggerId}/childRuns?limit=${limit}`),
            data,
        );
    }

    /**
     * Duplicates given custom trigger.
     */
    @bindThis
    public duplicateCustomTrigger(customTriggerId: string) {
        const data = {};
        return this.$http.post<any>(this.buildUrl(`${customTriggerId}/duplicate`), data);
    }

    /**
     * Creates a custom trigger in workflow version.
     */
    @bindThis
    public createCustomTriggerInWorkflowVersion(
        groupId: string,
        displayName,
        description,
        queryDefinition,
        pingOwner,
        alert,
        channelId: string,
        channelName,
        customTriggerActions,
        fieldDefinitionIdsToAskWhenGather,
        customTriggerType,
        autonomous,
        parentCustomTriggerId: string,
        disabled,
        stateId: string,
        updateText,
        evaluatedUpdateText,
        runOneTimeOnly,
        customTriggerSecondaryType,
    ) {
        const data: Record<string, any> = {
            displayName,
            description,
            queryDefinition,
            pingOwner,
            alert,
            customTriggerActions: customTriggerActions || [],
            autonomous: this.utils.isNullOrUndefined(autonomous) ? false : autonomous,
            disabled,
            stateId,
            updateText,
            evaluatedUpdateText,
            runOneTimeOnly,
            customTriggerSecondaryType,
        };

        if (fieldDefinitionIdsToAskWhenGather && fieldDefinitionIdsToAskWhenGather.length) {
            data.fieldDefinitionIdsToAskWhenGather = fieldDefinitionIdsToAskWhenGather;
        }

        // parentCustomTriggerId parameter is used to handle logic of children/parent logics, and is not used for adding to the graph under parent.
        // To add to the graph under parent, we use the addToGraphUnderParentId parameter, and therefore separate the two.
        if (parentCustomTriggerId) {
            data.parentCustomTriggerId = parentCustomTriggerId;
        }

        if (parentCustomTriggerId) {
            data.addToGraphUnderParentId = parentCustomTriggerId;
        } else {
            // If no parent is given, we create the trigger under the root custom trigger, which is identified by CUTR8G.
            data.addToGraphUnderParentId = 'CUTR8G';
        }

        if (channelId && channelName) {
            data.notifications = {
                type: this.groupNotifications.public,
                channelId,
                channelName,
            };
        }

        if (customTriggerType) {
            data.customTriggerType = customTriggerType;
        }

        return this.$http.post<any>(this.buildUrl(`${groupId}/customTriggers`), data);
    }

    /**
     * Creates multiple custom triggers in a workflow version.
     */
    @bindThis
    public createMultipleCustomTriggersInWorkflowVersion(groupId: string, customTriggersToCreate) {
        const data = {
            customTriggers: customTriggersToCreate,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/createMultipleCustomTriggers`), data);
    }

    /**
     * Tests an integration.
     */
    @bindThis
    public executeIntegrationCommand(
        projectId: string,
        integrationId: string,
        commandType: string,
        commandData: Record<string, any>,
        projectIntegrationId?: string,
    ) {
        const data = {
            commandRequest: {
                commandType,
                commandData,
            },
            projectIntegrationId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/${integrationId}/integrationCommand`), data);
    }

    /**
     * Updates a custom trigger.
     */
    @bindThis
    public updateCustomTrigger(
        customTriggerId: string,
        disabled,
        displayName,
        description,
        queryDefinition,
        pingOwner,
        alert,
        channelId: string,
        channelName,
        customTriggerActions,
        fieldDefinitionIdsToAskWhenGather,
        customTriggerType,
        isScheduled,
        recurrencePeriodType,
        recurrenceDaysInWeek,
        recurrenceDaysInMonth,
        recurrenceHour,
        everyXMinutes,
        everyXHours,
        workerItemContextType,
        recurrenceMinute,
        monitorInnerItems,
        monitorFieldDefinitionIds,
        doNotRunOnWeekends,
        recurrenceMonthsInYear,
        timezone,
        runOneTimeOnly,
        linkedFieldDefinitionsMap,
        monitorFormIds,
        customTriggerSecondaryType,
        monitorInnerItemsCreatedByCustomTriggerId,
        skipWhenConditionNotMet,
        dontRunOnDone: boolean,
        isReset: boolean,
        runAlsoOnNewItems: boolean,
        runAlsoOnIntakeItems: boolean,
    ) {
        const data = this.createDataForUpdateCustomTrigger(
            disabled,
            displayName,
            description,
            queryDefinition,
            pingOwner,
            alert,
            channelId,
            channelName,
            customTriggerActions,
            fieldDefinitionIdsToAskWhenGather,
            customTriggerType,
            isScheduled,
            recurrencePeriodType,
            recurrenceDaysInWeek,
            recurrenceDaysInMonth,
            recurrenceHour,
            everyXMinutes,
            everyXHours,
            workerItemContextType,
            recurrenceMinute,
            monitorInnerItems,
            monitorFieldDefinitionIds,
            null,
            null,
            null,
            null,
            doNotRunOnWeekends,
            recurrenceMonthsInYear,
            timezone,
            runOneTimeOnly,
            linkedFieldDefinitionsMap,
            monitorFormIds,
            customTriggerSecondaryType,
            monitorInnerItemsCreatedByCustomTriggerId,
            skipWhenConditionNotMet,
            dontRunOnDone,
            isReset,
            runAlsoOnNewItems,
            runAlsoOnIntakeItems,
        );

        return this.$http.post<any>(this.buildUrl(customTriggerId), data);
    }

    /**
     * Gets the Base API URL for a specific project integration.
     */
    @bindThis
    public getIntegrationApiBaseUrl(projectIntegrationId: string) {
        return this.$http.get<{ apiBaseUrl: string; incomingWebhookUrl: string }>(
            this.buildUrl(`${projectIntegrationId}/apiBaseUrl`),
        );
    }

    /**
     * Gets the project integration actions.
     */
    @bindThis
    public getProjectIntegrationActions(projectIntegrationId: string, actionTypes?: ActionType[], entityId?: string) {
        const params = { customActionTypes: actionTypes || [], entityId };

        return this.$http.get<{ entities: ProjectIntegrationActionWithIsImported[] }>(
            this.buildUrl(`${projectIntegrationId}/actions`),
            { params },
        );
    }

    /**
     * Get URL for embed widget by calling integration action.
     */
    @bindThis
    public getEmbedWidgetUrlFromAction(
        itemInterfaceWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
    ) {
        return this.$http.post<{ embedUrl: string }>(
            this.buildUrl(`${initiativeId}/${itemInterfaceWidgetId}/urlFromAction`),
            {},
        );
    }

    /**
     * Create Private Image PreSigned URL
     */
    @bindThis
    public createImageStreamUrl(
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        projectId: TonkeanId<TonkeanType.PROJECT>,
        imagePrivateUrl: string,
        itemId: string,
        imageStreamSelectedActionId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>,
    ) {
        const body = {
            itemId,
            projectId,
            imagePrivateUrl,
            imageStreamSelectedActionId,
        };

        return this.$http.post<{ streamImageUrl: string }>(
            this.buildUrl(`${projectIntegrationId}/streamImageUrl`),
            body,
        );
    }

    @bindThis
    public getImageStream(preSignedUrl: string) {
        const options = {
            responseType: 'arraybuffer',
        };

        return this.$http.get<IHttpResponse<ArrayBuffer>>(`${preSignedUrl}`, options);
    }

    /**
     * Update a project integration action.
     */
    @bindThis
    public updateProjectIntegrationAction(
        projectIntegrationActionId: string,
        displayName?: string,
        description?: string,
        actionDefinition?: CustomActionDefinition,
        parametersDefinition?: ProjectIntegrationActionParametersDefinition,
        responseHandlingDefinition?: ProjectIntegrationActionResponseHandlingDefinition,
    ) {
        const body = {
            displayName,
            description,
            actionDefinition,
            parametersDefinition,
            responseHandlingDefinition,
        };

        return this.$http.post<ProjectIntegrationAction>(
            this.buildUrl(`${projectIntegrationActionId}/action/update`),
            body,
        );
    }

    /**
     * validate the project integration action entity value.
     */
    @asyncApiJob
    @bindThis
    public validateActionHandleResponse(
        projectIntegrationId: string,
        testRunId: string,
        handleResponse: ProjectIntegrationEntityResponseHandlingDefinitionBase<EntityResponseHandlingDefinitionType>,
    ): Promise<ResponseHandlingValidationStatus> {
        const body = {
            baseExternalActivityHandleResponseDefinition: handleResponse,
        };

        return this.$http.post<ResponseHandlingValidationStatus>(
            this.buildUrl(`${projectIntegrationId}/${testRunId}/validate/handle-response`),
            body,
        );
    }

    /**
     * validate the webhook payload handle response entity value.
     */
    @asyncApiJob
    @bindThis
    public validateWebhookPayloadHandleResponse(
        projectIntegrationId: string,
        webhookPayloadId: string,
        handleResponse: ProjectIntegrationEntityResponseHandlingDefinitionBase<EntityResponseHandlingDefinitionType>,
    ): Promise<ResponseHandlingValidationStatus> {
        const body = {
            webhookPayloadId,
            externalActivityHandleResponseDefinition: handleResponse,
        };

        return this.$http.post<ResponseHandlingValidationStatus>(
            this.buildUrl(`${projectIntegrationId}/payload/validate/handle-response`),
            body,
        );
    }

    /**
     * Update a project integration action.
     */
    @bindThis
    public updateProjectIntegrationActionDefinition(
        projectIntegrationActionId: string,
        responseHandlingDefinition: ProjectIntegrationEntityResponseHandlingDefinition,
        actionDefinition?: CustomActionDefinition,
    ) {
        const body = {
            actionDefinition,
            responseHandlingDefinition,
        };

        return this.$http.post<ProjectIntegrationAction>(
            this.buildUrl(`${projectIntegrationActionId}/action/update`),
            body,
        );
    }

    /**
     * Update a project integration entity webhook definition.
     */
    @bindThis
    public updateProjectIntegrationEntityWebhookDefinition(
        projectInIntegrationId: string,
        projectIntegrationEntityId: string,
        queryDefinition: BasicQueryDefinition,
        responseHandlingDefinition: ProjectIntegrationEntityResponseHandlingDefinition,
        shouldAcceptAll: boolean,
    ) {
        const projectIntegrationEntityWebhookDefinition = {
            shouldAcceptAll,
            queryDefinition,
            externalActivityResponseHandlingDefinition: responseHandlingDefinition,
        };

        return this.$http.post<ProjectIntegrationEntity>(
            this.buildUrl(`${projectInIntegrationId}/${projectIntegrationEntityId}/update`),
            { projectIntegrationEntityWebhookDefinition },
        );
    }

    /**
     * Update a project integration entity fetching definition.
     */
    @bindThis
    public updateProjectIntegrationEntityFetcherDefinition(
        projectInIntegrationId: string,
        projectIntegrationEntityId: string,
        fetcherActionId: string,
    ) {
        const projectIntegrationEntityFetcherDefinition = {
            fetcherActionId,
        };

        return this.$http.post<ProjectIntegrationEntity>(
            this.buildUrl(`${projectInIntegrationId}/${projectIntegrationEntityId}/update`),
            { projectIntegrationEntityFetcherDefinition },
        );
    }

    /**
     * get payloads of project integration.
     */
    @bindThis
    public getProjectIntegrationWebhookPayloads(projectIntegrationId: string) {
        return this.$http.get<{ payloads: WebhookPayload[] }>(
            this.buildUrl(`${projectIntegrationId}/webhook/payloads`),
        );
    }

    /**
     * Get Payloads fields metadata of project integration
     */
    @bindThis
    public getWebhookPayloadsFieldsMetadataOfProjectIntegration(
        projectIntegrationId: string,
        skip: number,
        limit: number,
    ) {
        return this.$http.get<{ fields: WebhookPayloadMetadataField[] }>(
            this.buildUrl(`${projectIntegrationId}/webhook/payloads/fields?skip=${skip}&limit=${limit}`),
        );
    }

    /**
     * get a project integration action by id.
     */
    @bindThis
    public getProjectIntegrationActionById(projectIntegrationId: string, projectIntegrationActionId: string) {
        return this.$http.get<ProjectIntegrationAction>(
            this.buildUrl(`${projectIntegrationId}/${projectIntegrationActionId}`),
        );
    }

    /**
     * Delete a project integration action.
     */
    @bindThis
    public deleteProjectIntegrationAction(projectIntegrationActionId: string) {
        return this.$http.delete<void>(this.buildUrl(`${projectIntegrationActionId}`));
    }

    /**
     * Delete a project integration entity.
     */
    @bindThis
    public deleteProjectIntegrationEntity(projectIntegrationId: string, projectIntegrationEntityId: string) {
        return this.$http.delete<void>(this.buildUrl(`${projectIntegrationId}/${projectIntegrationEntityId}`));
    }

    @bindThis
    public getProjectIntegrationEntityDisplayNamesDependencies(
        projectIntegrationId: string,
        projectIntegrationEntityId: string,
    ) {
        return this.$http.get<{ entitiesDependentDisplayNames: string[] }>(
            this.buildUrl(`${projectIntegrationId}/${projectIntegrationEntityId}/subEntitiesDependenciesDisplayNames`),
        );
    }

    @bindThis
    public getProjectIntegrationEntityDependencies(projectIntegrationId: string, projectIntegrationEntityId: string) {
        return this.$http.get<{ entities: ProjectIntegrationEntity[] }>(
            this.buildUrl(`${projectIntegrationId}/${projectIntegrationEntityId}/subEntitiesDependencies`),
        );
    }

    @bindThis
    public getProjectIntegrationFetcherDependencies(projectIntegrationId: string, projectIntegrationFetcherId: string) {
        return this.$http.get<{ entitiesDependentDisplayNames: string[] }>(
            this.buildUrl(`${projectIntegrationId}/${projectIntegrationFetcherId}/entitiesDependencies`),
        );
    }

    /**
     * Gets the auto complete options for a given field
     * @param integrationId The integration / project integration id
     * @param fieldName The name of the field to be completed
     * @param params Query params
     * @return {*}
     */
    @bindThis
    public getAutoCompleteOptions(projectId: string, integrationId: string, fieldName: string, params: any) {
        return this.$http.get(this.buildUrl(`${projectId}/${integrationId}/autoComplete/${fieldName}`), { params });
    }

    /**
     * Create new project integration actions.
     */
    @bindThis
    public createProjectIntegrationAction(
        projectIntegrationId: string,
        displayName: string,
        description: string,
        actionDefinition: CustomActionDefinition,
        parametersDefinition: ProjectIntegrationActionParametersDefinition,
        actionType: ActionType,
        responseHandlingDefinition?: ProjectIntegrationActionResponseHandlingDefinition,
        dependentProjectIntegrationEntityIds?: string[],
    ) {
        const body = {
            displayName,
            description,
            actionDefinition,
            parametersDefinition,
            actionType,
            responseHandlingDefinition,
            dependentProjectIntegrationEntityIds,
        };
        return this.$http.post<ProjectIntegrationAction>(this.buildUrl(`${projectIntegrationId}/action/create`), body);
    }

    /**
     * Executes an evaluation on the training set.
     */
    @bindThis
    public executeEvaluationOnTrainingSet(trainingSetId: string) {
        const body = {};
        return this.$http.post<any>(this.buildUrl(`${trainingSetId}/executeEvaluation`), body);
    }

    /**
     * Executes an evaluation on the training set item.
     */
    @bindThis
    public executeEvaluationOnTrainingSetItem(projectId: string, trainingSetItemId: string) {
        const body = {};
        return this.$http.post<any>(this.buildUrl(`${projectId}/${trainingSetItemId}/executeEvaluation`), body);
    }

    /**
     * Get training set fields by training set id.
     */
    @bindThis
    public getTrainingSetFields(trainingSetId: string) {
        return this.$http.get<{ trainingSetFields: TrainingSetField[] }>(this.buildUrl(`${trainingSetId}/fields`));
    }

    /**
     * Get training set items by training set id.
     */
    @bindThis
    public getTrainingSetItemsIdentifiers(
        trainingSetId: string,
        size: number,
        searchedTitle: string = '',
        skip: number,
    ) {
        const params = {
            size,
            skip,
            searchedTitle,
        };

        return this.$http.get<{ entities: TrainingSetItemIdentifiers[] }>(this.buildUrl(`${trainingSetId}/items`), {
            params,
        });
    }

    /**
     * Get training set items by training set item id.
     */
    @bindThis
    public getEnrichedTrainingSetItemById(trainingSetItemId: string, projectId: string, trainingSetId: string) {
        const params = {
            trainingSetId,
        };

        return this.$http.get<EnrichedTrainingSetItemResponse>(this.buildUrl(`${projectId}/${trainingSetItemId}`), {
            params,
        });
    }

    @bindThis
    public updateTrainingSetItem(
        trainingSetItemId: string,
        projectId: string,
        expectedValues: TrainingSetItemExpectedValue[],
        businessLabelsDisplayNames: string[],
        documentCollectionId?: string,
    ) {
        const body = {
            projectId,
            expectedValues,
            businessLabelsDisplayNames,
            documentCollectionId,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/${trainingSetItemId}`), body);
    }

    @bindThis
    public uploadTrainingSetItemDisplayName(
        trainingSetItemId: string,
        projectId: string,
        trainingSetItemDisplayName: string,
    ) {
        const body = {
            trainingSetItemDisplayName,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/${trainingSetItemId}/displayName`), body);
    }

    @bindThis
    public getEnterpriseComponentVariablesValues(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        enterpriseComponentId: EnterpriseComponentId,
        enterpriseComponentVariableIds: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>[],
    ) {
        return this.$http.get<{
            enterpriseComponentIdToVariableValue: Record<TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>, string>;
        }>(this.buildUrl(`${projectId}/${enterpriseComponentId}/variables/values`), {
            params: { enterpriseComponentVariableIds },
        });
    }

    @bindThis
    public updateEnterpriseComponentAuthorization(
        enterpriseComponentId: EnterpriseComponentId,
        authorization: EnterpriseComponentAuthorization,
    ): Promise<EnterpriseComponentAuthorization> {
        return this.$http.post<EnterpriseComponentAuthorization>(
            this.buildUrl(`${enterpriseComponentId}/authorization`),
            {
                authorizationDetails: authorization,
            },
        );
    }

    @bindThis
    public updateEnterpriseComponentVariablesValues(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        enterpriseComponentId: EnterpriseComponentId,
        enterpriseComponentVariableIdToValue: Record<TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>, string>,
    ) {
        return this.$http.post(this.buildUrl(`${projectId}/${enterpriseComponentId}/variables/values`), {
            enterpriseComponentVariableToValue: enterpriseComponentVariableIdToValue,
        });
    }

    @bindThis
    public updateDocumentCollectionDisplayName(
        projectId: string,
        documentCollectionId: string,
        documentCollectionDisplayName: string,
    ) {
        const body = {
            documentCollectionDisplayName,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/${documentCollectionId}/displayName`), body);
    }

    /**
     * Calculate ocr output.
     */
    @bindThis
    public calculateOcrOutput(projectId: string, trainingSetItemId: string, ocrOutputType: OcrOutputType) {
        const data = {
            outputType: ocrOutputType,
        };

        return this.$http.post<CalculateOcrOutputResponse>(
            this.buildUrl(`${projectId}/${trainingSetItemId}/calculateOcrOutput`),
            data,
        );
    }

    @bindThis
    public getTrainingSetItemPlainTextOutput(projectId: string, trainingSetItemId: string) {
        return this.$http.post<{
            output?: string;
            uploadingErrorMessage?: string;
        }>(this.buildUrl(`${projectId}/${trainingSetItemId}/text`), {});
    }

    /**
     * Gets Training sets gallery
     */
    @bindThis
    public getTrainingSetsGallery(projectId: string) {
        return this.$http.get<TrainingSetsGallery>(this.buildUrl(`${projectId}/trainingSetsGallery`));
    }

    /**
     * Get Training set model by id
     */
    @bindThis
    public getTrainingSetModel(modelId: string) {
        return this.$http.get<{ model: TrainingSetModel; modelFields: TrainingSetField[] }>(
            this.buildUrl(`${modelId}`),
        );
    }

    /**
     * Searches for training sets and returns their identifiers.
     */
    @bindThis
    public searchTrainingSetsIdentifiers(
        projectId: string,
        searchText: string | undefined,
        workflowFolderId: string | undefined,
        trainingSetType: TrainingSetType | undefined,
        skip: number | undefined,
        limit: number | undefined,
    ) {
        return this.$http.get<{ entities: TrainingSetIdentifiersSummary[] }>(
            this.buildUrl(`${projectId}/trainingSetsIdentifiers`),
            {
                params: {
                    workflowFolderId: workflowFolderId || undefined,
                    trainingSetType,
                    q: searchText || undefined,
                    skip: skip || undefined,
                    limit: limit || undefined,
                },
            },
        );
    }

    /**
     * Gets a training set identifiers summary by a given id.
     */
    @bindThis
    public getTrainingSetIdentifiersSummaryById(trainingSetId: string) {
        return this.$http.get<TrainingSetIdentifiersSummary>(this.buildUrl(`${trainingSetId}/identifierSummary`));
    }

    /**
     * Creates a training set
     */
    @bindThis
    public createTrainingSet(
        projectId: string,
        displayName: string,
        trainingSetType: TrainingSetType,
        description?: string,
    ) {
        const data = {
            displayName,
            description,
            type: trainingSetType,
        };
        return this.$http.post<{
            trainingSetId: string;
        }>(this.buildUrl(`${projectId}/trainingSet`), data);
    }

    /**
     * Creates a training set model
     */
    @bindThis
    public createTrainingSetModel(
        trainingSetId: string,
        trainingSetFieldId: string,
        displayName: string,
        methodType: TrainingSetModelMethodType,
        configuration: TrainingSetModelConfiguration,
        testItemsIds: string[],
        description: string = '',
        documentCollectionIds?: string[],
    ) {
        const data = {
            trainingSetFieldId,
            displayName,
            methodType,
            configuration,
            testItemsIds,
            description,
            documentCollectionIds,
        };
        return this.$http.post<{
            trainingSetModelId: string;
        }>(this.buildUrl(`${trainingSetId}/models`), data);
    }

    /**
     * Get training set model summaries by ids
     */
    @bindThis
    public getTrainingSetModelSummariesByIds(projectId: string, modelIds: string[]) {
        const params = {
            modelIds,
        };
        return this.$http.get<{ modelSummaries: TrainingSetModelSummary[] }>(this.buildUrl(`${projectId}/models`), {
            params,
        });
    }

    /**
     * Create new training set field.
     */
    @bindThis
    public createTrainingSetField(
        trainingSetId: string,
        fieldName: string,
        fieldType: FieldType,
        trainingSetFieldType: TrainingSetFieldType,
        matchHandlingType?: MatchHandlingType,
        maximalFieldLength?: number,
    ) {
        const body = {
            fieldName,
            fieldType,
            matchHandlingType,
            trainingSetFieldType,
            maximalFieldLength,
        };
        return this.$http.post<{ trainingSetFieldId: string }>(this.buildUrl(`${trainingSetId}/fields`), body);
    }

    /**
     * Update existing training set field.
     */
    @bindThis
    public updateTrainingSetField(
        trainingSetFieldId: string,
        fieldName: string,
        fieldType: FieldType | undefined,
        isActive: boolean,
        matchHandlingType?: MatchHandlingType,
        maximalFieldLength?: number,
    ) {
        const body = {
            fieldName,
            fieldType,
            matchHandlingType,
            isActive,
            maximalFieldLength,
        };
        return this.$http.post<void>(this.buildUrl(trainingSetFieldId), body);
    }

    /**
     * Updates a custom trigger.
     */
    @bindThis
    public multiUpdateCustomTrigger(groupId: string, updates) {
        const preparedUpdates = updates.map((update) => {
            const triggerData = this.createDataForUpdateCustomTrigger(
                update.disabled,
                update.displayName,
                update.description,
                update.queryDefinition,
                update.pingOwner,
                update.alert,
                update.channelId,
                update.channelName,
                update.customTriggerActions,
                update.fieldDefinitionIdsToAskWhenGather,
                update.customTriggerType,
                update.isScheduled,
                update.recurrencePeriodType,
                update.recurrenceDaysInWeek,
                update.recurrenceDaysInMonth,
                update.recurrenceHour,
                update.everyXMinutes,
                update.everyXHours,
                update.workerItemContextType,
                update.recurrenceMinute,
                update.monitorInnerItems,
                update.monitorFieldDefinitionIds,
                update.linkedFieldDefinitionId,
                update.stateId,
                update.updateText,
                update.evaluatedUpdateText,
                update.doNotRunOnWeekends,
                update.recurrenceMonthsInYear,
                update.timezone,
                update.runOneTimeOnly,
                null,
                update.monitorFormIds,
                update.customTriggerSecondaryType,
                update.monitorInnerItemsCreatedByCustomTriggerId,
                update.skipWhenConditionNotMet,
                update.dontRunOnDone,
                false,
                update.runAlsoOnNewItems,
                update.runAlsoOnIntakeItems,
            );

            triggerData.customTriggerId = update.customTriggerId;

            return triggerData;
        });

        const data = {
            updates: preparedUpdates,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/multiUpdateTriggers`), data);
    }

    /**
     * Get training set models summary.
     */
    @bindThis
    public getSummaryTrainingSetModels(
        trainingSetFieldId: string,
        limit: number,
        skip: number,
        searchTerm: string | undefined,
        documentCollectionId: string | undefined,
        filterByDocumentCollection: boolean,
    ) {
        const params = {
            limit,
            skip,
            searchTerm,
            documentCollectionId,
            filterByDocumentCollection,
        };

        return this.$http.get<EnrichedTrainingSetModelSummary[]>(this.buildUrl(`${trainingSetFieldId}/models`), {
            params,
        });
    }

    /**
     * Get training set models from each field.
     */
    @bindThis
    public getSummaryTrainingSetModelsForAllFields(
        trainingSetId: string,
        limit: number,
        searchTerm: string | undefined,
    ) {
        const params = {
            limit,
            searchTerm,
        };

        return this.$http.get<TrainingSetModelsOfAllFields>(this.buildUrl(`${trainingSetId}/models`), {
            params,
        });
    }

    /**
     * Get training set models from each field, grouped by collections.
     */
    @bindThis
    public getModelSummariesByCollectionsAndFields(
        projectId: string,
        trainingSetId: string,
        limit: number,
        skip: number,
        searchTerm: string | undefined,
    ) {
        const params = {
            limit,
            skip,
            searchTerm,
        };

        return this.$http.get<TrainingSetModelsOfAllFieldsByCollections>(
            this.buildUrl(`${projectId}/${trainingSetId}/modelsGroupedByCollections`),
            {
                params,
            },
        );
    }

    /**
     * Get training set model identifiers by training set id.
     */
    @bindThis
    public getTrainingSetModelIdentifiers(trainingSetId: string, limit: number, skip: number, searchTerm?: string) {
        const params = {
            limit,
            skip,
            searchTerm,
        };

        return this.$http.get<{ entities: TrainingSetModelIdentifiers[] }>(
            this.buildUrl(`${trainingSetId}/modelIdentifiers`),
            {
                params,
            },
        );
    }

    /**
     * Get training set fields by training set id.
     */
    @bindThis
    public updateTrainingSetModel(
        trainingSetModelId: string,
        isActive?: boolean,
        displayName?: string,
        modelMethodType?: TrainingSetModelMethodType,
        trainingSetModelConfiguration?: TrainingSetModelConfiguration,
        trainingSetFieldId?: string,
        testItemsIds?: string[],
        description?: string,
        documentCollectionIds?: string[],
    ) {
        const data = {
            displayName,
            trainingSetFieldId,
            modelMethodType,
            trainingSetModelConfiguration,
            isActive,
            testItemsIds,
            description,
            documentCollectionIds,
        };

        return this.$http.post<void>(this.buildUrl(trainingSetModelId), data);
    }

    /**
     * Updates a custom trigger.
     */
    @bindThis
    public updateCustomTriggerDisableMode(customTriggerId: string, disabled) {
        const data = {
            disabled,
        };

        return this.$http.post<any>(this.buildUrl(`${customTriggerId}/disabled`), data);
    }

    /**
     * Updates default actor for a project.
     */
    @bindThis
    public updateProjectDefaultActor(projectId: string, defaultActorId: string) {
        const data = {
            defaultActorId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/defaultActor`), data);
    }

    /**
     * Get is support user disabled for project
     */
    @bindThis
    public isSupportUserDisabled(projectId: string) {
        return this.$http.get<supportUserDisabledResponse>(this.buildUrl(`${projectId}/SupportUserAccess`));
    }

    /**
     * Update support user board access
     */
    @asyncApiJob
    @bindThis
    public changeSupportUserDisabled(projectId: string, shouldDisable: boolean) {
        const data = {
            shouldDisableSupportAccess: shouldDisable,
        };

        return this.$http.post<supportUserDisabledResponse>(this.buildUrl(`${projectId}/SupportUserAccess`), data);
    }

    /**
     * Get is support user in group
     */
    @bindThis
    public isSupportUserInGroup(groupId: string) {
        return this.$http.get<SupportUserInGroupResponse>(this.buildUrl(`${groupId}/SupportUserAccess`));
    }

    /**
     * Updates a custom trigger.
     */
    @bindThis
    public updateCustomTriggerIsHidden(customTriggerId: string, isHidden) {
        const data = {
            hidden: isHidden,
        };

        return this.$http.post<any>(this.buildUrl(`${customTriggerId}/hidden`), data);
    }

    /**
     * Updates a custom trigger custom notification settings.
     */
    @bindThis
    public updateCustomTriggerCustomNotificationSettings(customTriggerId: string, customNotificationSettings) {
        const data = {
            customNotificationSettings,
        };

        return this.$http.post<any>(this.buildUrl(`${customTriggerId}/customNotificationSettings`), data);
    }

    /**
     * Updates a custom trigger.
     */
    @bindThis
    public updateCustomTriggerDisplayName(customTriggerId: string, displayName: string) {
        const data = {
            displayName,
        };

        return this.$http.post<any>(this.buildUrl(`${customTriggerId}/displayName`), data);
    }

    /**
     * Updates a custom trigger state id.
     */
    @bindThis
    public updateCustomTriggerStateId(
        customTriggerId: string,
        stateId: string,
        updateText,
        evaluatedUpdateText,
        externalStatusValue,
        stateUpdaterIsOwner,
        stateUpdaterIsPreviousActor,
        stateUpdaterPersonId: string,
        stateUpdaterExpressionDefinition,
        skipStatusNotification,
        stateUpdaterIsTonkean,
        stateUpdaterInnerItemsStateId: string,
        stateUpdaterFieldsToUpdate,
    ) {
        const data = {
            stateId,
            updateText,
            evaluatedUpdateText,
            externalStatusValue,
            stateUpdaterIsOwner,
            stateUpdaterIsPreviousActor,
            stateUpdaterPersonId,
            stateUpdaterExpressionDefinition,
            stateUpdateSkipNotification: skipStatusNotification,
            stateUpdaterIsTonkean,
            stateUpdaterInnerItemsStateId,
            stateUpdaterFieldsToUpdate,
        };

        return this.$http.post<any>(`${this.buildUrl(customTriggerId)}/stateId`, data);
    }

    /**
     * Deletes a custom trigger.
     */
    @bindThis
    public deleteCustomTrigger(customTriggerId: string) {
        return this.$http.delete<{ parentCustomTrigger?: unknown; deletedCustomTriggerIds: string[] }>(
            this.buildUrl(customTriggerId),
        );
    }

    /**
     * Updates the gather updates flag for a group.
     */
    @bindThis
    public toggleWorkflowVersionGatherUpdates(groupId: string, shouldSendGatherUpdates) {
        const data = {
            shouldSendGatherUpdates,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/toggleGatherUpdates`), data);
    }

    /**
     * Run query on initiative
     */
    @bindThis
    public runQueryOnInitiative(initiativeId: string, queryDefinition) {
        const data = {
            queryDefinition,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/runQuery`), data);
    }

    /**
     * Updates the preferences of the activity digest email.
     */
    @bindThis
    public updateActivityDigestEmailPreferences(
        projectId: string,
        personId: string,
        shouldSendActivityDigestEmail,
        recurrencePeriodType,
    ) {
        const data: Record<string, any> = {
            shouldSendActivityDigestEmail,
        };

        if (recurrencePeriodType) {
            data.recurrencePeriodType = recurrencePeriodType;
        }

        return this.$http.post<any>(this.buildUrl(`${projectId}/${personId}/updateActivityDigestEmailSettings`), data);
    }

    /**
     * Gets the unsubscribed groups of a person in a project.
     */
    @bindThis
    public getUnsubscribedGroups(projectId: string, personId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/${personId}/unsubscribedGroups`));
    }

    /**
     * Gets the subscribed groups of a person in a project for activity digest email notifications.
     */
    @bindThis
    public getActivityDigestSubscribedGroups(projectId: string, personId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/${personId}/activityDigestSubscribedGroups`));
    }

    /**
     * Sends a preview of scheduled report.
     */
    @bindThis
    public sendPreviewScheduledReport(
        projectId: string,
        name,
        fieldsMap,
        conditions,
        reportType,
        includeFirstLevelOfInnerTracks,
        orderBySettings,
    ) {
        const data = {
            name,
            fieldsMap,
            conditions,
            reportType,
            includeFirstLevelOfInnerTracks,
            orderBySettings,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/scheduledReports/preview`), data);
    }

    /**
     * Create scheduled report.
     */
    @bindThis
    public createScheduledReport(
        type,
        projectId: string,
        name,
        fieldsMap,
        conditions,
        recurrencePeriodType,
        recurrenceDaysInWeek,
        recurrenceDaysInMonth,
        recurrenceMonthsInYear,
        recurrenceHour,
        subscribers,
        includeFirstLevelOfInnerTracks,
        recurrenceMinute,
        orderBySettings,
    ) {
        const data: Record<string, any> = {
            scheduledReportType: type,
            name,
            fieldsMap,
            conditions,
            recurrencePeriodType,
            subscribers,
            includeFirstLevelOfInnerTracks,
            orderBySettings,
        };

        // recurrenceDays (either Weekly or Monthly frequency) cannot be 0, that's why we don't need to condition over null and undefined specifically.
        if (recurrenceDaysInWeek) {
            data.recurrenceDaysInWeek = recurrenceDaysInWeek;
        }

        if (recurrenceDaysInMonth) {
            data.recurrenceDaysInMonth = recurrenceDaysInMonth;
        }

        // recurrenceHour can be 0, that's why we check for null and undefined specifically.
        if (recurrenceHour !== null && recurrenceHour !== undefined) {
            data.recurrenceHour = recurrenceHour;
        }

        if (recurrenceMinute) {
            data.recurrenceMinute = recurrenceMinute;
        }

        if (recurrenceMonthsInYear) {
            data.recurrenceMonthsInYear = recurrenceMonthsInYear;
        }

        return this.$http.post<any>(this.buildUrl(`${projectId}/scheduledReports`), data);
    }

    /**
     * Update scheduled report.
     */
    @bindThis
    public updateScheduledReport(
        type,
        scheduledReportId: string,
        name,
        fieldsMap,
        conditions,
        recurrencePeriodType,
        recurrenceDaysInWeek,
        recurrenceDaysInMonth,
        recurrenceMonthsInYear,
        recurrenceHour,
        subscribers,
        includeFirstLevelOfInnerTracks,
        recurrenceMinute,
        orderBySettings,
    ) {
        const data: Record<string, any> = {
            scheduledReportType: type,
            name,
            fieldsMap,
            conditions,
            recurrencePeriodType,
            overrideSubscribers: subscribers,
            includeFirstLevelOfInnerTracks,
            orderBySettings,
        };

        // recurrenceDays (either Weekly or Monthly frequency) cannot be 0, that's why we don't need to condition over null and undefined specifically.
        if (recurrenceDaysInWeek) {
            data.recurrenceDaysInWeek = recurrenceDaysInWeek;
        }

        if (recurrenceDaysInMonth) {
            data.recurrenceDaysInMonth = recurrenceDaysInMonth;
        }

        // recurrenceHour can be 0, that's why we check for null and undefined specifically.
        if (recurrenceHour !== null && recurrenceHour !== undefined) {
            data.recurrenceHour = recurrenceHour;
        }

        if (recurrenceMinute) {
            data.recurrenceMinute = recurrenceMinute;
        }

        if (recurrenceMonthsInYear) {
            data.recurrenceMonthsInYear = recurrenceMonthsInYear;
        }

        return this.$http.post<any>(this.buildUrl(scheduledReportId), data);
    }

    /**
     * Deletes an existing scheduled report.
     */
    @bindThis
    public deleteScheduledReport(scheduledReportId: string) {
        return this.$http.delete<any>(this.buildUrl(scheduledReportId));
    }

    /**
     * Subscribes from scheduled report.
     */
    @bindThis
    public subscribeScheduledReport(scheduledReportId: string) {
        return this.$http.post<any>(this.buildUrl(`${scheduledReportId}/subscribe`), undefined);
    }

    /**
     * Subscribes to group for activity digest email notification.
     */
    @bindThis
    public activityDigestSubscribeGroup(groupId: string) {
        return this.$http.post<any>(this.buildUrl(`${groupId}/activityDigestSubscribe`), undefined);
    }

    /**
     * Subscribes to group.
     */
    @bindThis
    public subscribeGroup(groupId: string) {
        return this.$http.post<any>(this.buildUrl(`${groupId}/subscribe`), undefined);
    }

    /**
     * Unsubscribes from scheduled report.
     */
    @bindThis
    public unsubscribeScheduledReport(scheduledReportId: string) {
        return this.$http.post<any>(this.buildUrl(`${scheduledReportId}/unsubscribe`), undefined);
    }

    /**
     * Unsubscribes from group.
     */
    @bindThis
    public unsubscribeFromGroup(groupId: string) {
        return this.$http.post<any>(this.buildUrl(`${groupId}/unsubscribe`), undefined);
    }

    /**
     * Unsubscribes from group for activity digest email notification.
     */
    @bindThis
    public activityDigestUnsubscribeFromGroup(groupId: string) {
        return this.$http.post<any>(this.buildUrl(`${groupId}/activityDigestUnsubscribe`), undefined);
    }

    /**
     * Returns scheduled reports for user in project.
     */
    @bindThis
    public getScheduledReports(projectId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/scheduledReports`));
    }

    /**
     * Gets user highlights report.
     */
    @bindThis
    public getHighlightsReport(projectId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/highlightsReport`));
    }

    /**
     * Gets previously read highlights.
     */
    @bindThis
    public getReadHighlights(projectId: string, createdBefore, size, groupId: string) {
        return this.$http.get<any>(
            this.buildUrl(`${projectId}/readHighlights?createdBefore=${createdBefore}&size=${size}&groupId=${groupId}`),
        );
    }

    /**
     * Mark highlight as read.
     */
    @bindThis
    public markHighlightRead(projectId: string, highlightId: string) {
        return this.$http.post<any>(this.buildUrl(`${projectId}/${highlightId}/read`), null);
    }

    /**
     * Gets the tags autocomplete options
     */
    @bindThis
    public getOrCreateFunctionByName(projectId: string, name) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/functions/${name}?create=true`));
    }

    /**
     * Gets the tags autocomplete options
     */
    @bindThis
    public searchTopics(projectId: string, term?, limit?) {
        let suffix = '';
        if (limit) {
            suffix = `&limit=${limit}`;
        }
        let q = '';
        if (term) {
            q = `q=${term}`;
        }
        return this.$http.get<any>(this.buildUrl(`${projectId}/tags?${q}${suffix}`));
    }

    /**
     * Gets the tags autocomplete options
     */
    @bindThis
    public searchTags(projectId: string, term, isFunction) {
        if (isFunction) {
            return this.searchFunctions(projectId, term);
        }
        return this.searchTopics(projectId, term);
    }

    @bindThis
    public searchDropDownOptionsFromMatchedDataSourceByInitiative(
        fieldDefinitionId: FieldDefinition['id'],
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        searchText: string | undefined,
    ) {
        return this.$http.get<{ results: string[] }>(
            this.buildUrl(
                `${getInitiativeIdPath(initiativeId)}/${getFieldDefinitionIdPath(fieldDefinitionId)}/dropdownSearch`,
            ),
            {
                params: { searchText },
            },
        );
    }

    @bindThis
    public searchDropDownOptionsFromMatchedDataSourceByVersionType(
        fieldDefinitionId: FieldDefinition['id'],
        workflowVersionType: WorkflowVersionType,
        searchText: string | undefined,
    ) {
        return this.$http.get<{ results: string[] }>(
            this.buildUrl(
                `${workflowVersionType.toUpperCase()}/${getFieldDefinitionIdPath(fieldDefinitionId)}/dropdownSearch`,
            ),
            {
                params: { searchText },
            },
        );
    }

    /**
     * Auto completion for track creation - auto completes both initiatives (and will be offered as initiative links),
     * and external activities.
     */
    @bindThis
    public trackCreationAutoComplete(
        projectId: string,
        groupId: string,
        term,
        limit: number,
        type,
        doNotFetchInitiatives,
    ) {
        const config: IRequestShortcutConfig = {
            params: {
                q: term,
                limit,
                doNotFetchInitiatives,
                type,
            },
        };

        return this.$http.get<any>(this.buildUrl(`${projectId}/${groupId}/trackCreateAutoComplete`), config);
    }

    /**
     * Returns external items of given source and externalType of project.
     * Amount of items returned is the limit.
     */
    @bindThis
    public getExternalItems(projectId: string, source, externalType, skip: number, limit: number) {
        return this.$http.get<any>(
            this.buildUrl(
                `${projectId}/externalActivities?source=${source}&externalType=${externalType}&skip=${skip}&limit=${limit}`,
            ),
        );
    }

    /**
     * Evaluate handle response test run expressions.
     */
    @bindThis
    public evaluateHandleResponseTestRunExpressions(
        projectIntegrationId: string,
        testRunId: string,
        expressionsList: TonkeanExpressionDefinition[],
    ): Promise<Record<string, string>> {
        return this.$http.post<Record<string, string>>(this.buildUrl(`${projectIntegrationId}/${testRunId}/evaluate`), {
            expressions: expressionsList,
        });
    }

    /**
     * Gets the tags autocomplete options
     */
    @bindThis
    public searchExternalItems(projectId: string, groupId: string, source, externalType, type, term, limit: number) {
        let suffix = '';

        if (limit) {
            suffix += `&limit=${limit}`;
        }

        if (source) {
            suffix += `&source=${source}`;
        }

        if (externalType) {
            if (angular.isArray(externalType)) {
                suffix += `&externalType=${externalType.join('&externalType=')}`;
            } else {
                suffix += `&externalType=${externalType}`;
            }
        }

        if (groupId) {
            suffix += `&groupId=${groupId}`;
        }

        if (type) {
            if (angular.isArray(type)) {
                suffix += `&type=${type.join('&type=')}`;
            } else {
                suffix += `&type=${type}`;
            }
        }

        return this.$http.get<any>(
            this.buildUrl(`${projectId}/externalActivity/autoComplete?searchText=${term}${suffix}`),
        );
    }

    /**
     * Searches entities for navigation purposes.
     */
    @bindThis
    public searchNavigationEntities(
        projectId: string,
        searchText: string,
        limitPerSection: number,
        currentlyViewedEnvironment: string,
        currentlyViewedGroupId: string,
        navigationCategories: NavigationCategory[],
        currentlyViewedProjectIntegrationId: string,
        isHomepage: boolean,
    ): Promise<NavigationSearchResponse> {
        return this.$http.post<NavigationSearchResponse>(this.buildUrl(`${projectId}/navigationSearch`), {
            searchText,
            limitPerSection: limitPerSection || 25,
            currentlyViewedEnvironment: currentlyViewedEnvironment || undefined,
            currentlyViewedGroupId: currentlyViewedGroupId || undefined,
            navigationCategories: navigationCategories || [],
            currentlyViewedProjectIntegrationId: currentlyViewedProjectIntegrationId || undefined,
            isHomepage,
        });
    }

    /**
     * Searches initiatives using query, the light version. Only queries the id and title fields for now.
     */
    @bindThis
    public lightSearchInitiatives(
        projectId: string,
        groupId: string,
        workflowVersionType: WorkflowVersionType,
        initiativesQuery: Record<string, any> | undefined,
        searchText: string | undefined,
        skip: number | undefined,
        limit: number | undefined,
        trackTotalHits?: boolean,
    ): Promise<LightSearchInitiativesResponse> {
        const data: Record<string, any> = {
            initiativesQuery: initiativesQuery || undefined,
            searchText: searchText || undefined,
            skip: skip || undefined,
            limit: limit || undefined,
            trackTotalHits,
        };

        return this.$http.post<LightSearchInitiativesResponse>(
            this.buildUrl(`${projectId}/${groupId}/${workflowVersionType}/lightSearchInitiatives`),
            data,
        );
    }

    /**
     * Searches initiatives using query.
     */
    @bindThis
    public searchInitiatives(projectId: string, searchInitiativesQuery?: SearchInitiativesQuery) {
        const userProjectContext =
            this.authenticationService.getCurrentUser().projectContext ||
            this.authenticationService.getCurrentUser().projectContexts?.[projectId];
        const isGuestOnly =
            userProjectContext?.calculatedTonkeanRoles?.includes(SCIMTonkeanRole.GUEST) &&
            userProjectContext?.calculatedTonkeanRoles?.length === 1;

        if (!!searchInitiativesQuery?.parentId) {
            return this.$http.post<any>(
                this.buildUrl(
                    `${getProjectIdPath(projectId)}/${getInitiativeIdPath(
                        searchInitiativesQuery.parentId,
                    )}/searchInnerInitiatives`,
                ),
                searchInitiativesQuery,
            );
        } else if (isGuestOnly) {
            return this.$http.post<any>(
                this.buildUrl(`${getProjectIdPath(projectId)}/searchGuestInitiatives`),
                searchInitiativesQuery,
            );
        }

        return this.$http.post<any>(
            this.buildUrl(`${getProjectIdPath(projectId)}/searchInitiatives`),
            searchInitiativesQuery,
        );
    }

    /**
     * Returns the position of the initiative from a given filtering in a list (business report/items list)
     * Also returns the total hits and the items ids in the list (by a limit configured in the server)
     * @param projectId - Given project ID
     * @param initiativeId - The initiative to search
     * @param searchInitiativesQuery - Rhe query of the business report to match against
     */
    @bindThis
    public getInitiativePositionInList(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
        searchInitiativesQuery?: SearchInitiativesQuery | undefined,
    ) {
        if (!initiativeId) {
            return this.$q.reject(`No initiative id is given to find position of in project ${projectId}`);
        }

        if (!searchInitiativesQuery) {
            return this.$q.reject(
                `No search initiative query given, unable to find position of initiative ${initiativeId} in project ${projectId}`,
            );
        }

        const userProjectContext =
            this.authenticationService.getCurrentUserSafe()?.projectContext ||
            this.authenticationService.getCurrentUserSafe()?.projectContexts?.[projectId];

        const isGuestOnly =
            userProjectContext?.calculatedTonkeanRoles?.includes(SCIMTonkeanRole.GUEST) &&
            userProjectContext?.calculatedTonkeanRoles?.length === 1;

        if (isGuestOnly) {
            return this.$http.post<any>(
                this.buildUrl(`${projectId}/${initiativeId}/guest/position`),
                searchInitiativesQuery,
            );
        }

        return this.$http.post<InitiativeNavigation>(
            this.buildUrl(`${projectId}/${initiativeId}/position`),
            searchInitiativesQuery,
        );
    }

    /**
     * get full initiative entity and widgets to hide / display map
     */
    @bindThis
    public getItemInterfaceData(
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
    ) {
        return this.$http.get<Initiative>(
            this.buildUrl(`${getInitiativeIdPath(initiativeId)}/${getItemInterfaceIdPath(itemInterfaceId)}`),
            {},
        );
    }

    /**
     * get initiatives  for solution report using query.
     */
    @bindThis
    public getSolutionReportInitiatives(
        solutionReportId: string,
        projectId: string,
        searchInitiativesQuery?: SearchInitiativesQuery,
    ) {
        return this.$http.post<any>(
            this.buildUrl(`${solutionReportId}/searchSolutionReportInitiatives`),
            searchInitiativesQuery,
        );
    }

    @bindThis
    public searchInitiativesByHistoryFilter(
        projectId: string,
        groupId: string,
        workerRunsApiFilter: GetWorkerRunsApiFilters,
        skip: number = 0,
        limit: number = 0,
        nextPageToken: string | undefined,
        returnTotalCount?: boolean,
    ): Promise<SearchInitiativesByHistoryFilterResponse> {
        const body = {
            workerRunsApiFilterParameters: workerRunsApiFilter || undefined,
            skip: skip || undefined,
            limit: limit || undefined,
            nextPageToken: nextPageToken || undefined,
            returnTotalCount: returnTotalCount || undefined,
        };

        return this.$http.post<SearchInitiativesByHistoryFilterResponse>(
            this.buildUrl(`${projectId}/${groupId}/searchInitiativesByHistoryFilter`),
            body,
        );
    }

    @bindThis
    public getWeekViewInitiatives(
        projectId: string,
        dateRange: { from: number; to: number },
        onlyGroupId: string,
        excludeExampleGroups: boolean,
        skip: number,
        limit: number,
        onlyDraftInitiatives: boolean,
    ) {
        const params = {
            fromEtaOrDueDateOrExternalDateOrFieldDate: dateRange.from,
            toEtaOrDueDateOrExternalDateOrFieldDate: dateRange.to,
            byGroup: onlyGroupId,
            excludeExampleGroups,
            skip,
            limit,
            onlyDraftInitiatives,
        };

        return this.$http.get<{ entities: any[]; relatedEntities: any }>(
            this.buildUrl(`${projectId}/initiatives/weekView`),
            {
                params,
            },
        );
    }

    @bindThis
    public getRelatedInitiativesCount(projectId: string, initiativeIds: string[]) {
        const data = {
            initiativeIds,
        };

        return this.$http.post<any>(this.buildUrl(`${getProjectIdPath(projectId)}/initiativesInnerItemsCount`), data);
    }

    @bindThis
    public getDateSuggestion(text) {
        const data = {
            text,
        };

        return this.$http.post<any>(this.buildUrl('suggestDate'), data);
    }

    /**
     * Publishes the workflow version for given group.
     */
    @bindThis
    public markAsReadyToPublishWorkflowVersion(
        groupId: string,
        comment: string,
        executeInitialSyncInGroup: boolean,
        calculateCustomTriggerIds: string[],
        calculateFieldDefinitionIds: string[],
    ) {
        const data = {
            comment,
            executeInitialSyncInGroup,
            calculateCustomTriggerIds,
            calculateFieldDefinitionIds,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/readyToPublish`), data);
    }

    /**
     * Publishes the workflow version for given group.
     */
    @bindThis
    public publishWorkflowVersion(
        groupId: string,
        comment: string,
        executeInitialSyncInGroup: boolean,
        calculateCustomTriggerIds: string[],
        calculateFieldDefinitionIds: string[],
    ) {
        const data = {
            comment,
            executeInitialSyncInGroup,
            calculateCustomTriggerIds,
            calculateFieldDefinitionIds,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/publish`), data);
    }

    /**
     * Create a workflow version for given group.
     */
    @bindThis
    public commitWorkflowVersion(groupId: string, comment: string) {
        const data = {
            comment,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/commit`), data);
    }

    /**
     * Reverts the draft workflow version.
     */
    @bindThis
    public revertDraftWorkflowVersion(groupId: string, workflowVersionId?: string) {
        const data = {
            workflowVersionId,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/revertDraftVersion`), data);
    }

    /**
     * Create a new group in a given project Id.
     */
    @bindThis
    public createGroup(
        projectId: string,
        name,
        visibilityType,
        members,
        notificationType,
        notificationChannel,
        visibleToOwner,
        metadata,
        shouldSendGatherUpdates,
        canAccessWithToken,
        isExample,
        innerTracksTemplate,
        writePermissionType,
        writePermissionMembers,
        dashboardHidden,
        blockWorkerErrorAlerts,
        workerType,
        recurrencePeriodType,
        recurrenceDaysInMonth,
        recurrenceDaysInWeek,
        recurrenceHour,
        recurrenceMinute,
        everyXMinutes,
        everyXHours,
        doNotRunOnWeekends,
        scheduledWorkerDefinition,
        defaultActorId: string,
        archiveDoneAfterDays,
        deleteArchivedAfterDays,
        recurrenceMonthsInYear,
        workflowFolderId: string,
        workflowFolderCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
        featuresMap,
    ) {
        const data: Record<string, any> = {
            name,
            members,
            visibilityType: visibilityType.toUpperCase(),
            notifications: {
                type: notificationType,
                channelId: notificationType === this.groupNotifications.public ? notificationChannel : null,
                channelName: notificationType === this.groupNotifications.private ? notificationChannel : null,
            },
            visibleToOwner: !!visibleToOwner,
            metadata,
            shouldSendGatherUpdates,
            canAccessWithToken,
            isExample,
            writePermissionType,
            writePermissionMembers,
            dashboardHidden,
            blockWorkerErrorAlerts,
            workerType,
            recurrencePeriodType,
            recurrenceDaysInMonth,
            recurrenceDaysInWeek,
            recurrenceHour,
            doNotRunOnWeekends,
            recurrenceMinute,
            everyXMinutes,
            everyXHours,
            scheduledWorkerDefinition,
            defaultActorId,
            archiveDoneAfterDays,
            deleteArchivedAfterDays,
            recurrenceMonthsInYear,
            workflowFolderCategoryId,
        };

        if (innerTracksTemplate) {
            data.innerTracksTemplate = innerTracksTemplate;
        }

        if (!isExample) {
            return this.$http.post<any>(this.buildUrl(`${projectId}/${workflowFolderId}/groups`), data);
        }
        return this.$http.post<any>(this.buildUrl(`${projectId}/groups`), data);
    }

    /**
     * Updates the display name of a group.
     */
    @bindThis
    public updateGroupDisplayName(groupId: string, diplayName) {
        const data = {
            displayName: diplayName,
        };

        return this.$http.post<any>(`${this.buildUrl(groupId)}/displayName`, data);
    }

    /**
     * Update an existing group by its id.
     */
    @bindThis
    public updateGroup(
        groupId: string,
        name,
        visibilityType,
        members,
        notificationType,
        notificationChannel,
        visibleToOwner,
        metadata,
        canAccessWithToken,
        innerTracksTemplate,
        writePermissionType,
        writePermissionMembers,
        blockWorkerErrorAlerts,
        defaultActorId: string,
        archiveDoneAfterDays,
        deleteArchivedAfterDays,
        isSupportUserPermitted,
        communicationIntegrationId,
        smartSearchEnabled,
    ) {
        // IMPORTANT: if adding properties to this function, make sure you also add in the prepareDefaultGroupUpdateParams function's return value.
        // So in group updates there will be a default value.
        const data: Record<string, any> = {
            name,
            members,
            visibilityType: visibilityType.toUpperCase(),
            notifications: {
                type: notificationType,
                channelId: notificationType === this.groupNotifications.public ? notificationChannel : null,
                channelName: notificationType === this.groupNotifications.private ? notificationChannel : null,
                communicationSourceProjectIntegrationId: communicationIntegrationId,
            },
            visibleToOwner: !!visibleToOwner,
            metadata,
            canAccessWithToken,
            writePermissionType,
            writePermissionMembers,
            blockWorkerErrorAlerts,
            defaultActorId,
            archiveDoneAfterDays,
            deleteArchivedAfterDays,
            smartSearchEnabled,
        };

        if (typeof isSupportUserPermitted === 'boolean') {
            data.isSupportUserPermitted = isSupportUserPermitted;
        }

        // HACK of the life: cant be array or request will fail, just ignore,
        // This means that if its array it wont change.
        if (innerTracksTemplate && !angular.isArray(innerTracksTemplate)) {
            data.innerTracksTemplate = innerTracksTemplate;
        }

        return this.$http.post<any>(this.buildUrl(groupId), data);
    }

    /**
     * Gets the group with the given group id.
     * @returns
     */
    @bindThis
    public getGroupById(groupId: string) {
        return this.$http.get<Group>(this.buildUrl(groupId));
    }

    /**
     * Update worker enabled mode of a group.
     */
    @bindThis
    public updateGroupWorkerEnabled(groupId: string, workerEnabled, evaluateOnOldItems) {
        const data = {
            workerEnabled,
            evaluateOnOldItems,
        };

        return this.$http.post<any>(`${this.buildUrl(groupId)}/workerEnabled`, data);
    }

    /**
     * Update build environment enabled mode of a group.
     */
    @bindThis
    public updateBuildEnvironmentEnabled(groupId: string, buildEnvironmentEnabled) {
        const data = {
            buildEnvironmentEnabled,
        };

        return this.$http.post<any>(`${this.buildUrl(groupId)}/buildEnvironmentEnabled`, data);
    }

    /**
     * is group outdated.
     */
    @bindThis
    public getGroupOutdated(groupId: string, environment: WorkflowVersionType) {
        return this.$http.get<GroupOutdatedResponse>(this.buildUrl(`${groupId}/${environment}/outdated`));
    }

    /**
     * Get summaries of initiatives which requester is the user
     */
    @bindThis
    public getMyRequests(projectId: TonkeanId<TonkeanType.PROJECT>) {
        return this.$http.get<MyRequestesResponse>(this.buildUrl(`${projectId}/myRequests`));
    }

    /**
     * is group outdated.
     */
    @bindThis
    public getGroupCustomTriggerCreateTrackActions(groupId: string, environment: WorkflowVersionType) {
        return this.$http
            .get<{ entities: Group[] }>(this.buildUrl(`${groupId}/${environment}/customTriggerCreateTrackActions`))
            .then((response) => response.entities);
    }

    /**
     * Update worker group owners.
     */
    @bindThis
    public updateGroupOwners(groupId: string, ownersIds) {
        const data = {
            owners: ownersIds,
        };

        return this.$http.post<any>(`${this.buildUrl(groupId)}/owners`, data);
    }

    @bindThis
    public updateWorkflowFolderOwners(workflowFolderId: string, ownersIds: string[]) {
        const data = {
            makers: ownersIds,
        };

        return this.$http.post<WorkflowFolder>(`${this.buildUrl(workflowFolderId)}/owners`, data);
    }

    @bindThis
    public updateWorkflowFolderPublishers(workflowFolderId: string, publisherIds: string[]) {
        const data = {
            publishers: publisherIds,
        };

        return this.$http.post(`${this.buildUrl(workflowFolderId)}/publishers`, data);
    }

    @bindThis
    public updateWorkflowFolderProcessOwners(workflowFolderId: string, processOwnersIds: string[]) {
        const data = {
            processOwners: processOwnersIds,
        };

        return this.$http.post(`${this.buildUrl(workflowFolderId)}/processOwners`, data);
    }

    /**
     * Update workflow version's recurring time configuration.
     */
    @bindThis
    public updateWorkflowVersionRecurringTime({
        groupId,
        recurrencePeriodType,
        recurrenceDaysInWeek,
        recurrenceDaysInMonth,
        recurrenceHour,
        everyXMinutes,
        everyXHours,
        doNotRunOnWeekends,
        recurrenceMinute,
        recurrenceMonthsInYear,
        timezone,
    }: UpdateWorkflowVersionRecurrenceTimeParams) {
        const data: Record<string, any> = {
            recurrencePeriodType,
            timezone,
        };

        // recurrenceDays (either Weekly or Monthly frequency) cannot be 0, that's why we don't need to condition over null and undefined specifically.
        if (recurrenceDaysInWeek) {
            data.recurrenceDaysInWeek = recurrenceDaysInWeek;
        }
        if (recurrenceMonthsInYear) {
            data.recurrenceMonthsInYear = recurrenceMonthsInYear;
        }

        if (recurrenceDaysInMonth) {
            data.recurrenceDaysInMonth = recurrenceDaysInMonth;
        }

        data.doNotRunOnWeekends = doNotRunOnWeekends || false;

        // recurrenceMinute, recurrenceHour, everyXMinutes, everyXHours can be 0, that's why we check for null and undefined specifically.
        if (recurrenceHour !== null && recurrenceHour !== undefined) {
            data.recurrenceHour = recurrenceHour;
        }
        if (recurrenceMinute !== null && recurrenceMinute !== undefined) {
            data.recurrenceMinute = recurrenceMinute;
        }
        if (everyXMinutes !== null && everyXMinutes !== undefined) {
            data.everyXMinutes = everyXMinutes;
        }
        if (everyXHours !== null && everyXHours !== undefined) {
            data.everyXHours = everyXHours;
        }

        return this.$http.post<any>(`${this.buildUrl(groupId)}/recurrenceTime`, data);
    }

    /**
     * Update workflow version's Scheduled Worker Definition.
     */
    @bindThis
    public updateWorkflowVersionScheduledWorkerDefinition(
        groupId: string,
        scheduledTrackTitleExpression,
        evaluatedScheduledTrackTitleExpression,
    ) {
        const data = {
            scheduledWorkerDefinition: {
                scheduledTrackTitleExpression,
                evaluatedScheduledTrackTitleExpression,
            },
        };

        return this.$http.post<any>(`${this.buildUrl(groupId)}/scheduledWorkerDefinition`, data);
    }

    /**
     * Update workflow version's is scheduled property.
     */
    @bindThis
    public updateWorkflowVersionIsScheduled(groupId: string, isScheduled) {
        const data = {
            isScheduled,
        };

        return this.$http.post<any>(`${this.buildUrl(groupId)}/isScheduled`, data);
    }

    /**
     * Update workflow version's is from another module property.
     */
    @bindThis
    public updateWorkflowVersionIsFromAnotherModule(groupId: string, isFromAnotherModule: boolean) {
        const data = {
            isFromAnotherModule,
        };

        return this.$http.post<{
            updatedWorkflowVersion: WorkflowVersion;
        }>(`${this.buildUrl(groupId)}/isFromAnotherModule`, data);
    }

    /**
     * Runs a scheduled worker immediately.
     */
    @bindThis
    public runScheduledWorkerNow(workflowVersionId: string) {
        const data = {};
        return this.$http.post<any>(`${this.buildUrl(workflowVersionId)}/runScheduledWorkerNow`, data);
    }

    /**
     * Runs a scheduled worker immediately.
     */
    @bindThis
    public testWorker(
        groupId: string,
        testItemType,
        initiativeId: string,
        externalItemId: string,
        manualItemText,
        runOnCustomTriggerIds,
    ) {
        const data = {
            testItemType,
            initiativeId,
            externalItemId,
            manualItemText,
            runOnCustomTriggerIds: runOnCustomTriggerIds && runOnCustomTriggerIds.length ? runOnCustomTriggerIds : null,
        };

        return this.$http.post<any>(`${this.buildUrl(groupId)}/testWorker`, data);
    }

    /**
     * Creates and invokes a BackgroundProcess object
     */
    @bindThis
    public InvokeBackgroundProcess(
        displayName: string,
        initiativeIds: string[],
        workflowVersionId: string,
        continueQueryDefinition: unknown | undefined,
    ) {
        const data = {
            displayName,
            initiativeIds,
            continueQueryDefinition,
        };

        return this.$http.post<BackgroundProcess>(`${this.buildUrl(workflowVersionId)}/invokeInitiatives`, data);
    }

    @bindThis
    public async exportInitiativesCsv(
        workflowVersionId: string,
        initiativeIds: string[],
        fileName: string,
    ): Promise<void> {
        return this.$http
            .post<string>(this.buildUrl(`${workflowVersionId}/initiatives/download`), {
                fileName,
                initiativeIds,
            })
            .then((response) => {
                this.utils.downloadFile(response, 'text/csv', fileName, 'csv');
            });
    }

    @bindThis
    public async getWorkflowFolderVersionsHistoryForDownload(
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    ): Promise<{
        solution: WorkflowFolderVersionsExportData;
        groups: GroupVersionsExportData[];
    }> {
        return this.$http.get(this.buildUrl(`${workflowFolderId}/versionHistory/download`), {});
    }

    /**
     * Runs existing item through worker again.
     */
    @bindThis
    public runOnExistingItemAgain(groupId: string, initiativesIds: string[], shouldReRunTriggers: boolean) {
        const data = {
            shouldReRunTriggers,
            initiativesIds,
        };

        return this.$http.post<void>(`${this.buildUrl(groupId)}/runAgain`, data);
    }

    /**
     * Runs a scheduled autonomous custom trigger immediately.
     */
    @bindThis
    public runScheduledAutonomousCustomTriggerNow(workflowVersionId: string, customTriggerId: string) {
        const data = {};
        return this.$http.post<any>(
            this.buildUrl(`${workflowVersionId}/${customTriggerId}/runScheduledAutonomousCustomTriggerNow`),
            data,
        );
    }

    /**
     * Moves a field of a group.
     */
    @bindThis
    public reorderGroupLiveReportFields(
        groupId: string,
        fieldDefinitionIdToNewIndexMap: any,
        environment: WorkflowVersionType,
        solutionBusinessReportId: string,
    ) {
        const data = {
            fieldDefinitionIdToNewIndexMap,
            solutionBusinessReportId,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/${environment}/reorderGroupLiveReportFields`), data);
    }

    @bindThis
    public updateWorkflowVersionDashboardHidden(groupId: string, dashboardHidden) {
        const data = {
            dashboardHidden,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/hidden`), data);
    }

    /**
     * Updates the group's properties, props are an object like :
     *
     * {
     *   name: 'value', // (key should be matched to tonkeanService.updateGroup function relevant param)
     * }
     */
    @bindThis
    public updateGroupProperties(group, props) {
        const params = this.prepareDefaultGroupUpdateParams(group);
        const keys = this.utils.objKeys(props);
        for (const key_ of keys) {
            const key = key_!;
            // Override given keys
            params[key] = props[key];
        }

        const paramsArray = this.utils.objValues(params);
        return (this.updateGroup as any)(...paramsArray);
    }

    /**
     * Update an existing group by its id.
     */
    @bindThis
    public updateGroupName(group, name) {
        return this.updateGroupProperties(group, { name });
    }

    /**
     * Update an existing group by its id.
     */
    @bindThis
    public updateGroupAllowShare(group, allow) {
        return this.updateGroupProperties(group, { canAccessWithToken: allow });
    }

    /**
     * Updates a group's metadata according to the given groupId and updated metadata.
     */
    @bindThis
    public updateGroupMetadata(groupId: string, metadata) {
        const data = {
            value: metadata,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/metadata`), data);
    }

    /**
     * Stars a specific group
     * @return  the updated group
     */
    @bindThis
    public starGroup(groupId: string) {
        return this.$http.post<any>(this.buildUrl(`${groupId}/star`), undefined);
    }

    /**
     * UnStars a specific group
     * @return  the updated group
     */
    @bindThis
    public unstarGroup(groupId: string) {
        return this.$http.post<any>(this.buildUrl(`${groupId}/unstar`), undefined);
    }

    /**
     * Toggles a field definition hidden\shown state in workflow version
     */
    @bindThis
    public overrideLiveReportFieldDefinitions(
        groupId: string,
        workflowVersionFieldDefinitions,
        targetType,
        takeDefinitionsFromVersionType,
        solutionBusinessReportId,
    ) {
        const data = {
            fieldDefinitions: workflowVersionFieldDefinitions,
            targetType,
            takeDefinitionsFromVersionType,
            solutionBusinessReportId,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/overrideLiveReportFieldDefinitions`), data);
    }

    /**
     * Toggles a single field definition hidden\shown state in group
     */
    @bindThis
    public setFieldIsHidden(
        fieldDefinitionId: string,
        isHidden: boolean,
        environment: WorkflowVersionType,
        groupId: string,
        targetType: string,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            isHidden,
            solutionBusinessReportId,
            targetType,
        };

        return this.$http.post<any>(
            this.buildUrl(`${groupId}/${fieldDefinitionId}/${environment}/toggleFieldIsHidden`),
            data,
        );
    }

    /**
     * Gets live report field definitions by the goup id and the environment
     */
    @bindThis
    public getLiveReportFieldDefinitions(environment: WorkflowVersionType, groupId: string) {
        return this.$http.get<LiveReportFieldDefinition[]>(this.buildUrl(`${groupId}/${environment}/liveReportFields`));
    }

    /**
     * Gets live report field definitions by group ids
     */
    @bindThis
    public getLiveReportFieldDefinitionsOfGroups(projectId: string, groupIds: string[]) {
        const config: IRequestShortcutConfig = {
            params: {
                groupIds,
            },
        };

        return this.$http.get<{
            groupIdToEnvironmentIdsToLiveReportFields: {
                groupId: string;
                environmentIdToLiveReportFields: {
                    environmentId: WorkflowVersionType;
                    liveReportFieldDefinitions: LiveReportFieldDefinition[];
                }[];
            }[];
        }>(this.buildUrl(`${projectId}/liveReportFieldsByGroupIds`), config);
    }

    /**
     * Gets solution business report field definitions by id
     */
    @bindThis
    public getSolutionBusinessReportFieldDefinitions(solutionBusinessReportId: string) {
        return this.$http.get<LiveReportFieldDefinition[]>(
            this.buildUrl(`${solutionBusinessReportId}/solutionBusinessReportFields`),
        );
    }

    /**
     * Import initiatives according to the given parameters.
     */
    @bindThis
    public importInitiatives(
        projectId: string,
        projectIntegrationId: string,
        type,
        parentInitiativeId: string,
        workflowVersionId: string,
        testDrive,
        includeIds,
        queryString,
    ) {
        const data: Record<string, any> = {};

        let suffix = '';

        if (parentInitiativeId) {
            data.parentInitiativeId = parentInitiativeId;
        }
        if (workflowVersionId) {
            data.workflowVersionId = workflowVersionId;
        }
        if (includeIds) {
            data.includeIds = includeIds;
        }
        if (queryString) {
            data.queryString = queryString;
        }
        // testDrive is a flag saying we just want to show the user the entities to import - not actually import them.
        if (testDrive) {
            suffix = '?testDrive=true';
        }

        return this.$http.post<any>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/import/${type}${suffix}`),
            data,
        );
    }

    /**
     * Sync the selected view to the given initiative.
     */
    @bindThis
    public createInitiativeSyncConfig(
        initiativeId: string,
        projectIntegrationId: string,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType: string,
        deleteItemsSelectionType: DeleteItemsSelectionType,
        syncItemsCreatorSelectionType: SyncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration?: SyncItemsCreatorConfiguration,
    ) {
        const data = {
            projectIntegrationId,
            viewData,
            viewCount,
            viewType,
            previewMode: !!previewMode,
            uniqueIdentifierType,
            deleteItemsSelectionType,
            syncItemsCreatorSelectionType,
            syncItemsCreatorConfiguration,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/syncConfig`), data);
    }

    /**
     * Sync the selected view to the given group.
     */
    @bindThis
    public createGroupSyncConfig(
        groupId: string,
        projectIntegrationId: string,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType: string,
        deleteItemsSelectionType: DeleteItemsSelectionType,
        syncItemsCreatorSelectionType: SyncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration?: SyncItemsCreatorConfiguration,
    ) {
        const data = {
            projectIntegrationId,
            viewData,
            viewCount,
            viewType,
            previewMode: !!previewMode,
            uniqueIdentifierType,
            deleteItemsSelectionType,
            syncItemsCreatorSelectionType,
            syncItemsCreatorConfiguration,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/syncConfig`), data);
    }

    /**
     * Updates the given sync config.
     */
    @bindThis
    public updateSyncConfig(
        syncConfigId: string,
        projectIntegrationId: string,
        viewData,
        viewCount,
        viewType,
        previewMode,
        uniqueIdentifierType: string,
        deleteItemsSelectionType: DeleteItemsSelectionType,
        syncItemsCreatorSelectionType: SyncItemsCreatorSelectionType,
        syncItemsCreatorConfiguration?: SyncItemsCreatorConfiguration,
    ) {
        const data = {
            projectIntegrationId,
            viewData,
            viewCount,
            viewType,
            previewMode: !!previewMode,
            uniqueIdentifierType,
            deleteItemsSelectionType,
            syncItemsCreatorSelectionType,
            syncItemsCreatorConfiguration,
        };

        return this.$http.post<any>(this.buildUrl(syncConfigId), data);
    }

    /**
     * Returns the preview results of a sync about to be configured.
     */
    @bindThis
    public previewSyncView(
        projectId: string,
        projectIntegrationId: string,
        viewData,
        viewType,
        viewCount?: number,
        searchText?: string,
    ) {
        const data: Record<string, any> = {
            projectIntegrationId,
            viewType,
            viewData,
            viewCount,
            searchText,
        };

        data.previewMode = true;

        return this.$http.post<EntitiesSyncPreviewResponse>(this.buildUrl(`${projectId}/previewSyncView`), data);
    }

    @bindThis
    public getSearchWidgetSearch(
        workflowVersionType: WorkflowVersionType,
        itemInterfaceWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
        searchText: string,
        skip: number,
        limit: number,
    ) {
        const params = {
            searchText,
            skip,
            limit,
        };
        return this.$http.get<SearchWidgetSearchResponse>(
            this.buildUrl(`${workflowVersionType}/${itemInterfaceWidgetId}/searchWidget`),
            {
                params,
            },
        );
    }

    @bindThis
    public getSearchWidgetSelectedItems(
        workflowVersionType: WorkflowVersionType,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        itemInterfaceWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
    ) {
        const params = {};
        const data = {};
        return this.$http.get<SearchWidgetUpdateSelectedResponse>(
            this.buildUrl(`${workflowVersionType}/${initiativeId}/${itemInterfaceWidgetId}/searchWidgetSelectedItems`),
            {
                data,
                params,
            },
        );
    }

    @bindThis
    public updateSearchWidgetSelectedItem(
        workflowVersionType: WorkflowVersionType,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        itemInterfaceWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
        externalId: string,
    ) {
        const data = {
            externalId,
        };
        return this.$http.post<SearchWidgetUpdateSelectedResponse>(
            this.buildUrl(`${workflowVersionType}/${initiativeId}/${itemInterfaceWidgetId}/searchWidgetToggleItem`),
            data,
        );
    }

    @bindThis
    public updateEnterpriseComponentDisplayName(projectId: string, enterpriseComponentId: string, displayName) {
        return this.$http.post<any>(
            this.buildUrl(`${projectId}/${enterpriseComponentId}/displayName?value=${displayName}`),
            {},
        );
    }

    @bindThis
    public getWorkflowFolderAccessibleEnterpriseComponents(
        projectId: string,
        workflowFolderId: string,
        enterpriseComponentType: EnterpriseComponentType,
    ): Promise<WorkflowFolderAccessibleEnterpriseComponentsResponse> {
        return this.$http.get<WorkflowFolderAccessibleEnterpriseComponentsResponse>(
            this.buildUrl(`${projectId}/${workflowFolderId}/accessible/${enterpriseComponentType}`),
        );
    }

    @bindThis
    public getNoCodeProjectIntegrationWithUploadAction(
        projectId: string,
    ): Promise<{ projectIntegrationIds: string[] }> {
        return this.$http.get<{ projectIntegrationIds: string[] }>(
            this.buildUrl(`${projectId}/projectIntegrationsWithUploadAction`),
        );
    }

    @bindThis
    public getNoCodeProjectIntegrationWithDownloadAction(
        projectId: string,
    ): Promise<{ projectIntegrationIds: string[] }> {
        return this.$http.get<{ projectIntegrationIds: string[] }>(
            this.buildUrl(`${projectId}/projectIntegrationsWithDownloadAction`),
        );
    }

    // Update Enterprise Component description
    @bindThis
    public updateEnterpriseComponentDescription(projectId: string, projectIntegrationId: string, description: string) {
        return this.$http.post<any>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/description?descriptionValue=${description}`),
            {},
        );
    }

    // Update Enterprise Component icon
    @bindThis
    public async updateEnterpriseComponentIcon(projectId: string, projectIntegrationId: string, icon: Blob) {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': icon.type,
            },
        };
        const { iconUrl } = await this.$http.post<{ iconUrl: string }>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/icon`),
            icon,
            options,
        );
        return iconUrl;
    }

    @bindThis
    public updateProjectIntegrationStorageIntegration(projectIntegrationId: string, storageIntegrationId: string) {
        const data = {
            value: storageIntegrationId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectIntegrationId}/storageIntegration`), data);
    }

    @bindThis
    public updateProjectIntegrationStorageIntegrationConfiguration(
        projectIntegrationId: string,
        storageIntegrationConfiguration,
    ) {
        const data = {
            value: storageIntegrationConfiguration,
        };

        return this.$http.post<any>(this.buildUrl(`${projectIntegrationId}/storageIntegrationConfiguration`), data);
    }

    /**
     * Returns the preview of the list field definitions.
     */
    @bindThis
    public previewListFieldDefinitions(
        workflowVersionId: string,
        fieldDefinitionsToPreview,
        query,
        filterInitiativesByTonkeanQuery,
        skip: number,
        limit: number,
    ) {
        const data = {
            fieldDefinitionsToPreview: fieldDefinitionsToPreview || [],
            q: query || '',
            filterInitiativesByTonkeanQuery,
            skip: skip || 0,
            limit: limit || 10,
        };

        return this.$http.post<any>(this.buildUrl(`${workflowVersionId}/previewFieldDefinitions`), data);
    }

    /**
     * Creates initiative by intake.
     */
    @bindThis
    public createInitiativeByIntake(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        workflowVersionType: WorkflowVersionType,
        initialValuesFromIntakeCreator: Record<string, String>,
        conversationId: string | undefined,
    ) {
        const data = { initialValuesFromIntakeCreator, conversationId };
        return this.$http.post<Initiative>(
            this.buildUrl(`${projectId}/${customTriggerId}/${workflowVersionType}/initiative`),
            data,
        );
    }

    /**
     * Removes sync from target.
     */
    @bindThis
    public deleteSyncConfig(syncConfigId: string) {
        return this.$http.delete<any>(this.buildUrl(syncConfigId));
    }

    /**
     * Create a new initiative.
     */
    @bindThis
    public createInitiative(
        projectId: string,
        parentId: string,
        title,
        tags,
        metadata,
        functions,
        ownerId: string,
        tempOwner,
        due,
        groupId: string,
        initiativeId: string,
        fields,
        underItemId: string,
        etagValue,
        workerRunLogicActionId: string,
        createdByCustomTriggerId: string,
        workerRunLogicActionType,
        state,
        description,
        eta,
        updateText,
        createdByFormId: string,
        createdByFormName,
        customTriggerId: string,
        workerRunId: string,
        inEditMode,
        isDraftInitiative,
        isFormQuestion: boolean,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = this.createInitiativeBodyParam(
            title,
            tags,
            metadata,
            functions,
            isFormQuestion,
            initiativeId,
            fields,
            parentId,
            underItemId,
            groupId,
            ownerId,
            tempOwner,
            customTriggerId,
            workerRunId,
            due,
            workerRunLogicActionId,
            createdByCustomTriggerId,
            workerRunLogicActionType,
            state,
            description,
            eta,
            updateText,
            createdByFormId,
            createdByFormName,
            inEditMode,
            isDraftInitiative,
        );

        const url = throughSolutionBusinessReport
            ? `${solutionBusinessReportId}/initiatives`
            : `${projectId}/initiatives`;
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     * Create a new initiative.
     */
    @bindThis
    public createInnerInitiative(
        projectId: string,
        parentId: string,
        title,
        tags,
        metadata,
        functions,
        ownerId: string,
        tempOwner,
        due,
        groupId: string,
        initiativeId: string,
        fields,
        underItemId: string,
        etagValue,
        workerRunLogicActionId: string,
        createdByCustomTriggerId: string,
        workerRunLogicActionType,
        state,
        description,
        eta,
        updateText,
        createdByFormId: string,
        createdByFormName,
        customTriggerId: string,
        workerRunId: string,
        inEditMode,
        isDraftInitiative,
        isFormQuestion: boolean,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
        fieldDefinitionIdToExpression?: {
            fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>;
            expression: TonkeanExpressionDefinition;
        }[],
    ) {
        const data = this.createInitiativeBodyParam(
            title,
            tags,
            metadata,
            functions,
            isFormQuestion,
            initiativeId,
            fields,
            parentId,
            underItemId,
            groupId,
            ownerId,
            tempOwner,
            customTriggerId,
            workerRunId,
            due,
            workerRunLogicActionId,
            createdByCustomTriggerId,
            workerRunLogicActionType,
            state,
            description,
            eta,
            updateText,
            createdByFormId,
            createdByFormName,
            inEditMode,
            isDraftInitiative,
            fieldDefinitionIdToExpression,
        );

        const url = throughSolutionBusinessReport
            ? `${solutionBusinessReportId}/initiatives`
            : `${projectId}/${parentId}/initiatives`;
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    private createInitiativeBodyParam(
        title,
        tags,
        metadata,
        functions,
        isFormQuestion: boolean,
        initiativeId: string,
        fields,
        parentId: string,
        underItemId: string,
        groupId: string,
        ownerId: string,
        tempOwner,
        customTriggerId: string,
        workerRunId: string,
        due,
        workerRunLogicActionId: string,
        createdByCustomTriggerId: string,
        workerRunLogicActionType,
        state,
        description,
        eta,
        updateText,
        createdByFormId: string,
        createdByFormName,
        inEditMode,
        isDraftInitiative,
        fieldDefinitionIdToExpression?: {
            fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>;
            expression: TonkeanExpressionDefinition;
        }[],
    ) {
        const data: Record<string, any> = {
            title: title.trim(),
            tags: tags || [],
            metadata: metadata || {},
            functions: functions || [],
            isFormQuestion: isFormQuestion || false,
        };

        if (!!fieldDefinitionIdToExpression?.length) {
            data.fieldDefinitionIdToExpression = fieldDefinitionIdToExpression;
        }

        if (initiativeId) {
            data.initiativeId = initiativeId;
        }
        if (fields) {
            data.fields = fields;
        }
        if (parentId) {
            data.parentId = parentId;
        }
        if (underItemId) {
            data.underItemId = underItemId;
        }
        if (groupId && groupId !== 'public') {
            data.groupId = groupId;
        }
        if (ownerId) {
            data.owner = ownerId;
        } else if (tempOwner) {
            data.tempOwner = tempOwner;
        }

        if (customTriggerId) {
            data.customTriggerId = customTriggerId;
        }

        if (workerRunId) {
            data.workerRunId = workerRunId;
        }

        if (due) {
            data.dueDate = due;
        }

        if (workerRunLogicActionId) {
            data.workerRunLogicActionId = workerRunLogicActionId;
        }
        if (createdByCustomTriggerId) {
            data.createdByCustomTriggerId = createdByCustomTriggerId;
        }
        if (workerRunLogicActionType) {
            data.workerRunLogicActionType = workerRunLogicActionType;
        }
        if (state) {
            data.status = state.type;
            data.stateColor = state.color;
            data.stateText = state.label;
        }
        if (description) {
            data.description = description;
        }
        if (eta) {
            data.eta = eta;
        }
        if (updateText) {
            data.updateText = updateText;
        }

        if (createdByFormId) {
            data.createdByFormId = createdByFormId;
        }
        if (createdByFormName) {
            data.createdByFormName = createdByFormName;
        }

        if (inEditMode) {
            data.inEditMode = inEditMode;
        }

        if (isDraftInitiative) {
            data.isDraftInitiative = isDraftInitiative;
        }
        return data;
    }

    @bindThis createPartialInitiative(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        title: string,
        groupId: TonkeanId<TonkeanType.GROUP>,
        isDraftInitiative: boolean,
        ownerId: string | undefined,
        parentId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
        due: number | undefined,
        fields: { fieldDefinitionId: string; value: string }[],
        state:
            | {
                  type: string;
                  color: string;
                  label: string;
              }
            | undefined,
        createdByCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER> | undefined,
        fieldDefinitionIdToExpression?: {
            fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>;
            expression: TonkeanExpressionDefinition;
        }[],
    ) {
        if (parentId) {
            return this.createInnerInitiative(
                projectId,
                parentId || '',
                title,
                null,
                null,
                null,
                ownerId || '',
                null,
                due,
                groupId,
                '',
                fields,
                '',
                null,
                '',
                createdByCustomTriggerId || '',
                null,
                state,
                null,
                null,
                null,
                '',
                null,
                '',
                '',
                false,
                isDraftInitiative,
                false,
                false,
                undefined,
                fieldDefinitionIdToExpression,
            );
        } else {
            return this.createInitiative(
                projectId,
                parentId || '',
                title,
                null,
                null,
                null,
                ownerId || '',
                null,
                due,
                groupId,
                '',
                fields,
                '',
                null,
                '',
                '',
                null,
                state,
                null,
                null,
                null,
                '',
                null,
                '',
                '',
                false,
                isDraftInitiative,
                false,
                false,
            );
        }
    }

    @bindThis
    public duplicateInitiative(initiativeId: TonkeanId<TonkeanType.INITIATIVE>, title?: string) {
        const data = {
            title,
        };

        return this.$http.post<Initiative>(this.buildUrl(`${initiativeId}/duplicate`), data);
    }

    @bindThis
    public setDefaultCommunicationIntegration(projectId: string, projectIntegrationId: string) {
        const data = {
            projectIntegrationId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/setDefaultCommunicationIntegration`), data);
    }

    /**
     * Create multiple initiatives in bulk.
     */
    @bindThis
    public createMultipleInitiatives(groupId: string, initiatives) {
        if (!initiatives || !initiatives.length) {
            return this.$q.resolve();
        }

        // Make sure the given initiatives are valid.
        for (const initiative of initiatives) {
            if (initiative) {
                // Make sure we send arrays/objects and not nulls when expected.
                if (!initiative.tags) {
                    initiative.tags = [];
                }
                if (!initiative.functions) {
                    initiative.functions = [];
                }
                if (!initiative.metadata) {
                    initiative.metadata = {};
                }
            }
        }

        // Splitting the initiatives we received to chunks that the server can accept.
        const initiativeChunks = this.utils.splitArrayToChunks(initiatives, 250);
        // currentPromise will hold the promise chain for creation of all chunks of initiatives.
        let currentPromise: Promise<any> | null = null;

        /* jshint loopfunc:true */
        for (const currentChunk of initiativeChunks) {
            const currentData = {
                initiatives: currentChunk,
            };

            if (!currentPromise) {
                // If current promise is not yet defined, this is the first call to multiple initiatives. We set it to be the promise.
                currentPromise = this.$http.post<any>(this.buildUrl(`${groupId}/multipleInitiatives`), currentData);
            } else {
                // If current promise is already defined, we're not at the first call, and we should chain the current call
                // to the currentPromise. Thus, we will end up with a chain of promises that when currentPromise is complete,
                // we can be sure that all given initiatives creation is done.
                // Note that it is very important to concatenate the result from previous promise to the result of the current promise,
                // so at the end of the chain we will have all the results.
                currentPromise = currentPromise.then((previousData) => {
                    return this.$http
                        .post<any>(this.buildUrl(`${groupId}/multipleInitiatives`), currentData)
                        .then((data) => {
                            // Merging previous data into current data.
                            data.entities = data.entities.concat(previousData.entities);
                            this.utils.flatCopyObjectProperties(data.relatedEntities, previousData.relatedEntities);

                            return this.$q.resolve(data);
                        });
                });
            }
        }

        return currentPromise;
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeMetadata(initiativeId: string, value) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/metadata`), data);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeFunctionMetadata(initiativeId: string, functionId: string, value) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/${functionId}/metadata`), data);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateProjectMetadataJson(projectId: string, value) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/metadataJson`), data);
    }

    /**
     * update is allowed non admins to create solutions.
     */
    @bindThis
    public updateProjectIsAllowedOnlyAdminsToCreateSolution(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        isAllowOnlyAdminsToCreateSolutions: boolean,
    ) {
        const data = {
            isAllowOnlyAdminsToCreateSolutions,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/allowOnlyAdminsToCreateSolutions`), data);
    }

    /**
     * Updates the given workflow version's states, or resets them to default.
     * @param groupId - the id of the group to update states for.
     * @param states - the updated states array.
     * @param resetToDefault - if true, the workflow version's states will be reset to default.
     * @returns
     */
    @bindThis
    public updateWorkflowVersionStates(groupId: string, states, resetToDefault) {
        const data = {
            states,
            resetToDefault: !!resetToDefault,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/states`), data);
    }

    /**
     * Updates the given workflow version's data source type.
     */
    @bindThis
    public updateWorkflowVersionDataTypeSource(groupId: string, dataSourceType) {
        const data = {
            dataSourceType,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/dataSourceType`), data);
    }

    /**
     * Update workflow version's description (read me)
     */
    public updateWorkflowVersionDescription(groupId: string, htmlDescription: any[]) {
        const data = {
            htmlDescription,
        };

        return this.$http.post<void>(`${this.buildUrl(groupId)}/htmlDescription`, data);
    }

    /**
     * Updates the given project's states, or resets them to default.
     * @param projectId - the id of the project to update states for.
     * @param states - the updated states array.
     * @param resetToDefault - if true, the project's states will be reset to default.
     * @returns
     */
    @bindThis
    public updateProjectStates(projectId: string, states, resetToDefault) {
        const data = {
            states,
            resetToDefault: !!resetToDefault,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/states`), data);
    }

    /**
     * Gets the project's default states array.
     * @returns
     */
    @bindThis
    public getProjectDefaultStates(projectId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/defaultStates`));
    }

    /**
     Update the projects domains
     @param projectId the project id to update
     @param domains an array of strings describing the updated projects domains
     @return {HttpPromise}
     */
    @bindThis
    public updateProjectDomains(projectId: string, scimDomains: string[], manualDomains: string[]) {
        const data = { scimDomains, manualDomains };
        return this.$http.post<any>(this.buildUrl(`${projectId}/domains`), data);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeDescription(
        initiativeId: string,
        value,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            value,
            solutionBusinessReportId,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${initiativeId}/description`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeDueDate(
        initiativeId: string,
        value,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            value,
            solutionBusinessReportId,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${initiativeId}/dueDate`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     * Gets initiatives that were created by form (duplicate submitted ones if needed).
     */
    @bindThis
    public getDuplicatedFormInitiatives(
        workflowVersionId: string,
        formId: string,
        parentId: string,
        shouldIncludeEdit: boolean = true,
        shouldDuplicate: boolean = true,
        shouldIncludeQuestion: boolean = true,
        allInitiatives: boolean = false,
        skip?: number,
        limit?: number,
    ) {
        const params = {
            shouldIncludeEdit,
            shouldDuplicate,
            shouldIncludeQuestion,
            allInitiatives,
        };

        if (!this.utils.isNullOrEmpty(skip)) {
            // Typescript didn't allow me to use params.skip
            params['skip'] = skip;
        }

        if (!this.utils.isNullOrEmpty(limit)) {
            // Typescript didn't allow me to use params.limit
            params['limit'] = limit;
        }

        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${formId}/${parentId}/formInitiatives`), {
            params,
        });
    }

    /**
     * Update start time
     */
    @bindThis
    public updateInitiativeStartTime(initiativeId: string, value, etagValue) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/startTime`), data, this.getETagConfig(etagValue));
    }

    /**
     * Set the weekly report config
     */
    @bindThis
    public updateNextSummaryReportConfig(projectId: string, value) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/me/nextReport`), data);
    }

    /**
     * Set the morning alert flag for the given project
     */
    @bindThis
    public updateSendMorningAlertConfig(projectId: string, value) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/me/morningAlert`), data);
    }

    /**
     * Enable or disable the highlights generation for the user.
     * @param projectId - the project id in which we should toggle the highlights for the user.
     * @param value - true or false, to turn the highlights on or off.
     */
    @bindThis
    public toggleDigestHighlights(projectId: string, value) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/me/createHighlights`), data);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeGatherUpdatesPreferences(initiativeId: string, values, etagValue) {
        const data = {
            values,
        };

        return this.$http.post<any>(
            this.buildUrl(`${initiativeId}/gatherUpdatesPreferences`),
            data,
            this.getETagConfig(etagValue),
        );
    }

    /**
     /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeTitle(
        initiativeId: string,
        value,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            value: value.trim(),
            solutionBusinessReportId,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${initiativeId}/title`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeTags(
        initiativeId: string,
        values,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            values,
            solutionBusinessReportId,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${initiativeId}/tags`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeIntegrationFilter(initiativeId: string, value) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/integrationFilter`), data);
    }

    /**
     /**
     * Create insight for inquiryId
     */
    @bindThis
    public askForInitiativeUpdates(initiativeId: string, customText, isExample) {
        const data: Record<string, any> = {
            isExample,
        };
        if (customText && customText.length > 0) {
            data.text = customText;
        }
        return this.$http.post<any>(this.buildUrl(`${initiativeId}/gatherUpdates`), data);
    }

    /**
     /**
     * Create insight for inquiryId
     */
    @bindThis
    public initiativeSubscribe(initiativeId: string, subscribed) {
        const action = subscribed ? 'unsubscribe' : 'subscribe';
        return this.$http.post<any>(this.buildUrl(`${initiativeId}/${action}`), null);
    }

    /**
     * Get the available external statuses for the given initiative.
     */
    @bindThis
    public getInitiativeAvailableExternalStatuses(initiativeId: string) {
        return this.$http.get<any>(this.buildUrl(`${initiativeId}/availableExternalStatuses`));
    }

    /**
     * Updates the external status for the initiative.
     */
    @bindThis
    public updateInitiativeExternalStatus(initiativeId: string, statusId: string) {
        const data = {
            statusId,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/updateInitiativeExternalStatus`), data);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public createFunctions(projectId: string, functions) {
        const data = {
            names: functions,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/functions`), data);
    }

    /**
     * Create new incoming webhook.
     */
    @bindThis
    public createIncomingWebhook(projectId: string, displayName, definition, iconUrl) {
        const data = {
            displayName,
            definition,
            iconUrl,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/incomingWebhooks`), data);
    }

    /**
     * Create new incoming webhook for twilio.
     */
    @bindThis
    public createTwilioIncomingWebhook(projectId: string, projectIntegrationId: string) {
        const data = {
            projectIntegrationId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/incomingWebhooks/twiliochat`), data);
    }

    /**
     * Create new incoming webhook for greenhouse.
     */
    @bindThis
    public createGreenhouseIncomingWebhook(projectId: string, projectIntegrationId: string) {
        const data = {
            projectIntegrationId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/incomingWebhooks/greenhouse`), data);
    }

    /**
     * Create new incoming webhook for frontapp.
     */
    @bindThis
    public createIntegrationIncomingWebhook(projectId: string, projectIntegrationId: string, integrationType) {
        const data = {
            projectIntegrationId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/incomingWebhooks/${integrationType}`), data);
    }

    /**
     * Create new incoming webhook.
     */
    @bindThis
    public createIncomingEmailWebhook(
        projectId: string,
        displayName,
        iconUrl,
        storageIntegrationId: string,
        storageIntegrationConfiguration,
    ) {
        const data = {
            displayName,
            iconUrl,
            storageIntegrationId,
            storageIntegrationConfiguration,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/incomingEmails`), data);
    }

    /**
     * Gets an incoming webhook.
     */
    @bindThis
    public getIncomingWebhookByProjectIntegrationId(
        projectId: string,
        projectIntegrationId: string,
        noErrorOnNotExisting?,
        incomingWebhookType?,
    ) {
        if (!projectId || !projectIntegrationId) {
            return this.$q.resolve();
        }

        const config: IRequestShortcutConfig = { params: {} };

        if (noErrorOnNotExisting) {
            config.params.noErrorOnNotExisting = true;
        }

        if (incomingWebhookType) {
            config.params.incomingWebhookType = incomingWebhookType;
        }
        return this.$http.get<any>(this.buildUrl(`${projectId}/incomingWebhooks/${projectIntegrationId}`), config);
    }

    /**
     * Create new incoming webhook for ServiceNow.
     */
    @bindThis
    public createServicenowIncomingWebhook(projectId: string, projectIntegrationId: string) {
        const data = {
            projectIntegrationId,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/incomingWebhooks/servicenow`), data);
    }

    /**
     * Gets all incoming webhooks by project integration.
     */
    @bindThis
    public getIncomingWebhooksByProjectIntegrationId(projectIntegrationId: string) {
        const data = {};
        return this.$http.get<any>(this.buildUrl(`${projectIntegrationId}/incomingWebhooks`), data);
    }

    /**
     * Updates an existing incoming webhook.
     */
    @bindThis
    public updateIncomingWebhook(incomingWebhookId: string, displayName, definition, updatableExternalActivities) {
        const data = {
            incomingWebhookId,
            displayName,
            definition,
            updatableExternalActivities,
        };

        return this.$http.post<any>(this.buildUrl(incomingWebhookId), data);
    }

    /**
     * Deletes an existing incoming webhook.
     */
    @bindThis
    public deleteIncomingWebhook(incomingWebhookId: string) {
        return this.$http.delete<any>(this.buildUrl(incomingWebhookId));
    }

    /**
     * Sets the "pick off a child key" JSON mapping setting for the given webhook.
     * @param incomingWebhookId - the id of the webhook to update.
     * @param pickOffChildKeyString - the "pick off a child key" string to set. If null/empty string the property gets cleared.
     */
    @bindThis
    public setIncomingWebhookPickOffChildKey(incomingWebhookId: string, pickOffChildKeyString) {
        const data = {
            pickOffChildKey: pickOffChildKeyString,
        };

        return this.$http.post<any>(`${this.buildUrl(incomingWebhookId)}/pickOffChildKey`, data);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateFunctionDefaultOwner(functionId: string, ownerId: string) {
        const data = {
            value: ownerId,
        };

        return this.$http.post<any>(this.buildUrl(`${functionId}/defaultOwner`), data);
    }

    /**
     * Get is state of specific group is deletable.
     */
    @bindThis
    public getIsStateDeletable(projectId: Project['id'], groupId: Group['id'], stateId: string) {
        return this.$http.get<{ isDeletable: boolean; usedCustomTriggers: string[] }>(
            this.buildUrl(`${projectId}/${groupId}/${stateId}/isDeletable`),
        );
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateFunctionName(functionId: string, name) {
        const data = {
            value: name,
        };

        return this.$http.post<any>(this.buildUrl(`${functionId}/name`), data);
    }

    /**
     * Generate initiatives ids
     */
    @bindThis
    public generateInitiativeId(count) {
        const data = {};

        return this.$http.post<any>(this.buildUrl(`INITIATIVE/id?count=${count}`), data);
    }

    /**
     * Gets project communication channels.
     */
    @bindThis
    public getCommunicationChannels(projectId: string, integrationId: string, serviceUrl, teamId: string) {
        const config: IRequestShortcutConfig = {
            params: {
                serviceUrl, // For Microsoft Teams
                teamId, // For Microsoft Teams
            },
        };
        return this.$http.get<any>(this.buildUrl(`${projectId}/${integrationId}/autoComplete/channels`), config);
    }

    /**
     * Returns a workflow version of given type for the given project and group ids.
     */
    @bindThis
    public getWorkflowVersionOfType(projectId: string, workflowVersionType, groupIds, skip: number, limit: number) {
        return this.$http.post<any>(this.buildUrl(`${projectId}/workflowVersions/${workflowVersionType}`), {
            groupIds,
            skip,
            limit,
        });
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public setNotificationChannel(id: string, channelId: string, overwrite) {
        const data = {
            value: channelId,
        };
        return this.$http.post<any>(
            this.buildUrl(id + (overwrite ? '/customNotificationSettings' : '/notificationSettings')),
            data,
        );
    }

    /**
     * Check if notification channel exists and both the user and tonkean are in the channel.
     */
    @bindThis
    public checkNotificationChannel(projectId: string, notificationType, channelName) {
        const data = {
            notifications: {
                type: notificationType,
                channelName,
            },
        };
        return this.$http.post<any>(this.buildUrl(`${projectId}/notificationSettings/check`), data);
    }

    @bindThis
    public checkSlackChannel(projectId: string, integrationId: string, channelName: string, channelType: string) {
        const data = {
            integrationId,
            channelName,
            channelType,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/checkSlackChannel`), data);
    }

    @bindThis
    public getWorkflowFoldersRelations(folderId: string, projectId: string, skip: number, limit: number) {
        limit = angular.isNumber(limit) && limit >= 0 ? limit : 100;
        skip = angular.isNumber(skip) && skip >= 0 ? skip : 0;

        const params = {
            limit,
            skip,
        };

        return this.$http.get<any>(this.buildUrl(`${projectId}/${folderId}/relations`), { params });
    }

    @bindThis
    public getWorkflowFolder(workflowFolderId: string) {
        const params = {};

        return this.$http.get<WorkflowFolder>(this.buildUrl(`${workflowFolderId}`), { params });
    }

    @bindThis
    public getWorkflowFolders(
        projectId: string,
        skip: number,
        limit: number,
        relationsLimit,
        onlyOwnerOf: boolean = false, // Only solutions current user is an owner of
    ): Promise<{ entities: WorkflowFolder[]; relations: any[] }> {
        limit = angular.isNumber(limit) && limit >= 0 ? limit : 100;
        relationsLimit = angular.isNumber(relationsLimit) && relationsLimit >= 0 ? relationsLimit : 100;
        skip = angular.isNumber(skip) && skip >= 0 ? skip : 0;

        const params = {
            limit,
            skip,
            relationsLimit,
            onlyOwnerOf,
        };

        return this.$http.get<any>(this.buildUrl(`${projectId}/workflowFolders`), { params });
    }

    @bindThis
    public getWorkflowFolderBusinessReports(
        workflowFolderId: string,
    ): Promise<{ entities: BusinessReportAccessibilitySummary[] }> {
        const params = {};

        return this.$http.get<{ entities: BusinessReportAccessibilitySummary[] }>(
            this.buildUrl(`${workflowFolderId}/businessReports`),
            { params },
        );
    }

    @bindThis
    public getPersonBusinessReports(projectId: string): Promise<{ entities: BusinessReportAccessibilitySummary[] }> {
        const params = {};

        return this.$http.get<{ entities: BusinessReportAccessibilitySummary[] }>(
            this.buildUrl(`${projectId}/businessReports`),
            { params },
        );
    }

    @bindThis
    public getPersonBusinessReportById(groupId: string): Promise<BusinessReportAccessibilitySummary> {
        const params = {};

        return this.$http.get<BusinessReportAccessibilitySummary>(this.buildUrl(`${groupId}/businessReport`), {
            params,
        });
    }

    @bindThis
    public updateBusinessReportAccessibility(
        groupId: string,
        isAccessible?: boolean,
        businessReportAccessibilityType?: BusinessReportAccessType,
        specificPeopleWithAccess?: string[],
    ): Promise<any> {
        const data = {
            isAccessible,
            businessReportAccessibilityType: businessReportAccessibilityType || undefined,
            specificPeopleWithAccess: specificPeopleWithAccess || undefined,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/accessibility`), data);
    }

    @bindThis
    public getWorkflowFolderPublishers(workflowFolderId: string): Promise<{ entities: Person[] }> {
        return this.$http.get<{ entities: Person[] }>(this.buildUrl(`${workflowFolderId}/publishers`));
    }

    @bindThis
    public deleteWorkflowFolder(folderId: string) {
        return this.$http.delete<any>(this.buildUrl(folderId));
    }

    @bindThis
    public createWorkflowFolder(folderName, isSandbox, projectId: string) {
        const data = {
            displayName: folderName,
            isSandbox,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/workflowFolders`), data);
    }

    @bindThis
    public addGroupToWorkflowFolder() {
        return this.$q.resolve();
    }

    @bindThis
    public deleteGroupFromWorkflowFolder() {
        return this.$q.resolve();
    }

    @bindThis
    public moveEntityToWorkflowFolder(
        folderId: string,
        groupId: string,
        workflowFolderCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
    ) {
        const data = {
            entityToMoveId: groupId,
            workflowFolderCategoryId,
        };

        return this.$http.post<any>(this.buildUrl(`${folderId}/moveInto`), data);
    }

    @bindThis
    public updateWorkflowFolderDisplayName(workflowFolderId: string, displayName: string) {
        const data = {
            displayName,
        };
        return this.$http.post<any>(this.buildUrl(`${workflowFolderId}/updateDisplayName`), data);
    }

    @bindThis
    public updateWorkflowFolderBusinessOutcome(
        workflowFolderId: string,
        businessOutcome: {
            markdownBusinessOutcome?: string;
            htmlBusinessOutcome?: TElement[];
        },
    ) {
        return this.$http.post<undefined>(this.buildUrl(`${workflowFolderId}/updateBusinessOutcome`), businessOutcome);
    }

    @bindThis
    public updateWorkflowFolderDescription(
        workflowFolderId: string,
        description: {
            markdownDescription?: string;
            htmlDescription?: TElement[];
        },
    ) {
        return this.$http.post<undefined>(this.buildUrl(`${workflowFolderId}/updateDescription`), description);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public setInitiativeFunction(initiativeId: string, functionId: string, etagValue) {
        const data = {
            value: functionId,
        };
        return this.$http.post<any>(this.buildUrl(`${initiativeId}/function`), data, this.getETagConfig(etagValue));
    }

    @bindThis
    public guestMoveInitiative(
        initiativeId: string,
        currentParentId: string,
        underId: string,
        parentId: string,
        moveToGroupId: string,
        etagValue,
    ) {
        const data = {
            currentParentId,
            underInitiativeOrLinkId: underId || null,
            moveToGroupId: moveToGroupId || null,
        };

        return this.$http.post<any>(
            this.buildUrl(`${initiativeId}/moveGuest?underInitiativeId=${underId}&newParentId=${parentId}`),
            data,
            this.getETagConfig(etagValue),
        );
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public moveInitiative(
        initiativeId: string,
        currentParentId: string,
        underId: string,
        parentId: string,
        moveToGroupId: string,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            currentParentId,
            newParentId: parentId || null,
            underInitiativeId: underId || null,
            underInitiativeOrLinkId: underId || null,
            moveToGroupId: moveToGroupId || null,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${initiativeId}/move`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateFieldValue(
        fieldId: string,
        value,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            value,
            solutionBusinessReportId,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${fieldId}/value`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    @bindThis
    public updateFieldValueNew(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        fieldDefinitionKey: string,
        value: string,
        isMultiValueField: boolean,
        multiValueSeparator: string,
    ) {
        const data = {
            value,
            isMultiValueField,
            multiValueSeparator,
        };

        return this.$http.post<Initiative>(
            this.buildUrl(
                `${getWorkflowVersionIdPath(workflowVersionId)}/${getInitiativeIdPath(
                    initiativeId,
                )}/${fieldDefinitionKey}/value`,
            ),
            data,
        );
    }

    @bindThis
    public updateMultipleFieldsValues(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        values: Record<string, string>,
        isMadeByTonkean: boolean = false,
    ) {
        // By default, Angular removes undefined values from the request, so we need to replace them with nulls.
        const fixedValues = Object.fromEntries(
            Object.entries(values).map(([fieldDefinitionKey, value]) => {
                return [fieldDefinitionKey, value === undefined ? null : value];
            }),
        );

        const data = {
            values: fixedValues,
            isMadeByTonkean: isMadeByTonkean,
        };

        return this.$http.post<Initiative>(
            this.buildUrl(`${getWorkflowVersionIdPath(workflowVersionId)}/${getInitiativeIdPath(initiativeId)}/values`),
            data,
        );
    }

    @bindThis
    public getCTAActionsEntitiesSummary(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        formIds: TonkeanId<TonkeanType.FORM>[],
        customTriggerIds: TonkeanId<TonkeanType.CUSTOM_TRIGGER>[],
    ) {
        const data = {
            formIds,
            customTriggerIds,
        };

        return this.$http.post<GetCTAActionsEntitiesSummaryResponse>(
            this.buildUrl(`${workflowVersionId}/ctaActionsEntitiesSummary`),
            data,
        );
    }

    @bindThis
    public getCTAActionsEntitiesSummaryByWorkflowVersionType(
        workflowVersionType: WorkflowVersionType,
        formIds: TonkeanId<TonkeanType.FORM>[],
        customTriggerIds: TonkeanId<TonkeanType.CUSTOM_TRIGGER>[],
    ) {
        const data = {
            formIds,
            customTriggerIds,
        };

        return this.$http.post<GetCTAActionsEntitiesSummaryResponse>(
            this.buildUrl(`${workflowVersionType}/ctaActionsEntitiesSummary`),
            data,
        );
    }

    @bindThis
    public notifyProjectAccess(projectId: TonkeanId<TonkeanType.PROJECT>) {
        const data = {};
        return this.$http.post<{}>(this.buildUrl(`${projectId}/notifyAccess`), data);
    }

    /**
     * Update a multiple field with values
     */
    @bindThis
    public createFieldValues(fieldDefinitionId: string, values, initiativeOrLinkId: string, etagValue) {
        const data = {
            values,
        };

        return this.$http.post<any>(
            this.buildUrl(`${initiativeOrLinkId}/${fieldDefinitionId}/values`),
            data,
            this.getETagConfig(etagValue),
        );
    }

    /**
     * Update a multiple field with values
     */
    @bindThis
    public updateFieldValues(
        fieldId: string,
        _fieldDefinitionId: string,
        values,
        _initiativeOrLinkId: string,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            values,
            solutionBusinessReportId,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${fieldId}/values`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeState(
        initiativeId: string,
        status,
        updateText,
        eta,
        stateText,
        stateColor,
        atMentionIds,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data: Record<string, any> = {
            updateText,
            status,
            eta,
            atMentionIds,
            solutionBusinessReportId,
        };

        if (stateText) {
            data.stateText = stateText;
            data.stateColor = stateColor;
        }

        const url = this.optionalSolutionBusinessReportUrl(
            `${initiativeId}/updateData`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    @bindThis
    public updateInitiativeStatus(
        initiativeId: string,
        status: string,
        project: Project,
        workflowVersion: WorkflowVersion,
    ) {
        const state = getProjectStates(workflowVersion, project).find((state) => state.label === status);
        if (state) {
            return this.updateInitiativeState(initiativeId, state.type, '', null, state.label, state.color, null, null);
        } else {
            return Promise.reject("Cannot update status because it's not a valid status");
        }
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateNextReminder(initiativeId: string, date, shouldRecalculate, etagValue) {
        const data = {
            value: date,
            shouldRecalculate,
        };

        return this.$http.post<any>(
            this.buildUrl(`${initiativeId}/nextGatherUpdates`),
            data,
            this.getETagConfig(etagValue),
        );
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public estimateNextReminder(initiativeId: string, personId: string) {
        return this.$http.get<any>(this.buildUrl(`${initiativeId}/${personId}/estimateNextReminder`));
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public removeFromInbox(initiativeId: string) {
        const data = {
            value: false,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/inInbox`), data);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateInitiativeOwner(
        initiativeId: string,
        personId: string | null,
        customMessage,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            value: personId,
            setOwnerCustomMessage: customMessage,
            solutionBusinessReportId,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${initiativeId}/owner`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updatePersonLicense(projectId: string, personId: string, isLicensed, addSeatIfNeeded) {
        addSeatIfNeeded = addSeatIfNeeded || false;
        return this.$http.post<any>(
            this.buildUrl(`${projectId}/${personId}/licensed?value=${isLicensed}&addSeatIfNeeded=${addSeatIfNeeded}`),
            undefined,
        );
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public addPeopleToFunction(initiativeId: string, functionId: string, people) {
        const data = {
            people,
        };

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/${functionId}/people`), data);
    }

    /**
     * Deletes an entity by id.
     * @param id - The item ID.
     * @param params - Custom params for the entity deletion.
     */
    @bindThis
    public deleteEntity(id: string, params) {
        const options = params ? { params } : undefined;
        return this.$http.delete<any>(this.buildUrl(id), options);
    }

    /**
     * Deletes all given initiative ids.
     */
    @bindThis
    public deleteInitiatives(projectId: string, initiativeIds) {
        if (!initiativeIds || !initiativeIds.length) {
            return this.$q.resolve();
        }
        const data = {
            initiativeIds,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/deleteInitiatives`), data);
    }

    /**
     * Deletes an entity by id.
     * @param itemId The item ID.
     */
    @bindThis
    public removePersonFromInitiative(initiativeId: string, personId: string) {
        return this.$http.delete<any>(this.buildUrl(`${initiativeId}/${personId}`));
    }

    /**
     * Deletes an entity by id.
     * @param itemId The item ID.
     */
    @bindThis
    public removeFunctionFromInitiative(initiativeId: string, funcId: string) {
        return this.$http.delete<any>(this.buildUrl(`${initiativeId}/${funcId}`));
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updatePersonStatus(initiativeId: string, personId: string, status, updateText, etaDate) {
        const data: Record<string, any> = {
            status,
        };
        if (updateText && updateText.length) {
            data.updateText = updateText;
        }
        if (etaDate) {
            data.eta = etaDate.getTime();
        }

        return this.$http.post<any>(this.buildUrl(`${initiativeId}/${personId}/status`), data);
    }

    /**
     * Archives or un-archives an initiative.
     */
    @bindThis
    public updateInitiativeArchiveState(
        initiativeId: string,
        isArchive,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            value: isArchive,
        };

        const url = this.optionalSolutionBusinessReportUrl(
            `${initiativeId}/archived`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );

        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     * Archives or un-archives an initiative.
     */
    @bindThis
    public updateInitiativeArchiveStateBulk(projectId: string, initiativeIds, isArchive) {
        const data = {
            initiativeIds,
            value: isArchive,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/bulkArchive`), data);
    }

    /**
     * Post the tags to a question
     */
    @bindThis
    public postFeedback(subject, description) {
        const data: Record<string, any> = { text: description };

        if (subject && subject.length) {
            data.subject = subject;
        }
        return this.$http.post<any>(this.buildUrl('feedback'), data);
    }

    /**
     * Sends invites to people.
     */
    @bindThis
    public sendInvites(
        projectId: string,
        users,
        message,
        isManual,
        groupIds,
        noOp: boolean,
        tonkeanRoles: SCIMTonkeanRole[] = [SCIMTonkeanRole.PROCESS_CONTRIBUTOR, SCIMTonkeanRole.SYSTEM_USER],
    ) {
        const data = {
            users,
            message,
            groupIds: groupIds && groupIds.length ? groupIds : null,
            tonkeanRoles,
        };

        return this.$http.post<InviteUsersResponse>(this.buildUrl(`${projectId}/invite`), data, {
            params: { isManual, noOp },
        });
    }

    /**
     * Sends invites to people.
     */
    @bindThis
    public createSampleData(projectId: string, usecases) {
        return this.$http.post<any>(this.buildUrl(`${projectId}/sampleData`), { usecases });
    }

    /**
     * Update the user profile
     */
    @bindThis
    public updateProfileInfo(
        name,
        title,
        messagePreferences,
        timezone,
        metadata,
        preferEmail,
        preferImportantNotificationsAsUser,
    ) {
        const data = {
            name,
            title,
            messagePreferences: messagePreferences || [],
            timezone,
            preferEmail,
            preferImportantNotificationsAsUser,
            metadata: metadata || {},
        };

        return this.$http.post<any>(this.buildUrl('me'), data);
    }

    /**
     * Updates the user's last login (and timezone).
     */
    @bindThis
    public updateUserLastLogin(timezone) {
        const data = {
            timezone,
        };

        return this.$http.post<any>(this.buildUrl('me/lastLogin'), data);
    }

    /**
     * Create insight for inquiryId
     */
    @bindThis
    public updateProfileMetadata(value) {
        const data = {
            value,
        };

        return this.$http.post<any>(this.buildUrl('me/metadata'), data);
    }

    @bindThis
    public updateMessagePreferences(messagePreferences, messageId: string) {
        const options = messageId ? { params: { message: messageId } } : undefined;
        return this.$http.post<any>(
            this.buildUrl('messagePreferences'),
            { messagePreferences: messagePreferences || [] },
            options,
        );
    }

    @bindThis
    public updateUserMessagePreferences(messagePreferences, messageId: string) {
        const options = messageId ? { params: { message: messageId } } : undefined;
        return this.$http.post<any>(
            this.buildUrl('me/messagePreferences'),
            { messagePreferences: messagePreferences || [] },
            options,
        );
    }

    /**
     * gets the user messagePreferences without auth
     */
    @bindThis
    public getMessagePreferences(messageId: string) {
        const options = messageId ? { params: { message: messageId } } : undefined;
        return this.$http.get<any>(this.buildUrl('me/messagePreferences'), options);
    }

    /**
     * Upload avatar
     */
    @bindThis
    public setAvatar(file) {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': 'image/png',
            },
        };

        return this.$http.post<any>(this.buildUrl('me/avatar'), file, options);
    }

    /**
     * Uploads image of a template.
     */
    @bindThis
    public uploadTemplateModuleImage(file: string | ArrayBuffer | null, templateId: string) {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': 'image/png',
            },
        };

        return this.$http.post<any>(this.buildUrl(`${templateId}/uploadPreviewImage`), file, options);
    }

    /**
     * Uploads a worker static asset.
     */
    @bindThis
    public uploadWorkerStaticAsset(groupId: string, file, assetDisplayName, customTriggerId: string, contentType) {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': contentType,
            },
            params: {
                assetDisplayName,
                customTriggerId,
            },
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/staticAssets`), file, options);
    }

    /**
     * Copies a custom trigger from one group to destination group.
     */
    @asyncApiJob
    @bindThis
    public copyCustomTriggerToGroup(customTriggerId: string, destinationGroupId: string, workflowVersionId: string) {
        const data = {
            customTriggerId,
            destinationGroupId,
        };

        return this.$http.post<any>(this.buildUrl(`${workflowVersionId}/copyCustomTriggerToGroup`), data);
    }

    /**
     * Gets custom trigger dependencies display name map.
     */
    @bindThis
    public getCustomTriggerDependenciesDisplayNameMap(customTriggerId: string, workflowVersionId: string) {
        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${customTriggerId}/dependenciesDisplayNameMap`));
    }

    /**
     * Posts a comment on an activity entity.
     * @param entityId - The entity ID.
     * @param message The comment text.
     * @param updateInitiativeId - The initiative id to update if needed. Used mostly to tell the server to update the initiative's lastStatusComment date.
     */
    @bindThis
    public postComment(entityId: string, message, updateInitiativeId: string, atMentionIds) {
        const data = {
            text: message,
            updateInitiativeId: updateInitiativeId || null,
            atMentionIds,
        };

        return this.$http.post<{ comment: Comment; activity?: Activity }>(this.buildUrl(`${entityId}/comments`), data);
    }

    /**
     * Posts a comment on an activity entity.
     * @param entityId - The entity ID.
     * @param message The comment text.
     * @param updateInitiativeId - The initiative id to update if needed. Used mostly to tell the server to update the initiative's lastStatusComment date.
     */
    @bindThis
    public postCommentWithInitiative(
        entityId: string,
        message,
        updateInitiativeId: string,
        atMentionIds,
        filterOnOtherTargets,
    ) {
        const data = {
            text: message,
            updateInitiativeId: updateInitiativeId || null,
            atMentionIds,
            filterOnOtherTargets: filterOnOtherTargets,
        };

        return this.$http.post<{ comment: Comment; activity?: Activity }>(
            this.buildUrl(`${updateInitiativeId}/${entityId}/comments`),
            data,
        );
    }

    /**
     * Creates or updates the organization license.
     * @param organizationId The org ID.
     * @param ,organizationName:{String},ccEmail:{String},address:{String},city:{String},zipCode:{String},state:{String},country:{String},phoneNumber:{String}}} licenseData
     */
    @bindThis
    public createOrUpdateLicense(organizationId: string, licenseData) {
        return this.$http.post<any>(this.buildUrl(`${organizationId}/license`), licenseData);
    }

    /**
     * Creates or updates the organization license.
     * @param organizationId The org ID.
     * @param ,organizationName:{String},ccEmail:{String},address:{String},city:{String},zipCode:{String},state:{String},country:{String},phoneNumber:{String}}} licenseData
     */
    @bindThis
    public moveToPricingV2(projectId: string, isTest) {
        let suffix = '';
        if (isTest) {
            suffix = '?testFlight=true';
        }
        return this.$http.post<any>(this.buildUrl(`${projectId}/moveToPricingV2${suffix}`), undefined);
    }

    /**
     * Deletes an entity by id.
     * @param itemId The item ID.
     */
    @bindThis
    public deleteItem(itemId: string) {
        return this.$http.delete<any>(this.buildUrl(itemId));
    }

    /**
     * Deletes a comment by id.
     * @param commentId - The comment id.
     * @param updateInitiativeId - The initiative id to update if needed. Used mostly to tell the server to update the initiative's lastStatusComment date.
     */
    @bindThis
    public deleteComment(commentId: string, updateInitiativeId: string) {
        let suffix = '';
        if (updateInitiativeId) {
            suffix = `?updateInitiativeId=${updateInitiativeId}`;
        }

        return this.$http.delete<any>(this.buildUrl(commentId + suffix));
    }

    /**
     * Gets a token to be used by the local collector
     */
    @bindThis
    public getDeployJson() {
        return this.$http.get<any>('/deploy.json');
    }

    /**
     * Gets a existing worker form
     */
    @bindThis
    public getWorkerForm(workflowVersionId: string, formId: string, referral?: FormReferralData) {
        const config = {
            params: {
                ...referral,
            },
        };

        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${formId}`), config);
    }

    /**
     * Gets a existing worker form for initative
     */
    @bindThis
    public getWorkerFormForInitiative(
        workflowVersionId: string,
        formId: string,
        initiativeId: string,
        referral?: FormReferralData,
    ) {
        const config = {
            params: {
                ...referral,
            },
        };

        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${initiativeId}/${formId}`), config);
    }

    /**
     * Gets a existing worker form
     */
    @bindThis
    public getDraftWorkerForm(formId: string, referral?: FormReferralData) {
        const config = {
            params: {
                ...referral,
            },
        };
        return this.$http.get<any>(this.buildUrl(`${formId}/draft`), config);
    }

    /**
     * Gets a existing worker create form for inner items
     */
    @bindThis
    public getPublishedWorkerForm(
        formId: string,
        referral?: { referringWorkflowVersion: string; referringCustomTrigger: string; referringInitiative: string },
    ) {
        const config = {
            params: {
                ...referral,
            },
        };
        return this.$http.get<any>(this.buildUrl(`${formId}/published`), config);
    }

    /**
     * Gets a existing worker update form and create form for initiatives
     */
    @bindThis
    public getPublishedWorkerFormForInitiative(
        formId: string,
        referral: { referringWorkflowVersion: string; referringCustomTrigger: string; referringInitiative: string },
    ) {
        const config = {
            params: {
                referringWorkflowVersion: referral.referringWorkflowVersion,
                referringCustomTrigger: referral.referringCustomTrigger,
            },
        };
        return this.$http.get<any>(this.buildUrl(`${formId}/${referral.referringInitiative}/published`), config);
    }

    /**
     * Gets all workflow version forms.
     */
    @bindThis
    public getAllWorkerForm(
        workflowVersionId: string,
        searchText?: string,
        formType?: FormType.CREATE | FormType.UPDATE,
    ) {
        const config = {
            params: {
                searchText,
                formType,
            },
        };

        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/forms`), config);
    }

    /**
     * Gets all workflow version forms.
     */
    @bindThis
    public getInitiativeFormsSubmissions(initiativeId: string) {
        return this.$http.get<any>(this.buildUrl(`${initiativeId}/formsSubmissions`));
    }

    /**
     * Gets summary of forms in a group and environment.
     */
    @bindThis
    public getGroupEnvironmentFormsSummary(
        groupId: string,
        workflowVersionType: WorkflowVersionType,
        searchText?: string,
        formType?: FormType.CREATE | FormType.UPDATE,
        shouldFilterOutUploadFiles: boolean = false,
    ): Promise<{ entities: GroupEnvironmentFormSummary[] }> {
        const config = {
            params: {
                searchText,
                formType,
                shouldFilterOutUploadFiles,
            },
        };

        return this.$http.get<{ entities: GroupEnvironmentFormSummary[] }>(
            this.buildUrl(`${groupId}/${workflowVersionType}/forms`),
            config,
        );
    }

    /**
     * Gets solution business reports forms summaries for a workflow folder
     */
    @bindThis
    public getWorkflowFolderFormsSummary(
        workflowFolderId: string,
    ): Promise<{ entities: SolutionBusinessReportFormSummary[] }> {
        return this.$http.get<{ entities: SolutionBusinessReportFormSummary[] }>(
            this.buildUrl(`${workflowFolderId}/forms`),
        );
    }

    /**
     * Gets summary of forms in a group for solutions business report;
     */
    @bindThis
    public getSolutionBusinessReportGroupFormsSummary(
        projectId: string,
        groupId: string,
        solutionBusinessReportId: string,
        workflowVersionType: WorkflowVersionType,
        searchText?: string,
        formType?: FormType.CREATE | FormType.UPDATE,
        shouldFilterOutUploadFiles: boolean = false,
    ): Promise<{ entities: GroupEnvironmentFormSummary[] }> {
        const config = {
            params: {
                groupId,
                searchText,
                formType,
                shouldFilterOutUploadFiles,
            },
        };

        return this.$http.get<{ entities: GroupEnvironmentFormSummary[] }>(
            this.buildUrl(
                `${projectId}/${solutionBusinessReportId}/${workflowVersionType}/getSolutionBusinessReportForms`,
            ),
            config,
        );
    }

    /**
     * Update the accessible forms (actions) per group in a solution business report
     */
    @bindThis
    public updateSolutionBusinessReportGroupFormsAccessibility(
        projectId: string,
        groupId: string,
        solutionBusinessReportId: string,
        formIds: string[],
    ): Promise<any> {
        const data = { formIds };
        return this.$http.post<void>(
            this.buildUrl(`${projectId}/${solutionBusinessReportId}/${groupId}/updateSolutionBusinessReportForms`),
            data,
        );
    }

    public getAllFormsByWorkflowFolderId(workflowFolderId: string, workflowVersionType: WorkflowVersionType) {
        return this.$http.get<any>(this.buildUrl(`${workflowVersionType}/${workflowFolderId}/forms`));
    }

    /**
     * Create a worker form
     * */
    @bindThis
    public createWorkerForm(
        groupId: string,
        formType,
        definition,
        disabled,
        displayName,
        descriptionExpression,
        slackCommand,
        formQuestionType,
        collapseDescriptionByDefault: boolean,
        expandableDescriptionExpression: TonkeanExpressionDefinition,
        expandableDescriptionLabel: string,
    ) {
        const data = {
            formType,
            definition,
            disabled,
            displayName,
            descriptionExpression,
            slackCommand,
            formQuestionType,
            collapseDescriptionByDefault,
            expandableDescriptionExpression,
            expandableDescriptionLabel,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/forms`), data);
    }

    /**
     * Delete a worker form
     * */
    @bindThis
    public deleteWorkerForm(formId: string) {
        return this.$http.delete<any>(this.buildUrl(formId));
    }

    /**
     * Update a worker form
     * */
    @bindThis
    public updateWorkerForm(
        formId: string,
        formType,
        definition,
        disabled,
        displayName,
        descriptionExpression,
        slackCommand,
        formQuestionType,
        collapseDescriptionByDefault: boolean,
        smartSearchEnabled: boolean,
        homepageEnabled: boolean,
        expandableDescriptionExpression: TonkeanExpressionDefinition,
        expandableDescriptionLabel: string,
    ) {
        const data = {
            formType,
            definition,
            disabled,
            displayName,
            descriptionExpression,
            slackCommand,
            formQuestionType,
            collapseDescriptionByDefault,
            expandableDescriptionExpression,
            expandableDescriptionLabel,
            smartSearchEnabled,
            homepageEnabled,
        };

        return this.$http.post<any>(this.buildUrl(formId), data);
    }

    /**
     * Answer a worker create form
     * */
    @bindThis
    public answerWorkerCreateForm(
        workflowVersionId: string,
        formVersionType: WorkflowVersionType,
        formId: string,
        initiativeId: string,
        workerRunId: string,
        customTriggerId: string,
        sessionId: string,
        fields,
        workerRunLogicId: string,
        isContinuousForm: boolean,
        ignoreNonRestrictingErrors: boolean,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
        parentInitiativeId?: string,
    ) {
        const data = this.getAnswerFormBody(
            workflowVersionId,
            formVersionType,
            formId,
            initiativeId,
            workerRunId,
            customTriggerId,
            sessionId,
            fields,
            workerRunLogicId,
            isContinuousForm,
            ignoreNonRestrictingErrors,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
            parentInitiativeId,
            undefined,
        );

        const url = this.optionalSolutionBusinessReportUrl(
            `${getWorkflowVersionIdPath(workflowVersionId)}/${getFormIdPath(formId)}/answer`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data);
    }

    /**
     * Answer a worker create inner items form
     * */
    @bindThis
    public answerWorkerCreateInnerItemsForm(
        workflowVersionId: string,
        formVersionType: WorkflowVersionType,
        formId: string,
        initiativeId: string,
        workerRunId: string,
        customTriggerId: string,
        sessionId: string,
        fields,
        workerRunLogicId: string,
        isContinuousForm: boolean,
        ignoreNonRestrictingErrors: boolean,
        parentInitiativeId: string,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
        createdByCustomTriggerId?: string,
    ) {
        const data = this.getAnswerFormBody(
            workflowVersionId,
            formVersionType,
            formId,
            '',
            workerRunId,
            customTriggerId,
            sessionId,
            fields,
            workerRunLogicId,
            isContinuousForm,
            ignoreNonRestrictingErrors,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
            '',
            createdByCustomTriggerId,
        );

        const url = this.optionalSolutionBusinessReportUrl(
            `${getWorkflowVersionIdPath(workflowVersionId)}/${getInitiativeIdPath(parentInitiativeId)}/${getFormIdPath(
                formId,
            )}/answer`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data);
    }

    /**
     * Answer a worker create inner items form
     * */
    @bindThis
    public answerWorkerUpdateForm(
        workflowVersionId: string,
        formVersionType: WorkflowVersionType,
        formId: string,
        initiativeId: string,
        workerRunId: string,
        customTriggerId: string,
        sessionId: string,
        fields,
        workerRunLogicId: string,
        isContinuousForm: boolean,
        ignoreNonRestrictingErrors: boolean,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
        parentInitiativeId?: string,
    ) {
        const data = this.getAnswerFormBody(
            workflowVersionId,
            formVersionType,
            formId,
            '',
            workerRunId,
            customTriggerId,
            sessionId,
            fields,
            workerRunLogicId,
            isContinuousForm,
            ignoreNonRestrictingErrors,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
            parentInitiativeId,
            undefined,
        );

        const url = this.optionalSolutionBusinessReportUrl(
            `${getWorkflowVersionIdPath(workflowVersionId)}/${getInitiativeIdPath(initiativeId)}/${getFormIdPath(
                formId,
            )}/answerUpdate`,
            throughSolutionBusinessReport,
            solutionBusinessReportId,
        );
        return this.$http.post<any>(this.buildUrl(url), data);
    }

    private getAnswerFormBody(
        workflowVersionId: string,
        formVersionType: WorkflowVersionType,
        formId: string,
        initiativeId: string,
        workerRunId: string,
        customTriggerId: string,
        sessionId: string,
        fields,
        workerRunLogicId: string,
        isContinuousForm: boolean,
        ignoreNonRestrictingErrors: boolean,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
        parentInitiativeId?: string,
        createdByCustomTriggerId?: string,
    ) {
        const data: Record<string, any> = {
            fields,
            isContinuousForm,
            ignoreNonRestrictingErrors,
        };

        if (initiativeId && initiativeId.length) {
            data.formInitiativeId = initiativeId;
        }
        if (parentInitiativeId && parentInitiativeId.length) {
            data.parentInitiativeId = parentInitiativeId;
        }
        if (workerRunId && workerRunId.length) {
            data.workerRunId = workerRunId;
        }
        if (customTriggerId && customTriggerId.length) {
            data.customTriggerId = customTriggerId;
        }
        if (sessionId && sessionId.length) {
            data.sessionId = sessionId;
        }
        if (workerRunLogicId) {
            data.workerRunLogicId = workerRunLogicId;
        }
        if (formVersionType) {
            data.workflowVersionType = formVersionType;
        }
        if (createdByCustomTriggerId) {
            data.createdByCustomTriggerId = createdByCustomTriggerId;
        }
        return data;
    }

    /**
     * Answer a worker form
     * */
    @bindThis
    public answerItemInterface(
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        matchedItemWorkflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        workerRunId?: TonkeanId<TonkeanType.WORKER_RUN>,
    ) {
        const data = {
            customTriggerId,
            workerRunId,
            matchedItemWorkflowVersionId,
        };

        return this.$http.post<any>(
            this.buildUrl(
                `${getWorkflowVersionIdPath(workflowVersionId)}/${getInitiativeIdPath(
                    initiativeId,
                )}/${getItemInterfaceIdPath(itemInterfaceId)}/answer`,
            ),
            data,
        );
    }

    /**
     * Evaluate end sequence data
     * */
    @bindThis
    public evaluateEndSequenceData(
        groupId: TonkeanId<TonkeanType.GROUP>,
        customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        initiativeId?: string,
    ) {
        return this.$http.get<EndSequence>(
            this.buildUrl(`${groupId}/${customTriggerId}/${initiativeId}/endSequence`),
            {},
        );
    }

    /**
     * Create a new data tile for a given initiative id.
     * */
    @bindThis
    public createDataTile(
        initiativeId: string,
        tileValue,
        fieldDefinitionId: string,
        etagValue,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ) {
        const data = {
            fieldDefinitionId,
            value: tileValue,
            solutionBusinessReportId,
        };

        if (fieldDefinitionId) {
            data.fieldDefinitionId = fieldDefinitionId;
        }

        if (Array.isArray(tileValue)) {
            // If it's a multivalue, access the create or update multivalue endpoint
            return this.createFieldValues(fieldDefinitionId, tileValue, initiativeId, etagValue);
        }

        const url = `${throughSolutionBusinessReport ? `${solutionBusinessReportId}/` : ''}${initiativeId}/tiles`;
        return this.$http.post<any>(this.buildUrl(url), data, this.getETagConfig(etagValue));
    }

    /**
     * Collects a project integration now.
     */
    @bindThis
    public collectProjectIntegrationNow(projectIntegrationId: string) {
        return this.$http.post<any>(this.buildUrl(`${projectIntegrationId}/collectNow`), undefined);
    }

    /**
     * Get all existing data fields in the current project.
     * @param workflowVersionId Workflow version id
     * @param customTriggersIds Optional array of the related custom triggers.
     */
    @bindThis
    public getFieldDefinitions(
        workflowVersionId: string,
        customTriggersIds?: string[],
    ): Promise<{ entities: FieldDefinition[]; relatedEntities: Record<any, unknown> }> {
        return this.$http.post(this.buildUrl(`${workflowVersionId}/fieldDefinitions`), { customTriggersIds });
    }

    @bindThis
    public getFieldDefinitionById(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION>,
    ): Promise<FieldDefinition> {
        return this.$http.get(this.buildUrl(`${workflowVersionId}/${fieldDefinitionId}`), {});
    }

    @bindThis
    public getFieldDefinitionByIdAndWorkflowVersionType(
        fieldDefinitionId: string,
        workflowVersionType: WorkflowVersionType,
    ): Promise<any> {
        return this.$http.get<any>(this.buildUrl(`${fieldDefinitionId}`), {
            params: { workflowVersionType },
        });
    }

    @bindThis
    public getFieldDefinitionsByIdsAndByWorkflowVersionType(
        fieldDefinitionIds: FieldDefinition['id'][],
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowVersionType: WorkflowVersionType,
    ): Promise<any> {
        return this.$http.post<{ fieldDefinitions: FieldDefinition[] }>(
            this.buildUrl(`${projectId}/fieldDefinitions`),
            {
                workflowVersionType,
                fieldDefinitionIds,
            },
        );
    }

    @bindThis
    public getFieldDefinitionsByWorkflowVersionType(
        groupId: TonkeanId<TonkeanType.GROUP>,
        workflowVersionType: WorkflowVersionType | undefined,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    ): Promise<{
        entities: FieldDefinition[];
    }> {
        if (workflowVersionType) {
            return this.$http.get(this.buildUrl(`${groupId}/${workflowVersionType}/fieldDefinitions`), {});
        } else {
            return this.getFieldDefinitions(workflowVersionId);
        }
    }

    /**
     * Get field definitions from multiple workflow versions
     * Note: you should only use workflow versions from the same type, otherwise the data might get corrupted by entity helper
     */
    @bindThis
    public getFieldDefinitionsByWorkflowVersions(
        projectId: string,
        workflowVersionIds: string[],
        skip: number,
        limit: number,
    ): Promise<any> {
        const data = { workflowVersionIds };
        const params = { skip, limit };
        return this.$http.post<any>(this.buildUrl(`${projectId}/fieldDefinitionsByVersions`), data, { params });
    }

    /**
     * Get field definitions that participate in a specific item interface
     */
    @bindThis
    public getWidgetsConfigurationsFieldDefinitions(
        configurations: WidgetConfiguration[],
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        isDraft: boolean,
    ): Promise<{ fieldDefinitions: FieldDefinition[] }> {
        const data = {
            configurations,
            workflowVersionId,
            isDraft,
        };

        return this.$http.post<{
            fieldDefinitions: FieldDefinition[];
        }>(this.buildUrl(`${getProjectIdPath(projectId)}/widgetsConfigurationsFieldDefinitions`), data);
    }

    /**
     * Gets the integration entities and fields configuration from server.
     * */
    @bindThis
    public getIntegrationEntitiesAndFieldsConfiguration(projectId: string) {
        return this.$http.get<any>(this.buildUrl(`${projectId}/integrationsEntityMap`));
    }

    /**
     * Create a new field definition.
     * */
    @bindThis
    public createFieldDefinition(
        targetType,
        name,
        description,
        type,
        ranges,
        definition,
        projectIntegrationId: string,
        groupId: string,
        fieldType,
        values,
        updateable,
        isImportant,
        displayConfiguration,
        manualValue,
        notConfigured,
        populationDisabled,
        isHidden,
        systemUtilized,
        forceUseDefinitionMetadata,
        updateFieldPermissions,
        idRelationField,
        isMultiValueField,
        inputMultiValueSeparator,
        outputMultiValueSeparator,
        dropdownSource,
        allowAddDropdownOptions,
        dropdownOptionsFromFieldDefinitionId: string,
        dropdownOptionsFromFieldMetadataSyncFieldDefinition,
        formulaData,
        fieldGroupName: string,
        linkedCustomTriggerId: string,
        secondaryId: string,
        suggestedValue?: string,
        showManualOptionsNoResults?: boolean,
        defaultValueFieldDefinitionId?: string,
        urlLabel?: string,
    ) {
        const data: Record<string, any> = {
            name,
            description,
            type,
            targetType,
            ranges: ranges || [],
            definition: definition || {},
            projectIntegrationId,
            fieldType,
            possibleValues: values,
            updateable,
            groupId,
            isImportant,
            isConfigured: !notConfigured,
            populationDisabled: !!populationDisabled,
            isHidden,
            systemUtilized,
            forceUseDefinitionMetadata: !!forceUseDefinitionMetadata,
            updateFieldPermissions,
            idRelationField,
            isMultiValueField,
            inputMultiValueSeparator,
            outputMultiValueSeparator: displayConfiguration?.outputMultiValueSeparator || outputMultiValueSeparator,
            dropdownSource,
            suggestedValue,
            allowAddDropdownOptions,
            dropdownOptionsFromFieldDefinitionId,
            dropdownOptionsFromFieldMetadataSyncFieldDefinition,
            formulaData: formulaData !== undefined ? Object.fromEntries(formulaData) : null,
            fieldGroupName,
            linkedCustomTriggerId,
            secondaryId,
            showManualOptionsNoResults,
            defaultValueFieldDefinitionId,
            urlLabel,
        };

        if (displayConfiguration) {
            data.displayAs = displayConfiguration?.displayAs;
            data.compareTimeframe = displayConfiguration?.compareTimeframe;
            data.isIncrementNegative = displayConfiguration?.isIncrementNegative;

            if (data.displayAs === 'Number' && !!displayConfiguration?.numberFieldDecimalPlaces) {
                data.numberFieldDecimalPlaces = {
                    decimalPlaces:
                        displayConfiguration.numberFieldDecimalPlaces.decimalPlaces !== undefined
                            ? displayConfiguration.numberFieldDecimalPlaces.decimalPlaces
                            : 3,
                    includeTrailingZeros:
                        displayConfiguration.numberFieldDecimalPlaces.includeTrailingZeros !== undefined
                            ? !!displayConfiguration.numberFieldDecimalPlaces.includeTrailingZeros
                            : false,
                };
            }

            data.displayFormat = displayConfiguration?.displayFormat;
            data.displayFormatPrefix = displayConfiguration?.displayFormatPrefix;
            data.displayFormatPostfix = displayConfiguration?.displayFormatPostfix;
            data.fieldLabel = displayConfiguration?.fieldLabel;
        }

        if (manualValue) {
            data.manualValue = manualValue;
        }

        return this.$http.post<any>(this.buildUrl(`${groupId}/fieldDefinitions`), data);
    }

    /**
     * Validates a formula expression.
     */
    @bindThis
    public validateFormulaExpression(
        _projectId: string,
        formulaExpression,
        formulaForFieldDefinitionType,
        groupId: string,
        customFormulaExpression,
        variables,
        allVariableTokensAreAllowed,
        workflowVersionId: string,
    ) {
        const data = {
            formulaExpression,
            formulaForFieldDefinitionType,
            groupId,
            customFormulaExpression,
            variables,
            allVariableTokensAreAllowed,
        };

        return this.$http.post<any>(this.buildUrl(`${workflowVersionId}/validateFormulaExpression`), data);
    }

    /**
     * Clears the activities of the given workflow version from their workflow version relation.
     */
    @bindThis
    public clearDraftVersionChangesActivities(groupId: string) {
        const data = {};

        return this.$http.post<any>(this.buildUrl(`${groupId}/clearDraftVersionChangesActivities`), data);
    }

    /**
     * Get all templates
     */
    @bindThis
    public getAllTemplates(versionType, filter, title, onBehalfOf, projectId) {
        const params = {
            versionType,
            filter,
            title,
            onBehalfOf,
            projectId,
        };

        return this.$http.get<any>(this.buildUrl('getAllTemplates'), { params });
    }

    /**
     * Get example templates for anonymous browsing
     */
    @bindThis
    public getPublishedTemplates() {
        return this.$http.get<any>(this.buildUrl('getPublishedTemplates'));
    }

    /**
     * Get all categories
     */
    @bindThis
    public getAllTemplateCategories() {
        const params = {};
        return this.$http.get<any>(this.buildUrl('getAllTemplateCategories'), { params });
    }

    @bindThis
    public getTemplatesDependencies(templateId: string) {
        return this.$http.get<any>(this.buildUrl(`${templateId}/requiredDependencies`));
    }

    /**
     * Duplicates a group.
     */
    @asyncApiJob
    @bindThis
    public duplicateGroup(
        groupId: string,
        name,
        visibilityType,
        members,
        notificationType,
        notificationChannel,
        visibleToOwner,
        metadata,
        shouldSendGatherUpdates,
        canAccessWithToken,
        isExample,
        innerTracksTemplate,
        writePermissionType,
        writePermissionMembers,
        dashboardHidden,
        blockWorkerErrorAlerts,
        workerType,
        defaultActorId: string,
        archiveDoneAfterDays,
        deleteArchivedAfterDays,
        owners,
        workflowFolderId: string,
        workflowFolderCategoryId: string,
        urlLabel?: string,
    ) {
        const data: Record<string, any> = {
            name,
            visibilityType: visibilityType.toUpperCase(),
            members,
            notifications: {
                type: notificationType,
                channelId: notificationType === this.groupNotifications.public ? notificationChannel : null,
                channelName: notificationType === this.groupNotifications.private ? notificationChannel : null,
            },
            visibleToOwner: !!visibleToOwner,
            metadata,
            shouldSendGatherUpdates,
            canAccessWithToken,
            isExample,
            writePermissionType,
            writePermissionMembers,
            dashboardHidden,
            blockWorkerErrorAlerts,
            workerType,
            defaultActorId,
            archiveDoneAfterDays,
            deleteArchivedAfterDays,
            owners,
            workflowFolderId,
            workflowFolderCategoryId,
            urlLabel,
        };

        if (innerTracksTemplate) {
            data.innerTracksTemplate = innerTracksTemplate;
        }

        return this.$http.post<any>(this.buildUrl(`${groupId}/duplicate`), data);
    }

    /**
     * Create bot by template id
     */
    @bindThis
    public createBotByTemplateId(
        projectId: string,
        templateId: string,
        oldIntegrationIdToNewIntegrationIdMapping,
        oldPersonIdToNewPersonIdMapping,
        externalFieldIdToFieldNameMapping,
        featuresMap,
        workflowFolderId: string,
        workflowFolderCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
    ) {
        const data = {
            templateId,
            oldIntegrationIdToNewIntegrationIdMapping,
            oldPersonIdToNewPersonIdMapping,
            externalFieldIdToFieldNameMapping,
            workflowFolderId,
            workflowFolderCategoryId,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/${workflowFolderId}/createGroupFromTemplate`), data);
    }

    /**
     * Create entity by creation json
     */
    @bindThis
    public createEntityFromCreationJson(projectId: string, creationJson, creationJsonDependencies?) {
        const data = {
            creationJson,
            creationJsonDependencies,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/createEntityFromCreationJson`), data);
    }

    /**
     * Create multiple new field definitions.
     * */
    @bindThis
    public createMultipleFieldDefinitions(groupId: string, definitions, dontOverrideIndex) {
        const data = {
            definitions,
            dontOverrideIndex: dontOverrideIndex || null,
        };

        return this.$http.post<any>(this.buildUrl(`${groupId}/multipleFieldDefinitions`), data);
    }

    /**
     * Get creation json dependencies.
     * */
    @bindThis
    public getCreationJsonDependencies(creationJson) {
        return this.$http.post<any>(this.buildUrl('creationJsonRequiredDependencies'), creationJson);
    }

    @bindThis
    public getMarketplaceItemDependencies(templateName: string, type: MarketplaceItemType) {
        const params = { templateName, type };
        return this.$http.get<{
            requiredPeople: Person[];
            requiredProjectIntegrations: ProjectIntegration[];
            requiredExternalFields: FieldDefinition[];
        }>(this.buildUrl('marketplaceItemRequiredDependencies'), { params });
    }

    /**
     * Updates a fieldDefinition by it's id, with a given name and ranges (highlighting ranges).
     * */
    @bindThis
    public updateFieldDefinition(
        fieldDefinitionId: string,
        targetType,
        name,
        description,
        type,
        ranges,
        definition,
        projectIntegrationId: string,
        _groupId: string,
        fieldType,
        values,
        updateable,
        isImportant,
        displayConfiguration,
        updateFieldPermissions,
        isMultiValueField,
        inputMultiValueSeparator,
        outputMultiValueSeparator,
        dropdownSource,
        allowAddDropdownOptions,
        dropdownOptionsFromFieldDefinitionId: string,
        dropdownOptionsFromFieldMetadataSyncFieldDefinition,
        formulaData: Map<string, any>,
        fieldGroupName: string,
        suggestedValue?: string,
        showManualOptionsNoResults?: boolean,
        secondaryId?: string,
        defaultValueFieldDefinitionId?: string,
        urlLabel?: string,
    ) {
        const data = {
            name,
            description,
            type,
            targetType,
            ranges,
            definition,
            projectIntegrationId,
            fieldType,
            possibleValues: values,
            updateable,
            isImportant,
            displayAs: displayConfiguration.displayAs,
            compareTimeframe: displayConfiguration.compareTimeframe,
            isIncrementNegative: displayConfiguration.isIncrementNegative,
            numberFieldDecimalPlaces: displayConfiguration.numberFieldDecimalPlaces,
            displayFormat: displayConfiguration.displayFormat,
            displayFormatPrefix: displayConfiguration.displayFormatPrefix,
            displayFormatPostfix: displayConfiguration.displayFormatPostfix,
            fieldLabel: this.utils.isNullOrEmpty(displayConfiguration.fieldLabel)
                ? null
                : displayConfiguration.fieldLabel,
            updateFieldPermissions,
            isMultiValueField,
            inputMultiValueSeparator,
            outputMultiValueSeparator: displayConfiguration.outputMultiValueSeparator,
            dropdownSource,
            allowAddDropdownOptions: dropdownSource === 'SYNC_FIELD' ? false : allowAddDropdownOptions,
            dropdownOptionsFromFieldDefinitionId:
                dropdownSource === 'FROM_FIELD' || dropdownSource === 'SEARCH'
                    ? dropdownOptionsFromFieldDefinitionId
                    : null,
            dropdownOptionsFromFieldMetadataSyncFieldDefinition:
                dropdownSource === 'SYNC_FIELD' ? dropdownOptionsFromFieldMetadataSyncFieldDefinition : null,
            formulaData: formulaData !== undefined ? Object.fromEntries(formulaData) : null,
            fieldGroupName,
            suggestedValue,
            showManualOptionsNoResults,
            secondaryId,
            defaultValueFieldDefinitionId,
            urlLabel,
        };

        return this.$http.post<any>(this.buildUrl(fieldDefinitionId), data);
    }

    /**
     * Deletes an existing fieldDefinition via the given fieldDefinitionId.
     * */
    @bindThis
    public deleteFieldDefinition(fieldDefinitionId: string) {
        return this.$http.delete<any>(this.buildUrl(fieldDefinitionId));
    }

    /**
     * Get all data tiles associated with the given intiative id.
     * */
    @bindThis
    public getInitiativeDataTiles(initiativeId: string) {
        return this.$http.get<any>(this.buildUrl(`${getInitiativeIdPath(initiativeId)}/tiles`));
    }

    /**
     * Get the field definition formula data by workflowVersion and field definition.
     * */
    @bindThis
    public getFieldDefinitionFormulaData(workflowVersionId: string, fieldDefinitionId: string) {
        return this.$http.get<Object>(this.buildUrl(`${workflowVersionId}/${fieldDefinitionId}/formulaData`));
    }

    /**
     * Get field definition dependencies (custom triggers and other field definitions).
     * */
    @bindThis
    public getFieldDefinitionDependencies(workflowVersionId: string, fieldDefinitionId: string) {
        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${fieldDefinitionId}/dependencies`));
    }

    /**
     * Gets the dependency levels of the project.
     * */
    @bindThis
    public getProjectDependencyLevels(workflowVersionId: string) {
        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/getProjectDependencyLevels`));
    }

    /**
     * Gets the field definitions that are impacted by given field definition.
     */
    @bindThis
    public getImpactedByFieldDefinitions(workflowVersionId: string, fieldsDefinitionIds) {
        const data = {
            fieldsDefinitionIds,
        };

        return this.$http.post<any>(this.buildUrl(`${workflowVersionId}/impactedBy`), data);
    }

    /**
     * Updates an existing tile with the new value given.
     * */
    @bindThis
    public updateDataTile(tileId: string, newValue, externalId: string, etagValue) {
        const data = {
            value: newValue,
            externalId,
        };

        return this.$http.post<any>(this.buildUrl(tileId), data, this.getETagConfig(etagValue));
    }

    /**
     * Creates a new calendar subscription
     * */
    @bindThis
    public createCalendarSubscription(projectId: string, groupId: string) {
        const data = {
            groupId: groupId || null,
        };

        return this.$http.post<any>(this.buildUrl(`${projectId}/calendar/subscribe`), data);
    }

    @bindThis
    public copyFieldDefinitionToGroup(fieldDefinitionId: string, destinationGroupId: string) {
        const data = {
            destinationGroupId,
        };

        return this.$http.post<any>(this.buildUrl(`${fieldDefinitionId}/copyFieldDefinitionToGroup`), data);
    }

    /**
     * Gets the related field definitions for the custom trigger in a specific workflow version.
     */
    @bindThis
    public getRelatedFieldDefinitionIds(
        workflowVersionId: string,
        customTriggerId: string,
    ): Promise<{ fieldDefinitionIds: string[] }> {
        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${customTriggerId}/relatedFieldDefinitionIds`));
    }

    /**
     * Gets the trained keywords for the given branch.
     */
    @bindThis
    public getTrainedKeywords(workflowVersionId: string, customTriggerId: string) {
        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${customTriggerId}/trainedKeywords`), {});
    }

    /**
     * Adds the trained keyword to the given branch.
     */
    @bindThis
    public addTrainedKeyword(customTriggerId: string, keyword, sentiment) {
        const data = {
            keyword,
            sentiment,
        };

        return this.$http.post<any>(this.buildUrl(`${customTriggerId}/trainedKeywords`), data);
    }

    /**
     * Deletes given keywords from trained data.
     */
    @bindThis
    public deleteTrainedKeyword(trainedKeywordId: string) {
        return this.$http.delete<any>(this.buildUrl(trainedKeywordId), {});
    }

    /**
     * Adds a field definition to a workflow version.
     * */
    @bindThis
    public addFieldDefinitionToWorkflowVersion(
        addToWorkflowVersionId: string,
        originalWorkflowVersionId: string,
        fieldDefinitionId: string,
    ) {
        const data = {
            fieldDefinitionId,
            originalWorkflowVersionId,
        };

        return this.$http.post<any>(this.buildUrl(`${addToWorkflowVersionId}/linkFieldDefinition`), data);
    }

    /**
     * Removes a field definition from a workflow version.
     * */
    @bindThis
    public removeFieldDefinitionFromWorkflowVersion(workflowVersionId: string, fieldDefinitionId: string) {
        const data = {};

        return this.$http.post<any>(this.buildUrl(`${workflowVersionId}/${fieldDefinitionId}/unlink`), data);
    }

    /**
     * Gets all available fields for integration of the given type.
     * */
    @bindThis
    public getAvailableExternalFields(projectIntegrationId: string, externalTypes, includeViewerFields?: boolean) {
        const params = {
            externalTypes,
            includeViewerFields,
        };

        return this.$http.get<any>(this.buildUrl(`${projectIntegrationId}/fieldMetadata`), { params });
    }

    /**
     * Gets all available fields for integration that have the given query string in their name, across all entities of integration.
     * */
    @bindThis
    public getEntityAvailableExternalFields(projectIntegrationId: string, queryString, onlyOfTypes, limit: number) {
        const params = {
            searchText: queryString || '',
            onlyOfTypes: onlyOfTypes || [],
            limit: limit || 10,
        };

        return this.$http.get<any>(this.buildUrl(`${projectIntegrationId}/autoCompleteEntities`), { params });
    }

    /**
     * Gets an external activity by the given project id, integration id and external activity id.
     */
    @bindThis
    public getExternalActivityById(
        projectId: string,
        projectIntegrationId: string,
        externalActivityId: string,
        includeOriginalEntity: boolean,
    ): Promise<ExternalActivity> {
        const config: IRequestShortcutConfig = {
            params: {
                includeOriginalEntity,
            },
        };
        return this.$http.get<any>(
            this.buildUrl(
                `${projectId}/${projectIntegrationId}/externalActivity/${encodeURIComponent(externalActivityId)}`,
            ),
            config,
        );
    }

    @bindThis
    public getFieldInstanceById(fieldInstanceId: string): Promise<any> {
        return this.$http.get<any>(this.buildUrl(`${fieldInstanceId}`));
    }

    /**
     * Gets an object of project integration id as key and an object as a value. The sub object has
     * the external activity id as key and the url as value.
     */
    @bindThis
    public getExternalActivitiesUrl(projectId: string, projectIntegrationIdToExternalActivitiesMap) {
        return this.$http.post<any>(this.buildUrl(`${projectId}/externalActivitiesUrl`), {
            projectIntegrationIdToExternalActivitiesMap,
        });
    }

    /**
     * Re populate initative.
     */
    @bindThis
    public repopulateInitiative(initiativeId: string, projectId: string) {
        return this.$http.post<any>(this.buildUrl(`${initiativeId}/populate`), { projectId });
    }

    /**
     * Re populate initatives bulk
     */
    @bindThis
    public repopulateInitiativesBulk(initiatives: Set<string>, projectId: string) {
        return this.$http.post<any>(this.buildUrl(`initiatives/populate`), { projectId, initiatives });
    }

    /**
     * Re populate field definition for all items in the list.
     */
    @bindThis
    public repopulateFieldDefinition(workflowVersionId: string, fieldDefinitionId: string) {
        return this.$http.post<any>(this.buildUrl(`${workflowVersionId}/${fieldDefinitionId}/populate`), undefined);
    }

    /**
     * Gets list of custom trigger summaries in provided workflow versions.
     *
     * @param groupId  - the group id.
     * @param workflowVersionIds  - list of workflow versions.
     * @param sequentialIdentifiers  - list of workflow version sequential identifiers.
     * @returns list of custom trigger summaries, containing it's id, name and workflow version.
     */
    @bindThis
    public getCustomTriggerSummaryByWorkflowVersion(
        groupId: string,
        workflowVersionIds: string[] | string = [],
        sequentialIdentifiers: string[] | string = [],
    ): Promise<CustomTriggerSummary[]> {
        const config: IRequestShortcutConfig = {
            params: {
                workflowVersionIds: toArray(workflowVersionIds),
                sequentialIdentifiers: toArray(sequentialIdentifiers),
            },
        };

        return this.$http.get<any>(this.buildUrl(`${groupId}/customTriggerSummaryByWorkflowVersion`), config);
    }

    @bindThis
    public createCompanyTemplate(
        groupId: string,
        title: string,
        mainColor: string,
        headerCss: string,
        description: string,
        longDescription: string,
        categories: Category[],
        environment: WorkflowVersionType,
    ): Promise<{ templateId: string; error: string }> {
        const data = {
            title,
            mainColor,
            headerCss,
            description,
            longDescription,
            environment,
            templateAdditionalCategories: categories?.map((singleCategory) => singleCategory.id),
        };

        return this.$http.post(this.buildUrl(`${groupId}/template`), data);
    }

    @bindThis
    public deleteCompanyTemplate(templateId: string): Promise<any> {
        return this.$http.delete(this.buildUrl(templateId));
    }

    /**
     * Check if an initiative has a worker run id, and if it does, get it's id.
     *
     * @param initiativeId  - the initiative id.
     * @returns object that says if the initiative has a worker run, and if it does, it's id.
     */
    @bindThis
    public getInitiativeAnyWorkerRun(
        initiativeId: string,
    ): Promise<{ hasWorkerRuns: boolean; workerRunStage?: WorkerRunStage; workerRunId?: string }> {
        const config: IRequestShortcutConfig = {
            params: {
                initiativeId,
            },
        };

        return this.$http.get<any>(this.buildUrl(`${initiativeId}/workerRun`), config);
    }

    @bindThis
    public getSyncConfigById(workflowVersionId: string, syncConfigId: string) {
        const config = {
            params: {},
        };

        return this.$http.get<any>(this.buildUrl(`${workflowVersionId}/${syncConfigId}`), config);
    }

    @bindThis
    public getSyncedGroupsByProjectIntegrationExternalType(
        projectId: string,
        workflowVersionType: string,
        projectIntegrationId: string,
        externalType: string,
        offset: number,
        limit: number,
    ) {
        const config = {
            params: { externalType, offset, limit },
        };

        return this.$http.get<any>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/${workflowVersionType}`),
            config,
        );
    }

    /**
     * Returns the example entities for the integration.
     */
    @bindThis
    public getIntegrationExampleEntities(
        projectIntegrationId: string,
        externalType: string,
        query?: string,
        limit?: number,
        onlyUpdatable?: boolean,
        originalFieldFilters?,
    ): Promise<{
        exampleEntities: any[];
        fieldNameToDisplayNameMap: Record<string | number, string>;
        fieldNameToFieldTypeMap: Record<string | number, FieldType>;
        matchedEntityFields: Set<string>;
        globalFields: Set<string>;
    }> {
        const data = {
            limit,
            query,
            onlyUpdatable,
            originalFieldFilters: originalFieldFilters && originalFieldFilters.length ? originalFieldFilters : null,
        };

        const config = {
            params: {
                externalType,
            },
        };

        return this.$http.post<any>(this.buildUrl(`${projectIntegrationId}/example`), data, config);
    }

    @bindThis
    public getCustomTriggerLinkedFieldDefinitions(workflowVersionId: string, customTriggerId: string) {
        const data = {};

        return this.$http.get<any>(
            this.buildUrl(`${workflowVersionId}/${customTriggerId}/linkedFieldDefinitions`),
            data,
        );
    }

    /**
     * Posts the given callbackId and value to the server to answer a worker email question.
     * @returns
     */
    @bindThis
    public postEmailWorkerAnswer(callbackId: string, value) {
        const data = {
            callbackId,
            value,
        };

        return this.$http.post<any>(this.buildUrl('simpleMessageAction'), data);
    }

    /**
     * Gets a JWT token for insided community
     * @return }
     */
    @bindThis
    public getCommunityToken() {
        const data = {};
        return this.$http.post<any>(this.buildUrl('sso/insided/signin'), data);
    }

    /**
     * Migrate a module to makers
     */
    @bindThis
    public migrateToMakers(groupId: string) {
        return this.$http.post<void>(this.buildUrl(`${groupId}/migrateToMakers`), undefined);
    }

    @bindThis
    public getEntitiesToPublishSummary(workflowVersionId: string) {
        return this.$http.get<{ entities: EntitiesToPublishSummary[] }>(
            this.buildUrl(`${workflowVersionId}/entitiesToPublishSummary`),
        );
    }

    @bindThis
    public publishSolution(workflowFolderId: string, comment: string) {
        return this.$http.post<any>(this.buildUrl(`${workflowFolderId}/publish`), { comment });
    }

    @bindThis
    public getInitiativeFieldInstanceByFieldDefinitionId(
        initiativeId: string,
        fieldDefinitionId: string,
        contentType?: 'TEXT' | 'JSON',
    ) {
        return this.$http.get<{ value: any }>(
            this.buildUrl(`${initiativeId}/${fieldDefinitionId}/value?contentType=${contentType || 'TEXT'}`),
        );
    }

    @bindThis
    public getOcrOutput(
        initiativeId: string,
        fieldDefinitionId: string,
        outputType?: OcrOutputType,
        textExtractionFieldInformation?: TextExtractionFieldInformation,
        expectedValue?: string,
    ) {
        const data = {
            outputType: outputType || OcrOutputType.WHOLE,
            textExtractionFieldInformation: textExtractionFieldInformation || undefined,
            expectedValue: expectedValue || undefined,
        };

        return this.$http.post<OcrOutputResponse>(
            this.buildUrl(`${initiativeId}/${fieldDefinitionId}/ocrOutput`),
            data,
        );
    }

    @bindThis
    public previewFindWords(
        initiativeId: string,
        fieldDefinitionId: string,
        expectedValue?: string,
        textExtractionFieldInformation?: TextExtractionFieldInformation,
        snippetWordsBack?: number,
    ) {
        const data = {
            expectedValue: expectedValue || undefined,
            textExtractionFieldInformation: textExtractionFieldInformation || undefined,
            snippetWordsBack: snippetWordsBack || 5,
        };

        return this.$http.post<PreviewFindWordsResponse>(
            this.buildUrl(`${initiativeId}/${fieldDefinitionId}/previewFindWords`),
            data,
        );
    }

    @bindThis
    public getWorkflowFolderVersionSummaries(
        workflowFolderId: string,
        commentText: string | undefined,
        createdFrom: number | undefined,
        createdTo: number | undefined,
        publisherPersonIds: string[] | undefined,
        sequentialIdentifiers: (number | string)[] | undefined,
        skip: number,
        limit: number,
    ) {
        return this.$http.get<{ entities: WorkflowFolderVersionSummary[] }>(
            this.buildUrl(`${workflowFolderId}/versions`),
            {
                params: {
                    commentText,
                    createdFrom,
                    createdTo,
                    publisherPersonIds,
                    sequentialIdentifiers,
                    skip,
                    limit,
                },
            },
        );
    }

    @bindThis
    public getWorkflowFolderVersionCount(workflowFolderId: string) {
        return this.$http.get<{ count: number }>(this.buildUrl(`${workflowFolderId}/versions/count`));
    }

    @bindThis
    public resumeModuleFlowAfterTrigger(initiativeId: string, workerRunId, customTriggerId, workflowVersionId) {
        return this.$http.post<void>(
            this.buildUrl(`${initiativeId}/${workflowVersionId}/${workerRunId}/${customTriggerId}/resumeFlow`),
            null,
            this.getETagConfig(undefined),
        );
    }

    @bindThis
    public getLatestJobsStatus(jobIds: string[]) {
        return this.$http.get<{ entities: JobStatusResponse<unknown>[] }>(this.buildUrl('jobStatuses'), {
            params: { jobIds },
        });
    }

    @bindThis
    public getPeopleWhoMadeChangesInWorkflowVersion(workflowVersionId: string) {
        return this.$http.get<Person[]>(this.buildUrl(`${workflowVersionId}/peopleWhoMadeChanges`));
    }

    @bindThis
    public getBackgroundProcessesByWorkflowVersionType(
        groupId: string,
        workflowVersionType: WorkflowVersionType,
        searchText: string | undefined | null,
        backgroundProcessType: BackgroundProcessType | undefined,
        backgroundProcessStatuses: BackgroundProcessStatus[] | undefined,
        skip: number,
        limit: number,
        fillEntities: boolean = false,
    ) {
        return this.$http.get<BackgroundProcessesFetchResults>(
            this.buildUrl(`${groupId}/${workflowVersionType}/backgroundProcesses`),
            { params: { skip, limit, fillEntities, searchText, backgroundProcessType, backgroundProcessStatuses } },
        );
    }

    @bindThis
    public stopBackgroundProcessesById(backgroundProcessId: string) {
        return this.$http.post(this.buildUrl(`${backgroundProcessId}/stop`), {});
    }

    @bindThis
    public getBackgroundProcessById(backgroundProcessId: string) {
        return this.$http.get<BackgroundProcess>(this.buildUrl(backgroundProcessId));
    }

    @bindThis
    public getEnterpriseComponentAllWorkflowFoldersAccess(
        enterpriseComponentId: string,
        enterpriseComponentType: string,
        projectId: string,
        skip: number,
        limit: number,
    ): Promise<SingleWorkflowFolderEnterpriseComponentAccess[]> {
        return this.$http.get<SingleWorkflowFolderEnterpriseComponentAccess[]>(
            this.buildUrl(`${projectId}/${enterpriseComponentType}/${enterpriseComponentId}/workflowFolderAccess`),
            { params: { skip, limit } },
        );
    }

    @bindThis
    public getEnterpriseComponentAllowNewWorkflowFolderAccess(
        enterpriseComponentId: string,
        enterpriseComponentType: string,
        projectId: string,
    ): Promise<NewWorkflowFolderEnterpriseComponentAccess> {
        return this.$http.get<NewWorkflowFolderEnterpriseComponentAccess>(
            this.buildUrl(
                `${projectId}/${enterpriseComponentType}/${enterpriseComponentId}/AllowNewWorkflowFoldersCommand`,
            ),
        );
    }

    @bindThis
    public getEnterpriseComponentsPersonPermissionsRole(
        enterpriseComponentIds: string[],
        enterpriseComponentType: EnterpriseComponentType,
        projectId: string,
    ): Promise<EnterpriseComponentsPersonPermissionRole> {
        const data = {
            entitiesType: enterpriseComponentType,
            entityIds: enterpriseComponentIds,
        };

        return this.$http.post<EnterpriseComponentsPersonPermissionRole>(
            this.buildUrl(`${projectId}/enterpriseComponentsPersonPermissionRole`),
            data,
        );
    }

    @bindThis
    public getEnterpriseComponentAdmins(
        enterpriseComponentId: string,
        enterpriseComponentType: EnterpriseComponentType,
        projectId: string,
    ): Promise<Person[]> {
        return this.$http.get<Person[]>(
            this.buildUrl(`${projectId}/${enterpriseComponentType}/${enterpriseComponentId}/admins`),
        );
    }

    @bindThis
    public updateEnterpriseComponentAdmins(
        enterpriseComponentId: string,
        enterpriseComponentType: EnterpriseComponentType,
        projectId: string,
        admins: Person[],
    ): Promise<void> {
        return this.$http.post(
            this.buildUrl(`${projectId}/${enterpriseComponentType}/${enterpriseComponentId}/admins`),
            {
                adminIds: admins.map((admin) => admin.id),
            },
        );
    }

    /**
     * Get the full entities of people or groups. This is used to fill the people selector with objects
     * when we have only the ids in hand.
     * @param projectId - The project in which the entities are in
     * @param authorizablesIds - The ids to fetch
     */
    @bindThis
    public getAuthorizablesByIds(projectId: string, authorizablesIds: string[]): Promise<Person[]> {
        const params = { authorizablesIds };
        return this.$http
            .get<any>(this.buildUrl(`${projectId}/getAuthorizablesByIds`), { params })
            .then((response) => response.authorizableIds);
    }

    @bindThis
    public updateEnterpriseComponentWorkflowFolderAccess(
        enterpriseComponentId: string,
        enterpriseComponentType: EnterpriseComponentType,
        projectId: string,
        workflowFolderId: string,
        isAllowedNew: boolean,
    ): Promise<void> {
        return this.$http.post(
            this.buildUrl(`${projectId}/${enterpriseComponentType}/${enterpriseComponentId}/workflowFolderAccess`),
            {
                accessAllowed: isAllowedNew,
                workflowFolderId,
            },
        );
    }

    @bindThis
    public getEnterpriseComponentWorkflowFoldersRelatedGroups(
        enterpriseComponentId: string,
        enterpriseComponentType: EnterpriseComponentType,
        projectId: string,
        workflowFolderId: string,
    ): Promise<string[]> {
        return this.$http.get(
            this.buildUrl(
                `${projectId}/${enterpriseComponentType}/${enterpriseComponentId}/${workflowFolderId}/relatedGroups`,
            ),
        );
    }

    @bindThis
    public updateEnterpriseComponentAutoAccess(enterpriseComponentId: string, newState: boolean): Promise<void> {
        return this.$http.post(this.buildUrl(`${enterpriseComponentId}/allowNewSolutionAccess`), {
            allowNewWorkflowFolder: newState,
        });
    }

    @bindThis
    public getProjectIntegrationDependentGroups(projectId: string, projectIntegrationId: string): Promise<Group[]> {
        return this.$http
            .get<{ entities: Group[] }>(this.buildUrl(`${projectId}/${projectIntegrationId}/dependentGroups`))
            .then((response) => response.entities);
    }

    @bindThis
    public getProjectIntegrationActionDependentGroups(
        projectIntegrationId: string,
        projectIntegrationActionId: string,
    ): Promise<Group[]> {
        return this.$http
            .get<{
                entities: Group[];
            }>(this.buildUrl(`${projectIntegrationId}/${projectIntegrationActionId}/dependentGroups`))
            .then((response) => response.entities);
    }

    @bindThis
    public getProjectIntegrationActionParameters(
        projectIntegrationId: string,
        projectIntegrationActionId: string,
    ): Promise<ActionParameterExpression[]> {
        return this.$http
            .get<{
                entities: ActionParameterExpression[];
            }>(this.buildUrl(`${projectIntegrationId}/${projectIntegrationActionId}/parameters`))
            .then((response) => response.entities);
    }

    @bindThis
    public getProjectIntegrationCollectStatus(
        projectIntegrationId: string,
    ): Promise<{ entityIdToCollectStatusMap: Record<string, GetCollectStatusResponse> }> {
        return this.$http.get<{ entityIdToCollectStatusMap: Record<string, GetCollectStatusResponse> }>(
            this.buildUrl(`${projectIntegrationId}/collectStatus`),
        );
    }

    /**
     * Gets Training set items by document collections
     */
    @bindThis
    public getTrainingSetItemsByDocumentCollection(
        projectId: string,
        documentCollectionId: string,
        size: number,
        skip: number,
    ) {
        const params = { documentCollectionId, size, skip };
        return this.$http.get<{ itemToCollections: ItemToDocumentCollectionSummary[] }>(
            this.buildUrl(`${projectId}/trainingSetItemsConnectedToDocumentCollections`),
            {
                params,
            },
        );
    }

    /**
     * Gets solution mapper operations graph
     */
    @bindThis
    public getOperationsGraph(solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER>) {
        return this.$http.get<{
            nodes: OperationNode[];
            edges: OperationEdge[];
        }>(this.buildUrl(`${solutionMapperId}/operationsGraph`));
    }

    /**
     * Gets solution mappers of workflow folder
     */
    @bindThis
    public getSolutionMappers(workflowFolderId: string) {
        return this.$http.get<{ solutionMappers: SolutionMapper[] }>(this.buildUrl(`${workflowFolderId}/mappers`));
    }

    /**
     * Gets the latest created solution mapper of workflow folder
     */
    @bindThis
    public getLatestSolutionMapper(workflowFolderId: string) {
        return this.$http.get<{ solutionMapper: SolutionMapper }>(this.buildUrl(`${workflowFolderId}/getLatestMapper`));
    }

    /**
     * Gets Training set model with document collection by id
     */
    @bindThis
    public getTrainingSetModelWithDocumentCollection(modelId: string, includeDocumentCollections: boolean = false) {
        const params = { includeDocumentCollections };
        return this.$http.get<TrainingSetGetModelResponse>(this.buildUrl(`${modelId}/modelWithDocumentCollection`), {
            params,
        });
    }

    /**
     * Gets Training set model test items by model id
     */
    @bindThis
    public getTrainingSetModelTestItems(
        trainingSetId: string,
        testItemsIds: string[],
        trainingSetFieldId: string,
        modelConfiguration: TrainingSetModelConfiguration,
        trainingSetModelMethodType: TrainingSetModelMethodType,
        documentCollectionIds: string[],
    ) {
        return this.$http.post<{
            trainingSetItemsToSnippets: TrainingSetItemToSnippet[];
            documentCollectionIdToDisplay: Record<string, string>;
        }>(this.buildUrl(`${trainingSetId}/testItems`), {
            testItemsIds,
            trainingSetFieldId,
            FindWordsConfiguration: modelConfiguration.findWordsConfiguration,
            before: modelConfiguration.before,
            after: modelConfiguration.after,
            arbitraryAnchors: modelConfiguration.arbitraryAnchors,
            arbitraryAnchorsQuantifier: modelConfiguration.arbitraryAnchorsQuantifier,
            negativeArbitraryAnchors: modelConfiguration.negativeArbitraryAnchors,
            negativeArbitraryAnchorsQuantifier: modelConfiguration.negativeArbitraryAnchorsQuantifier,
            presetValue: modelConfiguration.presetValue,
            ignoreNonWordCharacters: modelConfiguration.ignoreNonWordCharacters,
            methodType: trainingSetModelMethodType,
            trainingExamples: modelConfiguration.trainingExamples?.filter(
                (trainingExample) => trainingExample.keyword !== '' && trainingExample.keywordSentiment,
            ),
            documentCollectionIds,
            sensitivityLevel: modelConfiguration.sensitivityLevel,
            customSensitivityLevel: modelConfiguration.customSensitivityLevel,
        });
    }

    /**
     * Deletes Training Set Model
     */
    @bindThis
    public deleteTrainingSetModel(trainingSetModelId: string) {
        return this.$http.delete<void>(this.buildUrl(trainingSetModelId));
    }

    /**
     * Run project integration action test and returns request result
     */
    @asyncApiJob
    @bindThis
    public runProjectIntegrationActionTest(
        projectIntegrationId: string,
        projectIntegrationActionId: string,
        testedProjectIntegrationAction: ProjectIntegrationAction,
        parameterIdToValueMap: Record<string, string>,
    ): Promise<ProjectIntegrationTestActionResponse> {
        const data = {
            testedProjectIntegrationAction,
            parameterIdToValueMap,
        };

        return this.$http.post<ProjectIntegrationTestActionResponse>(
            this.buildUrl(`${projectIntegrationId}/${projectIntegrationActionId}/test/runtime`),
            data,
        );
    }

    /**
     * Run project integration action paginated test
     */
    @asyncApiJob
    @bindThis
    public runProjectIntegrationPaginatedActionTest(
        projectIntegrationId: string,
        projectIntegrationActionId: string,
        numberOfPages: number,
        testedProjectIntegrationAction: ProjectIntegrationAction,
    ): Promise<any> {
        const body = {
            numberOfPages,
            testedProjectIntegrationAction,
        };

        return this.$http.post<ProjectIntegrationActionTestRunsResponse>(
            this.buildUrl(`${projectIntegrationId}/${projectIntegrationActionId}/test/flow`),
            body,
        );
    }

    @bindThis
    public getProjectIntegrationActionTestRuns(
        projectIntegrationId: string,
        projectIntegrationActionId: string,
    ): Promise<ProjectIntegrationActionTestRunsResponse> {
        return this.$http.get<any>(this.buildUrl(`${projectIntegrationId}/${projectIntegrationActionId}/tests`));
    }

    @bindThis
    public getProjectIntegrationActionExternalActivitiesHandleResponseByTestRun(
        projectIntegrationId: string,
        projectIntegrationEntityId: string,
        testRunIds: string[],
        query?: string,
    ): Promise<{
        entities: ExternalActivity[];
        numOfPages: number;
        totalEntities: number;
        entitiesPerPage: number[];
    }> {
        const body = {
            projectIntegrationTestRunIds: testRunIds,
            query,
        };
        return this.$http.post<any>(
            this.buildUrl(`${projectIntegrationId}/${projectIntegrationEntityId}/response-summaries/test-runs`),
            body,
        );
    }

    @bindThis
    public getProjectIntegrationActionExternalActivitiesHandleResponseByPayload(
        projectIntegrationId: string,
        responseHandlingDefinition: unknown,
        projectIntegrationEntityId: string,
        payloadId: string,
        query?: string,
    ): Promise<{
        entities: ExternalActivity[];
        totalEntities: number;
    }> {
        const body = {
            payloadId,
            responseHandlingDefinition,
            query,
        };

        return this.$http.post<{
            entities: ExternalActivity[];
            totalEntities: number;
        }>(this.buildUrl(`${projectIntegrationId}/${projectIntegrationEntityId}/response-summaries/payload`), body);
    }

    /**
     * Get Wsdl operations by wsdl Url.
     */
    @asyncApiJob
    @bindThis
    public getOperationNamesFromWsdlUrl(
        projectIntegrationId: string,
        wsdlUrl: string,
    ): Promise<GetOperationNamesFromWsdlUrlResponse> {
        const data = {
            wsdlUrl,
        };

        return this.$http.post<GetOperationNamesFromWsdlUrlResponse>(
            this.buildUrl(`${projectIntegrationId}/wsdl/operations/url`),
            data,
        );
    }

    /**
     * Get Wsdl operations by wsdl file.
     */
    @bindThis
    public getOperationNamesFromWsdlFile(
        projectIntegrationId: string,
        wsdlFile: Blob,
    ): Promise<GetOperationNamesFromWsdlUrlResponse> {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': 'application/wsdl+xml',
            },
        };
        return this.$http.post<GetOperationNamesFromWsdlUrlResponse>(
            this.buildUrl(`${projectIntegrationId}/wsdl/operations/file`),
            wsdlFile,
            options,
        );
    }

    /**
     * import project integration custom actions from wsdl url
     */
    @asyncApiJob
    @bindThis
    public importProjectIntegrationCustomActionsFromWsdlUrl(
        projectIntegrationId: string,
        wsdlUrl: string,
        operations: string[],
    ): Promise<void> {
        const data = {
            wsdlUrl,
            operations,
        };

        return this.$http.post<void>(this.buildUrl(`${projectIntegrationId}/wsdl/import/url`), data);
    }

    /**
     * import project integration custom actions from wsdl file
     */
    @bindThis
    public importProjectIntegrationCustomActionsFromWsdlFile(
        projectIntegrationId: string,
        wsdlFile: Blob,
        operations: string[],
    ): Promise<void> {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': 'application/wsdl+xml',
            },
            params: {
                operations,
            },
        };

        return this.$http.post<void>(this.buildUrl(`${projectIntegrationId}/wsdl/import/file`), wsdlFile, options);
    }

    /**
     * Creates solution mapper operation edge
     */
    @bindThis
    public createOperationEdge(
        workflowFolderId: string,
        solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER>,
        source: string,
        target: string,
        displayName: string,
        description?: string,
        apps?: string[],
        automatedParts?: OperationAutomatedPart[],
        manualParts?: OperationManualPart[],
        estimatedTime?: number,
        estimatedCost?: number,
    ) {
        const data = {
            source,
            target,
            displayName,
            description,
            apps,
            automatedParts,
            manualParts,
            estimatedTime,
            estimatedCost,
        };

        return this.$http.post<{
            operationEdgeId: string;
            operationConfigurationId: string;
        }>(this.buildUrl(`${workflowFolderId}/${solutionMapperId}/edge`), data);
    }

    /**
     * Creates solution mapper operation Node
     */
    @bindThis
    public createOperationNode(
        workflowFolderId: string,
        solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER>,
        x: number,
        y: number,
        type: OperationNodeType,
        displayName: string,
        description?: string,
        apps?: string[],
        automatedParts?: OperationAutomatedPart[],
        manualParts?: OperationManualPart[],
        estimatedTime?: number,
        estimatedCost?: number,
    ) {
        const data = {
            type,
            x,
            y,
            displayName,
            description,
            apps,
            automatedParts,
            manualParts,
            estimatedTime,
            estimatedCost,
        };

        return this.$http.post<{
            operationNodeId: string;
            operationConfigurationId: string;
        }>(this.buildUrl(`${workflowFolderId}/${solutionMapperId}/node`), data);
    }

    /**
     * Creates a new solution mapper
     */
    @bindThis
    public createSolutionMapper(workflowFolderId: string, displayName: string, description?: string) {
        const data = {
            displayName,
            description,
        };

        return this.$http.post<{
            solutionMapperId: string;
        }>(this.buildUrl(`${workflowFolderId}/mapper`), data);
    }

    /**
     * Creates a process participant
     */
    @bindThis
    public createProcessParticipant(
        workflowFolderId: string,
        workflowFolderCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
        roleInProcess: string,
        businessGroupId?: string,
        description?: string,
        systemBeingUsed?: ProcessParticipantSystemBeingUsed,
    ) {
        const data = {
            roleInProcess,
            description,
            systemBeingUsed,
            businessGroupId,
            workflowFolderCategoryId,
        };

        return this.$http.post<ProcessParticipant>(this.buildUrl(`${workflowFolderId}/processParticipant`), data);
    }

    /**
     * Updates a process participant
     */
    @bindThis
    public updateProcessParticipant(
        processParticipantId: TonkeanId<TonkeanType.PROCESS_PARTICIPANT>,
        workflowFolderId: string,
        roleInProcess: string,
        businessGroupId?: string,
        description?: string,
        systemBeingUsed?: ProcessParticipantSystemBeingUsed,
    ) {
        const data = {
            workflowFolderId,
            roleInProcess,
            description,
            systemBeingUsed,
            businessGroupId,
        };

        return this.$http.post<{ id: TonkeanId<TonkeanType.PROCESS_PARTICIPANT> }>(
            this.buildUrl(processParticipantId),
            data,
        );
    }

    @bindThis
    public deleteProcessParticipant(processParticipantId: TonkeanId<TonkeanType.PROCESS_PARTICIPANT>) {
        return this.$http.delete<void>(this.buildUrl(processParticipantId));
    }

    /**
     * Updates solution mapper operation Node
     */
    @bindThis
    public updateOperationNode(operationNodeId: string, operationNodeType?: OperationNodeType, x?: number, y?: number) {
        const data = {
            operationNodeType,
            x,
            y,
        };

        return this.$http.post<void>(this.buildUrl(operationNodeId), data);
    }

    /**
     * Updates a solution mapper
     */
    @bindThis
    public updateSolutionMapper(
        solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER>,
        displayName: string,
        description?: string,
    ) {
        const data = {
            displayName,
            description,
        };

        return this.$http.post<void>(this.buildUrl(solutionMapperId), data);
    }

    /**
     * Get evaluated test action
     */
    @asyncApiJob
    @bindThis
    public evaluateProjectIntegrationTestAction(
        projectIntegrationId: string,
        httpRequestDefinition: CustomActionDefinition,
        paramIdToValueMap: Record<string, string>,
        parameters: BaseActionParameter[],
    ) {
        const data = {
            requestDefinition: httpRequestDefinition,
            paramIdToValueMap,
            parameters,
        };
        return this.$http.post<PreviewEvaluatedResponse>(this.buildUrl(`${projectIntegrationId}/test/evaluate`), data);
    }

    /**
     * Updates solution mapper operation configuration
     */
    @bindThis
    public updateOperationConfiguration(
        operationConfigurationId: string,
        displayName?: string,
        description?: string,
        apps?: string[],
        automatedParts?: OperationAutomatedPart[],
        manualParts?: OperationManualPart[],
        estimatedTime?: number,
        estimatedCost?: number,
    ) {
        const data = {
            displayName,
            description,
            apps,
            automatedParts,
            manualParts,
            estimatedTime,
            estimatedCost,
        };

        return this.$http.post<void>(this.buildUrl(operationConfigurationId), data);
    }

    /**
     * Deletes solution mapper operation edge
     */
    @bindThis
    public deleteOperationEdge(operationEdgeId: string) {
        return this.$http.delete<void>(this.buildUrl(operationEdgeId));
    }

    /**
     * Deletes solution mapper operation node
     */
    @bindThis
    public deleteOperationNode(operationNodeId: string) {
        return this.$http.delete<void>(this.buildUrl(operationNodeId));
    }

    /**
     * Deletes a solution mapper
     */
    @bindThis
    public deleteSolutionMapper(solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER>) {
        return this.$http.delete<void>(this.buildUrl(solutionMapperId));
    }

    @bindThis
    public getBackgroundProcessesStatsByWorkflowFolder(projectId: string, workflowVersionType: WorkflowVersionType) {
        return this.$http.get<BackgroundProcessesStatsByModule>(
            this.buildUrl(`${projectId}/${workflowVersionType}/backgroundProcesses/stats`),
        );
    }

    @bindThis
    public validateIsSlackCommandTaken(slackCommand: string, groupId: string) {
        return this.$http.get<{ isCommandValid: boolean }>(
            this.buildUrl(`${groupId}/forms/slackCommand/${slackCommand}/validate`),
        );
    }

    @bindThis
    public getPeopleDirectoriesByProjectId(projectId: string, searchText: string = '', skip: number, limit: number) {
        return this.$http.get<FetchResults<PeopleDirectory>>(this.buildUrl(`${projectId}/peopleDirectories`), {
            params: { searchText, skip, limit },
        });
    }

    @bindThis
    public getPeopleDirectoryById(peopleDirectoryId: string) {
        return this.$http.get<PeopleDirectory>(this.buildUrl(peopleDirectoryId));
    }

    @bindThis
    public getPeopleDirectoriesByWorkflowFolderId(
        workflowFolderId: string,
        searchText: string = '',
        skip: number,
        limit: number,
        ignorePeopleDirectoryIds?: string[],
    ) {
        return this.$http.get<FetchResults<PeopleDirectory>>(this.buildUrl(`${workflowFolderId}/peopleDirectories`), {
            params: { searchText, skip, limit, ignorePeopleDirectoryIds: ignorePeopleDirectoryIds || [] },
        });
    }

    @bindThis
    public createPeopleDirectory(
        displayName: string,
        description: string,
        allowNewWorkflowFolder: boolean,
        projectId: string,
        communicationIntegrationsPreferences: string[],
    ) {
        const data = {
            displayName,
            description,
            allowNewWorkflowFolder,
            communicationIntegrationsPreferences,
        };

        return this.$http.post<PeopleDirectory>(this.buildUrl(`${projectId}/peopleDirectory`), data);
    }

    @bindThis
    public updatePeopleDirectory(
        peopleDirectoryId: string,
        communicationIntegrationsPreferences: string[],
        draftInstanceId?: string,
        publishedInstanceId?: string,
    ) {
        const data = {
            draftInstanceId,
            publishedInstanceId,
            communicationIntegrationsPreferences,
        };

        return this.$http.post<PeopleDirectory>(this.buildUrl(peopleDirectoryId), data);
    }

    @bindThis
    public deletePeopleDirectory(peopleDirectoryId: string) {
        return this.$http.delete(this.buildUrl(peopleDirectoryId));
    }

    @bindThis
    public getPeopleDirectoryInstancesByPeopleDirectoryId(
        peopleDirectoryId: string,
        searchText: string = '',
        skip: number,
        limit: number,
        fillEntities: boolean,
    ) {
        return this.$http.get<FetchResults<PeopleDirectoryInstance>>(
            this.buildUrl(`${peopleDirectoryId}/peopleDirectoryInstances`),
            {
                params: {
                    searchText,
                    skip,
                    limit,
                    fillEntities,
                },
            },
        );
    }

    @bindThis
    public createPeopleDirectoryInstance(
        peopleDirectoryId: string,
        displayName: string,
        description: string,
        color: Color,
    ) {
        const data = {
            displayName,
            description,
            color,
        };

        return this.$http.post<PeopleDirectoryInstance>(
            this.buildUrl(`${peopleDirectoryId}/peopleDirectoryInstance`),
            data,
        );
    }

    @bindThis
    public getPeopleDirectoryInstance(peopleDirectoryInstanceId: string) {
        return this.$http.get<PeopleDirectoryInstance>(this.buildUrl(peopleDirectoryInstanceId));
    }

    @bindThis
    public updatePeopleDirectoryGroup(peopleDirectoryInstance: PeopleDirectoryInstance) {
        const data = {
            displayName: peopleDirectoryInstance.displayName,
            description: peopleDirectoryInstance.description,
            color: peopleDirectoryInstance.color,
            people: peopleDirectoryInstance.people,
            dataSourceProjectIntegrationId: peopleDirectoryInstance.dataSourceProjectIntegration?.id,
            individualSourceEmailField: peopleDirectoryInstance.individualSourceEmailField,
            individualSourceEntity: peopleDirectoryInstance.individualSourceEntity,
            query: peopleDirectoryInstance.query,
            individualSourceType: peopleDirectoryInstance.individualsSourceType,
            maxCapacity: peopleDirectoryInstance.maxCapacity,
            identityProviderUserGroupsIds: peopleDirectoryInstance.identityProviderUserGroupsIds,
        };

        return this.$http.post<PeopleDirectoryInstance>(this.buildUrl(peopleDirectoryInstance.id), data);
    }

    @bindThis
    public deletePeopleDirectoryInstance(peopleDirectoryInstanceId: string) {
        return this.$http.delete<{ deleted: boolean }>(this.buildUrl(peopleDirectoryInstanceId));
    }

    @bindThis
    public uploadTrainingSetItems(trainingSetId: string, files: TonkeanUploadedFile[]) {
        const formData = new FormData();
        files.forEach((file) => {
            file.blob && formData.append('files', file.blob, file.name);
        });

        return this.$http.post<
            { trainingSetItemId: string; status: TrainingSetItemUploadingStatus; name: string; type: GcsMimeType }[]
        >(this.buildUrl(`${trainingSetId}/items`), formData, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined },
        });
    }

    @bindThis
    public uploadPlainTextTrainingSetItem(trainingSetId: string, itemName: string, content: string) {
        const data = {
            itemName,
            content,
        };

        return this.$http.post<{ trainingSetItemId: string; status: TrainingSetItemUploadingStatus; name: string }>(
            this.buildUrl(`${trainingSetId}/item`),
            data,
        );
    }

    @bindThis
    public getTrainingSetItemsRow(
        trainingSetId: string,
        row: TrainingSetItemsRowType | undefined,
        limit: number,
        nextPageToken: string | undefined,
        searchTerm: string | undefined,
        filters: FilterTrainingData,
    ) {
        const params = {
            limit,
            nextPageToken,
            searchTerm,
            filterByMatchedModelId: filters.matchedModel?.id,
            filterByExtractedModelId: filters.extractedModel?.id,
            filterByDocumentCollectionIds: filters.documentCollections?.map((document) => document.id),
            filterByBusinessLabelIds: filters.businessLabels?.map((label) => label.id),
        };

        return this.$http.get<TrainingSetItemsAndEvaluationRelatedEntities & { nextPageToken?: string }>(
            this.buildUrl(`${trainingSetId}/items/${row}`),
            { params },
        );
    }

    @bindThis
    public getTrainingSetItemsMatchedByModelId(trainingSetModelId: string, limit: number) {
        const params = {
            limit,
        };

        return this.$http.get<{ entities: TrainingSetModelToItemIdentifiersMatched[] }>(
            this.buildUrl(`${trainingSetModelId}/itemsMatchedByModelId`),
            { params },
        );
    }

    @bindThis
    public getTrainingSetItemsExtractedByModelId(trainingSetModelId: string, limit: number) {
        const params = {
            limit,
        };

        return this.$http.get<{ entities: TrainingSetModelToItemIdentifiersMatched[] }>(
            this.buildUrl(`${trainingSetModelId}/itemsExtractedByModelId`),
            { params },
        );
    }

    @bindThis
    public getTrainingSetItemsPolling(
        trainingSetId: string,
        updatedAfter: number,
        searchTerm: string | undefined,
        filters: FilterTrainingData,
    ) {
        const params = {
            searchTerm,
            updatedAfter,
            filterByMatchedModelId: filters.matchedModel?.id,
            filterByExtractedModelId: filters.extractedModel?.id,
            filterByDocumentCollectionIds: filters.documentCollections?.map((document) => document.id),
            filterByBusinessLabelIds: filters.businessLabels?.map((businessLabel) => businessLabel.id),
        };

        return this.$http.get<TrainingSetItemsAndEvaluationRelatedEntities>(
            this.buildUrl(`${trainingSetId}/items/polling`),
            { params },
        );
    }

    @bindThis
    public createPeopleDirectoryInstanceGroupMessage(
        peopleDirectoryInstanceId: string,
        displayName: string,
        active: boolean,
        forceEmail: boolean,
        emailType?: PeopleDirectoryInstanceGroupMessagesEmailType,
        emailList?: string[],
        selectedCommunicationIntegrationId?: string,
        slackChannelType?: PeopleDirectoryInstanceGroupMessagesChannelType,
        slackChannel?: Record<string, unknown>,
    ) {
        const data = {
            displayName,
            active,
            selectedCommunicationIntegrationId,
            forceEmail,
            emailType,
            emailList,
            slackChannelType,
            slackChannel,
        };

        return this.$http.post<PeopleDirectoryInstanceGroupMessage>(
            this.buildUrl(`${peopleDirectoryInstanceId}/groupMessage`),
            data,
        );
    }

    @bindThis
    public updatePeopleDirectoryInstanceGroupMessage(
        peopleDirectoryInstanceGroupMessageId: string,
        displayName: string,
        active: boolean,
        forceEmail: boolean,
        emailType?: PeopleDirectoryInstanceGroupMessagesEmailType,
        emailList?: string[],
        selectedCommunicationIntegrationId?: string,
        slackChannelType?: PeopleDirectoryInstanceGroupMessagesChannelType,
        slackChannel?: Record<string, unknown>,
    ) {
        const data = {
            displayName,
            active,
            selectedCommunicationIntegrationId,
            forceEmail,
            emailType,
            emailList,
            slackChannelType,
            slackChannel,
        };

        return this.$http.post<PeopleDirectoryInstanceGroupMessage>(
            this.buildUrl(peopleDirectoryInstanceGroupMessageId),
            data,
        );
    }

    @bindThis
    public getPeopleDirectoryInstanceUserGroups(peopleDirectoryInstanceId: string) {
        return this.$http.get<FetchResults<UserGroup>>(this.buildUrl(`${peopleDirectoryInstanceId}/userGroups`));
    }

    @bindThis
    public getPeopleDirectoryInstanceGroupMessagesByPeopleDirectoryInstanceId(
        peopleDirectoryInstanceId: string,
        searchText: string,
        limit: number,
        skip: number,
    ) {
        const params = {
            searchText,
            limit,
            skip,
        };

        return this.$http.get<FetchResults<PeopleDirectoryInstanceGroupMessage>>(
            this.buildUrl(`${peopleDirectoryInstanceId}/groupMessages`),
            { params },
        );
    }

    @bindThis
    public updateProjectIntegrationAccessControl(
        projectIntegrationId: string,
        accessControlDefinition: AccessControlDefinition,
    ) {
        const body = {
            accessControlDefinition,
        };

        return this.$http.post<void>(this.buildUrl(`${projectIntegrationId}/access/control`), body);
    }

    @bindThis
    public getProjectIntegrationActivitiesStats(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
    ) {
        return this.$http.get<{ result: Record<string, number> }>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/entityDocumentCount`),
        );
    }

    public getProjectDocumentsCountPerIntegrationPerEntity(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        projectIntegrationsIds: TonkeanId<TonkeanType.PROJECT_INTEGRATION>[],
    ) {
        return this.$http.get<{
            result: Record<TonkeanId<TonkeanType.PROJECT_INTEGRATION>, Record<string, number>>;
        }>(this.buildUrl(`${projectId}/documentsCountPerEntity`), {
            params: { projectIntegrationsIds },
        });
    }

    @bindThis
    public getTrialStatus(projectId: TonkeanId<TonkeanType.PROJECT>) {
        return this.$http.get<TrialStatusResult>(this.buildUrl(`${projectId}/trialStatus`), {});
    }

    @bindThis
    public deletePeopleDirectoryInstanceGroupMessageById(peopleDirectoryInstanceGroupMessageId: string) {
        return this.$http.delete<{ deleted: boolean }>(this.buildUrl(peopleDirectoryInstanceGroupMessageId));
    }

    @bindThis
    public getPeopleDirectoryInstanceViewIndividuals(
        projectId: string,
        projectIntegrationId: string,
        viewData,
        viewType,
        emailFieldName?: string,
        viewCount?: number,
    ) {
        const data: Record<string, any> = {
            projectIntegrationId,
            viewType,
            viewData,
            viewCount,
            emailFieldName,
            previewMode: true,
        };

        return this.$http.post<PeopleDirectoryInstanceViewIndividualsResponse>(
            this.buildUrl(`${projectId}/peopleDirectoryIndividualsPreview`),
            data,
        );
    }

    @bindThis
    public getPeopleDirectoryInstanceViewIndividualsExternalActivities(
        projectId: string,
        projectIntegrationId: string,
        entity: string,
        emailFieldName: string,
        emailFieldValue: string,
        viewCount: number,
        viewData: TonkeanQueryDefinition,
    ) {
        const data: Record<string, any> = {
            projectIntegrationId,
            entity,
            viewCount,
            emailFieldName,
            emailFieldValue,
            viewData,
            previewMode: true,
        };

        return this.$http.post<{ entities: ExternalActivity[] }>(
            this.buildUrl(`${projectId}/peopleDirectoryIndividualExternalActivities`),
            data,
        );
    }

    @bindThis
    public smartSearchReply(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        replyText: string,
        useAssistant: boolean | undefined,
        conversationId?: string,
    ) {
        const data = {
            replyText,
            conversationId,
            useAssistant,
        };
        const path = useAssistant ? `${projectId}/smartSearchAssistant` : `${projectId}/smartSearch`;
        return this.$http.post<SmartConversationMessage<SmartSearchResponse>>(this.buildUrl(path), data);
    }

    @bindThis
    public smartIntakeAssistant(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        replyText: string,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        itemInterfaceWidgetIds: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[],
        conversationId?: string,
    ) {
        const data = {
            replyText,
            conversationId,
            initiativeId,
            itemInterfaceId,
            itemInterfaceWidgetIds,
        };
        return this.$http.post<SmartConversationMessage<SmartSearchResponse>>(
            this.buildUrl(`${projectId}/smartIntakeAssistant`),
            data,
        );
    }

    @bindThis
    public smartSearchSuggestAction(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        conversationId: string,
        userQuestion: string,
    ) {
        const data = {
            conversationId,
            userQuestion,
        };
        return this.$http.post<SmartSearchSuggestActionResponse>(
            this.buildUrl(`${projectId}/smartSearchSuggestAction`),
            data,
        );
    }

    @bindThis
    public externalActivitySmartSearchEntityAnalysis(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        externalId: string,
        conversationId: string,
    ) {
        const data = {
            conversationId,
            externalId,
        };
        return this.$http.post<SmartSearchEntityAnalysisResponse>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/smartSearchEntityAnalysis`),
            data,
        );
    }

    @bindThis
    public initiativeSmartSearchEntityAnalysis(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        conversationId: string,
    ) {
        const data = {
            conversationId,
        };
        return this.$http.post<SmartSearchEntityAnalysisResponse>(
            this.buildUrl(`${projectId}/${initiativeId}/smartSearchEntityAnalysis`),
            data,
        );
    }

    @bindThis
    public getSmartSearchLookupResult(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        lookupSearch: string,
        boostQuery: string[],
        userQuestion: string,
        suggestDataSources: string[],
        suggestGroups: string[],
        skip: number,
        limit: number,
    ) {
        const params = {
            q: lookupSearch,
            boostQuery,
            userQuestion,
            suggestDataSources,
            suggestGroups,
            skip,
            limit,
        };

        return this.$http.get<SmartSearchLookupResponse>(this.buildUrl(`${projectId}/smartSearchLookup`), {
            params,
        });
    }

    @bindThis
    public getProjectIntegrationSmartSearchAccessSettings(
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
    ) {
        return this.$http.get<FetchResults<ProjectIntegrationSmartSearchEntityConfig>>(
            this.buildUrl(`${projectIntegrationId}/smartSearchAccess`),
            { params: {} },
        );
    }

    @bindThis
    public createProjectIntegrationSmartSearchAccessEntityConfig(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        externalType: string,
        allowAccess: boolean,
        conditions: FieldQuery | undefined,
    ) {
        return this.$http.post<ProjectIntegrationSmartSearchEntityConfig>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/createSmartSearchEntityConfig`),
            {
                externalType,
                allowAccess,
                conditions,
            },
        );
    }

    @bindThis
    public updateProjectIntegrationSmartSearchAccessEntityConfig(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        projectIntegrationSmartSearchEntityConfigId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_SMART_SEARCH_ENTITY_CONFIG>,
        externalType: string,
        allowAccess: boolean,
        conditions: TonkeanQueryDefinition | undefined,
    ) {
        return this.$http.post<void>(
            this.buildUrl(`${projectId}/${projectIntegrationSmartSearchEntityConfigId}/smartSearchEntityConfig`),
            {
                externalType,
                allowAccess,
                conditions,
            },
        );
    }

    @bindThis
    public deleteProjectIntegrationSmartSearchAccessEntityConfig(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        projectIntegrationSmartSearchEntityConfigId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_SMART_SEARCH_ENTITY_CONFIG>,
    ) {
        return this.$http.delete<void>(
            this.buildUrl(`${projectId}/${projectIntegrationSmartSearchEntityConfigId}/deleteSmartSearchEntityConfig`),
        );
    }

    @bindThis
    public getModelsItemsMatched(trainingSetId: string) {
        return this.$http.get<Record<string, number>>(this.buildUrl(`${trainingSetId}/modelsMatchedItems`), {});
    }

    @bindThis
    public deleteTrainingSetItem(projectId: string, trainingSetItemId: string) {
        return this.$http.delete<void>(this.buildUrl(`${projectId}/${trainingSetItemId}`));
    }

    @bindThis
    public deleteTrainingSet(trainingSetId: string) {
        return this.$http.delete<void>(this.buildUrl(trainingSetId));
    }

    @bindThis
    public updateWorkflowFolderAccess(workflowFolderId: string, isHiddenFromNonSolutionCollaborator: boolean) {
        return this.$http.post<void>(this.buildUrl(`${workflowFolderId}/access`), {
            isHiddenFromNonSolutionCollaborator,
        });
    }

    @bindThis
    public rerunFailedCustomTriggers(projectId: string, workerRunId: string, workerRunStartTime: number) {
        return this.$http.post<{ workerRunId: string; startTime: number }>(
            this.buildUrl(`${projectId}/${workerRunId}/rerunFromFailed`),
            { workerRunStartTime },
        );
    }

    @bindThis
    public updateProjectDataSourceConnectionsSettings(
        projectId: string,
        connectionPermission: ConnectionPermission,
        authorizedPeopleIds: string[],
    ) {
        return this.$http.post<{ data: string; status: number }>(this.buildUrl(`${projectId}/dataSourceSettings`), {
            nonSharedCredentialsConnectionPermission: connectionPermission,
            authorizedPeopleIds,
        });
    }

    @bindThis
    public updatePredefinedConnection(
        projectId: string,
        sharedCredentialId: string,
        isEnabled: boolean,
        integrationId: string,
        description: string,
        authorizedUsers: string[],
        displayName: string,
        connectionPermission: ConnectionPermission,
    ) {
        return this.$http.post(this.buildUrl(`${sharedCredentialId}`), {
            isEnabled,
            integrationId,
            description,
            authorizedUsers,
            displayName,
            connectionPermission,
        });
    }

    @bindThis
    public getDataSourceConnections(projectId: string) {
        return this.$http.get<{ entities: DataSourceConnection[] }>(
            this.buildUrl(`${projectId}/dataSourceConnections`),
            {},
        );
    }

    @bindThis
    public getDataSourceConnectionById(projectId: string, dataSourceConnectionId: string) {
        return this.$http.get<DataSourceConnection>(
            this.buildUrl(`${projectId}/dataSourceConnections/${dataSourceConnectionId}`),
            {},
        );
    }

    @bindThis
    public deleteDataSourceConnection(projectId: string, dataSourceConnectionId: string) {
        return this.$http.delete<void>(this.buildUrl(`${projectId}/dataSourceConnections/${dataSourceConnectionId}`));
    }

    @bindThis
    public createPredefinedConnection(
        projectId: string,
        dataSourceConnectionId: string,
        integrationId: string,
        description: string,
        displayName: string,
        authorizedIds: string[],
        connectionPermission: ConnectionPermission,
    ) {
        return this.$http.post(this.buildUrl(`${projectId}/${dataSourceConnectionId}/create/sharedCredential`), {
            dataSourceConnectionId,
            integrationId,
            isEnabled: true,
            description,
            displayName,
            authorizedIds,
            connectionPermission,
        });
    }

    @bindThis
    public createDataSourceConnection(
        projectId: string,
        dataSourceType: IntegrationType,
        isEnabled = true,
        allowOnlySharedCredentials?: boolean,
    ) {
        return this.$http.post<DataSourceConnection>(this.buildUrl(`${projectId}/create/dataSourceConnection`), {
            dataSourceType,
            isEnabled,
            allowOnlySharedCredentials,
        });
    }

    @bindThis
    public updateDataSourceConnection(
        projectId: string,
        dataSourceConnectionId: string,
        allowOnlySharedCredentials: boolean,
        isEnabled: boolean,
        connectionPermission: ConnectionPermission,
        authorizedEntitiesIds: string[],
    ) {
        return this.$http.post<DataSourceConnection>(this.buildUrl(`${projectId}/${dataSourceConnectionId}`), {
            allowOnlySharedCredentials,
            isEnabled,
            connectionPermission,
            authorizedEntitiesIds,
        });
    }

    @bindThis
    public updateProjectUsersSource(
        projectId: string,
        processContributorsSource: ProjectUsersSource,
        sourceChangeBehavior: ProjectSourceChangeBehavior,
    ) {
        const data = {
            processContributorsSource,
            sourceChangeBehavior,
        };

        return this.$http.post(this.buildUrl(`${projectId}/usersSource`), data);
    }

    @bindThis
    public updateProjectCommunicationIntegrationsUsersCollect(
        projectId: string,
        communicationIntegrationsToCollect: string[],
    ) {
        const data = {
            communicationIntegrationsToCollect,
        };

        return this.$http.post(this.buildUrl(`${projectId}/communicationSourcesUsersCollect`), data);
    }

    @bindThis
    public updateProjectAllowCreateProcessContributors(projectId: string, allowCreateProcessContributors: boolean) {
        const data = {
            allowCreateProcessContributors,
        };

        return this.$http.post(this.buildUrl(`${projectId}/allowCreateProcessContributors`), data);
    }

    @bindThis
    public updateProjectJITProvisioning(projectId: string, isJITProvisioning: boolean, jitProvisioningType) {
        const data = {
            isJITProvisioning,
            jitProvisioningType,
        };

        return this.$http.post(this.buildUrl(`${projectId}/JITProvisioning`), data);
    }

    @bindThis
    public updateProjectPersonRoles(projectId: string, personId: string, newManualRoles: SCIMTonkeanRole[]) {
        const data = {
            personId,
            newManualRoles,
        };

        return this.$http.post(this.buildUrl(`${projectId}/projectPersonRoles`), data);
    }

    /**
     * Updates the entity for a project integration.
     * @param projectIntegrationId - the project integration id with the desired entity.
     * @param entityId - he entity id that we want to update.
     * @param displayName - new display name.
     * @param description - Optional, new description.
     * @param isCollectEnabled - is collect enabled.
     * @param isAddRecordsEnabled - is add records in view data enabled.
     * @param turnOffSingleCollect - turn off single collect for entity.
     * @param entityWebhookDefinition - the entity webhook definition.
     */
    @bindThis
    public updateProjectIntegrationEntity(
        projectIntegrationId: string,
        entityId: string,
        displayName: string,
        description: string | undefined,
        isCollectEnabled: boolean,
        isAddRecordsEnabled: boolean,
        turnOffSingleCollect: boolean,
        entityWebhookDefinition?: ProjectIntegrationEntityWebhookDefinition,
    ) {
        return this.$http.post<ProjectIntegrationEntity>(this.buildUrl(`${projectIntegrationId}/${entityId}/update`), {
            displayName,
            description,
            isCollectEnabled,
            isAddRecordsEnabled,
            projectIntegrationEntityWebhookDefinition: entityWebhookDefinition,
            turnOffSingleCollect,
        });
    }

    @bindThis
    public deleteProjectIdentityProvider(projectId: string, identityProviderId: string) {
        return this.$http.delete(this.buildUrl(`${projectId}/${identityProviderId}`));
    }

    /**
     * Creates new entity for project integration.
     * @param projectIntegrationId - the project integration id with the desired entity.
     * @param displayName - the new entity name.
     * @param description - Optional, description text.
     * @return object that contains the created entityId.
     */
    @bindThis
    public createProjectIntegrationEntity(projectIntegrationId: string, displayName: string, description?: string) {
        return this.$http.post<{ createdEntityId: string }>(this.buildUrl(`${projectIntegrationId}/entity`), {
            displayName,
            description,
        });
    }

    /**
     * Getting the entity of project integration by id.
     * @param projectIntegrationId - the project integration id with the desired entity.
     * @param entityId - the entity id that we want to fetch.
     */
    @bindThis
    public getProjectIntegrationEntityById(projectIntegrationId: string, entityId: string) {
        return this.$http.get<ProjectIntegrationEntity>(this.buildUrl(`${projectIntegrationId}/${entityId}`));
    }

    @bindThis
    public getProjectIntegrationFetchingActionsByEntityId(projectIntegrationId: string, entityId: string) {
        return this.$http.get<{ entities: ProjectIntegrationAction[] }>(
            this.buildUrl(`${projectIntegrationId}/${entityId}/fetcherActions`),
        );
    }

    /**
     * Updates the entity for a project integration.
     * @param projectIntegrationId - the project integration id with the desired entity.
     * @param entityId - he entity id that we want to update.
     * @param isCollectEnabled - is collect enabled.
     */
    @bindThis
    public updateProjectIntegrationEntityIsCollectEnabled(
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        entityId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ENTITY>,
        isCollectEnabled: boolean,
    ) {
        return this.$http.post<ProjectIntegrationEntity>(this.buildUrl(`${projectIntegrationId}/${entityId}/update`), {
            isCollectEnabled,
        });
    }

    /**
     * get all field metadata for a project integration entity.
     * @param projectIntegrationEntityId - the project integration id with the desired entity.
     */
    @bindThis
    public getEntityFieldMetadata(
        projectIntegrationEntityId: string,
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        projectIntegrationIntegrationUniqueType: string,
    ) {
        return this.$http.get<{ entities: FieldMetadata[] }>(this.buildUrl(`${projectIntegrationEntityId}/metadata`), {
            params: {
                projectIntegrationId,
                projectIntegrationIntegrationUniqueType,
            },
        });
    }

    /**
     * Update field metadata in elastic.
     * @param projectIntegrationEntityId - the project integration entity id with the desired entity.
     * @param fieldMetadata - field metadata to update
     */
    @bindThis
    public updateFieldMetadataCommand(projectIntegrationEntityId: string, fieldMetadata: FieldMetadata) {
        return this.$http.post<void>(this.buildUrl(`${projectIntegrationEntityId}/updateFieldMetadata`), {
            fieldMetadata,
        });
    }

    /**
     * Updates the encrypted fields list for a project integration entity.
     * @param projectIntegrationId - the project integration id with the desired entity.
     * @param entityId - he entity id that we want to update.
     * @param encryptedFields - the fields to encrypt.
     */
    @bindThis
    public updateProjectIntegrationEntityEncryptedFields(
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        entityId: string,
        encryptedFields: BaseActionParameter[],
    ) {
        return this.$http.post<ProjectIntegrationEntity>(this.buildUrl(`${projectIntegrationId}/${entityId}/update`), {
            encryptedFields,
        });
    }

    /**
     * Getting the entities of project integration id.
     * @param projectIntegrationId - the project integration id with the desired entity.
     */
    @bindThis
    public getProjectIntegrationEntitySummaries(projectIntegrationId: string) {
        return this.$http.get<{ entities: ProjectIntegrationEntitySummaryWithIsImported[] }>(
            this.buildUrl(`${projectIntegrationId}/entities`),
        );
    }

    @bindThis
    public getEnterpriseComponentOverview<T extends EnterpriseComponentType>(
        projectId: string,
        enterpriseComponentId: string,
        enterpriseComponentType: T,
    ): Promise<ConvertEnterpriseComponentTypeToOverviewResult<T>> {
        return this.$http.get(
            this.buildUrl(`${projectId}/${enterpriseComponentType}/${enterpriseComponentId}/overview`),
        );
    }

    @bindThis
    public checkPeopleDirectoriesExistingByDisplayName(projectId: string, peopleDirectoriesDisplayNames: string[]) {
        return this.$http.get<Record<string, boolean>>(this.buildUrl(`${projectId}/checkExistingPeopleDirectories`), {
            params: {
                displayNames: peopleDirectoriesDisplayNames,
            },
        });
    }

    @bindThis
    public createSolutionBusinessReport(
        workflowFolderId: string,
        displayName: string,
        workflowVersionType: WorkflowVersionType,
        groups: string[],
    ) {
        return this.$http.post<{ id: string }>(this.buildUrl(`${workflowFolderId}/solutionBusinessReport`), {
            displayName,
            workflowVersionType,
            groups,
        });
    }

    @bindThis
    public updateSolutionBusinessReport(
        solutionBusinessReportId: string,
        updateOptions: {
            displayName?: string;
            permissions?: SolutionBusinessReportPermissionsUpdate;
            groups?: SolutionBusinessReportGroup[];
        },
    ) {
        return this.$http.post<undefined>(this.buildUrl(solutionBusinessReportId), updateOptions);
    }

    @bindThis
    public deleteSolutionBusinessReport(solutionBusinessReportId: string) {
        return this.$http.delete<undefined>(this.buildUrl(solutionBusinessReportId), {});
    }

    @bindThis
    public getSolutionBusinessReports(projectId: string) {
        return this.$http.get<SolutionBusinessReport[]>(this.buildUrl(`${projectId}/solutionBusinessReports`));
    }

    @bindThis
    public deleteSolutionBusinessReportFilter(reportId: string, filterId: string): Promise<void> {
        return this.$http.delete(this.buildUrl(`${reportId}/${filterId}`));
    }

    @bindThis
    public createSolutionBusinessReportFilter(
        reportId: string,
        groupId: string,
        displayName: string,
        definition: SolutionBusinessReportGroupFilters,
    ): Promise<SolutionBusinessReportSavedFilter> {
        return this.$http.post<SolutionBusinessReportSavedFilter>(this.buildUrl(`${reportId}/${groupId}/filters`), {
            displayName,
            definition,
        });
    }

    @bindThis
    public getSolutionBusinessReportById(solutionBusinessReportId: string, sharedReport: boolean) {
        return this.$http.get<{
            solutionBusinessReport: SolutionBusinessReport;
            groupIdToSavedFilters: SolutionBusinessReportSavedFilter[];
        }>(this.buildUrl(solutionBusinessReportId), {
            params: {
                sharedReport,
            },
        });
    }

    @bindThis
    public createItemInterface(
        projectId: string,
        workflowFolderId: string,
        groupId: string,
        displayName: string,
        configuration: ItemInterfaceConfiguration,
        description?: string,
        businessGroupsIds?: string[],
        smartAssistantPolicy?: string | undefined,
    ): Promise<ItemInterface> {
        return this.$http.post(this.buildUrl(`${projectId}/${workflowFolderId}/itemInterface`), {
            groupId,
            displayName,
            configuration,
            description,
            businessGroupsIds,
            smartAssistantPolicy,
        });
    }

    @bindThis
    public updateItemInterfaceById(
        itemInterfaceId: string,
        displayName: string,
        description: string,
        headerBackgroundColor: string,
        textColor: string,
        textAlignment: string,
        title: TonkeanExpressionDefinition,
        subtitle: TonkeanExpressionDefinition,
        groupId: TonkeanId<TonkeanType.GROUP>,
        businessGroupsIds: string[],
        showLogo: boolean,
        mainActionEntityId: TonkeanId<TonkeanType.FORM> | TonkeanId<TonkeanType.CUSTOM_TRIGGER> | undefined,
        mainActionLabel: string | undefined,
        mainActionEntityType: ItemInterfaceHeaderMainActionType | undefined,
        configuration: ItemInterfaceConfiguration | undefined,
        smartAssistantPolicy?: string | undefined,
    ): Promise<ItemInterface> {
        return this.$http.post(this.buildUrl(`${itemInterfaceId}`), {
            displayName,
            description,
            headerBackgroundColor,
            textColor,
            textAlignment,
            title,
            subtitle,
            groupId,
            businessGroupsIds,
            showLogo,
            mainActionEntityId,
            mainActionLabel,
            mainActionEntityType,
            configuration,
            smartAssistantPolicy,
        });
    }

    @bindThis
    public getItemInterfaceSmartAssistantPolicyById(
        itemInterfaceId?: string,
        groupId?: TonkeanId<TonkeanType.GROUP>,
        workflowVersionType?: WorkflowVersionType,
    ): Promise<{ smartAssistantPolicy: string | null } | null> {
        if (!itemInterfaceId || !groupId || !workflowVersionType) {
            return Promise.resolve(null);
        }

        return this.$http.get<{ smartAssistantPolicy: string | null }>(
            this.buildUrl(`${groupId}/${workflowVersionType}/${itemInterfaceId}/smartAssistantPolicy`),
        );
    }

    @bindThis
    public updateItemInterfaceLogoById(itemInterfaceId: string, icon: Blob): Promise<{ iconUrl: string }> {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': icon.type,
            },
        };
        return this.$http.post<{ iconUrl: string }>(this.buildUrl(`${itemInterfaceId}/logo`), icon, options);
    }

    @bindThis
    public uploadItemInterfaceWidgetImageById(widget: WidgetBase, icon?: Blob): Promise<{ iconUrl: string }> {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': icon?.type,
            },
        };
        return this.$http.post<{ iconUrl: string }>(
            this.buildUrl(`${widget.id}/${widget.parentEntityType.toString()}/uploadImage`),
            icon,
            options,
        );
    }

    @bindThis
    public updateDefaultItemInterface(
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        groupId: TonkeanId<TonkeanType.GROUP>,
        isDefault: boolean,
    ) {
        return this.$http.post(this.buildUrl(`${itemInterfaceId}/default`), { groupId, isDefault });
    }

    @bindThis
    public getDefaultItemInterfaceId(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>) {
        return this.$http.get<{ defaultItemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE> }>(
            this.buildUrl(`${workflowVersionId}/defaultItemInterfaceId`),
        );
    }

    @bindThis
    public deleteItemInterfaceLogoById(itemInterfaceId: string): Promise<void> {
        return this.$http.delete<void>(this.buildUrl(`${itemInterfaceId}/logo`));
    }

    @bindThis
    public getItemInterfacesByWorkflowVersionId(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>) {
        return this.$http.get<{
            entities: ItemInterface[];
        }>(this.buildUrl(`${workflowVersionId}/itemInterfaces`));
    }

    @bindThis
    public getItemInterfacesSummaryByGroupId(
        groupId: TonkeanId<TonkeanType.GROUP>,
        workflowVersionType: WorkflowVersionType,
    ) {
        return this.$http.get<{
            entities: ItemInterface[];
        }>(this.buildUrl(`${groupId}/${workflowVersionType}/itemInterfaces`));
    }

    @bindThis
    public getItemInterfacesSummaryByGroupIdOrByWorkflowVersionId(
        groupId: TonkeanId<TonkeanType.GROUP>,
        workflowVersionType: WorkflowVersionType | undefined,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    ) {
        if (groupId && workflowVersionType) {
            return this.getItemInterfacesSummaryByGroupId(groupId, workflowVersionType);
        } else {
            return this.getItemInterfacesByWorkflowVersionId(workflowVersionId);
        }
    }

    @bindThis
    public deleteItemInterface(itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>) {
        return this.$http.delete(this.buildUrl(`${itemInterfaceId}`));
    }

    @bindThis
    public duplicateInterface(entityId: TonkeanId<TonkeanType.ITEM_INTERFACE>) {
        const data = { entityId };
        return this.$http.post<ItemInterface>(this.buildUrl('duplicateInterface'), data);
    }

    @bindThis
    public getInterfaceWidgetPresets(parentEntityId: TonkeanId<WidgetParentTypes>) {
        return this.$http.get<{ entities: WidgetPreset[] }>(this.buildUrl(`widgetPresets`), {
            params: { parentEntityId },
        });
    }

    @bindThis
    public updateIntakeRequestViewersByInitiativeId(initiativeId: TonkeanId, customInterfaceId: TonkeanId) {
        return this.$http.put<{
            viewers: IntakeRequestViewer[];
        }>(this.buildUrl(`${initiativeId}/${customInterfaceId}/updateViewers`), {});
    }

    @asyncApiJob
    @bindThis
    public installEntityFromMarketplaceItemByTemplateName(
        data: FromMarketplaceItemCreationData,
    ): Promise<CreateFromMarketplaceResponse> {
        return this.$http.post(this.buildUrl(`${data.projectId}/marketplaceItem/install`), data);
    }

    @bindThis
    public getMarketplaceItemDirectInstallationStatus(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        requestId: string,
    ): Promise<MarketplaceItemDirectInstallResponse> {
        return this.$http.get(this.buildUrl(`${projectId}/marketplaceItem/direct/install/status`), {
            params: { requestId },
        });
    }

    @bindThis
    public getEnterpriseComponentInfoFromMarketplaceItem(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        templateName: string,
    ): Promise<EnterpriseComponentInfoFromMarketplaceItemResponse> {
        return this.$http.get(this.buildUrl(`${projectId}/marketplaceItem/dataSource/info`), {
            params: { templateName },
        });
    }

    @asyncApiJob
    @bindThis
    public createMarketplaceItem(data: MarketplaceItemCreationData, imageUrls: string[]): Promise<MarketplaceItem> {
        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('type', data.type);
        formData.append('projectId', data.projectId);
        formData.append('templateName', data.templateName);
        formData.append('shouldSubscribeOnInstall', data.shouldSubscribeOnInstall.toString());
        formData.append('isViewOnly', data.isViewOnly ? data.isViewOnly.toString() : 'false');
        formData.append('isHidden', data.isHidden ? data.isHidden.toString() : 'false');

        if (data.version) {
            formData.append('version', data.version);
        }
        if (data.entityId) {
            formData.append('entityId', data.entityId);
        }
        if (data.previewSummary) {
            formData.append('previewSummary', data.previewSummary);
        }
        if (data.iconUrl) {
            formData.append('iconUrl', data.iconUrl);
        }
        if (data.configuration) {
            formData.append('configuration', JSON.stringify(data.configuration));
        }
        if (data.subtitle) {
            formData.append('subTitle', JSON.stringify(data.subtitle));
        }
        if (data.categories) {
            // Form data is weird and in order to pass array we append the values to the same key
            data.categories.forEach((category) => {
                formData.append('categories', category);
            });
        }

        imageUrls?.forEach((url) => {
            // Form data is weird and in order to pass array we append the values to the same key
            formData.append('imageUrls', url);
        });

        return this.$http.post(this.buildUrl(`${data.projectId}/marketplaceItem`), formData, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined },
        });
    }

    @bindThis
    public uploadMarketplaceItemImages(
        projectId: string,
        images: TonkeanUploadedFile[],
        sectionImages: TonkeanUploadedFile[],
        entityId?: string,
    ): Promise<{ imageUrls: string[]; sectionImageUrls: string[] }> {
        const formData = new FormData();

        formData.append('projectId', projectId);
        if (entityId) {
            formData.append('entityId', entityId);
        }

        images.forEach((file) => {
            file.blob && formData.append('images', file.blob, file.name);
        });

        sectionImages.forEach((image) => {
            image.blob && formData.append('sectionImages', image.blob, image.name);
        });

        return this.$http.post<{ imageUrls: string[]; sectionImageUrls: string[] }>(
            this.buildUrl(`${projectId}/marketplaceItem/images`),
            formData,
            {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined },
            },
        );
    }

    @bindThis
    public updateMarketplaceItem(
        marketplaceItemId: TonkeanId<TonkeanType.MARKETPLACE_ITEM>,
        data: MarketplaceItemCreationData,
        imagesToDelete: string[],
        currentImages: string[],
    ): Promise<MarketplaceItem> {
        const formData = new FormData();

        formData.append('title', data.title);
        formData.append('type', data.type);
        formData.append('projectId', data.projectId);
        formData.append('templateName', data.templateName);
        formData.append('shouldSubscribeOnInstall', data.shouldSubscribeOnInstall.toString());
        formData.append('isViewOnly', data.isViewOnly ? data.isViewOnly.toString() : 'false');
        formData.append('isHidden', data.isHidden ? data.isHidden.toString() : 'false');

        if (data.version) {
            formData.append('version', data.version);
        }
        if (data.entityId) {
            formData.append('entityId', data.entityId);
        }
        if (data.previewSummary) {
            formData.append('previewSummary', data.previewSummary);
        }
        if (data.iconUrl) {
            formData.append('iconUrl', data.iconUrl);
        }
        if (data.configuration) {
            formData.append('configuration', JSON.stringify(data.configuration));
        }
        if (data.subtitle) {
            formData.append('subTitle', JSON.stringify(data.subtitle));
        }
        if (data.categories) {
            // Form data is weird and in order to pass array we append the values to the same key
            data.categories.forEach((category) => {
                formData.append('categories', category);
            });
        }

        currentImages?.forEach((url) => {
            formData.append('currentImages', url);
        });

        imagesToDelete.forEach((imageToDelete) => {
            formData.append('imagesToDelete', imageToDelete);
        });

        return this.$http.post(this.buildUrl(`${data.projectId}/${marketplaceItemId}`), formData, {
            headers: { 'Content-Type': undefined },
        });
    }

    @bindThis
    public updateMarketplaceItemCreationJson(
        marketplaceItemId: TonkeanId<TonkeanType.MARKETPLACE_ITEM>,
        projectId: TonkeanId<TonkeanType.PROJECT>,
        type: MarketplaceItemType,
        version: string,
        entityId?: TonkeanId,
    ): Promise<MarketplaceItem> {
        const data = {
            entityId,
            type,
            version,
        };

        return this.$http.post(this.buildUrl(`${projectId}/${marketplaceItemId}/creationJson`), data);
    }

    @bindThis
    public getMarketplaceItems(
        projectId: TonkeanId<TonkeanType.PROJECT>,
    ): Promise<{ entities: MarketplaceItem[]; rootEntitiesToDisplayName: Record<string, string> }> {
        return this.$http.get(this.buildUrl(`${projectId}/marketplaceItems`));
    }

    @bindThis
    public getProjectIntegrationDependentMarketplaceItems(
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
    ): Promise<MarketplaceItemSummary[]> {
        return this.$http.get(this.buildUrl(`${projectIntegrationId}/marketplaceItems`));
    }

    @bindThis
    public getMarketplaceItemBlob(marketplaceItemId: TonkeanId<TonkeanType.MARKETPLACE_ITEM>): Promise<Blob> {
        return this.$http.get(this.buildUrl(`${marketplaceItemId}/download`), { responseType: 'blob' });
    }

    @bindThis
    public getMarketplaceItemById(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        marketplaceItemId: TonkeanId<TonkeanType.MARKETPLACE_ITEM>,
    ): Promise<MarketplaceItem> {
        return this.$http.get(this.buildUrl(`${projectId}/${marketplaceItemId}`));
    }

    @bindThis
    public deleteMarketplaceItem(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        marketplaceItemId: TonkeanId<TonkeanType.MARKETPLACE_ITEM>,
    ): Promise<void> {
        return this.$http.delete(this.buildUrl(`${projectId}/${marketplaceItemId}`));
    }

    @bindThis
    public getMarketplaceItemFromStorage(templateName: string, type: MarketplaceItemType): Promise<MarketplaceItem> {
        return this.$http.get<MarketplaceItem>(this.buildUrl(`marketplaceItem`), {
            params: { templateName, type },
        });
    }

    @bindThis
    public getHomepageProjectSummary(
        projectUrlSlug: string,
        fallbackProjectId: TonkeanId<TonkeanType.PROJECT> | undefined,
    ): Promise<ProjectHomepageSummary> {
        const params = {
            projectUrlSlug,
            fallbackProjectId,
        };
        return this.$http.get<ProjectHomepageSummary>(this.buildUrl(`homepageProjectSummary`), { params });
    }

    @bindThis
    public getHomepageSolutionSites(
        projectId: TonkeanId<TonkeanType.PROJECT>,
    ): Promise<FetchResults<SolutionSiteHomepageSummary>> {
        return this.$http.get<FetchResults<SolutionSiteHomepageSummary>>(this.buildUrl(`${projectId}/homepageSites`));
    }

    @bindThis
    public getHomepageMyItemsGroups(
        projectId: TonkeanId<TonkeanType.PROJECT>,
    ): Promise<TonkeanId<TonkeanType.GROUP>[]> {
        return this.$http.get<TonkeanId<TonkeanType.GROUP>[]>(this.buildUrl(`${projectId}/homepage/itemsGroups`));
    }

    @bindThis
    public getSolutionSiteByWorkflowFolderId(
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    ): Promise<SolutionSite> {
        return this.$http.get<SolutionSite>(this.buildUrl(`${workflowFolderId}/solutionSite`));
    }

    @bindThis
    public getSolutionSiteById(solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE>) {
        return this.$http.get<SolutionSite>(this.buildUrl(solutionSiteId));
    }

    @bindThis
    public getTransformedSolutionSiteUrlSlugs(projectUrlSlug: string, solutionSiteUrlSlug: string) {
        return this.$http.get<{
            projectId: TonkeanId<TonkeanType.PROJECT>;
            solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE>;
            hasAccess: boolean;
        }>(this.buildUrl('siteTransformedSlugs'), {
            params: {
                projectUrlSlug,
                solutionSiteUrlSlug,
            },
        });
    }

    @bindThis
    public getPreSignedUrlForUploadMarketplaceItemToStorage(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        templateName: string,
    ) {
        const data = {
            templateName,
        };

        return this.$http.post<{ preSignedUrl: string }>(this.buildUrl(`${projectId}/marketplaceItemInstallUrl`), data);
    }

    /**
     * This function uploads a marketplace item to a third party storage like s3 by a pre-signed url (Without going through tonkean's backend).
     */
    @bindThis
    public uploadMarketplaceItemToStorageByPreSignedUrl(url: string, marketplaceItemJson: string) {
        const blob = new Blob([marketplaceItemJson], { type: 'application/json' });

        return this.$http.put<void>(`${url}`, blob, {
            headers: {
                'Content-Type': blob.type,
            },
        });
    }

    @bindThis
    public startInstallingMarketplaceItemFromStorage(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        templateName: string,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER> | undefined,
        workflowFolderCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY> | undefined,
        creationJsonDependencies?: CreationJsonDependencies,
    ) {
        const data = {
            templateName,
            workflowFolderId,
            workflowFolderCategoryId,
            creationJsonDependencies,
        };

        return this.$http.post<{ requestId: string }>(
            this.buildUrl(`${projectId}/marketplaceItem/direct/install`),
            data,
        );
    }

    @bindThis
    public swapSolutionSitePagesIndices(
        solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE>,
        firstPageId: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>,
        secondPageId: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>,
    ) {
        const data = {
            firstPageId,
            secondPageId,
        };

        return this.$http.post<void>(this.buildUrl(`${solutionSiteId}/swapSolutionSitePageIndices`), data);
    }

    @bindThis
    public updateSolutionSite(
        solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE>,
        displayName: string,
        description: string,
        urlSlug: string,
        headerBackgroundColor: Color,
        icon: Icon | undefined,
        showLogo: boolean | undefined,
        mainActionFormId: TonkeanId<TonkeanType.FORM> | undefined,
        mainActionLabel: string | undefined,
        homepageEnabled: boolean,
        configuration: SolutionSiteConfiguration | undefined,
    ): Promise<void> {
        return this.$http.post(this.buildUrl(`${solutionSiteId}`), {
            solutionSiteId,
            displayName,
            description,
            urlSlug,
            icon,
            headerBackgroundColor,
            showLogo,
            mainActionFormId,
            mainActionLabel,
            homepageEnabled,
            configuration,
        });
    }

    @bindThis
    public createMarketplaceItemForNativeIntegration(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        integrationType: string,
        entities: { displayName: string }[],
        actions: { displayName: string }[],
        iconUrl: string,
    ) {
        return this.$http.post<{ marketplaceItemId: string; isCreated: boolean; displayName: string }>(
            this.buildUrl(`${projectId}/marketplaceItem/native-integration/${integrationType}`),
            {
                entities,
                actions,
                iconUrl,
            },
        );
    }

    @bindThis
    public updateSolutionSiteLogoById(
        solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE>,
        logo: Blob,
    ): Promise<{ logoUrl: string }> {
        const options = {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': logo.type,
            },
        };
        return this.$http.post<{ logoUrl: string }>(this.buildUrl(`${solutionSiteId}/logo`), logo, options);
    }

    @bindThis
    public deleteSolutionSiteLogoById(solutionSiteId: string): Promise<void> {
        return this.$http.delete<void>(this.buildUrl(`${solutionSiteId}/logo`));
    }

    @bindThis
    public createSolutionSitePage(
        solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE>,
        params: SolutionSitePageCreateParams,
    ): Promise<SolutionSitePage> {
        return this.$http.post(this.buildUrl(`${solutionSiteId}/page`), {
            ...params,
        });
    }

    @bindThis
    public updateSolutionSitePage(
        solutionSitePageId: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>,
        params: SolutionSitePageCreateParams,
    ): Promise<void> {
        return this.$http.post(this.buildUrl(`${solutionSitePageId}`), {
            ...params,
        });
    }

    @bindThis
    public getPagesOfSolutionSite(solutionSiteId: TonkeanId<TonkeanType.SOLUTION_SITE>): Promise<{
        entities: SolutionSitePage[];
    }> {
        return this.$http.get(this.buildUrl(`${solutionSiteId}/pages`));
    }

    @bindThis
    public getPagesOfSolutionSiteByWorkflowFolder(workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>): Promise<{
        entities: SolutionSitePage[];
    }> {
        return this.$http.get(this.buildUrl(`${workflowFolderId}/pages`));
    }

    @bindThis
    public deleteSolutionSitePage(solutionSitePageId: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>) {
        return this.$http.delete<void>(this.buildUrl(solutionSitePageId));
    }

    /**
     * Gets the inspect tabs of worker run logic
     */
    @bindThis
    public getWorkerRunLogicInspectTabs(
        projectId: string,
        workerRunId: string,
        workerRunLogicId: string,
        workerRunStartTime: number,
    ) {
        return this.$http.get<{ inspectTabs: WorkerRunLogicInspectTabBase[] }>(
            this.buildUrl(`${projectId}/${workerRunLogicId}/inspectTabs`),
            { params: { workerRunId, workerRunStartTime } },
        );
    }

    /**
     * Gets simple nlp matching configuration sensitivity options
     */
    @bindThis
    public getSimpleNlpMatchingConfigurationSensitivityOptions(projectId: string) {
        return this.$http.get<{
            sensitivityLevels: string[];
        }>(this.buildUrl(`${projectId}/nlpSensitivityOptions`));
    }

    @bindThis
    public getPeopleDirectoriesByDisplayNames(projectId: string, displayNames: string[]) {
        return this.$http.get<FetchResults<PeopleDirectory>>(
            this.buildUrl(`${projectId}/peopleDirectoriesByDisplayName`),
            {
                params: { displayNames },
            },
        );
    }

    @bindThis
    public getRecipientsPreviewFromTonkeanExpression(
        workflowVersionId: string,
        initiativeId: string | undefined,
        expression: string,
    ) {
        return this.$http.get<RecipientsPreviewFromTonkeanExpressionsResponse>(
            this.buildUrl(`${workflowVersionId}/peopleDirectoriesRecipientsPreview`),
            {
                params: {
                    expression,
                    initiativeId: initiativeId || undefined,
                },
            },
        );
    }

    @bindThis
    public getItemInterfaceDataForDisplay(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        originWidget?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
        workflowVersionId?: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        workflowVersionType?: WorkflowVersionType,
    ) {
        return this.$http.get<ItemInterfaceFullData>(
            this.buildUrl(
                `${getProjectIdPath(projectId)}/${getInitiativeIdPath(initiativeId)}/${getItemInterfaceIdPath(
                    itemInterfaceId,
                )}`,
            ),
            {
                params: {
                    originWidget,
                    workflowVersionId,
                    workflowVersionType,
                },
            },
        );
    }

    @bindThis
    public getItemInterfaceById(
        itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        originWidget?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
    ) {
        return this.$http.get<ItemInterface>(this.buildUrl(`${workflowVersionId}/${itemInterfaceId}`), {
            params: {
                originWidget,
            },
        });
    }

    @bindThis
    public createInterfaceWidget(
        parentEntityId: TonkeanId<WidgetParentTypes>,
        displayName: string,
        title: string,
        itemInterfaceWidgetType: ItemInterfaceWidgetType,
        displayColumns: number,
        configuration: WidgetConfiguration,
        showDisplayDescriptionAsTooltip: boolean,
        displayDescription?: TElement[],
    ) {
        return this.$http.post<WidgetBase>(this.buildUrl(`widget`), {
            parentEntityId,
            displayName,
            title,
            displayColumns,
            itemInterfaceWidgetType,
            configuration,
            displayDescription,
            showDisplayDescriptionAsTooltip,
        });
    }

    @bindThis
    public duplicateInterfaceWidget(
        interfaceWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
        parentEntityType: WidgetParentTypes,
    ) {
        return this.$http.post<WidgetBase>(this.buildUrl(`${interfaceWidgetId}/duplicate`), {
            parentEntityType,
        });
    }

    @bindThis
    public updateWidgetById(widget: WidgetBase) {
        return this.$http.post<WidgetBase>(this.buildUrl(widget.id), {
            parentEntityType: widget.parentEntityType,
            configuration: widget.configuration,
            displayColumns: widget.displayColumns,
            displayName: widget.displayName,
            displayDescription: widget.displayDescription,
            showDisplayDescriptionAsTooltip: widget.showDisplayDescriptionAsTooltip,
        });
    }

    @bindThis
    public deleteInterfaceWidgetById(
        itemInterfaceWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
        parentEntityId: TonkeanId<WidgetParentTypes>,
    ) {
        return this.$http.delete<void>(this.buildUrl(itemInterfaceWidgetId), { params: { parentEntityId } });
    }

    @bindThis
    public swapInterfaceWidgetIndices(
        parentEntityId: TonkeanId<WidgetParentTypes>,
        firstWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
        secondWidgetId: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
    ) {
        const data = {
            firstWidgetId,
            secondWidgetId,
        };

        return this.$http.post<void>(this.buildUrl(`${parentEntityId}/swapWidgetIndices`), data);
    }

    @bindThis
    public getInterfaceWidgets<T extends WidgetBase>(
        parentEntityId: TonkeanId<WidgetParentTypes>,
        versionId: TonkeanId<TonkeanType.WORKFLOW_VERSION | TonkeanType.ENTITY_VERSION>,
        versionType: WorkflowVersionType,
        initiativeId?: TonkeanId<TonkeanType.INITIATIVE>,
    ) {
        return this.$http.get<GetInterfaceWidgetResponse<T>>(this.buildUrl(`widgets`), {
            params: {
                parentEntityId,
                versionId,
                versionType,
                initiativeId,
            },
        });
    }

    @bindThis
    public getEnterpriseComponentVariables(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        enterpriseComponentId: EnterpriseComponentId,
        relatedEntityId?: EnterpriseComponentVariableRelatedEntityId,
    ) {
        return this.$http.get<{
            entities: EnterpriseComponentVariableWithIsImported[];
            dependentProjectIntegrationSubEntitiesInfo: Record<
                TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>,
                SubEntityInfo[]
            >;
        }>(this.buildUrl(`${projectId}/${enterpriseComponentId}/variables`), { params: { relatedEntityId } });
    }

    @bindThis
    public getUploadCustomActionByProjectIntegrationId(
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
    ) {
        return this.$http.get<{
            uploadProjectIntegrationAction: ProjectIntegrationAction;
        }>(this.buildUrl(`${projectIntegrationId}/uploadAction`));
    }

    @bindThis
    public getProjectIntegrationStorageConfiguration(projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>) {
        return this.$http.get<{
            storageIntegrationConfiguration: StorageIntegrationConfiguration;
        }>(this.buildUrl(`${projectIntegrationId}/storageConfiguration`));
    }

    @bindThis
    public createEnterpriseComponentVariable(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        enterpriseComponentId: EnterpriseComponentId,
        displayName: string,
        description: string,
        isRequired: boolean,
        isEncrypted: boolean,
        valueType: EnterpriseComponentVariableValueType,
    ) {
        return this.$http.post<EnterpriseComponentVariable>(
            this.buildUrl(`${projectId}/${enterpriseComponentId}/variable`),
            {
                displayName,
                description,
                relatedEntityId: enterpriseComponentId,
                isRequired,
                isEncrypted,
                valueType,
            },
        );
    }

    @bindThis
    public updateEnterpriseComponentVariable(
        id: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>,
        projectId: TonkeanId<TonkeanType.PROJECT>,
        enterpriseComponentId: EnterpriseComponentId,
        displayName: string,
        description: string,
        isRequired: boolean,
        isEncrypted: boolean,
        valueType: EnterpriseComponentVariableValueType,
    ) {
        return this.$http.post<EnterpriseComponentVariable>(
            this.buildUrl(`${projectId}/${enterpriseComponentId}/variable/${id}`),
            {
                displayName,
                description,
                isRequired,
                isEncrypted,
                valueType,
            },
        );
    }

    @bindThis
    public deleteEnterpriseComponentVariable(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        enterpriseComponentId: EnterpriseComponentId,
        id: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>,
    ) {
        return this.$http.delete(this.buildUrl(`${projectId}/${enterpriseComponentId}/variable/${id}`));
    }

    @bindThis
    public getItemInterfaceAndWidgetByInitiativeId(
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        originWidget?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
    ) {
        return this.$http.get<{
            itemInterface: ItemInterface;
            itemInterfaceWidgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
            includedWidgetsSummaryByInterfaceId?: IncludedWidgetsSummaryByInterface;
        }>(this.buildUrl(`${initiativeId}/itemInterfaceAndWidgets`), { params: { originWidget } });
    }

    @bindThis
    public getWorkflowFolderCategories(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    ) {
        return this.$http.get<{
            workflowFolderCategories: WorkflowFolderCategory[];
            processParticipants?: ProcessParticipant[];
            moduleSuggestions?: Record<TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>, ModuleMarketplaceItem[]>;
        }>(this.buildUrl(`${projectId}/${workflowFolderId}/categories`));
    }

    /**
     * Builds a url for the API
     * @param path The API path
     * @return  a URL.
     */
    protected buildUrl(path: string): string {
        return this.environment.apiUrl + path;
    }

    @bindThis
    public generateFormula(projectId: TonkeanId<TonkeanType.PROJECT>, userPrompt: string, conversationId?: string) {
        const data = {
            userPrompt,
            conversationId,
        };

        return this.$http.post<SmartConversationMessage<FormulaAIServerResponse>>(
            this.buildUrl(`${projectId}/generateFormula`),
            data,
        );
    }

    @bindThis
    public createWorkflowFolderCategory(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        displayName: string,
        description: string,
        outcomeDefinition: TElement[],
    ) {
        return this.$http.post<WorkflowFolderCategory>(this.buildUrl(`${projectId}/${workflowFolderId}/category`), {
            displayName,
            description,
            outcomeDefinition,
        });
    }

    @bindThis
    public updateWorkflowFolderCategory(
        id: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        displayName: string,
        description: string,
        outcomeDefinition: TElement[],
    ) {
        return this.$http.post<WorkflowFolderCategory>(
            this.buildUrl(`${projectId}/${workflowFolderId}/category/${id}`),
            {
                displayName,
                description,
                outcomeDefinition,
            },
        );
    }

    @bindThis
    public swapWorkflowFolderCategoryIndices(
        firstCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
        secondCategoryId: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    ) {
        const data = {
            firstWorkflowFolderCategoryId: firstCategoryId,
            secondWorkflowFolderCategoryId: secondCategoryId,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/${workflowFolderId}/categories/swapIndices`), data);
    }

    @bindThis
    public deleteWorkflowFolderCategory(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        id: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>,
    ) {
        return this.$http.delete(this.buildUrl(`${projectId}/${workflowFolderId}/category/${id}`));
    }

    @bindThis
    public setIntegrationEncryptionKey(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        encryptionKey: string,
    ) {
        const body = {
            encryptionKey,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/${projectIntegrationId}/key`), body);
    }

    /**
     * Create new incoming webhook for Facebookads.
     */
    @bindThis
    public createFacebookAdsIncomingWebhook(projectId: string, projectIntegrationId: string) {
        const data = {
            projectIntegrationId,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/incomingWebhooks/facebookAds`), data);
    }

    /**
     * Get all tonkean data sources
     */
    @bindThis
    public getAllDataSources() {
        return this.$http.get<void>('https://s3-us-west-2.amazonaws.com/tonkean-files/webhook_integrations.json');
    }

    @bindThis
    public getWorkflowFolderGlobalFieldDefinitions(
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        workflowVersionType: WorkflowVersionType = WorkflowVersionType.DRAFT,
    ) {
        return this.$http.get<{ entities: FieldDefinition[] }>(
            this.buildUrl(`${workflowFolderId}/${workflowVersionType}/globalFieldDefinitions`),
        );
    }

    /**
     * Gets entity types of an integration
     * */
    @bindThis
    public getIntegrationEntityTypes(projectId: TonkeanId<TonkeanType.PROJECT>, integrationType: string) {
        const params = {
            integrationType,
        };

        return this.$http.get<string[]>(this.buildUrl(`${projectId}/entityTypes`), { params });
    }

    @bindThis
    public commitSolutionSitePage(solutionSitePageId: TonkeanId<TonkeanType.SOLUTION_SITE_PAGE>, comment: string) {
        return this.$http.post<EntityVersion>(this.buildUrl(`${solutionSitePageId}/commitVersion`), { comment });
    }

    @bindThis
    public markSolutionSitePageAsReadyToPublish(solutionSitePageId: SolutionSitePage['id'], comment: string) {
        return this.$http.post<SolutionSitePage>(this.buildUrl(`${solutionSitePageId}/markForPublish`), { comment });
    }

    @bindThis
    public checkIfAnySolutionSitePagesAreDirty(workflowFolderId: WorkflowFolder['id']) {
        return this.$http.get<boolean>(this.buildUrl(`${workflowFolderId}/solutionSitePagesDirty`));
    }

    @bindThis
    public revertSolutionSitePage(solutionSitePageId: SolutionSitePage['id']) {
        return this.$http.post(this.buildUrl(`${solutionSitePageId}/revert`), {});
    }

    @bindThis
    public getUseGraphqlInForms(projectId: Project['id']) {
        return this.$http.get<{ useGraphql: boolean; useGraphqlUpdate: boolean }>(
            this.buildUrl(`${projectId}/useGraphqlForms`),
        );
    }

    @bindThis
    public checkPersonAccessControlToInitiative(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        personId: TonkeanId<TonkeanType.PERSON>,
    ) {
        return this.$http.get<WorkflowFolderAccessControl>(
            this.buildUrl(`${projectId}/${initiativeId}/${personId}/accessControlToInitiative`),
        );
    }

    @bindThis
    public upsertExternalActivity(
        fieldsValues: FieldValue[],
        projectId: TonkeanId<TonkeanType.PROJECT>,
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
        projectIntegrationEntityId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ENTITY>,
        created?: number,
        allowEdit: boolean = true,
    ) {
        const data = {
            fieldsValues,
            created,
            allowEdit,
        };
        return this.$http.post<void>(
            this.buildUrl(`${projectId}/${projectIntegrationId}/${projectIntegrationEntityId}/upsertExternalActivity`),
            data,
        );
    }

    @bindThis
    public getCategoricalItemInterfaceFieldChartWidgetData(
        workflowVersionId: WorkflowVersion['id'],
        itemInterfaceWidgetId: ItemInterfaceWidget['id'],
    ) {
        return this.$http.get<any>(
            this.buildUrl(`${workflowVersionId}/${itemInterfaceWidgetId}/itemInterfaceFieldChartWidgetData`),
        );
    }

    @bindThis
    public getCategoricalSolutionSiteFieldChartWidgetData(
        workflowVersionType: WorkflowVersionType,
        itemInterfaceWidgetId: ItemInterfaceWidget['id'],
    ) {
        const params = {
            workflowVersionType,
        };

        return this.$http.get<any>(this.buildUrl(`${itemInterfaceWidgetId}/solutionSiteFieldChartWidgetData`), {
            params,
        });
    }

    @bindThis
    public getMonthlyPricingSummaries(projectId: TonkeanId<TonkeanType.PROJECT>) {
        return this.$http.get<{
            activeUsersMonthly: PricingSummary[];
            pricingItemsMonthly: PricingSummary[];
        }>(this.buildUrl(`${projectId}/monthlyPricingSummaries`), {});
    }

    @bindThis
    public async exportActiveUsersCsv(projectId: TonkeanId<TonkeanType.PROJECT>, fileName: string) {
        return this.$http
            .get<string>(this.buildUrl(`${projectId}/activeUsers/download`), { params: { fileName } })
            .then((response) => this.utils.downloadFile(response, 'text/csv', fileName, 'csv'));
    }

    /**
     * Returns a http request configuration with an etag header in it, initialized to the given etag value.
     */
    private getETagConfig(etagValue) {
        const config: IRequestShortcutConfig = { headers: {} };
        if (etagValue) {
            config.headers![TONKEAN_E_TAG_STR] = etagValue;
        }

        return config;
    }

    @bindThis
    public updateItemDetails(
        groupId: TonkeanId<TonkeanType.GROUP>,
        itemDetailsId: TonkeanId<TonkeanType.ITEM_DETAILS>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        name: string,
        description: string,
        requesterExpression: TonkeanExpressionDefinition | null | undefined,
    ) {
        const body = {
            groupId,
            name,
            description,
            requesterExpression,
        };

        return this.$http.post<void>(this.buildUrl(`${workflowVersionId}/${itemDetailsId}/itemDetails`), body);
    }

    @bindThis
    public createItemDetails(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        groupId: TonkeanId<TonkeanType.GROUP>,
        targetId: TonkeanId<TonkeanType.GROUP> | TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        name: string,
        description: string,
    ) {
        const body = {
            groupId,
            targetId,
            name,
            description,
        };

        return this.$http.post<void>(this.buildUrl(`${projectId}/${workflowVersionId}/itemDetails`), body);
    }

    @bindThis
    public createStripeBillingPortalSession(projectId: Project['id']) {
        return this.$http.post<{ url: string }>(this.buildUrl(`${projectId}/billingPortalSession`), {});
    }

    @bindThis
    public sendInitialProcessDescription(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        initialProcessDescription: string,
    ): Promise<ProcessBuilderMissingQuestionsResponse> {
        return this.$http.post<ProcessBuilderMissingQuestionsResponse>(this.buildUrl(`${projectId}/processBuilder`), {
            userPrompt: initialProcessDescription,
        });
    }

    @bindThis
    public answerProcessBuildQuestion(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        userPrompt: string,
        questionId: string,
        conversationId: string | undefined,
    ) {
        const data = {
            userPrompt,
            questionId,
            conversationId,
        };

        return this.$http.post<SmartConversationMessage<ProcessBuilderAnsweredMissingQuestionAIServerMessage>>(
            this.buildUrl(`${projectId}/iterateQuestionsProcessBuilder`),
            data,
        );
    }

    /**
     * Creates a process builder
     */
    @bindThis
    public createProcessBuilder(workflowFolderId: string) {
        return this.$http.post<ProcessBuilder>(this.buildUrl(`${workflowFolderId}/new/processBuilder`), {});
    }

    @bindThis
    public setInputSourceContract(
        groupId: TonkeanId<TonkeanType.GROUP>,
        contractId: TonkeanId<TonkeanType.CONTRACT> | undefined,
    ): Promise<void> {
        if (contractId) {
            return this.$http.post(this.buildUrl(`${groupId}/inputSourceContract`), {
                contractId: contractId,
            });
        } else {
            return this.$http.delete(this.buildUrl(`${groupId}/inputSourceContract`));
        }
    }

    @bindThis
    public getInputSourceContract(
        groupId: TonkeanId<TonkeanType.GROUP>,
        workflowVersionType: WorkflowVersionType | undefined,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    ): Promise<{
        contractId: TonkeanId<TonkeanType.CONTRACT>;
    }> {
        return this.$http.get(
            this.buildUrl(`${groupId}/${workflowVersionType || workflowVersionId}/inputSourceContract`),
            {},
        );
    }

    @bindThis
    public getIsDuplicateGroupAllowed(groupId: TonkeanId<TonkeanType.GROUP>): Promise<{
        isDuplicateGroupAllowed: boolean;
    }> {
        return this.$http.get(this.buildUrl(`${groupId}/isDuplicateGroupAllowed`), {});
    }

    @bindThis
    public getContractSummaries(groupId: TonkeanId<TonkeanType.GROUP>): Promise<{ entities: ContractSummary[] }> {
        return this.$http.get<{
            entities: ContractSummary[];
        }>(this.buildUrl(`${groupId}/contracts`), {});
    }

    @bindThis
    public updateContractFieldMapping(
        groupId: TonkeanId<TonkeanType.GROUP>,
        contractFieldId: TonkeanId<TonkeanType.CONTRACT_FIELD>,
        targetId: string | undefined,
        contractFieldType: ContractFieldType,
    ): Promise<void> {
        if (targetId) {
            let targetType = ContractFieldMappingTargetType.FIELD_DEFINITION;
            if (contractFieldType === ContractFieldType.OUTPUT || contractFieldType === ContractFieldType.INPUT) {
                targetType = targetId.startsWith('TNK_')
                    ? ContractFieldMappingTargetType.SPECIAL_FIELD
                    : ContractFieldMappingTargetType.FIELD_DEFINITION;
            } else if (contractFieldType === ContractFieldType.ITEM_INTERFACE) {
                targetType = ContractFieldMappingTargetType.ITEM_INTERFACE;
            } else if (contractFieldType === ContractFieldType.INTAKE_SEQUENCE) {
                targetType = ContractFieldMappingTargetType.INTAKE_SEQUENCE;
            }

            return this.$http.post(this.buildUrl(`${groupId}/inputSourceContract/${contractFieldId}`), {
                targetId,
                targetType,
            });
        } else {
            return this.$http.delete(this.buildUrl(`${groupId}/inputSourceContract/${contractFieldId}`));
        }
    }

    @bindThis
    public getContractFieldsMapping(
        groupId: TonkeanId<TonkeanType.GROUP>,
        workflowVersionType: WorkflowVersionType | undefined,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        contractId: TonkeanId<TonkeanType.CONTRACT>,
    ): Promise<{
        entities: ContractFieldMapping[];
    }> {
        return this.$http.get<{
            entities: ContractFieldMapping[];
        }>(this.buildUrl(`${groupId}/${workflowVersionType || workflowVersionId}/${contractId}/mappings`), {});
    }

    @bindThis
    public getProcessBuilderByWorkflowFolderId(workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>) {
        return this.$http.get<ProcessBuilder | undefined>(this.buildUrl(`${workflowFolderId}/processBuilder`), {});
    }

    /**
     * Updates a process builder
     */
    @bindThis
    public updateProcessBuilder(
        workflowFolderId: string,
        title?: string,
        originalText?: TElement[],
        suggestedText?: TElement[],
    ) {
        const data = { title, originalText, suggestedText };

        return this.$http.post<undefined>(this.buildUrl(`${workflowFolderId}/update/processBuilder`), data);
    }

    /**
     * Generates a process mapper from a suggested text from the process planner
     */
    @asyncApiJob
    @bindThis
    public generateProcessMapper(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        suggestedText: string,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
    ) {
        const data = { userPrompt: suggestedText, workflowFolderId };

        return this.$http.post<{ solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER> }>(
            this.buildUrl(`${projectId}/generateProcessMapper`),
            data,
        );
    }

    @bindThis
    public triggerButtonCustomTrigger(
        customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
        triggerActionName: undefined | string,
        actionTriggeredOnInterfaceLoad?: boolean,
    ) {
        const data = {
            triggerActionName,
            actionTriggeredOnInterfaceLoad,
        };

        return this.$http.post<{ solutionMapperId: TonkeanId<TonkeanType.SOLUTION_MAPPER> }>(
            this.buildUrl(`${customTriggerId}/${initiativeId}/triggerAction`),
            data,
        );
    }

    @bindThis
    public getProcessMappersByWorkflowVersionId(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>) {
        return this.$http.get<{
            entities: ProcessMapper[];
        }>(this.buildUrl(`${workflowVersionId}/processMappers`));
    }

    @bindThis
    public createProcessMapper(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>,
        groupId: string,
        displayName: string,
        description?: string,
    ): Promise<ProcessMapper> {
        return this.$http.post(this.buildUrl(`${projectId}/${workflowFolderId}/processMapper`), {
            groupId,
            displayName,
            description,
        });
    }

    @bindThis
    public updateProcessMapperById(
        processMapperId: string,
        displayName: string,
        description: string,
    ): Promise<ProcessMapper> {
        return this.$http.post(this.buildUrl(`${processMapperId}`), {
            displayName,
            description,
        });
    }

    @bindThis
    public getProcessMapperFullData(
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    ) {
        return this.$http.get<{
            processMapper: ProcessMapper;
            processMapperNodes: ProcessMapperNode[];
            processMapperEdges: ProcessMapperEdge[];
        }>(this.buildUrl(`${workflowVersionId}/${processMapperId}`));
    }

    @bindThis
    public getProcessMapperNodes(
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        initiativeId?: TonkeanId<TonkeanType.INITIATIVE>,
    ) {
        return this.$http.get<{
            entities: ProcessMapperNode[];
            nodeIdToStageMap: Record<TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>, ProcessMapperNodeStage>;
        }>(this.buildUrl(`${workflowVersionId}/${processMapperId}/processMapperNodes`), { params: { initiativeId } });
    }

    @bindThis
    public createProcessMapperNode(
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
        graphX: number,
        graphY: number,
    ): Promise<ProcessMapperNode> {
        return this.$http.post(this.buildUrl(`${processMapperId}/processMapperNode`), {
            graphX,
            graphY,
        });
    }

    @bindThis
    public updateProcessMapperNode(processMapperNode: ProcessMapperNode): Promise<ProcessMapperNode> {
        return this.$http.post(this.buildUrl(processMapperNode.id), processMapperNode);
    }

    @bindThis
    public getProcessMapperEdges(
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    ) {
        return this.$http.get<{
            entities: ProcessMapperEdge[];
        }>(this.buildUrl(`${workflowVersionId}/${processMapperId}/processMapperEdges`));
    }

    @bindThis
    public createProcessMapperEdge(
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
        source: TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>,
        target: TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>,
    ): Promise<ProcessMapperEdge> {
        return this.$http.post(this.buildUrl(`${processMapperId}/processMapperEdge`), {
            source,
            target,
        });
    }

    @bindThis
    public createJointNodeInServer(
        processMapperEdge: TonkeanId<TonkeanType.PROCESS_MAPPER_EDGE>,
    ): Promise<ProcessMapperNode> {
        return this.$http.post(this.buildUrl(`${processMapperEdge}/joint`), {});
    }

    @bindThis
    public deleteProcessMapperNode(
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
        processMapperNodeId: TonkeanId<TonkeanType.PROCESS_MAPPER_NODE>,
    ): Promise<void> {
        return this.$http.delete<void>(this.buildUrl(processMapperNodeId), { params: { processMapperId } });
    }

    @bindThis
    public getContractFields(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        contractId: TonkeanId<TonkeanType.CONTRACT>,
        contractFieldType?: ContractFieldType,
    ) {
        return this.$http.get<{ entities: ContractFieldSummary[] }>(
            this.buildUrl(`${projectId}/${contractId}/fields`),
            { params: { contractFieldType } },
        );
    }

    @bindThis
    public deleteProcessMapperEdge(
        processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>,
        processMapperEdgeId: TonkeanId<TonkeanType.PROCESS_MAPPER_EDGE>,
    ): Promise<void> {
        return this.$http.delete<void>(this.buildUrl(processMapperEdgeId), { params: { processMapperId } });
    }

    @bindThis
    public deleteProcessMapper(processMapperId: TonkeanId<TonkeanType.PROCESS_MAPPER>): Promise<void> {
        return this.$http.delete<void>(this.buildUrl(`${processMapperId}`));
    }

    @bindThis
    public getProjectToken(projectId: TonkeanId<TonkeanType.PROJECT>) {
        const params = { projectId };
        return this.$http.get<any>(this.buildUrl(`auth/project/tokens`), { params });
    }

    @bindThis
    public getGroupProjectIntegrationForMatchedItems(groupId: TonkeanId<TonkeanType.GROUP>) {
        return this.$http.get<ProjectIntegration>(this.buildUrl(`${groupId}/projectIntegrationForMatchedItems`));
    }

    @bindThis
    public getCallToActionInfo(
        groupId: TonkeanId<TonkeanType.GROUP>,
        workflowVersionType: WorkflowVersionType,
        tonkeanIds: TonkeanId[],
    ): Promise<MenuActionInfo> {
        return this.$http.post(this.buildUrl(`${groupId}/${workflowVersionType}/callToActionInfo`), { tonkeanIds });
    }

    @bindThis
    public getGroupIdAndWorkflowVersionTypeByWorkflowVersionId(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    ) {
        return this.$http.get<{ groupId: TonkeanId<TonkeanType.GROUP>; workflowVersionType: WorkflowVersionType }>(
            this.buildUrl(`${workflowVersionId}/getGroupIdAndWorkflowVersionType`),
        );
    }

    @bindThis
    public getMatchItemActionData(projectId: TonkeanId<TonkeanType.PROJECT>, groupId: TonkeanId<TonkeanType.GROUP>) {
        return this.$http.get<{ entities: MatchedItemActionData[] }>(
            this.buildUrl(`${projectId}/${groupId}/getMatchItemActionDataCommand`),
        );
    }

    @bindThis
    public getProjectIntegrationEntityMetadata(projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>) {
        return this.$http.get<{ entitiesMetadata: Record<string, ProjectIntegrationEntityFields> }>(
            this.buildUrl(`${projectIntegrationId}/entitiesMetadata`),
        );
    }

    @bindThis
    public getProjectIntegrationCustomFieldSubFields(
        customFieldId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ENTITY_FIELD_METADATA>,
        projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>,
    ) {
        return this.$http.get<{ entities: ProjectIntegrationEntityFieldMetadataSubField[] }>(
            this.buildUrl(`${projectIntegrationId}/${customFieldId}/subFields`),
        );
    }

    private createDataForUpdateCustomTrigger(
        disabled,
        displayName,
        description,
        queryDefinition,
        pingOwner,
        alert,
        channelId: string,
        channelName,
        customTriggerActions,
        fieldDefinitionIdsToAskWhenGather,
        customTriggerType,
        isScheduled,
        recurrencePeriodType,
        recurrenceDaysInWeek,
        recurrenceDaysInMonth,
        recurrenceHour,
        everyXMinutes,
        everyXHours,
        workerItemContextType,
        recurrenceMinute,
        monitorInnerItems,
        monitorFieldDefinitionIds,
        linkedFieldDefinitionId: string | null,
        stateId: string | null,
        updateText,
        evaluatedUpdateText,
        doNotRunOnWeekends,
        recurrenceMonthsInYear,
        timezone,
        runOneTimeOnly,
        linkedFieldDefinitionsMap,
        monitorFormIds,
        customTriggerSecondaryType,
        monitorInnerItemsCreatedByCustomTriggerId,
        skipWhenConditionNotMet,
        dontRunOnDone: boolean,
        isReset: boolean,
        runAlsoOnNewItems: boolean,
        runAlsoOnIntakeItems: boolean,
    ) {
        const data: Record<string, any> = {
            disabled,
            displayName,
            description,
            queryDefinition,
            pingOwner,
            alert,
            customTriggerActions: customTriggerActions || [],
            fieldDefinitionIdsToAskWhenGather: fieldDefinitionIdsToAskWhenGather || [],
            customTriggerType,
            isScheduled: isScheduled || false,
            workerItemContextType,
            monitorInnerItems,
            monitorInnerItemsCreatedByCustomTriggerId,
            monitorFieldDefinitionIds,
            linkedFieldDefinitionId,
            stateId,
            updateText,
            evaluatedUpdateText,
            runOneTimeOnly,
            linkedFieldDefinitionsMap: linkedFieldDefinitionsMap || {},
            monitorFormIds: monitorFormIds || [],
            skipWhenConditionNotMet,
            dontRunOnDone,
            isReset,
            runAlsoOnNewItems,
            runAlsoOnIntakeItems,
        };

        if (channelId && channelName) {
            data.notifications = {
                type: this.groupNotifications.public,
                channelId,
                channelName,
            };
        }

        if (isScheduled) {
            data.recurrencePeriodType = recurrencePeriodType;

            // recurrenceDays (either Weekly or Monthly frequency) cannot be 0, that's why we don't need to condition over null and undefined specifically.
            if (recurrenceDaysInWeek) {
                data.recurrenceDaysInWeek = recurrenceDaysInWeek;
            }

            if (recurrenceMonthsInYear) {
                data.recurrenceMonthsInYear = recurrenceMonthsInYear;
            }

            if (timezone) {
                data.timezone = timezone;
            }

            if (recurrenceDaysInMonth) {
                data.recurrenceDaysInMonth = recurrenceDaysInMonth;
            }

            // recurrenceHour can be 0, that's why we check for null and undefined specifically.
            if (recurrenceHour !== null && recurrenceHour !== undefined) {
                data.recurrenceHour = recurrenceHour;
            }

            if (recurrenceMinute !== null && recurrenceMinute !== undefined) {
                data.recurrenceMinute = recurrenceMinute;
            }

            if (everyXMinutes) {
                data.everyXMinutes = everyXMinutes;
            }
            if (everyXHours) {
                data.everyXHours = everyXHours;
            }

            data.doNotRunOnWeekends = doNotRunOnWeekends || false;
        }

        if (customTriggerSecondaryType) {
            data.customTriggerSecondaryType = customTriggerSecondaryType;
        }

        return data;
    }

    private prepareDefaultGroupUpdateParams(group) {
        const ids: string[] = [];
        if (group.members) {
            angular.forEach(group.members, (person) => {
                ids.push(person.id);
            });
        }
        let writePermissionMembers: string[] | null = null;
        if (group.writePermissionMembers) {
            writePermissionMembers = [];
            angular.forEach(group.writePermissionMembers, function (person) {
                writePermissionMembers!.push(person.id);
            });
        }

        let notificationType = group.notificationSettings.type || group.notificationSettings.channelType;
        const channel =
            group.visibilityType === 'PRIVATE'
                ? group.notificationSettings.channelName
                : group.notificationSettings.channelId;
        if (notificationType === this.groupNotifications.none && group.visibilityType === 'PRIVATE') {
            notificationType = this.groupNotifications.direct;
        }
        return {
            groupId: group.id,
            name: group.name,
            visibilityType: group.visibilityType,
            members: ids,
            notificationType,
            notificationChannel: channel,
            visibleToOwner: group.visibleToOwner,
            metadata: group.metadata,
            canAccessWithToken: group.canAccessWithToken,
            innerTracksTemplate: null,
            writePermissionType: group.writePermissionType,
            writePermissionMembers,
            blockWorkerErrorAlerts: group.blockWorkerErrorAlerts,
            isSupportUserPermitted: true,
        };
    }

    private optionalSolutionBusinessReportUrl(
        baseUrl: string,
        throughSolutionBusinessReport?: boolean,
        solutionBusinessReportId?: string,
    ): string {
        return `${throughSolutionBusinessReport ? `${solutionBusinessReportId}/` : ''}${baseUrl}`;
    }

    /**
     * evaluate expressionss in the server
     */
    @bindThis
    public statelessEvaluateExpressions(
        data: StatelessExpressionsEvaluationRequest,
    ): Promise<StatelessExpressionsEvaluationResponse> {
        return this.$http.post<StatelessExpressionsEvaluationResponse>(this.buildUrl(`evaluateExpression`), data);
    }

    /**
     * evaluate json expressionss in the server
     */
    @bindThis
    public statelessJsonEvaluateExpressions(
        data: StatelessJsonExpressionsEvaluationRequest,
    ): Promise<StatelessJsonExpressionsEvaluationResponse> {
        return this.$http.post<StatelessJsonExpressionsEvaluationResponse>(
            this.buildUrl(`evaluateJsonExpressions`),
            data,
        );
    }

    @bindThis
    public createProjectIntegrationEntityFieldMetadata(data: CreateProjectIntegrationEntityFieldMetadataRequest) {
        return this.$http.post<{ projectIntegrationEntityFieldMetadataObject: ProjectIntegrationEntityFieldMetadata }>(
            this.buildUrl(`${data.projectIntegrationId}/entityFieldMetadata`),
            { ...data.projectIntegrationEntityFieldMetadataParams },
        );
    }

    @bindThis
    public updateProjectIntegrationEntityFieldMetadata(data: UpdateProjectIntegrationEntityFieldMetadataRequest) {
        return this.$http.post<{ projectIntegrationEntityFieldMetadataObject: ProjectIntegrationEntityFieldMetadata }>(
            this.buildUrl(`${data.projectIntegrationId}/${data.projectIntegrationEntityFieldMetadataId}`),
            { ...data.projectIntegrationEntityFieldMetadataParams },
        );
    }

    @bindThis
    public fetchInitiativeFields(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        initiativeIds: TonkeanId<TonkeanType.INITIATIVE>[],
        specialFields: string[],
        fieldDefinitionIdsToFetch: FieldDefinitionIdToFetch[],
    ) {
        return this.$http.post<{ data: FetchInitiativeFieldsResponse }>(this.buildUrl(`${projectId}/fetchFields`), {
            initiativeIds,
            specialFields,
            fieldDefinitionIdsToFetch,
        });
    }
}

/**
 * A method decorator that checks if the method answer is an async api job response then we handle it
 * for them in the apiJobManager and return a promise that will contain the desired answer once the job is done.
 */
function asyncApiJob<T extends (...args: any[]) => PromiseLike<any>>(
    _target: TonkeanService,
    _propertyKey: string,
    descriptor: TypedPropertyDescriptor<T>,
): TypedPropertyDescriptor<T> | void {
    return {
        get(this: TonkeanService): T {
            const value = descriptor.get?.apply(this) || descriptor.value;

            if (!value) {
                throw new Error('Wrong use of the async api job decorator.');
            }

            return ((...args: Parameters<T>) => {
                return value(...args).then((result: ReturnType<T> | JobStatusResponse<ReturnType<T>>) => {
                    if (result && typeof result === 'object' && 'jobId' in result) {
                        const apiJobManager = this.apiJobManager;
                        return apiJobManager.addJob<ReturnType<T>>(result.jobId, this.getLatestJobsStatus);
                    }

                    return result;
                });
            }) as T;
        },
    };
}

export default TonkeanService;

angular.module('tonkean.app').service('tonkeanService', TonkeanService);

// Overcome the typing error:
// "Parameter 'config' of method from exported interface has or is using private name 'IRequestShortcutConfig'"
export type { IRequestShortcutConfig } from 'angular';

// This is used to override the return type of the $http angular service methods - this is needed because we use an
// interceptor and change the return type.
declare module 'angular' {
    interface IHttpService {
        <T>(config: _IRequestConfig): Promise<T>;

        get<T>(url: string, config?: IRequestShortcutConfig): Promise<T>;

        delete<T>(url: string, config?: IRequestShortcutConfig): Promise<T>;

        head<T>(url: string, config?: IRequestShortcutConfig): Promise<T>;

        jsonp<T>(url: string, config?: IRequestShortcutConfig): Promise<T>;

        post<T>(url: string, data: any, config?: IRequestShortcutConfig): Promise<T>;

        put<T>(url: string, data: any, config?: IRequestShortcutConfig): Promise<T>;

        patch<T>(url: string, data: any, config?: IRequestShortcutConfig): Promise<T>;
    }
}
