import { useAngularService } from 'angulareact';
import { useCallback, useEffect, useState } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { LightweightSearchInitiative } from '@tonkean/tonkean-entities';

interface Params {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionType: WorkflowVersionType;
    query?: Record<string, any>;
    searchText?: string;
    skip?: number;
    limit?: number;
    clearResultOnEmpty?: boolean;
    trackTotalHits?: boolean;
    active?: boolean;
}

/**
 * Utility for searching initiatives using the lightweight query API (queries Elasticsearch).
 * @param groupId The group in which we are fetching.
 * @param workflowVersionType The environment we're searching in.
 * @param searchText The textual query we want to search for.
 * @param skip How many items we skip.
 * @param limit The amount of results we'd like to return.
 * @param query The custom query object from a conditions component
 * @param clearResultOnEmpty Should return empty result if the current result is empty
 * @param trackTotalHits Should return a count of total hits without the limit
 * @param active Used to make sure the search runs only when we want to
 */
export default function useLightSearchInitiatives({
    groupId,
    workflowVersionType,
    query,
    searchText,
    skip,
    limit,
    clearResultOnEmpty = false,
    trackTotalHits = false,
    active = true,
}: Params) {
    const [initiatives, setInitiatives] = useState<LightweightSearchInitiative[]>([]);
    const [totalHits, setTotalHits] = useState<number | undefined>();
    const projectManager = useAngularService('projectManager');

    const [{ data, error, loading, called }, lightSearchInitiatives] = useLazyTonkeanService('lightSearchInitiatives');

    const triggerSearch = useCallback(
        (searchTerm?: string) => {
            return lightSearchInitiatives(
                projectManager.project.id,
                groupId,
                workflowVersionType,
                query,
                searchTerm,
                skip,
                limit,
                trackTotalHits,
            );
        },
        [
            groupId,
            lightSearchInitiatives,
            limit,
            projectManager.project.id,
            query,
            skip,
            trackTotalHits,
            workflowVersionType,
        ],
    );

    useEffect(() => {
        if (!active) {
            return;
        }

        lightSearchInitiatives(
            projectManager.project.id,
            groupId,
            workflowVersionType,
            query,
            searchText,
            skip,
            limit,
            trackTotalHits,
        );
    }, [
        groupId,
        lightSearchInitiatives,
        limit,
        projectManager.project.id,
        query,
        searchText,
        skip,
        workflowVersionType,
        trackTotalHits,
        active,
    ]);

    useEffect(() => {
        if ((data?.entities?.length || clearResultOnEmpty) && !loading && !error) {
            setInitiatives(data?.entities || []);
        }
        if (trackTotalHits && (data?.totalHits || data?.totalHits === 0) && !loading && !error) {
            setTotalHits(data.totalHits);
        }
    }, [data, loading, error, clearResultOnEmpty, trackTotalHits]);

    return {
        triggerSearch,
        initiatives,
        totalHits,
        loading,
        error,
        called,
    };
}
