import { useAngularService, useAngularWatch } from 'angulareact';
import { useMemo } from 'react';

import type {
    FieldDefinition,
    FieldInstance,
    Initiative,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import { FieldDefinitionTargetType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { getInitiativeFieldValue, InitiativeFieldValue } from '@tonkean/tonkean-utils';
import type { Either } from '@tonkean/utils';

const getGlobalFieldValueFromCachedMap = (
    requestedVersionMap: string,
    fieldDefinitionId: TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}`,
    groupsMap,
): InitiativeFieldValue | undefined => {
    return Object.assign({}, ...Object.values(groupsMap).map((group: any) => group[requestedVersionMap]))[
        fieldDefinitionId
    ];
};

export function getFieldInstance(
    fieldDefinition: FieldDefinition<unknown>,
    initiative: Initiative | undefined,
    workflowVersion: WorkflowVersion | undefined,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    showEmptyFieldWhenNoInitiative: boolean,
    groupsMap,
    workflowVersionType?: WorkflowVersionType,
): InitiativeFieldValue | undefined {
    if (fieldDefinition.targetType === FieldDefinitionTargetType.GLOBAL) {
        if (groupId) {
            if (
                workflowVersion?.workflowVersionType === WorkflowVersionType.DRAFT ||
                initiative?.isDraftInitiative ||
                workflowVersionType === WorkflowVersionType.DRAFT
            ) {
                return groupsMap[groupId]?.globalFieldsDraftMap[fieldDefinition.id];
            } else {
                return groupsMap[groupId]?.globalFieldsMap[fieldDefinition.id];
            }
        }
        if (
            workflowVersion?.workflowVersionType === WorkflowVersionType.DRAFT ||
            workflowVersionType === WorkflowVersionType.DRAFT
        ) {
            return getGlobalFieldValueFromCachedMap('globalFieldsDraftMap', fieldDefinition.id, groupsMap);
        } else {
            return getGlobalFieldValueFromCachedMap('globalFieldsMap', fieldDefinition.id, groupsMap);
        }
    }

    if (!initiative || !workflowVersion) {
        if (showEmptyFieldWhenNoInitiative) {
            return {
                value: undefined,
                formattedValue: undefined,
                change: undefined,
                id: undefined,
                appliedRangeNumber: undefined,
            };
        }

        throw new Error(
            'When passing a non-global field definition to UpdateField component, you must pass initiative and workflow version.',
        );
    }

    return getInitiativeFieldValue(initiative, fieldDefinition, workflowVersion);
}

function useGetFieldInstance(
    groupId: TonkeanId<TonkeanType.GROUP>,
    fieldDefinition: FieldDefinition,
    initiative: Initiative | undefined,
    workflowVersion: WorkflowVersion | undefined,
    showEmptyFieldWhenNoInitiative: boolean,
) {
    const projectManager = useAngularService('projectManager');
    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    return useMemo<Either<FieldInstance, InitiativeFieldValue> | undefined>(() => {
        return getFieldInstance(
            fieldDefinition,
            initiative,
            workflowVersion,
            groupId,
            showEmptyFieldWhenNoInitiative,
            groupsMap,
        );
    }, [fieldDefinition, initiative, workflowVersion, groupId, showEmptyFieldWhenNoInitiative, groupsMap]);
}

export default useGetFieldInstance;
