import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import type { TwilioProjectIntegrationData } from './TwilioProjectIntegrationData';
import type CustomizedSetupComponentProps from '../../base/CustomizedSetupComponentProps';

import type { AutoCompleteOptions } from '@tonkean/infrastructure';
import { useAutoCompleteOptions } from '@tonkean/infrastructure';
import { TnkSelect } from '@tonkean/infrastructure';
import { Checkbox } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { InputSize } from '@tonkean/tui-theme/sizes';

interface Service {
    /**
     * Service Id
     */
    value: string;
    /**
     * Service Display Name
     */
    label: string;
}

const Divider = styled.hr`
    width: 100%;
`;

const CustomTnkSelect = styled(TnkSelect)`
    width: 80%;
`;

const constructAutoCompleteOptions = (options: AutoCompleteOptions[] = []) => {
    return options.map((option) => ({
        value: option.value,
        label: option.displayName,
    }));
};

const TwilioCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps<TwilioProjectIntegrationData>> = ({
    integration,
    onChangeOrInitIntegration,
    projectIntegrationData,
}) => {
    const { autoCompleteOptions, loading, error } = useAutoCompleteOptions(integration.id, 'services');
    const [selectedServices, setSelectedServices] = useState<Service[] | null>([]);

    const onServicesInputChanged = useCallback((services) => {
        setSelectedServices(services);
    }, []);

    useEffect(() => {
        if (autoCompleteOptions?.length && projectIntegrationData?.servicesIds?.length) {
            setSelectedServices(
                constructAutoCompleteOptions(
                    autoCompleteOptions.filter((option) => projectIntegrationData.servicesIds.includes(option.value)),
                ),
            );
        }
    }, [autoCompleteOptions, projectIntegrationData]);

    useEffect(() => {
        const config: TwilioProjectIntegrationData = {
            servicesIds: (selectedServices || []).map((service) => service.value),
            shouldOverrideServiceWebhooks: true,
        };

        onChangeOrInitIntegration({ projectIntegrationData: config }, false);
    }, [onChangeOrInitIntegration, selectedServices]);

    return (
        <div className="flex flex-col">
            <label className="common-size-xs margin-bottom-lg">Which Twilio Services Are Relevant?</label>

            <div className="flex flex-vmiddle">
                <label>Services</label>
                <div className="col-md-8 flex-vmiddle">
                    <CustomTnkSelect
                        value={selectedServices}
                        className="margin-bottom"
                        placeholder="Select your services.."
                        isLoading={loading}
                        onChange={onServicesInputChanged}
                        isClearable={false}
                        options={constructAutoCompleteOptions(autoCompleteOptions)}
                        isMulti
                        closeMenuOnSelect
                        isSearchable
                    />

                    {error && (
                        <Tooltip content="Unable to load services. Try to re-authenticate.">
                            <i className="fa fa-warning common-color-danger margin-left" />
                        </Tooltip>
                    )}
                </div>
            </div>
            <Divider />

            <div className="flex flex-col">
                <label className="common-size-xs margin-bottom-lg">Webhooks Management</label>

                <div>
                    <Checkbox onChange={() => {}} size={InputSize.LARGE} checked disabled>
                        Override My Service Webhooks
                    </Checkbox>
                    <div className="common-color-light-grey common-size-xxxs">
                        <span>
                            In order for us to stay up to date on your Twilio data we will <strong>OVERRIDE</strong> any
                            existing webhooks you may have on the selected services.
                        </span>
                        <div className="common-size-xxs margin-top">
                            * If it is not desirable, you can create a new service{' '}
                            <a href="https://www.twilio.com/console/sms/services" target="_blank">
                                <strong>here</strong>
                            </a>
                            .
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwilioCustomizeSetupComponent;
