import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const NetsuiteAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
}) => {
    const [accountId, setAccountId] = useState<string>('');
    const [accountUrlId, setAccountUrlId] = useState<string>('');
    const [consumerKey, setConsumerKey] = useState<string>('');
    const [consumerSecret, setConsumerSecret] = useState<string>('');
    const [tokenKey, setTokenKey] = useState<string>('');
    const [tokenSecret, setTokenSecret] = useState<string>('');

    const onSubmit = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                accountId,
                accountUrlId,
                consumerKey,
                consumerSecret,
                tokenKey,
                tokenSecret,
            };
            authenticate(config, {}, `Netsuite (${accountId})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={onSubmit}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="netsuite-login-account-id" className="col-sm-3 control-label">
                            Account Id (Realm)
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={accountId}
                                className="form-control margin-bottom-xs"
                                id="netsuite-login-account-id"
                                onChange={({ target }) => setAccountId(target.value)}
                                autoComplete="off"
                                placeholder="Account ID"
                                required
                            />
                            <span>Note: This is case sensitive!</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="netsuite-login-account-url-id" className="col-sm-3 control-label">
                            Account URL
                        </label>
                        <div className="col-sm-8 flex-vmiddle">
                            <span>https://</span>
                            <input
                                type="text"
                                value={accountUrlId}
                                className="form-control margin-bottom-xs"
                                id="netsuite-login-account-url-id"
                                onChange={({ target }) => setAccountUrlId(target.value)}
                                autoComplete="off"
                                placeholder="Subdomain"
                                required
                            />
                            <span>.app.netsuite.com</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="netsuite-login-consumer-key" className="col-sm-3 control-label">
                            Consumer Key
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={consumerKey}
                                className="form-control margin-bottom-xs"
                                id="netsuite-login-consumer-key"
                                onChange={({ target }) => setConsumerKey(target.value)}
                                autoComplete="off"
                                placeholder="Consumer Key \ Client Key"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="netsuite-login-consumer-secret" className="col-sm-3 control-label">
                            Consumer Secret
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={consumerSecret}
                                className="form-control margin-bottom-xs"
                                id="netsuite-login-consumer-secret"
                                onChange={({ target }) => setConsumerSecret(target.value)}
                                autoComplete="off"
                                placeholder="Consumer Secret \ Client Secret"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="netsuite-login-token-key" className="col-sm-3 control-label">
                            Token Key
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={tokenKey}
                                className="form-control margin-bottom-xs"
                                id="netsuite-login-token-key"
                                onChange={({ target }) => setTokenKey(target.value)}
                                autoComplete="off"
                                placeholder="Access Token Key"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="netsuite-login-token-secret" className="col-sm-3 control-label">
                            Token Secret
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={tokenSecret}
                                className="form-control margin-bottom-xs"
                                id="netsuite-login-token-secret"
                                onChange={({ target }) => setTokenSecret(target.value)}
                                autoComplete="off"
                                placeholder="Access Token Secret"
                                required
                            />
                        </div>
                    </div>

                    <div className="margin-bottom-lg info-box common-size-xxs">
                        <p className="common-color-dark-grey">API Access Setup:</p>
                        <div>In your Netsuite instance</div>
                        <div className="common-color-dark-grey margin-top-lg">
                            <ul className="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                                <li>
                                    <div className="padding-left-xxs">
                                        Navigate to Setup &gt; Integration &gt; Manage Integrations &gt; New
                                    </div>
                                </li>
                                <li>
                                    <div className="padding-left-xxs">Take the Consumer Key and Secret.</div>
                                </li>
                                <li>
                                    <div className="padding-left-xxs">
                                        Log in as administrator and set up an access token.
                                        <br />
                                        Navigate to the{' '}
                                        <a
                                            target="_blank"
                                            href={`https://${accountUrlId}.app.netsuite.com/app/setup/useraccesstoken.nl`}
                                        >
                                            Access Token
                                        </a>{' '}
                                        page.
                                        <br />* Fill the Account URL field first.
                                    </div>
                                </li>
                                <li>
                                    <div className="padding-left-xxs">
                                        Select the Application Name created from the previous step.
                                    </div>
                                </li>
                                <li>
                                    <div className="padding-left-xxs">Save and take the Token Key and Secret</div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NetsuiteAuthenticationComponent;
