import template from './tnkLiveSync.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkLiveSync', {
    bindings: {
        /**
         * Optional parameter, if given this object will be filled with the used control
         */
        control: '<',

        /**
         * True if it's a custom filters sync. False otherwise.
         */
        customDefinedView: '<',

        /**
         * Passes the current defined definition, if such exists.
         */
        existingDefinition: '<',

        /**
         * The integration data
         */
        projectIntegration: '<',

        /**
         * The selected entity type.
         */
        selectedEntityType: '<',

        /**
         * The plural name of the selected entity.
         */
        selectedEntityPluralName: '<',

        /**
         * function to calculate the plural entity name on demand
         */
        calculatePluralEntityName: '<',

        /**
         * passes this parameter to the custom filters
         */
        copyPropertiesFromDefinition: '<',

        /**
         * a flag representing the hidden state of the custom filters (true to hide)
         */
        hideFilters: '<',

        /**
         * this method must return the data needed for the load preview method (pluralEntityType and viewData)
         */
        supplyDataForLoadPreview: '&',

        /**
         * object passed to the sync control with validity state
         */
        syncValidityState: '<',

        /**
         * passed to the custom filters control
         */
        isSpecialEntity: '<',

        /**
         * passed to the custom filters control
         */
        fallbackEntityType: '<',

        /**
         * Set to true to make the preview reload
         */
        loadPreviewFlag: '<',

        /**
         * Set the view type
         */
        viewType: '<',

        /**
         * The field representing the title expression.
         */
        titleExpression: '<',

        /**
         * The field representing the default value for title if expression evaluates to an empty or null value.
         */
        defaultTitleIfExpressionEmpty: '<',

        /**
         * options for intgeration views for the user to select
         */
        integrationViews: '<',

        /**
         * Flag indicating if the preview should hide the manage triggers modal button
         */
        hideManageTriggersModal: '<',

        /**
         * Flag indicating if the preview should hide the manage triggers modal button
         */
        hideInformation: '<',

        /**
         * Flag indicating if the preview should hide the Module on/off button
         */
        hideBotInPreview: '<',

        /**
         * An object used for overriding the default shouldGatherUpdates
         */
        overrideActivateBotObject: '<',

        /**
         * Flag indicating if the preview should be full screen
         */
        fullScreen: '<',

        /**
         * The name/id of the entity. Should be unique and singular.
         */
        selectedEntity: '<',

        /**
         * A function to call when the viewed data as been changed
         */
        onDataChanged: '<',
    },
    template,
    controller: 'LiveSyncCtrl',
});
