import React, { useMemo } from 'react';

import type { BreadcrumbsSettings } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { ProjectIntegration, ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';

const useProjectIntegrationEntityFetcherBreadCrumbsConfiguration = (
    projectIntegration: ProjectIntegration,
    entity: ProjectIntegrationEntity,
    projectIntegrationAction?: ProjectIntegrationAction,
    onExit?: (destinationEntityId: string) => Promise<boolean>,
) => {
    // Memo for the breadCrumbs settings.
    return useMemo(() => {
        // Project Integration bread crumb.
        const projectIntegrationBreadCrumb: BreadcrumbsSettings = {
            displayName: projectIntegration.displayName,
            id: projectIntegration.displayName,
            prefixIcon: (
                <ProjectIntegrationIcon
                    width={14}
                    iconUrl={projectIntegration.iconUrl}
                    integrationType={projectIntegration.integration.integrationType}
                />
            ),
        };

        // Entities Page bread crumb.
        const entitiesPageBreadCrumb: BreadcrumbsSettings = {
            displayName: 'Entities',
            id: 'Entities',
        };

        // Single entity with other entities options bread crumb.
        const entitiesBreadCrumb: BreadcrumbsSettings = {
            displayName: entity.displayName,
            id: entity.id,
        };

        const items = [projectIntegrationBreadCrumb, entitiesPageBreadCrumb, entitiesBreadCrumb];

        if (projectIntegrationAction) {
            const entityFetchersBreadCrumb: BreadcrumbsSettings = {
                displayName: 'Entity Fetchers',
                id: 'entity-fetcher',
            };
            const entityFetcherBreadCrumb: BreadcrumbsSettings = {
                displayName: projectIntegrationAction.displayName,
                id: projectIntegrationAction.id,
            };
            items.push(entityFetchersBreadCrumb, entityFetcherBreadCrumb);
        }

        return items;
    }, [
        projectIntegration.displayName,
        projectIntegration.iconUrl,
        projectIntegration.integration.integrationType,
        entity.displayName,
        entity.id,
        projectIntegrationAction,
    ]);
};

export default useProjectIntegrationEntityFetcherBreadCrumbsConfiguration;
