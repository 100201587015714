import React, { useCallback } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { IconSvg, MenuItem } from '@tonkean/infrastructure';
import { CursorClickIcon, FormIcon, InterfaceIcon, TrashOldIcon } from '@tonkean/svg';
import { InterfaceCTAActionType } from '@tonkean/tonkean-entities';
import type { CustomTrigger, Form, Initiative, ItemInterface, WorkflowVersionType } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType, FieldDefinitionInterfaceId } from '@tonkean/tonkean-entities';

export type ItemGridMenuActionIds =
    | CustomTrigger['id']
    | ItemInterface['id']
    | Form['id']
    | TonkeanId<TonkeanType.FIELD_DEFINITION>
    | TonkeanId<TonkeanType.CONTRACT_FIELD>
    | 'DELETE_ITEM'
    | 'CLONE_ITEM'
    | FieldDefinitionInterfaceId;

interface Props {
    initiativeId: Initiative['id'] | undefined;
    displayName: string;
    actionId: ItemGridMenuActionIds;
    actionType: InterfaceCTAActionType;
    projectId: string | undefined;
    workflowVersionType: WorkflowVersionType;
    deleteCallback: any;
    disabled?: boolean;
}

const ItemWidgetsInlineActionMenuItem: React.FC<Props> = ({
    initiativeId,
    displayName,
    actionId,
    actionType,
    projectId,
    workflowVersionType,
    deleteCallback,
    disabled = false,
}) => {
    const [, triggerButtonCustomTrigger] = useLazyTonkeanService('triggerButtonCustomTrigger');

    const emitToast = useToastMessage();
    const executeCustomTrigger = useCallback(async () => {
        if (actionId && initiativeId) {
            try {
                await triggerButtonCustomTrigger(actionId as CustomTrigger['id'], initiativeId, displayName);
                emitToast(`custom trigger ${displayName} executed successfully `, 'success');
            } catch {
                emitToast(`custom trigger ${displayName} failed to execute `, 'danger');
            }
        }
    }, [actionId, displayName, emitToast, initiativeId, triggerButtonCustomTrigger]);

    switch (actionType) {
        case InterfaceCTAActionType.OPEN_FORM: {
            return (
                <MenuItem
                    state="form"
                    params={{
                        formId: actionId,
                        projectId,
                        initiativeId,
                        formVersionType: workflowVersionType.toLowerCase(),
                    }}
                    icon={<IconSvg as={FormIcon} size={16} />}
                    disabled={disabled}
                    openInNewTab
                >
                    {displayName}
                </MenuItem>
            );
        }
        case InterfaceCTAActionType.OPEN_INTERFACE: {
            return (
                <MenuItem
                    state="product.customInterfaceView"
                    params={{
                        itemInterfaceId: actionId,
                        initiativeId,
                    }}
                    icon={<IconSvg as={InterfaceIcon} size={16} color="#FF7A00" />}
                    openInNewTab
                >
                    {displayName}
                </MenuItem>
            );
        }
        case InterfaceCTAActionType.TRIGGER_WORKFLOW: {
            return (
                <MenuItem
                    onClick={executeCustomTrigger}
                    icon={<IconSvg as={CursorClickIcon} size={16} color="#FF7A00" />}
                >
                    {displayName}
                </MenuItem>
            );
        }
        case InterfaceCTAActionType.DELETE_ITEM: {
            return (
                <MenuItem
                    key={actionId}
                    icon={<IconSvg as={TrashOldIcon} size={16} />}
                    onClick={deleteCallback}
                    disabled={disabled}
                >
                    {displayName}
                </MenuItem>
            );
        }
        default: {
            return <></>;
        }
    }
};

export default ItemWidgetsInlineActionMenuItem;
