import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function WorkerTileNewWorkerCtrl($scope, projectManager, workflowFolderManager, modalUtils) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.wfm = workflowFolderManager;

    $scope.data = {
        folderId: ctrl.folderId,
        openWorker: ctrl.openWorker,

        isHovered: false,
    };

    ctrl.$onInit = function () {};

    ctrl.$onChanges = function (changes) {};

    $scope.mouseEnterNewModuleTile = function () {
        $scope.data.isHovered = true;
    };

    $scope.mouseLeaveNewModuleTile = function () {
        $scope.data.isHovered = false;
    };

    $scope.createNewWorkerInWorkflowFolder = function (folderId, $event) {
        if (
            !$scope.pm.isOwner &&
            !$scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id][folderId].isUserOwner
        ) {
            $event?.stopPropagation();
            return;
        }

        analyticsWrapper.track('Open new module modal', { category: 'Modules browser' });
        modalUtils.openChooseWorkerTypeModal(folderId).then((group) => {
            $scope.data.openWorker(group);
        });
    };

    $scope.createNewWorkerFromTemplate = function (folderId, $event) {
        if (
            !$scope.pm.isOwner &&
            !$scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id][folderId].isUserOwner
        ) {
            $event?.stopPropagation();
            return;
        }

        modalUtils.openSetupGroupModal(false, null, true, true, null, folderId);
    };
}

export default angular.module('tonkean.app').controller('WorkerTileNewWorkerCtrl', lateConstructController(WorkerTileNewWorkerCtrl));
