/**
 * Created by ShaLi on 8/11/15.
 */

function tnkSelectOnClick($window, $document) {
    return {
        restrict: 'A',
        link(scope, element) {
            element.on('click', function () {
                let range;
                const documentObj = $document[0];
                if (angular.isFunction(this.select)) {
                    this.select();
                } else if (documentObj.selection) {
                    range = documentObj.body.createTextRange();
                    range.moveToElementText(this);
                    range.select();
                } else if ($window.getSelection) {
                    range = documentObj.createRange();
                    range.selectNode(this);
                    $window.getSelection().addRange(range);
                }
            });
        },
    };
}
export default angular.module('tonkean.shared').directive('tnkSelectOnClick', tnkSelectOnClick);
