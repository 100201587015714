import type { IHttpInterceptor, IHttpResponse, IPromise, IRequestConfig } from 'angular';
import type { AngularServices } from 'angulareact';
import { bindThis } from '@tonkean/utils';
import { TONKEAN_E_TAG_STR } from '@tonkean/constants';

class HttpInterceptor implements IHttpInterceptor {
    public static $inject: (string & keyof AngularServices)[] = [
        'authenticationService',
        'utils',
        '$log',
        '$q',
        '$window',
        '$injector',
        'entityHelper',
        'environment',
    ];

    constructor(
        public authenticationService: AngularServices['authenticationService'],
        public utils: AngularServices['utils'],
        public $log: AngularServices['$log'],
        public $q: AngularServices['$q'],
        public $window: AngularServices['$window'],
        public $injector: AngularServices['$injector'],
        public entityHelper: AngularServices['entityHelper'],
        public environment: AngularServices['environment'],
    ) {}

    /**
     * Before API requests we add the authentication data.
     */
    @bindThis
    public request(config: IRequestConfig): IRequestConfig | IPromise<IRequestConfig> {
        // Checking if this is an API call.
        if (config.url.indexOf(this.environment.apiUrl) !== 0) {
            return config;
        }

        // Inserting the authorization header to the request
        const currentUser = this.authenticationService.currentUser;
        const projectAccessToken = this.authenticationService.getProjectToken();
        const accessToken = currentUser ? currentUser.accessToken : null;

        if (this.environment.withCredentials && config.url.startsWith(this.environment.apiUrl)) {
            config.withCredentials = true;
        }

        if (!config.headers) {
            config.headers = {};
        }

        if (accessToken) {
            config.headers.Authorization = `token ${accessToken}`;
        }

        if (projectAccessToken) {
            config.headers['Authorization-Project'] = `Bearer ${projectAccessToken}`;
        }

        // If a post request, and not already inserted by the user, insert a generated Tonkean-ETag.
        if (
            config.method &&
            (config.method === 'POST' || config.method === 'DELETE') &&
            !config.headers[TONKEAN_E_TAG_STR]
        ) {
            config.headers[TONKEAN_E_TAG_STR] = this.utils.guid();
        }

        return config;
    }

    /**
     * On response success this takes the data out of the response object.
     */
    @bindThis
    public response<T>(response: IHttpResponse<T>): IPromise<IHttpResponse<T>> | IHttpResponse<T> {
        // Checking if this is an API call.
        if (response.config.url.indexOf(this.environment.apiUrl) !== 0) {
            return response;
        }

        if (
            response.config.responseType &&
            response.config.responseType !== 'text' &&
            response.config.responseType !== 'json'
        ) {
            return response.data as any;
        }

        let enriched;
        try {
            const clone = this.cloneObjectDeep(response.data);
            enriched = this.entityHelper.enrichObj(clone);
        } catch (error) {
            this.$log.error('Failed to enrich the response.', error);
            enriched = null;
        }

        // We return the data object on success.
        // We don't care about the rest of the response when it's a success
        const returnedData = enriched || response.data || response;

        // If we have the tonkean etag in our headers, we return it in the response object.
        // This seems odds, and that's because we return a stripped response of only the data
        // and not the headers to the user of the http requests.
        // This time we need the headers for the etag header. So until we refactor this in a better way,
        // we put the tonkean etag header inside the returned data entity.
        if (
            response &&
            typeof response === 'object' &&
            response.headers(TONKEAN_E_TAG_STR) &&
            typeof returnedData == 'object'
        ) {
            returnedData.headerTonkeanETag = response.headers(TONKEAN_E_TAG_STR);
        }

        return returnedData;
    }

    private stripSensitiveData<T>(response: IHttpResponse<T>) {
        if (response.config.headers?.Authorization) {
            response.config.headers.Authorization = `token ***`;
        }

        if (response.config.headers?.['Authorization-Project']) {
            response.config.headers['Authorization-Project'] = `Bearer ***`;
        }
    }
    /**
     * On response error we check if the API failed with 401.
     * That means that we are logged out and we need to notify the system about it.
     */
    @bindThis
    public responseError<T>(response: IHttpResponse<T>): IPromise<IHttpResponse<T>> | IHttpResponse<T> {
        this.stripSensitiveData(response);

        if (response.config.url.indexOf(this.environment.apiUrl) === 0) {
            const modal: AngularServices['modal'] = this.$injector.get('modal');
            if (response.status === 403 && modal.openValidateUserIfNeeded()) {
                return this.$q.reject();
            } else if (response.status === 401) {
                this.$log.error(response);
                this.authenticationService.logout(true);
                return this.$q.reject();
            } else if (
                (response.status === 404 ||
                    response.status === 502 ||
                    response.status === 503 ||
                    response.status === 504) && // Report these errors to Sentry, since they shouldn't happen! We need to know about them.
                // Make sure Sentry is up and running first and that we're on test or prod environments.

                window &&
                window.location &&
                this.$window &&
                typeof this.$window.Sentry !== 'undefined' &&
                this.$window.Sentry &&
                window.location.href.includes(this.environment.appUrl)
            ) {
                this.$window.Sentry.captureMessage(
                    `Server responded with status code ${response.status} for request URL: ${response.config.url}`,
                );
            }
        }

        return this.$q.reject(response);
    }

    /**
     * Our own custom deep object clone function, since angular.copy is very slow.
     * This recursive function is assuming that it would only need to copy a plain Object, Array, Date, String, Number, or Boolean.
     * The last 3 types are immutable, so we can perform a shallow copy and not worry about it changing.
     * It further assumes that any elements contained in Object or Array would also be one of the 6 simple types in that list.
     * http://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object?page=1&tab=votes#tab-top
     * @param obj - the obj to deep clone.
     * @returns {*} - a clone of the given object.
     */
    private cloneObjectDeep<T>(obj: T): T {
        // Handle the 3 simple types, and null or undefined.
        if (null === obj || 'object' !== typeof obj) {
            return obj;
        }

        // Handle Date.
        if (obj instanceof Date) {
            const copy = new Date();
            copy.setTime(obj.getTime());
            return copy as any;
        }

        // Handle Array.
        if (Array.isArray(obj)) {
            const copy = obj.map((item) => this.cloneObjectDeep(item));
            return copy as any;
        }

        // Handle Object.
        if (obj instanceof Object) {
            const copy = Object.fromEntries(
                Object.entries(obj).map(([key, value]) => [key, this.cloneObjectDeep(value)]),
            );
            return copy as any;
        }

        console.error("Unable to copy obj! Its type isn't supported.");
        return obj;
    }
}

angular.module('tonkean.app').service('httpInterceptor', HttpInterceptor);
