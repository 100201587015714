import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function GoodtimeCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,
    };

    ctrl.$onInit = function () {
        // todo: Do I need to reset these fields?
        if ($scope.data.definition && !$scope.data.definition.tagIdsExpression) {
            $scope.data.definition.tagIdsExpression = {
                evaluatedExpression: '',
                originalExpression: '',
            };
        }
        if ($scope.data.definition && !$scope.data.definition.tagIdExpression) {
            $scope.data.definition.tagIdExpression = {
                evaluatedExpression: '',
                originalExpression: '',
            };
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.onTagIdsExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.tagIdsExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTagIdExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.tagIdExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('GoodtimeCustomActionsDefinitionCtrl', lateConstructController(GoodtimeCustomActionsDefinitionCtrl));
