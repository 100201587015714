import { lateConstructController } from '@tonkean/angular-components';
import { OWNER_EVALUATION_OPTIONS } from '@tonkean/constants';

/* @ngInject */
function ManualItemsTemplateConfigCtrl($scope, tonkeanUtils, utils, personCache) {
    const ctrl = this;

    $scope.data = {
        // Component bindings
        existingInnerTracksTemplate: ctrl.existingInnerTracksTemplate,
        hideApplyOnPreviouslyCreatedTracks: ctrl.hideApplyOnPreviouslyCreatedTracks,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        shouldShowRemoveButton: ctrl.shouldShowRemoveButton,
        isDynamic: ctrl.isDynamic,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        // Component model
        initiatives: [],
        applySettingsOnPreviouslyCreatedTracks: false,
        dueDateAlertOpen: {},
        ownerAlertOpen: {},

        newTrackPlaceholder: ctrl.newTrackPlaceholder || 'Inner Track name',
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if ($scope.data.existingInnerTracksTemplate && $scope.data.existingInnerTracksTemplate.length) {
            // Edit mode.
            // applySettingsOnPreviouslyCreatedTracks setting.
            $scope.data.applySettingsOnPreviouslyCreatedTracks =
                $scope.data.existingInnerTracksTemplate.applySettingsOnPreviouslyCreatedTracks;

            for (let i = 0; i < $scope.data.existingInnerTracksTemplate.length; i++) {
                const trackTemplate = $scope.data.existingInnerTracksTemplate[i];
                // Setting the inviteSent flag to true, so that the people selector won't display the send an invite message.
                if (trackTemplate.manualOwnerInfo) {
                    trackTemplate.manualOwnerInfo.inviteSent = true;
                }
                const initiative = {
                    // Title
                    title: trackTemplate.title,

                    // Due date
                    dueDateEvaluationOption: trackTemplate.dueDateEvaluationOption,
                    relativeDueDateInDays: trackTemplate.relativeDueDateInDays,

                    // Owner
                    ownerEvaluationOption: OWNER_EVALUATION_OPTIONS.personConfiguration,
                    inputType: trackTemplate.inputType,
                };

                // In case expression exist set it or migrate the value from the title field.
                if ($scope.data.existingInnerTracksTemplate.length !== 1) {
                    initiative.expression = trackTemplate.expression ? trackTemplate.expression : {};
                } else {
                    initiative.expression = trackTemplate.expression
                        ? trackTemplate.expression
                        : {
                              originalExpression: trackTemplate.title,
                              evaluatedExpression: trackTemplate.title,
                          };
                }
                if (trackTemplate.personDefinition) {
                    initiative.personDefinition = trackTemplate.personDefinition;
                }

                if (trackTemplate.fieldsToUpdate) {
                    initiative.fieldsToUpdate = trackTemplate.fieldsToUpdate;
                    initiative.actualFieldsCount = $scope.getActualFieldsCount(initiative.fieldsToUpdate);
                }

                if (
                    trackTemplate.ownerEvaluationOption &&
                    trackTemplate.ownerEvaluationOption !== OWNER_EVALUATION_OPTIONS.personConfiguration
                ) {
                    trackTemplate.personDefinition = convertManualOwnerInfoToPersonSelection(
                        trackTemplate.ownerEvaluationOption,
                        trackTemplate.manualOwnerInfo,
                    );
                    initiative.personDefinition = trackTemplate.personDefinition;
                    loadInitiativePersonId(initiative);
                    $scope.onInnerTracksTemplateConfigChanged(false);
                }

                $scope.data.initiatives.push(initiative);
            }

            $scope.onInnerTracksTemplateConfigChanged(false);
        }
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
        }

        if (changesObj.shouldShowRemoveButton) {
            $scope.data.shouldShowRemoveButton = changesObj.shouldShowRemoveButton.currentValue;
        }
    };

    /**
     * This closes the popover when you click outside of it.
     */
    $scope.outsideClicked = function (index) {
        $scope.data.isOpen[index] = false;
    };

    /**
     * Checks whether the initiative has fields to update.
     */
    $scope.doesInitiativeHasFieldsToUpdate = function (fieldsToUpdate) {
        if (!fieldsToUpdate) {
            return false;
        }

        return $scope.getActualFieldsCount(fieldsToUpdate) > 0;
    };

    /**
     * Returns a counter of all the fields that are different than {}
     */
    $scope.getActualFieldsCount = function (fieldsToUpdate) {
        let actualFieldsCount = 0;

        fieldsToUpdate.forEach((field) => {
            if (field && !angular.equals(field, {})) {
                switch (field.inputTypeSelection) {
                    case 'EMPTY_VALUE': {
                        actualFieldsCount += 1;
                        break;
                    }

                    case 'MULTIPLE_VALUES': {
                        actualFieldsCount += isArrayIncludeNonEmptyExpression(field.multipleInputExpressionsValues)
                            ? 1
                            : 0;
                        break;
                    }

                    case 'EXPRESSION': {
                        actualFieldsCount += !utils.isNullOrUndefined(field.expressionValue?.originalExpression)
                            ? 1
                            : 0;
                        break;
                    }

                    case 'MANUAL': {
                        actualFieldsCount += isManualFieldContainValue(field) ? 1 : 0;
                        break;
                    }

                    default: {
                        actualFieldsCount += 1;
                    }
                }
            }
        });

        return actualFieldsCount;
    };

    /**
     * Adds an empty initiative to the initiatives.
     */
    $scope.addEmptyInitiative = function () {
        const emptyInitiative = getEmptyInitiative();
        $scope.data.initiatives.push(emptyInitiative);
        $scope.data.existingInnerTracksTemplate.push(emptyInitiative);
    };

    /**
     * Removes initiative from the initiatives.
     */
    $scope.removeInitiative = function (index) {
        $scope.data.initiatives.splice(index, 1);
        $scope.data.existingInnerTracksTemplate.splice(index, 1);

        $scope.outsideClicked(index);
        $scope.onInnerTracksTemplateConfigChanged(true);
    };

    $scope.onOwnerChanged = function (index, personSelectionConfiguration, shouldSaveLogic) {
        $scope.data.initiatives[index].personDefinition = personSelectionConfiguration;

        $scope.onInnerTracksTemplateConfigChanged(shouldSaveLogic);
    };

    /**
     * Updates the initiative at its index and saves logic if needs to.
     */
    $scope.onInnerTracksTemplateConfigChangedUpdateInitiative = function (index, initiative, shouldSaveLogic) {
        if (!utils.isNullOrUndefined(initiative.fieldsToUpdate) && initiative.fieldsToUpdate.length) {
            initiative.actualFieldsCount = $scope.getActualFieldsCount(initiative.fieldsToUpdate);
        }

        $scope.data.initiatives[index] = initiative;

        $scope.onInnerTracksTemplateConfigChanged(shouldSaveLogic);
    };

    /**
     * Occurs each time there's a change in the initiatives configuration.
     */
    $scope.onInnerTracksTemplateConfigChanged = function (shouldSaveLogic) {
        if (!$scope.data.initiatives || !$scope.data.initiatives.length) {
            ctrl.onInnerTracksTemplateConfigChanged(getServerTemplateConfiguration([], shouldSaveLogic));
            return;
        }

        // We only care for initiatives that don't have an empty title.
        const nonEmptyInitiatives = $scope.data.initiatives.filter(
            (initiative) =>
                (initiative &&
                    initiative.expression &&
                    initiative.expression.evaluatedExpression &&
                    initiative.expression.evaluatedExpression.length) ||
                (initiative.fieldsToUpdate && initiative.fieldsToUpdate.length),
        );
        if (!nonEmptyInitiatives || !nonEmptyInitiatives.length) {
            ctrl.onInnerTracksTemplateConfigChanged(getServerTemplateConfiguration([], shouldSaveLogic));
            return;
        }

        // Fire the event to send the updated model to the user of the component.
        if (ctrl.onInnerTracksTemplateConfigChanged) {
            ctrl.onInnerTracksTemplateConfigChanged(
                getServerTemplateConfiguration(nonEmptyInitiatives, shouldSaveLogic),
            );
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
        initiative,
    ) {
        initiative.expression.originalExpression = originalExpression;
        initiative.expression.evaluatedExpression = evaluatedExpression;
        $scope.onInnerTracksTemplateConfigChanged(shouldSaveLogic);
    };

    /**
     * Toggles the apply settings on previously created tracks check box.
     */
    $scope.toggleApplySettingsOnPreviouslyCreatedTracks = function () {
        $scope.data.applySettingsOnPreviouslyCreatedTracks = !$scope.data.applySettingsOnPreviouslyCreatedTracks;
        $scope.onInnerTracksTemplateConfigChanged(true);
    };

    /**
     * Load initiative person by id to use it's avatar
     */
    function loadInitiativePersonId(initiative) {
        // Load person to use it avatar
        if (initiative.personDefinition.specificPeopleIds && initiative.personDefinition.specificPeopleIds.length) {
            personCache.getEntityById(initiative.personDefinition.specificPeopleIds[0], false, true).then((person) => {
                initiative.selectedPeople = person;
            });
        } else {
            initiative.selectedPeople = null;
        }
    }

    /**
     * Gets the server configuration for tracks template.
     */
    function getServerTemplateConfiguration(initiatives, shouldSaveLogic) {
        return {
            innerTracksTemplate: {
                innerTrackTemplatesCollection: initiatives,
                applySettingsOnPreviouslyCreatedTracks: $scope.data.applySettingsOnPreviouslyCreatedTracks,
            },
            shouldSaveLogic,
        };
    }

    function isManualFieldContainValue(field) {
        switch (field.fieldId) {
            case 'TNK_DUE_DATE':
            case 'TNK_ETA':
                return !!field?.dateValue?.daysFromExecutionCurrentTime;

            case 'TNK_STAGE':
                return !!field?.statusValue;

            default:
                return false;
        }
    }

    // Instead of doing migration we convert the old owner info to person selection.
    function convertManualOwnerInfoToPersonSelection(ownerEvaluationOption, ownerManualInfo) {
        let personSelectionConfiguration;
        if (ownerEvaluationOption === OWNER_EVALUATION_OPTIONS.manual) {
            personSelectionConfiguration = {
                selectedPeopleIds: [ownerManualInfo.id],
                personSelectionType: 'specificPeople',
            };
        } else if (ownerEvaluationOption === OWNER_EVALUATION_OPTIONS.takeFromParent) {
            personSelectionConfiguration = {
                personSelectionType: 'initiativeOwner',
            };
        }

        return tonkeanUtils.buildPersonSelectionConfiguration(personSelectionConfiguration);
    }

    /**
     * Check if we have at least one not empty expression or empty field
     * @param multipleExpressions - Array of multiple expressions
     * @returns Whether there is empty expression in the array
     */
    function isArrayIncludeNonEmptyExpression(multipleExpressions) {
        return multipleExpressions?.some((field) => field?.originalExpression !== null && !angular.equals(field, {}));
    }

    /**
     * Returns an empty initiative (title is empty, and important fields are initialized).
     */
    function getEmptyInitiative() {
        return {
            title: '',
            ownerEvaluationOption: OWNER_EVALUATION_OPTIONS.personConfiguration,
            selectedPeople: null,
            personDefinition: null,
            peopleSelectorControl: {},
            expression: { originalExpression: '', evaluatedExpression: '' },
        };
    }
}

export default angular
    .module('tonkean.app')
    .controller('ManualItemsTemplateConfigCtrl', lateConstructController(ManualItemsTemplateConfigCtrl));
