/**
 * Represents a type of a database.
 */
enum SQLDatabaseType {
    MYSQL = 'MYSQL',
    MSSQL = 'MSSQL',
    POSTGRES = 'POSTGRES',
    AMAZON_REDSHIFT = 'AMAZON_REDSHIFT',
    AMAZON_ATHENA = 'AMAZON_ATHENA',
    GOOGLE_BIGQUERY = 'GOOGLE_BIGQUERY',
    ORACLE_DB = 'ORACLE_DB',
}

export default SQLDatabaseType;
