/**
 * An enum that describes what actions are possible when viewing an interface.
 */
enum ItemInterfacePermission {
    /**
     * The user has full permissions to edit the item.
     * The interface cannot be modified in this state.
     */
    USER_CAN_EDIT_ITEM = 'USER_CAN_EDIT_ITEM',

    /**
     * The user has full permissions to edit the item, but not add inner items.
     * The interface cannot be modified in this state.
     */
    USER_CAN_EDIT_EXISTING_ITEM = 'USER_CAN_EDIT_EXISTING_ITEM',

    /**
     * The user has read-only permissions to the item, and cannot modify any field.
     * The interface cannot be modified.
     */
    USER_CANT_EDIT_ITEM = 'USER_CANT_EDIT_ITEM',

    /**
     * The user has read-only permissions to the item, and cannot modify any field.
     * The interface and its widgets can be modified.
     */
    INTERFACE_IS_EDITABLE = 'INTERFACE_IS_EDITABLE',

    /**
     * The user has read-only permissions to the item, and cannot modify any field.
     * The interface can only be viewed.
     * In this state the user can:
     * * Click on widgets to see their configuration,
     * * Click on the header to see theme configuration
     *
     * But the user should not be able to edit any widget.
     */
    INTERFACE_IS_VIEW_ONLY = 'INTERFACE_IS_VIEW_ONLY',
}

export default ItemInterfacePermission;
