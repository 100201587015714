import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import OutlookAuthenticationType, { authTypeToDisplayName } from './OutlookAuthenticationType';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';
import type { Account } from '../docuSign/DocuSignProjectIntegrationData';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Checkbox, TnkSelect } from '@tonkean/infrastructure';
import utils from '@tonkean/utils';

const Divider = styled.hr`
    width: 100%;
`;

interface ProjectData {
    authType: string | undefined;
    tenantId: string | undefined;
    userId: string | undefined;
    selectedFolder: string | undefined;
    shouldCollectMessages: string | undefined;
}

export interface Folder {
    label: string | undefined;
    value: string | undefined;
    description: string | undefined;
}

const OutlookSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    onChangeOrInitIntegration,
    createProjectApis,
    projectIntegration,
    projectIntegrationData,
}) => {
    const projectManager = useAngularService('projectManager');
    const [{ loading }, callExecuteIntegrationCommand] = useLazyTonkeanService('executeIntegrationCommand');
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const [authType, setAuthType] = useState<string>(OutlookAuthenticationType.USER);
    const [tenantId, setTenantId] = useState<string | undefined>();
    const [appId, setAppId] = useState<string | undefined>();
    const [foldersList, setFoldersList] = useState<Folder[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<Account | undefined>();
    const [shouldCollectMessages, setShouldCollectMessages] = useState<boolean>(false);
    const [isLoadingFolders, setIsLoadingFolders] = useState(false);
    const [userIdChanged, setUserIdChanged] = useState(false);

    // Error
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        const init = async () => {
            const setupProjectData = projectIntegrationData as ProjectData;
            const existingProjectData = projectIntegration?.projectData?.projectDatas[0];

            const currentAuthType = setupProjectData?.authType || existingProjectData?.authType;
            const currentTenantId = setupProjectData?.tenantId || existingProjectData?.tenantId;
            const currentShouldCollectMessages =
                setupProjectData?.shouldCollectMessages || existingProjectData?.shouldCollectMessages;
            const currentUserId = userId || existingProjectData?.userId;

            setAuthType(currentAuthType);
            setShouldCollectMessages(currentShouldCollectMessages);
            setTenantId(currentTenantId);

            if (currentUserId && userId === undefined) {
                setUserId(currentUserId);
            }

            if (currentAuthType === OutlookAuthenticationType.APPLICATION) {
                callExecuteIntegrationCommand(
                    projectManager.project.id,
                    integration.id,
                    'GET_OUTLOOK_APP_ID',
                    {},
                    projectIntegration?.id,
                ).then((data) => {
                    setAppId(data.appId);
                });
            }

            if (
                currentShouldCollectMessages &&
                (currentAuthType !== OutlookAuthenticationType.APPLICATION || !utils.isNullOrEmpty(currentUserId))
            ) {
                setIsLoadingFolders(true);
                setError(undefined);
                try {
                    const folders = await createProjectApis.getAutoCompleteOptions(
                        projectManager.project.id,
                        integration.id,
                        'folders',
                        { userId: currentUserId },
                    );
                    const constructedFolders: Folder[] = constructAutoCompleteOptions(folders.options);
                    if (folders.options) {
                        setFoldersList(constructedFolders);
                    }
                } catch (error_) {
                    const errorMessage =
                        (error_ as IHttpResponse<any>)?.data?.data?.error?.cause?.message ||
                        'Error occurred while getting the Mail Folders';
                    setSelectedFolder(undefined);
                    setError(errorMessage);
                } finally {
                    setIsLoadingFolders(false);
                }

                // Edit mode.
                if (existingProjectData?.selectedFolder && !userIdChanged && !selectedFolder) {
                    setSelectedFolder(existingProjectData.selectedFolder);
                }
            }
        };
        init();
    }, [
        shouldCollectMessages,
        authType,
        callExecuteIntegrationCommand,
        createProjectApis,
        integration.id,
        projectIntegration,
        projectIntegrationData,
        projectManager.project.id,
        userId,
        selectedFolder,
        userIdChanged,
    ]);

    useEffect(() => {
        let isDisabled = false;
        if ((shouldCollectMessages && utils.isNullOrEmpty(selectedFolder)) || error) {
            isDisabled = true;
        }

        const projectIntegrationData = {
            authType,
            tenantId,
            userId,
            shouldCollectMessages,
            selectedFolder,
        };

        onChangeOrInitIntegration(
            {
                projectIntegrationData,
            },
            isDisabled,
        );
    }, [authType, onChangeOrInitIntegration, selectedFolder, tenantId, userId, shouldCollectMessages, error]);

    const onFolderSelected = useCallback((folder) => {
        setSelectedFolder(folder);
    }, []);

    const onUserIdChange = useCallback((userId) => {
        setUserId(userId);
        setUserIdChanged(true);
        setSelectedFolder(undefined);
    }, []);

    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: option.displayName,
            description: option.description,
        }));
    };

    return (
        <div className="flex flex-col api-token-authentication">
            <div className="form-group">
                <label className="col-sm-4 control-label margin-top-xs">Authentication Type</label>
                <div className="col-sm-7">
                    <input value={authTypeToDisplayName[authType]} className="form-control" readOnly />
                </div>
            </div>

            <div className="form-group margin-bottom-none">
                <label className="col-sm-4 control-label margin-top-xs" />
                <div className="col-sm-7">
                    <Checkbox checked={shouldCollectMessages} disabled readOnly>
                        Collect Messages, Attachments &amp; Folders
                    </Checkbox>
                </div>
            </div>

            <Divider />

            {authType === OutlookAuthenticationType.APPLICATION && (
                <>
                    <div className="form-group">
                        <label className="col-sm-4 control-label margin-top-xs">Tenant ID</label>
                        <div className="col-sm-7">
                            <input value={tenantId} className="form-control" readOnly />
                        </div>
                    </div>

                    <div className="margin-bottom-lg info-box common-size-xxs margin-top-md">
                        <div>
                            Your Azure admin may need to grant access to the Application in the Azure Enterprise
                            applications page or by using theis link:{' '}
                            <a
                                href={`https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${appId}`}
                                target="_blank"
                            >
                                https://login.microsoftonline.com/{tenantId}/adminconsent?client_id={appId}
                            </a>
                        </div>
                    </div>
                </>
            )}

            {(authType === OutlookAuthenticationType.APPLICATION || shouldCollectMessages) && (
                <div className="form-group">
                    <label className="col-sm-4 control-label margin-top-xs">User ID or Principal Name</label>
                    <div className="col-sm-7">
                        <input
                            value={userId}
                            className="form-control"
                            onChange={({ target }) => onUserIdChange(target.value)}
                            autoComplete="off"
                            placeholder="User ID or Principal Name"
                        />
                        <span className="common-size-xxxs common-color-grey">
                            {authType === OutlookAuthenticationType.APPLICATION &&
                                "If left empty, we won't collect any data but integration actions will be available."}
                            {authType !== OutlookAuthenticationType.APPLICATION &&
                                'You may provide a shared mailbox user ID to delegate on behalf.'}
                        </span>
                    </div>
                </div>
            )}

            {shouldCollectMessages && (
                <div className="flex flex-col">
                    <div className="form-group">
                        <label className="control-label col-sm-4 margin-top-6">Select Mail Folder</label>
                        <div className="col-sm-7">
                            <TnkSelect
                                placeholder="Select or Search Folder"
                                isMulti={false}
                                isCreatable={false}
                                isClearable={false}
                                options={foldersList}
                                onChange={onFolderSelected}
                                value={selectedFolder}
                                isSearchable
                                closeMenuOnSelect
                            />
                        </div>
                        <div className="margin-top-6">{isLoadingFolders && <i className="loading-small" />}</div>
                    </div>
                </div>
            )}

            <div className="margin-bottom-md">
                {error && <div className="alert alert-danger margin-bottom">{error}.</div>}
            </div>
        </div>
    );
};

export default OutlookSetupComponent;
