import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';

/* @ngInject */
function DisplayConfigurationCtrl($scope, utils) {
    const ctrl = this;

    $scope.data = {
        // Component Bindings.
        fieldDefinitionType: ctrl.fieldDefinitionType,
        fieldType: ctrl.fieldType,
        targetType: ctrl.targetType,
        displayAs: ctrl.displayAs,
        evaluatedDisplayType: ctrl.evaluatedDisplayType,
        displayFormat: ctrl.displayFormat,
        displayFormatPrefix: ctrl.displayFormatPrefix,
        displayFormatPostfix: ctrl.displayFormatPostfix,
        compareTimeframe: ctrl.compareTimeframe,
        isIncrementNegative: ctrl.isIncrementNegative,
        disabled: ctrl.disabled,
        isMultiValueField: ctrl.isMultiValueField,
        outputMultiValueSeparator: ctrl.outputMultiValueSeparator,
        projectIntegrationId: ctrl.projectIntegrationId,
        fieldLabel: ctrl.fieldLabel || '',
        isFromMatchedItems: ctrl.isFromMatchedItems,
        showFieldLabelInput: utils.isNullOrEmpty(ctrl.fieldLabel),

        // Consts
        displayAsApiNameToLabel: {
            String: 'Text',
            LongString: 'Long Text',
            Number: 'Number',
            Date: 'Date',
            Boolean: 'Boolean',
            List: 'Dropdown',
        },
        timeframeApiNameToLabel: {
            1: 'Yesterday',
            7: 'Last week',
            30: 'Last month',
        },
        maxDecimalPlace: 10,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        $scope.data.decimalPlacesOptions = Array.from({ length: $scope.data.maxDecimalPlace + 1 }, (_, i) => i);
    };

    ctrl.$onChanges = function (changes) {
        if (changes.displayAs) {
            $scope.data.displayAs = ctrl.displayAs;
        }
        if (changes.evaluatedDisplayType) {
            $scope.data.evaluatedDisplayType = ctrl.displayAs;
        }
        if (changes.displayFormat) {
            $scope.data.displayFormat = ctrl.displayFormat;
        }
        if (changes.displayFormatPrefix) {
            $scope.data.displayFormatPrefix = ctrl.displayFormatPrefix;
        }
        if (changes.displayFormatPostfix) {
            $scope.data.displayFormatPostfix = ctrl.displayFormatPostfix;
        }
        if (changes.compareTimeframe) {
            $scope.data.compareTimeframe = ctrl.compareTimeframe;
        }
        if (changes.isIncrementNegative) {
            $scope.data.isIncrementNegative = ctrl.isIncrementNegative;
        }
        if (changes.numberFieldDecimalPlaces) {
            $scope.data.decimalPlaces = !!ctrl.numberFieldDecimalPlaces ? ctrl.numberFieldDecimalPlaces.decimalPlaces : 3;
            $scope.data.includeTrailingZeros = ctrl.numberFieldDecimalPlaces ? !!ctrl.numberFieldDecimalPlaces.includeTrailingZeros : false;
        }
        if (changes.isMultiValueField) {
            $scope.data.isMultiValueField = ctrl.isMultiValueField;
        }
        if (changes.outputMultiValueSeparator) {
            $scope.data.outputMultiValueSeparator = ctrl.outputMultiValueSeparator;
        }
        if (changes.fieldLabel) {
            $scope.data.fieldLabel = ctrl.fieldLabel;
            $scope.data.showFieldLabelInput = !utils.isNullOrEmpty(ctrl.fieldLabel);
        }
    };

    $scope.fieldLabelChanged = function () {
        if (!utils.isNullOrEmpty($scope.data.fieldLabel)) {
            $scope.displayConfigurationChanged(true);
        } else {
            // Making sure the label will not be an empty string
            $scope.data.fieldLabel = undefined;
        }
    };

    $scope.toggleFieldLabel = function (toShow) {
        $scope.data.showFieldLabelInput = toShow;
        if (!toShow) {
            $scope.data.fieldLabel = undefined;
            $scope.displayConfigurationChanged(true);
        }
    };

    /**
     * Occurs once any of the configuration properties are changed.
     */
    $scope.displayConfigurationChanged = function (didDataChange) {
        const displayConfigurationObject = {
            displayAs: $scope.data.displayAs,
            numberFieldDecimalPlaces: {
                decimalPlaces: $scope.data.decimalPlaces,
                includeTrailingZeros: $scope.data.includeTrailingZeros,
            },
            evaluatedDisplayType: $scope.data.evaluatedDisplayType,
            displayFormat: $scope.data.displayFormat,
            displayFormatPrefix: $scope.data.displayFormatPrefix,
            displayFormatPostfix: $scope.data.displayFormatPostfix,
            compareTimeframe: $scope.data.compareTimeframe,
            isIncrementNegative: $scope.data.isIncrementNegative,
            outputMultiValueSeparator: $scope.data.outputMultiValueSeparator,
            fieldLabel: $scope.data.fieldLabel,

        };

        if (ctrl.onDisplayConfigurationChange) {
            ctrl.onDisplayConfigurationChange({ displayConfigurationObject, didDataChange });
        }
    };

    /**
     * Occurs once display as is selected.
     */
    $scope.selectDisplayAs = function (displayAsApiName) {
        analyticsWrapper.track('Select display as', { category: 'Edit Field Modal', label: displayAsApiName });
        $scope.data.displayAs = displayAsApiName;
        $scope.data.evaluatedDisplayType = displayAsApiName;

        $scope.displayConfigurationChanged(true);
    };

    /**
     * Occurs once display format is selected.
     */
    $scope.onDisplayFormatSelected = function (selectedDisplayFormat, skipAnalytics, didDataChange) {
        // skipAnalytics allows us to use this function from other places than the html without falsely collecting analytics.
        if (!skipAnalytics) {
            analyticsWrapper.track('Select display format', {
                category: 'Edit Field Modal',
                label: selectedDisplayFormat.label,
            });
        }

        $scope.data.displayFormat = selectedDisplayFormat.apiName;
        $scope.data.displayFormatPrefix = selectedDisplayFormat.prefix;
        $scope.data.displayFormatPostfix = selectedDisplayFormat.postfix;

        $scope.displayConfigurationChanged(didDataChange);
    };

    /**
     * Occurs once a compare time frame is selected.
     */
    $scope.selectCompareTimeframe = function (timeframeApiName) {
        analyticsWrapper.track('Select compare trend to', {
            category: 'Edit Field Modal',
            label: $scope.data.timeframeApiNameToLabel[timeframeApiName],
        });
        $scope.data.compareTimeframe = timeframeApiName;

        $scope.displayConfigurationChanged(true);
    };

    /**
     * Occurs once decimal places config is changed
     */
    $scope.selectDecimalPlaces = function (decimalPlaces) {

        $scope.data.decimalPlaces = decimalPlaces;

        $scope.displayConfigurationChanged(true);
    };

    /**
     * Occurs once includeTrailingZeros is changed
     */
    $scope.toggleIncludeTrailingZeros = function () {
        $scope.data.includeTrailingZeros = !$scope.data.includeTrailingZeros;

        $scope.displayConfigurationChanged(true);
    };

    /**
     * Occurs once increment is negative is changed.
     */
    $scope.selectIsIncrementNegative = function (isIncrementNegative) {
        analyticsWrapper.track('Increment is', {
            category: 'Edit Field Modal',
            label: isIncrementNegative ? 'Negative' : 'Positive',
        });

        $scope.displayConfigurationChanged(true);
    };
}

export default angular.module('tonkean.app').controller('DisplayConfigurationCtrl', lateConstructController(DisplayConfigurationCtrl));
