import type { TElement } from '@udecode/plate';
import React from 'react';
import styled from 'styled-components';

import { HTMLEditor } from '@tonkean/editor';
import { Field, useFormikField } from '@tonkean/infrastructure';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

const MarketplaceItemCreateModalDataSourceContentSection: React.FC = () => {
    const { value: description, setValue: setDescription } = useFormikField<TElement[]>('configuration.description');

    return (
        <StyledField label="Description">
            <HTMLEditor
                dataAutomation="marketplace-item-create-modal-summary"
                initialValue={description}
                onChange={(value) => setDescription(value)}
            />
        </StyledField>
    );
};

export default MarketplaceItemCreateModalDataSourceContentSection;
