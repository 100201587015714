import React, { useMemo } from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import ItemInterfaceWidgetUrlExpression from '../../CommonWidgetComponents/ItemInterfaceWidgetUrlExpression';
import EmbedUrlInputType, { EmbedUrlInputTypes } from '../../entities/EmbedUrlInputType';
import type EmbedWidgetConfiguration from '../EmbedWidgetConfiguration';
import { EmbedWidgetType } from '../EmbedWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { FormikTnkProjectIntegrationSelector } from '@tonkean/angular-to-react-components';
import { FormikEntitySelector } from '@tonkean/angular-to-react-components';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import {
    Field,
    Input,
    Radio,
    RadioGroup,
    SimpleSelect,
    useFormikField,
    useGetProjectIntegrationById,
} from '@tonkean/infrastructure';
import { ProjectIntegrationActionSelector } from '@tonkean/infrastructure';
import { useEntityAvailabeExternalFieldTonkeanExpressionTab } from '@tonkean/infrastructure';
import { ActionType } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const EditorWrapper = styled.div`
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-hight: 10px;
    color: ${Theme.colors.gray_700};
    min-width: 60px;
    margin-bottom: 8px;
`;

const UrlWrapper = styled.div`
    margin-top: 16px;
`;

const SectionTitle = styled(ConfigurationSectionTitle)`
    margin-left: 16px;
`;

const EntitySelectorWrapper = styled.div`
    .entity-selector-react {
        width: 100%;
    }
`;

const ACTION_TYPES = [ActionType.CUSTOM];

const EmbedWidgetEditor: React.FC<ItemWidgetEditorProps<EmbedWidgetConfiguration>> = ({
    configuration,
    onSave,
    workflowVersion,
}) => {
    const {
        itemInterface: { group, workflowVersionId },
        initiative,
    } = useItemInterfaceContext();

    const { setValue: setUrlInputType, value: urlInputType } = useFormikField<string | undefined>(
        'configuration.embed.urlInputType',
    );

    // Backward compatibility default assignment (widget was created before urlInputType was added
    if (urlInputType === undefined) {
        setUrlInputType((prevValue) => (prevValue === undefined ? EmbedUrlInputType.FROM_FIELD : prevValue));
    }
    const enableGetEmbedUrlFromActionFeature = useFeatureFlag('feature_get_embed_url_from_action');
    const { setValue: setUrlAction } = useFormikField<TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION> | undefined>(
        'configuration.embed.selectedActionId',
    );

    const { setValue: setUrlIntegration } = useFormikField<TonkeanId<TonkeanType.PROJECT_INTEGRATION> | undefined>(
        'configuration.embed.selectedProjectIntegrationId',
    );

    const { projectIntegration: selectedProjectIntegration } = useGetProjectIntegrationById(
        configuration.embed.type === EmbedWidgetType.URL ? configuration.embed.selectedProjectIntegrationId : undefined,
    );

    const availableExternalFieldTab = useEntityAvailabeExternalFieldTonkeanExpressionTab(
        configuration.embed.type === EmbedWidgetType.URL ? configuration.embed.selectedProjectIntegrationId : undefined,
        configuration.embed.type === EmbedWidgetType.URL ? configuration.embed.integrationEntityApiName : undefined,
    );

    const additionalTabs: TonkeanExpressionAdditionalTab[] = useMemo(() => {
        return [availableExternalFieldTab];
    }, [availableExternalFieldTab]);

    return (
        <>
            <SectionTitle>Embed Details</SectionTitle>
            <EditorWrapper>
                <RadioGroup name="configuration.embed.type" direction="row" size={InputSize.MEDIUM}>
                    <Radio value={EmbedWidgetType.URL} dataAutomation="embed-wodget-editor-type-url">
                        Url
                    </Radio>
                    <Radio value={EmbedWidgetType.HTML} dataAutomation="embed-wodget-editor-type-html">
                        HTML
                    </Radio>
                </RadioGroup>

                {configuration.embed.type === EmbedWidgetType.URL && (
                    <>
                        {enableGetEmbedUrlFromActionFeature && (
                            <Field
                                label="Embed URL Input Method"
                                informationTooltip="Select method of fetching embed URL"
                                light
                            >
                                <SimpleSelect
                                    name="configuration.embed.urlInputType"
                                    options={EmbedUrlInputTypes}
                                    thin
                                />
                            </Field>
                        )}

                        {enableGetEmbedUrlFromActionFeature && urlInputType === EmbedUrlInputType.FROM_ACTION ? (
                            <>
                                <Field label="Data Source" light>
                                    <FormikTnkProjectIntegrationSelector
                                        name="configuration.embed.selectedProjectIntegrationId"
                                        onProjectIntegrationSelected={(projectIntegration) => {
                                            if (configuration.embed.type === EmbedWidgetType.URL) {
                                                setUrlIntegration(projectIntegration.id);
                                                configuration.embed.selectedProjectIntegrationId = undefined;
                                            }
                                        }}
                                        includeAllNoCodeDatasources
                                    />
                                </Field>
                                <Field label="Entity" light>
                                    <EntitySelectorWrapper>
                                        <FormikEntitySelector
                                            name="configuration.embed.integrationEntityApiName"
                                            projectIntegration={selectedProjectIntegration}
                                        />
                                    </EntitySelectorWrapper>
                                </Field>

                                {configuration?.embed.selectedProjectIntegrationId && (
                                    <Field label="Data Source Action" light>
                                        <ProjectIntegrationActionSelector
                                            selectedProjectIntegrationActionId={configuration.embed.selectedActionId}
                                            projectIntegration={configuration.embed.selectedProjectIntegrationId}
                                            actionTypes={ACTION_TYPES}
                                            onProjectIntegrationActionChanged={setUrlAction}
                                        />
                                    </Field>
                                )}

                                {configuration?.embed.selectedActionId && (
                                    <>
                                        <Field label="External ID" light>
                                            <FormikTonkeanExpression
                                                workflowVersionId={workflowVersion.id}
                                                groupId={workflowVersion.groupId}
                                                name="configuration.embed.externalIdForAction"
                                                dataAutomation="embed-widget-editor-url-external-id"
                                                placeholder="Insert external id to fetch the url."
                                                additionalTabs={additionalTabs}
                                                defaultTabId={availableExternalFieldTab?.id}
                                                showFormulasTab={false}
                                                doNotEvaluatePreview
                                                hideEditorButton
                                            />
                                        </Field>
                                    </>
                                )}
                            </>
                        ) : (
                            <Field inlineError>
                                <UrlWrapper>
                                    <ItemInterfaceWidgetUrlExpression
                                        dataAutomation="embed-widget-editor-url-input"
                                        name="configuration.embed.url"
                                        placeholder="Enter Url"
                                    />
                                </UrlWrapper>
                            </Field>
                        )}
                    </>
                )}

                {configuration.embed.type === EmbedWidgetType.HTML && (
                    <Field inlineError>
                        <UrlWrapper>
                            <FormikTonkeanExpression
                                placeholder="Enter HTML"
                                name="configuration.embed.html"
                                groupId={group.id}
                                workflowVersionId={workflowVersionId}
                                overrideExampleInitiative={initiative?.id}
                                dataAutomation="embed-widget-editor-html-input"
                                includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']}
                                modTextArea
                            />
                        </UrlWrapper>
                    </Field>
                )}

                <Field inlineError>
                    <Label>Height</Label>
                    <Input
                        title="height"
                        placeholder="Height"
                        name="configuration.embed.height"
                        autoComplete="off"
                        data-automation="embed-widget-editor-height-input"
                    />
                </Field>
            </EditorWrapper>
        </>
    );
};

export default EmbedWidgetEditor;
