/**
 * Created by ShaLi on 20/01/2016.
 */

export function OktaModalCtrl(
    $scope,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    $scope.data = {
        subDomain: null,
        hasAdminRights: false,
        oauth: false,
        isCreate: true,
        oktaUrlSuffixOptions: ['.okta.com', '.oktapreview.com'],
        selectedOktaUrlSuffix: '.okta.com',
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.selected = state.selected || {};

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.connectWithToken = function () {
        if (
            $scope.data.loginModal.$valid &&
            !utils.isNullOrEmpty($scope.data.apiKey) &&
            !utils.isNullOrEmpty($scope.data.subDomain)
        ) {
            const subDomain = $scope.data.subDomain;
            $scope.error = null;
            $scope.loading = true;

            return createProjectApis
                .createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    {
                        domain: subDomain,
                        apiKey: $scope.data.apiKey,
                        isPreview: $scope.data.selectedOktaUrlSuffix === $scope.data.oktaUrlSuffixOptions[1],
                    },
                    integrations.getNewIntegrationUniqueType(authenticationService.currentUser.id, 'OKTA'),
                    undefined,
                )
                .then(function (integObj) {
                    $scope.integration = integObj;
                    $scope.data.isCreate = false;
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.loading = false;
                });
        }
    };

    $scope.changeUser = function () {
        $scope.data.isCreate = true;
        $scope.integration = null;
        $scope.selected = {};
    };

    $scope.ok = function () {
        const s = {
            selected: $scope.selected,
            integration: $scope.integration,
        };

        s.integrations = [
            {
                integration: $scope.integration,
                projectData: {},
            },
        ];

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.data.isCreate = false;
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;
            }
        } else {
            $scope.data.isCreate = true;
        }
    }
}
export default angular.module('tonkean.shared').controller('OktaModalCtrl', OktaModalCtrl);
