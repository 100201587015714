import React from 'react';

import CreateOrEditItemInterfaceModalContent from './CreateOrEditItemInterfaceModalContent';

import { Modal } from '@tonkean/infrastructure';

interface Props extends React.ComponentPropsWithoutRef<typeof CreateOrEditItemInterfaceModalContent> {
    open: boolean;
}

const CreateOrEditItemInterfaceModal: React.FC<Props> = ({
    open,
    onClose,
    workflowFolder,
    existingItemInterface,
    groupId,
}) => {
    return (
        <Modal open={open} onClose={onClose} size={550} dataAutomation="create-or-edit-item-interface-modal">
            <CreateOrEditItemInterfaceModalContent
                onClose={onClose}
                workflowFolder={workflowFolder}
                existingItemInterface={existingItemInterface}
                groupId={groupId}
            />
        </Modal>
    );
};

export default CreateOrEditItemInterfaceModal;
