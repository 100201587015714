/**
 * Creates an initiative title for the history modal - if it's a test, it will add a postfix of (test, PART_OF_ID),
 * otherwise, will return it as is.
 *
 * @param title - the real initiative title.
 * @param isTest - is it a test run?
 * @param id - the tonkean id of the initiative.
 * @returns a title to show in the history modal for the initiative.
 */
function initiativeTitle(title: string = 'Unknown', isTest: boolean = false, id: string = '') {
    if (!isTest) {
        return title;
    }

    return `${title} (Test, ${id.slice(4, 10)})`;
}

export default initiativeTitle;
