import changePlanModalTemplate from './modals/changePlanModal.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';

function OrgOverviewCtrl(
    $scope,
    $rootScope,
    $state,
    $q,
    $window,
    modalUtils,
    modal,
    consts,
    tonkeanService,
    utils,
    projectManager,
) {
    /**
     * This component used to inherit the scope of the settingsCtrl when rendered from the $state.
     * Now it is rendered with `ng-transclude`, so it doesn't inherit the scope, and we must manually add
     * the values from the parent scope (`$scope.$parent.$parent`) into the `$scope`.
     */
    Object.entries($scope.$parent?.$parent).forEach(([key, value]) => {
        if (!key.startsWith('$')) {
            $scope[key] = value;
        }
    });

    $scope.pm = projectManager;
    $scope.lps = $rootScope.lps;
    $scope.consts = consts;

    $scope.data = {
        showChangeSeats: false,
        showChangeBillingInfo: false,
        showChangePaymentInfo: false,
        showChangePlanType: false,
        numberOfFullUsers: 1,
        licenseData: null,
        countries: consts.getCountries(),
        usaStates: consts.getUsaStates(),
        loadingStripe: false,
        newLicense: false,
    };

    $scope.changePlanData = {
        newPlan: null,
        newPlanTotalPriceFactor: null,
    };

    // A sync object passed to the tnkCreditCard component. It overrides the submit function with its own submit logic.
    $scope.creditCardSyncObject = {
        submit() {
            console.log('tnkCreditCard component failed in initialize.');
        },
    };
    // The card extra data the form holds (zipCode and name). This data is also sent to stripe via the tnkCreditCard component.
    $scope.cardExtraData = {};

    $scope.forms = {};

    $scope.init = function () {
        // Enterprise projects have nothing to find here.
        if ($scope.pm.isEnterprise) {
            return;
        }

        // If the plan was loaded successfully, initialize the numberOfFullUsers.
        if ($scope.plan) {
            $scope.data.numberOfFullUsers = $scope.plan.quantity;
        }

        if ($scope.license) {
            // Get all the licensed users (full users).
            tonkeanService.getTeamMembers($scope.projectId, { onlyLicensed: true }).then((data) => {
                if (data && data.total) {
                    $scope.data.numberOfFullUsers = data.total;
                }
            });

            // Initialize the card extra data.
            if ($scope.license.customer && $scope.license.customer.card) {
                $scope.cardExtraData = {
                    name: $scope.license.customer.card.name,
                };
            }
        }

        // If in modal mode, auto-open the change plan form.
        if ($scope.$dismiss) {
            $scope.data.showChangePlanType = true;
        }
    };

    /**
     * Called when the tnkCreditCard directive is done loading stripe.
     */
    $scope.loadingStripeComplete = function () {
        $scope.data.loadingStripe = false;
    };

    $scope.askForHelp = function (text) {
        modal.openFeedback(text || '', true);
    };

    $scope.updateEmail = function (email) {
        const licenseData = {
            ccEmail: email,
        };

        tonkeanService
            .createOrUpdateLicense($scope.pm.project.id, licenseData)
            .then(function () {
                $scope.data.editBillingEmail = false;
                $scope.$emit('alert', {
                    type: 'success',
                    msg: 'Saved!',
                });
                $scope.updateOrganization();
            })
            .catch(function (error) {
                $scope.$emit('alert', error);
            });
    };

    /**
     * Opens the change plan modal and handles its result - cancels or updates the license with the new data.
     */
    $scope.openChangePlanModal = function () {
        // Only proceed in opening the change plans modal if we're not already opening it.
        if (!$scope.loadingChangePlansModal) {
            $scope.loadingChangePlansModal = true;

            // If there's a coupon discount for our current plan, get the plans again and apply that coupon.
            let populatePlansPromise = null;
            if ($scope.license.customer.discount) {
                populatePlansPromise = $scope.populatePlans($scope.license.customer.discount.coupon);
            } else {
                populatePlansPromise = $q.resolve();
            }

            populatePlansPromise
                .then(function () {
                    $scope.refreshNewPlan();

                    modalUtils
                        .open({
                            backdrop: 'static', // backdrop is present but modal window is not closed when clicking outside of the modal window
                            template: changePlanModalTemplate,
                            scope: $scope,
                            windowClass: 'fullscreen-modal',
                            animation: false,
                        })
                        .result.then(function () {
                            // change plan clicked.
                            // If the selected number of seats if not sufficient for the number of full users we have, don't continue.
                            // This is a fail-safe - not supposed to happen.
                            if ($scope.data.newQuantity < $scope.data.numberOfFullUsers) {
                                return;
                            }

                            $scope.setLoadingOrg(true);
                            analyticsWrapper.track('Change plan', {
                                category: 'Subscription',
                                label: $scope.changePlanData.newPlan.id,
                            });

                            const licenseData = {
                                plan: $scope.changePlanData.newPlan.id,
                                seats: $scope.data.newQuantity,
                            };
                            return tonkeanService
                                .createOrUpdateLicense($scope.pm.project.id, licenseData)
                                .then(() => {
                                    $scope.data.newLicense = true;

                                    $scope.$emit('alert', {
                                        type: 'success',
                                        msg: 'Plan was changed successfully!',
                                    });

                                    // Reload the org overview page (if not in modal mode).
                                    $scope.close();
                                })
                                .catch(() => {
                                    $scope.$emit('alert', {
                                        type: 'error',
                                        msg: 'An error occurred while trying to update plan.',
                                    });
                                })
                                .finally(() => {
                                    $scope.setLoadingOrg(false);
                                });
                        })
                        .catch(function () {
                            // cancel clicked.
                            // Reset the selected tier and interval.
                            $scope.sharedData.selectedTier = $scope.sharedData.tiersMap[$scope.plan.tier];
                            $scope.sharedData.selectedInterval = $scope.plan.interval;
                        });
                })
                .finally(function () {
                    $scope.loadingChangePlansModal = false;
                });
        }
    };

    /**
     * Updates the $scope.changePlanData.newPlan object according to the selected tier and interval
     * and recalculates other necessary properties.
     */
    $scope.refreshNewPlan = function () {
        $scope.changePlanData.newPlan = utils.findFirst($scope.sharedData.allPlans, (plan) => {
            return (
                plan.tier === $scope.sharedData.selectedTier.apiName &&
                plan.interval === $scope.sharedData.selectedInterval
            );
        });

        // This is not supposed to happen, but this is a fail safe in case it does.
        if (!$scope.changePlanData.newPlan) {
            $scope.$emit('alert', { type: 'error', msg: 'Could load plans - current plan not found.' });
            $scope.loadingChangePlansModal = false;
            return;
        }

        const isYearly = $scope.changePlanData.newPlan.interval === $scope.sharedData.intervals.year;
        $scope.changePlanData.newPlanTotalPriceFactor = isYearly ? 12 : 1;
    };

    /**
     * When the user clicks the x button of the modal (when in modal mode).
     */
    $scope.close = function () {
        if ($scope.$dismiss) {
            // If we are a modal, close it
            $scope.$dismiss();
            // If the user changed his license we must refresh the page because tnkRequire removed ng-click of some elements and we must restore them.
            if ($scope.data.newLicense) {
                // Hard reload so all the modals would close + tnkRequire would refresh
                $window.location.reload();
            }
        } else {
            // Otherwise reload the overview page.
            $state.go('product.settings.license', { tab: 'overview' }, { reload: true });
        }
    };

    $scope.updateLicenseBillingInfo = function () {
        analyticsWrapper.track('Edit License Billing Info', { category: 'Subscription' });

        $scope.savingBillingInfo = true;
        $scope.data.billingFormError = null;

        // Only send state if the country is US.
        if ($scope.license.customer.billingInformation.country !== 'US') {
            $scope.license.customer.billingInformation.state = null;
        }

        // Create the data model expected by the server.
        const updatedLicense = {
            organizationName: $scope.license.customer.billingInformation.organizationName,
            ccEmail: $scope.license.customer.billingInformation.ccEmail,
            phoneNumber: $scope.license.customer.billingInformation.phoneNumber,
            country: $scope.license.customer.billingInformation.country,
            state: $scope.license.customer.billingInformation.state,
            city: $scope.license.customer.billingInformation.city,
            address: $scope.license.customer.billingInformation.address,
            zipCode: $scope.license.customer.billingInformation.zipCode,
        };

        tonkeanService
            .createOrUpdateLicense($scope.pm.project.id, updatedLicense)
            .then(function () {
                $scope.data.showChangeBillingInfo = false;
            })
            .catch(function (error) {
                if (error.data && error.data.error && error.data.error.message) {
                    $scope.data.billingFormError = error.data.error.message;
                } else {
                    $scope.data.billingFormError = error.error || error;
                }
            })
            .finally(function () {
                $scope.savingBillingInfo = false;
            });
    };

    $scope.closeBillingInfoForm = function () {
        $scope.data.showChangeBillingInfo = false;
        $scope.data.billingFormError = null;
    };

    $scope.updateLicensePaymentInfo = function () {
        if ($scope.forms.changePaymentInfo.$valid) {
            analyticsWrapper.track('Edit License Payment Info', { category: 'Subscription' });
            $scope.data.paymentFormError = null;
            $scope.savingPaymentInfo = true;

            $scope.creditCardSyncObject
                .submit()
                .then(function (token) {
                    const licenseData = {
                        token: token.id,
                    };
                    return tonkeanService.createOrUpdateLicense($scope.pm.project.id, licenseData);
                })
                .then(function (freshLicense) {
                    // Update the data that might have changed in our instance of the license.
                    $scope.license.customer.card.last4 = freshLicense.license.customer.card.last4;
                    $scope.license.customer.card.name = freshLicense.license.customer.card.name;

                    $scope.data.showChangePaymentInfo = false;
                })
                .catch(function (error) {
                    if (error.data && error.data.error && error.data.error.message) {
                        $scope.data.paymentFormError = error.data.error.message;
                    } else {
                        $scope.data.paymentFormError = error.error || error;
                    }
                })
                .finally(function () {
                    $scope.savingPaymentInfo = false;
                });
        }
    };

    $scope.closePaymentInfoForm = function () {
        $scope.data.showChangePaymentInfo = false;
        $scope.data.paymentFormError = null;
    };

    $scope.init();
}
export default angular.module('tonkean.app').controller('OrgOverviewCtrl', OrgOverviewCtrl);
