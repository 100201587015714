import type CreateAfterOption from '../../../entities/CreateAfterOption';
import logicConfigModes from '../../../entities/LogicConfigMode';
import type TriggerParamsEnricher from '../../../entities/TriggerParamsEnricher';

import { CustomTriggerType } from '@tonkean/tonkean-entities';

const nlpBranchTriggerParamsEnricher: TriggerParamsEnricher = (params) => {
    params.customTriggerType = CustomTriggerType.NLP_BRANCH;
    params.displayName = addNlpBranchBlockCreateAfterOption.blockDisplayName;
    params.selectWithConfigMode = logicConfigModes.configuration;
};

const addNlpBranchBlockCreateAfterOption: CreateAfterOption = {
    label: 'Add Branch',
    blockDisplayName: 'Branch (NLP)',
    triggerParamsEnricher: nlpBranchTriggerParamsEnricher,
};

export default addNlpBranchBlockCreateAfterOption;
