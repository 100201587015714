import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaSingleField } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';

export default class ConvertObjectToKeyedArrayFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.CONVERT_OBJECT_TO_KEYED_ARRAY;
    override readonly sign = 'ConvertObjectToKeyedArray';
    override readonly displayName = 'Convert Object To Keyed Array';
    override readonly description = `converts a JSON object into an array, preserving the original key as "original_key" within each array element.`;
    override readonly dataType = FieldType.String;
    override readonly fields: FormulaSingleField[] = [
        {
            displayName: 'Field',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Json Path',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
