<div class="worker-email-button-answer-modal common-close-btn-container">
    <!-- Close button -->
    <div class="common-close-btn" ng-hide="inviting || data.hideModalButtons" ng-click="cancel()">
        <tnk-icon src="../../../svg/src/icons/x.svg"></tnk-icon>
    </div>

    <div class="text-center">
        <!-- Sending loading circle -->
        <div class="worker-button-answer-posting" ng-if="data.posting">
            <i class="loading-large"></i>
            <h3>Posting your answer...</h3>
        </div>

        <!-- Success message -->
        <div ng-if="data.isSuccess && !data.posting">
            <h3 class="common-title-md">Thanks! 👌</h3>
            <p>Your answer was posted.</p>
        </div>

        <!-- Error message -->
        <div ng-if="data.isError && !data.posting">
            <h3 class="common-title-md">Oh no!</h3>
            <p class="common-color-danger">There was an error posting your answer.</p>
        </div>

        <!-- Done button -->
        <div ng-if="!posting && !data.hideModalButtons" class="margin-top-xlg padding-top">
            <button type="button" class="btn btn-lg btn-primary" ng-click="cancel()">Done</button>
        </div>
    </div>
</div>
