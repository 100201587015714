import type WorkerRunReasonConfigItem from './WorkerRunReasonConfigItem';
import { ModuleItemInterface, SyncStatus } from './WorkerRunReasonConfigItem';

import { WorkerRunReason } from '@tonkean/tonkean-entities';

const workerRunReasonConfig: Record<WorkerRunReason, WorkerRunReasonConfigItem> = {
    // region Data source
    // region Created
    [WorkerRunReason.CREATED_EXTERNAL_ACTIVITY_SYNC_MET]: {
        eventType: 'Created',
        syncStatus: SyncStatus.MET,
    },
    [WorkerRunReason.CREATED_EXTERNAL_ACTIVITY_SYNC_NOT_MET]: {
        eventType: 'Created',
        syncStatus: SyncStatus.NOT_MET,
    },
    [WorkerRunReason.CREATED_EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION]: {
        eventType: 'Created',
        syncStatus: SyncStatus.DISABLED,
    },
    // endregion
    // region Updated
    [WorkerRunReason.UPDATED_EXTERNAL_ACTIVITY_SYNC_MET]: {
        eventType: 'Updated',
        syncStatus: SyncStatus.MET,
    },
    [WorkerRunReason.UPDATED_EXTERNAL_ACTIVITY_SYNC_NOT_MET]: {
        eventType: 'Updated',
        syncStatus: SyncStatus.NOT_MET,
    },
    [WorkerRunReason.DELETED_EXTERNAL_ACTIVITY]: {
        eventType: 'Deleted',
        syncStatus: SyncStatus.MET,
    },
    [WorkerRunReason.UPDATED_EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION]: {
        eventType: 'Updated',
        syncStatus: SyncStatus.DISABLED,
    },
    // endregion
    // region Unknown type
    [WorkerRunReason.EXTERNAL_ACTIVITY_SYNC_MET]: {
        eventType: 'Created/Updated',
        syncStatus: SyncStatus.MET,
    },
    [WorkerRunReason.EXTERNAL_ACTIVITY_SYNC_NOT_MET]: {
        eventType: 'Created/Updated',
        syncStatus: SyncStatus.NOT_MET,
    },
    [WorkerRunReason.EXTERNAL_ACTIVITY_PREVENTED_DISABLED_PROJECT_INTEGRATION]: {
        eventType: 'Created/Updated',
        syncStatus: SyncStatus.DISABLED,
    },
    // endregion
    [WorkerRunReason.SYNC_INITIATIVE_CREATION_FAILED]: {
        eventType: 'Created',
        success: false,
        // TODO: remove this, it's not really duplicated, it's just not supported in the current deploy,
        isDeprecated: true,
    },
    [WorkerRunReason.INITIATIVE_CREATION_PREVENTED_DUPLICATE_TITLE]: {
        eventType: 'Created',
        success: false,
        duplicateTitle: true,
        // TODO: remove this, it's not really duplicated, it's just not supported in the current deploy,
        isDeprecated: true,
    },
    // endregion

    // region Module item
    // region Sync related
    [WorkerRunReason.CREATED_INITIATIVE_BY_SYNC]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.DATA_SOURCE,
        description: 'Created by sync',
    },
    [WorkerRunReason.INITIATIVE_CREATED_FROM_SYNC]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.DATA_SOURCE,
        description: 'Created by sync',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_FROM_SYNC]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.DATA_SOURCE,
        description: 'Updated by sync',
    },
    // endregion
    // region Population
    [WorkerRunReason.INITIATIVE_FIELDS_UPDATED_IN_POPULATION]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.BACKGROUND_PROCESS,
        description: 'Item fields were refreshed',
    },
    // endregion
    // region Initiative updated/created with a describing message
    [WorkerRunReason.FORM_FILLED_CREATED_INITIATIVE]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.FORM,
        description: 'Create form filled',
    },
    [WorkerRunReason.TEST_MANUAL_INITIATIVE_CREATED]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.TEST_BY_USER,
        description: 'Test run',
    },
    [WorkerRunReason.TEST_EXTERNAL_INITIATIVE_CREATED]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.TEST_BY_USER,
        description: 'Test run',
    },
    [WorkerRunReason.MANUALLY_CREATED_INITIATIVE]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Item created manually',
    },
    [WorkerRunReason.MANUALLY_DUPLICATED_INITIATIVE]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.MANUAL_BY_USER,
        description: 'Item cloned manually',
    },
    [WorkerRunReason.INITIATIVE_CREATED_FROM_EMAIL_WEBHOOK]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.EMAIL_WEBHOOK,
        description: 'Item created by email',
    },
    [WorkerRunReason.INITIATIVE_CREATED_FROM_EXTERNAL]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Manually imported from data source',
    },
    [WorkerRunReason.INITIATIVE_CREATED_FROM_SUB_TRACKS_DIALOG]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.COMMUNICATION_INTEGRATION,
        description: 'Inner Item created',
    },
    [WorkerRunReason.INITIATIVE_CREATED_FROM_AT_MENTIONS_ACTIONS_DIALOG]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.COMMUNICATION_INTEGRATION,
        description: 'Inner Item created',
    },
    [WorkerRunReason.INITIATIVE_CREATED_DUE_TO_RUN_SCHEDULED_WORKER_NOW]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.MANUAL_BY_USER,
        description: 'Clicked on run now on scheduled trigger',
    },
    [WorkerRunReason.INITIATIVE_CREATED_FROM_WORKER_INNER_ITEMS_CREATION]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Inner item created',
    },
    [WorkerRunReason.INITIATIVE_CREATED_FROM_LOGIC]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.MODULE_FLOW,
        description: 'Work delegation from',
    },
    [WorkerRunReason.INITIATIVE_CREATED_FROM_BOT]: {
        eventType: 'Created',
        moduleItemInterface: ModuleItemInterface.COMMUNICATION_INTEGRATION,
        description: 'Item created',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_COMMENT]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'A comment was added',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_RUN_ON_EXISTING_ITEM_AGAIN]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.MANUAL_BY_USER,
        description: 'User rerun the item through the flow',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_MANUAL_ADMIN_POPULATION]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.MANUAL_BY_USER,
        description: 'Item fields were refreshed',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_BY_FORM_FROM_SLACK]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.COMMUNICATION_INTEGRATION_FORM,
        description: 'A form was answered',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_BY_FORM_FROM_APP]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.WEB_FORM,
        description: 'A form was answered',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_INNER_INITIATIVE_UPDATED]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.BACKGROUND_PROCESS,
        description: 'Inner item updated',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_MOVE_INITIATIVE]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Item was moved',
    },
    [WorkerRunReason.NEW_MONITORED_THREAD_MESSAGE]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.COMMUNICATION_INTEGRATION,
        description: 'Message was replied in a thread',
    },
    [WorkerRunReason.NEW_BUTTON_CLICK]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.COMMUNICATION_INTEGRATION,
        description: 'A button was clicked',
    },
    [WorkerRunReason.NEW_UPDATE_FORM_ANSWERED]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.FORM,
        description: 'Update form filled',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_INTEGRATION_FILTER]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'External sources of an item was edited',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_ASSOCIATE_INITIATIVE]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Related item converted to track',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_DUE_DATE_FROM_BOT]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.COMMUNICATION_INTEGRATION,
        description: 'Due date changed',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_FIELD_UPDATED]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT_OR_MODULE_FLOW,
        description: 'Field updated',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_FIELD_CREATED]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.FIELDS_SETTINGS,
        description: 'Field created',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_TAGS]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Tags changed',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_DUE_DATE]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Due date changed',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_TITLE]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Title changed',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_DESCRIPTION]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Description changed',
    },
    [WorkerRunReason.INITIATIVE_UPDATE_FUNCTION]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Function changed',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_OWNER]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Owner changed',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_STATE]: {
        eventType: 'Updated',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT,
        description: 'Status changed',
    },
    // endregion
    // region Archived
    [WorkerRunReason.INITIATIVE_UPDATED_ARCHIVED]: {
        eventType: 'Archived',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT_OR_MODULE_FLOW,
        description: 'Item was archived',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_UNARCHIVED]: {
        eventType: 'Un-Archived',
        moduleItemInterface: ModuleItemInterface.LIVE_REPORT_OR_MODULE_FLOW,
        description: 'Item was restored from archive',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_ARCHIVED_DETACHED]: {
        eventType: 'Archived',
        moduleItemInterface: ModuleItemInterface.INPUT_SOURCE_ITEM_DELETION,
        description: 'Intake source item deleted',
    },
    [WorkerRunReason.INITIATIVE_UPDATED_ARCHIVED_BACKGROUND_PROCESS]: {
        eventType: 'Archived',
        moduleItemInterface: ModuleItemInterface.MODULE_REACTIVATION_PROCESS,
        description: 'Item was archived',
    },

    // endregion
    // region User initiated
    [WorkerRunReason.USER_CLICKED_BUTTON_IN_INTERFACE]: {
        subType: 'A user clicked an interface button',
    },
    [WorkerRunReason.ACTION_TRIGGERED_ON_INTERFACE_LOAD]: {
        subType: 'Action triggered on interface load',
    },
    // endregion
    // endregion

    // region Flow runs
    [WorkerRunReason.RUN_SCHEDULED_AUTONOMOUS_TRIGGER_NOW]: { subType: 'Manually Triggered' },
    [WorkerRunReason.RERUN_FAILED_CUSTOM_TRIGGERS]: { subType: 'Manually Triggered Failed Actions' },
    [WorkerRunReason.SCHEDULED_AUTONOMOUS_TRIGGER]: { subType: 'Triggered on schedule' },
    [WorkerRunReason.AUTONOMOUS_TRIGGER_MATCH]: { subType: 'Conditions Met' },
    [WorkerRunReason.AUTONOMOUS_TRIGGER_DOES_NOT_MATCH]: { subType: "Conditions didn't met", hasNotRun: true },
    [WorkerRunReason.AUTONOMOUS_TRIGGER_DISABLED]: { subType: 'Trigger Disabled', hasNotRun: true },
    [WorkerRunReason.FIELD_CHANGE_AUTONOMOUS_TRIGGER_MATCH]: { subType: 'Triggered by Field changed' },
    [WorkerRunReason.MONITOR_FORM_TRIGGER_MATCH]: { subType: 'Triggered by form filled' },
    [WorkerRunReason.INITIATIVE_CREATED_BY_FORM]: { subType: 'Triggered by form filled' },
    [WorkerRunReason.RESUMED_DELAY_ACTION]: { subType: 'Resumed from a delay action' },
    // endregion

    // region Deprecated reasons
    [WorkerRunReason.UNKNOWN]: { isDeprecated: true },
    [WorkerRunReason.RESUMED_WORKER]: { isDeprecated: true },
    [WorkerRunReason.PERSON_INQUIRY_RESUMED]: { isDeprecated: true },
    [WorkerRunReason.ASK_FIELD_UPDATE_RESUMED]: { isDeprecated: true },
    [WorkerRunReason.RUN_SCHEDULED_WORKER_NOW]: { isDeprecated: true },
    [WorkerRunReason.TEST_RUN]: { isDeprecated: true },
    [WorkerRunReason.NEW_CREATED_INITIATIVE]: { isDeprecated: true },
    [WorkerRunReason.ACTION_ITEMS_RESUMED]: { isDeprecated: true },
    [WorkerRunReason.NEW_CREATED_INNER_INITIATIVE]: { isDeprecated: true },
    [WorkerRunReason.CHANGE_BUT_NO_NEW_AUTONOMOUS_TRIGGERS_MATCHED]: { isDeprecated: true },
    [WorkerRunReason.SCHEDULED_WORKER]: { isDeprecated: true },
    [WorkerRunReason.FORM_ANSWERED]: { isDeprecated: true },
    [WorkerRunReason.NO_CHANGE_IN_AUTONOMOUS_TRIGGER]: { isDeprecated: true },
    [WorkerRunReason.CHANGE_BUT_NO_AUTONOMOUS_TRIGGERS_MATCHED]: { isDeprecated: true },
    [WorkerRunReason.INITIATIVE_CREATION_PREVENTED_EXTERNAL_ID]: { isDeprecated: true },
    [WorkerRunReason.INITIATIVE_UPDATED]: { isDeprecated: true },
    [WorkerRunReason.AUTONOMOUS_TRIGGER_MATCH_BUT_ALREADY_MARKED]: { isDeprecated: true },
    [WorkerRunReason.INITIATIVE_UPDATED_EDIT_MODE]: { isDeprecated: true },
    [WorkerRunReason.INITIATIVE_CREATED_USING_TONKEAN_ACTION]: { isDeprecated: true },
    [WorkerRunReason.INITIATIVE_UPDATE_DELETE_LINK]: { isDeprecated: true },
    [WorkerRunReason.INITIATIVE_UPDATE_CREATE_LINK]: { isDeprecated: true },
    // endregion
};

export default workerRunReasonConfig;
