<!-- Single mode + Button mode - Enabled or new integration -->
<div
    ng-init="singleIntegration = integrations[0]"
    ng-hide="inlineSetupScreen"
    ng-if="!state[integrations[0].name].integrations[0] || !state[integrations[0].name].integrations[0].disabled"
    data-ng-disabled="saving || data.ngDisabled"
    uib-tooltip="{{ data.ngDisabled && data.ngDisabledTooltip ? data.ngDisabledTooltip : undefined }}"
    tnk-require="{{
        $root.pm.projectData.allowIntegrationsInTrial
            ? 'free'
            : singleIntegration && integrationRequireMap[singleIntegration.name]
            ? integrationRequireMap[singleIntegration.name]
            : defaultLicenseTierToAllow
    }}"
    ng-click="!data.ngDisabled && !saving && onClickIntegration(singleIntegration)"
    class="btn btn-default margin-right-xs common-size-xxs margin-bottom-xs integration-selection-button"
    data-automation="integration-group-data-source-option"
    ng-class="{ 'common-width-100': wideMode }"
>
    <!-- Not a webhook -->
    <div ng-if="!webhookSource" class="flex-inline mod-vmiddle" ng-style="{'max-width': buttonMaxWidth}">
        <i
            class="initiative-integration-icon mod-normal margin-right flex-no-shrink"
            ng-class="!singleIntegration.iconUrl ? 'mod-' + singleIntegration.name : ''"
            ng-style="{
                background: singleIntegration.iconUrl ? 'url(' + singleIntegration.iconUrl + ') no-repeat center' : null
            }"
        ></i>
        <span class="common-ellipsis">
            {{ buttonPrefix || ''
            }}{{ buttonText ||
                singleIntegration.displayName +
                    (state[integrations[0].name].integrations[0].integration.isNativeIntegration &&
                    !state[integrations[0].name].integrations[0].integration.supportsMultipleIntegrationsPerUser
                        ? ' (' + state[integrations[0].name].integrations[0].creator.firstName + ')'
                        : '')
            }}{{ buttonPostfix || '' }}
        </span>
        &nbsp;
        <i class="loading-small margin-left" ng-show="saving"></i>
    </div>

    <!-- Webhooks -->
    <div ng-if="webhookSource" class="overflow-hidden">
        <img
            ng-if="webhookSource.imgUrl"
            src="{{ webhookSource.imgUrl }}"
            class="initiative-integration-icon mod-normal mod-circle margin-right flex-no-shrink"
        />
        <span class="common-ellipsis">{{ buttonPrefix || '' }}{{ webhookSource.name }}</span>
        <i class="margin-left-xs common-color-grey common-ellipsis common-size-xxxxs">(Custom Source)</i>
        <i class="loading-small margin-left" ng-show="saving"></i>
        <!-- Integration with no multiple instances-->
    </div>
</div>

<!-- Single mode + Button mode - Existing disabled integration -->
<div
    ng-init="singleIntegration = integrations[0]; projectIntegration = state[integrations[0].name].integrations[0]"
    ng-hide="inlineSetupScreen"
    ng-if="state[integrations[0].name].integrations[0].disabled === true"
    data-ng-disabled="saving"
    tnk-require="{{
        singleIntegration && integrationRequireMap[singleIntegration.name]
            ? integrationRequireMap[singleIntegration.name]
            : defaultLicenseTierToAllow
    }}"
    ng-click="onClickIntegration(singleIntegration)"
    class="
        btn btn-default
        margin-right-xs
        common-size-xxs
        margin-bottom-xs
        integration-selection-button
        long-name-limit
    "
    ng-class="{ 'common-width-100': wideMode }"
>
    <div class="flex-inline mod-vmiddle initiative-integration">
        <i class="fa fa-exclamation common-color-danger margin-right-xs"></i>
        <i
            class="initiative-integration-icon mod-normal margin-right"
            ng-class="!projectIntegration.iconUrl ? 'mod-' + singleIntegration.name : ''"
            ng-style="{
                background: projectIntegration.iconUrl
                    ? 'url(' + projectIntegration.iconUrl + ') no-repeat center'
                    : null
            }"
        ></i>
        <span
            class="common-ellipsis initiative-integration-label"
            id="initiative-project-integration-{{ projectIntegration.id }}"
            tooltip-enable="utils.hasEllipsis('#initiative-project-integration-{{ projectIntegration.id }}')"
            uib-tooltip="{{ projectIntegration.displayName }}"
        >
            {{ buttonPrefix || ''
            }}{{
                projectIntegration.displayName +
                    (projectIntegration.integration.isNativeIntegration &&
                    !projectIntegration.integration.supportsMultipleIntegrationsPerUser
                        ? ' (' + projectIntegration.creator.firstName + ')'
                        : '')
            }}
        </span>
        <i class="loading-small margin-left" ng-show="saving"></i>
    </div>
</div>
