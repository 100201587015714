import React from 'react';
import styled, { css } from 'styled-components';

import EmailIntakeRestrictionBlockView from './EmailIntakeRestrictionBlockView';

import { Paragraph } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SubHeader = styled.div`
    display: flex;
    margin-bottom: 40px;
`;

const EmailIntakeRestrictionBlockViewWrapper = styled.div<{ isLast?: boolean }>`
    ${({ isLast = false }) =>
        !isLast &&
        css`
            margin-bottom: 34px;
        `}
`;

const Note = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_600};
    margin-bottom: 28px;
`;

const ProjectIntegrationPageEmailIntakeAccessControlPage: React.FC = () => {
    return (
        <>
            <SubHeader>
                <Paragraph $light>
                    Control who can send emails to your <Paragraph $inline>Tonkean Inbox</Paragraph>
                </Paragraph>
            </SubHeader>

            <EmailIntakeRestrictionBlockViewWrapper>
                <EmailIntakeRestrictionBlockView
                    header="Allow only emails from the following addresses: "
                    toggleName="accessControl.fromEmailAddresses.isEnabled"
                    inputTagsName="accessControl.fromEmailAddresses.emailAddresses"
                />
            </EmailIntakeRestrictionBlockViewWrapper>

            <Note>If an email was not found in the approved emails list, check if it is in the domain list:</Note>

            <EmailIntakeRestrictionBlockViewWrapper isLast>
                <EmailIntakeRestrictionBlockView
                    header="Allow only emails from the following domains: "
                    toggleName="accessControl.fromEmailDomains.isEnabled"
                    inputTagsName="accessControl.fromEmailDomains.emailDomains"
                />
            </EmailIntakeRestrictionBlockViewWrapper>
        </>
    );
};

export default ProjectIntegrationPageEmailIntakeAccessControlPage;
