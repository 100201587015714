<!-- Close button -->
<a ui-sref="products" class="close-new-product common-close-btn mod-big mod-with-text">
    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
</a>
<div class="new-product-title">
    <div ng-show="editProject">
        <i class="fa fa-wrench margin-right-xs"></i>
        <span>Manage Data Sources</span>
    </div>
    <div class="text-center margin-normal-v" ng-hide="editProject">
        <div class="common-title">Welcome to Tonkean!</div>
        <div class="common-subtitle">Let’s start by creating a board</div>
    </div>
</div>
<div class="new-product-splitter"></div>
<form class="new-product-steps" novalidate name="createProduct" ng-submit="submit()">
    <div class="new-product-step clearfix" ng-if="!editProject">
        <div class="new-product-step-content">
            <div class="margin-bottom">Board name:</div>
            <div class="form-group form-group-lg">
                <label for="projectName" class="sr-only">Board name</label>
                <input
                    type="text"
                    class="form-control"
                    id="projectName"
                    placeholder="Your company or team name..."
                    data-automation="create-product-form-enter-board-name"
                    ng-disabled="creatingProject"
                    ng-model="state.projectName"
                    required
                />
            </div>

            <div class="margin-normal-v hidden">
                <div class="margin-bottom-sm">In what org/company?</div>
                <select
                    class="form-control input-lg"
                    ng-options="o as o.name for o in $root.currentUser.organizations"
                    ng-model="state.organization"
                ></select>
            </div>
        </div>
    </div>
    <div class="new-product-step clearfix" ng-if="editProject">
        <div class="new-product-step-content">
            <tnk-integration-group
                group="integrationGroups.communication"
                sources="integrationGroups.communication.integrations(pm.project.id)"
                state="state.integrationState"
                integration-changed="onIntegrationSaved()"
                current-integration="currentIntegration"
                ng-disabled="creatingProject"
                ng-model="state.integrations.communication"
            ></tnk-integration-group>
            <tnk-integration-group
                group="integrationGroups.webhooks"
                sources="integrationGroups.webhooks.integrations"
                state="state.integrationState"
                integration-changed="onIntegrationSaved()"
                current-integration="currentIntegration"
                ng-disabled="creatingProject"
                ng-model="state.integrations.webhooks"
            ></tnk-integration-group>
            <tnk-integration-group
                group="integrationGroups.sales"
                sources="integrationGroups.sales.integrations"
                state="state.integrationState"
                integration-changed="onIntegrationSaved()"
                current-integration="currentIntegration"
                ng-disabled="creatingProject"
                ng-model="state.integrations.sales"
            ></tnk-integration-group>
            <tnk-integration-group
                group="integrationGroups.issues"
                sources="integrationGroups.issues.integrations"
                state="state.integrationState"
                integration-changed="onIntegrationSaved()"
                current-integration="currentIntegration"
                ng-disabled="creatingProject"
                ng-model="state.integrations.issues"
            ></tnk-integration-group>
            <tnk-integration-group
                group="integrationGroups.support"
                sources="integrationGroups.support.integrations"
                state="state.integrationState"
                integration-changed="onIntegrationSaved()"
                current-integration="currentIntegration"
                ng-disabled="creatingProject"
                ng-model="state.integrations.support"
            ></tnk-integration-group>
            <tnk-integration-group
                group="integrationGroups.data"
                sources="integrationGroups.data.integrations"
                state="state.integrationState"
                integration-changed="onIntegrationSaved()"
                current-integration="currentIntegration"
                ng-disabled="creatingProject"
                ng-model="state.integrations.data"
            ></tnk-integration-group>
            <tnk-integration-group
                group="integrationGroups.finance"
                sources="integrationGroups.finance.integrations"
                state="state.integrationState"
                integration-changed="onIntegrationSaved()"
                current-integration="currentIntegration"
                ng-disabled="creatingProject"
                ng-model="state.integrations.data"
            ></tnk-integration-group>
            <tnk-integration-group
                group="integrationGroups.code"
                sources="integrationGroups.code.integrations"
                state="state.integrationState"
                integration-changed="onIntegrationSaved()"
                current-integration="currentIntegration"
                ng-disabled="creatingProject"
                ng-model="state.integrations.code"
            ></tnk-integration-group>
        </div>
    </div>
    <div class="margin-normal-h common-subtitle-inner" ng-if="modal && editProject">
        Don't see the integration you need?
        <a ng-click="modal.openFeedback('Integration request')">Please let us know!</a>
    </div>
    <!--<hr/>-->
    <div class="new-product-step clearfix" ng-hide="editProject">
        <div class="new-product-step-content">
            <div class="flex-vmiddle">
                <p class="flex-grow">
                    <!--Please review and confirm the details above-->
                </p>
                <i class="loading common-icon-left" data-automation="loading-circle" ng-show="creatingProject"></i>
                <button
                    type="button"
                    ng-if="editProject"
                    ng-click="onCancel()"
                    analytics-on
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Cancel"
                    analytics-label="Cloud"
                    class="btn btn-default btn-lg common-icon-left"
                    ng-disabled="creatingProject"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    data-automation="tnk-create-product-form-template-create-board"
                    analytics-on
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Confirm"
                    analytics-label="Cloud"
                    class="btn btn-primary btn-lg"
                    ng-disabled="creatingProject"
                >
                    <i class="fa fa-check"></i>
                    {{ editProject ? 'Save' : 'Create Board' }}
                </button>
            </div>
        </div>
    </div>
</form>
