import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { EnterpriseComponentAuthorizationParam } from './EnterpriseComponentAuthorizationParam';
import EnterpriseComponentCertificationTwoWayTLS from './EnterpriseComponentCertificationTwoWayTLS';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import { SimpleSelect, Spacer } from '@tonkean/infrastructure';
import type { NoCertification, ProjectIntegration, TwoWayCertification } from '@tonkean/tonkean-entities';
import { CertificationTypeToDisplayName, EnterpriseComponentCertificateType } from '@tonkean/tonkean-entities';

const StyledSimpleSelect = styled(SimpleSelect)`
    width: 200px;
`;

interface Props {
    projectIntegration: ProjectIntegration;
    expressionProps: Partial<TonkeanExpressionProps>;
}

const EnterpriseComponentCertificationPage: React.FC<Props> = ({ projectIntegration, expressionProps }) => {
    const formikContext = useFormikContext<NoCertification | TwoWayCertification>();

    // Supported certification type options.
    const supportedCertificationTypes = Object.keys(
        EnterpriseComponentCertificateType,
    ) as EnterpriseComponentCertificateType[];

    const options = useMemo(() => {
        return supportedCertificationTypes.map((certificationType) => ({
            value: certificationType,
            label: CertificationTypeToDisplayName[certificationType],
        }));
    }, [supportedCertificationTypes]);

    return (
        <>
            <EnterpriseComponentAuthorizationParam>Certification Type:</EnterpriseComponentAuthorizationParam>
            <StyledSimpleSelect name="certificationType" options={options} />
            <Spacer height={12} />
            {formikContext?.values?.certificationType === EnterpriseComponentCertificateType.TWO_WAY_TLS && (
                <EnterpriseComponentCertificationTwoWayTLS expressionProps={expressionProps} />
            )}
        </>
    );
};

export default EnterpriseComponentCertificationPage;
