import template from './tnkSpecificGroupFieldsMetadataTable.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkSpecificGroupFieldsMetadataTable', {
    bindings: {
        onFieldSelected: '<',
        isDraft: '<',
        cantEditInProductionEnvironmentMessage: '<',
        reloadFields: '<',
        dropdownFieldOpened: '<',
        applyTriggerDependenciesFilter: '<',
        fieldEditorHasPendingChanges: '<',
        onTabSelected: '<',
        fieldFilterByName: '<',
        selectedFieldDefinitionForDependencies: '<',
        filteredCustomFields: '<',
        columns: '<',
        showYourFields: '<',
        showFields: '<',
        canModifyBot: '<',
        groupId: '<',
        workflowVersion: '<',
        applyFieldDependenciesFilter: '<',
        fieldDefinitionDependenciesCounter: '<',
        triggerDependenciesCounter: '<',
        loadDependenciesDropdownList: '<',
        showHideFields: '<',
        fieldType: '<',
        openDuplicateFieldModal: '<',
    },
    template,
    controller: 'SpecificGroupFieldsMetadataTableCtrl',
});
