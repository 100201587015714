/**
 * Get the number of values that the condition accepts
 * @param condition - condition to check
 * @returns {number} - The number of values accept
 */
export function getNumberOfValuesAcceptableByCondition(condition) {
    if (condition?.noValueRequired) {
        return 0;
    } else if (condition?.requiresSecondValue) {
        return 2;
    }

    return 1;
}
