<div>
    <strong>{{ item.actor.id === as.currentUser.id ? 'You ' : item.actor.name + ' '}}</strong>

    <!-- Type of change -->
    <ANY ng-switch="item.type">
        <ANY ng-switch-when="INITIATIVE_STATUS_CHANGED">
            has marked this initiative
            <strong class="common-color-success">{{ item.metadata.newStatus }}</strong>
            &nbsp;-
        </ANY>
        <ANY ng-switch-when="NEW_INITIATIVE">created a new item:</ANY>
        <ANY ng-switch-when="INITIATIVE_DESCRIPTION_CHANGED">changed the description</ANY>
        <ANY ng-switch-when="INITIATIVE_TAGS_CHANGED">changed the goals</ANY>
        <ANY ng-switch-when="INITIATIVE_LEADER_CHANGED">changed the owner</ANY>
        <ANY ng-switch-when="INITIATIVE_TITLE_CHANGED">changed the title</ANY>
        <ANY ng-switch-when="INITIATIVE_DUE_DATE_CHANGED">changed the due date</ANY>
        <ANY ng-switch-default>made changes</ANY>
    </ANY>

    <!-- Inner items -->
    <span ng-if="item.reference1.id !== item.target.id || targetId !== item.target.id">
        <div class="inline-block margin-left-xxxs" ng-if="item.type !== 'NEW_INITIATIVE'">of</div>
        <a
            ng-click="modal.openViewInitiative(item.reference1.id)"
            class="common-color-dark-grey common-bold margin-left-xxxs"
        >
            {{ item.reference1.title }}
        </a>
    </span>
</div>
