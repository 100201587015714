import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import type { BaseDataSourceConnectionConfigurationProps } from './baseDataSourceConnectionConfigurationProps';
import BoardAdmins from '../../../EnterpriseComponentsModule/components/EnterpriseComponentPageLayout/EnterpriseComponentMenuItemPageContent/BoardAdmins';
import type { AdditionalSidePaneConfiguration } from '../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import PeoplePermissionsPicker from '../PeoplePermission/PeoplePermissionsPicker';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import type { Person } from '@tonkean/tonkean-entities';
import { ConnectionPermission } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const TitleText = styled.div`
    color: ${Theme.colors.gray_500};
    margin-bottom: 17px;
`;

const Wrapper = styled.div`
    margin-top: 20px;
`;

const DataSourceConnectionAccessibilityTab: React.FC<
    AdditionalSidePaneConfiguration<BaseDataSourceConnectionConfigurationProps>
> = ({ currentDataSourceConnection, setCurrentDataSourceConnection }) => {
    const project = useProject();

    const [{ data: authorizables }, getAuthorizables] = useLazyTonkeanService('getAuthorizablesByIds');

    useEffect(() => {
        getAuthorizables(project.id, currentDataSourceConnection.authorizedEntitiesIds);
    }, [currentDataSourceConnection, getAuthorizables, project.id]);

    const onPeoplePermissionsPickerChanged = useCallback(
        (changedConnectionPermission: ConnectionPermission, changedAuthorizedEntities: Person[]) => {
            const authorizedEntitiesIds = changedAuthorizedEntities.map((person) => person.id);

            setCurrentDataSourceConnection({
                ...currentDataSourceConnection,
                authorizedEntitiesIds,
                connectionPermission: changedConnectionPermission,
            });
        },
        [currentDataSourceConnection, setCurrentDataSourceConnection],
    );

    return (
        <Wrapper>
            <TitleText>Who can connect this data source type</TitleText>

            {authorizables && (
                <PeoplePermissionsPicker
                    connectionPermission={currentDataSourceConnection.connectionPermission}
                    authorizedPeople={authorizables}
                    onChange={onPeoplePermissionsPickerChanged}
                    noOneLabel="No one except board admins"
                />
            )}

            {currentDataSourceConnection.connectionPermission === ConnectionPermission.SPECIFIC_MEMBERS && (
                <BoardAdmins />
            )}
        </Wrapper>
    );
};

export default DataSourceConnectionAccessibilityTab;
