import React from 'react';
import styled, { css } from 'styled-components';

import { getIconComponent } from './WidgetIconUtils';

import { LogicIcon } from '@tonkean/infrastructure';
import { NoIcon } from '@tonkean/svg';
import { TonkeanIcon } from '@tonkean/svg';
import type { Icon } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { classNames, colorSvg } from '@tonkean/utils';

const TONKEAN_ICON_NAME = 'tonkean';

const StyledNoIcon = styled(NoIcon)`
    border: 1px solid ${Theme.colors.gray_700};
`;

const IconWrapper = styled.div<{ $isSelected: boolean | undefined; $iconColor: string | undefined; $iconName: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 20px;
        height: 20px;
        background-size: contain !important;
        background: no-repeat;
        margin: 8px;
    }

    &:hover {
        background: ${Theme.colors.gray_300};
        border-radius: 2px;
    }

    ${({ $isSelected, $iconColor }) =>
        $isSelected &&
        css`
            background: ${Theme.colors.tabsHover};
            border-radius: 2px;
            border: 1px solid ${Theme.colors.primaryHighlight};

            div {
                color: ${$iconColor};
            }
        `}
    ${({ $iconName, $iconColor }) =>
        $iconName !== NO_ICON && $iconColor
            ? css`
                  ${colorSvg($iconColor)}
                  color: ${$iconColor};
              `
            : css`
                  ${colorSvg(Theme.colors.gray_700)}
                  color: ${Theme.colors.gray_700};
              `}
`;

const StyledIcon = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    width: 20px;
    margin: 8px;
    min-height: 20px;
`;
const LogicIconWrapper = styled.div`
    display: flex;
    margin: 8px;
    align-self: baseline;
`;
const NO_ICON: string = 'no icon';
interface Props {
    icon: Icon;
    selectedIcon?: Icon | undefined;
    color?: string;
}

const TonkeanIconInsidePicker: React.FC<Props> = ({ icon, selectedIcon, color }) => {
    const IconComponent = getIconComponent(icon);
    const isSelected =
        (!selectedIcon && icon.name === NO_ICON) ||
        (selectedIcon?.name === icon?.name &&
            selectedIcon?.type === icon?.type &&
            selectedIcon?.integrationName === icon?.integrationName &&
            selectedIcon?.integrationId === icon?.integrationId);

    return (
        <IconWrapper $isSelected={isSelected} $iconColor={color} $iconName={icon.name}>
            {icon.name === TONKEAN_ICON_NAME ? (
                <span className="tnk-icon">
                    <TonkeanIcon style={{ height: '22px', margin: '8px' }} key={`${icon.name} component`} />
                </span>
            ) : icon.name === NO_ICON ? (
                <StyledNoIcon />
            ) : IconComponent ? (
                <IconComponent style={{ height: '20px', margin: '8px' }} key={`${icon.name} component`} />
            ) : icon.integrationName || icon.integrationId ? (
                <LogicIconWrapper>
                    <LogicIcon
                        integrationType={icon.integrationName}
                        logicBlockType={icon.blockType}
                        projectIntegrationId={icon.integrationId}
                        size={20}
                        key={icon.integrationName}
                        iconUrl={icon.iconUrl}
                    />
                </LogicIconWrapper>
            ) : icon.isFullClassName ? (
                <StyledIcon className={icon.iconClass} key={`${icon.name}Icon`} />
            ) : (
                <StyledIcon
                    className={classNames(`fa-${icon.iconClass}`, `fa-${icon.name}`)}
                    key={`${icon.name}Icon`}
                />
            )}
        </IconWrapper>
    );
};

export default TonkeanIconInsidePicker;
