import * as React from 'react';
import styled from 'styled-components';

import { ReactComponent as CommunicationSourcesOutlineIcon } from '../../../../images/icons/communication_sources_outline.svg';
import { ReactComponent as HaveAQuestionIcon } from '../../../../images/icons/have-a-question-desktop.svg';
import { ReactComponent as BlueprintIcon } from '../../../../images/icons/solution-icon.svg';
import { ReactComponent as UseThisBlueprintIcon } from '../../../../images/icons/use-this-blueprint-icon.svg';

import { BlueprintButton, BlueprintButtonColor, BlueprintButtonSize } from '@tonkean/marketplace-item-view';
import { FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
`;

const Title = styled.div`
    font-family: Lato;
    font-weight: 500;
    font-size: ${FontSize.XXXXXXLARGE_32};
    line-height: 44px;
    margin-top: 20px;
`;

const SubTitle = styled.div`
    font-family: Lato;
    font-weight: 500;
    font-size: ${FontSize.XXLARGE_20};
    line-height: 44px;
    margin-top: 8px;
`;

const Actions = styled.div`
    margin-top: 45px;
    display: flex;
    gap: 15px;
`;

const BlueprintIconStyled = styled(BlueprintIcon)`
    height: 85px;
    width: 85px;
`;

const HaveQuestionIconStyled = styled(HaveAQuestionIcon)`
    position: absolute;
    pointer-events: none;
    left: 95%;
    top: 20%;
`;

interface Props {}

const EmptyTrialBoard: React.FC<Props> = ({}) => {
    return (
        <Wrapper>
            <BlueprintIconStyled />
            <Title>Your account was created successfully.</Title>
            <SubTitle>Download your first blueprint from the</SubTitle>
            <SubTitle>public library to start the Tonkean journey!</SubTitle>
            <Actions>
                <BlueprintButton
                    $size={BlueprintButtonSize.SMALL}
                    $color={BlueprintButtonColor.DEFAULT}
                    href="https://library.tonkean.com/?category=Solutions+Blueprints"
                >
                    <UseThisBlueprintIcon />
                    Download a Blueprint
                </BlueprintButton>
                <BlueprintButton
                    $size={BlueprintButtonSize.SMALL}
                    $color={BlueprintButtonColor.DARK}
                    onClick={() => Intercom('showNewMessage')}
                >
                    <CommunicationSourcesOutlineIcon />
                    Speak with an expert
                    <HaveQuestionIconStyled aria-label="Have a question?" />
                </BlueprintButton>
            </Actions>
        </Wrapper>
    );
};

export default EmptyTrialBoard;
