import { useMemo } from 'react';

import type { SingleFieldConfiguration } from '../../CommonWidgetConfiguration';
import type { InnerItemWidgetConfiguration } from '../../entities';

import type { FieldDefinitionKey, WidgetBase } from '@tonkean/tonkean-entities';

const useShowFilterFields = (
    widget: WidgetBase<InnerItemWidgetConfiguration>,
    configurationFields: Record<FieldDefinitionKey, SingleFieldConfiguration>,
): boolean => {
    return useMemo<boolean>(() => {
        return (
            Object.values(configurationFields).some((field) => field.isShown && field.isFilterColumn) ||
            Boolean(widget.configuration.displayAdvancedFilters)
        );
    }, [configurationFields, widget.configuration.displayAdvancedFilters]);
};

export default useShowFilterFields;
