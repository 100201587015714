import React from 'react';
import styled from 'styled-components';

import ModuleIntegrationsPreview, {
    IntegrationsPreviewSize,
} from '../../../../../../infrastructure/components/ModuleIntegrationsPreview';

import { TextEllipsis, ZebraTableBodyCell } from '@tonkean/infrastructure';
import type { Group } from '@tonkean/tonkean-entities';

const StyledModuleName = styled.div`
    margin-bottom: 10px;
    padding-right: 8px;
`;

const ModuleNameGridCell = styled(ZebraTableBodyCell)`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

interface Props {
    module: Group;
}

const GridModuleName: React.FC<Props> = ({ module }): React.ReactElement => {
    if (!module) {
        return <>Module not Available</>;
    }

    return (
        <ModuleNameGridCell>
            <StyledModuleName>
                <TextEllipsis numberOfLines={1} tooltip>
                    {module.name}
                </TextEllipsis>
            </StyledModuleName>
            <ModuleIntegrationsPreview worker={module} size={IntegrationsPreviewSize.SMALL} />
        </ModuleNameGridCell>
    );
};

export default GridModuleName;
