import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */

function ChannelSelectorCtrl($scope, tonkeanService, projectManager, communicationIntegrationsService) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.cis = communicationIntegrationsService;

    $scope.data = {
        // Component Bindings.
        selectedChannel: ctrl.selectedChannel,
        enableClearChannel: ctrl.enableClearChannel,
        classes: ctrl.classes,
        inSentenceMode: ctrl.inSentenceMode,
        viewOnly: ctrl.viewOnly,
        appendToBody: ctrl.appendToBody,
        selectorTooltip: ctrl.selectorTooltip,

        // Local data properties.
        notificationChannels: null,
        communicationIntegration: null,
        communicationChannelLabel: 'channel',
        communicationIntegrationLabel: 'Slack channel',

        showRefreshButton: ctrl.showRefreshButton ?? true,
    };

    $scope.loadingOptions = false;

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        if (ctrl.selectedIntegration) {
            $scope.data.communicationIntegration = ctrl.selectedIntegration;
        } else {
            // Get the communication integration.
            $scope.data.communicationIntegration = $scope.cis.getDefaultCommunicationIntegration();
            if (
                !$scope.cis.supportsFeature(
                    $scope.data.communicationIntegration.integrationType,
                    $scope.cis.features.channels,
                )
            ) {
                $scope.data.communicationIntegration = $scope.cis.getFirstThatSupports($scope.cis.features.channels);
            }

            // Set the communication integration relevant labels.
            if ($scope.data.communicationIntegration) {
                $scope.data.communicationChannelLabel =
                    $scope.cis.integrations[$scope.data.communicationIntegration.integrationType].channelLabel;
                $scope.data.communicationIntegrationLabel = $scope.cis.getFirstThatSupportsLabel(
                    $scope.cis.features.channels,
                );
            }
        }

        // Load the channels for this communication integration.
        $scope.loadAutoCompleteChannels();
    };

    /**
     * Called whenever one-way bindings are updated. The changes object is a hash whose keys are the names of the bound properties that have changed,
     * and the values are an object of the form.
     */
    ctrl.$onChanges = function (changes) {
        // If reloadFields was set to true, reload field options.
        if (changes.reloadChannels && changes.reloadChannels.currentValue) {
            $scope.loadAutoCompleteChannels();
        }

        if (changes.selectedIntegration && changes.selectedIntegration.currentValue) {
            $scope.data.communicationIntegration = ctrl.selectedIntegration;
            $scope.loadAutoCompleteChannels();
        }

        if ((changes.selectedChannel && changes.selectedChannel.currentValue) || !changes.selectedChannel) {
            $scope.data.selectedChannel = ctrl.selectedChannel;
        }
    };

    $scope.loadAutoCompleteChannels = function () {
        if ($scope.data.communicationIntegration) {
            $scope.loadingOptions = true;
            const integrationId =
                $scope.data.communicationIntegration?.integration?.id || $scope.data.communicationIntegration.id;
            tonkeanService
                .getCommunicationChannels(
                    projectManager.project.id,
                    integrationId,
                    $scope.data.communicationIntegration?.projectData?.serviceUrl ||
                        $scope.data.communicationIntegration?.projectData?.projectDatas?.[0]?.serviceUrl,
                    $scope.data.communicationIntegration?.projectData?.teamId ||
                        $scope.data.communicationIntegration?.projectData?.projectDatas?.[0]?.teamId,
                )
                .then(function (data) {
                    $scope.data.notificationChannels = data.options;

                    // Call the onChannelsLoaded callback if it was supplied.
                    if (ctrl.onChannelsLoaded) {
                        ctrl.onChannelsLoaded({ channels: $scope.data.notificationChannels });
                    }
                })
                .finally(() => {
                    // Clear the loadingFlag and reloadChannels flag both for success and for error cases.
                    $scope.loadingOptions = false;
                    // Reset the reload channels flag, so we can be notified again.
                    ctrl.reloadChannels = false;
                });
        }
    };

    $scope.onChannelSelected = function (channel) {
        $scope.data.selectedChannel = channel;
        if (ctrl.onChannelSelected) {
            ctrl.onChannelSelected({ channel });
        }
    };
}

angular.module('tonkean.app').controller('ChannelSelectorCtrl', lateConstructController(ChannelSelectorCtrl));
