<div class="delay-configuration-logic">
    <!-- Title -->
    <div class="margin-bottom-xs">Delay:</div>

    <div class="flex-vmiddle">
        <!-- Input for time amount -->
        <input
            type="number"
            class="form-control margin-right"
            ng-model="data.delayInMinutes"
            ng-change="onActionsChanged(true)"
        />

        <!-- Selection for time unit -->
        <ui-select
            theme="bootstrap"
            class="common-width-30 time-unit-selection"
            ng-change="onActionsChanged(true)"
            ng-model="data.delayTimeUnit"
        >
            <ui-select-match placeholder="Time unit...">{{ data.delayTimeUnit.displayName }}</ui-select-match>
            <ui-select-choices repeat="timeUnitOption in data.timeUnitOptions | filter:$select.search">
                <div ng-bind-html="timeUnitOption.displayName | escapeHtml | highlight: $select.search"></div>
            </ui-select-choices>
        </ui-select>
    </div>

    <div ng-if="data.validationObject.noDelayTime" class="common-color-danger margin-top-6">
        {{ data.validationObject.noDelayTime }}
    </div>
</div>
