import twiliochatCircleImage from '../../../../../apps/tracks/images/integrations/twiliochat-circle.png';
import twilioImage from '../../../../../apps/tracks/images/twilio.png';
import AuthenticationMethod from '../../base/AuthenticationMethod';
import type DataRetentionSettings from '../../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../../base/IntegrationDefinitionBase';
import IntegrationIcon from '../../base/IntegrationIcon';
import IntegrationIconsSettings from '../../base/IntegrationIconsSettings';
import TwilioAuthenticationComponent from '../common/TwilioAuthenticationComponent';
import TwilioCustomizeSetupComponent from '../common/TwilioSetupComponent';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

export default class TwilioSMSIntegration extends IntegrationDefinitionBase {
    override name = 'twiliosms';
    override displayName = 'Twilio SMS';
    override description = 'We will only collect sms items.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override externalActivityCreatedDateFieldName = 'date_created';
    override entities = [new IntegrationEntity('SMS', 'SMS')];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(twiliochatCircleImage, '130px'),
        new IntegrationIcon(twilioImage, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { SMS: true },
        isOnlyDataRetention: { SMS: true },
    };

    override customizedAuthenticationComponent = TwilioAuthenticationComponent;
    override customizedSetupComponent = TwilioCustomizeSetupComponent;
}
