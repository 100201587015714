import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { useToastMessage } from '@tonkean/angular-hooks';
import { DatepickerInput, Radio, RadioGroup } from '@tonkean/infrastructure';
import { FieldType, fieldTypeToFieldDisplayName } from '@tonkean/tonkean-entities';
import { toArray } from '@tonkean/utils';

export const FORMULA_CONST_DATE_FORMAT = 'D MMM YYYY';

type SupportedType = FieldType.Boolean | FieldType.String | FieldType.Number | FieldType.Date;

const initialValues: Record<SupportedType, string> = {
    [FieldType.Number]: '',
    [FieldType.String]: '',
    [FieldType.Boolean]: '',
    [FieldType.Date]: '',
};

function valuesReducer(
    values: Record<SupportedType, string>,
    { type, value }: { value: string; type: SupportedType },
): Record<SupportedType, string> {
    return {
        ...values,
        [type]: value,
    };
}

interface Props {
    defaultValue?: string;
    defaultType?: FieldType;
    isHTMLTag?: boolean;
    allowedTypes?: FieldType | FieldType[];
    onSelected: (value: string, dataType: FieldType) => void;
}

const FormulaPopoverTabConst: React.FC<Props> = ({
    defaultValue = '',
    defaultType,
    isHTMLTag,
    allowedTypes,
    onSelected,
}) => {
    const manualFieldRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        manualFieldRef.current?.focus();
    }, []);

    const allowedTypesArray = useMemo(() => {
        const supportedTypes: SupportedType[] = [FieldType.String, FieldType.Number, FieldType.Boolean, FieldType.Date];
        return toArray(allowedTypes ?? supportedTypes).filter((type) =>
            supportedTypes.includes(type as any),
        ) as SupportedType[];
    }, [allowedTypes]);

    const [type, setType] = useState(() => (defaultType as SupportedType) ?? allowedTypesArray[0]);
    const [values, setValues] = useReducer(valuesReducer, undefined, () => ({
        ...initialValues,
        [type]: defaultValue,
    }));

    const onTypeSelected = useCallback((selectedType: SupportedType) => {
        setType(selectedType);
    }, []);

    const onValueChanged = useCallback(
        (value: string) => {
            setValues({ type, value });
        },
        [type],
    );

    const emitToastMessage = useToastMessage();

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (isHTMLTag) {
            values[type] = values[type].replace('<', '').replace('>', '');

            if (values[type].includes(' ')) {
                emitToastMessage('An HTML element cannot have a space.', 'warning');
                return;
            }
        }

        onSelected(values[type], type);
    };

    return (
        <div className="padding-normal-sm inline-select-list-wrapper-const-tab overflow-auto">
            <form className="tabs-field-selector-tab-content" onSubmit={onSubmit}>
                <div className="common-color-light-grey margin-bottom-xs"> Manual value: </div>

                {type === FieldType.Boolean && (
                    <RadioGroup direction="row" value={values[FieldType.Boolean]} onChange={onValueChanged}>
                        <Radio value="true" ref={manualFieldRef}>
                            true
                        </Radio>
                        <Radio value="false">false</Radio>
                    </RadioGroup>
                )}

                {type === FieldType.Number && (
                    <input
                        className="form-control"
                        data-automation="formula-popover-tab-const-manual-number-value"
                        value={values[FieldType.Number]}
                        onChange={({ target }) => onValueChanged(target.value)}
                        placeholder="Manual number value"
                        type="number"
                        ref={manualFieldRef}
                    />
                )}

                {type === FieldType.String && (
                    <>
                        <input
                            className="form-control"
                            value={values[FieldType.String]}
                            onChange={({ target }) => onValueChanged(target.value)}
                            placeholder="Manual text value"
                            type="text"
                            ref={manualFieldRef}
                            data-automation="formula-popover-tab-const-manual-text-input"
                        />
                        {isHTMLTag && (
                            <div className="common-color-light-grey margin-bottom-xs">
                                Do not include opening and closing tags ({'</>'}) in the element name
                            </div>
                        )}
                    </>
                )}

                {type === FieldType.Date && (
                    <>
                        <DatepickerInput
                            value={values[FieldType.Date]}
                            onChange={(date) => {
                                if (!date) {
                                    return;
                                }

                                const formattedDate = dayjs(date).format(FORMULA_CONST_DATE_FORMAT);
                                onValueChanged(formattedDate);
                            }}
                            ref={manualFieldRef}
                        />
                    </>
                )}

                <div className="common-color-light-grey margin-bottom-xs margin-top-md">Supported value types:</div>

                <RadioGroup value={type} onChange={onTypeSelected} direction="row">
                    {allowedTypesArray.map((allowedType) => (
                        <Radio key={allowedType} value={allowedType}>
                            {fieldTypeToFieldDisplayName[allowedType]}
                        </Radio>
                    ))}
                </RadioGroup>

                <div className="flex flex-justify-end-no-xs margin-top-xs">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        data-automation="formula-popover-tab-const-save-field"
                        title="Save"
                        disabled={type !== FieldType.String && !values[type]?.length}
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FormulaPopoverTabConst;
