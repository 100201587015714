import { useAngularService } from 'angulareact';

import type { Group, Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useIsEnvironmentActive = (groupId?: TonkeanId<TonkeanType.GROUP>, initiative?: Initiative) => {
    const projectManager = useAngularService('projectManager');
    const group: Group | undefined = groupId && projectManager.groupsMap[groupId];
    return initiative?.isDraftInitiative ? group?.buildEnvironmentEnabled : group?.workerEnabled;
};

export default useIsEnvironmentActive;
