import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function CloudConvertCustomActionsDefinitionCtrl($scope, integrations, integrationsConsts) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        definition: ctrl.definition, // The action definition.
        previewEvaluationSource: ctrl.previewEvaluationSource,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        fileEntityMetadata: integrationsConsts.getEntitiesMetadata()['CLOUDCONVERT']['TASK'],
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    ctrl.$onInit = function () {
        switch ($scope.data.definition.customActionKey) {
            case 'CREATE_MERGE_TASK':
                // For now, Cloud Convert supports only pdf output
                $scope.data.definition.cloudConvertOutputFormat = 'pdf';
                break;
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onDestinationFolderExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.destinationFolderExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDocumentIdExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.documentIdExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (storageIntegrationId, newDocumentName, shouldSaveLogic) {
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.newDocumentName = newDocumentName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTargetPathTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.targetPathExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onCloudConvertTaskIdsExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.cloudConvertTaskIdsExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onCloudConvertFileNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.cloudConvertFileNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onCloudConvertTimeoutExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.cloudConvertTimeoutExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'CloudConvertCustomActionsDefinitionCtrl',
        lateConstructController(CloudConvertCustomActionsDefinitionCtrl),
    );
