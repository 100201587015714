import React, { useCallback } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { TextEllipsis } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    displayText: string;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    parentInitiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionType?: WorkflowVersionType;
    ownerId?: string;
    onInitiativeCreated?: (initiativeCreated: any) => void;
    buttonBackground?: string;
    originatedCustomTriggerId?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
}

const InnerItemsWidgetHeaderImmediateCreateItemAction: React.FC<Props> = ({
    displayText,
    projectId,
    parentInitiativeId,
    groupId,
    workflowVersionType,
    ownerId,
    onInitiativeCreated,
    buttonBackground,
    className,
    originatedCustomTriggerId,
}) => {
    const [, createPartialInitiative] = useLazyTonkeanService('createPartialInitiative');

    const createNewInitiative = useCallback(async () => {
        const craetedInitiative = await createPartialInitiative(
            projectId,
            displayText,
            groupId,
            workflowVersionType === WorkflowVersionType.DRAFT,
            ownerId,
            parentInitiativeId,
            undefined,
            [],
            undefined,
            originatedCustomTriggerId,
        );

        onInitiativeCreated?.(craetedInitiative);
    }, [
        createPartialInitiative,
        originatedCustomTriggerId,
        displayText,
        groupId,
        onInitiativeCreated,
        ownerId,
        parentInitiativeId,
        projectId,
        workflowVersionType,
    ]);

    return (
        <UserThemedClickableButton
            className={className}
            data-automation="immediate-create-item-action-button"
            background={buttonBackground}
            onClick={createNewInitiative}
        >
            <TextEllipsis numberOfLines={1} tooltip>
                {displayText}
            </TextEllipsis>
        </UserThemedClickableButton>
    );
};

export default InnerItemsWidgetHeaderImmediateCreateItemAction;
