import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import FloatingReturnToButton from './components/FloatingReturnToButton/FloatingReturnToButton';
import DefaultTonkeanNavigationParamsContext from './components/Navigation/DefaultTonkeanNavigationParamsContext';
import NavigationMenu from './components/Navigation/NavigationMenu';
import NavigationSearch from './components/NavigationSearch/NavigationSearch';
import TrialStatus from './components/TrialStatus/TrialStatus';
import TrialStatusMTU from './components/TrialStatus/TrialStatusMTU';
import UserMenu from './components/User/UserMenu';
import useIsInEmptyTrial from './hooks/useIsInEmptyTrial';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { Breakpoint, useBreakpoint } from '@tonkean/infrastructure';
import { SCIMTonkeanRole } from '@tonkean/tonkean-entities';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Theme, type Color } from '@tonkean/tui-theme';

const COLOR_BANNER_HEIGHT = 6;
export const NAV_BAR_HEIGHT = 63;
export const TOPBAR_REDESIGN_HEIGHT = NAV_BAR_HEIGHT + COLOR_BANNER_HEIGHT;
export const TOPBAR_HEIGHT = 50;

const TopbarContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // We want it to be above the project-board content which has z-index 1
    z-index: 4;
`;

const Topbar = styled.nav<{ $redesign?: boolean; transparentMode?: boolean }>`
    // We want grid so we can center the company logo in the homepage relative to the page width and not the rest of the container width
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto auto;
    height: ${({ $redesign }) => ($redesign ? NAV_BAR_HEIGHT : TOPBAR_HEIGHT)}px;
    ${({ transparentMode }) =>
        !transparentMode &&
        css`
            border-bottom: 2px solid ${Theme.colors.gray_200};
        `};
    background: ${({ transparentMode }) =>
        transparentMode ? 'linear-gradient(180deg, #f5f5f5, transparent)' : 'white'};

    ${childrenStyledFocus}
`;

const ColorBanner = styled.div<{ $color: Color }>`
    width: 100%;
    height: ${COLOR_BANNER_HEIGHT}px;
    background-color: ${({ $color }) => $color};
`;

const NavigationWrapper = styled.span`
    position: relative;
`;

const NavigationSearchContainer = styled.div`
    z-index: 15;
    padding-right: 30px;
`;

const RightSideTopbar = styled.div`
    display: flex;
    justify-self: flex-end;
    flex-shrink: 0;
`;

const StyledUserMenu = styled(UserMenu)`
    margin-right: 18px;
`;

interface Props {
    transparentMode?: boolean;
    showGradient?: boolean;
    logoUrl: string | undefined;
    primaryColor?: Color;
    redesign?: boolean;
}

const NavigationTopbar: React.FC<Props> = ({ transparentMode, showGradient, logoUrl, primaryColor, redesign }) => {
    const navigationService = useAngularService('navigationService');
    const [isDrilldownMode] = useAngularWatch(() => navigationService.isDrilldownMode);
    const [hideNavigationInHeader, hideSearchInHeader] = useGetStateParams(
        'hideNavigationInHeader',
        'hideSearchInHeader',
    );
    const isInEmptyTrial = useIsInEmptyTrial();
    const projectManager = useAngularService('projectManager');
    const [isInTrial] = useAngularWatch(() => projectManager.isSelectedProjectInTrial() || false);
    const mtuPricing = useFeatureFlag('tonkean_feature_mtu_pricing') as boolean;
    const hasMTUPackage = !!projectManager.project?.mtuLicenseConfiguration && mtuPricing;

    // Make sure the theme configuration gets loaded when the project does (prevents race condition)
    const [projectThemeConfiguration] = useAngularWatch(() => projectManager?.project?.themeConfiguration);
    const themeColor = primaryColor || projectThemeConfiguration?.primaryColor;

    const { id: projectId, urlSlug: projectUrlSlug } = projectManager.project || {};
    const defaultUrlParamsContext = useMemo(() => ({ projectId, projectUrlSlug }), [projectId, projectUrlSlug]);

    const breakpoint = useBreakpoint();

    const [projectToken] = useAngularWatch(() => projectManager?.projectToken);

    const authenticationService = useAngularService('authenticationService');
    const [currentUser] = useAngularWatch(() => authenticationService.currentUser);
    const isSystemUser = Boolean(
        currentUser?.projectContext?.calculatedTonkeanRoles.includes(SCIMTonkeanRole.SYSTEM_USER),
    );

    if (!projectToken) {
        return <></>;
    }

    return (
        <NavigationWrapper>
            <FloatingReturnToButton />

            {!isDrilldownMode && (
                <TopbarContainer>
                    {redesign && <ColorBanner $color={themeColor} />}
                    <Topbar transparentMode={transparentMode} $redesign={redesign}>
                        <DefaultTonkeanNavigationParamsContext.Provider value={defaultUrlParamsContext}>
                            <NavigationMenu
                                showGradient={showGradient}
                                transparentMode={transparentMode}
                                logoUrl={logoUrl || projectManager?.project?.themeConfiguration?.logoUrl}
                                projectUrlSlug={projectUrlSlug}
                                isSystemUser={isSystemUser}
                            />
                        </DefaultTonkeanNavigationParamsContext.Provider>
                        <RightSideTopbar>
                            {!hideNavigationInHeader && !hideSearchInHeader && !isInEmptyTrial && (
                                <NavigationSearchContainer>
                                    {breakpoint !== Breakpoint.MINIATURE_0 && (
                                        <NavigationSearch
                                            projectId={projectManager.project.id}
                                            primaryColor={themeColor}
                                            isSystemUser={isSystemUser}
                                        />
                                    )}
                                </NavigationSearchContainer>
                            )}

                            {isInTrial && (hasMTUPackage ? <TrialStatusMTU /> : <TrialStatus />)}

                            <StyledUserMenu transparentMode={transparentMode} isSystemUser={isSystemUser} />
                        </RightSideTopbar>
                    </Topbar>
                </TopbarContainer>
            )}
        </NavigationWrapper>
    );
};

export default NavigationTopbar;
