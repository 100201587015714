import { useEffect, useMemo } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface UseInitiativeByIdResponse {
    initiative: Initiative | undefined;
    loading: boolean;
}

function useInitiativeById(initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined): UseInitiativeByIdResponse {
    const [{ data: initiativeResponse, loading: isLoadingIniiative }, getInitiativeById] =
        useLazyTonkeanService('getInitiativeById');

    useEffect(() => {
        if (initiativeId) {
            getInitiativeById(initiativeId);
        }
    }, [initiativeId, getInitiativeById]);

    return useMemo(() => {
        return { initiative: initiativeResponse, loading: isLoadingIniiative };
    }, [initiativeResponse, isLoadingIniiative]);
}

export default useInitiativeById;
