import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';

import { CollaborationActionsContext } from '../../collaboration-actions/utils/CollaborationActionsContext';
import { TaggableEntitiesInput } from '../../taggableEntities/components';
import { SendCommentSelected, SendComment } from '../icons';

import { useProject, getBorderColor, useFocusTrap } from '@tonkean/infrastructure';
import type { Person, TonkeanId, TonkeanType, NewActivityData, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { themeConfiguration, Theme, FontSize } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const MentionInputContainer = styled.form<{ $isHighlighted: boolean; $isInThread: boolean; $isInputActive: boolean }>`
    align-items: center;
    margin: 0 10px;
    padding: 1px;
    border-radius: 6px;
    border: ${({ $isHighlighted }) => `1px solid ${getBorderColor(false, $isHighlighted, false)}`};
    display: grid;
    grid-template-columns: minmax(0, 1fr) 25px;

    ${({ $isInputActive }) =>
        $isInputActive &&
        css`
            position: absolute;
            width: calc(100% - 20px);
        `};

    ${({ $isInThread, $isInputActive }) =>
        $isInThread &&
        $isInputActive &&
        css`
            width: calc(100% - 103px);
            bottom: initial;
        `};
`;
const StyledIconWrapper = styled.button`
    padding: 5px;
    background: 0;
    border: 0;
    width: 25px;
    height: 25px;
    cursor: pointer;
`;

const TextWrapper = styled.div<{ $isInThread: boolean; $isInputActive: boolean }>`
    grid-area: textwrapper;
    display: grid;
    grid-template-areas: 'warning limit';
    align-items: center;
    justify-content: right;

    ${({ $isInThread }) =>
        $isInThread &&
        css`
            justify-content: flex-end;
            align-items: flex-end;
        `};

    ${({ $isInputActive }) =>
        $isInputActive &&
        css`
            margin-top: 35px;
        `};
`;

const TextLimit = styled.span`
    position: relative;
    right: ${themeConfiguration.sizes.input[InputSize.MEDIUM].paddingRightLeft + 2}px;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_400};
    line-height: 14px;
    grid-area: limit;
    margin-top: 5px;
`;

const StyledWarning = styled.span`
    margin-right: 35px;
    margin-left: 12px;
    color: ${Theme.colors.warning};
    font-size: ${FontSize.SMALL_12};
`;

interface AddCommentInputProps {
    className?: string;
    targetId: TonkeanId<TonkeanType.ACTIVITY | TonkeanType.INITIATIVE>;
    defaultCommentText?: string;
    onSubmit: (newActivity: NewActivityData) => void;
    showOnReply?: (boolean) => void;
    canUserEditItem: boolean;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>;
    workflowVersionType: WorkflowVersionType;
    isInThread?: boolean;
    maxCommentLength?: number;
}

const MAX_COMMENT_LENGTH = 1000;

const AddCommentInput: React.FC<AddCommentInputProps> = ({
    className,
    onSubmit,
    showOnReply,
    targetId,
    defaultCommentText = '',
    canUserEditItem,
    initiativeId,
    workflowVersionType,
    isInThread = false,
    maxCommentLength = MAX_COMMENT_LENGTH,
}) => {
    const project = useProject();
    const [initialDefaultCommentText, setInitialDefaultCommentText] = useState(defaultCommentText);
    const [comment, setComment] = useState(defaultCommentText);
    const [mentions, setMentions] = useState<Person[]>([]);
    const [isSendIconHighlighted, setIsSendIconHighlighted] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const { isCommentsOpen } = useContext(CollaborationActionsContext);
    const [isInputFocused, setIsInputFocused] = useState<boolean>(!!isCommentsOpen);
    const focusTrap = useFocusTrap(isInputFocused, inputRef, false);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.length <= maxCommentLength) {
                setComment(event.target.value);
            }
        },
        [maxCommentLength],
    );

    const isValidCommentLength = comment.length <= maxCommentLength;

    useEffect(() => {
        if (defaultCommentText && defaultCommentText !== initialDefaultCommentText) {
            setComment(defaultCommentText);
            setInitialDefaultCommentText(defaultCommentText);
        }
    }, [defaultCommentText, initialDefaultCommentText]);

    useEffect(() => {
        setIsSendIconHighlighted(!!comment && comment.trim() !== '' && isValidCommentLength);
    }, [comment, isValidCommentLength]);

    useEffect(() => {
        if (isCommentsOpen && inputRef) {
            focusTrap(inputRef?.current);
        }

        const onBlurAction = () => {
            setIsInputFocused(false);
        };
        const onFocusAction = () => {
            setIsInputFocused(true);
        };

        const currentRef: HTMLInputElement | null = inputRef?.current;
        if (currentRef) {
            currentRef?.addEventListener('focus', onFocusAction);
            currentRef?.addEventListener('blur', onBlurAction);

            return () => {
                currentRef?.removeEventListener('focus', onFocusAction);
                currentRef?.removeEventListener('blur', onBlurAction);
            };
        }
    }, [isCommentsOpen, focusTrap]);

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            if (!targetId) {
                return;
            }

            event.preventDefault();

            if (comment === '' || !comment) {
                return;
            }
            setIsSendIconHighlighted(true);

            onSubmit({
                targetEntityId: targetId,
                text: comment,
                updateInitiativeId: initiativeId,
                atMentionIds: mentions.map((mention) => mention.id),
            });

            setComment('');
            showOnReply?.(false);
        },
        [comment, initiativeId, mentions, onSubmit, showOnReply, targetId],
    );

    // need to manage if the input is active or not
    // when the input is active we make is position absolute to enable the taggable options to be on top of the comments
    // if the input is not active we make it relative to be in the normal flow
    // we need to update the component position to not absolute if it is in a thread
    // to avoid the component moving while user is scroling
    const [isInputActive, setIsInputActive] = useState<boolean>(false);
    const handleFocus = useCallback(() => {
        setIsInputActive(true);
    }, [setIsInputActive]);

    const handleOutOfFocus = useCallback(() => {
        if (isInThread) {
            setIsInputActive(false);
        }
    }, [setIsInputActive, isInThread]);

    return (
        <>
            <MentionInputContainer
                onSubmit={handleSubmit}
                className={className}
                $isHighlighted={isInputFocused}
                $isInThread={isInThread}
                $isInputActive={isInputActive}
                onFocus={handleFocus}
                onBlur={handleOutOfFocus}
                onMouseLeave={handleOutOfFocus}
            >
                <TaggableEntitiesInput
                    size={InputSize.MEDIUM_LARGE}
                    value={comment}
                    onChange={handleChange}
                    onCommentChange={setComment}
                    onMentionsChange={setMentions}
                    placeholder="Write a comment"
                    disabled={!canUserEditItem}
                    projectId={project.id}
                    dataAutomation="add-comment-form-mention-input"
                    fields={[]}
                    initiativeId={initiativeId}
                    workflowVersionType={workflowVersionType}
                    ref={inputRef}
                />

                <StyledIconWrapper
                    type="submit"
                    data-automation="add-comment-form-post-button"
                    disabled={!isSendIconHighlighted}
                >
                    {isSendIconHighlighted ? <SendCommentSelected /> : <SendComment />}
                </StyledIconWrapper>
            </MentionInputContainer>

            <TextWrapper $isInThread={isInThread} $isInputActive={isInputActive}>
                {!isValidCommentLength && (
                    <StyledWarning data-automation="comment-length-warning">
                        The comment cannot exceed {maxCommentLength} characters
                    </StyledWarning>
                )}
                <TextLimit data-automation="add-comment-form-text-limit-indication">{`${comment.length}/${maxCommentLength}`}</TextLimit>
            </TextWrapper>
        </>
    );
};

export default AddCommentInput;
