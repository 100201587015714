import { useField } from 'formik';
import { nanoid } from 'nanoid';
import * as React from 'react';
import styled from 'styled-components';

import { ExtendableRow, Field, Input, Toggle } from '@tonkean/infrastructure';
import { Button, ButtonShape } from '@tonkean/tui-buttons/Button';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const StyledButton = styled(Button)`
    margin-right: 6px;
`;

const TokenContainer = styled.div`
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
`;

const Header = styled.div`
    font-size: ${FontSize.SMALL_12};
    font-weight: 500;
    color: ${Theme.colors.gray_800};
`;

const TokenKeyWrapper = styled.div`
    margin-bottom: 20px;
`;
const HeaderWrapper = styled.div`
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 12px;
`;

const Wrapper = styled.div`
    padding: 20px;
`;

interface Props {}

const TokenRestrictionExtendableRow: React.FC<Props> = () => {
    const { setValue } = useField<string>('accessControl.tokenRestriction.token')[2];

    const generateRandomToken = () => {
        setValue(nanoid(24));
    };

    return (
        <ExtendableRow
            header={
                <HeaderWrapper>
                    <Header>Token Based control method</Header>
                    <Toggle
                        onClick={(e) => e.stopPropagation()}
                        size={InputSize.MEDIUM}
                        name="accessControl.tokenRestriction.isEnabled"
                        showInnerLabel
                    />
                </HeaderWrapper>
            }
            isOpenByDefault
        >
            <Wrapper>
                <TokenKeyWrapper>
                    <Field label="Token Key">
                        <TokenContainer>
                            <StyledButton
                                onClick={generateRandomToken}
                                shape={ButtonShape.RECTANGULAR}
                                size={ButtonSize.MEDIUM}
                            >
                                Generate
                            </StyledButton>

                            <Input
                                name="accessControl.tokenRestriction.token"
                                placeholder="Insert or Generate a token key"
                            />
                        </TokenContainer>
                    </Field>
                </TokenKeyWrapper>

                <Field label="Header Key">
                    <TokenContainer>
                        <Input
                            name="accessControl.tokenRestriction.headerName"
                            placeholder="Enter the name of the header field that holds the Secret Key"
                        />
                    </TokenContainer>
                </Field>
            </Wrapper>
        </ExtendableRow>
    );
};

export default TokenRestrictionExtendableRow;
