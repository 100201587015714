<div class="tnk-docusign-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'ADD_COMMENT_TO_WORKFLOW'" class="margin-bottom-xlg">

        <div class="margin-top">
            <div class="margin-bottom-xs">Creator Email</div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Email"
                    saved-expression="data.definition.emailExpression"
                    on-tonkean-expression-changed="onEmailTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">Comment</div>

            <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.customTriggerId }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Comment"
                    saved-expression="data.definition.commentExpression"
                    on-tonkean-expression-changed="onCommentTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
