import React, { useContext } from 'react';
import styled from 'styled-components';

import { ItemWidgetContext } from '../../../entities';

import { Placeholder } from '@tonkean/infrastructure';
import { getWidgetDisplayColumns } from '@tonkean/tonkean-entities';

interface Props {
    height: number | undefined;
}

const EmbedWidgetPlaceholder = styled(Placeholder)<{ displayColumns: number }>`
    height: 100%;
    width: 100%;
    z-index: 0;
    overflow: hidden;
    grid-column: ${({ displayColumns }) => `span ${displayColumns}`};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const EmbedWidgetLoadingSkeleton: React.FC<Props> = ({ height }) => {
    const widgetContext = useContext(ItemWidgetContext);

    return <EmbedWidgetPlaceholder $width="100%" displayColumns={getWidgetDisplayColumns(widgetContext?.widget)} />;
};

export default EmbedWidgetLoadingSkeleton;
