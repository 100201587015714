import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function TonkeanActionLogicConfigurationCtrl(
    $scope,
    utils,
    projectManager,
    workflowFolderManager,
    customTriggerManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        workflowVersionType: ctrl.workflowVersionType,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        actions: [
            {
                type: 'CREATE_TRACK',
                title: 'Add Item to Module',
            },
            {
                type: 'ARCHIVE_TRACK',
                title: 'Archive Item',
            },
            {
                type: 'ADD_COMMENT',
                title: 'Add Comment To Item',
            },
        ],

        selectedAction: null,
        innerActionDefinition: {},
        groupIdsInSolution: [],
    };

    /**
     * Initialization function of the controller.
     */
    ctrl.$onInit = function () {
        if (
            $scope.data.configuredLogic &&
            $scope.data.configuredLogic.node?.customTriggerActions === undefined &&
            !projectManager.project.features.tonkean_allow_inner_items_old_flow
        ) {
            // Whenever we initialize a Tonkean action within an inner item flow and tonkean_allow_inner_items_old_flow is off the action should be of type create track in another module
            const workingOn = customTriggerManager.getWorkflowVersionFirstMonitorParentData(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node,
            )?.monitorActionDefinition?.workerItemContextType;

            if (workingOn === 'TRIGGERED_ACTION_ITEM') {
                $scope.selectAction({
                    type: 'CREATE_TRACK',
                    title: 'Add Item to Module',
                });
            }
        }
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            $scope.data.selectedAction = null;
            $scope.data.innerActionDefinition = {};

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    $scope.selectAction = function (action) {
        if (
            (action.type === 'ARCHIVE_TRACK' && $scope.data.configuredLogic.impacts.length > 0) ||
            ($scope.data.groupIdsInSolution.length === 1 && action.type === 'CREATE_TRACK')
        )
            return;

        $scope.data.selectedAction = action;

        if (action.changeTypeOnSelect) {
            ctrl.onUpdateLogicType({ type: action.type });
            return;
        }

        $scope.onActionsChanged(true);

        const canSkipCustomTriggerWhenConditionWasNotMet = action.type != 'ARCHIVE_TRACK';

        ctrl.onSelectedLogicTypeConfigurationChanged({ canSkipCustomTriggerWhenConditionWasNotMet });
    };

    $scope.onInnerActionDefinitionChanged = function (shouldSave) {
        $scope.onActionsChanged(shouldSave);
    };

    /**
     * Initializes the edit mode of the component.
     */
    function initializeEditMode() {
        const workflowFolder = workflowFolderManager.getContainingWorkflowFolder(
            $scope.pm.project.id,
            $scope.data.groupId,
        );

        $scope.data.groupIdsInSolution = workflowFolder.groupIds;

        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'TONKEAN_ACTION',
        );

        if (customTriggerAction) {
            $scope.data.selectedAction = utils.findFirst(
                $scope.data.actions,
                (action) => action.type === customTriggerAction.customTriggerActionDefinition.actionType,
            );

            $scope.data.innerActionDefinition = customTriggerAction.customTriggerActionDefinition.actionDefinition;
        }

        const canSkipCustomTriggerWhenConditionWasNotMet =
            !customTriggerAction || customTriggerAction.customTriggerActionDefinition.actionType != 'ARCHIVE_TRACK';

        ctrl.onSelectedLogicTypeConfigurationChanged({ canSkipCustomTriggerWhenConditionWasNotMet });
    }

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSave) {
        if (ctrl.onActionsChanged) {
            const definition = {
                actionType: $scope.data.selectedAction ? $scope.data.selectedAction.type : null,
                actionDefinition: $scope.data.innerActionDefinition || {},
            };

            const actionDefinition = {
                actions: [
                    {
                        type: 'TONKEAN_ACTION',
                        displayName: $scope.data.selectedAction ? $scope.data.selectedAction.displayName : null,
                        definition,
                    },
                ],
            };

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic: shouldSave });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('TonkeanActionLogicConfigurationCtrl', lateConstructController(TonkeanActionLogicConfigurationCtrl));
