<div class="custom-notification-settings">
    <!-- Person selection title -->
    <div class="common-title-xxs margin-bottom">
        {{ data.overrideTitle ? data.overrideTitle : 'Who should Tonkean module Notify?' }}
    </div>

    <!-- Selecting notification method -->
    <tnk-channel-or-person-selector
        group-id="data.groupId"
        workflow-version-id="{{ data.workflowVersionId }}"
        logic-id="data.configuredLogic.node.id"
        saved-channel-or-person-selection="data.existingCustomNotificationSettings"
        validation-object="data.validationObject"
        configured-logic="data.configuredLogic"
        on-channel-or-person-selection-changed="
            onChannelOrPersonSelectionChanged(channelOrPersonSelectionConfiguration, shouldSaveLogic)
        "
        hide-fail-if-no-value="true"
        show-send-to="true"
        show-reply-in-resume-thread="data.hasParentMonitoringThread"
        unique-identifier="customNotificationWhoToAsk"
        on-communication-project-integration-changed="
            onCommunicationIntegrationChanged(selectedCommunicationProjectIntegration)
        "
        communication-project-integration-selector="true"
    ></tnk-channel-or-person-selector>

    <!-- separator -->
    <hr />

    <!-- Title -->
    <div class="margin-bottom-xs common-title-xxs">
        Message text:
        <a
            class="inline-block common-italic margin-left"
            uib-tooltip="You can use markdown format to add styling to the message"
            target="_blank"
            ng-href="{{::$root.knowledgeBase.pages.formulasMarkdown}}"
        >
            <i class="fa fa-info-circle"></i>
        </a>
    </div>

    <!-- Input -->
    <div class="margin-bottom">
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            saved-original-expression="data.botMessageText"
            saved-evaluated-expression="data.evaluatedBotMessageText"
            expression-unique-identifier="customNotificationInput"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>
    </div>

    <!-- Separator -->
    <hr />

    <!-- Title -->
    <div class="common-title-xxs margin-bottom">Message settings</div>

    <!-- Hide initiative page link-->
    <label class="common-normal-weight">
        <input
            type="checkbox"
            ng-model="data.addInitiativePageLink"
            ng-change="onCustomNotificationSettingsChanged(true)"
        />
        <span>Add a link to the item in the Business Report</span>
    </label>

    <!-- Separator -->
    <hr />

    <!-- Fields selection title -->
    <div>
        <div class="common-title-xxs">Add data of fields to the message</div>
        <span class="common-size-xxs common-color-grey3">Enrich your message with context</span>
    </div>

    <!-- Separator -->
    <hr class="margin-none" />

    <!-- Fields -->
    <tnk-multi-field-quick-selection
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        include-global-fields="true"
        on-fields-change="onNotificationAdditionalFieldsChanges(fields)"
        existing-fields="data.notificationAdditionalFields"
        special-fields-for-features="['ADDED_FIELDS_TO_BOT_MESSAGE']"
    ></tnk-multi-field-quick-selection>

    <!-- Separator -->
    <hr />

    <!-- Preview -->
    <div class="margin-top-lg">
        <!-- Title -->
        <div class="margin-bottom common-bold">Preview message:</div>

        <!-- Preview -->
        <tnk-preview-bot-bubble-ctrl
            text="data.botMessageText"
            text-evaluated-expression="data.evaluatedBotMessageText"
            post-text="data.addInitiativePageLink && tonkeanUtils.getItemDisplayNameForBotPreview(pm, data.groupId, wvm, scm)"
            post-text-evaluated-expression="data.evaluatedPostText"
            preview-type="text"
            placeholder="Needs attention"
            is-email="!cis.anyIntegrationsConnected()"
            user="as.currentUser"
            bot-fields="data.notificationAdditionalFields"
            no-buttons="true"
            logic-id="data.configuredLogic.node.id"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            no-triangle="true"
        ></tnk-preview-bot-bubble-ctrl>
    </div>
</div>
