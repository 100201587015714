import template from './tnkBotOffBanner.template.html?angularjs';
import lateConstructController from '../../utils/lateConstructController';

angular.module('tonkean.app').component('tnkBotOffBanner', {
    template,
    controller: 'BotOffBanner',
});

/* @ngInject */
function BotOffBanner($scope, $rootScope, $state, currentUserHelper) {
    $scope.data = {
        loadingTurningBotMessagesOn: false,
        errorLoadingTurningBotMessagesOn: false,
    };

    $scope.init = function () {
        // If the bot is not disabled for this user then we should not show this component.
        const botIsDisabled = currentUserHelper.checkUserMessagePreferenceExists('NO_GATHER_UPDATE');
        if (!botIsDisabled) {
            $scope.close();
        }
    };

    $scope.turnBotMessagesOn = function () {
        $scope.data.loadingTurningBotMessagesOn = true;
        $scope.data.errorLoadingTurningBotMessagesOn = false;

        currentUserHelper
            .removeNoGatherUpdateOptionFromCurrentUser()
            .then(() => {
                $scope.close();
            })
            .catch(() => {
                $scope.data.errorLoadingTurningBotMessagesOn = true;
            })
            .finally(() => {
                $scope.data.loadingTurningBotMessagesOn = false;
            });
    };

    $scope.close = function () {
        $rootScope.showFixedFooter = false;
    };

    $scope.init();
}

angular.module('tonkean.app').controller('BotOffBanner', lateConstructController(BotOffBanner));
