<div class="bot-button-pressed-configuration-logic">
    <tnk-bot-post-message-action-configuration
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        configured-logic="data.configuredLogic"
        custom-trigger-action-type="BOT_BUTTON_PRESSED"
        preview-evaluation-source="data.previewEvaluationSource"
        invalid-logics="data.invalidLogics"
        open-field-modal="data.openFieldModal"
        on-actions-changed="onActionsChanged(definition, shouldSaveLogic)"
    ></tnk-bot-post-message-action-configuration>
</div>
