import fetchRetry from 'fetch-retry';
import { StatusCodes } from 'http-status-codes';

import { NetworkServiceError } from './NetworkServiceError';

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface RequestParams<BodyType, ResponseType> {
    url: string;
    method: Method;
    body?: BodyType;
    headers?: Record<string, string>;
    retryOn?: number[];
    defaultResponseForNotModifiedStatus?: ResponseType;
}

export default class NetworkService {
    private baseUrl: string;
    private authorizationToken: string | undefined;
    private projectToken: string | undefined;

    constructor({
        baseUrl,
        authorizationToken,
        projectToken,
    }: {
        baseUrl: string;
        authorizationToken?: string;
        projectToken?: string;
    }) {
        this.baseUrl = baseUrl;
        this.authorizationToken = authorizationToken;
        this.projectToken = projectToken;
    }

    setAuthorizationToken(token: string) {
        this.authorizationToken = token;
    }

    setProjectToken(token: string) {
        this.projectToken = token;
    }

    async sendRequest<ResponseType, BodyType = undefined>({
        url,
        method,
        body: bodyParam,
        headers,
        retryOn,
        defaultResponseForNotModifiedStatus,
    }: RequestParams<BodyType, ResponseType>): Promise<ResponseType> {
        const fullUrl = this.baseUrl + url;
        const body: BodyInit = bodyParam instanceof FormData ? bodyParam : JSON.stringify(bodyParam);
        const contentType: Record<string, string> =
            bodyParam instanceof FormData ? {} : { 'Content-Type': 'application/json' };

        const response = await fetchRetry(fetch)(fullUrl, {
            method,
            body,
            headers: {
                ...contentType,
                ...headers,
                Authorization: this.authorizationToken ? `token ${this.authorizationToken}` : '',
                'Authorization-Project': this.projectToken ? `Bearer ${this.projectToken}` : '',
            },
            retryOn: [...(retryOn ?? []), StatusCodes.SERVICE_UNAVAILABLE],
        });

        if (response.ok) {
            const responseText = await response.text();
            return responseText ? JSON.parse(responseText) : {};
        } else if (response.status === StatusCodes.NOT_MODIFIED) {
            if (defaultResponseForNotModifiedStatus) {
                return defaultResponseForNotModifiedStatus;
            } else {
                throw new NetworkServiceError(
                    `Unexpected NOT_MODIFIED response for ${fullUrl}. Please set defaultResponseForNotModifiedStatus for requests expected to response with NOT_MODIFIED status code`,
                    response.status,
                );
            }
        } else {
            throw new NetworkServiceError(await response.text(), response.status);
        }
    }

    async getRequest<ResponseType>(
        params: Omit<RequestParams<undefined, ResponseType>, 'method'>,
    ): Promise<ResponseType> {
        return await this.sendRequest<ResponseType>({
            ...params,
            method: 'GET',
        });
    }

    async postRequest<ResponseType, BodyType = undefined>(
        params: Omit<RequestParams<BodyType, ResponseType>, 'method'>,
    ): Promise<ResponseType> {
        return await this.sendRequest<ResponseType, BodyType>({
            ...params,
            method: 'POST',
        });
    }

    async putRequest<ResponseType, BodyType = undefined>(
        params: Omit<RequestParams<BodyType, ResponseType>, 'method'>,
    ): Promise<ResponseType> {
        return await this.sendRequest<ResponseType, BodyType>({
            ...params,
            method: 'PUT',
        });
    }

    async deleteRequest<ResponseType, BodyType = undefined>(
        params: Omit<RequestParams<BodyType, ResponseType>, 'method'>,
    ): Promise<ResponseType> {
        return await this.sendRequest<ResponseType, BodyType>({
            ...params,
            method: 'DELETE',
        });
    }
}
