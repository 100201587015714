import React, { useCallback, useMemo, useState } from 'react';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { SimpleSelect } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
    workflowFolderCategoryId?: TonkeanId<TonkeanType.WORKFLOW_FOLDER_CATEGORY>;
    groupId?: TonkeanId<TonkeanType.GROUP>;

    onGroupSelected: (groupId: TonkeanId<TonkeanType.GROUP>) => void;
}

const GroupSimpleSelector: React.FC<Props> = ({
    projectId,
    workflowFolderId,
    workflowFolderCategoryId,
    groupId,
    onGroupSelected,
}) => {
    const [selectedGroupId, setSelectedGroupId] = useState<TonkeanId<TonkeanType.GROUP> | undefined>(groupId);

    const { data, loading, error } = useTonkeanService('getGroupsBySolution', projectId, workflowFolderId);
    const groups = data?.entities;

    const options: SimpleSelectSingleOption<TonkeanId<TonkeanType.GROUP>>[] = useMemo(() => {
        if (!groups) {
            return [];
        }

        return (
            groups
                .filter((group) =>
                    workflowFolderCategoryId ? group.workflowFolderCategoryId === workflowFolderCategoryId : true,
                )
                .map((group) => ({
                    value: group.id,
                    label: group.name,
                })) || []
        );
    }, [groups, workflowFolderCategoryId]);

    const onChange = useCallback(
        (selectedGroupId: TonkeanId<TonkeanType.GROUP>) => {
            onGroupSelected(selectedGroupId);
            setSelectedGroupId(selectedGroupId);
        },
        [onGroupSelected],
    );

    return (
        <SimpleSelect
            value={selectedGroupId}
            options={options}
            onChange={onChange}
            isLoading={loading}
            isError={!!error}
            placeholder="Select Module"
        />
    );
};

export default GroupSimpleSelector;
