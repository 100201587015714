import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function FormFieldSelectActionsMenuCtrl($scope, $timeout) {
    const ctrl = this;

    $scope.data = {
        enableInline: ctrl.enableInline,
        enableDelete: ctrl.enableDelete,
        removeFormField: ctrl.removeFormField,
        toggleIsRequired: ctrl.toggleIsRequired,
        toggleFieldInline: ctrl.toggleFieldInline,
        toggleShowTitle: ctrl.toggleShowTitle,
        toggleViewOnly: ctrl.toggleViewOnly,
        toggleCollapseDescriptionByDefault: ctrl.toggleCollapseDescriptionByDefault,
        field: ctrl.field,
        isInline: ctrl.isInline,
        formQuestionType: ctrl.formQuestionType,
        formType: ctrl.formType,
        workflowVersionId: ctrl.workflowVersionId,
        groupId: ctrl.groupId,

        showValidationConfigurationModal: false,
    };

    ctrl.$onChanges = function (changedObj) {
        if (changedObj.field) {
            $scope.data.field = changedObj.field.currentValue;
        }
    };

    $scope.toggleShowTitle = function (field) {
        $scope.data.toggleShowTitle({ field });
    };

    $scope.toggleViewOnly = function (field) {
        if (
            !field.fieldDefinition.updateable ||
            !field.fieldDefinition.canUpdateFromUI ||
            field.fieldDefinition.idRelationField
        ) {
            return;
        }

        $scope.data.toggleViewOnly({ field });
    };

    $scope.toggleCollapseDescriptionByDefault = function (field) {
        $scope.data.toggleCollapseDescriptionByDefault({ field });
    };

    $scope.closeValidationConfigurationModal = () => {
        $timeout(() => {
            $scope.data.showValidationConfigurationModal = false;
        });
    };

    /**
     * @param fieldValidation {FormFieldValidation[]}
     */
    $scope.onValidationChanged = (fieldValidation) => {
        ctrl.onValidationChanged({ fieldValidation });
    };
}

export default angular
    .module('tonkean.app')
    .controller('FormFieldSelectActionsMenuCtrl', lateConstructController(FormFieldSelectActionsMenuCtrl));
