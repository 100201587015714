import React from 'react';
import styled from 'styled-components';

import EnterpriseComponentIcon from '../../../modules/EnterpriseComponentsModule/components/EnterpriseComponentIcon';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@tonkean/infrastructure';
import { EnterpriseComponentVariables } from '@tonkean/infrastructure';
import { useAuthentication } from '@tonkean/integrations';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const CancelButton = styled(Button)`
    margin-right: 5px;
`;

interface Props {
    /**
     * Whether the modal is open.
     */
    isOpen: boolean;

    /**
     * The project integration we create the variable values for.
     */
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;

    /**
     * What callback to call when close action is executed.
     */
    onClose(): void;
}

const EnterpriseComponentVariablesValuesModal: React.FC<Props> = ({ isOpen, projectIntegrationId, onClose }) => {
    const { data: projectIntegration } = useTonkeanService('getProjectIntegrationById', projectIntegrationId);
    const onSaveVariableRef = React.createRef<() => Promise<boolean>>();
    const authenticate = useAuthentication();

    // On Click save button
    const onSaveInner = async () => {
        // Saving variables.
        const isSuccessful = await onSaveVariableRef?.current?.();

        if (isSuccessful) {
            await authenticate(projectIntegration);
            onClose();
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} fixedWidth>
            <ModalHeader>
                <HeaderWrapper>
                    {projectIntegration && (
                        <EnterpriseComponentIcon
                            integrationType={projectIntegration.integrationType}
                            iconUrl={projectIntegration.iconUrl}
                            isValid={projectIntegration.valid}
                        />
                    )}
                    Set Up Integration
                </HeaderWrapper>
            </ModalHeader>
            <ModalBody>
                <EnterpriseComponentVariables
                    enterpriseComponentId={projectIntegrationId}
                    relatedEntityId={projectIntegrationId}
                    onSaveRef={onSaveVariableRef}
                    hideHeader
                />
            </ModalBody>

            <ModalFooter align="right" border>
                <CancelButton onClick={() => onClose()} cancel>
                    Cancel
                </CancelButton>
                <Button onClick={onSaveInner}>Save And Authenticate</Button>
            </ModalFooter>
        </Modal>
    );
};

export default EnterpriseComponentVariablesValuesModal;
