import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

import { InformationTooltip } from '@tonkean/infrastructure';
import { PersonAvatarDisplay } from '@tonkean/infrastructure';
import { EyeIcon } from '@tonkean/svg';
import type { PersonAvatar } from '@tonkean/tonkean-entities';
import { InformationIconSize } from '@tonkean/tui-theme/sizes';

const ViewersHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

const HeaderText = styled.div`
    color: #838a92;
    font-size: 12px;
    font-weight: 700;
    margin-right: 8px;
    line-height: 20px;
`;

const EyeIconSized = styled(EyeIcon)`
    height: 20px;
    width: 20px;
    margin-right: 4px;
`;

const PersonColumn = styled.div`
    margin-right: 8px;
    display: flex;
    align-items: center;
`;

const ViewerPopover = styled.div`
    min-width: 273px;
    max-height: 417px;
`;

const ViewingNow = styled.div<{ $themeColor?: string }>`
    color: ${(props) => (props.$themeColor ? props.$themeColor : '#00371f')};
    font-size: 12px;
    font-weight: 400;
`;

const TableText = styled.div`
    color: #34393e;
    font-size: 14px;
    font-weight: 500;
`;

const AvatarWrapper = styled.div`
    margin-right: 4px;
`;

const TimeAgoText = styled.div`
    color: #838a92;
    font-size: 12px;
    font-weight: 400;
`;

const RoleText = styled.div`
    color: #475467;
    font-size: 10px;
`;

const ViewerRow = styled.div<{ isLast?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.isLast ? '0' : '16px')};
`;

interface Props {
    viewers: PersonAvatar[];
    themeColor: string;
}

const InterfaceViewersPopover: React.FC<Props> = ({ viewers, themeColor }) => {
    return (
        <ViewerPopover data-automation="interface-viewer-popover">
            <ViewersHeader>
                <EyeIconSized />
                <HeaderText>LAST VIEWED BY</HeaderText>
                <InformationTooltip iconSize={InformationIconSize.MEDIUM}>
                    <div>Shows all viewers of the request across the different views over the last six months</div>
                </InformationTooltip>
            </ViewersHeader>
            {viewers.map((viewer, index) => {
                return (
                    <ViewerRow
                        key={viewer.personId}
                        isLast={index === viewers.length - 1}
                        data-automation="interface-viewer-row"
                    >
                        <PersonColumn>
                            <AvatarWrapper>
                                <PersonAvatarDisplay
                                    person={viewer}
                                    showBorder={viewer.isViewingNow}
                                    themeColor={themeColor}
                                    disableTooltip
                                />
                            </AvatarWrapper>
                            <div>
                                <TableText>{viewer.name}</TableText>
                                {viewer.title && <RoleText>{viewer.title}</RoleText>}
                            </div>
                        </PersonColumn>
                        {viewer.isViewingNow ? (
                            <ViewingNow $themeColor={themeColor}>Viewing now</ViewingNow>
                        ) : (
                            <TimeAgoText> {dayjs(viewer.lastView).fromNow()}</TimeAgoText>
                        )}
                    </ViewerRow>
                );
            })}
        </ViewerPopover>
    );
};

export default InterfaceViewersPopover;
