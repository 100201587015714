import { getFieldConditionToApiDefinitionMap } from '@tonkean/constants';
import type { FieldQuery } from '@tonkean/tonkean-entities';
import Utils from '@tonkean/utils';

/**
 * Recursively returns true if there are any non empty conditions in given query. False otherwise.
 */
export function anyNonEmptyConditionsInQuery(query: FieldQuery | undefined) {
    //  If no query at all or no filters and no inner queries.
    if (!query || (!query.filters?.length && !query.queries?.length)) {
        return false;
    }

    // If we have filters, check if any of them are non empty
    const fieldConditionToApiDefinitionMap = getFieldConditionToApiDefinitionMap();
    if (query.filters?.length) {
        for (let i = 0; i < query.filters.length; i++) {
            const currentFilter = query.filters[i];
            if (
                currentFilter &&
                (!Utils.isNullOrEmpty(currentFilter?.value) ||
                    !Utils.isNullOrEmpty(currentFilter.expression?.evaluatedExpression) ||
                    fieldConditionToApiDefinitionMap[currentFilter.condition].noValueRequired)
            ) {
                return true;
            }
        }
    }

    // If we have inner queries, check if any of them are non empty
    if (query.queries?.length) {
        for (let i = 0; i < query.queries.length; i++) {
            if (anyNonEmptyConditionsInQuery(query.queries[i])) {
                return true;
            }
        }
    }

    return false;
}
