import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function AdobesignCustomActionsDefinitionCtrl($scope, integrations) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
        possibleParticipantsRoles: [
            'SIGNER',
            'APPROVER',
            'ACCEPTOR',
            'CERTIFIED_RECIPIENT',
            'FORM_FILLER',
            'DELEGATE_TO_SIGNER',
            'DELEGATE_TO_APPROVER',
            'DELEGATE_TO_ACCEPTOR',
            'DELEGATE_TO_CERTIFIED_RECIPIENT',
            'DELEGATE_TO_FORM_FILLER',
            'SHARE',
            'NOTARY_SIGNER',
            'ELECTRONIC_SEALER',
        ],
        possibleAgreementStates: ['AUTHORING', 'DRAFT', 'IN_PROCESS'],
        possibleSignatureTypes: ['ESIGN', 'WRITTEN'],
        possibleBooleanValues: ['false', 'true'],
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'CREATE_AGREEMENT':
                if (!$scope.data.definition.participantGroups) {
                    $scope.data.definition.participantGroups = [];
                    $scope.addParticipantGroup();
                }
                if (!$scope.data.definition.signatureType) {
                    $scope.data.definition.signatureType = $scope.data.possibleSignatureTypes[0];
                }
                if (!$scope.data.definition.agreementState) {
                    $scope.data.definition.agreementState = $scope.data.possibleAgreementStates[0];
                }
            case 'TRANSFER_AGREEMENT_DOCUMENT':
                if (!$scope.data.definition.attachSupportingDocuments) {
                    $scope.data.definition.attachSupportingDocuments = $scope.data.possibleBooleanValues[1];
                }
                if (!$scope.data.definition.attachAuditReport) {
                    $scope.data.definition.attachAuditReport = $scope.data.possibleBooleanValues[0];
                }
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    /**
     * File Source Selection
     */

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (storageIntegrationId, newDocumentName, shouldSaveLogic) {
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.newDocumentName = newDocumentName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Upload Transient Document
     */

    $scope.onNewDocumentNameTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.newDocumentNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * File Target Selection
     */

    $scope.onTargetPathTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.targetPathExpression = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Transfer Agreement Document
     */

    $scope.onTargetPathTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.targetPathExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onVersionTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.versionIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Create Agreement
     */

    $scope.onAgreementNameTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.agreementNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTemplateIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.templateIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTransientDocumentIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.transientDocumentIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onAgreementExternalIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.agreementExternalIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Agreement Participants
     */
    $scope.addParticipantGroup = function () {
        $scope.data.definition.participantGroups.push({
            role: $scope.data.possibleParticipantsRoles[0],
            participantObjects: [{}],
        });
    };

    $scope.addParticipantObject = function (parentIndex) {
        $scope.data.definition.participantGroups[parentIndex].participantObjects.push({});
    };

    $scope.removeParticipantGroup = function (index) {
        $scope.data.definition.participantGroups.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.removeParticipantObject = function (parentIndex, index) {
        $scope.data.definition.participantGroups[parentIndex].participantObjects.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.onParticipantOrderTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.participantGroups[index].orderExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onParticipantGroupMessageTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.participantGroups[index].privateMessageExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onParticipantEmailTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.participantGroups[parentIndex].participantObjects[index].participantEmailExpression =
            expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onParticipantNameTonkeanExpressionChanged = function (expression, parentIndex, index, shouldSaveLogic) {
        $scope.data.definition.participantGroups[parentIndex].participantObjects[index].participantNameExpression =
            expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('AdobesignCustomActionsDefinitionCtrl', lateConstructController(AdobesignCustomActionsDefinitionCtrl));
