import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TriggerIcon } from '../../../images/icons/lightning.svg';
import { ReactComponent as LockIcon } from '../../../images/icons/lock-o.svg';
import TonkeanAvatarsBar from '../ProjectIntegrationPageModule/components/EnterpriseComponentSolutionsAccess/TonkeanAvatarsBar';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { H2, HighlightableText, TextEllipsis } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { classNames } from '@tonkean/utils';

const SolutionBoxWrapper = styled(Clickable)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: left;

    border: 1px solid ${Theme.colors.gray_300};
    border-top: 4px solid ${Theme.colors.promotion};
    border-radius: 3px;

    &:hover {
        box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
        border-bottom-color: ${Theme.colors.gray_400};
        border-left-color: ${Theme.colors.gray_400};
        border-right-color: ${Theme.colors.gray_400};
    }
`;

const TopArea = styled.div`
    flex-grow: 1;
    padding: 16px;
`;

const DetailsBar = styled.div`
    display: flex;
    align-items: center;
    padding: 14px 16px;
    border-top: 1px solid ${Theme.colors.gray_300};
`;

const TitleBar = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`;
const StyledH2 = styled(H2)`
    flex-grow: 1;
`;

const StyledLockIcon = styled(LockIcon)`
    width: 18px;
    height: 18px;
`;

const WrapperTextEllipsis = styled.div`
    margin-top: 20px;
    margin-bottom: 11px;
    line-height: 1.2;
    font-size: ${FontSize.SMALL_12};
    white-space: pre-wrap;
    overflow-wrap: break-word;
`;

const DetailIcon = styled.div`
    line-height: 10px;
    margin-right: 10px;

    svg {
        width: 16px;
        height: 16px;
    }
`;

const DetailText = styled.div`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};

    &:not(:last-child) {
        margin-right: 45px;
    }
`;

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    folderId: string;
    scrolledTo: boolean;
    highlightText?: string;
}

const SolutionBox: React.FC<Props> = ({ projectId, folderId, scrolledTo, highlightText }) => {
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const { data: workflowFolder } = useAsyncMethod(workflowFolderManager, 'getWorkflowFolder', projectId, folderId);
    const classes = classNames(scrolledTo && 'scrolled-to');

    const folderVisible = workflowFolder?.isVisible;

    return (
        <SolutionBoxWrapper
            id={`folder-${folderId}`}
            className={classes}
            state={folderVisible && 'product.solution'}
            params={folderVisible && { solutionId: folderId }}
            buttonAsDiv
        >
            {workflowFolder && (
                <>
                    <TopArea>
                        <TitleBar data-automation="solution-box-cards">
                            <StyledH2>
                                <TextEllipsis numberOfLines={1} tooltip>
                                    <HighlightableText
                                        text={workflowFolder.displayName}
                                        highlightText={highlightText}
                                        data-automation="solution-box-title"
                                    />
                                </TextEllipsis>
                            </StyledH2>
                            {!folderVisible && <StyledLockIcon />}
                        </TitleBar>
                        <TonkeanAvatarsBar
                            avatars={workflowFolder.processOwners}
                            avatarsDesktopLimit={12}
                            shouldDisplayViewTime={false}
                            showAllInDisplayMore={false}
                            separatedAvatars
                            showMoreAvatarsLink
                        />
                    </TopArea>
                    <DetailsBar>
                        <DetailIcon>
                            <TriggerIcon />
                        </DetailIcon>
                        <DetailText data-automation="solution-box-modules-count">
                            {workflowFolder.groupIds.length} Modules
                        </DetailText>
                    </DetailsBar>
                </>
            )}
        </SolutionBoxWrapper>
    );
};

export default SolutionBox;
