enum NavigablePageKey {
    LIVE_REPORT = 'LIVE_REPORT',
    ENTERPRISE_COMPONENTS = 'DATA_SOURCES',
    ENTERPRISE_COMPONENTS_DATA_SOURCES = 'ENTERPRISE_COMPONENTS_DATA_SOURCES',
    ENTERPRISE_COMPONENTS_COMMUNICATION_TOOLS = 'ENTERPRISE_COMPONENTS_COMMUNICATION_TOOLS',
    ENTERPRISE_COMPONENTS_BUSINESS_GROUPS = 'ENTERPRISE_COMPONENTS_BUSINESS_GROUPS',
    ENTERPRISE_COMPONENTS_TRAINING_SETS = 'ENTERPRISE_COMPONENTS_TRAINING_SETS',
    ENTERPRISE_COMPONENTS_REUSABLE_COMPONENTS = 'ENTERPRISE_COMPONENTS_REUSABLE_COMPONENTS',
    OPS_MODULES = 'OPS_MODULES',
    RUNTIME = 'RUNTIME',
    EDUCATION_CENTER = 'EDUCATION_CENTER',
    BOARD_SETTINGS = 'BOARD_SETTINGS',
    HOMEPAGE = 'HOMEPAGE',
}

export default NavigablePageKey;
