import { useAngularService } from 'angulareact';
import { Formik } from 'formik';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import type { MarketplaceItemCreateSection } from './marketplace/components/MarketplaceItemCreateModalSection';
import MarketplaceItemCreateModalSection from './marketplace/components/MarketplaceItemCreateModalSection';
import useGetMarketplaceItemFinalSections from './marketplace/hooks/useGetMarketplaceItemFinalSections';
import useMarketplaceItemInitialValues from './marketplace/hooks/useMarketplaceItemInitialValues';
import MarketplaceItemCreateModal from './marketplace/MarketplaceItemCreateModalContext';
import { WorkflowFolderCategoriesContext } from '../../../modules/SolutionsPageModule/entities/WorkflowFolderCategoriesContext';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    FormikHelpers,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    useCloseCallback,
    withModal,
} from '@tonkean/infrastructure';
import { MarketplaceItemType, BlueprintSolutionType } from '@tonkean/tonkean-entities';
import type {
    Group,
    MarketplaceItem,
    SolutionMarketplaceItem,
    TonkeanId,
    TonkeanType,
    TonkeanUploadedFile,
} from '@tonkean/tonkean-entities';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledModalBody = styled(ModalBody)`
    background: ${Theme.colors.HEX_F7F8FA};
`;

const MarketplaceItemTypeToLabel: Record<MarketplaceItemType | BlueprintSolutionType, string> = {
    [MarketplaceItemType.SOLUTION]: 'Solution Template',
    [MarketplaceItemType.MODULE]: 'Module Template',
    [MarketplaceItemType.DATA_SOURCE]: 'Data Source Template',
    [MarketplaceItemType.PAGE]: 'Page Template',
    [MarketplaceItemType.CONTRACT]: 'Contract Template',
    [BlueprintSolutionType.SOLUTION_TEMPLATE]: 'Internal Solution Template',
    [BlueprintSolutionType.MARKETPLACE_BLUEPRINT]: 'Marketplace Blueprint',
};

interface Props {
    blueprintSolutionType?: BlueprintSolutionType;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    marketplaceItemType: MarketplaceItemType;
    entityIdToCopy?: TonkeanId;
    entityNameToCopy?: string;
    workflowFolder?: WorkflowFolder;
    existingMarketplaceItem?: MarketplaceItem;
    onSubmit?: (marketplaceItem: MarketplaceItem) => void;
    additionalSections?: MarketplaceItemCreateSection[];
    marketplaceItemImages?: TonkeanUploadedFile[];
}

const MarketplaceItemCreateEditModal: React.FC<Props> = ({
    projectId,
    marketplaceItemType,
    entityIdToCopy,
    entityNameToCopy,
    workflowFolder,
    existingMarketplaceItem,
    onSubmit,
    additionalSections,
    marketplaceItemImages,
    blueprintSolutionType,
}) => {
    const onClose = useCloseCallback();

    const $rootScope = useAngularService('$rootScope');
    const $timeout = useAngularService('$timeout');

    const [{ loading: createLoading, error: createError }, createMarketplaceItem] =
        useLazyTonkeanService('createMarketplaceItem');
    const [{ loading: updateLoading, error: updateError }, updateMarketplaceItem] =
        useLazyTonkeanService('updateMarketplaceItem');
    const [{ loading: uploadImagesLoading, error: uploadImagesError }, uploadMarketplaceItemImages] =
        useLazyTonkeanService('uploadMarketplaceItemImages');

    const loading = createLoading || updateLoading || uploadImagesLoading;
    const error = createError || updateError || uploadImagesError;

    const isMarketplaceBlueprint =
        marketplaceItemType === MarketplaceItemType.SOLUTION &&
        (!blueprintSolutionType || blueprintSolutionType === BlueprintSolutionType.MARKETPLACE_BLUEPRINT);

    const sections = useGetMarketplaceItemFinalSections(
        isMarketplaceBlueprint,
        marketplaceItemType,
        additionalSections,
    );

    // Aggregate the schemas from all of the sections
    const validationSchema = useMemo(() => {
        const schemaEntries: any = sections
            .map((section) => section.validationSchema)
            .filter(Boolean)
            .flatMap((schema) => Object.entries(schema));
        const configurationSchemaEntries: any = sections
            .map((section) => section.configurationValidationSchema)
            .filter(Boolean)
            .flatMap((schema) => Object.entries(schema));

        const schema = Object.fromEntries(schemaEntries);
        schema.configuration = Yup.object(Object.fromEntries(configurationSchemaEntries));
        return Yup.object(schema);
    }, [sections]);

    const initialValues = useMarketplaceItemInitialValues(
        projectId,
        sections,
        marketplaceItemType,
        existingMarketplaceItem,
        workflowFolder,
        entityNameToCopy,
        entityIdToCopy,
        marketplaceItemImages,
        blueprintSolutionType,
    );

    const groupInfoManager = useAngularService('groupInfoManager');
    const workflowFolderCategoriesContextValue = useContext(WorkflowFolderCategoriesContext);
    const marketplaceItemCreateModalContextValue = useMemo(() => {
        const groups = workflowFolder?.groupIds
            .map((groupId) => groupInfoManager.getGroupByIdFromCache(groupId) as Group | undefined)
            .filter(Boolean) as Group[];

        return {
            entityIdToCopy,
            workflowFolder,
            workflowFolderCategories: workflowFolderCategoriesContextValue?.workflowFolderCategories,
            groups,
            existingMarketplaceItem,
            workflowFolderCategoryProcessParticipants:
                workflowFolderCategoriesContextValue?.workflowFolderCategoryProcessParticipants || [],
        };
    }, [
        entityIdToCopy,
        existingMarketplaceItem,
        groupInfoManager,
        workflowFolder,
        workflowFolderCategoriesContextValue?.workflowFolderCategories,
        workflowFolderCategoriesContextValue?.workflowFolderCategoryProcessParticipants,
    ]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                // prepare images to upload
                const marketplaceItemImagesToUpload = values.images?.filter((image) => !!image.blob) || [];
                const sectionIndexToImagesToUpload: Record<number, TonkeanUploadedFile> = {};

                if (isMarketplaceBlueprint) {
                    values.configuration.solutionOutline?.sections?.map((section, index) => {
                        if (section.image?.[0]?.blob) {
                            sectionIndexToImagesToUpload[index] = section.image[0];
                        }
                    });
                }

                // we are uploading images here and replace them with urls in order to make the createMarketplaceItem call async (Which makes us get rid of the InputStream)
                const imagesUploadResult = await uploadMarketplaceItemImages(
                    values.projectId,
                    marketplaceItemImagesToUpload,
                    Object.values(sectionIndexToImagesToUpload),
                    values.entityId || existingMarketplaceItem?.rootEntityId,
                );

                // prepare images to delete
                const imagesToDelete: string[] = [];

                if (isMarketplaceBlueprint) {
                    // replace uploaded section images with urls, and delete the old ones if needed

                    let uploadedImagesCounter = 0;
                    values.configuration.solutionOutline?.sections.forEach((section, index) => {
                        if (section.image?.[0]?.blob) {
                            if (existingMarketplaceItem) {
                                const imageToDelete = (existingMarketplaceItem as SolutionMarketplaceItem).configuration
                                    .solutionOutline?.sections[index]?.image?.[0]?.url;
                                imageToDelete && imagesToDelete.push(imageToDelete);
                            }

                            section.image = [
                                {
                                    id: section.image[0].id,
                                    url: imagesUploadResult.sectionImageUrls[uploadedImagesCounter],
                                },
                            ];

                            uploadedImagesCounter += 1;
                        }
                    });
                }

                if (imagesUploadResult.imageUrls.length > 0) {
                    // in case of edit we need to delete the old images (Actually it's always one image so that is the reason for the deletion)
                    imagesToDelete.push(...(existingMarketplaceItem?.images || []));
                }

                // get current images (after upload) to send to create/update
                const currentMarketplaceItemImages =
                    imagesUploadResult.imageUrls.length > 0
                        ? imagesUploadResult.imageUrls
                        : existingMarketplaceItem?.images || [];

                const result = !existingMarketplaceItem
                    ? await createMarketplaceItem(values, imagesUploadResult.imageUrls)
                    : await updateMarketplaceItem(
                          existingMarketplaceItem.id,
                          values,
                          imagesToDelete,
                          currentMarketplaceItemImages,
                      );

                onSubmit?.(result);
                $timeout(() => {
                    $rootScope.$emit('alert', {
                        msg: `Successfully ${existingMarketplaceItem ? 'updated' : 'created'} reusable component`,
                        linkText: `${result.title}`,
                        linkUiSref: `product.enterpriseComponents({tab:'reusable-components'})`,
                        type: 'success',
                        timeout: 3500,
                    });
                });
                onClose();
            }}
        >
            <FormikHelpers>
                <MarketplaceItemCreateModal.Provider value={marketplaceItemCreateModalContextValue}>
                    <ModalForm data-automation="create-marketplace-item-modal">
                        <ModalHeader data-automation="create-marketplace-item-modal-header">
                            {existingMarketplaceItem ? 'Edit' : 'Create'}{' '}
                            {MarketplaceItemTypeToLabel[blueprintSolutionType ?? marketplaceItemType]}
                        </ModalHeader>
                        <StyledModalBody>
                            {sections.map((section, index) => (
                                <MarketplaceItemCreateModalSection key={index} section={section} />
                            ))}
                        </StyledModalBody>

                        <ModalFooterActions error={error} loading={loading} />
                    </ModalForm>
                </MarketplaceItemCreateModal.Provider>
            </FormikHelpers>
        </Formik>
    );
};

export default withModal(MarketplaceItemCreateEditModal, {
    size: ModalSize.MEDIUM,
    fixedWidth: true,
    onBackdropClicked: () => false,
});
