import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import { H1, Paragraph, SavingIndicator } from '@tonkean/infrastructure';
import type { ErrorResponseType } from '@tonkean/utils';

const IndicatorsWrapper = styled.div`
    margin-left: 8px;
`;

const TitleWrapper = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
`;

interface Props {
    title: ReactNode;
    description?: ReactNode;
    isLoading?: boolean;
    error?: ErrorResponseType;
}

const ProjectIntegrationEntityLeftPageGenericHeader: React.FC<Props> = ({
    error,
    isLoading = false,
    title,
    description,
}) => {
    return (
        <>
            <TitleWrapper>
                <H1>{title}</H1>

                <IndicatorsWrapper>
                    <SavingIndicator loading={isLoading} error={error} />
                </IndicatorsWrapper>
            </TitleWrapper>
            {description && <Paragraph $light>{description}</Paragraph>}
        </>
    );
};

export default ProjectIntegrationEntityLeftPageGenericHeader;
