import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const SimpleLegalAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [subDomain, setSubDomain] = useState<string>();
    const [apiToken, setApiToken] = useState<string>();

    const startAuthentication = (formEvent: FormEvent) => {
        formEvent.preventDefault();
        formEvent.stopPropagation();

        try {
            const config = {
                subDomain,
                apiToken,
            };
            authenticate(config, {}, `${integrationConfiguration.displayName} (${subDomain})`);
        } catch {
            // We are using this component from angularjs context, thus we can't be sure that reportError is present.
            if (reportError) {
                reportError('Error occurred while authenticating.');
            }
        }
    };

    return (
        <form className="flex flex-col" onSubmit={startAuthentication}>
            <div className="flex flex-col">
                {/* URL*/}
                <div className="form-group">
                    <label className="col-sm-3 control-label">URL</label>
                    <div className="col-sm-8">
                        <div className="flex-vmiddle">
                            <div>https://</div>
                            <div className="flex-grow">
                                <input
                                    type="text"
                                    value={subDomain}
                                    className="form-control"
                                    onChange={({ target }) => setSubDomain(target.value)}
                                    autoComplete="off"
                                    placeholder="Your sub-domain"
                                    required
                                />
                            </div>
                            <div>.simplelegal.com</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col">
                    <div className="form-group">
                        <label className="col-sm-3 control-label">API Token</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={apiToken}
                                className="form-control"
                                onChange={({ target }) => setApiToken(target.value)}
                                autoComplete="off"
                                placeholder="API Token"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                Go to SimpleLegal -&gt; Administration -&gt; Client Keys and create a new ClientKey.
                            </span>
                        </div>
                    </div>
                </div>

                <div className="flex mod-center margin-top-20">
                    <button className="btn btn-secondary btn-lg">Connect</button>
                </div>
            </div>
        </form>
    );
};

export default SimpleLegalAuthenticationComponent;
