import React from 'react';
import styled from 'styled-components';

import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
`;

const FormDescriptionText = styled.div`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_500};
`;

const EmptyFormDescription: React.FC = () => {
    return (
        <Wrapper>
            <FormDescriptionText>No Forms found</FormDescriptionText>
        </Wrapper>
    );
};

export default EmptyFormDescription;
