import template from './tnkSolutionMetrics.template.html?angularjs';

/**
 * A components which shows the metrics of a solution and its modules in different environments.
 */
export default angular.module('tonkean.app').component('tnkSolutionMetrics', {
    template,
    controller: 'SolutionMetricsCtrl',
    bindings: {
        selectedEnv: '<',
        moduleSearch: '<',
    },
});
