import type { IHttpResponse } from 'angular';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, MatchedItemActionData, TonkeanType } from '@tonkean/tonkean-entities';

const useMatchedItemActionsData = (
    groupId: TonkeanId<TonkeanType.GROUP>,
    projectId: TonkeanId<TonkeanType.PROJECT>,
): { data: MatchedItemActionData[] | undefined; loading: boolean; error: IHttpResponse<any> | undefined } => {
    const {
        data: matchedItemActionData,
        loading: matchedItemLoading,
        error: matchedItemError,
    } = useTonkeanService('getMatchItemActionData', projectId, groupId);

    return {
        data: matchedItemActionData?.entities,
        loading: matchedItemLoading,
        error: matchedItemError,
    };
};

export default useMatchedItemActionsData;
