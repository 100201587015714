import React from 'react';

import ProcessMapperGraphEdgeWrapper from './ProcessMapperGraphEdgeWrapper';

/**
 * Each node size is calculated like this: title = 60px, each field = 33px, padding of 16px is added only when there is at least 1 field.
 * We must give every node type a size in advance so the node type is calculated in the server according to the number of field it has.
 */
const processMapperGraphConfig = {
    nodeTypes: {
        DEFAULT: {
            shape: (
                <symbol width="310" height="60" viewBox="0 0 310 60" id="DEFAULT">
                    <rect x={0} y={15} width="310" height="60" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#DEFAULT',
            typeText: 'None',
        },
        NODE_1_FIELD: {
            shape: (
                <symbol width="310" height="109" viewBox="0 0 310 109" id="NODE_1_FIELD">
                    <rect x={0} y={15} width="310" height="109" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#NODE_1_FIELD',
            typeText: 'None',
        },
        NODE_2_FIELD: {
            shape: (
                <symbol width="310" height="142" viewBox="0 0 310 142" id="NODE_2_FIELD">
                    <rect x={0} y={15} width="310" height="142" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#NODE_2_FIELD',
            typeText: 'None',
        },
        NODE_3_FIELD: {
            shape: (
                <symbol width="310" height="175" viewBox="0 0 310 175" id="NODE_3_FIELD">
                    <rect x={0} y={15} width="310" height="175" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#NODE_3_FIELD',
            typeText: 'None',
        },
        NODE_4_FIELD: {
            shape: (
                <symbol width="310" height="208" viewBox="0 0 310 208" id="NODE_4_FIELD">
                    <rect x={0} y={15} width="310" height="208" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#NODE_4_FIELD',
            typeText: 'None',
        },
        NODE_5_FIELD: {
            shape: (
                <symbol width="310" height="241" viewBox="0 0 310 241" id="NODE_5_FIELD">
                    <rect x={0} y={15} width="310" height="241" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#NODE_5_FIELD',
            typeText: 'None',
        },
        JOINT: {
            shape: (
                <symbol width="20" height="20" viewBox="0 0 20 20" id="JOINT">
                    <circle cx="10" cy="10" r="9" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#JOINT',
            typeText: 'None',
        },
        DISPLAYABLE_JOINT: {
            shape: (
                <symbol width="0.1" height="0.1" viewBox="0 0 0.1 0.1" id="DISPLAYABLE_JOINT">
                    <circle cx="90.5" cy="90.5" r="90.5" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#DISPLAYABLE_JOINT',
            typeText: 'None',
        },
    },
    nodeSubtypes: {},
    edgeTypes: {
        DEFAULT: {
            shapeId: '#DEFAULT',
            shape: (
                <ProcessMapperGraphEdgeWrapper id="DEFAULT">
                    <path d="M424.994 29.616l3.623-615.111M25.434 30.514l6.258.279" stroke="#9CA6B2" />
                </ProcessMapperGraphEdgeWrapper>
            ),
        },
        TARGET_JOINT: {
            shapeId: '#TARGET_JOINT',
            shape: (
                <ProcessMapperGraphEdgeWrapper id="TARGET_JOINT">
                    <path d="M424.994 29.616l3.623-615.111M25.434 30.514l6.258.279" stroke="#9CA6B2" />
                </ProcessMapperGraphEdgeWrapper>
            ),
        },
    },
};

export default processMapperGraphConfig;
