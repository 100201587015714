import { useAngularService } from 'angulareact';
import type { EventSource } from 'extended-eventsource';
import { useMemo } from 'react';

import { getEventSource } from './useLazyEventStreamAsyncMethodHelper';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { HttpMethodType } from '@tonkean/tonkean-entities';

type EventSourceErrorType = MessageEvent<unknown>;

interface Props {
    /**
     * A record of string to callbacks, that will be run when the matching event from the event source is received
     */
    listeners?: Record<string, EventListener>;

    /**
     * A string representing the event name that will be considered as the result, a terminating event.
     * Defaults to 'result'
     */
    resultEventKey?: string;

    /**
     * What http method to use for the call?
     * Defaults to GET
     */
    httpMethod?: HttpMethodType.GET | HttpMethodType.POST;
}

/**
 * Hook for working with the browsers event stream the same way you would work with useAsyncMethod.
 */
export function useLazyEventStreamAsyncMethod<PARAMS extends object | undefined, RESULT extends object | undefined>(
    props: Props,
): ReturnType<
    typeof useLazyAsyncMethod<
        EventSourceErrorType,
        { execute: (url: string, queryParams: PARAMS) => Promise<RESULT> },
        'execute'
    >
> {
    const { listeners, resultEventKey = 'result', httpMethod = HttpMethodType.GET } = props;
    const environment = useAngularService('environment');
    const authenticationService = useAngularService('authenticationService');
    const methodObject = useMemo(() => {
        return {
            execute: (url: string, params: PARAMS) => {
                const currentUser = authenticationService.currentUser;
                const accessToken = currentUser?.accessToken;
                const projectToken = authenticationService.getProjectToken();

                const queryParamsString =
                    httpMethod === HttpMethodType.GET && params
                        ? `?${Object.entries(params)
                              .map(([key, value]) => `${key}=${value ?? ''}`)
                              .join('&')}`
                        : '';
                let sse: EventSource | undefined = undefined;
                const promise = new Promise<RESULT>((resolve, reject) => {
                    // We have to use this EventSource package because the browsers EventSource does not allow for headers / http POST method
                    sse = getEventSource(`${environment.apiUrl}${url}${queryParamsString}`, {
                        method: httpMethod,
                        body: httpMethod === HttpMethodType.POST && params ? JSON.stringify(params) : undefined,
                        headers: {
                            authorization: accessToken ? `token ${accessToken}` : '',
                            'Authorization-Project': projectToken ? `Bearer ${projectToken}` : '',
                            'Content-Type': 'application/json',
                        },
                    });
                    if (listeners) {
                        Object.entries(listeners).forEach(([key, listener]) => sse?.addEventListener(key, listener));
                    }

                    sse.addEventListener(resultEventKey, (event: MessageEvent<string | undefined>) => {
                        const result = event.data ? JSON.parse(event.data) : undefined;
                        resolve(result);
                    });

                    sse.addEventListener('error', (event) => {
                        reject(event);
                    });
                });

                return promise.finally(() => {
                    sse?.close();
                });
            },
        };
    }, [authenticationService, environment.apiUrl, httpMethod, listeners, resultEventKey]);

    return useLazyAsyncMethod(methodObject, 'execute');
}
