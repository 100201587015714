<div class="padding-normal common-close-btn-container">
    <!-- Close button -->
    <div class="common-close-btn" ng-click="onCancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <!-- Title -->
    <div class="modal-header">
        <div class="flex-vmiddle">
            <div class="integration-icon mod-tableau"></div>
            <h4 class="flex-grow">Setup Tableau View</h4>
        </div>
    </div>

    <!-- Tableau project integration creator -->
    <tnk-tableau-project-integration-configuration
        project-integration="projectIntegration"
        on-project-integration-created="onTableauProjectIntegrationConfigured(projectIntegration)"
        on-cancel="onCancel()"
    ></tnk-tableau-project-integration-configuration>
</div>
