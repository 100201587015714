function ApiConsts($rootScope, $http, integrations, utils, tonkeanService, projectManager) {
    const _this = this;
    _this.integrationEntitiesAndFieldsConfiguration = null;
    _this.loadingByProjectId = {};

    $rootScope.$on('projectSelected', loadConsts);
    $rootScope.$on('newIntegrationAdded', loadConsts);

    /**
     * Initializes the service.
     */
    _this.init = function () {
        if (projectManager && projectManager.project && projectManager.project.id) {
            loadConsts();
        }

        return _this;
    };

    /**
     * Returns the integrationEntitiesAndFieldsConfiguration property.
     */
    _this.getIntegrationEntitiesAndFieldsConfiguration = function () {
        return _this.integrationEntitiesAndFieldsConfiguration;
    };

    /**
     * Returns configuration by integration type.
     */
    this.getConfigurationByIntegration = function (integrationType) {
        return _this.integrationEntitiesAndFieldsConfiguration[integrationType.toUpperCase()];
    };

    /**
     * Returns configuration by integration type and entity.
     */
    this.getConfigurationByIntegrationAndEntity = function (integrationType, entity) {
        if (_this.integrationEntitiesAndFieldsConfiguration[integrationType.toUpperCase()]) {
            return _this.integrationEntitiesAndFieldsConfiguration[integrationType.toUpperCase()][entity];
        } else {
            return null;
        }
    };

    this.loadWebhookSources = function () {
        if (!!_this.webhooksSources) {
            $rootScope.$broadcast('webhookSourcesLoaded');
            return;
        }

        $http.get('https://s3-us-west-2.amazonaws.com/tonkean-files/webhook_integrations.json').then(function (result) {
            _this.webhooksSources = result.data;

            // Filter out existing sources from the webhooks ones.
            if (_this.webhooksSources) {
                const allIntegrationsArray = integrations.getAllIntegrationTypes();
                // Faster using a dict of all integrations than searching on an array every time.
                const allIntegrations = utils.arrayToSet(allIntegrationsArray, true);

                _this.webhooksSources = _this.webhooksSources.filter(function (webhookSource) {
                    if (webhookSource.name) {
                        const name = webhookSource.name.toLowerCase();
                        const nameNoSpace = name.replace(/\s/, '');
                        // check exact
                        if (allIntegrations.hasOwnProperty(name) || allIntegrations.hasOwnProperty(nameNoSpace)) {
                            return false;
                        }
                        // check contains
                        for (const element of allIntegrationsArray) {
                            const integ = element.toString();
                            if (nameNoSpace.includes(integ) || integ.includes(nameNoSpace)) {
                                return false;
                            }
                        }
                    }
                    return true;
                });

                // Broadcast that the wehooks finish to load so other controllers can update theirs
                $rootScope.$broadcast('webhookSourcesLoaded');
            }
        });
    };

    /**
     * Loads the API consts from server.
     */
    function loadConsts() {
        if (
            projectManager &&
            projectManager.project &&
            projectManager.project.id &&
            !_this.loadingByProjectId[projectManager.project.id] &&
            projectManager.getProjectToken()
        ) {
            _this.loadingByProjectId[projectManager.project.id] = true;
            tonkeanService
                .getIntegrationEntitiesAndFieldsConfiguration(projectManager.project.id)
                .then(function (configuration) {
                    // If integrationEntitiesAndFieldsConfiguration is already assigned, we should update its fields with newest configuration.
                    if (_this.integrationEntitiesAndFieldsConfiguration) {
                        for (const property in configuration) {
                            if (configuration.hasOwnProperty(property)) {
                                _this.integrationEntitiesAndFieldsConfiguration[property] = configuration[property];
                            }
                        }
                    }

                    _this.integrationEntitiesAndFieldsConfiguration = configuration;
                })
                .finally(() => (_this.loadingByProjectId[projectManager.project.id] = false));
        }
    }
}

angular.module('tonkean.shared').service('apiConsts', ApiConsts);
