<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<form>
    <div>
        <div class="modal-body salesforce-modal">
            <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>

            <div ng-hide="connecting || integration">
                <div class="text-center">
                    <button
                        type="button"
                        class="btn btn-secondary btn-lg"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="authorize()"
                    >
                        Authenticate with {{ currentIntegration.displayName }}
                    </button>
                    <div class="margin-normal-sm-v common-subtitle-inner"></div>
                </div>

                <div class="margin-top-xlg">
                    <div class="common-title-xxxs mod-grey mod-light common-underlinen">
                        {{ currentIntegration.displayName }} Account Type
                    </div>
                    <tnk-radio-button
                        radio-id="saleforce-account-type-sandbox"
                        radio-name="saleforce-account-type"
                        radio-label="Sandbox"
                        radio-value="sandbox"
                        model="data.selectedAccountType"
                        is-required="true"
                        on-click="setAccountType('sandbox')"
                        on-click-param="(sandbox)"
                        classes="margin-top"
                    ></tnk-radio-button>
                    <tnk-radio-button
                        radio-id="saleforce-account-type-production"
                        radio-name="saleforce-account-type"
                        radio-label="Production"
                        radio-value="production"
                        model="data.selectedAccountType"
                        is-required="true"
                        on-click="setAccountType('production')"
                        on-click-param="(production)"
                        classes="margin-top"
                    ></tnk-radio-button>
                </div>

                <!-- Should collect converted leads -->
                <div class="margin-top-xlg">
                    <div class="common-title-xxxs mod-grey mod-light common-underlinen">
                        Converted Leads
                    </div>
                    <div>
                        <input
                                id="collect-converted-leads"
                                type="checkbox"
                                ng-model="data.collectConvertedLeads"
                        />
                        <label
                                for="collect-converted-leads"
                                class="flex-no-shrink common-size-xxs margin-top-6 padding-left-none"
                        >
                            Should collect converted leads
                        </label>
                    </div>
                </div>
            </div>

            <div class="text-center" ng-show="connecting">
                <h4>
                    <span class="loading"></span>
                    Connecting to {{ currentIntegration.displayName }}
                </h4>
            </div>

            <div ng-show="integration && !connecting">
                <!-- Authenticated component -->
                <div class="alert alert-info">
                    <a
                        class="pull-right"
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Change User
                    </a>

                    <div class="integration-group-header-connected" ng-if="integration">
                        Authenticated
                        <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                            as
                            <span class="integration-group-header-connected-username">
                                {{ integration.integrationUserDisplayName || integration.integrationUser }}
                            </span>
                        </span>
                    </div>
                </div>

                <!-- Account type component -->
                <div class="margin-normal-v">
                    <span>Account Type:</span>
                    <span class="common-bold">{{ data.accountTypes[data.selectedAccountType].label }} -</span>
                    <a ng-click="resetIntegration()">Change Account Type</a>
                </div>

                <!-- Webhook section -->
                <div class="webhook-section padding-md" ng-if="projectIntegration">
                    <div class="flex-grow common-title-xs margin-bottom">Salesforce webhooks:</div>
                    <!-- Create endpoint button -->
                    <div
                        class="flex-vmiddle"
                        ng-if="!loading && !data.incomingWebhookUrl && !data.loadingExistingIncomingWebhooks"
                    >
                        <div class="btn btn-primary" ng-click="createSalesforceIncomingWebhook()">
                            Create Salesforce Webhook Endpoint
                        </div>
                    </div>

                    <!-- Endpoint -->
                    <div ng-if="data.incomingWebhookUrl">
                        <!-- webhook url + copy button -->
                        <div class="flex-vmiddle">
                            <div
                                class="
                                    common-size-xxs common-bold
                                    margin-right
                                    padding-right-none
                                    margin-top-xs margin-bottom-xs
                                "
                            >
                                URL:
                            </div>
                            <div class="flex-grow">
                                <div class="input-group">
                                    <div class="relative flex">
                                        <input
                                            type="text"
                                            id="input-webhookUrl"
                                            class="
                                                form-control
                                                inline-block
                                                text-center
                                                common-width-full
                                                webhook-url-input
                                            "
                                            ng-model="data.incomingWebhookUrl"
                                        />
                                    </div>
                                    <span class="input-group-btn">
                                        <button
                                            type="button"
                                            class="btn btn-default email-endpoint-modal-copy-btn"
                                            ng-click="copyUrlToClipboard('input-webhookUrl')"
                                        >
                                            Copy
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- Setup instructions -->
                        <div class="common-size-xxxs margin-top-md">
                            <div class="common-bold">Setup instructions:</div>
                            <div class="common-color-light-grey">
                                <ol>
                                    <li class="margin-bottom-xs margin-top-xs">Go to Setup > Process Automation >
                                        Workflow Actions > Outbound Messages and click on "New Outbound Message".
                                    </li>
                                    <li class="margin-bottom-xs">Select the relevant object (like 'Account') and click
                                        "Next".
                                    </li>
                                    <li class="margin-bottom-xs">Type a name for the outbound message and paste the
                                        webhook url from above in the Endpoint URL field and click on "Save".
                                    </li>
                                    <li class="margin-bottom-xs">Go to Setup > Process Automation > Flows and click on
                                        "New Flow".
                                    </li>
                                    <li class="margin-bottom-xs">Select "Start From Scratch", Click "Next", Select
                                        "Record-Triggered Flow" and click on "Create".
                                    </li>
                                    <li class="margin-bottom-xs">Choose the Object from step 2 and select "A record is
                                        created or updated" for the trigger.
                                    </li>
                                    <li class="margin-bottom-xs">Add element in the flow, select "Action" and
                                        then the workflow action from steps 1-3.
                                    </li>
                                    <li class="margin-bottom-xs">Give the action a label and click "Save".</li>
                                    <li class="margin-bottom-xs">Give the flow a Label. Click "Save" and Activate the
                                        flow.
                                    </li>
                                </ol>

                                <div>For more information please contact our support team.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button
            class="btn btn-default"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Cancel Integration"
            analytics-label="{{ currentIntegration.name }}"
            analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
            type="button"
            ng-click="onCancel()"
        >
            Cancel
        </button>

        <button
            class="btn btn-primary"
            ng-disabled="(selections|filter:{loadingBranches:true}:true).length"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Save Integration"
            analytics-label="{{ currentIntegration.name }}"
            analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
            type="button"
            ng-click="ok()"
        >
            OK
        </button>
    </div>
</form>
