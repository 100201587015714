<tnk-form-page
        form="data.form"
        project="data.project"
        load-form="data.loadForm"
        add-under-initiative="data.addUnderInitiative"
        collection-form="data.collectionForm"
        group="data.group"
        field-definition-id="data.fieldDefinitionId"
        only-inner-items="data.onlyInnerItems"
        hierarchy="data.hierarchy"
        form-version-type="data.formVersionType"
        view-mode="data.viewMode"
        worker-run-id="data.workerRunId"
        worker-run-logic-action-type="data.workerRunLogicActionType"
        form-id="data.formId"
        session-id="data.sessionId"
        custom-trigger-id="data.customTriggerId"
        initiative-id="data.initiativeId"
        item-interface-id="data.itemInterfaceId"
        workflow-version-id="data.workflowVersionId"
/>

