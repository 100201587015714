import { analyticsWrapper } from '@tonkean/analytics';
import { getInviteMessages } from '@tonkean/constants';

function InviteCtrl(
    $scope,
    $uibModalInstance,
    $q,
    projectManager,
    authenticationService,
    groupInfoManager,
    groupPermissions,
    inviteManager,
    utils,
    initialPeople,
    initialMessageText,
    isIntro,
    initialPrivateGroupIds,
    afterInviteCallback,
) {
    let groups = [];
    let groupsRequested = false;
    const dummyGroupPlaceholder = { id: 'dummy', name: 'No private modules found', isDummyPlaceholder: true };

    $scope.currentUserId = authenticationService.currentUser.id;
    $scope.project = projectManager.project;
    $scope.invitees = [];
    $scope.initialMessageText = initialMessageText || getInviteMessages($scope.project.name).default;
    $scope.isIntro = !!isIntro;
    $scope.showCustomInviteMessage = true;

    $scope.badEmails = ['shali@abc'];

    $scope.data = {
        submitDisabled: false,
        selectedPrivateGroups: [],
        loadingPrivateGroups: false,
        messageText: '',
        customMessageText:
            `Hello,\n\r` +
            `I'm inviting you to collaborate in Tonkean, where we're automating our processes and building great experiences.\n\r` +
            `Sign in to Tonkean using the link provided in this email and start exploring our solutions.\n\r` +
            `To learn more, visit the Tonkean Docs: https://docs.tonkean.com. There, you'll find information on how to automate processes across various business functions by connecting with your existing systems.`,
    };

    $scope.init = function () {
        $scope.data.messageText = angular.copy($scope.initialMessageText);

        if (initialPeople && initialPeople.length) {
            $scope.invitees = initialPeople;
        }

        groupInfoManager.getGroups().then((groupsData) => {
            const groups = groupsData.filter((group) => group.visibilityType !== 'PRIVATE');
            let tooltip = '';
            if (groups.length > 0) {
                tooltip += groups[0].name;
            }
            if (groups.length > 1) {
                tooltip += `, ${groups[1].name}`;
            }
            if (groups.length > 2) {
                tooltip += ` and ${groups.length - 2} more`;
            }

            if (tooltip.length) {
                tooltip = ` (${tooltip})`;
            }

            $scope.data.selectedPrivateGroups = [
                {
                    name: 'All Public Modules',
                    publicPlaceholder: true,
                    tooltip: `Public modules${tooltip} are accessible to all users in the board.`,
                },
            ];

            // Adding possibly given initialPrivateGroupIds.
            if (initialPrivateGroupIds && initialPrivateGroupIds.length) {
                for (const initialPrivateGroupId of initialPrivateGroupIds) {
                    $scope.data.selectedPrivateGroups.push(projectManager.groupsMap[initialPrivateGroupId]);
                }
            }
        });
    };

    $scope.filterPrivateGroups = function (query) {
        let getGroupsPromise = $q.resolve(groups);

        // If we haven't requested the groups from the server yet, get them now.
        if (!groupsRequested) {
            $scope.data.loadingPrivateGroups = true;
            getGroupsPromise = groupInfoManager
                .getGroups(true)
                .then((groupsData) => {
                    // Save the groups we've got from the server and marked the flag so we won't request them again.
                    groupsRequested = true;

                    if (groupsData && groupsData.length) {
                        // Filter only private groups, and mark not allowed groups.
                        groups = groupsData
                            .filter((group) => group.visibilityType === 'PRIVATE')
                            .map((group) => {
                                if (!$scope.pm.isOwner && !groupPermissions.currentUserIsOwnerMap[group.id]) {
                                    // If the user is not the project owner, nor is he the group's creator - mark the group as not allowed.
                                    group.isNotAllowedToInvite = true;
                                }
                                return group;
                            })
                            .sort((group1, group2) => {
                                // Sort the array, so that groups which the user didn't create are last.
                                if (!group1.isNotAllowedToInvite && group2.isNotAllowedToInvite) {
                                    return -1;
                                }
                                if (group1.isNotAllowedToInvite && !group2.isNotAllowedToInvite) {
                                    return 1;
                                }
                                return 0;
                            });
                    } else {
                        groups = [];
                    }

                    return groups;
                })
                .finally(() => {
                    $scope.data.loadingPrivateGroups = false;
                });
        }

        return getGroupsPromise.then((groupsData) => {
            // Filter the searched groups if we have groups and a query.
            if (groupsData && groupsData.length) {
                const queryLowerCase = query.toLowerCase();
                return groupsData.filter((group) => group.name.toLowerCase().includes(queryLowerCase));
            }

            // No groups data, just return the dummy object.
            return [dummyGroupPlaceholder];
        });
    };

    /**
     * Checks if the given selected tag (in tags-input) is not a dummy tag or a group not created by the user.
     * @param tag - the selected tag.
     * @returns {boolean} - true allow selection. False to prevent selection.
     */
    $scope.isGroupAllowed = function (tag) {
        return !tag.isDummyPlaceholder && !tag.isNotAllowedToInvite;
    };

    /**
     * Checks if the tag is the "All public lists" placeholder tag, and prevents its deletion.
     * The tags-input expects a boolean response, so returning false for it does the job,
     * while still returning true for other tags.
     * @returns {boolean}
     */
    $scope.isNotAllPublicListsPlaceholder = function (tag) {
        return !tag.publicPlaceholder;
    };

    $scope.cancel = function () {
        analyticsWrapper.track('Cancel', { category: 'Invite' });
        $uibModalInstance.dismiss('cancel');
    };

    $scope.onCustomMessageChange = function () {
        const tonkeanOnlyLinksFindingPattern = /\b(?:https?:\/\/(?!.*\btonkean\.com\b)|www\.(?!.*\btonkean\.com\b))\S+/;
        if (tonkeanOnlyLinksFindingPattern.test($scope.data.customMessageText)) {
            $scope.data.submitDisabled = true;
            $scope.inviteError =
                'For security reasons non-Tonkean links may not be included in the body of the message';
        } else {
            $scope.data.submitDisabled = false;
            $scope.inviteError = null;
        }
    };

    $scope.submit = function () {
        // If in invite mode and there are no invitees, the button needs to close the modal.
        if (!$scope.isIntro && !$scope.invitees.length) {
            $scope.cancel();
            return;
        }

        // Invite or intro mode with invitees set.
        analyticsWrapper.track('Invite', { category: 'Invite', label: $scope.invitees.length });

        if ($scope.project) {
            $scope.inviting = true;
            $scope.inviteError = null;

            // Prepare the message to send.
            let message = angular.copy($scope.data.messageText);
            // If we are in invite mode and the user has set a custom message.
            if (!isIntro && $scope.data.customMessageText) {
                message = $scope.data.customMessageText;
            }

            // Add groups to add the invitees to, if set by the user.
            let groupIds = null;
            if ($scope.data.selectedPrivateGroups && $scope.data.selectedPrivateGroups.length > 1) {
                groupIds = $scope.data.selectedPrivateGroups
                    .filter((selectedGroup) => selectedGroup.id)
                    .map((selectedGroup) => selectedGroup.id);
            }

            inviteManager
                .sendInvites($scope.project.id, $scope.invitees, message, true, groupIds, true)
                .then(function (data) {
                    // Take the fresh users returned from the server and update the given initial users.
                    if (initialPeople && initialPeople.length && data && data.invites) {
                        /* jshint loopfunc:true */
                        for (let i = 0; i < data.invites.length; i++) {
                            const invite = data.invites[i];
                            // Replace each initial person according to email (that's the only thing we really know about him).
                            utils.replaceInArrayCompareFields(initialPeople, invite.person, 'email');
                        }
                    }

                    // If we added members to any group, we need to update our groups so we have the updated members.
                    if (groupIds) {
                        groupInfoManager.getGroups(true);
                    }

                    // Callback for after the invite call.
                    if (afterInviteCallback) {
                        afterInviteCallback();
                    }

                    // Close the modal.
                    $uibModalInstance.close();
                })
                .catch(function (error) {
                    $scope.inviting = false;
                    const errorData = error.data ? error.data.error : undefined;
                    let emails = [];
                    if (errorData?.data && errorData.data?.emails) {
                        emails = errorData.data.emails;
                        if (angular.isString(emails)) {
                            emails = emails.split(', ');
                        }
                        $scope.badEmails = $scope.badEmails.concat(emails);
                    }

                    $scope.inviteError = 'Failed to invite people to the project. ';

                    if (
                        error.status === 403 &&
                        (error?.data?.error?.message === 'Invalid emails' ||
                            error?.data?.data?.error?.message === 'Invalid emails')
                    ) {
                        $scope.inviteError += 'Some of the given emails are invalid.';
                    }

                    if (error.status === 403 && error?.data?.data?.error?.message === 'Disabled users') {
                        $scope.inviteError += 'Some of the users are disabled.';
                    }
                });
        }
    };

    $scope.init();
}
angular.module('tonkean.app').controller('InviteCtrl', InviteCtrl);
