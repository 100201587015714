import TimelineWidget from './TimelineWidget';
import type { ItemInterfaceWidgetPackage } from '../../../../WidgetModule';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';

const TimelineWidgetPackage: ItemInterfaceWidgetPackage<ItemInterfaceWidgetConfiguration> = {
    displayComponent: TimelineWidget,
};

export default TimelineWidgetPackage;
