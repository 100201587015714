<div class="communication-source-page org-content-page">
    <tnk-integration-group
        ng-repeat="communicationIntegrationName in data.communicationIntegrations"
        sources="[communicationIntegrationName]"
        state="state.integrationState"
        integration-changed="onIntegrationSaved()"
        current-integration="currentIntegration"
        ng-disabled="creatingProject || (cis.anyIntegrationsConnected() && !cis.isIntegrationConnected(communicationIntegrationName))"
        ng-disabled-tooltip="Can't connect {{ communicationIntegrationName | capitalize }} while {{
            cis.getFirstIntegration().displayName
        }} is connected"
        ng-model="state.integrations.communication"
        is-communication="true"
    ></tnk-integration-group>
</div>
