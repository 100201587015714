import { useAngularService } from 'angulareact';
import React, { useEffect } from 'react';

import type { IDPLoginData } from '@tonkean/tonkean-entities';
import utils, { debouncer } from '@tonkean/utils';

interface Props {
    values: any;
    useManual: boolean;
    setPingResult: (result: Promise<IDPLoginData>) => void;
    setEmailValid: (valid: boolean) => void;
    isEmailValid: boolean;
}

const pingDebouncer = debouncer(1000);

/**
 * A component in charge of fetching the login info required to perform PingIdentity SSO authorization.
 * This is copied from "OktaLoginFetcher".
 * probably can do some refactor to re-use dup code
 */
const PingLoginFetcher: React.FC<Props> = ({ values, useManual, setPingResult, setEmailValid, isEmailValid }) => {
    const tonkeanService = useAngularService('tonkeanService');

    useEffect(() => {
        if (!useManual && utils.isValidEmail(values['email'])) {
            setEmailValid(true);
        }
    }, [setEmailValid, useManual, values]);

    useEffect(() => {
        const oktaUri = useManual ? `https://${values['selectedPingDomain']}/${values['pingEnvironmentId']}` : '';
        const domain = !useManual && isEmailValid ? utils.getCompanyEmailDomain(values['email']) : '';

        pingDebouncer(
            () =>
                (!!domain || !!oktaUri) &&
                setPingResult(tonkeanService.getIDPLoginInfo('ping_identity', oktaUri, domain)),
        );
    }, [isEmailValid, setPingResult, tonkeanService, useManual, values]);

    return <></>;
};

export default PingLoginFetcher;
