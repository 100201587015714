import React, { useContext, useMemo, useState } from 'react';

import RichTextEditorHeaderActions from './RichTextEditorHeaderActions';
import RichTextEditorHeaderTitle from './RichTextEditorHeaderTitle';
import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';
import RichTextEditorInputWidgetView from './RichTextEditorInputWidgetView';
import { ItemInterfacePermission, ItemWidgetContext } from '../../entities';
import { type ItemWidgetProps, ItemWidgetSidepaneTriggerZone, ItemWidget } from '../../WidgetModule';

import { HTMLEditorFullPluginsList, HTMLEditorToolbarSeparatorPlugin } from '@tonkean/editor';
import {
    TonkeanExpressionEditorContext,
    type TonkeanExpressionEditorContextType,
    useItemInterfaceContext,
} from '@tonkean/infrastructure';

const htmlEditorPlugins = [HTMLEditorToolbarSeparatorPlugin, ...HTMLEditorFullPluginsList];

const RichTextEditorInputWidget: React.FC<ItemWidgetProps<RichTextEditorInputWidgetConfiguration>> = ({
    widget,
    permission,
    workflowVersionId,
}) => {
    const { initiative, itemInterface, workflowVersion } = useItemInterfaceContext();
    const widgetContext = useContext(ItemWidgetContext);
    const contextValue: TonkeanExpressionEditorContextType = useMemo(() => {
        return {
            initiative,
            workflowVersion,
        };
    }, [initiative, workflowVersion]);

    const [isEditMode, setIsEditMode] = useState(false);
    const [isEmptyState, setIsEmptyState] = useState(false);
    const isBuilderMode = widgetContext?.isEditable && permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE;
    const displayEditButtonCondition =
        !widget.configuration.isDisabled && !isEditMode && !isEmptyState && !isBuilderMode;
    const onEditClick = () => {
        setIsEditMode(true);
    };

    return (
        <TonkeanExpressionEditorContext.Provider value={contextValue}>
            <ItemWidget
                sidepaneTriggerZone={ItemWidgetSidepaneTriggerZone.HEADER}
                permission={permission}
                containerOverflowOverride="visible"
                itemWidgetBodyHeight={widget.configuration.height || undefined}
                headerTitle={<RichTextEditorHeaderTitle widget={widget} />}
                headerActions={
                    <>
                        {displayEditButtonCondition && (
                            <RichTextEditorHeaderActions itemInterface={itemInterface} onEditClick={onEditClick} />
                        )}
                    </>
                }
                noBackgroundBody={isEmptyState && !isBuilderMode}
                growBody
                noPaddingBody
                disableMinHeight
            >
                <RichTextEditorInputWidgetView
                    widget={widget}
                    permission={permission}
                    editorPlugins={htmlEditorPlugins}
                    workflowVersionId={workflowVersionId}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    setIsEmptyState={setIsEmptyState}
                />
            </ItemWidget>
        </TonkeanExpressionEditorContext.Provider>
    );
};

export default RichTextEditorInputWidget;
