import styled, { css } from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const SQLDatabaseQueryContainer = styled.div<{ expanded: boolean }>`
    background: #ffffff;
    border: 1px solid ${Theme.colors.gray_300};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    padding-left: 14px;
    padding-right: 14px;
    padding-top: 11px;
    padding-bottom: 11px;

    margin-bottom: 20px;

    ${({ expanded }) =>
        expanded &&
        css`
            border-top: 3px solid #2f84dc;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
        `}

    ${({ expanded }) =>
        !expanded &&
        css`
            cursor: pointer;
        `}
`;
export default SQLDatabaseQueryContainer;
