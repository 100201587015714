import { useCallback, useEffect, useState } from 'react';

import useTimeout from './useTimeout';

/**
 * A hook to use ephemeral (or temporary) values in state.
 * @param initialState the initial value to store. Can be a value or a function to calculate the value.
 * @param timeout the timeout to wait before resetting the value to the initial state.
 *
 * @returns an array with the first entry being the value, and the second a function to set the value. Same as `useState`.
 */
function useEphemeralState<TState>(initialState: TState | (() => TState), timeout: number = 10_000) {
    const [state, setState] = useState<TState>(initialState);

    const restoreState = useCallback(() => {
        if (state !== initialState) {
            setState(initialState);
        }
    }, [initialState, state]);

    const [trigger, cancel] = useTimeout(restoreState, timeout);

    useEffect(() => {
        if (state !== initialState) {
            trigger();
        }

        return cancel;
    }, [cancel, initialState, state, trigger]);

    return [state, setState] as const;
}

export default useEphemeralState;
