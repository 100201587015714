import React from 'react';
import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const Label = styled.div`
    color: ${Theme.colors.gray_700};
`;
const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ProjectIntegrationCollectStatusCollectRequestSent: React.FC = () => {
    return (
        <LabelWrapper>
            <Label>A request for collecting data was sent</Label>
        </LabelWrapper>
    );
};

export default ProjectIntegrationCollectStatusCollectRequestSent;
