import React, { useMemo } from 'react';

import type { ContractGroupSummary } from '../entities';
import { ReactComponent as GroupIcon } from '../icons/contract-group.svg';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { InputComponentSizes } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY } from '@tonkean/utils';

export interface ContractGroupSelectorProps {
    groups: ContractGroupSummary[] | undefined;
    loading: boolean;
    selectedContract?: TonkeanId<TonkeanType.GROUP>;
    onChange?: (contractId: TonkeanId<TonkeanType.GROUP> | undefined) => void | undefined;
    name?: string;
    size?: InputComponentSizes;
    disabled?: boolean;
}

const ContractGroupSelector: React.FC<ContractGroupSelectorProps> = ({
    groups,
    loading,
    selectedContract,
    onChange,
    name,
    disabled,
    size,
}) => {
    const options = useMemo(() => {
        return (
            groups?.map((group) => ({
                label: group.displayName,
                value: group.id,
                icon: <GroupIcon />,
            })) || EMPTY_ARRAY
        );
    }, [groups]);

    return (
        <SimpleSelect
            isDisabled={loading || disabled}
            placeholder="No Group"
            isLoading={loading}
            options={options}
            size={size}
            value={selectedContract}
            onChange={onChange}
            name={name}
            isClearable
        />
    );
};

export default ContractGroupSelector;
