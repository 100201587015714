import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addEndSequenceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addEndSequenceBlockCreateAfterOption';
import addGoToSequenceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addGoToSequenceBlockCreateAfterOption';
import addShowInterfaceBlockCreateAfterOption from '../sharedConfigComponents/form/createAfterOptions/addShowInterfaceBlockCreateAfterOption';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const AutonomousInterfaceSubmittedLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.AUTONOMOUS,
    secondaryType: LogicBlockConfigType.AUTONOMOUS_INTERFACE_SUBMITTED,
    iconClass: 'mod-trigger',
    secondaryIconClass: 'icon-interface-submitted',
    title: 'Interface Submitted',
    description: 'When Interface submitted',
    longDescription:
        'This trigger will run when a new item is created by a Tonkean interface, as long as it matches the condition below',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    doNotDisplayStatusEdit: false,
    doNotDisplayNotificationsEdit: true,
    doesntHaveDefinition: true,
    cantDrag: true,
    createAfterOptions: () => [
        addShowInterfaceBlockCreateAfterOption,
        addGoToSequenceBlockCreateAfterOption,
        addEndSequenceBlockCreateAfterOption,
    ],
    createAfterOptionsTitle: 'Sequence Actions',
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (!definition?.itemInterfaceId) {
            validationObject.interfaceNotSelectedError = 'An interface need to be selected.';
        }
        if (definition?.setInitialValues) {
            const invalidFieldsToUpdate = definition?.fieldsToUpdate.some((field) => !field?.fieldId);
            if (invalidFieldsToUpdate) {
                validationObject.fieldNotSelectedError = 'Must select a field to update and a value.';
            }
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default AutonomousInterfaceSubmittedLogicBlockConfig;
