import { paramCase } from 'param-case';
import React from 'react';
import styled from 'styled-components';

import EnterpriseComponentTabHeader from '../../EnterpriseComponentsModule/components/EnterpriseComponentPageLayout/EnterpriseComponentTabHeader';
import SharedTemplatesPage from '../../SharedTemplatesModule/SharedTemplatesPage';
import TrainingSetsPage from '../../TrainingSetsModule/modules/TrainingSetsModule/components/TrainingSetsPage';

import { angularToReact } from '@tonkean/angular-components';
import { defaultLazyFallback } from '@tonkean/angular-components';
import { KnowledgeBase } from '@tonkean/constants';
import type { EnterpriseComponentType } from '@tonkean/tonkean-entities';
import { TonkeanType } from '@tonkean/tonkean-entities';

const PeopleDirectoryTab = React.lazy(
    () => import('../../PeopleDirectoryModule/components/PeopleDirectoryTab/PeopleDirectoryTab'),
);

const TabHeaderContainer = styled.div`
    margin-top: 30px;
`;

const TnkEditIntegrations = angularToReact('tnk-edit-integrations-state');
const TnkCommunicationSources = angularToReact('tnk-product-settings-state');

interface Configuration {
    enterpriseComponentType?: EnterpriseComponentType;
    component: React.ComponentType;
    tabName: string;

    /**
     * This will be used in the url. If not supplied, will use dash-case with the tab name.
     */
    tabKey?: string;
}

function getEnterpriseComponentsPageConfiguration(
    showPeopleDirectoryTab: boolean,
    showTrainingSetsTab: boolean,
    showReusableComponentsTab: boolean,
) {
    const configurations = [
        {
            enterpriseComponentType: TonkeanType.PROJECT_INTEGRATION,
            component: () => (
                <TabHeaderContainer>
                    <EnterpriseComponentTabHeader
                        headerText="Data Sources"
                        subHeaderText={`Connect to external data sources to use within your solutions and module business logic. Data source integrations are bi directional connections that empower you to retrieve and modify data from systems such as your CRM, Databases, Speadsheets, Email inboxes and other cloud applications. `}
                        knowledgeBaseUrl={KnowledgeBase.pages.dataSources || ''}
                    />
                    <TnkEditIntegrations />
                </TabHeaderContainer>
            ),
            tabName: 'Data Sources',
        },
        {
            component: () => (
                <TabHeaderContainer>
                    <EnterpriseComponentTabHeader
                        headerText="Communication Tools"
                        subHeaderText={`Communication tools are leveraged in "people coordination" actions defined in the business logic of your modules and Tonkean auto check-in capabilities 
                        to reach out to your team and collect the relevant information about the items you track. `}
                        knowledgeBaseUrl={KnowledgeBase.pages.dataSources || ''}
                    />
                    <TnkCommunicationSources />
                </TabHeaderContainer>
            ),
            tabName: 'Communication Tools',
            tabKey: 'communication-sources',
        },
        showPeopleDirectoryTab && {
            enterpriseComponentType: TonkeanType.PEOPLE_DIRECTORY,
            component: () => (
                <React.Suspense fallback={defaultLazyFallback}>
                    <PeopleDirectoryTab />
                </React.Suspense>
            ),
            tabName: 'Business Groups',
        },
        showTrainingSetsTab && {
            enterpriseComponentType: TonkeanType.TRAINING_SET,
            component: TrainingSetsPage,
            tabName: 'Training Sets',
        },
        showReusableComponentsTab && {
            component: () => (
                <TabHeaderContainer>
                    <EnterpriseComponentTabHeader
                        headerText="Reusable Components"
                        subHeaderText={`Reusable components are tonkean assets such as Solutions, Modules, Data sources and Workspace Apps that are templatized and can be leveraged by other team members in your organization. `}
                        knowledgeBaseUrl={KnowledgeBase.pages.library || ''}
                    />
                    <SharedTemplatesPage />
                </TabHeaderContainer>
            ),
            tabName: 'Reusable Components',
        },
    ].filter(Boolean) as Configuration[];

    return configurations.map((configuration) => {
        return {
            ...configuration,
            tabKey: configuration.tabKey ?? paramCase(configuration.tabName),
        };
    });
}

export default getEnterpriseComponentsPageConfiguration;

export function getEnterpriseComponentTabKey(enterpriseComponent: EnterpriseComponentType) {
    const configurations = getEnterpriseComponentsPageConfiguration(true, true, true);

    return configurations.find((configuration) => configuration.enterpriseComponentType === enterpriseComponent)
        ?.tabKey;
}
