import { useAngularService } from 'angulareact';
import React from 'react';
import styled from 'styled-components';

import useGetFieldInstance from './useGetFieldInstance';

import { IconSvg } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { LineChartUpIcon } from '@tonkean/svg';
import { FieldType } from '@tonkean/tonkean-entities';
import type { FieldDefinition, Initiative, TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const IconWrapper = styled(Clickable)`
    padding: 1px;
    line-height: 0;
    border-radius: 2px;

    background: #e3ecf7;
    ${colorSvg('#2f84dc')}
    &:hover {
        background: #2f84dc;
        ${colorSvg(Theme.colors.white)}
    }
`;

interface Props extends StyledComponentsSupportProps {
    groupId: TonkeanId<TonkeanType.GROUP>;
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    fieldDefinition: FieldDefinition;
    showEmptyFieldWhenNoInitiative: boolean;
}

const FieldOpenFullscreenButton: React.FC<Props> = ({
    fieldDefinition,
    showEmptyFieldWhenNoInitiative,
    groupId,
    workflowVersion,
    initiative,
}) => {
    const modal = useAngularService('modal');

    const fieldInstance = useGetFieldInstance(
        groupId,
        fieldDefinition,
        initiative,
        workflowVersion,
        showEmptyFieldWhenNoInitiative,
    );

    if (fieldDefinition.fieldType !== FieldType.Number || !fieldInstance || !fieldInstance.id) {
        return null;
    }

    return (
        <Tooltip content="Show Analytics">
            <IconWrapper
                className="field-open-fs-btn"
                onClick={() => modal.openFieldViewModal({ id: fieldInstance.id, fieldDefinition }, null, null)}
            >
                <IconSvg as={LineChartUpIcon} size={14} />
            </IconWrapper>
        </Tooltip>
    );
};

export default FieldOpenFullscreenButton;
