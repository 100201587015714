import HistoryModal from './HistoryModal';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkHistoryModal',
        reactToAngular(HistoryModal, [
            'show',
            'group',
            'environment',
            'workerRunInfo',
            'workerRunStage',
            'inspect',
            'workerRunLogicId',
            'tab',
            'defaultFilterSearchTerm',
        ]),
    );
