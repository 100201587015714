import type EnterpriseComponentOverviewStepDefinition from '../../EnterpriseComponentsModule/entities/overview/EnterpriseComponentOverviewStepDefinition';

import type { EnterpriseComponentOverviewResult } from '@tonkean/tonkean-entities';

function getProjectIntegrationOverviewStepDefinitions(
    result: EnterpriseComponentOverviewResult,
): EnterpriseComponentOverviewStepDefinition[] {
    return [];
}

export default getProjectIntegrationOverviewStepDefinitions;
