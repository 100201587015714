/**
 * Helps with paging
 */
function RequestPager() {
    const _this = this;

    /**
     * Get all entities from the request method.
     * @param requestMethod - Method that will make the request for each page (should return a promise).
     * The request method can have as many params as necessary but it MUST have limit and skip as the last params (in that order)
     * @param params - An array with all the parameters required for the request.
     * Should not include limit or skip. Should include ALL params until limit and skip even if the values are null.
     * @param getEntitiesMethod - How to retrieve the result entities from the response
     * @param getTargetResultCountMethod - How to retrieve the total count (of all entities from all pages)
     * @returns {Promise<*[]>}
     */
    _this.getAllPages = async function (requestMethod, params, getEntitiesMethod, getTargetResultCountMethod) {
        const limit = 10;
        const results = [];

        let continuePaging = false;
        do {
            // It's ok to disable eslint rule here because we don't know the number of pages we need in advanced, so we cant use Promise.all
            // eslint-disable-next-line no-await-in-loop
            const requestResults = await requestMethod(...params, limit, results.length);
            results.push(...getEntitiesMethod(requestResults));

            continuePaging = results.length < getTargetResultCountMethod(requestResults);
        } while (continuePaging);

        return results;
    };
}

export default angular.module('tonkean.app').service('requestPager', RequestPager);
