import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function IntegrationSmartsheetFieldCtrl($scope, projectManager, createProjectApis, utils) {
    const ctrl = this;

    $scope.data = {
        // Component bindings
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        targetType: ctrl.targetType,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
        existingFieldDefinition: ctrl.existingFieldDefinition,

        fieldDefinitionName: ctrl.fieldDefinitionName,
        fieldDefinitionNameEdited: ctrl.fieldDefinitionNameEdited,

        sheet: null,
        filter: null,
        column: null,
        rowNumber: null,

        smartsheetStep: null,
        availableSheets: null,
        availableColumns: null,

        showSmartsheetFieldSetup: true,
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        $scope.loadExternalSheets(true);
        $scope.data.smartsheetStep = 'selectSheet';

        if (!$scope.data.createMode && $scope.data.existingFieldDefinition.definition) {
            initializeEditMode();
        }

        // It is important to fire the event to get the containing controller to have the updated data.
        definitionChanged();
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function () {};

    $scope.toggleShowSmartsheetFieldSetup = function () {
        $scope.data.showSmartsheetFieldSetup = !$scope.data.showSmartsheetFieldSetup;
    };

    /**
     * Occurs once the user changes its row number selection.
     */
    $scope.onRowNumberChanged = function () {
        definitionChanged();
    };

    /**
     * Occurs once a sheet is selected.
     */
    $scope.onSheetSelected = function (sheet) {
        $scope.data.sheet = sheet;
        $scope.loadExternalColumns(false);
        $scope.data.smartsheetStep = 'selectFields';

        definitionChanged();
    };

    $scope.loadExternalSheets = function (shouldReset) {
        // we don't want to delete the users value when he is editing an already existing external field
        if (shouldReset) {
            $scope.data.sheet = null;
        }

        $scope.data.availableSheets = null;
        $scope.loadingExternal = true;

        const integrationId = $scope.data.projectIntegration.integration.id;

        if (!integrationId) {
            $scope.loadingExternal = false;
            return;
        }

        const projectId = projectManager.project.id;

        createProjectApis.getAutoCompleteOptions(projectId, integrationId, 'sheets').then(function (result) {
            $scope.data.availableSheets = result.options;
            $scope.loadingExternal = false;
        });
    };

    /**
     * Loads external smartsheet coluumns.
     */
    $scope.loadExternalColumns = function (shouldReset) {
        // we don't want to delete the users value when he is editing an already existing external field
        if (shouldReset) {
            $scope.data.column = null;
            $scope.data.filter = null;
        }

        $scope.data.availableColumns = null;
        $scope.loadingExternal = true;

        const integrationId = $scope.data.projectIntegration.integration.id;

        if (!integrationId) {
            $scope.loadingExternal = false;
            return;
        }
        const projectId = projectManager.project.id;

        if ($scope.data.sheet) {
            const sheetId = $scope.data.sheet.value;
            createProjectApis
                .getAutoCompleteOptions(projectId, integrationId, 'columns', {
                    sheetId,
                })
                .then(function (result) {
                    $scope.data.availableColumns = result.options;
                    $scope.loadingExternal = false;
                });
        }
    };

    /**
     * Occurs once column is selected.
     */
    $scope.onColumnSelected = function () {
        // Changing the name to the selected field label.
        if (ctrl.onFieldDefinitionNameChange && !$scope.data.fieldDefinitionNameEdited) {
            ctrl.onFieldDefinitionNameChange({ fieldDefinitionName: $scope.data.column.displayName });
        }

        definitionChanged();
    };

    /**
     * Occurs once filter is selected.
     */
    $scope.onFilterSelected = function () {
        definitionChanged();
    };

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged() {
        const validDefinition = !!(
            $scope.data.sheet &&
            $scope.data.column &&
            ($scope.data.rowNumber || $scope.data.filter)
        );

        const definitionObject = {
            fieldConfigurationSummaryTitle: 'Smartsheet',
            fieldConfigurationSummarySubTitle: null,
            validDefinition,
            definition: {
                sheet: $scope.data.sheet ? $scope.data.sheet.value : null,
                sheetName: $scope.data.sheet ? $scope.data.sheet.displayName : null,
                filter: $scope.data.filter ? $scope.data.filter.value : null,
                filterName: $scope.data.filter ? $scope.data.filter.displayName : null,
                column: $scope.data.column ? $scope.data.column.value : null,
                columnName: $scope.data.column ? $scope.data.column.displayName : null,
                rowNumber: $scope.data.rowNumber,
            },
        };

        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({
                newDefinition: definitionObject,
                doNotReloadPreview: !validDefinition,
            });
        }
    }

    /**
     * Initializes elastic edit mode.
     */
    function initializeEditMode() {
        $scope.data.showSmartsheetFieldSetup = !$scope.data.existingFieldDefinition.definition.sheet;

        if ($scope.data.existingFieldDefinition.definition.sheet) {
            $scope.data.sheet = {
                value: $scope.data.existingFieldDefinition.definition.sheet,
                displayName: $scope.data.existingFieldDefinition.definition.sheetName,
            };

            $scope.loadExternalColumns(false);
            $scope.data.smartsheetStep = 'selectFields';
        } else {
            $scope.data.smartsheetStep = 'selectSheet';
        }

        if ($scope.data.existingFieldDefinition.definition.filter) {
            $scope.data.filter = {
                value: $scope.data.existingFieldDefinition.definition.filter,
                displayName: $scope.data.existingFieldDefinition.definition.filterName,
            };
        }

        if (!utils.isNullOrUndefined($scope.data.existingFieldDefinition.definition.rowNumber)) {
            $scope.data.rowNumber = $scope.data.existingFieldDefinition.definition.rowNumber;
        }

        if ($scope.data.existingFieldDefinition.definition.column) {
            $scope.data.column = {
                value: $scope.data.existingFieldDefinition.definition.column,
                displayName: $scope.data.existingFieldDefinition.definition.columnName,
            };
        }
    }
}
export default angular
    .module('tonkean.app')
    .controller('IntegrationSmartsheetFieldCtrl', lateConstructController(IntegrationSmartsheetFieldCtrl));
