import type HistoryTableGroup from './HistoryTableGroup';
import type HistoryField from '../entities/HistoryField';

/**
 * This will return a function that accepts a tableField and will add it to the
 * relevant history table group by mutating the array. It can be used in a forEach.
 *
 * @param formulaFieldsGroup - the formulas group.
 * @param specialFieldsGroup - the special fields group.
 * @param customFieldsGroup - the regular fields group.
 * @param matchedEntityToFieldsGroupMap - a map of matched entity id to it's group.
 * @returns a function that accepts a table field.
 */
function addHistoryFieldToItsGroup(
    formulaFieldsGroup: HistoryTableGroup,
    specialFieldsGroup: HistoryTableGroup,
    customFieldsGroup: HistoryTableGroup,
    matchedEntityToFieldsGroupMap: Record<string, HistoryTableGroup>,
) {
    return (tableField: HistoryField) => {
        const { relationFieldId, projectIntegrationId, isFormulaField, isSpecialField } = tableField;

        // Is this field is in a matched entity?
        if (relationFieldId || projectIntegrationId) {
            const matchedByRelationFieldId = relationFieldId && matchedEntityToFieldsGroupMap[relationFieldId];
            const matchedByProjectIntegrationId =
                projectIntegrationId && matchedEntityToFieldsGroupMap[projectIntegrationId];

            const matchedEntityFieldsGroup = matchedByRelationFieldId || matchedByProjectIntegrationId;

            // If the relationFieldId of projectIntegrationId is in the map, it means that this field is in a
            // matched entity. If not, it means that it's a regular custom field, probably of type data source.
            if (matchedEntityFieldsGroup) {
                matchedEntityFieldsGroup.fields.push(tableField);
                return;
            }
        }

        if (isFormulaField) {
            formulaFieldsGroup.fields.push(tableField);
            return;
        }

        if (isSpecialField) {
            specialFieldsGroup.fields.push(tableField);
            return;
        }

        customFieldsGroup.fields.push(tableField);
    };
}

export default addHistoryFieldToItsGroup;
