import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function SendEmailRecipientsCtrl($scope, tonkeanUtils) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        workflowVersionId: ctrl.workflowVersionId,
        identifier: ctrl.identifier,
        validationObject: ctrl.validationObject,

        // Saved configurations
        savedRecipientsConfiguration: ctrl.recipientsConfiguration,
        savedBCCConfiguration: ctrl.bccConfiguration,
        savedCCConfiguration: ctrl.ccConfiguration,

        showCC: !!ctrl.ccConfiguration,
        showBCC: !!ctrl.bccConfiguration,

        loading: false,
    };

    ctrl.$onInit = function () {
        $scope.data.loading = true;

        if (
            $scope.data.configuredLogic &&
            (!!ctrl.toRecipientsArray || !!ctrl.ccRecipientsArray || !!ctrl.bccRecipientsArray)
        ) {
            handleArrayBackwardsCompatibility();
        }

        $scope.data.loading = false;
    };

    /**
     * Occurs once recipients configuration changed.
     */
    $scope.onRecipientsConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        ctrl.onRecipientsConfigurationChanged({
            configuration: tonkeanUtils.buildPersonSelectionConfiguration(
                personSelectionConfiguration,
                'specificPeopleIds',
            ),
            shouldSaveLogic,
        });
    };

    /**
     * Occurs once CC configuration changed.
     */
    $scope.onCCConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        ctrl.onCCConfigurationChanged({
            configuration: tonkeanUtils.buildPersonSelectionConfiguration(
                personSelectionConfiguration,
                'specificPeopleIds',
            ),
            shouldSaveLogic,
        });
    };

    /**
     * Occurs once BCC configuration changed.
     */
    $scope.onBCCConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        ctrl.onBCCConfigurationChanged({
            configuration: tonkeanUtils.buildPersonSelectionConfiguration(
                personSelectionConfiguration,
                'specificPeopleIds',
            ),
            shouldSaveLogic,
        });
    };

    function createEmailAndName(emailAddress, emailName) {
        let finalEmailName = '';

        if (emailName) {
            finalEmailName = `(${emailName})`;
        }

        return emailAddress + finalEmailName;
    }

    function convertRecipientArrayToConfiguration(recipientsArray) {
        const emailAndNamesOriginalExpressionArray = [];
        const emailAndNamesEvaluatedExpressionArray = [];

        for (const singleRecipient of recipientsArray) {
            emailAndNamesOriginalExpressionArray.push(
                createEmailAndName(singleRecipient.emailAddress, singleRecipient.emailName),
            );
            emailAndNamesEvaluatedExpressionArray.push(
                createEmailAndName(singleRecipient.emailAddressEvaluatedText, singleRecipient.emailNameEvaluatedText),
            );
        }
        if (emailAndNamesEvaluatedExpressionArray.length && emailAndNamesOriginalExpressionArray.length) {
            return {
                failIfNoValue: true,
                forceEmail: true,
                initiativeOwner: false,
                personEmailExpressionDefinition: {
                    originalExpression: emailAndNamesOriginalExpressionArray.join(','),
                    evaluatedExpression: emailAndNamesEvaluatedExpressionArray.join(','),
                },
                previousActor: false,
                specificPeopleIds: [],
                useNone: false,
            };
        }

        return null;
    }

    function handleArrayBackwardsCompatibility() {
        const toRecipientsArray = ctrl.toRecipientsArray;
        const ccRecipientsArray = ctrl.ccRecipientsArray;
        const bccRecipientsArray = ctrl.bccRecipientsArray;

        if (toRecipientsArray && toRecipientsArray.length) {
            $scope.data.savedRecipientsConfiguration = convertRecipientArrayToConfiguration(toRecipientsArray);

            ctrl.onRecipientsConfigurationChanged({
                configuration: $scope.data.savedRecipientsConfiguration,
                shouldSaveLogic: true,
                array: [],
            });
        }

        if (ccRecipientsArray && ccRecipientsArray.length) {
            $scope.data.savedCCConfiguration = convertRecipientArrayToConfiguration(ccRecipientsArray);

            ctrl.onCCConfigurationChanged({
                configuration: $scope.data.savedCCConfiguration,
                shouldSaveLogic: true,
                array: [],
            });
        }

        if (bccRecipientsArray && bccRecipientsArray.length) {
            $scope.data.savedBCCConfiguration = convertRecipientArrayToConfiguration(bccRecipientsArray);

            ctrl.onBCCConfigurationChanged({
                configuration: $scope.data.savedBCCConfiguration,
                shouldSaveLogic: true,
                array: [],
            });
        }

        $scope.data.showCC = !!$scope.data.savedCCConfiguration;
        $scope.data.showBCC = !!$scope.data.savedBCCConfiguration;
    }
}

export default angular.module('tonkean.app').controller('SendEmailRecipientsCtrl', lateConstructController(SendEmailRecipientsCtrl));
