<div class="padding-normal common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn padding-normal-sm" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- title -->
    <h1 class="common-size-l common-bold margin-bottom-lg mod-no-top text-center">{{ educationItem.title }}</h1>
    <p class="common-color-grey common-size-xxs text-center">{{ educationItem.subtext }}</p>

    <!--Video-->
    <div ng-if="educationItem.videoId">
        <iframe
            width="100%"
            height="315"
            ng-src="{{ videoUrl }}"
            frameborder="0"
            gesture="media"
            allow="encrypted-media"
            allowfullscreen
        ></iframe>
    </div>

    <!-- info -->
    <p light-markdown="educationItem.description" class="margin-top"></p>
    <p>
        <a href="{{ educationItem.kbUrl }}" target="_blank">Learn more...</a>
    </p>

    <!--CTAs-->
    <!--<hr/>-->
    <div class="text-center" ng-if="as.currentUser && as.currentUser.isUser && pm.project && group">
        <button
            class="btn btn-primary btn-lg"
            ng-repeat="cta in educationItem.modalsCta"
            ng-class="{ 'margin-right': !$last }"
            ng-click="doItemAction(cta)"
        >
            {{ cta.title }}
        </button>
    </div>
</div>
