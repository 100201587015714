<div ng-switch="data.currentStep.data.template">
    <div ng-switch-when="integrationTypeTemplate">
        <div ng-include="'./configTemplates/integrationTypeTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="setupTopInitiativesTrackingConfigTemplate">
        <div ng-include="'./configTemplates/setupTopInitiativesTrackingConfigTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="setupAccountsTrackingConfigTemplate">
        <div ng-include="'./configTemplates/setupAccountsTrackingConfigTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="setupForecastConfigTemplate">
        <div ng-include="'./configTemplates/setupForecastConfigTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="spreadsheetImportConfigTemplate">
        <div ng-include="'./configTemplates/spreadsheetImportConfigTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="setupWebsiteConversionConfigTemplate">
        <div ng-include="'./configTemplates/setupWebsiteConversionConfigTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="setupProductMilestonesConfigTemplate">
        <div ng-include="'./configTemplates/setupProductMilestonesConfigTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="salesTeamPerformanceConfigTemplate">
        <div ng-include="'./configTemplates/salesTeamPerformanceConfigTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="setupSingleRepConfigTemplate">
        <div ng-include="'./configTemplates/setupSingleRepConfigTemplate.template.html'"></div>
    </div>
    <div ng-switch-when="groupSettingsTemplate">
        <div ng-include="'./configTemplates/groupSettingsTemplate.template.html'"></div>
    </div>
</div>
