import React, { useState } from 'react';
import styled from 'styled-components';

import { Chevron, ChevronDirection, Menu, MenuItem } from '@tonkean/infrastructure';
import type { GroupEnvironmentFormSummary } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';

const ActionsChevron = styled(Chevron)`
    margin-left: 5px;
    svg > path {
        stroke-width: 1;
        fill: none;
    }
`;

const StyledButton = styled(Clickable)`
    display: block;
`;

interface Props {
    workerForms: GroupEnvironmentFormSummary[];
    onFormSelected?: (formId: string) => void;
    viewOnlyMode: boolean;
}

const UpdateFormsActionSelector: React.FC<Props> = ({ workerForms, onFormSelected, viewOnlyMode }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    if (!workerForms?.length) {
        return null;
    }

    return (
        <Menu
            show={menuOpen}
            onClose={() => setMenuOpen(false)}
            menuItems={
                <>
                    {workerForms?.map((entity) => (
                        <MenuItem key={entity.id} onClick={() => onFormSelected?.(entity.id)}>
                            {entity.clientDisplayName || entity.displayName}
                        </MenuItem>
                    ))}
                </>
            }
        >
            <StyledButton onClick={() => setMenuOpen(true)} disabled={viewOnlyMode}>
                Select an action <ActionsChevron direction={menuOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
            </StyledButton>
        </Menu>
    );
};

export default UpdateFormsActionSelector;
