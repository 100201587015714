import React from 'react';
import styled from 'styled-components';

import SingleWorkerRunFlowRunTableRowGrid from './SingleWorkerRunFlowRun/SingleWorkerRunFlowRunTable/SingleWorkerRunFlowRunTableRowGrid';

import { Placeholder } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const ChevronPlaceHolder = styled(Placeholder)<{ depth: number }>`
    margin-right: 8px;
    margin-left: ${({ depth }) => depth * 20}px;
`;

const IconPlaceHolder = styled(Placeholder)`
    margin-right: 8px;
`;

const Center = styled.div`
    display: flex;
    align-items: center;
    padding-left: 0 !important;
`;

const FlowRunTableRowWithBorders = styled(SingleWorkerRunFlowRunTableRowGrid)<{ depth: number; isFirstRow: boolean }>`
    border-top: 1px solid ${({ depth }) => (depth === 1 ? Theme.colors.gray_400 : Theme.colors.gray_300)};
    ${({ isFirstRow }) => isFirstRow && 'border-top: none;'}
`;

const generateDepth = (): number[] => {
    const depths: number[] = [];
    let currentDepth = 1;

    depths.push(currentDepth);

    for (let i = 0; i < 9; i++) {
        const add = Math.floor(Math.random() * 3) - 1;
        currentDepth = currentDepth + add;
        currentDepth = Math.max(currentDepth, 1);
        depths.push(currentDepth);
    }

    return depths;
};

/**
 * Run once outside of the component for consistency.
 * Happens once per session.
 */
const depths = generateDepth();

const WorkerRunTableListLoading: React.FC = () => {
    return (
        <>
            {depths.map((depth, index) => (
                <FlowRunTableRowWithBorders key={`${index}-${depth}`} depth={depth} isFirstRow={index == 0}>
                    <Center>
                        <ChevronPlaceHolder depth={depth} $width="8px" $circle />
                        <IconPlaceHolder $width="14px" $circle />
                        <Placeholder $height={FontSize.MEDIUM_14} $width="150px" />
                    </Center>
                    <Center>
                        <Placeholder $height={FontSize.MEDIUM_14} $width="100px" />
                    </Center>
                </FlowRunTableRowWithBorders>
            ))}
        </>
    );
};

export default WorkerRunTableListLoading;
