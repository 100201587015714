import template from './tnkTagsAtMention.template.html?angularjs';

function tnkTagsAtMention() {
    return {
        restrict: 'E',
        scope: {
            textId: '@',
            resultList: '=',
            noCustom: '=',
            maxTags: '@',
            noValidation: '=',
            excludeMe: '=',
            opened: '&',
            onTagAdded: '&',
        },
        template,
        controller: [
            '$scope',
            'tonkeanService',
            function ($scope, tonkeanService) {
                $scope.selectedItems = [];
                $scope.data = { visible: false };

                $scope.$watch('data.visible', function () {
                    const isOpen = $scope.data.visible;
                    if ($scope.opened) {
                        $scope.opened({ open: isOpen });
                    }
                });

                $scope.searchTags = function (query) {
                    $scope.loadingPeople = true;
                    console.log(query);
                    const limit = 10;
                    return tonkeanService
                        .searchTopics($scope.$root.pm.project.id, query, limit)
                        .then(function (data) {
                            let people = data.tags;
                            if (query.length) {
                                let alreadyExist = $scope.selectedItems.some(function (p) {
                                    return p.name.toLowerCase() === query.toLowerCase();
                                });
                                if (!alreadyExist && people) {
                                    alreadyExist = people.some(function (p) {
                                        return p.name.toLowerCase() === query.toLowerCase();
                                    });
                                }

                                if (!alreadyExist && !$scope.noCustom) {
                                    people = people.slice(0, 7);
                                    people.push({
                                        isNew: true,
                                        name: query,
                                        title: '(new function)',
                                    });
                                }
                            }
                            $scope.selectedItems = people;
                            return $scope.selectedItems;
                        })
                        .catch(function (error) {
                            return [{ error }];
                        })
                        .finally(function () {
                            $scope.loadingPeople = false;
                        });
                };

                $scope.getTextRaw = function (item) {
                    if ($scope.resultList) {
                        $scope.resultList.push(item);
                    }

                    $scope.selectedItems.length = 0;

                    if ($scope.onTagAdded) {
                        $scope.onTagAdded({ item });
                    }
                    if ($scope.opened) {
                        $scope.opened({ open: false });
                    }

                    return `#${item.name.replace(new RegExp(' ', 'g'), '-').toLowerCase()}`;
                };
            },
        ],
    };
}
angular.module('tonkean.app').directive('tnkTagsAtMention', tnkTagsAtMention);
