import TrainingSetSearchMatcherSelector from './TrainingSetSearchMatcherSelector';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkTrainingSetSearchMatcherSelector',
        reactToAngular(TrainingSetSearchMatcherSelector, ['groupId', 'selectedTrainingSetId', 'onItemSelected']),
    );
