import type { ElementBlocker } from '../../../../services/elementBlocker.service';

/**
 * Add this directive to block the element from being edited. Pass true as a string to block, false to enable.
 * To change the text, you should add the attribute `blocked-reason` and pass a string as the new text.
 *  You can also pass the `data-blocked-message` attribute, but you will not have a default text in case the passed argument is empty
 * To use "Move to build environment to edit" as the text, you can use `data-blocked-message-production` as a shortcut.
 *
 * @example
 * <form blocked-element="{{data.formIsBlocked}}" data-blocked-message="you can't edit it">
 *     <input type="text" />
 *     <button type="submit">submit!</button>
 * </form>
 *
 * @param elementBlocker - angularJs service
 */
const blockedElement: angular.IDirectiveFactory = (elementBlocker: ElementBlocker) => ({
    restrict: 'A',
    link(_scope, jQLightElement, attributes) {
        const toggleBlock = () => {
            const shouldBeBlocked = attributes.blockedElement === 'true';

            const element = jQLightElement[0];
            const message = attributes.blockedReason || undefined;

            if (!element) {
                throw new Error('Element to block not found');
            }
            if (shouldBeBlocked) {
                elementBlocker.blockElement(element, message);
            } else {
                elementBlocker.unblockElement(element);
            }
        };

        toggleBlock();
        attributes.$observe('blockedElement', toggleBlock);
    },
});

export default angular.module('tonkean.app').directive('blockedElement', ['elementBlocker', blockedElement]);
