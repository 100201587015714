import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function JiraCustomActionsDefinitionCtrl(
    $scope,
    $q,
    tonkeanUtils,
    tonkeanService,
    customTriggerManager,
    createProjectApis,
    projectManager,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        definition: ctrl.definition,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        validationObject: ctrl.validationObject,
        onDefinitionChanged: ctrl.onDefinitionChanged,
        configuredLogic: ctrl.configuredLogic,
        statusOptions: [],
        users: [],
        selectedStatus: null,
    };

    /**
     * Occurs on init of the component .
     */
    ctrl.$onInit = function () {
        $scope.data.loading = true;

        if ($scope.data.definition.status) {
            $scope.data.selectedStatus = {
                displayName: $scope.data.definition.status,
                value: $scope.data.definition.status,
            };
        }

        if ($scope.data.definition.personSettings) {
            // This part may seem odd, but is necessary.
            // We use the tonkeanUtils.buildPersonSelectionConfiguration function to build the definition we need for
            // the server, and we set the property of the specific people ids to be personIds there.
            // BUT, the component of selection a person wants the property to be called specificPeopleIds, and in order
            // to provide the component the property it needs, we convert the personIds property to the
            // specificPeopleIds property.
            // The reason for this mess originates from the fact that there's a mismatch between PersonDefinition
            // used for notifications (that uses the specificPeopleIds property) and the CustomPersonSettings
            // used for actions (that uses the personIds property).
            if ($scope.data.definition.personSettings.personIds) {
                $scope.data.definition.personSettings.specificPeopleIds =
                    $scope.data.definition.personSettings.personIds;
            }
            $scope.data.savedPersonSelectionConfiguration = $scope.data.definition.personSettings;
        } else if ($scope.data.definition.assigneeId) {
            $scope.data.savedPersonSelectionConfiguration = {
                personSelectionType: 'specificPeople',
                specificPeopleIds: [$scope.data.definition.assigneeId],
            };
        }

        // Get statuses if jira project key exist
        if (
            $scope.data.projectIntegration &&
            $scope.data.projectIntegration.projectData &&
            $scope.data.projectIntegration.projectData.projectDatas &&
            $scope.data.projectIntegration.projectData.projectDatas.length
        ) {
            $scope.getStatuses($scope.data.projectIntegration.projectData.projectDatas);
        }

        $scope.refreshUsers().then(() => ($scope.data.loading = false));
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.definition) {
            $scope.data.definition = changesObj.definition.currentValue;

            // Initialization
            if ($scope.data.configuredLogic && $scope.data.definition) {
                $scope.data.loading = false;
            }
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.commentText = originalExpression;
        $scope.data.definition.evaluatedCommentExpression = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once service desk id tonkean expression has changed.
     */
    $scope.onServiceDeskIdChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        if (!$scope.data.definition.serviceDeskId) {
            $scope.data.definition.serviceDeskId = {};
        }

        $scope.data.definition.serviceDeskId.originalExpression = originalExpression;
        $scope.data.definition.serviceDeskId.evaluatedExpression = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once accounts ids tonkean expression has changed.
     */
    $scope.onAccountsIdsChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        if (!$scope.data.definition.accountsIds) {
            $scope.data.definition.accountsIds = {};
        }

        $scope.data.definition.accountsIds.originalExpression = originalExpression;
        $scope.data.definition.accountsIds.evaluatedExpression = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Get all the relevant statuses for this project
     */
    $scope.getStatuses = function (projectDatas) {
        $scope.data.statusOptions = [];

        $q.all(
            // Get all available statuses of all projects
            projectDatas.map((projectData) =>
                createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.data.projectIntegration.integration.id,
                    'statuses',
                    {
                        project: projectData.project,
                    },
                ),
            ),
        )
            .then((projectsStatuses) => {
                // Merge all statuses from all projects
                $scope.data.statusOptions = [
                    ...new Set(
                        projectsStatuses.flatMap((singleProjectStatuses) =>
                            singleProjectStatuses.options.map((option) => option.displayName),
                        ),
                    ),
                    // Make a distinct list of the statuses
                ].map((status) => ({ displayName: status }));
            })
            .catch((error) => {
                $scope.data.loadingStatusesError = 'Unable to load available Jira statuses';
                console.error(error);

                if (error.status === 403) {
                    $scope.data.loadingStatusesError = 'You are not permitted to view the available Jira statuses';
                }
            });
    };

    /**
     * Get all the relevant users
     */
    $scope.refreshUsers = function (q) {
        return tonkeanService
            .getIntegrationExampleEntities($scope.data.projectIntegration.id, 'User', q && q.length ? q : null)
            .then((data) => {
                if (data.exampleEntities && data.exampleEntities.length) {
                    $scope.data.users = data.exampleEntities.map((exampleEntity) => ({
                        ...exampleEntity,
                        title: exampleEntity.title ?? exampleEntity.originalEntity?.displayName,
                    }));
                }
            });
    };

    /**
     * Save selected status to definition.
     */
    $scope.onStatusSelected = function (selectedItem) {
        let shouldSave = false;

        if (selectedItem && selectedItem.displayName) {
            shouldSave = $scope.data.definition.status !== selectedItem.displayName;
            $scope.data.selectedStatus = selectedItem;
            $scope.data.definition.status = selectedItem.displayName;
        }

        $scope.onActionsChanged(shouldSave);
    };

    /**
     * Save selected user to definition.
     */
    $scope.onPersonSelectionConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        $scope.data.definition.personSettings = tonkeanUtils.buildPersonSelectionConfiguration(
            personSelectionConfiguration,
            'personIds',
        );
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs on update of the actions definition.
     */
    $scope.onActionsChanged = function (shouldSave) {
        if (ctrl.onDefinitionChanged) {
            switch ($scope.data.definition.customActionKey) {
                case 'ADD_ISSUE_COMMENT':
                    break;
            }

            ctrl.onDefinitionChanged({ shouldSave });
        }
    };

    /**
     * File Source Selection
     */
    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (storageIntegrationId, newDocumentName, shouldSaveLogic) {
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.newDocumentName = newDocumentName;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onActionsChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onActionsChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('JiraCustomActionsDefinitionCtrl', lateConstructController(JiraCustomActionsDefinitionCtrl));
