import React from 'react';
import styled from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const EventPlaceholderContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        'avatar eventText'
        'avatar dateText'
        'content content';
    column-gap: 8px;
    row-gap: 8px;
    align-items: center;
    padding: 0 24px;
`;

const AvatarPlaceholder = styled(Placeholder)`
    grid-area: avatar;
`;

const EventTextPlaceholder = styled(Placeholder)`
    grid-area: eventText;
`;

const DateTextPlaceholder = styled(Placeholder)`
    grid-area: dateText;
`;

const Content = styled.div<{ borderLeft: boolean }>`
    grid-area: content;
    margin-left: 14px;
    margin-bottom: 8px;
    padding-bottom: 16px;
    padding-left: 22px;
    border-left: ${({ borderLeft }) => borderLeft && `1px solid ${Theme.colors.gray_400}`};
`;

const TimelineEventsLoadingState: React.FC = () => {
    return (
        <>
            {range(5).map((index, _, array) => (
                <EventPlaceholderContainer key={index}>
                    <AvatarPlaceholder $width="28px" $height="28px" $circle />
                    <EventTextPlaceholder $width="30%" $height="20px" />
                    <DateTextPlaceholder $width="5%" $height="5px" />
                    <Content borderLeft={index !== array.length - 1}>
                        <Placeholder $width="100%" $height="80px" />
                    </Content>
                </EventPlaceholderContainer>
            ))}
        </>
    );
};

export default TimelineEventsLoadingState;
