import { useAngularService } from 'angulareact';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Modal, ModalBody, ModalFooter, Textarea } from '@tonkean/infrastructure';
import { Button } from '@tonkean/tui-buttons/Button';

const CancelButton = styled(Button)`
    margin-right: 5px;
`;

interface Props {
    /**
     * Whether the modal is open.
     */
    isOpen: boolean;

    /**
     * What callback to call when close action is executed.
     */
    onClose(createdIntegrationId?: string): void;
}

const ImportFromCreationJsonModal: React.FC<Props> = ({ isOpen, onClose }) => {
    const [creationJson, setCreationJson] = useState('');
    const tonkeanService = useAngularService('tonkeanService');
    const projectManager = useAngularService('projectManager');

    const createFromCreationJson = () => {
        tonkeanService
            .createEntityFromCreationJson(projectManager.project.id, JSON.parse(creationJson))
            .then((response) => onClose(response?.createdEntitiesIds?.find((singleId) => singleId.startsWith('PRIN'))));
    };

    return (
        <Modal open={isOpen} onClose={onClose} fixedWidth>
            <ModalBody>
                <Textarea
                    id="creation-json-input"
                    value={creationJson}
                    onChange={(event) => setCreationJson(event.target.value)}
                    minRows={5}
                />
            </ModalBody>

            <ModalFooter align="right" border>
                <CancelButton onClick={() => onClose()} cancel>
                    Cancel
                </CancelButton>
                <Button
                    disabled={!creationJson}
                    onClick={() => {
                        createFromCreationJson();
                    }}
                >
                    Create From JSON
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ImportFromCreationJsonModal;
