function CalendarSubscriptionCtrl($scope, environment, $timeout, tonkeanService, groupId, projectManager) {
    $scope.pm = projectManager;
    $scope.groupId = groupId;

    $scope.data = {
        downloading: false,
        downloadUrl: '',
        webCalUrl: '',
        urlFocused: false,
    };

    /**
     * Schedule time modal initialization function.
     */
    $scope.init = function () {
        tonkeanService.createCalendarSubscription($scope.pm.project.id, groupId).then(function (data) {
            if (data && data.url) {
                const path = data.url.slice(Math.max(0, data.url.indexOf('/calendar')));
                const host = `ical.tonkean.com${environment.isProd ? '' : `/${environment.name}`}`;

                $scope.data.downloadUrl = `http://${host}${path}`;
                $scope.data.webCalUrl = `webcal://${host}${path}`;
            }
        });
    };

    $scope.downloadClicked = function () {
        $scope.data.downloading = true;

        $timeout(function () {
            $scope.data.downloading = false;
        }, 10_000);
    };

    /**
     * Dismisses the modal.
     */
    $scope.cancel = function () {
        $scope.$dismiss();
    };

    $scope.urlFocused = function ($event) {
        $event.target.setSelectionRange(0, $event.target.value.length);
        $scope.data.urlFocused = true;
    };

    $scope.urlUnFocused = function ($event) {
        $event.target.setSelectionRange(0, 0);
        $scope.data.urlFocused = false;
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('CalendarSubscriptionCtrl', CalendarSubscriptionCtrl);
