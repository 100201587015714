import React, { useMemo } from 'react';
import styled from 'styled-components';

import FormBuilderPreviewType from '../entities/FormBuilderPreviewType';

import { angularToReact } from '@tonkean/angular-components';
import { useUUID } from '@tonkean/infrastructure';
import type { Form } from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    grid-area: preview;
    padding: 30px;
    overflow-y: auto;

    & > * {
        width: 100%;
    }
`;

const TonkeanPreviewForm = angularToReact<{
    formId: string;
    form: Form;
    groupId: string;
    workflowVersionId: string;
    formType: 'DIALOG' | 'WEBFORM';
    isInFormBuilder: boolean;
}>('tnk-preview-form-ctrl');

interface Props {
    groupId: string;
    workflowVersionId: string;
    formPreviewType: FormBuilderPreviewType;
    form: Form;
}

const FormBuilderPreview: React.FC<Props> = ({ form, groupId, workflowVersionId, formPreviewType }) => {
    const previewType = useMemo(() => {
        switch (formPreviewType) {
            case FormBuilderPreviewType.Web: {
                return 'WEBFORM';
            }
            case FormBuilderPreviewType.Slack: {
                return 'DIALOG';
            }
        }
    }, [formPreviewType]);

    // key is used to un-mount and remount form editor.
    const id = useUUID();
    const key = form.id ?? id;

    return (
        <Wrapper>
            <TonkeanPreviewForm
                key={key} // key is used to force re-mount of angular component
                formId={form.id}
                form={form}
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                formType={previewType}
                isInFormBuilder
            />
        </Wrapper>
    );
};

export default FormBuilderPreview;
