import React, { useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { Editor, Transforms } from 'slate';
import type { Element, Range } from 'slate';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import ExpressionEditorFormulaElement from './components/Elements/ExpressionEditorFormulaElement';
import ExpressionEditorVariableElement from './components/Elements/ExpressionEditorVariableElement';
import type { ExpressionEditorContextValue } from './entities/ExpressionEditorContext';
import ExpressionEditorContext from './entities/ExpressionEditorContext';
import convertNodesToString from './utils/convertNodesToString';
import convertStringToNodes from './utils/convertStringToNodes';
import createFormulaElement from './utils/createFormulaElement';
import createVariableElement from './utils/createVariableElement';

import { createPlugins, OldCoreEditor } from '@tonkean/infrastructure';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import useCompositeEventCallback from '@tonkean/tui-hooks/useCompositeEventCallback';

const StyledEditor = styled(OldCoreEditor)<{ prefix: string | undefined }>`
    line-height: 24px;

    ${({ prefix }) =>
        prefix &&
        css`
            > &:first-child::before {
                content: '${prefix}';
            }
        `}
`;

const editorPlugins = createPlugins({
    variable: {
        isInline: true,
        isVoid: true,
        component: ExpressionEditorVariableElement,
    },
    formula: {
        isInline: true,
        isVoid: true,
        component: ExpressionEditorFormulaElement,
    },
});

export interface ExpressionEditorRef {
    addVariable(variableId: string): void;

    addFormulaTag(formula: string): void;

    addText(text: string): void;

    replaceText(text: string): void;
}

interface ExpressionEditorProps extends ExpressionEditorContextValue {
    placeholder?: string;
    prefix?: string;
    value: string;
    additionalTabs?: TonkeanExpressionAdditionalTab[];

    onChange(value: string): void;
}

type Props = ExpressionEditorProps & Omit<React.TextareaHTMLAttributes<HTMLDivElement>, keyof ExpressionEditorProps>;

const ExpressionEditor: React.ForwardRefRenderFunction<ExpressionEditorRef, Props> = (
    {
        value,
        onChange,
        groupId,
        workflowVersionId,
        customTriggerId,
        translateVariableLabel,
        getVariableIconClassName,
        getProjectIntegrationTypeAndId,
        placeholder,
        prefix,
        additionalTabs,
        onBlur: onBlurProp,
        ...props
    },
    ref,
) => {
    const editorRef = useRef<Editor>(null);
    const lastRangeRef = useRef<Range>();

    const restoreSelection = useCallback(() => {
        const target = lastRangeRef.current;
        const editor = editorRef.current;

        if (target && editor) {
            ReactEditor.focus(editor);
            Transforms.select(editor, target);
        }
    }, []);

    useImperativeHandle(ref, () => {
        const move = () => {
            if (editorRef.current) {
                Transforms.move(editorRef.current, { distance: 1, unit: 'character' });
            }
        };

        const addElement = (element: Element) => {
            if (editorRef.current) {
                restoreSelection();
                Transforms.insertNodes(editorRef.current, element);
                move();
            }
        };

        return {
            addFormulaTag(formula: string) {
                addElement(createFormulaElement(formula));
            },
            addVariable(variableId: string) {
                addElement(createVariableElement(variableId));
            },
            addText(text: string) {
                if (editorRef.current) {
                    restoreSelection();
                    Transforms.insertText(editorRef.current, text);
                    move();
                }
            },
            replaceText(text: string) {
                if (editorRef.current) {
                    Transforms.delete(editorRef.current, {
                        at: {
                            anchor: Editor.start(editorRef.current, []),
                            focus: Editor.end(editorRef.current, []),
                        },
                    });
                    Transforms.insertText(editorRef.current, text);
                }
            },
        };
    }, [restoreSelection]);

    const onBlur = useCompositeEventCallback(() => {
        lastRangeRef.current = editorRef.current?.selection || undefined;
    }, onBlurProp);

    const escapedPrefix = useMemo(() => {
        if (prefix) {
            return prefix.replaceAll('\\', '\\\\').replaceAll("'", "\\'");
        }
    }, [prefix]);

    const contextValue = useMemo<ExpressionEditorContextValue>(
        () => ({
            groupId,
            workflowVersionId,
            customTriggerId,
            translateVariableLabel,
            getVariableIconClassName,
            getProjectIntegrationTypeAndId,
            additionalTabs,
        }),
        [
            customTriggerId,
            getVariableIconClassName,
            getProjectIntegrationTypeAndId,
            groupId,
            translateVariableLabel,
            workflowVersionId,
            additionalTabs,
        ],
    );

    return (
        <ExpressionEditorContext.Provider value={contextValue}>
            <StyledEditor
                dir="ltr"
                value={value}
                convertValueToNodes={convertStringToNodes}
                onChange={onChange}
                convertNodesToValue={convertNodesToString}
                plugins={editorPlugins}
                placeholder={placeholder}
                onBlur={onBlur}
                prefix={escapedPrefix}
                {...props}
                ref={editorRef}
            />
        </ExpressionEditorContext.Provider>
    );
};

export default React.forwardRef(ExpressionEditor);
