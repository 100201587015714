import * as React from 'react';

import EnterpriseComponentRequestInterceptorData from './EnterpriseComponentRequestInterceptorData';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';

interface Props {
    expressionProps: Partial<TonkeanExpressionProps>;
}

const EnterpriseComponentAuthorizationApiToken: React.FC<Props> = ({ expressionProps }) => {
    return <EnterpriseComponentRequestInterceptorData expressionProps={expressionProps} />;
};

export default EnterpriseComponentAuthorizationApiToken;
