function ChangeExternalStatusCtrl($scope, tonkeanService, authenticationService, initiative) {
    $scope.data = {
        initiative,

        externalStatuses: [],
        loadingAvailableExternalStatuses: false,
        errorLoadingAvailableExternalStatuses: false,

        savingExternalStatusDefault: false,
        errorSavingExternalStatusDefault: false,
    };

    /**
     * Modal initialization function.
     */
    $scope.init = function () {
        $scope.loadExternalStatuses();
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $scope.$dismiss();
    };

    /**
     * Loads available external statuses for an initiative.
     */
    $scope.loadExternalStatuses = function () {
        $scope.data.loadingAvailableExternalStatuses = true;
        $scope.data.errorLoadingAvailableExternalStatuses = false;

        tonkeanService
            .getInitiativeAvailableExternalStatuses($scope.data.initiative.id)
            .then((data) => {
                $scope.data.externalStatuses = data.availableStatuses;
            })
            .catch(() => {
                $scope.data.errorLoadingAvailableExternalStatuses = true;

                $scope.$emit('alert', {
                    msg: 'There was an error trying to get available statuses from external system.',
                    type: 'danger',
                });
            })
            .finally(() => {
                $scope.data.loadingAvailableExternalStatuses = false;
            });
    };

    /**
     * Changes the default status change to the given status.
     */
    $scope.changeDefaultExternalStatus = function (externalStatus) {
        authenticationService.currentUser.metadata.externalStatusUpdateSettings[
            $scope.data.initiative.externalSource + $scope.data.initiative.stateText
        ] = externalStatus;

        $scope.data.savingExternalStatusDefault = true;
        $scope.data.errorSavingExternalStatusDefault = false;

        tonkeanService
            .updateProfileMetadata(authenticationService.currentUser.metadata)
            .then(() => {
                $scope.$dismiss();
            })
            .catch(() => {
                $scope.data.errorSavingExternalStatusDefault = true;

                $scope.$emit('alert', {
                    msg: 'There was an error trying to save status update settings.',
                    type: 'danger',
                });
            })
            .finally(() => {
                $scope.data.savingExternalStatusDefault = false;
            });
    };

    $scope.init();
}

angular.module('tonkean.app').controller('ChangeExternalStatusCtrl', ChangeExternalStatusCtrl);
