import { ActionDefinitionType, HttpMethodType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function HttpRequestConfigurationCtrl($scope, $q, utils, modalUtils, customTriggerManager) {
    const ctrl = this;
    $scope.data = {
        groupId: ctrl.groupId,
        isHttpUploadAction: ctrl.isHttpUploadAction,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        existingDefinition: ctrl.existingDefinition,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        shouldDeleteVariable: ctrl.shouldDeleteVariable,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        apiBaseUrl: ctrl.apiBaseUrl,
        url: null,
        urlEvaluatedText: null,
        body: null,
        bodyEvaluatedText: null,
        method: null,
        headersArray: [],
        queryParams: [],
        contentType: 'application/json',
        contentTypes: [],
        contentTypeOptions: [
            'application/EDI-X12',
            'application/EDIFACT',
            'application/octet-stream',
            'application/ogg',
            'application/pdf  ',
            'application/xhtml+xml',
            'application/x-shockwave-flash',
            'application/json',
            'application/ld+json',
            'application/xml',
            'application/zip',
            'application/x-www-form-urlencoded',
            'text/csv',
            'text/html',
            'text/plain',
            'text/xml',
        ],
        disableFollowRedirects: false,
        disableAutoCharset: false,
        keepTrailingForwardSlash: false,
        ignoreUntrustedCertificates: false,
        retryOnStatusCodesAsObjectArray: [],
        retryOnStatusCodes: [],
        importActionConfig: ctrl.importActionConfig,
        isPreviewHidden: ctrl.isPreviewHidden,
        fileSource: 'Integration',
        storageIntegration: undefined,
        fileIdInStorageIntegration: undefined,
        fileDownloadUrl: undefined,
        parametersValues: undefined,
        httpMethodTypes: Object.values(HttpMethodType),
        isBaseUrlHidden: ctrl.isBaseUrlHidden,
        additionalTabsForTonkeanExpression: ctrl.additionalTabsForTonkeanExpression,
        isIntegrationGenericActionModal: ctrl.isIntegrationGenericActionModal,
        prefix: '',
        fileMimeTypeExpression: {},
        newFileNameExpression: {},
        formFields: [],
        formFieldTypes: ['Text', 'File'],
    };

    ctrl.$onInit = function () {
        initializeEditMode();
        $scope.onDefinitionChanged(false);
        $scope.data.prefix = $scope.getPrefixForInput();
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
            initializeEditMode();
            $scope.onDefinitionChanged(false);
        }

        if (changesObj.shouldDeleteVariable) {
            $scope.data.shouldDeleteVariable = changesObj.shouldDeleteVariable.currentValue;
        }

        if (changesObj.importActionConfig) {
            $scope.data.actionConfig = changesObj.importActionConfig.currentValue;
            initWithImportActionConfig();
        }
        if (changesObj.additionalTabsForTonkeanExpression) {
            $scope.data.additionalTabsForTonkeanExpression = changesObj.additionalTabsForTonkeanExpression.currentValue;
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onURLTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.url = originalExpression;
        $scope.data.urlEvaluatedText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onBodyTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.body = originalExpression;
        $scope.data.bodyEvaluatedText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onHeaderNameTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
        headerArray,
        index,
    ) {
        headerArray[index].name = { originalExpression, evaluatedExpression };

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onHeaderValueTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
        headerArray,
        index,
    ) {
        headerArray[index].value = { originalExpression, evaluatedExpression };

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.addHeader = function (headerArray) {
        headerArray.push({
            name: { originalExpression: null, evaluatedExpression: null },
            value: { originalExpression: null, evaluatedExpression: null },
        });

        $scope.onDefinitionChanged(true);
    };

    $scope.removeHeader = function (headerArray, index) {
        headerArray.splice(index, 1);

        $scope.onDefinitionChanged(true);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        const retryOnStatusCodes = $scope.data.retryOnStatusCodesAsObjectArray
            ?.map((statusCode) => Number.parseInt(statusCode.text))
            ?.filter(Boolean);

        const sharedDefinition = {
            url: {
                originalExpression: $scope.data.url,
                evaluatedExpression: $scope.data.urlEvaluatedText,
                isStripHtmlDisabled: false,
                parameterType: 'TONKEAN_EXPRESSION',
            },
            queryParams: [],
            methodType: $scope.data.method,
            headers: $scope.data.headersArray,
            disableFollowRedirects: $scope.data.disableFollowRedirects,
            ignoreUntrustedCertificates: $scope.data.ignoreUntrustedCertificates,
            disableAutoCharset: $scope.data.disableAutoCharset,
            keepTrailingForwardSlash: $scope.data.keepTrailingForwardSlash,
            retryOnStatusCodes: retryOnStatusCodes.length !== 0 ? retryOnStatusCodes : undefined,
            maxRetries: 3,
            definitionType: ActionDefinitionType.HTTP,
            fileMimeTypeExpression: $scope.data.fileMimeTypeExpression,
            newFileNameExpression: $scope.data.newFileNameExpression,
            formFields: $scope.data.formFields,
        };

        if ($scope.data.isHttpUploadAction) {
            const source = {
                fileSourceType: $scope.data.fileSourceType,
                storageIntegrationId:
                    $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.storageIntegrationId : undefined,
                storageIntegrationActionId:
                    $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.storageIntegrationActionId : undefined,
                extendedMatchConfiguration:
                    $scope.data.fileSourceType === 'DATA_STORAGE' ? $scope.data.extendedMatchConfiguration : undefined,
                url: $scope.data.fileSourceType === 'URL' ? $scope.data.urlExpression : undefined,
                parametersValues: $scope.data.parametersValues,
                workerStaticAssetId:
                    $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetId : undefined,
                workerStaticAssetDisplayName:
                    $scope.data.fileSourceType === 'UPLOAD_FILE' ? $scope.data.workerStaticAssetDisplayName : undefined,
            };

            const definition = {
                ...sharedDefinition,
                source,
            };

            ctrl.onDefinitionChanged?.({ definition, shouldSaveLogic });
        } else {
            // Body is only relevant for HTTP requests that are not GET request.
            const body =
                $scope.data.method !== 'GET'
                    ? {
                          originalExpression: $scope.data.body,
                          evaluatedExpression: $scope.data.bodyEvaluatedText,
                          isStripHtmlDisabled: false,
                          parameterType: 'TONKEAN_EXPRESSION',
                      }
                    : null;

            const definition = {
                ...sharedDefinition,
                contentType: $scope.data.contentType,
                body,
            };

            ctrl.onDefinitionChanged?.({ definition, shouldSaveLogic });
        }
    };

    $scope.getContentTypeOptions = function (searchQuery) {
        // To avoid infinite $digest loops, we always work with the same reference.
        const fields = $scope.data.contentTypes;
        utils.resetArrayValues(fields, $scope.data.contentTypeOptions);

        if (searchQuery) {
            // Filter the fields.
            const searchQueryLower = searchQuery.toLowerCase();
            const searchMatches = fields.filter(function (field) {
                return field.toLowerCase() === searchQueryLower;
            });

            // If the search query is not found in the given entities, add the search query as an option and return it.
            if (!searchMatches || !searchMatches.length) {
                fields.unshift(searchQuery);
            }
        }

        // The search query is contained in our field options or the custom input feature is not enabled. Just return the field options.
        return fields;
    };

    $scope.contentTypeSelectionChange = function (item) {
        $scope.data.contentType = item;
        $scope.onDefinitionChanged(true);
    };

    $scope.onDisableFollowRedirectsToggled = function (value) {
        $scope.data.disableFollowRedirects = value;
        $scope.onDefinitionChanged(true);
        return $q.resolve();
    };

    $scope.onIgnoreUntrustedCertificatesToggled = function (value) {
        $scope.data.ignoreUntrustedCertificates = value;
        $scope.onDefinitionChanged(true);
        return $q.resolve();
    };

    $scope.onDisableAutoCharsetToggled = function (value) {
        $scope.data.disableAutoCharset = value;
        $scope.onDefinitionChanged(true);
        return $q.resolve();
    };

    $scope.onKeepTrailingForwardSlashToggled = function (value) {
        $scope.data.keepTrailingForwardSlash = value;
        $scope.onDefinitionChanged(true);
        return $q.resolve();
    };

    $scope.onStatusCodeChanged = function () {
        $scope.onDefinitionChanged(true);
    };

    $scope.openHttpRequestPreviewModal = function () {
        let exampleInitiative = null;
        if (customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId]) {
            exampleInitiative =
                customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][
                    $scope.data.configuredLogic.node.id
                ];
        }
        const options = {
            url: $scope.data.apiBaseUrl ? `${$scope.data.apiBaseUrl}/${$scope.data.url}` : $scope.data.url,
            method: $scope.data.method,
            contentType: $scope.data.contentType,
            headers: $scope.data.headersArray,
            body: $scope.data.bodyEvaluatedText,
            formFields: $scope.data.formFields,
            isHttpUploadAction: $scope.data.isHttpUploadAction,
        };
        modalUtils.openHttpRequestPreviewModal($scope.data.groupId, options, exampleInitiative);
    };

    $scope.onStatusCodesTagsChanged = function () {
        $scope.onDefinitionChanged(true);
    };

    $scope.selectFileSource = (selectedFileSource) => {
        $scope.data.fileSource = selectedFileSource;
        $scope.onDefinitionChanged(true);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.storageIntegration = selectedProjectIntegration;
        $scope.onDefinitionChanged(true);
    };

    $scope.onStorageIntegrationFileIdTonkeanExpressionChanged = (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) => {
        $scope.data.fileIdInStorageIntegration = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFileDownloadUrlTonkeanExpressionChanged = (originalExpression, evaluatedExpression, shouldSaveLogic) => {
        $scope.data.fileDownloadUrl = { originalExpression, evaluatedExpression };
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.fileSourceType = fileSourceType;
        $scope.data.storageIntegrationId = storageIntegrationId;
        $scope.data.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.urlExpression = urlExpression;
        $scope.data.workerStaticAssetId = workerStaticAssetId;
        $scope.data.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.parametersValues = parametersValues;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once the file mimetype expression has changed.
     */
    $scope.onFileMimeTypeExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.fileMimeTypeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onNewFileNameExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.newFileNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Form Fields
     */
    $scope.removeFormField = function (index) {
        $scope.data.formFields.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.addFormField = function () {
        $scope.data.formFields.push({});
    };

    $scope.onFormFieldNameTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.formFields[index].formFieldNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFormFieldValueTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.formFields[index].formFieldValueExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.getPrefixForInput = function () {
        if ($scope.data.isIntegrationGenericAction) {
            if ($scope.data.isIntegrationGenericActionModal) {
                return '{{Env url}}/';
            } else {
                return '/';
            }
        } else {
            return undefined;
        }
    };

    /**
     * Initializes the new configuration of file to upload.
     */
    function initializeNewConfigurationAsNeeded() {
        if ($scope.data.existingDefinition.source) {
            if ($scope.data.existingDefinition.source.fileSourceType) {
                // Are we using the new definition?

                $scope.data.fileSourceType = $scope.data.existingDefinition.source.fileSourceType;
                $scope.data.storageIntegrationId = $scope.data.existingDefinition.source.storageIntegrationId;
                $scope.data.storageIntegrationActionId =
                    $scope.data.existingDefinition.source.storageIntegrationActionId;
                $scope.data.extendedMatchConfiguration =
                    $scope.data.existingDefinition.source.extendedMatchConfiguration;
                $scope.data.urlExpression = $scope.data.existingDefinition.source.url;
                $scope.data.workerStaticAssetId = $scope.data.existingDefinition.source.workerStaticAssetId;
                $scope.data.workerStaticAssetDisplayName =
                    $scope.data.existingDefinition.source.workerStaticAssetDisplayName;
            } else {
                // Are we using the old definition? If so, we initialize the correct variables for new version.
                $scope.data.fileSourceType = $scope.data.fileDownloadUrl ? 'URL' : 'DATA_STORAGE';
                $scope.data.storageIntegrationId = $scope.data.storageIntegration.id;
                $scope.data.urlExpression = $scope.data.fileDownloadUrl;
                if ($scope.data.fileIdInStorageIntegration) {
                    $scope.data.extendedMatchConfiguration = {
                        extendedMatchConfigurationType: 'EXPRESSION',
                        uiExpression: $scope.data.fileIdInStorageIntegration,
                    };
                }
            }
        }
    }

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        if ($scope.data.existingDefinition) {
            if ($scope.data.existingDefinition.url) {
                $scope.data.url = $scope.data.existingDefinition.url.originalExpression;
                $scope.data.urlEvaluatedText = $scope.data.existingDefinition.url.evaluatedExpression;
            }

            if ($scope.data.existingDefinition.body) {
                $scope.data.body = $scope.data.existingDefinition.body.originalExpression;
                $scope.data.bodyEvaluatedText = $scope.data.existingDefinition.body.evaluatedExpression;
            }

            if ($scope.data.invalidLogics && $scope.data.invalidLogics[$scope.data.configuredLogic.node.id]) {
                $scope.data.validationObject = $scope.data.invalidLogics[$scope.data.configuredLogic.node.id];
            }

            $scope.data.fileSource = $scope.data.existingDefinition.source?.type;
            $scope.data.storageIntegration = { id: $scope.data.existingDefinition.source?.storageIntegrationId };
            $scope.data.storageIntegrationActionId = $scope.data.existingDefinition.source?.storageIntegrationActionId;
            $scope.data.fileIdInStorageIntegration = $scope.data.existingDefinition.source?.fileId;
            $scope.data.fileDownloadUrl = $scope.data.existingDefinition.source?.url;
            $scope.data.parametersValues = $scope.data.existingDefinition.source?.parametersValues;
            $scope.data.headersArray = $scope.data.existingDefinition.headers;
            $scope.data.method = $scope.data.existingDefinition.methodType;
            $scope.data.contentType = $scope.data.existingDefinition.contentType || $scope.data.contentType;
            $scope.data.disableFollowRedirects = $scope.data.existingDefinition.disableFollowRedirects;
            $scope.data.ignoreUntrustedCertificates = $scope.data.existingDefinition.ignoreUntrustedCertificates;
            $scope.data.disableAutoCharset = $scope.data.existingDefinition.disableAutoCharset;
            $scope.data.keepTrailingForwardSlash = $scope.data.existingDefinition.keepTrailingForwardSlash;
            // gets from server ex. [505,404]
            $scope.data.retryOnStatusCodes = $scope.data.existingDefinition.retryOnStatusCodes;
            $scope.data.queryParams = $scope.data.existingDefinition.queryParams;
            // gets from tags-input ex. [{text: 505}, {text:404}]
            $scope.data.retryOnStatusCodesAsObjectArray = ($scope.data.retryOnStatusCodes || []).map((status) => {
                return { text: status };
            });
            $scope.data.fileMimeTypeExpression = $scope.data.existingDefinition.fileMimeTypeExpression;
            $scope.data.newFileNameExpression = $scope.data.existingDefinition.newFileNameExpression;
            $scope.data.formFields = $scope.data.existingDefinition.formFields;
            initializeNewConfigurationAsNeeded();
        }

        // Make sure we have "headers" array.
        if (!$scope.data.headersArray) {
            $scope.data.headersArray = [];
        }

        if (!$scope.data.formFields) {
            $scope.data.formFields = [];
        }

        if (!$scope.data.method) {
            $scope.data.method = 'POST';
        }
    }

    function initWithImportActionConfig() {
        if ($scope.data.actionConfig) {
            const importActionConfigJson = JSON.parse($scope.data.actionConfig);

            if (importActionConfigJson) {
                $scope.data.urlEvaluatedText = importActionConfigJson.url;
                $scope.data.method = importActionConfigJson.method;
                $scope.data.body = importActionConfigJson.body;

                // Delete current headers and import the given values
                $scope.data.headersArray = [];
                Object.keys(importActionConfigJson.header).forEach((key) => {
                    if (key === 'Content-Type') {
                        // Content type is saved outside headers array
                        $scope.data.contentType = importActionConfigJson.header[key];
                    } else {
                        $scope.data.headersArray.push({
                            name: {
                                originalExpression: key,
                                evaluatedExpression: key,
                            },
                            value: {
                                originalExpression: importActionConfigJson.header[key],
                                evaluatedExpression: importActionConfigJson.header[key],
                            },
                        });
                    }
                });
            }
            $scope.onDefinitionChanged(false);
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('HttpRequestConfigurationCtrl', lateConstructController(HttpRequestConfigurationCtrl));
