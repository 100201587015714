import difference from 'lodash.difference';
import { useMemo } from 'react';
import * as React from 'react';

import { EnterpriseComponentAuthorizationParam } from './EnterpriseComponentAuthorizationParam';
import { ReactComponent as TrashIcon } from '../../../../../images/icons/trash-new.svg';
import useCustomRequestAdditionalTab from '../../../HttpRequestConfiguration/utils/useCustomRequestAdditionalTab';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { SimpleSelect, Spacer } from '@tonkean/infrastructure';
import { ActionDefinitionType, EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import type {
    EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition,
    ProjectIntegration,
    TonkeanExpressionDefinition,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

type SingleRecipe = {
    id: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>;
    value: TonkeanExpressionDefinition;
};

interface Props {
    projectIntegration: ProjectIntegration;
    expressionProps: Partial<TonkeanExpressionProps>;

    onChangeHandlingDefinition(definition: EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition): void;

    variableValueAssignmentResponseHandlingDefinition:
        | EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition
        | undefined;
}

const EnterpriseComponentVariableValueAssignmentResponseHandleResponseView: React.FC<Props> = ({
    projectIntegration,
    expressionProps,
    onChangeHandlingDefinition,
    variableValueAssignmentResponseHandlingDefinition,
}) => {
    const tab = useCustomRequestAdditionalTab(ActionDefinitionType.HTTP, []);

    // all the additional props to tonkean expression with the http request (body headers status) fields.
    const additionalExpressionProps: Partial<TonkeanExpressionProps> = useMemo(() => {
        return {
            ...expressionProps,
            additionalTabs: [...(expressionProps?.additionalTabs || []), tab],
        };
    }, [expressionProps, tab]);

    // All the enterprise component variables in the current project integration.
    const { data: variables } = useTonkeanService(
        'getEnterpriseComponentVariables',
        projectIntegration.projectId,
        projectIntegration.id,
        projectIntegration.id,
    );

    // List of all the enterprise component variables as select options.
    const options = useMemo(() => {
        if (!variables) {
            return [];
        }

        return variables.entities.map((variable) => ({
            label: variable.displayName,
            value: variable.id,
        }));
    }, [variables]);

    // All the unused enterprise component variable ids.
    const unUsedOptions: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>[] = useMemo(() => {
        const allVariablesKeys =
            variables?.entities?.map((variable) => {
                return variable.id;
            }) || [];

        const usedVariablesKeys = Object.keys(
            variableValueAssignmentResponseHandlingDefinition?.parametersRecipe || {},
        ) as TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>[];

        return difference(allVariablesKeys, usedVariablesKeys);
    }, [variableValueAssignmentResponseHandlingDefinition?.parametersRecipe, variables?.entities]);

    // On Clicking 'add' new recipe row.
    const onClickAdd = () => {
        const nextUnusedVariable = unUsedOptions[0];

        if (!nextUnusedVariable) {
            return;
        }

        onChangeHandlingDefinition({
            responseHandlingType: EntityResponseHandlingDefinitionType.ENTERPRISE_COMPONENT_VARIABLE_VALUE_ASSIGNMENT,
            parametersRecipe: {
                ...variableValueAssignmentResponseHandlingDefinition?.parametersRecipe,
                [nextUnusedVariable]: {
                    originalExpression: '',
                    evaluatedExpression: '',
                    isStripHtmlDisabled: true,
                },
            },
        });
    };

    // On delete single recipe row
    const deleteRow = (deletedRow: SingleRecipe) => {
        delete variableValueAssignmentResponseHandlingDefinition?.parametersRecipe[deletedRow.id];

        onChangeHandlingDefinition({
            responseHandlingType: EntityResponseHandlingDefinitionType.ENTERPRISE_COMPONENT_VARIABLE_VALUE_ASSIGNMENT,
            parametersRecipe: {
                ...variableValueAssignmentResponseHandlingDefinition?.parametersRecipe,
            },
        });
    };

    // On Clicking 'add' new recipe row.
    const onChangeParameterKey = (
        oldVariableId: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>,
        newVariableId: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>,
    ) => {
        const oldRecipe = variableValueAssignmentResponseHandlingDefinition?.parametersRecipe[oldVariableId];

        if (oldRecipe) {
            const oldRecipeCopy = { ...oldRecipe };
            delete variableValueAssignmentResponseHandlingDefinition.parametersRecipe[oldVariableId];

            onChangeHandlingDefinition({
                responseHandlingType:
                    EntityResponseHandlingDefinitionType.ENTERPRISE_COMPONENT_VARIABLE_VALUE_ASSIGNMENT,
                parametersRecipe: {
                    ...variableValueAssignmentResponseHandlingDefinition?.parametersRecipe,
                    [newVariableId]: oldRecipeCopy,
                },
            });
        }
    };

    // List of recipe rows.
    const listOfRecipeRows = useMemo(() => {
        return Object.entries(variableValueAssignmentResponseHandlingDefinition?.parametersRecipe || {}).map(
            ([id, value]) => ({
                id,
                value,
            }),
        ) as SingleRecipe[];
    }, [variableValueAssignmentResponseHandlingDefinition?.parametersRecipe]);

    return (
        <>
            {listOfRecipeRows.map((row) => (
                <React.Fragment key={row.id}>
                    <EnterpriseComponentAuthorizationParam>Parameter Name:</EnterpriseComponentAuthorizationParam>
                    <SimpleSelect
                        onChange={(selectedId: TonkeanId<TonkeanType.ENTERPRISE_COMPONENT_VARIABLE>) =>
                            onChangeParameterKey(row.id, selectedId)
                        }
                        value={row.id}
                        options={options}
                    />
                    <Spacer height={12} />

                    <EnterpriseComponentAuthorizationParam>Parameter Value:</EnterpriseComponentAuthorizationParam>
                    <TonkeanExpression
                        {...additionalExpressionProps}
                        savedOriginalExpression={row.value.originalExpression}
                        savedEvaluatedExpression={row.value.evaluatedExpression}
                        onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                            onChangeHandlingDefinition({
                                responseHandlingType:
                                    EntityResponseHandlingDefinitionType.ENTERPRISE_COMPONENT_VARIABLE_VALUE_ASSIGNMENT,
                                parametersRecipe: {
                                    ...variableValueAssignmentResponseHandlingDefinition?.parametersRecipe,
                                    [row.id]: {
                                        originalExpression,
                                        evaluatedExpression,
                                        isStripHtmlDisabled: true,
                                    },
                                },
                            });
                        }}
                        placeholder="Insert Value"
                    />
                    <Spacer height={12} />

                    <IconButton onClick={() => deleteRow(row)} flat>
                        <TrashIcon />
                    </IconButton>

                    <Spacer height={12} />
                </React.Fragment>
            ))}

            <Spacer height={12} />

            {unUsedOptions.length > 0 && (
                <ClickableLink onClick={onClickAdd} disabled={unUsedOptions.length <= 0}>
                    + Add new
                </ClickableLink>
            )}
        </>
    );
};

export default EnterpriseComponentVariableValueAssignmentResponseHandleResponseView;
