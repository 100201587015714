<span
    class="beacon mod-{{ beaconPlacement }} pointer"
    ng-hide="hideBeacon"
    uib-popover-template="'./tipPopoverTemplate.template.html'"
    analytics-on
    analytics-category="Tips"
    analytics-event="Tip open"
    analytics-label="{{ type }}"
    popover-is-open="popover.isOpen"
    popover-trigger="{{ tip ? 'outsideClick' : 'none' }}"
    popover-append-to-body="true"
    popover-placement="{{ popoverPlacement || 'top' }}"
></span>
