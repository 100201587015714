import React from 'react';
import styled from 'styled-components';

import { ReactComponent as DoneIconSvg } from '../../../../../../../images/icons/history/done.svg';
import { ReactComponent as ErrorIconSvg } from '../../../../../../../images/icons/history/history-error.svg';
import { ReactComponent as MonitoringIcon } from '../../../../../../../images/icons/history/monitoring.svg';
import { ReactComponent as OffIcon } from '../../../../../../../images/icons/history/off.svg';
import { ReactComponent as WasntActivatedIcon } from '../../../../../../../images/icons/history/wasnt-activated.svg';

import { LoadingDots, Tooltip } from '@tonkean/infrastructure';
import { WarningTriangleIcon } from '@tonkean/svg';
import { WorkerRunLogicStatus, workerRunLogicStatusToDataAutomationSuffix } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import type { PaletteDefinition } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const LoadingDotsWithMargin = styled(LoadingDots)`
    margin-right: 6px;
`;

const InlineSvgIcon = styled.div`
    display: inline-flex;
    margin-right: 6px;

    .tnk-icon {
        display: inline-flex;
    }
`;

const ColoredDoneIcon = styled(InlineSvgIcon)`
    .tnk-icon {
        path {
            stroke: ${Theme.current.palette.history.status.good};
        }
    }
`;

const ErrorIcon = styled(InlineSvgIcon)`
    svg {
        width: 12px;
        height: 12px;
    }
`;

const ColoredLink = styled(StateLink)<{ status: keyof PaletteDefinition['history']['status'] }>`
    &,
    &:hover,
    &:focus {
        color: ${({ status }) => Theme.current.palette.history.status[status]};
        text-decoration: underline ${({ status }) => Theme.current.palette.history.status[status]};
    }
`;

const ColoredText = styled.span<{ status: keyof PaletteDefinition['history']['status'] }>`
    color: ${({ status }) => Theme.current.palette.history.status[status]};
`;

const MonitoringStatus: React.FC = () => {
    return (
        <>
            <InlineSvgIcon>
                <span className="tnk-icon">
                    <MonitoringIcon />
                </span>
            </InlineSvgIcon>
            <ColoredText
                status="good"
                data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[WorkerRunLogicStatus.MONITORING]}`}
            >
                Monitoring
            </ColoredText>
        </>
    );
};

const WasntActivatedStatus: React.FC = () => {
    return (
        <>
            <InlineSvgIcon>
                <span className="tnk-icon">
                    <WasntActivatedIcon />
                </span>
            </InlineSvgIcon>
            <ColoredText
                status="nothing"
                data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[WorkerRunLogicStatus.WASNT_ACTIVATED]}`}
            >
                Wasn’t Activated
            </ColoredText>
        </>
    );
};

const OffStatus: React.FC<{ text: string }> = ({ text }) => {
    return (
        <>
            <InlineSvgIcon>
                <span className="tnk-icon">
                    <OffIcon />
                </span>
            </InlineSvgIcon>
            <ColoredText
                status="nothing"
                data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[WorkerRunLogicStatus.WORKER_OFF]}`}
            >
                {text}
            </ColoredText>
        </>
    );
};

const InnerTriggerActivatedStatus: React.FC = () => {
    return (
        <>
            <InlineSvgIcon>
                <span className="tnk-icon">
                    <DoneIconSvg />
                </span>
            </InlineSvgIcon>
            <ColoredText
                status="nothing"
                data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[WorkerRunLogicStatus.INNER_TRIGGER_ACTIVATED]}`}
            >
                Inner Trigger Activated
            </ColoredText>
        </>
    );
};

interface LinkProps {
    onClick: (e) => void;
    inspectState: { name: string; params: any };
    logicStatus: WorkerRunLogicStatus;
    children?: string;
}

const MonitoringStatusLinked: React.FC<LinkProps> = ({ onClick, inspectState, logicStatus }) => {
    return (
        <>
            <InlineSvgIcon>
                <span className="tnk-icon">
                    <MonitoringIcon />
                </span>
            </InlineSvgIcon>
            <Tooltip content="Inspect">
                <ColoredLink
                    status="good"
                    state={inspectState.name}
                    params={inspectState.params}
                    data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[logicStatus]}`}
                    onClick={onClick}
                    onKeyPress={onClick}
                    dontChangeState
                >
                    Monitoring
                </ColoredLink>
            </Tooltip>
        </>
    );
};

const ConditionsNotMetStatus: React.FC<React.PropsWithChildren<LinkProps>> = ({
    onClick,
    inspectState,
    logicStatus,
    children,
}) => {
    return (
        <>
            <InlineSvgIcon>
                <span className="tnk-icon">
                    <WasntActivatedIcon />
                </span>
            </InlineSvgIcon>
            <Tooltip content="Inspect">
                <ColoredLink
                    status="nothing"
                    state={inspectState.name}
                    params={inspectState.params}
                    data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[logicStatus]}`}
                    onClick={onClick}
                    onKeyPress={onClick}
                    dontChangeState
                >
                    {children}
                </ColoredLink>
            </Tooltip>
        </>
    );
};

const ErrorStatus: React.FC<LinkProps> = ({ onClick, inspectState, logicStatus }) => {
    return (
        <>
            <ErrorIcon>
                <span className="tnk-icon">
                    <ErrorIconSvg />
                </span>
            </ErrorIcon>
            <Tooltip content="Inspect">
                <ColoredLink
                    status="bad"
                    state={inspectState.name}
                    params={inspectState.params}
                    data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[logicStatus]}`}
                    onClick={onClick}
                    onKeyPress={onClick}
                    dontChangeState
                >
                    Error
                </ColoredLink>
            </Tooltip>
        </>
    );
};

const PendingStatus: React.FC<LinkProps> = ({ onClick, inspectState, logicStatus }) => {
    return (
        <>
            <LoadingDotsWithMargin border />
            <Tooltip content="Inspect">
                <ColoredLink
                    status="nothing"
                    state={inspectState.name}
                    params={inspectState.params}
                    data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[logicStatus]}`}
                    onClick={onClick}
                    onKeyPress={onClick}
                    dontChangeState
                >
                    Pending
                </ColoredLink>
            </Tooltip>
        </>
    );
};

const SuccessStatus: React.FC<LinkProps> = ({ onClick, inspectState, logicStatus }) => {
    return (
        <>
            <ColoredDoneIcon>
                <span className="tnk-icon">
                    <DoneIconSvg />
                </span>
            </ColoredDoneIcon>
            <Tooltip content="Inspect">
                <ColoredLink
                    status="good"
                    state={inspectState.name}
                    data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[logicStatus]}`}
                    params={inspectState.params}
                    onClick={onClick}
                    onKeyPress={onClick}
                    dontChangeState
                >
                    Success
                </ColoredLink>
            </Tooltip>
        </>
    );
};

const PartialStatus: React.FC<LinkProps> = ({ onClick, inspectState, logicStatus }) => {
    return (
        <>
            <ErrorIcon>
                <span className="tnk-icon">
                    <WarningTriangleIcon />
                </span>
            </ErrorIcon>
            <Tooltip content="Inspect">
                <ColoredLink
                    status="warn"
                    state={inspectState.name}
                    data-automation={`single-worker-run-logic-status-${workerRunLogicStatusToDataAutomationSuffix[logicStatus]}`}
                    params={inspectState.params}
                    onClick={onClick}
                    onKeyPress={onClick}
                    dontChangeState
                >
                    Partial Success
                </ColoredLink>
            </Tooltip>
        </>
    );
};

interface Props extends LinkProps {
    logicInfo: any;
}

const SingleWorkerRunFlowRunLogicStatus: React.FC<Props> = ({ logicStatus, onClick, inspectState, logicInfo }) => {
    switch (logicStatus) {
        case WorkerRunLogicStatus.CONDITION_NOT_MET: {
            return (
                <ConditionsNotMetStatus
                    onClick={onClick}
                    inspectState={inspectState}
                    logicStatus={WorkerRunLogicStatus.CONDITION_NOT_MET}
                >
                    Conditions not met
                </ConditionsNotMetStatus>
            );
        }
        case WorkerRunLogicStatus.ERROR: {
            return (
                <ErrorStatus onClick={onClick} inspectState={inspectState} logicStatus={WorkerRunLogicStatus.ERROR} />
            );
        }
        case WorkerRunLogicStatus.INNER_TRIGGER_ACTIVATED: {
            return <InnerTriggerActivatedStatus />;
        }
        case WorkerRunLogicStatus.WORKER_OFF: {
            return <OffStatus text="Module was turned off" />;
        }
        case WorkerRunLogicStatus.OFF: {
            return <OffStatus text="Wasn't Activated" />;
        }
        case WorkerRunLogicStatus.SKIPPED: {
            return (
                <ConditionsNotMetStatus
                    onClick={onClick}
                    inspectState={inspectState}
                    logicStatus={WorkerRunLogicStatus.SKIPPED}
                >
                    Skipped
                </ConditionsNotMetStatus>
            );
        }
        case WorkerRunLogicStatus.PENDING: {
            return (
                <PendingStatus
                    onClick={onClick}
                    inspectState={inspectState}
                    logicStatus={WorkerRunLogicStatus.PENDING}
                />
            );
        }
        case WorkerRunLogicStatus.WASNT_ACTIVATED: {
            return <WasntActivatedStatus />;
        }
        case WorkerRunLogicStatus.MONITORING: {
            if (!logicInfo || logicInfo.completed) {
                return <MonitoringStatus />;
            }
            return (
                <MonitoringStatusLinked
                    onClick={onClick}
                    inspectState={inspectState}
                    logicStatus={WorkerRunLogicStatus.MONITORING}
                />
            );
        }
        case WorkerRunLogicStatus.PARTIAL: {
            return (
                <PartialStatus
                    onClick={onClick}
                    inspectState={inspectState}
                    logicStatus={WorkerRunLogicStatus.PARTIAL}
                />
            );
        }
        case WorkerRunLogicStatus.SUCCESS: {
            return (
                <SuccessStatus
                    onClick={onClick}
                    inspectState={inspectState}
                    logicStatus={WorkerRunLogicStatus.SUCCESS}
                />
            );
        }
    }
};

export default SingleWorkerRunFlowRunLogicStatus;
