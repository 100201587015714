import styled from 'styled-components';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import { FontSize, Theme } from '@tonkean/tui-theme';

export const StyledField = styled.div<{ isFirst?: boolean }>`
    ${({ isFirst }) => !isFirst && 'margin-top: 21px'};
    color: ${Theme.colors.gray_700};
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
`;

export const StyledPreCreatedParameter = styled.div`
    margin-top: 40px;
    color: ${Theme.colors.gray_700};
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
`;

export const StyledTonkeanExpression = styled(TonkeanExpression)`
    margin-top: 4px;
    height: 26px;
`;
