<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <div class="margin-bottom-lg info-box common-size-xxs">
                <!--<i class="fa fa-info-circle margin-right"></i>-->
                <p class="common-color-dark-grey">Setup instructions:</p>
                <div class="common-color-dark-grey">
                    1. Log into your Teamwork site and click your avatar in the top right of the app.
                    <br />
                    2. Click on "Edit my details" at the bottom of the list.
                    <br />
                    3. Choose "API & Mobile".
                    <br />
                    4. Enable the API and you will be provided with an API key.
                    <br />
                    5. Copy the API Key to this windows.
                    <br />
                    6. To finish please click save.
                    <br />
                </div>
            </div>
            <div class="form-group">
                <label for="login-uri" class="col-sm-3 control-label">Teamwork URL</label>
                <div class="col-sm-9">
                    <input
                        type="text"
                        ng-model="data.appUri"
                        class="form-control"
                        id="login-uri"
                        placeholder="URL used to get to your Teamwork homepage"
                        autocomplete="off"
                        required
                    />
                    <div class="common-subtitle-inner">
                        <i>
                            https://
                            <span class="common-underline">your-company</span>
                            .teamwork.com
                        </i>
                    </div>
                    <div class="common-subtitle-inner">
                        <i>
                            For EU users https://
                            <span class="common-underline">your-company</span>
                            eu.teamwork.com
                        </i>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="login-api-key" class="col-sm-3 control-label">API Key</label>

                <div class="col-sm-9">
                    <input
                        type="text"
                        ng-model="data.key"
                        class="form-control"
                        id="login-api-key"
                        autocomplete="off"
                        placeholder="API Key"
                        required
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>
            <div class="form-group">
                <div class="col-sm-offset-1 col-sm-8">
                    <i class="common-color-light-grey common-size-xxxxs">
                        Note: When connecting the integration we will only get completed tasks from the last 60 days.
                    </i>
                </div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
