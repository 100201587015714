<form name="status-{{ initiative.id }}" class="common-height-full">
    <div class="padding-normal flex flex-col common-close-btn-container common-height-full">
        <span class="common-close-btn" ng-click="$dismiss()">
            <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
        </span>

        <h4 class="common-bold">
            Ask {{ initiative.owner && initiative.owner.name ? initiative.owner.name : '' }} for an update
        </h4>
        <p>
            We’ll send it via {{ cis.getPreferredCommunicationIntegrationNameForUser(initiative.owner) || 'email' }},
            and keep you posted.
        </p>

        <div
            class="rounded-box mod-no-border common-bg mod-light-grey padding-normal-sm clearfix"
            ng-if="initiative.updateText && initiative.updateText.length"
        >
            <div class="rounded-box mod-no-border padding-normal-sm clearfix common-size-xxxxs common-color-black">
                <strong
                    ng-if="initiative.status !== 'FUTURE'"
                    ng-style="{ color: initiative.stateColor }"
                    class="pull-right margin-right"
                >
                    {{ initiative.stateText }}
                </strong>
                <p class="margin-bottom flex-vmiddle common-size-xxxxxs common-color-grey">
                    <i class="flex-grow">
                        Latest update by
                        <strong>{{ initiative.updater.name }}</strong>
                        &nbsp;{{ initiative.updated | timeAgo }}:
                    </i>
                </p>
                <div class="flex avatar-size-xxxs">
                    <tnk-avatar class="flex-no-shrink" user="initiative.updater"></tnk-avatar>
                    <div class="flex-grow margin-left clearfix">
                        <!--<i class="fa fa-quote-left opacity-25 common-color-grey common-size-xxxxxs pull-left"></i>-->
                        <div light-markdown="initiative.updateText"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="margin-top flex-grow relative" style="max-height: 50%; min-height: 80px">
            <label for="message-{{ initiative.id }}" class="sr-only">Message</label>
            <textarea
                class="form-control common-no-outline common-resize-y-only"
                rows="4"
                id="message-{{ initiative.id }}"
                placeholder="Add a personal message if you are looking for a specific update (optional)..."
                maxlength="450"
                ng-disabled="posting"
                ng-keypress="onTextAreaKeyPress($event)"
                autofocus="{{ editMode }}"
                ng-required="required"
                ng-model="data.tempText"
            ></textarea>
        </div>

        <div class="flex flex-xs-col margin-top mod-justify-end">
            <div class="flex-no-shrink">
                <button
                    class="btn btn-primary btn-lg-mobile mod-100"
                    type="submit"
                    ng-click="askForUpdate()"
                    ng-disabled="posting || !as.currentUser || as.currentUser.isGuest"
                >
                    <span ng-hide="posting">
                        Ask now
                        <i class="margin-left fa fa-arrow-right"></i>
                    </span>
                    <span ng-show="posting">Sending...</span>
                </button>
            </div>
        </div>

        <div ng-if="data.error" class="common-color-danger">{{ data.error }}</div>
        <!--</div>-->
    </div>
</form>
