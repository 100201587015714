import * as React from 'react';

import MarketplaceItemCreateModalDataSourceDataSourceSection from './MarketplaceItemCreateModalDataSourceDataSourceSection';

import { useFormikField } from '@tonkean/infrastructure';
import type {
    ProjectIntegrationActionMarketplaceSummary,
    ProjectIntegrationEntityMarketplaceSummary,
    ProjectIntegrationParameterMarketplaceSummary,
} from '@tonkean/tonkean-entities';
import { EnterpriseComponentAuthorizationType } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const UpdateMarketplaceDataSourceItemInfo: React.FC = () => {
    const { value: actions } = useFormikField<ProjectIntegrationActionMarketplaceSummary[]>('configuration.actions');
    const { value: entities } = useFormikField<ProjectIntegrationEntityMarketplaceSummary[]>('configuration.entities');
    const { value: parameters } =
        useFormikField<ProjectIntegrationParameterMarketplaceSummary[]>('configuration.parameters');
    const { value: authorizationType } = useFormikField<EnterpriseComponentAuthorizationType>(
        'configuration.authorizationType',
    );

    return (
        <MarketplaceItemCreateModalDataSourceDataSourceSection
            entities={entities}
            actions={actions}
            parameters={parameters || EMPTY_ARRAY}
            authorizationType={authorizationType || EnterpriseComponentAuthorizationType.NONE}
        />
    );
};

export default UpdateMarketplaceDataSourceItemInfo;
