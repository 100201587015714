import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useMemo } from 'react';

import type SolutionSiteFieldChartWidgetConfiguration from './SolutionSiteFieldChartWidgetConfiguration';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { HistoricalFieldChartComponent } from '@tonkean/fields';
import type { FieldDefinition, SolutionSite } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';

interface Props {
    solutionSite: SolutionSite;
    configuration: SolutionSiteFieldChartWidgetConfiguration;
    entityVersionType: WorkflowVersionType;
}

const SolutionSiteHistoricalGlobalFieldFieldChartWidget: React.FC<Props> = ({
    solutionSite,
    configuration,
    entityVersionType,
}) => {
    const projectManager = useAngularService('projectManager');

    const { data: globalFieldDefinitions } = useTonkeanService(
        'getWorkflowFolderGlobalFieldDefinitions',
        solutionSite.workflowFolderId,
        entityVersionType,
    );

    const filteredFields = useMemo<FieldDefinition[] | undefined>(() => {
        return globalFieldDefinitions?.entities?.filter((fieldDefinition) =>
            Boolean(configuration.fields?.[fieldDefinition.id]),
        );
    }, [globalFieldDefinitions?.entities, configuration.fields]);

    const [groupsMap] = useAngularWatch(() => projectManager.groupsMap);

    const globalFields = useMemo(() => {
        return Object.assign(
            {},
            ...Object.values(groupsMap).map((groupMap: any) => {
                if (entityVersionType === WorkflowVersionType.PUBLISHED) {
                    return groupMap.globalFieldsMap;
                }
                return groupMap.globalFieldsDraftMap;
            }),
        );
    }, [groupsMap, entityVersionType]);

    const fieldInstance = useMemo(() => {
        if (globalFields && filteredFields?.[0]) {
            return globalFields[filteredFields[0].id];
        }
    }, [filteredFields, globalFields]);

    const fieldLabel = useMemo<string | undefined>(() => {
        const fields = Object.keys(configuration.fields);
        const field: string | undefined = fields[0];
        if (field && configuration.fields[field]?.label) {
            return configuration.fields[field]?.label;
        } else {
            return fieldInstance?.fieldDefinition.name ?? '';
        }
    }, [fieldInstance?.fieldDefinition.name, configuration.fields]);

    return (
        <HistoricalFieldChartComponent
            fieldInstance={fieldInstance}
            fieldName={fieldLabel ?? ''}
            showStatusOverlay={configuration.showStatusOverlay}
            configuredChartType={configuration.chartHistoricalDisplayType}
        />
    );
};

export default SolutionSiteHistoricalGlobalFieldFieldChartWidget;
