function LoginColumnCtrl(
    $scope,
    $rootScope,
    $state,
    $window,
    $localStorage,
    tonkeanService,
    authenticationService,
    utils,
) {
    $window.scrollTo(0, 0);

    $scope.close = function () {
        // Set the onboardingWatched in the user's metadata to true if needed.
        if (!$rootScope.as.currentUser.metadata || !$rootScope.as.currentUser.metadata.onboardingWatched) {
            if (!$rootScope.as.currentUser.metadata) {
                $rootScope.as.currentUser.metadata = {};
            }
            $rootScope.as.currentUser.metadata.onboardingWatched = true;
            tonkeanService.updateProfileMetadata($rootScope.as.currentUser.metadata);
        }

        // Check if the user got here when he tried to auto create an example list from the website's list gallery.
        // If so, we should navigate him to the list auto creation page instead of the normal product page.
        if (!utils.isNullOrEmpty($localStorage.autoCreateListId)) {
            // Finish the authentication without navigating onwards.
            authenticationService.handleAuthenticationComplete(true);
            // Navigate to the create list page with the autoCreateList flag marked as true, and clear the localStorage.
            const autoCreateListId = $localStorage.autoCreateListId;
            delete $localStorage.autoCreateListId;
            $state.go(
                'createList',
                { listType: autoCreateListId, g: null, autoCreateList: true },
                { location: 'replace' },
            );
        } else {
            // Finish the authentication normally with redirect.
            authenticationService.handleAuthenticationComplete();
        }
    };
}

export default angular.module('tonkean.app').controller('LoginColumnCtrl', LoginColumnCtrl);
