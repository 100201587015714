<div class="search-triggers-configuration flex-vmiddle">
    <ui-select
        class="flex-grow ui-select-min-width"
        ng-class="{ 'margin-right-xs': !data.canClear }"
        theme="bootstrap"
        ng-change="onTriggerSelected(data.selectedSimplifiedTrigger)"
        ng-model="data.selectedSimplifiedTrigger"
    >
        <ui-select-match placeholder="Search triggers...">{{ data.selectedSimplifiedTrigger.title }}</ui-select-match>
        <!-- refresh is the function to refresh options while the user is typing. -->
        <!-- refresh-delay is 0 because our refresh function does the throttling itself (ui-select doesn't do a good job). -->
        <ui-select-choices
            repeat="trigger in data.simplifiedTriggers | filter:$select.search"
            refresh="searchTriggersThrottled($select.search)"
            refresh-delay="0"
            ng-click="stopPropagation($event)"
        >
            <div class="flex-vmiddle">
                <span>{{ trigger.title }}</span>
            </div>
        </ui-select-choices>
    </ui-select>

    <button
        ng-if="data.canClear"
        ng-click="clearTrigger()"
        class="btn btn-no-border svg-icon svg-icon-vmiddle common-color-light-grey margin-right-xs"
        uib-tooltip="Clear"
    >
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </button>

    <!-- Error-->
    <div class="common-color-danger common-size-xxxs" ng-if="!data.loadingTriggers && data.errorLoadingTriggers">
        {{ data.errorLoadingTriggers }}
    </div>
</div>
