import * as React from 'react';

import { EnterpriseComponentAuthorizationParam } from './EnterpriseComponentAuthorizationParam';

import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { Spacer } from '@tonkean/infrastructure';

interface Props {
    expressionProps: Partial<TonkeanExpressionProps>;
}

const EnterpriseComponentAuthorizationOAuth2AuthorizationCode: React.FC<Props> = ({ expressionProps }) => {
    return (
        <>
            <EnterpriseComponentAuthorizationParam>Auth URL:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="authUrl" {...expressionProps} placeholder="Auth URL" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Client Id:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="clientId" {...expressionProps} placeholder="Client Id" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Client Secret:</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="clientSecret" {...expressionProps} placeholder="Client Secret" />
            <Spacer height={12} />

            <EnterpriseComponentAuthorizationParam>Account Id</EnterpriseComponentAuthorizationParam>
            <FormikTonkeanExpression name="accountId" {...expressionProps} placeholder="Account Id" />
        </>
    );
};

export default EnterpriseComponentAuthorizationOAuth2AuthorizationCode;
