import { useAngularService } from 'angulareact';
import type React from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { OperationNode } from '@tonkean/tonkean-entities';

function useMoveNode(setNodes?: React.Dispatch<React.SetStateAction<OperationNode[]>>) {
    const $rootScope = useAngularService('$rootScope');
    const [, updateOperationNode] = useLazyTonkeanService('updateOperationNode');

    // eslint-disable-next-line id-length
    const updateNodeFunc = (node: OperationNode, x: number, y: number) => {
        if (!setNodes) {
            return;
        }

        updateOperationNode(node.id, undefined, x, y).catch(() => {
            $rootScope.$emit('alert', {
                msg: `An error occurred while trying to move ${node.operationConfiguration.displayName}.`,
                type: 'danger',
            });
        });

        setNodes((currentNodes) =>
            currentNodes.map((currentNode) => {
                if (currentNode.id === node.id) {
                    return {
                        ...currentNode,
                        x,
                        y,
                    };
                }

                return currentNode;
            }),
        );
    };

    return updateNodeFunc;
}

export default useMoveNode;
