import template from './tnkTip.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';

function tnkTip() {
    return {
        restrict: 'E',
        scope: {
            type: '@',
            beaconPlacement: '@',
            popoverPlacement: '@',
        },
        template,
        controller: [
            '$scope',
            'consts',
            'tonkeanService',
            function ($scope, consts, tonkeanService) {
                $scope.tip = consts.getTips()[$scope.type];
                $scope.hideBeacon = false;

                $scope.popover = {};

                $scope.init = function () {
                    if ($scope.$root.as && $scope.$root.as.currentUser && $scope.$root.as.currentUser.metadata) {
                        if (!$scope.$root.as.currentUser.metadata.tipsCompleted) {
                            $scope.$root.as.currentUser.metadata.tipsCompleted = {};
                        }

                        setHideBeacon();
                        $scope.$root.$watch('as.currentUser.metadata.allTipDone', setHideBeacon);
                    }
                };

                $scope.tipDone = function () {
                    analyticsWrapper.track('Done tip', { category: 'Tips', label: $scope.type });

                    if (!$scope.$root.as.currentUser.metadata) {
                        $scope.$root.as.currentUser.metadata = {};
                    }
                    if (!$scope.$root.as.currentUser.metadata.tipsCompleted) {
                        $scope.$root.as.currentUser.metadata.tipsCompleted = {};
                    }

                    $scope.$root.as.currentUser.metadata.tipsCompleted[$scope.type] = true;

                    complete();
                };

                $scope.doneAll = function () {
                    analyticsWrapper.track('Opt out tips', { category: 'Tips', label: $scope.type });
                    $scope.$root.as.currentUser.metadata.allTipDone = true;
                    complete();
                };

                function complete() {
                    $scope.popover.isOpen = false;
                    $scope.hideBeacon = true;
                    tonkeanService.updateProfileMetadata($scope.$root.as.currentUser.metadata);
                }

                function setHideBeacon() {
                    if ($scope.$root.as && $scope.$root.as.currentUser && $scope.$root.as.currentUser.metadata) {
                        const tipCompleted =
                            $scope.$root.as.currentUser.metadata.tipsCompleted &&
                            $scope.$root.as.currentUser.metadata.tipsCompleted[$scope.type];
                        $scope.hideBeacon = tipCompleted || $scope.$root.as.currentUser.metadata.allTipDone;
                    }
                }

                $scope.init();
            },
        ],
    };
}
export default angular.module('tonkean.app').directive('tnkTip', tnkTip);
