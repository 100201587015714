import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function ManualNextStepsLogicConfigurationCtrl(
    $scope,
    tonkeanUtils,
    consts,
    utils,
    authenticationService,
    projectManager,
    communicationIntegrationsService,
    workflowVersionManager,
    syncConfigCacheManager,
    customTriggerManager,
    integrationsConsts,
    formManager,
) {
    const ctrl = this;
    const defaultActionName = consts.getDefaultLogicName();

    $scope.as = authenticationService;
    $scope.pm = projectManager;
    $scope.tonkeanUtils = tonkeanUtils;
    $scope.cis = communicationIntegrationsService;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        innerTracksTemplate: [],
        dynamicInnerTracksTemplate: [],
        shouldShowRemoveButton: false,
        shouldSendNotification: false,
        existingManualItemsCreatorPersonSelectionConfiguration: {},
        evaluatedPostText: '{{TNK_TITLE}}',

        previewButtons: [
            { text: 'Post an update' },
            { text: 'Ask me later' },
            { text: 'Create inner-items' },
            { text: 'More...' },
        ],
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = ctrl.previewEvaluationSource;
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = ctrl.invalidLogics;
        }
    };

    /**
     * Occurs on init of the component .
     */
    ctrl.$onInit = function () {
        // If no configured items set the first item as the logic title
        if (!$scope.data.innerTracksTemplate) {
            $scope.data.innerTracksTemplate = [];
        }

        if ($scope.data.innerTracksTemplate.length === 0 && $scope.data.dynamicInnerTracksTemplate.length === 0) {
            if ($scope.data.configuredLogic.node.displayName !== defaultActionName) {
                $scope.data.innerTracksTemplate.push({ title: $scope.data.configuredLogic.node.displayName });
            } else {
                $scope.data.innerTracksTemplate.push({});
            }
        }
    };

    /**
     * Occurs on update of the actions definition.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const parsedPersonSelectionConfig = tonkeanUtils.buildPersonSelectionConfiguration(
                $scope.data.manualItemsCreatorPersonSelectionConfiguration,
            );
            const definition = {
                personDefinition: parsedPersonSelectionConfig,
                skipNotification: !$scope.data.shouldSendNotification,
            };

            if ($scope.data.innerTracksTemplate.innerTrackTemplatesCollection) {
                definition['innerTracksTemplate'] = $scope.data.innerTracksTemplate;
            }

            if ($scope.data.dynamicInnerTracksTemplate.innerTrackTemplatesCollection) {
                definition['dynamicInnerTracksTemplate'] = $scope.data.dynamicInnerTracksTemplate;
            }

            const actionDefinition = {
                actions: [
                    {
                        type: 'MANUAL_NEXT_STEPS',
                        definition,
                    },
                ],
            };

            // We need to run validation here because we want to show an error to
            // the user in real time so the user would not get confused when we delete empty items.
            validate(definition);

            refreshRemoveButton();

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Occurs once the template is changed.
     */
    $scope.onInnerTracksTemplateConfigChanged = function (innerTracksTemplate, shouldSaveLogic) {
        $scope.data.innerTracksTemplate = innerTracksTemplate;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once the template is changed.
     */
    $scope.onDynamicInnerTracksTemplateConfigChanged = function (innerTracksTemplate, shouldSaveLogic) {
        $scope.data.dynamicInnerTracksTemplate = innerTracksTemplate;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Choose who will be the updater of the field
     */
    $scope.onManualItemsCreatorPersonSelectionConfigurationChanged = function (
        personSelectionConfiguration,
        shouldSaveLogic,
    ) {
        $scope.data.manualItemsCreatorPersonSelectionConfiguration = personSelectionConfiguration;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'MANUAL_NEXT_STEPS',
        );
        if (customTriggerAction) {
            $scope.data.shouldSendNotification = !customTriggerAction.customTriggerActionDefinition.skipNotification;
            $scope.data.existingManualItemsCreatorPersonSelectionConfiguration =
                customTriggerAction.customTriggerActionDefinition.personDefinition;

            if (customTriggerAction.customTriggerActionDefinition.innerTracksTemplate) {
                $scope.data.innerTracksTemplate =
                    customTriggerAction.customTriggerActionDefinition.innerTracksTemplate
                        .innerTrackTemplatesCollection || [];
            }

            if (customTriggerAction.customTriggerActionDefinition.dynamicInnerTracksTemplate) {
                $scope.data.dynamicInnerTracksTemplate =
                    customTriggerAction.customTriggerActionDefinition.dynamicInnerTracksTemplate
                        .innerTrackTemplatesCollection || [];
            }

            refreshRemoveButton();
        }
    }

    function refreshRemoveButton() {
        let dynamicInnerTracks = $scope.data.dynamicInnerTracksTemplate;
        let innerTracks = $scope.data.innerTracksTemplate;

        if (utils.isNullOrUndefined($scope.data.dynamicInnerTracksTemplate.length)) {
            dynamicInnerTracks = $scope.data.dynamicInnerTracksTemplate.innerTrackTemplatesCollection;
        }

        if (utils.isNullOrUndefined($scope.data.innerTracksTemplate.length)) {
            innerTracks = $scope.data.innerTracksTemplate.innerTrackTemplatesCollection;
        }

        $scope.data.shouldShowRemoveButton = dynamicInnerTracks.length + innerTracks.length > 1;
    }

    function validate(definition) {
        const params = {
            definition,
            customTrigger: $scope.data.configuredLogic.node,
            parentTrigger: customTriggerManager.getDirectParentInWorkflowVersion(
                $scope.data.workflowVersionId,
                $scope.data.configuredLogic.node.id,
            ),
            integrationsConsts,
            communicationIntegrationsService,
            group: projectManager.groupsMap[$scope.data.groupId],
            syncConfigCacheManager,
            workflowVersionManager,
            formManager,
            project: projectManager.project,
            projectManager,
            childImpacts: $scope.data.configuredLogic.impacts,
        };
        $scope.data.invalidLogics[$scope.data.configuredLogic.node.id] = consts
            .getLogicBlockTypes()
            .MANUAL_NEXT_STEPS.validator(params);
    }
}

export default angular
    .module('tonkean.app')
    .controller(
        'ManualNextStepsLogicConfigurationCtrl',
        lateConstructController(ManualNextStepsLogicConfigurationCtrl),
    );
