import template from './tnkTagsInput.template.html?angularjs';

function tnkTagsInput() {
    return {
        restrict: 'E',
        scope: {
            isFunc: '=',
            maxTags: '=',
            tags: '=',
            loadOnEmpty: '=',
            latestTagSearch: '=',
            allowLeftoverText: '=',
            placeholder: '@',
            autoCompleteTemplate: '@',
            selectedItemTemplate: '@',
            onTagAdded: '&',
            onTagRemoved: '&',
            includeExternal: '<',
        },
        template,
        controller: 'TagsInputCtrl',
    };
}

angular.module('tonkean.app').directive('tnkTagsInput', tnkTagsInput);
