import { ModuleBrowserSolutionBoxTabsState } from '@tonkean/infrastructure';
import { lateConstructController } from '@tonkean/angular-components';
import checkSolutionCanPublish from './checkSolutionCanPublish';
import { analyticsWrapper } from '@tonkean/analytics';
import { WORKER_TYPES } from '@tonkean/constants';

/* @ngInject */
function WorkersBrowserCtrl(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $q,
    $timeout,
    $location,
    projectManager,
    groupInfoManager,
    modalUtils,
    workflowVersionManager,
    utils,
    workflowFolderManager,
    authenticationService,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.modalUtils = modalUtils;
    $scope.wvm = workflowVersionManager;
    $scope.utils = utils;
    $scope.$state = $state;
    $scope.wfm = workflowFolderManager;
    $scope.as = authenticationService;

    $scope.data = {
        currentTab: {},
        workerTypes: WORKER_TYPES,
        tab: $stateParams.tab,
        selectedGroupId: $stateParams.g,
        workflowVersionId: null,
        tipIndex: 0,
        folderIdToMakersMap: {},
        workflowFolderFetchLimit: 20,
        workflowFolderFetchSkip: 0,
        deleteWorkflowFolderLoading: {},
        deleteWorkflowFolderErrorMessage: {},
        loadingGroups: false,
        folderCreateTileHoverMap: {},
        searchTerm: '',
        // The keys are the folder id, and the values are either true if can publish or string with the reason of why can't.
        canPublish: {},
        sidePaneWorkflowFolder: undefined,
        scrolledToFolderId: null,
        isInTrial: projectManager.isSelectedProjectInTrial(),
        reachedSolutionsLimit: false,
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        let loadingPromise;

        // Call get groups so all of our inner components have updated group data.
        const groupsPromise = groupInfoManager.getGroups(false);

        const folderPromise = workflowFolderManager
            .getWorkflowFolders($scope.pm.project.id, false, 50, 50)
            .then(() => checkIfCanPublish());
        loadingPromise = $q.all([groupsPromise, folderPromise]);

        $scope.data.loadingGroups = true;
        loadingPromise.finally(() => {
            $scope.data.loadingGroups = false;

            if ($stateParams.folderId) {
                // The next digest cycle should contain the loaded element
                $timeout(() => {
                    const someElement = angular.element(document.getElementById(`folder-${$stateParams.folderId}`));
                    const scrollContainer = angular.element(document.querySelector('#project-board'));
                    scrollContainer.scrollTo(someElement, 20, 500);
                    $scope.data.scrolledToFolderId = $stateParams.folderId;
                });
            }
        });

        if ($stateParams.solutionOnReportTab) {
            $stateParams.solutionOnReportTab
                .split(',')
                .forEach(
                    (solutionId) => ($scope.data.currentTab[solutionId] = ModuleBrowserSolutionBoxTabsState.REPORTS),
                );
        }
        $scope.data.reachedSolutionsLimit =
            $scope.pm.projectData.maxAllowedWorkflowFolders <= $scope.getNonSandboxWorkflowFoldersCount();
    };

    $scope.deleteWorkflowFolder = function (folderId) {
        $timeout(() => {
            if ($scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id][folderId].groupIds.length !== 0) {
                $scope.data.deleteWorkflowFolderErrorMessage[folderId] =
                    'Please remove all the Modules before deleting a solution';
                return;
            }

            $scope.data.deleteWorkflowFolderLoading[folderId] = true;
            workflowFolderManager
                .deleteWorkflowFolder(folderId, $scope.pm.project.id)
                .then(() => checkIfCanPublish())
                .catch((error) => {
                    $scope.data.deleteWorkflowFolderErrorMessage[folderId] = error.data.error.message;
                })
                .finally(() => {
                    $scope.data.deleteWorkflowFolderLoading[folderId] = false;
                });
        });
    };

    $scope.createNewWorkflowFolder = function () {
        return modalUtils.openCreateWorkflowFolderModal($scope.pm.project.id).then((workflowFolder) => {
            if (workflowFolder) {
                $state.go('product.solution', { solutionId: workflowFolder.id });
            } else {
                checkIfCanPublish();
            }
        });
    };

    $scope.updateFolderDisplayName = function (folderId, folderName) {
        return workflowFolderManager.updateWorkflowFolderDisplayName($scope.pm.project.id, folderId, folderName);
    };

    $scope.hasAnyInaccessibleFolders = function () {
        return Object.values($scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id]).some(
            (folder) => !folder.isVisible,
        );
    };

    $scope.onToggleClick = function (index) {
        $scope.openWorker(projectManager.groups[index]);
    };

    $scope.createNewWorker = function () {
        analyticsWrapper.track('Open new module modal', { category: 'Modules browser' });
        modalUtils
            .openChooseWorkerTypeModal()
            .then(() => checkIfCanPublish())
            .then((group) => {
                $scope.openWorker(group);
            });
    };

    $scope.openWorker = function (group) {
        goToEditor(group.id);
    };

    $scope.getMoreTooltip = function (worker) {
        let tooltip = '';
        const limit = $scope.avatarsLimit(worker);
        for (let i = limit; i < worker.originalGroupProjectIntegrations.length; i++) {
            tooltip += worker.originalGroupProjectIntegrations[i].displayName;
            if (i < worker.originalGroupProjectIntegrations.length - 1) {
                tooltip += ', ';
            }
        }
        return tooltip;
    };

    $scope.avatarsLimit = function (worker) {
        const integrationsLimit = 3;

        // Auto check-ins will appear instead of an integration
        const cachedPublishedVersion = workflowVersionManager.getPublishedVersionFromCache(worker.id);
        let limit =
            cachedPublishedVersion && cachedPublishedVersion.shouldSendGatherUpdates
                ? integrationsLimit - 1
                : integrationsLimit;

        // If it's only a single integration more, just show it (instead of the +1 icon)
        limit = worker.originalGroupProjectIntegrations.length === limit + 1 ? limit + 1 : limit;

        return limit;
    };

    /**
     * Does this module answer the search term?
     */
    $scope.moduleSearchFilter = function (groupId) {
        return (
            !$scope.data.searchTerm?.length ||
            projectManager.groupsMap[groupId]?.name.toLowerCase().includes($scope.data.searchTerm.toLowerCase())
        );
    };

    /**
     * Do any of the modules in this solution answer the search term?
     */
    $scope.solutionSearchFilter = function (folder) {
        return folder.displayName.toLowerCase().includes($scope.data.searchTerm.toLowerCase());
    };

    /**
     * Are there any solutions that have a module that answers the search term?
     */
    $scope.anyModuleSearchFilter = function () {
        const folders = Object.values(workflowFolderManager.projectIdToFolderIdToFolderMap[$scope.pm.project.id]);
        return folders.some($scope.solutionSearchFilter);
    };

    $scope.mouseEnterNewModuleTile = function (folderId) {
        $scope.data.folderCreateTileHoverMap[folderId] = true;
    };

    $scope.mouseLeaveNewModuleTile = function (folderId) {
        $scope.data.folderCreateTileHoverMap[folderId] = false;
    };

    $scope.isMapEmpty = function (map) {
        return !map || !Object.keys(map).length;
    };

    $scope.getFoldersCount = function () {
        if ($scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id]) {
            return Object.keys($scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id]).length;
        } else {
            return 0;
        }
    };

    $scope.getNonSandboxWorkflowFoldersCount = function () {
        if ($scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id]) {
            return Object.values($scope.wfm.projectIdToFolderIdToFolderMap[$scope.pm.project.id]).filter(
                (workflowFolder) => !workflowFolder.isSandbox,
            ).length;
        } else {
            return 0;
        }
    };

    $scope.onBackButtonClick = function () {
        $state.params.tab = 'metrics';
    };

    $scope.onPublished = () => {
        $timeout(() => {
            checkIfCanPublish();
        });
    };

    $scope.onWorkerMoved = () => {
        checkIfCanPublish();
    };

    $scope.openWorkflowFolderVersionsSidePane = (workflowFolder) => {
        $timeout(() => {
            $scope.data.sidePaneWorkflowFolder = workflowFolder;
            $rootScope.showBotSidePane = false;
        });
    };

    $scope.onWorkflowFolderVersionsSidePaneClose = () => {
        $timeout(() => {
            $scope.data.sidePaneWorkflowFolder = undefined;
        });
    };

    $scope.onWorkflowFolderVersionsSidePaneExitComplete = () => {
        $timeout(() => {
            // If the side pane has been opened again, don't show the bot side pane.
            if (!$scope.data.sidePaneWorkflowFolder) {
                $rootScope.showBotSidePane = true;
            }
        });
    };

    $scope.$on('$destroy', () => {
        $location.hash('');
    });

    function checkIfCanPublish() {
        const workflowFolders = Object.values(
            workflowFolderManager.projectIdToFolderIdToFolderMap[$scope.pm.project.id],
        );

        const canPublishObj = Object.fromEntries(
            workflowFolders.map((workflowFolder) => {
                const canPublishResult = checkSolutionCanPublish(projectManager, workflowFolder);
                return [workflowFolder.id, canPublishResult];
            }),
        );

        $scope.data.canPublish = canPublishObj;
    }

    $scope.onTabSelected = (folderId, newTab) => {
        const newTabState = $stateParams.solutionOnReportTab?.split(',') || [];
        const solutionIndex = newTabState.indexOf(folderId);

        // Keeping state updated for browser back and forward
        if (newTab === ModuleBrowserSolutionBoxTabsState.REPORTS) {
            if (solutionIndex === -1) {
                // when moving to reports tab and the workflow is not already in reports tab
                newTabState.push(folderId);
            }
        } else {
            newTabState.splice(solutionIndex, 1);
        }
        $stateParams.solutionOnReportTab = newTabState.join(',');
        $state.go('.', { solutionOnReportTab: $stateParams.solutionOnReportTab }, { notify: false, inherit: true });

        // Moving current state to the requested tab
        $scope.data.currentTab[folderId] = newTab;
    };

    $scope.onSearchTermChange = () => {
        // Resetting all tabs to modules in the browser history
        $stateParams.solutionOnReportTab = '';
        $state.go('.', { solutionOnReportTab: '' }, { notify: false });

        // Resetting all tabs to moduels in current state
        $scope.data.currentTab = {};
    };

    function goToEditor(id) {
        $state.go('product.workerEditor', { g: id });
    }
}

export default angular.module('tonkean.app').controller('WorkersBrowserCtrl', lateConstructController(WorkersBrowserCtrl));
