import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';
import { getFieldConditionToApiDefinitionMap } from '@tonkean/constants';

/* @ngInject */
function tnkTriggersGalleryCtrl(
    $scope,
    $state,
    $stateParams,
    $location,
    $timeout,
    utils,
    triggerGalleryConsts,
    builtInListsConsts,
    integrations,
    apiConsts,
    authenticationService,
    projectManager,
    communicationIntegrationsService,
) {
    /* jshint validthis: true */
    const ctrl = this;

    const integrationGroups = integrations.getIntegrationGroups();
    const communicationIntegrations = integrations
        .getIntegrationGroups()
        .communication.integrations(projectManager.project.id);

    $scope.cis = communicationIntegrationsService;
    $scope.as = authenticationService;
    $scope.pm = projectManager;
    $scope.utils = utils;

    $scope.steps = {
        gallery: 'gallery',
        triggerConfig: 'triggerConfig',
    };
    $scope.groupId = $scope.pm.groupId;

    $scope.data = {
        triggerTemplates: triggerGalleryConsts.getTriggerTemplates(),
        builtInLists: builtInListsConsts.getBuiltInLists(),
        fieldConditionToApiDefinitionMap: getFieldConditionToApiDefinitionMap(),
        currentStep: $scope.steps.gallery,
        currentTrigger: null,
        currentTriggerInfo: {
            integrationTypes: [],
            dataSources: {},
        },
        title: ctrl.title,
        btnLabel: ctrl.isIframe ? 'Get Started' : 'Use Trigger',
        // The data source we're defining for the field definition.
        selectedDataTypeObject: null,
        // Integration selection properties.
        currentIntegration: null,
        sources: null,
        projectHasCommunication: $scope.as.isUserAuthenticated()
            ? $scope.cis.anyIntegrationSupports($scope.cis.features.notifications)
            : false,
        integrationState: {},
        searchIntegrationNameText: '',
        searchTriggersNameText: '',
        webhookSourcesLimit: 8,
    };

    $scope.init = function () {
        // Make sure the trigger info is initialized.
        $scope.data.currentTriggerInfo = {};

        // If a trigger id was given, go to that trigger (if the id is valid).
        if (!utils.isNullOrEmpty($stateParams.id) && $scope.data.triggerTemplates[$stateParams.id.toUpperCase()]) {
            $scope.goToTriggerStep($stateParams.id.toUpperCase());
        }

        // Load optional integrations sources
        loadSources();
        // Load optional webhook integrations sources
        apiConsts.loadWebhookSources();

        $scope.data.allCategories = {};

        // Init all triggers infos according to the templates
        $scope.data.triggersInfos = [];
        for (const template in $scope.data.triggerTemplates) {
            const triggerInfo = $scope.data.triggerTemplates[template];
            initTriggerInfo(triggerInfo, triggerInfo);
            $scope.data.triggersInfos.push(triggerInfo);

            // add categories
            if (triggerInfo.categories) {
                for (let i = 0; i < triggerInfo.categories.length; i++) {
                    $scope.data.allCategories[triggerInfo.categories[i]] = true;
                }
            }
        }

        triggerOnStepChange($scope.currentStep);
    };

    // Load the webhook sources after the apiConsts is done loading them.
    $scope.$on('webhookSourcesLoaded', () => {
        loadWebhookSources();
    });

    $scope.goToTriggerStep = function (triggerId) {
        analyticsWrapper.track('Go to trigger', { category: 'Trigger Gallery', label: triggerId });

        $scope.data.currentTrigger = $scope.data.triggerTemplates[triggerId];
        $scope.data.currentStep = $scope.steps.triggerConfig;

        // Update the state (so the user can navigate back and forward).
        $state.current.reloadOnSearch = false;
        $location.search('id', triggerId.toLowerCase());
        $timeout(() => {
            $state.current.reloadOnSearch = true;
        });

        $scope.data.currentTriggerInfo = {
            integrationTypes: [],
            dataSources: {},
        };

        initTriggerInfo($scope.data.currentTrigger, $scope.data.currentTriggerInfo);

        // scroll to top
        if (document.querySelector('#project-board')) {
            document.querySelector('#project-board').scrollTop = 0;
        } else {
            document.documentElement.scrollTop = 0;
        }

        triggerOnStepChange($scope.steps.triggerConfig);
    };

    $scope.goToGalleryStep = function () {
        $scope.data.currentTrigger = null;
        $scope.data.currentTriggerInfo = {};
        $scope.data.currentStep = $scope.steps.gallery;

        // Clear the trigger id state param.
        $state.current.reloadOnSearch = false;
        $location.search('id', null);
        $timeout(() => {
            $state.current.reloadOnSearch = true;
        });

        triggerOnStepChange($scope.steps.gallery);
    };

    $scope.goToBuiltInList = function (listId) {
        if (ctrl.isIframe) {
            ctrl.iframeAction({ triggerId: null, builtInListId: listId });
        } else {
            analyticsWrapper.track('Go to built in list', { category: 'Trigger Gallery', label: listId });
            $state.go('createList', { listType: listId, g: null, autoCreateList: false });
        }
    };

    $scope.useTrigger = function () {
        // If ctrl is open from an iframe(website) call iframeAction
        if (ctrl.isIframe) {
            analyticsWrapper.track('Use trigger', { category: 'Trigger Gallery', label: 'From website' });
            ctrl.iframeAction({ triggerId: $scope.data.currentTrigger.id });
        } else {
            analyticsWrapper.track('Use trigger', { category: 'Trigger Gallery', label: 'From app' });
            // If ctrl is open from the app
            // Go to the lists page, and tell it to open a trigger modal for our trigger.
            $state.go('product.board', {
                filter: 'all',
                g: projectManager.groupDefaultId,
                st: null,
                createTriggerId: $scope.data.currentTrigger.id,
            });
            // Close the modal if we are in a modal.
            if (ctrl.onClose) {
                ctrl.onClose();
            }
        }
    };

    /**
     * Init the trigger template, populate with datasources icons
     */
    function initTriggerInfo(trigger, triggerInfo) {
        if (trigger && trigger.query) {
            // Load the integration types used in the query.
            loadTriggerQueriesIntegrationTypes([trigger.query], triggerInfo);

            // Make sure the data sources array exists.
            if (!triggerInfo.dataSources) {
                triggerInfo.dataSources = {};
            }

            // Set the data sources we want to show (according to the loaded integration types and defaultDataSources in the consts.
            if (triggerInfo.integrationTypes) {
                for (let i = 0; i < triggerInfo.integrationTypes.length; i++) {
                    const integrationTypeName = triggerInfo.integrationTypes[i];
                    if (trigger.defaultDataSources && trigger.defaultDataSources[integrationTypeName]) {
                        // If the integration type name exists in the trigger template's defaultDataSources, take it.
                        triggerInfo.dataSources[integrationTypeName] = getTriggersIntegrationDefaultDataSource(
                            trigger.defaultDataSources[integrationTypeName],
                        );
                    } else if (integrationGroups[integrationTypeName]) {
                        // Otherwise, take the first integration in that integration group.
                        triggerInfo.dataSources[integrationTypeName] =
                            getTriggersIntegrationDataSource(integrationTypeName);
                    }
                }
            }
        }
    }

    /**
     * Returns an object with name, iconUrl and iconClass from the given integrationTypeName.
     */
    function getTriggersIntegrationDataSource(integrationTypeName) {
        return {
            name: utils.capitalize(integrationGroups[integrationTypeName].title),
            iconUrl: null, // We've got no iconUrl but an icon class that sets the icon.
            iconClass: `initiative-integration-icon mod-${integrationGroups[integrationTypeName].integrations[0]}`,
        };
    }

    /**
     * Enriches the given data source with iconClass needed and returns it.
     */
    function getTriggersIntegrationDefaultDataSource(dataSource) {
        // Add the classes needed for image urls.
        dataSource.iconClass = 'initiative-integration-icon mod-circle';
        if (dataSource.integration) {
            dataSource.iconClass += ` mod-${dataSource.integration}`;
        }
        return dataSource;
    }

    /**
     * Goes over the queries recursively and extracts the integration types used.
     * @param queries - an array or query object.
     * @param triggerInfo - the trigger info object.
     */
    function loadTriggerQueriesIntegrationTypes(queries, triggerInfo) {
        // Make sure we have an integration type array.
        if (!triggerInfo.integrationTypes) {
            triggerInfo.integrationTypes = [];
        }

        if (queries && queries.length) {
            // Go over queries.
            for (const query of queries) {
                // Go over filters.
                if (query.filters && query.filters.length) {
                    for (let j = 0; j < query.filters.length; j++) {
                        const filter = query.filters[j];

                        // Extract integration type if it wasn't already collected.
                        if (filter.integrationType && !triggerInfo.integrationTypes.includes(filter.integrationType)) {
                            triggerInfo.integrationTypes.push(filter.integrationType);
                        }
                    }
                }

                // Go on recursively if we have inner queries.
                if (query.queries && query.queries.length) {
                    loadTriggerQueriesIntegrationTypes(query.queries, triggerInfo);
                }
            }
        }
    }

    /**
     * Initializes $scope.data.sources with all the non-connected sources.
     */
    function loadSources() {
        const existingIntegrations = [];

        // Constructing a list of existing integration unique types without duplicates (only if we have a project - we might not be authenticated).
        if ($scope.pm.project) {
            for (let j = 0; j < $scope.pm.project.integrations.length; j++) {
                const type =
                    $scope.pm.project.integrations[j].integration.isNativeIntegration &&
                    !$scope.pm.project.integrations[j].integration.supportsMultipleIntegrationsPerUser
                        ? $scope.pm.project.integrations[j].integration.integrationType
                        : $scope.pm.project.integrations[j].integration.integrationUniqueType;
                existingIntegrations.push(type.toLowerCase());
            }
        }

        // Taking only the sources that do not already exist.
        $scope.data.sources = integrations.getUniqueIntegrationsDataSources(
            existingIntegrations,
            projectManager.projectData.canBeCreatedByUserIntegrationTypes,
            projectManager.project.id,
        );
    }

    // Load optional webhook integrations sources from api consts
    function loadWebhookSources() {
        $scope.data.webhooksSources = apiConsts.webhooksSources;
    }

    function triggerOnStepChange(newStep) {
        $timeout(function () {
            if (ctrl.onStepChanged) {
                ctrl.onStepChanged({ step: newStep });
            }
        });
    }

    /**
     * On integration selected set the source.
     */
    $scope.selectIntegration = function (selectedIntegration, source) {
        source.name = selectedIntegration.name;
        source.label = selectedIntegration.displayName;
        source.iconClass = `initiative-integration-icon mod-${selectedIntegration.integration.integrationType.toLowerCase()}`;
        source.iconUrl = '';
    };

    /**
     * On integration selected set the source by integrationName.
     */
    $scope.selectIntegrationByName = function (selectedIntegrationName, source) {
        source.name = selectedIntegrationName;
        source.label = selectedIntegrationName;
        source.iconClass = `initiative-integration-icon mod-${selectedIntegrationName}`;
        source.iconUrl = '';
    };

    /**
     * On webhook integration selected set the source.
     */
    $scope.selectWebhookIntegration = function (selectedIntegration, source) {
        source.name = selectedIntegration.name;
        source.label = selectedIntegration.name;
        source.iconClass = 'initiative-integration-icon';
        source.iconUrl = selectedIntegration.imgUrl;
    };

    /**
     * load 8 more optional integrations.
     * Stop close event when clicking on the dropdown by stopping propagation.
     */
    $scope.loadMoreIntegrations = function (event) {
        $scope.data.webhookSourcesLimit = $scope.data.webhookSourcesLimit + 8;
        utils.eventStopPropagation(event);
    };

    $scope.isNotCommunicationIntegration = function (integration) {
        return !communicationIntegrations.includes(integration.integrationType.toLowerCase());
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('tnkTriggersGalleryCtrl', lateConstructController(tnkTriggersGalleryCtrl));
