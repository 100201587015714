import { useAngularService } from 'angulareact';
import React, { useContext, useMemo } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react/dist/components/editable';
import styled from 'styled-components';

import ExpressionEditorElementWrapper from './ExpressionEditorElementWrapper';
import ExpressionEditorTagWrapper from './ExpressionEditorTagWrapper';
import ExpressionEditorVariableTag from './ExpressionEditorVariableTag';
import ExpressionEditorContext from '../../entities/ExpressionEditorContext';
import type FormulaElement from '../../entities/FormulaElement';
import { getVariables } from '../../utils/convertStringToNodes';
import createFormulaElement from '../../utils/createFormulaElement';

const FormulaTag = styled(ExpressionEditorTagWrapper)`
    background: #e2f5e3;
`;

const Text = styled.div`
    display: inline;
    vertical-align: baseline;
`;

interface Props extends RenderElementProps {
    element: FormulaElement;
}

const ExpressionEditorFormulaElement: React.FC<React.PropsWithChildren<Props>> = ({
    attributes,
    children,
    element,
}) => {
    const { groupId, workflowVersionId, additionalTabs } = useContext(ExpressionEditorContext);
    const editor = useSlateStatic();
    const modal = useAngularService('modal');

    const parts = useMemo(() => {
        return getVariables(element.formulaContent).map((part, index) => {
            const variableId = part.isVariable ? part.text.split('|')[0] : '';
            return { ...part, key: `${variableId}${index}` };
        });
    }, [element.formulaContent]);

    function updateFormula(newFormula: string) {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        Transforms.setNodes(editor, createFormulaElement(newFormula), { at: path });
    }

    const onClick = () => {
        modal
            .openInlineFormulaEditorModal(
                groupId || '',
                workflowVersionId || '',
                null,
                element.formulaContent,
                additionalTabs,
            )
            ?.result.then(({ formulaExpression }) => {
                updateFormula(formulaExpression);
            });
    };

    const onVariableChange = (partIndex: number, newVariableContent: string) => {
        const newFormulaContent = parts
            .map((part, index) => {
                if (part.isVariable) {
                    const content = index === partIndex ? newVariableContent : part.text;
                    return `{{${content}}}`;
                }

                return part.text;
            })
            .join('');

        updateFormula(newFormulaContent);
    };

    return (
        <ExpressionEditorElementWrapper editorChildren={children} attributes={attributes}>
            <FormulaTag onClick={onClick} elementToDelete={element} active>
                {parts.map((part, index) =>
                    part.isVariable ? (
                        <ExpressionEditorVariableTag
                            key={part.key}
                            variableContent={part.text}
                            onChange={(newVariableContent) => onVariableChange(index, newVariableContent)}
                            insideFormula
                        />
                    ) : (
                        <Text key={part.key}>{part.text}</Text>
                    ),
                )}
            </FormulaTag>
        </ExpressionEditorElementWrapper>
    );
};

export default ExpressionEditorFormulaElement;
