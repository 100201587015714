<div class="bot-gather-example-modal text-center common-close-btn-container">
    <div class="common-close-btn margin-normal-sm" ng-click="$dismiss()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </div>

    <div class="common-title-md line-height-normal margin-bottom margin-top">
        What happens when you
        <br />
        assign someone to a track
    </div>

    <div class="margin-auto common-width-75-no-xs">
        When you assign an owner to a track the Tonkean Module will ping them for you, to get you updates and next
        steps.
        <br />
        Here are some examples:
    </div>

    <tnk-gallery images="images[data.mode]" ng-class="{ 'mod-mail': data.mode === 'mail' }"></tnk-gallery>

    <div class="flex mod-center margin-top-lg padding-left-xlg padding-right-xlg margin-bottom">
        <button class="btn btn-primary mod-inverse common-no-outline" ng-click="modalUtils.openTestBotModal()">
            Send me a test
        </button>
    </div>
</div>
