import { analyticsWrapper } from '@tonkean/analytics';

function MoveToTrackModalCtrl($scope, $filter, $rootScope, $uibModalInstance, trackHelper, initiative) {
    $scope.data = {
        initiative,
    };

    $scope.init = function () {
        $scope.popover = {
            search: {
                isOpen: false,
                isFocused: false,
                searchText: '',
                results: [],
            },
        };
    };

    /**
     * Fired from the moveToTrackPopoverTemplate when an item is selected.
     * @param $item - the newly selected item (track).
     */
    $scope.onSelect = function ($item) {
        // Set the properties to the desired state after selection.
        $scope.popover.search.isOpen = false;
        $scope.popover.isActive = false;
        $scope.popover.search.searchText = $item.title;
        // Save the selected item so we can later submit it and do the move.
        $scope.selectedItem = $item;
    };

    $scope.submit = function () {
        // Only do something if an track to move into was selected.
        if ($scope.selectedItem && $scope.selectedItem.id) {
            $scope.loading = true;

            analyticsWrapper.track('Move to track', { category: 'Move To Track Modal' });

            const initiativeWasRoot = !initiative.parent || !initiative.parent.id;

            trackHelper
                .moveInitiative(
                    initiative.id,
                    initiative.parent ? initiative.parent.id : null,
                    null,
                    $scope.selectedItem.id,
                )
                .then(() => {
                    $scope.$emit('alert', {
                        msg: `Track moved into "${$scope.selectedItem.title}" successfully!`,
                        type: 'success',
                    });

                    $rootScope.$broadcast('newActivityUpdate');

                    if (initiativeWasRoot) {
                        // Let the initiatives control know an item was removed from root (they should remove it from their root list).
                        $rootScope.$broadcast('initiativeMovedFromRoot', { initiativeId: initiative.id });
                    }

                    $uibModalInstance.close();
                })
                .catch(() => {
                    $scope.loading = false;
                    $scope.$emit('alert', {
                        msg: `Failed moving Track into "${$scope.selectedItem.title}`,
                        type: 'danger',
                    });
                });
        }
    };

    $scope.init();
}
export default angular.module('tonkean.app').controller('MoveToTrackModalCtrl', MoveToTrackModalCtrl);
