import React from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage, H4, Paragraph } from '@tonkean/infrastructure';
import CopyInput from '@tonkean/infrastructure/components/CopyInput';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

const WebhookUrlDisplayWrapper = styled.div`
    margin-bottom: 32px;
`;
const SubHeader = styled(H4)`
    margin-bottom: 16px;
`;

const StyledCopyInput = styled(CopyInput)`
    flex-grow: 1;
`;

interface Props {
    entity: ProjectIntegrationEntity;
    projectIntegration: ProjectIntegration;
}

const ProjectIntegrationEntityLiveStreamWebhookUrl: React.FC<Props> = ({ entity, projectIntegration }) => {
    const { data: incomingWebhookResult, error: incomingWebhookError } = useTonkeanService(
        'getIncomingWebhookByProjectIntegrationId',
        entity.projectId,
        projectIntegration.id,
        null,
        'TONKEAN',
    );

    return (
        <>
            <SubHeader $bold>Webhook URL</SubHeader>

            <Paragraph style={{ marginBottom: '4px' }} $light>
                Copy this url and call using HTTP POST with a JSON object or array of JSON objects.
            </Paragraph>

            {incomingWebhookError && <ErrorMessage>Can't get webhook url</ErrorMessage>}

            <WebhookUrlDisplayWrapper>
                {incomingWebhookResult && <StyledCopyInput value={incomingWebhookResult.incomingWebhook.newUrl} />}
            </WebhookUrlDisplayWrapper>
        </>
    );
};

export default ProjectIntegrationEntityLiveStreamWebhookUrl;
