import template from './tnkAssignValueForInnerItemField.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkAssignValueForInnerItemField', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        initiative: '<',
        onInnerTracksTemplateConfigChanged: '&',
        onRemoveItem: '&',
        index: '<',
        showRemoveItem: '<',
        ownerConfiguration: '<',
        onOwnerChanged: '&',
        configuredLogic: '<',
        isDynamic: '<',
    },
    template,
    controller: 'AssignValueForInnerItemFieldCtrl',
});
