import template from './tnkAddUserPopover.template.html?angularjs';

function tnkAddUserPopover() {
    return {
        restrict: 'E',
        scope: {
            selectedPeople: '=',
            maxTags: '<',
            onSave: '&',
            onClose: '&',
        },
        template,
        controller: 'AddUserPopoverCtrl',
    };
}
angular.module('tonkean.app').directive('tnkAddUserPopover', tnkAddUserPopover);
