import React from 'react';
import styled from 'styled-components';

import { ProcessMapperEmptyState } from '@tonkean/svg';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Text = styled.div`
    color: ${Theme.colors.gray_500};
    margin-top: 10px;
    font-size: ${FontSize.XXLARGE_20};
`;
const ProcessMapperWidgetEmptyState: React.FC = ({}) => {
    return (
        <Container>
            <ProcessMapperEmptyState />
            <Text>Select a process map to display</Text>
        </Container>
    );
};

export default ProcessMapperWidgetEmptyState;
