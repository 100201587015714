import template from './excelSheetSelector.template.html?angularjs';

/**
 * Component for selecting a excel 365 sheet.
 */
export default angular.module('tonkean.app').component('tnkExcelSheetSelector', {
    bindings: {
        projectIntegration: '<', // The project integration object.
        configuredInWorkflowGroupId: '@', // If given, the integration is configured in a workflow version context and it is the group id.
        configuredInWorkflowVersionId: '@', // If given, the integration is configured in a workflow version context and it is the group id.
        onProjectIntegrationCreated: '&', // Occurs once the creation of the project integration is finished.
        onCancel: '&', // Occurs when an integration is closed
        displayColumnSelection: '<', // If true, will display the column selection for the user. If false, will only display column selection if configuredInWorkflowVersionId is present.
    },
    template,
    controller: 'ExcelSheetSelectorCtrl',
});
