import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function TwilioSmsCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        definition: ctrl.definition,
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    $scope.onTonkeanFromPhoneNumberExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fromPhoneNumberExpression = expression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTonkeanToPhoneNumberExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.toPhoneNumberExpression = expression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTonkeanMessageBodyExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.messageBodyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller('TwilioSmsCustomActionsDefinitionCtrl', lateConstructController(TwilioSmsCustomActionsDefinitionCtrl));
