import BuiltInListsUtils from '../../../../shared/services/builtInLists/builtInListsUtils';
import { TONKEAN_ENTITY_TYPE } from '@tonkean/constants';
import { getDefaultTonkeanQuery, getTonkeanEntityType } from '@tonkean/tonkean-utils';
import { analyticsWrapper } from '@tonkean/analytics';
import { getInviteMessages } from '@tonkean/constants';
import { VIEW_TYPES } from '@tonkean/constants';

function SetupGroupCtrl(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $location,
    $sessionStorage,
    $q,
    $timeout,
    $sce,
    $localStorage,
    consts,
    integrations,
    apiConsts,
    customTriggerManager,
    customFieldsManager,
    modalUtils,
    modal,
    builtInListsConsts,
    projectManager,
    authenticationService,
    tonkeanService,
    createProjectApis,
    utils,
    inviteManager,
    onBoardingManager,
    trackHelper,
    tonkeanUtils,
    groupManager,
    groupInfoManager,
    workflowVersionManager,
    workflowVersionDataSourceManager,
    formManager,
    syncConfigCacheManager,
) {
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.tonkeanService = tonkeanService;
    $scope.modalUtils = modalUtils;
    $scope.as = $rootScope.as;
    $scope.consts = consts;
    $scope.integrations = integrations;
    $scope.viewTypes = VIEW_TYPES;
    $scope.utils = utils;
    $scope.builtInLists = {};
    $scope.categories = {};
    $scope.categoriesList = [];
    $scope.wvm = workflowVersionManager;

    $scope.forms = {}; // Contains our forms and enables checking its validity.
    $scope.error = null; // Initialize the error message to null.

    if (
        !$scope.isModal &&
        !$scope.group &&
        $stateParams.g &&
        projectManager.groupsMap &&
        projectManager.groupsMap[$stateParams.g]
    ) {
        $scope.group = projectManager.groupsMap[$stateParams.g];
    }

    $scope.mainType = $stateParams.mainType || 'list';

    $scope.data = {
        selectedTab: null,
        currentStepIndex: 0,
        // Whether or not to show the group settings screen.
        // If a group wasn't provided, we always show the group settings screen.
        showGroupSettings: $scope.group ? $scope.showGroupSettings : true,
        integrationState: {},
        groupSettings: $scope.group ? angular.copy($scope.group) : {},
        groupControlObject: {},
        autoCompleteOptions: {},
        postingProgressCount: 7,
        postingProgress: 0,
        isGroupValid: true,
        integrationEntitiesAndFieldsConfiguration: apiConsts.getIntegrationEntitiesAndFieldsConfiguration(),
        manualInitiativesOwners: [],
        uninvitedManualOwners: [],
        resetIntegrationSelectionSearch: false,
        onPreviousStep: null,
        sidebarColor: null,
        minDate: new Date(),
        autoCompleteError: null,
        duplicateSyncControl: {},
        loadingSandboxIFrame: true,
        autoCreateList: $stateParams.autoCreateList,
        createExampleListButtonClicked: false,
        isUserAuthenticated: false,
        startValidatingRequiredIntegrations: false,
        oldIntegrationIdToNewIntegrationIdMapping: {},
        isMakeBotFromJson: false,
    };

    $scope.loadingAutoCompleteOptions = {};
    $scope.valueResults = {};
    $scope.integrationsResults = {};
    $scope.initiativesResults = [];

    $scope.init = function () {
        if ($scope.mainType === 'bot') {
            $scope.isInline = true;
            $scope.workersTemplateMode = true;
        }

        if ($stateParams.listType && $stateParams.listType.toLowerCase() === 'custom') {
            $scope.data.isMakeBotFromJson = true;
        }

        // Load the built in lists object. If the user is not autheticated, we only load example lists.
        $scope.builtInLists = builtInListsConsts.getBuiltInLists(
            $scope.isListGallery || $scope.isInline,
            $scope.workersTemplateMode,
        );
        $scope.categories = builtInListsConsts.getCategories(
            $scope.isListGallery || $scope.isInline,
            $scope.workersTemplateMode,
        );
        $scope.categoriesList = getCategoriesList($scope.categories);

        $scope.data.isUserAuthenticated = !!authenticationService.isUserAuthenticated();

        if (!$scope.isListGallery) {
            $scope.data.selectedTab = $sessionStorage.selectedListCategory || $scope.categoriesList[0];
        }

        if ($scope.isInline) {
            $scope.data.selectedTab = null; // "all"
        }

        // If there is a request for a list type.
        if ($scope.emptyOnly) {
            $scope.selectList($scope.builtInLists.EMPTY);
        } else if ($stateParams.listType && $stateParams.listType.length) {
            let requestedType = $stateParams.listType.replace(/-/gi, '_');
            requestedType = requestedType.toUpperCase();

            if ($scope.builtInLists[requestedType]) {
                // make sure we have a project in hand (might have been redirected from login)
                if ((projectManager.project && !projectManager.project.isDummy) || !$scope.data.isUserAuthenticated) {
                    // select that list
                    $scope.selectList($scope.builtInLists[requestedType], true);
                } else {
                    $scope.firstLoading = true;

                    projectManager.getProjects().then(function () {
                        $scope.firstLoading = false;
                        // select that list
                        $scope.selectList($scope.builtInLists[requestedType], true);
                    });
                }
            } else if (requestedType === 'CUSTOM') {
                $scope.data.enterCustomJson = true;
            }
        } else if ($scope.group) {
            // If we were supplied with a duplicate group data, set up what's needed.
            // Select the given duplicate list model as the selected list.
            // We have no steps, so the group settings step will be added and we'll jump right to it.
            $scope.selectList($scope.group, false, 'DUPLICATE');

            // Initializing the sync validity object for the live sync control of the duplicate group case.
            $scope.valueResults['SYNC_VALIDITY'] = {};
        }

        $scope.isBotActiveData = {
            getInitialValue: $scope.shouldActivateBotByCurrentListType,
            shouldOverride: false,
            overrideValue: null,
        };

        if ($stateParams.autoCreateList && $stateParams.autoCreateList !== 'false') {
            $scope.submitList(true, true);
        }
    };

    if (!$scope.isModal) {
        $scope.$watchCollection(
            function () {
                return $location.hash();
            },
            function () {
                // reaction
                const hash = $location.hash();
                if (hash && hash.length) {
                    const indexHash = Number.parseInt(hash);
                    if (indexHash !== $scope.data.currentStepIndex) {
                        // means it changed from the outside (usually hitting on the browser back)
                        $timeout(function () {
                            $scope.nextStep(indexHash, indexHash < $scope.data.currentStepIndex);
                        });
                    }
                }
            },
        );
    }

    $scope.submitList = function (finalSubmit, skipFormValidityCheck) {
        // Clear the error if exists.
        $scope.error = null;

        if ($scope.isDuplicatedList) {
            // When duplicating a list
            $scope.data.loadingGroupCreation = true;
            return $scope.data.groupControlObject
                .submit($scope.data.selectedList.id !== 'EMPTY', $scope.data.selectedList.workerType)
                .then((newGroup) => {
                    $scope.data.newGroup = newGroup;
                    onSubmitSuccessful();
                })
                .catch(handleError)
                .finally(() => {
                    $scope.data.loadingGroupCreation = false;
                });
        }

        if (!$scope.data.showGroupSettings || !finalSubmit) {
            // need to show the edit settings
            // set the list name to be the name from the built in (unless its duplicated) - before we show the list settings (so it can be edited).
            if (!$scope.isDuplicatedList) {
                $scope.data.groupSettings.name = $scope.data.selectedList.getListDefaultName
                    ? $scope.data.selectedList.getListDefaultName($scope.valueResults)
                    : $scope.data.selectedList.title;
            } else {
                // When duplicating a list
                return $scope.data.groupControlObject
                    .submit($scope.data.selectedList.id !== 'EMPTY', $scope.data.selectedList.workerType)
                    .then((newGroup) => {
                        $scope.data.newGroup = newGroup;
                        onSubmitSuccessful();
                    })
                    .catch(handleError)
                    .finally(() => {
                        $scope.data.loadingGroupCreation = false;
                    });
            }

            // if there is a request to pre-set the permissions than add it here
            if ($scope.valueResults['GROUP_PERMISSIONS']) {
                $scope.data.groupSettings.visibilityType = $scope.valueResults['GROUP_PERMISSIONS'];
                if (
                    $scope.data.groupSettings.visibilityType === 'private' &&
                    $scope.valueResults['GROUP_PERMISSIONS_MEMBERS']
                ) {
                    $scope.data.groupSettings.members = $scope.valueResults['GROUP_PERMISSIONS_MEMBERS'];
                }
            }
        }

        // Only proceed if the form is valid, or if skipFormValidityCheck is true. Use it only if you dont have the form anymore
        if (skipFormValidityCheck === true || $scope.forms.setupList.$valid) {
            if (!finalSubmit) {
                // Move to next step if not in final one.
                $scope.nextStep();
            } else {
                // If in final step, submit the new list.
                $scope.posting = true;

                // call each step prepare data function if exists
                $scope.prepareData();

                // Add the required integrations into the variables bag
                if ($scope.data.requiredIntegrationsStates) {
                    for (let i = 0; i < $scope.data.requiredIntegrationsStates.length; i++) {
                        const integrationState = $scope.data.requiredIntegrationsStates[i];
                        $scope.integrationsResults[integrationState.type] = integrationState.currentIntegration;
                        $scope.integrationsResults[integrationState.originalProjectIntegrationId] =
                            integrationState.currentIntegration;
                        $scope.valueResults[`${integrationState.type}_UNIQUE_TYPE`] =
                            integrationState.currentIntegration.integrationUniqueType;
                        $scope.valueResults[`${integrationState.type}_PROJECT_INTEGRATION_ID`] =
                            integrationState.currentIntegration.id;
                    }
                }

                if ($scope.data.columns) {
                    for (let i = 0; i < $scope.data.columns.length; i++) {
                        const column = $scope.data.columns[i];
                        if (column.type === 'EXTERNAL' && column.matchedEntity) {
                            column.definition.FieldName = column.matchedEntity.name;
                            column.definition.FieldLabel = column.matchedEntity.label;
                        }
                    }
                }

                // see if we should force activate the bot or not
                const shouldForceActivateBot = $scope.shouldActivateBot();

                // Submit data - listSettings -> fields -> initiatives -> sync.
                createGroup(shouldForceActivateBot)
                    .then(() => groupInfoManager.getGroups(true))
                    .then(createGroupStates)
                    .then(turnOnGroupAndSetManualDataSource)
                    .then(createBlankCustomTriggers)
                    .then(createFields)
                    .then(createFieldsVisibilityMap)
                    .then(createForms)
                    .then(createInitiatives)
                    .then(createSync)
                    .then(createCustomTriggers)
                    .then(updateGroupWorkerSchedule)
                    .then(clearDraftVersionChangesActivities)
                    .then(onSubmitSuccessful)
                    .catch(handleError);
            }
        }
    };

    function turnOnGroupAndSetManualDataSource() {
        const turnWorkerOn = groupManager.setWorkerEnabled(
            $scope.data.newGroup,
            !($scope.workersTemplateMode || ($scope.data.selectedList && $scope.data.selectedList.isDuplicatedList)),
            false,
        );
        const updateWorkflowVersionDataSourceTypePromise = workflowVersionDataSourceManager.updateWorkflowVersionDataSourceType(
            $scope.data.newGroup.id,
            'MANUAL',
        );
        const updatePromises = [turnWorkerOn, updateWorkflowVersionDataSourceTypePromise];

        return $q.all(updatePromises);
    }

    /**
     * Creating the forms for the new group.
     */
    function createForms(ignore, failedForm, attempt = 0) {
        // In the first time this function is being called it will take the list from the $scope, otherwise it use the given failedForm.
        // Clone the form in order not to change the original object.
        const groupForms = failedForm || angular.copy($scope.data.selectedList.forms);

        if (!groupForms) {
            return $q.resolve();
        }

        if (!failedForm) {
            matchFormFieldsWithCreatedColumns(groupForms);
        }

        // Before creating the form we have to change the form fields to the newly created group's fields.
        return $q.all(
            groupForms.map(async (form) => {
                try {
                    const descriptionExpression =
                        form.descriptionExpression || form.description
                            ? tonkeanUtils.createTonkeanExpression(form.description)
                            : null;
                    const formCreationResponse = await formManager.createWorkerForm(
                        $scope.data.newGroup.id,
                        form.formType,
                        form.definition,
                        form.disabled,
                        form.displayName,
                        descriptionExpression,
                        form.slackCommand,
                    );
                    $scope.valueResults[form.id] = formCreationResponse.id;
                    return $q.resolve();
                } catch (error) {
                    if (error.status === 409) {
                        // In the first fail we want to save the slack command in order to concatenate its original version in the next attempts
                        if (attempt === 0) {
                            form.originalSlackCommand = form.slackCommand;
                        }
                        // Chaining the slack command attempts number.
                        form.slackCommand = `${form.originalSlackCommand} (${attempt + 1})`;
                        return createForms(null, [form], attempt + 1);
                    }
                }
            }),
        );
    }

    /**
     * The function matches the form fields with the newly created group columns.
     * @param groupForms List of all the group's forms.
     */
    function matchFormFieldsWithCreatedColumns(groupForms) {
        // Iterating over the forms fields.
        groupForms.forEach((newForm) => {
            newForm.definition.fields.forEach((fieldDefinition) => {
                // Setting the form's field id to the newly created field id.
                if ($scope.valueResults[fieldDefinition.fieldDefinitionIdentifier]) {
                    fieldDefinition.fieldDefinitionIdentifier =
                        $scope.valueResults[fieldDefinition.fieldDefinitionIdentifier];
                }
            });
        });
    }

    function updateGroupWorkerSchedule() {
        const compiledDefinition = $scope.compileValues($scope.data.selectedList.scheduledWorkerDefinition);

        if (compiledDefinition) {
            return tonkeanService.updateWorkflowVersionScheduledWorkerDefinition(
                $scope.data.newGroup.id,
                compiledDefinition.scheduledTrackTitleExpression,
                compiledDefinition.evaluatedScheduledTrackTitleExpression,
            );
        } else {
            return $q.resolve();
        }
    }

    function clearDraftVersionChangesActivities() {
        return tonkeanService.clearDraftVersionChangesActivities($scope.data.newGroup.id);
    }

    function createGroupStates() {
        if ($scope.data.selectedList.states) {
            return tonkeanService
                .updateWorkflowVersionStates($scope.data.newGroup.id, $scope.data.selectedList.states)
                .then(() => tonkeanService.getGroupById($scope.data.newGroup.id, true))
                .then(() => {
                    for (
                        let i = 0;
                        i < workflowVersionManager.getDraftVersionFromCache($scope.data.newGroup.id).states.length;
                        i += 1
                    ) {
                        const state = workflowVersionManager.getDraftVersionFromCache($scope.data.newGroup.id).states[
                            i
                        ];
                        const key = `STATE_${state.label.toUpperCase().replaceAll(' ', '_')}`;
                        $scope.valueResults[key] = state.id;
                    }
                });
        }
    }

    $scope.onDisplayFormatSelected = function (selectedDisplayFormat, skipAnalytics) {
        // skipAnalytics allows us to use this function from other places than the html without falsely collecting analytics.
        if (!skipAnalytics) {
            analyticsWrapper.track('Select display format', {
                category: 'Built in list',
                label: selectedDisplayFormat.label,
            });
        }

        $scope.valueResults['DISPLAY_FORMAT_API_NAME'] = selectedDisplayFormat.apiName;
        $scope.valueResults['DISPLAY_FORMAT_PREFIX'] = selectedDisplayFormat.prefix;
        $scope.valueResults['DISPLAY_FORMAT_POSTFIX'] = selectedDisplayFormat.postfix;
    };

    $scope.selectList = function (list, dontNav, overrideListId) {
        $scope.data.selectedList = angular.copy(list);
        $scope.data.steps = angular.copy(list.steps);
        $scope.data.configurationColumns = angular.copy(list.columns);
        $scope.data.columns = angular.copy(list.columns);

        // Initializing the tags of the selected list.
        if (builtInListsConsts.categories[$scope.data.selectedList.category]) {
            $scope.data.selectedList.tags = [];
            $scope.data.selectedList.tags.push(builtInListsConsts.categories[$scope.data.selectedList.category].title);
            if ($scope.data.selectedList.additionalCategoriesSet) {
                for (const additionalCategoryId in $scope.data.selectedList.additionalCategoriesSet) {
                    if ($scope.data.selectedList.additionalCategoriesSet.hasOwnProperty(additionalCategoryId)) {
                        $scope.data.selectedList.tags.push(builtInListsConsts.categories[additionalCategoryId].title);
                    }
                }
            }
        }

        if (overrideListId) {
            $scope.data.selectedList.id = overrideListId;
        }

        $scope.addSetupGroupStep();

        if (!$scope.isModal && !dontNav) {
            // If not modal, set the nav.
            const listType = list.id.toLowerCase().replace(/_/gi, '-');
            if (!$scope.isListGallery && !$scope.isInline) {
                $location.path(`/create/list/${listType}`, false);
            } else {
                // We don't want a refresh here, cause it will cause authentication to happen.
                $state.current.reloadOnSearch = false;
                $location.search('listType', listType);
                $timeout(() => {
                    $state.current.reloadOnSearch = true;
                });
            }
        }

        $scope.initRequiredIntegrationStates();

        $scope.nextStep(0);

        window.Intercom('trackEvent', `Chose Builtin List ${list.category}`);
    };

    $scope.selectProvider = function (integration) {
        // This function selectProvider is called from two different places.
        // One place is from the existing integrations buttons, which sends the function the project integration object as is.
        // The other place is from the integrations group control, which sends an integration object which has the project integration inside of it
        // as an integrations array. That's why we start by determining which project integration should we use - from the instances array or as it.
        let projectIntegration;

        if (integration && integration.integrations && integration.integrations.length) {
            projectIntegration =
                integration.integrations[0] && integration.integrations[0].integration
                    ? integration.integrations[0]
                    : integration.integrations[0];
        } else {
            projectIntegration = integration;
        }

        // clear new integration in progress
        $scope.data.currentIntegration = null;

        if (projectIntegration !== 'manual') {
            // If this integration is broken, send the user to the integrations page.
            if (projectIntegration && projectIntegration.valid === false) {
                $scope.$dismiss();
                $state.go('product.enterpriseComponents', { tab: 'data-sources', projectId: $scope.pm.project.id });
                return;
            }

            // let currentStep = $scope.data.selectedList.steps[$scope.data.currentStepIndex];
            const currentStep = $scope.data.currentStep;

            $scope.valueResults[currentStep.data.sourceType] = projectIntegration.displayName;
            $scope.integrationsResults[currentStep.data.sourceType] = projectIntegration;

            $scope.nextStep();
        }
    };

    $scope.onIntegrationSaved = function (overrideState) {
        if (overrideState) {
            $scope.data.integrationState = overrideState;
        }

        let selected;
        let selectedName;

        // Get the selected integration.
        if ($scope.data.currentIntegration && $scope.data.currentIntegration.name) {
            // Take the integration state object of the selected integration according to its name.
            selectedName = $scope.data.currentIntegration.name;
            selected = $scope.data.integrationState[selectedName];
        }
        if (!selectedName || !selected) {
            // Fallback if something went wrong. Most of the times, the first integration will be the one we want.
            selected = utils.objToArray($scope.data.integrationState)[0].value;
            selectedName = selected.key;
        }

        // clear the 'other' searchbox
        $scope.data.searchIntegrationNameText = null;

        const provider = {
            type: selected.integrations[0].integrationType,
            name: selectedName,
            displayName: selected.integrations[0].displayName || selectedName,
            integrations: selected.projectIntegrations || selected.integrations,
        };

        $scope.selectProvider(provider);
    };

    /**
     * Fires from tnkIntegrationSelection component.
     * @param currentIntegration - the updated integrations.
     */
    $scope.onCurrentIntegrationChanged = function (currentIntegration) {
        // It's enough to update our own data.currentIntegration. This will cause the $digest to notice the change
        // and update everything according to it.
        $scope.data.currentIntegration = currentIntegration;
    };

    /**
     * Adds empty initiatives placeholders to the initiativesResults array.
     * @param numberOfEmptyInitiatives - the number of empty initiatives to create. Default is zero.
     * @param ownerId - an optional owner id to add to the new created initiatives.
     * @param reset - should the initiativesResults list be reset.
     */
    $scope.addToInitiativesResults = function (numberOfEmptyInitiatives, ownerId, reset) {
        // Reset the initiatives list.
        if (reset) {
            $scope.initiativesResults = [];
        }

        if (numberOfEmptyInitiatives) {
            // Add empty initiatives the number of times we were requested.
            for (let i = 0; i < numberOfEmptyInitiatives; i++) {
                $scope.initiativesResults.push({ fields: {}, ownerId });
            }
        }
    };

    /**
     * A method to add the default 3 placeholder initiatives
     * @param ownerName - (optional) if present will set this owner to new placeholder initiatives
     */
    $scope.addDefaultsToInitiativesResults = function (ownerName) {
        if ($scope.initiativesResults && !$scope.initiativesResults.length) {
            $scope.addToInitiativesResults(3, ownerName, true);
        }
    };

    /**
     * Add the setup group settings to the end if needed
     * **/
    $scope.addSetupGroupStep = function () {
        if ($scope.data.showGroupSettings) {
            // Make sure we have a steps array.
            if (!$scope.data.steps) {
                $scope.data.steps = [];
            }

            // Add the list settings to the end.
            $scope.data.steps.push({
                type: 'config',
                data: {
                    template: 'groupSettingsTemplate',
                    infoTemplate: 'groupSettingsInfoTemplate',
                },
            });
        }
    };

    /**
     * Removes an item from the initiativesResults array.
     * @param index - the index of the item to remove. If the index does not exist in the array, nothing is removed.
     */
    $scope.removeFromInitiativesResults = function (index) {
        // Only do something if the index is valid.
        if (index < $scope.initiativesResults.length) {
            $scope.initiativesResults.splice(index, 1);
            $scope.updateManualInitiativesOwners();
        }
    };

    /**
     * Going forward a step, or going to the given index.
     * Since this function can be given a specific index, it can be we either going backwards or forwards,
     * and that's why we need to have the goingToPreviousStep flag, which says which direction we're going.
     * We use that flag to either skip backwards or forwards the preview step if it isn't needed.
     * @param index If given, will move to that index.
     * @param goingToPreviousStep If true, means we're going backwards in steps. Otherwise, we're going forwards in steps.
     */
    $scope.nextStep = function (index, goingToPreviousStep) {
        $scope.scrollModalToTop();

        // If user gave us a step to jump to , we go to that step. Otherwise, we increment the current step by one.
        if (index >= 0) {
            $scope.data.currentStepIndex = index;
        } else {
            $scope.data.currentStepIndex = $scope.data.currentStepIndex + 1;
        }

        // If we should skip step, we go skip backwards or forwards according to given flag.
        if (
            $scope.data.steps[$scope.data.currentStepIndex].shouldSkipStep &&
            $scope.data.steps[$scope.data.currentStepIndex].shouldSkipStep(
                $scope.valueResults,
                $scope.integrationsResults,
            )
        ) {
            if (goingToPreviousStep) {
                $scope.data.currentStepIndex = $scope.data.currentStepIndex - 1;
                $scope.data.steps[$scope.data.currentStepIndex].actualIndex = $scope.data.currentStepIndex;
            } else {
                $scope.data.currentStepIndex = $scope.data.currentStepIndex + 1;
                $scope.data.steps[$scope.data.currentStepIndex].actualIndex = $scope.data.currentStepIndex - 1;
            }

            // if we are skipping this step, actually preform the next step method so next step logic (like skip step) will run
            // (if we need to skip multiple steps)
            $scope.nextStep($scope.data.currentStepIndex, goingToPreviousStep);
            return;
        }

        if (!$scope.isModal) {
            $location.hash($scope.data.currentStepIndex);
        }

        $scope.data.currentStep = $scope.data.steps[$scope.data.currentStepIndex];

        if ($scope.integrationsResults[$scope.data.currentStep.data.sourceType]) {
            $scope.integrationsResults[$scope.data.currentStep.data.sourceType] = null;
        }

        // Initializing the integration sources that do not already exist.
        $scope.data.sources = $scope.data.currentStep.data.integrations;

        if ($scope.data.sources) {
            $scope.data.sources = $scope.data.currentStep.data.integrations.filter(function (source) {
                for (let j = 0; j < projectManager.project.integrations.length; j++) {
                    const projectIntegration = projectManager.project.integrations[j];

                    if (projectIntegration.integration.isNativeIntegration) {
                        if (projectIntegration.integration.integrationType.toLowerCase() === source.toLowerCase()) {
                            return false;
                        }
                    } else {
                        if (projectIntegration.integrationUniqueType === source) {
                            return false;
                        }
                    }
                }

                return true;
            });
        }

        $scope.integrationsSettings = utils.arrayToSet($scope.data.currentStep.data.integrations);

        // clear new integration in progress
        $scope.data.currentIntegration = null;
        // Clear manual initiatives owners (since the UI clears them when proceeding).
        $scope.data.manualInitiativesOwners = [];
        $scope.data.uninvitedManualOwners = [];

        // Reset the integration selection search box.
        resetIntegrationSelectionSearch();

        // reload step (to make sure our ng-inits will be restarted
        $scope.data.loadingStep = true;
        $timeout(function () {
            $scope.data.loadingStep = false;
        });
    };

    $scope.previousStep = function () {
        // if not in a sync list there would be no sync validity properties
        if ($scope.valueResults['SYNC_VALIDITY']) {
            $scope.valueResults['SYNC_VALIDITY'].isLoading = true;
        }

        // Reset the integration selection search box.
        resetIntegrationSelectionSearch();

        if ($scope.data.onPreviousStep) {
            $scope.data.onPreviousStep($scope.data.currentStep.type);
        }

        if ($scope.data.currentStepIndex === 0) {
            $scope.goToListSelection();
        } else {
            return $scope.nextStep($scope.data.currentStepIndex - 1, true);
        }
    };

    $scope.close = function () {
        if ($scope.data.selectedList && ($scope.data.selectedList.id === 'DUPLICATE' || $scope.emptyOnly)) {
            // In case we are duplicating a list, we don't go back to list selection. Instead just close the modal (it can only be a moda).
            $scope.$dismiss();
        } else {
            // Closing a "normal" built-in list creation flow.
            $scope.goToListSelection();
        }
    };

    $scope.goToListSelection = function () {
        $scope.data.selectedList = null;

        $scope.clearCurrentSetupValues();

        // If not in modal, set the nav.
        if (!$scope.isModal) {
            $location.hash(null);

            if (!$scope.isListGallery && !$scope.isInline) {
                // User is authenticated - go to list creation page.
                $location.path('/create/list/', false);
            } else {
                // User not authenticated - just clear the list type state param without refreshing.
                $state.current.reloadOnSearch = false;
                $location.search('listType', null);
                $timeout(() => {
                    $state.current.reloadOnSearch = true;
                });
            }
        }
    };

    $scope.clearCurrentSetupValues = function () {
        // clear new integration in progress
        $scope.data.currentIntegration = null;
        $scope.data.integrationState = {};
        $scope.valueResults = {};
        $scope.integrationsResults = {};
        $scope.initiativesResults = [];
        $scope.data.advancedSyncDisplayed = false;
    };

    /**
     * Opens the list preview modal for the given list.
     * @param $event - the click event.
     * @param list - the list to preview.
     */
    $scope.openPreview = function ($event, list) {
        // Stop bubbling of click event.
        $event.stopPropagation();

        if (!list.frames || !list.frames.length) {
            // if no frames (like in an empty list) go directly to the select list
            return $scope.selectList(list);
        }

        analyticsWrapper.track('Open preview group', { category: 'Built-in List Modal', label: list.id });

        modalUtils
            .openSetupGroupPreviewModal($scope.data.selectedList, list, $scope.builtInLists)
            .result.then((listPreviewToCreate) => {
                if (listPreviewToCreate) {
                    $scope.selectList(listPreviewToCreate);
                }
            });
    };

    $scope.onGoogleSheetProjectIntegrationSelected = function (projectIntegration, fieldDefinitionsToCreate) {
        const provider = {
            type: projectIntegration.integration.integrationType,
            name: projectIntegration.displayName,
            displayName: projectIntegration.displayName,
            integrations: [projectIntegration],
        };

        if (fieldDefinitionsToCreate && fieldDefinitionsToCreate.length) {
            $scope.data.additionToColumns = [];

            for (const element of fieldDefinitionsToCreate) {
                element.addAndDoNotProcess = true;
                $scope.data.additionToColumns.push(element);
            }
        }

        $scope.selectProvider(provider);
    };

    $scope.onExcelSheetProjectIntegrationSelected = function (projectIntegration, fieldDefinitionsToCreate) {
        const provider = {
            type: projectIntegration.integration.integrationType,
            name: projectIntegration.displayName,
            displayName: projectIntegration.displayName,
            integrations: [projectIntegration],
        };

        if (fieldDefinitionsToCreate && fieldDefinitionsToCreate.length) {
            $scope.data.additionToColumns = [];

            for (const element of fieldDefinitionsToCreate) {
                element.addAndDoNotProcess = true;
                $scope.data.additionToColumns.push(element);
            }
        }

        $scope.selectProvider(provider);
    };

    $scope.filterListBySearchTerm = function (list, search) {
        if (!search || !search.length) {
            return true;
        }
        search = search.toLowerCase();

        let isMatch = list.title.toLowerCase().includes(search);
        isMatch = isMatch || (list.subtitle && list.subtitle.toLowerCase().includes(search));
        isMatch = isMatch || (list.description && list.description.toLowerCase().includes(search));
        isMatch = isMatch || (list.longDescription && list.longDescription.toLowerCase().includes(search));
        isMatch = isMatch || (list.entityTypeDisplayName && list.entityTypeDisplayName.toLowerCase().includes(search));
        isMatch =
            isMatch ||
            (list.requiredIntegrations &&
                list.requiredIntegrations.length &&
                list.requiredIntegrations.join(' ').toLowerCase().includes(search));

        return isMatch;
    };

    $scope.getAutoComplete = function (integrationId, fieldName, params, valuesKey) {
        $scope.loadingAutoCompleteOptions[fieldName] = true;
        // Clear the valueResults before starting (so it won't hold an old value).
        $scope.valueResults[valuesKey] = null;

        return createProjectApis
            .getAutoCompleteOptions($scope.pm.project.id, integrationId, fieldName, params)
            .then(function (result) {
                $scope.data.autoCompleteError = null;

                if (valuesKey) {
                    $scope.valueResults[valuesKey] = result.options;
                }

                $scope.data.autoCompleteOptions[fieldName] = result.options;
            })
            .catch(function () {
                const errorCases = {
                    views: 'views',
                    metricsAndDimensions: 'metrics and dimensions',
                    values: 'values',
                };
                $scope.data.autoCompleteError = `Couldn't fetch ${errorCases[fieldName]} from Google Analytics.`;
            })
            .finally(function () {
                $scope.loadingAutoCompleteOptions[fieldName] = false;
            });
    };

    /**
     * A variable function that is used to pass a dynamic variable to later compile using the compileValues function.
     * @param key - the key to look for in the valueResults.
     * @returns {{key: *, fromValues: boolean}}
     */
    $scope.variable = function (key) {
        return { key, fromValues: true };
    };

    $scope.prepareData = function () {
        for (let i = 0; i < $scope.data.steps.length; i++) {
            const step = $scope.data.steps[i];
            if (step.prepareData) {
                step.prepareData($scope.valueResults, $scope.integrationsResults, $scope.initiativesResults);
            }
        }
    };

    /**
     * Checks if the config has a should activate bot method and runs it to get an answer
     * @returns {boolean} returns true based on logic of each builtin config, defaults to false.
     */
    $scope.shouldActivateBotByCurrentListType = function () {
        for (let i = 0; i < $scope.data.steps.length; i++) {
            const step = $scope.data.steps[i];
            if (step.shouldActivateBot) {
                return step.shouldActivateBot($scope.integrationsResults, $scope.valueResults);
            }
        }

        return false;
    };

    /**
     * Checks whether the bot should be activated, first checks if the user has overriden the default value
     * if not returns the default value of the list type
     * @returns {*}
     */
    $scope.shouldActivateBot = function () {
        if ($scope.isBotActiveData.shouldOverride) {
            return $scope.isBotActiveData.overrideValue;
        }

        return $scope.shouldActivateBotByCurrentListType();
    };

    $scope.setManulInitiativesOwners = function (array) {
        $scope.data.manualInitiativesOwners = array;
        $scope.calculateUninvitedManualOwners();
    };

    $scope.selectTab = function (cat) {
        $scope.data.selectedList = null;
        $scope.data.searchTerm = null;
        $scope.data.selectedTab = cat;
        $sessionStorage.selectedListCategory = cat;
    };

    $scope.calculateUninvitedManualOwners = function () {
        // filter owners who are in the system
        $scope.data.uninvitedManualOwners = $scope.data.manualInitiativesOwners.filter((owner) => !owner.id);
    };

    /**
     * Used to make the sandbox url a trusted url.
     */
    $scope.trustSrc = function (src) {
        return $sce.trustAsResourceUrl(src);
    };

    $scope.loadFields = function (integrationState) {
        integrationState.isCollapsed = !integrationState.isCollapsed;

        if (!integrationState.isCollapsed) {
            $scope.getAvailableExternalFields(integrationState);
        }
    };

    $scope.getAvailableExternalFields = function (integrationState, forceServer) {
        integrationState.isLoadingExternalFields = true;
        // If we already filled the fields options, skip so we won't erase the changes of the user
        if (
            integrationState.availableExternalFields &&
            integrationState.availableExternalFields.length &&
            !forceServer
        ) {
            integrationState.isLoadingExternalFields = false;
            return $q.resolve();
        }

        const externalColumns = $scope.data.columns.filter(
            (column) =>
                column.type === 'EXTERNAL' &&
                column.integrationUniqueType === integrationState.originalProjectIntegrationId,
        );

        // Get the external types related to this project integration
        const externalTypes = [];
        externalColumns.map((column) => {
            if (
                column.definition &&
                column.definition.projectIntegrationId === integrationState.originalProjectIntegrationId
            ) {
                externalTypes.push(column.definition.ExternalType);
            }
        });

        return tonkeanService
            .getAvailableExternalFields(integrationState.currentIntegration.id, externalTypes)
            .then((data) => {
                integrationState.availableExternalFields = data.entitiesWithLabels;

                // Put the external entity on the column so we can retrieve it when sending to server
                externalColumns.forEach((column) => {
                    column.matchedEntity = utils.findFirst(integrationState.availableExternalFields, (entity) => {
                        return (
                            column.definition &&
                            entity.label === column.definition.FieldLabel &&
                            column.definition.ExternalType === entity.entity
                        );
                    });
                });
            })
            .finally(() => (integrationState.isLoadingExternalFields = false));
    };

    $scope.filterNonDefinitionFields = function (column) {
        return !!(column.definition && column.definition.FieldLabel);
    };

    $scope.initRequiredIntegrationStates = function () {
        if ($scope.data.selectedList.requiredIntegrations && $scope.data.selectedList.requiredIntegrations.length) {
            $scope.data.requiredIntegrationsStates = $scope.data.selectedList.requiredIntegrations.map(
                (integrationType, index) => {
                    const state = {
                        type: integrationType,
                        state: {},
                        currentIntegration: null,
                        integrationChanged: null,
                        supportsMultipleIntegrationsPerUser: false,
                        existingProjectIntegrations: [],
                        pastIntegrationName: null,
                        originalProjectIntegrationId: null,
                        isCollapsed: true,
                        isLoadingExternalFields: false,
                    };
                    // match integration with their past name
                    if ($scope.data.selectedList.pastIntegrationNames) {
                        // match integration with their past name
                        state.pastIntegrationName = `- (Original name: ${$scope.data.selectedList.pastIntegrationNames[index]})`;
                    }

                    if ($scope.data.selectedList.requiredIntegrationsIds) {
                        state.originalProjectIntegrationId = $scope.data.selectedList.requiredIntegrationsIds[index];
                    }

                    const filter = $scope.pm.project.integrations.filter(
                        (integ) => integ.integrationType === state.type,
                    );
                    state.currentIntegration = null;

                    if (filter && filter.length) {
                        state.supportsMultipleIntegrationsPerUser =
                            filter[0].integration.supportsMultipleIntegrationsPerUser;
                        state.currentIntegration = filter[0];

                        // Creating the default mapping between old and new project integrations
                        if ($scope.data.selectedList.requiredIntegrationsIds) {
                            $scope.data.oldIntegrationIdToNewIntegrationIdMapping[
                                $scope.data.selectedList.requiredIntegrationsIds[index]
                            ] = filter[0].id;
                        }

                        if (state.supportsMultipleIntegrationsPerUser) {
                            state.existingProjectIntegrations = filter;
                        }
                    }

                    state.integrationChanged = (changedProjectIntegration) => {
                        // If this is a newly connected integration the model wont be the same, so we correct this
                        if (changedProjectIntegration.projectIntegration) {
                            state.currentIntegration = changedProjectIntegration.projectIntegration;
                            state.currentIntegration.valid = changedProjectIntegration.valid;
                            state.currentIntegration.disabled = changedProjectIntegration.disabled;
                        } else {
                            state.currentIntegration = changedProjectIntegration;
                        }

                        $scope.getAvailableExternalFields(state, true);
                    };

                    return state;
                },
            );
        }
    };

    $scope.refreshExistingIntegrations = function (integrationState) {
        const filter = $scope.pm.project.integrations.filter(
            (integ) => integ.integrationType === integrationState.type,
        );
        integrationState.existingProjectIntegrations = filter;
    };

    $scope.onExistingIntegrationSelected = function (integrationState, selectedIntegration) {
        $scope.refreshExistingIntegrations(integrationState);
        $scope.data.oldIntegrationIdToNewIntegrationIdMapping[integrationState.originalProjectIntegrationId] =
            selectedIntegration.id;
        integrationState.currentIntegration = selectedIntegration;
        $scope.getAvailableExternalFields(integrationState, true);
    };

    /**
     * Creates example list.
     */
    $scope.createExampleList = function () {
        analyticsWrapper.track('Create example list', {
            category: 'Built-in List Modal',
            label: $scope.data.selectedList.id,
        });

        if ($scope.isListGallery) {
            // If we're in the list gallery, we are in an iFrame and should let our parent know the user wants to create a list,
            // so he can open the authentication window, etc.
            // We also save an auto list creation id in the localStorage so when the user finishes the sign up + onboarding
            // his list is auto created by navigating to '/create/list/{listId}?autoCreateList=true'.
            $localStorage.autoCreateListId = $scope.data.selectedList.id;
            $scope.postMessageToContainer($scope.data.selectedList.id);
        } else {
            // The user is not in the gallery, we should create the example list as expected.
            // First, make sure the required integrations are valid.
            const createMissingProjectIntegrationsPromises = [];
            if ($scope.data.selectedList.requiredIntegrations && $scope.data.selectedList.requiredIntegrations.length) {
                $scope.data.startValidatingRequiredIntegrations = true;
                for (let i = 0; i < $scope.data.requiredIntegrationsStates.length; i++) {
                    const integrationState = $scope.data.requiredIntegrationsStates[i];
                    if (!integrationState.currentIntegration || !integrationState.currentIntegration.id) {
                        $scope.data.hasMockIntegrations = true;
                        createMissingProjectIntegrationsPromises.push(
                            $q.resolve().then(() => {
                                const uniqueType = integrations.getNewIntegrationUniqueType(
                                    $scope.as.currentUser.id,
                                    integrationState.type,
                                );
                                return createProjectApis
                                    .createIntegration(
                                        projectManager.project.id,
                                        integrationState.type,
                                        {},
                                        uniqueType,
                                        true,
                                    )
                                    .then((integration) => {
                                        return createProjectApis.createProjectIntegration(
                                            projectManager.project,
                                            integration,
                                            integrationState.type,
                                            uniqueType,
                                            true,
                                        );
                                    })
                                    .then((updatedProject) => {
                                        integrationState.currentIntegration = utils.findFirst(
                                            updatedProject.integrations,
                                            (prin) => prin.integration.integrationUniqueType === uniqueType,
                                        );
                                    });
                            }),
                        );
                    }
                }
            }

            $scope.data.createExampleListButtonClicked = true;

            $q.all(createMissingProjectIntegrationsPromises).then(() => $scope.submitList(true, true));
        }
    };

    /**
     * Occurs once the iframe is loaded.
     */
    $scope.onSandboxIFrameLoaded = function () {
        $scope.data.loadingSandboxIFrame = false;
    };

    /**
     * Updates the data.manualInitiativesOwners with the owners of the manual initiatives added by the user.
     * @param initiative - the initiative that was updated (owner was added or removed).
     * @param newOwner - a new owner in case he was added and null if removed.
     */
    $scope.updateManualInitiativesOwners = function (initiative, newOwner) {
        // Update the given initiative with the owner that was supplied (if one was supplied).
        // The prepareInitiatives function relies on ownerId and not the owner object - so this action is for our use only.
        // We have to add the new owner to the initiative itself because just an id is not enough for UI purposes.
        // Removing is handled in the html itself.
        if (initiative && newOwner) {
            initiative.owner = newOwner;
            // This display name is added so it's easier to display the user's name in the html.
            initiative.owner.customDisplayName = newOwner.firstName || newOwner.email || 'The owner';
        }

        // A map that will be later converted to an array.
        const ownersMap = {};

        if ($scope.initiativesResults) {
            // Go over the initiative results, and update the owners list accordingly.
            for (let i = 0; i < $scope.initiativesResults.length; i++) {
                const initiativeObj = $scope.initiativesResults[i];
                // If the initiative has an owner and the owner has a name or email, add him to the map.
                if (initiativeObj.owner) {
                    const key = initiativeObj.owner.name || initiativeObj.owner.email || initiativeObj.owner.firstName;
                    ownersMap[key] = initiativeObj.owner;
                }
            }
        }

        // Convert the built map to an array and save it.
        $scope.setManulInitiativesOwners(utils.objValues(ownersMap));
    };

    /**
     * Creates the group of the built in list.
     */
    function createGroup(shouldForceActivateBot) {
        // Save the built-in list id to the group's metadata, and send analytics.
        saveListIdToGroupMetadata($scope.data.selectedList.id);
        analyticsWrapper.track('Create group', { category: 'Built-in List Modal', label: $scope.data.selectedList.id });

        $scope.valueResults['TNK_GROUP_CREATOR'] = $scope.as.currentUser.id;

        if ($scope.data.selectedList.isExampleList) {
            // If it's an example list, we create the group directly using tonkean service.
            const groupName = $scope.data.selectedList.getListDefaultName
                ? $scope.data.selectedList.getListDefaultName()
                : $scope.data.selectedList.title;

            // Set the metadata for this example list if we want to set displayImportPromoBox on it.
            if (!$scope.data.groupSettings.metadata) {
                $scope.data.groupSettings.metadata = {};
            }
            $scope.data.groupSettings.metadata.displayClearExamples = true;
            $scope.data.groupSettings.metadata.displayImportPromoBox = $scope.data.selectedList.displayImportPromoBox;

            return tonkeanService
                .createGroup(
                    projectManager.project.id,
                    groupName,
                    'PUBLIC',
                    null,
                    'NONE',
                    null,
                    null,
                    $scope.data.groupSettings.metadata,
                    !$scope.data.selectedList.disableShouldSendGatherUpdates,
                    null,
                    false,
                    null,
                    'EVERYONE',
                    null,
                    $scope.data.selectedList.dashboardHidden,
                    false,
                    $scope.data.selectedList.workerType,
                    $scope.data.selectedList.recurrencePeriodType,
                    $scope.data.selectedList.recurrenceDaysInMonth,
                    $scope.data.selectedList.recurrenceDaysInWeek,
                    $scope.data.selectedList.recurrenceHour,
                    $scope.data.selectedList.recurrenceMinute,
                    $scope.data.selectedList.everyXMinutes,
                    $scope.data.selectedList.everyXHours,
                    $scope.data.selectedList.doNotRunOnWeekends,
                    null,
                    null,
                    null,
                    null,
                    $scope.data.selectedList.recurrenceMonthsInYear,
                )
                .then((updatedGroup) => {
                    // Update group in cache.
                    updateGroupInCaches(updatedGroup);

                    updatePostingProgress();
                });
        } else if ($scope.data.showGroupSettings) {
            // If we displayed the group settings, we should use the tnkEditGroup directive's submit logic.
            // if parameter is false - bot live is active
            return $scope.data.groupControlObject
                .submit(
                    $scope.data.selectedList.id !== 'EMPTY' && !shouldForceActivateBot,
                    $scope.data.selectedList.workerType,
                )
                .then(function (updatedGroup) {
                    // The group can be null (if this is a new group). data.groupSettings can't.
                    const groupObject = $scope.group || $scope.data.groupSettings;
                    // Update the referenced group name or our new group settings.
                    groupObject.name = updatedGroup.name;

                    // Update group in cache.
                    updateGroupInCaches(updatedGroup);

                    updatePostingProgress();
                });
        } else {
            // Group settings wasn't displayed.
            // If a group was provided from the start just use it.
            if ($scope.group) {
                $scope.group.name = $scope.data.groupSettings.name;
                $scope.data.newGroup = $scope.group;

                // Save the group id to $scope.valueResults.
                $scope.valueResults['GROUP_ID'] = $scope.data.newGroup.id;

                updatePostingProgress();

                return tonkeanService.updateGroupName($scope.group, $scope.group.name);
            }
        }
    }

    /**
     * Updates the newly created group in relevant caches, and in the variables map.
     */
    function updateGroupInCaches(updatedGroup) {
        // Set the newGroup that is used in the next submit phases.
        $scope.data.newGroup = updatedGroup;
        // Save the group id to $scope.valueResults.
        $scope.valueResults['GROUP_ID'] = $scope.data.newGroup.id;
        // Add the new group to the pm. Otherwise the app won't work well.
        $scope.pm.addGroup(updatedGroup);
    }

    /**
     * Adds the built-in list id to the group's metadata.
     * @param builtInListId - the id of the current built-in list to save to the metadata.
     */
    function saveListIdToGroupMetadata(builtInListId) {
        // group is the outer group object supplied to us, groupSettings is our inner copy of it.
        // If this is a new group, we won't have an outer group object.
        if ($scope.group) {
            // Make sure the group has a metadata object.
            if (!$scope.group.metadata) {
                $scope.group.metadata = {};
            }

            // Add the given built-in list id to the metadata.
            $scope.group.metadata.builtInListId = builtInListId;
        }

        // Make sure the groupSettings has metadata.
        if (!$scope.data.groupSettings.metadata) {
            $scope.data.groupSettings.metadata = {};
        }

        // Add the given built-in list id to the metadata.
        $scope.data.groupSettings.metadata.builtInListId = builtInListId;
    }

    /**
     * Creates the sync for the built in list.
     */
    function createSync() {
        // If we are configured to use manually, that means we are not interested in a sync, but we chose to create items manually, and we have nothing to do in sync,
        // or if we don't have a sync configured, we don't want to sync at all. Returning empty promise.
        if (
            $scope.valueResults['USE_MANUALLY'] ||
            (!$scope.data.selectedList.sync && !$scope.data.selectedList.duplicateSync)
        ) {
            return updateProgressAndReturnPromise();
        }

        let projectIntegration;
        let viewData;
        let viewType;

        if ($scope.data.selectedList.duplicateSync) {
            // We received duplicate sync, and we should all sync information from it.
            projectIntegration = $scope.data.selectedList.duplicateSync.projectIntegration;
            viewType = $scope.data.selectedList.duplicateSync.viewType;
            // It is important we take the view data from the duplicate sync control and not
            // from the given view data, as user might have changed the sync filters during
            // the preview step of the list creation.
            viewData = $scope.data.duplicateSyncControl.createDefinitionFromCustomFilters();
        } else if (
            $scope.data.selectedList.sync &&
            $scope.integrationsResults[$scope.data.selectedList.sync.integrationType]
        ) {
            // Otherwise, if a sync was configured, we use it for the selected built it list.
            projectIntegration = $scope.integrationsResults[$scope.data.selectedList.sync.integrationType];
            viewData = $scope.data.selectedList.isWorkerTemplate
                ? $scope.data.selectedList.sync.viewData
                : $scope.valueResults['PREVIEW_SYNC_CONTROL'].createDefinitionFromCustomFilters();
            viewType = $scope.viewTypes.custom;
        }

        // If the sync integration was already chosen by the user
        if (
            $scope.data.selectedList.sync &&
            $scope.integrationsResults[$scope.data.selectedList.sync.projectIntegrationId]
        ) {
            projectIntegration = $scope.integrationsResults[$scope.data.selectedList.sync.projectIntegrationId];
        }

        // Add title expression if exists
        if ($scope.data.selectedList.sync.titleExpression) {
            viewData.titleExpression = $scope.data.selectedList.sync.titleExpression;
        }

        // If there's no project integration, viewData or viewType, we cannot configure a sync. Returning empty promise.
        if (!projectIntegration || !viewData || !viewType) {
            return updateProgressAndReturnPromise();
        }

        return syncConfigCacheManager
            .createGroupSyncConfig($scope.data.newGroup.id, projectIntegration.id, viewData, null, viewType, null)
            .then(() => updateProgressAndReturnPromise());
    }

    /**
     * If this is a template, the first thing we should do is create empty custom triggers as placeholders.
     * We do this so any field\other custom trigger would be able to use these placeholders as references
     *
     * Later in the code we should update all the placeholders with their actual properties
     */
    function createBlankCustomTriggers() {
        let blankTriggersPromise = $q.resolve();

        if (
            $scope.data.selectedList &&
            $scope.data.selectedList.customTriggers &&
            $scope.data.selectedList.customTriggers.length &&
            $scope.data.selectedList.customTriggersGraph
        ) {
            const defaultQuery = {
                query: getDefaultTonkeanQuery(),
            };

            const customTriggers = $scope.data.selectedList.customTriggers.map((trigger) => {
                return {
                    key: trigger.key,
                    displayName: trigger.displayName,
                    description: trigger.description,
                    queryDefinition: defaultQuery,
                    pingOwner: false,
                    alert: false,
                };
            });

            blankTriggersPromise = innerCreateCustomTriggers($scope.data.newGroup.id, customTriggers);
        }

        return blankTriggersPromise;
    }

    /**
     * Creates custom triggers for the list.
     */
    function createCustomTriggers() {
        // If we don't have triggers configured for the list, and we are also not in a duplicate mode,
        // we have nothing to do in regards of triggers, so we return.
        if (
            (!($scope.data.selectedList.customTriggers && $scope.data.selectedList.customTriggers.length) ||
                !$scope.data.selectedList.customTriggersGraph) &&
            !$scope.data.selectedList.isDuplicatedList
        ) {
            return updateProgressAndReturnPromise();
        }

        let triggersPromise = $q.resolve();
        if ($scope.data.selectedList.customTriggers && $scope.data.selectedList.customTriggersGraph) {
            // If custom triggers were configured, we use them for the selected built it list.

            // Compiling the custom triggers we're about to create, as they may be relying on fields.
            const customTriggers = $scope.compileValues($scope.data.selectedList.customTriggers);

            triggersPromise = $q
                .resolve()
                .then(() => {
                    const triggerUpdates = [];
                    if (customTriggers && customTriggers.length) {
                        for (let trigger of customTriggers) {
                            // Checking whether the custom trigger has formId.
                            // If the trigger has form id defined we replace the id with the new created form id (using the mapping we built before).
                            if (
                                trigger.customTriggerActions &&
                                trigger.customTriggerActions.length &&
                                trigger.customTriggerActions[0].definition &&
                                trigger.customTriggerActions[0].definition.formId
                            ) {
                                // Setting the custom trigger form id to the newly created form id.
                                trigger.customTriggerActions[0].definition.formId =
                                    $scope.valueResults[trigger.customTriggerActions[0].definition.formId];
                            }

                            // Compile again so we can compile the trigger dependencies.
                            trigger = $scope.compileValues(trigger);
                            trigger.groupId = $scope.data.newGroup.id;
                            trigger.customTriggerId = $scope.valueResults[trigger.key];
                            triggerUpdates.push(trigger);
                        }
                    }

                    return tonkeanService.multiUpdateCustomTrigger($scope.data.newGroup.id, triggerUpdates);
                })
                .then((data) => {
                    if (data.fieldDefinitionsChanged) {
                        $rootScope.$broadcast('linkedFieldDefinitionsChanged');
                    }

                    const graph = $scope.data.selectedList.customTriggersGraph;
                    return $q.resolve({
                        customTriggers: data.entities,
                        graph,
                    });
                });
        }

        // First, we create the custom triggers.
        return triggersPromise.then((createdTriggersData) => {
            let promise = $q.resolve();
            if (
                createdTriggersData &&
                createdTriggersData.customTriggers &&
                createdTriggersData.customTriggers.length &&
                createdTriggersData.graph
            ) {
                // Now that we have created the triggers, we can create the graph using their ids!
                const compiledGraph = $scope.compileValues(createdTriggersData.graph);

                if (compiledGraph) {
                    promise = customTriggerManager.updateWorkflowVersionCustomTriggerGraph(
                        $scope.data.newGroup.id,
                        compiledGraph,
                    );
                }

                promise = promise.then(() => updateProgressAndReturnPromise());
            }

            return promise;
        });
    }

    function innerCreateCustomTriggers(groupId, customTriggers) {
        return customTriggerManager
            .createMultipleCustomTriggersInWorkflowVersion($scope.data.newGroup.id, customTriggers)
            .then((multiCreationResults) => {
                addCustomTriggersCreationsResultsToValueResults(multiCreationResults);
            });
    }

    /**
     * Adds created custom triggers to valueResults map
     */
    function addCustomTriggersCreationsResultsToValueResults(multiCreationResults) {
        // Then, we add the created custom triggers to the variables map, so we can later use them in the graph creation.
        if (multiCreationResults && multiCreationResults.creationResults) {
            for (let i = 0; i < multiCreationResults.creationResults.length; i++) {
                const creationResult = multiCreationResults.creationResults[i];

                // If the creationResults returned with a key (which we have set), save the fieldDefinitionId to $scope.valueResults.
                if (
                    creationResult.key &&
                    !creationResult.errorsOccured &&
                    creationResult.createdCustomTrigger &&
                    creationResult.createdCustomTrigger.id
                ) {
                    $scope.valueResults[creationResult.key] = creationResult.createdCustomTrigger.id;
                }
            }
        }
    }

    function createFields() {
        let dependencyLevelsPromise = $q.resolve();
        if ($scope.data.selectedList.isDuplicatedList) {
            dependencyLevelsPromise = tonkeanService.getProjectDependencyLevels(
                $scope.data.selectedList.duplicatedListWorkflowVersionId,
            );
        }

        // We separated the creation of columns and global fields, because global fields may relay on new field definitions (e.g for aggregative fields).
        return dependencyLevelsPromise.then((dependencyLevelsMap) => {
            return createColumnFields(dependencyLevelsMap).then(() => createGlobalFields(dependencyLevelsMap));
        });
    }

    function createFieldsVisibilityMap() {
        if ($scope.data.selectedList.workflowVersionFieldDefinitions) {
            let visibilityMap = $scope.compileValues($scope.data.selectedList.workflowVersionFieldDefinitions);
            visibilityMap = visibilityMap.map((fieldDef) => {
                // backward compatibility
                let fieldId;
                if (!!fieldDef.fieldDefinitionKey && $scope.valueResults[fieldDef.fieldDefinitionKey]) {
                    fieldId = $scope.valueResults[fieldDef.fieldDefinitionKey];
                } else {
                    fieldId = fieldDef.fieldDefinition.id;
                }

                return {
                    fieldDefinitionId: fieldId,
                    isHidden: fieldDef.isHidden,
                };
            });
            return $q.all(
                tonkeanService.overrideLiveReportFieldDefinitions(
                    $scope.data.newGroup.id,
                    visibilityMap,
                    'COLUMN',
                    'DRAFT',
                ),
                tonkeanService.overrideLiveReportFieldDefinitions(
                    $scope.data.newGroup.id,
                    visibilityMap,
                    'GLOBAL',
                    'DRAFT',
                ),
            );
        } else {
            return $q.resolve();
        }
    }

    /**
     * Creates columns for the built in list.
     */
    function createColumnFields(dependencyLevelsMap) {
        // If we don't have columns, addition to columns or duplicate columns, we have nothing to do. Returning empty promise.
        if (!$scope.data.columns && !$scope.data.additionToColumns) {
            return updateProgressAndReturnPromise(2);
        }

        // Figuring out which columns we need to create.
        let columnsToCreate = [];
        if ($scope.data.columns && $scope.data.columns.length) {
            columnsToCreate = columnsToCreate.concat($scope.data.columns);
        }
        if ($scope.data.additionToColumns && $scope.data.additionToColumns.length) {
            columnsToCreate = columnsToCreate.concat($scope.data.additionToColumns);
        }

        // If we have no columns, we return.
        if (!columnsToCreate || !columnsToCreate.length) {
            return updateProgressAndReturnPromise(2);
        }

        // Setting an index to each of the columns, so that the separation of regular columns and formula columns won't affect
        // the actual desired order.
        for (const [i, element] of columnsToCreate.entries()) {
            element.index = i;
        }

        // Creating the columns in the server.
        let createColumnsPromise = $q.resolve();
        const dependencyLevelToColumnsMap = utils.groupBy(columnsToCreate, (column) => {
            // If we have the dependency level defined already, we take it from there.
            // Otherwise, we check if we have it in the dependency map we received from the server.
            // If we do, we take it from there. Otherwise, we consider the level to be zero.
            if (column.dependencyLevel) {
                return column.dependencyLevel;
            } else if (dependencyLevelsMap && dependencyLevelsMap[column.key]) {
                return dependencyLevelsMap[column.key];
            } else {
                return 0;
            }
        });
        const allDependenciesLevels = utils.objKeys(dependencyLevelToColumnsMap).sort();

        /* jshint loopfunc:true */
        for (const allDependenciesLevel of allDependenciesLevels) {
            let currentLevelColumns = dependencyLevelToColumnsMap[allDependenciesLevel];

            if (currentLevelColumns && currentLevelColumns.length) {
                createColumnsPromise = createColumnsPromise.then(() => {
                    // Evaluating field definitions if regular built in list.
                    // Evaluate each dependency level at its time, to handle a higher dependency needs a valueResult of a lower dependency level field
                    if (!$scope.data.selectedList.isDuplicatedList) {
                        // We only need to evaluate field definitions at this point for a "real" built in list - not an example list or a duplicated list.
                        currentLevelColumns = evaluateFieldDefinitions(currentLevelColumns, 'COLUMN');
                    } else {
                        currentLevelColumns = currentLevelColumns.map((column) => {
                            column.groupId = $scope.data.newGroup.id;
                            return column;
                        });
                    }
                    const finalFieldDefinitions = evaluateFormulaFieldDefinitions(currentLevelColumns);

                    if (finalFieldDefinitions && finalFieldDefinitions.length) {
                        return customFieldsManager
                            .createMultipleFieldDefinitions($scope.data.newGroup.id, finalFieldDefinitions, true)
                            .then((creationResultsData) => {
                                const createdFields = creationResultsData.creationResults;
                                if (createdFields) {
                                    for (const createdField of createdFields) {
                                        $scope.valueResults[createdField.key] = createdField.definition.id;
                                    }
                                    updatePostingProgress();

                                    // Add the field definitions key-to-definitionId mapping to the $scope.valueResults.
                                    saveFieldDefinitionIdsToKeyMapping(creationResultsData);
                                }

                                return $q.resolve();
                            });
                    }

                    return $q.resolve();
                });
            }
        }

        return createColumnsPromise;
    }

    /**
     * Creates the global fields for the list.
     */
    function createGlobalFields() {
        // If we do not have global fields configured, or we do not have global fields to duplicate, we do nothing.
        if (!$scope.data.selectedList.globalFields) {
            return updateProgressAndReturnPromise();
        }

        // If we have global fields configured for the list, we take those. Otherwise, we take the duplicate global fields we were given.
        let globalFieldDefinitionsToCreate = [];
        if ($scope.data.selectedList.globalFields && $scope.data.selectedList.globalFields.length) {
            globalFieldDefinitionsToCreate = globalFieldDefinitionsToCreate.concat(
                $scope.data.selectedList.globalFields,
            );
        }

        if (!$scope.data.selectedList.isExampleList && !$scope.data.selectedList.isDuplicatedList) {
            globalFieldDefinitionsToCreate = evaluateFieldDefinitions(globalFieldDefinitionsToCreate, 'GLOBAL');
        } else {
            globalFieldDefinitionsToCreate = globalFieldDefinitionsToCreate.map((fieldDefinition) =>
                $scope.compileValues(fieldDefinition),
            );
        }

        // Creating the global field definitions in the server.
        if (globalFieldDefinitionsToCreate && globalFieldDefinitionsToCreate.length) {
            // Setting target type to global for all definitions.
            for (const globalFieldDefinition of globalFieldDefinitionsToCreate) {
                globalFieldDefinition.targetType = 'GLOBAL';
            }

            globalFieldDefinitionsToCreate = globalFieldDefinitionsToCreate.map((column) => {
                column.groupId = $scope.data.newGroup.id;
                return column;
            });

            return customFieldsManager
                .createMultipleFieldDefinitions($scope.data.newGroup.id, globalFieldDefinitionsToCreate, null)
                .then((creationResultsData) => {
                    // Add the field definitions key-to-definitionId mapping to the $scope.valueResults.
                    saveFieldDefinitionIdsToKeyMapping(creationResultsData);
                    updateProgressAndReturnPromise();
                });
        }

        return updateProgressAndReturnPromise();
    }

    function evaluateFieldDefinitions(fields, targetType) {
        const fieldDefinitionsToCreate = [];

        for (const currentColumn of fields) {
            if (currentColumn.addAndDoNotProcess) {
                fieldDefinitionsToCreate.push(currentColumn);
                continue;
            }

            if (!currentColumn.greedyMatch || currentColumn.fieldDefinition.fallBackToManual) {
                // only send fields that are real (aka not "greedyMatch"), unless there is a fallback to manual (in that case server will create it as manual)

                const fieldDefinitionToCreate = $scope.compileValues(currentColumn);

                // If field definition to create is null, that means we failed compiling due to a required variable
                // that its value does not exist. Therefore, we proceed without it.
                if (utils.isNullOrUndefined(fieldDefinitionToCreate)) {
                    continue;
                }

                fieldDefinitionToCreate.targetType = targetType;

                if (!currentColumn.integrationType || currentColumn.integrationType === 'MANUAL') {
                    const newProjectIntegrationId =
                        $scope.data.oldIntegrationIdToNewIntegrationIdMapping[currentColumn.integrationUniqueType];
                    fieldDefinitionToCreate.projectIntegrationId =
                        newProjectIntegrationId || fieldDefinitionToCreate.projectIntegrationId;
                    fieldDefinitionsToCreate.push(fieldDefinitionToCreate);
                } else {
                    const projectIntegration = $scope.integrationsResults[currentColumn.integrationType];

                    if (projectIntegration) {
                        fieldDefinitionToCreate.projectIntegrationId = projectIntegration.id;
                        fieldDefinitionsToCreate.push(fieldDefinitionToCreate);
                    } else if (currentColumn.requiresIntegration === false) {
                        fieldDefinitionsToCreate.push(fieldDefinitionToCreate);
                    } else if (currentColumn.fieldDefinition.fallBackToManual) {
                        // if person skipped the integrations, and the fallbackManual is true, treat it as manual
                        fieldDefinitionToCreate.type = 'MANUAL';
                        fieldDefinitionToCreate.updateable = true;
                        fieldDefinitionToCreate.definition = null;
                        fieldDefinitionsToCreate.push(fieldDefinitionToCreate);
                    }
                }
            }
        }

        return fieldDefinitionsToCreate;
    }

    /**
     * Function to evaluate field definitions of type formula (TNK_COLUMN_FORMULA).
     */
    function evaluateFormulaFieldDefinitions(fieldDefinitions) {
        if (!fieldDefinitions) {
            return fieldDefinitions;
        }

        const nonFormulaFieldDefinitions = fieldDefinitions.filter(
            (fieldDefinition) => fieldDefinition.type !== 'TNK_COLUMN_FORMULA',
        );
        const formulaFieldDefinitions = fieldDefinitions.filter(
            (fieldDefinition) => fieldDefinition.type === 'TNK_COLUMN_FORMULA',
        );

        // This will hold the field definitions we wish to create eventually.
        let finalFieldDefinitions = [];
        finalFieldDefinitions = finalFieldDefinitions.concat(nonFormulaFieldDefinitions);

        for (let formulaFieldDefinition of formulaFieldDefinitions) {
            formulaFieldDefinition = $scope.compileValues(formulaFieldDefinition);

            switch (formulaFieldDefinition.definition.formulaType) {
                case 'STRUCTURED':
                    // For structured formula, compiling the definition to inject the real field definition ids inside the definition.
                    finalFieldDefinitions.push(formulaFieldDefinition);
                    break;

                case 'EXPRESSION':
                    // For formula of expression type, we process the expression and replace the keys with the real field definition ids from the $scope.valueResults.
                    const expression = formulaFieldDefinition.definition.formulaExpression;
                    let compiledExpression = '';
                    let errorCompilingFormula = false;

                    for (let i = 0; i < expression.length; i++) {
                        const currentChar = expression[i];

                        // Field definition keys will be encapsulated in {} brackets
                        if (currentChar === '{') {
                            const startingIndex = i + 1;
                            const closingIndex = expression.indexOf('}', i); // The closing bracket of the opening bracket we found
                            const fieldDefinitionId = expression.substr(startingIndex, closingIndex - startingIndex);
                            let replaceWithValue = fieldDefinitionId;

                            // Only if the value between the brackets is a field definition id, we care to replace it.
                            // It can be other values too, e.g TNK_AMOUNT, etc.
                            if (
                                $scope.valueResults[fieldDefinitionId] ||
                                getTonkeanEntityType(fieldDefinitionId) ===
                                TONKEAN_ENTITY_TYPE.FIELD_DEFINITION
                            ) {
                                replaceWithValue = $scope.valueResults[fieldDefinitionId];

                                if (!replaceWithValue) {
                                    // If we're here, we expect the value to be present and if it is not, it's a problem.
                                    // This is the same as the required flag of the $scope.compileValues() function.
                                    errorCompilingFormula = true;
                                    break;
                                }
                            }

                            // Finally, concatenating current variable into the compiled expression.
                            compiledExpression += `{${replaceWithValue}}`;
                            // We processes the whole variable so we can increment i to process to the next char after the variable.
                            i = closingIndex;
                        } else {
                            // If we are not at the start of a variable, we just append current char into the compiled expression.
                            compiledExpression += currentChar;
                        }
                    }

                    // If no error compiling the formula, we update the compiled expression and push the definition into the
                    // final definitions array.
                    if (!errorCompilingFormula) {
                        formulaFieldDefinition.definition.formulaExpression = compiledExpression;
                        // groupId and projectId are a must for the server to work properly in creating the formula of expression type.
                        formulaFieldDefinition.groupId = $scope.data.newGroup.id;
                        formulaFieldDefinition.projectId = $scope.pm.project.id;
                        finalFieldDefinitions.push(formulaFieldDefinition);
                    }
                    break;
            }
        }

        return finalFieldDefinitions;
    }

    /**
     * Saves the given fieldsData (which should contain a creationResult array returned from the server) to a
     * key to fieldsDefinitionId mapping in $scope.valueResults.
     * This mechanism is used by sending a key to the server (configured in consts) and saving the created definition id to the same key.
     * This way we can extract the expected field definition id by key later on.
     * @param fieldsData - the fields creation data returned from the server.
     */
    function saveFieldDefinitionIdsToKeyMapping(fieldsData) {
        // Add the field definitions key-to-definitionId mapping to the $scope.valueResults.
        if (fieldsData && fieldsData.creationResults) {
            for (let i = 0; i < fieldsData.creationResults.length; i++) {
                const creationResult = fieldsData.creationResults[i];

                // If the creationResults returned with a key (which we have set), save the fieldDefinitionId to $scope.valueResults.
                if (
                    creationResult.key &&
                    !creationResult.errorsOccured &&
                    creationResult.definition &&
                    creationResult.definition.id
                ) {
                    $scope.valueResults[creationResult.key] = creationResult.definition.id;
                }
            }
        }
    }

    function createInitiatives() {
        if (
            (!$scope.initiativesResults || !$scope.initiativesResults.length) &&
            (!$scope.data.selectedList.initiatives || !$scope.data.selectedList.initiatives.length)
        ) {
            return $q.resolve();
        }

        const generatedIdsLength = 10;

        return trackHelper.fillIdsStock(generatedIdsLength).then(() => {
            for (let i = 1; i <= generatedIdsLength; i++) {
                $scope.valueResults[`INITIATIVE_ID_${i}`] = trackHelper.generateId();
            }

            // If initiatives in config, combine the lists.
            if ($scope.data.selectedList.initiatives && $scope.data.selectedList.initiatives.length) {
                $scope.initiativesResults = $scope.data.selectedList.initiatives.concat($scope.initiativesResults);
            }

            $scope.initiativesResults = $scope.compileValues($scope.initiativesResults);

            return prepareOwners($scope.initiativesResults).then(function () {
                // Prepare initiatives from $scope.initiativesResults.
                const initiatives = prepareInitiatives($scope.initiativesResults);

                if (initiatives.length) {
                    return trackHelper
                        .createMultipleInitiatives($scope.data.newGroup.id, initiatives)
                        .then(function () {
                            updatePostingProgress();
                        });
                }

                updatePostingProgress();

                return $q.resolve();
            });
        });
    }

    /**
     * Returns true if project integration is supported for this sync params. False otherwise.
     */
    function isSupportedIntegration(syncParams, projectIntegration) {
        // if no integration return false
        if (!projectIntegration) {
            return false;
        }

        // If supportedIntegrations does not exist at all, all integrations are supported by default.
        if (!syncParams.supportedIntegrations) {
            return true;
        }

        // Otherwise, we go through the supported integrations, checking if our integration exists in them.
        for (let j = 0; j < syncParams.supportedIntegrations.length; j++) {
            if (
                syncParams.supportedIntegrations[j].toLowerCase() ===
                projectIntegration.integration.integrationType.toLowerCase()
            ) {
                return true;
            }
        }

        return false;
    }

    /**
     * Make sure all initiatives that have owners have ownerId
     * if they dont, invite them and set the ownerId
     */
    function prepareOwners(initiatives) {
        if (initiatives) {
            // all the invites that needs to be sent
            const invites = [];
            for (const initiative of initiatives) {
                // see if he was already invited through the intro in the people selector
                if (
                    initiative.selectedPeople &&
                    initiative.selectedPeople.length > 0 &&
                    initiative.selectedPeople[0].id
                ) {
                    initiative.ownerId = initiative.selectedPeople[0].id;
                    continue;
                }

                // see if we need to invite him
                if (!initiative.ownerId && initiative.owner) {
                    // each invite has a return email and the original initiative so we can update the ownerId
                    invites.push({
                        email: initiative.owner.email,
                        initiative,
                    });
                }
            }

            // send all the invites in a bulk, for each invite search for the corresponding data returned from the server and set the ownerId on the original initiative
            if (invites.length > 0) {
                const users = [];
                const usersMap = {}; // to make sure we don't send dups
                for (const invite of invites) {
                    if (!usersMap[invite.email]) {
                        usersMap[invite.email] = true;
                        users.push({ email: invite.email });
                    }
                }

                const message = getInviteMessages($scope.pm.project.name).default;
                return inviteManager
                    .sendInvites($scope.pm.project.id, users, message, false, null, false)
                    .then(function (data) {
                        const invitedPeople = data.invites;
                        for (const invite of invites) {
                            for (const invitedPerson of invitedPeople) {
                                if (invite.email === invitedPerson.email) {
                                    invite.initiative.ownerId = invitedPerson.person.id;
                                }
                            }
                        }
                    });
            }
        }

        return $q.resolve();
    }

    /**
     * Prepares the given raw initiatives into a server valid initiatives to send.
     * @param rawInitiatives - the initiatives
     * @returns {Array}
     */
    function prepareInitiatives(rawInitiatives) {
        const initiatives = [];

        if (!rawInitiatives || !rawInitiatives.length) {
            return initiatives;
        }

        // Go over the raw initiatives we got and create valid server initiatives from them.
        for (const rawInitiative of rawInitiatives) {
            // We only add the initiative if it has at least a title.
            if (rawInitiative.title) {
                const newInitiative = { title: rawInitiative.title };

                newInitiative.groupId = $scope.data.newGroup.id;

                if (rawInitiative.initiativeId) {
                    newInitiative.initiativeId = rawInitiative.initiativeId;
                }
                if (rawInitiative.parentId) {
                    newInitiative.parentId = rawInitiative.parentId;
                }
                // Getting the project integration for the integrationType of the inner sync.
                if (rawInitiative.syncParams) {
                    const projectIntegration = $scope.integrationsResults[rawInitiative.syncParams.integrationType];
                    const integrationSupported = isSupportedIntegration(rawInitiative.syncParams, projectIntegration);

                    if (!integrationSupported) {
                        switch (rawInitiative.syncParams.noIntegrationSupportFallback) {
                            // If fall back is no initiative creation, we continue to next loop iteration, and by that
                            // skipping the creation of the initiative
                            case BuiltInListsUtils.noIntegrationSupportFallback.noInitiativeCreation:
                                continue;

                            // If fall back is to not sync into the initiative, we just skip the syncParams creation, and
                            // therefore there will be no sync into the initiative
                            case BuiltInListsUtils.noIntegrationSupportFallback.noSync:
                                break;
                        }
                    } else {
                        // Integration supported, we create the sync params.
                        newInitiative.syncParams = {
                            projectIntegrationId: projectIntegration.id,
                            viewType: 'Custom',
                            viewData: $scope.compileValues(rawInitiative.syncParams.viewData),
                        };
                    }
                }

                // Other optional properties.
                // If a due date was set, set the hour to 17:00. setHours returns a unix time number as needed for the server.
                newInitiative.dueDate = rawInitiative.dueDate
                    ? new Date(rawInitiative.dueDate).setHours(17, 0, 0, 0)
                    : null;
                // Owner might be a variable we need to compile.
                newInitiative.owner = rawInitiative.ownerId ? $scope.compileValues(rawInitiative.ownerId) : null;

                // Add the initiatives fields if they exist.
                if (rawInitiative.fields) {
                    newInitiative.fields = [];

                    // We expect the raw initiative's fields object to be a map from a fieldDefinitionKey to a value.
                    // The fieldDefinitionKey maps to a key in $scope.valueResults that is filled with a fieldDefinitionId by the createFields phase just before us.
                    for (const key in rawInitiative.fields) {
                        if (rawInitiative.fields.hasOwnProperty(key)) {
                            // Add the field to the new initiative with the given value and field definition id from the valueResults.
                            newInitiative.fields.push({
                                value: rawInitiative.fields[key],
                                fieldDefinitionId: $scope.valueResults[key],
                            });
                        }
                    }
                }

                // Setting due date to initiative.
                if (rawInitiative.dueDateDaysFromCreation) {
                    newInitiative.dueDate = new Date();
                    newInitiative.dueDate.addDays(rawInitiative.dueDateDaysFromCreation);
                    newInitiative.dueDate = newInitiative.dueDate.getTime();
                }

                // Setting owner to initiative.
                if (rawInitiative.setOwnerToCurrentUser) {
                    newInitiative.owner = authenticationService.currentUser.id;
                }

                if ($scope.data.selectedList.isExampleList) {
                    newInitiative.isExample = true;
                }

                if (rawInitiative.stateText) {
                    newInitiative.stateText = rawInitiative.stateText;
                }
                if (rawInitiative.stateColor) {
                    newInitiative.stateColor = rawInitiative.stateColor;
                }
                if (rawInitiative.status) {
                    newInitiative.status = rawInitiative.status;
                }
                if (rawInitiative.updateText) {
                    newInitiative.updateText = rawInitiative.updateText;
                }
                if (rawInitiative.eta) {
                    newInitiative.eta = rawInitiative.eta;
                }

                // Add the new initiative to the array.
                initiatives.push(newInitiative);
            }
        }

        return initiatives;
    }

    /**
     * Update the posting progress so we can show it as a progress bar.
     */
    function updatePostingProgress(progressCount) {
        const addProgressCount = progressCount || 1;

        $timeout(function () {
            $scope.data.postingProgress = $scope.data.postingProgress + addProgressCount;
        });
    }

    /**
     * Occurs after successfully submitting the new dashboard.
     */
    function onSubmitSuccessful() {
        // We set a 3 seconds delay to allow the server to finish updating the various fields...
        const creationDelay = $scope.data.selectedList.category ? 3000 : 0;

        $timeout(function () {
            // Emit a success message, only if this is not the first group,
            // because it pops over the already auto-popping education/kb popover.
            if ($scope.pm.groups && $scope.pm.groups.length > 1) {
                $scope.$emit('alert', {
                    msg: `${$scope.workersTemplateMode ? 'Module' : 'List'} created successfully!`,
                    type: 'success',
                });
            }

            // Broadcast an update message to the system.
            $rootScope.$broadcast('newActivityUpdate');

            // Complete the firstListCreated on boarding step. If it was already completed this action will be ignored.
            onBoardingManager.completeStep('firstListCreated');

            // Go to the new group's page.
            if ($scope.workersTemplateMode) {
                groupInfoManager.getGroups(true).finally(function () {
                    $state.go(
                        'product.workerEditor',
                        {
                            g: $scope.data.newGroup.id,
                            wr: null,
                            init: null,
                        },
                        { location: 'replace', reload: true },
                    );

                    // force refresh, otherwise things not really load up
                    $timeout(function () {
                        window.location.reload();
                    }, 300);

                    $scope.posting = false;
                });
            } else {
                if ($scope.data.selectedList && $scope.data.selectedList.isDuplicatedList) {
                    $state.go(
                        'product.board',
                        {
                            filter: 'all',
                            st: null,
                            g: $scope.data.newGroup.id,
                            listFilter: null,
                            isGroupListFilter: null,
                        },
                        { location: 'replace', reload: true },
                    );

                    // Force refresh, otherwise things don't really load up.
                    $timeout(function () {
                        window.location.reload();
                    }, 300);
                } else {
                    $state.go(
                        'product.board',
                        {
                            filter: 'all',
                            st: null,
                            g: $scope.data.newGroup.id,
                            listFilter: null,
                            isGroupListFilter: null,
                        },
                        { location: 'replace' },
                    );
                }

                $scope.posting = false;
            }

            // Close the modal as we are done.
            if ($scope.$close) {
                $scope.$close();
            }

            window.Intercom('trackEvent', 'Finished Builtin List Setup');
            window.Intercom('trackEvent', `Finished Builtin List Setup ${$scope.data.selectedList.category}`);
        }, creationDelay);
    }

    function handleError(errorResponse) {
        // Hide the loading circle and reset the posting progress.
        $scope.posting = false;
        $scope.data.postingProgress = 0;

        // Set a default error message.
        $scope.error = 'Unknown error. Please try again.';

        // Try to find the actual error message.
        if (errorResponse) {
            if (errorResponse.data && errorResponse.data.error && errorResponse.data.error.message) {
                $scope.error = errorResponse.data.error.message;
            } else if (errorResponse.statusText) {
                $scope.error = errorResponse.statusText;
            }
        }

        $scope.data.createExampleListButtonClicked = false;
    }

    /**
     * Updates the posting progress of the built in list creation and return an empty promise.
     */
    function updateProgressAndReturnPromise(progressCount) {
        updatePostingProgress(progressCount);
        return $q.resolve();
    }

    $scope.compileValues = function (value) {
        const flags = {};
        const compiledValue = compileValuesInner(value, flags);

        // If we were instructed to abort, return null.
        if (flags.abort) {
            return null;
        }

        return compiledValue;
    };

    /**
     * Opens the invite modal
     * @param ownersToInvite the list of users to invite
     */
    $scope.inviteWithEmail = function (ownersToInvite) {
        modal
            .openInvite(ownersToInvite, null, true)
            // update our collection for new users (update id)
            .then(function () {
                for (const invitedOwner of ownersToInvite) {
                    for (let j = 0; j < $scope.initiativesResults.length; j++) {
                        const initiative = $scope.initiativesResults[j];
                        if (initiative.owner && initiative.owner.email === invitedOwner.email) {
                            initiative.ownerId = invitedOwner.id;

                            // if there is a selected person and he fits the invited owner, replace the selected person and update the tnk-people-selector
                            if (
                                $scope.initiativesResults[j].selectedPeople[0] &&
                                $scope.initiativesResults[j].selectedPeople[0].email === invitedOwner.email
                            ) {
                                $scope.initiativesResults[j].selectedPeople[0] = invitedOwner;
                                $scope.initiativesResults[j].peopleSelectorControl.updateUninvitedPeople();
                            }
                            break;
                        }
                    }

                    // update the the manual owners, if they have id they are considered invited, this is important
                    // when need to calculate the uninvited list
                    for (let h = 0; h < $scope.data.manualInitiativesOwners.length; h++) {
                        const owner = $scope.data.manualInitiativesOwners[h];
                        if (owner.email === invitedOwner.email) {
                            owner.id = invitedOwner.id;
                            break;
                        }
                    }
                }

                $scope.calculateUninvitedManualOwners();
            });
    };

    $scope.loadCustomJson = function (text) {
        text = text.replace(/\n/g, '');

        text = text.replace(
            /BuiltInListsUtils\.variable\('(.+?)'.+?\)/g,
            "{ 'key': '$1', 'fromValues': 'true', 'required':'true'}",
        );

        if (text.includes("'id'")) {
            // text = text.replace(/"/g, '\\\\"');
            text = text.replaceAll("'", '"');
        }

        const json = JSON.parse(text);
        if (json) {
            $scope.selectList(json, true);
        }
    };

    /**
     * Compiles the given value recursively.
     * @param value - the value to compile.
     * @param flags - an inner flags object.
     *                Will contain flags.abort = true, if this value should be compiled as null.
     * @returns {*}
     */
    function compileValuesInner(value, flags) {
        // If we are aborting this compilation, return the value immediately.
        if (flags && flags.abort) {
            return value;
        }

        if (Array.isArray(value)) {
            const arr = [];
            for (const element of value) {
                arr.push(compileValuesInner(element, flags));
            }
            return arr;
        } else if (value instanceof Object) {
            // Check if it's our value placeholder.
            if (value.fromValues) {
                // If the placeholder is null, and this is a required variable, turn on the abort flag.
                if (value.required && utils.isNullOrUndefined($scope.valueResults[value.key])) {
                    flags.abort = true;
                    console.warn(`compileValues has been aborted, key: ${value.key} not found.`);
                }

                return $scope.valueResults[value.key];
            } else {
                const result = {};

                // recursive
                for (const property in value) {
                    if (value.hasOwnProperty(property)) {
                        if (value[property] && value[property].replaceKey) {
                            const key = $scope.valueResults[property] || property;
                            result[key] = compileValuesInner(value[property].value, flags);
                        } else {
                            result[property] = compileValuesInner(value[property], flags);
                        }

                        if (value[property] && value instanceof Object && value[property].fromValues) {
                            // means we replaced it in the result[propery], mark result as something that was compiled
                            result.compiledFrom = value[property].key;
                        }
                    }
                }

                return result;
            }
        } else if (typeof value === 'string' && value.indexOf('TNK_VALUE_EXPRESSION') === 0) {
            // Check if its a string that needs to be treated like an expression
            value = value.replace(/TNK_VALUE_EXPRESSION\((.*)\)/gs, '$1');
            return value.replace(/expressionVariable\((.*?)\)/g, (match, group1) => {
                return $scope.valueResults[group1];
            });
        } else {
            return value;
        }
    }

    /**
     * Return the categries ordered by keywords found in the title (if exists)
     */
    function getCategoriesList(categories) {
        let title = authenticationService.currentUser ? authenticationService.currentUser.title : null;
        if (title && title.length) {
            title = title.toLowerCase();
        }

        const categoriesList = [];
        // going over the categories, the one that has a match with the title
        // setting it to be first
        let foundMatch = false;
        for (const id in categories) {
            if (categories.hasOwnProperty(id)) {
                const cat = categories[id];
                let inserted = false;

                // only checking, if we didn't find match yet - this is in order to maintaining order of importance
                // ie. Sales is stronger than product (matching wise)
                if (!foundMatch && title && title.length && cat.titleKeywords && cat.titleKeywords.length) {
                    for (let i = 0; i < cat.titleKeywords.length; i++) {
                        const keyword = cat.titleKeywords[i].toLowerCase();
                        if (title.includes(keyword)) {
                            categoriesList.splice(0, 0, cat);
                            inserted = true;
                            foundMatch = true;
                            break;
                        }
                    }
                }

                if (!inserted) {
                    categoriesList.push(cat);
                }
            }
        }

        return categoriesList;
    }

    /**
     * Tells tnk-integration-selection to reset the "other" search box.
     */
    function resetIntegrationSelectionSearch() {
        // We should first set to false and then to true, so our tnkIntegrationSelection component recognizes a change.
        // We set to true using the $timeout service, so it happens in the next digest. Otherwise our toggle is meaningless.
        $scope.data.resetIntegrationSelectionSearch = false;
        $timeout(function () {
            $scope.data.resetIntegrationSelectionSearch = true;
        });
    }

    $scope.scrollModalToTop = function () {
        $timeout(function () {
            // scroll to top
            const el = document.querySelectorAll('.modal-content')[0];
            if (el) {
                el.scrollTop = 0;
            }
        });
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('SetupGroupCtrl', SetupGroupCtrl);
