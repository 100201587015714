import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';

import type SmartsheetFolder from './SmartsheetFolder';
import SourceType from './SmartsheetSourceType';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { TnkSelect } from '@tonkean/infrastructure';

const SmartsheetWorkspaceComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    projectIntegration,
    createProjectApis,
    onChangeOrInitIntegration,
}) => {
    const projectManager = useAngularService('projectManager');

    const [workspacesList, setWorkspacesList] = useState([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [selectedWorkspace, setSelectedWorkspace] = useState<{ value: string; label: string }>();
    const [currentFolder, setCurrentFolder] = useState<SmartsheetFolder>();
    const [folderNavigationHistory, setFolderNavigationHistory] = useState<SmartsheetFolder[]>([]);
    const [isLoadingFolders, setIsLoadingFolders] = useState(false);
    const [folders, setFolders] = useState([]);
    const [sourceType, setSourceType] = useState<SourceType>();

    // On every change of the project integration, we initialize current folder, selected workspace and source type from project data.
    useEffect(() => {
        const projectData = projectIntegration?.projectData?.projectDatas[0];
        if (projectData) {
            setCurrentFolder(projectData.folder);
            setSelectedWorkspace(projectData.workspace);
            setSourceType(projectData.sourceType === SourceType.HOME ? SourceType.HOME : SourceType.WORKSPACE);
        }
    }, [projectIntegration]);

    // On every change of the integration, we reset the state if the integration id was changed.
    useEffect(() => {
        // If the user changed the integration credentials (logged in to a different user) -
        // it means that the current project data is irrelevant and we should reset the component state.
        if (integration?.id !== projectIntegration?.integration?.id) {
            setCurrentFolder(undefined);
            setSelectedWorkspace(undefined);
            setSourceType(undefined);
        }
    }, [integration, projectIntegration]);

    // Loading the relevant workspace auto complete options.
    useEffect(() => {
        const onSourceTypeChanged = async () => {
            if (sourceType) {
                if (sourceType === SourceType.WORKSPACE) {
                    const workspaces = await createProjectApis.getAutoCompleteOptions(
                        projectManager.project.id,
                        integration.id,
                        'workspace',
                        {},
                    );
                    if (workspaces.options) {
                        // Constructing the options in the right model so we can use pass it to react-select dropdown.
                        const workspacesAutoCompleteOptions = constructAutoCompleteOptions(workspaces.options);
                        setWorkspacesList(workspacesAutoCompleteOptions);

                        setSelectedWorkspace(workspacesAutoCompleteOptions[0]);
                    }
                } else if (sourceType === SourceType.HOME) {
                    setSelectedWorkspace({ value: 'Home', label: 'Home' });
                }
            }
        };
        onSourceTypeChanged();
    }, [createProjectApis, integration.id, projectManager.project.id, sourceType]);

    // Load folder options.
    useEffect(() => {
        const fetchFolders = async () => {
            if (sourceType) {
                setIsLoadingFolders(true);
                try {
                    let params;
                    if (sourceType === SourceType.HOME && !currentFolder) {
                        params = { source: 'home' };
                    } else if (currentFolder) {
                        params = { folderId: currentFolder.value };
                    } else if (selectedWorkspace) {
                        params = { workspaceId: selectedWorkspace.value };
                    }
                    if (params) {
                        const innerFolders = await createProjectApis.getAutoCompleteOptions(
                            projectManager.project.id,
                            integration.id,
                            'folder',
                            params,
                        );
                        setFolders(innerFolders.options);
                    }
                } catch {
                    setError('Error occurred while fetching folders.');
                } finally {
                    setIsLoadingFolders(false);
                }
            }
        };
        fetchFolders();
    }, [createProjectApis, currentFolder, integration.id, projectManager.project.id, selectedWorkspace, sourceType]);

    const onWorkspaceChanged = useCallback((workspace) => {
        setSelectedWorkspace(workspace);
    }, []);

    const openFolder = (folder: SmartsheetFolder) => {
        if (currentFolder) {
            folderNavigationHistory.push(currentFolder);
            setFolderNavigationHistory(folderNavigationHistory);
        }
        setCurrentFolder(folder);
    };

    const selectFolder = () => {
        const projectIntegrationDisplayName = `Smartsheet Workspace (${currentFolder?.displayName || 'Root'} Folder)`;
        const projectData = {
            folder: currentFolder,
            workspace: selectedWorkspace,
            sourceType,
        };

        onChangeOrInitIntegration({ projectIntegrationData: projectData, projectIntegrationDisplayName }, false);
    };

    const goToPreviousFolder = () => {
        // Remove the last folder in the array.
        const targetFolder = folderNavigationHistory.pop();
        // Updating folder navigation history.
        setFolderNavigationHistory(folderNavigationHistory);
        // If the folder we just popped out is undefined it means we are in the ROOT folder.
        if (!targetFolder) {
            setSourceType(undefined);
            setCurrentFolder(undefined);
        } else {
            setCurrentFolder(targetFolder);
        }
    };

    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: option.displayName,
        }));
    };

    const selectSourceType = (sourceType: SourceType) => {
        setSourceType(sourceType);
    };

    return (
        <div className="flex flex-col">
            {error && <div className="alert alert-danger margin-bottom">{error}</div>}

            {!sourceType && (
                <div>
                    <button
                        onClick={() => selectSourceType(SourceType.HOME)}
                        className="btn btn-default btn-lg block common-width-100 margin-bottom"
                    >
                        Home
                    </button>

                    <button
                        onClick={() => selectSourceType(SourceType.WORKSPACE)}
                        className="btn btn-default btn-lg block common-width-100"
                    >
                        Workspaces
                    </button>
                </div>
            )}

            {sourceType && (
                <div className="flex flex-col">
                    <TnkSelect
                        value={selectedWorkspace}
                        className="margin-bottom"
                        isMulti={false}
                        isCreatable={false}
                        isDisabled={sourceType === SourceType.HOME}
                        onChange={onWorkspaceChanged}
                        isClearable={false}
                        options={workspacesList}
                        closeMenuOnSelect
                        isSearchable
                    />

                    <div className="flex-vmiddle margin-bottom">
                        <b>Select a Folder:</b>
                        <a onClick={goToPreviousFolder} className="margin-left-xs">
                            Back
                        </a>
                    </div>

                    <div className="integration-instance-selection-box-header common-bold">
                        {currentFolder
                            ? currentFolder?.displayName
                            : sourceType === SourceType.HOME
                              ? 'Sheets'
                              : 'My Workspace'}
                    </div>

                    <div className="integration-instance-selection-box">
                        {isLoadingFolders && (
                            <div className="text-center margin-top-lg">
                                <i className="loading-medium" />
                            </div>
                        )}

                        {!isLoadingFolders && selectedWorkspace && folders.length > 0 && (
                            <div className="common-height-full">
                                {folders.map((folder: SmartsheetFolder, index: number) => (
                                    <div
                                        key={folder.value}
                                        onClick={() => openFolder(folder)}
                                        className="list-item btn-default pointer common-size-xxs flex-vmiddle"
                                    >
                                        <div className="flex-grow">
                                            <strong>{folder.displayName}</strong>
                                            <div className="common-size-xxxxs common-color-grey">
                                                <span>{folder.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {!folders.length && !isLoadingFolders && (
                            <div className="flex-vmiddle mod-justify-center common-height-full">
                                <div className="text-center">
                                    <div className="common-color-grey common-size-xxxs">
                                        This folder has no inner folders
                                    </div>

                                    <button className="btn btn-sm btn-primary margin-top-xs" onClick={selectFolder}>
                                        Use this Folder
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    <button
                        className="btn btn-primary flex-no-shrink common-no-radius"
                        ng-if="data.integration && data.configStep === 'selectFolder' && data.currentFolder.value"
                        onClick={selectFolder}
                    >
                        Use this Folder ({currentFolder ? currentFolder.displayName : 'Root'})
                    </button>
                </div>
            )}
        </div>
    );
};

export default SmartsheetWorkspaceComponent;
