import React from 'react';

import useSolutionSiteContext from '../../../hooks/useSolutionSiteContext';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import WidgetConfigurationFieldSelector from '../../CommonWidgetConfiguration/WidgetConfigurationFieldSelector';
import useWidgetConfigurationDeletedFields from '../../hooks/useWidgetConfigurationDeletedFields';
import type FieldsItemWidgetConfiguration from '../FieldsItemWidgetConfiguration';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { LoadingCircle } from '@tonkean/infrastructure';
import type { WorkflowVersionType } from '@tonkean/tonkean-entities';

const SolutionSiteFieldsWidgetConfigurationEditor: React.FC<ItemWidgetEditorProps<FieldsItemWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
}) => {
    const { solutionSite } = useSolutionSiteContext();

    const [entityVersionType] = useGetStateParams<[WorkflowVersionType]>('env');

    const { data: globalFieldDefinitions, loading } = useTonkeanService(
        'getWorkflowFolderGlobalFieldDefinitions',
        solutionSite.workflowFolderId,
        entityVersionType,
    );

    const deletedFieldsIds = useWidgetConfigurationDeletedFields(
        globalFieldDefinitions?.entities ?? [],
        Object.keys(configuration.fields || {}),
    );

    if (loading || !globalFieldDefinitions) {
        return <LoadingCircle large centered />;
    }

    return (
        <>
            <WidgetConfigurationFieldSelector
                allFieldDefinitions={globalFieldDefinitions?.entities}
                groups={undefined}
                deletedFieldsIds={deletedFieldsIds}
                workflowVersion={workflowVersion}
                workflowVersionType={entityVersionType}
                fixedOrderForSpecialFields={false}
            />
        </>
    );
};

export default SolutionSiteFieldsWidgetConfigurationEditor;
