import Utils from '@tonkean/utils';

/**
 * Calculates a unique id for the given state.
 */
function getStateUniqueId(state) {
    if (!state) {
        return null;
    }

    const replaceSpaceRegex = new RegExp(' ', 'g');
    return `${state.color}-${state.type}-${state.label.replace(replaceSpaceRegex, '')}`;
}

/**
 * If the given initiative is presented in a group which is different than its original group (meaning he's linked),
 * returns an array of states that do not exist in the current group but do exist
 * in the initiative's original group. The function removes duplicates from the returned states, i.e you won't receive states
 * that are identical to those in the current group (according to state label, color and type).
 * Will return null if some needed data is missing, if the initiative is not linked or if the returned array will be empty.
 * @param initiativeGroup - the given initiative's group (bring it from the pm's cache).
 * @param currentGroup - the current group the user's viewing.
 * @returns {*}
 */
export function getInitiativeOriginalGroupStates(
    initiativeGroup,
    initiativeWorkflowVersion,
    currentGroup,
    currentGroupWorkflowVersion,
) {
    // We only return original group states if this initiative is linked and the current group is different than it's original group.
    if (!initiativeGroup || !currentGroup || initiativeGroup.id === currentGroup.id) {
        return null;
    }

    const currentGroupStates = currentGroupWorkflowVersion.states.filter((state) => state.label?.length);

    // Build a set from the states array so we can filter out duplicates. But first, convert the states to some unique id.
    const statesSet = Utils.arrayToSet(currentGroupStates.map((state) => getStateUniqueId(state)));

    // Go over the states in the track's original group, and only add the ones that are not duplicates (and not with an empty label).
    const originalGroupStates = initiativeWorkflowVersion.states.filter(
        (state) => state.label?.length && !statesSet[getStateUniqueId(state)!],
    );

    return originalGroupStates?.length ? originalGroupStates : null;
}
