import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { StyledField, StyledTonkeanExpression } from './SuccessCriteriaSharedStyles';
import { ReactComponent as TrashIcon } from '../../.././../../../../../../../images/icons/trash.svg';
import { ReactComponent as InfoIcon } from '../../../../../../../../../../images/icons/info10px.svg';

import { Field, Input, Tooltip, useCreateDialog } from '@tonkean/infrastructure';
import { ActionParameterType, ActionType, EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import type {
    ProjectIntegrationAction,
    ProjectIntegrationActionModuleResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const ClickableAddField = styled(Clickable)`
    color: ${Theme.colors.primary};
    font-weight: 500;
    margin-left: auto;
    margin-right: 15px;
    font-size: ${FontSize.SMALL_12};
    margin-bottom: 2px;
    display: block;
`;

const StyledInfoIcon = styled(InfoIcon)`
    margin-left: 6px;
`;

const StyledTrashIcon = styled(TrashIcon)`
    opacity: 0;
    transition: opacity 0.1s linear;
`;

const CustomFieldWrapper = styled.div`
    background-color: ${Theme.current.palette.colorPicker.HEX_FFFFFF};
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 3px;
    margin: 10px 15px 15px 15px;
    padding: 0 20px 12px 20px;
    position: relative;

    &:hover {
        ${StyledTrashIcon} {
            opacity: 1;
            transition: opacity 0.1s linear;
        }
    }
`;

const StyledIconButton = styled(IconButton)`
    margin-left: auto;
    margin-top: 8px;
`;

interface Props {
    setProjectIntegrationAction: React.Dispatch<
        React.SetStateAction<ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>>
    >;
    projectIntegrationAction: ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>;
    groupId: string;
    actionsCustomFieldTab: TonkeanExpressionAdditionalTab[];
    evaluateExpressionToEvaluatedPreview?: Record<string, string>;
}

const SuccessCriteriaCustom: React.FC<Props> = ({
    setProjectIntegrationAction,
    projectIntegrationAction,
    groupId,
    actionsCustomFieldTab,
    evaluateExpressionToEvaluatedPreview,
}) => {
    const { confirm } = useCreateDialog();

    // When clicking the add new field button, we add new field in the action responseHandlingDefinition.
    const onAddingNewField = useCallback(() => {
        setProjectIntegrationAction((currentAction) => {
            return {
                ...currentAction,
                responseHandlingDefinition: {
                    responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                    ...currentAction.responseHandlingDefinition,
                    successDefinition: {
                        ...currentAction.responseHandlingDefinition?.successDefinition,
                        outputLinkedFields: [
                            ...(currentAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields || []),
                            {
                                name: '',
                                value: {
                                    originalExpression: '',
                                    evaluatedExpression: '',
                                    isStripHtmlDisabled: true,
                                    parameterType: ActionParameterType.TONKEAN_EXPRESSION,
                                },
                                containsEntityId:
                                    currentAction.actionType === ActionType.CREATE ||
                                    currentAction.actionType === ActionType.CREATE_OR_UPDATE,
                            },
                        ],
                    },
                },
            };
        });
    }, [setProjectIntegrationAction]);

    // When clicking the remove field button
    const onDeleteField = useCallback(
        async (fieldIndex: number) => {
            const confirmed = await confirm(
                'Are you sure?',
                'Removing fields which are being used by modules may break existing flows.',
                {
                    okLabel: 'Remove',
                    cancelLabel: 'Cancel',
                },
            );

            if (confirmed) {
                setProjectIntegrationAction((currentAction) => {
                    return {
                        ...currentAction,
                        responseHandlingDefinition: {
                            responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                            ...currentAction.responseHandlingDefinition,
                            successDefinition: {
                                ...currentAction.responseHandlingDefinition?.successDefinition,
                                outputLinkedFields:
                                    currentAction?.responseHandlingDefinition?.successDefinition?.outputLinkedFields.filter(
                                        (linkedField, outputLinkedFieldIndex) => outputLinkedFieldIndex !== fieldIndex,
                                    ) || [],
                            },
                        },
                    };
                });
            }
        },
        [confirm, setProjectIntegrationAction],
    );

    // On changing the field value we updating the action.
    const onChangeCustomFieldValue = useCallback(
        (originalExpression: string, evaluatedExpression: string, index: number) => {
            setProjectIntegrationAction((currentAction) => {
                return {
                    ...currentAction,
                    responseHandlingDefinition: {
                        responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                        ...currentAction.responseHandlingDefinition,
                        successDefinition: {
                            ...currentAction.responseHandlingDefinition?.successDefinition,
                            outputLinkedFields: currentAction.responseHandlingDefinition?.successDefinition
                                ?.outputLinkedFields
                                ? [
                                      ...currentAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields.map(
                                          (outputLinkedField, outputLinkedFieldIndex) =>
                                              outputLinkedFieldIndex === index
                                                  ? {
                                                        ...outputLinkedField,
                                                        value: {
                                                            ...outputLinkedField.value,
                                                            originalExpression,
                                                            evaluatedExpression,
                                                            parameterType: ActionParameterType.TONKEAN_EXPRESSION,
                                                        },
                                                    }
                                                  : { ...outputLinkedField },
                                      ),
                                  ]
                                : [],
                        },
                    },
                };
            });
        },
        [setProjectIntegrationAction],
    );

    // On changing the new field name we updating the action.
    const onChangeFieldName = useCallback(
        (newName: string, index: number) => {
            setProjectIntegrationAction((currentAction) => {
                return {
                    ...currentAction,
                    responseHandlingDefinition: {
                        responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                        ...currentAction.responseHandlingDefinition,
                        successDefinition: {
                            ...currentAction.responseHandlingDefinition?.successDefinition,
                            outputLinkedFields: currentAction.responseHandlingDefinition?.successDefinition
                                ?.outputLinkedFields
                                ? [
                                      ...currentAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields.map(
                                          (outputLinkedField, outputLinkedFieldIndex) =>
                                              outputLinkedFieldIndex === index
                                                  ? { ...outputLinkedField, name: newName }
                                                  : { ...outputLinkedField },
                                      ),
                                  ]
                                : [],
                        },
                    },
                };
            });
        },
        [setProjectIntegrationAction],
    );

    // Counting each name to validate if its uniq name.
    const fieldsNamesToExistCountDictionary = useMemo(() => {
        if (projectIntegrationAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields) {
            const fieldNames =
                projectIntegrationAction.responseHandlingDefinition.successDefinition.outputLinkedFields.map(
                    (outputField) => outputField.name,
                );
            return fieldNames.reduce((acc, curr) => {
                acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
                return acc;
            }, {});
        } else {
            return {};
        }
    }, [projectIntegrationAction?.responseHandlingDefinition?.successDefinition?.outputLinkedFields]);

    return (
        <>
            <ClickableAddField onClick={onAddingNewField}>+ Add Field</ClickableAddField>

            {projectIntegrationAction.responseHandlingDefinition?.successDefinition?.outputLinkedFields.map(
                (outputLinkedField, index) => (
                    <CustomFieldWrapper key={index}>
                        <Field
                            error={
                                !outputLinkedField.name ||
                                outputLinkedField.name === '' ||
                                fieldsNamesToExistCountDictionary[outputLinkedField.name] > 1
                                    ? "Name can't be empty and must be unique"
                                    : undefined
                            }
                            label={<StyledField>Name:</StyledField>}
                        >
                            <Input
                                size={InputSize.LARGE}
                                placeholder="New Field Name"
                                value={outputLinkedField.name}
                                onChange={({ target: { value } }) => {
                                    onChangeFieldName(value, index);
                                }}
                            />
                        </Field>

                        <Field
                            error={!outputLinkedField.value.originalExpression ? "Value can't be empty" : undefined}
                            label={
                                <StyledField>
                                    Value:
                                    <Tooltip content="You can use Json Path formula to indicate the location of the field value">
                                        <StyledInfoIcon />
                                    </Tooltip>
                                </StyledField>
                            }
                        >
                            <StyledTonkeanExpression
                                groupId={groupId}
                                savedOriginalExpression={outputLinkedField.value.originalExpression}
                                savedEvaluatedExpression={outputLinkedField.value.evaluatedExpression}
                                workflowVersionId=""
                                placeholder="Extract value from the response body/headers"
                                onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                                    onChangeCustomFieldValue(originalExpression, evaluatedExpression, index);
                                }}
                                additionalTabs={actionsCustomFieldTab}
                                customPreview={
                                    evaluateExpressionToEvaluatedPreview?.[
                                        outputLinkedField.value.evaluatedExpression
                                    ] || 'No Test Runs found'
                                }
                                globalExpressionOnly
                            />
                        </Field>

                        <StyledIconButton onClick={() => onDeleteField(index)} flat>
                            <StyledTrashIcon />
                        </StyledIconButton>
                    </CustomFieldWrapper>
                ),
            )}
        </>
    );
};

export default SuccessCriteriaCustom;
