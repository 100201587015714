import { useRef, useCallback } from 'react';

/**
 * A hook to run a callback after some delay.
 *
 * @example
 * const Text: React.FC = () => {
 *   const [boolValue, setBoolValue] = useState<boolean>(false);
 *
 *   const restoreValue = useCallback(() => boolValue && setBoolValue(false), [boolValue]);
 *   useTimeout(restoreValue, 1000);
 * }
 *
 * @param callback the callback to run after timeout
 * @param timeout the timeout (in ms) to delay callback execution
 *
 * @returns an array, the first element is a function to trigger the timeout and second is a function to cancel it.
 */
function useTimeout(callback: () => void, timeout: number = 0) {
    const timeoutIdRef = useRef<NodeJS.Timeout>();
    const cancel = useCallback(() => {
        const timeoutId = timeoutIdRef.current;

        if (timeoutId) {
            timeoutIdRef.current = undefined;
            clearTimeout(timeoutId);
        }
    }, [timeoutIdRef]);

    const trigger = useCallback(() => {
        cancel();

        timeoutIdRef.current = setTimeout(callback, timeout);
    }, [callback, cancel, timeout]);

    return [trigger, cancel] as const;
}

export default useTimeout;
