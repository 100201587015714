import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class PairsFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.PAIRS;
    override readonly sign = 'Pairs';
    override readonly displayName = 'Pairs';
    override readonly description = 'Create a JSON that contains the pairs as key value fields inside of it.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Pairs',
            pluralName: 'Pairs',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => [
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: FieldType.String,
                    displayName: `Key ${repeatIndex}`,
                    explanation: 'The key of the pair.',
                },
                {
                    fieldDefinitionType: FieldDefinitionType.SINGLE,
                    dataType: FieldType.String,
                    displayName: `Value ${repeatIndex}`,
                    explanation: `The value of the pair.`,
                },
            ],
        },
    ];
}
