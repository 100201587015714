import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function IntegrationJiraCountFieldCtrl($scope, projectManager) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        // Component bindings
        projectIntegration: ctrl.projectIntegration,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        targetType: ctrl.targetType,
        createMode: ctrl.createMode,
        duplicateMode: ctrl.duplicateMode,
        duplicateOrCreateMode: ctrl.createMode || ctrl.duplicateMode,
        existingFieldDefinition: ctrl.existingFieldDefinition,

        fieldDefinitionName: ctrl.fieldDefinitionName,
        fieldDefinitionNameEdited: ctrl.fieldDefinitionNameEdited,

        customJql: false,
        jql: '',
    };

    /**
     * Initialization function for the component.
     */
    ctrl.$onInit = function () {
        if (!$scope.data.createMode && $scope.data.existingFieldDefinition.definition) {
            initializeEditMode();
        }

        // It is important to fire the event to get the containing controller to have the updated data.
        definitionChanged();
    };

    /**
     * Occurs when changes are made to component bindings.
     */
    ctrl.$onChanges = function () {};

    /**
     * Toggles the field definition type.
     */
    $scope.toggleFieldDefinitionType = function () {
        $scope.data.customJql = !$scope.data.customJql;
    };

    /**
     * Occurs once the jql is changed.
     */
    $scope.onJqlChanged = function () {
        definitionChanged();
    };

    /**
     * Occurs once the aggregate query definition is changed.
     */
    $scope.onDefinitionChange = function (newDefinition, doNotReloadPreview) {
        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({
                newDefinition,
                doNotReloadPreview,
                actualFieldDefinitionType: 'AGGREGATE_QUERY',
            });
        }
    };

    /**
     * Add the given keyword to jql text.
     */
    $scope.addKeywordToJQL = function (keyword) {
        $scope.data.jql += keyword;
    };

    /**
     * Occurs once the definition changes.
     */
    function definitionChanged() {
        const validDefinition = $scope.data.jql && $scope.data.jql.length;

        const definitionObject = {
            fieldConfigurationSummaryTitle: 'Count issues',
            fieldConfigurationSummarySubTitle: null,
            validDefinition,
            definition: {
                jql: $scope.data.jql,
            },
        };

        if (ctrl.onDefinitionChange) {
            ctrl.onDefinitionChange({
                newDefinition: definitionObject,
                doNotReloadPreview: !validDefinition,
                actualFieldDefinitionType: 'JIRA_COUNT_FILTER',
            });
        }
    }

    /**
     * Initializes elastic edit mode.
     */
    function initializeEditMode() {
        if (
            $scope.data.existingFieldDefinition.definition &&
            $scope.data.existingFieldDefinition.definition.jql &&
            $scope.data.existingFieldDefinition.definition.jql.length
        ) {
            $scope.data.customJql = true;
            $scope.data.jql = $scope.data.existingFieldDefinition.definition.jql;
        }
    }
}
export default angular
    .module('tonkean.app')
    .controller('IntegrationJiraCountFieldCtrl', lateConstructController(IntegrationJiraCountFieldCtrl));
