import template from './tnkWorkerFileSelection.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkWorkerFileSelection', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        customTriggerId: '@',

        fileSourceType: '@',
        storageIntegrationId: '@',
        storageIntegrationActionId: '@',
        extendedMatchConfiguration: '<',
        urlExpression: '<',
        workerStaticAssetId: '@',
        workerStaticAssetDisplayName: '@',
        previewEvaluationSource: '<',
        parametersValues: '<',
        shouldDisplayDeleteSign: '<',
        onDeleteSignClicked: '&',

        onWorkerFileSelectionChanged: '&',
        infoMessage: '<',

        attachmentIndex: '<', // Saves the position of the attachment in the attachment group

        shouldAddMatchedEntityOption: '<',
        matchEntityConfiguration: '<',
        isVerticalRadioBoxes: '<',

        validationObject: '<',
    },
    template,
    controller: 'WorkerFileSelectionCtrl',
});
