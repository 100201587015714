import type { IDeferred, IPromise } from 'angular';
import type { AngularServices } from 'angulareact';
import type { IntegrationSupportedEntities, IntegrationSupportedEntity } from '@tonkean/tonkean-entities';

/**
 * A service to help with integration metadata operations.
 */
class IntegrationMetadataManager {
    /**
     * A map between a project integration id to entities map.
     */
    public projectIntegrationIdToEntitiesMap: Record<string, IntegrationSupportedEntities> = {};

    public projectIntegrationToDeferred: Record<string, IDeferred<unknown>[]> = {};

    protected constructor(
        private $q: AngularServices['$q'],
        private tonkeanService: AngularServices['tonkeanService'],
        private requestThrottler: AngularServices['requestThrottler'],
    ) {}

    /**
     * Gets the entities supported for the given project integration.
     */
    public getIntegrationEntities(projectIntegrationId: string): IPromise<IntegrationSupportedEntities | undefined> {
        if (this.projectIntegrationIdToEntitiesMap[projectIntegrationId]) {
            // If we already have the response for the get integration entities in cache, we return it.
            return this.$q.resolve(this.projectIntegrationIdToEntitiesMap[projectIntegrationId]);
        } else {
            // We're creating a deferred promise so we can later resolve/reject it in the throttler.
            const deferred: IDeferred<IntegrationSupportedEntities> = this.$q.defer();

            // Initializing the map between project integration id and its promises.
            if (!this.projectIntegrationToDeferred[projectIntegrationId]) {
                this.projectIntegrationToDeferred[projectIntegrationId] = [];
            }

            // Adding the current deferred to the map.
            this.projectIntegrationToDeferred[projectIntegrationId]?.push(deferred);

            // Throttling the request to supported entities, so if we have multiple requests at once, we
            // won't call the API multiple times.
            this.requestThrottler.do(
                `projectIntegrationSupportedEntities-${projectIntegrationId}`,
                50,
                () => this.tonkeanService.getIntegrationSupportedEntities(projectIntegrationId),
                (data) => {
                    // Initialize map if needed.
                    if (!this.projectIntegrationIdToEntitiesMap) {
                        this.projectIntegrationIdToEntitiesMap = {};
                    }

                    // Cache response.
                    this.projectIntegrationIdToEntitiesMap[projectIntegrationId] = data;

                    // Once the latest deferred is resolve, we can resolve all the promises under the
                    // project integration.
                    this.projectIntegrationToDeferred[projectIntegrationId]?.forEach((promise) => {
                        promise.resolve(this.projectIntegrationIdToEntitiesMap[projectIntegrationId]);
                    });
                },
                (error) => {
                    // Once the latest deferred is rejected, we reject all the promises of the integration.
                    this.projectIntegrationToDeferred[projectIntegrationId]?.forEach((promise) => {
                        promise.reject(error);
                    });
                },
            );

            // Return cached response.
            return deferred.promise;
        }
    }

    /**
     * Gets the entities supported for the given project integration.
     */
    public async getIntegrationEntitiesAsDict(
        projectIntegrationId: string,
    ): Promise<Record<string, IntegrationSupportedEntity> | undefined> {
        if (projectIntegrationId) {
            const supportedEntities = await this.getIntegrationEntities(projectIntegrationId);
            return Object.fromEntries(
                supportedEntities?.supportedEntities.map((supportedEntity) => [
                    supportedEntity.entity,
                    supportedEntity,
                ]) || [],
            );
        }
    }
}

angular.module('tonkean.app').service('integrationMetadataManager', IntegrationMetadataManager);

export default IntegrationMetadataManager;
