import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class MaxFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.MAX;
    override readonly sign = 'Max';
    override readonly displayName = 'Maximum';
    override readonly description = 'Will calculate the maximum of the given fields.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Number',
            pluralName: 'Numbers',
            fieldDefinitionType: FieldDefinitionType.ARRAY,
            minRepeats: 1,
            defaultRepeats: 2,
            generator: (repeatIndex) => ({
                fieldDefinitionType: FieldDefinitionType.SINGLE,
                dataType: FieldType.Number,
                displayName: `Number ${repeatIndex}`,
            }),
        },
    ];
}
