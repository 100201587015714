import type { CSSProperties } from 'react';
import React from 'react';
import styled from 'styled-components';

import IconDisplay from './IconDisplay';
import TonkeanIconInsidePicker from './TonkeanIconInsidePicker';

import type { Icon, IconsByCategory } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Category = styled.div<{ $width: number }>`
    width: ${({ $width }) => $width}px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
`;

const IconsDisplay = styled.ul<{ $showCategories: boolean | undefined }>`
    display: flex;
    ${({ $showCategories }) => $showCategories && 'flex: 1'};
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding-inline: inherit;
    margin-bottom: inherit;
    padding: 0 8px;
`;

const CategoryTitle = styled.span`
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_700};
    margin: 8px 16px;
`;

interface Props {
    data: {
        icons: IconsByCategory[];
        showCategories?: boolean;
        onChangeIcon: (icon: Icon) => void;
        width: number;
        height: number;
        selectedIcon: Icon | undefined;
        color?: string;
    };
    index: number;
    style: CSSProperties;
}

const IconsCategorySection: React.FC<Props> = ({
    data: { icons, showCategories, onChangeIcon, width, selectedIcon, color },
    index,
    style,
}) => {
    const item = icons?.[index];
    return item ? (
        <Category key={item.label} $width={width} style={style}>
            {showCategories && (
                <CategoryTitle data-automation={`icons-category-title-${item.label}`}>{item.label}</CategoryTitle>
            )}
            <IconsDisplay $showCategories={showCategories}>
                {item.icons.map((icon, index) => {
                    return (
                        <IconDisplay
                            icon={icon}
                            onChangeIcon={onChangeIcon}
                            key={`${icon.name}-${item.label}-${index}`}
                        >
                            <TonkeanIconInsidePicker icon={icon} selectedIcon={selectedIcon} color={color} />
                        </IconDisplay>
                    );
                })}
            </IconsDisplay>
        </Category>
    ) : (
        <Category key="empty" $width={width} />
    );
};

export default IconsCategorySection;
