import TrackListItem from './TrackListItem';

import { reactToAngular } from '@tonkean/angular-components';

angular
    .module('tonkean.app')
    .component(
        'tnkTrackListItem',
        reactToAngular(
            TrackListItem,
            [
                'id',
                'data',
                'uiActions',
                'displayFieldsList',
                'ignoreColumnVisibility',
                'hideContextMenu',
                'formViewInInitiative',
                'disableGoToTrack',
                'createdInFormId',
                'collectItemsMode',
                'viewItemsMode',
                'fieldValidationMap',
                'hideHistory',
                'onTrackRemoved',
                'dragging',
                'disableOwnerInvite',
                'hideTitle',
                'titleFormLabel',
                'workerForms',
                'filterVisibleFieldDefinitions',
                'showActionsColumn',
                'showDone',
                'enableInnerItemsToggle',
                'enableAddingItems',
                'onManualFieldUpdate',
                'numerateItems',
                'loadingRelatedInitiatives',
            ],
            [
                'projectManager',
                'groupPermissions',
                'authenticationService',
                'workflowVersionManager',
                'workflowVersionHelper',
                'customFieldsManager',
                'trackHelper',
                'personCache',
                'modal',
                'currentUserService',
                'licensePermissionsService',
                'trackStore',
                'tonkeanService',
                'liveReportHelper',
                'activityManager',
                '$rootScope',
                '$scope',
                '$timeout',
            ],
        ),
    );
