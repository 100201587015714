import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import usePairValuesFilter from '../../hooks/usePairValuesFilter';
import useSortedOperatorsByDataType from '../../hooks/useSortedOperatorsByDataType';

import { FieldTypeIcon } from '@tonkean/fields';
import { getOperators } from '@tonkean/forumla-operators';
import type { FieldType, ProjectIntegration } from '@tonkean/tonkean-entities';
import type { FormulaOperatorDefinitionBase } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import utils, { filterPairValues } from '@tonkean/utils';

// Create data type to formula operators list pairs list

interface Props {
    depth: number;
    canSelectInnerTrackAggregation: boolean;
    onSelected: (operators: FormulaOperatorDefinitionBase) => void;
    filter: string;
    customTrigger?: any;
    projectIntegration?: ProjectIntegration;
}

const FormulaPopoverTabFormula: React.FC<Props> = ({
    depth,
    canSelectInnerTrackAggregation,
    onSelected,
    filter,
    customTrigger,
    projectIntegration,
}) => {
    const operatorsByDataType = useMemo(() => {
        return Object.entries(
            utils.groupBy(
                // Filter out deprecated operators
                getOperators({ withInnerTrackAggregationOperand: true, customTrigger, projectIntegration }),
                (operator: FormulaOperatorDefinitionBase) =>
                    typeof operator.dataType === 'function' ? 'Dynamic' : operator.dataType,
            ),
        ) as [FieldType, FormulaOperatorDefinitionBase[]][];
    }, [customTrigger, projectIntegration]);

    const filterRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        filterRef.current?.focus();
    }, []);

    const filterFunction = useCallback(
        (formulaOperator: FormulaOperatorDefinitionBase, searchString: string): boolean =>
            formulaOperator.displayName.toLowerCase().includes(searchString) ||
            formulaOperator.sign.toLowerCase().includes(searchString),
        [],
    );

    const filteredOperatorsByDepth = useMemo(() => {
        if (depth === 0 && canSelectInnerTrackAggregation) {
            return operatorsByDataType;
        }

        return filterPairValues(
            operatorsByDataType,
            (operator) => operator.key !== OperatorKey.INNER_TRACK_AGGREGATION,
        );
    }, [canSelectInnerTrackAggregation, depth, operatorsByDataType]);

    const sortedFormulaOperatorsByDataType = useSortedOperatorsByDataType(filteredOperatorsByDepth);

    const filteredFormulaOperatorsByDataType = usePairValuesFilter(
        sortedFormulaOperatorsByDataType,
        filterFunction,
        filter,
    );

    return (
        <div className="inline-select-list">
            {filteredFormulaOperatorsByDataType.map(
                ([dataType, formulas]) =>
                    formulas.length && (
                        <div key={dataType} className="inline-select-list-data-type">
                            <h5 className="inline-select-title">{dataType}</h5>
                            {formulas.map((formula) => (
                                <button
                                    key={formula.id}
                                    type="button"
                                    className="inline-select-item"
                                    data-automation={`formula-popover-tab-formula-item-${formula.displayName}`}
                                    onClick={() => onSelected(formula)}
                                >
                                    <span className="tabs-field-selector-field-icon-container">
                                        <FieldTypeIcon fieldType="formula" size="xs" />
                                    </span>
                                    {formula.displayName}
                                </button>
                            ))}
                        </div>
                    ),
            )}
        </div>
    );
};

export default FormulaPopoverTabFormula;
