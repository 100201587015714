import React from 'react';
import styled from 'styled-components';

import { DrillDownNotSelectedIcon } from '@tonkean/svg';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 8px;
`;

const Label = styled.div`
    font-size: ${FontSize.LARGE_16};
    font-weight: 400;
    color: ${Theme.colors.gray_600};
`;

export interface DetailedItemsWidgetDrillDownNotSelectedProps {
    inInterfaceEditMode: boolean;
}

const DetailedItemsWidgetDrillDownNotSelected: React.FC<DetailedItemsWidgetDrillDownNotSelectedProps> = ({
    inInterfaceEditMode,
}) => {
    const message = inInterfaceEditMode
        ? 'Select drill down interface to show'
        : 'No detailed interface has been selected';

    return (
        <Wrapper>
            <DrillDownNotSelectedIcon />
            <Label>{message}</Label>
        </Wrapper>
    );
};

export default DetailedItemsWidgetDrillDownNotSelected;
