<div class="margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'ADD_PURCHASE_ORDER_LINE' || data.definition.customActionKey === 'ADD_REQUISITION_LINE'" class="margin-bottom-xlg">

        <div class="margin-bottom">
            <div ng-if="data.definition.purchaseOrderLines.length > 0" class="margin-right margin-top-xs margin-bottom">
                Purchase Order lines ({{ data.definition.purchaseOrderLines.length }}):
            </div>

            <div ng-repeat="(purchaseOrderLineIndex, purchaseOrderLine) in data.definition.purchaseOrderLines"
                 class="flex">
                <div class="margin-left-xs margin-right-xs margin-top">{{ purchaseOrderLineIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Line ID -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">Line ID:</div>

                            <div class="flex-grow"></div>

                            <button
                                    class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                    ng-click="removePurchaseOrderLine(purchaseOrderLineIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.purchaseOrderLines[purchaseOrderLineIndex].lineIdExpression"
                                placeholder="If empty, new line will be added."
                                on-tonkean-expression-changed="
                                onLineLineIdTonkeanExpressionChanged(expression, purchaseOrderLineIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Line Type -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Line Type:</div>
                        <ui-select
                            theme="bootstrap"
                            ng-model="data.definition.purchaseOrderLines[purchaseOrderLineIndex].lineType"
                            ng-change="onDefinitionChanged(true)"
                        >
                            <ui-select-match placeholder="Line Type">
                                {{ data.definition.purchaseOrderLines[purchaseOrderLineIndex].lineType }}
                            </ui-select-match>

                            <ui-select-choices
                                repeat="lineType in data.possiblePurchaseOrderLineTypes | filter:$select.search"
                            >
                                <div ng-bind-html="lineType | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <!-- Description -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Description:</div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.purchaseOrderLines[purchaseOrderLineIndex].descriptionExpression"
                                on-tonkean-expression-changed="
                                onLineDescriptionTonkeanExpressionChanged(expression, purchaseOrderLineIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Quantity -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Quantity:</div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.purchaseOrderLines[purchaseOrderLineIndex].quantityExpression"
                                on-tonkean-expression-changed="
                                onLineQuantityTonkeanExpressionChanged(expression, purchaseOrderLineIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Price -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Price:</div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.purchaseOrderLines[purchaseOrderLineIndex].priceExpression"
                                on-tonkean-expression-changed="
                                onLinePriceTonkeanExpressionChanged(expression, purchaseOrderLineIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Currency -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Currency:</div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.purchaseOrderLines[purchaseOrderLineIndex].currencyExpression"
                                on-tonkean-expression-changed="
                                onLineCurrencyTonkeanExpressionChanged(expression, purchaseOrderLineIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>
                </div>
            </div>

            <a ng-click="addPurchaseOrderLine()">+ Add Purchase Order Line</a>
            <div ng-if="data.validationObject.purchaseOrderLinesError" class="common-color-danger">
                {{ data.validationObject.purchaseOrderLinesError }}
            </div>
        </div>
    </div>
</div>
