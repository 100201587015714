<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<form novalidate>
    <div ng-form="data.integrationForm">
        <div class="modal-body">
            <tnk-generic-integration-connection-component
                ng-if="currentIntegration.name"
                integration-type="currentIntegration.name"
                integration="integration"
                on-integration-create="(onIntegrationCreate)"
                is-created-from-shared-credentials="projectIntegration.sharedCredentialId"
            ></tnk-generic-integration-connection-component>

            <div ng-show="integration">
                <h4>Which Asana-Workspaces are relevant to this team?</h4>
                <hr />
                <strong>Suggested Workspaces:</strong>

                <div class="margin-normal-v" ng-if="connecting || loading">
                    <div class="loading-placeholder margin-normal-sm-h margin-normal-v padding-normal-lg"></div>
                    <div class="loading-placeholder margin-normal-sm-h margin-normal-v padding-normal-lg"></div>
                </div>

                <div class="margin-normal-v">
                    <div ng-repeat="selection in selections" class="new-product-git-repo">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    ng-model="selection.selected"
                                    ng-required="(selections|filter:{selected:true}:true).length===0"
                                    ng-change="onSelectionChange(selection)"
                                />
                                <strong>{{ selection.org.displayName }}</strong>
                            </label>

                            <div class="common-subtitle-inner margin-normal-h">
                                <span>{{ selection.org.description }}</span>
                            </div>

                            <!-- Monitor Specific Projects-->
                            <div class="margin-left-lg">
                                <div class="flex-vmiddle">
                                    <tnk-radio-button
                                        radio-id="monitor-projects-all-{{ selection.org.value }}"
                                        radio-name="monitor-projects-mode-{{ selection.org.value }}"
                                        radio-label="Take all projects"
                                        radio-value="TAKE_ALL"
                                        model="selection.monitorProjectsMode"
                                        is-required="true"
                                        on-click="setMonitorProjectsMode(selection, onClickParam)"
                                        on-click-param="('TAKE_ALL')"
                                        classes="margin-top margin-right-md common-normal-weight"
                                    ></tnk-radio-button>

                                    <tnk-radio-button
                                        radio-id="monitor-projects-select-{{ selection.org.value }}"
                                        radio-name="monitor-projects-mode-{{ selection.org.value }}"
                                        radio-label="Filter by project"
                                        radio-value="FILTER"
                                        model="selection.monitorProjectsMode"
                                        is-required="true"
                                        on-click="setMonitorProjectsMode(selection, onClickParam)"
                                        on-click-param="('FILTER')"
                                        classes="margin-top margin-right-md common-normal-weight"
                                    ></tnk-radio-button>
                                </div>

                                <div class="margin-left-xs" ng-if="selection.monitorProjectsMode === 'TAKE_ALL'">
                                    <!-- Include private projects -->
                                    <div class="flex-vmiddle margin-normal-h">
                                        <input
                                            id="include-private-project-checkbox"
                                            type="checkbox"
                                            ng-model="selection.includePrivateProjects"
                                        />
                                        <label
                                            for="include-private-project-checkbox"
                                            class="flex-no-shrink common-size-xxs margin-top-6 padding-left-none"
                                        >
                                            Include private projects
                                        </label>
                                    </div>
                                </div>

                                <div
                                    ng-if="selection.monitorProjectsMode === 'FILTER'"
                                    class="padding-top-xs mod-width-250"
                                    ng-init="onchange=getOnProjectsChangeCallback(selection); loadoptions=getQueryProject(selection)"
                                >
                                    <!-- Dropdown -->
                                    <tnk-select
                                        value="selection.projects"
                                        is-multi="true"
                                        is-creatable="false"
                                        close-menu-on-select="false"
                                        default-menu-is-open="false"
                                        on-change="(onchange)"
                                        load-options="(loadoptions)"
                                        is-searchable="true"
                                        is-clearable="false"
                                        is-async="true"
                                        thin="true"
                                    ></tnk-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ng-if="otherOrganizations && otherOrganizations.length">
                    <strong>Add Other Asana-Workspace:</strong>

                    <div class="flex-vmiddle margin-normal-sm-v">
                        <div class="flex-grow margin-right">
                            <ui-select
                                theme="bootstrap"
                                ng-model="temp.org"
                                ng-disabled="validatingRepo || !otherOrganizations"
                            >
                                <ui-select-match placeholder="Add another Workspace">
                                    {{ $select.selected.displayName }}
                                </ui-select-match>
                                <ui-select-choices
                                    repeat="org in otherOrganizations | filter:$select.search"
                                    refresh="addCustomOrganization($select.search)"
                                    refresh-delay="0"
                                >
                                    <div ng-bind-html="org.displayName | escapeHtml | highlight: $select.search"></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>
                        <button
                            type="button"
                            ng-click="onOrganizationSelected(temp.org)"
                            ng-disabled="validatingOrganization"
                            class="btn btn-secondary"
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div
                class="common-color-danger pull-left"
                ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid"
            >
                You should select at least one Asana-Workspace
            </div>
            <button
                class="btn btn-default"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="onCancel()"
            >
                Cancel
            </button>
            <button
                class="btn btn-primary"
                ng-disabled="(selections|filter:{loadingBranches:true}:true).length || !data.integrationForm.$valid"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="ok()"
            >
                OK
            </button>
        </div>
    </div>
</form>
