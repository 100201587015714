import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { InformationTooltip, LoadingCircle, Toggle } from '@tonkean/infrastructure';
import type { ProjectIntegrationCollectToggles } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { InputSize, InformationIconSize } from '@tonkean/tui-theme/sizes';
import { getStateError } from '@tonkean/utils';

const ConfigurationTogglesContainer = styled.div`
    margin-top: 40px;
`;

const ToggleContainer = styled.div`
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    margin-bottom: 8px;
    display: flex;
`;

const ToggleWithHeight = styled(Toggle)`
    // It's the height of the loading circle. To prevent a jump when it's shown, we will make the height of the toggle
    // fixed with the height of the loading circle.
    min-height: 15px;
`;

const ErrorContainer = styled.div`
    margin-left: 10px;
`;

const SmartSearchEnabledLabelContainer = styled.div`
    display: flex;
    gap: 5px;
`;

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    displayWebhooksControl: boolean;
    displayContinuousCollectControl: boolean;
    displaySystemAIAccessEnabledControl: boolean | undefined;
}

const ProjectIntegrationTogglesConfigurationComponent: React.FC<Props> = ({
    projectId,
    projectIntegrationId,
    displayWebhooksControl,
    displayContinuousCollectControl,
    displaySystemAIAccessEnabledControl,
}) => {
    const [toggles, setToggles] = useState<ProjectIntegrationCollectToggles>();

    const [
        { loading: loadingToggles, error: errorLoadingToggles, data: currentToggles },
        getProjectIntegrationCollectToggles,
    ] = useLazyTonkeanService('getProjectIntegrationCollectToggles');

    useEffect(() => {
        getProjectIntegrationCollectToggles(projectIntegrationId);
    }, [projectIntegrationId, getProjectIntegrationCollectToggles]);

    useEffect(() => {
        if (!loadingToggles && !errorLoadingToggles && currentToggles) {
            setToggles(currentToggles);
        }
    }, [currentToggles, errorLoadingToggles, loadingToggles]);

    const [
        { loading: loadingShouldHandleWebhooksUpdate, error: errorInShouldHandleWebhooksUpdate },
        updateProjectIntegrationShouldHandleWebhooks,
    ] = useLazyTonkeanService('updateProjectIntegrationShouldHandleWebhooks');

    const [
        { loading: loadingShouldPerformContinuousCollect, error: errorInShouldPerformContinuousCollect },
        updateProjectIntegrationShouldPerformContinuousCollect,
    ] = useLazyTonkeanService('updateProjectIntegrationShouldPerformContinuousCollect');

    const [
        { loading: loadingSystemAIAccessEnabled, error: errorInSystemAIAccessEnabled },
        updateProjectIntegrationSystemAIAccessEnabled,
    ] = useLazyTonkeanService('updateProjectIntegrationSystemAIAccessEnabled');

    return (
        <ConfigurationTogglesContainer>
            {displayWebhooksControl && (
                <ToggleContainer>
                    <ToggleWithHeight
                        size={InputSize.SMALL}
                        onChange={() => {
                            if (toggles) {
                                const newValue = !toggles.shouldHandleWebhooks;
                                setToggles({
                                    ...toggles,
                                    shouldHandleWebhooks: newValue,
                                });
                                updateProjectIntegrationShouldHandleWebhooks(projectIntegrationId, newValue);
                            }
                        }}
                        disabled={!toggles || loadingShouldHandleWebhooksUpdate}
                        checked={toggles?.shouldHandleWebhooks}
                        labelBefore="Enable webhooks real-time updates"
                        labelAfter={loadingToggles || loadingShouldHandleWebhooksUpdate ? <LoadingCircle /> : undefined}
                    />
                    {errorInShouldHandleWebhooksUpdate && (
                        <ErrorContainer className="common-error flex-grow">
                            {getStateError(errorInShouldHandleWebhooksUpdate)}
                        </ErrorContainer>
                    )}
                </ToggleContainer>
            )}

            {displayContinuousCollectControl && (
                <ToggleContainer>
                    <ToggleWithHeight
                        size={InputSize.SMALL}
                        onChange={() => {
                            if (toggles) {
                                const newValue = !toggles.shouldPerformContinuousCollect;
                                setToggles({
                                    ...toggles,
                                    shouldPerformContinuousCollect: newValue,
                                });
                                updateProjectIntegrationShouldPerformContinuousCollect(projectIntegrationId, newValue);
                            }
                        }}
                        checked={toggles?.shouldPerformContinuousCollect}
                        disabled={!toggles || loadingShouldPerformContinuousCollect}
                        labelBefore="Enable continuous collect"
                        labelAfter={
                            loadingToggles || loadingShouldPerformContinuousCollect ? <LoadingCircle /> : undefined
                        }
                    />
                    {errorInShouldPerformContinuousCollect && (
                        <ErrorContainer className="common-error flex-grow">
                            {getStateError(errorInShouldPerformContinuousCollect)}
                        </ErrorContainer>
                    )}
                </ToggleContainer>
            )}

            {displaySystemAIAccessEnabledControl && (
                <ToggleContainer>
                    <ToggleWithHeight
                        size={InputSize.SMALL}
                        onChange={() => {
                            if (toggles) {
                                const newValue = !toggles.systemAIAccessEnabled;
                                setToggles({
                                    ...toggles,
                                    systemAIAccessEnabled: newValue,
                                });
                                updateProjectIntegrationSystemAIAccessEnabled(
                                    projectId,
                                    projectIntegrationId,
                                    newValue,
                                );
                            }
                        }}
                        checked={toggles?.systemAIAccessEnabled}
                        disabled={!toggles || loadingSystemAIAccessEnabled}
                        labelBefore={
                            <SmartSearchEnabledLabelContainer>
                                Use for system AI access
                                <InformationTooltip iconSize={InformationIconSize.SMALL}>
                                    The API key for this connection will be used for system features that leverage AI,
                                    such as the AI front door
                                </InformationTooltip>
                            </SmartSearchEnabledLabelContainer>
                        }
                        labelAfter={loadingToggles || loadingSystemAIAccessEnabled ? <LoadingCircle /> : undefined}
                    />
                    {errorInSystemAIAccessEnabled && (
                        <ErrorContainer className="common-error flex-grow">
                            {getStateError(errorInSystemAIAccessEnabled)}
                        </ErrorContainer>
                    )}
                </ToggleContainer>
            )}
        </ConfigurationTogglesContainer>
    );
};

export default ProjectIntegrationTogglesConfigurationComponent;
