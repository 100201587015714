<div class="project-integration-selector">
    <!-- Storage provider -->
    <tnk-select
        value="data.projectIntegrationIdSelectedOption"
        on-change="(onProjectIntegrationSelected)"
        is-searchable="true"
        options="data.availableProjectIntegrationsOptions"
        thin="true"
        placeholder="data.selectorLabelPlaceholder ? data.selectorLabelPlaceholder : 'Select'"
        is-disabled="data.disabled"
        is-loading="data.isLoading"
    ></tnk-select>
</div>
