import * as React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from '../../../images/icons/arrow-right.svg';
import { ReactComponent as TonkeanLogoIcon } from '../../../images/tonkean-lower-case-t-logo.svg';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useGetUpgradeLink } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 70px;
    background-color: ${Theme.colors.basicBackground};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

const TonkeanLogo = styled(TonkeanLogoIcon)`
    height: 48px;
`;

const Title = styled.div`
    font-family: Inter;
    font-size: ${FontSize.XXXXXXXLARGE_36};
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 30px;
    color: ${Theme.colors.gray_blue};
`;

const UpdateButton = styled(Clickable)`
    margin-top: 22px;
    background-color: ${Theme.colors.purple};
    border-radius: 8px;
    padding: 12px 20px;
    font-weight: 600;
    font-size: ${FontSize.LARGE_16};
    color: ${Theme.colors.white};
    &:hover {
        color: ${Theme.colors.white};
    }
`;

const ContactSalesButton = styled(Clickable)`
    padding: 12px 20px;
    border: 1px solid ${Theme.colors.gray_300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: ${Theme.colors.gray_700};
    margin-top: 13px;
    font-weight: 600;
`;

const UpdateButtonTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const StyledArrowIcon = styled(ArrowIcon)`
    margin-left: 10px;
    path {
        stroke: ${Theme.colors.white};
        stroke-width: 1px;
    }
`;

const Or = styled.div`
    margin-top: 22px;
    color: ${Theme.colors.gray_blue};
`;

interface Props {}

const ProductExpiredContent: React.FC<Props> = ({}) => {
    const url = useGetUpgradeLink();
    const project = useProject();
    const mtuPricing = useFeatureFlag('tonkean_feature_mtu_pricing') as boolean;
    const hasMtuConfiguration = !!project.mtuLicenseConfiguration && mtuPricing;
    return (
        <Wrapper>
            <TonkeanLogo />
            <Title>Your free trial has ended</Title>
            {hasMtuConfiguration && url && (
                <>
                    <UpdateButton href={url}>
                        <UpdateButtonTextWrapper>
                            Upgrade your plan
                            <StyledArrowIcon />
                        </UpdateButtonTextWrapper>
                    </UpdateButton>
                    <Or>or</Or>
                </>
            )}
            <ContactSalesButton onClick={() => Intercom('showNewMessage', 'Hi, I would like to upgrade my account')}>
                Contact sales
            </ContactSalesButton>{' '}
        </Wrapper>
    );
};

export default ProductExpiredContent;
