import { analyticsWrapper } from '@tonkean/analytics';

function QuickSetupCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $state,
    tonkeanService,
    groupInfoManager,
) {
    $scope.pm = $rootScope.pm;
    $scope.as = $rootScope.as;

    $scope.step = $stateParams.step;
    $scope.initialLoading = true;

    $scope.pm.getProjects().then(function () {
        if (!$scope.pm.projects || !$scope.pm.projects.length) {
            $state.go('loginCreate', null, { location: 'replace' });
            return;
        }

        const pid = $scope.pm.project ? $scope.pm.project.id : $scope.pm.projects[0].id;
        $scope.pm.selectProject(pid).then(function () {
            groupInfoManager.getGroups().then(function () {
                $scope.initialLoading = false;
            });
        });
    });

    $scope.data = {
        usecases: {
            CUSTOMER_SUCCESS: { btn: 'Customers / Accounts', caption: 'Top Customers' },
            PRODUCT_ECOSYSTEM: { btn: 'Product Milestones', caption: 'Milestones' },
            OTHER: { btn: 'Other', caption: 'Top Initiatives', noExamples: true },
        },
        // selectedUsecase: selectedUsecase || null
    };

    $scope.init = function () {
        if (!$scope.$root.selectedUsecase) {
            $state.go('quickSetup', { step: null }, { replace: true });
        }
    };

    $scope.changeStep = function (step) {
        $scope.step = step;
        $state.go('quickSetup', { step });
    };

    $scope.selectUsecase = function (id) {
        $scope.$root.selectedUsecase = id;
        $scope.$root.firstListName = $scope.data.usecases[id].caption;
        // $scope.changeStep('select');

        $scope.creating = true;

        // update group name
        tonkeanService
            .updateGroupName(
                $scope.pm.groupsMap[$scope.pm.groupDefaultId],
                $scope.data.usecases[$rootScope.selectedUsecase].caption,
            )
            .then(function () {
                // update group metadata
                setGroupMetadata().then(function () {
                    $scope.close(true);
                });
            });
    };

    $scope.close = function (finish) {
        analyticsWrapper.track(finish ? 'onboarding completed' : 'onboarding dismissed', {
            category: 'Onboarding',
            label: `Welcome modal ${$scope.data.tab}`,
        });

        if (!$rootScope.as.currentUser.metadata || !$rootScope.as.currentUser.metadata.onboardingWatched) {
            if (!$rootScope.as.currentUser.metadata) {
                $rootScope.as.currentUser.metadata = {};
            }
            $rootScope.as.currentUser.metadata.onboardingWatched = true;
            tonkeanService.updateProfileMetadata($rootScope.as.currentUser.metadata);
        }

        $state.go('product');
    };

    $scope.createSampleTracks = function () {
        if (!$scope.creating) {
            $scope.creating = true;
            $rootScope.hideInitialQuestion = true;

            if ($rootScope.selectedUsecase === 'OTHER') {
                $scope.close(true);
            } else {
                // update group name
                tonkeanService.updateGroupName(
                    $scope.pm.groupsMap[$scope.pm.groupDefaultId],
                    $scope.data.usecases[$rootScope.selectedUsecase].caption,
                );

                // update group metadata
                setGroupMetadata();

                tonkeanService
                    .createSampleData($scope.pm.project.id, [$rootScope.selectedUsecase])
                    .then(function () {
                        $rootScope.$broadcast('newActivityUpdate');
                    })
                    .finally(function () {
                        $scope.close(true);
                        $scope.creating = false;
                    });
            }
        }
    };

    $scope.onImportCompleteCallback = function () {
        // update group metadata
        setGroupMetadata();

        $scope.close(true);
    };

    function setGroupMetadata() {
        const metadata = $scope.pm.groupsMap[$scope.pm.groupDefaultId].metadata || {};
        metadata.usecase = $rootScope.selectedUsecase;
        return tonkeanService.updateGroupMetadata($scope.pm.groupDefaultId, metadata);
    }

    $scope.init();
}
export default angular.module('tonkean.app').controller('QuickSetupCtrl', QuickSetupCtrl);
