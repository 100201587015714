import React, { useRef } from 'react';
import styled from 'styled-components';

import ManageMakersModalContent from './ManageMakersModalContent';

import { Modal } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';

const ManageMakersModal = styled(Modal)`
    width: 550px;
`;

interface Props {
    workflowFolder: WorkflowFolder;
    open: boolean;
    onClose(): void;
}

const OpenManageMakersModal: React.FC<Props> = ({ workflowFolder, open, onClose }) => {
    const initiallyFocusedRef = useRef<HTMLButtonElement>(null);

    return (
        <ManageMakersModal
            open={open}
            onClose={onClose}
            fullScreen={false}
            scrollable={false}
            initiallyFocusedElementRef={initiallyFocusedRef}
            hasBackdrop
        >
            <ManageMakersModalContent workflowFolder={workflowFolder} initiallyFocusedRef={initiallyFocusedRef} />
        </ManageMakersModal>
    );
};

export default OpenManageMakersModal;
