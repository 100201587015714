<div uib-dropdown class="select-integration-instance-react" dropdown-append-to-body click-outside="closeDropdown()" is-open="data.dropdownIsOpen">
    <div uib-dropdown-toggle>
        <ng-transclude></ng-transclude>
    </div>

    <ul uib-dropdown-menu class="dropdown-menu-right">
        <!-- Integration Selection Option  -->
        <li
            ng-if="projectIntegrationOptions.length"
            ng-repeat="integrationOption in projectIntegrationOptions"
            ng-click="selectIntegration(integrationOption)"
            data-automation="integration-option" 
            data-automation-label="{{ integrationOption.displayName }}"
        >
            <a
                class="pointer integration-option"
            >
                <tnk-integration-group
                    class="pointer-events-none"
                    sources="[integrationType.toLowerCase()]"
                    ng-model="ignored"
                    display-mode="simple-row"
                    button-postfix="{{ ' (' + integrationOption.displayName + ')' }}"
                    current-integration="integrationOption"
                    open-modal-for-configuration="true"
                    display-new-prefix="false"
                    selected-project-Integration="selectedProjectIntegration"
                    icon-url-override="integrationOption.iconUrl"
                ></tnk-integration-group>
            </a>
        </li>

        <li class="divider" ng-if="projectIntegrationOptions.length"></li>

        <!-- New Integration -->
        <li>
            <a class="integration-option pointer">
                <tnk-integration-group
                    sources="[integrationType.toLowerCase()]"
                    ng-model="ignored"
                    display-mode="simple-row"
                    state="data.fakeIntegrationState"
                    button-prefix="{{ 'New ' }}"
                    button-postfix="{{ ' Data Source' }}"
                    current-integration="integrationOption"
                    open-modal-for-configuration="true"
                    integration-changed="selectIntegration(changedProjectIntegration.projectIntegration)"
                    display-new-prefix="false"
                ></tnk-integration-group>
            </a>
        </li>
    </ul>
</div>
