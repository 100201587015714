import type { FieldFilter, FieldQuery } from '@tonkean/tonkean-entities';

/**
 * Recursively getting all the filters participating in a tonkean custom query.
 */
export function getTonkeanQueryParticipatingFilters(query: FieldQuery) {
    let allParticipatingFilters: FieldFilter[] = [];

    if (query?.filters?.length) {
        for (let i = 0; i < query.filters.length; i++) {
            const filter = query.filters[i];

            if (filter) {
                allParticipatingFilters.push(filter);
            }
        }
    }

    if (query?.queries?.length) {
        for (let i = 0; i < query.queries.length; i++) {
            allParticipatingFilters = allParticipatingFilters.concat(
                getTonkeanQueryParticipatingFilters(query.queries[i]!),
            );
        }
    }

    return allParticipatingFilters;
}
