import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ClassificationIcon } from '../../../../../../images/icons/classification.svg';
import { ReactComponent as TextExtractionIcon } from '../../../../../../images/icons/text-extraction.svg';

import { ActiveIndicator, Tooltip } from '@tonkean/infrastructure';
import {
    PersonPermissionRoleType,
    ProjectIntegrationPageMenuItemType,
    TrainingSetType,
} from '@tonkean/tonkean-entities';
import type { SingleTrainingSetInGallery } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const Title = styled.div`
    display: flex;
    align-items: center;
`;

const IconWrapper = styled.div`
    margin-right: 10px;
`;

const StyledClassificationIcon = styled(ClassificationIcon)`
    margin-top: 5px;
`;

const Text = styled.span`
    color: ${Theme.colors.gray_700};
`;

const ManageLink = styled(StateLink)`
    margin-right: 8px;
`;

const LinksContainer = styled.div`
    display: flex;
`;

function getTrainingSetAsTableRow(trainingSet: SingleTrainingSetInGallery, showOverviewPageFeatureFlag: boolean) {
    const usedBy = trainingSet.allowedSolutions.map((solution) => solution.displayName);
    const lastUpdated = trainingSet.updated || trainingSet.created;

    return {
        name: (
            <Title>
                <IconWrapper>
                    {trainingSet.trainingSetType === TrainingSetType.TEXT_EXTRACTOR ? (
                        <TextExtractionIcon />
                    ) : (
                        <StyledClassificationIcon />
                    )}
                </IconWrapper>
                <Text>{trainingSet.displayName}</Text>
            </Title>
        ),
        updated: <Text>{utils.formatDate(lastUpdated, true, true, true)}</Text>,
        solutions: (
            <Tooltip content={usedBy.join(', ')} disabled={usedBy.length === 0}>
                <Text>
                    {usedBy.length === 0 ? (
                        <>None</>
                    ) : (
                        <>
                            {usedBy.length} Solution
                            {usedBy.length > 1 ? 's' : ''}
                        </>
                    )}
                </Text>
            </Tooltip>
        ),
        status: <ActiveIndicator isActive={trainingSet.isActive} onText="Active" offText="Inactive" />,
        access: (
            <LinksContainer>
                {trainingSet.accessType === PersonPermissionRoleType.MAKER ? (
                    <StateLink
                        state="product.trainingSetPage"
                        params={{
                            page: ProjectIntegrationPageMenuItemType.INFORMATION,
                            enterpriseComponentId: trainingSet.trainingSetId,
                        }}
                    >
                        View
                    </StateLink>
                ) : (
                    <>
                        <ManageLink
                            state="product.trainingSetPage"
                            params={{
                                page: showOverviewPageFeatureFlag
                                    ? ProjectIntegrationPageMenuItemType.OVERVIEW
                                    : ProjectIntegrationPageMenuItemType.TRAINING_DATA,
                                enterpriseComponentId: trainingSet.trainingSetId,
                            }}
                        >
                            Manage
                        </ManageLink>
                        <StateLink
                            state="product.trainingSetPage"
                            params={{
                                page: ProjectIntegrationPageMenuItemType.TRAINING_DATA,
                                enterpriseComponentId: trainingSet.trainingSetId,
                            }}
                        >
                            Train
                        </StateLink>
                    </>
                )}
            </LinksContainer>
        ),
        id: trainingSet.trainingSetId,
    };
}

export default getTrainingSetAsTableRow;
