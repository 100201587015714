import hubspotImage from '../../../../apps/tracks/images/hubspot.png';
import hubspotCircleImage from '../../../../apps/tracks/images/integrations/hubspot-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

export default class HubspotIntegration extends IntegrationDefinitionBase {
    override name = 'hubspot';
    override displayName = 'Hubspot';
    override description = 'We will collect companies, contacts, deals, files and folders';
    override authenticationType = AuthenticationMethod.OAUTH;
    override externalActivityCreatedDateFieldName = 'createdate';
    override storageProviderSettings = new StorageProviderSettings(true, false);
    override showEntitiesInboundConfiguration = false;
    override entities = [
        new IntegrationEntity('Company', 'Companies'),
        new IntegrationEntity('Contact', 'Contacts'),
        new IntegrationEntity('Deal', 'Deals'),
        new IntegrationEntity('Engagement', 'Engagements'),
        new IntegrationEntity('File', 'Files'),
        new IntegrationEntity('Folder', 'Folders'),
        new IntegrationEntity('Form', 'Forms'),
        new IntegrationEntity('FormSubmission', 'Form Submissions'),
        new IntegrationEntity('FormSubmissionAnswer', 'Form Submission Answers'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(hubspotCircleImage, '130px'),
        new IntegrationIcon(hubspotImage, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: {
            Company: true,
            Deal: true,
            Engagement: true,
            FormSubmission: true,
            FormSubmissionAnswer: true,
            Folder: true,
            File: true,
        },
        isOnlyDataRetention: {},
    };

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const scopePermissions =
            'crm.objects.companies.read crm.objects.deals.read crm.objects.companies.write crm.objects.contacts.write crm.objects.deals.write crm.objects.contacts.read crm.objects.owners.read crm.schemas.companies.read crm.schemas.contacts.read crm.schemas.deals.read oauth files forms forms-uploaded-files files.ui_hidden.read';
        const clientId = environment.integrationKeysMap.hubspot;
        const uri = environment.integrationKeysMap.hubspotUri;
        const redirectUriState = oAuthHandler.publicGetState();
        const redirectUri = `${uri}?state=${redirectUriState}`;
        const url = `https://app.hubspot.com/oauth/authorize?client_id=${clientId}&scope=${scopePermissions}&redirect_uri=${encodeURIComponent(
            redirectUri,
        )}`;

        const code = await oAuthHandler.startOAuth(url, redirectUriState);
        return { code, redirectUri };
    }
}
