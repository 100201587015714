<div class="modal-header">
    <!--<h3 class="modal-title">{{currentIntegration.displayName}}</h3>-->
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <form
            class="form-horizontal"
            ng-submit="authorize()"
            novalidate
    >
        <div ng-form="data.integrationForm">
            <div class="modal-body">
                <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>

                <div ng-hide="connecting || integration">

                    <!-- Custom App connect-->
                    <div ng-if="data.customApp">
                        <div class="form-group">
                            <label class="col-sm-3 control-label" for="facebookads-app-id">App ID</label>

                            <div class="col-sm-8">
                                <input
                                        autocomplete="off"
                                        class="form-control"
                                        id="facebookads-app-id"
                                        ng-model="data.appId"
                                        placeholder="App ID"
                                        required
                                        type="text"
                                />
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="col-sm-3 control-label" for="facebookads-app-secret">App Secret</label>

                            <div class="col-sm-8">
                                <input
                                        autocomplete="off"
                                        class="form-control"
                                        id="facebookads-app-secret"
                                        ng-model="data.appSecret"
                                        placeholder="App Secret"
                                        required
                                        type="password"
                                />
                            </div>
                        </div>

                        <!-- Setup instructions -->
                        <div class="margin-bottom-lg common-size-xxxs margin-top-lg info-box">
                            <div class="common-bold">Setup instructions:</div>
                            <div class="common-color-light-grey">
                                <ol>
                                    <li class="margin-bottom-xs margin-top-xs">
                                        Navigate to <a href="https://developers.facebook.com/apps/" target="_blank">https://developers.facebook.com/apps/</a>
                                        and click on "Create App".
                                    </li>
                                    <li class="margin-bottom-xs">Select "Business" and click on "Next".</li>
                                    <li class="margin-bottom-xs">Enter a name, select the relevant business account and
                                        click on "Create App".
                                    </li>
                                    <li class="margin-bottom-xs">
                                        In Settings -> Basic:
                                        <ul>
                                            <li>Add 'https://tracks.tonkean.com/' as the App Domain.</li>
                                            <li>Add 'https://tonkean.com/privacy/' the Privacy policy URL.</li>
                                            <li>Select 'Yourself' as the app's purpose and click "Save"</li>
                                        </ul>
                                    </li>
                                    <li class="margin-bottom-xs">
                                        In Settings -> Advanced:
                                        <ul>
                                            <li>Add your account id to the Advertising Accounts field.</li>
                                        </ul>
                                    </li>
                                    <li class="margin-bottom-xs">
                                        In App Review -> Permissions and Features:
                                        <ul>
                                            <li>Click on "Request Advanced" on the "public_profile" permission and
                                                complete the process.
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="margin-bottom-xs">
                                        Add the next Products
                                        <ul>
                                            <li>
                                                Facebook Login
                                                <ul>
                                                    <li>
                                                        Under Settings -> 'Valid OAuth Redirect URIs', add the next URL:<br/>
                                                        {{ data.redirectUri }}
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Webhooks (Optional)</li>
                                            <li>Marketing API</li>
                                        </ul>
                                    </li>
                                    <li class="margin-bottom-xs">Grab the App ID & App Secret from the Settings -> Basic
                                        page.
                                    </li>
                                </ol>
                                <div>For more information please contact our support team.</div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <button
                                type="button"
                                class="btn btn-secondary btn-lg"
                                analytics-on
                                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                                analytics-event="Authenticate"
                                analytics-label="{{ currentIntegration.name }}"
                                ng-click="authorize()"
                        >
                            Authenticate with {{ currentIntegration.displayName }}
                        </button>
                        <div class="margin-normal-sm-v common-subtitle-inner"></div>
                    </div>

                    <div class="form-group margin-top text-center">
                        <a class="common-size-xxs" ng-click="data.customApp = !data.customApp">
                            Or connect with {{ data.customApp ? 'Tonkean' : 'Custom App' }}
                        </a>
                    </div>
                </div>


                <div class="text-center" ng-show="connecting">
                    <h4>
                        <span class="loading"></span>
                        Connecting to {{ currentIntegration.displayName }}
                    </h4>
                </div>
                <div ng-show="integration && !connecting">
                    <div class="alert alert-info">
                        <a
                                class="pull-right"
                                href="#"
                                analytics-on="click"
                                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                                analytics-event="Change Auth"
                                analytics-label="{{ currentIntegration.name }}"
                                ng-click="changeUser()"
                        >
                            Change User
                        </a>

                        <div class="integration-group-header-connected" ng-if="integration">
                            Authenticated
                            <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                            as
                            <span class="integration-group-header-connected-username">
                                {{ integration.integrationUserDisplayName || integration.integrationUser }}
                            </span>
                        </span>
                        </div>
                    </div>
                    <h4>Which Facebook Accounts are relevant to this team?</h4>
                    <hr/>
                    <strong>Suggested Accounts:</strong>

                    <div class="margin-normal-v">
                        <div ng-repeat="selection in selections" class="new-product-git-repo">
                            <div class="checkbox">
                                <label>
                                    <input
                                            type="checkbox"
                                            ng-model="selection.selected"
                                            ng-required="(selections|filter:{selected:true}:true).length===0"
                                            ng-change="onSelectionChange(selection)"
                                    />
                                    <strong>{{ selection.account.displayName }}</strong>
                                </label>

                                <div class="common-subtitle-inner margin-normal-h">
                                    <span>{{ selection.account.description }}</span>
                                    <span ng-hide="selection.showEdit || !selection.selected">
                                    <span ng-show="selection.account.description">&nbsp;|&nbsp;</span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ng-if="otherAccounts && otherAccounts.length">
                        <strong>Add Other Facebook Accounts:</strong>

                        <div class="flex-vmiddle margin-normal-sm-v">
                            <div class="flex-grow margin-right">
                                <ui-select theme="bootstrap" ng-model="temp.account" ng-disabled="!otherAccounts">
                                    <ui-select-match placeholder="Add another Account">
                                        {{ $select.selected.displayName }}
                                    </ui-select-match>
                                    <ui-select-choices
                                        repeat="account in otherAccounts | filter:$select.search"
                                        refresh-delay="0"
                                    >
                                        <div
                                            ng-bind-html="org.displayName | escapeHtml | highlight: $select.search"
                                        ></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                            <button type="button" ng-click="onAccountSelected(temp.org)" class="btn btn-secondary">
                                Add
                            </button>
                        </div>
                    </div>

                    <hr/>
                    <div>
                        <div class="flex-vmiddle mod-justify-center">
                            <div class="common-title-xxs margin-bottom">Facebook Ads Webhook Endpoint</div>
                        </div>

                        <!-- Creating -->
                        <div class="flex-vmiddle mod-justify-center" ng-if="data.creatingFacebookAdsIncomingWebhook">
                            <span class="loading-small margin-right-xs"></span>
                            <span>Creating existing Facebook Ads webhook endpoint...</span>
                        </div>

                        <!-- Error -->
                        <div
                                class="flex-vmiddle mod-justify-center common-color-danger common-size-xxxxs"
                                ng-if="data.errorCreatingFacebookAdsIncomingWebhook"
                        >
                            There was an error trying to load existing Facebook Ads webhook endpoint.
                        </div>

                        <!-- Create endpoint button -->
                        <div
                                class="flex-vmiddle mod-justify-center"
                                ng-if="
                    !data.incomingFacebookAdsWebhookUrl &&
                    !data.creatingFacebookAdsIncomingWebhook &&
                    !data.errorCreatingFacebookAdsIncomingWebhook
                "
                        >
                            <button
                                    class="btn btn-primary"
                                    ng-click="createFacebookAdsIncomingWebhook()"
                                    ng-disabled="!data.projectIntegration"
                                    type="button"
                            >
                                Create Facebook Ads Webhook Endpoint
                            </button>
                        </div>

                        <!-- Endpoint URL -->
                        <div class="flex-vmiddle mod-justify-center" ng-if="data.incomingFacebookAdsWebhookUrl">
                            {{ data.incomingFacebookAdsWebhookUrl }}
                        </div>

                        <!-- Supported webhooks -->
                        <div class="common-size-xxxs margin-top-md info-box">
                            <div class="common-color-dark-grey common-bold">Currently supported webhooks:</div>
                            <div class="common-color-light-grey">LeadGen.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div
                        class="common-color-danger pull-left"
                        ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid"
                >
                    You should select at least one Facebook Account
                </div>
                <button
                        class="btn btn-default"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Cancel Integration"
                        analytics-label="{{ currentIntegration.name }}"
                        analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                        type="button"
                        ng-click="onCancel()"
                >
                    Cancel
                </button>
                <button
                        class="btn btn-primary"
                        ng-disabled="!data.integrationForm.$valid || !selections || !selections.length"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Save Integration"
                        analytics-label="{{ currentIntegration.name }}"
                        analytics-value="{{ (selections | filter: { selected: true }:true).length }}"
                        type="button"
                        ng-click="ok()"
                >
                    OK
                </button>
            </div>
        </div>
    </form>
</div>
