import React from 'react';

/** edge endings listed here, for arrow shape use 'arrow-ending' in the edge css, for line ending use 'line-ending**/
const ProcessMapperEdgeArrow: React.FC = () => (
    <>
        <marker id="arrow-ending" viewBox="0 0 11 16" refX="2" refY="8" markerWidth="6" markerHeight="8" orient="auto">
            <path
                d="M9.77522 8.72109C10.1664 8.33119 10.1674 7.69802 9.77747 7.30688L3.42366 0.932784C3.03375 0.541638 2.40059 0.540629 2.00944 0.930531C1.6183 1.32043 1.61729 1.9536 2.00719 2.34474L7.65503 8.0106L1.98917 13.6584C1.59802 14.0483 1.59701 14.6815 1.98691 15.0727C2.37681 15.4638 3.00998 15.4648 3.40112 15.0749L9.77522 8.72109ZM0.998407 9L9.06764 9.01286L9.07083 7.01286L1.00159 7L0.998407 9Z"
                fill="#979797"
            />
        </marker>
        <marker
            id="line-ending"
            viewBox="0 0 3.7 1"
            refX="0"
            refY="0.5"
            markerWidth="3.7"
            markerHeight="1"
            orient="auto"
        >
            <path d="M1 0L0 150" stroke="#979797" stroke-width="150" />
        </marker>
        <marker
            id="arrow-ending-selected"
            viewBox="0 0 11 16"
            refX="2"
            refY="8"
            markerWidth="6"
            markerHeight="8"
            orient="auto"
        >
            <path
                d="M9.77522 8.72109C10.1664 8.33119 10.1674 7.69802 9.77747 7.30688L3.42366 0.932784C3.03375 0.541638 2.40059 0.540629 2.00944 0.930531C1.6183 1.32043 1.61729 1.9536 2.00719 2.34474L7.65503 8.0106L1.98917 13.6584C1.59802 14.0483 1.59701 14.6815 1.98691 15.0727C2.37681 15.4638 3.00998 15.4648 3.40112 15.0749L9.77522 8.72109ZM0.998407 9L9.06764 9.01286L9.07083 7.01286L1.00159 7L0.998407 9Z"
                fill="#1e90ff"
            />
        </marker>
        <marker
            id="line-ending-selected"
            viewBox="0 0 3.7 1"
            refX="0"
            refY="0.5"
            markerWidth="3.7"
            markerHeight="1"
            orient="auto"
        >
            <path d="M1 0L0 150" stroke="#1e90ff" stroke-width="150" />
        </marker>
    </>
);

export default ProcessMapperEdgeArrow;
