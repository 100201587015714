import type { ValidationError } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

export interface JoinedValidationError {
    message: string;
    errors: ValidationError[];
    sources: formulaTreeNode[];
}

/**
 * Creates a list of distinct errors by joining errors with the same error message to a single error
 *
 * @param errors - list of validation errors
 * @returns list of joined validation errors
 */
function joinErrors(errors: ValidationError[]) {
    const errorMessageToValidationErrors = utils.groupBy(
        errors,
        (validationError: ValidationError) => validationError.error,
    );

    return Object.entries(errorMessageToValidationErrors).map(([message, errors]) => {
        const sources = errors?.map((validationError) => validationError.validationSource).filter(Boolean) || [];

        return {
            message,
            errors,
            sources,
        } as JoinedValidationError;
    });
}

/**
 * Triage the validations messages into warnings or errors lists.
 *
 * @param errors - pre triage validation messages
 * @returns Triaged validations messages
 */
function triageValidations(errors: ValidationError[]): Record<'errors' | 'warnings', JoinedValidationError[]> {
    return {
        errors: joinErrors(errors.filter((error) => !error.isWarning)),
        warnings: joinErrors(errors.filter((error) => error.isWarning)),
    };
}

export default triageValidations;
