import { SortOrderType } from '@tonkean/tonkean-entities';
import { range } from '@tonkean/utils';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';

function AddScheduledReportCtrl(
    $scope,
    $rootScope,
    $uibModalInstance,
    $timeout,
    $state,
    $q,
    inviteManager,
    consts,
    utils,
    tonkeanService,
    projectManager,
    createMode,
    editedReport,
    onClosedCallback,
    createOrUpdateCallback,
    groupId,
    workflowVersionManager,
    customFieldsManager,
) {
    $scope.pm = projectManager;
    $scope.as = $rootScope.as;
    $scope.createMode = createMode;
    $scope.editedReport = editedReport;
    $scope.groupId = groupId;
    $scope.wvm = workflowVersionManager;

    // 1-12 including
    const allMonthsInYear = range(1, 13);

    const defaultOrderByOption = {
        id: 'default',
        label: 'Default (by index)',
        fieldType: 'String',
    };
    const specialFieldsOrderByOptions = getSpecialFieldsForFeatures(false, ['BASIC_FIELDS'])
        .map((field) => ({
            id: field.id,
            label: field.name,
            fieldType: field.fieldType,
        }));

    /**
     * Modal initialization function.
     */
    $scope.init = function () {
        $scope.data = {
            // Report Name
            reportName: null,
            userChangedReportName: false,

            // Report Types
            reportTypeOptions: consts.getReportTypesSettings(),
            selectedReportType: null,
            selectedReportTypePair: null,
            selectedReportTypeConfig: null,
            selectedReportTypeFamily: null,
            includeFirstLevelOfInnerTracks: false,

            // Custom filters control
            customReportControl: {
                existingDefinition: {
                    query: {
                        type: 'And',
                        filters: [
                            {
                                fieldName: 'groupId',
                                fieldLabel: 'List',
                                type: 'List',
                                fromOriginalEntity: false,
                                values: projectManager.groups.map((group) => group.id),
                                possibleValuesMap: utils.createMapFromArray(projectManager.groups, 'id', null, 'name'),
                            },
                        ],
                    },
                },
            },

            // Due/ETA type options
            timePeriodOptions: [
                {
                    displayName: 'Next 7 days',
                    condition: 'Next',
                    value: '7',
                },
                {
                    displayName: 'Next 14 days',
                    condition: 'Next',
                    value: '14',
                },
                {
                    displayName: 'Next 30 days',
                    condition: 'Next',
                    value: '30',
                },
                {
                    displayName: 'In this month',
                    condition: 'InThis',
                    value: 'Month',
                },
                {
                    displayName: 'In this quarter',
                    condition: 'InThis',
                    value: 'Quarter',
                },
            ],

            // Frequency
            reportFrequenciesOptions: ['Daily', 'Weekly', 'Monthly'],
            selectedFrequency: 'Weekly',

            // Hour
            hoursInDay: {},
            selectedHourInDay: {},

            // Week
            selectedDaysInWeek: getDaysInWeekSelectedMap({ Mon: true }),
            atLeastOneDaySelected: true,

            // Month
            daysInMonth: [],
            selectedDayInMonth: 1,

            // Subscribers
            subscribers: [$scope.as.currentUser],
            peopleWithNoPermissions: null,
            peopleThatAreNotFullUsers: null,

            // Loading flags & error handling
            errorMessage: null,
            creatingOrUpdatingReport: false,
            sendingPreview: false,
            previewSent: false,
            selectedMinute: 0,
            minutesToSelectOptions: [0, 15, 30, 45],

            // Report types config
            reportTypeConfigurations: {
                List: {
                    type: 'List',
                    initFunction: initSingleSelectionTypeConfig,
                    initEditModeFunction: initSingleSelectionTypeEditMode,
                    getReportName: getSingleSelectionReportName,
                    onEntitySelected: setPeopleWithNoPermissions,
                    getEntityDisplayName: getListEntityDisplayName,
                    getRelevantEntities: getListRelevantEntities,
                    getConditionedEntities: getListConditionedEntities,
                    applyConditionsFunction: applyListConditions,
                },
                Status: {
                    type: 'Status',
                    initFunction: initSingleSelectionTypeConfig,
                    initEditModeFunction: initSingleSelectionTypeEditMode,
                    getReportName: getSingleSelectionReportName,
                    getEntityDisplayName: getStatusEntityDisplayName,
                    getRelevantEntities: getAllExistingStates,
                    getConditionedEntities: getStatusConditionedEntities,
                    applyConditionsFunction: applyStatusConditions,
                },
                Function: {
                    type: 'Function',
                    initFunction: initSingleSelectionTypeConfig,
                    initEditModeFunction: initSingleSelectionTypeEditMode,
                    getReportName: getSingleSelectionReportName,
                    getEntityDisplayName: getFunctionEntityDisplayName,
                    getRelevantEntities: getFunctionRelevantEntities,
                    getConditionedEntities: getFunctionConditionedEntities,
                    applyConditionsFunction: applyFunctionConditions,
                },
                Tag: {
                    type: 'Tag',
                    initFunction: initSingleSelectionTypeConfig,
                    initEditModeFunction: initSingleSelectionTypeEditMode,
                    getReportName: getSingleSelectionReportName,
                    getEntityDisplayName: getTagEntityDisplayName,
                    getRelevantEntities: getTagRelevantEntities,
                    getConditionedEntities: getTagConditionedEntities,
                    applyConditionsFunction: applyTagConditions,
                },
                'Due/ETA': {
                    type: 'Due/ETA',
                    description: 'time period',
                    initFunction: initSingleSelectionTypeConfig,
                    initEditModeFunction: initSingleSelectionTypeEditMode,
                    getReportName: getDueOrEtaReportName,
                    getEntityDisplayName: getDueOrEtaEntityDisplayName,
                    getRelevantEntities: getDueOrEtaRelevantEntities,
                    getConditionedEntities: getDueOrEtaConditionedEntities,
                    applyConditionsFunction: applyDueOrEtaConditions,
                },
                Custom: {
                    type: 'Custom',
                    description: 'custom',
                    initFunction: initCustomReportConfig,
                    getReportName: getCustomReportName,
                    applyConditionsFunction: applyCustomReportConditions,
                },
            },
            groupList: getModulesForList(),
            selectedGroup: null,

            selectedOrderByField: defaultOrderByOption,
            orderByOptions: [],
            orderBySortTypes: Object.values(SortOrderType),
            orderBySortType: SortOrderType.ASC,
        };

        // Dictionary of hour name to hour numeric - { '1 AM' : 1 }
        $scope.data.hoursInDay = getHoursOfTheDay();
        $scope.data.selectedHourInDay = $scope.data.hoursInDay['10 AM'];

        // Generating the days of month
        for (let i = 1; i <= 31; i++) {
            $scope.data.daysInMonth.push(i);
        }

        // Are we in edit mode? If so, we load data from existing report.
        if (!$scope.createMode && $scope.editedReport) {
            initializeEditMode();
        }

        initReportType();
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        if (onClosedCallback) {
            onClosedCallback();
        }

        $uibModalInstance.close();
    };

    /**
     * Occurs when the user changes the selection of report type.
     */
    $scope.changeReportType = function (type) {
        $scope.data.selectedReportType = type;
        initReportType();
    };

    $scope.onSelectedOrderByField = () => {
        if ($scope.data.selectedOrderByField.id === 'default') {
            $scope.data.orderBySortType = SortOrderType.ASC;
        }
    };

    /**
     * Recalculates the report name.
     */
    $scope.reloadReportName = function () {
        const config = getConfig();

        if (config.getReportName && !$scope.data.userChangedReportName) {
            $scope.data.reportName = config.getReportName($scope.data.selectedReportTypeConfig);
        }
    };

    /**
     * Sends a preview of the configured scheduled report.
     */
    $scope.sendPreview = function () {
        $scope.data.sendingPreview = true;
        const conditions = getConditionsObject();
        const fieldsMap = getFieldsMapForReport();

        tonkeanService
            .sendPreviewScheduledReport(
                $scope.pm.project.id,
                $scope.data.reportName,
                fieldsMap,
                conditions,
                $scope.data.reportTypeOptions[$scope.data.selectedReportType],
                $scope.data.includeFirstLevelOfInnerTracks,
                $scope.data.selectedOrderByField.id !== 'default'
                    ? {
                          fieldId: $scope.data.selectedOrderByField.id,
                          fieldType: $scope.data.selectedOrderByField.fieldType,
                          sortOrderType: $scope.data.orderBySortType,
                      }
                    : undefined,
            )
            .then(function (data) {
                $scope.data.previewSent = data.sentPreview;
                $scope.data.errorMessage = data.message;
            })
            .catch(function (error) {
                $scope.data.errorMessage = `Error sending preview - ${error}`;
            })
            .finally(function () {
                $scope.data.sendingPreview = false;
            });
    };

    /**
     * Adds the newly configured scheduled report to the project.
     */
    $scope.addOrUpdateScheduledReport = function () {
        $scope.data.creatingOrUpdatingReport = true;

        const conditions = getConditionsObject();
        const recurrenceDaysInWeek = getRecurrenceDaysInWeek();
        const recurrenceDaysInMonth = [$scope.data.selectedDayInMonth];
        const recurrenceHour = $scope.data.selectedHourInDay;
        const recurrenceMinute = $scope.data.selectedMinute;
        const orderBySettings =
            $scope.data.selectedOrderByField.id !== 'default'
                ? {
                      fieldId: $scope.data.selectedOrderByField.id,
                      fieldType: $scope.data.selectedOrderByField.fieldType,
                      sortOrderType: $scope.data.orderBySortType,
                  }
                : undefined;
        const fieldsMap = getFieldsMapForReport();

        const recurrenceMonthsInYear = $scope.data.selectedFrequency === 'Monthly' ? allMonthsInYear : undefined;

        let updateOrCreatePromise;

        if (createMode) {
            updateOrCreatePromise = getSubscribers().then(function (subscribers) {
                return tonkeanService.createScheduledReport(
                    $scope.data.reportTypeOptions[$scope.data.selectedReportType],
                    $scope.pm.project.id,
                    $scope.data.reportName,
                    fieldsMap,
                    conditions,
                    $scope.data.selectedFrequency,
                    recurrenceDaysInWeek,
                    recurrenceDaysInMonth,
                    recurrenceMonthsInYear,
                    recurrenceHour,
                    subscribers,
                    $scope.data.includeFirstLevelOfInnerTracks,
                    recurrenceMinute,
                    orderBySettings,
                );
            });
        } else {
            updateOrCreatePromise = getSubscribers().then(function (subscribers) {
                return tonkeanService.updateScheduledReport(
                    $scope.data.reportTypeOptions[$scope.data.selectedReportType],
                    $scope.editedReport.id,
                    $scope.data.reportName,
                    fieldsMap,
                    conditions,
                    $scope.data.selectedFrequency,
                    recurrenceDaysInWeek,
                    recurrenceDaysInMonth,
                    recurrenceMonthsInYear,
                    recurrenceHour,
                    subscribers,
                    $scope.data.includeFirstLevelOfInnerTracks,
                    recurrenceMinute,
                    orderBySettings,
                );
            });
        }

        updateOrCreatePromise
            .then(function () {
                if (createOrUpdateCallback) {
                    createOrUpdateCallback();
                }

                $uibModalInstance.close();
            })
            .catch(function () {
                $scope.data.creatingOrUpdatingReport = false;
                $scope.data.errorMessage = 'Could not create or update report. Please try again later.';
            });
    };

    /**
     * Occurs when a subscriber is unselected from the list.
     */
    $scope.subscriberUnselected = function () {
        filterPeople($scope.data.peopleWithNoPermissions, $scope.data.subscribers);
        filterPeople($scope.data.peopleThatAreNotFullUsers, $scope.data.subscribers);
    };

    /**
     * Selects a day in week, and sets a boolean indicating whether at least one day in week is selected.
     */
    $scope.selectDayInWeek = function (dayInWeekSelection) {
        dayInWeekSelection.selected = !dayInWeekSelection.selected;

        if (dayInWeekSelection.selected) {
            $scope.data.atLeastOneDaySelected = true;
        } else {
            const recurrenceDaysInWeek = getRecurrenceDaysInWeek();

            if (!recurrenceDaysInWeek || !recurrenceDaysInWeek.length) {
                $scope.data.atLeastOneDaySelected = false;
            }
        }
    };

    /**
     * Navigates to the manage users page.
     */
    $scope.goToManageUsers = function () {
        $state.go('product.settings.license', { tab: 'members' });
        $scope.cancel();
    };

    /**
     * Opens a prompt making sure user really wants to delete the report.
     */
    $scope.deleteScheduledReport = function () {
        $scope.mboxData = {
            title: 'Delete Scheduled Report',
            body: `Are you sure you want to delete "${$scope.editedReport.name}"?`,
            isWarn: true,
            okLabel: 'Delete',
            cancelLabel: 'Cancel',
        };

        $rootScope.modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(function () {
                // okLabel clicked.
                doDeleteScheduledReport();
            });
    };

    /**
     * Deletes the scheduled report.
     */
    function doDeleteScheduledReport() {
        // Delete only enabled in edit mode.
        if (!$scope.createMode) {
            $scope.data.deletingReport = true;
            tonkeanService
                .deleteScheduledReport($scope.editedReport.id)
                .then(function () {
                    if (createOrUpdateCallback) {
                        createOrUpdateCallback();
                    }

                    $uibModalInstance.close();
                })
                .catch(function (error) {
                    $scope.data.deletingError = error;
                })
                .finally(function () {
                    $scope.data.deletingReport = false;
                });
        }
    }

    /**
     * Filters the peopleArrayToFilter so that it only contains people that are in existingPeopleSet.
     */
    function filterPeople(peopleArrayToFilter, existingPeopleSet) {
        if (peopleArrayToFilter && peopleArrayToFilter.length) {
            const peopleSet = utils.createMapFromArray(existingPeopleSet, 'id', true);

            for (let i = 0; i < peopleArrayToFilter.length; i++) {
                const person = peopleArrayToFilter[i];

                if (!peopleSet[person.id]) {
                    peopleArrayToFilter.splice(i, 1);
                }
            }
        }
    }

    /**
     * Constructs a selected map from the days of week settings.
     */
    function getDaysInWeekSelectedMap(selectedDaysMap) {
        const daysOfWeekSelectedMap = {};
        const daysOfWeekSettings = consts.getDaysOfWeekSettings();

        for (const singleDay in daysOfWeekSettings) {
            if (daysOfWeekSettings.hasOwnProperty(singleDay)) {
                const singleDayApiValue = daysOfWeekSettings[singleDay];

                daysOfWeekSelectedMap[singleDay] = {
                    apiValue: singleDayApiValue,
                    selected: selectedDaysMap[singleDayApiValue] || selectedDaysMap[singleDay],
                };
            }
        }

        return daysOfWeekSelectedMap;
    }

    /**
     * Gets a dictionary of hour display value to hour numeric value.
     */
    function getHoursOfTheDay() {
        const hoursOfTheDay = {};

        for (let i = 0; i <= 11; i++) {
            hoursOfTheDay[`${i === 0 ? 12 : i} AM`] = i;
        }

        for (let j = 0; j <= 11; j++) {
            hoursOfTheDay[`${j === 0 ? 12 : j} PM`] = j + 12;
        }

        return hoursOfTheDay;
    }

    /**
     * Initializes the list of people that are not full users.
     */
    function initializePeopleThatAreNotFullUsers() {
        // Get the people from data.subscribers that are not full users.
        $scope.data.peopleThatAreNotFullUsers = [];

        if ($scope.data.subscribers) {
            for (let i = 0; i < $scope.data.subscribers.length; i++) {
                const subscriber = $scope.data.subscribers[i];

                if (!subscriber.projectContext || !subscriber.projectContext.isLicensed) {
                    $scope.data.peopleThatAreNotFullUsers.push(subscriber);
                }
            }
        }
    }

    /**
     * Initializes the modal into an edit mode with given $scope.editedReport.
     */
    function initializeEditMode() {
        const reportTypeApiNameToDisplayNameMap = utils.reverseMap($scope.data.reportTypeOptions);

        $scope.data.reportName = $scope.editedReport.name;
        $scope.data.userChangedReportName = true;
        $scope.data.includeFirstLevelOfInnerTracks = $scope.editedReport.includeFirstLevelOfInnerTracks;
        $scope.data.selectedReportType = reportTypeApiNameToDisplayNameMap[$scope.editedReport.scheduledReportType];
        $scope.data.selectedReportTypePair = {
            value: $scope.editedReport.scheduledReportType,
            displayName: $scope.data.selectedReportType,
        };
        $scope.data.subscribers = $scope.editedReport.subscribers;
        initializePeopleThatAreNotFullUsers();
        $scope.data.selectedFrequency = $scope.editedReport.recurrencePeriodType;

        // Setting the selected day
        switch ($scope.data.selectedFrequency) {
            case 'Weekly':
                const selectedDaysMap = {};

                for (let i = 0; i < $scope.editedReport.recurrenceDaysInWeek.length; i++) {
                    const selectedDay = $scope.editedReport.recurrenceDaysInWeek[i];

                    selectedDaysMap[selectedDay] = true;
                }

                $scope.data.selectedDaysInWeek = getDaysInWeekSelectedMap(selectedDaysMap);
                break;

            case 'Monthly':
                // In month frequency, you may only choose one recurrence day
                $scope.data.selectedDayInMonth = $scope.editedReport.recurrenceDaysInMonth[0];
                break;
        }

        // Setting the selected hour
        if ($scope.editedReport.recurrenceHour) {
            $scope.data.selectedHourInDay = $scope.editedReport.recurrenceHour;
        }

        // Setting the selected minute
        if ($scope.editedReport.recurrenceMinute) {
            $scope.data.selectedMinute = $scope.editedReport.recurrenceMinute;
        }

        const config = getConfig();

        if (config.initEditModeFunction) {
            config.initEditModeFunction(config);
        }

        if ($scope.editedReport.fieldsMap) {
            Object.keys($scope.editedReport.fieldsMap).forEach((groupId) => {
                $scope.data.groupList[groupId].checked = true;
            });
        }
    }

    /**
     * Returns a promise that returns a list of subscribers ids.
     * If subscribers do not exist in tonkean yet, sends them an invite to the project.
     */
    function getSubscribers() {
        const subscribersToInvite = [];
        let subscribersToReport = [];

        for (let i = 0; i < $scope.data.subscribers.length; i++) {
            const subscriber = $scope.data.subscribers[i];

            if (!subscriber.id) {
                // If it's not a person object (and therefore does not have an id),
                // it's a new email and should be invited to tonkean
                subscribersToInvite.push({ email: subscriber.email, name: subscriber.email.split('@')[0] });
            } else {
                // Otherwise, it's an existing user and we take its id
                subscribersToReport.push(subscriber.id);
            }
        }

        if (subscribersToInvite.length > 0) {
            // If we have any new users, we send the invites and then return the subscribers array
            return inviteManager.sendInvites($scope.pm.project.id, subscribersToInvite).then(function (data) {
                const newUsers = data.invites.map(function (invitee) {
                    return invitee.person.id;
                });

                subscribersToReport = subscribersToReport.concat(newUsers);
                return $q.resolve(subscribersToReport);
            });
        } else {
            // Otherwise, we just return the subscribers array (of only existing users)
            return $q.resolve(subscribersToReport);
        }
    }

    /**
     * Constructs the conditions object of initiatives.
     */
    function getConditionsObject() {
        const config = getConfig();

        const conditions = {
            query: {
                type: 'And',
                filters: [],
                queries: [],
            },
        };

        config.applyConditionsFunction(config, conditions);

        return conditions;
    }

    /**
     * Gets the selected recurrence days in week.
     */
    function getRecurrenceDaysInWeek() {
        const recurrenceDays = [];

        for (const day in $scope.data.selectedDaysInWeek) {
            if (
                $scope.data.selectedDaysInWeek.hasOwnProperty(day) &&
                $scope.data.selectedDaysInWeek[day].selected === true
            ) {
                recurrenceDays.push($scope.data.selectedDaysInWeek[day].apiValue);
            }
        }

        return recurrenceDays;
    }

    /**
     * Initializes the configuration needed for given type.
     */
    function initReportType(resetReportName) {
        const config = getConfig();
        $scope.data.selectedReportTypeConfig = config;

        if ($scope.data.selectedReportType && config.initFunction) {
            config.initFunction(config);

            if (config.getReportName && (!$scope.data.userChangedReportName || resetReportName)) {
                $scope.data.reportName = config.getReportName($scope.data.selectedReportTypeConfig);
            }
        }

        // Setting the report type family
        switch ($scope.data.selectedReportType) {
            case 'List':
            case 'Status':
            case 'Function':
            case 'Tag':
            case 'Due/ETA':
                $scope.data.selectedReportTypeFamily = 'SingleSelection';
                break;
            default:
                $scope.data.selectedReportTypeFamily = null;
        }

        setPeopleWithNoPermissions();
    }

    /**
     * Gets the config of the currently selected report type.
     * @returns {*}
     */
    function getConfig() {
        return $scope.data.reportTypeConfigurations[$scope.data.selectedReportType];
    }

    // region: List Report Type

    /**
     * Occurs when list selection is changed.
     */
    function setPeopleWithNoPermissions() {
        // Get the people from data.subscribers that do not have permissions to the group.
        $scope.data.peopleWithNoPermissions = [];

        if (
            $scope.data.subscribers &&
            $scope.data.selectedReportType === 'List' &&
            $scope.data.selectedReportTypeConfig &&
            $scope.data.selectedReportTypeConfig.selectedEntity &&
            $scope.data.selectedReportTypeConfig.selectedEntity.members &&
            $scope.data.selectedReportTypeConfig.selectedEntity.members.length
        ) {
            const groupMembersSet = utils.createMapFromArray(
                $scope.data.selectedReportTypeConfig.selectedEntity.members,
                'id',
                true,
            );

            for (let j = 0; j < $scope.data.subscribers.length; j++) {
                const subscriber = $scope.data.subscribers[j];

                if (!groupMembersSet[subscriber.id]) {
                    $scope.data.peopleWithNoPermissions.push(subscriber);
                }
            }
        }
    }

    /**
     * Gets all the relevant entities for type of report.
     */
    function getListRelevantEntities() {
        return $scope.pm.groups;
    }

    /**
     * Returns the display name of the entity.
     */
    function getListEntityDisplayName(entity) {
        return entity.name;
    }

    /**
     * Gets all the conditioned entities for report.
     */
    function getListConditionedEntities() {
        const groupId = getConditionedEntityFromFilters('scheduledReportGroupFilter');

        return $scope.pm.groups.filter(function (group) {
            return group.id === groupId;
        });
    }

    /**
     * Creates the condition object for report type.
     */
    function applyListConditions(config, conditionsObject) {
        conditionsObject.query.filters.push(
            getEqualsFilter('rootInitiativeFilter', 'isRootInitiative', true, 'Boolean', true),
        );
        conditionsObject.query.filters.push(
            getEqualsFilter('scheduledReportGroupFilter', 'groupId', config.selectedEntity.id, 'String', true),
        );
    }

    // endregion

    // region: Status Report Type

    /**
     * Returns a unique array of strings of state names found in both the project and all groups,
     * since the project defines the default states and each group can override it with custom states.
     * @returns {*}
     */
    function getAllExistingStates() {
        const statesDict = {};

        // No point in doing anything if there isn't a selected project.
        if (projectManager.project) {
            // First, take the project states (if they exist).
            if (projectManager.project.states) {
                for (let i = 0; i < projectManager.project.states.length; i++) {
                    const projectState = projectManager.project.states[i];

                    // Only take states with non-empty labels.
                    if (projectState.label && projectState.label.length) {
                        statesDict[projectState.label.toLowerCase()] = projectState;
                    }
                }
            }

            // Then, go over all the lists, and try to take their states if they are different than the ones we already have.
            if (projectManager.groups) {
                // Go over all the groups.
                for (let i = 0; i < projectManager.groups.length; i++) {
                    const group = projectManager.groups[i];

                    const workflowVersion = workflowVersionManager.getPublishedVersionFromCache(group.id);

                    if (workflowVersion.states) {
                        // Go over the group's states and add it if it's new.
                        for (let j = 0; j < workflowVersion.states.length; j++) {
                            const groupState = workflowVersion.states[j];
                            if (
                                groupState.label &&
                                groupState.label.length &&
                                !statesDict[groupState.label.toLowerCase()]
                            ) {
                                statesDict[groupState.label.toLowerCase()] = groupState;
                            }
                        }
                    }
                }
            }
        }

        return utils.objValues(statesDict);
    }

    /**
     * Returns the display name of the entity.
     */
    function getStatusEntityDisplayName(entity) {
        return entity.label;
    }

    /**
     * Gets all the conditioned entities for report.
     */
    function getStatusConditionedEntities() {
        const selectedState = getConditionedEntityFromFilters('scheduledReportStatusFilter');

        // Currently you can only select one state, so only one element in the states array.
        return getAllExistingStates().filter((state) => state.label === selectedState);
    }

    /**
     * Creates the condition object for report type.
     */
    function applyStatusConditions(config, conditionsObject) {
        conditionsObject.query.filters.push(
            getEqualsFilter('scheduledReportStatusFilter', 'stateText', config.selectedEntity.label, 'String', true),
        );
    }

    // endregion

    // region: Function Report Type

    /**
     * Gets all the relevant entities for report.
     */
    function getFunctionRelevantEntities() {
        return $scope.pm.functions.filter(function (projectFunction) {
            return !utils.isNullOrEmpty(projectFunction.name);
        });
    }

    /**
     * Returns the display name of the entity.
     */
    function getFunctionEntityDisplayName(functionEntity) {
        return functionEntity.name;
    }

    /**
     * Gets all the conditioned entities for report.
     */
    function getFunctionConditionedEntities() {
        const selectedFunction = getConditionedEntityFromFilters('scheduledReportFunctionFilter');

        return $scope.pm.functions.filter(function (projectFunction) {
            return projectFunction.name === selectedFunction;
        });
    }

    /**
     * Creates the condition object for report type.
     */
    function applyFunctionConditions(config, conditionsObject) {
        conditionsObject.query.filters.push(
            getEqualsFilter('scheduledReportFunctionFilter', 'function', config.selectedEntity.name, 'String', true),
        );
    }

    // endregion

    // region: Tag Report Type

    /**
     * Gets all the relevant entities for report.
     */
    function getTagRelevantEntities() {
        return $scope.pm.tags.filter(function (tag) {
            return !utils.isNullOrEmpty(tag.name);
        });
    }

    /**
     * Returns the display name of the entity.
     */
    function getTagEntityDisplayName(entity) {
        return entity.name;
    }

    /**
     * Gets all the conditioned entities for report.
     */
    function getTagConditionedEntities() {
        const selectedTag = getConditionedEntityFromFilters('scheduledReportTagFilter');

        return $scope.pm.tags.filter(function (tag) {
            return tag.name === selectedTag;
        });
    }

    /**
     * Creates the condition object for report type.
     */
    function applyTagConditions(config, conditionsObject) {
        conditionsObject.query.filters.push(
            getEqualsFilter('scheduledReportTagFilter', 'tags', config.selectedEntity.name, 'String', true),
        );
    }

    // endregion

    // region: Due/ETA Report Type

    /**
     * Gets the report name for this report type.
     */
    function getDueOrEtaReportName() {
        return `Due tracks ${$scope.data.selectedFrequency} Report`;
    }

    /**
     * Gets all the relevant entities for report.
     */
    function getDueOrEtaRelevantEntities() {
        return $scope.data.timePeriodOptions;
    }

    /**
     * Returns the display name of the entity.
     */
    function getDueOrEtaEntityDisplayName(entity) {
        return entity.displayName;
    }

    /**
     * Gets all the conditioned entities for report.
     */
    function getDueOrEtaConditionedEntities() {
        const relevantQuery = utils.findFirst(
            $scope.editedReport.conditions.query.queries,
            (q) => q.id === 'dueOrEtaFilter',
        );

        if (relevantQuery) {
            return $scope.data.timePeriodOptions.filter((timePeriod) => {
                const sameCondition = timePeriod.condition === relevantQuery.filters[0].condition;
                const sameValue = timePeriod.value === relevantQuery.filters[0].value;

                return sameCondition && sameValue;
            });
        }
    }

    /**
     * Creates the condition object for report type.
     */
    function applyDueOrEtaConditions(config, conditionsObject) {
        conditionsObject.query.queries.push({
            id: 'dueOrEtaFilter',
            type: 'Or',
            filters: [
                {
                    fieldName: 'dueDate',
                    condition: config.selectedEntity.condition,
                    value: config.selectedEntity.value,
                    fromOriginalEntity: false,
                },
                {
                    fieldName: 'eta',
                    condition: config.selectedEntity.condition,
                    value: config.selectedEntity.value,
                    fromOriginalEntity: false,
                },
            ],
        });
    }

    // endregion

    // region: Custom Report Type

    /**
     * Creates the condition object for report type.
     */
    function applyCustomReportConditions(config, conditionsObject) {
        if ($scope.data.customReportControl) {
            const serverDefinition = $scope.data.customReportControl.createDefinitionFromCustomFilters();

            if (serverDefinition && serverDefinition.query) {
                // Query type
                if (serverDefinition.query.type) {
                    conditionsObject.query.type = serverDefinition.query.type;
                }

                // Converting filters
                if (serverDefinition.query.filters && serverDefinition.query.filters.length) {
                    for (let i = 0; i < serverDefinition.query.filters.length; i++) {
                        const serverFilter = serverDefinition.query.filters[i];
                        conditionsObject.query.filters.push(serverFilter);
                    }
                }

                // Converting queries
                if (serverDefinition.query.queries && serverDefinition.query.queries.length) {
                    for (let i = 0; i < serverDefinition.query.queries.length; i++) {
                        const serverInnerQuery = serverDefinition.query.queries[i];
                        conditionsObject.query.queries.push(serverInnerQuery);
                    }
                }
            }
        }
    }

    /**
     * Gets the name for the report of the custom type.
     */
    function getCustomReportName() {
        return `Custom ${$scope.data.selectedFrequency} Report`;
    }

    /**
     * Initializes the report of custom type.
     */
    function initCustomReportConfig() {
        if (!$scope.data.selectedGroup) {
            $scope.selectGroupFromList($scope.data.groupList[$scope.pm.groups[0].id]);
        }
    }

    // endregion

    // region: Single Selection Report Type

    /**
     * Occurs when the user selected an entity from the menu.
     */
    $scope.onSelectedEntity = function (config) {
        config.errorMessage = null;

        if (config.getReportName && !$scope.data.userChangedReportName) {
            $scope.data.reportName = config.getReportName($scope.data.selectedReportTypeConfig);
        }

        if (config.onEntitySelected) {
            config.onEntitySelected();
        }
    };

    /**
     * Gets the report name for this report type.
     */
    function getSingleSelectionReportName(config) {
        // If no selected entity, we have a report name without the entity itself.
        if (!config.selectedEntity) {
            return `${$scope.data.selectedFrequency} Report`;
        }

        return `${config.getEntityDisplayName(config.selectedEntity)} ${$scope.data.selectedFrequency} Report`;
    }

    /**
     * Gets the conditioned entity from the query filters by the given field name.
     * @param fieldName
     */
    function getConditionedEntityFromFilters(filterId) {
        const conditionedFilter = utils.findFirst(
            $scope.editedReport.conditions.query.filters,
            (f) => f.id === filterId,
        );

        if (conditionedFilter && conditionedFilter.value) {
            return conditionedFilter.value;
        }
    }

    /**
     * Returns an equals filter for fieldName, value and type.
     */
    function getEqualsFilter(filterId, fieldName, value, type, notFromOriginalEntity) {
        const filter = {
            fieldName,
            condition: 'Equals',
            value,
        };

        // Filter id
        if (filterId) {
            filter.id = filterId;
        }

        // Type
        if (type) {
            filter.type = type;
        }

        // From original entity
        if (notFromOriginalEntity) {
            filter.fromOriginalEntity = false;
        }

        return filter;
    }

    /**
     * Initialization function of the report type.
     */
    function initSingleSelectionTypeConfig(config) {
        config.relevantEntities = config.getRelevantEntities();

        // Setting the display name to all entities
        for (let i = 0; i < config.relevantEntities.length; i++) {
            const relevantEntity = config.relevantEntities[i];

            relevantEntity.displayName = config.getEntityDisplayName(relevantEntity);
        }

        if (!config.selectedEntity && !config.errorMessage) {
            config.selectedEntity = config.relevantEntities[0];
        }

        if (config.selectedEntity) {
            config.errorMessage = null;
        }

        if ($scope.data.selectedReportType === 'List') {
            $scope.data.groupList[config.selectedEntity.id].checked = true;
            $scope.selectGroupFromList($scope.data.groupList[config.selectedEntity.id]);
        }
    }

    /**
     * Initialization function from edit mode.
     */
    function initSingleSelectionTypeEditMode(config) {
        const conditionedEntities = config.getConditionedEntities();

        if (!conditionedEntities || !conditionedEntities.length) {
            config.errorMessage = `Could not find selected ${config.type.toLowerCase()}, it could be a permissions problem. Please select another ${config.type.toLowerCase()}.`;
        } else {
            config.selectedEntity = conditionedEntities[0];
        }

        if (!$scope.data.selectedGroup) {
            $scope.selectGroupFromList($scope.data.groupList[$scope.pm.groups[0].id]);
        }
    }

    function getModulesForList() {
        return $scope.pm.groups.reduce((map, group) => {
            map[group.id] = {
                item: group,
                id: group.id,
                label: group.name,
                checked: false,
                loadingFieldList: false,
            };

            return map;
        }, {});
    }

    function getFieldsOfModuleForList(groupListItem) {
        if (groupListItem.loadingFieldList || groupListItem.fieldsList) {
            return;
        }

        groupListItem.loadingFieldList = true;

        return customFieldsManager.getFieldDefinitions(groupListItem.item.publishedWorkflowVersionId).then((fields) => {
            $timeout(() => {
                groupListItem.fieldsList = [...fields].reduce((map, field) => {
                    map[field.id] = {
                        item: field,
                        id: field.id,
                        label: field.fieldLabel || field.name,
                        checked: $scope.editedReport?.fieldsMap?.[groupListItem.id]?.includes(field.id),
                    };

                    return map;
                }, {});

                groupListItem.loadingFieldList = false;

                $scope.initOrderByOptions();
            });
        });
    }

    $scope.selectGroupFromList = (group) => {
        $timeout(() => {
            $scope.data.selectedGroup = group;
            getFieldsOfModuleForList($scope.data.selectedGroup);

            if ($scope.data.selectedReportType === 'List') {
                $scope.data.selectedReportTypeConfig.selectedEntity = group.item;
                setPeopleWithNoPermissions();
            }
        });
    };

    $scope.checkGroupsFromList = (groups) => {
        $timeout(() => {
            $scope.data.groupList = { ...$scope.data.groupList, ...groups };
        });
    };

    $scope.checkFieldsFromList = (fields) => {
        $timeout(() => {
            $scope.data.groupList[$scope.data.selectedGroup.id].fieldsList = {
                ...$scope.data.groupList[$scope.data.selectedGroup.id].fieldsList,
                ...fields,
            };
        });
    };

    $scope.initOrderByOptions = () => {
        if ($scope.data.groupList?.[$scope.data.selectedGroup?.id]?.fieldsList) {
            const selectedFieldsOrderByOptions = Object.values(
                $scope.data.groupList[$scope.data.selectedGroup.id].fieldsList,
            ).map((field) => ({
                label: field.label,
                id: field.id,
                fieldType: field.item.fieldType,
            }));
            $scope.data.orderByOptions = [
                defaultOrderByOption,
                ...specialFieldsOrderByOptions,
                ...selectedFieldsOrderByOptions,
            ];

            if ($scope.editedReport?.orderBySettings) {
                const foundOption = $scope.data.orderByOptions.find(
                    (option) => option.id === $scope.editedReport?.orderBySettings.fieldId,
                );
                if (foundOption) {
                    $scope.data.selectedOrderByField = foundOption;
                    $scope.data.orderBySortType = $scope.editedReport?.orderBySettings.sortOrderType;
                }
            }
        }
    };

    function getFieldsMapForReport() {
        const fieldsMap = {};

        if ($scope.data.selectedReportType === 'List') {
            if ($scope.data.groupList[$scope.data.selectedGroup.id].fieldsList) {
                fieldsMap[$scope.data.selectedGroup.id] = getCheckFieldsFromGroupList($scope.data.selectedGroup.id);
            } else {
                fieldsMap[$scope.data.selectedGroup.id] =
                    $scope.editedReport?.fieldsMap?.[$scope.data.selectedGroup.id] || [];
            }
        } else {
            Object.values($scope.data.groupList)
                .filter((group) => group.checked)
                .forEach((group) => {
                    if ($scope.data.groupList[group.id].fieldsList) {
                        fieldsMap[group.id] = getCheckFieldsFromGroupList(group.id);
                    } else {
                        fieldsMap[group.id] = $scope.editedReport?.fieldsMap?.[group.id] || [];
                    }
                });
        }

        return fieldsMap;
    }

    function getCheckFieldsFromGroupList(groupId) {
        return Object.values($scope.data.groupList[groupId].fieldsList)
            .filter((field) => field.checked)
            .map((field) => field.id);
    }

    // endregion

    $scope.init();
}

export default angular.module('tonkean.app').controller('AddScheduledReportCtrl', AddScheduledReportCtrl);
