import BotHistoryItem from './BotHistoryItem';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkBotHistoryItem',
        reactToAngular(
            BotHistoryItem,
            ['id', 'uiActions'],
            ['authenticationService', 'trackHelper', 'botHistoryItemStore'],
        ),
    );
