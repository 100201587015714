function NavMenuCtrl(
    $scope,
    $rootScope,
    $localStorage,
    $state,
    onBoardingManager,
    timeUtils,
    licenseManager,
    modal,
    projectManager,
    authenticationService,
    licensePermissionsService,
    utils,
    enterpriseHelper,
) {
    $scope.$storage = $localStorage;
    $rootScope.isMenuOpen = false;
    $scope.$rootScope = $rootScope;
    $scope.$state = $state;
    $scope.pm = projectManager;
    $scope.as = authenticationService;
    $scope.obm = onBoardingManager;
    $scope.timeUtils = timeUtils;
    $scope.licenseManager = licenseManager;
    $scope.utils = utils;

    // Show manage Enterprise item only if user is owner of enterprise
    $scope.isEnterpriseUser = licensePermissionsService.isEnterpriseUser();
    $scope.enterpriseName = '';
    $scope.addingProjectToEnterprise = false;

    $scope.currentYear = new Date().getFullYear();

    $rootScope.shrinkHeader = false;

    $scope.toggleMenu = function () {
        $rootScope.isMenuOpen = !$rootScope.isMenuOpen;
    };

    $scope.openMenu = function () {
        $rootScope.isMenuOpen = true;
    };

    $scope.closeMenu = function () {
        $rootScope.isMenuOpen = false;
    };

    $scope.openInvite = function () {
        $rootScope.isMenuOpen = false;
        modal.openInvite();
    };

    $scope.openFeedback = function () {
        $rootScope.isMenuOpen = false;
        modal.openFeedback();
    };

    $scope.openReferralModal = function () {
        $rootScope.isMenuOpen = false;
        modal.openReferralModal();
    };

    $scope.goToProfilePage = function () {
        if ($rootScope.lps.shouldGoToTracksBoxPage()) {
            // The user is not licensed in V1. We send him to a stand-alone profile page since he can't reach the product.settings.profile page.
            $state.go('profile');
        } else {
            // Send the user to the normal profile page.
            $state.go('product.settings.profile');
        }
    };

    $scope.addProjectToEnterprise = function (event) {
        // Prevent the click of the menu item from happening.
        utils.eventStopAllPropagations(event);

        // Make sure we are in an enterprise user account.
        if ($scope.isEnterpriseUser) {
            $scope.addingProjectToEnterprise = true;

            enterpriseHelper
                .addProjectToEnterpriseById(authenticationService.currentUser.enterprise.id, $scope.pm.project.id)
                .then(() => {
                    // Update the project data now that the project was added to the enterprise.
                    $scope.pm
                        .getProjectData(true)
                        .then(() => {
                            $scope.closeMenu();

                            $scope.$emit('alert', {
                                msg: `Successfully added board "${$scope.pm.project.name}" to the enterprise account.`,
                                type: 'success',
                            });
                        })
                        .finally(() => {
                            $scope.addingProjectToEnterprise = false;
                        });
                })
                .catch(() => {
                    $scope.addingProjectToEnterprise = false;
                    $scope.closeMenu();
                    $scope.$emit('alert', {
                        msg: `There was an error adding board${$scope.pm.project.name} to the enterprise account.`,
                        type: 'danger',
                    });
                });
        }
    };

    $rootScope.$on('$locationChangeSuccess', function () {
        licenseManager.states.requestForAccessSent = false;
        $scope.closeMenu();
    });

    $scope.$on('currentUserChanged', function () {
        if ($rootScope.as.currentUser && $rootScope.as.currentUser.isUser) {
            if ($rootScope.as.currentUser.metadata && $rootScope.as.currentUser.metadata.showOnBoarding2) {
                $rootScope.$broadcast('toggleBotPopover', true, true);
            }

            // If the firstListCreated step was not yet completed, wait for a broadcast for when it happened.
            if (!$rootScope.onBoardingManager.isStepCompleted('firstListCreated')) {
                // Open bot popover when first list is created.
                const firstListCreatedListener = $scope.$on('onBoardingStepCompleted', function (event, stepKey) {
                    // Only show the bot popover if this is the first list and the first list step was not yet completed.
                    if (stepKey === 'firstListCreated' && (!$scope.pm.groups || $scope.pm.groups.length === 1)) {
                        // Open the bot popover in sticky mode..
                        $rootScope.$broadcast('toggleBotPopover', true, true);
                        // Remove the broadcast listener.
                        firstListCreatedListener();
                    }
                });
            }

            $scope.isEnterpriseUser = licensePermissionsService.isEnterpriseUser();
            if ($scope.isEnterpriseUser) {
                $scope.enterpriseName = authenticationService.currentUser.enterprise.name;
            }
        }
    });
}

angular.module('tonkean.app').controller('NavMenuCtrl', NavMenuCtrl);
