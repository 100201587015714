<div ng-controller="ManualAddTracksCtrl">
    <!-- Tab selection -->
    <div class="inner-tabs-bar flex">
        <div ng-class="{ 'mod-selected': !data.pasteInitiatives }" ng-click="data.pasteInitiatives = false">
            Enter manually
        </div>
        <div ng-class="{ 'mod-selected': data.pasteInitiatives }" ng-click="data.pasteInitiatives = true">
            Paste from your notes
        </div>
        <div class="flex-grow"></div>
    </div>

    <!--add manually-->
    <div ng-show="!data.pasteInitiatives">
        <div class="flex-vmiddle common-size-xxxxs common-color-grey margin-bottom">
            <strong class="manual-initiative-name flex-basis-50 flex-grow flex-no-shrink margin-right">
                {{ initiativeLable || 'Milestone' }} Name
            </strong>
            <strong class="common-width-12 margin-right-xs">Due</strong>
            <strong class="common-width-33">Owner</strong>
        </div>
        <div
            ng-repeat="initiative in initiativesResults"
            class="margin-bottom-lg flex mod-align-start"
            ng-style="{ 'padding-left': 15 * initiative.parentsCount + 'px' }"
        >
            <!-- Milestone name -->
            <div class="flex-basis-50 flex-grow">
                <input
                    type="text"
                    class="form-control common-width-100 common-input-only-line common-bg mod-transparent margin-right"
                    ng-model="initiative.title"
                    ng-change="assignInitiativeToCurrentUser(initiative)"
                    placeholder="{{ initiativeLable || 'milestone' | capitalize }} name..."
                    ng-required="$first ? (!initiative.title || !initiative.title.length) : false"
                />
            </div>

            <!-- Milestone due date -->
            <div class="relative common-width-12 flex-no-shrink margin-right-xs">
                <button
                    type="button"
                    class="btn btn-no-border padding-left padding-right common-width-full"
                    data-ng-disabled="!$first && (!initiative.title || !initiative.title.length)"
                    ng-click="datePickerOpen = !datePickerOpen"
                >
                    <i
                        class="svg-icon-track common-color-light-grey margin-right-xs"
                        ng-hide="$root.isMobile && initiative.dueDate"
                    >
                        <tnk-icon src="/images/icons/calendar.svg"></tnk-icon>
                    </i>
                    <span ng-if="initiative.dueDate">{{ initiative.dueDate | date: 'MMM d' }}</span>
                </button>
                <input
                    class="margin-top track-due-date-input mod-no-adjustment"
                    uib-datepicker-popup="fullDate"
                    datepicker-options="{showWeeks: false}"
                    ng-model="initiative.dueDate"
                    ng-min="data.minDate"
                    min-date="data.minDate"
                    ng-change="initiative.dueDate = initiative.dueDate.getTime()"
                    is-open="datePickerOpen"
                    close-text="Close"
                />
            </div>

            <!-- Milestone owner -->
            <div
                class="manual-initiative-owner common-width-33 flex-no-shrink"
                ng-init="initiative.selectedPeople = initiative.owner ? [initiative.owner] : (data.currentUserAsDefaultOwner ? [as.currentUser] : []); initiative.peopleSelectorControl = {};"
            >
                <tnk-deprecated-people-selector
                    class="common-width-full"
                    disabled="!$first && (!initiative.title || !initiative.title.length)"
                    no-validation="false"
                    control-object="initiative.peopleSelectorControl"
                    selected-people="initiative.selectedPeople"
                    on-tag-added="
                        initiative.selectedPeople.length
                            ? (initiative.ownerId = initiative.selectedPeople[0].id)
                            : null;
                        updateManualInitiativesOwners(initiative, initiative.selectedPeople[0])
                    "
                    on-tag-removed="
                        initiative.owner = null; initiative.ownerId = null; updateManualInitiativesOwners(initiative)
                    "
                    on-person-invite="
                        initiative.selectedPeople.length
                            ? (initiative.ownerId = initiative.selectedPeople[0].id)
                            : null;
                        updateManualInitiativesOwners(initiative, initiative.selectedPeople[0])
                    "
                    max-tags="1"
                    placeholder="Type a name or email"
                    show-intro="true"
                ></tnk-deprecated-people-selector>
            </div>

            <!-- Delete button -->
            <button
                type="button"
                class="btn btn-xs btn-no-border common-no-outline flex-no-shrink margin-left-xxs margin-top-xs"
                ng-click="removeFromInitiativesResults($index)"
                tabindex="-1"
                data-ng-disabled="$first"
                uib-tooltip="Remove {{ initiativeLable || 'Milestone' }}"
                tooltip-placement="top"
            >
                <span class="svg-icon-xs common-color-grey">
                    <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                </span>
            </button>
        </div>
        <!-- Add button -->
        <div>
            <button
                type="button"
                class="btn btn-secondary btn-sm common-color-white margin-top"
                ng-click="addToInitiativesResults(1)"
            >
                <i class="fa fa-plus-circle margin-right-xs common-no-outline"></i>
                <strong>Add {{ initiativeLable || 'Milestone' }}</strong>
            </button>
        </div>

        <!-- Owners description box -->
        <div
            class="flex mod-align-start flex-xs-wrap margin-top-lg sync-preview-owners-box mod-manual-owners"
            ng-class="{ 'mod-visible': data.manualInitiativesOwners.length > 0 }"
        >
            <div class="svg-icon mod-static">
                <tnk-icon src="/images/icons/tonkean-logo.svg"></tnk-icon>
            </div>
            <div class="flex-grow">
                <div class="margin-bottom">
                    <div class="common-size-xs">Great Start!</div>
                    <div class="common-size-xs common-width-full common-bold">
                        <span>The Tonkean Module will ping&nbsp;</span>
                        <ng-pluralize
                            count="data.manualInitiativesOwners.length"
                            when="{'0': 'Future owners',
                                     '1': '{{ data.manualInitiativesOwners[0].customDisplayName | capitalize }}',
                                     '2': '{{ data.manualInitiativesOwners[0].customDisplayName | capitalize }} and {{
                                data.manualInitiativesOwners[1].customDisplayName | capitalize
                            }}',
                                     'other': '{{ data.manualInitiativesOwners[0].customDisplayName | capitalize }}, {{
                                data.manualInitiativesOwners[1].customDisplayName | capitalize
                            }} and {{ data.manualInitiativesOwners.length - 2 }} more'}"
                        ></ng-pluralize>
                        <span>&nbsp;for next steps and updates</span>
                    </div>
                    <div
                        ng-if="data.uninvitedManualOwners.length"
                        class="common-size-xxs common-color-light-grey2 common-thin pointer"
                        ng-click="inviteWithEmail(data.uninvitedManualOwners)"
                    >
                        Introduce
                        <span ng-if="data.uninvitedManualOwners.length === 1">
                            {{ data.uninvitedManualOwners[0].customDisplayName | capitalize }}
                        </span>
                        <span ng-if="data.uninvitedManualOwners.length === 2">
                            {{ data.uninvitedManualOwners[0].customDisplayName | capitalize }} and
                            {{ data.uninvitedManualOwners[1].customDisplayName | capitalize }}
                        </span>
                        <span ng-if="data.uninvitedManualOwners.length > 2">
                            {{ data.uninvitedManualOwners[0].customDisplayName | capitalize }},
                            {{ data.uninvitedManualOwners[1].customDisplayName | capitalize }} and
                            <a class="tnk-tooltip mod-top common-color-light-grey2 common-underline">
                                {{ data.uninvitedManualOwners.length - 2 }} more
                                <div class="tnk-tooltip-text">
                                    <div ng-repeat="owner in data.uninvitedManualOwners" ng-if="$index >= 2">
                                        {{ owner.customDisplayName | capitalize }}
                                    </div>
                                </div>
                            </a>
                        </span>
                        to Tonkean with a personal message?
                    </div>
                </div>
                <div class="flex-no-shrink">
                    <button
                        type="button"
                        class="btn btn-primary mod-inverse common-no-outline margin-bottom"
                        ng-click="modalUtils.openTestBotModal()"
                    >
                        Send me a preview
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!--paste from notes-->
    <div ng-show="data.pasteInitiatives">
        <p>
            Paste a list of {{ initiativesLable || 'items' }} from your notes. Every line will be a separate
            {{ initiativeLable }}:
        </p>
        <div class="relative">
            <div ng-show="handlingPasteText" class="text-center absolute-fill-view">
                <i class="loading-small margin-top-xxlg"></i>
            </div>
            <!--ng-change="handlePastedText()"-->
            <textarea
                class="form-control common-width-full"
                ng-paste="handlePastedText()"
                data-ng-disabled="handlingPasteText"
                ng-model="data.pastedTextbox"
                rows="10"
                placeholder="Paste in a list of {{ initiativesLable || 'items' }}..."
            ></textarea>
        </div>
        <p ng-if="data.pastedTextbox && data.pastedTextbox.length" class="margin-top">
            <a ng-click="handlePastedText()" data-ng-disabled="handlingPasteText">
                <i class="fa fa-refresh common-color-primary margin-right"></i>
                Analyze text
            </a>
        </p>
    </div>
</div>
