import React, { useContext } from 'react';

import SQLDatabaseExplanationSubtitle from './SQLDatabaseExplanationSubtitle';
import SQLDatabaseFormSizedInput from './SQLDatabaseFormSizedInput';
import SQLDatabaseInnerMiddleSectionTitle from './SQLDatabaseInnerMiddleSectionTitle';
import SQLDatabaseIntegrationConfigurationContext from './SQLDatabaseIntegrationConfigurationContext';
import SQLDatabaseMultiMiddleSectionTitle from './SQLDatabaseMultiMiddleSectionTitle';
import SQLDatabaseRequiredAsterisk from './SQLDatabaseRequiredAsterisk';
import SQLDatabaseSectionTitle from './SQLDatabaseSectionTitle';
import SQLDatabaseToggledSectionTitle from './SQLDatabaseToggledSectionTitle';
import type SQLDatabaseQueryConfiguration from '../entities/SQLDatabaseQueryConfiguration';

import { Toggle } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const SQLDatabaseQueryConfigurationTimestampsSection: React.FC<{
    queryConfiguration: SQLDatabaseQueryConfiguration;
    index: number;
}> = ({ queryConfiguration, index }) => {
    const { changeCustomTimestampsEnabled, changeCreatedColumn, changeUpdatedColumn } = useContext(
        SQLDatabaseIntegrationConfigurationContext,
    );

    return (
        <>
            <SQLDatabaseMultiMiddleSectionTitle>
                <SQLDatabaseToggledSectionTitle>Timestamp Fields</SQLDatabaseToggledSectionTitle>
                <Toggle
                    checked={queryConfiguration.customTimestampsEnabled}
                    onChange={() => changeCustomTimestampsEnabled(!queryConfiguration.customTimestampsEnabled, index)}
                    size={InputSize.MEDIUM}
                />
            </SQLDatabaseMultiMiddleSectionTitle>

            {queryConfiguration.customTimestampsEnabled && (
                <>
                    <SQLDatabaseExplanationSubtitle>
                        You can modify the created and updated time of the created data source items using columns from
                        the query results. <br />
                        If not defined, the timestamps will be set to the current time when fetching the query.
                    </SQLDatabaseExplanationSubtitle>

                    <SQLDatabaseSectionTitle>
                        Created field <SQLDatabaseRequiredAsterisk />
                    </SQLDatabaseSectionTitle>

                    <SQLDatabaseFormSizedInput
                        type="text"
                        value={queryConfiguration.createdColumn}
                        className="form-control"
                        onChange={({ target }) => changeCreatedColumn(target.value, index)}
                        autoComplete="off"
                        placeholder="Created column name"
                        required
                    />

                    <SQLDatabaseInnerMiddleSectionTitle>
                        Updated field <SQLDatabaseRequiredAsterisk />
                    </SQLDatabaseInnerMiddleSectionTitle>

                    <SQLDatabaseFormSizedInput
                        type="text"
                        value={queryConfiguration.updatedColumn}
                        className="form-control"
                        onChange={({ target }) => changeUpdatedColumn(target.value, index)}
                        autoComplete="off"
                        placeholder="Updated column name"
                        required
                    />
                </>
            )}
        </>
    );
};

export default SQLDatabaseQueryConfigurationTimestampsSection;
