import dayjs from 'dayjs';
import { useMemo } from 'react';

/**
 * React hook that gets a timestamp and returns formatted dates - time, date and
 * full date (date and time). Used in `WorkerRunsTableItem`.
 *
 * @param workerRunTimestamp - the timestamp.
 */
function useGetWorkerRunsItemFormattedDate(workerRunTimestamp: number): {
    formattedTime: string;
    fullDate: string;
    formattedDate: string | undefined;
} {
    const date = useMemo(() => {
        return dayjs(workerRunTimestamp);
    }, [workerRunTimestamp]);

    const formattedDate = useMemo(() => {
        if (date.isToday()) {
            return;
        }
        if (date.isYesterday()) {
            return 'Yesterday';
        }

        return date.format('MM.DD.YY');
    }, [date]);

    const formattedTime = useMemo(() => {
        return date.format('hh:mm A');
    }, [date]);

    const fullDate = useMemo(() => {
        const day = date.format('MMM D, YYYY');
        const hour = date.format('hh:mm:ss A');
        return `${day} ${hour}`;
    }, [date]);

    return { formattedDate, formattedTime, fullDate };
}

export default useGetWorkerRunsItemFormattedDate;
