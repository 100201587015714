/**
 * Replace the used variable names with the equivalent variable id.
 */
export function replaceExpressionVariableNamesToIds(
    expression,
    openingSign,
    closingSign,
    variableNameToVariableMap,
    variableLabelToVariableMap,
    variableIdToVariableMap = {},
    isArrayOfInvalidIdsIncluded = false,
    isFieldValid,
) {
    const invalidIds: string[] = [];
    let error: string | null = null;
    let evaluatedExpression = '';

    for (let i = 0; i < expression.length; i++) {
        // Searching for an opening sign.
        const openingSignIndex = expression.indexOf(openingSign, i);

        if (openingSignIndex === i) {
            // If we found an opening sign.

            // Searching for closing sign.
            const closingSignIndex = expression.indexOf(closingSign, openingSignIndex);

            if (closingSignIndex !== -1) {
                // If we found a closing sign.

                // Getting the used variable name.
                const variableName = expression.substring(openingSignIndex + openingSign.length, closingSignIndex);
                const variableFullId = `${openingSign}${variableName}${closingSign}`;
                if (
                    variableNameToVariableMap[variableName] ||
                    variableLabelToVariableMap[variableName] ||
                    isFieldValid?.(variableName)
                ) {
                    // If we have the variable name in our map, we switch it with the id.

                    evaluatedExpression += openingSign;

                    if (variableNameToVariableMap[variableName]) {
                        evaluatedExpression += variableNameToVariableMap[variableName].id;
                    } else if (variableLabelToVariableMap[variableName]) {
                        evaluatedExpression += variableLabelToVariableMap[variableName].id;
                    } else if (variableIdToVariableMap[variableName] || isFieldValid?.(variableName)) {
                        evaluatedExpression += variableName;
                    } else {
                        error = `Couldn't find Field ${variableFullId}`;
                        if (isArrayOfInvalidIdsIncluded) {
                            invalidIds.push(variableFullId);
                        } else {
                            break;
                        }
                    }

                    evaluatedExpression += closingSign;

                    // Incrementing i to be after the closing sign in the next loop iteration.
                    i = closingSignIndex + closingSign.length - 1;
                } else {
                    // Otherwise, we set is valid to false and break.
                    error = `Couldn't find Field ${openingSign}${variableName}${closingSign}.`;
                    if (isArrayOfInvalidIdsIncluded) {
                        invalidIds.push(variableFullId);
                    } else {
                        break;
                    }
                }
            } else {
                // We didn't find a closing sign for an opening sign, we set is valid to false and break.
                error = `Could not find field closing sign for field opening sign at index ${openingSignIndex}`;
                break;
            }
        } else {
            // We didn't find an opening sign, we add the current char to final result.
            evaluatedExpression += expression[i];
        }
    }

    return {
        valid: !error,
        error,
        evaluatedExpression,
        invalidIds,
    };
}
