<!-- User avatar -->
<tnk-avatar
    default-on-empty="defaultOnEmpty"
    class="flex-no-shrink common-size-xxs"
    user="user"
    ng-hide="user.isCustomOption"
></tnk-avatar>
<!-- User display name/email -->
<div
    ng-if="!user.styledGroupTags"
    class="fields fs-censored"
    ng-class="{ 'margin-left': user.isCustomOption && overrideDisplayName }"
    data-automation="tnk-user-full-display-text"
>
    <div class="name" data-automation="tnk-user-full-name-{{ overrideDisplayName || user.displayName || user.name || user.email }}">
        {{ overrideDisplayName || user.displayName || user.name || user.email }}
    </div>
    <div ng-if="user.projectContext.isExternal" class="guest-indication">
        (Guest)
    </div>
    <div class="job-title">{{ user.title }}</div>
</div>
<div ng-if="user.styledGroupTags" class="fields fs-censored">
    <div class="user-group-name name common-color-black common-break-long" data-automation="tnk-user-full-name">
        <tnk-text-ellipsis number-of-lines="1" tooltip="true" children="user.displayName"></tnk-text-ellipsis>
    </div>
    <span class="common-color-grey4" style="margin-left: 4px">
        {{
            user.members.length > 0
                ? ' (' + user.members.length + ' Users)'
                : user.displayName === '* deleted *'
                ? ''
                : '(Empty)'
        }}
    </span>
</div>
