<div class="feedback-modal">
    <div class="text-center" ng-show="loading">
        <i class="loading-large"></i>

        <h3>Sending...</h3>
    </div>
    <div ng-hide="loading">
        <div class="common-title">
            <i class="fa fa-life-ring common-color-success"></i>
            <span class="margin-normal-sm-h">Help / Feedback</span>
        </div>
        <div class="mark-modal-divider"></div>
        <div class="row">
            <div class="col-sm-5" ng-hide="hideInfo">
                <p><strong>We would love your input:</strong></p>

                <p>
                    questions, bug reports, feature requests or any general feedback helps.
                    <br />
                    Let us know what we can do to make Tonkean better.
                </p>
            </div>
            <div ng-class="{ 'col-sm-7': !hideInfo, 'col-sm-12': hideInfo }">
                <div>
                    <input
                        type="text"
                        class="form-control"
                        id="subject"
                        ng-model="data.subject"
                        maxlength="255"
                        placeholder="Subject (optional but helpful)"
                    />
                </div>

                <div>
                    <textarea
                        class="margin-normal-sm-v form-control"
                        rows="10"
                        id="description"
                        maxlength="2000"
                        placeholder="Describe your request or feedback"
                        ng-model="data.description"
                    ></textarea>
                </div>
            </div>
        </div>

        <div class="mark-modal-divider"></div>
        <div class="clearfix">
            <button class="btn btn-primary mark-modal-btn" ng-click="submit()">Send</button>
            <button class="btn btn-default mark-modal-btn" ng-click="$dismiss()">Cancel</button>
            <span class="text-danger common-size-xxs" ng-if="error">{{ error | error }}</span>
        </div>
    </div>
</div>
