const validateParentTriggerChannelResponse = (validationObject, definition, parentTrigger) => {
    if (
        definition?.sendResponseToChannel &&
        (!parentTrigger.node.customTriggerActions?.length ||
            (!parentTrigger.node.customTriggerActions[0].customTriggerActionDefinition.notificationSettings &&
                !parentTrigger.node.customTriggerActions[0].customTriggerActionDefinition.sendResponseToChannel))
    ) {
        validationObject.noReplyTarget = 'Must select who to send the reply to.';
    }
};

export default validateParentTriggerChannelResponse;
