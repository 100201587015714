import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';

const addButtonBlockCreateAfterOptionCanCreate: CreateAfterOptionCanCreate = (childImpacts, configuredLogic) => {
    return (
        !configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition
            ?.personEmailExpressionDefinition ||
        configuredLogic?.node?.customTriggerActions?.[0]?.customTriggerActionDefinition?.sendTo !== 'EXTERNAL'
    );
};

export default addButtonBlockCreateAfterOptionCanCreate;
