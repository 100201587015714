import { Formik } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
    aggregationTypeToDisplayName,
    numericLineItemWidgetAggregationTypes,
    temporalLineItemWidgetAggregationTypes,
    textualLineItemWidgetAggregationTypes,
    LineItemWidgetAggregationType,
} from '../../entities';
import type { LineItemAggregation } from '../../entities';

import { FieldSelector } from '@tonkean/fields';
import {
    Checkbox,
    Field,
    H3,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    SimpleSelect,
    withModal,
} from '@tonkean/infrastructure';
import type { FieldDefinition } from '@tonkean/tonkean-entities';
import { FieldType, FormDefinitionType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { yupEnum } from '@tonkean/utils';

const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const aggregationModalSchema = Yup.object({
    title: Yup.string()
        .min(3, 'minimum 3 characters')
        .when('aggregation', {
            is: LineItemWidgetAggregationType.NONE,
            then: Yup.string(),
            otherwise: Yup.string().required("Title can't be empty"),
        }),

    outputFieldDefinitionId: Yup.string(),
    hide: Yup.boolean(),

    aggregation: yupEnum(LineItemWidgetAggregationType, 'Wrong aggregation format').required(
        "Aggregation type can't be empty",
    ),
});

const typesToInclude: FormDefinitionType[] = [FormDefinitionType.MANUAL];

interface Props {
    fieldDefinitions: FieldDefinition[];
    loading: boolean;
    initialValues: LineItemAggregation | undefined;
    fieldName: string;
    fieldType: FieldType;

    onSubmit(
        aggregation: LineItemWidgetAggregationType,
        title: string,
        outputFieldDefinitionId: FieldDefinition['id'] | undefined,
        hide: boolean | undefined,
    ): void;
}

const fieldTypeToAggregationTypes = {
    [FieldType.Number]: numericLineItemWidgetAggregationTypes,
    [FieldType.Date]: temporalLineItemWidgetAggregationTypes,
};

const LineItemsAddAggregationModal: React.FC<Props> = ({
    onSubmit,
    initialValues,
    fieldName,
    fieldDefinitions,
    fieldType,
    loading,
}) => {
    // Constructing list of object for select component.
    const aggregationOptions = useMemo(() => {
        const options: LineItemWidgetAggregationType[] =
            fieldType in fieldTypeToAggregationTypes
                ? fieldTypeToAggregationTypes[fieldType]
                : textualLineItemWidgetAggregationTypes;

        return options.map((option) => ({
            value: option,
            label: aggregationTypeToDisplayName[option],
        }));
    }, [fieldType]);

    return (
        <Formik
            onSubmit={(values) => {
                onSubmit(values.aggregation, values.title, values.outputFieldDefinitionId, values.hide);
            }}
            validationSchema={aggregationModalSchema}
            initialValues={
                (initialValues && initialValues) || {
                    title: '',
                    aggregation: LineItemWidgetAggregationType.NONE,
                    outputFieldDefinitionId: undefined,
                    hide: false,
                }
            }
        >
            {({ values, setFieldValue }) => (
                <ModalForm>
                    <ModalHeader>Aggregation options</ModalHeader>
                    <ModalBody>
                        <FieldsWrapper>
                            <Field
                                label={
                                    <H3 $color={Theme.colors.gray_700} $bold>
                                        Select aggregation function
                                    </H3>
                                }
                            >
                                <SimpleSelect
                                    onChange={(selected) => {
                                        if (selected && selected !== values.aggregation) {
                                            setFieldValue(
                                                'title',
                                                `${aggregationTypeToDisplayName[selected]} Of ${fieldName}`,
                                            );
                                        }
                                    }}
                                    name="aggregation"
                                    options={aggregationOptions}
                                />
                            </Field>

                            {values.aggregation !== LineItemWidgetAggregationType.NONE && (
                                <>
                                    <Field
                                        label={
                                            <H3 $color={Theme.colors.gray_700} $bold>
                                                Title
                                            </H3>
                                        }
                                    >
                                        <Input name="title" size={InputSize.LARGE} />
                                    </Field>

                                    <Field
                                        label={
                                            <H3 $color={Theme.colors.gray_700} $bold>
                                                Store aggregate in field
                                            </H3>
                                        }
                                    >
                                        <FieldSelector
                                            name="outputFieldDefinitionId"
                                            fieldsDefinitions={fieldDefinitions}
                                            loading={loading}
                                            typesToInclude={typesToInclude}
                                            isClearable
                                        />
                                    </Field>

                                    {values.outputFieldDefinitionId && (
                                        <Checkbox name="hide">
                                            <H3 $color={Theme.colors.gray_700} $bold>
                                                Hide aggregate value
                                            </H3>
                                        </Checkbox>
                                    )}
                                </>
                            )}
                        </FieldsWrapper>
                    </ModalBody>

                    <ModalFooterActions
                        saveButtonProps={{ highlighted: true }}
                        cancelButtonProps={{ highlighted: true, outlined: true, cancel: false }}
                        border
                    />
                </ModalForm>
            )}
        </Formik>
    );
};

export default withModal(LineItemsAddAggregationModal, {
    size: ModalSize.MEDIUM,
    fixedWidth: true,
});
