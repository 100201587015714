import styled from 'styled-components';

import { Theme } from '@tonkean/tui-theme';

const SearchWidgetInnerStateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px 0px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid ${Theme.colors.gray_300};
    background-color: ${Theme.colors.basicBackground};
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
`;

export default SearchWidgetInnerStateContainer;
