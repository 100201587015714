import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import ProjectIntegrationEntityFieldMetadataModalBodyForm from './ProjectIntegrationEntityFieldMetadataModalBodyForm';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    FormikHelpers,
    H3,
    Modal,
    ModalFooter,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
} from '@tonkean/infrastructure';
import type {
    ProjectIntegrationEntityFieldMetadata,
    ProjectIntegrationEntityFieldMetadataSubField,
} from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';

const StyledModalFooter = styled(ModalFooter)`
    padding: 0;
`;

const MAX_INPUT_LENGTH = 255;
const ValidationSchema = Yup.object({
    fieldName: Yup.string()
        .required('A field name is required')
        .max(MAX_INPUT_LENGTH, `Field name is too long. Reduce the name to ${MAX_INPUT_LENGTH} characters or less`),
    displayName: Yup.string()
        .required('A field name is required')
        .max(MAX_INPUT_LENGTH, `Display name is too long. Reduce the name to ${MAX_INPUT_LENGTH} characters or less`),
    fieldType: Yup.string().max(
        MAX_INPUT_LENGTH,
        `Field type is too long. Reduce the name to ${MAX_INPUT_LENGTH} characters or less`,
    ),
    isCustomFieldToggle: Yup.boolean().default(false).required('A is Custom Field is required'),
    shouldIncludeInCreateActionToggle: Yup.boolean().default(false).required('A include Create Actions is required'),
    isRequiredInCreateActionToggle: Yup.boolean().default(false).required('A required In Create is required'),
    shouldIncludeInUpdateAndCustomActionToggle: Yup.boolean()
        .default(false)
        .required('A include In Custom Update Actions is required'),
    subFields: Yup.array(),
});
export type FormElements = Yup.InferType<typeof ValidationSchema>;

interface EditCustomFieldModalProps {
    open: boolean;
    onClose: () => void;
    fieldName: string;
    field: ProjectIntegrationEntityFieldMetadata | null | undefined;
    onSubmit: (values: FormElements) => void;
    loading?: boolean;
    error?: ErrorResponseType;
}

const EditCustomFieldModal: React.FC<EditCustomFieldModalProps> = ({
    open,
    onClose,
    field,
    fieldName,
    onSubmit,
    loading,
    error,
}) => {
    const [subFields, setSubFields] = useState<ProjectIntegrationEntityFieldMetadataSubField[]>([]);
    const [subFieldsValidationError, setSubFieldsValidationError] = useState<string | undefined>(undefined);

    const [{ loading: subFieldsLoading, error: subFieldsError }, getProjectIntegrationCustomFieldSubFields] =
        useLazyTonkeanService('getProjectIntegrationCustomFieldSubFields');

    useEffect(() => {
        if (!!field) {
            getProjectIntegrationCustomFieldSubFields(field.id, field.projectIntegrationId).then((data) => {
                setSubFields(data.entities);
            });
        }
    }, [field, getProjectIntegrationCustomFieldSubFields]);

    const editSubField = useCallback(
        (index: number, id: string, newName: string) => {
            if (subFieldsValidationError) {
                setSubFieldsValidationError(undefined);
            }
            setSubFields((prevSubFields) =>
                prevSubFields.map((field, i) => (i === index ? { ...field, name: newName } : field)),
            );
        },
        [setSubFields, setSubFieldsValidationError, subFieldsValidationError],
    );

    const removeSubField = useCallback(
        (index: number) => {
            if (subFieldsValidationError) {
                setSubFieldsValidationError(undefined);
            }
            setSubFields((prevSubFields) => prevSubFields.filter((_, i) => i !== index));
        },
        [setSubFields, setSubFieldsValidationError, subFieldsValidationError],
    );

    const addNewSubField = useCallback(() => {
        setSubFields((prevSubFields) => [
            ...prevSubFields,
            {
                id: 'newId',
                name: '',
            },
        ]);
    }, []);

    const validateSubFields = useCallback(() => {
        const names = subFields.map((field) => field.name);

        const nameIsBlank = names.some((name) => !name.trim());
        if (nameIsBlank) {
            setSubFieldsValidationError('Sub field names must not be blank');
            return { subFields: 'Sub fields names must not be blank' };
        }

        const hasRecurringNames = names.length !== new Set(names).size;
        if (hasRecurringNames) {
            setSubFieldsValidationError('Sub fields must have unique names');
            return { subFields: 'Sub fields must have unique names' };
        }

        setSubFieldsValidationError(undefined);
    }, [subFields]);

    return (
        <Modal open={open} onClose={onClose} size={ModalSize.LARGE} fixedWidth>
            <Formik
                initialValues={{
                    isCustomFieldToggle: !!field?.isCustomField,
                    collectedFieldName: field ? field?.collectedFieldName : fieldName,
                    fieldName: field?.generatedJsonFieldName || '',
                    displayName: field?.displayName || '',
                    fieldType: field?.fieldType || '',
                    shouldIncludeInCreateActionToggle: !!field?.shouldIncludeInCreateAction,
                    isRequiredInCreateActionToggle: !!field?.isRequiredInCreateAction,
                    shouldIncludeInUpdateAndCustomActionToggle: !!field?.shouldIncludeInUpdateAndCustomAction,
                    subFields: subFields || [],
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values) => {
                    values = { ...values, subFields };
                    onSubmit(values);
                }}
                validate={(values) => {
                    if (subFields.length > 0) {
                        return validateSubFields();
                    }
                }}
            >
                <FormikHelpers>
                    <ModalForm>
                        <ModalHeader>
                            <H3>{`Edit '${field ? field?.collectedFieldName : fieldName}'`}</H3>
                        </ModalHeader>

                        <ProjectIntegrationEntityFieldMetadataModalBodyForm
                            subFieldsError={subFieldsError}
                            subFieldsLoading={subFieldsLoading}
                            subFields={subFields}
                            subFieldsValidationError={subFieldsValidationError}
                            addSubField={addNewSubField}
                            removeSubField={removeSubField}
                            editSubField={editSubField}
                        />
                        <StyledModalFooter align="right" border>
                            <ModalFooterActions saveDisabled={loading} onCancel={onClose} error={error} />
                        </StyledModalFooter>
                    </ModalForm>
                </FormikHelpers>
            </Formik>
        </Modal>
    );
};

export default EditCustomFieldModal;
