import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import ViewDataAddRecordModal from './ViewDataAddRecordModal';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { Field } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

styled(Field)`
    margin-bottom: 20px;
`;
interface Props {
    entityName: string;
    projectIntegrationEntityId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ENTITY>;
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    projectIntegrationUniqueType: string;
    onSubmit(): void;
}

const ViewDataAddRecord: React.FC<Props> = ({
    entityName,
    projectIntegrationEntityId,
    projectIntegrationId,
    projectIntegrationUniqueType,
    onSubmit,
}) => {
    const [isAddRecordModalOpen, setIsAddRecordModalOpen] = useState(false);

    const { data: projectIntegrationSummaries } = useTonkeanService(
        'getProjectIntegrationEntitySummaries',
        projectIntegrationId,
    );

    const isAddRecordsEnabled = useMemo<boolean>(
        () =>
            !!projectIntegrationSummaries?.entities?.find(
                (projectIntegrationEntity) => projectIntegrationEntity.displayName === entityName,
            )?.isAddRecordsEnabled,
        [entityName, projectIntegrationSummaries?.entities],
    );

    return (
        <>
            {isAddRecordsEnabled && (
                <>
                    <Button size={ButtonSize.SMALL} onClick={() => setIsAddRecordModalOpen(true)} outlined>
                        + Add {entityName}
                    </Button>
                    <ViewDataAddRecordModal
                        entityName={entityName}
                        open={isAddRecordModalOpen}
                        onClose={() => setIsAddRecordModalOpen(false)}
                        projectIntegrationEntityId={projectIntegrationEntityId}
                        projectIntegrationId={projectIntegrationId}
                        projectIntegrationUniqueType={projectIntegrationUniqueType}
                        onSubmit={onSubmit}
                    />
                </>
            )}
        </>
    );
};

export default ViewDataAddRecord;
