<section class="projects-list container mod-main mod-padding">
    <div ng-if="!loadingProjects" ng-hide="error">
        <header class="flex-vmiddle flex-xs-col">
            <div class="flex-grow">
                <h1>Tonkean Boards</h1>

                <p>Below are boards you have access to. Click to access them or create a new one.</p>
            </div>
            <div ng-if="data.showCreateNewBoard">
                <a
                    class="btn"
                    ng-class="$root.isMobile ? 'btn-default' : 'btn-primary btn-lg'"
                    ui-sref="products.new({type:'private'})"
                    data-automation="product-list-create-new-board"
                    tnk-require
                    tnk-require-non-partial
                    analytics-on
                    analytics-category="Manage Teams"
                    analytics-event="Create Another"
                    analytics-label="Private"
                >
                    Create another
                    <strong>Board</strong>
                </a>
            </div>
        </header>
        <hr class="visible-xs-block" />
        <div class="flex-vmiddle">
            <div class="flex-grow"></div>
            <!-- Search box -->
            <div class="flex-vmiddle" ng-if="showSearch()">
                <div ng-if="data.fetchingProjects">
                    <i class="loading-small invisible"></i>
                </div>
                <span class="svg-icon-sm flex-no-shrink common-color-grey margin-right-xs">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/search.svg"></tnk-icon>
                </span>
                <input
                    autofocus
                    type="search"
                    class="form-control common-no-border common-no-outline search-text-box padding-left-none"
                    id="projectSearch"
                    placeholder="Search"
                    ng-class="{ 'common-color-grey': utils.isNullOrUndefined(data.projectsQuery) }"
                    ng-change="onSearchChanged(data.projectsQuery)"
                    ng-model="data.projectsQuery"
                    data-automation="product-list-search"
                />
            </div>
        </div>
        <section ng-show="data.projectsLoaded.length">
            <div>
                <div class="project" ng-repeat="project in data.projectsLoaded">
                    <div
                        class="project-name"
                        ui-state="getProjectIdStateName()"
                        ui-state-params="getProjectIdStateParams(project.id)"
                        ng-click="pm.selectProject(project)"
                        analytics-on="click"
                        analytics-category="Board"
                        analytics-event="Open"
                        analytics-label="Manage"
                        data-automation="project-list-project-name"
                    >
                        {{ project.name }}
                    </div>

                    <div class="project-details">
                        <span class="title">By {{ project.creator.name }}</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <section class="no-products" ng-if="!loadingProjects" ng-show="pm.projects.length === 0">
        <header>Welcome to Tonkean!</header>
        <div class="sub-header">Start by configuring your team</div>
        <a
            class="new-project-btn margin-none"
            ui-sref="loginCreate"
            analytics-on
            analytics-category="Manage Teams"
            analytics-event="Create First"
            analytics-label="Private"
        >
            Create new Board
        </a>
        <!--<div class="no-products-info">-->
        <!--</div>-->
    </section>
    <div ng-show="pm.loading.projects" class="common-page-load">
        <i class="loading-full-circle"></i>
    </div>
    <div ng-show="data.showError" class="alert alert-danger text-center" role="alert">
        <div>Failed to get projects from server</div>
        <!--<div>{{pm.error.projects}}</div>-->
    </div>
</section>
<div class="flex-vmiddle padding-right-md">
    <div class="flex-grow"></div>
    <div ng-if="data.hasMore">
        <!-- Load more link -->
        <a
            ng-disabled="!data.hasMore"
            ng-click="fetchNextPage()"
            class="common-color-blue-grey common-size-s pointer btn btn-default"
        >
            Load more
        </a>
        <!-- Loading-->
        <i class="loading-small margin-left-xs" ng-if="data.loadingAnotherPage"></i>
    </div>
    <div class="flex-grow"></div>
</div>
