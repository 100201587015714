import RichTextEditorInputConfigurationEditor from './RichTextEditorInputConfigurationEditor';
import RichTextEditorInputWidget from './RichTextEditorInputWidget';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';

const RichTextEditorInputWidgetPackage: ItemInterfaceWidgetPackage<ItemInterfaceWidgetConfiguration> = {
    displayComponent: RichTextEditorInputWidget,
    configurationComponent: RichTextEditorInputConfigurationEditor,
};

export default RichTextEditorInputWidgetPackage;
