import React from 'react';

import { PencilNewIcon } from '@tonkean/svg';
import type { ItemInterface } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';

interface Props {
    itemInterface: ItemInterface;
    onEditClick: () => void;
}
const RichTextEditorHeaderActions: React.FC<Props> = ({ itemInterface, onEditClick }) => {
    return (
        <Button
            data-automation="rich-text-editor-header-edit-action"
            backgroundColor={itemInterface.themeConfiguration.headerBackgroundColor}
            onClick={onEditClick}
            flex
            highlighted
        >
            <PencilNewIcon />
            Edit
        </Button>
    );
};

export default RichTextEditorHeaderActions;
