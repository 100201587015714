import type { Placement } from '@popperjs/core';
import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CursorClickIcon } from '../../../../../../images/icons/cursor-click.svg';
import { ReactComponent as FilterIcon } from '../../../../../../images/icons/filter.svg';
import { ReactComponent as FormAnsweredIcon } from '../../../../../../images/icons/form-answered.svg';
import { ReactComponent as InterfaceSubmittedIcon } from '../../../../../../images/icons/interface-submitted.svg';

import { IconSvg, Menu, MenuItem } from '@tonkean/infrastructure';
import type { LogicBlockConfigDefinition } from '@tonkean/logic-block-configs';
import { logicBlockConfigs } from '@tonkean/logic-block-configs';
import { LogicBlockConfigType } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';
import type { Color } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import utils, { colorSvg } from '@tonkean/utils';

const MenuTitle = styled(MenuItem)`
    min-width: 155px;
    font-weight: 500;
    font-size: ${FontSize.MSMALL_13};
    margin: 5px 9px;
    padding: 0;
`;

const StyledMenuItem = styled(MenuItem)`
    font-size: ${FontSize.MSMALL_13};
    padding: 5px 10px;
`;

const MenuItemIconBlock = styled.div<{ $backgroundColor: Color }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 10px;
    border-radius: 5px;
    background: ${({ $backgroundColor }) => $backgroundColor};
    ${colorSvg(Theme.colors.white)}
`;

const iconSize = 12.5;
const FAIcon = styled.div`
    width: ${iconSize};
    height: ${iconSize};
    color: ${Theme.colors.white};
`;

const MenuSpacer = styled.hr`
    margin: 5px;
`;

const StyledSVG = styled(IconSvg)`
    path {
        fill: ${Theme.colors.light_purple};
    }
`;

type AutonomousLogicBlockConfigTypes =
    | LogicBlockConfigType.AUTONOMOUS_MATCH_CONDITIONS
    | LogicBlockConfigType.AUTONOMOUS_ITEM_CREATED
    | LogicBlockConfigType.AUTONOMOUS_CREATED_FROM_FORM
    | LogicBlockConfigType.AUTONOMOUS_SCHEDULE
    | LogicBlockConfigType.AUTONOMOUS_FIELD_CHANGED
    | LogicBlockConfigType.MONITOR_TRACKS_ITEM_CREATED
    | 'MONITOR_TRACKS_MATCH_CONDITIONS'
    | LogicBlockConfigType.USER_INTERFACE_BUTTON_CLICK
    | LogicBlockConfigType.AUTONOMOUS_INTERFACE_SUBMITTED;

const autonomousSecondaryTypeToBlockTypeMap: Record<AutonomousLogicBlockConfigTypes, LogicBlockConfigDefinition> =
    utils.createMapFromArray(
        utils.findAllInObj(
            logicBlockConfigs,
            (blockType) => blockType.type === 'AUTONOMOUS' || blockType.type === 'MONITOR_TRACKS',
        ),
        'secondaryType',
    );

const typeToMenuItemConfig: Record<
    AutonomousLogicBlockConfigTypes,
    { icon: any; iconBackgroundColor: Color; dataAutomation?: string }
> = {
    AUTONOMOUS_MATCH_CONDITIONS: {
        icon: <IconSvg as={FilterIcon} size={iconSize} color={Theme.colors.white} />,
        iconBackgroundColor: '#2f84dc',
        dataAutomation: 'autonomous-type-dropDown-match-conditions-option',
    },
    AUTONOMOUS_CREATED_FROM_FORM: {
        icon: <IconSvg as={FormAnsweredIcon} size={iconSize} color={Theme.colors.white} />,
        iconBackgroundColor: `${Theme.colors.light_purple}`,
        dataAutomation: 'autonomous-type-dropDown-form-filled-option',
    },
    AUTONOMOUS_INTERFACE_SUBMITTED: {
        icon: <StyledSVG as={InterfaceSubmittedIcon} size={iconSize} color={Theme.colors.light_purple} />,
        iconBackgroundColor: `${Theme.colors.light_purple}`,
        dataAutomation: 'autonomous-type-dropDown-interface-submitted-option',
    },
    AUTONOMOUS_FIELD_CHANGED: {
        icon: <FAIcon className="fa fa-eye" />,
        iconBackgroundColor: '#40a7b8',
        dataAutomation: 'autonomous-type-dropDown-field-changed-option',
    },
    AUTONOMOUS_ITEM_CREATED: {
        icon: <FAIcon className="fa fa-flag" />,
        iconBackgroundColor: '#12d4b8',
        dataAutomation: 'autonomous-type-dropDown-item-is-added-option',
    },
    AUTONOMOUS_SCHEDULE: {
        icon: <FAIcon className="fa fa-clock-o" />,
        iconBackgroundColor: '#9ca6b2',
        dataAutomation: 'autonomous-type-dropDown-on-scheduled-option',
    },
    MONITOR_TRACKS_ITEM_CREATED: {
        icon: <FAIcon className="fa fa-flag" />,
        iconBackgroundColor: '#12d4b8',
    },
    MONITOR_TRACKS_MATCH_CONDITIONS: {
        icon: <IconSvg as={FilterIcon} size={iconSize} color={Theme.colors.basicBackground} />,
        iconBackgroundColor: '#2f84dc',
    },
    USER_INTERFACE_BUTTON_CLICK: {
        icon: <IconSvg as={CursorClickIcon} size={iconSize} color={Theme.colors.basicBackground} />,
        iconBackgroundColor: '#FF7A00',
        dataAutomation: 'autonomous-type-dropDown-user-interface-button-click-option',
    },
};
enum TypesSections {
    MONITOR_TRACKS_TYPES = 'MONITOR_TRACKS_TYPES',
    AUTONOMOUS_CONDITIONS_TYPES = 'AUTONOMOUS_CONDITIONS_TYPES',
    AUTONOMOUS_ON_ITEM_CREATED_TYPES = 'AUTONOMOUS_ON_ITEM_CREATED_TYPES',
    NON_INNER_AUTONOMOUS_TYPES = 'NON_INNER_AUTONOMOUS_TYPES',
    AUTONOMOUS_ON_INTAKE = 'AUTONOMOUS_ON_INTAKE',
}
const TYPES_SECTIONS: Record<TypesSections, AutonomousLogicBlockConfigTypes[]> = {
    MONITOR_TRACKS_TYPES: [LogicBlockConfigType.MONITOR_TRACKS_ITEM_CREATED, 'MONITOR_TRACKS_MATCH_CONDITIONS'],
    AUTONOMOUS_CONDITIONS_TYPES: [
        LogicBlockConfigType.AUTONOMOUS_MATCH_CONDITIONS,
        LogicBlockConfigType.AUTONOMOUS_FIELD_CHANGED,
    ],
    AUTONOMOUS_ON_ITEM_CREATED_TYPES: [LogicBlockConfigType.AUTONOMOUS_ITEM_CREATED],
    AUTONOMOUS_ON_INTAKE: [
        LogicBlockConfigType.AUTONOMOUS_INTERFACE_SUBMITTED,
        LogicBlockConfigType.AUTONOMOUS_CREATED_FROM_FORM,
    ],
    NON_INNER_AUTONOMOUS_TYPES: [
        LogicBlockConfigType.USER_INTERFACE_BUTTON_CLICK,
        LogicBlockConfigType.AUTONOMOUS_SCHEDULE,
    ],
};

interface Props extends StyledComponentsSupportProps {
    dropdownTitle: string;
    onAutonomousTypeSelected: (typeConfig: LogicBlockConfigDefinition) => void;
    isMonitorTrack?: boolean;
    isInnerAutonomous?: boolean;
    placement?: Placement;
}

const AutonomousTypesDropdown: React.FC<React.PropsWithChildren<Props>> = ({
    dropdownTitle,
    onAutonomousTypeSelected,
    isMonitorTrack,
    isInnerAutonomous,
    placement = 'auto-start',
    className,
    children,
}) => {
    const $rootScope = useAngularService('$rootScope');
    const [open, setOpen] = useState(false);

    const menusItems = useMemo(() => {
        const typesSections: AutonomousLogicBlockConfigTypes[][] = [];
        if (isMonitorTrack) {
            typesSections.push(TYPES_SECTIONS.MONITOR_TRACKS_TYPES);
        } else {
            typesSections.push(
                TYPES_SECTIONS.AUTONOMOUS_ON_ITEM_CREATED_TYPES,
                TYPES_SECTIONS.AUTONOMOUS_ON_INTAKE,
                TYPES_SECTIONS.AUTONOMOUS_CONDITIONS_TYPES,
            );
            if (!isInnerAutonomous) {
                typesSections.push(TYPES_SECTIONS.NON_INNER_AUTONOMOUS_TYPES);
            }
        }

        return typesSections.map((section, index) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`fragment-${index}`}>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <MenuSpacer key={`spacer-${index}`} />
                    {section
                        .filter((type) => {
                            const featureFlag = autonomousSecondaryTypeToBlockTypeMap[type].featureFlag;
                            return !featureFlag || $rootScope.features.currentProject?.[featureFlag];
                        })
                        .map((type) => (
                            <StyledMenuItem
                                key={type}
                                onClick={() => onAutonomousTypeSelected(autonomousSecondaryTypeToBlockTypeMap[type])}
                                data-automation={typeToMenuItemConfig[type].dataAutomation}
                            >
                                <MenuItemIconBlock $backgroundColor={typeToMenuItemConfig[type].iconBackgroundColor}>
                                    {typeToMenuItemConfig[type].icon}
                                </MenuItemIconBlock>
                                {autonomousSecondaryTypeToBlockTypeMap[type].title}
                            </StyledMenuItem>
                        ))}
                </React.Fragment>
            );
        });
    }, [$rootScope.features.currentProject, isInnerAutonomous, isMonitorTrack, onAutonomousTypeSelected]);

    return (
        <>
            <Menu
                show={open}
                onClose={() => setOpen(false)}
                placement={placement}
                menuItems={
                    <>
                        <MenuTitle key="title">{dropdownTitle}</MenuTitle>
                        {menusItems}
                    </>
                }
            >
                <Clickable className={className} onClick={() => setOpen(true)}>
                    {children}
                </Clickable>
            </Menu>
        </>
    );
};

export default AutonomousTypesDropdown;
