import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as TriggerIcon } from '../../../../../../../images/icons/lightning.svg';
import HistoryContext from '../../../../entities/HistoryContext';

import { TextEllipsis } from '@tonkean/infrastructure';
import { createWorkerRunInfo } from '@tonkean/infrastructure';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { WorkerRunReason } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const InfoRow = styled.div`
    display: flex;
    align-items: center;
    line-height: 14px;
    margin-bottom: 10px;
    margin-top: 5px;
`;

const ShowChildWorkerRunTitle = styled(Clickable)<{ isClickable?: boolean }>`
    text-decoration: ${({ isClickable }) => (isClickable === true ? 'underline' : 'none')};
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
    margin-left: 6px;
    border: none;
    max-width: 175px;
`;

interface Props {
    childWorkerRun: WorkerRun;
    isCurrentChildSameAsWorkerRun: boolean;
    onClick: () => void;
}

const SingleWorkerRunFlowRunInnerChildTitle: React.FC<Props> = ({
    childWorkerRun,
    isCurrentChildSameAsWorkerRun,
    onClick: onClickProp,
}) => {
    const { getState, updateState } = useContext(HistoryContext);

    const state = useMemo(() => {
        return getState({ workerRunInfo: createWorkerRunInfo(childWorkerRun.id, childWorkerRun.startTime) });
    }, [childWorkerRun.id, childWorkerRun.startTime, getState]);

    const isClickable = !isCurrentChildSameAsWorkerRun;

    const onClick = () => {
        if (isClickable) {
            onClickProp();
            updateState({ workerRunInfo: createWorkerRunInfo(childWorkerRun.id, childWorkerRun.startTime) });
        }
    };

    const childDisplayTitle = useMemo(() => {
        if (childWorkerRun.workerRunReason === WorkerRunReason.NEW_MONITORED_THREAD_MESSAGE) {
            return `New reply: ${childWorkerRun.resumingText}`;
        } else if (childWorkerRun.workerRunReason === WorkerRunReason.NEW_BUTTON_CLICK) {
            return `New button click`;
        } else {
            return `New item: ${childWorkerRun.initiativeTitle}`;
        }
    }, [childWorkerRun.initiativeTitle, childWorkerRun.resumingText, childWorkerRun.workerRunReason]);

    return (
        <InfoRow>
            <TriggerIcon />
            <ShowChildWorkerRunTitle
                state={isClickable && state.name}
                params={isClickable && state.params}
                onClick={isClickable && onClick}
                isClickable={isClickable}
                dontChangeState
            >
                <TextEllipsis numberOfLines={1} tooltip>
                    {childDisplayTitle}
                </TextEllipsis>
            </ShowChildWorkerRunTitle>
        </InfoRow>
    );
};

export default SingleWorkerRunFlowRunInnerChildTitle;
