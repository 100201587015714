import React, { useCallback } from 'react';
import styled from 'styled-components';

import SmartConversationReply from './SmartConversationReply';
import SmartSearchAssistantToolMessageView from './SmartSearchAssistantToolMessageView';
import type { SmartConversationManager } from '../hooks';

import { IconSvg, LoadingDotsSmooth, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { useResizeObserver } from '@tonkean/infrastructure';
import { SmartSearchIcon } from '@tonkean/svg';
import type { SmartConversationContentType, SmartConversationMessage } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
`;

/**
 * We need a separate scroll container because otherwise the resize observer doesnt know that the height changed if its in overflow
 */
const ScrollContainer = styled.div`
    overflow: auto;
`;

const MessagesContainer = styled.div`
    display: grid;
    row-gap: 20px;
`;

const LoadingContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const ResponseIcon = styled(IconSvg)`
    flex-shrink: 0;
`;

interface Props<T extends SmartConversationContentType> extends StyledComponentsSupportProps {
    smartConversationManager: SmartConversationManager<T>;
    messageTransformer: (message: SmartConversationMessage<T>, index: number) => JSX.Element;
    loading: boolean;
    error: any;
    showReplyBar: boolean;
    takeFullHeight: boolean;
    showTalkToExpert?: boolean;
    needHelpClicked?: () => void;
}

const SmartConversationView = <T extends SmartConversationContentType>({
    smartConversationManager,
    messageTransformer,
    loading,
    error,
    showReplyBar,
    takeFullHeight,
    className,
    showTalkToExpert,
    needHelpClicked,
}: Props<T>) => {
    const { setNode } = useResizeObserver((entry) => {
        entry.target.scrollIntoView({ behavior: 'smooth', block: 'end' });
    });

    const onReplyCallback = useCallback(
        (message: string) => {
            if (message.trim() !== '') {
                const lastMessageConversationId =
                    smartConversationManager.messages[smartConversationManager.messages.length - 1]?.conversationId ||
                    '';

                smartConversationManager.callReplyMethod(message, lastMessageConversationId, false, false);
            }
        },
        [smartConversationManager],
    );

    const lastAssistantToolMessage =
        smartConversationManager.assistantToolMessages?.[smartConversationManager.assistantToolMessages?.length - 1];

    const displayedMessages = smartConversationManager.messages.filter((message) => !message.response.hidden);

    return (
        <Container className={className}>
            {/**
             * There will be a big gap between the loading and the start of the component if there are no displayed messages,
             * because there is always the first user message which is hidden. And the flex gap would work on it  */}
            {displayedMessages.length > 0 && (
                <ScrollContainer>
                    <MessagesContainer ref={setNode}>
                        {smartConversationManager.messages.map(
                            (message, index) => !message.response.hidden && messageTransformer(message, index),
                        )}
                    </MessagesContainer>
                </ScrollContainer>
            )}

            {loading && (
                <LoadingContainer>
                    <ResponseIcon as={SmartSearchIcon} size={18} />
                    {lastAssistantToolMessage ? (
                        <SmartSearchAssistantToolMessageView assistantToolMessage={lastAssistantToolMessage} />
                    ) : (
                        <LoadingDotsSmooth size={10} centered />
                    )}
                </LoadingContainer>
            )}
            {error && <SimpleErrorStateMessage error={error} showSmallError />}
            {takeFullHeight && <div className="flex-grow" />}
            {showReplyBar && (
                <SmartConversationReply
                    onReply={onReplyCallback}
                    loading={loading}
                    showTalkToExpert={showTalkToExpert}
                    needHelpClicked={needHelpClicked}
                />
            )}
        </Container>
    );
};

export default SmartConversationView;
