import { useAngularService } from 'angulareact';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import PredefinedConnectionCrudModal from './PredefinedConnectionCrudModal';
import { Cell } from './PredefinedConnectionSharedStyle';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToggle } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { SharedCredential } from '@tonkean/tonkean-entities';
import type { IntegrationType } from '@tonkean/tonkean-entities';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const IntegrationIconWrapper = styled.div`
    margin-right: 5px;
`;

const AccountNameCell = styled(Cell)`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
`;

const DataSourceTypeCell = styled(Cell)`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
`;

const ToggleCell = styled(Cell)<{ $isActive: boolean }>`
    color: ${({ $isActive }) => ($isActive ? Theme.colors.success : Theme.colors.warning)};
`;

const EditCell = styled(Cell)``;

const EditClickable = styled(ClickableLink)`
    color: ${Theme.colors.primaryActive};
    font-size: ${FontSize.SMALL_12};
    text-decoration-line: underline;
`;

interface Props {
    integrationType: IntegrationType;
    sharedCredential: SharedCredential;
    onPredefinedConnectionUpdated(): void;
    dataSourceConnection: DataSourceConnection;
}

const PredefinedConnectionsTableRowView: React.FC<Props> = ({
    integrationType,
    sharedCredential,
    onPredefinedConnectionUpdated,
    dataSourceConnection,
}) => {
    const [{ error, loading }, updatePredefinedConnection] = useLazyTonkeanService('updatePredefinedConnection');
    const [isCrudModalOpen, setIsCrudModalOpen] = useState(false);
    const integrations = useAngularService('integrations');
    const project = useProject();
    const [isEnabled, toggleIsEnabled] = useToggle(sharedCredential.enabled);

    // On change the isEnabled
    const toggleChangeIsEnabled = useCallback(async () => {
        try {
            toggleIsEnabled();
            await updatePredefinedConnection(
                project.id,
                sharedCredential.id,
                !isEnabled,
                sharedCredential.integration.id,
                sharedCredential.description,
                sharedCredential.authorizedUsers,
                sharedCredential.displayName,
                sharedCredential.connectionPermission,
            );
        } catch {
            toggleIsEnabled();
        }
    }, [toggleIsEnabled, updatePredefinedConnection, project.id, sharedCredential, isEnabled]);

    const integrationName =
        integrations.getIntegrationsConfig()[integrationType.toLowerCase()]?.displayName ||
        integrationType.toLowerCase();

    return (
        <>
            <React.Fragment key={sharedCredential.id}>
                <AccountNameCell>{sharedCredential.displayName}</AccountNameCell>

                <DataSourceTypeCell>
                    <IntegrationIconWrapper>
                        <ProjectIntegrationIcon width={14} integrationType={integrationType} />
                    </IntegrationIconWrapper>

                    {integrationName}
                </DataSourceTypeCell>

                <ToggleCell $isActive={sharedCredential.enabled}>
                    {sharedCredential.enabled ? 'Active' : 'Inactive'}
                </ToggleCell>

                <EditCell>
                    <EditClickable
                        data-automation="predefined-connections-table-row-view-edit-row"
                        onClick={() => {
                            setIsCrudModalOpen(true);
                        }}
                    >
                        Edit
                    </EditClickable>
                </EditCell>
            </React.Fragment>

            {isCrudModalOpen && (
                <PredefinedConnectionCrudModal
                    sharedCredential={sharedCredential}
                    integrationType={integrationType}
                    isOpen={isCrudModalOpen}
                    onClose={() => setIsCrudModalOpen(false)}
                    onPredefinedConnectionUpdated={onPredefinedConnectionUpdated}
                    dataSourceConnection={dataSourceConnection}
                />
            )}
        </>
    );
};

export default PredefinedConnectionsTableRowView;
