import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

export interface WorkdayReportsAuthenticationProps extends CustomizedAuthenticationComponentProps {}

const WorkdayReportsAuthenticationComponent: React.FC<WorkdayReportsAuthenticationProps> = ({
    authenticate,
    reportError,
}) => {
    const [report, setReport] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [user, setUser] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [uniqueIdField, setUniqueIdField] = useState<string>('');
    const [itemTitleField, setItemTitleField] = useState<string>('');

    const authenticateWithUserAndOrg = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                report,
                url,
                user,
                password,
                uniqueIdField,
                itemTitleField,
            };
            authenticate(config, {}, `Workday Reports (${report})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithUserAndOrg}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="login-api-report" className="col-sm-3 control-label">
                            Report Name
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={report}
                                className="form-control"
                                id="login-api-report"
                                onChange={({ target }) => setReport(target.value)}
                                autoComplete="off"
                                placeholder="Name"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-url" className="col-sm-3 control-label">
                            Report Web Service URL
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={url}
                                className="form-control"
                                id="login-api-url"
                                onChange={({ target }) => setUrl(target.value)}
                                autoComplete="off"
                                placeholder="URL"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-user" className="col-sm-3 control-label">
                            Username
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={user}
                                className="form-control"
                                id="login-api-user"
                                onChange={({ target }) => setUser(target.value)}
                                autoComplete="off"
                                placeholder="Username"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-password" className="col-sm-3 control-label">
                            Password
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={password}
                                className="form-control"
                                onChange={({ target }) => setPassword(target.value)}
                                id="login-api-password"
                                autoComplete="off"
                                placeholder="Password"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-unique-id-field" className="col-sm-3 control-label">
                            Unique Identifier field
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={uniqueIdField}
                                className="form-control"
                                onChange={({ target }) => setUniqueIdField(target.value)}
                                id="login-api-unique-id-field"
                                autoComplete="off"
                                placeholder="Unique Identifier field"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-item-title-field" className="col-sm-3 control-label">
                            Item Title field
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={itemTitleField}
                                className="form-control"
                                onChange={({ target }) => setItemTitleField(target.value)}
                                id="login-api-item-title-field"
                                autoComplete="off"
                                placeholder="Item Title field"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default WorkdayReportsAuthenticationComponent;
