import { useMemo, useState } from 'react';

import useCalculatedFiltersQueryDefinition from '../../InnerItemsWidgetModule/hooks/useCalculatedFiltersQueryDefinition';
import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';

import type { BasicQueryDefinition, FieldFilter, ItemInterfaceWidget } from '@tonkean/tonkean-entities';
import { FieldQueryType } from '@tonkean/tonkean-entities';

const useUserFilters = (widget: ItemInterfaceWidget<LineItemsWidgetConfiguration>) => {
    const [endUserFilters, setEndUserFilters] = useState<FieldFilter[]>([]);

    const [endUserAdvancedQuery, setEndUserAdvancedQuery] = useState<BasicQueryDefinition>({
        query: {
            type: FieldQueryType.And,
            filters: [],
            queries: [],
        },
    });

    const endUserFiltersQueryDefinition = useMemo<BasicQueryDefinition>(() => {
        return {
            query: {
                type: FieldQueryType.And,
                filters: [...endUserFilters],
                queries: [{ ...endUserAdvancedQuery.query }],
            },
        };
    }, [endUserAdvancedQuery, endUserFilters]);

    const calculatedFiltersQueryDefinition = useCalculatedFiltersQueryDefinition(endUserFiltersQueryDefinition, widget);

    return useMemo(() => {
        return [
            calculatedFiltersQueryDefinition,
            endUserFilters,
            setEndUserFilters,
            endUserFiltersQueryDefinition,
            endUserAdvancedQuery,
            setEndUserAdvancedQuery,
        ] as const;
    }, [calculatedFiltersQueryDefinition, endUserAdvancedQuery, endUserFilters, endUserFiltersQueryDefinition]);
};

export default useUserFilters;
