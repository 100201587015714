import BotProjectedItemDispatcher from '../dispatchers/BotProjectedItemDispatcher';

/**
 * A map for action type names.
 */
export const BotProjectedItemActionTypes = {
    TOGGLE_SHOW_MORE_INITIATIVES: 'TOGGLE_SHOW_MORE_INITIATIVES',
    SET_REMINDER_DROPDOWN_TARGET: 'SET_REMINDER_DROPDOWN_TARGET',
};

/**
 * Our flux actions.
 */
export const BotProjectedItemActions = {
    toggleShowMoreInitiatives(sectionId, ownerId, isTrue) {
        BotProjectedItemDispatcher.dispatch({
            type: BotProjectedItemActionTypes.TOGGLE_SHOW_MORE_INITIATIVES,
            sectionId,
            ownerId,
            isTrue,
        });
    },

    setReminderDropdownTarget(sectionId, ownerId, isTrue) {
        BotProjectedItemDispatcher.dispatch({
            type: BotProjectedItemActionTypes.SET_REMINDER_DROPDOWN_TARGET,
            sectionId,
            ownerId,
            isTrue,
        });
    },
};

export default BotProjectedItemActions;
