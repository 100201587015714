import type { ColDef } from '@ag-grid-community/core';

import {
    EMPHASIZED_HEADER_CLASS_NAME,
    EMPTY_AND_REQUIRED_CLASS_NAME,
    FILLED_FILTER_ICON_CLASS_NAME,
    REQUIRED_HEADER_CLASS_NAME,
} from './customClassNames';
import isEditableColumn from './getIsEditableColumn';
import ColumnId from '../configs/config/columnId';
import type InitiativeRowData from '../entities/InitiativeRowData';

import { type FieldDefinitionKey, SpecialFieldsKey } from '@tonkean/tonkean-entities';

const getDueDateDefinition = (
    commonDef: Partial<ColDef<InitiativeRowData>>,
    fieldDefinitionKeyToName: Record<FieldDefinitionKey, string | undefined>,
    isEditable: boolean,
    enableFilter: boolean | undefined,
    shouldShowRequiredIndication: (fieldDefinitionKey: FieldDefinitionKey) => boolean,
    filteredColumnIds: string[] | undefined,
    isEmphasizeText: boolean | undefined,
    min: string | undefined,
    max: string | undefined,
    getIsFieldEmptyAndRequired: (fieldDefinitionKey: FieldDefinitionKey, row: InitiativeRowData) => boolean,
    drilldownColumnId: string | undefined,
) => {
    return {
        ...commonDef,
        headerName: fieldDefinitionKeyToName[SpecialFieldsKey.DUE_DATE] || 'Due Date',
        field: ColumnId.DueDate,
        type: 'resizableColumn',
        cellRenderer: 'dueDateCellRenderer',
        cellEditor: 'agDateCellEditor',
        editable: (params) => {
            return isEditableColumn(params.node.data?.dueDate, ColumnId.DueDate, isEditable, drilldownColumnId);
        },
        filter: enableFilter && 'agDateColumnFilter',
        equals: (date1, date2) => {
            if (date1 === undefined && date2 === undefined) {
                return true;
            }

            return new Date(date1).getTime() === new Date(date2).getTime();
        },
        headerClass: ({ column }) => {
            const classes: string[] = [];
            if (shouldShowRequiredIndication(SpecialFieldsKey.DUE_DATE)) {
                classes.push(REQUIRED_HEADER_CLASS_NAME);
            }
            if (isEmphasizeText) {
                classes.push(EMPHASIZED_HEADER_CLASS_NAME);
            }
            if (column?.getColId() && filteredColumnIds?.includes(column.getColId())) {
                classes.push(FILLED_FILTER_ICON_CLASS_NAME);
            }
            return classes;
        },
        cellEditorParams: {
            min,
            max,
        },
        cellClassRules: {
            [EMPTY_AND_REQUIRED_CLASS_NAME]: (params) => {
                return !!params.data && getIsFieldEmptyAndRequired(SpecialFieldsKey.DUE_DATE, params.data);
            },
        },
    } as ColDef<InitiativeRowData>;
};

export default getDueDateDefinition;
