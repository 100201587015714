import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo } from 'react';

import SystemInterfaceDisplay from './SystemInterfaceDisplay';
import ItemInterfacePermission from '../../../entities/ItemInterfacePermission';

import {
    useGetStateParams,
    useIsInSharedReport,
    useLazyAsyncMethod,
    useLazyTonkeanService,
    useTonkeanService,
} from '@tonkean/angular-hooks';
import {
    ItemInterfaceSection,
    NotFoundPage,
    useIsDataRestricted,
    useUserSolutionBusinessReportAccess,
} from '@tonkean/infrastructure';
import LoadingTonkean from '@tonkean/infrastructure/components/LoadingTonkean';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface ContainerProps {
    solutionReportId?: TonkeanId<TonkeanType.SOLUTION_BUSINESS_REPORT>;
    groupId?: TonkeanId<TonkeanType.GROUP>;
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;

    onBack?(): void;

    showBreadcrumbs?: boolean;
    showClosingButton?: boolean;
    originWidgetId?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
    showPoweredByTonkean?: boolean;
    showPanel?: boolean;
}

/**
 * This is the container component for the system interfaces.
 * It's responsible for fetching all necessary data for the SystemInterfaceDisplay.
 */
const SystemInterface: React.FC<ContainerProps> = (props) => {
    const trackHelper = useAngularService('trackHelper');
    const groupInfoManager = useAngularService('groupInfoManager');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const groupPermissions = useAngularService('groupPermissions');

    const isInSharedReport = useIsInSharedReport();

    const [stateSolutionReportId, stateGroupId, stateInitiativeId, stateOriginWidget] = useGetStateParams<
        [
            TonkeanId<TonkeanType.SOLUTION_BUSINESS_REPORT>,
            TonkeanId<TonkeanType.GROUP> | undefined,
            TonkeanId<TonkeanType.INITIATIVE>,
            TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>,
        ]
    >('solutionBusinessReportId', 'groupId', 'initiativeId', 'originWidget');

    const solutionReportId = props.solutionReportId || stateSolutionReportId;
    const initiativeId = props.initiativeId || stateInitiativeId;

    const [{ data: initiative, error: initiativeError, loading: initiativeLoading }, getInitiativeById] =
        useLazyAsyncMethod(trackHelper, 'getInitiativeById');

    const groupId = props.groupId || stateGroupId || initiative?.group.id;
    const originWidget = props.originWidgetId || stateOriginWidget;

    const [{ data: workflowVersion, error: workflowVersionError }, getGroupWorkflowVersion] = useLazyAsyncMethod<
        IHttpResponse<any>
    >(workflowVersionManager, 'getGroupWorkflowVersion');

    const [{ data: group, error: groupError }, getGroup] = useLazyAsyncMethod<IHttpResponse<any>>(
        groupInfoManager,
        'getGroup',
    );
    useEffect(() => {
        if (initiativeId && !initiative) {
            getInitiativeById(initiativeId);
        }
        if (groupId) {
            getGroup(groupId);
        }
    }, [getGroup, getInitiativeById, groupId, initiative, initiativeId]);

    const [{ data: solutionReport }, getSolutionReport] = useLazyTonkeanService('getSolutionBusinessReportById');

    const { data, error: itemInterfaceError } = useTonkeanService(
        'getItemInterfaceAndWidgetByInitiativeId',
        initiativeId,
        originWidget,
    );

    const itemInterface = data?.itemInterface;
    const itemInterfaceWidgets = data?.itemInterfaceWidgets;

    useEffect(() => {
        if (itemInterface && groupId) {
            getGroupWorkflowVersion(groupId, itemInterface.workflowVersionType);
        }
    }, [itemInterface, getGroupWorkflowVersion, groupId]);

    useEffect(() => {
        if (solutionReportId) {
            getSolutionReport(solutionReportId, isInSharedReport);
        }
    }, [getSolutionReport, isInSharedReport, solutionReportId]);

    const solutionReportPermissions = useUserSolutionBusinessReportAccess(solutionReport?.solutionBusinessReport);

    const canUserEditItem = useMemo(() => {
        const canUserEditItemsInGroup = group ? groupPermissions.isCurrentUserGroupCollaborator(group) : false;

        const canEditItemsInSolutionReport = solutionReportPermissions.EDIT;

        const userCanEditItem = solutionReportId ? canEditItemsInSolutionReport : canUserEditItemsInGroup;

        const isItemArchived = initiative?.isArchived;

        return userCanEditItem && !isItemArchived;
    }, [group, initiative?.isArchived, solutionReportId, solutionReportPermissions.EDIT, groupPermissions]);

    let permission: ItemInterfacePermission;
    if (canUserEditItem) {
        if (isInSharedReport) {
            permission = ItemInterfacePermission.USER_CAN_EDIT_EXISTING_ITEM;
        } else {
            permission = ItemInterfacePermission.USER_CAN_EDIT_ITEM;
        }
    } else {
        permission = ItemInterfacePermission.USER_CANT_EDIT_ITEM;
    }

    const showNotFoundError = useIsDataRestricted([
        initiativeError?.status,
        workflowVersionError?.status,
        groupError?.status,
        itemInterfaceError?.status,
    ]);

    if (showNotFoundError) {
        return (
            <React.Suspense
                fallback={
                    <div className="common-height-full flex mod-align-center mod-center mod-justify-center">
                        <i className="loading-large" />
                    </div>
                }
            >
                <NotFoundPage text="Oops, we can't find that page" dataAutomation="interface-not-found" />
            </React.Suspense>
        );
    }

    if (
        !initiative ||
        (solutionReportId && !solutionReport) ||
        !group ||
        !workflowVersion ||
        !itemInterface ||
        !itemInterfaceWidgets
    ) {
        return <LoadingTonkean />;
    }

    return (
        <SystemInterfaceDisplay
            initiativeId={initiativeId}
            solutionReport={solutionReport?.solutionBusinessReport}
            group={group}
            workflowVersion={workflowVersion}
            itemInterface={itemInterface}
            widgets={itemInterfaceWidgets}
            includedWidgetsSummaryByInterfaceId={data?.includedWidgetsSummaryByInterfaceId}
            onBack={props.onBack}
            showBreadcrumbs={props.showBreadcrumbs}
            showClosingButton={props.showClosingButton}
            permissions={permission}
            showPoweredByTonkean={props.showPoweredByTonkean}
            showPanel={props.showPanel}
            section={ItemInterfaceSection.MAIN}
        />
    );
};

export default SystemInterface;
