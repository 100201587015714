import { getIn, isEmptyArray, setIn } from 'formik';
import type { FormikErrors, FormikTouched } from 'formik/dist/types';
import { useContext } from 'react';

import FormikHelpersContext from './FormikHelpersContext';
import useFormikField from './useFormikField';

import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';

const useFormikArrayField = <T extends any[]>(field: string) => {
    type Item = T[number];

    const formikHelpers = useContext(FormikHelpersContext);
    const fieldOptions = useFormikField<T>(field);

    // Shamefully stolen from https://github.com/jaredpalmer/formik/blob/master/packages/formik/src/FieldArray.tsx#L152
    const updateArrayField = (
        fn: (prev: Item[]) => Item[],
        alterTouched: boolean | ((prev: FormikErrors<Item[]>) => FormikErrors<Item[]>),
        alterErrors: boolean | ((prev: FormikTouched<Item[]>) => FormikTouched<Item[]>),
    ) => {
        const { setFormikState } = formikHelpers!.getFormik();

        setFormikState((prevState) => {
            const updateErrors = typeof alterErrors === 'function' ? alterErrors : fn;
            const updateTouched = typeof alterTouched === 'function' ? alterTouched : fn;

            // values fn should be executed before updateErrors and updateTouched,
            // otherwise it causes an error with unshift.
            const values = setIn(prevState.values, field, fn(getIn(prevState.values, field)));

            let fieldError = alterErrors ? updateErrors(getIn(prevState.errors, field)) : undefined;
            let fieldTouched = alterTouched ? updateTouched(getIn(prevState.touched, field)) : undefined;

            if (isEmptyArray(fieldError)) {
                fieldError = undefined;
            }
            if (isEmptyArray(fieldTouched)) {
                fieldTouched = undefined;
            }

            return {
                ...prevState,
                values,
                errors: alterErrors ? setIn(prevState.errors, field, fieldError) : prevState.errors,
                touched: alterTouched ? setIn(prevState.touched, field, fieldTouched) : prevState.touched,
            };
        });
    };

    const push = useConstantRefCallback((...items: T) => {
        updateArrayField((arrayLike) => [...arrayLike, ...items], false, false);
    });

    const unshift = useConstantRefCallback((...items: Item[]) => {
        updateArrayField(
            (array) => (array ? [...items, ...array] : items),
            (array) => (array ? [undefined, ...array] : [undefined]),
            (array) => (array ? [undefined, ...array] : [undefined]),
        );
    });

    const remove = useConstantRefCallback((index: number) => {
        updateArrayField(
            (currentArray) => currentArray?.filter((_, currentItemIndex) => currentItemIndex !== index) as T,
            true,
            true,
        );
    });

    return { ...fieldOptions, unshift, push, remove };
};

export default useFormikArrayField;
