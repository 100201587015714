import React from 'react';

import { ReactComponent as SlackIconIcon } from '../../../../../images/icons/slack-icon.svg';
import { ReactComponent as WebIcon } from '../../../../../images/icons/web.svg';

import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    label: string;
}

const FormBuilderSelectIcon: React.FC<Props> = ({ label, className }) => {
    switch (label) {
        case 'Slack': {
            return <SlackIconIcon className={className} />;
        }
        case 'Web': {
            return <WebIcon className={className} />;
        }
        default: {
            return <></>;
        }
    }
};

export default FormBuilderSelectIcon;
