import React, { useContext, useMemo } from 'react';

import RichTextWidgetEditor from './RichTextWidgetEditor';
import { ItemInterfacePermission, ItemWidgetContext } from '../../../entities';
import { ItemWidget, ItemWidgetSidepaneTriggerZone } from '../../../WidgetModule';
import type RichTextWidgetConfiguration from '../RichTextWidgetConfiguration';

import type { HTMLEditorPlugin } from '@tonkean/editor';
import { CoreEditorSerialize, filterToCoreEditorPlugins } from '@tonkean/editor';
import type { WidgetBase } from '@tonkean/tonkean-entities';
import type { DataAutomationSupportProps } from '@tonkean/utils';

interface Props extends DataAutomationSupportProps {
    widget: WidgetBase<RichTextWidgetConfiguration>;
    permission: ItemInterfacePermission;
    editorPlugins: HTMLEditorPlugin[];
    headerActions?: JSX.Element;
}

const RichTextWidgetView: React.FC<Props> = ({ widget, permission, editorPlugins, headerActions }) => {
    const widgetContext = useContext(ItemWidgetContext);
    const editable = widgetContext?.isEditable && permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE;

    const coreEditorPlugins = useMemo(() => filterToCoreEditorPlugins(editorPlugins), [editorPlugins]);

    return (
        <ItemWidget
            noPaddingBody={editable}
            sidepaneTriggerZone={ItemWidgetSidepaneTriggerZone.HEADER}
            permission={permission}
            containerOverflowOverride="visible"
            noBackgroundBody={widget.configuration.hideWidgetBackground}
            dashedBorderBody={widget.configuration.hideWidgetBackground}
            headerActions={headerActions}
            disableMinHeight
        >
            {editable ? (
                <RichTextWidgetEditor widget={widget} plugins={editorPlugins} />
            ) : (
                <CoreEditorSerialize value={widget.configuration.content} plugins={coreEditorPlugins} />
            )}
        </ItemWidget>
    );
};

export default React.memo(RichTextWidgetView);
