import React, { useContext } from 'react';

import WorkflowVersionSelector from '../../../../../../infrastructure/components/WorkflowVersionSelector';
import HistoryContext from '../../../../entities/HistoryContext';

interface Props {
    value?: string;
    onChange(value?: string): void;
}

const WorkerRunsFiltersPopoverWorkflowVersion: React.FC<Props> = ({ value, onChange }) => {
    const { state } = useContext(HistoryContext);

    return <WorkflowVersionSelector groupId={state.groupId} value={value} onChange={onChange} />;
};

export default WorkerRunsFiltersPopoverWorkflowVersion;
