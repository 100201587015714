import useTonkeanQuery from '../utils/reactQuery/useTonkeanQuery';

import { getEmbedInterfaceByWidgetId, type GetEmbedInterfaceByWidgetIdResponse } from '@tonkean/network-service';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

const useTDLEmbedInterfaceByWidgetId = (
    projectId: TonkeanId<TonkeanType.PROJECT> | undefined,
    groupId: TonkeanId<TonkeanType.GROUP> | undefined,
    contractFieldId: TonkeanId<TonkeanType.CONTRACT_FIELD> | undefined,
    workflowVersionType: WorkflowVersionType | undefined,
) => {
    return useTonkeanQuery<GetEmbedInterfaceByWidgetIdResponse | undefined>({
        queryUniqueKey: 'getEmbedInterfaceByWidgetId',
        propsUniqueKeysArray: [projectId, groupId, contractFieldId, workflowVersionType],
        queryFn: async (networkService) => {
            return getEmbedInterfaceByWidgetId(
                networkService,
                projectId!,
                groupId!,
                contractFieldId!,
                workflowVersionType!,
            );
        },
        enabledFn: () => !!projectId && !!groupId && !!contractFieldId && !!workflowVersionType,
    });
};

export default useTDLEmbedInterfaceByWidgetId;
