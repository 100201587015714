import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import ItemInterfacePanel from './ItemInterfacePanel';
import ItemsDropdownNavigation from './ItemsDropdownNavigation';
import ItemInterfacePermission from '../../../entities/ItemInterfacePermission';
import { useRegisterItemInterfaceFieldDefinitionSummaryOnContext } from '../../../hooks';
import useItemInterfaceContextDataBuilder from '../../../hooks/useItemInterfaceContextDataBuilder';
import { getIsInWidgetBuilder } from '../../../WidgetModule';
import useGetWidgetsToDisplay from '../../../widgets/hooks/useGetWidgetsToDisplay';
import {
    useActivateOnLoadActions,
    useGetItemInterfaceData,
    useInitiativeNavigationContext,
    useItemInterfaceBreadcrumbs,
    usePollInitiativeData,
} from '../../hooks';
import usePluggableActionsToShowSettings from '../../hooks/usePluggableActionsToShowSettings';
import { ItemSystemInterfaceContext } from '../../utils/ItemSystemInterfaceContext';
import InterfaceHeaderCallToActionMenu from '../ItemInterface/InterfaceHeaderCallToActionMenu';
import type { InterfaceLiveDisplay } from '../ItemInterface/InterfaceLiveDisplay';
import ItemInterfaceHeaderLogo from '../ItemInterface/ItemInterfaceHeaderLogo';
import ItemInterfaceView from '../ItemInterface/ItemInterfaceView';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { TnkParentNavigation } from '@tonkean/angular-to-react-components';
import { CollaborationActionsPlugin, InterfaceViewers } from '@tonkean/collaboration';
import {
    Breadcrumbs,
    Breakpoint,
    IconSvg,
    ItemInterfaceContext,
    ItemInterfaceFieldDefinitionSummaryContext,
    ItemInterfaceSection,
    LayoutGridElements,
    NavigationCarousel,
    useBreakpoint,
    useIsDataRestricted,
    useOrBuildItemInterfaceFieldDefinitionSummaryContext,
    useProject,
    XCloseButton,
} from '@tonkean/infrastructure';
import UserNoAccess from '@tonkean/infrastructure/components/UserNoAccess';
import { BackIcon, HomeIcon } from '@tonkean/svg';
import type {
    Group,
    IncludedWidgetsSummaryByInterface,
    Initiative,
    ItemInterface,
    ItemInterfaceWidget,
    ItemInterfaceWidgetConfiguration,
    PluggableActionType,
    SolutionBusinessReport,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import {
    ItemInterfaceBuilderMenuItem,
    ItemInterfaceLoadingState,
    useItemInterfaceHeaderThemeConfiguration,
} from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';
import { BreadcrumbsSize } from '@tonkean/tui-theme/sizes';
import { EMPTY_ARRAY } from '@tonkean/utils';

const ItemInterfaceWithPanel = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    overflow: hidden;

    // CSS to set widget panel sizes
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 8;
    @media screen and (min-width: ${Breakpoint.MINIATURE_0}px) and (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }
`;

const Header = styled.header<{ borderColor: string }>`
    display: grid;
    height: 72px;
    background-color: ${Theme.colors.basicBackground};
    grid-template-columns: 1fr auto 1fr;
    gap: 8px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 8px solid ${({ borderColor }) => borderColor};
    padding: 5px 20px;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        padding: 8px;
        background-color: ${Theme.colors.gray};
        border-bottom: none;
    }
`;

const BackAndBreadcrumbsWrapper = styled.header`
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;

const BreadcrumbsBar = styled.div<{ size: BreadcrumbsSize; lastIsBold?: boolean }>`
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: ${Theme.colors.gray_500};
    font-size: ${({ size }) => Theme.sizes.breadcrumbs[size].fontSize};
    background-color: ${Theme.colors.basicBackground};

    .tnk-parent-navigation {
        a {
            text-decoration: none;
            cursor: pointer;
        }

        display: flex;
        align-items: center;
        line-height: 20px;
        font-weight: 400;

        .tnk-immediate-parent-label {
            font-weight: 500;
            color: ${Theme.colors.gray_800};
            cursor: default;
        }

        .tnk-parent-separator {
            font-weight: 500;
        }
    }
`;

const Body = styled.main`
    flex-grow: 1;
    background-color: ${Theme.colors.gray_100};
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const SeparatorWrapper = styled.div`
    margin: 0 5px;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
    a {
        font-weight: 400;
        color: ${Theme.colors.gray_500};
    }
`;

const InterfaceViewersWrapper = styled.div`
    margin-right: 16px;
`;

const RightHeaderSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
`;

const StyledNavigationCarousel = styled(NavigationCarousel)`
    margin-right: 8px;
`;

const ItemInterfaceHeaderCallToActionMenuWrapper = styled.div`
    margin-left: 12px;
    margin-right: 8px;
`;

const StyledIconButton = styled(IconButton)`
    margin-left: 10px;
    height: auto;
    width: auto;
    padding: 8px;

    svg {
        max-height: unset;
        max-width: unset;
    }
`;

const TopHeadGutterLeftArea = styled.div`
    grid-area: item-interface-header-gutter-left;
    display: flex;
    align-items: center;
`;

const MainItemInterfaceView = styled.div<{ hasRightElement: boolean }>`
    flex: ${(props) => (props.hasRightElement ? '0 0 70%' : '1')};
    height: 100%;
    overflow-y: auto;
`;

export interface SystemInterfaceDisplayProps {
    initiativeId: Initiative['id'] | undefined;
    solutionReport?: SolutionBusinessReport;
    group: Group | undefined;
    workflowVersion: WorkflowVersion | undefined;
    itemInterface: ItemInterface;
    widgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    includedWidgetsSummaryByInterfaceId: IncludedWidgetsSummaryByInterface | undefined;
    showBreadcrumbs?: boolean;
    showClosingButton?: boolean;
    permissions: ItemInterfacePermission;
    section: ItemInterfaceSection;
    showPoweredByTonkean?: boolean;
    showReturnToHomepageButton?: boolean;
    showNavigation?: boolean;
    showPanel?: boolean;

    onBack?(): void;
}

const SystemInterfaceDisplay: React.FC<SystemInterfaceDisplayProps> = ({
    initiativeId,
    solutionReport,
    group,
    workflowVersion,
    itemInterface,
    widgets,
    includedWidgetsSummaryByInterfaceId,
    onBack,
    showClosingButton,
    permissions: permission,
    section,
    showBreadcrumbs = true,
    showPoweredByTonkean = true,
    showReturnToHomepageButton,
    showNavigation = true,
    showPanel = false,
}) => {
    const $state = useAngularService('$state');

    const {
        polledInitiative,
        polledInitiativeUpdateTime,
        errorStatusCode: pollInitiativeErrorStatusCode,
        pending: pollInitiativePending,
        loading: isLoadingInitiative,
        reloadInitiativeFromServer: initiativeManuallyReloadInitiativeFromServer,
        applyFastReloadInterval: initiativeApplyFastReloadInterval,
        setPollInitiativeEnabled,
    } = usePollInitiativeData(itemInterface?.projectId, initiativeId, itemInterface?.id);

    const {
        widgetsResponse: panelWidgetsResponse,
        itemInterface: panelItemInterface,
        includedWidgetsSummaryByInterfaceId: panelIncludedWidgetsSummaryByInterfaceId,
    } = useGetItemInterfaceData(
        itemInterface?.projectId,
        initiativeId,
        undefined,
        itemInterface.configuration?.panelInterfaceId,
    );

    const {
        polledInitiative: panelItemInterfaceData,
        polledInitiativeUpdateTime: panelItemInterfaceDataUpdateTime,
        reloadInitiativeFromServer: panelItemInterfaceManuallyReloadInitiativeFromServer,
        applyFastReloadInterval: panelItemInterfaceApplyFastReloadInterval,
        setPollInitiativeEnabled: panelItemInterfaceSetPollInitiativeEnabled,
    } = usePollInitiativeData(itemInterface?.projectId, initiativeId, panelItemInterface?.id);

    // Because the panel and the main interface use the same initiative but different interface we need to refresh both
    // interfaces to see if a widget live display has updated so on change from either interface we fetch both.
    const { applyFastReloadInterval, manuallyReloadInitiativeFromServer } = useMemo(() => {
        return {
            applyFastReloadInterval: () => {
                initiativeApplyFastReloadInterval();
                panelItemInterfaceApplyFastReloadInterval();
            },
            manuallyReloadInitiativeFromServer: (updatedInitiative?: Initiative | undefined) => {
                initiativeManuallyReloadInitiativeFromServer(updatedInitiative);
                panelItemInterfaceManuallyReloadInitiativeFromServer(updatedInitiative);
            },
        };
    }, [
        initiativeApplyFastReloadInterval,
        panelItemInterfaceApplyFastReloadInterval,
        initiativeManuallyReloadInitiativeFromServer,
        panelItemInterfaceManuallyReloadInitiativeFromServer,
    ]);

    const mostRecentInitiative =
        panelItemInterfaceDataUpdateTime &&
        polledInitiativeUpdateTime &&
        panelItemInterfaceDataUpdateTime > polledInitiativeUpdateTime
            ? panelItemInterfaceData
            : polledInitiative;

    const isInBuilder = getIsInWidgetBuilder($state);
    const widgetsToDisplay = useGetWidgetsToDisplay(widgets, mostRecentInitiative);

    const showNotFoundPage = useIsDataRestricted([pollInitiativeErrorStatusCode]);

    useActivateOnLoadActions(initiativeId, itemInterface, manuallyReloadInitiativeFromServer, applyFastReloadInterval);

    const panelWidgetsToDisplay = useGetWidgetsToDisplay(panelWidgetsResponse?.entities, mostRecentInitiative);

    const fieldDefinitionSummaryContext = useOrBuildItemInterfaceFieldDefinitionSummaryContext();

    if (showNotFoundPage) {
        return <UserNoAccess />;
    }

    return (
        <ItemInterfaceFieldDefinitionSummaryContext.Provider value={fieldDefinitionSummaryContext}>
            <SystemInterfaceDisplayInner
                initiative={mostRecentInitiative}
                initiativeInitialLoading={pollInitiativePending || isLoadingInitiative}
                solutionReport={solutionReport}
                group={group}
                workflowVersion={workflowVersion}
                refreshItem={manuallyReloadInitiativeFromServer}
                itemInterface={itemInterface}
                // When we're in builder mode, we display all the widgets.
                widgetsToDisplay={isInBuilder ? widgets : widgetsToDisplay}
                allInterfaceWidgets={widgets}
                onBack={onBack}
                showBreadcrumbs={showBreadcrumbs}
                showClosingButton={showClosingButton}
                permissions={permission}
                showPoweredByTonkean={showPoweredByTonkean}
                includedWidgetsSummaryByInterfaceId={includedWidgetsSummaryByInterfaceId}
                applyFastReloadInterval={applyFastReloadInterval}
                showReturnToHomepageButton={showReturnToHomepageButton}
                showNavigation={showNavigation}
                section={section}
                showPanel={showPanel}
                // Panel
                panelItemInterface={panelItemInterface}
                panelItemInterfaceRefreshItem={manuallyReloadInitiativeFromServer}
                panelIncludedWidgetsSummaryByInterfaceId={panelIncludedWidgetsSummaryByInterfaceId}
                panelItemInterfaceApplyFastReloadInterval={applyFastReloadInterval}
                panelWidgetsToDisplay={panelWidgetsToDisplay}
                allPanelItemInterfaceInterfaceWidgets={panelWidgetsResponse?.entities}
                setPollInitiativeEnabled={setPollInitiativeEnabled}
                panelSetPollInitiativeEnabled={panelItemInterfaceSetPollInitiativeEnabled}
            />
        </ItemInterfaceFieldDefinitionSummaryContext.Provider>
    );
};

interface DisplayProps {
    initiative: Initiative | undefined;
    initiativeInitialLoading: boolean;
    solutionReport?: SolutionBusinessReport;
    group: Group | undefined;
    workflowVersion: WorkflowVersion | undefined;
    itemInterface: ItemInterface;
    panelItemInterface?: ItemInterface;
    widgetsToDisplay: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    panelWidgetsToDisplay: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    allInterfaceWidgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    allPanelItemInterfaceInterfaceWidgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[] | undefined;
    showBreadcrumbs?: boolean;
    showClosingButton?: boolean;
    permissions: ItemInterfacePermission;
    showPoweredByTonkean?: boolean;
    includedWidgetsSummaryByInterfaceId: IncludedWidgetsSummaryByInterface | undefined;
    panelIncludedWidgetsSummaryByInterfaceId: IncludedWidgetsSummaryByInterface | undefined;
    applyFastReloadInterval: (() => void) | undefined;
    panelItemInterfaceApplyFastReloadInterval: (() => void) | undefined;
    showReturnToHomepageButton?: boolean;
    showNavigation?: boolean;
    showPanel?: boolean;
    section: ItemInterfaceSection;

    setPollInitiativeEnabled?(pollInitiativeEnabled: boolean): void;
    panelSetPollInitiativeEnabled?(pollInitiativeEnabled: boolean): void;

    refreshItem(): void;

    panelItemInterfaceRefreshItem?(): void;

    onBack?(): void;
}

const SystemInterfaceDisplayInner: React.FC<DisplayProps> = ({
    initiative,
    initiativeInitialLoading,
    solutionReport,
    group,
    workflowVersion,
    refreshItem,
    itemInterface,
    panelItemInterface,
    widgetsToDisplay,
    allInterfaceWidgets,
    onBack,
    showClosingButton,
    permissions: permission,
    showBreadcrumbs = true,
    showPoweredByTonkean = true,
    includedWidgetsSummaryByInterfaceId,
    panelIncludedWidgetsSummaryByInterfaceId,
    applyFastReloadInterval,
    showReturnToHomepageButton = false,
    showNavigation = true,
    panelWidgetsToDisplay,
    showPanel = false,
    panelItemInterfaceRefreshItem,
    panelItemInterfaceApplyFastReloadInterval,
    allPanelItemInterfaceInterfaceWidgets,
    section,
    setPollInitiativeEnabled,
    panelSetPollInitiativeEnabled,
}) => {
    const $location = useAngularService('$location');
    const project = useProject();

    const $state = useAngularService('$state');
    const breadcrumbs = useItemInterfaceBreadcrumbs({ solutionReport, group, initiative });

    const contextValue = useMemo<ItemSystemInterfaceContext>(() => {
        return {
            onInitiativeUpdated: refreshItem,
        };
    }, [refreshItem]);

    const pluggableActionsToShowSettings: PluggableActionType[] = usePluggableActionsToShowSettings(itemInterface);

    const itemInterfaceContextValue = useItemInterfaceContextDataBuilder({
        initiative,
        initiativeInitialLoading,
        itemInterface,
        section,
        workflowVersion,
        includedWidgetsSummaryByInterfaceId,
        widgetsToDisplay,
        allInterfaceWidgets,
        reloadInitiativeFromServer: refreshItem,
        applyFastReloadInterval,
        pluggableActionsToShowSettings,
        setPollInitiativeEnabled,
    });

    const panelItemInterfaceContextValue = useItemInterfaceContextDataBuilder({
        initiative,
        initiativeInitialLoading,
        itemInterface: panelItemInterface,
        section: ItemInterfaceSection.PANEL,
        workflowVersion,
        includedWidgetsSummaryByInterfaceId: panelIncludedWidgetsSummaryByInterfaceId,
        widgetsToDisplay: panelWidgetsToDisplay,
        allInterfaceWidgets: allPanelItemInterfaceInterfaceWidgets || EMPTY_ARRAY,
        reloadInitiativeFromServer: panelItemInterfaceRefreshItem,
        applyFastReloadInterval: panelItemInterfaceApplyFastReloadInterval,
        setPollInitiativeEnabled: panelSetPollInitiativeEnabled,
    });

    const navigateToItem = useCallback(
        (id: TonkeanId<TonkeanType.INITIATIVE>) => {
            if ($state.params.initiativeId) {
                $state.go('.', { initiativeId: id });
            } else {
                $location.search({ tid: id });
            }
        },
        [$location, $state],
    );

    const { navigateToNextInitiativeId, navigateToPreviousInitiativeId, total, currentPosition } =
        useInitiativeNavigationContext();

    const displayBreadcrumbs = useMemo(() => {
        if (initiative === undefined) {
            return [];
        }

        const shouldShowNavigationDropdown = currentPosition > 0 && showNavigation;
        const isInitiativeHasParents = initiative && initiative.parentInitiatives?.length > 0;
        const breadcrumbsNoInitiative =
            shouldShowNavigationDropdown || isInitiativeHasParents ? breadcrumbs.slice(0, -1) : breadcrumbs;

        const parentsArray = [...initiative.parentInitiatives] as { id: string; title: string }[];

        if (!shouldShowNavigationDropdown) {
            parentsArray.push(initiative);
        }

        if (isInitiativeHasParents) {
            return (
                <BreadcrumbsBar size={BreadcrumbsSize.MEDIUM} lastIsBold>
                    <StyledBreadcrumbs settings={breadcrumbsNoInitiative} size={BreadcrumbsSize.MEDIUM} lastIsBold />
                    <SeparatorWrapper>/</SeparatorWrapper>
                    <TnkParentNavigation
                        parentsArray={parentsArray}
                        navigate={navigateToItem}
                        immediateParentLabel={shouldShowNavigationDropdown ? '' : 'Current Item'}
                        parentsSeparator="/"
                        immediateParentBold
                    />

                    {shouldShowNavigationDropdown && (
                        <>
                            <SeparatorWrapper>/</SeparatorWrapper>
                            <ItemsDropdownNavigation initiative={initiative} />
                        </>
                    )}
                </BreadcrumbsBar>
            );
        } else {
            return (
                <BreadcrumbsBar size={BreadcrumbsSize.MEDIUM}>
                    <Breadcrumbs settings={breadcrumbsNoInitiative} size={BreadcrumbsSize.MEDIUM} lastIsBold />

                    {shouldShowNavigationDropdown && (
                        <>
                            <SeparatorWrapper>/</SeparatorWrapper>
                            <ItemsDropdownNavigation initiative={initiative} />
                        </>
                    )}
                </BreadcrumbsBar>
            );
        }
    }, [initiative, currentPosition, showNavigation, breadcrumbs, navigateToItem]);

    const effectiveBreakpoint = useBreakpoint();

    const isMobile = Number(effectiveBreakpoint) <= Breakpoint.MID_XSMALL_768;

    const isInBuilder = getIsInWidgetBuilder($state);

    const isHeaderEnabled = [
        ItemInterfacePermission.INTERFACE_IS_EDITABLE,
        ItemInterfacePermission.INTERFACE_IS_VIEW_ONLY,
    ].includes(permission);

    const headerClickableProps =
        isInBuilder && isHeaderEnabled
            ? {
                  state: '.',
                  params: {
                      currentTab: ItemInterfaceBuilderMenuItem.THEME,
                      widgetId: undefined,
                  },
              }
            : {};

    const interfaceActions = itemInterface?.configuration?.actions || EMPTY_ARRAY;

    const panelInterfaceActions = panelItemInterface?.configuration?.actions || EMPTY_ARRAY;

    const headerThemeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);

    const itemInterfaceWidgetIds = useMemo(() => widgetsToDisplay?.map((widget) => widget.id), [widgetsToDisplay]);

    const allowLinkToHomepage = useFeatureFlag('tonkean_feature_standalone_item_interface_link_to_homepage');

    useRegisterItemInterfaceFieldDefinitionSummaryOnContext(
        itemInterface.id,
        itemInterfaceContextValue.allInterfaceWidgets,
        itemInterfaceContextValue.itemInterfaceFieldDefinitions,
        itemInterfaceContextValue.initiative,
        itemInterfaceContextValue.widgetsInnerItems,
    );

    const liveDisplay = useMemo(
        () => ({
            actionsLiveDisplayKeyPrefix: itemInterface.id,
            actionsDisabledLiveDisplay: itemInterfaceContextValue.actionsDisabledLiveDisplay,
            actionsHiddenLiveDisplay: itemInterfaceContextValue.actionsHiddenLiveDisplay,
        }),
        [
            itemInterface.id,
            itemInterfaceContextValue.actionsDisabledLiveDisplay,
            itemInterfaceContextValue.actionsHiddenLiveDisplay,
        ],
    );

    const panelLiveDisplay: InterfaceLiveDisplay | undefined = useMemo(() => {
        if (!panelItemInterface) {
            return undefined;
        }
        return {
            actionsLiveDisplayKeyPrefix: panelItemInterface.id,
            actionsDisabledLiveDisplay: panelItemInterfaceContextValue.actionsDisabledLiveDisplay,
            actionsHiddenLiveDisplay: panelItemInterfaceContextValue.actionsHiddenLiveDisplay,
        };
    }, [
        panelItemInterface,
        panelItemInterfaceContextValue.actionsDisabledLiveDisplay,
        panelItemInterfaceContextValue.actionsHiddenLiveDisplay,
    ]);

    const showInterfacePanel = showPanel && !!panelWidgetsToDisplay && !!panelItemInterface;

    return (
        <ItemInterfaceContext.Provider value={itemInterfaceContextValue}>
            <ItemSystemInterfaceContext.Provider value={contextValue}>
                <Wrapper>
                    <Clickable {...headerClickableProps} aria-label="Open theme configuration panel">
                        <Header borderColor={headerThemeConfiguration?.headerBackgroundColor}>
                            <BackAndBreadcrumbsWrapper>
                                <TopHeadGutterLeftArea data-automation="system-interface-display-homepage-view">
                                    {allowLinkToHomepage && showReturnToHomepageButton && (
                                        <StyledIconButton
                                            state="homepageView"
                                            params={{ projectUrlSlug: project.urlSlug }}
                                            flat
                                        >
                                            <IconSvg as={HomeIcon} size={20} />
                                        </StyledIconButton>
                                    )}
                                </TopHeadGutterLeftArea>

                                {!!onBack && (
                                    <IconButton
                                        onClick={() => onBack()}
                                        data-automation="system-interface-back"
                                        aria-label="go back"
                                        flat
                                    >
                                        <BackIcon />
                                    </IconButton>
                                )}

                                {showBreadcrumbs && initiative && displayBreadcrumbs}
                            </BackAndBreadcrumbsWrapper>

                            <ItemInterfaceHeaderLogo />

                            <RightHeaderSection>
                                {initiative && itemInterface && (
                                    <InterfaceViewersWrapper>
                                        <InterfaceViewers
                                            initiativeId={initiative?.id}
                                            itemInterfaceId={itemInterface.id}
                                            themePrimaryColor={headerThemeConfiguration.primaryColor}
                                            howManyViewersToShow={3}
                                        />
                                    </InterfaceViewersWrapper>
                                )}
                                {!itemInterface.isSystemInterface && initiative?.project.id && (
                                    <CollaborationActionsPlugin
                                        initiative={initiative}
                                        projectId={initiative?.project.id}
                                        itemInterfaceId={itemInterface.id}
                                        itemInterfaceWidgetIds={itemInterfaceWidgetIds}
                                        selectedPluggableActionState={
                                            itemInterfaceContextValue.selectedPluggableActionState
                                        }
                                        pluggableActionHoverState={itemInterfaceContextValue.pluggableActionHoverState}
                                        pluggableActionsToShowSettings={
                                            itemInterfaceContextValue.pluggableActionsToShowSettings
                                        }
                                        disabled={isInBuilder}
                                    />
                                )}
                                {showNavigation && currentPosition > 0 && (
                                    <StyledNavigationCarousel
                                        current={currentPosition}
                                        total={total || 0}
                                        onNextClick={navigateToNextInitiativeId}
                                        onPreviousClick={navigateToPreviousInitiativeId}
                                    />
                                )}
                                <ItemInterfaceHeaderCallToActionMenuWrapper>
                                    <InterfaceHeaderCallToActionMenu
                                        initiative={initiative}
                                        actions={interfaceActions}
                                        workflowVersionType={itemInterface.workflowVersionType}
                                        primaryColor={headerThemeConfiguration.headerBackgroundColor}
                                        itemInterface={itemInterface}
                                        workflowVersion={workflowVersion}
                                        projectId={itemInterface.projectId}
                                        permission={permission}
                                        reloadInitiativeFromServer={refreshItem}
                                        liveDisplay={liveDisplay}
                                        showActionAsButton={
                                            itemInterface.configuration?.showActionAsButton !== undefined
                                                ? itemInterface.configuration?.showActionAsButton
                                                : true
                                        }
                                    />
                                </ItemInterfaceHeaderCallToActionMenuWrapper>
                                {showClosingButton && (
                                    <XCloseButton
                                        data-automation="system-interface-close"
                                        size={isMobile ? ButtonSize.HUGE : ButtonSize.MEDIUM}
                                    />
                                )}
                            </RightHeaderSection>
                        </Header>
                    </Clickable>
                    <Body>
                        <ItemInterfaceWithPanel>
                            <MainItemInterfaceView hasRightElement={showInterfacePanel && !isMobile}>
                                <ItemInterfaceView
                                    itemInterfaceWidgetsState={ItemInterfaceLoadingState.IDLE}
                                    widgets={widgetsToDisplay}
                                    initiative={initiative}
                                    workflowVersion={workflowVersion}
                                    permission={permission}
                                    showPoweredByTonkean={showPoweredByTonkean}
                                />
                            </MainItemInterfaceView>

                            {showInterfacePanel && (
                                <ItemInterfacePanel
                                    panelItemInterfaceContextValue={panelItemInterfaceContextValue}
                                    isMobile={isMobile}
                                    panelItemInterface={panelItemInterface}
                                    itemInterfaceWidgetsState={ItemInterfaceLoadingState.IDLE}
                                    widgets={panelWidgetsToDisplay}
                                    initiative={initiative}
                                    workflowVersion={workflowVersion}
                                    permission={ItemInterfacePermission.USER_CAN_EDIT_ITEM}
                                    showPoweredByTonkean={false}
                                    itemActions={panelInterfaceActions}
                                    refreshItem={panelItemInterfaceRefreshItem}
                                    liveDisplay={panelLiveDisplay}
                                />
                            )}
                        </ItemInterfaceWithPanel>
                    </Body>
                </Wrapper>
            </ItemSystemInterfaceContext.Provider>
        </ItemInterfaceContext.Provider>
    );
};

export default SystemInterfaceDisplay;
