const INTAKE_THANK_YOU_PAGE_CONSTS = {
    DEFAULT_THANK_YOU_REDIRECT_TIME: 5,
    DEFAULT_THANK_YOU_TITLE: 'Thank you for your submission!',
    DISABLED_THANK_YOU_TITLE: 'This page is disabled',
    DISABLED_THANK_YOU_SUB_TITLE: "New requests can't be submitted at this time",
    THANK_YOU_REDIRECT_URL_DATA_AUTOMATION: 'fill-form-redirect-url-messages-expected-url',
    THANK_YOU_NO_REDIRECT_URL_DATA_AUTOMATION: 'fill-form-interface-link-button',
    THANK_YOU_TITLE_DATA_AUTOMATION: 'fill-form-web-response-message',
    THANK_YOU_SUB_TITLE_DATA_AUTOMATION: 'fill-form-web-response-message-subtitle',
    THANK_YOU_REDIRECT_LINK_WRAPPER_DATA_AUTOMATION: 'fill-form-redirect-to-url-messages',
};

export default INTAKE_THANK_YOU_PAGE_CONSTS;
