import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function TrainTriggerLogicConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        validationObject: {},
        trainedTriggers: [],
        trainingEntityType: 'TRAINING_SET',

        trainingSetTrainTriggerConfiguration: undefined,
    };

    /**
     * Initialization function for the controller.
     */
    ctrl.$onInit = function () {
        initializeEditMode();
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
            initializeEditMode();
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = ctrl.invalidLogics;
        }
    };

    /**
     * Adds ane mpty configured custom trigger.
     */
    $scope.addEmptyTrainedTrigger = function () {
        if (!$scope.data.trainedTriggers) {
            $scope.data.trainedTriggers = [];
        }

        $scope.data.trainedTriggers.push(getEmptyTrainedTrigger());
    };

    /**
     * Adds a trained keyword to the configuration.
     */
    $scope.addTrainedKeyword = function (trainedTriggerIndex) {
        const trainedTrigger = $scope.data.trainedTriggers[trainedTriggerIndex];
        trainedTrigger.trainedKeywords.push(getEmptyTrainedKeyword());
    };

    /**
     * Occurs on deletion of a custom trigger.
     */
    $scope.deleteTrainedTrigger = function (trainedTriggerIndex) {
        $scope.data.trainedTriggers.splice(trainedTriggerIndex, 1);

        if (!$scope.data.trainedTriggers?.length) {
            $scope.addEmptyTrainedTrigger();
        }

        $scope.onActionsChanged(true);
    };

    /**
     * Deletes a trained keyword.
     */
    $scope.deleteTrainedKeyword = function (trainedTriggerIndex, trainedKeywordIndex) {
        $scope.data.trainedTriggers[trainedTriggerIndex].trainedKeywords.splice(trainedKeywordIndex, 1);
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs on selection of training type.
     */
    $scope.selectTrainingEntityType = function (selectedTrainingEntityType) {
        $scope.data.trainingEntityType = selectedTrainingEntityType;
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs on selection of custom trigger input.
     */
    $scope.selectInputType = function (inputTypeSelection, trainedTriggerIndex) {
        const trainedTrigger = $scope.data.trainedTriggers[trainedTriggerIndex];
        trainedTrigger.inputTypeSelection = inputTypeSelection;

        if (inputTypeSelection === 'MANUAL') {
            trainedTrigger.customTriggerDisplayNameExpression = null;
        } else {
            trainedTrigger.customTriggerId = null;
        }

        $scope.onActionsChanged(true);
    };

    /**
     * Occurs on the selection of manual custom trigger.
     */
    $scope.onCustomTriggerSelected = function (selectedCustomTrigger, trainedTriggerIndex) {
        $scope.data.trainedTriggers[trainedTriggerIndex].customTriggerId = selectedCustomTrigger.id;
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs on change of custom trigger expression.
     */
    $scope.onCustomTriggerDisplayNameTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
        trainedTriggerIndex,
    ) {
        $scope.data.trainedTriggers[trainedTriggerIndex].customTriggerDisplayNameExpression = {
            originalExpression,
            evaluatedExpression,
        };

        $scope.onActionsChanged(shouldSaveLogic);
    };

    $scope.onTrainingSetTrainTriggerConfiguration = function (newConfiguration) {
        $scope.data.trainingSetTrainTriggerConfiguration = newConfiguration;
        $scope.onActionsChanged(true);
    };
    /**
     * Occurs on the change of the keyword expression.
     */
    $scope.onKeywordTonkeanExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
        trainedTriggerIndex,
        trainedKeywordIndex,
    ) {
        const trainedTrigger = $scope.data.trainedTriggers[trainedTriggerIndex];
        if (trainedTrigger.trainedKeywords && trainedTrigger.trainedKeywords[trainedKeywordIndex]) {
            const trainedKeyword = trainedTrigger.trainedKeywords[trainedKeywordIndex];

            trainedKeyword.keywordExpression = {
                originalExpression,
                evaluatedExpression,
            };
        }

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Selects a sentiment for a trained keyword.
     */
    $scope.selectTrainedKeywordSentiment = function (trainedBranchIndex, trainedKeywordIndex, keywordSentiment) {
        $scope.data.trainedTriggers[trainedBranchIndex].trainedKeywords[trainedKeywordIndex].keywordSentiment =
            keywordSentiment;
        $scope.onActionsChanged(true);
    };

    /**
     * Occurs once action definition has been changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        // Converting custom trigger definitions.
        const convertedTrainedTriggers = $scope.data.trainedTriggers
            .filter((trainedTrigger) => {
                if (!trainedTrigger.trainedKeywords || !trainedTrigger.trainedKeywords.length) {
                    return false;
                }

                const nonEmptyTrainedKeywords = trainedTrigger.trainedKeywords.filter(
                    (trainedKeyword) => !!trainedKeyword?.keywordExpression?.evaluatedExpression,
                );

                if (!nonEmptyTrainedKeywords || !nonEmptyTrainedKeywords.length) {
                    return false;
                }

                if (trainedTrigger.inputTypeSelection === 'MANUAL') {
                    return !!trainedTrigger.customTriggerId;
                } else {
                    return !!trainedTrigger?.customTriggerDisplayNameExpression?.evaluatedExpression;
                }
            })
            .map((trainedTrigger) => {
                const convertedTrainedKeywords = trainedTrigger.trainedKeywords
                    .filter((trainedKeyword) => trainedKeyword?.keywordExpression?.evaluatedExpression)
                    .map((trainedKeyword) => {
                        return {
                            keywordSentiment: trainedKeyword.keywordSentiment,
                            keywordExpression: {
                                originalExpression: trainedKeyword.keywordExpression.originalExpression,
                                evaluatedExpression: trainedKeyword.keywordExpression.evaluatedExpression,
                            },
                        };
                    });

                return {
                    customTriggerId:
                        trainedTrigger.inputTypeSelection === 'MANUAL' ? trainedTrigger.customTriggerId : undefined,
                    customTriggerDisplayNameExpression:
                        trainedTrigger.inputTypeSelection === 'EXPRESSION'
                            ? trainedTrigger.customTriggerDisplayNameExpression
                            : undefined,
                    trainedKeywords: convertedTrainedKeywords,
                };
            });

        if (ctrl.onActionsChanged) {
            const definition = {
                actions: [
                    {
                        type: 'TRAIN_TRIGGER',
                        definition: {
                            trainingEntityType: $scope.data.trainingEntityType,
                            trainingSetTrainTriggerConfiguration:
                                $scope.data.trainingEntityType === 'TRAINING_SET'
                                    ? $scope.data.trainingSetTrainTriggerConfiguration
                                    : undefined,
                            trainedTriggers: convertedTrainedTriggers,
                        },
                    },
                ],
            };

            ctrl.onActionsChanged({ definition, shouldSaveLogic });
        }
    };

    /**
     * Initializes the edit mode of the logic.
     */
    function initializeEditMode() {
        const customTriggerAction = $scope.data.configuredLogic.node;

        $scope.data.validationObject = $scope.data.invalidLogics[$scope.data.configuredLogic.node.id];

        if (customTriggerAction) {
            const customTriggerActionDefinition =
                customTriggerAction.customTriggerActions?.[0]?.customTriggerActionDefinition;

            $scope.data.trainingEntityType = customTriggerActionDefinition.trainingEntityType;

            // for training set
            $scope.data.trainingSetTrainTriggerConfiguration =
                customTriggerActionDefinition?.trainingSetTrainTriggerConfiguration;

            $scope.data.trainedTriggers = customTriggerActionDefinition?.trainedTriggers || [];

            if (!$scope.data.trainedTriggers || !$scope.data.trainedTriggers.length) {
                $scope.addEmptyTrainedTrigger();
            } else {
                $scope.data.trainedTriggers.forEach((trainedTrigger) => {
                    if (trainedTrigger.customTriggerDisplayNameExpression) {
                        trainedTrigger.inputTypeSelection = 'EXPRESSION';
                    } else {
                        trainedTrigger.inputTypeSelection = 'MANUAL';
                    }

                    if (!trainedTrigger.trainedKeywords || !trainedTrigger.trainedKeywords.length) {
                        trainedTrigger.trainedKeywords = [getEmptyTrainedKeyword()];
                    }
                });
            }
        }
    }

    /**
     * Returns an empty trained keyword object.
     */
    function getEmptyTrainedKeyword() {
        return {
            keywordSentiment: 'POSITIVE',
        };
    }

    /**
     * Gets an empty trained custom trigger.
     */
    function getEmptyTrainedTrigger() {
        return {
            inputTypeSelection: 'MANUAL',
            trainedKeywords: [getEmptyTrainedKeyword()],
        };
    }
}

export default angular
    .module('tonkean.app')
    .controller('trainTriggerLogicConfigurationCtrl', lateConstructController(TrainTriggerLogicConfigurationCtrl));
