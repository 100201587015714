<div class="clearfix mod-width-250">
    <!-- Dropdown -->
    <tnk-select
            ng-if="data.dropdownSource !== 'SEARCH'"
            data-automation="edit-dropdown-options-popover"
            value="data.selected"
            is-multi="data.isMulti"
            is-creatable="data.isCreatable"
            close-menu-on-select="!data.isMulti"
            default-menu-is-open="!data.isMulti"
            on-change="(updateOptions)"
            is-searchable="true"
            is-clearable="true"
            options="data.options"
    ></tnk-select>
    <tnk-update-field-async-search-dropdown-selector
            ng-if="data.dropdownSource === 'SEARCH' && !!data.initiativeId && !!data.fieldDefinitionId"
            class="inline-block common-width-100"
            data-automation="edit-search-dropdown-options-popover"
            field-definition-id="data.fieldDefinitionId"
            initiative-id="data.initiativeId"
            on-change="(updateOptions)"
            value="!data.isMulti ? data.selected : undefined"
            is-multi-value-list="data.isMulti"
            multi-value-list="data.isMulti ? data.selected : undefined"
            read-only="false"
            is-creatable="data.isCreatable"
            default-menu-is-open="!data.isMulti"
            close-menu-on-select="!data.isMulti"
    ></tnk-update-field-async-search-dropdown-selector>

    <!-- Form Buttons -->
    <div class="text-right margin-top" ng-if="data.isMulti">
        <!-- Cancel -->
        <button class="btn btn-sm btn-default margin-left-xs" ng-click="close()">Cancel</button>

        <!-- Save -->
        <button class="btn btn-sm btn-primary margin-left-xs"
                data-automation="tnk-edit-dropdown-options-popover-template-save" ng-click="saveOptions()">Save
        </button>
    </div>
</div>
