import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function ExampleItemEvaluatedExpressionCtrl(
    $scope,
    projectManager,
    tonkeanService,
    requestThrottler,
    customTriggerManager,
    utils,
) {
    const ctrl = this;
    $scope.ctm = customTriggerManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        logicId: ctrl.logicId,
        expressionUniqueIdentifier: ctrl.expressionUniqueIdentifier,
        expressionToEvaluate: ctrl.expressionToEvaluate,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        // Evaluated example
        doNotEvaluatePreview: ctrl.doNotEvaluatePreview,
        exampleInitiativeId: null,
        exampleInitiativeTitle: '',
        exampleWorkerRunLogicId: null,
        workerRunId: null,
        workerRunStartTime: null,

        evaluatedExpressionValue: null,
        evaluatedExpressionLoading: false,
        evaluationError: '',
        hidePreview: ctrl.hidePreview,
        finishedOnInit: false,
        isShowingMore: false,
        hideIcon: ctrl.hideIcon,
        htmlPreview: ctrl.htmlPreview,
        color: ctrl.color,
        isInline: ctrl.isInline,
        throttlerGuid: utils.guid(),

        // The maximum expression length that can be displayed without expandable DIV (Using it in the HTML).
        maximumExpressionLength: 197,

        customPreview: ctrl.customPreview,
        showPeopleDirectories: ctrl.showPeopleDirectories,
    };

    /**
     * Occurs on changes of the component.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.htmlPreview) {
            $scope.data.htmlPreview = changesObj.htmlPreview.currentValue;
        }

        if (
            changesObj.overrideExampleInitiativeId &&
            !utils.isNullOrUndefined(changesObj.overrideExampleInitiativeId.currentValue)
        ) {
            $scope.data.exampleInitiativeId = changesObj.overrideExampleInitiativeId.currentValue;
            // setExampleInitiativeTitle();
            evaluate();
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = ctrl.previewEvaluationSource;
            evaluate();
        }

        if (changesObj.expressionToEvaluate) {
            $scope.data.expressionToEvaluate = changesObj.expressionToEvaluate.currentValue;
            if ($scope.data.expressionToEvaluate || !ctrl.fieldIdToEvaluate) {
                evaluateExpressionForPreview($scope.data.expressionToEvaluate);
            }
        }

        if (changesObj.fieldIdToEvaluate && changesObj.fieldIdToEvaluate.currentValue !== undefined) {
            if (changesObj.fieldIdToEvaluate.currentValue) {
                $scope.data.fieldIdToEvaluate = `{{${changesObj.fieldIdToEvaluate.currentValue}}}`;
            } else {
                $scope.data.fieldIdToEvaluate = '';
            }

            evaluateExpressionForPreview($scope.data.fieldIdToEvaluate);
        }

        if (changesObj.doNotEvaluatePreview && changesObj.doNotEvaluatePreview.currentValue !== undefined) {
            $scope.data.doNotEvaluatePreview = changesObj.doNotEvaluatePreview.currentValue;
        }

        if (changesObj.expressionUniqueIdentifier && changesObj.expressionUniqueIdentifier.currentValue !== undefined) {
            $scope.data.expressionUniqueIdentifier = changesObj.expressionUniqueIdentifier.currentValue;
        }

        if (changesObj.hidePreview && changesObj.hidePreview.currentValue !== undefined) {
            $scope.data.hidePreview = changesObj.hidePreview.currentValue;
        }

        if (changesObj.color) {
            $scope.data.color = changesObj.color.currentValue;
        }

        if (changesObj.customPreview) {
            $scope.data.customPreview = changesObj.customPreview.currentValue;
        }
    };

    /**
     * Occurs on changes of the component.
     */
    ctrl.$onInit = function () {
        $scope.data.finishedOnInit = true;
        if (ctrl.overrideExampleInitiativeId) {
            $scope.data.exampleInitiativeId = ctrl.overrideExampleInitiativeId;
        } else {
            const exampleInitiativeMap =
                customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId];
            $scope.data.exampleInitiativeId = exampleInitiativeMap?.[$scope.data.logicId] ?? exampleInitiativeMap?.root;
        }

        // Todo: for now not calling the get title, till we will have throttler that return promise for all the requests
        // setExampleInitiativeTitle();
        evaluate();
    };

    $scope.togglePreview = function ($event) {
        $scope.data.hidePreview = !$scope.data.hidePreview;
        $scope.data.isShowingMore = false;

        // Because the icon is inside the click area of the show more, we want to stop the event from bubbling
        return utils.eventStopAllPropagations($event);
    };

    $scope.onExampleAreaClicked = function () {
        if (
            $scope.data.evaluatedExpressionValue.length > $scope.data.maximumExpressionLength &&
            !$scope.data.isShowingMore
        ) {
            $scope.data.isShowingMore = true;
        }
    };

    $scope.onShowLessClicked = function (event) {
        // Stopping event propagation so onExampleAreaClicked won't fire and show more again immediately
        event.stopPropagation();
        $scope.data.isShowingMore = false;
    };

    //
    // /**
    //  * Set example initiative title.
    //  */
    // function setExampleInitiativeTitle() {
    //     if ($scope.data.exampleInitiativeId) {
    //         // We use the throttler here to avoid calling getInitiativeById multiple times when initializing a logic.
    //         // Since we are placed multiple times in a logic, we might try to call it multiple times at the same time.
    //         requestThrottler.do(`exampleItemEvaluatedExpression-${$scope.data.exampleInitiativeId}`, 50, () => trackHelper.getInitiativeById($scope.data.exampleInitiativeId)
    //             .then(data => {
    //                 $scope.data.exampleInitiativeTitle = data.title;
    //             }));
    //     }
    // }

    /**
     * Evaluate the existing value for evaluation.
     */
    function evaluate() {
        if ($scope.data.expressionToEvaluate) {
            evaluateExpressionForPreview($scope.data.expressionToEvaluate);
        } else if ($scope.data.fieldIdToEvaluate) {
            evaluateExpressionForPreview($scope.data.fieldIdToEvaluate);
        }
    }

    /**
     * Evaluate expression for preview if should.
     */
    function evaluateExpressionForPreview(evaluatedExpression) {
        if ($scope.data.previewEvaluationSource) {
            const evaluatedExpressionKey = 'expression';

            evaluateWorkerRunLogicExpression(
                [
                    {
                        key: evaluatedExpressionKey,
                        expression: evaluatedExpression,
                    },
                ],
                $scope.data.previewEvaluationSource.workerRunLogic,
                $scope.data.previewEvaluationSource.workerRunId,
                $scope.data.previewEvaluationSource.workerRunStartTime,
                evaluatedExpressionKey,
            );
        } else if (
            !$scope.data.doNotEvaluatePreview &&
            $scope.data.exampleInitiativeId &&
            evaluatedExpression &&
            $scope.data.finishedOnInit
        ) {
            const evaluatedExpressionKey = 'expression';

            evaluateInitiativeExpressions(
                [
                    {
                        key: evaluatedExpressionKey,
                        expression: evaluatedExpression,
                    },
                ],
                $scope.data.exampleInitiativeId,
                evaluatedExpressionKey,
            );
        } else {
            // clear the evaluated value
            $scope.data.evaluatedExpressionValue = '';
            $scope.data.evaluationError = '';
        }
    }

    /**
     * Evaluate Initiative expressions.
     */
    function evaluateInitiativeExpressions(expressionsToEvaluate, initiativeId, evaluatedExpressionKey) {
        $scope.data.evaluationError = '';
        const uniqueThrottlerId = `tonkeanExpressiongetEvaluatedExpressionsForInitiative-${$scope.data.groupId}-${$scope.data.logicId}-${$scope.data.expressionUniqueIdentifier}-${$scope.data.throttlerGuid}`;

        if (expressionsToEvaluate.length && initiativeId) {
            $scope.data.evaluatedExpressionLoading = true;
            requestThrottler.do(uniqueThrottlerId, 50, () =>
                tonkeanService
                    .getEvaluatedExpressionsForInitiative(
                        projectManager.project.id,
                        initiativeId,
                        expressionsToEvaluate,
                    )
                    .then((result) => {
                        $scope.data.evaluatedExpressionValue = result.evaluatedExpressions[evaluatedExpressionKey];
                    })
                    .catch(() => {
                        $scope.data.evaluationError = "Couldn't evaluate";
                        $scope.data.evaluatedExpressionValue = '';
                    })
                    .finally(() => {
                        $scope.data.evaluatedExpressionLoading = false;
                    }),
            );
        }
    }

    /**
     * Evaluate Initiative expressions.
     */
    function evaluateWorkerRunLogicExpression(
        expressionsToEvaluate,
        exampleWorkerRunLogicId,
        workerRunId,
        workerRunStartDate,
        evaluatedExpressionKey,
    ) {
        $scope.data.evaluationError = '';
        const uniqueThrottlerId = `tonkeanExpressiongetEvaluatedExpressionsWithWorkerRunLogic-${$scope.data.groupId}-${$scope.data.logicId}-${$scope.data.expressionUniqueIdentifier}-${$scope.data.throttlerGuid}`;

        if (expressionsToEvaluate.length) {
            $scope.data.evaluatedExpressionLoading = true;
            requestThrottler.do(uniqueThrottlerId, 50, () =>
                tonkeanService
                    .getEvaluatedExpressionsForWorkerRunLogic(
                        projectManager.project.id,
                        exampleWorkerRunLogicId,
                        workerRunId,
                        workerRunStartDate,
                        expressionsToEvaluate,
                    )
                    .then((result) => {
                        $scope.data.evaluatedExpressionValue = result.evaluatedExpressions[evaluatedExpressionKey];
                    })
                    .catch(() => {
                        $scope.data.evaluationError = "Couldn't evaluate";
                        $scope.data.evaluatedExpressionValue = '';
                    })
                    .finally(() => {
                        $scope.data.evaluatedExpressionLoading = false;
                    }),
            );
        }
    }
}

angular
    .module('tonkean.app')
    .controller('ExampleItemEvaluatedExpressionCtrl', lateConstructController(ExampleItemEvaluatedExpressionCtrl));
