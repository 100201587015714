<div class="tnk-springcm-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'UPLOAD_DOCUMENT'" class="margin-bottom-xlg">
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Folder ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The folder id the new document will be created in."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Folder ID"
                saved-expression="data.definition.folderIdExpression"
                on-tonkean-expression-changed="onFolderIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- New Document Name -->
        <div class="margin-top margin-bottom">
            <div class="margin-bottom-xs">
                New Document Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Document name"
                saved-original-expression="data.definition.newDocumentNameExpression.originalExpression"
                saved-evaluated-expression="data.definition.newDocumentNameExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onNewDocumentNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            preview-evaluation-source="data.previewEvaluationSource"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            parameters-values="data.definition.parametersValues"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'GENERATE_DOCUMENT_FROM_XML'" class="margin-bottom-xlg">
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Document Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The name of the new document."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Document Name"
                saved-original-expression="data.definition.documentNameExpression.originalExpression"
                saved-evaluated-expression="data.definition.documentNameExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onDocumentNameTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                Template ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The document that serves as a template to be populated with data."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Template ID"
                saved-original-expression="data.definition.templateIdExpression.originalExpression"
                saved-evaluated-expression="data.definition.templateIdExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    templateIdExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                Destination Folder ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The folder where the new document should be placed."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Destination Folder ID"
                saved-original-expression="data.definition.destinationFolderIdExpression.originalExpression"
                saved-evaluated-expression="data.definition.destinationFolderIdExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    destinationFolderIdExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                XML Data:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="XML data used to populate the template document. Try <FirstName></FirstName> for example. If you use the SpringCM document entity, use the xml_data field."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="XML Data"
                saved-original-expression="data.definition.dataXMLExpression.originalExpression"
                saved-evaluated-expression="data.definition.dataXMLExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    dataXMLExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-top">
            <div class="margin-bottom">
                Document Fields:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Fill XML data in order to populate document fields."
                ></i>
            </div>

            <div ng-if="data.xmlParsingError" class="alert-danger common-color-danger margin-bottom">
                {{ data.xmlParsingError }}
            </div>

            <div ng-if="areDocumentFieldsEmpty() && !data.xmlParsingError">
                In order to populate fields, fill the XML data property above with the relevant XML.
            </div>

            <div ng-repeat="(fieldName,fieldValue) in data.definition.documentFields" class="margin-bottom">
                <div class="margin-bottom-xs">{{ fieldName }}</div>
                <tnk-tonkean-expression
                    group-id="{{ data.groupId }}"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    logic-id="{{ data.configuredLogic.node.id }}"
                    preview-evaluation-source="data.previewEvaluationSource"
                    placeholder="Field value"
                    saved-original-expression="fieldValue.originalExpression"
                    saved-evaluated-expression="fieldValue.evaluatedExpression"
                    expression-unique-identifier="documentField{{ $index }}"
                    on-tonkean-expression-changed="
                        onDocumentFieldValueChanged(originalExpression, evaluatedExpression, shouldSaveLogic, fieldName)
                    "
                ></tnk-tonkean-expression>
            </div>
        </div>

        <!-- Error Section -->
        <div ng-if="data.validationObject.error" class="common-color-danger">{{ data.validationObject.error }}</div>
    </div>

    <div ng-if="data.definition.customActionKey === 'MOVE_DOCUMENT'" class="margin-bottom-xlg">
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Destination Folder ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Move the document to this folder"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Destination Folder ID"
                saved-original-expression="data.definition.destinationFolderIdExpression.originalExpression"
                saved-evaluated-expression="data.definition.destinationFolderIdExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    destinationFolderIdExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Error Section -->
        <div ng-if="data.validationObject.moveFolderError" class="common-color-danger">
            {{ data.validationObject.moveFolderError }}
        </div>
    </div>
</div>
