function WorkflowVersionChangeDetailsModalCtrl($scope, authenticationService, $uibModalInstance, changes, activity) {
    $scope.data = {
        changes,
        activity,
        currentUser: authenticationService.currentUser,
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };
}

export default angular
    .module('tonkean.app')
    .controller('WorkflowVersionChangeDetailsModalCtrl', WorkflowVersionChangeDetailsModalCtrl);
