<div class="initiatives-sidebar">
    <!-- Floating Open/Close Button -->
    <button
        class="btn btn-primary-black btn btn-primary margin-top margin-left absolute-left"
        ng-hide="data.showSidebar"
        ng-disabled="loading"
        ng-click="toggleSidebar()"
    >
        <span ng-if="!data.isForms">
            View My Tracks
            <span ng-if="data.initiatives">
                ({{ (data.initiatives | filter: { status: data.toggleDone ? 'DONE' : '!DONE' }).length }})
            </span>
        </span>
        <span ng-if="data.isForms">View Forms</span>
    </button>

    <div
        class="update-initiative-my-list-panel common-border-right common-close-btn-container"
        ng-class="{ 'mod-visible': data.showSidebar }"
        ng-show="data.showSidebar"
    >
        <!-- Close button -->
        <div class="common-close-btn" ng-click="toggleSidebar()">
            <tnk-icon src="../../../../svg/src/icons/x.svg"></tnk-icon>
        </div>

        <!-- Top pane -->
        <div class="common-size-xs mod-no-top flex-vmiddle">
            <div ng-if="!data.isForms" class="flex-grow">
                My Tracks
                <span ng-if="data.initiatives">
                    ({{ (data.initiatives | filter: { status: data.toggleDone ? 'DONE' : '!DONE' }).length }})
                </span>
            </div>
            <div ng-if="data.isForms" class="flex-grow">Forms</div>
            <!-- Filter button -->
            <div class="margin-right flex-no-shrink dropbox relative" uib-dropdown on-toggle="toggled(open)">
                <!-- Filter button -->
                <button
                    class="btn btn-sm dropdown-toggle common-color-dark-grey pointer"
                    analytics-on="click"
                    analytics-category="Filter"
                    analytics-event="Filter by dueDate"
                    ng-class="data.dateRange || data.toggleDone ? 'btn-primary-black' : 'btn-default'"
                    uib-dropdown-toggle
                >
                    Filter
                    <i class="fa fa-chevron-down margin-left-xs"></i>
                </button>
                <!-- Filter dropdown -->
                <div
                    uib-dropdown-menu
                    class="dropdown-menu-right common-color-dark-grey common-size-xxs padding-normal-sm"
                    role="menu"
                >
                    <strong class="common-color-black">Status:</strong>
                    <div class="common-size-xxxxs">
                        <div
                            ng-click="data.toggleDone = false"
                            class="filter-option-btn"
                            ng-class="{ active: !data.toggleDone }"
                            analytics-on="click"
                            analytics-category="Filter"
                            analytics-event="Filter by Status"
                            analytics-label="Active"
                        >
                            Active
                        </div>
                        <div
                            ng-click="data.toggleDone = true"
                            class="filter-option-btn"
                            ng-class="{ active: data.toggleDone }"
                            analytics-on="click"
                            analytics-category="Filter"
                            analytics-event="Filter by State"
                            analytics-label="Done"
                        >
                            Completed
                        </div>
                    </div>
                    <strong class="common-color-black margin-top block">Due date:&nbsp;</strong>
                    <div class="common-size-xxxxs">
                        <div
                            class="margin-bottom-xs filter-option-btn"
                            ng-class="{ active: !data.dateRange }"
                            ng-click="setDueFilter()"
                        >
                            None
                        </div>
                        <div
                            ng-repeat="r in dueFilterOptions"
                            class="filter-option-btn"
                            ng-class="{ active: data.dateRange.label === r.label }"
                            ng-click="setDueFilter(r)"
                        >
                            {{ r.label }}
                        </div>
                    </div>

                    <strong ng-if="!data.hideHierarchyFilter" class="common-color-black margin-top block">
                        Hierarchy:&nbsp;
                    </strong>

                    <div ng-if="!data.hideHierarchyFilter" class="common-size-xxxxs">
                        <div
                            ng-repeat="r in hierarchyFilterOptions"
                            class="filter-option-btn"
                            ng-class="{ active: data.hierarchy.key === r.key }"
                            ng-click="setHierarchyFilter(r)"
                        >
                            {{ r.label }}
                        </div>
                    </div>

                    <strong class="common-color-black margin-top block">Items:</strong>
                    <div class="common-size-xxxxs">
                        <div
                            ng-repeat="itemFilterOption in itemsFilterOptions"
                            class="filter-option-btn"
                            ng-class="{ active: data.selectedItemFilter === itemFilterOption.value }"
                            ng-click="selectItemFilter(itemFilterOption.value)"
                        >
                            {{ itemFilterOption.label }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Selected filter -->
        <div class="common-size-xxxxxs common-color-grey" ng-if="data.dateRange || data.toggleDone">
            <i>
                <span>{{ data.toggleDone ? 'Only completed' : 'Only active' }}</span>
                <span ng-if="data.dateRange">, due: {{ data.dateRange.label.toLowerCase() }}</span>
            </i>
        </div>
        <hr />

        <!-- Loading circle -->
        <div ng-if="loading" class="text-center">
            <i class="loading-small"></i>
        </div>

        <!-- Tracks lists -->
        <div
            ng-if="!loading"
            ng-repeat="(groupId, initiatives) in data.initiativesByGroup"
            class="padding-bottom-lg"
            ng-show="(filteredInitiatives && filteredInitiatives.length && !data.groupId) || data.groupId == groupId"
        >
            <!-- List name -->
            <div class="common-title-xxs margin-bottom-xs">{{ pm.groupsMap[groupId].name }}</div>

            <!-- The initiatives -->
            <div
                ng-repeat="item in filteredInitiatives = (initiatives | filter:{status: data.toggleDone ? 'DONE' : '!DONE'})"
                ng-class="{
                    'common-color-primary common-bold mod-active': sp.id === item.id || sp.initiativeId === item.id
                }"
                class="update-initiative-panel-item padding-left-lg padding-top-xs padding-bottom-xs pointer"
                ng-click="setCurrentIndex(item.id)"
            >
                <!-- Parents container -->
                <div class="flex" ng-if="item.parentInitiatives && item.parentInitiatives.length">
                    <!-- Bullet placeholder - to push parents right -->
                    <i class="fa fa-circle common-size-xxxxxs margin-right-lg" style="color: transparent"></i>
                    <!-- Parents -->
                    <div class="common-size-xxxxxs common-color-grey text-left">
                        <span ng-repeat="p in item.parentInitiatives" class="inline-block">
                            <span class="common-color-grey margin-right-xs">{{ p.title || itemMap[p.id].title }}</span>
                            <i class="fa fa-angle-right margin-right-xs common-color-light-grey"></i>
                        </span>
                    </div>
                </div>
                <div class="flex">
                    <!-- Bullet -->
                    <i
                        class="fa fa-circle common-size-xxxxxs margin-right-lg margin-top-xs"
                        ng-style="{ color: itemMap[item.id].stateColor || item.stateColor }"
                    ></i>
                    <!-- Initiative title -->
                    <span>{{ itemMap[item.id].title || item.title }}</span>
                </div>
            </div>
        </div>

        <!-- Other - Initiatives from groups the user can't see (i.e invisibleGroupsInitiatives) -->
        <div ng-if="!loading && data.invisibleGroupsInitiatives.length">
            <!-- "Other" title -->
            <div
                class="common-title-xxs margin-bottom-xs"
                ng-if="filteredInvisibleInitiatives && filteredInvisibleInitiatives.length"
            >
                Other
            </div>

            <!-- The initiatives -->
            <div
                ng-repeat="item in filteredInvisibleInitiatives = (data.invisibleGroupsInitiatives | filter:{status: data.toggleDone ? 'DONE' : '!DONE'})"
                class="update-initiative-panel-item padding-left-lg padding-top-xs padding-bottom-xs pointer"
                ng-class="{
                    'common-color-primary common-bold mod-active': sp.id === item.id || sp.initiativeId === item.id
                }"
                ng-click="setCurrentIndex(item.id)"
            >
                <!-- Parents container -->
                <div class="flex" ng-if="::parseParents(item, itemMap).length">
                    <!-- Bullet placeholder - to push parents right -->
                    <i class="fa fa-circle common-size-xxxxxs margin-right-lg" style="color: transparent"></i>
                    <!-- Parents -->
                    <div class="common-size-xxxxs common-color-grey text-left">
                        <span ng-repeat="p in ::parseParents(item, itemMap)" class="inline-block">
                            <span class="common-color-grey margin-right-xs">{{ p.title || itemMap[p.id].title }}</span>
                            <i class="fa fa-angle-right margin-right-xs common-color-light-grey"></i>
                        </span>
                    </div>
                </div>
                <div class="flex">
                    <!-- Bullet -->
                    <i
                        class="fa fa-circle common-size-xxxxxs margin-right-lg margin-top-xs"
                        ng-style="{ color: itemMap[item.id].stateColor || item.stateColor }"
                    ></i>
                    <!-- Initiative title -->
                    <span>{{ itemMap[item.id].title || item.title }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
