import React from 'react';
import styled from 'styled-components';

import { INTAKE_ITEM_INTERFACEC_CONSTS } from './IntakeItemInterfaceConsts';
import { useRegisterItemInterfaceFieldDefinitionSummaryOnContext } from '../../hooks';
import { InterfaceModuleHeader } from '../../InterfaceModuleHeader';

import { CollaborationActionsPlugin } from '@tonkean/collaboration';
import { Breakpoint } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import type {
    TonkeanType,
    TonkeanId,
    WorkflowVersionType,
    ItemInterfaceHeaderThemeConfiguration,
    ProjectThemeConfiguration,
    Initiative,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const SequenceBackgroundWrapper = styled.div<{ $themeColor: string }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    height: max-content;
    padding-bottom: 64px;
    z-index: 0;
    background-color: ${({ $themeColor }) => $themeColor};
`;

const SequenceContainer = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin: 0 auto;
  padding-top: 2%;
  z-index: 1;
  transition: max-width 0.2s linear;
  @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
    margin: 0px !important;
    width: 100%;
  }
}
`;

interface IntakeItemInterfaceWrapperProps {
    projectThemeConfiguration: ProjectThemeConfiguration;
    forceThemeConfiguration: boolean;
    itemInterfaceThemeConfiguration?: ItemInterfaceHeaderThemeConfiguration | undefined;
    workflowVersionType: WorkflowVersionType;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    currentCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    projectUrlSlug: string | undefined;
    initiative: Initiative | undefined;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    itemInterfaceWidgetIds?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>[];
}

const IntakeItemInterfaceWrapper: React.FC<React.PropsWithChildren<IntakeItemInterfaceWrapperProps>> = ({
    projectThemeConfiguration,
    forceThemeConfiguration,
    itemInterfaceThemeConfiguration,
    workflowVersionType,
    workflowVersionId,
    currentCustomTriggerId,
    projectUrlSlug,
    projectId,
    children,
    initiative,
    itemInterfaceId,
    itemInterfaceWidgetIds,
}) => {
    const themeColor = forceThemeConfiguration
        ? projectThemeConfiguration.primaryColor
        : itemInterfaceThemeConfiguration?.primaryColor || Theme.colors.primary;

    const logoUrl = forceThemeConfiguration
        ? projectThemeConfiguration.logoUrl
        : itemInterfaceThemeConfiguration?.logoUrl;

    const {
        itemInterfaceFieldDefinitions,
        widgetsToDisplay,
        selectedPluggableActionState,
        pluggableActionHoverState,
        pluggableActionsToShowSettings,
        widgetsInnerItems,
    } = useItemInterfaceContext();

    useRegisterItemInterfaceFieldDefinitionSummaryOnContext(
        itemInterfaceId,
        widgetsToDisplay,
        itemInterfaceFieldDefinitions,
        initiative,
        widgetsInnerItems,
    );

    return (
        <SequenceBackgroundWrapper
            $themeColor={themeColor}
            data-automation={INTAKE_ITEM_INTERFACEC_CONSTS.INTAKE_ITEM_INTERFACE_DATA_AUTOMATION}
        >
            <InterfaceModuleHeader
                versionType={workflowVersionType}
                workflowVersionId={workflowVersionId}
                currentCustomTriggerId={currentCustomTriggerId}
                logoUrl={logoUrl}
                themeColor={themeColor}
                pluggableActions={
                    initiative &&
                    itemInterfaceId &&
                    itemInterfaceWidgetIds && (
                        <CollaborationActionsPlugin
                            initiative={initiative}
                            projectId={projectId}
                            itemInterfaceId={itemInterfaceId}
                            itemInterfaceWidgetIds={itemInterfaceWidgetIds}
                            selectedPluggableActionState={selectedPluggableActionState}
                            pluggableActionHoverState={pluggableActionHoverState}
                            pluggableActionsToShowSettings={pluggableActionsToShowSettings}
                        />
                    )
                }
                projectUrlSlug={projectUrlSlug}
                initiativeId={initiative?.id}
                itemInterfaceId={itemInterfaceId}
            />
            <SequenceContainer>{children}</SequenceContainer>
        </SequenceBackgroundWrapper>
    );
};

export default IntakeItemInterfaceWrapper;
