import type { SyncConfig } from '@tonkean/tonkean-entities';
import { getSpecialFieldsForFeatures } from '@tonkean/tonkean-utils';

/**
 * Get list of all field definitions - custom field, special fields and a fake field for the data
 * source (matched entity field of type root). The fields will have isSpecialField property.
 *
 * @param allFieldDefinitions - list of custom field definitions.
 * @param syncConfig - the current sync config (intake source).
 * @param tonkeanUtils - tonkeanUtils instance.
 */
function getHistoryInitiativeFields(
    allFieldDefinitions: any[],
    syncConfig: SyncConfig | undefined,
    tonkeanUtils: any,
): any[] {
    const customFieldDefinitions = allFieldDefinitions.map((fieldDefinition) => ({
        ...fieldDefinition,
        isSpecialField: false,
    }));
    const specialFieldDefinitions = getSpecialFieldsForFeatures(false, ['HISTORY_FIELDS']).map((fieldDefinition) => ({
        ...fieldDefinition,
        isSpecialField: true,
    }));
    const fields = [...customFieldDefinitions, ...specialFieldDefinitions];

    if (!syncConfig) {
        return fields;
    }

    // Create intake source matched entity field.
    const syncConfigField = {
        id: syncConfig.id,
        name: `${syncConfig.viewData?.entityMetadata?.pluralLabel || syncConfig.viewData?.entity} (Root)`,
        type: 'ROOT',
        isSpecialField: false,
        projectIntegrationId: syncConfig.projectIntegration.id,
        definition: {
            integrationType: syncConfig.viewData.integrationType,
        },
    };

    return [...fields, syncConfigField];
}

export default getHistoryInitiativeFields;
