<mentio-menu
    mentio-for="textId"
    mentio-trigger-char="'@'"
    mentio-items="selectedPeople"
    mentio-visible="data.visible"
    mentio-template-url="people-mentions.tpl"
    mentio-search="searchPeople(term)"
    mentio-select="getPeopleTextRaw(item)"
></mentio-menu>

<script type="text/ng-template" id="people-mentions.tpl">
    <ul class="list-group user-search">
        <li mentio-menu-item="item" ng-repeat="item in items  | limitTo:10" class="list-group-item" ng-click="item.isNotAllowed && $event.stopImmediatePropagation();"
            ng-class="{ 'mod-disabled': item.isNotAllowed}">
            <div class="flex flex-vmiddle list-group-item-content">
                <div class="flex-no-shrink avatar-size-xxs margin-right">
                    <tnk-avatar user="item" name="item.name" mod-secondary="item.email ? false : true"></tnk-avatar>
                </div>
                <div class="flex-grow margin-left-xs">
                    <div class="flex-vmiddle">
                        <div class="name flex-grow">{{item.name}}</div>
                        <span ng-if="item.isNotAllowed" class="svg-icon-sm margin-right-md-minus padding-right-xs padding-left-xxs common-color-grey">
                            <tnk-icon src="../../../../../apps/tracks/images/icons/no-access.svg"></tnk-icon>
                        </span>
                    </div>
                    <div class="list-group-item-sub-text common-size-xxs">{{item.defaultOwner ? item.defaultOwner.name :
                        item.title}}
                    </div>
                    <div ng-if="item.isNotAllowed" class="common-size-xxxxs">No access</div>
                </div>
            </div>
        </li>
    </ul>
</script>
