import * as React from 'react';
import styled, { css } from 'styled-components';

import type { OcrOutput } from '@tonkean/tonkean-entities';

const PageSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const PageSelectorTitle = styled.div`
    margin-right: 5px;
`;

const PageNumberButton = styled.button<{ selected: boolean }>`
    border: none;
    background: transparent;
    padding: 0;
    margin: 0 5px 0 0;

    &:hover {
        text-decoration: underline;
    }

    ${({ selected }) =>
        selected &&
        css`
            color: blue;
            text-decoration: underline;
        `};
`;

interface Props {
    ocrOutput?: OcrOutput;
    selectedPageIndex: number;
    onPageSelected: (page: number) => void;
}

const DocumentPreviewPageSelector: React.FC<Props> = ({ ocrOutput, selectedPageIndex, onPageSelected }) => {
    return (
        <PageSelectorWrapper>
            <PageSelectorTitle>Page:</PageSelectorTitle>

            {ocrOutput?.pages.map((page) => (
                <PageNumberButton
                    key={page.index}
                    selected={selectedPageIndex === page.index}
                    onClick={() => onPageSelected(page.index)}
                >
                    {page.index + 1}
                </PageNumberButton>
            ))}
        </PageSelectorWrapper>
    );
};

export default DocumentPreviewPageSelector;
