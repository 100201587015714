<div class="communication-project-integration-selector" data-automation="tnk-communication-project-integrations-selector-template-communication-selector">
    <div>
        <ui-select
            class="ui-select-min-width"
            theme="bootstrap"
            ng-model="data.selectedCommunicationProjectIntegration"
            ng-change="onCommunicationIntegrationChanged(data.selectedCommunicationProjectIntegration)"
        >
            <ui-select-match placeholder="Select">
                <div
                    data-automation="{{ data.automationIdentifier }}"
                    ng-if="
                        data.selectedCommunicationProjectIntegration.integration.integrationUserDisplayName ||
                        data.selectedCommunicationProjectIntegration.displayNameFull
                    "
                    class="flex-vmiddle"
                >
                    <div
                        class="initiative-integration-icon mod-17 margin-right"
                        ng-class="
                            !data.selectedCommunicationProjectIntegration.iconUrl
                                ? 'mod-' +
                                  data.selectedCommunicationProjectIntegration.integration.integrationType.toLowerCase()
                                : ''
                        "
                        ng-style="{
                            background: data.selectedCommunicationProjectIntegration.iconUrl
                                ? 'url(' + data.selectedCommunicationProjectIntegration.iconUrl + ') no-repeat center'
                                : null
                        }"
                    ></div>
                    <div>
                        {{
                            data.selectedCommunicationProjectIntegration.integration.integrationUserDisplayName ||
                                data.selectedCommunicationProjectIntegration.displayNameFull
                        }}
                    </div>
                </div>
                <div
                    data-automation="{{ data.automationIdentifier }}"
                    ng-if="data.selectedCommunicationProjectIntegration.communicationIntegrationType"
                    class="flex-vmiddle"
                >
                    <div
                        ng-class="{
                            'mod-mail-option':
                                data.selectedCommunicationProjectIntegration.communicationIntegrationType === 'Email',
                            'worker-outline-tab-icon-connectors':
                                data.selectedCommunicationProjectIntegration.communicationIntegrationType ===
                                data.manageIntegrationsLiteral
                        }"
                        class="initiative-integration-icon mod-17 margin-right"
                    ></div>
                    <div>{{ data.selectedCommunicationProjectIntegration.communicationIntegrationType }}</div>
                </div>
            </ui-select-match>
            <ui-select-choices
                repeat="communicationProjectIntegration in data.communicationProjectIntegrationOptions | filter:$select.search"
            >
                <div
                    ng-if="
                        communicationProjectIntegration.integration.integrationUserDisplayName ||
                        communicationProjectIntegration.displayNameFull
                    "
                    class="flex-vmiddle"
                >
                    <div
                        data-automation="communication-source-option-{{
                            communicationProjectIntegration.integration.integrationUserDisplayName
                        }}"
                        class="initiative-integration-icon mod-17 margin-right"
                        ng-class="
                            !communicationProjectIntegration.iconUrl
                                ? 'mod-' + communicationProjectIntegration.integration.integrationType.toLowerCase()
                                : ''
                        "
                        ng-style="{
                            background: communicationProjectIntegration.iconUrl
                                ? 'url(' + communicationProjectIntegration.iconUrl + ') no-repeat center'
                                : null
                        }"
                    ></div>
                    <div
                        ng-bind-html="communicationProjectIntegration.integration.integrationUserDisplayName || communicationProjectIntegration.displayNameFull | highlight: $select.search"
                    ></div>
                </div>

                <div
                    data-automation="communication-sources-default-options-{{
                        communicationProjectIntegration.communicationIntegrationType
                    }}"
                    ng-if="communicationProjectIntegration.communicationIntegrationType"
                    class="flex-vmiddle"
                >
                    <div
                        ng-class="{
                            'mod-mail-option': communicationProjectIntegration.communicationIntegrationType === 'Email',
                            'worker-outline-tab-icon-connectors':
                                communicationProjectIntegration.communicationIntegrationType ===
                                data.manageIntegrationsLiteral
                        }"
                        class="initiative-integration-icon mod-17 margin-right"
                    ></div>
                    <div
                        ng-bind-html="communicationProjectIntegration.communicationIntegrationType | highlight: $select.search"
                    ></div>
                </div>
            </ui-select-choices>
        </ui-select>
    </div>
</div>
