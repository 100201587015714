import type { FormikProps } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as TriggerModuleIcon } from '../../../../../images/icons/trigger-module-flow.svg';
import FormSettingsUploadLogoIcon from '../../../../modules/FormBuilder/components/FormSettings/FormSettingsUploadLogoIcon';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { FormikTnkCustomFilters } from '@tonkean/angular-to-react-components';
import { TnkFieldSelector } from '@tonkean/angular-to-react-components';
import { ContractSelector } from '@tonkean/contracts';
import { Input, Separator, SimpleSelect, Spacer, useFormikField } from '@tonkean/infrastructure';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import type {
    ContractSummary,
    FieldDefinition,
    Group,
    MatchItemFieldDefinition,
    ProjectIntegration,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const NameContainer = styled.div`
    display: flex;
    margin: 15px 0;
    gap: 15px;
    align-items: center;
    width: 100%;
`;

const ModuleSelector = styled.div`
    height: 300px;
    overflow: scroll;
`;

const ModuleOption = styled(Clickable)`
    align-items: center;
    background: transparent;
    text-align: left;
    padding: 12px 0 10px 15px;
    width: 100%;
    border: none;
`;

const ModuleOptionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const OrderByContainer = styled.div`
    padding: 8px;
`;

const OrderByRow = styled.div`
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
`;

const Description = styled.div`
    color: ${Theme.colors.gray_500};
`;
const SectionContainer = styled.div`
    border: 1px solid ${Theme.colors.gray_400};
    border-radius: 8px;
    padding: 16px;
    background-color: ${Theme.colors.white};
`;

const SectionTitle = styled.div`
    font-size: ${FontSize.LARGE_16};
    padding: 0px 16px 16px;
    font-family: 'Lato', sans-serif;
    color: ${Theme.colors.gray_800};
`;

const SelectedModuleTitle = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.LARGE_16};
    font-family: 'Lato', sans-serif;
`;

const SelectedModuleModuleName = styled.div`
    font-size: ${FontSize.SMALL_12};
`;

const OrderByNote = styled.span`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
`;

const CheckWrapper = styled.span`
    width: 30px;
    display: grid;
    place-items: center;

    .tnk-icon {
        display: inline-flex;
    }

    & polygon {
        fill: ${Theme.colors.success};
    }
`;

const SelectedModuleButton = styled(Clickable)`
    align-items: center;
    background: transparent;
    text-align: left;
    padding: 12px 0 10px 15px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
`;

const SelectedModuleTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const orderByFieldTypes = [
    {
        key: 'textual',
        value: 'String',
        label: 'Textual',
    },
    {
        key: 'number',
        value: 'Number',
        label: 'Number',
    },
    {
        key: 'date',
        value: 'Date',
        label: 'Date',
    },
];
const orderTypes = [
    {
        key: 'ASC',
        value: 'ASC',
        label: 'Ascending',
    },
    {
        key: 'DESC',
        value: 'DESC',
        label: 'Descending',
    },
];

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersion: WorkflowVersion;
    selectedModule: Group | undefined;
    allGroups: Group[] | undefined;
    setSelectedModule: React.Dispatch<React.SetStateAction<any>>;
    projectIntegration: ProjectIntegration | undefined;
    fieldDefinitionToEdit: FieldDefinition<MatchItemFieldDefinition>;
    formik: FormikProps<FieldDefinition<MatchItemFieldDefinition>>;
    moduleSelectionReadOnly: boolean;
    contracts: ContractSummary[] | undefined;
    isLoadingContracts: boolean;
}

const FieldDefinitionMatchItemSettingsForm: React.FC<Props> = ({
    groupId,
    workflowVersion,
    selectedModule,
    allGroups,
    setSelectedModule,
    projectIntegration,
    fieldDefinitionToEdit,
    formik,
    moduleSelectionReadOnly,
    isLoadingContracts,
    contracts,
}) => {
    const isContractsEnabledFF = useFeatureFlag('tonkean_feature_enable_module_contract');

    const { value: selectedContractId } = useFormikField<TonkeanId<TonkeanType.CONTRACT>>(
        'definition.matchConfiguration.contractId',
    );

    const possibleGroups = useMemo(() => {
        const allGroupsWithoutCurrent = allGroups?.filter((module) => module.id !== groupId);

        if (!isContractsEnabledFF) {
            return allGroupsWithoutCurrent;
        }

        if (workflowVersion.workflowVersionType === undefined) {
            // historic versions will always show all groups.
            return allGroupsWithoutCurrent;
        }

        if (selectedContractId) {
            if (workflowVersion.workflowVersionType === WorkflowVersionType.DRAFT) {
                return allGroupsWithoutCurrent?.filter((module) => module.draftContractId === selectedContractId);
            }

            if (workflowVersion.workflowVersionType === WorkflowVersionType.PUBLISHED) {
                return allGroupsWithoutCurrent?.filter((module) => module.publishedContractId === selectedContractId);
            }
        }

        return allGroupsWithoutCurrent;
    }, [isContractsEnabledFF, workflowVersion.workflowVersionType, selectedContractId, allGroups, groupId]);

    return (
        <>
            <Description>
                To set a new matched item, configure which module you want to match the item with. Please note- there
                must be a relationship between the items in order to match them. The items are automatically matched by
                "Intake request id" field. You may add additional conditions.
            </Description>

            <NameContainer>
                Name: <Input name="name" />
            </NameContainer>

            {isContractsEnabledFF && (
                <NameContainer>
                    Contract (Optional):
                    <ContractSelector
                        size={InputSize.MEDIUM}
                        loading={isLoadingContracts}
                        contracts={contracts}
                        disabled={!!fieldDefinitionToEdit?.definition?.matchConfiguration?.contractId}
                        name="definition.matchConfiguration.contractId"
                    />
                </NameContainer>
            )}
            {!selectedModule && (
                <SectionContainer>
                    <SectionTitle>Select a module:</SectionTitle>

                    <Separator />

                    <ModuleSelector>
                        {possibleGroups?.map((module) => {
                            return (
                                <div key={`container_${module.id}`}>
                                    <ModuleOption key={module.id} onClick={() => setSelectedModule(module)} buttonAsDiv>
                                        <ModuleOptionWrapper>
                                            <TriggerModuleIcon /> {module.name}
                                        </ModuleOptionWrapper>
                                    </ModuleOption>
                                    <Separator key={`separator_${module.id}`} />
                                </div>
                            );
                        })}
                    </ModuleSelector>
                </SectionContainer>
            )}
            {selectedModule && (
                <SelectedModuleButton disabled={moduleSelectionReadOnly} onClick={() => setSelectedModule(undefined)}>
                    <SelectedModuleTitleContainer>
                        <SelectedModuleTitle>Selected module</SelectedModuleTitle>
                        <SelectedModuleModuleName>{selectedModule.name}</SelectedModuleModuleName>
                    </SelectedModuleTitleContainer>

                    <CheckWrapper>
                        <FormSettingsUploadLogoIcon isDirty isValid />
                    </CheckWrapper>
                </SelectedModuleButton>
            )}
            <Spacer height={8} width={5} />

            {projectIntegration && selectedModule && !selectedContractId && (
                <SectionContainer>
                    <SectionTitle>Module initiative id</SectionTitle>
                    <Separator />

                    <FormikTnkCustomFilters
                        name="definition.matchConfiguration.customQuery"
                        itemsSource="EXTERNAL"
                        selectedEntityType={projectIntegration.name}
                        projectIntegration={projectIntegration}
                        groupId={groupId}
                        workflowVersionId={workflowVersion.id}
                        notAllowedConditionsSet={{ IsMemberOf: true }}
                        hideCloseButton
                        editMode
                        useExpressionsForValue
                        verticalMod
                        smallMod
                        hideTimeRangeSelection
                        fromSync
                    />
                    <OrderByContainer>
                        <OrderByRow>
                            Order by field:
                            <TnkFieldSelector
                                workflowVersionId={selectedModule.draftWorkflowVersionId}
                                selectedField={fieldDefinitionToEdit.definition.matchConfiguration.orderByField}
                                itemsSource="COLUMN"
                                onFieldSelected={(fieldDefinition: FieldDefinition) => {
                                    formik.setFieldValue('definition.matchConfiguration.orderByField', fieldDefinition);
                                }}
                                placeHolder="Select module field"
                                fieldInspectModal={false}
                                fieldInspectAutoOpen={false}
                                allowClearSelection
                                reloadFields
                            />
                        </OrderByRow>

                        <OrderByRow>
                            Order:
                            <SimpleSelect name="definition.matchConfiguration.orderByOrder" options={orderTypes} />
                        </OrderByRow>

                        <OrderByRow>
                            Order field type:
                            <SimpleSelect
                                name="definition.matchConfiguration.orderByFieldType"
                                options={orderByFieldTypes}
                            />
                        </OrderByRow>
                    </OrderByContainer>

                    <OrderByNote>
                        Use the order by to define what initiative should be selected if more than one is matched.
                    </OrderByNote>
                </SectionContainer>
            )}
        </>
    );
};

export default FieldDefinitionMatchItemSettingsForm;
