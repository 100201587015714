import React from 'react';

import FormulaNodeOperand from './FormulaNodeOperand';
import FormulaNodeTree from './FormulaNodeTree';
import type SharedFormulaNodeProps from '../../entities/SharedFormulaNodeProps';

import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';

interface Props extends SharedFormulaNodeProps {
    /** The node to display */
    node: formulaTreeNode;
}

const FormulaNode: React.FC<Props> = ({
    depth,
    canDelete,
    node,
    disabled,
    onNodeChanged,
    onNodeDeleted,
    additionalTabs = [],
    customTrigger,
    projectIntegration,
}) => {
    switch (node.type) {
        case FormulaTreeNodeType.CONST:
        case FormulaTreeNodeType.EMPTY:
        case FormulaTreeNodeType.VARIABLE:
            return (
                <FormulaNodeOperand
                    depth={depth}
                    additionalTabs={additionalTabs}
                    customTrigger={customTrigger}
                    canDelete={canDelete}
                    disabled={disabled}
                    node={node}
                    onNodeChanged={onNodeChanged}
                    onNodeDeleted={onNodeDeleted}
                />
            );

        case FormulaTreeNodeType.TREE:
            return (
                <FormulaNodeTree
                    depth={depth}
                    additionalTabs={additionalTabs}
                    customTrigger={customTrigger}
                    projectIntegration={projectIntegration}
                    canDelete={canDelete}
                    disabled={disabled}
                    node={node}
                    onNodeChanged={onNodeChanged}
                    onNodeDeleted={onNodeDeleted}
                />
            );
    }
};

export default React.memo(FormulaNode);
