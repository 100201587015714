import { css } from 'styled-components';

import {
    ALPINE_THEME,
    ASC_ICON,
    CELL,
    CLICKABLE_ROW,
    DESC_ICON,
    EMPHASIZED_HEADER_CLASS_NAME,
    EMPTY_AND_REQUIRED_CLASS_NAME,
    ERRORED_ROW_CLASS_NAME,
    FILLED_FILTER_ICON_CLASS_NAME,
    FILTER_ICON,
    HEADER_MENU_BUTTON,
    REQUIRED_HEADER_CLASS_NAME,
    ROOT_WRAPPER,
    ROW,
    ROW_BACKGROUND_COLOR,
} from '../utils';

import { FontSize, Theme } from '@tonkean/tui-theme';

const itemsGridStyles = css<{
    $isEnlargeText?: boolean;
    isEmphasizeText?: boolean;
    backgroundColor?: string;
    $forceGridHeight?: boolean;
    $hasHorizontalBorder?: boolean;
    $hasVerticalBorder?: boolean;
}>`
    .${CELL} {
        // ag grid support enableCellTextSelection but when enable it will break the css for the "options" column and select inputs (status picker)
        // Ag-Grid has feature for copy but for enterprise only
        // for now this good enough.
        user-select: text;
    }

    .${ROOT_WRAPPER} {
        border: none;
    }

    .${FILTER_ICON} {
        display: none;
    }

    .${FILLED_FILTER_ICON_CLASS_NAME} {
        path {
            stroke: ${({ backgroundColor }) => `${backgroundColor}`};
        }

        svg {
            fill: ${({ backgroundColor }) => `${backgroundColor}`};
        }
    }

    .${ALPINE_THEME} {
        --ag-row-hover-color: ${({ backgroundColor }) => `${backgroundColor}1A`}; // 10% opacity
        --ag-odd-row-background-color: white;
        --ag-font-family: Roboto;
        --ag-row-vertical-border: ${({ $hasVerticalBorder }) =>
            $hasVerticalBorder ? `1px solid ${Theme.colors.gray_300}` : '0px'};
        --ag-cell-horizontal-border: ${({ $hasHorizontalBorder }) =>
            $hasHorizontalBorder ? `1px solid ${Theme.colors.gray_300}` : '0px'};
        --ag-header-background-color: var(${Theme.colors.gray_100}, #fcfcfb);
        --ag-header-foreground-color: ${Theme.colors.gray_600};
        --ag-header-row-font-weight: 300;
        --ag-value-change-value-highlight-background-color: ${({ backgroundColor }) =>
            `${backgroundColor}1A`}; // 10% opacity
    }

    .${ASC_ICON}, .${DESC_ICON} {
        color: ${Theme.colors.gray_600};
    }

    .${HEADER_MENU_BUTTON} {
        svg path {
            stroke: ${Theme.colors.gray_600};
        }
    }

    .${ROW} {
        font-weight: ${({ isEmphasizeText, $isEnlargeText }) =>
            `${isEmphasizeText ? ($isEnlargeText ? 400 : 'bold') : 'normal'}`};
        font-size: ${({ isEmphasizeText, $isEnlargeText }) =>
            `${isEmphasizeText && $isEnlargeText ? FontSize.XXXXLARGE_24 : FontSize.MEDIUM_14}`};
    }

    // Prevent the overscrolling so we won't navigate back

    .ag-center-cols-viewport {
        overscroll-behavior-x: none;
    }

    .${ROW_BACKGROUND_COLOR} {
        background-color: ${({ backgroundColor }) => `${backgroundColor}1A`}; // 10% opacity;
    }

    .ag-cell-last-left-pinned {
        border: none;
    }

    ${({ $hasHorizontalBorder }) =>
        !$hasHorizontalBorder &&
        css`
            .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell),
            .ag-pinned-left-header {
                border-right: none;
            }
        `};

    .${CLICKABLE_ROW} {
        cursor: pointer;
    }

    .ag-root-wrapper-body.ag-layout-normal {
        height: ${({ $forceGridHeight }) => ($forceGridHeight ? '100%' : '0')};
    }

    .${ERRORED_ROW_CLASS_NAME} {
        div {
            border-left: 3px solid #d10027;
            background-color: #d100270d;
        }
    }

    .${EMPTY_AND_REQUIRED_CLASS_NAME} {
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        background-color: #d100270d;
    }

    .${REQUIRED_HEADER_CLASS_NAME} {
        .ag-header-cell-text {
            ::after {
                content: '(*)';
                margin-left: 4px;
                color: red;
            }
        }
    }

    .${EMPHASIZED_HEADER_CLASS_NAME} {
        font-size: 24px;
        font-weight: 500;
    }
`;

export default itemsGridStyles;
