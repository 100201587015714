import template from './tnkLogicWorkerComponent.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkLogicWorkerComponent', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        workflowVersionType: '@',
        includeSpaceFillerForWarningMessage: '<',
        displaysHistoryVersion: '<',
        configuredLogic: '<',
        syncObject: '<',
        invalidLogics: '<',
        getSpecialChildren: '<',
        createMultipleLogicComponents: '&',
        updateMultipleLogicComponents: '&',
        duplicateLogic: '&',
        removeSpecialChildren: '<',
        onComponentClosed: '&',
        onActionsChanged: '&',
        onRequestValidation: '&',
        logicConfigMode: '<',
        createNewLogicCallback: '&',
        onSideBySideDiffSelected: '&',
        workflowFolderProjectIntegrationsAccess: '<',
        previewEvaluationSource: '<',
        openFieldModal: '<',
        viewOnly: '<',
        noHeader: '<',
        filtersResultMap: '<',
    },
    template,
    controller: 'LogicWorkerComponentCtrl',
});
