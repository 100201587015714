import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function EmailWebhookCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        definition: ctrl.definition,
    };

    ctrl.$onInit = function () {
        const actionType = $scope.data.definition.customActionKey;

        switch (actionType) {
            case 'UPDATE_EMAIL_THREAD':
                if (!$scope.data.definition.newFields) {
                    $scope.data.definition.newFields = [];
                    $scope.addNewField();
                }
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    /**
     * New Fields
     */
    $scope.addNewField = function () {
        $scope.data.definition.newFields.push({});
    };

    $scope.removeNewField = function (index) {
        $scope.data.definition.newFields.splice(index, 1);
        $scope.onDefinitionChanged(true);
    };

    $scope.onFieldKeyTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.newFields[index].fieldKeyExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFieldValueTonkeanExpressionChanged = function (expression, index, shouldSaveLogic) {
        $scope.data.definition.newFields[index].fieldValueExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'EmailWebhookCustomActionsDefinitionCtrl',
        lateConstructController(EmailWebhookCustomActionsDefinitionCtrl),
    );
