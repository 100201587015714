// Custom tab for tonkean expression with payload.
import { useMemo } from 'react';

import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

const useWebhookPayloadAdditionalTab = () => {
    return useMemo<TonkeanExpressionAdditionalTab>(() => {
        return {
            id: 'RESPONSE_PARAMS',
            label: 'Payload Params',
            iconClass: 'mod-handle-response',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: () => {
                // The fields ids are taken for the server so if we need to change remember to change from the server.
                return Promise.resolve([
                    {
                        name: 'payload',
                        value: 'payload',
                        label: 'Payload',
                        id: 'payload',
                    },
                ]);
            },
        };
    }, []);
};

export default useWebhookPayloadAdditionalTab;
