import SimpleLegalAuthenticationComponent from './SimpleLegalAuthenticationComponent';
import simpleLegalCircleIcon from '../../../../apps/tracks/images/integrations/simplelegal-circle.svg';
import simpleLegalIcon from '../../../../apps/tracks/images/simplelegal.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';
import { DataSourcesSectionType } from '@tonkean/tonkean-entities';

class SimpleLegalIntegration extends IntegrationDefinitionBase {
    override name = 'simplelegal';
    override displayName = 'SimpleLegal';
    override description = 'We will only collect Cost Codes, Entities, Matters and Users.';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override externalActivityCreatedDateFieldName = 'createdate';
    override entities = [
        new IntegrationEntity('Accrual', 'Accruals'),
        new IntegrationEntity('AccrualSubmission', 'Accrual Submissions'),
        new IntegrationEntity('CostCode', 'Cost Codes'),
        new IntegrationEntity('CustomAttribute', 'Custom Attributes'),
        new IntegrationEntity('Entity', 'Entities'),
        new IntegrationEntity('Matter', 'Matters'),
        new IntegrationEntity('MatterGroup', 'Matter Groups'),
        new IntegrationEntity('Template', 'Templates'),
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Vendor', 'Vendors'),
        new IntegrationEntity('VendorGroup', 'Vendor Groups'),
    ];

    override customizedAuthenticationComponent = SimpleLegalAuthenticationComponent;
    override supportsSharedCredentials = true;

    override dataSourceSectionType = DataSourcesSectionType.CLOUD;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(simpleLegalCircleIcon, '130px'),
        new IntegrationIcon(simpleLegalIcon, '115px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
        daysDefault: { free: 7 },
    };
}

export default SimpleLegalIntegration;
