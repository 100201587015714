import React from 'react';
import styled from 'styled-components';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { ModalProps } from '@tonkean/infrastructure';
import { ModalBody, ModalFooterActions, ModalHeader, Paragraph, Placeholder, withModal } from '@tonkean/infrastructure';
import type { Group, Project } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const ErrorMessage = styled(Paragraph)`
    color: ${Theme.colors.error};
`;

const UsedActionWrapper = styled(ErrorMessage)`
    display: flex;
`;

const Body = styled(ModalBody)`
    gap: 8px;
    display: flex;
    flex-direction: column;
`;

interface Props extends ModalProps {
    projectId: Project['id'];
    groupId: Group['id'];
    statusId: string;
    statusName: string;
    onDelete: () => Promise<void>;
}

const DeleteStatusModal: React.FC<Props> = ({ projectId, groupId, statusId, statusName, onDelete }) => {
    const { loading, data, error } = useTonkeanService('getIsStateDeletable', projectId, groupId, statusId);

    return (
        <>
            <ModalHeader $border>Delete Status "{statusName}"</ModalHeader>

            <Body>
                {data?.isDeletable && <Paragraph>Are you sure you want to delete?</Paragraph>}
                {loading && <Placeholder $height="20px" $width="auto" />}

                {data?.isDeletable === false && (
                    <ErrorMessage data-automation="delete-status-modal-error-message">
                        Status can't be deleted because the status is in use in the following actions -
                        <UsedActionWrapper>
                            {data?.usedCustomTriggers.map((name) => <React.Fragment key={name}>{name}</React.Fragment>)}
                        </UsedActionWrapper>
                    </ErrorMessage>
                )}
            </Body>

            <ModalFooterActions
                saveButtonProps={{ disabled: data?.isDeletable === false || loading }}
                error={error}
                loading={loading}
                saveLabel="Delete"
                onSave={onDelete}
                border
                isDelete
            />
        </>
    );
};

export default withModal(DeleteStatusModal, { size: 500, fixedWidth: true });
