import type { IntrospectionQuery } from 'graphql';
import type {
    IntrospectionNonNullTypeRef,
    IntrospectionObjectType,
    IntrospectionTypeRef,
} from 'graphql/utilities/getIntrospectionQuery';

export interface TonkeanGraphqlCacheQueryResolversConfig {
    queryName: string;
    typeName: string;
    argName: string;
}

const getEntityGyIdSymbol = '[_GET_ENTITY_BY_ID_QUERY_]';

export const getNonNullIntrospectionType = <T extends IntrospectionTypeRef>(type: T | undefined) => {
    const nonNull: IntrospectionNonNullTypeRef['kind'] = 'NON_NULL';

    if (!type || type.kind !== nonNull) {
        throw new Error(`${type?.['name'] ?? ''} expected to be ${nonNull} but was ${type?.kind}`.trim());
    }

    return type.ofType;
};

function getTonkeanGraphqlCacheQueryResolversConfig(
    schema: IntrospectionQuery,
): TonkeanGraphqlCacheQueryResolversConfig[] {
    const queryType = schema.__schema.types.find(
        (type) => type.name === schema.__schema.queryType.name,
    ) as IntrospectionObjectType;

    return queryType.fields
        .filter((field) => field.description?.includes(getEntityGyIdSymbol))
        .map((field) => {
            const errorPrefixMustHave = `Query with ${getEntityGyIdSymbol} in it's description (named ${field.name}) must have`;

            if (field.args.length !== 1) {
                throw new Error(`${errorPrefixMustHave} one argument`);
            }

            const argType = getNonNullIntrospectionType(field.args[0]!.type);
            const fieldType = getNonNullIntrospectionType(field.type);

            const isMultipleReturnType = fieldType.kind === 'LIST';
            const isMultipleInput = argType.kind === 'LIST';
            if (isMultipleReturnType !== isMultipleInput) {
                throw new Error(
                    `${errorPrefixMustHave} list as a return type if the argument is a list, or a single object as a return type if the argument is not a list`,
                );
            }

            const returnType = isMultipleReturnType ? getNonNullIntrospectionType(fieldType.ofType) : fieldType;
            if (returnType.kind !== 'OBJECT') {
                throw new Error(`${errorPrefixMustHave} object as a return type. A scalar, union, etc are not allowed`);
            }

            const inputType = isMultipleInput ? getNonNullIntrospectionType(argType.ofType) : argType;
            const inputIsId = inputType.kind === 'SCALAR' && inputType.name === 'ID';
            if (!inputIsId && inputType.kind !== 'INPUT_OBJECT') {
                throw new Error(`${errorPrefixMustHave} either an object as an input type, or an ID scalar`);
            }

            return {
                queryName: field.name,
                argName: field.args[0]!.name,
                typeName: returnType.name,
            };
        });
}

export default getTonkeanGraphqlCacheQueryResolversConfig;
