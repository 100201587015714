function AddUserPopoverCtrl($scope, $rootScope, licensePermissionsService) {
    $scope.pm = $rootScope.pm;
    $scope.lps = licensePermissionsService;
    $scope.popover = {};

    $scope.data = {
        customMessage: null,
        selectedPeople: [...$scope.selectedPeople],
        maxTags: $scope.maxTags ?? 'Infinity',
    };

    $scope.$watch('selectedPeople', function (value) {
        $scope.data.selectedPeople = [...value];
    });

    $scope.updateSelected = function () {
        $scope.data.selectedPeople.forEach((user) => {
            if (user.customEmail) {
                user.customEmail = false;
                user.name = user.email;
                user.initials = user.email[0];
            }
        });
    };

    $scope.save = function () {
        const emails = ($scope.data.selectedPeople || []).map((user) => user.email).join(', ');
        $scope.onSave({ data: emails });
        $scope.onClose();
    };

    $scope.close = function () {
        $scope.popover.isOpen = false;
        // clean the manual date so if the user opens the modal again he wont see old settings
        $scope.manualNextUpdate = {};

        if ($scope.onClose) {
            $scope.onClose();
        }
    };
}

angular.module('tonkean.app').controller('AddUserPopoverCtrl', AddUserPopoverCtrl);
