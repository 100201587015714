import type SolutionSite from './SolutionSite';
import type SolutionSiteThemeConfiguration from './SolutionSiteThemeConfiguration';
import type { FullProject } from '../Project';

/**
 * Returns the correct theme configuration, if project theme is forced will take that
 */
export const useSolutionSiteThemeConfiguration = (
    solutionSite: Pick<SolutionSite, 'themeConfiguration'>,
    project: Pick<FullProject, 'themeConfiguration' | 'forceThemeConfiguration'>,
): SolutionSiteThemeConfiguration => {
    const projectThemeConfiguration = project.themeConfiguration;
    if (project.forceThemeConfiguration) {
        return {
            ...solutionSite.themeConfiguration,
            headerBackgroundColor: projectThemeConfiguration.primaryColor,
            logoUrl: projectThemeConfiguration.logoUrl,
        };
    }

    return solutionSite.themeConfiguration;
};
