import template from './tnkEditDropdownOptionsPopover.template.html?angularjs';

angular.module('tonkean.app').component('tnkEditDropdownOptionsPopover', {
    bindings: {
        dropdownConfig: '<',
        onClose: '<',
        initiativeId: '<',
        fieldDefinitionId: '<',
        dropdownSource: '<',
    },
    transclude: true,
    template,
    controller: 'EditDropdownOptionsPopoverCtrl',
});
