import React from 'react';

import { ActivityType } from '@tonkean/tonkean-entities';
import type { Initiative } from '@tonkean/tonkean-entities';
import type { Activity } from '@tonkean/tonkean-entities';

interface Props {
    event: Activity;
    fieldsMap?: any;
}

const TimelineEventMessage: React.FC<Props> = ({ event, fieldsMap }) => {
    switch (event.type) {
        case ActivityType.NEW_COMMENT: {
            return <>commented</>;
        }
        case ActivityType.INITIATIVE_CREATED: {
            const initiative = event.target as Initiative;
            return <>created {initiative.title}</>;
        }

        case ActivityType.NEW_INITIATIVE: {
            return <>created a new item</>;
        }
        case ActivityType.INITIATIVE_DESCRIPTION_CHANGED: {
            return <>changed the description</>;
        }
        case ActivityType.INITIATIVE_TAGS_CHANGED: {
            return <>changed the goals</>;
        }

        case ActivityType.INITIATIVE_LEADER_CHANGED: {
            return <>changed the owner</>;
        }
        case ActivityType.INITIATIVE_TITLE_CHANGED: {
            return <>changed the title</>;
        }

        case ActivityType.INITIATIVE_DUE_DATE_CHANGED: {
            return <>changed the due date</>;
        }
        case ActivityType.INITIATIVE_STATUS_CHANGED: {
            return (
                <>
                    {'has marked this initiative as '}
                    <span style={{ color: event.metadata?.newState?.stateColor }}>
                        {event.metadata?.newState?.stateText}
                    </span>
                </>
            );
        }

        case ActivityType.FORM_SUBMITTED: {
            return <>submitted form '{event.metadata?.formName}'</>;
        }

        case ActivityType.ITEM_INTERFACE_SUBMITTED: {
            return <>submitted item interface '{event.metadata?.itemInterfaceName}'</>;
        }

        case ActivityType.ITEM_INTERFACE_BUTTON_CLICKED: {
            return <>clicked on button '{event.metadata?.customTriggerName}'</>;
        }

        case ActivityType.INNER_INITIATIVE_CREATED: {
            return <>created item '{event.metadata?.initiativeName}'</>;
        }

        case ActivityType.INITIATIVE_FILE_UPLOADED: {
            return <>uploaded file '{event.metadata?.fileName}'</>;
        }

        case ActivityType.INITIATIVE_FUNCTION_UPDATE_DATA_CHANGED: {
            if (event.metadata?.nothingChanged) {
                return <>updated that the status is still the same</>;
            }

            return (
                <>
                    updated the status to{' '}
                    <span style={{ color: event.metadata?.newState?.stateColor }}>
                        {event.metadata?.newState?.stateText}
                    </span>
                </>
            );
        }
        case ActivityType.INITIATIVE_MANUAL_FIELD_VALUE_CHANGED: {
            const workflowVersionId =
                event.reference2.fieldDefinition?.workflowVersionId ?? event.reference2.workflowVersionId;
            const fieldDefinitionId = event.reference2.fieldDefinition?.id ?? event.reference2.id;

            const newValue = fieldsMap?.[workflowVersionId]?.[fieldDefinitionId]?.name ?? event?.reference2?.name;
            return <>changed the value of {newValue}</>;
        }
        case ActivityType.BOT_GATHER: {
            return <>Tonkean module reached out to </>;
        }

        default: {
            console.error(`Timeline Title Unhandled "event.type": ${event.type}`);
            return null;
        }
    }
};

export default TimelineEventMessage;
