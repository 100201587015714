import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import type { OcrBlock } from '@tonkean/tonkean-entities';
import { getIndexedMultiDimensionCollection } from '@tonkean/utils';

const Paragraph = styled.div`
    margin-bottom: 5px;
`;

interface Props {
    ocrBlock: OcrBlock;
}

const DocumentPreviewBlock: React.FC<Props> = ({ ocrBlock }) => {
    const indexedParagraphLines = useMemo(() => {
        return getIndexedMultiDimensionCollection(ocrBlock.paragraphsLines || []);
    }, [ocrBlock]);

    return (
        <>
            {indexedParagraphLines.map((indexedParagraph) => (
                <Paragraph key={indexedParagraph.index}>
                    {indexedParagraph.collection.map((line) => (
                        <React.Fragment key={line.index}>
                            {line.original} <br />
                        </React.Fragment>
                    ))}
                </Paragraph>
            ))}
        </>
    );
};

export default DocumentPreviewBlock;
