import React, { useContext } from 'react';

import ProjectIntegrationFrontDoorSettings from './ProjectIntegrationFrontDoorSettings';
import EnterpriseComponentPageTemplate from '../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import ProjectIntegrationPageContext from '../ProjectIntegrationPageContext';

const ProjectIntegrationFrontDoorPage: React.FC = () => {
    const { currentProjectIntegration } = useContext(ProjectIntegrationPageContext);
    return (
        <EnterpriseComponentPageTemplate
            name="AI Front Door - Data Access"
            description="Define access permissions to the data source entities and records, based on predefined business logic"
        >
            {currentProjectIntegration && (
                <ProjectIntegrationFrontDoorSettings projectIntegration={currentProjectIntegration} />
            )}
        </EnterpriseComponentPageTemplate>
    );
};

export default ProjectIntegrationFrontDoorPage;
