import FunctionOperatorBase from './FunctionOperatorBase';
import { separatorCharsPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class ArrayCommonFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.ARRAY_COMMON;
    override readonly sign = 'ArrayCommon';
    override readonly displayName = 'Array Common';
    override readonly description =
        'Will return the common items from the 2 arrays (match array) as a comma-separated array string.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'First Array',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Second Array',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Separator',
            dataType: FieldType.String,
            defaultValue: ',',
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: separatorCharsPreset,
        },
    ];
}
