import React from 'react';

import type { SearchItemImageBaseProps } from './SearchItemImageBase';
import SearchItemImageBase, { ImageContainer, StyledImg } from './SearchItemImageBase';
import useStreamServiceImage from '../../hooks/useStreamServiceImage';
import { SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE } from '../SearchWidgetConfiguration';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface SearchItemStreamImageProps extends SearchItemImageBaseProps {
    searchEntityProjectIntegrationId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    imageStreamSelectedActionId?: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>;
    projectId?: TonkeanId<TonkeanType.PROJECT>;
}

const SearchItemStreamImage: React.FC<SearchItemStreamImageProps> = ({
    entity,
    cardsDensityConfiguration,
    imagePlaceholderMode = SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE,
    searchEntityProjectIntegrationId,
    imageStreamSelectedActionId,
    projectId,
}) => {
    const imageWidth = cardsDensityConfiguration.image.width;
    const imageHeight = cardsDensityConfiguration.image.height;

    const fetchedImageUrl = useStreamServiceImage(
        entity.imageUrl,
        searchEntityProjectIntegrationId,
        projectId,
        entity.id,
        imageStreamSelectedActionId,
    );

    if (fetchedImageUrl) {
        // Render your component with successful data
        return (
            <ImageContainer $width={imageWidth}>
                <StyledImg src={fetchedImageUrl} alt={`item-${entity.id}`} $width={imageWidth} $height={imageHeight} />
            </ImageContainer>
        );
    }

    // Render your component with an error message if retries fail
    return (
        <SearchItemImageBase
            cardsDensityConfiguration={cardsDensityConfiguration}
            entity={entity}
            imagePlaceholderMode={imagePlaceholderMode}
        />
    );
};

export default SearchItemStreamImage;
