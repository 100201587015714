import leverCircleIcon from '../../../../apps/tracks/images/integrations/lever-circle.png';
import leverIcon from '../../../../apps/tracks/images/lever.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class LeverIntegration extends IntegrationDefinitionBase {
    override name = 'lever';
    override displayName = 'Lever';
    override description = 'We will only collect users and feedbacks and opportunities items.';
    override authenticationType = AuthenticationMethod.API_TOKEN;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [
        new IntegrationEntity('User', 'Users'),
        new IntegrationEntity('Feedback', 'Feedbacks'),
        new IntegrationEntity('Opportunity', 'Opportunities'),
        new IntegrationEntity('Application', 'Applications'),
        new IntegrationEntity('Interview', 'Interviews'),
        new IntegrationEntity('FeedbackField', 'Feedback Fields'),
        new IntegrationEntity('FeedbackTemplate', 'Feedback Templates'),
        new IntegrationEntity('FeedbackTemplateField', 'Feedback Template Fields'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(leverCircleIcon, '130px'),
        new IntegrationIcon(leverIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: false,
        removableEntities: {},
        editableEntities: {
            User: true,
            Feedback: true,
            Opportunity: true,
            Application: true,
            Interview: true,
            FeedbackField: true,
            FeedbackTemplate: true,
            FeedbackTemplateField: true,
        },
        isOnlyDataRetention: {},
    };
}

export default LeverIntegration;
