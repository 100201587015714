import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

const MarketoAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [subdomain, setSubdomain] = useState<string>('');
    const [clientId, setClientId] = useState<string>('');
    const [clientSecret, setClientSecret] = useState<string>('');

    const authenticateWithClientIdAndSecret = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                api_subdomain: subdomain,
                api_client_id: clientId,
                api_client_secret: clientSecret,
            };
            authenticate(config, {}, `${integrationConfiguration.displayName} (${subdomain})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithClientIdAndSecret}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="marketo-subdomain" className="col-sm-3 control-label">
                            API Subdomain
                        </label>
                        <div className="col-sm-8 flex-vmiddle">
                            <span>https://</span>
                            <input
                                type="text"
                                value={subdomain}
                                className="form-control"
                                id="marketo-subdomain"
                                onChange={({ target }) => setSubdomain(target.value)}
                                autoComplete="off"
                                placeholder="Subdomain"
                                required
                            />
                            <span>.mktorest.com/rest</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-id" className="col-sm-3 control-label">
                            Client ID
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={clientId}
                                className="form-control"
                                id="api-client-id"
                                onChange={({ target }) => setClientId(target.value)}
                                autoComplete="off"
                                placeholder="Client ID"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="api-client-secret" className="col-sm-3 control-label">
                            Client Secret
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={clientSecret}
                                className="form-control"
                                onChange={({ target }) => setClientSecret(target.value)}
                                id="api-client-secret"
                                autoComplete="off"
                                placeholder="Client Secret"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                Foe steps to create an API user and get the credentials,{' '}
                                <a href="https://developers.marketo.com/rest-api/" target="_blank">
                                    click here
                                </a>
                                .
                            </span>
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MarketoAuthenticationComponent;
