import template from './tnkUpdateFieldValue.template.html?angularjs';

/**
 * A component to edit and update field values.
 * Supports different kinds of fields: manual, manual date and external list.
 *
 * *******************************************************************
 * This component also supports creating new fields (not key metrics).
 * Simply pass a fieldDefinition and null for the field.
 */
angular.module('tonkean.app').component('tnkUpdateFieldValue', {
    bindings: {
        field: '<',
        fieldDefinition: '<',
        focus: '<',
        isDisabled: '<',
        onSaved: '&',
        delegateSave: '<',
        doNotUpdateInServer: '<',
        appendDatePickerToBody: '<', // Boolean, if true appends the date picker popup to body
        targetId: '<', // What entity is this field related to.
        onFieldChanged: '&',
        group: '<',
        initiative: '<',
        invalid: '<',
        isOnlyField: '<', // Whether to show a big 'submit' button like in ask for info on the web
        inlineUpdate: '<', // whether to display an inline update button
        buttonsColor: '<',
        workflowVersionType: '<',
    },
    template,
    controller: 'UpdateFieldValueCtrl',
});
