<div ng-if="!projectIntegartionId || projectIntegartionId === 'new'">
    <div class="edit-integrations-container org-content-page">
        <div ng-if="data.projectIntegrationSummaries">
            <div ng-if="data.loadProjectIntegrationsErrorMessage" class="common-color-danger">
                {{ data.loadProjectIntegrationsErrorMessage }}
            </div>
            <div class="flex-vmiddle search-projectintegration-input">
                <div class="flex-no-shrink margin-right-lg common-width-60">
                    <div class="rounded-box flex-vmiddle setup-group-built-in-search">
                        <span class="svg-icon-sm margin-left">
                            <tnk-icon src="/images/icons/search.svg"></tnk-icon>
                        </span>
                        <input
                            autofocus
                            class="form-control common-width-full common-no-border common-no-outline"
                            placeholder="Search Connected Data Sources"
                            ng-model="data.searchTerm"
                        />
                    </div>
                </div>
                <div
                    ng-if="data.searchTerm && data.searchTerm.length"
                    class="alert alert-info common-size-xxxxs padding-normal-sm flex-vmiddle margin-none"
                >
                    <a ng-click="data.searchTerm = null">
                        <i class="fa fa-times margin-right-xs inline-block"></i>
                        Clear search
                    </a>
                    <span class="margin-left flex-grow">Showing data sources related to "{{ data.searchTerm }}".</span>
                </div>
            <!-- Button for adding data sources -->
                <div uib-dropdown on-toggle="toggled(open)">
                    <button
                        type="button"
                        class="btn btn-primary edit-integrations-add-button"
                        data-automation="edit-integrations-add-from-beginning-new-data-source-button"
                        uib-dropdown-toggle
                    >
                        + New Data Source
                    </button>
                    <ul uib-dropdown-menu class="actions-menu mod-medium dropdown-menu dropdown-menu-right" role="menu">
                        <!-- Cloud -->
                        <li ng-click="openAddNewIntegrationModal()">
                            <span
                                class="svg-icon-sm margin-right"
                                data-automation="edit-integrations-template-cloud-application"
                            >
                                <tnk-icon src="/images/icons/plug-o.svg"></tnk-icon>
                            </span>
                            Cloud Application
                        </li>
                        <!-- Spreadsheet -->
                        <li ng-click="openAddNewIntegrationModal('sheets || excel || smartsheet || googledrive')">
                            <span class="svg-icon-sm margin-right" data-automation="edit-integration-spreadsheet">
                                <tnk-icon src="/images/icons/googlesheets-logo.svg"></tnk-icon>
                            </span>
                            Spreadsheet / Files
                        </li>
                        <!-- SQL -->
                        <li ng-click="openAddNewIntegrationModal('sql || amazon')">
                            <!--<span class="svg-icon-sm margin-right">-->
                            <!--<tnk-icon src="/images/icons/history/data-source.svg"></tnk-icon>-->
                            <!--</span>-->
                            <i
                                class="fa fa-database margin-right common-color-primary"
                                data-automation="edit-integration-sql-data-base"
                            ></i>
                            Database / Storage / Bus
                        </li>
                        <!-- Chat -->
                        <li ui-sref="product.enterpriseComponents({ tab: 'communication-sources' })">
                            <!--<span class="svg-icon-sm margin-right">-->
                            <!--<tnk-icon src="/images/icons/history/data-source.svg"></tnk-icon>-->
                            <!--</span>-->
                            <span class="svg-icon-sm margin-right">
                                <tnk-icon src="/images/icons/message-bubbles.svg"></tnk-icon>
                            </span>
                            Chat (Slack/MS Teams)
                        </li>
                        <!-- Email -->
                        <li ng-click="openAddNewIntegrationModal('emailwebhook')">
                            <!--<span class="svg-icon-sm margin-right">-->
                            <!--<tnk-icon src="/images/icons/history/data-source.svg"></tnk-icon>-->
                            <!--</span>-->
                            <span class="margin-right" data-automation="edit-integration-email-inbox">
                                <img src="/images/integrations/emailwebhook-circle.png" width="12px" />
                            </span>
                            Email Inbox
                        </li>
                        <!-- Custom -->
                        <li ng-click="openAddNewIntegrationModal('Custom Source')">
                            <span class="svg-icon-sm margin-right" data-automation="edit-integration-custom-source">
                                <tnk-icon src="/images/icons/webhook.svg"></tnk-icon>
                            </span>
                            <span>Custom / Internal API</span>
                        </li>
                        <li ng-if="data.showCreateFromJsonButton" ng-click="openImportFromCreationJsonModal()">
                            <span
                                class="svg-icon-sm margin-right"
                                data-automation="edit-integration-import-from-creation-json"
                            >
                                <tnk-icon src="/images/icons/webhook.svg"></tnk-icon>
                            </span>
                            <span>Import From Creation JSON</span>
                            <tnk-import-from-creation-json-modal
                                is-open="data.isImportFromCreationJsonModalOpen"
                                on-close="(closeImportFromCreationJsonModal)"
                            ></tnk-import-from-creation-json-modal>
                            <tnk-enterprise-component-variables-values-modal
                                ng-if="data.creationJsonEnterpriseComponentId"
                                is-open="data.isEnterpriseComponentVariablesValuesModalOpen"
                                project-integration-id="data.creationJsonEnterpriseComponentId"
                                on-close="(closeEnterpriseComponentVariablesModal)"
                            ></tnk-enterprise-component-variables-values-modal>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="flex-vmiddle filter-checkbox-header">
                Show sources that you can:

                <tnk-checkbox class="filter-checkbox" label="'Manage'" value="data.isManageShown"></tnk-checkbox>

                <tnk-checkbox class="filter-checkbox" label="'View'" value="data.isViewShown"></tnk-checkbox>

                <tnk-checkbox
                    class="filter-checkbox"
                    label="'Inaccessible'"
                    value="data.isNoAccessShown"
                ></tnk-checkbox>
            </div>

            <!-- Integration list -->
            <div class="margin-top-lg">
                <!-- Header -->

                <div
                    class="
                        flex-vmiddle
                        data-source-row
                        common-bold-title common-size-xxxxs common-color-dark-grey
                        text-uppercase
                    "
                >
                    <div class="data-source-col-name flex-grow">
                        Data Sources ({{
                            (
                                data.projectIntegrationSummaries
                                | filter: filterProjectIntegrationsBySearchTerm
                                | filter: filterProjectIntegrationsByCheckboxes
                                | filter: filterProjectIntegrationsBySystemUtilized
                            ).length
                        }})
                    </div>
                    <div class="data-source-col">Last data collect</div>
                    <div class="data-source-col">Used by</div>
                    <div class="data-source-col">Status</div>
                    <div class="data-source-col">Access Type</div>
                </div>

                <i ng-if="data.isLoading" class="loading-integrations loading-large"></i>
                <div ng-if="!data.isLoading">
                    <!-- rows-->
                    <div
                        ng-repeat="projectIntegration in data.projectIntegrationSummaries | filter:filterProjectIntegrationsBySearchTerm | filter:filterProjectIntegrationsByCheckboxes | filter:filterProjectIntegrationsBySystemUtilized | orderBy:'displayName' "
                        class="
                            flex-vmiddle
                            data-source-row
                            common-selectable-grey
                            pointer
                            common-size-xxs common-color-dark-grey
                        "
                        ng-click="navigateToProjectIntegrationPage(projectIntegration.id)"
                    >
                        <div
                            class="data-source-col-name flex-grow integration-selection-button"
                            data-automation="integration-group-box-mode-template-data-source-name-{{ projectIntegration.displayName }}"
                        >
                            <!-- Integration Icon -->
                            <i
                                class="initiative-integration-icon mod-normal margin-right"
                                ng-class="{
                                    'mod-error': projectIntegration.valid === false || projectIntegration.disabled,
                                    'mod-{{projectIntegration.integration.integrationType.toLowerCase()}}':
                                        !projectIntegration.iconUrl
                                }"
                                ng-style="{
                                    background: projectIntegration.iconUrl
                                        ? 'url(' + projectIntegration.iconUrl + ') no-repeat center'
                                        : null
                                }"
                            ></i>

                            <!-- Integration Name -->
                            <div
                                class="common-ellipsis integration-display-name-box"
                                id="integration-display-name-box-{{ projectIntegration.id }}"
                                tooltip-enable="utils.hasEllipsis('#integration-display-name-box-{{
                                    projectIntegration.id
                                }}')"
                                uib-tooltip="{{
                                    projectIntegration.displayName +
                                        (projectIntegration.integration.isNativeIntegration &&
                                        !projectIntegration.integration.supportsMultipleIntegrationsPerUser
                                            ? ' (' + projectIntegration.creator.firstName + ')'
                                            : '')
                                }}"
                            >
                                {{
                                    projectIntegration.displayName +
                                        (projectIntegration.integration.isNativeIntegration &&
                                        !projectIntegration.integration.supportsMultipleIntegrationsPerUser
                                            ? ' (' + projectIntegration.creator.firstName + ')'
                                            : '')
                                }}
                            </div>
                        </div>

                        <!-- Last update -->
                        <div class="data-source-col">
                            <span
                                uib-tooltip="{{ projectIntegration.lastCollect | date: 'medium' }}"
                                ng-if="projectIntegration.lastCollect"
                            >
                                {{ projectIntegration.lastCollect | timeAgo }}
                            </span>
                        </div>

                        <!-- Used by -->
                        <div class="data-source-col">
                            <span
                                class="modules-count"
                                ng-if="data.integToGroupMap[projectIntegration.id]"
                                uib-tooltip="{{ combineGroupNames(data.integToGroupMap[projectIntegration.id]) }}"
                            >
                                {{ data.integToGroupMap[projectIntegration.id].length }}
                                Module{{ data.integToGroupMap[projectIntegration.id].length > 1 ? 's' : '' }}
                            </span>
                            <span ng-if="!data.integToGroupMap[projectIntegration.id]" class="modules-count-empty">
                                None
                            </span>
                        </div>

                        <!-- Status -->
                        <div
                            class="data-source-col text-center"
                            ng-init="isBroken = projectIntegration.valid === false || projectIntegration.disabled"
                        >
                            <div class="common-color-success2 flex-vmiddle" ng-if="!isBroken">
                                <i class="fa fa-circle common-size-xxxxs margin-right"></i>
                                <span>Active</span>
                            </div>

                            <div class="common-color-warning flex-vmiddle" ng-if="isBroken">
                                <i class="fa fa-circle common-size-xxxxs margin-right"></i>
                                <span>Inactive</span>
                            </div>
                        </div>

                        <!-- PERMISSIONS -->
                        <div class="flex-vmiddle data-source-col">
                            <span
                                class="margin-right flex-grow"
                                data-automation="integration-group-box-mode-template-view-data"
                                ng-class="
                                    projectIntegration.accessType ===
                                    data.projectIntegrationPersonAccessType.NOT_AUTHORIZED
                                        ? 'inaccessible-col-button'
                                        : 'col-button'
                                "
                            >
                                <span
                                    ng-if="
                                        projectIntegration.accessType ===
                                        data.projectIntegrationPersonAccessType.NOT_AUTHORIZED
                                    "
                                    class="svg-icon-sm inaccessible-icon"
                                >
                                    <tnk-icon src="/images/icons/lock12.svg"></tnk-icon>
                                </span>

                                {{ getPermissionsText(projectIntegration) }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    id="error-text"
                    ng-if="
                        (
                            data.projectIntegrationSummaries
                            | filter: filterProjectIntegrationsBySearchTerm
                            | filter: filterProjectIntegrationsByCheckboxes
                            | filter: filterProjectIntegrationsBySystemUtilized
                        ).length === 0
                    "
                >
                    No data sources to show. Consider changing the filters.
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Single integration view -->
<div
    ng-if="projectIntegartionId && projectIntegartionId !== 'new'"
    ng-include="'./singleIntegrationView.template.html'"
></div>

<!-- bottom part -->
<!--<div class="edit-integrations-info-box common-color-dark-grey">-->
<!--<hr/>-->
<!--<div class="common-size-l margin-top-xlg margin-bottom-xlg text-center common-title-font">What's next?</div>-->
<!--<div class="container flex margin-top flex-xs-wrap">-->

<!--&lt;!&ndash; Import &ndash;&gt;-->
<!--<div class="flex-basis-33-no-xs info-box-item-border">-->
<!--<p class="common-size-xs flex-vmiddle">-->
<!--<span class="svg-icon-sm margin-right"><tnk-icon src="/images/icons/sync.svg"></tnk-icon></span>-->
<!--<span>Import items as Tracks</span>-->
<!--</p>-->
<!--<p class="common-size-xxs">-->
<!--Choose specific items from your data source to import once, or define a condition for <strong>continuous-->
<!--import</strong>.-->
<!--</p>-->
<!--<p class="common-size-xxs common-color-grey">-->
<!--In your list, click: <span-->
<!--class="svg-icon-xs margin-left-xs margin-right-xs rounded-box padding-top-xxs padding-bottom-xxs padding-left-xs padding-right-xs"><tnk-icon-->
<!--src="images/icons/ellipsis-v.svg"></tnk-icon></span> &gt; <span-->
<!--class="margin-left-xs common-tag-full mod-border-only common-size-xxxxs">Import</span>-->
<!--</p>-->
<!--</div>-->

<!--&lt;!&ndash; Columns &ndash;&gt;-->
<!--<div class="flex-basis-33-no-xs info-box-item-border">-->
<!--<p class="common-size-xs flex-vmiddle">-->
<!--<span class="svg-icon-sm margin-right"><tnk-icon src="/images/icons/plug-o.svg"></tnk-icon></span>-->
<!--<span>Columns & Key Metrics</span>-->
<!--</p>-->
<!--<p class="common-size-xxs">-->
<!--Create a <strong>Column</strong> and configure it to match data for each of the items in the list,-->
<!--or create a <strong>Key Metric</strong> and aggregate data with formulas like Sum, Avg, etc.-->
<!--</p>-->
<!--<p class="common-size-xxs common-color-grey">-->
<!--In your list, click <span class="common-size-xxxs margin-left-xs margin-right-xs"><i-->
<!--class="fa fa-plus-circle common-color-primary"></i></span> to create a column.-->
<!--</p>-->
<!--</div>-->

<!--&lt;!&ndash; Triggers &ndash;&gt;-->
<!--<div class="flex-basis-33-no-xs">-->
<!--<p class="common-size-xs flex-vmiddle">-->
<!--<span class="svg-icon-sm margin-right"><tnk-icon src="/images/icons/trigger.svg"></tnk-icon></span>-->
<!--<span>Automation Triggers</span>-->
<!--</p>-->
<!--<p class="common-size-xxs">-->
<!--Based on your Tracks and Columns, you can use <strong>multiple data sources</strong> to create-->
<!--powerful automation with alerts and custom actions that can make changes <strong>back into your data-->
<!--source</strong> as well.-->
<!--</p>-->
<!--<p class="common-size-xxs common-color-grey">-->
<!--In your list, click <span class="common-size-xxs svg-icon-xsm common-color-grey margin-right-xs margin-left-xs"><tnk-icon src="/images/icons/customise.svg"></tnk-icon></span> to configure worker.-->
<!--</p>-->
<!--</div>-->

<!--</div>-->
<!--</div>-->
