<div class="org-upgrade org-content-page mod-no-padding mod-no-padding-xs">
    <div class="hidden-xs" ng-if="!$root.showHeader">
        <tnk-icon src="/images/icons/tonkean.svg"></tnk-icon>
    </div>

    <!-- Loading -->
    <div class="common-page-load" ng-show="loadingStripe">
        <i class="loading-full-circle"></i>
    </div>

    <div class="common-height-full flex" ng-hide="loadingStripe">
        <!-- Menu -->
        <div
            class="org-upgrade-menu org-upgrade-padded-content flex-no-shrink hidden-xs"
            ng-class="{ 'margin-top-lg': !$root.showHeader }"
            ng-if="!data.hideMenu"
        >
            <div class="org-upgrade-menu-item" ng-class="{ 'mod-active': data.currentStep === upgradeStepsMap.select }">
                1. SELECT PLAN
            </div>
            <div class="org-upgrade-menu-item" ng-class="{ 'mod-active': data.currentStep === upgradeStepsMap.info }">
                2. COMPANY INFORMATION
            </div>
            <div
                class="org-upgrade-menu-item"
                ng-class="{ 'mod-active': data.currentStep === upgradeStepsMap.payment }"
            >
                3. PAYMENT
                <i class="svg-icon svg-icon-xs svg-icon-align-text-top margin-left-xs">
                    <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                </i>
            </div>
        </div>

        <!-- Page content -->
        <div class="flex mod-center flex-grow">
            <div
                class="flex org-upgrade-steps-container flex-grow"
                ng-class="{ 'mod-center': data.currentStep !== upgradeStepsMap.assignUsers }"
            >
                <!-- Select plan step -->
                <div ng-show="data.currentStep === 'SELECT'" class="mod-adjust-width org-upgrade-padded-content">
                    <!-- Title -->
                    <h3 class="common-title-lg mod-light margin-none">Select a plan</h3>

                    <!-- Loading org (and plans) -->
                    <div ng-if="loadingOrg" class="margin-top-lg common-size">
                        <i class="loading-medium margin-none"></i>
                    </div>

                    <!-- Tier selection -->
                    <div ng-if="!loadingOrg">
                        <div class="margin-top">
                            <div class="common-size-xxs common-color-black margin-bottom-xs">
                                Compare plans and features on our
                                <a href="https://tonkean.com/pricing/" target="_blank">Pricing page</a>
                            </div>
                            <ui-select
                                class="org-upgrade-tier-select"
                                theme="bootstrap"
                                ng-model="sharedData.selectedTier"
                                on-select="setTier($item)"
                                search-enabled="false"
                            >
                                <ui-select-match>{{ $select.selected.displayName }}</ui-select-match>
                                <ui-select-choices repeat="tier in sharedData.tiers">
                                    <div>
                                        {{ tier.displayName }}
                                        <i class="opacity-50">
                                            (${{
                                                sharedData.tiersToPlans[tier.apiName]['MONTH'].amountPerSeatPerMonth
                                            }})
                                        </i>
                                    </div>
                                </ui-select-choices>
                            </ui-select>
                        </div>

                        <!-- Seat number selection -->
                        <h4 class="common-title-xxs margin-bottom-xs margin-top-xlg">Select number of seats</h4>
                        <div class="common-size-xxxxs common-color-grey" ng-if="!loadingTeamMembers && !loadingOrg">
                            * We suggest
                            <span ng-bind="data.upgradeSuggestion"></span>
                            &nbsp;{{ data.upgradeSuggestion === 1 ? 'seat' : 'seats' }}, based on the number of users
                            {{
                                lps.isPricingVersion(lps.pricingVersions.V1)
                                    ? 'that have logged into the app.'
                                    : 'that have
                            used the app or have been assigned as owners.'
                            }}
                        </div>
                        <div class="margin-top">
                            <!-- plan.quantity gets updated asyncly from a different controller, so need a way to init tempQuantity asyncly -->
                            <div
                                ng-if="data.upgradeSuggestion > 1"
                                ng-init="data.tempQuantity = data.upgradeSuggestion"
                            ></div>
                            <input
                                type="number"
                                ng-init="data.tempQuantity = data.upgradeSuggestion"
                                class="form-control org-seats-box mod-input block margin-right"
                                min="1"
                                ng-model="data.tempQuantity"
                                ng-change="data.upgradeQuantity = parseNewPlanQuantity(data.tempQuantity)"
                                ng-blur="data.tempQuantity = data.upgradeQuantity"
                            />
                        </div>
                        <!-- Plan types -->
                        <div class="margin-top-xlg">
                            <div class="flex-vmiddle flex-xs-wrap margin-bottom-md">
                                <h4 class="common-title-xxs flex-grow margin-top-none margin-xs-bottom">
                                    Choose a payment plan
                                </h4>
                                <div class="org-upgrade-coupon-container flex-vmiddle mod-justify-end flex-no-shrink">
                                    <i
                                        class="loading-small margin-right-xs margin-left-xs"
                                        ng-show="data.loadingPromoCode"
                                    ></i>
                                    <div
                                        ng-if="data.promoCodeStatus"
                                        class="common-size-xxxxs margin-right-xs margin-left-xs"
                                        ng-class="
                                            data.promoCodeStatus === 'error'
                                                ? 'common-color-danger'
                                                : 'common-color-success'
                                        "
                                    >
                                        {{ data.promoCodeStatus === 'error' ? 'Invalid code' : 'Promo applied!' }}
                                    </div>
                                    <div class="org-upgrade-coupon-content flex-vmiddle">
                                        <a
                                            ng-click="data.showPromoCode = true"
                                            ng-hide="data.showPromoCode"
                                            class="common-size-xxxxs"
                                        >
                                            Enter Promo Code
                                        </a>
                                        <div ng-if="data.showPromoCode" class="flex-vmiddle">
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Enter code..."
                                                    ng-disabled="data.loadingPromoCode"
                                                    ng-model="data.promoCodeInput"
                                                    ng-keydown="onCouponKeyDown($event)"
                                                    autofocus
                                                />
                                                <span class="input-group-btn">
                                                    <a
                                                        class="btn btn-primary"
                                                        data-ng-disabled="data.loadingPromoCode || !data.promoCodeInput || !data.promoCodeInput.length"
                                                        ng-click="applyPromoCode()"
                                                    >
                                                        Apply
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Annual plan -->
                            <div
                                class="org-upgrade-plan flex-vmiddle mod-justify-space"
                                ng-class="{ 'mod-selected': plan.interval === sharedData.intervals.year }"
                                ng-click="setPlan('YEAR')"
                            >
                                <div class="flex-vmiddle">
                                    <input
                                        type="radio"
                                        name="planTypeAnnual"
                                        id="planTypeAnnual"
                                        value="YEAR"
                                        ng-checked="plan.interval === sharedData.intervals.year"
                                        class="hidden-xs flex-no-shrink"
                                    />
                                    <div class="margin-right-lg">
                                        <div class="flex-vmiddle margin-bottom-xxs">
                                            <span class="common-title-xs margin-right">Pay annually</span>
                                            <span class="common-tag-full common-title-font">Save over 15%!</span>
                                        </div>
                                        <div class="common-size-xxs common-color-light-grey2">
                                            ${{
                                                (
                                                    data.upgradeQuantity *
                                                    plans['YEAR'].amountPerSeatPerMonth *
                                                    12
                                                ).toFixed(2) | number
                                            }}
                                            yearly for
                                            {{
                                                data.upgradeQuantity === 1 ? '1 user' : data.upgradeQuantity + ' users'
                                            }}. You will be charged for the whole year.
                                            <div ng-if="plans['YEAR'].percentOff" class="common-color-success">
                                                Coupon: {{ plans['YEAR'].percentOff }}% discount
                                                {{
                                                    plans['YEAR'].discountDuration === 'repeating'
                                                        ? 'for ' + plans['YEAR'].discountDurationInMonths + ' months'
                                                        : ''
                                                }}
                                            </div>
                                            <div ng-if="plans['YEAR'].amountOff" class="common-color-success">
                                                Coupon: ${{ plans['YEAR'].amountOff }} discount
                                                {{
                                                    plans['YEAR'].discountDuration === 'repeating'
                                                        ? 'for ' + plans['YEAR'].discountDurationInMonths + ' months'
                                                        : plans['YEAR'].discountDuration === 'forever'
                                                        ? 'every year'
                                                        : ''
                                                }}
                                                (Total: ${{
                                                    Math.max(
                                                        (
                                                            data.upgradeQuantity *
                                                                plans['YEAR'].amountPerSeatPerMonth *
                                                                12 -
                                                            plans['YEAR'].amountOff
                                                        ).toFixed(2),
                                                        0
                                                    ) | number
                                                }})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mod-align-end flex-no-shrink-no-xs flex-xs-wrap">
                                    <div class="org-upgrade-plan-price">
                                        <span class="margin-right-xxs">$</span>
                                        <span class="common-bold">{{ plans['YEAR'].amountPerSeatPerMonth }}</span>
                                    </div>
                                    <div class="common-color-dark-grey flex-no-shrink">Per seat/month</div>
                                </div>
                            </div>
                            <!-- Monthly plan -->
                            <div
                                class="org-upgrade-plan flex-vmiddle mod-justify-space"
                                ng-class="{ 'mod-selected': plan.interval === sharedData.intervals.month }"
                                ng-click="setPlan('MONTH')"
                            >
                                <div class="flex-vmiddle">
                                    <input
                                        type="radio"
                                        name="planTypeAnnual"
                                        id="planTypeMonthly"
                                        value="MONTH"
                                        ng-checked="plan.interval === sharedData.intervals.month"
                                        class="hidden-xs flex-no-shrink"
                                    />
                                    <div class="margin-right-lg">
                                        <div class="common-title-xs margin-bottom-xxs">Pay monthly</div>
                                        <div class="common-size-xxs common-color-light-grey2">
                                            ${{
                                                (data.upgradeQuantity * plans['MONTH'].amountPerSeatPerMonth).toFixed(2)
                                                    | number
                                            }}
                                            per month for
                                            {{
                                                data.upgradeQuantity === 1 ? '1 user' : data.upgradeQuantity + ' users'
                                            }}&nbsp; (${{
                                                (
                                                    data.upgradeQuantity *
                                                    plans['MONTH'].amountPerSeatPerMonth *
                                                    12
                                                ).toFixed(2) | number
                                            }}
                                            yearly).
                                            <div ng-if="plans['MONTH'].percentOff" class="common-color-success">
                                                Coupon: {{ plans['MONTH'].percentOff }}% discount
                                                {{
                                                    plans['MONTH'].discountDuration === 'repeating'
                                                        ? 'for ' + plans['MONTH'].discountDurationInMonths + ' months'
                                                        : ''
                                                }}
                                            </div>
                                            <div ng-if="plans['MONTH'].amountOff" class="common-color-success">
                                                Coupon: ${{ plans['MONTH'].amountOff }} discount
                                                {{
                                                    plans['MONTH'].discountDuration === 'repeating'
                                                        ? 'for ' + plans['MONTH'].discountDurationInMonths + ' months'
                                                        : plans['MONTH'].discountDuration === 'forever'
                                                        ? 'every month'
                                                        : ''
                                                }}
                                                (Total: ${{
                                                    Math.max(
                                                        (
                                                            data.upgradeQuantity *
                                                                plans['MONTH'].amountPerSeatPerMonth -
                                                            plans['MONTH'].amountOff
                                                        ).toFixed(2),
                                                        0
                                                    ) | number
                                                }})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex mod-align-end flex-no-shrink-no-xs flex-xs-wrap">
                                    <div class="org-upgrade-plan-price">
                                        <span class="margin-right-xxs">$</span>
                                        <span class="common-bold">{{ plans['MONTH'].amountPerSeatPerMonth }}</span>
                                    </div>
                                    <div class="common-color-dark-grey flex-no-shrink">Per seat/month</div>
                                </div>
                            </div>
                            <!-- Continue button -->
                            <button
                                type="button"
                                class="btn btn-primary-black org-upgrade-big-button margin-top-lg"
                                ng-click="nextStep()"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Company information step -->
                <div
                    ng-show="data.currentStep === 'INFO'"
                    class="margin-bottom-xlg padding-bottom-xlg org-upgrade-padded-content"
                >
                    <h3 class="common-title-lg mod-light margin-bottom-xlg margin-top-none">
                        Add company information:
                    </h3>
                    <form name="forms.frmCompanyInfo" ng-submit="nextStep('frmCompanyInfo')" novalidate>
                        <!-- Company name input -->
                        <div class="form-group">
                            <label for="companyName" class="control-label">Company name *</label>
                            <input
                                type="text"
                                class="form-control"
                                id="companyName"
                                placeholder="Company Name"
                                ng-model="licenseData.organizationName"
                                required
                            />
                            <div class="common-color-grey common-size-xxxxs margin-top-xs">
                                This information will be included on all billing invoices as the billing name.
                            </div>
                        </div>
                        <!-- Country -->
                        <div class="form-group">
                            <label for="frmCountryS" class="control-label">Country *</label>
                            <select
                                name="ship-country"
                                id="frmCountryS"
                                placeholder="USA"
                                class="form-control"
                                ng-model="licenseData.country"
                                autocomplete="billing country"
                                ng-options="countryCode as countryName for (countryCode, countryName) in data.countries"
                                required
                            ></select>
                        </div>
                        <!-- State (if country is US) -->
                        <div class="form-group" ng-if="licenseData.country === 'US'">
                            <label for="frmStateS" class="control-label">State *</label>
                            <select
                                name="ship-state"
                                id="frmStateS"
                                placeholder="NY"
                                class="form-control"
                                ng-model="licenseData.state"
                                autocomplete="billing state"
                                ng-options="stateCode as stateName for (stateCode, stateName) in data.usaStates"
                                required
                            ></select>
                        </div>
                        <!-- City -->
                        <div class="form-group">
                            <label for="frmCityS" class="control-label">City *</label>
                            <input
                                name="ship-city"
                                id="frmCityS"
                                placeholder="San Francisco"
                                class="form-control"
                                ng-model="licenseData.city"
                                autocomplete="billing address-level2"
                                required
                            />
                        </div>
                        <!-- Address -->
                        <div class="form-group">
                            <label for="frmAddressS" class="control-label">Address *</label>
                            <input
                                name="ship-address"
                                id="frmAddressS"
                                class="form-control"
                                ng-model="licenseData.address"
                                placeholder="123 Any Street"
                                autocomplete="billing street-address"
                                required
                            />
                        </div>
                        <!-- Zip (postal) code -->
                        <div class="form-group">
                            <label for="frmZipS" class="control-label">Postal code *</label>
                            <input
                                name="ship-zip"
                                id="frmZipS"
                                placeholder="10011"
                                class="form-control"
                                ng-model="licenseData.zipCode"
                                autocomplete="billing postal-code"
                                required
                            />
                        </div>
                        <!-- Phone number (optional) -->
                        <div class="form-group">
                            <label for="frmPhoneNumA" class="control-label">Phone</label>
                            <input
                                class="form-control"
                                type="tel"
                                name="phone"
                                id="frmPhoneNumA"
                                ng-model="licenseData.phoneNumber"
                                placeholder="+1-650-450-1212"
                                autocomplete="tel"
                            />
                        </div>
                        <!-- Email (optional) -->
                        <div class="form-group">
                            <label for="ccEmail" class="control-label">Email bills to</label>
                            <input
                                type="email"
                                id="ccEmail"
                                class="form-control"
                                ng-model="licenseData.ccEmail"
                                placeholder="Optional"
                            />
                        </div>

                        <!-- Continue (and back button on small screens) -->
                        <div class="margin-top">
                            <button
                                type="button"
                                class="btn btn-default visible-xs-inline-block visible-sm-inline-block"
                                ng-click="previousStep()"
                            >
                                Back
                            </button>
                            <button type="submit" class="btn btn-primary-black org-upgrade-big-button">Continue</button>
                        </div>

                        <div class="common-color-light-grey2 common-size-xxxxs margin-top-lg">* a required field</div>
                    </form>
                </div>

                <!-- Payment method step -->
                <div ng-show="data.currentStep === 'PAYMENT'" class="org-upgrade-padded-content">
                    <h3 class="common-title-lg mod-light margin-bottom-xlg margin-top-none">
                        <i class="svg-icon-md svg-icon-align-text-base common-color-grey">
                            <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                        </i>
                        Add payment method:
                    </h3>
                    <form name="forms.frmPaymentInfo" ng-submit="submit()" novalidate>
                        <!-- Cardholder Name -->
                        <div class="form-group">
                            <label for="frmCCName" class="control-label">Name on card</label>
                            <input
                                name="cardname"
                                id="frmCCName"
                                type="text"
                                class="form-control"
                                autocomplete="cc-name"
                                placeholder="Name On Card"
                                ng-model="cardExtraData.name"
                                required
                            />
                        </div>
                        <!-- Credit card (Stripe element component) -->
                        <div class="form-group margin-bottom-xlg">
                            <label class="control-label">Card details</label>
                            <tnk-credit-card
                                sync-object="creditCardSyncObject"
                                card-extra-data="cardExtraData"
                                loading-stripe-complete="loadingStripeComplete()"
                            ></tnk-credit-card>
                        </div>
                        <!-- Complete button -->
                        <div class="margin-top">
                            <button
                                type="button"
                                class="btn btn-default visible-xs-inline-block visible-sm-inline-block"
                                ng-click="previousStep()"
                            >
                                Back
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary-black org-upgrade-big-button"
                                ng-disabled="posting"
                            >
                                <i class="loading mod-white margin-right-xs" ng-show="posting"></i>
                                Complete purchase
                            </button>
                        </div>
                        <!-- Terms -->
                        <div class="margin-top org-upgrade-terms">
                            <div class="common-bold">
                                The card will be charged
                                {{ pm.isInTrial ? 'at the end of your trial period' : 'immediately' }} for the
                                {{ plan.name }} plan, in the amount of ${{
                                    (data.upgradeQuantity * plan.amountPerSeat).toFixed(2)
                                }}
                                for {{ data.upgradeQuantity > 1 ? data.upgradeQuantity + ' seats' : '1 seat' }}.
                            </div>
                            <div class="common-color-light-grey2">
                                By clicking the button, you agree to our
                                <a href="https://tonkean.com/terms/" target="_blank">terms of services</a>
                                and
                                <a href="https://tonkean.com/privacy/" target="_blank">privacy policy</a>
                                .
                            </div>
                            <a
                                href="https://stripe.com"
                                target="_blank"
                                class="powered-by-stripe block margin-top padding-top-xs"
                            >
                                <img src="/images/powered-by-stripe.png" alt="powered by Stripe" />
                            </a>
                            <div class="margin-top-xlg padding-top-lg common-size-xxs">
                                <div class="common-bold">Please note</div>
                                <div class="common-color-dark-grey text-justify">
                                    All credit card subscriptions are recurring, which means that in each subscription
                                    period your card will be automatically charged unless you cancel your subscription.
                                    You may modify your plan or cancel your subscription at any time, in which case you
                                    will not be automatically charged following the end of your current subscription
                                    period.
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Select users final step -->
                <div
                    ng-show="data.currentStep === 'ASSIGN_USERS'"
                    class="flex flex-xs-wrap flex-grow overflow-y-auto-xs common-stretch-self-full-height mod-left"
                >
                    <!-- Success blue left sidebar -->
                    <div
                        class="org-upgrade-success-sidebar"
                        ng-class="{ 'mod-mobile-shrink': mobileHideSuccessSidebar }"
                    >
                        <h4 class="common-title-md mod-white margin-top-none margin-bottom-xs">Congratulations!</h4>
                        <div class="common-title-sm mod-white mod-light padding-bottom-xs">
                            You've purchased
                            {{ data.upgradeQuantity > 1 ? data.upgradeQuantity + ' seats' : '1 seat' }} in the
                            {{ plan.name }} plan
                        </div>
                        <hr class="common-separator opacity-50 margin-top margin-bottom" />
                        <div class="common-size-xxs padding-top-xs padding-bottom-xs">
                            You're on the {{ plan.interval === 'year' ? 'annual' : 'monthly' }} plan, starting
                            {{ data.postPaymentDetails.periodStart | date: 'mediumDate' }} to
                            {{ data.postPaymentDetails.periodEnd | date: 'mediumDate' }}
                        </div>
                        <hr class="common-separator opacity-50 margin-top margin-bottom" />
                        <div class="common-size-xxs padding-top-xs padding-bottom-xs">
                            You {{ pm.isInTrial ? 'will pay' : 'paid' }} ${{
                                data.upgradeQuantity * plan.amountPerSeat
                            }}
                            with with credit card
                            <span class="common-one-liner">
                                <i class="org-censor-bullets mod-white margin-left-xs"></i>
                                <i class="org-censor-bullets mod-white"></i>
                                <i class="org-censor-bullets mod-white"></i>
                                {{ data.postPaymentDetails.last4 }}
                            </span>
                        </div>

                        <!-- Cool zigzag details summary -->
                        <div class="org-upgrade-success-summary-container">
                            <div class="org-upgrade-success-summary">
                                <div class="hidden-xs">
                                    <div class="org-upgrade-success-summary-logo hidden-xs margin-top">
                                        <tnk-icon src="/images/icons/tonkean.svg"></tnk-icon>
                                    </div>
                                    <hr class="common-separator mod-primary margin-top-lg margin-bottom opacity-50" />
                                    <div class="flex-vmiddle common-color-black common-title-font">
                                        <div class="text-center">
                                            <div class="org-upgrade-big-number">{{ data.upgradeQuantity }}</div>
                                            <div class="common-size-xxs">
                                                {{ data.upgradeQuantity === 1 ? 'SEAT' : 'SEATS' }}
                                            </div>
                                        </div>
                                        <div
                                            class="common-separator-v mod-primary mod-spacey opacity-50 common-size-xxl"
                                        ></div>
                                        <div class="flex-no-shrink">
                                            <div>{{ plan.name }}</div>
                                            <hr
                                                class="common-separator mod-primary margin-top margin-bottom opacity-50"
                                            />
                                            <div>
                                                {{ data.postPaymentDetails.periodStart | date: 'MMM yyyy' }} -
                                                {{ data.postPaymentDetails.periodEnd | date: 'MMM yyyy' }}
                                            </div>
                                        </div>
                                        <div
                                            class="common-separator-v mod-primary mod-spacey opacity-50 common-size-xxl"
                                        ></div>
                                        <div>
                                            <div>TOTAL AMOUNT</div>
                                            <div>${{ data.upgradeQuantity * plan.amountPerSeat | number }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="visible-xs-block text-center">
                                    <button
                                        type="button"
                                        class="btn btn-lg btn-primary-black"
                                        ng-click="mobileHideSuccessSidebar = true"
                                    >
                                        Assign seats to users
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Full users selection -->
                    <div class="flex-grow padding-normal-lg common-height-full">
                        <!-- Title -->
                        <div class="flex-vmiddle">
                            <h4 class="common-title-md mod-light margin-top-none margin-bottom flex-grow">
                                <span ng-show="lps.isPricingVersion(lps.pricingVersions.V1)">Full</span>
                                Tonkean users:
                            </h4>
                            <div
                                class="
                                    margin-left
                                    flex-no-shrink
                                    common-size-xxs common-tag-full
                                    org-members-seats-counter
                                "
                            >
                                <span
                                    ng-style="{
                                        width:
                                            data.fullUsersToAddTemp.length / data.upgradeQuantity > 1
                                                ? '100%'
                                                : (data.fullUsersToAddTemp.length / data.upgradeQuantity) * 100 + '%'
                                    }"
                                ></span>
                                <span>{{ data.fullUsersToAddTemp.length }}/{{ data.upgradeQuantity }} seats</span>
                            </div>
                        </div>
                        <!-- Loading -->
                        <div class="text-center" ng-if="loadingFullUsers">
                            <span class="loading-medium"></span>
                        </div>
                        <!-- People (full users) selector -->
                        <div class="common-bold common-color-black common-size-xxs" ng-if="!loadingFullUsers">
                            <div>
                                You have {{ data.upgradeQuantity }}&nbsp;{{
                                    data.upgradeQuantity === 1 ? 'seat' : 'seats'
                                }}
                                to fill.
                            </div>
                            <div>You can select the people you would like to make full users now.</div>
                            <div>
                                <tnk-deprecated-people-selector
                                    class="flex-grow margin-right-xs org-upgrade-assign-users-selector"
                                    exclude-me="false"
                                    no-validation="false"
                                    selected-people="data.fullUsersToAddTemp"
                                    options="{onlyNotLicensed: true}"
                                    on-tag-added="onFullUserTagsChanged()"
                                    on-tag-removed="onFullUserTagsChanged()"
                                    on-tag-removing="onFullUserTagsRemoved($tag)"
                                    placeholder="Type a name or email"
                                    show-intro="true"
                                    max-tags="{{ plan.quantity }}"
                                ></tnk-deprecated-people-selector>
                            </div>
                        </div>
                        <!-- Assign seats / full users button -->
                        <div class="text-right margin-top-none">
                            <button type="button" class="btn btn-default margin-right-xs" ng-click="completeUpgrade()">
                                Skip
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary-black common-title-font"
                                ng-click="addFullUsers()"
                                ng-disabled="!data.fullUsersToAddTemp || !data.fullUsersToAddTemp.length"
                            >
                                <i class="loading mod-white margin-right-xs" ng-show="addingUsers"></i>
                                Assign seats
                            </button>
                        </div>
                        <!-- Error message -->
                        <div class="common-size-xxs common-color-danger margin-top" ng-if="usersError">
                            {{ usersError }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Right blue side bar placeholder (since the sidebar is absolute positioned) -->
        <div
            class="org-side-bar-blue mod-persistent mod-placeholder"
            ng-if="
                (data.currentStep === upgradeStepsMap.info || data.currentStep === upgradeStepsMap.payment) &&
                !$root.isMobile
            "
        ></div>

        <!-- Right blue side bar (when needed) -->
        <div
            class="org-side-bar-blue mod-persistent flex-no-shrink"
            ng-if="
                (data.currentStep === upgradeStepsMap.info || data.currentStep === upgradeStepsMap.payment) &&
                !$root.isMobile
            "
        >
            <h4 class="common-title-xxs margin-bottom margin-top-none">Plan Summary</h4>
            <!-- Plan summary -->
            <ul class="pretty-list mod-dark">
                <li>
                    <span class="common-size-xxs">
                        Your plan is
                        <strong>{{ plan.name }}</strong>
                        , for
                        <strong>{{ data.upgradeQuantity === 1 ? '1 seat' : data.upgradeQuantity + ' seats' }}</strong>
                        &nbsp;
                        <a href="#" ng-click="goToStep(upgradeStepsMap.select)" class="common-underline common-bold">
                            Edit
                        </a>
                    </span>
                </li>
                <li>
                    <span class="common-size-xxs">
                        You will be paying
                        <strong>{{ plan.interval === 'year' ? 'annually' : 'monthly' }}</strong>
                        .
                        <br />
                        <a
                            href="#"
                            ng-click="setPlan(plan.interval === 'year' ? 'MONTH' : 'YEAR')"
                            class="common-underline common-bold"
                        >
                            Switch to {{ plan.interval === 'year' ? 'monthly' : 'annually' }}
                        </a>
                        <span class="common-color-light-grey2" ng-if="plan.interval !== 'year'">(Save over 15%)</span>
                    </span>
                </li>
                <li>
                    <span class="common-size-xxs">
                        <span>Your total payment is</span>
                        <span class="common-bold">
                            ${{ (data.upgradeQuantity * plan.amountPerSeat).toFixed(2) | number }}
                            {{ plan.interval }}ly,
                        </span>
                        &nbsp;
                        <span>based on a ${{ plan.amountPerSeatPerMonth.toFixed(2) }} per seat/month price.</span>
                        &nbsp;
                        <span class="common-color-light-grey2" ng-if="plan.interval === 'year'">
                            (you're saving over 15% annually)
                        </span>
                    </span>
                </li>
            </ul>
            <!-- Company information -->
            <div
                ng-if="licenseData.organizationName && data.currentStep === upgradeStepsMap.payment"
                class="margin-top-xlg"
            >
                <h4 class="common-title-xxs margin-bottom margin-top-none">Company information</h4>
                <div class="common-bold">{{ licenseData.organizationName }}</div>
                <div>{{ licenseData.ccEmail }}</div>
                <div>{{ licenseData.phoneNumber }}</div>
                <div>{{ licenseData.address }}</div>
                <div>{{ licenseData.city }}{{ licenseData.state ? ', ' + data.usaStates[licenseData.state] : '' }}</div>
                <div>{{ licenseData.zipCode }}</div>
                <div>{{ licenseData.country ? data.countries[licenseData.country] : '' }}</div>
                <div class="margin-top-xs">
                    <a href="#" class="common-underline" ng-click="goToStep(upgradeStepsMap.info)">Edit</a>
                </div>
            </div>
        </div>
    </div>
</div>
