<span ng-if="data.error">Failed getting people: {{ data.error | error }}</span>
<tnk-user
    ng-if="!data.error && !data.customEmail"
    class="mod-one-line common-size-xxs"
    user="data"
    default-on-empty="true"
    ng-class="{ 'group-padding-right': data.styledGroupTags }"
></tnk-user>
<div ng-if="data.customEmail" class="share-modal-custom-item">
    <i class="fa share-modal-custom-item-icon common-color-grey" data-automation="people-autocomplete-item-template-mail-item"></i>
    <span>{{ data.email }}</span>
    <span class="share-modal-custom-item-via">via email</span>
</div>
