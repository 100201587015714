import React from 'react';
import styled from 'styled-components';

import type { VersionsListsFilter } from './VersionsListsFilter';
import { ReactComponent as VersionControlSearchIcon } from '../../../../images/icons/version-control-search.svg';

import { angularToReact } from '@tonkean/angular-components';
import { Toggle, useDebouncedState } from '@tonkean/infrastructure';
import type { Person } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { classNames } from '@tonkean/utils';

type FilterType = 'history' | 'versions';
type TooltipDirection = 'right' | 'left';

const WorkflowVersionsListFilter = angularToReact<
    Omit<VersionsListsFilter & VersionHistoryFilter, 'searchTerm'> & {
        onSaveFilter: (filters: VersionsListsFilter & VersionHistoryFilter) => void;
        filterType: FilterType;
        appendFilterDialogToBody: boolean;
        tooltipDirection: TooltipDirection;
        shouldLoadCommitActivities?: boolean;
        workflowVersionId?: string;
    }
>('tnk-workflow-versions-list-filter');

const GroupByText = styled.div`
    position: relative;
    line-height: 14px;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_600};
    display: flex;
    align-items: center;
    margin-left: 0.5em;
`;

const WorkflowVersionsFilter = styled.div`
    display: flex;
    flex-grow: 1;
    height: 14px;
    margin-left: 6px;
`;

interface VersionHistoryFilter {
    activityType?: string;
    actor?: Person;
    fromDate?: Date;
    toDate?: Date;
    subSequentialIdentifier?: string;
}

type Filter = VersionsListsFilter | VersionHistoryFilter;

interface BaseProps<FILTER extends Filter> {
    hasLoadMore: boolean;
    loading: boolean;
    isEmpty: boolean;
    filters: FILTER;
    tooltipDirection?: TooltipDirection;
    appendFilterDialogToBody: boolean;
    loadMoreButtonSecondary?: boolean;
    loadingIndicator?: React.ReactNode;
    children: React.ReactNode;
    /** Must be memoized because it's used in the debouncer. */
    onFiltersChange: (filters: FILTER) => void;
    onLoadMoreClick: () => void;
    onGroupToggleClick?: () => void;
    isToggleActive?: boolean;
    emptyListMessage: string;
    shouldLoadCommitActivities?: boolean;
    workflowVersionId?: string;
    hideGroupByToggle?: boolean;
}

interface HistoryProps extends BaseProps<VersionHistoryFilter> {
    filterType: 'history';
    title: string;
}
interface VersionsProps extends BaseProps<VersionsListsFilter> {
    filterType: 'versions';
    title?: undefined;
}

type Props = HistoryProps | VersionsProps;

const WorkflowVersionsPaginatedList: React.FC<Props> = ({
    title,
    hasLoadMore,
    loading,
    isEmpty,
    loadMoreButtonSecondary = false,
    filters: filtersProp,
    filterType = 'versions',
    tooltipDirection = 'left',
    appendFilterDialogToBody = false,
    children,
    loadingIndicator,
    onLoadMoreClick,
    onFiltersChange,
    onGroupToggleClick,
    isToggleActive = false,
    emptyListMessage,
    shouldLoadCommitActivities = false,
    workflowVersionId,
    hideGroupByToggle = false,
}) => {
    const [filters, setFilters] = useDebouncedState(filtersProp, onFiltersChange, 300);

    return (
        <div className="workflow-versions-paginated-list">
            <div className="workflow-version-search">
                {title ? (
                    <strong>{title}</strong>
                ) : (
                    <>
                        <span>
                            <span className="tnk-icon">
                                <VersionControlSearchIcon />
                            </span>
                        </span>

                        <input
                            className="form-control common-no-border common-no-outline common-size-xxxxs"
                            onChange={({ target }) => setFilters({ ...filters, searchTerm: target.value })}
                            placeholder="Search"
                        />
                    </>
                )}

                <WorkflowVersionsFilter>
                    <WorkflowVersionsListFilter
                        {...filters}
                        filterType={filterType}
                        appendFilterDialogToBody={appendFilterDialogToBody}
                        tooltipDirection={tooltipDirection}
                        shouldLoadCommitActivities={shouldLoadCommitActivities}
                        workflowVersionId={workflowVersionId}
                        onSaveFilter={(newFilters) => {
                            setFilters({ ...filters, ...newFilters });
                        }}
                    />
                </WorkflowVersionsFilter>

                {filterType === 'history' && onGroupToggleClick && !hideGroupByToggle && (
                    <>
                        <Toggle size={InputSize.SMALL} checked={isToggleActive} onChange={onGroupToggleClick} />
                        <GroupByText>Group by Block</GroupByText>
                    </>
                )}
            </div>

            <div className="workflow-version-items">
                {!loading && isEmpty && (
                    <div className="common-size-xxs margin-left margin-top margin-bottom"> {emptyListMessage} </div>
                )}

                {children}

                {hasLoadMore && !loading && (
                    <button
                        className={classNames(
                            loadMoreButtonSecondary ? 'btn-secondary' : 'btn-primary',
                            'block btn btn-sm margin-auto margin-top margin-bottom',
                        )}
                        type="button"
                        onClick={onLoadMoreClick}
                    >
                        Load more
                    </button>
                )}

                {loading &&
                    (loadingIndicator || (
                        <div className="flex-vmiddle margin-left margin-top margin-bottom">
                            <div className="common-size-xxs margin-right-xs">Loading...</div>
                            <span className="loading-small" />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default WorkflowVersionsPaginatedList;
