function TrialExpiredModalCtrl(
    $scope,
    authenticationService,
    tonkeanService,
    projectManager,
    modalUtils,
    licenseManager,
) {
    $scope.modalUtils = modalUtils;

    $scope.data = {};

    $scope.setLimitedState = function () {
        $scope.data.loading = true;

        licenseManager.setLimitedState().finally(() => {
            window.location.reload();
        });
    };
}

export default angular.module('tonkean.app').controller('TrialExpiredModalCtrl', TrialExpiredModalCtrl);
