import template from './tnkDelayLogicConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkDelayLogicConfiguration', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        configuredLogic: '<',
        onActionsChanged: '&',
        validationObject: '<',
    },
    template,
    controller: 'DelayLogicConfigurationCtrl',
});
