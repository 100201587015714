import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import type { TaggableFieldDefinition } from '../../taggableEntities/entities';
import type { CollaborationAction } from '../entities';
import { CollaborationPlusIcon, ConciergeIcon } from '../icons';

import { Breakpoint } from '@tonkean/infrastructure';
import { PluggableActionType } from '@tonkean/tonkean-entities';
import type { ProjectThemeConfiguration, SelectedPluggableAction } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { colorSvg } from '@tonkean/utils';

const CollaborationActionsNav = styled.div`
    display: flex;
    gap: 16px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;

const StyledConciergeIcon = styled(ConciergeIcon)<{ color: string }>`
    ${({ color }) => colorSvg(color)};
`;

const StyledCollaborationIcon = styled(CollaborationPlusIcon)<{ color: string }>`
    ${({ color }) => colorSvg(color)};
`;

interface Props {
    primaryColor?: string;
    setSelectedPluggableAction?: (selectedPluggableAction: SelectedPluggableAction | undefined) => void;
    setPluggableActionHover?: (pluggableActionHover: boolean) => void;
    defaultFieldEntity?: TaggableFieldDefinition;
    pluggableActionsToShowSettings?: PluggableActionType[];
}

const CollaborationActionsPluginMenu: React.FC<Props> = ({
    primaryColor,
    setSelectedPluggableAction,
    setPluggableActionHover,
    defaultFieldEntity,
    pluggableActionsToShowSettings,
}) => {
    const projectManager = useAngularService('projectManager');
    const projectThemeConfiguration: ProjectThemeConfiguration = projectManager.project.themeConfiguration;
    const themeColorToDisplay = primaryColor ? primaryColor : projectThemeConfiguration.primaryColor;

    const collaborationActions: CollaborationAction[] = useMemo(() => {
        return [
            {
                actionType: PluggableActionType.CONCIERGE,
                icon: <StyledConciergeIcon color={themeColorToDisplay} />,
                shouldShow: pluggableActionsToShowSettings?.includes(PluggableActionType.CONCIERGE) || false,
            },
            {
                actionType: PluggableActionType.COLLABORATION,
                icon: <StyledCollaborationIcon color={themeColorToDisplay} />,
                iconFill: themeColorToDisplay,
                shouldShow: pluggableActionsToShowSettings?.includes(PluggableActionType.COLLABORATION) || false,
            },
        ];
    }, [pluggableActionsToShowSettings, themeColorToDisplay]);

    const handleActionClicked = useCallback(
        (collaborationAction: CollaborationAction) => {
            const initialActionString =
                collaborationAction.actionType === PluggableActionType.COLLABORATION
                    ? `Please help me with *@${defaultFieldEntity?.fieldName}*[](${defaultFieldEntity?.fieldDefinitionId})`
                    : `help me fill "${defaultFieldEntity?.fieldName}"`;

            setSelectedPluggableAction?.({ actionType: collaborationAction.actionType, initialActionString });
        },
        [defaultFieldEntity?.fieldName, defaultFieldEntity?.fieldDefinitionId, setSelectedPluggableAction],
    );

    return (
        <CollaborationActionsNav>
            {collaborationActions
                .filter((collaborationAction) => {
                    return collaborationAction.shouldShow;
                })
                .map((collaborationAction) => (
                    <Clickable
                        key={collaborationAction.actionType}
                        onClick={() => handleActionClicked(collaborationAction)}
                        onMouseEnter={() => setPluggableActionHover?.(true)}
                        onMouseLeave={() => setPluggableActionHover?.(false)}
                        data-automation={`collaboration-action-${collaborationAction.actionType}`}
                    >
                        {collaborationAction.icon}
                    </Clickable>
                ))}
        </CollaborationActionsNav>
    );
};

export default CollaborationActionsPluginMenu;
