import React, { useCallback, useMemo } from 'react';

import FormulaNodePopoverTrigger from './FormulaNodes/FormulaNodePopoverTrigger';
import FormulaContext from '../entities/FormulaContext';
import { FormulaWrapper } from '../FormulaBuilder';

import { operatorKeyToOperatorMap } from '@tonkean/forumla-operators';
import { getOperandTree } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { createEmptyFormulaNode } from '@tonkean/tonkean-entities';
import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import { rootFormulaField } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const innerAggregationNode = getOperandTree(
    operatorKeyToOperatorMap[OperatorKey.INNER_TRACK_AGGREGATION],
    rootFormulaField,
);

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;

    onFormulaChanged(newTree: formulaTreeNode): void;
}

const FormulaBuilderInnerAggregationMock: React.FC<Props> = ({ workflowVersionId, onFormulaChanged }) => {
    const onNodeChanged = useCallback(
        (newNode: formulaTreeNode) => {
            if (newNode.type !== FormulaTreeNodeType.TREE || newNode.operator !== innerAggregationNode.operator) {
                onFormulaChanged(newNode);
            }
        },
        [onFormulaChanged],
    );

    const onNodeDeleted = useCallback(() => {
        const newNode = createEmptyFormulaNode(rootFormulaField);
        onNodeChanged(newNode);
    }, [onNodeChanged]);

    const mockContextValue = useMemo(
        () => ({
            highlightedNodes: undefined,
            setHighlightedNodes: () => {},
            specificEditor: undefined,
            setSpecificEditor: () => {},
            formulaDataMap: undefined,
            onInnerTrackAggregationSelected: () => {},
            workflowVersionId,
            formulaChangedOutsideSpecificEditorCallback: () => {},
            fieldDefinition: undefined,
        }),
        [workflowVersionId],
    );

    return (
        <FormulaContext.Provider value={mockContextValue}>
            <header className="flex flex-vmiddle margin-bottom-xs margin-top">
                <div className="flex-grow common-bold">Formula Editor:</div>
            </header>

            <FormulaWrapper>
                <FormulaNodePopoverTrigger
                    content="Inner items aggregation field"
                    depth={0}
                    canDelete={false}
                    disabled={false}
                    node={innerAggregationNode}
                    onNodeChanged={onNodeChanged}
                    onNodeDeleted={onNodeDeleted}
                    disableWrap
                />
            </FormulaWrapper>
        </FormulaContext.Provider>
    );
};

export default FormulaBuilderInnerAggregationMock;
