import { lateConstructController } from '@tonkean/angular-components';
import { getDisplayFormats } from '@tonkean/constants';

/* @ngInject */
function FormFieldsSelectCtrl($scope, customFieldsManager) {
    const ctrl = this;
    $scope.fieldDisplayFormats = getDisplayFormats();

    $scope.data = {
        titleLabel: ctrl.titleLabel,
        fields: ctrl.fields?.map((field) => Object.assign({}, field)) || [],
        isDraggable: ctrl.isDraggable,
        addButtonLabel: ctrl.addButtonLabel,
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        formQuestionType: ctrl.formQuestionType,
        fixedFields: ctrl.fixedFields || [],
        isAliasEnabled: ctrl.isAliasEnabled,
        defaultFields: ctrl.defaultFields || [],
        isInlineEnabled: ctrl.isInlineEnabled || false,

        // Sorting and filtering the fields
        filterFieldsOptions: ctrl.filterFieldsOptions,
        sortFields: ctrl.sortFields,
        sortOptionsBy: ctrl.sortOptionsBy,

        // Callback functions
        onMove: ctrl.onMove,
        onAdd: ctrl.onAdd,
        onRemove: ctrl.onRemove,
        onEdit: ctrl.onEdit,
        onReplace: ctrl.onReplace,
        formType: ctrl.formType,

        existingFieldIdsSet: {},

        // The limit number of the display name characters
        displayNameCharacterLimit: ctrl.displayNameCharacterLimit,
    };

    ctrl.$onInit = function () {
        // Build map from existing fields
        $scope.data.fields.forEach((field) => {
            if (!$scope.data.existingFieldIdsSet[field.fieldDefinition.id] && !!field) {
                const fieldWrapper = wrapFieldForm(field.fieldDefinition.id);
                $scope.data.existingFieldIdsSet[fieldWrapper.fieldDefinition.id] = true;
            }
        });

        // Push fixed and default fields
        [$scope.data.fixedFields, $scope.data.defaultFields].forEach((fields) => {
            fields?.forEach((fieldId) => {
                if (!$scope.data.existingFieldIdsSet[fieldId]) {
                    const field = wrapFieldForm(fieldId);
                    $scope.data.fields.push(field);
                    $scope.data.onAdd({ field });
                    $scope.data.existingFieldIdsSet[fieldId] = true;
                }
            });
        });

        // Add at least one dummy fields
        if ($scope.data.fields.length === 0) {
            $scope.addEmptyFormField();
        }

        sortFields();
    };

    ctrl.$onChanges = function (changedObj) {
        if (changedObj.fixedFields) {
            $scope.data.fixedFields = changedObj.fixedFields.currentValue;
        }
        if (changedObj.fields) {
            $scope.data.fields = ctrl.fields?.map((field) => ({ ...field })) || [];
        }
    };

    /**
     * Adds an empty field  to the list
     */
    $scope.addEmptyFormField = function () {
        const fieldObjWrapper = { showTitle: true };
        $scope.data.fields.push(fieldObjWrapper);
    };

    /**
     * When user changes an existing form field to another field
     */
    $scope.onFieldOptionSelected = function (wrapper, selectedFieldDefinition) {
        if (wrapper?.fieldDefinition && wrapper.fieldDefinition?.id !== selectedFieldDefinition.id) {
            // Updating map
            delete $scope.data.existingFieldIdsSet[wrapper.fieldDefinition.id];
            $scope.data.existingFieldIdsSet[selectedFieldDefinition?.id] = true;

            // We chose another field
            $scope.data.onReplace({ oldField: wrapper, newFieldDefinition: selectedFieldDefinition });
            wrapper.fieldDefinition = selectedFieldDefinition;
        } else if (!wrapper.fieldDefinition) {
            // Updating map
            $scope.data.existingFieldIdsSet[selectedFieldDefinition?.id] = true;

            // Field just got added
            wrapper.fieldDefinition = selectedFieldDefinition;
            $scope.data.onAdd({ field: wrapper });
        }

        if (
            selectedFieldDefinition?.updateable === false ||
            selectedFieldDefinition?.canUpdateFromUI === false ||
            selectedFieldDefinition?.idRelationField === true
        ) {
            wrapper.viewOnly = true;
        }

        sortFields();
    };

    /**
     * Handles moving a field with drag and drop
     * @param oldIndex - previous position of the field
     * @param newIndex - current position of the element
     */
    $scope.onFormFieldMoved = function (oldIndex, newIndex) {
        const movedItem = $scope.data.fields.splice(oldIndex, 1)[0];

        // If the old index is before the new one, removing the item will cause a shift in 1 position.
        if (oldIndex < newIndex) {
            newIndex = newIndex - 1;
        }

        // Place the item in its new position.
        $scope.data.fields.splice(newIndex, 0, movedItem);

        // Call parent callback
        $scope.data.onMove({ oldIndex, newIndex });
    };

    /**
     * Generates a delete function for a given field
     * @param field - the field to remove
     * @returns {function(...[*]=)}
     */
    $scope.removeFormField = function (index) {
        return () => {
            const field = $scope.data.fields.splice(index, 1)[0];
            $scope.data.existingFieldIdsSet[field?.fieldDefinition?.id] = false;

            $scope.data.onRemove({ field });
        };
    };

    /**
     * A function which toggles the field required property
     * @param field
     */
    $scope.toggleIsRequired = function (field) {
        return () => {
            const fieldWrapper = $scope.data.fields.find((f) => f.fieldDefinition.id === field.fieldDefinition.id);
            fieldWrapper.isRequired = !fieldWrapper.isRequired;

            // Call parent callback
            $scope.data.onEdit({ field: fieldWrapper });
        };
    };

    /**
     * A function which toggles the field inline property
     * @param field
     */
    $scope.toggleFieldInline = function (field) {
        return () => {
            const fieldWrapper = $scope.data.fields.find((f) => f.fieldDefinition.id === field.fieldDefinition.id);
            fieldWrapper.isInline = !fieldWrapper.isInline;

            // Call parent callback
            $scope.data.onEdit({ field: fieldWrapper });
        };
    };

    /**
     * A function which toggles the field show title property
     * @param field
     */
    $scope.toggleShowTitle = function (field) {
        const fieldWrapper = $scope.data.fields.find((f) => f.fieldDefinition.id === field.fieldDefinition.id);
        fieldWrapper.showTitle = !fieldWrapper.showTitle;

        // Call parent callback
        $scope.data.onEdit({ field: fieldWrapper });
    };

    /**
     * A function which toggles the field view only property
     * @param field
     */
    $scope.toggleViewOnly = function (field) {
        const fieldWrapper = $scope.data.fields.find((f) => f.fieldDefinition.id === field.fieldDefinition.id);
        fieldWrapper.viewOnly = !fieldWrapper.viewOnly;

        // Call parent callback
        $scope.data.onEdit({ field: fieldWrapper });
    };

    /**
     * A function which toggles whether the description will be collapsed by default
     * @param field
     */
    $scope.toggleCollapseDescriptionByDefault = function (field) {
        const fieldWrapper = $scope.data.fields.find((f) => f.fieldDefinition.id === field.fieldDefinition.id);
        fieldWrapper.collapseDescriptionByDefault = !fieldWrapper.collapseDescriptionByDefault;

        // Call parent callback
        $scope.data.onEdit({ field: fieldWrapper });
    };

    /**
     * A function which updates the validations
     * @param field
     * @param fieldValidation the updated field validations
     */
    $scope.onValidationChanged = function (field, fieldValidation) {
        const fieldWrapper = $scope.data.fields.find((f) => f.fieldDefinition.id === field.fieldDefinition.id);
        fieldWrapper.validation = fieldValidation;

        // Call parent callback
        $scope.data.onEdit({ field: fieldWrapper });
    };

    /**
     * A comparator function wrapper to sort the fields by
     * @param firstField
     * @param secondField
     * @returns {*}
     */
    $scope.sortSpecialFields = function (firstField, secondField) {
        return $scope.data.sortOptionsBy({ first: firstField, second: secondField });
    };

    /**
     * Handles what happens when the user types display name for a field (question)
     * @param fieldWrapper
     */
    $scope.onDisplayNameChanged = function (fieldWrapper) {
        $scope.data.onEdit({ field: fieldWrapper });
    };

    $scope.isDragDisabled = function () {
        return !$scope.data.isDraggable || $scope.data.fields.some((_field) => !_field.fieldDefinition);
    };

    /**
     * Sort the fields by a given sorting function
     */
    function sortFields() {
        const sortedFields = $scope.data.sortFields({ fields: $scope.data.fields });
        $scope.data.fields = Array.isArray(sortedFields) ? sortedFields : $scope.data.fields;
    }

    /**
     * Wrap a field definition with a form parameter
     * @param fieldId - The field definition ID
     * @param defaultValue - default value
     * @param isRequired - Is the field required
     * @param isInline - Should the field be inlined
     * @param displayName - A Display name for the field
     * @returns {{isRequired: boolean, fieldDefinition: null, defaultValue: string, displayName: string, isInline: boolean}}
     */
    function wrapFieldForm(fieldId, defaultValue = '', isRequired = false, isInline = false, displayName = '') {
        const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
            $scope.data.workflowVersionId,
            fieldId,
        );
        return {
            fieldDefinition,
            defaultValue,
            isRequired,
            displayName,
            isInline,
        };
    }
}

export default angular.module('tonkean.app').controller('FormFieldsSelectCtrl', lateConstructController(FormFieldsSelectCtrl));
