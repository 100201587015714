import ItemInterfaceUploadWidget from './ItemInterfaceUploadWidget';
import UploadWidgetConfigurationEditor from './UploadWidgetConfigurationEditor';
import type { ItemInterfaceWidgetPackage } from '../../WidgetModule';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';

const UploadWidgetPackage: ItemInterfaceWidgetPackage<ItemInterfaceWidgetConfiguration> = {
    displayComponent: ItemInterfaceUploadWidget,
    configurationComponent: UploadWidgetConfigurationEditor,
};

export default UploadWidgetPackage;
