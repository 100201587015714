import ItemDetailsWhenLine from './ItemDetailsWhenLine';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkItemDetailsWhenLine',
        reactToAngular(ItemDetailsWhenLine, [
            'workflowVersionId',
            'groupTargetId',
            'customTriggerTargetId',
            'overrideDetailsTitlePlaceholder',
            'monitorInnerItems',
            'monitoredInnerItemCustomTrigger',
            'showOnlyInnerItem',
        ]),
    );
