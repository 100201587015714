import { useEffect } from 'react';

import { analyticsWrapper } from '@tonkean/analytics';
import type { Initiative, ItemInterface } from '@tonkean/tonkean-entities';

function useItemInterfaceUpdateAnalyticsContext(
    itemInterface: ItemInterface | undefined,
    initiative: Initiative | undefined,
) {
    useEffect(() => {
        if (itemInterface) {
            analyticsWrapper.setGlobalContext({
                isInterfaceContext: true,
                interfaceContextDraftMode: initiative?.isDraftInitiative,
            });
        }
    }, [itemInterface, initiative]);

    useEffect(() => {
        return () => {
            analyticsWrapper.setGlobalContext({
                isInterfaceContext: false,
            });
        };
    }, []);
}

export default useItemInterfaceUpdateAnalyticsContext;
