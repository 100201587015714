import { useMemo } from 'react';

import { filterPairValues } from '@tonkean/utils';

// Get the type of an item in the values list in the pairs list.
type PairSingleValue<T> = T extends [any, (infer T)[]][] ? T : never;

/**
 * React hook for filtering by a string a list of pair values.
 *
 * @param items - list of pairs with a list to filter as value.
 * @param filterFunction - a filter function that returns true if should stay in the filtered list of pairs.
 * @returns filtered list of items, the current filter value and a function to set a new filter value.
 */
function usePairValuesFilter<T extends [any, any[]][]>(
    items: T,
    filterFunction: (item: PairSingleValue<T>, searchString: string) => boolean,
    filter: string,
) {
    // Handle field definition filtering
    const filteredItems = useMemo(() => {
        const searchString = filter.toLowerCase();
        // Filter the pairs list based on the search string
        return filterPairValues(items, (item) => filterFunction(item, searchString)) as T;
    }, [items, filter, filterFunction]);

    return filteredItems;
}

export default usePairValuesFilter;
