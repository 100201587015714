import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { IGraphViewProps } from 'react-digraph';
import styled, { css } from 'styled-components';

import useProcessMapperNodesToStage from './hooks/useProcessMapperNodesToStage';
import type ProcessMapperWidgetConfiguration from './ProcessMapperWidgetConfiguration';
import ProcessMapperWidgetEmptyState from './ProcessMapperWidgetEmptyState';
import type { ItemWidgetProps } from '../../WidgetModule';
import { ItemWidget } from '../../WidgetModule';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { ProcessMapperLoadingSkeleton } from '@tonkean/process-mapper';
import { ProcessMapperDisplayableGraph } from '@tonkean/process-mapper';
import { Theme } from '@tonkean/tui-theme';

const CustomItemWidget = styled(ItemWidget)<{ height: number | undefined }>`
    overflow: visible;
    min-height: ${({ height }) => (height ? `${height}vh` : '90vh')};
`;

const ProcessMapperGraphContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const SkeletonContainer = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${Theme.colors.white};
`;

const ProcessMapperHiddenContainer = styled.div<{ $hide: boolean }>`
    height: 100%;
    width: 100%;

    ${({ $hide }) =>
        $hide &&
        css`
            opacity: 0;
        `};
`;

const ProcessMapperWidget: React.FC<ItemWidgetProps<ProcessMapperWidgetConfiguration>> = ({
    initiative,
    widget,
    permission,
}) => {
    const { workflowVersion } = useItemInterfaceContext();

    const graphRef = useRef<React.Component<IGraphViewProps, any, any>>(null);

    const [{ data, loading }, getProcessMapperFullData] = useLazyTonkeanService('getProcessMapperFullData');

    useEffect(() => {
        if (widget.configuration.selectedProcessMapper && workflowVersion && !loading && !data) {
            getProcessMapperFullData(widget.configuration.selectedProcessMapper, workflowVersion?.id);
        }
    }, [data, getProcessMapperFullData, loading, widget.configuration.selectedProcessMapper, workflowVersion]);

    const { processMapperNodesToStage, loadingProcessMapperNodesToStage } = useProcessMapperNodesToStage({
        initiative,
        nodes: data?.processMapperNodes || [],
    });

    const allDataLoaded = useMemo(() => {
        return !!data && !loadingProcessMapperNodesToStage;
    }, [data, loadingProcessMapperNodesToStage]);

    const [showOverlayLoader, setShowOverlayLoader] = useState<boolean>(true);

    // When the component mounts or `data` changes, this effect will run
    useEffect(() => {
        if (allDataLoaded) {
            // We use a loader to hide the graph animation shift in react-digraph
            // The loader is shown initially, and after 1700ms (1.7 seconds), it's hidden
            // This allows the graph animation to complete and the graph to stabilize before displaying
            // The loader is hidden by setting `showOverlayLoader` to `false`

            // Set up a timeout to hide the loader after 1.7 seconds
            const timeoutId = setTimeout(() => {
                setShowOverlayLoader(false);
            }, 1700);

            // Clean up the timeout when the component unmounts or when `data` changes
            return () => clearTimeout(timeoutId);
        }
    }, [allDataLoaded]);

    return (
        <CustomItemWidget height={widget.configuration.height} permission={permission}>
            {widget.configuration.selectedProcessMapper ? (
                allDataLoaded && data ? (
                    <ProcessMapperGraphContainer>
                        {showOverlayLoader && (
                            <SkeletonContainer>
                                <ProcessMapperLoadingSkeleton />
                            </SkeletonContainer>
                        )}
                        <ProcessMapperHiddenContainer $hide={showOverlayLoader}>
                            <ProcessMapperDisplayableGraph
                                processMapper={data.processMapper}
                                initiative={initiative}
                                processMapperNodes={data.processMapperNodes}
                                processMapperEdges={data.processMapperEdges}
                                graphRef={graphRef}
                                processMapperNodeToStage={processMapperNodesToStage}
                            />
                        </ProcessMapperHiddenContainer>
                    </ProcessMapperGraphContainer>
                ) : (
                    <ProcessMapperLoadingSkeleton />
                )
            ) : (
                <ProcessMapperWidgetEmptyState />
            )}
        </CustomItemWidget>
    );
};

export default ProcessMapperWidget;
