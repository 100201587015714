import { useAngularService } from 'angulareact';
import type React from 'react';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import initialFilters from '../entities/initialFilters';
import type WorkerRunDateRangeFilter from '../entities/WorkerRunDateRangeFilter';
import workerRunReasonConfig from '../entities/workerRunReasonConfig';
import type WorkerRunReasonConfigItem from '../entities/WorkerRunReasonConfigItem';
import type WorkerRunsFilter from '../entities/WorkerRunsFilter';
import type WorkerRunStagesFilter from '../entities/WorkerRunStagesFilter';
import originatedWorkerRunState from '../states/originatedWorkerRunState';

import { useFetchManager, LIMIT_PARAM, NEXT_PAGE_TOKEN_PARAM, SKIP_PARAM } from '@tonkean/infrastructure';
import type { Environment, WorkerRunReason, WorkerRunStage } from '@tonkean/tonkean-entities';

const workerRunReasonConfigEntries = Object.entries(workerRunReasonConfig) as [
    WorkerRunReason,
    WorkerRunReasonConfigItem,
][];
const SUPPORTED_WORKER_RUN_REASONS = workerRunReasonConfigEntries
    .filter(([, workerRunConfig]) => !workerRunConfig.isDeprecated)
    .map(([workerRunReason]) => workerRunReason);
const SUPPORTED_WORKER_RUN_REASONS_WITHOUT_NOT_RUN = SUPPORTED_WORKER_RUN_REASONS.filter(
    (workerRunReason) => !workerRunReasonConfig[workerRunReason].hasNotRun,
);

const MAXIMUM_AVAILABLE_STAGES = Object.keys(initialFilters.stages).length;
const AUTO_RELOAD_WORKER_RUNS_INTERVAL_MS = 2000;
const PAGE_SIZE = 50;

/**
 * React hook that manages fetching the worker runs list based on the given filters, auto-reloading and manual
 * reloading.
 *
 * @param groupId - the current group id.
 * @param searchTerm - the search term to search by.
 * @param filters - the filters to search by.
 * @param workerRunStagesFilter - the stages to search for.
 * @param workerRunDateRangeFilter - the date range to search for.
 * @param environment - the current environment.
 * @param onLoading - function to emit loading state.
 * @param currentlyInspecting - does an inspect modal currently open? If it does, the auto-reload will stop.
 * @param workerRunsWrapperRef - react ref of the scrollable wrapper element. When manually reloading, it will scroll
 * the wrapper content to the top.
 */
function useWorkerRunsFetchManager(
    groupId: string,
    searchTerm: string,
    filters: WorkerRunsFilter,
    workerRunStagesFilter: WorkerRunStagesFilter,
    workerRunDateRangeFilter: WorkerRunDateRangeFilter,
    environment: Environment | undefined,
    onLoading: (isLoading: boolean) => void,
    currentlyInspecting: boolean,
    workerRunsWrapperRef: React.RefObject<HTMLDivElement>,
) {
    const tonkeanService = useAngularService('tonkeanService');
    const projectManager = useAngularService('projectManager');
    const originatedWorkerRun = useRecoilValue(originatedWorkerRunState);

    const [
        [getWorkerRuns, stopFetcher],
        { data: workerRuns, loading, hasMorePages, error, manuallyReload, loadNextPage },
    ] = useFetchManager(tonkeanService, 'getWorkerRuns', {
        getNextPageToken: 'nextPageToken',
        checkHasMore: 'hasMore',
        autoReloadInterval: currentlyInspecting ? undefined : AUTO_RELOAD_WORKER_RUNS_INTERVAL_MS,
        limit: PAGE_SIZE,
        sort: { key: 'updated', desc: true },
    });

    // List of worker run stages to get, based on the filter object.
    const workerRunStagesFilterArray = useMemo(() => {
        const stagesFilterArray = Object.entries(workerRunStagesFilter)
            .filter(([_stage, active]) => !!active)
            .map(([stage]) => stage as WorkerRunStage);

        // If all are true, we will send an empty array to get all stages
        if (stagesFilterArray.length === MAXIMUM_AVAILABLE_STAGES) {
            return [];
        }

        return stagesFilterArray;
    }, [workerRunStagesFilter]);

    useLayoutEffect(() => {
        getWorkerRuns(
            projectManager.project.id,
            groupId,
            {
                triggerId: filters.trigger,
                onlyError: filters.errors === 'onlyErrors',
                onlyNonError: filters.errors === 'noErrors',
                startedFrom: workerRunDateRangeFilter.dateFrom.getTime?.(),
                startedTo: workerRunDateRangeFilter.dateTo?.getTime?.(),
                workflowVersionSequentialIdentifier: filters.version,
                onlyTestRuns: environment === 'build',
                onlyProductionRuns: environment === 'production',
                searchText: searchTerm || undefined,
                workerRunStages: workerRunStagesFilterArray,
                onlyWorkerRunReasons: filters.showHasNotRun
                    ? SUPPORTED_WORKER_RUN_REASONS
                    : SUPPORTED_WORKER_RUN_REASONS_WITHOUT_NOT_RUN,
                backgroundProcessId: filters.backgroundProcess,
                originatedFromWorkerRunId: originatedWorkerRun?.workerRun?.id,
                originatedCustomTrigger: originatedWorkerRun?.customTriggerId,
            },
            SKIP_PARAM,
            LIMIT_PARAM,
            NEXT_PAGE_TOKEN_PARAM,
        );

        return () => {
            stopFetcher();
        };
    }, [
        environment,
        filters,
        getWorkerRuns,
        groupId,
        originatedWorkerRun?.customTriggerId,
        originatedWorkerRun?.workerRun.id,
        projectManager.project.id,
        searchTerm,
        stopFetcher,
        workerRunStagesFilterArray,
        workerRunDateRangeFilter,
    ]);

    const manuallyReloading = loading.manualReloading;
    const showLoadingPlaceholders = loading.initial || loading.nextPageLoading;

    /**
     * Emit a loading state to the modal root, so it will know if to show loading state or empty state
     * to the right side of the modal if there is no selected worker run (we don't want to show empty state
     * if we can auto select one after the workerRuns list loads).
     */
    useEffect(() => {
        onLoading(showLoadingPlaceholders);
    }, [onLoading, showLoadingPlaceholders]);

    const reload = useCallback(() => {
        manuallyReload();
        workerRunsWrapperRef.current?.scroll({ behavior: 'smooth', top: 0 });
    }, [manuallyReload, workerRunsWrapperRef]);

    return {
        workerRuns,
        showLoadingPlaceholders,
        error,
        reload,
        hasMorePages,
        loadNextPage,
        manuallyReloading,
    };
}

export default useWorkerRunsFetchManager;
