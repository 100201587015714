enum WorkflowVersionInputSourceType {
    MANUAL = 'MANUAL',
    SCHEDULED = 'SCHEDULED',
    ANOTHER_MODULE = 'ANOTHER_MODULE',
    DATA_SOURCE = 'DATA_SOURCE',
    EMAIL = 'EMAIL',
    CHAT = 'CHAT',
    FORMS = 'FORMS',
}

export default WorkflowVersionInputSourceType;
