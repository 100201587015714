import { useAngularService } from 'angulareact';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import getFieldLabelToDisplay from '../../../utils/getFieldLabelToDisplay';
import { getIsInWidgetBuilder } from '../../../WidgetModule';
import type { WidgetFieldPackage } from '../../CommonWidgetConfiguration';
import useFieldsWidgeEffectiveDisplayType from '../../hooks/useFieldsWidgeEffectiveDisplayType';
import useGetLoadedFieldDefinitionById from '../../hooks/useGetLoadedFieldDefinitionById';
import { FieldsWidgetDisplayType } from '../FieldsItemWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { SPECIAL_FIELD_KEYS } from '@tonkean/constants';
import type { UpdateListFieldProps } from '@tonkean/fields';
import { FieldError, FieldOpenFullscreenButton, FieldTrendIndicator, UpdateField } from '@tonkean/fields';
import { Breakpoint, Field, SavingIndicator, Spacer, TextEllipsis, useIsGuestData } from '@tonkean/infrastructure';
import { DataNotUpToDateIndicator } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import useConstantRefCallback from '@tonkean/tui-hooks/useConstantRefCallback';
import { Theme, FontSize } from '@tonkean/tui-theme';

const StyledField = styled(Field)<{
    displayType: FieldsWidgetDisplayType;
    fullWidth?: boolean;
    viewOnly?: boolean;
}>`
    ${({ fullWidth }) =>
        fullWidth &&
        css`
            grid-column: 1/-1;
        `}

    ${({ displayType, viewOnly }) => {
        if (displayType === FieldsWidgetDisplayType.GALLERY) {
            return css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                outline: 1px solid ${Theme.colors.gray_300};
                padding: 16px;

                ${viewOnly &&
                css`
                    border-radius: 4px;
                    margin: 8px;
                    background-color: white;
                    white-space: initial;
                    word-wrap: break-word;
                `}

                @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
                    padding: 8px;
                }
            `;
        } else if (displayType === FieldsWidgetDisplayType.TABLE) {
            return css`
                padding: 0px 16px 0px 24px;
            `;
        } else {
            return;
        }
    }}

    // This is used to fix the visual alignment of the field label and the text. 
    // It is placed here so it doesn't interfere with the Field component in other regions. 
    ${({ displayType }) =>
        displayType === FieldsWidgetDisplayType.LIST &&
        css`
            label {
                margin-left: 8px;
            }
        `}

    ${({ displayType }) =>
        displayType === FieldsWidgetDisplayType.TABLE &&
        css`
            flex-diryhection: row;
            display: grid;
            grid-template-columns: 25% 75%;
            align-items: start;
            justify-content: start;
            padding-top: 10px;
            padding-bottom: 10px;
        `}

    .field-open-fs-btn {
        visibility: collapse;
    }

    &:hover {
        .field-open-fs-btn {
            visibility: visible;
        }
    }
`;

const FieldLabel = styled.div<{
    displayType: FieldsWidgetDisplayType;
    isEmphasized?: boolean;
}>`
    font-size: ${({ isEmphasized, displayType }) => {
        return isEmphasized
            ? FontSize.LARGE_16
            : displayType === FieldsWidgetDisplayType.TABLE
              ? FontSize.MEDIUM_14
              : FontSize.SMALL_12;
    }};

    color: ${Theme.colors.gray_700};
    font-weight: 300;

    ${({ displayType }) => {
        if (displayType === FieldsWidgetDisplayType.GALLERY) {
            return css`
                text-align: left;
            `;
        } else if (displayType === FieldsWidgetDisplayType.TABLE) {
            return css`
                font-weight: 500;
                padding: 12px 0;
                min-height: 40px;
                line-height: 16px;
            `;
        }
    }}
`;

const MissingFieldTitleText = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.warning};
`;

const FieldDataWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const FieldValue = styled.div<{
    isInGalleryView?: boolean;
    isEmphasizeText?: boolean;
}>`
    ${({ isInGalleryView, isEmphasizeText }) => {
        const fontSize = isEmphasizeText ? 24 : 22;
        const fontWeight = isEmphasizeText ? 500 : 100;
        if (isInGalleryView) {
            return css`
                width: 100%;
                background-color: transparent;

                input {
                    background-color: transparent;
                    font-size: ${fontSize}px;
                    font-weight: ${fontWeight};
                }
                @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
                    width: 100%;
                }
            `;
        } else if (isEmphasizeText) {
            return css`
                input {
                    font-size: ${fontSize}px;
                    font-weight: ${fontWeight};
                }
            `;
        } else {
            return;
        }
    }}
`;

const SavingIndicatorAligner = styled(SavingIndicator)`
    visibility: hidden;
`;

const StyledFieldTrendIndicator = styled(FieldTrendIndicator)<{ isInGalleryView?: boolean }>`
    margin-left: 14px;
    width: fit-content;
    ${({ isInGalleryView }) =>
        isInGalleryView &&
        css`
            margin-left: 0;
        `}
    .compare-time-frame {
        margin-left: 4px;
        font-size: ${FontSize.SMALL_12};
        font-weight: 100;
    }

    .trend-indicator-icon {
        margin-right: 4px;
    }
`;

interface Props {
    fieldDefinitionId: string;
    field: WidgetFieldPackage | undefined;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    submitAsForm: boolean;
    labelOverride?: string;
    fullWidth?: boolean;
    fieldDisplay?: FieldsWidgetDisplayType;
    fieldDisplayInMobile?: FieldsWidgetDisplayType;
    hideColorsAndTrends?: boolean;
    showEmptyFieldWhenNoInitiative: boolean;
    onChange(newValue: any): Promise<void>;
    onValueSet?: (value: any) => void;
    showModuleOffIndication?: boolean;
    editingEnabledInWidget?: boolean;
    canUserEditItem?: boolean;
    showAsQuestionWidget?: boolean;
    onSave?: (saving: boolean) => void;
    onSavingError?: (savingError) => void;
    placeholderText?: string;
    updateListFieldProps?: UpdateListFieldProps;
    hasError?: boolean;
    emphasizeText?: boolean;
    dataAutomation?: string;
    isDisabled?: boolean;
}

const SingleField: React.FC<Props> = ({
    projectId,
    fieldDefinitionId,
    field,
    labelOverride,
    submitAsForm,
    fullWidth,
    fieldDisplay,
    hideColorsAndTrends,
    showEmptyFieldWhenNoInitiative,
    onChange,
    onValueSet,
    showModuleOffIndication,
    editingEnabledInWidget,
    canUserEditItem,
    fieldDisplayInMobile,
    showAsQuestionWidget,
    onSave,
    onSavingError,
    placeholderText,
    updateListFieldProps,
    hasError,
    emphasizeText,
    dataAutomation,
    isDisabled = false,
}) => {
    const emphasizedTextFF = useFeatureFlag('tonkean_feature_emphasize_fields_widget_text') as boolean;
    const $state = useAngularService('$state');
    const isInBuilder = getIsInWidgetBuilder($state);

    const currentUserService = useAngularService('currentUserService');

    let doesCurrentUserHavePermissionToEdit = true;

    if (!field) {
        doesCurrentUserHavePermissionToEdit = false;
    } else if (!field.fieldDefinition.isSpecialField) {
        doesCurrentUserHavePermissionToEdit = currentUserService.doesCurrentUserHavePermissionToEdit(
            field.fieldDefinition,
            field.initiative?.owner,
            field.initiative?.group,
            field.initiative?.creator,
        );
    }

    const getLoadedFieldDefinitionById = useGetLoadedFieldDefinitionById(field?.workflowVersion?.id);

    const fieldDefinitionData = field?.fieldDefinition.definition as object | undefined;

    const isMatchedEntityField =
        !!fieldDefinitionData?.['matchConfiguration'] || !!field?.fieldDefinition.idRelationField;

    const isEnvironmentActive = field?.environmentIsActive;
    const viewOnly =
        !(
            editingEnabledInWidget &&
            field?.fieldDefinition.updateable &&
            field?.fieldDefinition.canUpdateFromUI &&
            canUserEditItem &&
            doesCurrentUserHavePermissionToEdit &&
            !isMatchedEntityField &&
            isEnvironmentActive
        ) || isDisabled;

    const onChangeObject = useMemo(() => {
        return { onChange };
    }, [onChange]);
    const [{ loading: onChangeLoading, error: onChangeError }, invokeOnChange] = useLazyAsyncMethod(
        onChangeObject,
        'onChange',
    );
    const onValueSave = useConstantRefCallback((newValue) => {
        if (viewOnly) {
            return;
        }
        return invokeOnChange(newValue);
    });
    useEffect(() => {
        onSave?.(onChangeLoading);
    }, [onSave, onChangeLoading]);
    useEffect(() => {
        onSavingError?.(onChangeError);
    }, [onChangeError, onSavingError]);

    const { dirty, isSubmitting } = useFormikContext();

    const widgetEffectiveDisplayType = useFieldsWidgeEffectiveDisplayType(
        showAsQuestionWidget ? FieldsWidgetDisplayType.LIST : fieldDisplay,
        showAsQuestionWidget ? FieldsWidgetDisplayType.LIST : fieldDisplayInMobile,
    );

    const isInGalleryView = widgetEffectiveDisplayType == FieldsWidgetDisplayType.GALLERY;

    const showFieldsWidgetsReadOnlyTable = useFeatureFlag('tonkean_feature_show_fields_widgets_read_only_table');

    if (useIsGuestData(projectId) && fieldDefinitionId === SPECIAL_FIELD_KEYS.itemNotificationChannel) {
        return <></>;
    }

    return (
        <StyledField
            dataAutomation={dataAutomation}
            key={fieldDefinitionId}
            displayType={widgetEffectiveDisplayType}
            viewOnly={!editingEnabledInWidget && isInGalleryView && showFieldsWidgetsReadOnlyTable}
            showLabelInline={widgetEffectiveDisplayType === FieldsWidgetDisplayType.TABLE}
            label={
                showAsQuestionWidget ? undefined : (
                    <FieldLabel
                        data-automation="single-field-name"
                        displayType={widgetEffectiveDisplayType}
                        isEmphasized={emphasizeText}
                    >
                        {field ? (
                            <span className="flex-vmiddle">
                                <TextEllipsis
                                    numberOfLines={widgetEffectiveDisplayType !== FieldsWidgetDisplayType.TABLE ? 1 : 0}
                                    overflowHidden={widgetEffectiveDisplayType !== FieldsWidgetDisplayType.TABLE}
                                    tooltip
                                >
                                    {getFieldLabelToDisplay(field.fieldDefinition, labelOverride)}{' '}
                                </TextEllipsis>
                                <Spacer width={5} />
                                {!showAsQuestionWidget && !hideColorsAndTrends && (
                                    <FieldOpenFullscreenButton
                                        showEmptyFieldWhenNoInitiative={showEmptyFieldWhenNoInitiative}
                                        groupId={field.groupId}
                                        initiative={field.initiative}
                                        workflowVersion={field.workflowVersion}
                                        fieldDefinition={field.fieldDefinition}
                                    />
                                )}
                                <DataNotUpToDateIndicator show={!isEnvironmentActive && showModuleOffIndication} />
                                {!viewOnly && isInGalleryView && <SavingIndicatorAligner />}
                                {!viewOnly && <SavingIndicator loading={onChangeLoading} error={onChangeError} />}
                            </span>
                        ) : (
                            <MissingFieldTitleText>Missing Field</MissingFieldTitleText>
                        )}
                    </FieldLabel>
                )
            }
            fullWidth={fullWidth}
            light
        >
            <FieldValue isInGalleryView={isInGalleryView} isEmphasizeText={emphasizeText && emphasizedTextFF}>
                {field ? (
                    <FieldDataWrapper>
                        <UpdateField
                            showEmptyFieldWhenNoInitiative={showEmptyFieldWhenNoInitiative}
                            fieldDefinition={field.fieldDefinition}
                            hideColorsAndTrends={hideColorsAndTrends}
                            onValueSave={onValueSave}
                            onValueSet={onValueSet}
                            projectId={projectId}
                            groupId={field.groupId}
                            initiative={field.initiative}
                            workflowVersion={field.workflowVersion}
                            viewOnly={viewOnly}
                            revealByDefault={
                                showAsQuestionWidget ||
                                submitAsForm ||
                                (widgetEffectiveDisplayType === FieldsWidgetDisplayType.TABLE && !viewOnly)
                            }
                            fieldsAreBeingEdited={dirty}
                            isSubmittingForm={isSubmitting || onChangeLoading}
                            placeholderText={placeholderText}
                            hasError={hasError}
                            isInBuilder={isInBuilder}
                            updateListFieldProps={updateListFieldProps}
                            isEmphasizeText={emphasizeText && emphasizedTextFF}
                            isInGalleryView={isInGalleryView}
                            isInTableView={widgetEffectiveDisplayType === FieldsWidgetDisplayType.TABLE}
                            editingEnabledInWidget={editingEnabledInWidget}
                            getLoadedFieldDefinitionById={getLoadedFieldDefinitionById}
                            submitAsForm={submitAsForm}
                            saveWhileTyping
                        />
                        {!hideColorsAndTrends && (
                            <StyledFieldTrendIndicator
                                showEmptyFieldWhenNoInitiative={showEmptyFieldWhenNoInitiative}
                                groupId={field.groupId}
                                initiative={field.initiative}
                                workflowVersion={field.workflowVersion}
                                fieldDefinition={field.fieldDefinition}
                                isInGalleryView={isInGalleryView}
                            />
                        )}
                    </FieldDataWrapper>
                ) : (
                    <FieldError
                        fieldErrorText="Data can't be displayed"
                        tooltipText="Data can't be displayed since the item is missing this field."
                    />
                )}
            </FieldValue>
        </StyledField>
    );
};

export default SingleField;
