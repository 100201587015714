import FunctionOperatorBase from './FunctionOperatorBase';
import { separatorCharsPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class ConvertToArrayFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.CONVERT_TO_ARRAY;
    override readonly sign = 'ConvertToArray';
    override readonly displayName = 'Convert to Array';
    override readonly description = 'Will return an array for the given string and separator.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'String Array',
            explanation: 'A string array separated by any separator.',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Separator',
            dataType: FieldType.String,
            defaultValue: ',',
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: separatorCharsPreset,
        },
    ];
}
