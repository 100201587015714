<div class="tnk-docusign-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'UPLOAD_TRANSIENT_DOCUMENT'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            parameters-values="data.definition.parametersValues"
            info-message="'Maximum document size is 5MB'"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <div class="margin-top">
            <div class="margin-bottom-xs">
                New Document Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Document name"
                saved-expression="data.definition.newDocumentNameExpression"
                on-tonkean-expression-changed="onNewDocumentNameTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'TRANSFER_AGREEMENT_DOCUMENT'" class="margin-bottom-xlg">
        <tnk-worker-target-storage-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            should-display-delete-sign="false"
            new-document-name="data.definition.newDocumentName"
            info-message="'Maximum document size is 5MB'"
            on-worker-target-storage-selection-changed="
                onWorkerTargetStorageSelectionChanged(storageIntegrationId, newDocumentName, shouldSaveLogic)
            "
        ></tnk-worker-target-storage-selection>

        <div
            ng-if="data.validationObject.storageIntegrationId"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.storageIntegrationId }}
        </div>

        <!-- Target Path -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Target Path:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the root folder will be used"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Target Path"
                saved-expression="data.definition.targetPathExpression"
                on-tonkean-expression-changed="onTargetPathTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Version Id -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Version Id:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If not provided then latest version will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Version Id"
                saved-expression="data.definition.versionIdExpression"
                on-tonkean-expression-changed="onVersionTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Attach Supporting Documents -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Attach Supporting Documents:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Attach corresponding supporting documents to the signed agreement PDF. Default: true."
                ></i>
            </div>

            <ui-select
                theme="bootstrap"
                ng-model="data.definition.attachSupportingDocuments"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Attach Supporting Documents">
                    {{ data.definition.attachSupportingDocuments }}
                </ui-select-match>

                <ui-select-choices
                    repeat="attachSupportingDocuments in data.possibleBooleanValues | filter:$select.search"
                >
                    <div ng-bind-html="attachSupportingDocuments | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Attach Audit Report -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Attach Audit Report:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Attach an audit report to the signed agreement PDF. Default: false."
                ></i>
            </div>

            <ui-select
                theme="bootstrap"
                ng-model="data.definition.attachAuditReport"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Attach Supporting Documents">
                    {{ data.definition.attachAuditReport }}
                </ui-select-match>

                <ui-select-choices repeat="attachAuditReport in data.possibleBooleanValues | filter:$select.search">
                    <div ng-bind-html="attachAuditReport | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'CREATE_AGREEMENT'" class="margin-bottom-xlg">
        <!-- Agreement Name -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Agreement Name:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Agreement Name"
                saved-expression="data.definition.agreementNameExpression"
                on-tonkean-expression-changed="onAgreementNameTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>

            <div
                ng-if="data.validationObject.agreementNameError"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.agreementNameError }}
            </div>
        </div>

        <!-- Agreement State -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Agreement State:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Select 'IN_PROCESS' for sending the envelope immediately."
                ></i>
            </div>

            <ui-select
                theme="bootstrap"
                ng-model="data.definition.agreementState"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Choose agreement state">
                    {{ data.definition.agreementState }}
                </ui-select-match>

                <ui-select-choices repeat="agreementState in data.possibleAgreementStates | filter:$select.search">
                    <div ng-bind-html="agreementState | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>

            <div
                ng-if="data.validationObject.agreementStateError"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.agreementStateError }}
            </div>
        </div>

        <!-- Template/Library Document ID -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Template/Library Document ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Template/Library Document ID"
                saved-expression="data.definition.templateIdExpression"
                on-tonkean-expression-changed="onTemplateIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Transient Document Id -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Transient Document Id:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Transient Document Id"
                saved-expression="data.definition.transientDocumentIdExpression"
                on-tonkean-expression-changed="
                    onTransientDocumentIdTonkeanExpressionChanged(expression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>

            <div
                ng-if="data.validationObject.agreementFileError"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.agreementFileError }}
            </div>
        </div>

        <!-- Signature Type -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Signature Type:
                <i class="fa fa-info-circle common-color-primary margin-left-xs" uib-tooltip="Default: 'ESIGN'."></i>
            </div>

            <ui-select theme="bootstrap" ng-model="data.definition.signatureType" ng-change="onDefinitionChanged(true)">
                <ui-select-match placeholder="Choose signature type">
                    {{ data.definition.signatureType }}
                </ui-select-match>

                <ui-select-choices repeat="signatureType in data.possibleSignatureTypes | filter:$select.search">
                    <div ng-bind-html="signatureType | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>

            <div
                ng-if="data.validationObject.signatureTypeError"
                class="margin-top-xs margin-bottom-xs common-color-danger"
            >
                {{ data.validationObject.signatureTypeError }}
            </div>
        </div>

        <!-- External Id -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                External ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="An arbitrary value from your system, which can be specified at sending time and then later returned or queried."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="External ID"
                saved-expression="data.definition.agreementExternalIdExpression"
                on-tonkean-expression-changed="
                    onAgreementExternalIdTonkeanExpressionChanged(expression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <div class="margin-bottom">
            <div ng-if="data.definition.participantGroups.length > 0" class="margin-right margin-top-xs margin-bottom">
                Participant Groups ({{ data.definition.participantGroups.length }}):
            </div>

            <div
                ng-repeat="(participantGroupIndex, participantGroup) in data.definition.participantGroups"
                class="flex"
            >
                <div class="margin-left-xs margin-right-xs margin-top">{{ participantGroupIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Role -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">Role:</div>

                            <div class="flex-grow"></div>

                            <button
                                class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                ng-click="removeParticipantGroup(participantGroupIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <ui-select
                            theme="bootstrap"
                            ng-model="data.definition.participantGroups[participantGroupIndex].role"
                            ng-change="onDefinitionChanged(true)"
                        >
                            <ui-select-match placeholder="Participant group role">
                                {{ data.definition.participantGroups[participantGroupIndex].role }}
                            </ui-select-match>

                            <ui-select-choices
                                repeat="participantRole in data.possibleParticipantsRoles | filter:$select.search"
                            >
                                <div ng-bind-html="participantRole | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <!-- Order -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Order:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.participantGroups[participantGroupIndex].orderExpression"
                            on-tonkean-expression-changed="
                                onParticipantOrderTonkeanExpressionChanged(
                                    expression,
                                    participantGroupIndex,
                                    shouldSaveLogic
                                )
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Group Message -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Group Message:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.participantGroups[participantGroupIndex].privateMessageExpression"
                            on-tonkean-expression-changed="
                                onParticipantGroupMessageTonkeanExpressionChanged(
                                    expression,
                                    participantGroupIndex,
                                    shouldSaveLogic
                                )
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Participants -->
                    <div
                        ng-repeat="(participantObjectIndex, participantObject) in data.definition.participantGroups[participantGroupIndex].participantObjects"
                        class="flex"
                    >
                        <div class="margin-left-xs margin-right-xs margin-top">
                            {{ participantGroupIndex + 1 }}.{{ participantObjectIndex + 1 }}.
                        </div>
                        <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                            <!-- Participant Email -->
                            <div class="margin-bottom">
                                <div class="flex-vmiddle">
                                    <div class="margin-right margin-top-xs">Email:</div>

                                    <div class="flex-grow"></div>

                                    <button
                                        class="
                                            svg-icon-xs
                                            common-color-grey
                                            svg-icon-hover-primary
                                            btn btn-no-border btn-on-hover
                                            pointer
                                            flex-no-shrink
                                            margin-left-xxs
                                        "
                                        ng-click="removeParticipantObject(participantGroupIndex, participantObjectIndex)"
                                    >
                                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                    </button>
                                </div>

                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    saved-expression="data.definition.participantGroups[participantGroupIndex].participantObjects[participantObjectIndex].participantEmailExpression"
                                    on-tonkean-expression-changed="
                                        onParticipantEmailTonkeanExpressionChanged(
                                            expression,
                                            participantGroupIndex,
                                            participantObjectIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>

                            <!-- Participant Name -->
                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Name:</div>
                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    saved-expression="data.definition.participantGroups[participantGroupIndex].participantObjects[participantObjectIndex].participantNameExpression"
                                    on-tonkean-expression-changed="
                                        onParticipantNameTonkeanExpressionChanged(
                                            expression,
                                            participantGroupIndex,
                                            participantObjectIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>
                        </div>
                    </div>
                    <a ng-click="addParticipantObject(participantGroupIndex)">+ Add Participant</a>
                </div>
            </div>

            <a ng-click="addParticipantGroup()">+ Add Participant Group</a>
            <div ng-if="data.validationObject.participantsDataError" class="common-color-danger">
                {{ data.validationObject.participantsDataError }}
            </div>
        </div>
    </div>
</div>
