import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';

import AnotherModuleInputSourceSelectionContent from './contentComponents/anotherModule/AnotherModuleInputSourceSelectionContent';
import ChatInputSourceSelectionContent from './contentComponents/ChatInputSourceSelectionContent';
import GenericDatasourceInputSourceSelection from './contentComponents/dataSource/GenericDatasourceInputSourceSelection';
import EmailInputSourceSelectionContent from './contentComponents/EmailInputSourceSelectionContent';
import FormsInputSourceSelectionContent from './contentComponents/FormsInputSourceSelectionContent';
import ManualInputSourceSelectionContent from './contentComponents/ManualInputSourceSelectionContent';
import ScheduledInputSourceSelectionContent from './contentComponents/scheduled/ScheduledInputSourceSelectionContent';
import type { InputSourceContentComponentProps } from './InputSourceContentComponentProps';
import WorkflowVersionInputSourceType from '../../entities/WorkflowVersionInputSourceType';

import type { TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import { DataSourceType } from '@tonkean/tonkean-entities';

const workflowVersionInputSourceTypeToContentComponent: Record<
    WorkflowVersionInputSourceType,
    React.FC<InputSourceContentComponentProps>
> = {
    [WorkflowVersionInputSourceType.MANUAL]: ManualInputSourceSelectionContent,
    [WorkflowVersionInputSourceType.FORMS]: FormsInputSourceSelectionContent,
    [WorkflowVersionInputSourceType.SCHEDULED]: ScheduledInputSourceSelectionContent,
    [WorkflowVersionInputSourceType.DATA_SOURCE]: GenericDatasourceInputSourceSelection,
    [WorkflowVersionInputSourceType.EMAIL]: EmailInputSourceSelectionContent,
    [WorkflowVersionInputSourceType.CHAT]: ChatInputSourceSelectionContent,
    [WorkflowVersionInputSourceType.ANOTHER_MODULE]: AnotherModuleInputSourceSelectionContent,
};

interface Props {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
}

const ItemDetailsInputSourceSelection: React.FC<Props> = ({ workflowVersionId }) => {
    const $stateParams = useAngularService('$stateParams');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const workflowVersionDataSourceManager = useAngularService('workflowVersionDataSourceManager');
    const workflowVersion: WorkflowVersion = workflowVersionManager.getCachedWorkflowVersion(workflowVersionId)!;
    const dataSourceType = workflowVersion.dataSourceType;
    const isScheduled = workflowVersion.isScheduled;
    const isFromAnotherModule = workflowVersion.isFromAnotherModule;

    const [selectedInputSourceType, setSelectedInputSourceType] = useState(WorkflowVersionInputSourceType.MANUAL);
    useEffect(() => {
        let inputSourceType;
        // If we have this param in the url, we want go to the connection page of this project integration
        if ($stateParams.projectIntegration || $stateParams.syncProjectIntegrationId) {
            inputSourceType = WorkflowVersionInputSourceType.DATA_SOURCE;
        } else if (dataSourceType === DataSourceType.INTEGRATION) {
            inputSourceType = WorkflowVersionInputSourceType.DATA_SOURCE;
        } else if (dataSourceType === DataSourceType.FORMS) {
            inputSourceType = WorkflowVersionInputSourceType.FORMS;
        } else if (isScheduled) {
            inputSourceType = WorkflowVersionInputSourceType.SCHEDULED;
        } else if (isFromAnotherModule) {
            inputSourceType = WorkflowVersionInputSourceType.ANOTHER_MODULE;
        } else {
            inputSourceType = WorkflowVersionInputSourceType.MANUAL;
        }

        setSelectedInputSourceType(inputSourceType);
    }, [
        $stateParams.projectIntegration,
        $stateParams.syncProjectIntegrationId,
        dataSourceType,
        isFromAnotherModule,
        isScheduled,
    ]);

    const syncConfigManager = useAngularService('syncConfigManager');
    const onTabChanged = (value: WorkflowVersionInputSourceType) => {
        setSelectedInputSourceType(value);
        // Remove\update previous state (clean up) before updating to desired state
        let promise: Promise<any> = Promise.resolve();
        if (selectedInputSourceType === WorkflowVersionInputSourceType.SCHEDULED) {
            promise = workflowVersionDataSourceManager.updateWorkflowVersionIsScheduled(workflowVersion.groupId, false);
        } else if (selectedInputSourceType === WorkflowVersionInputSourceType.ANOTHER_MODULE) {
            promise = workflowVersionDataSourceManager.updateWorkflowVersionIsFromAnotherModule(
                workflowVersion.groupId,
                false,
            );
        } else if (
            selectedInputSourceType === WorkflowVersionInputSourceType.DATA_SOURCE &&
            workflowVersion.syncConfig
        ) {
            promise = syncConfigManager.removeGroupSyncConfig(workflowVersion.groupId, workflowVersion.syncConfig.id);
        }

        // After clean up update to desire state
        promise.then(() => {
            switch (value) {
                case WorkflowVersionInputSourceType.MANUAL:
                case WorkflowVersionInputSourceType.FORMS: {
                    const dataSourceType =
                        value === WorkflowVersionInputSourceType.MANUAL ? DataSourceType.MANUAL : DataSourceType.FORMS;
                    workflowVersionDataSourceManager.updateWorkflowVersionDataSourceType(
                        workflowVersion.groupId,
                        dataSourceType,
                        false,
                    );

                    break;
                }
                case WorkflowVersionInputSourceType.SCHEDULED: {
                    workflowVersionDataSourceManager.updateWorkflowVersionIsScheduled(workflowVersion.groupId, true);

                    break;
                }
                case WorkflowVersionInputSourceType.ANOTHER_MODULE: {
                    workflowVersionDataSourceManager.updateWorkflowVersionIsFromAnotherModule(
                        workflowVersion.groupId,
                        true,
                    );

                    break;
                }
                // No default
            }
            // if is datasource we need to create sync config but cant because we dont have the details yet, let content comp do that
        });
    };

    const SelectedContentComponent = workflowVersionInputSourceTypeToContentComponent[selectedInputSourceType];

    return <SelectedContentComponent workflowVersion={workflowVersion} setSelectedInputSourceType={onTabChanged} />;
};

export default ItemDetailsInputSourceSelection;
