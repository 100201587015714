import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function NextStepsLogicConfigurationCtrl(
    $scope,
    communicationIntegrationsService,
    authenticationService,
    tonkeanUtils,
    projectManager,
    workflowVersionManager,
    syncConfigCacheManager,
) {
    const ctrl = this;

    $scope.cis = communicationIntegrationsService;
    $scope.as = authenticationService;
    $scope.pm = projectManager;
    $scope.tonkeanUtils = tonkeanUtils;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        invalidLogics: ctrl.invalidLogics,
        previewEvaluationSource: ctrl.previewEvaluationSource,

        customTriggerAction: null,
        customText: '',
        evaluatedCustomText: '',
        evaluatedPostText: '{{TNK_TITLE}}',

        emailSubject: '',
        evaluatedEmailSubject: '',
        sendAsPlainText: true,

        noPreviousActorOption: false,
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;

            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }

        if (changesObj.previewEvaluationSource) {
            $scope.data.previewEvaluationSource = changesObj.previewEvaluationSource.currentValue;
        }
    };

    /**
     * Occurs once the person selection configuration is changed.
     */
    $scope.onPersonSelectionConfigurationChanged = function (personSelectionConfiguration, shouldSaveLogic) {
        $scope.data.personSelectionConfiguration = personSelectionConfiguration;
        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once the actions object is changed.
     */
    $scope.onActionsChanged = function (shouldSaveLogic) {
        if (ctrl.onActionsChanged) {
            const actionDefinition = {
                fieldDefinitionsToCreate: [],
                actions: [],
            };
            tonkeanUtils.enrichPersonActionDefinition(
                'NEXT_STEPS',
                actionDefinition,
                $scope.data.personSelectionConfiguration,
                $scope.data.customText,
                $scope.data.evaluatedCustomText,
            );

            // Since we rely on enrichPersonActionDefinition to create our action object,
            // and it takes some digest cycles to get the person selection component to initialize
            // our selection object, we check for existence of the definition.

            const customTriggerAction = getFirstCustomTriggerAction(
                actionDefinition.actions,
                'NEXT_STEPS',
            );
            if (customTriggerAction && customTriggerAction.definition) {
                // Set the from property.
                const definition = customTriggerAction.definition;
                definition.emailSubject = $scope.data.emailSubject;
                definition.evaluatedEmailSubject = $scope.data.evaluatedEmailSubject;
                definition.sendAsPlainText = $scope.data.sendAsPlainText;
            }
            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Occurs once Slack Message text or Email body expression has changed.
     */
    $scope.onTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.customText = originalExpression;
        $scope.data.evaluatedCustomText = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once email subject expression has changed.
     */
    $scope.onTonkeanEmailSubjectExpressionChanged = function (
        originalExpression,
        evaluatedExpression,
        shouldSaveLogic,
    ) {
        $scope.data.emailSubject = originalExpression;
        $scope.data.evaluatedEmailSubject = evaluatedExpression;

        $scope.onActionsChanged(shouldSaveLogic);
    };

    /**
     * Occurs once send as plain text toggle has changed.
     */
    $scope.onSendAsPlainTextChanged = function (shouldSendAsPlainText) {
        $scope.data.sendAsPlainText = shouldSendAsPlainText;
        $scope.onActionsChanged(true);
    };

    /**
     * Initializes the edit mode of the component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'NEXT_STEPS',
        );

        if (customTriggerAction) {
            $scope.data.customTriggerAction = customTriggerAction;
            // The slack message / email subject.
            $scope.data.customText = customTriggerAction.customTriggerActionDefinition.text;
            $scope.data.evaluatedCustomText = customTriggerAction.customTriggerActionDefinition.evaluatedText;

            // This section is 'new', Thus, we need to support backwards. In order to do that, we set default values for the following properties.
            $scope.data.emailSubject =
                customTriggerAction.customTriggerActionDefinition.emailSubject || $scope.data.customText;
            $scope.data.evaluatedEmailSubject =
                customTriggerAction.customTriggerActionDefinition.evaluatedEmailSubject ||
                $scope.data.evaluatedCustomText;
            $scope.data.sendAsPlainText =
                customTriggerAction.customTriggerActionDefinition.sendAsPlainText === undefined
                    ? true
                    : customTriggerAction.customTriggerActionDefinition.sendAsPlainText;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('NextStepsLogicConfigurationCtrl', lateConstructController(NextStepsLogicConfigurationCtrl));
