import React from 'react';

import ContractFieldsMapping, { type ContractFieldsMappingProps } from './ContractFieldsMapping';
import ContractInterfacesSelector from './ContractInterfacesSelector';

import type { ItemInterface } from '@tonkean/tonkean-entities';

const ContractItemInterfacesMapping: React.FC<Omit<ContractFieldsMappingProps<ItemInterface>, 'selectorComponent'>> = (
    props,
) => {
    return <ContractFieldsMapping selectorComponent={ContractInterfacesSelector} {...props} />;
};

export default ContractItemInterfacesMapping;
