function LoginSentCtrl($scope, $localStorage, tonkeanService) {
    $scope.workEmail = $localStorage.LastLoginWithEmailAddress;
    $scope.data = {};

    $scope.resend = function () {
        if ($scope.workEmail && !$scope.loadingEmail) {
            $scope.loadingEmail = true;
            tonkeanService
                .sendLoginEmail($scope.workEmail)
                .then(function () {
                    $scope.$emit('alert', { type: 'success', msg: 'Email confirmation re-sent successfully' });
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.loadingEmail = false;
                });
        }
    };
}

export default angular.module('tonkean.app').controller('LoginSentCtrl', LoginSentCtrl);
