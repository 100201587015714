import React from 'react';

import SingleWorkerRunActionInspectJson from './SingleWorkerRunActionInspectJson';
import type { TabComponentProps } from '../../../entities/WorkerRunLogicInspectTabData';

import { WorkerRunLogicInspectTabType } from '@tonkean/tonkean-entities';

const SingleWorkerRunActionInspectResponseJson: React.FC<
    TabComponentProps<WorkerRunLogicInspectTabType.RESPONSE_JSON>
> = ({ responseJson }) => {
    return (
        <SingleWorkerRunActionInspectJson
            object={responseJson}
            inspectTabType={WorkerRunLogicInspectTabType.RESPONSE_JSON}
        />
    );
};

export default SingleWorkerRunActionInspectResponseJson;
