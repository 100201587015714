import React from 'react';
import styled from 'styled-components';

import { Chevron, ChevronDirection, Placeholder } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const Item = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 13px 10px 13px 15px;
    border-bottom: 1px solid ${Theme.colors.gray_300};
    height: 61px;
`;

const GroupPlaceholder = styled(Placeholder)`
    margin-left: 12px;
`;

const ContentPlaceholder = styled(Placeholder)`
    margin-left: 8px;
    display: flex;
    flex-direction: column;
`;

const StatusIndicator = styled.div`
    position: absolute;
    width: 2px;
    left: 0;
    top: 3px;
    bottom: 3px;
    border-radius: 2px;
    background: ${Theme.colors.gray_300};
`;

const AuditLogGroupPlaceholder: React.FC = () => {
    return (
        <Item>
            <StatusIndicator />
            <Chevron direction={ChevronDirection.RIGHT} />
            <GroupPlaceholder $width="76px" $height="16px" $borderRadius="100px" />
            <ContentPlaceholder $width="400px" $height="14px" />
        </Item>
    );
};

export default AuditLogGroupPlaceholder;
