import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import SharePointAuthenticationType from './SharePointAuthenticationType';
import SharePointPermissionLevel from './SharePointPermissionLevel';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { useFeatureFlag, useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Checkbox } from '@tonkean/infrastructure';
import { TnkSelect } from '@tonkean/infrastructure';

export interface SharePointProjectData {
    url: string | undefined;
    siteId: string | undefined;
    documentLibraryId: string | undefined;
    folderId: string | undefined;
    shouldCollectLists: boolean;
    authType: string | undefined;
    permissionLevel: string | undefined;
    siteName: string | undefined;
    documentLibraryName: string | undefined;
    folderName: string | undefined;
    webUrl: string | undefined;
    selectedLists: Object[];
    shouldIndexContent: boolean;
}

interface Object {
    label: string | undefined;
    value: string | undefined;
}

const CenteredDiv = styled.div`
    text-align: center;
    margin-bottom: 10px;
`;

const SharePointCustomizedSetupComponent: React.FC<CustomizedSetupComponentProps<SharePointProjectData>> = ({
    integration,
    createProjectApis,
    onChangeOrInitIntegration,
    projectIntegration,
    projectIntegrationData,
}) => {
    const projectManager = useAngularService('projectManager');
    const [{ loading }, callExecuteIntegrationCommand] = useLazyTonkeanService('executeIntegrationCommand');

    // URL Verification
    const [showUrlField, setShowUrlField] = useState(false);
    const [selectedUrl, setSelectedUrl] = useState<string | undefined>();
    const [verifiedProjectData, setVerifiedProjectData] = useState<SharePointProjectData | undefined>();

    // Site Selection
    const [isSelectedSitesApp, setIsSelectedSitesApp] = useState<boolean>(false);
    const [sitesList, setSitesList] = useState([]);
    const [librariesList, setLibraryList] = useState([]);
    const [foldersList, setFolderList] = useState([]);
    const [siteQuery, setSiteQuery] = useState<string>('');
    const [folderQuery, setFolderQuery] = useState<string>('');
    const [selectedSite, setSelectedSite] = useState<Object | undefined>();
    const [selectedLibrary, setSelectedLibrary] = useState<Object | undefined>();
    const [selectedFolder, setSelectedFolder] = useState<Object | undefined>();
    const [isLoadingSites, setIsLoadingSites] = useState(false);
    const [isLoadingLibraries, setIsLoadingLibraries] = useState(false);
    const [isLoadingFolders, setIsLoadingFolders] = useState(false);
    const [listQuery, setListQuery] = useState<string>('');
    const [isLoadingLists, setIsLoadingLists] = useState(false);
    const [listsList, setListsList] = useState([]);
    const [selectedLists, setSelectedLists] = useState<Object[]>([]);
    const [isModified, setIsModified] = useState(false);

    // SharePoint Lists
    const [shouldCollectLists, setCollectLists] = useState<boolean>(false);

    // Indexing file content
    const smartSearchFlagEnabled = useFeatureFlag('tonkean_feature_smartsearch');
    const shouldAllowIndexContentFeatureEnabled = useFeatureFlag(
        'tonkean_feature_sharepoint_should_allow_index_files_content',
    );
    const [shouldIndexContent, setShouldIndexContent] = useState<boolean>(false);

    // Error
    const [error, setError] = useState<string | undefined>();

    // Predefined Site ID
    const predefinedSiteId = projectManager.projectData.sharepointPredefinedSiteId;

    const onSiteSelected = useCallback(
        async (site) => {
            setSelectedSite(site);
            setSelectedLibrary(undefined);
            setIsLoadingLibraries(true);
            setError(undefined);
            try {
                const libraries = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'documentLibraries',
                    { siteId: site.value },
                );

                if (libraries.options.length < 1) {
                    const errorMessage = `Tonkean does not have permissions to access your site. Please refer to the documentation on how to provide the right permissions.`;
                    setLibraryList([]);
                    setError(errorMessage);
                } else {
                    const autoCompleteOptions = constructAutoCompleteOptions(libraries.options);
                    setLibraryList(autoCompleteOptions);
                }
            } catch (error_) {
                const errorMessage = (error_ as IHttpResponse<any>)?.data?.data?.error?.cause?.message;
                setError(`Error occurred while getting the Libraries list. ${errorMessage || ''}`);
            } finally {
                setIsLoadingLibraries(false);
            }
        },
        [createProjectApis, integration.id, projectManager.project.id],
    );

    // Getting the exiting url from the projectData
    useEffect(() => {
        const init = () => {
            if (isModified) {
                return;
            }

            if (projectIntegrationData?.url) {
                setSelectedUrl(projectIntegrationData.url);
                setShowUrlField(true);
            }

            if (projectIntegrationData) {
                setCollectLists(projectIntegrationData.shouldCollectLists);
                setShouldIndexContent(projectIntegrationData.shouldIndexContent);
                setSelectedLists(projectIntegrationData.selectedLists);
                setSelectedSite({
                    value: projectIntegrationData?.siteId,
                    label: projectIntegrationData?.siteName,
                });
                setSelectedLibrary({
                    value: projectIntegrationData?.documentLibraryId,
                    label: projectIntegrationData?.documentLibraryName,
                });
                setSelectedFolder({
                    value: projectIntegrationData?.folderId,
                    label: projectIntegrationData?.folderName,
                });
                setVerifiedProjectData(projectIntegrationData);
            }

            if (projectIntegrationData?.permissionLevel) {
                setIsSelectedSitesApp(
                    projectIntegrationData.permissionLevel === SharePointPermissionLevel.SELECTED_SITES,
                );
            } else {
                callExecuteIntegrationCommand(
                    projectManager.project.id,
                    integration.id,
                    'GET_SHAREPOINT_APP_PERMISSION',
                    {},
                    projectIntegration?.id,
                ).then((data) => {
                    if (verifiedProjectData && data.projectData.permissionLevel) {
                        verifiedProjectData.permissionLevel = data.projectData.permissionLevel;
                    }
                    setIsSelectedSitesApp(
                        data.projectData?.permissionLevel === SharePointPermissionLevel.SELECTED_SITES,
                    );

                    if (
                        predefinedSiteId &&
                        predefinedSiteId !== '' &&
                        data.projectData?.permissionLevel === SharePointPermissionLevel.SELECTED_SITES
                    ) {
                        onSiteSelected({ value: predefinedSiteId, label: '' });
                    }
                });
            }
        };

        init();
    }, [
        callExecuteIntegrationCommand,
        integration.id,
        isModified,
        onSiteSelected,
        predefinedSiteId,
        projectIntegration?.id,
        projectIntegrationData,
        projectManager.project.id,
        verifiedProjectData,
    ]);

    // Verify URL
    const verifyUrl = () => {
        if (!selectedUrl) {
            return;
        }

        setError(undefined);

        callExecuteIntegrationCommand(
            projectManager.project.id,
            integration.id,
            'VERIFY_SHAREPOINT_URL',
            { url: selectedUrl, siteId: selectedSite?.value },
            projectIntegration?.id,
        )
            .then((data) => {
                setVerifiedProjectData(data.projectData);
                setSelectedSite({ value: data.projectData?.siteId, label: data.projectData?.siteName });
                setSelectedLibrary({
                    value: data.projectData?.documentLibraryId,
                    label: data.projectData?.documentLibraryName,
                });
                setSelectedFolder({ value: data.projectData?.folderId, label: data.projectData?.folderName });
            })
            .catch((error) => {
                let errorMessage =
                    error?.data?.data?.error?.cause?.message || 'An error occurred while verifying the url.';
                if (
                    (errorMessage.startsWith('403') || errorMessage.startsWith('Error: ')) &&
                    verifiedProjectData?.authType === SharePointAuthenticationType.CLIENT_CREDENTIALS
                ) {
                    errorMessage = `Tonkean does not have permissions to access your site. Please refer to the documentation on how to provide the right permissions.`;
                }
                setError(errorMessage);
            });
    };

    useEffect(() => {
        if (!verifiedProjectData) {
            return;
        }
        const projectIntegrationDisplayName = `SharePoint Online (${selectedFolder?.label || selectedLibrary?.label})`;
        verifiedProjectData.shouldCollectLists = shouldCollectLists;
        verifiedProjectData.shouldIndexContent = shouldIndexContent;
        verifiedProjectData.selectedLists = selectedLists;

        const disabled = !verifiedProjectData || !selectedLibrary?.value;

        onChangeOrInitIntegration(
            {
                projectIntegrationData: verifiedProjectData,
                projectIntegrationDisplayName: projectIntegration?.displayName || projectIntegrationDisplayName,
            },
            disabled,
        );
    }, [
        onChangeOrInitIntegration,
        projectIntegration?.displayName,
        selectedFolder?.label,
        selectedLibrary,
        selectedLists,
        shouldCollectLists,
        shouldIndexContent,
        verifiedProjectData,
    ]);

    const setProjectDataFromUserSelection = useCallback(
        (library, folder) => {
            if (!selectedSite) {
                setVerifiedProjectData(undefined);
                return;
            }
            setIsModified(true);
            setVerifiedProjectData({
                url: undefined,
                webUrl: folder?.description || library.description,
                siteId: selectedSite.value,
                documentLibraryId: library.value,
                folderId: folder?.value || undefined,
                shouldCollectLists,
                authType: verifiedProjectData?.authType,
                permissionLevel: verifiedProjectData?.permissionLevel,
                siteName: selectedSite.label,
                documentLibraryName: library.label,
                folderName: folder?.label || undefined,
                selectedLists,
                shouldIndexContent,
            });
        },
        [
            selectedLists,
            selectedSite,
            shouldCollectLists,
            shouldIndexContent,
            verifiedProjectData?.authType,
            verifiedProjectData?.permissionLevel,
        ],
    );

    // Get Sites list.
    useEffect(() => {
        const getSites = async () => {
            if (siteQuery.length > 0 && siteQuery.length < 3) {
                setIsLoadingSites(false);
                return;
            }
            setError(undefined);
            setIsLoadingSites(true);
            try {
                const sites = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'sites',
                    { query: siteQuery },
                );
                const autoCompleteOptions = constructAutoCompleteOptions(sites.options);
                setSitesList(autoCompleteOptions);
            } catch (error_) {
                const errorMessage =
                    (error_ as IHttpResponse<any>)?.data?.data?.error?.cause?.message ||
                    'Error occurred while getting the Sites list';
                setError(errorMessage);
            } finally {
                setIsLoadingSites(false);
            }
        };
        if (verifiedProjectData && verifiedProjectData?.permissionLevel !== SharePointPermissionLevel.SELECTED_SITES) {
            getSites();
        }
    }, [createProjectApis, integration.id, projectManager.project.id, siteQuery, verifiedProjectData]);

    const onLibrarySelected = useCallback(
        (library) => {
            setSelectedLibrary(library);
            setProjectDataFromUserSelection(library, null);
        },
        [setProjectDataFromUserSelection],
    );

    useEffect(() => {
        const getFolders = async () => {
            if (!selectedSite || !selectedLibrary || folderQuery.length < 3) {
                return;
            }
            setIsLoadingFolders(true);
            setError(undefined);
            try {
                const folders = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'folders',
                    { siteId: selectedSite.value, documentLibraryId: selectedLibrary.value, query: folderQuery },
                );

                const autoCompleteOptions = constructAutoCompleteOptions(folders.options);
                setFolderList(autoCompleteOptions);
            } catch {
                setError('Error occurred while getting Folders list.');
            } finally {
                setIsLoadingFolders(false);
            }
        };
        if (verifiedProjectData && verifiedProjectData?.permissionLevel !== SharePointPermissionLevel.SELECTED_SITES) {
            getFolders();
        }
    }, [
        createProjectApis,
        folderQuery,
        integration,
        projectManager.project.id,
        selectedLibrary,
        selectedSite,
        siteQuery,
        verifiedProjectData,
    ]);

    const onFolderSelected = useCallback(
        (folder) => {
            setSelectedFolder(folder);
            setProjectDataFromUserSelection(selectedLibrary, folder);
        },
        [selectedLibrary, setProjectDataFromUserSelection],
    );

    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: option.displayName,
            description: option.description,
        }));
    };

    useEffect(() => {
        const getLists = async () => {
            if (!selectedSite || !shouldCollectLists) {
                return;
            }
            setIsLoadingLists(true);
            setError(undefined);
            try {
                const lists = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'lists',
                    { siteId: selectedSite.value, query: listQuery },
                );

                const autoCompleteOptions = constructAutoCompleteOptions(lists.options);
                setListsList(autoCompleteOptions);
            } catch {
                setError('Error occurred while getting Lists.');
            } finally {
                setIsLoadingLists(false);
            }
        };
        if (shouldCollectLists && selectedSite) {
            getLists();
        }
    }, [createProjectApis, integration.id, listQuery, projectManager.project.id, selectedSite, shouldCollectLists]);

    const onSiteIdEntered = useCallback(
        async (siteId) => {
            await onSiteSelected({ value: siteId, label: '' });
        },
        [onSiteSelected],
    );

    const onListSelected = useCallback((listsList) => {
        setSelectedLists(listsList);
    }, []);

    return (
        <div>
            <div className="margin-bottom-md">
                {error && <div className="alert alert-danger margin-bottom">{error}.</div>}
            </div>

            <div className="flex mod-center common-subtitle-inner common-size-xxs margin-bottom-md">
                <i className="fa fa-info-circle margin-right-xs margin-top-xxxs" />
                <span>
                    For setup documentation{' '}
                    <a
                        href="https://docs.tonkean.com/en/data-sources/connect-native-data-sources/common-data-sources/sharepoint.html"
                        target="_blank"
                    >
                        click here
                    </a>
                </span>
            </div>

            {!isSelectedSitesApp && (
                <div className="flex flex-col">
                    <div className="form-group">
                        <label className="control-label col-md-3 text-right margin-top-6">Select a Site:</label>
                        <div className="col-sm-8">
                            <TnkSelect
                                placeholder="Search Sites..."
                                isMulti={false}
                                onInputChange={setSiteQuery}
                                isCreatable={false}
                                isClearable={false}
                                options={sitesList}
                                onChange={onSiteSelected}
                                value={selectedSite}
                                isDisabled={sitesList.length === 0 && !!error}
                                isSearchable
                                closeMenuOnSelect
                            />
                        </div>
                        <div className="margin-top-6">{isLoadingSites && <i className="loading-small" />}</div>
                    </div>
                </div>
            )}

            {isSelectedSitesApp && (
                <div className="flex flex-col">
                    <div className="form-group">
                        <label className="control-label col-md-3 text-right margin-top-6">Enter Site ID:</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={selectedSite?.value}
                                className="form-control"
                                onChange={({ target }) => onSiteIdEntered(target.value)}
                                autoComplete="off"
                                placeholder="Site ID"
                            />
                            <span className="common-size-xxxs common-color-grey">
                                Site ID Format:{' '}
                                <span className="common-italic common-bold">[hostname],[spsite-id],[spweb-id]</span>
                            </span>
                        </div>
                    </div>
                </div>
            )}

            <div className="flex flex-col">
                <div className="form-group">
                    <label className="control-label col-md-3 text-right margin-top-6">Document Library:</label>
                    <div className="col-sm-8">
                        <TnkSelect
                            placeholder="Select Library"
                            isMulti={false}
                            isCreatable={false}
                            isClearable={false}
                            options={librariesList}
                            onChange={onLibrarySelected}
                            value={selectedLibrary}
                            isDisabled={!selectedSite}
                            isSearchable
                            closeMenuOnSelect
                        />
                    </div>
                    <div className="margin-top-6">
                        {isLoadingLibraries && <i className="loading-small margin-left-md" />}
                    </div>
                </div>
            </div>

            <div className="flex flex-col">
                <div className="form-group">
                    <label className="control-label col-md-3 text-right margin-top-6">
                        Folder{!isSelectedSitesApp && ' (Optional)'}:
                    </label>
                    <div className="col-sm-8">
                        <TnkSelect
                            placeholder="Start typing the folder's name"
                            isMulti={false}
                            onInputChange={setFolderQuery}
                            isCreatable={false}
                            isClearable={false}
                            options={foldersList}
                            onChange={onFolderSelected}
                            value={selectedFolder}
                            isDisabled={!selectedSite || !selectedLibrary || isSelectedSitesApp}
                            isSearchable
                            closeMenuOnSelect
                        />
                        {isSelectedSitesApp && (
                            <span className="common-size-xxxs common-color-grey">
                                Not available for search in Sites.Selected App
                            </span>
                        )}
                    </div>
                    <div className="margin-top-6">
                        {isLoadingFolders && <i className="loading-small margin-left-md" />}
                    </div>
                </div>
            </div>

            <div className="margin-bottom-lg info-box common-size-xxs margin-top-md">
                <CenteredDiv className="common-color-black">
                    <a onClick={() => setShowUrlField(!showUrlField)}>Or provide the site's target URL:</a>
                </CenteredDiv>
                {showUrlField && (
                    <div>
                        <p className="common-color-dark-grey">SharePoint Url:</p>
                        {isSelectedSitesApp && (
                            <div>
                                <ul className="common-ul-small-bullets mod-grey padding-left-md">
                                    <li>
                                        Please enter in the Site ID field the full Site ID your admin provided access
                                        to.
                                    </li>
                                    <li>
                                        Then navigate to the folder you would like Tonkean to collect items from and get
                                        the URL.
                                    </li>
                                </ul>
                            </div>
                        )}

                        {!isSelectedSitesApp && (
                            <div>
                                Please navigate to the folder you would like Tonkean to collect items from and get the
                                URL.
                            </div>
                        )}

                        <div>
                            For example:
                            <br />
                            <span className="common-bold">
                                https://Company.sharepoint.com/sites/SiteName/FolderName/Forms/AllItems.aspx...
                            </span>
                        </div>

                        <div className="flex flex-col">
                            <div className="form-group flex-inline margin-bottom-none margin-top-md">
                                <div className="col-sm-10">
                                    <input
                                        type="text"
                                        value={selectedUrl}
                                        className="form-control"
                                        onChange={({ target }) => setSelectedUrl(target.value)}
                                        autoComplete="off"
                                        disabled={
                                            loading ||
                                            !!projectIntegrationData?.url ||
                                            (isSelectedSitesApp && !selectedSite)
                                        }
                                        placeholder="SharePoint site's target URL"
                                    />
                                </div>

                                <button
                                    type="button"
                                    disabled={
                                        loading ||
                                        !selectedUrl ||
                                        selectedUrl === verifiedProjectData?.url ||
                                        !!projectIntegrationData?.url ||
                                        (isSelectedSitesApp && !selectedSite)
                                    }
                                    className="btn btn-primary"
                                    onClick={verifyUrl}
                                >
                                    {loading && <span className="loading" />}
                                    {!loading &&
                                        ((selectedUrl === verifiedProjectData?.url && !!selectedUrl) ||
                                        !!projectIntegrationData?.url
                                            ? 'Verified Url'
                                            : 'Verify Url')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {smartSearchFlagEnabled && shouldAllowIndexContentFeatureEnabled && (
                <CenteredDiv>
                    <Checkbox checked={shouldIndexContent} onChange={() => setShouldIndexContent((value) => !value)}>
                        Include File Content
                    </Checkbox>
                </CenteredDiv>
            )}

            <CenteredDiv>
                <Checkbox checked={shouldCollectLists} onChange={() => setCollectLists(!shouldCollectLists)}>
                    Should Collect SharePoint Lists
                </Checkbox>
            </CenteredDiv>

            {shouldCollectLists && (
                <div>
                    <div>
                        <label>Site Lists</label>
                        <div>
                            <TnkSelect
                                onInputChange={setListQuery}
                                isCreatable={false}
                                isClearable={false}
                                options={listsList}
                                onChange={onListSelected}
                                value={selectedLists}
                                isDisabled={!selectedSite}
                                isLoading={isLoadingLists}
                                isMulti
                                isSearchable
                                closeMenuOnSelect
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SharePointCustomizedSetupComponent;
