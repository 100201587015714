import React from 'react';

import type RichTextEditorInputWidgetConfiguration from './RichTextEditorInputWidgetConfiguration';
import RichTextEditorInputWidgetSelector from './RichTextEditorInputWidgetSelector';
import type { ItemWidgetEditorProps } from '../../WidgetModule';

const RichTextEditorInputConfigurationEditor: React.FC<
    ItemWidgetEditorProps<RichTextEditorInputWidgetConfiguration>
> = ({ workflowVersion, configuration }) => {
    return <RichTextEditorInputWidgetSelector configuration={configuration} workflowVersion={workflowVersion} />;
};

export default RichTextEditorInputConfigurationEditor;
