import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import SmartSearchLookupEntityBoxView from './getInformationViews/SmartSearchLookupEntityBoxView';
import SmartSearchActionButton from './SmartSearchActionButton';

import { AITypewriter } from '@tonkean/ai-builder';
import { AppearFromTopAnimation, IconSvg, ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { SmartConversationResponseText } from '@tonkean/smart-conversation';
import { SmartSearchIcon } from '@tonkean/svg';
import type { SmartSearchResponse } from '@tonkean/tonkean-entities';
import {
    type SmartConversationMessage,
    type SmartSearchAssistantCategoryResponse,
    type SmartSearchSuggestAction,
    type TonkeanId,
    TonkeanType,
    tonkeanTypeToIdentifyingPrefixMap,
} from '@tonkean/tonkean-entities';

const ResponseContainer = styled.div`
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'gutter text';
    column-gap: 10px;
`;

const ResponseTextContainer = styled.div`
    grid-area: text;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ResponseIcon = styled(IconSvg)`
    grid-area: gutter;
    margin-top: 4px;
`;

const ResponseButtonsContainer = styled(AppearFromTopAnimation)`
    grid-area: buttons;
`;

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    message: SmartConversationMessage<SmartSearchAssistantCategoryResponse>;
    onAnimationEnd: () => void;
    allMessages: SmartConversationMessage<SmartSearchResponse>[];
}

const SmartSearchAssistantResponseCategoryView: React.FC<Props> = ({
    projectId,
    message,
    onAnimationEnd,
    allMessages,
}) => {
    const [isInitialTextEnded, setIsInitialTextEnded] = useState(false);

    const messageAnswerText = message.response?.answerText;

    const { suggestedAction, suggestedEntity, answerText } = useMemo(() => {
        let messageData;
        try {
            messageData = JSON.parse(messageAnswerText);
            if (messageData?.['answer_text']) {
                if (messageData.action?.id) {
                    const data = messageData.action;
                    const actionFields = Object.entries<string>(data.arguments).map(([key, value]) => ({
                        id: key,
                        value,
                    }));

                    let tonkeanType = TonkeanType.FORM;
                    if (data.id.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.CUSTOM_TRIGGER])) {
                        tonkeanType = TonkeanType.CUSTOM_TRIGGER;
                    }

                    const suggestedActionObj: SmartSearchSuggestAction = {
                        foundAction: true,
                        actionId: data.id,
                        actionName: data.name,
                        actionFields,
                        answerText: messageData['answer_text'],
                        tonkeanType,
                    };

                    // if an action, only send the action
                    return {
                        suggestedAction: suggestedActionObj,
                        suggestedEntity: undefined,
                        answerText: messageData['answer_text'],
                    };
                } else if (messageData.entity?.id) {
                    let useCitation = true;

                    if (messageData.entity.entityType === 'DATA_SOURCE') {
                        useCitation = false;
                    } else {
                        // if an entity exist, check if we already cited it
                        allMessages.forEach((item) => {
                            if (
                                item.messageId !== message.messageId &&
                                item.response?.['answerText']?.includes(messageData.entity.id)
                            ) {
                                useCitation = false;
                            }
                        });
                    }

                    if (useCitation) {
                        return {
                            suggestedAction: undefined,
                            suggestedEntity: messageData.entity,
                            answerText: messageData['answer_text'],
                        };
                    }
                }

                // valid json but no action or entity
                return {
                    suggestedAction: undefined,
                    suggestedEntity: undefined,
                    answerText: messageData['answer_text'],
                };
            }
        } catch (error) {
            console.warn('Invalid JSON string', error);
        }
        // not a json, return as is
        return { suggestedAction: undefined, suggestedEntity: undefined, answerText: messageAnswerText };
    }, [allMessages, message.messageId, messageAnswerText]);

    return (
        <ResponseContainer>
            <ResponseIcon as={SmartSearchIcon} />
            <ResponseTextContainer>
                <SmartConversationResponseText>
                    <AITypewriter
                        skipAnimation={!message.showAnimation}
                        text={answerText}
                        onTypingDone={(isDone) => {
                            if (isDone) {
                                onAnimationEnd();
                                setIsInitialTextEnded(true);
                            }
                        }}
                    />
                </SmartConversationResponseText>

                {suggestedAction && isInitialTextEnded && (
                    <ResponseButtonsContainer skipAnimation={!message.showAnimation} onAnimationEnd={onAnimationEnd}>
                        <SmartSearchActionButton
                            projectId={projectId}
                            action={suggestedAction}
                            conversationId={message.messageId}
                        />
                    </ResponseButtonsContainer>
                )}
                {suggestedEntity && isInitialTextEnded && (
                    <SmartSearchLookupEntityBoxView
                        externalLink={suggestedEntity.url}
                        icon={
                            <ProjectIntegrationIcon
                                width={18}
                                projectIntegrationId={suggestedEntity.projectIntegrationId}
                                integrationType={suggestedEntity.integrationType || ''}
                            />
                        }
                        title={suggestedEntity.name}
                        subTitle={suggestedEntity.entityType}
                        isAnalyzing={false}
                        isReAnalyzeEnabled={false}
                        onClickAnalyze={() => {
                            return;
                        }}
                    />
                )}
            </ResponseTextContainer>
        </ResponseContainer>
    );
};

export default SmartSearchAssistantResponseCategoryView;
