import React from 'react';
import styled, { css } from 'styled-components';

import MarketplaceGallery from './components/MarketplaceGallery';

const marginTop = css`
    margin-top: 30px;
`;

const Wrapper = styled.main`
    ${marginTop}
`;

interface Props {}

const SharedTemplatesPage: React.FC<Props> = () => {
    return (
        <Wrapper>
            <MarketplaceGallery />
        </Wrapper>
    );
};

export default SharedTemplatesPage;
