import React from 'react';
import styled from 'styled-components';

import { SOLUTION_SITE_INTERFACE_MENU_WIDTH } from './SolutionSiteSidePane';

import { Placeholder, Spacer } from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';

const Container = styled.div`
    height: 100%;
    grid-area: solution-site-sidepane;
    background: ${Theme.colors.white};
    border-right: 1px solid ${Theme.colors.gray_200};
    display: flex;
    flex-direction: column;
    width: ${SOLUTION_SITE_INTERFACE_MENU_WIDTH}px;
    align-items: start;
`;

const TitlePlaceholderContainer = styled.div`
    width: 100%;
    padding: 0 16px;
`;

const PagesPlaceholderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    padding: 0 8px;
`;
interface Props {}

const SolutionSiteSidePaneLoading: React.FC<Props> = ({}) => {
    return (
        <Container>
            <Spacer height={24} />
            <TitlePlaceholderContainer>
                <Placeholder $width="65%" $height="36px" />
            </TitlePlaceholderContainer>

            <Spacer height={24} />
            <PagesPlaceholderContainer>
                <Placeholder $width="100%" $height="40px" />
                <Placeholder $width="100%" $height="40px" />
                <Placeholder $width="100%" $height="40px" />
            </PagesPlaceholderContainer>
        </Container>
    );
};

export default SolutionSiteSidePaneLoading;
