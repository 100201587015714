import uiSelect from 'ui-select';
import angularSanitize from 'angular-sanitize';
import 'ngstorage';
import 'ng-tags-input';

const shared = angular.module('tonkean.shared', [uiSelect, 'ngTagsInput', angularSanitize, 'ngStorage']);

function sharedConfig(tagsInputConfigProvider) {
    tagsInputConfigProvider.setDefaults('tagsInput', {
        minLength: 1,
        addOnBlur: false,
        addOnEnter: false,
        addFromAutocompleteOnly: true,
    });
}
shared.config(sharedConfig);
