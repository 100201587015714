<div class="margin-left margin-right" ng-if="data.hasAdminRights && !data.loading">
    <div class="margin-right">Action Performer:</div>

    <form ng-submit="" novalidate class="margin-top-xxs">
        <tnk-person-selection-configuration
            class="block margin-bottom-lg"
            group-id="data.groupId"
            workflow-version-id="{{ data.workflowVersionId }}"
            radio-id-prefix="'actionPerformer-' + data.workflowVersionId"
            validation-object="data.validationObject"
            saved-person-selection-configuration="data.existingActionPerformerSelectionConfiguration"
            on-person-selection-configuration-changed="
                onPerformingAdminSelected(personSelectionConfiguration, shouldSaveLogic)
            "
            preview-evaluation-source="data.previewEvaluationSource"
            support-quick-create="false"
            specific-people-custom-options-refresh="refreshAdmins(q)"
            specific-people-custom-options="data.admins"
            configured-logic="data.configuredLogic"
        ></tnk-person-selection-configuration>
    </form>
</div>
