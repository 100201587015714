import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import type GetViewTransform from '../SolutionMapperGraph/GetViewTransform';

import { useEventListener } from '@tonkean/infrastructure';
import { useResizeObserver } from '@tonkean/infrastructure';
import useMultipleRefCallback from '@tonkean/tui-hooks/useMultipleRefCallback';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
`;
export const SolutionMapperAddOverlayTitle = styled.div`
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
    text-align: center;
    pointer-events: none;
    color: ${Theme.colors.basicBackground};
    font-size: ${FontSize.XLARGE_18};
    text-shadow: 0 0 40px #000;
    font-weight: bold;
    letter-spacing: 0.5px;
`;
const Small = styled.small`
    display: block;
    font-weight: normal;
    font-size: ${FontSize.MEDIUM_14};
    margin-top: 5px;
`;
const Overlay = styled.svg`
    display: block;
    cursor: pointer;
    height: 100%;
    width: 100%;
`;

interface Props {
    onSelect(x: number, y: number): void;
    getViewTransform: GetViewTransform;
    rectangle?: boolean;
}

const SolutionMapperAddOverlay: React.FC<Props> = ({ onSelect: onSelectProp, getViewTransform, rectangle = false }) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { setNode } = useResizeObserver((entry) => setElementHeight(entry.contentRect.height));
    const sharedRef = useMultipleRefCallback(wrapperRef, setNode);

    const [elementHeight, setElementHeight] = useState(0);

    useEffect(() => {
        setElementHeight(wrapperRef.current?.clientHeight || 0);
    }, []);

    // The current zoom level.
    const viewTransform = useMemo(() => {
        return getViewTransform();
    }, [getViewTransform]);

    const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>();

    // Update the mouse position relative to the overlay element.
    const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = event.target as HTMLDivElement;

        setMousePosition({
            x: event.nativeEvent.offsetX ?? event.nativeEvent['layerX'] ?? event.pageX - target.offsetLeft,
            y: event.nativeEvent.offsetY ?? event.nativeEvent['layerY'] ?? event.pageY - target.offsetTop,
        });
    };

    const onSelect = () => {
        if (!mousePosition) {
            return;
        }

        onSelectProp(mousePosition.x, mousePosition.y);
    };

    // Listen to arrows and enter press. If it's enter, trigger onSelect. If it's an arrow button, modify the
    // mousePosition.
    useEventListener('keydown', (event) => {
        if (!wrapperRef.current) {
            return;
        }

        if (event.key === 'Enter') {
            // If the user is focused on something, don't trigger an on click of the focused element.
            event.preventDefault();
            event.stopPropagation();

            onSelect();

            return;
        }

        let newX = mousePosition?.x || 0;
        let newY = mousePosition?.y || 0;

        switch (event.key) {
            case 'ArrowUp':
                newY -= 20;
                break;
            case 'ArrowDown':
                newY += 20;
                break;
            case 'ArrowLeft':
                newX -= 20;
                break;
            case 'ArrowRight':
                newX += 20;
                break;
        }

        // Make sure we are not going outside of the boundary.
        setMousePosition({
            x: Math.min(Math.max(newX, 0), wrapperRef.current.clientWidth),
            y: Math.min(Math.max(newY, 0), wrapperRef.current.clientHeight),
        });
    });

    return (
        <Wrapper
            role="button"
            onMouseLeave={() => setMousePosition(undefined)}
            onClick={onSelect}
            onMouseMove={onMouseMove}
            // Handled by useEventListener
            onKeyDown={() => {}}
            ref={sharedRef}
        >
            <SolutionMapperAddOverlayTitle>
                Click where you want to place the new block<Small>or use the arrow keys and press enter</Small>
            </SolutionMapperAddOverlayTitle>
            <Overlay>
                <defs>
                    <mask id="solution-mapper-add-overlay" x={0} y={0} width="100%" height={`${elementHeight}px`}>
                        <rect x={0} y={0} fill="#fff" height={`${elementHeight}px`} width="100%" />
                        {mousePosition &&
                            (rectangle ? (
                                <rect
                                    height={55 * viewTransform.k}
                                    x={mousePosition.x - (275 * viewTransform.k) / 2}
                                    y={mousePosition.y - 25 * viewTransform.k}
                                    width={300 * viewTransform.k}
                                    rx={5 * viewTransform.k}
                                    ry={5 * viewTransform.k}
                                />
                            ) : (
                                <circle cx={mousePosition.x} cy={mousePosition.y} r={70 * viewTransform.k} />
                            ))}
                    </mask>
                </defs>
                <rect
                    x={0}
                    y={0}
                    width="100%"
                    height={`${elementHeight}px`}
                    mask="url(#solution-mapper-add-overlay)"
                    fillOpacity={0.5}
                />
            </Overlay>
        </Wrapper>
    );
};

export default SolutionMapperAddOverlay;
