import template from './tnkTrackWeekViewItem.template.html?angularjs';
import { lateConstructController } from '@tonkean/angular-components';

export const tnkTrackWeekViewItem = angular.module('tonkean.app').component('tnkTrackWeekViewItem', {
    bindings: {
        item: '<',
        day: '<',
        trackTodayPopoverPlacement: '<',
    },
    template,
    controller: 'TrackWeekViewItem',
});

/* @ngInject */
function TrackWeekViewItem($scope, authenticationService, modalUtils, initiativeCache, timeUtils) {
    const ctrl = this;
    $scope.as = authenticationService;
    $scope.modalUtils = modalUtils;
    $scope.timeUtils = timeUtils;
    $scope.itemMap = initiativeCache.getInitiativesCache();
    $scope.item = ctrl.item;
    $scope.day = ctrl.day;
    $scope.trackTodayPopoverPlacement = ctrl.trackTodayPopoverPlacement;

    $scope.data = {
        isDueDateOnDay: false,
        isEtaOnDay: false,
        fieldsOnDay: null,
        thingsOnDay: [],
    };

    $scope.init = function () {
        const track = $scope.itemMap[$scope.item.trackId];
        $scope.data.isDueDateOnDay = track.dueDate && timeUtils.getDateToken(track.dueDate) === $scope.day.dateToken;
        $scope.data.isEtaOnDay = track.eta && timeUtils.getDateToken(track.eta) === $scope.day.dateToken;
        $scope.data.fieldsOnDay = track.fields.filter((field) => timeUtils.isSameDay($scope.day.date, field.dateValue));

        const thingsOnDay = [];
        if ($scope.data.isDueDateOnDay) {
            thingsOnDay.push('Due date');
        }
        if ($scope.data.isEtaOnDay) {
            thingsOnDay.push('ETA');
        }
        $scope.data.thingsOnDay = thingsOnDay.concat(
            $scope.data.fieldsOnDay.map((field) => field.fieldDefinition.name),
        );
    };

    // When the tracksBydate object changes we need to reload
    $scope.$watchCollection('day.items', $scope.init);

    $scope.init();
}

export const tnkTrackWeekViewItemController = angular
    .module('tonkean.app')
    .controller('TrackWeekViewItem', lateConstructController(TrackWeekViewItem));
