import type { TElement } from '@udecode/plate';
import { useAngularService } from 'angulareact';
import uniqWith from 'lodash.uniqwith';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useModuleMarketplaceItemCreateModal } from '../../../MarketplaceItemCreateModalContext';

import { HTMLEditor } from '@tonkean/editor';
import { Field, LoadingCircle, useFormikField } from '@tonkean/infrastructure';
import type { SuggestedDataSource } from '@tonkean/tonkean-entities';
import { IntegrationType } from '@tonkean/tonkean-entities';

const StyledField = styled(Field)`
    margin-bottom: 24px;
`;

const MarketplaceItemCreateModalModuleContentSection: React.FC = () => {
    const { value: description, setValue: setDescription } = useFormikField<TElement[]>('configuration.description');
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const { entityIdToCopy, existingMarketplaceItem, groups } = useModuleMarketplaceItemCreateModal();
    const [isLoading, setIsLoading] = useState(true);
    const { setValue: setSuggestedDatasources } = useFormikField<SuggestedDataSource[]>(
        'configuration.suggestedDatasources',
    );

    const suggestedDatasources = useMemo(() => {
        if (existingMarketplaceItem) {
            return existingMarketplaceItem.configuration.suggestedDatasources || [];
        }
        const filteredGroups = groups?.filter((group) => group.id === entityIdToCopy) || [];
        const group = filteredGroups?.[0];
        const suggestedDataSources =
            group?.originalGroupProjectIntegrations
                ?.filter((projectIntegration) => !projectIntegration.representativeGroupId)
                ?.filter(
                    (projectIntegration) =>
                        (projectIntegration.integrationType !== IntegrationType.NO_CODE_DATA_SOURCE &&
                            projectIntegration.integrationType !== IntegrationType.WEBHOOK) ||
                        projectIntegration.publicMarketplaceItemTemplateName != null,
                )
                ?.map((projectIntegration) => ({
                    integrationType: projectIntegration.integrationType as IntegrationType,
                    publicMarketplaceTemplateName: projectIntegration.publicMarketplaceTemplateName,
                    publicMarketplaceVersion: projectIntegration.publicMarketplaceVersion,
                })) || [];

        return uniqWith(suggestedDataSources, (suggestedA, suggestedB) => {
            if (
                suggestedA.integrationType === suggestedB.integrationType &&
                suggestedA.integrationType === IntegrationType.NO_CODE_DATA_SOURCE
            ) {
                return suggestedA.publicMarketplaceTemplateName === suggestedB.publicMarketplaceTemplateName;
            } else {
                return suggestedA.integrationType === suggestedB.integrationType;
            }
        });
    }, [entityIdToCopy, existingMarketplaceItem, groups]);
    // Set initial values for sections for formik
    useEffect(() => {
        setSuggestedDatasources(suggestedDatasources);
    }, [suggestedDatasources, setSuggestedDatasources]);

    useEffect(() => {
        loadGroupDescriptionIfEmpty();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadGroupDescriptionIfEmpty = async () => {
        if (!description) {
            const groupDescription = await workflowVersionManager.getPublishedVersion(entityIdToCopy || '');
            setDescription(groupDescription.htmlDescription);
        }
        setIsLoading(false);
    };

    return (
        <>
            <StyledField label="Description">
                {isLoading ? (
                    <LoadingCircle />
                ) : (
                    <HTMLEditor
                        dataAutomation="marketplace-item-create-modal-description"
                        initialValue={description}
                        onChange={setDescription}
                    />
                )}
            </StyledField>
        </>
    );
};

export default MarketplaceItemCreateModalModuleContentSection;
