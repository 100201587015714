import type { FormikProps } from 'formik';
import { Form } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import FieldDefinitionConfigurationModalSubHeader from './FieldDefinitionConfigurationModalSubHeader';
import FieldDefinitionMatchItemSettingsForm from './fieldDefinitionMatchItemComponents/FieldDefinitionMatchItemSettingsForm';
import type { FieldDefinitionParam } from './FieldDefinitionParam';
import TnkPreviewListColumns from '../../common/tnkPreviewListColumns/TnkPreviewListColumns';

import type { ContractSummary, MatchItemFieldDefinition } from '@tonkean/tonkean-entities';
import type {
    FieldDefinition,
    Group,
    ProjectIntegration,
    TonkeanId,
    TonkeanType,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import { FieldDefinitionTargetType, FieldType, WorkflowVersionType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { EMPTY_ARRAY } from '@tonkean/utils';

const FormContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto;

    grid-template-columns: minmax(640px, 37%) 1fr;
    grid-template-areas: 'left-pane right-pane';
    height: 100%;
`;

const LeftSide = styled.div`
    padding: 45px 25px;
    border-right: 1px solid ${Theme.colors.gray_300};
    height: 100%;
`;

const PreviewListContainer = styled.div`
    padding: 8px;
`;

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersion: WorkflowVersion;
    onCancel: () => void;
    onDelete: () => void;
    onDuplicate: () => void;
    fieldDefinitionToEdit: FieldDefinition<MatchItemFieldDefinition>;
    selectedModule: Group | undefined;
    setSelectedModule: (Group) => void;
    modules: Group[];
    projectIntegration: ProjectIntegration | undefined;
    onModalEscCloseCallback: () => void;
    editFieldDefinition: boolean;
    formik: FormikProps<FieldDefinition<MatchItemFieldDefinition>>;
    contracts: ContractSummary[];
    isLoadingContracts: boolean;
}

const FieldDefinitionMatchItemSettingsModalInner: React.FC<Props> = ({
    groupId,
    workflowVersion,
    onCancel,
    onDelete,
    onDuplicate,
    fieldDefinitionToEdit,
    selectedModule,
    setSelectedModule,
    modules,
    projectIntegration,
    onModalEscCloseCallback,
    editFieldDefinition,
    formik,
    contracts,
    isLoadingContracts,
}) => {
    const previewListColumn = useMemo<JSX.Element>(() => {
        if (selectedModule && fieldDefinitionToEdit && projectIntegration) {
            // we are extracting the configuration of the group project integration.
            // there is no expiration for this entity so we just take the first one.
            const externalType = Object.keys(
                projectIntegration?.entitiesExpirationConfiguration?.entitiesConfiguration,
            )[0];

            const definitionToPreview: FieldDefinitionParam = {
                definitionId: 'previewedFieldDefinition',
                name: 'Matched Item',
                fieldDefinitionType: 'EXTERNAL',
                fieldDefinitionTargetType: FieldDefinitionTargetType.COLUMN,
                projectIntegrationId: selectedModule.projectIntegrationIdForGroupItems,
                fieldType: FieldType.String,
                definition: {
                    FieldName: 'TNK_TITLE',
                    fieldDefinitionTargetType: FieldDefinitionTargetType.COLUMN,
                    FieldLabel: 'External Entity',
                    ExternalType: externalType,
                    matchConfiguration: {
                        matchOption: 'MATCH_FIELD_CUSTOM_QUERY',
                        customQuery: formik.values.definition.matchConfiguration.customQuery,
                        orderByField: undefined,
                        orderByOrder: 'ASC',
                        orderByFieldType: 'String',
                        isForMatchingItem: true,
                        matchedItemSourceGroupId: selectedModule.id,
                        matchedItemSourceWorkflowVersionId: selectedModule.draftWorkflowVersionId,
                    },
                },
                ranges: [],
                displayAs: FieldType.String,
                previewDefinitionType: 'NEW_DEFINITION',
                doNotCalculatePreviewForDefinition: false,
                validDefinition: true,
            };

            return (
                <TnkPreviewListColumns
                    key={Math.random()}
                    groupId={groupId}
                    workflowVersionId={workflowVersion.id}
                    definitionsToPreview={[definitionToPreview]}
                    existingFieldDefinitionId={fieldDefinitionToEdit?.id || 'TNK_TITLE'}
                    highlightedDefinitionId="previewedFieldDefinition"
                    highlightedFieldDefinitions="previewedFieldDefinition"
                    reloadPreview
                />
            );
        }
        return <></>;
    }, [
        fieldDefinitionToEdit,
        formik.values.definition?.matchConfiguration?.customQuery,
        groupId,
        projectIntegration,
        selectedModule,
        workflowVersion.id,
    ]);

    return (
        <Form>
            <FieldDefinitionConfigurationModalSubHeader
                headerTitle="Matched Item Field"
                hasPendingChanges={formik.dirty && !formik.isSubmitting}
                onModalEscClose={onModalEscCloseCallback}
                onCancel={onCancel}
                workflowVersionType={workflowVersion.workflowVersionType ?? WorkflowVersionType.DRAFT}
                onDelete={onDelete}
                onDuplicate={onDuplicate}
                saveDisabled={!selectedModule}
            />
            <FormContainer>
                <LeftSide>
                    <FieldDefinitionMatchItemSettingsForm
                        groupId={groupId}
                        workflowVersion={workflowVersion}
                        selectedModule={selectedModule}
                        allGroups={modules}
                        setSelectedModule={setSelectedModule}
                        projectIntegration={projectIntegration}
                        fieldDefinitionToEdit={fieldDefinitionToEdit}
                        formik={formik}
                        moduleSelectionReadOnly={
                            editFieldDefinition && !fieldDefinitionToEdit?.definition?.matchConfiguration?.contractId
                        }
                        contracts={contracts || EMPTY_ARRAY}
                        isLoadingContracts={isLoadingContracts}
                    />
                </LeftSide>
                <PreviewListContainer>{previewListColumn}</PreviewListContainer>
                <LeftSide />
            </FormContainer>
        </Form>
    );
};
export default FieldDefinitionMatchItemSettingsModalInner;
