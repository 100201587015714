/* jshint ignore:start */

import React from 'react';
import DefaultInboxReason from './DefaultInboxReason';

export default class PingNowInboxReason extends DefaultInboxReason {
    constructor(props) {
        super(props);
    }

    /**
     * The main render function
     */
    render() {
        const item = this.props.item;
        const initiative = this.props.initiative;

        return (
            <div>
                <div>
                    {item.askerPerson.name} pinged you about {this.renderTitleLinkElement(initiative)}.
                </div>
                {item.reasonMetadata && item.reasonMetadata.customText && (
                    <div className="margin-normal-sm common-color-light-grey common-italic">
                        "{item.reasonMetadata.customText}"
                    </div>
                )}
                <div>What's the latest status?</div>
            </div>
        );
    }
}
/* jshint ignore:end */
