import type { ResponseOfIsValueValid } from '../../../../../../../components/common/EditableTitle/EditableText';

const getResponseOfIsNameValid: (newName: string, otherNames: string[], savedName: string) => ResponseOfIsValueValid = (
    newName: string,
    otherNames: string[],
    savedName: string,
) => {
    const usedNames = otherNames.filter((projectIntegrationName) => projectIntegrationName !== savedName);

    if (!newName) {
        return {
            isValid: false,
            errorReason: "Name can't be empty",
        };
    } else if (usedNames.includes(newName)) {
        return {
            isValid: false,
            errorReason: `Action name "${newName}" already exists`,
        };
    } else {
        return {
            isValid: true,
        };
    }
};

export default getResponseOfIsNameValid;
