<div class="flex">
    <div class="flex-grow relative">
        <label for="message" class="sr-only">Message</label>
        <textarea
            class="form-control tnk-inline-textbox-input"
            rows="{{ data.isFocus || (message && message.length) ? rows : 1 }}"
            id="message"
            ng-class="{ 'mod-used': message && message.length }"
            placeholder="{{ placeholder }}"
            maxlength="{{ maxlength || '' }}"
            ng-keydown="onMessageKeydown($event)"
            ng-focus="data.isFocus = true"
            ng-blur="data.isFocus = false"
            ng-required="required"
            ng-model="message"
        ></textarea>
    </div>
</div>
