<div class="manage-scheduled-reports-modal common-close-btn-container">
    <!-- Reports turned off for user -->
    <div ng-if="data.isDisabledForUser" class="alert alert-danger">
        You have disabled the Scheduled Reports feature.
        <a class="common-underline common-color-danger" ng-click="turnScheduledReportsBotOn()">Turn it ON.</a>
        <span ng-if="data.turnScheduledReportsLoading">
            <i class="loading-small margin-left"></i>
        </span>
    </div>

    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold margin-bottom-lg mod-no-top">Scheduled reports</h1>

    <!-- Reports section -->
    <div class="margin-bottom-lg">
        <!-- Reports table -->

        <!-- Loading -->
        <div ng-if="data.loadingReports" class="flex-vmiddle common-size-xs">
            <i class="loading-small margin-right"></i>
            Loading reports...
        </div>

        <!-- No reports -->
        <div
            ng-if="!data.loadingReports && (!data.scheduledReports || !data.scheduledReports.length)"
            class="common-size-xs"
        >
            There are no scheduled reports yet.
        </div>

        <!-- Reports display -->
        <div ng-if="!data.loadingReports && data.scheduledReports && data.scheduledReports.length">
            <!-- Table titles -->
            <div class="flex-vmiddle common-color-grey">
                <div class="wide-column">Name</div>
                <div class="regular-column">Type</div>
                <div class="regular-column">
                    <span class="visible-xs-inline-block">Freq.</span>
                    <span class="hidden-xs">Frequency</span>
                </div>
                <div class="regular-column padding-xs-left-xs">Recipients</div>
            </div>

            <hr class="margin-normal-sm-v" />

            <!-- Table contents -->
            <div
                ng-repeat="report in data.scheduledReports"
                class="flex-vmiddle margin-bottom show-edit-when-hover common-bold"
            >
                <!-- Name -->
                <div class="wide-column common-ellipsis">{{ report.name }}</div>

                <!-- Type -->
                <div class="regular-column common-ellipsis">
                    {{ data.reportTypeOptions[report.scheduledReportType] }}
                </div>

                <!-- Recurrence Type -->
                <div class="regular-column common-ellipsis">
                    <span>{{ report.recurrencePeriodType }}</span>
                    <span class="hidden-xs">({{ report.frequencyDescription }})</span>
                </div>

                <!-- Subscribers -->
                <div
                    class="regular-column padding-xs-left-xs"
                    ng-if="!report.subscribers || !report.subscribers.length"
                >
                    <span class="visible-xs-inline-block">-</span>
                    <span class="hidden-xs">No recipients</span>
                </div>
                <div class="regular-column padding-xs-left-xs common-ellipsis" ng-if="report.subscribers.length === 1">
                    {{ report.subscribers[0].firstName | capitalize }}
                </div>
                <div class="regular-column padding-xs-left-xs common-ellipsis" ng-if="report.subscribers.length > 1">
                    {{
                        report.subscribers[0].firstName + ' + ' + (report.subscribers.length - 1) + ' more' | capitalize
                    }}
                </div>

                <!-- Edit or unsubscribe button -->
                <div
                    ng-if="report.currentUserHasFullAccess && !report.subscriptionLoading"
                    ng-click="openUpdateScheduledReportModal(report)"
                    class="btn-on-hover padding-xs-left-xs small-column pointer"
                >
                    <i class="fa fa-pencil common-color-primary" uib-tooltip="Edit report" tooltip-placement="top"></i>
                </div>
                <div
                    ng-if="
                        report.currentUserIsSubscriber &&
                        !report.currentUserHasFullAccess &&
                        !report.subscriptionLoading
                    "
                    ng-click="unsubscribeFromReport(report)"
                    class="padding-xs-left-xs small-column pointer"
                >
                    <i
                        class="fa fa-eye common-color-primary"
                        uib-tooltip="Unsubscribe from report"
                        tooltip-placement="top"
                    ></i>
                </div>
                <div
                    ng-if="
                        !report.currentUserIsSubscriber &&
                        !report.currentUserHasFullAccess &&
                        !report.subscriptionLoading
                    "
                    ng-click="subscribeToReport(report)"
                    class="btn-on-hover padding-xs-left-xs small-column pointer"
                >
                    <i
                        class="fa fa-eye common-color-grey"
                        uib-tooltip="Subscribe to report"
                        tooltip-placement="top"
                    ></i>
                </div>
                <i ng-if="report.subscriptionLoading" class="loading-small"></i>
            </div>
        </div>
    </div>

    <!-- Add a new report button -->
    <div class="flex-vmiddle">
        <!-- Add button -->
        <button type="button" class="btn btn-primary" ng-click="openCreateScheduledReportModal()">
            <i class="fa fa-plus margin-right"></i>
            Add scheduled report
        </button>

        <!-- Error handling -->
        <div ng-if="data.errorMessage" class="margin-left common-size-xxs common-bold common-color-danger">
            {{ data.errorMessage }}
        </div>
    </div>
</div>
