import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import PeoplePermissionsLabel from '../PeoplePermission/PeoplePermissionsLabel';

import { SearchBox, Separator } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { IntegrationDefinitionBase } from '@tonkean/integrations';
import { ConnectionPermission } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

const DataSourceConnectionsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 832px;
`;

const DataSourceConnectionListHeader = styled.div`
    display: grid;
    grid-template-columns: 280px auto;
    align-items: center;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.XSMALL_10};
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 10px;
`;

const IntegrationIcon = styled(ProjectIntegrationIcon)`
    margin-right: 6px;
`;

const IntegrationTypeListItem = styled.div`
    display: grid;
    grid-template-columns: 280px auto;
    align-items: center;
`;

const IntegrationTypeLabel = styled.div`
    display: flex;
    align-items: center;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
    text-transform: capitalize;
    height: 50px;
`;

const StyledSearchBox = styled(SearchBox)`
    display: block;
    width: 550px;
    margin-bottom: 28px;
    margin-top: 28px;
`;

const StyledOtherDatasourceConnectionList = styled.div`
    margin-left: 10px;
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
`;

const StyledNoResults = styled.div`
    margin-top: 10px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    integrations: IntegrationDefinitionBase[];
    connectionPermissionType?: ConnectionPermission;
    authorizedPeopleIds?: string[];
}

const OtherDataSourceConnectionsList: React.FC<Props> = ({
    connectionPermissionType = ConnectionPermission.ALL_MEMBERS,
    authorizedPeopleIds = [],
    integrations,
}) => {
    const utils = useAngularService('utils');
    const [searchTerm, setSearchTerm] = useState('');
    const project = useProject();

    const searchFilteredDataSourceConnectionTypes = useMemo(() => {
        return integrations.filter((dataSourceConnectionsType) => {
            return dataSourceConnectionsType.displayName.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [integrations, searchTerm]);

    const allAuthorizedPeopleIds = Object.keys(
        utils.arrayToSet((authorizedPeopleIds || []).concat(project?.owners.map((owner) => owner.id) || [])),
    );

    return integrations.length ? (
        <>
            <StyledSearchBox
                value={searchTerm}
                onChange={({ target: { value } }) => {
                    setSearchTerm(value);
                }}
                placeholder="Search data source type"
            />

            <DataSourceConnectionsListWrapper>
                {searchFilteredDataSourceConnectionTypes.length > 0 && (
                    <>
                        <DataSourceConnectionListHeader>
                            <div>Data Source Type</div>
                            <div>Who Can Create Connection</div>
                        </DataSourceConnectionListHeader>

                        <Separator color={Theme.colors.gray_400} />
                    </>
                )}

                {searchTerm && searchFilteredDataSourceConnectionTypes.length === 0 && (
                    <StyledNoResults>
                        No data source rules to show. Consider changing the search filter.
                    </StyledNoResults>
                )}

                <StyledOtherDatasourceConnectionList>
                    {searchFilteredDataSourceConnectionTypes.map((integrationType) => {
                        return (
                            <IntegrationTypeListItem key={integrationType.name}>
                                <IntegrationTypeLabel>
                                    <IntegrationIcon width={14} integrationType={integrationType.name?.toUpperCase()} />
                                    {integrationType.displayName}
                                </IntegrationTypeLabel>
                                {connectionPermissionType && (
                                    <PeoplePermissionsLabel
                                        connectionPermission={connectionPermissionType}
                                        authorizedPeopleIds={allAuthorizedPeopleIds}
                                        noOneLabel="No one except board admins"
                                    />
                                )}
                            </IntegrationTypeListItem>
                        );
                    })}
                </StyledOtherDatasourceConnectionList>
            </DataSourceConnectionsListWrapper>
        </>
    ) : (
        <></>
    );
};

export default OtherDataSourceConnectionsList;
