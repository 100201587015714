<div
    class="padding-normal-xs common-color-dark-grey common-size-xxs container manual-items-template-config"
    ng-click="stopOuterOutsideClick($event)"
>
    <div ng-if="data.isDynamic">
        <div class="common-title-xxs flex-grow">Configuration</div>
        <div class="flex-vmiddle margin-top-xs margin-bottom">
            <div class="flex-vmiddle">
                <span>Input Type:</span>

                <i
                    class="fa fa-info-circle margin-left-xs"
                    uib-tooltip="How will action items be taken from this input? If Text, will be separated by commas. If JSON, will try to take the items in the json, if its an array will take the elements, if its an object will take the first order values"
                ></i>
            </div>

            <tnk-radio-button
                class="margin-left-xs line-height-normal"
                radio-id="assign-inner-item-template-input-type-manual"
                radio-name="assign-inner-item-template-input-type"
                radio-value="TEXT"
                model="data.inputType"
                on-click="selectInputType(onClickParam)"
                on-click-param="('TEXT')"
                is-required="true"
            >
                <div>Text</div>
            </tnk-radio-button>

            <tnk-radio-button
                class="margin-left-xs line-height-normal"
                radio-id="assign-inner-item-template-input-type-json"
                radio-name="assign-inner-item-template-input-type"
                radio-value="JSON"
                model="data.inputType"
                on-click="selectInputType(onClickParam)"
                on-click-param="('JSON')"
                is-required="true"
            >
                <div>JSON</div>
            </tnk-radio-button>
        </div>
    </div>

    <!-- Header -->
    <div class="flex-vmiddle">
        <div class="common-title-xxs flex-grow">Fields</div>
        <div>
            <!-- Due date setting -->
            <div
                class="common-bubble-inplace-container flex-no-shrink margin-right margin-left"
                ng-click="stopOutsideClick($event)"
            >
                <!-- Add due date button -->
                <div
                    uib-tooltip="Set Due Date"
                    tooltip-class="add-due-date-tooltip"
                    ng-click="data.dueDateAlertOpen = true"
                >
                    <div
                        class="relative"
                        ng-class="{
                            'common-color-grey': !data.initiative.relativeDueDateInDays,
                            'common-color-black': data.initiative.relativeDueDateInDays
                        }"
                    >
                        <!-- Due date icon -->
                        <span class="svg-icon-sm svg-icon-vmiddle pointer">
                            <tnk-icon src="/images/icons/calendar-new.svg"></tnk-icon>
                        </span>
                        <!-- Days counter bubble -->
                        <span class="add-due-date-time-bubble" ng-if="data.initiative.relativeDueDateInDays">
                            {{ data.initiative.relativeDueDateInDays }}
                        </span>
                    </div>
                </div>

                <!-- Add due date bubble -->
                <div
                    class="common-bubble-inplace mod-right"
                    ng-class="{ 'mod-visible': data.dueDateAlertOpen }"
                    ng-show="data.dueDateAlertOpen"
                    click-outside="data.dueDateAlertOpen[$index] = false"
                    outside-if-not="common-bubble-inplace-container"
                    outside-activators="mod-visible"
                >
                    <!-- Add due date title -->
                    <div class="flex-grow flex-vmiddle text-left margin-left-xs">
                        <!-- Due date icon -->
                        <span class="svg-icon-xs common-color-black svg-icon-vmiddle margin-right">
                            <tnk-icon src="/images/icons/calendar-new.svg"></tnk-icon>
                        </span>
                        <!-- Add Due date text -->
                        <span class="common-color-dark-grey line-height-normal flex-grow">Add Due Date</span>

                        <!-- Close button -->
                        <span
                            class="pointer margin-top-none margin-right svg-icon flex-no-shrink common-color-grey"
                            ng-click="data.dueDateAlertOpen = false"
                        >
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </span>
                    </div>
                    <hr class="common-separator mod-darker margin-top margin-bottom-md" />

                    <!-- Due date actual value picking -->
                    <div class="flex-vmiddle margin-left margin-bottom-md">
                        <input
                            type="number"
                            min="0"
                            class="form-control org-seats-box mod-input block margin-right"
                            ng-model="data.initiative.relativeDueDateInDays"
                            ng-change="onDueDateChanged(data.initiative)"
                        />
                        <span class="common-bold">days after Track added</span>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <!-- Owner picking -->
            <div
                class="common-bubble-inplace-container flex-no-shrink margin-right-xs"
                ng-click="stopOutsideClick($event)"
            >
                <!-- Owner button -->
                <div uib-tooltip="Set Owner" tooltip-class="add-due-date-tooltip" ng-click="data.ownerAlertOpen = true">
                    <span
                        class="flex-vmiddle"
                        ng-class="{
                            'common-color-grey': !data.initiative.selectedPeople,
                            'common-color-black': data.initiative.selectedPeople
                        }"
                    >
                        <tnk-avatar
                            ng-if="data.selectedOwnerPersonObject"
                            class="avatar-sm pointer"
                            user="data.selectedOwnerPersonObject"
                            default-on-empty="false"
                        ></tnk-avatar>

                        <span ng-if="!data.selectedOwnerPersonObject" class="svg-icon-md svg-icon-vmiddle pointer">
                            <tnk-icon src="/images/icons/add-owner.svg"></tnk-icon>
                        </span>
                    </span>
                </div>

                <!-- Add owner bubble -->
                <div
                    class="common-bubble-inplace mod-right"
                    ng-class="{ 'mod-visible': data.ownerAlertOpen }"
                    ng-show="data.ownerAlertOpen"
                    click-outside="data.ownerAlertOpen = false"
                    outside-if-not="common-bubble-inplace-container"
                    outside-activators="mod-visible"
                >
                    <!-- Add owner title -->
                    <div class="flex-grow flex-vmiddle text-left margin-left-xs">
                        <!-- Owner icon -->
                        <span class="svg-icon-md common-color-black svg-icon-vmiddle margin-right">
                            <tnk-icon src="/images/icons/add-owner.svg"></tnk-icon>
                        </span>
                        <!-- Add owner text -->
                        <span class="common-color-dark-grey line-height-normal flex-grow">Add owner</span>

                        <!-- Close button -->
                        <span
                            class="pointer margin-top-none margin-right svg-icon flex-no-shrink common-color-grey"
                            ng-click="data.ownerAlertOpen = false"
                        >
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </span>
                    </div>
                    <hr class="common-separator mod-darker margin-top margin-bottom-md" />

                    <!-- Owner actual value picking -->
                    <div class="flex-vmiddle margin-left margin-bottom-md">
                        <tnk-person-selection-configuration
                            class="block"
                            group-id="data.groupId"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            max-people="1"
                            radio-id-prefix="'assign-owner-' + data.initiative.id + data.workflowVersionId"
                            saved-person-selection-configuration="data.ownerConfiguration"
                            on-person-selection-configuration-changed="
                                onOwnerPersonSelectionChanged(personSelectionConfiguration, shouldSaveLogic)
                            "
                            support-quick-create="false"
                            enable-clear-selection="true"
                            configured-logic="data.configuredLogic"
                        ></tnk-person-selection-configuration>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="common-size-xxxxs common-color-grey3 mod-justify-end">
        Create a new field associated to the Sub Item.
    </div>
    <!-- Body -->
    <div class="margin-top margin-bottom">
        <!-- Fields to update selection -->
        <tnk-multiple-field-to-update-editors
            field-selector-group-id="{{ data.groupId }}"
            field-selector-workflow-version-id="{{ data.workflowVersionId }}"
            expression-group-id="{{ data.groupId }}"
            expression-workflow-version-id="{{ data.workflowVersionId }}"
            configured-fields="data.fieldsToUpdate"
            on-configured-fields-changed="onConfiguredFieldsChanged(configuredFields, shouldSaveLogic)"
            extra-button-trigger="removeInitiative()"
            extra-button-text="Remove Item"
            is-extra-button-red="true"
            is-extra-button-visible="data.showRemoveButton"
            special-fields-for-features="['WORKER_INNER_ITEM_CREATION_FIELDS']"
        ></tnk-multiple-field-to-update-editors>
    </div>
</div>
