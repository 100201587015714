import React from 'react';

interface TnkProjectIntegrationViewDataPreviewListOverlayProps {
    entityPluralLabel: string;
}

export const TnkProjectIntegrationViewDataPreviewListLoading: React.FC<
    TnkProjectIntegrationViewDataPreviewListOverlayProps
> = ({ entityPluralLabel }) => <div>Please wait while your {entityPluralLabel} are loading...</div>;

export const TnkProjectIntegrationViewDataPreviewListNoRows: React.FC<
    TnkProjectIntegrationViewDataPreviewListOverlayProps
> = ({ entityPluralLabel }) => <div>No {entityPluralLabel} to Show</div>;
