import { reactToAngular } from 'angulareact';

import NoCodeProjectIntegrationUploadDynamicParameters from './NoCodeProjectIntegrationUploadDynamicParameters';

export default angular
    .module('tonkean.app')
    .component(
        'tnkNoCodeProjectIntegrationUploadDynamicParameters',
        reactToAngular(NoCodeProjectIntegrationUploadDynamicParameters, [
            'configurationProjectIntegrationId',
            'storageProjectIntegration',
            'onStorageIntegrationDynamicParametersChanged',
        ]),
    );
