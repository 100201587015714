import React from 'react';

import type { Initiative, ItemInterface } from '@tonkean/tonkean-entities';

export interface ItemSystemInterfaceContext {
    item?: Initiative;
    itemInterface?: ItemInterface;
    onInitiativeUpdated?(): void;
}

export const ItemSystemInterfaceContext = React.createContext<ItemSystemInterfaceContext>({});
