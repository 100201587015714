import { useMemo } from 'react';

import { useInitiativeValuesFromExpressionsQueries } from '@tonkean/infrastructure';
import { useTDLMatchConditions } from '@tonkean/tonkean-data-layer';
import type {
    Initiative,
    ItemInterfaceWidget,
    ItemInterfaceWidgetConfiguration,
    MatchConditionExpression,
} from '@tonkean/tonkean-entities';
import {
    ItemInterfaceWidgetConfigurationDisplayConditionsMethod,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const shouldEvaluateWidgetUsingMatchConditions = (widget: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>) => {
    return (
        widget.configuration.enableDisplayConditions &&
        widget.configuration.displayConditions &&
        widget.configuration.displayConditionsMethod ===
            ItemInterfaceWidgetConfigurationDisplayConditionsMethod.CONTINUOUSLY
    );
};

function useGetWidgetsToDisplay(
    widgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[] | undefined,
    initiative: Initiative | undefined,
) {
    const widgetsDisplayConditionsExpressions: MatchConditionExpression[] = useMemo(() => {
        return (
            widgets
                ?.filter((widget) => shouldEvaluateWidgetUsingMatchConditions(widget))
                .map((widget) => {
                    return {
                        entityId: widget.id,
                        queryDefinition: widget.configuration.displayConditions!,
                    };
                }) ?? []
        );
    }, [widgets]);

    const fieldsFromWidgetsExpressions = useInitiativeValuesFromExpressionsQueries(
        initiative,
        widgetsDisplayConditionsExpressions,
    );

    const { data: matchConditionsResponse } = useTDLMatchConditions({
        fieldValues: fieldsFromWidgetsExpressions,
        matchInput: widgetsDisplayConditionsExpressions,
        workflowVersionType: initiative?.isDraftInitiative ? WorkflowVersionType.DRAFT : WorkflowVersionType.PUBLISHED,
    });

    return useMemo(() => {
        if (!widgets) {
            return EMPTY_ARRAY;
        }

        return widgets?.filter((widget) => {
            if (shouldEvaluateWidgetUsingMatchConditions(widget)) {
                return matchConditionsResponse?.[widget.id]?.matched;
            }
            return true;
        });
    }, [widgets, matchConditionsResponse]);
}

export default useGetWidgetsToDisplay;
