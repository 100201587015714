import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

/**
 * @deprecated
 * Should use ToTimestamp
 */
export default class ToTimeFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.TO_TIME;
    override readonly sign = 'ToTime';
    override readonly displayName = 'To Time';
    override readonly description = 'Will get the unix time value of any input.';
    override readonly dataType = FieldType.Number;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: FieldType.Date,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];

    override readonly deprecated = true;
}
