import React, { useMemo } from 'react';
import type { GroupBase } from 'react-select';

import { MultiValueLabel, Option, SingleValue } from './components/PersonSelectComponents';
import SimpleSelect from './SimpleSelect';
import type { SimpleSelectProps, SimpleSelectSingleOption } from './SimpleSelectTypes';

import type { Person } from '@tonkean/tonkean-entities';

interface PersonSelectProps<IsMulti extends boolean> {
    options?: Person[];
    isMulti?: IsMulti;
}
type Props<IsMulti extends boolean> = Omit<
    SimpleSelectProps<SimpleSelectSingleOption<string>, IsMulti, false, GroupBase<SimpleSelectSingleOption<string>>>,
    keyof PersonSelectProps<IsMulti>
> &
    PersonSelectProps<IsMulti>;

const PersonSelect = <IsMulti extends boolean>({
    options: optionsProp,
    ...props
}: Props<IsMulti>): React.ReactElement => {
    const options = useMemo(() => {
        return optionsProp?.map((person) => ({ ...person, value: person.id, label: person.name }));
    }, [optionsProp]);

    return (
        <SimpleSelect
            options={options}
            components={{
                Option,
                MultiValueLabel,
                SingleValue,
            }}
            {...(props as any)}
        />
    );
};

export default PersonSelect;
