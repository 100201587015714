import React from 'react';

import SolutionSiteItemsTableAgGridItemWidgetConfigurationEditorDisplay from './configurationEditorDisplays/SolutionSiteItemsTableAgGridItemWidgetConfigurationEditorDisplay';
import SolutionSiteItemsTableWidgetConfigurationEditorDisplay from './configurationEditorDisplays/SolutionSiteItemsTableWidgetConfigurationEditorDisplay';
import type { SolutionSiteEditorProps } from '../../../WidgetModule';
import useGetEditorConfigDataByWorkflowVersionId from '../hooks/useGetEditorConfigDataByWorkflowVersionId';
import type SolutionSiteItemWidgetConfiguration from '../SolutionSiteItemWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';

const SolutionSiteItemWidgetConfigurationEditor: React.FC<
    SolutionSiteEditorProps<SolutionSiteItemWidgetConfiguration>
> = ({ configuration, workflowVersionType }) => {
    const { group, fieldDefinitions, workflowVersionId, fieldDefinitionsLoading } =
        useGetEditorConfigDataByWorkflowVersionId(workflowVersionType);

    const showItemsTableAgGrid = !!useFeatureFlag('tonkean_feature_workspace_app_inner_items_ag_grid');

    return (
        <>
            {showItemsTableAgGrid ? (
                <SolutionSiteItemsTableAgGridItemWidgetConfigurationEditorDisplay
                    configuration={configuration}
                    workflowVersionId={workflowVersionId}
                    groupId={group?.id}
                    fieldDefinitions={fieldDefinitions?.entities}
                    isLoadingFieldDefinitions={fieldDefinitionsLoading}
                />
            ) : (
                <SolutionSiteItemsTableWidgetConfigurationEditorDisplay
                    fieldDefinitions={fieldDefinitions?.entities}
                    configuration={configuration}
                    groupId={group?.id}
                    workflowVersionId={workflowVersionId}
                />
            )}
        </>
    );
};

export default SolutionSiteItemWidgetConfigurationEditor;
