import { useCallback } from 'react';

import type InitiativeRowData from '../entities/InitiativeRowData';
import { isNotRealRow } from '../entities/InitiativeRowData';
import isFieldEmpty from '../utils/isFieldEmpty';

import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

const useGetIsFieldEmptyAndRequired = (isEditable: boolean | undefined, requiredFields: FieldDefinitionKey[]) => {
    return useCallback(
        (fieldDefinitionKey: FieldDefinitionKey, row: InitiativeRowData) => {
            if (isNotRealRow(row) || !isEditable) {
                return false;
            }

            return requiredFields.includes(fieldDefinitionKey) && isFieldEmpty(row, fieldDefinitionKey);
        },
        [isEditable, requiredFields],
    );
};

export default useGetIsFieldEmptyAndRequired;
