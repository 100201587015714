import type { ReactElement } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';

import { NoIcon } from '@tonkean/svg';
import type { Icon } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const StyledNoIcon = styled(NoIcon)<{ width?: number; height?: number; $iconColor: string | undefined }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border: 1px solid ${Theme.colors.gray_400};
    margin: 0 4px;
    color: ${Theme.colors.gray_700};
`;

const StyledSelectedIconComponent = styled.div<{ $iconColor: string | undefined }>`
    ${({ $iconColor }) =>
        $iconColor
            ? css`
                  ${colorSvg($iconColor)}
                  color: ${$iconColor};
              `
            : css`
                  ${colorSvg(Theme.colors.gray_700)}
                  color: ${Theme.colors.gray_700};
              `}
`;

const NO_ICON: string = 'no icon';

interface Props {
    icon?: Icon;
    width?: number;
    height?: number;
    SelectedIconComponent: ReactElement;
    color?: string;
}

const SelectedIcon: React.FC<Props> = ({ icon, width, height, SelectedIconComponent, color }) => {
    return !icon || icon?.name === NO_ICON ? (
        <StyledNoIcon width={width} height={height} $iconColor={color} />
    ) : (
        <StyledSelectedIconComponent $iconColor={color}>{SelectedIconComponent}</StyledSelectedIconComponent>
    );
};

export default SelectedIcon;
