import { angularToReact } from '@tonkean/angular-components';
import type { Form, FormQuestionType } from '@tonkean/tonkean-entities';

export type FormSelectorProps = {
    groupId: string;
    workflowVersionId: string;
    selectedFormId?: string;

    onFormSelected: (form: Form, onFormSelectedParameter: any) => void;
    onFormSelectedParameter?: any;
    onFormSelectedOpenConfirmation?: (form: Form) => Promise<void>;

    clearable?: boolean;
    hideCreateForms?: boolean;
    hideUpdateForms?: boolean;
    doNotIncludeFormIdsSet?: Record<string, boolean>; // If given, will not include forms whose ids are in the doNotIncludeFormIdsSet collection.
    formType?: 'WEBFORM' | 'DIALOG'; // Is a Dialog or a WebForm
    formQuestionTypes?: FormQuestionType[];
    showWorkflowFolderForms?: boolean;
    automationIdentifier?: string;
    hideFileUploadForms?: boolean;
    existingFormMatchedEntityFieldId?: string;
    validationObject?: Record<string, string>;
};

const FormSelector = angularToReact<FormSelectorProps>('tnk-form-selector', {
    onFormSelected: ['form', 'onFormSelectedParameter'],
    onFormSelectedOpenConfirmation: ['form'],
});
export default FormSelector;
