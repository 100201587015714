import { useAngularService } from 'angulareact';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import ChainOfEventsSingleEvent from './ChainOfEventsSingleEvent';
import ChainOfEventsSingleFlowRunEvent from './ChainOfEventsSingleFlowRunEvent';
import ChainOfEventsStates from './ChainOfEventsStates';
import { ChainOfEventsTitle, SubTitle } from './ChainOfEventsTitle';
import HistoryContext from '../../entities/HistoryContext';
import chainOfEventsSidePaneState from '../../states/chainOfEventsSidePaneState';
import currentWorkerRunState from '../../states/currentWorkerRunState';
import initialWorkerRunForDrillState from '../../states/initialWorkerRunForDrillState';
import getWorkerRunDisplayTitle from '../../utils/getWorkerRunDisplayTitle';
import getWorkerRunFullDescription from '../../utils/getWorkerRunFullDescription';

import { useEscapeCallback, useFetchManager } from '@tonkean/infrastructure';
import { createWorkerRunInfo } from '@tonkean/infrastructure';
import { WorkerRunStage } from '@tonkean/tonkean-entities';
import type { WorkerRun } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const SingleEventTitle = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
`;

const SidePane = styled.aside<{ isOpen: boolean }>`
    position: absolute;
    right: 0;
    top: 54px;
    bottom: 0;
    overflow: hidden;
    width: ${({ isOpen }) => (isOpen ? 340 : 50)}px;
    transition: width 0.2s ease-in-out;
    background: ${Theme.current.palette.mainColors.basicBackground};
    border-left: 1px solid ${Theme.current.palette.mainColors.HEX_CACACA};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    width: 340px;
`;

const EventLink = styled.div<{ selected?: boolean }>`
    font-size: 12px;
    margin-top: 4px;
    text-decoration: ${({ selected }) => (selected ? 'none' : 'underline')};
`;

interface Props extends StyledComponentsSupportProps {}

const ChainOfEvents: React.FC<Props> = ({ className }) => {
    const [chainOfEventsSidePane, setChainOfEventsSidePaneState] = useRecoilState(chainOfEventsSidePaneState);
    const [initialWorkerRunForDrill, setInitialWorkerRunForDrill] = useRecoilState(initialWorkerRunForDrillState);
    const currentWorkerRun = useRecoilValue(currentWorkerRunState);

    const projectManager = useAngularService('projectManager');
    const tonkeanService = useAngularService('tonkeanService');

    const { state, updateState, rangeFilter } = useContext(HistoryContext);
    const [hoveringFlowRunLink, setHoveringFlowRunLink] = useState(false);

    const [[getChainOfEvents, cancelFetcher], { data: workerRuns, loading, error }] = useFetchManager(
        tonkeanService,
        'getChainOfEvents',
        {
            autoReloadInterval: chainOfEventsSidePane.isOpen ? 2000 : 10_000,
        },
    );

    const chainOfEventsRootWorkerRun = initialWorkerRunForDrill?.id || currentWorkerRun?.id;
    const chainOfEventsRootWorkerRunStartTime = initialWorkerRunForDrill?.startTime || currentWorkerRun?.startTime;
    useEffect(() => {
        if (chainOfEventsRootWorkerRun && chainOfEventsRootWorkerRunStartTime && rangeFilter?.dateFrom) {
            getChainOfEvents(
                projectManager.project.id,
                state.groupId,
                chainOfEventsRootWorkerRun,
                chainOfEventsRootWorkerRunStartTime,
                rangeFilter.dateFrom?.getTime(),
                rangeFilter.dateTo?.getTime(),
            );

            return cancelFetcher;
        }
    }, [
        cancelFetcher,
        chainOfEventsRootWorkerRun,
        chainOfEventsRootWorkerRunStartTime,
        getChainOfEvents,
        projectManager.project.id,
        rangeFilter,
        state.groupId,
    ]);

    const { dataSourceRun, moduleItemRun, flowRuns } = useMemo(() => {
        return {
            dataSourceRun: workerRuns.find((entity) => entity.workerRunStage === WorkerRunStage.DATA_SOURCE),
            moduleItemRun: workerRuns.find((entity) => entity.workerRunStage === WorkerRunStage.MODULE_ITEM),
            flowRuns: workerRuns.filter((entity) => entity.workerRunStage === WorkerRunStage.FLOW_RUN) || [],
        };
    }, [workerRuns]);

    useEscapeCallback(() => setChainOfEventsSidePaneState({ isOpen: false }), chainOfEventsSidePane.isOpen);

    const selectChainOfEventsWorkerRun = (workerRun: WorkerRun) => {
        if (currentWorkerRun && workerRun.id !== currentWorkerRun.id) {
            if (!initialWorkerRunForDrill) {
                setInitialWorkerRunForDrill(currentWorkerRun);
            }
            updateState({
                workerRunInfo: createWorkerRunInfo(workerRun.id, workerRun.startTime),
                workerRunStage: workerRun.workerRunStage,
            });
        }
    };

    const isEmptyState = (!state.workerRunInfo || workerRuns.length === 0) && !loading.initial;
    const showEmptyState = isEmptyState && !error;
    const showChainOfEventsLoading = !isEmptyState && !error && loading.initial;
    const showChainOfEvents = !isEmptyState && !error && !loading.initial;

    return (
        <SidePane
            className={className}
            isOpen={chainOfEventsSidePane.isOpen}
            onMouseEnter={() => setChainOfEventsSidePaneState({ isOpen: true })}
            onMouseLeave={() => setChainOfEventsSidePaneState({ isOpen: false })}
            onFocus={() => setChainOfEventsSidePaneState({ isOpen: true })}
            onBlur={() => setChainOfEventsSidePaneState({ isOpen: false })}
        >
            <Container>
                {(error || showEmptyState || showChainOfEventsLoading) && (
                    <ChainOfEventsStates error={error} isLoading={showChainOfEventsLoading} isEmpty={showEmptyState} />
                )}
                {showChainOfEvents && (
                    <>
                        <ChainOfEventsTitle>
                            Chain of Events <SubTitle>In current time range</SubTitle>
                        </ChainOfEventsTitle>

                        {dataSourceRun && (
                            <ChainOfEventsSingleEvent
                                onClick={() => selectChainOfEventsWorkerRun(dataSourceRun)}
                                isLast={flowRuns.length === 0 && !moduleItemRun}
                                workerRunStage={WorkerRunStage.DATA_SOURCE}
                                targetWorkerRun={dataSourceRun}
                                title={`${getWorkerRunFullDescription(dataSourceRun)}:`}
                            >
                                <EventLink selected={state.workerRunStage === WorkerRunStage.DATA_SOURCE}>
                                    {getWorkerRunDisplayTitle(dataSourceRun)}
                                </EventLink>
                            </ChainOfEventsSingleEvent>
                        )}
                        {moduleItemRun && (
                            <ChainOfEventsSingleEvent
                                onClick={() => selectChainOfEventsWorkerRun(moduleItemRun)}
                                isLast={flowRuns.length === 0}
                                workerRunStage={WorkerRunStage.MODULE_ITEM}
                                targetWorkerRun={moduleItemRun}
                                title={`${getWorkerRunFullDescription(moduleItemRun)}:`}
                            >
                                <EventLink selected={state.workerRunStage === WorkerRunStage.MODULE_ITEM}>
                                    {getWorkerRunDisplayTitle(moduleItemRun)}
                                </EventLink>
                            </ChainOfEventsSingleEvent>
                        )}
                        {flowRuns.length > 0 && (
                            <ChainOfEventsSingleEvent
                                workerRunStage={WorkerRunStage.FLOW_RUN}
                                title={<SingleEventTitle>Flow runs ({flowRuns.length}):</SingleEventTitle>}
                                hovered={hoveringFlowRunLink}
                                isLast
                                isMultiple
                            >
                                {flowRuns.map((run) => (
                                    <ChainOfEventsSingleFlowRunEvent
                                        key={run.id}
                                        workerRun={run}
                                        onSelect={() => selectChainOfEventsWorkerRun(run)}
                                        onHover={(newState: boolean) => setHoveringFlowRunLink(newState)}
                                    />
                                ))}
                            </ChainOfEventsSingleEvent>
                        )}
                    </>
                )}
            </Container>
        </SidePane>
    );
};

export default ChainOfEvents;
