import AutonomousItemCreatedLogicBlockConfig from './AutonomousItemCreatedLogicBlockConfig';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const InnerAutonomousItemCreatedLogicBlockConfig: LogicBlockConfigDefinition = {
    ...AutonomousItemCreatedLogicBlockConfig,
    type: LogicBlockConfigType.INNER_AUTONOMOUS,
};

export default InnerAutonomousItemCreatedLogicBlockConfig;
