import React from 'react';

interface Props {
    active: boolean;
    showRegexTutorialLink?: boolean;
    showEvaluationToggleButton?: boolean;
    onToggle: (active) => void;
}

const RegexViewerBlockSideButtons: React.FC<Props> = ({
    active,
    showRegexTutorialLink = false,
    showEvaluationToggleButton = false,
    onToggle,
}) => (
    <div className="flex-grow text-right">
        {showRegexTutorialLink && (
            <span className="tnk-tooltip mod-left margin-left-xs">
                <a
                    href="https://regexone.com/"
                    target="_blank"
                    className="common-color-grey common-size-xxxs inline-button"
                >
                    <i className="fa fa-book" />
                </a>
                <span className="tnk-tooltip-text">Regex tutorial</span>
            </span>
        )}
        {showEvaluationToggleButton && (
            <span className="tnk-tooltip mod-left margin-left-xs">
                <button
                    type="button"
                    className="common-color-grey common-size-xxxs inline-button"
                    onClick={() => onToggle(!active)}
                >
                    <i className="fa fa-flask" />
                </button>
                <span className="tnk-tooltip-text">{active ? 'Show expression' : 'Show evaluated'}</span>
            </span>
        )}
    </div>
);

export { RegexViewerBlockSideButtons };
