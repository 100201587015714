/**
 * Created by ShaLi on 8/17/15.
 */

function InitiativeSettingsModalCtrl($scope, showSetReminder, initiative, manualGatherUpdate, onReminderSet) {
    $scope.showSetReminder = showSetReminder;
    $scope.initiative = initiative;
    $scope.manualGatherUpdate = manualGatherUpdate;
    $scope.onReminderSet = onReminderSet;
}
angular.module('tonkean.app').controller('InitiativeSettingsModalCtrl', InitiativeSettingsModalCtrl);
