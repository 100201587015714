<div class="common-size-xs" ng-if="item.reference1.description">
    <hr class="margin-normal-sm-v" />
    <p
        class="common-break-long"
        light-markdown="item.metadata? item.metadata.newDescription : item.reference1.description"
    ></p>

    <!--<hr ng-if="item.metadata.newTicketUrl" class="margin-normal-sm-v"/>-->
    <div ng-if="item.metadata.newTicketUrl" class="common-subtitle-inner">
        <span>Ticket:</span>
        <a href="{{ item.metadata.newTicketUrl }}" target="_blank">{{ item.metadata.newTicketUrl }}</a>
    </div>
</div>
