class NetworkServiceError extends Error {
    constructor(
        message: string,
        public status: number,
    ) {
        super(message);
        this.name = 'NetworkServiceError';
    }
}

export { NetworkServiceError };
