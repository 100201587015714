import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { FormikTnkCustomFilters } from '@tonkean/angular-to-react-components';
import type { ModalProps } from '@tonkean/infrastructure';
import {
    H3,
    H4,
    Input,
    ModalBody,
    ModalFooterActions,
    ModalHeader,
    ModalSize,
    Paragraph,
    Toggle,
    withModal,
} from '@tonkean/infrastructure';
import type { ActionStateConfiguration, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledModalBody = styled(ModalBody)`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CardContainer = styled.div`
    display: grid;
    gap: 16px;
    padding: 16px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 4px;
    background: ${Theme.colors.gray_100};
    overflow-x: auto;
`;

const TitleAndToggleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const drawerEffectVariants = {
    hidden: {
        overflow: 'hidden',
        opacity: 0,
        height: 0,
        transition: {
            duration: 0.6,
        },
    },
    shown: {
        overflow: 'hidden',
        opacity: 1,
        height: 'auto',
        transition: {
            duration: 0.6,
        },
    },
};

interface Props extends ModalProps {
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    existingConfiguration: ActionStateConfiguration | undefined;
    onSave: (configuration: ActionStateConfiguration) => void;
}

const ActionDisabledStateModal: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    existingConfiguration,
    onSave,
    onClose,
}) => {
    const initialValues: ActionStateConfiguration = {
        disabled: {
            active: existingConfiguration?.disabled?.active || false,
            conditions: existingConfiguration?.disabled?.conditions,
            disabledText: existingConfiguration?.disabled?.disabledText,
        },
        hidden: {
            active: existingConfiguration?.hidden?.active || false,
            conditions: existingConfiguration?.hidden?.conditions,
        },
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                onSave(values);
                onClose();
            }}
        >
            {({ submitForm, values }) => {
                return (
                    <Form>
                        <ModalHeader>
                            <H3>Set button states</H3>
                            <Paragraph $light>
                                The button is in an active state by default. Define the conditions below for each state
                                below
                            </Paragraph>
                        </ModalHeader>
                        <StyledModalBody>
                            <TitleAndToggleContainer>
                                <H4>Disable button when...</H4>
                                <Toggle
                                    name="disabled.active"
                                    size={InputSize.SMALL}
                                    dataAutomation="action-disabled-state-modal-disable-toggle"
                                />
                            </TitleAndToggleContainer>

                            <motion.div
                                variants={drawerEffectVariants}
                                initial={existingConfiguration?.disabled?.active ? 'shown' : 'hidden'}
                                animate={values?.disabled?.active ? 'shown' : 'hidden'}
                            >
                                <CardContainer>
                                    <FormikTnkCustomFilters
                                        name="disabled.conditions"
                                        itemsSource="COLUMN"
                                        groupId={groupId}
                                        workflowVersionId={workflowVersionId}
                                        specialFieldsForFeatures={['ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER']} // ?
                                        includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']} // ?
                                        hideTimeRangeSelection
                                        hideCloseButton
                                        editMode
                                        useExpressionsForValue
                                        verticalMod
                                        smallMod
                                    />

                                    <H4 $bold>Disabled button will display the following message on hover</H4>
                                    <Input
                                        data-automation="action-disabled-state-modal-message-input"
                                        name="disabled.disabledText"
                                        placeholder="Explain why the button is disabled"
                                    />
                                </CardContainer>
                            </motion.div>

                            <TitleAndToggleContainer>
                                <H4>Hide button when...</H4>
                                <Toggle
                                    name="hidden.active"
                                    size={InputSize.SMALL}
                                    dataAutomation="action-disabled-state-modal-hide-toggle"
                                />
                            </TitleAndToggleContainer>

                            <motion.div
                                variants={drawerEffectVariants}
                                initial={existingConfiguration?.hidden?.active ? 'shown' : 'hidden'}
                                animate={values?.hidden?.active ? 'shown' : 'hidden'}
                            >
                                <CardContainer>
                                    <FormikTnkCustomFilters
                                        name="hidden.conditions"
                                        itemsSource="COLUMN"
                                        groupId={groupId}
                                        workflowVersionId={workflowVersionId}
                                        specialFieldsForFeatures={['ITEM_INTERFACE_INNER_ITEMS_WIDGET_FILTER']} // ?
                                        includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']} // ?
                                        hideTimeRangeSelection
                                        hideCloseButton
                                        editMode
                                        useExpressionsForValue
                                        verticalMod
                                        smallMod
                                    />
                                </CardContainer>
                            </motion.div>
                        </StyledModalBody>
                        <ModalFooterActions onSave={submitForm} />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withModal(ActionDisabledStateModal, { size: ModalSize.MEDIUM });
