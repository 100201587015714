<div>
    <div class="padding-normal box-with-shadow text-center">
        <h3 class="margin-normal">Solution-based Pricing</h3>
        <p>
            A solution solves an end-to-end operational challenge (i.e. sales velocity, customer satisfaction, employee
            satisfaction, etc.).
            <br />
            Each solution can include as many modules (processes) and data sources (system connections) as you may need
            to improve your operation.
        </p>
        <p class="margin-bottom-xlg">
            <a href="https://tonkean.com/pricing/" target="_blank">Explore Editions & Pricing Options →</a>
        </p>

        <div class="text-center" ng-if="!data.askedForMeeting">
            <button class="btn btn-primary btn-lg" ng-click="askForMeeting()">Contact Sales</button>
        </div>
        <div class="alert alert-success" ng-if="data.askedForMeeting">
            <h4>Thank you.</h4>
            <p>A representative will reach out to you shortly to schedule some time.</p>
        </div>

        <div class="margin-top-lg common-color-grey">
            For more information
            <a href="#" onclick="Intercom('showNewMessage');">click here to chat with us.</a>
        </div>
    </div>
</div>
