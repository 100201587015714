import { useAngularService } from 'angulareact';
import React, { useCallback, useState } from 'react';

import TemplateConfigurationModal from './TemplateConfigurationModal';

import { Button } from '@tonkean/tui-buttons/Button';

interface Props {
    onClose(): void;
}

const TemplateConfigurationModalTrigger: React.FC<Props> = ({ onClose }) => {
    const $rootScope = useAngularService('$rootScope');

    const [isOpen, setIsOpen] = useState(false);

    const onCloseModal = useCallback(() => {
        setIsOpen(false);
        onClose();
    }, [onClose]);

    const onError = useCallback(
        (error) => {
            $rootScope.$emit('alert', { type: 'danger', msg: error });
            onCloseModal();
        },
        [$rootScope, onCloseModal],
    );

    return (
        <>
            <Button onClick={() => setIsOpen(true)}>Create Template</Button>
            <TemplateConfigurationModal isOpen={isOpen} onError={onError} onClose={onCloseModal} />
        </>
    );
};

export default TemplateConfigurationModalTrigger;
