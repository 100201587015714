import { useAngularService } from 'angulareact';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import WorkflowVersionInfo from './WorkflowVersionInfo';
import { ReactComponent as BackIcon } from '../../../../images/icons/back.svg';
import { ReactComponent as EmptySolutionIcon } from '../../../../images/icons/empty-solution.svg';
import WorkerAuditLogs from '../tnkWorkerAuditLogs/WorkerAuditLogs';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { SimpleErrorStateMessage } from '@tonkean/infrastructure';
import type { WorkflowVersion } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledBackIconWrapper = styled.div`
    .tnk-icon svg {
        height: 10px;
    }
`;

const VerticalSeparator = styled.span`
    height: 10px;
    background-color: ${Theme.colors.gray_400};
    width: 1px;
    margin-left: 2px;
    margin-right: 10px;
`;

interface Props {
    groupId: string;
    workflowVersionId: string;
    workflowVersionsCache: WorkflowVersion[];
    onUnselectWorkflowVersion(): void;
    targetTypeId?: string;
    groupByToggleDefault?: boolean;
}

const WorkflowVersionHistory: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    workflowVersionsCache,
    onUnselectWorkflowVersion,
    targetTypeId,
    groupByToggleDefault,
}) => {
    const tonkeanUtils = useAngularService('tonkeanUtils');
    const consts = useAngularService('consts');
    const authenticationService = useAngularService('authenticationService');
    const projectManager = useAngularService('projectManager');
    const modalUtils = useAngularService('modalUtils');
    const tonkeanService = useAngularService('tonkeanService');
    const workflowVersionManager = useAngularService('workflowVersionManager');

    const [workflowVersion, setWorkflowVersion] = useState<WorkflowVersion>();

    const [{ loading, error, data: workflowVersionFromManager }, getCachedWorkflowVersionOrGetFromServer] =
        useLazyAsyncMethod(workflowVersionManager, 'getCachedWorkflowVersionOrGetFromServer');

    /**
     * When there is a workflow version id, take it from the cache, but it not existing, trigger
     * getCachedWorkflowVersionOrGetFromServer.
     */
    useEffect(() => {
        if (workflowVersion?.id === workflowVersionId) {
            return;
        }

        const versionFromCache = workflowVersionsCache.find(
            (cachedWorkflowVersion) => cachedWorkflowVersion.id === workflowVersionId,
        );

        if (versionFromCache) {
            setWorkflowVersion(versionFromCache);
        } else {
            // If not found in the sidepane cache, trigger a request.
            getCachedWorkflowVersionOrGetFromServer(workflowVersionId);
        }
    }, [getCachedWorkflowVersionOrGetFromServer, workflowVersion?.id, workflowVersionId, workflowVersionsCache]);

    /**
     * When getCachedWorkflowVersionOrGetFromServer resolves, make sure that it matches the current workflowVersionId
     * (it might have changes since), and if it does, use it as the workflow version.
     */
    useEffect(() => {
        if (workflowVersionFromManager?.id === workflowVersionId) {
            setWorkflowVersion(workflowVersionFromManager);
        }
    }, [workflowVersionFromManager, workflowVersionFromManager?.id, workflowVersionId]);

    return (
        <div className="workflow-versions-history">
            {loading && (
                <div className="flex flex-grow mod-align-center mod-justify-center">
                    <div className="loading-large" />
                </div>
            )}

            {error && (
                <SimpleErrorStateMessage
                    icon={
                        <span className="tnk-icon">
                            <EmptySolutionIcon />
                        </span>
                    }
                    error={error}
                />
            )}

            {workflowVersion && (
                <>
                    <WorkflowVersionInfo
                        workflowVersion={workflowVersion}
                        headerPrepend={
                            <>
                                <button
                                    className="inline-button workflow-versions-history-back-button"
                                    type="button"
                                    onClick={onUnselectWorkflowVersion}
                                >
                                    <StyledBackIconWrapper>
                                        <span className="tnk-icon">
                                            <BackIcon />
                                        </span>
                                    </StyledBackIconWrapper>
                                </button>
                                <VerticalSeparator />
                            </>
                        }
                        showAsDrillDown
                    />

                    <WorkerAuditLogs
                        tonkeanService={tonkeanService}
                        projectManager={projectManager}
                        authenticationService={authenticationService}
                        consts={consts}
                        groupId={groupId}
                        pageSize="20"
                        workflowVersionId={workflowVersion.id}
                        tonkeanUtils={tonkeanUtils}
                        modalUtils={modalUtils}
                        groupByToggleDefault={groupByToggleDefault}
                        targetTypeId={targetTypeId}
                        appendFilterDialogToBody
                    />
                </>
            )}
        </div>
    );
};

export default WorkflowVersionHistory;
