import { useMemo } from 'react';

import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

const useHeadersExpressionAdditionalTab: () => TonkeanExpressionAdditionalTab = () => {
    return useMemo(() => {
        return {
            id: 'REQUEST_PARAMS',
            label: 'Request Params',
            iconClass: 'mod-actions',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: () => {
                return Promise.resolve([
                    {
                        name: 'requestBody',
                        value: 'requestBody',
                        label: 'Request Body',
                        id: 'requestBody',
                    },
                ]);
            },
        };
    }, []);
};

export default useHeadersExpressionAdditionalTab;
