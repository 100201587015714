<div class="worker-outline-item" ng-class="{ 'mod-focused': data.isFocused, 'is-dependent': data.isDependent }">
    <!-- Editable display name. -->
    <div class="flex-vmiddle" ng-if="!data.hideNodeDisplayName">
        <!-- Bullet -->
        <span
            class="worker-outline-bullet flex-no-shrink margin-bottom-xxs"
            ng-class="{ 'yellow-bullet': data.isDependent }"
            ng-hide="data.savingDisplayName"
        ></span>
        <!-- Loading circle (takes bullet's place) -->
        <div class="worker-outline-item-loading-container flex-no-shrink" ng-show="data.savingDisplayName">
            <i class="loading-small"></i>
        </div>
        <!-- Input -->
        <span
            ng-if="!data.editMode"
            ng-click="switchToEditMode()"
            class="
                common-size-xxxxs
                form-control
                common-input-no-border
                flex-grow
                padding-right-lg padding-left-none
                margin-top-xxs
            "
        >
            <tnk-highlightable-text
                ng-if="data.nodeBlock"
                highlight-text="data.highlightText"
                text="data.nodeBlock.node.displayName || data.defaultActionName"
            ></tnk-highlightable-text>
        </span>
        <!-- Input -->
        <input
            ng-if="data.editMode"
            type="text"
            ng-model="data.nodeBlock.node.displayName"
            ng-readonly="data.workflowVersion.workflowVersionType !== 'DRAFT'"
            id="worker-outline-input-{{ data.nodeBlock.node.id }}"
            class="
                common-size-xxxxs
                form-control
                common-input-no-border
                flex-grow
                padding-right-lg padding-left-none
                worker-outline-item-input
            "
            ng-blur="onNodeBlur()"
            ng-focus="onNodeFocused()"
            ng-keydown="onOutlineItemInputKeyDown($event)"
        />
    </div>

    <!-- Child nodes -->
    <div class="padding-left-md" ng-if="data.nodeBlock && data.nodeBlock.impacts && data.nodeBlock.impacts.length">
        <div ng-repeat="impact in data.nodeBlock.impacts | filter : { node: { isHidden: false }}">
            <tnk-worker-outline-item
                group-id="data.groupId"
                node-block="impact"
                node-parent="data.nodeBlock"
                node-grand-parent="data.nodeParent"
                workflow-version-id="{{ data.workflowVersionId }}"
                sync-object="::data.syncObject"
                select-node="selectNode(node)"
                create-new-node="createNewNode(parentNode)"
                update-current-node-data="updateCurrentNodeData(currentNode, nodeParent, nodeGrandParent)"
                tab-node-in="tabNodeIn(nodeBlock, nodeParent)"
                tab-node-out="tabNodeOut(nodeBlock, nodeParent, nodeGrandParent)"
                move-in-tree="moveInTree(isUp, currentNodeId)"
                highlight-text="data.highlightText"
                trigger-ids-to-highlight="data.triggerIdsToHighlight"
            ></tnk-worker-outline-item>
        </div>
    </div>
</div>
