<div class="import-tracks">
    <tnk-import-tracks
        group-id="importTracksSettings.groupId"
        hide-time-range-selection="true"
        parent-initiative="importTracksSettings.parentInitiative"
        func="importTracksSettings.func"
        on-complete-callback="done()"
        only-import="true"
        create-in-edit-mode="importTracksSettings.createInEditMode"
        form-id="importTracksSettings.formId"
        disable-import-from-integrations="importTracksSettings.disableImportFromIntegrations"
        workflow-version-id="{{ importTracksSettings.workflowVersionId }}"
        display-fields-list="importTracksSettings.fieldsNames"
        dismiss="$dismiss()"
    ></tnk-import-tracks>
</div>
