import LogicalOperatorBase from './LogicalOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

const fieldMatchingType = Symbol('field A and field B value type');

export default class GreaterThanOperator extends LogicalOperatorBase {
    override readonly key = OperatorKey.GREATER_THAN;
    override readonly sign = '>';
    override readonly displayName = 'Greater Than';
    override readonly description = 'Will return true if the left hand field is greater than the right hand field.';

    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Field A',
            dataType: [FieldType.Number, FieldType.Date],
            typeMatchingSymbol: fieldMatchingType,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Field B',
            dataType: [FieldType.Number, FieldType.Date],
            typeMatchingSymbol: fieldMatchingType,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
