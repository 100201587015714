export const SUBSCRIPTION_TIERS = {
    BASIC: {
        apiName: 'BASIC',
        displayName: 'Basic plan',
        tnkRequire: 'license',
    },
    STANDARD: {
        apiName: 'STANDARD',
        displayName: 'Standard plan',
        tnkRequire: 'standardLicense',
    },
    PREMIUM: {
        apiName: 'PREMIUM',
        displayName: 'Premium plan',
        tnkRequire: 'premiumLicense',
    },
};

export function getSubscriptionTiers() {
    return structuredClone(SUBSCRIPTION_TIERS);
}
