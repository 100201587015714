/**
 * create new array with the same instances
 * merge all the new items from arrayB to arrayA and replace all the updated items from arrayB into arrayA
 *
 * @param arrayA main array
 * @param arrayB secondary array
 * @param compareKey the uniq param of every item of type T in the array if not given defaults 'id'
 * @return new array of type T with the instances of arrayA and arrayB unless the item in ArrayB have same 'id', then the instance will be item from arrayB
 */
function mergeObjectsById<T extends { id: string }>(arrayA: T[], arrayB: T[], compareKey?: 'id'): T[];

/**
 * generic version create new array with the same instances
 * merge all the new items from arrayB to arrayA and replace all the updated items from arrayB into arrayA
 *
 * @param arrayA main array
 * @param arrayB secondary array
 * @param compareKey the uniq param of every item of type T
 * @return new array of type T with the instances of arrayA and arrayB unless the item in ArrayB have same compareKey, then the instance will be item from arrayB
 */
function mergeObjectsById<T extends Record<any, any>>(arrayA: T[], arrayB: T[], compareKey: keyof T): T[];

function mergeObjectsById<T extends Record<any, any>>(arrayA: T[], arrayB: T[], compareKey: keyof T = 'id') {
    const updatedItems = arrayA.map((itemInArrayA) => {
        const updatedItem = arrayB.find((itemInArrayB) => itemInArrayB[compareKey] === itemInArrayA[compareKey]);
        return updatedItem || itemInArrayA;
    });
    const newItems = arrayB.filter((itemInArrayB) =>
        arrayA.every((itemInArrayA) => itemInArrayB[compareKey] !== itemInArrayA[compareKey]),
    );

    return [...updatedItems, ...newItems];
}

export default mergeObjectsById;
