function EnterpriseHelper($q, tonkeanService, authenticationService) {
    const _this = this;

    /**
     * Add board to enterprise.
     */
    _this.addProjectToEnterpriseById = function (enterpriseId, projectId) {
        return tonkeanService.updateProjectEnterpriseId(projectId, enterpriseId).then(() => {
            // Update the user, since he now has a seat in this new enterprise project.
            updateUser();

            return $q.resolve();
        });
    };

    /**
     * Remove board from enterprise
     */
    _this.removeProjectFromEnterpriseById = function (enterpriseId, projectId) {
        return tonkeanService.deleteProjectEnterpriseId(projectId, enterpriseId).then(function () {
            // Update the user, since he was now removed from an enterprise project.
            updateUser();

            return $q.resolve();
        });
    };

    /**
     * Updates the current user.
     */
    function updateUser() {
        tonkeanService.getCurrentUser().then(function (freshUser) {
            authenticationService.updateCurrentUser(freshUser);
        });
    }
}

angular.module('tonkean.app').service('enterpriseHelper', EnterpriseHelper);
