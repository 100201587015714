import type { SingleFieldConfiguration } from '../CommonWidgetConfiguration';

import type { ItemInterfaceWidgetConfiguration } from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';

export enum FieldsWidgetDisplayType {
    TABLE = 'TABLE',
    LIST = 'LIST',
    GALLERY = 'GALLERY',
}

export default interface FieldsItemWidgetConfiguration extends ItemInterfaceWidgetConfiguration {
    /**
     * The fields to display in the widget
     */
    fields: Record<FieldDefinitionKey, SingleFieldConfiguration>;

    /**
     * View type
     */
    fieldDisplay: FieldsWidgetDisplayType;

    /**
     * Can the user edit the values of the fields?
     */
    editingEnabled?: boolean;

    /**
     * Are all the values submitted as one as a form? Or are they saved individually
     */
    submitAsForm?: boolean;

    /**
     * Should the icon be presented on the widget
     */
    showIcon: boolean;

    /**
     * Should show search bar on the widget
     */
    showSearch: boolean;

    /**
     * Should show the text emphasized in the widget.
     */
    emphasizeText: boolean;
}
