import React, { useEffect, useState } from 'react';

import ImageWidgetDisplay from './ImageWidgetDisplay';
import { useSolutionSiteContext } from '../../../hooks';
import type { SolutionSitePageWidgetProps } from '../../../WidgetModule';
import type ImageWidgetConfiguration from '../ImageWidgetConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import utils, { isUrlValid } from '@tonkean/utils';

const SolutionSitePageImageWidget: React.FC<SolutionSitePageWidgetProps<ImageWidgetConfiguration>> = ({
    widget,
    permission,
}) => {
    const [isUrlExpressionValid, setIsUrlExpressionValid] = useState<boolean>(false);
    const [urlExpression, setUrlExpression] = useState<string>('');
    const [entityVersionType] = useGetStateParams<[WorkflowVersionType]>('env');

    const { solutionSite } = useSolutionSiteContext();

    const [
        { data: expressions, loading: loadingExpressions, error: urlExpressionError },
        getEvaluatedExpressionForProject,
    ] = useLazyTonkeanService('getEvaluatedExpressionForProject');

    useEffect(() => {
        if (widget?.configuration?.image?.url?.evaluatedExpression) {
            getEvaluatedExpressionForProject(
                solutionSite.projectId,
                entityVersionType || WorkflowVersionType.PUBLISHED,
                [
                    {
                        key: utils.guid(),
                        expression: widget?.configuration?.image?.url?.evaluatedExpression,
                    },
                ],
            );
        }
    }, [
        entityVersionType,
        getEvaluatedExpressionForProject,
        solutionSite.projectId,
        widget?.configuration?.image?.url?.evaluatedExpression,
    ]);

    useEffect(() => {
        setIsUrlExpressionValid(isUrlValid(urlExpression) && urlExpression?.match(/\.(jpeg|jpg|png)$/) != null);
    }, [urlExpression]);

    useEffect(() => {
        if (expressions?.evaluatedExpressions) {
            const expressionValues = Object.values(expressions?.evaluatedExpressions);
            setUrlExpression(expressionValues?.[0] as string);
        }
    }, [expressions]);

    return (
        <ImageWidgetDisplay
            permission={permission}
            altText={widget?.configuration?.image?.altText}
            url={urlExpression}
            isUrlExpressionValid={isUrlExpressionValid}
            originalExpression={widget?.configuration?.image?.url?.originalExpression}
            loadingExpressions={loadingExpressions}
        />
    );
};

export default SolutionSitePageImageWidget;
