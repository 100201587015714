import moment from 'moment';
import React, { useMemo } from 'react';

import { fullDateFormat, shortDateFormats } from '../GridConfiguration/GridTimeFormats';

import { Placeholder, Tooltip } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { DeprecatedDate } from '@tonkean/utils';

interface Props {
    date: string;
    loading: boolean;
    emptyMessage: string;
}

const GridDateStatistic: React.FC<Props> = ({ date, loading, emptyMessage }) => {
    const dateEpochTime = Number.parseInt(date) / 1000;
    const shortDate = useMemo(
        () => moment(dateEpochTime).calendar(DeprecatedDate.nowAsDate(), shortDateFormats),
        [dateEpochTime],
    );
    const longDate = useMemo(() => moment(dateEpochTime).format(fullDateFormat), [dateEpochTime]);

    return (
        <>
            {date && (
                <Tooltip placement="top-start" content={longDate}>
                    <span>{shortDate}</span>
                </Tooltip>
            )}
            {!date && !loading && <span>{emptyMessage}</span>}
            {loading && <Placeholder $height={FontSize.SMALL_12} $width="100px" />}
        </>
    );
};

export default GridDateStatistic;
