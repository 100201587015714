import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';

import FormSettingsSectionHeaderSubtitle from './FormSettingsSectionHeaderSubtitle';
import FormSettingsSectionHeaderTitle from './FormSettingsSectionHeaderTitle';

import { SavingIndicator, Toggle } from '@tonkean/infrastructure';
import { FormBuilderContext } from '@tonkean/infrastructure';
import type { CreateForm } from '@tonkean/tonkean-entities';
import { FormBuilderSections, SavingState } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const TitleWrapper = styled.div`
    margin-top: ${Theme.sizes.formBuilder.standardMarginTop}px;

    display: flex;
    align-items: flex-end;
`;

interface Props {
    form: CreateForm;
    onFormChanged: Dispatch<SetStateAction<CreateForm>>;
}

const FormSettingsSmartSearchEnabled: React.FC<Props> = ({ form, onFormChanged }) => {
    const { editedSections, setSectionLoading } = useContext(FormBuilderContext);

    const onChange = useCallback(
        ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
            if (form) {
                onFormChanged((prevForm) => ({
                    ...prevForm,
                    smartSearchEnabled: checked,
                }));
                setSectionLoading(FormBuilderSections.AIAccess);
            }
        },
        [form, onFormChanged, setSectionLoading],
    );

    return (
        <>
            <TitleWrapper>
                <FormSettingsSectionHeaderTitle>AI Access</FormSettingsSectionHeaderTitle>{' '}
                <SavingIndicator state={editedSections[FormBuilderSections.AIAccess]} />
            </TitleWrapper>
            <FormSettingsSectionHeaderSubtitle>
                Select which AI components will have access to this form.
            </FormSettingsSectionHeaderSubtitle>
            <Toggle
                checked={form.smartSearchEnabled}
                onChange={onChange}
                size={InputSize.SMALL}
                disabled={editedSections[FormBuilderSections.AIAccess] === SavingState.LOADING}
                labelBefore="Enable access via AI Front Door"
            />
        </>
    );
};

export default FormSettingsSmartSearchEnabled;
