import { useAngularService } from 'angulareact';

import useStatesForWorkflowVersion from './useStatesForWorkflowVersion';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface useStatesForWorkflowVersionIdProps {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
}

const useStatesForWorkflowVersionId = ({ workflowVersionId }: useStatesForWorkflowVersionIdProps) => {
    const workflowVersionManager = useAngularService('workflowVersionManager');
    const { data: workflowVersion, loading: loadingWorkflowVersion } = useAsyncMethod(
        workflowVersionManager,
        'getCachedWorkflowVersionOrGetFromServer',
        workflowVersionId,
    );

    return useStatesForWorkflowVersion(workflowVersion);
};

export default useStatesForWorkflowVersionId;
