export function DynamicsModalCtrl(
    $scope,
    $q,
    oauthHandler,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    environment,
    projectManager,
) {
    $scope.data = {
        instanceUri: null,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = state.integration;
        $scope.selected = state.selected || {};

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();
    };

    $scope.connect = function () {
        if ($scope.data.loginModal.$valid) {
            // var uri = $scope.data.uri;
            let instanceUri = $scope.data.instanceUri;

            $scope.error = null;
            $scope.loading = true;

            const redirectUri = environment.integrationKeysMap.dynamicsUri;

            if (instanceUri.indexOf('https://') !== 0 && instanceUri.indexOf('http://') !== 0) {
                instanceUri = `https://${instanceUri}`;
            }

            oauthHandler
                .dynamics(environment.integrationKeysMap.dynamics, instanceUri, redirectUri)
                .then(function (code) {
                    return $q.resolve({ code, redirectUri, instanceUri });
                })
                .then(function (config) {
                    return createProjectApis
                        .createIntegration(
                            projectManager.project.id,
                            $scope.currentIntegration.name,
                            config,
                            integrations.getIntegrationUniqueType(
                                authenticationService.currentUser.id,
                                'DYNAMICS365',
                                $scope.otherIntegrationOwner,
                            ),
                            undefined,
                        )
                        .then(function (integObj) {
                            $scope.integration = integObj;
                        })
                        .catch(function (error) {
                            $scope.error = error;
                        })
                        .finally(function () {
                            $scope.loading = false;
                            const s = {
                                selected: $scope.selected,
                                integration: $scope.integration,
                            };

                            s.integrations = [
                                {
                                    integration: $scope.integration,
                                    projectData: {},
                                },
                            ];

                            if ($scope.onIntegrationClosed) {
                                $scope.onIntegrationClosed(s);
                            }
                        });
                })
                .catch(function (error) {
                    if (error === 'invalid_resource') {
                        $scope.error = `${instanceUri} is invalid`;
                        $scope.loading = false;
                    }
                });
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.selected = {};
    };

    $scope.ok = function () {
        if (!$scope.data.loginModal.$valid) {
            return;
        }

        const s = {
            selected: $scope.selected,
            integration: $scope.integration,
        };

        s.integrations = [
            {
                integration: $scope.integration,
                projectData: {},
            },
        ];

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;
            }
        }
    }
}

export default angular.module('tonkean.shared').controller('DynamicsModalCtrl', DynamicsModalCtrl);
