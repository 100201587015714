<div class="sync-preview-modal common-close-btn-container">
    <!-- Close button -->
    <span class="common-close-btn" ng-click="cancel()">
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Modal title -->
    <h1 class="common-size-l common-bold margin-bottom-lg mod-no-top">Intake source items preview</h1>

    <!-- Sync preview component -->
    <tnk-live-sync
        control="newControl"
        custom-defined-view="customDefinedView"
        existing-definition="viewData"
        project-integration="projectIntegration"
        selected-entity-type="singleEntityType"
        selected-entity-plural-name="pluralEntityType"
        hide-filters="!customDefinedView"
        sync-validity-state="syncValidityState"
        view-type="viewType"
        title-expression="titleExpression"
        default-title-if-expression-empty="defaultTitleIfExpressionEmpty"
        load-preview-flag="data.loadPreviewFlag"
        integration-views="integrationViews"
        override-activate-bot-object="isBotActiveObject"
        hide-bot-in-preview="hideBotInPreview"
    ></tnk-live-sync>

    <div class="flex mod-justify-end margin-top-lg padding-top">
        <button
            type="button"
            class="btn btn-primary-black"
            ng-class="{ 'margin-right-xs': allowSave }"
            ng-hide="previewSyncItems.length > syncItemsLimitation"
            ng-click="cancel()"
        >
            Close
        </button>
        <button
            type="button"
            class="btn btn-primary"
            data-automation="sync-preview-modal-save-button"
            ng-click="save()"
            ng-if="allowSave"
            ng-hide="previewSyncItems.length > syncItemsLimitation"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-primary"
            ng-click="cancel()"
            ng-if="previewSyncItems.length > syncItemsLimitation"
        >
            Edit criteria
        </button>
    </div>
</div>
