<div class="flex-grow enterprise-manageMembers-content">
    <div class="common-title mod-light margin-bottom-lg">Manage Members</div>

    <div class="enterprise-manageMembers-information">
        <p>
            Enterprise Members are the users from across all your Enterprise Boards. To add new members, go to the board
            and invite someone. That person will be added to that specific board, and be added to your seat count.
        </p>
        <p>
            Note: If a person is a member of multiple Boards that are part of the Enterprise license, their seat will
            only be counted once.
        </p>
    </div>

    <div class="padding-top-xlg">
        <span class="common-size-xs common-bold">Enterprise Members</span>
        <span
            class="common-color-grey common-size-xs"
            ng-if="data.totalPeople >= 0"
            ng-class="{ 'common-color-danger': data.totalPeople >= data.enterprise.seats }"
        >
            ({{ data.totalPeople }} / {{ data.enterprise.seats }})
        </span>
    </div>

    <div class="form-group padding-top-lg">
        <label class="sr-only" for="peopleSearch">Search</label>
        <input
            type="search"
            class="form-control"
            id="peopleSearch"
            placeholder="Search current members"
            ng-model="data.peopleSearch"
        />
    </div>

    <table class="enterprise-table table margin-top-lg">
        <thead>
            <tr>
                <th class="padding-left-none">
                    <a
                        ng-click="sort('NAME')"
                        class="inline-block flex-vmiddle common-link-no-style margin-right-xxs"
                        ng-class="{ 'common-color-light-grey2': data.sortField === 'NAME' }"
                    >
                        <span class="margin-right-xxs common-color-grey3 common-bold common-size-xxs">Member Name</span>
                        <i class="common-color-grey3 svg-icon-xs" ng-show="data.sortField === 'NAME' && data.sortDesc">
                            <tnk-icon src="/images/icons/arrow-up.svg"></tnk-icon>
                        </i>
                        <i class="common-color-grey3 svg-icon-xs" ng-show="data.sortField === 'NAME' && !data.sortDesc">
                            <tnk-icon src="/images/icons/arrow-down.svg"></tnk-icon>
                        </i>
                    </a>
                    <i class="loading visible-xs-inline-block" ng-show="data.loadingPeople"></i>
                </th>
                <th class="padding-left-none">
                    <a
                        ng-click="sort('EMAIL')"
                        class="flex-vmiddle common-link-no-style"
                        ng-class="{ 'common-color-light-grey2': data.sortField === 'EMAIL' }"
                    >
                        <span class="margin-right-xxs common-color-grey3 common-bold common-size-xxs">Email</span>
                        <i class="common-color-grey3 svg-icon-xs" ng-show="data.sortField === 'EMAIL' && data.sortDesc">
                            <tnk-icon src="/images/icons/arrow-up.svg"></tnk-icon>
                        </i>
                        <i
                            class="common-color-grey3 svg-icon-xs"
                            ng-show="data.sortField === 'EMAIL' && !data.sortDesc"
                        >
                            <tnk-icon src="/images/icons/arrow-down.svg"></tnk-icon>
                        </i>
                    </a>
                </th>
                <th class="padding-left-none">
                    <a class="flex-vmiddle common-link-no-style cursor-default">
                        <span class="margin-right-xxs common-color-grey3 common-bold common-size-xxs">
                            Number of boards
                        </span>
                    </a>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr ng-repeat="person in data.people">
                <td>
                    <div class="flex-vmiddle avatar-size-xxs name-cell">
                        <tnk-avatar user="person.person" class="margin-right flex-no-shrink"></tnk-avatar>
                        {{ person.person.name }}
                    </div>
                </td>
                <td>
                    <div class="flex-vmiddle email-cell">
                        <div class="flex-grow common-break-long">{{ person.person.email }}</div>
                    </div>
                </td>
                <td>
                    <div class="flex-vmiddle">
                        <span tooltip-placement="auto bottom" uib-tooltip="{{ getProjectsNames(person.projects) }}">
                            On {{ person.projects.length }} board<span ng-if="person.projects.length > 1">s</span>
                        </span>
                    </div>
                </td>
                <td align="right">
                    <div
                        class="btn btn-default common-size-xxxxs common-color-grey"
                        ng-click="removeSeat(person.person,person.projects)"
                        ng-if="data.currentUserId != person.person.id"
                    >
                        Remove
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="text-center" ng-show="data.searchTotal > data.pageSize">
        <uib-pagination
            total-items="data.searchTotal"
            items-per-page="data.pageSize"
            ng-model="data.currentPage"
            class="pagination-sm"
            max-size="7"
            force-ellipses="true"
            previous-text="&lsaquo;"
            next-text="&rsaquo;"
        ></uib-pagination>
    </div>
</div>
