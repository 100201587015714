<div class="margin-left margin-right margin-top">
    <div ng-if="data.definition.customActionKey === 'UPLOAD_DOCUMENT'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            parameters-values="data.definition.parametersValues"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- Document ID -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Document ID:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the upload timestamp will be used"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Document ID"
                preview-evaluation-source="data.previewEvaluationSource"
                expression-unique-identifier="EvisortUploadDocumentId"
                saved-original-expression="data.definition.documentIdExpression.originalExpression"
                saved-evaluated-expression="data.definition.documentIdExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onDocumentIdExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Path -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Upload Path:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the root folder will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                placeholder="Path"
                expression-unique-identifier="EvisortDocumentPath"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.definition.documentPathExpression.originalExpression"
                saved-evaluated-expression="data.definition.documentPathExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onDocumentPathExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Fields Array -->
        <div class="margin-bottom-lg">
            <div ng-if="data.definition.fields.length > 0" class="margin-right margin-top-xs margin-bottom">
                Fields ({{ data.definition.fields.length }}):
            </div>

            <div ng-repeat="(fieldIndex, field) in data.definition.fields" class="flex">
                <div class="margin-left-xs margin-right-xs margin-top">{{ fieldIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Field ID -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">Field Name:</div>

                            <div class="flex-grow"></div>

                            <button
                                class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                ng-click="removeField(fieldIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>

                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            placeholder="Field ID"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.fields[fieldIndex].fieldNameExpression"
                            on-tonkean-expression-changed="
                                onFieldNameTonkeanExpressionChanged(expression, fieldIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Field Values -->
                    <div
                        ng-repeat="(fieldValueIndex, fieldValueObject) in data.definition.fields[fieldIndex].fieldValueObjects"
                        class="flex"
                    >
                        <div class="margin-left-xs margin-right-xs margin-top">
                            Values {{ fieldIndex + 1 }}.{{ fieldValueIndex + 1 }}.
                        </div>
                        <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                            <div class="margin-bottom">
                                <div class="flex-vmiddle">
                                    <div class="margin-right margin-top-xs">Value:</div>

                                    <div class="flex-grow"></div>

                                    <button
                                        class="
                                            svg-icon-xs
                                            common-color-grey
                                            svg-icon-hover-primary
                                            btn btn-no-border btn-on-hover
                                            pointer
                                            flex-no-shrink
                                            margin-left-xxs
                                        "
                                        ng-click="removeFieldValueObject(fieldIndex, fieldValueIndex)"
                                    >
                                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                    </button>
                                </div>

                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    saved-expression="data.definition.fields[fieldIndex].fieldValueObjects[fieldValueIndex].valueExpression"
                                    on-tonkean-expression-changed="
                                        onFieldValueTonkeanExpressionChanged(
                                            expression,
                                            fieldIndex,
                                            fieldValueIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>
                        </div>
                    </div>
                    <a ng-click="addFieldValueObject(fieldIndex)">+ Add Value</a>
                </div>
            </div>
        </div>

        <a ng-click="addField()">+ Add Field</a>

        <div ng-if="data.validationObject.uploadFieldsError" class="common-color-danger">
            {{ data.validationObject.uploadFieldsError }}
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'TRANSFER_DOCUMENT'" class="margin-bottom-xlg">
        <tnk-worker-target-storage-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            should-display-delete-sign="false"
            new-document-name="data.definition.newDocumentName"
            info-message="'Maximum document size is 5MB'"
            on-worker-target-storage-selection-changed="
                onWorkerTargetStorageSelectionChanged(storageIntegrationId, newDocumentName, shouldSaveLogic)
            "
        ></tnk-worker-target-storage-selection>

        <div
            ng-if="data.validationObject.storageIntegrationId"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.storageIntegrationId }}
        </div>

        <!-- Target Path -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Target Path:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the root folder will be used"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                placeholder="Target Path"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.definition.targetPathExpression.originalExpression"
                saved-evaluated-expression="data.definition.targetPathExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onTargetPathTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>

        <!-- Flag to specify if OCR'd version is requested -->
        <div class="delay-configuration-logic margin-top-md">
            <tnk-checkbox
                class="margin-top-xs"
                label="'Get OCR\'d version'"
                value="data.definition.ocr"
                on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>

        <!-- Content type of the document to download -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                Content Type:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Content type (pdf or docx) of the document to download, applicable only when OCR is checked. If missing, the format of the original document is returned."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.customTriggerId }}"
                placeholder="Content Type"
                disabled="!data.definition.ocr"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-original-expression="data.definition.contentTypeExpression.originalExpression"
                saved-evaluated-expression="data.definition.contentTypeExpression.evaluatedExpression"
                on-tonkean-expression-changed="
                    onContentTypeTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
                "
            ></tnk-tonkean-expression>
        </div>
    </div>
</div>
