import React from 'react';
import styled from 'styled-components';

import { ReactComponent as FilterFailIcon } from '../../../../../images/icons/filter-fail.svg';
import { ReactComponent as FilterPassIcon } from '../../../../../images/icons/filter-pass.svg';

import { CustomFilters } from '@tonkean/angular-to-react-components';
import { Message, MessageType } from '@tonkean/infrastructure';
import type { WorkerRunLogic } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

const SummaryMessage = styled(Message)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

interface Props extends StyledComponentsSupportProps {
    workflowVersionId: string;
    configuredLogic: any;
    workerRunLogic: WorkerRunLogic;
}

const ActionInspectCondtions: React.FC<Props> = ({ workflowVersionId, configuredLogic, workerRunLogic }) => {
    const filtersResultMap = workerRunLogic.filtersResultMap || {};
    const summary = workerRunLogic.conditionMet
        ? {
              text: 'Action met the following conditions',
              icon: <FilterPassIcon />,
              messageType: MessageType.SUCCESS,
          }
        : {
              text: 'Action did not meet the following conditions',
              icon: <FilterFailIcon />,
              messageType: MessageType.INFO,
          };

    return (
        <>
            <SummaryMessage icon={summary.icon} type={summary.messageType}>
                {summary.text}
            </SummaryMessage>

            <CustomFilters
                control={{}}
                specialFieldsForFeatures={['CUSTOM_TRIGGER_CONDITIONS']}
                onFiltersChangeDefinition={(definition) => {}}
                itemsSource="COLUMN"
                existingDefinition={configuredLogic.node.queryDefinition}
                workflowVersionId={workflowVersionId}
                logic-id={configuredLogic.node.id}
                filtersResultMap={filtersResultMap}
                evaluatePreview
                editMode // ?
                smallMod
                hideTimeRangeSelection
                boldTitle
                viewOnly
                displayIntegrationIndication
                hideCloseButton
            />
        </>
    );
};

export default ActionInspectCondtions;
