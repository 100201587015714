import type IntegrationIcon from './IntegrationIcon';

/**
 * Represents the settings for the display of an integration icon.
 */
class IntegrationIconsSettings {
    constructor(
        /**
         * The circle icon indication to display for the integration.
         */
        public circledIcon: IntegrationIcon,

        /**
         * The larger icon indication to display for the integration.
         */
        public rectangleIcon: IntegrationIcon,
    ) {}
}

export default IntegrationIconsSettings;
