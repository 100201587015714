import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import FindWordsEditorTestItem from './FindWordsEditorTestItem';
import FormulaContext from '../../../entities/FormulaContext';
import type TestItemConfiguration from '../entities/TestItemConfiguration';

import { useSequentialIdentifier } from '@tonkean/infrastructure';
import type { TextExtractionFieldInformation } from '@tonkean/tonkean-entities';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';

const TestItemContainer = styled.div`
    border: 1px solid #cecece;
    border-radius: 6px;
    padding: 20px;
`;

const TestItemsTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
`;

const TestItemsSubtitle = styled.div`
    font-size: 12px;
    color: #5b636c;
`;

const Divider = styled.hr`
    margin: 20px 0 20px 0;
`;

const TestAnotherItem = styled.button`
    font-weight: 500;
    font-size: 10px;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    color: #2684ff;

    &:hover {
        text-decoration: underline;
    }

    ${styledFocus}
`;

const FeatureWarning = styled.div`
    font-size: 12px;
    color: #f0611e;
    margin-bottom: 15px;
`;

interface Props {
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    fieldDefinitionId?: string;
    textExtractionFieldInformation: TextExtractionFieldInformation;
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    onPreviewFullTextClicked: (initiativeId?: string, expectedValue?: string) => void;
    onFormulaDataChange: (dataToInsert: any) => void;
    indexInTree: string;
}

const FindWordsEditor: React.FC<Props> = ({
    initiativeId,
    groupId,
    workflowVersionId,
    fieldDefinitionId,
    textExtractionFieldInformation,
    onPreviewFullTextClicked,
    onFormulaDataChange,
    indexInTree,
}) => {
    const { formulaDataMap } = useContext(FormulaContext);

    // Get the current maximal identifier between the values in the map to initialize sequentialIdentifier with.
    const maximalUniqueIdentifier = useMemo(() => {
        const maxUniqueIdentifier = formulaDataMap?.get('0')?.reduce(function (prev, current) {
            return prev.uniqueIdentifier > current.uniqueIdentifier ? prev : current;
        }).uniqueIdentifier;

        return maxUniqueIdentifier !== undefined ? maxUniqueIdentifier + 1 : 0;
    }, [formulaDataMap]);

    const { incrementValue } = useSequentialIdentifier(maximalUniqueIdentifier);

    function getDataInitialState() {
        if (formulaDataMap?.get('0') === undefined) {
            return [
                {
                    uniqueIdentifier: incrementValue(),
                    initiativeId,
                },
            ];
        }

        return formulaDataMap.get('0');
    }

    const [testItemConfigurations, setTestItemConfigurations] = useState<TestItemConfiguration[]>(() =>
        getDataInitialState(),
    );

    const onTestItemConfigurationChange = (newTestItemConfiguration: TestItemConfiguration) => {
        const newTestItemConfigurations = testItemConfigurations.map((currentTestItem) => {
            if (currentTestItem.uniqueIdentifier === newTestItemConfiguration.uniqueIdentifier) {
                return newTestItemConfiguration;
            }

            return currentTestItem;
        });

        setTestItemConfigurations(newTestItemConfigurations);
        onFormulaDataChange(newTestItemConfigurations);
    };

    const onTestItemConfigurationDelete = (uniqueIdentifierToDelete: number) => {
        const newTestItemConfigurations = testItemConfigurations.filter(
            (testItemConfigurations) => testItemConfigurations.uniqueIdentifier !== uniqueIdentifierToDelete,
        );

        setTestItemConfigurations(newTestItemConfigurations);
        onFormulaDataChange(newTestItemConfigurations);
    };

    return (
        <>
            <TestItemContainer>
                <FeatureWarning>* Note: This is an experimental feature and is in its alpha version.</FeatureWarning>
                <TestItemsTitle>Test Items</TestItemsTitle>

                <TestItemsSubtitle>
                    Select items you know the text you are looking for to test this formula
                </TestItemsSubtitle>

                <Divider />

                {testItemConfigurations?.map((testItemConfiguration) => {
                    return (
                        <FindWordsEditorTestItem
                            key={testItemConfiguration.uniqueIdentifier}
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                            fieldDefinitionId={fieldDefinitionId}
                            textExtractionFieldInformation={textExtractionFieldInformation}
                            onPreviewFullTextClicked={onPreviewFullTextClicked}
                            testItemConfiguration={testItemConfiguration}
                            onChange={onTestItemConfigurationChange}
                            onDelete={() => onTestItemConfigurationDelete(testItemConfiguration.uniqueIdentifier)}
                            isLastItem={testItemConfigurations.length === 1}
                        />
                    );
                })}

                <TestAnotherItem
                    onClick={(event) => {
                        setTestItemConfigurations([
                            ...testItemConfigurations,
                            {
                                uniqueIdentifier: incrementValue(),
                                initiativeId,
                            },
                        ]);

                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    + Test Another Item
                </TestAnotherItem>
            </TestItemContainer>
        </>
    );
};

export default FindWordsEditor;
