export function GreenhouseModalCtrl(
    $scope,
    tonkeanService,
    createProjectApis,
    $rootScope,
    utils,
    integrations,
    authenticationService,
    projectManager,
) {
    let state;
    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.data.projectIntegration = settings.projectIntegration;
        $scope.integration = state.integration;
        $scope.selected = state.selected || {};

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        tryLoadFromEdit();

        if ($scope.data.projectIntegration) {
            $scope.data.loadingExistingIncomingWebhooks = true;
            $scope.data.errorLoadingExistingIncomingWebhooks = false;

            tonkeanService
                .getIncomingWebhooksByProjectIntegrationId($scope.data.projectIntegration.id)
                .then((data) => {
                    if (data.entities && data.entities.length) {
                        $scope.data.incomingWebhookUrl = data.entities[0].url;
                    }
                })
                .catch(() => {
                    $scope.data.errorLoadingExistingIncomingWebhooks = true;
                })
                .finally(() => {
                    $scope.data.loadingExistingIncomingWebhooks = false;
                });
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    $scope.connect = async function () {
        if ($scope.data.loginModal.$valid) {
            $scope.error = null;
            $scope.loading = true;

            try {
                $scope.integration = await createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    {
                        harvest_api_key: $scope.data.harvestApiKey,
                        job_board_api_key: $scope.data.jobBoardApiKey,
                    },
                    integrations.getNewIntegrationUniqueType(authenticationService.currentUser.id, 'GREENHOUSE'),
                    undefined,
                );
            } catch (error) {
                $scope.error = error;
            } finally {
                $scope.loading = false;
                const s = {
                    selected: $scope.selected,
                    integration: $scope.integration,
                };

                s.integrations = [
                    {
                        integration: $scope.integration,
                        projectData: {},
                    },
                ];

                if ($scope.onIntegrationClosed) {
                    $scope.onIntegrationClosed(s);
                }
            }
        }
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.selected = {};
    };

    $scope.createGreenhouseIncomingWebhook = function () {
        $scope.data.creatingGreenhouseIncomingWebhook = true;
        $scope.data.errorCreatingGreenhouseIncomingWebhook = false;

        tonkeanService
            .createGreenhouseIncomingWebhook(projectManager.project.id, $scope.data.projectIntegration.id)
            .then((data) => {
                if (data.incomingWebhook) {
                    $scope.data.incomingWebhookUrl = data.incomingWebhook.url;
                }
            })
            .catch(() => {
                $scope.data.errorCreatingGreenhouseIncomingWebhook = true;
            })
            .finally(() => {
                $scope.data.creatingGreenhouseIncomingWebhook = false;
            });
    };

    function tryLoadFromEdit() {
        if (state.integrations && !$scope.integration && state.integrations[0] && state.integrations[0].integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                $scope.integration = state.integrations[0].integration;
            }
        }
    }
}

export default angular.module('tonkean.shared').controller('GreenhouseModalCtrl', GreenhouseModalCtrl);
