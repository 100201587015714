import { TrackActions } from '@tonkean/flux';
import lateConstructController from '../../utils/lateConstructController';

/* @ngInject */
function ManageFieldsCtrl(
    $scope,
    $q,
    $rootScope,
    $state,
    $stateParams,
    customFieldsManager,
    projectManager,
    tonkeanService,
    liveReportHelper,
) {
    const ctrl = this;

    $scope.pm = projectManager;
    $scope.cfm = customFieldsManager;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        hideVisibilityButton: ctrl.hideVisibilityButton,
        dndDisabled: ctrl.dndDisabled,
        modeSmall: ctrl.modeSmall,
        saveOnChange: ctrl.saveOnChange,
        targetType: ctrl.targetType,
        createFieldsAsVisible: ctrl.createFieldsAsVisible,
        solutionBusinessReportId: ctrl.solutionBusinessReportId,

        // Map between field id and is loading state
        fieldIsLoadingMap: {},
        // Map between field id and the an array of running saving promises.
        // This is used to ensure that if there are multiple promises running all of them will return before shutting off the is loading state
        fieldSavePromisesMap: {},
        workflowVersionType: $stateParams.env || ctrl.environment || 'PUBLISHED',
    };

    /**
     * Occurs on initialization of the component.
     */
    ctrl.$onInit = function () {
        // Initialize published fields
        const formulaFieldDefinitionTypes = new Set(['TNK_COLUMN_AGGREGATE', 'TNK_COLUMN_FORMULA']);
        $scope.data.fieldDefinitionToIsHiddenMap = angular.copy(getRelevantFieldToIsHiddenMapping()) || [];
        $scope.data.copiedFieldDefinitions = angular
            .copy(customFieldsManager.selectedTargetFieldsMap[$scope.data.workflowVersionId][$scope.data.targetType])
            .filter((field) => !field.systemUtilized)
            .map((fieldDefinition) => ({
                ...fieldDefinition,
                displayType: formulaFieldDefinitionTypes.has(fieldDefinition.type)
                    ? 'Formula'
                    : fieldDefinition.definitionSource,
            }));
    };

    function getRelevantFieldToIsHiddenMapping() {
        if ($scope.data.solutionBusinessReportId) {
            return liveReportHelper.solutionReportsFieldsIsHiddenMap[$scope.data.solutionBusinessReportId];
        }

        return liveReportHelper.fieldsIsHiddenMap[$scope.data.groupId]?.[$scope.data.workflowVersionType];
    }

    function saveSingleField(fieldDefinitionId, savePromise) {
        $scope.data.fieldIsLoadingMap[fieldDefinitionId] = true;
        const promisesMap = $scope.data.fieldSavePromisesMap;
        if (!promisesMap[fieldDefinitionId]) {
            promisesMap[fieldDefinitionId] = [];
        }
        promisesMap[fieldDefinitionId].push(savePromise);
        $scope.saveChanges(savePromise).finally(() => {
            promisesMap[fieldDefinitionId] = promisesMap[fieldDefinitionId].filter(
                (promise) => promise !== savePromise,
            );
            if (promisesMap[fieldDefinitionId].length === 0) {
                $scope.data.fieldIsLoadingMap[fieldDefinitionId] = false;
            }
        });
    }

    $scope.toggleShow = function (fieldDefinition) {
        const newValue = !(
            $scope.data.fieldDefinitionToIsHiddenMap[fieldDefinition.id] ||
            $scope.data.fieldDefinitionToIsHiddenMap[fieldDefinition.id] == null
        );
        $scope.data.fieldDefinitionToIsHiddenMap[fieldDefinition.id] = newValue;

        if ($scope.data.saveOnChange) {
            const savePromise = customFieldsManager.updateFieldIsHidden(
                fieldDefinition.id,
                newValue,
                $scope.data.workflowVersionType,
                $scope.data.groupId,
                $scope.data.workflowVersionId,
                $scope.data.targetType,
                $scope.data.solutionBusinessReportId,
            );

            saveSingleField(fieldDefinition.id, savePromise);
        }
    };

    $scope.repopulateField = function (fieldDefinition) {
        tonkeanService
            .repopulateFieldDefinition($scope.data.workflowVersionId, fieldDefinition.id)
            .then(() => {
                $scope.$emit('alert', { msg: 'Field refreshed successfully', type: 'success' });
            })
            .catch(() => $scope.$emit('alert', { msg: 'Error accord while refreshing Field', type: 'warning' }));
    };

    $scope.saveChanges = function (savePromise) {
        $scope.data.savingSelectedFields = true;
        let promise = savePromise;
        if (!promise) {
            promise = customFieldsManager.rearrangeLiveReportFields(
                $scope.data.groupId,
                $scope.data.copiedFieldDefinitions,
                $scope.data.fieldDefinitionToIsHiddenMap,
                $scope.data.targetType,
                $scope.data.workflowVersionType,
                $scope.data.workflowVersionId,
                $scope.data.solutionBusinessReportId,
            );
        }

        return promise
            .then(() => {
                // Update React's over re arrange of fields.
                TrackActions.fieldDefinitionsUpdated();

                // Broadcast a change t field definition.
                $rootScope.$broadcast('newActivityUpdate');
            })
            .catch((error) => {
                ctrl.controlObject.onSaveChangesErrorOccurred(error.data.error.message);
                return $q.reject(error);
            })
            .finally(() => {
                $scope.data.savingSelectedFields = false;
            });
    };

    /**
     * Fired when an item is moved from its old position within a drag-and-drop event.
     * @param oldIndex - the old original index of the item (before moving it).
     * @param newIndex - the new index of the item (to where should we move it).
     */
    $scope.onColumnMoved = function (oldIndex, newIndex) {
        // Remove the old item and save it so we can add it to its new position.
        const movedItem = $scope.data.copiedFieldDefinitions.splice(oldIndex, 1)[0];
        // If the old index is before the new one, removing the item will cause a shift in 1 position.
        if (oldIndex < newIndex) {
            newIndex = newIndex - 1;
        }

        // Place the item in its new position.
        $scope.data.copiedFieldDefinitions.splice(newIndex, 0, movedItem);

        if ($scope.data.saveOnChange) {
            const movedRight = oldIndex - newIndex < 0;
            let savePromise;
            const fieldDefinitionId = movedItem.id;
            const targetType = movedItem.targetType;
            const amount = Math.abs(oldIndex - newIndex);
            const groupId = $scope.data.groupId;
            if (movedRight) {
                savePromise = customFieldsManager.moveFieldRight(
                    groupId,
                    fieldDefinitionId,
                    targetType,
                    $scope.data.workflowVersionType,
                    amount,
                    $scope.data.solutionBusinessReportId,
                );
            } else {
                savePromise = customFieldsManager.moveFieldLeft(
                    groupId,
                    fieldDefinitionId,
                    targetType,
                    $scope.data.workflowVersionType,
                    amount,
                    $scope.data.solutionBusinessReportId,
                );
            }

            saveSingleField(movedItem.id, savePromise);
        }
    };

    /**
     * Go to Module editor.
     */
    $scope.goToBotEditor = function () {
        $state.go('product.workerEditor', { g: $scope.data.groupId, isFromList: true, t: 'fields' });

        if (ctrl.onClose) {
            ctrl.onClose({});
        }
    };

    ctrl.controlObject.saveChanges = $scope.saveChanges;
}

angular.module('tonkean.app').controller('ManageFieldsCtrl', lateConstructController(ManageFieldsCtrl));
