import React from 'react';

import { Placeholder, PlaceholderGrid, PlaceholderGridDirection } from '@tonkean/infrastructure';

function getTrainingSetAsTableRowWithState() {
    return {
        name: <Placeholder $width="100%" />,
        updated: <Placeholder $width="100%" />,
        solutions: <Placeholder $width="100%" />,
        status: <Placeholder $width="100%" />,
        access: (
            <PlaceholderGrid direction={PlaceholderGridDirection.COLUMNS} gap="8px">
                <Placeholder $width="40px" />
                <Placeholder $width="40px" />
            </PlaceholderGrid>
        ),
    };
}

export default getTrainingSetAsTableRowWithState;
