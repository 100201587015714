import { useAngularService } from 'angulareact';
import { useMemo, useState } from 'react';

import useFetchManager from './useFetchManager';
import useIsTabActive from './useIsTabActive/useIsTabActive';

import type { Initiative, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useInitiatives = (
    limitGalleryView: number,
    reloadInterval: number,
    projectId?: TonkeanId<TonkeanType.PROJECT>,
    sort?: { key: 'created'; desc?: boolean },
) => {
    const [totalInitiatives, setTotalInitiatives] = useState<number>();

    const isTabActive = useIsTabActive();

    const tonkeanService = useAngularService('tonkeanService');

    const trackHelper = useAngularService('trackHelper');

    const [[searchInitiatives], { data: initiatives, loading, loadNextPage, hasMorePages, executionDate, isFetched }] =
        useFetchManager(tonkeanService, 'searchInitiatives', {
            getItems: (result: { entities: { id: string; [key: string]: any }[]; hasMoreEntities: boolean }) =>
                result.entities as Initiative[],
            onLoaded(result: {
                entities: { id: string; [key: string]: any }[];
                hasMoreEntities: boolean;
                totalHits: number;
            }) {
                trackHelper.getRelatedInitiativesCount(
                    projectId!,
                    result.entities.map(({ id }) => id),
                );
                setTotalInitiatives(result.totalHits);
            },
            checkHasMore: (result: { entities: { id: string; [key: string]: any }[]; hasMoreEntities: boolean }) => {
                return result.hasMoreEntities;
            },
            limit: limitGalleryView,
            autoReloadInterval: isTabActive ? reloadInterval : undefined,
            sort,
        });

    const nonArchivedInitiatives = useMemo(() => {
        return initiatives.filter((initiative) => !initiative.isArchived);
    }, [initiatives]);

    return [
        searchInitiatives,
        {
            initiatives: nonArchivedInitiatives,
            loading,
            loadNextPage,
            hasMorePages,
            totalInitiatives,
            executionDate,
            isFetched,
        },
    ] as const;
};

export default useInitiatives;
