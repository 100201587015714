import template from './tnkFieldToUpdateEditor.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkFieldToUpdateEditor', {
    bindings: {
        fieldSelectorGroupId: '@',
        fieldSelectorWorkflowVersionId: '@',
        expressionGroupId: '@',
        expressionWorkflowVersionId: '@',
        isPreselectedField: '@',
        preselectedFieldLabel: '@',
        preselectedFieldId: '@',
        configuredField: '<',
        reloadFields: '<',
        specialFieldsForFeatures: '<',
        logicId: '@',
        previewEvaluationSource: '<',

        onConfiguredFieldChanged: '&',
        callbackParam: '<',

        onFieldRemoved: '&',
        onFieldRemovedCallbackParam: '<',
        doNotIncludeFieldIdsSet: '<',
        shouldHideDeleteButton: '<',
        globalExpressionOnly: '<',
    },
    template,
    controller: 'FieldToUpdateEditorCtrl',
});
