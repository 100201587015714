import SolutionSitePageIncludeInterfaceWidget from './components/SolutionSitePageIncludeInterfaceWidget';
import SolutionSitePageIncludeInterfaceWidgetEditor from './components/SolutionSitePageIncludeInterfaceWidgetEditor';
import type { SolutionSitePageIncludeInterfaceWidgetConfiguration } from './SolutionSitePageIncludeInterfaceWidgetConfiguration';
import type { SolutionSitePageWidgetPackage } from '../../WidgetModule';

export const SolutionSitePageIncludeInterfaceWidgetPackage: SolutionSitePageWidgetPackage<SolutionSitePageIncludeInterfaceWidgetConfiguration> =
    {
        displayComponent: SolutionSitePageIncludeInterfaceWidget,
        configurationComponent: SolutionSitePageIncludeInterfaceWidgetEditor,
    };
