<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <div class="margin-bottom-lg info-box common-size-xxs">
                <!--<i class="fa fa-info-circle margin-right"></i>-->
                <p class="common-color-dark-grey">Setup instructions:</p>
                <div class="common-color-dark-grey">
                    1. Log into your Kanbanize and click your avatar in the top right of the app.
                    <br />
                    2. Click on "API"
                    <br />
                    3. Copy the API Key to this window.
                    <br />
                    4. To finish please click save.
                    <br />
                </div>
            </div>
            <div class="form-group">
                <label for="login-uri" class="col-sm-3 control-label">Kanbanize URL</label>
                <div class="col-sm-9">
                    <input
                        type="text"
                        ng-model="data.appUri"
                        class="form-control"
                        id="login-uri"
                        placeholder="URL used to get to your Kanbanize homepage"
                        autocomplete="off"
                        required
                    />
                    <div class="common-subtitle-inner"><i>Example: https://your-company.kanbanize.com</i></div>
                </div>
            </div>
            <div class="form-group">
                <label for="login-api-key" class="col-sm-3 control-label">API Key</label>

                <div class="col-sm-9">
                    <input
                        type="text"
                        ng-model="data.key"
                        class="form-control"
                        id="login-api-key"
                        autocomplete="off"
                        placeholder="API Key"
                        required
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7"></div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>

        <h4>Which Kanbanize projects are relevant to this team?</h4>
        <hr />
        <div ng-show="selectedProjects.length">
            <div ng-repeat="selected in selectedProjects">
                <div class="panel panel-default">
                    <div class="panel-heading flex-vmiddle">
                        <h4 class="flex-grow">
                            {{ selected.project.displayName }}
                            <i class="fa fa-check common-color-secondary margin-left"></i>
                        </h4>
                        <button
                            type="button"
                            class="btn btn-default btn-sm pull-right"
                            analytics-on
                            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                            analytics-event="Delete Kanbanize proj"
                            analytics-label="{{ currentIntegration.name }}"
                            ng-click="removeProject($index)"
                        >
                            Remove
                        </button>
                    </div>
                    <div class="panel-body">
                        <div>All Boards, Initiatives and Cards in this project will be analyzed.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-vmiddle" ng-hide="filteredProjects.length === 0">
            <div class="flex-grow margin-right">
                <ui-select
                    theme="bootstrap"
                    ng-model="temp.project"
                    ng-disabled="addingProject||filteredProjects.length===0"
                >
                    <ui-select-match placeholder="Select a Project">{{ $select.selected.displayName }}</ui-select-match>
                    <ui-select-choices repeat="p in filteredProjects | filter:$select.search">
                        <div ng-bind-html="p.displayName | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>
        </div>

        <div ng-if="projectIntegration">
            <a ng-click="modalUtils.openMapIntegrationUsersModal(projectIntegration)">Map Kanbanize users..</a>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
