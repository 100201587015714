import { angularToReact } from '@tonkean/angular-components';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const TnkPreviewListColumns = angularToReact<{
    groupId: TonkeanId<TonkeanType.GROUP>;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    definitionsToPreview: any[];
    existingFieldDefinitionId: `FIDE${string}` | `TNK_${string}` | undefined;
    highlightedDefinitionId?: any;
    highlightedFieldDefinitions?: any;
    globalFieldsToPreview?: any;
    hidePreviewTitle?: boolean;
    reloadPreview?: boolean;
    softLoading?: boolean;
    isBrokenIntegration?: boolean;
    filterInitiativesByTonkeanQuery?: any;
    emptyStateType?: any;
}>('tnk-preview-list-columns');

export default TnkPreviewListColumns;
