<div class="margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'UPDATE_EMAIL_THREAD'" class="margin-bottom-xlg">

        <div class="margin-bottom">
            <div ng-if="data.definition.newFields.length > 0" class="margin-right margin-top-xs margin-bottom">
                Email Thread Fields ({{ data.definition.newFields.length }}):
            </div>

            <div ng-repeat="(newFieldIndex, newField) in data.definition.newFields"
                 class="flex">
                <div class="margin-left-xs margin-right-xs margin-top">{{ newFieldIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Field Key -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">Field Key:</div>

                            <div class="flex-grow"></div>

                            <button
                                    class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                    ng-click="removeNewField(newFieldIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.newFields[newFieldIndex].fieldKeyExpression"
                                on-tonkean-expression-changed="
                                onFieldKeyTonkeanExpressionChanged(expression, newFieldIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Field Value -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Field Value:</div>
                        <tnk-tonkean-expression
                                class="flex-grow"
                                group-id="{{ data.groupId }}"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                logic-id="{{ data.configuredLogic.node.id }}"
                                preview-evaluation-source="data.previewEvaluationSource"
                                saved-expression="data.definition.newFields[newFieldIndex].fieldValueExpression"
                                on-tonkean-expression-changed="
                                onFieldValueTonkeanExpressionChanged(expression, newFieldIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>
                </div>
            </div>

            <a ng-click="addNewField()">+ Add New Field</a>
            <div ng-if="data.validationObject.newFieldsError" class="common-color-danger">
                {{ data.validationObject.newFieldsError }}
            </div>
        </div>
    </div>
</div>
