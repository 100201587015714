import type NetworkService from '../NetworkService';

import type { TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';

const URLS = {
    getWorkflowVersion: (workflowVersionid: TonkeanId<TonkeanType.WORKFLOW_VERSION>) =>
        `workflowVersion/${workflowVersionid}`,
    getWorkflowVersionByInitiativeId: (
        projectId: TonkeanId<TonkeanType.PROJECT>,
        initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
    ) => `project/${projectId}/initiative/${initiativeId}/workflowVersion`,
};

async function getWorkflowVersion(
    networkService: NetworkService,
    workflowVersionid: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
): Promise<WorkflowVersion> {
    return networkService.getRequest<WorkflowVersion>({
        url: URLS.getWorkflowVersion(workflowVersionid),
    });
}

async function getWorkflowVersionByInitiativeId(
    networkService: NetworkService,
    projectId: TonkeanId<TonkeanType.PROJECT>,
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>,
): Promise<WorkflowVersion> {
    const result = await networkService.getRequest<{ workflowVersion: WorkflowVersion }>({
        url: URLS.getWorkflowVersionByInitiativeId(projectId, initiativeId),
    });

    return result.workflowVersion;
}

export { getWorkflowVersion, getWorkflowVersionByInitiativeId };
