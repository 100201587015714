import { analyticsWrapper } from '@tonkean/analytics';

function ConnectFieldManuallyCtrl(
    $scope,
    $rootScope,
    $uibModalInstance,
    modalUtils,
    tonkeanService,
    trackHelper,
    utils,
    field,
    groupId,
    initiative,
) {
    $scope.pm = $rootScope.pm;
    $scope.modalUtils = modalUtils;
    $scope.forms = {};

    $scope.data = {
        currentType: 'SQL',
        field: field || {},
        groupId,
        filter: {},
        initiative,
        integrationType: '',
    };

    $scope.init = function () {
        $scope.posting = false;
        $scope.loading = true;
        $scope.editing = false;
        $scope.fieldNameTitle = $scope.data.field.fieldDefinition.name;

        if (
            $scope.data.field.fieldDefinition.projectIntegration &&
            $scope.data.field.fieldDefinition.projectIntegration.integration
        ) {
            $scope.data.integration = $scope.data.field.fieldDefinition.projectIntegration.integration;
            $scope.data.filter.source = $scope.data.integration.integrationType.toLowerCase();
        } else if ($scope.data.field.fieldDefinition.definition.integrationType) {
            $scope.data.filter.source = $scope.data.field.fieldDefinition.definition.integrationType.toLowerCase();
        }

        $scope.data.currentType = $scope.data.field.fieldDefinition.type;
        $scope.data.filter.externalType = $scope.data.field.fieldDefinition.definition.ExternalType;

        if ($scope.data.field.externalId) {
            // Give the external connected item the id as a start.
            $scope.data.connectedItem = { id: $scope.data.field.externalId };

            if ($scope.data.currentType === 'EXTERNAL' && $scope.data.field.fieldDefinition.projectIntegration) {
                // Try to get the actual external item.
                tonkeanService
                    .getExternalActivityById(
                        $scope.pm.project.id,
                        $scope.data.field.fieldDefinition.projectIntegration.id,
                        $scope.data.field.externalId,
                    )
                    .then(function (externalItem) {
                        $scope.data.connectedItem = externalItem;
                    })
                    .finally(function () {
                        $scope.loading = false;
                    });
            } else {
                $scope.loading = false;
            }
        } else {
            $scope.loading = false;
        }
    };

    $scope.enterEditMode = function () {
        $scope.editing = true;
    };

    $scope.submit = function () {
        $scope.posting = true;

        // Update the reference of the given field so that the user clicked save.
        field = $scope.data.field;

        const emitMessageSuffix =
            $scope.data.fieldDefinitionType !== 'MANUAL' ? 'Loading data, this may take a few seconds...' : '';

        if (field.fieldDefinition.type !== 'MANUAL') {
            field.externalId = $scope.data.item.id;
        }

        analyticsWrapper.track('Connect field manually', { category: 'Connect Field Modal' });

        trackHelper
            .updateInitiativeDataTile($scope.data.initiative.id, field, null, field.externalId)
            .then(function (updatedField) {
                $scope.posting = false;
                if (updatedField.isAssociated) {
                    $scope.$emit('alert', {
                        msg: `Field "${$scope.data.field.fieldDefinition.name}" was connected successfully! ${emitMessageSuffix}`,
                        type: 'success',
                    });
                } else {
                    $scope.$emit('alert', {
                        msg: `Couldn't find match for field "${$scope.data.field.fieldDefinition.name}"`,
                        type: 'warning',
                    });
                }
                utils.copyEntityFields(updatedField, field);
                $uibModalInstance.close(updatedField);
            })
            .catch(function (error) {
                $scope.error = error;
                $scope.posting = false;
            });
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('ConnectFieldManuallyCtrl', ConnectFieldManuallyCtrl);
