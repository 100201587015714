import React from 'react';

import type BaseProjectIntegrationEntityProps from '../../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import type { AdditionalSidePaneConfiguration } from '../../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ProjectIntegrationEntityGenericSplitPageWithRequest from '../ProjectIntegrationEntityGenericSplitPageWithRequest';
import HandleResponseActionType from '../ProjectIntegrationEntitySchema/HandleResponseActionType';

const ProjectIntegrationEntityMetadataConfigurePage: React.FC<
    AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps>
> = ({ entity, ...props }) => {
    return (
        <ProjectIntegrationEntityGenericSplitPageWithRequest
            projectIntegrationActionId={entity.metadataApiDefinition?.metadataApiActionId}
            httpRequestConfigurationHeaderText="Field Metadata"
            requestUrlHeader="API Path To Collect Metadata From"
            entity={entity}
            labelPrefix="Field Metadata"
            description="Configure the API call Tonkean sends to collect field metadata."
            handleResponseActionType={HandleResponseActionType.FIELD_METADATA}
            {...props}
        />
    );
};

export default ProjectIntegrationEntityMetadataConfigurePage;
