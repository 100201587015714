import isValidWorkflowDataStorageConfiguration from '../isValidWorkflowDataStorageConfiguration';
import validateFormBeforePublish from '../validateFormBeforePublish';

import utils from '@tonkean/utils';

/**
 * Validates that the properties we passed to the tnk-worker-file-selection
 * component are valid.
 * If errors exists, we will write them on the validationObject
 * in the 'errorMessage' property.
 */
export const validateFileSelectionComponent = (
    workflowVersionInfoRetriever,
    syncConfigCacheManager,
    group,
    validationObject,
    {
        fileSourceType,
        url,
        extendedMatchConfiguration,
        storageIntegrationId,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
    },
    projectManager,
) => {
    // Validating URL expression.
    if (fileSourceType === 'URL' && !url.evaluatedExpression) {
        validationObject.errorMessage = 'URL is required.';
    }

    // Validating upload file configuration.
    if (fileSourceType === 'UPLOAD_FILE' && (!workerStaticAssetId || !workerStaticAssetDisplayName)) {
        validationObject.errorMessage = 'Uploaded file details are invalid.';
    }

    if (fileSourceType === 'DATA_STORAGE' && !storageIntegrationId) {
        validationObject.errorMessage = 'Data storage is required.';
    }

    // Validating data storage expression value.
    if (
        fileSourceType === 'DATA_STORAGE' &&
        extendedMatchConfiguration?.extendedMatchConfigurationType === 'EXPRESSION' &&
        (!storageIntegrationId || !extendedMatchConfiguration?.uiExpression?.evaluatedExpression)
    ) {
        validationObject.errorMessage = 'File ID is required.';
    }

    if (
        !isValidWorkflowDataStorageConfiguration(
            { fileSourceType, extendedMatchConfiguration, storageIntegrationId },
            group,
            workflowVersionInfoRetriever,
            syncConfigCacheManager,
            projectManager,
        )
    ) {
        validationObject.errorMessage =
            'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
    }
};

export const validateIntercomCustomActions = (
    validationObject,
    definition,
    integrationsConsts,
    group,
    logicBlockConfigsFormInfoRetriever,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    const doesNotRequireActionPerformer =
        integrationsConsts.getActionsByIntegrationsMap()['INTERCOM'].customActions[
            performedActionDefinition.customActionKey
        ].doesNotRequireActionPerformer;

    const admin = performedActionDefinition.admin;
    if (
        !doesNotRequireActionPerformer &&
        (!admin ||
            (!admin.personEmailFieldDefinitionId &&
                !admin.personEmailExpressionDefinition &&
                !admin.adminIds?.length &&
                !admin.customInput?.length &&
                !admin.initiativeOwner &&
                !admin.previousActor &&
                !admin.useNone))
    ) {
        validationObject.noAdmin = 'Must have an admin';
    }

    const form = logicBlockConfigsFormInfoRetriever.getWorkerFormFromCache(
        group.draftWorkflowVersionId,
        definition.performedActionDefinition?.formId,
    );
    validateFormBeforePublish(validationObject, form);

    switch (performedActionDefinition.customActionKey) {
        case 'ASSIGN_CONVERSATION':
            const assignee = performedActionDefinition.assignee;
            if (
                !assignee ||
                (!assignee.personEmailExpressionDefinition &&
                    !assignee.personEmailFieldDefinitionId &&
                    !assignee.assigneeIds?.length &&
                    !assignee.initiativeOwner &&
                    !assignee.previousActor &&
                    !assignee.useNone)
            ) {
                validationObject.noAssignee = 'Must have an assignee';
            }

            break;
        case 'SNOOZE_CONVERSATION':
            if (!performedActionDefinition.timeToSnoozeInMinutes) {
                validationObject.noSnooze = 'Must have a snooze duration';
            }
            break;
        case 'REPLY_CONVERSATION':
        case 'CREATE_NOTE':
            if (!performedActionDefinition.text) {
                validationObject.noText = 'Must have a text';
            }
            break;

        case 'START_CONVERSATION':
            if (utils.isNullOrEmpty(performedActionDefinition.recipientEntityIdType)) {
                validationObject.noRecipientEntityIdType = 'Must select the recipient content type';
            }

            if (
                !performedActionDefinition.recipientEntityExpression ||
                utils.isNullOrEmpty(performedActionDefinition.recipientEntityExpression.evaluatedExpression)
            ) {
                validationObject.noRecipient = 'Must have Recipient';
            }

            if (utils.isNullOrEmpty(performedActionDefinition.recipientType)) {
                validationObject.noRecipientType = 'Must select the recipient type';
            }

            if (utils.isNullOrEmpty(performedActionDefinition.messageType)) {
                validationObject.noMessageType = 'Must select the message type';
            }

            if (
                !performedActionDefinition.bodyExpression ||
                utils.isNullOrEmpty(performedActionDefinition.bodyExpression.evaluatedExpression)
            ) {
                validationObject.noBody = 'Must have a Message Body';
            }

            const isSubjectEmpty =
                !performedActionDefinition.subjectExpression ||
                utils.isNullOrEmpty(performedActionDefinition.subjectExpression.evaluatedExpression);
            const isEmail =
                !utils.isNullOrEmpty(performedActionDefinition.messageType) &&
                performedActionDefinition.messageType === 'EMAIL';
            if (isEmail && isSubjectEmpty) {
                validationObject.noSubject = 'Must have a Subject when the message is an Email';
            }

            break;
        case 'TAG_USER':
            if (performedActionDefinition.evaluatedTagText === '') {
                validationObject.emptyTagWarning = 'Tag must have a text';
            }
            break;
        case 'ARCHIVE_USER':
            if (!performedActionDefinition.archiveUserIdType) {
                validationObject.emptyUserIdType = 'Match user by must be chosen';
            }
            break;
        case 'TAG_COMPANY':
            if (performedActionDefinition.evaluatedTagText === '') {
                validationObject.emptyTagWarning = 'Tag must have a text';
            }
            break;
        case 'TAG_LEAD':
            if (performedActionDefinition.evaluatedTagText === '') {
                validationObject.emptyTagWarning = 'Tag must have a text';
            }
            break;
        case 'UNTAG_USER':
            if (performedActionDefinition.evaluatedTagText === '') {
                validationObject.emptyTagWarning = 'Tag must have a text';
            }
            break;
        case 'UNTAG_COMPANY':
            if (performedActionDefinition.evaluatedTagText === '') {
                validationObject.emptyTagWarning = 'Tag must have a text';
            }
            break;
        case 'UNTAG_LEAD':
            if (performedActionDefinition.evaluatedTagText === '') {
                validationObject.emptyTagWarning = 'Tag must have a text';
            }
            break;

        case 'CREATE_USER':
            const isUserIdFilled =
                performedActionDefinition.userIdExpression?.evaluatedExpression &&
                !utils.isNullOrEmpty(performedActionDefinition.userIdExpression.evaluatedExpression);
            const isEmailFilled =
                performedActionDefinition.emailExpression?.evaluatedExpression &&
                !utils.isNullOrEmpty(performedActionDefinition.emailExpression.evaluatedExpression);

            // Check if both of them are undefined or empty
            if (!(isUserIdFilled || isEmailFilled)) {
                validationObject.atLeastEmailOrUserIdWarning = 'At least one of user id or email must be filled';
            }
            break;

        case 'UPDATE_CUSTOM_ATTRIBUTES':
            let anyNonNullCustomAttribute = false;

            if (performedActionDefinition.customAttributes?.length) {
                for (let i = 0; i < performedActionDefinition.customAttributes.length; i++) {
                    const customAttribute = performedActionDefinition.customAttributes[i];

                    if (
                        customAttribute.keyExpression?.evaluatedExpression ||
                        customAttribute.valueExpression?.evaluatedExpression
                    ) {
                        anyNonNullCustomAttribute = true;
                        break;
                    }
                }
            }

            if (!anyNonNullCustomAttribute) {
                validationObject.noCustomAttributes = 'You must fill at least one custom attribute.';
            }
            break;

        case 'REDACT_MESSAGE_FROM_CONVERSATION':
            if (
                !performedActionDefinition.messageIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.messageIdExpression.originalExpression)
            ) {
                validationObject.messageIDMissingError = 'Message ID must be filled.';
            }
            break;
    }
};

export const validateFrontappCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;
    let teammate;
    switch (performedActionDefinition.customActionKey) {
        case 'SET_ASSIGNEE':
            break;

        case 'REPLY_CONVERSATION':
            if (!performedActionDefinition.text) {
                validationObject.noText = 'Must have a text';
            }
            teammate = performedActionDefinition.teammate;
            if (
                !teammate ||
                (!teammate.personEmailExpressionDefinition &&
                    !teammate.personEmailFieldDefinitionId &&
                    !teammate.assigneeIds?.length &&
                    !teammate.initiativeOwner &&
                    !teammate.previousActor &&
                    !teammate.useNone &&
                    !teammate.personIds?.length)
            ) {
                validationObject.noReplier = 'Must have a replier';
            }
            break;
    }
};

export const validateZendeskCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'UPLOAD_ATTACHMENT_TO_TICKET':
        case 'CREATE_COMMENT_ON_TICKET':
            if (!performedActionDefinition.comment) {
                validationObject.noComment = 'Must have a comment';
            }

            if (!performedActionDefinition.author) {
                validationObject.noAuthor = 'Must have an author';
            }

            break;
        case 'ADD_TAG_ON_TICKET':
        case 'REMOVE_TAG_FROM_TICKET':
            if (!performedActionDefinition.tags?.length) {
                validationObject.noTags = 'Must have at least 1 tag';
            }

            break;
    }
};

export const validateGoogleCloudStorageCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    syncConfigCacheManager,
    group,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;
    switch (performedActionDefinition.customActionKey) {
        case 'UPLOAD_FILE':
            validateFileSelectionComponent(
                workflowVersionInfoRetriever,
                syncConfigCacheManager,
                group,
                validationObject,
                performedActionDefinition.fileSourceConfiguration,
                projectManager,
            );
            break;
    }
};

export const validateGoogleSheetsCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    syncConfigCacheManager,
    group,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;
    switch (performedActionDefinition.customActionKey) {
        case 'DELETE_ROW': {
            const actionProjectIntegration = projectManager.getProjectIntegrationById(definition.projectIntegrationId);
            const googleSheetUsageMethod =
                actionProjectIntegration.projectData.projectDatas[0].config.spreadsheetUsageMethodType;
            if (googleSheetUsageMethod === 'APPEND') {
                validationObject.appendDeleteError =
                    'Delete action is not supported for append usage method. Review our documentation for google sheets for more information.';
            }
            break;
        }
    }
};

export const validateGoogleDriveCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    syncConfigCacheManager,
    group,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'COMMENT_ON_FILE':
            if (!performedActionDefinition.evaluatedContent) {
                validationObject.noContent = 'Must have a comment';
            }

            break;

        case 'SHARE_FILE':
        case 'SHARE_FOLDER':
            if (!performedActionDefinition.evaluatedEmail) {
                validationObject.noEmail = 'Must have an Email';
            }

            if (!performedActionDefinition.role) {
                validationObject.noRole = 'Must have a Role Selected';
            }

            break;

        case 'GENERATE_PDF':
            if (utils.isNullOrEmpty(performedActionDefinition.destinationFolderId)) {
                validationObject.noDestinationFolderId = 'Destination Folder (ID) Is a Must';
            }
            break;

        case 'CREATE_DOC_FROM_TEMPLATE':
            if (
                !performedActionDefinition.templateIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.templateIdExpression.evaluatedExpression)
            ) {
                validationObject.noTemplateDocId = 'Template Doc/Slide ID is a must';
            }
            break;

        case 'UPLOAD_FILE_FROM_URL':
            if (
                !performedActionDefinition.fileNameExpresssion ||
                utils.isNullOrEmpty(performedActionDefinition.fileNameExpresssion.evaluatedExpression)
            ) {
                validationObject.noFileName = 'Must have a file name';
            }

            if (performedActionDefinition.attachment) {
                validateFileSelectionComponent(
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    group,
                    validationObject,
                    performedActionDefinition.attachment,
                    projectManager,
                );
            } else if (
                !performedActionDefinition.urlExpression ||
                utils.isNullOrEmpty(performedActionDefinition.urlExpression.evaluatedExpression)
            ) {
                validationObject.errorMessage = 'URL is required.';
            }
            break;
    }
};

export const validateTwilioChatCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    if (
        performedActionDefinition.customActionKey === 'REPLY_TO_CHANNEL' ||
        performedActionDefinition.customActionKey === 'ADD_OR_UPDATE_CHANNEL_ATTRIBUTES'
    ) {
        if (!performedActionDefinition.serviceIdExpression?.evaluatedExpression) {
            validationObject.noService = 'Must have a target Service ID';
        }

        if (!performedActionDefinition.channelIdExpression?.evaluatedExpression) {
            validationObject.noChannel = 'Must have a target Channel ID';
        }
    } else if (performedActionDefinition.customActionKey === 'ADD_OR_UPDATE_TASK_ATTRIBUTES') {
        if (!performedActionDefinition.workspaceIdExpression?.evaluatedExpression) {
            validationObject.noWorkspace = 'Must have a target Workspace ID';
        }

        if (!performedActionDefinition.taskIdExpression?.evaluatedExpression) {
            validationObject.noTask = 'Must have a target Task ID';
        }
    }
};

export const validateDropboxCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'CREATE_FOLDER':
            validateDropboxFolderName(performedActionDefinition, validationObject);

            break;

        case 'CREATE_SHARED_FOLDER':
            validateDropboxFolderName(performedActionDefinition, validationObject);

            if (
                !performedActionDefinition.members?.length ||
                !performedActionDefinition.members.filter((member) => member.emailExpression?.evaluatedExpression)
                    .length
            ) {
                validationObject.noMembers = 'Must have at least one member to share with';
            }
            break;

        case 'COPY_FOLDER':
            validateDropboxFolderName(performedActionDefinition, validationObject);

            if (!performedActionDefinition.pathFromExpression?.evaluatedExpression) {
                validationObject.noFromPath = 'Must have a source folder to copy';
            }
            break;
    }
};

export const validateDropboxFolderName = (performedActionDefinition, validationObject) => {
    if (!performedActionDefinition.folderNameExpression?.evaluatedExpression) {
        validationObject.noFolderName = 'Must have a folder name';
    }
};

export const validateGmailCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'SEND_EMAIL':
            if (performedActionDefinition.recipientsConfiguration) {
                const recipientsConfiguration = performedActionDefinition.recipientsConfiguration;

                if (
                    !recipientsConfiguration.initiativeOwner &&
                    !recipientsConfiguration.specificPeopleIds?.length &&
                    (!recipientsConfiguration.personEmailExpressionDefinition ||
                        utils.isNullOrEmpty(
                            recipientsConfiguration.personEmailExpressionDefinition.originalExpression,
                        ) ||
                        utils.isNullOrEmpty(
                            recipientsConfiguration.personEmailExpressionDefinition.evaluatedExpression,
                        ))
                ) {
                    validationObject.noToRecipients = 'Must have at least one recipient';
                }
            }

            break;
    }
};

export const validateSendGridCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'SEND_EMAIL':
            if (performedActionDefinition.recipientsConfiguration) {
                const recipientsConfiguration = performedActionDefinition.recipientsConfiguration;

                if (
                    !recipientsConfiguration.initiativeOwner &&
                    !recipientsConfiguration.specificPeopleIds?.length &&
                    (!recipientsConfiguration.personEmailExpressionDefinition ||
                        utils.isNullOrEmpty(
                            recipientsConfiguration.personEmailExpressionDefinition.originalExpression,
                        ) ||
                        utils.isNullOrEmpty(
                            recipientsConfiguration.personEmailExpressionDefinition.evaluatedExpression,
                        ))
                ) {
                    validationObject.noToRecipients = 'Must have at least one recipient';
                }
            }

            if (
                utils.isNullOrEmpty(performedActionDefinition.fromEmail) ||
                utils.isNullOrEmpty(performedActionDefinition.fromEmailEvaluatedText)
            ) {
                validationObject.noFromAddress = 'Must have a From email address.';
            }

            if (
                utils.isNullOrEmpty(performedActionDefinition.emailSubject) ||
                utils.isNullOrEmpty(performedActionDefinition.emailSubjectEvaluatedText)
            ) {
                validationObject.noSubject = 'Must have a subject.';
            }

            break;
    }
};

export const validateAsanaCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'DUPLICATE_TASK':
            if (performedActionDefinition.duplicateTaskName === '') {
                validationObject.emptyTaskName = 'Must fill a task name';
            }
            break;

        case 'DUPLICATE_PROJECT':
            if (
                !performedActionDefinition.newProjectNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.newProjectNameExpression.originalExpression)
            ) {
                validationObject.emptyProjectName = 'Must fill a project name';
            }
            break;

        case 'UPLOAD_ATTACHMENT':
            if (
                !isValidWorkflowDataStorageConfiguration(
                    performedActionDefinition,
                    group,
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    projectManager,
                )
            ) {
                validationObject.invalidFileChoice =
                    'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
            }
            break;
    }
};

export const validateDocusignCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'UPLOAD_ATTACHMENT':
            if (
                !isValidWorkflowDataStorageConfiguration(
                    performedActionDefinition,
                    group,
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    projectManager,
                )
            ) {
                validationObject.invalidFileChoice =
                    'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
            }
            break;
        case 'TRANSFER_DOCUMENT':
            if (!performedActionDefinition.storageIntegrationId?.length) {
                validationObject.storageIntegrationId = 'A storage provider must be selected.';
            }
            break;
        case 'ADD_RECIPIENTS_WITH_NAME':
            if (!performedActionDefinition.recipientsObjects?.length) {
                validationObject.noRecipients = 'At least one recipient must be added.';
            } else {
                performedActionDefinition.recipientsObjects.forEach((recipient) => {
                    if (utils.isNullOrEmpty(recipient.name.originalExpression)) {
                        validationObject.noRecipients = 'The recipient name must be filled.';
                    } else if (utils.isNullOrEmpty(recipient.email.originalExpression)) {
                        validationObject.noRecipients = 'The recipient email must be filled.';
                    }
                });
            }
            break;
        case 'CREATE_ENVELOPE_WITH_TABS':
            if (
                !performedActionDefinition.emailSubjectExpression ||
                utils.isNullOrEmpty(performedActionDefinition.emailSubjectExpression.originalExpression)
            ) {
                validationObject.emailSubjectError = 'Email subject must be filled.';
            }
            if (!performedActionDefinition.status || utils.isNullOrEmpty(performedActionDefinition.status)) {
                validationObject.statusError = 'Access type must be selected.';
            }
            if (
                !isValidWorkflowDataStorageConfiguration(
                    performedActionDefinition,
                    group,
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    projectManager,
                )
            ) {
                validationObject.invalidFileChoice =
                    'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
            }
            if (performedActionDefinition.recipientsObjects.length) {
                performedActionDefinition.recipientsObjects.forEach((recipient) => {
                    if (utils.isNullOrEmpty(recipient.name.originalExpression)) {
                        validationObject.recipientsDataError = 'The recipient name must be filled.';
                    } else if (utils.isNullOrEmpty(recipient.email.originalExpression)) {
                        validationObject.recipientsDataError = 'The recipient email must be filled.';
                    } else if (recipient.recipientTabObjects.length) {
                        recipient.recipientTabObjects.forEach((tabObject) => {
                            if (utils.isNullOrEmpty(tabObject.tabType)) {
                                validationObject.recipientsDataError = 'Recipient tab type must be selected';
                            }
                        });
                    }
                });
            }
            break;
        case 'LOCK_ENVELOPE':
            if (
                !performedActionDefinition.lockDurationInSecondsExpression ||
                utils.isNullOrEmpty(performedActionDefinition.lockDurationInSecondsExpression.originalExpression)
            ) {
                validationObject.lockDurationInSeconds = 'Lock duration must be filled.';
            }
            break;
    }
};

export const validateTwilioSMSCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'SEND_SMS':
            if (
                !performedActionDefinition.fromPhoneNumberExpression ||
                utils.isNullOrEmpty(performedActionDefinition.fromPhoneNumberExpression.originalExpression)
            ) {
                validationObject.toNumberError = 'From Phone Number Must Be Filled.';
            }

            if (
                !performedActionDefinition.toPhoneNumberExpression ||
                utils.isNullOrEmpty(performedActionDefinition.toPhoneNumberExpression.originalExpression)
            ) {
                validationObject.phoneNumberError = 'To Phone Number Must Be Filled.';
            }

            if (
                !performedActionDefinition.messageBodyExpression ||
                utils.isNullOrEmpty(performedActionDefinition.messageBodyExpression.originalExpression)
            ) {
                validationObject.messageBodyError = 'Message Body Must Be Filled.';
            }
            break;
    }
};

export const validateSalesforceCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'CONVERT_LEAD':
            if (!performedActionDefinition.convertState?.length) {
                validationObject.convertState = 'Convert State must be filled.';
            }
            break;
        case 'MERGE_RECORDS':
            if (!performedActionDefinition.recordToMerge?.length) {
                validationObject.recordToMerge = 'Record To Merge ID must be filled.';
            }
            if (!performedActionDefinition.recordType?.length) {
                validationObject.recordType = 'Record Type must be filled.';
            }
            break;
        case 'UPLOAD_ATTACHMENT':
            if (
                !isValidWorkflowDataStorageConfiguration(
                    performedActionDefinition,
                    group,
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    projectManager,
                )
            ) {
                validationObject.invalidFileChoice =
                    'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
            }
            break;
    }
};

export const validateSpringCMCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'GENERATE_DOCUMENT_FROM_XML':
            if (
                !performedActionDefinition.documentNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.documentNameExpression.originalExpression)
            ) {
                validationObject.error = 'Document name must be filled.';
            }
            if (
                !performedActionDefinition.templateIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.templateIdExpression.originalExpression)
            ) {
                validationObject.error = 'Template ID must be filled.';
            }
            if (
                !performedActionDefinition.destinationFolderIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.destinationFolderIdExpression.originalExpression)
            ) {
                validationObject.error = 'Destination folder ID must be filled.';
            }
            if (
                !performedActionDefinition.dataXMLExpression ||
                utils.isNullOrEmpty(performedActionDefinition.dataXMLExpression.originalExpression)
            ) {
                validationObject.error = 'XML Data must be filled.';
            }
            break;
        case 'UPLOAD_DOCUMENT':
            if (
                !isValidWorkflowDataStorageConfiguration(
                    performedActionDefinition,
                    group,
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    projectManager,
                )
            ) {
                validationObject.invalidFileChoice =
                    'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
            }
            break;
        case 'MOVE_DOCUMENT':
            if (
                !performedActionDefinition.destinationFolderIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.destinationFolderIdExpression.originalExpression)
            ) {
                validationObject.moveFolderError = 'Destination folder ID must be filled.';
            }
            break;
    }
};

export const validateGoodTimeCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'ADD_TAGS':
            if (
                !performedActionDefinition.tagIdsExpression ||
                utils.isNullOrEmpty(performedActionDefinition.tagIdsExpression.originalExpression)
            ) {
                validationObject.tagsInputError = 'Tag IDs ID must be filled.';
            }
            break;
        case 'DELETE_TAG':
            if (
                !performedActionDefinition.tagIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.tagIdExpression.originalExpression)
            ) {
                validationObject.tagInputError = 'Tag ID must be filled.';
            }
            break;
    }
};

export const validateAmazonS3CustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'UPLOAD_FILE':
            if (
                !isValidWorkflowDataStorageConfiguration(
                    performedActionDefinition,
                    group,
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    projectManager,
                )
            ) {
                validationObject.invalidFileChoice =
                    'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
            }
            break;
    }
};

export const validateSharePointCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'CREATE_FILE':
            if (
                !performedActionDefinition.newDocumentNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.newDocumentNameExpression.originalExpression)
            ) {
                validationObject.newDocumentNameError = 'File name must be filled.';
            }
            if (
                !performedActionDefinition.newDocumentContentExpression ||
                utils.isNullOrEmpty(performedActionDefinition.newDocumentContentExpression.originalExpression)
            ) {
                validationObject.newDocumentContentError = 'File content must be filled.';
            }
            break;
        case 'MERGE_PDFS':
            if (
                !performedActionDefinition.newDocumentNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.newDocumentNameExpression.originalExpression)
            ) {
                validationObject.newDocumentNameError = 'File name must be filled.';
            }
            if (
                !performedActionDefinition.sourceFirstPdfExpression ||
                utils.isNullOrEmpty(performedActionDefinition.sourceFirstPdfExpression.originalExpression)
            ) {
                validationObject.sourcePdfExpression = 'Source PDF IDs must be filled.';
            }
            if (
                !performedActionDefinition.sourceSecondPdfExpression ||
                utils.isNullOrEmpty(performedActionDefinition.sourceSecondPdfExpression.originalExpression)
            ) {
                validationObject.sourcePdfExpression = 'Source PDF IDs must be filled.';
            }
            break;
        case 'CREATE_DOC_FROM_TEMPLATE':
            if (
                !performedActionDefinition.templateIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.templateIdExpression.originalExpression)
            ) {
                validationObject.docFromTemplateIdDataError = 'Template Doc ID must be filled.';
            }

            if (
                !performedActionDefinition.targetIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.targetIdExpression.originalExpression)
            ) {
                validationObject.docFromTemplateTargetDataError = 'Target Folder ID must be filled.';
            }

            if (
                !performedActionDefinition.newNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.newNameExpression.originalExpression)
            ) {
                validationObject.docFromTemplateNewNameDataError = 'New File Name must be filled.';
            }

            if (performedActionDefinition.replacements.length) {
                performedActionDefinition.replacements.forEach((replacement) => {
                    if (utils.isNullOrEmpty(replacement.placeholderExpression.originalExpression)) {
                        validationObject.replacementsDataError = `The replacement's Placeholder must be filled.`;
                    } else if (utils.isNullOrEmpty(replacement.textExpression.originalExpression)) {
                        validationObject.participantsDataError = `The replacement's Text must be filled.`;
                    }
                });
            }
            break;
    }
};

export const validateSlackAppCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;
    switch (performedActionDefinition.customActionKey) {
        case 'CREATE_CHANNEL':
            if (performedActionDefinition.channelName === '') {
                validationObject.error = 'Must fill a channel name';
            }
            break;
        case 'SET_CHANNEL_TOPIC':
            if (
                !performedActionDefinition.channelTopicExpression ||
                utils.isNullOrEmpty(performedActionDefinition.channelTopicExpression.originalExpression)
            ) {
                validationObject.error = 'Must fill a topic';
            }
            break;
        case 'REACT_ON_MESSAGE':
            if (
                !performedActionDefinition.messageReactionExpression ||
                utils.isNullOrEmpty(performedActionDefinition.messageReactionExpression.originalExpression)
            ) {
                validationObject.error = 'Must fill a reaction';
            }
            break;
        case 'CREATE_USER_GROUP':
            if (
                !performedActionDefinition.userGroupNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.userGroupNameExpression.originalExpression)
            ) {
                validationObject.error = 'Must fill a User Group name';
            }
            break;
    }
};

export const validateBoxCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'UPLOAD_FILE':
            if (
                !isValidWorkflowDataStorageConfiguration(
                    performedActionDefinition,
                    group,
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    projectManager,
                )
            ) {
                validationObject.invalidFileChoice =
                    'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
            }
            break;

        case 'MOVE_FOLDER':
        case 'MOVE_FILE':
            if (
                !performedActionDefinition.destinationFolderExpresssion ||
                utils.isNullOrEmpty(performedActionDefinition.destinationFolderExpresssion.originalExpression)
            ) {
                validationObject.destinationFolderError = 'Destination folder ID must be filled.';
            }
            break;

        case 'SHARE_FOLDER':
        case 'SHARE_FILE':
            if (!performedActionDefinition.accessLevel || utils.isNullOrEmpty(performedActionDefinition.accessLevel)) {
                validationObject.accessLevelError = 'Access level must be selected.';
            }
            break;

        case 'CREATE_COLLABORATION':
            if (!performedActionDefinition.itemType || utils.isNullOrEmpty(performedActionDefinition.itemType)) {
                validationObject.itemTypeError = 'Item type must be selected.';
            }
            if (
                performedActionDefinition.itemType &&
                (!performedActionDefinition.itemIdExpresssion ||
                    utils.isNullOrEmpty(performedActionDefinition.itemIdExpresssion.originalExpression))
            ) {
                validationObject.itemIdError = 'Folder/File ID must be filled.';
            }
            if (!performedActionDefinition.accessType || utils.isNullOrEmpty(performedActionDefinition.accessType)) {
                validationObject.accessTypeError = 'Access type must be selected.';
            }
            if (
                performedActionDefinition.accessType &&
                performedActionDefinition.accessType === 'User' &&
                (!performedActionDefinition.accessIdExpresssion ||
                    utils.isNullOrEmpty(performedActionDefinition.accessIdExpresssion.originalExpression)) &&
                (!performedActionDefinition.accessEmailAddressExpresssion ||
                    utils.isNullOrEmpty(performedActionDefinition.accessEmailAddressExpresssion.originalExpression))
            ) {
                validationObject.accessableByIdError = 'User ID or Email must be filled';
            }
            if (
                performedActionDefinition.accessType &&
                performedActionDefinition.accessType === 'Group' &&
                (!performedActionDefinition.accessIdExpresssion ||
                    utils.isNullOrEmpty(performedActionDefinition.accessIdExpresssion.originalExpression))
            ) {
                validationObject.accessableByIdError = 'Group ID must be filled';
            }
            if (!performedActionDefinition.accessRole || utils.isNullOrEmpty(performedActionDefinition.accessRole)) {
                validationObject.accessRoleError = 'Access role must be selected.';
            }
            break;
    }
};

export const validateLeverCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'CREATE_FEEDBACK':
            if (
                !performedActionDefinition.opportunityIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.opportunityIdExpression.originalExpression)
            ) {
                validationObject.opportunityIdError = 'Opportunity ID must be filled.';
            }
            if (
                !performedActionDefinition.feedbackIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.feedbackIdExpression.originalExpression)
            ) {
                validationObject.feedbackIdError = 'Feedback Template ID must be filled.';
            }
            if (
                !performedActionDefinition.performAsExpression ||
                utils.isNullOrEmpty(performedActionDefinition.performAsExpression.originalExpression)
            ) {
                validationObject.performAsError = 'Perform As must be filled.';
            }
            break;
        case 'UPDATE_FEEDBACK':
            if (
                !performedActionDefinition.opportunityIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.opportunityIdExpression.originalExpression)
            ) {
                validationObject.opportunityIdError = 'Opportunity ID must be filled.';
            }
            if (
                !performedActionDefinition.feedbackIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.feedbackIdExpression.originalExpression)
            ) {
                validationObject.feedbackIdError = 'Opportunity Feedback ID must be filled.';
            }
            if (
                !performedActionDefinition.performAsExpression ||
                utils.isNullOrEmpty(performedActionDefinition.performAsExpression.originalExpression)
            ) {
                validationObject.performAsError = 'Perform As must be filled.';
            }
            if (!performedActionDefinition.fields?.length) {
                validationObject.fieldsError = 'At least one field must be added.';
            } else {
                performedActionDefinition.fields.forEach((field) => {
                    if (utils.isNullOrEmpty(field.fieldIdExpression.originalExpression)) {
                        validationObject.fieldsError = 'The Field ID must be filled.';
                    }
                });
            }
            break;
    }
};

export const validateUiPathCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'START_JOB':
            if (
                !performedActionDefinition.releaseKeyExpression ||
                utils.isNullOrEmpty(performedActionDefinition.releaseKeyExpression.originalExpression)
            ) {
                validationObject.releaseKeyError = 'Release Key must be filled.';
            }
            break;
    }
};

export const validateEvisortCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'UPLOAD_DOCUMENT':
            if (performedActionDefinition.fields.length) {
                performedActionDefinition.fields.forEach((fieldObject) => {
                    if (utils.isNullOrEmpty(fieldObject.fieldNameExpression.originalExpression)) {
                        validationObject.uploadFieldsError = "Name field can't be empty";
                    } else if (fieldObject.fieldValueObjects.length < 1) {
                        validationObject.uploadFieldsError = 'At least one value must be filled for each field';
                    } else if (fieldObject.fieldValueObjects.length) {
                        fieldObject.fieldValueObjects.forEach((fieldValueObject) => {
                            if (utils.isNullOrEmpty(fieldValueObject.valueExpression.originalExpression)) {
                                validationObject.uploadFieldsError = "Value field can't be empty";
                            }
                        });
                    }
                });
            }
            break;
    }
};

export const validateGoogleSlidesCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'CREATE_PRESENTATION_FROM_TEMPLATE':
            if (performedActionDefinition.replacementActions.length) {
                performedActionDefinition.replacementActions.forEach((replacementAction) => {
                    if (utils.isNullOrEmpty(replacementAction.placeholderTextExpression.originalExpression)) {
                        validationObject.replacementActionsError = "Text to find can't be empty";
                    } else if (utils.isNullOrEmpty(replacementAction.newTextExpression.originalExpression)) {
                        validationObject.replacementActionsError = "New text can't be empty";
                    }
                });
            }
            if (
                !performedActionDefinition.templateIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.templateIdExpression.originalExpression)
            ) {
                validationObject.templateIdError = "Template Presentation ID can't be empty";
            }
            if (
                !performedActionDefinition.newPresentationNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.newPresentationNameExpression.originalExpression)
            ) {
                validationObject.newPresentationNameError = "New Presentation Name can't be empty";
            }
            if (
                !performedActionDefinition.destinationFolderIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.destinationFolderIdExpression.originalExpression)
            ) {
                validationObject.destinationFolderError = "Destination Folder ID can't be empty";
            }
            break;
    }
};
export const validateCloudConvertCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'CREATE_MERGE_TASK':
        case 'CREATE_EXPORT_URL_TASK':
            if (
                !performedActionDefinition.cloudConvertTaskIdsExpression ||
                utils.isNullOrEmpty(performedActionDefinition.cloudConvertTaskIdsExpression.originalExpression)
            ) {
                validationObject.cloudConvertTaskIdsError = "Template Presentation ID can't be empty";
            }
            break;
    }
};

export const validateCustomHttpActionFields = (validationObject, definition, integrationsConsts) => {
    const customActionFieldDefinitions =
        integrationsConsts.getActionsByIntegrationsMap()[definition.integrationType].customActions[
            definition.performedActionDefinition.customActionKey
        ].fieldsConfiguration.fields;
    const actionValidator =
        integrationsConsts.getActionsByIntegrationsMap()[definition.integrationType].customActions[
            definition.performedActionDefinition.customActionKey
        ].actionValidator;
    const fields = definition.performedActionDefinition.fieldsValuesDefinition
        ? definition.performedActionDefinition.fieldsValuesDefinition.tonkeanExpressionsValuesMap
        : {};
    const fieldKeys = Object.keys(fields);
    const fieldIdToFieldDefinitionMap = utils.createMapFromArray(customActionFieldDefinitions, 'id');

    // Go through all fields and validate them
    fieldKeys.forEach((fieldKey) => {
        const fieldValue =
            definition.performedActionDefinition.fieldsValuesDefinition.tonkeanExpressionsValuesMap[fieldKey]
                .originalExpression;
        const fieldDefinition = fieldIdToFieldDefinitionMap[fieldKey];

        // If the field set as required we need to check if its not null or empty.
        if (fieldDefinition?.isRequired && utils.isNullOrEmpty(fieldValue)) {
            validationObject[fieldKey] = `${fieldDefinition?.displayName} must be filled`;
            return;
        }

        // If the field has custom validator function, activate it on the field value.
        if (fieldDefinition?.fieldValidator) {
            const validationResult = fieldDefinition.fieldValidator(fieldValue);
            if (validationResult) {
                validationObject[fieldKey] = validationResult;
                return;
            }
        }
    });

    // If the custom action has a general action validator.
    if (actionValidator) {
        const validationResult = actionValidator(fields);
        if (validationResult) {
            validationObject.generalError = validationResult;
            return;
        }
    }
};

export const validateTrelloCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'ADD_MEMBER_TO_CARD':
            if (!performedActionDefinition.evaluatedEmail) {
                // Make sure we have a member email to send.
                validationObject.noEmail = 'Must fill member email';
            }
            break;

        case 'COMMENT_ON_CARD':
            if (!performedActionDefinition.evaluatedCommentText) {
                // Make sure we have a member email to send.
                validationObject.noCommentText = 'Must fill the comment text';
            }
            break;
    }
};

export const validateAdobesignCustomActions = (
    validationObject,
    definition,
    workflowVersionInfoRetriever,
    group,
    syncConfigCacheManager,
    projectManager,
) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'UPLOAD_TRANSIENT_DOCUMENT':
            if (
                !isValidWorkflowDataStorageConfiguration(
                    performedActionDefinition,
                    group,
                    workflowVersionInfoRetriever,
                    syncConfigCacheManager,
                    projectManager,
                )
            ) {
                validationObject.invalidFileChoice =
                    'Data storage that operates on a different type than the Intake Source can’t be matched to the Root monitored item.';
            }
            break;
        case 'TRANSFER_AGREEMENT_DOCUMENT':
            if (!performedActionDefinition.storageIntegrationId?.length) {
                validationObject.storageIntegrationId = 'A storage provider must be selected.';
            }
            break;
        case 'CREATE_AGREEMENT':
            if (
                !performedActionDefinition.agreementNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.agreementNameExpression.originalExpression)
            ) {
                validationObject.agreementNameError = 'Agreement name must be filled.';
            }

            if (
                !performedActionDefinition.agreementState ||
                utils.isNullOrEmpty(performedActionDefinition.agreementState)
            ) {
                validationObject.agreementStateError = 'Agreement state must be selected.';
            }

            if (
                (!performedActionDefinition.templateIdExpression ||
                    utils.isNullOrEmpty(performedActionDefinition.templateIdExpression.originalExpression)) &&
                (!performedActionDefinition.transientDocumentIdExpression ||
                    utils.isNullOrEmpty(performedActionDefinition.transientDocumentIdExpression.originalExpression))
            ) {
                validationObject.agreementFileError = 'Library or Transient Document ID must be filled.';
            }

            if (
                !performedActionDefinition.signatureType ||
                utils.isNullOrEmpty(performedActionDefinition.signatureType)
            ) {
                validationObject.signatureTypeError = 'Agreement signature type must be selected.';
            }

            if (performedActionDefinition.participantGroups.length) {
                performedActionDefinition.participantGroups.forEach((participantGroup) => {
                    if (utils.isNullOrEmpty(participantGroup.role)) {
                        validationObject.participantsDataError = `The participant group's role must be filled.`;
                    } else if (
                        !participantGroup.orderExpression ||
                        utils.isNullOrEmpty(participantGroup.orderExpression.originalExpression)
                    ) {
                        validationObject.participantsDataError = `The participant group's order must be filled.`;
                    } else if (participantGroup.participantObjects.length < 1) {
                        validationObject.participantsDataError = `At least 1 participant must be filled in each group.`;
                    } else if (participantGroup.participantObjects.length) {
                        participantGroup.participantObjects.forEach((participantObject) => {
                            if (
                                !participantObject.participantEmailExpression ||
                                utils.isNullOrEmpty(participantObject.participantEmailExpression.originalExpression)
                            ) {
                                validationObject.participantsDataError = `Participant's email must be filled`;
                            }
                        });
                    }
                });
            }
            break;
    }
};

export const validateCoupaCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'CREATE_AGREEMENT':
            if (performedActionDefinition.purchaseOrderLines.length < 1) {
                validationObject.purchaseOrderLinesError = `At least 1 purchase order line must be added.`;
            }
            break;
    }
};

export const validateNetsuiteCustomActions = (validationObject, definition) => {
    const performedActionDefinition = definition.performedActionDefinition;

    switch (performedActionDefinition.customActionKey) {
        case 'UPLOAD_FILE':
            if (
                !performedActionDefinition.suiteScriptIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.suiteScriptIdExpression)
            ) {
                validationObject.netsuiteScriptIdError = 'SuiteScript ID is mandatory.';
            }
            if (
                !performedActionDefinition.suiteScriptDeployVersionExpression ||
                utils.isNullOrEmpty(performedActionDefinition.suiteScriptDeployVersionExpression)
            ) {
                validationObject.netsuiteScriptDeployVersionError = 'SuiteScript Deployment Version is mandatory.';
            }
            if (
                !performedActionDefinition.fileNameExpression ||
                utils.isNullOrEmpty(performedActionDefinition.fileNameExpression)
            ) {
                validationObject.netsuiteFileNameError = 'File name is mandatory.';
            }
            if (
                !performedActionDefinition.targetFolderIdExpression ||
                utils.isNullOrEmpty(performedActionDefinition.targetFolderIdExpression)
            ) {
                validationObject.netsuiteFolderIdError = 'Target folder ID is mandatory.';
            }
            if (
                !performedActionDefinition.fileDescriptionExpression ||
                utils.isNullOrEmpty(performedActionDefinition.fileDescriptionExpression)
            ) {
                validationObject.netsuiteDescriptionError = 'File description is mandatory.';
            }
            break;
    }
};
