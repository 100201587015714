import { useMemo } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type {
    EnterpriseComponentId,
    EnterpriseComponentVariableRelatedEntityId,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

const useEnterpriseComponentVariablesExpressionTab = (
    projectId: TonkeanId<TonkeanType.PROJECT>,
    enterpriseComponentId: EnterpriseComponentId,
    relatedEntityId: EnterpriseComponentVariableRelatedEntityId | undefined,
): TonkeanExpressionAdditionalTab => {
    const [, getEnterpriseComponentVariables] = useLazyTonkeanService('getEnterpriseComponentVariables');

    return useMemo(() => {
        return {
            id: 'ENTERPRISE_COMPONENT_VARIABLES',
            label: 'Enterprise Component Variables',
            iconClass: 'mod-enterprise-component-variable',
            searchPlaceholder: 'Search...',
            shouldShowInFormulasChoices: true,
            getFields: async () => {
                const variables = await getEnterpriseComponentVariables(
                    projectId,
                    enterpriseComponentId,
                    relatedEntityId,
                );

                return variables.entities.map((variable) => ({
                    name: variable.displayName,
                    value: variable.id,
                    label: variable.displayName,
                    id: variable.id,
                }));
            },
        };
    }, [enterpriseComponentId, getEnterpriseComponentVariables, projectId, relatedEntityId]);
};

export default useEnterpriseComponentVariablesExpressionTab;
