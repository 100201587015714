import React from 'react';
import styled from 'styled-components';

import { Modal, ModalBody, ModalFooterActions, ModalHeader, ModalSize } from '@tonkean/infrastructure';
import type { Environment, Group } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Theme } from '@tonkean/tui-theme';

const NoteBorderSection = styled.div`
    color: #f0771e;
    margin-top: 5px;
`;

const LinkSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 8px;
`;

const GroupLink = styled(StateLink)`
    margin: 2px;
    display: flex;
    align-items: center;
    color: ${Theme.colors.primary};

    &::before {
        margin: 4px;
        background: ${Theme.colors.primary};
        content: '';
        height: 4px;
        width: 4px;
        display: block;
        border-radius: 100%;
    }
`;

interface Props {
    open: boolean;
    group: Group;
    linkedGroups: Group[] | undefined;
    environment: Environment;
    onConfirm(): void;
    onCancel(): void;
}

const EnvironmentActivateConfirmationModal: React.FC<Props> = ({
    open,
    group,
    linkedGroups,
    onConfirm,
    onCancel,
    environment,
}) => {
    return (
        <Modal
            open={open}
            onClose={onCancel}
            onExited={onCancel}
            size={ModalSize.MEDIUM}
            ariaRole="alertdialog"
            fixedWidth
        >
            <ModalHeader>Turning the {environment} environment off</ModalHeader>
            <ModalBody>
                <div>
                    Are you sure you want to turn {group.name} {environment} environment off?
                    {!!linkedGroups?.length && (
                        <div>
                            The following modules are connected with this module:{' '}
                            <LinkSection>
                                {linkedGroups.map((linkedGroup) => (
                                    <GroupLink
                                        key={linkedGroup.id}
                                        state="product.workerEditor"
                                        params={{
                                            g: linkedGroup.id,
                                            env: environment === 'build' ? 'DRAFT' : 'PUBLISHED',
                                        }}
                                        options={{ notify: true }}
                                        openInNewTab
                                    >
                                        {linkedGroup.name}
                                    </GroupLink>
                                ))}
                            </LinkSection>
                        </div>
                    )}
                    <NoteBorderSection>
                        <b>Important note: </b>&nbsp;Items in the module will NOT get updated while the module is turned
                        off.
                    </NoteBorderSection>
                </div>
            </ModalBody>
            <ModalFooterActions onCancel={onCancel} cancelLabel="Cancel" onSave={onConfirm} saveLabel="Turn Off" />
        </Modal>
    );
};
export default EnvironmentActivateConfirmationModal;
