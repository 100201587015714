<div class="common-close-btn-container flex-vmiddle flex-col padding-top-xlg">
    <div class="common-close-btn margin-normal-sm" ng-click="$dismiss()">
        <tnk-icon src="../../../../apps/tracks/images/icons/x.svg"></tnk-icon>
    </div>

    <div class="padding-top-lg common-title mod-light margin-bottom-xlg">
        Turn Smart Auto Check-ins {{ isCurrentlyActive ? 'OFF' : 'ON' }}?
    </div>
    <div class="bot-toggle-modal-img margin-bottom-xlg">
        <img class="" ng-src="{{ isCurrentlyActive ? 'images/areYouSureBotOff.png' : 'images/areYouSureBotOn.png' }}" />
    </div>
    <div ng-if="isCurrentlyActive" class="common-title-xxs mod-light padding-bottom-lg">
        <div>Owners in this list won't get any follow up messages.</div>
        <a ng-click="onLearnMore()">Learn more</a>
    </div>
    <div ng-if="!isCurrentlyActive" class="common-title-xxs mod-light padding-bottom-lg">
        <div>It will follow up with owners in this list when the time's right.</div>
        <a ng-click="onLearnMore()">Learn more</a>
    </div>
    <div class="flex mod-justify-end margin-bottom-xlg">
        <button
            class="btn btn-lg common-no-outline margin-right"
            ng-class="isCurrentlyActive ? 'btn-default' : 'btn-primary'"
            ng-click="onConfirm()"
        >
            Yes, turn it {{ isCurrentlyActive ? 'off' : 'on' }}
        </button>
        <button
            class="btn btn-lg common-no-outline"
            ng-class="!isCurrentlyActive ? 'btn-default' : 'btn-primary'"
            ng-click="$dismiss()"
        >
            Not now
        </button>
    </div>
</div>
