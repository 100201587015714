import template from './tnkInitiativeSpecialFieldSelector.template.html?angularjs';

/**
 * A component to update special fields of a given initiative (uses tnkSpecialFieldManualInput).
 * Once a field value is changed, the component auto saves the new value in the initiative and emits a UI alert accordingly.
 */
export default angular.module('tonkean.app').component('tnkInitiativeSpecialFieldSelector', {
    bindings: {
        initiativeId: '<',
        workflowVersionId: '<',
        fieldId: '<',
        delegateSave: '<',
        onSaved: '<',
    },
    template,
    controller: 'InitiativeSpecialFieldSelector',
});
