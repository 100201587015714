import pagerDutyCircleIcon from '../../../../apps/tracks/images/integrations/pagerduty-circle.png';
import pagerDutyIcon from '../../../../apps/tracks/images/pagerduty.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class PagerDutyIntegration extends IntegrationDefinitionBase {
    override name = 'pagerduty';
    override displayName = 'PagerDuty';
    override description =
        'We will collect Event Orchestrations, Incidents, On-Calls, Priorities, Services and Users data.';
    override authenticationType = AuthenticationMethod.API_TOKEN;

    override entities = [
        new IntegrationEntity('EventOrchestration', 'Event Orchestrations'),
        new IntegrationEntity('Incident', 'Incidents'),
        new IntegrationEntity('OnCall', 'OnCalls'),
        new IntegrationEntity('Priority', 'Priorities'),
        new IntegrationEntity('Service', 'Services'),
        new IntegrationEntity('User', 'Users'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(pagerDutyCircleIcon, '130px'),
        new IntegrationIcon(pagerDutyIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: {
            EventOrchestration: true,
            Incident: true,
            OnCall: true,
            Priority: true,
            Service: true,
            User: true,
        },
        isOnlyDataRetention: {},
    };
}

export default PagerDutyIntegration;
