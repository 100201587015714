<div class="tabs-field-selector">
    <!-- Popover trigger button -->
    <button
        type="button"
        ng-class="data.buttonClassOverride || 'btn btn-default tabs-field-selector-btn'"
        uib-popover-template="'tabs-field-selector-popover-template'"
        popover-class="{{ data.tabsFieldSelectorModNarrow ? 'tabs-field-selector-popover-narrow' : '' }} {{
            data.tabsFieldSelectorModFixedWidth ? 'tabs-field-selector-mod-fixed-width' : ''
        }} tabs-field-selector-popover popover-no-arrow"
        popover-placement="{{ data.popoverPlacement || 'bottom-right' }}"
        popover-trigger="outsideClick"
        popover-animation="false"
        popover-is-open="data.isPopoverOpen"
        ng-click="onPopoverOpen()"
        uib-tooltip="{{::data.tooltip}}"
        tooltip-placement="top"
        tooltip-append-to-body="true"
        tooltip-enable="{{::!!data.tooltip}}"
        data-automation="{{ data.automationIdentifierExpressionAddField }}"
    >
        <i class="svg-icon-nav-sm common-color-primary">
            <tnk-icon src="../../../../../apps/tracks/images/icons/insert-field.svg"></tnk-icon>
        </i>
    </button>
</div>

<!-- Popover template -->
<script type="text/ng-template" id="tabs-field-selector-popover-template">
    <div uib-tabset active="data.activeTabIndex">
        <div uib-tab ng-repeat="tab in data.tabs track by $index" select="selectTabByIndex($index)" ng-if="data.tabFieldsCache[tab.label].length || tab.emptyStateMessage || tab.isLoading" index="$index">
            <!-- Header -->
            <div uib-tab-heading class="tabs-nav-icon flex-vmiddle mod-center" ng-class="tab.isLoading || tab.iconClass" uib-tooltip="{{tab.label}}" tooltip-append-to-body="true">
                <span ng-if="tab.isLoading" class="loading-medium"></span>
            </div>

            <div class="padding-normal-sm" ng-if="tab.isLoading">
                Loading Options...
            </div>

            <!-- Content -->
            <div class="tabs-field-selector-tab-content" ng-if="!tab.isLoading">
                <!-- Default dropdown selector-->
                <div ng-if="tab.getFields">
                    <!-- Search -->
                    <input type="text" autofocus ng-model="data.search" placeholder="{{tab.searchPlaceholder || 'Search for a field...'}}" class="form-control tabs-field-selector-tab-search" ng-click="forceFocus($event)"  ng-model-options="{ debounce: 500 }"   />
                    <!-- Dropdown -->
                    <ul class="dropdown-menu tabs-field-selector-dropdown dropdown-menu-no-animation" prevent-scroll-bubbling>
                        <li ng-repeat="field in fields = (data.displayedFieldList | filter: filterFieldList) track by $index"
                            data-automation="tnk-tabs-field-selector-template-field-value"
                            ng-class="{'divider': field.isDivider, 'tabs-field-selector-group-title': field.isGroupTitle}">

                            <!-- Field value -->
                            <a ng-click="selectField(field)" ng-if="!field.isDivider && !field.isGroupTitle">
                                <span class="tabs-field-selector-field-icon-container">
                                    <!-- Integration icon -->
                                    <span
                                        ng-if="field.source && !field.definition.integrationType && !field.projectIntegrationId"
                                        class="inline-block mod-sm mod-circle initiative-integration-icon mod-{{field.source | lowercase}}"
                                    ></span>
                                    <tnk-project-integration-icon
                                        ng-if="(field.definition.integrationType || field.projectIntegrationId) && !field.iconModClass"
                                        class-name="'inline-block mod-sm mod-circle'"
                                        icon-url="(field.projectIntegration || {}).iconUrl"
                                        integration-type="field.definition.integrationType"
                                        project-integration-id="field.projectIntegrationId"
                                    ></tnk-project-integration-icon>
                                    <span ng-if="field.iconModClass" class="inline-block mod-sm mod-circle initiative-integration-icon {{field.iconModClass}}"></span>
                                    <span ng-if="!field.source && !field.definition.integrationType && !field.iconModClass && !field.projectIntegrationId"
                                          class="common-color-grey-soft">
                                        <tnk-field-type-icon field-type="field.fieldType" size="xs"></tnk-field-type-icon>
                                    </span>
                                </span>
                                {{field.label || field.name}}
                            </a>

                            <!-- In case of a field which is a title -->
                            <span ng-if="field.isGroupTitle">
                                {{field.title}}
                            </span>
                        </li>

                        <!-- No search results -->
                        <li ng-if="!fields.length" class="padding-normal-sm">
                            <span class="common-color-grey">
                                {{ tab.emptyStateMessage || 'No fields found...' }}
                            </span>
                        </li>
                    </ul>
                </div>

                <!-- Custom Selector -->
                <div ng-if="tab.isParentOfRoot" ng-include="'../tnkTonkeanExpression/templates/parentOfRoot.template.html'"></div>
            </div>
        </div>
    </div>
</script>
