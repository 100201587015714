/**
 * Helper function that allows passing undefined in a template literal.
 * If the interpolation is falsy, it won't be displayed, and if it has a space before it,
 * it will be trimmed out.
 * **This should be used as a template literal and not as a regular function**
 *
 * @example not undefined:
 * const name = 'John Doe';
 * text`Hello ${name}!`; // => 'Hello John Doe!'
 * @example undefined:
 * const name = undefined;
 * text`Hello ${name}!`; // => 'Hello!'
 */
function text(strings: TemplateStringsArray, ...interpolations: (string | number | undefined | null)[]): string {
    return (
        strings
            .map((string, index) => {
                // Add the corresponding interpolation if it's not falsy.
                const interpolation = interpolations[index];
                if (interpolation) {
                    return `${string}${interpolation}`;
                }

                // Trim trailing space, but not a linebreak and not multiple spaces.
                return string.replaceAll(/ $/gm, '');
            })
            // Concat all to a single string.
            .join('')
    );
}

export default text;
