import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Placeholder, Table, TextEllipsis } from '@tonkean/infrastructure';
import type { ProjectIntegrationActionMarketplaceSummary } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const StyledNoResults = styled.div`
    padding: 20px 10px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    actions: ProjectIntegrationActionMarketplaceSummary[];
    loading?: boolean;
}

const MarketplaceItemCreateModalDataSourceActionsTab: React.FC<Props> = ({ actions, loading }) => {
    const columns = [
        {
            Header: 'Name',
            accessor: 'name' as const,
            width: '20%',
        },
        {
            Header: 'Description',
            accessor: 'description' as const,
            width: '60%',
        },
    ];

    const entities = useMemo(() => {
        return actions.map((action) => {
            return {
                name: action.displayName,
                description: (
                    <TextEllipsis numberOfLines={1} tooltip>
                        {!!action.description ? action.description : 'No Description'}
                    </TextEllipsis>
                ),
            };
        });
    }, [actions]);

    return (
        <>
            {loading ? (
                range(6).map((index) => <Placeholder key={index} $width="100%" $height="30px" />)
            ) : (
                <>
                    <Table columns={columns} data={entities} />

                    {!entities.length && <StyledNoResults>No actions to show.</StyledNoResults>}
                </>
            )}
        </>
    );
};

export default MarketplaceItemCreateModalDataSourceActionsTab;
