import type { ColDef } from '@ag-grid-community/core';
import type React from 'react';

import {
    EMPHASIZED_HEADER_CLASS_NAME,
    EMPTY_AND_REQUIRED_CLASS_NAME,
    FILLED_FILTER_ICON_CLASS_NAME,
    REQUIRED_HEADER_CLASS_NAME,
} from './customClassNames';
import isEditableColumn from './getIsEditableColumn';
import ColumnId from '../configs/config/columnId';
import type InitiativeRowData from '../entities/InitiativeRowData';

import type { FieldDefinitionKey, Person } from '@tonkean/tonkean-entities';
import { SpecialFieldsKey } from '@tonkean/tonkean-entities';

const getOwnerDefinition = (
    commonDef: Partial<ColDef<InitiativeRowData>>,
    fieldDefinitionKeyToName: Record<FieldDefinitionKey, string | undefined>,
    enableFilter: boolean | undefined,
    isEditable: boolean,
    getIsFieldEmptyAndRequired: (fieldDefinitionKey: FieldDefinitionKey, row: InitiativeRowData) => boolean,
    shouldShowRequiredIndication: (fieldDefinitionKey: FieldDefinitionKey) => boolean,
    isEmphasizeText: boolean | undefined,
    filteredColumnIds: string[] | undefined,
    peopleSelectorCellEditor: React.FC,
    fixedRowHeight: number | undefined,
    drilldownColumnId: string | undefined,
) => {
    return {
        ...commonDef,
        headerName: fieldDefinitionKeyToName[SpecialFieldsKey.OWNER] || 'Owner',
        field: ColumnId.Owner,
        type: 'resizableColumn',
        cellRenderer: 'ownerCellRenderer',
        filter: enableFilter && 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
        editable: (params) => {
            return isEditableColumn(params.data?.owner?.id, ColumnId.Owner, isEditable, drilldownColumnId);
        },
        cellStyle: { overflow: 'visible', display: 'flex', alignItems: 'center' },
        cellEditorSelector: () => {
            return {
                component: peopleSelectorCellEditor,
                popup: true,
            };
        },
        cellEditorParams: {
            fixedRowHeight,
        },
        getQuickFilterText: ({ data }) => {
            return (data?.owner as any)?.name || 'Unassigned';
        },
        filterValueGetter: ({ data }) => {
            return (data?.owner as any)?.name || 'Unassigned';
        },
        tooltipValueGetter: ({ data }) => {
            return (data?.owner as any)?.name || 'Unassigned';
        },
        equals: (person1, person2) => {
            return person1?.id === person2?.id;
        },
        filterParams: {
            valueGetter: (params) => {
                return params.data.owner?.name;
            },
        },
        comparator: (personA: Person, personB: Person) => {
            if (personA?.name === personB?.name) return 0;
            return (personA?.name || 'unassigned') > (personB?.name || 'unassigned') ? 1 : -1;
        },
        cellClassRules: {
            [EMPTY_AND_REQUIRED_CLASS_NAME]: (params) => {
                return !!params.data && getIsFieldEmptyAndRequired(SpecialFieldsKey.OWNER, params.data);
            },
        },
        headerClass: ({ column }) => {
            const classes: string[] = [];
            if (shouldShowRequiredIndication(SpecialFieldsKey.OWNER)) {
                classes.push(REQUIRED_HEADER_CLASS_NAME);
            }
            if (isEmphasizeText) {
                classes.push(EMPHASIZED_HEADER_CLASS_NAME);
            }
            if (column?.getColId() && filteredColumnIds?.includes(column.getColId())) {
                classes.push(FILLED_FILTER_ICON_CLASS_NAME);
            }
            return classes;
        },
    } as ColDef<InitiativeRowData>;
};

export default getOwnerDefinition;
