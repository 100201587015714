import { useAngularService } from 'angulareact';
import React from 'react';

import InputSourceSelectionContentConfiguration from '../../../../../../infrastructure/components/Contracts/InputSourceSelectionContentConfiguration';
import WorkflowVersionInputSourceType from '../../../../entities/WorkflowVersionInputSourceType';
import type { InputSourceContentComponentProps } from '../../InputSourceContentComponentProps';
import { SelectInputSourceTitleContainer } from '../sharedComponents';

import { useTonkeanService } from '@tonkean/angular-hooks';
import { H4, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { ClickableLink } from '@tonkean/tui-buttons/Link';

const AnotherModuleInputSourceSelectionContent: React.FC<InputSourceContentComponentProps> = ({
    setSelectedInputSourceType,
    workflowVersion,
}) => {
    const {
        loading: isLoadingContracts,
        data: contractsSummary,
        error: errorLoadingContractSummaries,
        rerun: rerunLoadContractSummaries,
    } = useTonkeanService('getContractSummaries', workflowVersion.groupId);

    const workflowVersionManager = useAngularService('workflowVersionManager');

    return (
        <div data-automation="another-module-configuration">
            <SelectInputSourceTitleContainer>
                <H4 data-automation="another-module-title" $bold>
                    Another Module
                </H4>

                <ClickableLink
                    data-automation="another-module-change-method"
                    onClick={() => {
                        setSelectedInputSourceType(WorkflowVersionInputSourceType.MANUAL);
                    }}
                >
                    Change Intake Method
                </ClickableLink>
            </SelectInputSourceTitleContainer>

            {errorLoadingContractSummaries ? (
                <SimpleErrorStateMessage
                    dataAutomation="another-module-configuration-error"
                    error={errorLoadingContractSummaries}
                    reload={rerunLoadContractSummaries}
                />
            ) : (
                <InputSourceSelectionContentConfiguration
                    groupId={workflowVersion.groupId}
                    workflowVersionType={workflowVersion.workflowVersionType}
                    workflowVersionId={workflowVersion.id}
                    contracts={contractsSummary?.entities}
                    loading={isLoadingContracts}
                    onConfigurationChanged={() => {
                        workflowVersionManager.incrementDraftCounter(workflowVersion.groupId);
                    }}
                />
            )}
        </div>
    );
};

export default AnotherModuleInputSourceSelectionContent;
