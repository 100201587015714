export enum AdobeSignAuthenticationType {
    OAUTH = 'OAuth',
    ACCESS_TOKEN = 'Access_Token',
}

export const authTypeToDisplayName: Record<AdobeSignAuthenticationType, string> = {
    [AdobeSignAuthenticationType.OAUTH]: 'OAuth',
    [AdobeSignAuthenticationType.ACCESS_TOKEN]: 'Access Token',
};

export default AdobeSignAuthenticationType;
