import template from './innerTracksTemplateConfig.template.html?angularjs';

/**
 * Component to define manual initiative creation configuration.
 */
export default angular.module('tonkean.app').component('tnkInnerTracksTemplateConfig', {
    bindings: {
        existingInnerTracksTemplate: '<', // The existing inner tracks template to be loaded into the component.
        hideApplyOnPreviouslyCreatedTracks: '<', // If true, will not show the checkbox offering you to apply template to previously created tracks.
        newTrackPlaceholder: '@',
        onInnerTracksTemplateConfigChanged: '&', // Fires whenever changes are made in the inner tracks template configuration.
    },
    template,
    controller: 'InnerTracksTemplateConfigCtrl',
});
