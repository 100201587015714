import { reactToAngular } from '@tonkean/angular-components';
import { FieldSelector } from '@tonkean/fields';

/**
 * Field definition selector
 */

export default angular
    .module('tonkean.app')
    .component(
        'tnkFieldSelectorReact',
        reactToAngular(FieldSelector, [
            'fieldsDefinitions',
            'typesToInclude',
            'isClearable',
            'name',
            'includeSpecialFields',
            'onChange',
            'fieldDefinitionId',
            'thin',
            'size',
            'onlyMultiValueFields',
            'onlyForMatchedItems',
        ]),
    );
