import type { BaseWidgetHandler } from './BaseWidgetHandler';
import DetailedItemsWidgetHandler from './DetailedItemsWidgetHandler';
import FieldsItemsWidgetHandler from './FieldsItemsWidgetHandler';
import InnerItemsWidgetHandler from './InnerItemsWidgetHandler';
import LineItemsWidgetHandler from './LineItemsWidgetHandler';
import ModuleItemsWidgetHandler from './ModuleItemsWidgetHandler';
import QuestionWidgetHandler from './QuestionWidgetHandler';
import UploadWidgetHandler from './UploadWidgetHandler';

import type { WidgetBase } from '@tonkean/tonkean-entities';
import { ItemInterfaceWidgetType } from '@tonkean/tonkean-entities';

const ItemInterfaceWidgetHandler = (widget: WidgetBase): BaseWidgetHandler | undefined => {
    switch (widget.type) {
        case ItemInterfaceWidgetType.INNER_ITEMS: {
            return new InnerItemsWidgetHandler();
        }
        case ItemInterfaceWidgetType.LINE_ITEMS: {
            return new LineItemsWidgetHandler();
        }
        case ItemInterfaceWidgetType.MODULE_ITEMS: {
            return new ModuleItemsWidgetHandler();
        }
        case ItemInterfaceWidgetType.DETAILED_ITEMS: {
            return new DetailedItemsWidgetHandler();
        }

        case ItemInterfaceWidgetType.FIELDS: {
            return new FieldsItemsWidgetHandler();
        }
        case ItemInterfaceWidgetType.UPLOAD: {
            return new UploadWidgetHandler();
        }

        case ItemInterfaceWidgetType.QUESTION: {
            return new QuestionWidgetHandler();
        }
        default: {
            return undefined;
        }
    }
};

export default ItemInterfaceWidgetHandler;
