import React, { useMemo } from 'react';
import styled from 'styled-components';

import ImageWidgetUploader from './ImageWidgetUploader';
import type { ItemWidgetEditorProps } from '../../../WidgetModule';
import { ItemInterfaceWidgetUrlExpression } from '../../CommonWidgetComponents';
import { ImageInputType, ImageInputTypes } from '../../entities';
import type ImageWidgetConfiguration from '../ImageWidgetConfiguration';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import { FormikTnkProjectIntegrationSelector } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { FormikEntitySelector } from '@tonkean/angular-to-react-components';
import { Field, SimpleSelect, useFormikField } from '@tonkean/infrastructure';
import { ProjectIntegrationActionSelector } from '@tonkean/infrastructure';
import { useEntityAvailabeExternalFieldTonkeanExpressionTab } from '@tonkean/infrastructure';
import { ActionType } from '@tonkean/tonkean-entities';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';

const Wrapper = styled.div`
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const EntitySelectorWrapper = styled.div`
    .entity-selector-react {
        width: 100%;
    }
`;

const ACTION_TYPES = [ActionType.STREAM_IMAGE];

const ImageWidgetEditor: React.FC<ItemWidgetEditorProps<ImageWidgetConfiguration>> = ({
    configuration,
    selectedWidget,
    onSave,
    workflowVersion,
}) => {
    const { setValue: setImageInputType, value: imageInputType } = useFormikField<string | undefined>(
        'configuration.imageInputType',
    );

    // Backward compatibility default assignment (widget was created before imageInputType was added
    if (imageInputType === undefined) {
        setImageInputType((prevValue) => (prevValue === undefined ? ImageInputType.IMAGE_FROM_FIELD : prevValue));
    }
    const enableStreamImageFeature = useFeatureFlag('tonkean_enable_stream_service_features');

    const { setValue: setImageAction } = useFormikField<string | undefined>(
        'configuration.imageStreamSelectedActionId',
    );

    const { setValue: setImageIntegration } = useFormikField<ProjectIntegration | undefined>(
        'configuration.imageStreamSelectedProjectIntegration',
    );

    const availableExternalFieldTab = useEntityAvailabeExternalFieldTonkeanExpressionTab(
        configuration.imageStreamSelectedProjectIntegration?.id,
        configuration.integrationEntityApiName,
    );

    const additionalTabs: TonkeanExpressionAdditionalTab[] = useMemo(() => {
        return [availableExternalFieldTab];
    }, [availableExternalFieldTab]);

    return (
        <Wrapper>
            {enableStreamImageFeature && (
                <Field label="Image selection method" informationTooltip="Select method of fetching image URL" light>
                    <SimpleSelect name="configuration.imageInputType" options={ImageInputTypes} thin />
                </Field>
            )}

            {enableStreamImageFeature && imageInputType === ImageInputType.IMAGE_FROM_ACTION ? (
                <>
                    <Field label="Data Source" light>
                        <FormikTnkProjectIntegrationSelector
                            name="configuration.imageStreamSelectedProjectIntegrationId"
                            onProjectIntegrationSelected={(projectIntegration) => {
                                setImageIntegration(projectIntegration);
                                configuration.imageStreamSelectedProjectIntegration = undefined;
                            }}
                            includeAllNoCodeDatasources
                        />
                    </Field>
                    <Field label="Entity">
                        <EntitySelectorWrapper>
                            <FormikEntitySelector
                                name="configuration.integrationEntityApiName"
                                projectIntegration={configuration.imageStreamSelectedProjectIntegration}
                            />
                        </EntitySelectorWrapper>
                    </Field>

                    {configuration?.imageStreamSelectedProjectIntegration?.id && (
                        <Field
                            label="Data Source Action"
                            informationTooltip="The action should be of type 'Stream Image' and fetch based on the image URL provided"
                            light
                        >
                            <ProjectIntegrationActionSelector
                                selectedProjectIntegrationActionId={configuration.imageStreamSelectedActionId}
                                projectIntegration={configuration.imageStreamSelectedProjectIntegration.id}
                                actionTypes={ACTION_TYPES}
                                onProjectIntegrationActionChanged={setImageAction}
                            />
                        </Field>
                    )}

                    {configuration?.imageStreamSelectedActionId && (
                        <>
                            <Field label="Image Url" light>
                                <FormikTonkeanExpression
                                    workflowVersionId={workflowVersion.id}
                                    groupId={workflowVersion.groupId}
                                    name="configuration.image.url"
                                    dataAutomation="image-widget-editor-image-url"
                                    placeholder="Insert image url"
                                    additionalTabs={additionalTabs}
                                    defaultTabId={availableExternalFieldTab.id}
                                    showFormulasTab={false}
                                    disabled={false}
                                    hideEditorButton
                                />
                            </Field>
                            <Field label="Image External ID" light>
                                <FormikTonkeanExpression
                                    workflowVersionId={workflowVersion.id}
                                    groupId={workflowVersion.groupId}
                                    name="configuration.imageExternalIdentifier"
                                    dataAutomation="image-widget-editor-image-external-id"
                                    placeholder="Insert image external id"
                                    additionalTabs={additionalTabs}
                                    defaultTabId={availableExternalFieldTab.id}
                                    showFormulasTab={false}
                                    doNotEvaluatePreview
                                    hideEditorButton
                                />
                            </Field>
                        </>
                    )}
                </>
            ) : (
                <>
                    <ImageWidgetUploader
                        selectedWidget={selectedWidget}
                        configuration={configuration}
                        onSave={onSave}
                    />
                    <ItemInterfaceWidgetUrlExpression
                        placeholder="Insert image url"
                        name="configuration.image.url"
                        label="Image Url"
                        dataAutomation="image-widget-editor-image-url"
                    />
                </>
            )}
        </Wrapper>
    );
};

export default ImageWidgetEditor;
