import { FieldType, PresetItemInterfaceWidgetType } from '@tonkean/tonkean-entities';

export const fieldTypeToPresetItemInterfaceWidgetTypes: Record<FieldType, PresetItemInterfaceWidgetType[]> = {
    [FieldType.String]: [PresetItemInterfaceWidgetType.QUESTION_TEXT],
    [FieldType.Date]: [PresetItemInterfaceWidgetType.QUESTION_DATE],
    [FieldType.Number]: [PresetItemInterfaceWidgetType.QUESTION_NUMBER],
    [FieldType.Boolean]: [PresetItemInterfaceWidgetType.QUESTION_TEXT],
    [FieldType.LongString]: [
        PresetItemInterfaceWidgetType.QUESTION_TEXT,
        PresetItemInterfaceWidgetType.RICH_TEXT_EDITOR_INPUT,
    ],
    [FieldType.List]: [PresetItemInterfaceWidgetType.QUESTION_SELECT, PresetItemInterfaceWidgetType.QUESTION_LIST],
    [FieldType.URL]: [PresetItemInterfaceWidgetType.QUESTION_TEXT],
};
