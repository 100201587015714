import template from './columnFormulaField.template.html?angularjs';

/**
 * Definition of a column formula field.
 */
angular.module('tonkean.app').component('tnkColumnFormulaField', {
    bindings: {
        createMode: '<',
        duplicateMode: '<',
        projectIntegration: '<',
        groupId: '<',
        workflowVersionId: '@',
        targetType: '<',
        additionalTabs: '<',
        customTrigger: '<',

        overrideFormulaOperator: '<',
        existingFieldDefinition: '<',

        fieldDefinitionName: '<',
        fieldDefinitionNameEdited: '<',

        aggregationOnColumnFeatureName: '@',
        columnFormulaFeatureName: '@',

        formulaExpressionTree: '<',
        onSelectInnerTrackAggregation: '<',

        onDefinitionChange: '&',
        onFieldDefinitionNameChange: '&',

        hideInnerTrackAggregationFormula: '<',
        onChange: '&',
        workflowVersionType: '<',
    },
    template,
    controller: 'ColumnFormulaFieldCtrl',
});
