import template from './tnkSetReminderPopover.template.html?angularjs';
import { analyticsWrapper } from '@tonkean/analytics';
import { REMINDERS } from '@tonkean/constants';
import { calculateNextReminderHour } from '@tonkean/tonkean-utils';

function tnkSetReminderPopover() {
    return {
        restrict: 'E',
        scope: {
            // the initiative to work on
            item: '=initiative',
            // true to hide the custom option
            hideCustom: '=',
            // an object to save changes on
            manualGatherUpdate: '=',
            // true if the server shouldn't be updated on set reminder
            dontUpdateServer: '<',
            // If given and set to true, the tooltip will not be displayed.
            noTooltip: '<',
            // gets called when the server returns a response
            onSave: '&',
            // gets called when the custom option is selected
            onCustom: '&',
            // gets called when setting a reminder
            onSetReminder: '&',
            // gets called when user selected 'ask now' and there isn't an owner on the initiative (didnt save to server yet), expects a return value of string
            provideOwnerName: '&',
            // optional label for the custom option, if empty has a default fallback
            customLabel: '@',
            // If true will display a default dropdown toggle label, if not will use ng-transclude
            defaultDropdownToggleLabel: '<',
            // Whether to block users from editing
            viewOnly: '<',
            // If true close dropdown
            forceCloseDropdown: '<',
        },
        transclude: true,
        template,
        controller: 'SetReminderPopoverCtrl',
    };
}
angular.module('tonkean.app').directive('tnkSetReminderPopover', tnkSetReminderPopover);

function SetReminderPopoverCtrl(
    $scope,
    $rootScope,
    licensePermissionsService,
    authenticationService,
    trackHelper,
    modal,
    utils,
) {
    $scope.pm = $rootScope.pm;
    $scope.as = authenticationService;
    $scope.lps = licensePermissionsService;
    $scope.data = {
        customDate: {},
    };
    $scope.minDate = new Date();
    $scope.reminders = REMINDERS;
    $scope.data = {
        dropdownOpen: false,
    };

    $scope.setReminder = function (item, when) {
        $scope.data.dropdownOpen = false;

        const hour = calculateNextReminderHour(when);

        if ($scope.manualGatherUpdate) {
            $scope.manualGatherUpdate.date = utils.getTimeDiffFromNow(when.days, hour);
        }

        if ($scope.onSetReminder) {
            $scope.onSetReminder();
        }

        if (!$scope.dontUpdateServer) {
            analyticsWrapper.track('Remind me', { category: 'Tracks editor', label: when.label });
            trackHelper.updateNextReminder(item, when.days, hour).then(function (data) {
                item.nextGatherUpdate = data.nextGatherUpdate;

                if ($scope.onSave) {
                    $scope.onSave();
                }
            });
        }
    };

    $scope.askForUpdateNow = function (item) {
        $scope.data.dropdownOpen = false;

        let ownerName = item.owner ? item.owner.name : null;

        if ($scope.provideOwnerName) {
            ownerName = $scope.provideOwnerName();
        }

        modal.openAskForUpdateModal(item, ownerName);
    };

    $scope.onCustomClicked = function (item) {
        $scope.data.dropdownOpen = false;

        if ($scope.onCustom) {
            $scope.onCustom(item);
        }
    };

    $scope.$watch('forceCloseDropdown', () => {
        if ($scope.forceCloseDropdown && $scope.data.dropdownOpen) {
            $scope.data.dropdownOpen = false;
        }
    });
}

angular.module('tonkean.app').controller('SetReminderPopoverCtrl', SetReminderPopoverCtrl);
