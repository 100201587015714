<div class="tnk-collect-inner-items-form">
    <div
        ng-if="data.minimumItemsAmount"
        class="common-size-xs margin-bottom"
        data-automation="collect-inner-items-form-minimum-items-message"
    >
        You are required to enter {{ data.minimumItemsAmount }} or more items/answers in this form.
    </div>
    <!-- Loading-->
    <div class="flex mod-justify-center" ng-if="data.loadingForm">
        <i class="loading-small margin-right margin-top-xxs"></i>
        Loading Form, Please Wait...
    </div>

    <!-- Form -->
    <div ng-if="!data.loadingForm">
        <!-- Form Question -->
        <div class="flex mod-justify-space margin-bottom-md mod-align-center">
            <div
                class="margin-right-lg"
                ng-if="data.formQuestionToDisplay"
                bind-user-html="data.formQuestionToDisplay"
            ></div>

            <a>
                <tnk-import-inner-items-from-csv-modal
                    disabled="data.error || !data.isGroupCollaborator"
                    form-name="data.questionInitiative.title"
                    on-exited="(omImportFinished)"
                    field-ids-to-field-names="data.fieldIdsToFieldNames"
                    on-mappings-created="(onCsvMappingCreated)"
                ></tnk-import-inner-items-from-csv-modal>
            </a>
        </div>

        <!-- Inner Tracks Editor -->
        <div class="overflow-scroll-horizontal tracks-editor-wrapper" id="inner-items-container">
            <tnk-tracks-editor
                ng-if="!data.error && data.questionInitiative"
                class="overflow-scroll-horizontal display-table common-width-100"
                tracks="data.initiatives"
                editor-id="collect-items-update-form"
                hide-column-quick-create-force="true"
                show-header="true"
                header-max-lines="data.formConfig.definition.headerMaxLines"
                show-add-on-empty="true"
                group-id="data.groupId"
                only-group="data.groupId"
                parent-item="data.questionInitiative"
                create-tracks-in-edit-mode="true"
                workflow-version-id="data.formConfig.workflowVersion.id"
                display-fields-list="data.fieldsIds"
                on-initiative-created="onInitiativeCreated(initiative, addUnderItemId)"
                on-track-removed="(onTrackRemoved)"
                ignore-column-visibility="true"
                hide-bulk-selection="true"
                hide-context-menu="true"
                add-item-label="{{ data.formConfig.definition.addItemButtonLabel }}"
                collect-items-mode="true"
                created-in-form-id="data.formConfig.id"
                created-in-form-name="{{ data.formConfig.displayName }}"
                created-in-form="data.formConfig"
                do-not-allow-edit-published-initiatives="false"
                disable-new-item-typeahead="true"
                no-subitems="true"
                disable-go-to-track="true"
                disable-owner-invite="true"
                custom-fields="null"
                field-validation-map="data.itemsValidationMap"
                hide-history="true"
                hide-title="data.fieldsIds.indexOf('TNK_TITLE') === -1"
                load-next-initiatives-page-callback="loadNextPage()"
                has-more-initiatives="data.hasMorePages"
                scroller-element-id="inner-items-container"
                list-parent-element-id="inner-items-container"
                lazy-load-tracks="true"
                sticky-header="true"
                solution-business-report-id="data.solutionBusinessReportId"
            ></tnk-tracks-editor>
        </div>

        <!-- Submit form button -->
        <div
            class="flex-vmiddle margin-top-lg"
            ng-class="{
                'flex-space-between': data.showItemsAmountError && !data.showBack,
                'mod-justify-end': !data.showBack,
                'mod-justify-space': data.showBack
            }"
        >
            <button
                ng-if="data.showBack"
                ng-click="handleBackClicked()"
                class="btn btn-secondary margin-right back-button"
                ng-style="{
                    'color': data.buttonsColor ,
                    'border-color': data.buttonsColor,
                }"
                ng-disabled="data.backLoading"
                data-automation="sequence-back-button"
            >
                Back
                <i class="loading-small margin-left-xs" ng-if="data.backLoading"></i>
            </button>

            <tnk-submit-form-button
                class="margin-left"
                label="data.formConfig.definition.overrideSubmitFormButtonLabel || data.formConfig.definition.submitFormButtonLabel"
                primary-color="data.primaryColor"
                secondary-color="data.secondaryColor"
                buttons-color="data.buttonsColor"
                form-finished="data.formFinished"
                on-submit="submitForm(ignoreNonRestrictingErrors)"
                all-errors-not-restricting-errors="data.allErrorsNotRestrictingErrors"
                disabled="data.showItemsAmountError || data.error || !data.isGroupCollaborator"
            ></tnk-submit-form-button>
        </div>
        <div ng-if="data.showEmptyTitleError || data.error" class="margin-top">
            <div ng-if="data.showEmptyTitleError" class="common-size-xs common-color-danger">
                You must give all items a title, or remove the filled in fields from items with no title
            </div>

            <div ng-if="data.error" class="common-size-xs margin-left-md common-color-danger">
                {{ data.error }}
            </div>
        </div>
    </div>
</div>
