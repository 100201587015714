import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as EyeIcon } from '../../../../../../../images/icons/eye-thin.svg';
import { ReactComponent as HistoryIcon } from '../../../../../../../images/icons/history.svg';
import { ReactComponent as SettingsIcon } from '../../../../../../../images/icons/settings-2.svg';

import { Chevron, ChevronDirection, Menu, MenuItem } from '@tonkean/infrastructure';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 110px;
    height: 26px;

    border: none;
    background-color: transparent;
    padding: 0;

    border-radius: 3px;

    &:hover {
        background-color: ${Theme.colors.gray_300};
    }

    ${styledFocus}
`;

const StyledEye = styled(EyeIcon)`
    height: 12px;
    width: 18px;
`;

const StyledHistoryIcon = styled(HistoryIcon)`
    path {
        fill: ${Theme.colors.primary};
    }
`;

const BlueText = styled.span`
    color: ${Theme.colors.primary};
`;

interface Props {
    onHistoryClick: () => void;
    onBackgroundProcessesClick: () => void;
}

const GridViewMoreMenu: React.FC<Props> = ({ onHistoryClick, onBackgroundProcessesClick }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    return (
        <Menu
            show={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            menuItems={
                <>
                    <MenuItem
                        onClick={onHistoryClick}
                        icon={<StyledHistoryIcon />}
                        data-automation="solution-metric-grid-history-button"
                    >
                        <BlueText>Module History</BlueText>
                    </MenuItem>
                    <MenuItem
                        onClick={onBackgroundProcessesClick}
                        icon={<SettingsIcon />}
                        data-automation="solution-metric-grid-bp-button"
                    >
                        <BlueText>Background Processes</BlueText>
                    </MenuItem>
                </>
            }
            placement="bottom-start"
            thin
        >
            <Wrapper onClick={() => setIsMenuOpen((prevOpen) => !prevOpen)}>
                <StyledEye />
                View More
                <Chevron direction={isMenuOpen ? ChevronDirection.UP : ChevronDirection.DOWN} />
            </Wrapper>
        </Menu>
    );
};

export default GridViewMoreMenu;
