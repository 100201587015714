import type { EnterpriseComponentPageMenuItemConfiguration } from '../entities/EnterpriseComponentPageMenuConfiguration';

import type { PersonPermissionRoleType } from '@tonkean/tonkean-entities';

export function isMenuItemVisible(
    menuItem: EnterpriseComponentPageMenuItemConfiguration<any, any>,
    currentUserAccess: PersonPermissionRoleType,
): boolean {
    return !menuItem.hideItem && menuItem.permissionRoles.includes(currentUserAccess);
}
