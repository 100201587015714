import React from 'react';
import styled from 'styled-components';

import { NoItemsIcon } from '@tonkean/svg';
import { FontSize } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

const Label = styled.div`
    font-size: ${FontSize.MEDIUM_14};
    font-weight: 500;
`;

const DetailedItemsWidgetNoItems = () => {
    return (
        <Wrapper>
            <NoItemsIcon />
            <Label>No Items to show</Label>
        </Wrapper>
    );
};

export default DetailedItemsWidgetNoItems;
