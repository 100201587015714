import BotHistoryItemDispatcher from '../dispatchers/BotHistoryItemDispatcher';
import { TrackDispatcher } from '@tonkean/flux';
import { TrackActionTypes } from '@tonkean/flux';
import BotProjectedItemDispatcher from '../dispatchers/BotProjectedItemDispatcher';
import { BotProjectedItemActionTypes } from '../actions/BotProjectedItemActions';
import { TonkeanStore } from '@tonkean/shared-services';

/**
 * The main track store.
 * Since we are still in an AngularJS app, the store doesn't actively manage it's data, but delegates everything to the outside component.
 */
class BotProjectedItemStore extends TonkeanStore {
    /* @ngInject */

    constructor(trackHelper) {
        // Initialize our super with the dispatchers we need.
        super([BotHistoryItemDispatcher, BotProjectedItemDispatcher, TrackDispatcher]);

        this.trackHelper = trackHelper;
    }

    getNewState(sectionId, ownerId) {
        if (!this.states[sectionId]) {
            this.states[sectionId] = {};
        }

        // Reset the state of the given id to a new one: requesting a new state means the component was re-constructed.
        this.states[sectionId][ownerId] = {
            setReminderDropdownOpen: false,
            showMoreInitiatives: false,
        };

        // Return the state to the component.
        return this.states[sectionId][ownerId];
    }

    getState(sectionId, ownerId) {
        return this.states[sectionId][ownerId];
    }

    /**
     * A helper function for our components.
     * @param trackId - the component's id.
     * @param editorId - the component's editor id.
     * @param requestedIdsObject - the idsObject the component has received via the store's emit function (contains the id and editor id, if any).
     * @returns {boolean} - true if the component should re-render itself.
     */
    shouldItemUpdate(ownerId, sectionId, requestedIdsObject) {
        // If there's no id and not editor id, all tracks should re-render.
        if (!requestedIdsObject) {
            return true;
        }
        // No editor specified - all components with the requested track id should re-render.
        if (!requestedIdsObject.sectionId && ownerId === requestedIdsObject.ownerId) {
            return true;
        }
        // No id specified - all components of this editor should re-render.
        if (!requestedIdsObject.ownerId && sectionId === requestedIdsObject.sectionId) {
            return true;
        }
        // The specified id and editor id match this tracks ids - re-render.
        if (ownerId === requestedIdsObject.ownerId && sectionId === requestedIdsObject.sectionId) {
            return true;
        }

        return false;
    }

    /**
     * The listener function that waits for our dispatcher to dispatch a new update.
     * @param action - the action the dispatcher dispatched.
     */
    onDispatch(action) {
        switch (action.type) {
            case TrackActionTypes.TOGGLE_TRACK_SET_REMINDER_DROPDOWN:
                if (this.states[action.editorId]) {
                    this.states[action.editorId][action.id].setReminderDropdownOpen = action.isTrue;
                    this.emit(action.editorId, action.id);
                }
                break;

            case BotProjectedItemActionTypes.SET_REMINDER_DROPDOWN_TARGET:
                this.states[action.sectionId][action.ownerId].setReminderDropdownTarget = action.isTrue;
                this.emit(action.sectionId, action.ownerId);
                break;

            case BotProjectedItemActionTypes.TOGGLE_SHOW_MORE_INITIATIVES:
                this.states[action.sectionId][action.ownerId].showMoreInitiatives = action.isTrue;
                this.emit(action.sectionId, action.ownerId);
                break;
        }
    }
}

export default angular.module('tonkean.app').service('botProjectedItemStore', BotProjectedItemStore);
