import React from 'react';
import styled from 'styled-components';

import { Field, Toggle } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledField = styled(Field)`
    display: flex;
    justify-content: space-between;
`;

const MarketplaceItemUsage: React.FC = () => {
    return (
        <>
            <StyledField label="Will the item be view only (not downloadable) in the library" showLabelInline>
                <Toggle name="isViewOnly" onClick={(e) => e.stopPropagation()} size={InputSize.SMALL} />
            </StyledField>
            <StyledField
                label="Will the item be hidden (not shown in the library, aceess only through url)"
                showLabelInline
            >
                <Toggle name="isHidden" onClick={(e) => e.stopPropagation()} size={InputSize.SMALL} />
            </StyledField>
        </>
    );
};

export default MarketplaceItemUsage;
