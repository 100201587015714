import React from 'react';
import styled from 'styled-components';

import type { EditableTextProps } from './EditableText';
import EditableText from './EditableText';

import { FontSize } from '@tonkean/tui-theme';

const StyledEditableText = styled(EditableText)`
    height: 45px;
`;

interface Props extends EditableTextProps {}

const EditableTitle: React.FC<Props> = (props) => {
    return (
        <StyledEditableText
            {...props}
            fontSize={props.fontSize ?? FontSize.XXLARGE_20}
            showUnderline={props.showUnderline ?? true}
        />
    );
};

export default EditableTitle;
