import React, { useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';

import CommentCard from './CommentCard';

import { Breakpoint } from '@tonkean/infrastructure';
import type {
    Person,
    TonkeanId,
    TonkeanType,
    NewActivityData,
    Activity,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps } from '@tonkean/utils';

const ListWrapper = styled.div`
    display: grid;
    max-height: 415px;
    overflow: auto;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: 0;
        row-gap: 0;
    }
`;

const StyledCommentCard = styled(CommentCard)`
    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 14px;
        border: none;
        border-radius: 0;
        border-top: 1px solid ${Theme.colors.gray_200};
        border-bottom: 1px solid ${Theme.colors.gray_200};

        &:first-child {
            border-top: none;
        }
    }
`;

interface CommentListProps extends DataAutomationSupportProps {
    currentUser: Person;
    comments: Activity[];
    onCommentAdded: (newActivity: NewActivityData) => void;
    onCommentDelete: (commentId: string, targetId?: string) => void;
    threadMaxHeight?: number | undefined;
    canUserEditItem: boolean;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>;
    workflowVersionType: WorkflowVersionType;
    defaultCommentText?: string;
    disableReplyToComment?: boolean;
    orderByAscending?: boolean;
}

const CommentList: React.FC<CommentListProps> = ({
    comments,
    currentUser,
    onCommentAdded,
    onCommentDelete,
    threadMaxHeight,
    canUserEditItem,
    initiativeId,
    workflowVersionType,
    defaultCommentText,
    disableReplyToComment,
    orderByAscending = false,
}) => {
    const sortedComments = useMemo(() => {
        return comments.sort((first, second) => {
            if (orderByAscending) {
                return first.created - second.created;
            }

            return second.created - first.created;
        });
    }, [comments, orderByAscending]);

    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (listRef.current) {
            if (orderByAscending) {
                listRef.current.scrollTop = listRef.current.scrollHeight;
            } else {
                listRef.current.scrollTop = 0;
            }
        }
    }, [comments, orderByAscending]);

    return (
        <ListWrapper ref={listRef}>
            {sortedComments.map((comment) => (
                <StyledCommentCard
                    key={comment.id}
                    currentUser={currentUser}
                    comment={comment}
                    onCommentAdded={onCommentAdded}
                    onCommentDelete={onCommentDelete}
                    threadMaxHeight={threadMaxHeight}
                    canUserEditItem={canUserEditItem}
                    initiativeId={initiativeId}
                    workflowVersionType={workflowVersionType}
                    defaultCommentText={defaultCommentText}
                    disableReplyToComment={disableReplyToComment}
                />
            ))}
        </ListWrapper>
    );
};

export default CommentList;
