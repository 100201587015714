import NoCodeDataSourceCustomSetup from './NoCodeDataSourceCustomSetup';
import webhookCircle from '../../../../apps/tracks/images/integrations/webhook-circle.svg';
import webhookRect from '../../../../apps/tracks/images/webhook.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { DataSourcesSectionType } from '@tonkean/tonkean-entities';

export default class NoCodeDataSourceIntegration extends IntegrationDefinitionBase {
    override name = 'no_code_data_source';
    override displayName = 'No Code Data Source';
    override description = '';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.NONE;
    override dataSourceSectionType = DataSourcesSectionType.CLOUD;
    override showEntitiesInboundConfiguration = false;
    override storageProviderSettings = new StorageProviderSettings(true, true);
    override hasDynamicEntities = true;
    override customizedSetupComponent = NoCodeDataSourceCustomSetup;
    entities = [];

    override supportsSharedCredentials = false;

    override dataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
        ignoreEntities: [],
    };

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(webhookCircle, '130px'),
        new IntegrationIcon(webhookRect, '130px'),
    );
}
