import { angularToReact } from '@tonkean/angular-components';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';

const TnkFieldSelector = angularToReact<{
    selectedField?: any;
    itemsSource: 'EXTERNAL' | 'COLUMN';
    projectIntegration?: ProjectIntegration;
    externalType?: string;
    onFieldSelected: (selectedField: any, selectedFieldIdentifier: string, dontSaveChanges: boolean) => void;
    placeHolder?: string;
    fieldInspectModal: boolean;
    fieldInspectAutoOpen: boolean;
    viewOnly?: boolean;
    groupId?: string;
    workflowVersionId?: string;
    groupBy?: string;
    fieldsFilter?: (data: any) => any;
    fieldInspectFilter?: (data: any) => any;
    includeGlobalFields?: boolean;
    addFieldsQuickCreateOptions?: boolean;
    quickCreateMatchedEntity?: boolean;
    reloadFields?: boolean;
    doNotDisplaySelectedField?: boolean;
    selectedFieldIdentifier?: string;
    onlyUpdatableFieldDefinitions?: boolean;
    specialFieldsForFeatures?: string[];
    excludedTabSelectorSpecialFields?: string[];
    includeTabSelectorSpecialFieldsForFeatures?: string[];
    allowClearSelection?: boolean;
}>('tnk-field-selector', {
    onFieldSelected: ['selectedField', 'selectedFieldIdentifier', 'dontSaveChanges'],
});

export default TnkFieldSelector;
