// http://jsfiddle.net/eXQf3/753/   OR   https://github.com/angular-ui/ui-select/issues/832

function preventScrollBubbling() {
    return {
        restrict: 'A',
        link(scope, element, attrs) {
            // If the attribute itself is compiled into false, dont run this directive
            if (attrs.preventScrollBubbling !== 'false') {
                element.bind('mousewheel', function (event) {
                    const heightDif = this.offsetHeight - this.clientHeight;
                    const maxScrollTop = this.scrollHeight - this.offsetHeight + heightDif;

                    if (
                        (this.scrollTop === maxScrollTop && event.deltaY > 0) ||
                        (this.scrollTop === 0 && event.deltaY < 0)
                    ) {
                        event.preventDefault();
                    }
                });
            }
        },
    };
}
export default angular.module('tonkean.app').directive('preventScrollBubbling', preventScrollBubbling);
