import React from 'react';
import type { Row } from 'react-table';

import { Chevron, ChevronDirection } from '@tonkean/infrastructure';

interface Props<T extends { expendable?: boolean }> {
    row: Row<T>;
    open?: boolean;
}

const TableChevronCell = <T extends { expendable?: boolean }>({ row, open }: Props<T>) => {
    if (!!row.original.expendable) {
        return <Chevron direction={open ? ChevronDirection.DOWN : ChevronDirection.RIGHT} />;
    }

    return <></>;
};

export default TableChevronCell;
