import useWorkflowVersionCacheSynchronizer from './useWorkflowVersionCacheSynchronizer';
import useTonkeanQuery from '../utils/reactQuery/useTonkeanQuery';

import { getWorkflowVersionByInitiativeId } from '@tonkean/network-service';
import type { TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';

const useTDLWorkflowVersionByInitiativeId = (
    projectId: TonkeanId<TonkeanType.PROJECT> | undefined,
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
) => {
    const workflowVersionCacheSynchronizer = useWorkflowVersionCacheSynchronizer();

    return useTonkeanQuery<WorkflowVersion | undefined>({
        queryUniqueKey: 'getWorkflowVersionByInitiativeId',
        propsUniqueKeysArray: [projectId, initiativeId],
        queryFn: async (networkService) => {
            return getWorkflowVersionByInitiativeId(networkService, projectId!, initiativeId!);
        },
        enabledFn: () => !!projectId && !!initiativeId,
        synchronizeCachesFn: workflowVersionCacheSynchronizer,
    });
};

export default useTDLWorkflowVersionByInitiativeId;
