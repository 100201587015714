<section class="login-page mod-reverse">
    <div class="login-page-desktop-container">
        <div class="common-width-full common-page-load" ng-show="loading">
            <i class="loading-full-circle"></i>
        </div>
        <div class="login-page-content-container mod-centered flex-grow">
            <div class="flex flex-col" ng-hide="loading">
                <div ng-show="error" class="login-token-error-box margin-right-lg">
                    <div class="alert alert-danger text-center" role="alert">{{ error }}</div>
                </div>
                <div class="login-page-content">
                    <div class="flex flex-col login-page-content-box padding-top-xlg">
                        <h1 class="login-page-title">AI-Powered Coordination Module</h1>

                        <div class="common-size-xs margin-top-lg margin-bottom-lg common-thin">
                            <p>
                                With our out-of-the-box people coordination capabilities, the Tonkean Module makes sure
                                things are actually being taking care of.
                            </p>
                            <p>
                                Tonkean knows to proactively follow up at the right time to make sure
                                <strong>nothing falls through the cracks</strong>
                                .
                            </p>
                            <p class="margin-normal-v">
                                It will ask owners you assign for their input, keep your data up to date and alert you
                                if things need your attention.
                            </p>
                            <p class="">Your team doesn’t need to sign up or install anything!</p>
                            <!--<p>-->
                            <!--How do you want to use the Bot?<br/>-->
                            <!--<i class="common-color-grey common-size-xxxxs">(You can always change that later)</i>-->
                            <!--</p>-->
                        </div>
                        <div class="margin-top margin-bottom flex-vmiddle mod-inline">
                            <button
                                class="btn login-btn mod-has-icon mod-primary btn-lg common-bold"
                                ng-click="connectSlack()"
                                analytics-on
                                analytics-category="AddBot"
                                analytics-event="AddBot"
                                analytics-label="Slack"
                            >
                                <span class="flex-vmiddle">
                                    <i class="svg-icon-lg slack-svg-white svg-icon-vmiddle margin-right">
                                        <tnk-icon src="/images/icons/slack-icon.svg"></tnk-icon>
                                    </i>
                                    <span class="flex-grow">Add to Slack</span>
                                    <i class="loading-small margin-left" ng-show="creating"></i>
                                </span>
                            </button>

                            <button
                                class="btn login-btn mod-has-icon mod-primary btn-lg common-bold margin-left"
                                ng-click="close()"
                                analytics-on
                                analytics-category="AddBot"
                                analytics-event="AddBot"
                                data-automation="login-add-bot-add-email"
                                analytics-label="Email"
                            >
                                <span class="flex-vmiddle">
                                    <i class="common-color-white margin-right fa fa-envelope-o"></i>
                                    <span class="flex-grow">Use Email</span>
                                </span>
                            </button>
                        </div>
                        <div class="common-size-xxxxs common-color-light-grey2">
                            You can always change your selection later.
                        </div>
                        <div class="visible-xs flex-grow"></div>
                        <div class="margin-top-xlg padding-top-lg margin-bottom-lg">
                            <div
                                class="cursor-default common-size-xxxxs margin-none common-underline"
                                uib-popover="Nothing yet. After you'll create your list and ACTIVATE the module, it will reach out via Direct Message ONLY to people you’ve set as an owner of a “Track”."
                                popover-class="popover-dark"
                                popover-placement="top"
                                popover-trigger="{{ !isMobile ? 'mouseenter' : 'outsideClick' }}"
                            >
                                What happens when I add the Tonkean BOT?
                            </div>
                        </div>
                        <br />
                        <!--<div class="margin-top-xxlg login-no-slack-link">-->
                        <!--<a class="login-link-grey margin-top-xxlg" ng-click="close()">My team doesn’t have Slack</a>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="login-page-image-box" ng-if="!loading">
            <div class="login-page-image-inner-box">
                <div class="login-page-image mod-bot"></div>
            </div>
        </div>
    </div>

    <!-- MOBILE -->
    <div class="login-page-mobile-container flex flex-col">
        <div class="flex-grow common-page-load" ng-show="loading">
            <i class="loading-full-circle"></i>
        </div>
        <div ng-hide="loading" class="flex flex-col common-height-full">
            <div ng-show="error" class="login-token-error-box">
                <div class="alert alert-danger text-center" role="alert">{{ error }}</div>
            </div>
            <div class="login-page-content">
                <h1 class="login-page-title">
                    <div>Meet Tonkean's A.I. Bot</div>
                </h1>

                <div class="common-size-xxs margin-top-lg">
                    It proactively follows up at the right time to make sure nothing falls through the cracks.
                </div>
            </div>
            <div class="flex-grow"></div>
            <div class="login-page-image-inner-box padding-bottom-lg">
                <div class="login-page-image mod-bot"></div>
            </div>
            <div class="flex-vmiddle mod-justify-center mod-inline margin-bottom-lg padding-bottom-xs">
                <button
                    class="btn login-btn mod-has-icon mod-primary btn-lg common-bold"
                    ng-click="connectSlack()"
                    analytics-on
                    analytics-category="AddBot"
                    analytics-event="AddBot"
                    analytics-label="Slack"
                >
                    <span class="flex-vmiddle">
                        <i class="svg-icon-lg slack-svg-white svg-icon-vmiddle margin-right">
                            <tnk-icon src="/images/icons/slack-icon.svg"></tnk-icon>
                        </i>
                        <span class="flex-grow">Add to Slack</span>
                        <i class="loading-small margin-left" ng-show="creating"></i>
                    </span>
                </button>

                <button
                    class="btn login-btn mod-has-icon mod-primary btn-lg common-bold margin-left"
                    ng-click="close()"
                    analytics-on
                    analytics-category="AddBot"
                    analytics-event="AddBot"
                    data-automation="login-add-bot-add-email"
                    analytics-label="Email"
                >
                    <span class="flex-vmiddle">
                        <i class="common-color-white margin-right fa fa-envelope-o"></i>
                        <span class="flex-grow">Use Email</span>
                    </span>
                </button>
            </div>

            <div class="common-size-xxxxs common-color-light-grey2 text-center">
                You can always change your selection later.
            </div>
            <div class="flex-grow"></div>
        </div>
    </div>
</section>
