<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div ng-repeat="integration in instances">
        <div class="alert alert-danger" ng-show="integration.error">{{ integration.error | error }}</div>
        <div ng-hide="integration.connecting || integration.Obj">
            <form
                name="data.loginModal"
                class="form-horizontal"
                novalidate
                ng-submit="signIn(integration,data.loginModal.$valid)"
            >
                <div class="form-group row">
                    <label for="git-url" class="col-sm-2 control-label">URL</label>

                    <div class="col-sm-10">
                        <input
                            type="text"
                            ng-model="integration.auth.uri"
                            class="form-control"
                            id="git-url"
                            placeholder="Your Git URL"
                            required
                        />

                        <div class="common-subtitle-inner" ng-hide="integration.authType">
                            <br />
                            Examples:
                            <br />
                            git@git.mycompany.com:repo/path.git
                            <br />
                            https://git.mycompany.com/repo/path.git
                            <br />
                            file:///Users/myUser/Code/repoLocalFolder/
                        </div>
                    </div>
                </div>
                <div ng-if="integration.authType === 'basic'">
                    <div class="form-group row">
                        <label for="login-username" class="col-sm-2 control-label">Username</label>

                        <div class="col-sm-10">
                            <input
                                type="text"
                                ng-model="integration.auth.user"
                                class="form-control"
                                id="login-username"
                                placeholder="Username"
                                autocomplete="off"
                                required
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="login-password" class="col-sm-2 control-label">Password</label>

                        <div class="col-sm-10">
                            <input
                                type="password"
                                ng-model="integration.auth.password"
                                class="form-control"
                                id="login-password"
                                autocomplete="off"
                                placeholder="Password"
                                required
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row" ng-if="integration.authType === 'ssh'">
                    <label for="login-sshPassphrase" class="col-sm-2 control-label">Passphrase</label>

                    <div class="col-sm-10">
                        <input
                            type="password"
                            ng-model="integration.auth.sshPassphrase"
                            class="form-control"
                            id="login-sshPassphrase"
                            autocomplete="off"
                            placeholder="Passphrase"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-offset-2 col-sm-10">
                        <button
                            type="submit"
                            analytics-on
                            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                            analytics-event="Authenticate"
                            analytics-label="{{ currentIntegration.name }}"
                            class="btn btn-primary"
                        >
                            Connect
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="text-center" ng-show="integration.connecting">
            <h4>
                <span class="loading"></span>
                Connecting to {{ currentIntegration.displayName }}
            </h4>
        </div>
        <div ng-show="integration.Obj && !integration.connecting">
            <div class="panel panel-default">
                <div class="panel-heading flex-vmiddle">
                    <h4 class="flex-grow">{{ integration.Obj.integrationUri }}</h4>
                    <button
                        type="button"
                        class="btn btn-danger pull-right"
                        ng-show="instances.length > 1"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Delete git"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="removeIntegrationInstance($index)"
                    >
                        Delete
                    </button>
                </div>
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-2">Branch</div>
                        <div class="col-md-10">
                            <ui-select
                                ng-model="integration.branch"
                                ng-disabled="!integration.branches"
                                theme="bootstrap"
                                required
                            >
                                <ui-select-match
                                    placeholder="{{ integration.branches ? 'Select a Branch' : 'Loading Branches...' }}"
                                >
                                    {{ $select.selected.displayName }}
                                </ui-select-match>
                                <ui-select-choices repeat="branch in integration.branches | filter:$select.search">
                                    <div
                                        ng-bind-html="branch.displayName | escapeHtml | highlight: $select.search"
                                    ></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-default btn-block" ng-click="addAnother()" ng-show="$last">
                <i class="fa fa-plus"></i>
                Add Another
            </button>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        analytics-value="{{ instances.length }}"
        type="button"
        ng-click="$dismiss('cancel')"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        analytics-value="{{ instances.length }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
