<form>
    <div class="flex-grow flex flex-xs-col common-color-dark-grey initiative-settings {{ innerCss }}">
        <div class="margin-bottom-lg">
            <div class="common-title-xs">Customize follow ups</div>
            <div>for {{ initiative.title }}</div>
        </div>

        <!-- AUTO -->
        <div class="margin-bottom-lg">
            <tnk-radio-button
                radio-id="custom-followup-auto"
                radio-name="followup-type"
                radio-label="Automated follow ups"
                radio-value="{{ data.followUpTypes.auto }}"
                model="data.selectedFollowUpType"
                is-required="true"
                on-click="setSelectedFollowUpType(onClickParam)"
                on-click-param="(data.followUpTypes.auto)"
            ></tnk-radio-button>
            <div class="padding-left-lg">
                <!-- Set custom reminder -->
                <div ng-if="data.advanceNextReminder" class="padding-right-lg">
                    <div>Set next ping manually:</div>
                    <div class="flex-vmiddle flex-wrap">
                        <div class="input-group common-width-40-no-xs">
                            <span class="input-group-btn margin-right">
                                <button
                                    type="button"
                                    class="btn btn-default new-quest-calendar-icon common-no-outline"
                                    ng-click="reminderDateOpened = true"
                                >
                                    <i class="fa fa-calendar"></i>
                                </button>
                            </span>
                            <!--Tuesday, April, 10, 2018-->
                            <input
                                type="text"
                                readonly
                                class="form-control pointer"
                                ng-click="reminderDateOpened=true"
                                uib-datepicker-popup="EEE, MMM, dd, yyyy"
                                datepicker-options="data.datePickerOptions"
                                is-open="reminderDateOpened"
                                close-text="Close"
                                ng-model="data.reminderDate"
                                ng-change="data.reminderDateChanged = true; data.dateIsInPast = false;"
                            />
                        </div>
                        <div
                            uib-timepicker
                            ng-model="data.reminderDate"
                            show-spinners="false"
                            ng-change="data.reminderDateChanged = true; data.dateIsInPast = false;"
                        ></div>
                        <a
                            ng-if="data.reminderDate"
                            ng-click="data.reminderDateChanged = true; data.dateIsInPast = false; data.reminderDate = null;"
                            class="margin-left"
                        >
                            Clear
                        </a>
                    </div>
                    <div ng-if="data.dateIsInPast" class="common-color-danger">Can't set a reminder in the past.</div>
                    <div class="text-right margin-top" ng-show="data.reminderDateChanged">
                        <button class="btn btn-primary" ng-click="setReminder()">Save</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- RECURRING -->
        <div class="margin-bottom-lg">
            <tnk-radio-button
                radio-id="custom-followup-recurring"
                radio-name="followup-type"
                radio-label="Recurring reminders"
                radio-value="{{ data.followUpTypes.recurring }}"
                model="data.selectedFollowUpType"
                is-required="true"
                on-click="setSelectedFollowUpType(onClickParam)"
                on-click-param="(data.followUpTypes.recurring)"
            ></tnk-radio-button>
            <div ng-if="data.selectedFollowUpType === data.followUpTypes.recurring" class="padding-left-lg">
                <div class="flex-vmiddle">
                    <div class="margin-right">Follow up every</div>
                    <div class="btn-group">
                        <!-- Take the value of each day option object-->
                        <button
                            ng-repeat="value in data.daysOptions"
                            class="btn btn-checkbox-primary common-no-outline"
                            uib-btn-checkbox
                            ng-model="value.isSelected"
                        >
                            {{ value.label }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <button class="btn btn-lg btn-primary" ng-click="onSaveButtonClick()">Save</button>
        </div>
    </div>
</form>
