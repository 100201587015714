import useTDLServerConnectivity from './useTDLServerConnectity';

const TDLServerConnectivityAngularBridge = {
    setTonkeanServerUrl(tonkeanServerUrl: string) {
        useTDLServerConnectivity.setState({
            ...useTDLServerConnectivity.getState(),
            tonkeanServerUrl,
        });
    },

    setAccessToken(accessToken: string) {
        useTDLServerConnectivity.setState({
            ...useTDLServerConnectivity.getState(),
            accessToken,
        });
    },

    setProjectToken(projectToken: string) {
        useTDLServerConnectivity.setState({
            ...useTDLServerConnectivity.getState(),
            projectToken,
        });
    },
};

export default TDLServerConnectivityAngularBridge;
