import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

const useSetQueryParamOnLocation = () => {
    const $location = useAngularService('$location');

    return useCallback(
        (key: string, value: string | null) => {
            return $location.search(key, value);
        },
        [$location],
    );
};

export default useSetQueryParamOnLocation;
