import styled from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const SQLDatabaseExplanationSubtitle = styled.div`
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_600};
    margin-top: 6px;
    margin-bottom: 10px;
    font-style: italic;
`;

export default SQLDatabaseExplanationSubtitle;
