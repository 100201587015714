<div class="question-confirm">
    <div class="modal-header common-title">Removing "{{ data.group.name }}"</div>
    <div class="question-confirm-body">
        <p><strong>What should we do with the existing items?</strong></p>
        <p ng-click="data.type = deleteTypes.archive" class="margin-right pointer">
            <input type="radio" class="form-inline" name="type" ng-model="data.type" required value="archive" />
            Delete all existing items
        </p>
        <div ng-click="data.type = deleteTypes.move" class="pointer flex-vmiddle">
            <div>
                <input type="radio" class="form-inline" name="type" ng-model="data.type" required value="move" />
                <span class="margin-right-xs">Move to list:</span>
            </div>
            <ui-select
                class="inline-block common-width-50"
                theme="bootstrap"
                ng-model="data.selectedGroup"
                ng-disabled="data.type !== deleteTypes.move"
            >
                <ui-select-match placeholder="Choose a list">{{ $select.selected.name }}</ui-select-match>
                <ui-select-choices repeat="group in (groups | filter: {name: $select.search})">
                    <i
                        class="fa margin-right-xs"
                        ng-class="group.visibilityType === 'PUBLIC' ? 'fa-globe' : 'fa-lock'"
                    ></i>
                    <span ng-bind-html="group.name | highlight: $select.search"></span>
                </ui-select-choices>
            </ui-select>
        </div>
        <p class="text-danger" ng-if="error">{{ error | error }}</p>
    </div>
    <div class="alert alert-warning common-size-xxxxs">
        <i class="fa-exclamation-triangle fa margin-right"></i>
        Please note - this action is irreversible!
    </div>
    <div class="alert alert-danger common-size-xxxxs flex mod-align-baseline">
        <i class="fa-exclamation-triangle fa margin-right"></i>
        <div>
            This will remove the
            <strong>Module</strong>
            as well!
            <br />
            If you just want to hide the report do it via the module editor.
        </div>
    </div>
    <div class="modal-footer text-right margin-top-none">
        <button class="btn btn-default" ng-click="$dismiss()" data-ng-disabled="loading">Cancel</button>
        <button
            class="btn btn-danger"
            ng-click="removeList()"
            data-ng-disabled="loading"
            data-automation="delete-group-modal-confirm-button"
        >
            <span class="flex-vmiddle">
                Remove Business Report and Module
                <i class="loading-small mod-white margin-left-xs" ng-show="loading"></i>
            </span>
        </button>
    </div>
</div>
