function workflowVersionActionModalCtrl(
    $rootScope,
    $scope,
    $uibModalInstance,
    $timeout,
    $q,
    $localStorage,
    groupId,
    workflowVersionManager,
    tonkeanService,
    groupInfoManager,
    onSuccessfulAction,
    onReadyToPublishCompareClicked,
    utils,
    action,
    projectManager,
    solutionSitePageId,
    targetTypeId,
    entityVersionId,
    useTotalCommitted,
    modalUtils,
    modal,
) {
    $scope.wvm = workflowVersionManager;

    $scope.data = {
        steps: {
            changesReviewStep: {
                key: 'CHANGES_REVIEW_STEP',
                skip: false,
                index: 0,
            },
            testItemVerificationStep: {
                key: 'TEST_ITEM_VERIFICATION_STEP',
                skip: false,
                index: 1,
            },
            runOnNewItemsConfirmationStep: {
                key: 'RUN_ON_NEW_ITEMS_CONFIRMATION_STEP',
                skip: false,
                index: 2,
            },
            selectNewPropertiesToApplyOnOldItemsStep: {
                key: 'SELECT_NEW_PROPERTIES_TO_APPLY_ON_OLD_ITEMS_STEP',
                skip: true,
                index: 3,
            },
            commentStep: {
                key: 'COMMENT_STEP',
                skip: false,
                index: 4,
            },
        },
        actions: {
            useOldPublish: {
                title: 'Publish',
                commentStepDescription:
                    'Your current module version will be used as your production module.<br />Please note, this is an irreversible step.',
                button: 'Publish',
                error: 'There was an error trying to publish the module.',
                commentRequired: true,
            },
            isCreateVersion: {
                title: 'Create a new version',
                subTitle: 'Provide a meaningful description for the changes included in this version',
                commentStepDescription:
                    'The pending changes in your module will be consolidated and saved as a new version that can be restored to your build environment.',
                tip: '<b>Pro tip:</b> You can also use <b>CTRL + D</b> from the module builder to quickly create a version with the pending changes and a system-generated description',
                button: 'Create Version',
                error: 'There was an error trying to create a version for the module.',
                commentRequired: false,
                placeholder: 'Enter description',
            },
            createSolutionSitePageVersion: {
                title: 'Create a new version',
                subTitle: 'Provide a meaningful description for the changes included in this version',
                commentStepDescription:
                    'The pending changes in the page will be consolidated and saved as a new version that can be restored to your build environment.',
                tip: '<b>Pro tip:</b> Use <b>CTRL + D</b> in the page builder to quickly create a version',
                button: 'Create Version',
                error: 'There was an error trying to create a version for the page.',
                commentRequired: false,
                placeholder: 'Enter description',
            },
            markReadyToPublish: {
                title: 'Mark Module as "Ready for Publishing"',
                commentStepDescription:
                    'Your current module version will be marked as ready to be used as your production module.<br />Please note, this is an irreversible step.',
                button: 'Mark For Publish',
                error: 'There was an error trying to mark the module for publish.',
                commentRequired: true,
            },
            markSolutionSitePageReadyToPublish: {
                title: 'Mark Solution Site Page as "Ready for Publishing"',
                commentStepDescription:
                    'Your current solution site page version will be marked as ready to be used as your production module.<br />Please note, this is an irreversible step.',
                button: 'Mark For Publish',
                error: 'There was an error trying to mark the solution site page for publish.',
                commentRequired: true,
            },
        },
        groupId,
        action,
        onReadyToPublishCompareClicked,
        publishingWorkflowVersion: false,
        errorPublishingWorkflowVersion: false,
        errorPublishingWorkflowVersionMessage: null,
        comment: getVersionPublishComment(groupId | solutionSitePageId),
        currentStep: null,
        firstStepIndex: 0,
        lastStepIndex: null,

        publishImplicationsResponse: {},
        customTriggerIdToDependentFieldDefinitionMap: {},
        customTriggerToFilterFieldsBy: undefined,

        workflowVersion: undefined,
        workflowVersionId: workflowVersionManager.groupIdToDraftWorkflowVersionIdMap[groupId],
        selectedActorPeopleToFilterOn: [],
        workerAuditLogsControlObject: {},
        onlyActivityTypes: workflowVersionManager.getWorkflowRelevantActivityTypes(),
        runOnNewItems: false,
        loading: false,
        error: false,

        executeInitialSyncInGroup: false,
        calculateCustomTriggerIdsSet: {},
        calculateFieldDefinitionIdsSet: {},
        solutionSitePageId,
        targetTypeId,
        hideGroupByToggle: !!targetTypeId,
        entityVersionId,
        useTotalCommitted,
    };

    $scope.init = function () {
        if ($scope.data.action === 'isCreateVersion' || $scope.data.action === 'createSolutionSitePageVersion') {
            $scope.data.currentStep = $scope.data.steps.commentStep;
            return;
        }

        $scope.data.loading = true;
        $scope.data.lastStepIndex = Object.keys($scope.data.steps).length - 1;

        const testRunsPromise = $scope.data.groupId
            ? tonkeanService.getAnyTestRunsOccurred($scope.data.groupId)
            : Promise.resolve(null);

        const publishImplicationsPromise = $scope.data.groupId
            ? tonkeanService.getPublishImplications($scope.data.groupId)
            : Promise.resolve(null);

        const workflowVersionPromise = $scope.data.workflowVersionId
            ? tonkeanService.getWorkflowVersionById($scope.data.workflowVersionId, true)
            : Promise.resolve(null);
        $q.all([testRunsPromise, publishImplicationsPromise, workflowVersionPromise])
            .then(([anyTestRunsResponse, publishImplicationsResponse, workflowVersion]) => {
                $scope.data.publishImplicationsResponse = publishImplicationsResponse;

                const fieldsFromResponse = [
                    ...($scope.data.publishImplicationsResponse?.changedFieldDefinitions || []),
                    ...Object.values(
                        $scope.data.publishImplicationsResponse?.customTriggerIdToDependentFieldDefinitionIdsMap || {},
                    ).flat(1),
                ];
                const allFields = fieldsFromResponse.filter(
                    ({ id }, index) => fieldsFromResponse.findIndex(({ id: fieldId }) => fieldId === id) === index,
                );

                $scope.data.customTriggerIdToDependentFieldDefinitionMap = {
                    ...$scope.data.publishImplicationsResponse?.customTriggerIdToDependentFieldDefinitionIdsMap,
                    all: allFields,
                };

                // If there are no new items to run on, just skip this confirmation step.
                if (publishImplicationsResponse) {
                    $scope.data.steps.runOnNewItemsConfirmationStep.skip =
                        !anyPublishImplications(publishImplicationsResponse);
                }

                // Check whether to skip testItemVerificationStep or not.
                if (anyTestRunsResponse?.anyTestRunsOccurred) {
                    $scope.data.steps.testItemVerificationStep.skip = true;
                }

                $scope.data.workflowVersion = workflowVersion;

                // Set values from publishConfiguration as default if exists
                if ($scope.data.workflowVersion?.publishConfiguration) {
                    const {
                        comment,
                        publishConfiguration: {
                            executeInitialSyncInGroup,
                            calculateCustomTriggerIds,
                            calculateFieldDefinitionIds,
                        },
                    } = $scope.data.workflowVersion;

                    $scope.data.comment = comment ?? $scope.data.comment;
                    $scope.data.executeInitialSyncInGroup = executeInitialSyncInGroup ?? false;
                    $scope.data.calculateCustomTriggerIdsSet = Object.fromEntries(
                        calculateCustomTriggerIds.map((calculateCustomTriggerId) => [calculateCustomTriggerId, true]),
                    );
                    $scope.data.calculateFieldDefinitionIdsSet = Object.fromEntries(
                        calculateFieldDefinitionIds.map((calculateFieldDefinitionId) => [
                            calculateFieldDefinitionId,
                            true,
                        ]),
                    );
                }
            })
            .catch((error) => {
                $scope.data.error = true;
                $scope.data.errorInPublishImplications = true;
            })
            .finally(() => {
                $scope.data.currentStep = $scope.data.steps.changesReviewStep;

                // Calculating first and last steps index.
                calculateLastStepIndex();

                $scope.data.loading = false;
            });
    };

    $scope.toggleClicked = function () {
        $scope.data.runOnNewItems = !$scope.data.runOnNewItems;
    };

    /**
     * Go over all steps and find the first one with higher index than current step without the skip flag set to true
     */
    $scope.nextStep = function () {
        if ($scope.data.error) {
            $scope.data.error = false;
        }

        let nextStepIndex = $scope.data.currentStep.index + 1;

        // If we are marking a solution site page for publish, we can skip the testing steps.
        if (
            $scope.data.solutionSitePageId &&
            $scope.data.currentStep.index === $scope.data.steps.changesReviewStep.index
        ) {
            nextStepIndex = $scope.data.steps.commentStep.index;
        }

        // If we got an error in the publish implications step the RUN_ON_NEW_ITEMS_CONFIRMATION_STEP and SELECT_NEW_PROPERTIES_TO_APPLY_ON_OLD_ITEMS_STEP
        // steps are not relevant and we want to skip them and go straight to the PUBLISH_COMMENT_STEP step.
        if (
            $scope.data.errorInPublishImplications &&
            $scope.data.currentStep.index === $scope.data.steps.testItemVerificationStep.index
        ) {
            nextStepIndex = $scope.data.steps.commentStep.index;
        }

        for (let i = nextStepIndex; i <= $scope.data.lastStepIndex; i++) {
            $scope.data.currentStep = utils.findInObj($scope.data.steps, (step) => step.index === i).value;
            if (!$scope.data.currentStep.skip) {
                return;
            }
        }
    };

    $scope.toggleExecuteInitialSyncInGroup = function (executeInitialSyncInGroup) {
        $scope.data.executeInitialSyncInGroup = executeInitialSyncInGroup;
    };

    $scope.toggleCalculateTriggersOnExistingItems = function (customTriggerId, activate) {
        if (activate) {
            $scope.data.calculateCustomTriggerIdsSet[customTriggerId] = true;

            const dependentFields =
                $scope.data.publishImplicationsResponse?.customTriggerIdToDependentFieldDefinitionIdsMap?.[
                    customTriggerId
                ];
            dependentFields?.forEach(({ id }) => ($scope.data.calculateFieldDefinitionIdsSet[id] = true));
        } else {
            delete $scope.data.calculateCustomTriggerIdsSet[customTriggerId];
        }
    };

    $scope.toggleCalculateFieldsOnExistingItems = function (fieldDefinitionId) {
        if ($scope.data.calculateFieldDefinitionIdsSet[fieldDefinitionId]) {
            const haveActiveDependentCustomTriggers = Object.keys($scope.data.calculateCustomTriggerIdsSet)
                .flatMap(
                    (customTriggerId) =>
                        $scope.data.publishImplicationsResponse?.customTriggerIdToDependentFieldDefinitionIdsMap?.[
                            customTriggerId
                        ] || [],
                )
                .some(({ id }) => id === fieldDefinitionId);

            let promise = Promise.resolve();
            if (haveActiveDependentCustomTriggers) {
                $scope.questionConfirmModalData = {
                    title: 'Are you sure?',
                    body: 'A custom trigger that will be applied on existing items depends on this field, and not applying the update on this field might cause problems.',
                    okLabel: 'Apply only on new items',
                    cancelLabel: 'Apply to new and old',
                };

                promise = modal.openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning vertical-middle-modal',
                }).result;
            }

            promise.then(() => delete $scope.data.calculateFieldDefinitionIdsSet[fieldDefinitionId]);
        } else {
            $scope.data.calculateFieldDefinitionIdsSet[fieldDefinitionId] = true;
        }
    };

    /**
     * Go over all steps and find the first one with lower index than current step without the skip flag set to true
     */
    $scope.previousStep = function () {
        // For solution site pages, need to skip the rest of the step and go to the comment step (changesReviewStep)
        if ($scope.data.solutionSitePageId && $scope.data.currentStep.index === $scope.data.steps.commentStep.index) {
            $scope.data.currentStep = $scope.data.steps.changesReviewStep;
            return;
        }
        const previousStepIndex = $scope.data.currentStep.index - 1;
        for (let i = previousStepIndex; i >= $scope.data.firstStepIndex; i--) {
            $scope.data.currentStep = utils.findInObj($scope.data.steps, (step) => step.index === i).value;
            if (!$scope.data.currentStep.skip) {
                return;
            }
        }
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    /**
     * Closes the modal and open view changes.
     */
    $scope.closeAndViewChanges = function () {
        $uibModalInstance.close();
        if ($scope.data.groupId) {
            modalUtils.openWorkflowVersionChangesModal(
                $scope.data.groupId,
                $scope.data.workflowVersionId,
                'Pending Changes',
                'Below are the changes that will be included on the next published version of the module',
                null,
                true,
                false,
                undefined,
                undefined,
            );
        } else if ($scope.data.solutionSitePageId) {
            modalUtils.openWorkflowVersionChangesModal(
                null,
                $scope.data.workflowVersionId,
                'Pending Changes',
                'Below are the changes that will be included on the next published version of the solution site page',
                $scope.data.solutionSitePageId,
                false,
                true,
                $scope.data.entityVersionId,
                0,
            );
        }
    };

    /**
     * Publishes the current workflow version of the bot.
     */
    $scope.workflowVersionAction = function () {
        if ($scope.data.solutionSitePageId) {
            performSolutionSitePageAction();
        } else {
            performWorkflowVersionAction();
        }
    };

    $scope.init();

    /**
     * Occurs once the person added.
     */
    $scope.onActorPersonAdded = function () {
        $scope.data.workerAuditLogsControlObject.onActorPeopleSelected($scope.data.selectedActorPeopleToFilterOn);
    };

    $scope.onTestItemFinished = function () {
        $scope.nextStep();
    };

    /**
     * Opens the activity actor person selection popover.
     */
    $scope.openActivityActorPersonSelection = function () {
        $scope.data.activityActorSelectionPopoverOpen = true;

        const ngPopoverElement = angular.element(document.querySelector('#activity-actor-selection-popover'));
        const ngContainer = angular.element(document.querySelector('#activity-actor-selection-popover-container'));

        ngContainer.append(ngPopoverElement);

        // This is important to make the popover show right after the first click, and not have to double click the link for it to show.
        // Our theory here is that appending the element of the popover requires a digest loop to be rendered, and by calling timeout
        // we trigger such digest loop and the popover gets displayed.
        $timeout(() => {});
    };

    /**
     * Occurs on the change of the publish comment.
     */
    $scope.onCommentChanged = function () {
        setVersionPublishComment($scope.data.comment, $scope.data.groupId);
    };

    /**
     * Closes the popover for the actor selection.
     */
    $scope.resetActivityActorSelectionPopover = function () {
        $scope.data.activityActorSelectionPopoverOpen = false;

        const ngPopoverElement = angular.element(document.querySelector('#activity-actor-selection-popover'));
        const ngContainer = angular.element(document.querySelector('#activity-actor-selection-popover-parent'));

        ngContainer.append(ngPopoverElement);
    };

    /**
     * Occurs once the person removed.
     */
    $scope.onActorPersonRemoved = function () {
        $scope.data.workerAuditLogsControlObject.onActorPeopleSelected($scope.data.selectedActorPeopleToFilterOn);
    };

    $scope.makeRunOnNewItems = (goToAdvanced = false) => {
        let promise = $q.resolve();

        if (!goToAdvanced && hasChangesInNewPropertiesForm()) {
            $scope.questionConfirmModalData = {
                title: 'Are you sure?',
                body: 'This will override the changes you have made in advanced mode and apply the changes only to new items. Are you sure?',
                okLabel: 'Apply only on new items',
                cancelLabel: 'Go back',
            };

            promise = modal
                .openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning vertical-middle-modal',
                })
                .result.then(() => {
                    $scope.resetNewProperties();
                });
        }

        promise.then(() => {
            $scope.data.steps.selectNewPropertiesToApplyOnOldItemsStep.skip = !goToAdvanced;

            calculateLastStepIndex();

            $scope.nextStep();
        });
    };

    $scope.resetNewProperties = () => {
        $scope.data.executeInitialSyncInGroup = false;
        $scope.data.calculateCustomTriggerIdsSet = {};
        $scope.data.calculateFieldDefinitionIdsSet = {};
    };

    function performWorkflowVersionAction() {
        $scope.data.publishingWorkflowVersion = true;
        $scope.data.errorPublishingWorkflowVersion = false;

        let promise;
        if ($scope.data.action === 'useOldPublish') {
            promise = tonkeanService.publishWorkflowVersion(
                $scope.data.groupId,
                $scope.data.comment,
                $scope.data.executeInitialSyncInGroup,
                Object.keys($scope.data.calculateCustomTriggerIdsSet),
                Object.keys($scope.data.calculateFieldDefinitionIdsSet),
            );
        } else if ($scope.data.action === 'isCreateVersion') {
            promise = tonkeanService.commitWorkflowVersion($scope.data.groupId, $scope.data.comment);
        } else {
            promise = workflowVersionManager.markAsReadyToPublishWorkflowVersion(
                $scope.data.groupId,
                $scope.data.comment,
                $scope.data.executeInitialSyncInGroup,
                Object.keys($scope.data.calculateCustomTriggerIdsSet),
                Object.keys($scope.data.calculateFieldDefinitionIdsSet),
            );
        }

        promise
            .then((workflowVersionId) => {
                const group = projectManager.groupsMap[$scope.data.groupId];

                deleteVersionPublishComment($scope.data.groupId);

                if ($scope.data.action === 'useOldPublish' || $scope.data.action === 'isCreateVersion') {
                    workflowVersionManager.lastWorkflowVersionIdMap[group.id] = workflowVersionId;

                    // Setting the build environment off after the publish in cache (it is done in the command).
                    group.buildEnvironmentEnabled = false;

                    return groupInfoManager.getGroup($scope.data.groupId, true).then(() =>
                        // Initialize the changes counter for the group.
                        workflowVersionManager
                            .initializeChangesCounter(
                                workflowVersionManager.getDraftVersionFromCache($scope.data.groupId).id,
                                $scope.data.groupId,
                            )
                            .then(() => {
                                if ($scope.data.action === 'isCreateVersion') {
                                    $rootScope.$emit('alert', {
                                        type: 'success',
                                        msg: `${group.name} version created`,
                                    });
                                }
                            }),
                    );
                } else {
                    $rootScope.$emit('alert', {
                        type: 'success',
                        msg: `${group.name} marked for publish`,
                    });

                    window.Intercom('trackEvent', 'mark for publish completed');
                    // Poll for changes on intercom after 2 seconds to have the message appear to the user
                    setTimeout(() => window.Intercom('update'), 2000);
                }
            })
            .then(() => {
                // Notify successful publish, to let the app change to production
                onSuccessfulAction?.();

                // Closing modal on success and get groups that is finished.
                $uibModalInstance.close();
            })
            .catch((error) => {
                $scope.data.errorPublishingWorkflowVersion = true;
                $scope.data.errorPublishingWorkflowVersionMessage =
                    error?.data?.error?.message || $scope.data.actions[$scope.data.action].error;
            })
            .finally(() => {
                $scope.data.publishingWorkflowVersion = false;
            });
    }

    function performSolutionSitePageAction() {
        let promise;
        switch ($scope.data.action) {
            case 'createSolutionSitePageVersion': {
                promise = tonkeanService.commitSolutionSitePage($scope.data.solutionSitePageId, $scope.data.comment);
                break;
            }
            case 'markSolutionSitePageReadyToPublish': {
                promise = tonkeanService.markSolutionSitePageAsReadyToPublish(
                    $scope.data.solutionSitePageId,
                    $scope.data.comment,
                );
                break;
            }
            default: {
                throw new Error(`Unknown action ${$scope.data.action}`);
            }
        }

        return promise
            .then(() => {
                // Notify successful publish, to let the app change to production
                onSuccessfulAction?.();

                // Closing modal on success and get groups that is finished.
                $uibModalInstance.close();
            })
            .catch((error) => {
                $scope.data.errorPublishingWorkflowVersion = true;
                $scope.data.errorPublishingWorkflowVersionMessage =
                    error?.data?.error?.message || $scope.data.actions[$scope.data.action].error;
            });
    }

    // function perform

    function hasChangesInNewPropertiesForm() {
        return (
            $scope.data.executeInitialSyncInGroup ||
            Object.keys($scope.data.calculateCustomTriggerIdsSet).length ||
            Object.keys($scope.data.calculateFieldDefinitionIdsSet).length
        );
    }

    function calculateLastStepIndex() {
        const stepsArray = Object.values($scope.data.steps);
        for (let i = stepsArray.length - 1; i >= 0; i--) {
            if (!stepsArray[i].skip) {
                $scope.data.lastStepIndex = stepsArray[i].index;
                return;
            }
        }
    }

    /**
     * Returns whether there were any changes that require publish implications.
     */
    function anyPublishImplications(publishImplicationsResponse) {
        return (
            publishImplicationsResponse?.changedSyncConfig ||
            publishImplicationsResponse?.changedCustomTriggers?.length ||
            publishImplicationsResponse?.changedFieldDefinitions?.length
        );
    }

    function getVersionPublishComment(groupId) {
        return getVersionPublishCommentMap()[groupId] || '';
    }

    function setVersionPublishComment(comment, groupId) {
        const map = getVersionPublishCommentMap();
        map[groupId] = comment;
        $localStorage.currentPublishComment = JSON.stringify(map);
    }

    function deleteVersionPublishComment(groupId) {
        const map = getVersionPublishCommentMap();
        delete map[groupId];
        $localStorage.currentPublishComment = JSON.stringify(map);
    }

    function getVersionPublishCommentMap() {
        try {
            // Getting the value from the local storage.
            const currentPublishComment = JSON.parse($localStorage.currentPublishComment);

            // The value might be null due to changes we made in the way we save the data in the local storage.
            // Thus, checking whether the value is null.
            if (utils.isNullOrUndefined(currentPublishComment)) {
                $localStorage.currentPublishComment = JSON.stringify({});
                return {};
            } else {
                return currentPublishComment;
            }
        } catch {
            $localStorage.currentPublishComment = JSON.stringify({});
            return {};
        }
    }
}

export default angular.module('tonkean.app').controller('workflowVersionActionModalCtrl', workflowVersionActionModalCtrl);
