<!-- Normal mode ui: ui-select with refresh button -->
<div ng-if="!data.inSentenceMode">
    <div class="flex-vmiddle {{::data.classes}}">
        <ui-select
            class="flex-grow"
            theme="bootstrap"
            uib-tooltip="{{ data.selectorTooltip }}"
            ng-model="data.selectedChannel"
            append-to-body="{{ data.appendToBody }}"
            ng-class="{ 'common-z-index-above-modal': data.appendToBody }"
            ng-disabled="loadingOptions || data.viewOnly"
            on-select="onChannelSelected($item)"
        >
            <ui-select-match placeholder="Select a {{::data.communicationChannelLabel}}...">
                {{ $select.selected.displayName }}
                <!-- if we we are in the project settings -->
                <span
                    ng-if="data.enableClearChannel"
                    ng-click="!data.viewOnly && $select.clear($event)"
                    class="
                        svg-icon-vmiddle svg-icon-hover-dark-grey
                        absolute-right
                        margin-right-lg
                        padding-right-xxs
                        margin-top-xxs
                        common-color-grey
                        ui-select-custom-span-container
                    "
                >
                    <tnk-icon src="../../../../svg/src/icons/x.svg"></tnk-icon>
                </span>
            </ui-select-match>
            <ui-select-choices repeat="channel in data.notificationChannels | filter:$select.search">
                <div
                    data-automation="channel-selector-template-channel-display-name"
                    ng-bind-html="channel.displayName | highlight: $select.search"
                ></div>
            </ui-select-choices>
        </ui-select>

        <!-- Refresh button -->
        <button
            type="button"
            class="btn btn-secondary flex-no-shrink margin-left-xs channel-selector-refresh-button"
            uib-tooltip="Refresh {{::data.communicationIntegrationLabel}}s"
            ng-click="loadAutoCompleteChannels()"
            ng-disabled="data.viewOnly"
            ng-if="data.showRefreshButton"
        >
            <i class="fa fa-refresh" ng-hide="loadingOptions"></i>
            <i class="loading-small" ng-show="loadingOptions"></i>
        </button>
    </div>
</div>

<!-- In sentence mode: tnk-dropdown-search with refresh link -->
<div ng-if="data.inSentenceMode" class="flex-vmiddle">
    <tnk-dropdown-search
        class="margin-right-xs"
        items="data.notificationChannels"
        selected-item="data.selectedChannel"
        enable-clear="data.enableClearChannel"
        closed-placeholder="{{::data.communicationIntegrationLabel}}"
        selected-label="{{ data.communicationIntegration.displayName + ' ' + data.selectedChannel.displayName }}"
        label-classes="{{::data.classes}}"
        on-item-selected="onChannelSelected(selectedItem)"
        placeholder="Search {{::data.communicationChannelLabel}}s"
        view-only="data.viewOnly"
    ></tnk-dropdown-search>

    <!-- Refresh button -->
    <span
        class="common-size-xxxs common-color-primary margin-left-xs"
        ng-if="!data.viewOnly"
        uib-tooltip="Refresh {{::data.communicationIntegrationLabel}}s"
        ng-click="loadAutoCompleteChannels()"
    >
        <i class="fa fa-refresh pointer" ng-hide="loadingOptions"></i>
        <i class="loading-small" ng-show="loadingOptions"></i>
    </span>
</div>
