import { useAngularService } from 'angulareact';
import { useCallback, useMemo } from 'react';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';

/**
 * Fetches and returns all the information needed to run the project integration page.
 * It will fetch the project integration and integrations and return functions to update needed information.
 */
function useProjectIntegrationToSolutionAndGroupMapping(projectIntegrationId: string) {
    const workflowFolderManager = useAngularService('workflowFolderManager');
    const tonkeanService = useAngularService('tonkeanService');
    const project = useProject();

    const { data: groups } = useAsyncMethod(
        tonkeanService,
        'getProjectIntegrationDependentGroups',
        project.id,
        projectIntegrationId,
    );

    // Create a map of id to the group data
    const groupsMap = useMemo(() => {
        return Object.fromEntries(groups?.map((group) => [group.id, group]) || []);
    }, [groups]);

    const insertGroupToSolutionAndProjectIntegrationBucket = useCallback(
        (mapping, projectIntegrationId, solution, group) => {
            if (!mapping[projectIntegrationId]) {
                mapping[projectIntegrationId] = {};
            }

            if (!mapping[projectIntegrationId][solution.id]) {
                mapping[projectIntegrationId][solution.id] = [];
            }

            if (!mapping[projectIntegrationId][solution.id].includes(group.id)) {
                mapping[projectIntegrationId][solution.id].push(group.id);
            }
        },
        [],
    );

    // Create a map of id to the workflow folder data
    const workflowFolders = useMemo(() => {
        const workflowFoldersMap = {};

        groups?.forEach((group) => {
            // Get solution name
            const workflowFolder: WorkflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                project.id,
                group.id,
            );

            // Save the workflow folder mapping
            if (workflowFolder && !workflowFoldersMap[workflowFolder.id]) {
                workflowFoldersMap[workflowFolder.id] = workflowFolder;
            }
        });

        return workflowFoldersMap;
    }, [workflowFolderManager, project.id, groups]);

    // Fill the mapping between project integration, solution and module
    const projectIntegrationToSolutionAndGroup = useMemo(() => {
        const projectIntegrationToGroupMap = {};

        groups?.forEach((group) => {
            // Get the solution of the group
            const workflowFolder: WorkflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                project.id,
                group.id,
            );

            if (workflowFolder) {
                // Add the mapping
                insertGroupToSolutionAndProjectIntegrationBucket(
                    projectIntegrationToGroupMap,
                    projectIntegrationId,
                    workflowFolder,
                    group,
                );
            }
        });

        return projectIntegrationToGroupMap;
    }, [
        projectIntegrationId,
        workflowFolderManager,
        project.id,
        groups,
        insertGroupToSolutionAndProjectIntegrationBucket,
    ]);

    return {
        mapping: projectIntegrationToSolutionAndGroup,
        workflowFolders,
        groups: groupsMap,
    };
}

export default useProjectIntegrationToSolutionAndGroupMapping;
