import React, { useState } from 'react';
import styled from 'styled-components';

import type { SuggestedModuleConfig } from './SuggestedSectionConfig';
import { ReactComponent as DownloadIcon } from '../../../../../../../../images/icons/download.svg';
import { ReactComponent as TrashNewIcon } from '../../../../../../../../images/icons/trash-new.svg';

import {
    Field,
    InformationTooltip,
    Input,
    KebabMenuButton,
    Menu,
    MenuItem,
    Toggle,
    useFormikArrayField,
} from '@tonkean/infrastructure';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';
import { colorSvg } from '@tonkean/utils';

const SuggestedModuleLineContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const ModuleLabelField = styled(Field)`
    margin-right: 10px;
    flex-basis: 30%;
`;

const ModuleUrlField = styled(Field)`
    margin-right: 10px;
    flex-grow: 1;
`;

const StyledMenuItem = styled(MenuItem)`
    svg {
        ${colorSvg(Theme.colors.gray_600)};
    }
`;

const MenuItemContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledInformationTooltip = styled(InformationTooltip)`
    margin-right: 28px;
    margin-left: 8px;
`;

const StyledBusinessReportToggle = styled.span`
    display: flex;
    align-items: center;
`;

interface Props {
    sectionIndex: number;
    moduleIndex: number;
}

const SuggestedModuleConfigEditor: React.FC<Props> = ({ sectionIndex, moduleIndex }) => {
    const { remove: removeModuleFromSections } = useFormikArrayField<SuggestedModuleConfig[]>(
        `configuration.solutionOutline.sections[${sectionIndex}].suggestedModules`,
    );

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    return (
        <SuggestedModuleLineContainer>
            <ModuleLabelField>
                <Input
                    data-automation="suggested-section-config-editor-module-name-input"
                    name={`configuration.solutionOutline.sections[${sectionIndex}].suggestedModules[${moduleIndex}].label`}
                    placeholder="Module Name"
                />
            </ModuleLabelField>
            <ModuleUrlField>
                <Input
                    data-automation="suggested-section-config-editor-module-url-input"
                    name={`configuration.solutionOutline.sections[${sectionIndex}].suggestedModules[${moduleIndex}].templateName`}
                    placeholder="Suggested module template name (e.g. legal-intake)"
                />
            </ModuleUrlField>

            <Menu
                show={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
                placement="bottom-end"
                menuItems={
                    <>
                        <StyledMenuItem
                            onClick={() => removeModuleFromSections(moduleIndex)}
                            icon={<TrashNewIcon />}
                            data-automation="suggested-section-config-editor-delete-suggested-module"
                        >
                            Delete
                        </StyledMenuItem>

                        <StyledMenuItem icon={<DownloadIcon />}>
                            <MenuItemContainer>
                                <span>Embed module </span>
                                <StyledInformationTooltip>
                                    Turn this on if you want this module will be downloaded with the blueprint
                                </StyledInformationTooltip>
                                <Field>
                                    <StyledBusinessReportToggle>
                                        <Toggle
                                            name={`configuration.solutionOutline.sections[${sectionIndex}].suggestedModules[${moduleIndex}].isEmbedded`}
                                            onClick={(e) => e.stopPropagation()}
                                            size={InputSize.SMALL}
                                        />
                                    </StyledBusinessReportToggle>
                                </Field>
                            </MenuItemContainer>
                        </StyledMenuItem>
                    </>
                }
                thin
            >
                <KebabMenuButton
                    onClick={(e) => {
                        setIsMenuOpen(true);
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    flat
                />
            </Menu>
        </SuggestedModuleLineContainer>
    );
};

export default React.memo(SuggestedModuleConfigEditor);
