import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { isInitiative } from './FieldChartUtils';
import HistoricalFieldChartComponent from './HistoricalFieldChartComponent';

import { Modal, ModalHeader, ModalSize, XCloseButton } from '@tonkean/infrastructure';
import type {
    FieldDefinition,
    FieldInstance,
    Group,
    Initiative,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ChartContainer = styled.div`
    margin: 10px;
    min-height: 40vh;
`;

const TitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
    font-weight: 500;
`;

const TitleWrapper = styled.div`
    font-size: ${FontSize.XXLARGE_20};
    display: flex;
    align-items: center;
`;

const ModalSubHeader = styled(Clickable)`
    margin-top: 10px;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.primary};
    text-decoration: underline;
`;

const FieldChartModal: React.FC<{
    open: boolean;
    onClose(): void;
    fieldDefinition?: FieldDefinition;
    fieldInstance?: FieldInstance;
    target: Initiative | Group;
}> = ({ open, onClose, fieldDefinition, fieldInstance, target }) => {
    const $location = useAngularService('$location');

    const showStatusOverlay = useMemo<boolean>(() => {
        return isInitiative(target);
    }, [target]);

    const initiativeId = useMemo<TonkeanId<TonkeanType.INITIATIVE> | undefined>(() => {
        if (isInitiative(target)) {
            return target.id;
        }
    }, [target]);

    const fieldChartFieldInstance = useMemo<FieldInstance | undefined>(() => {
        if (fieldInstance) {
            return fieldInstance;
        }
        if (fieldDefinition && isInitiative(target)) {
            return target.defIdToValidFieldsMap?.[fieldDefinition.id]?.[0];
        }
    }, [fieldDefinition, fieldInstance, target]);

    const isOpen = Boolean(open && fieldChartFieldInstance);

    const modalSubHeader = isInitiative(target) ? target.title : target?.name;

    const openTarget = useCallback(() => {
        if (isInitiative(target)) {
            $location.search('tid', target.id);
        }
        onClose();
    }, [$location, onClose, target]);

    return (
        <Modal open={isOpen} onClose={onClose} size={ModalSize.LARGE} fixedWidth>
            <ModalHeader $border={false}>
                <TitleHeader>
                    <TitleWrapper>{fieldChartFieldInstance?.fieldDefinition?.name}</TitleWrapper>
                    <XCloseButton onClick={onClose} size={ButtonSize.MEDIUM} />
                </TitleHeader>
                <ModalSubHeader onClick={openTarget}>{modalSubHeader}</ModalSubHeader>
            </ModalHeader>
            <ChartContainer>
                <HistoricalFieldChartComponent
                    fieldInstance={fieldChartFieldInstance}
                    fieldName={fieldChartFieldInstance?.fieldDefinition?.name ?? ''}
                    showStatusOverlay={showStatusOverlay}
                    initiativeId={initiativeId}
                    chartInModal
                />
            </ChartContainer>
        </Modal>
    );
};

export default FieldChartModal;
