import React from 'react';

import UpdateFieldLinkText from './UpdateFieldLinkText';

import { Input, InputReveal } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

interface UpdateFieldLinkURLProps {
    value?: string;
    urlLabel?: string;
    isInTableView: boolean;
    dataAutomation?: string;
    readOnly?: boolean;
    onChange: (value: string, dontSave?: boolean) => void;
    saveWhileTyping?: boolean;
    setEditing: (editing: boolean) => void;
    finalReadOnly?: boolean;
    revealByDefault?: boolean;
    placeholderText?: string;
    onEnterDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const UpdateFieldTextOrURL: React.FC<UpdateFieldLinkURLProps> = ({
    value,
    urlLabel,
    isInTableView,
    dataAutomation,
    readOnly,
    onChange,
    saveWhileTyping,
    setEditing,
    finalReadOnly,
    revealByDefault,
    placeholderText,
    onEnterDown,
    ...props
}) => {
    if (readOnly) {
        return <UpdateFieldLinkText value={value} isInTableView={isInTableView} urlLabel={urlLabel} />;
    }

    return (
        <InputReveal
            data-automation={dataAutomation}
            as={Input}
            value={value as string | number | undefined}
            onChange={(e) => onChange(e.target.value, true)}
            onFocus={() => saveWhileTyping && setEditing(true)}
            onBlur={(e) => {
                if (saveWhileTyping) {
                    setEditing(false);
                }
                return onChange(e.target.value);
            }}
            type="text"
            readOnly={finalReadOnly}
            disabled={finalReadOnly}
            $revealByDefault={revealByDefault}
            placeholder={placeholderText || 'Enter text'}
            size={InputSize.LARGE}
            onKeyDown={(e) => {
                if (onEnterDown && e.key === 'Enter') {
                    onChange(e.currentTarget.value);
                    onEnterDown(e);
                }
            }}
            {...props}
        />
    );
};

export default UpdateFieldTextOrURL;
