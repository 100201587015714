import { ProjectIntegrationPageMenuItemType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { analyticsWrapper } from '@tonkean/analytics';
import { WORKER_TYPES } from '@tonkean/constants';

/* @ngInject */
function DataSourceWorkerComponentCtrl(
    $scope,
    $attrs,
    $rootScope,
    $stateParams,
    modalUtils,
    projectManager,
    tonkeanService,
    groupInfoManager,
    workflowVersionDataSourceManager,
    workflowVersionManager,
    syncConfigCacheManager,
    utils,
    $state,
) {
    const ctrl = this;
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.scm = syncConfigCacheManager;
    $scope.$stateParams = $stateParams;
    $scope.utils = utils;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        group: $scope.pm.groupsMap[ctrl.groupId],
        invalidLogics: ctrl.invalidLogics,

        workerTypes: WORKER_TYPES,

        workerTypeOptionSelection: workflowVersionManager.getCachedWorkflowVersion(ctrl.workflowVersionId)
            .onTriggerTypeSelected
            ? workflowVersionManager.getCachedWorkflowVersion(ctrl.workflowVersionId).isScheduled
                ? 'scheduledWorker'
                : 'onTriggerWorker'
            : null,
        configureWorker: workflowVersionManager.getCachedWorkflowVersion(ctrl.workflowVersionId).onTriggerTypeSelected,
        savingRecurrenceTimeSelection: false,
        errorSavingRecurrenceTimeSelection: null,

        sendingRunWorkerNow: false,
        errorSendingRunWorkerNow: null,

        recurrenceTimeSelection: null,
        existingRecurrenceTimeSelection: null,

        editMode: false,
        targetDataSourceForEdit: null,
        showManual: false,

        scheduledTrackName: $scope.pm.groupsMap[ctrl.groupId]
            ? $scope.pm.groupsMap[ctrl.groupId].scheduledTrackTitleExpression
            : null,
        evaluatedScheduledTrackName: $scope.pm.groupsMap[ctrl.groupId]
            ? $scope.pm.groupsMap[ctrl.groupId].evaluatedScheduledTrackTitleExpression
            : null,

        shouldReloadFields: false,

        editGroupControlObject: {},
        columns: {
            ctrlObject: {},
        },
        keyMetrics: {
            ctrlObject: {},
        },

        createFormLoading: false,
        loadingForms: false,
        groupCreateForms: [],

        showDataSourceConfiguration: false,

        workflowFolderProjectIntegrationsAccess: ctrl.workflowFolderProjectIntegrationsAccess,
        projectIntegrationPageMenuItemType: ProjectIntegrationPageMenuItemType,
    };

    /**
     * Set edit mode of sync to be true.
     */
    $scope.setEditMode = function () {
        $scope.data.editMode = true;
    };

    /**
     * Set edit owner settings to be true.
     */
    $scope.setCoordinationFieldSettings = function () {
        $scope.data.editSyncSettings = !$scope.data.editSyncSettings;
    };

    $scope.onSyncComplete = function () {
        $scope.data.editSyncSettings = false;
        $scope.reloadFields();
    };

    /**
     * Occurs on init of the component .
     */
    ctrl.$onInit = function () {
        $scope.init();
    };

    $scope.init = function () {
        const workflowVersion = workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId);
        if (projectManager.groupsMap[$scope.data.groupId] && workflowVersion.scheduledWorkerDefinition) {
            $scope.data.scheduledTrackName = workflowVersion.scheduledWorkerDefinition.scheduledTrackTitleExpression;
            $scope.data.evaluatedScheduledTrackName =
                workflowVersion.scheduledWorkerDefinition.evaluatedScheduledTrackTitleExpression;
        }

        // Sync to group mode.
        $scope.data.group = projectManager.groupsMap[$scope.data.groupId];

        if (workflowVersion.dataSourceType === 'UNKNOWN') {
            $scope.data.editMode = true;
        }

        $scope.reloadFields();
    };

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.invalidLogics) {
            $scope.data.invalidLogics = changesObj.invalidLogics.currentValue;
        }
    };

    $scope.openSyncModal = function () {
        modalUtils.openSyncTracksModal(
            $scope.data.groupId,
            $scope.data.workflowVersionId,
            $scope.data.workflowVersionId,
        );
    };

    $scope.selectOutlineTab = function (tab) {
        $rootScope.$broadcast('selectWorkflowOutlineTab', { tabKey: tab });
        $scope.onComponentClosed();
    };

    $scope.chooseManualDataSource = function () {
        $scope.data.targetDataSourceForEdit = 'MANUAL';

        analyticsWrapper.track('Select data source', {
            category: 'Worker data source',
            label: $scope.data.targetDataSourceForEdit,
        });

        workflowVersionDataSourceManager.updateWorkflowVersionDataSourceType($scope.data.groupId, 'MANUAL').then(() => {
            $scope.onDataSourceChanged();

            if (workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).isScheduled) {
                // manual worker can't be scheduled
                $scope.onWorkerTypeOptionSelection(false);
            }
        });
    };

    $scope.goBack = function () {
        $scope.data.editMode = true;
        $scope.data.targetDataSourceForEdit = null;

        workflowVersionDataSourceManager
            .updateWorkflowVersionDataSourceType($scope.data.groupId, 'UNKNOWN')
            .then(() => $scope.onDataSourceChanged());
    };

    $scope.submit = function () {
        // will trigger save for the tnk-manage-field
        $scope.data.columns.ctrlObject.saveChanges();

        // Call the analytics.
        analyticsWrapper.track(`${'edit' + ' '}${$scope.data.type} list`, {
            category: 'Edit datasource worker',
        });

        // Save the group's settings.
        $scope.data.editGroupControlObject
            .submit(
                !workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).shouldSendGatherUpdates,
            )
            .then(function (group) {
                groupInfoManager.getGroup($scope.data.groupId, true).finally(function () {
                    // Emit a success alert.
                    $scope.$emit('alert', {
                        msg: `"${group.name}" saved successfully!`,
                        type: 'success',
                    });

                    // Broadcast the update to the system.
                    $rootScope.$broadcast('newActivityUpdate');

                    if (ctrl.onComponentClosed) {
                        ctrl.onComponentClosed();
                    }
                });
            })
            .catch((error) => {
                if (error !== 'dismissed') {
                    $scope.$emit('alert', { type: 'error', msg: `Failed saving group ${$scope.data.group.name}` });
                }

                $scope.loading = false;
            });
    };

    $scope.onComponentClosed = function () {
        // If there was a sync, need to get the workflow version from server because the dataSourceType might have changed.
        groupInfoManager.getGroup($scope.data.groupId, true).then(() => $scope.onDataSourceChanged());

        if ($attrs.onComponentClosed) {
            ctrl.onComponentClosed();
        }
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onScheduledTrackNameExpressionChanged = function (originalExpression, evaluatedExpression) {
        if ($scope.data.scheduledTrackName !== originalExpression) {
            if (originalExpression === '') {
                $scope.data.scheduledTrackName = null;
            } else {
                $scope.data.scheduledTrackName = originalExpression;
            }

            if (evaluatedExpression === '') {
                $scope.data.evaluatedScheduledTrackName = null;
            } else {
                $scope.data.evaluatedScheduledTrackName = evaluatedExpression;
            }

            workflowVersionManager
                .updateWorkflowVersionScheduledWorkerDefinition(
                    $scope.data.groupId,
                    $scope.data.scheduledTrackName,
                    $scope.data.evaluatedScheduledTrackName,
                )
                .catch(() => {
                    $scope.data.errorUpdateGroupScheduledWorkerDefinition = 'There was an error trying to save.';
                })
                .finally(() => {});
        } else {
            $scope.data.scheduledTrackName = originalExpression;
            $scope.data.evaluatedScheduledTrackName = evaluatedExpression;
        }
    };

    /**
     * Navigate to the specific project integration page using the return to state button
     */
    $scope.goToProjectIntegrationPageWithReturnTo = function () {
        $state.go('product.projectIntegrationPage', {
            fromName: projectManager.groupsMap[$scope.data.groupId].name,
            fromState: 'product.workerEditor',
            fromStateParams: $state.params,
            enterpriseComponentId: syncConfigCacheManager.getSyncConfig($scope.data.workflowVersionId).projectIntegration.id,
            page: ProjectIntegrationPageMenuItemType.SOLUTIONS_ACCESS,
        });
    };

    /**
     * Occurs on a change of time recurrence configuration.
     */
    $scope.onRecurrenceTimeSelectionChanged = function (recurrenceTimeSelection) {
        $scope.data.recurrenceTimeSelection = recurrenceTimeSelection;
        $scope.data.savingRecurrenceTimeSelection = true;
        $scope.data.errorSavingRecurrenceTimeSelection = null;

        workflowVersionDataSourceManager
            .updateWorkflowVersionRecurringTime({
                groupId: $scope.data.groupId,
                recurrencePeriodType: $scope.data.recurrenceTimeSelection.recurrencePeriodType,
                recurrenceDaysInWeek: $scope.data.recurrenceTimeSelection.recurrenceDaysInWeek,
                recurrenceDaysInMonth: $scope.data.recurrenceTimeSelection.recurrenceDaysInMonth,
                recurrenceHour: $scope.data.recurrenceTimeSelection.recurrenceHour,
                everyXMinutes:
                    $scope.data.recurrenceTimeSelection.recurrencePeriodType === 'EveryXMinutes'
                        ? $scope.data.recurrenceTimeSelection.everyXMinutes
                        : null,
                everyXHours:
                    $scope.data.recurrenceTimeSelection.recurrencePeriodType === 'EveryXHours'
                        ? $scope.data.recurrenceTimeSelection.everyXHours
                        : null,
                doNotRunOnWeekends: $scope.data.recurrenceTimeSelection.doNotRunOnWeekends,
                recurrenceMinute: $scope.data.recurrenceTimeSelection.recurrenceMinute,
                recurrenceMonthsInYear: $scope.data.recurrenceTimeSelection.recurrenceMonthsInYear,
                timezone: $scope.data.recurrenceTimeSelection.timezone,
            })
            .then(() => {
                ctrl.onRecurrenceTimeSelectionChanged();
            })
            .catch(() => {
                $scope.data.errorSavingRecurrenceTimeSelection = 'There was an error trying to save.';
            })
            .finally(() => {
                $scope.data.savingRecurrenceTimeSelection = false;
            });
    };

    /**
     * Tells the worker to run immediately.
     */
    $scope.runWorkerNow = function () {
        $scope.data.sendingRunWorkerNow = true;
        $scope.data.errorSendingRunWorkerNow = null;

        tonkeanService
            .runScheduledWorkerNow($scope.data.workflowVersionId)
            .catch(() => {
                $scope.data.errorSendingRunWorkerNow = 'Error trying to run worker.';
            })
            .finally(() => {
                $scope.data.sendingRunWorkerNow = false;
            });
    };

    /**
     * Toggles the worker configuration flag.
     */
    $scope.toggleWorkerConfiguration = function () {
        $scope.data.configureWorker = !$scope.data.configureWorker;
    };

    /**
     * Occurs on a radio button option selected.
     */
    $scope.onWorkerTypeOptionSelection = function (isScheduled) {
        analyticsWrapper.track('Select simple worker type', { category: 'Worker data source', label: isScheduled });

        workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).isScheduled = isScheduled;
        $scope.toggleWorkerConfiguration();

        $scope.data.savingIsScheduledProperty = true;
        $scope.data.errorSavingIsScheduledProperty = null;

        $scope.data.editScheduled = false;

        tonkeanService
            .updateWorkflowVersionIsScheduled($scope.data.groupId, isScheduled)
            .then((updatedWorkflowVersion) => {
                workflowVersionManager.updateWorkflowVersion(updatedWorkflowVersion);
            })
            .catch(() => {
                $scope.data.errorSavingIsScheduledProperty = 'There was an error trying to save is scheduled property';
            })
            .finally(() => {
                $scope.data.savingIsScheduledProperty = false;
            });
    };

    $scope.onIntegrationSelected = function (projectIntegration) {
        analyticsWrapper.track('Select data source', {
            category: 'Worker data source',
            label: projectIntegration ? projectIntegration.name : 'Removed source',
        });
        $scope.data.selectedIntegration = projectIntegration;

        if (workflowVersionManager.getCachedWorkflowVersion($scope.data.workflowVersionId).isScheduled) {
            // integration worker can't be scheduled
            $scope.onWorkerTypeOptionSelection(false);
        }

        // If sync removed was removed
        if (!projectIntegration) {
            workflowVersionDataSourceManager
                .updateWorkflowVersionDataSourceType($scope.data.groupId, 'UNKNOWN', true)
                .then(() => $scope.onDataSourceChanged());
        }
    };

    /**
     * Update reload flag so tnk-columns will reload his fields.
     */
    $scope.reloadFields = function () {
        $scope.data.shouldReloadFields = true;
        groupInfoManager.getGroup($scope.data.group.id).then(function () {
            $scope.data.group = projectManager.groupsMap[$scope.data.group.id];
            $scope.data.shouldReloadFields = false;
            ctrl.setShouldReloadFields();
        });
    };

    $scope.onDataSourceChanged = function () {
        ctrl.onDataSourceChanged();
    };

    /**
     * Mark edit scheduled.
     */
    $scope.setEditScheduled = function () {
        $scope.data.editScheduled = true;
    };
}

export default angular
    .module('tonkean.app')
    .controller('DataSourceWorkerComponentCtrl', lateConstructController(DataSourceWorkerComponentCtrl));
