import { useAngularService } from 'angulareact';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import ItemInterfacePermission from '../entities/ItemInterfacePermission';
import SystemInterfaceDisplay from '../SystemInterface/components/SystemInterface/SystemInterfaceDisplay';
import useGetItemInterfaceData from '../SystemInterface/hooks/useGetItemInterfaceData';

import {
    Breakpoint,
    ItemInterfaceSection,
    LayoutGridElements,
    Modal,
    ModalAnimationType,
    useBreakpoint,
    useInitiativeById,
    useUserPermissionsForInitiativeInGroup,
} from '@tonkean/infrastructure';
import LoadingTonkean from '@tonkean/infrastructure/components/LoadingTonkean';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const BACKDROP_BACKGROUND = 'rgba(0, 0, 0, 0.5)';

const DrawerWhenMobileModal = styled(Modal)`
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 100%;
        margin: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 8px 8px 0px 0px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;

    // CSS to set widget panel sizes
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 8;
    @media screen and (min-width: ${Breakpoint.MINIATURE_0}px) and (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }
`;

const Body = styled.main`
    overflow-y: scroll;
    flex-grow: 1;
    background-color: ${Theme.colors.gray_100};
    display: flex;
    flex-direction: column;
`;

interface Props {
    open?: boolean;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined;
    itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    originWidget?: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET>;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    onClose?: () => void;
    showNavigation?: boolean;
}

const ItemInterfaceModal: React.FC<Props> = ({
    open = true,
    initiativeId,
    itemInterfaceId,
    originWidget,
    projectId,
    onClose,
    showNavigation = false,
}) => {
    const {
        showLoading: showLoadingInitial,
        itemInterface,
        workflowVersion,
        widgetsResponse,
        includedWidgetsSummaryByInterfaceId,
    } = useGetItemInterfaceData(projectId, initiativeId, originWidget, itemInterfaceId);

    const { initiative, loading: loadingInitiative } = useInitiativeById(initiativeId);

    const { canUserEditItem } = useUserPermissionsForInitiativeInGroup(
        initiative?.isArchived,
        initiative?.isDraftInitiative,
        itemInterface?.group,
    );

    const permission = !canUserEditItem
        ? ItemInterfacePermission.USER_CANT_EDIT_ITEM
        : ItemInterfacePermission.USER_CAN_EDIT_ITEM;

    const $location = useAngularService('$location');
    const $timeout = useAngularService('$timeout');

    const isOpen = !!initiativeId && open;

    const onCloseCallback = useCallback(() => {
        // Using timeout for immediate digest cycle
        $timeout(() => {
            $location.search('tid', null);
            $location.search('originWidget', null);
        });
        onClose?.();
    }, [$location, $timeout, onClose]);

    const effectiveBreakpoint = useBreakpoint();

    const isMobile = Number(effectiveBreakpoint) <= Breakpoint.MID_XSMALL_768;

    return (
        <DrawerWhenMobileModal
            open={isOpen}
            onClose={onCloseCallback}
            modalAnimationType={
                isMobile ? ModalAnimationType.ANIMATE_BOTTOM_TO_TOP : ModalAnimationType.ANIMATE_OPACITY
            }
            backdropBackground={BACKDROP_BACKGROUND}
            animationDuration={350}
            windowedFullScreen
        >
            {isOpen && (
                <Wrapper>
                    <Body>
                        {showLoadingInitial || loadingInitiative ? (
                            <LoadingTonkean />
                        ) : (
                            itemInterface && (
                                <SystemInterfaceDisplay
                                    initiativeId={initiativeId}
                                    group={itemInterface.group}
                                    workflowVersion={workflowVersion}
                                    itemInterface={itemInterface}
                                    widgets={widgetsResponse?.entities || []}
                                    includedWidgetsSummaryByInterfaceId={includedWidgetsSummaryByInterfaceId}
                                    permissions={permission}
                                    showBreadcrumbs={false}
                                    showReturnToHomepageButton={false}
                                    showPoweredByTonkean={false}
                                    onBack={onClose}
                                    section={ItemInterfaceSection.MAIN}
                                    showNavigation={showNavigation}
                                    showPanel
                                    showClosingButton
                                />
                            )
                        )}
                    </Body>
                </Wrapper>
            )}
        </DrawerWhenMobileModal>
    );
};

export default ItemInterfaceModal;
