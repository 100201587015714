import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Accordion, Placeholder, PlaceholderGrid, PlaceholderGridDirection } from '@tonkean/infrastructure';
import type { WorkflowFolderVersionSummary } from '@tonkean/tonkean-entities';
import { WorkflowFolderVersionType } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

export const SequentialIdentifier = styled.span<{
    inHeader?: boolean;
}>`
    color: ${Theme.colors.gray_600};
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;

    ${({ inHeader }) =>
        inHeader &&
        css`
            margin-right: 4px;
        `}
`;

const VersionAccordion = styled(Accordion)`
    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                ${SequentialIdentifier} {
                    color: ${Theme.colors.gray_700};
                }
            }
        `};
`;

const Title = styled.div`
    flex-grow: 1;
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${Theme.colors.gray_800};
`;

const ChangedModules = styled.div<{
    isCurrent: boolean;
}>`
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    display: flex;
    align-items: center;
    color: ${({ isCurrent = false }) => (isCurrent ? Theme.colors.success : Theme.colors.gray_600)};
`;

const ChangedModulesCount = styled.div<{
    isCurrent: boolean;
}>`
    margin-left: 4px;
    min-width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: 15px;
    vertical-align: middle;
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    padding: 0 3px;

    background-color: ${({ isCurrent = false }) => (isCurrent ? Theme.colors.success : Theme.colors.gray_300)};
    color: ${({ isCurrent = false }) => (isCurrent ? Theme.colors.basicBackground : Theme.colors.gray_700)};
`;

interface Props {
    workflowFolderVersionSummary?: WorkflowFolderVersionSummary;
    isSearching?: boolean;
}

const SolutionVersionsVersionContainer: React.FC<React.PropsWithChildren<Props>> = ({
    workflowFolderVersionSummary,
    isSearching = false,
    children,
}) => {
    const isPublished =
        workflowFolderVersionSummary?.workflowFolderVersion.workflowFolderVersionType ===
        WorkflowFolderVersionType.PUBLISHED;

    // If currently searching or this is the published version, we want to expand it by default. Otherwise it should be
    // closed.
    const shouldBeOpenedByDefault = isPublished || isSearching;

    const [expanded, setExpanded] = useState(shouldBeOpenedByDefault);

    useEffect(() => {
        setExpanded(shouldBeOpenedByDefault);
    }, [shouldBeOpenedByDefault]);

    const publishDate = useMemo(() => {
        if (!workflowFolderVersionSummary) {
            return;
        }

        return utils.formatDate(workflowFolderVersionSummary.workflowFolderVersion.created, true, true, true);
    }, [workflowFolderVersionSummary]);

    const changedModulesCount = useMemo(() => {
        if (!workflowFolderVersionSummary) {
            return;
        }

        return workflowFolderVersionSummary.workflowFolderVersionGroupSummaries.filter((summary) => summary.hadChanges)
            .length;
    }, [workflowFolderVersionSummary]);

    return (
        <VersionAccordion
            onChange={setExpanded}
            disabled={!workflowFolderVersionSummary}
            open={expanded}
            title={
                <>
                    <Title>
                        {workflowFolderVersionSummary ? (
                            <>
                                <SequentialIdentifier inHeader>
                                    V{workflowFolderVersionSummary?.workflowFolderVersion.sequentialIdentifier}
                                </SequentialIdentifier>{' '}
                                {publishDate}
                            </>
                        ) : (
                            <PlaceholderGrid direction={PlaceholderGridDirection.COLUMNS}>
                                <Placeholder $width="20px" $height="12px" />
                                <Placeholder $width="80px" $height="12px" />
                            </PlaceholderGrid>
                        )}
                    </Title>
                    <ChangedModules isCurrent={isPublished}>
                        {workflowFolderVersionSummary ? (
                            <>
                                Changed Assets{' '}
                                <ChangedModulesCount isCurrent={isPublished}>{changedModulesCount}</ChangedModulesCount>
                            </>
                        ) : (
                            <PlaceholderGrid direction={PlaceholderGridDirection.COLUMNS}>
                                <Placeholder $width="60px" $height="12px" />
                                <Placeholder $width="12px" $circle />
                            </PlaceholderGrid>
                        )}
                    </ChangedModules>
                </>
            }
            contentPadding
        >
            {children}
        </VersionAccordion>
    );
};

export default SolutionVersionsVersionContainer;
