import googleSlidesIcon from '../../../../apps/tracks/images/googleslides.png';
import googleSlidesCircleIcon from '../../../../apps/tracks/images/integrations/googleslides-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

export default class GoogleSlidesIntegration extends IntegrationDefinitionBase {
    public googleSlidesScope =
        'https://www.googleapis.com/auth/drive ' +
        'https://www.googleapis.com/auth/drive.file ' +
        'https://www.googleapis.com/auth/drive.readonly ' +
        'https://www.googleapis.com/auth/presentations ' +
        'https://www.googleapis.com/auth/spreadsheets ' +
        'https://www.googleapis.com/auth/spreadsheets.readonly';

    override name = 'googleslides';
    override displayName = 'Google Slides';
    override description = 'We are not collecting any data';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.OAUTH;

    override entities = [];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(googleSlidesCircleIcon, '130px'),
        new IntegrationIcon(googleSlidesIcon, '130px'),
    );

    override async authenticate(oAuthHandler, rootScope): Promise<Record<string, any>> {
        const redirectUri = rootScope.integrationKeysMap.googleUri;
        const clientId = rootScope.integrationKeysMap.google;
        const code = await oAuthHandler.google(clientId, this.googleSlidesScope, redirectUri, true);

        return { code, redirectUri };
    }
}
