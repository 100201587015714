import React from 'react';
import styled, { css } from 'styled-components';

import type { ItemInterfacePermission } from '../../../entities';
import type { InterfaceLiveDisplay } from '../ItemInterface/InterfaceLiveDisplay';
import PanelItemInterfaceView from '../ItemInterface/PanelItemInterfaceView';

import type { ItemInterfaceContextData } from '@tonkean/infrastructure';
import { ItemInterfaceContext, useToggle } from '@tonkean/infrastructure';
import { ChevronDoubleIcon } from '@tonkean/svg';
import type { ItemInterfaceLoadingState } from '@tonkean/tonkean-entities';
import type {
    Initiative,
    InterfaceCTASavedAction,
    ItemInterface,
    ItemInterfaceWidget,
    ItemInterfaceWidgetConfiguration,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Container = styled.div<{ mobileSidePaneClosed: boolean }>`
    height: 100%;
    position: relative;
    transition: all 0.3s ease;
    z-index: 2;
    width: ${({ mobileSidePaneClosed }) => (mobileSidePaneClosed ? '32px' : '95%')};
`;

const PanelItemInterfaceViewWrapper = styled.div<{ mobileSidePaneClosed: boolean }>`
    position: absolute;
    height: 100%;
    overflow-y: auto;
    box-shadow: -7px 0px 9px 0px rgba(0, 0, 0, 0.06);
    z-index: 2;
    transition: all 0.3s ease;

    ${({ mobileSidePaneClosed }) => css`
        right: ${mobileSidePaneClosed ? '0' : '0'};
        width: ${mobileSidePaneClosed ? '32px' : '100%'};
    `};
`;

const ToggleButton = styled.button<{ mobileSidePaneClosed: boolean }>`
    right: ${({ mobileSidePaneClosed }) => (mobileSidePaneClosed ? '17px' : 'calc(100% - 15px)')};

    border: 1px solid ${Theme.colors.gray_300};
    position: absolute;
    top: 30px;
    z-index: 3;
    background-color: ${Theme.colors.white};
    box-shadow: -7px 0px 9px 0px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: right 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Overlay = styled.div<{ mobileSidePaneClosed: boolean }>`
    transition:
        opacity 0.3s ease,
        visibility 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;

    ${({ mobileSidePaneClosed }) => css`
        opacity: ${!mobileSidePaneClosed ? '0.8' : '0'};
        visibility: ${!mobileSidePaneClosed ? 'visible' : 'hidden'};
    `}
`;

const CustomDoubleChevron = styled(ChevronDoubleIcon)<{ mobileSidePaneClosed: boolean }>`
    transition: transform 0.3s ease;
    ${({ mobileSidePaneClosed }) =>
        mobileSidePaneClosed &&
        css`
            transform: rotate(180deg);
        `}
`;
interface ItemInterfacePanelMobileProps {
    panelItemInterfaceContextValue: ItemInterfaceContextData;
    panelItemInterface: ItemInterface;
    itemInterfaceWidgetsState: ItemInterfaceLoadingState;
    widgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    permission: ItemInterfacePermission;
    showPoweredByTonkean: boolean;
    itemActions: InterfaceCTASavedAction[];
    refreshItem: (() => void) | undefined;
    liveDisplay: InterfaceLiveDisplay | undefined;
}

export const ItemInterfaceMobilePanel: React.FC<ItemInterfacePanelMobileProps> = ({
    panelItemInterfaceContextValue,
    panelItemInterface,
    itemInterfaceWidgetsState,
    widgets,
    initiative,
    workflowVersion,
    permission,
    showPoweredByTonkean,
    itemActions,
    refreshItem,
    liveDisplay,
}) => {
    const [sidePanelClosed, toggleIsMobileSidePaneClosed] = useToggle(true);

    return (
        <Container mobileSidePaneClosed={sidePanelClosed}>
            <ToggleButton mobileSidePaneClosed={sidePanelClosed} onClick={toggleIsMobileSidePaneClosed}>
                <CustomDoubleChevron mobileSidePaneClosed={sidePanelClosed} />
            </ToggleButton>

            <Overlay mobileSidePaneClosed={sidePanelClosed} onClick={toggleIsMobileSidePaneClosed} />
            <PanelItemInterfaceViewWrapper mobileSidePaneClosed={sidePanelClosed}>
                <ItemInterfaceContext.Provider value={panelItemInterfaceContextValue}>
                    {!sidePanelClosed && (
                        <PanelItemInterfaceView
                            itemInterface={panelItemInterface}
                            itemInterfaceWidgetsState={itemInterfaceWidgetsState}
                            widgets={widgets}
                            initiative={initiative}
                            workflowVersion={workflowVersion}
                            permission={permission}
                            showPoweredByTonkean={showPoweredByTonkean}
                            itemActions={itemActions}
                            refreshItem={refreshItem}
                            liveDisplay={liveDisplay}
                        />
                    )}
                </ItemInterfaceContext.Provider>
            </PanelItemInterfaceViewWrapper>
        </Container>
    );
};

export default ItemInterfaceMobilePanel;
