import template from './tnkTonkeanExpression.template.html?angularjs';

angular.module('tonkean.app').component('tnkTonkeanExpression', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        workflowFolderId: '@',
        entityVersionType: '@',
        logicId: '@',
        placeholder: '@',
        expressionUniqueIdentifier: '@',
        elementId: '@', // Optional, if present will put this id on the html element of the expression
        savedOriginalExpression: '<',
        savedEvaluatedExpression: '<',
        savedExpression: '<', // Expressions object containing (savedOriginalExpression and savedEvaluatedExpression). Can be passed instead of these properties for ease of use.
        savedIsStripHtmlDisabled: '<',
        additionalTabs: '<',
        paramForTabs: '<',
        disabled: '<',
        disabledReason: '<',
        prefix: '@', // Optional, if exists add an un-editable prefix
        customPreview: '@', // Optional, if exists showing this as preview

        doNotEvaluatePreview: '<', // When false, will evaluate the expression for preview.
        overrideExampleInitiative: '<', // an example initiative for the preview.
        hidePreview: '<', // weather to show preview by default

        onTonkeanExpressionChanged: '&',
        modNormalInput: '<', // When true, a normal, plain text, input is displayed instead of the advanced tags input.
        modTextArea: '<', // When true, a multi line textarea is displayed instead of the advanced tags input.
        textAreaNumberOfRows: '<', // Represents the number of rows in the text area

        globalExpressionOnly: '<', // When true, will not show anything related to initiative like fields.
        showToggleStripHtml: '<', // When true, a small menu with a toggle strip HTML button will appear.
        modFixedWidth: '<', // Setting it true will prevent the expression field input from expending.
        tabsFieldSelectorModNarrow: '<', // Making the width of the tnkTabsFieldSelector narrower.
        tabsFieldSelectorModFixedWidth: '<', // Making the width of the tnkTabsFieldSelector fixed.

        showFormulasTab: '<', // If false, it will hide the formulas tab in the expression editor.
        saveOnKeyUp: '<', // Should it emit onExpressionUpdated on every keydown. If false, will emit on blur.
        shouldDeleteVariable: '<', // If exists we use this method to know if we should delete variable.
        hideEditorButton: '<', // If exists we hide the editor button.
        className: '<', // for styling with styled component

        automationIdentifierExpressionValue: '@',
        automationIdentifierExpressionAddField: '@',

        showPeopleDirectories: '<',

        previewEvaluationSource: '<', // Evaluation source (Either example item id or worker run logic)
        defaultTabId: '<', // Optional, if exists this tab will open by default.
        excludedTabSelectorSpecialFields: '<', // remove from special fields tab selector fields (for example ['TNK_TODAY']) that are considerInTypes:'FIELDS_TAB_SELECTOR' or any provided specialFieldsValuesForFeatures.
        includeTabSelectorSpecialFieldsForFeatures: '<', // Use 'considerInTypes' to add fields under this category to the special fields tab selector
        fieldsToFilter: '<', // list of fields that should be filtered from options
    },
    template,
    controllerAs: '$ctrl',
    controller: 'TonkeanExpressionCtrl',
});
