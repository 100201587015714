import type { ColDef } from '@ag-grid-community/core';

import { ERRORED_ROW_CLASS_NAME } from './customClassNames';
import ColumnId from '../configs/config/columnId';
import { type FieldValidationParams, isNotRealRow, isRowLoading } from '../entities';
import type FieldValidation from '../entities/FieldValidation';
import type InitiativeRowData from '../entities/InitiativeRowData';

import { Theme } from '@tonkean/tui-theme';

const getOptionsDefinitions = (
    isEditable: boolean,
    validations: FieldValidation<FieldValidationParams>[] | undefined,
    hasRowMarker?: boolean,
) => {
    return {
        colId: ColumnId.Options,
        headerName: '',
        filter: false,
        pinned: 'left',
        cellRenderer: 'optionsRenderer',
        maxWidth: 36,
        minWidth: 36,
        enableCellChangeFlash: false,
        cellStyle: () => ({
            padding: 0,
            margin: 0,
            alignItems: 'center',
            fontFamily: 'Roboto',
            color: Theme.colors.gray_800,
        }),
        cellClassRules: {
            [ERRORED_ROW_CLASS_NAME]: (params) => {
                if (
                    !params.data ||
                    (params.data && isNotRealRow(params.data)) ||
                    !isEditable ||
                    (params.data && isRowLoading(params.data))
                ) {
                    return false;
                }

                const hasOneInvalid = (validations || []).some(
                    (lineItemValidation) => !lineItemValidation.isValid(params.data),
                );

                return hasOneInvalid;
            },
        },
        cellRendererParams: {
            hasRowMarker,
        },
        valueGetter: (value) => {
            return JSON.stringify(value.node?.data || '{}') + value.node?.rowIndex || '';
        },
    } as ColDef<InitiativeRowData>;
};

export default getOptionsDefinitions;
