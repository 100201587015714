import { generateDataObject, getUsersToMap, tryToMatchExisting } from './mapIntegrationUsers';

function MapIntegrationUsersModalCtrl(
    $scope,
    $uibModalInstance,
    $q,
    projectManager,
    createProjectApis,
    tonkeanService,
    utils,
    projectIntegration,
    comparePropertyKey,
    requestPager,
    usersToMap,
    onUsersMapped,
) {
    $scope.data = {
        comparePropertyKey,
        projectIntegration,
        users: [],
        mappedUsersMap: {},
        mappedCount: 0,
        totalCount: 0,
        showActorNameAndActorIdentifyingName: true,
    };

    function shouldShowActorNAmeAndActorIdentifyingName() {
        return $scope.data.users.some((user) => user.actorName !== user.actorIdentifyingName);
    }

    $scope.init = () => {
        $scope.loading = true;

        const usersToMapPromise = usersToMap
            ? Promise.resolve(usersToMap)
            : getUsersToMap(
                  createProjectApis,
                  comparePropertyKey,
                  $scope.data.projectIntegration.id,
                  $scope.pm.project.id,
              );

        usersToMapPromise
            .then((usersToMap) => {
                return tryToMatchExisting(
                    requestPager,
                    tonkeanService,
                    projectManager,
                    usersToMap.users,
                    comparePropertyKey,
                ).then(() => usersToMap);
            })
            .then(({ users, mappedUsersMap, mappedCount, totalCount }) => {
                $scope.data.users = users;
                $scope.data.mappedUsersMap = mappedUsersMap;
                $scope.data.mappedCount = mappedCount;
                $scope.data.totalCount = totalCount;
                $scope.data.showActorNameAndActorIdentifyingName = shouldShowActorNAmeAndActorIdentifyingName();
            })
            .catch((error) => ($scope.error = error))
            .finally(() => ($scope.loading = false));
    };

    $scope.submit = () => {
        const result = $scope.data.users
            .filter(({ people: [person] }) => person?.isValid || person?.id)
            .map((user) => ({
                comparePropertyKey: user[comparePropertyKey] ?? user.comparePropertyKey,
                email: user.people[0].email,
            }));

        $scope.saving = true;
        createProjectApis
            .updateProjectIntegrationUserMapping($scope.data.projectIntegration.id, result, true)
            .then(function () {
                $scope.$emit('alert', { msg: 'User mapping saved.', type: 'success' });

                if (onUsersMapped) {
                    const users = $scope.data.users.map((user) => {
                        if (user.people.length) {
                            return { ...user, saved: true };
                        }
                        return { ...user, saved: false };
                    });

                    onUsersMapped(generateDataObject(users));
                }

                $uibModalInstance.close();
            })
            .catch(function (error) {
                $scope.saving = false;
                $scope.error = error;
            });
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('MapIntegrationUsersModalCtrl', MapIntegrationUsersModalCtrl);
