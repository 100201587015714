/**
 * Created by ShaLi on 5/13/15.
 */

function categoryFormatsPrefix() {
    return function (categoryFormats) {
        if (!categoryFormats || categoryFormats.length === 0) {
            return '';
        }

        const suffix = ' due to: ';

        if (categoryFormats.length > 1) {
            return `Multiple types of challenges${suffix}`;
        }

        const formatText = categoryFormats[0].categoryFormat;
        const params = categoryFormats[0].params;

        if (!formatText.includes('{}') || params.length === 0) {
            return formatText + suffix;
        }

        if (params.length === 1) {
            return formatText.replace('{}', params[0]) + suffix;
        }

        let formatParams = '';
        for (let i = 0; i < params.length; i++) {
            let prefix = '';
            if (i > 0 && i < params.length - 1) {
                prefix = ', ';
            } else if (i === params.length - 1) {
                prefix = ' and ';
            }
            formatParams = formatParams + prefix + params[i];
        }

        return formatText.replace('{}', formatParams) + suffix;
    };
}
export default angular.module('tonkean.app').filter('categoryFormatsPrefix', categoryFormatsPrefix);
