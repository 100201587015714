import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import WidgetItemsSingleFilter from './WidgetItemsSingleFilter';

import { CustomFilters } from '@tonkean/angular-to-react-components';
import { Breakpoint, Chevron, ChevronDirection, Popover, TextEllipsis } from '@tonkean/infrastructure';
import { XIcon } from '@tonkean/svg';
import { FieldQueryType } from '@tonkean/tonkean-entities';
import type {
    BasicQueryDefinition,
    FieldDefinition,
    FieldFilter,
    Group,
    Project,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import type { FieldDefinitionKey } from '@tonkean/tonkean-entities';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme, FontSize } from '@tonkean/tui-theme';
import { colorSvg } from '@tonkean/utils';

const XButtonContainer = styled(Clickable)`
    &:hover {
        background: ${Theme.colors.white};
        ${colorSvg(Theme.colors.gray_800)}
    }
    width: 20px;
    border-radius: 8px;
    @media (max-width: ${Breakpoint.SMALL_1366}px) {
        width: 40px;
    }
`;

const XButtonWrapper = styled.div`
    margin: 0 auto;
    width: fit-content;
`;

const XButton = styled(XIcon)`
    width: 8px;
    height: 8px;
    ${colorSvg(Theme.colors.gray_600)}
`;

const FilterButton = styled.div<{ $hasValue: boolean }>`
    border-radius: 36px;
    height: 30px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border: ${({ $hasValue }) =>
        $hasValue ? `2px solid ${Theme.colors.green}` : `1px solid ${Theme.colors.gray_300}`};
    background-color: ${({ $hasValue }) => ($hasValue ? `rgba(44,145,103,0.1)` : `${Theme.colors.white}`)};
    @media (max-width: ${Breakpoint.SMALL_1366}px) {
        height: 40px;
        padding: 20px;
    }
`;

const ChevronContainer = styled(Clickable)`
    padding-left: 5px;
`;

const FieldLabel = styled(Clickable)`
    font-size: ${FontSize.SMALL_12};
    width: 100%;
    color: ${Theme.colors.gray_700};
    @media (max-width: ${Breakpoint.SMALL_1366}px) {
        font-size: ${FontSize.MEDIUM_14};
    }
`;

const FilterValue = styled.span`
    font-weight: bold;
`;

interface Props {
    fieldFilter?: FieldFilter | undefined;
    projectId: Project['id'];
    groupId: Group['id'];
    workflowVersion: WorkflowVersion;
    label: string;
    fieldDefinition?: FieldDefinition | undefined;
    onFilterChanged?: (fieldId: FieldDefinition['id'], value: any) => void;
    onFilterRemoved?: (fieldId?: FieldDefinition['id']) => void;
    advancedFilter?: BasicQueryDefinition;
    onChangeAdvancedFilter?: (query: BasicQueryDefinition) => void;
    formattedFilterLabel?: string | undefined;
    onChangeFormattedLabel?: (fieldFilterKey: FieldDefinitionKey, newFormattedLabel: string | undefined) => void;
}

const WidgetItemSingleFilterPopover: React.FC<Props> = ({
    fieldFilter,
    projectId,
    groupId,
    workflowVersion,
    label,
    fieldDefinition,
    onFilterChanged,
    onFilterRemoved,
    advancedFilter,
    onChangeAdvancedFilter,
    formattedFilterLabel,
    onChangeFormattedLabel,
}) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const controlRef = useRef({});

    const onFilterChangedClosePopover = useCallback(
        (fieldId, value) => {
            setPopoverOpen(false);
            onFilterChanged?.(fieldId, value);
        },
        [onFilterChanged],
    );

    const hasAnyValue = advancedFilter ? advancedFilter.query.filters.length > 0 : fieldFilter?.value !== undefined;

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setPopoverOpen(false);
        }
    };

    const clearFilterValue = useCallback(() => {
        if (onChangeAdvancedFilter) {
            onChangeAdvancedFilter({
                query: {
                    type: FieldQueryType.And,
                    filters: [],
                    queries: [],
                },
            });
        } else if (onFilterRemoved) {
            onFilterRemoved(fieldDefinition?.id);
            fieldDefinition && onChangeFormattedLabel?.(fieldDefinition.id, undefined);
        }
    }, [fieldDefinition, onChangeAdvancedFilter, onChangeFormattedLabel, onFilterRemoved]);

    return (
        <Popover
            show={popoverOpen}
            onClose={() => {
                setPopoverOpen(false);
            }}
            placement="bottom-start"
            content={
                advancedFilter && onChangeAdvancedFilter ? (
                    <CustomFilters
                        control={controlRef.current}
                        existingDefinition={advancedFilter}
                        onFiltersChangeDefinition={onChangeAdvancedFilter}
                        itemsSource="COLUMN"
                        groupId={groupId}
                        workflowVersionId={workflowVersion.id}
                        specialFieldsForFeatures={['ITEM_INTERFACE_INNER_ITEMS_ADVANCED_FILTER']}
                        includeTabSelectorSpecialFieldsForFeatures={['VIEWER_FIELDS']}
                        hideTimeRangeSelection
                        hideCloseButton
                        editMode
                        useExpressionsForValue
                        verticalMod
                        smallMod
                    />
                ) : (
                    fieldDefinition &&
                    onFilterRemoved && (
                        <WidgetItemsSingleFilter
                            fieldFilter={fieldFilter}
                            projectId={projectId}
                            workflowVersion={workflowVersion}
                            groupId={groupId}
                            fieldDefinition={fieldDefinition}
                            onFilterChanged={onFilterChangedClosePopover}
                            onFilterRemoved={onFilterRemoved}
                            onChangeFormattedLabel={onChangeFormattedLabel}
                            onKeyDown={handleKeyDown}
                            label={label}
                        />
                    )
                )
            }
        >
            <FilterButton $hasValue={hasAnyValue} data-automation="widget-item-single-filter-popover-advanced-filter">
                <FieldLabel
                    onClick={() => {
                        setPopoverOpen(true);
                    }}
                >
                    <TextEllipsis numberOfLines={1} tooltip>
                        {label}
                        {formattedFilterLabel && <FilterValue> {formattedFilterLabel} </FilterValue>}
                    </TextEllipsis>
                </FieldLabel>
                {hasAnyValue && (
                    <XButtonContainer
                        onClick={() => clearFilterValue()}
                        data-automation="widget-item-single-filter-popover-x-button"
                    >
                        <XButtonWrapper>
                            <XButton />
                        </XButtonWrapper>
                    </XButtonContainer>
                )}

                <ChevronContainer
                    onClick={() => {
                        setPopoverOpen(true);
                    }}
                >
                    <Chevron direction={ChevronDirection.DOWN} />{' '}
                </ChevronContainer>
            </FilterButton>
        </Popover>
    );
};

export default WidgetItemSingleFilterPopover;
