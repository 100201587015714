import React from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../../components/ConfigurationSectionTitle';
import Section from '../../../../components/Section';
import { WidgetInlineActionsSelector } from '../../../CommonWidgetConfiguration';
import type { InnerItemWidgetConfiguration } from '../../../entities';
import LineItemsInitialValues from '../../../LineItemsWidgetModule/components/LineItemsInitialValues';
import { useGroupSelectorOptions } from '../../hooks';
import FilterAndSortItems from '../configurationEditorSettingsComponents/FilterAndSortItems';
import ItemCreationButtonCreateForm from '../configurationEditorSettingsComponents/ItemCreationButtonCreateForm';
import ItemInterfaceSelectorContainer from '../configurationEditorSettingsComponents/ItemInterfaceSelectorContainer';
import TableSize from '../configurationEditorSettingsComponents/RowHeightSelector';
import SelectCreateForm from '../configurationEditorSettingsComponents/SelectCreateForm';
import { SelectorTitle } from '../configurationEditorSettingsComponents/SelectorTitle';
import StyledWidgetConfigurationToggle from '../configurationEditorSettingsComponents/StyledWidgetConfigurationToggle';
import ItemsWidgetConfigurationEditorFieldSelector from '../ItemsWidgetConfigurationEditorFieldSelector';
import ItemsWidgetConfigurationEditorInterfaceSelector from '../ItemsWidgetConfigurationEditorInterfaceSelector';

import { SimpleSelect } from '@tonkean/infrastructure';
import type { FieldDefinition, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const StyledTableSize = styled(TableSize)`
    margin-bottom: 8px;
`;

interface Props {
    configuration: InnerItemWidgetConfiguration;
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
    fieldDefinitions: FieldDefinition[] | undefined;
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined;
    isLoadingFieldDefinitions: boolean;
}

const SolutionSiteItemsTableAgGridItemWidgetConfigurationEditorDisplay: React.FC<Props> = ({
    configuration,
    groupId,
    fieldDefinitions,
    workflowVersionId,
    isLoadingFieldDefinitions,
}) => {
    const groupSelectorOptions = useGroupSelectorOptions();

    return (
        <>
            <ItemInterfaceSelectorContainer>
                <ConfigurationSectionTitle>Items</ConfigurationSectionTitle>

                <StyledTableSize />

                <SelectorTitle>Module</SelectorTitle>
                <SimpleSelect name="configuration.groupId" options={groupSelectorOptions} />

                {groupId && workflowVersionId && (
                    <>
                        <ItemCreationButtonCreateForm />

                        <SelectCreateForm
                            itemCreationType={configuration.itemCreationType}
                            workflowVersionId={workflowVersionId}
                            groupId={groupId}
                        />

                        <ItemsWidgetConfigurationEditorInterfaceSelector
                            groupId={groupId}
                            workflowVersionId={workflowVersionId}
                        />

                        <FilterAndSortItems groupId={groupId} workflowVersionId={workflowVersionId} />

                        <StyledWidgetConfigurationToggle
                            title="Display advanced filters"
                            toolTip="Display advanced filters option at the top of the widget"
                            name="configuration.displayAdvancedFilters"
                        />

                        <StyledWidgetConfigurationToggle title="Emphasize Text" name="configuration.emphasizeText" />
                    </>
                )}
            </ItemInterfaceSelectorContainer>

            {workflowVersionId && (
                <>
                    <ItemsWidgetConfigurationEditorFieldSelector
                        configuration={configuration}
                        fieldDefinitions={fieldDefinitions}
                        initiative={undefined}
                        workflowVersionId={workflowVersionId}
                        fixedOrderForSpecialFields={false}
                        supportCardsViewFields={false}
                    />
                    <Section>
                        <WidgetInlineActionsSelector workflowVersionId={workflowVersionId} />
                    </Section>

                    {groupId && (
                        <Section>
                            <LineItemsInitialValues
                                workflowVersionId={workflowVersionId}
                                groupId={groupId}
                                fieldDefinitions={fieldDefinitions}
                                isLoadingFieldDefinitions={isLoadingFieldDefinitions}
                            />
                        </Section>
                    )}
                </>
            )}
        </>
    );
};

export default SolutionSiteItemsTableAgGridItemWidgetConfigurationEditorDisplay;
