import template from './setupGroupNew.template.html?angularjs';

/**
 * Component to display the templates gallery
 */
export default angular.module('tonkean.app').component('tnkSetupGroupNew', {
    bindings: {
        tab: '<',
        disableTabNavigation: '<',
        hideTitle: '<',
    },
    template,
    controller: 'SetupGroupNewCtrl',
});
