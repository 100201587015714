<div class="org-content-page org-content-padded" ng-if="!pm.isExpired" style="padding: 50px 0 0 30px">
    <!-- Title -->
    <h2 class="common-title margin-top-none margin-bottom-lg flex-grow">Invoices</h2>

    <!-- Loading -->
    <div class="common-page-load" ng-show="loading">
        <i class="loading-full-circle"></i>
    </div>

    <!-- Invoices table -->
    <table class="table" ng-show="!loading">
        <thead>
            <tr>
                <th>#</th>
                <th>Time Period</th>
                <th>Seats</th>
                <th>Amount</th>
                <th>Status</th>
                <th class="hidden-xs">Description</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr ng-repeat="item in invoices">
                <td class="common-ellipsis" style="max-width: 70px">{{ item.id }}</td>
                <td>
                    <div class="flex-vmiddle flex-wrap">
                        <span class="flex-no-shrink">{{ item.lines[0].periodStart | date: 'mediumDate' }}</span>
                        &nbsp;-&nbsp;
                        <span class="flex-no-shrink">{{ item.lines[0].periodEnd | date: 'mediumDate' }}</span>
                    </div>
                </td>
                <td>{{ item.lines[item.lines.length - 1].quantity }}</td>
                <td>
                    <div
                        uib-tooltip="This estimate is based on your current users and applications, excluding tax."
                        tooltip-enable="item.id === data.upcomingInvoiceId"
                        tooltip-placement="auto bottom"
                    >
                        {{ item.total / 100 }} {{ item.lines[0].currency.toUpperCase()
                        }}{{ item.id === data.upcomingInvoiceId ? ' *' : '' }}
                    </div>
                </td>
                <td>
                    <span ng-if="item.chargeId && item.paid" class="invoice-status mod-paid">PAID</span>
                    <span ng-if="!item.chargeId && !item.paid" class="invoice-status mod-open">OPEN</span>
                </td>
                <td class="common-break-long hidden-xs">
                    <div ng-repeat="line in item.lines">{{ line.description }}</div>
                </td>
                <td ng-if="item.invoiceHtml">
                    <a href="{{ item.invoiceHtml }}" target="_blank">View</a>
                </td>
                <td ng-if="!item.invoiceHtml">
                    <a ng-click="viewInvoice(item)">View</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
