import React, { useCallback } from 'react';
import styled from 'styled-components';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage } from '@tonkean/infrastructure';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const StyledError = styled.span`
    display: flex;
    flex-direction: column;
    max-width: 445px;
`;

const ResumeMessage = styled.div`
    display: flex;
`;

const InstructionsSection = styled.span`
    color: ${Theme.colors.gray_700};
    margin-top: 6px;
`;

const InstructionLabel = styled.span`
    margin-right: 4px;
`;

const StyledLink = styled(ClickableLink)`
    display: contents;
`;

interface Props {
    projectIntegrationId: string;
    onCollectNowRequestStarted?: () => void;
}

const ProjectIntegrationCollectStatusStopped: React.FC<Props> = ({
    projectIntegrationId,
    onCollectNowRequestStarted = () => {},
}) => {
    const [{ loading }, collectProjectIntegrationNow] = useLazyTonkeanService('collectProjectIntegrationNow');

    const onCollectNowClick = useCallback(() => {
        onCollectNowRequestStarted();
        collectProjectIntegrationNow(projectIntegrationId);
    }, [onCollectNowRequestStarted, collectProjectIntegrationNow, projectIntegrationId]);

    return (
        <ErrorMessage>
            <StyledError>
                <ResumeMessage>Data update is disabled due to over 100 consecutive collect failures</ResumeMessage>
                <InstructionsSection>
                    <InstructionLabel>After resolving the issue, click</InstructionLabel>
                    <StyledLink disabled={loading} onClick={onCollectNowClick}>
                        "Resume Collect" in order to continue auto collection.
                    </StyledLink>
                </InstructionsSection>
            </StyledError>
        </ErrorMessage>
    );
};

export default ProjectIntegrationCollectStatusStopped;
