import empty from './templates/empty';

function BuiltInListsTemplates() {
    const _this = this;
    const templatesObject = {};

    const templates = [
        // Empty manual list
        empty,
    ];

    for (const template of templates) {
        templatesObject[template.id] = template;
    }

    _this.templates = templatesObject;
}

export default angular.module('tonkean.shared').service('builtInListsTemplates', BuiltInListsTemplates);
