import utils from '@tonkean/utils';
import { getGroupsSectionsIds } from '@tonkean/constants';

function ProjectBoardCtrl(
    $rootScope,
    $scope,
    $stateParams,
    $state,
    $location,
    $sessionStorage,
    $localStorage,
    $log,
    $timeout,
    PageLoadState,
    tonkeanService,
    licensePermissionsService,
    enterpriseHelper,
    groupInfoManager,
    authenticationService,
    projectManager,
    workflowVersionManager,
    workflowFolderManager,
    navigationService,
    licenseManager,
) {
    $scope.as = authenticationService;
    $scope.pm = projectManager;
    $scope.wvm = workflowVersionManager;
    $scope.wfm = workflowFolderManager;
    $scope.ns = navigationService;
    $scope.$state = $state;

    $scope.data = {
        filterCollapsed: false,
        showGlobalFilters: $localStorage.showGlobalFilters,
        isEnterpriseUser: licensePermissionsService.isEnterpriseUser(),
        enterpriseName:
            $scope.as.currentUser && $scope.as.currentUser.enterprise ? $scope.as.currentUser.enterprise.name : '',
        isBotSidePaneOpen: false,
        loadingWorkflowFolders: false,
        solutionBusinessReports: [],
        shouldFetchAllGroups: true,
    };

    // The different inner bar we support (this function as an enum).
    $scope.innerBarType = { functions: 'func', tags: 'tags', status: 'status' };
    // Flag used to determine the current inner bar filter that's displayed.
    $scope.filterVisiblity = {};
    // Models for selected filter in mobile.
    $scope.mobileFilters = {};

    $scope.pageLoadState = new PageLoadState('main');

    $scope.$location = $location;
    $scope.loadingPage = $scope.pageLoadState.createFragment('loadingPage');

    const projectId = $stateParams.projectId;

    $scope.sectionsIds = getGroupsSectionsIds();

    $scope.groupSectionTypes = {
        starred: 'starred',
        allLists: 'allLists',
    };

    if (!$localStorage.sectionCollapse) {
        $localStorage.sectionCollapse = {};
    }

    $scope.data.sectionCollapse = $localStorage.sectionCollapse;

    // Important node: the "filter" property goes later in the HTML into an angular ng-repeat filter.
    // If you set, for example, dashboardHidden: false, it will filter out groups who don't have dashboardHidden property defined at all, and this is not what you want.
    // The workaround for it in angular is setting the filter to '!true' - so it will only keep those whose value is NOT TRUE (so undefined will be caught here too).
    $scope.groupLinksSectionDefinitions = [
        {
            type: $scope.groupSectionTypes.starred,
            title: 'STARRED',
            id: 'STARRED',
            requestedTabOnOpen: $scope.sectionsIds.starred,
            tooltip: 'View starred business reports',
            filterGroups: { isStarred: true, isExample: '!true', dashboardHidden: '!true' },
            filterSolutionBusinessReports: () => false,
            isVisibleOnEmpty: false,
        },
    ];

    $scope.init = function () {
        $rootScope.showHeader = false;
        $rootScope.showFooter = false;

        $scope.data.shouldFetchAllGroups = !isCurrentLocationIntake();

        if (projectId) {
            loadProject($scope.data.shouldFetchAllGroups).then(() => {
                $scope.data.loadingWorkflowFolders = true;

                const workflowFoldersPromise = workflowFolderManager.getWorkflowFolders(projectId, false);
                const solutionBusinessReportsPromise = tonkeanService.getSolutionBusinessReports(projectId);

                Promise.all([workflowFoldersPromise, solutionBusinessReportsPromise])
                    .then(function ([folders, reports]) {
                        $scope.data.solutionBusinessReports = reports;

                        if (folders) {
                            for (const solution of folders) {
                                const groupIdsMap = utils.arrayToSet(solution.groupIds);
                                if (solution.groupIds && solution.groupIds.length) {
                                    $scope.groupLinksSectionDefinitions.push({
                                        type: $scope.groupSectionTypes.allLists,
                                        title: solution.displayName,
                                        id: solution.id,
                                        workflowFolderId: solution.id,
                                        requestedTabOnOpen: $scope.sectionsIds.all,
                                        onlyIds: groupIdsMap,
                                        isVisibleOnEmpty: false,
                                        filterGroups(group) {
                                            return (
                                                !group.isStarred &&
                                                !group.isExample &&
                                                !group.dashboardHidden &&
                                                workflowFolderManager.projectIdToFolderIdToFolderMap[projectId][
                                                    solution.id
                                                ].groupIds.includes(group.id) &&
                                                projectManager.allowedBusinessReportIdsSet[group.id]
                                            );
                                        },
                                        filterSolutionBusinessReports(solutionBusinessReport) {
                                            return solutionBusinessReport.solutionId === solution.id;
                                        },
                                    });
                                }
                            }
                        }
                    })
                    .finally(() => {
                        $scope.data.loadingWorkflowFolders = false;
                    });
            });
        }

        if (!$sessionStorage.projectInvolveClosed) {
            $sessionStorage.projectInvolveClosed = {};
        }

        if (!$stateParams.projectId || $stateParams.projectId === 'board' || $stateParams.projectId === 'tnk') {
            const params = angular.copy($stateParams);
            const goToState =
                $rootScope.currentRouteStateName && $rootScope.currentRouteStateName.includes('product.')
                    ? $rootScope.currentRouteStateName
                    : 'product';

            if ($location.hash()) {
                params['#'] = $location.hash();
            }

            if ($scope.pm.project && $scope.pm.project.id) {
                $log.info(`Redirecting to latest project selected: ${$scope.pm.project.id}`);
                params.projectId = $scope.pm.project.id;
                checkLicense().then((result) => {
                    if (!result) {
                        $state.go(goToState, params, { location: 'replace' });
                    }
                });
            } else {
                $scope.pm.getProjects(false, true).then(async function () {
                    if ($scope.pm.projects && $scope.pm.projects.length) {
                        if ($stateParams.projectId || goToState !== 'product') {
                            // select the first one
                            $log.info('Redirecting to the first project');
                            $scope.data.filterCollapsed = false;
                            params.projectId = $scope.pm.projects[0].id;

                            if (await checkLicense()) {
                                return;
                            }

                            $state.go(goToState, params, { location: 'replace' });
                        } else {
                            // go to all projects
                            $state.go('products', params, { location: 'replace' });
                        }
                    } else {
                        $log.info('No projects found');
                        $state.go('loginCreate');
                    }
                });
            }
        }
    };

    $scope.addNewFunc = function () {
        if ($scope.data.newFuncName && $scope.data.newFuncName.length && $scope.pm.project) {
            const funcName = $scope.data.newFuncName.replace(new RegExp(' ', 'g'), '-');

            tonkeanService.getOrCreateFunctionByName($scope.pm.project.id, funcName).then(function () {
                $scope.pm.getFunctions(true).finally(function () {
                    $scope.data.showAddFunc = false;
                    // $scope.data.showFilter = false;
                    $scope.data.newFuncName = '';
                    // $state.go('product.board', {filter: data.name}, {location: 'replace'});
                });
            });
        }
    };

    $scope.navToFilter = function (filter, st, filterType) {
        // Clear all other filter types.
        for (const type in $scope.mobileFilters) {
            if ($scope.mobileFilters.hasOwnProperty(type) && type !== filterType) {
                $scope.mobileFilters[type] = null;
            }
        }

        // Navigate to the selected filter.
        $state.go('product.board', { filter, g: null, st });
    };

    $scope.onFilterClicked = function () {
        $scope.data.showFilter = false;
        $scope.filterVisiblity.showInnerBar = null;
        $rootScope.showProjectInnerFilter = null;
        $scope.mobileFilters = {};
    };

    $scope.toggleInnerBar = function (innerBarType) {
        $scope.data.showFilter = false;
        $scope.filterVisiblity.showInnerBar =
            $scope.filterVisiblity.showInnerBar === innerBarType ? null : innerBarType;

        $rootScope.showProjectInnerFilter = !!$scope.filterVisiblity.showInnerBar;
    };
    $scope.toggleGlobalFilter = function (newIsOpen) {
        $scope.data.showGlobalFilters = newIsOpen;
        $localStorage.showGlobalFilters = newIsOpen;

        // if we are closing the modal and there are filters active
        if (
            !newIsOpen &&
            ($scope.filterVisiblity.stateFilter ||
                ($scope.filterVisiblity.baseFilter !== 'all' && $scope.filterVisiblity.baseFilter !== 'my'))
        ) {
            $state.go('product.board', { filter: 'all', st: null, g: null });
        }
    };

    /**
     * Closes the filters inner bar
     *
     * if  any mobile filters are active also clears the filters
     */
    $scope.closeFilterInnerBar = function () {
        $scope.filterVisiblity.showInnerBar = null;

        // checks if any filters are active
        if ($scope.filterVisiblity.baseFilter !== 'all' || utils.existsInObj($scope.mobileFilters, (value) => value)) {
            $scope.onClearFilters();
        }
    };

    $scope.onClearFilters = function () {
        $scope.navToFilter('all');
        $scope.onFilterClicked();
    };

    $scope.onBotSidePaneOpenChanged = function (isOpen) {
        $scope.data.isBotSidePaneOpen = isOpen;
    };

    $scope.addProjectToEnterprise = function (event) {
        // Prevent the click of the menu item from happening.
        utils.eventStopAllPropagations(event);

        // Make sure we are in an enterprise user account.
        if ($scope.data.isEnterpriseUser) {
            $scope.addingProjectToEnterprise = true;

            enterpriseHelper
                .addProjectToEnterpriseById($scope.as.currentUser.enterprise.id, $scope.pm.project.id)
                .then(() => {
                    // Update the project data now that the project was added to the enterprise.
                    $scope.pm
                        .getProjectData(true)
                        .then(() => {
                            $rootScope.isMenuOpen = false;

                            $scope.$emit('alert', {
                                msg: `Successfully added board "${$scope.pm.project.name}" to the enterprise account.`,
                                type: 'success',
                            });
                        })
                        .finally(() => {
                            $scope.addingProjectToEnterprise = false;
                        });
                })
                .catch(() => {
                    $scope.addingProjectToEnterprise = false;
                    $rootScope.isMenuOpen = false;
                    $scope.$emit('alert', {
                        msg: `There was an error adding board${$scope.pm.project.name} to the enterprise account.`,
                        type: 'danger',
                    });
                });
        }
    };

    $scope.isMakerOfFolders = () => {
        return workflowFolderManager.getFoldersOfMaker(projectId, authenticationService.currentUser.id).length;
    };

    $scope.onSolutionBusinessReportCreated = (solutionBusinessReport) => {
        $timeout(() => {
            $state.go('product.solution-business-report', { solutionBusinessReportId: solutionBusinessReport.id });
            $scope.data.solutionBusinessReports.push(solutionBusinessReport);
        });
    };

    function isCurrentLocationIntake() {
        const intakeEndPoints = ['form', 'itemInterfaceIntake', 'itemInterface'];
        return $location.$$path.split('/').some((location) => intakeEndPoints.includes(location));
    }

    function loadProject(shouldFetchAllGroups) {
        // Show header.
        if ($rootScope.stateData && !$rootScope.stateData.hideHeader) {
            $rootScope.showHeader = true;
        }
        // Hide footer.
        if (!$rootScope.stateData.hideFooter && $rootScope.showFooter) {
            $rootScope.showFooter = !$rootScope.isMobile;
        }
        // Hide bottom mobile menu.
        if ($rootScope.isMobile && $rootScope.stateData && $rootScope.stateData.hideMobileBottomMenu) {
            $rootScope.hideMobileBottomMenu = true;
        }

        return $scope.pm
            .selectProject(projectId)
            .then(async function () {
                $log.debug(`Project loaded [${projectId}]`);

                $scope.pm.getProjectData(true);
                $rootScope.trackHelper.fillIdsStock(50, true);

                const fetchedGroups = shouldFetchAllGroups ? groupInfoManager.getGroups(true) : Promise.resolve();

                fetchedGroups.then(() => {
                    $scope.loadingPage.complete();
                });

                if (await checkLicense()) {
                    return;
                }

                const isMaker = $scope.isMakerOfFolders();
                window.Intercom('update', {
                    // eslint-disable-line new-cap
                    isMaker: isMaker > 0,
                });
            })
            .catch(function () {
                $log.warn('Failed to get a project by Id.');
                $scope.$emit('alert', { type: 'warning', msg: 'Board was not found' });
                $state.go('products');
            });
    }

    async function checkLicense() {
        // Check if the project is not expired and licensed and the user is NOT licensed.
        // or if limited license and not the project creator
        // If so, we only allow this user to update his initiatives.
        const projectId = $scope.pm.project.id;
        if ($state.current.name.includes('upgrade')) {
            return false;
        }

        if ($rootScope.lps.shouldGoToExpiredPage()) {
            $rootScope.showHeader = false;

            if (!$scope.pm.project.isExpired && !$scope.pm.project.isUserLicensed) {
                await licenseManager.grabLicense();
                return false;
            } else {
                $state.go('product.expired', { projectId }, { location: 'replace' });
                return true;
            }
        }

        if ($rootScope.lps.shouldGoToTracksBoxPage()) {
            // In the old pricing, unlicensed users go to tracks box
            if ($location.search().tid) {
                // go to update initiative
                $state.go(
                    'updateState',
                    {
                        projectId,
                        id: $location.search().tid,
                    },
                    { location: 'replace' },
                );
            } else {
                $state.go('updateState', { projectId }, { location: 'replace' });
            }
            return true;
        }

        return false;
    }

    function navigateToDefaultView() {
        const options = { location: 'replace' };
        const params = angular.copy($stateParams);

        if ($state.current.name === 'product' && $scope.pm.project.features?.tonkean_feature_homepage) {
            params.projectId = $scope.pm.project.id;
            params.projectUrlSlug = $scope.pm.project.urlSlug;
            $state.go('homepageView', params, options);
        } else if ($state.current.name === 'product' && (params.g || $scope.pm.hasLiveReports)) {
            // go to live reports

            $scope.data.filterCollapsed = false;
            params.projectId = $scope.pm.project.id;
            params.filter = 'all';
            params.listFilter = null;
            params.isGroupListFilter = null;
            $state.go('product.board', params, options);
        } else if ($state.current.name === 'product') {
            // go to worker

            $scope.data.filterCollapsed = false;
            params.projectId = $scope.pm.project.id;
            params.listFilter = null;
            params.isGroupListFilter = null;
            $state.go('product.workers', params, options);
        } else {
            $state.go($state.current.name, params, options);
        }
    }

    $scope.$on('$stateChangeSuccess', async function (e, toState) {
        if (
            $scope.currentState &&
            !toState.name.includes('updateState') &&
            $scope.currentState.includes('upgrade') &&
            $scope.currentState.includes('expired') &&
            (await checkLicense())
        ) {
            return;
        }

        // Once we have a project Id we should navigate to the default tab
        $scope.currentState = toState.name;

        if (toState.name === 'product' && $scope.pm.project) {
            navigateToDefaultView();
        }
    });

    $scope.$on('currentUserChanged', function () {
        if ($scope.as.currentUser && $scope.as.currentUser.isUser) {
            $scope.data.isEnterpriseUser = licensePermissionsService.isEnterpriseUser();
            if ($scope.data.isEnterpriseUser) {
                $scope.data.enterpriseName = $scope.as.currentUser.enterprise.name;
            }
        }
    });

    $scope.init();
}

export default angular.module('tonkean.app').controller('ProjectBoardCtrl', ProjectBoardCtrl);
