import React from 'react';
import styled from 'styled-components';

import { TonkeanAvatar } from '@tonkean/infrastructure';
import { TextEllipsis } from '@tonkean/infrastructure';
import type { Person } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

export enum VersionCommentAvatarSize {
    /** 24px X 24px */
    BIG = 'BIG',
    /** 20px X 20px */
    REGULAR = 'REGULAR',
    /** 18px X 18px */
    SMALL = 'SMALL',
}

const avatarSizeToConfig: Record<VersionCommentAvatarSize, { size: number; marginRight: number }> = {
    [VersionCommentAvatarSize.BIG]: {
        marginRight: 8,
        size: 24,
    },
    [VersionCommentAvatarSize.REGULAR]: {
        marginRight: 8,
        size: 20,
    },
    [VersionCommentAvatarSize.SMALL]: {
        marginRight: 5,
        size: 18,
    },
};

const Wrapper = styled.div`
    display: flex;
`;

const Comment = styled.div`
    align-self: center;
    flex-grow: 1;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${Theme.colors.gray_600};
`;

const Updater = styled.strong`
    font-weight: 400;
    color: ${Theme.colors.gray_800};
`;

const Avatar = styled.div<{ size: VersionCommentAvatarSize }>`
    position: relative;
    width: ${({ size }) => avatarSizeToConfig[size].size}px;
    height: ${({ size }) => avatarSizeToConfig[size].size}px;
    margin-right: ${({ size }) => avatarSizeToConfig[size].marginRight}px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;
`;

interface Props extends StyledComponentsSupportProps {
    avatarSize?: VersionCommentAvatarSize;
    /** The user who commented. */
    user: Person;
    /** How many lines to show before ellipsis (three dots)? If undefined, it won't have a limit. */
    linesLimit?: number;
    showAvatar?: boolean;
}

const VersionComment: React.FC<React.PropsWithChildren<Props>> = ({
    className,
    user,
    linesLimit,
    avatarSize = VersionCommentAvatarSize.SMALL,
    children,
    showAvatar = true,
}) => {
    return (
        <Wrapper className={className}>
            {showAvatar && (
                <Avatar size={avatarSize}>
                    <TonkeanAvatar owner={user} />
                </Avatar>
            )}
            <Comment>
                <TextEllipsis numberOfLines={linesLimit} disabled={linesLimit === undefined} tooltip>
                    <Updater>{user.firstName}: </Updater>
                    {children}
                </TextEllipsis>
            </Comment>
        </Wrapper>
    );
};

export default VersionComment;
