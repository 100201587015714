import { useMemo } from 'react';

import type BaseProjectIntegrationEntityProps from './BaseProjectIntegrationEntityProps';
import { ProjectIntegrationEntityTabKey } from './ProjectIntegrationEntityTabKey';
import type {
    SidePaneBlockConfiguration,
    SidePaneConfiguration,
} from '../../modules/GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';
import ProjectIntegrationEntityContinuousCollectPage from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntityContinuousCollectPage';
import ProjectIntegrationEntityEncryptionPage from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntityEncryption/ProjectIntegrationEntityEncryptionPage';
import ProjectIntegrationEntityEntityFetcherPage from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntityEntityFetcherPage';
import ProjectIntegrationEntityGeneralPage from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntityGeneralPage';
import ProjectIntegrationEntityHeaderComponent from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntityHeaderComponent';
import ProjectIntegrationEntityLiveStreamPage from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntityLiveStream/ProjectIntegrationEntityLiveStreamPage';
import ProjectIntegrationEntityMetadataConfigurePage from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntityMetadata/ProjectIntegrationEntityMetadataConfigurePage';
import ProjectIntegrationEntitySchemaPage from '../../modules/ProjectIntegrationEntityModule/components/ProjectIntegrationEntitySchemaPage';

const useProjectIntegrationEntityConfiguration = () => {
    return useMemo(() => {
        const settingBlock: SidePaneBlockConfiguration<BaseProjectIntegrationEntityProps> = {
            displayName: 'Settings',
            key: 'settings',
            subTabsConfigurations: [
                {
                    displayName: 'General',
                    key: ProjectIntegrationEntityTabKey.GENERAL,
                    component: ProjectIntegrationEntityGeneralPage,
                },
                {
                    displayName: 'Encryption',
                    key: ProjectIntegrationEntityTabKey.ENCRYPTION,
                    component: ProjectIntegrationEntityEncryptionPage,
                },
                {
                    displayName: 'Schema',
                    key: ProjectIntegrationEntityTabKey.SCHEMA,
                    component: ProjectIntegrationEntitySchemaPage,
                },
            ],
        };

        const input: SidePaneBlockConfiguration<BaseProjectIntegrationEntityProps> = {
            displayName: 'Input',
            key: 'input',
            subTabsConfigurations: [
                {
                    displayName: 'Collect',
                    key: ProjectIntegrationEntityTabKey.CONTINUOUS_COLLECT,
                    component: ProjectIntegrationEntityContinuousCollectPage,
                },

                {
                    displayName: 'Webhook',
                    key: ProjectIntegrationEntityTabKey.LIVE_STREAM,
                    component: ProjectIntegrationEntityLiveStreamPage,
                },

                {
                    displayName: 'Entity Fetcher',
                    key: ProjectIntegrationEntityTabKey.ENTITY_FETCHER,
                    component: ProjectIntegrationEntityEntityFetcherPage,
                },
                {
                    displayName: 'Field Metadata',
                    key: ProjectIntegrationEntityTabKey.FIELD_METADATA,
                    component: ProjectIntegrationEntityMetadataConfigurePage,
                },
            ],
        };

        const sidePaneConfiguration: SidePaneConfiguration<BaseProjectIntegrationEntityProps> = {
            sidePaneBlockConfiguration: [settingBlock, input],
            tabKeysToExcludeHeaderComponent: [
                ProjectIntegrationEntityTabKey.CONTINUOUS_COLLECT,
                ProjectIntegrationEntityTabKey.LIVE_STREAM,
                ProjectIntegrationEntityTabKey.SCHEMA,
                ProjectIntegrationEntityTabKey.FIELD_METADATA,
            ],
            headerComponent: ProjectIntegrationEntityHeaderComponent,
        };

        return sidePaneConfiguration;
    }, []);
};

export default useProjectIntegrationEntityConfiguration;
