import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import { SearchIcon } from './icons';
import { SearchAgainIcon } from './icons';

import { Field, IconSvg, Input, useFocus } from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

const InputWrapper = styled.div<{ $mobileView?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;

    ${({ $mobileView }) =>
        !$mobileView &&
        css`
            margin-bottom: 20px;
        `};

    ${({ $mobileView }) =>
        $mobileView &&
        css`
            margin: -20px -20px 20px -20px;
            border-bottom: 1px solid ${Theme.colors.gray_400};
            width: auto;
            max-width: unset;
        `};
`;
const StyledInput = styled(Input)<{ $mobileView?: boolean }>`
    position: relative;
    height: unset;
    min-height: 34px;
    padding: 14px 47px;

    border-radius: 50px;

    font-size: 15px;
    line-height: 16px;

    &:focus {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.14);
    }

    ${({ $mobileView }) =>
        $mobileView &&
        css`
            border-radius: 0;
            border: 1px solid transparent;
            padding: 13px 47px;
            font-size: 19px;
            line-height: 16px;

            &:focus {
                box-shadow: none;
            }
        `}
`;

const StyledSearchIcon = styled(SearchIcon)<{ $mobileView?: boolean }>`
    position: absolute;
    z-index: 1;
    left: 19px;

    ${({ $mobileView }) =>
        $mobileView &&
        css`
            left: 15px;
        `}
`;

const ResubmitButton = styled(IconButton)`
    position: absolute;
    right: 19px;

    cursor: pointer;

    line-height: 0;
`;

export interface SmartSearchFormProps {
    onSubmit: (search: string) => void;
    searchString?: string;
    searchRequired?: boolean;
    mobileView?: boolean;
}

const SmartSearchForm: React.FC<SmartSearchFormProps> = ({
    onSubmit,
    searchString,
    searchRequired = true,
    mobileView,
}) => {
    const SmartSearchValidationSchema = useMemo(() => {
        return Yup.object({
            search: searchRequired ? Yup.string().required() : Yup.string(),
        });
    }, [searchRequired]);

    const { inputRef } = useFocus();

    return (
        <Formik
            initialValues={{ search: searchString }}
            validationSchema={SmartSearchValidationSchema}
            onSubmit={({ search }) => {
                if (search) {
                    onSubmit(search);
                }
            }}
            enableReinitialize
        >
            {({ submitForm }) => (
                <Form>
                    <Field>
                        <InputWrapper $mobileView={mobileView}>
                            <StyledSearchIcon $mobileView={mobileView} />
                            <StyledInput name="search" ref={inputRef} $mobileView={mobileView} />

                            <Tooltip content="Try another search">
                                <ResubmitButton onClick={() => submitForm()} flat>
                                    <IconSvg as={SearchAgainIcon} size={16} />
                                </ResubmitButton>
                            </Tooltip>
                        </InputWrapper>
                    </Field>
                </Form>
            )}
        </Formik>
    );
};

export default SmartSearchForm;
