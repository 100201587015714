<div class="padding-normal">
    <div class="modal-header">
        <div class="flex-vmiddle">
            <div class="integration-icon mod-excel365"></div>
            <h4 class="flex-grow">Set Up Microsoft Excel Online</h4>
        </div>
    </div>
    <tnk-excel-sheet-selector
        project-integration="projectIntegration"
        on-project-integration-created="onExcelSheetSelected(projectIntegration, fieldDefinitionsToCreate)"
        on-cancel="onCancel()"
    ></tnk-excel-sheet-selector>
</div>
