import SolutionVersions from './SolutionVersions';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkSolutionVersions',
        reactToAngular(SolutionVersions, ['onClose', 'workflowFolder', 'onExitComplete']),
    );
