export const FORM_SECONDARY_COLORS = {
    default: '#3FA7B8', // (empty)
    yellow: '#f2d600',
    purple: '#c377e0',
    blue: '#0079bf',
    red: '#eb5a46',
    green: '#61bd4f',
    orange: '#ff9f1a',
    darkblue: '#355263',
    black: '#111111',
    sky: '#00c2e0',
    pink: '#ff78cb',
    lime: '#51e898',
};

export function getFormSecondaryColorOptions() {
    return structuredClone(FORM_SECONDARY_COLORS);
}
