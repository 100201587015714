<div class="tnk-manage-triggers common-close-btn-container">
    <!-- Close button -->
    <span
        class="common-close-btn tnk-manage-triggers-close-btn"
        ng-class="data.modSlim ? 'svg-icon-md ' : 'svg-icon-lg'"
        ng-if="!data.hideCloseBtn"
        ng-click="cancel()"
    >
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <div>
        <!-- Title & Smart triggers-->
        <div ng-class="{ 'flex-basis-45 margin-right-xlg': !data.modSlim }">
            <!-- Module live indication -->
            <div ng-if="!data.hideBotToggle" class="margin-xs-bottom">
                <tnk-bot-toggle
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    size="lg"
                    toggleDisabled="data.viewOnly"
                ></tnk-bot-toggle>
            </div>

            <!-- Error for toggling Module live -->
            <div ng-if="data.errorToggleBotLive" class="flex flex-self-end common-color-danger margin-bottom-lg">
                {{ data.errorToggleBotLive }}
            </div>

            <!-- Tonkean smart triggers info box -->
            <div class="flex mod-align-start">
                <span class="margin-right-xs svg-icon-sm flex-no-shrink">
                    <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                </span>
                <div class="manage-triggers-smart-triggers-box">
                    <div class="common-title-xxs">Tonkean Smart-Triggers™</div>
                    <div class="margin-top-xs margin-bottom-xlg">
                        <!-- Explanation -->
                        <div class="common-size-xxs">
                            Tonkean’s pre-built Smart-Triggers determine when the right time is to
                            <strong>
                                follow-up with item owners based on due dates, ETA’s, data anomaly detection
                            </strong>
                            and more.
                        </div>
                        <div class="margin-top-xs flex-vmiddle">
                            <!-- Envelope or communication integration icon -->
                            <i
                                ng-if="!cis.anyIntegrationsConnected()"
                                class="svg-icon-sm svg-icon-align-text-top margin-right-xs"
                            >
                                <tnk-icon src="/images/icons/envelope.svg"></tnk-icon>
                            </i>
                            <i
                                ng-if="cis.anyIntegrationsConnected()"
                                ng-init="data.communicationIntegration = cis.getFirstThatSupports(cis.features.notifications);"
                                class="initiative-integration-icon mod-sm mod-dropdown inline-block"
                                ng-class="
                                    data.communicationIntegration
                                        ? 'mod-' +
                                          data.communicationIntegration.integration.integrationType.toLowerCase()
                                        : 'mod-slack'
                                "
                            ></i>
                            <!-- Send a test/preview button -->
                            <a
                                class="common-link-blue common-bullet-separator"
                                ng-click="modalUtils.openTestBotModal()"
                                ng-disabled="data.viewOnly"
                            >
                                Send me a test
                            </a>
                            <!-- Learn more -->
                            <a
                                class="common-link-blue"
                                ng-click="modalUtils.openEducationItemModal(educationManager.getEducationItem(educationManager.educationItemType.bot), pm.groupsMap[data.groupId], data.workflowVersionId)"
                            >
                                Learn more
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- All triggers of all kinds -->
        <div class="flex-no-xs mod-align-start">
            <!-- Select a list-->
            <div ng-if="data.showListSelection" class="manage-triggers-group-selection margin-right-xlg-no-xs">
                <!-- Title (not mobile) -->
                <div class="common-bold hidden-xs">
                    <div class="common-bold">Your lists</div>
                    <hr class="common-separator mod-darker margin-top margin-bottom" />
                </div>
                <!-- Title (mobile)-->
                <div class="common-bold visible-xs-block">Select a list:</div>

                <!-- Group selection (not mobile) -->
                <div class="hidden-xs">
                    <div
                        ng-repeat="group in pm.groups"
                        ng-click="selectGroup(group)"
                        class="manage-triggers-group-option common-ellipsis"
                        ng-class="{ 'mod-selected': data.groupId === group.id }"
                    >
                        {{ group.name }}
                    </div>
                </div>
                <!-- Group selection (mobile) -->
                <ui-select
                    class="margin-top-xs visible-xs-block margin-bottom-xlg"
                    theme="bootstrap"
                    on-select="selectGroup(data.selectedGroup)"
                    ng-model="data.selectedGroup"
                >
                    <ui-select-match placeholder="Search list...">{{ data.selectedGroup.name }}</ui-select-match>
                    <ui-select-choices repeat="group in pm.groups | filter:$select.search">
                        <div ng-bind-html="group.name | escapeHtml | highlight: $select.search"></div>
                    </ui-select-choices>
                </ui-select>
            </div>

            <!-- Triggers with title container -->
            <div class="flex-grow">
                <!-- Selected list title -->
                <div ng-if="data.showListSelection" class="common-bold margin-bottom">
                    {{ data.selectedGroup.name }} Triggers
                </div>

                <!-- Triggers container -->
                <div class="flex-no-xs mod-align-start flex-wrap">
                    <!-- Multi column triggers -->
                    <div
                        class="triggers-container margin-bottom-xlg"
                        ng-class="{ 'margin-right-lg-no-xs': !data.modSlim }"
                    >
                        <!-- Header -->
                        <div class="flex-vmiddle">
                            <!-- Title -->
                            <div class="margin-right">Multi column Triggers</div>
                            <!-- Trigger count -->
                            <div class="common-color-grey" ng-if="!data.loadingCustomTriggers">
                                {{ data.customTriggers.length }}
                            </div>
                            <div class="flex-grow"></div>
                            <!-- Create new button -->
                            <a
                                class="common-link-blue common-bold flex-no-shrink"
                                ng-click="openCustomTriggerModal()"
                                ng-if="!data.viewOnly"
                            >
                                New
                            </a>
                        </div>

                        <!-- Separator -->
                        <hr class="common-separator mod-darker margin-top margin-bottom-md" />

                        <!-- Loading state -->
                        <div ng-if="data.loadingCustomTriggers" class="flex-vmiddle">
                            <i class="loading-small margin-right-xs"></i>
                            <span>Loading your triggers...</span>
                        </div>

                        <!-- Error state -->
                        <div
                            ng-if="!data.loadingCustomTriggers && data.customTriggersError"
                            class="common-color-danger"
                        >
                            There was an error trying to load custom triggers.
                            <a ng-click="loadCustomTriggers()">Retry</a>
                        </div>

                        <!-- Empty state -->
                        <div
                            ng-if="
                                !data.loadingCustomTriggers && !data.customTriggersError && !data.customTriggers.length
                            "
                        >
                            <div class="common-color-grey text-center margin-top-lg">
                                Create Triggers that work across columns
                            </div>
                            <div class="margin-top-md text-center">
                                <img
                                    src="/images/customTriggers/empty-custom-triggers.png"
                                    alt="Create multi column triggers"
                                    class="multi-column-triggers-empty-state-img"
                                />
                            </div>
                            <div class="margin-top-lg text-center">
                                <button
                                    type="button"
                                    ng-click="!data.viewOnly && openCustomTriggerModal()"
                                    ng-disabled="data.viewOnly"
                                    class="btn btn-primary margin-normal-sm common-bold"
                                >
                                    Create Trigger
                                </button>
                                <span class="common-color-grey">Or</span>
                                <a ng-click="openTriggersGalleryModal()" class="margin-normal-sm common-bold">
                                    Explore library
                                </a>
                            </div>
                        </div>

                        <!-- Items state -->
                        <div
                            ng-if="
                                !data.loadingCustomTriggers && !data.customTriggersError && data.customTriggers.length
                            "
                        >
                            <!-- Each item -->
                            <div
                                class="pointer margin-bottom"
                                ng-repeat="customTrigger in data.customTriggers"
                                ng-click="openCustomTriggerModal(customTrigger)"
                                ng-class="{ 'mod-disabled': customTrigger.disabled }"
                            >
                                <div class="flex mod-align-start single-trigger-box">
                                    <!-- Trigger info -->
                                    <div class="flex-grow margin-right-xs">
                                        <!-- Display name -->
                                        <div>{{ customTrigger.displayName }}</div>
                                        <!-- Description -->
                                        <div class="margin-top-xs common-color-dark-grey">
                                            {{ customTrigger.description }}
                                        </div>
                                        <!-- Rule count -->
                                        <div class="margin-top-xs common-color-grey">
                                            {{
                                                customTrigger.filtersCount > 1
                                                    ? customTrigger.filtersCount + ' rules'
                                                    : customTrigger.filtersCount === 1
                                                    ? '1 rule'
                                                    : 'No rules'
                                            }}
                                        </div>
                                    </div>
                                    <!-- Toggle -->
                                    <div class="flex-no-shrink flex-vmiddle">
                                        <!-- Loading toggling disabled on/off -->
                                        <div
                                            ng-if="customTrigger.loadingToggleDisabled"
                                            class="margin-right-xs flex-no-shrink"
                                        >
                                            <i class="loading-small"></i>
                                        </div>

                                        <!-- Disabled on/off -->
                                        <tnk-toggle
                                            class="flex-no-shrink"
                                            toggle-disabled="data.viewOnly"
                                            ng-show="
                                                wvm.getCachedWorkflowVersion(data.workflowVersionId)
                                                    .shouldSendGatherUpdates
                                            "
                                            is-active="!customTrigger.disabled"
                                            on-toggle-click="toggleCustomTriggerDisabled($event, data)"
                                            data="customTrigger"
                                            size="sm"
                                            on-label="('ON')"
                                            off-label="OFF"
                                        ></tnk-toggle>

                                        <!-- Error toggling disabled on/off -->
                                        <div
                                            ng-if="
                                                !customTrigger.loadingToggleDisabled &&
                                                customTrigger.errorToggleDisabled
                                            "
                                            class="common-color-danger common-size-xxxxs flex-no-shrink"
                                        >
                                            {{ customTrigger.errorToggleDisabled }}
                                        </div>
                                    </div>
                                    <!-- Integrations icons -->
                                    <!--<div class="margin-right">-->
                                    <!--<i ng-repeat="projectIntegration in customTrigger.projectIntegrationsInTriggerConditions"-->
                                    <!--uib-tooltip="{{projectIntegration.displayName}}"-->
                                    <!--class="initiative-integration-icon mod-circle mod-normal mod-external-item mod-border inline-block"-->
                                    <!--ng-class="!projectIntegration.iconUrl ? 'mod-'+projectIntegration.integration.integrationType.toLowerCase() : ''"-->
                                    <!--ng-style="{'background': projectIntegration.iconUrl ? 'url('+ projectIntegration.iconUrl +') no-repeat center' : null}"></i>-->
                                    <!--</div>-->
                                </div>
                                <!-- Separator -->
                                <hr class="common-separator mod-darker margin-top-none margin-bottom-none" />
                            </div>
                        </div>
                    </div>

                    <!-- Column specific / Single column triggers -->
                    <div class="triggers-container">
                        <!-- Header -->
                        <div class="flex-vmiddle">
                            <!-- Title -->
                            <div class="margin-right">Single column Triggers</div>
                            <!-- Trigger count -->
                            <div class="common-color-grey" ng-if="!data.loadingCustomTriggers">
                                {{
                                    (cfm.selectedFieldsMap[data.workflowVersionId] | filter: filterEmptyColumnTriggers)
                                        .length
                                }}
                            </div>
                            <div class="flex-grow"></div>
                            <!-- Create new button -->
                            <div uib-dropdown ng-if="!data.viewOnly" class="flex-no-shrink">
                                <a class="common-link-blue common-bold" uib-dropdown-toggle>New</a>
                                <ul uib-dropdown-menu class="dropdown-menu-right margin-bottom-lg">
                                    <li
                                        class="dropdown-header margin-left-xxs-minus"
                                        ng-if="
                                            cfm.selectedFieldsMap[data.workflowVersionId] &&
                                            cfm.selectedFieldsMap[data.workflowVersionId].length
                                        "
                                    >
                                        Existing columns
                                    </li>
                                    <li
                                        class="dropdown-header margin-left-xxs-minus"
                                        ng-if="
                                            !cfm.selectedFieldsMap[data.workflowVersionId] ||
                                            !cfm.selectedFieldsMap[data.workflowVersionId].length
                                        "
                                    >
                                        No existing columns
                                    </li>
                                    <li ng-repeat="field in cfm.selectedFieldsMap[data.workflowVersionId]">
                                        <a ng-click="openFieldDefinitionModal(field)" ng-disabled="data.viewOnly">
                                            {{ field.name }}
                                        </a>
                                    </li>
                                    <li
                                        ng-if="
                                            !cfm.selectedFieldsMap[data.workflowVersionId] ||
                                            !cfm.selectedFieldsMap[data.workflowVersionId].length
                                        "
                                        class="padding-normal-sm"
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-primary"
                                            ng-click="!data.viewOnly && $root.$broadcast('createNewField', [data.groupId, 'COLUMN', null, null, true, false, null, null, null, null, null, null, null, null, null, null, null, null, data.workflowVersionId])"
                                        >
                                            Create a new column
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- Separator -->
                        <hr class="common-separator mod-darker margin-top margin-bottom-md" />

                        <!-- Loading state -->
                        <div ng-if="data.loadingCustomTriggers" class="flex-vmiddle">
                            <i class="loading-small margin-right-xs"></i>
                            <span>Loading your triggers...</span>
                        </div>

                        <!-- Empty state -->
                        <div
                            ng-if="
                                !data.loadingCustomTriggers &&
                                (!cfm.selectedFieldsMap[data.workflowVersionId] ||
                                    !(cfm.selectedFieldsMap[data.workflowVersionId] | filter: filterEmptyColumnTriggers)
                                        .length)
                            "
                        >
                            <div class="common-color-grey text-center margin-top-lg">Add triggers to you columns</div>
                            <div class="margin-top-md text-center">
                                <img
                                    src="/images/customTriggers/empty-column-triggers.png"
                                    alt="Create single column triggers"
                                    class="multi-column-triggers-empty-state-img"
                                />
                            </div>
                            <div class="margin-top-lg text-center">
                                <div uib-dropdown class="flex-no-shrink">
                                    <button
                                        type="button"
                                        ng-disabled="data.viewOnly"
                                        uib-dropdown-toggle
                                        class="btn btn-primary common-bold"
                                    >
                                        Add trigger to Column
                                    </button>
                                    <ul uib-dropdown-menu class="dropdown-menu-right margin-bottom-lg">
                                        <li
                                            class="dropdown-header margin-left-xxs-minus"
                                            ng-if="
                                                cfm.selectedFieldsMap[data.workflowVersionId] &&
                                                cfm.selectedFieldsMap[data.workflowVersionId].length
                                            "
                                        >
                                            Existing columns
                                        </li>
                                        <li
                                            class="dropdown-header margin-left-xxs-minus"
                                            ng-if="
                                                !cfm.selectedFieldsMap[data.workflowVersionId] ||
                                                !cfm.selectedFieldsMap[data.workflowVersionId].length
                                            "
                                        >
                                            No existing columns
                                        </li>
                                        <li ng-repeat="field in cfm.selectedFieldsMap[data.workflowVersionId]">
                                            <a ng-click="openFieldDefinitionModal(field)" ng-disabled="data.viewOnly">
                                                {{ field.name }}
                                            </a>
                                        </li>
                                        <li
                                            ng-if="
                                                !cfm.selectedFieldsMap[data.workflowVersionId] ||
                                                !cfm.selectedFieldsMap[data.workflowVersionId].length
                                            "
                                            class="padding-normal-sm"
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-primary"
                                                ng-click="!data.viewOnly && $root.$broadcast('createNewField', [data.groupId, 'COLUMN', null, null, true, false, null, null, null, null, null, null, null, null, null, null, null, null, data.workflowVersionId])"
                                            >
                                                Create a new column
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!-- Items state -->
                        <div
                            ng-if="
                                !data.loadingCustomTriggers &&
                                cfm.selectedFieldsMap[data.workflowVersionId] &&
                                cfm.selectedFieldsMap[data.workflowVersionId].length
                            "
                        >
                            <!-- Each item -->
                            <div
                                ng-repeat="field in cfm.selectedFieldsMap[data.workflowVersionId] | filter:filterEmptyColumnTriggers"
                                ng-click="!data.viewOnly && openFieldDefinitionModal(field)"
                                class="pointer margin-bottom"
                            >
                                <div class="flex-vmiddle mod-justify-space single-column-box">
                                    <!-- Field display name & description -->
                                    <div class="margin-top-xs">
                                        <!-- Field display name -->
                                        <div class="common-bold">{{ field.name }}</div>
                                        <!-- Field description -->
                                        <div class="common-color-grey3">
                                            <!-- Rules length -->
                                            <span
                                                ng-if="field.ranges && field.ranges.length"
                                                class="common-bullet-separator"
                                            >
                                                {{ field.ranges.length
                                                }}{{
                                                    field.ranges.length === 1
                                                        ? ' rule'
                                                        : '
                                                rules'
                                                }}
                                            </span>

                                            <!-- Integration name or column type -->
                                            <span ng-if="field.type === 'MANUAL'">Manual</span>
                                            <span ng-if="field.type === 'TNK_COLUMN_FORMULA'">Formula</span>
                                            <span ng-if="field.projectIntegration">
                                                {{ field.projectIntegration.displayName }}
                                            </span>
                                        </div>
                                    </div>

                                    <!-- Field icon -->
                                    <div ng-if="field.projectIntegration" class="margin-right-xxs">
                                        <i
                                            uib-tooltip="{{ field.projectIntegration.displayName }}"
                                            class="
                                                initiative-integration-icon
                                                mod-circle mod-normal mod-border
                                                inline-block
                                            "
                                            ng-class="
                                                !field.projectIntegration.iconUrl
                                                    ? 'mod-' +
                                                      field.projectIntegration.integration.integrationType.toLowerCase()
                                                    : ''
                                            "
                                            ng-style="{
                                                background: field.projectIntegration.iconUrl
                                                    ? 'url(' + field.projectIntegration.iconUrl + ') no-repeat center'
                                                    : null
                                            }"
                                        ></i>
                                    </div>
                                    <div ng-if="!field.projectIntegration && field.type === 'MANUAL'">
                                        <span class="svg-icon-lg" uib-tooltip="Manual">
                                            <tnk-icon src="/images/icons/text-input-lg.svg"></tnk-icon>
                                        </span>
                                    </div>
                                    <div ng-if="!field.projectIntegration && field.type === 'TNK_COLUMN_FORMULA'">
                                        <span class="svg-icon-lg" uib-tooltip="Formula">
                                            <tnk-icon src="/images/icons/calculator-lg.svg"></tnk-icon>
                                        </span>
                                    </div>
                                </div>
                                <!-- Separator -->
                                <hr class="common-separator mod-darker margin-top-none margin-bottom-none" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
