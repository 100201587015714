<div>
    <div class="common-size-xxxxs common-font-smooth pointer">
        <!-- Hidden columns -->
        <div
            ng-if="
                customFields &&
                (customFields | filter: filterVisibleFieldDefinitions | filter: { systemUtilized: false }).length
            "
        >
            <div
                class="common-size-xxxxs padding-normal-sm flex-vmiddle pointer"
                ng-click="data.quickCreateExpended = (data.quickCreateExpended === 'HIDDENCOL' ? null : 'HIDDENCOL')"
            >
                <span style="width: 2em" class="svg-icon-sm">
                    <tnk-icon src="../../../../../../apps/tracks/images/icons/columns.svg"></tnk-icon>
                </span>
                <span class="flex-grow">Add Existing Field</span>
                <i
                    class="flex-no-shrink"
                    ng-class="data.quickCreateExpended === 'HIDDENCOL' ? 'chevron-bottom' : 'chevron-right'"
                ></i>
            </div>
            <hr class="margin-none" ng-show="data.quickCreateExpended === 'HIDDENCOL'" />
            <div
                class="quick-create-item-line flex-vmiddle"
                data-automation="quick-column-popover-select-new-field-{{fieldDef.name}}"
                ng-show="data.quickCreateExpended === 'HIDDENCOL'"
                ng-repeat="fieldDef in (customFields | filter: filterVisibleFieldDefinitions | filter:{systemUtilized: false} | limitTo:10) track by fieldDef.id"
                ng-click="updateColumnVisibility(fieldDef)"
            >
                <span style="width: 2em" class="text-center flex-no-shrink">-</span>
                <span>{{ fieldDef.name }}</span>
            </div>
            <div
                class="quick-create-item-line flex-vmiddle"
                ng-show="data.quickCreateExpended === 'HIDDENCOL'"
                ng-click="modal.openManageFieldsModal(groupId, workflowVersionId ,true, solutionBusinessReportId, environment)"
            >
                <span style="width: 2em" class="text-center"></span>
                <span data-automation="quick-column-popover-other-option">Other...</span>
            </div>
            <hr class="margin-none" />
        </div>

        <!-- Add linked column -->
        <div ng-if="data.anyOtherWorkflowVersionsWithFields">
            <div class="quick-create-item-line flex-vmiddle" ng-click="openAddLinkedColumnModal()">
                <span style="width: 2em" class="svg-icon common-fill-grey-text">
                    <tnk-icon src="../../../../../../apps/tracks/images/icons/link.svg"></tnk-icon>
                </span>
                <span>Add Linked Column</span>
            </div>
            <hr class="margin-none" />
        </div>

        <!-- Other -->
        <div
            ng-if="data.isDraft"
            class="quick-create-item-line padding-normal-sm"
            style="border-radius: 0 0 4px 4px"
            ng-click="onHeaderPlusClick()"
        >
            Add New Manual Field...
        </div>
    </div>
</div>
