import React, { useMemo } from 'react';

import { ReactComponent as ActionsEmptyStateIcon } from '../../../../../../../../../images/icons/actions-empty-state.svg';
import type AuditLogFilterSearchQuery from '../../../../entities/AuditLogFilterSearchQuery';

import { StateMessage } from '@tonkean/infrastructure';

interface Props {
    filterSearch: AuditLogFilterSearchQuery;
    emptyMessageSubtitle?: string;
}

const NoAuditLogsMessage: React.FC<Props> = ({
    filterSearch,
    emptyMessageSubtitle = 'Once changes are made you will be able to view them here',
}) => {
    const isFilterSearchActive = useMemo(() => {
        return !!Object.keys(filterSearch?.filters || {}).length || !!filterSearch?.search?.length;
    }, [filterSearch]);

    return (
        <StateMessage
            icon={
                <span className="tnk-icon">
                    <ActionsEmptyStateIcon />
                </span>
            }
            title={isFilterSearchActive ? 'No logs to show' : 'No logs were listed yet'}
        >
            {isFilterSearchActive ? 'Try changing or clearing the filters.' : emptyMessageSubtitle}
        </StateMessage>
    );
};

export default NoAuditLogsMessage;
