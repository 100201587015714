import template from './tnkDataRetentionConfiguration.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkDataRetentionConfiguration', {
    bindings: {
        projectIntegration: '<',
        setNativeEntitiesInboundConfiguration: '&',
    },
    template,
    controller: 'DataRetentionConfigurationCtrl',
});
