import React from 'react';
import styled, { css } from 'styled-components';

import CommentView from './CommentView';

import { Breakpoint } from '@tonkean/infrastructure';
import type { Person, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { Comment } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Container = styled.div<{ maxHeight?: number | undefined }>`
    overflow: auto;
    ${({ maxHeight }) =>
        maxHeight !== undefined &&
        css`
            max-height: ${maxHeight}px;
        `};
`;

const ItemContainer = styled.div`
    position: relative;
    padding: 0 16px 16px 0;
    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding-bottom: 8px;
    }
`;

const EventText = styled.span`
    color: ${Theme.colors.gray_700};
`;

const StyledDivVector = styled.div`
    position: absolute;
    left: -19px;
    top: 12px;
    height: calc(100% + 5px);
    width: 12px;

    border-left: 1px solid ${Theme.colors.gray_400};
    border-bottom: 1px solid ${Theme.colors.gray_400};
    border-bottom-left-radius: 6px 6px;
`;

interface CommentThreadProps {
    className?: string | undefined;
    comments: Comment[];
    currentUser: Person;
    maxHeight?: number | undefined;
    onCommentDelete?: (commentId: string) => void;
    targetId?: TonkeanId<TonkeanType.ACTIVITY>;
}

const CommentThread: React.FC<CommentThreadProps> = ({
    className,
    comments,
    currentUser,
    maxHeight,
    onCommentDelete,
    targetId,
}) => {
    return (
        <Container className={className} maxHeight={maxHeight} data-automation="comment-card">
            {comments.map((comment, index) => {
                const commenterIsCurrentUser = comment.commenter.id === currentUser.id;
                const userName = commenterIsCurrentUser ? 'You' : comment.commenter.name;
                const isNotLastComment = index < comments.length - 1;

                return (
                    <ItemContainer data-automation="comment-thread-container" key={comment.id}>
                        {isNotLastComment && <StyledDivVector />}
                        <CommentView
                            commentId={comment.id}
                            commentText={comment.text}
                            created={comment.created}
                            actor={comment.commenter}
                            eventText={<EventText>{userName}</EventText>}
                            onCommentDelete={commenterIsCurrentUser ? onCommentDelete : undefined}
                            targetId={targetId}
                        />
                    </ItemContainer>
                );
            })}
        </Container>
    );
};

export default CommentThread;
