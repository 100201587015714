import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from '../../../../../../../../../../images/icons/trash.svg';

import { Field, Input, TagsInput } from '@tonkean/infrastructure';
import type {
    BodyContainsConditions,
    ProjectIntegrationAction,
    ProjectIntegrationActionModuleResponseHandlingDefinition,
} from '@tonkean/tonkean-entities';
import { EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledTagsInput = styled(TagsInput)`
    margin-bottom: 20px;
`;

const StyledClickable = styled(Clickable)`
    color: ${Theme.colors.primary};
    font-size: ${FontSize.SMALL_12};

    &:disabled {
        background-color: ${Theme.colors.gray_500};
    }
`;

const Divider = styled.hr`
    width: 100%;
`;

const StyledIconButton = styled(IconButton)`
    margin-left: auto;
    margin-top: 15px;
`;

const FieldLabel = styled.div`
    color: ${Theme.colors.gray_700};
`;

interface BodyContainsConditionsForView extends BodyContainsConditions {
    isMock?: boolean;
}

interface Props {
    projectIntegrationAction: ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>;
    setProjectIntegrationAction: React.Dispatch<
        React.SetStateAction<ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>>
    >;
}

const FailureCriteriaHandleView: React.FC<Props> = ({ projectIntegrationAction, setProjectIntegrationAction }) => {
    // Creating new empty definition inside the action.
    const createNewActionWithFailureDefinition = (
        originalProjectIntegrationAction: ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition>,
    ): ProjectIntegrationAction<ProjectIntegrationActionModuleResponseHandlingDefinition> => {
        return {
            ...originalProjectIntegrationAction,
            responseHandlingDefinition: {
                ...originalProjectIntegrationAction.responseHandlingDefinition,
                responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                failureDefinition: { bodyContainsConditions: [{ textToContain: [], errorMessageToDisplay: '' }] },
            },
        };
    };

    // On changing handling keyword.
    const onChangeProjectIntegrationActionFailureKeywords = useCallback(
        (newKeywordsArray: string[], isMock: boolean, index: number) => {
            setProjectIntegrationAction((prevState) => {
                const updatedAction = !isMock ? { ...prevState } : createNewActionWithFailureDefinition(prevState);
                return {
                    ...updatedAction,
                    responseHandlingDefinition: {
                        responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                        ...updatedAction.responseHandlingDefinition,
                        failureDefinition: {
                            ...updatedAction.responseHandlingDefinition?.failureDefinition,
                            bodyContainsConditions:
                                updatedAction.responseHandlingDefinition?.failureDefinition?.bodyContainsConditions.map(
                                    (bodyContainsCondition, _index) =>
                                        _index === index
                                            ? { ...bodyContainsCondition, textToContain: newKeywordsArray }
                                            : { ...bodyContainsCondition },
                                ) || [],
                        },
                    },
                };
            });
        },
        [setProjectIntegrationAction],
    );

    // On changing the error message that will appear in the logs.
    const onChangeProjectIntegrationActionFailureMessage = useCallback(
        (newMessage: string, isMock: boolean, index: number) => {
            setProjectIntegrationAction((prevState) => {
                const updatedAction = !isMock ? { ...prevState } : createNewActionWithFailureDefinition(prevState);

                return {
                    ...updatedAction,
                    responseHandlingDefinition: {
                        responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                        ...updatedAction.responseHandlingDefinition,
                        failureDefinition: {
                            ...updatedAction.responseHandlingDefinition?.failureDefinition,
                            bodyContainsConditions:
                                updatedAction.responseHandlingDefinition?.failureDefinition?.bodyContainsConditions.map(
                                    (bodyContainsCondition, _index) =>
                                        _index === index
                                            ? { ...bodyContainsCondition, errorMessageToDisplay: newMessage }
                                            : { ...bodyContainsCondition },
                                ) || [],
                        },
                    },
                };
            });
        },
        [setProjectIntegrationAction],
    );

    // On clicking + add button we create new handle.
    const createNewFailureCondition = useCallback(() => {
        setProjectIntegrationAction((prevState) => {
            const emptyBodyContains = {
                textToContain: [],
                errorMessageToDisplay: '',
            };
            return {
                ...prevState,
                responseHandlingDefinition: {
                    responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                    ...prevState.responseHandlingDefinition,
                    failureDefinition: {
                        bodyContainsConditions: prevState.responseHandlingDefinition?.failureDefinition
                            ?.bodyContainsConditions
                            ? [
                                  ...prevState.responseHandlingDefinition?.failureDefinition?.bodyContainsConditions,
                                  emptyBodyContains,
                              ]
                            : [emptyBodyContains],
                    },
                },
            };
        });
    }, [setProjectIntegrationAction]);

    // When handling error we showing a mock because we dont want to add empty handle.
    const bodyContainsConditions: BodyContainsConditionsForView[] = useMemo(() => {
        const defaultCondition: BodyContainsConditionsForView = {
            textToContain: [],
            errorMessageToDisplay: '',
            isMock: true,
        };
        return (
            projectIntegrationAction.responseHandlingDefinition?.failureDefinition?.bodyContainsConditions || [
                defaultCondition,
            ]
        );
    }, [projectIntegrationAction.responseHandlingDefinition?.failureDefinition?.bodyContainsConditions]);

    const onClickDeleteCondition = (indexToRemove: number) => {
        setProjectIntegrationAction((prevState) => {
            const updatedBodyContainsConditions = [
                ...(prevState.responseHandlingDefinition?.failureDefinition?.bodyContainsConditions || []),
            ];
            updatedBodyContainsConditions.splice(indexToRemove, 1);

            return {
                ...prevState,
                responseHandlingDefinition: {
                    responseHandlingType: EntityResponseHandlingDefinitionType.MODULE_OUTPUT_FIELDS,
                    ...prevState.responseHandlingDefinition,
                    failureDefinition: {
                        ...prevState.responseHandlingDefinition?.failureDefinition,
                        bodyContainsConditions: updatedBodyContainsConditions,
                    },
                },
            };
        });
    };

    const shouldShowDivider =
        !!projectIntegrationAction.responseHandlingDefinition?.failureDefinition?.bodyContainsConditions.length;

    return (
        <>
            {bodyContainsConditions.map((bodyCondition, index) => (
                <div key={(!!bodyCondition.isMock).toString() + index}>
                    <Field
                        error={
                            !bodyCondition.isMock && bodyCondition.textToContain.length === 0
                                ? 'must contains at least 1 keyword'
                                : undefined
                        }
                        label={<FieldLabel>If response body contains:</FieldLabel>}
                        light
                    >
                        <StyledTagsInput
                            placeholder="Insert keywords"
                            value={bodyCondition.textToContain}
                            onChange={(newKeywords) => {
                                onChangeProjectIntegrationActionFailureKeywords(
                                    newKeywords,
                                    bodyCondition.isMock || false,
                                    index,
                                );
                            }}
                        />
                    </Field>

                    <Field
                        error={
                            !bodyCondition.isMock && !bodyCondition.errorMessageToDisplay
                                ? 'error message cant be empty'
                                : undefined
                        }
                        label={<FieldLabel>Provide the following message in the history page:</FieldLabel>}
                        light
                    >
                        <Input
                            size={InputSize.LARGE}
                            placeholder="Insert response"
                            value={bodyCondition.errorMessageToDisplay}
                            onChange={({ target: { value } }) => {
                                onChangeProjectIntegrationActionFailureMessage(
                                    value,
                                    bodyCondition.isMock || false,
                                    index,
                                );
                            }}
                        />
                    </Field>
                    {!bodyCondition.isMock && (
                        <StyledIconButton onClick={() => onClickDeleteCondition(index)} flat>
                            <TrashIcon />
                        </StyledIconButton>
                    )}

                    {/* Divider when we have more then one error*/}
                    {shouldShowDivider && <Divider />}
                </div>
            ))}

            <StyledClickable
                disabled={
                    !projectIntegrationAction.responseHandlingDefinition?.failureDefinition?.bodyContainsConditions
                }
                onClick={createNewFailureCondition}
            >
                + OR
            </StyledClickable>
        </>
    );
};

export default FailureCriteriaHandleView;
