import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import type { GlobalColor } from '@tonkean/tonkean-entities';

const useCustomColorListFromProjectThemConfiguration: () => GlobalColor[] = () => {
    const projectManager = useAngularService('projectManager');

    const projectThemeConfiguration = useMemo(() => {
        return projectManager.project.themeConfiguration;
    }, [projectManager.project.themeConfiguration]);

    return useMemo(() => {
        const primaryGlobalColor: GlobalColor = {
            label: 'Brand Color',
            id: 'primaryColor',
            color: projectThemeConfiguration?.primaryColor,
            isHidden: false,
        };

        return [primaryGlobalColor, ...(projectThemeConfiguration?.globalColors || [])];
    }, [projectThemeConfiguration]);
};

export default useCustomColorListFromProjectThemConfiguration;
