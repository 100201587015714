import React, { useContext } from 'react';

export interface InterfaceWidgetHoverContext {
    hoveredWidgetIcon?: JSX.Element;
    setIsUserHoveringWidgetInMenu(icon: JSX.Element | undefined): void;
}

const InterfaceWidgetHoverContext = React.createContext<InterfaceWidgetHoverContext | undefined>(undefined);

export const InterfaceWidgetHoverContextProvider = InterfaceWidgetHoverContext.Provider;

export default function useInterfaceWidgetHoverContext() {
    const contextValue = useContext(InterfaceWidgetHoverContext);

    if (!contextValue) {
        throw new Error('component must be wrapped with InterfaceWidgetHoverContext');
    }

    return contextValue;
}
