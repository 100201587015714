<div class="tnk-asana-custom-action-definition margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>
    <div ng-if="data.definition.customActionKey === 'UPLOAD_ATTACHMENT'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            preview-evaluation-source="data.previewEvaluationSource"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            parameters-values="data.definition.parametersValues"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>
    </div>

    <div ng-if="data.definition.customActionKey === 'DUPLICATE_TASK'" class="margin-bottom-xlg">
        <!-- Title -->
        <div class="margin-bottom-xs">New task name:</div>

        <div ng-if="data.validationObject.emptyTaskName" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.emptyTaskName }}
        </div>

        <!-- Tonkean custom expression input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Enter new task name here..."
            saved-original-expression="data.definition.duplicateTaskName"
            saved-evaluated-expression="data.definition.evaluatedDuplicateTaskName"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>
    </div>

    <!-- Duplicate Project -->
    <div ng-if="data.definition.customActionKey === 'DUPLICATE_PROJECT'" class="margin-bottom-xlg">
        <!-- Title -->
        <div class="margin-bottom-xs">New project name:</div>

        <div ng-if="data.validationObject.emptyProjectName" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.emptyProjectName }}
        </div>

        <!-- Tonkean custom expression input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Enter new project name here..."
            saved-original-expression="data.definition.newProjectNameExpression.originalExpression"
            saved-evaluated-expression="data.definition.newProjectNameExpression.evaluatedExpression"
            on-tonkean-expression-changed="
                onNewProjectNameExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>
    </div>
</div>
