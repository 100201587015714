import React, { useMemo } from 'react';

import { ItemInterfaceScrollContext } from '@tonkean/tonkean-entities';

interface Props {
    scrollingEventObject: Event | undefined;
}

const ItemInterfaceScrollContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
    scrollingEventObject,
    children,
}) => {
    const contextValue = useMemo(
        () => ({
            scrollingEventObject,
        }),
        [scrollingEventObject],
    );

    return <ItemInterfaceScrollContext.Provider value={contextValue}>{children}</ItemInterfaceScrollContext.Provider>;
};

export default ItemInterfaceScrollContextProvider;
