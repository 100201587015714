import { useCallback } from 'react';

import type NextStepSummary from '../entities/NextStepSummary';
import type SequenceSubmissionResponse from '../entities/SequenceSubmissionResponse';

const useNextStepSummary = (): ((itemInterfaceSubmissionResponse: SequenceSubmissionResponse) => NextStepSummary) => {
    return useCallback((itemInterfaceSubmissionResponse: SequenceSubmissionResponse) => {
        const answeredCustomTrigger = itemInterfaceSubmissionResponse?.formAnsweredCustomTrigger;
        const firstActionDefinition = answeredCustomTrigger?.customTriggerActions?.[0]?.customTriggerActionDefinition;

        const isWaitConditions = firstActionDefinition?.waitFormType === 'WAIT_CONDITIONS';
        const waitFormConditions = firstActionDefinition?.waitFormConditions;
        const evaluatedIntakeSequenceButtonLabel = itemInterfaceSubmissionResponse?.evaluatedIntakeSequenceButtonLabel;
        const showInterfaceInNextStep = firstActionDefinition?.isCustomInterfaceInSequence;

        const showFormInNextStep = firstActionDefinition?.formId;

        const goToSequenceTrigger = itemInterfaceSubmissionResponse?.goToSequenceTrigger;
        const firstActiongoToSequenceTrigger =
            goToSequenceTrigger?.customTriggerActions?.[0].customTriggerActionDefinition;
        const goToSequenceInterfaceId = firstActiongoToSequenceTrigger?.itemInterfaceId;

        return {
            isWaitConditions,
            waitFormConditions,
            showInterfaceInNextStep,
            showFormInNextStep,
            goToSequenceTriggerId: goToSequenceTrigger?.id,
            goToSequenceInterfaceId,
            isStepLastInSequence: itemInterfaceSubmissionResponse?.isLastInSequence,
            isNextStepThankYouPage: itemInterfaceSubmissionResponse?.isNextStepThankYouPage,
            firstActionDefinition,
            answeredCustomTriggerId: answeredCustomTrigger?.id,
            nextStepInitiative: itemInterfaceSubmissionResponse?.nextStepInitiative,
            nextStepWorkflowVersion: itemInterfaceSubmissionResponse?.nextStepWorkflowVersion,
            evaluatedIntakeSequenceButtonLabel,
        };
    }, []);
};

export default useNextStepSummary;
