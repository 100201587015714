import template from './tnkCommunicationProjectIntegrationSelector.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkCommunicationProjectIntegrationSelector', {
    bindings: {
        selectedCommunicationProjectIntegration: '<',
        onCommunicationProjectIntegrationChanged: '&',
        emailOption: '<',
        forceEmail: '<',
        disallowManageCommunicationSources: '<',

        automationIdentifier: '@',
    },
    template,
    controller: 'CommunicationProjectIntegrationSelectorCtrl',
});
