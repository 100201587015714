import getAsanaProjectAutoCompleteTemplate from './asanaProjectAutoCompleteTemplate';

export function AsanaModalCtrl(
    $scope,
    $rootScope,
    createProjectApis,
    oauthHandler,
    utils,
    integrations,
    authenticationService,
    environment,
    projectManager,
) {
    $scope.temp = {};
    $scope.loading = false;

    $scope.data = {
        includePrivateProjects: false,
    };

    let state;

    $scope.init = function (settings) {
        state = angular.copy(settings.state) || {};
        $scope.showRemove = !!settings.state;
        if (!$scope.$dismiss) {
            $scope.$dismiss = settings.onCancel;
        }

        $scope.integration = settings.integration || state.integration;

        if ($scope.integration) {
            updateAutoCompleteOptions();
        }
        $scope.orgs = state.orgs;
        $scope.selections = state.selections || [];

        $scope.onIntegrationClosed = settings.onIntegrationClosed;
        $scope.integrationConfigurationCanceled = settings.integrationConfigurationCanceled;

        updateOrganizationLists();
        tryLoadFromEdit();
    };

    $scope.authorize = function () {
        $scope.connecting = true;
        $scope.error = null;
        const promise = oauthHandler
            .asana(environment.integrationKeysMap.asana, environment.integrationKeysMap.asanaUri)
            .then(function (code) {
                return { code };
            });

        promise
            .then(function (config) {
                config.redirectUri = environment.integrationKeysMap.asanaUri;
                return createProjectApis.createIntegration(
                    projectManager.project.id,
                    $scope.currentIntegration.name,
                    config,
                    integrations.getIntegrationUniqueType(
                        authenticationService.currentUser.id,
                        'ASANA',
                        $scope.otherIntegrationOwner,
                    ),
                    undefined,
                );
            })
            .then(function (data) {
                $scope.integration = data;
                return updateAutoCompleteOptions();
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.connecting = false;
            });
    };

    $scope.changeUser = function () {
        $scope.integration = null;
        $scope.orgs = null;
        $scope.authorize();
    };

    $scope.onSelectionChange = function (selection) {
        if (!selection.selected) {
            selection.showEdit = false;
        }
    };

    function updateAutoCompleteOptions() {
        $scope.loading = true;

        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'workspaces')
            .then(function (data) {
                $scope.selections = data.options.slice(0, 3).map(function (org) {
                    return {
                        org,
                        monitorProjectsMode: 'TAKE_ALL',
                    };
                });
                $scope.orgs = data.options;
                updateOrganizationLists();
            })
            .catch(function (error) {
                $scope.error = error;
            })
            .finally(function () {
                $scope.loading = false;
            });
    }

    $scope.onBranchKeyUp = function ($event, selection) {
        // Escape key
        if ($event.keyCode === 27) {
            selection.showEdit = false;
        }
    };

    $scope.$watch('temp.org', function () {
        $scope.onOrganizationSelected($scope.temp.org);
    });

    $scope.onOrganizationSelected = function (org) {
        if (org && org.displayName) {
            const selection = {
                org,
                selected: true,
                monitorProjectsMode: 'TAKE_ALL',
            };
            $scope.selections.push(selection);
            updateOrganizationLists();
            $scope.onSelectionChange(selection);
            $scope.temp.org = null;
        }
    };

    $scope.queryProjects = function (selection, query) {
        return createProjectApis
            .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'projects', {
                workspaceId: selection.org.value,
                query,
            })
            .then((data) => {
                return data.options.map((option) =>
                    createProjectAutoCompleteObject(option.value, option.displayName, option.description === 'true'),
                );
            });
    };

    // Hack alert! Using ng-init and lambdas to make the react component pass an extra param to the callback that only exists in the angular DOM (ng-repeat item)
    // We cant direct the angularjs binding directly to the js because it somehow becomes an infinite loop, so we cache it in the ng-init
    $scope.getQueryProject = function (selection) {
        return (query) => $scope.queryProjects(selection, query);
    };
    $scope.getOnProjectsChangeCallback = function (selection) {
        return (projects) => {
            selection.projects = projects;
            selection.selected = true;
        };
    };

    $scope.setMonitorProjectsMode = function (selection, onClickParam) {
        selection.monitorProjectsMode = onClickParam;
    };

    /**
     * Refresh the repositories selection for each combo-box.
     */
    function updateOrganizationLists() {
        if ($scope.orgs) {
            const orgInSelection = new Set(
                $scope.selections.map(function (s) {
                    return s.org.displayName;
                }),
            );
            $scope.otherOrganizations = $scope.orgs.filter(function (r) {
                return !orgInSelection.has(r.displayName);
            });
        }
    }

    $scope.ok = function () {
        if (!$scope.data.integrationForm.$valid) {
            return;
        }

        const s = {
            orgs: $scope.orgs,
            selections: $scope.selections,
            integration: $scope.integration,
        };
        s.integrations = $scope.selections
            .map(function (selection) {
                if (selection.selected) {
                    return {
                        integration: $scope.integration,
                        projectData: {
                            workspace: selection.org.value,
                            includePrivateProjects:
                                selection?.monitorProjectsMode === 'FILTER' ? true : selection.includePrivateProjects,
                            projects:
                                selection?.monitorProjectsMode === 'FILTER'
                                    ? selection?.projects.map((project) => ({
                                          value: project.value,
                                          isPublic: project.isPublic,
                                          label: project.labelText,
                                      }))
                                    : [],
                        },
                    };
                }
            })
            .filter(angular.identity);

        if ($scope.onIntegrationClosed) {
            $scope.onIntegrationClosed(s);
        }
    };

    $scope.onCancel = function () {
        $scope.integrationConfigurationCanceled();
        $scope.$dismiss('cancel');
    };

    /**
     * This function is a callback for the react authentication component on when integration is created
     * @param integration - The created integration
     */
    $scope.onIntegrationCreate = function (integration) {
        $scope.integration = integration;

        // Because we're calling this function from a React component
        $scope.$evalAsync(async () => {
            if (!!integration) {
                $scope.connecting = true;
                const { options } = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    $scope.integration.id,
                    'workspaces',
                );
                $scope.selections = options.slice(0, 3).map(function (org) {
                    return {
                        org,
                        monitorProjectsMode: 'TAKE_ALL',
                    };
                });
                $scope.orgs = options;
                updateOrganizationLists();
                $scope.connecting = false;
            } else {
                $scope.orgs = [];
                $scope.selections = [];
                $scope.integration = null;
            }
        });
    };

    function createProjectAutoCompleteObject(value, label, isPublic) {
        return {
            value,
            label: getAsanaProjectAutoCompleteTemplate(label, isPublic),
            labelText: label,
            isPublic,
        };
    }

    function tryLoadFromEdit() {
        if (state.integrations?.[0]?.integration && !$scope.integration) {
            $scope.otherIntegrationOwner = utils.findFirst(state.integrations, function (i) {
                return $rootScope.currentUser && i.integration.user.id !== $rootScope.currentUser.id;
            });
            $scope.integration = state.integrations[0].integration;
            $scope.connecting = true;
            $scope.error = null;

            // Initializing include private projects from edit mode.
            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                const originalIntegration = state.integrations[0];

                if (
                    originalIntegration.projectData &&
                    originalIntegration.projectData.projectDatas &&
                    originalIntegration.projectData.projectDatas.length > 0
                ) {
                    for (const i in originalIntegration.projectData.projectDatas) {
                        const projectData = originalIntegration.projectData.projectDatas[i];

                        if (projectData.includePrivateProjects) {
                            $scope.data.includePrivateProjects = true;
                        }
                    }
                }
            }

            createProjectApis
                .getAutoCompleteOptions(projectManager.project.id, $scope.integration.id, 'workspaces')
                .then(function (data) {
                    $scope.orgs = data.options;
                    $scope.selections = data.options
                        .map(function (org) {
                            // There's only one project integration for eash integration type, so state.integrations[0] should exist.
                            if (state.integrations && state.integrations.length === 1 && state.integrations[0]) {
                                const originalIntegration = state.integrations[0];

                                if (
                                    originalIntegration.projectData &&
                                    originalIntegration.projectData.projectDatas &&
                                    originalIntegration.projectData.projectDatas.length > 0
                                ) {
                                    for (const i in originalIntegration.projectData.projectDatas) {
                                        const projectData = originalIntegration.projectData.projectDatas[i];

                                        if (projectData.workspace === org.value) {
                                            return {
                                                org,
                                                selected: true,
                                                projects: projectData?.projects?.map((project) =>
                                                    createProjectAutoCompleteObject(
                                                        project.value,
                                                        project.label,
                                                        project.isPublic,
                                                    ),
                                                ),
                                                monitorProjectsMode: projectData?.projects?.length
                                                    ? 'FILTER'
                                                    : 'TAKE_ALL',
                                                includePrivateProjects: projectData.includePrivateProjects,
                                            };
                                        }
                                    }
                                }
                            }
                        })
                        .filter(angular.identity);
                    updateOrganizationLists();
                })
                .catch(function (error) {
                    $scope.error = error;
                })
                .finally(function () {
                    $scope.connecting = false;
                });
        }
    }
}
export default angular.module('tonkean.shared').controller('AsanaModalCtrl', AsanaModalCtrl);
