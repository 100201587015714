import { useAngularService } from 'angulareact';
import dayjs from 'dayjs';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import BrokenDatasourceIndication from './BrokenDatasourceIndication';
import InaccessibleDatasourceIndication from './InaccessibleDatasourceIndication';
import InvalidLogicsValidationContext from '../../../ItemDetailsModuleEditorSidePane/InvalidLogicsValidationContext';
import WorkflowFolderProjectIntegrationsAccessContext from '../../../ItemDetailsModuleEditorSidePane/WorkflowFolderProjectIntegrationsAccessContext';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import { KebabMenuButton, LoadingCircle, Menu, MenuItem, SimpleErrorStateMessage } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { SyncConfig, TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationWorkflowFolderAccess } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 21px;
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 8px;
    padding: 12px;
`;

const StyledMenuItem = styled(MenuItem)`
    min-width: 110px;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const TopBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DatasourceIconContainer = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Theme.colors.tabsHover};
    border-radius: 8px;
`;

const PropTitle = styled.div`
    color: ${Theme.colors.gray_700};
`;

const PropValue = styled.div`
    font-weight: 500;
`;

interface Props {
    syncConfig: SyncConfig;
    workflowVersion: WorkflowVersion;
    groupId: TonkeanId<TonkeanType.GROUP>;
}

const ViewExistingDatasourceInputSource: React.FC<Props> = ({ syncConfig, workflowVersion, groupId }) => {
    const $timeout = useAngularService('$timeout');
    const $state = useAngularService('$state');
    const [menuOpen, setMenuOpen] = useState(false);
    const lastDataCollection = useMemo(() => {
        return syncConfig?.projectIntegration?.lastCollect
            ? dayjs(syncConfig.projectIntegration.lastCollect).format('DD/MM/YYYY hh:mm A')
            : 'Unavailable';
    }, [syncConfig.projectIntegration.lastCollect]);

    const syncConfigManager = useAngularService('syncConfigManager');
    const [{ loading: deleteSyncConfigLoading, error: deleteSyncConfigError }, removeGroupSyncConfig] =
        useLazyAsyncMethod(syncConfigManager, 'removeGroupSyncConfig');

    const workflowFolderProjectIntegrationsAccess = useContext<ProjectIntegrationWorkflowFolderAccess | undefined>(
        WorkflowFolderProjectIntegrationsAccessContext,
    );
    const { invalidLogics } = useContext(InvalidLogicsValidationContext);

    const projectIntegrationIsInaccessible = useMemo(() => {
        const inaccessibleProjectIntegration =
            workflowFolderProjectIntegrationsAccess?.inAccessibleProjectIntegrations?.includes(
                syncConfig.projectIntegration.id,
            );
        const errorInInvalidLogics = invalidLogics.DATASOURCE?.inAccessibleProjectIntegration;
        return inaccessibleProjectIntegration || errorInInvalidLogics;
    }, [
        syncConfig.projectIntegration.id,
        workflowFolderProjectIntegrationsAccess?.inAccessibleProjectIntegrations,
        invalidLogics,
    ]);

    if (deleteSyncConfigLoading) {
        return <LoadingCircle large centered />;
    }

    return (
        <Wrapper>
            {projectIntegrationIsInaccessible && (
                <InaccessibleDatasourceIndication
                    selectedProjectIntegrationId={syncConfig.projectIntegration.id}
                    hasError={!!invalidLogics.DATASOURCE?.inAccessibleProjectIntegration}
                />
            )}
            {!syncConfig.projectIntegration.valid && (
                <BrokenDatasourceIndication
                    workflowVersionId={syncConfig.workflowVersion.id}
                    projectIntegrationSummary={syncConfig.projectIntegration}
                />
            )}
            <CardContainer>
                <RowContainer>
                    <TopBar>
                        <DatasourceIconContainer>
                            <ProjectIntegrationIcon
                                width={25}
                                iconUrl={syncConfig.projectIntegration.iconUrl}
                                integrationType={syncConfig.projectIntegration.integration.integrationType}
                            />
                        </DatasourceIconContainer>

                        <Menu
                            show={menuOpen}
                            onClose={() => setMenuOpen(false)}
                            placement="bottom-end"
                            menuItems={
                                <>
                                    <StyledMenuItem
                                        data-automation="datasource-input-source-menu-edit"
                                        state="."
                                        params={{
                                            syncProjectIntegrationId: syncConfig.projectIntegration.id,
                                        }}
                                    >
                                        Edit
                                    </StyledMenuItem>
                                    <StyledMenuItem
                                        data-automation="datasource-input-source-menu-delete"
                                        state="."
                                        params={{
                                            syncProjectIntegrationId: undefined,
                                        }}
                                        onClick={() => removeGroupSyncConfig(groupId, syncConfig.id)}
                                    >
                                        Delete
                                    </StyledMenuItem>
                                </>
                            }
                        >
                            <KebabMenuButton
                                data-automation="datasource-input-source-menu-button"
                                onClick={() => setMenuOpen(true)}
                                pressed={menuOpen}
                                flat
                            />
                        </Menu>
                    </TopBar>
                    <PropValue>{syncConfig.projectIntegration.displayName}</PropValue>
                </RowContainer>

                <RowContainer>
                    <PropTitle>Entity</PropTitle>
                    <PropValue>
                        {syncConfig.viewData?.entityMetadata?.pluralLabel || syncConfig.viewData.entity} (
                        {syncConfig.viewData.query.filters?.length ? 'Filtered' : 'All'})
                    </PropValue>
                </RowContainer>

                <RowContainer>
                    <PropTitle>Last data collection</PropTitle>
                    <PropValue>{lastDataCollection}</PropValue>
                </RowContainer>

                {deleteSyncConfigError && <SimpleErrorStateMessage error={deleteSyncConfigError} showSmallError />}
            </CardContainer>
        </Wrapper>
    );
};

export default ViewExistingDatasourceInputSource;
