import AmazonS3AuthenticationComponent from './AmazonS3AuthenticationComponent';
import AmazonS3CustomizeSetupComponent from './AmazonS3CustomizeSetupComponent';
import amazonS3Image from '../../../../apps/tracks/images/amazon-s3.png';
import amazonS3CircleImage from '../../../../apps/tracks/images/integrations/amazon-s3-circle.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';
import { DataSourcesSectionType } from '@tonkean/tonkean-entities';

class AmazonS3Integration extends IntegrationDefinitionBase {
    override name = 'amazons3';
    override displayName = 'Amazon S3';
    override description = 'We will not collect any of your data.';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;
    override storageProviderSettings = new StorageProviderSettings(true, true);

    override entities = [new IntegrationEntity('File', 'Files')];

    override dataSourceSectionType = DataSourcesSectionType.DATABASE;

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(amazonS3CircleImage, '130px'),
        new IntegrationIcon(amazonS3Image, '130px'),
    );

    override customizedAuthenticationComponent = AmazonS3AuthenticationComponent;
    override customizedSetupComponent = AmazonS3CustomizeSetupComponent;
}

export default AmazonS3Integration;
