<div class="dropbox-selector">
    <!-- Saving to... -->
    <div class="text-center padding-top-xlg padding-bottom-xlg" ng-if="data.saving">
        <h4>
            <span class="loading"></span>
            Saving...
        </h4>
    </div>

    <form name="data.integrationForm" ng-hide="data.saving" novalidate ng-submit="onFolderSelected(data.currentFolder)">
        <div class="modal-body">
            <!-- Error -->
            <div class="alert alert-danger" ng-show="data.error">{{ data.error | error }}</div>

            <!-- Authenticate -->
            <div ng-if="(!data.integration || data.error) && !data.connecting" class="text-center">
                <button
                    type="button"
                    class="btn btn-secondary btn-lg"
                    analytics-on
                    analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                    analytics-event="Authenticate"
                    analytics-label="Dropbox"
                    ng-click="authorize()"
                >
                    Authenticate with Dropbox
                </button>
            </div>

            <!-- Connecting to... -->
            <h4 class="flex-vmiddle mod-justify-center" ng-if="data.connecting">
                <span class="loading"></span>
                <span class="margin-left-xs">Connecting to Dropbox</span>
            </h4>

            <div ng-if="data.integration && !data.connecting">
                <div class="alert alert-info flex-vmiddle">
                    <a
                        href="#"
                        analytics-on="click"
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Change Auth"
                        analytics-label="{{ currentIntegration.name }}"
                        ng-click="changeUser()"
                    >
                        Switch account
                    </a>

                    <div class="integration-group-header-connected" ng-if="integration">
                        Authenticated
                        <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                            as
                            <span class="integration-group-header-connected-username">
                                {{ data.integration.integrationUserDisplayName || data.integration.integrationUser }}
                            </span>
                        </span>
                    </div>
                </div>

                <div ng-if="data.configStep === 'selectFolder'">
                    <div class="flex-vmiddle margin-bottom">
                        <b>Select a Folder:</b>
                        <a ng-click="goToPreviousFolder()" ng-if="data.currentFolder" class="margin-left-xs">Back</a>

                        <div class="flex-grow"></div>

                        <div class="flex-no-shrink">
                            <label class="sr-only" for="folderSearch">Search</label>
                            <input
                                type="search"
                                class="form-control"
                                id="folderSearch"
                                placeholder="Search"
                                ng-change="onSearchChanged(data.folderSearch)"
                                ng-model="data.folderSearch"
                            />
                        </div>
                    </div>

                    <!-- Header that shows current folder name -->
                    <div class="integration-instance-selection-box-header common-bold">
                        {{ data.currentFolder ? data.currentFolder.displayName : 'My Drive' }}
                    </div>

                    <!-- Folders selection -->
                    <div class="integration-instance-selection-box">
                        <!-- Loading -->
                        <div ng-if="data.foldersLoading" class="text-center margin-top-lg">
                            <i class="loading-medium"></i>
                        </div>

                        <!-- Folders -->
                        <div ng-if="!data.foldersLoading" class="common-height-full">
                            <div ng-if="data.folders && data.folders.length">
                                <div
                                    ng-click="onOpenClicked(selection)"
                                    ng-repeat="selection in data.folders"
                                    class="list-item btn-default pointer common-size-xxs flex-vmiddle"
                                >
                                    <div class="flex-grow">
                                        <strong>{{ selection.displayName }}</strong>
                                    </div>
                                </div>
                            </div>

                            <!-- No inner folders -->
                            <div
                                class="flex-vmiddle mod-justify-center common-height-full"
                                ng-if="!data.folders || !data.folders.length"
                            >
                                <div class="text-center">
                                    <!-- Message -->
                                    <div class="common-color-grey common-size-xxxs">
                                        This folder has no inner folders
                                    </div>
                                    <!-- Use this folder -->
                                    <button
                                        class="btn btn-sm btn-primary margin-top-xs"
                                        ng-if="data.currentFolder.value"
                                        analytics-on
                                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                                        analytics-event="Save Integration"
                                        analytics-label="{{ currentIntegration.name }}"
                                        analytics-value="{{
                                            (data.selections | filter: { selected: true }:true).length
                                        }}"
                                        type="submit"
                                    >
                                        Use this Folder
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-vmiddle">
            <!-- Error -->
            <div class="common-color-danger" ng-show="data.integrationForm.$submitted && !data.integrationForm.$valid">
                You must choose a title and unique identifier
            </div>

            <!-- Placeholder -->
            <div class="flex-grow"></div>

            <!-- Cancel button -->
            <button
                class="btn btn-default margin-right-xs flex-no-shrink"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Cancel Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (data.selections | filter: { selected: true }:true).length }}"
                type="button"
                ng-click="cancel()"
            >
                Cancel
            </button>

            <!-- OK button -->
            <button
                class="btn btn-primary flex-no-shrink data-source-use-folder-button common-ellipsis"
                ng-if="data.integration && data.configStep === 'selectFolder' && data.currentFolder.value"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Save Integration"
                analytics-label="{{ currentIntegration.name }}"
                analytics-value="{{ (data.selections | filter: { selected: true }:true).length }}"
                uib-tooltip="{{ data.currentFolder ? data.currentFolder.displayName : 'Home' }}"
                tooltip-enable="utils.hasEllipsis('.dropbox-selector .data-source-use-folder-button')"
                type="submit"
            >
                Use this Folder ({{ data.currentFolder ? data.currentFolder.displayName : 'Home' }})
            </button>
        </div>
    </form>
</div>
