import React, { useCallback, useMemo } from 'react';
import type { StylesConfig } from 'react-select';

import AutocompleteSelector from './AutocompleteSelector';
import type { SimpleSelectSingleOption } from './SimpleSelectTypes';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    value: string | undefined;
    onChange: (value: { name: string }[]) => void;
    isDisabled?: boolean;
    bold?: boolean;
    styles?: StylesConfig<SimpleSelectSingleOption<string>>;
}

const TonkeanItemTagsSelector: React.FC<Props> = ({
    projectId,
    value,
    isDisabled,
    onChange,
    className,
    bold = false,
    styles,
}) => {
    const [, searchTags] = useLazyTonkeanService('searchTags');
    const getTagsAutocomplete = useCallback(
        (debounced: string, ignoreBusinessLabels: string[]) => {
            return searchTags(projectId, debounced, false).then((data) => {
                return data.tags;
            });
        },
        [projectId, searchTags],
    );

    const initialTags = useMemo(() => {
        return value
            ? value
                  .split(',')
                  .filter(Boolean)
                  .map((tag) => ({
                      name: tag,
                  }))
            : [];
    }, [value]);

    return (
        <AutocompleteSelector
            onDebouncedSearchGetResults={getTagsAutocomplete}
            value={initialTags}
            valueKey="name"
            labelKey="name"
            placeholder="Add tags"
            noOptionsMessage={() => null}
            isDisabled={isDisabled}
            onChange={onChange}
            className={className}
            classNamePrefix="tonkean-tags-selector"
            bold={bold}
            styles={styles}
            isMulti
            isCreatable
        />
    );
};

export default TonkeanItemTagsSelector;
