import React from 'react';
import styled from 'styled-components';

import { Cell, CellContent, Wrapper } from './DetailedItemsWidgetItemsListStyle';

import { Theme } from '@tonkean/tui-theme';

const Rectangle = styled.div<{ width: number; height: number }>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    background: ${Theme.colors.gray_300};
    border-radius: 2px;
`;

const SmallRectangle = <Rectangle width={55} height={14} />;
const MediumRectangle = <Rectangle width={100} height={16} />;
const LargeRectangle = <Rectangle width={105} height={14} />;

const NumCells = 7;

const DetailsItemsWidgetItemsListLoading = () => {
    return (
        <Wrapper>
            {Array.from({ length: NumCells }).map((_, i) => (
                <Cell key={i} $isSelected={false} $selectionColor="">
                    <CellContent>
                        {MediumRectangle}
                        {SmallRectangle}
                        {LargeRectangle}
                    </CellContent>
                </Cell>
            ))}
        </Wrapper>
    );
};

export default DetailsItemsWidgetItemsListLoading;
