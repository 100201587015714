<div class="padding-normal-xxs common-close-btn-container" ng-controller="InitiativesFilterPopoverCtrl">
    <!-- X Button -->
    <span
        class="common-close-btn padding-normal-sm padding-none margin-none"
        data-automation="initiatives-filter-popover-template-close-button"
        ng-click="closeInitiativesFilterPopover()"
    >
        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
    </span>

    <!-- Custom filters -->
    <tnk-custom-filters
        ng-if="groupId"
        control="initiativesFilterControl"
        items-source="COLUMN"
        group-id="groupId"
        workflow-version-id="workflowVersionId"
        hide-time-range-selection="true"
        hide-close-button="true"
        edit-mode="true"
        existing-definition="data.existingDefinition"
        on-filters-change="applyFilter()"
        special-fields-for-features="['INNER_LIST_FILTER']"
    ></tnk-custom-filters>

    <!-- Clear button -->
    <button
        type="button"
        class="btn btn-default btn-sm margin-top"
        ng-if="initiativesFilterControl.anyNonEmptyConditions()"
        ng-click="clearAdvancedFilter()"
    >
        Clear
    </button>
</div>
