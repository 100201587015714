function EntitiesExpirationConfigurationModalCtrl(
    $scope,
    $q,
    modal,
    utils,
    integrations,
    tonkeanService,
    projectIntegrationId,
    integrationName,
    integrationMetadataManager,
) {
    $scope.data = {
        /**
         * Holds a list of all configured entities in the data retention.
         */
        integrationConfiguredEntities: [],

        /**
         * A map between entity id to its editable state.
         * Since we want to allow the user to edit the data retention and have a Save/Cancel,
         * we save the edited state separately from the array of the data retention entities.
         * Using this way, we can just delete the entity once the user wants to cancel.
         */
        entityIdToEditableStateMap: {},

        isInitializeLoading: true,
        errorMessage: '',
        dataRetentionSettings: {},
        integrationName,
        projectIntegration: {},
    };

    /**
     * Initialization function for the modal.
     */
    $scope.init = function () {
        $scope.data.dataRetentionSettings = integrations.getDataRetentionSettingsByIntegration(integrationName);
        return loadEntitiesAndConfiguration();
    };

    $scope.init();

    /**
     * Add new blank entity to the list.
     */
    $scope.addEntity = function () {
        $scope.data.integrationConfiguredEntities.push({
            // The identifier of the entity.
            id: null,

            // How long ago we save the entity (in case it's not an indefinite entity). Defaults to 365.
            expirationDays: 365,

            // The display name of the entity.
            displayName: null,

            // Indicates whether the entity will be saved for eternity (== indefinite).
            isIndefinite: false,
        });
    };

    /**
     * Edit selected entity expiration days
     */
    $scope.editEntityExpirationDays = function (entity) {
        if (entity && entity.id) {
            // An entity is editable if the entire integration is canEditAll or the integration has editable entities and the given entity is one of them.
            const isEditableEntity =
                $scope.data.dataRetentionSettings.canEditAll ||
                ($scope.data.dataRetentionSettings.editableEntities &&
                    $scope.data.dataRetentionSettings.editableEntities[entity.id]);

            if (isEditableEntity) {
                $scope.data.entityIdToEditableStateMap[entity.id] = {
                    expirationDays: entity.expirationDays,
                    isIndefinite: entity.isIndefinite,
                };
            }
        }
    };

    /**
     * Delete the selected entity from the entity id to editable state map.
     */
    $scope.cancelEditMode = function (entity) {
        if (entity && entity.id) {
            delete $scope.data.entityIdToEditableStateMap[entity.id];
        }
    };

    /**
     * Checking whether entity is already selected.
     */
    $scope.isEntityAlreadySelected = function (selectedEntity) {
        return !!utils.findFirst(
            $scope.data.integrationConfiguredEntities,
            (configuredEntity) => configuredEntity.id && configuredEntity.id === selectedEntity.entity,
        );
    };

    /**
     * Save the selected entity expiration days.
     */
    $scope.saveEntityExpirationDays = function (index, entity) {
        // When the user try to insert negative number the expirationDays will be undefined because the model is not valid. In this case we set 0 as default.
        $scope.data.integrationConfiguredEntities[index].expirationDays =
            $scope.data.entityIdToEditableStateMap[entity.id].expirationDays || 0;
        $scope.data.integrationConfiguredEntities[index].isIndefinite =
            $scope.data.entityIdToEditableStateMap[entity.id].isIndefinite;
        // After the value has been changed we delete the entity from the map.
        delete $scope.data.entityIdToEditableStateMap[entity.id];
    };

    /**
     * Open a confirmation modal, whether the user is sure he wants to toggle data retention setting to indefinite.
     * If the user approves we update the entity is indefinite value.
     */
    $scope.openConfirmationModalAndToggleIsIndefinite = function (entity) {
        // When isIndefinite is true, the user tries to turn off the toggle.
        // Thus, no modal should be displayed and we just update value to FALSE.
        if ($scope.data.entityIdToEditableStateMap[entity.id].isIndefinite || entity.isIndefinite) {
            updateEntityIsIndefinite(entity);
            return $q.resolve();
        }

        const questionContent = 'Setting data collection to indefinite is not common. Are you sure?';

        return modal
            .openQuestionConfirmModal({
                controller: 'QuestionConfirmModalCtrl',
                windowClass: 'mod-primary',
                resolve: {
                    questionConfirmModalData() {
                        return {
                            title: 'Data Collection Settings',
                            body: questionContent,
                            okLabel: 'Approve',
                            cancelLabel: 'Cancel',
                        };
                    },
                },
            })
            .result.then(() => {
                updateEntityIsIndefinite(entity);
                return $q.resolve();
            })
            .catch(() => $q.reject());
    };

    /**
     * Closing the modal.
     */
    $scope.onCancel = function () {
        $scope.$dismiss('cancel');
    };

    /**
     * Saving the modal configuration and updating the project integration in the server.
     */
    $scope.saveModalConfiguration = async function () {
        try {
            const request = {
                entitiesConfiguration: {},
            };

            $scope.data.integrationConfiguredEntities
                .filter((entity) => entity && entity.id)
                .forEach((entity) => {
                    request.entitiesConfiguration[entity.id] = {
                        displayName: entity.displayName,
                        id: entity.id,
                        // If is indefinite set to true we will set expirationDays to null.
                        expirationDays: entity.isIndefinite ? null : entity.expirationDays,
                        // Expiration days and isIndefinite can't be defined in the same time.
                        // Thus, if is indefinite sets to false (means we have expiration days), we set the value to null.
                        isIndefinite: entity.isIndefinite || null,
                    };
                });

            await tonkeanService.updateProjectIntegrationEntitiesExpirationConfiguration(projectIntegrationId, request);
        } catch {
            $scope.data.errorMessage = 'Error occurred while saving configuration.';
            return;
        }

        $scope.$dismiss('cancel');
    };

    /**
     * Removing entity from the integration configured entities.
     */
    $scope.removeEntity = function (index) {
        const entityToDelete = $scope.data.integrationConfiguredEntities[index];

        // An entity can be removed if the entire integration has canAddOrRemoveAll, or the entity itself is a removable entity.
        const canEntityBeRemoved =
            $scope.data.dataRetentionSettings.canAddOrRemoveAll ||
            ($scope.data.dataRetentionSettings.removableEntities &&
                $scope.data.dataRetentionSettings.removableEntities[entityToDelete.id]);

        if (!entityToDelete.id || canEntityBeRemoved) {
            $scope.data.integrationConfiguredEntities.splice(index, 1);
        }
    };

    $scope.onEntitySelected = function (selectedEntity, index) {
        $scope.data.integrationConfiguredEntities[index].id = selectedEntity.entity;
        $scope.data.integrationConfiguredEntities[index].displayName = selectedEntity.label;
    };

    /**
     * This function loads all of the available integration entities and the project integration data retention settings.
     * @returns {Promise<void>}
     */
    async function loadEntitiesAndConfiguration() {
        try {
            // Fetching the project integration in order to pass it to the tnk-entity-selector component.
            $scope.data.projectIntegration = await tonkeanService.getProjectIntegrationById(projectIntegrationId);

            // Loading the integration entities for the project integration we're working on.
            // Since we're having multiple entity selectors, having this call prior to the initialization
            // of the entity selector is helpful as it saves us from loading the entities multiple times.
            await integrationMetadataManager.getIntegrationEntities(projectIntegrationId);

            // Extracting the configured data retention for the given project integration.
            $scope.data.integrationConfiguredEntities = await getDataRetentionConfiguration(projectIntegrationId);

            // If integration data retention configuration contains ignore entities array.
            if ($scope.data.dataRetentionSettings.ignoreEntities) {
                // Filtering out entities that we don't want to display to the user.
                $scope.data.integrationConfiguredEntities = $scope.data.integrationConfiguredEntities.filter(
                    (entity) => !$scope.data.dataRetentionSettings.ignoreEntities.includes(entity.id),
                );
            }

            // UI indication: Whether the list will be empty or not (Info message will be displayed if true)
            $scope.data.isConfigurationIsEmpty = $scope.data.integrationConfiguredEntities.length === 0;
        } finally {
            $scope.data.isInitializeLoading = false;
        }
    }

    /**
     * The function extracts the data retention configuration from the project integration.
     * If the configuration is not exists, we fetch it from the server.
     * Eventually, we structure the object to fit the UI model.
     */
    async function getDataRetentionConfiguration(projectIntegrationId) {
        const { dataRetentionConfiguration } = await tonkeanService.getProjectIntegrationDataRetentionConfiguration(
            projectIntegrationId,
        );
        const projectIntegrationDataRetentionSettings = dataRetentionConfiguration.entitiesConfiguration;

        return Object.keys(dataRetentionConfiguration.entitiesConfiguration).map((key) => {
            return {
                displayName: projectIntegrationDataRetentionSettings[key].displayName,
                id: projectIntegrationDataRetentionSettings[key].id,
                isIndefinite: projectIntegrationDataRetentionSettings[key].isIndefinite,
                expirationDays: projectIntegrationDataRetentionSettings[key].expirationDays,
            };
        });
    }

    /**
     * Updates the is indefinite value in the entityIdToEditableStateMap for the given entity id.
     */
    function updateEntityIsIndefinite(entity) {
        if (entity.id) {
            $scope.data.entityIdToEditableStateMap[entity.id].isIndefinite =
                !$scope.data.entityIdToEditableStateMap[entity.id].isIndefinite;

            if (
                !$scope.data.entityIdToEditableStateMap[entity.id].isIndefinite &&
                $scope.data.entityIdToEditableStateMap[entity.id].expirationDays === null
            ) {
                $scope.data.entityIdToEditableStateMap[entity.id].expirationDays = 365;
            }
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('EntitiesExpirationConfigurationModalCtrl', EntitiesExpirationConfigurationModalCtrl);
