import type { InferType } from 'yup';
import type { AnyObject, ObjectShape, TypeOfShape } from 'yup/lib/object';
import type ObjectSchema from 'yup/lib/object';
import type { Maybe } from 'yup/lib/types';

type SameKeyAndValue<T> = {
    [K in keyof T]: K;
};

/**
 * A util function that returns an object with it's keys and values being the fields in the schema.
 *
 * @example
 * const processContributorsSchema = yup.object({
 *   processContributorsSource: yupEnum(ProjectUsersSource).required(),
 *   sourceChangeBehavior: yupEnum(ProjectSourceChangeBehavior).required(),
 *   communicationSources: yup.array(yup.string().required()).required(),
 *   domains: yup.array().of(yup.string().required()).required(),
 *   allowCreateProcessContributors: yup.boolean().required(),
 * });
 * const processContributorsFormFields = getFieldsFromYup(processContributorsSchema);
 *
 * processContributorsFormFields === {
 *   processContributorsSource: "processContributorsSource",
 *   sourceChangeBehavior: "sourceChangeBehavior",
 *   communicationSources: "communicationSources",
 *   domains: "domains",
 *   allowCreateProcessContributors: "allowCreateProcessContributors",
 * }
 */
function getFieldsFromYup<
    TShape extends ObjectShape,
    TContext extends AnyObject = AnyObject,
    TIn extends Maybe<TypeOfShape<TShape>> = TypeOfShape<TShape>,
>(schema: ObjectSchema<TShape, TContext, TIn>) {
    return Object.fromEntries(Object.keys(schema.fields).map((key) => [key, key])) as SameKeyAndValue<
        InferType<typeof schema>
    >;
}

export default getFieldsFromYup;
