import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Paragraph } from '@tonkean/infrastructure';
import { TonkeanAvatar } from '@tonkean/infrastructure';
import { TonkeanLogo } from '@tonkean/svg';
import type { Person } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const Container = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        'avatar eventText actions'
        'avatar dateText actions';
    column-gap: 8px;
    align-items: center;
`;

const UserAvatar = styled.span`
    grid-area: avatar;
    width: 28px;
    height: 28px;
    position: relative;
`;

const EventText = styled(Paragraph).attrs({
    $inline: true,
    $small: false,
})`
    grid-area: eventText;
    color: ${Theme.colors.gray_800};
`;

const TonkeanLogoStyled = styled(TonkeanLogo)`
    grid-area: avatar;
    width: 28px;
    height: 28px;
`;

const DateText = styled.span`
    grid-area: dateText;
    line-height: 14px;
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
`;

const ActionsWrapper = styled.div`
    grid-area: actions;
    display: flex;
    gap: 5px;
    align-items: center;
`;

interface EventTitleProps {
    actor: Person;
    date: number;
    eventText: string | JSX.Element;
    actions?: JSX.Element | null;
    activatedByTonkean?: boolean;
    onBehalfOfFullName?: string;
}

const EventTitle: React.FC<EventTitleProps> = ({
    actions,
    actor,
    eventText,
    date,
    activatedByTonkean = false,
    onBehalfOfFullName,
}) => {
    const dateText = useMemo(() => utils.formatRelativeTimeFromNow(date), [date]);

    return (
        <Container>
            {activatedByTonkean ? (
                <TonkeanLogoStyled />
            ) : (
                <UserAvatar>
                    <TonkeanAvatar owner={actor} onBehalfOfFullName={onBehalfOfFullName} />
                </UserAvatar>
            )}
            <EventText>{eventText}</EventText>
            <DateText>{dateText}</DateText>
            {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
        </Container>
    );
};

export default EventTitle;
