import React from 'react';
import styled from 'styled-components';

import TimelineEvent from './TimelineEvent';

import { Breakpoint } from '@tonkean/infrastructure';
import type {
    Person,
    Activity,
    NewActivityData,
    TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';

const DateDivider = styled.div`
    padding: 10px 0;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;

    color: ${Theme.colors.gray_600};

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        padding: 14px 0;
    }
`;

interface Props {
    currentUser: Person;
    date: string;
    events: Activity[];
    onCommentAdded: (newActivity: NewActivityData) => void;
    defaultShowAddCommentForm?: boolean;
    threadMaxHeight?: number;
    onCommentDelete: (commentId: string, targetId?: string) => void;
    isLastGroup: boolean;
    canUserEditItem: boolean;
    initiativeId?: TonkeanId<TonkeanType.INITIATIVE>;
    workflowVersionType?: WorkflowVersionType;
}

const TimelineDateGroup: React.VFC<Props> = ({ date, events, isLastGroup, ...otherTimelineEventProps }) => {
    return (
        <>
            <DateDivider>{date}</DateDivider>
            {events.map((event, index) => (
                <TimelineEvent
                    key={event.id}
                    event={event}
                    isLastEvent={isLastGroup && index === events.length - 1}
                    {...otherTimelineEventProps}
                />
            ))}
        </>
    );
};

export default TimelineDateGroup;
