import type { RegexBaseItem } from './RegexBaseItem';

import type { EvaluatedRegexExpressionGroup } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

/**
 * A group from the evaluated input and regex
 */
export class RegexMatchedGroupPart implements EvaluatedRegexExpressionGroup, RegexBaseItem {
    public index: string;
    public color: string;

    constructor(
        public name: string,
        public value: string,
        public startIndex: number,
        public endIndex: number,
        public matchIndex: number,
        public groupIndex: number | undefined,
    ) {
        this.index = `${matchIndex}:${groupIndex}`;
        this.color = this.getColor();
    }

    /**
     * Get a color for the group from the array
     */
    private getColor(): RegexMatchedGroupPart['color'] {
        if (this.groupIndex === undefined) {
            return 'transparent';
        }

        return (
            Theme.current.palette.regex.groups[this.groupIndex % Theme.current.palette.regex.groups.length] ||
            'transparent'
        );
    }

    getTooltipLabel() {
        return {
            label: `Match ${this.matchIndex}`,
            subLabel: this.name.replaceAll(/\.$/g, ''),
        };
    }
}
