import { Formik } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import EditableTitle from '../../../components/common/EditableTitle/EditableTitle';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Field, FormikAutosave, Placeholder } from '@tonkean/infrastructure';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const StyledTitle = styled(EditableTitle)`
    width: 100%;
    padding: 30px 40px;
    font-size: 24px;
    border: none;

    &:focus,
    &:active {
        border: none;
    }
`;

const NameEditorWrapper = styled.div`
    width: 100%;
`;

const StyledPlaceHolder = styled(Placeholder)`
    margin-left: 20px;
`;

const ProcessNameSchema = yup.object({
    title: yup.string(),
});

interface Props {
    workflowFolderId: TonkeanId<TonkeanType.WORKFLOW_FOLDER>;
    currentName?: string;
    loading: boolean;
    onChange: (name: string) => void;
}

const ProcessBuilderNameEditor: React.FC<Props> = ({ workflowFolderId, currentName, loading, onChange }) => {
    const [{ loading: updateLoading, error: updateError }, updateProcessBuilder] =
        useLazyTonkeanService('updateProcessBuilder');

    const onSubmit = useCallback(
        async (name: string | undefined) => {
            if (!!name && !loading) {
                onChange(name);
                await updateProcessBuilder(workflowFolderId, name);
            }
        },
        [loading, onChange, updateProcessBuilder, workflowFolderId],
    );

    return (
        <NameEditorWrapper>
            {loading ? (
                <StyledPlaceHolder $height="36px" $width="570px" $borderRadius="0" />
            ) : (
                <Formik
                    validationSchema={ProcessNameSchema}
                    initialValues={{ title: currentName }}
                    onSubmit={(values) => onSubmit(values.title)}
                    enableReinitialize
                >
                    <Field>
                        <StyledTitle name="title" placeholder="Process name" />
                        <FormikAutosave />
                    </Field>
                </Formik>
            )}
        </NameEditorWrapper>
    );
};

export default ProcessBuilderNameEditor;
