import React from 'react';

import GenericIntegrationConnectionComponent from '../../../../../shared/components/tnkIntegrationGroup/genericIntegration/GenericIntegrationConnectionComponent';

import { Modal, ModalBody, ModalHeader, ModalSize } from '@tonkean/infrastructure';
import type { Integration } from '@tonkean/tonkean-entities';

interface Props {
    isModalOpen: boolean;
    onClose(): void;
    integrationType: string;
    onIntegrationCreate: (integration: Integration) => void;
}

const PredefinedConnectionAuthenticationModal: React.FC<Props> = ({
    isModalOpen,
    onClose,
    integrationType,
    onIntegrationCreate,
}) => {
    return (
        <Modal height="450" size={ModalSize.MEDIUM} open={isModalOpen} onClose={onClose} fixedWidth>
            <ModalHeader>Set Up Connection</ModalHeader>

            <ModalBody>
                <GenericIntegrationConnectionComponent
                    integrationType={integrationType}
                    onIntegrationCreate={onIntegrationCreate}
                />
            </ModalBody>
        </Modal>
    );
};

export default PredefinedConnectionAuthenticationModal;
