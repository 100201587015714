import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import SolutionVersionsSingleGroupRow from './SolutionVersionsSingleGroupRow';
import { SequentialIdentifier } from './SolutionVersionsVersionContainer';
import VersionComment, { VersionCommentAvatarSize } from '../../../infrastructure/components/VersionComment';

import { Popover, TextEllipsis } from '@tonkean/infrastructure';
import type { WorkflowFolderVersionGroupSummary } from '@tonkean/tonkean-entities';
import { TonkeanType } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const GroupName = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-height: 16px;
    font-weight: 500;
    color: ${Theme.colors.gray_800};
    border-bottom: 1px solid ${Theme.colors.gray_300};

    overflow-wrap: anywhere;
    padding-bottom: 8px;
    margin-bottom: 10px;
    width: 290px;
`;
const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;
const ChangesCount = styled.div`
    font-weight: 500;
    font-size: ${FontSize.XSMALL_10};
    line-height: 15px;
    color: ${Theme.colors.gray_500};
`;
const ChangesCountCircle = styled.div`
    display: inline-block;
    height: 15px;
    min-width: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: 100%;
    padding: 0 3px;

    font-weight: bold;
    font-size: ${FontSize.XSMALL_10};
    background: ${Theme.colors.gray_300};
    color: ${Theme.colors.gray_700};
`;
const WorkflowVersionHistoryLink = styled(StateLink)`
    font-size: ${FontSize.XSMALL_10};
    line-height: 12px;
    text-decoration-line: underline;
    &,
    &:hover,
    &:focus {
        color: ${Theme.colors.primary};
    }
`;

const PopoverTrigger = styled.button`
    display: block;
    background: transparent;
    border: none;
    padding: 0;
    border: 0;
    text-align: left;
    text-decoration: underline;
`;

interface Props {
    summary: WorkflowFolderVersionGroupSummary;
}

const SolutionVersionsSingleGroup: React.FC<Props> = ({ summary }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const workflowVersionHistoryParams = useMemo(() => {
        if (!summary.entityId) {
            return {};
        }

        return {
            g: summary.entityId,
            historyVersion:
                summary.entityType === TonkeanType.GROUP ? summary.workflowVersionId : summary.entityVersionId,
            t: 'versions',
        };
    }, [summary.entityId, summary.entityType, summary.entityVersionId, summary.workflowVersionId]);

    const isDeleted = !summary.entityId;

    return (
        <SolutionVersionsSingleGroupRow entityType={summary.entityType} hadChanged>
            {isDeleted ? (
                <div className="common-no-text-decoration">
                    Deleted module <SequentialIdentifier>({summary.workflowVersionId})</SequentialIdentifier>
                </div>
            ) : (
                <Popover
                    content={
                        <>
                            <GroupName>
                                {summary.entityName}{' '}
                                <SequentialIdentifier>(V{summary.sequentialIdentifier})</SequentialIdentifier>
                            </GroupName>

                            <InfoRow>
                                <ChangesCount>
                                    Published Changes <ChangesCountCircle>{summary.changesCount}</ChangesCountCircle>
                                </ChangesCount>
                                {summary.entityType === TonkeanType.GROUP ? (
                                    <WorkflowVersionHistoryLink
                                        state="product.workerEditor"
                                        params={workflowVersionHistoryParams}
                                        openInNewTab
                                    >
                                        Module Version History
                                    </WorkflowVersionHistoryLink>
                                ) : (
                                    <></>
                                )}
                            </InfoRow>

                            {summary.publishApprover && (
                                <VersionComment
                                    user={summary.publishApprover}
                                    avatarSize={VersionCommentAvatarSize.BIG}
                                >
                                    {summary.comment}
                                </VersionComment>
                            )}
                        </>
                    }
                    show={popoverOpen}
                    onClose={() => setPopoverOpen(false)}
                >
                    <PopoverTrigger onClick={() => setPopoverOpen(true)}>
                        <TextEllipsis numberOfLines={2}>
                            {summary.entityName}{' '}
                            <SequentialIdentifier>(V{summary.sequentialIdentifier})</SequentialIdentifier>
                        </TextEllipsis>
                    </PopoverTrigger>
                </Popover>
            )}
        </SolutionVersionsSingleGroupRow>
    );
};

export default SolutionVersionsSingleGroup;
