import { useMemo } from 'react';

import { type ItemInterface, PluggableActionType } from '@tonkean/tonkean-entities';

const usePluggableActionsToShowSettings = (itemInterface: ItemInterface | undefined): PluggableActionType[] => {
    return useMemo(() => {
        const pluggableActionTypeList: PluggableActionType[] = [];

        if (itemInterface) {
            if (itemInterface.configuration?.showCollaborationPopoverConcierge) {
                pluggableActionTypeList.push(PluggableActionType.CONCIERGE);
            }
            if (itemInterface.configuration?.showCollaborationPopoverComments) {
                pluggableActionTypeList.push(PluggableActionType.COLLABORATION);
            }
        }

        return pluggableActionTypeList;
    }, [itemInterface]);
};

export default usePluggableActionsToShowSettings;
