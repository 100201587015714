import type { FormEvent } from 'react';
import React, { useState } from 'react';

import CoupaAuthType, { authTypeToDisplayName } from './CoupaAuthType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { Radio, RadioGroup } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const CoupaAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [authType, setAuthType] = useState<CoupaAuthType>(CoupaAuthType.OAUTH);
    const [instanceUrl, setInstanceUrl] = useState<string | undefined>();
    const [apiKey, setApiKey] = useState<string | undefined>();
    const [clientId, setClientId] = useState<string | undefined>();
    const [clientSecret, setClientSecret] = useState<string | undefined>();
    const [scopes, setScopes] = useState<string | undefined>();

    const startAuthentication = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const projectIntegrationData = {
                authType,
                instanceUrl,
                scopes,
            };
            const credentials = {
                apiKey,
                clientId,
                clientSecret,
            };
            const config = {
                ...projectIntegrationData,
                ...credentials,
            };
            authenticate(config, projectIntegrationData, integrationConfiguration.displayName);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div className="flex flex-col form-horizontal">
            <div className="flex flex-col api-token-authentication">
                <div className="form-group">
                    <label className="col-sm-4 control-label">Authentication Type</label>
                    <div className="col-sm-8 padding-top-xs">
                        <RadioGroup value={authType} direction="row" onChange={setAuthType} size={InputSize.MEDIUM}>
                            <Radio value={CoupaAuthType.API_KEY}>{authTypeToDisplayName[CoupaAuthType.API_KEY]}</Radio>
                            <Radio value={CoupaAuthType.OAUTH}>{authTypeToDisplayName[CoupaAuthType.OAUTH]}</Radio>
                        </RadioGroup>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="api-instance-url" className="col-sm-3 control-label">
                        Instance URL
                    </label>
                    <div className="col-sm-8">
                        <div className="flex-vmiddle">
                            <div>https://</div>
                            <div className="flex-grow">
                                <input
                                    type="text"
                                    value={instanceUrl}
                                    className="form-control"
                                    id="api-instance-url"
                                    onChange={({ target }) => setInstanceUrl(target.value)}
                                    autoComplete="off"
                                    placeholder="<instance name>.<coupa host>"
                                    required
                                />
                            </div>
                            <div>.com/</div>
                        </div>
                        <span className="common-size-xxxs common-color-grey">
                            https://[&lt;instance name&gt;.&lt;coupa host&gt;].com
                        </span>
                    </div>
                </div>

                {authType === CoupaAuthType.API_KEY && (
                    <div className="form-group">
                        <label htmlFor="api-key" className="col-sm-3 control-label">
                            API Key
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={apiKey}
                                className="form-control"
                                id="api-key"
                                onChange={({ target }) => setApiKey(target.value)}
                                autoComplete="off"
                                placeholder="<api key>"
                                required
                            />
                            <span className="common-size-xxxs common-color-grey">
                                Navigate to Setup &gt; API Keys in your Coupa instance.
                            </span>
                        </div>
                    </div>
                )}

                {authType === CoupaAuthType.OAUTH && (
                    <div className="flex flex-col">
                        <div className="margin-bottom-lg info-box common-size-xxs">
                            <p className="common-color-dark-grey">OAuth Setup Information:</p>
                            <div className="common-color-dark-grey margin-top-lg">
                                <ul className="common-ul-big-bullets mod-grey margin-top-xs padding-left-xs">
                                    <li>
                                        Navigate to Setup &gt; Oauth2/OpenID Connect Clients in your Coupa instance
                                        (/oauth2/clients).
                                    </li>
                                    <li>Click Create and select Client Credentials in the Grant type dropdown</li>
                                    <li>
                                        Complete the fields and select the scopes (access permissions) the Client needs.
                                        <br />
                                        Note that if you want to give a Client access to all API endpoints and
                                        operations, you will need to add all scopes to that Client.
                                    </li>
                                    <li>
                                        Once you save the Client, you will get the Client credentials: Identifier,
                                        Secret, Oidc Scopes.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="api-client-id" className="col-sm-3 control-label">
                                Client ID
                            </label>
                            <div className="col-sm-8">
                                <input
                                    value={clientId}
                                    className="form-control"
                                    onChange={({ target }) => setClientId(target.value)}
                                    id="api-client-id"
                                    autoComplete="off"
                                    placeholder="<client id>"
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="api-client-secret" className="col-sm-3 control-label">
                                Client Secret
                            </label>
                            <div className="col-sm-8">
                                <input
                                    type="password"
                                    value={clientSecret}
                                    className="form-control"
                                    onChange={({ target }) => setClientSecret(target.value)}
                                    id="api-client-secret"
                                    autoComplete="off"
                                    placeholder="<client secret>"
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="api-scopes" className="col-sm-3 control-label">
                                Scopes (Separated by space)
                            </label>
                            <div className="col-sm-8">
                                <textarea
                                    value={scopes}
                                    id="api-scopes"
                                    onChange={({ target }) => setScopes(target.value)}
                                    className="form-control common-resize-y-only"
                                    autoComplete="off"
                                    placeholder="<scopes>"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Connect button*/}
                <div className="flex mod-center margin-top-20">
                    <button className="btn btn-secondary btn-lg" onClick={startAuthentication} disabled={!instanceUrl}>
                        Authenticate with {integrationConfiguration.displayName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CoupaAuthenticationComponent;
