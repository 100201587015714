import { type FieldDefinition, FieldType } from '@tonkean/tonkean-entities';

const equals = (value1: any, value2: any, fieldDefinition: FieldDefinition) => {
    if (fieldDefinition.fieldType === FieldType.Date && value1 && value2) {
        return new Date(value1).toString() === new Date(value2).toString();
    }

    if ((value1 === '' && value2 === undefined) || (value1 === undefined && value2 === '')) {
        return true;
    }

    // because the server return the value as string!
    // eslint-disable-next-line eqeqeq
    return value1 == value2;
};

export default equals;
