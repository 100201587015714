import SplitAndTakeIndexFunction from '../functions/SplitAndTakeIndexFunction';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

export default class FirstNameFunction extends SplitAndTakeIndexFunction {
    override readonly displayName = 'First name';
    override readonly description = 'Will split the given name by " " and return the first name.';
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Full name',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Split By',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: ' ',
            defaultTab: FormulaPopoverTab.CONST,
        },
        {
            displayName: 'Take Index',
            dataType: FieldType.Number,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultValue: 0,
            defaultTab: FormulaPopoverTab.CONST,
        },
    ];
}
