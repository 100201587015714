import { Formik } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    Field,
    FormikHelpers,
    H3,
    InformationTooltip,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    Textarea,
} from '@tonkean/infrastructure';
import type { ProcessMapper, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import type { WorkflowFolder } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { InformationIconSize } from '@tonkean/tui-theme/sizes';

const Subtitle = styled.div`
    margin-top: 8px;
    font-size: ${FontSize.SMALL_12};
    line-height: 16px;
    color: ${Theme.colors.gray_700};
`;

const StyledField = styled(Field)`
    margin-top: 33px;
`;

const FieldLabel = styled.div`
    display: flex;
    align-items: center;
`;

const StyledInformationTooltip = styled(InformationTooltip)`
    margin-left: 4px;
`;

const OptionalTag = styled.i`
    color: ${Theme.colors.gray_500};
    font-weight: 400;
    margin-left: 4px;
`;

const StyledModalFooter = styled(ModalFooter)`
    padding: 2px 6px;
`;

const DISPLAY_NAME_MAX_LENGTH = 255;
const DESCRIPTION_MAX_LENGTH = 2000;
const ValidationSchema = Yup.object({
    displayName: Yup.string()
        .required('A name is required')
        .max(
            DISPLAY_NAME_MAX_LENGTH,
            `Process map name is too long. Reduce the name to ${DISPLAY_NAME_MAX_LENGTH} characters or less`,
        ),
    groupId: Yup.string().required('A module is required'),
    description: Yup.string().max(
        DESCRIPTION_MAX_LENGTH,
        `Description text is too long. Reduce the description to ${DESCRIPTION_MAX_LENGTH} characters or less`,
    ),
});
type FormElements = Yup.InferType<typeof ValidationSchema>;
interface Props {
    open: boolean;
    onClose(createdProcessMapper?: ProcessMapper, processMapperWasCreated?: boolean): void;
    workflowFolder: WorkflowFolder;
    existingProcessMapper?: ProcessMapper;
    groupId: TonkeanId<TonkeanType.GROUP>;
}

const CreateOrEditProcessMapperModal: React.FC<Props> = ({
    open,
    onClose,
    workflowFolder,
    existingProcessMapper,
    groupId,
}) => {
    const [{ loading: createLoading, error: createError }, createProcessMapper] =
        useLazyTonkeanService('createProcessMapper');

    const [{ loading: updateLoading, error: updateError }, updateProcessMapperById] =
        useLazyTonkeanService('updateProcessMapperById');

    const loading = createLoading || updateLoading;
    const error = createError || updateError;

    const onSubmit = useCallback(
        (values: FormElements) => {
            const promise = existingProcessMapper
                ? updateProcessMapperById(existingProcessMapper.id, values.displayName, values.description ?? '')
                : createProcessMapper(
                      workflowFolder.project.id,
                      workflowFolder.id,
                      values.groupId,
                      values.displayName,
                      values.description,
                  );
            return promise.then((processMapper) => {
                onClose(processMapper, Boolean(!existingProcessMapper));
            });
        },
        [
            existingProcessMapper,
            updateProcessMapperById,
            createProcessMapper,
            workflowFolder.project.id,
            workflowFolder.id,
            onClose,
        ],
    );

    return (
        <Modal open={open} onClose={onClose} size={550}>
            <Formik
                initialValues={{
                    displayName: existingProcessMapper?.displayName || '',
                    groupId: existingProcessMapper?.group?.id || groupId,
                    description: existingProcessMapper?.description || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={onSubmit}
            >
                <FormikHelpers>
                    <ModalForm>
                        <ModalHeader>
                            <H3>
                                {existingProcessMapper ? `Edit '${existingProcessMapper.displayName}'` : 'Create New'}{' '}
                                Process Map
                            </H3>
                            <Subtitle>
                                Create a process map that describes each step of the business process and its current
                                status.
                            </Subtitle>
                        </ModalHeader>
                        <ModalBody>
                            <Field
                                label={
                                    <FieldLabel>
                                        Name
                                        <StyledInformationTooltip
                                            iconSize={InformationIconSize.SMALL}
                                            placement="top-start"
                                            tooltipLimitWidth={25}
                                            textAlignLeft
                                        >
                                            The name is used as the default title for the process map and may be visible
                                            to users
                                        </StyledInformationTooltip>
                                    </FieldLabel>
                                }
                            >
                                <Input
                                    type="text"
                                    placeholder="Type process map name"
                                    autoComplete="off"
                                    name="displayName"
                                    data-automation="create-or-edit-process-mapper-modal-name-input"
                                />
                            </Field>
                            <StyledField
                                label={
                                    <FieldLabel>
                                        Description <OptionalTag>(Optional)</OptionalTag>{' '}
                                        <StyledInformationTooltip
                                            iconSize={InformationIconSize.SMALL}
                                            placement="top-start"
                                            tooltipLimitWidth={25}
                                            textAlignLeft
                                        >
                                            The description can be used to understand the purpose of the process map.
                                        </StyledInformationTooltip>
                                    </FieldLabel>
                                }
                            >
                                <Textarea
                                    placeholder="Describe your process map"
                                    autoComplete="off"
                                    name="description"
                                    minRows={3}
                                    data-automation="create-or-edit-process-mapper-modal-description-textarea"
                                />
                            </StyledField>
                        </ModalBody>

                        <StyledModalFooter align="right" border>
                            <ModalFooterActions
                                saveDisabled={loading}
                                saveLabel={existingProcessMapper ? 'Update' : 'Create'}
                                error={error}
                            />
                        </StyledModalFooter>
                    </ModalForm>
                </FormikHelpers>
            </Formik>
        </Modal>
    );
};

export default CreateOrEditProcessMapperModal;
