import { useAngularService } from 'angulareact';
import { useField, useFormikContext } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import EnterpriseComponentAuthorizationOAuth2AccountCredentials from './EnterpriseComponentAuthorizationOAuth2AccountCredentials';
import EnterpriseComponentAuthorizationOAuth2AuthorizationCode from './EnterpriseComponentAuthorizationOAuth2AuthorizationCode';
import EnterpriseComponentAuthorizationOAuth2ClientCredentials from './EnterpriseComponentAuthorizationOAuth2ClientCredentials';
import { EnterpriseComponentAuthorizationParam } from './EnterpriseComponentAuthorizationParam';
import EnterpriseComponentRequestInterceptorData from './EnterpriseComponentRequestInterceptorData';
import EnterpriseComponentVariableValueAssignmentResponseHandleResponseView from './EnterpriseComponentVariableValueAssignmentResponseHandleResponseView';
import useCustomRequestAdditionalTab from '../../../HttpRequestConfiguration/utils/useCustomRequestAdditionalTab';

import { useFeatureFlag, useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { TonkeanExpressionProps } from '@tonkean/angular-to-react-components';
import { FormikTonkeanExpression } from '@tonkean/angular-to-react-components';
import { H2, LoadingCircle, SimpleSelect, Spacer } from '@tonkean/infrastructure';
import type {
    EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition,
    OAuth2AccountCredential,
    OAuth2AuthorizationCode,
    OAuth2ClientCredentials,
    OAuth2Custom,
    ProjectIntegration,
    TonkeanId,
    TonkeanType,
} from '@tonkean/tonkean-entities';
import {
    ActionDefinitionType,
    ActionType,
    EntityResponseHandlingDefinitionType,
    HttpMethodType,
    HttpRequestBodyType,
    OAuth2GrantType,
} from '@tonkean/tonkean-entities';
import { ClickableButton } from '@tonkean/tui-buttons/Button';

const CustomAuthLoading = styled(LoadingCircle)`
    margin-left: 5px;
`;

const StyledSimpleSelect = styled(SimpleSelect)`
    width: 200px;
`;

interface Props {
    projectIntegration: ProjectIntegration;
    expressionProps: Partial<TonkeanExpressionProps>;
}

const EnterpriseComponentAuthorizationOAuth2: React.FC<Props> = ({ projectIntegration, expressionProps }) => {
    const noCodeRefreshTokenExpirationFF = useFeatureFlag('tonkean_feature_no_code_refresh_token_only_when_expired');
    const oauthHandler = useAngularService('oauthHandler');
    const $state = useAngularService('$state');
    const tab = useCustomRequestAdditionalTab(ActionDefinitionType.HTTP, []);

    const formikContext = useFormikContext<
        OAuth2AuthorizationCode | OAuth2AccountCredential | OAuth2ClientCredentials | OAuth2Custom
    >();

    const [{ value: refreshActionId }, , { setValue: setRefreshActionId }] = useField<
        TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION> | undefined
    >('refreshActionId');

    const [{ value: customAuthActionId }, , { setValue: setAuthActionId }] = useField<
        TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION> | undefined
    >('customAuthenticationActionId');

    const [loadingCustomAuthAction, setLoadingCustomAuthAction] = useState(false);
    const [, exchangeCodeFromToken] = useLazyTonkeanService('exchangeCodeFromToken');
    const [, evaluateCodeRequest] = useLazyTonkeanService('evaluateCodeRequest');
    const [, oauthInitRequest] = useLazyTonkeanService('oauthInitRequest');
    const [, createProjectIntegrationAction] = useLazyTonkeanService('createProjectIntegrationAction');

    const authenticate = async () => {
        // get security state

        switch (formikContext.values.grantType) {
            case OAuth2GrantType.AUTHORIZATION_CODE:
                const state = oauthHandler.publicGetState();
                const { evaluatedCodeRequestUrl } = await evaluateCodeRequest(
                    projectIntegration.id,
                    formikContext.values,
                    state,
                );

                const code: any = await oauthHandler.startOAuth(evaluatedCodeRequestUrl, state);
                await exchangeCodeFromToken(projectIntegration.id, formikContext.values, code);
                break;
            case OAuth2GrantType.ACCOUNT_CREDENTIALS:
            case OAuth2GrantType.CLIENT_CREDENTIALS:
            case OAuth2GrantType.CUSTOM:
                await oauthInitRequest(projectIntegration.id, formikContext.values);
                break;
        }
    };

    const createDefaultProjectIntegrationAction = useCallback(
        (displayName: string, actionType: ActionType) => {
            return createProjectIntegrationAction(
                projectIntegration.id,
                displayName,
                '',
                {
                    definitionType: ActionDefinitionType.HTTP,
                    url: {
                        originalExpression: '',
                        evaluatedExpression: '',
                        isStripHtmlDisabled: true,
                    },
                    methodType: HttpMethodType.POST,
                    body: {
                        originalExpression: '',
                        evaluatedExpression: '',
                        isStripHtmlDisabled: true,
                    },
                    headers: [],
                    contentType: 'application/x-www-form-urlencoded',
                    disableAutoCharset: false,
                    disableFollowRedirects: false,
                    ignoreUntrustedCertificates: false,
                    removeKeysWithEmptyValueFromBody: false,
                    shouldIncludeFileDownloading: false,
                    retryOnStatusCodes: [],
                    maxRetries: 3,
                    queryParams: [],
                    keepTrailingForwardSlash: false,
                    wasUrlConvertedToEnvUrlAsParameterFormat: true,
                    formBodyParameters: [],
                    enableKeyValueParameters: false,
                    bodyType: HttpRequestBodyType.FORM_DATA,
                },
                {
                    parameters: [],
                    entitiesToRunOn: [],
                    isChildEntitiesEnabled: false,
                    childEntitiesParameterDefinitions: [],
                    isCustomFieldsEnabled: false,
                },
                actionType,
                {
                    responseHandlingType:
                        EntityResponseHandlingDefinitionType.ENTERPRISE_COMPONENT_VARIABLE_VALUE_ASSIGNMENT,
                    parametersRecipe: {},
                },
            );
        },
        [createProjectIntegrationAction, projectIntegration.id],
    );

    // Navigate to request page in single action view.
    const navigateToEditActionLayout = useCallback(
        (actionId: TonkeanId<TonkeanType.PROJECT_INTEGRATION_ACTION>) => {
            $state.go('product.projectIntegrationActionManager', {
                tab: 'request',
                projectIntegrationActionId: actionId,
                projectIntegrationId: projectIntegration.id,
            });
        },
        [$state, projectIntegration.id],
    );

    // TODO: Temporary solution - REMOVE AFTER REFACTOR CLIENT
    // when user clicks on refresh action at the first time we update formik refresh action id and right after formik updates we submit the form and redirecting
    useEffect(() => {
        if (
            formikContext.initialValues.grantType === OAuth2GrantType.AUTHORIZATION_CODE &&
            !!refreshActionId &&
            refreshActionId !== formikContext.initialValues?.refreshActionId
        ) {
            // Submit the form to because we dont want to lose the created action id.
            formikContext.handleSubmit();
            // Navigate to created refresh action.
            navigateToEditActionLayout(refreshActionId);
        }
    }, [formikContext, navigateToEditActionLayout, refreshActionId]);

    // On Click Refresh Action.
    const onClickRefreshAction = async () => {
        if (!refreshActionId) {
            // if refresh action is not already exists.
            // Create new action.
            const newAction = await createDefaultProjectIntegrationAction(
                'OAuth2 Authentication Refresh Action',
                ActionType.OAUTH2_REFRESH_AUTHENTICATION,
            );
            // Update formik.
            setRefreshActionId(newAction.id);
        } else {
            // Navigate to refresh action.
            navigateToEditActionLayout(refreshActionId);
        }
    };

    // On click configure auth action.
    const onClickConfigureCustomAction = async () => {
        if (!customAuthActionId) {
            setLoadingCustomAuthAction(true);
            // if custom authentication action is not already exists.
            // Create new action.
            const newAction = await createDefaultProjectIntegrationAction(
                'OAuth2 Authentication Request Action',
                ActionType.CUSTOM_AUTHENTICATION,
            );
            // Update formik.
            setAuthActionId(newAction.id);

            // We do it because formik set field takes time to update
            setTimeout(() => {
                formikContext
                    .submitForm()
                    .then(() => {
                        navigateToEditActionLayout(newAction.id);
                    })
                    .finally(() => {
                        setLoadingCustomAuthAction(false);
                    });
            }, 2000);
        } else {
            // Navigate to refresh action.
            navigateToEditActionLayout(customAuthActionId);
        }
    };

    // Oauth 2.0 Supported grant type options.
    const options = useMemo(() => {
        return [
            { label: 'Authorization Code', value: OAuth2GrantType.AUTHORIZATION_CODE },
            { label: 'Client Credentials', value: OAuth2GrantType.CLIENT_CREDENTIALS },
            { label: 'Account Credentials', value: OAuth2GrantType.ACCOUNT_CREDENTIALS },
            { label: 'Custom', value: OAuth2GrantType.CUSTOM },
        ];
    }, []);

    const [{ value: variableValueAssignmentResponseHandlingDefinition }, , { setValue }] = useField<
        EnterpriseComponentVariableValueAssignmentResponseHandlingDefinition | undefined
    >('variableValueAssignmentResponseHandlingDefinition');

    // all the additional props to tonkean expression with the http request (body headers status) fields.
    const expressionPropsWithResponseParamsTab: Partial<TonkeanExpressionProps> = useMemo(() => {
        return {
            ...expressionProps,
            additionalTabs: [...(expressionProps?.additionalTabs || []), tab],
        };
    }, [expressionProps, tab]);

    return (
        <>
            <EnterpriseComponentAuthorizationParam>Grant Type:</EnterpriseComponentAuthorizationParam>
            <StyledSimpleSelect name="grantType" options={options} />
            <Spacer height={12} />

            {formikContext?.values?.grantType === OAuth2GrantType.AUTHORIZATION_CODE && (
                <EnterpriseComponentAuthorizationOAuth2AuthorizationCode expressionProps={expressionProps} />
            )}

            {formikContext?.values?.grantType === OAuth2GrantType.ACCOUNT_CREDENTIALS && (
                <EnterpriseComponentAuthorizationOAuth2AccountCredentials expressionProps={expressionProps} />
            )}

            {formikContext?.values?.grantType === OAuth2GrantType.CLIENT_CREDENTIALS && (
                <EnterpriseComponentAuthorizationOAuth2ClientCredentials expressionProps={expressionProps} />
            )}

            {formikContext?.values?.grantType === OAuth2GrantType.CUSTOM && (
                <>
                    <ClickableButton onClick={onClickConfigureCustomAction} disabled={loadingCustomAuthAction}>
                        Configure Custom Auth Action
                    </ClickableButton>
                    {loadingCustomAuthAction && <CustomAuthLoading />}
                    <Spacer height={12} />
                </>
            )}

            {noCodeRefreshTokenExpirationFF && formikContext?.values?.grantType && (
                <>
                    <Spacer height={12} />
                    <EnterpriseComponentAuthorizationParam>Expires in seconds:</EnterpriseComponentAuthorizationParam>
                    <FormikTonkeanExpression
                        name="expiration"
                        {...expressionPropsWithResponseParamsTab}
                        placeholder="Expires in seconds"
                    />
                </>
            )}

            <Spacer height={12} />

            <ClickableButton onClick={authenticate}>Authenticate</ClickableButton>

            <Spacer height={24} />

            <EnterpriseComponentVariableValueAssignmentResponseHandleResponseView
                expressionProps={expressionProps}
                projectIntegration={projectIntegration}
                variableValueAssignmentResponseHandlingDefinition={variableValueAssignmentResponseHandlingDefinition}
                onChangeHandlingDefinition={setValue}
            />

            <Spacer height={12} />

            {formikContext?.values?.grantType === OAuth2GrantType.AUTHORIZATION_CODE && (
                <>
                    <ClickableButton onClick={onClickRefreshAction}> Configure Refresh Action</ClickableButton>
                    <Spacer height={12} />
                </>
            )}

            <H2>Enrich data in the request </H2>
            <Spacer height={24} />
            <EnterpriseComponentRequestInterceptorData expressionProps={expressionProps} />
        </>
    );
};

export default EnterpriseComponentAuthorizationOAuth2;
