import { useAngularService } from 'angulareact';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { getEnterpriseComponentTabKey } from '../../../EnterpriseComponentsModule/utils/getEnterpriseComponentsPageConfiguration';
import useProjectIntegrationToSolutionAndGroupMapping from '../../hooks/useProjectIntegrationToSolutionAndGroupMapping';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useTonkeanService } from '@tonkean/angular-hooks';
import { useProject } from '@tonkean/infrastructure';
import {
    ErrorMessage,
    Modal,
    ModalBody,
    ModalFooterActions,
    ModalHeader,
    ModalSize,
    Placeholder,
    Separator,
    SimpleErrorStateMessage,
    TextEllipsis,
    useToggle,
} from '@tonkean/infrastructure';
import { WarningTriangleIcon } from '@tonkean/svg';
import type { MarketplaceItemSummary, TonkeanId } from '@tonkean/tonkean-entities';
import { TonkeanType } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { getStateError } from '@tonkean/utils';

const SolutionSection = styled.div`
    font-weight: bold;
    margin-top: 16px;
`;

const ModuleName = styled.div`
    font-weight: normal;
    margin-left: 16px;
`;

const RemoveButton = styled(Clickable)`
    display: block;
    padding-left: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    &:hover {
        background-color: #edf7f9;
    }
`;

const DependenciesList = styled.div`
    margin: 16px 0;
`;

const StyledSeparator = styled(Separator)`
    margin-top: 16px;
`;

const WarningIcon = styled(WarningTriangleIcon)`
    margin-right: 8px;
`;

const SolutionName = styled.div`
    margin-bottom: 4px;
`;

const Notice = styled.div`
    color: ${Theme.colors.gray_500};
    margin-top: 16px;
`;

const NoAccess = styled.span`
    margin-left: 4px;
`;

const DataSourceNameHeader = styled.div`
    display: inline-block;
    margin-left: 4px;
`;

const BlackLinkState = styled(StateLink)`
    color: black;

    &:hover {
        color: black;
    }
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin-top: 8px;
`;

interface Props {
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
    projectIntegrationDisplayName: string;
    checkDependencies?: boolean;
    warningMessage?: string;
    title?: string;
}

const ProjectIntegrationRemoveModal: React.FC<Props> = ({
    projectIntegrationId,
    projectIntegrationDisplayName,
    checkDependencies = true,
    warningMessage,
    title = `${projectIntegrationDisplayName} Data Source`,
}) => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    const project = useProject();
    const $state = useAngularService('$state');
    const $rootScope = useAngularService('$rootScope');
    const workflowFolderManager = useAngularService('workflowFolderManager');

    const {
        mapping: projectIntegrationToSolutionAndGroup,
        workflowFolders,
        groups,
    } = useProjectIntegrationToSolutionAndGroupMapping(projectIntegrationId);

    const [
        { loading: deleteProjectIntegrationLoading, error: deleteProjectIntegrationError },
        deleteProjectIntegration,
    ] = useLazyTonkeanService('deleteProjectIntegration');

    const {
        loading: dependentMarketplaceItemsLoading,
        error: dependentMarketplaceItemsError,
        data: dependentMarketplaceItems,
    } = useTonkeanService('getProjectIntegrationDependentMarketplaceItems', projectIntegrationId);

    const onDelete = useCallback(async () => {
        await deleteProjectIntegration(project?.id, projectIntegrationId);
        toggleIsOpen();

        $state.go(
            'product.enterpriseComponents',
            { tab: $state.params.tab || getEnterpriseComponentTabKey(TonkeanType.PROJECT_INTEGRATION) },
            { reload: true },
        );

        $rootScope.$emit('alert', {
            msg: `Deleted ${title} successfully`,
            type: 'success',
        });
    }, [title, project, $rootScope, $state, deleteProjectIntegration, projectIntegrationId, toggleIsOpen]);

    return (
        <>
            <RemoveButton
                data-automation="projec-integration-remove-modal-remove-button"
                onClick={() => toggleIsOpen()}
            >
                Delete
            </RemoveButton>

            <Modal open={isOpen} onClose={() => toggleIsOpen()} size={ModalSize.SMEDIUM} fixedWidth>
                <ModalHeader $flex>
                    <WarningIcon />
                    Delete
                    <DataSourceNameHeader>
                        <TextEllipsis numberOfLines={1} tooltip>
                            {title}
                        </TextEllipsis>
                    </DataSourceNameHeader>
                </ModalHeader>

                <ModalBody>
                    <div>{warningMessage || `You are about to delete ${title}.`}</div>

                    {checkDependencies && (
                        <>
                            <Notice>
                                {Object.keys(projectIntegrationToSolutionAndGroup[projectIntegrationId] || []).length >
                                0
                                    ? `Solutions that are using ${title}:`
                                    : `There are no solutions which use ${title}.`}
                            </Notice>

                            <DependenciesList>
                                {Object.keys(projectIntegrationToSolutionAndGroup[projectIntegrationId] || [])?.map(
                                    (workflowFolderId) => {
                                        const isUserAllowed =
                                            workflowFolderManager.projectIdToFolderIdToFolderMap[project.id]?.[
                                                workflowFolderId
                                            ]?.isUserOwner;

                                        return (
                                            <SolutionSection key={workflowFolderId}>
                                                <SolutionName>
                                                    <BlackLinkState
                                                        state="product.workers"
                                                        params={{
                                                            folderId: workflowFolderId,
                                                            fromState: $state.current.name,
                                                            fromStateParams: $state.params,
                                                            fromName: title,
                                                        }}
                                                    >
                                                        {workflowFolders[workflowFolderId]?.displayName}
                                                    </BlackLinkState>

                                                    {!isUserAllowed && <NoAccess>(No Access)</NoAccess>}
                                                </SolutionName>

                                                {projectIntegrationToSolutionAndGroup[projectIntegrationId][
                                                    workflowFolderId
                                                ].map((groupId) => {
                                                    return (
                                                        <ModuleName key={groupId}>
                                                            {isUserAllowed ? (
                                                                <BlackLinkState
                                                                    state="product.workerEditor"
                                                                    params={{
                                                                        g: groupId,
                                                                        fromState: $state.current.name,
                                                                        fromStateParams: $state.params,
                                                                        fromName: title,
                                                                    }}
                                                                >
                                                                    {groups[groupId]?.name}
                                                                </BlackLinkState>
                                                            ) : (
                                                                <div>{groups[groupId]?.name}</div>
                                                            )}
                                                        </ModuleName>
                                                    );
                                                })}
                                            </SolutionSection>
                                        );
                                    },
                                )}
                            </DependenciesList>
                        </>
                    )}

                    {dependentMarketplaceItemsLoading && <Placeholder $width="300px" />}
                    {dependentMarketplaceItemsError && (
                        <SimpleErrorStateMessage
                            error={getStateError(dependentMarketplaceItemsError, {
                                fallbackErrorMessage: `There was an error fetching dependent reusable components of ${title}`,
                            })}
                            showSmallError
                        />
                    )}

                    {!dependentMarketplaceItemsLoading &&
                        !dependentMarketplaceItemsError &&
                        dependentMarketplaceItems && (
                            <>
                                <StyledSeparator />
                                <Notice>
                                    {dependentMarketplaceItems.length > 0
                                        ? `Reusable components that are using ${title}:`
                                        : `There are no reusable components which use ${title}.`}
                                </Notice>

                                <DependenciesList>
                                    {dependentMarketplaceItems.map((marketplaceItemSummary: MarketplaceItemSummary) => {
                                        return (
                                            <SolutionSection key={marketplaceItemSummary.id}>
                                                <SolutionName>{marketplaceItemSummary.title}</SolutionName>
                                            </SolutionSection>
                                        );
                                    })}
                                </DependenciesList>
                            </>
                        )}

                    {deleteProjectIntegrationError && (
                        <StyledErrorMessage>
                            {deleteProjectIntegrationError?.data?.message || `There was an error deleting ${title}`}
                        </StyledErrorMessage>
                    )}
                </ModalBody>

                <ModalFooterActions
                    saveLabel="Delete"
                    onCancel={() => toggleIsOpen()}
                    loading={deleteProjectIntegrationLoading}
                    saveDisabled={
                        (checkDependencies && projectIntegrationToSolutionAndGroup[projectIntegrationId]) ||
                        dependentMarketplaceItemsLoading ||
                        dependentMarketplaceItemsError ||
                        (dependentMarketplaceItems && dependentMarketplaceItems.length > 0)
                    }
                    onSave={onDelete}
                    isWarning
                />
            </Modal>
        </>
    );
};

export default ProjectIntegrationRemoveModal;
