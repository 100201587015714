<div class="padding-none">
    <div
        class="question-box-activity margin-none row"
        ui-sref="product.analyze.problems.details({problemId: item.reference2.problem.id})"
        analytics-on
        analytics-category="Problem Page"
        analytics-event="Open"
        analytics-label="Home activity"
    >
        <div class="question-box-activity-icon col-sm-1"></div>
        <div class="question-box-activity-text col-sm-10">
            <p class="capital-first-letter">{{ item.reference2.text.substring(15) }}</p>

            <div ng-if="item.reference2.peopleCount === 1">
                <p
                    ng-if="
                        item.reference2.indications &&
                        item.reference2.indications.length &&
                        item.reference2.indications[0] &&
                        item.reference2.indications[0].elaborateText
                    "
                >
                    And mentioned:
                    <span class="block" light-markdown="item.reference2.indications[0].elaborateText"></span>
                </p>

                <div
                    ng-if="
                        item.reference2.elaborateTags &&
                        item.reference2.elaborateTags.length > 0 &&
                        item.reference2.elaborateTags.length < 5
                    "
                    class="question-box-activity-specifically list-comma"
                >
                    Specifically:
                    <span ng-repeat="tag in item.reference2.elaborateTags">{{ tag.tag }}</span>
                </div>
            </div>
        </div>
        <div class="question-box-activity-arrow col-sm-1 hidden-xs"></div>
    </div>
</div>
