import { useCallback, useMemo } from 'react';

import { ReactComponent as IntakeSequenceIcon } from '../../../images/icons/intake-request-empty-state.svg';
import { ReactComponent as RequestFieldIcon } from '../../../images/icons/request-field.svg';
import { ReactComponent as UserCircleIcon } from '../../../images/icons/user-circle.svg';
import {
    IntakeSequenceTagging,
    RequestFieldsTagging,
    UserTagging,
} from '../components/taggableEntitiesInput/taggableEntitiesComponents';
import type { TaggableEntityItem } from '../entities';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { TonkeanType } from '@tonkean/tonkean-entities';
import type { TonkeanId } from '@tonkean/tonkean-entities';

interface GetTaggableEntitiesResponse {
    fetchEntities: (search: any, callback: (data: TaggableEntityItem[]) => void) => Promise<void>;
    getTaggabaleEntityById: (id: string) => TaggableEntityItem | undefined;
}

const useGetTaggableEntities = (
    fields?: TaggableEntityItem[],
    projectId?: TonkeanId<TonkeanType.PROJECT>,
    taggableCustomTriggerInterfaces?: TaggableEntityItem[],
): GetTaggableEntitiesResponse => {
    const [, getTeamMembers] = useLazyTonkeanService('getTeamMembers');

    const emptyStateTitles = useMemo(() => {
        const emptyStateMenu: TaggableEntityItem[] = [];

        emptyStateMenu.push({
            id: 'Empty state title',
            display: 'Type to filter the following tags:',
            isEmptyState: true,
            isTitle: true,
        });
        if (taggableCustomTriggerInterfaces && taggableCustomTriggerInterfaces.length > 0) {
            emptyStateMenu.push({
                id: TonkeanType.CUSTOM_TRIGGER,
                display: 'Intake sequence',
                icon: IntakeSequenceIcon,
                isEmptyState: true,
            });
        }
        emptyStateMenu.push({
            id: TonkeanType.PERSON,
            display: 'User',
            icon: UserCircleIcon,
            isEmptyState: true,
        });
        if (fields && fields.length > 0) {
            emptyStateMenu.push({
                id: TonkeanType.FIELD_DEFINITION,
                display: 'Request field',
                icon: RequestFieldIcon,
                isEmptyState: true,
            });
        }
        return emptyStateMenu;
    }, [fields, taggableCustomTriggerInterfaces]);

    const getTaggabaleEntityById = useCallback(
        (id: string): TaggableEntityItem | undefined => {
            const customTriggerEntity = taggableCustomTriggerInterfaces?.find(
                (customTrigger) => customTrigger.id === id,
            );
            if (customTriggerEntity) {
                return customTriggerEntity;
            }

            const fieldEntity = fields?.find((fieldItem) => fieldItem.id === id);
            if (fieldEntity) {
                return fieldEntity;
            }

            const emptyStateEntity = emptyStateTitles.find((fieldItem) => fieldItem.id === id);
            if (emptyStateEntity) {
                return emptyStateEntity;
            }

            const isTitleEntity = [
                TonkeanType.CUSTOM_TRIGGER.toString(),
                TonkeanType.PERSON.toString(),
                TonkeanType.FIELD_DEFINITION.toString(),
            ].includes(id);
            if (isTitleEntity) {
                return { id: 'title', display: 'title', isTitle: true };
            }

            return undefined;
        },
        [taggableCustomTriggerInterfaces, fields, emptyStateTitles],
    );

    const fetchEntities = useCallback(
        async (search: any, callback: (data: TaggableEntityItem[]) => void) => {
            const entities: TaggableEntityItem[] = [];

            if (!search) {
                entities.push(...emptyStateTitles);
            } else {
                const filteredInterfaces = taggableCustomTriggerInterfaces?.filter((interfaceItem) =>
                    interfaceItem.display.toLowerCase().includes(search.toLowerCase()),
                );

                const filteredFields = fields?.filter((fieldItem) =>
                    fieldItem.display.toLowerCase().includes(search.toLowerCase()),
                );

                if (filteredInterfaces && filteredInterfaces.length > 0) {
                    entities.push({
                        id: TonkeanType.CUSTOM_TRIGGER,
                        display: 'Intake Sequence',
                        isTitle: true,
                    });

                    entities.push(
                        ...filteredInterfaces.map((taggableCustomTriggerInterface) => ({
                            ...taggableCustomTriggerInterface,
                            component: IntakeSequenceTagging,
                        })),
                    );
                }

                if (projectId) {
                    const filteredTeamMembers = await getTeamMembers(projectId, undefined, search);
                    const teamMmembersData: TaggableEntityItem[] = filteredTeamMembers?.people.map((admin) => ({
                        ...admin,
                        display: admin.name,
                    }));

                    if (teamMmembersData?.length > 0) {
                        entities.push({
                            id: TonkeanType.PERSON,
                            display: 'Users',
                            isTitle: true,
                        });

                        entities.push(
                            ...teamMmembersData.map((member) => ({
                                ...member,
                                component: UserTagging,
                            })),
                        );
                    }
                }

                if (filteredFields && filteredFields.length > 0) {
                    entities.push({
                        id: TonkeanType.FIELD_DEFINITION,
                        display: 'Request Fields',
                        isEmptyState: false,
                        isTitle: true,
                    });

                    entities.push(
                        ...filteredFields.map((fieldItem) => ({
                            ...fieldItem,
                            component: RequestFieldsTagging,
                        })),
                    );
                }
            }

            callback(entities);
        },
        [fields, getTeamMembers, projectId, taggableCustomTriggerInterfaces, emptyStateTitles],
    );

    return { fetchEntities, getTaggabaleEntityById };
};

export default useGetTaggableEntities;
