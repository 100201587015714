import React from 'react';
import styled, { css } from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import type { StyledComponentsSupportProps } from '@tonkean/utils';
import { classNames } from '@tonkean/utils';

const Icon = styled.div<{ $iconUrl?: string; $small?: boolean }>`
    margin-right: 10px;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;

    ${({ $iconUrl }) =>
        $iconUrl &&
        css`
            background: url('${$iconUrl}') !important;
        `};

    ${({ $small }) =>
        $small &&
        css`
            height: 14px;
            width: 14px;
            min-height: 14px;
            min-width: 14px;
        `}

    background-size: cover !important;
`;

const StyledPlaceholder = styled(Placeholder)`
    margin-right: 10px;
`;

interface Props extends StyledComponentsSupportProps {
    iconUrl?: string;
    integrationType?: string;
    isValid?: boolean;
    small?: boolean;
    dataAutomation?: string;
}

/**
 * Will show the Enterprise Component icon.
 */
const EnterpriseComponentIcon: React.FC<Props> = ({
    iconUrl,
    integrationType,
    isValid = true,
    small,
    dataAutomation,
}) => {
    const hasMissingInformation = !integrationType && !iconUrl;

    const className = classNames(
        'initiative-integration-icon',
        integrationType && `mod-${integrationType.toLowerCase()}`,
        !isValid && 'mod-error',
    );

    return (
        <>
            {hasMissingInformation ? (
                <StyledPlaceholder $width="20px" data-automation={dataAutomation} $circle />
            ) : (
                <Icon className={className} $iconUrl={iconUrl} $small={small} data-automation={dataAutomation} />
            )}
        </>
    );
};

export default EnterpriseComponentIcon;
