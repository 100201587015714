export enum AirtableAuthenticationType {
    API_KEY = 'api_key',
    TOKEN = 'token',
    OAUTH = 'oauth',
}

export const authTypeToDisplayName: Record<AirtableAuthenticationType, string> = {
    [AirtableAuthenticationType.API_KEY]: 'API Key',
    [AirtableAuthenticationType.TOKEN]: 'Personal Token',
    [AirtableAuthenticationType.OAUTH]: 'OAuth',
};

export default AirtableAuthenticationType;
