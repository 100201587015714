import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-form.svg';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import type { FormikExpressionProps } from '@tonkean/angular-to-react-components';
import type { HttpRequestHeader } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const StyledIconButton = styled(IconButton)`
    margin: 3px auto auto auto;
`;

const InputWrapper = styled.div`
    margin-right: 12px;
    &:not(:first-child) {
        margin-bottom: 15px;
    }
`;

interface Props {
    name: string;
    isContentType?: boolean;
    removeRow?: () => void;
    expressionProps?: FormikExpressionProps;
}

const SingleHeaderRow: React.FC<Props> = ({ name, removeRow, expressionProps }) => {
    const [header, , headerHelper] = useField<HttpRequestHeader>(name);

    return (
        <>
            <InputWrapper>
                <TonkeanExpression
                    {...expressionProps}
                    savedOriginalExpression={header.value.name.originalExpression}
                    savedEvaluatedExpression={header.value.name.evaluatedExpression}
                    placeholder="Header Name"
                    onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                        headerHelper.setValue({
                            ...header.value,
                            name: { ...header.value.name, originalExpression, evaluatedExpression },
                        });
                    }}
                    doNotEvaluatePreview
                />
            </InputWrapper>

            <TonkeanExpression
                {...expressionProps}
                placeholder="Header Value"
                savedOriginalExpression={header.value.value.originalExpression}
                savedEvaluatedExpression={header.value.value.evaluatedExpression}
                onTonkeanExpressionChanged={(originalExpression, evaluatedExpression) => {
                    headerHelper.setValue({
                        ...header.value,
                        value: { ...header.value.value, originalExpression, evaluatedExpression },
                    });
                }}
                doNotEvaluatePreview
            />

            <StyledIconButton onClick={removeRow} flat>
                <TrashIcon />
            </StyledIconButton>
        </>
    );
};

export default SingleHeaderRow;
