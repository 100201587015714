<div class="common-page-load" ng-show="loading">
    <i class="loading-full-circle"></i>
</div>

<div ng-if="!loading" class="update-initiative-state-container container padding-none-xs margin-top-md mod-center">
    <div class="margin-auto common-break-long">
        <div class="visible-xs margin-bottom-lg" ng-if="!data.onlyFieldDefinitionId && !data.onlyInnerItems">
            Please provide an update for:
        </div>

        <div class="margin-bottom-lg">
            <div class="common-size">
                <span>
                    <!-- Title -->
                    <span class="fs-censored">{{ data.initiative.title }}</span>
                    <span
                        ng-if="data.initiative.group && !data.initiative.group.isVisibleToUser"
                        class="common-size-xxxxs common-color-light-grey"
                    >
                        <i class="svg-icon">
                            <tnk-icon src="/images/icons/lock.svg"></tnk-icon>
                        </i>
                        (The list is private and not visible to you)
                    </span>
                    <!-- Parents -->
                    <span
                        class="common-size-xxxxs common-color-grey text-left margin-none fs-censored"
                        ng-if="data.initiative.parentInitiatives && data.initiative.parentInitiatives.length"
                    >
                        <span class="inline-block" ng-if="::parseParents(data.initiative, itemMap).length">
                            (
                            <span
                                ng-repeat="p in ::parseParents(data.initiative, itemMap)"
                                class="inline-block"
                            >
                                <span class="common-color-grey">{{ p.title || itemMap[p.id].title }}</span>
                                <i
                                    class="fa fa-angle-right margin-right-xs margin-left-xs common-color-light-grey"
                                    ng-if="!$last"
                                ></i>
                            </span>
                            )
                        </span>
                    </span>
                </span>
                <span
                    class="margin-left-xs common-size-xs"
                    ng-if="data.initiative.dueDate"
                    uib-tooltip="{{ data.initiative.dueDate | date }}"
                >
                    (DUE: {{ data.initiative.dueDate | daysDiff }})
                </span>
            </div>
            <div
                ng-if="data.initiative.externalId || (data.initiative.tags && data.initiative.tags.length)"
                class="common-size-xxxxs common-bold common-color-dark-grey margin-top-xs margin-bottom"
            >
                <span class="margin-right" ng-if="data.initiative.externalId">
                    Connected to
                    <a ng-href="{{ data.initiative.externalUrl }}" target="_blank">
                        {{ data.initiative.externalSourceFriendly || data.initiative.externalSource | capitalize }}
                    </a>
                </span>
                <span ng-if="data.initiative.tags && data.initiative.tags.length">
                    <span
                        class="initiative-tag"
                        ng-class="{ 'margin-left-xs': !$first }"
                        ng-repeat="tag in data.initiative.tags"
                    >
                        {{ tag.name || tag }}
                    </span>
                </span>
            </div>
        </div>

        <!-- Description -->
        <div
            ng-if="
                data.initiative.description &&
                data.initiative.description.length &&
                !data.onlyFieldDefinitionId &&
                !data.onlyInnerItems
            "
            class="initiative-description-container margin-top-lg margin-bottom-lg relative"
            ng-class="{ 'mod-open': data.showDescription }"
        >
            <div
                class="relative initiative-description-box initiative-growable"
                click-outside="data.showDescription = false"
                outside-if-not="initiative-growable-hook initiative-description-content"
                ng-class="data.showDescription ? 'mod-grow' : 'mod-sm'"
            >
                <div class="common-color-dark-grey common-size-xxs clearfix initiative-description-content fs-censored">
                    <div class="flex-grow" light-markdown="data.initiative.description"></div>
                </div>
                <a
                    ng-click="data.showDescription = false"
                    class="margin-top-lg common-size-xxxxs"
                    ng-show="data.showDescription"
                >
                    Collapse
                </a>
                <div
                    class="initiative-growable-hook"
                    ng-hide="data.showDescription"
                    ng-click="data.showDescription = true;"
                ></div>
            </div>
        </div>

        <tnk-function-card
            class="mod-no-max margin-top-lg mod-page"
            ng-if="!data.onlyFieldDefinitionId && !data.onlyInnerItems && data.initiative"
            title-text="Please provide an update:"
            show-owner="true"
            full-page-mode="true"
            update-initiative="initiativeSaved(data, dontLoadActivity)"
            initiative="data.initiative"
            edit-mode="true"
        ></tnk-function-card>

        <!-- Update Fields -->
        <div
            ng-if="
                ((data.fieldDefinitions && data.fieldDefinitions.length) ||
                    (data.onlyFieldDefinitionId && data.tonkeanFieldToUpdate)) &&
                !data.onlyInnerItems &&
                !data.showWebformMessageResponse
            "
            class="margin-top-xxlg container-md box-with-shadow mod-no-xs padding-normal"
            ng-class="{
                'common-border-primary margin-top-none': !!data.onlyFieldDefinitionId,
                'margin-bottom-xxlg': !data.onlyFieldDefinitionId
            }"
        >
            <p>
                Update the following information:
                <i class="loading-small margin-left" ng-if="fieldPosting"></i>
            </p>
            <hr class="margin-normal-sm-v" />
            <!-- Tonkean Fields (when not updating normal fields) -->
            <div ng-if="data.tonkeanFieldToUpdate" class="flex-vmiddle">
                <span class="flex-no-shrink common-bold margin-right">{{ data.tonkeanFieldToUpdate.label }}:</span>
                <tnk-initiative-special-field-selector
                    initiative-id="data.initiative.id"
                    field-id="data.onlyFieldDefinitionId"
                    workflow-version-id="data.workflowVersion.id"
                    on-saved="(onFieldSaved)"
                ></tnk-initiative-special-field-selector>
            </div>

            <!-- Fields (when not updating Tonkean fields) -->
            <div ng-if="!data.tonkeanFieldToUpdate">
                <div
                    class="flex-vmiddle flex-wrap margin-bottom-xs relative"
                    ng-repeat="fieldDefinition in data.fieldDefinitions"
                    ng-if="
                        !fieldDefinition.systemUtilized &&
                        (!data.onlyFieldDefinitionId || data.onlyFieldDefinitionId === fieldDefinition.id)
                    "
                >
                    {{field = data.initiative.defIdToValidFieldsMap[fieldDefinition.id] ?
                    data.initiative.defIdToValidFieldsMap[fieldDefinition.id][0] : null;""}}

                    <div class="flex-basis-40 common-bold padding-right-xs field-name">
                        <span class="fs-censored">{{ fieldDefinition.fieldLabel || fieldDefinition.name }}:</span>
                        <div class="common-color-grey common-size-xxxxxs" ng-if="fieldDefinition.definitionSource">
                            {{ fieldDefinition.definitionSource | capitalize }}
                        </div>
                    </div>
                    <tnk-update-field-value
                        class="flex-basis-60"
                        field="field"
                        initiative="data.initiative"
                        group="data.initiative.group"
                        field-definition="fieldDefinition"
                        on-saved="onFieldSaved(updatedField)"
                        target-id="data.initiative.id"
                        inline-update="!data.onlyFieldDefinitionId"
                        is-only-field="!!data.onlyFieldDefinitionId"
                        is-disabled="!((field && field.evaluatedUpdateable && (fieldDefinition.type === 'MANUAL' || field.isAssociated)) || (!field && fieldDefinition.type === 'MANUAL'))"
                    ></tnk-update-field-value>
                </div>
            </div>
        </div>

        <!-- Ask Field Update response -->
        <div
            ng-if="data.showWebformMessageResponse"
            class="
                margin-top-none
                container-md
                box-with-shadow
                mod-no-xs
                padding-normal
                common-border-primary
                web-response
            "
        >
            {{ data.askForInfoAnsweredWebResponse }}
        </div>

        <!-- Inner tracks -->
        <div
            id="update-initiative-state-inner-items"
            class="margin-top-xlg container-md mod-no-xs padding-top-xlg update-initiative-state-inner-items"
            ng-if="data.initiative && data.initiative.group && !data.onlyFieldDefinitionId"
            ng-class="{
                'box-with-shadow common-border-primary padding-left-xlg-no-xs margin-top-none': data.onlyInnerItems,
                'margin-bottom-xxlg': !data.onlyInnerItems
            }"
        >
            <p class="margin-bottom-lg">
                <strong>{{ data.onlyInnerItems ? 'Set ' : '' }}Inner Tracks:</strong>
            </p>
            <hr class="margin-none" />

            <div ng-show="loadingTracks" class="text-center common-size margin-top-lg">
                <i class="loading-medium"></i>
            </div>

            <div class="flex" ng-if="!loadingTracks">
                <div class="flex-grow" style="max-width: none">
                    <tnk-tracks-editor
                        scroller-element-id="update-initiative-state-inner-items"
                        list-parent-element-id="update-initiative-state-inner-items"
                        tracks="data.initiative.relatedInitiatives"
                        collapse-by-default="true"
                        show-add-on-empty="true"
                        show-archived="false"
                        hide-header="true"
                        block-adding-links="true"
                        editor-id="data.initiative.id"
                        default-func="data.initiative.function"
                        default-me="true"
                        loading="data.loadingRelated || data.initiative.loadingRelated"
                        allow-dnd-on-root="true"
                        parent-item="data.initiative"
                        group-id="data.initiative.group.id"
                        workflow-version-id="data.workflowVersion.id"
                        override-item-click="onItemClicked"
                        custom-fields="null"
                        created-in-worker-run-information="data.createdInWorkerRunInformation"
                        view-only-mode="!data.initiative.group.canUserWrite"
                        load-next-initiatives-page-callback="loadNextInnerItemsPage()"
                        has-more-initiatives="data.initiative.hasMoreInnerItemsToLoad"
                        lazy-load-tracks="true"
                    ></tnk-tracks-editor>
                </div>
            </div>
        </div>

        <!-- Done when updating specific field or inner items. -->
        <div ng-if="data.onlyInnerItems" class="margin-top text-right">
            <a
                class="btn btn-lg btn-primary"
                ui-sref="product.interface.group({groupId: data.initiative.group.id, initiativeId: data.initiativeId})"
            >
                Done
            </a>
        </div>
    </div>

    <!-- Empty state -->
    <div
        class="margin-auto text-center common-size-xs common-color-grey"
        ng-if="!loadingTracks && !loading && !data.initiative && data.initiativeId && data.initiativeId.length"
    >
        <div class="margin-top-xlg">Track was not found...</div>
    </div>
</div>
