import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function DomainsInputCtrl($rootScope, $scope, utils) {
    const ctrl = this;
    $scope.placeholder = ctrl.placeholder || "Enter company's domains";
    $scope.tags = ctrl.domains || [];
    $scope.invalidTagMessage = '';

    $scope.isValidTag = function (tag) {
        if (utils.isCommonDomain(tag.text)) {
            $scope.invalidTagMessage = `Can't use a public domain like ${tag.text}`;
            return false;
        }

        const basicDomainRegex = '.+\\..+';
        if (!tag.text.match(basicDomainRegex)) {
            $scope.invalidTagMessage = 'Please enter a valid domain with two parts seperated by a dot';
            return false;
        }

        $scope.invalidTagMessage = '';
        return true;
    };

    $scope.isValidOnKeyStroke = function (text) {
        $scope.isValidTag({ text });
        // have to return an array for the 'auto complete'
        return [];
    };

    $scope.updateTags = function () {
        const tagsTexts = $scope.tags.map(function (tag) {
            return tag.text;
        });
        ctrl.updateDomains(tagsTexts);
    };

    ctrl.$onChanges = function (changes) {
        if (changes.domains) {
            $scope.tags = ctrl.domains || [];
        }
    };
}
export default angular.module('tonkean.app').controller('DomainsInputCtrl', lateConstructController(DomainsInputCtrl));
