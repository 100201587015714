import { useAngularService } from 'angulareact';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import BoxAuthenticateAsType from './BoxAuthenticateAsType';
import BoxAuthenticationType from './BoxAuthenticationType';
import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';
import GenericIntegrationContext from '../GenericIntegrationContext';

import { RadioGroup } from '@tonkean/infrastructure';
import { Radio } from '@tonkean/infrastructure';
import { TnkSelect } from '@tonkean/infrastructure';
import { InputSize } from '@tonkean/tui-theme/sizes';

const CenteredAuthTypeTitle = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const CenteredLinkButton = styled.div`
    text-align: center;
`;

interface Permission {
    label: string;
    value: string;
}

const BoxComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const environment = useAngularService('environment');
    const { oauthHandler } = useContext(GenericIntegrationContext);
    const permissionList = [
        {
            label: 'Manage app users',
            value: 'manage_app_users',
        },
        {
            label: 'Manage enterprise properties',
            value: 'manage_enterprise_properties',
        },
        {
            label: 'Manage retention policies',
            value: 'manage_data_retention',
        },
        {
            label: 'Global content manager',
            value: 'gcm',
        },
        {
            label: 'Admin can make calls on behalf of users',
            value: 'admin_on_behalf_of',
        },
    ];
    const readWriteOptions = {
        read_only: {
            label: 'Read Only',
            value: 'root_readonly',
        },
        read_write: {
            label: 'Read & Write',
            value: 'root_readonly root_readwrite',
        },
    };
    const defaultPermissions = ['manage_webhook', 'manage_managed_users', 'manage_groups'];
    const [permission, setPermission] = useState<string>('');
    const [authenticationType, setAuthenticationType] = useState<BoxAuthenticationType>(BoxAuthenticationType.OAUTH);
    const [selectedAdvancedPermissions, setSelectedAdvancedPermissions] = useState<Permission[]>([]);
    const [clientId, setClientId] = useState<string>();
    const [clientSecret, setClientSecret] = useState<string>();
    const [boxSubjectId, setBoxSubjectId] = useState<string>();
    const [authenticateAs, setAuthenticateAs] = useState<BoxAuthenticateAsType>(BoxAuthenticateAsType.ENTERPRISE);

    const authenticateWithOAuth = async () => {
        try {
            const redirectUri = environment.integrationKeysMap.boxUri;
            const tonkeanClientId = environment.integrationKeysMap.box;
            const redirectUriState = oauthHandler.publicGetState();

            const scopes = defaultPermissions;

            scopes.push(permission);

            selectedAdvancedPermissions.forEach((permission) => {
                scopes.push(permission.value);
            });

            const url = `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=${tonkeanClientId}&scope=${scopes.join(
                ' ',
            )}&redirect_uri=${redirectUri}&state=${redirectUriState}`;

            const code = await oauthHandler.startOAuth(url, redirectUriState);

            const authType = BoxAuthenticationType.OAUTH;

            authenticate({ code, scopes, authType });
        } catch {
            reportError('Error occurred while authenticating using OAuth.');
        }
    };

    const authenticateWithCustomApp = () => {
        try {
            const boxSubjectType = authenticateAs.toLowerCase();
            const authType = BoxAuthenticationType.CUSTOM_APP;
            authenticate({ clientId, clientSecret, boxSubjectType, boxSubjectId, authType });
        } catch {
            reportError('Error occurred while authenticating using a custom app.');
        }
    };

    const onPermissionsSelected = useCallback((permissions) => {
        setSelectedAdvancedPermissions(permissions);
    }, []);

    return (
        <div className="flex flex-col">
            <div className="api-token-authentication">
                <CenteredAuthTypeTitle>
                    <h5>{authenticationType} Authentication</h5>
                </CenteredAuthTypeTitle>

                {authenticationType === BoxAuthenticationType.OAUTH && (
                    <div className="flex flex-col">
                        <div className="form-group flex-inline">
                            <label className="control-label col-md-4 margin-right">Read/Write permissions</label>

                            <div className="col-sm-7">
                                <RadioGroup
                                    value={permission}
                                    direction="row"
                                    onChange={setPermission}
                                    size={InputSize.MEDIUM}
                                >
                                    <Radio value={readWriteOptions.read_only.value}>
                                        {readWriteOptions.read_only.label}
                                    </Radio>
                                    <Radio value={readWriteOptions.read_write.value}>
                                        {readWriteOptions.read_write.label}
                                    </Radio>
                                </RadioGroup>
                            </div>
                        </div>

                        <div className="form-group flex-inline">
                            <label className="control-label col-md-4 margin-right">
                                Advanced permissions (optional)
                            </label>

                            <div className="col-sm-7">
                                <TnkSelect
                                    options={permissionList}
                                    onChange={onPermissionsSelected}
                                    value={selectedAdvancedPermissions}
                                    isSearchable
                                    isMulti
                                />
                                <span className="common-size-xxxs common-color-grey">
                                    Minimum permissions that will always be requested are: Manage files and folders,
                                    users, groups and webhooks.
                                </span>
                            </div>
                        </div>

                        <div className="flex mod-center margin-top-20">
                            <button
                                className=" btn btn-secondary btn-lg"
                                onClick={authenticateWithOAuth}
                                disabled={!permission}
                            >
                                Authenticate with {integrationConfiguration.displayName}
                            </button>
                        </div>
                    </div>
                )}

                {authenticationType === BoxAuthenticationType.CUSTOM_APP && (
                    <div>
                        <form className="form-horizontal" onSubmit={authenticateWithCustomApp}>
                            <div className="flex flex-col">
                                <div className="form-group">
                                    <label htmlFor="client_id" className="col-md-4 control-label">
                                        Client ID
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            value={clientId}
                                            className="form-control"
                                            onChange={({ target }) => setClientId(target.value)}
                                            id="client_id"
                                            autoComplete="off"
                                            placeholder="Client ID"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="client_secret" className="col-md-4 control-label">
                                        Client Secret
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            type="password"
                                            value={clientSecret}
                                            className="form-control"
                                            onChange={({ target }) => setClientSecret(target.value)}
                                            id="client_secret"
                                            autoComplete="off"
                                            placeholder="Client Secret"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="control-label col-md-4">Authenticate As</label>

                                    <div className="col-sm-7 margin-top-6">
                                        <RadioGroup
                                            value={authenticateAs}
                                            direction="row"
                                            onChange={setAuthenticateAs}
                                            size={InputSize.MEDIUM}
                                        >
                                            <Radio value={BoxAuthenticateAsType.ENTERPRISE}>
                                                {BoxAuthenticateAsType.ENTERPRISE}
                                            </Radio>
                                            <Radio value={BoxAuthenticateAsType.USER}>
                                                {BoxAuthenticateAsType.USER}
                                            </Radio>
                                        </RadioGroup>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="authenticate_as_id" className="col-md-4 control-label">
                                        {authenticateAs} ID
                                    </label>
                                    <div className="col-sm-7">
                                        <input
                                            value={boxSubjectId}
                                            className="form-control"
                                            onChange={({ target }) => setBoxSubjectId(target.value)}
                                            id="authenticate_as_id"
                                            autoComplete="off"
                                            placeholder={`${authenticateAs} ID`}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-grou margin-bottom-md">
                                    <label className="control-label col-md-4">Prerequisites</label>
                                    <div className="col-sm-7 common-size-xxxs common-color-grey">
                                        <ul className="common-ul-small-bullets mod-grey margin-top-xs padding-left-xxs margin-bottom-xxs">
                                            <li>
                                                A Custom Application using Client Credentials Grant authentication
                                                within the Box&nbsp;
                                                <a href="https://app.box.com/developers/console" target="_blank">
                                                    Developer Console
                                                </a>
                                                .
                                            </li>
                                            <li>
                                                Make sure the next scopes are enrolled:
                                                <ul className="common-ul-small-bullets mod-grey margin-top-xs padding-left-md margin-bottom-xs">
                                                    <li>Read all files and folders stored in Box</li>
                                                    <li>Read and write all files and folders stored in Box</li>
                                                    <li>Manage groups</li>
                                                    <li>Manage webhooks</li>
                                                    <li>Manage app users</li>
                                                    <li>Admin can make calls on behalf of users</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://support.box.com/hc/en-us/articles/360043697154-Two-Factor-Authentication-Set-Up-for-Your-Account"
                                                    target="_blank"
                                                >
                                                    2FA
                                                </a>
                                                &nbsp;enabled on your Box account in order to view/copy your client
                                                secret from the configuration tab.
                                            </li>
                                            <li>Ensure your application is authorized within the Box Admin Console</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Connect button*/}
                                <div className="flex mod-center">
                                    <button className="btn btn-primary">Connect</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

                <div>
                    <CenteredLinkButton className="form-group margin-top-20">
                        <a
                            onClick={() =>
                                setAuthenticationType(
                                    authenticationType === BoxAuthenticationType.OAUTH
                                        ? BoxAuthenticationType.CUSTOM_APP
                                        : BoxAuthenticationType.OAUTH,
                                )
                            }
                        >
                            Use&nbsp;
                            {authenticationType === BoxAuthenticationType.OAUTH
                                ? BoxAuthenticationType.CUSTOM_APP
                                : BoxAuthenticationType.OAUTH}
                            &nbsp;Authentication
                        </a>
                    </CenteredLinkButton>
                </div>
            </div>
        </div>
    );
};

export default BoxComponent;
