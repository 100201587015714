import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

export interface SAPOpenConnectorAuthenticationProps extends CustomizedAuthenticationComponentProps {}

const SAPOpenConnectorAuthenticationComponent: React.FC<SAPOpenConnectorAuthenticationProps> = ({
    authenticate,
    reportError,
}) => {
    const [url, setUrl] = useState<string>('');
    const [user, setUser] = useState<string>('');
    const [organization, setOrganization] = useState<string>('');

    const authenticateWithUserAndOrg = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const config = {
                api_url: url,
                api_user: user,
                api_org: organization,
            };
            authenticate(config, {}, `SAP Open Connector (${user})`);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateWithUserAndOrg}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label htmlFor="login-api-region" className="col-sm-3 control-label">
                            SAP Open Connector URL
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={url}
                                className="form-control"
                                id="login-api-url"
                                onChange={({ target }) => setUrl(target.value)}
                                autoComplete="off"
                                placeholder="URL"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-client" className="col-sm-3 control-label">
                            User
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                value={user}
                                className="form-control"
                                id="login-api-user"
                                onChange={({ target }) => setUser(target.value)}
                                autoComplete="off"
                                placeholder="Client ID"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="login-api-secret" className="col-sm-3 control-label">
                            Organization
                        </label>
                        <div className="col-sm-8">
                            <input
                                type="password"
                                value={organization}
                                className="form-control"
                                onChange={({ target }) => setOrganization(target.value)}
                                id="login-api-organization"
                                autoComplete="off"
                                placeholder="Organization"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SAPOpenConnectorAuthenticationComponent;
