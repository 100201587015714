import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../base/CustomizedAuthenticationComponentProps';

import { GoogleAuthenticationType } from '@tonkean/tonkean-entities';

const GoogleCloudStorageComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
}) => {
    const [jsonCredentials, setJsonCredentials] = useState<string>('');
    const [bucket, setBucket] = useState<string | undefined>(undefined);

    const authenticateCallback = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();
            const parsedJson = JSON.parse(jsonCredentials);

            if (typeof parsedJson !== 'object') {
                reportError('JSON is not valid.');
            } else {
                const config = {
                    credentialsJson: parsedJson,
                    bucket,
                    environment: GoogleAuthenticationType.SERVICE_ACCOUNT,
                };
                authenticate(config, {}, `${integrationConfiguration.displayName} (${bucket})`);
            }
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateCallback}>
                <div className="flex api-token-authentication">
                    <div className="form-group">
                        <label className="col-sm-4 control-label">Service Account Credentials</label>
                        <div className="col-sm-7">
                            <textarea
                                value={jsonCredentials}
                                onChange={({ target }) => setJsonCredentials(target.value)}
                                className="form-control common-resize-y-only"
                                autoComplete="off"
                                placeholder="JSON Credentials"
                                required
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="col-sm-4 control-label">Bucket</label>
                        <div className="col-sm-7">
                            <input
                                type="text"
                                value={bucket}
                                className="form-control"
                                onChange={({ target }) => setBucket(target.value)}
                                autoComplete="off"
                                placeholder="Bucket ID"
                                required
                            />
                        </div>
                    </div>

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default GoogleCloudStorageComponent;
