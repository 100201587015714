import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { FormikTnkCustomFilters } from '@tonkean/angular-to-react-components';
import {
    H3,
    ModalBody,
    ModalFooterActions,
    ModalForm,
    ModalHeader,
    ModalSize,
    useCloseCallback,
    useFormikField,
    withModal,
    XCloseButton,
} from '@tonkean/infrastructure';
import type { ProjectIntegration, TonkeanQueryDefinition } from '@tonkean/tonkean-entities';
import { anyNonEmptyConditionsInQuery } from '@tonkean/tonkean-utils';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const ModalBodyStyled = styled(ModalBody)`
    overflow: visible;
    z-index: 2;
`;

const specialFieldsForFeatures = ['VIEWER_FIELDS'];

interface Props {
    name: string;
    projectIntegration: ProjectIntegration;
    entityType: string;
}

export const ProjectIntegrationFrontDoorEntityConditions: React.FC<Props> = ({
    name,
    projectIntegration,
    entityType,
}) => {
    const onClose = useCloseCallback();
    const { value, setValue } = useFormikField<TonkeanQueryDefinition | undefined>(name);

    return (
        <Formik
            initialValues={{ conditions: value }}
            onSubmit={({ conditions }) => {
                const hasNonEmptyConditions = anyNonEmptyConditionsInQuery(conditions?.query);
                setValue(hasNonEmptyConditions ? conditions : undefined);
                onClose();
            }}
        >
            <ModalForm>
                <ModalHeader $justifyContent="space-between" $flex>
                    <H3 $color={Theme.colors.gray_700} $bold>
                        {entityType} - Filter Conditions
                    </H3>
                    <XCloseButton size={ButtonSize.MEDIUM} onClick={onClose} />
                </ModalHeader>

                <ModalBodyStyled>
                    <FormikTnkCustomFilters
                        name="conditions"
                        itemsSource="EXTERNAL"
                        projectIntegration={projectIntegration}
                        selectedEntityType={entityType}
                        editMode={false}
                        evaluatePreview={false}
                        specialFieldsForFeatures={specialFieldsForFeatures}
                        includeTabSelectorSpecialFieldsForFeatures={specialFieldsForFeatures}
                        includeViewerFields
                        useExpressionsForValue
                        hideTimeRangeSelection
                        hideCloseButton
                        showFieldInspect
                        fromSync
                    />
                </ModalBodyStyled>
                <ModalFooterActions />
            </ModalForm>
        </Formik>
    );
};

export default withModal(ProjectIntegrationFrontDoorEntityConditions, {
    size: ModalSize.LARGE,
    fixedWidth: true,
    clickOutsideDeactivatesFocusTrap: true,
});
