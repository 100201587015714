import SftpAuthenticationComponent from './SftpAuthenticationComponent';
import SFTPCustomSetupComponent from './SFTPCustomSetupComponent';
import sftpCircleIcon from '../../../../apps/tracks/images/integrations/sftp-circle.jpeg';
import sftpIcon from '../../../../apps/tracks/images/sftp.png';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class SftpIntegration extends IntegrationDefinitionBase {
    override name = 'sftp';
    override displayName = 'SFTP';
    override description = '';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [new IntegrationEntity('File', 'Files'), new IntegrationEntity('Folder', 'Folders')];
    override customizedAuthenticationComponent = SftpAuthenticationComponent;
    override customizedSetupComponent = SFTPCustomSetupComponent;
    override storageProviderSettings = new StorageProviderSettings(true, true);

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(sftpCircleIcon, '130px'),
        new IntegrationIcon(sftpIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: { File: true, Folder: true },
        isOnlyDataRetention: {},
        daysDefault: { free: 7 },
    };
}

export default SftpIntegration;
