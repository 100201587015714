import { analyticsWrapper } from '@tonkean/analytics';

function CreateProductCtrl(
    $scope,
    $log,
    integrations,
    createProjectApis,
    modal,
    entityHelper,
    authenticationService,
    projectManager,
) {
    $scope.integrationGroups = integrations.getIntegrationGroups();
    $scope.state = $scope.state || {};
    $scope.state.integrations = $scope.state.integrations || {};
    $scope.modal = modal;
    $scope.pm = projectManager;

    $scope.$watch('editProject', function () {
        const project = $scope.editProject;
        if (project) {
            $scope.state.organization = project.organization;
            $scope.state.projectName = project.name;
            entityHelper.enrichProject(project);
            $scope.state.integrationState = {};

            for (let i = 0; i < project.integrations.length; i++) {
                const projectIntegration = project.integrations[i];

                $scope.state.integrationState[projectIntegration.integration.integrationUniqueType] = {
                    integrations: [projectIntegration],
                };
            }
        }
    });

    $scope.submit = function () {
        if ($scope.createProduct.$valid && !$scope.editProject) {
            if (!$scope.creatingProject) {
                $scope.creatingProject = true;

                analyticsWrapper.track(`${$scope.editProject ? 'Edit' : 'Create'} Project`, {
                    category: 'Create Project',
                    label: 'Project Page',
                });

                window.Intercom('trackEvent', 'CreateProject', {
                    projectName: $scope.state.projectName,
                    email: authenticationService.currentUser.email,
                });

                createProjectApis
                    .createProject(
                        $scope.state.projectName,
                        $scope.state.organization ? $scope.state.organization.id : null,
                        [],
                    )
                    .then(function (data) {
                        $scope.project = data;
                    })
                    .then(() => {
                        return createProjectApis.getMe().then((freshUser) => {
                            authenticationService.updateCurrentUser(freshUser);
                        });
                    })
                    .catch(function (error) {
                        const errorMessage = $scope.editProject ? 'Failed to edit project' : 'Failed to create project';
                        $scope.$emit('alert', errorMessage);
                        $log.error('Failed to create or edit project', error);
                    })
                    .finally(function () {
                        $scope.creatingProject = false;
                    });
            }
        } else {
            $scope.$emit('alert', 'Please fill all required fields marked in red.');
        }
    };

    // on edit project screen
    $scope.onIntegrationSaved = function () {
        $scope.$emit('alert', { msg: 'Settings saved!', type: 'success' });
    };
}
export default angular.module('tonkean.shared').controller('CreateProductCtrl', CreateProductCtrl);
