import trackEditorOpenFunctionCardModalTemplate from './trackEditorOpenFunctionCardModal.template.html?angularjs';
import { TrackActions } from '@tonkean/flux';
import { DeprecatedDate } from '@tonkean/utils';
import { analyticsWrapper } from '@tonkean/analytics';
import {
    STATUSES,
    FIELD_CREATE_TYPES,
    DEFAULT_COLUMN_WIDTH,
    DUMMY_TRACK_TITLE,
    getFieldDefinitionConfigurationModalSteps,
} from '@tonkean/constants';
import { calculateProgressAndInnerItemWarn } from '@tonkean/tonkean-utils';
import { calculateNextReminderHour, initiativeSyncedWithExternalSource } from '@tonkean/tonkean-utils';

function TracksEditorCtrl(
    $scope,
    $state,
    $stateParams,
    $rootScope,
    $timeout,
    $log,
    $q,
    $filter,
    $window,
    $document,
    $localStorage,
    $location,
    safeDomEvents,
    integrations,
    utils,
    tonkeanService,
    initiativeCache,
    entityHelper,
    trackHelper,
    trackStore,
    modal,
    customFieldsManager,
    groupInfoManager,
    groupPermissions,
    projectManager,
    workflowVersionManager,
    syncConfigCacheManager,
    liveReportHelper,
    authenticationService,
) {
    $scope.pm = $rootScope.pm;
    $scope.as = authenticationService;
    $scope.wvm = workflowVersionManager;
    $scope.gm = groupInfoManager;
    $scope.modal = modal;
    $scope.utils = utils;
    $scope.cfm = customFieldsManager;
    $scope.statuses = STATUSES;
    $scope.fieldCreateTypes = FIELD_CREATE_TYPES;
    $scope.isMobile = $rootScope.isMobile;
    $scope.scm = syncConfigCacheManager;
    $scope.itemMap = initiativeCache.getInitiativesCache();
    $scope.trackHelper = trackHelper;

    $scope.minDate = new Date();
    $scope.todayTime = DeprecatedDate.nowAsDate();

    $scope.placeHolderPos = 'ROOT';

    $scope.defaultColumnWidth = DEFAULT_COLUMN_WIDTH;
    // const ROWS_MAX_BULK_SIZE = 200;

    const popoversPoolMap = {};

    $scope.selected = {};
    $scope.editing = {};
    $scope.latestTitle = {};
    $scope.pasteTip = {};
    $scope.listPopoverIsOpen = {};
    $scope.multiFieldPopoverIsOpen = {};
    $scope.fetchedExternalActivity = {};
    $scope.uiParent = {};
    $scope.hideRow = {};
    $scope.popovers = {}; // Used to store the open state of the tnkTrackListItem popovers.
    $scope.popoversData = {}; // Stores data related to the tnkTrackListItem popovers.
    $scope.setUserOnUpdate = () => {};
    $scope.setUserCurrentlySelected = [];
    $scope.data = {
        items: [],
        atMentioned: [],
        tags: [],
        people: [],
        initativesResults: [],
        externalResults: [],
        combinedResults: [],
        dueDate: {},
        bulkSelectItems: {},
        lastLength: -1,
        dragging: false,
        allowDndOnRoot: $scope.allowDndOnRoot,
        newItemText: $scope.defaultNewItemText,
        hasPermissionsToEditWorker: false,
        isDraft: false,
        quickColumn: {
            // Model for the input in the quickColumn popover
            isOpen: false,
            isLoading: false,
            name: null,
        },
        resize: {
            columnId: null,
            xPos: 0,
            yPos: 0,
            minXPos: 0,
        },

        topViewIndex: -1,
        bottomViewIndex: 50,

        loadingQuickColumn: {},
        quickCreateExpended: 'HIDDENCOL',

        orderBy: { field: 'index', orderType: '', fieldType: '' },
        defaultOrderType: { name: 'ASC', title: 'asc' },

        anyOtherWorkflowVersionsWithFields: false,

        minimizeTitleHeader: $scope.minimizeTitleHeader,
        doNotAllowEditPublishedInitiatives: $scope.doNotAllowEditPublishedInitiatives,

        showHeader: $scope.showHeader,
        stickyHeader: $scope.stickyHeader,

        currentFieldDescriptionPopoverIsOpen: null,
        currentFieldDescriptionPopoverPlacement: 'bottom',
        currentFieldDescriptionPopoverIsLocked: false,

        hideTitle: $scope.hideTitle,
        showActionsColumn: $scope.showActionsColumn,
        workerForms: $scope.workerForms,
        solutionBusinessReportId: $scope.solutionBusinessReportId,
        isInSharedMode: $state.current.name === 'product.processContributorSolutionBusinessReport',
        environment: $stateParams.env || $scope.environment || 'PUBLISHED',
        onlyUpdateExistingItems: $scope.onlyUpdateExistingItems,

        defaultItemInterfaceId: $scope.defaultItemInterfaceId,
        originWidget: $scope.originWidget,
        enableInnerItemsToggle: $scope.enableInnerItemsToggle ?? true,
        showDone: $scope.showDone,
        enableAddingItems: $scope.enableAddingItems ?? true,
        onManualFieldUpdate: $scope.onManualFieldUpdate,
        numerateItems: $scope.numerateItems || false,
        loadingRelatedInitiatives: $scope.loadingRelatedInitiatives,
    };

    $scope.quickColumnManualOptions = [
        { name: 'Text', svgIcon: '/images/icons/text.svg', fieldType: 'Text' },
        {
            name: 'Date',
            svgIcon: '/images/icons/calendar-new.svg',
            fieldType: 'Date',
            displayConfiguration: { displayAs: 'Date' },
        },
        {
            name: 'Number',
            svgIcon: '/images/icons/hashtag.svg',
            fieldType: 'Number',
            displayConfiguration: { displayAs: 'Number' },
        },
        {
            name: 'Percentage',
            cssIcon: 'fa fa-percent',
            fieldType: 'Number',
            displayConfiguration: {
                displayAs: 'Number',
                displayFormat: 'PERCENTAGE',
                displayFormatPrefix: '',
                displayFormatPostfix: '%',
            },
        },
        {
            name: 'Currency',
            cssIcon: 'fa fa-dollar',
            fieldType: 'Number',
            displayConfiguration: {
                displayAs: 'Number',
                displayFormat: 'CURRENCY',
                displayFormatPrefix: '$',
                displayFormatPostfix: '',
            },
        },
        { name: 'Dropdown', svgIcon: '/images/icons/chevron-circle.svg', fieldType: 'List', notConfigured: true },
    ];

    // Extra data needed by the tnkTrackListItem.
    $scope.trackListItemData = {
        editorId: null,
        groupId: null,
        customFields: [],
        allowItemMenuMobile: true,
        quickCreationMode: $scope.quickCreationMode,
        hideColumnQuickCreateForce: $scope.hideColumnQuickCreateForce,
        environment: $scope.data.environment,
        solutionBusinessReportId: $scope.data.solutionBusinessReportId,
        isInSharedMode: $scope.data.isInSharedMode,
    };

    // UI functions needed by the tnkTrackListItem.
    $scope.trackListItemUiActions = {};

    $scope.statusPopoverState = {
        isOpen: false,
    };

    let createForm = document.getElementById(`tracks-item-create-form-${$scope.editorId}`);
    $scope.data.loading = true;

    $scope.data.dummyItemInitialised = false;

    $scope.init = async function () {
        setBasicFields();
        $scope.data.hideTitle = $scope.hideTitle;
        $scope.data.tracks = $filter('filter')($scope.tracks, $scope.filter);

        // Reload order by preferences if exists.
        if ($scope.saveOrderPreference) {
            const orderBy = $localStorage.orderBy && $localStorage.orderBy[$scope.groupId];
            if (orderBy) {
                loadOrderBy(orderBy.field, orderBy.fieldType, orderBy.orderType, false);
            }
        }

        const tracks = $scope.data.tracks;

        if (tracks && !$scope.loading && tracks.length !== $scope.data.lastLength) {
            $scope.data.loading = false;

            $scope.data.lastLength = tracks.length;

            createForm = document.getElementById(`tracks-item-create-form-${$scope.editorId}`);

            $scope.pm.getFunctions();

            if ($scope.placeHolderPos !== 'ROOT') {
                $scope.setSelected(-1, null);
            }

            $scope.data.items = tracks;

            if (!$scope.submittedFromInner) {
                $scope.selectedIndex = tracks.length - 1;
                $scope.data.currentList = tracks;
            }

            if (!$scope.addedNew) {
                $scope.hideAddNew = $scope.tracks.length > 0 || !$scope.showAddOnEmpty;
            }

            $scope.subItem = $scope.parentItem;

            if ($scope.defaultTag && !$scope.data.tags.length) {
                $scope.data.tags.push({ name: $scope.defaultTag });
            }

            initUiParent($scope.data.items);
        }

        initTrackListItemData();
        initTrackListItemUiActions();

        calculateAnyOtherWorkflowVersionsWithFields();

        $scope.data.isDraft =
            $scope.pm.groupsMap?.[$scope.groupId]?.draftWorkflowVersionId === $scope.workflowVersionId;
        $scope.data.hasPermissionsToEditWorker = groupPermissions.isCurrentUserGroupCollaborator(
            $scope.pm.groupsMap[$scope.groupId],
        );

        // Register to destroy event
        $scope.$on('$destroy', function () {
            trackStore.destroyEditor($scope.editorId);
        });

        /**
         * To allow users to create inner items without a title, we must add a dummy item with a unique title, which we will hide later.
         * We only add it if we are in a Collect Inner Items mode ($scope.collectItemsMode) and if there isn't a dummy item already.
         *
         * @see: {@link https://tonkean.atlassian.net/browse/TNKN-6813}
         */
        if (
            $scope.collectItemsMode &&
            $scope.data.items.filter((item) => item.title === DUMMY_TRACK_TITLE).length === 0 &&
            !$scope.hideAddNewForce &&
            !$scope.data.dummyItemInitialised &&
            $scope.data.hasPermissionsToEditWorker &&
            $scope.addEmptyItem
        ) {
            $scope.data.dummyItemInitialised = true;
            $scope.addEmptyItem();
        }

        $scope.data.workerForms = $scope.workerForms;
    };

    $scope.calculateVisibleColumns = function () {
        const fieldDefinitions = $scope.cfm.selectedColumnFieldsMap[$scope.workflowVersionId];

        if (!fieldDefinitions) {
            return [];
        }
        const visibleFromFieldManager = $scope.ignoreColumnVisibility
            ? fieldDefinitions
            : fieldDefinitions.filter(
                  (fieldDefinition) => $scope.filterVisibleFieldDefinitions(fieldDefinition) === false,
              );

        var visibleColumns = $scope.displayFieldsList
            ? $scope.displayFieldsList
                  .map((fieldId) => visibleFromFieldManager.find((field) => field.id === fieldId))
                  .filter(Boolean)
            : visibleFromFieldManager;

        visibleColumns.forEach((visibleColumn) => {
            visibleColumn.integrationType = $scope.getIntegrationType(visibleColumn);
        });

        return visibleColumns;
    };

    $scope.getIntegrationType = function (fieldDef) {
        if (fieldDef.originalSource) {
            return fieldDef.originalSource;
        } else if (fieldDef.projectIntegration && fieldDef.projectIntegration.integrationType) {
            return fieldDef.projectIntegration.integrationType;
        } else if (fieldDef.definition && fieldDef.definition.integrationType) {
            return fieldDef.definition.integrationType;
        } else {
            return undefined;
        }
    };

    $scope.isFieldsExists = function () {
        return $scope.cfm.selectedColumnFieldsMap[$scope.workflowVersionId]?.length > 0;
    };

    /**
     * Calculates the anyOtherWorkflowVersionsWithFields property.
     */
    function calculateAnyOtherWorkflowVersionsWithFields() {
        $scope.data.anyOtherWorkflowVersionsWithFields = false;

        // Creating a set with all fields that belong to workflowVersionId workflow version.
        const currentWorkflowVersionFieldsSet = {};

        if (
            customFieldsManager.selectedFieldsMap[$scope.workflowVersionId] &&
            customFieldsManager.selectedFieldsMap[$scope.workflowVersionId].length
        ) {
            for (let i = 0; i < customFieldsManager.selectedFieldsMap[$scope.workflowVersionId].length; i++) {
                currentWorkflowVersionFieldsSet[customFieldsManager.selectedFieldsMap[$scope.workflowVersionId][i].id] =
                    true;
            }
        }

        // Figuring out if we have anything to link with.
        for (const otherWorkflowVersionId in workflowVersionManager.workflowVersionIdToWorkflowVersionMap) {
            if (
                workflowVersionManager.workflowVersionIdToWorkflowVersionMap.hasOwnProperty(otherWorkflowVersionId) &&
                otherWorkflowVersionId !== $scope.workflowVersionId &&
                customFieldsManager.selectedFieldsMap[otherWorkflowVersionId] &&
                customFieldsManager.selectedFieldsMap[otherWorkflowVersionId].length
            ) {
                // Gathering all the field definitions that were created in the workflow version.
                const relevantFieldsForOtherWorkflowVersion = [];
                for (let i = 0; i < customFieldsManager.selectedFieldsMap[otherWorkflowVersionId].length; i++) {
                    const currentField = customFieldsManager.selectedFieldsMap[otherWorkflowVersionId][i];
                    if (
                        currentField.workflowVersionId &&
                        currentField.workflowVersionId === otherWorkflowVersionId &&
                        !currentWorkflowVersionFieldsSet[currentField.id]
                    ) {
                        relevantFieldsForOtherWorkflowVersion.push(currentField);
                    }
                }

                if (relevantFieldsForOtherWorkflowVersion.length) {
                    $scope.data.anyOtherWorkflowVersionsWithFields = true;
                    break;
                }
            }
        }
    }

    function initTrackListItemData() {
        $scope.trackListItemData.customFields = $scope.customFields;
        $scope.trackListItemData.groupId = $scope.groupId;
        $scope.trackListItemData.workflowVersionId = $scope.workflowVersionId;
        $scope.trackListItemData.editorId = $scope.editorId;
        $scope.trackListItemData.viewOnlyMode = $scope.viewOnlyMode;
        $scope.trackListItemData.onlyUpdateExistingItems = $scope.onlyUpdateExistingItems;
        $scope.trackListItemData.anonymous = $scope.anonymous;
        $scope.trackListItemData.hideAddNewForce = $scope.hideAddNewForce;
        $scope.trackListItemData.uiParent = $scope.uiParent;
        $scope.trackListItemData.parentItem = $scope.parentItem;
        $scope.trackListItemData.selectedColumnFieldsMap = $scope.cfm.selectedColumnFieldsMap;
    }

    function setBasicFields() {
        $scope.data.titleDisplayName = getDisplayNameOfSpecialField('TNK_TITLE') || 'Title';
        $scope.data.dueDateDisplayName = getDisplayNameOfSpecialField('TNK_DUE_DATE') || 'Due date';
        $scope.data.statusDisplayName = getDisplayNameOfSpecialField('TNK_STAGE') || 'Status';
        $scope.data.ownerDisplayName = getDisplayNameOfSpecialField('TNK_OWNER_ID') || 'Owner';
        $scope.data.tagsDisplayName = getDisplayNameOfSpecialField('TNK_TAGS') || 'Tags';
    }

    function initTrackListItemUiActions() {
        /** UI functions needed by the tnkTrackListItem.
         *  Each function should either:
         *  1. Be wrapped with an $apply call, because calls from react won't trigger a digest loop.
         *     Copy paste this and rename the function: $scope.$apply($scope.showRelated.apply(null, arguments));
         *     ('arguments' is a way generically passing the supplied arguments to the called function.)
         *  2. Be wrapped with a function returning the inner function's promise (if the inner function supports promises).
         *     Copy paste this and rename the function: return $scope.showRelated.apply(null, arguments);
         */
        $scope.trackListItemUiActions = {
            showRelated() {
                $scope.$apply($scope.showRelated.apply(null, arguments));
            },
            goToItem() {
                $scope.$apply($scope.goToItem.apply(null, arguments));
            },
            goToGroup() {
                $scope.$apply($scope.goToGroup.apply(null, arguments));
            },
            addUserField() {
                $scope.$apply($scope.addUserField.apply(null, arguments));
            },
            toggleTrackSummaryPopover() {
                $scope.$apply($scope.toggleTrackSummaryPopover.apply(null, arguments));
            },
            toggleDoneItems() {
                const funcArgs = arguments;
                $timeout(function () {
                    $scope.toggleDoneItems.apply(null, funcArgs);
                });
            },
            fieldOpenOrExtend() {
                $scope.$apply($scope.fieldOpenOrExtend.apply(null, arguments));
            },
            fieldOpenGraph() {
                $scope.$apply($scope.fieldOpenGraph.apply(null, arguments));
            },
            askForUpdates() {
                $scope.$apply($scope.askForUpdates.apply(null, arguments));
            },
            setReminder() {
                $scope.$apply($scope.setReminder.apply(null, arguments));
            },
            openEditStatus() {
                $scope.$apply($scope.openEditStatus.apply(null, arguments));
            },
            setSelected() {
                $scope.$apply($scope.setSelected.apply(null, arguments));
            },
            setAddNewAfterItem() {
                $scope.$apply($scope.setAddNewAfterItem.apply(null, arguments));
            },
            sendItemTo() {
                $scope.$apply($scope.sendItemTo.apply(null, arguments));
            },
            removeLinkedInitiative() {
                $scope.$apply($scope.removeLinkedInitiative.apply(null, arguments));
            },
            openSettingsModal() {
                $scope.$apply($scope.openSettingsModal.apply(null, arguments));
            },
            archiveItem() {
                $scope.$apply($scope.archiveItem.apply(null, arguments));
            },
            startBulkSelection() {
                $scope.$apply($scope.startBulkSelection.apply(null, arguments));
            },
            toggleItemBulkSelectState() {
                $scope.$apply($scope.toggleItemBulkSelectState.apply(null, arguments));
            },
            onTextFocus() {
                const funcArgs = arguments;
                $timeout(function () {
                    $scope.onTextFocus.apply(null, funcArgs);
                });
            },
            onTitleChange() {
                // NOTE: If doing $scope.apply() here, we encounter "digest already in progress" error when doing a lot of actions fast.
                // So instead, we fire the on title change separately from the $apply(), and use $timeout() instead to activate digest.
                // This may not be the best solution, and needs to be refactored if problems appear.
                // Commented line below is the old code that also worked.
                $scope.onTitleChange.apply(null, arguments);
                $timeout(() => {});
                // $scope.$apply($scope.onTitleChange.apply(null, arguments));
            },
            tabInTrack() {
                $scope.$apply($scope.tabInTrack.apply(null, arguments));
            },
            tabOutTrack() {
                $scope.$apply($scope.tabOutTrack.apply(null, arguments));
            },
            moveOnItemsIndex() {
                $scope.$apply($scope.moveOnTabIndex.apply(null, arguments));
            },
            updateFieldValue() {
                $scope.$apply($scope.updateFieldValue.apply(null, arguments));
            },
            setSelectedInitiativeOnHover() {
                $scope.$apply($scope.setSelectedInitiativeOnHover.apply(null, arguments));
            },
            onDueDateChange() {
                $scope.$apply($scope.onDueDateChange.apply(null, arguments));
            },
            moveInitiativeInside() {
                $scope.$apply($scope.moveInitiativeInside.apply(null, arguments));
            },
            addDummyIfNeeded() {
                // Being called in a digest cycle so we use $timeout to run after the digest cycle
                $timeout(() => addDummyIfNeeded.apply(null, arguments));
            },
        };
    }

    /**
     * Watching the tracks.length for keeping the data.items.
     * if its empty we still want to init this, so we show an empty list.
     */
    $scope.$watch('tracks.length', function () {
        $scope.data.tracks = $filter('filter')($scope.tracks, $scope.filter);

        if ($scope.data.tracks) {
            $scope.data.items = $scope.data.tracks;

            if (!$scope.submittedFromInner) {
                $scope.selectedIndex = $scope.data.tracks.length - 1;
                $scope.data.currentList = $scope.data.tracks;
            }

            initUiParent($scope.data.items);
        }
    });

    $scope.$watch('defaultFunc', function () {
        $scope.data.tempFunction = $scope.defaultFunc;

        if ($scope.data.atMentioned && !$scope.data.atMentioned.length && $scope.data.tempFunction) {
            $scope.data.atMentioned.push($scope.data.tempFunction);
        }
    });

    $scope.$watch('loading', () => {
        $scope.init();
    });

    $scope.$watch('customFields', function () {
        // Custom fields are loaded asynchronously. If they were loaded successfully we should let the trackListItem know about it.
        // update track list item either way, for values and for no value, because of ability to toggle fields
        initTrackListItemData();
        TrackActions.fieldDefinitionsUpdated();
    });

    $scope.$watch('workflowVersionId', () => {
        $scope.init();
    });

    $scope.$watch('workerForms', function () {
        $scope.init();
    });

    $scope.$watch('fieldsConfigurations', function () {
        setBasicFields();
    });

    $scope.$watch('defaultItemInterfaceId', () => {
        $scope.data.defaultItemInterfaceId = $scope.defaultItemInterfaceId;
    });

    $scope.$watch('showDone', () => {
        if ($scope.data.showDone !== undefined) {
            $scope.data.items?.forEach((item) => {
                $scope.toggleDoneItems(item.id, Boolean($scope.data.showDone));
            });
        }
    });

    $scope.$watch('enableInnerItemsToggle', () => {
        $scope.data.enableInnerItemsToggle = $scope.enableInnerItemsToggle ?? true;
    });

    $scope.$watch('enableAddingItems', () => {
        $scope.data.enableAddingItems = $scope.enableAddingItems ?? true;
    });

    /**
     * Once the user scrolls down the list enough, this will fire and its job is to load more tracks for display in the list.
     */
    $scope.loadMoreTracks = function () {
        // Has more initiatives can be undefined when there was no page requests. in that case we still
        // want to request page.
        if ($scope.loadNextInitiativesPageCallback && $scope.hasMoreInitiatives !== false) {
            $log.debug('Scroll triggered!');
            return $scope.loadNextInitiativesPageCallback();
        }

        return $q.resolve();
    };

    function initUiParent(list, parent) {
        for (const item of list) {
            if (parent) {
                $scope.uiParent[item.id] = $scope.itemMap[parent.id] || parent;
            }
            $scope.latestTitle[item.id] = item.title;

            if (item.expended) {
                // then set all capture inner tracks ui parent
                initUiParent(item.relatedInitiatives, item);
            }
        }

        $timeout($scope.updateViewIds);
    }

    $scope.submit = function (text) {
        const newItemText = text ? text : $scope.data.newItemText;

        if (newItemText && newItemText.length) {
            const newItem = {
                title: newItemText,
                id: $scope.generateID(),
                status: 'FUTURE',
                group: { id: $scope.onlyGroup },
                metadata: {},
                dueDate: $scope.data.dueDate.dueDate,
                defIdToValidFieldsMap: {}, // needed for react
                multipleFieldsMap: {}, // needed for react
                hasRelatedInitiatives: false,
            };
            $scope.addedNew = true;

            // Adding the func/owner set through at mentions
            if (!$scope.data.atMentioned.length && $scope.data.tempFunction) {
                $scope.data.atMentioned.push($scope.data.tempFunction);
            }

            const underItemId = calculateUnderItemId();
            addItemToUI(newItem, underItemId, true, true);
            // actually create the item in the server
            $scope.addItem(newItem, $scope.subItem, underItemId);

            $scope.data.atMentioned = [];
            $scope.data.tags = [];
            $scope.data.dueDate = {};
            $scope.pasteTip.isOpen = false;

            $scope.data.newItemText = $scope.defaultNewItemText || '';

            if ($scope.defaultFunc) {
                $scope.data.tempFunction = $scope.defaultFunc;
            } else if ($scope.subItemMode && $scope.subItem && $scope.subItem.function) {
                $scope.data.tempFunction = $scope.subItem.function;
            } else {
                $scope.data.tempFunction = null;
            }

            if ($scope.defaultTag && !$scope.data.tags.length) {
                $scope.data.tags.push($scope.defaultTag);
            }

            if ($scope.subItemMode) {
                $scope.subItemMode = false;
            }
        }
    };

    $scope.onHeaderPlusClick = function () {
        $state.go('product.workerEditor', {
            g: $scope.groupId,
            t: 'fields',
            field: 'COLUMN',
            fieldCreateStartWithDataSource: 'manual',
        });
    };

    $scope.openAddLinkedColumnModal = function () {
        modal
            .openAddLinkedColumnModal($scope.workflowVersionId)
            .then(() => calculateAnyOtherWorkflowVersionsWithFields());
    };

    $scope.unlinkFieldDefinition = function (fieldDefinitionId) {
        customFieldsManager
            .removeFieldDefinitionToWorkflowVersion($scope.workflowVersionId, fieldDefinitionId)
            .then(() => {
                calculateAnyOtherWorkflowVersionsWithFields();

                groupInfoManager.getGroup($scope.groupId, true);
            });
    };

    // function tryFocus(elementName, interval, retryCount) {
    //     $timeout(() => {
    //         let element = document.getElementById(elementName);
    //         if (element) {
    //             element.focus();
    //         }
    //         else if (retryCount > 0){
    //             retryCount -= 1;
    //             tryFocus(elementName, interval, retryCount);
    //         }
    //     }, interval);
    // }

    $scope.onQuickColumnKeyDown = function ($event) {
        if (utils.isEscapeKey($event)) {
            $scope.closeColumnQuickCreate();
        }
    };

    $scope.closeColumnQuickCreate = function (manualType) {
        $scope.data.quickColumn.name = null;
        $scope.data.quickColumn.isOpen = false;

        if (manualType && manualType.name) {
            $scope.data.loadingQuickColumn[manualType.name] = false;
        } else {
            $scope.data.loadingQuickColumn = {};
        }
    };

    function calculateUnderItemId() {
        // If no related initiative but it's asked to be added under an item
        if ($scope.subItem && (!$scope.subItem.relatedInitiatives || !$scope.subItem.relatedInitiatives.length)) {
            // initialize them
            if (!$scope.subItem.relatedInitiatives) {
                $scope.subItem.relatedInitiatives = [];
            }

            if (!$scope.parentItem || $scope.innerMode) {
                // this means this is going to be the first inner item, so we reset the underItemId
                $scope.underItemId = null;
            }
        }

        // copy the value of $scope.underItemId locally (as it might change during our async calls
        return $scope.underItemId ? angular.copy($scope.underItemId) : null;
    }

    /**
     * Adds items correctly to the ui
     * dont forget to call calculateUnderItemId to get the underItemId
     * @param newItem
     * @param underItemId
     */
    function addItemToUI(newItem, underItemId, createAtBottom, pushToList, parent, dontSetSelected) {
        let createInIndex;
        let itemList;
        if (!dontSetSelected) {
            itemList = getCurrentItemList(newItem);
            if (parent) {
                $scope.uiParent[newItem.id] = $scope.itemMap[parent.id] || parent;
            }
        } else {
            if (parent) {
                itemList = $scope.itemMap[parent.id].relatedInitiatives;
            } else {
                itemList = $scope.data.tracks;
            }
        }

        // add the item to the UI
        if (pushToList) {
            if (!underItemId && createAtBottom) {
                itemList.push(newItem);
            } else {
                if (!underItemId) {
                    newItem.index = 0;
                    createInIndex = 0;
                } else {
                    newItem.index = $scope.itemMap[underItemId].index + 1;
                }

                // find where to insert the item and re-calc indexes
                for (const [i, element] of itemList.entries()) {
                    // add 1 to all the indexes that are bigger then our new item
                    const itarItem = $scope.itemMap[element.id];
                    if (itarItem && itarItem.index >= newItem.index) {
                        itarItem.index += 1;
                        element.index = itarItem.index;
                    }

                    if (element.id === underItemId) {
                        createInIndex = i + 1;
                    }
                }
                itemList.splice(createInIndex, 0, newItem);
            }
        }

        if (!dontSetSelected) {
            // if this is underItemId insert, then update new text box position
            if (underItemId && $scope.selectedItem !== newItem) {
                $scope.selectedItem = newItem;
                $scope.selectedIndex = createInIndex;
            }
            $timeout(function () {
                if (underItemId) {
                    // if this is underItemId insert, then update new text box position
                    $scope.setSelected($scope.selectedIndex + 1, newItem, false, true);
                }
            });

            if (!$scope.subItem) {
                $scope.subItem = $scope.parentItem;
            }
        }
    }

    function getCurrentItemList(newItem) {
        if ($scope.subItem && (!$scope.parentItem || $scope.innerMode)) {
            // && $scope.innerMode) {
            if (newItem) {
                newItem.parent = $scope.subItem;
            }
            $scope.data.currentList = $scope.subItem.relatedInitiatives;
            return $scope.data.currentList;
        } else {
            if ($scope.subItem && !$scope.originWidget) {
                if (newItem) {
                    newItem.parent = $scope.subItem;
                }
                $scope.data.currentList = $scope.subItem.relatedInitiatives;
                return $scope.data.currentList;
            } else {
                $scope.data.items = $scope.data.tracks;
                $scope.data.currentList = $scope.data.items;
                return $scope.data.tracks;
            }
        }
    }

    $scope.updateItem = function (item) {
        analyticsWrapper.track('Update Item', { category: 'Tracks editor' });
        trackHelper
            .updateInitiativeTitle(
                item.id,
                item.tempTitle,
                $scope.createdInFormId,
                $scope.data.isInSharedMode,
                $scope.data.solutionBusinessReportId,
            )
            .then(function (data) {
                item.title = data.title;
                $scope.editing[item.id] = false;
            });
    };

    $scope.deleteItem = function (item, index) {
        if (!item.relatedInitiatives || !item.relatedInitiatives.length) {
            hideDeletedItem(item, index);

            analyticsWrapper.track('Delete Item', { category: 'Tracks editor' });
            tonkeanService.deleteEntity(item.id).then(function () {
                enrich(item);
            });
        } else {
            $scope.questionConfirmModalData = {
                title: 'Delete item',
                body: `The item "${item.title}" and all of its inner items will be deleted. This action is irreversible. Are you sure you want to continue?`,
                okLabel: 'Remove',
                cancelLabel: 'Cancel',
            };

            modal
                .openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning',
                })
                .result.then(function () {
                    // okLabel

                    hideDeletedItem(item, index);

                    analyticsWrapper.track('Delete Item', { category: 'Tracks editor' });
                    tonkeanService.deleteEntity(item.id).then(function () {
                        enrich(item);
                    });
                })
                .catch(function () {
                    // cancelLabel
                    $scope.deleting = false;
                });
        }
    };

    $scope.bulkDelete = function () {
        const bulkSelectedIds = utils
            .objToArray($scope.data.bulkSelectItems)
            .filter((item) => item.value)
            .map((item) => item.key);

        for (const bulkSelectedId of bulkSelectedIds) {
            $scope.itemMap[bulkSelectedId].deleted = true;
        }

        analyticsWrapper.track('Delete Bulk', { category: 'Tracks editor' });

        tonkeanService
            .deleteInitiatives($scope.pm.project.id, bulkSelectedIds)
            .then(() => {
                // Fake scroll to show more items if needed
                onscrollAction(true);
            })
            .catch(() => {
                $rootScope.$emit('alert', {
                    msg: `Failed deleting ${bulkSelectedIds.length} initiatives.`,
                    type: 'error',
                });

                for (const bulkSelectedId of bulkSelectedIds) {
                    $scope.itemMap[bulkSelectedId].deleted = false;
                }
            })
            .finally(() => $scope.finishBulkSelection());
    };

    $scope.bulkArchive = function (archive) {
        const bulkSelected = utils.objToArray($scope.data.bulkSelectItems).filter(function (item) {
            return item.value;
        });
        const action = !archive ? 'Un-Archive' : 'Archive';

        if (bulkSelected.length > 250) {
            $scope.$emit('alert', { msg: `Can't ${action} more than 250 items`, type: 'error' });

            return;
        }

        if (bulkSelected.length) {
            if (bulkSelected.length === 1) {
                $scope.finishBulkSelection();
                return $scope.archiveItem($scope.itemMap[bulkSelected[0].key]);
            }

            const bodyText = `Are you sure you want to ${action} these ${bulkSelected.length} items (and all of their inner items)?`;

            $scope.questionConfirmModalData = {
                title: !archive ? 'Un-' + ' items' : 'Archive items',
                body: bodyText,
                okLabel: action,
                cancelLabel: 'Cancel',
            };

            modal
                .openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning',
                })
                .result.then(function () {
                    // okLabel
                    const keys = [];
                    console.log(archive);
                    for (const item of bulkSelected) {
                        keys.push(item.key);
                        $scope.itemMap[item.key].isArchived = archive;
                    }

                    $scope.finishBulkSelection();

                    // reset new item textbox position
                    $scope.setSelected(-1, null, false, false, true);

                    analyticsWrapper.track('Archive Bulk', { category: 'Tracks editor' });
                    tonkeanService.updateInitiativeArchiveStateBulk($scope.pm.project.id, keys, archive).finally(() => {
                        // Fake scroll to show more items if needed
                        onscrollAction(true);
                    });
                })
                .catch(function () {
                    // cancelLabel
                });
        }
    };

    $scope.bulkRefresh = function () {
        const bulkSelected = utils
            .objToArray($scope.data.bulkSelectItems)
            .filter(function (item) {
                return item.value;
            })
            .map(function (item) {
                return item.key;
            });
        if (bulkSelected.length) {
            if (bulkSelected.length === 1) {
                $scope.finishBulkSelection();
                return $scope.repopulateInitiative(bulkSelected[0], $scope.pm.project.id);
            }

            const bodyText = `Are you sure you want to Refresh these ${bulkSelected.length} items?`;

            $scope.questionConfirmModalData = {
                title: 'Refresh items',
                body: bodyText,
                okLabel: 'Refresh',
                cancelLabel: 'Cancel',
            };

            modal
                .openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning',
                })
                .result.then(function () {
                    // okLabel
                    $scope.finishBulkSelection();

                    // reset new item textbox position
                    $scope.setSelected(-1, null, false, false, true);

                    analyticsWrapper.track('Refresh Bulk', { category: 'Tracks editor' });
                    tonkeanService.repopulateInitiativesBulk(bulkSelected, $scope.pm.project.id);
                })
                .catch(function () {
                    // cancelLabel
                });
        }
    };

    /**
     * Archives all example initiatives.
     */
    $scope.archiveExampleInitiatives = function () {
        const exampleGroupInitiativeIds = [];
        let askUserForPermission = false;

        // Collect the initiative ids of the example initiatives in this group.
        for (const initiativeId in $scope.itemMap) {
            // Check if this initiative is in this group and is an example.
            if (
                $scope.itemMap.hasOwnProperty(initiativeId) &&
                $scope.itemMap[initiativeId].groupId === $scope.groupId &&
                $scope.itemMap[initiativeId].isExample
            ) {
                exampleGroupInitiativeIds.push(initiativeId);

                // If this initiative has inner initiatives that are not examples, we should ask the user if we should delete them.
                if (!askUserForPermission && $scope.itemMap[initiativeId].relatedInitiatives) {
                    for (let i = 0; i < $scope.itemMap[initiativeId].relatedInitiatives.length; i++) {
                        const innerInitiative = $scope.itemMap[initiativeId].relatedInitiatives[i];
                        if (!innerInitiative.isExample) {
                            askUserForPermission = true;
                        }
                    }
                }
            }
        }

        // If the user added inner initiatives to the example initiatives, we should ask for permission to clear them.
        if (askUserForPermission) {
            $scope.questionConfirmModalData = {
                title: 'Are you sure?',
                body: 'Clearing example Items will also clear all Items created under them.',
                okLabel: 'Clear all',
                cancelLabel: 'Cancel',
            };

            modal
                .openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning',
                })
                .result.then(function () {
                    // okLabel
                    archiveExampleInitiativesInner(exampleGroupInitiativeIds);
                })
                .catch(function () {
                    // cancelLabel
                });
        } else {
            archiveExampleInitiativesInner(exampleGroupInitiativeIds);
        }
    };

    function archiveExampleInitiativesInner(exampleGroupInitiativeIds) {
        // Clear the position of the selected and add new initiative form (in case it's in an example initiative).
        $scope.clearSelected();

        trackHelper.bulkArchive(exampleGroupInitiativeIds);
        $scope.pm.groupsMap[$scope.groupId].metadata.displayClearExamples = false;

        // Update server and caches.
        $scope.pm.updateGroupMetadata($scope.groupId, $scope.pm.groupsMap[$scope.groupId].metadata);
    }

    /**
     * Starts the bulk select operation.
     * @param initiatingItemId - the item that caused the bulk select to start and should be marked as selected.
     */
    $scope.startBulkSelection = function (initiatingItemId) {
        $scope.data.bulkSelecting = true;
        if (initiatingItemId) {
            $scope.data.bulkSelectItems[initiatingItemId] = true;
        }
        TrackActions.toggleTracksBulkSelect($scope.editorId, true);
    };

    $scope.finishBulkSelection = function () {
        $scope.data.bulkSelecting = false;
        $scope.data.bulkSelectItems = {};
        // When archived there is no react components.
        if (!$scope.showArchived) {
            // Let the react components know the bulk selection is done.
            TrackActions.toggleTracksBulkSelect($scope.editorId, false);
        }
    };

    $scope.toggleItemBulkSelectState = function (itemId, newState) {
        $scope.data.bulkSelectItems[itemId] = newState;
    };

    $scope.bulkSelectAll = function () {
        for (let i = 0; i < $scope.data.tracks.length; i++) {
            const item = $scope.data.tracks[i];
            $scope.data.bulkSelectItems[item.id] = true;
        }
        TrackActions.bulkSelectAllTracks($scope.editorId);
    };

    $scope.bulkSelectNone = function () {
        $scope.data.bulkSelectItems = {};
        TrackActions.bulkSelectNoTracks($scope.editorId);
    };

    $scope.archiveItem = function (item) {
        if (!item.relatedInitiatives || !item.relatedInitiatives.length) {
            innerArchiveItem(item);
        } else {
            let bodyText = `The item "${item.title}" and all of its inner items will be archived. People that follow this won't get updates anymore. Are you sure you want to continue?`;
            if (item.isArchived) {
                bodyText = `The item "${item.title}" and all of its inner items will be un-archived. Are you sure you want to continue?`;
            }

            $scope.questionConfirmModalData = {
                title: item.isArchived ? 'Un-Archive' : 'Archive item',
                body: bodyText,
                okLabel: item.isArchived ? 'Un-Archive' : 'Archive',
                cancelLabel: 'Cancel',
            };

            modal
                .openQuestionConfirmModal({
                    scope: $scope,
                    windowClass: 'mod-warning',
                })
                .result.then(function () {
                    // okLabel

                    innerArchiveItem(item);
                })
                .catch(function () {
                    // cancelLabel
                });
        }
    };

    function innerArchiveItem(item) {
        item.archived = !item.isArchived;

        // reset new item textbox position
        $scope.setSelected(-1, null, false, false, true);

        analyticsWrapper.track('Archive Item', { category: 'Tracks editor' });
        trackHelper.updateInitiativeArchiveState(item.id, item.archived).then(function (data) {
            copyEntityFields(data, item);
            item.archived = item.isArchived;
            enrich(item);
            // We need to load the related initiatives if we are a sub item. So if we have a ui parent take that,
            // if we dont check if we are inside an initiative view.
            const parent = $scope.uiParent[item.id] || $scope.parentItem;
            if (
                parent && // $scope.uiParent[item.id]
                item.isArchived
            ) {
                trackHelper.updateInnerItemsMetadataOnItemRemoved(parent, item);
            }
        });
    }

    $scope.addItem = function (item, parent, underItemId) {
        $scope.trackingNewTextChanged = false;

        const tags = [];
        for (let i = 0; i < $scope.data.tags.length; i++) {
            const tag = $scope.data.tags[i];

            tag.name = tag.name.replace('#', '').trim();
            if (tag.name.length) {
                tags.push(tag.name);
            }
        }

        let ownerId;
        if (($scope.defaultMe || item.dueDate) && (!$scope.data.atMentioned || $scope.data.atMentioned.length === 0)) {
            ownerId = $scope.as.currentUser.id;
        }

        if (
            !ownerId &&
            (!$scope.data.atMentioned || !$scope.data.atMentioned.length) &&
            !parent &&
            $scope.pm.groupsMap[$scope.groupId] &&
            $scope.pm.groupsMap[$scope.groupId].metadata &&
            $scope.pm.groupsMap[$scope.groupId].metadata.defaultManualOwner
        ) {
            // if root item, has no owner and group has defaultManualOwnerId, use the group's default
            ownerId = $scope.pm.groupsMap[$scope.groupId].metadata.defaultManualOwner.id;
        }

        let due = null;
        if (item.dueDate) {
            due = item.dueDate.getTime();
        }

        if (parent) {
            $scope.itemMap[parent.id].expended = true;
            trackHelper.updateInnerItemsMetadataOnItemAdded($scope.itemMap[parent.id], item);
        }

        // Set the new added item as the selected item.
        // if (!underItemId) {
        //     $scope.setSelected(null, item, false);
        // }

        analyticsWrapper.track('Add Item', {
            category: 'Tracks editor',
            label: parent ? 'Inner item' : 'High level item',
        });

        const trackHelperCallback = function (data) {
            // when return from server
            createCallback(data, item, parent);
        };

        const trackHelperErrorCallback = function () {
            utils.removeFirst($scope.data.items, (singleItem) => singleItem.id === item.id);
            utils.removeFirst($scope.data.tracks, (singleItem) => singleItem.id === item.id);
            utils.removeFirst($scope.data.currentList, (singleItem) => singleItem.id === item.id);
        };

        return trackHelper
            .createInitiative(
                item,
                $scope.pm.project.id,
                parent ? parent.id : null,
                item.title,
                tags,
                $scope.data.atMentioned,
                ownerId,
                underItemId,
                due,
                $scope.onlyGroup,
                trackHelperCallback,
                null,
                trackHelperErrorCallback,
                $scope.createdInWorkerRunInformation,
                null,
                null,
                null,
                null,
                null,
                $scope.createdInFormId,
                $scope.createdInFormName,
                null,
                null,
                $scope.createTracksInEditMode || false,
                $scope.data.isDraft,
                null,
                $scope.data.isInSharedMode,
                $scope.data.solutionBusinessReportId,
            )
            .then(function (data) {
                if ($scope.onInitiativeCreated) {
                    $scope.onInitiativeCreated({ initiative: data, addUnderItemId: underItemId });
                }

                // when return from cache
                return createCallback(data, item, parent);
            });
    };

    function createCallback(data, item, parent) {
        if (data && data.id) {
            item.id = data.id;
            item.dueDate = data.dueDate;
            enrich(item);

            // if we have a parent but it is not the root parent (if we are in initiative view)
            if (parent && (!$scope.parentItem || parent.id !== $scope.parentItem.id)) {
                $scope.uiParent[item.id] = $scope.itemMap[parent.id] || parent;
                enrich($scope.itemMap[parent.id]);
                // make sure the rest of the initiatives under this parent are up to date
                reloadIndexesFromCache(parent.relatedInitiatives);
            }
        }
    }

    $scope.toggleItemStatus = function (item) {
        if (item.status === 'DONE') {
            item.status = 'FUTURE';
        } else {
            item.status = 'DONE';
        }
        analyticsWrapper.track('Toggle status', { category: 'Tracks editor', label: item.status });
        tonkeanService
            .updateInitiativeStatus(
                item.id,
                item.status,
                $scope.data.isInSharedMode,
                $scope.data.solutionBusinessReportId,
            )
            .then(function (data) {
                copyEntityFields(data, item);

                enrich(item);
            });
    };

    $scope.loadNextInnerItemsPage = function (item, forceCollapseInnerItems = false) {
        // before first page request hasMoreInnerItemsToLoad is undefined, but
        // we still want to get more pages. this makes sure that hasMoreInnerItems is
        // actually false and not falsy
        if (item.hasMoreInnerItemsToLoad === false) {
            return;
        }
        item.loadingRelated = true;
        const pageSize = $scope.pm.projectData?.initiativesInnerItemsPageSize || 250;
        const excludeStatuses = item.showCompletedInnerItems || $scope.data.showDone ? [] : ['DONE'];
        return trackHelper
            .loadRelatedInitiatives(item, false, item.relatedInitiatives.length, pageSize, excludeStatuses)
            .then(onInnerItemsLoad.bind(this, item, false, true, forceCollapseInnerItems));
    };

    function onInnerItemsLoad(item, toggle, dontSelect, forceCollapseInnerItems = false) {
        const itemIdsToCountRelated = item.relatedInitiatives.map(({ id }) => id);
        initUiParent(item.relatedInitiatives, item);
        trackHelper.getRelatedInitiativesCount(item.project.id, itemIdsToCountRelated);

        // When filtering done items we might be filtering all inner items, and in that case
        // we need the expended to be false. All other cases it should be true
        item.expended = item.showCompletedInnerItems || item.relatedInitiatives.length > 0;
        item.loadingRelated = false;

        if (!toggle && !dontSelect) {
            $scope.setSelected(
                item.relatedInitiatives.length - 1,
                item.relatedInitiatives[item.relatedInitiatives.length - 1],
            );
        }

        TrackActions.toggleTrackExpanded(item.id, $scope.editorId);

        $timeout($scope.updateViewIds);

        return $q.resolve(item);
    }

    $scope.showRelated = function (item, toggle, dontSelect) {
        item = $scope.itemMap[item.trackId] || item;
        if ($scope.data.enableInnerItemsToggle && item.hasRelatedInitiatives) {
            analyticsWrapper.track('Show Related', { category: 'Tracks editor' });

            // If we didnt load an inner items page and we are trying to open the track
            if (item.hasMoreInnerItemsToLoad === undefined && !item.expended) {
                item.loadingRelated = true;
                return $scope.loadNextInnerItemsPage(item);
            } else {
                if (toggle) {
                    if (!dontSelect && item.expended && !$scope.hideAddNew && $scope.placeHolderPos !== 'ROOT') {
                        // means it's about to be closed, move it to root
                        $scope.setSelected(-1, null, false, false, true);
                    }

                    item.expended = !item.expended;
                } else {
                    item.expended = true;
                }

                if (item.expended && !toggle && !dontSelect) {
                    $scope.setSelected(
                        item.relatedInitiatives.length - 1,
                        item.relatedInitiatives[item.relatedInitiatives.length - 1],
                    );
                }

                TrackActions.toggleTrackExpanded(item.id, $scope.editorId);

                $timeout($scope.updateViewIds);

                return $q.resolve(item);
            }
        } else {
            return $q.resolve(item);
        }
    };

    $scope.toggleDoneItems = function (trackId, showCompletedInnerItems) {
        const cachedInitiative = trackHelper.getInitiativeFromCache(trackId);
        cachedInitiative.relatedInitiatives = [];
        if (cachedInitiative.hasRelatedInitiatives) {
            cachedInitiative.hasMoreInnerItemsToLoad = true;
        }

        if (showCompletedInnerItems !== undefined) {
            cachedInitiative.showCompletedInnerItems = showCompletedInnerItems;
        } else {
            cachedInitiative.showCompletedInnerItems = !cachedInitiative.showCompletedInnerItems;
        }
        $scope.loadNextInnerItemsPage(cachedInitiative);
        $timeout($scope.updateViewIds);
    };

    $scope.onTitleChange = function (item, newTitle) {
        if ($scope.latestTitle[item.id] !== newTitle) {
            /**
             * If the user changes the title of the dummy item, it is no longer dummy, so we must add a new one.
             *
             * @see: {@link https://tonkean.atlassian.net/browse/TNKN-6813}
             */
            addDummyIfNeeded(item, true);

            $scope.latestTitle[item.id] = newTitle;

            analyticsWrapper.track('Update Item Title', { category: 'Tracks editor' });
            trackHelper.updateInitiativeTitle(
                item.id,
                $scope.latestTitle[item.id],
                $scope.createdInFormId,
                $scope.data.isInSharedMode,
                $scope.data.solutionBusinessReportId,
            );
        }
    };

    $scope.onNewTextChange = function () {
        if (!$scope.trackingNewTextChanged) {
            $scope.trackingNewTextChanged = true;
            analyticsWrapper.track('Typing in text editor', { category: 'Tracks editor' });
            $timeout(function () {
                $scope.trackingNewTextChanged = false;
            }, 30_000);
        }
    };

    $scope.onTextFocus = function (item, index) {
        if ($scope.selectedItem) {
            // reset prev
            $scope.selected[$scope.selectedItem.id] = false;
        }

        $scope.selectedIndex = index;
        $scope.selectedItem = item;
        $scope.data.currentList = $scope.uiParent[item.id]
            ? $scope.uiParent[item.id].relatedInitiatives
            : $scope.data.items;
        // note - the .track-item-tabbable element, and the selected element are not the same, so we need to construct it ourselves using the 'tracks-col-' prefix
        $scope.selectedTabbleId = `tracks-col-item-text-${item.id}${$scope.editorId}`;
        $scope.selected[item.id] = true;
    };

    $scope.onNewItemFocus = function () {
        $scope.selectedTabbleId = `theText-${$scope.editorId}`;
        $scope.selected = {};

        $scope.data.newItemFocus = true;

        if (!$scope.trackingNewTextFocused) {
            $scope.trackingNewTextFocused = true;
            analyticsWrapper.track('New item text focused', { category: 'Tracks editor' });
        }
    };

    $scope.onTextKeyDown = function ($event) {
        // console.log($event);
        const mentionOpen = $scope.mentionOpen || $scope.data.linkIsOpen;

        if ($event.code === 'Enter' || $event.keyCode === 13) {
            $event.preventDefault();
            $scope.setSelected($scope.selectedIndex, $scope.selectedItem, true);
        } else if (($event.code === 'ArrowUp' || $event.keyCode === 38) && !mentionOpen) {
            $event.preventDefault();
            $scope.moveOnTabIndex(-1);
        } else if (($event.code === 'ArrowDown' || $event.keyCode === 40) && !mentionOpen) {
            $event.preventDefault();

            $scope.moveOnTabIndex(+1);
        } else if (($event.code === 'Tab' || $event.keyCode === 9) && !$event.shiftKey) {
            $event.preventDefault();
            $scope.tabInTrack();
        } else if (($event.code === 'Tab' || $event.keyCode === 9) && $event.shiftKey) {
            $event.preventDefault();
            $scope.tabOutTrack();
        }
    };

    $scope.onTextKeyUp = function ($event, item) {
        if ($event && $event.target && $event.target.textContent && $event.target.textContent.length) {
            $timeout(function () {
                $scope.onTitleChange(item, $event.target.textContent);
            });
        }
    };

    $scope.onResizeColumnMouseDown = function ($event, fieldDef) {
        const doc = $document[0];
        const bodyBoundingRect = doc.body.getBoundingClientRect();
        const headerContainerBoundingRect = doc
            .getElementById(`tracks-headers-container-${$scope.editorId}`)
            .getBoundingClientRect();
        const columnElement = doc.getElementById(`tracksHeader${fieldDef.id}`);
        const columnElementBoundingRect = columnElement.getBoundingClientRect();
        $scope.data.resize.columnId = fieldDef.id;
        $scope.data.resize.minXPos = columnElementBoundingRect.left - bodyBoundingRect.left + $scope.defaultColumnWidth;
        $scope.data.resize.yPos = headerContainerBoundingRect.top - bodyBoundingRect.top;
        $scope.updateResizeColumnIndicatorXPosition($event.pageX);

        // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36 left: 37, up: 38, right: 39, down: 40,
        const preventScrollWithKeys = (event) => {
            if (event.keyCode >= 32 && event.keyCode <= 40) {
                event.preventDefault();
                return false;
            }
        };

        const mouseMove = (event) => {
            utils.safeApply($scope, () => $scope.updateResizeColumnIndicatorXPosition(event.pageX));

            // prevent scroll through moving the mouse down\up
            event.preventDefault();
            return false;
        };

        const mouseUp = (event) => {
            doc.removeEventListener('mousemove', mouseMove);
            doc.removeEventListener('mouseup', mouseUp);
            doc.removeEventListener('wheel', utils.eventStopAllPropagations);
            doc.removeEventListener('keydown', preventScrollWithKeys);

            $scope.updateResizeColumnIndicatorXPosition(event.pageX);
            // the real new width is where the line is, so add half the width of the box (+padding and border) to the new width
            const newWidth = $scope.data.resize.xPos - columnElementBoundingRect.left + 4;

            if (!$scope.as.currentUser.metadata) {
                $scope.as.currentUser.metadata = {};
            }

            const metadata = $scope.as.currentUser.metadata;
            if (!metadata.columnWidthOverrides) {
                metadata.columnWidthOverrides = {};
            }

            if (!metadata.columnWidthOverrides[$scope.groupId]) {
                metadata.columnWidthOverrides[$scope.groupId] = {};
            }

            const columnConfig = metadata.columnWidthOverrides[$scope.groupId][fieldDef.id];
            if (newWidth !== columnConfig) {
                if (newWidth === $scope.defaultColumnWidth) {
                    if (columnConfig) {
                        delete metadata.columnWidthOverrides[$scope.groupId][fieldDef.id];
                    }
                } else {
                    utils.safeApply(
                        $scope,
                        () => (metadata.columnWidthOverrides[$scope.groupId][fieldDef.id] = newWidth),
                    );
                }

                if (!$scope.as.currentUser.isGuest) {
                    tonkeanService.updateProfileMetadata(metadata);
                }

                TrackActions.editorColumnWidthUpdated($scope.editorId);
            }

            $scope.data.resize.columnId = null;
        };

        doc.addEventListener('mousemove', mouseMove);
        doc.addEventListener('mouseup', mouseUp);
        doc.addEventListener('wheel', utils.eventStopAllPropagations);
        doc.addEventListener('keydown', preventScrollWithKeys);
    };

    $scope.updateResizeColumnIndicatorXPosition = function (newX) {
        if (newX < $scope.data.resize.minXPos) {
            $scope.data.resize.xPos = $scope.data.resize.minXPos;
            return;
        }

        $scope.data.resize.xPos = newX;
    };

    $scope.moveOnTabIndex = function (diff) {
        const currentId = $scope.selectedTabbleId;

        // get all tabable elements (using querySelectorAll since it's potentially faster than getElementsByClassName).
        const tabbables = document.querySelectorAll('.track-item-tabbable:not(.mod-linked)');
        let oldItemId;
        let targetItemId;
        const editorId = $scope.editorId;

        // this is in a variable here cause jhint is annoying about declaring an inline function inside a for-loop
        const setTrackEditMode = function (trackId, mode) {
            // needs to be on angular timeout, cause it will call $apply on focus
            $timeout(function () {
                TrackActions.toggleTrackTitleEdit(trackId, editorId, mode);
            });
        };

        for (let i = 0; i < tabbables.length; i++) {
            // loop through each element
            const el = tabbables[i];
            // console.log(el.value);
            if (el.id === currentId) {
                const newIndex = i + diff;

                if (newIndex > -1 && newIndex < tabbables.length) {
                    oldItemId = el.dataset.itemId;
                    // check if the old tabbable is a track (vs the addItemInput),
                    // if a track then we need to un-check it's title edit mode
                    if (oldItemId) {
                        setTrackEditMode(oldItemId, false);
                    }

                    targetItemId = tabbables[newIndex].dataset.itemId;

                    // check if the target item is a track or the addItemInput
                    if (targetItemId) {
                        setTrackEditMode(targetItemId, true);
                    } else {
                        // means it's the addItemInput
                        document.getElementById(tabbables[newIndex].id).focus();
                    }
                    break;
                }
            }
        }
    };

    $scope.tabInTrack = function () {
        if (($scope.selectedIndex > 0 && $scope.uiParent[$scope.selectedItem.id]) || $scope.data.allowDndOnRoot) {
            // only if allowDndOnRoot than accept null parent

            let list = $scope.data.items;
            if (
                $scope.selectedItem.parent &&
                (!$scope.parentItem || $scope.selectedItem.parent.id !== $scope.parentItem.id)
            ) {
                list = $scope.itemMap[$scope.selectedItem.parent.id].relatedInitiatives;
            }

            // If there is nothing to tab into, we return.
            // if (!list[$scope.selectedIndex - 1]) {
            //     return;
            // }

            let targetParent = null;
            if (list[$scope.selectedIndex - 1]) {
                targetParent = $scope.itemMap[list[$scope.selectedIndex - 1].id];
            }

            if (targetParent) {
                let underId;
                if (targetParent.hasRelatedInitiatives) {
                    underId = targetParent.relatedInitiatives[targetParent.relatedInitiatives.length - 1].id;
                }

                $scope.moveInitiativeInside($scope.selectedItem.id, targetParent, underId);
            }
        }
    };

    $scope.tabOutTrack = function () {
        if (
            $scope.selectedItem.parent &&
            ($scope.uiParent[$scope.selectedItem.id] || (!$scope.parentItem && $scope.data.allowDndOnRoot))
        ) {
            // only if allowDndOnRoot than accept null parent and not inside a track view
            const currentParent = $scope.itemMap[$scope.selectedItem.parent.id];
            const parentParent = currentParent.parent ? $scope.itemMap[currentParent.parent.id] : null;

            if (parentParent || $scope.data.allowDndOnRoot) {
                // only if allowDndOnRoot than accept null parent
                $scope.moveInitiativeOutside($scope.selectedItem.id, parentParent, currentParent.id);
            }
        }
    };

    $scope.onKeyDown = function ($event) {
        // reset the paste tip
        $scope.pasteTip = {};

        const mentionOpen = $scope.mentionOpen || $scope.data.linkIsOpen;
        if (($event.code === 'Tab' || $event.keyCode === 9) && !$event.shiftKey && $scope.data.items.length) {
            $event.preventDefault();
            $scope.setSubItem(true);
        } else if (
            (($event.code === 'Escape' || $event.keyCode === 27) && !mentionOpen) ||
            ($event.shiftKey && ($event.code === 'Tab' || $event.keyCode === 9))
        ) {
            $event.preventDefault();
            $scope.setSubItem(false);
        } else if (($event.code === 'ArrowUp' || $event.keyCode === 38) && !mentionOpen) {
            $scope.selectedIndex = $scope.data.currentList ? $scope.data.currentList.length : 0;
            $scope.onTextKeyDown($event);
        } else if (($event.code === 'ArrowDown' || $event.keyCode === 40) && !mentionOpen) {
            $event.preventDefault();
            // $scope.selectedIndex = $scope.data.currentList.length - 1;
            $scope.onTextKeyDown($event);
        }
    };

    $scope.setSubItem = function (active) {
        let selectedItem;

        // If we don't allow adding subitems
        if ($scope.noSubitems) {
            return;
        }

        // Check if we are on the root, if so the selectedItem is the last item.
        // The second part of the if is only relevant for the initiativeView, so we check if $scope.parentItem is set.
        if (
            !$scope.selectedItem ||
            ($scope.parentItem && $scope.subItem && $scope.selectedItem.id === $scope.subItem.id)
        ) {
            selectedItem = $scope.data.items[$scope.data.items.length - 1];
        } else {
            selectedItem = $scope.selectedItem;
        }

        if (selectedItem && selectedItem.id) {
            selectedItem = $scope.itemMap[selectedItem.id];
        }

        if (active) {
            if (selectedItem && !selectedItem.created) {
                // if designated parent is not created in server yet - ignore.
                return;
            }

            // var index = $scope.selectedIndex;
            $scope.innerMode = true;
            $scope.subItem = selectedItem;

            $scope.data.currentList = $scope.subItem.relatedInitiatives;

            if ($scope.subItem.hasRelatedInitiatives) {
                $scope.subItemMode = false;
                $scope.showRelated($scope.subItem, false, true, true);
            } else {
                $scope.subItemMode = true;
            }
        } else {
            // find parent
            // var parent = selectedItem ? $scope.uiParent[selectedItem.id] : null;
            const parent = $scope.subItem ? $scope.uiParent[$scope.subItem.id] : null;

            if (parent) {
                // var grandParent = $scope.uiParent[parent.id];
                $scope.subItemMode = false;
                $scope.selectedItem = $scope.subItem;
                $scope.subItem = parent; // .relatedInitiatives[parent.relatedInitiatives.length -1];
                // if (!$scope.subItemMode) {
                $scope.data.currentList = parent ? parent.relatedInitiatives : $scope.data.items;
                // $scope.selectedIndex = $scope.data.currentList.length - 1;

                $scope.selectedIndex = utils.indexOf(
                    $scope.data.currentList,
                    (item) => item.id === $scope.selectedItem.id,
                );

                $scope.setSelected($scope.selectedIndex, $scope.selectedItem, false, true);
            } else {
                // means we are moving to root
                const previousSubItem = $scope.subItem;
                let index = -1;

                $scope.innerMode = false;
                $scope.subItemMode = false;
                $scope.subItem = null;
                $scope.data.currentList = $scope.data.items;

                if (previousSubItem) {
                    // if root and there is an item before it, we want it to be set as sibling
                    // so finding it's previous sub item holder index
                    index = utils.indexOf($scope.data.currentList, (item) => item.id === previousSubItem.id);
                }

                // We want to unfocus when we are moving to root but we didnt come from INSIDE the last item.
                const lastItemId = $scope.data.items[$scope.data.items.length - 1].id;
                let unfocus = false;
                // We move to root when $scope.selectedItem is: null OR it is the last item OR if we are in trackview it is the parentItem
                if (
                    (!$scope.selectedItem ||
                        $scope.selectedItem.id === lastItemId ||
                        ($scope.parentItem && $scope.selectedItem.id === $scope.parentItem.id)) && // Make sure the previous sub item wasn't the last one, so we know we don't come from inside it.
                    (!previousSubItem || previousSubItem.id !== lastItemId)
                ) {
                    unfocus = true;
                }

                // set it as sibling
                $scope.setSelected(index, previousSubItem, false, index > -1, unfocus);
            }
        }

        if ($scope.subItem) {
            // set the function default of inner to be it's parent (if exists)
            $scope.data.tempFunction = $scope.subItem.function;
        } else {
            $scope.data.tempFunction = null;
        }
    };

    $scope.setSelected = function (index, item, setSub, setAsSibling, dontFocus) {
        if (!item && index >= 0) {
            item = $scope.data.currentList[index];
        }

        // move the form in the DOM
        let placeholder;
        const attachTo = item && !setSub && !setAsSibling && $scope.uiParent[item.id] ? $scope.uiParent[item.id] : item;

        placeholder = attachTo ? document.getElementById(`create-placeholder-${attachTo.id}${$scope.editorId}`) : null;
        if (!placeholder) {
            placeholder = document.getElementById(`create-placeholder-base-${$scope.editorId}`);
            $scope.placeHolderPos = 'ROOT';
            $scope.innerMode = false;
        } else {
            $scope.placeHolderPos = attachTo.title;
            $scope.innerMode = !setAsSibling; // if setAsSibling is true then we don't want to show it as inner
        }

        if (!createForm) {
            createForm = document.getElementById(`tracks-item-create-form-${$scope.editorId}`);
        }

        if (placeholder && createForm) {
            $scope.selectedItem = item;
            $scope.hideAddNew = false;

            if (index > -1) {
                $scope.selectedIndex = index;
            } else {
                $scope.selectedIndex = $scope.data.currentList.length;
            }
            placeholder.append(createForm);

            if (!dontFocus) {
                $timeout(function () {
                    document.getElementById(`theText-${$scope.editorId}`).focus();
                });
            }

            if (setSub) {
                $scope.setSubItem(true);
            } else {
                $scope.subItem =
                    $scope.selectedItem && $scope.uiParent[$scope.selectedItem.id]
                        ? $scope.uiParent[$scope.selectedItem.id]
                        : $scope.parentItem;
                $scope.subItemMode = false;
            }

            if (setAsSibling && item) {
                $scope.underItemId = item.id;
            } else {
                $scope.underItemId = null;
            }
        }
    };

    $scope.clearSelected = function () {
        // move the form in the DOM
        let placeholder;

        // Get the base placeholder (the one in the tracks editor).
        placeholder = document.getElementById(`create-placeholder-base-${$scope.editorId}`);
        $scope.placeHolderPos = 'ROOT';
        $scope.innerMode = false;

        if (!createForm) {
            createForm = document.getElementById(`tracks-item-create-form-${$scope.editorId}`);
        }

        if (placeholder && createForm) {
            $scope.selectedItem = null;
            $scope.underItemId = null;
            $scope.hideAddNew = false;

            $scope.subItem =
                $scope.selectedItem && $scope.uiParent[$scope.selectedItem.id]
                    ? $scope.uiParent[$scope.selectedItem.id]
                    : $scope.parentItem;
            $scope.subItemMode = false;

            placeholder.append(createForm);
        }
    };

    /** set the add new text box after a specific item **/
    $scope.setAddNewAfterItem = function (item, index) {
        // use index + 1 and true asSibling in setSelected
        $scope.setSelected(index + 1, item, false, true);
    };

    /** set the add new text box after a specific item
     * if index < 0 moving it to the END of the list **/
    $scope.sendItemTo = function (item, index) {
        if (!item) {
            return;
        }

        let reloadAllPromise = $q.resolve();
        if ($scope.reloadAllInitiativesPages && $scope.hasMoreInitiatives) {
            // Marking item as moving.
            TrackActions.trackMoving(item.id, $scope.editorId, true);
            // Fetching all initiatives.
            reloadAllPromise = $scope.reloadAllInitiativesPages({ softUpdate: true });
        }

        return reloadAllPromise.then(() => {
            // $timeout() is important here, since $data.items won't be updated until next digest.
            $timeout(function () {
                let itemList;
                let moveIntoTargetId;
                // find the right list of items
                if ($scope.uiParent[item.id]) {
                    // means it's inside
                    moveIntoTargetId = $scope.uiParent[item.id].trackId;
                    itemList = $scope.uiParent[item.id].relatedInitiatives;
                } else {
                    itemList = $scope.data.items;
                    if (!$scope.data.allowDndOnRoot) {
                        return;
                    }
                }

                if (index < 0 || index > itemList.length) {
                    index = itemList.length;
                }

                // call onDrop, as if the item was dnd
                $scope.onDrop(item.id, index, moveIntoTargetId);

                // Marking item as not moving anymore.
                TrackActions.trackMoving(item.id, $scope.editorId, false);
            });
        });
    };

    $scope.moveInitiativeInside = function (itemId, targetParent, underId, dontMoveNewItemBox) {
        const item = $scope.itemMap[itemId];
        const currentParent = item.parent ? $scope.itemMap[item.parent.id] : null;
        const currentSelectedIndex = $scope.selectedIndex;

        if (!targetParent || itemId === targetParent.id) {
            // invalid target
            return;
        }

        targetParent = $scope.itemMap[targetParent.id];

        $scope.showRelated(targetParent, false, true, true).then(function () {
            // Remove this item from the main tracks list to keep it in sync.
            const index = findById($scope.data.tracks, item.id);
            if (index !== null && index > -1) {
                $scope.data.tracks.splice(index, 1);
            }

            if (!dontMoveNewItemBox) {
                $timeout(function () {
                    const el = document.getElementById(`item-text-${itemId}${$scope.editorId}`);
                    if (el) {
                        el.focus();
                    }
                });

                // Reset new item textbox position (if it's not already reset)
                if ($scope.placeHolderPos !== 'ROOT') {
                    $scope.setSelected(-1, null, false, false, true);
                }
            }

            // Actually do the move.
            trackHelper
                .moveInitiative(item.id, currentParent ? currentParent.id : null, underId, targetParent.id)
                .catch(function () {
                    // Reverting properties that have changed because of the move.
                    $scope.selectedIndex = currentSelectedIndex;
                    $scope.selectedItem.parent = currentParent;

                    if (
                        targetParent && // If it was the first related initiative, we set the expanded property back to false
                        targetParent.relatedInitiatives.length === 0
                    ) {
                        $scope.itemMap[targetParent.id].expended = false;
                    }

                    $rootScope.$broadcast('groupListUpdated', { groupIds: [item.groupId] });
                });

            $scope.selectedIndex = targetParent.relatedInitiatives.length - 1;
            $scope.uiParent[itemId] = targetParent;
            targetParent.expended = true;
        });
    };

    $scope.moveInitiativeOutside = function (itemId, targetParent, underId) {
        const item = $scope.itemMap[itemId];
        const currentSelectedIndex = $scope.selectedIndex;
        const currentParent = item.parent ? $scope.itemMap[item.parent.id] : null;

        if (targetParent && targetParent.id === itemId) {
            // can't move to yourself
            return;
        }

        // Reset new item textbox position (if it's not already reset)
        if ($scope.placeHolderPos !== 'ROOT') {
            $scope.setSelected(-1, null, false, false, true);
        }

        if (targetParent && (!$scope.parentItem || targetParent.id !== $scope.parentItem.id)) {
            // Expand the targetParent and mark it as a UI parent.
            targetParent.expended = true;
            $scope.uiParent[itemId] = $scope.itemMap[targetParent.id];
        } else {
            $scope.uiParent[itemId] = null;
        }

        $timeout(function () {
            const el = document.getElementById(`item-text-${itemId}${$scope.editorId}`);
            if (el) {
                el.focus();
            }
        });

        // actually move it
        trackHelper
            .moveInitiative(
                item.id,
                currentParent ? currentParent.id : null,
                underId,
                targetParent ? targetParent.id : null,
            )
            .catch(function () {
                // Reverting properties that have changed because of the move
                $scope.selectedIndex = currentSelectedIndex;
                $scope.selectedItem.parent = currentParent;

                // Push the item back to the original list
                if (
                    currentParent && // Expanding the list if needed
                    currentParent.relatedInitiatives.length > 0
                ) {
                    $scope.itemMap[currentParent.id].expended = true;
                }

                $rootScope.$broadcast('groupListUpdated', { groupIds: [item.groupId] });
            });

        if (!targetParent || ($scope.parentItem && targetParent.id === $scope.parentItem.id)) {
            const rootList = $scope.data.items;

            // Get the index to put the item under. If we didn't receive an "underId", the index is of the last item in the list.
            const underIdIndex = underId ? findById(rootList, underId) : rootList.length - 1;

            if (underIdIndex > -1 && underIdIndex < rootList.length - 1) {
                // Set the index of the current item.
                item.index = $scope.itemMap[underId].index + 1;

                // Re-index all items in the target list (now that we might have inserted an item).
                for (const element of rootList) {
                    const currentItem = $scope.itemMap[element.id];
                    if (currentItem.index >= item.index) {
                        currentItem.index = currentItem.index + 1;
                    }
                }

                // Push the item to the target list in the found index.
                rootList.splice(underIdIndex + 1, 0, item);
            } else {
                item.index = $scope.itemMap[rootList[rootList.length - 1].id].index + 1;
                rootList.push(item);
            }
        }

        $scope.selectedIndex = item.index;
        // If the old parent doesn't have any other related initiatives, collapse it.
        if (item.index > -1 && !currentParent.relatedInitiatives.length) {
            currentParent.expended = false;
        }
    };

    $scope.onPaste = function ($event) {
        const lines = [];

        if ($event && $event.clipboardData) {
            let pastedText;
            const pastedHtml = $event.clipboardData.getData('text/html');
            if (pastedHtml && pastedHtml.length) {
                pastedText = pastedHtml.replaceAll(/(<([^>]+)>)/gim, '');
            }

            if (!pastedText || !pastedText.length) {
                pastedText = $event.clipboardData.getData('text/plain');
            }

            if (pastedText && pastedText.length) {
                // The currentItemsMap is used to determine whether we need to create a line from the paste or not.
                // We now initialize the map to contain all the items that already exist in the list.
                // Reset the current items map so we know our calculation results are fresh from this paste.
                $scope.currentItemsMap = {};
                const currentList = getCurrentItemList();
                for (const element of currentList) {
                    if (element.title) {
                        // if it exists in the list already its checked
                        $scope.currentItemsMap[element.title] = true;
                    }
                }

                // Now check the pasted values while marking dupes
                let duplicatedCount = 0;
                const splits = pastedText.trim().split('\n');
                if (splits && splits.length) {
                    for (const line of splits) {
                        const lineValue = line ? line.trim() : null;
                        // if we found a valid line
                        if (lineValue && lineValue.length) {
                            // if it already exists in list or we are about to add it dupes++
                            if ($scope.currentItemsMap[lineValue]) {
                                duplicatedCount += 1;
                            } else {
                                $scope.currentItemsMap[lineValue] = true;
                                lines.push(lineValue);
                            }
                        }
                    }
                }

                $scope.pasteTip.duplicatedCount = duplicatedCount;
                if (lines.length > 1 || duplicatedCount > 0) {
                    $scope.pasteTip.lines = lines;
                    $scope.pasteTip.isOpen = true;
                }
            }
        }
    };

    $scope.pasteMultiple = function () {
        if ($scope.pasteTip.lines) {
            $scope.pasteTip.isOpen = false;
            const lines = $scope.pasteTip.lines;
            analyticsWrapper.track('Pasted list', { category: 'Tracks editor' });

            const tempFunction = $scope.data.tempFunction;

            // auto create only if more than one line
            for (const line_ of lines) {
                const line = line_.replace('- ', '').replace('• ', '').replace('* ', '').trim();
                if (line.length) {
                    // make sure the original funcitons (temp/default) are being move forward to the next iteration.
                    $scope.data.tempFunction = tempFunction;

                    // get tags
                    const regex = /#([\w-_]+)(?:$|\W|\s)/g;
                    let tag = regex.exec(line);
                    while (tag) {
                        $scope.data.tags.push({ name: tag[1] });
                        tag = regex.exec(line);
                    }

                    // get functions
                    const regex2 = /@([\w-_]+)(?:$|\W|\s)/g;
                    let func = regex2.exec(line);
                    let funcName;
                    while (func) {
                        funcName = func[1];
                        let funcitem;
                        if (!$scope.pm.functionMap[funcName]) {
                            $scope.pm.functionMap[funcName] = { name: funcName };
                            funcitem = { name: funcName, isNew: true };
                        } else {
                            funcitem = $scope.pm.functionMap[funcName];
                        }
                        $scope.data.atMentioned.push(funcitem);

                        func = regex2.exec(line);
                    }

                    $scope.submit(line);
                }
            }
            $timeout(function () {
                $scope.pasteTip = {};
                $scope.data.newItemText = '';
            });
        }
    };

    $scope.onDueDateChange = function (itemId, date) {
        analyticsWrapper.track('Changed due date', { category: 'Tracks editor' });
        trackHelper
            .updateDueDate(
                itemId,
                date,
                true,
                $scope.createdInFormId,
                undefined,
                $scope.data.isInSharedMode,
                $scope.data.solutionBusinessReportId,
            )
            .then(() => addDummyIfNeeded(utils.findFirstById($scope.tracks, itemId)));
    };

    /**
     * Opens the initiative's settings modal.
     * @param initiative - the initiative for which we want to open the next gather update settings modal.
     * @param showSetReminder - whether to show the set reminder option or not.
     * @param analyticsCategory - If supplied, an analytics event will be recorded with this given category.
     */
    $scope.openSettingsModal = function (initiative, showSetReminder, analyticsCategory) {
        if (analyticsCategory && analyticsCategory.length) {
            analyticsWrapper.track('Set reminder', { category: analyticsCategory });
        }

        $scope.initiativeClicked = initiative;
        modal.openInitiativeSettingsModal(initiative, showSetReminder);
    };

    /**
     * Opens, if needed, the update status modal.
     * In some cases, it would just do the update itself and not go through the modal.
     */
    $scope.openEditStatus = function (initiative, hideActivity, state) {
        // If edit status modal is already opened, we do nothing.
        if ($scope.openEditStatusOpen) {
            return;
        }

        if (!state || state.type !== 'DONE') {
            // If state isn't given or state is given and isn't done, we open the regular update status modal.
            openFunctionCardModal(initiative, hideActivity, state);
        } else {
            trackHelper
                .updateInitiativeState(
                    initiative,
                    state,
                    $scope.createdInFormId,
                    undefined,
                    undefined,
                    undefined,
                    $scope.data.isInSharedMode,
                    $scope.data.solutionBusinessReportId,
                )
                .then(() => addDummyIfNeeded(initiative));

            if (state.type === 'DONE' && initiativeSyncedWithExternalSource(initiative)) {
                // If state is done, and the initiative is synced, we check do we need to open the modal for the update external status step,
                // or, if the user chose to remember his choice, we need to update the external status as well.
                if (
                    $scope.as.currentUser.metadata.externalStatusUpdateSettings &&
                    $scope.as.currentUser.metadata.externalStatusUpdateSettings.automaticallyUpdateExternalStatus &&
                    $scope.as.currentUser.metadata.externalStatusUpdateSettings[
                        initiative.externalSource + initiative.stateText
                    ] &&
                    !(
                        $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain &&
                        $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain[
                            initiative.externalSource + initiative.stateText
                        ]
                    )
                ) {
                    // If use chose to remember his choice, we just update the status in the external system as well.
                    trackHelper
                        .updateInitiativeExternalStatus(
                            initiative.id,
                            $scope.as.currentUser.metadata.externalStatusUpdateSettings[
                                initiative.externalSource + state.label
                            ].statusId,
                        )
                        .then(() => {
                            $scope.$emit('alert', {
                                msg: `Successfully updated status in ${initiative.externalSourceFriendly}!`,
                                type: 'success',
                            });
                        })
                        .catch(() => {
                            $scope.$emit('alert', {
                                msg: 'There was an error trying to update external status in external system.',
                                type: 'danger',
                            });
                        });
                } else if (
                    !$scope.as.currentUser.metadata.externalStatusUpdateSettings ||
                    (!$scope.as.currentUser.metadata.externalStatusUpdateSettings[
                        initiative.externalSource + initiative.stateText
                    ] &&
                        !(
                            $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain &&
                            $scope.as.currentUser.metadata.externalStatusUpdateSettings.doNotAskAgain[
                                initiative.externalSource + initiative.stateText
                            ]
                        ) &&
                        initiativeSyncedWithExternalSource(initiative) &&
                        integrations.initiativeSupportsExternalStatusChange(initiative))
                ) {
                    // Otherwise, we open the edit status modal, but we skip right through to the follow-up steps.
                    openFunctionCardModal(initiative, hideActivity, state, true);
                }
            }
        }
    };

    /**
     * Opens the update status modal.
     */
    function openFunctionCardModal(initiative, hideActivity, state, skipUpdateTextStep) {
        $scope.initiativeClicked = initiative;
        $scope.funcSelectedState = state;
        $scope.funcHideActivity = hideActivity;
        $scope.skipUpdateTextStep = skipUpdateTextStep;

        analyticsWrapper.track('Update status modal', { category: 'Tracks editor' });

        const modalFunctionCard = modal.open({
            template: trackEditorOpenFunctionCardModalTemplate,
            scope: $scope,
            backdrop: 'static',
        });
        modalFunctionCard.result
            .then(() => addDummyIfNeeded(initiative))
            .finally(function () {
                $scope.openEditStatusOpen = null;
            });
        $scope.openEditStatusOpen = modalFunctionCard;
    }

    $scope.askForUpdates = function (owner, item) {
        if (!owner.isTemporary) {
            // console.log(name);
            analyticsWrapper.track('Ask for updates', { category: 'Tracks editor' });
            const name = owner.name;
            modal.openAskForUpdateModal(item, name);
        }
    };

    $scope.onAddFunction = function (data) {
        analyticsWrapper.track('Add owner', { category: 'Tracks editor', label: data.owner ? 'owner' : 'function' });
    };

    $scope.updateInitiativeData = function (data) {
        if ($scope.openEditStatusOpen) {
            $scope.openEditStatusOpen.close();

            $scope.openEditStatusOpen = null;
        }

        const item = $scope.initiativeClicked;
        utils.copyEntityFields(data, item, { relatedInitiatives: true, parentInitiatives: true, parent: true });
        enrich(item);
        TrackActions.trackStatusUpdated(item.id);
    };

    $scope.selectItem = function (item, $event) {
        if (!$event.shiftKey) {
            $scope.selected = {};
        } else {
            $event.preventDefault();
        }
        $scope.selected[item.id] = true;
    };

    /**
     * Opens the field definition modal for creating key metric out of existing column.
     */
    $scope.openFieldDefinitionModalForCreatingKeyMetric = function (columnFieldDefinition) {
        if (!columnFieldDefinition) {
            return;
        }

        const fieldDefinition = {
            type: 'TNK_COLUMN_AGGREGATE',
            definition: {
                aggregationType: 'Sum',
                aggregatedDefinitionId: columnFieldDefinition.id,
                aggregatedDefinitionName: columnFieldDefinition.name,
                groupId: $scope.groupId,
            },
        };

        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            'GLOBAL',
            'TNK_COLUMN_AGGREGATE',
            columnFieldDefinition.projectIntegration,
            false,
            true,
            fieldDefinition,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            $scope.workflowVersionId,
        ]);
    };

    /**
     * Opens the field definition creation modal with the same definition (duplication in same list).
     */
    $scope.duplicateMetricInSameGroup = function (fieldDefinition) {
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            fieldDefinition.targetType,
            fieldDefinition.type,
            fieldDefinition.projectIntegration,
            false,
            true,
            fieldDefinition,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            $scope.workflowVersionId,
        ]);
    };

    /**
     * Opens the copy to another list modal.
     */
    $scope.openDuplicateMetricModal = function (fieldDefinition) {
        modal.openDuplicateFieldDefinitionModal(fieldDefinition, $scope.pm.groupsMap[$scope.groupId]);
    };

    /**
     * Repopulating field definition for all tracks in list.
     */
    $scope.repopulateField = function (fieldDefinition) {
        tonkeanService
            .repopulateFieldDefinition($scope.workflowVersionId, fieldDefinition.id)
            .then(() => {
                $scope.$emit('alert', { msg: 'Field refreshed successfully', type: 'success' });
            })
            .catch(() => $scope.$emit('alert', { msg: 'Error occurred while refreshing Field', type: 'warning' }));
    };

    $scope.goToItem = function (item, $event, openInNewTab) {
        const encodedSearchInitiativesQuery = encodeURI(
            btoa(encodeURIComponent(JSON.stringify($scope.searchInitiativesQuery))),
        );

        analyticsWrapper.track('Open Item', { category: 'Tracks editor' });

        if ($scope.overrideItemClick) {
            $scope.overrideItemClick(item);
            if ($event) {
                $event.preventDefault();
            }
            return;
        }

        if ($stateParams.initiativeId) {
            // in full page, we want to stay in full page
            if ($scope.defaultItemInterfaceId) {
                switch ($state.current.name) {
                    case 'product.customInterfaceView': {
                        if ($scope.originWidget) {
                            $location.search('originWidget', $scope.originWidget);
                        } else {
                            $location.search('originWidget', undefined);
                        }
                        $location.search('initiativeId', item.id);
                        break;
                    }
                    case 'product.board': {
                        $state.go('product.interface.group', {
                            groupId: $state.params.g,
                            initiativeId: item.id,
                            originWidget: $scope.originWidget,
                            searchInitiativesQuery: encodedSearchInitiativesQuery,
                        });

                        break;
                    }
                    case 'product.solution-business-report': {
                        $state.go('product.interface.report', {
                            solutionBusinessReportId: $state.params.solutionBusinessReportId,
                            groupId: item.groupId,
                            initiativeId: item.id,
                            originWidget: $scope.originWidget,
                            searchInitiativesQuery: encodedSearchInitiativesQuery,
                        });

                        break;
                    }
                    case 'product.itemInterfaceBuilderPreview': {
                        $state.go(
                            '.',
                            {
                                initiativeId: item.id,
                                originWidget: $scope.originWidget,
                                searchInitiativesQuery: encodedSearchInitiativesQuery,
                            },
                            { notify: false },
                        );

                        break;
                    }
                    case 'product.interface.group': {
                        $state.go(
                            '.',
                            {
                                initiativeId: item.id,
                                originWidget: $scope.originWidget,
                                projectId: projectManager.project.id,
                                searchInitiativesQuery: encodedSearchInitiativesQuery,
                            },
                            { notify: false },
                        );

                        break;
                    }
                    default: {
                        break;
                    }
                }
            } else {
                $state.go('product.interface.group', {
                    groupId: item.groupId,
                    initiativeId: item.id,
                    projectId: projectManager.project.id,
                });
            }
        } else {
            if (openInNewTab || $state.current.name === 'form') {
                let url = $state.href('product.interface.group', {
                    groupId: item.groupId,
                    initiativeId: item.id,
                    projectId: projectManager.project.id,
                });

                if ($scope.defaultItemInterfaceId) {
                    switch ($state.current.name) {
                        case 'solution-site': {
                            url = $state.href('product.interface.group', {
                                projectId: projectManager.project.id,
                                groupId: item.groupId,
                                initiativeId: item.id,
                                originWidget: $scope.originWidget,
                                searchInitiativesQuery: encodedSearchInitiativesQuery,
                            });
                            break;
                        }
                        case 'form':
                        case 'product.solutionSiteBuilderPreview': {
                            url = $state.href('product.interface.group', {
                                projectId: projectManager.project.id,
                                groupId: item.groupId,
                                initiativeId: item.id,
                                originWidget: $scope.originWidget,
                                searchInitiativesQuery: encodedSearchInitiativesQuery,
                            });
                            break;
                        }
                        case 'product.board': {
                            url = $state.href('product.interface.group', {
                                groupId: $state.params.g,
                                initiativeId: item.id,
                                env: $scope.data.isDraft ? 'DRAFT' : 'PUBLISHED',
                                originWidget: $scope.originWidget,
                                searchInitiativesQuery: encodedSearchInitiativesQuery,
                            });
                            break;
                        }
                        case 'product.solution-business-report': {
                            url = $state.href('product.interface.report', {
                                solutionBusinessReportId: $state.params.solutionBusinessReportId,
                                groupId: item.groupId,
                                initiativeId: item.id,
                                env: $scope.data.isDraft ? 'DRAFT' : 'PUBLISHED',
                                originWidget: $scope.originWidget,
                                searchInitiativesQuery: encodedSearchInitiativesQuery,
                            });

                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }

                $window.open(url, '_blank');
            } else {
                if ($scope.originWidget) {
                    $location.search('originWidget', $scope.originWidget);
                } else {
                    $location.search('originWidget', undefined);
                }

                $location.search('tid', item.id);
                $location.search('searchInitiativesQuery', encodedSearchInitiativesQuery);
            }

            if ($event) {
                $event.preventDefault();
            }
        }
    };

    $scope.goToGroup = function (id) {
        analyticsWrapper.track('Go to list of linked item', { category: 'Tracks editor' });
        $state.go('product.board', { filter: 'all', st: null, g: id, listFilter: null, isGroupListFilter: null });
    };

    $scope.hideCreate = function () {
        if ($scope.placeHolderPos && $scope.placeHolderPos !== 'ROOT') {
            $scope.setSelected(-1);
        }
        $scope.data.newItemText = '';
        $scope.hideAddNew = true;
        $scope.data.newItemFocus = false;
    };

    $scope.getDndMarker = function () {
        // The drag-and-drop marker that appears only when the user is dragging between the dragged items.
        return '<li class="tnkDndMarker"><span class="tnkDndMarker-bullet"></span></li>';
    };

    $scope.getDndPlaceholder = function (itemId) {
        // Get the item from cache so we can use its title.
        const track = trackHelper.getInitiativeFromCache(itemId);

        // The drag-and-drop placeholder that is placed instead of the currently dragged element.
        return `<li class="tnkDndPlaceholder"><div>${track.title}</div></li>`;
    };

    /**
     * In the dnd-drop callback, we now have to handle the data array that we
     * sent above. We handle the insertion into the list ourselves. By returning
     * true, the dnd-list directive won't do the insertion itself.
     * @param itemId - the item id of the item being dropped.
     * @param index - the index in the new list. Might be -1.
     * @param moveIntoTargetId - an optional parameter that can hold the id of the item the current item is dropped into, i.e "the new parent".
     *                           Might not be available in cases such as dropping at root level, etc.
     * @param isDropInto - when set to true, the action is considered is a drop-into another track (rather then between tracks).
     *                     This is different than re-ordering tracks inside their parent.
     *                     Drop into is marked differently in the UI and has a differnt meanning.
     * @returns {boolean}
     */
    $scope.onDrop = function (itemId, index, moveIntoTargetId, isDropInto) {
        if (trackHelper.isTheInitiativeBeingMoved(itemId)) {
            return false;
        }

        analyticsWrapper.track('Drag and drop', { category: 'Tracks editor' });

        let targetListCopy = null;
        if (moveIntoTargetId) {
            targetListCopy = trackHelper.getInitiativeFromCache(moveIntoTargetId).relatedInitiatives || [];
        } else {
            targetListCopy = $filter('filter')($scope.data.items, $scope.filter);
        }

        const item = trackHelper.getInitiativeFromCache(itemId);
        const currentIndex = item.index;
        const currentSelectedIndex = $scope.selectedIndex;
        const currentParent = item.parent ? $scope.itemMap[item.parent.id] : null;
        const currentScopeParentItem = $scope.parentItem;
        let targetParent;

        if (item && index > -1) {
            const oldParentId = item.parent ? item.parent.id : null;

            // Don't allow dropping an item inside his own parent.
            if (isDropInto && moveIntoTargetId && oldParentId && moveIntoTargetId === oldParentId) {
                return false;
            }

            // reset new item textbox position
            $scope.setSelected(-1, null, false, false, true);

            // Try to find the moved item in the list it was dropped into, and tell if this is a reorder or not.
            let oldIndex = -1;
            let isReorder = false;

            // Going over the target list copy, so if we find the item we know it's a reorder.
            for (const [i, element] of targetListCopy.entries()) {
                if (element.id === itemId) {
                    if (!isDropInto) {
                        // If we found the moved item in the list and it's not a drop into another parent, this is a reorder.
                        isReorder = true;
                    }
                    // Save the old index of the moved item, so we can later splice it if needed.
                    oldIndex = i;
                    break;
                }
            }

            if (!isReorder) {
                // If this is not a reorder, the oldIndex should be the index in the old list and not the target list.
                const oldList = currentParent ? currentParent.relatedInitiatives || [] : $scope.data.items;
                for (const [i, element] of oldList.entries()) {
                    if (element.id === itemId) {
                        oldIndex = i;
                        break;
                    }
                }
            }

            if ((isReorder && oldIndex !== index) || oldIndex > -1 || moveIntoTargetId || isDropInto) {
                // Sibling refers to the first or last sibling in the list the item is moved to.
                // When dropping item inside another one, it might not have siblings.
                let sibling = null;
                if (targetListCopy && targetListCopy.length) {
                    const siblingId = index === 0 ? targetListCopy[targetListCopy.length - 1].id : targetListCopy[0].id;
                    sibling = $scope.itemMap[siblingId];
                }

                // moveIntoTargetId is an optional parameter that tells us explicitly who the targetParent is.
                if (!moveIntoTargetId) {
                    // If this is a move (and not a re-order) and the dropped item will have a sibling in the new list he's moving to.
                    if (!isReorder && sibling) {
                        // The sibling might not have a parent if he is in root level.
                        if (sibling.parent) {
                            targetParent = trackHelper.getInitiativeFromCache(sibling.parent.id);
                        }
                    } else {
                        // This item might not have a parent if he came from root level.
                        if (item.parent) {
                            targetParent = trackHelper.getInitiativeFromCache(item.parent.id);
                        }
                    }
                } else {
                    targetParent = trackHelper.getInitiativeFromCache(moveIntoTargetId);
                }

                const targetParentId = targetParent ? targetParent.id : null;

                // ****** Link initiative checks ******

                // If we are dropping the item inside a parent (creating a sub-item).
                if (
                    targetParent && // Do not allow dropping an item inside a parent where one of the children is the same item (might be a linked item, or the other way around).
                    isDropInto &&
                    targetParent.relatedInitiatives &&
                    utils.findFirstCompareProperties(targetParent.relatedInitiatives, item, 'trackId') &&
                    !isReorder
                ) {
                    return false;
                }

                // If we are dropping the linked item at root level, only allow it if the item we're linked to isn't at root level too.
                if (!targetParent && !isReorder) {
                    for (let i = 0; i < $scope.data.items.length; i++) {
                        if ($scope.data.items[i].trackId === item.trackId) {
                            return false;
                        }
                    }
                }

                // ************************************

                let isRecursive;
                if (targetParent && targetParent.parentInitiatives && targetParent.parentInitiatives.length) {
                    // verify it's not recursive
                    for (let pIndex = 0; pIndex < targetParent.parentInitiatives.length; pIndex++) {
                        if (targetParent.parentInitiatives[pIndex].id === itemId) {
                            isRecursive = true;
                        }
                    }
                }

                if (targetParentId !== itemId && !isRecursive) {
                    $scope.itemMoved = !isReorder;
                    if (!isReorder) {
                        $scope.itemMovedFromParentId = oldParentId;
                    }

                    // There are 3 cases we should handle, that the trackHelper won't handle for us.
                    // The trackHelper moveInitiative function knows how to handle the parents we move from/to but not the root list
                    // since it doesn't have that list (only we do). The cases:
                    // 1. Moving FROM the root list to a parent.
                    // 2. Moving To   the root list from a parent.
                    // 3. Reorder in root list.

                    // Set up the flags we need for the 3 cases.
                    let movingToRoot = true;
                    let targetList = null;
                    if (targetParentId && (!$scope.parentItem || targetParentId !== $scope.parentItem.id)) {
                        targetList = $scope.itemMap[targetParentId].relatedInitiatives || [];
                        movingToRoot = false;
                    } else {
                        targetList = $scope.data.items;
                        movingToRoot = true;
                    }

                    let movingFromRoot = true;
                    if (currentParent && (!$scope.parentItem || currentParent.id !== $scope.parentItem.id)) {
                        movingFromRoot = false;
                    }

                    if (movingFromRoot) {
                        // Case 1.
                        // We need to remove the item from the root list. The trackHelper will add it to the new parent.
                        $scope.data.items.splice(oldIndex, 1);
                        // If we removed an item from before the desired index, decrement the index by 1 (in re-order).
                        if (isReorder && index > oldIndex) {
                            index = index - 1;
                        }
                    }

                    if (movingToRoot) {
                        // Case 2.
                        // We need to add the item to the root list. The trackHelper will remove it from the parent.
                        // Set the item's new index.
                        item.index = index > 0 ? $scope.itemMap[$scope.data.items[index - 1].id].index + 1 : 0;

                        // Update the indices in the list accordingly.
                        for (let j = 0; j < $scope.data.items.length; j++) {
                            const currentItem = $scope.itemMap[$scope.data.items[j].id];
                            if (currentItem.index >= item.index) {
                                currentItem.index = currentItem.index + 1;
                            }
                        }

                        // add the item to the new place
                        $scope.data.items.splice(index, 0, item);
                    }
                    // Case 3 is when cases 1 and 2 happen (re-order is like moving from root to root). So no need to do anything.

                    let underId = null;
                    if (index > 0 && targetList.length) {
                        // Find the under id in the list we're gonna drop the item to.
                        underId = targetList[index - 1].id;
                    }

                    // Dropped to the same position
                    if (underId === itemId) {
                        return false;
                    }

                    if (!isReorder) {
                        item.parent = targetParentId ? $scope.itemMap[targetParentId] : null;
                        item.parentInitiatives = sibling
                            ? sibling.parentInitiatives
                            : item.parent
                              ? [item.parent]
                              : null;
                        $scope.uiParent[itemId] =
                            !$scope.parentItem || targetParentId !== $scope.parentItem.id ? item.parent : null;

                        if (item.parent) {
                            item.parent.innerItemWarn = calculateProgressAndInnerItemWarn(item.parent).innerItemWarn;
                        }
                        TrackActions.trackRelatedItemsUpdated(targetParentId);
                        $scope.updateViewIds();
                    }

                    $timeout(function () {
                        trackHelper
                            .moveInitiative(
                                item.id,
                                currentParent ? currentParent.id : null,
                                underId,
                                targetParentId,
                                true,
                                undefined,
                                $scope.editorId,
                            )
                            .then(function () {
                                if (movingToRoot) {
                                    reloadIndexesFromCache($scope.data.items);
                                    $scope.data.tracks = $scope.data.items;
                                }
                                $scope.updateViewIds();
                            })
                            .catch(function () {
                                // Reverting properties that have changed because of the move
                                item.index = currentIndex;
                                item.parent = currentParent;
                                $scope.selectedIndex = currentSelectedIndex;

                                if (
                                    currentParent && // If the parent has more than 0 items, it should be expanded
                                    currentParent.relatedInitiatives.length > 0
                                ) {
                                    $scope.itemMap[currentParent.id].expended = true;
                                }

                                if (
                                    targetParent && // If the item was the first related initiative of the parent, we set the expended property back to false
                                    targetParent.relatedInitiatives.length === 0
                                ) {
                                    $scope.itemMap[targetParent.id].expended = false;
                                }

                                // If it doesn't have a current parent, and it has a target parent,
                                // that means it was taken out of root level and we need to push it back to root level.
                                if (!currentParent && targetParent) {
                                    $scope.parentItem = currentScopeParentItem;
                                    item.parentInitiatives = null;
                                    $scope.data.items.push(item);
                                }

                                $rootScope.$broadcast('groupListUpdated', { groupIds: [item.groupId] });
                            });
                    });

                    return true;
                } else {
                    $scope.itemMoved = false;
                    return false;
                    // / can't drop into self
                }
            }
        } else {
            return false;
        }
    };

    /**
     * Finalize the drop transaction. This function is called by the dnd framework.
     */
    $scope.onMoved = function () {
        $timeout(() => {
            if ($scope.itemMoved) {
                // If we moved the item from a parent and the parent has no more children, collapse it.
                if (
                    $scope.itemMovedFromParentId &&
                    !$scope.itemMap[$scope.itemMovedFromParentId].relatedInitiatives.length
                ) {
                    $scope.itemMap[$scope.itemMovedFromParentId].expended = false;
                }

                const movedFromParent = $scope.itemMap[$scope.itemMovedFromParentId];
                if (movedFromParent) {
                    movedFromParent.innerItemWarn = calculateProgressAndInnerItemWarn(movedFromParent).innerItemWarn;
                }

                TrackActions.trackRelatedItemsUpdated($scope.itemMovedFromParentId);
                $scope.updateViewIds();

                $scope.itemMoved = false;
                $scope.itemMovedFromParentId = null;
            }
        });
    };

    $scope.onDragStart = function (itemId) {
        $scope.data.dragging = true;
        $scope.data.draggingItem = itemId;
        // Clear all popovers.
        $scope.popovers = {};
        $scope.popoversData = {};
        // Clear add new item.
        $scope.clearSelected();
    };

    $scope.onDragEnd = function () {
        $scope.data.dragging = false;
        $scope.data.draggingItem = null;
    };

    $scope.getSize = function (obj) {
        return Object.keys(obj).length;
    };

    $scope.unFocusNewItem = function () {
        $scope.data.newItemFocus = false;
        if (
            $scope.data.newItemText &&
            $scope.data.newItemText.length &&
            (!$scope.defaultNewItemText || $scope.data.newItemText !== $scope.defaultNewItemText)
        ) {
            $scope.submit($scope.data.newItemText);
        }
    };

    $scope.onAtMentionOpen = function (open) {
        $scope.mentionOpen = open;
    };

    $scope.resetFieldDescriptionPopoverIsOpenMap = function () {
        $scope.data.currentFieldDescriptionPopoverIsOpen = null;
    };

    $scope.setFieldDescriptionPopoverIsOpen = function (fieldDefId, isOpen, placement, shouldLock) {
        // We have a lock mechanism when the user clicks the header, making the popover clickable
        // while not running the mouseenter\mouseexit events
        if (shouldLock) {
            // The lock cant happen twice, so if the header is clicked twice that means we need to toggle
            if ($scope.data.currentFieldDescriptionPopoverIsLocked) {
                $scope.data.currentFieldDescriptionPopoverIsLocked = null;
                isOpen = false;
            } else {
                $scope.data.currentFieldDescriptionPopoverIsLocked = fieldDefId;
            }
        } else if ($scope.data.currentFieldDescriptionPopoverIsLocked) {
            // if the lock is active but the operation is not a lock operation, just ignore it
            return;
        }

        // First reset, so there can only be 1 popover open at a time
        $scope.resetFieldDescriptionPopoverIsOpenMap();

        // Then set the value
        $scope.data.currentFieldDescriptionPopoverIsOpen = isOpen ? fieldDefId : null;
        $scope.data.currentFieldDescriptionPopoverPlacement = placement || 'bottom';
    };

    $scope.removeLinkedInitiative = function (linkedItem) {
        const parent = $scope.uiParent[linkedItem.id];

        // First, remove the item locally.
        // If we're removing a sub item (that has a parent).
        if (parent && parent.relatedInitiatives) {
            // Remove the linked item from his parent's children list.
            $scope.removeItemById(parent.relatedInitiatives, linkedItem);
            // Update the counts
            trackHelper.updateInnerItemsMetadataOnItemRemoved(parent, linkedItem);
            // Re-rendering the parent item
            TrackActions.trackRelatedItemsUpdated(parent.id);
            $scope.updateViewIds();
        } else {
            // If we're removing a root level item, remove the linked item from the root items list.
            $scope.removeItemById($scope.data.items, linkedItem);
        }

        // Now ask the server to remove the linked item.
        tonkeanService.deleteEntity(linkedItem.id);
    };

    /**
     * Removes the given item from the given list if it finds it in the list by its reference (not by id).
     */
    $scope.removeItem = function (list, item) {
        let index;
        for (const [i, element] of list.entries()) {
            if (element === item) {
                index = i;
                break;
            }
        }
        if (index >= 0) {
            list.splice(index, 1);
        }
    };

    /**
     * Removes the given item from the given list if it finds it in the list by its id (not by reference).
     */
    $scope.removeItemById = function (list, item) {
        // Verify we've got what we need.
        if (item && item.id && list) {
            let index;
            for (const [i, element] of list.entries()) {
                if (element.id && element.id === item.id) {
                    index = i;
                    break;
                }
            }

            if (index >= 0) {
                list.splice(index, 1);
            }
        }
    };

    $scope.openOrExtend = function (item) {
        if ($scope.data.enableInnerItemsToggle && item.hasRelatedInitiatives && !item.isArchived) {
            $scope.showRelated(item, true);
        } else {
            $scope.goToItem(item);
        }
    };

    $scope.fieldOpenOrExtend = function (item, fieldDef) {
        if (item.defIdToFieldsMap && item.defIdToFieldsMap[fieldDef.id] && item.defIdToFieldsMap[fieldDef.id][0]) {
            if (!item.defIdToFieldsMap[fieldDef.id][0].hasUrlLink) {
                modal.openFieldViewModal(item.defIdToFieldsMap[fieldDef.id][0], item);
            }
        } else if ($scope.data.enableInnerItemsToggle && item.hasRelatedInitiatives && !item.isArchived) {
            $scope.showRelated(item, true);
        } else {
            $scope.goToItem(item);
        }
    };

    $scope.fieldOpenGraph = function (item, fieldDef) {
        if (item.defIdToFieldsMap && item.defIdToFieldsMap[fieldDef.id] && item.defIdToFieldsMap[fieldDef.id][0]) {
            const viewType =
                fieldDef.fieldType !== 'Number' && fieldDef.displayAs !== 'Number' ? 'SHOW_FULL_VIEW' : null;
            modal.openFieldViewModal(item.defIdToFieldsMap[fieldDef.id][0], item, viewType);
        }
    };

    $scope.onSubmitInner = function (newInitiative) {
        if ($scope.onSubmit) {
            $scope.submittedFromInner = true;
            // The name of the property should be the same as the name given to the directive in the onSubmit function for this to work (in initiatives.html).
            // So don't change 'softUpdate' unless you change it in initiatives.html.
            const softUpdate = !!newInitiative;
            $scope.onSubmit({ softUpdate });
        }
    };

    $scope.onManualFieldKeyDown = function ($event, initiativeId, fieldDef, field) {
        // Only do something if we got all needed data and this is a manual field.
        if (initiativeId && fieldDef && fieldDef.type && fieldDef.type.toUpperCase() === 'MANUAL') {
            if ($event.code === 'Enter' || $event.keyCode === 13) {
                $event.preventDefault();

                // Lose focus  - this will cause editManualFieldValue to be called.
                if ($event && $event.target) {
                    $event.target.blur();
                }
            } else if (($event.code === 'Escape' || $event.keyCode === 27) && $event && $event.target) {
                // Restore previous value (if existed).
                $event.target.textContent = field ? field.value : '';

                // Lost focus.
                $event.target.blur();
            }
        }
    };

    $scope.editManualFieldValue = function ($event, initiativeId, fieldDef, field) {
        // Only do something if we got all needed data and this is a manual field.
        if (
            initiativeId &&
            fieldDef &&
            fieldDef.type &&
            fieldDef.type.toUpperCase() === 'MANUAL' &&
            $event &&
            $event.target
        ) {
            const divValue = $event.target.textContent ? angular.copy($event.target.textContent) : '';

            if (!field || divValue !== field.value) {
                $scope.updateFieldValue(initiativeId, field, fieldDef, divValue).then(function (newField) {
                    if (newField) {
                        // Update the div to the new field's value.
                        $event.target.textContent = newField.displayValue;
                    }
                });
            }
        }
    };

    $scope.updateFieldValue = function (initiativeOrLinkId, field, fieldDef, value) {
        let updateFieldPromise = $q.resolve(field);
        const realTrack = utils.findFirstById($scope.tracks, initiativeOrLinkId);

        if (fieldDef && fieldDef.id === 'startTime') {
            updateFieldPromise = trackHelper.updateStartTime(initiativeOrLinkId, value, true);
        }

        // If we're updating an existing field.
        if (field) {
            analyticsWrapper.track('Update data tile', { category: 'Tracks editor' });

            // Only update to the new value if the value has changed.
            if (field.value !== value) {
                updateFieldPromise = trackHelper
                    .updateInitiativeDataTile(
                        initiativeOrLinkId,
                        field,
                        value,
                        undefined,
                        $scope.data.isInSharedMode,
                        $scope.data.solutionBusinessReportId,
                    )
                    .then((newField) => {
                        TrackActions.trackFieldUpdated(initiativeOrLinkId);
                        return $q.resolve(newField);
                    });
            }
        } else if (value && value !== '') {
            // If we're creating a new field, need to have some value.
            // Create the new field with its new value.
            analyticsWrapper.track('Create data tile from existing', { category: 'Tracks editor' });

            updateFieldPromise = trackHelper
                .createInitiativeDataTile(
                    initiativeOrLinkId,
                    value,
                    fieldDef,
                    $scope.data.isInSharedMode,
                    $scope.data.solutionBusinessReportId,
                )
                .then((newField) => {
                    return $q.resolve(newField);
                });
        }

        // else
        return updateFieldPromise.then(() => addDummyIfNeeded(realTrack));
    };

    $scope.openMoveGroupModal = function (item) {
        let underItemId = null;
        let initativeArray = null;
        if (item.parent) {
            initativeArray = item.parent.relatedInitiatives || [];
        } else {
            initativeArray = $scope.data.items;
        }

        for (let i = 0; i < initativeArray.length; i++) {
            const initiative = initativeArray[i];
            if (initiative.id === item.id) {
                if (i > 0) {
                    underItemId = initativeArray[i - 1].id;
                }

                break;
            }
        }

        modal.openMoveGroupModal(item, !$scope.hideAddNewForce);
    };

    $scope.setFocusOnMainTextbox = function () {
        document.getElementById(`theText-${$scope.editorId}`).focus();
    };

    $scope.hideColumn = function ($event, fieldDefinitionId) {
        customFieldsManager
            .updateFieldIsHidden(
                fieldDefinitionId,
                true,
                $scope.data.environment,
                $scope.groupId,
                $scope.workflowVersionId,
                'COLUMN',
                $scope.data.solutionBusinessReportId,
            )
            .then(() => {
                // if we are ordering by the column we are hiding - clear order by
                if ($scope.data.orderBy === fieldDefinitionId) {
                    $scope.clearOrderBy();
                }

                TrackActions.fieldDefinitionsUpdated();
            });
    };

    $scope.moveFieldLeft = function ($event, fieldDefinitionId) {
        customFieldsManager
            .moveFieldLeft(
                $scope.groupId,
                fieldDefinitionId,
                'COLUMN',
                $scope.data.environment,
                1,
                $scope.data.solutionBusinessReportId,
            )
            .then((result) => {
                TrackActions.fieldDefinitionsUpdated();
                initTrackListItemData();
            });
    };

    $scope.moveFieldRight = function ($event, fieldDefinitionId) {
        customFieldsManager
            .moveFieldRight(
                $scope.groupId,
                fieldDefinitionId,
                'COLUMN',
                $scope.data.environment,
                1,
                $scope.data.solutionBusinessReportId,
            )
            .then((result) => {
                TrackActions.fieldDefinitionsUpdated();
                initTrackListItemData();
            });
    };

    /**
     * Opens the field definition configuration modal for edit mode.
     */
    $scope.openEditFieldDefinitionModal = function (fieldDefinition) {
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            fieldDefinition.targetType,
            fieldDefinition.type,
            fieldDefinition.projectIntegration,
            false,
            false,
            fieldDefinition,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.workflowVersionId,
        ]);
    };

    /**
     * Opens the field definition configuration modal for the configuration of a manual field.
     */
    $scope.openConfigureManualFieldModal = function (fieldDefinition) {
        $rootScope.$broadcast('createNewField', [
            $scope.groupId,
            'COLUMN',
            fieldDefinition.type,
            null,
            false,
            false,
            fieldDefinition,
            null,
            null,
            getFieldDefinitionConfigurationModalSteps().dataTypeConfiguration.id,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            $scope.workflowVersionId,
        ]);
    };

    function hideDeletedItem(item, index) {
        if (item.expended || ($scope.uiParent[item.id] && $scope.uiParent[item.id].expended)) {
            $scope.setSelected(-1, null);
        }

        $timeout(function () {
            $scope.itemMap[item.id].deleted = true;
            const list = $scope.uiParent[item.id] ? $scope.uiParent[item.id].relatedInitiatives : null;
            if (list) {
                list.splice(index, 1);

                if ($scope.uiParent[item.id] && !list.length) {
                    // if it was the last item
                    $scope.uiParent[item.id].expended = false;
                }
            }
        });
    }

    function enrich(initiative) {
        return entityHelper.enrichEntity(initiative);
    }

    function findById(arr, id) {
        for (const [i, element] of arr.entries()) {
            if (element.id === id) {
                return i;
            }
        }
        return null;
    }

    function copyEntityFields(from, to) {
        for (const property in from) {
            if (from.hasOwnProperty(property)) {
                to[property] = from[property];
            }
        }
    }

    function reloadIndexesFromCache(items) {
        for (const item of items) {
            const id = item.id;
            if ($scope.itemMap[id]) {
                item.index = $scope.itemMap[id].index;
                item.parent = $scope.itemMap[id].parent;
                item.parentInitiatives = $scope.itemMap[id].parentInitiatives;
            }
        }
    }

    $scope.generateID = function () {
        const newId = trackHelper.generateId();
        return newId || utils.generateTempID();
    };

    /**
     * Executes an order by.
     * @param orderBy - On what to order by
     * @param type - Whats the type of data (Number, String etc...)
     * @param orderType - Optional. Either 'DESC' or 'ASC' string, defaults to ASC
     */
    $scope.setOrderBy = function (field, fieldType, orderType) {
        loadOrderBy(field, fieldType, orderType);
        if ($scope.onOrderBy) {
            $scope.onOrderBy({
                field: $scope.data.orderBy.field,
                fieldType: $scope.data.orderBy.fieldType,
                orderType: $scope.data.orderBy.orderType,
                forceLoad: true,
                groupId: $scope.groupId,
            });
        }

        // Propagate to React component onSort function
        if ($scope.onSort) {
            $scope.onSort({
                field: $scope.data.orderBy.field,
                fieldType: $scope.data.orderBy.fieldType,
                orderType: $scope.data.orderBy.orderType,
            });
        }
    };

    $scope.clearOrderBy = function () {
        $scope.setOrderBy('index');
    };

    $scope.filterVisibleFieldDefinitions = function (fieldDef) {
        if ($scope.data.solutionBusinessReportId) {
            return liveReportHelper.solutionReportsFieldsIsHiddenMap[$scope.data.solutionBusinessReportId][fieldDef.id];
        }

        return liveReportHelper.fieldsIsHiddenMap[$scope.groupId][$scope.data.environment][fieldDef.id];
    };

    $scope.updateColumnVisibility = function (fieldDefinition) {
        customFieldsManager
            .updateFieldIsHidden(
                fieldDefinition.id,
                false,
                $scope.data.environment,
                $scope.groupId,
                $scope.workflowVersionId,
                'COLUMN',
                $scope.data.solutionBusinessReportId,
            )
            .then(() => {
                TrackActions.fieldDefinitionsUpdated();

                // update everyone
                $rootScope.$broadcast('newActivityUpdate');
            });
    };

    $scope.updateViewIds = function () {
        // $scope.data.topViewIndex = topViewIndex;
        // $scope.data.bottomViewIndex = bottomViewIndex;

        const rows = [...document.getElementsByClassName(`tracks-item-row-marker-${$scope.editorId}`)];

        for (const [i, el] of rows.entries()) {
            // loop through each element

            // document.getElementById('marker-' + el.id).innerText = i;

            // update visiblity according to index, true means hidden
            $scope.hideRow[el.id] = i < $scope.data.topViewIndex || i > $scope.data.bottomViewIndex;
        }
    };

    /** Lazy loading + scroll handling. **/
    // Keeps tracks of the amount the user has scrolled so far - helps identify down scrolling.
    let scrolled = 0;
    let onscrollHandlerId = null;

    if ($scope.lazyLoadTracks) {
        // Get scroller, or ignore it if none found
        const scrollerElement = document.getElementById($scope.scrollerElementId) || {};
        let headersContainerTopToPoint = null;
        const initiativesContentElement = document.getElementById($scope.listParentElementId);

        const editorId = $scope.editorId;
        const lazyLoadTracks = $scope.lazyLoadTracks;
        const isMobile = $rootScope.isMobile;
        let changingTop = false;
        let changingTitles = false;
        let showingTitlesHint = false;
        let lastScrollTop = null;
        let lastScrollLeft = null;
        // let animationConst = 'top 0.1s ease';

        // set the scroll top of the headersContainerElement
        const setHeadersContainerTop = function (innerCheck) {
            const headersContainerElement = document.getElementById(`tracks-headers-container-${editorId}`);
            if (scrollerElement.scrollTop < lastScrollTop) {
                // moving up, so in order for it not to jump, make it all they way up and the timer will fix it
                // headersContainerElement.style.transition = null;
                headersContainerElement.style.top = '0px';
            }

            if (!changingTop || innerCheck) {
                changingTop = true;
                // adding a lag to the update so it won't make the scroll itself heavy
                setTimeout(function () {
                    if (lastScrollTop !== scrollerElement.scrollTop) {
                        // means is still scrolling, so let's wait till it's done and check again later
                        setHeadersContainerTop(true);
                        return;
                    }

                    // set the top
                    // console.log('setting top');
                    const headersContainerElement = document.getElementById(`tracks-headers-container-${editorId}`);
                    // headersContainerElement.style.transition = animationConst;
                    if (headersContainerElement) {
                        let newTop =
                            scrollerElement.scrollTop -
                            initiativesContentElement.offsetTop +
                            headersContainerElement.offsetHeight +
                            4;
                        if (isMobile) {
                            newTop = newTop - 18;
                        }
                        headersContainerElement.style.top = `${newTop}px`;
                    }
                    changingTop = false;
                }, 1000);
            }
        };

        const resetTitlesPos = function () {
            if (showingTitlesHint) {
                const titles = document.querySelectorAll('.tracks-title-box');
                for (const title of titles) {
                    title.style.display = 'none';
                }
                showingTitlesHint = false;
            }
        };

        const setTitlesPos = function (innerCheck) {
            if (scrollerElement.scrollLeft < 500 || lastScrollLeft > scrollerElement.scrollLeft) {
                // reset
                resetTitlesPos();

                if (scrollerElement.scrollLeft < 500) {
                    return;
                }
            }

            if (!changingTitles || innerCheck) {
                changingTitles = true;
                // adding a lag to the update so it won't make the scroll itself heavy
                setTimeout(function () {
                    if (lastScrollLeft !== scrollerElement.scrollLeft) {
                        // means is still scrolling, so let's wait till it's done and check again later
                        setTitlesPos(true);
                        return;
                    }

                    if (scrollerElement.scrollLeft < 500) {
                        // reset
                        resetTitlesPos();
                    } else {
                        // set the position
                        const titles = document.querySelectorAll('.tracks-title-box');
                        for (const title of titles) {
                            title.style.left = `${scrollerElement.scrollLeft}px`;
                            title.style.display = 'block';
                        }

                        showingTitlesHint = true;
                    }

                    changingTitles = false;
                }, 1000);
            }
        };

        /**
         * Will trigger load more when the screen reaches the end of the document
         *
         * @param forceLoadMore {boolean} - to force load more even if the screen has not
         * scrolled and it has no where to scroll/
         */
        const onscrollAction = function (forceLoadMore = false) {
            if (lazyLoadTracks) {
                // Only do something if the user scrolled down more then he did before
                if (scrollerElement.scrollTop > scrolled || forceLoadMore) {
                    scrolled = scrollerElement.scrollTop;
                    // and are "one screen" away from the end or not able to scroll
                    if (
                        scrollerElement.scrollHeight - scrollerElement.scrollTop <=
                            document.documentElement.clientHeight * 2 ||
                        scrollerElement.clientHeight === scrollerElement.scrollHeight
                    ) {
                        $timeout($scope.loadMoreTracks);
                    }
                }

                // calc view rows indexes
                const itemHeight = 60;
                const menusHeight = 150;

                const itemsCanFitInView = (document.documentElement.clientHeight - menusHeight) / itemHeight;
                const approximateTopViewIndex = (scrollerElement.scrollTop - menusHeight) / itemHeight;

                // allowing "page" before and "page" after as buffer
                const topViewIndex = (approximateTopViewIndex - itemsCanFitInView).toFixed(1);
                const bottomViewIndex = (approximateTopViewIndex + itemsCanFitInView * 2).toFixed(1);

                if (topViewIndex !== $scope.data.topViewIndex || bottomViewIndex !== $scope.data.bottomViewIndex) {
                    $scope.data.topViewIndex = topViewIndex;
                    $scope.data.bottomViewIndex = bottomViewIndex;

                    $scope.updateViewIds();
                }
            }

            // put header on top if it's out of view
            const headersContainerElement = document.getElementById(`tracks-headers-container-${editorId}`);

            if (headersContainerElement) {
                const initiativesContentInitialTop = initiativesContentElement.offsetTop;
                // is scrolled > element top - element height * 2
                if (!headersContainerTopToPoint && scrollerElement.scrollTop > initiativesContentInitialTop) {
                    // means it's out of view, scrolled after
                    if (!headersContainerTopToPoint) {
                        headersContainerTopToPoint = scrollerElement.scrollTop; // saving that point

                        // making the element absolute
                        headersContainerElement.style.position = 'absolute';
                        headersContainerElement.style.paddingLeft = '0px';
                        headersContainerElement.style.left = '0px';
                        headersContainerElement.style.right = '0px';
                        if (isMobile) {
                            // need to be the width of the editor
                            const trackEditorContentElement = document.getElementById(
                                `tracks-editor-content-${editorId}`,
                            );
                            headersContainerElement.style.width = trackEditorContentElement
                                ? `${trackEditorContentElement.offsetWidth}px`
                                : null;
                        }

                        // to make it not 'jump' need to add this new offset as a padding to the container
                        initiativesContentElement.style.paddingTop = `${headersContainerElement.offsetHeight}px`;
                    }
                } else if (headersContainerTopToPoint > scrollerElement.scrollTop) {
                    // it's no longer there, let's zero it out
                    headersContainerTopToPoint = null;
                    headersContainerElement.style.position = 'static';

                    initiativesContentElement.style.paddingTop = null;
                }

                // set the scroll top of the headersContainerElement
                if (headersContainerTopToPoint && lastScrollTop !== scrollerElement.scrollTop) {
                    setHeadersContainerTop();
                }
            }

            setTitlesPos();

            // save the last scroll
            lastScrollTop = scrollerElement.scrollTop;
            lastScrollLeft = scrollerElement.scrollLeft;

            calculateAllowItemMenuInMobile(scrollerElement);
        };

        onscrollHandlerId = safeDomEvents.registerEvent($scope.scrollerElementId, 'onscroll', onscrollAction);

        $scope.$on('$destroy', function () {
            // cleaning the onscroll by setting the old ballback (which if null, means we are the root and can get rid of it)
            safeDomEvents.unregisterEvent($scope.scrollerElementId, 'onscroll', onscrollHandlerId);
        });

        $timeout(function getPagesUntilScroll() {
            const hasScroller = scrollerElement.scrollHeight > scrollerElement.clientHeight;
            // When there is no scroller and we have more items to load.
            // Has more initiatives can be undefined when we did not start to get
            // initiatives.
            // Has more initiatives cn be undefined when there was no page request, in that case
            // we still want to request pages.
            if (!hasScroller && $scope.hasMoreInitiatives !== false) {
                $scope.loadMoreTracks().then(() => $timeout(getPagesUntilScroll));
            }
        });
    }

    // if we have this element this means we are in the initiative view
    // that means scroll on project-board doesnt happen so we must bind a scroll event to this element
    const initiativeViewElement = document.querySelector('#initiative-view-box');
    if (initiativeViewElement) {
        const onInitiativeViewScrollHandlerId = safeDomEvents.registerEvent('initiative-view-box', 'onscroll', () => {
            calculateAllowItemMenuInMobile(initiativeViewElement);
        });

        $scope.$on('$destroy', () => {
            safeDomEvents.unregisterEvent('initiative-view-box', 'onscroll', onInitiativeViewScrollHandlerId);
        });
    }

    $scope.onTabInLinkMousedown = function () {
        // Do the tab in
        $scope.setSubItem(true);

        // Because we clicked outside of the input, a blur event is going to happen. That blur event will change a flag called newItemFocus.
        // When that flag is off the tab in link is not shown in DOM so any click events will not trigger.
        // Thats why we use mouse down, after mouse down will come a blur so we have to wait for next render to focus. (if we focus now the blur event will just cancel it out)
        $timeout(() => {
            const elementById = document.getElementById(`theText-${$scope.editorId}`);
            elementById.focus();
        });
    };

    $scope.closePromo = function () {
        $scope.data.promoDismissed = true;
    };

    /**
     * Close builtInList promo box and update user metadata.
     */
    $scope.closeBuiltInListsImportPromo = function () {
        $scope.pm.groupsMap[$scope.groupId].metadata.displayImportPromoBox = false;

        // Update server and caches.
        $scope.pm.updateGroupMetadata($scope.groupId, $scope.pm.groupsMap[$scope.groupId].metadata);
    };

    $scope.setStatusPopoverIsOpenState = function (isOpen) {
        // If the set owner step is also completed, never show the status popover to avoid cases in which they are simultaneously displayed.
        const user = $scope.as.currentUser;
        if (
            user &&
            user.metadata &&
            user.metadata.continuousOnBoarding &&
            user.metadata.continuousOnBoarding.steps &&
            user.metadata.continuousOnBoarding.steps['assignOwner'] &&
            user.metadata.continuousOnBoarding.steps['assignOwner'].completed
        ) {
            isOpen = false;
        }

        $scope.statusPopoverState.isOpen = isOpen;
    };

    /**
     * Sets a next gather update reminder based on a reminder object from the consts.
     * @param initiative - the initiative to set a reminder for.
     * @param nextReminder - A next reminder object from the consts (should contain days [required] and hours [optional]).
     * @param analyticsCategory - If supplied, an analytics event will be recorded with this given category.
     */
    $scope.setReminder = function (initiative, nextReminder, analyticsCategory) {
        if (analyticsCategory && analyticsCategory.length) {
            analyticsWrapper.track('Set reminder', { category: analyticsCategory });
        }

        const hour = calculateNextReminderHour(nextReminder);
        trackHelper.updateNextReminder(initiative, nextReminder.days, hour);
    };

    /**
     * Handle the view only mode by blocking any click on the item other than collapse
     * **/
    $scope.itemClicked = function ($event, item) {
        // If it isn't in viewOnly mode or it is item that should be available also in view only mode.
        if (!$scope.viewOnlyMode || ($event.target && Object.hasOwn($event.target.dataset, 'allowOnViewOnlyMode'))) {
            return;
        }

        $event.stopPropagation();

        if ($scope.data.enableInnerItemsToggle && item.hasRelatedInitiatives) {
            $scope.showRelated(item, true);
        }

        return true;
    };

    $scope.itemDoubleClicked = function ($event) {
        if ($scope.viewOnlyMode) {
            $event.stopPropagation();
            $event.preventDefault();
        }
    };

    /**
     * Update the currently viewed initiative when hovering track title.
     */
    $scope.setSelectedInitiativeOnHover = function (initiative) {
        if (initiative && initiative.id) {
            $scope.pm.currentlyViewedSimplifiedInitiative = { id: initiative.id, title: initiative.title };
        }
    };

    /**
     * Toggles the track summary popover for the React trackListItem.
     * It does that by planting a prepared tnk-add-function-popover element (that waits at the end of tnkTracksEditor) into the given container id.
     */
    $scope.toggleTrackSummaryPopover = function (containerId, track, isOpen) {
        // Since this toggle happens on mouseEnter and mouseOut, it specifically tells us to display or hide it.
        if (isOpen) {
            $scope.popovers.trackSummary = true;
            $scope.popoversData.trackSummary = track.id;
            appendPopoverToContainer(`popover-track-summary-${$scope.editorId}`, containerId);
        } else {
            $scope.resetPopover(`popover-track-summary-${$scope.editorId}`, 'trackSummary');
        }
    };

    /**
     * Resets a popover and puts it back in the end of the tracks editor.
     * @param popoverId - the element id of the popover.
     * @param popoverPropertyName - the property name in $scope.popover and $scope.popoversData.
     */
    $scope.resetPopover = function (popoverId, popoverPropertyName) {
        // Reset the popover states.
        $scope.popovers[popoverPropertyName] = false;
        $scope.popoversData[popoverPropertyName] = null;
        // Append the popover back to its tracks editor.
        appendPopoverToContainer(popoverId, `tracks-editor-${$scope.editorId}`);
    };

    /**
     * Load order by, change type if the field is being orderd.
     */
    function loadOrderBy(field, fieldType, orderType) {
        if ($scope.data.orderBy.field !== field) {
            $scope.data.orderBy.field = field;
            $scope.data.orderBy.orderType = orderType ? orderType : 'ASC';
        } else {
            $scope.data.orderBy.orderType = $scope.data.orderBy.orderType === 'DESC' ? 'ASC' : 'DESC';
        }

        if ($scope.data.orderBy.field !== 'index') {
            // build inner initiative field name to order the inner items in the html
            let fieldOrderName = 'index';
            switch ($scope.data.orderBy.field) {
                case 'TNK_STAGE':
                    fieldOrderName = 'stateText';
                    break;
                case 'TNK_OWNER_NAME':
                    fieldOrderName = 'owner.name';
                    break;
                case 'TNK_DUE_DATE':
                    fieldOrderName = 'dueDate';
                    break;
                case 'TNK_TITLE':
                    fieldOrderName = 'title';
                    break;

                case 'index':
                    fieldOrderName = 'index';
                    break;
                default:
                    fieldOrderName = `fieldDefinitionSortValueMap.${$scope.data.orderBy.field}`;
                    break;
            }

            // If desc we add minus sign so it will be reversed, if not TNK Field  add fieldDefinitionSortValueMap
            $scope.data.orderBy.filter = ($scope.data.orderBy.orderType === 'DESC' ? '-' : '') + fieldOrderName;
            $scope.data.allowDndOnRoot = false;
        } else {
            $scope.data.orderBy.filter = 'index';
            $scope.data.allowDndOnRoot = $scope.allowDndOnRoot;
        }

        $scope.data.orderBy.fieldType = fieldType;
    }

    /**
     * Appends the given popover element id to the given container id using angular's append function,
     * and returns the container's angular (jqLite) element.
     */
    function appendPopoverToContainer(popoverElementId, containerId) {
        // Get the element and the container as angular elements.
        let ngPopoverElement = angular.element(document.getElementById(popoverElementId));
        const ngContainer = angular.element(document.getElementById(containerId));

        // Make sure our popovers pool have this popover as backup.
        // This is done since in some rare cases the appended popover element might get deleted while in the container.
        // In this case, we'll reload it from the pool.
        if (ngPopoverElement && ngPopoverElement[0] && !popoversPoolMap[popoverElementId]) {
            popoversPoolMap[popoverElementId] = ngPopoverElement;
        }

        // In case the popover element has been deleted for some reason, we'll get it from the pool.
        if (!ngPopoverElement || !ngPopoverElement[0]) {
            ngPopoverElement = popoversPoolMap[popoverElementId];
        }

        // Append takes the element from where ever it is and puts in the given container.
        ngContainer.append(ngPopoverElement);

        return ngContainer;
    }

    function calculateAllowItemMenuInMobile(scrollingElement) {
        // decide whether to allow the user to swipe to see the item menu icon when in mobile
        $scope.trackListItemData.allowItemMenuMobile = false;
        // if we are at the leftmost edge
        if (scrollingElement.scrollLeft === 0) {
            // we put a delay because this is set before the swipe action, and we only want to take this into account from the next swipe
            $timeout(() => {
                $scope.trackListItemData.allowItemMenuMobile = true;
            }, 500);
        }
    }

    const onBoardingCreateTrackListener = $scope.$on('onBoardingStepCompleted', function (event, stepKey) {
        // if the user completed a track
        if (stepKey === 'createTrack') {
            // open the popover in 2500 ms
            $timeout(function () {
                $scope.setStatusPopoverIsOpenState(true);
            }, 2500);

            // unsubscribe to onboarding updates
            onBoardingCreateTrackListener();
        }
    });

    $scope.translateItemId = function (itemId) {
        return $scope.itemMap[itemId] ? $scope.itemMap[itemId].trackId : itemId;
    };

    /**
     * Occurs once an event of a whole list update was fired.
     */
    $rootScope.$on('groupListUpdated', (e, params) => {
        const groupIds = params ? params.groupIds : null;
        const changedInitiativeIds = params ? params.changedInitiativeIds : null;

        if ($scope.groupId && groupIds && groupIds.length && changedInitiativeIds) {
            for (const groupId of groupIds) {
                if ($scope.groupId === groupId) {
                    for (const initiativeId of changedInitiativeIds) {
                        // Changing in data.initiatives.
                        /* jshint loopfunc:true */
                        const existingInInitiatives = utils.findFirst(
                            $scope.data.tracks,
                            (initiative) => initiative.id === initiativeId,
                        );
                        if (!existingInInitiatives) {
                            const cachedInitiative = trackHelper.getInitiativeFromCache(initiativeId);
                            if (cachedInitiative && cachedInitiative.group.id === $scope.groupId) {
                                $scope.data.tracks.push(cachedInitiative);
                            }
                        }
                    }
                    return;
                }
            }
        }
    });

    // updatingTitle indicates that the add dummy was from a title update
    const addDummyIfNeeded = (item, updatingTitle = false) => {
        if ($scope.collectItemsMode && item.title === DUMMY_TRACK_TITLE && !$scope.hideAddNewForce) {
            // We need tp update the title to the new title.
            if (!updatingTitle && $scope.hideTitle) {
                const generatedTitle = trackHelper.autoGenerateTitle(
                    $scope.as.currentUser.name,
                    $scope.createdInFormName,
                );
                trackHelper.updateInitiativeTitle(
                    item.id,
                    generatedTitle,
                    $scope.createdInFormId,
                    $scope.data.isInSharedMode,
                    $scope.data.solutionBusinessReportId,
                );
            }

            if (updatingTitle || $scope.hideTitle) {
                $scope.addEmptyItem();
            }
        }
    };

    /**
     * This function adds a dummy item, so the user can edit items without inserting a title first.
     *
     * @see: {@link https://tonkean.atlassian.net/browse/TNKN-6813}
     */
    $scope.addEmptyItem = () => {
        $scope.submit(DUMMY_TRACK_TITLE, true);
    };

    $scope.getDisplayNameOfFieldDefinition = (fieldDef) => {
        const formFieldDefinition = $scope.createdInForm?.definition.fields.find(
            (field) => field.fieldDefinitionIdentifier === fieldDef.id,
        );
        return (
            getFieldsLabelsFromConfigurations(fieldDef.id) ||
            formFieldDefinition?.displayName ||
            fieldDef.fieldLabel ||
            fieldDef.name
        );
    };

    $scope.getDisplayNameForTitleField = () => {
        const prefix = $scope.createdInFormId ? $scope.data.titleDisplayName : 'Items';
        const plusSign = $scope.hasMoreInitiatives ? '+' : '';
        const suffix =
            !$scope.createdInFormId && $scope.data.items && $scope.data.items.length
                ? ` (${$scope.data.items.length}${plusSign})`
                : '';

        return prefix + suffix;
    };

    function getDisplayNameOfSpecialField(fieldId) {
        return (
            getFieldsLabelsFromConfigurations(fieldId) ||
            $scope.createdInForm?.definition.fields.find((field) => field.fieldDefinitionIdentifier === fieldId)
                ?.displayName
        );
    }

    function getFieldsLabelsFromConfigurations(fieldId) {
        return $scope.fieldsConfigurations?.[fieldId]?.label;
    }
}

angular.module('tonkean.app').controller('TracksEditorCtrl', TracksEditorCtrl);
