import React from 'react';

export default class WorkerHistoryItemTrigger extends React.Component {
    renderTitleLink(initiative) {
        const id = `worker-history-item-title-${this.props.id}-tnk-worker-history`;
        return (
            <span>
                <a id={id} onClick={this.props.goToTrack.bind(this, initiative.id)}>
                    {initiative.title}
                </a>
            </span>
        );
    }

    render() {
        if (!this.props.triggers || !this.props.triggers.length) {
            return null;
        }

        if (!this.props.initiative || !this.props.initiative) {
            return null;
        }

        const initiative = this.props.initiative;

        return this.props.triggers.map((trigger) => (
            <div
                key={`trigger-${trigger.id}`}
                className="common-size-xxxs worker-history-item-trigger padding-normal-xs flex-vmiddle"
            >
                <div>
                    {/* Filter Initiative */}
                    <span>
                        {/* Initiative Title */}
                        <strong>{this.renderTitleLink(initiative)}</strong>
                        <span className="tnk-tooltip mod-top  filter-initiative-icon common-size-xxxxxs margin-left">
                            <i
                                className="fa fa-filter edit-button pointer"
                                onClick={this.props.filterInitiative.bind(this, initiative)}
                            />
                            <span className="tnk-tooltip-text">Filter by this Track</span>
                        </span>
                    </span>
                </div>
            </div>
        ));
    }
}
