import { WorkflowVersionType } from '@tonkean/tonkean-entities';

function FormManager($rootScope, $q, tonkeanService, utils, workflowVersionManager, workflowFolderManager) {
    const _this = this;

    _this.workflowVersionIdToFormIdToFormMap = {};
    _this.workflowFolderIdToFormIdToFormsMap = {};
    _this.formIdToWorkflowVersionIdToFormMap = {};

    /**
     * @type {Record<import("@tonkean/tonkean-entities").Form["id"], Record<import("@tonkean/tonkean-entities").WorkflowVersionType, import("@tonkean/tonkean-entities").Form>>}
     */
    _this.formCache = {};

    _this.addToSingleFormCache = function (form, workflowVersionId) {
        if (!_this.formIdToWorkflowVersionIdToFormMap[form.id]) {
            _this.formIdToWorkflowVersionIdToFormMap[form.id] = {};
        }
        _this.formIdToWorkflowVersionIdToFormMap[form.id][workflowVersionId] = form;
    };

    _this.addToSingleFormToWorkflowVersionTypeCache = function (form, workflowVersionType) {
        if (!_this.formCache[form.id]) {
            _this.formCache[form.id] = {};
        }
        _this.formCache[form.id][workflowVersionType] = form;
    };

    /**
     * Gets all the forms of a workflow version.
     */
    _this.getAllWorkerForm = function (workflowVersionId, forceServer) {
        if (_this.workflowVersionIdToFormIdToFormMap[workflowVersionId] && !forceServer) {
            // Do we have it cached already?
            return $q.resolve(utils.objValues(_this.workflowVersionIdToFormIdToFormMap[workflowVersionId]));
        } else {
            // Otherwise, we do not have it in cache, we fetch.
            return tonkeanService.getAllWorkerForm(workflowVersionId).then((results) => {
                // Initializing the cache for the workflow version.
                _this.workflowVersionIdToFormIdToFormMap[workflowVersionId] = {};

                // Saving forms in cache for group.
                const retrievedForms = results.entities;
                for (const form of retrievedForms) {
                    _this.workflowVersionIdToFormIdToFormMap[workflowVersionId][form.id] = form;
                    _this.addToSingleFormCache(form, workflowVersionId);
                }

                // Return the forms.
                return $q.resolve(utils.objValues(retrievedForms));
            });
        }
    };

    _this.getAllWorkflowFolderForms = function (workflowFolderId, workflowVersionType, forceServer) {
        if (_this.workflowFolderIdToFormIdToFormsMap[workflowFolderId] && !forceServer) {
            // Do we have it cached already?
            return $q.resolve(utils.objValues(_this.workflowFolderIdToFormIdToFormsMap[workflowFolderId]));
        } else {
            // Otherwise, we do not have it in cache, we fetch.
            return tonkeanService
                .getAllFormsByWorkflowFolderId(workflowFolderId, workflowVersionType)
                .then((results) => {
                    // Initializing the cache for the workflow version.
                    _this.workflowFolderIdToFormIdToFormsMap[workflowFolderId] = {};

                    // Saving forms in cache for group.
                    const retrievedForms = results.entities;
                    for (const form of retrievedForms) {
                        _this.workflowFolderIdToFormIdToFormsMap[workflowFolderId][form.id] = form;
                        if (!_this.workflowVersionIdToFormIdToFormMap[form.workflowVersion.id]) {
                            _this.workflowVersionIdToFormIdToFormMap[form.workflowVersion.id] = {};
                        }
                        _this.workflowVersionIdToFormIdToFormMap[form.workflowVersion.id][form.id] = form;

                        _this.addToSingleFormCache(form, form.workflowVersion.id);
                    }

                    // Return the forms.
                    return $q.resolve(utils.objValues(retrievedForms));
                });
        }
    };

    /**
     * Gets a single form.
     */
    _this.getWorkerForm = function (workflowVersionId, formId, initiativeId) {
        if (
            _this.workflowVersionIdToFormIdToFormMap[workflowVersionId] &&
            _this.workflowVersionIdToFormIdToFormMap[workflowVersionId][formId]
        ) {
            // Gets the worker form.
            return $q.resolve(_this.workflowVersionIdToFormIdToFormMap[workflowVersionId][formId]);
        } else if (
            _this.formIdToWorkflowVersionIdToFormMap[formId] &&
            _this.formIdToWorkflowVersionIdToFormMap[formId][workflowVersionId]
        ) {
            // Gets the worker form.
            return $q.resolve(_this.formIdToWorkflowVersionIdToFormMap[formId][workflowVersionId]);
        } else if (utils.isNullOrEmpty(workflowVersionId) || utils.isNullOrEmpty(formId)) {
            return $q.reject('Given parameters to getWorkerForm were null or empty');
        } else {
            let promise;
            if (initiativeId) {
                promise = tonkeanService.getWorkerFormForInitiative(workflowVersionId, formId, initiativeId);
            } else {
                promise = tonkeanService.getWorkerForm(workflowVersionId, formId, undefined);
            }
            return promise.then((form) => {
                // Save the form on this cache only
                _this.formIdToWorkflowVersionIdToFormMap[form.id] =
                    _this.formIdToWorkflowVersionIdToFormMap[form.id] || {};
                _this.formIdToWorkflowVersionIdToFormMap[form.id][form.workflowVersionId] = form;
                return $q.resolve(form);
            });
        }
    };

    /**
     * Gets the form from cache if it exists there
     * @param workflowVersionId - the relevant workflow version
     * @param formId - The relevant from ID
     * @returns {*}
     */
    _this.getWorkerFormFromCache = function (workflowVersionId, formId) {
        if (
            _this.workflowVersionIdToFormIdToFormMap[workflowVersionId] &&
            _this.workflowVersionIdToFormIdToFormMap[workflowVersionId][formId]
        ) {
            return _this.workflowVersionIdToFormIdToFormMap[workflowVersionId][formId];
        } else if (
            _this.formIdToWorkflowVersionIdToFormMap[formId] &&
            _this.formIdToWorkflowVersionIdToFormMap[formId][workflowVersionId]
        ) {
            return _this.formIdToWorkflowVersionIdToFormMap[formId][workflowVersionId];
        }
    };

    /**
     * Creates a worker form.
     */
    _this.createWorkerForm = function (
        groupId,
        formType,
        definition,
        disabled,
        displayName,
        descriptionExpression,
        slackCommand,
        formQuestionType,
        collapseDescriptionByDefault,
        expandableDescriptionExpression,
        expandableDescriptionLabel,
    ) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        return tonkeanService
            .createWorkerForm(
                groupId,
                formType,
                definition,
                disabled,
                displayName,
                descriptionExpression,
                slackCommand,
                formQuestionType,
                collapseDescriptionByDefault,
                expandableDescriptionExpression,
                expandableDescriptionLabel,
            )
            .then((createdForm) => {
                workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);
                // Init if the mapping doesnt exist
                if (!_this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId]) {
                    _this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId] = {};
                } else {
                    _this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId] = {
                        ..._this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId],
                    };
                }
                _this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId][createdForm.id] = createdForm;

                // Add to Single form cache
                _this.addToSingleFormCache(createdForm, draftWorkflowVersionId);
                _this.addToSingleFormToWorkflowVersionTypeCache(createdForm, WorkflowVersionType.DRAFT);

                const workflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                    createdForm.project.id,
                    groupId,
                );

                _this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id] = {
                    ..._this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id],
                };
                _this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id][createdForm.id] = createdForm;

                $rootScope.$broadcast('formUpdate', createdForm);
                return $q.resolve(createdForm);
            });
    };

    /**
     * Updates a worker form.
     */
    _this.updateWorkerForm = function (
        groupId,
        formId,
        formType,
        definition,
        disabled,
        displayName,
        descriptionExpression,
        slackCommand,
        formQuestionType,
        collapseDescriptionByDefault,
        smartSearchEnabled,
        homepageEnabled,
        expandableDescriptionExpression,
        expandableDescriptionLabel,
    ) {
        const draftWorkflowVersionId = workflowVersionManager.getDraftVersionFromCache(groupId).id;

        return tonkeanService
            .updateWorkerForm(
                formId,
                formType,
                definition,
                disabled,
                displayName,
                descriptionExpression,
                slackCommand,
                formQuestionType,
                collapseDescriptionByDefault,
                smartSearchEnabled,
                homepageEnabled,
                expandableDescriptionExpression,
                expandableDescriptionLabel,
            )
            .then((updatedForm) => {
                workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);

                _this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId] = {
                    ..._this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId],
                };

                _this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId][updatedForm.id] = updatedForm;

                _this.addToSingleFormCache(updatedForm, draftWorkflowVersionId);
                _this.addToSingleFormToWorkflowVersionTypeCache(updatedForm, WorkflowVersionType.DRAFT);

                const workflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                    updatedForm.project.id,
                    groupId,
                );

                _this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id] = {
                    ..._this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id],
                };
                _this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id][updatedForm.id] = updatedForm;

                $rootScope.$broadcast('formUpdate', updatedForm);
                return $q.resolve(_this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId][updatedForm.id]);
            });
    };

    /**
     * Deletes a worker form.
     */
    _this.deleteWorkerForm = function (groupId, formId) {
        const workflowVersion = workflowVersionManager.getDraftVersionFromCache(groupId);
        const draftWorkflowVersionId = workflowVersion.id;

        return tonkeanService.deleteWorkerForm(formId).then(() => {
            workflowVersionManager.incrementWorkflowVersionCounter(draftWorkflowVersionId);

            if (_this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId][formId]) {
                $rootScope.$broadcast('formUpdate', null);
                delete _this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId][formId];

                _this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId] = {
                    ..._this.workflowVersionIdToFormIdToFormMap[draftWorkflowVersionId],
                };
            }

            if (_this.formIdToWorkflowVersionIdToFormMap[formId][draftWorkflowVersionId]) {
                $rootScope.$broadcast('formUpdate', null);
                delete _this.formIdToWorkflowVersionIdToFormMap[formId][draftWorkflowVersionId];

                _this.formIdToWorkflowVersionIdToFormMap[formId] = {
                    ..._this.formIdToWorkflowVersionIdToFormMap[formId],
                };
            }

            if (_this.formCache[formId] && _this.formCache[formId][WorkflowVersionType.DRAFT]) {
                $rootScope.$broadcast('formUpdate', null);
                delete _this.formCache[formId][WorkflowVersionType.DRAFT];

                _this.formCache[formId] = {
                    ..._this.formCache[formId],
                };
            }

            const workflowFolder = workflowFolderManager.getContainingWorkflowFolder(
                workflowVersion.project.id,
                groupId,
            );

            if (_this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id]?.[formId]) {
                $rootScope.$broadcast('formUpdate', null);
                delete _this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id][formId];

                _this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id] = {
                    ..._this.workflowFolderIdToFormIdToFormsMap[workflowFolder.id],
                };
            }
        });
    };

    /**
     *
     * @param {import("@tonkean/tonkean-entities").Form["id"]} formId
     * @param {import("@tonkean/tonkean-entities").WorkflowVersionType} workflowVersionType
     * @param {any} referralData
     * @returns {Promise<import("@tonkean/tonkean-entities").Form>}
     */
    _this.getFormByWorkflowVersionType = function (formId, workflowVersionType, referralData) {
        if (_this.formCache?.[formId]?.[workflowVersionType]) {
            return $q.resolve(_this.formCache[formId][workflowVersionType]);
        }

        if (!_this.formCache[formId]) {
            _this.formCache[formId] = {};
        }

        let promise;

        if (workflowVersionType === 'DRAFT') {
            promise = tonkeanService.getDraftWorkerForm(formId, referralData);
        } else if (workflowVersionType === 'PUBLISHED') {
            if (referralData?.referringInitiative) {
                promise = tonkeanService.getPublishedWorkerFormForInitiative(formId, referralData);
            } else {
                promise = tonkeanService.getPublishedWorkerForm(formId, referralData);
            }
        }

        return promise.then((form) => {
            _this.formCache[formId][workflowVersionType] = form;
            return form;
        });
    };

    /**
     *
     * @param {import("@tonkean/tonkean-entities").Form} form
     * @param {import("@tonkean/tonkean-entities").WorkflowVersionType?} workflowVersionType
     */
    _this.cacheFormByWorkflowVersionType = function (form, workflowVersionType) {
        if (!_this.formCache[form.id]) {
            _this.formCache[form.id] = {};
        }
        _this.formCache[form.id][workflowVersionType] = form;
    };
}

angular.module('tonkean.app').service('formManager', FormManager);
