<div class="modal-header">
    <!--<h3 class="modal-title">{{currentIntegration.displayName}}</h3>-->
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body padding-bottom-none">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>
    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="signIn()"
        ng-hide="loading || integration"
    >
        <div>
            <div class="form-group">
                <label for="mysql-type" class="col-sm-2 control-label">Database Type</label>

                <div class="col-sm-10">
                    <select
                        required
                        ng-model="data.credentials.dbType"
                        class="form-control"
                        placeholder="Select your database"
                        id="mysql-type"
                    >
                        <option value="mysql">MySQL</option>
                        <option value="mssql">Microsoft SQL Server</option>
                        <option value="postgres">Postgres</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label for="mysql-url" class="col-sm-2 control-label">URL</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        ng-model="data.credentials.address"
                        class="form-control"
                        id="mysql-url"
                        placeholder="Your MySql server's address"
                        required
                    />
                    <div class="common-subtitle-inner">
                        <i>
                            i.e.
                            <span class="common-underline">your-mysql-server</span>
                            .domain.net
                        </i>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="login-username" class="col-sm-2 control-label">Username</label>
                <div class="col-sm-10">
                    <input
                        type="text"
                        ng-model="data.credentials.user"
                        class="form-control"
                        id="login-username"
                        placeholder="Username"
                        autocomplete="off"
                        required
                    />
                    <div class="common-subtitle-inner">
                        <i>
                            Must be a user
                            <span class="common-underline">with read access</span>
                            to the server.
                        </i>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="login-password" class="col-sm-2 control-label">Password</label>

                <div class="col-sm-10">
                    <input
                        type="password"
                        ng-model="data.credentials.password"
                        class="form-control"
                        id="login-password"
                        autocomplete="off"
                        placeholder="Password"
                        required
                    />
                </div>
            </div>
            <div class="form-group">
                <label for="login-schema" class="col-sm-2 control-label">Schema</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        ng-model="data.credentials.schema"
                        class="form-control"
                        id="login-schema"
                        placeholder="Schema"
                        autocomplete="off"
                        required
                    />
                </div>
            </div>
            <div class="form-group">
                <label for="login-port" class="col-sm-2 control-label">Port</label>

                <div class="col-sm-10">
                    <input
                        type="text"
                        ng-model="data.credentials.port"
                        class="form-control"
                        id="login-port"
                        placeholder="Port"
                        autocomplete="off"
                        required
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-2"></div>

                <div class="col-sm-10">
                    <input type="checkbox" ng-model="data.credentials.useSsl" class="margin-right-xs" id="use-ssl" />
                    <label for="use-ssl">Use SSL</label>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7">
                    <div class="margin-normal-sm-v common-subtitle-inner common-size-xxs">
                        <i class="fa fa-info-circle margin-right"></i>
                        We only need read permissions!
                    </div>
                </div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                        ng-disabled="!data.loginModal.$valid"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Connected to schema
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!data.loginModal.$valid">OK</button>
</div>
