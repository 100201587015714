import React from 'react';
import styled from 'styled-components';

import NlpBranchExpendableContent from './NlpBranchExpendableContent';
import SingleWorkerRunActionInspectNlpStatus from './SingleWorkerRunActionInspectNlpStatus';
import TrainingSetAnyOfTheRestExpendable from './TrainingSetAnyOfTheRestExpendable';
import { ReactComponent as ModelIcon } from '../../../../../../images/icons/model.svg';

import { LogicIcon } from '@tonkean/infrastructure';
import { CustomTriggerType } from '@tonkean/tonkean-entities';
import type { TrainingSetModelSummary } from '@tonkean/tonkean-entities';
import type { TrainingSetNLPBranchResult } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledNlpActionsIcon = styled(LogicIcon)`
    width: 16px !important;
    height: 16px !important;
    margin-right: 8px;
`;

export const NLPBranchName = styled.div<{ dark: boolean }>`
    color: ${({ dark }) => (dark ? Theme.colors.gray_800 : Theme.colors.gray_700)};
    font-weight: ${({ dark }) => (dark ? 500 : 400)};
`;

const StyledModelIcon = styled(ModelIcon)`
    margin-right: 8px;
`;

function getTrainingSetNlpActionBranchAsRow(
    branchImpact: any,
    logicBlockTypes: any,
    modelSummariesOfBranch: TrainingSetModelSummary[],
    branchResult?: TrainingSetNLPBranchResult,
    activeBranchWorkerRunLogic?: any,
) {
    const logicBlockType = branchImpact.node.customTriggerType && logicBlockTypes[branchImpact.node.customTriggerType];
    const oneModelForBranch = modelSummariesOfBranch.length === 1;
    const isActive = activeBranchWorkerRunLogic?.customTriggerId === branchImpact.node.id;

    const showMatchedInformationExpendable =
        oneModelForBranch && branchResult?.modelResults[0]?.resultEnrichment.matchInformation?.matched;

    const showAnyOfTheRestExpendable =
        branchImpact.node.customTriggerType === CustomTriggerType.NLP_BRANCH_ANY_OF_THE_REST &&
        modelSummariesOfBranch.length;

    const activationExplainText =
        branchImpact.node.customTriggerType === CustomTriggerType.NLP_BRANCH_ANY_OF_THE_REST
            ? 'Activated when a model without a specific branch was matched with the received text'
            : 'Activated when the system could not find a model to handle the received text';

    return {
        expendableBorder: showMatchedInformationExpendable && !showAnyOfTheRestExpendable,
        branch: (
            <Wrapper>
                <StyledNlpActionsIcon logicBlockType={logicBlockType} />
                <NLPBranchName dark={isActive}>{branchImpact.node.displayName}</NLPBranchName>
            </Wrapper>
        ),
        status: (
            <Wrapper>
                <SingleWorkerRunActionInspectNlpStatus
                    matchInformation={
                        oneModelForBranch ? branchResult?.modelResults[0]?.resultEnrichment.matchInformation : undefined
                    }
                    failureReason={
                        oneModelForBranch ? branchResult?.modelResults[0]?.resultEnrichment.failureReason : undefined
                    }
                    active={isActive}
                    branchType={branchImpact.node.customTriggerType}
                    activationExplainText={activationExplainText}
                />
            </Wrapper>
        ),
        model: (
            <>
                {oneModelForBranch && modelSummariesOfBranch[0] && (
                    <Wrapper>
                        <StyledModelIcon />
                        {modelSummariesOfBranch[0].displayName}
                    </Wrapper>
                )}
            </>
        ),
        expendable:
            showMatchedInformationExpendable || showAnyOfTheRestExpendable ? (
                <>
                    {showAnyOfTheRestExpendable ? (
                        <TrainingSetAnyOfTheRestExpendable
                            modelSummariesOfBranch={modelSummariesOfBranch}
                            branchResult={branchResult}
                        />
                    ) : (
                        <NlpBranchExpendableContent
                            matchedResult={branchResult?.modelResults[0]?.resultEnrichment.matchInformation}
                        />
                    )}
                </>
            ) : undefined,
        highlighted: isActive,
    };
}

export default getTrainingSetNlpActionBranchAsRow;
