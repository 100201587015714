import React, { useMemo } from 'react';

import { ReactComponent as ErrorWarningIcon } from '../../../../../../images/icons/error-warning.svg';
import { ReactComponent as RedErrorIcon } from '../../../../../../images/icons/red-error.svg';
import type { EnterpriseComponentOverViewStepKey } from '../../../entities/EnterpriseComponentPageMenuConfiguration';

import type { EnterpriseComponentType } from '@tonkean/tonkean-entities';
import type { ConvertEnterpriseComponentTypeToOverviewResult } from '@tonkean/tonkean-entities';
import { EnterpriseComponentOverviewStepStatus } from '@tonkean/tonkean-entities';

interface Props<T extends EnterpriseComponentType> {
    enterpriseComponentOverviewStepKeys: EnterpriseComponentOverViewStepKey<T>[];
    enterpriseComponentOverviewResult: ConvertEnterpriseComponentTypeToOverviewResult<T>;
}

const EnterpriseComponentPageMenuItemOverviewIcon = <T extends EnterpriseComponentType>({
    enterpriseComponentOverviewStepKeys,
    enterpriseComponentOverviewResult,
}: Props<T>) => {
    const statuses = useMemo(() => {
        return new Set(
            enterpriseComponentOverviewStepKeys.map(
                (stepKey) =>
                    enterpriseComponentOverviewResult[stepKey] as unknown as EnterpriseComponentOverviewStepStatus,
            ),
        );
    }, [enterpriseComponentOverviewResult, enterpriseComponentOverviewStepKeys]);

    if (statuses.has(EnterpriseComponentOverviewStepStatus.ERROR)) {
        return <RedErrorIcon />;
    } else if (statuses.has(EnterpriseComponentOverviewStepStatus.WARNING)) {
        return <ErrorWarningIcon />;
    } else {
        return <></>;
    }
};

export default EnterpriseComponentPageMenuItemOverviewIcon;
