import utils from '@tonkean/utils';

/**
 * This validates the send form logic has selected a form and adds an appropriate message.
 */
const formMustBeSelectedValidator = (validationObject, definition) => {
    if (utils.isNullOrUndefined(definition.formId)) {
        validationObject.noFormSelected = 'You must select a form.';
    }
};

export default formMustBeSelectedValidator;
