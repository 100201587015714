import React, { useEffect, useState } from 'react';

import ProjectIntegrationActionCreateModalBody from './ProjectIntegrationActionCreateModalBody';
import ProjectIntegrationActionCreateModalFooter from './ProjectIntegrationActionCreateModalFooter';
import ProjectIntegrationActionCreateModalHeader from './ProjectIntegrationActionCreateModalHeader';
import defaultCustomGetHttpActionDefinition from '../../../../../../../HttpRequestConfiguration/entities/defaultCustomGetHttpActionDefinition';
import defaultCustomPostHttpActionDefinition from '../../../../../../../HttpRequestConfiguration/entities/defaultCustomPostHttpActionDefinition';
import defaultCustomSQLActionDefinition from '../../../../../../../HttpRequestConfiguration/entities/defaultCustomSQLActionDefinition';
import sqlIntegrations from '../../../utils/SqlIntegrations';
import type ActionOutput from '../entities/ActionOutput';

import { Modal, ModalSize } from '@tonkean/infrastructure';
import type { ActionType, IntegrationSupportedEntity, ProjectIntegration } from '@tonkean/tonkean-entities';
import { IntegrationType } from '@tonkean/tonkean-entities';
import { getSmallestAvailableName } from '@tonkean/utils';

interface Props {
    onCreate(id: string): void;
    onCancel: () => void;
    projectIntegration: ProjectIntegration;
    projectIntegrationActionNames: string[];
    isOpen: boolean;
    customActionTypesToExclude?: ActionType[];
    defaultActionType: ActionType;
    dependentProjectIntegrationEntityIds?: string[];
    defaultEntity?: IntegrationSupportedEntity;
    canChooseEntity?: boolean;
    isPostAction?: boolean;
}

const ProjectIntegrationActionCreateModal: React.FC<Props> = ({
    isOpen,
    onCreate,
    onCancel,
    projectIntegration,
    projectIntegrationActionNames,
    customActionTypesToExclude,
    defaultActionType,
    dependentProjectIntegrationEntityIds,
    defaultEntity,
    canChooseEntity = true,
    isPostAction = true,
}) => {
    const isSqlIntegration = sqlIntegrations.includes(projectIntegration.integrationType.toLowerCase());
    const isCustomDataSource = projectIntegration.integrationType === IntegrationType.WEBHOOK;
    const [isNameValid, setIsNameValid] = useState(true);

    const [description, setDescription] = useState<string>('');
    const [actionOutput, setActionOutput] = useState<ActionOutput | undefined>(
        isSqlIntegration || isCustomDataSource ? { type: defaultActionType } : undefined,
    );
    const ACTION_DEFAULT_NAME = isSqlIntegration
        ? 'New SQL Custom Action'
        : `New HTTP ${defaultActionType.toLowerCase() || 'Custom'} Action`;
    const [name, setName] = useState<string>(ACTION_DEFAULT_NAME);

    // Initialize to a default name
    useEffect(() => {
        setName(getSmallestAvailableName(ACTION_DEFAULT_NAME, projectIntegrationActionNames));
    }, [ACTION_DEFAULT_NAME, projectIntegrationActionNames]);

    const defaultHttpAction = isPostAction
        ? defaultCustomPostHttpActionDefinition
        : defaultCustomGetHttpActionDefinition;

    const defaultCustomActionDefinition = sqlIntegrations.includes(projectIntegration.integrationType.toLowerCase())
        ? defaultCustomSQLActionDefinition
        : defaultHttpAction;

    return (
        <Modal open={isOpen} onClose={onCancel} size={ModalSize.MEDIUM}>
            <ProjectIntegrationActionCreateModalHeader
                onCancel={onCancel}
                projectIntegration={projectIntegration}
                projectIntegrationActionNames={projectIntegrationActionNames}
                setIsNameValid={setIsNameValid}
                defaultName={name}
                setName={setName}
            />

            <ProjectIntegrationActionCreateModalBody
                projectIntegration={projectIntegration}
                setActionOutput={setActionOutput}
                setDescription={setDescription}
                customActionTypesToExclude={customActionTypesToExclude}
                defaultActionType={defaultActionType}
                defaultEntity={defaultEntity}
                canChooseEntity={canChooseEntity}
            />

            <ProjectIntegrationActionCreateModalFooter
                onCreate={onCreate}
                onCancel={onCancel}
                projectIntegration={projectIntegration}
                actionOutput={actionOutput}
                description={description}
                isNameValid={isNameValid}
                name={name}
                defaultActionDefinition={defaultCustomActionDefinition}
                dependentProjectIntegrationEntityIds={dependentProjectIntegrationEntityIds}
            />
        </Modal>
    );
};

export default ProjectIntegrationActionCreateModal;
