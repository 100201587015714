import NumericDateBinaryOperatorBase from './NumericDateBinaryOperatorBase';

import { OperatorKey } from '@tonkean/tonkean-entities';

export default class SubtractionOperator extends NumericDateBinaryOperatorBase {
    override readonly key = OperatorKey.SUBTRACTION;
    override readonly sign = '-';
    override readonly displayName = 'Subtract';
    override readonly description = 'Will subtract the right hand field from the left hand field.';
}
