<div class="margin-left margin-top padding-right-xs">
    <i ng-if="data.loading" class="loading"></i>

    <div ng-if="data.definition.customActionKey === 'CREATE_ORDER'" class="margin-bottom">
        <!-- Email -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Email:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Email"
                saved-expression="data.definition.emailExpression"
                on-tonkean-expression-changed="onEmailTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Customer ID -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Customer ID:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Customer ID"
                saved-expression="data.definition.customerIdExpression"
                on-tonkean-expression-changed="onCustomerIdTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Line Items Array -->
        <div class="margin-bottom margin-top-lg">
            <div ng-if="data.definition.lineItems.length > 0" class="margin-right margin-top-xs margin-bottom">
                Line Items ({{ data.definition.lineItems.length }}):
            </div>

            <div ng-repeat="(lineItemIndex, lineItem) in data.definition.lineItems" class="flex">
                <div class="margin-left-xs margin-right-xs margin-top">{{ lineItemIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Product ID -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">Product ID:</div>

                            <div class="flex-grow"></div>

                            <button
                                class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                ng-click="removeLineItem(lineItemIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.lineItems[lineItemIndex].productId"
                            on-tonkean-expression-changed="
                                onLineItemProductIdTonkeanExpressionChanged(expression, lineItemIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Variant ID -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Variant ID:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.lineItems[lineItemIndex].variantId"
                            on-tonkean-expression-changed="
                                onLineItemVariantIdTonkeanExpressionChanged(expression, lineItemIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Price -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Price:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.lineItems[lineItemIndex].price"
                            on-tonkean-expression-changed="
                                onLineItemPriceTonkeanExpressionChanged(expression, lineItemIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Quantity -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Quantity:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.lineItems[lineItemIndex].quantity"
                            on-tonkean-expression-changed="
                                onLineItemQuantityTonkeanExpressionChanged(expression, lineItemIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Title -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Title:</div>
                        <tnk-tonkean-expression
                            class="flex-grow"
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            saved-expression="data.definition.lineItems[lineItemIndex].title"
                            on-tonkean-expression-changed="
                                onLineItemTitleTonkeanExpressionChanged(expression, lineItemIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Properties (Additional Data) Array -->
                    <div
                        ng-repeat="(propertyIndex, property) in data.definition.lineItems[lineItemIndex].properties"
                        class="flex"
                    >
                        <div class="margin-left-xs margin-right-xs margin-top">
                            {{ lineItemIndex + 1 }}.{{ propertyIndex + 1 }}.
                        </div>
                        <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                            <!-- Key -->
                            <div class="margin-bottom">
                                <div class="flex-vmiddle">
                                    <div class="margin-right margin-top-xs">Key:</div>

                                    <div class="flex-grow"></div>

                                    <button
                                        class="
                                            svg-icon-xs
                                            common-color-grey
                                            svg-icon-hover-primary
                                            btn btn-no-border btn-on-hover
                                            pointer
                                            flex-no-shrink
                                            margin-left-xxs
                                        "
                                        ng-click="removeProperty(lineItemIndex, propertyIndex)"
                                    >
                                        <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                                    </button>
                                </div>

                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    saved-expression="data.definition.lineItems[lineItemIndex].properties[propertyIndex].key"
                                    on-tonkean-expression-changed="
                                        onPropertyKeyTonkeanExpressionChanged(
                                            expression,
                                            lineItemIndex,
                                            propertyIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>

                            <!-- Value -->
                            <div class="margin-bottom">
                                <div class="margin-right margin-top-xs">Value:</div>
                                <tnk-tonkean-expression
                                    class="flex-grow"
                                    group-id="{{ data.groupId }}"
                                    workflow-version-id="{{ data.workflowVersionId }}"
                                    logic-id="{{ data.configuredLogic.node.id }}"
                                    preview-evaluation-source="data.previewEvaluationSource"
                                    saved-expression="data.definition.lineItems[lineItemIndex].properties[propertyIndex].value"
                                    on-tonkean-expression-changed="
                                        onPropertyValueTonkeanExpressionChanged(
                                            expression,
                                            lineItemIndex,
                                            propertyIndex,
                                            shouldSaveLogic
                                        )
                                    "
                                ></tnk-tonkean-expression>
                            </div>
                        </div>
                    </div>
                    <a ng-click="addProperty(lineItemIndex)">+ Add Additional Data</a>
                </div>
            </div>

            <a ng-click="addLineItem()">+ Add Line Item</a>
        </div>

        <!-- Discounts Array -->
        <div class="margin-bottom-lg">
            <div ng-if="data.definition.discounts.length > 0" class="margin-right margin-top-xs margin-bottom">
                Discounts ({{ data.definition.discounts.length }}):
            </div>

            <div ng-repeat="(discountIndex, discount) in data.definition.discounts" class="flex">
                <div class="margin-left-xs margin-right-xs margin-top">{{ discountIndex + 1 }}.</div>
                <div class="margin-bottom attribute-box common-border-dark-softer padding-normal-xs flex-grow">
                    <!-- Discount Type -->
                    <div class="margin-bottom">
                        <div class="flex-vmiddle">
                            <div class="margin-right margin-top-xs">
                                Discount Type:
                                <i
                                    class="fa fa-info-circle common-color-primary margin-left-xs"
                                    uib-tooltip="If selected, please also enter a value in the 'Discount Amount' field."
                                ></i>
                            </div>

                            <div class="flex-grow"></div>

                            <button
                                class="
                                    svg-icon-xs
                                    common-color-grey
                                    svg-icon-hover-primary
                                    btn btn-no-border btn-on-hover
                                    pointer
                                    flex-no-shrink
                                    margin-left-xxs
                                "
                                ng-click="removeDiscount(discountIndex)"
                            >
                                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                            </button>
                        </div>
                        <ui-select
                            theme="bootstrap"
                            ng-model="data.definition.discounts[discountIndex].discountType"
                            ng-change="onDefinitionChanged(true)"
                        >
                            <ui-select-match placeholder="Select option">
                                {{ data.definition.discounts[discountIndex].discountType }}
                            </ui-select-match>

                            <ui-select-choices
                                repeat="discountType in data.possibleDiscountTypes | filter:$select.search"
                            >
                                <div ng-bind-html="discountType | escapeHtml | highlight: $select.search"></div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <!-- Discount Code -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">
                            Discount Code:
                            <i
                                class="fa fa-info-circle common-color-primary margin-left-xs"
                                uib-tooltip="Defaults to 'Custom discount'. For the discount to be applied, you must also enter values for Discount Type and Amount."
                            ></i>
                        </div>
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Discount Code"
                            saved-expression="data.definition.discounts[discountIndex].discountCodeExpression"
                            on-tonkean-expression-changed="
                                onDiscountCodeTonkeanExpressionChanged(expression, discountIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Discount Amount -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">
                            Discount Amount:
                            <i
                                class="fa fa-info-circle common-color-primary margin-left-xs"
                                uib-tooltip="The 'Discount Type' field determines how this value is used. For a fixed amount, enter 5.00 to discount $5.00. For a percentage, enter .25 to discount 25%."
                            ></i>
                        </div>
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Discount Amount"
                            saved-expression="data.definition.discounts[discountIndex].discountAmountExpression"
                            on-tonkean-expression-changed="
                                onDiscountAmountTonkeanExpressionChanged(expression, discountIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>
                </div>
            </div>

            <a ng-click="addDiscount()">+ Add Discount</a>
        </div>

        <!-- Send Receipt -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Send Receipt:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Whether to send an order confirmation to the customer."
                ></i>
            </div>
            <ui-select theme="bootstrap" ng-model="data.definition.sendReceipt" ng-change="onDefinitionChanged(true)">
                <ui-select-match placeholder="Select option">
                    {{ data.definition.sendReceipt }}
                </ui-select-match>

                <ui-select-choices repeat="booleanOption in data.possibleBooleanOptions | filter:$select.search">
                    <div ng-bind-html="booleanOption | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Send Shipping Confirmation -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Send Shipping Confirmation:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Whether to send a shipping confirmation to the customer."
                ></i>
            </div>
            <ui-select
                theme="bootstrap"
                ng-model="data.definition.sendFulfillmentReceipt"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Select option">
                    {{ data.definition.sendFulfillmentReceipt }}
                </ui-select-match>

                <ui-select-choices repeat="booleanOption in data.possibleBooleanOptions | filter:$select.search">
                    <div ng-bind-html="booleanOption | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Inventory Behaviour -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Inventory Behaviour:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The behaviour to use when updating inventory."
                ></i>
            </div>
            <ui-select
                theme="bootstrap"
                ng-model="data.definition.inventoryBehaviour"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Select option">
                    {{ data.definition.inventoryBehaviour }}
                </ui-select-match>

                <ui-select-choices
                    repeat="inventoryBehaviour in data.possibleInventoryBehaviours | filter:$select.search"
                >
                    <div ng-bind-html="inventoryBehaviour | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Fulfillment Status -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Fulfillment Status:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The order's status in terms of fulfilled line items."
                ></i>
            </div>
            <ui-select
                theme="bootstrap"
                ng-model="data.definition.fulfillmentStatus"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Select option">
                    {{ data.definition.fulfillmentStatus }}
                </ui-select-match>

                <ui-select-choices
                    repeat="fulfillmentStatus in data.possibleFulfillmentStatuses | filter:$select.search"
                >
                    <div ng-bind-html="fulfillmentStatus | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Fulfillment Status -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Financial Status:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The status of payments associated with the order. Can only be set when the order is created."
                ></i>
            </div>
            <ui-select
                theme="bootstrap"
                ng-model="data.definition.financialStatus"
                ng-change="onDefinitionChanged(true)"
            >
                <ui-select-match placeholder="Select option">
                    {{ data.definition.financialStatus }}
                </ui-select-match>

                <ui-select-choices repeat="financialStatus in data.possibleFinancialStatuses | filter:$select.search">
                    <div ng-bind-html="financialStatus | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <!-- Note -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Note:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Note"
                saved-expression="data.definition.noteExpression"
                on-tonkean-expression-changed="onNoteTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Tags -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Tags:</div>

            <!-- Tonkean custom expression input -->
            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Tags"
                saved-expression="data.definition.tagsExpression"
                on-tonkean-expression-changed="onTagsTonkeanExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Shipping/Billing Address  -->
        <div class="margin-bottom margin-top-lg">
            <div ng-repeat="(addressIndex, address) in data.definition.addresses" class="margin-bottom margin-top">
                <!-- Address Header  -->
                <div class="margin-bottom">
                    <div class="flex-vmiddle">
                        <div class="margin-right margin-top-xs">
                            {{
                                data.definition.addresses[addressIndex].addressType === 'shipping_address'
                                    ? 'Shipping Address'
                                    : 'Billing Address'
                            }}
                        </div>

                        <div class="flex-grow"></div>

                        <button
                            class="
                                svg-icon-xs
                                common-color-grey
                                svg-icon-hover-primary
                                btn btn-no-border btn-on-hover
                                pointer
                                flex-no-shrink
                                margin-left-xxs
                            "
                            ng-click="removeAddress(data.definition.addresses[addressIndex].addressType)"
                        >
                            <tnk-icon src="/images/icons/x.svg"></tnk-icon>
                        </button>
                    </div>
                </div>

                <div class="margin-left">
                    <!-- First Name  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">First Name:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="First Name"
                            saved-expression="data.definition.addresses[addressIndex].firstNameExpression"
                            on-tonkean-expression-changed="
                                onAddressFirstNameExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Last Name  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Last Name:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Last Name"
                            saved-expression="data.definition.addresses[addressIndex].lastNameExpression"
                            on-tonkean-expression-changed="
                                onAddressLastNameExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Company  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Company:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Company"
                            saved-expression="data.definition.addresses[addressIndex].companyExpression"
                            on-tonkean-expression-changed="
                                onAddressCompanyExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Phone  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Phone:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Phone"
                            saved-expression="data.definition.addresses[addressIndex].phoneExpression"
                            on-tonkean-expression-changed="
                                onAddressPhoneExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Address  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Address:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Address"
                            saved-expression="data.definition.addresses[addressIndex].address1Expression"
                            on-tonkean-expression-changed="
                                onAddressAddress1ExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Address2  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Address Line 2:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Address Line 2"
                            saved-expression="data.definition.addresses[addressIndex].address2Expression"
                            on-tonkean-expression-changed="
                                onAddressAddress2ExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- City  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">City:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="City"
                            saved-expression="data.definition.addresses[addressIndex].cityExpression"
                            on-tonkean-expression-changed="
                                onAddressCityExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Country  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Country:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Country"
                            saved-expression="data.definition.addresses[addressIndex].countryExpression"
                            on-tonkean-expression-changed="
                                onAddressCountryExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Country Code -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Country Code:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Country Code"
                            saved-expression="data.definition.addresses[addressIndex].countryCodeExpression"
                            on-tonkean-expression-changed="
                                onAddressCountryCodeExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- State/Province  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">State/Province:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="State/Province"
                            saved-expression="data.definition.addresses[addressIndex].provinceExpression"
                            on-tonkean-expression-changed="
                                onAddressProvinceExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- State/Province Code -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">State/Province Code:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="State/Province Code"
                            saved-expression="data.definition.addresses[addressIndex].provinceCodeExpression"
                            on-tonkean-expression-changed="
                                onAddressProvinceCodeExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>

                    <!-- Zip Code  -->
                    <div class="margin-bottom">
                        <div class="margin-right margin-top-xs">Zip Code:</div>

                        <!-- Tonkean custom expression input -->
                        <tnk-tonkean-expression
                            group-id="{{ data.groupId }}"
                            workflow-version-id="{{ data.workflowVersionId }}"
                            logic-id="{{ data.configuredLogic.node.id }}"
                            preview-evaluation-source="data.previewEvaluationSource"
                            placeholder="Zip Code"
                            saved-expression="data.definition.addresses[addressIndex].zipExpression"
                            on-tonkean-expression-changed="
                                onAddressZipExpressionChanged(expression, addressIndex, shouldSaveLogic)
                            "
                        ></tnk-tonkean-expression>
                    </div>
                </div>
            </div>
            <div ng-hide="addressTypeExists('shipping_address')" class="margin-bottom">
                <a ng-click="addAddress('shipping_address')">+ Add Shipping Address</a>
            </div>
            <div ng-hide="addressTypeExists('billing_address')" class="margin-bottom">
                <a ng-click="addAddress('billing_address')">+ Add Billing Address</a>
            </div>
        </div>
    </div>
</div>
