import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addItemIsAddedCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addItemIsAddedCreateAfterOption';
import addMonitorBlockCreateAfterOption from '../sharedConfigComponents/innerAutonomous/createAfterOptions/addMonitorBlockCreateAfterOption';

import { CustomTriggerType, LogicBlockConfigType } from '@tonkean/tonkean-entities';

const ApprovalCycleLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.APPROVAL_CYCLE,
    title: 'Create Approval Cycle',
    iconClass: 'mod-approve',
    creatingActionItems: true,
    description: 'Begin an approval cycle that will complete when all Reviewers have agreed to move on.',
    cantMoveInto: true,
    doNotDisplayStatusEdit: true,
    createAfterOptionsTitle: 'Run on',
    createAfterOptions: (features) => {
        if (features?.tonkean_show_inner_item_idd) {
            return [addItemIsAddedCreateAfterOption, addMonitorBlockCreateAfterOption];
        } else {
            return [addMonitorBlockCreateAfterOption];
        }
    },
    allowedChildrenTypes: () => [LogicBlockConfigType.MONITOR_TRACKS],
    innerItemDetailsTitlePlaceholder: 'Approver',
    innerItemDetailsDescriptionPlaceholder: 'A process contributor that should approve the business request',
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};

        if (!definition.reviewerPersonIds?.length) {
            validationObject.noReviewers = 'Must have at least 1 reviewer.';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
    parentValidationOnCreation: (parent, $emit) => {
        if (
            parent?.customTriggerType &&
            (parent.customTriggerType === CustomTriggerType.AUTONOMOUS ||
                parent.customTriggerType === CustomTriggerType.INNER_AUTONOMOUS) &&
            parent.monitorInnerItems
        ) {
            $emit('alert', {
                msg: 'You cannot create Approval Cycle as an inner child of a trigger that monitor inner items creation',
                type: 'error',
            });
            return false;
        } else {
            return true;
        }
    },
};

export default ApprovalCycleLogicBlockConfig;
