import { useCallback, useState } from 'react';

import { SEARCH_INITIATIVES_QUERY_PARAM } from '../../../SystemInterface/utils/navigationQueryParams';

import { useSetQueryParamOnLocation } from '@tonkean/infrastructure';
import type { ColumnId } from '@tonkean/items-grid';
import type { SearchInitiativesQuery, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

const useDrilldownInterface = (searchInitiativesQuery?: SearchInitiativesQuery, columnIdToClickOn?: ColumnId) => {
    const setQueryParamOnLocation = useSetQueryParamOnLocation();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalInitiativeId, setModalInitiativeId] = useState<TonkeanId<TonkeanType.INITIATIVE>>();

    const onRowClicked = useCallback(
        (params: { columnId: string | undefined; initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined }) => {
            if (params.initiativeId && (!columnIdToClickOn || params.columnId === columnIdToClickOn)) {
                setModalInitiativeId(params.initiativeId);
                setModalIsOpen(true);

                if (searchInitiativesQuery) {
                    setQueryParamOnLocation('tid', params.initiativeId);
                    setQueryParamOnLocation(
                        SEARCH_INITIATIVES_QUERY_PARAM,
                        encodeURI(btoa(encodeURIComponent(JSON.stringify(searchInitiativesQuery)))),
                    );
                }
            }
        },
        [setQueryParamOnLocation, columnIdToClickOn, searchInitiativesQuery],
    );

    const onClose = useCallback(() => {
        setModalIsOpen(false);
        setModalInitiativeId(undefined);
        setQueryParamOnLocation(SEARCH_INITIATIVES_QUERY_PARAM, null);
    }, [setQueryParamOnLocation]);

    return {
        modalInitiativeId,
        setModalInitiativeId,
        onRowClicked,
        onClose,
        modalIsOpen,
    };
};

export default useDrilldownInterface;
