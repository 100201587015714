import React, { useMemo } from 'react';
import styled from 'styled-components';

import {
    ExtendableRowColoredText,
    ExtendableRowText,
    HeaderWrapper,
    InnerContentWrapper,
} from './HandleResponseSharedCriteriaStyle';

import { ExtendableRow, Radio, RadioGroup, TagsInput } from '@tonkean/infrastructure';
import type { HttpRequestDefinition, ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const StyledRadioGroup = styled(RadioGroup)`
    margin-bottom: 22px;
`;
interface Props {
    actionDefinition: HttpRequestDefinition;
    setProjectIntegrationAction: React.Dispatch<React.SetStateAction<ProjectIntegrationAction>>;
}

const RetryCriteria: React.FC<Props> = ({ actionDefinition, setProjectIntegrationAction }) => {
    // decide the retryCriteriaHeaderText by if there is codes to retry.
    const retryCriteriaHeaderText = useMemo(() => {
        if (!!actionDefinition.retryOnStatusCodes) {
            return 'Retry on specific codes (advanced)';
        } else {
            return 'Do not retry this action (default)';
        }
    }, [actionDefinition.retryOnStatusCodes]);

    // on Adding/Removing status code we validate the given input is a real code and then inset to our project integration action.
    const onRetryOnStatusCodesArrayChanged = (updatedStatusCodesArray: string[]) => {
        setProjectIntegrationAction((prevAction) => {
            return {
                ...prevAction,
                actionDefinition: {
                    ...prevAction.actionDefinition,
                    retryOnStatusCodes: updatedStatusCodesArray
                        .map((code) => Number.parseInt(code))
                        .filter((code) => code !== Number.NaN && code >= -1 && code < 600),
                },
            };
        });
    };

    // List of status codes as string for TagsInput.
    const retryCodesArrayAsStrings = useMemo(() => {
        return actionDefinition.retryOnStatusCodes?.map((code) => code.toString()) || [];
    }, [actionDefinition.retryOnStatusCodes]);

    // On changing the radio button in the retry criteria, we set the retryOnStatusCodes on the project integration action to empty array or undefined.
    const onChangeRetryOnSpecificCodes = (shouldRetryOnStatusCodes: boolean) => {
        setProjectIntegrationAction((currentProjectIntegrationAction) => {
            return {
                ...currentProjectIntegrationAction,
                actionDefinition: {
                    ...currentProjectIntegrationAction.actionDefinition,
                    retryOnStatusCodes: shouldRetryOnStatusCodes ? [] : undefined,
                },
            };
        });
        return shouldRetryOnStatusCodes;
    };

    return (
        <ExtendableRow
            header={
                <HeaderWrapper>
                    <ExtendableRowColoredText color={Theme.colors.warning}>Retry Criteria:</ExtendableRowColoredText>
                    <ExtendableRowText>{retryCriteriaHeaderText}</ExtendableRowText>
                </HeaderWrapper>
            }
        >
            <InnerContentWrapper>
                <StyledRadioGroup
                    value={!!actionDefinition.retryOnStatusCodes}
                    direction="row"
                    onChange={onChangeRetryOnSpecificCodes}
                    size={InputSize.MEDIUM}
                >
                    <Radio value>Retry on specific codes (advanced)</Radio>
                    <Radio value={false}>Do not retry this action (default)</Radio>
                </StyledRadioGroup>

                <TagsInput
                    placeholder="Type or select specific codes to retry"
                    value={retryCodesArrayAsStrings}
                    isDisabled={!actionDefinition.retryOnStatusCodes}
                    onChange={onRetryOnStatusCodesArrayChanged}
                />
            </InnerContentWrapper>
        </ExtendableRow>
    );
};

export default RetryCriteria;
