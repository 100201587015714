<div class="preview-key-metrics">
    <!-- Preview title -->
    <div class="margin-bottom-lg" ng-if="!data.hideTitle">
        <!-- Title -->
        <div class="flex-vmiddle common-bold common-size-xxs margin-right-xs" data-automation="preview-key-metrics-template-title" ng-hide="data.hidePreviewTitle">
            <span class="margin-right-xs">Preview Global Field (Key Metric)</span>
            <i ng-if="data.softLoading" class="loading-small"></i>
            <i class="fa fa-exclamation common-color-danger" ng-if="data.isBrokenIntegration"></i>
        </div>

        <!-- Subtitle -->
        <div ng-if="data.isBrokenIntegration" class="margin-top-xs common-color-danger common-size-xxs">
            The preview data might not be updated, since its connection with Tonkean is broken.
        </div>
    </div>

    <!-- Loading state -->
    <div ng-if="data.loading && !data.softLoading">
        <div class="common-color-light-grey2 common-size-xxs margin-bottom-xs">
            {{ data.highlightedDefinition.name ? data.highlightedDefinition.name : 'Global field name' }}
        </div>

        <div class="flex-vmiddle margin-bottom-lg margin-top">
            <div class="key-metric-loading-placeholder margin-right-xs"></div>
        </div>

        <div ng-if="data.showTracksLoading">
            <hr class="margin-none" />
            <div class="placeholder-container" ng-repeat="item in [1,2,3,4,5,6,7,8,9]">
                <div class="flex-vmiddle padding-normal">
                    <div class="big-loading-placeholder"></div>
                    <div class="small-loading-placeholder"></div>
                </div>
                <hr class="margin-none" />
            </div>
        </div>
    </div>

    <!-- Error state -->
    <div ng-if="!data.loading && data.error" class="common-size-xxs common-color-danger">
        <span class="common-bold">{{ data.error }}</span>
        <a ng-click="reloadPreview()">Refresh</a>
    </div>

    <!-- Item state -->
    <div ng-if="(!data.loading || data.softLoading) && !data.error">
        <!-- Key metric value bar -->
        <div class="flex-vmiddle">
            <div
                ng-repeat="definitionToPreview in data.definitionsToPreview track by definitionToPreview.definitionId"
                ng-class="{ 'margin-right-xlg': !$last }"
            >
                <!-- Key metric title -->
                <div class="common-color-light-grey2 common-size-xxs">
                    {{ definitionToPreview.name ? definitionToPreview.name : 'Global field name' }}
                </div>

                <!-- Key metric value -->
                <div class="common-bold key-metric-value-container">
                    <span ng-style="data.globalDefinitionIdToValueMap[definitionToPreview.definitionId].style">
                        {{ data.globalDefinitionIdToValueMap[definitionToPreview.definitionId].displayValue }}
                    </span>
                </div>
            </div>
        </div>

        <!-- Special info based on key metric type -->
        <div ng-if="data.highlightedDefinition" class="margin-top-lg">
            <div ng-switch="data.highlightedDefinition.fieldDefinitionType">
                <div ng-switch-when="TNK_COLUMN_AGGREGATE">
                    <tnk-preview-list-columns
                        group-id="{{ data.groupId }}"
                        workflow-version-id="{{ data.workflowVersionId }}"
                        definitions-to-preview="data.initiativeDefinitionsToPreview"
                        reload-preview="data.reloadListPreview"
                        hide-preview-title="true"
                        filter-initiatives-by-tonkean-query="data.highlightedDefinition.definition.aggregateQueryDefinition"
                        empty-state-type="NO_RESULTS"
                    ></tnk-preview-list-columns>
                </div>
            </div>
        </div>
    </div>
</div>
