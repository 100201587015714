import { KnowledgeBase } from '@tonkean/constants';

/**
 * Service that holds all needed education information.
 */
function EducationManager($rootScope, modalUtils, utils, groupInfoManager) {
    const _this = this;

    /**
     * educationItems holds all the different items for education modal.
     */
    _this.educationItems = [
        {
            id: 'TRACK',
            stepName: 'viewEducationTracks',
            title: 'Tracks and Owners',
            subtext: 'Choose what you want the Module to follow-up for updates.',
            img: '/images/onBoarding/icon-tracks.png',
            videoId: 'E1hrGWEvyXo',
            description:
                'Tracks are the core of Tonkean. They are the things that you as a manager care about. They are critical items you *want to keep track of*.\nEach track can represent anything from an Account, Customer, Project or a any general initiative.\n\nSetting an *owner* to a Track, activates the Module and allows it to proactively follow up with that owner to get the latest status and take actions on that Track.',
            kbUrl: KnowledgeBase.pages.items,
            modalsCta: [
                {
                    title: 'Import / Sync Tracks',
                    action(groupId, workflowVersionId) {
                        return null;
                    },
                },
            ],
        },
        {
            id: 'DATA',
            stepName: 'viewEducationData',
            title: 'Full visibility with LIVE data',
            subtext: 'Pull information from systems you work with, to see all in one place.',
            img: '/images/onBoarding/icon-visibility.png',
            videoId: 'o8BFmEZz_Vo',
            description:
                'For each item, Tonkean can automatically pull in your most important data points.\nUse *Columns* and *Key metrics* to choose what data will give you the visibility you need. In addition, each of these data points are automatically tracked by Tonkean, and will alert you and the track owner on any noticeable changes that need your attention.',
            kbUrl: KnowledgeBase.pages.connectingDataSources,
            modalsCta: [
                {
                    title: 'Manage Fields',
                    action(groupId, workflowVersionId) {
                        return modalUtils.openManageColumnsModal(groupId, workflowVersionId);
                    },
                },
                {
                    title: 'Add Global Fields (Key Metrics)',
                    action(groupId, workflowVersionId) {
                        return $rootScope.$broadcast('createNewField', [
                            groupId,
                            'GLOBAL',
                            null,
                            null,
                            true,
                            false,
                            null,
                            groupInfoManager.getGroups,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            workflowVersionId,
                        ]);
                    },
                },
            ],
        },
        {
            id: 'BOT',
            stepName: 'viewEducationBot',
            title: 'Intelligent Follow-Ups',
            subtext: 'With Smart-Triggers, Tonkean Module make sure nothing fall through the cracks.',
            img: '/images/onBoarding/icon-automation.png',
            videoId: 'iy6lEP4ajNk',
            description:
                'Tonkean’s brilliant module is here to help you out in your day to day, as well as in times of crisis. It’ll help you focus on leading by doing the grunt work of gathering data and insights from all over the place for you, while still letting you keep a personal touch.',
            kbUrl: KnowledgeBase.pages.modules,
        },
    ];

    /**
     * A map between id to the education item related to it.
     */
    _this.educationItemIdToItemMap = utils.createMapFromArray(_this.educationItems, 'id');

    /**
     * Returns the education item relevant for the given education item type.
     */
    _this.getEducationItem = function (educationItemType) {
        return _this.educationItemIdToItemMap[educationItemType];
    };

    /**
     * Enum for different education items.
     */
    _this.educationItemType = {
        track: 'TRACK',
        data: 'DATA',
        bot: 'BOT',
    };
}

export default angular.module('tonkean.app').service('educationManager', EducationManager);
