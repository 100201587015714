import React from 'react';
import styled from 'styled-components';

import { ButtonRadioGroup, ButtonRadioGroupButton } from '@tonkean/infrastructure';
import { PersonInquirySendTo } from '@tonkean/tonkean-entities';
import { InputSize } from '@tonkean/tui-theme/sizes';

const FullWidthButtonGroup = styled(ButtonRadioGroup)`
    width: 100%;
`;

interface Props {
    sendTo: PersonInquirySendTo;
    onSendToChange(sendTo: PersonInquirySendTo): void;
}

const PersonInquiryButton: React.FC<Props> = ({ sendTo, onSendToChange }) => {
    return (
        <FullWidthButtonGroup size={InputSize.MEDIUM} value={sendTo} onChange={onSendToChange} equalWidth>
            <ButtonRadioGroupButton value={PersonInquirySendTo.PEOPLE_DIRECTORY}>
                Business Groups
            </ButtonRadioGroupButton>
            <ButtonRadioGroupButton value={PersonInquirySendTo.CUSTOM}>Custom</ButtonRadioGroupButton>
        </FullWidthButtonGroup>
    );
};

export default PersonInquiryButton;
