<div class="integration-group mod-inline" ng-hide="inlineSetupScreen">
    <div class="new-product-button-group mod-inline">
        <div class="new-product-button-group-inner">
            <div
                class="new-product-integration-item box-with-shadow"
                ng-repeat="integration in integrations | filter:{name:''}"
                ng-click="!editMode && onClick(integration)"
                ng-class="{ 'mod-clickable': !editMode && !fixMode && !saving }"
                ng-init="projectIntegration = state[integration.name].integrations[0]"
            >
                <!-- Integration logo when icon url is NOT available -->
                <div ng-if="!projectIntegration || !projectIntegration.iconUrl">
                    <!-- Integration logo (inline) -->
                    <i
                        class="integration-group-header-connected-logo hidden-xs margin-none mod-confirm mod-{{
                            integration.name
                        }}"
                    ></i>
                </div>
                <!-- Integration logo when icon url IS available -->
                <div
                    ng-if="projectIntegration && projectIntegration.iconUrl"
                    class="integration-group-header-connected-logo mod-confirm hidden-xs mod-manual-icon margin-none"
                    uib-tooltip="{{ state[integration.name].integrations[0].displayName || integration.displayName }}"
                >
                    <i
                        class="initiative-integration-icon mod-normal margin-right-xs"
                        ng-style="{ background: 'url(' + projectIntegration.iconUrl + ') no-repeat center' }"
                    ></i>
                    <span>{{ state[integration.name].integrations[0].displayName || integration.displayName }}</span>
                </div>

                <!-- Integration name (inline) -->
                <span class="margin-left margin-right-xs">
                    {{
                        (state[integration.name].integrations[0].displayName || integration.displayName) +
                            (state[integration.name].integrations[0].integration.isNativeIntegration &&
                            !state[integration.name].integrations[0].integration.supportsMultipleIntegrationsPerUser
                                ? ' (' + state[integration.name].integrations[0].creator.firstName + ')'
                                : '')
                    }}
                </span>

                <div class="flex-vmiddle flex-grow common-width-auto">
                    <div class="flex-grow">
                        <!-- Integration is authenticating (inline) -->
                        <span
                            ng-if="state[integration.name].connecting"
                            uib-tooltip="Authentication is in progress, popup window is open."
                            class="margin-left common-color-primary"
                        >
                            Authenticating...
                        </span>
                    </div>

                    <!-- Loading icon -->
                    <i class="loading-medium margin-right-xs" ng-show="updatingIntegrations[integration.name]"></i>

                    <div ng-include="'./integrationGroupButtonTemplate.template.html'"></div>
                </div>
                <!-- If we're in field fix mode, we do not want the plus sign! -->
                <i ng-if="!fixMode && !state[integration.name]" class="fa fa-plus margin-left"></i>
            </div>
        </div>
    </div>
</div>
