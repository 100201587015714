import type { TreeNodeItem } from './TreeNodeItem';

/**
 * A tree node class with parent and children pointers.
 */
export class TreeNode<T extends TreeNodeItem> {
    public children: TreeNode<T>[] = [];
    public parent: TreeNode<T> | null;

    constructor(public item: T) {}

    /**
     * Returns true of this group is a parent of the
     * group from the param.
     */
    public isParentOf(groupToCompare: TreeNode<T>): boolean {
        const startSmallerThanGroupToCompare = this.item.startIndex <= groupToCompare.item.startIndex;
        const endBiggerThanGroupToCompare = this.item.endIndex >= groupToCompare.item.endIndex;

        return startSmallerThanGroupToCompare && endBiggerThanGroupToCompare;
    }

    /**
     * Returns true if this group is a child of the
     * group from the param.
     */
    public isChildOf(groupToCompare: TreeNode<T>): boolean {
        const startBiggerThanGroupToCompare = this.item.startIndex >= groupToCompare.item.startIndex;
        const endSmallerThenGroupToCompare = this.item.endIndex <= groupToCompare.item.endIndex;

        return startBiggerThanGroupToCompare && endSmallerThenGroupToCompare;
    }

    /**
     * Remove a child from the current node.
     */
    private removeChildNode(child: TreeNode<T>): void {
        this.children = this.children.filter((existingChild) => existingChild !== child);
    }

    /**
     * Remove a parent from the current node.
     */
    private removeParentNode(): void {
        this.parent = null;
    }

    /**
     * Add a child to the children list from the
     * current node.
     */
    private setChildNode(child: TreeNode<T>): void {
        if (!this.children.includes(child)) {
            this.children.push(child);
            this.children = this.children.sort((a, b) => a.item.startIndex - b.item.startIndex);
        }
    }

    /**
     * Set parent to the current nude.
     */
    private setParentNode(parent: TreeNode<T>): void {
        this.parent = parent;
    }

    /**
     * Push the current node between a parent and
     * a list of nodes. It will mark the parent as
     * parent and mark the current node as parent
     * of the children in the list.
     */
    public pushBetween(parent: TreeNode<T>, children?: TreeNode<T>[]) {
        this.setParentNode(parent);
        parent.setChildNode(this);

        if (children) {
            children.forEach((child) => {
                // Should be the same as the `parent` argument.
                if (child.parent) {
                    child.parent.removeChildNode(child);
                    child.parent.removeParentNode();
                }

                this.setChildNode(child);
                child.setParentNode(this);
            });
        }
    }
}
