import { useCallback } from 'react';

import { ADD_DIRECTION, useModifiableList } from '@tonkean/infrastructure';
import type {
    SmartConversationContentType,
    SmartConversationMessage,
    TonkeanId,
    TonkeanType,
    SmartConversationReplyMethodParams,
    SmartConversationUserMessageContent,
} from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const createUserReplyMessage = (
    text: string,
    conversationId: string,
    hidden?: boolean,
): SmartConversationMessage<SmartConversationUserMessageContent> => {
    const response: SmartConversationUserMessageContent = {
        userResponse: true,
        text,
        hidden,
    };
    return {
        messageId: utils.guid(),
        showAnimation: true,
        conversationId,
        response,
    };
};

export interface SmartSearchAssistantToolMessage {
    message: string;
}

export interface SmartConversationManager<T extends SmartConversationContentType> {
    messages: SmartConversationMessage<T>[];
    messagesHelpers: {
        onCreate: (item: SmartConversationMessage<T>, direction?: ADD_DIRECTION | undefined) => void;
        onDelete: (itemId: string) => void;
        reset: () => void;
    };
    callReplyMethod: (message: string, threadConversationId: string, hidden: boolean, reset: boolean) => void;
    replyMethodLoading: boolean;
    replyMethodError: any;
    assistantToolMessages?: SmartSearchAssistantToolMessage[];
}

const useSmartConversation = <T extends SmartConversationContentType>(
    projectId: TonkeanId<TonkeanType.PROJECT>,
    replyMethod: ({
        projectId,
        userReply,
        conversationId,
    }: SmartConversationReplyMethodParams) => Promise<SmartConversationMessage<T>>,
    replyMethodLoading: boolean,
    replyMethodError: any,
    assistantToolMessages?: SmartSearchAssistantToolMessage[],
): SmartConversationManager<T> => {
    const [messages, messagesHelpers] = useModifiableList<SmartConversationMessage<T>>([], (item) => item.messageId);

    const callReplyMethod = useCallback(
        (message: string, threadConversationId: string, hidden: boolean, reset: boolean) => {
            if (reset && messages.length > 0) {
                messagesHelpers.reset();
            }

            const userMessage = createUserReplyMessage(
                message,
                threadConversationId,
                hidden,
            ) as SmartConversationMessage<T>;

            messagesHelpers.onCreate(userMessage, ADD_DIRECTION.END);
            const userResponse = userMessage.response;
            replyMethod({
                projectId,
                userReply: userResponse.text,
                conversationId: userMessage.conversationId,
            }).then((replyData: SmartConversationMessage<T>) => {
                replyData.showAnimation = true;
                messagesHelpers.onCreate({ ...replyData, messageId: utils.guid() }, ADD_DIRECTION.END);
            });
        },
        [messages.length, messagesHelpers, projectId, replyMethod],
    );

    return {
        messages,
        messagesHelpers,
        callReplyMethod,
        replyMethodLoading,
        replyMethodError,
        assistantToolMessages,
    };
};

export default useSmartConversation;
