import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import ModuleActionsSelector from './ModuleActionsSelector';

import { XCloseButton } from '@tonkean/infrastructure';
import { Modal } from '@tonkean/infrastructure';
import { ModalBody, ModalFooter, ModalHeader, ModalSize } from '@tonkean/infrastructure';
import { Spacer } from '@tonkean/infrastructure';
import { LoadingCircle } from '@tonkean/infrastructure';
import { FlowRunIcon } from '@tonkean/svg';
import type { GroupEnvironmentFormSummary } from '@tonkean/tonkean-entities';
import type { Group } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

const TitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
    font-weight: 500;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const ModalSubHeader = styled.div`
    margin-top: 10px;
    font-size: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_700};
`;

const BorderBox = styled.div<{ bordered: boolean }>`
    border: ${({ bordered }) => (bordered ? 1 : 0)}px solid ${Theme.colors.gray_300};
    border-radius: 3px;
    margin: 0 19px;
`;

interface Props {
    workerForms: Omit<GroupEnvironmentFormSummary, 'additionalDataValue'>[];

    onClose(): void;

    open: boolean;
    group: Group;

    updateForms(records: GroupEnvironmentFormSummary[]): void;

    loadingUpdateForms: boolean;
}

const SolutionsBusinessReportActionsModal: React.FC<Props> = ({
    open,
    onClose,
    workerForms,
    group,
    updateForms,
    loadingUpdateForms,
}) => {
    const formattedForms = useMemo(() => {
        return workerForms?.map((form) => ({ ...form, additionalDataValue: 'Submit an update form' }));
    }, [workerForms]);

    const [records, setRecords] = useState<GroupEnvironmentFormSummary[]>(() =>
        formattedForms.map((form) => ({ ...form })),
    );
    useEffect(() => {
        if (!open) {
            setRecords(formattedForms.map((form) => ({ ...form })));
        }
    }, [open, formattedForms]);

    const hasActions = records.length > 0;
    return (
        <Modal onClose={onClose} open={open} size={ModalSize.SMEDIUM} fixedWidth>
            <ModalHeader $border={false}>
                <TitleHeader>
                    <TitleWrapper>
                        <FlowRunIcon />
                        <Spacer width={10} />
                        Manage Report Actions
                    </TitleWrapper>
                    <XCloseButton onClick={onClose} size={ButtonSize.MEDIUM} />
                </TitleHeader>
                {hasActions && (
                    <ModalSubHeader>Select the actions that will appear in the "{group.name}" report</ModalSubHeader>
                )}
            </ModalHeader>

            <ModalBody removePadding={hasActions}>
                <BorderBox bordered={hasActions}>
                    <ModuleActionsSelector
                        newFormsGroupId={group.id}
                        actions={records}
                        setActions={setRecords}
                        additionalDataLabel="Action Type"
                        headerBoxShadow
                    />
                </BorderBox>
            </ModalBody>

            {hasActions && (
                <ModalFooter align="right">
                    {loadingUpdateForms ? (
                        <LoadingCircle />
                    ) : (
                        <Button
                            onClick={() => {
                                updateForms(records);
                            }}
                        >
                            Save
                        </Button>
                    )}
                </ModalFooter>
            )}
        </Modal>
    );
};

export default SolutionsBusinessReportActionsModal;
