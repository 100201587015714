import type { IRootScopeService } from 'angular';

import { environment } from '@tonkean/shared-services';
import { memoize } from '@tonkean/utils';

/**
 * Returns the value of the integration key-map requested.
 * If an override is configured for the current project, it will use it.
 * @param keyName key requested
 * @param rootScope root-scope to check for override in currentProject
 */
function getIntegrationKeyMapValue(keyName: string, rootScope: IRootScopeService): string {
    if (rootScope.features.currentProject.tonkean_integration_keymap_overrides) {
        try {
            const overrides = JSON.parse(rootScope.features.currentProject.tonkean_integration_keymap_overrides);
            if (overrides[keyName]) {
                return overrides[keyName];
            }
        } catch (error) {
            console.error('failed to parse tonkean_integration_keymap_overrides', error);
        }
    }
    return environment.integrationKeysMap[keyName] as string;
}

export default memoize(getIntegrationKeyMapValue);
