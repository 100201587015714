import { analyticsWrapper } from '@tonkean/analytics';
import { WORKER_TYPES } from '@tonkean/constants';

function ChooseWorkerTypeCtrl(
    $scope,
    utils,
    groupManager,
    projectManager,
    authenticationService,
    folderId,
    workflowFolderCategoryId,
) {
    const workerTypes = WORKER_TYPES;
    $scope.workerTypes = workerTypes;
    $scope.pm = projectManager;
    $scope.validWorkerTypesArray = utils
        .objValues(workerTypes)
        .filter((type) => type.key !== workerTypes.UNINITIALIZED.key);

    $scope.data = {
        workerName: '',
        selectedType: workerTypes.FULL_TIME.key,
    };

    $scope.init = function () {
        const type = workerTypes.FULL_TIME.key;

        $scope.chooseType(type);
    };

    $scope.chooseType = function (type) {
        $scope.data.selectedType = type;
    };

    $scope.cancel = function () {
        analyticsWrapper.track('Cancel create worker', { category: 'Workers browser' });
        $scope.$dismiss();
    };

    $scope.save = function () {
        analyticsWrapper.track('Create worker', { category: 'Workers browser', label: $scope.data.selectedType });

        const type = $scope.data.selectedType;

        $scope.data.loading = true;
        $scope.data.error = null;
        return groupManager
            .quickCreateWorker(
                $scope.pm.project.id,
                $scope.data.workerName,
                type,
                false,
                authenticationService.currentUser.id,
                $scope.pm.projectData.archiveInitiativeDaysBeforeCensoring,
                folderId,
                workflowFolderCategoryId,
            )
            .then((group) => {
                $scope.$close(group);
            })
            .catch(() => {
                $scope.data.error = 'There was an error creating a module.';
                $scope.data.loading = false;
            });
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('ChooseWorkerTypeCtrl', ChooseWorkerTypeCtrl);
