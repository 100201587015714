import { FormulaTreeNodeType } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';

export interface FormulaTreeConversionResult {
    /**
     * Does the expression has a formula in it (surrounded by `<#` and `#>`)?
     * */
    hasFormula: boolean;

    /**
     * Tonkean expression with ids in variables
     */
    evaluatedExpression: string;

    /**
     * Tonkean expression with names in variables
     * */
    originalExpression: string;

    /**
     * The tree used to convert to the expression
     * */
    formulaTree: formulaTreeNode;

    /**
     * The identifier of the variable used as the operand.
     */
    variableId?: string;
}

/**
 * Converts a formula tree to a tonkean expression. Flattens the concat operand and allows to add custom operands
 * to flatten.
 *
 * @param formulaTree - the formula tree to flatten.
 * @param flattenOperators - the operands to flatten in addition to the concat operand. **Except the concat operand,
 * it will not flatten recursively - only if the operands are in the root of the tree to convert**.
 * @returns a tonkean expression and an indication whether the formula has been fully converted or if the expression
 * has a formula in it (using `<#` `#>`).
 */
function formulaTreeToExpression(
    formulaTree: formulaTreeNode,
    flattenOperators: OperatorKey[] = [],
): FormulaTreeConversionResult {
    switch (formulaTree.type) {
        case FormulaTreeNodeType.TREE: {
            const operatorKey = formulaTree.operator.key;
            const shouldFlattenOperator = [...flattenOperators, OperatorKey.STRING_CONCAT].includes(operatorKey);

            if (shouldFlattenOperator) {
                // Will flatten toString because the expression will auto-convert it to string
                const operands = formulaTree.operands.map((operand) =>
                    formulaTreeToExpression(operand, [OperatorKey.TO_STRING]),
                );

                return {
                    formulaTree,
                    hasFormula: operands.some((operand) => operand.hasFormula),
                    originalExpression: operands.map((operand) => operand.originalExpression).join(''),
                    evaluatedExpression: operands.map((operand) => operand.evaluatedExpression).join(''),
                };
            }

            return {
                formulaTree,
                hasFormula: true,
                originalExpression: `<# ${formulaTree.toString(false)} #>`,
                evaluatedExpression: `<# ${formulaTree.toString(true)} #>`,
            };
        }

        case FormulaTreeNodeType.CONST:
            return {
                formulaTree,
                hasFormula: false,
                originalExpression: formulaTree.value,
                evaluatedExpression: formulaTree.value,
            };

        case FormulaTreeNodeType.EMPTY:
            return {
                formulaTree,
                hasFormula: false,
                originalExpression: '',
                evaluatedExpression: '',
            };

        case FormulaTreeNodeType.VARIABLE:
            return {
                formulaTree,
                hasFormula: false,
                originalExpression: `{{${formulaTree.name}}}`,
                evaluatedExpression: `{{${formulaTree.variableId}}}`,
                variableId: formulaTree?.variableId,
            };
    }
}

export default formulaTreeToExpression;
