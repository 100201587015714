import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import personSelectorValidator from '../sharedConfigComponents/validators/personSelectorValidator';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const SendEmailLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.SEND_EMAIL,
    title: 'Send Email',
    iconClass: 'mod-notification',
    description: 'Send a custom email.',
    hasPersonConfig: true,
    validator: ({ definition }) => {
        const validationObject: LogicBlockValidationObject = {};
        personSelectorValidator(validationObject, definition, true);

        if (!definition.text || !definition.subject) {
            validationObject.notifyType = 'Must enter a subject and text.';
        }

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default SendEmailLogicBlockConfig;
