<!-- Link to the group that this initiative belongs to -->
<span ng-if="data.godFather" class="flex-vmiddle">
    <!-- Not using ui-sref since it doesn't watch expressions -->
    <a
        data-ui-state="data.godFather.sref.state"
        data-ui-state-params="data.godFather.sref.params"
        class="common-color-light-grey margin-right-xs fs-censored initiative-view-header-parent-name"
        ng-class="{ 'common-disabled': data.disableGodFather }"
    >
        {{ data.godFather.name }}
    </a>
    <i
        ng-if="data.parentsArray && data.parentsArray.length"
        class="fa fa-angle-right margin-right-xs common-color-light-grey"
    ></i>
</span>

<!-- We only show the trail of parent initaitives if there are any parent initiatives -->
<span ng-if="data.parentsArray && data.parentsArray.length" class="flex-vmiddle tnk-parent-navigation">
    <!-- show root if there are 2 parent-->
    <span ng-if="data.parentsArray.length > 1" class="flex-vmiddle">
        <a
            ng-click="data.navigate(data.parentsArray[0].id)"
            class="common-color-grey margin-right-xs fs-censored initiative-view-header-parent-name cursor"
        >
            {{ data.parentsArray[0].title || data.provideParent(data.parentsArray[0].id) }}
        </a>
        <i ng-if="!data.parentsSeparator" class="fa fa-angle-right margin-right-xs common-color-light-grey"></i>
        <span class="tnk-parent-separator" ng-if="data.parentsSeparator">{{ data.parentsSeparator }}&nbsp;</span>
    </span>
    <!-- ... if there are 3 parents-->
    <span ng-if="data.parentsArray.length > 2" uib-dropdown>
        <span class="pointer" uib-dropdown-toggle>... &nbsp;</span>
        <ul uib-dropdown-menu>
            <li ng-repeat="parent in data.parentsArray" uib-tooltip="Move to track" tooltip-placement="left auto">
                <a ng-click="data.navigate(parent.id)" class="margin-right-xs fs-censored">
                    {{ parent.title || data.provideParent(parent.id).title }}
                </a>
            </li>
        </ul>
        <i ng-if="!data.parentsSeparator" class="fa fa-angle-right margin-right-xs common-color-light-grey"></i>
        <span ng-if="data.parentsSeparator">{{ data.parentsSeparator }}&nbsp;</span>
    </span>
    <!-- immediate parent -->
    <span class="flex-vmiddle">
        <a
            ng-click="data.navigate(data.immediateParent.id)"
            class="
                common-color-grey
                margin-right-xs
                fs-censored
                initiative-view-header-parent-name
                cursor
                tnk-immediate-parent-label
            "
        >
            {{
                data.immediateParentLabel ||
                    data.immediateParent.title ||
                    data.provideParent(data.immediateParent).title
            }}
        </a>
    </span>
</span>
