/**
 * Helper for actions on integrations\project integrations
 */
function IntegartionHelper(
    $rootScope,
    $q,
    $timeout,
    authenticationService,
    tonkeanService,
    activityManager,
    pollingManager,
    utils,
    projectManager,
) {
    const _this = this;

    _this.setDefaultCommunicationIntegration = (projectId, projectIntegrationId) => {
        return tonkeanService.setDefaultCommunicationIntegration(projectId, projectIntegrationId).then(() => {
            return projectManager.getProjectData(true);
        });
    };

    _this.runCollectOnProjectIntegration = function (projectIntegration) {
        const cachedProjectIntegration = utils.findFirstById(
            projectManager.project.integrations,
            projectIntegration.id,
        );

        // Setting next collect to null so you know it's syncing.
        projectIntegration.nextCollect = null;
        if (cachedProjectIntegration) {
            cachedProjectIntegration.nextCollect = null;
        }

        return tonkeanService.collectProjectIntegrationNow(projectIntegration.id).then(() => {
            const deferred = $q.defer();

            // Polling the project integration to know when the collect has stopped
            pollingManager.registerPollingPromise(
                () => tonkeanService.getProjectIntegrationById(projectIntegration.id), // Fetch project integration.
                (projectIntegration) => projectIntegration.nextCollect || !projectIntegration.valid, // Stop only once we have the next collect or if the integration is broken.
                (updatedProjectIntegration) => {
                    // Update collect time of project integration.

                    // Ending the sync in a delay of a single second, to let the force polling we activated before the time
                    // to update the list with the new/updated initiatives.
                    $timeout(() => {
                        // Updating the last and next collect times from the latest project integration returned.
                        projectIntegration.lastCollect = updatedProjectIntegration.lastCollect;
                        projectIntegration.nextCollect = updatedProjectIntegration.nextCollect;
                        projectIntegration.valid = updatedProjectIntegration.valid;

                        // Updating the project integration cached in the cached project object
                        if (cachedProjectIntegration) {
                            cachedProjectIntegration.lastCollect = updatedProjectIntegration.lastCollect;
                            cachedProjectIntegration.nextCollect = updatedProjectIntegration.nextCollect;
                        }

                        if (projectIntegration.valid) {
                            $rootScope.$emit('alert', {
                                msg: `Integration ${projectIntegration.name} was updated successfully!`,
                                type: 'success',
                            });
                        }

                        deferred.resolve();
                    }, 1000);
                },
                2000,
            );

            return deferred.promise;
        });
    };
}

export default angular.module('tonkean.app').service('integrationHelper', IntegartionHelper);
