import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Checkbox, useToggle } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const FilterHeader = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.current.palette.mainColors.gray_600};
    line-height: 12px;
    font-weight: 500;
    margin-bottom: 10px;
`;

const CheckBoxesWrapper = styled.div`
    display: inline-flex;
    gap: 12px;
`;

interface Props {
    onFilterChanged(isSolutionAccessible: boolean, isSolutionInaccessible: boolean): void;
}

const AccessibilityFilters: React.FC<Props> = ({ onFilterChanged }) => {
    const [isSolutionAccessible, toggleIsSolutionAccessible] = useToggle(true);
    const [isSolutionInaccessible, toggleIsSolutionInaccessible] = useToggle(false);

    useEffect(() => {
        onFilterChanged(isSolutionAccessible, isSolutionInaccessible);
    }, [onFilterChanged, isSolutionAccessible, isSolutionInaccessible]);

    return (
        <>
            <FilterHeader>Show sources that are:</FilterHeader>
            <CheckBoxesWrapper>
                <Checkbox
                    checked={isSolutionAccessible}
                    name="Accessible"
                    onChange={() => toggleIsSolutionAccessible()}
                >
                    Accessible
                </Checkbox>

                <Checkbox
                    checked={isSolutionInaccessible}
                    name="Inaccessible"
                    onChange={() => toggleIsSolutionInaccessible()}
                >
                    Inaccessible
                </Checkbox>
            </CheckBoxesWrapper>
        </>
    );
};

export default AccessibilityFilters;
