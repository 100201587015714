import React, { useState } from 'react';

import DragAndDropModalContent from './DragAndDropModalContent';
import type DragAndDropModalProps from './DragAndDropModalProps';
import { Modal } from '../Modal';

import useStillMounted from '@tonkean/tui-hooks/useStillMounted';

interface Props extends DragAndDropModalProps {
    // Is modal open
    isOpen: boolean;
    // Executing on modal exit
    onCancel: () => void;
}

const DragAndDropModal: React.FC<Props> = ({ isOpen, onCancel, loading: loadingProp, ...props }) => {
    const [internalLoading, setInternalLoading] = useState(false);
    const loading = internalLoading || loadingProp;
    const isModalMountedRef = useStillMounted();

    return (
        <Modal
            open={isOpen}
            size={510}
            onClose={() => {
                if (!loading) {
                    onCancel();
                }
            }}
            fixedWidth
        >
            <DragAndDropModalContent
                loading={loading}
                onLoadingChanged={setInternalLoading}
                isModalMountedRef={isModalMountedRef}
                {...props}
            />
        </Modal>
    );
};

export default DragAndDropModal;
