import template from './singlePersonSelector.template.html?angularjs';

/**
 * Component to select a single existing user in Tonkean.
 */
export default angular.module('tonkean.app').component('tnkSinglePersonSelector', {
    bindings: {
        onPersonSelected: '&',
        // Same as OnPersonSelected but with '<' binding so we wont have to change the existing usages
        onPersonSelectedReact: '<',
        onPersonRemoved: '&',
        paramsObjectForCallbacks: '<',
        selectedPersonId: '<',
        selectedPersonEmail: '<',
        placeholder: '@',
        readOnly: '<',
        hidePlaceholderWhenDisabled: '<',
        hideTagsBackgroundWhenDisabled: '<',
        includeExternal: '<',
    },
    template,
    controller: 'SinglePersonSelectorCtrl',
});
