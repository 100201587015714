import WorkflowVersionsHistoryTab from './WorkflowVersionsHistoryTab';

import { reactToAngular } from '@tonkean/angular-components';

export default angular
    .module('tonkean.app')
    .component(
        'tnkWorkflowVersionsHistoryTab',
        reactToAngular(WorkflowVersionsHistoryTab, [
            'draftVersionChanges',
            'groupId',
            'productionWorkflowVersionId',
            'onTestWorkflowClicked',
            'onRevertWorkflowVersionClicked',
            'groupByToggleDefault',
        ]),
    );
