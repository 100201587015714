import React, { useState } from 'react';

import PreviewMatchedEntityItemsModal from './PreviewMatchedEntityItemsModal';

import { Button } from '@tonkean/tui-buttons/Button';
import { ButtonSize } from '@tonkean/tui-theme/sizes';

interface Props {
    workflowVersionId: string;
    matchedEntityId: string;
    initiativeId?: string;
}

const PreviewMatchedEntityItemsModalTrigger: React.FC<Props> = ({
    workflowVersionId,
    matchedEntityId,
    initiativeId,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button size={ButtonSize.SMALL} onClick={() => setOpen(true)}>
                Preview Items
            </Button>

            <PreviewMatchedEntityItemsModal
                open={open}
                onClose={() => setOpen(false)}
                workflowVersionId={workflowVersionId}
                matchedEntityId={matchedEntityId}
                initiativeId={initiativeId}
            />
        </>
    );
};

export default PreviewMatchedEntityItemsModalTrigger;
