import type { MatchConditionsRequest } from './MatchConditionsRequest';
import type { MatchConditionsResponse } from './MatchConditionsResponse';
import type NetworkService from '../NetworkService';

import type {
    MatchConditionExpression,
    MatchConditionResult,
    TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';

const URLS = {
    matchConditions: 'matchConditions',
};

function matchConditions(
    networkService: NetworkService,
    fieldValues: Record<TonkeanId<TonkeanType.FIELD_DEFINITION> | `TNK_${string}`, string>,
    matchInput: MatchConditionExpression[],
    workflowVersionType: WorkflowVersionType,
): Promise<Record<string, MatchConditionResult>> {
    const body = {
        fieldValues,
        matchInput,
        workflowVersionType,
    };

    return networkService.postRequest<MatchConditionsResponse, MatchConditionsRequest>({
        url: URLS.matchConditions,
        body,
    });
}

export { matchConditions };
