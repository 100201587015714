import type { Features } from 'angular';
import type { AngularServices } from 'angulareact';

import type CreateAfterOption from '../../../entities/CreateAfterOption';
import type CreateAfterOptionCanCreate from '../../../entities/CreateAfterOptionCanCreate';
import type LogicBlockConfigsFormInfoRetriever from '../../LogicBlockConfigsFormInfoRetriever';
import formAnsweredCanCreateInSameModule from '../canCreate/formAnsweredCanCreateInSameModule';
import formAnsweredCanCreateIsSequenceEnd from '../canCreate/formAnsweredCanCreateIsSequenceEnd';
import formAnsweredValidateRootParentInSequence from '../canCreate/formAnsweredValidateRootParentInSequence';
import formAnsweredGoToSequenceTriggerParamsEnricher from '../enrichers/formAnsweredGoToSequenceTriggerParamsEnricher';

import { ItemDetailsIconType } from '@tonkean/tonkean-entities';
import type { LogicImpact } from '@tonkean/tonkean-entities';

const goToSequenceOptionçanCreate: CreateAfterOptionCanCreate = (
    childImpacts: LogicImpact[],
    configuredLogic: LogicImpact,
    logicBlockConfigsFormInfoRetriever: LogicBlockConfigsFormInfoRetriever,
    customTriggerManager: AngularServices['customTriggerManager'],
    features: Features,
) => {
    return (
        !configuredLogic.node.disabled &&
        formAnsweredCanCreateInSameModule(configuredLogic, logicBlockConfigsFormInfoRetriever) &&
        formAnsweredCanCreateIsSequenceEnd(configuredLogic) &&
        formAnsweredValidateRootParentInSequence(
            configuredLogic,
            customTriggerManager,
            'AUTONOMOUS_INTERFACE_SUBMITTED',
        )
    );
};

const addGoToSequenceBlockCreateAfterOption: CreateAfterOption = {
    label: 'Go to sequence',
    blockDisplayName: 'Go to sequence',
    triggerParamsEnricher: formAnsweredGoToSequenceTriggerParamsEnricher,
    canCreate: goToSequenceOptionçanCreate,
    iconType: ItemDetailsIconType.GO_TO_SEQUENCE,
};
export default addGoToSequenceBlockCreateAfterOption;
