import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function MailchimpMarketingCustomActionsDefinitionCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        validationObject: ctrl.validationObject,

        loading: false,
    };

    $scope.onEmailAddressExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.emailAddressExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSubscriptionStatusExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.subscriptionStatusExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged({ shouldSaveLogic });
    };
}

export default angular
    .module('tonkean.app')
    .controller(
        'MailchimpMarketingCustomActionsDefinitionCtrl',
        lateConstructController(MailchimpMarketingCustomActionsDefinitionCtrl),
    );
