<div
    class="tnk-toggle-state-button {{ getRelevantClassProperties() }}"
    uib-tooltip="{{ data.tooltipContent }}"
    tooltip-placement="{{ data.tooltipPlacement }}"
    tooltip-enable="{{ data.displayTooltip }}"
    ng-click="onToggleClick($event)"
    data-automation="tnk-toggle-template-click-toggle-button"
>
    <div class="white-circle"></div>
    <div class="button-text">{{ data.isActive ? data.onLabel : data.offLabel }}</div>
</div>
