<div class="worker-outline-container">
    <tnk-side-pane
        is-open="data.selectedTab && data.isOutlineSidePaneOpen"
        custom-class="workers-left-side-pane"
        is-left="true"
        dont-prevent-scroll-bubbling="true"
        tabs="data.tabs"
        tabs-custom-class="'worker-outline-tabs'"
        selected-tab="data.selectedTab"
        on-tab-selected="selectTab(tab, true)"
        on-is-open-changed="setOutlineIsOpen(isOpen, true)"
        margin-top="data.workflowVersion.workflowVersionType === 'DRAFT' ? 8 : 0"
    >
        <div class="flex-grow" ng-if="!data.isLoading">
            <!-- Item details -->
            <tnk-item-details-module-editor-side-pane
                class="flex flex-grow full-min-h"
                ng-if="data.selectedTab.key === 'item-details'"
                workflow-version="wvm.workflowVersionIdToWorkflowVersionMap[data.workflowVersionId]"
                workflow-folder-project-integrations-access="data.workflowFolderProjectIntegrationsAccess"
                request-logics-validation="(data.requestLogicsValidation)"
                invalid-logics="data.invalidLogics"
            ></tnk-item-details-module-editor-side-pane>

            <!-- Workflow items -->
            <div ng-if="data.selectedTab.key === 'items'" class="padding-left padding-right">
                <div class="flex flex-col common-height-full">
                    <div class="margin-top margin-bottom-lg">
                        <div class="text-uppercase common-color-grey common-size-xxxxs margin-bottom margin-top-xs">
                            items in this flow:
                        </div>

                        <!-- Explanation -->
                        <div class="common-color-dark-grey common-size-xxxxs margin-bottom margin-top-xs">
                            Representation of the items that are created and run by this module. The base item is called
                            "Root", and the module can create inner items.
                        </div>

                        <div class="workflow-outline-items-container">
                            <div
                                class="workflow-outline-item-row flex-vmiddle pointer"
                                ng-class="{ 'mod-selected': !data.selectedWorkflowItem }"
                            >
                                <span
                                    ng-click="data.selectDataSourceNode()"
                                    class="pointer flex-grow"
                                    ng-switch="wvm.getCachedWorkflowVersion(data.workflowVersionId).dataSourceType"
                                >
                                    <a ng-switch-when="INTEGRATION" class="flex-vmiddle common-color-dark-grey">
                                        <!-- integration icon-->
                                        <i
                                            class="initiative-integration-icon mod-dropdown margin-none"
                                            ng-class="
                                                !scm.getSyncConfig(data.workflowVersionId).projectIntegration.iconUrl
                                                    ? 'mod-' +
                                                      scm
                                                          .getSyncConfig(data.workflowVersionId)
                                                          .projectIntegration.integration.integrationType.toLowerCase()
                                                    : null
                                            "
                                            ng-style="{
                                                background: scm.getSyncConfig(data.workflowVersionId).projectIntegration
                                                    .iconUrl
                                                    ? 'url(' +
                                                      scm.getSyncConfig(data.workflowVersionId).projectIntegration
                                                          .iconUrl +
                                                      ') no-repeat center'
                                                    : null
                                            }"
                                        ></i>

                                        <span class="margin-left-xs margin-right-xs">
                                            {{
                                                scm.getSyncConfig(data.workflowVersionId).projectIntegration.displayName
                                            }}
                                        </span>
                                        <span class="margin-right-xs">
                                            {{
                                                scm.getSyncConfig(data.workflowVersionId).viewData.entityMetadata
                                                    .pluralLabel ||
                                                    scm.getSyncConfig(data.workflowVersionId).viewData['Entity'] ||
                                                    utils.pluralize(
                                                        scm.getSyncConfig(data.workflowVersionId).viewData['entity']
                                                    )
                                            }}
                                        </span>
                                    </a>
                                    <a ng-switch-when="MANUAL" class="flex-vmiddle common-color-dark-grey">
                                        <i class="margin-right svg-icon">
                                            <tnk-icon src="/images/icons/bullet-list.svg"></tnk-icon>
                                        </i>
                                        <span class="flex-no-shrink">Manual List Item</span>
                                    </a>
                                    <a ng-switch-default="" class="common-size-xxxxs">Configure...</a>
                                </span>
                                <span
                                    class="common-size-xxxxs flex-no-shrink text-right"
                                    ng-click="selectWorkflowItem(null)"
                                >
                                    Root Item
                                </span>
                            </div>

                            <!--actionItemsLogics-->
                            <div ng-if="!data.loadingActionItems">
                                <div
                                    class="workflow-outline-item-row flex-vmiddle pointer"
                                    ng-repeat="actionItem in data.actionItemsLogics"
                                    ng-if="
                                        data.logicBlockTypesMap[actionItem.node.customTriggerType][
                                            actionItem.node.customTriggerSecondaryType
                                        ].creatingActionItems
                                    "
                                    ng-class="{
                                        'mod-selected':
                                            data.selectedWorkflowItem &&
                                            data.selectedWorkflowItem.id === actionItem.node.id
                                    }"
                                    ng-click="selectWorkflowItem(actionItem.node)"
                                    ng-style="{ 'margin-left': 10 * actionItem.level + 'px' }"
                                >
                                    <!--<span class="worker-outline-bullet flex-no-shrink"></span>-->
                                    <i class="margin-left margin-right svg-icon-xs flex-no-shrink">
                                        <tnk-icon src="/images/icons/bullet-list.svg"></tnk-icon>
                                    </i>
                                    <span class="flex-grow">{{ actionItem.node.displayName }}</span>
                                    <span class="pointer common-size-xxxxs flex-no-shrink common-color-dark-grey">
                                        [Inner Item]
                                    </span>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <!-- Example Item Title -->
                        <div class="text-uppercase common-color-grey common-size-xxxxs margin-bottom">
                            example item:
                        </div>

                        <!-- Example Item Description -->
                        <div class="common-size-xxxxs common-color-dark-grey margin-top-sm margin-bottom-lg">
                            The selected item will help you evaluate your configured expressions.
                        </div>

                        <!-- Example Item Selector -->
                        <div ng-if="data.showExampleItemSelector">
                            <div class="flex margin-bottom-xs">
                                <!-- Title -->
                                <span class="common-size-xxs flex-grow show-edit-when-hover">
                                    <i
                                        class="fa fa-flask common-color-grey"
                                        tooltip-placement="bottom-left"
                                        uib-tooltip="Select example item to see what values will be sent."
                                    ></i>
                                    <i ng-hide="data.exampleInitiativeTitleLoading">
                                        {{
                                            initiativeTitle(
                                                data.selectedExampleInitiative.title || 'NONE',
                                                data.selectedExampleInitiative.isDraftInitiative,
                                                data.selectedExampleInitiative.id
                                            )
                                        }}
                                    </i>
                                    <a
                                        ng-hide="
                                            data.exampleInitiativeTitleLoading ||
                                            data.selectedExampleInitiative.title === undefined
                                        "
                                        ng-click="data.changeExampleItem = !data.changeExampleItem"
                                        class="edit-button common-size-xxxxs"
                                    >
                                        <i class="fa fa-pencil"></i>
                                    </a>
                                    <span class="loading-small" ng-show="data.exampleInitiativeTitleLoading"></span>
                                </span>
                            </div>

                            <div ng-show="data.changeExampleItem">
                                <!-- Example initiative selection -->
                                <tnk-search-initiatives
                                    group-id="data.groupId"
                                    can-clear="false"
                                    on-initiative-selected="onInitiativeSelected(selectedSimplifiedInitiative)"
                                    selected-initiative-id="data.selectedExampleInitiative.id"
                                    should-set-default-initiative="true"
                                    default-initiatives-amount="20"
                                    only-roots="false"
                                    only-draft-initiatives="data.workflowVersion.workflowVersionType == 'DRAFT'"
                                ></tnk-search-initiatives>
                                <!-- Error state -->
                                <div
                                    class="common-color-danger common-size-xxxxs margin-right"
                                    ng-if="data.exampleItemError"
                                >
                                    {{ data.exampleItemError }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Fields -->
            <div ng-if="data.selectedTab.key === 'fields'">
                <div class="padding-left padding-right">
                    <button
                        ng-if="data.workflowVersion.workflowVersionType !== 'DRAFT'"
                        ng-click="data.canModifyBot(true)"
                        class="
                            alert alert-warning
                            block
                            text-left
                            full-width
                            cursor-pointer
                            flex-vmiddle
                            margin-bottom-none margin-top
                            padding-xs
                            common-no-outline
                        "
                        type="button"
                    >
                        <div>Cannot be edited in a published version.</div>
                    </button>
                </div>

                <tnk-group-fields-metadata-table
                    group-id="data.groupId"
                    workflow-version-id="{{ data.workflowVersionId }}"
                    open-triggers-outline="openTriggerOutline(fieldDefinitionForDependenciesFilterOptional)"
                    on-field-selected="(data.onOutlineFieldSelected)"
                    field-editor-has-pending-changes="data.fieldEditorHasPendingChanges"
                    reload-configuration="data.reloadFieldsConfiguration"
                    can-modify-bot="data.canModifyBot"
                ></tnk-group-fields-metadata-table>
            </div>

            <!-- Triggers-->
            <div ng-if="data.selectedTab.key === 'triggers'">
                <button
                    ng-if="data.workflowVersion.workflowVersionType !== 'DRAFT'"
                    ng-click="data.canModifyBot(true)"
                    class="
                        alert alert-warning
                        block
                        text-left
                        cursor-pointer
                        flex-vmiddle
                        margin-bottom-none margin-top margin-right margin-left
                        padding-xs
                        common-no-outline
                    "
                    type="button"
                >
                    <div>Cannot be edited in a published version.</div>
                </button>
                <!-- Triggers Toolbar-->
                <div ng-if="data.workflowVersion.workflowVersionType === 'DRAFT'">
                    <tnk-outline-filter
                        apply-filters="filterCustomTriggers(fieldTextFilter, fieldForDependencies)"
                        field-definition-for-dependencies-options="data.fieldDefinitions"
                        selected-field-definition-for-dependencies="data.selectedFieldDefinitionForDependencies"
                        entity-name="Triggers"
                    ></tnk-outline-filter>
                    <hr class="margin-top-xxs margin-bottom-xxs" />
                    <div class="flex-vmiddle worker-outline-triggers-control-panel common-color-grey">
                        <span uib-dropdown>
                            <a
                                uib-dropdown-toggle
                                class="common-link-no-style flex-no-shrink margin-right-md common-color-primary"
                                tooltip-append-to-body="true"
                                ng-click="controlPanelAddNode()"
                                uib-tooltip="Add logic"
                                tooltip-placement="bottom"
                            >
                                <i class="svg-icon-sm">
                                    <tnk-icon src="/images/icons/plus.svg"></tnk-icon>
                                </i>
                            </a>
                            <!-- In order not to calculate 2 filters on every digest we use the flag of the dropdown isopen to only calculate it when the dropdown is open -->
                            <ul
                                uib-dropdown-menu
                                ng-if="data.calculatedPlusButtonDropdownOptions!== undefined && data.calculatedPlusButtonDropdownOptions.length > 1"
                            >
                                <li
                                    ng-repeat="option in data.calculatedPlusButtonDropdownOptions"
                                    ng-click="createNewNodeAccordingToTemplate(option)"
                                >
                                    <a>{{ option.label(null) }}</a>
                                </li>
                            </ul>
                        </span>

                        <button
                            class="btn btn-no-style common-link-no-style flex-no-shrink margin-right-xs"
                            ng-class="{
                                disabled:
                                    data.logicBlockTypesMap[data.selectedLogic.node.customTriggerType][
                                        data.selectedLogic.node.customTriggerSecondaryType
                                    ].cantDelete
                            }"
                            ng-click="controlPanelDeleteNode()"
                            uib-tooltip="{{
                                data.logicBlockTypesMap[data.selectedLogic.node.customTriggerType][
                                    data.selectedLogic.node.customTriggerSecondaryType
                                ].cantDelete
                                    ? 'Cant Delete'
                                    : 'Delete Logic'
                            }}"
                            tooltip-placement="bottom"
                        >
                            <i class="svg-icon-sm">
                                <tnk-icon src="/images/icons/delete.svg"></tnk-icon>
                            </i>
                        </button>

                        <span class="common-separator-v mod-spacey flex-no-shrink"></span>

                        <a
                            class="common-link-no-style flex-no-shrink margin-right-md"
                            ng-click="controlPanelTabOut()"
                            uib-tooltip="Tab logic out"
                            tooltip-placement="bottom"
                        >
                            <i class="svg-icon-nav-sm">
                                <tnk-icon src="/images/icons/arrow-left-lg-bold.svg"></tnk-icon>
                            </i>
                        </a>
                        <a
                            class="common-link-no-style flex-no-shrink margin-right-md"
                            ng-click="controlPanelTabIn()"
                            uib-tooltip="Tab logic in"
                            tooltip-placement="bottom"
                        >
                            <i class="svg-icon-nav-sm">
                                <tnk-icon src="/images/icons/arrow-right-lg-bold.svg"></tnk-icon>
                            </i>
                        </a>
                        <a
                            class="common-link-no-style flex-no-shrink margin-right-md"
                            ng-click="controlPanelMoveDown()"
                            uib-tooltip="Move logic down"
                            tooltip-placement="bottom"
                        >
                            <i class="svg-icon-nav-sm">
                                <tnk-icon src="/images/icons/arrow-down-lg-bold.svg"></tnk-icon>
                            </i>
                        </a>
                        <a
                            class="common-link-no-style flex-no-shrink margin-right-md"
                            ng-click="controlPanelMoveUp()"
                            uib-tooltip="Move logic up"
                            tooltip-placement="bottom"
                        >
                            <i class="svg-icon-nav-sm">
                                <tnk-icon src="/images/icons/arrow-up-lg-bold.svg"></tnk-icon>
                            </i>
                        </a>
                    </div>
                </div>

                <!-- Triggers list-->
                <div class="flex-grow worker-outline">
                    <!-- Empty state - create first logic -->
                    <div
                        class="padding-normal-sm"
                        ng-if="
                            data.workflowVersion.workflowVersionType === 'DRAFT' &&
                            data.nodeTree &&
                            (!data.nodeTree.impacts || !data.nodeTree.impacts.length)
                        "
                    >
                        <div class="flex-vmiddle worker-outline-item padding-left-md">
                            <!-- Bullet -->
                            <span
                                class="worker-outline-bullet flex-no-shrink margin-right-xs"
                                ng-hide="data.savingNewNode"
                            ></span>
                            <!-- Loading circle (takes bullet's place) -->
                            <span
                                class="worker-outline-item-loading-container flex-no-shrink"
                                ng-show="data.savingNewNode"
                            >
                                <i class="loading-small"></i>
                            </span>
                            <!-- Input -->
                            <input
                                type="text"
                                ng-model="data.newNodeDisplayName"
                                id="new-worker-outline-input"
                                placeholder="Write down your actions..."
                                class="
                                    common-size-xxxxs
                                    form-control
                                    common-input-no-border
                                    flex-grow
                                    padding-right-lg padding-left-none
                                "
                                ng-blur="onNewNodeBlur()"
                                ng-keydown="onNewNodeInputKeyDown($event)"
                            />
                        </div>
                    </div>

                    <!-- Bullet workflow outline (when there's at least one logic) -->
                    <div ng-if="data.nodeTree && data.nodeTree.impacts" class="padding-normal-sm">
                        <!-- Monitor Module -->
                        <div
                            ng-if="pm.groupsMap[data.groupId].workerType !== 'ON_TRIGGER' && !data.selectedWorkflowItem"
                        >
                            <div ng-repeat="triggerNode in data.nodeTree.impacts" class="margin-bottom">
                                <div class="flex-vmiddle pointer">
                                    <span class="flex-vmiddle" ng-class="{ 'yellow-border': triggerNode.isDependent }">
                                        <i
                                            class="margin-right-xs svg-icon-xs svg-icon-vmiddle"
                                            ng-class="{ 'yellow-trigger': triggerNode.isDependent }"
                                        >
                                            <tnk-icon src="/images/icons/lightning.svg"></tnk-icon>
                                        </i>
                                        <strong class="pointer flex-no-shrink margin-right-xs">
                                            <tnk-highlightable-text
                                                text="triggerNode.node.displayName"
                                                highlight-text="data.filterText"
                                            ></tnk-highlightable-text>
                                        </strong>

                                        <!-- Run now -->
                                        <div ng-if="triggerNode.node.isScheduled" class="margin-left-xs flex-no-shrink">
                                            <a ng-click="runScheduledAutonomousCustomTriggerNow(triggerNode.node.id)">
                                                Run Now
                                            </a>
                                        </div>
                                    </span>

                                    <span
                                        ng-if="data.filterText != '' && data.counterMap[triggerNode.node.id]"
                                        class="common-size-xxxxxs margin-left-xs margin-top-xxs"
                                    >
                                        ({{ data.counterMap[triggerNode.node.id].filtered }}/{{
                                            data.counterMap[triggerNode.node.id].total
                                        }})
                                    </span>

                                    <div
                                        class="flex-grow text-right padding-right-xs"
                                        ng-click="triggerNode.collapseOutline = !triggerNode.collapseOutline"
                                    >
                                        <i
                                            ng-class="triggerNode.collapseOutline ? 'chevron-right' : 'chevron-bottom'"
                                        ></i>
                                    </div>
                                </div>
                                <hr class="margin-normal-sm-v" ng-hide="triggerNode.collapseOutline" />
                                <div class="worker-outline-item-container">
                                    <tnk-worker-outline-item
                                        group-id="data.groupId"
                                        ng-hide="triggerNode.collapseOutline"
                                        workflow-version-id="{{ data.workflowVersionId }}"
                                        node-block="triggerNode"
                                        node-parent="data.nodeTree"
                                        node-grand-parent="null"
                                        hide-node-display-name="true"
                                        sync-object="::data.syncObject"
                                        select-node="selectNode(node)"
                                        create-new-node="createNewNode(parentNode)"
                                        update-current-node-data="updateCurrentNodeData(currentNode, nodeParent, nodeGrandParent)"
                                        tab-node-in="tabNodeIn(nodeBlock, nodeParent)"
                                        tab-node-out="tabNodeOut(nodeBlock, nodeParent, nodeGrandParent)"
                                        move-in-tree="moveInTree(isUp, currentNodeId)"
                                        highlight-text="data.filterText"
                                        trigger-ids-to-highlight="data.dependentCustomTriggersIds"
                                    ></tnk-worker-outline-item>
                                </div>
                                <hr class="margin-normal-sm-v" />
                            </div>
                        </div>

                        <!-- Simple Module -->
                        <div
                            ng-if="pm.groupsMap[data.groupId].workerType === 'ON_TRIGGER' || data.selectedWorkflowItem"
                        >
                            <div class="flex-vmiddle pointer">
                                <i class="margin-right-xs svg-icon-xs svg-icon-vmiddle">
                                    <tnk-icon src="/images/icons/lightning.svg"></tnk-icon>
                                </i>
                                <strong class="pointer flex-no-shrink">
                                    {{
                                        data.selectedWorkflowItem
                                            ? data.selectedWorkflowItem.displayName
                                            : data.titleLabel
                                    }}
                                </strong>
                            </div>
                            <hr class="margin-normal-sm-v" />
                            <tnk-worker-outline-item
                                group-id="data.groupId"
                                node-block="data.selectedWorkflowItemNode || data.nodeTree"
                                node-parent="null"
                                workflow-version-id="{{ data.workflowVersionId }}"
                                node-grand-parent="null"
                                hide-node-display-name="true"
                                sync-object="::data.syncObject"
                                select-node="selectNode(node)"
                                create-new-node="createNewNode(parentNode)"
                                update-current-node-data="updateCurrentNodeData(currentNode, nodeParent, nodeGrandParent)"
                                tab-node-in="tabNodeIn(nodeBlock, nodeParent)"
                                tab-node-out="tabNodeOut(nodeBlock, nodeParent, nodeGrandParent)"
                                move-in-tree="moveInTree(isUp, currentNodeId)"
                            ></tnk-worker-outline-item>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Statuses new-->
            <div ng-if="data.selectedTab.key === 'statuses'">
                <tnk-statuses-worker-outline
                  workflow-version-id="data.workflowVersionId"
                  group-id="data.groupId"
                  view-only="data.workflowVersion.workflowVersionType !== 'DRAFT'"
                />
            </div>

            <div ng-if="data.selectedTab.key === 'contract'">
                 <tnk-contract-side-pane-tab
                    ng-if="!!data.workflowVersion && !!data.groupId"
                    workflow-version-type="data.workflowVersion.workflowVersionType"
                    workflow-version-id="data.workflowVersion.id"
                    group-id="data.groupId"
                 />
            </div>

            <!-- Forms-->
            <tnk-forms-worker-outline
                ng-if="data.selectedTab.key === 'forms'"
                form-id-to-form-map="formManager.workflowVersionIdToFormIdToFormMap[data.workflowVersionId]"
                switch-to-draft-dialog="data.canModifyBot"
                create-new-form="createNewForm"
                edit-form="editForm"
                is-loading="data.loadingForms"
                selected-form-id="data.editFormId"
            ></tnk-forms-worker-outline>

            <!-- Interfaces -->
            <tnk-interface-worker-outline
                ng-if="data.selectedTab.key === 'interfaces'"
                workflow-version-id="data.workflowVersionId"
                workflow-version-type="data.workflowVersion.workflowVersionType"
            ></tnk-interface-worker-outline>

            <!-- Process Mappers -->
            <tnk-process-mappers-worker-outline
              ng-if="data.selectedTab.key === 'process-mappers'"
              workflow-version-id="data.workflowVersionId"
              workflow-version-type="data.workflowVersion.workflowVersionType"
            ></tnk-process-mappers-worker-outline>

            <!-- Datasource Connectors-->
            <div ng-if="data.selectedTab.key === 'connectors'" class="padding-normal-sm">
                <!-- Data sources connected by current user -->
                <div>
                    <!-- data sources connectors title -->
                    <div class="common-size-xxs common-bold flex-grow">Data sources used by module</div>

                    <!-- Explanation -->
                    <div class="common-color-light-grey common-size-xxxxs margin-bottom margin-top-xs">
                        Representation of data sources that are being used by this module
                    </div>

                    <!-- Has Integrations-->
                    <div ng-if="pm.groupsMap[data.groupId].groupProjectIntegrations.length">
                        <div
                            ng-repeat="projectIntegration in pm.groupsMap[data.groupId].groupProjectIntegrations | filter: filterGroupRepresentativeProjectIntegration"
                            class="common-size-xxs box-with-shadow margin-bottom padding-normal-sm"
                        >
                            <div class="flex-grow flex-vmiddle integration-selection-button">
                                <!-- Integration Icon -->
                                <i
                                    class="initiative-integration-icon mod-normal margin-right"
                                    ng-class="
                                        !projectIntegration.iconUrl
                                            ? 'mod-' + projectIntegration.integration.integrationType.toLowerCase()
                                            : ''
                                    "
                                    ng-style="{
                                        background: projectIntegration.iconUrl
                                            ? 'url(' + projectIntegration.iconUrl + ') no-repeat center'
                                            : null
                                    }"
                                ></i>

                                <!-- Integration Name -->
                                <div
                                    class="
                                        common-ellipsis
                                        integration-display-name-box
                                        flex-grow
                                        common-color-dark-grey
                                    "
                                    uib-tooltip="{{
                                        projectIntegration.displayName +
                                            (projectIntegration.integration.isNativeIntegration &&
                                            !projectIntegration.integration.supportsMultipleIntegrationsPerUser
                                                ? ' (' + projectIntegration.creator.firstName + ')'
                                                : '')
                                    }}"
                                >
                                    {{
                                        projectIntegration.displayName +
                                            (projectIntegration.integration.isNativeIntegration &&
                                            !projectIntegration.integration.supportsMultipleIntegrationsPerUser
                                                ? ' (' + projectIntegration.creator.firstName + ')'
                                                : '')
                                    }}
                                </div>
                            </div>

                            <!-- Connector Update Time -->
                            <div class="padding-left flex-vmiddle connector-collect">
                                <!-- Last update -->
                                <span
                                    class="connector-last-collect"
                                    ng-if="!data.runningCollect[projectIntegration.id]"
                                >
                                    <span
                                        class="connector-last-collect-label"
                                        uib-tooltip="{{ projectIntegration.lastCollect | date: 'medium' }}"
                                    >
                                        {{
                                            projectIntegration.lastCollect
                                                ? 'Updated ' + (projectIntegration.lastCollect | timeAgo)
                                                : 'Not updated yet'
                                        }}
                                    </span>

                                    <span
                                        uib-tooltip="Only makers are allowed to update the data source"
                                        tooltip-enable="!data.hasPermissionsToEditWorker"
                                    >
                                        <span
                                            class="connector-last-collect-icon"
                                            ng-class="{ 'common-disabled': !data.hasPermissionsToEditWorker }"
                                            ng-click="!data.hasPermissionsToEditWorker || runCollect(projectIntegration)"
                                            data-automation="tnk-worker-outline-template-data-source-update"
                                        >
                                            Update
                                        </span>
                                    </span>
                                </span>

                                <!-- Updating -->
                                <span class="connector-collect-now" ng-if="data.runningCollect[projectIntegration.id]">
                                    <span class="connector-collect-now-label">Updating Data Source...</span>
                                    <span class="connector-collect-now-icon">
                                        <tnk-icon src="/images/icons/data-sources/collect-now-loader.svg"></tnk-icon>
                                    </span>
                                </span>
                            </div>

                            <hr class="margin-normal-sm-v" />
                            <div class="common-size-xxxs padding-left flex">
                                <div class="flex-grow">
                                    <!-- Status -->
                                    <div
                                        class="text-center"
                                        ng-init="isBroken = projectIntegration.valid === false || projectIntegration.disabled"
                                    >
                                        <div class="common-color-success2 flex-vmiddle" ng-if="!isBroken">
                                            <i class="fa fa-circle common-size-xxxxs margin-right"></i>
                                            <span>Active</span>
                                        </div>

                                        <div class="common-color-warning flex-vmiddle" ng-if="isBroken">
                                            <i class="fa fa-exclamation-triangle common-size-xxxxs margin-right"></i>
                                            <span>Disconnected</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Manage -->
                                <div class="text-right">
                                    <span class="flex-vmiddle text-right">
                                        <span
                                            class="margin-right common-color-light-grey"
                                            ng-if="
                                                projectIntegration.creator.id !== authenticationService.currentUser.id
                                            "
                                            uib-tooltip="Owner: {{ projectIntegration.creator.name }}"
                                        >
                                            <i class="fa fa-info-circle"></i>
                                        </span>
                                        <span
                                            uib-tooltip="Only makers are allowed to navigate to the data source"
                                            tooltip-enable="!data.hasPermissionsToEditWorker"
                                            tooltip-append-to-body="true"
                                        >
                                            <a
                                                    data-automation="integration-group-box-mode-template-view-data"
                                                    ng-class="{ 'common-disabled common-pointer-events-none': !data.hasPermissionsToEditWorker || !projectIntegration }"
                                                    ui-sref="product.projectIntegrationPage({
                                                                enterpriseComponentId: projectIntegration.id,
                                                                fromName: pm.groupsMap[data.groupId].name,
                                                                fromState: 'product.workerEditor',
                                                                fromStateParams: $state.params,
                                                                page: data.projectIntegrationSettingsPageName,
                                                            })"
                                            >
                                                Manage
                                            </a>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Empty State -->
                    <div
                        ng-if="
                            !pm.groupsMap[data.groupId].groupProjectIntegrations ||
                            !pm.groupsMap[data.groupId].groupProjectIntegrations.length
                        "
                        class="common-color-grey flex margin-top-lg mod-center"
                    >
                        No data sources connected to this module
                    </div>

                    <hr />
                    <a ui-sref="product.enterpriseComponents({
                                tab: 'data-sources',
                                fromName: pm.groupsMap[data.groupId].name,
                                fromState: 'product.workerEditor',
                                fromStateParams: $state.params,
                            })"
                    >
                        Manage Data Sources
                    </a>
                </div>
            </div>

            <!-- Versions -->
            <div ng-if="data.selectedTab.key === 'versions'">
                <tnk-workflow-versions-history-tab
                    group-id="data.groupId"
                    draft-version-changes="data.workflowVersion.workflowVersionType === 'DRAFT' ? wvm.workflowVersionIdToChangesCounterMap[data.workflowVersionId] : undefined"
                    production-workflow-version-id="wvm.getPublishedVersionFromCache(data.groupId).id"
                    on-test-workflow-clicked="(onTestWorkflowClicked)"
                    on-revert-workflow-version-clicked="(onRevertWorkflowVersionClicked)"
                    target-type-id="data.targetTypeId"
                    group-By-toggle-default="data.groupByToggleDefault"
                ></tnk-workflow-versions-history-tab>
            </div>

            <!-- Bot configuration -->
            <div ng-if="data.selectedTab.key === 'auto_checkins'">
                <div
                    class="tnk-side-pane-inner"
                    ng-class="{
                        'cursor-pointer':
                            data.selectedTab.key === 'auto_checkins' &&
                            data.workflowVersion.workflowVersionType !== 'DRAFT'
                    }"
                    blocked-element="{{
                        data.selectedTab.key === 'auto_checkins' && data.workflowVersion.workflowVersionType !== 'DRAFT'
                    }}"
                    data-blocked-message-production
                    ng-click="data.selectedTab.key === 'auto_checkins' && data.workflowVersion.workflowVersionType !== 'DRAFT' && data.canModifyBot()"
                >
                    <tnk-bot-worker-component
                        disabled="data.workflowVersion.workflowVersionType !== 'DRAFT'"
                        group-id="data.groupId"
                        workflow-version-id="{{ data.workflowVersionId }}"
                    ></tnk-bot-worker-component>
                </div>
            </div>
        </div>

        <div ng-if="data.isLoading" class="tnk-side-pane-inner tnk-side-pane-loading">
            <span class="loading-large"></span>
        </div>
    </tnk-side-pane>
</div>
