import { reactToAngular } from 'angulareact';

import ExpandableFieldDescription from './ExpandableFieldDescription';

angular
    .module('tonkean.app')
    .component(
        'tnkExpandableFieldDescription',
        reactToAngular(ExpandableFieldDescription, ['fieldDefinition', 'formField']),
    );
