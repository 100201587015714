import template from './displayFormatSelector.template.html?angularjs';

/**
 * Component to select display formats.
 */
export default angular.module('tonkean.app').component('tnkDisplayFormatSelector', {
    bindings: {
        defaultDisplayFormat: '@', // What format should be selected by default (if no selected format was given).

        selectedFormatApiName: '<', // The selected format api name.
        selectedFormatPrefix: '<', // The selected format prefix.
        selectedFormatPostfix: '<', // The selected format postfix.

        apiNameFilter: '<', // Only display formats of given api name.
        fieldTypeFilter: '<', // Only display formats of given field type.

        disableSelection: '<', // Should we show the selections disabled

        onDisplayFormatSelected: '&', // A function to be called when a format is selected.
    },
    template,
    controller: 'DisplayFormatSelectorCtrl',
});
