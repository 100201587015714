import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-form.svg';

import { TonkeanExpression } from '@tonkean/angular-to-react-components';
import type { DynamicField } from '@tonkean/tonkean-entities';
import type { TonkeanExpressionAdditionalTab } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const DynamicFieldHeaderWrapper = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 5fr 5fr 1fr 3px;
`;

const DynamicFieldWrapper = styled(DynamicFieldHeaderWrapper)`
    margin-bottom: 10px;
`;

const NameValueTitleWrapper = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
`;

const StyledIconButton = styled(IconButton)`
    margin-top: 3px;
`;

interface Props {
    name: string;
    remove(): void;
    expressionTabs?: TonkeanExpressionAdditionalTab[];
}

const ProjectIntegrationHandleResponseSingleDynamicField: React.FC<Props> = ({ name, remove, expressionTabs }) => {
    const [dynamicField, , dynamicFieldHelper] = useField<DynamicField>(name);

    return (
        <>
            <DynamicFieldHeaderWrapper>
                <NameValueTitleWrapper>Name:</NameValueTitleWrapper>
                <NameValueTitleWrapper>Value:</NameValueTitleWrapper>
            </DynamicFieldHeaderWrapper>
            <DynamicFieldWrapper>
                <TonkeanExpression
                    placeholder="Dynamic Field Name"
                    savedEvaluatedExpression={dynamicField.value.name.evaluatedExpression}
                    savedOriginalExpression={dynamicField.value.name.originalExpression}
                    onTonkeanExpressionChanged={(originalExpression, evaluatedExpression, shouldSaveLogic) => {
                        dynamicFieldHelper.setValue({
                            ...dynamicField.value,
                            name: {
                                originalExpression,
                                evaluatedExpression,
                            },
                        });
                    }}
                    additionalTabs={expressionTabs}
                    defaultTabId="RESPONSE_PARAMS"
                />

                <TonkeanExpression
                    placeholder="Dynamic Field Value"
                    savedEvaluatedExpression={dynamicField.value.value.evaluatedExpression}
                    savedOriginalExpression={dynamicField.value.value.originalExpression}
                    onTonkeanExpressionChanged={(originalExpression, evaluatedExpression, shouldSaveLogic) => {
                        dynamicFieldHelper.setValue({
                            ...dynamicField.value,
                            value: {
                                originalExpression,
                                evaluatedExpression,
                            },
                        });
                    }}
                    additionalTabs={expressionTabs}
                    defaultTabId="RESPONSE_PARAMS"
                />
                <StyledIconButton onClick={() => remove()} flat>
                    <TrashIcon />
                </StyledIconButton>
            </DynamicFieldWrapper>
        </>
    );
};

export default ProjectIntegrationHandleResponseSingleDynamicField;
