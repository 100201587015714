import { useAngularService } from 'angulareact';
import { useCallback } from 'react';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import type { TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

const useFetchSequenceStepData = (
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    intakeWorkflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
    workflowVersionType: WorkflowVersionType,
    intakeInitiativeId: TonkeanId<TonkeanType.INITIATIVE>,
    nextWorkerRunId?: TonkeanId<TonkeanType.WORKER_RUN>,
) => {
    const tonkeanService = useAngularService('tonkeanService');
    const projectManager = useAngularService('projectManager');

    const [{ loading: loadingNextStep, error: errorNextStep }, answerItemInterface] = useLazyAsyncMethod(
        tonkeanService,
        'answerItemInterface',
    );

    const [{ loading: widgetsLoading, error: widgetsError }, getInterfaceWidgets] =
        useLazyTonkeanService('getInterfaceWidgets');

    const [{ loading: itemInterfaceLoading, error: itemInterfaceError }, getItemInterfaceById] =
        useLazyTonkeanService('getItemInterfaceById');

    const handleNextInterface = useCallback(
        async (
            itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
            customTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
        ) => {
            return answerItemInterface(
                itemInterfaceId,
                customTriggerId,
                intakeWorkflowVersionId,
                workflowVersionId,
                intakeInitiativeId,
                nextWorkerRunId,
            );
        },
        [answerItemInterface, nextWorkerRunId, intakeWorkflowVersionId, intakeInitiativeId, workflowVersionId],
    );

    const getStepItemInterfaceById = useCallback(
        async (
            itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
            workflowVersionIdOverride: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined,
        ) => {
            const result = await getItemInterfaceById(
                itemInterfaceId,
                workflowVersionIdOverride || intakeWorkflowVersionId,
                undefined,
            );
            projectManager.addGroup(result.group);
            return result;
        },
        [getItemInterfaceById, projectManager, intakeWorkflowVersionId],
    );

    const getStepInterfaceWidgets = useCallback(
        async (
            itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>,
            stepWorkflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION> | undefined = intakeWorkflowVersionId,
            stepInitiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined = intakeInitiativeId,
        ) => {
            return getInterfaceWidgets(itemInterfaceId, stepWorkflowVersionId, workflowVersionType, stepInitiativeId);
        },
        [getInterfaceWidgets, intakeInitiativeId, workflowVersionType, intakeWorkflowVersionId],
    );

    return {
        handleNextInterface,
        getStepItemInterfaceById,
        getStepInterfaceWidgets,
        fetchSequenceStepLoading: loadingNextStep || widgetsLoading || itemInterfaceLoading,
        fetchSequenceStepError: [errorNextStep, widgetsError, itemInterfaceError],
    };
};

export default useFetchSequenceStepData;
