import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useState } from 'react';

import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import { TnkSelect } from '@tonkean/infrastructure';

const UiPathCustomizeSetupComponent: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    createProjectApis,
    onChangeOrInitIntegration,
    projectIntegration,
}) => {
    const projectManager = useAngularService('projectManager');

    const [foldersList, setFoldersList] = useState([]);
    const [error, setError] = useState<string | undefined>();
    const [query, setQuery] = useState<string>('');
    const [selectedFolder, setSelectedFolder] = useState<{ value: string; label: string }>();
    const [isLoadingFolders, setIsLoadingFolders] = useState(false);

    // On every change of the project integration, we initialize current folder from project data.
    useEffect(() => {
        const projectData = projectIntegration?.projectData?.projectDatas[0];
        if (projectData) {
            setSelectedFolder(projectData.folder);
        }
    }, [projectIntegration]);

    // Reset the folders list
    useEffect(() => {
        // In case the user re-authenticated
        if (integration?.id !== projectIntegration?.integration?.id) {
            setSelectedFolder(undefined);
        }
    }, [integration, projectIntegration]);

    // Get Folders list.
    useEffect(() => {
        const getFolders = async () => {
            setIsLoadingFolders(true);
            try {
                const folders = await createProjectApis.getAutoCompleteOptions(
                    projectManager.project.id,
                    integration.id,
                    'folders',
                    { query },
                );
                const autoCompleteOptions = constructAutoCompleteOptions(folders.options);
                setFoldersList(autoCompleteOptions);
            } catch {
                setError('Error occurred while getting folders list.');
            } finally {
                setIsLoadingFolders(false);
            }
        };
        getFolders();
    }, [createProjectApis, integration, projectManager.project.id, query]);

    const onListSelected = useCallback((folder) => {
        setSelectedFolder(folder);
    }, []);

    useEffect(() => {
        const projectIntegrationDisplayName = `UiPath (${selectedFolder?.label})`;
        const projectIntegrationData = {
            folder: selectedFolder,
        };

        onChangeOrInitIntegration({ projectIntegrationData, projectIntegrationDisplayName }, !selectedFolder);
    }, [onChangeOrInitIntegration, selectedFolder]);

    const constructAutoCompleteOptions = (options) => {
        return options.map((option) => ({
            value: option.value,
            label: option.displayName,
        }));
    };

    return (
        <div className="flex flex-col">
            {error && <div className="alert alert-danger margin-bottom">{error}</div>}

            <div className="flex flex-col">
                <div className="flex-vmiddle margin-bottom">
                    <b>Select a Folder:</b>
                    {isLoadingFolders && <i className="loading-small margin-left-md" />}
                </div>

                <TnkSelect
                    placeholder="Search Folders..."
                    isMulti={false}
                    onInputChange={setQuery}
                    isCreatable={false}
                    isClearable={false}
                    options={foldersList}
                    onChange={onListSelected}
                    value={selectedFolder}
                    isSearchable
                    closeMenuOnSelect
                />
            </div>
        </div>
    );
};

export default UiPathCustomizeSetupComponent;
