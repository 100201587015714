import React, { useMemo } from 'react';
import styled from 'styled-components';

import { ReactComponent as InformationIcon } from '../../../../../../images/icons/history/search-tooltip.svg';
import { ReactComponent as UserGroupIcon } from '../../../../../../images/icons/user-group.svg';
import PeopleDirectoryViewMode from '../../../entities/PeopleDirectoryViewMode';
import { usePeopleDirectoryView } from '../../../utils/PeopleDirectoryViewContext';

import { Placeholder } from '@tonkean/infrastructure';
import {
    PeopleDirectorySendStatus,
    PeopleDirectorySendStatusLabel,
    SendToGroupOfPeopleAs,
} from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import type { Either } from '@tonkean/utils';

const Wrapper = styled.span`
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
`;
const LoadingWrapper = styled.span`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`;
const Top = styled.span`
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-weight: 500;
    color: ${Theme.colors.gray_800};

    svg {
        height: 14px;
        width: 14px;
    }
`;
const StyledInformationIcon = styled(InformationIcon)``;
const Avatar = styled.span`
    position: relative;

    ${StyledInformationIcon} {
        height: 10px;
        width: 10px;
        position: absolute;
        z-index: 7;
        top: -6px;
        right: -6px;
        background-color: ${Theme.colors.basicBackground};
        border-radius: 50%;

        path {
            fill: ${Theme.colors.warning};
        }
    }
`;
const Failed = styled.span`
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.MEDIUM_14};
    color: ${Theme.colors.gray_600};

    min-height: ${FontSize.MEDIUM_14};
`;
const Warn = styled.span`
    color: ${Theme.colors.warning};
`;

interface LoadingProps {
    loading: true;
}
interface GeneralProps {
    peopleDirectoryId: string;
    showFailed?: boolean;
    showErrorIcon?: boolean;
}
type Props = Either<LoadingProps, GeneralProps>;

const Loading = () => {
    return (
        <LoadingWrapper>
            <Placeholder $width="17px" $circle />
            <Placeholder $width="100px" />
        </LoadingWrapper>
    );
};

const FailedMessage: React.FC<GeneralProps & { individualsLength: number }> = ({
    peopleDirectoryId,
    showFailed,
    individualsLength,
}) => {
    const { individualsSendResults, sendToGroupOfPeopleAs, groupMessagesResults } =
        usePeopleDirectoryView(peopleDirectoryId);

    const failedCount = useMemo(() => {
        if (!showFailed || !individualsSendResults) {
            return 0;
        }

        return individualsSendResults.filter((person) => person.status === PeopleDirectorySendStatus.FAIL).length;
    }, [individualsSendResults, showFailed]);

    if (sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL) {
        if (failedCount > 0) {
            return (
                <>
                    <Warn>
                        {failedCount}/{individualsLength} recipients failed
                    </Warn>{' '}
                    to receive a message
                </>
            );
        } else {
            return <>{PeopleDirectorySendStatusLabel.SUCCESS}</>;
        }
    } else {
        if (groupMessagesResults?.status === PeopleDirectorySendStatus.FAIL) {
            return <Warn>{PeopleDirectorySendStatusLabel.FAIL}</Warn>;
        } else {
            return <>{PeopleDirectorySendStatusLabel.SUCCESS}</>;
        }
    }
};

const Content: React.FC<GeneralProps> = (props) => {
    const { peopleDirectory, individualsSendResults, individuals, mode, sendToGroupOfPeopleAs, groupMessagesResults } =
        usePeopleDirectoryView(props.peopleDirectoryId);

    const relevantIndividuals = mode === PeopleDirectoryViewMode.HISTORY ? individualsSendResults : individuals;

    return (
        <Wrapper>
            <Top>
                <Avatar>
                    <UserGroupIcon />
                    {props.showErrorIcon && groupMessagesResults?.status === PeopleDirectorySendStatus.FAIL && (
                        <StyledInformationIcon />
                    )}
                </Avatar>
                {peopleDirectory.displayName}
                {sendToGroupOfPeopleAs === SendToGroupOfPeopleAs.EACH_INDIVIDUAL && (
                    <> ({relevantIndividuals?.length ?? 0})</>
                )}
            </Top>
            {props.showFailed && (
                <Failed>
                    <FailedMessage {...props} individualsLength={relevantIndividuals?.length ?? 0} />
                </Failed>
            )}
        </Wrapper>
    );
};

const SinglePeopleDirectorySummary: React.FC<Props> = (props) => {
    return props.loading ? <Loading /> : <Content {...props} />;
};

export default SinglePeopleDirectorySummary;
