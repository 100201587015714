import React from 'react';
import styled from 'styled-components';

import ProjectIntegrationPageExplorerItemComponent from './ProjectIntegrationPageExplorerItemComponent';
import type { ProjectIntegrationPageExplorerSectionWithLoading } from '../../../ProjectIntegrationPageModule/entities/ProjectIntegrationPageExplorerSection';

import { Placeholder } from '@tonkean/infrastructure';

const SectionTitle = styled.div`
    font-weight: 500;
    font-size: 12px;
    margin-left: 14px;
    margin-top: 18px;
    margin-bottom: 18px;
`;

const ThinDivider = styled.div`
    width: auto;
    height: 1px;
    background: #e9ecf1;
`;

const ThickDivider = styled.div`
    width: auto;
    height: 1px;
    background: #cdd2d8;
`;

interface Props {
    section: ProjectIntegrationPageExplorerSectionWithLoading;
    selectedItemId?: string;
    onItemSelected: (selectedItemId: string) => void;
}

const ProjectIntegrationPageExplorerSectionComponent: React.FC<Props> = ({
    section,
    selectedItemId,
    onItemSelected,
}) => {
    return (
        <React.Fragment>
            {section.loading && (
                <SectionTitle>
                    <Placeholder $width="100px" $height="15px" />{' '}
                </SectionTitle>
            )}
            {!section.loading && <SectionTitle>{section.title}</SectionTitle>}
            <ThickDivider />
            {(section.items || []).map((item) => {
                return (
                    <div key={item.id}>
                        <ProjectIntegrationPageExplorerItemComponent
                            item={item}
                            selectedItemId={selectedItemId}
                            onItemSelected={onItemSelected}
                        />
                        <ThinDivider />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default ProjectIntegrationPageExplorerSectionComponent;
