import React, { useRef } from 'react';

import ItemInterfaceWrapper from './ItemInterfaceWrapper';
import ItemInterfacePermission from '../entities/ItemInterfacePermission';
import useItemInterfaceContextDataBuilder from '../hooks/useItemInterfaceContextDataBuilder';
import { useRegisterItemInterfaceFieldDefinitionSummaryOnContext } from '../hooks/useRegisterItemInterfaceFieldDefinitionSummaryOnContext';
import type { ItemInterfaceHeaderProps } from '../SystemInterface/components/ItemInterface/ItemInterfaceHeader';
import type { ItemInterfaceViewProps } from '../SystemInterface/components/ItemInterface/ItemInterfaceView';
import ItemInterfaceView from '../SystemInterface/components/ItemInterface/ItemInterfaceView';
import SystemInterfaceDisplay, {
    type SystemInterfaceDisplayProps,
} from '../SystemInterface/components/SystemInterface/SystemInterfaceDisplay';
import useActivateOnLoadActions from '../SystemInterface/hooks/useActivateOnLoadActions';
import useGetItemInterfaceData from '../SystemInterface/hooks/useGetItemInterfaceData';
import usePollInitiativeData from '../SystemInterface/hooks/usePollInitiativeData';
import useGetWidgetsToDisplay from '../widgets/hooks/useGetWidgetsToDisplay';

import type { ItemInterfaceContextData, ItemInterfaceSection } from '@tonkean/infrastructure';
import {
    ItemInterfaceContext,
    NotFoundPage,
    useIsDataRestricted,
    useUserPermissionsForInitiativeInGroup,
} from '@tonkean/infrastructure';
import LoadingTonkean from '@tonkean/infrastructure/components/LoadingTonkean';
import type {
    Initiative,
    ItemInterfaceWidget,
    TonkeanId,
    TonkeanType,
    ValidationState,
} from '@tonkean/tonkean-entities';
import { ItemInterfaceLoadingState } from '@tonkean/tonkean-entities';

function Loading() {
    return (
        <div className="common-height-full flex mod-align-center mod-center mod-justify-center">
            <i className="loading-large" />
        </div>
    );
}

type RenderedInterfaceViewInfo = (
    | {
          type: 'itemInterfaceView';
          props: ItemInterfaceViewProps;
      }
    | {
          type: 'systemInterfaceDisplay';
          props: SystemInterfaceDisplayProps;
      }
) & { context: ItemInterfaceContextData };

export interface CustomInterfaceViewProps {
    itemInterfaceId: TonkeanId<TonkeanType.ITEM_INTERFACE>;
    initiativeId: TonkeanId<TonkeanType.INITIATIVE>;
    originWidget: TonkeanId<TonkeanType.ITEM_INTERFACE_WIDGET> | undefined;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    section: ItemInterfaceSection;
    forcedPermission?: ItemInterfacePermission;
    showPoweredByTonkean?: boolean;
    hideLogoInHeader?: boolean;
    showReturnToHomepageButton?: boolean;
    cleanMode?: boolean;
    CustomHeaderComponent?: React.FC<ItemInterfaceHeaderProps>;
    CustomLoadingComponent?: React.FC;
    initiativeReloadStarted?: () => void;
    showTitle?: boolean;
    onValidationChangedCallback?: (
        validation: ValidationState,
        setForceShownValidation?: (value: boolean) => void,
    ) => void;
    inheritValidationContext?: boolean;
    inheritQuickNavigationContext?: boolean;
    showPanel?: boolean;
    isNested?: boolean;
}

const CustomInterfaceView: React.FC<CustomInterfaceViewProps> = ({
    itemInterfaceId,
    initiativeId,
    originWidget,
    projectId,
    forcedPermission,
    showPoweredByTonkean = true,
    hideLogoInHeader = false,
    cleanMode,
    showReturnToHomepageButton,
    CustomHeaderComponent,
    CustomLoadingComponent,
    initiativeReloadStarted,
    showTitle,
    onValidationChangedCallback,
    inheritValidationContext,
    inheritQuickNavigationContext,
    showPanel = false,
    section,
    isNested = false,
}) => {
    const lastRenderedInterfaceViewInfo = useRef<RenderedInterfaceViewInfo | undefined>(undefined);

    const {
        showNotFoundPage: showNotFoundPageInitial,
        showLoading: showLoadingInitial,
        itemInterface,
        workflowVersion,
        widgetsResponse,
        includedWidgetsSummaryByInterfaceId,
    } = useGetItemInterfaceData(projectId, initiativeId, originWidget, itemInterfaceId);

    const {
        polledInitiative,
        errorStatusCode: pollInitiativeErrorStatusCode,
        loading: polledInitiativeLoading,
        pending: polledInitiativePending,
        reloadInitiativeFromServer,
        applyFastReloadInterval,
        setPollInitiativeEnabled,
    } = usePollInitiativeData(projectId, initiativeId, itemInterface?.id || itemInterfaceId);

    const widgetsToDisplay: ItemInterfaceWidget[] = useGetWidgetsToDisplay(
        widgetsResponse?.entities as ItemInterfaceWidget[],
        polledInitiative,
    );

    const itemInterfaceContextValue = useItemInterfaceContextDataBuilder({
        initiative: polledInitiative,
        initiativeInitialLoading: polledInitiativePending || polledInitiativeLoading,
        itemInterface,
        section,
        workflowVersion,
        includedWidgetsSummaryByInterfaceId,
        widgetsToDisplay,
        allInterfaceWidgets: widgetsResponse?.entities || [],
        applyFastReloadInterval,
        reloadInitiativeFromServer: (updatedInitiative?: Initiative | undefined) => {
            reloadInitiativeFromServer(updatedInitiative);
            initiativeReloadStarted?.();
        },
        setPollInitiativeEnabled,
    });

    const { canUserEditItem, canUserViewItem } = useUserPermissionsForInitiativeInGroup(
        polledInitiative?.isArchived,
        polledInitiative?.isDraftInitiative,
        itemInterface?.group,
    );

    const showNotFoundPage = useIsDataRestricted([pollInitiativeErrorStatusCode]) || showNotFoundPageInitial;

    const showLoading = [
        showLoadingInitial,
        polledInitiativeLoading,
        itemInterfaceContextValue.loadingRequiredInformation,
    ].some(Boolean);

    useActivateOnLoadActions(polledInitiative?.id, itemInterface, reloadInitiativeFromServer, applyFastReloadInterval);

    useRegisterItemInterfaceFieldDefinitionSummaryOnContext(
        itemInterfaceId,
        itemInterfaceContextValue.allInterfaceWidgets,
        itemInterfaceContextValue.itemInterfaceFieldDefinitions,
        itemInterfaceContextValue.initiative,
        itemInterfaceContextValue.widgetsInnerItems,
    );

    if (showNotFoundPage || !canUserViewItem) {
        return (
            <React.Suspense fallback={<Loading />}>
                <NotFoundPage text="Oops, we can't find that page" dataAutomation="interface-not-found" />
            </React.Suspense>
        );
    }

    if (showLoading || !itemInterface || polledInitiative?.id !== initiativeId) {
        /**
         * In case component transitions between initiatives, the last rendered info is used while new info is being loaded
         * This allows a much smoother transitions without any loader or jumping UI
         */
        if (lastRenderedInterfaceViewInfo.current) {
            return (
                <ItemInterfaceContext.Provider value={lastRenderedInterfaceViewInfo.current.context}>
                    <ItemInterfaceWrapper style={{ pointerEvents: 'none' }}>
                        {lastRenderedInterfaceViewInfo.current.type === 'itemInterfaceView' ? (
                            <ItemInterfaceView {...lastRenderedInterfaceViewInfo.current.props} />
                        ) : (
                            <SystemInterfaceDisplay {...lastRenderedInterfaceViewInfo.current.props} />
                        )}
                    </ItemInterfaceWrapper>
                </ItemInterfaceContext.Provider>
            );
        } else {
            return CustomLoadingComponent ? <CustomLoadingComponent /> : <LoadingTonkean />;
        }
    }

    const permission = !canUserEditItem
        ? ItemInterfacePermission.USER_CANT_EDIT_ITEM
        : forcedPermission ?? ItemInterfacePermission.USER_CAN_EDIT_ITEM;

    const interfaceViewRenderingInfo: RenderedInterfaceViewInfo = cleanMode
        ? {
              context: itemInterfaceContextValue,
              type: 'itemInterfaceView',
              props: {
                  itemInterfaceWidgetsState: showLoadingInitial
                      ? ItemInterfaceLoadingState.LOADING
                      : ItemInterfaceLoadingState.IDLE,
                  widgets: widgetsToDisplay,
                  initiative: polledInitiative,
                  workflowVersion,
                  permission,
                  showPoweredByTonkean,
                  hideLogoInHeader,
                  CustomHeaderComponent,
                  showReturnToHomepageButton,
                  cleanMode,
                  showTitle,
                  onValidationChangedCallback,
                  inheritValidationContext,
                  isNested,
                  inheritQuickNavigationContext,
              },
          }
        : {
              context: itemInterfaceContextValue,
              type: 'systemInterfaceDisplay',
              props: {
                  initiativeId,
                  group: itemInterface.group,
                  workflowVersion,
                  itemInterface,
                  widgets: widgetsToDisplay,
                  includedWidgetsSummaryByInterfaceId,
                  permissions: permission,
                  showBreadcrumbs: false,
                  showReturnToHomepageButton,
                  showPoweredByTonkean,
                  showPanel,
                  section,
              },
          };

    // storing last rendered params to be used for smooth transition in case interface/initiative are changed and data need to be reloaded
    lastRenderedInterfaceViewInfo.current = interfaceViewRenderingInfo;

    return (
        <ItemInterfaceContext.Provider value={itemInterfaceContextValue}>
            <ItemInterfaceWrapper>
                {interfaceViewRenderingInfo.type === 'itemInterfaceView' ? (
                    <ItemInterfaceView {...interfaceViewRenderingInfo.props} />
                ) : (
                    <SystemInterfaceDisplay {...interfaceViewRenderingInfo.props} />
                )}
            </ItemInterfaceWrapper>
        </ItemInterfaceContext.Provider>
    );
};

export default CustomInterfaceView;
