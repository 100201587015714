function ManageScheduledReportsCtrl(
    $scope,
    $rootScope,
    $uibModalInstance,
    consts,
    utils,
    tonkeanService,
    projectManager,
    modalUtils,
    currentUserHelper,
    groupId,
) {
    $scope.pm = projectManager;
    $scope.modalUtils = modalUtils;
    $scope.as = $rootScope.as;

    /**
     * Modal initialization function.
     */
    $scope.init = function () {
        $scope.data = {
            reportTypeOptions: utils.reverseMap(consts.getReportTypesSettings()),
            errorMessage: null,
            scheduledReports: [],
            loadingReports: true,
            isDisabledForUser: false,
            turnScheduledReportsLoading: false,
            groupId,
        };

        calculateScheduledReportsIsDisabled();

        loadScheduledReports();
    };

    /**
     * Closes the modal.
     */
    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    /**
     * Opens the create report modal in edit mode.
     */
    $scope.openUpdateScheduledReportModal = function (report) {
        modalUtils.openAddScheduledReportModal(false, $scope.cancel, loadScheduledReports, report, $scope.data.groupId);
    };

    /**
     * Opens the create report modal in create mode.
     */
    $scope.openCreateScheduledReportModal = function () {
        modalUtils.openAddScheduledReportModal(true, $scope.cancel, loadScheduledReports, null, $scope.data.groupId);
    };

    /**
     * Unsubscribes current user from given report.
     */
    $scope.unsubscribeFromReport = function (report) {
        $scope.mboxData = {
            title: 'Unsubscribe from scheduled report',
            body: `Are you sure you want to unsubscribe from "${report.name}"?`,
            isWarn: true,
            okLabel: 'Unsubscribe',
            cancelLabel: 'Cancel',
        };

        $rootScope.modal
            .openMessageBox({
                scope: $scope,
                size: 'md',
                windowClass: 'mod-danger',
            })
            .result.then(function () {
                // okLabel clicked.

                report.subscriptionLoading = true;
                tonkeanService
                    .unsubscribeScheduledReport(report.id)
                    .then(function () {
                        loadScheduledReports();
                    })
                    .catch(function (error) {
                        $scope.data.errorMessage = error;
                    })
                    .finally(function () {
                        report.subscriptionLoading = false;
                    });
            });
    };

    /**
     * Unsubscribes from given report.
     */
    $scope.subscribeToReport = function (report) {
        report.subscriptionLoading = true;
        tonkeanService
            .subscribeScheduledReport(report.id)
            .then(function () {
                // loadScheduledReports();
                // Not reloading the scheduled reports, because reloading looks like a blink in the modal.
                report.currentUserIsSubscriber = true;
            })
            .catch(function (error) {
                $scope.data.errorMessage = error;
            })
            .finally(function () {
                report.subscriptionLoading = false;
            });
    };

    $scope.turnScheduledReportsBotOn = function () {
        $scope.data.turnScheduledReportsLoading = true;
        currentUserHelper
            .removeNoScheduledReportOptionFromCurrentUser()
            .then(() => {
                calculateScheduledReportsIsDisabled();
            })
            .finally(() => {
                $scope.data.turnScheduledReportsLoading = false;
            });
    };

    /**
     * Loads the scheduled reports for the project.
     */
    function loadScheduledReports() {
        $scope.data.loadingReports = true;

        tonkeanService
            .getScheduledReports($scope.pm.project.id)
            .then(function (data) {
                $scope.data.scheduledReports = data.entities;

                enrichReports();

                $scope.data.loadingReports = false;
            })
            .catch(function (error) {
                $scope.data.errorMessage = error;
                $scope.data.loadingReports = false;
            });
    }

    /**
     * Enriches the report entities with information helpful for display.
     */
    function enrichReports() {
        for (let i = 0; i < $scope.data.scheduledReports.length; i++) {
            const report = $scope.data.scheduledReports[i];

            // Setting a boolean indicating whether current user is a subscriber of the report.
            if (!report.subscribers || !report.subscribers.length) {
                report.currentUserIsSubscriber = false;
            } else {
                for (let j = 0; j < report.subscribers.length; j++) {
                    const subscriberId = report.subscribers[j].id;

                    if (subscriberId === $scope.as.currentUser.id) {
                        report.currentUserIsSubscriber = true;
                    }
                }
            }

            // Setting a boolean indicating whether user has full access to the report
            // (either user is a project owner, or is the creator of the report).
            report.currentUserHasFullAccess = $scope.pm.isOwner || $scope.as.currentUser.id === report.creator.id;

            // Setting the report.frequencyDescription to display more specific details on the frequency
            switch (report.recurrencePeriodType) {
                case 'Daily':
                    if (report.recurrenceHour === 0) {
                        report.frequencyDescription = '12 AM';
                    } else if (report.recurrenceHour >= 1 && report.recurrenceHour <= 11) {
                        report.frequencyDescription = `${report.recurrenceHour} AM`;
                    } else if (report.recurrenceHour >= 12 && report.recurrenceHour <= 23) {
                        report.frequencyDescription = `${report.recurrenceHour} PM`;
                    }
                    break;

                case 'Weekly':
                    report.frequencyDescription = '';
                    const weekDayNumToWeekDayNameMap = utils.reverseMap(consts.getDaysOfWeekSettings());

                    for (let k = 0; k < report.recurrenceDaysInWeek.length; k++) {
                        const recurrenceDay = report.recurrenceDaysInWeek[k];

                        report.frequencyDescription += weekDayNumToWeekDayNameMap[recurrenceDay];

                        if (k < report.recurrenceDaysInWeek.length - 1) {
                            // If it's the last day, no comma
                            report.frequencyDescription += ', ';
                        }
                    }
                    break;

                case 'Monthly':
                    // Get the least significant digit - we select the postfix according to it.
                    const lastDigit = report.recurrenceDaysInMonth[0] % 10;
                    let postfix = 'th';

                    switch (lastDigit) {
                        case 1: {
                            postfix = 'st';

                            break;
                        }
                        case 2: {
                            postfix = 'nd';

                            break;
                        }
                        case 3: {
                            postfix = 'rd';

                            break;
                        }
                        // No default
                    }

                    // In month frequency, you may only choose one day. Add the matching postfix.
                    report.frequencyDescription = report.recurrenceDaysInMonth[0] + postfix;
                    break;
            }
        }
    }

    function calculateScheduledReportsIsDisabled() {
        // If string exists, the user has disabled the scheduled reports feature
        $scope.data.isDisabledForUser = currentUserHelper.checkUserMessagePreferenceExists('NO_SCHEDULED_REPORT');
    }

    $scope.init();
}

export default angular.module('tonkean.app').controller('ManageScheduledReportsCtrl', ManageScheduledReportsCtrl);
