import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowRightLgIcon } from '../../../../../images/icons/arrow-right-lg.svg';

import { LogicIcon } from '@tonkean/infrastructure';
import type { ActivitiesGroup } from '@tonkean/tonkean-entities';
import { FontSize, Theme } from '@tonkean/tui-theme';

const GroupName = styled.div`
    display: flex;
    justify-content: flex-start;
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_800};
    overflow-wrap: break-word;
    width: 150px;
    margin-bottom: 7px;
`;

const GroupNameBorder = styled.div`
    border-bottom: 1px solid ${Theme.colors.gray_300};
    margin-bottom: 7px;
`;

const IconsDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5px;
`;

const Title = styled.div`
    display: flex;
    justify-content: flex-start;
    font-size: ${FontSize.XSMALL_10};
    color: ${Theme.colors.gray_600};
    font-weight: 500;
`;

const RightArrow = styled.div`
    margin: 0px 5px;
    display: flex;

    .tnk-icon {
        display: inline-flex;

        svg {
            width: 16px;
            height: 14px;
        }
    }
`;

interface Props {
    parentBlock: any;
    activitiesGroup: ActivitiesGroup;
    logicBlock: any;
    groupTitle?: string;
}

const WorkerAuditLogGroupTooltipContent: React.FC<Props> = ({
    parentBlock,
    activitiesGroup,
    logicBlock,
    groupTitle,
}) => {
    return (
        <>
            {groupTitle && (
                <>
                    <GroupName>{groupTitle}</GroupName>
                    <GroupNameBorder />
                </>
            )}
            <Title>Proceeding {parentBlock?.title}</Title>
            <IconsDiv>
                <LogicIcon integrationType={parentBlock?.integrationType} logicBlockType={parentBlock} />
                <RightArrow>
                    <span className="tnk-icon">
                        <ArrowRightLgIcon />
                    </span>
                </RightArrow>
                <LogicIcon integrationType={activitiesGroup.entity?.integrationType} logicBlockType={logicBlock} />
            </IconsDiv>
        </>
    );
};

export default WorkerAuditLogGroupTooltipContent;
