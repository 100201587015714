import styled from 'styled-components';

const SQLDatabaseMultiMiddleSectionTitle = styled.div`
    font-size: 12px;
    color: #34393e;
    margin-top: 10px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
`;

export default SQLDatabaseMultiMiddleSectionTitle;
