<div class="box-with-shadow padding-normal text-left common-size-xxxxs">
    <div class="margin-top-none margin-bottom-lg common-size-s header-font">What should you track?</div>

    <div class="flex margin-bottom">
        <div class="svg-icon-xs common-color-primary margin-right">
            <tnk-icon src="/images/icons/check-circle.svg"></tnk-icon>
        </div>
        <div>
            Things you want to keep an
            <strong>ongoing status</strong>
            of.
        </div>
    </div>
    <div class="flex margin-bottom">
        <div class="svg-icon-xs common-color-primary margin-right">
            <tnk-icon src="/images/icons/check-circle.svg"></tnk-icon>
        </div>
        <div>
            You care about the
            <strong>context and details</strong>
            of that status.
        </div>
    </div>
    <div class="flex margin-bottom">
        <div class="svg-icon-xs common-color-primary margin-right">
            <tnk-icon src="/images/icons/check-circle.svg"></tnk-icon>
        </div>
        <div>
            Each has
            <strong>one owner</strong>
            . You or others.
        </div>
    </div>
    <div class="flex margin-bottom">
        <div class="svg-icon-xs common-color-primary margin-right">
            <tnk-icon src="/images/icons/check-circle.svg"></tnk-icon>
        </div>
        <div>
            They're part of a
            <strong>defined workflow</strong>
            , or you want them to be.
        </div>
    </div>
    <div class="flex margin-bottom">
        <div class="svg-icon-xs common-color-primary margin-right">
            <tnk-icon src="/images/icons/check-circle.svg"></tnk-icon>
        </div>
        <div>
            You wish to
            <strong>automate</strong>
            that workflow.
        </div>
    </div>
</div>
<div class="margin-top-lg box-with-shadow text-left common-size-xxxxs">
    <div class="padding-normal">
        The Tonkean A.I. Module will automatically follow up on your Tracks, so you don't have to!
    </div>
    <img src="/images/onBoarding/sandbox-msg.png" style="border-radius: 0 0 5px 5px" width="100%" />
</div>
