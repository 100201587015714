import React from 'react';
import styled from 'styled-components';

import { BoardSettingsTabs } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';

const Container = styled.div`
    margin-top: 5px;
    color: ${Theme.colors.gray_600};
`;

const BoardThemeOverrideWarning: React.FC = () => {
    return (
        <Container>
            Board theme override has been enabled, editing is disabled.{' '}
            <ClickableLink state="product.settings.tab" params={{ tab: BoardSettingsTabs.theme.value }} openInNewTab>
                View
            </ClickableLink>
        </Container>
    );
};

export default BoardThemeOverrideWarning;
