<div class="list-group-item tracks-item-row">
    <div
        class="tracks-item-box flex-vmiddle tracks-items-flex-basis mod-{{
            itemMap[item.trackId].blocked ? 'danger' : statuses[itemMap[item.trackId].status].cssclass
        }}"
    >
        <!-- 3 doted handle -->
        <div
            class="dropbox hidden-xs flex-no-shrink track-menu-button visible-on-hover"
            uib-dropdown
            on-toggle="toggled(open)"
        >
            <div
                class="pointer dropdown-toggle tracks-item-icon-menu flex-vmiddle common-color-light-grey2"
                uib-dropdown-toggle
                ng-click="data.showMenu = true"
            >
                <i class="svg-icon-smd svg-icon-hover-primary pointer flex-vmiddle svg-icon-vmiddle">
                    <tnk-icon src="../../../../../apps/tracks/images/icons/ellipsis-v.svg"></tnk-icon>
                </i>
            </div>
            <ul uib-dropdown-menu class="dropdown-menu" role="menu">
                <li>
                    <a
                        ui-sref="product.interface.group({groupId: itemMap[item.trackId].group.id, initiativeId: item.id})"
                    >
                        Open item
                    </a>
                </li>
                <li class="divider"></li>
                <li ng-show="!itemMap[item.trackId].isDetached">
                    <a ng-click="archiveItem(itemMap[item.trackId])">Un-Archive</a>
                </li>
                <li>
                    <a ng-click="deleteItem(itemMap[item.trackId], $index)">Delete</a>
                </li>
                <li ng-if="!uiParent[item.id]" class="divider"></li>
                <li ng-if="!uiParent[item.id]">
                    <a ng-click="data.bulkSelecting = true; data.bulkSelectItems[item.id] = true">Bulk Select</a>
                </li>
            </ul>
        </div>

        <!-- Bulk select checkbox -->
        <i
            class="pointer flex-no-shrink common-color-grey fa common-size-s margin-right"
            ng-if="data.bulkSelecting"
            ng-class="{
                'fa-check-square-o common-color-primary': data.bulkSelectItems[item.id],
                'fa-square-o': !data.bulkSelectItems[item.id]
            }"
            ng-click="data.bulkSelectItems[item.id] = !data.bulkSelectItems[item.id]"
        ></i>

        <!-- Bullet -->
        <i
            class="pointer flex-no-shrink common-color-white track-item-bullet"
            ng-hide="data.bulkSelecting"
            ng-style="{
                'background-color': itemMap[item.trackId].status !== 'FUTURE' ? itemMap[item.trackId].stateColor : ''
            }"
        ></i>

        <div class="flex-vmiddle tracks-item-box-border-container flex-grow flex-self-stretch">
            <!-- Title -->
            <div class="tracks-col-text flex-vmiddle flex-grow padding-left">
                <div>
                    <div
                        class="common-size-xxxxs common-color-grey text-left hidden-sub"
                        ng-if="::((!parentItem) && itemMap[item.trackId].parentInitiatives && itemMap[item.trackId].parentInitiatives.length)"
                    >
                        <span ng-repeat="p in ::itemMap[item.trackId].parentInitiatives">
                            <a ng-click="goToItem(p)" class="common-color-grey margin-right-xs">
                                {{ p.title || itemMap[p.id].title }}
                            </a>
                            <i class="fa fa-angle-right margin-right-xs common-color-light-grey"></i>
                        </span>
                    </div>
                    <div
                        id="item-text-{{ item.id }}{{ editorId }}"
                        name="item-text-{{ item.id }}{{ editorId }}"
                        class="track-item-text hidden-xs hidden-sm"
                    >
                        {{ itemMap[item.trackId].title }}
                    </div>
                    <div class="flex-no-shrink visible-xs visible-sm" ng-click="goToItem(item, $event)">
                        <div class="common-text-wrap track-item-text">
                            {{ itemMap[item.trackId].title || item.title }}
                        </div>
                    </div>
                </div>
                <i
                    ng-if="itemMap[item.trackId].description && itemMap[item.trackId].description.length"
                    class="common-color-grey common-size-xxxxs pointer padding-left-xs fa fa-align-left"
                    uib-popover-template="'../tnkViewFunctionPopover/viewFunctionPopoverTemplate.template.html'"
                    popover-is-open="itemMap[item.id].popoverIsOpen"
                    popover-trigger="mouseenter"
                    popover-placement="auto right"
                ></i>
                <div
                    class="flex-grow hidden-xs hidden-sm track-item-func pointer"
                    ng-dblclick="goToItem(itemMap[item.trackId])"
                    ng-click="openOrExtend(itemMap[item.trackId])"
                >
                    &nbsp;
                </div>
            </div>

            <!-- Click zone -->
            <div
                class="flex-grow hidden-xs hidden-sm track-item-func pointer"
                ng-dblclick="goToItem(itemMap[item.trackId])"
                ng-click="openOrExtend(itemMap[item.trackId])"
            >
                &nbsp;
            </div>

            <!-- Open button -->
            <div class="flex-vmiddle visible-on-hover hidden-xs hidden-sm" ng-if="itemMap[item.trackId].created">
                <a
                    class="btn btn-default"
                    ui-sref="product.interface.group({groupId: itemMap[item.trackId].group.id, initiativeId: item.id})"
                    ng-click="goToItem(item, $event)"
                >
                    Open
                </a>
            </div>

            <!-- Tags -->
            <div
                class="flex-vmiddle mod-center tracks-col-info2 hidden-xs hidden-sm"
                ng-if="itemMap[item.trackId].created"
                ng-class="{
                    'visible-on-hover':
                        (!itemMap[item.trackId].tags || !itemMap[item.trackId].tags.length) && data.tagsOpen !== item.id
                }"
            >
                <i
                    class="margin-normal-h fa fa-tags common-color-light-grey2"
                    ng-click="data.tagsOpen = item.id"
                    ng-if="itemMap[item.trackId].tags && itemMap[item.trackId].tags.length > 1"
                    uib-tooltip="{{
                        itemMap[item.trackId].tags ? 'Tags: #' + itemMap[item.trackId].tags.join(' #') : ''
                    }}"
                ></i>
                <span
                    ng-if="itemMap[item.trackId].tags && itemMap[item.trackId].tags.length === 1"
                    uib-tooltip="{{ itemMap[item.trackId].tags[0].length > 10 ? itemMap[item.trackId].tags[0] : '' }}"
                    class="initiative-tag pointer common-size-xxxxxs"
                >
                    #{{
                        itemMap[item.trackId].tags[0].length > 10
                            ? itemMap[item.trackId].tags[0].substr(0, 10) + '..'
                            : itemMap[item.trackId].tags[0]
                    }}
                </span>
            </div>

            <!-- Due date set -->
            <div
                class="common-size-xxxxs common-color-grey hidden-xs hidden-sm tracks-col-info2 common-bold text-left"
                ng-class="{
                    'visible-on-hover':
                        itemMap[item.trackId].status === 'FUTURE' &&
                        !itemMap[item.trackId].dueDate &&
                        !itemMap[item.trackId].inInbox
                }"
            >
                <div ng-if="itemMap[item.trackId].created && itemMap[item.trackId].dueDate">
                    <div
                        class="flex-vmiddle"
                        ng-class="
                            itemMap[item.trackId].status !== 'DONE' &&
                            (itemMap[item.trackId].dueDate < todayTime ||
                                itemMap[item.trackId].eta > itemMap[item.trackId].dueDate)
                                ? 'common-color-danger'
                                : 'common-color-grey'
                        "
                    >
                        <i class="svg-icon-track svg-icon-align-text-top margin-right-xxs">
                            <tnk-icon src="../../../../../apps/tracks/images/icons/calendar.svg"></tnk-icon>
                        </i>
                        <span
                            uib-tooltip="Due: {{ itemMap[item.trackId].dueDate | daysHoursDiff }}"
                            tooltip-placement="auto bottom"
                        >
                            {{ itemMap[item.trackId].dueDate | date: 'MMM d' }}
                        </span>
                    </div>
                </div>
            </div>

            <!-- Status -->
            <div class="flex-vmiddle flex-no-shrink tracks-col-status" ng-if="itemMap[item.trackId].created">
                <div class="flex-no-shrink">
                    <div class="track-padding-right-no-xs">
                        <div class="track-item-func flex-vmiddle">
                            <div class="hidden-xs flex-vmiddle track-item-func-height flex-grow">
                                <div
                                    class="
                                        tracks-col-status-text
                                        flex-grow flex
                                        common-size-xxxxs
                                        text-left
                                        common-ellipsis
                                        relative
                                    "
                                    ng-if="itemMap[item.trackId].created"
                                    ng-style="{
                                        color:
                                            itemMap[item.trackId].status !== 'FUTURE' &&
                                            itemMap[item.trackId].stateColor
                                                ? itemMap[item.trackId].stateColor
                                                : ''
                                    }"
                                    uib-popover-template="'../tnkViewFunctionPopover/viewFunctionPopoverTemplate.template.html'"
                                    popover-is-open="listPopoverIsOpen[item.id]"
                                    popover-enable="(itemMap[item.trackId].updateText || itemMap[item.trackId].eta || itemMap[item.trackId].description)"
                                    popover-trigger="mouseenter"
                                    popover-placement="bottom-right"
                                >
                                    <span
                                        class="flex-grow"
                                        ng-style="{ opacity: itemMap[item.trackId].status === 'FUTURE' ? '0.3' : '1' }"
                                    >
                                        {{
                                            itemMap[item.trackId].status !== 'FUTURE'
                                                ? itemMap[item.trackId].stateText
                                                : ''
                                        }}
                                    </span>
                                    <!-- Status underline -->
                                    <span
                                        class="tracks-col-status-line"
                                        ng-if="itemMap[item.trackId].status !== 'FUTURE'"
                                        ng-style="{
                                            width: '100%',
                                            'background-color': itemMap[item.trackId].stateColor
                                        }"
                                    ></span>
                                </div>
                            </div>
                            <div class="visible-xs track-leader-avatar mod-border avatar-main img-circle avatar">
                                <img
                                    class="avatar-img img-circle"
                                    ng-if="itemMap[item.trackId].owner && itemMap[item.trackId].owner.avatarUri"
                                    ng-src="{{ itemMap[item.trackId].owner.avatarUri }}"
                                />
                                <span
                                    ng-if="!itemMap[item.trackId].owner || !itemMap[item.trackId].owner.avatarUri"
                                    class="avatar-img img-circle svg-icon-lg common-color-grey"
                                >
                                    <tnk-icon src="../../../../../apps/tracks/images/icons/avatar.svg"></tnk-icon>
                                </span>
                            </div>
                        </div>
                    </div>
                    <ul
                        class="dropdown-menu-right"
                        uib-dropdown-menu
                        ng-if="!itemMap[item.trackId].isArchived && data.showMenu"
                        aria-labelledby="simple-dropdown-{{ item.id }}"
                    >
                        <li ng-if="itemMap[item.trackId].owner && itemMap[item.trackId].owner.id !== as.currentUser.id">
                            <a ng-click="askForUpdates(itemMap[item.trackId].owner, itemMap[item.trackId])">
                                <span class="{{ itemMap[item.trackId].owner.isTemporary ? 'common-disabled' : '' }}">
                                    Ask {{ itemMap[item.trackId].owner.name }}
                                </span>
                            </a>
                        </li>
                        <li
                            class="divider"
                            ng-if="itemMap[item.trackId].owner && itemMap[item.trackId].owner.id !== as.currentUser.id"
                        ></li>
                        <li>
                            <a ng-click="openEditStatus(itemMap[item.trackId])">Give an update</a>
                        </li>
                        <li class="divider"></li>
                        <li
                            ng-repeat="state in wvm.getCachedWorkflowVersion(workflowVersionId).states"
                            ng-if="state.label && state.label.length"
                        >
                            <a ng-click="openEditStatus(itemMap[item.trackId], false, state)">
                                <i class="fa fa-square margin-right" ng-style="{ color: state.color }"></i>
                                {{ state.label }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Owner and Function -->
            <div class="flex-vmiddle tracks-col-who mod-mini">
                <!-- Owner avatar -->
                <div
                    class="
                        track-leader-avatar
                        mod-border
                        avatar-main
                        img-circle
                        avatar
                        common-color-grey
                        svg-icon-hover-black
                    "
                    uib-tooltip="{{
                        itemMap[item.trackId].owner
                            ? itemMap[item.trackId].owner.name
                            : itemMap[item.trackId].function
                            ? itemMap[item.trackId].function.name
                            : ''
                    }}"
                    tooltip-placement="bottom"
                    tooltip-enable="itemMap[item.trackId].owner || itemMap[item.trackId].function"
                >
                    <span ng-if="!itemMap[item.trackId].owner" class="avatar-img img-circle svg-icon-lg">
                        <tnk-icon src="../../../../../apps/tracks/images/icons/add-owner.svg"></tnk-icon>
                    </span>
                    <img
                        ng-if="itemMap[item.trackId].owner && !itemMap[item.trackId].owner.isGravatar"
                        class="avatar-img img-circle"
                        ng-src="{{ itemMap[item.trackId].owner.avatarUri }}"
                    />
                    <tnk-avatar
                        ng-if="itemMap[item.trackId].owner && itemMap[item.trackId].owner.isGravatar"
                        user="itemMap[item.trackId].owner"
                        class="track-leader-avatar mod-border"
                        default-on-empty="true"
                    ></tnk-avatar>
                </div>
            </div>
        </div>
    </div>
</div>
