import template from './tnkMessagePreferences.template.html?angularjs';
/**
 * Created by ShaLi on 04/11/2015.
 */

export default angular.module('tonkean.app').component('tnkMessagePreferences', {
    bindings: {
        messageId: '@',
        workingOnCurrentUser: '@',
        excludeOptionKey: '@',
        onSelectedPreferencesChanged: '&',
    },
    template,
    controller: 'MessagePreferencesCtrl',
});
