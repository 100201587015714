import React from 'react';

import { range } from '@tonkean/utils';
import { classNames } from '@tonkean/utils';
import type { StyledComponentsSupportProps } from '@tonkean/utils';

interface Props extends StyledComponentsSupportProps {
    loading?: boolean;
}

const InitiativesPlaceholderContainer: React.FC<Props> = ({ loading = true, className }) => {
    return (
        <div className={classNames('relative initiatives-placeholder-container', className)}>
            {/* placeholder table */}
            <div className={classNames('initiatives-placeholder', loading && 'mod-loading')}>
                <div className="tracks-headers-container padding-normal-xs flex-vmiddle">
                    <div className="placeholder-base mod-white mod-lg flex-grow margin-normal margin-normal-xs-v">
                        Tracks
                    </div>
                    <div className="placeholder-base mod-white mod-md flex-grow hidden-xs margin-normal-xs-v" />
                    <div className="placeholder-base mod-white mod-sm flex-grow margin-normal-xs-v">Due date</div>
                    <div className="placeholder-base mod-white mod-sm flex-grow margin-normal-xs-v">Status</div>
                    <div className="placeholder-base mod-white mod-sm flex-grow hidden-xs margin-normal-xs-v">
                        Owner
                    </div>
                    <div className="placeholder-base mod-white mod-sm flex-grow hidden-xs margin-normal-xs-v">KPI</div>
                    <div className="placeholder-base mod-white mod-sm flex-grow hidden-xs margin-normal-xs-v">KPI</div>
                    <div className="placeholder-base mod-white mod-sm flex-grow hidden-xs margin-normal-xs-v">KPI</div>
                    <div className="placeholder-base mod-white mod-sm">KPI</div>
                </div>
                {range(9).map((index) => (
                    <div
                        key={index}
                        className="flex-vmiddle margin-normal-v"
                        style={{ opacity: loading ? 1 - index * 0.1 : 1 }}
                    >
                        <div className="placeholder-circle" />
                        <div className="placeholder-rect mod-lg" />
                        <div className="placeholder-base mod-white mod-sm hidden-xs" />
                        <div className="placeholder-rect mod-sm mod-big hidden-xs" />
                        <div className="placeholder-rect mod-sm mod-big" />
                        <div className="placeholder-circle mod-big" />
                        <div className="placeholder-rect mod-sm mod-big hidden-xs" />
                        <div className="placeholder-rect mod-sm mod-big hidden-xs" />
                        <div className="placeholder-rect mod-sm mod-big hidden-xs" />
                        <div className="placeholder-rect mod-sm mod-big" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InitiativesPlaceholderContainer;
