import React, { useMemo } from 'react';
import styled from 'styled-components';

import workerRunStageConfig from '../../entities/workerRunStageConfig';

import { Placeholder, TextEllipsis } from '@tonkean/infrastructure';
import type { WorkerRunStage } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const theme = Theme.current.palette.history.workerRunsList;

const Header = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.borderColor};
    padding: 0 20px;
    flex-grow: 0;
    flex-shrink: 0;
`;

const Icon = styled.span`
    line-height: 0;
`;

const Title = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    font-weight: normal;
    font-size: ${FontSize.LARGE_16};
    line-height: normal;
    color: ${Theme.colors.gray_800};
    margin: 0 10px;
`;

const Date = styled.small`
    flex-grow: 1;
    flex-shrink: 0;
    font-size: ${FontSize.SMALL_12};
    line-height: normal;
    color: ${theme.tableHeaderColor};
    padding-top: 3px;
`;

const Main = styled.div<{ scrollable: boolean }>`
    flex-grow: 1;
    overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
`;

const NamePlaceHolder = styled(Placeholder)`
    margin-right: 10px;
`;

interface Props {
    startTime?: Date | number;
    icon?: React.ReactNode;
    title?: React.ReactNode;
    workerRunStage: WorkerRunStage;
    scrollable?: boolean;
}

const SingleWorkerRunContent: React.FC<React.PropsWithChildren<Props>> = ({
    startTime,
    icon,
    title,
    children,
    workerRunStage,
    scrollable = false,
}) => {
    const timeAgo = useMemo(() => {
        if (!startTime) {
            return;
        }

        return utils.formatDate(startTime, true, false, true, true);
    }, [startTime]);

    return (
        <>
            <Header>
                <Icon>{icon}</Icon>
                <Title>
                    {title ? (
                        <>
                            <TextEllipsis dataAutomation="single-worker-run-header-title" numberOfLines={1} tooltip>
                                {workerRunStageConfig[workerRunStage].singleName}
                                {title && ` - ${title}`}
                            </TextEllipsis>
                        </>
                    ) : (
                        <NamePlaceHolder $height={FontSize.LARGE_16} $width="100px" />
                    )}
                </Title>
                <Date>{timeAgo || <Placeholder $height={FontSize.SMALL_12} $width="100px" />}</Date>
            </Header>

            <Main scrollable={scrollable}>{children}</Main>
        </>
    );
};

export default SingleWorkerRunContent;
