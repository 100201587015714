import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Checkbox, Placeholder, Table, TextEllipsis } from '@tonkean/infrastructure';
import type { ProjectIntegrationEntityMarketplaceSummary } from '@tonkean/tonkean-entities';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const StyledNoResults = styled.div`
    padding: 20px 10px;
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    entities: ProjectIntegrationEntityMarketplaceSummary[];
    loading?: boolean;
}

const MarketplaceItemCreateModalDataSourceEntitiesTab: React.FC<Props> = ({ entities, loading }) => {
    const columns = [
        {
            Header: 'Name',
            accessor: 'name' as const,
            width: '20%',
        },
        {
            Header: 'Description',
            accessor: 'description' as const,
            width: '60%',
        },
        {
            Header: 'Include Schema',
            accessor: 'includeSchema' as const,
            width: '20%',
        },
    ];

    const entitiesRows = useMemo(() => {
        return entities.map((entity, index) => {
            return {
                name: entity.displayName,
                description: (
                    <TextEllipsis numberOfLines={1} tooltip>
                        No Description
                    </TextEllipsis>
                ),
                includeSchema: <Checkbox name={`configuration.entities.${index}.includeSchema`} />,
            };
        });
    }, [entities]);

    return (
        <>
            {loading ? (
                range(6).map((index) => <Placeholder key={index} $width="100%" $height="30px" />)
            ) : (
                <>
                    <Table columns={columns} data={entitiesRows} />

                    {!entitiesRows.length && <StyledNoResults>No entities to show.</StyledNoResults>}
                </>
            )}
        </>
    );
};

export default MarketplaceItemCreateModalDataSourceEntitiesTab;
