<form novalidate name="initiativeCommentForm" id="initiativeCommentForm">
    <div class="flex" ng-class="{ 'mod-single-line': singleLineMode }">
        <tnk-avatar class="tnk-message-textbox-avatar flex-no-shrink" ng-hide="anonymous"></tnk-avatar>
        <!-- Not single line mode -->
        <div class="flex-grow relative" ng-if="!singleLineMode">
            <label for="{{::commentTextAreaId}}" class="sr-only">Comment</label>
            <textarea
                class="form-control tnk-message-textbox-textarea common-resize-y-only"
                id="{{::commentTextAreaId}}"
                mentio
                rows="{{ state.isFocus || (state.text && state.text.length) ? rows : 1 }}"
                placeholder="{{ placeholder }}"
                maxlength="{{ maxlength || -1 }}"
                ng-keydown="onMessageKeydown($event)"
                ng-change="onTextChangedInner(state.text)"
                ng-focus="state.isFocus = true"
                ng-blur="state.isFocus = false"
                ng-required="required"
                ng-model="state.text"
            ></textarea>
            <tnk-people-at-mention
                text-id="{{::commentTextAreaId}}"
                result-list="state.atMentions"
                include-functions="true"
                opened="onAtMentionIsOpen(open)"
            ></tnk-people-at-mention>
            <div class="tnk-message-textbox-tri" ng-hide="anonymous"></div>
        </div>

        <!-- Single line mode -->
        <div class="flex-grow relative" ng-if="singleLineMode">
            <label for="smallComment" class="sr-only">Comment</label>
            <input
                type="text"
                id="smallComment"
                class="form-control tnk-message-textbox-input"
                mentio
                placeholder="{{ placeholder }}"
                autocomplete="off"
                maxlength="{{ maxlength || -1 }}"
                ng-keydown="onMessageKeydown($event)"
                ng-change="onTextChangedInner(state.text)"
                ng-focus="state.isFocus = true"
                ng-blur="state.isFocus = false"
                ng-required="required"
                ng-model="state.text"
            />
            <tnk-people-at-mention
                text-id="smallComment"
                result-list="state.atMentions"
                include-functions="true"
                opened="onAtMentionIsOpen(open)"
            ></tnk-people-at-mention>
            <a
                class="tnk-message-textbox-input-button"
                ng-click="!isDisabled && postComment()"
                ng-class="{ 'mod-disabled': isDisabled }"
            >
                <span ng-show="!postingComment">Send</span>
                <span ng-show="postingComment" class="loading-small"></span>
            </a>
        </div>
    </div>

    <div class="flex-vmiddle tnk-message-textbox-footer" ng-show="state.isFocus || (state.text && state.text.length)">
        <div class="flex-grow">
            <div
                class="common-size-xxxxs margin-top-xs common-color-warning"
                ng-if="
                    (state.text.length || state.isFocus) && maxlength && maxlength - state.text.length < maxlength / 2
                "
            >
                {{ maxlength - state.text.length }} characters left
            </div>
        </div>
        <button
            type="submit"
            class="btn btn-primary pull-right margin-top-xs"
            ng-if="!singleLineMode"
            ng-disabled="postingComment || isDisabled"
            ng-click="postComment()"
        >
            <span ng-hide="postingComment">Post</span>
            <span ng-show="postingComment">Posting...</span>
        </button>
    </div>

    <div class="common-color-danger padding-normal-xs" ng-show="commentError">Failed to post comment</div>
</form>
