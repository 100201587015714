import React from 'react';
import styled from 'styled-components';

import ItemInterfaceMobilePanel from './ItemInterfaceMobilePanel';
import type { ItemInterfacePermission } from '../../../entities';
import type { InterfaceLiveDisplay } from '../ItemInterface/InterfaceLiveDisplay';
import PanelItemInterfaceView from '../ItemInterface/PanelItemInterfaceView';

import { useFeatureFlag } from '@tonkean/angular-hooks';
import type { ItemInterfaceContextData } from '@tonkean/infrastructure';
import { ItemInterfaceContext } from '@tonkean/infrastructure';
import type {
    Initiative,
    InterfaceCTASavedAction,
    ItemInterface,
    ItemInterfaceWidget,
    ItemInterfaceWidgetConfiguration,
    WorkflowVersion,
} from '@tonkean/tonkean-entities';
import type { ItemInterfaceLoadingState } from '@tonkean/tonkean-entities';

const Container = styled.div`
    width: 100%;
    z-index: 2;
    box-shadow: -7px 0px 9px 0px rgba(0, 0, 0, 0.06);
    overflow-y: auto;
`;

interface ItemInterfacePanelProps {
    panelItemInterfaceContextValue: ItemInterfaceContextData;
    isMobile: boolean;
    panelItemInterface: ItemInterface;
    itemInterfaceWidgetsState: ItemInterfaceLoadingState;
    widgets: ItemInterfaceWidget<ItemInterfaceWidgetConfiguration>[];
    initiative: Initiative | undefined;
    workflowVersion: WorkflowVersion | undefined;
    permission: ItemInterfacePermission;
    showPoweredByTonkean: boolean;
    itemActions: InterfaceCTASavedAction[];
    refreshItem: (() => void) | undefined;
    liveDisplay: InterfaceLiveDisplay | undefined;
}

export const ItemInterfacePanel: React.FC<ItemInterfacePanelProps> = ({
    panelItemInterfaceContextValue,
    isMobile,
    panelItemInterface,
    itemInterfaceWidgetsState,
    widgets,
    initiative,
    workflowVersion,
    permission,
    showPoweredByTonkean,
    itemActions,
    refreshItem,
    liveDisplay,
}) => {
    const enableMobileSidePane = useFeatureFlag('tonkean_feature_enable_item_interface_mobile_side_panel');

    if (isMobile) {
        if (enableMobileSidePane) {
            return (
                <ItemInterfaceMobilePanel
                    panelItemInterfaceContextValue={panelItemInterfaceContextValue}
                    panelItemInterface={panelItemInterface}
                    itemInterfaceWidgetsState={itemInterfaceWidgetsState}
                    widgets={widgets}
                    initiative={initiative}
                    workflowVersion={workflowVersion}
                    permission={permission}
                    showPoweredByTonkean={showPoweredByTonkean}
                    itemActions={itemActions}
                    refreshItem={refreshItem}
                    liveDisplay={liveDisplay}
                />
            );
        } else {
            return <></>;
        }
    }

    return (
        <Container>
            <ItemInterfaceContext.Provider value={panelItemInterfaceContextValue}>
                <PanelItemInterfaceView
                    itemInterface={panelItemInterface}
                    itemInterfaceWidgetsState={itemInterfaceWidgetsState}
                    widgets={widgets}
                    initiative={initiative}
                    workflowVersion={workflowVersion}
                    permission={permission}
                    showPoweredByTonkean={showPoweredByTonkean}
                    itemActions={itemActions}
                    refreshItem={refreshItem}
                    liveDisplay={liveDisplay}
                />
            </ItemInterfaceContext.Provider>
        </Container>
    );
};

export default ItemInterfacePanel;
