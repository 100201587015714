import { useAngularService } from 'angulareact';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';

import ChainOfEvents from './components/ChainOfEvents/ChainOfEvents';
import HistoryHeader from './components/HistoryHeader';
import SingleWorkerRun from './components/SingleWorkerRun/SingleWorkerRun';
import SingleWorkerRunWrapper from './components/SingleWorkerRun/SingleWorkerRunWrapper';
import WorkerRuns from './components/WorkerRuns/WorkerRuns';
import WorkerRunsTableItemStageIcon from './components/WorkerRuns/WorkerRunsTable/WorkerRunsTableItemStageIcon';
import HistoryContext from './entities/HistoryContext';
import initialFilters from './entities/initialFilters';
import type WorkerRunDateRangeFilter from './entities/WorkerRunDateRangeFilter';
import type { StateParams } from './hooks/useHistoryStateManager';
import useHistoryStateManager, { RootState } from './hooks/useHistoryStateManager';
import currentWorkerRunState from './states/currentWorkerRunState';
import historyBreadcrumbsState from './states/historyBreadcrumbsState';
import initialWorkerRunForDrillState from './states/initialWorkerRunForDrillState';
import getWorkerRunFullDescription from './utils/getWorkerRunFullDescription';
import { ReactComponent as EmptyFieldsIcon } from '../../../images/icons/history/empty-fields.svg';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { createWorkerRunInfo, getWorkerRunInfo } from '@tonkean/infrastructure';
import { StateMessage } from '@tonkean/infrastructure';
import type { Environment, WorkerRunLogicInspectTabType, WorkerRunStage } from '@tonkean/tonkean-entities';
import type { RuntimePageTab } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { Theme } from '@tonkean/tui-theme';

const Grid = styled.div<{ displayChainOfEvents?: boolean }>`
    background: ${Theme.colors.HEX_F2F2F2};
    height: 100%;
    overflow: hidden;

    display: grid;
    ${({ displayChainOfEvents }) =>
        displayChainOfEvents &&
        css`
            grid-template-areas:
                'header header header'
                'actions conent chain-of-events';
            grid-template-columns: 3fr 4fr 50px;
        `}

    ${({ displayChainOfEvents }) =>
        !displayChainOfEvents &&
        css`
            grid-template-areas:
                'header header'
                'actions content';
            grid-template-columns: 3fr 4fr;
        `}

  grid-template-rows: 54px 1fr;

    ${childrenStyledFocus}
`;

const Header = styled(HistoryHeader)`
    grid-area: header;
    position: relative;
    z-index: 3;
`;

const WorkerRunsSide = styled(WorkerRuns)`
    grid-area: actions;
    position: relative;
    z-index: 2;
`;

const ContentWrapper = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    padding-top: 20px;
`;

const ChainOfEventsSide = styled(ChainOfEvents)`
    grid-area: chain-of-events;
    z-index: 2;
`;

const WorkerRunLinkWrapper = styled.div`
    display: flex;
    align-content: center;
`;

const WorkerRunLink = styled(StateLink)`
    line-height: 14px;
    margin-left: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    text-decoration: underline;
    color: ${Theme.colors.gray_600};

    &:hover {
        color: ${Theme.colors.gray_600};
    }
`;

const Description = styled.span`
    margin-right: 8px;
`;

interface Props {
    isModal: boolean;
    group: any;
    groups?: any[];
    environment: Environment;
    workerRunInfo?: string;
    workerRunStage?: WorkerRunStage;
    inspect: boolean;
    workerRunLogicId?: string;
    tab?: WorkerRunLogicInspectTabType | RuntimePageTab;
    defaultFilterSearchTerm?: string;

    onCloseClick?(): void;
}

const History: React.FC<Props> = ({
    isModal,
    groups = [],
    group,
    environment,
    workerRunInfo,
    workerRunStage,
    inspect,
    workerRunLogicId,
    tab,
    defaultFilterSearchTerm = '',
    onCloseClick,
}) => {
    const [loading, setLoading] = useState(true);
    const initialWorkerRunForDrill = useRecoilValue(initialWorkerRunForDrillState);
    const historyBreadcrumbs = useRecoilValue(historyBreadcrumbsState);
    const resetInitialWorkerRunForDrill = useResetRecoilState(initialWorkerRunForDrillState);
    const resetCurrentWorkerRunState = useResetRecoilState(currentWorkerRunState);
    const [from] = useGetStateParams<[number]>('from');
    const [to] = useGetStateParams<[number]>('to');

    const $state = useAngularService('$state');
    const $timeout = useAngularService('$timeout');
    const groupId = group.id;
    const stateParams = useMemo(
        (): StateParams => ({
            groupId,
            environment,
            workerRunInfo,
            workerRunStage,
            inspect,
            workerRunLogicId,
            tab,
            defaultFilterSearchTerm,
        }),
        [groupId, environment, workerRunInfo, workerRunStage, inspect, workerRunLogicId, tab, defaultFilterSearchTerm],
    );
    const { state, updateState, getState } = useHistoryStateManager(
        isModal ? RootState.MODAL : RootState.TAB,
        stateParams,
    );

    const environmentIsActive = state.environment === 'build' ? group.buildEnvironmentEnabled : group.workerEnabled;

    const [rangeFilter, setRangeFilter] = useState<WorkerRunDateRangeFilter>(initialFilters.dateRange);

    // Calculate the from and to dates from the parameters, if given, or use the default, and them navigate to the correct state
    useEffect(() => {
        const isFromExistsAndIsNumber = !!(from && !isNaN(from));
        const isToExistsAndIsNumber = !!(to && !isNaN(to));
        const dateFrom = isFromExistsAndIsNumber ? dayjs(from * 1000).toDate() : initialFilters.dateRange().dateFrom;
        const dateTo = isToExistsAndIsNumber ? dayjs(to * 1000).toDate() : initialFilters.dateRange().dateTo;

        setRangeFilter({
            dateFrom,
            dateTo,
        });
    }, [from, to, $state, $timeout]);

    const contextValue = useMemo(
        () => ({
            state,
            updateState,
            getState,
            environmentIsActive,
            setRangeFilter,
            rangeFilter,
        }),
        [state, updateState, getState, environmentIsActive, rangeFilter],
    );

    // If there is no worker run id, and the list of worker runs isn't loading (because if it's loading,
    // it might auto-select a worker run), show the empty state.
    const showEmptyState = useMemo(() => {
        return !getWorkerRunInfo(state.workerRunInfo) && !loading;
    }, [loading, state.workerRunInfo]);

    const breadcrumbsState = useMemo(
        () =>
            getState({
                workerRunInfo: historyBreadcrumbs?.workerRun
                    ? createWorkerRunInfo(historyBreadcrumbs?.workerRun.id, historyBreadcrumbs?.workerRun.startTime)
                    : undefined,
                workerRunStage: historyBreadcrumbs?.workerRun.workerRunStage,
            }),
        [getState, historyBreadcrumbs?.workerRun],
    );

    useEffect(() => {
        return () => {
            resetInitialWorkerRunForDrill();
            resetCurrentWorkerRunState();
        };
    }, [resetCurrentWorkerRunState, resetInitialWorkerRunForDrill]);

    const onInitialWorkerRunLinkClick = useCallback(() => {
        updateState({
            workerRunInfo: initialWorkerRunForDrill
                ? createWorkerRunInfo(initialWorkerRunForDrill.id, initialWorkerRunForDrill.startTime)
                : undefined,
            workerRunStage: initialWorkerRunForDrill?.workerRunStage,
        });
    }, [initialWorkerRunForDrill, updateState]);

    const onParentLinkClick = useCallback(() => {
        updateState({
            workerRunInfo: historyBreadcrumbs?.workerRun
                ? createWorkerRunInfo(historyBreadcrumbs?.workerRun.id, historyBreadcrumbs?.workerRun.startTime)
                : undefined,
            workerRunStage: historyBreadcrumbs?.workerRun.workerRunStage,
        });
    }, [historyBreadcrumbs?.workerRun, updateState]);

    return (
        <HistoryContext.Provider value={contextValue}>
            <Grid data-automation="history-grid" displayChainOfEvents>
                <Header group={group} groups={groups} isModal={isModal} onCloseClick={onCloseClick} />

                <WorkerRunsSide groupId={state.groupId} onLoading={setLoading} />

                <ContentWrapper>
                    {showEmptyState ? (
                        <SingleWorkerRunWrapper>
                            <StateMessage
                                icon={
                                    <span className="tnk-icon">
                                        <EmptyFieldsIcon />
                                    </span>
                                }
                            >
                                No Event To Show
                            </StateMessage>
                        </SingleWorkerRunWrapper>
                    ) : (
                        <>
                            {initialWorkerRunForDrill && (
                                <WorkerRunLink
                                    state={breadcrumbsState.name}
                                    params={breadcrumbsState.params}
                                    onClick={onInitialWorkerRunLinkClick}
                                    dontChangeState
                                >
                                    <WorkerRunLinkWrapper>
                                        <WorkerRunsTableItemStageIcon workerRun={initialWorkerRunForDrill} />
                                        <Description>
                                            {getWorkerRunFullDescription(initialWorkerRunForDrill)}
                                        </Description>
                                    </WorkerRunLinkWrapper>
                                </WorkerRunLink>
                            )}

                            {historyBreadcrumbs && (
                                <WorkerRunLink
                                    state={breadcrumbsState.name}
                                    params={breadcrumbsState.params}
                                    onClick={onParentLinkClick}
                                    dontChangeState
                                >
                                    <WorkerRunLinkWrapper>
                                        <WorkerRunsTableItemStageIcon workerRun={historyBreadcrumbs.workerRun} />
                                        <Description>
                                            {getWorkerRunFullDescription(historyBreadcrumbs.workerRun)}
                                        </Description>
                                    </WorkerRunLinkWrapper>
                                </WorkerRunLink>
                            )}
                            <SingleWorkerRun projectId={group.project.id} />
                        </>
                    )}
                </ContentWrapper>

                <ChainOfEventsSide />
            </Grid>
        </HistoryContext.Provider>
    );
};

export default History;
