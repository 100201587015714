import { useCallback, useState } from 'react';

import type { SearchDropdownSelectorOption } from '../UpdateFieldAsyncSearchDropdownSelector';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import type { FieldDefinition, TonkeanId, TonkeanType, WorkflowVersionType } from '@tonkean/tonkean-entities';

const useDropdownOptionsFromMatchedEntity = (
    workflowVersionType: WorkflowVersionType | undefined,
    initiativeId: TonkeanId<TonkeanType.INITIATIVE> | undefined,
    fieldDefinitionId: FieldDefinition['id'],
) => {
    const [options, setOptions] = useState<SearchDropdownSelectorOption[]>([]);

    const [{ loading: loadingByInitiative }, searchDropDownOptionsFromMatchedDataSourceByInitiative] =
        useLazyTonkeanService('searchDropDownOptionsFromMatchedDataSourceByInitiative');

    const [{ loading: loadingByWorkflowVersionType }, searchDropDownOptionsFromMatchedDataSourceByVersionType] =
        useLazyTonkeanService('searchDropDownOptionsFromMatchedDataSourceByVersionType');

    const getDropDownOptionsFromMatchedEntity = useCallback(
        async (dropDownSearch: string | undefined) => {
            if (!workflowVersionType && !initiativeId) {
                setOptions([]);

                return;
            }

            setOptions([]);

            if (!initiativeId) {
                const { results } = await searchDropDownOptionsFromMatchedDataSourceByVersionType(
                    fieldDefinitionId,
                    workflowVersionType!,
                    dropDownSearch,
                );
                const data = results.map((item) => ({ label: item, value: item }));

                setOptions(data);
            } else {
                const { results } = await searchDropDownOptionsFromMatchedDataSourceByInitiative(
                    fieldDefinitionId,
                    initiativeId,
                    dropDownSearch,
                );

                const data = results.map((item) => ({ label: item, value: item }));

                setOptions(data);
            }
        },
        [
            fieldDefinitionId,
            initiativeId,
            searchDropDownOptionsFromMatchedDataSourceByInitiative,
            searchDropDownOptionsFromMatchedDataSourceByVersionType,
            workflowVersionType,
        ],
    );

    return [
        {
            options,
            isLoading: loadingByInitiative || loadingByWorkflowVersionType,
        },
        getDropDownOptionsFromMatchedEntity,
    ] as const;
};

export default useDropdownOptionsFromMatchedEntity;
