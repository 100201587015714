import { angularToReact } from 'angulareact';

import type { TonkeanId } from '@tonkean/tonkean-entities';

const TnkNotificationSettings = angularToReact<{
    overwriteSettings?: any;
    overwriteId?: TonkeanId;
    disableEdit?: boolean;
    controlledSave?: boolean;
    explanationInTooltip?: boolean;
    onChannelChanged?: (channel: any) => void;
    viewOnly: boolean;
    hideTagsBackgroundWhenDisabled?: boolean;
}>('tnk-notification-settings', angular.module('tonkean.app'));

export default TnkNotificationSettings;
