<div class="board-settings-page org-content-padded" style="padding: 32px 0 0 30px">
    <h2 class="common-title-md">Board Settings</h2>

    <hr class="margin-normal-v" />

    <div class="margin-normal-v">
        <span class="common-size inline-block margin-right">Name:</span>

        <div class="common-size inline-block" ng-hide="editProjectName">
            <strong>{{ projectName }}</strong>
        </div>
        <form class="flex" ng-submit="submitName(projectName)" ng-show="editProjectName" novalidate>
            <div class="form-group flex-grow">
                <label class="sr-only" for="projectName">Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="projectName"
                    placeholder="Project Name"
                    ng-disabled="savingName"
                    ng-model="projectName"
                    required
                />
            </div>
            <div class="margin-left">
                <button type="submit" class="btn btn-primary" ng-disabled="savingName">Save</button>
            </div>
            <div class="margin-left">
                <button type="button" class="btn btn-default" ng-disabled="savingName" ng-click="cancelEdit()">
                    Cancel
                </button>
            </div>
        </form>
        <i
            class="pointer padding-normal-sm-h fa fa-pencil margin-left common-size"
            ng-click="editProjectName = true"
            ng-show="pm.isOwner"
            ng-hide="editProjectName"
         />
    </div>

    <hr class="margin-normal-v" />

    <div class="margin-bottom">
        <span class="common-size inline-block margin-right">URL ID:</span>

        <div class="common-size inline-block" ng-hide="data.editProjectUrlSlug">
            <strong>{{ data.urlSlug }}</strong>
        </div>
        <form class="flex" ng-submit="submitUrlSlug()" ng-show="data.editProjectUrlSlug" novalidate>
            <div class="flex-grow">
                <label class="sr-only" for="projectName">URL ID</label>
                <input
                        type="text"
                        class="form-control"
                        id="urlSlug"
                        placeholder="URL ID"
                        ng-disabled="data.savingUrlSlug"
                        ng-model="data.urlSlug"
                        required
                />
                <div ng-if="data.projectUrlSlugValidationError" class="common-error">{{data.projectUrlSlugValidationError}}</div>
            </div>
            <div class="margin-left">
                <button type="submit" class="btn btn-primary" ng-disabled="data.savingUrlSlug">Save</button>
            </div>
            <div class="margin-left">
                <button type="button" class="btn btn-default" ng-disabled="data.savingUrlSlug" ng-click="cancelUrlSlugEdit()">
                    Cancel
                </button>
            </div>
        </form>
        <i
                class="pointer padding-normal-sm-h fa fa-pencil margin-left common-size"
                ng-click="data.editProjectUrlSlug = true"
                ng-show="pm.isOwner"
                ng-hide="data.editProjectUrlSlug"
        />
    </div>
</div>
