import React from 'react';
import styled from 'styled-components';

import useEnterpriseComponentContext from '../../hooks/useEnterpriseComponentContext';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { ModalBody, ModalFooterActions, ModalHeader, ModalSize, Paragraph, withModal } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { EnterpriseComponentVariable, SubEntityInfo } from '@tonkean/tonkean-entities';

const List = styled.div`
    padding: 16px 0;
`;

const BoldText = styled(Paragraph)`
    font-weight: 500;
`;

interface Props {
    enterpriseComponentVariable: EnterpriseComponentVariable;
    dependentSubEntities?: SubEntityInfo[];
    onDelete: () => void;
}

const EnterpriseComponentVariableDeleteModal: React.FC<Props> = ({
    enterpriseComponentVariable,
    dependentSubEntities,
    onDelete: onDeleteProp,
}) => {
    const { id: projectId } = useProject();
    const { enterpriseComponent } = useEnterpriseComponentContext();

    const [{ error, loading }, deleteEnterpriseComponentVariable] = useLazyTonkeanService(
        'deleteEnterpriseComponentVariable',
    );

    const emitToast = useToastMessage();

    const hasDependents = (dependentSubEntities || []).length > 0;

    const onDelete = async () => {
        await deleteEnterpriseComponentVariable(projectId, enterpriseComponent.id, enterpriseComponentVariable.id);
        emitToast(`${enterpriseComponentVariable.displayName} deleted successfully`, 'success');
        onDeleteProp();
    };

    return (
        <>
            <ModalHeader>Delete {enterpriseComponentVariable.displayName} Parameter</ModalHeader>

            <ModalBody>
                {!hasDependents && (
                    <>
                        Are you sure you want to delete{' '}
                        <BoldText $inline>{enterpriseComponentVariable.displayName}</BoldText>?
                    </>
                )}

                {hasDependents && (
                    <>
                        <BoldText $inline>{enterpriseComponentVariable.displayName}</BoldText> can't be deleted as it is
                        being used by the following components:
                        <List>
                            {dependentSubEntities?.map(({ id, displayName }) => (
                                <BoldText key={id}>{displayName}</BoldText>
                            ))}
                        </List>
                        Please remove <BoldText $inline>{enterpriseComponentVariable.displayName}</BoldText> from the
                        above components before deleting it.
                    </>
                )}
            </ModalBody>

            <ModalFooterActions
                loading={loading}
                error={error}
                onSave={hasDependents ? onDeleteProp : onDelete}
                saveLabel={hasDependents ? 'OK' : 'Delete'}
                isDelete={!hasDependents}
                showCancel={!hasDependents}
            />
        </>
    );
};

export default withModal(EnterpriseComponentVariableDeleteModal, { size: ModalSize.SMEDIUM, fixedWidth: true });
