import type WsdlWithOperations from './WsdlTypes';
import { WsdlOption } from './WsdlTypes';

import type { TonkeanService } from '@tonkean/shared-services';

/**
 * Handles which tonkean service we should use in this cases:
 * 1. getting operations.
 * 2. import operations.
 * @param tonkeanService
 */
const fetchAndImportRequestHelper = (tonkeanService: TonkeanService) => {
    const requestOperations = (prinId: string, wsdlWithOperations: WsdlWithOperations) => {
        switch (wsdlWithOperations.type) {
            case WsdlOption.FILE:
                return tonkeanService.getOperationNamesFromWsdlFile(prinId, wsdlWithOperations.wsdl);
            case WsdlOption.URL:
                return tonkeanService.getOperationNamesFromWsdlUrl(prinId, wsdlWithOperations.wsdl);
            default:
                throw new Error('url or path should be given');
        }
    };

    const importOperations = (prinId: string, wsdlWithOperations: WsdlWithOperations) => {
        switch (wsdlWithOperations.type) {
            case WsdlOption.URL:
                return tonkeanService.importProjectIntegrationCustomActionsFromWsdlUrl(
                    prinId,
                    wsdlWithOperations.wsdl,
                    wsdlWithOperations.operations,
                );
            case WsdlOption.FILE:
                return tonkeanService.importProjectIntegrationCustomActionsFromWsdlFile(
                    prinId,
                    wsdlWithOperations.wsdl,
                    wsdlWithOperations.operations,
                );
            default:
                throw new Error('url or path should be given');
        }
    };
    return { importOperations, requestOperations };
};

export default fetchAndImportRequestHelper;
