import { useAngularService } from 'angulareact';
import React, { useState } from 'react';
import styled from 'styled-components';

import ProjectIntegrationEntityFetcherBreadCrumbs from './ProjectIntegrationEntityFetcherBreadCrumbs';
import { ReactComponent as EditIcon } from '../../../../images/icons/edit-pencil.svg';
import { ReactComponent as MoreActionIcon } from '../../../../images/icons/more-actions.svg';
import { ReactComponent as TrashIcon } from '../../../../images/icons/trash-form.svg';
import type BaseProjectIntegrationEntityProps from '../../../components/state.product.projectIntegrationPageEntity/BaseProjectIntegrationEntityProps';
import { ProjectIntegrationEntityTabKey } from '../../../components/state.product.projectIntegrationPageEntity/ProjectIntegrationEntityTabKey';
import type { AdditionalSidePaneConfiguration } from '../../GenericContentBySidePaneLayoutModule/SidePaneBlockConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { useGetStateParams } from '@tonkean/angular-hooks';
import { EditNameModal, LoadingCircle, Menu, MenuItem, useCreateDialog, useToggle } from '@tonkean/infrastructure';
import type { ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import { IconButton } from '@tonkean/tui-buttons/Button';

const Wrapper = styled.div<{ defaultMargin: boolean }>`
    margin: ${({ defaultMargin }) => (defaultMargin ? 30 : 0)}px 0;
`;

const BreadCrumbsAndActionButton = styled.div`
    display: inline-flex;
    align-items: center;
`;

const StyledIconButton = styled(IconButton)`
    margin-left: 5px;
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    margin-left: 4px;
`;

interface Props extends AdditionalSidePaneConfiguration<BaseProjectIntegrationEntityProps> {
    defaultMargin?: boolean;
    projectIntegrationAction: ProjectIntegrationAction;
}

const ProjectIntegrationEntityFetcherHeader: React.FC<Props> = ({
    entity,
    projectIntegration,
    entitiesSummary,
    defaultMargin = true,
    tabKeyToOnExit = {},
    projectIntegrationAction,
}) => {
    const [isMenuOpen, toggleIsMenuOpen] = useToggle(false);
    const $state = useAngularService('$state');
    const toast = useToastMessage();
    const { confirm } = useCreateDialog();
    const emitToast = useToastMessage();
    const [currentTabKey] = useGetStateParams('tab');
    const [projectIntegrationActionState, setProjectIntegrationActionState] = useState(projectIntegrationAction);
    const [isEditingFetcherName, setIsEditingFetcherName] = useState(false);

    const [{ loading: loadingDeleteEntityFetcher }, deleteProjectIntegrationAction] = useLazyTonkeanService(
        'deleteProjectIntegrationAction',
    );

    const [, getProjectIntegrationFetcherDependencies] = useLazyTonkeanService(
        'getProjectIntegrationFetcherDependencies',
    );

    const [{}, updateProjectIntegrationAction] = useLazyTonkeanService('updateProjectIntegrationAction');
    const onSubmitRenameEntityFetcherName = async (name: string) => {
        const res = await updateProjectIntegrationAction(
            projectIntegrationAction.id,
            name,
            projectIntegrationAction.description,
            projectIntegrationAction.actionDefinition,
            projectIntegrationAction.parametersDefinition,
            projectIntegrationAction.responseHandlingDefinition,
        );

        setProjectIntegrationActionState(res);
    };

    const onClickDeleteEntityFetcher = async () => {
        try {
            if (entity.entityFetcherDefinition.fetcherActionId === projectIntegrationAction.id) {
                return toast(
                    'The entity fetcher you are tyring to delete is used for single collect. please change to another one and try again',
                    'danger',
                );
            }

            const dependentEntities = await getProjectIntegrationFetcherDependencies(
                projectIntegration.id,
                projectIntegrationAction.id,
            );

            // If we have dependent entities we not allow deleting
            if (dependentEntities.entitiesDependentDisplayNames.length) {
                emitToast(
                    `Could not delete entity fetcher. This entity fetcher depends on those entities : ${dependentEntities.entitiesDependentDisplayNames.join(
                        ', ',
                    )}. Remove the dependencies before deleting`,
                    'danger',
                );
            } else {
                const confirmed = await confirm(
                    'Are you sure?',
                    `You are about to delete entity fetcher "${projectIntegrationActionState.displayName}" This action cannot be undone`,
                    {
                        okLabel: 'Delete',
                        cancelLabel: 'Cancel',
                        warning: true,
                    },
                );

                if (!confirmed) {
                    return;
                }

                await deleteProjectIntegrationAction(projectIntegrationAction.id as string);

                $state.go('product.projectIntegrationPageEntity', {
                    entityId: entity.id,
                    tab: ProjectIntegrationEntityTabKey.ENTITY_FETCHER,
                    enterpriseComponentId: projectIntegration.id,
                });
            }
        } catch {
            emitToast('Failed to delete entity fetcher. Please try again.', 'danger');
        }
    };

    return (
        <Wrapper defaultMargin={defaultMargin}>
            <BreadCrumbsAndActionButton>
                <ProjectIntegrationEntityFetcherBreadCrumbs
                    entity={entity}
                    projectIntegration={projectIntegration}
                    entitiesSummary={entitiesSummary}
                    onExit={tabKeyToOnExit[currentTabKey]}
                    projectIntegrationAction={projectIntegrationActionState}
                />

                <Menu
                    show={isMenuOpen}
                    onClose={toggleIsMenuOpen}
                    placement="right-start"
                    menuItems={
                        <>
                            <MenuItem onClick={() => setIsEditingFetcherName(true)} icon={<EditIcon />}>
                                Rename Entity Fetcher
                            </MenuItem>
                            <MenuItem onClick={onClickDeleteEntityFetcher} icon={<TrashIcon />}>
                                Delete Entity Fetcher
                            </MenuItem>
                        </>
                    }
                    thin
                >
                    <StyledIconButton onClick={() => toggleIsMenuOpen()} flat>
                        <MoreActionIcon />
                    </StyledIconButton>
                </Menu>

                {loadingDeleteEntityFetcher && <StyledLoadingCircle />}
            </BreadCrumbsAndActionButton>
            {isEditingFetcherName && (
                <EditNameModal
                    title="Rename Entity Fetcher"
                    editingDisabled={false}
                    editingDisabledTooltip=""
                    inputPlaceholder="Enter a name for your entity fetcher"
                    maxLength={255}
                    name={projectIntegrationActionState.displayName}
                    onNameChange={async (name) => {
                        await onSubmitRenameEntityFetcherName(name);
                        setIsEditingFetcherName(false);
                    }}
                    onCloseModal={() => setIsEditingFetcherName(false)}
                    hideOpenButton
                    isModalOpen
                />
            )}
        </Wrapper>
    );
};

export default ProjectIntegrationEntityFetcherHeader;
