export default angular.module('tonkean.app').filter('arrayNotEmptyFilter', function () {
    return function (input, arrayField, negate) {
        if (input && arrayField) {
            if (negate) {
                // Return items with empty arrays
                return input.filter((item) => !item[arrayField] || !item[arrayField].length);
            } else {
                // Return items with non empty arrays
                return input.filter((item) => item[arrayField] && item[arrayField].length);
            }
        }

        return input;
    };
});
