import { useCallback } from 'react';

const usePollingWithConditions = () => {
    const handleConditionalPolling = useCallback(
        (
            fetchInterval: number,
            condition: (data: any) => boolean,
            fetchData: () => Promise<any>,
            setConditionsMatched: (conditionsMatched: boolean, data?: any) => void,
        ) => {
            const fetchDataWithCondition = async () => {
                try {
                    const newData = await fetchData();
                    if (condition(newData)) {
                        setConditionsMatched(true, newData);
                        clearInterval(pollInterval);
                    }
                } catch (error) {
                    console.error('Error fetching data during polling:', error);
                }
            };

            const pollInterval = setInterval(fetchDataWithCondition, fetchInterval);

            return () => clearInterval(pollInterval);
        },
        [],
    );

    return handleConditionalPolling;
};

export default usePollingWithConditions;
