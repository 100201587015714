import React, { useState } from 'react';
import styled from 'styled-components';

import ProjectIntegrationFrontDoorTable from './ProjectIntegrationFrontDoorTable';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { H4, IconSvg, LoadingCircle, SimpleErrorStateMessage, Toggle } from '@tonkean/infrastructure';
import { WarningTriangleIcon } from '@tonkean/svg';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const SettingsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
`;

const SettingsLabel = styled.div`
    flex-shrink: 0;
`;

const SimpleErrorStateMessageStyled = styled(SimpleErrorStateMessage)`
    flex-shrink: 0;
`;

const AccessSummaryLabel = styled(H4)`
    display: flex;
    align-items: start;
    gap: 5px;
`;

const WarningStyledIconSvg = styled(IconSvg)`
    flex-shrink: 0;
`;

const getFrontDoorAccessSummary = (
    projectIntegrationDisplayName: string,
    smartSearchEnabled: boolean | undefined,
    smartSearchSpecificEntitiesOnly: boolean | undefined,
) => {
    if (smartSearchEnabled && smartSearchSpecificEntitiesOnly) {
        return {
            label: `Only ${projectIntegrationDisplayName} entities that are defined below will be accessible via the Front Door`,
            warning: true,
        };
    }

    if (smartSearchEnabled) {
        return {
            label: `All ${projectIntegrationDisplayName} entities will be accessible via the Front Door`,
        };
    }

    if (smartSearchSpecificEntitiesOnly) {
        return {
            label: `When access via AI Front Door is Enabled, only ${projectIntegrationDisplayName} entities that are defined below will be accessible via the Front Door`,
            warning: true,
        };
    }

    return {
        label: `When access via AI Front Door is Enabled, all ${projectIntegrationDisplayName} entities will be accessible`,
        warning: true,
    };
};

interface Props {
    projectIntegration: ProjectIntegration;
}

const ProjectIntegrationFrontDoorSettings: React.FC<Props> = ({ projectIntegration }) => {
    const [smartSearchEnabled, setSmartSearchEnabled] = useState(projectIntegration.smartSearchEnabled);
    const [
        { loading: loadingSmartSearchEnabled, error: errorInSmartSearchEnabled },
        updateProjectIntegrationSmartSearchEnabled,
    ] = useLazyTonkeanService('updateProjectIntegrationSmartSearchEnabled');

    const [smartSearchSpecificEntitiesOnly, setSmartSearchSpecificEntitiesOnly] = useState(
        projectIntegration.smartSearchSpecificEntitiesOnly,
    );
    const [
        { loading: loadingUpdateSmartSearchSpecificEntitiesOnly, error: errorUpdateSmartSearchSpecificEntitiesOnly },
        updateProjectIntegrationSmartSearchSpecificEntitiesOnly,
    ] = useLazyTonkeanService('updateProjectIntegrationSmartSearchSpecificEntitiesOnly');

    const accessSummary = getFrontDoorAccessSummary(
        projectIntegration.displayName,
        smartSearchEnabled,
        smartSearchSpecificEntitiesOnly,
    );

    return (
        <Container>
            <SettingsRow>
                <SettingsLabel>
                    <H4>Enable access via AI Front Door</H4>
                </SettingsLabel>
                <Toggle
                    size={InputSize.SMALL}
                    onChange={(event) => {
                        const newValue = event.target.checked;
                        setSmartSearchEnabled(newValue);
                        projectIntegration.smartSearchEnabled = newValue;
                        updateProjectIntegrationSmartSearchEnabled(
                            projectIntegration.projectId,
                            projectIntegration.id,
                            newValue,
                        ).catch(() => {
                            projectIntegration.smartSearchEnabled = !newValue;
                            setSmartSearchEnabled(!newValue);
                        });
                    }}
                    checked={smartSearchEnabled}
                    disabled={loadingSmartSearchEnabled}
                    labelBefore={loadingSmartSearchEnabled ? <LoadingCircle /> : undefined}
                />
                {errorInSmartSearchEnabled && (
                    <SimpleErrorStateMessageStyled error={errorInSmartSearchEnabled} showSmallError />
                )}
            </SettingsRow>
            <SettingsRow>
                <SettingsLabel>
                    <H4>Limit access to only specific entities</H4>
                </SettingsLabel>
                <Toggle
                    size={InputSize.SMALL}
                    onChange={(event) => {
                        const newValue = event.target.checked;
                        setSmartSearchSpecificEntitiesOnly(newValue);
                        projectIntegration.smartSearchSpecificEntitiesOnly = newValue;
                        updateProjectIntegrationSmartSearchSpecificEntitiesOnly(
                            projectIntegration.projectId,
                            projectIntegration.id,
                            newValue,
                        ).catch(() => {
                            projectIntegration.smartSearchSpecificEntitiesOnly = !newValue;
                            setSmartSearchSpecificEntitiesOnly(!newValue);
                        });
                    }}
                    checked={smartSearchSpecificEntitiesOnly}
                    disabled={loadingUpdateSmartSearchSpecificEntitiesOnly}
                    labelBefore={loadingUpdateSmartSearchSpecificEntitiesOnly ? <LoadingCircle /> : undefined}
                />
                {errorUpdateSmartSearchSpecificEntitiesOnly && (
                    <SimpleErrorStateMessageStyled error={errorUpdateSmartSearchSpecificEntitiesOnly} showSmallError />
                )}
            </SettingsRow>

            {accessSummary && (
                <AccessSummaryLabel $color={Theme.colors.gray_600} $bold>
                    {accessSummary.warning && <WarningStyledIconSvg as={WarningTriangleIcon} size={18} />}
                    {accessSummary.label}
                </AccessSummaryLabel>
            )}
            {smartSearchSpecificEntitiesOnly && (
                <ProjectIntegrationFrontDoorTable projectIntegration={projectIntegration} />
            )}
        </Container>
    );
};

export default ProjectIntegrationFrontDoorSettings;
