import FunctionOperatorBase from './FunctionOperatorBase';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';

export default class FilterArrayOfObjectsWithEmptyField extends FunctionOperatorBase {
    override readonly key = OperatorKey.FILTER_ARRAY_OF_OBJECTS_WITH_EMPTY_FIELD;
    override readonly sign = 'FilterArrayOfObjectsWithEmptyField';
    override readonly displayName = 'Filter Array Of Objects With Empty Field';
    override readonly description =
        'filter objects in the given array if one of the given fields is empty, if you have more than 1 field add "," between them, its possible to filter using nested fields " key.innerKey"';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Array of objects',
            dataType: [FieldType.List, FieldType.String],
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Fields',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
    ];
}
