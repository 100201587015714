import { useAngularService } from 'angulareact';
import { useEffect } from 'react';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import type { ErrorResponseType } from '@tonkean/utils';

/**
 * React hook that returns the project integration by the id. It uses `projectIntegrationCache.getProjectIntegrationById`,
 * so it will first look for the project integration in the cache, and if not existing, it will send an http request.
 *
 * @param projectIntegrationId - the project integration id. If undefined, it will return undefined.
 */
function useGetProjectIntegrationById(projectIntegrationId: string | undefined): {
    loading: boolean;
    error?: ErrorResponseType;
    projectIntegration?: ProjectIntegration;
} {
    const projectIntegrationCache = useAngularService('projectIntegrationCache');

    const [{ data: projectIntegration, loading, error }, getProjectIntegrationById] = useLazyAsyncMethod(
        projectIntegrationCache,
        'getProjectIntegrationById',
    );

    useEffect(() => {
        if (projectIntegrationId) {
            getProjectIntegrationById(projectIntegrationId);
        }
    }, [getProjectIntegrationById, projectIntegrationId]);

    return { projectIntegration, loading, error };
}

export default useGetProjectIntegrationById;
