import { useAngularService } from 'angulareact';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import NavigationMenuSection from './NavigationMenuSection';
import { homepageNavigablePage } from '../../config/internalNavigationPagesConfiguration';
import type BaseNavigablePage from '../../entities/BaseNavigablePage';
import { NavigablePageType } from '../../entities/BaseNavigablePage';
import { isExternalNavigablePage } from '../../entities/ExternalNavigablePage';
import type InternalNavigablePage from '../../entities/InternalNavigablePage';
import { isInternalNavigablePage } from '../../entities/InternalNavigablePage';
import NavigablePageKey from '../../entities/NavigablePageKey';
import navigationPagesConfiguration from '../../utils/navigationPagesConfiguration';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { TonkeanIconDisplay } from '@tonkean/icon-picker';
import { Lightning01Icon } from '@tonkean/svg';
import type { SolutionSiteHomepageSummary, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { Theme, FontSize, type Color } from '@tonkean/tui-theme';
import { EMPTY_ARRAY } from '@tonkean/utils';

const MenuLabel = styled.div`
    color: ${Theme.colors.gray_500};
    font-size: ${FontSize.SMALL_12};
    line-height: 12px;
    margin: 14px 0 8px 14px;
`;

interface Props {
    currentPage: BaseNavigablePage['id'] | undefined;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    projectUrlSlug: string;
    solutionSites:
        | Pick<SolutionSiteHomepageSummary, 'id' | 'displayName' | 'urlSlug' | 'pages' | 'themeConfiguration'>[]
        | undefined;
    themeColor: Color;
    isSystemUser: boolean;
}

const NavigationMenuContent: React.FC<Props> = ({
    currentPage,
    projectId,
    projectUrlSlug,
    solutionSites,
    themeColor,
    isSystemUser,
}) => {
    const $rootScope = useAngularService('$rootScope');

    const workflowFolderManager = useAngularService('workflowFolderManager');
    const { data: workflowFolders } = useAsyncMethod(workflowFolderManager, 'getWorkflowFolders', projectId);

    const pagesSections = useMemo(() => {
        const homepagePage = homepageNavigablePage;
        const internalPages = navigationPagesConfiguration
            .filter(
                (page) =>
                    isInternalNavigablePage(page) &&
                    page.isShownInNavigationMenu($rootScope.features.currentProject) &&
                    page.id !== NavigablePageKey.HOMEPAGE &&
                    (isSystemUser || page.accessibleToAllUsers),
            )
            .map((page) => {
                if (page.id === NavigablePageKey.OPS_MODULES && page.type === NavigablePageType.INTERNAL) {
                    const innerPages: InternalNavigablePage[] | undefined = workflowFolders?.map((workflowFolder) => ({
                        id: workflowFolder.id,
                        type: NavigablePageType.INTERNAL,
                        name: workflowFolder.displayName,
                        icon: Lightning01Icon,
                        menuIcon: Lightning01Icon,
                        isShownInNavigationMenu: () => true,
                        openInNewPage: false,
                        accessibleToAllUsers: false,
                        state: 'product.solution',
                        params: {
                            solutionId: workflowFolder.id,
                        },
                    }));

                    if (innerPages) {
                        innerPages.push({ ...page, name: 'All solutions', menuIcon: undefined });
                    }
                    return {
                        ...page,
                        innerPages,
                    };
                }

                return page;
            });
        const externalPages = navigationPagesConfiguration.filter(
            (page) =>
                isExternalNavigablePage(page) &&
                page.isShownInNavigationMenu($rootScope.features.currentProject) &&
                (isSystemUser || page.accessibleToAllUsers),
        );

        const solutionSiteSection = {
            name: 'solution sites',
            pages:
                solutionSites?.map((solutionSite) => {
                    const icon = () => (
                        <TonkeanIconDisplay
                            icon={solutionSite.themeConfiguration.icon}
                            integrationTypesForIcon={undefined}
                            showMatchedEntityIcon={undefined}
                            customTriggerTypes={EMPTY_ARRAY}
                            size={24}
                            dontShowEmptyIcon
                        />
                    );

                    const page: InternalNavigablePage = {
                        id: solutionSite.id,
                        type: NavigablePageType.INTERNAL,
                        name: solutionSite.displayName,
                        // eslint-disable-next-line react/no-unstable-nested-components
                        icon,
                        menuIcon: icon,
                        isShownInNavigationMenu: () => true,
                        openInNewPage: false,
                        accessibleToAllUsers: true,
                        state: 'solution-site',
                        params: {
                            projectUrlSlug,
                            solutionSiteUrlSlug: solutionSite.urlSlug,
                        },
                        innerPages: solutionSite.pages?.map((solutionSitePage) => {
                            const innerPage: InternalNavigablePage = {
                                id: solutionSitePage.id,
                                type: NavigablePageType.INTERNAL,
                                name: solutionSitePage.displayName,
                                icon: undefined,
                                menuIcon: undefined,
                                isShownInNavigationMenu: () => true,
                                openInNewPage: false,
                                accessibleToAllUsers: true,
                                state: 'solution-site',
                                params: {
                                    projectUrlSlug,
                                    solutionSiteUrlSlug: solutionSite.urlSlug,
                                    solutionSitePageUrlSlug: solutionSitePage.urlSlug,
                                },
                            };
                            return innerPage;
                        }),
                    };
                    return page;
                }) || EMPTY_ARRAY,
        };

        return [
            { name: 'homepage', pages: [homepagePage] },
            solutionSiteSection,
            { name: 'internal', pages: internalPages },
            { name: 'external', pages: externalPages },
        ].filter((section) => section.pages.length);
    }, [solutionSites, $rootScope.features.currentProject, isSystemUser, workflowFolders, projectUrlSlug]);

    return (
        <>
            {pagesSections.map(
                (pagesSection, index) =>
                    pagesSection.pages.length > 0 && (
                        <NavigationMenuSection
                            key={pagesSection.name}
                            pages={pagesSection.pages}
                            isLastSection={index < pagesSections.length - 1}
                            currentPageId={currentPage}
                            themeColor={themeColor}
                        />
                    ),
            )}
        </>
    );
};

export default NavigationMenuContent;
