import React from 'react';
import styled from 'styled-components';

import { TextEllipsis } from '@tonkean/infrastructure';
import { LinkIndicator } from '@tonkean/svg';
import { Theme, FontSize } from '@tonkean/tui-theme';

interface UpdateFieldLinkTextProps {
    value?: string;
    isInTableView: boolean;
    urlLabel?: string;
}

const Link = styled.a<{ $isInTableView: boolean }>`
    color: ${Theme.colors.primaryHighlight};

    &:hover {
        color: ${Theme.colors.primaryHighlight};
    }

    text-decoration: underline;
    font-size: ${({ $isInTableView }) => ($isInTableView ? FontSize.LARGE_16 : FontSize.XXXLARGE_22)};
    font-weight: 500;
    display: flex;
    align-items: center;
`;

const LinkText = styled(TextEllipsis)`
    display: block;
    text-overflow: ellipsis;
`;

const LinkIcon = styled(LinkIndicator)`
    flex-shrink: 1;
    margin-left: 4px;
`;

const UpdateFieldLinkText: React.FC<UpdateFieldLinkTextProps> = ({ value, isInTableView, urlLabel }) => {
    return (
        <Link href={value} target="_blank" $isInTableView={isInTableView}>
            <LinkText numberOfLines={1}>{urlLabel ? urlLabel : value}</LinkText>
            <LinkIcon />
        </Link>
    );
};

export default UpdateFieldLinkText;
