import React, { useCallback } from 'react';

import FormulaOperandBinary from './FormulaOperatorBinary';
import FormulaOperandFunction from './FormulaOperatorFunction';
import type SharedFormulaNodeProps from '../../entities/SharedFormulaNodeProps';

import { FormulaTreeTreeNode } from '@tonkean/tonkean-entities';
import { OperatorFamily } from '@tonkean/tonkean-entities';
import type { formulaTreeNode } from '@tonkean/tonkean-entities';

interface Props extends SharedFormulaNodeProps {
    /** The node to display */
    node: FormulaTreeTreeNode;
}

const FormulaNodeTree: React.FC<Props> = ({
    depth,
    canDelete,
    node,
    disabled,
    onNodeChanged,
    onNodeDeleted,
    additionalTabs = [],
    customTrigger,
    projectIntegration,
}) => {
    const onChange = useCallback(
        (newOperand: formulaTreeNode, oldOperand: formulaTreeNode) => {
            const newOperandsList = node.operands.map((operand) =>
                operand !== oldOperand ? operand.clone(operand.field) : newOperand,
            );
            onNodeChanged(new FormulaTreeTreeNode(node.operator, newOperandsList, node.field, node.id), node);
        },
        [node, onNodeChanged],
    );

    switch (node.operator.family) {
        case OperatorFamily.FUNCTION:
            return (
                <FormulaOperandFunction
                    depth={depth}
                    additionalTabs={additionalTabs}
                    customTrigger={customTrigger}
                    projectIntegration={projectIntegration}
                    canDelete={canDelete}
                    disabled={disabled}
                    node={node}
                    onOperandChanged={onChange}
                    onNodeChanged={onNodeChanged}
                    onNodeDeleted={onNodeDeleted}
                />
            );

        case OperatorFamily.ARITHMETIC:
        case OperatorFamily.LOGICAL:
            return (
                <FormulaOperandBinary
                    depth={depth}
                    additionalTabs={additionalTabs}
                    customTrigger={customTrigger}
                    canDelete={canDelete}
                    disabled={disabled}
                    node={node}
                    onOperandChanged={onChange}
                    onNodeChanged={onNodeChanged}
                    onNodeDeleted={onNodeDeleted}
                />
            );
    }
};

export default FormulaNodeTree;
