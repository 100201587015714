import { TONKEAN_ENTITY_TYPE } from '@tonkean/constants';

function EntityPersonHelper(utils) {
    /**
     * Enrich person entity by type.
     * @param entity an Object with Id.
     * @return {*} The enriched entity.
     */
    this.enrichEntity = function (entity) {
        if (entity && entity.id && angular.isString(entity.id)) {
            switch (0) {
                case entity.id.indexOf('PRSN'): {
                    entity.tonkeanEntityType = TONKEAN_ENTITY_TYPE.PERSON;
                    return this.enrichPerson(entity);

                    break;
                }
                // No default
            }
        }
        return entity;
    };

    /**
     * Enrich person/user objects (that start with 'PRSN'.
     * @param person - the person/user object to enrich.
     */
    this.enrichPerson = function (person) {
        if (person) {
            if (!person.messagePreferences) {
                person.messagePreferences = [];
            }

            if (person.name) {
                person.initials = utils.getInitials(person.name);
            }

            person.isGatherUpdateDisabled =
                person.messagePreferences && person.messagePreferences.includes('NO_GATHER_UPDATE');
        }

        return person;
    };
}

angular.module('tonkean.shared').service('entityPersonHelper', EntityPersonHelper);
