enum EmbedUrlInputType {
    FROM_FIELD = 'FROM_FIELD',
    FROM_ACTION = 'FROM_ACTION',
}

export const validationToEmbedUrlInputTypeDisplayName: Record<EmbedUrlInputType, string> = {
    [EmbedUrlInputType.FROM_FIELD]: 'From Field',
    [EmbedUrlInputType.FROM_ACTION]: 'From Data Source Action',
};

export const EmbedUrlInputTypes = Object.values(EmbedUrlInputType).map((embedUrlInputType) => ({
    value: embedUrlInputType.valueOf(),
    label: validationToEmbedUrlInputTypeDisplayName[embedUrlInputType],
}));

export default EmbedUrlInputType;
