import React from 'react';
import styled from 'styled-components';

import { EmptyList } from '@tonkean/svg';
import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import StateLink from '@tonkean/tui-basic/StateLink';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';

const NoActionDiv = styled.div`
    height: 220px;
    text-align: center;
    display: grid;
    align-items: center;
`;

const NoActionTitle = styled.div`
    font-weight: 500;
    font-size: ${FontSize.LARGE_16};
    color: ${Theme.colors.gray_700};
    margin-bottom: 8px;
`;

const NoActionIcon = styled.div`
    margin-bottom: 20px;
`;

const NoActionModalSubHeader = styled.span`
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
`;

interface Props {
    groupId: TonkeanId<TonkeanType.GROUP> | undefined;
}

const ModuleActionsSelectorEmptyState: React.FC<Props> = ({ groupId }) => {
    return (
        <NoActionDiv>
            <div>
                <NoActionIcon>
                    <EmptyList />
                </NoActionIcon>
                <NoActionTitle>No Available Actions</NoActionTitle>
                <NoActionModalSubHeader>
                    No actions are available for this {groupId ? 'module' : 'solution'}.
                    {groupId && (
                        <>
                            <br />
                            Create a new update form in the{' '}
                            <StateLink
                                state="product.workerEditor"
                                params={{
                                    g: groupId,
                                    t: 'forms',
                                }}
                            >
                                Solution Studio
                            </StateLink>
                        </>
                    )}
                </NoActionModalSubHeader>
            </div>
        </NoActionDiv>
    );
};

export default ModuleActionsSelectorEmptyState;
