import React from 'react';

import { ReactComponent as LockIcon } from '../../../../images/icons/lock.svg';

export default function getAsanaProjectAutoCompleteTemplate(label: string, isPublic: boolean) {
    const icon = (
        <>
            {!isPublic ? (
                <span>
                    <span className="tnk-icon">
                        <LockIcon />
                    </span>
                </span>
            ) : (
                ''
            )}
        </>
    );
    return (
        <span>
            {icon}
            {label}
        </span>
    );
}
