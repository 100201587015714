<div class="send-notification-logic-configuration">
    <!-- Send notification is the same as person inquiry but without buttons. -->
    <tnk-person-inquiry-logic-configuration
        group-id="{{ data.groupId }}"
        workflow-version-id="{{ data.workflowVersionId }}"
        configured-logic="data.configuredLogic"
        invalid-logics="data.invalidLogics"
        on-actions-changed="onActionsChanged(definition, shouldSaveLogic)"
        preview-evaluation-source="data.previewEvaluationSource"
    ></tnk-person-inquiry-logic-configuration>
</div>
