import React, { useCallback, useEffect, useState } from 'react';

import SmartSearchLookupEntityBoxView from './SmartSearchLookupEntityBoxView';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import { CubeIcon } from '@tonkean/svg';
import { SmartSearchLookupRelevanceType } from '@tonkean/tonkean-entities';
import type { SmartSearchLookupEntityRelevance } from '@tonkean/tonkean-entities';
import type { SmartSearchEntityAnalysisResponse } from '@tonkean/tonkean-entities';

interface Props {
    entityRelevance: SmartSearchLookupEntityRelevance;
    onAnalysisRequested: (result: Promise<SmartSearchEntityAnalysisResponse>) => void;
    analyseOnLoad: boolean;

    conversationId: string;
}

const SmartSearchLookupEntityBox: React.FC<Props> = ({
    entityRelevance,
    onAnalysisRequested,
    conversationId,
    analyseOnLoad,
}) => {
    const { id: projectId } = useProject();

    const [isAutoInitializedRequired, setIsAutoInitializedRequired] = useState(analyseOnLoad);
    const [
        { error: externalActivityError, loading: externalActivityLoading, called: calledExternalActivity },
        externalActivitySmartSearchEntityAnalysis,
    ] = useLazyTonkeanService('externalActivitySmartSearchEntityAnalysis');

    const [
        { error: initiativeError, loading: initiativeLoading, called: calledInitiative },
        initiativeSmartSearchEntityAnalysis,
    ] = useLazyTonkeanService('initiativeSmartSearchEntityAnalysis');

    const analyze = useCallback(() => {
        switch (entityRelevance.type) {
            case SmartSearchLookupRelevanceType.EXTERNAL_ACTIVITY:
                return onAnalysisRequested(
                    externalActivitySmartSearchEntityAnalysis(
                        projectId,
                        entityRelevance.projectIntegrationId,
                        entityRelevance.entityId,
                        conversationId,
                    ),
                );
            case SmartSearchLookupRelevanceType.INITIATIVE:
                return onAnalysisRequested(
                    initiativeSmartSearchEntityAnalysis(projectId, entityRelevance.entityId, conversationId),
                );
        }
    }, [
        conversationId,
        entityRelevance,
        externalActivitySmartSearchEntityAnalysis,
        initiativeSmartSearchEntityAnalysis,
        onAnalysisRequested,
        projectId,
    ]);

    // On Component load validating if the auto Initialize component is true
    useEffect(() => {
        if (isAutoInitializedRequired) {
            setIsAutoInitializedRequired((_isAutoInitializedRequired) => {
                // second validation with the real value (this is the safe check)
                if (_isAutoInitializedRequired) {
                    analyze();
                }
                return false;
            });
        }
    }, [analyze, isAutoInitializedRequired]);

    switch (entityRelevance.type) {
        case SmartSearchLookupRelevanceType.EXTERNAL_ACTIVITY:
            return (
                <SmartSearchLookupEntityBoxView
                    externalLink={entityRelevance.externalUrl}
                    icon={
                        <ProjectIntegrationIcon
                            width={18}
                            projectIntegrationId={entityRelevance.projectIntegrationId}
                            integrationType={entityRelevance.integrationType}
                        />
                    }
                    title={entityRelevance.title}
                    subTitle={entityRelevance.externalTypeDisplayName}
                    isAnalyzing={externalActivityLoading}
                    isReAnalyzeEnabled={!!externalActivityError || !calledExternalActivity}
                    onClickAnalyze={analyze}
                />
            );
        case SmartSearchLookupRelevanceType.INITIATIVE:
            return (
                <SmartSearchLookupEntityBoxView
                    externalLink={`${window.location.origin}/${projectId}/item/${entityRelevance.groupId}/${entityRelevance.entityId}`}
                    icon={<CubeIcon />}
                    title={entityRelevance.title}
                    subTitle={entityRelevance.groupName}
                    isAnalyzing={initiativeLoading}
                    isReAnalyzeEnabled={!!initiativeError || !calledInitiative}
                    onClickAnalyze={analyze}
                />
            );
        default:
            return <>Unknown Entity</>;
    }
};

export default SmartSearchLookupEntityBox;
