import React from 'react';
import styled from 'styled-components';

import { Placeholder } from '@tonkean/infrastructure';
import { TonkeanAvatar } from '@tonkean/infrastructure';
import type { Person } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const Avatar = styled.div`
    height: 20px;
    width: 20px;
    margin-right: 10px;
`;

const UnknownPerson = styled.div`
    height: 20px;
    width: 20px;
    background-color: ${Theme.colors.gray_400};
    border-radius: 50%;

    &:after {
        content: '?';
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        line-height: 20px;
    }
`;

interface Props {
    person: Person | undefined;
    loading: boolean;
}

const UserActionAvatar: React.FC<Props> = ({ loading, person }) => {
    return (
        <Avatar>
            {loading && <Placeholder $width="20px" $height="20px" $circle />}

            {!loading && person && (
                <div className="avatar-main avatar-sm margin-right flex-no-shrink">
                    <TonkeanAvatar owner={person} />
                </div>
            )}

            {!loading && !person && <UnknownPerson />}
        </Avatar>
    );
};

export default UserActionAvatar;
