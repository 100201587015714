import FunctionOperatorBase from './FunctionOperatorBase';
import { timeZoneField } from '../commonFields';
import { dateFormatsPreset } from '../commonPresets';

import type { FormulaFieldArray, FormulaSingleField } from '@tonkean/tonkean-entities';
import { OperatorKey } from '@tonkean/tonkean-entities';
import { FieldType } from '@tonkean/tonkean-entities';
import { FieldDefinitionType } from '@tonkean/tonkean-entities';
import { FormulaPopoverTab } from '@tonkean/tonkean-entities';

/**
 * @deprecated
 * Should use DateFormat
 */
export default class ToDateFunction extends FunctionOperatorBase {
    override readonly key = OperatorKey.TO_DATE;
    override readonly sign = 'ToDate';
    override readonly displayName = 'To Date';
    override readonly description = 'Will convert date to a specific format.';
    override readonly dataType = FieldType.String;
    override readonly fields: (FormulaSingleField | FormulaFieldArray)[] = [
        {
            displayName: 'Input',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
        },
        {
            displayName: 'Date Format',
            dataType: FieldType.String,
            fieldDefinitionType: FieldDefinitionType.SINGLE,
            defaultTab: FormulaPopoverTab.PRESET,
            presetValues: dateFormatsPreset,
        },
        timeZoneField(),
    ];

    override readonly deprecated = true;
}
