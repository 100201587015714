<div class="test-workflow padding-top-xs">
    <tnk-radio-button
        radio-id="test-workflow-method-item"
        radio-name="test-workflow-method"
        radio-label="Using {{ data.itemLabel }}"
        radio-value="{{ data.testWorkflowMethods.ITEM }}"
        model="data.selectedTestMethod"
        is-required="true"
        on-click="data.selectedTestMethod = data.testWorkflowMethods.ITEM"
        classes="margin-top"
    ></tnk-radio-button>
    <div ng-if="data.selectedTestMethod === data.testWorkflowMethods.ITEM" class="margin-left-lg">
        <!-- Explanation -->
        <div class="test-explanation">
            Choose a test {{ data.itemLabel }} to send through the build version of this Tonkean module.

            <strong ng-if="!wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled">
                * Make sure you choose a test {{ data.itemLabel }} for testing the Tonkean module, and not a real one.
            </strong>
            <strong ng-if="wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled">
                * Test now will run your module flow immediately with a mocked scheduled item
            </strong>
        </div>

        <!-- Choose Test Item -->
        <div ng-if="!wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled">
            <!-- Choosing an item to test with -->
            <div class="item-select-label">Create test item</div>

            <!-- Choosing external item -->
            <div ng-if="scm.getSyncConfig(data.workflowVersionId)" class="margin-bottom">
                <tnk-external-entity-selector
                    project-integration-id="data.projectIntegrationId"
                    external-type="data.externalType"
                    external-type-plural-name="data.itemLabel"
                    selected-entity="data.externalItemToTestWith"
                    on-entity-selected="(onExternalItemToTestWithChanged)"
                ></tnk-external-entity-selector>
            </div>

            <!-- Choosing manual item -->
            <div ng-if="!scm.getSyncConfig(data.workflowVersionId)" class="margin-bottom">
                <input
                    type="text"
                    ng-model="data.manualItemToTestWith"
                    data-automation="test-workflow-enter-title-input"
                    class="form-control"
                    placeholder="Enter title..."
                />
            </div>
        </div>

        <div
            ng-if="
                !data.predefinedRunOnCustomTrigger && !wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled
            "
            class="custom-trigger-selector"
        >
            <!-- Hiding test specific trigger for now, as it doesn't actually work. -->
            <!--            <tnk-radio-button-->
            <!--                radio-id="should-run-on-custom-trigger-false"-->
            <!--                radio-name="should-run-on-custom-trigger"-->
            <!--                radio-label="Test entire flow"-->
            <!--                radio-value="no"-->
            <!--                model="data.shouldRunOnCustomTrigger"-->
            <!--                is-required="true"-->
            <!--                on-click="data.shouldRunOnCustomTrigger = 'no'; data.runOnCustomTrigger = null"-->
            <!--                on-click-param="(readonly)"-->
            <!--                classes="margin-top"-->
            <!--            ></tnk-radio-button>-->

            <!--            <tnk-radio-button-->
            <!--                radio-id="should-run-on-custom-trigger-true"-->
            <!--                radio-name="should-run-on-custom-trigger"-->
            <!--                radio-label="Test only a specific Trigger"-->
            <!--                radio-value="yes"-->
            <!--                model="data.shouldRunOnCustomTrigger"-->
            <!--                is-required="true"-->
            <!--                on-click="data.shouldRunOnCustomTrigger = 'yes'"-->
            <!--                on-click-param="(readonly)"-->
            <!--                classes="margin-top"-->
            <!--            ></tnk-radio-button>-->

            <!-- Logic to run on selection -->
            <ui-select
                ng-if="data.shouldRunOnCustomTrigger === 'yes'"
                theme="bootstrap"
                class="common-width-50 custom-trigger-selector-input"
                ng-model="data.runOnCustomTrigger"
            >
                <ui-select-match placeholder="Search trigger blocks...">
                    {{ data.runOnCustomTrigger.displayName }}
                </ui-select-match>
                <ui-select-choices repeat="customTrigger in data.autonomousCustomTriggers | filter:$select.search">
                    <div ng-bind-html="customTrigger.displayName | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>
    </div>

    <hr />

    <tnk-radio-button
        data-automation="tnk-test-workflow-template-using-create-form-checkbox"
        radio-id="test-workflow-method-form"
        radio-name="test-workflow-method"
        radio-label="Using Create Form"
        radio-value="{{ data.testWorkflowMethods.FORM }}"
        model="data.selectedTestMethod"
        is-required="true"
        on-click="data.selectedTestMethod = data.testWorkflowMethods.FORM"
        view-only="!data.availableForms.length"
        classes="margin-top"
        uib-tooltip="You don't have any Create Forms in this version of the Module"
        tooltip-enable="!data.availableForms.length"
        tooltip-placement="top-left"
    ></tnk-radio-button>
    <div ng-if="data.selectedTestMethod === data.testWorkflowMethods.FORM" class="margin-left-lg">
        <div class="test-explanation">Choose a form to test in the web form view</div>
        <div class="common-color-light-grey margin-bottom">
            When you test with a create form, a new tab opens at the fill form page. Once the form is submitted, a new item would be added to the module in the build environment.
        </div>
        <div class="flex-vmiddle">
            <div>Test using a create form</div>
            <ui-select
                class="flex-grow margin-left-md"
                data-automation="tnk-test-workflow-template-test-using-create-form-selection"
                ng-change="fieldSelected(column.matchedEntity, column)"
                theme="bootstrap"
                ng-model="data.selectedForm"
            >
                <ui-select-match placeholder="">{{ data.selectedForm.displayName }}</ui-select-match>
                <ui-select-choices
                    repeat="form in data.availableForms | filter:$select.search"
                    data-automation="tnk-test-workflow-template-available-form"
                >
                    {{ form.displayName }}
                </ui-select-choices>
            </ui-select>
        </div>
    </div>

    <!-- Test worker button -->
    <div class="flex-vmiddle margin-top-lg">
        <button
            class="btn"
            ng-class="{ 'btn-primary': !data.testButtonSecondary, 'btn-secondary': data.testButtonSecondary }"
            data-automation="test-workflow-test-now-button"
            ng-disabled="(data.selectedTestMethod === data.testWorkflowMethods.ITEM && !data.externalItemToTestWith && !data.manualItemToTestWith && !wvm.getCachedWorkflowVersion(data.workflowVersionId).isScheduled)
                    || (data.selectedTestMethod === data.testWorkflowMethods.FORM && !data.selectedForm)"
            ng-click="testWorker()"
        >
            {{ data.selectedTestMethod === data.testWorkflowMethods.FORM ? 'Open Test Create Form' : 'Test Now' }}
        </button>

        <!-- Loading -->
        <div class="flex-vmiddle margin-left-xs" ng-if="data.sendingTestWorker">
            <i class="margin-right-xs loading-small"></i>
            <div>Running test item, it might take a few seconds...</div>
        </div>

        <!-- Error -->
        <div
            class="margin-left-xs common-color-danger common-size-xxxs"
            ng-if="!data.sendingTestWorker && data.errorSendingTestWorker"
        >
            {{ data.errorSendingTestWorker }}
        </div>
    </div>
</div>
