import template from './tnkFormFieldSelectActionsMenu.template.html?angularjs';

/**
 * Component description goes here
 */
export default angular.module('tonkean.app').component('tnkFormFieldSelectActionsMenu', {
    bindings: {
        enableInline: '<',
        enableDelete: '<',
        toggleIsRequired: '&',
        toggleFieldInline: '&',
        toggleShowTitle: '&',
        toggleViewOnly: '&',
        toggleCollapseDescriptionByDefault: '&',
        removeFormField: '&',
        onValidationChanged: '&',
        field: '<',
        formQuestionType: '<',
        formType: '<',
        workflowVersionId: '<',
        groupId: '<',
    },
    template,
    controller: 'FormFieldSelectActionsMenuCtrl',
});
