import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { SingleWorkerRunInformationRowContext } from './SingleWorkerRunInformationRow';

import { Placeholder, PlaceholderGrid, PlaceholderGridDirection } from '@tonkean/infrastructure';
import { FontSize } from '@tonkean/tui-theme';
import { Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const theme = Theme.current.palette.history.workerRunsList;

const Title = styled.h4`
    margin: 0 0 6px;
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    color: ${theme.tableHeaderColor};
    white-space: nowrap;
`;

const Value = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-height: 16px;
    color: ${Theme.colors.gray_800};
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
`;

interface Props {
    title: React.ReactNode;
    /** If undefined, it will show loading placeholder */
    value: React.ReactNode | undefined;
    placeholderCount?: number;
}

const SingleWorkerRunInformationItem: React.FC<Props> = ({ title, value, placeholderCount = 1 }) => {
    const addItem = useContext(SingleWorkerRunInformationRowContext);

    // Notify SingleWorkerRunInformationRow to add 'auto' to the 'grid-template-columns'.
    useEffect(() => {
        const removeFunc = addItem();
        return () => removeFunc();
    }, [addItem]);

    return (
        <div>
            <Title>{title}</Title>
            <Value>
                {value ? (
                    value
                ) : (
                    <PlaceholderGrid direction={PlaceholderGridDirection.ROWS} gap="2px">
                        {range(placeholderCount).map((i) => (
                            <Placeholder key={i} $height={FontSize.SMALL_12} $width="150px" />
                        ))}
                    </PlaceholderGrid>
                )}
            </Value>
        </div>
    );
};

export default SingleWorkerRunInformationItem;
