<!-- Create/Edit field -->
<div class="padding-bottom">
    <div ng-if="data.existingFieldDefinition.definition.matchConfiguration.matchingRecurrence === 'ONCE'"
        style="margin-top: 15px;">
        <div class="common-size-xxxs mod-gray padding-normal-sm rounded-box" style="display: flex;">
    
            <span class="svg-icon-nav-sm margin-right-xs">
                <tnk-icon src="/images/icons/info-o.svg"></tnk-icon>
            </span>
    
            <div>
                <strong>Matched Entity Cadence</strong> The matched entity cadence is configured as <strong>“Single
                    Match”</strong>. This means that the matched condition will be evaluated until it finds its first match
                and then it will halt. Please contact Tonkean’s support team for more details.
            </div>
        </div>
        <hr>
    </div>
    <!-- Entity type selection -->
    <div class="common-bold">
        Select
        {{
            data.existingFieldDefinition.definition.matchConfiguration.isForMatchingItem
                ? 'a module '
                : 'data source entity type'
        }}:
    </div>

    <div class="flex-vmiddle">
        <!-- Entity selection for all entities integrations -->
        <tnk-entity-selector
            ng-if="data.integrationSupportsAllEntities"
            project-integration="data.projectIntegration"
            selected-entity="{{ data.selectedEntity }}"
            mode="fields"
            class="margin-right-xxs"
            force-entity-selection-disabled="!data.createMode"
            force-fetch-supported-entities-from-server="data.isCustomEntitiesFlagOn"
            on-entity-selected="onEntitySelected(selectedEntity)"
        ></tnk-entity-selector>
        <!-- Displaying tooltip only when field is SALESFORCE type -->
        <span
            ng-if="data.projectIntegration.integrationType === 'SALESFORCE'"
            class="fa fa-info-circle margin-left-xs"
            uib-tooltip="Edit datasource in order to add additional entities."
        ></span>
    </div>

    <!-- Entity selection for single entities integrations -->
    <button
        type="button"
        class="btn btn-default margin-right-xs common-no-outline"
        data-automation="integration-external-field-template-entity-selection-for-single-entities"
        ng-if="!data.integrationSupportsAllEntities"
        ng-disabled="!data.createMode"
        ng-repeat="opt in data.integrationFieldsOptions"
        ng-click="onEntitySelected(opt)"
        ng-class="{
            'selected-option':
                data.selectedEntity.toUpperCase() === opt.toUpperCase() ||
                data.selectedEntityPlural.toUpperCase() === opt.toUpperCase(),
            'not-selected-option':
                data.selectedEntity.toUpperCase() !== opt.toUpperCase() ||
                data.selectedEntityPlural.toUpperCase() !== opt.toUpperCase()
        }"
    >
        {{ opt }}
    </button>

    <!-- External field selection part -->
    <div ng-if="data.selectedEntity" class="margin-top-lg">
        <!-- Title -->
        <div
            class="common-bold margin-bottom-xs"
            ng-if="data.existingFieldDefinition.definition.matchConfiguration.isForMatchingItem"
        >
            Select a module field for real-time data:
        </div>

        <div
            class="common-bold margin-bottom-xs"
            ng-hide="
                data.idOnlyMode ||
                data.existingFieldDefinition.idRelationField ||
                data.existingFieldDefinition.definition.matchConfiguration.isForMatchingItem
            "
        >
            Select a data source field for real-time data:
        </div>

        <!-- External field selection -->
        <tnk-field-selector
            class="common-width-50"
            ng-hide="data.idOnlyMode || data.existingFieldDefinition.idRelationField"
            selected-field="data.selectedField"
            selected-field-identifier="filter"
            items-source="EXTERNAL"
            project-integration="data.projectIntegration"
            external-type="data.selectedEntityMetadata.pluralLabel || data.selectedEntity"
            on-field-selected="onFieldSelected(selectedField)"
            place-holder="Select a data source field"
            field-inspect-modal="true"
            field-inspect-auto-open="true"
            data-automation="integration-external-field-open-data-source-field"
            workflow-version-id="{{ data.workflowVersionId }}"
        ></tnk-field-selector>

        <!-- Match configuration selection title -->
        <div class="margin-bottom-xs margin-top-lg">
            <strong>
                Match by:
            </strong>
            <span ng-if="!data.showMatchingSettings">
                <i class="margin-normal-sm-h" ng-switch="data.matchConfigurationSelectionObject.matchOptionApiName">
                    <span ng-switch-when="TONKEAN_SMART_MATCHING">Smart matching</span>
                    <span ng-switch-when="SPECIFIC_ITEM">
                        Workflow {{ data.selectedEntityMetadata.label || data.selectedEntity }},
                        <span ng-if="data.matchConfigurationSelectionObject.creatingCustomTriggerId">
                            Created in - '{{
                                ctm.getCachedCustomTrigger(
                                    data.workflowVersionId,
                                    data.matchConfigurationSelectionObject.creatingCustomTriggerId
                                ).displayName
                            }}'
                        </span>
                        <span ng-if="data.matchConfigurationSelectionObject.idRelationFieldDefinitionId">
                            Id Relation - '{{
                                cfm.fieldsMap[data.workflowVersionId][
                                    data.matchConfigurationSelectionObject.idRelationFieldDefinitionId
                                ].name
                            }}'
                        </span>
                        <span
                            ng-if="
                                !data.matchConfigurationSelectionObject.creatingCustomTriggerId &&
                                !data.matchConfigurationSelectionObject.idRelationFieldDefinitionId
                            "
                        >
                            Root Monitored Item
                        </span>
                    </span>
                    <span ng-switch-when="MATCH_FIELD_RULES">
                        Match {{ data.selectedEntityMetadata.label || data.selectedEntity }}'s field '{{
                            data.matchConfigurationSelectionObject.entityMatchField.label
                        }}' with field '{{
                            data.matchConfigurationSelectionObject.workerItemMatchSpecialField
                                ? formulaSpecialFieldIdToDefinitionMap[
                                      data.matchConfigurationSelectionObject.workerItemMatchSpecialField
                                  ].label
                                : cfm.fieldsMap[data.workflowVersionId][
                                      data.matchConfigurationSelectionObject.workerItemMatchFieldDefinition
                                  ].name
                        }}'
                    </span>
                    <span ng-switch-when="MATCH_FIELD_CUSTOM_QUERY">Custom query</span>
                </i>
            </span>
        </div>

        <!-- Match configuration -->
        <div ng-show="data.showMatchingSettings">
            <tnk-match-option-selection
                ng-if="data.show"
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                project-integration="data.projectIntegration"
                entity-metadata="data.selectedEntityMetadata || data.selectedEntity"
                saved-match-option-selection="data.existingFieldDefinition.definition.matchConfiguration"
                show-advanced="true"
                default-selected-option="{{ data.matchDefaultSelectedOption }}"
                only-show-default-selected-option="data.idOnlyMode || data.existingFieldDefinition.idRelationField"
                on-match-option-selection-changed="onMatchOptionSelectionChanged(selectionObject, shouldSave)"
                mod-fixed-width="true"
                vertical-mod="false"
                tabs-field-selector-mod-narrow="false"
                tabs-field-selector-mod-fixed-width="true"
            ></tnk-match-option-selection>
        </div>

        <!-- Field type configuration selection title -->
        <div
            class="margin-bottom-xs margin-top-lg"
            ng-if="data.integrationSupportsChangingType && data.selectedFieldType"
        >
            <div class="flex-vmiddle">
                <strong>Field Type:</strong>

                <span ng-if="!data.showTypeSettings">
                    <i class="margin-normal-sm-h">{{ data.selectedFieldType }}</i>
                    [
                    <a ng-click="data.showTypeSettings = true">Edit</a>
                    ]
                </span>
                <!-- Field type configuration -->
                <div class="margin-left" uib-dropdown ng-if="data.showTypeSettings">
                    <button
                        type="button"
                        class="btn btn-default common-no-outline dropdown-toggle"
                        uib-dropdown-toggle
                        ng-disabled="data.disabled"
                    >
                        <span class="flex-vmiddle">
                            <span class="flex-grow text-left margin-right">
                                {{ data.displayAsApiNameToLabel[data.selectedFieldType] || 'Text' }}
                            </span>
                            <span class="dropdown-chevron flex-no-shrink"></span>
                        </span>
                    </button>
                    <ul class="dropdown-menu" uib-dropdown-menu aria-labelledby="split-button">
                        <li
                            ng-repeat="(displayAsApiName, displayAsLabel) in ::data.displayAsApiNameToLabel"
                            ng-click="selectFieldType(displayAsApiName)"
                        >
                            <a>{{ displayAsLabel }}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Extra configuration per type-->
            <div ng-switch="data.selectedFieldType" ng-if="data.showTypeSettings">
                <div ng-switch-when="List">
                    <tnk-picklist-value-editor
                        create-mode="data.createMode"
                        duplicate-mode="data.duplicateMode"
                        existing-values="data.existingFieldDefinition.possibleValues"
                        on-picklist-changed="onPicklistValuesChanged(values)"
                    ></tnk-picklist-value-editor>
                </div>
            </div>
        </div>
    </div>
</div>
