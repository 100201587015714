import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';

import AuditLogGroupRow from './AuditLogGroupRow';
import type AuditLogFilterSearchQuery from '../../../../entities/AuditLogFilterSearchQuery';
import type AuditLogType from '../../../../entities/AuditLogType';
import type AuditLogsGroupItemsResponse from '../../../UserActionItem/entities/responses/AuditLogsGroupItemsResponse';
import type AuditLogGroup from '../../entities/AuditLogGroup';
import PaginatedAuditLogItemsList from '../flat/PaginatedAuditLogItemsList';

import { Accordion, LIMIT_PARAM, SKIP_PARAM, useFetchManager } from '@tonkean/infrastructure';
import type { TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    enterpriseComponentId: string;
    enterpriseComponentType: TonkeanType;
    pageSize: number;
    group: AuditLogGroup;
    itemsCount: number;
    filterSearch?: AuditLogFilterSearchQuery;
    logType: AuditLogType;
}

const PaginatedAuditLogAccordionCard: React.FC<Props> = ({
    enterpriseComponentId,
    enterpriseComponentType,
    pageSize,
    group,
    itemsCount,
    filterSearch,
    logType,
}) => {
    const auditLogService = useAngularService('auditLogService');
    const projectManager = useAngularService('projectManager');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [
        [getAuditLogGroupItems],
        {
            data: auditLogsGroupItems,
            loadNextPage: loadNextGroupItemsPage,
            hasMorePages: hasMoreGroupItemsPages,
            loading: loadingGroupItems,
        },
    ] = useFetchManager(auditLogService, 'getAuditLogItems', {
        getItems: (auditLogs: AuditLogsGroupItemsResponse) => auditLogs.entities,
        limit: pageSize,
    });

    const onHeaderClick = () => {
        // Load items if we opened the header for the first time
        if (!isOpen && !auditLogsGroupItems.length) {
            getAuditLogGroupItems(
                projectManager.project.id,
                enterpriseComponentId,
                enterpriseComponentType,
                SKIP_PARAM,
                LIMIT_PARAM,
                logType,
                {
                    ...filterSearch,
                    groupId: group.groupUniqueIdentifier,
                },
            );
        }
        setIsOpen(!isOpen);
    };

    const isLoading = useMemo(() => {
        return loadingGroupItems.any || (itemsCount > 0 && !auditLogsGroupItems.length);
    }, [loadingGroupItems, itemsCount, auditLogsGroupItems]);

    return (
        <Accordion
            open={isOpen}
            onChange={onHeaderClick}
            title={<AuditLogGroupRow group={group} itemsCount={itemsCount} />}
        >
            {isOpen && (
                <PaginatedAuditLogItemsList
                    items={auditLogsGroupItems}
                    loading={isLoading}
                    hasMore={hasMoreGroupItemsPages}
                    loadNextPage={loadNextGroupItemsPage}
                    itemsCount={itemsCount}
                    pageSize={pageSize}
                    searchTerm={filterSearch?.search}
                    logType={logType}
                />
            )}
        </Accordion>
    );
};

export default PaginatedAuditLogAccordionCard;
