import React, { useMemo } from 'react';

import FieldsContainer from './FieldsContainer';
import SingleField from './SingleField';
import type { SingleFieldChanged, SingleFieldConfigurationWithDefinition } from '../../CommonWidgetConfiguration';
import type { FieldsWidgetDisplayType } from '../FieldsItemWidgetConfiguration';

import type { TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    showEmptyFieldWhenNoInitiative: boolean;
    showModuleOffIndication?: boolean;
    onChange(change: SingleFieldChanged): Promise<void>;

    fields: SingleFieldConfigurationWithDefinition[];
    submitAsForm: boolean;
    editingEnabledInWidget?: boolean;
    fieldDisplay: FieldsWidgetDisplayType;
    canUserEditItem: boolean;
    displayNotUpToDateWarning: boolean;
    emphasizeText?: boolean;
}

const FieldsWidgetDisplay: React.FC<Props> = ({
    projectId,
    showEmptyFieldWhenNoInitiative,
    fields,
    onChange,
    submitAsForm,
    editingEnabledInWidget,
    fieldDisplay,
    canUserEditItem,
    showModuleOffIndication,
    emphasizeText,
}) => {
    const sortedFieldDefinitions = useMemo(() => {
        return fields.sort((fieldA, fieldB) => Number(fieldA.index) - Number(fieldB.index));
    }, [fields]);

    return (
        <FieldsContainer fieldDisplay={fieldDisplay}>
            {sortedFieldDefinitions.map((field) => {
                return (
                    <SingleField
                        dataAutomation={`fields-widget-field-${field.package?.fieldDefinition.name}`}
                        key={field.key}
                        projectId={projectId}
                        onChange={(newValue) => {
                            if (field.package) {
                                return onChange({ package: field.package, newValue });
                            }
                            throw new Error('Unable to update field without filed definition');
                        }}
                        field={field.package}
                        fieldDefinitionId={field.key}
                        showEmptyFieldWhenNoInitiative={showEmptyFieldWhenNoInitiative}
                        labelOverride={field.label}
                        hideColorsAndTrends={field.hideColorsAndTrends}
                        submitAsForm={submitAsForm}
                        fullWidth={field?.fullWidth}
                        fieldDisplay={fieldDisplay}
                        showModuleOffIndication={showModuleOffIndication}
                        editingEnabledInWidget={editingEnabledInWidget}
                        canUserEditItem={canUserEditItem}
                        emphasizeText={emphasizeText}
                    />
                );
            })}
        </FieldsContainer>
    );
};

export default FieldsWidgetDisplay;
