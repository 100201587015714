import type { AngularServices } from 'angulareact';
import type { TonkeanId, TonkeanType, WorkflowVersion } from '@tonkean/tonkean-entities';
import { WorkflowVersionType } from '@tonkean/tonkean-entities';
import { WorkflowActivityEvents } from './WorkflowActivityEvents';

/**
 * A service to help with groups operations
 */
class WorkflowVersionManager {
    /* @ngInject */

    /**
     * Holds a map of workflow version id to the workflow versions entity.
     */
    public workflowVersionIdToWorkflowVersionMap: Record<string, WorkflowVersion> = {};

    /**
     * Holds a map of workflow version id to the workflow versions entity.
     */
    public workflowVersionRequestIdToWorkflowVersionPromiseMap: Record<string, Promise<WorkflowVersion>> = {};

    /**
     * Holds a map between group id to its draft workflow version entity.
     */
    public groupIdToDraftWorkflowVersionIdMap: Record<string, string> = {};

    /**
     * Holds a map between group id to its published workflow version entity.
     */
    public groupIdToPublishedWorkflowVersionIdMap: Record<string, string> = {};

    /**
     * Map between a workflow version id to its changes counter.
     */
    public workflowVersionIdToChangesCounterMap: Record<string, number> = {};

    /**
     * Map between a workflow version id to its changes counter.
     */
    public subWorkflowVersionIdToChangesCounterMap: Record<string, number> = {};

    /**
     * A map between the workflowVersionId and whether we encountered errors fetching the changes counts.
     * If we encounter an error we allow the user to publish anyway.
     */
    public workflowVersionIdToChangesCounterError: Record<string, boolean> = {};

    /**
     * holds the last workflow version id got a group
     */
    public lastWorkflowVersionIdMap: Record<string, string> = {};

    protected constructor(
        private $rootScope: AngularServices['$rootScope'],
        private $q: AngularServices['$q'],
        private utils: AngularServices['utils'],
        private tonkeanService: AngularServices['tonkeanService'],
        private liveReportHelper: AngularServices['liveReportHelper'],
        private authenticationService: AngularServices['authenticationService'],
        private projectManager: AngularServices['projectManager'],
    ) {}

    /**
     * Returns whether given workflow version id is already cached.
     */
    public hasWorkflowVersion(workflowVersionId: string): boolean {
        return !!this.workflowVersionIdToWorkflowVersionMap[workflowVersionId];
    }

    /**
     * Fetches given workflow version id from the server and caches it.
     */
    public getNotCachedVersionsFromServer(groupId: string, workflowVersionIds: string[]): Promise<void> {
        const missingWorkflowVersionIds = workflowVersionIds.filter(
            (workflowVersionId) => !this.workflowVersionIdToWorkflowVersionMap[workflowVersionId],
        );

        if (missingWorkflowVersionIds.length) {
            return this.tonkeanService
                .getGroupWorkflowVersions(
                    groupId,
                    0,
                    missingWorkflowVersionIds.length,
                    null,
                    null,
                    missingWorkflowVersionIds,
                )
                .then((data) => {
                    const workflowVersions = data.entities;
                    workflowVersions.forEach((workflowVersion) => this.cacheWorkflowVersion(workflowVersion));
                    return this.$q.resolve();
                });
        } else {
            return this.$q.resolve();
        }
    }

    /**
     * Fetches given workflow version id from the server and caches it.
     */
    public getFromServerAndCacheWorkflowVersion(workflowVersionId: string): Promise<WorkflowVersion> {
        return this.tonkeanService.getWorkflowVersionById(workflowVersionId).then((workflowVersion) => {
            this.cacheWorkflowVersion(workflowVersion);
            return this.$q.resolve(workflowVersion);
        });
    }

    public getFromServerAndCacheWorkflowVersions(
        projectId: TonkeanId<TonkeanType.PROJECT>,
        workflowVersionIds: TonkeanId<TonkeanType.WORKFLOW_VERSION>[],
    ) {
        return this.tonkeanService.getWorkflowVersionsByIds(projectId, workflowVersionIds).then((workflowVersions) => {
            workflowVersions.entities.map((workflowVersion) => this.cacheWorkflowVersion(workflowVersion));
            return this.$q.resolve(workflowVersions.entities);
        });
    }

    /**
     * Caches given workflow version.
     */
    public cacheWorkflowVersion(workflowVersion: WorkflowVersion): void {
        // We must have an identifier in order to cache it.
        if (workflowVersion && workflowVersion.id) {
            // Workflow version
            this.workflowVersionIdToWorkflowVersionMap[workflowVersion.id] = workflowVersion;

            // Group draft workflow version
            if (workflowVersion.workflowVersionType === 'DRAFT') {
                this.groupIdToDraftWorkflowVersionIdMap[workflowVersion.groupId] = workflowVersion.id;
            }

            // Group published workflow version
            if (workflowVersion.workflowVersionType === 'PUBLISHED') {
                this.groupIdToPublishedWorkflowVersionIdMap[workflowVersion.groupId] = workflowVersion.id;
            }
        }
    }

    public removeCachedWorkflowVersion(workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>) {
        delete this.workflowVersionIdToWorkflowVersionMap[workflowVersionId];
    }

    /**
     * Updated the given workflow version in the different caches by copying it's properties.
     * @param updatedWorkflowVersion - an updated workflow version to find and update in our caches.
     * @param deleteMissingFields - when true, missing fields in the updated workflow version will be deleted from the cached workflow version.
     */
    public updateWorkflowVersion(
        updatedWorkflowVersion: { id: string } & Partial<WorkflowVersion>,
        deleteMissingFields?: false,
    ): void;
    /**
     * Updated the given workflow version in the different caches by copying it's properties.
     * @param updatedWorkflowVersion - an updated workflow version to find and update in our caches.
     * @param deleteMissingFields - when true, missing fields in the updated workflow version will be deleted from the cached workflow version.
     */
    public updateWorkflowVersion(updatedWorkflowVersion: WorkflowVersion, deleteMissingFields: true): void;
    public updateWorkflowVersion(updatedWorkflowVersion: WorkflowVersion, deleteMissingFields: boolean = false) {
        // Initialization.
        if (!this.workflowVersionIdToWorkflowVersionMap) {
            this.workflowVersionIdToWorkflowVersionMap = {};
        }

        // Update the workflow versions map (without replacing the reference).
        const oldWorkflowVersion = this.workflowVersionIdToWorkflowVersionMap[updatedWorkflowVersion.id];
        if (oldWorkflowVersion) {
            this.utils.copyEntityFields(
                updatedWorkflowVersion,
                oldWorkflowVersion,
                undefined,
                undefined,
                deleteMissingFields,
            );
        } else {
            this.workflowVersionIdToWorkflowVersionMap[updatedWorkflowVersion.id] = updatedWorkflowVersion;
        }
    }

    /**
     * Gets a cached workflow version.
     */
    public getCachedWorkflowVersion(workflowVersionId: string): WorkflowVersion | undefined {
        return this.workflowVersionIdToWorkflowVersionMap[workflowVersionId];
    }

    public getCachedWorkflowVersionOrGetFromServer(workflowVersionId: string): Promise<WorkflowVersion> {
        const cachedWorkflowVersion = this.workflowVersionIdToWorkflowVersionMap[workflowVersionId];
        if (!cachedWorkflowVersion) {
            return this.getFromServerAndCacheWorkflowVersion(workflowVersionId).then((fetchedWorkflowVersion) => {
                return fetchedWorkflowVersion;
            });
        }

        return this.$q.resolve(cachedWorkflowVersion);
    }

    /**
     * Is the provided workflow version id a draft version?
     */
    public isDraftVersion(workflowVersionId: string) {
        const workflowVersion = this.getCachedWorkflowVersion(workflowVersionId);
        return workflowVersion?.workflowVersionType === WorkflowVersionType.DRAFT;
    }

    /**
     * Is the provided workflow version id a published version?
     */
    public isPublishedVersion(workflowVersionId: string) {
        const workflowVersion = this.getCachedWorkflowVersion(workflowVersionId);
        return workflowVersion?.workflowVersionType === WorkflowVersionType.PUBLISHED;
    }

    public getDraftVersion(groupId: string): Promise<WorkflowVersion> {
        const workflowVersion = this.getDraftVersionFromCache(groupId);
        if (workflowVersion) {
            return this.$q.resolve(workflowVersion);
        }

        return this.getGroupWorkflowVersionFromServer(groupId, WorkflowVersionType.DRAFT);
    }

    public getPublishedVersion(groupId: string): Promise<WorkflowVersion> {
        const workflowVersion = this.getPublishedVersionFromCache(groupId);
        if (workflowVersion) {
            return this.$q.resolve(workflowVersion);
        }

        return this.getGroupWorkflowVersionFromServer(groupId, WorkflowVersionType.PUBLISHED);
    }

    public getVersionByType(groupId: string, workflowVersionType: WorkflowVersionType): Promise<WorkflowVersion> {
        const workflowVersion =
            workflowVersionType === WorkflowVersionType.PUBLISHED
                ? this.getPublishedVersionFromCache(groupId)
                : this.getDraftVersionFromCache(groupId);
        if (workflowVersion) {
            return this.$q.resolve(workflowVersion);
        }

        return this.getGroupWorkflowVersionFromServer(groupId, workflowVersionType);
    }

    /**
     * Gets the cached draft version of given group id.
     */
    public getDraftVersionFromCache(groupId: string): WorkflowVersion | undefined {
        const draftWorkflowVersionId = this.groupIdToDraftWorkflowVersionIdMap[groupId];
        return draftWorkflowVersionId ? this.getCachedWorkflowVersion(draftWorkflowVersionId) : undefined;
    }

    /**
     * Gets the cached published version of given group id.
     */
    public getPublishedVersionFromCache(groupId: string): WorkflowVersion | undefined {
        const publishedWorkflowVersionId = this.groupIdToPublishedWorkflowVersionIdMap[groupId];
        return publishedWorkflowVersionId ? this.getCachedWorkflowVersion(publishedWorkflowVersionId) : undefined;
    }

    public updateWorkflowVersionScheduledWorkerDefinition(
        groupId,
        scheduledTrackName,
        evaluatedScheduledTrackName,
    ): Promise<unknown> {
        return this.tonkeanService
            .updateWorkflowVersionScheduledWorkerDefinition(groupId, scheduledTrackName, evaluatedScheduledTrackName)
            .then((updatedWorkflowVersion) => {
                this.incrementDraftCounter(groupId);
                this.updateWorkflowVersion(updatedWorkflowVersion);
            });
    }

    /**
     * Toggles the shouldSendGatherUpdates property of a workflow version.
     */
    public toggleShouldSendGatherUpdates(groupId: string, newValue: boolean): Promise<void> {
        const workflowVersion = this.getDraftVersionFromCache(groupId);
        const initialValue = workflowVersion?.shouldSendGatherUpdates;
        if (workflowVersion) {
            workflowVersion.shouldSendGatherUpdates = newValue;
        }

        return this.tonkeanService
            .toggleWorkflowVersionGatherUpdates(groupId, newValue)
            .then((updatedWorkflowVersion) => {
                if (workflowVersion) {
                    this.incrementWorkflowVersionCounter(workflowVersion.id);
                }

                return this.updateWorkflowVersion(updatedWorkflowVersion);
            })
            .catch(() => {
                if (workflowVersion && initialValue !== undefined) {
                    workflowVersion.shouldSendGatherUpdates = initialValue;
                }

                this.$rootScope.$emit('alert', "Couldn't update Auto Check-ins property.");
            });
    }

    /**
     * Updates the dashboardHidden state of the given workflow version.
     */
    public updateWorkflowVersionDashboardHidden(groupId: string, dashboardHidden: boolean): Promise<unknown> {
        const cachedWorkflowVersionObject = this.getDraftVersionFromCache(groupId);
        if (cachedWorkflowVersionObject) {
            cachedWorkflowVersionObject.dashboardHidden = dashboardHidden;
        }

        return this.tonkeanService.updateWorkflowVersionDashboardHidden(groupId, dashboardHidden).then((data) => {
            if (cachedWorkflowVersionObject) {
                this.incrementWorkflowVersionCounter(cachedWorkflowVersionObject.id);
            }

            return this.$q.resolve(data);
        });
    }

    /**
     * Returns the relevant from date for changes.
     */
    public getChangesFromDate(groupId: string): number {
        const publishedCreatedDate = this.getPublishedVersionFromCache(groupId)?.created;
        const draftLastRevertTime = this.getDraftVersionFromCache(groupId)?.lastRevertTime;

        if (publishedCreatedDate && draftLastRevertTime) {
            return Math.max(publishedCreatedDate, draftLastRevertTime);
        } else {
            return publishedCreatedDate || draftLastRevertTime || 0;
        }
    }

    /**
     * Gets all the activity types that are relevant for workflow changes.
     */
    public getWorkflowRelevantActivityTypes(): WorkflowActivityEvents[] {
        return [
            WorkflowActivityEvents.CUSTOM_TRIGGER_DISPLAY_NAME_CHANGED,
            WorkflowActivityEvents.TRIGGER_ENABLED_STATE_CHANGED,
            WorkflowActivityEvents.CUSTOM_TRIGGER_ACTION_DEFINITION_CHANGED,
            WorkflowActivityEvents.CUSTOM_TRIGGER_POST_STATE_CHANGED,
            WorkflowActivityEvents.CUSTOM_TRIGGER_CREATED,
            WorkflowActivityEvents.CUSTOM_TRIGGER_DELETED,
            WorkflowActivityEvents.CUSTOM_TRIGGER_DUPLICATED,
            WorkflowActivityEvents.CREATED_BOT_FROM_TEMPLATE,
            WorkflowActivityEvents.GROUP_SHOULD_GATHER_UPDATES,
            WorkflowActivityEvents.GROUP_IS_DASHBOARD_HIDDEN_TOGGLED,
            WorkflowActivityEvents.CUSTOM_TRIGGER_HIDDEN_STATE_CHANGE,
            WorkflowActivityEvents.GROUP_DATA_SOURCE_CHANGED_TO_MANUAL,
            WorkflowActivityEvents.GROUP_DATA_SOURCE_CHANGED_TO_FORMS,
            WorkflowActivityEvents.GROUP_DATA_SOURCE_REMOVED,
            WorkflowActivityEvents.DATA_SOURCE_CHANGED_TO_SCHEDULED,
            WorkflowActivityEvents.DATA_SOURCE_CHANGED_TO_INTEGRATION,
            WorkflowActivityEvents.INTEGRATION_DATA_SOURCE_CONFIGURATION_CHANGED,
            WorkflowActivityEvents.FIELD_DEFINITION_CREATED,
            WorkflowActivityEvents.FIELD_DEFINITION_UPDATED,
            WorkflowActivityEvents.FIELD_DEFINITION_DELETED,
            WorkflowActivityEvents.FORM_CREATED,
            WorkflowActivityEvents.FORM_UPDATED,
            WorkflowActivityEvents.FORM_DELETED,
            WorkflowActivityEvents.STATES_CHANGED,
            WorkflowActivityEvents.WORKER_CUSTOM_TRIGGER_GRAPH_CHANGED,
            WorkflowActivityEvents.TRAINED_KEYWORD_ADDED,
            WorkflowActivityEvents.TRAINED_KEYWORD_DELETED,
            WorkflowActivityEvents.SCHEDULED_WORKER_CONFIGURATION_CHANGED,
            WorkflowActivityEvents.FIELD_GRAPH_UPDATE,
            WorkflowActivityEvents.LIVE_REPORT_FIELD_IS_HIDDEN_UPDATED,
            WorkflowActivityEvents.LIVE_REPORT_FIELD_REORDER,
            WorkflowActivityEvents.MODULE_REVERT,
            WorkflowActivityEvents.ITEM_INTERFACE_CREATED,
            WorkflowActivityEvents.ITEM_INTERFACE_UPDATED,
            WorkflowActivityEvents.ITEM_INTERFACE_DELETED,
            WorkflowActivityEvents.MODULE_CREATED_FROM_MARKETPLACE,
            WorkflowActivityEvents.ITEM_INTERFACE_WIDGET_CREATED,
            WorkflowActivityEvents.ITEM_INTERFACE_WIDGET_UPDATED,
            WorkflowActivityEvents.ITEM_INTERFACE_WIDGET_DELETED,
            WorkflowActivityEvents.ITEM_INTERFACE_SET_AS_DEFAULT,
            WorkflowActivityEvents.ITEM_INTERFACE_REMOVED_AS_DEFAULT,
            WorkflowActivityEvents.ITEM_INTERFACE_LOGO_ADDED,
            WorkflowActivityEvents.ITEM_INTERFACE_LOGO_REMOVED,
            WorkflowActivityEvents.MODULE_CREATED_FROM_MODULE_DUPLICATED,
            WorkflowActivityEvents.ITEM_DETAILS_UPDATED,
            WorkflowActivityEvents.ITEM_DETAILS_CREATED,
            WorkflowActivityEvents.PROCESS_MAPPER_CREATED,
            WorkflowActivityEvents.PROCESS_MAPPER_UPDATED,
            WorkflowActivityEvents.PROCESS_MAPPER_DELETED,
            WorkflowActivityEvents.PROCESS_MAPPER_NODE_CREATED,
            WorkflowActivityEvents.PROCESS_MAPPER_NODE_UPDATED,
            WorkflowActivityEvents.PROCESS_MAPPER_NODE_DELETED,
            WorkflowActivityEvents.PROCESS_MAPPER_EDGE_CREATED,
            WorkflowActivityEvents.PROCESS_MAPPER_EDGE_UPDATED,
            WorkflowActivityEvents.PROCESS_MAPPER_EDGE_DELETED,
            WorkflowActivityEvents.GROUP_INPUT_SOURCE_FROM_ANOTHER_MODULE_MAPPING_CONFIGURATION_CHANGED,
            WorkflowActivityEvents.GROUP_INPUT_SOURCE_FROM_ANOTHER_MODULE_CONTRACT_CHANGED,
            WorkflowActivityEvents.GROUP_INPUT_SOURCE_CHANGED_TO_FROM_ANOTHER_MODULE,
            WorkflowActivityEvents.ITEM_INTERFACE_WIDGETS_SWAPPED,
            WorkflowActivityEvents.SOLUTION_SITE_PAGE_WIDGETS_SWAPPED,
        ];
    }

    /**
     * Initializes the counter of changes to draft workflow version.
     */
    public initializeChangesCounter(
        workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>,
        groupId: string,
    ): Promise<void> {
        if (!workflowVersionId || !groupId) {
            return this.$q.resolve();
        }

        if (!this.workflowVersionIdToChangesCounterMap[workflowVersionId]) {
            this.workflowVersionIdToChangesCounterMap[workflowVersionId] = 0;
        }

        if (!this.subWorkflowVersionIdToChangesCounterMap[workflowVersionId]) {
            this.subWorkflowVersionIdToChangesCounterMap[workflowVersionId] = 0;
        }

        if (!this.workflowVersionIdToChangesCounterError[workflowVersionId]) {
            this.workflowVersionIdToChangesCounterError[workflowVersionId] = false;
        }

        // Fetching the counter.
        return this.tonkeanService
            .getGroupActivity(
                groupId,
                1,
                this.getWorkflowRelevantActivityTypes(),
                null,
                0,
                false,
                null,
                null,
                null,
                true,
                workflowVersionId,
                null,
                null,
            )
            .then((activitiesFetchResponse) => {
                const changesCount = activitiesFetchResponse.total;

                // Saving it on the map.
                this.workflowVersionIdToChangesCounterMap[workflowVersionId] = changesCount;

                this.subWorkflowVersionIdToChangesCounterMap[workflowVersionId] =
                    activitiesFetchResponse.commitActivityTotal;

                const isDirty = changesCount !== 0 || activitiesFetchResponse.commitActivityTotal !== 0;

                this.updateWorkflowVersion({ id: workflowVersionId, isDirty }, false);

                const cachedWorkflowVersion = this.getCachedWorkflowVersion(workflowVersionId);
                if (cachedWorkflowVersion?.workflowVersionType === WorkflowVersionType.DRAFT) {
                    this.projectManager.updateGroup({ id: groupId, isDirty }, false);
                }

                return this.$q.resolve();
            })
            .catch(() => {
                this.workflowVersionIdToChangesCounterError[workflowVersionId] = true;

                this.updateWorkflowVersion({ id: workflowVersionId, isDirty: true }, false);
                this.projectManager.updateGroup({ id: groupId, isDirty: true }, false);

                return this.$q.resolve();
            });
    }

    /**
     * Increases the changes counter for the given workflow version.
     */
    public incrementWorkflowVersionCounter(workflowVersionId: string): void {
        if (!this.workflowVersionIdToChangesCounterMap[workflowVersionId]) {
            this.workflowVersionIdToChangesCounterMap[workflowVersionId] = 0;
        }

        // Increment.
        this.workflowVersionIdToChangesCounterMap[workflowVersionId] += 1;

        // Unmark as ready to publish
        const workflowVersion = this.workflowVersionIdToWorkflowVersionMap[workflowVersionId];
        if (workflowVersion) {
            workflowVersion.isPublishReady = false;
            workflowVersion.isDirty = true;

            if (workflowVersion.workflowVersionType === WorkflowVersionType.DRAFT) {
                this.projectManager.updateGroup({ id: workflowVersion.groupId, isDirty: true }, false);
            }
        }
    }

    /**
     * Increments the counter for the draft version of the given group.
     */
    public incrementDraftCounter(groupId: string): void {
        const draftVersionFromCache = this.getDraftVersionFromCache(groupId);
        if (draftVersionFromCache) {
            this.incrementWorkflowVersionCounter(draftVersionFromCache.id);
        }
    }

    public markAsReadyToPublishWorkflowVersion(
        groupId: string,
        comment: string,
        executeInitialSyncInGroup: boolean,
        calculateCustomTriggerIds: string[],
        calculateFieldDefinitionIds: string[],
    ): Promise<WorkflowVersion | undefined> {
        return this.tonkeanService
            .markAsReadyToPublishWorkflowVersion(
                groupId,
                comment,
                executeInitialSyncInGroup,
                calculateCustomTriggerIds,
                calculateFieldDefinitionIds,
            )
            .then(({ comment: returnedComment, isDirty, isPublishReady, publishReadyTime }) => {
                const workflowVersion = this.getDraftVersionFromCache(groupId);
                if (!workflowVersion) {
                    return;
                }

                // This endpoint returns an un-enriched publish approver. Because the user that called this method is
                // the approver, we can use the person in authenticationService current.
                const publishApprover = this.authenticationService.currentUser;

                this.updateWorkflowVersion(
                    {
                        id: workflowVersion.id,
                        comment: returnedComment,
                        isDirty,
                        isPublishReady,
                        publishReadyTime,
                        publishApprover,
                    },
                    false,
                );
                this.projectManager.updateGroup({ id: groupId, isDirty }, false);

                return workflowVersion;
            });
    }

    public getGroupWorkflowVersion(
        groupId: string,
        workflowVersionType: WorkflowVersionType,
    ): Promise<WorkflowVersion> {
        if (workflowVersionType === WorkflowVersionType.DRAFT) {
            return this.getDraftVersion(groupId);
        } else if (workflowVersionType === WorkflowVersionType.PUBLISHED) {
            return this.getPublishedVersion(groupId);
        } else {
            throw new Error(`Got unidentified workflowVersionType [${workflowVersionType}]`);
        }
    }

    private getGroupWorkflowVersionFromServer(
        groupId: string,
        workflowVersionType: WorkflowVersionType,
    ): Promise<WorkflowVersion> {
        const promiseIdentifier = groupId + workflowVersionType;

        const promise: Promise<WorkflowVersion> =
            this.workflowVersionRequestIdToWorkflowVersionPromiseMap[promiseIdentifier] ||
            this.tonkeanService.getGroupWorkflowVersion(groupId, workflowVersionType).then((workflowVersion) => {
                this.cacheWorkflowVersion(workflowVersion);
                return this.$q.resolve(workflowVersion);
            });

        this.workflowVersionRequestIdToWorkflowVersionPromiseMap[promiseIdentifier] = promise;

        promise.finally(() => {
            delete this.workflowVersionRequestIdToWorkflowVersionPromiseMap[promiseIdentifier];
        });

        return promise;
    }
}

export default WorkflowVersionManager;

angular.module('tonkean.app').service('workflowVersionManager', WorkflowVersionManager);
