import { useMemo } from 'react';

import type LineItemsWidgetConfiguration from '../entities/LineItemsWidgetConfiguration';

import type { FieldDefinitionKey, ItemInterfaceWidget } from '@tonkean/tonkean-entities';

const useRequiredFields = (widget: ItemInterfaceWidget<LineItemsWidgetConfiguration>) => {
    return useMemo(() => {
        return Object.entries(widget.configuration.fields)
            .filter(([, field]) => field.isShown && field.isRequired)
            .map(([id]) => id as FieldDefinitionKey);
    }, [widget.configuration.fields]);
};

export default useRequiredFields;
