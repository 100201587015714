import type { FormEvent } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Textarea } from '@tonkean/infrastructure';
import { GoogleAuthenticationType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { InputSize } from '@tonkean/tui-theme/sizes';

interface Props {
    authenticate(config: Record<string, any>, projectIntegrationData?: any, integrationDisplayName?: string): void;
    shouldIndexContent: boolean | undefined;
}

const CredentialsJsonTextarea = styled(Textarea)`
    padding-left: 5px;
`;

const CredentialsLabel = styled.label`
    width: 180px;
`;

const CredentialsForm = styled.div`
    padding: 0 20px 0 20px;
`;

const ErrorMessage = styled.div`
    color: ${Theme.colors.error};
`;

interface errorObject {
    message: string;
    stack: string;
}

const GoogleServiceAccount: React.FC<Props> = ({ authenticate, shouldIndexContent }) => {
    const [jsonCredentials, setJsonCredentials] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>();

    const authenticateCallback = (formEvent: FormEvent) => {
        formEvent.preventDefault();

        try {
            const parsedJson = JSON.parse(jsonCredentials);
            if (typeof parsedJson !== 'object') {
                setErrorMessage('JSON is not valid.');
            } else {
                const config = {
                    credentialsJson: parsedJson,
                    environment: GoogleAuthenticationType.SERVICE_ACCOUNT,
                    shouldIndexContent,
                };
                const additionalData = { environment: GoogleAuthenticationType.SERVICE_ACCOUNT };
                authenticate(config, additionalData);
            }
        } catch (error) {
            console.log(error);
            const friendlyError = (error as errorObject)?.message || 'Unknown Error.';
            setErrorMessage(`JSON is not valid. ${friendlyError}`);
        }
    };

    return (
        <div>
            <form className="form-horizontal" onSubmit={authenticateCallback}>
                <div className="flex flex-col">
                    <CredentialsForm className="form-group flex ">
                        <CredentialsLabel htmlFor="jsonCredentials">JSON credentials</CredentialsLabel>

                        <CredentialsJsonTextarea
                            value={jsonCredentials}
                            onChange={({ target }) => setJsonCredentials(target.value)}
                            id="jsonCredentials"
                            autoComplete="off"
                            minRows={3}
                            maxRows={25}
                            placeholder="Service Account Credentials"
                            size={InputSize.MEDIUM}
                            required
                        />
                    </CredentialsForm>

                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

                    {/* Connect button*/}
                    <div className="flex mod-center">
                        <button className="btn btn-primary">Connect</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default GoogleServiceAccount;
