import { getStateError } from '@tonkean/utils';

/**
 * Error filter for error objects
 */
function error() {
    return function (error) {
        if (!error) {
            return error;
        }
        return getStateError(error);
    };
}
angular.module('tonkean.shared').filter('error', error);
