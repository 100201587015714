import { useAngularService } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ProjectIntegrationPageContext from './components/ProjectIntegrationPageContext';
import type EnterpriseComponentPageExplorerItem from './entities/EnterpriseComponentPageExplorerItem';
import useIntegrationPageMenuConfiguration from './hooks/useIntegrationPageMenuConfiguration';
import useProjectIntegrationsBrowser from './hooks/useProjectIntegrationsBrowser';
import { getEnterpriseComponentByIdsFromProjectIntegrationSections } from './utils/getEnterpriseComponentByIds';
import { getEnterpriseComponentExplorerSectionsFromProjectIntegrationExplorerSections } from './utils/getEnterpriseComponentExplorerSectionsFromProjectIntegrationExplorerSections';
import getIntegrationsSectionsConfiguration from './utils/getIntegrationsSectionsConfiguration';
import getProjectIntegrationOverviewStepDefinitions from './utils/getProjectIntegrationOverviewStepDefinitions';
import EnterpriseComponentPageLayout from '../EnterpriseComponentsModule/components/EnterpriseComponentPageLayout/EnterpriseComponentPageLayout';
import type { EnterpriseComponentExplorerListSectionConfiguration } from '../EnterpriseComponentsModule/entities/EnterpriseComponentExplorerListConfiguration';

import type { TonkeanId } from '@tonkean/tonkean-entities';
import { TonkeanType } from '@tonkean/tonkean-entities';

interface Props {
    projectIntegrationId: TonkeanId<TonkeanType.PROJECT_INTEGRATION>;
}

const ProjectIntegrationPage: React.FC<Props> = ({ projectIntegrationId }) => {
    const projectManager = useAngularService('projectManager');
    const $rootScope = useAngularService('$rootScope');
    const integrations = useAngularService('integrations');

    const [projectIntegrationPageExplorerSections, setProjectIntegrationPageExplorerSections] = useState<
        EnterpriseComponentExplorerListSectionConfiguration[]
    >([]);
    const [projectIntegrationByIds, setProjectIntegrationByIds] = useState<
        Record<TonkeanId<TonkeanType.PROJECT_INTEGRATION>, EnterpriseComponentPageExplorerItem>
    >({});

    const {
        projectId,

        setCurrentProjectIntegration,
        currentProjectIntegration,

        setSelectedProjectIntegrationId,
        projectIntegrationSummaries,
        loadingProjectIntegrations,
        errorLoadingProjectIntegrations,
    } = useProjectIntegrationsBrowser(projectIntegrationId);

    const contextValue = useMemo(() => {
        return {
            projectId,

            setCurrentProjectIntegration,
            currentProjectIntegration,

            projectIntegrationSummaries,
            loadingProjectIntegrations,
            errorLoadingProjectIntegrations,
        };
    }, [
        projectId,

        setCurrentProjectIntegration,
        currentProjectIntegration,

        projectIntegrationSummaries,
        loadingProjectIntegrations,
        errorLoadingProjectIntegrations,
    ]);

    const menuConfiguration = useIntegrationPageMenuConfiguration(projectId, $rootScope, currentProjectIntegration);

    const onProjectIntegrationUpdated = useCallback(
        (updatedProjectIntegration: EnterpriseComponentPageExplorerItem<TonkeanType.PROJECT_INTEGRATION>) => {
            setProjectIntegrationByIds((oldValue) => ({
                ...oldValue,
                [updatedProjectIntegration.id]: updatedProjectIntegration,
            }));

            setCurrentProjectIntegration((oldValue) => oldValue && { ...oldValue, ...updatedProjectIntegration });

            // update the angular cached instance.
            const angularCachedProjectIntegration = projectManager.project.integrations.find(
                (searchedProjectIntegration) => searchedProjectIntegration.id === updatedProjectIntegration.id,
            );

            if (angularCachedProjectIntegration) {
                angularCachedProjectIntegration.displayName = updatedProjectIntegration.displayName;
                angularCachedProjectIntegration.description = updatedProjectIntegration.description;
                angularCachedProjectIntegration.iconUrl = updatedProjectIntegration.iconUrl;
                angularCachedProjectIntegration.valid = updatedProjectIntegration.valid;
            }
        },
        [projectManager.project.integrations, setCurrentProjectIntegration],
    );

    // Creating project integration sections and project integration by id when summaries
    // are returned or changed
    useEffect(() => {
        if (projectIntegrationSummaries) {
            const projectIntegrationsListConfiguration = getIntegrationsSectionsConfiguration(
                projectIntegrationSummaries?.entities,
                integrations.getIntegrationsConfig(),
            );

            setProjectIntegrationPageExplorerSections(
                getEnterpriseComponentExplorerSectionsFromProjectIntegrationExplorerSections(
                    projectIntegrationsListConfiguration.sections,
                ),
            );
            setProjectIntegrationByIds(
                getEnterpriseComponentByIdsFromProjectIntegrationSections(
                    projectIntegrationsListConfiguration.sections,
                ),
            );
        }
    }, [projectIntegrationSummaries, integrations]);

    return (
        <ProjectIntegrationPageContext.Provider value={contextValue}>
            <EnterpriseComponentPageLayout
                enterpriseComponentsByIds={projectIntegrationByIds}
                onEnterpriseComponentSelected={setSelectedProjectIntegrationId}
                onEnterpriseComponentUpdated={onProjectIntegrationUpdated}
                enterpriseComponentType={TonkeanType.PROJECT_INTEGRATION}
                menuConfiguration={menuConfiguration}
                enterpriseComponentExplorerItems={projectIntegrationPageExplorerSections}
                explorerTitle="Connected Data Sources"
                getOverviewStepDefinitions={getProjectIntegrationOverviewStepDefinitions}
                pageStateName="product.projectIntegrationPage"
            />
        </ProjectIntegrationPageContext.Provider>
    );
};

export default ProjectIntegrationPage;
