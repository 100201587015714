import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import EnterpriseComponentExplorerList from './EnterpriseComponentExplorerList';
import { ReactComponent as BackIconSvg } from '../../../../../../images/icons/back.svg';
import { ReactComponent as VersionControlSearchIcon } from '../../../../../../images/icons/version-control-search.svg';
import type { EnterpriseComponentExplorerListSectionConfiguration } from '../../../entities/EnterpriseComponentExplorerListConfiguration';
import useEnterpriseComponentContext from '../../../hooks/useEnterpriseComponentContext';
import { getEnterpriseComponentTabKey } from '../../../utils/getEnterpriseComponentsPageConfiguration';

import { Separator } from '@tonkean/infrastructure';
import { enterpriseComponentTypeDisplayName } from '@tonkean/tonkean-entities';
import type { EnterpriseComponentType } from '@tonkean/tonkean-entities';
import type { EnterpriseComponentsPersonPermissionRole } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { Theme } from '@tonkean/tui-theme';
import { FontSize } from '@tonkean/tui-theme';

function isEnterpriseComponentExplorerListSectionConfiguration(
    sections: EnterpriseComponentExplorerListSectionConfiguration[] | string[],
): sections is EnterpriseComponentExplorerListSectionConfiguration[] {
    return typeof sections[0] !== 'string';
}

export const SidePane = styled.div`
    border-right: 1px solid ${Theme.colors.gray_300};
    height: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-right: 1px solid ${Theme.colors.gray_400};
    z-index: 10000;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 54px);
`;

const ItemBrowserTitle = styled.div`
    font-size: ${FontSize.LARGE_16};
    font-weight: 500;
    margin-bottom: 20px;
    margin-left: 14px;
`;

const BackNavigationContainer = styled(Clickable)`
    display: flex;
    align-items: center;

    margin: 0 0 12px 14px;

    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
    cursor: pointer;

    ${styledFocus}
`;

const BackIconContainer = styled.div`
    margin-right: 8px;
    display: flex;
    align-items: center;

    svg {
        path {
            fill: ${Theme.colors.gray_500};
        }
    }
`;

const BackTitle = styled.div`
    font-size: ${FontSize.SMALL_12};
    line-height: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_500};
    text-transform: capitalize;
`;

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 14px;
`;

const SearchInput = styled.input`
    margin-top: -3px;
`;

const Divider = styled(Separator)`
    margin-bottom: 18px;
`;

const StickyHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 10001; /* In order to hide icons */
    background: white;
    padding-top: 24px;
`;

interface Props {
    explorerTitle: string;
    enterpriseComponentType: EnterpriseComponentType;
    allAccessData: EnterpriseComponentsPersonPermissionRole;
}

const EnterpriseComponentsExplorer: React.FC<Props> = ({ explorerTitle, enterpriseComponentType, allAccessData }) => {
    const { enterpriseComponentExplorerItems } = useEnterpriseComponentContext();
    const $state = useAngularService('$state');
    const [searchText, setSearchText] = useState<string | undefined>(undefined);

    const onBackClicked = useCallback(() => {
        $state.go('product.enterpriseComponents', {
            tab: getEnterpriseComponentTabKey(enterpriseComponentType),
            enterpriseComponentId: null,
        });
    }, [$state, enterpriseComponentType]);

    const sections = useMemo<EnterpriseComponentExplorerListSectionConfiguration[]>(
        () =>
            isEnterpriseComponentExplorerListSectionConfiguration(enterpriseComponentExplorerItems)
                ? enterpriseComponentExplorerItems
                : [{ id: '', title: '', itemIds: enterpriseComponentExplorerItems }],
        [enterpriseComponentExplorerItems],
    );

    return (
        <SidePane>
            <StickyHeader>
                <ItemBrowserTitle>{explorerTitle}</ItemBrowserTitle>

                <BackNavigationContainer onClick={onBackClicked}>
                    <BackIconContainer>
                        <BackIconSvg />
                    </BackIconContainer>

                    <BackTitle>{enterpriseComponentTypeDisplayName[enterpriseComponentType].singular} list</BackTitle>
                </BackNavigationContainer>

                <SearchContainer>
                    <span>
                        <span className="tnk-icon">
                            <VersionControlSearchIcon />
                        </span>
                    </span>

                    <SearchInput
                        className="form-control common-no-border common-no-outline"
                        onChange={({ target }) => setSearchText(target.value)}
                        placeholder={`Search for a ${enterpriseComponentTypeDisplayName[enterpriseComponentType].singular}`}
                    />
                </SearchContainer>

                <Divider color={Theme.colors.gray_400} />
            </StickyHeader>

            <EnterpriseComponentExplorerList
                enterpriseComponentType={enterpriseComponentType}
                enterpriseComponentSections={sections}
                filterByText={searchText}
                allAccessData={allAccessData}
            />
        </SidePane>
    );
};

export default EnterpriseComponentsExplorer;
