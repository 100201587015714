import type { IHttpResponse } from 'angular';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import GenericIntegrationConnectionComponent from './GenericIntegrationConnectionComponent';
import IntegrationEntitiesInboundConfiguration from './IntegrationEntitiesInboundConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { useToastMessage } from '@tonkean/angular-hooks';
import { useFeatureFlag } from '@tonkean/angular-hooks';
import { TextEllipsis, Tooltip } from '@tonkean/infrastructure';
import { ProjectIntegrationIcon } from '@tonkean/infrastructure';
import type { CustomizedSetupSaveConfiguration } from '@tonkean/integrations';
import { AuthenticationMethod } from '@tonkean/integrations';
import { IntegrationManager } from '@tonkean/integrations';
import type { UtilsService } from '@tonkean/shared-services';
import type { EntityInboundConfiguration, Integration } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';
import { getStateError } from '@tonkean/utils';

const CancelButton = styled(Button)`
    margin-right: 8px;
`;

const ProjectIntegrationTitle = styled.div`
    display: flex;
    font-size: 16px;
    border-right: 2px solid ${Theme.colors.gray_300};
    padding-right: 8px;
`;

const SetupTitle = styled.h4``;

const ProjectIntegrationDisplayName = styled(TextEllipsis)`
    margin-left: 8px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 0 0 16px;
    gap: 8px;
`;

interface Props {
    // Props
    integrationType: string;
    projectIntegration: any;
    onIntegrationClosed: (projectIntegration: Record<string, any>) => void;
    onCancel: () => void;

    // Injection
    utils: UtilsService;
    createProjectApis: any;
    projectManager: any;
    integration?: Integration;
}

const GenericIntegrationComponent: React.FC<Props> = ({
    integrationType,
    projectIntegration,
    onIntegrationClosed,
    onCancel,
    utils,
    createProjectApis,
    projectManager,
    integration,
}) => {
    const integrationConfiguration = useMemo(
        () => IntegrationManager.getIntegrationByName(integrationType),
        [integrationType],
    );
    if (!integrationConfiguration) {
        throw new Error(`No integration with the type ${integrationType}`);
    }

    const toast = useToastMessage();
    const enableEntitiesInboundConfigurationFlag = useFeatureFlag('tonkean_feature_entities_inbound_configuration');

    const [innerIntegration, setInnerIntegration] = useState(projectIntegration?.integration || integration);
    const [integrationDisplayName, setIntegrationDisplayName] = useState<string>();
    const [projectIntegrationData, setProjectIntegrationData] = useState<unknown>(
        projectIntegration?.projectData?.projectDatas?.[0],
    );
    const [customizedSetupSaveConfiguration, setCustomizedSetupSaveConfiguration] =
        useState<CustomizedSetupSaveConfiguration>();

    const [savingDisabled, setSavingDisabled] = useState<boolean>(false);
    const [saveButtonTooltipContent, setSaveButtonTooltipContent] = useState<string>();

    const [integrationEntitiesInboundConfigurationExists, setIntegrationEntitiesInboundConfigurationExists] =
        useState<boolean>(false);
    const [showIntegrationEntitiesInboundConfiguration, setShowIntegrationEntitiesInboundConfiguration] =
        useState<boolean>(false);

    const [entityTypeToInboundStatus, setEntityTypeToInboundStatus] = useState<Record<string, boolean>>({});

    const [{ data: entityTypes }, getIntegrationEntityTypes] = useLazyTonkeanService('getIntegrationEntityTypes');

    const showSaveButton = integrationConfiguration.authenticationType !== AuthenticationMethod.NONE;

    useEffect(() => {
        setProjectIntegrationData(projectIntegration?.projectData?.projectDatas?.[0]);
    }, [projectIntegration]);

    useEffect(() => {
        if (integrationConfiguration) {
            getIntegrationEntityTypes(projectManager.project.id, integrationType).then((entityTypes) => {
                setIntegrationEntitiesInboundConfigurationExists(
                    !!enableEntitiesInboundConfigurationFlag &&
                        entityTypes.length > 0 &&
                        !!integrationConfiguration.showEntitiesInboundConfiguration,
                );
            });
        }
    }, [
        integrationEntitiesInboundConfigurationExists,
        showIntegrationEntitiesInboundConfiguration,
        getIntegrationEntityTypes,
        integrationConfiguration,
        integrationType,
        projectManager.project.id,
        enableEntitiesInboundConfigurationFlag,
    ]);

    useEffect(() => {
        if (entityTypes) {
            setEntityTypeToInboundStatus(Object.fromEntries(entityTypes.map((entityType) => [entityType, true])));
        }
    }, [entityTypes]);

    const onChangeOrInitIntegration = useCallback((configuration, isDisabled, tooltipContent) => {
        setSavingDisabled(isDisabled);
        setCustomizedSetupSaveConfiguration(configuration);

        if (tooltipContent) {
            setSaveButtonTooltipContent(tooltipContent);
        }
    }, []);

    /**
     * Saves the integration once the OK button clicked.
     */
    const saveIntegration = async () => {
        if (!integrationConfiguration.customizedSetupComponent) {
            await onSetupCompleted(null, undefined);
        } else {
            try {
                await onSetupCompleted(
                    customizedSetupSaveConfiguration?.projectIntegrationData,
                    customizedSetupSaveConfiguration?.projectIntegrationDisplayName,
                    customizedSetupSaveConfiguration?.customIntegration,
                );
            } catch (error) {
                toast(getStateError(error as IHttpResponse<any>), 'danger');
            }
        }
    };

    const getEntitiesInboundConfiguration = () => {
        return Object.fromEntries(
            Object.entries(entityTypeToInboundStatus).map(([type, enabled]) => [
                type,
                {
                    collectConfiguration: {
                        isCollectEnabled: enabled,
                    },
                    livestreamConfiguration: {
                        isLivestreamEnabled: enabled,
                    },
                    entityType: type,
                } as EntityInboundConfiguration,
            ]),
        );
    };

    /**
     * Responsible for the project integration creation.
     */
    const onSetupCompleted = async (
        projectIntegrationData?: unknown,
        projectIntegrationDisplayName?: string,
        customIntegration?: Integration,
    ) => {
        const currentIntegration = customIntegration || innerIntegration;
        const updatedProjectData = projectIntegrationData ? [projectIntegrationData] : currentIntegration.projectData;
        const updatedIntegration = {
            ...currentIntegration,
            projectDatas: updatedProjectData,
        };

        // Updating state
        if (projectIntegrationData) {
            setProjectIntegrationData(projectIntegrationData);
            setInnerIntegration(updatedIntegration);
        }

        const oldProject = projectManager.project;
        let updatedProject;
        if (projectIntegration) {
            updatedProject = await createProjectApis.editProjectIntegration(
                projectManager.project.id,
                projectIntegration.id,
                updatedIntegration,
                projectIntegrationDisplayName || integrationDisplayName,
            );
        } else {
            updatedProject = await createProjectApis.createProjectIntegration(
                projectManager.project,
                updatedIntegration,
                projectIntegrationDisplayName || integrationDisplayName,
                undefined,
                undefined,
                integrationEntitiesInboundConfigurationExists ? getEntitiesInboundConfiguration() : {},
            );
        }

        // Refreshing, loading the cache. with the new integration.
        await projectManager.getProjectData(true);

        const newProjectIntegration = utils.findFirst(
            updatedProject.integrations,
            (prin: Record<string, any>) =>
                prin.id === projectIntegration?.id ||
                !oldProject.integrations.some((oldPrin) => oldPrin.id === prin.id),
        );

        if (newProjectIntegration) {
            onIntegrationClosed(newProjectIntegration);
        }
    };

    const handleIntegrationOnChangeOrInit = useCallback(
        (configuration: CustomizedSetupSaveConfiguration, isDisabled: boolean, tooltipContent?: string) => {
            if (!showSaveButton) {
                // throw error because this is the condition for showing the save button on this component.
                // if we do not show the save here, there is no need to call onChangeOrInitIntegration func in the customizedSetupComponent
                throw new Error('There was an error trying to create new integration');
            } else {
                onChangeOrInitIntegration(configuration, isDisabled, tooltipContent);
            }
        },
        [onChangeOrInitIntegration, showSaveButton],
    );

    const handleIntegrationOnSave = (
        projectIntegrationData?: unknown,
        projectIntegrationDisplayName?: string,
        customIntegration?: Integration,
    ) => {
        if (showSaveButton) {
            // throw error because this is the condition for not showing the save button on this component.
            // if we do show the save here, there is no need to call onSave func in the customizedSetupComponent
            throw new Error('There was an error trying to create new integration');
        } else {
            onSetupCompleted(projectIntegrationData, projectIntegrationDisplayName, customIntegration);
        }
    };

    return (
        <>
            <div className="generic-integration">
                {/* Modal Header*/}
                <Header>
                    <ProjectIntegrationTitle>
                        <ProjectIntegrationIcon
                            projectIntegrationId={projectIntegration?.id}
                            integrationType={projectIntegration?.integrationType || integrationType}
                            iconUrl={projectIntegration?.iconUrl}
                        />
                        {projectIntegration && (
                            <ProjectIntegrationDisplayName numberOfLines={1} tooltip>
                                {projectIntegration.displayName}
                            </ProjectIntegrationDisplayName>
                        )}
                    </ProjectIntegrationTitle>

                    <SetupTitle>Set Up Data Source</SetupTitle>
                </Header>
                {/* Modal Body*/}
                <div className="modal-body">
                    {integrationConfiguration.authenticationType !== AuthenticationMethod.NONE && (
                        <GenericIntegrationConnectionComponent
                            integrationType={integrationType}
                            integration={innerIntegration}
                            setProjectIntegrationData={setProjectIntegrationData}
                            setIntegrationDisplayName={setIntegrationDisplayName}
                            onIntegrationCreate={setInnerIntegration}
                            isCreatedFromSharedCredentials={!!projectIntegration?.sharedCredentialId}
                            projectIntegrationData={projectIntegrationData}
                            onAuthentication={() => setShowIntegrationEntitiesInboundConfiguration(true)}
                        />
                    )}
                    {(innerIntegration || integrationConfiguration.authenticationType === AuthenticationMethod.NONE) &&
                        integrationConfiguration.customizedSetupComponent && (
                            <integrationConfiguration.customizedSetupComponent
                                integration={innerIntegration}
                                projectIntegration={projectIntegration}
                                createProjectApis={createProjectApis}
                                onChangeOrInitIntegration={handleIntegrationOnChangeOrInit}
                                projectIntegrationData={projectIntegrationData}
                                onCancel={onCancel}
                                onSave={handleIntegrationOnSave}
                            />
                        )}
                    {enableEntitiesInboundConfigurationFlag &&
                        showIntegrationEntitiesInboundConfiguration &&
                        integrationEntitiesInboundConfigurationExists &&
                        entityTypes && (
                            <IntegrationEntitiesInboundConfiguration
                                entityTypes={entityTypes}
                                entityTypeToInboundStatus={entityTypeToInboundStatus}
                                onChange={setEntityTypeToInboundStatus}
                            />
                        )}
                </div>
                {/* Modal footer buttons*/}
                {/* When We use AuthenticationMethod NONE we hide the default save buttons because it uses the inner integration logic which is irrelevant*/}
                {showSaveButton && (
                    <div className="modal-footer">
                        <CancelButton onClick={onCancel} cancel>
                            Cancel
                        </CancelButton>

                        <Tooltip
                            content={saveButtonTooltipContent}
                            disabled={!savingDisabled || !saveButtonTooltipContent}
                        >
                            <Button
                                data-automation="generic-integration-component-save"
                                disabled={!innerIntegration || savingDisabled}
                                onClick={saveIntegration}
                            >
                                Save
                            </Button>
                        </Tooltip>
                    </div>
                )}
            </div>
        </>
    );
};

export default GenericIntegrationComponent;
