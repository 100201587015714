import styled from 'styled-components';

import { FontSize } from '@tonkean/tui-theme';

const LogicConfigurationTitle = styled.div<{ size?: 'large' }>`
    font-size: ${({ size }) => (size && size === 'large' ? FontSize.LARGE_16 : FontSize.MEDIUM_14)};
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    margin-bottom: 9px;
    margin-top: 9px;
`;

export default LogicConfigurationTitle;
