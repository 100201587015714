import outreachCircleIcon from '../../../../apps/tracks/images/integrations/outreach-circle.svg';
import outreachIcon from '../../../../apps/tracks/images/outreach.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class OutreachIntegration extends IntegrationDefinitionBase {
    override name = 'outreach';
    override displayName = 'Outreach';
    override description = 'Requires permissions to Calls, Mailboxes, Prospects, Rule Sets, Sequences & Users';
    override authenticationType = AuthenticationMethod.OAUTH;
    override externalActivityCreatedDateFieldName = 'createdate';
    override storageProviderSettings = new StorageProviderSettings(true, false);
    override entities = [
        new IntegrationEntity('Call', 'Calls'),
        new IntegrationEntity('Mailbox', 'Mailboxes'),
        new IntegrationEntity('Prospect', 'Prospects'),
        new IntegrationEntity('User', 'Users'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(outreachCircleIcon, '130px'),
        new IntegrationIcon(outreachIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Call: true, Mailbox: true, Prospect: true, User: true },
        isOnlyDataRetention: {},
    };

    override async authenticate(oAuthHandler, environment): Promise<Record<string, any>> {
        const clientId = environment.integrationKeysMap.outreach;
        const uri = environment.integrationKeysMap.outreachUri;
        const redirectUriState = oAuthHandler.publicGetState();
        const scope =
            'calls.all mailboxes.read prospects.all rulesets.all sequences.all sequenceStates.all sequenceSteps.all users.all webhooks.all';
        const redirectUri = `${uri}?state=${redirectUriState}`;
        const url = `https://api.outreach.io/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
            redirectUri,
        )}&response_type=code&scope=${scope}`;
        const code = await oAuthHandler.startOAuth(url, redirectUriState);
        return { code, redirectUri };
    }
}

export default OutreachIntegration;
