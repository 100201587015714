import template from './tnkIntegrationGroup.template.html?angularjs';
/**
 * Created by ShaLi on 6/23/15.
 */

function tnkIntegrationGroup() {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            group: '=',
            sources: '=',
            state: '=',
            displayMode: '@',
            viewOnlyMode: '<',
            openModalForConfiguration: '=',
            fixMode: '=',
            editMode: '=',
            hidePlusIcon: '<',
            connectedByName: '@',
            currentIntegration: '=',
            ngDisabled: '=',
            ngDisabledTooltip: '@',
            buttonPrefix: '@',
            buttonPostfix: '@',
            buttonText: '@',
            buttonMaxWidth: '@',
            webhookSource: '=',
            iconUrlOverride: '<',
            hideDeleteButton: '=', // If true, the integration won't show its delete button. Default - false.
            integrationChanged: '&',
            integrationConfigurationCanceled: '&',
            configuredInGroupId: '=',
            configuredInWorkflowVersionId: '=',
            onCollect: '&',
            wideMode: '<',
            selectedProjectIntegration: '<',
            isCommunication: '<',
            appendMenuToBody: '<',

            hideDataRetentionMenuOption: '<',
            hideUpdateDataMenuOption: '<',
            doNotRunCollectAfterSavingIntegration: '<',
        },
        template,
        controller: 'IntegrationGroupCtrl',
        link(scope, element, attrs, modelCtrl) {
            scope.$modelCtrl = modelCtrl;
        },
    };
}

export default angular.module('tonkean.shared').directive('tnkIntegrationGroup', tnkIntegrationGroup);
