import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function DelayLogicConfigurationCtrl($scope) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        validationObject: ctrl.validationObject,
        delayInMinutes: null,
        delayTimeUnit: null,

        timeUnitApiNameToConfigurationObjectMap: null,

        timeUnitOptions: [
            {
                apiName: 'SECONDS',
                displayName: 'Seconds',
            },
            {
                apiName: 'MINUTES',
                displayName: 'Minutes',
            },
            {
                apiName: 'HOURS',
                displayName: 'Hours',
            },
            {
                apiName: 'DAYS',
                displayName: 'Days',
            },
        ],
    };

    /**
     * Occurs on changes of the component properties.
     */
    ctrl.$onChanges = function (changesObj) {
        if (!$scope.data.timeUnitApiNameToConfigurationObjectMap) {
            $scope.data.timeUnitApiNameToConfigurationObjectMap = {};

            for (let i = 0; i < $scope.data.timeUnitOptions.length; i++) {
                const timeUnitOption = $scope.data.timeUnitOptions[i];
                $scope.data.timeUnitApiNameToConfigurationObjectMap[timeUnitOption.apiName] = timeUnitOption;
            }

            $scope.data.delayTimeUnit = $scope.data.timeUnitApiNameToConfigurationObjectMap['MINUTES'];
        }

        if (changesObj.configuredLogic) {
            $scope.data.configuredLogic = changesObj.configuredLogic.currentValue;
            initializeEditMode();
            $scope.onActionsChanged(false);
        }

        if (changesObj.validationObject) {
            $scope.data.validationObject = changesObj.validationObject.currentValue;
        }
    };

    $scope.onActionsChanged = function (shouldSaveLogic) {
        // Ignore negative values
        $scope.data.delayInMinutes =
            !!$scope.data.delayInMinutes && $scope.data.delayInMinutes >= 0 ? $scope.data.delayInMinutes : null;

        if (ctrl.onActionsChanged) {
            const actionDefinition = {
                actions: [
                    {
                        type: 'DELAY',
                        definition: {
                            delayInMinutes: $scope.data.delayInMinutes,
                            delayTimeUnit: $scope.data.delayTimeUnit ? $scope.data.delayTimeUnit.apiName : null,
                        },
                    },
                ],
            };

            ctrl.onActionsChanged({ definition: actionDefinition, shouldSaveLogic });
        }
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'DELAY',
        );
        if (customTriggerAction) {
            $scope.data.delayInMinutes = customTriggerAction.customTriggerActionDefinition.delayInMinutes;
            $scope.data.delayTimeUnit =
                $scope.data.timeUnitApiNameToConfigurationObjectMap[
                    customTriggerAction.customTriggerActionDefinition.delayTimeUnit
                ];
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('DelayLogicConfigurationCtrl', lateConstructController(DelayLogicConfigurationCtrl));
