import React from 'react';
import styled, { css } from 'styled-components';

import type { SearchWidgetCardsDensityConfiguration } from './SearchWidgetCardsDensityConfigurations';
import SearchWidgetLoadingImage from './SearchWidgetLoadingImage';
import { SearchWidgetImagePlaceholderMode } from '../SearchWidgetConfiguration';
import { SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE } from '../SearchWidgetConfiguration';

import type { SearchWidgetResponseItem } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

export const ImageContainer = styled.div<{ $width: number }>`
    display: flex;
    justify-content: center;
    ${({ $width }) => css`
        width: ${`${$width}px`};
        max-width: ${`${$width}px`};
        min-width: ${`${$width}px`};
    `}
`;

export const InitialsContainer = styled.div<{ $height: number; $fontSize: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ $height }) => css`
        height: ${`${$height}px`};
        width: ${`${$height}px`};
    `}

    border-radius: 4px;
    background: ${Theme.colors.gray_500};
    color: ${Theme.colors.white};
    font-weight: 800;
    font-size: ${({ $fontSize }) => $fontSize}px;
    line-height: 0;
    user-select: none;
`;

export const StyledImg = styled.img<{ $width: number; $height: number }>`
    max-width: ${({ $width }) => $width}px;
    max-height: ${({ $height }) => $height}px;
    border-radius: 4px;
`;
export interface SearchItemImageBaseProps {
    entity: SearchWidgetResponseItem;
    cardsDensityConfiguration: SearchWidgetCardsDensityConfiguration;
    imagePlaceholderMode: SearchWidgetImagePlaceholderMode | undefined;
}

const SearchItemImageBase: React.FC<SearchItemImageBaseProps> = ({
    entity,
    cardsDensityConfiguration,
    imagePlaceholderMode = SEARCH_WIDGET_DEFAULT_IMAGE_PLACEHOLDER_MODE,
}) => {
    const imageWidth = cardsDensityConfiguration.image.width;
    const imageHeight = cardsDensityConfiguration.image.height;
    const initialsFontSize = cardsDensityConfiguration.image.initialsFontSize;

    switch (imagePlaceholderMode) {
        case SearchWidgetImagePlaceholderMode.NONE:
            return <></>;
        case SearchWidgetImagePlaceholderMode.INITIALS:
            const initials = (entity.title || '')
                .split(' ')
                .slice(0, 2)
                .filter(Boolean)
                .map((letter) => letter.charAt(0).toUpperCase())
                .join('');
            return (
                <ImageContainer $width={imageWidth}>
                    <InitialsContainer $height={imageHeight} $fontSize={initialsFontSize}>
                        {initials}
                    </InitialsContainer>
                </ImageContainer>
            );
        case SearchWidgetImagePlaceholderMode.IMAGE:
            return <SearchWidgetLoadingImage cardsDensityConfiguration={cardsDensityConfiguration} />;
    }
};

export default SearchItemImageBase;
