import React, { useMemo } from 'react';

import { RegexPart } from './RegexPart';
import { RegexTooltipZone } from './RegexTooltipZone';
import { RegexViewerBlock } from './RegexViewerBlock';
import { RegexMatchedGroupPart } from '../entities/RegexMatchedGroupPart';
import { TreeBuilder } from '../entities/TreeBuilder';
import { TreeNode } from '../entities/TreeNode';

interface Props {
    matches: RegexMatchedGroupPart[][];
    evaluatedInput: string;
    unevaluatedInput: string;
    loading: boolean;
    selectedGroup: { match: number; group: number | undefined } | undefined;
}

/**
 * A react component for showing the regex result
 * over the input value
 */
const RegexInputResult: React.FC<React.PropsWithChildren<Props>> = ({
    children,
    matches,
    loading,
    evaluatedInput,
    unevaluatedInput,
    selectedGroup,
}) => {
    const rootGroup = useMemo(() => {
        // Create the root tree node (the entire input string)
        const rootGroup = new TreeNode(
            new RegexMatchedGroupPart('root group', evaluatedInput, 0, evaluatedInput.length, 0, undefined),
        );

        // Create the tree for each of the matches from the
        // regex and mark as children of the root tree node
        rootGroup.children = matches.map((match) => {
            const [root, ...groups] = match;
            return TreeBuilder.createTreeFromItemsList(root!, groups);
        });

        return rootGroup;
    }, [matches, evaluatedInput]);

    return (
        <RegexViewerBlock
            input={children}
            title="Input"
            evaluated={evaluatedInput}
            unevaluated={unevaluatedInput}
            loading={loading}
            className="regex-input-result"
        >
            <RegexTooltipZone>
                {(setTooltip) => (
                    <RegexPart part={rootGroup} selectedPart={selectedGroup} setTooltip={setTooltip} rootPart />
                )}
            </RegexTooltipZone>
        </RegexViewerBlock>
    );
};

export { RegexInputResult };
