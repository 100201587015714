import template from './tnkMetricsBar.template.html?angularjs';

function tnkMetricsBar() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            // string representing the type of component (for analytics)
            type: '@',

            fieldDefinitionTargetType: '@', // Represents the target type of the fields to display in the bar.

            // allow or disallow interactions
            viewOnly: '=',

            isDraft: '<',

            groupId: '=',
            workflowVersionId: '@',

            // an object mapping field id to metrics (field values and stats)
            fieldsMap: '<',

            // an object mapping draft field id to metrics (field values and stats)
            fieldsMapDraft: '<',

            // Called when the user clicks to hide the key metrics
            toggleKeyMetrics: '&',
            // the target object the field is associated with (initiative or group)
            target: '<',

            // Boolean value, should the display help the user associate the field if no association found? (connect manually)
            fieldAssociationAssistanceEnabled: '<',

            // Boolean value, true to hide the add metric button' false otherwise
            hideAdd: '<',

            // Boolean value, true to hide the hidden fields false to show them
            hideHiddenFields: '<',

            // Called when the user requests to add metrics, (via click on button)
            onUserAddMetric: '&',

            // Called when the user requests to add instance of a field that doesn't exist, (via click on button)
            onUserAddFieldInstance: '&',

            // Called when the user requests to edit the metrics settings, (via click on button)
            onUserEditMetricSettings: '&',

            // Called when the user requests to fix an integration
            onUserFixIntegration: '&',

            // Called when the user requests to filter the list by the field def query (only for tracks aggregation)
            onUserApplyTracksFilter: '&',

            listMode: '=',
            // String value, represents the workflowVersionType
            environment: '<',
        },
        template,
        controller: 'MetricsBarCtrl',
    };
}

export default angular.module('tonkean.app').directive('tnkMetricsBar', tnkMetricsBar);
