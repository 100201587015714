import { FieldDefinitionDisplayFormat, FormType } from '@tonkean/tonkean-entities';
import { lateConstructController } from '@tonkean/angular-components';
import { getFormPrimaryColorOptions, getFormSecondaryColorOptions } from '@tonkean/constants';

/* @ngInject */
const WorkerFormEditorCtrl = function (
    $scope,
    $q,
    modal,
    utils,
    customFieldsManager,
    formManager,
    customTriggerManager,
    projectManager,
) {
    const ctrl = this;

    $scope.pm = projectManager;

    $scope.data = {
        // Context params
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        formId: ctrl.formId,
        formTypeToCreate: ctrl.formTypeToCreate,

        // Form Configuration to build
        form: null,
        serverVersionForm: null,

        // Loaders
        loadingForm: false,
        formIsUpdating: false,

        // Fields Holders
        formFieldsWrappers: [],
        formSpecialFieldsWrappers: [],
        formCustomFieldsWrappers: [],
        defaultFields: [],
        fixedFields: [],
        collectInnerItemsFieldsIds: [],

        // Item Hierarchy
        itemsHierarchyFilterOptionsMap: {
            groupItems: {
                apiName: 'GROUP_ITEMS',
                displayName: 'All module items',
            },
            innerItems: {
                apiName: 'INNER_ITEMS',
                displayName: 'Only inner items',
            },
            singleItem: {
                apiName: 'SINGLE_ITEM',
                displayName: 'Root item',
            },
        },
        itemsHierarchyFilterOptions: null,
        selectedItemsHierarchyFilter: null,
        hideInnerItemOf: false,
        addUnderInitiativeId: null,
        itemsHierarchyApiNameToConfigurationMap: null,
        autoSubmitForm: false,

        // Errors
        errorDisplayNameLengthTooLong: false,
        errorInvalidSubmitButtonLabel: null,
        errorDisplayNameRequired: false,
        errorZeroFieldsSelected: false,
        duplicateSlackCommandError: null,
        duplicateDisplayNameError: null,

        // Limits on buttons length
        formDisplayNameMaxLength: 100,
        slackCommandMaxLength: 100,
        slackSubmitFormButtonLabelMaxLength: 24,

        primaryColor: null,
        primaryColorOptions: getFormPrimaryColorOptions(),
        secondaryColor: null,
        secondaryColorOptions: getFormSecondaryColorOptions(),

        advancedSettingsOpen: !!ctrl.advancedSettingsOpen,

        isInFormBuilder: ctrl.isInFormBuilder,
    };

    $scope.initForm = function (form, isANewForm) {
        wrapFormFields();
        initDefaultFields(form, isANewForm);

        // If we have the old description field but not the new one, do a migration
        if (form.description && !form.descriptionExpression) {
            form.descriptionExpression = {
                originalExpression: form.description,
                evaluatedExpression: form.description,
            };
        }
    };

    function loadForm() {
        $scope.data.itemsHierarchyFilterOptions = [];
        $scope.data.itemsHierarchyApiNameToConfigurationMap = {};

        for (const key in $scope.data.itemsHierarchyFilterOptionsMap) {
            if ($scope.data.itemsHierarchyFilterOptionsMap.hasOwnProperty(key)) {
                const currentOption = $scope.data.itemsHierarchyFilterOptionsMap[key];
                $scope.data.itemsHierarchyFilterOptions.push(currentOption);
                $scope.data.itemsHierarchyApiNameToConfigurationMap[currentOption.apiName] = currentOption;
            }
        }
        $scope.data.selectedItemsHierarchyFilter = $scope.data.itemsHierarchyFilterOptionsMap.singleItem;

        if ($scope.data.formId) {
            $scope.data.loadingForm = true;
            formManager
                .getWorkerForm($scope.data.workflowVersionId, $scope.data.formId)
                .then((form) => {
                    $scope.data.form = angular.copy(form);
                    $scope.data.serverVersionForm = angular.copy(form);

                    if (!$scope.data.form.formQuestionType && $scope.data.form.formType === 'UPDATE') {
                        $scope.data.form.formQuestionType = 'UPDATE_FIELDS';
                    }

                    $scope.data.selectedItemsHierarchyFilter =
                        $scope.data.itemsHierarchyApiNameToConfigurationMap[
                            $scope.data.form.definition.itemsHierarchyFilter
                        ];
                    $scope.data.autoSubmitForm = !!$scope.data.form.definition.autoSubmitForm;

                    $scope.initForm($scope.data.form, false);

                    $scope.data.formSpecialFieldsWrappers = $scope.data.formFieldsWrappers.filter(
                        (f) => f.fieldDefinition?.isSpecialField,
                    ) || [{}];
                    $scope.data.formCustomFieldsWrappers = $scope.data.formFieldsWrappers.filter(
                        (f) => f.fieldDefinition && !f.fieldDefinition.isSpecialField,
                    );

                    if (ctrl.onFormChanged) {
                        ctrl.onFormChanged({ form: $scope.data.form });
                    }
                })
                .catch(() => {
                    $scope.data.form = null;
                })
                .finally(() => {
                    $scope.data.loadingForm = false;
                });
        } else {
            $scope.data.form = {
                displayName: '',
                disabled: false,
                definition: {
                    fields: [],
                },
                formType: $scope.data.formTypeToCreate,
                formQuestionType: 'UPDATE_FIELDS',
            };

            $scope.initForm($scope.data.form, true);
        }

        // Generate the fields ids for the preview
        registerCollectItemsPreview();
    }

    if (ctrl.setFormFetchTrigger) {
        ctrl.setFormFetchTrigger(loadForm);
    }

    ctrl.$onInit = loadForm;

    /** `
     * Filter function to filter the special fields which are editable from the live report component such as title, owner etc
     * @param fields - the array of fields to filter the special fields from
     * @returns {*}
     */
    $scope.editableSpecialFields = function (fields) {
        // Fields which are not editable from the live report component
        const notEditableFields = new Set(['TNK_UPDATE_TEXT', 'TNK_ETA', 'TNK_OTHER', 'TNK_DESCRIPTION']);
        return fields.filter((field) => field.isSpecialField && !notEditableFields.has(field.id));
    };

    /**
     * Filter function to filter out special fields like title, owner etc
     * @param fields - the array of fields to filter
     * @returns {boolean}
     */
    $scope.noSpecialFields = function (fields) {
        return fields.filter((field) => !field.isSpecialField || field.id === 'TNK_OTHER');
    };

    /**
     * Filter function to filter out fields in file format and matched entities (not relevant in CREATE_FORM type)
     * @param fields - the array to filter the file fields from
     * @returns {boolean}
     */
    $scope.noFileFormatOrMatchedEntityFields = function (fields) {
        // Filter matched entities from selector for create form
        if ($scope.data.form.formType !== FormType.UPDATE) {
            fields = fields.filter((field) => field?.idRelationField !== true);
        }

        if ($scope.data.form.formType == FormType.CREATE) {
            fields = fields.filter((field) => !(field.displayFormat === FieldDefinitionDisplayFormat.FILE));
        }

        return fields;
    };

    /**
     * A function which creates a callback to add a field to the relevant fields array
     * @param fields - the array to add to
     * @returns {function(...[*]=)}
     */
    $scope.onFieldAdd = function (fields) {
        return (field) => {
            fields.push(field);
            $scope.submitForm();
        };
    };

    /**
     * A function which creates a callback to remove a field from the relevant fields array
     * @param fields - the array to remove from
     * @returns {function(...[*]=)}
     */
    $scope.onFieldRemove = function (fields) {
        return (field) => {
            const index = fields.findIndex(
                (fieldWrapper) => fieldWrapper?.fieldDefinition?.id === field?.fieldDefinition?.id,
            );
            if (index !== -1) {
                fields.splice(index, 1);
            }
            $scope.submitForm();
        };
    };

    /**
     * A function which creates a callback to change a field in the relevant fields array
     * @param fields - the array to change
     * @returns {function(...[*]=)}
     */
    $scope.onFieldChange = function (fields) {
        return (field) => {
            const index = fields.findIndex(
                (fieldWrapper) => fieldWrapper?.fieldDefinition?.id === field?.fieldDefinition?.id,
            );
            if (index !== -1) {
                fields.splice(index, 1, field);
            }
            $scope.submitForm();
        };
    };

    $scope.onAutoSubmitToggled = function (isActive) {
        $scope.data.autoSubmitForm = isActive;
    };

    /**
     * Fired when an item is moved from its old position within a drag-and-drop event.
     * @param fields - the array to change
     * @returns {function(...[*]=)}
     */
    $scope.onFormFieldMoved = function (fields) {
        return (oldIndex, newIndex) => {
            // Remove the old item and save it so we can add it to its new position.
            const movedItem = fields.splice(oldIndex, 1)[0];

            // Place the item in its new position.
            fields.splice(newIndex, 0, movedItem);
            $scope.submitForm();
        };
    };

    /**
     * Fired when a field definition is changed
     * @param fields - the array to change
     * @returns {function(...[*]=)}
     */
    $scope.onFormFieldReplace = function (fields) {
        return (oldField, newFieldDefinition) => {
            const field = fields.find(
                (fieldWrapper) => fieldWrapper.fieldDefinition.id === oldField.fieldDefinition.id,
            );
            field.fieldDefinition = newFieldDefinition;
            field.fieldDefinitionIdentifier = newFieldDefinition.id;
            $scope.submitForm();
        };
    };

    /**
     * Handles changing the form display name
     */
    $scope.onFormDisplayNameChanged = function () {
        if ($scope.data.form.displayName !== '') {
            $scope.data.errorDisplayNameRequired = false;
        }
    };

    /**
     * Handles changing the question of the form
     */
    $scope.onFormQuestionChanged = function () {
        $scope.submitForm();
    };

    $scope.onFormDescriptionExpressionChanged = function (expression) {
        $scope.data.form.descriptionExpression = expression;
    };

    $scope.onFormLogoChanged = function () {
        if (validateUrl($scope.data.form.definition.logoUrl)) {
            $scope.data.errorLogoUrlStartsWithHttp = false;
        }
    };

    /**
     * Handles the form submit
     */
    $scope.submitForm = function () {
        validateForm();

        $scope.data.errorSavingForm = null;

        $scope.data.formIsUpdating = true;

        if (!$scope.data.form) {
            $scope.data.form = {
                definition: {},
            };
        }

        $scope.data.form.definition.fields = buildFieldDefinitions();

        // There was a bug where this field was being saved on the definition and it could mess up the definition because it made it too long.
        // So if we are updating the definition we should remove this property because it should never be in the db
        if ($scope.data.form.definition.wrappedFields) {
            delete $scope.data.form.definition.wrappedFields;
        }

        ctrl.onFieldsChange($scope.data.form.definition.fields);
        ctrl.onFormQuestionChange($scope.data.form.definition.formQuestion);
        $scope.data.formIsUpdating = false;
    };

    /**
     * Handles selecting initiative hierarchy
     * @param initiative
     */
    $scope.onInitiativeSelected = function (initiative) {
        $scope.data.addUnderInitiativeId = initiative.id;
    };

    /**
     * Toggle the form question type (in update form)
     * @param type
     */
    $scope.selectFormType = function (type) {
        let confirmationPromise = $q.resolve();

        if (
            $scope.data.serverVersionForm?.formQuestionType === 'COLLECT_INNER_ITEMS' &&
            type === 'UPDATE_FIELDS' &&
            hasAnySendFormTriggersWithMonitorItems()
        ) {
            confirmationPromise = modal.openQuestionConfirmModal({
                controller: 'QuestionConfirmModalCtrl',
                windowClass: 'mod-warning',
                resolve: {
                    questionConfirmModalData() {
                        return {
                            title: 'Change form type',
                            body: 'Please remove all Monitor Items action blocks before changing form type.',
                            cancelLabel: 'Close',
                        };
                    },
                },
            }).result;
        }

        confirmationPromise
            .then(() => {
                $scope.data.form.formQuestionType = type;
            })
            .catch(() => ($scope.data.form.formQuestionType = $scope.data.serverVersionForm.formQuestionType))
            .finally(() => initDefaultFields($scope.data.form));
    };

    /**
     * Sort the special fields by their order
     * @param fields
     * @returns {*}
     */
    $scope.sortSpecialFields = function (fields) {
        return fields.sort((first, second) =>
            $scope.specialFieldsComparator(first?.fieldDefinition, second?.fieldDefinition),
        );
    };

    $scope.specialFieldsComparator = function (first, second) {
        // Defaulting to a high number if field definition does not exist
        const highNumber = 1000;

        const specialFieldOrder = {
            TNK_TITLE: 1,
            TNK_TAGS: 2,
            TNK_DUE_DATE: 3,
            TNK_STAGE: 4,
            TNK_OWNER_ID: 5,
        };

        return (specialFieldOrder[first?.id] || highNumber) - (specialFieldOrder[second?.id] || highNumber);
    };

    $scope.onPrimaryColorSelected = function (color) {
        $scope.data.primaryColor = color;
    };

    $scope.onSecondaryColorSelected = function (color) {
        $scope.data.secondaryColor = color;
    };

    /**
     * Get default and fixed fields for field selection
     */
    function initDefaultFields(form, isANewForm) {
        $scope.data.defaultFields =
            (form.formType === 'CREATE' || form.formQuestionType === 'COLLECT_INNER_ITEMS') &&
            isANewForm &&
            !$scope.data.form?.definition?.fields?.length
                ? ['TNK_TITLE']
                : [];
        $scope.data.fixedFields = [];
        $scope.data.primaryColor = form?.definition?.primaryColor || $scope.data.primaryColorOptions.default;
        $scope.data.secondaryColor = form?.definition?.secondaryColor || $scope.data.secondaryColorOptions.default;
    }

    /**
     * Build a form fields from a given field definition with more params relevant to the form configuration
     */
    function wrapFormFields() {
        $scope.data.formFieldsWrappers = $scope.data.form.definition.fields.map((formField) => {
            const fieldDefinition = customFieldsManager.getFieldDefinitionFromCachesById(
                $scope.data.workflowVersionId,
                formField.fieldDefinitionIdentifier,
            );
            return {
                fieldDefinition,
                ...formField,
                showTitle: formField.showTitle ?? true,
                viewOnly: formField.viewOnly ?? false,
                validation: formField.validation ?? [],
            };
        });
    }

    /**
     *  Check if at least one field is not a dummy.
     */
    function isAnyFieldNotDummy(fields = $scope.data.formFieldsWrappers) {
        return !utils.anyMatch(
            fields.filter((field) => utils.isNotNullOrUndefined(field)),
            (field) => field.fieldDefinition,
        );
    }

    /**
     * Validate the all form fields are present
     * @returns {boolean}
     */
    function validateForm() {
        let isValid = true;
        $scope.data.errorZeroFieldsSelected = false;

        // At least one field is a must
        if ($scope.data.form.formQuestionType === 'COLLECT_INNER_ITEMS') {
            if (
                (isAnyFieldNotDummy($scope.data.formCustomFieldsWrappers) &&
                    isAnyFieldNotDummy($scope.data.formSpecialFieldsWrappers)) ||
                $scope.data.formCustomFieldsWrappers.length + $scope.data.formSpecialFieldsWrappers.length === 0
            ) {
                $scope.data.errorZeroFieldsSelected = true;
                isValid = false;
            }
        } else if ($scope.data.formFieldsWrappers.length === 0 || isAnyFieldNotDummy($scope.data.formFieldsWrappers)) {
            $scope.data.errorZeroFieldsSelected = true;
            isValid = false;
        }

        return isValid;
    }

    /**
     * When the fields are changes in the inner collect form, recalculate the ids for the preview
     */
    function registerCollectItemsPreview() {
        $scope.$watch(
            'data.formCustomFieldsWrappers',
            (newValue) => {
                $scope.data.collectInnerItemsFieldsIds = newValue
                    .concat($scope.data.formSpecialFieldsWrappers)
                    .map((f) => f.fieldDefinition.id);
            },
            true,
        );

        $scope.$watch(
            'data.formSpecialFieldsWrappers',
            (newValue) => {
                $scope.data.collectInnerItemsFieldsIds = newValue
                    .concat($scope.data.formCustomFieldsWrappers)
                    .map((f) => f.fieldDefinition.id);
            },
            true,
        );
    }

    /**
     * Builds the field definitions from the given arrays based on the formQuestionType
     */
    function buildFieldDefinitions() {
        let fieldDefinitions = [];
        if ($scope.data.form.formQuestionType === 'COLLECT_INNER_ITEMS') {
            fieldDefinitions = [...$scope.data.formSpecialFieldsWrappers, ...$scope.data.formCustomFieldsWrappers];
        } else {
            fieldDefinitions = $scope.data.formFieldsWrappers;
        }

        return fieldDefinitions
            .filter((wrapper) => wrapper?.fieldDefinition)
            .map((wrapper) => {
                return {
                    fieldDefinitionIdentifier: wrapper.fieldDefinition.id,
                    ...wrapper,
                };
            });
    }

    function hasAnySendFormTriggersWithMonitorItems() {
        const logicIdToParentMap = customTriggerManager.getLogicIdToParentMap($scope.data.workflowVersionId);
        const monitorTracksCustomTriggers = customTriggerManager.getCustomTriggersOfTypeInWorkflowVersionFromCache(
            $scope.data.workflowVersionId,
            'MONITOR_TRACKS',
        );

        return !!monitorTracksCustomTriggers.filter(
            (singleMonitor) =>
                (logicIdToParentMap[singleMonitor.id].node.customTriggerType === 'SEND_FORM' ||
                    logicIdToParentMap[singleMonitor.id].node.customTriggerType === 'SEND_FORM_ANSWERED') &&
                logicIdToParentMap[singleMonitor.id].node?.customTriggerActions[0]?.customTriggerActionDefinition
                    ?.formId === $scope.data.form.id,
        )?.length;
    }

    function setDisplayErrorsBySubmitFormErrorsCodes(error) {
        if (error.status === 409 && error.data?.error?.message?.includes('Slack command')) {
            $scope.data.duplicateSlackCommandError = error.data.error.message;
        }
        if (error.status === 409 && error.data?.error?.message?.includes('Display name')) {
            $scope.data.duplicateDisplayNameError = error.data.error.message;
        }
    }

    function validateUrl(url) {
        if (!url) {
            return true;
        }

        const validUrlExpression = /https?:\/\/(www\.)?[-\w@:%.+~#=]{1,256}\.[a-z\d()]{1,6}\b([-\w()@:%+.~#?&/=]*) ?/gi;
        const regex = new RegExp(validUrlExpression);

        return url.match(regex) && !url.startsWith('http:');
    }

    ctrl.$onChanges = function (changesObj) {
        if (changesObj.formId) {
            $scope.data.formId = changesObj.formId.currentValue;
            loadForm();
        }
        if (changesObj.groupId) {
            $scope.data.groupId = changesObj.groupId.currentValue;
        }
        if (changesObj.formTypeToCreate) {
            $scope.data.formTypeToCreate = changesObj.formTypeToCreate.currentValue;
        }
        if (changesObj.workflowVersionId) {
            $scope.data.workflowVersionId = changesObj.workflowVersionId.currentValue;
        }
        if (changesObj.isInFormBuilder) {
            $scope.data.isInFormBuilder = changesObj.isInFormBuilder.currentValue;
        }
        if (changesObj.form) {
            let shouldInitDefaultFields = false;
            if (changesObj.form.currentValue.formQuestionType !== $scope.data.form?.formQuestionType) {
                shouldInitDefaultFields = true;
            }

            $scope.data.form = changesObj.form.currentValue;

            if (shouldInitDefaultFields) {
                initDefaultFields($scope.data.form);
            }
        }
        if (changesObj.setFormFetchTrigger && changesObj.setFormFetchTrigger.currentValue) {
            ctrl.setFormFetchTrigger = changesObj.setFormFetchTrigger.currentValue;
            ctrl.setFormFetchTrigger(loadForm);
        }
    };
};

export default angular.module('tonkean.app').controller('WorkerFormEditorCtrl', lateConstructController(WorkerFormEditorCtrl));
