import { useAngularService } from 'angulareact';
import { useEffect, useMemo, useState } from 'react';

import type MatchedEntityWidgetIcon from '../entities/MatchedEntityWidgetIcon';

import { useLazyAsyncMethod } from '@tonkean/angular-hooks';
import type {
    CustomTrigger,
    FieldDefinition,
    IntegrationType,
    ItemInterfaceWidgetConfiguration,
    TonkeanId,
    WidgetBase,
} from '@tonkean/tonkean-entities';
import { CustomTriggerType, TonkeanType, tonkeanTypeToIdentifyingPrefixMap } from '@tonkean/tonkean-entities';

function useGetMatchedEntityWidgetIcon(
    widget: WidgetBase<ItemInterfaceWidgetConfiguration>,
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION | TonkeanType.ENTITY_VERSION> | undefined,
): MatchedEntityWidgetIcon {
    const customTriggerManager = useAngularService('customTriggerManager');
    const customFieldsManager = useAngularService('customFieldsManager');
    const utils = useAngularService('utils');
    const [{ data: allFieldDefinitions, loading: loadingFields }, getFieldDefinitions] = useLazyAsyncMethod(
        customFieldsManager,
        'getFieldDefinitions',
    );

    useEffect(() => {
        if (workflowVersionId?.startsWith(tonkeanTypeToIdentifyingPrefixMap[TonkeanType.WORKFLOW_VERSION])) {
            getFieldDefinitions(workflowVersionId as TonkeanId<TonkeanType.WORKFLOW_VERSION>);
        }
    }, [getFieldDefinitions, workflowVersionId]);

    const fieldDefinitionMap = useMemo(() => {
        if (widget.configuration['showIcon'] && allFieldDefinitions) {
            return utils.createMapFromArray(allFieldDefinitions, 'id');
        }
    }, [allFieldDefinitions, utils, widget.configuration]);

    const shownFieldKeys = useMemo<string[]>(() => {
        const keys: string[] = [];
        if (widget?.configuration?.['fields']) {
            Object.keys(widget?.configuration?.['fields']).forEach((key) => {
                // allowing undefined for backward compatibility
                if (
                    widget.configuration['fields'][key]?.isShown === undefined ||
                    widget.configuration['fields'][key]?.isShown
                ) {
                    keys.push(key);
                }
            });
        }
        return keys;
    }, [widget.configuration]);

    const integrationTypesForIcon = useMemo(() => {
        if (fieldDefinitionMap) {
            const integrationTypes: Set<IntegrationType> = new Set();
            let hasFieldsWithoutIcon: boolean = false;
            shownFieldKeys.forEach((fieldId) => {
                const foundFieldDefinition = fieldDefinitionMap[fieldId];
                if (foundFieldDefinition?.projectIntegration?.integrationType) {
                    integrationTypes.add(foundFieldDefinition?.projectIntegration?.integrationType);
                } else if (!foundFieldDefinition?.linkedCustomTrigger?.id) {
                    hasFieldsWithoutIcon = true;
                }
            });
            return {
                integrationTypes: [...integrationTypes],
                hasFieldsWithoutIcon,
            };
        }
    }, [fieldDefinitionMap, shownFieldKeys]);

    const [customTriggerTypes, setCustomTriggerTypes] = useState<CustomTriggerType[]>([]);

    useEffect(() => {
        if (fieldDefinitionMap && !integrationTypesForIcon?.hasFieldsWithoutIcon) {
            const foundCustomTriggerIds = new Set<TonkeanId<TonkeanType.CUSTOM_TRIGGER>>();

            shownFieldKeys.forEach((fieldId) => {
                const foundFieldDefinition: FieldDefinition = fieldDefinitionMap[fieldId];
                if (foundFieldDefinition.linkedCustomTrigger?.id) {
                    foundCustomTriggerIds.add(foundFieldDefinition.linkedCustomTrigger?.id);
                }
            });
            if (foundCustomTriggerIds.size > 0) {
                const customTriggerTypesSet = new Set<CustomTriggerType>();
                customTriggerManager
                    .getCustomTriggerForCustomTriggerIdsFromCacheOrFallbackServer(workflowVersionId, [
                        ...foundCustomTriggerIds,
                    ])
                    .then((customTriggers: CustomTrigger[] | undefined) => {
                        if (customTriggers) {
                            customTriggers.forEach((customTrigger) => {
                                if (
                                    customTrigger.customTriggerType === CustomTriggerType.PERFORM_INTEGRATION_ACTION &&
                                    customTrigger.integrationType &&
                                    integrationTypesForIcon?.integrationTypes?.includes(customTrigger.integrationType)
                                ) {
                                    // if there is an integration type identical to the custom trigger integration type we ignore it.
                                    return;
                                }

                                customTriggerTypesSet.add(customTrigger.customTriggerType);
                            });
                        }
                        if (customTriggerTypesSet.size === 1) {
                            setCustomTriggerTypes([...customTriggerTypesSet]);
                        }
                    });
            }
            setCustomTriggerTypes([]);
        }
    }, [
        customTriggerManager,
        fieldDefinitionMap,
        integrationTypesForIcon?.hasFieldsWithoutIcon,
        integrationTypesForIcon?.integrationTypes,
        shownFieldKeys,
        workflowVersionId,
    ]);

    const widgetHasASingleIcon = useMemo<boolean>(() => {
        const hasSingleCustomTriggerType: boolean =
            customTriggerTypes.length === 1 && integrationTypesForIcon?.integrationTypes?.length === 0;
        const hasSingleIntegrationType: boolean =
            customTriggerTypes.length === 0 && integrationTypesForIcon?.integrationTypes?.length === 1;

        return (
            !integrationTypesForIcon?.hasFieldsWithoutIcon && (hasSingleCustomTriggerType || hasSingleIntegrationType)
        );
    }, [
        customTriggerTypes.length,
        integrationTypesForIcon?.hasFieldsWithoutIcon,
        integrationTypesForIcon?.integrationTypes?.length,
    ]);

    return { widgetHasASingleIcon, integrationTypesForIcon, customTriggerTypes };
}

export default useGetMatchedEntityWidgetIcon;
