import React, { useMemo } from 'react';

import RichTextWidgetView from './RichTextWidgetView';
import InterfaceHeaderCallToActionMenu from '../../../SystemInterface/components/ItemInterface/InterfaceHeaderCallToActionMenu';
import type { ItemWidgetProps } from '../../../WidgetModule';
import { useGetWorkflowVersionFieldsAndBasicFields } from '../../hooks';
import type { ItemInterfaceRichTextWidgetConfiguration } from '../RichTextWidgetConfiguration';

import { coreEditorTonkeanVariablePlugin } from '@tonkean/core-editor';
import { HTMLEditorFullPluginsList, HTMLEditorToolbarSeparatorPlugin } from '@tonkean/editor';
import type { TonkeanExpressionEditorContextType } from '@tonkean/infrastructure';
import { useItemInterfaceContext } from '@tonkean/infrastructure';
import { TonkeanExpressionEditorContext } from '@tonkean/infrastructure';
import { type FieldDefinition, useItemInterfaceHeaderThemeConfiguration } from '@tonkean/tonkean-entities';
import { EMPTY_ARRAY } from '@tonkean/utils';

const htmlEditorPlugins = [
    coreEditorTonkeanVariablePlugin,
    HTMLEditorToolbarSeparatorPlugin,
    ...HTMLEditorFullPluginsList,
];

const RichTextWidget: React.FC<ItemWidgetProps<ItemInterfaceRichTextWidgetConfiguration>> = ({
    widget,
    permission,
    workflowVersionId,
}) => {
    const {
        initiative,
        workflowVersion,
        itemInterface,
        project,
        reloadInitiativeFromServer,
        actionsHiddenLiveDisplay,
        actionsDisabledLiveDisplay,
    } = useItemInterfaceContext();

    const themeConfiguration = useItemInterfaceHeaderThemeConfiguration(itemInterface, project);

    const { allFieldDefinitions } = useGetWorkflowVersionFieldsAndBasicFields(workflowVersionId, [
        'ITEM_INTERFACE_FIELDS_WIDGET',
    ]);

    const contextFields: Record<string, FieldDefinition> = useMemo(() => {
        let value: Record<string, FieldDefinition> = {};
        if (allFieldDefinitions) {
            value = Object.fromEntries(
                allFieldDefinitions.map((fieldDefinition) => [fieldDefinition.id, fieldDefinition]),
            );
        }

        return value;
    }, [allFieldDefinitions]);

    const contextValue: TonkeanExpressionEditorContextType = useMemo(() => {
        return {
            initiative,
            workflowVersion,
            fields: contextFields,
        };
    }, [contextFields, initiative, workflowVersion]);

    const liveDisplay = useMemo(
        () => ({
            actionsLiveDisplayKeyPrefix: widget.id,
            actionsDisabledLiveDisplay,
            actionsHiddenLiveDisplay,
        }),
        [actionsDisabledLiveDisplay, actionsHiddenLiveDisplay, widget.id],
    );

    return (
        <TonkeanExpressionEditorContext.Provider value={contextValue}>
            <RichTextWidgetView
                widget={widget}
                permission={permission}
                editorPlugins={htmlEditorPlugins}
                headerActions={
                    <InterfaceHeaderCallToActionMenu
                        initiative={initiative}
                        actions={widget.configuration.actions || EMPTY_ARRAY}
                        workflowVersionType={itemInterface.workflowVersionType}
                        primaryColor={themeConfiguration.headerBackgroundColor}
                        itemInterface={itemInterface}
                        workflowVersion={workflowVersion}
                        projectId={itemInterface.projectId}
                        permission={permission}
                        reloadInitiativeFromServer={reloadInitiativeFromServer}
                        liveDisplay={liveDisplay}
                        showActionAsButton={!widget.configuration.showActionsAsMenu}
                        savingIndicatorPosition="left"
                        useAlternativeColors={widget.configuration.useAlternativeColors}
                    />
                }
            />
        </TonkeanExpressionEditorContext.Provider>
    );
};

export default RichTextWidget;
