import template from './enterpriseOverview.template.html?angularjs';

/**
 * Component to enterprise overview.
 */
export default angular.module('tonkean.app').component('tnkEnterpriseOverview', {
    bindings: {},
    template,
    controller: 'EnterpriseOverviewCtrl',
});
