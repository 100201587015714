import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import ProjectIntegrationEntityBodyAndHeadersLayout from './ProjectIntegrationEntityBodyAndHeadersLayout';
import ProjectIntegrationEntityHandleTestRunResponseView from './ProjectIntegrationEntityHandleTestRunResponseView';
import { ReactComponent as SpaceShip } from '../../../../../images/icons/spaceship-large.svg';
import ProjectIntegrationActionTestRunSelector from '../../../ProjectIntegrationPageModule/components/ProjectIntegrationPageActionsPage/ProjectIntegrationCustomActionModal/components/ProjectIntegrationActionTestRunSelector/ProjectIntegrationActionTestRunSelector';
import projectIntegrationEntityCollectPageInputState from '../../states/projectIntegrationEntityCollectPageInputState';

import { EMPTY_ARRAY } from '@tonkean/angular-to-react-components';
import { AnimatedSwitch, AnimatedSwitchItem, H4, StateMessage, Tab, Tabs, Tooltip } from '@tonkean/infrastructure';
import { EntityResponseHandlingDefinitionType } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationActionTestRun } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationEntity } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';

const StyledAnimatedSwitchItem = styled(AnimatedSwitchItem)`
    flex-grow: 1;
    overflow: hidden;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    background-color: ${Theme.colors.gray_100};
`;

const ContentWrapper = styled.div`
    padding: 6px 16px 16px 16px;
    height: 100%;
`;

const TabsWrapper = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1;
`;

const StyledStateMessage = styled(StateMessage)`
    height: 75%;
`;

const TestPickerWrapper = styled.div`
    padding: 20px 16px 15px 16px;
`;

const HeaderText = styled(H4)`
    margin: 4px 0 8px 0;
`;

enum PreviewTabType {
    REQUEST = 'REQUEST',
    RESPONSE = 'RESPONSE',
    HANDLE_RESPONSE = 'HANDLE_RESPONSE',
}

interface Props {
    entity: ProjectIntegrationEntity;
    testRuns: ProjectIntegrationActionTestRun[] | undefined;
    isLoadingTestRuns: boolean;
}

const ProjectIntegrationEntityContinuousCollectRightLayout: React.FC<Props> = ({
    entity,
    testRuns,
    isLoadingTestRuns,
}) => {
    // State for the selected test run Ids.
    const [{ selectedTestRunIds }, setCollectInputState] = useRecoilState(
        projectIntegrationEntityCollectPageInputState,
    );

    // State for the selected tab.
    const [tab, setTab] = useState<PreviewTabType>(PreviewTabType.RESPONSE);

    // Memo for enabling/disabling handle response tab.
    const isHandleResponseConfigured = useMemo(() => {
        const selectedTestRun =
            selectedTestRunIds?.length > 0 ? testRuns?.find((testRun) => selectedTestRunIds[0] === testRun.id) : null;

        if (
            selectedTestRun?.projectIntegrationAction?.responseHandlingDefinition?.responseHandlingType ===
            EntityResponseHandlingDefinitionType.ENTERPRISE_COMPONENT_VARIABLE_VALUE_ASSIGNMENT
        ) {
            return true;
        }

        return selectedTestRun?.projectIntegrationAction?.responseHandlingDefinition?.['validationStatus']
            ?.isValid as boolean;
    }, [testRuns, selectedTestRunIds]);

    // Change selected tab if the current tab became disabled.
    useEffect(() => {
        if (!isHandleResponseConfigured && tab === PreviewTabType.HANDLE_RESPONSE) {
            setTab(PreviewTabType.REQUEST);
        }
    }, [isHandleResponseConfigured, tab, setTab]);

    return (
        <Wrapper>
            <TestPickerWrapper>
                <HeaderText $bold>Preview Previous Collects Tests</HeaderText>
                <ProjectIntegrationActionTestRunSelector
                    isLoading={isLoadingTestRuns}
                    testRuns={testRuns || EMPTY_ARRAY}
                    onTestRunSelected={(testRunIds) => {
                        setCollectInputState((prevState) => ({ ...prevState, selectedTestRunIds: testRunIds }));
                    }}
                    selectedTestRunIds={selectedTestRunIds}
                    customTestName={`${entity.displayName} Collect Test -`}
                    autoSelectFirstTestRun
                />
            </TestPickerWrapper>

            {selectedTestRunIds.length > 0 && testRuns?.length !== 0 && (
                <TabsWrapper>
                    <Tabs showBackground={false} value={tab} onChange={setTab}>
                        <Tab label={PreviewTabType.REQUEST}>Request</Tab>
                        <Tab label={PreviewTabType.RESPONSE}>Response</Tab>
                        <Tooltip
                            disabled={isHandleResponseConfigured}
                            content={
                                <>
                                    <div>The selected test does not have its response handling configured.</div>
                                    <div>In order to see the results, extract the entities and run the test again</div>
                                </>
                            }
                        >
                            <Tab label={PreviewTabType.HANDLE_RESPONSE} disabled={!isHandleResponseConfigured}>
                                Handle Response Results
                            </Tab>
                        </Tooltip>
                    </Tabs>
                    {testRuns && (
                        <AnimatedSwitch activeLabel={tab}>
                            <StyledAnimatedSwitchItem label={PreviewTabType.REQUEST}>
                                <ContentWrapper>
                                    <ProjectIntegrationEntityBodyAndHeadersLayout
                                        testRuns={testRuns}
                                        testRunIds={selectedTestRunIds}
                                    />
                                </ContentWrapper>
                            </StyledAnimatedSwitchItem>
                            <StyledAnimatedSwitchItem label={PreviewTabType.RESPONSE}>
                                <ContentWrapper>
                                    <ProjectIntegrationEntityBodyAndHeadersLayout
                                        testRunIds={selectedTestRunIds}
                                        testRuns={testRuns}
                                        isResponse
                                    />
                                </ContentWrapper>
                            </StyledAnimatedSwitchItem>
                            <StyledAnimatedSwitchItem label={PreviewTabType.HANDLE_RESPONSE}>
                                <ContentWrapper>
                                    <ProjectIntegrationEntityHandleTestRunResponseView entity={entity} />
                                </ContentWrapper>
                            </StyledAnimatedSwitchItem>
                        </AnimatedSwitch>
                    )}
                </TabsWrapper>
            )}

            {testRuns?.length === 0 && (
                <StyledStateMessage
                    title="Configure your request to run test"
                    icon={<SpaceShip className="tnk-icon" />}
                />
            )}
        </Wrapper>
    );
};

export default ProjectIntegrationEntityContinuousCollectRightLayout;
