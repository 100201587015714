function MessageCommentCtrl($scope, modal, tonkeanService, utils) {
    $scope.as = $scope.$root.as;

    // Initialize the state with initial text if supplied.
    $scope.state = getNewState($scope.initialText);
    $scope.posting = false;
    // Set an id for the text area because mentio needs a unique id (taking entityId is not unique enough)
    $scope.commentTextAreaId = `comment-${utils.guid()}`;

    $scope.onMessageKeydown = function ($event) {
        if (!$scope.allowPartial && $scope.as.currentUser.isPartial) {
            modal.openValidateUser();
            $event.preventDefault();
            return;
        }

        // Don't post a comment if the at mention is open (because we want to select the at mention)
        if (
            ($event.code === 'Enter' || $event.keyCode === 13) &&
            !$scope.state.isAtMentionOpen &&
            ($event.ctrlKey || $scope.postOnEnter)
        ) {
            $event.preventDefault();
            $scope.postComment();
            return;
        }
    };

    $scope.onTextChangedInner = function (text) {
        if ($scope.onTextChanged) {
            $scope.onTextChanged({ text });
        }
    };

    $scope.onAtMentionIsOpen = function (isOpen) {
        $scope.state.isAtMentionOpen = isOpen;
    };

    $scope.postComment = function () {
        if ($scope.state.text && $scope.state.text.length && $scope.entityId && !$scope.postingComment) {
            $scope.postingComment = true;
            let atMentionIds = [];
            if ($scope.state.atMentions) {
                atMentionIds = $scope.state.atMentions.map((mention) => mention.id);
            }

            tonkeanService
                .postComment($scope.entityId, $scope.state.text, $scope.updateInitiativeId, atMentionIds)
                .then(function (comment) {
                    comment.commenter = $scope.as.currentUser;
                    if ($scope.onPost) {
                        $scope.onPost({ comment });
                    }
                    $scope.state = getNewState();
                })
                .catch(function (error) {
                    console.log(error);
                    $scope.$emit('alert', { type: 'danger', msg: 'Could not post comment' });
                })
                .finally(function () {
                    $scope.postingComment = false;
                });
        }
    };

    function getNewState(initialText) {
        return {
            text: initialText,
            atMentions: [],
            isAtMentionOpen: false,
        };
    }
}
angular.module('tonkean.app').controller('MessageCommentCtrl', MessageCommentCtrl);
