import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import FormBuilderSlackCommandInput from './FormBuilderSlackCommandInput';
import { ReactComponent as SlackIcon } from '../../../../images/slack-icon.svg';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import {
    CloseButton,
    LoadingCircle,
    Modal,
    ModalBody,
    ModalFooterState,
    ModalHeader,
    useDebouncedState,
} from '@tonkean/infrastructure';
import { FontSize, Theme } from '@tonkean/tui-theme';

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const NormalContent = styled.p`
    /* width: 90%; */
    color: ${Theme.colors.gray_700};
    font-size: ${FontSize.SMALL_12};
`;

const BoldContent = styled.p`
    color: ${Theme.colors.gray_800};
    font-weight: 500;
    font-size: ${FontSize.SMALL_12};
`;

const StyledLoadingCircle = styled(LoadingCircle)`
    align-self: center;
`;

const SlackIconWrapper = styled.span`
    display: inline-block;

    height: 22px;
    width: 22px;
    margin-right: 1ch;
`;

interface Props {
    groupId: string;
    open: boolean;
    onClose: () => void;
    onDuplicate: (newSlackCommand?: string) => void;
}

const FormBuilderDuplicateFormModal: React.FC<Props> = ({ open, onClose, onDuplicate, groupId }) => {
    const [{ data, loading }, validateSlackCommand] = useLazyTonkeanService('validateIsSlackCommandTaken');

    const [newDebouncedSlackCommand, setNewDebouncedSlackCommand] = useState<string>('');
    const [newSlackCommand, setNewSlackCommand] = useDebouncedState<string>('', setNewDebouncedSlackCommand, 300);

    useEffect(() => {
        if (newDebouncedSlackCommand !== '') {
            validateSlackCommand(newDebouncedSlackCommand, groupId);
        }
    }, [groupId, newDebouncedSlackCommand, validateSlackCommand]);

    const isNewSlackCommandInvalid = newSlackCommand.length !== 0 && data && !data.isCommandValid;

    return (
        <Modal open={open} onClose={onClose}>
            <ModalHeader>
                <HeaderWrapper>
                    <SlackIconWrapper>
                        <SlackIcon />
                    </SlackIconWrapper>
                    Create new slack command
                </HeaderWrapper>
            </ModalHeader>
            <ModalBody>
                <ContentWrapper>
                    <NormalContent>
                        As you attend to duplicate form, notice that each form requires its own slack command
                    </NormalContent>
                    <BoldContent>Type a new slack command or update it later</BoldContent>
                    <FormBuilderSlackCommandInput
                        value={newSlackCommand}
                        onChange={({ target: { value } }) => setNewSlackCommand(value)}
                        valid={!isNewSlackCommandInvalid}
                    />
                </ContentWrapper>
            </ModalBody>
            <ModalFooterState loading={loading}>
                <CloseButton cancel>Cancel</CloseButton>
                <CloseButton
                    onClick={() => onDuplicate(newSlackCommand.length === 0 ? undefined : newSlackCommand)}
                    disabled={loading || isNewSlackCommandInvalid}
                >
                    Duplicate
                </CloseButton>
            </ModalFooterState>
        </Modal>
    );
};

export default FormBuilderDuplicateFormModal;
