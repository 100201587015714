import type { Initiative } from '@tonkean/tonkean-entities';

/**
 * Calculates the progress and inner item warning for given initiative.
 */
export function calculateProgressAndInnerItemWarn(initiative: Initiative) {
    const result = {
        totalCount: initiative.innerItemsCount,
        doneCount: initiative.doneInnerItemsCount,
        innerItemWarn: false,
    };
    if (initiative.hasRelatedInitiatives) {
        angular.forEach(initiative.relatedInitiatives, function (item) {
            const track = item;
            if (!item.deleted) {
                if (track.status !== 'DONE' && track.metadata?.progress) {
                    result.doneCount += track.metadata.progress as number;
                }

                if (track.status === 'WARN' || track.innerItemWarn) {
                    result.innerItemWarn = true;
                }
            }
        });
    }

    return result;
}
