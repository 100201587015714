import type { ProjectIntegration } from '@tonkean/tonkean-entities';
import type TonkeanService from './tonkean-service.service';

class ProjectIntegrationCache {
    /* @ngInject */

    private projectIntegrationsCache: Record<string, any> = {};
    private projectIntegrationFetchesCache: Record<string, Promise<any>> = {};

    constructor(private tonkeanService: TonkeanService) {}

    /**
     * Get project integration from cache if available, otherwise, fetch from the server.
     *
     * @param projectIntegrationId - the project integration id.
     * @returns a promise that resolves to the requested project integration.
     */
    public async getProjectIntegrationById(projectIntegrationId: string): Promise<ProjectIntegration> {
        if (this.projectIntegrationsCache[projectIntegrationId]) {
            return this.projectIntegrationsCache[projectIntegrationId];
        }

        if (this.projectIntegrationFetchesCache[projectIntegrationId]) {
            return this.projectIntegrationFetchesCache[projectIntegrationId];
        }

        const projectIntegrationFetch = this.tonkeanService.getProjectIntegrationById(projectIntegrationId);
        this.addFetchToCache(projectIntegrationId, projectIntegrationFetch);

        return projectIntegrationFetch;
    }

    public getFromCache(projectIntegrationId: string): any {
        return this.projectIntegrationsCache[projectIntegrationId];
    }

    public cacheEntities(entities: any[]): void {
        entities.forEach((entity) => this.addToCache(entity));
    }

    private addFetchToCache(projectIntegrationId: string, projectIntegrationPromise: Promise<any>): void {
        this.projectIntegrationFetchesCache[projectIntegrationId] = projectIntegrationPromise;
        projectIntegrationPromise.then((projectIntegration) => this.addToCache(projectIntegration));
    }

    private addToCache(projectIntegration: any): void {
        this.projectIntegrationsCache[projectIntegration.id] = projectIntegration;
    }
}

angular.module('tonkean.app').service('projectIntegrationCache', ProjectIntegrationCache);

export default ProjectIntegrationCache;
