import React from 'react';

import { ReactComponent as BusinessReport } from '../../../../images/icons/history/flow-run.svg';
import { ReactComponent as SolutionBusinessReportIcon } from '../../../../images/icons/solution-business-report-modules.svg';
import { SolutionReportTypes } from '../entities/SolutionReportType';

interface Props {
    type: SolutionReportTypes;
}

const SolutionReportIcon: React.FC<Props> = ({ type }) => {
    return type === SolutionReportTypes.BUSINESS ? <BusinessReport /> : <SolutionBusinessReportIcon />;
};

export default SolutionReportIcon;
