import type { FormEvent } from 'react';
import React, { useState } from 'react';

import type CustomizedAuthenticationComponentProps from '../../base/CustomizedAuthenticationComponentProps';

const TwilioAuthenticationComponent: React.FC<CustomizedAuthenticationComponentProps> = ({
    authenticate,
    reportError,
    integrationConfiguration,
    projectIntegrationData,
}) => {
    const [serviceId, setServiceId] = useState<string>('');
    const [keySecret, setKeySecret] = useState<string>('');

    const authenticateToTwilio = (formEvent: FormEvent) => {
        try {
            formEvent.preventDefault();

            const config = {
                apiServiceId: serviceId,
                apiSecretKey: keySecret,
            };

            authenticate(config, config, integrationConfiguration.displayName);
        } catch {
            reportError('Error occurred while authenticating.');
        }
    };

    return (
        <div>
            <form onSubmit={authenticateToTwilio}>
                <div className="form-group flex-vmiddle">
                    <label htmlFor="login-api-sid" className="col-sm-3 control-label">
                        API Key SID
                    </label>
                    <div className="col-sm-8 padding-none">
                        <input
                            type="text"
                            id="login-api-sid"
                            value={serviceId}
                            autoComplete="off"
                            placeholder="API SID"
                            className="form-control"
                            onChange={({ target }) => setServiceId(target.value)}
                            required
                        />
                        <span className="common-size-xxxs common-color-grey">
                            Get your service id{' '}
                            <a href="https://www.twilio.com/console/project/settings" target="_blank">
                                here
                            </a>
                        </span>
                    </div>
                </div>

                <div className="form-group flex-vmiddle">
                    <label htmlFor="login-api-key-secret" className="col-sm-3 control-label">
                        API Key Secret
                    </label>
                    <div className="col-sm-8 padding-none">
                        <input
                            type="text"
                            id="login-api-key-secret"
                            value={keySecret}
                            autoComplete="off"
                            placeholder="API Secret"
                            className="form-control col-sm-8"
                            onChange={({ target }) => setKeySecret(target.value)}
                            required
                        />
                        <span className="common-size-xxxs common-color-grey">
                            Get your key secret{' '}
                            <a href="https://www.twilio.com/console/project/settings" target="_blank">
                                here
                            </a>
                        </span>
                    </div>
                </div>

                <div className="flex mod-center">
                    <button className="btn btn-primary">Connect</button>
                </div>
            </form>
        </div>
    );
};

export default TwilioAuthenticationComponent;
