import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import useTonkeanNetworkService from './useTonkeanNetworkService';

import type { NetworkServiceError } from '@tonkean/network-service';
import { type NetworkService } from '@tonkean/network-service';
import useEnrichServerResponseEntity from '@tonkean/shared-services/useEnrichServerResponseEntity';
import type { TonkeanId } from '@tonkean/tonkean-entities';

interface TonkeanQueryReturnType<DataType> {
    data: ReturnType<typeof useQuery<DataType>>['data'];
    isLoading: boolean;
    isError: boolean;
    errorStatusCode: number;
    isFetching: boolean;
    isSuccess: boolean;
    isPending: boolean;
    invalidateData: () => void;
}

// disable lint here just to make it extra clear that you can use `TonkeanId` here
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type UniqueQueryKeyType = string | number | TonkeanId | UniqueQueryKeyType[] | object | undefined;

interface UseTonkeanQueryProps<DataType> {
    queryUniqueKey: string;
    propsUniqueKeysArray: UniqueQueryKeyType[];
    queryFn: (networkService: NetworkService) => Promise<DataType>;
    enabledFn?: () => boolean | undefined;
    enrichDataFn?: (data: DataType) => void;
    synchronizeCachesFn?: (queryUniqueKey: string, propsUniqueKeysArray: UniqueQueryKeyType[], data: DataType) => void;
    resetDataWhenKeyChanges?: boolean;
    polling?:
        | {
              enabled: boolean;
              interval: number;
          }
        | undefined;

    // USING THE OLD WAY OF DATA ENRICHMENT IS DISCOURAGED. USE ONLY FOR MIGRATION PURPOSES
    useDeprecatedRelatedEntitiesEnrichment?: boolean;
}

const useTonkeanQuery = <DataType>({
    queryUniqueKey,
    propsUniqueKeysArray,
    queryFn,
    enabledFn,
    enrichDataFn,
    synchronizeCachesFn,
    resetDataWhenKeyChanges = true,
    useDeprecatedRelatedEntitiesEnrichment = false,
    polling,
}: UseTonkeanQueryProps<DataType>): TonkeanQueryReturnType<DataType> => {
    const networkService = useTonkeanNetworkService();
    const deprecatedRelatedEntitiesEnrichment = useEnrichServerResponseEntity();

    const query = useQuery<DataType, NetworkServiceError>({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [queryUniqueKey, ...propsUniqueKeysArray],
        queryFn: async () => {
            const result = await queryFn(networkService);

            if (useDeprecatedRelatedEntitiesEnrichment) {
                deprecatedRelatedEntitiesEnrichment(result);
            }

            if (enrichDataFn) {
                enrichDataFn(result);
            }

            if (synchronizeCachesFn) {
                synchronizeCachesFn(queryUniqueKey, propsUniqueKeysArray, result);
            }
            return result;
        },
        enabled: enabledFn ? enabledFn() : undefined,
        placeholderData: resetDataWhenKeyChanges ? undefined : keepPreviousData,
        refetchInterval: polling?.enabled ? polling.interval : false,
    });

    const invalidateData = useCallback(() => {
        return query.refetch();
    }, [query]);

    return {
        data: query.data,
        isLoading: query.isLoading,
        isError: query.isError,
        errorStatusCode: query.error?.status ?? 0,
        isFetching: query.isFetching,
        isSuccess: query.isSuccess,
        isPending: query.isPending,
        invalidateData,
    };
};

export default useTonkeanQuery;
export type { TonkeanQueryReturnType, UseTonkeanQueryProps, UniqueQueryKeyType };
