import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function WorkflowVersionSelectorCtrl($scope, workflowVersionManager) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,

        selectedWorkflowVersion: null,
        availableWorkflowVersions: [],
    };

    /**
     * Controller's initialization function.
     */
    ctrl.$onInit = function () {
        $scope.data.selectedWorkflowVersion = workflowVersionManager.getDraftVersionFromCache($scope.data.groupId);

        $scope.data.availableWorkflowVersions.push(
            workflowVersionManager.getDraftVersionFromCache($scope.data.groupId),
        );
        $scope.data.availableWorkflowVersions.push(
            workflowVersionManager.getPublishedVersionFromCache($scope.data.groupId),
        );
    };

    /**
     * Occurs on selection of a workflow version.
     */
    $scope.onWorkflowVersionSelection = function (selectedWorkflowVersion) {
        if (ctrl.onWorkflowVersionSelected) {
            ctrl.onWorkflowVersionSelected({
                workflowVersion: selectedWorkflowVersion,
            });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('WorkflowVersionSelectorCtrl', lateConstructController(WorkflowVersionSelectorCtrl));
