import React from 'react';
import styled, { css } from 'styled-components';

import ItemInterfaceBody, {
    type ItemInterfaceBodyProps,
} from '../../../../SystemInterface/components/ItemInterface/ItemInterfaceBody';

type ItemInterfaceSequenceBodyProps = { showHeader?: boolean };
const StyledItemInterfaceBody = styled(ItemInterfaceBody)<ItemInterfaceSequenceBodyProps>`
    ${({ showHeader }) =>
        !showHeader &&
        css`
            border-radius: 16px 16px 0px 0px;
            padding-top: 16px;
        `};
`;

export const ItemInterfaceSequenceBody: React.FC<ItemInterfaceSequenceBodyProps & ItemInterfaceBodyProps> = (props) => {
    return <StyledItemInterfaceBody {...props} />;
};
