import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import TriggeredModulesTable from './TriggeredModulesTable';
import TriggeredSolutionsModulesTableEmptyState from './TriggeredSolutionsModulesTableEmptyState';
import { ReactComponent as EmptyTrigger } from '../../../../../../../..//images/icons/trigger-empty.svg';

import { useAsyncMethod } from '@tonkean/angular-hooks';
import { Chevron, ChevronDirection, CollapsibleContent, ErrorMessage, Placeholder } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { Environment, Group, ProjectIntegration } from '@tonkean/tonkean-entities';
import styledFocus from '@tonkean/tui-basic/styledFocus';
import { DisableableButton } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const GrayText = styled.span`
    color: ${Theme.colors.gray_600};
`;

const SolutionName = styled.span`
    font-weight: 500;
    color: ${Theme.colors.gray_800};
    margin-left: 2px;
`;

const ChevronWrapper = styled(DisableableButton)`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${Theme.colors.gray_300};
    margin-right: 8px;
    padding: 0;

    border: none;
    cursor: pointer;

    display: inline-grid;
    place-items: center;

    .tnk-icon {
        display: inline-flex;
    }

    ${styledFocus}
`;

const SolutionTitle = styled(Flex)`
    padding: 10px 20px 20px 20px;
`;

const EmptyTriggerSvg = styled(EmptyTrigger)`
    margin-bottom: 12px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
    margin: 0 24px 24px 24px;
`;

const EmptyState = styled.div`
    margin-top: 70px;
    margin-bottom: 165px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${Theme.colors.gray_600};
    text-align: center;
`;

interface Props {
    externalActivity: { id: string; title: string };
    projectIntegration: ProjectIntegration;
    collectLogTime: number;
    groups: Group[];
    error?: string;
    loading?: boolean;
    environment: Environment;
}

const TriggeredSolutionsModulesTable: React.FC<Props> = ({
    externalActivity,
    projectIntegration,
    collectLogTime,
    groups,
    loading = false,
    error = '',
    environment,
}) => {
    const [collapsedSolutions, setCollapsedSolutions] = useState({});
    const project = useProject();

    const workflowFolderManager = useAngularService('workflowFolderManager');
    const {
        data: workflowFolders,
        loading: workflowFoldersLoading,
        error: workflowFoldersError,
    } = useAsyncMethod(workflowFolderManager, 'getWorkflowFolders', project.id, false);

    // Create list of workflow folders which contain the group data in them
    const workflowFoldersWithGroupData = useMemo(() => {
        // Create map from group id to the group for fast access
        const groupIdToGroupMap = {};
        groups.forEach((group) => {
            groupIdToGroupMap[group.id] = group;
        });

        // Add the group data to each workflow folder
        const workflowFoldersWithGroup: any = [];
        workflowFolders?.forEach((workflowFolder) => {
            workflowFoldersWithGroup.push({
                ...workflowFolder,
                groups: workflowFolder.groupIds
                    ?.map((groupId) => groupIdToGroupMap[groupId])
                    // Filter out groups which are not in the triggered modules list
                    .filter((group) => group),
            });
        });

        // Filter out workflow folders which have no groups which are triggered
        return workflowFoldersWithGroup?.filter((workflowFolder) => workflowFolder.groups?.length);
    }, [workflowFolders, groups]);

    // Loading state
    if (workflowFoldersLoading || loading) {
        return (
            <EmptyState>
                <EmptyTriggerSvg />
                Loading Triggered Modules...
            </EmptyState>
        );
    }

    // Error state
    if (workflowFoldersError || error) {
        return <StyledErrorMessage>There was an error getting the list of item triggered modules</StyledErrorMessage>;
    }

    // Empty state
    if (workflowFoldersWithGroupData.length === 0) {
        return <TriggeredSolutionsModulesTableEmptyState />;
    }

    return workflowFoldersWithGroupData?.map((workflowFolder) => {
        return (
            <React.Fragment key={workflowFolder.id}>
                <SolutionTitle>
                    <ChevronWrapper
                        onClick={() =>
                            setCollapsedSolutions({
                                ...collapsedSolutions,
                                [workflowFolder.id]: !collapsedSolutions[workflowFolder.id],
                            })
                        }
                    >
                        <Chevron
                            direction={
                                collapsedSolutions[workflowFolder.id] ? ChevronDirection.RIGHT : ChevronDirection.DOWN
                            }
                        />
                    </ChevronWrapper>

                    <GrayText>Solution: </GrayText>
                    <SolutionName>
                        {loading ? <Placeholder $width="150px" $height="16px" /> : workflowFolder.displayName}
                    </SolutionName>
                </SolutionTitle>

                <CollapsibleContent open={!collapsedSolutions[workflowFolder.id]} animateOpacity>
                    <TriggeredModulesTable
                        externalActivity={externalActivity}
                        projectIntegration={projectIntegration}
                        collectLogTime={collectLogTime}
                        groups={workflowFolder?.groups || []}
                        environment={environment}
                    />
                </CollapsibleContent>
            </React.Fragment>
        );
    });
};

export default TriggeredSolutionsModulesTable;
