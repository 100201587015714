import type { AngularServices } from 'angulareact';
import React, { useEffect } from 'react';
import { Provider } from 'urql';

import { queryResolversConfig, schema } from '../graphql-schema.json?graphql-schema';

import { useUrqlTonkeanClient } from '@tonkean/graphql-hooks';

interface Props {
    $rootScope: AngularServices['$rootScope'];
    environment: AngularServices['environment'];
    authenticationService: AngularServices['authenticationService'];
    children: React.ReactNode;
}

const UrqlClientProvider: React.FC<Props> = ({ children, $rootScope, environment, authenticationService }) => {
    const getAuthorizationHeader = () => {
        const currentUser = authenticationService.currentUser;
        const accessToken = currentUser?.accessToken;
        const projectToken = authenticationService.getProjectToken();

        return {
            authorization: accessToken ? `token ${accessToken}` : '',
            'Authorization-Project': projectToken ? `Bearer ${projectToken}` : '',
        };
    };

    const client = useUrqlTonkeanClient(schema, queryResolversConfig, environment.apiUrl, getAuthorizationHeader);

    useEffect(() => {
        $rootScope['urql'] = client;
    }, [$rootScope, client]);

    return <Provider value={client}>{children}</Provider>;
};

export default UrqlClientProvider;
