import React from 'react';
import styled from 'styled-components';

import PredefinedConnectionsTableRowView from './PredefinedConnectionsTableRowView';

import { Placeholder } from '@tonkean/infrastructure';
import type { SharedCredential } from '@tonkean/tonkean-entities';
import type { DataSourceConnection } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { randomNumber, range } from '@tonkean/utils';

const IntegrationIconWrapper = styled.div`
    margin-right: 5px;
`;

const Cell = styled.div`
    border-bottom: 1px solid ${Theme.colors.gray_300};
    height: 50px;
    display: flex;
    align-items: center;
`;

const NoConnectionsFound = styled.div`
    text-align: center;
    grid-column: span 4;
    height: 40px;
    line-height: 40px;
    color: ${Theme.colors.gray_500};
`;

interface Props {
    filterByTextValue: string;
    isLoading: boolean;
    sharedCredentials: SharedCredential[];
    onPredefinedConnectionUpdated(): void;
    dataSourceConnection?: DataSourceConnection;
}

const PredefinedConnectionsTableRowsView: React.FC<Props> = ({
    isLoading,
    filterByTextValue,
    sharedCredentials,
    onPredefinedConnectionUpdated,
    dataSourceConnection,
}) => {
    return (
        <>
            {!isLoading &&
                dataSourceConnection &&
                sharedCredentials.map((sharedCredential) => (
                    <React.Fragment key={sharedCredential.id}>
                        <PredefinedConnectionsTableRowView
                            integrationType={dataSourceConnection.dataSourceType}
                            sharedCredential={sharedCredential}
                            onPredefinedConnectionUpdated={onPredefinedConnectionUpdated}
                            dataSourceConnection={dataSourceConnection}
                        />
                    </React.Fragment>
                ))}

            {isLoading && (
                <>
                    {range(randomNumber(4, 8)).map((i) => (
                        <React.Fragment key={i}>
                            <Cell>
                                <Placeholder $width={`${randomNumber(100, 150)}px`} />
                            </Cell>

                            <Cell>
                                <IntegrationIconWrapper>
                                    <Placeholder $width="20px" />
                                </IntegrationIconWrapper>
                                <Placeholder $width={`${randomNumber(50, 90)}px`} />
                            </Cell>

                            <Cell>
                                <Placeholder $width="36px" />
                            </Cell>

                            <Cell>
                                <Placeholder $width="28px" />
                            </Cell>
                        </React.Fragment>
                    ))}
                </>
            )}

            {!isLoading && sharedCredentials.length === 0 && (
                <NoConnectionsFound>
                    No predefined connections found {!!filterByTextValue && `with the filter '${filterByTextValue}'`}
                </NoConnectionsFound>
            )}
        </>
    );
};

export default PredefinedConnectionsTableRowsView;
