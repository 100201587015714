import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { ReactComponent as DataSourceIcon } from '../../../../../../images/icons/history/data-source.svg';
import { ReactComponent as FlowRunIcon } from '../../../../../../images/icons/history/flow-run.svg';
import { ReactComponent as ModuleItemIcon } from '../../../../../../images/icons/history/module-item.svg';
import type WorkerRunStagesFilter from '../../../entities/WorkerRunStagesFilter';
import originatedWorkerRunState from '../../../states/originatedWorkerRunState';

import { Checkbox } from '@tonkean/infrastructure';
import { WorkerRunStage } from '@tonkean/tonkean-entities';

// TODO: bring back when have real stats
// const MAX_COUNT = 50;

const Flex = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
`;

const CheckboxFilter = styled(Checkbox)`
    margin-right: 14px;
`;

const Label = styled.div`
    display: flex;
    align-items: center;
    line-height: 20px;

    .tnk-icon {
        line-height: 0;
        margin-right: 5px;

        svg {
            height: 14px;
        }
    }
`;

interface Props {
    stats: Record<WorkerRunStage, number>;
    filters: WorkerRunStagesFilter;
    onChange: React.Dispatch<React.SetStateAction<WorkerRunStagesFilter>>;
}

const WorkerRunsFilterStages: React.FC<Props> = ({ stats, filters, onChange }) => {
    const originatedWorkerRun = useRecoilValue(originatedWorkerRunState);

    const emitChange = (stage: WorkerRunStage) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedFilters = {
            ...filters,
            [stage]: event.target.checked,
        };

        onChange(updatedFilters);
    };

    useEffect(() => {
        if (originatedWorkerRun?.isReplyMessage) {
            onChange((prevFilters: Record<WorkerRunStage, boolean>) => ({
                ...prevFilters,
                [WorkerRunStage.FLOW_RUN]: true,
            }));
        } else if (originatedWorkerRun?.isReplyMessage === false) {
            onChange((prevFilters: Record<WorkerRunStage, boolean>) => ({
                ...prevFilters,
                [WorkerRunStage.MODULE_ITEM]: true,
            }));
        }
    }, [onChange, originatedWorkerRun]);

    return (
        <>
            <Flex>
                <CheckboxFilter
                    checked={filters[WorkerRunStage.DATA_SOURCE]}
                    onChange={emitChange(WorkerRunStage.DATA_SOURCE)}
                    disabled={stats[WorkerRunStage.DATA_SOURCE] === 0}
                >
                    <Label>
                        <span className="tnk-icon">
                            <DataSourceIcon />
                        </span>
                        Data Sources
                        {/* TODO: bring back when have real stats*/}
                        {/* ({stats[WorkerRunStage.DATA_SOURCE] > MAX_COUNT ? '50+' : stats[WorkerRunStage.DATA_SOURCE]})*/}
                    </Label>
                </CheckboxFilter>

                <CheckboxFilter
                    dataAutomation="worker-runs-filter-stage-checkbox-module-item"
                    checked={filters[WorkerRunStage.MODULE_ITEM]}
                    onChange={emitChange(WorkerRunStage.MODULE_ITEM)}
                    disabled={stats[WorkerRunStage.MODULE_ITEM] === 0}
                >
                    <Label>
                        <span className="tnk-icon">
                            <ModuleItemIcon />
                        </span>
                        Module Items
                        {/* TODO: bring back when have real stats*/}
                        {/* ({stats[WorkerRunStage.MODULE_ITEM] > MAX_COUNT ? '50+' : stats[WorkerRunStage.MODULE_ITEM]})*/}
                    </Label>
                </CheckboxFilter>

                <CheckboxFilter
                    checked={filters[WorkerRunStage.FLOW_RUN]}
                    onChange={emitChange(WorkerRunStage.FLOW_RUN)}
                    disabled={stats[WorkerRunStage.FLOW_RUN] === 0}
                >
                    <Label>
                        <span className="tnk-icon">
                            <FlowRunIcon />
                        </span>
                        Flow Runs
                        {/* TODO: bring back when have real stats*/}
                        {/* ({stats[WorkerRunStage.FLOW_RUN] > MAX_COUNT ? '50+' : stats[WorkerRunStage.FLOW_RUN]})*/}
                    </Label>
                </CheckboxFilter>
            </Flex>
        </>
    );
};

export default WorkerRunsFilterStages;
