import { useAngularService } from 'angulareact';
import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import DuplicateItemModal from './DuplicateItemModal';
import InterfaceHeaderCallToActionMenuItem from './InterfaceHeaderCallToActionMenuItem';
import type { InterfaceLiveDisplay } from './InterfaceLiveDisplay';
import useLoadCTAActionsEntities from './useLoadCTAActionsEntities';
import { ItemInterfacePermission } from '../../../entities';
import useMatchedItemsActionButtons from '../../../widgets/ActionsWidgetModule/components/actionButtons/useMatchedItemsActionButtons';
import MatchedItemInterfaceModal from '../../../widgets/ActionsWidgetModule/components/MatchedItemInterfaceModal';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { FormPageModal } from '@tonkean/angular-to-react-components';
import {
    Breakpoint,
    IconSvg,
    KebabMenuButton,
    Menu,
    Placeholder,
    SavingIndicator,
    Separator,
    TextEllipsis,
} from '@tonkean/infrastructure';
import { Tooltip } from '@tonkean/infrastructure/components/Tooltip';
import { OpenMenuIcon } from '@tonkean/svg';
import type { InterfaceCTASavedMatchedItemAction, WorkflowVersionType } from '@tonkean/tonkean-entities';
import {
    type Initiative,
    InterfaceCTAActionType,
    type InterfaceCTASavedAction,
    type InterfaceCTASavedIntakeSequenceAction,
    type ItemInterface,
    type TonkeanId,
    type TonkeanType,
    type WorkflowVersion,
} from '@tonkean/tonkean-entities';
import { UserThemedClickableButton } from '@tonkean/tui-buttons/Button';
import type { Color } from '@tonkean/tui-theme';

const MenuButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        width: 100%;
    }
`;

const actionButtonSharedCss = css`
    transition-property: background-color, color;
    max-height: unset;
    height: unset;
    border-radius: 8px;

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        min-height: 40px;
        max-width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const PrimaryActionButton = styled(UserThemedClickableButton)<{ $hasMultipleActions: boolean }>`
    ${actionButtonSharedCss};
    padding: 1px 16px 1px 16px;
    min-width: 150px;
    max-width: 230px;

    ${({ $hasMultipleActions }) =>
        $hasMultipleActions &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `};

    &:hover {
        // bring it up so that we can see a color change in the border in case the color has changed when hovered
        z-index: 1;
    }

    display: flex;
    justify-content: space-between;

    svg {
        vertical-align: unset;
        height: 16px;
        width: 16px;
        margin-right: 0;
    }

    @media screen and (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        flex-grow: 1;
    }
`;

const KebabContainer = styled.div`
    display: flex;
    justify-content: flex-end; // we want the kebab to be on the right
    max-width: 230px;
`;

const MenuAndIndicatorContainer = styled.div<{ $indicatorPosition: 'left' | 'right' }>`
    display: flex;
    align-items: center;
    flex-direction: ${({ $indicatorPosition }) => ($indicatorPosition === 'right' ? 'row' : 'row-reverse')};
    gap: 4px;
`;

const PrimaryActionButtonTextContainer = styled.div`
    flex-grow: 1;
    padding: 5px 0;
`;

const OpenMenuButton = styled(UserThemedClickableButton)`
    ${actionButtonSharedCss};
    padding: 6px 10px;
    min-width: unset;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px; // we move this slightly 1px left so that the two buttons would overlap their border

    svg {
        margin: 0;
    }
`;

interface Props {
    initiative?: Initiative;
    actions: InterfaceCTASavedAction[];
    primaryColor: Color;
    itemInterface?: ItemInterface;
    workflowVersion: WorkflowVersion | undefined;
    workflowVersionType: WorkflowVersionType;
    projectId: TonkeanId<TonkeanType.PROJECT>;
    permission: ItemInterfacePermission;
    reloadInitiativeFromServer?: () => void | undefined;
    liveDisplay?: InterfaceLiveDisplay;
    showActionAsButton: boolean | undefined;
    useAlternativeColors?: boolean;
    savingIndicatorPosition?: 'left' | 'right';
}

/**
 * This component is for displaying an interface's configured actions in a dropdown button view.
 * It can support item interfaces and workspace apps actions. As they are fundamentally the same actions with slight variance,
 * like where to pull forms from which should be resolved by internal hooks.
 */
const InterfaceHeaderCallToActionMenu: React.FC<Props> = ({
    workflowVersionType,
    initiative,
    actions,
    primaryColor,
    itemInterface,
    workflowVersion,
    projectId,
    permission,
    reloadInitiativeFromServer,
    showActionAsButton,
    useAlternativeColors,
    liveDisplay,
    savingIndicatorPosition = 'right',
}) => {
    const [interfaceModalOpen, setInterfaceModalOpen] = useState(false);
    const [chosenMatchedItemInitiativeId, setChosenMatchedItemInitiativeId] = useState<string>();
    const [chosenMatchedItemInterfaceId, setChosenMatchedItemInterfaceId] = useState<string>();
    const [menuOpen, setMenuOpen] = useState(false);
    const [loadingIdMap, setLoadingIdMap] = useState<Record<string, boolean>>({});
    const [selectedFormId, setSelectedFormId] = useState<TonkeanId<TonkeanType.FORM> | ''>('');
    const [duplicateInitiativeModalOpen, setDuplicateInitiativeModalOpen] = useState(false);

    const [{ loading: loadingTriggerCustomTrigger, error: errorTriggerCustomTrigger }, triggerButtonCustomTrigger] =
        useLazyTonkeanService('triggerButtonCustomTrigger');

    const [{ loading: loadingDuplicateInitiative, error: errorDuplicateInitiative }, duplicateInitiative] =
        useLazyTonkeanService('duplicateInitiative');

    const $state = useAngularService('$state');

    const { groupIdToMatchedItemId, matchedItemsActionButtons } = useMatchedItemsActionButtons(
        itemInterface?.workflowVersionId,
        initiative,
    );

    const handleActionClick = (action: InterfaceCTASavedAction) => {
        let actionPromise: Promise<unknown> | undefined = undefined;
        switch (action.type) {
            case InterfaceCTAActionType.OPEN_FORM: {
                setSelectedFormId(action.id);
                break;
            }
            case InterfaceCTAActionType.TRIGGER_WORKFLOW: {
                if (initiative) {
                    actionPromise = triggerButtonCustomTrigger(action.id, initiative.id, action.label);
                }
                break;
            }
            case InterfaceCTAActionType.CLONE_ITEM: {
                setDuplicateInitiativeModalOpen(true);
                break;
            }

            case InterfaceCTAActionType.OPEN_MATCHED_ITEM: {
                if (action.interface) {
                    setChosenMatchedItemInterfaceId(action.interface.interfaceId);
                }

                const initiativeId = matchedItemsActionButtons?.find((matchedItem) => {
                    const fieldDefinitionId = action.id.split('-');
                    if (!action.interface) {
                        setChosenMatchedItemInterfaceId(fieldDefinitionId[1]);
                    }
                    return matchedItem.id === fieldDefinitionId[0];
                })?.initiativeId;

                // In case the initiativeId is undefined - means its interface from current module, so use current initiative id
                if (!initiativeId) {
                    setChosenMatchedItemInitiativeId(initiative?.id);
                } else {
                    setChosenMatchedItemInitiativeId(initiativeId);
                }
                setInterfaceModalOpen(true);

                break;
            }

            case InterfaceCTAActionType.OPEN_INTAKE_SEQUENCE: {
                const matchedItemId = groupIdToMatchedItemId[action.groupId];

                const href = $state.href(
                    'product.intakeInterface',
                    {
                        projectId,
                        customTriggerId: action.id,
                        workflowVersionType,
                        // No initiative id means it will create a new one
                        initiativeId: matchedItemId || initiative?.id,
                    },
                    { absolute: true },
                );

                window?.open(href, '_blank')?.focus();
                break;
            }
        }

        if (actionPromise) {
            setLoadingIdMap((value) => ({ ...value, [action.id]: true }));
            actionPromise.finally(() => {
                setLoadingIdMap((value) => ({ ...value, [action.id]: false }));
            });
        }
    };

    const filteredActions = useMemo(() => {
        return actions.filter(
            (action) =>
                action.shown &&
                !liveDisplay?.actionsHiddenLiveDisplay?.[`${liveDisplay.actionsLiveDisplayKeyPrefix}-${action.id}`],
        );
    }, [actions, liveDisplay?.actionsHiddenLiveDisplay, liveDisplay?.actionsLiveDisplayKeyPrefix]);

    const { data, loading: loadingCtaActionsEntitiesSummary } = useLoadCTAActionsEntities(
        filteredActions,
        itemInterface?.workflowVersionId,
        workflowVersionType,
    );

    interface actionsData {
        id: string;
        label: string;
        disabled: boolean;
        disabledText: string;
    }

    const getEnrichedActions = useCallback(
        (idToDefaultLabelMap: Record<string, actionsData>) => {
            return filteredActions.map((action) => ({
                ...action,
                label:
                    action.label ||
                    idToDefaultLabelMap?.[action.id]?.label ||
                    action.defaultLabel ||
                    'Error displaying action name',
                buttonStates: {
                    disabled: {
                        active: idToDefaultLabelMap?.[action.id]?.disabled || false,
                        disabledText:
                            action.buttonStates?.disabled?.disabledText ||
                            idToDefaultLabelMap?.[action.id]?.disabledText ||
                            'This action is not available for the current item (no matching items were found)',
                    },
                },
            }));
        },
        [filteredActions],
    );

    const enrichedActions: InterfaceCTASavedAction[] = useMemo(() => {
        const idToDefaultLabelMap: Record<string, actionsData> = Object.fromEntries([
            // Forms
            ...(data?.forms.map((form) => [form.id, { id: form.id, label: form.displayName, disabled: false }]) || []),

            // Custom triggers
            ...(data?.customTriggers.map((customTrigger) => [
                customTrigger.id,
                { id: customTrigger.id, label: customTrigger.displayName, disabled: false },
            ]) || []),

            // Matched items
            ...(matchedItemsActionButtons?.map((matchedItem) => [
                matchedItem.id,
                {
                    id: matchedItem.id,
                    label: matchedItem.customTriggerDisplayName,
                    disabled: !matchedItem.initiativeId,
                },
            ]) || []),

            // Matched items in specific interfaces
            ...actions
                .filter(
                    (action): action is InterfaceCTASavedMatchedItemAction =>
                        action.type === InterfaceCTAActionType.OPEN_MATCHED_ITEM &&
                        action.id.split('-')[0].startsWith('FIDE'),
                )
                .map((action) => {
                    const matchedItem = matchedItemsActionButtons.find(
                        (matchedAction) => matchedAction.id === action.id.split('-')[0],
                    );
                    return [
                        action.id,
                        {
                            disabled: !matchedItem?.initiativeId || (action.contractField && !action.interface),
                            disabledText: !matchedItem?.initiativeId
                                ? 'This action is not available for the current item (no matching items were found)'
                                : "Couldn't find the interface for this action.",
                        },
                    ];
                }),

            ...actions
                .filter(
                    (action): action is InterfaceCTASavedIntakeSequenceAction =>
                        action.type === InterfaceCTAActionType.OPEN_INTAKE_SEQUENCE,
                )
                .map((action) => {
                    const matchedItemId = groupIdToMatchedItemId[action.groupId];
                    return [
                        action.id,
                        {
                            id: action.id,
                            disabled: itemInterface?.group.id
                                ? action.groupId !== itemInterface.group.id && !matchedItemId
                                : false,
                        },
                    ];
                }),

            // Clone item
            [
                InterfaceCTAActionType.CLONE_ITEM,
                { id: InterfaceCTAActionType.CLONE_ITEM, label: 'Clone item', disabled: false },
            ],
        ]);

        return getEnrichedActions(idToDefaultLabelMap);
    }, [
        actions,
        data?.customTriggers,
        data?.forms,
        groupIdToMatchedItemId,
        itemInterface?.group.id,
        matchedItemsActionButtons,
        getEnrichedActions,
    ]);

    if (loadingCtaActionsEntitiesSummary) {
        return <Placeholder $width="210px" $height="28px" />;
    }

    const primaryAction = enrichedActions[0];
    if (!primaryAction) {
        return <></>;
    }

    const interfaceIsEditable = permission === ItemInterfacePermission.INTERFACE_IS_EDITABLE;

    // If there is no item interface, this is considered initialized
    const noItemInitialized = Boolean(itemInterface && !initiative);

    const extraActions = enrichedActions.slice(1);
    const menuItems = [
        <InterfaceHeaderCallToActionMenuItem
            key={primaryAction.id}
            action={primaryAction}
            liveDisplay={liveDisplay}
            handleActionClick={handleActionClick}
            primaryColor={primaryColor}
            blockActionsClick={interfaceIsEditable}
            loadingIdMap={loadingIdMap}
            noItemInitialized={noItemInitialized}
        />,
        <Separator key="separator-primary" />,
        ...extraActions.map((action) => {
            return (
                <InterfaceHeaderCallToActionMenuItem
                    key={action.id}
                    action={action}
                    liveDisplay={liveDisplay}
                    handleActionClick={handleActionClick}
                    primaryColor={primaryColor}
                    blockActionsClick={interfaceIsEditable}
                    loadingIdMap={loadingIdMap}
                    noItemInitialized={noItemInitialized}
                />
            );
        }),
    ];

    const hasMultipleActions = enrichedActions.length > 1;
    const loading = loadingTriggerCustomTrigger || loadingDuplicateInitiative;
    const error = errorTriggerCustomTrigger || errorDuplicateInitiative;
    const primaryActionLoading = loadingIdMap[primaryAction.id];

    const primaryActionLiveDisplayDisabled =
        liveDisplay?.actionsDisabledLiveDisplay?.[`${liveDisplay.actionsLiveDisplayKeyPrefix}-${primaryAction.id}`];

    return (
        <>
            <Menu
                show={menuOpen}
                onClose={() => setMenuOpen(false)}
                menuItems={menuItems}
                placement="bottom-end"
                popoverSameWidthAsOpeningElement={showActionAsButton}
            >
                {showActionAsButton ? (
                    <MenuAndIndicatorContainer $indicatorPosition={savingIndicatorPosition}>
                        <MenuButtonsContainer>
                            <Tooltip
                                content={
                                    noItemInitialized
                                        ? 'No item found'
                                        : primaryAction.buttonStates?.disabled?.disabledText
                                }
                                disabled={
                                    !noItemInitialized &&
                                    (!primaryActionLiveDisplayDisabled ||
                                        !primaryAction.buttonStates?.disabled?.disabledText) &&
                                    !primaryAction.buttonStates?.disabled?.active
                                }
                                placement="bottom"
                            >
                                <PrimaryActionButton
                                    data-automation="item-interface-header-call-to-action-button"
                                    onClick={() => handleActionClick(primaryAction)}
                                    disabled={
                                        primaryActionLiveDisplayDisabled ||
                                        primaryActionLoading ||
                                        interfaceIsEditable ||
                                        noItemInitialized ||
                                        primaryAction.buttonStates?.disabled?.active
                                    }
                                    background={primaryColor}
                                    $useAlternativeColors={useAlternativeColors}
                                    $hasMultipleActions={hasMultipleActions}
                                    $dontShowAsDisabled={interfaceIsEditable}
                                    $autoSvgColor
                                >
                                    <PrimaryActionButtonTextContainer>
                                        <TextEllipsis numberOfLines={1} tooltip>
                                            {primaryAction.label}
                                        </TextEllipsis>
                                    </PrimaryActionButtonTextContainer>
                                </PrimaryActionButton>
                            </Tooltip>
                            {hasMultipleActions && (
                                <OpenMenuButton
                                    data-automation="item-interface-header-call-to-action-menu-chevron"
                                    onClick={() => setMenuOpen(true)}
                                    background={primaryColor}
                                    $useAlternativeColors={useAlternativeColors}
                                    $autoSvgColor
                                >
                                    <IconSvg as={OpenMenuIcon} />
                                </OpenMenuButton>
                            )}
                        </MenuButtonsContainer>
                        <SavingIndicator error={error} loading={loading} />
                    </MenuAndIndicatorContainer>
                ) : (
                    <KebabContainer>
                        <Tooltip
                            content={
                                noItemInitialized ? 'No item found' : primaryAction.buttonStates?.disabled?.disabledText
                            }
                            disabled={
                                !noItemInitialized &&
                                (!primaryActionLiveDisplayDisabled ||
                                    !primaryAction.buttonStates?.disabled?.disabledText)
                            }
                            placement="bottom"
                        >
                            <MenuAndIndicatorContainer $indicatorPosition={savingIndicatorPosition}>
                                <KebabMenuButton
                                    aria-label="Open interface action list"
                                    onClick={() => setMenuOpen(true)}
                                    noBackgroundColor
                                    flat
                                />
                                <SavingIndicator loading={loading} error={error} />
                            </MenuAndIndicatorContainer>
                        </Tooltip>
                    </KebabContainer>
                )}
            </Menu>

            <FormPageModal
                open={selectedFormId !== ''}
                onClose={() => {
                    setSelectedFormId('');
                }}
                formId={selectedFormId}
                workflowVersionId={workflowVersion?.id}
                formVersionType={workflowVersionType}
                initiativeId={initiative?.id}
                onInitiativeCreated={() => {}}
                parentInitiativeId={initiative?.parent?.id}
                onSubmitResolved={reloadInitiativeFromServer}
            />

            {initiative && itemInterface && (
                <DuplicateItemModal
                    open={duplicateInitiativeModalOpen}
                    onClose={() => setDuplicateInitiativeModalOpen(false)}
                    itemInterface={itemInterface}
                    initiative={initiative}
                    backgroundColor={primaryColor}
                />
            )}

            <MatchedItemInterfaceModal
                open={interfaceModalOpen}
                onClose={() => setInterfaceModalOpen(false)}
                initiativeId={chosenMatchedItemInitiativeId as TonkeanId<TonkeanType.INITIATIVE>}
                itemInterfaceId={chosenMatchedItemInterfaceId as TonkeanId<TonkeanType.ITEM_INTERFACE>}
                projectId={projectId}
            />
        </>
    );
};

export default InterfaceHeaderCallToActionMenu;
