import { useAngularService } from 'angulareact';
import { Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import CreateMarketplaceItemFromJsonFile from './CreateMarketplaceItemFromJsonFile';

import { useGetStateParams } from '@tonkean/angular-hooks';
import { Field, FormikHelpers, H2, SimpleSelect } from '@tonkean/infrastructure';
import { MarketplaceItemType, type TonkeanUploadedFile } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { yupEnum } from '@tonkean/utils';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px;
    align-items: baseline;
`;

const StyledSelect = styled(SimpleSelect)`
    width: 200px;
    margin-left: 16px;
`;

const ConfirmWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const CreateEntityFromMarketplaceItemSchema = Yup.object({
    marketplaceItemType: yupEnum(MarketplaceItemType),
    marketplaceItemAsJsonFile: Yup.array(),
});

const options = [
    { value: 'SOLUTION', label: 'Solution' },
    { value: 'MODULE', label: 'Module' },
    { value: 'DATA_SOURCE', label: 'Data Source' },
    { value: 'CONTRACT', label: 'Contract' },
    { value: 'PAGE', label: 'Page' },
];

type CreateEntityFromMarketplaceItemSchemaType = Yup.InferType<typeof CreateEntityFromMarketplaceItemSchema>;
const CreateEntityFromMarketplaceItemJson: React.FC = () => {
    const [projectId] = useGetStateParams('projectId');
    const $state = useAngularService('$state');

    const convertFileToMarketplaceItemJson = useCallback((file: TonkeanUploadedFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.addEventListener('load', function (event) {
                try {
                    if (event.target?.result === null) reject('File is empty');
                    resolve(event.target!.result);
                } catch (error) {
                    reject(error);
                }
            });

            reader.addEventListener('error', function (error) {
                reject(error);
            });

            if (!file.blob) {
                reject('File blob is empty');
            }

            reader.readAsText(file.blob!);
        });
    }, []);

    const onSubmit = useCallback(
        (values: CreateEntityFromMarketplaceItemSchemaType) => {
            if (!values.marketplaceItemAsJsonFile?.[0]) {
                return;
            }

            convertFileToMarketplaceItemJson(values.marketplaceItemAsJsonFile[0]).then((result) => {
                $state.go(
                    'product.createEntityFromMarketplaceItemJsonInstallation',
                    {
                        projectId,
                        marketplaceItemType: values.marketplaceItemType,
                        marketplaceItemJson: result,
                    },
                    { reload: true },
                );
            });
        },
        [$state, convertFileToMarketplaceItemJson, projectId],
    );

    return (
        <Formik
            initialValues={{
                marketplaceItemType: undefined,
                marketplaceItemAsJsonFile: undefined,
            }}
            onSubmit={(values: CreateEntityFromMarketplaceItemSchemaType) => onSubmit(values)}
            validationSchema={CreateEntityFromMarketplaceItemSchema}
        >
            {({ values }) => (
                <FormikHelpers>
                    <Form>
                        <Container>
                            <H2> Create entity in project by marketplace item json </H2>

                            <Field label="marketplace item type" showLabelInline>
                                <StyledSelect
                                    name="marketplaceItemType"
                                    options={options}
                                    placeholder="Select Type"
                                    thin
                                />
                            </Field>

                            <CreateMarketplaceItemFromJsonFile />

                            <ConfirmWrapper>
                                <Button type="submit" disabled={!values.marketplaceItemAsJsonFile?.length}>
                                    Confirm
                                </Button>
                            </ConfirmWrapper>
                        </Container>
                    </Form>
                </FormikHelpers>
            )}
        </Formik>
    );
};

export default CreateEntityFromMarketplaceItemJson;
