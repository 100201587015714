import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import type SelectedStatusWidgetType from './SelectedStatusWidgetType';
import StatusProgressBarRowEditor from './StatusProgressBarRowEditor';
import type StatusProgressBarWidgetConfiguration from './StatusProgressBarWidgetConfiguration';
import type { ItemWidgetEditorProps } from '../../WidgetModule';

import { Checkbox, Spacer } from '@tonkean/infrastructure';
import type { State } from '@tonkean/tonkean-entities';
import { getProjectStates } from '@tonkean/tonkean-utils';
import { Theme } from '@tonkean/tui-theme';

const StatusTitleStyled = styled.span<{ active: boolean }>`
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    color: ${({ active }) => (active ? Theme.colors.gray_700 : Theme.colors.gray_500)};
`;

const Wrapper = styled.div`
    padding: 20px;
`;

const ProgressBarEditor: React.FC<ItemWidgetEditorProps<StatusProgressBarWidgetConfiguration>> = ({
    workflowVersion,
    configuration,
    onSave,
}) => {
    const projectManager = useAngularService('projectManager');

    const statuses: SelectedStatusWidgetType[] = useMemo(() => {
        const allStatuses: State[] = workflowVersion && getProjectStates(workflowVersion, projectManager.project);
        return allStatuses.map(
            (status) =>
                ({
                    ...status,
                    isChecked: configuration.selectedStatuses?.includes(status.id) ?? false,
                    overrideLable: configuration.overrideLables?.[status.id],
                }) as SelectedStatusWidgetType,
        );
    }, [workflowVersion, projectManager.project, configuration.selectedStatuses, configuration.overrideLables]);

    const [selectedStatuses, setSelectedStatuses] = useState(statuses);

    const onChangeSelectedStatuses = (isChecked: boolean, statusId: string) => {
        const newSelectedStatuses = selectedStatuses.map((status) => ({
            ...status,
            isChecked: status.id === statusId ? isChecked : status.isChecked,
        }));

        setSelectedStatuses(newSelectedStatuses);

        onSave({
            ...configuration,
            selectedStatuses: newSelectedStatuses.filter((status) => status.isChecked).map((status) => status.id),
        });
    };

    const onChangeStatusLabel = (label: string, statusId: string) => {
        const newSelectedStatuses = selectedStatuses.map((status) => {
            return status.id === statusId
                ? ({
                      ...status,
                      overrideLable: label,
                  } as SelectedStatusWidgetType)
                : status;
        });

        setSelectedStatuses(newSelectedStatuses);

        onSave({
            ...configuration,
            overrideLables: {
                ...configuration.overrideLables,
                [statusId]: label,
            },
        });
    };

    const isAnyStatusSelected = useMemo(() => {
        return selectedStatuses.some((status) => status.isChecked);
    }, [selectedStatuses]);

    useEffect(() => {
        setSelectAll(selectedStatuses.every((status) => status.isChecked));
    }, [selectedStatuses]);

    const [isSelectAll, setSelectAll] = useState(true);

    const selectAllHandler = (isSelected: boolean, userClicked?: boolean) => {
        setSelectAll(isSelected);

        const newSelectedStatuses = selectedStatuses.map((status) => ({
            ...status,
            isChecked: isSelected,
        }));

        setSelectedStatuses(newSelectedStatuses);

        onSave({
            ...configuration,
            selectedStatuses: newSelectedStatuses.filter((status) => status.isChecked).map((status) => status.id),
            isDirty: userClicked,
        });
    };

    useEffect(() => {
        if (!configuration.selectedStatuses?.length && !configuration.isDirty) {
            // Set all statuses selected as default
            selectAllHandler(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuration.selectedStatuses, configuration.isDirty]);

    return (
        <Wrapper>
            <Checkbox
                changeLabelColor={false}
                boldLabel={false}
                checked={isSelectAll}
                onChange={(event) => {
                    selectAllHandler(event.target.checked, true);
                }}
                indeterminate={!isSelectAll && isAnyStatusSelected}
            />

            <StatusTitleStyled active={isSelectAll}> Show / Hide all fields</StatusTitleStyled>

            <Spacer height={10} />

            {selectedStatuses.map((status) => (
                <StatusProgressBarRowEditor
                    key={status.id}
                    status={status}
                    onChangeLabel={onChangeStatusLabel}
                    onChange={onChangeSelectedStatuses}
                />
            ))}
        </Wrapper>
    );
};

export default ProgressBarEditor;
