import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import SingleWorkerRunInspect from './SingleWorkerRunInspect';
import SingleWorkerRunSwitch from './SingleWorkerRunSwitch';
import SingleWorkerRunWrapper from './SingleWorkerRunWrapper';
import { ReactComponent as NetworkErrorIcon } from '../../../../../images/icons/network-error.svg';
import HistoryContext from '../../entities/HistoryContext';
import useSingleWorkerRunFetchManager from '../../hooks/useSingleWorkerRunFetchManager';
import currentWorkerRunState from '../../states/currentWorkerRunState';
import historyBreadcrumbsState from '../../states/historyBreadcrumbsState';
import initialWorkerRunForDrillState from '../../states/initialWorkerRunForDrillState';

import { ErrorStateMessage } from '@tonkean/infrastructure';
import { createWorkerRunInfo, getWorkerRunInfo } from '@tonkean/infrastructure';
import type { WorkerRunStage } from '@tonkean/tonkean-entities';

interface Props {
    projectId: string;
}

const SingleWorkerRun: React.FC<Props> = ({ projectId }) => {
    const { state, updateState } = useContext(HistoryContext);

    /**
     * updates worker run url state. It should be triggered only if the worker run type doesn't match
     * the worker run type currently in state. It will not add an entry to the browser history.
     *
     * @param workerRunId - the new worker run id.
     * @param workerRunStage - the new worker run type.
     */
    const setWorkerRunState = useCallback(
        (workerRunId?: string, workerRunStartTime?: number, workerRunStage?: WorkerRunStage) => {
            updateState(
                {
                    workerRunInfo:
                        workerRunId && workerRunStartTime
                            ? createWorkerRunInfo(workerRunId, workerRunStartTime)
                            : undefined,
                    workerRunStage,
                },
                false,
            );
        },
        [updateState],
    );

    const [initialWorkerRunForDrill, setInitialWorkerRunForDrill] = useRecoilState(initialWorkerRunForDrillState);
    const setCurrentWorkerRun = useSetRecoilState(currentWorkerRunState);

    const workerRunInfo = useMemo(() => {
        return getWorkerRunInfo(state.workerRunInfo);
    }, [state.workerRunInfo]);

    const { workerRun, manuallyReloading, autoReloading, error, manuallyReload } = useSingleWorkerRunFetchManager(
        projectId,
        state.groupId,
        state.environment,
        workerRunInfo?.id,
        workerRunInfo?.startTime,
        state.workerRunStage,
        setWorkerRunState,
        state.inspect,
    );

    useEffect(() => {
        if (!!initialWorkerRunForDrill && initialWorkerRunForDrill.id === workerRunInfo?.id) {
            setInitialWorkerRunForDrill(undefined);
        }
    }, [initialWorkerRunForDrill, setInitialWorkerRunForDrill, state.workerRunInfo, workerRun, workerRunInfo?.id]);

    useEffect(() => {
        setCurrentWorkerRun(workerRun);
    }, [setCurrentWorkerRun, workerRun]);

    const setHistoryBreadcrumbs = useSetRecoilState(historyBreadcrumbsState);

    useEffect(() => {
        if (workerRun) {
            setHistoryBreadcrumbs((currentHistoryBreadcrumbs) => {
                if (currentHistoryBreadcrumbs?.shouldClear(workerRun)) {
                    return undefined;
                }

                return currentHistoryBreadcrumbs;
            });
        }
    }, [setHistoryBreadcrumbs, workerRun]);

    // Should show inspecting worker run modal only if the url state is is in inspect but doesn't have a worker run
    // logic id.
    const inspectingWorkerRun = !!(state.inspect && !state.workerRunLogicId);

    const inspectWorkerRun = useCallback(() => {
        updateState({ inspect: true });
    }, [updateState]);

    const stopInspectingWorkerRun = () => {
        updateState({ inspect: false });
    };

    return (
        <SingleWorkerRunWrapper>
            {error ? (
                <ErrorStateMessage
                    icon={
                        <span className="tnk-icon">
                            <NetworkErrorIcon />
                        </span>
                    }
                >
                    Couldn't load event
                </ErrorStateMessage>
            ) : (
                <SingleWorkerRunSwitch
                    inspectWorkerRun={inspectWorkerRun}
                    autoReloading={autoReloading}
                    manuallyReloading={manuallyReloading}
                    reload={manuallyReload}
                    workerRun={workerRun}
                    workerRunStage={workerRun?.workerRunStage ?? state.workerRunStage}
                />
            )}

            <SingleWorkerRunInspect
                workflowVersionId={workerRun?.workflowVersionId}
                initiative={workerRun?.initiative}
                shown={!!workerRun && inspectingWorkerRun}
                onClose={stopInspectingWorkerRun}
            />
        </SingleWorkerRunWrapper>
    );
};

export default SingleWorkerRun;
