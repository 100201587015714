/**
 * Created by ShaLi on 5/13/15.
 */

function plusNumber() {
    return function (number) {
        return number > 0 ? `+${number}` : number;
    };
}
export default angular.module('tonkean.app').filter('plusNumber', plusNumber);
