import template from './tnkMatchOptionSelection.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkMatchOptionSelection', {
    bindings: {
        groupId: '@',
        workflowVersionId: '@',
        targetWorkflowVersionId: '@', // The version to use in the LEFT side of the match (Defaults to workflowVersionId if not provided)
        projectIntegration: '<',
        logicId: '<', // Optional, if existing this indicates this component is related to a logic action
        previewEvaluationSource: '<',
        entityMetadata: '<',
        validationObject: '<', // Validation object on which to check if the match is valid
        showAdvanced: '@',
        defaultSelectedOption: '@', // The default match option to select, can be overriden if the component finds a more specific match.
        onlyShowDefaultSelectedOption: '<', // if true, will hide the other options
        orderByTonkeanField: '<', // If true the order by field will be directed at a tonkean field instead of a datasource field
        viewOnly: '<', // If true, the user can't edit anything.

        savedMatchOptionSelection: '<',
        onMatchOptionSelectionChanged: '&',
        modFixedWidth: '<', // Setting it true will prevent the expression field input from expending.
        tabsFieldSelectorModNarrow: '<', // Making the width of the tnkTabsFieldSelector narrower.
        tabsFieldSelectorModFixedWidth: '<', // Making the width of the tnkTabsFieldSelector fixed.

        verticalMod: '<', // Changing the custom filters layout to be vertical aligned
    },
    template,
    controller: 'MatchOptionSelectionCtrl',
});
