import React, { useEffect, useMemo } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import ProjectIntegrationActionTestResponseStatusCode from '../ProjectIntegrationActionTestResponseStatusCode';

import { SimpleSelect, usePrevious } from '@tonkean/infrastructure';
import type { ProjectIntegrationActionTestRun } from '@tonkean/tonkean-entities';
import { ProjectIntegrationActionDefinitionType } from '@tonkean/tonkean-entities';
import utils from '@tonkean/utils';

const OptionContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
`;

interface Props {
    testRuns: ProjectIntegrationActionTestRun[];
    isLoading: boolean;
    selectedTestRunIds: string[] | undefined;
    onTestRunSelected: (testRunId: string[]) => void;
    autoSelectFirstTestRun?: boolean;
    customTestName?: string;
}

const ProjectIntegrationActionTestRunSelector: React.FC<Props> = ({
    testRuns,
    isLoading,
    selectedTestRunIds,
    onTestRunSelected,
    autoSelectFirstTestRun = false,
    customTestName,
}) => {
    // Saving the previous value of isLoading - when autoSelectFirstTestRun is enabled we auto select the first value after the test run reloaded.
    const prevIsLoading = usePrevious(isLoading);

    const testRunsOptions: { value: string[]; label: ReactNode }[] = useMemo(() => {
        const nonGroupedTestRunId = 'nonGroupedTestRunId- ';
        let uniqueIdentifier = 0;

        // Grouping all the grouped test (paginated requests).
        const groupIdToTestRun: Record<string, ProjectIntegrationActionTestRun[]> = testRuns.reduce((prev, cur) => {
            const testRunGroupId = cur.testRunGroupMetadata
                ? cur.testRunGroupMetadata.testRunGroupId
                : nonGroupedTestRunId + uniqueIdentifier++;

            if (prev[testRunGroupId]) {
                prev[testRunGroupId] = [...prev[testRunGroupId], cur];
            } else {
                prev[testRunGroupId] = [cur];
            }

            return prev;
        }, {});

        // get the oldest test foreach group and map the tests to select options
        return Object.entries(groupIdToTestRun).map(([, groupedTestRuns]) => {
            const isTestRunGroup = groupedTestRuns.length > 1;

            const sortedTests = groupedTestRuns.sort((testA, testB) => testA.created - testB.created);

            const firstTestInGroup = sortedTests[0];

            const formattedDate = firstTestInGroup
                ? utils.formatDate(firstTestInGroup.created, true, false, true, true)
                : '';

            const label = `${
                customTestName
                    ? customTestName
                    : `${firstTestInGroup ? firstTestInGroup.projectIntegrationAction.displayName : 'Unknown Test'} - `
            } ${formattedDate} ${isTestRunGroup ? `- (${groupedTestRuns.length} Pages)` : ''}`;

            const lastRetryOfFirstTest = firstTestInGroup?.executionResultList.slice(-1)[0];

            return {
                label: (
                    <OptionContainer>
                        {label}
                        {lastRetryOfFirstTest?.type === ProjectIntegrationActionDefinitionType.HTTP &&
                            lastRetryOfFirstTest.response?.responseCode && (
                                <ProjectIntegrationActionTestResponseStatusCode
                                    statusCode={lastRetryOfFirstTest.response.responseCode}
                                    hideIndicator
                                />
                            )}
                    </OptionContainer>
                ),
                value: sortedTests.map((test) => test.id),
            };
        });
    }, [customTestName, testRuns]);

    // Auto select first testRun
    useEffect(() => {
        if (autoSelectFirstTestRun && !isLoading && prevIsLoading && testRunsOptions?.[0]?.value) {
            onTestRunSelected(testRunsOptions[0].value);
        }
    }, [autoSelectFirstTestRun, isLoading, onTestRunSelected, prevIsLoading, selectedTestRunIds, testRunsOptions]);

    return (
        <SimpleSelect
            isDisabled={!testRunsOptions || testRunsOptions.length === 0}
            placeholder={isLoading ? 'Loading test runs...' : 'Pick test run...'}
            value={selectedTestRunIds}
            isLoading={isLoading}
            options={testRunsOptions}
            onChange={onTestRunSelected}
            menuPosition="fixed"
            thin
        />
    );
};

export default ProjectIntegrationActionTestRunSelector;
