<div class="">
    <form name="tags-form" class="clearfix">
        <div class="margin-none" style="min-width: 220px">
            <tnk-tags-input
                class="flex-grow"
                tags="popover.initiativeTags"
                latest-tag-search="latestTagSearch"
                selected-item-template="initiative-tag-selected-item"
                auto-complete-template="initiative-tag-autocomplete-item"
                load-on-empty="true"
                placeholder="Add relevant topics..."
            ></tnk-tags-input>
            <div class="text-right margin-top">
                <a class="btn btn-default btn-sm btn-no-border margin-left" ng-click="close()">Cancel</a>
                <button
                    type="submit"
                    class="btn btn-primary margin-left"
                    ng-click="updateTags(popover.initiativeTags);"
                >
                    Save
                </button>
            </div>
        </div>
    </form>
</div>
