import { useAngularService } from 'angulareact';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import OAuthPermissionType from './OAuthPermissionType';

import { Radio, RadioGroup } from '@tonkean/infrastructure';
import { GoogleAuthenticationType } from '@tonkean/tonkean-entities';
import { IntegrationType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';
import { Theme } from '@tonkean/tui-theme';

interface Props {
    authenticate(config: Record<string, any>, projectIntegrationData?: any, integrationDisplayName?: string): void;
    integrationDisplayName: string;
    googleIntegrationType: IntegrationType;
    initialOAuthPermissionType?: OAuthPermissionType;
    shouldIndexContent: boolean | undefined;
}

const AuthenticateButton = styled(Button)`
    width: 285px;
    height: 41px;
    margin: auto auto 15px;
`;

const IntegrationPermissionText = styled.span`
    color: ${Theme.colors.gray_600};
    margin-bottom: 7px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const PermissionSelection = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const GoogleOAuthAuthentication: React.FC<Props> = ({
    authenticate,
    integrationDisplayName,
    googleIntegrationType,
    initialOAuthPermissionType,
    shouldIndexContent,
}) => {
    const environment = useAngularService('environment');
    const oAuthHandler = useAngularService('oauthHandler');

    const [oauthPermissionType, setOauthPermissionType] = useState<OAuthPermissionType | undefined>(
        initialOAuthPermissionType || OAuthPermissionType.readwrite,
    );
    const [shouldDisplayPermissionTypeComponent, setShouldDisplayPermissionTypeComponent] = useState<boolean>(false);

    useEffect(() => {
        // List of all integrations that require permission selection (read write, read only)
        const integrationsRequiringPermissionSelection = [IntegrationType.GOOGLEDRIVE];

        if (integrationsRequiringPermissionSelection.includes(googleIntegrationType)) {
            setShouldDisplayPermissionTypeComponent(true);
        }
    }, [googleIntegrationType]);

    const googleIntegrationScope = useMemo(() => {
        switch (googleIntegrationType) {
            case IntegrationType.GOOGLEDRIVE: {
                let googlePermissionSuffix = '';
                if (oauthPermissionType === OAuthPermissionType.readonly) {
                    googlePermissionSuffix = '.readonly';
                }
                return `https://www.googleapis.com/auth/drive${googlePermissionSuffix}`;
            }
            case IntegrationType.GOOGLESHEETS: {
                // Google expects no suffix to enable read-write permissions.
                let googlePermissionSuffix = '';
                if (oauthPermissionType === OAuthPermissionType.readonly) {
                    googlePermissionSuffix = '.readonly';
                }
                return `https://www.googleapis.com/auth/drive${googlePermissionSuffix} https://www.googleapis.com/auth/spreadsheets${googlePermissionSuffix}`;
            }
            case IntegrationType.GOOGLEGROUPS: {
                const googleGroupsScope =
                    'https://www.googleapis.com/auth/apps.groups.settings ' +
                    'https://www.googleapis.com/auth/admin.directory.group.' +
                    'readonly https://www.googleapis.com/auth/admin.directory.group ' +
                    'https://www.googleapis.com/auth/admin.directory.user.readonly ' +
                    'https://www.googleapis.com/auth/admin.directory.user ' +
                    'https://www.googleapis.com/auth/cloud-platform ' +
                    'https://www.googleapis.com/auth/admin.directory.group.member.readonly ' +
                    'https://www.googleapis.com/auth/admin.directory.group.member ' +
                    'https://www.googleapis.com/auth/admin.directory.group.readonly ' +
                    'https://www.googleapis.com/auth/admin.directory.group ' +
                    'https://www.googleapis.com/auth/admin.directory.orgunit ' +
                    'https://www.googleapis.com/auth/admin.directory.user.security';
                return googleGroupsScope;
            }
        }
    }, [googleIntegrationType, oauthPermissionType]);

    const authenticateCallback = async () => {
        const redirectUri = environment.integrationKeysMap.googleUri;
        const clientId = environment.integrationKeysMap.google;
        const code = await oAuthHandler.google(clientId, googleIntegrationScope, redirectUri, true);

        const config = { code, redirectUri, environment: GoogleAuthenticationType.PUBLIC, shouldIndexContent };
        const additionalData = {
            oauthPermissionType,
            environment: GoogleAuthenticationType.PUBLIC,
        };
        authenticate(config, additionalData);
    };

    return (
        <div>
            {integrationDisplayName && (
                <ContentWrapper>
                    <AuthenticateButton
                        data-automation="google-Authentication-button"
                        onClick={authenticateCallback}
                        outlined
                    >
                        {`Authenticate with ${integrationDisplayName}`}
                    </AuthenticateButton>

                    {shouldDisplayPermissionTypeComponent && (
                        <PermissionSelection>
                            <IntegrationPermissionText>Integration permissions</IntegrationPermissionText>
                            <RadioGroup
                                value={oauthPermissionType}
                                onChange={setOauthPermissionType}
                                direction="column"
                            >
                                <Radio value={OAuthPermissionType.readwrite}>Read/Write</Radio>
                                <Radio value={OAuthPermissionType.readonly}>Read Only</Radio>
                            </RadioGroup>
                        </PermissionSelection>
                    )}
                </ContentWrapper>
            )}
        </div>
    );
};

export default GoogleOAuthAuthentication;
