/**
 * Helps querying the current license permissions according to a wanted feature.
 */
function LicensePermissionsService(
    projectManager,
    authenticationService,
) {
    /**
     * *************************************************************************************************************
     * PLEASE NOTE: All of the functions here should be treated as if they will be called from within a digest loop,
     * so they should be super lightweight and not run any complicated calculations.
     * *************************************************************************************************************
     */

    const _this = this;

    _this.pricingVersions = {
        V1: 'V1',
        V2: 'V2',
    };

    /**
     * Checks if the current pricing version is the given version.
     * @param version - a pricing version, from the pricingVersion dictionary.
     * @returns {boolean}
     */
    _this.isPricingVersion = function (version) {
        if (version && _this.pricingVersions[version] && projectManager.project) {
            return projectManager.project.pricingVersion === _this.pricingVersions[version];
        }

        return false;
    };

    _this.isV2AndUserNotLicensed = function (person) {
        if (!person) {
            return true;
        }

        return (
            isV2() &&
            (projectManager.isLicensed || projectManager.project.isLimitedLicense || projectManager.isEnterprise) &&
            (!person.projectContext || !person.projectContext.isLicensed)
        );
    };

    _this.isEnterpriseUser = function () {
        return (
            authenticationService.currentUser &&
            authenticationService.currentUser.enterprise &&
            authenticationService.currentUser.enterprise.id
        );
    };

    _this.isV2AndUserLicensed = function (person) {
        return (
            person &&
            isV2() &&
            (projectManager.isLicensed || projectManager.project.isLimitedLicense) &&
            person.projectContext &&
            person.projectContext.isLicensed
        );
    };

    _this.isV2NotLicensed = function () {
        return (
            isV2() &&
            !projectManager.isLicensed &&
            !projectManager.project.isLimitedLicense &&
            !projectManager.isEnterprise
        );
    };

    _this.isV2TrialNotLicensed = function () {
        return _this.isV2NotLicensed() && projectManager.project.isInTrial;
    };

    _this.isV1Licensed = function () {
        return isV1() && projectManager.isLicensed;
    };

    _this.isV1TrialLicensed = function () {
        return _this.isV1Licensed() && projectManager.isInTrial;
    };

    _this.canPersonUpdate = function (person) {
        return (
            projectManager.project.isEnterprise ||
            projectManager.project.isInTrial ||
            isV1() ||
            ((projectManager.isLicensed || projectManager.project.isLimitedLicense) &&
                person &&
                person.projectContext &&
                (person.projectContext.isLicensed || person.projectContext.isInPreview))
        );
    };

    _this.supportsAssignableSeats = function () {
        return projectManager.isLicensed || (isV2() && projectManager.project.isLimitedLicense);
    };

    _this.shouldGoToExpiredPage = function () {
        if (projectManager.isExpired) {
            return true;
        }

        if (isV2() && !projectManager.isUserLicensed) {
            return true;
        }

        return false;
    };

    _this.shouldGoToTracksBoxPage = function () {
        if (!projectManager.isExpired && isV1() && !projectManager.isUserLicensed) {
            return true;
        }

        return false;
    };

    // region: private functions

    function isV1() {
        return _this.isPricingVersion(_this.pricingVersions.V1);
    }

    function isV2() {
        return _this.isPricingVersion(_this.pricingVersions.V2);
    }

    // endregion: private functions
}

angular.module('tonkean.app').service('licensePermissionsService', LicensePermissionsService);
