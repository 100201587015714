import { useAngularService } from 'angulareact';
import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
    dayInMonthOptions,
    dayInWeekOptions,
    frequencyOptions,
    hoursOfDayOptions,
    minuteOptions,
    monthOptions,
    timezoneOptions,
} from './recurrenceSelectionsOptions';
import useRecurrenceSelectionValidation from './useRecurrenceSelectionValidation';
import InvalidLogicsValidationContext from '../../../ItemDetailsModuleEditorSidePane/InvalidLogicsValidationContext';

import {
    ButtonToggleGroup,
    ButtonToggleGroupButton,
    Checkbox,
    Field,
    H4,
    Input,
    Paragraph,
    SimpleSelect,
    useFormikField,
} from '@tonkean/infrastructure';
import { RecurrencePeriodType } from '@tonkean/tonkean-entities';
import { Theme } from '@tonkean/tui-theme';
import { yupEnum } from '@tonkean/utils';

const RecurrencePeriodTypeExplainer = styled.div`
    color: ${Theme.colors.gray_600};
    margin-top: 3px;
    margin-left: 3px;
`;

const TimePickerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: stretch;
`;

const TimeSimpleSelect = styled(SimpleSelect)`
    flex-grow: 1;
`;

const DayInWeekButtonToggleGroupButton = styled(ButtonToggleGroupButton)`
    // make the buttons fit exactly the width of the panel (no overflow and stretch)
    padding: 0;
    flex-grow: 1;
`;

const MonthsInYearButtonToggleGroup = styled(ButtonToggleGroup)`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 20px;
`;

const MonthsInYearButtonToggleGroupButton = styled(ButtonToggleGroupButton)`
    // We need to round the edge buttons but making two rows is kind of niche and complicateed when generic so no infra support
    &:nth-child(6) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    &:nth-child(7) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
`;

interface Props {
    setValidationSchema: (schema: any) => void;
}

const ScheduledInputSourceRecurrenceSelection: React.FC<Props> = ({ setValidationSchema }) => {
    const projectManager = useAngularService('projectManager');
    const { value: recurrencePeriodType } = useFormikField<RecurrencePeriodType>('recurrencePeriodType');

    const { invalidLogics } = useContext(InvalidLogicsValidationContext);
    const { values, setTouched } = useFormikContext();
    const allValuesTouched = useMemo(() => {
        return Object.fromEntries(Object.keys(values as any).map((key) => [key, true]));
    }, [values]);
    // When the invalid logics is set that means we have triggered module-wide validation and want to retrigger this form validation,
    // so we set touched on all the values keys
    useEffect(() => {
        setTouched(allValuesTouched);
    }, [allValuesTouched, invalidLogics.DATASOURCE, setTouched]);

    const validationObject = useRecurrenceSelectionValidation(
        recurrencePeriodType,
        projectManager.project.features.tonkean_feature_minimum_scheduled_input_source_interval_minutes ?? 30,
    );

    useEffect(() => {
        setValidationSchema(
            Yup.object({
                ...validationObject,
                recurrencePeriodType: yupEnum(RecurrencePeriodType).required('Select recurrence frequency'),
                doNotRunOnWeekends: Yup.boolean(),
            }),
        );
    }, [setValidationSchema, validationObject]);

    return (
        <>
            <Field label={<H4>How frequently should the module run?</H4>}>
                <SimpleSelect placeholder="Pick frequency" name="recurrencePeriodType" options={frequencyOptions} />
                {recurrencePeriodType === RecurrencePeriodType.Quarterly && (
                    <RecurrencePeriodTypeExplainer>
                        Will run on the 1st of Jan, Apr, Jul and Oct
                    </RecurrencePeriodTypeExplainer>
                )}
            </Field>

            <Field>
                <Checkbox name="doNotRunOnWeekends">Do not run on weekends</Checkbox>
                <Paragraph $light>
                    In case where recurrence time is on a weekend, it will run on the next work day.
                </Paragraph>
            </Field>
            {recurrencePeriodType && (
                <>
                    {validationObject.recurrenceMonthsInYear && (
                        <Field label={<H4>Choose Months</H4>}>
                            <MonthsInYearButtonToggleGroup name="recurrenceMonthsInYear">
                                {monthOptions.map((option) => (
                                    <MonthsInYearButtonToggleGroupButton key={option.value} value={option.value}>
                                        {option.label}
                                    </MonthsInYearButtonToggleGroupButton>
                                ))}
                            </MonthsInYearButtonToggleGroup>
                        </Field>
                    )}

                    {/* need to do weekly day selection in toggle button group */}
                    {validationObject.recurrenceDaysInWeek && (
                        <Field label={<H4>Choose Days</H4>}>
                            <ButtonToggleGroup name="recurrenceDaysInWeek">
                                {dayInWeekOptions.map((option) => (
                                    <DayInWeekButtonToggleGroupButton key={option.value} value={option.value}>
                                        {option.label}
                                    </DayInWeekButtonToggleGroupButton>
                                ))}
                            </ButtonToggleGroup>
                        </Field>
                    )}

                    {validationObject.recurrenceDaysInMonth && (
                        <Field label={<H4>Choose Day</H4>}>
                            <SimpleSelect name="recurrenceDaysInMonth" options={dayInMonthOptions} />
                        </Field>
                    )}

                    {validationObject.recurrenceHour && validationObject.recurrenceMinute && (
                        <Field label={<H4>Choose Time</H4>}>
                            <TimePickerWrapper>
                                <TimeSimpleSelect name="recurrenceHour" options={hoursOfDayOptions} />
                                :
                                <TimeSimpleSelect name="recurrenceMinute" options={minuteOptions} />
                            </TimePickerWrapper>
                        </Field>
                    )}

                    {validationObject.everyXHours && (
                        <Field label={<H4>Hours</H4>}>
                            <Input type="number" name="everyXHours" />
                        </Field>
                    )}

                    {validationObject.everyXMinutes && (
                        <Field label={<H4>Minutes</H4>}>
                            <Input type="number" name="everyXMinutes" />
                        </Field>
                    )}

                    {validationObject.timezone && (
                        <Field label={<H4>Pick Time Zone</H4>}>
                            <SimpleSelect name="timezone" options={timezoneOptions} />
                        </Field>
                    )}
                </>
            )}
        </>
    );
};

export default ScheduledInputSourceRecurrenceSelection;
