import { getTonkeanQueryParticipatingFilters } from './getTonkeanQueryParticipatingFilters';

import type { FieldQuery } from '@tonkean/tonkean-entities';

/**
 * Recursively getting all the field definitions participating in query definition.
 */
export function getTonkeanQueryParticipatingFieldNames(query: FieldQuery) {
    return getTonkeanQueryParticipatingFilters(query)
        .filter((filter) => filter?.fieldName)
        .map((filter) => filter.fieldName);
}
