import React from 'react';
import styled, { keyframes } from 'styled-components';

import SolutionMapperGraphEdgeWrapper from './SolutionMapperGraphEdgeWrapper';

const rotatingSpinnerMaskAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;
const RotatingSpinnerMask = styled.path`
    animation: ${rotatingSpinnerMaskAnimation} 1s infinite;
    animation-timing-function: linear;
    transform-origin: center;
    transform-box: fill-box;
`;

const graphConfig = {
    nodeTypes: {
        defaultNode: {
            shape: (
                <symbol width="181" height="181" viewBox="0 0 181 181" id="defaultNode">
                    <circle cx="90.5" cy="90.5" r="90.5" fill="transparent" stroke="transparent" />
                </symbol>
            ),
            shapeId: '#defaultNode',
            typeText: 'None',
        },
    },
    nodeSubtypes: {},
    edgeTypes: {
        defaultEdge: {
            shapeId: '#defaultEdge',
            shape: (
                <SolutionMapperGraphEdgeWrapper id="defaultEdge">
                    <circle cx={24.316} cy={30.504} r={2} transform="rotate(-26.06 24.316 30.504)" fill="#9CA6B2" />
                    <circle cx={29.745} cy={23.396} r={2} transform="rotate(-26.06 29.745 23.396)" fill="#9CA6B2" />
                    <circle cx={33.26} cy={30.583} r={2} transform="rotate(-26.06 33.26 30.583)" fill="#9CA6B2" />
                    <path d="M24.994 29.616l3.623-5.111M25.434 30.514l6.258.279" stroke="#9CA6B2" />
                </SolutionMapperGraphEdgeWrapper>
            ),
        },
        loadingEdge: {
            shapeId: '#loadingEdge',
            shape: (
                <SolutionMapperGraphEdgeWrapper id="loadingEdge">
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="19" y="20" width="18" height="18">
                        <RotatingSpinnerMask
                            d="M35 29C35 32.866 31.866 36 28 36C24.134 36 21 32.866 21 29C21 25.134 24.134 22 28 22"
                            stroke="#39B3D6"
                            strokeWidth="3"
                        />
                    </mask>
                    <g mask="url(#mask0)">
                        <circle cx="28" cy="29" r="7" stroke="#39B3D6" strokeWidth="3" />
                    </g>
                </SolutionMapperGraphEdgeWrapper>
            ),
        },
    },
};

export default graphConfig;
