import { useAngularService } from 'angulareact';
import { useMemo } from 'react';

import type { IntegrationType } from '@tonkean/tonkean-entities';

const useNativeActionsFromConsts = (integrationType: IntegrationType | string | undefined) => {
    const integrationConsts = useAngularService('integrationsConsts');

    return useMemo(() => {
        if (integrationType) {
            const projectIntegrationCustomActionsConfig =
                integrationConsts.getActionsByIntegrationsMap()[integrationType]?.customActions;

            return Object.entries<{ displayName: string; description?: string }>(
                projectIntegrationCustomActionsConfig || {},
            ).map(([key, { description, displayName }]) => ({
                id: key,
                displayName,
                description,
            }));
        } else {
            return [];
        }
    }, [integrationConsts, integrationType]);
};

export default useNativeActionsFromConsts;
