import type LogicBlockValidationObject from '../entities/LogicBlockValidationObject';
import type LogicBlockConfigDefinition from '../LogicBlockConfigDefinition';
import addNewLogicOption from '../sharedConfigComponents/addNewLogicOption';
import validateNoEmptyConditions from '../sharedConfigComponents/validators/validateNoEmptyConditions';

import { LogicBlockConfigType } from '@tonkean/tonkean-entities';

const AutonomousLogicBlockConfig: LogicBlockConfigDefinition = {
    type: LogicBlockConfigType.AUTONOMOUS,
    secondaryType: LogicBlockConfigType.AUTONOMOUS_MATCH_CONDITIONS,
    secondaryIconClass: 'icon-match-condition',
    iconClass: 'mod-trigger',
    title: 'Match Conditions',
    description: 'Your data is always monitored. Choose when this part of the flow should continue.',
    longDescription:
        'This trigger will run when a new item enters the flow, as long as it matches the condition below. Note: this will run on existing items as well.',
    showWhenDo: true,
    cantChangeType: () => true,
    doNotDisplayFilterEdit: true,
    doNotDisplaySettingsEdit: true,
    doNotDisplayStatusEdit: false,
    doNotDisplayNotificationsEdit: true,
    doesntHaveDefinition: true,
    cantDrag: true,
    createAfterOptions: () => [addNewLogicOption],
    validator: ({ customTrigger }) => {
        const validationObject: LogicBlockValidationObject = {};

        validateNoEmptyConditions('Match conditions', customTrigger, validationObject);

        return Object.keys(validationObject).length > 0 ? validationObject : null;
    },
};

export default AutonomousLogicBlockConfig;
