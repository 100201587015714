import { lateConstructController } from '@tonkean/angular-components';

/* @ngInject */
function NetsuiteCustomActionsDefinitionCtrl($scope, integrations, utils) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        invalidLogics: ctrl.invalidLogics,
        validationObject: ctrl.validationObject,
        isIntegrationGenericAction: ctrl.isIntegrationGenericAction,
        filterByIntegrations: integrations.getSupportedDownloadingStorageIntegrations(),
        definition: ctrl.definition,
    };

    $scope.init = function () {
        if ($scope.data.definition.extendedMatchConfiguration && !$scope.data.definition.fileSourceType) {
            $scope.data.definition.fileSourceType = 'DATA_STORAGE';
            $scope.onDefinitionChanged(false);
        }
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic) {
        ctrl.onDefinitionChanged?.({ shouldSaveLogic });
    };

    /**
     * File Source Selection
     */

    $scope.onWorkerFileSelectionChanged = function (
        fileSourceType,
        storageIntegrationId,
        extendedMatchConfiguration,
        urlExpression,
        workerStaticAssetId,
        workerStaticAssetDisplayName,
        parametersValues,
        shouldSaveLogic,
        storageIntegrationActionId,
    ) {
        $scope.data.definition.fileSourceType = fileSourceType;
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.storageIntegrationActionId = storageIntegrationActionId;
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.data.definition.url = urlExpression;
        $scope.data.definition.workerStaticAssetId = workerStaticAssetId;
        $scope.data.definition.workerStaticAssetDisplayName = workerStaticAssetDisplayName;
        $scope.data.definition.parametersValues = parametersValues;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onWorkerTargetStorageSelectionChanged = function (storageIntegrationId, newDocumentName, shouldSaveLogic) {
        $scope.data.definition.storageIntegrationId = storageIntegrationId;
        $scope.data.definition.newDocumentName = newDocumentName;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onStorageProjectIntegrationSelected = (selectedProjectIntegration) => {
        $scope.data.definition.storageIntegrationId = selectedProjectIntegration?.id;
        $scope.onDefinitionChanged(true);
    };

    $scope.onAttachmentExtendedMatchConfigurationChanged = (extendedMatchConfiguration, shouldSaveLogic) => {
        $scope.data.definition.extendedMatchConfiguration = extendedMatchConfiguration;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Upload File
     */

    $scope.onSuiteScriptIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.suiteScriptIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onSuiteScriptDeployVersionTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.suiteScriptDeployVersionExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFileNameTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fileNameExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTargetFolderIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.targetFolderIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onFileDescriptionTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fileDescriptionExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Attach File
     */

    $scope.onFileIdTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.fileIdExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onTransactionTypeTonkeanExpressionChanged = function (expression, shouldSaveLogic) {
        $scope.data.definition.transactionTypeExpression = expression;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.copyToClipboard = function (elementId) {
        const elem = document.getElementById(elementId);
        const copyResult = utils.copyToClipboardFromText(elem.innerHTML);

        if (copyResult) {
            $scope.$emit('alert', {
                msg: `${elem.title} copied to clipboard`,
                type: 'success',
            });
        } else {
            $scope.$emit('alert', {
                msg: 'There was a problem copying to clipboard',
                type: 'error',
            });
        }
    };
}

export default angular
    .module('tonkean.app')
    .controller('NetsuiteCustomActionsDefinitionCtrl', lateConstructController(NetsuiteCustomActionsDefinitionCtrl));
