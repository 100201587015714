import { lateConstructController } from '@tonkean/angular-components';
import { getFirstCustomTriggerAction } from '@tonkean/tonkean-utils';

/* @ngInject */
function SendGridCustomActionsDefinitionCtrl(
    $scope,
    modal,
    customTriggerManager,
) {
    const ctrl = this;

    $scope.data = {
        groupId: ctrl.groupId,
        workflowVersionId: ctrl.workflowVersionId,
        projectIntegration: ctrl.projectIntegration,
        configuredLogic: ctrl.configuredLogic,
        previewEvaluationSource: ctrl.previewEvaluationSource,
        definition: ctrl.definition, // The action definition.
        fieldDefinitionsToCreate: ctrl.fieldDefinitionsToCreate, // Definitions to create.
        validationObject: ctrl.validationObject,
        matchConfigurationObject: ctrl.matchConfigurationObject,
        monitorRepliesOnOriginalMessage: {
            isActive: false,
        },
        loading: false,
    };

    ctrl.$onInit = function () {
        if ($scope.data.configuredLogic && $scope.data.definition) {
            $scope.data.loading = true;

            initializeEditMode();

            $scope.data.loading = false;
        }
    };

    ctrl.$onChanges = function (changes) {
        if (changes.validationObject) {
            $scope.data.validationObject = ctrl.validationObject;
        }
    };

    /**
     * Occurs once recipients configuration changed.
     */
    $scope.onRecipientsConfigurationChanged = function (configuration, shouldSaveLogic, array) {
        $scope.data.definition.recipientsConfiguration = configuration;
        $scope.data.definition.toRecipientsArray = array;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once CC configuration changed.
     */
    $scope.onCCConfigurationChanged = function (configuration, shouldSaveLogic, array) {
        $scope.data.definition.ccConfiguration = configuration;
        $scope.data.definition.ccRecipientsArray = array;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once BCC configuration changed.
     */
    $scope.onBCCConfigurationChanged = function (configuration, shouldSaveLogic, array) {
        $scope.data.definition.bccConfiguration = configuration;
        $scope.data.definition.bccRecipientsArray = array;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onContentTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.content = originalExpression;
        $scope.data.definition.contentEvaluatedText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onFromNameTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.fromName = originalExpression;
        $scope.data.definition.fromNameEvaluatedText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onFromEmailTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.fromEmail = originalExpression;
        $scope.data.definition.fromEmailEvaluatedText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Occurs once tonkean expression has changed.
     */
    $scope.onSubjectTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.emailSubject = originalExpression;
        $scope.data.definition.emailSubjectEvaluatedText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onReplyToTonkeanExpressionChanged = function (originalExpression, evaluatedExpression, shouldSaveLogic) {
        $scope.data.definition.replyTo = originalExpression;
        $scope.data.definition.replyToEvaluatedText = evaluatedExpression;

        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    $scope.onDefinitionChanged = function (shouldSaveLogic, childrenCustomTriggersToCreate) {
        if ($scope.data.matchConfigurationObject) {
            $scope.data.matchConfigurationObject.matchOptionApiName = 'NONE';
        }

        return ctrl.onDefinitionChanged({
            shouldSaveLogic,
            childrenToCreate: childrenCustomTriggersToCreate,
        });
    };

    $scope.onAddUnsubscribeLinkChanged = function () {
        $scope.data.definition.addUnsubcribeLink = !$scope.data.definition.addUnsubcribeLink;

        $scope.onDefinitionChanged(true);
    };

    $scope.openHtmlEditor = function () {
        let exampleInitiative = null;
        if (customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId]) {
            exampleInitiative =
                customTriggerManager.workflowVersionIdToExampleItemsMap[$scope.data.workflowVersionId][
                    $scope.data.configuredLogic.node.id
                ];
        }

        modal
            .openHtmlEditor(
                $scope.data.groupId,
                $scope.data.definition.content,
                exampleInitiative,
                false,
                $scope.data.configuredLogic.node.id,
                $scope.data.workflowVersionId,
            )
            .then((result) => {
                $scope.onContentTonkeanExpressionChanged(result.html, result.evaluatedHtml, true);
            });
    };

    $scope.onAttachmentsChanged = function (attachments, shouldSaveLogic) {
        $scope.data.definition.attachments = attachments;
        $scope.onDefinitionChanged(shouldSaveLogic);
    };

    /**
     * Initializes edit mode of the logic configuration component.
     */
    function initializeEditMode() {
        const customTriggerAction = getFirstCustomTriggerAction(
            $scope.data.configuredLogic.node.customTriggerActions,
            'PERFORM_INTEGRATION_ACTION',
        );

        if (customTriggerAction) {
            // Do something.
        } else {
            $scope.data.definition.sendAsPlainText = false;
        }
    }
}

export default angular
    .module('tonkean.app')
    .controller('SendGridCustomActionsDefinitionCtrl', lateConstructController(SendGridCustomActionsDefinitionCtrl));
