import { useEffect, useMemo } from 'react';

import getItemsGridErrors from './getItemsGridErrors';
import { isNotRealRow, isRowLoading } from '../entities';
import type { FieldValidationParams } from '../entities';
import type FieldValidation from '../entities/FieldValidation';
import type InitiativeRowData from '../entities/InitiativeRowData';

const useItemsValidationsNotifier = (
    items: InitiativeRowData[] | undefined,
    minimumItems: number,
    validations: FieldValidation<FieldValidationParams>[] | undefined,
    onValidationResultChange: ((errors: string[]) => void) | undefined,
    isReady: boolean,
) => {
    const realRows = useMemo(() => {
        return items?.filter((row) => !isNotRealRow(row)) || [];
    }, [items]);

    const errors = useMemo(() => {
        return getItemsGridErrors(realRows, validations || [], minimumItems);
    }, [minimumItems, realRows, validations]);

    const isSomeItemInLoadingState = useMemo(() => {
        return items?.some((item) => {
            return isRowLoading(item);
        });
    }, [items]);

    useEffect(() => {
        if (isReady && onValidationResultChange && !isSomeItemInLoadingState) {
            onValidationResultChange(errors);
        }
    }, [errors, isReady, isSomeItemInLoadingState, onValidationResultChange]);
};
export default useItemsValidationsNotifier;
