import useBreakpoint from './useBreakpoint';
import { Breakpoint } from '../components/BreakpointManager';

/**
 * A hook that returns whether the current breakpoint is mobile
 *
 * @returns the is mobile flag
 */
export default function useIsMobile(): boolean {
    const effectiveBreakpoint = useBreakpoint();
    return effectiveBreakpoint <= Breakpoint.MID_XSMALL_768;
}
