<div class="flex-vmiddle flex-grow flex-col flex-space-between">
    <!-- OAuth only integrations buttons -->

    <div
        class="tnk-tooltip mod-top"
        tnk-require="{{
            $root.pm.projectData.allowIntegrationsInTrial
                ? 'free'
                : integration && integrationRequireMap[integration.name]
                ? integrationRequireMap[integration.name]
                : defaultLicenseTierToAllow
        }}"
    >
        <!-- Using tnk-tooltip and not uib-tooltip cause the latter doesn't work on disabled buttons -->
        <span class="tnk-tooltip-text" ng-if="ngDisabled && ngDisabledTooltip">{{ ngDisabledTooltip }}</span>
        <!-- Will be shown if the integration is not at all connected -->
        <button
            type="button"
            class="btn btn-primary"
            ng-click="integration.login(integration)"
            ng-disabled="projectIntegration.connecting || ngDisabled"
            analytics-on
            analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
            analytics-event="Authenticate"
            analytics-label="{{ integration.name }}"
            ng-hide="projectIntegration || inlineMode"
        >
            {{ projectIntegration.connecting ? 'Connecting' : 'Connect' }}
        </button>

        <div
            ng-if="projectIntegration && (projectIntegration.valid === false || projectIntegration.disabled)"
            class="btn-group flex"
            uib-dropdown
            dropdown-append-to-body
        >
            <!-- Will be shown if the integration is invalid and needs fixing -->
            <button
                type="button"
                class="btn btn-warning"
                ng-click="loginCommunicationIntegration(integration, projectIntegration, false)"
                ng-disabled="ngDisabled"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Fix"
                analytics-label="{{ integration.name }}"
            >
                <span class="relative">
                    Fix
                    <i
                        ng-if="isFixingMap[integration.name]"
                        class="loading-small mod-white absolute-right margin-right-md-minus margin-top-xxs"
                    ></i>
                </span>
            </button>

            <!-- Options dropdown -->
            <button type="button" class="btn btn-warning" uib-dropdown-toggle ng-disabled="ngDisabled">
                <span class="caret"></span>
                <span class="sr-only">More options</span>
            </button>
            <ul uib-dropdown-menu role="menu" aria-labelledby="split-button">
                <li role="menuitem">
                    <tnk-project-integration-remove-modal
                        project-integration-id="projectIntegration.id"
                        project-integration-display-name="projectIntegration.displayName"
                        warning-message="deleteWarningMessage(projectIntegration)"
                    ></tnk-project-integration-remove-modal>
                </li>
            </ul>
        </div>

        <!-- Will be shown if the integration exists and is valid -->
        <!-- $scope.state is map of IntegrationType to Collection of Project Integrations. For example -
            jira: { integrations : [ {prin}, {prin}, ... ] }
        -->
        <div
            class="btn-group flex edit-communication-integration"
            data-automation="communication-integration-group-button-template-template-edit-communication-integration"
            uib-dropdown
            dropdown-append-to-body
            ng-class="{ disabled: ngDisabled || !pm.isOwner }"
            tooltip-enable="ngDisabled || !pm.isOwner"
            uib-tooltip="Only board owners can edit communication sources"
            ng-hide="
                fixMode && projectIntegration && projectIntegration.valid !== false && !projectIntegration.disabled
            "
            ng-if="projectIntegration && projectIntegration.valid !== false && !projectIntegration.disabled"
        >
            <button
                type="button"
                class="btn btn-default"
                ng-click="!ngDisabled  && pm.isOwner && loginCommunicationIntegration(integration, projectIntegration, null)"
                analytics-on
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ integration.name }}"
            >
                Edit
            </button>
            <button type="button" class="btn btn-default" uib-dropdown-toggle ng-disabled="ngDisabled || !pm.isOwner">
                <span class="caret"></span>
                <span class="sr-only">More options</span>
            </button>
            <ul uib-dropdown-menu role="menu" aria-labelledby="split-button">
                <li role="menuitem">
                    <a ng-click="setDefaultCommunicationIntegration(projectIntegration)">Set Default</a>
                </li>
                <li class="divider"></li>

                <li role="menuitem">
                    <tnk-project-integration-remove-modal
                        project-integration-id="projectIntegration.id"
                        project-integration-display-name="projectIntegration.displayName"
                        title="deleteTitle(projectIntegration)"
                        warning-message="deleteWarningMessage(projectIntegration)"
                    ></tnk-project-integration-remove-modal>
                </li>
            </ul>
        </div>
    </div>
</div>
