import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import EnterpriseComponentSingleVariableComponent from './EnterpriseComponentSingleVariableComponent';
import EnterpriseComponentVariableDeleteModal from './EnterpriseComponentVariableDeleteModal';
import EnterpriseComponentVariablesCreateOrUpdateModal from './EnterpriseComponentVariablesCreateOrUpdateModal';
import EnterpriseComponentPageTemplate from '../../../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';
import useEnterpriseComponentContext from '../../hooks/useEnterpriseComponentContext';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { ErrorMessage, Placeholder, PlaceholderGrid, PlaceholderGridDirection } from '@tonkean/infrastructure';
import { useProject } from '@tonkean/infrastructure';
import type { EnterpriseComponentVariable } from '@tonkean/tonkean-entities';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { FontSize, Theme } from '@tonkean/tui-theme';
import { range } from '@tonkean/utils';

const VariablesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 24px;
`;

const SingleVariableWrapper = styled.div`
    border: 1px solid ${Theme.colors.gray_300};
    border-radius: 3px;
    max-height: 100%;
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
`;

const AddNewButtonWrapper = styled(SingleVariableWrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 186px;
`;

const StyledClickableLink = styled(ClickableLink)`
    font-size: ${FontSize.SMALL_12};
    line-height: 14px;
    font-weight: 500;
`;

interface Props {}

const EnterpriseComponentVariablesPage: React.FC<Props> = () => {
    const { enterpriseComponent } = useEnterpriseComponentContext();

    const { id: projectId } = useProject();
    const [isCreateOrUpdateModalOpen, setIsCreateOrUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedVariable, setSelectedVariable] = useState<EnterpriseComponentVariable | undefined>();

    const [{ data, loading, error }, getEnterpriseComponentVariables] = useLazyTonkeanService(
        'getEnterpriseComponentVariables',
    );

    // Get the project integration variables.
    useEffect(() => {
        getEnterpriseComponentVariables(projectId, enterpriseComponent.id, enterpriseComponent.id);
    }, [projectId, enterpriseComponent, getEnterpriseComponentVariables]);

    const onClickAdd = () => {
        setSelectedVariable(undefined);
        setIsCreateOrUpdateModalOpen(true);
    };

    const onClickEdit = (variable: EnterpriseComponentVariable) => {
        setSelectedVariable(variable);
        setIsCreateOrUpdateModalOpen(true);
    };

    const onClickDelete = (variable: EnterpriseComponentVariable) => {
        setSelectedVariable(variable);
        setIsDeleteModalOpen(true);
    };

    const onCreateOrUpdateModalClose = useCallback(() => {
        setIsCreateOrUpdateModalOpen(false);
        setSelectedVariable(undefined);
        getEnterpriseComponentVariables(projectId, enterpriseComponent.id, enterpriseComponent.id);
    }, [
        setIsCreateOrUpdateModalOpen,
        setSelectedVariable,
        getEnterpriseComponentVariables,
        projectId,
        enterpriseComponent,
    ]);

    const onDeleteModalClose = useCallback(() => {
        setIsDeleteModalOpen(false);
        setSelectedVariable(undefined);
        getEnterpriseComponentVariables(projectId, enterpriseComponent.id, enterpriseComponent.id);
    }, [setIsDeleteModalOpen, setSelectedVariable, getEnterpriseComponentVariables, enterpriseComponent, projectId]);

    return (
        <EnterpriseComponentPageTemplate
            name="Parameters"
            description={
                <>
                    <div>
                        Parameters are global variables whose values can be set by the user when they first connect to
                        the data source.{' '}
                    </div>
                    <div>You can use the parameter values provided by the user throughout the configuration.</div>
                </>
            }
        >
            {error && <ErrorMessage>There was an error getting the available variables</ErrorMessage>}

            <VariablesGrid>
                {/* add new button */}
                {!loading && !error && (
                    <AddNewButtonWrapper>
                        <StyledClickableLink onClick={onClickAdd}>+ Add Parameter</StyledClickableLink>
                    </AddNewButtonWrapper>
                )}

                {data?.entities.map((variable) => (
                    <SingleVariableWrapper key={variable.id}>
                        <EnterpriseComponentSingleVariableComponent
                            variable={variable}
                            onEdit={onClickEdit}
                            onDelete={onClickDelete}
                            canUpdateOrDelete={!variable.isImported}
                        />
                    </SingleVariableWrapper>
                ))}

                {loading && (
                    <PlaceholderGrid direction={PlaceholderGridDirection.COLUMNS} template="331px 331px" gap="29px">
                        {range(5).map((i) => (
                            <Placeholder key={i} $height="210px" $width="331px" />
                        ))}
                    </PlaceholderGrid>
                )}

                <EnterpriseComponentVariablesCreateOrUpdateModal
                    enterpriseComponentVariable={selectedVariable}
                    onClose={onCreateOrUpdateModalClose}
                    open={isCreateOrUpdateModalOpen}
                    onSubmit={onCreateOrUpdateModalClose}
                />

                {data && selectedVariable && (
                    <EnterpriseComponentVariableDeleteModal
                        open={isDeleteModalOpen}
                        onClose={onDeleteModalClose}
                        onDelete={onDeleteModalClose}
                        enterpriseComponentVariable={selectedVariable}
                        dependentSubEntities={data.dependentProjectIntegrationSubEntitiesInfo[selectedVariable.id]}
                    />
                )}
            </VariablesGrid>
        </EnterpriseComponentPageTemplate>
    );
};

export default EnterpriseComponentVariablesPage;
