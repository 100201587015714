<div class="relative">
    <div class="inbox-load-new-container flex mod-justify-center">
        <div
            class="inbox-load-new-btn-group btn-group"
            ng-class="{ 'mod-shown': data.newItemsAvailable && !data.forceCloseNewItemsAvailable }"
        >
            <button class="btn btn-slim btn-primary padding-right-xs" ng-click="onLoadNewClick()">
                Load new alerts
            </button>
            <button
                class="btn btn-slim btn-primary svg-icon-xs padding-right-xs padding-left-xs common-color-white"
                ng-click="data.forceCloseNewItemsAvailable = true"
            >
                <tnk-icon src="/images/icons/x.svg"></tnk-icon>
            </button>
        </div>
    </div>

    <div class="flex mod-align-baseline">
        <div class="margin-right-xs">
            <i ng-if="!data.userCommunicationIntegration" class="svg-icon-sm">
                <tnk-icon src="/images/icons/envelope.svg"></tnk-icon>
            </i>
            <i
                ng-if="data.userCommunicationIntegration"
                class="initiative-integration-icon mod-dropdown mod-sm inline-block"
                ng-class="
                    data.userCommunicationIntegration
                        ? 'mod-' + data.userCommunicationIntegration.integration.integrationType.toLowerCase()
                        : 'mod-slack'
                "
            ></i>
        </div>
        <div>
            <div class="common-title-xs">Your Follow-ups & Alerts</div>

            <div class="common-color-grey3 common-size-xxxs margin-bottom-lg">
                All Follow-ups and alerts sent to you.
            </div>
        </div>
    </div>

    <div uib-dropdown>
        <span uib-dropdown-toggle class="pointer flex-vmiddle">
            <span class="margin-right-xs">{{ data.selectedFilter.label }}</span>
            <span class="flex-no-shrink dropdown-chevron mod-static"></span>

            <span class="flex-grow"></span>
            <i ng-if="data.loading" class="loading"></i>
        </span>
        <ul uib-dropdown-menu>
            <li ng-repeat="filter in filters">
                <a ng-click="setFilter(filter)">{{ filter.label }}</a>
            </li>
        </ul>
    </div>

    <hr class="common-separator margin-top-xs" />

    <div
        ng-repeat="item in data.items | arrayNotEmptyFilter:data.selectedFilter.fieldName:data.selectedFilter.showUnanswered | orderBy:'-created'"
    >
        <tnk-inbox-item id="item.id"></tnk-inbox-item>
    </div>

    <!-- Load more -->
    <div class="flex mod-justify-center padding-top">
        <button
            ng-if="data.items.length !== data.totalCount && !data.loading"
            ng-click="loadMyHistory()"
            class="btn btn-slim btn-primary"
        >
            Load More
        </button>
        <i ng-if="data.loading" class="loading-medium"></i>
    </div>

    <div ng-if="!data.items || !data.items.length" class="common-color-grey">Looks empty.</div>
</div>
