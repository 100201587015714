<div class="modal-header">
    <div class="flex-vmiddle">
        <div class="integration-icon mod-{{ currentIntegration.name }}"></div>
        <h4 class="flex-grow">Set Up Data Source</h4>
    </div>
</div>
<div class="modal-body">
    <div class="alert alert-danger" ng-show="error">{{ error | error }}</div>
    <div class="text-center" ng-show="loading">
        <h4>
            <span class="loading"></span>
            Connecting to {{ currentIntegration.displayName }}
        </h4>
    </div>

    <form
        name="data.loginModal"
        class="form-horizontal"
        novalidate
        ng-submit="connect()"
        ng-hide="loading || integration"
    >
        <div>
            <!-- Client ID -->
            <div class="form-group">
                <label for="login-api-cid" class="col-sm-4 control-label">Service Account Key Json:</label>

                <div class="col-sm-8">
                    <textarea
                        ng-model="data.credentialsJson"
                        class="form-control"
                        id="login-api-cid"
                        autocomplete="off"
                        placeholder="Credentials Json"
                        required
                    ></textarea>
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <!-- Working Bucket Name -->
            <div class="form-group">
                <label for="login-api-cid" class="col-sm-4 control-label">Working Bucket Name:</label>

                <div class="col-sm-8">
                    <input
                        type="text"
                        ng-model="data.workingBucketName"
                        class="form-control"
                        id="working-bucket-name"
                        autocomplete="off"
                        placeholder="Working Bucket Name"
                        required
                    />
                </div>
                <div class="common-subtitle-inner"><i></i></div>
            </div>

            <div class="margin-bottom-lg info-box common-size-xxs">
                <!-- Title -->
                <p class="common-color-dark-grey common-underline">Setup instructions:</p>

                <!-- Instructions -->
                <div class="common-color-dark-grey">
                    You should create a
                    <a href="https://console.cloud.google.com/iam-admin/serviceaccounts">service account</a>
                    dedicated to Tonkean, and create a JSON key file for it.
                    <br />
                    Copy the key file content and paste it into the
                    <strong>"Service Account Key Json:"</strong>
                    text area.
                    <br />
                    You should also create a dedicated
                    <a href="https://console.cloud.google.com/storage/browser">bucket</a>
                    for the google Cloud data source.
                    <br />
                    The service account should be granted with permissions to create and delete objects from this
                    dedicated bucket.
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-7"></div>
                <div class="col-sm-3 text-right">
                    <button
                        type="submit"
                        analytics-on
                        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                        analytics-event="Authenticate"
                        analytics-label="{{ currentIntegration.name }}"
                        class="btn btn-primary"
                    >
                        Connect
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div ng-show="integration && !loading">
        <div class="alert alert-info">
            <a
                class="pull-right"
                href="#"
                analytics-on="click"
                analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
                analytics-event="Change Auth"
                analytics-label="{{ currentIntegration.name }}"
                ng-click="changeUser()"
            >
                Change
            </a>

            <div class="integration-group-header-connected" ng-if="integration">
                Authenticated
                <span ng-show="integration.integrationUserDisplayName || integration.integrationUser">
                    as
                    <span class="integration-group-header-connected-username">
                        {{ integration.integrationUserDisplayName || integration.integrationUser }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        class="btn btn-default"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Cancel Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="onCancel()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        ng-disabled="!data.integrationForm.$valid || !selections || !selections.length"
        analytics-on
        analytics-category="Config {{ editProject ? 'Edit' : 'New' }}"
        analytics-event="Save Integration"
        analytics-label="{{ currentIntegration.name }}"
        type="button"
        ng-click="ok()"
    >
        OK
    </button>
</div>
