import React from 'react';
import styled from 'styled-components';

import { range } from '@tonkean/utils';

const Placeholder = styled.div.attrs({ className: 'loading-placeholder' })`
    animation-iteration-count: infinite;
    width: 100%;
    max-width: 300px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const LoadingGroup = styled.div`
    padding: 10px 10px 0;
`;

const GroupHeader = styled.div`
    min-height: 30px;
    width: 30%;
`;

const Row = styled.div`
    display: flex;
    min-height: 30px;
    margin-left: 30px;
`;

const RowValue = styled.div`
    margin-right: 10px;
    flex-grow: 1;
`;

const HistoryFieldsTableLoader: React.FC = () => {
    return (
        <Container>
            {[3, 6, 1, 4].map((rowAmount) => (
                <LoadingGroup key={rowAmount}>
                    <GroupHeader>
                        <Placeholder />
                    </GroupHeader>

                    {range(rowAmount).map((rowIndex) => (
                        <Row key={rowIndex}>
                            {[1, 2, 3].map((valueIndex) => (
                                <RowValue key={valueIndex}>
                                    <Placeholder />
                                </RowValue>
                            ))}
                        </Row>
                    ))}
                </LoadingGroup>
            ))}
        </Container>
    );
};

export default HistoryFieldsTableLoader;
