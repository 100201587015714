import React, { Suspense } from 'react';

import TonkeanForm from './TonkeanForm';

import LoadingTonkean from '@tonkean/infrastructure/components/LoadingTonkean';

/**
 * A wrapper component for the web forms.
 * The main purpose of it is to prefetch all data necessary for forms to function.
 */
const TonkeanFormWrapper: React.FC = () => {
    return (
        <Suspense fallback={<LoadingTonkean />}>
            <TonkeanForm />
        </Suspense>
    );
};

export default TonkeanFormWrapper;
