import GoogleCloudStorageAuthenticationComponent from './GoogleCloudStorageAuthenticationComponent';
import googleCloudStorageIcon from '../../../../apps/tracks/images/googlecloudstorage.svg';
import googleCloudStorageCircleIcon from '../../../../apps/tracks/images/integrations/googlecloudstorage-circle.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';
import StorageProviderSettings from '../base/StorageProviderSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class GoogleCloudStorageIntegration extends IntegrationDefinitionBase {
    override name = 'googlecloudstorage';
    override displayName = 'Google Cloud Storage';
    override description = 'We only collect files from a specific bucket';
    override authenticationType = AuthenticationMethod.CUSTOM;
    override supportsMultipleIntegrationPerUser = true;
    override entities = [new IntegrationEntity('Folder', 'Folders'), new IntegrationEntity('File', 'Files')];
    override customizedAuthenticationComponent = GoogleCloudStorageAuthenticationComponent;
    override storageProviderSettings = new StorageProviderSettings(true, true);

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(googleCloudStorageCircleIcon, '130px'),
        new IntegrationIcon(googleCloudStorageIcon, '130px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: false,
        canEditAll: false,
        removableEntities: {},
        editableEntities: { Folder: true, File: true },
        isOnlyDataRetention: {},
    };
}

export default GoogleCloudStorageIntegration;
