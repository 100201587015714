import type React from 'react';
import { useState } from 'react';

import useDebouncedState from './useDebouncedState';

/**
 * Wraps useDebouncedState and useState to get all the essentials for a debounced value
 * @param initialValue the initial value of the state
 * @param debounceInterval the time to debounce the state update
 *
 * @return array of values - first is the state updated after debounce, second is the setState function and third is the real state
 */
export function useFullDebouncedState<T extends Record<string, any> | number | string | boolean | undefined>(
    initialValue: T,
    debounceInterval: number = 200,
): [T, React.Dispatch<React.SetStateAction<T>>, T] {
    const [debouncedState, setDebouncedState] = useState<T>(initialValue);
    const [state, setState] = useDebouncedState(debouncedState, setDebouncedState, debounceInterval);

    return [debouncedState, setState, state];
}
