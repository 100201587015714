import template from './tnkBotWorkerFeed.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkBotWorkerFeed', {
    bindings: {
        isVisible: '<',
        closeSidePane: '&',
    },
    template,
    controller: 'botWorkerFeedCtrl',
});
