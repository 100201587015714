import template from './tnkDataSourceWorkerComponent.template.html?angularjs';

export default angular.module('tonkean.app').component('tnkDataSourceWorkerComponent', {
    bindings: {
        groupId: '<',
        workflowVersionId: '@',
        invalidLogics: '<',
        onComponentClosed: '&',
        onDataSourceChanged: '&',
        workflowFolderProjectIntegrationsAccess: '<',
        setShouldReloadFields: '<',
        onRecurrenceTimeSelectionChanged: '&',
    },
    template,
    controller: 'DataSourceWorkerComponentCtrl',
});
