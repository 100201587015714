import type { IHttpResponse } from 'angular';
import { useAngularService } from 'angulareact';
import React, { useMemo, useState } from 'react';

import GenericIntegrationAuthenticationComponent from './GenericIntegrationAuthenticationComponent';

import { IntegrationManager } from '@tonkean/integrations';
import { GenericIntegrationContext } from '@tonkean/integrations';
import type { Integration } from '@tonkean/tonkean-entities';
import { getStateError } from '@tonkean/utils';

interface Props {
    // Props
    integrationType: string;
    integration?: any;
    setProjectIntegrationData?: (projectIntegrationData: any) => void;
    setIntegrationDisplayName?: (displayName: string | undefined) => void;
    onIntegrationCreate: (integration?: Integration) => void;
    isCreatedFromSharedCredentials?: boolean;
    projectIntegrationData?: any;
    onAuthentication?: () => void;
}

const GenericIntegrationConnectionComponent: React.FC<Props> = ({
    integrationType,
    integration,
    setProjectIntegrationData,
    setIntegrationDisplayName,
    onIntegrationCreate,
    isCreatedFromSharedCredentials,
    projectIntegrationData,
    onAuthentication,
}) => {
    const integrationConfiguration = useMemo(
        () => IntegrationManager.getIntegrationByName(integrationType),
        [integrationType],
    );

    const integrations = useAngularService('integrations');
    const authenticationService = useAngularService('authenticationService');
    const createProjectApis = useAngularService('createProjectApis');
    const oauthHandler = useAngularService('oauthHandler');
    const projectManager = useAngularService('projectManager');

    if (!integrationConfiguration) {
        throw new Error(`No integration with the type ${integrationType}`);
    }

    const [currentIntegration, setCurrentIntegration] = useState(integration);
    const [connecting, setConnecting] = useState(false);
    const [error, setError] = useState<string>();

    /**
     * This function sets the integration state to undefined and as result it will reset the integration
     * and the user will have to reconnect (reauthenticate).
     */
    const changeUser = () => {
        onIntegrationCreate(undefined);
        setCurrentIntegration(undefined);
    };

    /**
     * Callback that we pass to children component so they can report on errors.
     */
    const reportError = (newError: string): void => {
        setError(newError);
    };

    /**
     * Responsible to create the integration.
     * This function is being called once the first authentication process is finished.
     *
     * @param config Any JSON object that will be saved under the integration.
     * @param newIntegrationDisplayName Optional integration display name.
     * @param projectIntegrationData
     */
    const createIntegration = async (
        config: Record<string, any>,
        projectIntegrationData: unknown,
        newIntegrationDisplayName?: string,
    ) => {
        try {
            setIntegrationDisplayName?.(newIntegrationDisplayName);
            setConnecting(true);
            setError(undefined);

            let integrationUniqueType;
            // If multiple integrations per user is not supported, we create a unique id for the integration.
            if (!integrationConfiguration.supportsMultipleIntegrationPerUser) {
                integrationUniqueType = integrations.getIntegrationUniqueType(
                    authenticationService.getCurrentUser().id,
                    integrationType.toUpperCase(),
                );
            }

            const newIntegration = await createProjectApis.createIntegration(
                projectManager.project.id,
                integrationConfiguration.name,
                config,
                integrationUniqueType,
                undefined,
            );

            setProjectIntegrationData?.(projectIntegrationData);
            setCurrentIntegration(newIntegration);
            onIntegrationCreate(newIntegration);
            onAuthentication?.();
        } catch (returnedError) {
            setError(getStateError(returnedError as IHttpResponse<any>));
        } finally {
            setConnecting(false);
        }
    };

    const contextProvider = useMemo(() => ({ createProjectApis, oauthHandler }), [createProjectApis, oauthHandler]);

    return (
        <GenericIntegrationContext.Provider value={contextProvider}>
            <div>
                {/* Error message*/}
                {error && <div className="alert alert-danger">{error}</div>}

                {/* Authentication Component*/}
                {!currentIntegration && !connecting && !isCreatedFromSharedCredentials && (
                    <GenericIntegrationAuthenticationComponent
                        authenticate={createIntegration}
                        integrationConfiguration={integrationConfiguration}
                        reportError={reportError}
                        projectIntegrationData={projectIntegrationData}
                    />
                )}

                {isCreatedFromSharedCredentials && (
                    <div className="alert alert-info margin-bottom-none integration-group-header-connected">
                        You're connected with a predefined connection.
                    </div>
                )}

                {/* Loader when connecting*/}
                {connecting && (
                    <h4 className="text-center">
                        <span className="loading" /> Connecting to {integrationConfiguration.displayName}
                    </h4>
                )}
            </div>
            {currentIntegration && !isCreatedFromSharedCredentials && (
                <div>
                    <div className="alert alert-info">
                        <a className="pull-right" href="#" onClick={changeUser}>
                            Change
                        </a>

                        <div className="integration-group-header-connected">
                            Authenticated
                            {(currentIntegration.integrationUserDisplayName || currentIntegration.integrationUser) && (
                                <span>
                                    {' '}
                                    as{' '}
                                    <span className="integration-group-header-connected-username">
                                        {currentIntegration.integrationUserDisplayName ||
                                            currentIntegration.integrationUser}
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </GenericIntegrationContext.Provider>
    );
};

export default GenericIntegrationConnectionComponent;
