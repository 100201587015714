<div class="flex-vmiddle {{ data.wrapperClasses }}">
    <div class="tnk-toggle-buttons-group margin-right-xs flex mod-align-center margin-top-xs {{ data.toggleButtonsGroupClasses}}">
        <!-- Title -->
        <div ng-if="data.titleLabel" class="margin-right-xs {{ data.titleClasses }}">{{ data.titleLabel }}</div>

        <!-- Buttons List -->
        <div class="btn-group">
            <label
                class="btn btn-default"
                data-automation="{{
                    data.automationIdentifier + '-' + button.label.toString().toLowerCase().replaceAll(' ', '-')
                }}"
                ng-repeat="button in data.buttons"
                ng-class="{ 'btn-primary': button.value === data.selectedButton.value }"
                uib-btn-radio="{{ button }}"
                ng-model="data.selectedButton"
                ng-click="data.onButtonSelected({ selectedButton: data.selectedButton })"
                uib-tooltip="{{ button.tooltip }}"
            >
                {{ button.label }}
            </label>
        </div>
    </div>
</div>
