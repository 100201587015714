const GridHeaders = {
    MODULE_NAME: {
        title: (filteredRows, rows) => {
            if (!filteredRows || filteredRows.length === rows.length) {
                return `Solution Modules (${rows.length})`;
            }

            if (filteredRows.length < rows.length) {
                return `Solution Modules (${filteredRows.length}/${rows.length})`;
            }
        },
        isVisible: () => true,
        fixedWidth: false,
    },
    LAST_FLOW: { title: () => 'Last Flow', isVisible: () => true, fixedWidth: false },
    TODAY: { title: () => 'Today', isVisible: () => true, fixedWidth: false },
    LAST_WEEK: { title: () => '7 Days', isVisible: () => true, fixedWidth: false },
    LAST_MONTH: { title: () => '30 Days', isVisible: () => true, fixedWidth: false },
    MODULE_STATUS: { title: () => 'Status', isVisible: () => true, fixedWidth: false },
    HISTORY: { title: () => '', isVisible: () => true, fixedWidth: true },
};

export default GridHeaders;
