import template from './conditionSelector.template.html?angularjs';

/**
 * Component to select condition and value.
 */
angular.module('tonkean.app').component('tnkConditionSelector', {
    bindings: {
        // The entire field object we're selecting a condition for.
        // NOTE: The reason for having both field and fieldName and fieldType, is for backwards compatibility -
        // the end goal is to have only field property and get rid of the rest.
        // For now, field is used specifically for properties that aren't field name field type.
        field: '<',
        fieldName: '<', // The field name we're selecting a condition for.
        fieldType: '<', // The type of the field we're defining a condition for.
        possibleValues: '<', // The possible values a field can have.
        possibleValuesMap: '<',

        projectIntegration: '<', // The connected project integration

        conditionApiName: '<', // The API name of the current condition.
        resetCondition: '<', // If we should reset our condition.

        value: '<', // The current value of the condition.
        secondValue: '<', // The current second value of the condition.

        expression: '<', // The expression to use as a value.
        doNotEvaluatePreview: '<',
        previewEvaluationSource: '<',

        groupId: '@', // What group are we in? This is only relevant for COLUMN items source.
        workflowVersionId: '@', // What workflow version are we in? This is only relevant for COLUMN items source.
        considerAllGroups: '@', // If true, will consider all groups of the project as data source for fields and not just the given group at groupId parameter.
        itemsSource: '@', // What items we should load (For example, EXTERNAL or COLUMN).

        disableSelections: '<', // Should we display the selection in the UI as disabled.

        onConditionSelected: '&', // Occurs once a condition is selected.
        onConditionSelectedParam: '<', // The param to pass to the onConditionSelected function.

        onValueChanged: '&', // Occurs once the value is changed.
        onSecondValueChanged: '&', // Occurs once the second value is changed.
        onValueChangedParam: '<', // The param to pass to the onValueChanged function.

        useExpressionsForValue: '<', // Indicates whether we should use tonkean expressions for values instead of plain values.
        onExpressionChanged: '&',
        modFixedWidth: '<', // Setting it true will prevent the expression field input from expending.
        tabsFieldSelectorModNarrow: '<', // Making the width of the tnkTabsFieldSelector narrower.
        tabsFieldSelectorModFixedWidth: '<', // Making the width of the tnkTabsFieldSelector fixed.
        verticalMod: '<', // Changing the conditions layout to be vertical

        allowedConditionsSet: '<', // Do we want to white list the allowed conditions? Should be a set. (For example, {'Equals' : true, 'NotEquals' : true}.
        notAllowedConditionsSet: '<', // Conditions to remove from the search
        excludedTabSelectorSpecialFields: '<', // remove from special fields tab selector fields (for example ['TNK_TODAY']) that are considerInTypes:'FIELDS_TAB_SELECTOR' or any provided specialFieldsValuesForFeatures.
        includeTabSelectorSpecialFieldsForFeatures: '<', // Use 'considerInTypes' to add fields under this category to the special fields tab selector
        allowContains: '<', // allow using contains operator
        showPeopleDirectories: '<',
    },
    template,
    controller: 'ConditionSelectorCtrl',
});
