import { useAngularService, useAngularWatch } from 'angulareact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { StylesConfig, Theme as ReactSelectTheme } from 'react-select';
import { createFilter } from 'react-select';
import styled, { css } from 'styled-components';

import navigationCategoriesConfigurationMap from './config/navigationCategoriesConfigurationMap';
import stateNameToStagePageType from './config/stateNameToStatePageType';
import statePageTypeToNavigationRequestMap from './config/statePageTypeToNavigationRequestMap';
import StatePageType from './entities/StatePageType';
import NavigationSearchItemOption from './NavigationSearchItemOption';
import NavigationSearchItemValueContainer from './NavigationSearchItemValueContainer';
import type { NavigationSelectionGroupType, NavigationSelectionSingleItemType } from './NavigationSelectionTypes';
import concatenateResults from './utils/concatenateResults';
import filterCategoriesByEnvironment from './utils/filterCategoriesByEnvironment';
import getValueToIndexMap from './utils/getValueToIndexMap';
import identifyingPrefixToTonkeanTypeMap from './utils/identifyingPrefixToTonkeanTypeMap';

import { useFeatureFlag, useLazyTonkeanService } from '@tonkean/angular-hooks';
import { Breakpoint, TnkSelect, useBreakpoint, useDebouncedState } from '@tonkean/infrastructure';
import { SMART_SEARCH_ID, SmartSearchModal } from '@tonkean/smart-search';
import {
    NavigationCategory,
    type NavigationCategoryResults,
    type NavigationEntitySummary,
    type NavigationInitiativeSummary,
    ProjectIntegrationPageMenuItemType,
    SCIMTonkeanRole,
    type TonkeanId,
    TonkeanType,
    WorkflowVersionType,
} from '@tonkean/tonkean-entities';
import { type Color, FontSize, Theme } from '@tonkean/tui-theme';
import type { DataAutomationSupportProps, StyledComponentsSupportProps } from '@tonkean/utils';

const Wrapper = styled.div<{ $fullscreen: boolean }>`
    width: 380px;

    ${({ $fullscreen }) =>
        $fullscreen &&
        css`
            &:focus-within {
                z-index: 6;
                position: fixed;
                top: 50px;
                left: 0;
                right: 0;
                margin: 0 !important;
                width: 100%;

                [data-automation='tnk-select-control'] {
                    border-radius: 0;
                    border: 0;
                }

                &::before {
                    content: '';
                    display: block;
                    position: fixed;
                    top: 50px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: ${Theme.colors.basicBackground};
                }
            }
        `}
`;

const dropdownStyles = {
    control: (styles, state) => ({
        ...styles,
        backgroundColor: Theme.colors.gray_200,
        minHeight: '28px',
        border: 'none',
        fontSize: FontSize.SMALL_12,
        cursor: 'pointer',
        boxShadow: state.menuIsOpen || state.isFocused ? `0 0 0 1px ${Theme.colors.gray_400}` : 'none',
    }),
};

const getNavigationTonkeanType = (navigationCategory: NavigationCategory, item: NavigationEntitySummary) => {
    if (navigationCategory === NavigationCategory.ALL_HOMEPAGE_INTAKES_IN_PROJECT) {
        return item.tonkeanType;
    }
    return identifyingPrefixToTonkeanTypeMap[item.id.slice(0, 4)];
};

interface Props extends StyledComponentsSupportProps, DataAutomationSupportProps {
    projectId: TonkeanId<TonkeanType.PROJECT>;
    onItemSelected?: () => void;
    placeholderText?: string;
    /**
     * To override specific styles of component
     */
    styles?: StylesConfig<NavigationSelectionSingleItemType, false, NavigationSelectionGroupType>;
    dropdownIndicatorComponent?: JSX.Element;
    isSystemUser?: boolean;
    isHomepage?: boolean;
    primaryColor?: Color;
}

const reactSelectDefaultFilter = createFilter();
const optionFilterMethod = (option, inputString) =>
    // Always show the smart search option, otherwise use default filter. This is to prevent hiccups in UI
    option.data.id === SMART_SEARCH_ID ? true : reactSelectDefaultFilter(option, inputString);

const NavigationSearch: React.FC<Props> = ({
    projectId,
    onItemSelected,
    placeholderText,
    styles,
    className,
    dropdownIndicatorComponent = null,
    isSystemUser,
    isHomepage = false,
    primaryColor,
    dataAutomation,
}) => {
    const projectManager = useAngularService('projectManager');
    const [projectName] = useAngularWatch(() => projectManager.project?.name);
    const $state = useAngularService('$state');
    const $timeout = useAngularService('$timeout');
    const [currentStateName] = useAngularWatch(() => $state.current.name);
    const angularStateParams = useAngularService('$stateParams');
    const [currentlyViewedEnvironment] = useAngularWatch(() => angularStateParams.env);
    const [currentlyViewedGroupId] = useAngularWatch(() => angularStateParams.g);
    const [currentlyViewedProjectIntegrationId] = useAngularWatch(() => angularStateParams.enterpriseComponentId);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
    const [notDebouncedSearchTerm, setNotDebouncedSearchTerm] = useDebouncedState('', setDebouncedSearchTerm, 300);
    const [options, setOptions] = useState<NavigationSelectionGroupType[]>([]);
    const [smartSearch, setSmartSearch] = useState('');

    const [{ data, error, loading }, searchNavigationEntities] = useLazyTonkeanService('searchNavigationEntities');

    const workflowVersionTypeByPage =
        stateNameToStagePageType[currentStateName] === StatePageType.MODULE_EDITOR
            ? WorkflowVersionType.DRAFT
            : WorkflowVersionType.PUBLISHED;

    // the checking of currentlyViewedEnvironment is necessary for old versions view where the angularStateParams.env is the version id
    const workflowVersionType: WorkflowVersionType =
        currentlyViewedEnvironment !== 'DRAFT' && currentlyViewedEnvironment !== 'PUBLISHED'
            ? workflowVersionTypeByPage
            : currentlyViewedEnvironment;

    const authenticationService = useAngularService('authenticationService');

    const calculatedTonkeanRolesIncludesSystemUser = authenticationService
        .getCurrentUserSafe()
        ?.projectContext?.calculatedTonkeanRoles?.includes(SCIMTonkeanRole.SYSTEM_USER);
    const isCurrentUserSystemUser = useMemo<boolean | undefined>(() => {
        return isSystemUser === undefined ? calculatedTonkeanRolesIncludesSystemUser : isSystemUser;
    }, [calculatedTonkeanRolesIncludesSystemUser, isSystemUser]);

    const blockSolutionSiteForProcessContributors = useFeatureFlag(
        'tonkean_feature_block_solution_site_for_process_contributors',
    ) as boolean;

    const navigationRequestInformation = useMemo(() => {
        const stagePageType = stateNameToStagePageType[currentStateName];
        if (stagePageType && statePageTypeToNavigationRequestMap[stagePageType]) {
            const navigationRequest = statePageTypeToNavigationRequestMap[stagePageType];
            if (!isCurrentUserSystemUser && blockSolutionSiteForProcessContributors) {
                const filteredNavigationCategories = navigationRequest.navigationCategories.filter(
                    (navigationCategory) =>
                        navigationCategory !== NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
                );
                navigationRequest.navigationCategories = filteredNavigationCategories;
            }

            return {
                ...navigationRequest,
                navigationCategories: filterCategoriesByEnvironment(
                    navigationRequest.navigationCategories,
                    workflowVersionType,
                ),
            };
        } else {
            return {
                navigationCategories: [
                    NavigationCategory.ALL_MODULES_IN_PROJECT,
                    NavigationCategory.ALL_SOLUTIONS_IN_PROJECT,
                    NavigationCategory.ALL_DATA_SOURCES_IN_PROJECT,
                    NavigationCategory.ALL_SOLUTION_BUSINESS_REPORTS_IN_PROJECT,
                    NavigationCategory.ALL_ITEM_INTERFACES_IN_PROJECT,
                    NavigationCategory.ALL_CREATE_FORMS_IN_PROJECT,
                    NavigationCategory.ALL_SOLUTION_SITE_PAGES_IN_PROJECT,
                ],
            };
        }
    }, [blockSolutionSiteForProcessContributors, currentStateName, isCurrentUserSystemUser, workflowVersionType]);

    const [navigationOptions, setNavigationOptions] = useState<NavigationSelectionGroupType[]>([]);

    useEffect(() => {
        if (data && !error && !loading) {
            const categoriesOrder: Partial<Record<NavigationCategory, number>> = getValueToIndexMap(
                navigationRequestInformation.navigationCategories,
            );

            const navigationSearchResultEntries = (
                Object.entries(data.categoriesResults) as [NavigationCategory, NavigationCategoryResults][]
            ).sort(
                ([firstCategory], [secondCategory]) =>
                    (categoriesOrder[firstCategory] || 0) - (categoriesOrder[secondCategory] || 0),
            );

            setNavigationOptions(
                navigationSearchResultEntries.map(([navigationCategory, navigationCategoryResults]) => ({
                    value: navigationCategory,
                    label: navigationCategoriesConfigurationMap[navigationCategory].displayName,
                    options: concatenateResults(navigationCategoryResults).map((item) => ({
                        value: item.id,
                        label: item.displayName,
                        tonkeanType: getNavigationTonkeanType(navigationCategory, item),
                        ...item,
                    })),
                })),
            );
        }
    }, [data, error, loading, navigationRequestInformation.navigationCategories]);

    const showSmartSearch = useFeatureFlag('tonkean_feature_smartsearch');
    useEffect(() => {
        const smartSearchOption: NavigationSelectionGroupType | undefined =
            showSmartSearch && notDebouncedSearchTerm
                ? {
                      value: '',
                      label: '',
                      options: [
                          {
                              id: SMART_SEARCH_ID,
                              displayName: 'smart search',
                              label: `Ask: "${notDebouncedSearchTerm}"`,
                              value: notDebouncedSearchTerm,
                          },
                      ],
                  }
                : undefined;
        setOptions([smartSearchOption, ...navigationOptions].filter(Boolean) as NavigationSelectionGroupType[]);
    }, [navigationOptions, notDebouncedSearchTerm, showSmartSearch]);

    useEffect(() => {
        searchNavigationEntities(
            projectId,
            debouncedSearchTerm,
            25,
            workflowVersionType,
            currentlyViewedGroupId,
            navigationRequestInformation.navigationCategories,
            currentlyViewedProjectIntegrationId,
            isHomepage,
        );
    }, [
        currentlyViewedGroupId,
        currentlyViewedProjectIntegrationId,
        debouncedSearchTerm,
        isHomepage,
        navigationRequestInformation.navigationCategories,
        projectId,
        searchNavigationEntities,
        workflowVersionType,
    ]);

    const stylesOverrides = useMemo(() => {
        const valueContainerStyles = {
            valueContainer: (stylesParam, state) => ({
                display: 'flex',
                alignItems: 'center',
                ...styles?.valueContainer?.(stylesParam, state),
            }),
        };
        return {
            ...dropdownStyles,
            ...styles,
            ...valueContainerStyles,
        };
    }, [styles]);

    const stateGo = (stateName: string, stateParams: Record<string, unknown>) => {
        $timeout(() => {
            $state.go(stateName, stateParams);
        });
    };

    const breakpoint = useBreakpoint();
    const isMobile = breakpoint <= Breakpoint.XSMALL_640;

    const tnkSelectTheme = useCallback(
        (theme: ReactSelectTheme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary:
                    primaryColor || projectManager.project?.themeConfiguration?.primaryColor || Theme.colors.promotion,
            },
        }),
        [primaryColor, projectManager.project?.themeConfiguration?.primaryColor],
    );

    return (
        <Wrapper className={className} $fullscreen={isMobile}>
            <TnkSelect
                placeholder={placeholderText || (projectName ? `Search ${projectName}` : '')}
                options={options}
                isLoading={!!error || loading}
                value={null}
                inputValue={notDebouncedSearchTerm.toString()}
                onInputChange={setNotDebouncedSearchTerm}
                maxMenuHeight={isMobile ? 1000 : undefined}
                noOptionsMessage={() => 'No results found...'}
                // Removed because this line causes issues with smart search modals value being reset and its not necessary anyways
                // onMenuClose={() => setOptions([])}
                styles={stylesOverrides}
                theme={tnkSelectTheme}
                onMenuOpen={() =>
                    searchNavigationEntities(
                        projectId,
                        debouncedSearchTerm,
                        25,
                        workflowVersionType,
                        currentlyViewedGroupId,
                        navigationRequestInformation.navigationCategories,
                        currentlyViewedProjectIntegrationId,
                        isHomepage,
                    )
                }
                components={{
                    Option: NavigationSearchItemOption,
                    ValueContainer: NavigationSearchItemValueContainer,
                    DropdownIndicator: () => dropdownIndicatorComponent,
                }}
                filterOption={optionFilterMethod}
                onChange={(selectedOption) => {
                    switch (selectedOption?.tonkeanType) {
                        case TonkeanType.PROJECT_INTEGRATION:
                            {
                                const stateRouteParameters = {
                                    enterpriseComponentId: selectedOption?.value,
                                    page: ProjectIntegrationPageMenuItemType.OVERVIEW,
                                };

                                if (
                                    currentlyViewedGroupId &&
                                    projectManager.groupsMap[currentlyViewedGroupId] &&
                                    stateNameToStagePageType[currentStateName] === StatePageType.MODULE_EDITOR
                                ) {
                                    stateRouteParameters['fromName'] =
                                        projectManager.groupsMap[currentlyViewedGroupId].name;
                                    stateRouteParameters['fromState'] = currentStateName;
                                    stateRouteParameters['fromStateParams'] = $state.params;
                                }

                                stateGo('product.projectIntegrationPage', stateRouteParameters);
                            }
                            break;

                        case TonkeanType.GROUP:
                            stateGo('product.workerEditor', {
                                g: selectedOption?.value,
                                env: 'PUBLISHED',
                                historyVersion: undefined,
                            });
                            break;

                        case TonkeanType.WORKFLOW_FOLDER:
                            stateGo('product.solution', { solutionId: selectedOption?.value });
                            break;

                        case TonkeanType.INITIATIVE: {
                            const initiativeSelectOption: NavigationInitiativeSummary | undefined =
                                selectedOption as any;

                            stateGo('product.workerEditor.history.workerRunStage', {
                                env: initiativeSelectOption?.isDraftInitiative ? 'BUILD' : 'PUBLISHED',
                                environment: initiativeSelectOption?.isDraftInitiative ? 'build' : 'production',
                                g: initiativeSelectOption?.groupId,
                                defaultFilterSearchTerm: initiativeSelectOption?.displayName,
                            });
                            break;
                        }

                        case TonkeanType.CUSTOM_TRIGGER: {
                            const customTriggerSelectOption = selectedOption as any;

                            stateGo('product.workerEditor', {
                                g: customTriggerSelectOption?.groupId,
                                env:
                                    customTriggerSelectOption?.workflowVersionType === WorkflowVersionType.DRAFT
                                        ? 'DRAFT'
                                        : 'PUBLISHED',
                                l: customTriggerSelectOption?.value,
                                spt: 'logic',
                                lcm: 'configuration',
                            });
                            break;
                        }

                        case TonkeanType.SOLUTION_BUSINESS_REPORT:
                            stateGo('product.solution-business-report', {
                                solutionBusinessReportId: selectedOption?.value,
                            });

                            break;

                        case TonkeanType.ITEM_INTERFACE:
                            if (selectedOption?.value.slice(0, 4).startsWith('CUTR')) {
                                stateGo('product.intakeInterface', {
                                    projectId,
                                    customTriggerId: selectedOption?.value,
                                    workflowVersionType: WorkflowVersionType.PUBLISHED,
                                });
                                break;
                            } else {
                                stateGo('product.workerEditor', {
                                    itemInterfaceId: selectedOption?.value,
                                    g: (selectedOption as any)?.groupId,
                                    t: 'interfaces',
                                });
                            }

                            break;
                        case TonkeanType.FORM:
                            stateGo('form', {
                                formId: selectedOption?.value,
                                projectId,
                                formVersionType: WorkflowVersionType.PUBLISHED,
                            });
                            break;
                        case TonkeanType.SOLUTION_SITE_PAGE:
                            const siteSelectedOption = selectedOption as any;
                            stateGo('solution-site', {
                                projectUrlSlug: siteSelectedOption.projectUrlSlug,
                                solutionSiteUrlSlug: siteSelectedOption.solutionSiteUrlSlug,
                                solutionSitePageUrlSlug: siteSelectedOption.solutionSitePageUrlSlug,
                            });
                            break;
                    }

                    if (selectedOption?.id === SMART_SEARCH_ID) {
                        setSmartSearch(notDebouncedSearchTerm);
                    }

                    if (onItemSelected) {
                        onItemSelected();
                    }
                }}
                dataAutomation={dataAutomation}
            />
            {showSmartSearch && (
                <SmartSearchModal
                    open={!!smartSearch}
                    onClose={() => setSmartSearch('')}
                    projectId={projectId}
                    initialSearchString={smartSearch}
                />
            )}
        </Wrapper>
    );
};

export default NavigationSearch;
