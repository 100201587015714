<span>
    People are saying good things about
    <strong>
        {{
            pm.inquiriesMap[item.reference1.inquiry.id].name
                ? pm.inquiriesMap[item.reference1.inquiry.id].name
                : 'the' + pm.inquiriesMap[item.reference1.inquiry.id].typeData.preTitle
        }}
    </strong>
</span>
