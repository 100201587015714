import { KnowledgeBase } from '@tonkean/constants';

function BotToggleValidationCtrl($scope, $window, isCurrentlyActive, callback) {
    $scope.isCurrentlyActive = isCurrentlyActive;

    $scope.onConfirm = function () {
        if (callback) {
            callback();
        }
        $scope.$close();
    };

    $scope.onLearnMore = function () {
        $window.open(KnowledgeBase.pages.modules);
    };
}

angular.module('tonkean.app').controller('BotToggleValidationCtrl', BotToggleValidationCtrl);
