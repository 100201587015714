import template from './tnkTrackWeekView.template.html?angularjs';

function tnkTrackWeekView() {
    return {
        restrict: 'E',
        scope: {
            items: '=',
            startDate: '=',
            fullMode: '=',
            groupId: '=',
            isDraft: '=',
            funcId: '=',
            ownerId: '=',
            stateText: '=',
            tag: '=',
            selectedState: '=',
        },
        template,
        controller: 'tnkTrackWeekViewCtrl',
    };
}
export default angular.module('tonkean.app').directive('tnkTrackWeekView', tnkTrackWeekView);
