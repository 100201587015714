import NetsuiteAuthenticationComponent from './NetsuiteAuthenticationComponent';
import netsuiteCircleImage from '../../../../apps/tracks/images/integrations/netsuite-circle.svg';
import netsuiteImage from '../../../../apps/tracks/images/netsuite.svg';
import AuthenticationMethod from '../base/AuthenticationMethod';
import type DataRetentionSettings from '../base/DataRetentionSettings';
import IntegrationDefinitionBase from '../base/IntegrationDefinitionBase';
import IntegrationIcon from '../base/IntegrationIcon';
import IntegrationIconsSettings from '../base/IntegrationIconsSettings';

import { IntegrationEntity } from '@tonkean/tonkean-entities';

class NetsuiteIntegration extends IntegrationDefinitionBase {
    override name = 'netsuite';
    override displayName = 'Netsuite';
    override description = '';
    override supportsMultipleIntegrationPerUser = true;
    override authenticationType = AuthenticationMethod.CUSTOM;

    override entities = [
        new IntegrationEntity('Account', 'Accounts'),
        new IntegrationEntity('Currency', 'Currencies'),
        new IntegrationEntity('Department', 'Departments'),
        new IntegrationEntity('Employee', 'Employees'),
        new IntegrationEntity('Item', 'Items'),
        new IntegrationEntity('Location', 'Locations'),
        new IntegrationEntity('Subsidiary', 'Subsidiaries'),
        new IntegrationEntity('Transaction', 'Transactions'),
        new IntegrationEntity('TransactionLine', 'Transaction Lines'),
        new IntegrationEntity('Vendor', 'Vendors'),
    ];

    override iconsSettings = new IntegrationIconsSettings(
        new IntegrationIcon(netsuiteCircleImage, '96px'),
        new IntegrationIcon(netsuiteImage, '96px'),
    );

    override dataRetentionSettings: DataRetentionSettings = {
        isSupportsDataRetention: true,
        canAddOrRemoveAll: true,
        canEditAll: true,
        removableEntities: {},
        editableEntities: {},
        isOnlyDataRetention: {},
    };

    override customizedAuthenticationComponent = NetsuiteAuthenticationComponent;
}

export default NetsuiteIntegration;
