import { analyticsWrapper } from '@tonkean/analytics';

function LoginCreateCtrl(
    $scope,
    $rootScope,
    $state,
    $localStorage,
    $location,
    $timeout,
    $window,
    createProjectApis,
    oauthHandler,
    utils,
    authenticationService,
    tonkeanService,
) {
    $scope.data = {
        usecaseMap: {
            CUSTOMER_SUCCESS: false,
            PRODUCT_ECOSYSTEM: false,
            OPERATIONS: false,
        },
        useCreatorDomain: false,
        companyDomain: null,
        autoCreateAccount: false,
        isLoading: true,
    };

    // Preload all the onboarding steps images in the current screen size
    $scope.$on('$viewContentLoaded', function () {
        // Delay so we will load the images at the end.
        $timeout(function () {
            const screenWidth = $window.innerWidth;
            const lgWidth = 1200;
            const smWidth = 768;

            // Calculate which size we are in and then append the images to the hidden div so
            // they will load in the background and not be displayed
            const imagesElement = angular.element(document.querySelector('#login-images'));
            let elementString;
            // lg width
            if (screenWidth >= lgWidth) {
                elementString =
                    '<div>' +
                    '<img src="/images/onBoarding/signup-bot.png"/>' +
                    '<img src="/images/onBoarding/signup-graph.png"/>' +
                    '<img src="/images/onBoarding/signup-columns.png"/>' +
                    '</div>';
            } else if (screenWidth >= smWidth) {
                // md and sm width
                elementString =
                    '<div>' +
                    '<img src="/images/onBoarding/signup-sm-bot.png"/>' +
                    '<img src="/images/onBoarding/signup-sm-graph.png"/>' +
                    '<img src="/images/onBoarding/signup-sm-columns.png"/>' +
                    '</div>';
            } else {
                // xs width
                elementString =
                    '<div>' +
                    '<img src="/images/onBoarding/signup-mobile-bot.png"/>' +
                    '<img src="/images/onBoarding/signup-mobile-graph.png"/>' +
                    '<img src="/images/onBoarding/signup-mobile-columns.png"/>' +
                    '</div>';
            }

            imagesElement.append(angular.element(elementString));
        }, 2000);
    });

    $scope.init = function () {
        if (!authenticationService || !authenticationService.currentUser) {
            // direct link with no actual user
            $state.go('login', null, { location: 'replace' });
            return;
        }

        if ($scope.pm.project) {
            // means there is already a project selected and exist, so move on to the next page..
            $scope.goToNextStep();
            return;
        }

        $scope.data.userName = authenticationService.currentUser.name;
        $scope.data.companyName =
            authenticationService.currentUser.additionalData && authenticationService.currentUser.additionalData.team
                ? authenticationService.currentUser.additionalData.team.name
                : null;
        $scope.data.title = authenticationService.currentUser.title;
        $scope.data.companyDomain = utils.getCompanyEmailDomain(authenticationService.currentUser.email);

        if (!$scope.data.companyName) {
            const workEmails = authenticationService.currentUser.emails
                .map(function (email) {
                    return utils.getCompanyEmailDomain(email);
                })
                .filter(angular.identity);

            $scope.data.companyName = workEmails.length === 1 ? workEmails[0] : null;
        }

        tonkeanService
            .getGlobalConfig()
            .then((config) => {
                $rootScope.globalConfig = config;
                $scope.data.companyName = $scope.data.companyName || 'My first project'; // Default for common domains emails (Only in auto create!)

                $scope.data.autoCreateAccount = config.features?.tonkean_feature_auto_create_account ?? false;

                $scope.data.autoCreateAccount && $scope.create();
                $scope.data.isLoading = $scope.data.autoCreateAccount;
            })
            .catch(() => {
                $scope.isLoading = false;
            });
    };

    $scope.create = function () {
        if ($scope.data.companyName && $scope.data.companyName.length) {
            $scope.creating = true;
            analyticsWrapper.track('Create Project', {
                category: 'Create Project',
                label: 'Fresh',
            });
            window.Intercom('trackEvent', 'CreateProject', {
                projectName: $scope.data.companyName,
                email: authenticationService.currentUser.email,
            });

            const metadata = {
                // This is a new joining user - we don't auto show the on-boarding. We only show it if he selects to start with an empty list.
                // showOnBoarding2 replaced showOnBoarding since we decided to only show the continuous onboarding to users who decided to create an empty list.
                // Numbering our properties replaces the need to run a migration (since showOnBoarding was set to true for each new user).
                showOnBoarding2: false,
                continuousOnBoarding: { steps: { signup: { completed: true } } }, // Create the continuousOnBoarding object, so this user will get to do the post sign up onboarding. Mark sign up as a completed step.
                phoneNumber: $scope.data.phone && $scope.data.phone.length ? $scope.data.phone : null,
            };

            tonkeanService
                .updateProfileInfo($scope.data.userName, $scope.data.title, null, null, metadata)
                .then(function (user) {
                    authenticationService.updateCurrentUser(user);

                    // Collect usecases from checkboxes.
                    const usecases = [];
                    for (const usecase in $scope.data.usecaseMap) {
                        if ($scope.data.usecaseMap.hasOwnProperty(usecase) && $scope.data.usecaseMap[usecase]) {
                            usecases.push(usecase);
                        }
                    }

                    return createProjectApis
                        .createProject($scope.data.companyName, null, [], usecases, $scope.data.useCreatorDomain)
                        .then(function (data) {
                            return $scope.pm.selectProject(data);
                        })
                        .then(() => {
                            return tonkeanService.getCurrentUser().then((freshUser) => {
                                authenticationService.updateCurrentUser(freshUser);
                            });
                        })
                        .then(() => {
                            $scope.goToNextStep();
                        });
                })
                .catch(function () {
                    $scope.creating = false;
                    $scope.$emit('alert', 'Could not create your account.');
                });
        }
    };

    $scope.goToNextStep = function () {
        // if the user got a was sent to the signup flow from a specific location (like initiative or built-in list)
        // we dont want him to go through the onboarding steps but go straight to where he came from
        // however, if he was sent from the homepage, we still want him to go through the onboarding steps
        if (
            $localStorage.authenticationCompleteRedirectUrl &&
            $localStorage.authenticationCompleteRedirectUrl !== '/'
        ) {
            // if we have a redirect url then we skip the info steps
            const authenticationCompleteRedirectUrl = $localStorage.authenticationCompleteRedirectUrl;
            delete $localStorage.authenticationCompleteRedirectUrl;

            if (
                authenticationCompleteRedirectUrl.includes('/library/install/module/') ||
                authenticationCompleteRedirectUrl.includes('/library/install/page/') ||
                authenticationCompleteRedirectUrl.includes('/library/install/data_source/')
            ) {
                $state.go('product.workers', {}, { location: 'replace' });
            }

            $location.url(authenticationCompleteRedirectUrl);
        } else {
            // $state.go('loginTriggers');
            $scope.finishSetup();
        }
    };

    $scope.finishSetup = function () {
        // Set the onboardingWatched in the user's metadata to true if needed.
        if (!$rootScope.as.currentUser.metadata || !$rootScope.as.currentUser.metadata.onboardingWatched) {
            if (!$rootScope.as.currentUser.metadata) {
                $rootScope.as.currentUser.metadata = {};
            }
            $rootScope.as.currentUser.metadata.onboardingWatched = true;
            tonkeanService.updateProfileMetadata($rootScope.as.currentUser.metadata);
        }

        // Check if the user got here when he tried to auto create an example list from the website's list gallery.
        // If so, we should navigate him to the list auto creation page instead of the normal product page.
        if (!utils.isNullOrEmpty($localStorage.autoCreateListId)) {
            // Finish the authentication without navigating onwards.
            authenticationService.handleAuthenticationComplete(true);
            // Navigate to the create list page with the autoCreateList flag marked as true, and clear the localStorage.
            const autoCreateListId = $localStorage.autoCreateListId;
            delete $localStorage.autoCreateListId;
            $state.go(
                'createList',
                { listType: autoCreateListId, g: null, autoCreateList: true },
                { location: 'replace' },
            );
        } else {
            // Finish the authentication normally with redirect.
            authenticationService.handleAuthenticationComplete();
        }
    };

    $scope.logout = function () {
        authenticationService.logout();
    };

    $scope.init();
}

export default angular.module('tonkean.app').controller('LoginCreateCtrl', LoginCreateCtrl);
