import React, { useMemo } from 'react';
import styled from 'styled-components';

import KeyValueActionParameters from './KeyValueActionParameters';
import SimpleActionParameters from './SimpleActionParameters';

import { useFetchProjectIntegrationActionParameters } from '@tonkean/angular-hooks';
import { Placeholder } from '@tonkean/infrastructure';
import { ActionDefinitionType, HttpMethodType } from '@tonkean/tonkean-entities';
import type { ProjectIntegrationAction } from '@tonkean/tonkean-entities';
import { range } from '@tonkean/utils';

const Loading = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

interface Props {
    /**
     * The group id this action is in.
     */
    groupId: string;

    /**
     * The version of the group this action is in.
     */
    workflowVersionId: string;

    /**
     * The preview evaluation source whether it is example item or worker run logic id
     */
    previewEvaluationSource: any;

    /**
     * The custom trigger id this action is defined in.
     */
    customTriggerId: string;

    /**
     * The definition of the current custom trigger.
     */
    definition: any;

    /**
     * The project integration action we base the configuration on.
     */
    projectIntegrationAction: ProjectIntegrationAction;

    /**
     * A callback to be called when ever the definition of the custom trigger is changed.
     * @param definition is the new definition we modified.
     * @param shouldSaveLogic whether we should save the logic or not when a change in definition has happened.
     */
    onDefinitionChange(definition: unknown, shouldSaveLogic: boolean): void;

    doesHaveFieldListParams: boolean;

    validationLogicErrorMessage?: string;
    validationLogicErrorMessageChildEntity?: string;

    loading: boolean;
}

const ProjectIntegrationActionLogicConfiguration: React.FC<Props> = ({
    groupId,
    workflowVersionId,
    previewEvaluationSource,
    customTriggerId,
    definition,
    projectIntegrationAction,
    onDefinitionChange,
    doesHaveFieldListParams,
    validationLogicErrorMessage,
    validationLogicErrorMessageChildEntity,
    loading,
}) => {
    const { parameters: predefinedParameters, parametersLoading } = useFetchProjectIntegrationActionParameters(
        projectIntegrationAction,
        loading,
    );

    const isEnableKeyValueParameters = useMemo(() => {
        return !!(
            projectIntegrationAction?.actionDefinition?.definitionType === ActionDefinitionType.HTTP &&
            projectIntegrationAction?.actionDefinition?.methodType !== HttpMethodType.GET &&
            projectIntegrationAction?.actionDefinition?.enableKeyValueParameters
        );
    }, [projectIntegrationAction?.actionDefinition]);

    if (parametersLoading) {
        return (
            <Loading>
                {range(5).map((number) => (
                    <Placeholder key={number} $width="250px" $height="36px" />
                ))}
            </Loading>
        );
    }

    return (
        <>
            <SimpleActionParameters
                groupId={groupId}
                workflowVersionId={workflowVersionId}
                previewEvaluationSource={previewEvaluationSource}
                customTriggerId={customTriggerId}
                definition={definition}
                projectIntegrationAction={projectIntegrationAction}
                onDefinitionChange={onDefinitionChange}
                isEnableKeyValueParameters={isEnableKeyValueParameters}
                doesHaveFieldListParams={doesHaveFieldListParams}
                validationLogicErrorMessage={validationLogicErrorMessage}
                validationLogicErrorMessageChildEntity={validationLogicErrorMessageChildEntity}
                predefinedParameters={predefinedParameters}
            />
            {isEnableKeyValueParameters && (
                <KeyValueActionParameters
                    groupId={groupId}
                    workflowVersionId={workflowVersionId}
                    previewEvaluationSource={previewEvaluationSource}
                    customTriggerId={customTriggerId}
                    definition={definition}
                    projectIntegrationAction={projectIntegrationAction}
                    onDefinitionChange={onDefinitionChange}
                />
            )}
        </>
    );
};

export default ProjectIntegrationActionLogicConfiguration;
