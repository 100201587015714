<div class="margin-left margin-right">
    <div class="margin-bottom" ng-if="data.loading">
        <i class="loading-small"></i>
    </div>
    <!-- Copy rows action -->
    <div class="margin-bottom" ng-if="data.definition.customActionKey === 'COPY_ROWS' && !data.loading">
        <!-- Channel name -->
        <div class="flex-vmiddle">
            <div class="margin-bottom margin-top-xs">Destination Sheet ID:</div>
            <i
                class="fa fa-info-circle margin-left-xs margin-bottom-xxs"
                uib-tooltip="You can find the sheet ID by right clicking on the sheet and then click on properties."
                tooltip-append-to-body="true"
            ></i>
        </div>

        <!-- Tonkean custom expression input -->
        <tnk-tonkean-expression
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            logic-id="{{ data.configuredLogic.node.id }}"
            preview-evaluation-source="data.previewEvaluationSource"
            placeholder="Enter destination sheet..."
            saved-original-expression="data.definition.destinationSheetId"
            saved-evaluated-expression="data.definition.evaluatedDestinationSheetId"
            on-tonkean-expression-changed="
                onTonkeanExpressionChanged(originalExpression, evaluatedExpression, shouldSaveLogic)
            "
        ></tnk-tonkean-expression>
    </div>
</div>
