<div class="margin-left margin-right margin-top">
    <div ng-if="data.definition.customActionKey === 'UPLOAD_FILE'" class="margin-bottom-xlg">
        <tnk-worker-file-selection
            group-id="{{ data.groupId }}"
            workflow-version-id="{{ data.workflowVersionId }}"
            custom-trigger-id="{{ data.configuredLogic.node.id }}"
            file-source-type="{{ data.definition.fileSourceType }}"
            storage-integration-id="{{ data.definition.storageIntegrationId }}"
            storage-integration-action-id="{{ data.definition.storageIntegrationActionId }}"
            should-display-delete-sign="false"
            url-expression="data.definition.url"
            extended-match-configuration="data.definition.extendedMatchConfiguration"
            preview-evaluation-source="data.previewEvaluationSource"
            worker-static-asset-id="{{ data.definition.workerStaticAssetId }}"
            worker-static-asset-display-name="{{ data.definition.workerStaticAssetDisplayName }}"
            parameters-values="data.parametersValues"
            on-worker-file-selection-changed="
                onWorkerFileSelectionChanged(
                    fileSourceType,
                    storageIntegrationId,
                    extendedMatchConfiguration,
                    urlExpression,
                    workerStaticAssetId,
                    workerStaticAssetDisplayName,
                    parametersValues,
                    shouldSaveLogic,
                    storageIntegrationActionId
                )
            "
        ></tnk-worker-file-selection>

        <div ng-if="data.validationObject.invalidFileChoice" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.invalidFileChoice }}
        </div>

        <!-- New Document Name -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                New File Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="File ID"
                saved-expression="data.definition.itemNameExpresssion"
                expression-unique-identifier="BoxUploadFileName"
                on-tonkean-expression-changed="onItemNameExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- On-behalf of user -->
        <div class="margin-top">
            <div class="margin-bottom-xs">
                On-Behalf of User ID:
                <i class="fa fa-info-circle common-color-primary margin-left-xs" uib-tooltip="Permissin"></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="User ID"
                saved-expression="data.definition.onBehalfOfUserIdExpresssion"
                expression-unique-identifier="BoxOnBehalfOfUserId"
                on-tonkean-expression-changed="onOnBehalfOfUserIdExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <!-- Copy Folder, Copy File, Move Folder, Move File -->
    <div
        ng-if="
            data.definition.customActionKey === 'COPY_FOLDER' ||
            data.definition.customActionKey === 'COPY_FILE' ||
            data.definition.customActionKey === 'MOVE_FOLDER' ||
            data.definition.customActionKey === 'MOVE_FILE'
        "
    >
        <!-- New Item's name -->
        <div>
            <div class="margin-bottom-xs">
                New {{ data.entityName }}'s Name:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="If empty, the original name will be used."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                saved-expression="data.definition.itemNameExpresssion"
                expression-unique-identifier="BoxNewItemName"
                on-tonkean-expression-changed="onItemNameExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Destination Folder -->
        <div>
            <div class="margin-bottom-xs">
                Destination Folder (ID):
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    ng-if="
                        data.definition.customActionKey === 'COPY_FOLDER' ||
                        data.definition.customActionKey === 'COPY_FILE'
                    "
                    uib-tooltip="If empty, the destination will be the current {{ data.entityName }}'s location"
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Folder ID"
                saved-expression="data.definition.destinationFolderExpresssion"
                expression-unique-identifier="BoxDestinationFolderID"
                on-tonkean-expression-changed="onDestinationFolderExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.destinationFolderError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.destinationFolderError }}
        </div>
    </div>

    <!-- Share Folder, Share File -->
    <div ng-if="data.definition.customActionKey === 'SHARE_FOLDER' || data.definition.customActionKey === 'SHARE_FILE'">
        <!-- Access Level -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Access Level:</div>
            <ui-select
                theme="bootstrap"
                ng-model="data.definition.accessLevel"
                required
                ng-change="onAccessLevelExpressionChanged(true)"
            >
                <ui-select-match placeholder="Choose a access level">
                    {{ data.definition.accessLevel }}
                </ui-select-match>

                <ui-select-choices repeat="possibleAccessLevel in data.possibleAccessLevels | filter:$select.search">
                    <div ng-bind-html="possibleAccessLevel | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <div ng-if="data.validationObject.accessLevelError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.accessLevelError }}
        </div>

        <!-- Can download permissions -->
        <div>
            <tnk-checkbox
                class="margin-top-xs"
                label="'Allow file downloads'"
                value="data.definition.canDownload"
                on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>

        <!-- Expires at -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Expires At:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Optional - The timestamp at which this shared link will expire."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Email address"
                saved-expression="data.definition.expiresAtExpresssion"
                expression-unique-identifier="BoxExpiresAt"
                on-tonkean-expression-changed="onExpiresAtExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>
    </div>

    <!-- Create collaboration -->
    <div ng-if="data.definition.customActionKey === 'CREATE_COLLABORATION'">
        <!-- Item Type (File or Folder) -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Item Type:</div>
            <ui-select
                theme="bootstrap"
                ng-model="data.definition.itemType"
                required
                ng-change="onItemTypeExpressionChanged(true)"
            >
                <ui-select-match placeholder="Choose a item type">
                    {{ data.definition.itemType }}
                </ui-select-match>

                <ui-select-choices repeat="possibleItemType in data.possibleItemTypes | filter:$select.search">
                    <div ng-bind-html="possibleItemType | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <div ng-if="data.validationObject.itemTypeError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.itemTypeError }}
        </div>

        <!-- Item ID -->
        <div ng-if="data.definition.itemType">
            <div class="margin-bottom-xs">{{ data.definition.itemType }} ID:</div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="{{ data.definition.itemType }} ID"
                saved-expression="data.definition.itemIdExpresssion"
                expression-unique-identifier="BoxItemId"
                on-tonkean-expression-changed="onItemIdExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div ng-if="data.validationObject.itemIdError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.itemIdError }}
        </div>

        <!-- Access Type (User or Group) -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Access Type:</div>
            <ui-select
                theme="bootstrap"
                ng-model="data.definition.accessType"
                required
                ng-change="onAccessTypeExpressionChanged(true)"
            >
                <ui-select-match placeholder="Choose a access type">
                    {{ data.definition.accessType }}
                </ui-select-match>

                <ui-select-choices repeat="possibleAccessType in data.possibleAccessTypes | filter:$select.search">
                    <div ng-bind-html="possibleAccessType | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <div ng-if="data.validationObject.accessTypeError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.accessTypeError }}
        </div>

        <!-- ID -->
        <div ng-if="data.definition.accessType">
            <div class="margin-bottom-xs">
                By {{ data.definition.accessType }} ID
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The ID of the user or group."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="{{ data.definition.accessType }} ID"
                saved-expression="data.definition.accessIdExpresssion"
                expression-unique-identifier="BoxAccessId"
                on-tonkean-expression-changed="onAccessIdExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Email -->
        <div ng-if="data.definition.accessType && data.definition.accessType === 'User'">
            <div class="margin-bottom-xs">
                Or By User Email Address
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="The email address of the user to grant access to the item."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Email address"
                saved-expression="data.definition.accessEmailAddressExpresssion"
                expression-unique-identifier="BoxAccessEmailAddress"
                on-tonkean-expression-changed="onAccessEmailAddressExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <div
            ng-if="data.validationObject.accessableByIdError"
            class="margin-top-xs margin-bottom-xs common-color-danger"
        >
            {{ data.validationObject.accessableByIdError }}
        </div>

        <!-- Expires at -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">
                Expires At:
                <i
                    class="fa fa-info-circle common-color-primary margin-left-xs"
                    uib-tooltip="Optional - Set the expiration date for the collaboration."
                ></i>
            </div>

            <tnk-tonkean-expression
                group-id="{{ data.groupId }}"
                workflow-version-id="{{ data.workflowVersionId }}"
                logic-id="{{ data.configuredLogic.node.id }}"
                preview-evaluation-source="data.previewEvaluationSource"
                placeholder="Collaboration expiration date"
                saved-expression="data.definition.expiresAtExpresssion"
                expression-unique-identifier="BoxExpiresAt"
                on-tonkean-expression-changed="onExpiresAtExpressionChanged(expression, shouldSaveLogic)"
            ></tnk-tonkean-expression>
        </div>

        <!-- Role -->
        <div class="margin-bottom">
            <div class="margin-right margin-top-xs">Role:</div>
            <ui-select
                theme="bootstrap"
                ng-model="data.definition.accessRole"
                required
                ng-change="onAccessRoleExpressionChanged(true)"
            >
                <ui-select-match placeholder="Choose a access role">
                    {{ data.definition.accessRole }}
                </ui-select-match>

                <ui-select-choices repeat="possibleAccessRole in data.possibleAccessRoles | filter:$select.search">
                    <div ng-bind-html="possibleAccessRole | escapeHtml | highlight: $select.search"></div>
                </ui-select-choices>
            </ui-select>
        </div>

        <div ng-if="data.validationObject.accessRoleError" class="margin-top-xs margin-bottom-xs common-color-danger">
            {{ data.validationObject.accessRoleError }}
        </div>

        <!-- Notify users -->
        <div ng-if="data.definition.accessType">
            <tnk-checkbox
                class="margin-top-xs"
                label="'Notify ' + data.definition.accessType + ' by Email'"
                value="data.definition.notifyUsers"
                on-toggle-async="onDefinitionChanged(true)"
            ></tnk-checkbox>
        </div>
    </div>
</div>
