import { useAngularService } from 'angulareact';
import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';

import type { SidePaneConfiguration } from './SidePaneBlockConfiguration';
import { ReactComponent as BackIcon } from '../../../images/icons/back.svg';
import { ReactComponent as RedErrorIcon } from '../../../images/icons/red-error.svg';
import { EnterpriseComponentPageTemplateContext } from '../../infrastructure/pageTemplates/EnterpriseComponentPageTemplate';

import { useGetStateParams } from '@tonkean/angular-hooks';
import type { BreadcrumbsSettings } from '@tonkean/infrastructure';
import { BreadcrumbsZone } from '@tonkean/infrastructure';
import { childrenStyledFocus } from '@tonkean/tui-basic/styledFocus';
import { IconButton } from '@tonkean/tui-buttons/Button';
import type { ClickableProps } from '@tonkean/tui-buttons/Clickable';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import type { Color } from '@tonkean/tui-theme';
import { FontSize, Theme } from '@tonkean/tui-theme';

const Wrapper = styled.div<{ marginRight: number; marginBottom: number }>`
    display: flex;

    height: 100%;
    width: 100%;
    margin-right: ${({ marginRight }) => `${marginRight}px`};
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};

    ${childrenStyledFocus};
`;

const SidePaneWrapper = styled.div<{ $color?: Color; showSeparator: boolean }>`
    padding: 24px 14px;
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0;

    background-color: ${({ $color }) => $color ?? 'unset'};
    border-right: ${({ showSeparator }) => (showSeparator ? `1px solid ${Theme.colors.gray_300}` : 'none')};
`;

const EntitiesBlock = styled.div``;

const BackIconButton = styled(IconButton)`
    margin-bottom: 28px;
    width: 75px;
`;

const TabsBlockHeader = styled.h4`
    color: ${Theme.colors.gray_800};
    font-weight: 500;
    font-size: ${FontSize.MEDIUM_14};
    margin-bottom: 20px;
    margin-top: 0;
`;

const TabHeader = styled(ClickableLink)<{ $isSelected: boolean }>`
    font-weight: 400;
    font-size: ${FontSize.SMALL_12};
    margin-bottom: 16px;

    &,
    &:hover,
    &:focus {
        color: ${(props) => (props.$isSelected ? Theme.colors.primaryHighlight : Theme.colors.gray_700)};
    }
`;

const ContentWrapper = styled.div`
    flex: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

interface GenericContentBySidePaneLayoutProps<T extends object> {
    sidePaneConfiguration: SidePaneConfiguration<T>;
    showBackButton?: boolean;
    tabParam?: string;
    layoutMarginRight?: number;
    layoutMarginBottom?: number;
    sidePaneBackgroundColor?: Color;

    /**
     * Whether to show a border between the sidebar and the content.
     */
    showSeparator?: boolean;

    /**
     * The state to go to in case of a route that does not exist.
     */
    baseState?: string;
}

type BackButtonProps = { showBackButton: false } | { showBackButton: true; clickableBack: ClickableProps };

type Props<T extends Record<any, any>> = GenericContentBySidePaneLayoutProps<T> & T & BackButtonProps;

const GenericContentBySidePaneLayout = <T extends Record<any, any>>(props: Props<T>) => {
    const {
        sidePaneConfiguration,
        clickableBack,
        tabParam = 'tab',
        layoutMarginRight = 0,
        layoutMarginBottom = 0,
        showBackButton = false,
        sidePaneBackgroundColor,
        showSeparator = false,
        baseState = '.',
    } = props;
    // currentTab key from the url param.
    const [currentTabKey] = useGetStateParams(tabParam);

    // Maps tab key to tab has error.
    const [tabKeyToHasError, setTabKeyToHasError] = useState<Record<string, boolean>>({});

    // Maps tab key to its onExit callback.
    const [tabKeyToOnExit, setTabKeyToOnExit] = useState<Record<string, () => Promise<boolean>>>({});

    const $state = useAngularService('$state');

    // Determine which page should be viewed.
    const viewingTabComponent = useMemo(() => {
        return sidePaneConfiguration.sidePaneBlockConfiguration
            .flatMap((page) => page.subTabsConfigurations)
            .find((subPages) => subPages.key === currentTabKey);
    }, [currentTabKey, sidePaneConfiguration]);

    const defaultGatewayTabKey = useMemo(() => {
        return sidePaneConfiguration.sidePaneBlockConfiguration
            .flatMap((page) => page.subTabsConfigurations)
            .find((subPages) => !subPages.hide && !subPages.disabled)?.key;
    }, [sidePaneConfiguration]);

    const encounteredInvalidUrlParam = useCallback(() => {
        if (defaultGatewayTabKey) {
            $state.go(baseState, { [tabParam]: defaultGatewayTabKey }, {});
            return <></>;
        } else {
            return <h1>404 Page not found</h1>;
        }
    }, [$state, baseState, defaultGatewayTabKey, tabParam]);

    // Util function to change between tabs.
    const switchSelectedTab = useCallback(
        (tabKey) => {
            $state.go(
                baseState,
                {
                    [tabParam]: tabKey,
                },
                { inherit: true, notify: false },
            );
        },
        [$state, baseState, tabParam],
    );

    const shouldShowHeaderComponent = useMemo(() => {
        return (
            sidePaneConfiguration.headerComponent &&
            !sidePaneConfiguration.tabKeysToExcludeHeaderComponent?.includes(currentTabKey)
        );
    }, [currentTabKey, sidePaneConfiguration.headerComponent, sidePaneConfiguration?.tabKeysToExcludeHeaderComponent]);

    // Callback for setting an error text for tab.
    const setErrorToTab = useCallback((tabKey: string, hasError: boolean) => {
        setTabKeyToHasError((prev) => ({ ...prev, [tabKey]: hasError }));
    }, []);

    // Callback for setting error for the viewing tab.
    const setTabError = useCallback(
        (hasError: boolean) => setErrorToTab(currentTabKey, hasError),
        [currentTabKey, setErrorToTab],
    );

    const pageTemplateContext = useContext(EnterpriseComponentPageTemplateContext);

    // If it's a breadcrumb without a display name, we will add the page name to it.
    const breadcrumbs = useMemo<BreadcrumbsSettings[]>(
        () => [
            {
                id: `GenericContentBySidePaneLayout - ${viewingTabComponent?.displayName || ''}`,
                displayName: viewingTabComponent?.displayName || '',
                clickable: pageTemplateContext && {
                    state: pageTemplateContext.currentPageState,
                    params: pageTemplateContext.currentPageParams,
                    options: { notify: false },
                },
            },
        ],
        [viewingTabComponent?.displayName, pageTemplateContext],
    );

    const pageTemplateContextValue = useMemo(
        () => ({
            currentPageState: baseState,
            currentPageParams: { [tabParam]: viewingTabComponent?.key },
        }),
        [baseState, tabParam, viewingTabComponent?.key],
    );

    // Callback for setting onExit callback for the viewing tab.
    const setOnExitToTab = useCallback((tabKey: string, callback: () => Promise<boolean>) => {
        setTabKeyToOnExit((prev) => ({ ...prev, [tabKey]: callback }));
    }, []);

    const setOnExitTab = useCallback(
        (callback) => setOnExitToTab(currentTabKey, callback),
        [currentTabKey, setOnExitToTab],
    );

    // Calls tabKeyToOnExit[currentTabKey] and clickableBack.onClick sequentially.
    const onClickBack = useCallback(async () => {
        const onExitTab = tabKeyToOnExit[currentTabKey];

        return (
            (!onExitTab || (await Promise.resolve(onExitTab()))) &&
            (!clickableBack.onClick || (await Promise.resolve(clickableBack.onClick())))
        );
    }, [tabKeyToOnExit, currentTabKey, clickableBack]);

    return (
        <Wrapper marginBottom={layoutMarginBottom} marginRight={layoutMarginRight}>
            <SidePaneWrapper $color={sidePaneBackgroundColor} showSeparator={showSeparator}>
                {showBackButton && (
                    <BackIconButton
                        leftIcon={<BackIcon />}
                        {...clickableBack}
                        onClick={onClickBack} // Overrides clickableBack.onClick
                        data-automation="generic-content-by-side-pane-layout-back-button"
                    >
                        Back
                    </BackIconButton>
                )}

                {sidePaneConfiguration.sidePaneBlockConfiguration
                    .filter((block) => !block.hide)
                    .map((tabsBlock) => (
                        <EntitiesBlock key={tabsBlock.displayName}>
                            <TabsBlockHeader>{tabsBlock.displayName}</TabsBlockHeader>

                            {tabsBlock.subTabsConfigurations.map((tab) => (
                                <div key={tab.key}>
                                    {!tab.hide && (
                                        <TabHeader
                                            $isSelected={tab.key === currentTabKey}
                                            data-automation="generic-content-by-side-pane-layout-menu-option"
                                            disabled={tab.disabled}
                                            state={tab.state ?? tabsBlock.state}
                                            params={{ [tabParam]: tab.key }}
                                            onClick={
                                                tab.key === currentTabKey ? undefined : tabKeyToOnExit[currentTabKey]
                                            }
                                        >
                                            {tab.displayName}
                                            {tabKeyToHasError[tab.key] && (
                                                <RedErrorIcon className="margin-left-xxs-no-sm" />
                                            )}
                                        </TabHeader>
                                    )}
                                </div>
                            ))}
                        </EntitiesBlock>
                    ))}
            </SidePaneWrapper>

            <EnterpriseComponentPageTemplateContext.Provider value={pageTemplateContextValue}>
                <BreadcrumbsZone settings={breadcrumbs}>
                    <ContentWrapper>
                        {sidePaneConfiguration?.headerComponent && shouldShowHeaderComponent && (
                            <sidePaneConfiguration.headerComponent
                                sidePaneTabName={viewingTabComponent?.displayName}
                                sidePaneTabKey={viewingTabComponent?.key}
                                switchSelectedTab={switchSelectedTab}
                                setTabError={setTabError}
                                tabKeyToHasError={tabKeyToHasError}
                                setOnExitTab={setOnExitTab}
                                tabKeyToOnExit={tabKeyToOnExit}
                                {...props}
                            />
                        )}
                        {viewingTabComponent && !viewingTabComponent.disabled && !viewingTabComponent.hide ? (
                            <viewingTabComponent.component
                                switchSelectedTab={switchSelectedTab}
                                sidePaneTabKey={viewingTabComponent?.key}
                                sidePaneTabName={viewingTabComponent?.displayName}
                                setTabError={setTabError}
                                tabKeyToHasError={tabKeyToHasError}
                                setOnExitTab={setOnExitTab}
                                tabKeyToOnExit={tabKeyToOnExit}
                                {...props}
                            />
                        ) : (
                            <>{encounteredInvalidUrlParam()}</>
                        )}
                    </ContentWrapper>
                </BreadcrumbsZone>
            </EnterpriseComponentPageTemplateContext.Provider>
        </Wrapper>
    );
};

export default GenericContentBySidePaneLayout;
