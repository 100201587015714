import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import ConfigurationSectionTitle from '../../../components/ConfigurationSectionTitle';
import type ImageWidgetConfiguration from '../ImageWidgetConfiguration';

import { useLazyTonkeanService } from '@tonkean/angular-hooks';
import { DragAndDropFiles, DragAndDropFilesSizeType, Field, Input } from '@tonkean/infrastructure';
import { EmptyImage } from '@tonkean/svg';
import type {
    TonkeanExpressionDefinition,
    TonkeanUploadedFile,
    WidgetBase,
    WidgetConfiguration,
    WidgetParentTypes,
} from '@tonkean/tonkean-entities';
import { Theme, FontSize } from '@tonkean/tui-theme';

const ErrorText = styled.span`
    color: ${Theme.colors.error};
`;

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const Label = styled.div`
    font-size: ${FontSize.SMALL_12};
    color: ${Theme.colors.gray_700};
    min-width: 60px;
    margin-bottom: 8px;
`;

interface Props {
    configuration: ImageWidgetConfiguration;
    selectedWidget: WidgetBase<WidgetConfiguration, WidgetParentTypes>;
    onSave: (configuration: ImageWidgetConfiguration) => void;
}

const ImageWidgetUploader: React.FC<Props> = ({ configuration, selectedWidget, onSave }) => {
    const [existingImages, setExistingImages] = useState<TonkeanUploadedFile[]>([]);

    const [{ loading: savingLogo }, uploadItemInterfaceWidgetImageById] = useLazyTonkeanService(
        'uploadItemInterfaceWidgetImageById',
    );

    const [uploadError, setUploadError] = useState<string>('');

    const updateExistingFiles = useCallback(
        async (files: TonkeanUploadedFile[]) => {
            if (files?.length > 0 && files[0]?.blob) {
                const { iconUrl: imageUrl } = await uploadItemInterfaceWidgetImageById(selectedWidget, files[0]?.blob);
                onSave({
                    ...configuration,
                    image: {
                        ...configuration.image,
                        url: {
                            originalExpression: imageUrl,
                            evaluatedExpression: imageUrl,
                        } as TonkeanExpressionDefinition,
                    },
                });
            }
            setExistingImages(files);
        },
        [configuration, onSave, selectedWidget, uploadItemInterfaceWidgetImageById],
    );

    return (
        <>
            <ConfigurationSectionTitle>Image Details</ConfigurationSectionTitle>
            <Field inlineError>
                <InputWrapper>
                    <Label>Image Alt Text (shown on hover)</Label>
                    <Input
                        title="altText"
                        name="configuration.image.altText"
                        autoComplete="off"
                        data-automation="image-widget-uploader-show-on-hover"
                    />
                </InputWrapper>
            </Field>
            <DragAndDropFiles
                mainText={
                    configuration?.image?.url
                        ? 'Drag image here to replace existing image'
                        : 'Drag image here to upload'
                }
                dragAndDropLogo={<EmptyImage />}
                existingFiles={existingImages}
                setExistingFiles={updateExistingFiles}
                setErrorText={(err) => setUploadError(err || '')}
                maxFileSizeMB={5}
                acceptedFileTypes={['image/jpeg', 'image/png']}
                limitationsDescriptionText="The maximum file size is 5mb and the supported file types are JPG, JPEG and PNG"
                isLoading={savingLogo}
                sizeType={DragAndDropFilesSizeType.FLUID}
                padding="12px"
                isImageUpload
            />
            {uploadError && <ErrorText>{uploadError}</ErrorText>}
        </>
    );
};

export default ImageWidgetUploader;
