import { useMemo } from 'react';

import type { IntakeProgressTriggerInSequenceInfo } from '../entities/IntakeProgressTriggerInSequenceInfo';

import { useTonkeanService } from '@tonkean/angular-hooks';
import type { CustomTriggerInSequenceInfo, GraphNode, TonkeanId, TonkeanType } from '@tonkean/tonkean-entities';
import { AutonomousSecondaryType, CustomTriggerType } from '@tonkean/tonkean-entities';

const findFirstFormFilledIntakeCustomTriggerIdMatchingFormId = (
    graphRootNodes: GraphNode[] | undefined,
    customTriggerById: Record<TonkeanId<TonkeanType.CUSTOM_TRIGGER>, CustomTriggerInSequenceInfo>,
    formId: TonkeanId<TonkeanType.FORM>,
) => {
    return graphRootNodes?.find((rootNode) => {
        return (
            customTriggerById[rootNode.node]?.monitorFormIds?.some((monitoredFromId) => monitoredFromId === formId) &&
            customTriggerById[rootNode.node]?.customTriggerSecondaryType ===
                AutonomousSecondaryType.AUTONOMOUS_CREATED_FROM_FORM
        );
    })?.node;
};

// Running DFS on the graph to find the path from the root to the current trigger
const findSequenceFromRootThroughCurrentTriggerId = (
    currentNode: GraphNode,
    customTriggerById: Record<TonkeanId<TonkeanType.CUSTOM_TRIGGER>, CustomTriggerInSequenceInfo>,
    currentCustomTriggerId: TonkeanId<TonkeanType.CUSTOM_TRIGGER>,
    currentSequence: TonkeanId<TonkeanType.CUSTOM_TRIGGER>[],
): TonkeanId<TonkeanType.CUSTOM_TRIGGER>[] | undefined => {
    if (currentNode.node === currentCustomTriggerId) {
        const currentPath = [...currentSequence, currentNode.node];

        // Add trigger to the end sequence, selecting the first one in case there is a split in the flow
        let futureNode: GraphNode = currentNode;
        let foundTriggerOutOfSequence = false;
        while (futureNode.impacts?.[0] !== undefined && !foundTriggerOutOfSequence) {
            futureNode = futureNode.impacts[0];
            const futureNodeInfo = customTriggerById[futureNode.node];
            const isValidTriggerForTrackerStatuses =
                futureNodeInfo?.customTriggerType === CustomTriggerType.SEND_FORM_ANSWERED ||
                (futureNodeInfo?.customTriggerType === CustomTriggerType.AUTONOMOUS &&
                    (futureNodeInfo?.customTriggerSecondaryType ===
                        AutonomousSecondaryType.AUTONOMOUS_CREATED_FROM_FORM ||
                        futureNodeInfo?.customTriggerSecondaryType ===
                            AutonomousSecondaryType.AUTONOMOUS_INTERFACE_SUBMITTED));

            if (isValidTriggerForTrackerStatuses) {
                currentPath.push(futureNode.node);
            } else {
                foundTriggerOutOfSequence = true;
            }
        }

        return currentPath;
    }

    if (!currentNode.impacts) {
        return undefined;
    }

    for (const childNode of currentNode.impacts) {
        const path = findSequenceFromRootThroughCurrentTriggerId(childNode, customTriggerById, currentCustomTriggerId, [
            ...currentSequence,
            currentNode.node,
        ]);
        if (path) {
            return path;
        }
    }

    return undefined;
};

export interface useIntakeCustomTriggersSequenceProps {
    workflowVersionId: TonkeanId<TonkeanType.WORKFLOW_VERSION>;
    customTriggerIdInSequence?: TonkeanId<TonkeanType.CUSTOM_TRIGGER>;
    currentFormId?: TonkeanId<TonkeanType.FORM>;
}

/*
 * This hook gets all custom triggers for a given workflow version, and returns a linear sequence of custom triggers that contains the specified trigger
 * The algorithms find the root node that contains a path to the specified trigger and continues the path until the end of the graph, choosing the first
 * child in case of a split in the flow.
 * In case no trigger specified, the first form filled trigger is chosen as the root node and the algorithm continues from there.
 */
const useIntakeCustomTriggersSequence = ({
    workflowVersionId,
    customTriggerIdInSequence,
    currentFormId,
}: useIntakeCustomTriggersSequenceProps): IntakeProgressTriggerInSequenceInfo[] => {
    const { data: allCustomTriggers } = useTonkeanService(
        'getCustomTriggersInSequenceSummaryOfWorkflowVersion',
        workflowVersionId,
    );

    const customTriggerById = useMemo(() => {
        if (!allCustomTriggers?.entities) {
            return null;
        }
        return allCustomTriggers.entities.reduce(
            (acc, customTrigger) => {
                acc[customTrigger.id] = customTrigger;
                return acc;
            },
            {} as Record<TonkeanId<TonkeanType.CUSTOM_TRIGGER>, CustomTriggerInSequenceInfo>,
        );
    }, [allCustomTriggers]);

    const firstFormFilledIntakeCustomTriggerId = useMemo(() => {
        return currentFormId && customTriggerById && allCustomTriggers?.graph
            ? findFirstFormFilledIntakeCustomTriggerIdMatchingFormId(
                  allCustomTriggers?.graph?.impacts,
                  customTriggerById,
                  currentFormId,
              )
            : undefined;
    }, [customTriggerById, allCustomTriggers, currentFormId]);

    // assuming only one form filled trigger that is the default sequence start point
    const currentCustomTriggerId = customTriggerIdInSequence || firstFormFilledIntakeCustomTriggerId;

    const customTriggersSequenceIds = useMemo(() => {
        if (!allCustomTriggers?.graph || !currentCustomTriggerId || !customTriggerById) {
            return null;
        }

        return findSequenceFromRootThroughCurrentTriggerId(
            allCustomTriggers.graph,
            customTriggerById,
            currentCustomTriggerId,
            [],
        );
    }, [allCustomTriggers, currentCustomTriggerId, customTriggerById]);

    return useMemo(() => {
        if (!customTriggersSequenceIds || !customTriggerById) {
            return [];
        }

        return customTriggersSequenceIds
            .filter((triggerId) => customTriggerById[triggerId])
            .map((triggerId) => {
                const customTrigger = customTriggerById[triggerId];
                return {
                    id: triggerId,
                    stateId: customTrigger?.stateId,
                };
            });
    }, [customTriggersSequenceIds, customTriggerById]);
};

export default useIntakeCustomTriggersSequence;
