import { useAngularService } from 'angulareact';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import useAuthentication from './useAuthentication';
import type CustomizedSetupComponentProps from '../base/CustomizedSetupComponentProps';

import {
    AnimatedSwitch,
    AnimatedSwitchItem,
    EnterpriseComponentVariables,
    Input,
    Tab,
    Tabs,
} from '@tonkean/infrastructure';
import type { Integration } from '@tonkean/tonkean-entities';
import { IntegrationType } from '@tonkean/tonkean-entities';
import { Button } from '@tonkean/tui-buttons/Button';

const FooterActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`;

const FooterUpdateAndAuthButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
`;

const TabContentWrapper = styled.div`
    margin-top: 12px;
`;

enum TabKey {
    SETUP = 'SETUP',
    VARIABLES_VALUE = 'VARIABLES_VALUE',
}

const NoCodeDataSourceCustomSetup: React.FC<CustomizedSetupComponentProps> = ({
    integration,
    projectIntegration,
    onChangeOrInitIntegration,
    createProjectApis,
    onCancel,
    onSave: onSaveParam,
}) => {
    const projectManager = useAngularService('projectManager');
    const onSaveRef = React.createRef<() => Promise<boolean>>();

    const defaultTab = !!projectIntegration ? TabKey.VARIABLES_VALUE : TabKey.SETUP;

    // The activated tab.
    const [activeTab, setActiveTab] = useState<TabKey>(defaultTab);

    // The display name of the integration.
    const [displayName, setDisplayName] = useState<string>(projectIntegration?.displayName || '');

    // Is In update mode
    const isEditMode = !!projectIntegration;

    const onSave = async () => {
        if (onSaveRef?.current) {
            await onSaveRef.current();
        }
        // when project integration exists it means we are in edit mode.
        if (isEditMode) {
            // Edit Project Integration.
            editCurrentIntegration();
        } else {
            // Creating Project Integration + Integration.
            createNewIntegration();
        }
    };

    const authenticate = useAuthentication();
    const saveAndAuthenticate = async () => {
        await onSave();
        await authenticate(projectIntegration);
    };

    // On Editing existing integration.
    const editCurrentIntegration = () => {
        onSaveParam?.(undefined, displayName, integration);
    };

    // On Creating new integration + project integration for no code data source integration.
    const createNewIntegration = async () => {
        // Create integration (TonkeanType.INTEGRATION).
        const createdIntegration: Integration = await createProjectApis.createIntegration(
            projectManager.project.id,
            IntegrationType.NO_CODE_DATA_SOURCE.toLowerCase(),
            {},
        );

        onSaveParam?.({}, displayName, createdIntegration);
    };

    // Should show the setup tab.
    const shouldShowSetUpTab = !isEditMode;

    // Validating display name is not empty before saving.
    const isCreateOrUpdateIntegrationValid = displayName !== '';

    // Should show the setup tab.
    const shouldShowVariablesTab = isEditMode;

    return (
        <>
            <Tabs value={activeTab} onChange={setActiveTab}>
                {shouldShowSetUpTab && <Tab label={TabKey.SETUP}>Configuration</Tab>}
                {shouldShowVariablesTab && <Tab label={TabKey.VARIABLES_VALUE}>Parameter Values</Tab>}
            </Tabs>
            <AnimatedSwitch activeLabel={activeTab}>
                <AnimatedSwitchItem label={TabKey.SETUP}>
                    <TabContentWrapper>
                        <label htmlFor="display-name-id">Display Name</label>

                        <Input
                            id="display-name-id"
                            value={displayName}
                            onChange={({ target: { value } }) => {
                                setDisplayName(value);
                            }}
                        />
                    </TabContentWrapper>
                </AnimatedSwitchItem>

                {isEditMode && (
                    <AnimatedSwitchItem label={TabKey.VARIABLES_VALUE}>
                        <TabContentWrapper>
                            <EnterpriseComponentVariables
                                enterpriseComponentId={projectIntegration.id}
                                relatedEntityId={projectIntegration.id}
                                onSaveRef={onSaveRef}
                                hideHeader
                            />
                        </TabContentWrapper>
                    </AnimatedSwitchItem>
                )}
            </AnimatedSwitch>

            <FooterActionsWrapper>
                <Button onClick={onCancel} outlined>
                    Cancel
                </Button>

                <FooterUpdateAndAuthButtonsWrapper>
                    {isEditMode && (
                        <Button onClick={saveAndAuthenticate} highlighted>
                            Authenticate
                        </Button>
                    )}
                    <Button disabled={!isCreateOrUpdateIntegrationValid} onClick={onSave} highlighted>
                        {isEditMode ? <>Update</> : <>Save</>}
                    </Button>
                </FooterUpdateAndAuthButtonsWrapper>
            </FooterActionsWrapper>
        </>
    );
};

export default NoCodeDataSourceCustomSetup;
